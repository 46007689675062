import {Text} from 'react-native';
import PropTypes from "prop-types";
import {LxReactMenuController, LxShimmerPlaceHolder} from "./Components";
import Icons from "IconLib";
import globalStyles from "GlobalStyles";
import {useCallback} from "react";
import {StyleSheet} from "react-native-web"

function LxReactText(props) {
    const parseLineBreaks = (text) => {
        return text.replaceAll("<br>", "\n");
    }

    let rawContent = props.children;

    if (rawContent) {
        if (typeof rawContent === "string") {
            rawContent = parseLineBreaks(rawContent);
        } else if (Array.isArray(rawContent)) {
            rawContent = [...props.children]; // props.children cannot be modified if a string is converted below.
            for (let i = 0; i < rawContent.length; i++) {
                if (typeof rawContent[i] === "string") {
                    rawContent[i] = parseLineBreaks(rawContent[i])
                }
            }
        }
    }

    const text = <Text style={{
            ...globalStyles.textStyles.default,
        }} {...props}>{rawContent}</Text>,
        onMenuAction = useCallback(() => {
            copyToClipboard(rawContent);
            HapticFeedback();
        }, [])

    if (props.copyable || props.copyableOnPress) {
        return <LxReactMenuController style={styles.menuController}
                                      copyableOnPress={!!props.copyableOnPress}
                                      actions={[{ iconComp: Icons.Copy, text: _("copy")}]}
                                      onPress={onMenuAction}>{text}</LxReactMenuController>
    }

    if (props.isLoading) {
        return <LxShimmerPlaceHolder
            isLoading={true}
            type={props.type}
            style={{height: StyleSheet.flatten(props.style).fontSize}}
        />
    } else {
        return text;
    }
}

const styles = {
    menuController: {
        maxWidth: "100%"
    }
}

export default LxReactText;

LxReactText.propTypes = {
    ...Text.propTypes,
    children: PropTypes.node,
    copyable: PropTypes.bool,
    copyableOnPress: PropTypes.bool,
    isLoading: PropTypes.bool,
    primaryStyle: PropTypes.bool,
    type: PropTypes.string,
    numberOfLines: PropTypes.number
};
