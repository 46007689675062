import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Flame(props) {
    return (
        <Svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path
                d="M16.892 12.935c0 1.936-1.641 3.807-3.283 4.775.525-.839.92-1.678.92-2.323 0-1.42-2.233-2.968-2.43-4.064-.592.387-2.036 1.677-2.036 3.032 0 .839.59 2 1.05 3.226-1.116-1.097-3.874-2.452-3.874-5.549 0-3.87 2.561-7.29 6.436-9.032-1.445 4.323 3.217 5.806 3.217 9.935zm2.233 6.775c0-.71-.591-1.29-1.313-1.29H6.188c-.722 0-1.313.58-1.313 1.29 0 .71.591 1.29 1.313 1.29h11.624c.788 0 1.313-.58 1.313-1.29z"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default Flame
