'use strict';

define(["AtheneMediaHandler"], function (AtheneMediaHandler) {
    return class AtheneDiagnosisScreen extends GUI.Screen {
        //region Static
        static Template = class {
            //region Static
            static getDiagnosisContainer(report) {
                // Its important to sanitize the HTML to prevent any XSS injections!
                return $("<pre class='athene-diagnosis__text-container'>" + report + "</pre>");
            } //endregion Static


        }; //endregion Static

        constructor(details) {
            super($("<div/>"));
            this.control = details.control;
        }

        viewDidLoad() {
            var args = arguments;
            return AtheneMediaHandler.shared(this.control).loadTechReport().then(report => {
                return super.viewDidLoad(...args).then(function () {
                    this.report = report;
                    this.element.append(AtheneDiagnosisScreen.Template.getDiagnosisContainer(report));
                }.bind(this));
            });
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK,
                style: 'transparent',
                rightSide: TitleBarCfg.CUSTOM_ICON,
                rightIconSrc: Icon.USER_MANAGEMENT.SHARE
            };
        }

        titleBarText() {
            return _("diagnosis-information");
        }

        getWaitingForTitle() {
            return _("diagnosis-information.fetch");
        }

        getWaitingForMessage() {
            return NBR_SPACE;
        }

        titleBarActionRight() {
            var enc = new TextEncoder();
            NavigationComp.showWaitingFor(asyncArrayBufferToBase64String(enc.encode(this.report)).then(function (base64) {
                exportBase64File(this.control.name + ".log", base64, "text/plain", this.getTitleBar().buttons.rightSideButton.getElement()[0]);
            }.bind(this)));
        }

    };
});
