import * as React from "react"
import Svg, { Path } from "react-native-svg"

function ExclamationMarkCircleFilled(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zm0-7.25a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm0-10.5a1.5 1.5 0 011.5 1.5l-.375 6.375a1.125 1.125 0 01-2.25 0L10.5 6.75a1.5 1.5 0 011.5-1.5z"
            />
        </Svg>
    )
}

export default ExclamationMarkCircleFilled
