import * as React from "react"
import Svg, { Path } from "react-native-svg"

function QrScanArea(props) {
    return (
        <Svg
            className="scan-region-highlight-svg"
            viewBox="0 0 238 238"
            preserveAspectRatio="none"
            style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                left: 0,
                top: 0
            }}
            fill="none"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <Path d="M31 2H10a8 8 0 00-8 8v21M207 2h21a8 8 0 018 8v21m0 176v21a8 8 0 01-8 8h-21m-176 0H10a8 8 0 01-8-8v-21" />
        </Svg>
    )
}

export default QrScanArea
