'use strict';
/**
 *  title               the title to display in this cell
 *  tintColor           the color to tint the cell in
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.COMFORT_ACTION = "ComfortActionCell";

    class ComfortActionCell extends GUI.TableViewV2.Cells.CenterCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        setContent(content) {
            content.active = true; // -> light background

            content.supportFastInteraction = true;
            this.setTintColor(content.tintColor);
            super.setContent(...arguments);
        }

        destroy() {
            this._stopInterval();

            super.destroy(...arguments);
        }

        /**
         * Takes an hex, rgb or rgba color, converts it into an rgba color with an alpha chanel of 0.4
         * @param color
         * @private
         */
        setTintColor(color) {
            if (color) {
                this.element.css("background-color", applyAlphaChannelToColorString(color, 0.4));
            } else {
                this.element.css("background-color", "");
            }
        }

        setThinking(shouldThink) {
            if (shouldThink) {
                this.element.addClass("thinking");
            } else {
                this.element.removeClass("thinking");
            }
        }

    }

    GUI.TableViewV2.Cells.ComfortActionCell = ComfortActionCell;
    return GUI;
}(window.GUI || {});
