import {useState, useEffect, useMemo} from "react";
import useAmbientSettings from "./useAmbientSettings";
import defaultBG from "../../../../resources/Images/EcoScreen/wallpaper.png";
import WallpaperOptions from "../../../PairedAppComp/helper/WallpaperOptions";

export default function useAmbientWallpaperOptions() {
    const [awsOptions, setAwsOptions] = useState([]);
    const settings = useAmbientSettings();

    const getImageData = async () => {
        let awsImgs = []

        WallpaperOptions.get().then(wallpapers => {
            wallpapers.map(async (img) => {
                awsImgs.push({
                    source: {
                        uri: img.src,
                    },
                    sourceThumb: {
                        uri: img.srcThumb
                    },
                    type: OptionType.BASE_IMAGE,
                    title: img.id,
                    author: img.author,
                    authorLink: img.authorLink
                })
            })
            setAwsOptions([...awsImgs]);
        });
    }

    useEffect(async () => {
        await getImageData()
    }, [])

    const currentImage = useMemo(() => {
        console.log("MEMO_CURRENT_IMAGE", settings);
        if (settings && settings.imageObject) {
            return cloneObject(settings.imageObject);
        }
        return null;
    }, [settings && settings.imageObject ? JSON.stringify(settings.imageObject).hashCode() : null]);

    const defaultImage = useMemo(() => {
        return {
            source: {
                uri: defaultBG,
            },
            type: OptionType.BASE_IMAGE,
            title: "Default"
        };
    }, [])

    return useMemo(() => {
        /*if (currentImage !== null) {
            console.log("MEMO_ALL_IMAGES --> returns empty array, as no current image exists!", settings);
            return [];
        }*/
        let allOptions = [...awsOptions, defaultImage].filter(imgObj => {
            return !currentImage || imgObj.source.uri !== currentImage.source.uri;
        });
        allOptions.splice(0,0, currentImage);
        return allOptions;
    }, [awsOptions, defaultImage, currentImage])
}

export const OptionType = {
    BASE_IMAGE: 0,
    DEVICE_UPLOAD: 1
}


