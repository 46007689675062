'use strict';

define(["ControlActionCellsScreen", "LoadManagerProgressView"], function (ControlActionCellsScreen, LoadManagerProgressView) {
    return class LoadManagerControlContent extends ControlActionCellsScreen {
        constructor() {
            super(...arguments);
        }

        shouldDisplayStateIcons() {
            return false;
        }

        getCustomInfoView() {
            return new LoadManagerProgressView(this.control);
        }

        /*getStateRows(states) {
            var loads = this.control.details.loads,
                rows = [];
            loads.forEach(function (load) {
                var value = NBR_SPACE,
                    valueColor;

                if (load.hasStatus) {
                    value = hasBit(states.statusLoads, 1 << load.id) ? _("on") : _("off");
                    valueColor = hasBit(states.statusLoads, 1 << load.id) ? ColorLight.BRAND_COLOR : ColorLight.TEXT_SECONDARY_B;
                }

                if (hasBit(states.lockedLoads, 1 << load.id)) {
                    value = _("control.lock.locked-title");
                    valueColor = ColorLight.RED;
                }

                rows.push(this.createStateCell(load.name, value, null, _("controls.loadManager.load-max", {
                    power: lxFormat("%.2f", load.power)
                }), null, valueColor, ColorLight.LOAD_MANAGER_SUBTITLE));
            }.bind(this));
            return rows;
        }*/

    };
});
