'use strict';

define([], function () {
    return class TaskRecordingCommandsScreen extends GUI.Screen {
        constructor() {
            super($('<div class="task-recording-commands-screen"></div>')); // get task from extension:

            this.task = SandboxComponent.getRecordedTask();
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.titleBar = this._getTitlebar();
            this.prependSubview(this.titleBar);
            this.tableView = new GUI.TableViewV2(this, this);
            this.tableView.getElement().addClass("task-recording-commands-screen__table-view");
            this.appendSubview(this.tableView);
            this.tableView.reload();
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);

            this.titleBar.onLeftButtonTapped = function () {
                this.ViewController.navigateBack();
                this.titleBar.onLeftButtonTapped = null;
            }.bind(this);

            this.titleBar.onRightButtonTapped = function () {
                var task = this.task; // save reference because of destroy fn!

                this.ViewController.navigateBack();
                NavigationComp.stopActivity(GUI.ActiveMiniserverViewController.Activity.TaskRecorder); // we have always commands if we are at this screen

                NavigationComp.showState(ScreenState.TaskDetails, {
                    task: task
                });
            }.bind(this);
        }

        viewDidDisappear() {
            this.titleBar.onLeftButtonTapped = null;
            this.titleBar.onRightButtonTapped = null;
            super.viewDidDisappear(...arguments);
        }

        getURL() {
            return "TaskCommandList";
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        // Private methods
        _getTitlebar() {
            var titleBarConfig = {
                leftSide: TitleBarCfg.Button.CLOSE,
                rightSide: 'taskRecorderIcon',
                style: HD_APP ? 'transparent' : null
            };
            var titleBar = new GUI.LxTitleBar(titleBarConfig);
            titleBar.setTitleBarSideTexts(_('taskrecorder.commands-recorded', {
                count: this.task.commands.length
            }));
            return titleBar;
        }

        // TableViewDataSource Methods
        styleForTableView() {
            return HD_APP ? TableViewStyle.GROUPED : null;
        }

        numberOfSections() {
            return 1;
        }

        numberOfRowsInSection() {
            return this.task.commands.length;
        }

        cellTypeForCellAtIndex() {
            return GUI.TableViewV2.CellType.Special.TASK_COMMAND;
        }

        contentForCell(cell, section, row) {
            return {
                title: this.task.commands[row].mainText,
                groupDetail: this.task.commands[row].subText,
                delta: this.task.commands[row].delta
            };
        }

    };
});
