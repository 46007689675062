import React from 'react';
import { Text, TouchableOpacity, StyleSheet } from 'react-native';
import Icons from 'IconLib';
import globalStyles from 'GlobalStyles';
import PropTypes from 'prop-types';

export default function TargetTemp(props) {
    const displayTemperature = props.targetTemperature === -1 ? _('controls.ac-control.settings.retain-last-value') : props.targetTemperature + `°${SandboxComponent.getTemperatureUnit()}`;
    if (!props.isSetByLogic && !props.isOperatedExternally) {
        return <TouchableOpacity style={{ flexDirection: "row", display: 'flex', flexShrink: 1 }} onPress={props.displayTempPicker}>
            <Text style={[styles.value, { color: globalStyles.colors.green_fixed, marginRight: 18 }, ...([props.additionalStyle] || [])]} numberOfLines={1} ellipsizeMode={'tail'}>
                {displayTemperature}
            </Text>
            <Icons.ChevronsUpDown fill={globalStyles.colors.text.secondary} width={18} height={18} />
        </TouchableOpacity>
    } else {
        return <Text style={styles.value}>
            {displayTemperature}
        </Text>
    }
}

TargetTemp.propTypes = {
    targetTemperature: PropTypes.number,
    displayTempPicker: PropTypes.func,
    isSetByLogic: PropTypes.bool,
    additionalStyle: PropTypes.object
}

const styles = StyleSheet.create({
    value: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.secondary,
    }
});