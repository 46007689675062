'use strict';

define(["NfcCodeTouchControlEnums"], function (NfcCodeTouchControlEnums) {
    return class NfcCodeTouchControlContentCodePermissions extends GUI.Screen {
        constructor(details) {
            super($('<div />'));
            this.control = details.control;
            this.code = details.code;
            this._isSelectingDefaultOutput = !!details.isSelectingDefaultOutput;

            if (this._isSelectingDefaultOutput) {
                this.outputs = this.control.getUsedOutputsWithPermissions(this.code.outputs);
            } else {
                this.outputs = this.control.getUsedOutputs();
            }
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);

            if (!this._isSelectingDefaultOutput) {
                this.ViewController.addMultipleView(NfcCodeTouchControlEnums.ScreenState.CODE_PERMISSIONS);
            }

            this.tableView = new GUI.TableViewV2(this, this);
            this.appendSubview(this.tableView);
        }

        viewWillAppear() {
            this.tableView.reload();
            super.viewWillAppear(...arguments);
        }

        titleBarText() {
            return !this._isSelectingDefaultOutput ? _('permissions') : _('standard-function');
        }

        getURL() {
            return "Permissions";
        }

        getAnimation() {
            return HD_APP ? AnimationType.HD_OVERLAY : AnimationType.PUSH_OVERLAP_LEFT;
        }

        handleModalBackgroundTapped() {
            return false;
        }

        // TableViewDataSource Methods
        styleForTableView(tableView) {
            return TableViewStyle.GROUPED;
        }

        numberOfSections(tableView) {
            return this._isSelectingDefaultOutput && this._multipleOutputsAllowed() ? 1 : 2;
        }

        numberOfRowsInSection(section, tableView) {
            if (section === 0) {
                return this.outputs.length;
            } else if (section === 1) {
                if (this._multipleOutputsAllowed()) {
                    return 1;
                }
            }

            return 0;
        }

        cellTypeForCellAtIndex(section, row, tableView) {
            if (section === 0) {
                return GUI.TableViewV2.CellType.CHECKABLE;
            } else {
                return GUI.TableViewV2.CellType.GENERAL;
            }
        }

        contentForCell(cell, section, row, tableView) {
            if (section === 0) {
                var output = this.outputs[row];

                if (this._isSelectingDefaultOutput) {
                    return {
                        title: output.name,
                        selected: output.nr === this.code.standardOutput,
                        disclosureIcon: true,
                        clickable: true
                    };
                }

                var outputBit = 1 << output.nr - 1; // we have to go from output (1-6) to bit

                return {
                    title: output.name,
                    selected: this.code.outputs & outputBit,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE
                };
            } else {
                var standardOutput;

                if (this.code.standardOutput === 0) {
                    standardOutput = NOT_AVAILABLE;
                } else {
                    standardOutput = this.control.getOutputForNr(this.code.standardOutput);

                    if (standardOutput === NOT_AVAILABLE) {
                        standardOutput = this.outputs[0].name;
                        this.code.standardOutput = this.outputs[0].nr;
                    }
                }

                var isValid = standardOutput !== NOT_AVAILABLE && this._multipleOutputsAllowed();

                return {
                    title: _('standard-function'),
                    disclosureText: standardOutput,
                    disclosureColor: window.Styles.colors.green,
                    disclosureIcon: isValid,
                    clickable: isValid
                };
            }
        }

        // TableViewDelegate methods
        didRequestCheckingCell(cell, section, row, tableView) {
            if (this._isSelectingDefaultOutput) {
                this.didSelectCell(cell, section, row);
                return false;
            }

            if (section === 0) {
                // forbid the deselection of the last permitted output!
                var output = this.outputs[row],
                    outputBit = 1 << output.nr - 1;

                if (!this._isSelectingDefaultOutput && !this._multipleOutputsAllowed() && this.code.outputs & outputBit) {
                    return false;
                }
            }

            return true;
        }

        didCheckCell(cell, section, row, tableView, selected) {
            var output = this.outputs[row],
                outputBit = 1 << output.nr - 1; // we have to go from output (1-6) to bit

            if (selected) {
                this.code.outputs |= outputBit;
            } else {
                this.code.outputs &= ~outputBit;
            }

            this._validateStandardOutput();
        }

        didSelectCell(cell, section, row) {
            if (this._isSelectingDefaultOutput) {
                var output = this.outputs[row];
                this.code.standardOutput = output.nr;
                this.ViewController.navigateBack();
            } else {
                this.ViewController.showState(NfcCodeTouchControlEnums.ScreenState.CODE_PERMISSIONS, null, {
                    control: this.control,
                    code: this.code,
                    isSelectingDefaultOutput: true
                });
            }
        }

        // Private methods

        /**
         * validates the standardOutput and sets a valid one if necessary
         * @private
         */
        _validateStandardOutput() {
            var isValid = this.code.outputs & 1 << this.code.standardOutput - 1; // checks if the standard output is one of the used outputs

            if (!isValid) {
                var usedAndPermittedOutputs = this.control.getUsedOutputsWithPermissions(this.code.outputs);

                if (usedAndPermittedOutputs.length > 0) {
                    this.code.standardOutput = usedAndPermittedOutputs[0].nr;
                } else {
                    this.code.standardOutput = 0;
                }
            }

            this.tableView.reloadRowsSelective(1, 0, 1);
        }

        /**
         * returns boolean if multiple outputs are allowed with this code
         * @returns {boolean}
         * @private
         */
        _multipleOutputsAllowed() {
            return this.control.getUsedOutputsWithPermissions(this.code.outputs).length > 1;
        }

    };
});
