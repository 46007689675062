import {getSubmenuCell} from "../../UtilityComp/utilities/helper";
import {ScreenState} from "./nfcCodeTouchControlEnums";

export default class NfcCodeTouchControlStatesSrc {
    static getStateSections(control, states, navigation) {
        let permissionInfos = ActiveMSComponent.getRequiredUserManagementPermissionInfos(),
            hasPermissions = permissionInfos.hasUserManagementPermissions || ActiveMSComponent.isAdminUser(),
            sections = [{
                rows: []
            }];

        if (!states || !hasPermissions) {
            return [];
        }

        if (hasPermissions && control.defaultOutput && control.supportsCodes) {
            let title = states.hasCodes ? _('user.access-codes') : _('user.access-code.add');
            sections[0].rows.push(getSubmenuCell(title, this._handleCodes.bind(this, control, navigation, states.hasCodes, states.codeDate), Icon.NfcCodeTouch.CODES));
        }

        if (hasPermissions && states.hasHistory) {
            sections[0].rows.push(getSubmenuCell(_('autopilot.history'), this._handleHistory.bind(this, control, navigation, states.historyDate), Icon.Alarm.CLOCK));
        }

        return sections;
    }

    // Private methods
    static _handleCodes(control, navigation, hasCodes, codeDate) {
        if (hasCodes) {
            control.getCodes().done((result) => {
                /*if (!this.isVisible()) {
                    return;
                }*/

                navigation.navigate(ScreenState.CODES, {
                    control,
                    codeDate: codeDate,
                    activeCodes: result.activeCodes,
                    inactiveCodes: result.inactiveCodes
                }, AnimationType.HD_OVERLAY);
            });
        } else {
            navigation.navigate(ScreenState.CODE, {
                control,
                createNewCode: true
            }, AnimationType.HD_OVERLAY);
        }
    }

    static _handleHistory(control, navigation, historyDate) {
        control.getHistory().done((history) => {
            /*if (!this.isVisible()) {
                return;
            }*/

            navigation.navigate(ScreenState.HISTORY, {
                historyDate: historyDate,
                history: history,
                control
            }, AnimationType.HD_OVERLAY);
        });
    }
}
