'use strict';

define("ControlCommandSrc", [], function () {
    return class ControlCommandSrc {
        constructor(control) {// BEWARE: don't set the control to "this" -> cyclic dependencies will occur!
            // TODO an improvement would be to create the commands initially and return the object
        }

        /**
         * returns a list of all commands
         * @param control   the control itself.
         * @param states    the current states of the control.
         * @returns {{}|null}
         */
        getCommands(control, states) {
            return null;
        }

        /**
         * Helper method to create an command object. will be used in the concrete subclasses.
         * @param name
         * @param cmd       either a string with the command or an object with cmds for each action (hit, release, hold, ..)
         * @param icon
         * @param [details]   Optional details object to pass more information to the object
         * @returns {{name: *, icon: *, cmd: *, details: *}}
         */
        createCommand(name, cmd, icon, details) {
            return {
                name: name,
                cmd: cmd,
                icon: icon,
                details: details
            };
        }

        /**
         * Helper method to create a command object that can be disabled
         * @param name
         * @param cmd
         * @param icon
         * @param disabled
         * @param details
         * @returns {{name: *, cmd: *, icon: *, disabled: *, details: *}}
         */
        createDeactivatable(name, cmd, icon, disabled, details) {
            return {
                name: name,
                cmd: cmd,
                icon: icon,
                disabled: disabled,
                details: details
            };
        }

        /**
         * Will return a selectable command. Meaning that here an option can be picked and therfore can also be
         * selected.
         * @param name
         * @param cmd
         * @param icon
         * @param isActive
         * @param details   Optional details object to pass more information to the object
         * @return {{name: *, cmd: *, icon: *, isActive: *, details: *}}
         */
        createSelectable(name, cmd, icon, isActive, details) {
            return {
                name: name,
                cmd: cmd,
                icon: icon,
                isActive: isActive,
                details: details
            };
        }

        /**
         * Helper method to create a cmd object that contains a cmd or multiple cmds for each action (hit, release, tap, pulse,..)
         * @param [hit]       sent when the user selects the button/cell and holds it (also provide release command!)
         * @param [release]   sent when the user releases the button/cell after a hit was sent. (also provide hit command!)
         * @param [tap]       sent if the user does not hold the cell/button, but only taps on it shortly. if not set, but hit+release is given, hit+release will be sent!
         * @param [doubleTap] sent when the user taps twice within a short time.
         * @param [tick]            sent each time a tick hits
         * @param [tickInterval]    defines how fast the tick cmd is repeated while the button is held, defaults to DEFAULT_TICK_INTERVAL
         */
        createCmdObj(hit, release, tap, doubleTap, tick, tickInterval) {
            return {
                hit: hit,
                release: release,
                tap: tap,
                doubleTap: doubleTap,
                tick: tick,
                tickInterval: tick ? tickInterval || DEFAULT_TICK_INTERVAL : false
            };
        }

    };
});
