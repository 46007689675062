'use strict';

define("UpDownLeftRightAnalogControlCommandSrc", ["UpDownLeftRightDigitalControlCommandSrc"], function (UpDownLeftRightDigitalControlCommandSrc) {
    return class UpDownLeftRightAnalogControlCommandSrc extends UpDownLeftRightDigitalControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        _getCmdObjForDirection(control, dir) {
            // Only add the tick (repetitive sending of a command) to the analog UpDownLeftRight control,
            // If the EIB Jalousie receives such a tick, it won't be able to make a long down- or upwards motion!
            return this.createCmdObj(control.getHitCommand(dir), control.getReleaseCommand(dir), control.getCommand(dir), // Tap command
                null, // Double Tab
                control.getCommand(dir), // Tick
                control.getCommandInterval && control.getCommandInterval() // Tick Interval
            );
        }

    };
});
