"use strict";

window.GUI = function (GUI) {
    var Template = function () {
        return {
            getTemplate: function getTemplate() {
                return $('<download-progress-bar>' + '<div class="text"></div>' + '<div class="spinner css-spinner-b css-spinner--small" ></div>' + ImageBox.getResourceImageWithClasses("resources/Images/General/close.svg", "close") + '</download-progress-bar>');
            }
        };
    }();

    function DownloadProgressBar() {
        this.element = Template.getTemplate();
    }

    DownloadProgressBar.prototype.updateData = function updateData(data) {
        if (data && data.total > 20000) {
            if (!this.closeTime || Date.now() - this.closeTime > 60000) {
                // only show every minute again
                if (!jQuery.contains(document, this.element[0])) {
                    $(document.body).append(this.element);
                    this.element.find(".close").on("click", function () {
                        this.element.remove();
                        this.closeTime = Date.now();
                    }.bind(this));
                }
            }

            if (data.estimated) {
                this.element.find(".text").text(_('download-prepared'));
            } else {
                var splitTexts = lxUnitConverter.convertSplitAndApply("%.0f kB", data.total / 1024);
                this.element.find(".text").text(_('downloading-data', {
                    size: splitTexts.valueTxt + " " + splitTexts.succTxt,
                    percent: data.percent
                }));
            }
        } else {
            this.element.remove();
        }
    };

    DownloadProgressBar.prototype.getElement = function getElement() {
        return this.element;
    };

    GUI.DownloadProgressBar = new DownloadProgressBar();
    return GUI;
}(window.GUI || {});
/*var active = false;
setInterval(function() {
    active = !active;
    GUI.DownloadProgressBar.updateData({
        total: active ? 2000000 : 1,
        percent: 24.5,
        estimated: false
    });
}, 2000);*/
