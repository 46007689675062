import { useState, useEffect } from 'react';

function useSystemStatusInfo(sourceUuids, filterFn) {
    const initState = {count: 0, color: null, allDataLoaded: false, hasActiveEntry: false},
        [statusInfo, setStatusInfo] = useState(initState);

    useEffect(() => {
        let unregFn;

        function handleMessagesUpdated(ev, entries, sourceUuids) {
            let filteredEntries = entries;
            if (filterFn) {
                filteredEntries = entries.filter(filterFn);
            }
            setStatusInfo({
                count: filteredEntries && filteredEntries.length ? filteredEntries.length : 0,
                color: filteredEntries && filteredEntries.length ? MessageCenterHelper.getColorForSeverityEntry(filteredEntries[0]) : null,
                allDataLoaded: true
            });
        }

        function handleAllMessagesUpdated(ev, messageCenterStructure) {
            let entries = (messageCenterStructure ? messageCenterStructure.entries || [] : []);
            let filteredEntries = entries;
            if (filterFn) {
                filteredEntries = entries.filter(filterFn);
            }
            let activeEntry = filteredEntries.find((entry) => {
                    return entry.entryUuid === messageCenterStructure.activeEntryUuid;
                }),
                unreadEntries = filteredEntries.filter((entry) => {
                    return !entry.readAt;
                });

            setStatusInfo({
                count: unreadEntries ? unreadEntries.length : 0,
                hasActiveEntry: !!activeEntry,
                color: activeEntry ? MessageCenterHelper.getColorForSeverityEntry(activeEntry) : null,
                allDataLoaded: true
            });
        }

        if (MessageCenterHelper.isAvailable()) {
            // fns stored as states will be called and the return value is stored (stateReducer) --> wrap in anonymous fn
            if (sourceUuids) {
                unregFn = ActiveMSComponent.registerSourceUuidForMessageCenterUpdate(handleMessagesUpdated, sourceUuids);
            } else {
                unregFn = ActiveMSComponent.registerForMessageCenterUpdate(handleAllMessagesUpdated);
            }
        } else {
            setStatusInfo(initState);
        }

        return () => {
            unregFn && unregFn();
            setStatusInfo(initState);
        };
    }, []);

    return statusInfo;
}

export default useSystemStatusInfo;
