'use strict';

define("SaunaControl", ["Control", "SaunaControlEnums", "LxComponents"], function (Control, SaunaControlEnums, {
    SaunaTempDisplay
}) {
    return class SaunaControl extends Control {
        constructor() {
            super(...arguments);
        }

        getLegacyScreens() {
            return Object.values(SaunaControlEnums.ScreenState)
        }

        getIcon() {
            return Icon.Sauna.CONTROL;
        }

        get reactIcon() {
            if (this.isGrouped()) {
                return null;
            }
            return SaunaTempDisplay;
        }

        specificCreateCmdText(cmdParts) {
            var text = null,
                cfg = {
                    name: this.name
                },
                value;

            switch (cmdParts[1]) {
                case "mode":
                    var modeID = parseInt(cmdParts[2]),
                        modes = SaunaControlEnums.SaunaModes;
                    Object.keys(modes).some(function (key) {
                        var found = false,
                            mode = modes[key];

                        if (mode.nr === modeID) {
                            cfg.argument0 = mode.name;
                            found = true;
                        }

                        return found;
                    });
                    text = _("cmdtext.sauna.mode", cfg);
                    break;

                case "temp":
                    value = parseInt(cmdParts[2]);
                    cfg.value = lxFormat("%.0f°", value);
                    text = _("cmdtext.sauna.temp", cfg);
                    break;

                case "humidity":
                    value = parseInt(cmdParts[2]);
                    cfg.value = lxFormat("%.0f%%", value);
                    text = _("cmdtext.sauna.humidity", cfg);
                    break;

                default:
                    var cmdKey = "cmdtext.sauna." + cmdParts[1];
                    text = _(cmdKey, cfg);
                    break;
            }

            return text;
        }

    };
});
