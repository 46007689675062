'use strict';

define(["OperatingModeEnums"], function (OperatingModeEnums) {
    return class OpModeScheduleDetailScreen extends GUI.TableViewScreenV2 {

        get useNewTableView() {
            return true;
        }

        constructor(details) {
            super($('<div class="op-mode-schedule-detail-screen" />')); // annoying if each time the screen reappears, e.g. after changing a mode, the keyboard jumps up.
            Object.assign(this, ContextMenuHandler.Mixin);

            this._shouldAutoFocusName = true; // store a reference to the original entry, this way we know if it did change.

            this.originalEntry = details.entry || {
                name: ""
            };

            if (details.entry) {
                // clone - we don't want to modify the original object. If we change it and hit cancel, we want it
                // to revert to its original values.
                this.entry = cloneObjectDeep(details.entry); // contains moment.js objects --> deep copy is needed.
            } else {
                // prepare new entry.
                this.entry = this._prepareNewEntry();

                this._autoFillMissingDateAttributes();
            }

            this.createContent();
        }

        viewWillDisappear() {
            this._shouldAutoFocusName = false;
            return super.viewWillDisappear(...arguments);
        }

        getPermissions() {
            return [{
                permission: MsPermission.OP_MODES
            }];
        }

        getURL() {
            return "OpMode-" + this.entry.name;
        }

        getAnimation() {
            return HD_APP ? AnimationType.HD_OVERLAY : this._isNewEntry() ? AnimationType.MODAL : AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarConfig() {
            var cfg = {};

            if (this._isNewEntry()) {
                cfg.rightSide = TitleBarCfg.Button.TICK;
            } else {
                cfg.leftSide = TitleBarCfg.Button.BACK;
            }

            return cfg;
        }

        titleBarText() {
            return this._isNewEntry() ? _("opmodes.new-entry") : this.entry.name;
        }

        titleBarAction() {
            Debug.OperatingMode && console.log(this.name, "titleBarAction"); // the left action is used to save changes to existing entries and to cancel creating a new one.

            if (this._isNewEntry()) {
                this.ViewController.navigateBack(false, {
                    update: true
                });
            } else {
                this._saveEntry();
            }
        }

        titleBarActionRight() {
            // tick on the right side means save a new retry.
            this._saveEntry();
        }

        createContent() {
            let section = {
                rows: []
            };
            this.tableContent = []; // Name

            if (!this.entry.isHeatCool) {
                section.rows.push({
                    type: GUI.TableViewV2.CellType.INPUT,
                    content: {
                        title: _("opmode.entry-name"),
                        placeholder: _("opmode.entry-name"),
                        autoFocus: this._isNewEntry() && this._shouldAutoFocusName,
                        value: this.entry.name,
                        validationRegex: Regex.CMD_TEXT,
                        filterRegex: Regex.CMD_TEXT_FILTER
                    },
                    textChanged: function textChanged(section, row, tableView, value, valid, valueDidChange) {
                        if (valid) {
                            this.entry.name = value;
                        } else {
                            // Set the original name if the new name (value) is invalid, or empty in this case
                            this.entry.name = this.originalEntry.name;
                        }
                    }.bind(this),
                    submitText: function submitText(section, row, tableView, value, valid) {
                        if (valid) {
                            this.entry.name = value;
                        } else {
                            // Set the original name if the new name (value) is invalid, or empty in this case
                            this.entry.name = this.originalEntry.name;
                        }
                    }.bind(this)
                })
            } // Operating Mode


            section.rows.push(this._createOpModeCell()); // Time selection
            // as the calMode might have changed - some attributes could be missing.

            this._autoFillMissingDateAttributes();

            section.rows = section.rows.concat(this._createTimeSelection());// Deleting an entry

            this.tableContent.push(section);

            if (!!this.entry.isDeletable) {
                // this flag is set in sanitizeEntries, so for new entries, it isn't true
                this.tableContent.push({
                    rows: [this._createDeleteCell()]
                });
            }
        }

        /**
         * Will return a section allowing to pick a date (either a period, a specific date or a weekday)
         * @returns {{rows: Array}}
         * @private
         */
        _createTimeSelection() {
            var rows = [],
                footerTitle;
            rows.push(this._createCalendarModeCell());

            switch (this.entry.calMode) {
                case OperatingModeEnums.CalendarMode.YEARLY:
                    rows.push(this._createDatePicker(this.entry.startObj, false, false, false));
                    break;

                case OperatingModeEnums.CalendarMode.SPAN_EACH_YEAR:
                    rows.push(this._createDatePicker(this.entry.startObj, true, true, false));
                    rows.push(this._createDatePicker(this.entry.endObj, true, false, false));
                    break;

                case OperatingModeEnums.CalendarMode.SPAN_THIS_YEAR:
                    rows.push(this._createDatePicker(this.entry.startObj, true, true, true));
                    rows.push(this._createDatePicker(this.entry.endObj, true, false, true));
                    break;

                case OperatingModeEnums.CalendarMode.EASTER:
                    // Mark every easter till 2 years in the future
                    var now = moment();
                    var marked = [{
                        d: LxDate.easter(now.year())._d,
                        color: window.Styles.colors.purple
                    }, {
                        d: LxDate.easter(now.add(1, "years").year())._d,
                        color: window.Styles.colors.purple
                    }, {
                        d: LxDate.easter(now.add(1, "years").year())._d,
                        color: window.Styles.colors.purple
                    }];
                    footerTitle = _("opmodes.easter-cover.explanation"); // the year is important as it specifies if the current or next year is meant.

                    rows.push(this._createDatePicker(this.entry.startObj, false, false, true, marked));
                    break;

                case OperatingModeEnums.CalendarMode.SPECIFIC:
                    rows.push(this._createDatePicker(this.entry.startObj, false, false, true));
                    break;

                case OperatingModeEnums.CalendarMode.WEEKDAY:
                    rows = rows.concat(this._createWeekdayCells());
                    break;

                default:
                    break;
            }

            return rows
        }

        /**
         * Will return a cell displaying the currently picked OP mode. it will also take care of displaying a
         * selector screen when a different op mode is to be picked.
         * @returns {{type: (string|string|string), content: {title, disclosureIcon: boolean, disclosureText: *}, action: (function(this:_createOpModeCell))}}
         * @private
         */
        _createOpModeCell() {
            var options = this._getOpModeOptions();

            var result = {
                type: GUI.TableViewV2.CellType.GENERAL,
                content: {
                    title: _("opmode.title"),
                    disclosureText: this.entry.operatingMode.name
                }
            };

            if (this.entry.isHeatCool) {
                // these entries' opmode cannot be changed!
                result.content.disabled = true;
            } else {
                result.content.disclosureIcon = true;
                result.action = this._showEntrySelector.bind(this, _("opmode.title"), options, function (res) {
                        this.entry.operatingMode = {
                            nr: parseInt(res.option.nr),
                            name: res.option.title
                        };
                    }.bind(this), false // don't leave it empty, otherwise the cell from didSelectCell is passed along instead!
                );
            }

            return result;
        }

        /**
         * Will return an array full of potential operating modes. the one who is currently selected has its
         * flag set.
         * @returns {Array}
         * @private
         */
        _getOpModeOptions() {
            var result = [],
                iKey,
                opModes = ActiveMSComponent.getStructureManager().getOperatingModes(true),
                selectedNr = this.entry.operatingMode.nr; // iterate over all opMode keys

            Object.keys(opModes).forEach(function (key) {
                iKey = parseInt(key);
                if (key < OperatingModeEnums.OperatingMode.MONDAY || key > OperatingModeEnums.OperatingMode.SUNDAY) {
                    // avoid presenting weekdays.
                    result.push({
                        nr: key,
                        title: opModes[key],
                        selected: iKey === selectedNr
                    });
                }
            });
            return result;
        }

        /**
         * Will return a cell that shows waht calendar mode is currently selected and also takes care of opening
         * a selector view to pick a different calendar mode.
         * @returns {*|{type: (string|string|string), content: {title: *, disclosureIcon: boolean, disclosureText}, action: (function(this:_createSelectorCell))}}
         * @private
         */
        _createCalendarModeCell() {
            var cell,
                options = [{
                    title: _("opmodes.yearly")
                }, {
                    title: _("opmodes.easter-cover")
                }, {
                    title: _("opmodes.specific")
                }, {
                    title: _("opmodes.span-this-year")
                }, {
                    title: _("opmodes.span-each-year")
                }, {
                    title: _("opmodes.weekday")
                }];

            if (this.entry.isHeatCool) {
                // heating and cooling periods must have timespan each year as calendar mode.
                cell = {
                    type: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        title: _("opmode.calmode.title"),
                        disclosureText: options[this.entry.calMode].title,
                        disabled: true
                    }
                };
            } else {
                cell = this._createSelectorCell(_("opmode.calmode.title"), options, "calMode");
            }

            return cell;
        }

        /**
         * Will return a specific picker that allows to pick a certain day/month. Optionally with a specific year.
         * @param value     the current selected value
         * @param isSpan    if true, it means that a timespan is to be selected with start and end.
         * @param isStart   if isSpan is true, this flag indicates if this cell is used as start or end.
         * @param withYear  if true, the year can be specified. If false, it isn't used.
         * @param marked    array of marked days -> http://docs.mobiscroll.com/2-17-0/jquery/calendar#!opt-marked
         * @private
         */
        _createDatePicker(value, isSpan, isStart, withYear, marked) {
            var title;

            if (isSpan) {
                if (isStart) {
                    title = _("controls.daytimer.entry.beginning");
                } else {
                    title = _("controls.daytimer.entry.end");
                }
            } else if (withYear) {
                title = _("mobiscroll.date");
            } else {
                title = _("dateTime.day");
            }

            var content = {
                title: title,
                value: cloneObjectDeep(value),
                marked: marked
            };

            if (!withYear) {
                content.minDate = moment().startOf('year').toDate();
                content.maxDate = moment().endOf('year').toDate();
                content.hideTimeAndYear = true;
            }

            return {
                type: withYear ? GUI.TableViewV2.CellType.DATE_PICKER : GUI.TableViewV2.CellType.DATE_TIME_PICKER,
                content: content,

                /**
                 * Called when the date picker change.
                 * @param value
                 * @param section
                 * @param row
                 * @param tableView
                 */
                onPickerChanged: (section, row, tableView, value) => {
                    Debug.OperatingMode && console.log(this.name, "onPickerChanged");

                    if (isStart || !isSpan) {
                        Debug.OperatingMode && console.log("     start or date picked! " + value.format(DateType.Date));
                        this.entry.startObj = value; // ensure the end date is always after the start date.

                        if (!this._isYearlyRepeating() && // NOT if it is repeating
                            this.entry.endObj && this.entry.startObj.isAfter(this.entry.endObj)) {
                            this.entry.endObj = cloneObjectDeep(this.entry.startObj).add(1, "days");
                        }
                    } else {
                        Debug.OperatingMode && console.log("     end of timespan picked! " + value.format(DateType.Date));
                        this.entry.endObj = value; // ensure the start date is always before the end date.

                        if (!this._isYearlyRepeating() && // NOT if it is repeating
                            this.entry.endObj.isBefore(this.entry.startObj)) {
                            this.entry.startObj = cloneObjectDeep(this.entry.endObj).add(-1, "days");
                        }
                    }
                }
            };
        }

        /**
         * Prepares the cells that specify how an schedule depending on weekdays work.
         * @returns {Array} the array of cells for picking a weekday dependant entry.
         * @private
         */
        _createWeekdayCells() {
            var cells = [],
                options; // day x

            options = [{
                title: _("opmodes.weekday.every.short")
            }, // 0
                {
                    title: _("opmodes.weekday.first.short")
                }, // 1
                {
                    title: _("opmodes.weekday.second.short")
                }, // 2
                {
                    title: _("opmodes.weekday.third.short")
                }, // 3
                {
                    title: _("opmodes.weekday.fourth.short")
                }, // 4
                {
                    title: _("opmodes.weekday.last.short")
                } // 5
            ];
            cells.push(this._createSelectorCell(_("opmodes.weekday.dayx"), options, 'weekDayInMonth')); // weekday

            options = [{
                title: _("mobiscroll.monday")
            }, // 0
                {
                    title: _("mobiscroll.tuesday")
                }, // 1
                {
                    title: _("mobiscroll.wednesday")
                }, // 2
                {
                    title: _("mobiscroll.thursday")
                }, // 3
                {
                    title: _("mobiscroll.friday")
                }, // 4
                {
                    title: _("mobiscroll.saturday")
                }, // 5
                {
                    title: _("mobiscroll.sunday")
                } // 6
            ];
            cells.push(this._createSelectorCell(_("times.270"), options, 'weekDay')); // month --> pass in value as they don't start with 0

            options = [{
                title: _("mobiscroll.january"),
                value: 1
            }, {
                title: _("mobiscroll.february"),
                value: 2
            }, {
                title: _("mobiscroll.march"),
                value: 3
            }, {
                title: _("mobiscroll.april"),
                value: 4
            }, {
                title: _("mobiscroll.may"),
                value: 5
            }, {
                title: _("mobiscroll.june"),
                value: 6
            }, {
                title: _("mobiscroll.july"),
                value: 7
            }, {
                title: _("mobiscroll.august"),
                value: 8
            }, {
                title: _("mobiscroll.september"),
                value: 9
            }, {
                title: _("mobiscroll.october"),
                value: 10
            }, {
                title: _("mobiscroll.november"),
                value: 11
            }, {
                title: _("mobiscroll.december"),
                value: 12
            }];

            if (Feature.REPEAT_OP_MODE_MONTHLY) {
                options.push({
                    title: _("opmodes.weekday.every-month"),
                    value: OperatingModeEnums.WeekdayEveryMonth
                });
            }

            cells.push(this._createSelectorCell(_("mobiscroll.month"), options, 'startMonth'));
            return cells;
        }

        /**
         * Will return a selector cell that has everything ready to pick an attribute from the options provided.
         * IMPORTANT: this only works if the options index equals the attribute value (0 = monday, attribute
         * weekDay: 0 = monday). It does not work on operating modes (IDX != the attribute value)
         * @param title         the title of both the cell and the selector window
         * @param options       an array of options. Either each option has a value attribute, or the position
         *                      inside the array represents the value.
         * @param attribute     the key of the attribute. Used to pick & update the entries currently selected option.
         * @returns {{type: (string|string|string), content: {title: *, disclosureIcon: boolean, disclosureText}, action: (function(this:_createSelectorCell))}}
         * @private
         */
        _createSelectorCell(title, options, attribute) {
            var selectedOption = this.entry[attribute]; // try to find the index of the currently selected option by looking at its value.

            for (var i = 0; i < options.length; i++) {
                if (options[i].hasOwnProperty("value") && options[i].value === this.entry[attribute]) {
                    selectedOption = i;
                    break;
                }
            } // fallback if the option provided by the entry cannot be selected.


            if (!options[selectedOption]) {
                console.error("Could not find the option that should have been selected: " + selectedOption);
                console.error("Options: " + JSON.stringify(options));
                console.error("Selecting the first option.");
                this.entry[attribute] = 0;
                selectedOption = this.entry[attribute];
            }

            return {
                type: GUI.TableViewV2.CellType.GENERAL,
                content: {
                    title: title,
                    // == weekday
                    disclosureIcon: true,
                    disclosureText: options[selectedOption].title
                },
                action: this._showEntrySelector.bind(this, title, options, function (res) {
                    // check if the result contains a value attribute. If so, assign it. Otherwise the row
                    // should be equal the value.
                    if (res.option.hasOwnProperty("value")) {
                        this.entry[attribute] = res.option.value;
                    } else {
                        this.entry[attribute] = res.row;
                    }
                }.bind(this), selectedOption)
            };
        }

        /**
         * Returns a cell that allows to delete
         * @returns {{type: (string|string|string), content: {title, disclosureIcon: boolean, rightIconSrc: string, rightIconColor: string}, action: (function(this:_createDeleteCell))}}
         * @private
         */
        _createDeleteCell() {
            return {
                type: GUI.TableViewV2.CellType.DELETE,
                content: {
                    title: _("opmode.entry.delete")
                },
                action: function () {
                    var content = {
                        title: _("opmode.entry.delete"),
                        message: _("opmode.entry.delete.confirmation", {
                            name: this.entry.name
                        }),
                        buttonOk: _("yes"),
                        buttonCancel: _("no"),
                        icon: Icon.DELETE,
                        color: window.Styles.colors.red
                    };
                    NavigationComp.showPopup(content).then(this._deleteEntry.bind(this));
                }.bind(this)
            };
        }

        /**
         * Will trigger that an entry is being deleted, including handling information popups and dismissing the
         * view.
         * @private
         */
        _deleteEntry() {
            var prms = ActiveMSComponent.deleteOpModeEntry(this.entry),
                text = _("opmode.entry.deleting"),
                error = _("opmode.entry.deleting.error");

            this._handleEntryModification(prms, text, error);
        }

        /**
         * Will save the entry - meaning it will save changes or create a new entry. It takes care of displaying
         * a popup, responding to errors and dismissing the view.
         * @private
         */
        _saveEntry() {
            if (this.entry.name === "") {
                this.entry.name = this.entry.operatingMode.name.regexFilter(Regex.CMD_TEXT_FILTER);
            }

            if (this._didModifyEntry()) {
                var prms = ActiveMSComponent.saveOpModeEntry({...this.entry, name: this.entry.name.regexFilter(Regex.CMD_TEXT_FILTER)}),
                    text = _("opmode.entry.saving"),
                    error = _("opmode.entry.saving.error");

                this._handleEntryModification(prms, text, error);
            } else {
                Debug.OperatingMode && console.log("Entry remains unchanged, don't save it!");
                this.ViewController.navigateBack();
            }
        }

        /**
         * Detects whether or not an entry has modified.
         * @returns {boolean} true if the entry was modified
         * @private
         */
        _didModifyEntry() {
            var modified = JSON.stringify(this.originalEntry) !== JSON.stringify(this.entry);
            Debug.OperatingMode && console.log(this.name, "_didModifyEntry: " + modified);
            return modified;
        }

        /**
         * Will present a popup while the entry is being modified (created/updated/deleted). If the operation
         * fails, it will present an error and close the view. When the operation is successful it will remove
         * the popup, dismiss the view and inform the "parent" view to reload it's data.
         * @param promise   the modification promise. It resolves if successful and rejects if an error occurs.
         * @param text      the text to show while the entry is being modified
         * @param error     the error message to show if something went wrong.
         * @private
         */
        _handleEntryModification(promise, text, error) {
            NavigationComp.showWaitingFor(promise, text, null, _("hide-window")).then(function () {
                this.ViewController.navigateBack(false, {
                    updated: true
                });
            }.bind(this), function (err) {
                // only show an error if the user did not press "hide".
                if (err !== GUI.PopupBase.ButtonType.CANCEL) {
                    NavigationComp.showPopup({
                        title: _("error"),
                        color: window.Styles.colors.red,
                        icon: Icon.ERROR,
                        message: error,
                        buttonOk: true
                    });
                }

                this.ViewController.navigateBack();
            }.bind(this));
        }

        /**
         *
         * @param title
         * @param options
         * @param updFn
         * @param [selectedIdx] optional - only provided if the selected option still needs to be provided.
         * @private
         */
        _showEntrySelector(title, options, updFn, selectedIdx) {
            var defer = Q.defer(),
                details = {
                    options: options,
                    title: title,
                    mode: GUI.SelectorScreenMode.QUICK,
                    deferred: defer
                },
                animationType = HD_APP ? AnimationType.HD_OVERLAY : AnimationType.PUSH_OVERLAP_LEFT;

            if (selectedIdx || selectedIdx === 0) {
                details.options[selectedIdx].selected = true;
            }

            this.ViewController.showState(ScreenState.SelectorScreen, null, details, animationType);
            defer.promise.done(function (result) {
                updFn(result[0]);
                this.createContent();
                this.reloadTable();
            }.bind(this), function (err) {// nothing to do
            });
        }

        /**
         * Will assign useful default values to a new entry object.
         * It will not touch the date attributes. They are being set in a different method.
         * @returns {{name, calMode: number, operatingMode: {name: *, nr: number}, startYear: number, startMonth: number, startDay: number}}
         * @private
         */
        _prepareNewEntry() {
            var defModeNr = OperatingModeEnums.OperatingMode.HOLIDAY,
                defMode = ActiveMSComponent.getStructureManager().getOperatingModes(true)[defModeNr],
                entry = {
                    name: "",
                    calMode: OperatingModeEnums.CalendarMode.SPECIFIC,
                    operatingMode: {
                        name: defMode,
                        nr: defModeNr
                    },
                    isDeletable: false
                };
            return entry;
        }

        /**
         * Will return true if the current entry is a newly created one.
         * @returns {boolean}
         * @private
         */
        _isNewEntry() {
            return !this.entry.hasOwnProperty("uuid");
        }

        /**
         * Checks the date attributes for the current calMode and fills them with proper initial values if missing.
         * @private
         */
        _autoFillMissingDateAttributes() {
            var today = ActiveMSComponent.getMiniserverTimeInfo();

            if (this.entry.calMode === OperatingModeEnums.CalendarMode.WEEKDAY) {
                this._autoFillMissingWeekdayAttributes(today);
            } else {
                // don't overwrite an existing startObj.
                this.entry.startObj = this.entry.startObj || today;

                if (this.entry.calMode === OperatingModeEnums.CalendarMode.SPAN_THIS_YEAR || this.entry.calMode === OperatingModeEnums.CalendarMode.SPAN_EACH_YEAR) {
                    // end date --> 5 days from now if choosing a timespan - don't overwrite an existing one.
                    this.entry.endObj = this.entry.endObj || cloneObjectDeep(this.entry.startObj).add(5, "days");
                }
            }
        }

        /**
         * Will look up sensible weekday default attributes for this entry based on the date provided
         * @param date      the date passed in to look up sensible default attributes.
         * @private
         */
        _autoFillMissingWeekdayAttributes(date) {
            // if a weekday is chosen, ensure the values have not been predefined yet, if not - set defaults.
            var configured = this.entry.hasOwnProperty("weekDay") && this.entry.hasOwnProperty("weekDayInMonth") && this.entry.hasOwnProperty("startMonth");

            if (!configured) {
                // moment returns 0 for sunday, 1 for monday .. and 6 for saturday. But in our API we're using
                // 0 for monday, 1 for tuesday ... 5 for saturday and 6 for sunday --> adopt it.
                var wd = date.day();
                this.entry.weekDay = (wd + 6) % 7; //(e.g. sunday = 0 + 6 = 6 % 7 = 6, monday = 1 + 6 = 7 % 7 = 0)
                // repeat at every first monthly occurrence

                this.entry.weekDayInMonth = OperatingModeEnums.WeekdayRepeatMode.FIRST; // each month of the year.

                this.entry.startMonth = date.month() + 1;
            }
        }

        /**
         * checks if the current entry is repeating (either yearly or span each year)
         * @returns {boolean}
         * @private
         */
        _isYearlyRepeating() {
            return this.entry.calMode === OperatingModeEnums.CalendarMode.YEARLY || this.entry.calMode === OperatingModeEnums.CalendarMode.SPAN_EACH_YEAR;
        }

    };
});
