import { useState, useEffect, useMemo, useRef } from 'react';
import { useConnectionReady } from "LxComponents";
import {EfmViewType} from "../efmUtilities";
import globalStyles from "GlobalStyles";
import {useIsFocused} from "@react-navigation/native";

const createNodeState = (node, val, oldState, color, error) => {
    let newStateValue = { stateValueMap: val };
    let newStateText = { stateTextMap: node.formatNodeValue(val) };
    let newNodeColor = color ? { stateColor: color } : { stateColor: Color.TEXT_INACTIVE_B };
    let newError = error ? { error: error } : {};
    let newNodeState = {...oldState, ...newStateValue, ...newStateText, ...newNodeColor, ...newError};
    return newNodeState;
}

function useNodesValueUpdate(nodes, passedViewType, unixUtcTs) {
    const viewType = useMemo(() => {
        if(
            passedViewType === EfmViewType.Actual &&
            moment(unixUtcTs * 1000).isBefore(ActiveMSComponent.getMiniserverUnixUtcTimestamp() * 1000, 'day')
        ) {
            return EfmViewType.Day;
        }
        return passedViewType;
    }, [passedViewType, unixUtcTs]);
    const [nodeStates, setNodeStates] = useState({});
    const connectionReady = useConnectionReady();
    const isFocused = useIsFocused();

    const [lastRelevantTs, setLastRelevantTs] = useState(unixUtcTs);
    const tsAsDependency = useMemo(() => {
        // Never need to update for actual
        // Actual will be updated through the listeners only, since a user selected ts is not possible there
        if(viewType === EfmViewType.Actual) {return lastRelevantTs;}

        // Any jump in unixUtcTs from manual interaction is at least an hour
        if(Math.abs(lastRelevantTs - unixUtcTs) <= 3600) {
            return lastRelevantTs;
        } else {
            setLastRelevantTs(unixUtcTs);
            return unixUtcTs;
        }
    }, [unixUtcTs]);

    const tmpNodeStates = useRef({}); // takes care of collecting the updates
    const debounceTimeout = useRef(null); // takes care of reducing the update rate.
    const collectTimeout = useRef(null); // takes care that several changes are dispatched together
    const shouldDispatchAgain = useRef(false);
    const dispatchWithDebounce = () => {
        if (debounceTimeout.current) {
            // dispatched shortly before, wait before dispatching again.
            shouldDispatchAgain.current = true;
        } else {
            // no debounce timeout active, dispatch right away!
            setNodeStates((previousNodeState) => {
                return {
                    ...previousNodeState, ...tmpNodeStates.current
                }});

            // start a debounce timeout, so for the 500 timeout milliseconds, no further callback is performed.
            debounceTimeout.current = setTimeout(() => {
                // if a debounceCallback is scheduled, perform it! (null the ref first, to enable recursive usage)
                debounceTimeout.current = null;
                shouldDispatchAgain.current && dispatchWithDebounce()
                shouldDispatchAgain.current = false;
            }, 500);
        }
    }

    useEffect(() => {
        if(!Array.isArray(nodes) || nodes.length === 0) {
            return;
        }

        let listeners = [],
            prms = [];

        if (connectionReady && isFocused) {
            tmpNodeStates.current = {};

            nodes.forEach(node => {

                function nodeValueError(err) {
                    const oldState = nodeStates[node._uuid] || {};
                    tmpNodeStates.current[node._uuid] = createNodeState(node, {}, oldState, globalStyles.colors.red, err)
                    if (!collectTimeout.current) { // use collection for errors as well
                        collectTimeout.current = setTimeout(() => {
                            collectTimeout.current = null;
                            dispatchWithDebounce();
                        }, 40);
                    }
                }

                function nodeValueReceived(nodeVal) {
                    const oldState = nodeStates[node._uuid] || {};
                    tmpNodeStates.current[node._uuid] = createNodeState(node, nodeVal, oldState, null)
                    // the collect timeout ensures that several updates within short time are collected together
                    if (!collectTimeout.current) {
                        collectTimeout.current = setTimeout(() => {
                            collectTimeout.current = null;
                            dispatchWithDebounce();
                        }, 40);
                    } // dispatch already scheduled.
                }

                // initial request
                try { // blackscreen if efm is resumed via last-location in a deeper state. nodes don't have functions anymore.
                    let nodePrms = node.getNodeValue(viewType, unixUtcTs);
                    nodePrms.then(nodeValueReceived, nodeValueError);
                    prms.push(nodePrms);

                    if (viewType === EfmViewType.Actual) {
                        listeners.push(node.registerForActualNodeValueUpdates(nodeValueReceived));
                    } else {
                        listeners.push(node.registerForTotalNodeValueUpdates(nodeValueReceived, viewType, unixUtcTs));
                    }
                } catch (ex) {
                    console.error("Failed during useNodesValueUpdate - " + ex.message);
                    console.error(ex);
                }
            });
        }

        return () => {
            prms.forEach(prm => prm.cancel());
            listeners.forEach(clearListener => clearListener());
            debounceTimeout.current && clearTimeout(debounceTimeout.current);
            debounceTimeout.current = null;
            collectTimeout.current && clearTimeout(collectTimeout.current);
            collectTimeout.current = null;
        };
    }, [viewType, tsAsDependency, connectionReady, isFocused, unixUtcTs]);

    return nodeStates;
}

export default useNodesValueUpdate;
