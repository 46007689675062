import PropTypes from "prop-types";
import {useContext, useMemo, useEffect} from "react";
import {TrustContext} from "./trustContextProvider";
import globalStyles from "GlobalStyles";
import {
    LxBackgroundComp,
    LxReactTableView,
    LxReactPressable,
    LxReactWaitingView,
    LxReactBadge,
    LxReactBadgeType,
    navigatorConfig,
    AmbientContext,
    useBackNavigation
} from "LxComponents";
import TabHeader from "../../../react-comps/tabHeader";
import {ScrollView, View} from "react-native";
import Icons from "IconLib";

function TrustListScreen({navigation, route}) {
    const {structurePath} = {...(route.params || { structurePath: [] })}
    const structure = useContext(TrustContext)
    const { isAmbientMode } = useContext(AmbientContext)

    useBackNavigation(() => {
        navigation.goBack();
    }, []);

    // ensure that when changing to other tabs or tapping on the trust tab again, it's reset to the root level
    useEffect(() => {
        const unsubscribeBlur = navigation.getParent().addListener("tabPress", () => {
            let navigationState = navigation.getState();
            if (navigationState.index !== 0 || (navigationState.index === 0 && navigationState.routes.length === 1)) {
                navigation.reset({
                    index: 0,
                    routeNames: [ScreenState.TrustListScreen],
                    routes: [{
                        name: ScreenState.TrustListScreen,
                        params: null
                    }],
                    params: {}
                });
            }
        });
        return () => {
            unsubscribeBlur();
        };
    }, []);

    /**
     * Ensures that there is a title to be shown in the list.
     * @param entry
     * @returns {string}
     */
    const titleForEntry = (entry) => {
        let name = "-unknown-";
        if (nullEmptyString(entry.name)) {
            name = entry.name;
        } else {
            switch (entry.type) {
                case TrustListType.MINISERVER:
                    name = entry.serialNr || "Miniserver";
                    break;
                case TrustListType.GROUP:
                    name = "Group";
                    break;
                case TrustListType.TRUST:
                    name = "Trust";
                    break;
                default:
                    break;
            }
        }
        return name;
    }

    /**
     * Ensures that there's always a one or two character badge text available. Turns out that the name attribute is
     * missing occasionally.
     * @param entry
     * @returns {string}
     */
    const textForBadge = (entry) => {
        let badgeId = "LX";
        if (nullEmptyString(entry.name)) {
            badgeId = entry.name.substr(0, Math.min(2, entry.name.length));
        } else {
            switch (entry.type) {
                case TrustListType.MINISERVER:
                    // use the last two characters of the serialNr if available
                    if (nullEmptyString(entry.serialNr) && entry.serialNr.length >= 2) {
                        const length = entry.serialNr.length;
                        badgeId = entry.serialNr.substr(length - 2, 2);
                    } else {
                        badgeId = "MS";
                    }
                    break;
                case TrustListType.GROUP:
                    badgeId = "GR";
                    break;
                default:
                    break;
            }
        }
        return badgeId.toUpperCase();
    }

    const leftContentForEntry = (entry) => {
        let left = null;
        if (entry.type === TrustListType.TRUST) {
            left = {
                comp: Icons.Trust,
                props: {
                    fill: globalStyles.colors.stateActive,
                    width: 40,
                    height: 40
                }
            }
        } else if (entry.type === TrustListType.MINISERVER) {
            left = {
                comp: LxReactBadge,
                props: {
                    text: textForBadge(entry),
                    textStyle: globalStyles.textStyles.body.bold,
                    backgroundColor: entry.updateRequired ? globalStyles.colors.tabBarGrey : globalStyles.colors.brand,
                    size: 40,
                    badgeType: LxReactBadgeType.ROUND
                }
            }
        } else {
            left = {
                comp: LxReactBadge,
                props: {
                    text: textForBadge(entry),
                    textStyle: globalStyles.textStyles.body.bold,
                    backgroundColor: globalStyles.colors.brand,
                    size: 40,
                    badgeType: LxReactBadgeType.SQUARE
                }
            }
        }
        return left;
    }

    const rightContentForEntry = (entry) => {
        let right = null;
        if (entry.type === TrustListType.MINISERVER) {
            right = {
                comp: LxReactPressable,
                props: {
                    key: entry.serialNr + "info",
                    onPress: () => handleAboutMsTapped(entry),
                    children: <Icons.InfoCircled width={24} height={24} fill={globalStyles.colors.brand} key={entry.serialNr + "-info-icon"}/>
                }
            }
        }
        return right;
    }

    const rowForEntry = (entry, idx) => {
        const isGroup = entry.type !== TrustListType.MINISERVER;

        return {
            title: titleForEntry(entry),
            mainLeftContent: leftContentForEntry(entry),
            mainLeftStyle: {
                width: 40,
                height: 40
            },
            disclosureIcon: isGroup,
            subTitle: entry.location,
            key: entry.serialNr || entry.name,
            onPress: () => {
                isGroup ?
                    handleGroupTapped(entry, idx) :
                    handleMsTapped(entry, idx);
            },
            mainRightContent: rightContentForEntry(entry)
        }
    }

    const handleGroupTapped = (group, idx) => {
        console.log(TrustListScreen.name, "handleGroupTapped: " + group.name + "@" + idx);
        navigation.push(TrustListScreen.name, {
            structurePath: [...structurePath, idx],
        })
    }

    const handleMsTapped = (ms, idx) => {
        console.log(TrustListScreen.name, "handleMsTapped: " + ms.name + "@" + idx);
        ActiveMSComponent.switchToTrustedMiniserver(ms);
    }

    const handleAboutMsTapped = (ms) => {
        console.log(TrustListScreen.name, "handleAboutMsTapped: " + ms.name);
        ActiveMSComponent.showTrustedMiniserverInfo(ms);
    }

    const displayGroup = useMemo(() => {
        let group = structure;
        if (group && group.items) {
            for (var i = 0; i < structurePath.length && !!group && !!group.items; i++) {
                group = group.items[structurePath[i]];
            }
        }
        return {...group};
    }, [structure, structurePath]);

    useEffect(() => {
        if (isAmbientMode) {
            navigation.setOptions({
                ...navigatorConfig({
                    title: displayGroup.name,
                    isAmbientMode,
                })
            })
        }
    }, [displayGroup, isAmbientMode])

    const tableContent = useMemo(() => {
        return [
            {
                rows: displayGroup.items ? displayGroup.items.map(rowForEntry) : []
            }
        ]
    }, [ displayGroup ]);

    if (!structure || structure.version === -1) {
        return <LxReactWaitingView />
    } else if (!displayGroup.items || displayGroup.items.length === 0) {
        return <LxBackgroundComp
            icon={<Icons.Trust width={45} height={45} fill={globalStyles.colors.brand}/>}
            title={displayGroup.name}
            message={
            displayGroup.isTrust ?
                "You don't have App Access rights to Miniservers within this Trust/Group."
                : "You don't have App Access rights."
        }
        />;
    } else {
        return (
            <View style={styles.rootCntr}>
                <ScrollView stlye={styles.container}>
                    {isAmbientMode ? null : <TabHeader title={displayGroup.name} />}
                    <LxReactTableView tableContent={tableContent} />
                </ScrollView>
            </View>
        )

    }
}

const styles = {
    rootCntr: {
        flex: 1,
        alignSelf: "center",
        maxWidth: 1280,
        width: "100%"
    },
    container:  {
        flex: 1,
        overflow: "scroll",
        alignSelf: "center",
        maxWidth: 1280,
        width: "100%",
    }
}

TrustListScreen.propTypes = {
   structurePath: PropTypes.arrayOf(PropTypes.number)
}

export default TrustListScreen;
