'use strict';

define(['ColorPickerColorSelection', 'ColorPickerControlEnums'], function (ColorPickerColorSelection, ColorPickerControlEnums) {
    return class ColorPickerControlContent extends Controls.ControlContent {
        constructor(details) {
            super(details);
        }

        viewDidLoad() {
            var prmsArr = [true];
            return Q(super.viewDidLoad(...arguments)).then(function () {
                this.isColorPickerControl = this.control.type === ControlType.COLOR_PICKER;
                this.colorSelection = new ColorPickerColorSelection(false, this.control);
                this.colorSelection.sendColorObject = this._sendColorObject;
                prmsArr.push(this.appendSubview(this.colorSelection));
                this.colorSelection.sendCommand = this.sendCommand;
                this.tableView = new GUI.TableViewV2(this, this);
                this.tableView.getElement().addClass("color-picker-control-content__table-view");
                prmsArr.push(this.appendSubview(this.tableView));
                return Q.all(prmsArr);
            }.bind(this));
        }

        titleBarText() {
            return this.control.name;
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK
            };
        }

        receivedStates(states) {
            this.favorites = states.favoriteColors;

            if (!this.colorObject) {
                this.colorObject = states.colorObject;
                this.tableView.reload();
            } else {
                this.colorObject = states.colorObject;
                this.tableView.cellForSectionAndRow(0, 0).setPreviewColor(this.colorObject);
                this.tableView.cellForSectionAndRow(0, 1).setActiveColor(this.colorObject);
                this.tableView.cellForSectionAndRow(0, 1).setColorsArray(this.favorites);
            }

            this.colorSelection.update(states);
        }

        // TableViewDataSource methods
        styleForTableView() {
            return TableViewStyle.GROUPED;
        }

        numberOfSections() {
            return 1;
        }

        numberOfRowsInSection() {
            return 2;
        }

        cellTypeForCellAtIndex(section, row) {
            if (row === 0) {
                return GUI.TableViewV2.CellType.Special.COLOR_PICKER_BRIGHTNESS;
            } else if (row === 1) {
                return GUI.TableViewV2.CellType.Special.COLOR_PICKER_FAVORITES;
            }
        }

        contentForCell(cell, section, row) {
            if (row === 0) {
                return {
                    title: _("on-off"),
                    previewColor: this.colorObject,
                    minIconSrc: "resources/Images/Controls/LightsceneRGB/icon-brightness_down.svg",
                    maxIconSrc: "resources/Images/Controls/LightsceneRGB/icon-brightness_up.svg",
                    step: 5
                };
            } else if (row === 1) {
                return {
                    activeColor: this.colorObject,
                    colorsArray: this.favorites,
                    changingAllowed: true
                };
            }
        }

        // TableViewDelegate methods
        onSwitchChanged(isOn) {
            this.sendCommand(isOn ? Commands.COLOR_PICKER.ON : Commands.COLOR_PICKER.OFF);
        }

        sliderDragged(cell, section, row, tableView, value, isDragging) {
            this._sendColorObject(this.colorObject, value, isDragging);
        }

        sliderClicked(cell, section, row, tableView, value) {
            this._sendColorObject(this.colorObject, value, false);
        }

        favoriteSelected(index) {
            if (this.favorites[index].type === ColorPickerControlEnums.PickerMode.LUMITECH && this.control.details.pickerType === 'Rgb') {
                var content = {
                    title: _("warning"),
                    message: _("controls.colorpicker.wrong-favorite-message"),
                    buttonCancel: true,
                    icon: Icon.CAUTION
                };
                NavigationComp.showPopup(content);
            } else {
                this.colorSelection.sendColorObject(this.favorites[index], this.favorites[index].brightness);
            }
        }

        favoriteChanged(index) {
            var cmd;

            if (this.colorObject.type === ColorPickerControlEnums.PickerMode.LUMITECH) {
                cmd = Commands.format(Commands.COLOR_PICKER.LUMITECH, this.colorObject.brightness, this.colorObject.kelvin);
            } else if (this.colorObject.type === ColorPickerControlEnums.PickerMode.RGB) {
                cmd = Commands.format(Commands.COLOR_PICKER.HSV, this.colorObject.hue, this.colorObject.sat, this.colorObject.brightness);
            }

            if (cmd) {
                this.sendCommand(Commands.format(Commands.COLOR_PICKER.SET_FAVORITE, index + 1, cmd), null, this.control.uuidParent);
            }
        }

        _sendColorObject(colorObj, brightness, isDragging) {
            var cmd;

            if (colorObj.type === ColorPickerControlEnums.PickerMode.LUMITECH) {
                cmd = Commands.format(Commands.COLOR_PICKER.LUMITECH, brightness, colorObj.kelvin);
            } else if (colorObj.type === ColorPickerControlEnums.PickerMode.RGB) {
                cmd = Commands.format(Commands.COLOR_PICKER.HSV, colorObj.hue, colorObj.sat, brightness);
            }

            if (!this.isColorPickerControl) {
                cmd = Commands.format(Commands.LIGHTSCENE_RGB.SET_COLOR, cmd);
            }

            this.sendCommand(cmd, Commands.Type.OVERRIDE, null, isDragging);
        }

    };
});
