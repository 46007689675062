'use strict';
/*
 iconSrc The source of the left icon
 title The title of the cell
 value -> An Array of values eg. [12, 43, 55] for 12 h 43 min 55 s
 minTime (Milliseconds)
 maxTime (Milliseconds)
 [wheelOrder] This is optional if this is not provided 12:43 (hhii) will be taken


 onPickerChanged(section, row, tableView, value)

 Examples:
 Label:     12 min 3 s
 -- -- --
 Wheel:     12 03
 -- -- --
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.DURATION_PICKER = "DurationPickerCell";

    class DurationPickerCell extends GUI.TableViewV2.Cells.PickerBaseCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("time-picker-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.hasHours = this.wheelOrder.indexOf("hh") !== -1;
            this.hasMinutes = this.wheelOrder.indexOf("ii") !== -1;
            this.hasSeconds = this.wheelOrder.indexOf("ss") !== -1;
        }

        initDetail(detailElement) {
            super.initDetail(detailElement);
            detailElement.addClass("time-picker-cell__detail");
        }

        /**
         * This function initializes the Picker
         */
        preparePicker() {
            this.picker.timespan(this.mergePickerConfig({
                minTime: this.content.minTime ? this.content.minTime : 0,
                maxTime: this.content.maxTime ? this.content.maxTime : 24 * 60 * 60 * 1000,
                wheelOrder: this.wheelOrder,
                //TODO-woessto: from lindosi:  check if labels are used anymore? add labels to picker?
                labels: ['', '', '', 'h', 'm', 's'] // ['Years', 'Months', 'Days', 'Hours', 'Minutes', 'Seconds']

            }));
            this.setPickerValue(this.content.value);
        }

        /**
         * This function will be called once the pickerValue has changed
         * For example if the user turns one of the pickerWheels
         * @param v The value returned by mobiscroll int he given format
         */
        onChange(v) {
            /**
             * v is in the following format specified with the labels property in the preparePicker function
             * 12 h 48 m 55 s
             */
            this.antiGhostTimer.fire();
            var h = 0,
                m = 0,
                s = 0;
            var tmpValues;

            if (this.hasHours && v.indexOf("h") !== -1) {
                tmpValues = v.split("h");
                h = parseInt(tmpValues[0]);
                v = tmpValues[1];
            }

            if (v.indexOf("m") !== -1) {
                tmpValues = v.split("m");
                m = parseInt(tmpValues[0]);
                v = tmpValues[1];
            }

            if (this.hasSeconds && v.indexOf("s") !== -1) {
                tmpValues = v.split("s");
                s = parseInt(tmpValues[0]);
                v = tmpValues[1];
            }

            var res = [h, m, s];
            this.updateLabel(this.getValueAsString(res));
            this.delegate.onPickerChanged.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView, res);
        }

        /**
         * This function returns the string for the timeLabel
         * @param val
         */
        getValueAsString(val) {
            /**
             * val is an array with hours, minutes and seconds [12, 48, 55]
             */
            this.content.value = val; // update the content.value for collapsing - expanding

            var seconds = 0;

            if (val[0]) {
                seconds += val[0] * 60 * 60;
            }

            if (val[1]) {
                seconds += val[1] * 60;
            }

            if (val[2]) {
                seconds += val[2];
            }

            if (this.force24hours) {
                return LxDate.formatSecondsIntoDigits(seconds, true, false, true);
            } else {
                return LxDate.formatSeconds(seconds, false, false, false, !this.hasSeconds);
            }
        }

        /**
         * This sets the given value to the mobiscroll picker
         * @param value This must be in the right format you specified in the picker config.
         */
        setPickerValue(value) {
            var valueArray = [];

            if (this.hasHours) {
                valueArray.push(value[0] || 0);
            }

            if (this.hasMinutes) {
                valueArray.push(value[1] || 0);
            }

            if (this.hasSeconds) {
                valueArray.push(value[2] || 0);
            }

            this.picker.mobiscroll('setVal', valueArray);
        }

    }

    GUI.TableViewV2.Cells.DurationPickerCell = DurationPickerCell;
    return GUI;
}(window.GUI || {});
