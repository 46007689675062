'use strict';

define(['AudioZoneControlEnums', 'AudioZoneDetailedContentBase'], function (AudioZoneControlEnums, AudioZoneDetailedContentBase) {
    class SpotifyLibraryScreen extends AudioZoneDetailedContentBase {
        //region Getter
        get desc() {
            return _("media.popup.spotify.title");
        } //endregion Getter


        constructor(details) {
            super(...arguments);
            Object.assign(this, MediaStateHandler.Mixin);
            this.myPlaylist = details.myPlaylist;
            this.items = details.items;
            this.delegate = details.delegate;
            this.contentTypes = [MediaEnum.MediaContentType.SERVICE];
            this.username = details.username;
            this.legacySearchIdentifier = MediaEnum.SearchLoc.SPOTIFY;
        }

        setViewController() {
            super.setViewController(...arguments);
            this.ViewController.getElement().attr("contentIdentifier", MediaEnum.ControlContentIdentifiers.SPOTIFY);

            if (!this.ViewController.handleOnSubtitleTapped) {
                this.ViewController.handleOnSubtitleTapped = this.delegate.showSpotifySelectorContextMenu.bind(this.delegate, this.ViewController);
            }
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                this._registerForMediaServerEvents(this.EVENT_TYPES.SERVICE);
            }.bind(this));
        }

        destroy() {
            this._unregisterForMediaServerEvents(this.EVENT_TYPES.SERVICE);

            return super.destroy(...arguments);
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarText() {
            return _("media.library");
        }

        viewControllerSubtitleText() {
            return this.username;
        }

        receivedServiceReloadEvent(reason) {
            if (reason === MediaEnum.ReloadCause.USER_CHANGED) {
                Controls.AudioZoneControl.SpotifyAccountHelper.getCurrentAccountId(this.control.uuidAction).then(function (id) {
                    var user = Controls.AudioZoneControl.SpotifyAccountHelper.getSpotifyUserForId(id);

                    if (user) {
                        this.username = user.user;

                        this.ViewController._checkTitlebar();
                    }
                }.bind(this));
            }
        }

        setTableContent() {
            this.tableContent = [];

            if (this.myPlaylist) {
                this.tableContent.push({
                    rows: [this._getCellForItem(this.myPlaylist)]
                });
            }

            if (this.items) {
                this.tableContent.push({
                    rows: this.items.map(this._getCellForItem.bind(this))
                });
            }

            return super.setTableContent(...arguments);
        }

        getMediaInfo() {
            return {
                service: {
                    uid: MediaEnum.ControlContentIdentifiers.SPOTIFY + "/" + this.username,
                    cmd: MediaEnum.Service.SPOTIFY
                }
            };
        }

        _getCellForItem(item) {
            var cell = this.delegate._getCellForItem.call(this.delegate, item, true);

            cell.action = function () {
                return this.ViewController.showState(Controls.AudioZoneControl.MediaBrowserBase.getScreenStateForItem(item), null, this.delegate._getScreenStateDetailsForItem(item));
            }.bind(this);

            return cell;
        }

        _getRightSideForTitleBar() {
            return null;
        }

    }

    Controls.AudioZoneControl.SpotifyLibraryScreen = SpotifyLibraryScreen;
    return Controls.AudioZoneControl.SpotifyLibraryScreen;
});
