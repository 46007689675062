import {useCCEvent} from "LxComponents";
import {useCallback, useState} from "react";

/**
 * Will return true as long as the connection is alive & ready for communication.
 * Will wait until crucial data is loaded, unless argument is set.
 * @param ignoreCrucialData if false - it'll remain false until crucial data to be loaded.
 * @returns {boolean}
 */
function useConnectionReady(ignoreCrucialData = false) {

    const [getConnected, setConnected] = useState(true);
    const handleCCEvent = useCallback((event, arg) => {
         if (event === CCEvent.ConnClosed) {
            setConnected(false);
        } else if (event === CCEvent.ConnEstablished && ignoreCrucialData) {
             setConnected(true);
         } else if (event === CCEvent.CrucialDataLoaded && !ignoreCrucialData) {
            setConnected(true);
        }
    }, []);
    useCCEvent([CCEvent.ConnClosed, CCEvent.ConnEstablished, CCEvent.CrucialDataLoaded], handleCCEvent);

    return getConnected;
}


export default useConnectionReady;