'use strict';

define("AudioZoneDetailedContentBase", [], function () {
    class AudioZoneDetailedContentBase extends GUI.TableViewScreenV2 {
        //region Getter
        get RIGHT_SIDE_BUTTON() {
            return {
                CONTEXT_MENU: "right-side__touch-area--ctx-menu",
                SEARCH: "right-side__touch-area--search"
            };
        } //endregion Getter


        constructor(details) {
            super($('<div />'));
            applyMixins(this, MediaContextMenuHandler.Mixin, ContextMenuHandler.Mixin);
            this.details = details;
            this.icon = details.icon;
            this.control = MediaServerComp.getActiveZoneControl();
        }

        getAnimation() {
            return HD_APP ? AnimationType.HD_OVERLAY_FULLSCREEN : AnimationType.OVERLAY;
        }

        // TableView DataSource Methods
        styleForTableView() {
            return TableViewStyle.COMFORT_MODE_2020;
        }

        titleBarActionRight(buttonId) {
            if (buttonId === this.RIGHT_SIDE_BUTTON.CONTEXT_MENU) {
                this._showContextMenuForItem(this);
            } else if (buttonId === this.RIGHT_SIDE_BUTTON.SEARCH) {
                this.ViewController.rightAction();
            }
        }

        titleBarText() {
            return super.titleBarText(...arguments);
        }

        viewControllerTitleText() {
            return this.control.getName();
        }

        viewControllerSubtitleText() {
            return null;
        }

        _checkTitlebar() {
            if (this.ViewController instanceof GUI.AddMediaViewControllerPlaylistPicker) {
                return; // We don't want a titleBar in this case. It is handled by the ViewController...
            } else if (this.ViewController instanceof GUI.AudioZoneControlDetailedViewController || this.ViewController instanceof GUI.AddMediaViewControllerBase) {
                var titleBarConfig = this.titleBarConfig();

                if (titleBarConfig) {
                    this._titleBar = new GUI.LxTitleBar(titleBarConfig);

                    this._titleBar.setTitleBarSideTexts(this.titleBarText());

                    this.prependSubview(this._titleBar);

                    this._titleBar.element.addClass("minor-title-bar");
                }
            } else {
                super._checkTitlebar(...arguments);
            }

            this._titleBar && Object.values(this.RIGHT_SIDE_BUTTON).forEach(function (btnId) {
                var btnElm = this._titleBar.element.find("." + btnId),
                    btn;

                if (btnElm[0]) {
                    btn = new GUI.LxButton(this, btnElm);
                    this.addToHandledSubviews(btn);
                    btn.onButtonTapped = this.titleBarActionRight.bind(this, btnId);
                }
            }.bind(this));
        }

        titleBarConfig() {
            return {
                leftSide: this._getLeftSideForTitleBar(),
                rightSide: this._getRightSideForTitleBar(),
                rightSideDiv: "<div>" + this._getRightSideTitleBarButtons().join("") + "</div>"
            };
        }

        _getLeftSideForTitleBar() {
            if (!HD_APP || this.ViewController instanceof GUI.ModalViewController || this.ViewController.history.stack.length > 1) {
                return super._getLeftSideForTitleBar(...arguments);
            } else {
                return TitleBarCfg.TEXT;
            }
        }

        _getRightSideForTitleBar() {
            return TitleBarCfg.CUSTOM_CONTENT;
        }

        _getRightSideTitleBarButtons() {
            var btns = [];
            btns.push(this._getRightSideTitleBarButtonElm(this.RIGHT_SIDE_BUTTON.CONTEXT_MENU, Icon.Buttons.MORE_HORIZONTAL));

            if (HD_APP && !MediaServerComp.Feature.V2_FIRMWARE && this.legacySearchIdentifier) {
                btns.push(this._getRightSideTitleBarButtonElm(this.RIGHT_SIDE_BUTTON.SEARCH, Icon.SEARCH));
            }

            return btns;
        }

        _getRightSideTitleBarButtonElm(btnClass, icon, onButtonTapped) {
            return "<div class='right-side__touch-area clickable " + btnClass + "'>" + ImageBox.getResourceImageWithClasses(icon, "right-side__button") + "</div>";
        }

    }

    Controls.AudioZoneControl.AudioZoneDetailedContentBase = AudioZoneDetailedContentBase;
    return Controls.AudioZoneControl.AudioZoneDetailedContentBase;
});
