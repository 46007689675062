import AlarmControlContentBase from "../../alarmControlBase/content/controlContent";
import {useCallback} from "react";

function SmokeAlarmControlContent(props) {
    const isAlarmActive = useCallback((control, states) => states.testAlarmActive || states.alarmActive, [])

    return <AlarmControlContentBase navigation={props.navigation} drillMessage={_("controls.smoke-alarm.drill.verify.message")} isAlarmActiveFn={isAlarmActive} />
}

export default SmokeAlarmControlContent
