'use strict';

define(['AudioZoneControlEnums'], function (AudioZoneControlEnums) {
    class MediaSearchBase extends GUI.Screen {
        //region Private
        EMPTY_VIEW = {
            NO_RESULT: 'noResult',
            NONE: 'none',
            SEARCHING: 'searching'
        }; //endregion Private

        //region Getter
        get inputPlaceholder() {
            return _("media.search.placeholder");
        }

        get isSearching() {
            return this._isSearching;
        }

        get tag() {
            if (this.tags && this.tags.length) {
                return this.tags[this.segmentIndex];
            } else {
                return 'none';
            }
        }

        get searchLocation() {
            return this.tag;
        }

        get contentType() {
            return null;
        }

        get mediaType() {
            return null;
        }

        get username() {
            return this.delegate.username || MediaEnum.NOUSER;
        } //endregion Getter


        //region Setter
        set isSearching(value) {
            this._isSearching = value;

            if (this.searchInput) {
                // Disable the auto focus of the input if we have search results,
                // this way we prevent the keyboard from popping up every time the input is visible again
                this.searchInput.config.autoFocus = !this._isSearching;
                this.searchInput.removeFocus();
            }
        } //endregion Setter


        constructor(details) {
            super($('<div/>'));
            Object.assign(this, MediaContextMenuHandler.Mixin);
            this.details = details;
            this.control = details.control;
            this.delegate = details.delegate;
            this.segmentIndex = 0;
            this.tags = [];
            this._isSearching = false;
            this._gotResults = false;
            this._tableViewMap = {};
        }

        viewDidLoad() {
            var pageViews = [];
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                if (this.tags.length) {
                    this.tags.forEach(function (tag) {
                        this._prepareTableViewForTag(tag);

                        pageViews.push(this._tableViewMap[tag].tableView.getElement());
                    }.bind(this));
                } else {
                    this._prepareTableViewForTag(this.tag);

                    pageViews.push(this._tableViewMap[this.tag].tableView.getElement());
                }

                this.pageView = new GUI.LxPageView(pageViews, false, this._donePaging.bind(this), {
                    overscroll: true
                });
                this.pageView.disableUserInteraction(true);
                this.appendSubview(this.pageView);
            }.bind(this));
        }

        viewDidAppear() {
            this.searchInput.onSubmit = function (value) {
                if (value.trim()) {
                    this._onSubmit(value);
                }
            }.bind(this);

            this.searchInput.resetButton.onButtonTapped = this._clearSearch.bind(this);

            this._titleBar.onSegmentSelected = function (idx) {
                this.segmentIndex = idx;
                this.inputText && this._onSubmit(this.inputText);
                this.pageView.setActivePage(this.segmentIndex);
            }.bind(this);

            return super.viewDidAppear(...arguments);
        }

        getAnimation() {
            return AnimationType.FADE;
        }

        getURL() {
            return "Search";
        }

        titleBarConfig() {
            var cfg = {};

            if (super.titleBarConfig) {
                cfg = super.titleBarConfig(...arguments);
            }

            var inputCfg = {
                type: GUI.LxInputEnum.Type.SEARCH,
                leftIconSrc: Icon.SEARCH,
                placeholder: this.inputPlaceholder,
                style: GUI.LxInputStyle.ROUNDED_SQUARE,
                resetButton: true,
                autoFocus: true,
                isDark: true
            };
            this.searchInput = new GUI.LxInput(inputCfg, this);
            cfg.leftSide = TitleBarCfg.CUSTOM_LX_VIEW;
            cfg.leftSideView = this.searchInput;

            if (this.showCancelButton()) {
                cfg.rightSide = TitleBarCfg.TEXT;
                cfg.rightText = _('cancel');
            }

            if (this.tags.length) {
                // Add the segmented control
                cfg.subTitleBar = TitleBarCfg.Sub.SEGMENTED;
                cfg.segments = this.tags.map(function (tag) {
                    return {
                        text: this._getNameForTag(tag),
                        tag: tag,
                        type: 'label'
                    };
                }.bind(this));
            }

            return cfg;
        }

        styleForTableView() {
            return TableViewStyle.COMFORT_MODE_2020;
        }

        titleBarText() {
            return _('media.search.placeholder');
        }

        showCancelButton() {
            return this.ViewController instanceof GUI.AddMediaViewControllerBase || !HD_APP;
        }

        titleBarActionRight() {
            this.ViewController.navigateBack();
        }

        getMediaInfo() {
            return this.delegate.getMediaInfo.apply(this.delegate, arguments);
        }

        /**
         * The user did hit enter - this is when the search must be triggered immediately.
         * @param value
         * @private
         */
        _onSubmit(value) {
            this.inputText = value;

            this._launchSearchFor(value);
        }

        _clearSearch() {
            this.searchInput.setValue('');
            this.isSearching = false;

            this._updateAllTableViews([]);
        }

        _launchSearchFor(keyword) {
            this.keyword = keyword;
            this.searchInput.setValue(keyword);

            this._setBackgroundViewForTag(this.tag, this.EMPTY_VIEW.SEARCHING);

            MediaServerComp.searchFor(this, this.keyword, this.searchLocation).then(this._onSearchFinished.bind(this), this._onSearchError.bind(this), this._onSearchUpdate.bind(this));
        }

        _onSearchFinished() {
            this.isSearching = false;
        }

        _onSearchError(error) {
            this.isSearching = false;
            this._gotResults = false;
        }

        _onSearchUpdate(update) {
            this.isSearching = true;
            this._gotResults = !!update;

            if (update) {
                this._updateTableViewForTag(update.type, update.tableContent);
            }
        }

        _prepareTableViewForTag(tag) {
            this._tableViewMap[tag] = {
                dataSource: tableViewDataSource(null, null, this),
                delegate: tableViewDelegate(null, this)
            };
            this._tableViewMap[tag].tableView = new GUI.TableViewV2(this._tableViewMap[tag].dataSource, this._tableViewMap[tag].delegate);
            this.addToHandledSubviews(this._tableViewMap[tag].tableView);
        }

        _updateAllTableViews(tableContent) {
            return Q.all(this.tags.map(function (tag) {
                return this._updateTableViewForTag(tag, tableContent);
            }.bind(this)));
        }

        _updateTableViewForTag(tag, tableContent) {
            var prms;
            Debug.Control.AudioZone.GlobalSearch && console.log(this.name + ": _updateTableViewForTag: " + tag + " with contentLength:" + tableContent.length);

            if (tableContent && tableContent.length) {
                prms = this._setBackgroundViewForTag(tag, this.EMPTY_VIEW.NONE).then(function () {
                    this._tableViewMap[tag].dataSource.update(tableContent);

                    this._tableViewMap[tag].delegate.update(tableContent);

                    return this._tableViewMap[tag].tableView.reload();
                }.bind(this));
            } else {
                prms = this._setBackgroundViewForTag(tag, this.EMPTY_VIEW.NO_RESULT);
            }

            return prms;
        }

        _setBackgroundViewForTag(tag, type) {
            this._tableViewMap[tag].dataSource.update([]);

            this._tableViewMap[tag].delegate.update([]);

            return this._tableViewMap[tag].tableView.reload().then(function () {
                switch (type) {
                    case this.EMPTY_VIEW.NO_RESULT:
                        this._tableViewMap[tag].tableView.setBackgroundView(new GUI.BackgroundView(Icon.ERROR, _("media.global-search.no-result"), null, null, null, true));

                        break;

                    case this.EMPTY_VIEW.NONE:
                        this._tableViewMap[tag].tableView.setBackgroundView(null);

                        break;

                    case this.EMPTY_VIEW.SEARCHING:
                        this._tableViewMap[tag].tableView.setBackgroundView(new GUI.BackgroundView(Icon.INDICATOR, _('device-learning.searching.title'), null, null, null, true));

                        break;
                }
            }.bind(this));
        }

        _donePaging(idx) {
            this.segmentIndex = idx;
            Debug.Control.AudioZone.GlobalSearch && console.log(this.name + ": segmentIndex --> " + this.segmentIndex);
            this._titleBar.segmentedControl && this._titleBar.segmentedControl.setActiveSegment(idx, !this.isVisible(true));
        }

        _getNameForTag(tag) {
            return tag;
        }

    }

    Controls.AudioZoneControl.MediaSearchBase = MediaSearchBase;
    return Controls.AudioZoneControl.MediaSearchBase;
});
