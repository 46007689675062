'use strict';

define("VentilationControl", ["Control", "VentilationControlEnums"], function (Control, VentilationControlEnums) {
    return class VentilationControl extends Control {
        constructor() {
            super(...arguments);
        }

        getLegacyScreens() {
            return Object.values(VentilationControlEnums.SCREEN_STATES)
        }

        hasError() {
            return this._hasControlInfoWithLevel(VentilationControlEnums.CONTROL_INFO.ERROR);
        }

        hasWarning() {
            return this._hasControlInfoWithLevel(VentilationControlEnums.CONTROL_INFO.WARNING);
        }

        getFriendlyValueName(event, operator) {
            let result = "";

            switch (event.data.stateName) {
                default:
                    return "";
            }

            return result;
        }

        getModeWithId(id) {
            return this.details.modes.filter(function (mode) {
                return mode.id === id;
            })[0];
        }

        getCo2QualityString() {
            let states = this.getStates(),
                ppmValue = states.airQualityIndoor,
                qualityString;

            if (ppmValue > states.airQualityMax) {
                qualityString = _("controls.leaf.quality.bad");
            } else {
                qualityString = _("controls.leaf.quality.good");
            }

            return qualityString;
        }

        formatHumidity(humidity) {
            return lxFormat("%.0f%%", humidity);
        }

        formatCO2(co2value) {
            return lxFormat("%.0f ppm", co2value);
        }

        /**
         * Check if the requested controlInfo level should be shown to the user
         * @Note: The systemState is more important then the controlInfo
         * @param level
         * @return {boolean}
         * @private
         */
        _hasControlInfoWithLevel(level) {
            let states = this.getStates(),
                hasSystemStateMessage = !!states.messageCenterEntries && states.messageCenterEntries.length > 0;
            return states.controlInfo && states.controlInfo.level === level && !hasSystemStateMessage;
        }

    };
});
