'use strict';

import { launchImageLibrary } from "react-native-image-picker";

define([ 'AudioZoneV2ControlEnums', 'AudioZoneV2DetailedContentBase', 'CoverViewV2' ],
    function (AudioZoneV2ControlEnums, AudioZoneV2DetailedContentBase, CoverViewV2) {
    return class CustomStreamScreen extends AudioZoneV2DetailedContentBase {
        //region Getter+Setter
        get RIGHT_SIDE_BUTTON() {
            let baseVal = super.RIGHT_SIDE_BUTTON;
            baseVal.CREATE = "right-side__touch-area--create";
            return baseVal;
        }

        get ICON_SIZE_LIMIT() {
            return 512 * 1024;
        }

        get streamId() {
            return this._streamObject.id || null;
        }
        get streamName() {
            return this._streamObject.name || null;
        }
        set streamName(newVal) {
            this._streamObject.name = newVal;
        }
        get streamUrl() {
            return this._streamObject.streamurl || null;
        }
        set streamUrl(newVal) {
            this._streamObject.streamurl = newVal;
        }

        get streamIconB64() {
            return this._iconB64;
        }
        set streamIconB64(newVal) {
            if (newVal.startsWith("data:")) {
                this._iconB64 = newVal;
            } else {
                this._iconB64 = "data:image/png;base64, " + newVal;
            }

        }
        get streamIconSrc() {
            return this._streamObject.coverurl || null;
        }

        get validData() {
            return !!this.streamName && !!this.streamUrl;
        }

        get streamUrlChanged() {
            return this.streamUrl !== this._ogObject.streamurl;
        }

        get dataChanged() {
            if (this.streamIconB64) {
                return true;
            } else if (this.streamName !== this._ogObject.name) {
                return true;
            } else if (this.streamUrlChanged) {
                return true;
            } else {
                return false;
            }
        }
        get imageChangedOnly() {
            return (
                this.streamName === this._ogObject.name &&
                this.streamUrl === this._ogObject.streamurl &&
                !!this.streamIconB64
            );
        }



        get asComp() {
            return this.control.audioserverComp;
        }
        //endregion Getter+Setter


        constructor(details) {
            super(...arguments);

            this._deleted = false;
            this._isNew = !details.streamObject || !details.streamObject.id;
            this._streamObject = details.streamObject || {};

            this._ogObject = cloneObjectDeep(this._streamObject);
        }

        titleBarActionRight(buttonId) {
            if (buttonId === this.RIGHT_SIDE_BUTTON.CREATE) {
                if (this._isNew) {
                    if (this.validData) {
                        return this._addNewCustomStream().then(() => {
                            this.ViewController.dismiss()
                        })
                    } else {
                        // nothing to do, maybe show a prompt?
                    }
                } else if (this.imageChangedOnly) {
                    let prms = this.asComp.setCustomStreamIcon(this.streamId, this.streamIconB64);
                    return NavigationComp.showWaitingFor(prms, null, null, true).then(() => {
                        this.ViewController.dismiss()
                    });

                } else if (this.dataChanged) {
                    return this._updateStream().then(() => {
                        this.ViewController.dismiss()
                    })
                } else {
                    return this.ViewController.dismiss();
                }
            } else {
                super.titleBarActionRight(...arguments);
            }
        }

        getURL() {
            return "CustomUrlEditor";
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            if (this._isNew) {
                return _("audio-server.custom-radio.add");

            } else {
                return _("media.edit-favorites", {
                    favorite: '"' + this.streamName + '"'
                })
            }
        }

        setTableContent() {
            return Q(this.getTableContent()).then(tableContent => {
                this.tableContent = tableContent;
                return super.setTableContent(...arguments);
            });
        }

        getTableContent() {
            let content = [
                    { rows: [ this._getUrlRow(), this._getNameRow(), this._getImageRow() ] },
                ];

            if (!this._isNew) {
                content.push({ rows: [this._getDeleteRow()] });
            }

            return content;
        }

        _getNameRow() {
            return {
                type: GUI.TableViewV2.CellType.INPUT,
                content: {
                    title: _("audio-server.custom-radio.name"),
                    placeholder: this.streamName || _("audio-server.custom-radio.name"),
                    filterRegex: Regex.NAME_FILTER,
                    validationRegex: Regex.NAME,
                    value: this.streamName,
                    showGreen: true
                },
                textChanged: function textChanged(section, row, tableView, value, valid) {
                    this.streamName = valid ? value : null;
                }.bind(this)
            };
        }

        _getUrlRow() {
            return {
                type: GUI.TableViewV2.CellType.INPUT,
                content: {
                    title: _("audio-server.custom-radio.url"),
                    validationFn: (value) => {
                        try {
                            new URL(value);
                            return true;
                        } catch (err) {
                            return false;
                        }
                    },
                    placeholder: this.streamUrl || _("audio-server.custom-radio.url"),
                    value: this.streamUrl,
                    selectText: true,
                    autoFocus: true,
                    showGreen: true,
                },
                textChanged: function textChanged(section, row, tableView, value, valid) {
                    this.streamUrl = valid ? value : null;
                }.bind(this)
            };
        }

        _getImageRow() {
            if (this.streamIconB64) {
                return {
                    content: {
                        title: _("audio-server.custom-radio.image"),
                        rightIconSrc: this.streamIconB64,
                        disclosureIcon: true
                    },
                    action: this._pickIcon.bind(this)
                }
            } else {
                // sole purpose of this coverview is to adopt the URL when externally connected.
                var coverView = new CoverViewV2(null, {}, false);
                var adoptedSrc = coverView.replaceWithAudioserverProxyHost(this.streamIconSrc);
                return {
                    content: {
                        title: _("audio-server.custom-radio.image"),
                            rightIconSrc: adoptedSrc,
                            disclosureIcon: true
                    },
                    action: this._pickIcon.bind(this)
                }
            }
        }


        _getDeleteRow() {
            return {
                type: GUI.TableViewV2.CellType.DELETE,
                content: {
                    title: _("delete")
                },
                action: this._deleteStream.bind(this)
            }
        }
        
        _getRightSideTitleBarButtons() {
            var rightSideButtons = [];
            rightSideButtons.push(this._getRightSideTitleBarButtonElm(this.RIGHT_SIDE_BUTTON.CREATE, Icon.TitleBar.TICK));
            return rightSideButtons;
        }

        // region actions
        
        _addNewCustomStream(isRetrying = false) {
            let prms =  this.asComp.addCustomStream(this.streamName, this.streamUrl, this.streamIconB64);
            prms = prms.then(null, (resp) => {
                this._handleShowErrorResponse(resp);
                return Q.reject(resp);
            });
            return NavigationComp.showWaitingFor(prms, null, _("audio-server.custom-radio.adding"));
        }

        _updateStream() {
             let prms = this.asComp.updateCustomStream(this.streamId, this.streamName, this.streamUrl, this.streamIconB64);
             prms = prms.then(null, (resp) => {
                this._handleShowErrorResponse(resp);
                return Q.reject(resp);
             });
            return NavigationComp.showWaitingFor(prms);
        };

        _showInvalidURL(suffix) {
            var msgPrts = [_("audio-server.custom-radio.wrong-url.message")];
            msgPrts.pushObject(suffix);
            var content = {
                title: _("audio-server.custom-radio.wrong-url.title"),
                message: msgPrts.join(" "),
                color: window.Styles.colors.orange,
                icon: Icon.CAUTION,
                buttonOk: _('okay')
            };
            NavigationComp.showPopup(content, PopupType.GENERAL);
        }

        _handleShowErrorResponse(response) {
            let error = response.hasOwnProperty("error") ? response.error : response;
            let errorInfo = response.hasOwnProperty("errorInfo") ? response.errorInfo : null;
            let textParts = [], handled = false;
            switch (error) {
                case MusicServerEnum.CustomRadioErrors.NONE:
                    // no error
                    handled = true;
                    break;
                case MusicServerEnum.CustomRadioErrors.INVALID_URL:
                    // invalid URL
                    handled = true;
                    this._showInvalidURL(errorInfo);
                    break;
                case MusicServerEnum.CustomRadioErrors.FS_ERROR:
                    textParts.push(errorInfo || "500, File-System Error"); // error on AS.
                    break;
                case MusicServerEnum.CustomRadioErrors.ID_NOT_FOUND:
                    textParts.push(errorInfo || "404, ID not found"); // already deleted?
                    break;
                case MusicServerEnum.CustomRadioErrors.NO_COVER:
                    textParts.push(errorInfo || "400, No/Invalid Cover Payload"); // cover error.
                    break;
                default:
                    textParts.push(errorInfo || JSON.stringify(response));
                    break;
            }

            if (!handled) {
                var content = {
                    title: _("error.occured"),
                    message: textParts.join(SEPARATOR_SYMBOL),
                    color: window.Styles.colors.orange,
                    icon: Icon.CAUTION,
                    buttonOk: _('okay')
                };
                NavigationComp.showPopup(content, PopupType.GENERAL);
            }
        }

        _deleteStream() {
            let prms = this.asComp.removeCustomStream(this.streamId);
            prms = prms.then(null, (resp) => {
                this._handleShowErrorResponse(resp);
                return Q.reject(resp);
            });
            NavigationComp.showWaitingFor(prms).then(() => {
                this._deleted = true;
                this.ViewController.navigateBack();
            })
        }

        // endregion

        /**
         * See https://github.com/react-native-image-picker/react-native-image-picker for more details on how to
         * restrict/interact with the picker
         * @private
         */
        _pickIcon() {
            launchImageLibrary({ mediaType: "photo", maxWidth: 800, maxHeight: 800  }).then((res) => {
                let supportedFileTypes = [
                    "png",
                    "jpeg"
                ];
                let supportedRegex = new RegExp(`^data:image\/(?:${supportedFileTypes.join("|")})`);
                if (!res.didCancel) {
                    if (supportedRegex.test(res.assets[0].uri)) {
                        // reference: https://github.com/react-native-image-picker/react-native-image-picker
                        var asset = res.assets[0];
                        if (this._fileSizeOkay(asset)) {
                            this._iconPicked( asset.uri);

                        } else {
                            NavigationComp.showPopup({
                                title: _("filesize-limit", { limit: lxFormat("%.1f MB", 0.5) } ),
                                buttonOk: true
                            }, PopupType.GENERAL);
                        }

                    } else {
                        var content = {
                            title: _("ambient.image-picker.wrong-format.title"),
                            message: _("ambient.image-picker.wrong-format.description", {
                                formats: supportedFileTypes.join(", ")
                            }),
                            buttonOk: _('okay'),
                            icon: Icon.CAUTION
                        };
                        NavigationComp.showPopup(content);
                    }
                }

            }, (err) => {
                console.error(this.name, "failed to load img from FS: ", err);
            })
        }

        _fileSizeOkay(asset) {
            let okay = true,
                buffer;

            if (!asset.hasOwnProperty("fileSize") && asset.base64) {
                buffer = base64StringToArrayBuffer(asset.base64);
                asset.fileSize = buffer.byteLength;
            } else if (!asset.hasOwnProperty("fileSize") && asset.uri && asset.uri.indexOf(";base64,") >= 0) {
                buffer = base64StringToArrayBuffer(asset.uri.split(";base64,")[1]);
                asset.fileSize = buffer.byteLength;
            }

            if (asset.fileSize) {
                okay = asset.fileSize <= this.ICON_SIZE_LIMIT;
            }

            return okay;
        }

        _iconPicked(imgObj) {
            this.streamIconB64 = imgObj;
            this.reloadTable();
        }
    };
});
