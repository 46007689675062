'use strict';

define([], function () {
    return class AutomaticDesignerRuleViewController extends GUI.ModalViewController {
        constructor(details) {
            super();
            Object.assign(this, ContextMenuHandler.Mixin);
            this.details = details || {};
            this.extension = this.details.extension || AutomaticDesignerEnums.RULE_EXTENSION.NONE;

            if (!this.details.rule) {
                if (hasBit(this.extension, AutomaticDesignerEnums.RULE_EXTENSION.SCENE)) {
                    this.details.rule = AutomaticDesignerEnums.SCENE_TEMPLATE;
                } else {
                    this.details.rule = AutomaticDesignerEnums.RUlE_TEMPLATE;
                }
            }

            this.isInEditMode = !!nullEmptyString(this.details.rule.id);
            AutomaticDesignerComponent.setCurrentRuleToEdit(this.details.rule);
        }

        viewWillAppear() {
            var showStatePrms,
                args = arguments;

            if (!this.didAlreadyShowInitialState) {
                if (hasBit(this.extension, AutomaticDesignerEnums.RULE_EXTENSION.TASK_RECORDER)) {
                    showStatePrms = this.showState(ScreenState.TaskV2Details, null, this.details, AnimationType.NONE);
                } else if (hasBit(this.extension, AutomaticDesignerEnums.RULE_EXTENSION.SCENE)) {
                    showStatePrms = this.showState(ScreenState.AutomaticDesigner.AddOrEditScene, null, this.details, AnimationType.NONE);
                } else {
                    showStatePrms = this.showState(ScreenState.AutomaticDesigner.AddOrEditRule, null, this.details, AnimationType.NONE);
                }
            } else {
                showStatePrms = Q(true);
            }

            return showStatePrms.then(() => {
                this.didAlreadyShowInitialState = true;
                return super.viewWillAppear(...args);
            });
        }

        getURL() {
            if (hasBit(this.extension, AutomaticDesignerEnums.RULE_EXTENSION.SCENE)) {
                if (this.details.rule && this.details.rule.id) {
                    return UrlStartLocation.AUTOMATIC_DESIGNER_SCENES + "/" + this.details.rule.id;
                } else {
                    return UrlStartLocation.AUTOMATIC_DESIGNER_SCENES + "/new";
                }
            } else {
                if (this.details.rule && this.details.rule.id) {
                    return UrlStartLocation.AUTOMATIC_DESIGNER + "/" + this.details.rule.id;
                } else {
                    return UrlStartLocation.AUTOMATIC_DESIGNER + "/new";
                }
            }
        }

        destroyOnBackNavigation() {
            return true;
        }

    };
});
