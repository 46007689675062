'use strict';

define(['ColorPickerColorSelection', 'LightControlEnums', 'ColorPickerControlEnums'], function (ColorPickerColorSelection, LightControlEnums, ColorPickerControlEnums) {
    return class ManualList extends GUI.View {
        constructor(control) {
            super($('<div class="container__manual-list"></div>'));
            // detect if it is a a light-control or lightscene-rgb
            this.isLightControl = control.type.indexOf('LightController') === 0;
            var extraClass = this.isLightControl ? ' manual-list--light-control' : ' scroll-container--light-scene-rgb';
            this.scrollContainer = $('<div class="manual-list__scroll-container' + extraClass + '"></div>');
            this.element.append(this.scrollContainer);
            this.control = control; // create favorites

            if (this.isLightControl) {
                this.controls = mapToArrayOld(this.control.subControls);
            } else {
                this.favorites = [];

                for (var i = 0; i < 4; i++) {
                    var hue = 0;

                    if (i === 0) {
                        hue = 240;
                    }

                    if (i === 1) {
                        hue = 120;
                    }

                    this.favorites.push({
                        type: ColorPickerControlEnums.PickerMode.RGB,
                        hue: hue,
                        sat: i === 3 ? 0 : 100,
                        brightness: 100
                    });
                }
            }
        }

        titleBarText() {
            return this.control.name;
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.TEXT
            };
        }

        viewDidLoad() {
            var prmsArr = [true];
            return Q(super.viewDidLoad(...arguments)).then(function () {
                this.titleBar = new GUI.LxTitleBar(this.titleBarConfig());
                this.titleBar.setTitleBarSideTexts(this.titleBarText());

                if (HD_APP) {
                    prmsArr.push(this.prependSubview(this.titleBar));
                    this.element.addClass("manual-list--hd");
                }

                if (this.isLightControl) {
                    // controlList
                    this.controlList = new GUI.ControlListTableView(this, this);
                    this.controlList.getElement().addClass('scroll-container__control-list');
                    prmsArr.push(this.appendSubview(this.controlList, this.scrollContainer));
                    prmsArr.push(this.controlList.reload());
                } else {
                    // colorSelection
                    this.subview = new ColorPickerColorSelection(false, this.control);
                    prmsArr.push(this.appendSubview(this.subview, this.scrollContainer));
                    this.subview.sendCommand = this.sendCommand.bind(this);
                }

                this.tableView = new GUI.TableViewV2(this, this);
                this.tableView.setForceFullReload(true);
                this.tableView.getElement().addClass("scroll-container__table-view");
                prmsArr.push(this.appendSubview(this.tableView, this.scrollContainer));
                return Q.all(prmsArr);
            }.bind(this));
        }

        viewDidAppear() {
            var baseCall = super.viewDidAppear(...arguments);

            this.titleBar.onLeftButtonTapped = function () {
                this.ViewController.navigateBack();
                this.titleBar.onLeftButtonTapped = null; // workaround for twice calls
            }.bind(this);

            return baseCall;
        }

        // Public methods
        update(states) {
            this.activeSceneNr = states.activeSceneNr;
            var scenes;

            if (this.isLightControl) {
                scenes = states.scenes;
            } else {
                scenes = this.control.details.sceneList;
            }

            this.sceneName = "";

            if (states.activeSceneNr === 9) {
                this.sceneName = _("controls.lightcontroller.all-on");
            } else if (states.activeSceneNr === 0) {
                this.sceneName = _("controls.lightcontroller.all-off");
            } else if (states.activeSceneNr === -1) {
                // costume scene
                this.sceneName = _("controls.lightcontroller.manual");
            } else {
                this.sceneName = _("controls.lightcontroller.active-scene", {
                    sceneName: scenes[states.activeSceneNr]
                });
            }

            this.colorObject = states.colorObject;

            if (this.isLightControl) {
                this.tableView.reload();

                if (HD_APP) {
                    this.titleBar.setTitleBarSideTexts(this.sceneName);
                }
            } else {
                if (states.haveScenesChanged || !this.tableView.table.length || this.previousSceneNr !== states.activeSceneNr) {
                    if (!this._reloadPrms) {
                        this._reloadPrms = this._reloadTableView();
                    } else {
                        this._needsReload = true;
                    }
                } else {
                    var prms = this._reloadPrms || Q.resolve();
                    prms.then(function () {
                        this.tableView.cellForSectionAndRow(0, 0).setPreviewColor(this.colorObject);
                        this.tableView.reloadRowsSelective(0, 1, this.tableView.table.length - 1);
                    }.bind(this));
                }
            }

            if (!this.isLightControl) {
                this.subview.update(states);
            }

            this.previousSceneNr = states.activeSceneNr;
        }

        _reloadTableView() {
            return this.tableView.reload().then(function () {
                // the subview might need an update after an update from the tableView
                this.subview.update(this.subview.states);

                if (this._needsReload) {
                    this._needsReload = false;
                    this._reloadPrms = this._reloadTableView();
                } else {
                    this._reloadPrms = null;
                }

                return this._reloadPrms || Q.resolve();
            }.bind(this));
        }

        // Factory
        dequeueReusableCell(reuseID) {
            var cellCachedInstance = this.cellCache && this.cellCache[reuseID];

            if (cellCachedInstance) {
                delete this.cellCache[reuseID];
                return cellCachedInstance;
            }
        }

        storeReusableCell(reuseID, instance) {
            if (this.cellCache) {
                this.cellCache[reuseID] = instance;
                return true;
            }

            return false;
        }

        // TableViewDataSource methods
        styleForTableView(tableView) {
            return TableViewStyle.GROUPED;
        }

        numberOfSections() {
            return 1;
        }

        numberOfRowsInSection(section, tableView) {
            if (tableView === this.controlList) {
                return this.controls.length;
            } else if (tableView === this.tableView) {
                if (this.isLightControl) {
                    return this.activeSceneNr === -1 ? 1 : 0;
                } else {
                    return this.activeSceneNr === -1 ? 3 : 2;
                }
            }
        }

        cellTypeForCellAtIndex(section, row) {
            if (this.isLightControl || this.activeSceneNr === -1 && row === 1) {
                return GUI.TableViewV2.CellType.GENERAL;
            } else {
                if (row === 0) {
                    return GUI.TableViewV2.CellType.Special.COLOR_PICKER_BRIGHTNESS;
                } else if (row === 1 || row === 2) {
                    return GUI.TableViewV2.CellType.Special.COLOR_PICKER_FAVORITES;
                }
            }
        }

        controlForCellAtIndex(section, row) {
            return this.controls[row];
        }

        contentForCell(cell, section, row) {
            if (this.isLightControl || this.activeSceneNr === -1 && row === 1) {
                return {
                    leftIconSrc: 'resources/Images/Controls/Light/save.svg',
                    leftIconColor: window.Styles.colors.red,
                    title: _("controls.lightcontroller.scene.save"),
                    disclosureIcon: true
                };
            } else {
                if (row === 0) {
                    return {
                        title: _("on-off"),
                        previewColor: this.colorObject,
                        minIconSrc: "resources/Images/Controls/LightsceneRGB/icon-brightness_down.svg",
                        maxIconSrc: "resources/Images/Controls/LightsceneRGB/icon-brightness_up.svg",
                        step: 5
                    };
                } else if (row === 1 || row === 2) {
                    return {
                        colorsArray: this.favorites
                    };
                }
            }
        }

        // TableViewDelegate methods
        didSelectCell(cell, section, row) {
            if (this.isLightControl || this.activeSceneNr === -1 && row === 1) {
                this.ViewController.showState(LightControlEnums.ScreenState.SAVE_SCENE, null, {
                    control: this.control
                });
            }
        }

        onSwitchChanged(isOn) {
            this.sendCommand(isOn ? Commands.LIGHTSCENE_RGB.ON : Commands.LIGHTSCENE_RGB.OFF);
        }

        sliderDragged(cell, section, row, tableView, value, isDragging) {
            this._sendColorObject(this.colorObject, value, isDragging);
        }

        sliderClicked(cell, section, row, tableView, value) {
            this._sendColorObject(this.colorObject, value, false);
        }

        favoriteSelected(index) {
            this._sendColorObject(this.favorites[index], this.favorites[index].brightness, false);
        }

        _sendColorObject(colorObj, brightness, isDragging) {
            var cmd;

            if (colorObj.type === ColorPickerControlEnums.PickerMode.LUMITECH) {
                cmd = Commands.format(Commands.COLOR_PICKER.LUMITECH, brightness, colorObj.kelvin);
            } else if (colorObj.type === ColorPickerControlEnums.PickerMode.RGB) {
                cmd = Commands.format(Commands.COLOR_PICKER.HSV, colorObj.hue, colorObj.sat, brightness);
            }

            if (!this.isColorPickerControl) {
                cmd = Commands.format(Commands.LIGHTSCENE_RGB.SET_COLOR, cmd);
            }

            this.sendCommand(cmd, Commands.Type.OVERRIDE, null, isDragging);
        }

    };
});
