import {Animated, View} from "react-native";
import LxReactText from "../LxReactText";
import globalStyles from "GlobalStyles";
import PropTypes from "prop-types";

const sliderRadius = 3;
const width = 50;

/**
 * Represents one or two labels above the slider, they show up/hide with a fade animation
 * @param {number} props.oneMarkerValue Single value or left value of a range slider
 * @param {number} props.twoMarkerValue Right value of a range slider
 * @param {number} props.oneMarkerLeftPosition Position of the left thumb
 * @param {number} props.twoMarkerLeftPosition Position of the right thumb
 * @param {Boolean} props.oneMarkerPressed Whether the left thumb was pressed
 * @param {Boolean} props.twoMarkerPressed Whether the right thumb was pressed
 * @param {String} props.textColor Color for the label text
 * @param {Object} props.labelTextStyle Style for overriding the label text style
 * @param {number} props.labelOpacity Opacity of the label, use an animated value for a fade animation
 * @returns {JSX.Element} A Label exclusively for LxReactHorizontalSlider
 * @constructor
 */
export default function LxReactSliderAnimatedLabel(props) {
    return (
        <View style={{position: 'relative'}}>
            {Number.isFinite(props.oneMarkerLeftPosition) &&
                Number.isFinite(props.oneMarkerValue) && (
                    <Animated.View style={[styles.labelContainer, {
                        opacity: props.labelOpacity,
                        left: props.oneMarkerLeftPosition - width / 2 + sliderRadius
                    }]}>
                        <View style={styles.label}>
                            <LxReactText
                                style={[props.labelTextStyle, styles.labelText]}>{props.oneMarkerValue}</LxReactText>
                        </View>
                    </Animated.View>
                )}

            {Number.isFinite(props.twoMarkerLeftPosition) &&
                Number.isFinite(props.twoMarkerValue) && (
                    <Animated.View style={[styles.labelContainer, {
                        opacity: props.labelOpacity,
                        left: props.twoMarkerLeftPosition - width / 2 + sliderRadius
                    }]}>
                        <View style={styles.label}>
                            <LxReactText
                                style={[props.labelTextStyle, styles.labelText]}>{props.twoMarkerValue}</LxReactText>
                        </View>
                    </Animated.View>
                )}
        </View>
    );
}

LxReactSliderAnimatedLabel.propTypes = {
    oneMarkerValue: PropTypes.number,
    twoMarkerValue: PropTypes.number,
    oneMarkerLeftPosition: PropTypes.number,
    twoMarkerLeftPosition: PropTypes.number,
    oneMarkerPressed: PropTypes.bool,
    twoMarkerPressed: PropTypes.bool,
    textColor: PropTypes.string,
    labelTextStyle: PropTypes.object,
    labelOpacity: PropTypes.number
}

const styles = {
    label: {
        width: 30,
        height: 24,
        alignItems: "center",
        justifyContent: "center",
        padding: 4,
        marginLeft: 6,
        marginBottom: 20,
        backgroundColor: globalStyles.colors.grey["900"],
        borderRadius: 4,
    },
    labelContainer: {
        position: 'absolute',
        bottom: 0,
        flexDirection: "column",
        alignItems: "center"
    },
    labelText: {
        fontSize: globalStyles.fontSettings.sizes.small,
        fontFamily: globalStyles.fontSettings.families.regular,
        fontWeight: 600,
        lineHeight: 16,
    }
}



