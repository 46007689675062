import {DEMAND, MODE, SCREEN_STATE} from "./climateControlEnums";
import {getSubmenuCell} from "../../UtilityComp/utilities/helper";
import globalStyles from "GlobalStyles";

export default class ClimateControlStatesSrc {
    static getStateSections(control, states, navigation) {
        let sections = [],
            settingsSection = {
                rows: [getSubmenuCell(_('settings'), this._openSettings.bind(this, control, navigation))]
            };
        this.isInServiceMode = states.currentMode === MODE.SERVICE_MODE; //needed by ContextMenuOptions in ControlContent

        if (!this.isInServiceMode) {
            sections = sections.concat(this._getControlSections(control, states)); // We don't need to show the settings cell if we don't have any settings

            if (states.nextMaintenance > 0 || Feature.FULL_CLIMATE) {
                sections.push(settingsSection);
            }
        }

        return sections;
    }

    /**
     * Sorts all controls into its correct section
     * @param states
     * @param control
     * @return {Array}
     * @private
     */
    static _getControlSections(control, states) {
        let ircV2Control,
            sortedControls = {},
            // Map of the controls, key is the demand, value is the array of controls matching the demand
            sections = [],
            currentDemand = control.getCurrentDemand(); // Sort each IRCv2 control into its correct section

        states.controls && states.controls.forEach((control) => {
            ircV2Control = ActiveMSComponent.getControlByUUID(control.uuid); // Controls may not be visualized and not be found in the LoxApp3.json

            if (ircV2Control) {
                // Check if the demand section already exists, directly add the control if it does, add a new array if it doesn't
                if (sortedControls[control.demand]) {
                    sortedControls[control.demand].push(ircV2Control);
                } else {
                    sortedControls[control.demand] = [ircV2Control];
                }
            }
        }); // =====================================================
        // Keep the order
        // 1. Current demand
        // 2. Heating or Cooling depending on the current demand
        // 2. Cooling or Heating depending on the current demand
        // 3. None, if it isn't the current demand
        // Always move the current demand section to the top

        if (sortedControls[currentDemand]) {
            sections.pushObject(this._getSectionForDemand(currentDemand, sortedControls, sections.length));
        }

        if (currentDemand !== DEMAND.HEATING) {
            sections.pushObject(this._getSectionForDemand(DEMAND.HEATING, sortedControls, sections.length));
        }

        if (currentDemand !== DEMAND.COOLING) {
            sections.pushObject(this._getSectionForDemand(DEMAND.COOLING, sortedControls, sections.length));
        }

        if (currentDemand !== DEMAND.NONE) {
            sections.pushObject(this._getSectionForDemand(DEMAND.NONE, sortedControls, sections.length));
        } // =====================================================


        return sections;
    }

   static  _getSectionForDemand(demand, sortedControls, sectionIdx) {
        if (sortedControls[demand]) {
            return {
                headerStrongTitle: this._demandToReadableString(demand),
                headerTitleStyle: sectionIdx > 0 ? styles.DemandSectionHeader : {},
                rows: sortedControls[demand].sort((el1, el2) => {
                    return el1.getName().localeCompare(el2.getName());
                }).map(this._getIRCv2Cell.bind(this))
            };
        }
    }

    /**
     * Simply returns a controlCell for the given control
     * @param control
     * @return {{type: (*|string), content: {control: *, displayAsCell: boolean, showGroupDetails: boolean}}}
     * @private
     */
    static _getIRCv2Cell(control) {
        return {
            type: "ControlBaseCard",
            props: {
                controlUuid: control.uuidAction,
                displayAsCell: true,
                showGroupDetails: false
            }
        };
    }

    /**
     * Converts the demand ID to a human readable string
     * @param demandId
     * @return {*}
     * @private
     */
    static _demandToReadableString(demandId) {
        let demandString;

        switch (parseInt(demandId)) {
            // demandId is a string, switch case only works on same types (like ====)
            case DEMAND.COOLING:
                demandString = _("controls.climate.demand.cooling");
                break;

            case DEMAND.NONE:
                demandString = _("controls.climate.demand.none");
                break;

            case DEMAND.HEATING:
                demandString = _("controls.climate.demand.heating");
                break;
        }

        return demandString;
    }

    static _openSettings(control, navigation) {
        navigation.navigate(SCREEN_STATE.SETTINGS);
    }

}


const styles = {
    DemandSectionHeader: {
        marginTop: globalStyles.spacings.gaps.regular
    }
}