import * as React from "react"
import Svg, { Path } from "react-native-svg"

function ShieldEnergy(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.871 4.538H1.87a.499.499 0 00-.37.483c0 8.57.761 15.406 10.326 18.948a.5.5 0 00.348 0C21.739 20.427 22.5 13.59 22.5 5.021a.499.499 0 00-.37-.483h-.001C21.995 4.5 14.72 2.51 12.363.148a.534.534 0 00-.723 0C9.278 2.511 2.007 4.5 1.871 4.537zM12.75 6.596a.239.239 0 01.287-.076.228.228 0 01.134.259l-.878 3.846h3.472c.088 0 .169.048.21.125a.224.224 0 01-.019.237l-4.706 6.417a.238.238 0 01-.287.076.228.228 0 01-.134-.26l.878-3.845H8.235a.237.237 0 01-.209-.124.225.225 0 01.018-.239l4.706-6.416z"
            />
        </Svg>
    )
}

export default ShieldEnergy
