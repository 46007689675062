import {BehaviorSubject} from "rxjs";

class EditEntryDataService{
    modes = new BehaviorSubject([]);

    init(initialModes) {
        this.modes.next(initialModes)
    }

    addMode(modeNr) {
        let newModes = this.modes.value;
        newModes.push(modeNr)
        this.sort(newModes)
        this.modes.next(newModes)
    }

    removeMode(modeNr) {
        let newModes = this.modes.value;
        newModes.splice(newModes.indexOf(modeNr), 1)
        this.sort(newModes)
        this.modes.next(newModes)
    }

    sort(modes) {
        return modes.sort((a,b) => a-b)
    }

    getSelectedModes() {
        return this.modes.asObservable();
    }
}

var EditEntrySingleton = (function () {
    var instance;

    function createInstance() {
       return new EditEntryDataService()
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

export default EditEntrySingleton;

