'use strict';
/**
 * This view is designed to create or modify active sequences or to pick/save a sequence from/to the global sequence
 * favorites.
 * Created by loxone on 08.03.17.
 *
 *
 * a sequence looks like this:
 * {
 *      colors: [ "hsv(100,80,100)", "hsv(200,100,100)", "hsv(150,80,100)"],
 *      interval: 7200
 * }
 */

define(['SelectorPageBase', 'ColorPickerV2ControlEnums'], function (SelectorPageBase, ColorPickerV2ControlEnums) {
    var MAX_SEQ_COLOR_INTERVAL = 60 * 60,
        // 1 hr
        MIN_SEQ_COLOR_INTERVAL = 1,
        // 1 sec, see https://www.wrike.com/open.htm?id=163165004
        DEF_SEQ_COLOR_INTERVAL = 30 * 60; // 30 mins

    var INTERVAL_SEND_DELAY = 2000; // the interval is only updated every 2 seconds.

    return class SequenceSelectorPage extends SelectorPageBase {
        constructor(control) {
            super(...arguments);
            this.selectionCache = null;
        }

        viewDidAppear() {
            var baseCall = super.viewDidAppear(...arguments);
            this.colorSelector.onDeleteColor = this.respondToColorDeleted.bind(this);
            return baseCall;
        }

        viewWillDisappear() {
            this.colorSelector.onDeleteColor = null;

            if (this.updateIntervalTimeout) {
                clearTimeout(this.updateIntervalTimeout);

                this._sendSequence(this.selection);
            }

            return super.viewWillDisappear(...arguments);
        }

        receivedStates(states) {
            this.states = states;
            var newSelection = cloneObject(states.sequence); // selection & favorites are important in the baseclass

            if (this.antiGhostTimer.isActive()) {
                // cache the data, it'll be update later when the anti ghost timer fires.
                this.selectionCache = newSelection;
            } else {
                // update the data right away, the UI will be updated in the base class.
                this._updateSequenceSelection(newSelection);
            }

            this.favorites = states.favSequences;
            super.receivedStates(...arguments);
        }

        /**
         * Called when a color is to be deleted;
         * @param idx       the index of the picked color (for sequences)
         */
        respondToColorDeleted(idx) {
            // start an anti ghost timer that avoids updating the UI with state updates for several seconds.
            this.antiGhostTimer.fire();
            this.selection.colors.splice(idx, 1);

            this._sendSequence(this.selection);
        }

        // ------------------------------------------------------------------------
        //            Implemented Abstract Baseclass Methods
        // ------------------------------------------------------------------------

        /**
         * Returns the value for the brightness slider.
         */
        getBrightnessValue() {
            var brightness;

            if (this.selection && this.selection.colors.length > 0) {
                // retrieve it from one of the colors in the sequence array, it's always the same brightness
                brightness = this.selection.colors[0].brightness;
            } else {
                brightness = this.activeBrightness;
            }

            return brightness;
        }

        /**
         * Used for the color selector, it returns the current selector mode (HSV, Temp or sequence)
         */
        getSelectorMode() {
            return ColorPickerV2ControlEnums.PickerMode.SEQUENCE;
        }

        /**
         * Returns an array of colors to show as gradient in the previewBar
         */
        getPreviewColors() {
            Debug.Control.ColorPickerV2 && console.log(this.name, "getPreviewColors: " + JSON.stringify(this.selection.colors));
            return this.selection.colors;
        }

        /**
         * Will be called whenever one of the favorites has been selected.
         * @param idx       the index of the picked favorites inside the fav array.
         * @param fav       the favorite object that has been picked.
         */
        handleFavoriteSelected(idx, fav) {
            // antiGhostTime needs to be reseted before, setting the sequenzer
            this.antiGhostTimer.resetAntiGhostTimer();

            this._sendSequence(fav);
        }

        /**
         * Will delete the favorite at the specified position.
         * @param favIdx    what favorite to delete
         */
        handleFavoriteDelete(favIdx) {
            this.sendCommand(Commands.format(Commands.COLOR_PICKER_V2.DEL_FAV_SEQUENCE, favIdx));
        }

        /**
         * Will save the current selection as new favorite at the specified position.
         * @param newIdx        where to save the new favorite to.
         */
        handleFavoriteSave(newIdx) {
            var colors = this._getSequenceColorsArg(this.selection),
                interval = this.selection.interval,
                allowedToSendCommand = this._checkFavSequencerIfExisting();

            if (!allowedToSendCommand) {
                this.sendCommand(Commands.format(Commands.COLOR_PICKER_V2.SET_FAV_SEQUENCE, newIdx, interval, colors));
            }
        }

        /**
         * Called whenever the user modifies the brightness.
         * @param newBrightness
         * @param isDragging
         */
        handleBrightnessChanged(newBrightness, isDragging) {
            var cmd = Commands.format(Commands.COLOR_PICKER_V2.SET_BRIGHTNESS, newBrightness);
            this.sendCommand(cmd, Commands.Type.OVERRIDE, null, isDragging);
        }

        /**
         * Called when a color is picked/changed using the colorSelection.
         * @param newColor  the current picked color
         * @param idx       the index of the picked color (for sequences)
         * @param dragging  if the selection process is still ongoing (still dragging along)
         */
        handleColorSelected(newColor, idx, dragging) {
            if (this.control.isInDayLightMode()) {
                this.selection.colors = [];
            }

            if (idx >= this.selection.colors.length) {
                this.selection.colors.push(newColor);
            } else {
                this.selection.colors[idx] = newColor;
            }

            if (dragging) {
                this.sendColorObject(newColor, true);
            } else {
                this._sendSequence(this.selection);
            }
        }

        /**
         * Called by baseclasses antiGhostTimeout. If forceUpdate is true, the UI (selector) will be updated.
         * @param forceUpdate
         */
        handleAntiGhostTimeout(forceUpdate) {
            var update = forceUpdate;

            if (this.selectionCache) {
                this._updateSequenceSelection(this.selectionCache);

                this.selectionCache = null;
                update = true;
            }

            super.handleAntiGhostTimeout(update);
        }

        // ------------------------------------------------------------------------
        //            TableView Base
        // ------------------------------------------------------------------------

        /**
         * For sequences there are always two cells. brightness & interval
         * @returns {number}
         */
        numberOfRowsInSection() {
            return 2;
        }

        /**
         * The brightness-cell uses a gradient slider
         * @returns {string}
         */
        cellTypeForCellAtIndex(section, row) {
            if (row === 1) {
                return GUI.TableViewV2.CellType.SLIDER;
            } else {
                return super.cellTypeForCellAtIndex(...arguments);
            }
        }

        contentForCell(cell, section, row) {
            if (row === 1) {
                return {
                    title: _("controls.colorpickerV2.sequence.interval"),
                    value: this.selection ? this.selection.interval : DEF_SEQ_COLOR_INTERVAL,
                    formatWithHTML: "<v.durs>",
                    minValue: MIN_SEQ_COLOR_INTERVAL,
                    maxValue: MAX_SEQ_COLOR_INTERVAL,
                    minIconSrc: Icon.MINUS,
                    maxIconSrc: Icon.PLUS,
                    step: 1
                };
            } else {
                return super.contentForCell(...arguments);
            }
        }

        sliderDragged(cell, section, row, tableView, value, isDragging) {
            if (row === 1) {
                this._updateInterval(value, isDragging);
            } else {
                super.sliderDragged(...arguments);
            }
        }

        sliderClicked(cell, section, row, tableView, value) {
            if (row === 1) {
                this._updateInterval(value);
            } else {
                super.sliderDragged(...arguments);
            }
        }

        /**
         * Will set the brightness cells slider position according to the current value.
         */
        updateBrightnessCell() {
            let wantedCell = null;
            this.processWhenVisible(function () {
                wantedCell = this.tableView.cellForSectionAndRow(0, 0);
                if (wantedCell) {
                    wantedCell.setPosition(this.getBrightnessWithCache());
                }
            }.bind(this));
        }

        // ------------------------------------------------------------------------
        //            Private Methods
        // ------------------------------------------------------------------------

        /**
         * Will only update the local attributes and the interval cell. The selection view is updated inside
         * the base class.
         * @param newSequence
         * @private
         */
        _updateSequenceSelection(newSequence) {
            this.selection = newSequence;
            let wantedCell = null;
            this.processWhenVisible(function () {
                wantedCell = this.tableView.cellForSectionAndRow(0, 1)
                if (wantedCell) {
                    wantedCell.setPosition(this.selection.interval);
                }
            }.bind(this));
        }

        /**
         * Will send an update that updates the interval. It has a timeout that ensures that these values are not
         * being sent too often as it causes a lot of work on the Miniserver.
         * @param seconds   the new color changing interval in seconds
         * @param dragging  true if the update comes from a slider being dragged (= don't send it too often)
         * @private
         */
        _updateInterval(seconds, dragging) {
            this.selection.interval = seconds;
            this.updateIntervalTimeout && clearTimeout(this.updateIntervalTimeout);

            if (dragging) {
                // ensure the interval isn't sent too often.
                this.updateIntervalTimeout = setTimeout(function () {
                    this._sendSequence(this.selection);

                    this.updateIntervalTimeout = null;
                }.bind(this), INTERVAL_SEND_DELAY);
            } else {
                // sends straight away.
                this._sendSequence(this.selection);
            }
        }

        /**
         * Converts seconds into an array of hours and minutes.
         * @param seconds
         * @returns {[hours,minutes]}
         * @private
         */
        _getTimeForPicker(seconds) {
            var minutes = parseInt(seconds / 60),
                hours = parseInt(minutes / 60);
            minutes = minutes % 60;
            return [hours, minutes];
        }

        /**
         * The sequenced passed into this method will be sent to the Miniserver and the color picker output will
         * start playing the sequence.
         * @param sequence
         * @private
         */
        _sendSequence(sequence) {
            var colorText = this._getSequenceColorsArg(sequence),
                cmd;

            cmd = Commands.format(Commands.COLOR_PICKER_V2.SET_SEQUENCE, sequence.interval, colorText);
            this.sendCommand(cmd, Commands.Type.OVERRIDE);
        }

        /**
         * Will return a string containing all the colors of the sequence separated by slashes. There is no slash
         * at the beginning or end of the string.
         * @param sequence      the sequence of which the colorargument is needed.
         * @returns {string}    e.g. "hsv(100,100,100)/hsv(300,50,100)/hsv(200,100,100)"
         * @private
         */
        _getSequenceColorsArg(sequence) {
            var colorArgs = [];
            sequence.colors.forEach(function (color) {
                colorArgs.push(LxColorUtils.getStringFromColorObject(color));
            });
            return colorArgs.join("/");
        }

        /**
         * Compairs the new sequencer if the same configuration was allready send
         * @returns {boolean}
         * @private
         */
        _checkFavSequencerIfExisting() {
            var cachedStates = this.states,
                newSelection = toString(this.selection.interval) + "/" + this._getSequenceColorsArg(this.selection),
                isSequencerExisting = false,
                savedColor;

            cachedStates.favSequences.forEach(function (favSequence) {
                savedColor = this._getSequenceColorsArg(favSequence);
                savedColor = toString(favSequence.interval) + "/" + savedColor;
                isSequencerExisting = newSelection === savedColor;
            }.bind(this));
            return isSequencerExisting;
        }

    };
});
