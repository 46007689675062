import {LxBaseControlContent, LxReactVerticalSliderButtonView, ControlContext} from "LxComponents";
import {useContext, useMemo} from "react";

function DimmerControlContent (props) {
    const {control, states} = useContext(ControlContext)

    const sliderConfig = useMemo(() => {
        return {
            max: states.max,
            min: states.min,
            step: states.step,
            antiGhostTimer: true,
            hasCustomRate: true,
            updateRate: 0.25
        }
    }, [states])

    return <LxBaseControlContent showStateIcon={false}>
        <LxReactVerticalSliderButtonView config={sliderConfig} value={states.pos} onValueChanged={sendValue.bind(this, control)}/>
    </LxBaseControlContent>
}

const sendValue = (control, pos) => {
    control.sendCommand(Commands.format(Commands.DIMMER.VALUE, pos), Commands.Type.OVERRIDE);
}

export default DimmerControlContent;