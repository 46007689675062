'use strict';

define("Control_Central", [], function () {
    var Control_Central = {
        get Mixin() {
            return {
                // ----------- Commands -----------

                /**
                 * sends a command to the grouped control, using the 'selective' command
                 * filters first, if a control should be skipped (eg. vol up only to active zones)
                 * @returns {Promise} resolves if all commands are sent successfully
                 */
                sendCommandGrouped: function sendCommandGrouped(cmd) {
                    var ctrlIds = [],
                        subControlsWithVisuPwd = []; // go through all sub controls. with this approach, we also filter out controls which are not visible for this user, but which are in the central control.

                    this.forEachSubControl(function (ctrl, id) {
                        if (this.isControlSelected(ctrl.uuidAction)) {
                            if (this.filterCommand(ctrl, cmd, ctrl.getStates())) {
                                if (ctrl.isSecured) {
                                    subControlsWithVisuPwd.push(ctrl);
                                }

                                ctrlIds.push(id);
                            }
                        }
                    }.bind(this));
                    return this._checkVisuPwdAndSend(arguments, subControlsWithVisuPwd, ctrlIds);
                },

                /**
                 * if one the controls needs a visu pwd, it asks the user right away if he want's to enter the password or skip the secured controls
                 * @param args
                 * @param subControlsWithVisuPwd
                 * @param ctrlIds
                 * @returns {Promise}
                 * @private
                 */
                _checkVisuPwdAndSend: function _checkVisuPwdAndSend(args, subControlsWithVisuPwd, ctrlIds) {
                    if (!this.isSecured && subControlsWithVisuPwd.length > 0) {
                        if (subControlsWithVisuPwd.length === ctrlIds.length) {
                            // all selected controls need a visu password, ask the user to enter it right away..
                            args[0] = this._prepareSelectiveCmd(ctrlIds, args[0]);
                            return this._forceVisuPassword(args);
                        } else if (SandboxComponent.hasCachedVisuPassword() || SandboxComponent.isRecordingTask()) {
                            // avoid asking the user if he wants to enter the password if he doesn't have to. There is no need
                            // to enter a visu pass while recording a task - it will be requested once the task is stored.
                            return this._respondToPopupSelection(ctrlIds, args, 0); // act like the user has tapped btn0 = "enter visu pwd"
                        }

                        var names = [];
                        this.forEachSubControl(function (ctrl) {
                            if (subControlsWithVisuPwd.indexOf(ctrl) !== -1) {
                                names.push(this.getNameForControlSelection(ctrl));
                            }
                        }.bind(this));
                        var content = {
                            title: _('secured.objects.text', {
                                count: subControlsWithVisuPwd.length
                            }),
                            message: _('secured.objects') + ":<br /><ul><li>" + names.join("</li><li>") + "</li></ul>",
                            buttons: [{
                                title: _('secured.password.enter'),
                                color: window.Styles.colors.green
                            }],
                            buttonCancel: true
                        };
                        content.buttons.push({
                            title: names.length > 1 ? _('secured.objects.skip_plural') : _('secured.objects.skip'),
                            color: window.Styles.colors.green
                        });
                        return NavigationComp.showPopup(content).then(this._respondToPopupSelection.bind(this, ctrlIds, args), function (res) {
                            throw new Error("user canceled sending");
                        });
                    } else if (!this.isControlSelected()) {
                        // prepare the command
                        args[0] = this._prepareSelectiveCmd(ctrlIds, args[0]);
                    }

                    return this._sendCommand.apply(this, args);
                },

                /**
                 * prepares the command with the given ctrlIds
                 * @param cmd
                 * @param ctrlIds
                 * @returns {string} selective command
                 * @private
                 */
                _prepareSelectiveCmd: function _prepareSelectiveCmd(ctrlIds, cmd) {
                    return Commands.format(Commands.CONTROL.SELECTED, ctrlIds.join(","), cmd);
                },

                /**
                 * calls this._sendCommand and forces this.isSecured to true
                 * @param args
                 * @returns {Promise}
                 * @private
                 */
                _forceVisuPassword: function _forceVisuPassword(args) {
                    this.isSecured = true; // temporarily set the secured flag to true, to request the visu password from the user

                    var promise = this._sendCommand.apply(this, args);

                    this.isSecured = false;
                    return promise;
                },

                /**
                 * @param ctrlIds
                 * @param args
                 * @param button nr of button tapped
                 * @returns {*}
                 * @private
                 */
                _respondToPopupSelection: function (ctrlIds, args, button) {
                    if (button === 1) {
                        // send only to all unsecured controls -> filter
                        ctrlIds = ctrlIds.filter(function (ctrlId) {
                            var subCtrl = this.getSubControl(ctrlId);
                            return subCtrl && !subCtrl.isSecured;
                        }.bind(this));
                    } // prepare the command


                    args[0] = this._prepareSelectiveCmd(ctrlIds, args[0]);

                    if (button === 0) {
                        // request visu password
                        return this._forceVisuPassword(args);
                    } else if (button === 1) {
                        // no visu pwd required, just send..
                        return this._sendCommand.apply(this, args);
                    }
                },

                /**
                 * override to filter commands only to certain controls (eg. vol up only to playing zones..)
                 * @param control
                 * @param command
                 * @param states
                 * @returns {boolean} whether or not the command should be sent to this control
                 */
                filterCommand: function filterCommand(control, command, states) {
                    return true;
                },
                isGrouped: function isGrouped() {
                    return this.type.startsWith("Central") && this.details.controls;
                },

                /**
                 * checks if this control is in a room with type Central
                 * @returns {boolean}
                 */
                isInCentralRoom: function isInCentralRoom() {
                    var room = this.getRoom();
                    return room && room.type === RoomType.CENTRAL;
                },
                getCentralIcon: function getCentralIcon() {// override if needed
                },

                /**
                 * updates the selected controls of a grouped control
                 * @param controlUuid if null, all grouped controls will be selected
                 */
                updateGroupSelection: function updateGroupSelection(controlUuid) {
                    var selectedCtrlUuids = this.getSelectedControlUuids();

                    if (controlUuid) {
                        // toggle this control
                        if (selectedCtrlUuids.indexOf(controlUuid) === -1) {
                            selectedCtrlUuids.push(controlUuid);
                        } else {
                            selectedCtrlUuids.splice(selectedCtrlUuids.indexOf(controlUuid), 1);
                        }
                    } else {
                        // select all controls
                        this.selectedControls = [];
                    } // update the states.. we also need states only for the selected controls


                    var sc = SandboxComponent.getStateContainerForUUID(this.uuidAction);
                    sc.updateStatesForSelectedControls();
                },

                /**
                 * checks if the control (or all controls) are selected
                 * @param controlUuid if null, it checks if all controls are selected
                 * @returns {boolean}
                 */
                isControlSelected: function isControlSelected(controlUuid) {
                    var selectedCtrlUuids = this.getSelectedControlUuids();

                    if (controlUuid) {
                        return selectedCtrlUuids.length === 0 || selectedCtrlUuids.indexOf(controlUuid) !== -1;
                    } else {
                        // check if all controls are selected
                        return selectedCtrlUuids.length === 0;
                    }
                },
                getSelectedControlUuids: function getSelectedControlUuids() {
                    if (!this.selectedControls) {
                        this.selectedControls = [];
                    }

                    return this.selectedControls;
                },
                getSelectedControls: function getSelectedControls() {
                    var ctrls = [],
                        selectedCtrlUuids = this.getSelectedControlUuids();
                    this.forEachSubControl(function (ctrl) {
                        if (selectedCtrlUuids.indexOf(ctrl.uuidAction) !== -1) {
                            ctrls.push(ctrl);
                        }
                    }.bind(this));
                    return ctrls;
                },

                /**
                 * returns the correct name for the control selection in ComfortMode
                 * examples:
                 *      central doors, only 2 doors in room 'garage' -> display the control name
                 *      central lights, rooms with 2 lights -> display either the room name (if only 1 light) or the room name + control name
                 *      lights in living room -> display control name
                 * @param control
                 * @returns {string}
                 */
                getNameForControlSelection: function getNameForControlSelection(control) {
                    var roomOfControl = control.getRoom();

                    if (roomOfControl) {
                        // check if more controls of the same type are in the same room
                        var foundControlInSameRoom = this.hasControlInSameRoomAsControl(control),
                            foundControlInOtherRoom = this.hasControlInOtherRoomAsControl(control);

                        if (foundControlInSameRoom && foundControlInOtherRoom) {
                            // we found another control in the same room and other controls in other rooms, prepend room name
                            return roomOfControl.name + SEPARATOR_SYMBOL + control.name;
                        } else if (foundControlInSameRoom) {
                            return control.name;
                        }

                        return roomOfControl.name;
                    } else {// the grouped control is in a room, always show the control name
                    }

                    return control.name;
                },
                hasControlInSameRoomAsControl: function hasControlInSameRoomAsControl(control) {
                    var roomUuid = control.room,
                        foundControlInSameRoom = false;
                    this.forEachSubControl(function (ctrl) {
                        if (ctrl !== control) {
                            foundControlInSameRoom |= ctrl.room === roomUuid;
                        }
                    });
                    return foundControlInSameRoom;
                },
                hasControlInOtherRoomAsControl: function hasControlInOtherRoomAsControl(control) {
                    var roomUuid = control.room,
                        foundControlInOtherRoom = false;
                    this.forEachSubControl(function (ctrl) {
                        if (ctrl !== control) {
                            foundControlInOtherRoom |= ctrl.room !== roomUuid;
                        }
                    });
                    return foundControlInOtherRoom;
                },

                /**
                 * iterates through all controls
                 * if a grouped control is again a grouped, the fn will be called for each single control
                 * @param fn
                 */
                forEachSubControl: function forEachSubControl(fn) {
                    var ctrlIdx = 0;
                    this.details.controls.forEach(function (ctrlInfo, idx) {
                        if (ctrlInfo.control) {
                            fn(ctrlInfo.control, ctrlInfo.id, ctrlIdx);
                            ctrlIdx++;
                        }
                    });
                },

                /**
                 * Returns the referenced controls as array.
                 * @return {Array}
                 */
                getSubControls: function getSubControls() {
                    var subCtrls = [];
                    this.forEachSubControl(function (ctrl) {
                        subCtrls.push(ctrl);
                    });
                    return subCtrls;
                },

                /**
                 * returns the wanted subControl or null
                 * @param [id]
                 * @param [uuidAction]
                 * @param [idx]
                 * @returns {null|Control}
                 */
                getSubControl: function getSubControl(id, uuidAction, idx) {
                    var subCtrl = null;
                    this.forEachSubControl(function (ctrl, ctrlId, ctrlIdx) {
                        if (ctrlId === id || ctrl.uuidAction === uuidAction || ctrlIdx === idx) {
                            subCtrl = ctrl;
                        }
                    });
                    return subCtrl;
                },

                /**
                 * Adopts grouped command arguments, so they look like regular commands for single controls, which can be
                 * translated by our existing translation implemetation.
                 * @param cmdParts
                 * @param cmdArguments
                 */
                adoptSpecificCreateCmdTextArgs: function adoptSpecificCreateCmdTextArgs(cmdParts, cmdArguments) {
                    // check if this is a selective cmd
                    if (cmdParts.length > 1 && cmdParts[1] === Commands.CONTROL.SELECTED.split("/")[0]) {
                        // {uuid}/selectedControls/{csv}/{cmd} --> {uuid}/{cmd}
                        cmdParts.splice(1, 2);
                    }
                }
            };
        }

    };
    return Control_Central;
});
