'use strict';

define("AalEmergencyControl", ["Control", "AalEmergencyControlEnums"], function (Control, AalEmergencyControlEnums) {
    return class AalEmergencyControl extends Control {
        constructor() {
            super(...arguments);
        }

        specificCreateCmdText(cmdParts) {
            var text = null,
                timespan = null;

            if (cmdParts[1] === Commands.AalEmergency.QUIT) {
                text = _("controls.aalemergency.quit-alarm");
            } else if (cmdParts[1] === Commands.AalEmergency.TRIGGER) {
                text = _("controls.aalemergency.call-help");
            } else if (cmdParts[1] === Commands.AalEmergency.DISABLE_ID) {
                timespan = cmdParts[2];

                if (timespan === "0") {
                    text = _("controls.aalemergency.reactivate");
                } else {
                    timespan = LxDate.formatSecondsShort(timespan, true, true);
                    text = _("controls.aalemergency.disable", {
                        duration: timespan
                    });
                }
            }

            if (text !== null) {
                text = this.name + ": " + text;
            }

            return text;
        }

        getFriendlyValueName(event, operator) {
            var name = "";

            if (event.data.stateName === "status") {
                switch (event.value) {
                    case AalEmergencyControlEnums.Status.NORMAL:
                        name = _("controls.aalemergency.ready");
                        break;

                    case AalEmergencyControlEnums.Status.TRIGGERED:
                        name = _("controls.aalemergency.alarm-active");
                        break;

                    case AalEmergencyControlEnums.Status.LOCKED:
                        name = _("controls.aalemergency.locked");
                        break;

                    case AalEmergencyControlEnums.Status.DISABLED:
                        name = _("controls.aalemergency.disabled");
                        break;

                    default:
                        break;
                }
            }

            if (name !== "") {
                name = this.name + ": " + name;
            }

            return name;
        }

    };
});
