import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Precipitation(props) {
    return (
        <Svg isreact="true"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 24.1 32"
            xmlSpace="preserve"
            enableBackground="new 0 0 24.1 32"
            {...props}
        >
            <Path
                d="M12.1 32C5.4 32 0 26.6 0 20 0 10.4 12.1 0 12.1 0s12.1 10.4 12.1 20c-.1 6.6-5.5 12-12.1 12zm0-28.8S2.4 12 2.4 20c0 5.3 4.3 9.6 9.7 9.6 5.3 0 9.7-4.3 9.7-9.6-.1-8-9.7-16.8-9.7-16.8z"
                fillRule="evenodd"
                clipRule="evenodd"
            />
            <Path
                className="water"
                d="M4 19v.3c0 4.5 3.6 8.1 8.1 8.1s8.1-3.6 8.1-8.1c0-.4 0-.9-.1-1.3-2.5 4.5-11.8-1.6-16.1 1z"
                fillRule="evenodd"
                clipRule="evenodd"
            />
        </Svg>
    )
}

export default Precipitation
