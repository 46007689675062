
/**
 * This should be an interface in the typescript world.
 * */
class StorageHandlerBase {
    constructor() {
    }

    saveFile(fileName, data, dataType) {
        throw new Error("PersistenceHandlerBase must be subclassed!");
    }

    getFile(fileName, dataType) {
        throw new Error("PersistenceHandlerBase must be subclassed!");
    }

    deleteFile(fileName) {
        throw new Error("PersistenceHandlerBase must be subclassed!");
    }
}

export default StorageHandlerBase;