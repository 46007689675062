'use strict';

define([], function () {
    return class ServiceModeList extends GUI.View {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                var template = '<div class="service-mode-list">';

                for (var i = 1; i <= 4; i++) {
                    template += createCell(i);
                }

                return template + '</div>';
            };

            var createCell = function createCell(index) {
                var cellTitle;

                if (index === 1) {
                    cellTitle = "controls.irc.service.no-heat-no-cool";
                } else if (index === 2) {
                    cellTitle = "controls.irc.service.heat-no-cool";
                } else if (index === 3) {
                    cellTitle = "controls.irc.service.no-heat-cool";
                } else if (index === 4) {
                    cellTitle = "controls.irc.service.heat-cool";
                }

                return '<div id="' + index + '-service-mode" class="list__cell">' + '<div class="cell__selection-circle"></div>' + '<div class="cell__title">' + _(cellTitle) + '</div>' + '</div>';
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor() {
            super($(ServiceModeList.Template.getTemplate()));
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.elements = {
                cells: this.element.find('.list__cell')
            };
            this.buttonHandlers = [];

            for (var i = 0; i < this.elements.cells.length; i++) {
                this.buttonHandlers.push(Hammer(this.elements.cells[i]));
            }

            this.boundServiceModeTapped = this._handleServiceModeTapped.bind(this);
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);

            for (var i = 0; i < this.elements.cells.length; i++) {
                this.buttonHandlers[i].on(tapEvent(), this.boundServiceModeTapped);
            }
        }

        viewWillDisappear() {
            for (var i = 0; i < this.elements.cells.length; i++) {
                this.buttonHandlers[i].off(tapEvent(), this.boundServiceModeTapped);
            }

            super.viewWillDisappear(...arguments);
        }

        destroy() {
            for (var i = 0; i < this.elements.cells.length; i++) {
                this.buttonHandlers[i].dispose();
            }

            super.destroy(...arguments);
        }

        updateList(states) {
            this.elements.cells.removeClass('cell--selected');
            this.elements.cells.closest('#' + states.serviceMode + '-service-mode').addClass('cell--selected');
        }

        _handleServiceModeTapped(event) {
            var serviceModeNr = parseInt(event.currentTarget.id);
            this.sendCommand(Commands.format(Commands.I_ROOM_CONTROLLER.SERVICE, serviceModeNr));
        }

    };
});
