'use strict';

define(["JalousieControlEnums", "JalousieControlContentConfigHelpScreen"], function (JalousieControlEnums, JalousieControlContentConfigHelpScreen) {
    return class JalousieControlContentConfigInfoScreen extends Controls.ControlContent {
        //region Static
        static Template = function () {
            var getView = function getView(ctrl, type) {
                return $('<div class="jalousie-control-content-config-info-screen__container-view">' + '   <div class="container-view__icon-container">' + '       ' + getIconForType(type) + '   </div>' + '   <div class="container-view__text-container">' + '       <div class="text-container__title">' + getTitleForType(type) + '</div>' + '       <div class="text-container__sub-title">' + getSubTitleForType(ctrl, type) + '</div>' + '       ' + getInfoButtonForType(type) + '       <div class="text-container__button">' + '       ' + getButtonTitleForType(type) + '       </div>' + '   </div>' + '</div>');
            };

            var getIconForType = function getIconForType(type) {
                if (type === JalousieControlEnums.InfoScreenTypes.INFO) {
                    return ImageBox.getResourceImageWithClasses("resources/Images/Controls/Jalousie/jalousie/config/icon-shading-w-frame.svg", "icon");
                } else if (type === JalousieControlEnums.InfoScreenTypes.SUCCESS) {
                    return ImageBox.getResourceImageWithClasses("resources/Images/General/Popups/success.svg", "icon");
                } else if (type === JalousieControlEnums.InfoScreenTypes.START_SLAT_ADJUSTMENT) {
                    return ImageBox.getResourceImageWithClasses("resources/Images/Controls/Jalousie/jalousie/config/icon-lamellen.svg", "icon");
                }
            };

            var getTitleForType = function getTitleForType(type) {
                if (type === JalousieControlEnums.InfoScreenTypes.INFO) {
                    return _('controls.jalousie.config.one-step.title');
                } else if (type === JalousieControlEnums.InfoScreenTypes.SUCCESS) {
                    return _('controls.jalousie.config.success.title');
                } else if (type === JalousieControlEnums.InfoScreenTypes.START_SLAT_ADJUSTMENT) {
                    return _('controls.jalousie.config.settings.correct-slats-position');
                }
            };

            var getSubTitleForType = function getSubTitleForType(ctrl, type) {
                if (type === JalousieControlEnums.InfoScreenTypes.INFO) {
                    return _('controls.jalousie.config.one-step.sub-title', {
                        type: _('controls.jalousie.config.shading.salutation')
                    });
                } else if (type === JalousieControlEnums.InfoScreenTypes.SUCCESS) {
                    return _('controls.jalousie.config.success.sub-title', {
                        type: _('controls.jalousie.config.shading.salutation')
                    });
                } else if (type === JalousieControlEnums.InfoScreenTypes.START_SLAT_ADJUSTMENT) {
                    return _('practical-tip.read-before');
                }
            };

            var getInfoButtonForType = function getInfoButtonForType(type) {
                if (type === JalousieControlEnums.InfoScreenTypes.START_SLAT_ADJUSTMENT) {
                    return '<div class="text-container__info-button">' + '   ' + ImageBox.getResourceImageWithClasses("resources/Images/ActiveMiniserver/Menu/icon-info.svg", "info-button__icon") + '   <div class="info-button__text">' + _('practical-tip') + '</div>' + '</div>';
                }

                return '';
            };

            var getButtonTitleForType = function getButtonTitleForType(type) {
                if (type === JalousieControlEnums.InfoScreenTypes.INFO) {
                    return _('controls.jalousie.config.one-step.button-title').toUpperCase();
                } else if (type === JalousieControlEnums.InfoScreenTypes.SUCCESS) {
                    return _('finish').toUpperCase();
                } else if (type === JalousieControlEnums.InfoScreenTypes.START_SLAT_ADJUSTMENT) {
                    return _('controls.jalousie.config.settings.correct-slats-position.start').toUpperCase();
                }
            };

            return {
                getView: getView
            };
        }(); //endregion Static

        constructor(details) {
            super(details);
            this.type = details.type;
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.containerView = JalousieControlContentConfigInfoScreen.Template.getView(this.control, this.type);
            this.actionButton = new GUI.LxButton(this, this.containerView.find(".text-container__button")[0], Color.BUTTON_GLOW, null, true);
            this.actionButton.useElementAsActivePart();
            this.addToHandledSubviews(this.actionButton);
            this.actionButton.onButtonReleased = this._handleActionButton.bind(this);
            this.element.append(this.containerView);

            if (this.type === JalousieControlEnums.InfoScreenTypes.SUCCESS) {
                this.tableView = new GUI.TableViewV2(this, this);
                this.tableView.getElement().addClass("jalousie-control-content-config-info-screen__table-view");
                this.appendSubview(this.tableView);
                this.tableView.reload();
            } else if (this.type === JalousieControlEnums.InfoScreenTypes.START_SLAT_ADJUSTMENT) {
                this.infoButton = new GUI.LxButton(this, this.containerView.find(".text-container__info-button")[0], window.Styles.colors.green, null, true);
                this.actionButton.setActiveParts([{
                    part: this.containerView.find(".text-container__info-button .info-button__icon")[0],
                    mode: "fill"
                }, {
                    part: this.containerView.find(".text-container__info-button .info-button__text")[0],
                    mode: "color"
                }]);
                this.addToHandledSubviews(this.infoButton);
                this.infoButton.onButtonReleased = this._handleInfoButton.bind(this);
            }
        }

        getAnimation() {
            return AnimationType.FADE;
        }

        getURL() {
            return "Info-" + this.type;
        }

        // TableViewDataSource methods
        styleForTableView(tableView) {
            return HD_APP ? TableViewStyle.GROUPED : null;
        }

        numberOfSections() {
            return 1;
        }

        numberOfRowsInSection(section) {
            return 1;
        }

        cellTypeForCellAtIndex(section, row) {
            return GUI.TableViewV2.CellType.GENERAL;
        }

        contentForCell(cell, section, row) {
            return {
                title: _("controls.jalousie.config.success.more-settings"),
                disclosureIcon: true,
                clickable: true
            };
        }

        // TableViewDelegate methods
        didSelectCell(cell, section, row) {
            this.ViewController.showState(JalousieControlEnums.ScreenState.SETTINGS, null, {
                control: this.control
            });
        }

        _handleActionButton() {
            if (this.type === JalousieControlEnums.InfoScreenTypes.INFO) {
                // info = first time configuration!
                // buttonOk -> start new configuration..
                var popupContent = {
                    message: _("controls.jalousie.config.service-mode-popup.message"),
                    buttonOk: _("controls.jalousie.config.service-mode-popup.activate-button"),
                    buttonCancel: _('cancel').toUpperCase(),
                    icon: Icon.WRENCH,
                    color: window.Styles.colors.red
                };
                NavigationComp.showPopup(popupContent).done(function () {
                    var cmd = JalousieControlEnums.ConfigModes.SET_END_POSITION;

                    if (Feature.JAL_CONFIG_SERVICE_MODE_CMD) {
                        cmd = Commands.JALOUSIE.CONFIG.SERVICE_MODE;
                    }

                    this.sendCommand(Commands.format(Commands.JALOUSIE.CONFIG.LEARN, cmd));
                }.bind(this));
            } else if (this.type === JalousieControlEnums.InfoScreenTypes.SUCCESS) {
                this.ViewController.navigateBack();
            } else if (this.type === JalousieControlEnums.InfoScreenTypes.START_SLAT_ADJUSTMENT) {
                // start slats adjustment
                this.sendCommand(Commands.format(Commands.JALOUSIE.CONFIG.LEARN, JalousieControlEnums.ConfigModes.ADJUST_SLATS));
            }
        }

        _handleInfoButton() {
            //if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface) {
            //    NavigationComp.openWebsite(_('controls.jalousie.config.slats-help-link'));
            //} else {
            new JalousieControlContentConfigHelpScreen().show(); //}
        }

    };
});
