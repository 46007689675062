import globalStyles from "GlobalStyles";
import {View} from "react-native";
import {LxReactText} from "LxComponents";

function TabHeader({title, rightChild}) {
    return  (<View style={styles.topContainer}>
        <LxReactText style={styles.miniserverTitle}>
            {ActiveMSComponent.getMiniserverName().toUpperCase()}
        </LxReactText>
        <View style={styles.headerContainer}>
            <LxReactText
                numberOfLines={1}
                style={styles.title}>
                {title.toUpperCase()}
            </LxReactText>
            {rightChild}
        </View>
    </View>);
}


const styles = {
    topContainer: {
        flexDirection: "column",
        width: "auto",
        paddingTop: 36,
        paddingBottom: 24,
        paddingHorizontal: 24
    },
    miniserverTitle: {
        color: globalStyles.colors.text.secondary,
        ...globalStyles.textStyles.footNote.bold,
        letterSpacing: 1
    },
    headerContainer: {
        flexDirection: "row",
        alignItems: "center"
    },
    title: {
        color: globalStyles.colors.white,
        ...globalStyles.textStyles.largeTitle.bold,
        flex: 1,
        textOverflow: "ellipsis",
    }
}

export default TabHeader;