import Svg, { Path } from "react-native-svg"

function Chevron(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 24" {...props}>
            <Path d="M9.916 11.81L3.474 6.061a.24.24 0 00-.176-.06.241.241 0 00-.167.087l-1.073 1.29a.256.256 0 00.028.354l4.873 4.267-4.873 4.27a.254.254 0 00-.027.353l1.072 1.29a.241.241 0 00.185.088.237.237 0 00.158-.061l6.442-5.751a.252.252 0 00.084-.189.252.252 0 00-.084-.189z" />
        </Svg>
    )
}

export default Chevron
