'use strict';

define(["SpotifyContentOverviewScreen", "AudioZoneV2SearchInfoView"], function (SpotifyContentOverviewScreen, AudioZoneV2SearchInfoView) {
    return class SpotifyOnboardingScreen extends SpotifyContentOverviewScreen {
        //region Private
        MAX_OVERVIEW_ITEM_CNT = 50; //endregion Private

        constructor(details) {
            super(...arguments);
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                this.searchView = new AudioZoneV2SearchInfoView(this.control, this, this.isInAmbientMode());
                this.prependSubview(this.searchView);
            }.bind(this));
        }

        _getSection(title, rows, showMoreAction, isFav) {
            return super._getSection(title, rows, false, isFav);
        }

    };
});
