'use strict';

define(['AudioZoneV2ControlEnums', 'SpotifyAccountManager'], function (AudioZoneV2ControlEnums, SpotifyAccountManager) {
    class MediaSearchV2Base extends GUI.Screen {
        //region Private
        EMPTY_VIEW = {
            NO_RESULT: 'noResult',
            NONE: 'none',
            SEARCHING: 'searching'
        }; //endregion Private

        //region Getter
        get inputPlaceholder() {
            return _("media.search.placeholder");
        }

        get isSearching() {
            return this._isSearching;
        }

        get tag() {
            if (this.tags && this.tags.length) {
                return this.tags[this.segmentIndex];
            } else {
                return 'none';
            }
        }

        get searchLocation() {
            return this.tag;
        }

        get contentType() {
            return null;
        }

        get mediaType() {
            return null;
        }

        get username() {
            return MusicServerEnum.NOUSER;
        } //endregion Getter


        //region Setter
        set isSearching(value) {
            this._isSearching = value;

            if (this.searchInput) {
                // Disable the auto focus of the input if we have search results,
                // this way we prevent the keyboard from popping up every time the input is visible again
                this.searchInput.config.autoFocus = !this._isSearching; //this.searchInput.removeFocus();
            }
        } //endregion Setter


        constructor(details) {
            super($('<div/>'));
            applyMixins(this, MediaContextMenuHandlerV2.Mixin);
            this.details = details;
            this.control = details.control;
            this._controlDefer = details.controlDefer;
            this.segmentIndex = 0;
            this.tags = [];
            this._isSearching = false;
            this._gotResults = false;
            this._tableViewMap = {};
        }

        /**
         * Not all views should adopt to ambient mode, hence provide an option to intercept.
         * @returns {*}
         */
        isInAmbientMode() {
           return false; // not all search screen should adopt to ambientMode
        }


        viewDidLoad() {
            var pageViews = [];
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                if (this.tags.length > 1) {
                    this.tags.forEach(function (tag) {
                        this._prepareTableViewForTag(tag);

                        pageViews.push(this._tableViewMap[tag].tableView.getElement());
                    }.bind(this));

                    if (!HD_APP || this.ViewController instanceof GUI.AddMediaViewControllerV2Base) {
                        this.segmentedControl = this._titleBar.segmentedControl;
                    } else {
                        this.segmentedControl = new GUI.LxSegmentedControl(this.tags.map(function (tag) {
                            return {
                                text: this._getNameForTag(tag),
                                tag: tag
                            };
                        }.bind(this)));
                        this.appendSubview(this.segmentedControl);
                    }
                } else {
                    this._prepareTableViewForTag(this.tag);

                    pageViews.push(this._tableViewMap[this.tag].tableView.getElement());
                }

                this.pageView = new GUI.LxPageView(pageViews, false, this._donePaging.bind(this), {
                    overscroll: true
                });
                this.pageView.disableUserInteraction(true);
                this.appendSubview(this.pageView);
                this._controlDefer && this._controlDefer.promise.done(null, null, function controlDeferDone(data) {
                    if (data.hasOwnProperty("searchTerm")) {
                        Debug.Control.AudioZone.GlobalSearch && console.log(this.name, "the control defer is done, search term provided: " + data.searchTerm);

                        this._onSubmit(data.searchTerm, false);
                    } else {
                        console.warn(this.name, "the control defer is done, but no search term provided!");
                    }
                }.bind(this));

                if (!this._controlDefer) {
                    Debug.Control.AudioZone.GlobalSearch && console.warn(this.name, "NO control defer provided!");
                }

                this.segmentedControl && this.segmentedControl.on("on-segment-selected", function (ev, idx) {
                    this.segmentIndex = idx;
                    this.pageView.setActivePage(this.segmentIndex);
                }.bind(this));
            }.bind(this));
        }

        viewDidAppear() {
            if (this.searchInput) {
                this.searchInput.onSubmit = function onSubmit(value) {
                    this.searchInput.removeFocus();

                    this._onSubmit(value, true);
                }.bind(this);

                this.searchInput.onTextChanged = function onTextChanged(value) {
                    this._onSubmit(value, false);
                }.bind(this);
            }

            return super.viewDidAppear(...arguments);
        }

        viewDidDisappear(viewRemainsVisible) {
            // Tell the controlDef that we aren't searching anymore
            this._controlDefer && this._controlDefer.resolve();
            return super.viewDidDisappear(...arguments);
        }

        getAnimation() {
            return AnimationType.FADE;
        }

        getURL() {
            return "Search";
        }

        titleBarConfig() {
            var cfg = {};

            if (!HD_APP || this.ViewController instanceof GUI.AddMediaViewControllerV2Base) {
                if (super.titleBarConfig) {
                    cfg = super.titleBarConfig(...arguments);
                }

                var inputCfg = {
                    type: GUI.LxInputEnum.Type.SEARCH,
                    leftIconSrc: Icon.SEARCH,
                    placeholder: this.inputPlaceholder,
                    style: GUI.LxInputStyle.ROUNDED_SQUARE,
                    resetButton: true,
                    autoFocus: true,
                    isDark: true,
                    isInAmbientMode: this.isInAmbientMode()
                };
                this.searchInput = new GUI.LxInput(inputCfg, this);
                cfg.leftSide = TitleBarCfg.CUSTOM_LX_VIEW;
                cfg.leftSideView = this.searchInput;
                cfg.isInAmbientMode = this.isInAmbientMode();

                if (this.showCancelButton()) {
                    cfg.rightSide = TitleBarCfg.TEXT;
                    cfg.rightText = _('cancel');
                }

                if (this.tags.length > 1) {
                    // Add the segmented control
                    cfg.subTitleBar = TitleBarCfg.Sub.SEGMENTED;
                    cfg.segments = this.tags.map(function (tag) {
                        return {
                            text: this._getNameForTag(tag),
                            tag: tag,
                            type: 'label'
                        };
                    }.bind(this));
                }
            }

            return cfg;
        }

        destroyOnBackNavigation() {
            return true;
        }

        styleForTableView() {
            return TableViewStyle.COMFORT_MODE_2020;
        }

        titleBarText() {
            if (!HD_APP || this.ViewController instanceof GUI.AddMediaViewControllerV2Base) {
                return _('media.search.placeholder');
            }
        }

        showCancelButton() {
            return this.ViewController instanceof GUI.AddMediaViewControllerV2Base || !HD_APP;
        }

        titleBarActionRight() {
            this.ViewController.navigateBack();
        }

        getMediaInfo() {
            return null;
        }

        viewControllerTitleText() {
            return _('media.search.placeholder');
        }

        /**
         * The user did hit enter - this is when the search must be triggered immediately.
         * @param value
         * @param force
         * @private
         */
        _onSubmit(value, force) {
            var src = getStackObj();

            if (value === "") {
                this._clearSearch();
            } else {
                if (value && this._prevSearchTerm !== value) {
                    this._searchTimeout && clearTimeout(this._searchTimeout);
                    this._searchTimeout = null;
                    this._searchTimeout = setTimeout(function _onSubmitSearchTimeout() {
                        this._searchTimeout = null;
                        this._prevSearchTerm = value;
                        this.inputText = value;
                        Debug.Control.AudioZone.GlobalSearch && console.log("_onSubmit - after timeout: " + value + ", src: ", src);

                        this._launchSearchFor(value);
                    }.bind(this), force ? 0 : 1500);
                }
            }
        }

        _clearSearch() {
            this._searchTimeout && clearTimeout(this._searchTimeout);
            this._searchTimeout = null;
            this.searchInput && this.searchInput.setValue('');
            this.isSearching = false;

            this._updateAllTableViews([]);
        }

        _launchSearchFor(keyword) {
            this.keyword = keyword;

            if (!HD_APP) {
                this.searchInput && this.searchInput.setValue(keyword);
            }

            if (this.control.audioserverComp.isSpotifyUrl(keyword)) {
                return this.control.audioserverComp.navigateToSpotifyUrl(keyword, this.ViewController, SpotifyAccountManager.shared(this.control).activeUser);
            }

            this._updateTableContentAndBackgroundViewForTag(this.tag, this.EMPTY_VIEW.SEARCHING);

            this.control.audioserverComp.searchFor(this, this.keyword, this.searchLocation).then(this._onSearchFinished.bind(this), this._onSearchError.bind(this), this._onSearchUpdate.bind(this));
        }

        _onSearchFinished() {
            this.isSearching = false;
        }

        _onSearchError(error) {
            this.isSearching = false;
            this._gotResults = false;
        }

        _onSearchUpdate(update) {
            this.isSearching = true;
            this._gotResults = !!update;

            if (update && (this.tags.length === 0 || this.tags.indexOf(update.type) !== -1)) {
                this._updateTableViewForTag(update.type, update.tableContent);
            }
        }

        _prepareTableViewForTag(tag) {
            this._tableViewMap[tag] = {
                dataSource: tableViewDataSource(null, null, this),
                delegate: tableViewDelegate(null, this)
            };
            this._tableViewMap[tag].tableView = new GUI.TableViewV2(this._tableViewMap[tag].dataSource, this._tableViewMap[tag].delegate);
            this.addToHandledSubviews(this._tableViewMap[tag].tableView);
        }

        _updateAllTableViews(tableContent) {
            return Q.all(this.tags.map(function (tag) {
                return this._updateTableViewForTag(tag, tableContent);
            }.bind(this)));
        }

        _updateTableViewForTag(tag, tableContent) {
            var prms;
            Debug.Control.AudioZone.GlobalSearch && console.log(this.name + ": _updateTableViewForTag: " + tag + " with contentLength:" + tableContent.length);

            if (tableContent && tableContent.length) {
                prms = this._updateTableContentAndBackgroundViewForTag(tag, this.EMPTY_VIEW.NONE, tableContent);
            } else {
                prms = this._updateTableContentAndBackgroundViewForTag(tag, this.EMPTY_VIEW.NO_RESULT);
            }

            return prms;
        }

        _updateTableContentAndBackgroundViewForTag(tag, type, tableContent) {
            var content = tableContent || [];
            Debug.Control.AudioZone.GlobalSearch && console.log(this.name, "_updateTableContentAndBackgroundViewForTag: " + tag + ", type: " + type + ", content length: " + content.length + ", didProvideContent: " + !!tableContent, getStackObj());

            var dsChanged = this._tableViewMap[tag].dataSource.update(content);

            var delChanged = this._tableViewMap[tag].delegate.update(content);

            var promise; // occasionally the reload to present "loading" is still pending while "No_Results" is returned. Due to
            // this the background view may be updated with an outdated info. To prevent this, store the latest bg
            // content type in a dataset to retrieve it once the reload has passed.

            this._bgViewTypes = this._bgViewTypes || {};
            this._bgViewTypes[tag] = type;

            if (dsChanged || delChanged) {
                Debug.Control.AudioZone.GlobalSearch && console.log(this.name, " --> content modified, reload", content);
                promise = this._tableViewMap[tag].tableView.reload();
            } else {
                Debug.Control.AudioZone.GlobalSearch && console.log(this.name, " --> UNCHANGED content, don't reload", content);
                promise = Q.resolve();
            }

            return promise.then(function () {
                switch (this._bgViewTypes[tag]) {
                    case this.EMPTY_VIEW.NO_RESULT:
                        Debug.Control.AudioZone.GlobalSearch && console.log(this.name, "    > " + tag + ": update BG view: NO_RESULT");

                        this._tableViewMap[tag].tableView.setBackgroundView(new GUI.BackgroundView(Icon.ERROR, _("media.global-search.no-result"), null, null, null, true));

                        break;

                    case this.EMPTY_VIEW.NONE:
                        Debug.Control.AudioZone.GlobalSearch && console.log(this.name, "    > " + tag + ": update BG view: NULL = Hide");

                        this._tableViewMap[tag].tableView.setBackgroundView(null);

                        break;

                    case this.EMPTY_VIEW.SEARCHING:
                        Debug.Control.AudioZone.GlobalSearch && console.log(this.name, "    > " + tag + ": update BG view: SEARCHING");

                        this._tableViewMap[tag].tableView.setBackgroundView(new GUI.BackgroundView(Icon.INDICATOR, _('device-learning.searching.title'), null, null, null, true));

                        break;
                }
            }.bind(this));
        }

        _donePaging(idx) {
            this.segmentIndex = idx;
            Debug.Control.AudioZone.GlobalSearch && console.log(this.name + ": segmentIndex --> " + this.segmentIndex);
            this.segmentedControl && this.segmentedControl.setActiveSegment(idx, !this.isVisible(true));
        }

        _getNameForTag(tag) {
            return tag;
        }

    }

    Controls.AudioZoneV2Control.MediaSearchV2Base = MediaSearchV2Base;
    return Controls.AudioZoneV2Control.MediaSearchV2Base;
});
