'use strict';

define("ActivityImageQueue", [], function () {
    if (!("Controls" in window)) {
        window.Controls = {};
    }
    Controls.AtheneControl = Controls.AtheneControl || {};
    Controls.AtheneControl.SingleTons = Controls.AtheneControl.SingleTons || {};

    if (Controls.AtheneControl.SingleTons.ActivityImageQueue) {
        return Controls.AtheneControl.SingleTons.ActivityImageQueue;
    } else {
        class ActivityImageQueue {
            //region Static
            static TYPE = {
                THUMB: "thumb",
                // Will automatically set the image quality to 0.4!
                FULL: "full"
            };
            static MAX_CONCURRENT = 5;
            static TIMEOUT = 10;

            static shared(controlUuid, type) {
                if (!controlUuid) {
                    throw "No controlUuid provided!";
                }

                var instanceId = controlUuid + "_" + type;
                this.__instances = this.__instances || {};

                if (!this.__instances.hasOwnProperty(instanceId)) {
                    this.__instances[instanceId] = new this(this, controlUuid, type);
                }

                return this.__instances[instanceId];
            }

            static destroy(controlUuid) {
                Object.values(this.TYPE).forEach(function (type) {
                    if (controlUuid) {
                        var instanceId = controlUuid + "_" + type;

                        if (this.__instances && this.__instances.hasOwnProperty(instanceId)) {
                            this.__instances[instanceId].destroy();

                            delete this.__instances[instanceId];
                        }
                    }
                }.bind(this));
            } //endregion Static


            constructor(initiator, controlUuid, type) {
                this.name = "ActivityImageQueue-" + type;
                this.controlUuid = controlUuid;
                this.type = type; // Check if the Singletone has been called the right way

                if (!(initiator instanceof Function) && !Controls.AtheneControl.SingleTons.ActivityImageQueue) {
                    throw "The ActivityImageQueue is a Singletone, use it like that! -> ActivityImageQueue.shared(this.control.uuidAction)";
                }

                this._imgQueue = {};
                this._concurrentCnt = 0;
            }

            destroy() {
                Object.values(this._imgQueue).forEach(function (def) {
                    def.reject("canceled");
                }.bind(this));
                this._imgQueue = [];
            }

            requestForUrl(src) {
                var def; // Recycle an already existing request

                if (this._imgQueue.hasOwnProperty(src)) {
                    def = this._imgQueue[src];
                } else {
                    def = Q.defer();
                    def.src = src;
                    this._imgQueue[src] = def;

                    this._processNext();
                }

                def.promise.abort = def.abort = this.abortForUrl.bind(this, src);
                return def.promise;
            }

            abortForUrl(src) {
                var wantedDefs = this._imgQueue[src];

                if (wantedDefs && !wantedDefs.isLoading) {
                    wantedDefs.reject("canceled");
                    delete this._imgQueue[src];
                }
            }

            _processNext() {
                var queuedSources = Object.keys(this._imgQueue);

                if (queuedSources.length) {
                    if (this._concurrentCnt === this.constructor.MAX_CONCURRENT) {
                        Debug.Control.Athene.Activities && console.info(this.name, "Maximum number of concurrent activity fetching for type '" + this.type + "' reached, waiting for a free slot!");
                    } else {
                        var prmsToProcess = Object.values(this._imgQueue).find(function (prms) {
                            return !prms.isLoading;
                        });

                        if (prmsToProcess) {
                            prmsToProcess.isLoading = true;
                            this._concurrentCnt++;

                            var prms = this._loadImageFromSrc(prmsToProcess.src);

                            prmsToProcess.resolve(prms);
                            prms.finally(function () {
                                delete this._imgQueue[prmsToProcess.src];
                                this._concurrentCnt--;
                                setTimeout(this._processNext.bind(this), 100);
                            }.bind(this)); //this._processNext();
                        }
                    }
                }
            }

            _loadImageFromSrc(src) {
                let mediaHandler = Controls.AtheneControl.SingleTons.MediaHandler.shared(ActiveMSComponent.getControlByUUID(this.controlUuid));
                return mediaHandler.verifyReachability().then(() => {
                    return mediaHandler.getAuthToken().then(authToken => {
                        var def = Q.defer(),
                            timeout,
                            quality,
                            isThumb = this.type === this.constructor.TYPE.THUMB,
                            imgElm = new Image();

                        let url = new URL(src);
                        url.searchParams.set("autht", authToken);
                        url.searchParams.set("cacheBuster", Date.now().toString());

                        if (isThumb) {
                            quality = 0.4;
                        }

                        imgElm.onload = function () {
                            clearTimeout(timeout);
                            var canvas = document.createElement("canvas");
                            canvas.width = this.naturalWidth;
                            canvas.height = this.naturalHeight;
                            var ctx = canvas.getContext("2d");
                            ctx.drawImage(this, 0, 0); // After drawing the image we can create a blob from which we can extract an arrayBuffer

                            canvas.toBlob(function (blob) {
                                def.resolve(blob.arrayBuffer().then(function (aBuff) {
                                    Debug.Communication && CommTracker.commFin(CommTracker.Transport.INTERCOM_GEN_2_HTTP, src, false, aBuff); // Use the arrayBuffer with our method to get the base64String

                                    return asyncArrayBufferToBase64String(aBuff).then(function (base64) {
                                        return "data:image/jpg;base64," + base64;
                                    });
                                }));
                            }.bind(this), "image/jpeg", quality);
                        };

                        imgElm.onerror = function (e) {
                            Debug.Communication && CommTracker.commFin(CommTracker.Transport.INTERCOM_GEN_2_HTTP, src, true, e);
                            clearTimeout(timeout);
                            def.reject(e);
                        };

                        imgElm.crossOrigin = ""; // Use a cache buster to always request a new Image, or the Browser cache will return the cached one!
                        // We use our own cache if available

                        imgElm.src = url.toString();
                        Debug.Communication && CommTracker.commStart(CommTracker.Transport.INTERCOM_GEN_2_HTTP, src);
                        timeout = setTimeout(function () {
                            def.reject(new Error("Timeout: No response for " + this.constructor.TIMEOUT + " seconds..."));
                        }.bind(this), this.constructor.TIMEOUT * 1000);
                        return def.promise;
                    });
                });
            }

        }

        Controls.AtheneControl.SingleTons.ActivityImageQueue = ActivityImageQueue;
    }

    return Controls.AtheneControl.SingleTons.ActivityImageQueue;
});
