'use strict';

define([], function () {
    return class ControlActionBaseScreen extends Controls.ControlContent {
        constructor() {
            super(...arguments);
        }

        viewWillAppear() {
            var promises = [super.viewWillAppear(...arguments)]; // Some controls like UpDownLeftRightDigital don't have a StateContainer,
            // so it will never receive any states.
            // Fix it by a force update of the commands table if no StateContainer exists

            var stateContainer = SandboxComponent.getStateContainerForUUID(this.control.uuidAction);

            if (!stateContainer) {
                promises.push(this.receivedStates(this.control.getStates()));
            }

            return Q.all(promises);
        }

        shouldBeUpdated() {
            return false;
        }

        getAnimation() {
            return AnimationType.FADE;
        }

    };
});
