'use strict';

class BackupAndSyncSettingsScreen extends GUI.TableViewScreenV2 {
    constructor(details) {
        super($('<div />'));
        this._isSyncing = false;
        this._miniservers = details.miniservers;
    }

    getAnimation() {
        return AnimationType.NONE;
    }

    titleBarText() {
        return _("backup-and-sync.menu");
    }

    reloadTable() {
        var serviceSection,
            syncSection,
            actionSection,
            platform = PlatformComponent.getPlatformInfoObj().platform,
            platformText = "",
            lastSync = PersistenceComponent.backupAndSyncGetLastSync(),
            dateString = "-";

        if (platform === PlatformType.IOS || PlatformType.DeveloperInterface) {
            platformText = _("backup-and-sync.settings.cells.service.icloud");
        }

        serviceSection = {
            rows: [this._createCell(_("backup-and-sync.settings.cells.service.title"), platformText)]
        };

        if (lastSync) {
            dateString = moment(lastSync).format(DateType.DateTextAndTime);
        }

        syncSection = {
            rows: [this._createCell(_("backup-and-sync.settings.cells.last-sync.title"), dateString), this._createCell(_("backup-and-sync.settings.cells.amount-miniserver.title"), Object.keys(this._miniservers).length.toString())]
        };
        actionSection = {
            rows: [this._isSyncing ? this._createSyncingCell() : this._createCell(_("backup-and-sync.settings.cells.sync-now.title"), null, window.Styles.colors.brand, this._startSync.bind(this)), this._createCell(_("backup-and-sync.settings.cells.deactivate-sync.title"), null, window.Styles.colors.red, this._deactivateSync.bind(this))]
        };
        this.tableContent = [serviceSection, syncSection, actionSection];
        return super.reloadTable(...arguments);
    }

    closeAction() {
        this.ViewController.dismiss();
    }

    _createCell(title, text, color, action) {
        var conf = {
            content: {
                title: title,
                disclosureText: text,
                titleColor: color,
                clickable: !!action
            },
            type: GUI.TableViewV2.CellType.GENERAL,
            action: action
        };
        return conf;
    }

    _createSyncingCell() {
        var conf = {
            content: {
                title: _("backup-and-sync.settings.cells.sync-now.title.working"),
                rightIconSrc: Icon.BACKUP_AND_SYNC,
                rightIconColor: window.Styles.colors.brand,
                clickable: false,
                titleColor: window.Styles.colors.brand
            },
            type: GUI.TableViewV2.CellType.GENERAL
        };
        return conf;
    }

    _startSync(cell, section, row, tableView) {
        this._isSyncing = true;
        this.reloadTable();
        PersistenceComponent.backupAndSyncSynchronize().then(function (res) {
            if (res) {
                this._miniservers = res;
            } else {
                console.warn("Backuped Miniservers are empty");
                this._miniservers = {};
            }
        }.bind(this), function (error) {
            console.error(error);
        }.bind(this)).finally(function () {
            this._isSyncing = false;
            this.reloadTable();
        }.bind(this));
    }

    _deactivateSync(cell, section, row, tableView) {
        NavigationComp.showPopup({
            title: _("backup-and-sync.popup.deactivate.title"),
            message: _("backup-and-sync.popup.deactivate.message"),
            buttonCancel: true,
            buttonOk: _("backup-and-sync.popup.deactivate.deactivate-button-text")
        }).then(function () {
            // Deactivate sync
            PersistenceComponent.backupAndSyncSetEnabled(false);
            this.ViewController.dismiss();
        }.bind(this), function () {// Cancel popup
        }.bind(this));
    }

}

GUI.BackupAndSyncSettingsScreen = BackupAndSyncSettingsScreen;
