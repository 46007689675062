import Svg, { Path } from "react-native-svg"

function PlayCircled(props) {
    return (
        <Svg isreact="true"
            height={24}
            viewBox="0 0 24 24"
            width={24}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zM8 7.5a.5.5 0 01.724-.447l9 4.5a.5.5 0 010 .894l-9 4.5A.5.5 0 018 16.5zm1 .809v7.381L16.381 12z"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default PlayCircled
