import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Close(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path d="M.793 4.793a1 1 0 011.414 0l6.5 6.5a1 1 0 010 1.414l-6.5 6.5a1 1 0 01-1.414-1.414L6.586 12 .793 6.207a1 1 0 010-1.414zM13 18.5a1 1 0 11-2 0v-13a1 1 0 112 0v13zm8.793-13.707a1 1 0 111.414 1.414L17.414 12l5.793 5.793a1 1 0 01-1.414 1.414l-6.5-6.5a1 1 0 010-1.414l6.5-6.5z" />
        </Svg>
    )
}

export default Close
