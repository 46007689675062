/**
 * Contains all additional information used by the autopilot generator (e.g enumeration, ...)
 */
define(function () {
    return {
        /**
         * AutopilotTypes: used to maybe store e.g. taskrecorder tasks, nfc commands later as autopilots
         * for the moment we just use the Type: Autopilot
         */
        AutopilotTypes: {
            Autopilot: 0,
            NFC: 1,
            QR: 2,
            Taskrecorder: 3
        },
        CommunicationEvents: {
            // for internal event triggering and catching
            ADD_EVENT: "autopilot-add-event",
            UPDATE_EVENT: "autopilot-update-event",
            ADD_ACTION: "autopilot-add-action",
            UPDATE_ACTION: "autopilot-update-action"
        },
        EventDetailTypes: {
            // list of possible event detail input types
            Radio: "radio",
            Number: "number",
            DecimalNumber: "decimal",
            Time: "time",
            DateTime: "datetime"
        },

        /**
         * Supported Blocks/BlockIdentifiers for events: list of blocks in the configuration supported as autopilot generator events
         */
        SupportedEventBlocks: [ ControlType.ALARM, ControlType.ALARM_CLOCK, ControlType.CAR_CHARGER, ControlType.DAYTIMER, ControlType.FRONIUS, ControlType.GATE, ControlType.I_ROOM, ControlType.I_Room_V2, ControlType.INFO_ONLY_DIGITAL, ControlType.INFO_ONLY_ANALOG, ControlType.JALOUSIE, ControlType.LIGHT, ControlType.LIGHT_V2, // requires Feature.LIGHTV2_AUTO_EVENTS
            ControlType.METER, ControlType.POWER, ControlType.PUSHBUTTON, ControlType.SWITCH, ControlType.SLIDER, ControlType.WINDOW_MONITOR, ControlType.SOLAR_PUMP, ControlType.STEAK, ControlType.AAL_SMART_ALARM, ControlType.WINDOW, ControlType.MAILBOX, ControlType.AAL_EMERGENCY, ControlType.PRESENCE_DETECTOR ],

        /**
         * Supported Blocks/BlockIdentifiers for actions: list of blocks in the configuration supported as autopilot generator actions
         */
        SupportedActionBlocks: [ ControlType.ALARM, ControlType.ALARM_CLOCK, ControlType.AUDIO_ZONE, ControlType.AUDIO_ZONE_V2, ControlType.CAR_CHARGER, ControlType.DAYTIMER, ControlType.DIMMER, ControlType.DIMMER_EIB, ControlType.GATE, ControlType.HOURCOUNTER, ControlType.INTERCOM, ControlType.I_ROOM, ControlType.I_Room_V2, ControlType.JALOUSIE, ControlType.LEFT_RIGHT_ANALOG, ControlType.LEFT_RIGHT_DIGITAL, ControlType.LIGHT, ControlType.LIGHT_V2, ControlType.LIGHTSCENE_RGB, ControlType.MEDIA, // Old RemoteControl
            ControlType.POOL, ControlType.PUSHBUTTON, ControlType.RADIO, ControlType.REMOTE, ControlType.SAUNA, ControlType.SLIDER, ControlType.SMOKE_ALARM, ControlType.SWITCH, ControlType.TIMED_SWITCH, ControlType.UP_DOWN_ANALOG, ControlType.UP_DOWN_DIGITAL, ControlType.VALUE_SELECTOR, ControlType.STEAK, Control.Type.ALARM_CENTRAL, Control.Type.AUDIO_ZONE_CENTRAL, Control.Type.GATE_CENTRAL, Control.Type.JALOUSIE_CENTRAL, Control.Type.LIGHT_CENTRAL, ControlType.TEXT_INPUT, ControlType.AAL_SMART_ALARM, ControlType.WINDOW, ControlType.MAILBOX, ControlType.AAL_EMERGENCY ],
        BlockIdentifiers: {
            Jalousie: _("wallmount.blinds"),
            IRoomController: _("controls.irc.name"),
            LightController: _("wallmount.lights"),
            Gate: _("controls.gate.name"),
            Alarm: _("controls.alarm"),
            Pushbutton: _("virtual-input"),
            Slider: _("virtual-input-analog"),
            InfoOnlyDigital: _("virtual-state"),
            InfoOnlyAnalog: _("virtual-state-analog")
        },

        /**
         *  AllowedWeatherTypes: List of allowed weather types (events) for the user
         *  We need the filtering, because in the structure file all possible weather types are listed
         */
        AllowedWeatherTypes: {
            1: {
                "analog": true
            },
            // temperature
            3: {
                "analog": true
            },
            // relative humidity
            4: {
                "analog": true
            },
            // wind speed
            5: {
                "analog": true,
                description: _("weather.winddirection.description")
            },
            // wind direction
            7: {
                "analog": true
            },
            // radiation
            9: {
                "analog": true
            },
            // rain
            11: {
                "analog": true
            },
            // pressure
            26: {
                "analog": true
            },
            // temperature
            34: {
                "analog": false
            } // sunshine

        },

        /**
         *  AllowedTimeTypes: List of allowed time types (events) for the user
         *  We need the filtering, because in the structure file all possible time types are listed
         */
        AllowedTimeTypes: {
            269: {
                "analog": true,
                description: "1 - 31"
            },
            // day 1,3,4..31
            //270: {"analog": true}, // weekdays MO/DI/MI and so on     we have operating modes! https://www.wrike.com/open.htm?id=99444924
            281: {
                "analog": false,
                description: _("daylight")
            },
            // daylight
            282: {
                "analog": false,
                description: _("daylight.30")
            },
            // daylight 30 min
            294: {
                "analog": false
            },
            // sunrise
            295: {
                "analog": false
            },
            // sunset
            298: {
                "analog": false
            },
            // dusk
            299: {
                "analog": false
            } // dawn

        },
        Operators: [{
            id: 1,
            title: _("equal")
        }, {
            id: 5,
            title: _("greater-equal")
        }, {
            id: 3,
            title: _("greater")
        }, {
            id: 4,
            title: _("smaller")
        }, {
            id: 6,
            title: _("smaller-equal")
        }],
        OperatorsTitle: {
            1: _("equal"),
            5: _("greater-equal"),
            3: _("greater"),
            4: _("smaller"),
            6: _("smaller-equal")
        },
        DefaultOperator: 1,
        // as default the = operator will be used
        DefaultActionId: 0,
        ValueTypes: {
            Percent: "percent"
        },

        /**
         *  ConditionTypes: types of conditions for events. For the moment we just use the AND condition
         */
        ConditionTypes: {
            NONE: 0,
            IF: 1,
            IF_NOT: 2,
            AND: 3,
            AND_NOT: 4,
            OR: 5,
            OR_NOT: 6
        },
        EventTypes: {
            Control: 0,
            OperatingMode: 1,
            Weather: 2,
            Time: 3,
            // this are the predefined time values from the loxApp3.json
            DateTime: 4,
            CustomTime: 5 // custom time selected by the user

        },
        ActionTypes: {
            Command: 0,
            OperatingMode: 1,
            CloudMailer: 2,
            Caller: 3,
            Notification: 4
        },
        EventValueTypes: {
            Analog: 0,
            Digital: 1
        },
        NotificationTypesTitle: {
            2: _("notifications.types.cloud-mailer"),
            3: _("notifications.types.caller"),
            4: _("notification")
        },
        AlarmStates: {
            Disarmed: 0,
            Armed: 1
        },
        SolarStates: {
            HeatOverloadInactive: 0,
            HeatOverloadActive: 1
        },
        ShowMore: _("show-more"),
        ShowLess: _("show-less"),
        ReloadRulesUpToDate: "rules are up to date",
        AlarmLevels: {
            NotActive: 0,
            Silent: 1,
            Acoustic: 2,
            Optic: 3,
            Internal: 4,
            External: 5,
            Remote: 6
        },
        SpecialEvents: {
            // used for displaying the event in a special case (e.g. Weekday names instead of numbers)
            Weekdays: 270,
            // this is the value from the structure file
            CustomTime: "custom-time",
            Pulse: [// list of pulse events, we do not need a detailed value input (like inactive/active)
                294, // sunrise
                295, // sunset
                298, // dawn
                299 // dask
            ]
        },
        ActiveInactiveOptions: [{
            value: 1,
            title: _("active")
        }, {
            value: 0,
            title: _("inactive")
        }],
        YesNoOptions: [{
            value: 1,
            title: _("yes")
        }, {
            value: 0,
            title: _("no")
        }],
        Weekdays: [{
            value: 0,
            title: _("mobiscroll.monday")
        }, {
            value: 1,
            title: _("mobiscroll.tuesday")
        }, {
            value: 2,
            title: _("mobiscroll.wednesday")
        }, {
            value: 3,
            title: _("mobiscroll.thursday")
        }, {
            value: 4,
            title: _("mobiscroll.friday")
        }, {
            value: 5,
            title: _("mobiscroll.saturday")
        }, {
            value: 6,
            title: _("mobiscroll.sunday")
        }],
        ScreenStates: {
            AutopilotScreen: "GUI.AutopilotScreen",
            AutopilotRuleScreen: "GUI.AutopilotRuleScreen",
            AutopilotEventScreen: "GUI.AutopilotEventScreen",
            AutopilotActionsScreen: "GUI.AutopilotActionsScreen",
            AutopilotOperatingModeScreen: "GUI.AutopilotOperatingModeScreen",
            AutopilotNotificationScreen: "GUI.AutopilotNotificationScreen",
            AutopilotControlCommandsScreen: "GUI.AutopilotControlCommandsScreen",
            AutopilotEventDetailsControlScreen: "GUI.AutopilotEventDetailsControlScreen",
            AutopilotEventDetailsSimpleScreen: "GUI.AutopilotEventDetailsSimpleScreen",
            AutopilotCategoryScreen: "GUI.AutopilotCategoryScreen"
        }
    };
});
