'use strict';
/**
 * onHardwareButtonUsed .. method called when either vol up or down was hit.
 */

define('HardwareVolumeButtonHandler', [], function () {
    return class HardwareVolumeButtonHandler {
        constructor(control) {
            Object.assign(this, EventHandler.Mixin);
            this.name = this.constructor.name;
            Debug.Control.AudioZone.VolumeControl && console.log(this.name, "ctor");

            if (!control) {
                throw "The control needs to be specified in order to support HW-Buttons!";
            }

            this.control = control;
            SandboxComponent.registerForStateChangesForUUID(this.control.uuidAction, this, this._receivedMiniserverStates);
            this._volume = false;
        }

        start() {
            Debug.Control.AudioZone.VolumeControl && console.log(this.name, "start"); // register all the HW-Buttons immediately. If otherwise they won't work in the tinyPlayer if as long as
            // the control slider didn't popup up.
            // store the bound references to properly remove them later on.

            this.boundHwDownButtonUsed = this._hwVolumeDownButtonUsed.bind(this);
            this.boundHwUpButtonUsed = this._hwVolumeUpButtonUsed.bind(this);
            document.addEventListener("volumedownbutton", this.boundHwDownButtonUsed, false);
            document.addEventListener("volumeupbutton", this.boundHwUpButtonUsed, false);
        }

        stop() {
            Debug.Control.AudioZone.VolumeControl && console.log(this.name, "stop"); // remove the previously stored bound hw button callbacks

            document.removeEventListener("volumedownbutton", this.boundHwDownButtonUsed, false);
            document.removeEventListener("volumeupbutton", this.boundHwUpButtonUsed, false);
        }

        /**
         * Called when an extension is destroyed
         */
        destroy() {
            Debug.Control.AudioZone.VolumeControl && console.log(this.name, "destroy");
            SandboxComponent.unregisterForStateChangesForUUID(this.control.uuidAction, this);
        }

        // ---------------------------------------------------------------------
        _receivedMiniserverStates(states) {
            this._volumeStep = states.volumeStep;
            this._volume = states.volume;
        }

        _hwVolumeDownButtonUsed() {
            Debug.Control.AudioZone.VolumeControl && console.log("_hwVolumeDownButtonUsed");

            this._sendVolumeChange(true);

            this.onHardwareButtonUsed && this.onHardwareButtonUsed(this, true);
        }

        _hwVolumeUpButtonUsed() {
            Debug.Control.AudioZone.VolumeControl && console.log("_hwVolumeUpButtonUsed");

            this._sendVolumeChange(false);

            this.onHardwareButtonUsed && this.onHardwareButtonUsed(this, false);
        }

        /**
         * Will check if the volume step feature is already available. if so the step cmd is sent, if not, the absolute
         * volume level is computed and set.
         * @param decrease  if true, the volume is to be decreased.
         * @private
         */
        _sendVolumeChange(decrease) {
            if (this._volume === false) {
                return;
            }

            var cmd,
                volume = this._volume,
                step = this._volumeStep * (decrease ? -1 : 1);

            if (Feature.VOLUME_STEP_SUPPORT) {
                cmd = Commands.format(Commands.AudioZone.VOLUME_STEPF, step);
            } else {
                volume += step;
                cmd = Commands.format(Commands.AudioZone.VOLUME_SETF, +volume);
            }

            if (cmd) {
                this.control.sendCommand(cmd);
            }
        }

    }; // EventHandler Mixin
});
