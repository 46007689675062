'use strict';

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.TASK_COMMAND = "TaskCommandCell";

    class TaskCommandCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getTaskCommandCellContent = function getTaskCommandCellContent(nr, title, groupDetail, time) {
                return '<div class="content__number-circle">' + nr + '</div>' + '<div class="content__texts-placeholder">' + '<div class="texts-placeholder__title">' + title + '</div>' + '<div class="texts-placeholder__subtitle-placeholder">' + '<div class="subtitle-placeholder__group-details text-overflow-ellipsis">' + groupDetail + '</div>' + '<div class="subtitle-placeholder__delta">' + time + '</div>' + '</div>' + '</div>';
            };

            return {
                getGeneralCellContent: getTaskCommandCellContent
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("task-command-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            var time = LxDate.formatSecondsIntoDigits(this.content.delta / 1000, true, true);
            this.contentPlaceholder.append(TaskCommandCell.Template.getGeneralCellContent(this.rowIdx + 1, this.content.title, this.content.groupDetail, time));
        }

    }

    GUI.TableViewV2.Cells.TaskCommandCell = TaskCommandCell;
    return GUI;
}(window.GUI || {});
