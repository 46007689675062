'use strict';

define("EnergyManager2Control", [
    "Control",
    "ControlSettingsUtil",
    "./content/energyManager2ControlContent.jsx",
    "./content/components/emStorageSettingsScreen.jsx",
    "./content/components/loadPrioSettings"
], function (Control, ControlSettingsUtil, CtrlContent, EmStorageSettingsScreen, LoadPrioSettings) {


     class EnergyManager2Control extends Control {
        constructor() {
            super(...arguments);
        }

        /**
         * The state of charge of the storage is known, if false - no storage is connected.
         * @returns {*}
         */
        get hasStorageStateOfChargeInfo() {
            return this.details && this.details.HasSsoc;
        }

        /**
         * The storage charge power is known to the energyManger
         * @returns {*}
         */
        get hasStorageChargePowerInfo() {
            return this.details && this.details.HasSpwr;
        }

        get storageConnected() {
            return this.hasStorageStateOfChargeInfo || this.hasStorageStateOfChargeInfo;
        }

        get hasStorageStateOfChargeStatistics() {
            return this.supportsStatisticV2 && this.hasStorageStateOfChargeInfo;
        }

        /**
         * True if minimum state of charge can be set via app and is not configured via object input
         * @returns {boolean}
         */
        canConfigureMinStateOfCharge() {
            return this.details && !this.details.MinSocLocked;
        }

        /**
         * True if maximum storage charge power can be set via app and is not configured via object input.
         * @returns {boolean}
         */
        canConfigureMaxStorageChargePower() {
            return this.details && !this.details.MaxSpwrLocked;
        }

        /**
         * Sends the command to the MS and on response informs the stateContainer so it'll trigger the internal notification
         * @param loadUuid  the load that was modified
         * @param active    was it activate dor deactivated?
         */
        setManualLoadEnabled(loadUuid, active) {
            let cmd;

            if (!!active) {
                cmd = Commands.format(Commands.EnergyManager2.LOAD_ACTIVATE, loadUuid);
            } else {
                cmd = Commands.format(Commands.EnergyManager2.LOAD_DEACTIVATE, loadUuid);
            }

            this.sendCommand(cmd).then(null, (err) => {
                console.error(this.name, "Failed to manually modify load state", err);
                this.triggerManualInteractionNotification(loadUuid, active, err);
            });
        }

        resetLoadToAuto(loadUuid) {
            let cmd = Commands.format(Commands.EnergyManager2.LOAD_AUTOMATIC, loadUuid);
            this.sendCommand(cmd).then(null, (err) => {
                this.triggerManualInteractionNotification(loadUuid, null, err, true);
            });
        }

         /**
          * Will display a notification for the manual interaction applied to this load via the stateContainer
          * to be shown within this app only.
          * @param loadUuid
          * @param active
          * @param [err]
          * @param [auto]      if true, the load should be put back into automatic handling.
          */
        triggerManualInteractionNotification(loadUuid, active, err = null, auto = false) {
            let stateCntr = SandboxComponent.getStateContainerForUUID(this.uuidAction)
            stateCntr && stateCntr.manualInteractionTriggered(loadUuid, active, err, auto);
        }

        /**
         * Updates the order of the loads.
         * @param loadUuidArray:string[]
         * @returns {*}
         */
        setLoadPrioOrder(loadUuidArray) {
            let uuidList = false;
            if (Array.isArray(loadUuidArray)) {
                uuidList = loadUuidArray.join(",");
                return this.sendCommand(Commands.format(Commands.EnergyManager2.SET_ORDER, uuidList));
            }
            console.error(this.name, "setLoadPrioOrder failed, argument not an array!");
            return Q.reject("setLoadPrioOrder - invalid argument!");
        }


        setStorageMinStateOfCharge(newVal) {
            return this.sendCommand(Commands.format(Commands.EnergyManager2.SET_MIN_SOC, newVal));
        }

        setStorageMaxChargePower(newVal) {
            return this.sendCommand(Commands.format(Commands.EnergyManager2.SET_MAX_SPWR, newVal));
        }

        get storageSettingsTexts() {
            if (!this._storageSettingsTexts) {
                this._storageSettingsTexts = [];
                this.loadStorageSettingsTexts();
            }
            return this._storageSettingsTexts;
        }

         loadStorageSettingsTexts() {
            if (this._loadPromise) {
                return this._loadPromise;
            }
            this._loadPromise = this.sendCommand(Commands.EnergyManager2.GET_STORAGE_TEXTS).then((res) => {
                this._storageSettingsTexts = getLxResponseValue(res);
                return this._storageSettingsTexts;
            });
            return this._loadPromise;
         }

        /**
         * Returns a settingsUtil that can be used with a expert mode screen (controlsettings) to display/modify the
         * settings of a load.
         * @param loadObj
         * @returns {EnergyLoadSettingsUtil}
         */
        getSettingsUtilForLoad (loadObj) {
            return new EnergyLoadSettingsUtil(this, loadObj);
        }

         getLegacyReactControlContent() {
             return CtrlContent.default;
         }

         getReactScreens() {
             return [
                 ...super.getReactScreens(...arguments),
                 EmStorageSettingsScreen.default,
                 LoadPrioSettings.default
             ];
         }

         get totalFormat() {
            if (this.details.totalFormat && this.details.totalFormat.endsWith("kWh")) {
                return "%.1f kWh";
            }
            return this.details.totalFormat || "%.2f kWh";
         }

         get actualFormat() {
             if (this.details.actualFormat && this.details.actualFormat.endsWith("kW")) {
                 return "%.1f kW";
             }
             return this.details.actualFormat || "%.2f kW";
         }

         get storageFormat() {
            return this.details.storageFormat || "%.0f%%";
         }
     }

    /**
     * Helper class to enable using the expert mode to modify the load settings.
     */
    class EnergyLoadSettingsUtil extends ControlSettingsUtil {
        constructor(emControl, loadObj) {
            //TODO-woessto: what to do with this.
            super({
                object: null,
                objectUUID: null,
                objectName: null,
                permission: null
            })
            this.emControl = emControl;
            this.loadObj = loadObj;
            this.loadUuid = loadObj.uuid;
        }

        get lightMode() {
            return true;
        }

        get showSortingHintFooter() {
            return false;
        }

        set lightMode(newVal) {
            //Ignore
        }

        get title() {
            return _("energymanager2.load-settings-title");
        }

        get icon() {
            return false; // TODO-woessto: icon not yet loadable.
        }

        set object(newVal) {
            this._object = newVal;
        }
        get object() {
            return {}; // not required.
        }

        /**
         * Loads settings from the current object. Even when expert-mode light is used, at some point data needs to be
         * loaded from the Miniserver, e.g. room/category symbols
         * @param useStructureData   false = load from the MS via Expert-Mode API (permission may still be light)
         * @returns {*}
         */
        getSettings(useStructureData) {
            Debug.Control.EnergyManagerSettings && console.log(EnergyLoadSettingsUtil.name, "getSettings");
            return this._sendCommand(Commands.format(Commands.EnergyManager2.Edit.LOAD, this.loadUuid)).then((res) => {
                return [getLxResponseValue(res)];
            }).then((settingsConfig) => {
                return this._prepareSettingObjects(settingsConfig);
            });
        }

        getControlSettings(objectUuid, permission) {
            Debug.Control.EnergyManagerSettings && console.log(EnergyLoadSettingsUtil.name, "getControlSettings");
            return this.getSettings(false);
        }

        getSettingsForLightMode(object) {
            Debug.Control.EnergyManagerSettings && console.log(EnergyLoadSettingsUtil.name, "getSettingsForLightMode");
            return this.getSettings(false);
        }

        saveSettings() {
            Debug.Control.EnergyManagerSettings && console.log(EnergyLoadSettingsUtil.name, "saveSettings", getStackObj());
            return this._sendCommand(Commands.format(Commands.EnergyManager2.Edit.SAVE, this.loadUuid));
        }

        verifySettingForObject(objectUuid, setting, value) {
            Debug.Control.EnergyManagerSettings && console.log(EnergyLoadSettingsUtil.name, "verifySettingsForObject: " + objectUuid + ", setting, value=", setting, value);
            return this._sendCommand(Commands.format(Commands.EnergyManager2.Edit.VERIFY, this.loadUuid, setting.id, value)).then((verifyResult) => {
                return value;
            });
        }

        saveSettingsForObject(objectUuid, objectName) {
            Debug.Control.EnergyManagerSettings && console.log(EnergyLoadSettingsUtil.name, "saveSettingsForObject: " + objectUuid + ", name: " + objectName);
            return this.saveSettings(); // could fail --> if nothing changed!
        }

        sendExpertModeRefresh(objectUuid) {
            Debug.Control.EnergyManagerSettings && console.log(EnergyLoadSettingsUtil.name, "sendExpertModeRefresh: " + objectUuid);
            return this._sendCommand(Commands.format(Commands.EnergyManager2.Edit.REFRESH, this.loadUuid));
        }

        showHelpFootNote() {
            Debug.Control.EnergyManagerSettings && console.log(EnergyLoadSettingsUtil.name, "showHelpFootNote: true");
            return true;
        }

        _sendCommand(cmd) {
            Debug.Control.EnergyManagerSettings && console.log(EnergyLoadSettingsUtil.name, "_sendCommand: " + cmd);
            return this.emControl.sendCommand(cmd, null, null, true, null, null, true).then((res) => {
                Debug.Control.EnergyManagerSettings && console.log(EnergyLoadSettingsUtil.name, "_sendCommand: " + cmd + " > CONFIRMED ", res);
                return res;
            }, (err) => {
                console.error(EnergyLoadSettingsUtil.name, "_sendCommand: " + cmd + " > Failed! ", err);
                return Q.reject(err);
            });
        }
    }

    return EnergyManager2Control;
});
