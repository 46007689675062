'use strict';

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.HOME_KIT_PROGRESS = "HomeKitProgressCell";

    class HomeKitProgressCell extends GUI.TableViewV2.Cells.GeneralCell {
        //region Static
        static Template = function () {
            var getProgressContent = function getProgressContent() {
                return $('<div class="content__progress">' + '<div class="progress__top">' + '<div class="top__left">&nbsp;</div>' + '<div class="top__right">&nbsp;</div>' + '</div>' + '<div class="progress__bottom">' + '<div class="bottom__used bottom__text-bar"><div class="text-bar__text">&nbsp;</div></div>' + '<div class="bottom__unused bottom__text-bar"><div class="text-bar__text">&nbsp;</div></div>' + '<div class="bottom__free">&nbsp;</div>' + '</div>' + '</div>');
            };

            var getKeyContent = function getKeyContent() {
                return $('<div class="content__key">' + '<div class="key__used">' + '<div class="used__icon"></div>' + '<div class="used__text">' + _("home-kit.settings.owner.functions.progress.used") + '</div>' + '</div>' + '<div class="key__unused">' + '<div class="unused__icon"></div>' + '<div class="unused__text">' + _("home-kit.settings.owner.functions.progress.unused") + '</div>' + '</div>' + '<div class="key__max">' + '<div class="max__icon"></div>' + '<div class="max__text">' + _("home-kit.settings.owner.functions.progress.max") + '</div>' + '</div>' + '</div>');
            };

            return {
                getProgressContent: getProgressContent,
                getKeyContent: getKeyContent
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("home-kit-progress-cell");
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.elements = this.elements || {};
                this.contentPlaceholder.append(HomeKitProgressCell.Template.getProgressContent());
                this.contentPlaceholder.append(HomeKitProgressCell.Template.getKeyContent());
                this.elements.contentProgress = this.element.find('.content__progress');
                this.elements.progressUsed = this.element.find('.bottom__used');
                this.elements.progressUsedText = this.elements.progressUsed.find('.text-bar__text');
                this.elements.progressUnused = this.element.find('.bottom__unused');
                this.elements.progressUnusedText = this.elements.progressUnused.find('.text-bar__text');
                this.elements.progressFree = this.element.find('.bottom__free');
                this.elements.progressLimit = this.element.find('.top__left');
                this.elements.progressLimitRight = this.element.find('.top__right');
                return Q.resolve();
            }.bind(this));
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function () {
                return GUI.animationHandler.schedule(this._updateProgressBars.bind(this, this.content));
            }.bind(this));
        }

        _updateProgressBars(content) {
            var limit = content.max,
                available = content.total,
                used = content.visible,
                unused = available - used,
                max = Math.max(available, limit),
                free = Math.max(0, limit - available);
            this.elements.progressUsed.css("width", used / max * 100 + "%");
            this.elements.progressUnused.css("width", unused / max * 100 + "%");
            this.elements.progressFree.css("width", free / max * 100 + "%");
            this.elements.progressUsed.toggle(used > 0);
            this.elements.progressUnused.toggle(unused > 0);
            this.elements.progressFree.toggle(free > 0);
            this.elements.progressUsedText.text(used || "");
            this.elements.progressUnusedText.text(unused || "");
            this.elements.progressLimit.text(limit || "");
            this.elements.progressLimit.css("width", limit / max * 100 + "%");
        }

        // ---------------------------------------------------------------------------------------------------------
        //   Methods for super fast tableContent updates
        // ---------------------------------------------------------------------------------------------------------
        cellTypeForReuse() {
            return GUI.TableViewV2.CellType.HOME_KIT_PROGRESS;
        }

        updateContent(newContent) {
            return super._updateContent(...arguments);
        }

        /**
         * This Method is used to apply a new content object onto the cell instance. e.g. update texts and so on.
         * @param newContent
         * @private
         */
        _applyNewContent(newContent) {
            var promises = [super._applyNewContent(...arguments)];
            promises.push(GUI.animationHandler.schedule(this._updateProgressBars.bind(this, newContent)));
            return Q.all(promises);
        } // ---------------------------------------------------------------------------------------------------------
        // ---------------------------------------------------------------------------------------------------------


    }

    GUI.TableViewV2.Cells.HomeKitProgressCell = HomeKitProgressCell;
    return GUI;
}(window.GUI || {});
