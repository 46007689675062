'use strict';

define("HourcounterControl", ["Control"], function (Control) {
    class HourcounterControl extends Control {
        constructor() {
            super(...arguments);
        }

        getReactControlContentFlags() {
            return {
                showMoreSection: false,
            }
        }

        getIcon() {
            return "resources/Images/Controls/Hourcounter/new/icon-hour-counter.svg";
        }

    }

    HourcounterControl.State = {
        OVERDUE: "overdue",
        TOTAL: "total",
        ACTIVE: "active"
    };
    return HourcounterControl;
});
