'use strict';

define([], function () {
    return {
        DaytimerState: {
            RESET_ACTIVE: -1,
            OFF: 0,
            NEEDS_ACTIVATION: 1,
            ON: 2,
            TIMER: 3
        },
        ScreenState: {
            CALENDAR: 'DaytimerControlContentCalendar',
            CALENDAR_HD: 'DaytimerControlContentCalendarHD',
            OPERATING_MODES: 'DaytimerControlContentOperatingModes',
            ENTRY_DETAIL: 'DaytimerControlContentEntryDetail',
            DEFAULT_VALUE: 'DaytimerControlContentDefaultValue',
            TIMER: 'DaytimerControlContentTimer'
        },
        ControlType: {
            DAYTIMER: 'Daytimer',
            IRC_DAYTIMER: 'IRCDaytimer',
            IRCV2_DAYTIMER: 'IRCV2Daytimer',
            POOL_DAYTIMER: 'PoolDaytimer'
        }
    };
});
