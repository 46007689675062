import {ScrollView, View, StyleSheet} from "react-native";
import globalStyles from "GlobalStyles";
import {useSafeAreaInsets} from "react-native-safe-area-context";

function LxControlContentContainer ({
                                        stickyTopComp: StickyTopComp,
                                        stickyTopCompProps = {},
                                        useFullWidth = false,
                                        children
}) {
    const {bottom} = useSafeAreaInsets();

    return <View style={StyleSheet.compose(styles.container, {marginBottom: bottom})}>
        {StickyTopComp && <StickyTopComp {...stickyTopCompProps}/>}
        <ScrollView
            style={useFullWidth ? styles.contentContainerWrapperFullScreen : styles.contentContainerWrapper}
            contentContainerStyle={useFullWidth ? styles.contentContainerFullScreen : styles.contentContainer}
        >
            {children}
        </ScrollView>
    </View>
}

const styles = StyleSheet.create({
    contentContainerWrapper: {
        width: "100%",
        maxWidth: globalStyles.sizes.contentMaxWidth
    },
    contentContainerWrapperFullScreen: {
        width: "100%",
    },
    container: {
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginHorizontal: globalStyles.spacings.contentHorizontal
    },
    contentContainer: {
        height: "100%",
        width: "100%",
        maxWidth: globalStyles.sizes.contentMaxWidth,
        flexDirection: "column",
        alignItems: "center"
    },
    contentContainerFullScreen: {
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center"
    },
})


export default LxControlContentContainer
