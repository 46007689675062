import * as React from "react"
import Svg, { Path } from "react-native-svg"

function AmbientModeLined(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M3 9.5a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5zM3.5 12a.5.5 0 000 1h5a.5.5 0 000-1h-5zM3 14.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5z"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5 6A1.5 1.5 0 0012 7.5v9a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0019.5 6h-6zM13 7.5a.5.5 0 01.5-.5h6a.5.5 0 01.5.5v9a.5.5 0 01-.5.5h-6a.5.5 0 01-.5-.5v-9z"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 4.5A1.5 1.5 0 011.5 3h21A1.5 1.5 0 0124 4.5v15a1.5 1.5 0 01-1.5 1.5h-21A1.5 1.5 0 010 19.5v-15zM1.5 4a.5.5 0 00-.5.5v15a.5.5 0 00.5.5h21a.5.5 0 00.5-.5v-15a.5.5 0 00-.5-.5h-21z"
            />
        </Svg>
    )
}

export default AmbientModeLined
