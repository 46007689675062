'use strict';

class LxGroupListTableView extends GUI.SelectableTableView {
    constructor(groupType) {
        super();
        this._groups = ActiveMSComponent.getStructureManager().getGroupsByType(groupType);
        this.eventHandlers = [];
    }

    viewDidLoad() {
        super.viewDidLoad();
        this.elements = {};
        this.element.addClass("lx-group-list-table-view");
        this.reload();
        this._structureChangesReg = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.StructureChanged, this._setNeedsReload.bind(this));
    }

    viewWillAppear() {
        if (this._needsReload) {
            this._reloadTable();

            this._needsReload = false;
        }

        super.viewWillAppear(...arguments);

        this._loadSidebar();
    }

    viewDidAppear() {
        super.viewDidAppear();

        if (this.elements.sidebar) {
            var hammer = Hammer(this.elements.sidebarScroller[0], {
                dragMinDistance: 5,
                dragLockToAxis: true,
                preventDefault: true
            });
            this.eventHandlers.push(hammer.on('dragstart', this._onSidebarDragStart.bind(this)));
            this.eventHandlers.push(hammer.on('dragup dragdown', this._onSidebarDrag.bind(this)));
            this.eventHandlers.push(hammer.on('dragend', this._onSidebarDragEnd.bind(this)));
            this.eventHandlers.push(hammer.on('dragleft dragright', this._onSidebarDragCancel.bind(this)));
        }
    }

    viewDidDisappear(viewRemainsVisible) {
        if (this.elements.sidebar) {
            while (this.eventHandlers.length > 0) {
                this.eventHandlers.pop().dispose();
            }
        }

        super.viewDidDisappear(viewRemainsVisible);
    }

    destroy() {
        NavigationComp.removeFromUIEvent(this._structureChangesReg);
        super.destroy();
    }

    // Public Methods
    selectGroup(group) {
        this._currentGroup = group;
        this.selectCell(0, this._groups.indexOf(group));
    }

    // TableViewDataSource methods
    styleForTableView() {
        return TableViewStyle.TRANSLUCENT;
    }

    numberOfSections() {
        return 1;
    }

    numberOfRowsInSection(section) {
        return this._groups.length;
    }

    cellTypeForCellAtIndex() {
        return GUI.TableViewV2.CellType.Special.GROUP_CELL;
    }

    contentForCell(cell, section, row, tableView) {
        return {
            groupUUID: this._groups[row].uuid,
            groupName: this._groups[row].name,
            imageName: this._groups[row].image,
            color: this._groups[row].color,
            selectable: true
        };
    }

    didSelectCell(cell, section, row) {
        this._onGroupSelected(this._groups[row]);
    }

    _onGroupSelected(group) {
        Debug.GUI.TableView && console.log(this.name, "_onGroupSelected");
        this._currentGroup = group;
        this.emit("group-selected", group.uuid, group.groupType);
        Debug.GUI.TableView && console.log(this.name, "_onGroupSelected < done");
    }

    _loadSidebar() {
        if (this.elements.sidebar || !this._shouldLoadSidebar()) {
            return;
        }

        this.elements.sidebar = $('<div class="lx-group-list-table-view-sidebar" />');
        this.elements.sidebarScroller = $('<div class="sidebar__scroller" />');
        var group;

        for (var i = 0; i < this._groups.length; i++) {
            group = this._groups[i];
            this.elements.sidebar.append($('<div id="sidebar-entry-' + i + '" class="sidebar__entry">' + group.name[0] + '</div>'));
        }

        this.elements.sidebar.append(this.elements.sidebarScroller);
        this.elements.sidebar.insertAfter(this.element);
    }

    /**
     * Removes the sideBar and calls _loadSidebar again. Needed to update the sidebar once the table reloads.
     * @private
     */
    _reloadSidebar() {
        if (this.elements.sidebar) {
            this.elements.sidebar.remove();
            this.elements.sidebar = null;
        }

        this._loadSidebar();
    }

    //sidebar
    _onSidebarDragStart(ev) {
        console.log("_onSidebarDragStart", this._dragging);
        this._dragging = true; // make big

        this.element.addClass("big-cells");
        this.element.find(".group-cell").height(this.element.height() / 5 + "px");

        this._onSidebarDrag(ev);
    }

    _onSidebarDrag(ev) {
        console.log("_onSidebarDrag", this._dragging);

        if (this._dragging) {
            var topPadding = 10,
                bottomPadding = 10;
            var sidebarHeight = this.element.height() - topPadding - bottomPadding;
            var entryHeight = parseInt(sidebarHeight / this._groups.length);
            var sidebarTopOffset = this.elements.sidebar[0].getBoundingClientRect().top;
            var yPosition = ev.gesture.center.pageY;
            var eventY = yPosition - (sidebarTopOffset + topPadding);
            var currIdx = eventY >= 0 ? parseInt(eventY / entryHeight) : 0; // if btn itself is selected

            currIdx = Math.min(currIdx, this._groups.length - 1);
            var upOrDown = ev.gesture.direction === "up" || ev.gesture.direction === "down";

            if (currIdx !== this.lastIdx && upOrDown) {
                this._deselectListEntry(this._currentListEntry);

                this._deselectSidebarEntry(this._currentSidebarEntry);

                this._currentSidebarGroup = this._groups[currIdx];
                this._currentListEntry = this.element.find('#group-cell-' + this._currentSidebarGroup.uuid);
                this._currentSidebarEntry = this.elements.sidebar.find('#sidebar-entry-' + currIdx);

                this._selectListEntry(this._currentListEntry, currIdx);

                this._selectSidebarEntry(this._currentSidebarEntry);

                this.lastIdx = currIdx;
            }

            ev.preventDefault();
            ev.stopPropagation();
            ev.gesture.preventDefault();
            ev.gesture.stopPropagation();
        }
    }

    _onSidebarDragCancel(ev) {
        console.log("_onSidebarDragCancel", this._dragging);

        if (this._dragging) {
            this._dragging = false;
            var cells = this.element.find(".group-cell");
            cells.css("height", "");

            this._deselectListEntry(this._currentListEntry);

            this._currentListEntry = null;

            this._deselectSidebarEntry(this._currentSidebarEntry);

            this._currentSidebarEntry = null; // remove big

            this.element.removeClass("big-cells"); // remove all classes

            cells.removeClass("selected");
            cells.removeClass("deselected");
            this._currentSidebarGroup = null;
            this.lastIdx = null;
        }
    }

    _onSidebarDragEnd(ev) {
        console.log("_onSidebarDragEnd", this._dragging);

        if (this._dragging) {
            // go to group
            this.selectGroup(this._currentSidebarGroup);
            this._currentSidebarGroup && this._onGroupSelected(this._currentSidebarGroup); // take a short timeout to not interrupt the transition

            setTimeout(function () {
                this._onSidebarDragCancel(ev);
            }.bind(this), 500);
        }
    }

    _selectListEntry(entry, idx) {
        entry.addClass("selected");
        entry.removeClass("deselected");
        var prev = entry.prev(),
            prevPrev;

        if (prev) {
            prevPrev = prev.prev();
        } else {
            prev = entry;
        }

        var elementToScrollTo = prevPrev ? prevPrev : prev;
        console.log(elementToScrollTo.find(".content__name").text());
        var scrollTop = this.element.height() / 5 * elementToScrollTo.index();
        $(this.element.children()[0]).velocity("stop");
        $(this.element.children()[0]).velocity("scroll", {
            container: this.element,
            offset: scrollTop,
            duration: 400,
            easing: "easeOutCubic"
        });
    }

    _deselectListEntry(entry) {
        if (entry) {
            entry.removeClass("selected");
            entry.addClass("deselected");
        }
    }

    _selectSidebarEntry(entry) {
        entry.addClass("selected");
        entry.next() && entry.next().addClass("selected-adjacent");
        entry.prev() && entry.prev().addClass("selected-adjacent");
    }

    _deselectSidebarEntry(entry) {
        if (entry) {
            entry.removeClass("selected");
            entry.next() && entry.next().removeClass("selected-adjacent");
            entry.prev() && entry.prev().removeClass("selected-adjacent");
        }
    }

    _setNeedsReload() {
        if (this.isVisible()) {
            this._reloadTable();
        } else {
            this._needsReload = true;
        }
    }

    reload() {
        this._reloadSidebar(); // intercept reload the sidebar too.


        return super.reload(...arguments);
    }

    _reloadTable() {
        this._groups = ActiveMSComponent.getStructureManager().getGroupsByType(this._groups[0].groupType);
        this.reload(); // select again..

        if (this._currentGroup) {
            var group = ActiveMSComponent.getStructureManager().getGroupByUUID(this._currentGroup.uuid, this._currentGroup.groupType);

            if (group) {
                // use "new" group..
                this.selectGroup(group);
            }
        }
    }

    _shouldLoadSidebar() {
        var shouldLoadSidebar = this.element[0].scrollHeight > this.element[0].clientHeight;
        return !HD_APP && shouldLoadSidebar;
    }

}

GUI.LxGroupListTableView = LxGroupListTableView;
