import {
    SearchableTable,
    LxReactText,
    useBackNavigation,
    LxReactDeviceSearchMiniserverSelection,
    LxReactDeviceSearchExtensionSelection,
    useConnectionReady
} from "LxComponents"
import {useMemo, useCallback} from "react";
import globalStyles from "GlobalStyles";
import useTrackRouteInSearchContext from "./useTrackRouteInSearchContext"
import useDeviceSearchTitleBar from "./useDeviceSearchTitleBar";
import {View} from "react-native";
import useDeviceSearchTechTags from "./useDeviceSearchTechTags";
import LxReactDeviceSearchIcon from "./LxReactDeviceSearchIcon";
import { useFocusEffect } from "@react-navigation/native";

export default function LxReactDeviceSearchTechTagSelection({navigation, route}) {
    useTrackRouteInSearchContext(LxReactDeviceSearchTechTagSelection.name, route);
    useDeviceSearchTitleBar(navigation, route);
    const connectionReady = useConnectionReady(true);

    useBackNavigation(() => {
        navigation.goBack();
    })

    const isGateway = route.params.isGateway;
    const techTagsAvailable = useDeviceSearchTechTags(true);

    const handleTechTagSelected = (techTag) => {
        ActiveMSComponent.setSearchType(techTag);
        const searchId = ActiveMSComponent.prepareForDeviceSearch(techTag);
        navigation.navigate(isGateway ? LxReactDeviceSearchMiniserverSelection.name : LxReactDeviceSearchExtensionSelection.name, {
            ...route.params,
            searchType: techTag,
            searchId,
        });
    }

    useFocusEffect(useCallback(() => {
        ActiveMSComponent.setSearchType(DeviceManagement.TYPE.TECH_SELECTION);
        if(!techTagsAvailable || techTagsAvailable.length === 0) {
            if(connectionReady) {
                ActiveMSComponent.getAvailableTechTags(false, true);
            } else {
                Debug.DeviceSearch.TechTags && console.log(LxReactDeviceSearchTechTagSelection.name, "connection not ready to refresh tech-tags");
            }
        } else {
            Debug.DeviceSearch.TechTags && console.log(LxReactDeviceSearchTechTagSelection.name, "tech-tags already available");
        }
    }, []));

    const renderOnlineView = (icon, hasOnlineState = false, isOnline = false, isTotal = false, smallIcon = true) => {
        return {
            comp: LxReactDeviceSearchIcon,
            props: {
                icon,
                hasOnlineState,
                isOnline,
                isTotal,
                smallIcon
            }
        }
    }

    const createRowForTechnology = (technology) => {
        Debug.DeviceSearch.TechTags && console.log(LxReactDeviceSearchTechTagSelection.name, "createRowForTechnology: ", JSON.stringify(technology));
        return {
            title: technology.title,
            onPress: () => handleTechTagSelected(technology.name),
            disabled: !connectionReady,
            mainLeftStyle: Style.iconBox,
            mainLeftContent: renderOnlineView(Icon.DeviceLearning.TECHNOLOGIES[technology.name.toUpperCase() || Icon.DeviceLearning.NO_TECHNOLOGY], false, false, true),
        }
    }

    const createRowsForTechnologies = (technologies) => {
        let rows = [];
        technologies.forEach(technology => {
            rows.push(createRowForTechnology(technology));
        });

        if (!connectionReady) {
            rows.forEach(techRow => { techRow.disabled = true; });
        }

        return rows;
    }

    const tableContent = useMemo(() => {
        let content = [];

        const renderFooter = (text) => {
            return <LxReactText key="footer" style={globalStyles.customStyles.sectionFooter}>
                {text}
            </LxReactText>
        }

        if (techTagsAvailable && techTagsAvailable.length > 0) {  
            content.push({
                rows: createRowsForTechnologies(techTagsAvailable)
            });
        } else if (techTagsAvailable && techTagsAvailable.length === 0) {
            // nothing searchable
            content.push({
                rows: [],
                footerElement: renderFooter(_("device-learning.no-technologies-available"))
            })
        } else {
            content.push({
                rows: [],
                footerElement: renderFooter(_("device-learning.loading-technologies"))
            })
        }

        Debug.DeviceSearch.TechTags && console.log(LxReactDeviceSearchTechTagSelection.name, "tech-tags= " + JSON.stringify(techTagsAvailable));
        return content;
    }, [JSON.stringify(techTagsAvailable).hashCode(), connectionReady]);

    return <View style={Style.rootCntr}>
        <SearchableTable containerStyle={Style.table} tableContent={tableContent} filterTitle={_("search.title")} />
        {/* <LxReactDeviceSearchRefresh style={Style.refresh} searchType={searchType} /> */}
    </View>
}

const Style = {
    rootCntr: {
        flexDirection: "column",
        height: "100%",
    },
    table: {
        flex: 1
    },
    iconBox: {
        background: globalStyles.colors.grey["600"],
        width: 48,
        minHeight: 48,
        height: "100%",
        justifyContent: "center",
        alignContent: "center"
    },
}
