import {
    useLiveState,
    useControl,
    LxReactText,
    LxReactSwitch,
    LxReactPressable,
    LxReactImageView,
    LxReactContextMenuHandler,
    LxReactRenderErrorView,
    App
} from "LxComponents";
import PropTypes from "prop-types";
import globalStyles from "GlobalStyles";
import {View} from "react-native";
import {useNavigation} from "@react-navigation/native";
import React, {useContext, useMemo, useRef} from "react";
import Icons from "IconLib";
import {AmbientContext} from "./Components";

export default function LxReactControl(props) {
    const ambientContextData = useContext(AmbientContext)
    const isAmbientMode = useMemo(() => {
        return props.isInAmbientMode || ambientContextData.isAmbientMode;
    }, [props.isInAmbientMode, ambientContextData && ambientContextData.isAmbientMode])
    const control = useControl(props.controlUuid);
    let stateNames = [
        "stateTextShort",
        "stateTextColor",
        "liveStateIcon",
        "stateColor",
        "stateIconColor",
        "stateIconSmall",
        "stateTintColor",
        "presenceSimulation",
        "messageCenterEntries",
        "universalIsLocked",
        "universalLockReason",
        "transformations",
    ];

    if (control) {
        stateNames = stateNames.concat([...control.getAdditionalCardStateNames()]);
    }

    const cellStyle = useMemo(() => {
        let adoptCellPadding = props.displayAsCell && (props.displayRoomName || props.showGroupDetails);
        return {
            pressable: {
            ...baseStyles.border,
                    width: "100%",
                    minHeight: 76
            },
            container: {
                paddingVertical: adoptCellPadding ? 8 : 16,
                    paddingLeft: 12,
                paddingRight: 4,
                    flexDirection: "row",
                    height: "100%"
            },
            stateIconContainer: {
                width: 40,
                    height: "auto",
            },
            stateIcon: {
            ...baseStyles.stateIcon,
                    height: "auto",
                    justifyContent: "center"
            },
            primary: {
            ...baseStyles.primary
            },
            secondary: {
            ...baseStyles.secondary
            },
            tertiary: {
            ...baseStyles.tertiary
            },
            smallStateIconCntr: {
            ...baseStyles.smallStateIconCntr,
                    left: 4,
                    top: 4,
                    width: 24,
                    height: 24,
            },
            smallStateIcon: {
            ...baseStyles.smallStateIcon,
                    width: 12,
                    height: 12,
            },
            controlContainer: {
                flexDirection: "row-reverse",
                    alignItems: "center",
                    justifyContent: "space-between",
                    maxWidth: 100
                //marginVertical: "auto"
            },
            textContainer: {
                justifyContent: "center",
                    flexDirection: "column",
                    flex: 1,
                    flexShrink: 0,
                    paddingHorizontal: 16
            },
            roomOrCategoryText: {
            ...baseStyles.roomOrCategoryText,
                    marginTop: 3
            }
        }
    }, [props.displayAsCell, props.displayRoomName, props.showGroupDetails])

    const liveState = useLiveState(props.controlUuid, stateNames);
    const elementRef = useRef(null);
    let iconMemoDeps = [
            liveState.states.liveStateIcon,
            liveState.states.transformations,
            liveState.states.stateColor,
            liveState.states.stateIconColor,
            liveState.states.messageCenterEntries,
            props.controlUuid,
            props.displayAsCell,
            props.controlUuid,
            props.iconClass
        ],
        errorButtonCfgMemoDeps = [
            liveState.states.universalIsLocked,
        ],
        buttonMemoDeps = []

    let navigation;
    try { // required as these may be used in pure react screens as well as controlContents that are wrapped in old code.
        navigation = useNavigation();
    } catch (ex) {
        navigation = App.navigationRef;
    }

    if (control) {
        iconMemoDeps = iconMemoDeps.concat([
            control.reactIcon,
            control.defaultIcon]);
        errorButtonCfgMemoDeps = errorButtonCfgMemoDeps.concat([
            control.isConfigured()
        ]);
    }

    const _getStyle = identifier => {
        if (props.displayAsCell) {
            return cellStyle[identifier];
        } else {
            return cardStyle[identifier];
        }
    }

    const systemStatusEntry = useMemo(() => {
        if (control) {
            // pass on the liveState - control.getStates may not have the updated value yet
            let lockingEntry = control.getLockingSystemStatusMessage(liveState.states),
                activeEntry = control.getActiveSystemStatusMessage(liveState.states);
            return lockingEntry || activeEntry;
        } else {
            return false;
        }

    }, [JSON.stringify(liveState.states.messageCenterEntries)]);

    const getStateIconColor = () => {
        let color;
        if (systemStatusEntry) {
            color = MessageCenterHelper.getColorForSeverityEntry(systemStatusEntry);
        } else if (liveState.states) {
            color = liveState.states.stateIconColor || liveState.states.stateTintColor;
        }
        return color;
    }

    const renderStateIcon = () => {
        let iconStyle = {..._getStyle('stateIcon')},
            transformations;
        if (liveState.states) {
            if (getStateIconColor()) {
                iconStyle.fill = getStateIconColor();
            }
            let imageDimensions = {};
            if (iconStyle) {
                if ("height" in iconStyle) imageDimensions.height = iconStyle.height;
                if ("width" in iconStyle) imageDimensions.width = iconStyle.width;
            }
            transformations = liveState.states.transformations;
            return <LxReactImageView source={liveState.states.liveStateIcon || control.getIcon()}
                                     iconClass={props.iconClass} transformations={transformations}
                                     containerStyle={iconStyle} imageStyle={imageDimensions}/>
        }
    }

    const renderedSmallIcon = useMemo(() => {
        if (control) {
            if (liveState.states.stateIconSmall) {
                const color = liveState.states.stateIconSmall.color;
                const source = liveState.states.stateIconSmall.iconSrc;
                const conditionalStyle = {
                    ...(color ? {backgroundColor: color} : {}),
                    ...((AMBIENT_MODE || isAmbientMode) ? {borderColor: "rgb(64,64,64)"} : {})
                }
                const containerStyle = {
                    ..._getStyle("smallStateIconCntr"),
                    ...conditionalStyle
                }
                return <LxReactImageView source={source}
                                         containerStyle={containerStyle}
                                         imageStyle={_getStyle("smallStateIcon")}/>
            } else {
                return null;
            }
        } else {
            return null;
        }

    }, [JSON.stringify(liveState.states.stateIconSmall)]);

    const renderedIcon = useMemo(() => {
        if (control) {
            let renderedIcon;
            if (control.reactIcon) {
                renderedIcon = <control.reactIcon controlUuid={props.controlUuid}
                                                  containerStyle={_getStyle('stateIcon')}
                                                  forCell={!!props.displayAsCell}/>;
            } else {
                renderedIcon = renderStateIcon();
            }
            return renderedIcon;
        } else {
            return null;
        }
    }, iconMemoDeps);



    const renderSwitch = (switchCfg) => {
        // stateDependent flag ensures the switch returns to the state-value after AD/Scene recroding ends.
        return <View style={baseStyles.button}>
            <LxReactSwitch
                stateDependent={true}
                activeTrackColor={switchCfg.activeTrackColor}
                style={baseStyles.button}
                disabled={switchCfg.disabled}
                value={switchCfg.active}
                onValueChanged={(val) => {sendCmd(val ? switchCfg.command1 : switchCfg.command0);}}
            /></View>
    }


    const renderButton = (buttonCfg, index, buttonCount) => {
        let buttonBgStyle = {
                ...baseStyles.buttonBackground,
                padding: 0
            },
            iconStyle = {
                ...baseStyles.button,
            };

        /**
         * Action can either be a function, an array of commnands, or a single cmd.
         * @param action
         * @returns {Q.Promise<>|void|*}
         */
        const handleButtonAction = (action) => {
            let res;
            if (typeof action === "function") {
                res = action();
                if (typeof res === "string") {
                    res = handleButtonAction(res);
                }
            } else if (Array.isArray(action)) {
                res = Q.all(action.map((singleAction) => {
                    return handleButtonAction(singleAction);
                }))
            } else if (typeof action === "string") {
                res = sendCmd(action);
            } else {
                console.error("LxReactControl", "Failed to respond to command!");
            }
            return res;
        }

        const getButtonTapAction = () => {
            var cmd;
            if (typeof buttonCfg.command === "object") {
                cmd = buttonCfg.command.tap;
            } else {
                cmd = buttonCfg.command;
            }
            return cmd ? () => handleButtonAction(cmd) : null;
        }
        const getDoubleTapAction = () => {
            if (typeof buttonCfg.command === "object" && buttonCfg.command.doubleTap) {
                return () => {
                    handleButtonAction(buttonCfg.command.doubleTap)
                };
            } else {
                return null;
            }
        }
        const getTickAction = () => {
            if (typeof buttonCfg.command === "object" && buttonCfg.command.tick) {
                return () => {
                    handleButtonAction(buttonCfg.command.tick)
                };
            } else {
                return null;
            }
        }
        const getHitAction = () => {
            if (typeof buttonCfg.command === "object" && buttonCfg.command.hit) {
                return () => {
                    handleButtonAction(buttonCfg.command.hit)
                };
            } else {
                return null;
            }
        }
        const getReleaseAction = () => {
            if (typeof buttonCfg.command === "object" && buttonCfg.command.release) {
                return () => {
                    handleButtonAction(buttonCfg.command.release)
                };
            } else {
                return null;
            }
        }


        if (buttonCfg.hasOwnProperty("reactIcon")) {
            if (buttonCfg.reactIcon === false) {
                return null; // don't render!
            }
            if (props.displayAsCell && buttonCount === 2) {
                buttonBgStyle.marginRight =  index === 1 ? 10 : 0;
                buttonBgStyle.marginLeft =  index === 0 ? 10 : 0;
            }
            buttonBgStyle.borderRadius = 3;
            buttonBgStyle.backgroundColor = globalStyles.colors.fill.tertiary;
            buttonBgStyle.width = globalStyles.sizes.button.size;
            buttonBgStyle.height = globalStyles.sizes.button.size;
            iconStyle.width = globalStyles.sizes.icons.button;
            iconStyle.height = globalStyles.sizes.icons.button;
            iconStyle.margin = "auto";
            iconStyle.fill = buttonCfg._btnColor || globalStyles.colors.text.primary;

            let hitReleaseProps = {};
            let hitAction = getHitAction(),
                releaseAction = getReleaseAction();
            if (hitAction) {
                hitReleaseProps.onHit = hitAction;
            }
            if (releaseAction) {
                hitReleaseProps.onReleased = releaseAction;
            }

            return <LxReactPressable
                {...hitReleaseProps}
                disabled={!!buttonCfg.disabled}
                onPress={ getButtonTapAction() }
                onLongPressTick={ getTickAction() }
                onDoublePress={ getDoubleTapAction() }
                pressableStyle={buttonBgStyle}>
                <buttonCfg.reactIcon style={iconStyle}/>
            </LxReactPressable>
        } else {
            return <Icon.SystemStatusError fill={{...iconStyle, fill: globalStyles.colors.red}}/>;
        }
    }

    // add systemStatus dependency to errorButtonCfg Memo (has been declared above)
    errorButtonCfgMemoDeps.push(JSON.stringify(systemStatusEntry));

    const errorButtonCfg = useMemo(() => {
        let config;

        if (control) {
            if (liveState.states.universalIsLocked) {
                config = true; // nothing to do.
            } else if (systemStatusEntry) {
                config = {
                    reactIcon: MessageCenterHelper.getIconForSeverityEntry(systemStatusEntry, false, false),
                    command: {
                        tap: () => {
                            navigation.navigate(ScreenState.MessageCenterMessageScreen, {
                                entry: systemStatusEntry
                            });
                        }
                    },
                    _btnColor: MessageCenterHelper.getColorForSeverityEntry(systemStatusEntry, true)
                }
            } else if (!control.isConfigured()) {
                config = {
                    reactIcon: Icons.SystemStateWarning,
                    command: {
                        tap: () => {openContent()} // will display the inconfigured popup via navigationComp.
                    },
                    _btnColor: globalStyles.colors.orange
                };
            }
        } else {
            config = true;
        }


        return config;
    }, errorButtonCfgMemoDeps);

    // if available extend buttonMemoDeps with the switches/buttons/error-Cfg
    if (control) {
        buttonMemoDeps = buttonMemoDeps.concat([
            liveState.allStatesReceived,
            JSON.stringify(control.button0),
            JSON.stringify(control.button1),
            JSON.stringify(control.switch),
            JSON.stringify(errorButtonCfg) // can't be done earlier, as the erroButtonCfg must be declared first.
        ])
    }
    /**
     * Use a memo to ensure it isn't re-rendered when not required. This did cause the onPressOut to be
     * dropped leading to infinite sending of the ticks
     * @type {unknown}
     */
    const renderControls = useMemo(() => {
        if (control) {
            if (errorButtonCfg) {
                if (typeof errorButtonCfg === "object") {
                    return <View style={_getStyle('controlContainer')}>{renderButton(errorButtonCfg, 0 , 1)}</View>;
                }
                return;
            }
            let ctrlStates = control.getStates();
            let switchCfg = control.getSwitch(ctrlStates);
            let btn0Cfg = control.getButton0(ctrlStates);
            let btn1Cfg = control.getButton1(ctrlStates);

            if (!liveState.allStatesReceived && control.supportsStates()) {
                return null; // no controls when states not ready yet!
            }
            if (switchCfg) {
                return <View style={_getStyle('controlContainer')}>{renderSwitch(switchCfg)}</View>;
            } else if (btn0Cfg || btn1Cfg) {
                let rendered = [], index = 0, buttonCount = 1;
                if (btn0Cfg && btn1Cfg) {
                    buttonCount++;
                }
                // render button1 before button0 --> earlier 0 was on top of 1, now 1 must be on the left
                rendered.pushObject(btn0Cfg ? renderButton(btn0Cfg, index++, buttonCount) : null);
                rendered.pushObject(btn1Cfg ? renderButton(btn1Cfg, index++, buttonCount) : null);
                return <View style={_getStyle('controlContainer')}>{rendered}</View>;
            } else {
                return null;
            }
        } else {
            return null;
        }

    }, buttonMemoDeps);

    const sendCmd = (cmd) => {
        control.sendCommand(cmd, null, null, null, CmdSrc.CARD);
    }

    const renderTexts = () => {
        return (
            <View style={_getStyle('textContainer')}>
                {renderCardTitle()}
                {renderStateText()}
                {renderRoomOrCategory()}
            </View>
        )
    }

    const renderCardTitle = () => {
        return <LxReactText style={_getStyle("primary")} numberOfLines={1}>{control.getName()}</LxReactText>
    }

    const getStateTextColor = () => {
        let color;
        if (liveState.states) {
            color = liveState.states.stateTextColor || liveState.states.stateTintColor;
        }
        return color;
    }

    const renderStateText = () => {
        let style = {..._getStyle("secondary")},
            text;

        if (systemStatusEntry && systemStatusEntry.isVisuLocked) {
            style.color = MessageCenterHelper.getColorForSeverityEntry(systemStatusEntry, false);
            if (systemStatusEntry.confirmedAt) {
                style.color = globalStyles.colors.text.secondary;
            }
            text = systemStatusEntry.title;

        } else if (!control.supportsStates() || (liveState && liveState.allStatesReceived)) {
            if (getStateTextColor()) {
                style.color = getStateTextColor();
            }

            text = control.getStateTextShort();
        } else {
            text = _('fetching-status')
        }

        if ((!text || !text.length) && !props.displayAsCell) {
            text = NBR_SPACE;
        }

        return <LxReactText style={style} numberOfLines={1}>{text}</LxReactText>;
    }

    const renderRoomOrCategory = () => {
        let text, group;

        if (props.displayAsCell && !props.showGroupDetails) {
            return null;
        }

        /* // RN1-I526 -- hide category from group details
        if (props.showGroupDetails) {
            text = control.groupDetail || NBR_SPACE;
        } else */
        if (props.displayRoomName || props.showGroupDetails) {
            group = control.getRoom();
            text = group && group.name || _('room.without');
        } else {
            group = control.getCategory();
            text = group && group.name || _('category.without');
        }
        return text ? <LxReactText style={[_getStyle("tertiary"), _getStyle("roomOrCategoryText")]} numberOfLines={1}>{text}</LxReactText> : null
    }

    const renderControlCard = () => {
        return <View key={"container-" + props.controlUuid} style={cardStyle.container}>
            <View key={"iconContainer-" + props.controlUuid} style={cardStyle.stateIconContainer}>
                {renderedIcon}
                {renderedSmallIcon}
                {renderControls}
            </View>
            {renderTexts()}
        </View>
    }

    const renderControlCell = () => {
        let tmpCellStyle = {...cellStyle.container};
        return <View key={"container-" + props.controlUuid} style={tmpCellStyle}>
            <View key={"iconContainer-" + props.controlUuid} style={cellStyle.stateIconContainer}>
                {renderedIcon}
            </View>
            {renderedSmallIcon}
            {renderTexts()}
            {renderControls}
        </View>
    }

    const openContent = () => {
        if (!control.supportsStates() || liveState.allStatesReceived) { // don't open if states not ready yet!
            let flags = {
                isInAmbientMode: isAmbientMode || props.isShortcut,
                push: !props.isShortcut,
                fromSearchResults: !!props.fromSearchResults
            }
            NavigationComp.showControlContent(control, null, null, flags)
        }
    }

    const _showContextMenu = () => {
        let contextMenuOptions;

        contextMenuOptions = props.getSortingContextMenuOptions({
            content: {
                control: control
            }
        });

        if (contextMenuOptions?.length) {
            LxReactContextMenuHandler.shared.showContextMenu(contextMenuOptions, "", elementRef.current, GUI.LxContextMenuV2);
        }
    }

    if (control) {
        try {
            return (
                <LxReactPressable
                    pkey={props.controlUuid}
                    key={props.controlUuid}
                    ref={elementRef}
                    onPress={openContent}
                    onLongPress={_showContextMenu}
                    style={[baseStyles.border, _getStyle('pressable'), JSON.parse(props.borderStyle || "{}")]}>
                    {props.displayAsCell ? renderControlCell() : renderControlCard()}
                </LxReactPressable>
            );
        } catch (error) {
            return (
                <LxReactRenderErrorView
                    key={props.controlUuid}
                    error={error}/>
            )
        }
    } else {
        return null;
    }
}

const baseStyles = {
    button: {
        height: 40,
        width: 40
    },
    buttonBackground: {
        backgroundColor: globalStyles.colors.grey["300a36"],
        height: 40
    },
    primary: {
        ...globalStyles.textStyles.body.bold,
        color: globalStyles.colors.text.primary,
    },
    secondary: {
        ...globalStyles.textStyles.body.bold,
        color: globalStyles.colors.text.secondary,
    },
    tertiary: {
        fontFamily: globalStyles.fontSettings.families.semiBold,
        color: globalStyles.colors.text.tertiary,
        fontSize: globalStyles.fontSettings.sizes.tiny,
        textTransform: "uppercase",
        letterSpacing: 1.5,
        lineHeight: "100%",
    },
    stateIcon: {
        fill: globalStyles.colors.text.primary
    },
    smallStateIconCntr: {
        position: "absolute",
        borderRadius: "50%",
        borderColor: globalStyles.colors.black,
        borderWidth: 2,
        backgroundColor: globalStyles.colors.text.primary,
        padding: 2
    },
    smallStateIcon: {
        margin: "auto",
        fill: globalStyles.colors.black
    },
    border: {
        borderStyle: "solid",
        borderRadius: 0,
        borderColor: globalStyles.colors.borderColor.default
    },
    roomOrCategoryText: {
        marginTop: 8
    }
}

const cardStyle = {
    pressable: {
        ...baseStyles.border,
        width: "100%",
        height: 212
    },
    container: {
        paddingHorizontal: globalStyles.spacings.contentHorizontal,
        paddingVertical: 14,
        height: "100%"
    },
    stateIconContainer: {
        height: 100,
        //width: 40,
        flexDirection: "row"
    },
    stateIcon: {
        ...baseStyles.stateIcon,
        height: 48,
        width: 48,
        marginTop: 26,
        marginLeft: 24,
    },
    controlContainer: {
        height: "auto",
        maxHeight: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    primary: {
        ...baseStyles.primary
    },
    secondary: {
        ...baseStyles.secondary
    },
    tertiary: {
        ...baseStyles.tertiary
    },
    textContainer: {
        marginTop: 12
    },
    smallStateIconCntr: {
        ...baseStyles.smallStateIconCntr,
        left: 0,
        top: 0,
        width: 28,
        height: 28,
    },
    smallStateIcon: {
        ...baseStyles.smallStateIcon,
        width: 14,
        height: 14,
    },
    roomOrCategoryText: {
        ...baseStyles.roomOrCategoryText
    }
}


LxReactControl.propTypes = {
    controlUuid: PropTypes.string.isRequired,
    displayAsCell: PropTypes.bool,
    showGroupDetails: PropTypes.bool,
    displayRoomName: PropTypes.bool,
    iconSrc: PropTypes.string,
    iconClass: PropTypes.string,
    showContextMenu: PropTypes.func,
    borderStyle: PropTypes.string,
    isShortcut: PropTypes.bool,
    isInAmbientMode: PropTypes.bool
}
