class EcoScreenUtils {
    static PresenceControlTypes = [
        ControlType.PRESENCE_DETECTOR,
    ];

    static createControlOption(control) {
        let subTitleParts = [];
        control.getRoom() && subTitleParts.push(control.getRoom().name);
        subTitleParts.push(control.getControlTypeName());

        return {
            id: control.uuidAction,
            title: control.getName(),
            subTitle: subTitleParts.join(SEPARATOR_SYMBOL)
        }
    }

    static createRoomOption(roomName, controls, controlFilterFn) {
        const filteredControls = controls.filter(ctrl => controlFilterFn?.(ctrl) ?? true)

        if (filteredControls.length === 0) {
            return null
        } else {
            return {
                noSection: true,
                title: roomName,
                options: filteredControls.map(this.createControlOption.bind(this))
            }
        }
    }

    static createRoomOptions(controlFilterFn) {
        const controls = ActiveMSComponent.getStructureManager().getAllControls();
        const roomGroups = groupByArray(Object.values(controls), (control) => control.room);
        return Object.values(roomGroups).map(({key, values}) => {
            let room = ActiveMSComponent.getStructureManager().getGroupByUUID(key, GroupTypes.ROOM),
                roomName = room?.name ?? _("room.without");
            return this.createRoomOption(roomName, values, controlFilterFn);
        }).filter(room => room);
    }

    static getPresenceControlCandidates() {
        return ActiveMSComponent.getStructureManager().getControlsByType(ControlType.PRESENCE_DETECTOR);
    }

    static getPresenceRoomsOptions() {
        let ctrlRoom;
        return this.getPresenceControlCandidates().map((ctrl) => {
            ctrlRoom = ctrl.getRoom();
            return {
                title: ctrlRoom ? ctrlRoom.name : ctrl.getName(),
                subTitle: ctrl.hasSiblingsInRoom ? ctrl.getName() : null,
                id: ctrl.uuidAction,
            }
        })
    }


    static updateSettings (updateSet) {
        console.log("EcoScreenSettings", "updateSettings: " + (JSON.stringify(updateSet)));
        PersistenceComponent.updateEcoScreenSetting(updateSet);
    }
}

export default EcoScreenUtils
