import { createStackNavigator } from '@react-navigation/stack'
import React, { useEffect } from 'react'
import QuickActionsScreen from './QuickActionsScreen';
import {navigatorConfig} from "LxComponents";
import SceneSelectorScreen from "./SceneSelectorScreen";
import {titleBarText} from "./QuickActionsUtils";

const QuickActionsStack = createStackNavigator();

function QuickActionsNavigator(props) {
    useEffect(() => {
        props.navigation.setOptions({
            ...navigatorConfig({
                headerShown: false,
                animationType: AnimationType.MODAL,
            })
        })
    }, []);

    return (
        <QuickActionsStack.Navigator initialRouteName={QuickActionsScreen.name}>
            <QuickActionsStack.Screen name={QuickActionsScreen.name} component={QuickActionsScreen} options={navigatorConfig({
                title: titleBarText()
            })} />
            <QuickActionsStack.Screen name={SceneSelectorScreen.name} component={SceneSelectorScreen} options={navigatorConfig({
                animationType: AnimationType.PUSH_OVERLAP_LEFT
            })}/>
        </QuickActionsStack.Navigator>
    )
}

export default QuickActionsNavigator
