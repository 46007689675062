import {Text} from "react-native";
import {useCallback, useEffect, useState} from "react";
import AmbientCardComp from "./AmbientCardComp";
import {LxReactSelectorScreen, navigatorConfig} from "LxComponents";
import globalStyles from "GlobalStyles";

const NO_FIXED_ROOM_UUID = "NO_FIXED_ROOM";

function AmbientRoomSelector({navigation, route}) {
    const [selectedRoomUuid, setSelectedRoomUuid] = useState(() => {
        let tmp;
        try {
            (Debug.AmbientMode || Debug.EcoScreeen) && console.log(AmbientRoomSelector.name, "useState - acquire old eco room uuid");
            tmp = PersistenceComponent.getOldEcoPlayerRoomUuid();
            (Debug.AmbientMode || Debug.EcoScreeen) && console.log(AmbientRoomSelector.name, "useState - old eco room uuid = " + tmp + ", set default settings right away");
            PersistenceComponent.setDefaultSettingsForRoom(true, tmp);
            (Debug.AmbientMode || Debug.EcoScreeen) && console.log(AmbientRoomSelector.name, "useState - default settings configured for room " + tmp);
        } catch (e) {
            console.error(AmbientRoomSelector.name, "Failed while trying to get old eco room uuid -- or configuring the settings with the default room! uuid=" + tmp);
        }
        return tmp;
    })
    let placeholder;

    useEffect(() => {
        navigation.setOptions({
            ...navigatorConfig({
                headerShown: false,
                animationType: AnimationType.NONE
            })
        })
    }, [])

    const _getCheckableOptions = useCallback(() => {
        let options = [],
            rooms = ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.ROOM);

        options = rooms.map(room => {
            return {
                title: room.name,
                id: room.uuid
            }
        })

        options.unshift({
            title: _('ambient.room-selector-title.no-permanent-room'),
            id: NO_FIXED_ROOM_UUID
        })

        return options;
    }, []);

    const _handleSelected = roomUuid => {
        (Debug.AmbientMode || Debug.EcoScreeen) && console.log(AmbientRoomSelector.name, "_handleSelected (roomUUID): " + roomUuid);
        setSelectedRoomUuid(roomUuid);
        if (roomUuid === NO_FIXED_ROOM_UUID) {
            roomUuid = undefined;
        }
        PersistenceComponent.setDefaultSettingsForRoom(true, roomUuid);
    }

    const leftButtonText = _('back');
    const leftButtonAction = useCallback(() => {
        navigation.goBack();
    }, [])
    const rightButtonText = _('ambient.open');
    const rightButtonAction = useCallback(() => {
        route.params.hideNavigator();
        PersistenceComponent.setAmbientOnboardingViewed(true);
        NavigationComp.showAmbientMode();
    }, [])

    if (PlatformComponent.isElectron) {
        if (PlatformComponent.isMacOS()) {
            placeholder = _('ambient.room-selector-title.mac');
        } else {
            placeholder = _('ambient.room-selector-title.pc');
        }
    } else {
        if (PlatformComponent.isIOS()) {
            placeholder = _('ambient.room-selector-title.ipad');
        } else {
            placeholder = _('ambient.room-selector-title.tablet');
        }
    }

    const headerTitleText = _('ambient.room-selector-title.title', {placeholder})


    return <AmbientCardComp leftButtonText={leftButtonText}
                            leftButtonAction={leftButtonAction}
                            rightButtonAction={rightButtonAction}
                            rightButtonText={rightButtonText}
                            rightButtonDisabled={false}>
            <Text style={styles.headerStyle}>{headerTitleText}</Text>
            <LxReactSelectorScreen options={_getCheckableOptions()}
                                   headerShown={false}
                                   onSelected={_handleSelected}
                                   selectedId={selectedRoomUuid}
                                   allowMultiple={false}
                                   animationType={AnimationType.NONE}
                                   withoutHorizontalSpaces={true}
                                   dispatchSelectionChange={true}/>
    </AmbientCardComp>
}

const styles = {
    headerStyle: {
        color: globalStyles.colors.text.primary,
        marginBottom: globalStyles.spacings.gaps.bigger,
        ...globalStyles.textStyles.title3.default
    }
}


export default AmbientRoomSelector
