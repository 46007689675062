'use strict';

define("LoadManagerControl", ["Control"], function (Control) {
    return class LoadManagerControl extends Control {
        constructor() {
            super(...arguments);
        }

    };
});
