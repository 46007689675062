import { useControl, useLiveState } from "LxComponents"
import MeterHeaderValue from "./meterHeaderValue"

export default function LiveMeterHeaderValue({ text, color, controlUUID, state }) {
    const control = useControl(controlUUID);
    const liveState = useLiveState(controlUUID, [state]);

    const getValue = () => {
        return lxUnitConverter.convertSplitAndApply(control.totalFormat, liveState.states[state])
    }

    const valueToDisplay = getValue()

    return <MeterHeaderValue
        valueTxt={valueToDisplay.valueTxt}
        unitTxt={valueToDisplay.succTxt}
        text={text}
        color={color}
    />
}