'use strict';

define(["AboutScreenBase"], function (AboutScreenBase) {
    return class PartnerBrandingScreen extends AboutScreenBase {
        constructor() {
            super($('<div />'));
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                let partnerIcon = ActiveMSComponent.getPartnerImageUUID();
                this.setIcon(partnerIcon);

                //this.setTitle(_("partner.installation-by"));
                //this.setSubtitle(ActiveMSComponent.getPartnerName());
            }.bind(this));
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        reloadTable() {
            this.tableContent = [{
                rows: [{
                    content: {
                        title: _("loxone-partner"),
                        disclosureText: ActiveMSComponent.getPartnerName()
                    }
                }]
            }];
            var partnerDetails = ActiveMSComponent.getPartnerDetails(),
                partnerDetailsKeys = Object.keys(partnerDetails);
            partnerDetailsKeys.forEach(function (detailKey) {
                var partnerDetail = partnerDetails[detailKey];

                if (partnerDetail !== "") {
                    var title, action, disclosureColor;

                    switch (detailKey) {
                        case "phoneNumber":
                            title = _("telephone");
                            action = this._openPhone.bind(this, partnerDetail);
                            disclosureColor = window.Styles.colors.green;
                            break;

                        case "eMailAddress":
                            title = _("email");
                            action = this._openMail.bind(this, partnerDetail);
                            disclosureColor = window.Styles.colors.green;
                            break;

                        case "website":
                            title = _("website");
                            action = this._openWebsite.bind(this, partnerDetail);
                            disclosureColor = window.Styles.colors.green;
                            break;

                        default:
                            title = "";
                    }

                    var cellObj = {
                        content: {
                            title: title,
                            disclosureText: partnerDetail,
                            disclosureColor: disclosureColor,
                            clickable: true
                        },
                        action: action
                    };
                    this.tableContent[0].rows.push(cellObj);
                }
            }.bind(this));
            return super.reloadTable(...arguments);
        }

        _openPhone(numb) {
            // Removes every "-", "/" and any whitespace character which is required by the "tel" protocol
            numb = numb.replace(/[-\/\s]/g, "");
            var number = "tel:" + numb;

            if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface || PlatformComponent.getPlatformInfoObj().platform === PlatformType.DeveloperInterface) {
                // Let the browser handle the tel protocol, the browser will open the phone application if available
                location.href = number;
            } else {
                appLink.openAppWithUrl(function (e) {
                    console.log(e);
                }, function (e) {
                    console.log("no phone app found!");
                }, number);
            }
        }

        _openMail(email) {
            var link = "mailto:" + email;
            NavigationComp.openWebsite(link);
        }

        _openWebsite(url) {
            if (url.indexOf("http://") < 0 && url.indexOf("https://") < 0) {
                url = "http://" + url;
            }

            NavigationComp.openWebsite(url);
        }

    };
});
