import * as React from "react"
import Svg, { Path } from "react-native-svg"

function ArrowCircle(props) {
    return (
        <Svg isreact="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path
                d="M.14 12.163l2.138-3.216c.189-.268.4-.405.633-.411.233-.007.438.13.615.411L5.7 12.163c.114.166.142.328.085.488-.056.16-.186.239-.387.239H3.526c.19 1.467.671 2.781 1.447 3.943a8.356 8.356 0 002.989 2.736c1.216.664 2.569.996 4.057.996a7.752 7.752 0 002.96-.584c.952-.39 1.92-1.024 2.904-1.904.189-.18.378-.275.567-.288.19-.012.366.064.53.23.151.14.214.322.189.546-.025.223-.151.437-.378.64a9.72 9.72 0 01-3.121 2.049 9.44 9.44 0 01-3.651.746c-1.728 0-3.298-.383-4.71-1.148a9.654 9.654 0 01-3.48-3.196c-.908-1.366-1.463-2.954-1.665-4.766H.424c-.227 0-.363-.077-.407-.23-.044-.153-.003-.319.123-.497zm4.9-6.182a.609.609 0 01-.19-.546c.025-.223.151-.437.378-.64A9.438 9.438 0 018.35 2.736 9.545 9.545 0 0112 2c1.728 0 3.298.383 4.71 1.148a9.654 9.654 0 013.48 3.196c.908 1.366 1.463 2.954 1.665 4.766h1.721c.227 0 .363.073.407.22.044.147.003.316-.123.507l-2.138 3.216c-.189.268-.4.405-.633.411-.233.006-.438-.13-.615-.411L18.3 11.837c-.114-.166-.142-.328-.085-.488.056-.16.192-.239.406-.239h1.873c-.177-1.48-.656-2.794-1.438-3.943a8.588 8.588 0 00-2.998-2.727C14.84 3.77 13.488 3.435 12 3.435a7.752 7.752 0 00-2.96.584c-.952.39-1.914 1.024-2.885 1.904-.214.18-.413.275-.596.288-.183.012-.356-.064-.52-.23z"
                stroke="none"
                strokeWidth={1}
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default ArrowCircle
