import {createStackNavigator} from "@react-navigation/stack";
import ActiveMiniserverScreen from "../../GUI/ActiveMiniserver/activeMiniserverScreen/activeMiniserverScreen";
import {LxReactScreenAdapter, LxReactControlContent, LxReactSelectorScreen} from "LxComponents";
import MessageOverviewScreen from "../LxReactMessageCenter/MessageOverviewScreen";
import MessageDetailScreen from "../LxReactMessageCenter/MessageDetailScreen";
import SortingScreen from "../../GUI/ViewController/cardScreen/sortingScreen.jsx";


function AmbientStackNavigator(props) {
    const AmbientStack = createStackNavigator();
    return <AmbientStack.Navigator
                                initialRouteName={ScreenState.ActiveMiniserverScreen}
                                screenOptions={ecoScreenOptions}>
            <AmbientStack.Screen name={ScreenState.AmbientControlContent} component={LxReactScreenAdapter} />
            <AmbientStack.Screen name={LxReactSelectorScreen.name} component={LxReactSelectorScreen} />
            <AmbientStack.Screen name={ScreenState.Statistic} component={LxReactScreenAdapter} />
            <AmbientStack.Screen name={ScreenState.SortingScreen} component={SortingScreen} />
            <AmbientStack.Screen name={ScreenState.ControlSettingsController} component={LxReactScreenAdapter} />
            <AmbientStack.Screen name={ScreenState.DeviceFavoritesSettings} component={LxReactScreenAdapter} />
            <AmbientStack.Screen name={ScreenState.AmbientAudioZoneControlContent} component={LxReactScreenAdapter} />
            <AmbientStack.Screen name={ScreenState.AmbientAudioZoneV2ControlContent} component={LxReactScreenAdapter} />

            <AmbientStack.Screen name={ScreenState.ActiveMiniserverScreen} component={ActiveMiniserverScreen} />
            <AmbientStack.Screen name={MessageOverviewScreen.name} component={MessageOverviewScreen} />
            <AmbientStack.Screen name={MessageDetailScreen.name} component={MessageDetailScreen} />
            <AmbientStack.Screen name={"ControlContent"} component={LxReactControlContent} />
        </AmbientStack.Navigator>
}

const ecoScreenOptions = {
    headerShown: false,
    cardOverlayEnabled: false
}

export default AmbientStackNavigator;
