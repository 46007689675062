'use strict';

window.MediaBrowserEditablePlaylistMixinV2 = {
    get Mixin() {
        return {
            startEditing(playlist, contentType, mediaInfo, playlistId, noDataNeeded) {
                Debug.Control.AudioZone.MediaEdit && console.log(this.viewId, "startEditing: dataNeeded? " + !noDataNeeded, getStackObj()); // Don't start a second defer, we might already have one from the initial call, the second one comes from the error handling!

                if (!this.startEditDef) {
                    this.startEditDef = Q.defer();
                }

                this._currentPlaylist = playlist;
                this._startArgs = arguments;
                this._editModePrms = this.control.audioserverComp.startEditing(contentType, mediaInfo, playlistId, noDataNeeded).then(this._editModeEnded.bind(this), this._handleEditModeError.bind(this), function () {
                    this.startEditDef.resolve();

                    this._editModeStarted.apply(this, arguments);
                }.bind(this));
                return this.startEditDef.promise;
            },

            stopEditing(playlistId) {
                Debug.Control.AudioZone.MediaEdit && console.log(this.viewId, "stopEditing");
                this.control.audioserverComp.stopEditing.apply(AudioserverComp, arguments);
                this._editModePrms = null;
                return Q(true);
            },

            isInEditMode() {
                return !!this._editModePrms;
            },

            didMoveCell(section, row, tableView, cell, oldIdx, newIdx) {
                if (this.editModeActive) {
                    Debug.Control.AudioZone.MediaBrowserBase && console.log(this.viewId, "didMoveCell >> update contentTypeItems");
                    var item = this.contentTypeItems[this.contentTypes[0]].all.splice(oldIdx, 1)[0];
                    this.contentTypeItems[this.contentTypes[0]].all.splice(newIdx, 0, item);
                    this.setTableContent(); // important to update it right away, the editableReusingListviews will reload asap and otherwise would show old content

                    this.control.audioserverComp.moveItem(oldIdx, newIdx);
                }
            },

            didRemoveCell(section, row, tableView) {
                if (this.editModeActive) {
                    var item = this.contentTypeItems[this.contentTypes[0]].all.splice(row, 1);
                    this.setTableContent(); // important to update it right away, the editableReusingListviews will reload asap and otherwise would show old content

                    this.control.audioserverComp.removeItem(row);
                }
            },

            _editModeEnded(cause) {
                Debug.Control.AudioZone.MediaEdit && console.log(this.viewId, "_handleEditModeEnded: " + cause);

                if (cause === MusicServerEnum.EditEndCause.STOPPED) {
                    this._updateEditMode(false);
                } else {
                    this._handleEditModeError({
                        cause: cause
                    });
                }
            },

            _updateEditMode(editActive) {
                var result = Q(true);
                this.editModeEnded && this.editModeEnded.apply(this, arguments);
                Debug.Control.AudioZone.MediaEdit && console.log(this.viewId, "_updateEditMode: " + editActive);

                if (editActive && this.isInEditMode()) {
                    return result;
                }

                if (editActive) {
                    result = this.startEditing.apply(this, this._startArgs);
                } else {
                    result = this.stopEditing(this._currentPlaylist.id);
                }

                return result;
            },

            /**
             * used when the edit mode was ended or could not be started due to some sort of error.
             * @param err   error object, might contain a userfriendly "reason" or a "cause" indicating what went wrong.
             * @private
             */
            _handleEditModeError(err) {
                console.error(this.viewId || this.name, "_handleEditModeError: error Object: " + JSON.stringify(err));
                this.onEditModeError && this.onEditModeError.apply(this, arguments);

                var successFn = null,
                    title = _("error"),
                    message = !err ? "" : err[MusicServerEnum.Attr.Container.REASON],
                    okayTitle = true,
                    content;

                if (err && err.cause) {
                    switch (err.cause) {
                        case MusicServerEnum.EditEndCause.MODIFIED:
                            title = _("media.edit.modified.title");
                            message = _("media.edit.modified.message", {
                                name: this.control.audioserverComp.getNameForItem(this._currentPlaylist)
                            });

                            successFn = function editErrorModifiedSuccessFn(res) {
                                Debug.Control.AudioZone.MediaEdit && console.log(this.viewId || this.name, "Modified popup successFn triggered! " + JSON.stringify(res));
                                this._startArgs[4] = false; // unset "noDownload" as now the data MUST be reloaded!

                                return this._updateEditMode(true);
                            }.bind(this);

                            okayTitle = _("edit");
                            break;

                        case MusicServerEnum.EditEndCause.CONFLICT:
                            title = _("media.edit.conflict.title");
                            message = _("media.edit.conflict.message", {
                                name: this.control.audioserverComp.getNameForItem(this._currentPlaylist)
                            });
                            break;

                        default:
                            break;
                    }

                    content = {
                        title: title,
                        message: message,
                        buttonOk: okayTitle,
                        buttonCancel: okayTitle !== true,
                        icon: Icon.INFO
                    };
                    NavigationComp.showPopup(content).then(successFn);

                    this._updateEditMode(false);
                } else {
                    console.error(this.viewId || this.name, "Edit mode did fail but no error was provided! Don't respond");
                }
            },

            _editModeStarted() {
                Debug.Media.Editor && console.log(this.viewId || this.name, "_editModeStarted");
                this.onEditModeStarted && this.onEditModeStarted.apply(this, arguments);
                this.control.audioserverComp.prepareForAdding().finally(this._updateEditMode.bind(this, false));
            },

            _getEditableActionsForCtxAndItem(ctx, item) {
                var actions = [],
                    actualItem,
                    wantedCtx;

                if (item === this) {
                    actualItem = item.lastSelectedItem;
                } else {
                    actualItem = item;
                }

                if (actualItem.contentType === MusicServerEnum.MediaContentType.SERVICE) {
                    if (this.identifier === MusicServerEnum.ControlContentIdentifiers.SPOTIFY) {
                        wantedCtx = this.CONTEXT.SPOTIFY;
                    }
                } else if (actualItem.contentType === MusicServerEnum.MediaContentType.PLAYLISTS) {
                    wantedCtx = this.CONTEXT.LMS;
                }

                if (ctx === wantedCtx) {
                    if (item === this && this.control.audioserverComp.isFileTypePlaylist(item.lastSelectedItem.type)) {
                        actions.pushObject(this._getActionWithTmplForCtx({
                            content: {
                                title: _("media.playlist.edit-list"),
                                leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_EDIT
                            },
                            action: this._editPlaylist.bind(this, item.lastSelectedItem)
                        }, ctx));
                        actions.pushObject(this._getActionWithTmplForCtx({
                            content: {
                                title: _("media.playlist.rename"),
                                leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_EDIT
                            },
                            action: this._renamePlaylist.bind(this, this.lastSelectedItem)
                        }, ctx));
                        actions.pushObject(this._getActionWithTmplForCtx({
                            content: {
                                title: _("media.playlist.delete"),
                                leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_DELETE
                            },
                            action: this._deletePlaylist.bind(this, this.lastSelectedItem)
                        }, ctx));
                    } else if (this.control.audioserverComp.isFileTypePlaylist(item.type)) {
                        actions.pushObject(this._getActionWithTmplForCtx({
                            content: {
                                title: _("media.playlist.rename"),
                                leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_EDIT
                            },
                            action: this._renamePlaylist.bind(this, item)
                        }, ctx));
                        actions.pushObject(this._getActionWithTmplForCtx({
                            content: {
                                title: _("media.playlist.delete"),
                                leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_DELETE
                            },
                            action: this._deletePlaylist.bind(this, item)
                        }, ctx));
                    }
                }

                return actions;
            },

            _deletePlaylist(item) {
                // This is needed to automatically stop the editing mode
                return this._performEditActionForItemWithinPlaylistId(item, item[MusicServerEnum.Event.ID], function () {
                    return this.control.audioserverComp.deleteContainer(MusicServerEnum.MediaContentType.PLAYLISTS, this.getMediaInfo(item.contentType), item[MusicServerEnum.Event.ID]).then(function () {
                        this.control.audioserverComp.showConfirmationFeedback(_("media.notifications.playlist.delete"));

                        if (item === this.lastSelectedItem) {
                            this.ViewController.navigateBack();
                        }
                    }.bind(this));
                }.bind(this), true); // no download required when deleting sth
            },

            _renamePlaylist(item) {
                var texts = this.control.audioserverComp.getTitleSubtitleForItem(item),
                    playlistId = item[MediaEnum.Event.ID];
                return this._performEditActionForItemWithinPlaylistId(item, playlistId, function () {
                    return this.control.audioserverComp.showNamePopup(_("media.playlist.rename"), _("apply"), decodeURIComponent(texts.title), decodeURIComponent(texts.title)).then(function (newName) {
                        return this.control.audioserverComp.renameContainer(item.contentType, this.getMediaInfo(item.contentType), playlistId, newName).then(function () {
                            if (this.lastSelectedItem.id === item.id) {
                                this.lastSelectedItem.name = newName;

                                if (HD_APP) {
                                    this.ViewController._checkTitlebar();
                                } else {
                                    this._titleBar.setTitleBarSideTexts(this.titleBarText());
                                }

                                var sectionHeaderTitle = this.element.find(".playlist-information__text-container >.text-container__title");

                                if (sectionHeaderTitle[0]) {
                                    sectionHeaderTitle.text(newName);
                                }
                            }
                        }.bind(this));
                    }.bind(this));
                }.bind(this), true); // no download required when renaming sth
            },

            _addNewMusicToItem(item, mustHandleEditMode) {
                var addMediaVc;

                if (item.contentType === MusicServerEnum.MediaContentType.SERVICE) {
                    addMediaVc = Controls.AudioZoneV2Control.Enums.ScreenState.ADD_MEDIA_VC_PLAYLIST_SPOTIFY;
                } else {
                    addMediaVc = Controls.AudioZoneV2Control.Enums.ScreenState.ADD_MEDIA_VC_PLAYLIST;
                }

                this.ViewController.showState(addMediaVc, null, {
                    delegate: this,
                    playlistId: item[MusicServerEnum.Event.ID],
                    control: this.control,
                    addTargetName: _("media.library.upload.title"),
                    targetMediaScreenState: Controls.AudioZoneV2Control.Enums.ScreenState.ADD_MEDIA,
                    blockedIds: [item.id],
                    mustHandleEditMode: !!mustHandleEditMode
                });
            },

            _editPlaylist(playlist) {
                return this.titleBarActionRight(this.RIGHT_SIDE_BUTTON.EDIT);
            },

            _performEditActionForItemWithinPlaylistId(item, playlistId, boundEditAction, noDownloadRequired) {
                return NavigationComp.showWaitingFor(this.startEditing(item, item.contentType, this.getMediaInfo(item.contentType), playlistId, !!noDownloadRequired)).finally(function () {
                    return Q(boundEditAction().finally(function () {
                        return this.stopEditing(playlistId);
                    }.bind(this)));
                }.bind(this));
            }
        }
    }
};
