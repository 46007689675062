'use strict';

define("SignalingBase", function () {
    return class SignalingBase {
        //region Private
        _DELEGATE_FN = {
            ON_OFFER_RESPONSE: "onOfferResponse",
            ON_CONNECTION_CLOSED: "onSignalingClosed"
        }; //endregion Private

        constructor({
                        delegate
                    }) {
            Object.assign(this, DelegateHandler.Mixin);
            this.name = this.constructor.name;
            this.__delegates = [];
            this.setDelegate(delegate);
        }

        destroy() {
            this.__delegates = null;
        }

        /**
         * Add additional delegates if needed
         * @param delegate
         */
        setDelegate(delegate) {
            delegate && this.__delegates.pushIfNoDuplicate(delegate);
        }

        /**
         * Function to setup the communication layer
         * @returns {Promise}
         */
        setupCommunication() {
            //fast-class-es6-converter: auto generated
            throw "Implement the 'setupCommunication' function in your class";
        }

        /**
         * Sends an offer
         * @param localDescription The peer connections local description
         * @returns {Promise} resolves with the remoteDescription or rejects with an error
         */
        sendOffer() {
            //fast-class-es6-converter: auto generated
            throw "Implement the 'sendOffer' function in your class";
        }

        /**
         * Sends a hangup command
         */
        hangUp() {
            //fast-class-es6-converter: auto generated
            throw "Implement the 'hangUp' function in your class";
        }

        /**
         * Notifies all delegates about a received offer
         * @param offerResp
         * @private
         */
        _onOfferResponse(offerResp) {
            this._notifyDelegates(this._DELEGATE_FN.ON_OFFER_RESPONSE, offerResp);
        }

        /**
         * The Remote peer closed the connection
         * @param [reason] Reason why the connection was closed
         * @private
         */
        _onConnectionClosed(reason) {
            this._notifyDelegates(this._DELEGATE_FN.ON_CONNECTION_CLOSED, reason);
        }

    };
});
