'use strict';

class HomeKitSelectHomeScreen extends GUI.TableViewScreenV2 {
    constructor(details) {
        super($('<div />'));
    }

    viewDidLoad() {
        var args = arguments;
        this.ViewController._preventReset = false;
        return super.viewDidLoad(...args).then(function () {
            this.continueButtonElem = $("<div class='continue-button'>" + _('home-kit.continue') + "</div>");
            this.continueButton = new GUI.LxButton(this, this.continueButtonElem);
            this.insertSubviewAfter(this.continueButton, this.tableView.getElement());

            this.continueButton.onButtonTapped = function () {
                this._selectHomeAndShowSetupCode(this._currentSelection);
            }.bind(this);
        }.bind(this));
    }

    viewWillAppear() {
        this.reloadTable();
        return super.viewWillAppear(...arguments);
    }

    getAnimation() {
        return AnimationType.NONE;
    }

    closeAction() {
        this.ViewController.dismiss();
    }

    titleBarText() {
        return _("home-kit.home.title");
    }

    reloadTable() {
        var args = arguments;
        return this._loadAllHomes(true).then(() => {
            var descriptionSection = {
                    footerTitle: _("home-kit.home.subtitle1") + "<br><br>" + _("home-kit.home.subtitle2"),
                    rows: []
                },
                homeSection = this._createHomeSection(),
                addHomeSection = this._createAddHomeSection();

            this.tableContent = [descriptionSection, homeSection, addHomeSection];
            return super.reloadTable(...args);
        });
    }

    _createHomeSection() {
        var homeSection;

        if (this._homes && this._homes.length > 0) {
            homeSection = {
                headerTitle: _("home-kit.home.title"),
                rows: []
            };

            this._homes.forEach(function (home) {
                homeSection.rows.push({
                    content: {
                        title: home,
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE,
                        selected: home === this._currentSelection
                    },
                    type: GUI.TableViewV2.CellType.CHECKABLE,
                    didCheckCell: function didCheckCell(value) {
                        this._currentSelection = value.content.title;
                    }.bind(this)
                });
            }.bind(this));
        }

        return homeSection;
    }

    _createAddHomePopupContent() {
        return {
            title: _("home-kit.home.popup.title"),
            input: {
                id: "homeName",
                required: true,
                placeholder: _("home-kit.home.title"),
                type: GUI.LxInputEnum.TEXT,
                validationRegex: Regex.HOME_KIT_NAME,
                filterRegex: Regex.HOME_KIT_NAME_FILTER
            },
            message: _("home-kit.home.popup.message"),
            buttonOk: _("home-kit.home.popup.create"),
            buttonCancel: _("home-kit.home.popup.cancel")
        };
    }

    _showAddHomePopupAction() {
        console.warn("create home clicked");
        this._createHomePopup = NavigationComp.showPopup(this._createAddHomePopupContent(), PopupType.INPUT);

        this._createHomePopup.then(this._addHomeAndShowSetupCode.bind(this), function () {
            console.log("User canceled creating new home");
        }.bind(this));
    }

    _createAddHomeSection() {
        var addHomeSection = {
            rows: []
        };
        addHomeSection.rows.push({
            content: {
                title: _("home-kit.home.add-home-button"),
                clickable: true
            },
            type: GUI.TableViewV2.CellType.GENERAL,
            action: this._showAddHomePopupAction.bind(this)
        });
        return addHomeSection;
    }

    _addHomeAndShowSetupCode(button) {
        var callbackDefer = Q.defer(),
            waitingPromise,
            navigationFunction,
            homeName = button.result.replaceAll("_", "").trim();
        waitingPromise = ActiveMSComponent.addHomeKitHome(homeName).then(function () {
            navigationFunction = this._navigateToSetupCode.bind(this);
            return ActiveMSComponent.getHomeKitPairingInfo().then(function (pairingInfo) {
                this.pairingInfo = pairingInfo;
                this.home = homeName;
                this.createHome = true;
            }.bind(this));
        }.bind(this)); // Show waiting screen while HomeKit is configured

        this.ViewController.showState(ScreenState.WaitingScreen, null, {
            message: _("home-kit.loading.message.home", {
                home: homeName
            }),
            cancelButtonText: _("home-kit.loading.cancel"),
            callbackDefer: callbackDefer,
            waitingPromise: waitingPromise
        });
        callbackDefer.promise.then(function () {
            navigationFunction();
        }.bind(this), function (error) {
            this.ViewController.handleError(error, _("home-kit.error.get-setup-code"));
        }.bind(this));
    }

    _navigateToSetupCode() {
        var defer = Q.defer();
        this.ViewController.showState(ScreenState.HomeKitSetupCode, null, {
            iconSrc: Icon.HOME_KIT.HOME,
            title: _("home-kit.setup-code.title"),
            message: _("home-kit.setup-code.message"),
            continueBtnText: _('home-kit.continue'),
            shareButtonText: _("home-kit.setup-code.share-button"),
            continueDef: defer,
            pairingInfo: this.pairingInfo,
            home: this.home,
            createHome: this.createHome
        });
        defer.promise.done(function () {
            this.ViewController.dismiss();
        }.bind(this));
    }

    _navigateToAccessories() {
        this.ViewController.showState(ScreenState.HomeKitAccessories);
    }

    _selectHomeAndShowSetupCode(home) {
        var callbackDefer = Q.defer(),
            waitingPromise;
        waitingPromise = ActiveMSComponent.getHomeKitPairingInfo().then(function (pairingInfo) {
            this.pairingInfo = pairingInfo;
            this.home = home;
            this.createHome = false;
        }.bind(this)); // Show waiting screen while HomeKit is configured

        this.ViewController.showState(ScreenState.WaitingScreen, null, {
            message: _("home-kit.loading.message.connect", {
                home: home
            }),
            cancelButtonText: _("home-kit.loading.cancel"),
            callbackDefer: callbackDefer,
            waitingPromise: waitingPromise
        });
        callbackDefer.promise.then(this._navigateToSetupCode.bind(this), function (error) {
            this.ViewController.handleError(error, _("home-kit.error.get-setup-code"));
        }.bind(this));
    }

    _loadAllHomes(initial) {
        return ActiveMSComponent.getAllHomeKitHomes().then(function (homes) {
            this._homes = homes;

            if (this._homes && this._homes.length > 0 && initial) {
                this._currentSelection = this._homes[0];
            }
        }.bind(this));
    }

}

GUI.HomeKitSelectHomeScreen = HomeKitSelectHomeScreen;
