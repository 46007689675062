'use strict';

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.BATTERY_MONITOR = "BatteryMonitorCell";

    class BatteryMonitorCell extends GUI.TableViewV2.Cells.GeneralCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        _applyNewContent(newContent) {
            var promises = [super._applyNewContent(...arguments)];
            var actions = [];
            actions.push(this._updLbl.bind(this, this.elements.title, newContent.title, newContent.titleColor, true));
            actions.push(this._updLbl.bind(this, this.elements.subtitle, newContent.subtitle, newContent.subtitleColor, true));
            promises.push(GUI.animationHandler.scheduleAll(actions));
            return Q.all(promises);
        }

    }

    GUI.TableViewV2.Cells.BatteryMonitorCell = BatteryMonitorCell;
    return GUI;
}(window.GUI || {});
