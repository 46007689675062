'use strict';
/*
 title
    group
    customGroupTitles
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.GROUP_CARD = "GroupCard";

    class GroupCard extends GUI.TableViewV2.Cells.CardsCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            Object.assign(this, CellContextMenuHandler.Mixin);
        }

        setContent(content) {
            var group = content.group;
            content.title2 = group.name;

            if (Debug.Sorting) {
                content.title2 = "(" + group.defaultRating + ") " + content.title2;
            }

            content.iconSrc = group.image;

            if (!content.useSimpleDesign) {
                content.iconColor = group.color;
            }

            content.msIcon = true;
            content.clickable = true;
            super.setContent(...arguments); // Used for our UI-Test

            this.element.attr("data--uuid", group.uuid);
            this.element.attr("data--group-type", group.groupType);
        }

        viewDidLoad() {
            let vdlPrms = super.viewDidLoad(...arguments);
            this._simpleDesignReg = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.SimpleDesignChanged, this._updateIconColor.bind(this));

            if ((ActiveMSComponent.isExpertModeLightEnabled() || ActiveMSComponent.isExpertModeEnabled()) && !NavigationComp.getCurrentActivityType()) {
                this._setupListener();
            }

            return Q(vdlPrms).then(() => {
                return this.appendReactComp({
                    reactComp: GUI.LxReactCardCell,
                    compProps: {
                        content: this.content,
                        ViewController: this.ViewController,
                        showContextMenu: (ev) => {
                            this._showSortingContextMenu(ev)
                        }
                    }
                });
            })
        }

        destroy() {
            NavigationComp.removeFromUIEvent(this._simpleDesignReg);
            super.destroy();
        }

        onSelected(event, forceSelection) {
            this._cancelPress(true);

            super.onSelected(...arguments); // also call base, eg. for search... (before navigation to give it the chance to navigate back!)

            var group = this.content.group;
            let detailScreenState = group.groupType === GroupTypes.CATEGORY ? ScreenState.CategoryDetail : ScreenState.RoomDetail;
            this.ViewController.showState(detailScreenState, null, {
                groupType: group.groupType,
                groupUUID: group.uuid
            });
        }

        _updateIconColor(ev, enabled) {
            this.setIconColor(enabled ? null : this.content.group.color);
        }

    }

    GUI.TableViewV2.Cells.GroupCard = GroupCard;
    return GUI;
}(window.GUI || {});
