'use strict';

class TaskRecordingBar extends GUI.InstructionBar {
    constructor() {
        super();
        this.element.addClass("task-recording-bar");
        SandboxComponent.startTaskRecording();
        this.task = SandboxComponent.getRecordedTask();
    }

    viewDidLoad() {
        super.viewDidLoad(...arguments); // add arrow

        this.elements.arrow = $(ImageBox.getResourceImageWithClasses("resources/Images/General/disclosure-icon.svg", "task-recording-bar__arrow")).hide();
        this.elements.arrow.insertAfter(this.elements.textLbl);
        this.elements.stopBtn = this.addButton("resources/Images/ActiveMiniserver/TaskRecorder/recording-stop.svg", "stop-btn");

        this._updateCommands();
    }

    viewDidAppear() {
        super.viewDidAppear(...arguments);
        SandboxComponent.registerForNewTaskCommands(this._updateCommands.bind(this));
        this.elements.stopBtn.on("click", this._finishRecording.bind(this));
    }

    viewDidDisappear(viewRemainsVisible) {
        SandboxComponent.unregisterForNewTaskCommands();
        this.elements.stopBtn.off("click");
        super.viewDidDisappear(viewRemainsVisible);
    }

    destroy() {
        this.task = null;
        SandboxComponent.stopTaskRecording();
        super.destroy();
    }

    onBarClicked() {
        if (this.task.commands.length > 0) {
            NavigationComp.showState(ScreenState.TaskRecordingCommands);
        }
    }

    // Private methods
    _updateCommands() {
        this.setText(_('taskrecorder.commands-recorded', {
            count: this.task.commands.length
        }));
        this.elements.arrow.toggle(this.task.commands.length > 0);
    }

    _finishRecording() {
        var task = this.task; // save a reference to the task because of destroy fn!

        NavigationComp.stopActivity(GUI.ActiveMiniserverViewController.Activity.TaskRecorder);

        if (task.commands.length > 0) {
            NavigationComp.showState(ScreenState.TaskDetails, {
                task: task
            });
        } else {
            NavigationComp.showState(ScreenState.TaskRecorder);
        }
    }

}

GUI.TaskRecordingBar = TaskRecordingBar;
