'use strict';
/**
 * callbacks:
 * onFavoriteSelected(idx, fav)      called whenever a favorite was selected.
 */

define(["FavColorButton", "ColorPickerV2ControlEnums"], function (FavColorButton, ColorPickerV2ControlEnums) {
    return class FavoriteSelectorBase extends GUI.View {
        constructor() {
            super($('<div class="favorite-selector-base"/>'));
            this.favViews = [];
        }

        viewDidAppear() {
            return super.viewDidAppear(...arguments).then(this._redrawFavorites.bind(this));
        }

        dispatchFavoriteSelected(fav) {
            this.onFavoriteSelected && this.onFavoriteSelected(fav);
        }

        setFavorites(favorites) {
            if (!this.cmpFavs || this.cmpFavs !== JSON.stringify(favorites)) {
                this.favorites = favorites;
                this.cmpFavs = JSON.stringify(this.favorites); // store to speed up comparing later on.

                return this._redrawFavorites();
            } else {
                return Q.resolve();
            }
        }

        setTemperatureOnlyMode(tempOnly) {
            this._tempOnlyMode = tempOnly;
            return this._redrawFavorites();
        }

        // ------------------------------------------------------------------------
        //           Private Methods
        // ------------------------------------------------------------------------
        _redrawFavorites() {
            console.log(this.viewId, "_redrawFavorites");
            var newFavView, fav, i, colors;
            var promises = []; // update existing favorite colors

            for (i = 0; i < this.favViews.length && i < this.favorites.length; i++) {
                fav = this.favorites[i];

                if (fav.type === ColorPickerV2ControlEnums.PickerMode.SEQUENCE) {
                    colors = fav.colors;
                } else {
                    colors = [fav];
                }

                this.favViews[i].setColors(colors);

                if (this._tempOnlyMode && colors[0].type !== ColorPickerV2ControlEnums.PickerMode.TEMPERATURE) {
                    promises.push(this.removeSubview(this.favViews[i]));
                }
            } // add potential new favorites


            for (; i < this.favorites.length; i++) {
                fav = this.favorites[i];

                if (fav.type === ColorPickerV2ControlEnums.PickerMode.SEQUENCE) {
                    colors = fav.colors;
                } else {
                    colors = [fav];
                }

                newFavView = new FavColorButton(colors, i);
                newFavView.getElement().addClass("favorite-selector__item");
                newFavView.onFavColorButtonTapped = this.onFavoriteButtonTapped.bind(this);
                promises.push(this.appendSubview(newFavView));
                this.favViews.push(newFavView);
            } // remove favorites that aren't there anymore.


            for (; i < this.favViews.length; i++) {
                promises.push(this.removeSubview(this.favViews[i]));
            }

            if (this.favViews.length > this.favorites.length) {
                this.favViews.splice(this.favorites.length, this.favViews.length - this.favorites.length);
            }

            return Q.all(promises);
        }

        onFavoriteButtonTapped(idx) {
            this.onFavoriteSelected && this.onFavoriteSelected(idx, this.favorites[idx]);
        }

    };
});
