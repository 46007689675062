'use strict';

define(["AudioZoneV2ControlEnums", "MediaBrowserV2Base", "SpotifyAccountManager"], function (AudioZoneV2ControlEnums, MediaBrowserV2Base, SpotifyAccountManager) {
    class MediaBrowserV2Service extends MediaBrowserV2Base {
        //region Static
        static isRadio(identifier) {
            return Object.values(MusicServerEnum.Radios).indexOf(identifier) > -1;
        }

        static getCellFromContentTypeItem(item, details, contentType, isPlaylist, isBrowsable, actionClb, buttonTappedClb, vc, isInEditMode) {
            return Controls.AudioZoneV2Control.MediaBrowserV2Base.getCellFromContentTypeItem.apply(this, arguments);
        }

        static getCellButtonIconSrc(item, details, contentType, vc, isInEditMode) {
            var baseIcon = Controls.AudioZoneV2Control.MediaBrowserV2Base.getCellButtonIconSrc.apply(this, arguments);

            if (this.isRadio(details.identifier) && AudioserverComp.isFileTypeBrowsable(item.type)) {
                baseIcon = null;
            }

            return baseIcon;
        }

        static getCellButtonIconColor() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Base.getCellButtonIconColor.apply(this, arguments);
        }

        static getCellTypeForItemCellAtIndex(item, contentType, isInEditMode) {
            return Controls.AudioZoneV2Control.MediaBrowserV2Base.getCellTypeForItemCellAtIndex.apply(this, arguments);
        }

        static getIconObjForCollection() {
            var iconObj = {};
            iconObj.fallback = Icon.AudioZone.NEW.SPOTIFY_LIKED_SONGS;
            iconObj.highRes = Icon.AudioZone.NEW.SPOTIFY_LIKED_SONGS;
            iconObj.lowRes = Icon.AudioZone.NEW.SPOTIFY_LIKED_SONGS;
            iconObj.fullSizeSvg = true;
            return iconObj;
        }

        static getIconObjForItem(item, identifier) {
            var baseIconObj = Controls.AudioZoneV2Control.MediaBrowserV2Base.getIconObjForItem.apply(this, arguments);

            if (this.isRadio && this.isRadio(identifier) && AudioserverComp.isFileTypeBrowsable(item.type)) {
                if (item.cmd === MusicServerEnum.Radios.WORLD) {
                    baseIconObj.fallback = Icon.AudioZone.NEW.GLOBE;
                    baseIconObj.highRes = Icon.AudioZone.NEW.GLOBE;
                    baseIconObj.lowRes = Icon.AudioZone.NEW.GLOBE;
                } else if (item.cmd === MusicServerEnum.Radios.MUSIC) {
                    baseIconObj.fallback = Icon.AudioZone.NEW.GENRE;
                    baseIconObj.highRes = Icon.AudioZone.NEW.GENRE;
                    baseIconObj.lowRes = Icon.AudioZone.NEW.GENRE;
                } else if (item.cmd === MusicServerEnum.Radios.LOCAL) {
                    baseIconObj.fallback = Icon.AudioZone.NEW.LOCATION_PIN;
                    baseIconObj.highRes = Icon.AudioZone.NEW.LOCATION_PIN;
                    baseIconObj.lowRes = Icon.AudioZone.NEW.LOCATION_PIN;

                } else {
                    baseIconObj.fallback = Icon.AudioZone.NEW.STREAM;
                    baseIconObj.highRes = Icon.AudioZone.NEW.STREAM;
                    baseIconObj.lowRes = Icon.AudioZone.NEW.STREAM;
                }

                baseIconObj.color = window.Styles.colors.green;

            } else if (this.isRadio && this.isRadio(identifier) && identifier === MusicServerEnum.Radios.CUSTOM) {
                // a custom stream --> set a proper fallback icon to default to "stream"
                baseIconObj.fallback = Icon.AudioZone.NEW.STREAM;

            } else if (identifier === MusicServerEnum.Service.SPOTIFY) {
                if (item.hasOwnProperty("id")) {
                    if (!baseIconObj.color) {
                        baseIconObj.color = window.Styles.colors.green;
                    }

                    switch (item.id) {
                        case MusicServerEnum.Spotify.TYPES.FEATURES:
                            baseIconObj.fallback = Icon.AudioZone.NEW.CLOCK_FIRE;
                            baseIconObj.highRes = Icon.AudioZone.NEW.CLOCK_FIRE;
                            baseIconObj.lowRes = Icon.AudioZone.NEW.CLOCK_FIRE;
                            break;

                        case MusicServerEnum.Spotify.TYPES.NEW_RELEASE:
                            baseIconObj.fallback = Icon.AudioZone.NEW.CLOCK;
                            baseIconObj.highRes = Icon.AudioZone.NEW.CLOCK;
                            baseIconObj.lowRes = Icon.AudioZone.NEW.CLOCK;
                            break;

                        case MusicServerEnum.Spotify.TYPES.CATEGORIES:
                            baseIconObj.fallback = Icon.AudioZone.NEW.GENRE;
                            baseIconObj.highRes = Icon.AudioZone.NEW.GENRE;
                            baseIconObj.lowRes = Icon.AudioZone.NEW.GENRE;
                            break;

                        case MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS:
                            baseIconObj.fallback = Icon.AudioZone.NEW.PLAYLIST;
                            baseIconObj.highRes = Icon.AudioZone.NEW.PLAYLIST;
                            baseIconObj.lowRes = Icon.AudioZone.NEW.PLAYLIST;
                            break;

                        case MusicServerEnum.Spotify.TYPES.TRACKS:
                            updateObject(baseIconObj, Controls.AudioZoneV2Control.MediaBrowserV2Service.getIconObjForCollection());
                            break;

                        case MusicServerEnum.Spotify.TYPES.ALBUMS:
                            baseIconObj.fallback = Icon.AudioZone.NEW.CD;
                            baseIconObj.highRes = Icon.AudioZone.NEW.CD;
                            baseIconObj.lowRes = Icon.AudioZone.NEW.CD;
                            break;

                        case MusicServerEnum.Spotify.TYPES.ARTISTS:
                            baseIconObj.fallback = Icon.AudioZone.NEW.MIC_2;
                            baseIconObj.highRes = Icon.AudioZone.NEW.MIC_2;
                            baseIconObj.lowRes = Icon.AudioZone.NEW.MIC_2;
                            baseIconObj.rounded = true;
                            break;

                        case MusicServerEnum.Spotify.TYPES.PODCASTS:
                            baseIconObj.fallback = Icon.AudioZone.NEW.PODCAST;
                            baseIconObj.highRes = Icon.AudioZone.NEW.PODCAST;
                            baseIconObj.lowRes = Icon.AudioZone.NEW.PODCAST;
                            baseIconObj.rounded = true;
                            break;

                        case MusicServerEnum.Spotify.TYPES.LIBRARY:
                            baseIconObj.fallback = Icon.AudioZone.NEW.LIBRARY;
                            baseIconObj.highRes = Icon.AudioZone.NEW.LIBRARY;
                            baseIconObj.lowRes = Icon.AudioZone.NEW.LIBRARY;
                            break;

                        default:
                            if (item.id.endsWith(":collection")) {
                                // spotify collections (liked songs) as favorites are handled here.
                                updateObject(baseIconObj, Controls.AudioZoneV2Control.MediaBrowserV2Service.getIconObjForCollection());
                            } else {
                                baseIconObj.rounded = item.tag === MusicServerEnum.Tag.ARTIST;
                            }

                    }
                }
            }

            return baseIconObj;
        } //endregion Static


        //region Getter
        get RIGHT_SIDE_BUTTON() {
            let baseVal = super.RIGHT_SIDE_BUTTON;
            baseVal.ADD_PLAYLIST = "right-side__touch-area--add-playlist";
            baseVal.ADD_RADIO = "right-side__touch-area--add-radio";
            return baseVal;
        }

        get desc() {
            if (MediaBrowserV2Service.isRadio(this.identifier)) {
                return _("media.service.webradio");
            } else {
                return _("media.popup.spotify.title");
            }
        } //endregion Getter


        constructor(details) {
            super(...arguments);
            this.service = this.getMediaInfo().service;
        }

        viewDidLoad() {
            this._onlyShowEditable = this.ViewController instanceof GUI.AddMediaViewControllerV2PlaylistPicker;
            return super.viewDidLoad(...arguments);
        }

        setViewController() {
            super.setViewController(...arguments);
            this.setViewControllerHtmlAttr(MusicServerEnum.HtmlProp.ACC_DEP_CONTENT, !!this._isContentAccountDependent());

            if (!this.ViewController.handleOnSubtitleTapped) {
                this.ViewController.handleOnSubtitleTapped = function () {
                    SpotifyAccountManager.shared(this.control).showAccountSelector(this.ViewController);
                }.bind(this);
            }
        }

        viewControllerSubtitleText() {
            if (this.identifier && !MediaBrowserV2Service.isRadio(this.identifier) && this._isContentAccountDependent()) {
                try {
                    return SpotifyAccountManager.shared(this.control).activeUserName;
                } catch (e) {
                    console.error(e);
                }
            }

            return null;
        }

        titleBarActionRight(buttonId) {
            if (buttonId === this.RIGHT_SIDE_BUTTON.ADD_PLAYLIST) {
                return this.createPlaylist();
            } else if (buttonId === this.RIGHT_SIDE_BUTTON.ADD_RADIO) {
                return this._showCustomRadioEdit();
            } else {
                return super.titleBarActionRight(...arguments);
            }
        }

        onContentTypeReload(reason, contentType) {
            if (reason === MusicServerEnum.ReloadCause.USER_CHANGED) {
                // Spotify specific event
                var newActiveUser = SpotifyAccountManager.shared(this.control).activeUser || {};
                var previousUser = SpotifyAccountManager.shared(this.control).previousUser || {}; // some screens, like "more" on the spotifyOverview, have the user id as username..

                if (this.username === previousUser.id) {
                    this.username = newActiveUser.id;
                } else {
                    this.username = newActiveUser.user;
                }

                if (this.details.username === previousUser.id) {
                    this.details.username = newActiveUser.id;
                } else {
                    this.details.username = newActiveUser.user;
                }

                this.ViewController._checkTitlebar();

                if (this._isContentAccountDependent() && this._shouldResetContentOnReload(reason, contentType) && this.lastSelectedItem && (this.lastSelectedItem.ownerId === previousUser.id || this.lastSelectedItem.owner_id === previousUser.id)) {
                    this.lastSelectedItem.owner_id = newActiveUser.id;
                    this.lastSelectedItem.ownerId = newActiveUser.id;
                    this.lastSelectedItem.owner = newActiveUser.user;
                }
            }

            return super.onContentTypeReload(...arguments);
        }

        _shouldResetContentOnReload(reason, contentType) {
            var shouldReload = super._shouldResetContentOnReload(...arguments);

            if (reason === MusicServerEnum.ReloadCause.USER_CHANGED) {
                // only reload if the content needs to, history or favorites aren't depending on the current account.
                shouldReload = shouldReload && this._isContentAccountDependent();
            }

            return shouldReload;
        }

        _isContentAccountDependent() {
            var isDependent = true,
                cause = '';

            if (!this.lastSelectedItem) {// can't tell.
            } else if (this.lastSelectedItem.hasOwnProperty("_historyDetails")) {
                cause = "history item";
                isDependent = false;
            } else if (this.lastSelectedItem.listedInFavourites) {
                cause = "favorites item";
                isDependent = false;
            }

            return isDependent;
        }

        processContentTypeDataChunk(data, contentType) {
            if (this._onlyShowEditable) {
                // We may need to filter out any non editable items!
                data.items = data.items.filter(function (item) {
                    return this.control.audioserverComp.isFileTypeEditable(item.type);
                }.bind(this));
                data.totalItems = data.items.length;
            }

            return super.processContentTypeDataChunk(data, contentType);
        }

        getTableContent() {
            var baseTableContent = super.getTableContent(...arguments); // Only show the "add" cell if we don't display a Playlist

            if (this._shouldOfferNewPlaylist()) {
                if (!baseTableContent[0] || !baseTableContent[0].hasOwnProperty("rows")) {
                    baseTableContent[0] = {
                        rows: []
                    };
                }

                baseTableContent[0].rows.unshift({
                    content: {
                        clickable: true,
                        title: _("media.playlist.create"),
                        leftIconSrc: Icon.TitleBar.PLUS,
                        leftIconColor: window.Styles.colors.green,
                        leftIconBgEnabled: true,
                        disclosureIcon: false
                    },
                    action: this.createPlaylist.bind(this)
                });
            }

            return baseTableContent;
        }

        getEmptyViewTitle() {
            if (!this.hasError && !this.receivedContent && MediaBrowserV2Service.isRadio(this.identifier)) {
                return _("audio-server.tunein.awaiting");
            } else {
                return super.getEmptyViewTitle(...arguments);
            }
        }

        _isCustomRadio() {
            return MediaBrowserV2Service.isRadio(this.identifier) && this.identifier === MusicServerEnum.Radios.CUSTOM;
        }

        emptyViewConfig() {
            if (this._isCustomRadio() && this.receivedContent) {
                return {
                    iconSrc: Icon.ADD,
                    title: _("audio-server.custom-radio.add"),
                    buttonAction: () => {
                        this.ViewController.ViewController.showState(AudioZoneV2ControlEnums.ScreenState.CUSTOM_STREAM_SCREEN, null, {
                            control: this.control
                        }, AnimationType.MODAL);
                    },
                    iconFillColor: Color.GREEN,
                    noCircle: true,
                    elementIsNotClickable: false
                }
            } else {
                return super.emptyViewConfig(...arguments);
            }
        }

        getMediaInfo(controlType) {
            if (MediaBrowserV2Service.isRadio(this.identifier)) {
                this.lastSelectedItem.uid = this.lastSelectedItem.uid || this.identifier + "/" + this.username;
                console.log(this.viewId || this.name, "radio getMediaInfo returned ", cloneObject(baseInfo));
                return {
                    service: this.lastSelectedItem
                };
            } else {
                var baseInfo = super.getMediaInfo(...arguments);
                baseInfo.service.cmd = MusicServerEnum.Service.SPOTIFY;
                return baseInfo;
            }
        }

        getMediaId(controlType) {
            if (MediaBrowserV2Service.isRadio(this.identifier)) {
                return this.lastSelectedItem.root || this.lastSelectedItem.id;
            } else {
                return super.getMediaId(...arguments);
            }
        }

        getRightVCButtonIcon() {
            if (!MediaBrowserV2Service.isRadio(this.identifier)) {
                if (this.lastSelectedItem.id === MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS) {
                    return Icon.ADD;
                } else if (Object.values(MusicServerEnum.Spotify.TYPES).indexOf(this.lastSelectedItem.id) === -1) {
                    return super.getRightVCButtonIcon(...arguments);
                }
            } else if (this.identifier === MusicServerEnum.Radios.CUSTOM) {
                return Icon.ADD;
            }
        }

        getRightVCButtonIconFn() {
            if (!MediaBrowserV2Service.isRadio(this.identifier)) {
                if (this.lastSelectedItem.id === MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS) {
                    return this.createPlaylist.bind(this);
                } else if (Object.values(MusicServerEnum.Spotify.TYPES).indexOf(this.lastSelectedItem.id) === -1) {
                    return super.getRightVCButtonIconFn(...arguments);
                }
            } else if (this.identifier === MusicServerEnum.Radios.CUSTOM) {
                return this._showCustomRadioEdit.bind(this);
            }

        }

        _getRightSideTitleBarButtons() {
            if (!MediaBrowserV2Service.isRadio(this.identifier)) {
                if (this.lastSelectedItem.id === MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS) {
                    return [this._getRightSideTitleBarButtonElm(this.RIGHT_SIDE_BUTTON.ADD_PLAYLIST, Icon.TitleBar.PLUS)];
                } else if (Object.values(MusicServerEnum.Spotify.TYPES).indexOf(this.lastSelectedItem.id) === -1) {
                    return super._getRightSideTitleBarButtons(...arguments);
                }
            } else if (this.identifier === MusicServerEnum.Radios.CUSTOM) {
                return [this._getRightSideTitleBarButtonElm(this.RIGHT_SIDE_BUTTON.ADD_RADIO, Icon.TitleBar.PLUS)];
            }
        }

        _showCustomRadioEdit(streamItem = null) {
            this.ViewController.showState(AudioZoneV2ControlEnums.ScreenState.CUSTOM_STREAM_SCREEN, null, {
                control: this.control,
                streamObject: streamItem
            }, AnimationType.MODAL);
        }

        getActionsForCtxAndItem(ctx, item) {
            if (ctx === "lms" && item.audiopath.startsWith("custom:")) {
                return [
                    this._getActionWithTmplForCtx({
                        content: {
                            title: _("edit"),
                            leftIconSrc: Icon.AudioZone.CONTEXT.STREAM_EDIT
                        },
                        action: this._showCustomRadioEdit.bind(this, item)
                    }, ctx),
                    this._getActionWithTmplForCtx({
                        content: {
                            title: _("delete"),
                            leftIconSrc: Icon.AudioZone.CONTEXT.STREAM_DELETE
                        },
                        action: this._deleteRadio.bind(this, item)
                    }, ctx)
                ];
            }
            return [];
        }

        createPlaylist() {
            var playlistCnt = this.contentTypeItems[MusicServerEnum.MediaContentType.SERVICE].all.length,
                playlistPlaceholder = _("media.playlist.save.default-name", {
                    number: playlistCnt + 1
                }),
                id = this.getMediaInfo(this.lastSelectedItem.contentType);

            return this.control.audioserverComp.showNamePopup(_("media.playlist.create"), _("create"), playlistPlaceholder).then(function (plName) {
                return this.control.audioserverComp.createContainer(this.lastSelectedItem.contentType, id, plName).then(function (newPlaylistItem) {
                    this.control.audioserverComp.stopEditing();
                    this.control.audioserverComp.showConfirmationFeedback(_("media.notifications.playlist.new")); // Don't navigate into the newly created playlist if we are in the Playlist picker

                    if (this.ViewController instanceof GUI.AddMediaViewControllerV2PlaylistPicker) {
                        return Q(true);
                    } else {
                        return this.handleOnItemCellClicked(newPlaylistItem, newPlaylistItem.contentType);
                    }
                }.bind(this));
            }.bind(this));
        }

        _shouldOfferNewPlaylist() {
            if (!MediaBrowserV2Service.isRadio(this.identifier) && this.lastSelectedItem.id === MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS) {
                return this.ViewController instanceof GUI.AddMediaViewControllerV2Base && !(this.ViewController instanceof GUI.AddMediaViewControllerV2PlaylistSpotify) && !(this.ViewController instanceof GUI.AddMediaViewControllerV2ZoneFavorite) && !this.control.audioserverComp.isFileTypePlayable(this.lastSelectedItem.type, this.lastSelectedItem.contentType);
            } else {
                return false;
            }
        }

        _deleteRadio(item) {
            return this.control.audioserverComp.removeCustomStream(item.id);
        }

    }

    Controls.AudioZoneV2Control.MediaBrowserV2Service = MediaBrowserV2Service;
    return Controls.AudioZoneV2Control.MediaBrowserV2Service;
});
