'use strict';
/*
 title
 icon
 color
 fillColor
 isPlaceholder
 */

window.GUI = function (GUI) {
    class IntercomActionCell extends GUI.TableViewV2.Cells.ComfortActionCell {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(content) {
                var template = '<div class="content__icon-container">' + '   ' + ImageBox.getResourceImageWithClasses(content.icon, "icon-container__icon") + '</div>';

                if (content.title) {
                    template += '<div class="content__title">' + content.title + '</div>';
                }

                return template;
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor() {
            super(...arguments);
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                // Hide the content icon from the base class, we use our own!
                this.contentPlaceholder.find('.content__icon').hide();
                this.contentPlaceholder.append(IntercomActionCell.Template.getTemplate(this.content));
                this.iconContainer = this.contentPlaceholder.find(".content__icon-container");
                this.iconElement = this.iconContainer.find(".icon-container__icon");
                this.content.color && this.iconContainer.css("background-color", this.content.color);
                this.content.fillColor && this.contentPlaceholder.find(".icon-container__icon").css("fill", this.content.fillColor);
                this.content.isPlaceholder && this.element.addClass("intercom-action-cell--placeholder");
            }.bind(this));
        }

        clickableAllowed() {
            return true;
        }

        rippleAllowed() {
            return true;
        }

        _dispatchButtonHit() {
            super._dispatchButtonHit(...arguments);

            this._setIconActive();
        }

        _performOnHoldRipple(e) {// We don't want a ripple when holding this cell
        }

        _dispatchButtonReleased() {
            super._dispatchButtonReleased(...arguments);

            this._setIconInactive();
        }

        _setIconActive() {
            this.iconContainer.css("backgroundColor", window.Styles.colors.stateActive);
            this.iconElement.css("fill", Color.WHITE);
        }

        _setIconInactive() {
            this.iconContainer.css("backgroundColor", this.content.color || Color.STATE_INACTIVE);
            this.iconElement.css("fill", this.content.fillColor || Color.ICON_A);
        }

        // Fix for issue --> https://projects.zoho.eu/portal/loxone#buginfo/16571000000023018/16571000006271129

        /*_getCustomRippleElement: function _getCustomRippleElement() {
                        return this.iconContainer;
                    },*/
        _getHammerOptions() {
            return {
                tapAlways: true // otherwise, no "fast taps" would be created, because of doubletap

            };
        }

    }

    GUI.TableViewV2.Cells.IntercomActionCell = IntercomActionCell;
    GUI.TableViewV2.CellType.Special.INTERCOM_ACTION_CELL = "IntercomActionCell";
    return GUI;
}(window.GUI || {});
