import globalStyles from "GlobalStyles"

import React, { useMemo } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';

import {useBorderColor, LxReactText} from "LxComponents";

import Svg, { Path, Circle } from 'react-native-svg';

const circleRadius = 62;

/* #region  Arc Util */
const polarToCartesian = (cX, cY, radius, angle) => {
    var angleInRadians = (angle - 90) * Math.PI / 180.0;
    return {
        x: cX + (radius * Math.cos(angleInRadians)),
        y: cY + (radius * Math.sin(angleInRadians))
    };
}

function describeArc({ x = circleRadius, y = circleRadius, radius, startAngle, endAngle }) {
    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    var d = [
        "M", x, y,
        "L", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y,
        "L", x, y,
    ].join(" ");

    return d;
}
/* #endregion */

const styles = {
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    label: {
        container: {
            paddingBottom: 8,
            paddingTop: 8,
        },
        title: {
            ...globalStyles.textStyles.title3.bold,
        },
        titleSmallPart: {
            ...globalStyles.textStyles.footNote.bold,
        },
        subtitle: {
            fontFamily: globalStyles.fontSettings.families.regular,
            fontSize: globalStyles.fontSettings.sizes.medium,
            lineHeight: globalStyles.fontSettings.sizes.medium * 1.236,
            color: 'white',
        },
    }
}

function computePercentages(values = [{value: 65}, {value: 35}]) {
    if (!Array.isArray(values)) { return { percentages: [], allAreZero: true } }
    const total = values.reduce((total, obj) => total + obj.value, 0)
    let totalPercent = 0;
    const percentages = values.map(({ value, color }, idx) => {
        let val = total <= 0 ? 0 : (value / total);
        val = Math.round(val * 100) / 100;
        if(idx === values.length - 1) {
            val = 1 - totalPercent;
        }
        totalPercent += val;
        return {
            value: val,
            color
        }
    });
    return {
        percentages,
        allAreZero: values.every(({value}) => value === 0),
    };
}

export function PercentageCircle({values = [{value: 65}, {value: 35}], hideComparison = false}) {
    const defaultColor = useBorderColor();

    const {percentages, allAreZero} = useMemo(() => {
        return computePercentages(values);
    }, [values]);

    const clipPathId = useMemo(() => {
        return "circleClippy-" + getRandomIntInclusive(0, 99999);
    }, []);
    const pathToClip = useMemo(() => {

        let circleRadiusToUse = 0.5;
        const circleCX = 0.5;
        const circleCY = circleRadiusToUse;
        const clipCircleRadius = circleRadiusToUse * 0.7;
        const clipCircleSize = clipCircleRadius * 2;
        const circleSize = circleRadiusToUse * 2;
        let clipPathParts = [];

        // rect covering the whole icon
        clipPathParts.push(`M0 0 h${circleSize} v${circleSize} h-${circleSize} v-${circleSize}`);

        // circle that will actually do the clipping.
        clipPathParts.push(`M ${circleCX - clipCircleRadius}, ${circleCY}`);
        clipPathParts.push(`a${clipCircleRadius},${clipCircleRadius} 0 1,0 ${clipCircleSize},0`);
        clipPathParts.push(`a${clipCircleRadius},${clipCircleRadius} 0 1,0 -${clipCircleSize},0`);

        return <path d={clipPathParts.join(" ")}/>;
    }, [circleRadius]);
    const clipPath = useMemo(() => {
        return <clipPath clipPathUnits={"objectBoundingBox"} id={clipPathId}>{pathToClip}</clipPath>;
    }, [pathToClip, clipPathId]);

    const svgElements = useMemo(() => {
        const arr = [];

        if (hideComparison) {
            arr.push(<Circle
                key={'zero-circle'}
                cx={circleRadius} cy={circleRadius}
                r={circleRadius}
                fill="transparent"
            />);
            return arr;
        }

        if(allAreZero) {
            arr.push(
                <Circle
                    key={'zero-circle'}
                    cx={circleRadius} cy={circleRadius}
                    r={circleRadius}
                    fill={defaultColor}
                />
            )
            return arr;
        }

        if (percentages.length === 0) { return arr; }
        if (percentages.length === 1) {
            return [(
                    <Circle
                        key="circle-0"
                        cx={circleRadius} cy={circleRadius}
                        r={circleRadius}
                        fill={values[0].color || defaultColor}
                    />
                )
            ]
        }
        if(percentages.filter(p => p.value > 0).length === 1) {
            return [(
                    <Circle
                        key="circle-0"
                        cx={circleRadius} cy={circleRadius}
                        r={circleRadius}
                        fill={percentages.find(p => p.value > 0).color || defaultColor}
                    />
                )
            ]
        }

        const totalGaps = percentages.length;
        const gapSize = 1.2;
        let startAngle = 0;
        percentages.forEach((percentage, idx) => {
            const endAngle = startAngle + (360 - (totalGaps * gapSize)) * percentage.value;
            arr.push(
                <Path
                    key={`perc-${idx}-${percentage.value}`}
                    fill={percentage.color || defaultColor}
                    d={describeArc({ radius: circleRadius, startAngle, endAngle: endAngle })}
                />
            );
            startAngle = endAngle + gapSize;
        })


        return arr;
    }, [circleRadius, values, defaultColor, hideComparison]);


    return <Svg
        style={{ width: circleRadius * 2, height: circleRadius * 2 }}
        width={circleRadius * 2} height={circleRadius * 2}
        viewBox={`0 0 ${circleRadius * 2} ${circleRadius * 2}`}
        clipPath={"url(#" + clipPathId + ")"}
    >
        {clipPath}
        {svgElements}
    </Svg>
}

function CircleGraph({ values = [
    {
        value: 33,
        label: ' ',
        format: '',
    },
    {
        value: 33,
        label: ' ',
        format: '',
    },
    {
        value: 33,
        label: ' ',
        format: '',
    },
], hideComparison = false }) {
    const defaultColor = useBorderColor();

    const {percentages, allAreZero} = useMemo(() => {
        return computePercentages(values);
    }, [values]);

    /* #region  Labels */
    const labels = useMemo(() => {
        let totalPercent = 0;
        return (values || []).map((obj, idx) => {
            let value = obj.value;
            // Check for number validity, since lxUnitConverter.convertSplitAndApply does not handle all edge cases
            // e.g. lxUnitConverter.convertSplitAndApply('%.2f', true) results in a valueTxt of ""
            if(typeof value !== 'number' || isNaN(value) || !isFinite(value)) {
                value = 0;
            }
            let formattedTexts = lxUnitConverter.convertSplitAndApply(obj.format, value);
            let percentage = null;
            let percentageUnitTxt = (<LxReactText style={styles.label.titleSmallPart}>%</LxReactText>);

            if(!isNaN(percentages[idx].value) && isFinite(percentages[idx].value)){
                percentage = lxFormat("%.0f", percentages[idx].value * 100);
                if(idx === values.length - 1 && !allAreZero) {
                    percentage = lxFormat("%.0f", 100 - totalPercent);
                }
            } else {
                percentage = '-';
            }

            totalPercent += Math.round(percentages[idx].value * 100);

            if (hideComparison) {
                percentage = null;
                percentageUnitTxt = null;
            }
            return (
                <View style={styles.label.container} key={`label-${idx}-${percentages[idx].value}`}>
                    <LxReactText
                        style={[styles.label.title, { color: obj.color || defaultColor }]}
                    >
                        {formattedTexts.valueTxt}<LxReactText style={styles.label.titleSmallPart}>{formattedTexts.succTxt}</LxReactText> {percentage}{percentageUnitTxt}
                    </LxReactText>
                    <LxReactText style={styles.label.subtitle}>{obj.label}</LxReactText>
                </View>
            )
        });
    }, [values, hideComparison, defaultColor]);
    /* #endregion */

    if(labels.length === 0) {
        return <LoadingCircleGraph />;
    }

    return (
        <View style={styles.container}>
            <View>
                {labels}
            </View>
            <PercentageCircle values={values} hideComparison={hideComparison} />
        </View>
    );
}
CircleGraph.propTypes = {
    values: PropTypes.arrayOf(PropTypes.exact({
        color: PropTypes.string,
        value: PropTypes.number,
        label: PropTypes.string,
        format: PropTypes.string,
    })).isRequired,
    hideComparison: PropTypes.bool
}

export function LoadingCircleGraph() {
    const defaultColor = useBorderColor();
    return (
        <CircleGraph
            values={[
                {
                    color: defaultColor,
                    value: 33,
                    label: ' ',
                    format: '',
                },
                {
                    color: defaultColor,
                    value: 33,
                    label: ' ',
                    format: '',
                },
                {
                    color: defaultColor,
                    value: 33,
                    label: ' ',
                    format: '',
                },
            ]}
        />
    )
}

export default CircleGraph
