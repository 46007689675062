'use strict';

import LxReactComp from "../../../react-comps/LxReactComp";
import LxReactVerticalSlider from "../../../react-comps/LxReactSlider/LxReactVerticalSlider";
import PropTypes from "prop-types";
import {LxSliderDefaultSettings} from "LxComponents";
import {isValidSliderValue} from "../../../react-comps/LxReactSlider/LxReactSliderUtils";

/**
 * @prop {object} [config] Slider configuration that contains value,step,min,max
 */
class LxVerticalSlider extends LxReactComp {

    constructor(props) {
        super(props);
        this.state = {
            ...props.config
        };
        this.state = this.checkConfigForSafety(this.state)

        this.options = {
            isDragging: false,
            isOff: false,
            valueChangeTimeout: null,
            antiGhosTimer: props.config.antiGhostTimer,
            antiGhostDuration: props.antiGhostDuration
        };

        if (props.config.antiGhostTimer) {
            this.options.cachedValue = props.value;
            this.options.antiGhostTimer = new AntiGhostTimer(() => {
                this.updateValueSafely(this.options.cachedValue);
            }, this.options.antiGhostDuration);
        }
    }

    //region Public Methods
    checkConfigForSafety(rawConfig) {
        if (!isValidSliderValue(rawConfig.max)) {
            rawConfig.max = this.state.max || LxSliderDefaultSettings.defaultSettings.MAX;
        }
        if (!isValidSliderValue(rawConfig.min)) {
            rawConfig.min = this.state.min || LxSliderDefaultSettings.defaultSettings.MIN;
        }
        if (!isValidSliderValue(rawConfig.step)) {
            rawConfig.step = this.state.step || LxSliderDefaultSettings.defaultSettings.STEP;
        }
        if (!isValidSliderValue(rawConfig.value)) {
            rawConfig.value = this.state.value || LxSliderDefaultSettings.defaultSettings.VALUE;
        }
        return rawConfig
    }

    updateProperties(newConfig) {
        this.setState(this.checkConfigForSafety(newConfig));

        if (this.state.min >= this.state.max) {
            console.warn("LxSlider min is greater or equal to max! -> don't work!");
        }
    }

    setPosition(pos, forceUpdate) {
        var noGhostActive = !this.options.antiGhostTimer ||
            (this.options.antiGhostTimer && !this.options.antiGhostTimer.isAntiGhostTimerActive());

        if (forceUpdate || (noGhostActive && !this.options.isDragging && !this.options.valueChangeTimeout)) {
            this.updateValueSafely(pos);
        }
        this.options.cachedValue = pos;
    }

    updateValueSafely(newValue) {
        let newSafeValue = Math.min(this.state.max, Math.max(newValue, this.state.min))
        this.setState({value: newSafeValue});
    }

    updateGUIPosition(calc, fromGesture) {
        developerAttention("LxVerticalSlider: updateGUIPosition() is deprecated, not needed anymore\"")
    }

    resetAntiGhost() {
        developerAttention("LxVerticalSlider: resetAntiGhost() is deprecated, not needed anymore\"")
    }

    setColor(color) {
        this.setState({
            activeColor: color
        })
    }

    setEnabled(enabled) {
        this.disabled = !enabled;
    }

    /**
     * Whether or not the slider is being used (drag active)
     * @returns {boolean} is it in use or not.
     */
    isDragging() {
        return this.options.isDragging;
    }

    //endregion Public methods

    //region Private methods
    _onValueChanged(newValue) {
        if (!this.isDragging()) {
            this._onDraggingChanged(true);
        }
        this.options.cachedValue = newValue;
        this.options.antiGhostTimer && this.options.antiGhostTimer.fire();
        this.updateValueSafely(newValue)
        if (this.props.hasCustomRate) {
            if (!this.options.valueChangeTimeout) {
                this.options.valueChangeTimeout = setTimeout(() => {
                    this._triggerOnPositionChanged(this.state.value, true)
                }, this.state.updateRate * 1000) ;
            }
        } else {
            this._triggerOnPositionChanged(newValue, false);
        }
    }

    _triggerOnPositionChanged(value, afterTimeout) {
        if (this.onPositionChanged) {
            this.onPositionChanged(value, value === this.state.min, this.options.isDragging);
        }
        if (afterTimeout) {
            this.options.valueChangeTimeout = null;
        }
    }

    _onDraggingChanged(hasStarted) {
        this.options.isDragging = hasStarted;
        if (this.onDraggingChanged) {
            this.onDraggingChanged(this.state.value);
        }
    }

    //endregion Private methods

    render() {
        return (
            <LxReactVerticalSlider
                min={this.state.min}
                max={this.state.max}
                value={this.state.value}
                onValueChanged={(newVal) => this._onValueChanged(newVal)}
                onValueChangeComplete={(newVal) => this._onDraggingChanged(false)}
                activeColor={this.state.activeColor}
                disabled={this.disabled}
                containerStyle={this.props.containerStyle}
            />
        );
    }

}

export default LxVerticalSlider;

LxVerticalSlider.propTypes = {
    config: PropTypes.object,
    containerStyle: PropTypes.object
}
