'use strict';

define("PoolDaytimerControl", ["DaytimerControlBase"], function (DaytimerControlBase) {
    return class PoolDaytimerControl extends DaytimerControlBase {
        constructor() {
            super(...arguments);
        }

        /**
         * Returns the string for the value of the entry that will be appended to the set entries command.
         * @param entry     the entry whos value is to be returned as a string for the setEntries-Cmd.
         * @return {null}   null only e.g. for a digital regular daytimer, as there is no value for it.
         */
        getEntryValueString(entry) {
            return entry.cycleMode;
        }

    };
});
