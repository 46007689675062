'use strict';

define("IRCDaytimerControlStateContainer", ["DaytimerControlStateContainerBase", "IRoomControlStateContainer", "IRoomControlEnums"], function (DaytimerControlStateContainerBase, IRoomControlStateContainer, IRoomControlEnums) {
    return class IRCDaytimerControlStateContainer extends DaytimerControlStateContainerBase {
        constructor(control) {
            super(control);
            this.iRoomControl = SandboxComponent.getStructureManager().getControlByUUID(this.control.uuidParent);

            if (this.control.details.isHeating) {
                this.tempIxUUID = this.iRoomControl.states.currHeatTempIx;
            } else if (this.control.details.isCooling) {
                this.tempIxUUID = this.iRoomControl.states.currCoolTempIx;
            }
        }

        getUUIDsToRegister() {
            // get all uuid's from IRoomControl
            var uuids = super.getUUIDsToRegister();
            uuids = uuids.concat(this.iRoomControl.states.temperatures);
            uuids.push(this.tempIxUUID);
            uuids.push(this.iRoomControl.states.serviceMode);
            return uuids;
        }

        prepareStates(newVals) {
            super.prepareStates(...arguments);
            this.states.activeMode = newVals[this.control.states.mode]; //this.states.value = newVals[this.control.states.value];
            //this.states.defaultValue = newVals[this.control.states.entriesAndDefaultValue].defValue;
            // temperatures

            var temperatureResult = IRoomControlStateContainer.prepareTemperaturesFromStates(newVals, this.iRoomControl, this.control.details.isHeating, this.control.details.isCooling);
            this.states.activeTemperatures = temperatureResult.activeTemperatures;

            this._prepareEntries(this.states.entries, this.states.activeTemperatures);

            this.states.activeTempMode = newVals[this.tempIxUUID];
            this.states.serviceModeActive = newVals[this.iRoomControl.states.serviceMode] !== IRoomControlEnums.ServiceMode.OFF;
        }

        _prepareEntries(entries, temperatures) {
            var entry;

            for (var modeNr in entries) {
                if (entries.hasOwnProperty(modeNr)) {
                    for (var i = 0; i < entries[modeNr].length; i++) {
                        entry = entries[modeNr][i];
                        entry.tempMode = entry.value;
                        entry.targetTemp = temperatures[entry.value];
                        delete entry.value;
                        delete entry.needActivate;
                    }
                }
            }
        }

    };
});
