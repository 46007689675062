'use strict';

define([], function () {
    return {
        CAPABILITIES: {
            NONE: 0,
            HEATING: 1,
            COOLING: 2,
            HEATING_AND_COOLING: 3
        },
        DEMAND: {
            COOLING: -1,
            NONE: 0,
            HEATING: 1
        },
        MODE: {
            NO_DEMAND: 0,
            HEATING: 1,
            COOLING: 2,
            HEATING_BOOST: 3,
            COOLING_BOOST: 4,
            SERVICE_MODE: 5,
            EXTERNAL_HEATER: 6
        },
        AUTO_MODE: {
            OFF: -1,
            HEATING_AND_COOLING: 0,
            HEATING_ONLY: 1,
            COOLING_ONLY: 2
        },
        BOUNDARY_INFO: {
            NOT_ENOUGH_DATA: 0,
            OK: 1,
            NO_DATA: 2
        },
        OVERWRITE_REASON: {
            AUTOMATIC: 0,
            BOOST: 1,
            EXTERNAL_HEATER: 2,
            STOP: 3,
            CUSTOM_INFO: 4 // the infoText provided by the MS specifies what text to show.
        },
        SERVICE_MOOD_SETTING: {
            OFF: 0,
            STANDBY: 1,
            HEATING_ON: 2,
            COOLING_ON: 3,
            VENT_ON: 4
        },
        SCREEN_STATE: {
            SETTINGS: "ClimateControlContentSettings",
            TEMPS: "ClimateControlTemperaturesScreen"
        },
        TEMP_BOUNDRIES: {
            get MIN() {
                return pickCelsiusOrFahrenheit(-10, 14);
            },

            get MAX() {
                return pickCelsiusOrFahrenheit(40, 104);
            }

        },
        ExcessEnergyBit: {
            NONE: 0,
            HEAT: 1,
            COOL: 2
        },
        ParameterFlags: {
            NONE: 0,
            MODE: 1,
            HEATING_BOUNDARY: 8192, // bit#13
            COOLING_BOUNDARY: 16384, // bit#14
        }
    };
});
