'use strict';

define(['IRoomControllerV2ControlEnums'], function (IRoomControllerV2ControlEnums) {
    return class IRoomControllerV2TemperaturesScreen extends GUI.TableViewScreen {
        //region Static
        static TEMP_STEP = 0.5;
        static Template = function () {
            var getStrongSectionHeader = function getStrongSectionHeader(title, description) {
                return '<div class="temp-section-header">' + '       <div class="temp-section-header__title temp-section-header__title--strong">' + title + '</div>' + '       <div class="temp-section-header__desc">' + description + '</div>' + '</div>';
            };

            var getSectionHeader = function getSectionHeader(title, description) {
                return '<div class="temp-section-header">' + '       <div class="temp-section-header__title">' + title + '</div>' + '       <div class="temp-section-header__desc">' + description + '</div>' + '</div>';
            };

            var getRelativeAndAbsoluteSliderValue = function getRelativeAndAbsoluteSliderValue(relativeValue, absoluteValue, relativeColor) {
                return "<div style='display: flex;'>" + "       <div style='flex: 1;'>(" + absoluteValue.toFixed(1) + "°)&nbsp;</div>" + "       <div style='color: " + relativeColor + ";'>" + "           " + Math.abs(relativeValue).toFixed(1) + "°" + "       </div>" + "   </div>";
            };

            return {
                getStrongSectionHeader: getStrongSectionHeader,
                getSectionHeader: getSectionHeader,
                getRelativeAndAbsoluteSliderValue: getRelativeAndAbsoluteSliderValue
            };
        }(); //endregion Static

        constructor(details) {
            super($("<div/>"));
            this.control = details.control;
            this.states = this.control.getStates();
            this.originTemps = {};
            this.originTemps[Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.COMFORT] = this.states.comfortTemperature;
            this.originTemps[Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.COMFORT_TOLERANCE] = this.states.comfortTolerance;
            this.originTemps[Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.ECO_MIN] = this.states.absentMinOffset;
            this.originTemps[Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.ECO_MAX] = this.states.absentMaxOffset;
            this.newTemps = cloneObject(this.originTemps);
        }

        viewWillDisappear() {
            // Only send changed temperatures!
            // Iterate over all temperatures, compare them with the initial values and send them if there is a difference
            Object.keys(this.newTemps).forEach(function (cmd) {
                if (this.originTemps[cmd] !== this.newTemps[cmd]) {
                    this.control.sendCommand(Commands.format(cmd, this.newTemps[cmd]), null, null, true); // Don't record this command
                }
            }.bind(this));
            super.viewWillDisappear(...arguments);
        }

        // Private methods
        titleBarText() {
            return _('temperatures');
        }

        _getTableContent() {
            return [{
                headerStrongTitle: _("controls.ircv2.temperatures.comfort.title"),
                headerDescription: _("controls.ircv2.temperatures.comfort.desc"),
                rows: [this._getComfortTempCell()]
            }, {
                headerTitle: _("controls.ircv2.temperatures.comfort.offset.title"),
                headerDescription: _("controls.ircv2.temperatures.comfort.offset.desc"),
                rows: [this._getComfortAllowedOffsetCell()]
            }, {
                headerStrongTitle: _("controls.ircv2.temperatures.absent.title"),
                headerDescription: _("controls.ircv2.temperatures.absent.desc"),
                rows: [this._getLowerAbsentTempCell(), this._getUpperAbsentTempCell()]
            }, {
                headerStrongTitle: _("off"),
                headerDescription: _("controls.ircv2.temperatures.off.desc"),
                rows: []
            }, {
                headerTitle: _("controls.ircv2.temperatures.building-protection.title"),
                headerDescription: _("controls.ircv2.temperatures.building-protection.desc"),
                rows: [{
                    content: {
                        title: _('controls.ircv2.temperatures.antifreeze'),
                        disclosureText: lxFormat("%.1f", this.states.frostProtectTemperature, true) + "°",
                        disclosureColor: window.Styles.colors.blue
                    }
                }, {
                    content: {
                        title: _('controls.ircv2.temperatures.heatprotection'),
                        disclosureText: lxFormat("%.1f", this.states.heatProtectTemperature, true) + "°",
                        disclosureColor: window.Styles.colors.red
                    }
                }]
            }];
        }

        reloadTable() {
            var prms = [true];
            this.tableContent = this._getTableContent();
            this.tableViewDataSource.update(this.tableContent);
            this.tableViewDelegate.update(this.tableContent); // Reload the whole tableView initially

            if (!this.tableView.table.length) {
                prms.push(this.tableView.reload());
            } else {
                // Reload all rows in every section to keep the current scroll position
                this.tableContent.forEach(function (section, sectionIdx) {
                    this.tableView.reloadRowsSelective(sectionIdx, 0, section.rows.length);
                }.bind(this));
            }

            return Q.all(prms);
        }

        _getComfortTempCell() {
            var cellType = GUI.TableViewV2.CellType.SLIDER,
                comfortTemp = this._getComfortTemperature();

            if (Feature.IRCv2_IS_INPUT_CONNECTED && hasBit(this.control.details.connectedInputs, IRoomControllerV2ControlEnums.ConnectedInputs.COMFORT_TEMP)) {
                cellType = GUI.TableViewV2.CellType.GENERAL;
            }

            return {
                content: {
                    title: _('controls.irc.temp.comfort.short'),
                    subtitle: _('controls.leaf.settings.locked-desc'),
                    minValue: this.states.frostProtectTemperature + IRoomControllerV2TemperaturesScreen.TEMP_STEP,
                    maxValue: this.states.heatProtectTemperature - IRoomControllerV2TemperaturesScreen.TEMP_STEP,
                    step: IRoomControllerV2TemperaturesScreen.TEMP_STEP,
                    value: comfortTemp,
                    valueColor: window.Styles.colors.stateActive,
                    disclosureText: lxFormat(this.control.details.format, comfortTemp),
                    disclosureColor: window.Styles.colors.stateActive,
                    format: this.control.details.format,
                    minIconSrc: Icon.MINUS,
                    maxIconSrc: Icon.PLUS,
                    cmd: Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.COMFORT
                },
                type: cellType,
                sliderDragEnded: this._valueChanged.bind(this),
                sliderClicked: this._valueChanged.bind(this)
            };
        }

        _getComfortAllowedOffsetCell() {
            var cellType = GUI.TableViewV2.CellType.SLIDER,
                format = "± " + this.control.details.format,
                comfortTolerance = this._getComfortTolerance();

            if (Feature.IRCv2_IS_INPUT_CONNECTED && hasBit(this.control.details.connectedInputs, IRoomControllerV2ControlEnums.ConnectedInputs.ALLOWED_COMFORT_TOLERANCE)) {
                cellType = GUI.TableViewV2.CellType.GENERAL;
            }

            return {
                content: {
                    title: _("controls.ircv2.comfort.allowed-offset"),
                    subtitle: _('controls.leaf.settings.locked-desc'),
                    minValue: IRoomControllerV2ControlEnums.TempBoundaries.COMFORT_TOLERANCE.MIN,
                    maxValue: IRoomControllerV2ControlEnums.TempBoundaries.COMFORT_TOLERANCE.MAX,
                    step: IRoomControllerV2TemperaturesScreen.TEMP_STEP,
                    value: comfortTolerance,
                    valueColor: window.Styles.colors.stateActive,
                    disclosureText: lxFormat(format, comfortTolerance),
                    disclosureColor: window.Styles.colors.stateActive,
                    format: format,
                    minIconSrc: Icon.MINUS,
                    maxIconSrc: Icon.PLUS,
                    cmd: Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.COMFORT_TOLERANCE
                },
                type: cellType,
                sliderDragEnded: this._valueChanged.bind(this),
                sliderClicked: this._valueChanged.bind(this)
            };
        }

        _getLowerAbsentTempCell() {
            var cellType = GUI.TableViewV2.CellType.SLIDER,
                absenceMinOffset = this._getAbsenceMinOffset();

            if (Feature.IRCv2_IS_INPUT_CONNECTED && hasBit(this.control.details.connectedInputs, IRoomControllerV2ControlEnums.ConnectedInputs.LOWER_ABSENT_TEMP)) {
                cellType = GUI.TableViewV2.CellType.GENERAL;
            }

            return {
                content: {
                    title: _('controls.ircv2.temperatures.lower-limit'),
                    subtitle: _('controls.leaf.settings.locked-desc'),
                    minValue: IRoomControllerV2TemperaturesScreen.TEMP_STEP,
                    maxValue: this._getComfortTemperature() - this.states.frostProtectTemperature,
                    step: IRoomControllerV2TemperaturesScreen.TEMP_STEP,
                    value: absenceMinOffset,
                    formatWithHTML: "%s",
                    disclosureText: IRoomControllerV2TemperaturesScreen.Template.getRelativeAndAbsoluteSliderValue(this._getComfortTemperature() - absenceMinOffset, absenceMinOffset, window.Styles.colors.blue),
                    minIconSrc: Icon.MINUS,
                    maxIconSrc: Icon.PLUS,
                    cmd: Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.ECO_MIN
                },
                type: cellType,
                sliderDragEnded: this._valueChanged.bind(this),
                sliderClicked: this._valueChanged.bind(this),
                userfriendlyValueForSlider: function (cell, section, row, tableView, value) {
                    value *= -1;
                    var absoluteValue = this._getComfortTemperature() + value;
                    return IRoomControllerV2TemperaturesScreen.Template.getRelativeAndAbsoluteSliderValue(value, absoluteValue, window.Styles.colors.blue);
                }.bind(this)
            };
        }

        _getUpperAbsentTempCell() {
            var cellType = GUI.TableViewV2.CellType.SLIDER,
                absenceMaxOffset = this._getAbsenceMaxOffset();

            if (Feature.IRCv2_IS_INPUT_CONNECTED && hasBit(this.control.details.connectedInputs, IRoomControllerV2ControlEnums.ConnectedInputs.UPPER_ABSENT_TEMP)) {
                cellType = GUI.TableViewV2.CellType.GENERAL;
            }

            return {
                content: {
                    title: _('controls.ircv2.temperatures.upper-limit'),
                    subtitle: _('controls.leaf.settings.locked-desc'),
                    minValue: IRoomControllerV2TemperaturesScreen.TEMP_STEP,
                    maxValue: this.states.heatProtectTemperature - this._getComfortTemperature(),
                    step: IRoomControllerV2TemperaturesScreen.TEMP_STEP,
                    value: absenceMaxOffset,
                    formatWithHTML: "%s",
                    disclosureText: IRoomControllerV2TemperaturesScreen.Template.getRelativeAndAbsoluteSliderValue(this._getComfortTemperature() + absenceMaxOffset, absenceMaxOffset, window.Styles.colors.red),
                    minIconSrc: Icon.MINUS,
                    maxIconSrc: Icon.PLUS,
                    cmd: Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.ECO_MAX
                },
                type: cellType,
                sliderDragEnded: this._valueChanged.bind(this),
                sliderClicked: this._valueChanged.bind(this),
                userfriendlyValueForSlider: function (cell, section, row, tableView, value) {
                    var absoluteValue = this._getComfortTemperature() + value;
                    return IRoomControllerV2TemperaturesScreen.Template.getRelativeAndAbsoluteSliderValue(value, absoluteValue, window.Styles.colors.red);
                }.bind(this)
            };
        }

        _showState(state, details) {
            this.ViewController.showState(state, null, details);
        }

        _valueChanged(cell, section, row, tableView, value) {
            this.newTemps[cell.content.cmd] = value;

            if (cell.content.cmd === Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.COMFORT) {
                this.reloadTable(); // Also validate the slider values that may have changed due to the comfort temperature adoption

                this._validateSliderCellValues();
            }
        }

        _adoptTempLimit(temp) {
            var adopted = SandboxComponent.getTemperatureForUI(temp);
            adopted = Math.round(adopted);
            return adopted;
        }

        /**
         * Either returns the original comfortTemperature if the comfortTemperature hasn't been changed before, or the new comfortTemperature
         * @return {*}
         * @private
         */
        _getComfortTemperature() {
            var newTemp = this.newTemps[Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.COMFORT];

            if (newTemp !== undefined) {
                return newTemp;
            } else {
                return this.states.comfortTemperature;
            }
        }

        /**
         * Either returns the original comfortTolerance if the comfortTolerance hasn't been changed before, or the new comfortTolerance
         * @return {*}
         * @private
         */
        _getComfortTolerance() {
            var newTolerance = this.newTemps[Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.COMFORT_TOLERANCE];

            if (newTolerance !== undefined) {
                return newTolerance;
            } else {
                return this.states.comfortTolerance;
            }
        }

        /**
         * Either returns the original absentMinOffset if the absentMinOffset hasn't been changed before, or the new absentMinOffset
         * @return {*}
         * @private
         */
        _getAbsenceMinOffset() {
            var newAbsenceMin = this.newTemps[Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.ECO_MIN];

            if (newAbsenceMin !== undefined) {
                return newAbsenceMin;
            } else {
                return this.states.absentMinOffset;
            }
        }

        /**
         * Either returns the original absentMaxOffset if the absentMaxOffset hasn't been changed before, or the new absentMaxOffset
         * @return {*}
         * @private
         */
        _getAbsenceMaxOffset() {
            var newAbsenceMax = this.newTemps[Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.ECO_MAX];

            if (newAbsenceMax !== undefined) {
                return newAbsenceMax;
            } else {
                return this.states.absentMaxOffset;
            }
        }

        /**
         * Validates if the value is between the min- and maxValue of the slider
         * A change of the comfort temperature may require the dependent temperatures,
         * like the max absence temperature, to be adopted
         * @private
         */
        _validateSliderCellValues() {
            this.tableContent.forEach(function (section) {
                section.rows.forEach(function (row) {
                    // Only adopt slider cells with content and a cmd. These hold temperatures that are changed by the user and may require adoption.
                    if (row.type === GUI.TableViewV2.CellType.SLIDER && row.content && row.content.cmd) {
                        // This ensures, that the value is within the sliders boundaries
                        this.newTemps[row.content.cmd] = Math.min(Math.max(row.content.minValue, row.content.value), row.content.maxValue);
                    }
                }.bind(this));
            }.bind(this));
        }

    };
});
