import * as React from "react"
import Svg, { Path } from "react-native-svg"

function DeleteCircled(props) {
    return (
        <Svg isreact="true"
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 16A8 8 0 108 0a8 8 0 000 16zm2.946-5.054a.5.5 0 01-.707 0L8 8.707l-2.24 2.24a.5.5 0 01-.706-.708L7.293 8l-2.24-2.24a.5.5 0 01.708-.706L8 7.293l2.24-2.24a.5.5 0 11.706.708L8.707 8l2.24 2.24a.5.5 0 010 .706z"
            />
        </Svg>
    )
}

export default DeleteCircled
