'use strict';

define("MeterControl", ["Control"], function (Control) {
    return class MeterControl extends Control {
        constructor() {
            super(...arguments);
        }

        getFriendlyValueName(event, operator) {
            switch (event.data.stateName) {
                case "actual":
                    return this.name + " " + event.data.stateTitle + " " + operator + " " + lxFormat(this.details.actualFormat, event.value);

                case "total":
                    return this.name + " " + event.data.stateTitle + " " + operator + " " + lxFormat(this.details.totalFormat, event.value);

                default:
                    return "";
            }
        }

        isBidirectional() {
            let result;
            switch (this.details.type) {
                case "storage":
                case "bidirectional":
                    result = true;
                    break;
                default:
                    result = false;
                    break;
            }
            return result;
        }

        hasStorage() {
            let result;
            switch (this.details.type) {
                case "storage":
                    result = true;
                    break;
                default:
                    result = false;
                    break;
            }
            return result;
        }

    };
});
