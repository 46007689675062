'use strict';

define("ActivityImageCache", [], function () {
    Controls.AtheneControl = Controls.AtheneControl || {};
    Controls.AtheneControl.SingleTons = Controls.AtheneControl.SingleTons || {};

    if (Controls.AtheneControl.SingleTons.ActivityImageCache) {
        return Controls.AtheneControl.SingleTons.ActivityImageCache;
    } else {
        class ActivityImageCache {
            //region Static
            static get MAX_PERSISTENT_CACHE_CNT() {
                if (PlatformComponent.isWebInterface() || PlatformComponent.isDeveloperInterface()) {
                    return 1;
                } else {
                    return 100;
                }
            }

            static shared(controlUuid) {
                if (!controlUuid) {
                    throw "No controlUuid provided!";
                }

                this.__instances = this.__instances || {};

                if (!this.__instances.hasOwnProperty(controlUuid)) {
                    this.__instances[controlUuid] = new this(this, controlUuid);
                }

                return this.__instances[controlUuid];
            }

            static destroy(controlUuid) {
                if (controlUuid && this.__instances && this.__instances.hasOwnProperty(controlUuid)) {
                    this.__instances[controlUuid].destroy().finally(function () {
                        delete this.__instances[controlUuid];
                    }.bind(this));
                }
            } //endregion Static


            constructor(initiator, controlUuid, type) {
                this.name = "ActivityImageCache";
                this.controlUuid = controlUuid; // Check if the Singletone has been called the right way

                if (!(initiator instanceof Function) && !Controls.AtheneControl.SingleTons.ActivityImageCache) {
                    throw "The ActivityImageCache is a Singletone, use it like that! -> ActivityImageCache.shared(this.control.controlUuid)";
                }

                this._persistDef = Q.defer();
                this._cache = {};
                this._unixMap = {};
            }

            prepare() {
                return PersistenceComponent.loadFile(this._getPersistentCacheFileName()).then(function (file) {
                    if (typeof file === "string") {
                        try {
                            file = JSON.parse(file);
                        } catch (e) {
                            console.warn(e);
                            file = {};
                        }
                    }

                    Object.keys(file).forEach(function (src) {
                        this._cache[src] = file[src].data;
                        this._unixMap[file[src].unix] = src;
                    }.bind(this));
                }.bind(this));
            }

            destroy() {
                return this._persist(true).finally(function () {
                    this.clear();
                }.bind(this));
            }

            set(src, dataURI, unix) {
                var uniqueSrc = this._uniquifySrc(src);

                this._cache[uniqueSrc] = dataURI;

                if (unix) {
                    this._unixMap[unix] = uniqueSrc;
                }

                this._persist();
            }

            get(src) {
                return this._cache[this._uniquifySrc(src)];
            }

            clear(src) {
                try {
                    if (src) {
                        delete this._cache[this._uniquifySrc(src)];
                    } else {
                        this._cache = {};
                    }
                } catch (e) {
                    // We don't endanger the state of the app just because of a cache issue!
                }

            }

            _uniquifySrc(src) {
                return src.split("/").splice(-2).join("/");
            }

            _persist(force) {
                var timeout = force ? 0 : 10,
                    toCache = {},
                    unixKeys,
                    cacheKeys;
                this._persistTimeout && clearTimeout(this._persistTimeout);
                this._persistTimeout = setTimeout(function () {
                    unixKeys = Object.keys(this._unixMap).map(Number).sort(function (l, r) {
                        return r - l;
                    });
                    cacheKeys = Object.keys(this._cache);

                    for (var i = 0; i < Math.min(this.constructor.MAX_PERSISTENT_CACHE_CNT, unixKeys.length); i++) {
                        var unix = unixKeys[i],
                            src = this._unixMap[unix],
                            data = this._cache[src];
                        toCache[src] = {
                            unix: unix,
                            data: data
                        };
                    }

                    this._persistDef.resolve(PersistenceComponent.saveFile(this._getPersistentCacheFileName(), toCache, DataType.OBJECT));

                    this._persistDef = Q.defer();
                }.bind(this), timeout * 1000);
                return this._persistDef.promise;
            }

            _getPersistentCacheFileName() {
                return "IntercomActivitiesCache_" + this.controlUuid + ".json";
            }

        }

        Controls.AtheneControl.SingleTons.ActivityImageCache = ActivityImageCache;
    }

    return Controls.AtheneControl.SingleTons.ActivityImageCache;
});
