'use strict';

define(["ControlActionScreen"], function (ControlActionScreen) {
    return class ControlActionCardsScreen extends ControlActionScreen {
        constructor() {
            super(...arguments);
        }

        viewDidLoad() {
            var promise = super.viewDidLoad(...arguments);

            if (this.control.isGrouped()) {
                this.element.addClass("control-action-cards-screen--grouped");
            }

            return promise;
        }

        getActionTableView() {
            return new GUI.CardView(this.tableViewDataSource, this.tableViewDelegate);
        }

        getActionTableViewCellType() {
            return GUI.TableViewV2.CellType.Special.COLLECTION;
        }

    };
});
