'use strict';

define("AcControl", ["Control", "./content/SettingsScreen", "./content/TimerScreen", "AcControlEnums"], function (Control, SettingsScreen, TimerScreen, { ConnectedInputsBitmask, ConnectedParametersBitmask }) {
    return class AcControl extends Control {
        VALUE_FORMAT = "%.1f°";
        constructor() {
            super(...arguments);
        }
        get parentClimateController() {
            return this.getParentClimateController();
        }

        get parentIRC() {
            return this.getParentIRC();
        }

        getSwitch(states) {
            if (!states.isOperatedExternally) {
                if (states.sleepPause || states.windowOpenPause || states.loadSheddingPause || states.pauseHvacCoolProhibited  || states.pauseHvacHeatProhibited || states.pauseDiffmode) {
                    return {
                        active: true,
                        activeTrackColor: window.Styles.colors.orange,
                        command0: Commands.SWITCH.OFF,
                        command1: Commands.SWITCH.ON
                    };
                }
    
                return {
                    active: !!states.status,
                    command0: Commands.SWITCH.OFF,
                    command1: Commands.SWITCH.ON
                };
            }
        }

        getReactScreens() {
            return [
                ...super.getReactScreens(...arguments),
                SettingsScreen.default,
                TimerScreen.default
            ]
        }

        isSetByLogic(inputName) {
            return this.details && (hasBit(this.details.connectedInputs, ConnectedInputsBitmask[inputName]) || hasBit(this.details.connectedParameters, ConnectedParametersBitmask[inputName]))
        }

        /**
         * If true, controls of this type MAY use the rooms name instead of its own. E.g. this is important for light
         * controllers, heating controllers and other room based controls.
         */
        controlTypeUsesRoomName() {
            return true;
        }

        getParentClimateController() {
            const parentUUID = this.details.uuidClimate;
            if(parentUUID) {
                return ActiveMSComponent.getControlByUUID(parentUUID);
            } else {
                return null;
            }
        }

        getParentIRC() {
            const parentUUID = this.details.uuidExternal;
            if(parentUUID) {
                return ActiveMSComponent.getControlByUUID(parentUUID);
            } else {
                return null;
            }
        }
    }
})
