'use strict';

define(["NfcTagInteractionScreen"], function (NfcTagInteractionScreen) {
    return class ShareMiniserverScreen extends NfcTagInteractionScreen {
        constructor() {
            super({
                type: Interaction.NFC.SHARE_MINISERVER
            });
            this._addUser = false;
            this._addPassword = false; // Clone the object to not change any property of the active miniserver

            this._miniserver = cloneObject(ActiveMSComponent.getActiveMiniserver());
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(() => {
                this.element.addClass("nfc-share-miniserver-screen");
                this.settingsTable = new GUI.TableViewV2(this, this);
                this.appendSubview(this.settingsTable);
                this.settingsTable.reload();
            });
        }

        viewDidAppear() {
            return super.viewDidAppear(...arguments).then(() => {
                // invalidate the change made by the nfcInteractionScreen, which (on HD devices) would call "showState"
                // with ScreenState.NFC instead of simply navigating back
                this.titleBar.onLeftButtonTapped = function onLeftButtonTapped() {
                    this.ViewController.navigateBack();
                    this.titleBar.onLeftButtonTapped = null;
                }.bind(this);

                this._startSharingMiniserver();
            });
        }


        _startSharingMiniserver() {
            var url = this._buildString(this._addUser, this._addPassword);

            var message = [ndef.uriRecord(url), ndef.androidApplicationRecord(AppPackageName)];

            this._write(message);
        }

        _buildString(withUsr, withPwd) {
            var host = ActiveMSComponent.getCurrentUrl(true);
            var ms = {
                host: host
            };

            if (withUsr) {
                ms.usr = this._miniserver.activeUser;
                var pwd;

                try {
                    pwd = VendorHub.Crypto.decrypt(this._miniserver.password);

                    if (pwd.length === 0) {
                        pwd = this._miniserver.password;
                    }
                } catch (e) {
                    console.error(e);
                    pwd = this._miniserver.password;
                }

                if (withPwd || pwd === "") {
                    // if password is empty, save to the tag!
                    ms.pwd = pwd;
                }
            }

            return UrlHelper.createURLStart(ms);
        }

        // TableViewDataSource Methods
        styleForTableView(tableView) {
            return HD_APP ? TableViewStyle.GROUPED : TableViewStyle.PLAIN;
        }

        numberOfSections(tableView) {
            return 1;
        }

        numberOfRowsInSection(section, tableView) {
            return 2;
        }

        cellTypeForCellAtIndex(section, row, tableView) {
            return GUI.TableViewV2.CellType.SWITCH;
        }

        contentForCell(cell, section, row, tableView) {
            if (section === 0) {
                if (row === 0) {
                    return {
                        title: _('nfc.tag.write.user'),
                        active: this._addUser
                    };
                } else if (row === 1) {
                    return {
                        title: _('nfc.tag.write.password'),
                        active: this._addPassword
                    };
                }
            }
        }

        onSwitchChanged(value, section, row, tableView, cell) {
            if (section === 0) {
                if (row === 0) {
                    this._addUser = value;

                    if (!value) {
                        this._addPassword = false;
                    }

                    this.settingsTable.reload();

                    this._startSharingMiniserver();
                } else if (row === 1) {
                    // request the pw, if he is sure!
                    if (value) {
                        var content = {
                            title: _('nfc.share-password-warning'),
                            buttonOk: _('save-password'),
                            buttonCancel: true,
                            icon: Icon.CAUTION,
                            color: window.Styles.colors.red
                        };
                        return NavigationComp.showPopup(content).then(function () {
                            return NavigationComp.requestPasswordFromCurrentUser().then(function (password) {
                                this._addPassword = true;
                                this._addUser = true;
                                this.settingsTable.reload();
                                this._miniserver.password = password;

                                this._startSharingMiniserver();
                            }.bind(this), function () {
                                cell.setActive(false);
                            }.bind(this));
                        }.bind(this), function () {
                            cell.setActive(false);
                        });
                    } else {
                        this._addPassword = false;
                        this.settingsTable.reload();

                        this._startSharingMiniserver();
                    }
                }
            }
        }

    };
});
