'use strict';
/*
 description
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.CHECKABLE_DESCRIPTION = "CheckableDescriptionCell";

    class CheckableDescriptionCell extends GUI.TableViewV2.Cells.CheckableCell {
        //region Static
        static Template = function () {
            var getDescriptionTemplate = function getDescriptionTemplate() {
                return '<div class="cell__description-label clickable"></div>';
            };

            return {
                getDescriptionTemplate: getDescriptionTemplate
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("checkable-description-cell");
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.element.find('.title__text').removeClass("text-overflow-ellipsis");
                this.element.prepend($('<div class="cell__flex-container"></div>').append(this.element.children()));
                var descriptionLabel = $(CheckableDescriptionCell.Template.getDescriptionTemplate());
                this.labelHandler = Hammer(descriptionLabel[0]).on(tapEvent(), function (e) {
                    this._handleTap(e);
                }.bind(this));
                this.element.append(descriptionLabel);
                this.elements.descriptionLabel = this.element.find(".cell__description-label");
                this.updateContent(this.content);
            }.bind(this));
        }

        // ---------------------------------------------------------------------------------------------------------
        //   Methods for super fast tableContent updates
        // ---------------------------------------------------------------------------------------------------------
        cellTypeForReuse() {
            return GUI.TableViewV2.CellType.CHECKABLE_DESCRIPTION;
        }

        updateContent(newContent) {
            return super._updateContent(...arguments);
        }

        /**
         * This Method is used to apply a new content object onto the cell instance. e.g. update texts and so on.
         * @param newContent
         * @private
         */
        _applyNewContent(newContent) {
            Debug.GUI.CheckableCell && console.log(this.name, "_applyNewContent: " + JSON.stringify(newContent)); // call verify first, as it'll ensure the proper icons are set.

            var promises = [super._applyNewContent(...arguments)];
            GUI.animationHandler.setHtml(this.elements.descriptionLabel, newContent.description);
            return Q.all(promises);
        }

        // ---------------------------------------------------------------------------------------------------------
        // ---------------------------------------------------------------------------------------------------------
        destroy() {
            this.labelHandler.dispose();
            return super.destroy(...arguments);
        }

    }

    GUI.TableViewV2.Cells.CheckableDescriptionCell = CheckableDescriptionCell;
    return GUI;
}(window.GUI || {});
