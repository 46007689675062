"use strict";
/**
 * Like a slider cell but with a gradient slider instead of a regular one.
 *
 * gradientColors             array of colors to be shown in the gradient sliders bar
 * */

GUI.TableViewV2.CellType.Special.GRADIENT_SLIDER = "GradientSliderCell";

class GradientSliderCell extends GUI.TableViewV2.Cells.SliderCell {
    constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
        super(...arguments);
    }

    /**
     * The reason this method exists is to be able to change to another slider implementation in the subclasses.
     * @param targetElement HTMLElement, in which the react component should be rendered
     * @param config Object, that contains slider configuration values such as max, min, step...
     * @returns {*}
     */
    getSlider(targetElement, config) {
        return this.appendReactComp({
            reactComp: GUI.LxSlider,
            compProps: {
                ...config,
                hasGradientTrack: true,
                activeColors: this.content.gradientColors,
                antiGhostTimer: !!this.content.antiGhostTimer,
                updateRate: 0.25,
                containerStyle: {
                    marginLeft: 15,
                    marginRight: 15
                },
                min: this.content.minValue,
                max: this.content.maxValue
            },
            target: targetElement
        })
    }

    setGradientColors(colors) {
        this.slider.setActiveColors(colors);
    }

}

GUI.TableViewV2.Cells.GradientSliderCell = GradientSliderCell;
