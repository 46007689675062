'use strict';
/**
 * Created by loxone on 08.03.17.
 */

define(['SelectorPageBase', 'ColorPickerV2ControlEnums'], function (SelectorPageBase, ColorPickerV2ControlEnums) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    class ParentStateSteward {
        constructor(control, onStateFn) {
            Object.assign(this, StateHandler.Mixin);
            this.control = control;
            this.onStateFn = onStateFn;
        }

        register() {
            this._registerForStates();

            this._requestStates();
        }

        unregister() {
            this._unregisterStates();
        }

        receivedStates() {
            this.onStateFn(...arguments);
        }
    }

    var DAYLIGHT_MODES = {
        AUTO: 0,
        MANUEL: 1
    };
    return class ColorSelectorPage extends SelectorPageBase {
        constructor(control) {
            super(...arguments);
            let parentControl = ActiveMSComponent.getStructureManager().getControlByUUID(this.control.uuidParent),
                parentStates = parentControl.getStates();
            this.parentStateSteward = new ParentStateSteward(parentControl, this.receivedParentStates.bind(this));
            this._daylightConfig = {};

            if (Feature.DAYLIGHT && parentStates) {
                this._daylightConfig = parentStates.daylightconfig;
            }
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                return this.favoriteSelector.setTemperatureOnlyMode(this.control.tuneAbleWhiteOnly());
            }.bind(this));
        }

        viewDidAppear() {
            let prms = super.viewDidAppear(...arguments);
            this.parentStateSteward.register();
            return prms;
        }

        viewDidDisappear(viewRemainsVisible) {
            this.parentStateSteward.unregister();
            return super.viewDidDisappear(viewRemainsVisible);
        }

        receivedStates(states) {
            let isInDayLightMode = this.control.isInDayLightMode();
            this.states = states; // selection & favorites are important in the baseclass

            this.activeMode = states.selectedMode;

            if (!this._isActive()) {
                this.selection = null;
            } else if (!this.getIsDragging()) {
                this.selection = cloneObject(states.color);
            }

            this.favorites = states.favColors; // Only single selection Views can switch between Temp and HSV

            if (!this.multiSelection) {
                if (this.activeMode !== ColorPickerV2ControlEnums.PickerMode.SEQUENCE) {
                    this.colorSelector.setActiveMode(this.activeMode);
                }
            }

            super.receivedStates(...arguments); // IMPORTANT!!!!!!! --> process after the base call

            if (Feature.DAYLIGHT) {
                if (this._lastPickerMode !== states.selectedMode) {
                    this._lastPickerMode = states.selectedMode;
                    this.colorSelector.togglePickerMode(states.selectedMode);

                    if (states.selectedMode === ColorPickerV2ControlEnums.PickerMode.DAYLIGHT) {
                        this.hideSubview(this.previewBar);
                    } else {
                        this.showSubview(this.previewBar);
                    }
                }

                let wantedCell = null;

                this.processWhenVisible(function () {
                    wantedCell = this.tableView.cellForSectionAndRow(0, 0);
                    if (wantedCell) {
                        wantedCell.setActive(isInDayLightMode);
                    }
                }.bind(this));

                if (!isInDayLightMode) {
                    this.colorSelector.setColorViewText("");
                } else {
                    this._setColorViewText();
                }
            }
        }

        receivedParentStates({
                                 daylightconfig
                             }) {
            if (Feature.DAYLIGHT && this.control.isInDayLightMode()) {
                this._daylightConfig = daylightconfig;

                this._setColorViewText();
            }
        }

        _setColorViewText() {
            if (this._daylightConfig.mode === DAYLIGHT_MODES.AUTO) {
                this.colorSelector.setColorViewText(_('controls.daylight.sunrise-to-sunset'));
            } else if (this._daylightConfig.mode === DAYLIGHT_MODES.MANUEL) {
                this.colorSelector.setColorViewText(_("x-to-y-time", {
                    x: LxDate.fromMinutesSinceMidnight(this._daylightConfig.from).format(DateType.TimeFormat),
                    y: LxDate.fromMinutesSinceMidnight(this._daylightConfig.until).format(DateType.TimeFormat)
                }));
            }
        }

        /**
         * In the baseclass, the number of cells is always 1 --> brightness
         * @returns {number}
         */
        numberOfRowsInSection() {
            return Feature.DAYLIGHT ? 2 : 1;
        }

        /**
         * The brightness-cell uses a gradient slider
         * @returns {string}
         */
        cellTypeForCellAtIndex(section, row) {
            if (row === 0 && Feature.DAYLIGHT) {
                return GUI.TableViewV2.CellType.SWITCH;
            } else {
                return super.cellTypeForCellAtIndex(...arguments);
            }
        }

        contentForCell(cell, section, row) {
            if (row === 0 && Feature.DAYLIGHT) {
                return {
                    title: _('controls.daylight.full-name'),
                    active: this.control.isInDayLightMode()
                };
            } else {
                return super.contentForCell(...arguments);
            }
        }

        onSwitchChanged(value) {
            var cmd;

            if (value) {
                cmd = Commands.format(Commands.COLOR_PICKER_V2.DAYLIGHT_ON, this.states.activeBrightness);
            } else {
                cmd = Commands.format(Commands.COLOR_PICKER_V2.TEMP, this.states.activeBrightness, this.states.color.kelvin);
            }

            this.control.sendCommand(cmd);
        }

        // ------------------------------------------------------------------------
        //            Implemented Abstract Baseclass Methods
        // ------------------------------------------------------------------------

        /**
         * Returns the value for the brightness slider.
         */
        getBrightnessValue() {
            var brightness = 0;

            if (this._isActive()) {
                brightness = this.selection ? this.selection.brightness : this.activeBrightness;
            }

            return brightness;
        }

        /**
         * Used for the color selector, it returns the current selector mode (HSV, Temp or sequence)
         */
        getSelectorMode() {
            return ColorPickerV2ControlEnums.PickerMode.HSV;
        }

        /**
         * Returns an array of colors to show as gradient in the previewBar
         */
        getPreviewColors() {
            Debug.Control.ColorPickerV2 && console.log(this.name, "getPreviewColors: " + JSON.stringify(this.selection));
            return this.selection ? [this.selection] : [];
        }

        /**
         * Will be called whenever one of the favorites has been selected.
         * @param idx       the index of the picked favorites inside the fav array.
         * @param fav       the favorite object that has been picked.
         */
        handleFavoriteSelected(idx, fav) {
            // antiGhostTime needs to be reseted before, setting the sequenzer
            this.antiGhostTimer.resetAntiGhostTimer();
            this.sendColorObject(fav, fav.brightness, true);
        }

        /**
         * Will delete the favorite at the specified position.
         * @param favIdx    what favorite to delete
         */
        handleFavoriteDelete(favIdx) {
            this.sendCommand(Commands.format(Commands.COLOR_PICKER_V2.DEL_FAVORITE, favIdx));
        }

        /**
         * Will set the brightness cells slider position according to the current value.
         */
        updateBrightnessCell() {
            var cellIdx = Feature.DAYLIGHT ? 1 : 0,
                wantedCell;
            this.processWhenVisible(function () {
                wantedCell = this.tableView.cellForSectionAndRow(0, cellIdx);
                if (wantedCell) {
                    wantedCell.setPosition(this.getBrightnessWithCache());
                }
            }.bind(this));
        }

        /**
         * Will save the current selection as new favorite at the specified position.
         * @param newIdx        where to save the new favorite to.
         */
        handleFavoriteSave(newIdx) {
            var color = LxColorUtils.getStringFromColorObject(this.selection);
            this.sendCommand(Commands.format(Commands.COLOR_PICKER_V2.SET_FAVORITE, newIdx, color));
        }

        /**
         * Called whenever the user modifies the brightness.
         * @param newBrightness
         * @param isDragging
         */
        handleBrightnessChanged(newBrightness, isDragging) {
            if (!this.selection) {
                this.selection = this._initColor();
            }

            this.selection.brightness = newBrightness;
            this.sendColorObject(this.selection, isDragging);
        }

        /**
         * Called when a color is picked/changed using the colorSelection.
         * @param newColor  the current picked color
         * @param idx       the index of the picked color (for sequences)
         * @param dragging  if the selection process is still ongoing (still dragging along)
         */
        handleColorSelected(newColor, idx, dragging) {
            this.selection = newColor;
            this.sendColorObject(this.selection, dragging);
        }

        /**
         * Overwrite - when the color selection changes here, we want to update the brightness cells slider gradient.
         */
        updatePreview() {
            super.updatePreview(...arguments);
            var colors = this.getPreviewColors(),
                color = cloneObject(colors[0]),
                cellIdx = Feature.DAYLIGHT ? 1 : 0,
                wantedCell;

            if (!color) {
                color = this._initColor();
            }

            color.brightness = 100;
            this.processWhenVisible(function () {
                wantedCell = this.tableView.cellForSectionAndRow(0, cellIdx);
                if (wantedCell) {
                    wantedCell.setGradientColors([Color.STATE_INACTIVE, LxColorUtils.getCSSRGBPropertyFromColorObject(color)]);
                }
            }.bind(this));
        }

        _isActive() {
            var result = false;

            switch (this.activeMode) {
                case ColorPickerV2ControlEnums.PickerMode.HSV:
                case ColorPickerV2ControlEnums.PickerMode.TEMPERATURE:
                case ColorPickerV2ControlEnums.PickerMode.DAYLIGHT:
                    result = true;
                    break;

                default:
                    break;
            }

            return result;
        }

        _initColor() {
            return {
                type: ColorPickerV2ControlEnums.PickerMode.HSV,
                hue: 0,
                sat: 0,
                brightness: this.activeBrightness
            };
        }

    };
});
