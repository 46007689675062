'use strict';

define(["AudioZoneV2ControlEnums"], function (AudioZoneV2ControlEnums) {
    class SettingsLineIn extends GUI.TableViewScreenV2 {
        //region Getter
        get input() {
            return this._input;
        } //endregion Getter


        //region Setter
        set input(newVal) {
            this._input = cloneObjectDeep(newVal);
            this.ogName = this._input.name;
        } //endregion Setter


        constructor(details) {
            super($('<div class="audiozone-settings-input" />'));
            this.details = details;
            this.control = details.control;
            this.currentPlayerId = details.control.details.playerid;
            this.validName = true;
            this.input = details.input;
        }

        reloadTable() {
            this.tableContent = [{
                rows: [{
                    type: GUI.TableViewV2.CellType.INPUT,
                    content: {
                        value: this.input.name,
                        placeholder: this.ogName,
                        autofocus: false,
                        validationRegex: Regex.NAME
                    }
                }]
            }];
            this.tableContent[0].rows.push({
                content: {
                    title: _("icon"),
                    clickable: true,
                    rightIconSrc: MusicServerEnum.LineInMap[this.input.icontype],
                    class: "base-cell--with-chevron"
                },
                action: this._showSymbols.bind(this)
            });
            this.tableContent[0].rows.push({
                content: {
                    title: _("audio-server.popup.title"),
                    // simply translates to Audioserver
                    clickable: false,
                    disclosureText: this.input.serverName
                },
                action: this._showSymbols.bind(this)
            });
            return super.reloadTable(...arguments);
        }

        viewWillDisappear() {
            var args = arguments;
            return this._updateSettings().finally(() => {
                return super.viewWillDisappear(...args);
            });
        }

        titleBarText() {
            return _("media.section.line-in");
        }

        textChanged(section, row, tableView, text, isValid) {
            this.input.name = text;
            this.validName = isValid;

            if (text === "") {
                this.input.name = this.ogName;
                this.validName = true;
            }
        }

        // Private methods

        /**
         * Will update all the settings of the input. Until this is done, a popup is shown.
         * @private
         */
        _updateSettings() {
            Debug.Control.AudioZone.Settings.Zone && console.log(this.name, "_updateSettings");

            if (this.updatePromise) {
                return; // nothing to do.
            }

            var promises = [];

            if (this.validName) {
                Debug.Control.AudioZone.Settings.Zone && console.log("       rename to: " + this.input.name);
                promises.push(this.control.audioserverComp.updateInputName(this.input));
            }

            promises.push(this.control.audioserverComp.updateInputEnabled(this.input));
            promises.push(this.control.audioserverComp.updateInputType(this.input));
            promises.push(this.control.audioserverComp.updateInputVolume(this.input, true));
            this.updatePromise = NavigationComp.showWaitingFor(Q.all(promises), null, _("media.input.updating", {
                name: this.input.name
            }));
            this.updatePromise.finally(function () {
                this.updatePromise = null;
            }.bind(this));
            return this.updatePromise;
        }

        _showSymbols() {
            var def = Q.defer(),
                details = {
                    options: Object.keys(MusicServerEnum.LineInMap).map(function (symbolType) {
                        symbolType = parseInt(symbolType); // symbolType may be a string!

                        return {
                            icontype: symbolType,
                            rightIconSrc: MusicServerEnum.LineInMap[symbolType],
                            selected: this.input.icontype === symbolType
                        };
                    }.bind(this)),
                    title: _("icon"),
                    deferred: def
                };
            this.ViewController.showState(ScreenState.SelectorScreen, null, details);
            def.promise.then(function (result) {
                this.input.icontype = result[0].option.icontype;
                this.reloadTable();
            }.bind(this));
        }

    }

    Controls.AudioZoneV2Control.SettingsLineIn = SettingsLineIn;
    return Controls.AudioZoneV2Control.SettingsLineIn;
});
