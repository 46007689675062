import {View} from "react-native";
import globalStyles from "GlobalStyles";
import {Pressable} from "react-native";
import {Animated} from "react-native-web";
import {useState, useMemo, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import {Swipeable} from "react-native-gesture-handler";
import LxReactRadioButton from "../LxReactRadionButton/LxReactRadioButton";
import {
    LxReactIconButton,
    LxReactButton,
    LxReactText,
    ReactWrapper
} from "LxComponents";
import Icons from "IconLib";
import { Subject } from 'rxjs';
import {useSortable} from "@dnd-kit/sortable";
/**
 *   The flexible cell is the base of all cells in the application. It contains the slots leftAccessory, main, mainLeft, mainRight, top, bottom
 *   which can be custom filled, styled or replaced. Additionaly it can be also swiped, checked and sorted when in a DraggableFlatlist.
 *   @param {Boolean} props.debugColors Flag for enabling slot backgroundColors
 *   @param {String} props.title Default title that appears in main
 *   @param {Object} props.titleStyle Style for overriding the title label style
 *   @param {String} props.subTitle Default subtitle that appears in main
 *   @param {Object} props.subTitleStyle Style for overriding the subTitle label style
 *   @param {Function} props.onDrag Drag function that comes from renderItem() of DraggableFlatlist
 *   @param {Boolean} props.checked Whether the cell is checked or not, requires checkable to be true to work
 *   @param {Boolean} props.checkable Whether the cell has a radio button or not
 *   @param {Function} props.onPress Function, that is called when item is clicked, defines if item is a Touchable
 *   @param {Function} props.onSwipe Function, that is called when item is fully swiped in a direction
 *   @param {Function} props.onCheckedToggled Function, that is called when item is checked or unchecked, requires checkable to be true to work
 *   @param {Array} props.refs Array, where references of the swipeable item are stored, is needed when using swipe actions, close the swipe actions by calling refs[index].close()
 *   @param {Object} props.cellContainerStyle Style for overriding the cell container
 *   @param {JSX.Element} props.leftAccessoryContent Content rendered left at the left side (is not between top,bottom)
 *   @param {Object} props.leftAccessoryStyle Style for the left accessory container
 *   @param {JSX.Element} props.mainCenterContent Content rendered in main (is between top,bottom)
 *   @param {Object} props.mainCenterStyle Style for the main container
 *   @param {JSX.Element | Array<JSX.Element>} props.mainLeftContent Content rendered at the left side of main (is between top,bottom)
 *   @param {Object} props.mainLeftStyle Style for the main Left container
 *   @param {JSX.Element | Array<JSX.Element>} props.mainRightContent Content rendered at the right side of main (is between top,bottom)
 *   @param {Object} props.mainRightStyle Style for the main right container
 *   @param {JSX.Element} props.topContent Content rendered above Main
 *   @param {Object} props.topStyle Style for the top container
 *   @param {JSX.Element} props.bottomContent Content rendered below Main
 *   @param {Object} props.bottomStyle Style for the bottom container
 *   @param {Function} props.renderRightSwipeActions Function, that returns an action panel that should appear when item is slided from right to left
 *   @param {Function} props.renderLeftSwipeActions Function, that returns an action panel that should appear when item is slided from left to right
 *   @returns {JSX.Element} A flexible cell with slots like main,mainRightLeft,top,bottom...
 */
export default function LxReactFlexibleCell(props) {
    const {
        listeners,
        setActivatorNodeRef,
    } = useSortable({ id: props.id });
    const [swipeOpen, setSwipeOpen] = useState(false);
    const [checked, setChecked] = useState(!!props.checked);
    const [mouseDownXPos, setMouseDownXPos] = useState(0);
    const [ignoreMousePressAfterSwipe, setIgnoreMousePressAfterSwipe] = useState(false);
    const didMount = useRef(false);

    // region styles
    const styles = useMemo(() => {
        return {
            cellElement: {
                backgroundColor: globalStyles.colors.transparent,
                minHeight: 60,
                opacity: props.disabled ? 0.4 : 1
            },
            container: {
                flex: 1,
                flexDirection: "row",
                paddingHorizontal: 4,
                width: "100%",
            },
            contentContainer: {
                flex: 1,
                flexDirection: "column",
                paddingVertical: 10,
                alignItems: "flex-start",
            },
            leftAccessory: {
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 10,
                backgroundColor: globalStyles.colors.transparent
            },
            mainContainer: {
                flex: 1,
                width: "100%",
                flexDirection: "row",
                alignItems: "center"
            },
            main: {
                maxWidth: "100%",
                flex: 1,
                flexDirection: "column",
                // removed flex-shrink: 0 because it lead to the main content overflowing the cell, because its not able to shrink
                flexBasis: "min-content",
                alignItems: "flex-start",
                backgroundColor: globalStyles.colors.transparent
            },
            mainLeftRight: {
                flexShrink: 1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: globalStyles.colors.transparent
            },
            mainLeft: {
                marginRight: 10
            },
            mainRight: {
                marginLeft: 10
            },
            top: {
                width: "100%",
                backgroundColor: globalStyles.colors.transparent
            },
            bottom: {
                width: "100%",
                backgroundColor: globalStyles.colors.transparent
            },
            title: {
                maxWidth: "100%",
                color: globalStyles.colors.white,
                ...globalStyles.textStyles.body.default
            },
            subTitle: {
                color: globalStyles.colors.text.secondary,
                ...globalStyles.textStyles.body.default
            },
            swipeButton: {
                borderRadius: 0,
                width: 60,
                height: "100%"
            },
            swipeButtonPressed: {
                borderRadius: 0,
                width: 60,
                height: "100%"
            },
            swipeIconButton: {
                padding: "auto",
                alignItems: "center"
            },
            swipeIconButtonIcon: {
                margin: "auto",
                width: 30,
                height: 30
            },
            swipeButtonText: {
                fontSize: "10pt"
            },
            dragIcon: {
                padding: globalStyles.spacings.gaps.small
            }
        }
    }, [props.disabled])


    // endregion

    // This basically listens for any changes to the setter
    useEffect(() => {
        // Don't do it initially (before the component has been mounted
        if (!didMount.current) {
            didMount.current = true;
            return;
        }

        emitEvent({
            type: LxReactFlexibleCell.EventTypes.SwipableOpened,
            value: swipeOpen
        });
        if (!swipeOpen) {
            closeSwipeable()
        }
    }, [swipeOpen]);

    useEffect(() => {
        setChecked(!!props.checked)
    }, [props.checked]);

    props.channel && props.channel.subscribe(({type, value, section, row}) => {
        if (!(section === props.section && row === props.row)) {
            switch (type) {
                case LxReactFlexibleCell.EventTypes.SwipableOpened:
                    if (!(section === props.section && row === props.row) && !!value) {
                        setSwipeOpen(false);
                    }
                    break;
                case LxReactFlexibleCell.EventTypes.Checked:
                    handleCheckEvent(value, section, row)
                    break;
                default:
                    // Unknown event
            }
        }
    })

    const handleCheckEvent = (value, section, row) => {
        if ("checked" in props) {
            let wasChecked = checked,
                shouldCheck = checked;
            switch (value.radioMode) {
                case LxReactFlexibleCell.RadioMode.Sectioned:
                    if (section === props.section) {
                        shouldCheck = row === props.row && value.checked;
                    }
                    break;
                case LxReactFlexibleCell.RadioMode.Table:
                    shouldCheck = section === props.section && row === props.row && value.checked;
                    break;
            }
            if (wasChecked !== shouldCheck) {
                setChecked(shouldCheck);
                props.onCheckedChange && props.onCheckedChange(shouldCheck, true);
            }
        }
    }

    /**
     * Emits an event to all other cells on this table
     * @param type
     * @param value
     */
    const emitEvent = ({type, value}) => {
        props.channel && props.channel.next({
            type: type,
            value: value,
            section: props.section,
            row: props.row
        });
    }

    const onRemove = () => {
        props.onRemove && props.onRemove(props.section, props.row)
    }

    const onCheck = check => {
        if (props.radioMode !== LxReactFlexibleCell.RadioMode.Inactive) {
            // if radio mode is active, don't allow to deselect a cell
            check = true;
        }
        return Q.resolve(props.onCheckedToggled(check)).then(canBeChecked => {
            let wasChecked = checked;
            emitEvent({
                type: LxReactFlexibleCell.EventTypes.Checked,
                value: {
                    checked: check,
                    radioMode: props.radioMode
                }
            });
            setChecked(check);
            if (wasChecked !== check && props.onCheckedChange) {
                props.onCheckedChange(check);
            }
            return canBeChecked;
        });
    }

    const renderLeftAccessory = () => {
        let child;
        if (typeof props.onRemove === "function") {
            child = (
                <Pressable key="left-accessory" onPress={onRemove}>
                    <Icons.MinusFilledCircle width={24} height={24} fill={globalStyles.colors.red}/>
                </Pressable>
            )
        } else if ("checked" in props) {
            child = (
                <LxReactRadioButton
                    checked={checked}
                    checkedStyle={props.checkedIconStyle}
                    uncheckedStyle={props.uncheckedIconStyle}
                    onToggled={onCheck}
                    disabled={props.disabled}
                    radius={24} />
            );
        } else {
            child = getContentForElement(props.leftAccessoryContent);
        }
        if (child) {
            return (
                <View key={`LeftAccessory-${props.section}:${props.row}`} style={[styles.leftAccessory, props.leftAccessoryStyle]}>
                    {child}
                </View>
            )
        }
    }

    const renderMainCenter = () => {
        let mainContent = getContentForElement(props.mainCenterContent);
        if (!props.mainCenterContent) {
            mainContent = [];
            if (props.title) {
                mainContent.push((<LxReactText type={"Primary"} numberOfLines={(props.titleStyle && props.titleStyle.numberOfLines) || 0} isLoading={props.isLoading} key={`Title-${props.section}:${props.row}`} style={[styles.title, props.titleStyle]} ellipsizeMode='tail'>
                    {props.title}
                </LxReactText>))
            }
            if (props.subTitle) {
                mainContent.push((<LxReactText type={"Secondary"} numberOfLines={(props.subTitleStyle && props.subTitleStyle.numberOfLines) || 0} isLoading={props.isLoading}  key={`SubTitle-${props.section}:${props.row}`} style={[styles.subTitle, props.subTitleStyle]} ellipsizeMode='tail'>
                    {props.subTitle}
                </LxReactText>))
            }
        }
        return (
            <View key={`Main-${props.section}:${props.row}`} style={[styles.main, props.mainCenterStyle]}>
                {mainContent}
            </View>
        )
    }

    const renderMainLeft = () => {
        let mainLeftContent = getContentForElement(props.mainLeftContent);
        if (mainLeftContent) {
            return   (<View key={`MainLeft-${props.section}:${props.row}`}
                            style={mergeStyles(styles.mainLeftRight, styles.mainLeft, props.mainLeftStyle)}>
                {mainLeftContent}
            </View>)
        }
    }

    const renderMainRight = () => {
        let mainRightContent = null;

        if (props.onDrag) {
            let pressableProps = {
                onPressIn: props.onDrag
            };

            /*if ((('ontouchstart' in window) ||
                (navigator.maxTouchPoints > 0) ||
                (navigator.msMaxTouchPoints > 0)) && !PlatformComponent.isDeveloperInterface()) {
                pressableProps.onLongPress = props.onDrag;
                pressableProps.longPressDelay = 250;
            } else {
                pressableProps.onPressIn = props.onDrag;
            }*/
            return (
                <Pressable key="main-right" {...pressableProps} style={styles.dragIcon}>
                    <Icons.Sort fill={globalStyles.colors.white} width={24} height={24}/>
                </Pressable>
            )
        } else if (props.useInDnd) {
            return (
                <div  style={{touchAction: "manipulation", cursorAction: "grab"}} ref={setActivatorNodeRef} {...listeners}>
                    <Icons.Sort fill={globalStyles.colors.white} width={24} height={24}/>
                </div>
            )
        } else {
            let renderContent = [];
            if (Array.isArray(props.mainRightContent)) {
                renderContent.splice(0,0,...props.mainRightContent);
            } else if (props.mainRightContent) {
                renderContent.push(props.mainRightContent);
            }
            if (props.disclosureIcon) {
                renderContent.push({
                    comp: Icons.Chevron,
                    props: {
                        fill: globalStyles.colors.text.secondary,
                        height: 24,
                        width: 12,
                        style: {marginLeft: 12, minHeight: 12, minWidth: 12}
                    }
                });
            }
            mainRightContent = getContentForElement(renderContent);
        }


        if (mainRightContent) {
            return (
                <View key={`MainRight-${props.section}:${props.row}`}
                      style={mergeStyles(styles.mainLeftRight, styles.mainRight, props.mainRightStyle)}>
                    {mainRightContent}
                </View>
            )
        } else {
            return null;
        }
    }

    const renderTop = () => {
        if (props.topContent) {
            return (
                <View key={`Top-${props.section}:${props.row}`} style={[styles.top, props.topStyle]}>
                    {getContentForElement(props.topContent)}
                </View>
            )
        } else {
            return null;
        }
    }

    const renderBottom = () => {
         if (props.bottomContent) {
            return (
                <View key={`Bottom-${props.section}:${props.row}`} style={[styles.bottom, props.bottomStyle]}>
                    {getContentForElement(props.bottomContent)}
                </View>
            )
        } else {
            return null;
        }
    }

    const renderMainContent = () => {
        if (props.mainContent) {
            let rendered = renderSingleItem(props.mainContent);
            return <View key={"MainContainer"} style={styles.mainContainer}>
                {rendered}
            </View>
        } else {
            return <View key={"MainContainer"} style={styles.mainContainer}>

                {renderMainLeft()}

                {renderMainCenter()}

                {renderMainRight()}

            </View>
        }
    }

    const renderCellSlots = () => [
        renderLeftAccessory(),
        (<View key={`ContentContainer-${props.section}:${props.row}`}
               style={[styles.contentContainer, props.mainContentContainerStyle]}>

            {renderTop()}

            {renderMainContent()}

            {renderBottom()}
        </View>)
    ]

    const handleCellContainerMouseDown = e => {
        // store mouse position when click starts
        setMouseDownXPos(e.screenX);
    }

    const handleCellContainerMouseUp = e => {
        const delta = Math.abs(e.screenX - mouseDownXPos);

        if (delta > 10) {
            // If mouse moved more than threshold, ignore the press event which will follow
            setIgnoreMousePressAfterSwipe(true);
        }
        setMouseDownXPos(0);
    }

    const renderCellContainer = () => {
        if (props.onPress || "checked" in props) {
            return (
                <Pressable
                    key={`CellContainerTouchable-${props.section}:${props.row}`}
                    style={[styles.container, props.cellContainerStyle]}
                    onPress={(cell) => handleOnPress(cell)}
                    disabled={props.disabled}
                    onMouseDown={handleCellContainerMouseDown}
                    onMouseUp={handleCellContainerMouseUp}
                >
                    {renderCellSlots()}
                </Pressable>
            )
        } else {
            return (<View key={`CellContainer-${props.section}:${props.row}`} style={[styles.container, props.cellContainerStyle]}>
                {renderCellSlots()}
            </View>)
        }
    }

    const renderLeftSwipeActions = (progress, dragX) => {
        let actions;
        if (props.renderLeftSwipeActions) {
            actions = props.renderLeftSwipeActions(props.section, props.row, progress, dragX);
        } else if (props.leftSwipeButtons) {
            actions = renderSwipeContainer(progress, dragX, props.leftSwipeButtons, "left");
        }

        return actions;
    }

    const renderRightSwipeActions = (progress, dragX) => {
        let actions;
        if (props.renderRightSwipeActions) {
            actions = props.renderRightSwipeActions(props.section, props.row, progress, row);
        } else if (props.rightSwipeButtons) {
            actions = renderSwipeContainer(progress, dragX, props.rightSwipeButtons, "right");
        }

        return actions;
    }

    const renderSwipeContainer = (progress, dragX, swipeButtons, direction) => {
        let trans,
            buttonWidth = swipeButtons.length * 60,
            inputRangeEnd = buttonWidth + 40;
        if (direction === "left") {
            trans = dragX.interpolate({
                inputRange: [0, buttonWidth, inputRangeEnd],
                outputRange: [buttonWidth * -1, 0, 0],
            });
        } else if (direction === "right") {
            trans = dragX.interpolate({
                inputRange: [inputRangeEnd * -1, buttonWidth * -1, 0],
                outputRange: [0, 0, buttonWidth],
            });
        }
        let buttons = [];
        swipeButtons.forEach((itemProps, index) => {
            buttons.push(renderSwipeButton(itemProps, `Swipe-${props.section}:${props.row}-${direction}${index}`, direction, index));
        })
        return <Animated.View
            key={`Swipe-${props.section}:${props.row}-${direction}-animation-ctr`}
            style={{maxHeight: 60, flexDirection: "row", transform: [{ translateX: trans }]}}>
            {(buttons)}
        </Animated.View>
    }

    const renderSwipeButton = (itemProps, key, direction, index) => {
        var result;
        if (!key) {
            debugger;
        }
        if (itemProps.hasOwnProperty("icon")) {
            result = (<LxReactIconButton
                {...itemProps}
                onPress={handleSwipePress}
                onPressArgs={[direction, index]}
                key={key}
                pkey={key}
                containerStyle={{...styles.swipeButton, ...styles.swipeIconButton, ...itemProps.containerStyle}}
                pressedContainerStyle={{...styles.swipeButtonPressed, ...itemProps.containerStylePressed}}
                iconStyle={{...styles.swipeIconButtonIcon, ...itemProps.iconStyle}}
            />);
        } else {
            result = (<LxReactButton
                {...itemProps}
                key={key}
                pkey={key}
                onPress={handleSwipePress}
                onPressArgs={[direction, index]}
                containerStyle={{...styles.swipeButton, ...itemProps.containerStyle}}
                textStyle={{...styles.swipeButtonText, ...itemProps.textStyle}}
            />);
        }
        return result;
    }

    const handleSwipePress = (event, direction, index) => {
        if (direction === "left") {
            props.leftSwipeButtons[index].onPress(event);
        } else if (direction === "right") {
            props.rightSwipeButtons[index].onPress(event);
        }
        closeSwipeable();
    }

    const hasSwipeActions = () => {
        return props.renderRightSwipeActions || props.renderLeftSwipeActions ||
            props.leftSwipeButtons || props.rightSwipeButtons;
    }

    const handleSwipeableWillOpen = (direction) => {
        setSwipeOpen(true);
    }

    const handleSwipeableClose = (direction) => {
        setSwipeOpen(false);
    }

    /**
     * OnPress may need to be intercepted, either to close swipeable or as it's a faulty onPress after swiping open via mouse
     * @param cell
     * @returns {*}
     */
    const handleOnPress = (cell) => {
        if (ignoreMousePressAfterSwipe) {
            setIgnoreMousePressAfterSwipe(false);
        } else if (swipeOpen) {
            closeSwipeable();
        } else if (props.onPress) {
            return props.onPress(cell, props.section, props.row);
        } else if ("checked" in props) {
            return onCheck(!checked);
        }
    }

    const closeSwipeable = () => {
        Object.keys(props.refs).forEach((key) => {
            props.refs[key].close();
        });
    }

    const renderCellElement = () => {
        if (props.onDrag || (!hasSwipeActions())) {
            return (<View key={`CellElement-${props.section}:${props.row}`} style={[styles.cellElement, props.cellElementStyle]}>
                {renderCellContainer()}
            </View>)
        } else {
            return (
                <Swipeable
                    key={`CellElementSwipeable-${props.section}:${props.row}`}
                    ref={ref => props.refs[`${props.section}:${props.row}`] = ref}
                    containerStyle={[styles.cellElement, props.cellElementStyle]}
                    childrenContainerStyle={[styles.cellElement, props.cellElementStyle]}
                    renderLeftActions={renderLeftSwipeActions}
                    renderRightActions={renderRightSwipeActions}
                    onSwipeableWillOpen={handleSwipeableWillOpen}
                    onSwipeableClose={handleSwipeableClose}
                >
                    {renderCellContainer()}
                </Swipeable>
            );
        }
    }

    const getContentForElement = (content) => {
        let processedContent = null;

        if (content) {
            if (Array.isArray(content)) {
                processedContent = content.map(renderSingleItem);
                if (processedContent.length === 0) {
                    processedContent = null;
                }
            } else {
                processedContent = renderSingleItem(content);
            }
        }

        return processedContent;
    }

    const renderSingleItem = (content) => {
        if (typeof content === "string") {
            return <LxReactText isLoading={props.isLoading}>{content}</LxReactText>;
        } else if (content && content.comp) {
            checkComponentProp(content);
            let newProps = {...content.props};
            if (content.comp.name === LxReactText.name || content.comp.hasIsLoading) {
                newProps.isLoading = props.isLoading;
            }
            return ReactWrapper.React.createElement(content.comp, newProps);
        } else {
            return null;
        }
    }

    const mergeStyles = (...styles) => {
        return Object.assign({}, ...styles)
    }

    const checkComponentProp = (prop) => {
        if (!prop) {
            console.error(LxReactFlexibleCell.name, "Passing NULL as content object must be handled!");
        } else if (!prop.comp) {
            console.error(LxReactFlexibleCell.name, "Content objects need to have a comp property to be rendered!");
        }
    }

    return (
        <View key={`Cell-${props.section}:${props.row}`} style={props.containerStyle}>
            {renderCellElement()}
        </View>
    )
}


LxReactFlexibleCell.EventTypes = {
    SwipableOpened: "swipableOpened",
    Checked: "checked",
    UpdateCellContent: "updateCellContent"
}

LxReactFlexibleCell.RadioMode = {
    Inactive: "inactive",
    Sectioned: "sectioned",
    Table: "table"
}

const debugColors = {
    main: globalStyles.colors.flexibleCell.debug.main,
    mainLeftRight: globalStyles.colors.flexibleCell.debug.mainLeftRight,
    leftAccessory: globalStyles.colors.flexibleCell.debug.leftAccessory,
    top: globalStyles.colors.flexibleCell.debug.top,
    bottom: globalStyles.colors.flexibleCell.debug.bottom
}

// One or more component creator property
const FlexCellSingleComponentProp = PropTypes.exact({
    comp: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.object]).isRequired, // must be a function component, otherwise it'll be repainted on each render.
    props: PropTypes.object
});
const FlexCellComponentsProp = PropTypes.oneOfType([FlexCellSingleComponentProp, PropTypes.arrayOf(FlexCellSingleComponentProp)]);

// one or more component objects, but also strings
const FlexCellSingleTextComponentProp = PropTypes.oneOfType([
    PropTypes.string,
    FlexCellSingleComponentProp
]);
const FlexCellTextComponentsProp = PropTypes.oneOfType(
    [FlexCellSingleTextComponentProp,
    PropTypes.arrayOf(
        FlexCellSingleTextComponentProp
    )]
)

LxReactFlexibleCell.propTypes = {
    debugColors: PropTypes.bool,
    containerStyle: PropTypes.object,
    cellElementStyle: PropTypes.object,
    section: PropTypes.number.isRequired,
    row: PropTypes.number.isRequired,
    channel: PropTypes.instanceOf(Subject),
    title: FlexCellTextComponentsProp,
    titleStyle: PropTypes.object,
    subTitle: FlexCellTextComponentsProp,
    subTitleStyle: PropTypes.object,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    checkedIconStyle: PropTypes.object,
    uncheckedIconStyle: PropTypes.object,
    radioMode: PropTypes.oneOf(Object.values(LxReactFlexibleCell.RadioMode)),
    onDrag: PropTypes.func,
    onRemove: PropTypes.func,
    onPress: PropTypes.func,
    onSwipe: PropTypes.func,
    onCheckedToggled: PropTypes.func,
    onCheckedChange: PropTypes.func,
    refs: PropTypes.array,
    cellContainerStyle: PropTypes.object,
    leftAccessoryContent: FlexCellComponentsProp,
    leftAccessoryStyle: PropTypes.object,
    mainContent: FlexCellSingleComponentProp,
    mainCenterContent: FlexCellComponentsProp,
    mainContentContainerStyle: PropTypes.object,
    mainCenterStyle: PropTypes.object,
    mainLeftContent: FlexCellComponentsProp,
    mainLeftStyle: PropTypes.object,
    disclosureIcon: PropTypes.bool,
    mainRightContent: FlexCellComponentsProp,
    mainRightStyle: PropTypes.object,
    topContent: FlexCellComponentsProp,
    topStyle: PropTypes.object,
    bottomContent: FlexCellComponentsProp,
    bottomStyle: PropTypes.object,
    renderRightSwipeActions: PropTypes.func,
    renderLeftSwipeActions: PropTypes.func,
    leftSwipeButtons: PropTypes.arrayOf(PropTypes.object),
    rightSwipeButtons: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    useInDnd:PropTypes.bool
}
