'use strict';

define(["NfcInstructionView", "NfcTagContentView"], function (NfcInstructionView, NfcTagContentView) {
    return class NfcTagInteractionScreen extends GUI.Screen {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return $('<div class="nfc-tag-interaction-screen">' + '   <div class="nfc-tag-interaction-screen__content" />' + '</div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(details) {
            super(NfcTagInteractionScreen.Template.getTemplate());
            this.type = details.type;

            if (this.type === Interaction.NFC.WRITE_POSITION) {
                this._urlScheme = details.urlScheme;
            } else if (this.type === Interaction.NFC.WRITE_COMMAND || this.type === Interaction.NFC.WRITE_SCENE) {
                this._commandUrl = details.commandUrl; //TODO-thallth: should this info be stored on the tag?

                this._mainText = details.mainText;
                this._subText = details.subText;
            }

            this._nrOfInteraction = 0; // helper to prevent touch animation
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(() => {
                this.titleBar = this._getTitlebar();
                this.prependSubview(this.titleBar);
                this.elements = {
                    contentView: this.element.find(".nfc-tag-interaction-screen__content")
                };
                this.instructionView = new NfcInstructionView();
                this.appendSubview(this.instructionView, this.elements.contentView);
                this.instructionView.setIcon("resources/Images/ActiveMiniserver/NFC/icon-nfc-logo.svg");
                this.instructionView.setTitle(_('nfc.tag.touch'));
                this.instructionView.setSubMessage(_('nfc.tag.tipp'));
                var message = "";

                if (this.type === Interaction.NFC.ERASE) {
                    message = _('nfc.tag.touch.delete');
                } else if (this.type === Interaction.NFC.WRITE_LOCK) {
                    message = _('nfc.tag.touch.write-protect.title');
                    this.instructionView.setSubMessage(_('nfc.tag.touch.write-protect.message'));
                    this.instructionView.setSubMessageColor(Color.RED);
                } else if (this.type === Interaction.NFC.SHARE_MINISERVER) {
                    message = _('nfc.tag.touch.write-miniserver');
                } else if (this.type === Interaction.NFC.READ) {
                    message = _('nfc.tag.touch.read');
                } else if (this.type === Interaction.NFC.WRITE_POSITION) {
                    message = _('nfc.tag.touch.write-position');
                } else if (this.type === Interaction.NFC.WRITE_COMMAND) {
                    message = _('nfc.tag.touch.write-command');
                } else if (this.type === Interaction.NFC.WRITE_SCENE) {
                    message = _("nfc.tag.touch.write-scene");
                }

                this.instructionView.setMessage(message);
                this.instructionView.on("button-click", function () {
                    this.ViewController.navigateBack();
                    NavigationComp.showState(ScreenState.Nfc);
                }.bind(this));
            });
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(() => {
                switch (this.type) {
                    case Interaction.NFC.ERASE:
                        this._startErasing();

                        break;

                    case Interaction.NFC.WRITE_LOCK:
                        this._startWritelocking();

                        break;

                    case Interaction.NFC.READ:
                        this._nrOfRead = 0; // helper to prevent touch animation

                        this._startReading();

                        break;

                    case Interaction.NFC.WRITE_POSITION:
                    case Interaction.NFC.WRITE_COMMAND:
                    case Interaction.NFC.WRITE_SCENE:
                        this._write();

                        break;
                }
            });
        }

        viewDidAppear() {
            return super.viewDidAppear(...arguments).then(() => {
                this.titleBar.onLeftButtonTapped = function onLeftButtonTapped() {
                    if (!HD_APP) {
                        this.ViewController.navigateBack();
                    }

                    NavigationComp.showState(ScreenState.Nfc);
                    this.titleBar.onLeftButtonTapped = null;
                }.bind(this);
            })
        }

        viewWillDisappear() {
            if (this._stopListeningFn) {
                nfcService.stopListening(this._stopListeningFn);
                delete this._stopListeningFn;
            }

            return super.viewWillDisappear(...arguments);
        }

        viewDidDisappear() {
            this.titleBar.onLeftButtonTapped = null;
            return super.viewDidDisappear(...arguments);
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        getURL() {
            if (this.type === Interaction.NFC.ERASE) {
                return "EraseNfcTag";
            } else if (this.type === Interaction.NFC.WRITE_LOCK) {
                return "WritelockNfcTag";
            } else if (this.type === Interaction.NFC.SHARE_MINISERVER) {
                return "ShareMiniserverNfc";
            } else if (this.type === Interaction.NFC.READ) {
                return "ReadNfcTag";
            } else if (this.type === Interaction.NFC.WRITE_POSITION) {
                return "WriteNfcPosition";
            } else if (this.type === Interaction.NFC.WRITE_COMMAND) {
                return "WriteNfcCommand";
            } else if (this.type === Interaction.NFC.WRITE_SCENE) {
                return "WriteNfcScene";
            }
        }

        // TableViewDataSource Methods
        styleForTableView(tableView) {
            return HD_APP ? TableViewStyle.GROUPED : TableViewStyle.PLAIN;
        }

        numberOfSections(tableView) {
            return this._tag ? 1 : 0;
        }

        numberOfRowsInSection(section, tableView) {
            return 1;
        }

        cellTypeForCellAtIndex(section, row, tableView) {
            return GUI.TableViewV2.CellType.GENERAL;
        }

        contentForCell(cell, section, row, tableView) {
            return {
                title: _('show-details'),
                disclosureIcon: true
            };
        }

        didSelectCell(cell, section, row, tableView) {
            this._hideDetailsButton();

            this._showResults();
        }

        // Private methods
        _getTitlebar() {
            var titleBarConfig = {
                leftSide: TitleBarCfg.Button.CLOSE,
                style: "transparent"
            };
            var titleBar = new GUI.LxTitleBar(titleBarConfig);
            var text = "";

            if (this.type === Interaction.NFC.ERASE) {
                text = _('nfc.tag.erase');
            } else if (this.type === Interaction.NFC.WRITE_LOCK) {
                text = _('nfc.tag.write-protect');
            } else if (this.type === Interaction.NFC.SHARE_MINISERVER) {
                text = _('nfc.tag.write-miniserver');
            } else if (this.type === Interaction.NFC.READ) {
                text = _('nfc.tag.read');
            } else if (this.type === Interaction.NFC.WRITE_POSITION) {
                text = _('app-position.save');
            } else if (this.type === Interaction.NFC.WRITE_COMMAND) {
                text = _('command.save');
            } else if (this.type === Interaction.NFC.WRITE_COMMAND) {
                text = _("scenes.save");
            }

            titleBar.setTitleBarSideTexts(text);
            return titleBar;
        }

        _startErasing() {
            nfcService.erase().done(function success() {
                delete this._stopListeningFn;

                this._setSuccessIcon();

                this.instructionView.setTitle(_('nfc.tag.erase.successful'));
                this.instructionView.setMessage("");
                this.instructionView.startSuccessAnimation();
                this._nrOfInteraction++;

                this._startErasing();
            }.bind(this), function error(e) {
                var reason;
                delete this._stopListeningFn;

                this._setFailedIcon(); // e always return the translated string
                // as we can not be sure, if the nfc tag is write protected!

                /*if (e === _('nfc.tag.is-write-protected')) {
                    reason = _('nfc.tag.erase.failed.text')
                } else {
                    reason = _("nfc.tag.error.message");
                }*/


                this.instructionView.setTitle(_('nfc.tag.delete.error'));
                this.instructionView.setMessage(_("nfc.tag.error.message"));
                this.instructionView.startFailAnimation(true, true);
                this._nrOfInteraction++;

                this._startErasing();

                this._hideDetailsButton();
            }.bind(this), function notify(stopFn) {
                this._nrOfInteraction === 0 && this.instructionView.startTouchAnimation();
                this._stopListeningFn = stopFn;
            }.bind(this));
        }

        _startWritelocking() {
            nfcService.makeReadOnly().done(function success() {
                delete this._stopListeningFn;
                this.instructionView.setIcon("resources/Images/ActiveMiniserver/NFC/icon-nfc-locked.svg");
                this.instructionView.setTitle(_('nfc.tag.write-protect.successful'));
                this.instructionView.setMessage(_('nfc.tag.write-protect.successful-message'));
                this.instructionView.startSuccessAnimation();
                this._nrOfInteraction++;

                this._startWritelocking();
            }.bind(this), function error(e) {
                delete this._stopListeningFn;

                this._setFailedIcon();

                this.instructionView.setTitle(_('nfc.tag.write-protect.failed'));
                this.instructionView.setMessage(_("nfc.tag.error.message"));
                this.instructionView.startFailAnimation(true, true);
                this._nrOfInteraction++;

                this._startWritelocking();

                this._hideDetailsButton();
            }.bind(this), function notify(stopFn) {
                this._nrOfInteraction === 0 && this.instructionView.startTouchAnimation();
                this._stopListeningFn = stopFn;
            }.bind(this));
        }

        _write(message) {
            if (this._stopListeningFn) {
                nfcService.stopListening(this._stopListeningFn);
                delete this._stopListeningFn;
            }

            if (!message) {
                if (this.type === Interaction.NFC.WRITE_POSITION) {
                    message = [ndef.uriRecord(this._urlScheme), ndef.androidApplicationRecord(AppPackageName)];
                } else if (this.type === Interaction.NFC.WRITE_COMMAND || this.type === Interaction.NFC.WRITE_SCENE) {
                    message = [ndef.uriRecord(this._commandUrl), ndef.androidApplicationRecord(AppPackageName)];
                } else {
                    return;
                }
            } else {
                Debug.NFC && console.warn("---------------------");
                Debug.NFC && console.warn("NFC: message was already set (from error before) --> " + JSON.stringify(message));
                Debug.NFC && console.warn("---------------------");
            }
            /*text = CryptoJS.AES.encrypt(text, NFC_PRIVATE_KEY).toString();
              var message = [
             ndef.uriRecord("loxonenfc://"),
             ndef.record(ndef.TNF_EXTERNAL_TYPE, "lx/cipher", null, text)
             ];*/


            nfcService.write(message).done(function success(tag) {
                tag.ndefMessage = message; // to show the correct details!

                this._tag = tag;
                delete this._stopListeningFn;

                this._setSuccessIcon();

                if (this.type === Interaction.NFC.WRITE_POSITION) {
                    this.instructionView.setTitle(_('app-position.save.successful'));
                } else if (this.type === Interaction.NFC.WRITE_COMMAND) {
                    this.instructionView.setTitle(_('command.save.successful'));
                } else if (this.type === Interaction.NFC.WRITE_SCENE) {
                    this.instructionView.setTitle(_("scene.save.successful"));
                } else {
                    this.instructionView.setTitle(_('nfc.tag.write.successful'));
                }

                this.instructionView.setMessage("");
                this.instructionView.startSuccessAnimation();
                this._nrOfInteraction++;

                this._write(message);

                this._addDetailsButton();
            }.bind(this), function error(e) {
                delete this._stopListeningFn;
                Debug.NFC && console.warn("---------------------");
                Debug.NFC && console.warn("NFC: write error message --> " + JSON.stringify(e));
                Debug.NFC && console.warn("---------------------");

                this._setFailedIcon();

                if (e === _('nfc.tag.is-write-protected')) {
                    this.instructionView.setTitle(_('nfc.tag.write.failed') + ". " + _('nfc.tag.write-protect.active'));
                    this.instructionView.setMessage(_('nfc.tag.write-protect.try-other-tag'));
                } else {
                    this.instructionView.setTitle(_('nfc.tag.write.failed'));
                    this.instructionView.setMessage(e || "");
                }

                this.instructionView.startFailAnimation(true);
                this._nrOfInteraction++;

                this._write(message);

                this._hideDetailsButton();
            }.bind(this), function notify(stopFn) {
                this._nrOfInteraction === 0 && this.instructionView.startTouchAnimation();
                this._stopListeningFn = stopFn;
            }.bind(this));
        }

        _startReading() {
            if (this._stopListeningFn) {
                nfcService.stopListening(this._stopListeningFn);
                delete this._stopListeningFn;
            }

            nfcService.read().done(function success(tag) {
                this._tag = tag;
                delete this._stopListeningFn;

                this._setSuccessIcon();

                this.instructionView.setTitle(_('nfc.tag.read.successful'));
                this._nrOfRead++;

                this._startReading();

                if (this._isTagEmpty(tag)) {
                    this.instructionView.startSuccessAnimation(false);
                    this.instructionView.setMessage(_('nfc.tag.empty'));
                } else if (this._isLoxoneTag(tag)) {
                    this.instructionView.startSuccessAnimation(true);
                    this.instructionView.setMessage("");

                    this._showResults();
                } else {
                    this.instructionView.startSuccessAnimation(false);
                    this.instructionView.setMessage(_('nfc.tag.not-loxone'));
                }
            }.bind(this), function error(e) {
                delete this._stopListeningFn;

                this._setFailedIcon();

                this.instructionView.setTitle(_('nfc.tag.read.failed'));
                this.instructionView.setMessage(_('nfc.tag.error.message'));
                this.instructionView.startFailAnimation(true);
                this._nrOfRead++;

                this._startReading();

                this._hideResults();

                this._hideDetailsButton();
            }.bind(this), function notify(stopFn) {
                this._nrOfRead === 0 && this.instructionView.startTouchAnimation();
                this._stopListeningFn = stopFn;
            }.bind(this));
        }

        _addDetailsButton() {
            if (!this.detailsTable) {
                this.detailsTable = new GUI.TableViewV2(this, this);
                this.appendSubview(this.detailsTable);
                this.detailsTable.reload();
            } else {
                this.detailsTable.getElement().css("display", "");
            }
        }

        _hideDetailsButton() {
            if (this.detailsTable) {
                this.detailsTable.getElement().css("display", "none");
            }
        }

        _showResults() {
            // show the details after a short timeout.
            if (!this._tagContentView) {
                this._tagContentView = new NfcTagContentView();
                this.appendSubview(this._tagContentView, this.elements.contentView);
            }

            this._tagContentView.getElement().css("opacity", 0);

            this._tagContentView.updateWithTag(this._tag); // update after the success animation is finished..


            setTimeout(function () {
                var tagContentName = this._tagContentView.getTagContentName();

                if (tagContentName) {
                    this.titleBar.setTitleBarSideTexts(tagContentName);
                }

                this._tagContentView.getElement().velocity({
                    translateZ: 0,
                    // Force HA by animating a 3D property
                    opacity: [1, 0]
                }, {
                    duration: 500
                });
            }.bind(this), 750);
        }

        _hideResults() {
            this._tagContentView.getElement().css("opacity", 0);
        }

        _isTagEmpty(tag) {
            if (!tag.ndefMessage || tag.ndefMessage.length === 0) {
                return true;
            }

            var messages = tag.ndefMessage,
                message;

            for (var i = 0; i < messages.length; i++) {
                message = messages[i];

                if (message.tnf !== ndef.TNF_EMPTY) {
                    return false;
                }
            }

            return true;
        }

        _isLoxoneTag(tag) {
            var messages = tag.ndefMessage,
                message;

            for (var i = 0; i < messages.length; i++) {
                message = messages[i];

                if (message.tnf === ndef.TNF_WELL_KNOWN) {
                    if (util.isType(message, ndef.TNF_WELL_KNOWN, ndef.RTD_URI)) {
                        var url = ndef.uriHelper.decodePayload(message.payload);

                        if (UrlHelper.isLoxoneURL(url)) {
                            return true;
                        }
                    }
                }
            }

            return false;
        }

        _setFailedIcon() {
            this.instructionView.setIcon(Icon.NfcInteraction.FAIL, 'failed');
        }

        _setSuccessIcon() {
            this.instructionView.setIcon(Icon.NfcInteraction.SUCCESS, 'success');
        }

    };
});
