'use strict';

define("JalousieControl", [
    "Control",
    "JalousieControlEnums",
    "IconLib"
], function (
    Control,
    JalousieControlEnums,
    {
        default: Icons
    }
) {
    return class JalousieControl extends Control {
        constructor() {
            super(...arguments);
        }

        getLegacyScreens() {
            return Object.values(JalousieControlEnums.ScreenState);
        }

        getCellType(forCentral) {
            return forCentral ? "JalousieControlStateCell" : ControlCard.BASE;
        }

        getControlTypeName() {
            var type = this.type; // The config may have additional subtypes!

            if (this.details.hasOwnProperty("type")) {
                if (window.hasOwnProperty("Control") && this.details.type === ControlSubTypes.ROOF_SHADE) {
                    type = "roofShade";
                }
            }

            return this._getControlTypeNameForType(type);
        }

        isJalousie() {
            if (!this.isSupported()) {
                return true;
            }

            if (this.details.isAutomatic) {
                return this.details.animation === JalousieControlEnums.AnimationType.BLINDS || this.details.animation === JalousieControlEnums.AnimationType.SHUTTERS || this.details.animation === JalousieControlEnums.AnimationType.NOT_SUPPORTED;
            } else {
                return this.details.animation === JalousieControlEnums.AnimationType.BLINDS || this.details.animation === JalousieControlEnums.AnimationType.SHUTTERS;
            }
        }

        isCurtain() {
            if (!this.isSupported()) {
                return false;
            }

            if (this.details.isAutomatic) {
                return this.details.animation === JalousieControlEnums.AnimationType.CURTAIN_BOTH_SIDES || this.details.animation === JalousieControlEnums.AnimationType.CURTAIN_LEFT || this.details.animation === JalousieControlEnums.AnimationType.CURTAIN_RIGHT;
            } else {
                return this.details.animation === JalousieControlEnums.AnimationType.CURTAIN_BOTH_SIDES || this.details.animation === JalousieControlEnums.AnimationType.CURTAIN_LEFT || this.details.animation === JalousieControlEnums.AnimationType.CURTAIN_RIGHT;
            }
        }

        isAwning() {
            if (!this.isSupported()) {
                return false;
            }

            return this.details.animation === JalousieControlEnums.AnimationType.AWNING;
        }

        isSupported() {
            var animationTypes = Object.keys(JalousieControlEnums.AnimationType);

            for (var i = 0; i < animationTypes.length; i++) {
                if (this.details.animation === JalousieControlEnums.AnimationType[animationTypes[i]]) {
                    return true;
                }
            }

            Debug.Control.Jalousie && console.warn("Unsupported animationType: " + this.details.animation);
            return false;
        }

        canShade() {
            return this.isJalousie() || this.isCurtain();
        }

        getOpenIcon(circled) {
            if (this.isCurtain() && circled) {
                return Icon.Gate.Circled.DOUBLE_OPEN;
            } else if (this.isJalousie() ) {
                return circled ? Icon.Jalousie.UP : Icon.Jalousie.Arrows.UP;} else if (this.isAwning()) {
                return circled ? Icon.Jalousie.AWNING_IN_CIRCLED : Icon.Jalousie.AWNING_IN;
            } else {
                switch (this.details.animation) {
                    case JalousieControlEnums.AnimationType.CURTAIN_LEFT:
                        return circled ? Icon.Jalousie.LEFT : Icon.Gate.Arrows.DOUBLE_OPEN;

                    case JalousieControlEnums.AnimationType.CURTAIN_RIGHT:
                        return circled ? Icon.Jalousie.RIGHT : Icon.Gate.Arrows.DOUBLE_OPEN;

                    default:
                        return circled ? Icon.Gate.Circled.DOUBLE_OPEN : Icon.Gate.Arrows.DOUBLE_OPEN;
                }
            }
        }

        getCloseIcon(circled) {
            if (this.isCurtain() && circled) {
                return Icon.Gate.Circled.DOUBLE_CLOSE;
            } else if (this.isJalousie()) {
                return circled ? Icon.Jalousie.DOWN : Icon.Jalousie.Arrows.DOWN;
            } else if (this.isAwning()) {
                return circled ? Icon.Jalousie.AWNING_OUT_CIRCLED : Icon.Jalousie.AWNING_OUT;
            } else {
                switch (this.details.animation) {
                    case JalousieControlEnums.AnimationType.CURTAIN_LEFT:
                        return circled ? Icon.Jalousie.RIGHT : Icon.Gate.Arrows.DOUBLE_CLOSE;

                    case JalousieControlEnums.AnimationType.CURTAIN_RIGHT:
                        return circled ? Icon.Jalousie.LEFT : Icon.Gate.Arrows.DOUBLE_CLOSE;

                    default:
                        return circled ? Icon.Gate.Circled.DOUBLE_CLOSE : Icon.Gate.Arrows.DOUBLE_CLOSE;
                }
            }
        }

        getReactOpenIcon() {
            if (this.isCurtain()) {
                return Icons.Open;
            } else if (this.isJalousie()) {
                return Icons.ArrowUp;
            } else if (this.isAwning()) {
                return Icons.AwningRetract;
            } else {
                switch (this.details.animation) {
                    case JalousieControlEnums.AnimationType.CURTAIN_LEFT:
                        return Icons.ArrowLeft;
                    case JalousieControlEnums.AnimationType.CURTAIN_RIGHT:
                        return Icons.ArrowRight;
                    default:
                        return Icons.Open;
                }
            }
        }

        getReactCloseIcon() {
            if (this.isCurtain()) {
                return Icons.Close;
            } else if (this.isJalousie()) {
                return Icons.ArrowDown;
            } else if (this.isAwning()) {
                return Icons.AwningExtend;
            } else {
                switch (this.details.animation) {
                    case JalousieControlEnums.AnimationType.CURTAIN_LEFT:
                        return Icons.ArrowRight;
                    case JalousieControlEnums.AnimationType.CURTAIN_RIGHT:
                        return Icons.ArrowLeft;
                    default:
                        return Icons.Close;
                }
            }
        }

        getShadingIcon() {
            if (this.isJalousie()) {
                return Icon.Jalousie.SHADE_JALOUSIE;
            } else {
                return Icon.Jalousie.SHADE_CURTAIN;
            }
        }

        getButton0(states) {
            if (states && !states.configurationNeeded) {
                return {
                    reactIcon: this.getReactOpenIcon(),
                    iconSrc: this.getOpenIcon(true),
                    command: Commands.JALOUSIE.FULL_UP,
                    disabled: states.locked || states.safetyActive
                };
            }
        }

        getButton1(states) {
            if (states && !states.configurationNeeded) {
                return {
                    reactIcon: this.getReactCloseIcon(),
                    iconSrc: this.getCloseIcon(false),
                    command: Commands.JALOUSIE.FULL_DOWN,
                    disabled: states.locked || states.safetyActive
                };
            }
        }

        getFriendlyValueName(event, operator) {
            var result = "";

            switch (event.data.stateName) {
                case "position":
                    if (event.value === 0 && event.operator === 1) {
                        result = this.name + " " + _("controls.jalousie.up.full").toLowerCase();
                    } else if (event.value === 1 && event.operator === 1) {
                        result = this.name + " " + _("controls.jalousie.curtain.down.full").toLowerCase();
                    } else {
                        result = this.name + " " + event.data.stateTitle + " " + operator + " " + (event.value * 100.0).toFixed(0) + " %";
                    }

                    break;

                case "autoActive":
                    result = this.name + " " + event.data.stateTitle + " " + (event.value ? _("active") : _("inactive")).toLowerCase();
                    break;

                default:
                    return "";
            }

            return result;
        }

    };
});
