'use strict';

define(["ControlActionBaseScreen", "ControlNoteView", "LxComponents"], function (ControlActionBaseScreen, ControlNoteView, {
    App, LxControlHistoryScreen
}) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var DESELECT_TIMEOUT = 60 * 2 * 1000;
    return class ControlActionScreen extends ControlActionBaseScreen {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return '<div class="control-action-screen__content">' + '   <div class="content__note-box" />' + '   <div class="content__info-base content__info">' + '       <div class="info__title-lbl" />' + '       <div class="info__message-lbl" />' + '   </div>' + '   <div class="content__info-base content__custom-info" />' + '   <div class="content__action-view" />' + '</div>' + '<div class="control-action-screen__details-btn">' + '   <div class="details-btn__text">' + _('more') + '</div>' + '   ' + ImageBox.getResourceImageWithClasses(Icon.Buttons.MORE, "details-btn__icon") + '<div class="details-btn__click-area"></div>' + '</div>' + '</div>';
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor() {
            super(...arguments); // used to avoid reloading the table while a cell is pressed.

            Object.assign(this, ContextMenuHandler.Mixin);
            this._cellInUse = false;
            this._shouldReload = false;
            this.customContentVisible = true;
        }

        viewDidLoad() {
            var promises = [super.viewDidLoad(...arguments)];
            this.element.append(ControlActionScreen.Template.getTemplate());
            this.elements.infoBox = this.element.find(".content__info").hide();
            this.elements.customInfoBox = this.element.find(".content__custom-info").hide();
            this.elements.noteBox = this.element.find(".content__note-box");
            this.elements.infoTitleLbl = this.element.find(".content__info .info__title-lbl");
            this.elements.infoMessageLbl = this.element.find(".content__info .info__message-lbl");
            this.elements.detailsBtn = this.element.find(".control-action-screen__details-btn");
            this.elements.detailsBtnArea = this.element.find(".details-btn__click-area");
            this.elements.content = this.element.find(".control-action-screen__content");
            this.elements.contentPlaceholder = this.element.find(".content__action-view"); // the details button should be easier to hit on HD.

            this.elements.detailsBtn.toggleClass("control-action-screen__details-btn--hd", HD_APP);
            var detailsButton = new GUI.LxRippleButton(this.elements.detailsBtnArea);
            this.addToHandledSubviews(detailsButton);
            detailsButton.on(GUI.LxRippleButton.CLICK_EVENT, this.handleDetailsBtn.bind(this));
            detailsButton.setDarkTheme(true);

            this._checkDetailsButton();

            this.setUpComfortUI(true);
            this.toggleStateIcons(false); // hide icons, if needed, they will be added again..

            this.setTitle(this.getTitle()); // set the info view.

            this.unregisteRuleListenerFn = AutomaticDesignerComponent.registerForRuleChangeNotify(function () {
                this.updateContextMenu(this.control.getStates());
            }.bind(this));
            return Q.all(promises).then(() => {
                var view = this.getCustomInfoView();

                if (view) {
                    this.setCustomInfoView(view);
                } // if the control has notes, present them (Ignore for subcontrols, ie uuidParent is set)
                if (this.control.hasControlNotes && !this.control.uuidParent) {
                    promises.push(this._createAndAddControlNoteView());
                } else {
                    this.elements.noteBox.hide();
                }
    
                this.tableViewDataSource = tableViewDataSource(null, this.getActionTableViewCellType(), this);
                this.tableViewDelegate = tableViewDelegate(null, this);
                this.actionTableView = this.getActionTableView();
    
                if (this.actionTableView) {
                    this.actionTableView.getElement().addClass("action-view__action-table");
                    this.appendSubview(this.actionTableView, this.getActionTablePlaceholder());
                } else {
                    // Delete the dataSource and Delegate if we don't have an actionTableView that takes use of them
                    delete this.tableViewDataSource;
                    delete this.tableViewDelegate;
                }
    
                if (this.control.isGrouped()) {
                    promises.push(this._initControlSelectionTable());
                } // locking
    
    
                this.lockTableView = this._loadLockTableView();
                this.lockTableView.getElement().addClass("content__lock-table-view");
                this.appendSubview(this.lockTableView, this.getActionTablePlaceholder()); // linked controls
    
                if (this.control.links && this.control.links.length > 0 && this._shouldShowLinkedControls()) {
                    this.linkedCtrlsTableView = this._loadLinkedCtrlsTableView();
                    this.linkedCtrlsTableView.getElement().addClass("content__linked-controls-table-view");
                    this.element.addClass("control-action-screen--has-linked-controls");
                    this.appendSubview(this.linkedCtrlsTableView, this.getActionTablePlaceholder());
                }
    
                if (!!this.details.linked) {
                    // add a class to ensure a black background, otherwise it'd be gray due to modal view.
                    this.element.addClass("control-action-screen--linked");
                }
            });
        }

        viewWillAppear() {
            if (this.control.isGrouped() && this.wasAlreadyShown()) {
                this._updateControlSelection();
            }

            var promises = [super.viewWillAppear(...arguments)];

            if (this.linkedCtrlsTableView) {
                promises.push(this.linkedCtrlsTableView.reload());
            }

            this.elements.content.scrollTop(this._scrollTop);
            this._expandButton && this.toggleSubview(this._expandButton, this._shouldAddExpandButton());
            return Q.all(promises);
        }

        viewWillDisappear() {
            this._clearDeselectionTimeout(true);

            var promise = super.viewWillDisappear(...arguments);
            this._scrollTop = this.elements.content.scrollTop();
            return promise;
        }

        /**
         * Return the upper screen buttons ("<" and "•••" are default buttons")
         * @return {*}
         */
        getScreenButtons() {
            var screenButtons = super.getScreenButtons(...arguments);

            if (this._shouldAddExpandButton()) {
                this._expandButton = this._prepareButton(Icon.Buttons.EXPAND, "screen__button-right button-right__expand", this.expandContent.bind(this));
                screenButtons.splice(1, 0, this._expandButton);
                this.hideSubview(this._expandButton);
                this.element.addClass("control-action-screen--has-expand-button");
            }
            
            let entryColorClass = ""
            switch (this?.states?.messageCenterEntries?.[0]?.severity) {
                case MessageCenterHelper.SEVERITY_LEVEL.INFO:
                    entryColorClass = "message__center--info"
                    break;
                case MessageCenterHelper.SEVERITY_LEVEL.WARNING:
                    entryColorClass = "message__center--warning"
                    break;
                case MessageCenterHelper.SEVERITY_LEVEL.IMPORTANT:
                    entryColorClass = "message__center--important"
                    break;
                case MessageCenterHelper.SEVERITY_LEVEL.CRITICAL:
                    entryColorClass = "message__center--critical"
                    break;
                default:
                    break;
            }
            this._messageCenterButton = this._prepareButton(Icon.MESSAGE_CENTER.HEALTH_CIRCLED, "screen__button-right button-right__message-center", this.openMessageCenterEntry.bind(this), false, entryColorClass);
            screenButtons.splice(1, 0, this._messageCenterButton);

            this.hideSubview(this._messageCenterButton);
            return screenButtons;
        }

        _shouldAddExpandButton() {
            return AMBIENT_MODE;
        }

        /**
         * Override to hide state icons. Beware that the private _shouldDisplayStateIcons might not call this method,
         * e.g. when the control is locked - stateIcons will always be shown.
         * @returns {boolean}
         */
        shouldDisplayStateIcons() {
            return !this.control.isGrouped();
        }

        onIconTapped() {
            if (this.shouldShowDetailsButton()) {
                this.handleDetailsBtn();
            }
        }

        shouldShowMessageCenterButton() {
            return true;
        }

        destroy() {
            if (this.control.isGrouped()) {
                this.control.updateGroupSelection(null);
            }

            this.unregisteRuleListenerFn();
            super.destroy();
        }

        _updateMessageCenterButton(states) {
            if (!this._messageCenterButton) {
                developerAttention("To Implement!");
                return;
            }
            var promises = []; // Hide the button, if the control has no referenced messageCenter entries

            if (!states.messageCenterEntries || !states.messageCenterEntries.length) {
                promises.push(this.hideSubview(this._messageCenterButton));
                this.element.removeClass("control-action-screen--has-system-state-message");
            } else {
                // Get the entries matching image and set it as the buttons image
                this._messageCenterButton.iconSrc = ImageBox.getResourceImageWithClasses(MessageCenterHelper.getIconForSeverityEntry(states.messageCenterEntries[0], true, true), "button__icon");
                this._messageCenterButton.iconColor = MessageCenterHelper.getColorForSeverityEntry(states.messageCenterEntries[0])
                promises.push(this.showSubview(this._messageCenterButton));
                this.activeMessageCenterEntry = states.messageCenterEntries[0];
                this.element.addClass("control-action-screen--has-system-state-message");
            }

            return Q.all(promises);
        }

        receivedStates(states) {
            var promises = [super.receivedStates(...arguments) || Q.resolve()];
            checkLegacyDrawingBug(); // On iOS 9 Devices icons disappeared

            var stateInfo = states.stateInfo; // behind this attribute there is a getter, that might be complex, only call once.
            // Only handle the MessageCenter if the control is able to send messages to the MessageCenter hence states exists

            if (this.control.states && this.shouldShowMessageCenterButton()) {
                promises.push(this._updateMessageCenterButton(states));
            }

            this.setTintColor(states.stateTintColor);

            if (typeof states.stateText === "string") {
                this.setSubtitle(states.stateTextForContent);
            } else {
                this.setSubtitle(null);
            }

            var displayStateIcons = this._shouldDisplayStateIcons(states);

            this.toggleStateIcons(displayStateIcons);

            if (displayStateIcons) {
                this._updateIcon(states);

                this.setSmallIcon(this.getSmallIcon(states));
            }

            if (states.presenceSimulation && states.presenceSimulation.active) {
                this.setSmallIcon({
                    iconSrc: Icon.PRESENCE_SIMULATION,
                    color: window.Styles.colors.blue
                });
                promises.push(this.setInfo(_('presence-simulation'), states.presenceSimulationText, window.Styles.colors.blue));
            } else if (stateInfo) {
                promises.push(this.setInfo(stateInfo.title, stateInfo.message, stateInfo.color));
            } else {
                promises.push(this.setInfo()); // -> hide
            } // Commands Tables --> don't update while a cell is in use.


            if (this._cellInUse) {
                this._shouldReload = true;
            } else {
                promises.push(this._updateCommandsTable(states));
            }

            this.updateContextMenu(states);
            this.states = states;
            return Q.all(promises);
        }

        updateStructureChanges() {
            var promises = [];
            this.setTitle(this.getTitle());

            if (this.control.isGrouped()) {
                this._reloadControlSelection();
            }

            if (this._shouldDisplayStateIcons(this.states)) {
                this._updateIcon(this.states); // to update the icon, it may have changed..

            }

            promises.push(this._updateCommandsTable(this.control.getStates())); // to reload eg. statistic

            promises.push(super.updateStructureChanges(...arguments));
            return Q.all(promises);
        }

        updateContextMenu(states) {
            this._contextMenuOptions = this.getContextMenuOptions(states); // Always append them last

            this._contextMenuOptions = this._contextMenuOptions.concat(this._getAutomaticDesignerRelatedMenuOptions()); // Hide the right button if we don't have a context menu

            this.rightButton && this.toggleSubview(this.rightButton, !!this._contextMenuOptions && this._contextMenuOptions.length);
        }

        // Screen Methods
        getRightIcon() {
            return Icon.Buttons.MORE2;
        }

        /**
         * Returns options visible in the Context menu
         * @return {Array}
         */
        getContextMenuOptions(states) {
            var options = []; // STATISTIC

            if (!!this.control.statistic) {
                options.push({
                    title: _('statistics.button.title'),
                    action: this.showStatisticForOutput.bind(this, 0)
                });
            } // EXPERT MODE LIGHT


            if (this.getShowExpertModeLight()) {
                options.push({
                    title: _('adopt-ui-settings'),
                    action: function () {
                        NavigationComp.showControlSettings(this.control, true);
                    }.bind(this)
                });
            } // EXPERT MODE


            if (this.getShowExpertMode()) {
                options.push({
                    title: _('expert-settings'),
                    action: function () {
                        NavigationComp.showControlSettings(this.control);
                    }.bind(this)
                });
            } // CONTROL NOTES


            if (this.control.hasControlNotes) {
                options.push({
                    title: _("note"),
                    action: function () {
                        NavigationComp.showControlNotes(this.control);
                    }.bind(this)
                });
            } // PRESET


            var presetEntry = SandboxComponent.getPresetContextMenuEntryFor(this.control);
            presetEntry && options.push(presetEntry); // CONTROL LOCKING

            if (this._isLockable(states)) {
                options.push(this._getLockContextMenuEntry());
            }

            return options;
        }

        rightAction(ev, hammerEvent) {
            this._showContextMenu(this._contextMenuOptions, this.control.getName(), hammerEvent.currentTarget);
        }

        // ControlActionScreen Methods

        /**
         * Required as regular state icons from the state container might not be proper. E.g. a pool cover screen
         * does not want to use the state containers state icons.
         * @param states
         * @return {string}
         */
        getStateIcon(states) {
            return states.stateIconForContent;
        }


        getStateIconColor(states) {
            return states.stateIconColor || this.getStateColor(states)
        }

        /**
         * Required as the regular state color from the state container might not be proper. E.g. a pool cover
         * screen does not want to use the state containers state color.
         * @param states
         * @return {string}
         */
        getStateColor(states) {
            return states.stateColorForContent;
        }

        /**
         * Required as the regular small icon from the state container might not be proper. E.g. a pool cover screen
         * does not want to use the state containers small state icon.
         * @param states
         * @return {string}
         */
        getSmallIcon(states) {
            return states.stateIconSmallForContent;
        }

        getActionTablePlaceholder() {
            return this.elements.contentPlaceholder;
        }

        getActionTableView() {
            return null;
        }

        getActionTableViewCellType() {
            return GUI.TableViewV2.CellType.GENERAL;
        }

        /**
         * return the table content which will be shown eg. in ComfortMode Action Screen
         * @param states can be used to prepare the table (eg. light scenes)
         */
        getTableContent(states) {
            // override and add sections as needed..
            var content = [],
                actionSections = this.getActionSections(states),
                stateSections = this.getStateSections(states),
                statesFirst = this.getStatesFirst(states);
            !statesFirst && actionSections.forEach(function (section) {
                content.push(section);
            });
            stateSections.forEach(function (section) {
                // there may be more than one section.
                content.push(section);
            });
            statesFirst && actionSections.forEach(function (section) {
                content.push(section);
            });
            return content;
        }

        /**
         * If true, the states will be shown before the actions. E.g. Hourcounter
         * @return {boolean}
         */
        getStatesFirst(states) {
            return false;
        }

        setStateIconFill(fillColor) {
            if (this.elements.stateIcon) {
                GUI.animationHandler.setCssAttr(this.elements.stateIcon, 'fill', fillColor || "");
            }
        }

        /**
         * sets info title/text in the info box
         * @param title
         * @param message
         * @param [bgColor]
         */
        setInfo(title, message, bgColor) {
            var showInfo = false;
            var messageLblText = "",
                titleLblText = "";

            if (typeof title === "string" && title.length > 0) {
                titleLblText = title;
                showInfo = true;
            }

            if (typeof message === "string" && message.length > 0) {
                messageLblText = message;
                showInfo = true;
            }

            return GUI.animationHandler.schedule(function () {
                if (showInfo) {
                    this.elements.infoBox.css("display", "");
                } else {
                    this.elements.infoBox.hide();
                }

                this.elements.infoBox.css("background-color", bgColor ? applyAlphaChannelToColorString(bgColor, 0.24) : "");
                this.elements.infoTitleLbl.css("color", bgColor || "");
                this.elements.infoMessageLbl.css("color", bgColor || "");
                this.elements.infoTitleLbl.text(titleLblText);
                this.elements.infoMessageLbl.text(messageLblText);
                this.element.toggleClass("control-action-screen--info-shown", showInfo);
            }.bind(this));
        }

        /**
         * adds a subview to the info box (eg. IRC timeline)
         * @param view
         */
        setCustomInfoView(view) {
            this.customInfoView && this.removeSubview(this.customInfoView);
            GUI.animationHandler.schedule(function () {
                this.elements.customInfoBox.empty();
            }.bind(this));
            this.customInfoView = view;

            if (view) {
                GUI.animationHandler.setCssAttr(this.elements.customInfoBox, "display", "");
                this.appendSubview(this.customInfoView, this.elements.customInfoBox);
            } else {
                GUI.animationHandler.hide(this.elements.customInfoBox);
            }

            GUI.animationHandler.toggleCssClass(this.element, "control-action-screen--custom-info-view-shown", !!view);
            this.toggleCustomInfoView(this.customContentVisible);
        }

        toggleCustomInfoView(visible) {
            if (visible) {
                GUI.animationHandler.setCssAttr(this.elements.customInfoBox, "display", "");
            } else {
                GUI.animationHandler.hide(this.elements.customInfoBox);
            }

            this.toggleSubview(this.customInfoView, visible);
            GUI.animationHandler.toggleCssClass(this.element, "control-action-screen--custom-info-view-shown", visible);
        }

        /**
         * This method can be overwritten, if a view is returned it will be shown inside the customInfoBox!
         * @return {null}
         */
        getCustomInfoView() {
            return null;
        }

        /**
         * Maybe the name needs to be adopted.
         */
        getTitle() {
            return this.control.getName();
        }

        /**
         * Calling this method ensures that the custom info view is shown before the default info view. By default
         * the normal info view is shown first, afterwards the custom info view is shonw.
         */
        swapInfoViews() {
            this.elements.customInfoBox.insertBefore(this.elements.infoBox);
        }

        // TableView DataSource Methods
        styleForTableView() {
            return TableViewStyle.TRANSLUCENT;
        }

        shouldShowDetailsButton() {
            return this.control.isGrouped() && this.control.getSelectedControlUuids().length === 1;
        }

        openMessageCenterEntry() {
            if (this.activeMessageCenterEntry) {
                App.navigationRef.navigate("MessageDetailScreen", {entry: this.activeMessageCenterEntry});
            }
        }

        expandContent() {
            NavigationComp.showControlContent(this.control, null, null, {isInAmbientMode: false})
        }

        /**
         * This method is called e.g. when the lock state changed, it is used to hide/show customized content added
         * by the subclasses. controlActionScreen also sets "customContentVisible" property.
         * @param visible
         */
        setCustomContentVisible(visible) {
            // to be implemented by sublasses that add custom content (e.g. the remoteControl)
            this.customInfoView && this.toggleSubview(this.customInfoView, visible);
        }

        // Private Methods

        /**
         * This private method is used to intervein to force state icons when the control is locked.
         * @param states
         * @returns {*}
         * @private
         */
        _shouldDisplayStateIcons(states) {
            var showIcons = false;

            if (states.universalIsLocked) {
                showIcons = true;
            } else {
                showIcons = this.shouldDisplayStateIcons(states);
            }

            return showIcons;
        }

        _checkStructureChanges() {
            // handle updating the control notes view (if already shown, it'll update itself.
            if (this.control.hasControlNotes && !this.controlNoteView) {
                // had no control note before, but now there is one.
                this.elements.noteBox.show();

                this._createAndAddControlNoteView();
            } else if (!this.control.hasControlNotes && this.controlNoteView) {
                // had a control not view, but now there is none anymore
                this.removeSubview(this.controlNoteView);
                this.controlNoteView = null;
                this.elements.noteBox.hide();
            } // The Context menu is home for the Statistics and the Help text which both can be activated/deactivated via Expert mode
            // We need to update the context menu if the user makes such changes


            this.updateContextMenu(this.control.getStates());

            super._checkStructureChanges(...arguments);
        }

        _updateIcon(states) {
            var stateIcon = this.getStateIcon(states);

            if (!stateIcon) {
                stateIcon = this.control.getIcon();
            }

            this.setIcon(stateIcon); // Also set the IconColor or the icon will be white til the next state update

            this.setIconColor(this.getStateIconColor(states));
        }

        _initControlSelectionTable() {
            var data = this._generateControlSelectionData();

            this._controlSelectionDataSource = tableViewDataSource(data, GUI.TableViewV2.CellType.GENERAL, this);
            this._controlSelectionDelegate = tableViewDelegate(data, this);
            this.controlSelectionTableView = new GUI.TableViewV2(this._controlSelectionDataSource, this._controlSelectionDelegate);
            return this.appendSubview(this.controlSelectionTableView, this.getActionTablePlaceholder()).then(function () {
                return this.controlSelectionTableView.reload();
            }.bind(this));
        }

        _generateControlSelectionData() {
            var allSelected = this.control.isControlSelected(),
                section0 = {
                    rows: []
                },
                compareOptions = {
                    numeric: true,
                    ignorePunctuation: true
                };
            this.control.forEachSubControl(function (ctrl) {
                section0.rows.push({
                    content: this.getControlSelectionCellContent(this.control, ctrl, allSelected),
                    type: this.getControlSelectionCellType(ctrl, allSelected),
                    action: this._updateControlSelection.bind(this, ctrl)
                });
            }.bind(this)); // sort by room and name

            section0.rows.sort(function (a, b) {
                var res = 0;
                var ctrlA = ActiveMSComponent.getControlByUUID(a.content.controlUuid),
                    ctrlB = ActiveMSComponent.getControlByUUID(b.content.controlUuid);

                if (ctrlA.room && ctrlB.room) {
                    res = ctrlA.getRoom().name.toUpperCase().localeCompare(ctrlB.getRoom().name.toUpperCase(), PlatformComponent.getLanguage(), compareOptions);
                } else if (ctrlA.room) {
                    res = -1;
                } else if (ctrlB.room) {
                    res = 1;
                }

                if (res === 0) {
                    res = ctrlA.getName().toUpperCase().localeCompare(ctrlB.getName().toUpperCase(), PlatformComponent.getLanguage(), compareOptions);
                }

                return res;
            });

            if (section0.rows.length > 0) {
                return [section0];
            } else {
                return [];
            }
        }

        _checkDetailsButton() {
            var show = this.shouldShowDetailsButton();
            GUI.animationHandler.schedule(function () {
                this.element.toggleClass("control-action-screen--details-button-shown", show);

                if (show) {
                    this.elements.detailsBtn.css("display", "");
                } else {
                    this.elements.detailsBtn.hide();
                }
            }.bind(this));
        }

        _updateCommandsTable(states) {
            var promises = [true],
                needsReload = false; // intervein if the control is locked, in that case only unlocking may be possible

            this._updateLockTable(states);

            this.customContentVisible = !states.universalIsLocked;
            this.setCustomContentVisible(!states.universalIsLocked);

            if (states.universalIsLocked) {
                // reset table content
                this.tableContent = [];
                this.lastTableContent = null; // important, otherwise there'd be no reload when it's visible again.

                if (this.tableViewDataSource) {
                    needsReload |= this.tableViewDataSource.update(this.tableContent);
                }

                if (this.tableViewDelegate) {
                    needsReload |= this.tableViewDelegate.update(this.tableContent);
                }

                if (this.actionTableView) {
                    this.hideSubview(this.actionTableView);
                    needsReload && promises.push(this.actionTableView.reload());
                }

                return Q.all(promises);
            }

            return Q(this.getTableContent(states)).then(function _updateCommandsTableContentAcquired(tableContent) {
                var prms = [true];

                if (!this.actionTableView) {
                    return Q.resolve();
                }

                this.tableContent = tableContent;
                var additionalSection = this.getAdditionalSection(states);
                additionalSection && this.tableContent.push(additionalSection); // Avoiding multiple view lifecycle is prevented by the functions itself, so we don't care

                if (this.tableContent.length === 0) {
                    this.hideSubview(this.actionTableView);
                } else {
                    prms.push(this.showSubview(this.actionTableView));
                    this.tableViewDataSource.update(this.tableContent);
                    this.tableViewDelegate.update(this.tableContent); // and reload only this parts

                    var newTableContent = JSON.stringify(this.tableContent);

                    if (this.lastTableContent !== newTableContent) {
                        this.lastTableContent = newTableContent;
                        prms.push(this.actionTableView.reload());
                    }
                }

                return Q.all(prms);
            }.bind(this));
        }

        /**
         * returns a cell content for the control selection
         * MAY BE OVERWRITTEN BY SUBCLASS!
         * @param parentControl
         * @param control
         * @param allSelected
         * @returns {object}
         * @private
         */
        getControlSelectionCellContent(parentControl, control, allSelected) {
            var isSingleSelected = !allSelected && this.control.isControlSelected(control.uuidAction);
            return {
                parentControlUuid: parentControl.uuidAction,
                controlUuid: control.uuidAction,
                active: isSingleSelected,
                clickable: true
            };
        }

        /**
         * Use default type.
         * @param control
         * @param allSelected
         * @return {GUI.TableViewV2.CellType.}
         */
        getControlSelectionCellType(control, allSelected) {
            return GUI.TableViewV2.CellType.CONTROL_STATE;
        }

        /**
         * returns sections used for "actions" on the control
         * @param states
         * @returns {}
         */
        getActionSections(states) {
            var sections = [],
                defaultActionSection = this.getActionSection(states);

            if (defaultActionSection !== null && defaultActionSection.rows.length > 0) {
                sections.push(defaultActionSection);
            }

            return sections;
        }

        /**
         * returns the "action" section
         * @param states
         * @returns {}
         */
        getActionSection(states) {
            var cmdObjects = this.control.getCommands(),
                section = {
                    rows: []
                };

            if (!cmdObjects) {
                return section;
            }

            cmdObjects.forEach(function (cmdObj) {
                section.rows.push(this._createCellObjectForCmd(cmdObj));
            }.bind(this));
            return section;
        }

        /**
         * Shows a given screen
         * @param viewType
         * @param details
         */
        showScreen(viewType, details) {
            details = details || {};
            details.contentDetails = details;
            details.control = this.control;
            this.ViewController.showState(viewType, null, details);
        }

        /**
         * Will take a command object and create a proper cell object for it.
         * @param cmdObj
         * @private
         */
        _createCellObjectForCmd(cmdObj) {
            var cellObj = {
                content: {
                    clickable: true
                }
            }; // Title or Icon?

            if (cmdObj.icon) {
                cellObj.content.icon = cmdObj.icon;
            } else {
                cellObj.content.title = cmdObj.name;
            } // TODO don't set the type here, use getActionTableViewCellType! but check why we made it this way!
            // differ between selection and commands


            if (cmdObj.hasOwnProperty("isActive")) {
                cellObj.type = GUI.TableViewV2.CellType.GENERAL;
                cellObj.content.active = cmdObj.isActive;
            } else {
                cellObj.type = GUI.TableViewV2.CellType.Special.COMFORT_ACTION;
            } // Disable the cell to prevent any interaction


            if (cmdObj.hasOwnProperty("disabled")) {
                cellObj.content.clickable = !cmdObj.disabled;
                cellObj.content.disabled = cmdObj.disabled;
            }

            if (typeof cmdObj.cmd === "string" || typeof cmdObj.cmd === "number") {
                cellObj.action = this._sendCommand.bind(this, cmdObj.cmd);
            } else if (typeof cmdObj.cmd === "object") {
                cellObj.type = GUI.TableViewV2.CellType.Special.COMFORT_ACTION;

                this._prepareInteractiveResponses(cellObj, cmdObj.cmd);
            } else if (typeof cmdObj.cmd === "function") {
                cellObj.type = GUI.TableViewV2.CellType.Special.COMFORT_ACTION;
                cellObj.action = cmdObj.cmd;
            }

            return cellObj;
        }

        /**
         * Will set the proper callback methods onto the cell obj depending on what the cmdObj has configured.
         * @param cellObj
         * @param cmdObj
         * @private
         */
        _prepareInteractiveResponses(cellObj, cmdObj) {
            if (cmdObj.hit && cmdObj.release) {
                cellObj.content.supportHit = true;
                cellObj.content.supportRelease = true;
                cellObj.onCellHit = this._onCellHit.bind(this, cmdObj.hit);
                cellObj.onCellReleased = this._onCellReleased.bind(this, cmdObj.release);
            }

            if (cmdObj.tap) {
                cellObj.action = this._sendCommand.bind(this, cmdObj.tap);
            } else if (cmdObj.hit && cmdObj.release) {
                cellObj.action = this._sendCommand.bind(this, [cmdObj.hit, cmdObj.release]);
            }

            if (cmdObj.doubleTap) {
                cellObj.content.supportDoubleTap = true;
                cellObj.onCellDoubleTapped = this._sendCommand.bind(this, cmdObj.doubleTap);
            }

            if (cmdObj.tick) {
                cellObj.content.supportsTick = true;
                cellObj.content.tickInterval = cmdObj.tickInterval || DEFAULT_TICK_INTERVAL;
                cellObj.onCellTick = this._sendCommand.bind(this, cmdObj.tick);
            }
        }

        /**
         * returns the "state" sections (usually below the action section). If not subclassed, only one section will
         * be appended.
         * @param states
         * @return {Array} an array of state sections to append.
         */
        getStateSections(states) {
            let sections = []
            let rows = this.getStateRows(states);

            if (rows && rows.length > 0) {
                 sections.push({
                    rows: rows
                });
            }

            sections.push(...this.control.getStateSections(states))

            return sections;
        }

        /**
         * returns the "state" section (usually below the action section).
         * @param states
         * @return {null|object}
         */
        getStateRows(states) {
            return null;
        }

        /**
         * This method will return an obj for a simple state cell
         * @param title
         * @param value
         * @param [color]       an optional color for the cell.
         * @param [subtitle]    an optional subtitle to show below the title.
         * @param [action]      an optional action to take if the state cell is tapped
         * @param [valueColor]  an optional color for the value
         * @param [subtitleColor]  an optional color for the subtitle
         * @return {{content: {title: *, stateText: *}, type: string}}
         */
        createStateCell(title, value, color, subtitle, action, valueColor, subtitleColor) {
            var result = {
                content: {
                    title: title,
                    stateText: value,
                    stateTextColor: valueColor,
                    color: color,
                    subtitle: subtitle,
                    clickable: !!action
                },
                type: GUI.TableViewV2.CellType.Special.COMFORT_STATE
            }; // if the cell has an action - even tough it's undefined, it would be called. so don't put it onto the
            // cell object if no action has been provided!

            if (action) {
                result.action = action;
            }

            if (subtitleColor) {
                result.content.subtitleColor = subtitleColor;
            }

            return result;
        }

        /**
         * Will return the section containing the additional controls below (expert mode & statistic)
         * @return {*}
         */
        getAdditionalSection() {
            var additionalSection = {
                rows: []
            }; // STATISTIC

            if (!!this.control.statistic) {
                this.getStatisticCells().forEach(function (statCell) {
                    additionalSection.rows.push(statCell);
                });
            }

            additionalSection.rows.pushObject(this._getHistoryCell());

            return additionalSection.rows.length > 0 ? additionalSection : null;
        }

        /**
         * When locked, it's shown in the locked screen, when unlocked in the additional-section.
         * @returns {null|{action: *, content: {disclosureIcon: boolean, title}}}
         * @private
         */
        _getHistoryCell() {
            if (this.control.hasHistory) {
                return {
                    content: {
                        title: _('history'),
                        disclosureIcon: true,
                    },
                    action: this._showControlHistory.bind(this)
                }
            } else {
                return null;
            }
        }

        _showControlHistory() {
            App.navigationRef.navigate(LxControlHistoryScreen, { control: this.control });
        }

        /**
         * Will return an array with cell objects for the statistic (might be more than one)
         * @return {[*]}
         */
        getStatisticCells() {
            return [this.getSubmenuCell(_("statistics"), this.showStatisticForOutput.bind(this, 0))];
        }

        /**
         * returns a cell for sub menus (eg. expert mode, settings, statistic..)
         * @param title
         * @param action
         * @param iconSrc   (not used yet!)
         * @returns {{}}
         */
        getSubmenuCell(title, action, iconSrc) {
            return {
                content: {
                    title: title,
                    disclosureIcon: true,
                    clickable: true
                },
                action: action
            };
        }

        /**
         * Will present the statistic for the output provided.
         * @param nr    the output number, 0 if not provided.
         */
        showStatisticForOutput(nr) {
            if (this.control.statistic && this.control.statistic.outputs && this.control.statistic.outputs[nr]) {
                nr = nr ? nr : 0;
                NavigationComp.showStatistic(this.control.uuidAction, this.control.statistic.outputs[nr].uuid);
            }
        }

        /**
         * Returns true if the expert mode is to be shown.
         * @return {*|boolean}
         * @private
         */
        getShowExpertMode() {
            return !SandboxComponent.isRecordingTask() && ActiveMSComponent.isExpertModeEnabled() && (!this.control.uuidParent || !!this.control.parentControl);
        }

        getShowExpertModeLight() {
            return !SandboxComponent.isRecordingTask() && ActiveMSComponent.isExpertModeLightEnabled() && (!this.control.uuidParent || !!this.control.parentControl);
        }

        handleDetailsBtn() {
            NavigationComp.showControlContent(this.control.getSelectedControls()[0]);
        }

        // Private methods

        /**
         * Called when an cell was hit - important for setting the cellInUse flag
         * @param cmd
         */
        _onCellHit(cmd) {
            // Don't name this method onCellReleased, or the TableView Delegate will call this method with different arguments
            this._cellInUse = true;

            this._sendCommand(cmd);
        }

        /**
         * Called when an cell was released - important for resetting the cellInUse flag and updating
         * the table if a state update did arrive in the meantime.
         * @param cmd
         */
        _onCellReleased(cmd) {
            // Don't name this method onCellReleased, or the TableView Delegate will call this method with different arguments
            this._cellInUse = false; // there might not be a command to send for onRelease.

            cmd && this._sendCommand(cmd);

            if (this._shouldReload) {
                this._shouldReload = false;

                this._updateCommandsTable(this.control.getStates());
            }
        }

        /**
         * Will send the command using this.control.
         * @param cmdOrArr   either a single cmd, an array with several commands, or a function returning cmds to send.
         * @return {Promise}
         * @private
         */
        _sendCommand(cmdOrArr) {
            var promises = [],
                cmdArr; // a function may be passed in, which will return the actual cmds (e.g. when the current state is important for the command, e.g. media client)

            if (typeof cmdOrArr === "function") {
                return this._sendCommand(cmdOrArr());
            }

            cmdArr = Array.isArray(cmdOrArr) ? cmdOrArr : [cmdOrArr];
            cmdArr.forEach(function (cmd) {
                promises.push(this.control.sendCommand(cmd));
            }.bind(this));
            var allFinished = Q.all(promises);
            allFinished.then(this._startDeselectionTimeout.bind(this));
            return allFinished;
        }

        /**
         * in order to enable cancelling actions that may have been conducted by mistake, the selection should remain
         * active for a certain period.
         * @private
         */
        _startDeselectionTimeout() {
            // clear the old one.
            this._clearDeselectionTimeout(false);

            if (this.control.isGrouped() && !this.control.isControlSelected()) {
                this._deselectControlsTimeout = setTimeout(this._clearDeselectionTimeout.bind(this, true), DESELECT_TIMEOUT);
            }
        }

        /**
         * Will stop the deselection timeout and optionally deselect the controls right away.
         * @param deselectNow
         * @private
         */
        _clearDeselectionTimeout(deselectNow) {
            if (this._deselectControlsTimeout) {
                clearTimeout(this._deselectControlsTimeout);
                this._deselectControlsTimeout = null;
            }

            if (deselectNow && this.control.isGrouped() && !this.control.isControlSelected()) {
                this._updateControlSelection(); // not passing along an argument means select all.

            }
        }

        /**
         * Will update the current controls selection
         * @param control
         * @private
         */
        _updateControlSelection(control) {
            var data; // get the data after updating the group selection!
            // when a new control is selected after a command has been sent, deselection is no longer wanted.

            if (control && this._deselectControlsTimeout) {
                this._clearDeselectionTimeout(false);
            }

            this.control.updateGroupSelection(control && control.uuidAction);
            data = this._generateControlSelectionData(); // get the data after updating the group selection!

            this._reloadControlSelection(data);

            this._checkDetailsButton(); // hide it if no data is to be displayed.


            this.toggleSubview(this.controlSelectionTableView, data.length > 0);
        }

        _reloadControlSelection(data) {
            var isInitialLoad = !this._controlSelectionDataSource.tableContent; // Increased speed drastically by only setting a cell active, if the active property changed
            // Don't clone the new state for comparison, use the tableContent property instead,
            // cloning (cloneObject(), or JSON.stringify()) may take very long, up to 2 seconds for a this.controlSelectionTableView with 100 controls!

            if (!isInitialLoad) {
                // Reload the whole tableView if no tableContent is available yet
                this._controlSelectionDataSource.tableContent[0].rows.forEach(function (row, rowIdx) {
                    if (row.content.active !== data[0].rows[rowIdx].content.active) {
                        var cell = this.controlSelectionTableView.table[0][rowIdx];
                        cell.setContent(data[0].rows[rowIdx].content);
                        cell.setActive(cell.content.active);
                    }
                }.bind(this));
            }

            this._controlSelectionDataSource.update(data);

            this._controlSelectionDelegate.update(data);

            if (isInitialLoad) {
                this.controlSelectionTableView.reload();
            }
        }

        _showView(viewType, details) {
            details = details || {};
            details.contentDetails = details;
            details.control = this.control;
            this.ViewController.showState(viewType, null, details);
        }

        _createAndAddControlNoteView() {
            var promise = null;
            this.controlNoteView = new ControlNoteView(this.control);
            this.controlNoteView.element.addClass("note-box__note-view");
            promise = this.appendSubview(this.controlNoteView, this.elements.noteBox);
            this.element.addClass("control-action-screen--note-box-shown");
            return promise;
        }

        // ------------------------------------------------------------------------------------------
        // ------------------------- Lock / Unlock Handling -----------------------------------------
        // ------------------------------------------------------------------------------------------
        _loadLockTableView() {
            this.lockTableDataSource = tableViewDataSource(null, null, null);
            this.lockTableDelegate = tableViewDelegate(null, null);
            return new GUI.TableViewV2(this.lockTableDataSource, this.lockTableDelegate);
        }

        _updateLockTable(states) {
            var lockContent = this._prepareControlLockedContent(states),
                modified = false;

            modified |= this.lockTableDataSource.update(lockContent);
            modified |= this.lockTableDelegate.update(lockContent);

            if (lockContent.length > 0) {
                this.showSubview(this.lockTableView);
                this.setTitle(states.stateText);
            } else {
                this.hideSubview(this.lockTableView);
                this.setTitle(this.getTitle());
            }

            this.element.toggleClass("control-action-screen--lock-shown", lockContent.length > 0);
            modified && this.lockTableView.reload();
        }

        _prepareControlLockedContent(states) {
            var content = [];

            if (states.universalIsLocked && states.universalLockReason) {
                content.push(this._getReasonSectionCell(states.universalLockReason));
            }

            if (states.universalIsUnlockable && SandboxComponent.hasAdminPermission()) {
                content.push({
                    rows: [this._getUnlockCell()]
                });
            }

            if (this.control.hasHistory && states.universalIsLocked) {
                content.push({rows: [this._getHistoryCell()]})
            }

            return content;
        }

        _getReasonSectionCell(reason) {
            return {
                headerTitle: _("control.lock.note"),
                rows: [{
                    content: {
                        text: reason.sanitizeHTML().insertHrefs().replace(Regex.NEW_LINE, "<br>")
                    },
                    type: GUI.TableViewV2.CellType.TEXT
                }]
            };
        }

        _getUnlockCell() {
            return {
                content: {
                    title: _("control.lock.unlock-title"),
                    clickable: true
                },
                type: GUI.TableViewV2.CellType.CENTERED_BUTTON,
                action: this._sendCommand.bind(this, Commands.CONTROL.UNLOCK_CTRL)
            };
        }

        // --------------- Context Menu --------------------------------
        _isLockable(states) {
            var lockable = false;

            if (this.control.supportsLocking() && SandboxComponent.hasAdminPermission()) {
                // control supports it, permissions granted - true if not locked already
                lockable = !states.universalIsUnlockable && !states.universalIsLocked;
            }

            return lockable;
        }

        _getLockContextMenuEntry() {
            return {
                title: _("control.lock.lock-title"),
                action: this._showControlLockScreen.bind(this)
            };
        }

        _showControlLockScreen() {
            return this.ViewController.showState(ScreenState.ControlLocking, null, {
                control: this.control
            });
        }

        // ------------------------------------------------------------------------------------------
        // -------------------------- Linked Controls -----------------------------------------------
        // ------------------------------------------------------------------------------------------
        _loadLinkedCtrlsTableView() {
            var linkedCtrlsContent = this._getLinkedControlsContent();

            this.linkedCtrlsDataSource = tableViewDataSource(linkedCtrlsContent, null, this);
            this.linkedCtrlsDelegate = tableViewDelegate(linkedCtrlsContent, this);
            return new GUI.TableViewV2(this.linkedCtrlsDataSource, this.linkedCtrlsDelegate);
        }

        _getLinkedControlsContent() {
            var content = [],
                section = {
                    headerTitle: _("control.linked-controls"),
                    rows: []
                },
                ctrl;
            this.control.links.forEach(function (ctrlUuid) {
                ctrl = ActiveMSComponent.getControlByUUID(ctrlUuid);

                if (ctrl) {
                    section.rows.push({
                        type: ctrl.getCellType(),
                        content: {
                            control: ctrl,
                            displayAsCell: true,
                            displayRoomName: false,
                            showGroupDetails: false,
                            hideActionElements: false,
                            contentDetail: {
                                linked: true
                            } // required to adopt bg, see _showCloseIcon below.

                        }
                    });
                }
            });

            if (section.rows.length > 0) {
                content.push(section);
            }

            return content;
        }

        /**
         * Overwritten implementation of screen.js, as for linked controls, the base implementation won't work,
         * it only checks the animation type, which isn't properly set as it's shown as modal.
         * @returns {boolean}
         * @private
         */
        _showCloseIcon() {
            var showClose = super._showCloseIcon(...arguments);

            if (this.details.linked) {
                showClose = true;
            }

            return showClose;
        }

        _shouldShowLinkedControls() {
            return true;
        }

        _getAutomaticDesignerRelatedMenuOptions() {
            var options = [];

            if (SandboxComponent.isAutopilotAvailable() && Feature.AUTOMATIC_DESIGNER) {
                var rulesPrms = AutomaticDesignerComponent.getRules([this.control]);
                options.push({
                    loadFn: function () {
                        return rulesPrms.then(function (rules) {
                            if (rules.length) {
                                return {
                                    title: _("automatic-designer.control-link.show-rules"),
                                    disclosureText: rules.length.toString(),
                                    // This must be a string, we can't pass numbers!
                                    action: function () {
                                        NavigationComp.showState(ScreenState.AutomaticDesigner.OverviewScreen, {
                                            filterControls: [this.control]
                                        });
                                    }.bind(this)
                                };
                            } else {
                                return null;
                            }
                        }.bind(this));
                    }.bind(this)
                });

                if (this.control.hasAutomaticDesignerCapabilities()) {
                    options.push({
                        title: _("automatic-designer.control-link.create-rule"),
                        action: function () {
                            NavigationComp.showState(ScreenState.AutomaticDesigner.RuleViewController, {
                                filterControls: [this.control]
                            });
                        }.bind(this)
                    });
                }

                if (Feature.AUTOMATIC_DESIGNER_SCENES) {
                    var scenesPrms = AutomaticDesignerComponent.getScenes([this.control]),
                        hasActionCapabilities = this.control.hasAutomaticDesignerCapabilities(AutomaticDesignerEnums.SCREEN_TYPES.ACTIONS);
                    options.push({
                        loadFn: function () {
                            return scenesPrms.then(function (scenes) {
                                if (scenes.length) {
                                    return {
                                        title: _("scenes.show", {
                                            count: scenes.length
                                        }),
                                        disclosureText: scenes.length.toString(),
                                        // This must be a string, we can't pass numbers!
                                        action: function () {
                                            NavigationComp.showState(ScreenState.AutomaticDesigner.ScenesOverviewScreen, {
                                                filterControls: [this.control],
                                                extension: AutomaticDesignerEnums.RULE_EXTENSION.SCENE
                                            });
                                        }.bind(this)
                                    };
                                } else {
                                    return null;
                                }
                            }.bind(this));
                        }.bind(this)
                    });

                    if (hasActionCapabilities) {
                        options.push({
                            title: _("scenes.create"),
                            action: function () {
                                NavigationComp.showState(ScreenState.AutomaticDesigner.RuleViewController, {
                                    filterControls: [this.control],
                                    extension: AutomaticDesignerEnums.RULE_EXTENSION.SCENE
                                });
                            }.bind(this)
                        });
                    }
                }
            }

            return options;
        }

    };
});
