import {
    navigatorConfig,
    LxReactControlStatsGraph,
    DateViewTypeSelector,
    DateViewType,
    DateViewTypeUtils
} from "LxComponents";
import globalStyles from "GlobalStyles";
import {
    useState,
    useEffect,
    useMemo
} from "react";
import {
    View
} from "react-native"



export default function LxReactControlStatsScreen({navigation, route}) {

    const ctrlUuid = route.params.controlUuid;
    const availableViewTypes = route.params.availableViewTypes || Object.values(DateViewType);
    const outputNames = route.params.outputNames;
    const format = route.params.format;
    const title = route.params.title;

    const topConfig = navigatorConfig({
        title: title
    });
    useEffect(() => {
        navigation.setOptions({
            ...topConfig
        });
    }, [topConfig])


    useEffect(() => {
        let isCurrViewTypeValid = route.params.availableViewTypes.some(avVt => avVt === viewInfo.vt);
        if (!isCurrViewTypeValid) {
            updateViewInfo({vt: route.params.availableViewTypes[0]});
        }
    }, [route.params.availableViewTypes])

    const [viewInfo, setViewInfo] = useState(DateViewTypeUtils.initializeViewInfo(availableViewTypes[0]));
    const updateViewInfo = (newInfo) => {
        setViewInfo({...viewInfo, ...newInfo});
    }

    const dataPointUnit = useMemo(() => {
        let displayUnit = "all";
        switch (viewInfo.vt) {
            case DateViewType.Day:
            case DateViewType.Week:
            case DateViewType.Month:
            case DateViewType.Year:
            case DateViewType.Lifetime:
                displayUnit = DateViewTypeUtils.getStatisticDiffDataPointUnit(viewInfo.vt);
                break;
            default:
                break;
        }
        return displayUnit;
    }, [viewInfo.vt]);

    const getPassOnProps = () => {
        let props = {...route.params};
        delete props.availableViewTypes;
        delete props.title;
        return props;
    }

    return <View style={styles.rootCntr}>
        <DateViewTypeSelector
            availableViewTypes={route.params.availableViewTypes}
            initialViewType={viewInfo.vt}
            initialTs={viewInfo.ts}
            onUpdateVt={updateViewInfo}
            autoUpdateTs={true}
            allowPastLive={!!route.params.allowPastLive}
            style={styles.dateViewTypeSel}
        />
        <View style={styles.graphCntr}>
            <LxReactControlStatsGraph
                fullStretch={true}
                style={styles.graph}
                {...getPassOnProps()} // don't pass this at the end, may overwrite important props (viewType, start/endDate)
                dataPointUnit={dataPointUnit}
                fromUnixUtcTs={viewInfo.start}
                toUnixUtcTs={viewInfo.end}
                viewType={viewInfo.vt}
            />
        </View>
    </View>
}

const styles = {
    rootCntr: {flexDirection: "column", height: "100%"},
    dateViewTypeSel: {},
    graphCntr: {flex: 1, margin: globalStyles.sizes.small, paddingTop: globalStyles.sizes.bigger},
    graph: {flex: 1}
}