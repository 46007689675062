'use strict';

class HomeKitSetupDoneScreen extends GUI.HomeKitOnboardingScreen {
    //region Static
    static getShownIds() {
        return [];
    }

    static shouldShow(id) {
        return true;
    } //endregion Static


    constructor(details) {
        super(details);
    }

    viewDidLoad() {
        var promise = super.viewDidLoad(...arguments);
        this.ViewController._preventReset = true;
        return promise;
    }

    continueBtnAction() {
        this.ViewController.dismiss();
    }

}

GUI.HomeKitSetupDoneScreen = HomeKitSetupDoneScreen;
