'use strict';

define("AudioZoneV2ControlContent", ['ControlActionCellsScreen', 'AudioZoneV2SearchInfoView', 'AudioZoneV2ControlEnums', 'CustomizationManager', 'FavoritesManager', 'SpotifyAccountManager', 'HistoryManager', 'ControlContentMenu', "CoverViewV2", 'AddMediaViewControllerV2Base', 'AddMediaViewControllerV2PlaylistPicker', 'AddMediaViewControllerV2PlaylistSpotify', 'AddMediaViewControllerV2SpotifyOnboarding', 'AddMediaViewControllerV2ZoneFavorite', 'MediaBrowserV2Base', 'MediaBrowserV2Service', 'MediaBrowserV2Service7', 'MediaBrowserV2Service11', 'MediaBrowserV2Service12', 'MediaBrowserV2Favorites', 'MediaBrowserV2LineIn', 'MediaBrowserV2Library', 'MediaBrowserV2Playlists', "PlayerV2"], function (ControlActionCellsScreen, AudioZoneV2SearchInfoView, AudioZoneV2ControlEnums, CustomizationManager, FavoritesManager, SpotifyAccountManager, HistoryManager, ControlContentMenu) {
    return class AudioZoneV2ControlContent extends ControlActionCellsScreen {
        //region Getter
        get isInEditMode() {
            return this._editModeActive;
        }

        get registeredEventTypes() {
            var eventTypes = [this.EVENT_TYPES.SERVICE];

            if (!HD_APP) {
                eventTypes.push(this.EVENT_TYPES.ZONE_FAVORITES);
            }

            return eventTypes;
        } //endregion Getter


        //region Setter
        set isInEditMode(value) {
            this._editModeActive = value;
            this.reloadTable().then(function () {
                this.toggleSubview(this.customInfoView, !value);
                this.elements.contentPlaceholder.toggleClass("content__action-view--is-editing", value);
                this.actionTableView.setEditingModeForSection(0, this.isInEditMode, false, this.isInEditMode);
                this.actionTableView.setEditingModeForSection(1, this.isInEditMode, false, this.isInEditMode);
            }.bind(this));
        } //endregion Setter


        constructor() {
            super(...arguments);
            applyMixins(this, MediaStateHandlerV2.Mixin);
            this.asCommStarted = false;
            this.states = this.control.getStates();
            this.currentPlayerId = this.control.details.playerid;
            this.eventHandlers = [];
            this._viewShouldRemainVisible = true;
            this._showHiddenItemsMap = {};
            this._editModeActive = false;
        }

        viewDidLoad() {
            this.element.addClass("audio-zone-v2-control");
            this.element.addClass("audio-zone-v2-control--starting");
            this.boundReload = this.boundReloadFn.bind(this);
            this.boundOnHistoryChange = this.onHistoryChange.bind(this);
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                if (Feature.SHARED_USER_SETTINGS) {
                    CustomizationManager.shared(this.control).registerForChange(this.boundReload);
                }

                setTimeout(function () {
                    if ((!this.tableContent || !this.tableContent.length) && !this.control.getStates().universalIsLocked) {
                        this.actionTableView.setBackgroundView(new GUI.BackgroundView(Icon.INDICATOR, _("loading"), null, null, null, true));
                    }
                }.bind(this), 500);
            }.bind(this));
        }

        viewDidAppear() {
            return super.viewDidAppear(...arguments).then(function () {
                Debug.Communication && console.warn("=".repeat(50));
                Debug.Communication && console.timeEnd("AudioZoneV2ControlContent - TTI");
                Debug.Communication && console.warn("=".repeat(50));
            }.bind(this));
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function () {
                this._prepareRegisterAndRequestStates().then(function () {
                    this.reloadTable();
                }.bind(this));
            }.bind(this));
        }

        /**
         * Limit to much updates by just subscribing to those states
         * @returns {[string, string]}
         */
        getStateIDs() {
            return ["canPlayMusic", "universalIsLocked", "messageCenterEntries"];
        }

        setSubtitle() {
            return null;
        }

        closeAction() {
            this._viewShouldRemainVisible = false;
            return super.closeAction();
        }

        viewDidDisappear(viewRemainsVisible) {
            return super.viewDidDisappear(...arguments).then(function () {
                !viewRemainsVisible && this._unregisterFromEvents();
            }.bind(this));
        }

        destroy() {
            if (Feature.SHARED_USER_SETTINGS) {
                CustomizationManager.shared(this.control).unregisterFromChange(this.boundReload);
            }

            this._unregisterFromEvents(true);

            return super.destroy(...arguments);
        }

        getCustomInfoView() {
            return new AudioZoneV2SearchInfoView(this.control, this, this.isInAmbientMode());
        }

        setCustomInfoView() {
            super.setCustomInfoView(...arguments);
            this.customInfoView && this.elements.customInfoBox.hide();
        }

        getActionTableView() {
            return new GUI.CardView(this.tableViewDataSource, this.tableViewDelegate);
        }

        // TableView DataSource Methods
        styleForTableView() {
            return HD_APP ? TableViewStyle.TRANSLUCENT : TableViewStyle.COMFORT_MODE_2020.replace("grouped", "translucent");
        }

        receivedStates(states) {
            super.receivedStates(...arguments);
            this.states = states;
            this.reloadTable();
        }

        receivedZoneFavoritesReloadEvent(reason) {
            return this.onFavChange();
        }

        boundReloadFn() {
            if (!this.isInEditMode) {
                return this.reloadTable();
            }
        }

        onFavChange() {
            return this._reloadSectionWithClass(ControlContentMenu.SECTION_ID.FAVS);
        }

        onHistoryChange() {
            return this._reloadSectionWithClass(ControlContentMenu.SECTION_ID.HISTORY);
        }

        receivedServiceReloadEvent() {
            return this._reloadSectionWithClass(ControlContentMenu.SECTION_ID.SPOTIFY_PLAYLISTS);
        }

        onSectionPatch(delegateId, section, sectionIdx) {
            if (!this.isInEditMode && this.tableContent) {
                this.tableContent.splice(sectionIdx, 1, section);
                var needsReload = false;
                needsReload |= this.tableViewDataSource.update(this.tableContent);
                needsReload |= this.tableViewDelegate.update(this.tableContent);

                if (needsReload) {
                    return this._handleReloadActionTable();
                } else {
                    return Q.resolve(true);
                }
            }
        }

        _reloadSectionWithClass(className) {
            if (!this.isInEditMode) {
                var sectionIdx = -1;
                return this._updateTableContent().then(function _reloadSectionWithClassContentUpdated() {
                    var needsReload = false;
                    needsReload |= this.tableViewDataSource.update(this.tableContent);
                    needsReload |= this.tableViewDelegate.update(this.tableContent);

                    if (needsReload) {
                        sectionIdx = this.tableContent.findIndex(function (section) {
                            return section.customClass && section.customClass.indexOf(className) !== -1;
                        }.bind(this));
                        return this._handleReloadActionTable();
                    } else {
                        return Q.resolve(true);
                    }
                }.bind(this));
            }
        }

        getTableContent() {
            // avoid loading any content while locked!
            if (this.control.getStates().universalIsLocked) {
                return Q.resolve([]);}


            if (!HD_APP) {
                // The singletone will be destroyed when the connection is lost, reapply the delegate on every fetch
                // the singletone already handles duplicate delegates!
                ControlContentMenu.shared(this.control).delegate = this;}

            if (this.control.audioserverComp.isConnectionPromisePending()) {
                return Q.resolve(this._getConnectingContent());
            }


            return ControlContentMenu.shared(this.control).getTableContent(this.actionTableView, this.isInEditMode, this.ViewController, this.boundReload).then(function (content) {
                this.tableContent = this._validateTableContent(cloneObjectDeep(content));
                return this.tableContent;
            }.bind(this));
        }

        /**
         * Required as it'll ensure that the mediaStateHandler reload calls are properly forwarded
         * @param contentType
         * @returns {string|number}
         */
        getMediaId(contentType) {
            if (contentType === this.EVENT_TYPES.SERVICE) {
                return MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS;
            } else if (contentType === this.EVENT_TYPES.ZONE_FAVORITES) {
                return this.control.details.playerid;
            } else {
                return 0;
            }
        }

        /**
         * Used to ensure the content provided is in proper format.
         * @param content
         * @returns {*}
         * @private
         */
        _validateTableContent(content) {
            if (this.isInEditMode) {
                content = content.filter(function (section) {
                    return !section.isSettingsSection;
                });
                content.forEach(function (section) {
                    section.rows.forEach(function (row) {
                        row.type = GUI.TableViewV2.CellType.CHECKABLE;
                        row.content.radioMode = GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE;
                        row.content.selected = row.enabled;
                        row.content.class = "";
                        row.content.disclosureText = null;
                        row.content.leftIconSrc = null;

                        row.didRequestCheckingCell = function didRequestCheckingCell(cell, section, row, tableView) {
                            if (cell.content.selected) {
                                var sectionId = content[section].rows[row].key.split("-")[0];

                                if (sectionId === MusicServerEnum.ControlContentSectionId.MENU) {
                                    return CustomizationManager.shared(this.control).get().then(function (settings) {
                                        return Object.values(settings[sectionId]).filter(function (item) {
                                            return item.enabled;
                                        }).length > 1;
                                    }.bind(this));
                                } else {
                                    return true;
                                }
                            } else {
                                return true;
                            }
                        }.bind(this);

                        row.didCheckCell = function didCheckCell(cell, sectionIdx, rowIdx, tableView, selected) {
                            CustomizationManager.shared(this.control).set(row.key, {
                                enabled: selected
                            });
                        }.bind(this);
                    }.bind(this));

                    section.didMoveCell = function (sectionIdx, rowIdx, tableView, cell, oldIdx, newIdx) {
                        CustomizationManager.shared(this.control).set(content[sectionIdx].rows[oldIdx].key, {
                            rating: newIdx
                        });
                        content[sectionIdx].rows.move(oldIdx, newIdx);
                    }.bind(this);

                    section.headerTitle = _(section.sectionId);
                }.bind(this));
                content[0].sectionRightButtonTitle = _("finish");
                content[0].rightSectionButtonTapped = this.onEditButtonTapped.bind(this);
            } else {
                // Filter out any disabled cells in the contentSection
                content.forEach(function (section, sectionIdx) {
                    if (section.isContentSection) {
                        content[sectionIdx].rows = section.rows.filter(function (row) {
                            return row.enabled === true;
                        });
                    }
                }.bind(this));
            }

            return content;
        }

        _getConnectingContent() {
            this.tableContent = [];
            return this.tableContent;
        }

        /**
         * Called by the controlContenMenu Helper if new data arrives after the promise did already resolve!
         * @param newContent
         */
        handleContentUpdated(newContent) {
            this.tableContent = this._validateTableContent(newContent);

            this._updateDataAndReloadTable();
        }

        shouldDisplayStateIcons() {
            return false;
        }

        /**
         * Reload the whole table
         * @note If an section already exists in the tableContent only it is reloaded instead of the whole table
         * @returns {*}
         */
        reloadTable() {
            if (this.control.audioserverComp.Feature.THIS_APP|| this.control.audioserverComp.isConnectionPromisePending()) {
                // Check if We have all the features
                return this._updateTableContent().then(function () {
                    return this._updateDataAndReloadTable();
                }.bind(this));
            } else {
                return this._handleReloadActionTable();
            }
        }

        /**
         * Seperate method from reloadTable as this one is used also when the contentMenuHelper reports changes
         * to the data after the initial acquisition promise has resolved. (cached data update or such)
         * @returns {Q.Promise<boolean>|*}
         * @private
         */
        _updateDataAndReloadTable() {
            var needsReload = false;
            needsReload |= this.tableViewDataSource.update(this.tableContent);
            needsReload |= this.tableViewDelegate.update(this.tableContent);

            if (needsReload) {
                return this._handleReloadActionTable();
            } else {
                return Q.resolve(true);
            }
        }

        _handleReloadActionTable() {
            var start = timingNow();
            return this.actionTableView.reload().then(function () {
                GUI.animationHandler.removeCssClass(this.element, "audio-zone-v2-control--starting");
                this.actionTableView.setBackgroundView(null);
            }.bind(this));
        }

        onEditButtonTapped() {
            this.isInEditMode = !this.isInEditMode;
        }

        _updateTableContent() {
            return this.getTableContent();
        }

        _updateCommandsTable() {// Do Nothing here!
        }

        _prepareRegisterAndRequestStates() {
            return this.control.audioserverComp.connectionPromise().then(() => {
                return Q.all([SpotifyAccountManager.shared(this.control).prepare(), HistoryManager.shared(this.control).prepare()]).then(function () {
                    this.customInfoView && this.elements.customInfoBox.show();
                    this.spotifyId = SpotifyAccountManager.getUserUnique(SpotifyAccountManager.shared(this.control).activeUser);
                    this.spotifyUsername = SpotifyAccountManager.shared(this.control).activeUserName;

                    this._registerForMediaServerReloadEvents.apply(this, this.registeredEventTypes);

                    HistoryManager.shared(this.control).registerForChange(this.boundOnHistoryChange);

                    this._requestStates();
                }.bind(this));
            }, null, notif => {
                this.reloadTable();
            });
        }

        _unregisterFromEvents(fromDestroy) {
            this._unregisterForMediaServerEvents.apply(this, this.registeredEventTypes);

            HistoryManager.shared(this.control).unregisterFromChange(this.boundOnHistoryChange);
        }

        getMediaInfo(contentType) {
            if (contentType === this.EVENT_TYPES.SERVICE && this.spotifyId) {
                // ensures that the updated activeUser is in place.
                if (SpotifyAccountManager.shared(this.contorl).isPrepared()) {
                    this.spotifyId = SpotifyAccountManager.getUserUnique(SpotifyAccountManager.shared(this.control).activeUser);
                    this.spotifyUsername = SpotifyAccountManager.shared(this.control).activeUserName;
                }

                return {
                    service: {
                        uid: MusicServerEnum.Service.SPOTIFY + "/" + this.spotifyId,
                        cmd: MusicServerEnum.Service.SPOTIFY,
                        user: this.spotifyUsername
                    }
                };
            }
        }

    };
});
