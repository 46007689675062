'use strict';

define(["ControlActionScreen"], function (ControlActionScreen) {
    return class ControlActionCellsScreen extends ControlActionScreen {
        constructor() {
            super(...arguments);
        }

        getActionTableView() {
            return new GUI.TableViewV2(this.tableViewDataSource, this.tableViewDelegate);
        }

    };
});
