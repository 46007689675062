import Accordion from 'react-native-collapsible/Accordion';
import { useState, useEffect, useMemo } from 'react';
import { LxReactSeparator, useControl, useLiveState } from 'LxComponents';
import { View, StyleSheet, Text, TouchableOpacity, Animated } from 'react-native';
import Icons from "IconLib"
import globalStyles from 'GlobalStyles';
import { categorizeValue, Categorization, getColorFor, getHumanReadableCategorization, determineResolution } from '../utils';
import useSpotPriceData from '../hooks/useSpotPriceData';

/**
 * Merges values within a section of data.
 *
 * @param {Object} section - The data object containing the content to be merged.
 * @param {Function} iteratorFn - The iterator function used to calculate the duration.
 * @returns {Object} - The modified data object with merged content and total duration.
 */
const mergeValuesWithinSection = (section, iteratorFn) => {
    if (section.content.length === 0) return section;

    const normalisedData = section.content.map(({ ts, values }) => ({ ts, values: [values[0].toFixed(3)] })).filter((dp, idx, self) => self.findIndex(dtp => dtp.ts === dp.ts) === idx);

    const mergedData = [];
    let currentGroup = { ts: [], values: [] };
    let totalRuntime = 0;
    const normalisedDataLength = normalisedData.length;

    const pushGroup = () => {
        mergedData.push({ ...currentGroup });
        const groupDuration = currentGroup.ts.length > 1
        ? iteratorFn(moment.unix(currentGroup.ts[currentGroup.ts.length - 1])).unix() - currentGroup.ts[0]
        : iteratorFn(moment.unix(currentGroup.ts[0])).unix() - currentGroup.ts[0];
        totalRuntime += groupDuration;
    }

    for (let i = 0; i < normalisedDataLength; i++) {
        const curr = normalisedData[i];
        const prev = normalisedData[i - 1];

        if (i > 0 && curr.values[0] === prev.values[0] && iteratorFn(moment.unix(prev.ts)).unix() === curr.ts) {
            currentGroup.ts.push(curr.ts);
            currentGroup.values = curr.values;
        } else {
            if (currentGroup.ts.length > 0) {
                pushGroup();
            }
            currentGroup = { ts: [curr.ts], values: curr.values };
        }
    }

    // Push the last group
    if (currentGroup.ts.length > 0) {
        pushGroup();
    }

    const totalDurationText = LxDate.formatSecondsShort(totalRuntime, true, true);

    return { ...section, content: mergedData, totalDurationText };
};


const FoldIndicator = ({ sectionActive }) => {
    const rotation = new Animated.Value(0);

    const animateRotation = (value, callback) => {
        Animated.timing(rotation, {
            toValue: value,
            useNativeDriver: true,
            duration: 300,
        }).start(callback);
    };

    const spin = rotation.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '90deg']
    })

    useEffect(() => {
        if (sectionActive) {
            animateRotation(1);
        } else {
            animateRotation(0);
        }
    }, [sectionActive]);

    return (
        <Animated.View style={{ transform: [{ rotate: spin }] }}>
            <Icons.Chevron
                fill={globalStyles.colors.text.secondary}
                width={18}
                height={18}
            />
        </Animated.View>
    )
}

export default function PriceLists({ uuidAction, viewInfo }) {
    const [activeSections, setActiveSections] = useState([]);
    const { details } = useControl(uuidAction)
    const { states = {} } = useLiveState(uuidAction, ["manualMax"]);
    const {
        data = [],
        max,
        highMax,
        lowMax,
        vLowMax,
        min
    } = useSpotPriceData({
        uuidAction,
        start: viewInfo.start,
        end: viewInfo.end
    });

    const { iteratorFn } = determineResolution(data.map(({ ts }) => ts));

    const { sections } = useMemo(() => {
        let sec = [
            Categorization.V_LOW,
            Categorization.LOW,
            Categorization.HIGH,
            Categorization.V_HIGH
        ].map(category => {
            return {
                color: getColorFor({ category }),
                name: getHumanReadableCategorization({ category }),
                content: []
            }
        })

        data?.forEach((item) => {
            const [value] = item.values,
                category = categorizeValue({ value, stats: { max, highMax, lowMax, vLowMax, min } }, states.manualMax),
                [
                    vLowSec,
                    lowSec,
                    highSec,
                    vHighSec
                ] = sec;

            switch (category) {
                case Categorization.LOWEST:
                case Categorization.V_LOW:
                    vLowSec.content.push(item)
                    break;
                case Categorization.LOW:
                    lowSec.content.push(item)
                    break;
                case Categorization.HIGH:
                    highSec.content.push(item)
                    break;
                case Categorization.V_HIGH:
                case Categorization.HIGHEST:
                    vHighSec.content.push(item)
                    break;
                default:
                    break;
            }
        });

        sec = sec.map(section => mergeValuesWithinSection(section, iteratorFn));

        sec.forEach(section => {
            section.content.sort((l, r) => {
                return l.ts[0] - r.ts[0];
            });
        });

        const filteredSec = sec.filter((item) => item.content?.length > 0)

        return { sections: filteredSec }
    }, [JSON.stringify(data), states.manualMax]);

    const updateSections = (activeSections, index) => {
        if (activeSections.includes(index)) {
            setActiveSections(activeSections.filter((i) => i !== index))
        } else {
            setActiveSections([...activeSections, index])
        }
    }

    const renderSectionTitle = (section, index) => {
        const handleUpdateSections = () => {
            updateSections(activeSections, index)
        }

        return (
            <View>
                {activeSections.includes(index - 1) ? <LxReactSeparator /> : null}
                <TouchableOpacity onPress={handleUpdateSections}>
                    <View style={styles.accordionItem}>
                        <Text style={styles.text}>{section.name}</Text>
                        <View style={styles.accordionItemLeft}>
                            <Text style={StyleSheet.compose(styles.text, { color: section.color })}>
                                {section.totalDurationText}
                            </Text>
                            <FoldIndicator sectionActive={activeSections.includes(index)} />
                        </View>
                    </View>
                </TouchableOpacity>
                {!activeSections.includes(index) ? <LxReactSeparator /> : null}
            </View>
        )
    }

    const renderHeader = (section, index, isActive) => {
        return (
            <View />
        )
    }

    const renderContent = (section) => {
        return (
            <View >
                {section.content?.map((item, index) => {
                    let suffix = "";
                    if(_('timeSuffix') !== " ") {
                        suffix = useAmPm() ? "" : ` ${_('timeSuffix')}`;
                    }
                    const tsLength = item.ts.length;
                    item.ts = [item.ts[0], item.ts[tsLength - 1]];
                    const title = `${moment.unix(item.ts[0]).format("LT")}${suffix} - ${iteratorFn(moment.unix(item.ts[1])).format("LT")}${suffix}`
                    const valueText = lxUnitConverter.convertSplitAndApply(details?.format ?? "", parseFloat(item.values[0]))
                    const value = `${valueText.valueTxt} ${valueText.succTxt}`
                    const color = getColorFor({ value: item.values[0], stats: { max, highMax, lowMax, vLowMax, min } }, states.manualMax)
                    return (
                        <View key={index} style={styles.accordionItem}>
                            <Text style={styles.text}>
                                {title}
                            </Text>
                            <Text style={StyleSheet.compose(styles.text, { color: color })}>
                                {value}
                            </Text>
                        </View>
                    )
                })}
            </View>
        )
    }
    
    if (data?.length) {
        return <View style={styles.root}>
            <LxReactSeparator />
            <Accordion
                sections={sections}
                renderSectionTitle={renderSectionTitle}
                renderHeader={renderHeader}
                renderContent={renderContent}
                activeSections={activeSections}
                onChange={updateSections}
            />
        </View>
    } else {
        return null
    }
}

const styles = StyleSheet.create({
    root: {
        flexDirection: "column",
        width: "100%",
        marginTop: 16,
    },
    accordionItem: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        backgroundColor: "transparent",
        height: 56,
        alignItems: "center",
    },
    accordionItemLeft: {
        flexDirection: "row",
        alignItems: "center",
    },
    text: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.primary,
    },
})
