'use strict';

define("UpDownLeftRightDigitalControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class UpDownLeftRightDigitalControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [];
            let details = { rowIdx: 0 };

            if (control.supportsDecrease()) {
                cmds.push(this.createDeactivatable(null, this._getCmdObjForDirection(control, Direction.DOWN), control.getContentButtonIcon(Direction.DOWN), states.minReached // for Analog and ValueSelector. Digital = "never true"
                , details));
            }

            cmds.push(this.createDeactivatable(null, this._getCmdObjForDirection(control, Direction.UP), control.getContentButtonIcon(Direction.UP), states.maxReached // for Analog and ValueSelector. Digital = "never true"
            , details));
            return cmds;
        }

        _getCmdObjForDirection(control, dir) {
            // Only add the tick (repetitive sending of a command) to the analog UpDownLeftRight control,
            // If the EIB Jalousie receives such a tick, it won't be able to make a long down- or upwards motion!
            return this.createCmdObj(control.getHitCommand(dir), control.getReleaseCommand(dir), control.getCommand(dir) // Tap command
            );
        }

    };
});
