'use strict';

define(["LxComponents"], function ({LxReactImageView}) {
    return class SubControlView extends GUI.View {
        //region Static
        static Template = (function template() {
            var getTemplate = function getTemplate() {
                return $(
                    '<div>' +
                        '<div class="control-state-view__container">' +
                            '<div class="control-state-view__container-text-wrapper"></div>' +
                            '<div class="control-state-view__container-controls"></div>' +
                        '</div>' +
                    '</div>'
                );
            };

            return {
                getTemplate: getTemplate,
            };
        })(); //endregion Static

        constructor(
            { uuidAction: controlUuid, pos, size, text, actionsVisible = Feature.SYSTEM_SCHEME_ACTION_VISIBILITY ? false : true },
            isTextObject = false,
        ) {
            super(SubControlView.Template.getTemplate());
            Object.assign(this, StateHandler.Mixin);
            this.control = ActiveMSComponent.getStructureManager().getControlByUUID(controlUuid);
            this.name = 'SubControlView@' + (!!this.control ? this.control.name : this.text);
            this.pos = pos;
            this.size = size;
            this.isTextObject = isTextObject;
            this.text = text;
            this.actionsVisible = actionsVisible;
        }

        viewDidLoad() {
            var btn;
            return Q(super.viewDidLoad(...arguments) || true).then(
                function () {
                    this.containerElement = this.element.find('.control-state-view__container');
                    this.containerText = this.containerElement.find('.control-state-view__container-text-wrapper');
                    this.controlsElement = this.element.find('.control-state-view__container-controls');
                    if(Feature.SYSTEM_SCHEME_EXTENSIONS) {
                        this.containerText.text(this.isTextObject ? this.text : `${this.text} ${this.control.name}`);
                        if (this.size) {
                            this.element.css('padding', 0);
                        }
                    } else {
                        this.containerElement.text(this.control.name);
                    }

                    if (this.control && !this.isTextObject) {
                        if(this.control.supportsStates()) {
                            this._registerForStates(this.control.uuidAction, [
                                'stateTextForInfo',
                                'stateTextColor',
                                'stateText',
                            ]);
                            if (this.control.getSwitch(this.control.getStates()) && Feature.SYSTEM_SCHEME_EXTENSIONS) {
                                this._registerForStates(this.control.uuidAction, [
                                    'isActive',
                                ]);
                            }
                        } else {
                            if (this._supportsActions && this.actionsVisible) {
                                this._updateControls();
                                this.element.css('z-index', '2');
                            } else {
                                this.element.css('z-index', '1');
                                this.controlsElement.remove();
                            }
                        }
                    } else {
                        this.containerElement.parent().css('cursor', 'default');
                        this.controlsElement.remove();
                    }

                    btn = new GUI.LxButton(this, this.element[0]);
                    this.addToHandledSubviews(btn);
                    if(!this.isTextObject) {
                        btn.onButtonTapped = this.onViewClicked.bind(this)
                    }
                }.bind(this),
            );
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments);

            if (!this.control && !this.isTextObject) {
                if(Feature.SYSTEM_SCHEME_EXTENSIONS) {
                    this.containerText.text('--');
                } else {
                    this.containerElement.text('--');
                }
            }

            this.element.css('top', this.pos.y + 'px');
            this.element.css('left', this.pos.x + 'px');
            if (this.size) {
                this.element.css('width', this.size.width + 'px');
                this.element.css('height', this.size.height + 'px');
            }
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments).then(() => {
                if(Feature.SYSTEM_SCHEME_EXTENSIONS) {
                    this._handleLineClamp();
                }
            });
        }

        destroy() {
            if (this.control && !this.isTextObject) {
                this._unregisterStates(this.control.uuidAction);
            }

            super.destroy(...arguments);
        }

        receivedStates(states) {
            this.states = states;
            if(this.control.uuidAction) {
                Debug.Control.SystemScheme && console.log(this.control.uuidAction + ": stateTextColor -->" + states.stateTextColor);
                Debug.Control.SystemScheme && console.log(this.control.uuidAction + ": stateTextForInfo -->" + states.stateTextForInfo);
            }
            this.element.css('color', states.stateTextColor || Color.WHITE);
            
            if (states.messageCenterEntries && states.messageCenterEntries.length) {
                this.updateBorderColorOfView(MessageCenterHelper.getColorForSeverityEntry(states.messageCenterEntries[0]));
            }

            if(Feature.SYSTEM_SCHEME_EXTENSIONS) {
                const prefix = this.text.length ? `${this.text} ` : '';
                const defaultText = `${prefix}${states.stateTextForInfo || states.stateText || (states.isActive ? _('on') : _('off'))}` || _('unknown');
                
                if(this.control.details && Object.keys(this.control.details).some(key => key.includes('image') || key.includes('text'))) {
                    if(this.control.details.hasOwnProperty('image')) {
                        this.appendReactComp({reactComp: LxReactImageView, compProps: {source: states.iconSrc, imageStyle: {fill: states.iconColor }, containerStyle: { width: 36, height: 36, display: 'inline-block' } }, target: this.containerText}).then((instance) => {
                            this.symbolCompInstance = instance;
                            this.containerText.css('display', 'flex');
                            this.containerText.css('align-items', 'center');
                            this.containerText.css('justify-content', 'center');
                            this.containerText.children().first().css('max-width', 'fit-content');
                            if(this.containerText.children().first().attr('id') !== 'prepend-text' && prefix) {
                                this.containerText.prepend($('<div id="prepend-text" style="display: inline-flex; justify-items: center; margin-right: 10px">').text(prefix));
                            }
                        });
                    }
                    if(this.control.details.hasOwnProperty('text')) {
                        if(states.text) {
                            states.text && this.containerText.text(`${prefix}${states.text}`);
                        } else {
                            this.containerText.text(defaultText);
                        }
                    }
                    if(this.control.details.hasOwnProperty('color')) {
                        this.element.css('color', states.textColor);
                    }                    
                } else {
                    this.containerText.text(defaultText);
                }

                if(this._supportsActions && this.actionsVisible) {
                    this._updateControls();
                    this.element.css('z-index', '2');
                } else {
                    this.controlsElement.remove();
                }
            } else {
                this.containerElement.text(states.stateTextForInfo || _('unknown'));
            }
        }

        onViewClicked(el, ev) {
            stopEventPropagation(ev);
            if (this.control) {
                NavigationComp.showControlContent(this.control);
            } else {
                NavigationComp.showPopup({
                    message: _('system-scheme.no-permission'),
                    buttonOk: false,
                    buttonCancel: _('ok.short')
                });
            }
        }

        updateBorderColorOfView(color) {
            this.element.css('border-color', color);
        }

        _getLineHeightInPx(elem) {
            const temp = document.createElement('div');
            temp.textContent = '0';
            elem.insertAdjacentElement('beforeend', temp);
            const h = temp.scrollHeight;
            temp.remove();
            return h;
        }

        _handleLineClamp() {
            const elem = this.containerElement[0];
            const clamp = Math.floor(elem.clientHeight / this._getLineHeightInPx(this.containerText[0])).toString();
            this.containerText.css('-webkit-line-clamp', clamp);
            this.containerText.css('line-clamp', elem.clientHeight / clamp);
        }

        _addSwitch({ active: isActive, command0, command1, disabled }) {
            const switchElement = $(
                '<div class="control-state-view__switch"></div>',
            );
            const switchView = new GUI.LxSwitch(this, switchElement[0], Color.STATE_ACTIVE, false, 0, isActive, 'rgba(118, 118, 128, 0.24)');
            switchView.onStateChanged = () => {
                this.control.sendCommand(isActive ? command0 : command1);
            }
            return GUI.animationHandler
                .append(switchElement, this.containerElement.children().last())
                .then(() => {
                    this._switchButton = new GUI.LxButton(
                        this,
                        switchElement[0],
                    );
                    return this.addToHandledSubviews(this._switchButton).then(
                        () => {
                            this._switchButton.onButtonTapped = function (source, ev) {
                                stopEventPropagation(ev);
                                this.switchView._handleTap(ev);
                            }
                            return switchView;
                        },
                    );
                });
        }

        _removeSwitch() {
            let promise;
            if (this._switchButton) {
                promise = this.removeSubview(this._switchButton).then(() => {
                    this._switchButton = null;
                });
            } else {
                promise = Q.resolve(true);
            }

            if (this.switchView) {
                promise = promise.then(() => {
                    GUI.animationHandler.remove(this.switchView.getElement()).then(() => {
                        this.switchView = null;
                    });
                });
            }

            return promise;
        }

        _addButton(button) {
            let buttonElement = $('<div class="control-state-view__button"></div>');
            buttonElement.iconColor = Color.WHITE;
            let buttonView = new GUI.LxButton(this, buttonElement[0], black54, null, true);
            if(!!button.reactIcon) {
                buttonView.appendReactComp({reactComp: button.reactIcon, compProps: {isLoading: false}});
                buttonElement.addClass('control-state-view__button--react');
            } else {
                buttonElement.append(ImageBox.getResourceImage(button.iconSrc));
            }
            buttonView.useChildsAsActiveParts('fill');

            return this.appendSubview(buttonView, this.controlsElement).then(() => {
                return buttonView;
            });
        }

        _sendCmd(cmd) {    
            if (typeof cmd === "function") {
                cmd = cmd();
            }
    
            if (cmd !== undefined) {
                // also possible, that another action is triggered.. (eg. App/Webpage)
                return this.control.sendCommand(cmd);
            }
        }

        _sendCmds(cmds) {
            Object.values(cmds).forEach(this._sendCmd.bind(this));
        }

        _registerBtnCallback(btn, callback, cmd) {
            if (typeof cmd === "string" || typeof cmd === "function") {
                btn[callback] = (btn, ev) => {
                    stopEventPropagation(ev);
                    this._sendCmd.call(this, cmd);
                }
            } else if (typeof cmd === "object" && !Array.isArray(cmd)) {
                btn[callback] = (btn, ev) => {
                    stopEventPropagation(ev);
                    this._sendCmds.call(this, cmd);
                }
            } else {
                console.error("Cannot register button callback " + callback + " - only command-strings or functions allowed!");
            }
        }

        _registerButton(buttonView, config) {
            var cmdObj;

            if (typeof config.command === "object") {
                cmdObj = config.command;
                cmdObj.hit && this._registerBtnCallback(buttonView, "onButtonHit", cmdObj.hit);
                cmdObj.release && this._registerBtnCallback(buttonView, "onButtonReleased", cmdObj.release);
                cmdObj.tap && this._registerBtnCallback(buttonView, "onButtonTapped", cmdObj.tap);
                cmdObj.doubleTap && this._registerBtnCallback(buttonView, "onButtonDoubleTapped", cmdObj.doubleTap);

                if (cmdObj.tick) {
                    buttonView.activateTicks(cmdObj.tickInterval);

                    this._registerBtnCallback(buttonView, "onButtonTicked", cmdObj.tick);
                }
            } else if (typeof config.command === "function") {
                buttonView.onButtonTapped = (btn, ev) => {
                    stopEventPropagation(ev);
                    config.command();
                }
            } else if (typeof config.command === "string") {
                buttonView.onButtonTapped = (btn, ev) => {
                    stopEventPropagation(ev);
                    this.control.sendCommand(config.command);
                }
            }

            buttonView.setEnabled(!config.disabled);
        }

        _isWaitingForControlUpdate() {
            return this._updateControlsPrms && this._updateControlsPrms.inspect().state === "pending";
        }

        _updateControls() {
            const [hasSwitch, hasButtons] = [this.control.getSwitch(this.states), this.control.getButton0(this.states) || this.control.getButton1(this.states)];
            const promises = [];
            hasSwitch && promises.push(this._updateSwitch(this.states));
            hasButtons && promises.push(this._updateButtons(this.states));

            Debug.Control.SystemScheme && console.log(this.viewId, "_updateControls");
            var prms;
    
            if (this._isWaitingForControlUpdate()) {
                if (this._updateControlsEnqueued) {
                    Debug.Control.SystemScheme && console.warn("_updateControls is already updating, Queue this update!");
                    prms = this._updateControlsEnqueuedPrms;
                } else {
                    this._updateControlsEnqueued = true;
                    prms = this._updateControlsPrms.then(function () {
                        this._updateControlsEnqueued = false;
                        Debug.Control.SystemScheme && console.warn("_updateControls queue finished, update now!");
                        return this._updateControls();
                    }.bind(this));
                    this._updateControlsEnqueuedPrms = prms;
                }
            } else {
                prms = Q.all(promises || []);
                this._updateControlsPrms = prms;
            }
    
            return prms.then(() => {
                if(!!this.button0 && !!this.button1) {
                    this.controlsElement.css('max-width', '75px');
                } else if (!!this.switchView || !!this.button0 || !!this.button1) {
                    this.controlsElement.css('max-width', '44px');
                }
                if(this.switchView) { // Workaround for https://projects.zoho.eu/portal/loxone#buginfo/16571000000023018/16571000011643268
                    // If switch is interacted with and then changed from within the Control Content, going back to the System Scheme will not update the switch, this will recreate it upon entering the System Scheme with reduced flickering
                    this.element.css('min-height', this.element.outerHeight());
                    this.element.css('min-width', this.element.outerWidth());
                    this.containerText.css('text-align', 'start');
                    this._removeSwitch().then(() => {
                        this._addSwitch(hasSwitch).then((switchView) => {
                            this.switchView = switchView;
                            this.element.css('min-height', '');
                            this.element.css('min-width', '');
                            this.containerText.css('text-align', 'center');
                        });
                    });
                }
            });
        }

        _updateButtons(states) {
            let promises = [];
            const btn0 = this.control.getButton0(states);
            const btn1 = this.control.getButton1(states);
            const _btn0 = JSON.stringify(btn0);
            const _btn1 = JSON.stringify(btn1);

            if(this._btn0Def && this._btn0Def.promise.inspect().state === "pending") {
                Debug.Control.SystemScheme && console.log(this.name + " Button already pending, return request");
                promises.push(this._btn0Def.promise.then(() => {this._updateButtons(states)}));
            } else if (btn0 && (!this._button0 || this._button0 !== _btn0)) {
                this._btn0Def = Q.defer();
                this._button0 = _btn0;

                if (this.button0) {
                    this.button0.getElement().remove();
                }

                Debug.Control.SystemScheme && console.log(this.name + " Adding button 0");
                promises.push(this._addButton(btn0).then((button) => {
                    this.button0 = button;
                    this._btn0Def.resolve();
                    this._registerButton(this.button0, btn0);
                }));
            } else if (!btn0 && this.button0) {
                Debug.Control.SystemScheme && console.log(this.name + " Removing button 0");
                promises.push(this.removeSubview(this.button0)).then(() => {
                    this.button0 = null;
                    this._button0 = null;
                });
            }

            if(this._btn1Def && this._btn1Def.promise.inspect().state === "pending") {
                Debug.Control.SystemScheme && console.log(this.name + " Button already pending, return request");
                promises.push(this._btn1Def.promise.then(() => {this._updateButtons(states)}));
            } else if (btn1 && (!this._button1 || this._button1 !== _btn1)) {
                this._btn1Def = Q.defer();
                this._button1 = _btn1;

                if (this.button1) {
                    this.button1.getElement().remove();
                }

                Debug.Control.SystemScheme && console.log(this.name + " Adding button 1");
                promises.push(this._addButton(btn1).then((button) => {
                    this.button1 = button;
                    this._btn1Def.resolve();
                    this._registerButton(this.button1, btn1);
                }));
            } else if (!btn1 && this.button1) {
                Debug.Control.SystemScheme && console.log(this.name + " Removing button 1");
                promises.push(this.removeSubview(this.button1)).then(() => {
                    this.button1 = null;
                    this._button1 = null;
                });
            }

            return Q.all(promises);
        }

        _updateSwitch() {
            const switchCfg = this.control.getSwitch(this.states);
            const { active: isActive, command0, command1 } = switchCfg || {};
            let promise;

            if (!this._switchButton && !this.switchView && switchCfg) {
                promise = this._addSwitch(switchCfg).then((switchView) => {
                    this.switchView = switchView;
                    this.switchView.onStateChanged = (newVal) => {
                        this.control.sendCommand(newVal ? command1 : command0);
                    };
                });
            } else {
                promise = Q.resolve(false);
            }

            return promise.then((res) => {
                    this.switchView && this.switchView.setActive(isActive);
                },
            );
        }

        get _supportsActions() {
            return this.control && this.control.getButton0(this.control.getStates()) || this.control.getButton1(this.control.getStates()) || this.control.getSwitch(this.control.getStates());
        }
    };

});
