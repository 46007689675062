import LxReactHorizontalSlider from "../../../react-comps/LxReactSlider/LxReactHorizontalSlider";
import LxReactComp from "../../../react-comps/LxReactComp";
import PropTypes from "prop-types";
import {LxSliderDefaultSettings} from "LxComponents";


/**
 * @prop {number} [min = 0] Minimum Value of slider
 * @prop {number} [max = 100] Maximum Value of slider
 * @prop {number} [step = 1] Step size of the slider
 * @prop [activeColors] colors gradient of the active part
 * @prop [hasGradientTrack] whether slider should have a gradient track
 * @prop {number} [updateRate = 0.25] min seconds between state changes
 * @prop {Boolean} [hasCustomRate] whether slider has a update rate
 * @prop {Object} [containerStyle] Style to apply on slider container
 * @prop {Boolean} [disabled = false] whether slider is draggable or not
 * @prop {Boolean} [hidden = false] whether slider is visible or not
 *
 */
class LxSlider extends LxReactComp {

    static DEFAULT_UPDATE_RATE = 0.25;

    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
        };
        this.options = {...props};

        //from previous constructor , handleType is unnecessary now
        if (!this.options) {
            this.options = { };
        }

        this.options.valueChangeTimeout = null;

        if (props.hasCustomRate) {
            this.options.updateRate = props.updateRate || LxSlider.DEFAULT_UPDATE_RATE;
        } else if (props.updateRate) {
            this.options.hasCustomRate = true;
        }

        if (props.antiGhostTimer) {
            this.options.cachedValue = props.value;
            this.options.antiGhostTimer = new AntiGhostTimer(() => {
                this.updateValueSafely(this.options.cachedValue);
            }, this.options.antiGhostDuration);
        }

        if (this.options.hasGradientTrack) {
            this.setActiveColors(this.options.activeColors);
        }

        this.disabled = !!this.options.disabled;
    }

    //region Public Methods
    updateProperties(props) {
        this.options.min = props.min || LxSliderDefaultSettings.defaultSettings.MIN;
        this.options.max = props.max || LxSliderDefaultSettings.defaultSettings.MAX;
        this.options.step = props.step || LxSliderDefaultSettings.defaultSettings.STEP;

        if (props.hasOwnProperty("hidden")) {
            this.options.hidden = props.hidden;
        }

        this.rerenderComponent();
        //this.setPosition(props.pos);

        if (this.options.min >= this.options.max) {
            console.warn("LxSlider min is greater or equal to max! -> won't work!");
        }
    }

    setEnabled(enabled) {
        this.disabled = !enabled;
    }

    setPosition(pos, forceUpdate = false) {
        var noGhostActive = !this.options.antiGhostTimer ||
            (this.options.antiGhostTimer && !this.options.antiGhostTimer.isAntiGhostTimerActive());

        if (forceUpdate || (noGhostActive && !this.options.isDragging && !this.options.valueChangeTimeout)) {
            this.updateValueSafely(pos);
        }
        this.options.cachedValue = pos;
    }

    updateValueSafely(newValue) {
        let newSafeValue = Math.min(this.options.max, Math.max(newValue, this.options.min))
        this.setState({value: newSafeValue});
    }

    updateGUIPosition(calc, fromGesture) {
        developerAttention("LxSliderV2: updateGUIPosition() is deprecated, not needed anymore\"")
    }

    setColor(color) {
        this.options.activeColor = color;
        this.rerenderComponent();
    }

    setActiveColors(activeColors) {
        //replace with [globalstyles.colors.white, globalstyles.colors.stateInActive
        this.options.activeColors = activeColors || ["#000", "#F0F0F0"];
        this.rerenderComponent();
    }

    rerenderComponent() {
        this.setState({})
    }

    resetAntiGhost() {
        developerAttention("LxSliderV2: resetAntiGhost() is deprecated, not needed anymore\"")
    }

    /**
     * Whether or not the slider is being used (drag active)
     * @returns {boolean} is it in use or not.
     */
    isDragging() {
        return this.options.isDragging;
    }
    //endregion Public methods

    //region Private methods
    _onValueChanged(newValue) {
        this.options.cachedValue = newValue;
        this.options.antiGhostTimer && this.options.antiGhostTimer.fire();
        this.updateValueSafely(newValue);
        if (this.options.hasCustomRate) {
            if (!this.options.valueChangeTimeout) {
                this.options.valueChangeTimeout = setTimeout(() => {
                    this._triggerOnPositionChanged(this.state.value, true);
                } , this.options.updateRate * 1000);
            }
        } else {
            this._triggerOnPositionChanged(newValue, false);
        }
    }

    _triggerOnPositionChanged(value, afterTimeout) {
        if (this.onPositionChanged) {
            this.onPositionChanged(value, value === this.options.min, this.options.isDragging);
        }
        if (afterTimeout) {
            this.options.valueChangeTimeout = null;
        }
    }

    _onDraggingChanged(isDragging) {
        this.options.isDragging = isDragging;
        if (this.onDraggingChanged) {
            this.onDraggingChanged(this.state.value, isDragging);
        }
    }
    //endregion Private methods

    render() {
        if (this.options.hidden) {
            return null;
        }
        return (
            <LxReactHorizontalSlider
                min={this.options.min}
                max={this.options.max}
                step={this.options.step}
                value={this.state.value}
                onValueChanged={(newVal) => this._onValueChanged(newVal)}
                onDraggingChanged={(isDragging) => this._onDraggingChanged(isDragging)}
                showLabels={false}
                activeColor={this.options.activeColor}
                disabled={this.disabled}
                gradientColors={this.options.activeColors}
                containerStyle={this.options.containerStyle}
                thumbStyle={this.options.thumbStyle}
                disabledThumbStyle={this.options.disabledThumbStyle}
            />
        );
    }
}

export default LxSlider
