import {CCLocation, ControlContext, LxBaseControlContent, useCCEvent} from "LxComponents";
import PropTypes from "prop-types";
import globalStyles from "GlobalStyles";
import { useContext, useCallback, useRef } from "react"
import {useFocusEffect} from "@react-navigation/native";
import LxAlarmTrackerView from "./LxAlarmTrackerView";
import ControlType from "../../daytimerControl/daytimerControlEnums";

function AlarmControlContentBase(props) {
    const {control, states, isAlert} = useContext(ControlContext)
    const trackerUUID = Object.keys(control.subControls)[0];
    const trackerControl = control.subControls[trackerUUID];
    const dismissTimeoutRef = useRef();
    const navigation = props.navigation;

    if (isAlert) {
        useCCEvent([SandboxComp.ECEvent.DeviceActivityChanged], (isActive) => {
            if (isActive && dismissTimeoutRef.current) {
                stopDismissTimeout();
            } else if (!isActive && !dismissTimeoutRef.current) {
                startDismissTimeout();
            }
        })
    }


    // This content should be dismissed automatically after a given timeout (when dismissed after being triggered)
    const unblockAmbientAndScreenSaverFn = useRef(null);
    useFocusEffect(
        useCallback(() => {
            if (isAlert) {
                unblockAmbientAndScreenSaverFn.current = SandboxComponent.blockAmbientAndScreensaver();
                startDismissTimeout();
            }

            return () => {
                unblockAmbientAndScreenSaverFn.current && unblockAmbientAndScreenSaverFn.current();
                stopDismissTimeout();
            }
        }, [states])
    )


    const startDismissTimeout = () => {
        if (!states.alarmActive && !dismissTimeoutRef.current) {
            dismissTimeoutRef.current = setTimeout(() => {
                unblockAmbientAndScreenSaverFn.current && unblockAmbientAndScreenSaverFn.current();
                navigation.goBack();
            }, 60 * 1000)
        }
    }

    const stopDismissTimeout = () => {
        if (dismissTimeoutRef.current) {
            clearTimeout(dismissTimeoutRef.current);
            dismissTimeoutRef.current = null;
        }
    }

    const {
        drillMessage = _("controls.alarm-base.drill.verify.message"),
        drillTitle = _("controls.alarm-base.drill.verify.title"),
        drillStartButtonTitle = _("controls.alarm-base.drill.start"),
        isAlarmActiveFn,
    } = props;

    /**
     * Starts the alarm drill, but asks the user if he is sure and gives a quick description what this is
     * @returns {*}
     * @private
     */
    const startAlarmDrill = () => {
        return NavigationComp.showPopup({
            title: drillTitle,
            message: drillMessage,
            buttonOk: drillStartButtonTitle,
            buttonCancel: true,
            icon: Icon.WARNING,
            color: globalStyles.colors.orange
        }).then(() => control.sendCommand(Commands.ALARM.START_DRILL));
    }

    const getContextMenuOptions = (control, states) => {
        let options = []

        if (Feature.START_DRILL) {
            options.push({
                title: drillStartButtonTitle,
                action: startAlarmDrill.bind(this)
            });
        }

        return options;
    }

    const isAlarmActive = () => {
        if (isAlarmActiveFn) {
            return isAlarmActiveFn(control, states)
        } else {
            return states.alarmActive;
        }
    }


    return <LxBaseControlContent getAdditionalContextMenuOptions={getContextMenuOptions} showMoreSection={!states.alarmActive} >
        {isAlarmActive() && <LxAlarmTrackerView trackerControl={trackerControl} location={CCLocation.DEFAULT}/>}
    </LxBaseControlContent>
}

AlarmControlContentBase.propTypes = {
    drillMessage: PropTypes.string,
    drillTitle: PropTypes.string,
    drillStartButtonTitle: PropTypes.string,
    isAlarmActiveFn: PropTypes.func,
    navigation: PropTypes.object
}

export default AlarmControlContentBase;
