'use strict';

define(['AutopilotEnums', 'AutopilotUtility', 'IRoomControllerV2ControlEnums', 'SolarPumpControlEnums', "WindowControlEnums", "AalEmergencyControlEnums"], function (AutopilotEnums, AutopilotUtility, iRoomV2ControlEnums, SolarPumpControlEnums, WindowControlEnums, AalEmergencyControlEnums) {
    return class AutopilotEventDetailsControlScreen extends GUI.Screen {
        constructor(details) {
            super($('<div class="autopilot-event-details-control-screen" />'));
            this.originalEvent = details.event;
            this.event = details.event ? cloneObject(details.event) : {};
            this.editMode = details.editMode || false;
            this.validInput = true;
            this.events = {};
            this.control = ActiveMSComponent.getStructureManager().getControlByUUID(this.event.controlUUID);
            this.title = this.control.name;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.elements = {};
            this.tableView = new GUI.TableViewV2(this, this);
            this.appendSubview(this.tableView);

            this._initialize();
        }

        viewWillAppear() {
            this.tableView.reload();
            super.viewWillAppear(...arguments);
        }

        getURL() {
            return "Autopilot/EventDetailsControl";
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarText() {
            return this.title;
        }

        titleBarAction() {
            if (this.editMode) {
                this._saveChanges();
            } else {
                this.ViewController.navigateBack();
            }
        }

        // TABLE VIEW DELEGATES
        styleForTableView(tableView) {
            return TableViewStyle.GROUPED;
        }

        numberOfSections() {
            return this.contentDetails.sections.length;
        }

        titleForHeaderInSection(sectionIdx) {
            if (this.contentDetails.forceHeader || this.contentDetails.sections.length > 1) {
                var section = this.contentDetails.sections[sectionIdx];
                return section.title;
            }

            return ""; // do not show section header if there is only one entry
        }

        numberOfRowsInSection(sectionIdx) {
            var section = this.contentDetails.sections[sectionIdx];
            var rowCount = section.rows.length;

            if (section.type === AutopilotEnums.EventDetailTypes.Number || section.type === AutopilotEnums.EventDetailTypes.DecimalNumber) {
                rowCount += AutopilotEnums.Operators.length;
            } // this adds or removes the "Add event" button


            if (!this.editMode) {
                rowCount++;
            }

            return rowCount;
        }

        cellTypeForCellAtIndex(section, row) {
            var sectionRows = this.contentDetails.sections[section].rows; // add 'create event' button

            if (this.contentDetails.sections[section].type === AutopilotEnums.EventDetailTypes.Number || this.contentDetails.sections[section].type === AutopilotEnums.EventDetailTypes.DecimalNumber) {
                if (row < AutopilotEnums.Operators.length) {
                    return GUI.TableViewV2.CellType.CHECKABLE;
                } else if (row === sectionRows.length + AutopilotEnums.Operators.length) {
                    return GUI.TableViewV2.CellType.BUTTON_CELL_CUSTOM;
                } else {
                    return GUI.TableViewV2.CellType.INPUT;
                }
            }

            if (row === sectionRows.length) {
                return GUI.TableViewV2.CellType.BUTTON_CELL_CUSTOM;
            }

            return GUI.TableViewV2.CellType.CHECKABLE;
        }

        contentForCell(cell, section, row) {
            var sectionObj = this.contentDetails.sections[section];
            var sectionRows = sectionObj.rows;
            var isNumberInput = this.contentDetails.sections[section].type === AutopilotEnums.EventDetailTypes.Number || this.contentDetails.sections[section].type === AutopilotEnums.EventDetailTypes.DecimalNumber;

            if (isNumberInput) {
                if (row < AutopilotEnums.Operators.length) {
                    // add operator cells
                    var operator = AutopilotEnums.Operators[row];
                    return {
                        title: operator.title,
                        selected: this.event.operator === operator.id,
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED
                    };
                } else if (row === sectionRows.length + AutopilotEnums.Operators.length) {
                    return {
                        title: _("autopilot.rule.event.add"),
                        color: window.Styles.colors.green
                    };
                } else {
                    var regex = this.contentDetails.sections[section].type === AutopilotEnums.EventDetailTypes.Number ? Regex.INT_VALUE : Regex.DECIMAL_VALUE;
                    var currRow = sectionRows[0];
                    var value = currRow.value;

                    if (sectionObj.valueType) {
                        if (sectionObj.valueType === AutopilotEnums.ValueTypes.Percent) {
                            value = value * 100.0;
                        }
                    }

                    return {
                        type: "text",
                        value: this.editMode ? value : "",
                        validationRegex: regex,
                        placeholder: _("autopilot.rule.action.placeholder-value")
                    };
                }
            } // add 'create event' button


            if (row === sectionRows.length) {
                return {
                    title: _("autopilot.rule.event.add"),
                    color: window.Styles.colors.green
                };
            }

            var item = sectionRows[row];
            return {
                title: item.title,
                clickable: true,
                selected: item.selected,
                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED
            };
        }

        contentForFooterInSection(sectionIdx, tableView) {
            var section = this.contentDetails.sections[sectionIdx];

            if (section && section.description) {
                return {
                    iconSrc: "resources/Images/General/icon-description.svg",
                    title: _("description"),
                    message: section.description
                };
            }
        }

        _setEventValue(section, prop, val) {
            if (this.editMode) {
                this.event[prop] = val;
            } else {
                if (!this.events.hasOwnProperty(section)) {
                    this.events[section] = JSON.parse(JSON.stringify(this.event));
                }

                this.events[section][prop] = val;
            }
        }

        didCheckCell(cell, sectionIdx, row) {
            var section = this.contentDetails.sections[sectionIdx];

            this._setEventValue(sectionIdx, "id", section.state);

            if (section.type === AutopilotEnums.EventDetailTypes.Radio && row < section.rows.length) {
                this._setEventValue(sectionIdx, "value", section.rows[row].value);
            } else {
                // number, date or something like this (with operator)
                this._setEventValue(sectionIdx, "operator", AutopilotEnums.Operators[row].id);
            }
        }

        didSelectCell(cell, sectionIdx, row) {
            // call edit rule screen
            var section = this.contentDetails.sections[sectionIdx];
            var isCreateButtonCell = false;

            if (section.type !== AutopilotEnums.EventDetailTypes.Radio) {
                isCreateButtonCell = row === AutopilotEnums.Operators.length + 1;
            } else {
                isCreateButtonCell = row === section.rows.length;
            }

            if (isCreateButtonCell) {
                this._saveChanges(sectionIdx, section);
            }
        }

        textChanged(section, row, tableView, value, valid, valueDidChange) {
            this._setEventValue(section, "value", value); // this.event.value = value;


            this.textHasChanged = true;
            this.validInput = valid;
        }

        onPickerChanged(section, row, tableView, value) {
        }

        _validateInputs(sectionObj) {
            if (sectionObj.type === AutopilotEnums.EventDetailTypes.Radio) {
                if (!this.event || typeof this.event.value === 'undefined') {
                    return _("input.no-value-selected");
                }
            } else {
                if (!this.validInput) {
                    if (sectionObj.type === AutopilotEnums.EventDetailTypes.Number) {
                        return _("input.number-required");
                    } else {
                        return _("input.decimal-number-required");
                    }
                }

                if (!this.event) {
                    return _("input.no-value-selected");
                } else if (typeof this.event.value === 'undefined') {
                    return _("input.no-input-value");
                } else if (typeof this.event.operator === 'undefined') {
                    return _("input.no-comparison-operator");
                } // check if value is outside the valid range


                if (sectionObj.rows.length > 0 && (sectionObj.rows[0].hasOwnProperty("minValue") || sectionObj.rows[0].hasOwnProperty("maxValue"))) {
                    var min = sectionObj.rows[0].minValue;
                    var max = sectionObj.rows[0].maxValue;

                    if (this.event.value < min || this.event.value > max) {
                        return _("input.out-of-range") + " (" + min + " - " + max + ")";
                    }
                }
            }

            return true;
        }

        _saveChanges(sectionIdx, sectionObj) {
            if (!sectionObj) {
                // this is the case if we are in edit mode and we only have one section
                sectionObj = this.editSection;
            }

            if (!this.editMode) {
                var event = this.events[sectionIdx];

                if (!event) {
                    this.events[sectionIdx] = JSON.parse(JSON.stringify(this.event));
                    this.event = this.events[sectionIdx];
                } else {
                    this.event = event;
                }
            }

            var validationResult = this._validateInputs(sectionObj);

            if (validationResult === true) {
                if (this.textHasChanged && sectionObj.valueType) {
                    if (sectionObj.valueType === AutopilotEnums.ValueTypes.Percent) {
                        this.event.value = this.event.value / 100.0;
                    }
                }

                if (this.editMode) {
                    AutopilotUtility.copyValues(this.event, this.originalEvent);
                    NavigationComp.dispatchEventToUI(AutopilotEnums.CommunicationEvents.UPDATE_EVENT, this.originalEvent);
                } else {
                    NavigationComp.dispatchEventToUI(AutopilotEnums.CommunicationEvents.ADD_EVENT, this.event);
                    NavigationComp.disableAnimationsTemp();
                }

                this.ViewController.navigateBackTo(AutopilotEnums.ScreenStates.AutopilotRuleScreen);
            } else {
                return AutopilotUtility.showValidationError(validationResult);
            }
        }

        _initialize() {
            // if editMode -> just show the state details
            // if !editMode -> show all possible states for the control
            if (this.editMode) {
                // just show single entry if we are in edit mode
                this._getDetailsByControl(this.control, this.event.id);
            } else {
                // show all possible values for control
                this._getDetailsByControl(this.control);
            }
        }

        _getDetailsByControl(control, stateUUID) {
            this.contentDetails = {
                sections: [],
                forceHeader: true
            };

            switch (control.type) {
                case ControlType.ALARM:
                    this._populateAlarmDetails(this.contentDetails, control, stateUUID);

                    break;

                case ControlType.AAL_SMART_ALARM:
                    this._populateAalSmartAlarmDetails(this.contentDetails, control, stateUUID);

                    break;

                case ControlType.ALARM_CLOCK:
                    this._populateAlarmClockDetails(this.contentDetails, control, stateUUID);

                    break;

                case ControlType.CAR_CHARGER:
                    this._populateCarChargerDetails(this.contentDetails, control, stateUUID);

                    break;

                case ControlType.DAYTIMER:
                    this._populateDaytimerDetails(this.contentDetails, control, stateUUID);

                    break;

                case ControlType.FRONIUS:
                    this._populateEnergyMonitorDetails(this.contentDetails, control, stateUUID);

                    break;

                case ControlType.GATE:
                    this._populateGateDetails(this.contentDetails, control, stateUUID);

                    break;

                case ControlType.I_ROOM:
                    this._populateRoomControllerDetails(this.contentDetails, control, stateUUID);

                    break;

                case ControlType.I_Room_V2:
                    this._populateRoomV2ControllerDetails(this.contentDetails, control, stateUUID);

                    break;

                case ControlType.INFO_ONLY_ANALOG:
                    this._populateAnalogVirtualInputDetails(this.contentDetails, control, stateUUID);

                    break;

                case ControlType.MAILBOX:
                    this._populateMailBoxDetails(this.contentDetails, control, stateUUID);



                    break;

                case ControlType.PUSHBUTTON:
                case ControlType.SWITCH:
                case ControlType.INFO_ONLY_DIGITAL:
                case ControlType.PRESENCE_DETECTOR:
                    this._populateVirtualInputDetails(this.contentDetails, control, stateUUID);

                    break;

                case ControlType.JALOUSIE:
                    this._populateJalousieDetails(this.contentDetails, control, stateUUID);

                    break;

                case ControlType.LIGHT:
                    this._populateLightControllerDetails(this.contentDetails, control, stateUUID);

                    break;

                case ControlType.LIGHT_V2:
                    this._populateLightV2ControllerDetails(this.contentDetails, control, stateUUID);

                    break;

                case ControlType.METER:
                    this._populateMeterDetails(this.contentDetails, control, stateUUID);

                    break;

                case ControlType.SLIDER:
                    this._populateAnalogInputDetails(this.contentDetails, control, stateUUID);

                    break;

                case ControlType.WINDOW_MONITOR:
                    this._populateWindowMonitorDetails(this.contentDetails, control, stateUUID);

                    break;

                case ControlType.SOLAR_PUMP:
                    this._populateSolarPumpDetails(this.contentDetails, control, stateUUID);

                    break;

                case ControlType.STEAK:
                    this._populateSteakDetails(this.contentDetails, control, stateUUID);

                    break;

                case ControlType.WINDOW:
                    this._populateWindowDetails(this.contentDetails, control, stateUUID);

                    break;

                case ControlType.AAL_EMERGENCY:
                    this._populateAalEmergencyAlarmDetails(this.contentDetails, control, stateUUID);

                default:
                    break;
            }
        }

        _populateAlarmDetails(details, control, stateUUID) {
            var armedConfig = {
                type: AutopilotEnums.EventDetailTypes.Radio,
                state: control.states["armed"],
                title: _("alarm.armed"),
                rows: [{
                    value: 0,
                    title: _("controls.alarm.disarmed"),
                    selected: this.event.value === AutopilotEnums.AlarmStates.Disarmed
                }, {
                    value: 1,
                    title: _("controls.alarm.armed"),
                    selected: this.event.value === AutopilotEnums.AlarmStates.Armed
                }]
            };
            var levelConfig = {
                type: AutopilotEnums.EventDetailTypes.Radio,
                state: control.states["level"],
                title: _("alarm.level"),
                rows: [{
                    value: AutopilotEnums.AlarmLevels.NotActive,
                    title: _("controls.alarm.level.not-active"),
                    selected: this.event.value === AutopilotEnums.AlarmLevels.NotActive
                }, {
                    value: AutopilotEnums.AlarmLevels.Silent,
                    title: _("controls.alarm.level.silent"),
                    selected: this.event.value === AutopilotEnums.AlarmLevels.Silent
                }, {
                    value: AutopilotEnums.AlarmLevels.Acoustic,
                    title: _("controls.alarm.level.acoustic"),
                    selected: this.event.value === AutopilotEnums.AlarmLevels.Acoustic
                }, {
                    value: AutopilotEnums.AlarmLevels.Optic,
                    title: _("controls.alarm.level.optic"),
                    selected: this.event.value === AutopilotEnums.AlarmLevels.Optic
                }, {
                    value: AutopilotEnums.AlarmLevels.Internal,
                    title: _("controls.alarm.level.internal"),
                    selected: this.event.value === AutopilotEnums.AlarmLevels.Internal
                }, {
                    value: AutopilotEnums.AlarmLevels.External,
                    title: _("controls.alarm.level.external"),
                    selected: this.event.value === AutopilotEnums.AlarmLevels.External
                }, {
                    value: AutopilotEnums.AlarmLevels.Remote,
                    title: _("controls.alarm.level.remote"),
                    selected: this.event.value === AutopilotEnums.AlarmLevels.Remote
                }]
            };

            if (stateUUID) {
                if (stateUUID === control.states["armed"]) {
                    details.sections.push(armedConfig);
                    this.editSection = armedConfig;
                } else if (stateUUID === control.states["level"]) {
                    details.sections.push(levelConfig);
                    this.editSection = levelConfig;
                }
            } else {
                details.sections.push(armedConfig);
                details.sections.push(levelConfig);
            }
        }

        _populateAalSmartAlarmDetails(details, control, stateUUID) {
            var alarmIsLeaveConfig, alarmIsLockedConfig, alarmLevelConfig;
            alarmIsLeaveConfig = {
                type: AutopilotEnums.EventDetailTypes.Radio,
                state: control.states["isLeaveActive"],
                title: _("controls.aal-smart-alarm.autopilot.leave"),
                rows: [{
                    title: _("inactive"),
                    value: 0,
                    selected: this.event.value === 0
                }, {
                    title: _("active"),
                    value: 1,
                    selected: this.event.value === 1
                }]
            };
            alarmIsLockedConfig = {
                type: AutopilotEnums.EventDetailTypes.Radio,
                state: control.states["isLocked"],
                title: _("controls.aal-smart-alarm.autopilot.locked"),
                rows: [{
                    title: _("inactive"),
                    value: 0,
                    selected: this.event.value === 0
                }, {
                    title: _("active"),
                    value: 1,
                    selected: this.event.value === 1
                }]
            };
            alarmLevelConfig = {
                type: AutopilotEnums.EventDetailTypes.Radio,
                state: control.states["alarmLevel"],
                title: _("alarm.level"),
                rows: [{
                    value: 0,
                    title: _("controls.alarm.level.not-active"),
                    selected: this.event.value === 0
                }, {
                    value: 1,
                    title: _("controls.aal-smart-alarm.level.pre-alarm"),
                    selected: this.event.value === 1
                }, {
                    value: 2,
                    title: _("controls.aal-smart-alarm.level.main-alarm"),
                    selected: this.event.value === 2
                }]
            };

            if (stateUUID) {
                if (stateUUID === control.states["isLeaveActive"]) {
                    details.sections.push(alarmIsLeaveConfig);
                    this.editSection = alarmIsLeaveConfig;
                } else if (stateUUID === control.states["isLocked"]) {
                    details.sections.push(alarmIsLockedConfig);
                    this.editSection = alarmIsLockedConfig;
                } else if (stateUUID === control.states["alarmLevel"]) {
                    details.sections.push(alarmLevelConfig);
                    this.editSection = alarmLevelConfig;
                }
            } else {
                details.sections.push(alarmLevelConfig, alarmIsLeaveConfig, alarmIsLockedConfig);
            }
        }

        _populateLightControllerDetails(details, control) {
            if (this.event.controlUUID) {
                SandboxComponent.registerForStateChangesForUUID(this.event.controlUUID, this, function (states) {
                    var config = {
                        type: AutopilotEnums.EventDetailTypes.Radio,
                        rows: [],
                        state: control.states["activeScene"]
                    };
                    var scenes = states.scenes;

                    for (var nr in scenes) {
                        if (!scenes.hasOwnProperty(nr)) {
                            continue;
                        }

                        var name = scenes[nr];
                        config.rows.push({
                            title: name,
                            value: parseInt(nr),
                            selected: this.event.value === parseInt(nr)
                        });
                    }

                    config.rows.push({
                        title: _('controls.lightcontroller.all-on'),
                        value: 9,
                        selected: this.event.value === 9
                    });
                    config.rows.push({
                        title: _('controls.lightcontroller.all-off'),
                        value: 0,
                        selected: this.event.value === 0
                    });
                    details.sections.push(config);
                    this.editSection = config;
                    SandboxComponent.unregisterForStateChangesForUUID(this.event.controlUUID, this);
                    this.tableView.reload();
                }.bind(this));
            }
        }

        _populateLightV2ControllerDetails(details, control) {
            if (this.event.controlUUID && Feature.LIGHTV2_AUTO_EVENTS) {
                SandboxComponent.registerForStateChangesForUUID(this.event.controlUUID, this, function (states) {
                    var config = {
                        type: AutopilotEnums.EventDetailTypes.Radio,
                        rows: [],
                        state: control.states["activeMoods"]
                    };
                    states.moodList.forEach(function (mood) {
                        config.rows.push({
                            title: mood.name,
                            value: parseInt(mood.id),
                            selected: this.event.value === parseInt(mood.id)
                        });
                    }.bind(this));
                    details.sections.push(config);
                    this.editSection = config;
                    SandboxComponent.unregisterForStateChangesForUUID(this.event.controlUUID, this);
                    this.tableView.reload();
                }.bind(this));
            }
        }

        _populateAnalogInputDetails(details, control) {
            var config = {
                title: _('slider.value'),
                type: AutopilotEnums.EventDetailTypes.Number,
                state: control.states["value"],
                rows: [{
                    value: this.event.value || ""
                }]
            };
            details.sections.push(config);
            this.editSection = config;
        }

        _populateAnalogVirtualInputDetails(details, control) {
            var config = {
                title: _('infoonlyanalog.value'),
                type: AutopilotEnums.EventDetailTypes.DecimalNumber,
                state: control.states["value"],
                rows: [{
                    value: this.event.value || ""
                }]
            };
            details.sections.push(config);
            this.editSection = config;
        }

        _populateMailBoxDetails(details, control) {
            // mail received
            details.sections.push({
                title: _("controls.mailbox.mail-received"),
                type: AutopilotEnums.EventDetailTypes.Radio,
                state: control.states["mailReceived"],
                rows: [{
                    value: 1,
                    title: _("yes"),
                    selected: this.event.value === 1
                }, {
                    value: 0,
                    title: _("no"),
                    selected: this.event.value === 0
                }]
            }); // packet received

            details.sections.push({
                title: _("controls.mailbox.packet-received"),
                type: AutopilotEnums.EventDetailTypes.Radio,
                state: control.states["packetReceived"],
                rows: [{
                    value: 1,
                    title: _("yes"),
                    selected: this.event.value === 1
                }, {
                    value: 0,
                    title: _("no"),
                    selected: this.event.value === 0
                }]
            });
        }

        _populateAalEmergencyAlarmDetails(details, control) {
            //state of alarm
            details.sections.push({
                title: _("status"),
                type: AutopilotEnums.EventDetailTypes.Radio,
                state: control.states["status"],
                rows: [{
                    value: AalEmergencyControlEnums.Status.NORMAL,
                    title: _("controls.aalemergency.ready"),
                    selected: this.event.value === AalEmergencyControlEnums.Status.NORMAL
                }, {
                    value: AalEmergencyControlEnums.Status.TRIGGERED,
                    title: _("controls.aalemergency.alarm-active"),
                    selected: this.event.value === AalEmergencyControlEnums.Status.TRIGGERED
                }, {
                    value: AalEmergencyControlEnums.Status.LOCKED,
                    title: _("controls.aalemergency.locked"),
                    selected: this.event.value === AalEmergencyControlEnums.Status.LOCKED
                }, {
                    value: AalEmergencyControlEnums.Status.DISABLED,
                    title: _("controls.aalemergency.disabled"),
                    selected: this.event.value === AalEmergencyControlEnums.Status.DISABLED
                }]
            });
        }

        _populatePowerDetails(details, control, stateUUID) {
            var powerFailure = {
                    title: _("controls.power.autopilot.power-failure"),
                    type: AutopilotEnums.EventDetailTypes.Radio,
                    state: control.states["powerFailure"],
                    rows: [{
                        value: 1,
                        title: _("yes"),
                        selected: this.event.value === 1
                    }, {
                        value: 0,
                        title: _("no"),
                        selected: this.event.value === 0
                    }]
                },
                supplyTimeRemaining = {
                    title: _("controls.power.autopilot.remaining-supply-time-in-minutes"),
                    type: AutopilotEnums.EventDetailTypes.Number,
                    state: control.states["supplyTimeRemaining"],
                    rows: [{
                        value: this.event.value || ""
                    }]
                },
                outputPower = {
                    title: _("controls.power.autopilot.usage-in-w"),
                    type: AutopilotEnums.EventDetailTypes.Number,
                    state: control.states["outputPower"],
                    rows: [{
                        value: this.event.value || ""
                    }]
                },
                batteryStateOfCharge = {
                    title: _("controls.power.autopilot.battery-state-of-charge-in-percent"),
                    type: AutopilotEnums.EventDetailTypes.Number,
                    state: control.states["batteryStateOfCharge"],
                    rows: [{
                        value: this.event.value || ""
                    }]
                },
                batteryState = {
                    title: _("controls.power.autopilot.battery-state.title"),
                    type: AutopilotEnums.EventDetailTypes.Radio,
                    state: control.states["batteryState"],
                    rows: [{
                        value: 1,
                        title: _("controls.power.operation-mode.battery.load"),
                        selected: this.event.value === 1
                    }, {
                        value: 0,
                        title: _("controls.power.autopilot.no-charging"),
                        selected: this.event.value === 0
                    }, {
                        value: -1,
                        title: _("controls.power.operation-mode.battery.unload"),
                        selected: this.event.value === -1
                    }]
                };

            if (stateUUID) {
                if (stateUUID === control.states.outputPower) {
                    details.sections.push(outputPower);
                    this.editSection = outputPower;
                } else if (stateUUID === control.states.powerFailure) {
                    details.sections.push(powerFailure);
                    this.editSection = powerFailure;
                } else if (stateUUID === control.states.batteryState) {
                    details.sections.push(batteryState);
                    this.editSection = batteryState;
                } else if (stateUUID === control.states.batteryStateOfCharge) {
                    details.sections.push(batteryStateOfCharge);
                    this.editSection = batteryStateOfCharge;
                } else if (stateUUID === control.states.supplyTimeRemaining) {
                    details.sections.push(supplyTimeRemaining);
                    this.editSection = supplyTimeRemaining;
                }
            } else {
                details.sections.push(powerFailure);
                details.sections.push(supplyTimeRemaining);
                details.sections.push(outputPower);
                details.sections.push(batteryStateOfCharge);
                details.sections.push(batteryState);
            }
        }

        _populateRoomControllerDetails(details, control, stateUUID) {
            var config = {
                title: _("controls.irc.temperature.current"),
                type: AutopilotEnums.EventDetailTypes.DecimalNumber,
                state: control.states["tempActual"],
                rows: [{
                    value: this.event.value || ""
                }]
            };
            var openWindow = {
                type: AutopilotEnums.EventDetailTypes.Radio,
                state: control.states["openWindow"],
                rows: [{
                    value: 1,
                    title: _("iroomcontroller.openwindow"),
                    selected: this.event.value === 1
                }]
            };

            if (stateUUID) {
                if (stateUUID === control.states["tempActual"]) {
                    details.sections.push(config);
                    this.editSection = config;
                } else if (stateUUID === control.states["openWindow"]) {
                    details.sections.push(openWindow);
                    this.editSection = openWindow;
                }
            } else {
                details.sections.push(config);
                details.sections.push(openWindow);
            }
        }

        _populateRoomV2ControllerDetails(details, control, stateUUID) {
            var tempActual = {
                    title: _("controls.irc.temperature.current"),
                    type: AutopilotEnums.EventDetailTypes.DecimalNumber,
                    state: control.states.tempActual,
                    rows: [{
                        value: this.event.value || ""
                    }]
                },
                reason = {
                    title: _("controls.ircv2.override.reason.title"),
                    type: AutopilotEnums.EventDetailTypes.Radio,
                    state: control.states.overrideReason,
                    rows: [{
                        value: iRoomV2ControlEnums.Reason.PRESENCE,
                        title: _("loxone-control.presence"),
                        selected: this.event.value === iRoomV2ControlEnums.Reason.PRESENCE
                    }, {
                        value: iRoomV2ControlEnums.Reason.WINDOW_OPEN,
                        title: _("controls.irc.exception.open-window"),
                        selected: this.event.value === iRoomV2ControlEnums.Reason.WINDOW_OPEN
                    }, {
                        value: iRoomV2ControlEnums.Reason.COMFORT_OVERRIDE,
                        title: getModeNameForId(iRoomV2ControlEnums.Mode.COMFORT),
                        selected: this.event.value === iRoomV2ControlEnums.Reason.COMFORT_OVERRIDE
                    }, {
                        value: iRoomV2ControlEnums.Reason.ECO_OVERRIDE,
                        title: getModeNameForId(iRoomV2ControlEnums.Mode.ECO),
                        selected: this.event.value === iRoomV2ControlEnums.Reason.ECO_OVERRIDE
                    }, {
                        value: iRoomV2ControlEnums.Reason.ECO_PLUS_OVERRIDE,
                        title: getModeNameForId(iRoomV2ControlEnums.Mode.ECO_PLUS),
                        selected: this.event.value === iRoomV2ControlEnums.Reason.ECO_PLUS_OVERRIDE
                    }, {
                        value: iRoomV2ControlEnums.Reason.PREPARE_STATE_HEAT_UP,
                        title: _("controls.ircv2.heat-up-phase"),
                        selected: this.event.value === iRoomV2ControlEnums.Reason.PREPARE_STATE_HEAT_UP
                    }, {
                        value: iRoomV2ControlEnums.Reason.PREPARE_STATE_COOL_DOWN,
                        title: _("controls.ircv2.cool-down-phase"),
                        selected: this.event.value === iRoomV2ControlEnums.Reason.PREPARE_STATE_COOL_DOWN
                    }, {
                        value: iRoomV2ControlEnums.Reason.NONE,
                        title: _("controls.ircv2.override.none"),
                        selected: this.event.value === iRoomV2ControlEnums.Reason.NONE
                    }]
                },
                activeMode = {
                    title: _("controls.ircv2.current-mode"),
                    type: AutopilotEnums.EventDetailTypes.Radio,
                    state: control.states.activeMode,
                    rows: [{
                        value: iRoomV2ControlEnums.Mode.COMFORT,
                        title: getModeNameForId(iRoomV2ControlEnums.Mode.COMFORT),
                        selected: this.event.value === iRoomV2ControlEnums.Mode.COMFORT
                    }, {
                        value: iRoomV2ControlEnums.Mode.ECO,
                        title: getModeNameForId(iRoomV2ControlEnums.Mode.ECO),
                        selected: this.event.value === iRoomV2ControlEnums.Mode.ECO
                    }, {
                        value: iRoomV2ControlEnums.Mode.ECO_PLUS,
                        title: getModeNameForId(iRoomV2ControlEnums.Mode.ECO_PLUS),
                        selected: this.event.value === iRoomV2ControlEnums.Mode.ECO_PLUS
                    }, {
                        value: iRoomV2ControlEnums.Mode.MANUAL,
                        title: _("controls.ircv2.op-mode.manual"),
                        selected: this.event.value === iRoomV2ControlEnums.Mode.MANUAL
                    }]
                };

            if (stateUUID) {
                if (stateUUID === control.states.tempActual) {
                    details.sections.push(tempActual);
                    this.editSection = tempActual;
                } else if (stateUUID === control.states.overrideReason) {
                    details.sections.push(reason);
                    this.editSection = reason;
                } else if (stateUUID === control.states.activeMode) {
                    details.sections.push(activeMode);
                    this.editSection = activeMode;
                }
            } else {
                details.sections.push(tempActual);
                details.sections.push(reason);
                details.sections.push(activeMode);
            }

            function getModeNameForId(id) {
                return control.getTimerModes().find(function (mode) {
                    return mode.id === id;
                }).name;
            }
        }

        /**
         * Popuplates details for a windowMonitor. If a stateUUID is provided, it'll only popuplate it with events
         * that affect that very detail.
         * IMPORTANT: if the stateUUID is set, the editSection attribute MUST BE set, otherwise it crashes when the
         * autopilot is being edited.
         * @param details       the details for the tableView.
         * @param control       what control it's for
         * @param [stateUUID]   if provided, only insert an event section that affects this stateUUID
         * @private
         */
        _populateWindowMonitorDetails(details, control, stateUUID) {
            var helperFn = function helperFn(stateId) {
                return {
                    title: _("windowmonitor." + stateId.toLowerCase()),
                    type: AutopilotEnums.EventDetailTypes.Number,
                    state: control.states[stateId],
                    rows: [{
                        value: this.event.value || ""
                    }]
                };
            }.bind(this);

            var offline = helperFn("numOffline");
            var open = helperFn("numOpen");
            var tilted = helperFn("numTilted");
            var closed = helperFn("numClosed");
            var unlocked = helperFn("numUnlocked");
            var locked = helperFn("numLocked");

            if (stateUUID) {
                // if a stateUUId is provided, an existing rule is being modified.
                switch (stateUUID) {
                    case control.states["numOffline"]:
                        details.sections.push(offline);
                        this.editSection = offline;
                        break;

                    case control.states["numOpen"]:
                        details.sections.push(open);
                        this.editSection = open;
                        break;

                    case control.states["numTilted"]:
                        details.sections.push(tilted);
                        this.editSection = tilted;
                        break;

                    case control.states["numClosed"]:
                        details.sections.push(closed);
                        this.editSection = closed;
                        break;

                    case control.states["numLocked"]:
                        // if the feature locked-windows is not present, these UUIDs will not be here.
                        details.sections.push(locked);
                        this.editSection = locked;
                        break;

                    case control.states["numUnlocked"]:
                        // if the feature locked-windows is not present, these UUIDs will not be here.
                        details.sections.push(unlocked);
                        this.editSection = unlocked;
                        break;

                    default:
                        break;
                }
            } else {
                // if no stateUUID is provided, add all sections. In this case a new event is being selected.
                details.sections.push(open);
                details.sections.push(tilted);
                details.sections.push(closed);
                details.sections.push(unlocked);
                details.sections.push(locked);
                details.sections.push(offline);
            }
        }

        /**
         * Populates details for a SolarPump. If a stateUUID is provided, it'll only populate it with events
         * that affect that very detail.
         * IMPORTANT: if the stateUUID is set, the editSection attribute MUST BE set, otherwise it crashes when the
         * autopilot is being edited.
         * @param details       the details for the tableView.
         * @param control       what control it's for
         * @param [stateUUID]   if provided, only insert an event section that affects this stateUUID
         * @private
         */
        _populateSolarPumpDetails(details, control, stateUUID) {
            // Gets the temperature section for a specific buffer at index
            var _getBufferTempSection = function _getBufferTempSection(buffer, idx, event) {
                    return {
                        title: _("solarpumpcontroller.automatic-designer.tempState", {
                            bufferName: buffer.name
                        }),
                        type: AutopilotEnums.EventDetailTypes.DecimalNumber,
                        state: control.states["bufferTemp" + idx],
                        rows: [{
                            value: event.value || ""
                        }]
                    };
                },
                // Gets the state section for a specific buffer at index
                _getBufferStateSection = function _getBufferStateSection(buffer, idx, event) {
                    return {
                        title: _("solarpumpcontroller.automatic-designer.bufferState", {
                            bufferName: buffer.name
                        }),
                        type: AutopilotEnums.EventDetailTypes.Radio,
                        state: control.states["bufferState" + idx],
                        rows: [{
                            value: SolarPumpControlEnums.BUFFER_STATE.WAITING,
                            title: _("solarpumpcontroller.bufferState.waiting"),
                            selected: event.value === SolarPumpControlEnums.BUFFER_STATE.WAITING
                        }, {
                            value: SolarPumpControlEnums.BUFFER_STATE.HEATING_UP,
                            title: _("solarpumpcontroller.bufferState.heatingUp"),
                            selected: event.value === SolarPumpControlEnums.BUFFER_STATE.HEATING_UP
                        }, {
                            value: SolarPumpControlEnums.BUFFER_STATE.OK_TEMP_REACHED,
                            title: _("solarpumpcontroller.bufferState.tempReached"),
                            selected: event.value === SolarPumpControlEnums.BUFFER_STATE.OK_TEMP_REACHED
                        }]
                    };
                },
                bufferTempN,
                bufferStateN,
                collectorTemp = {
                    title: _("solarpumpcontroller.collectorTemp"),
                    type: AutopilotEnums.EventDetailTypes.DecimalNumber,
                    state: control.states["collectorTemp"],
                    rows: [{
                        value: this.event.value
                    }]
                },
                heatOverload = {
                    type: AutopilotEnums.EventDetailTypes.Radio,
                    state: control.states["heatOverload"],
                    rows: [{
                        value: 0,
                        title: _('solarpumpcontroller.heatOverload.no'),
                        selected: this.event.value === AutopilotEnums.SolarStates.HeatOverloadInactive
                    }, {
                        value: 1,
                        title: _("solarpumpcontroller.heatOverload.yes"),
                        selected: this.event.value === AutopilotEnums.SolarStates.HeatOverloadActive
                    }]
                }; // Finally add the buffer independent states


            if (stateUUID) {
                var index = this.event.data.stateName,
                    bufferName,
                    currentBuffer;

                if (index.indexOf("bufferTemp") >= 0) {
                    index = index.replace("bufferTemp", "");
                    bufferName = this.event.data.control.details.buffers[index];
                } else if (index.indexOf("bufferState") >= 0) {
                    index = index.replace("bufferState", "");
                    bufferName = this.event.data.control.details.buffers[index];
                }

                if (stateUUID === control.states["bufferTemp" + index]) {
                    currentBuffer = _getBufferTempSection(bufferName, index, this.event);
                    details.sections.push(currentBuffer);
                    this.editSection = currentBuffer;
                } else if (stateUUID === control.states["bufferState" + index]) {
                    currentBuffer = _getBufferStateSection(bufferName, index, this.event);
                    details.sections.push(currentBuffer);
                    this.editSection = currentBuffer;
                } else if (stateUUID === control.states["collectorTemp"]) {
                    details.sections.push(collectorTemp);
                    this.editSection = collectorTemp;
                } else if (stateUUID === control.states["heatOverload"]) {
                    details.sections.push(heatOverload);
                    this.editSection = heatOverload;
                }
            } else {
                // Create and append all buffer sections (temp and state) to sections
                this.control.forEachBuffer(function (buffer, idx) {
                    bufferTempN = _getBufferTempSection(buffer, idx, this.event);
                    bufferStateN = _getBufferStateSection(buffer, idx, this.event);

                    if (control.states["bufferTemp" + idx]) {
                        details.sections.push(bufferTempN);
                    }

                    if (control.states["bufferState" + idx]) {
                        details.sections.push(bufferStateN);
                    }
                }.bind(this));
                details.sections.push(collectorTemp);
                details.sections.push(heatOverload);
            }
        }

        _populateSteakDetails(details, control, stateUUID) {
            var yellowAlarmActiveId = control.states["yellowAlarmActive"],
                greenAlarmActiveId = control.states["greenAlarmActive"],
                timerAlarmActiveId = control.states["timerAlarmActive"],
                temperatureYellowId = control.states["temperatureYellow"],
                temperatureGreenId = control.states["temperatureGreen"],
                targetYellowId = control.states["targetYellow"],
                targetGreenId = control.states["targetGreen"],
                isActiveId = control.states["isActive"],
                cells = [{
                    type: AutopilotEnums.EventDetailTypes.Radio,
                    state: yellowAlarmActiveId,
                    rows: [{
                        value: 1,
                        title: _('steak.sensor.color.yellow.active'),
                        selected: this.event.id === yellowAlarmActiveId && this.event.value === 1
                    }, {
                        value: 0,
                        title: _('steak.sensor.color.yellow.inactive'),
                        selected: this.event.id === yellowAlarmActiveId && this.event.value === 0
                    }]
                }, {
                    type: AutopilotEnums.EventDetailTypes.Radio,
                    state: greenAlarmActiveId,
                    rows: [{
                        value: 1,
                        title: _('steak.sensor.color.green.active'),
                        selected: this.event.id === greenAlarmActiveId && this.event.value === 1
                    }, {
                        value: 0,
                        title: _('steak.sensor.color.green.inactive'),
                        selected: this.event.id === greenAlarmActiveId && this.event.value === 0
                    }]
                }, {
                    type: AutopilotEnums.EventDetailTypes.Radio,
                    state: timerAlarmActiveId,
                    rows: [{
                        value: 1,
                        title: _("steak.timer.alarm.active"),
                        selected: this.event.id === timerAlarmActiveId && this.event.value === 1
                    }, {
                        value: 0,
                        title: _("steak.timer.alarm.inactive"),
                        selected: this.event.id === timerAlarmActiveId && this.event.value === 0
                    }]
                }, {
                    type: AutopilotEnums.EventDetailTypes.Number,
                    state: temperatureYellowId,
                    title: _('steak.sensor.temperature', {
                        color: _("steak.sensor.color.yellow")
                    }),
                    rows: [{
                        value: this.event.id === temperatureYellowId && this.event.value || "" ? this.event.value || "" : ""
                    }]
                }, {
                    type: AutopilotEnums.EventDetailTypes.Number,
                    state: temperatureGreenId,
                    title: _('steak.sensor.temperature', {
                        color: _("steak.sensor.color.green")
                    }),
                    rows: [{
                        value: this.event.id === temperatureGreenId && this.event.value || "" ? this.event.value || "" : ""
                    }]
                }, {
                    type: AutopilotEnums.EventDetailTypes.Number,
                    state: targetYellowId,
                    title: _('steak.sensor.target-temp', {
                        color: _("steak.sensor.color.yellow")
                    }),
                    rows: [{
                        value: this.event.id === targetYellowId && this.event.value || "" ? this.event.value || "" : ""
                    }]
                }, {
                    type: AutopilotEnums.EventDetailTypes.Number,
                    state: targetGreenId,
                    title: _('steak.sensor.target-temp', {
                        color: _("steak.sensor.color.green")
                    }),
                    rows: [{
                        value: this.event.id === targetGreenId && this.event.value || "" ? this.event.value || "" : ""
                    }]
                }, {
                    type: AutopilotEnums.EventDetailTypes.Radio,
                    state: isActiveId,
                    rows: [{
                        value: 1,
                        title: _('x.active', {
                            x: control.getName()
                        }),
                        selected: this.event.id === isActiveId && this.event.value === 1
                    }, {
                        value: 0,
                        title: _('x.inactive', {
                            x: control.getName()
                        }),
                        selected: this.event.id === isActiveId && this.event.value === 0
                    }]
                }];
            cells.forEach(function (cell) {
                if (stateUUID) {
                    if (stateUUID === cell.state) {
                        this.editSection = cell;
                        details.sections.push(cell);
                    }
                } else {
                    details.sections.push(cell);
                }
            }.bind(this));
        }

        _populateWindowDetails(details, control, stateUUID) {
            var positionId = control.states["position"],
                directionId = control.states["direction"],
                cells = [{
                    type: AutopilotEnums.EventDetailTypes.Number,
                    state: positionId,
                    title: _("position"),
                    rows: [{
                        value: this.event.id === positionId && this.event.value || "" ? this.event.value || "" : ""
                    }]
                }, {
                    type: AutopilotEnums.EventDetailTypes.Radio,
                    state: directionId,
                    rows: [{
                        value: WindowControlEnums.DIR.CLOSING,
                        title: _('close'),
                        selected: this.event.id === directionId && this.event.value === WindowControlEnums.DIR.CLOSING
                    }, {
                        value: WindowControlEnums.DIR.NOT_MOVING,
                        title: _('controls.ircv2.no-action-phase'),
                        selected: this.event.id === directionId && this.event.value === WindowControlEnums.DIR.NOT_MOVING
                    }, {
                        value: WindowControlEnums.DIR.OPENING,
                        title: _('open'),
                        selected: this.event.id === directionId && this.event.value === WindowControlEnums.DIR.NOT_MOVING
                    }]
                }];
            cells.forEach(function (cell) {
                if (stateUUID) {
                    if (stateUUID === cell.state) {
                        this.editSection = cell;
                        details.sections.push(cell);
                    }
                } else {
                    details.sections.push(cell);
                }
            }.bind(this));
        }

        _populateCarChargerDetails(details, control, stateUUID) {
            var connected = {
                type: AutopilotEnums.EventDetailTypes.Radio,
                state: control.states["connected"],
                rows: [{
                    value: 1,
                    title: _("controls.car-charger.car-connected"),
                    selected: this.event.value === 1
                }, {
                    value: 0,
                    title: _("controls.car-charger.car-disconnected"),
                    selected: this.event.value === 0
                }]
            };
            var charging = {
                type: AutopilotEnums.EventDetailTypes.Radio,
                state: control.states["charging"],
                rows: [{
                    value: 1,
                    title: _("controls.car-charger.loading"),
                    selected: this.event.value === 1
                }, {
                    value: 0,
                    title: _("controls.car-charger.loading-paused"),
                    selected: this.event.value === 0
                }]
            };
            var power = {
                title: _("controls.car-charger.current-power"),
                type: AutopilotEnums.EventDetailTypes.DecimalNumber,
                state: control.states["power"],
                description: _("autopilot.placeholder.value", {
                    format: "kW"
                }),
                rows: [{
                    value: this.event.value || "",
                    minValue: 0,
                    maxValue: 1000
                }]
            };
            var duration = {
                title: _("controls.car-charger.charging-time"),
                type: AutopilotEnums.EventDetailTypes.DecimalNumber,
                state: control.states["chargeDuration"],
                description: _("autopilot.placeholder.value", {
                    format: _("dateTime.second_plural")
                }),
                rows: [{
                    value: this.event.value || "",
                    minValue: 0,
                    maxValue: 9999999
                }]
            };

            if (stateUUID) {
                if (stateUUID === control.states["connected"]) {
                    details.sections.push(connected);
                    this.editSection = connected;
                } else if (stateUUID === control.states["power"]) {
                    details.sections.push(power);
                    this.editSection = power;
                } else if (stateUUID === control.states["charging"]) {
                    details.sections.push(charging);
                    this.editSection = charging;
                } else if (stateUUID === control.states["chargeDuration"]) {
                    details.sections.push(duration);
                    this.editSection = duration;
                }
            } else {
                details.sections.push(connected);
                details.sections.push(charging);
                details.sections.push(power);
                details.sections.push(duration);
            }
        }

        _populateDaytimerDetails(details, control, stateUUID) {
            var needsActivation = {
                type: AutopilotEnums.EventDetailTypes.Radio,
                state: control.states["needsActivation"],
                rows: [{
                    value: 1,
                    title: _("controls.daytimer.entry.activation-required"),
                    selected: this.event.value === 1
                }]
            };

            if (!control.details.analog) {
                var value = {
                    title: _("controls.daytimer.entry.value"),
                    type: AutopilotEnums.EventDetailTypes.Radio,
                    state: control.states["value"],
                    rows: [{
                        value: 1,
                        title: control.details.text.on,
                        selected: this.event.value === 1
                    }, {
                        value: 0,
                        title: control.details.text.off,
                        selected: this.event.value === 0
                    }]
                };
            }

            if (stateUUID) {
                if (stateUUID === control.states["needsActivation"]) {
                    details.sections.push(needsActivation);
                    this.editSection = needsActivation;
                } else if (stateUUID === control.states["value"]) {
                    details.sections.push(value);
                    this.editSection = value;
                }
            } else {
                details.sections.push(needsActivation);
                value && details.sections.push(value);
            }
        }

        _populateEnergyMonitorDetails(details, control, stateUUID) {
            var currProduction = {
                title: "Current Production",
                type: AutopilotEnums.EventDetailTypes.DecimalNumber,
                state: control.states["prodCurr"],
                description: _("autopilot.placeholder.value", {
                    format: "kW"
                }),
                rows: [{
                    value: this.event.value || "",
                    minValue: 0,
                    maxValue: 100
                }]
            };
            var consCurr = {
                title: "Current Consumption",
                type: AutopilotEnums.EventDetailTypes.DecimalNumber,
                state: control.states["consCurr"],
                description: _("autopilot.placeholder.value", {
                    format: "kW"
                }),
                rows: [{
                    value: this.event.value || "",
                    minValue: 0,
                    maxValue: 100
                }]
            };

            if (stateUUID) {
                if (stateUUID === control.states["prodCurr"]) {
                    details.sections.push(currProduction);
                    this.editSection = currProduction;
                } else if (stateUUID === control.states["consCurr"]) {
                    details.sections.push(consCurr);
                    this.editSection = consCurr;
                }
            } else {
                details.sections.push(currProduction);
                details.sections.push(consCurr);
            }
        }

        _populateGateDetails(details, control) {
            var config = {
                title: _("controls.gate.position"),
                type: AutopilotEnums.EventDetailTypes.Number,
                state: control.states["position"],
                description: _("autopilot.placeholder.gate.position"),
                valueType: AutopilotEnums.ValueTypes.Percent,
                rows: [{
                    value: this.event.value || "",
                    minValue: 0,
                    maxValue: 100
                }]
            };
            details.sections.push(config);
            this.editSection = config;
        }

        _populateJalousieDetails(details, control, stateUUID) {
            var automaticConfig = {
                title: _("autopilot.rule.name"),
                type: AutopilotEnums.EventDetailTypes.Radio,
                state: control.states["autoActive"],
                rows: [{
                    value: 1,
                    title: _("active"),
                    selected: this.event.value === 1
                }, {
                    value: 0,
                    title: _("inactive"),
                    selected: this.event.value === 0
                }]
            };
            var positionConfig = {
                title: _("controls.jalousie.position"),
                type: AutopilotEnums.EventDetailTypes.Number,
                state: control.states["position"],
                description: _("autopilot.placeholder.jalousie.position"),
                valueType: AutopilotEnums.ValueTypes.Percent,
                rows: [{
                    value: this.event.value || "",
                    minValue: 0,
                    maxValue: 100
                }]
            };

            if (stateUUID) {
                if (stateUUID === control.states["autoActive"]) {
                    details.sections.push(automaticConfig);
                    this.editSection = automaticConfig;
                } else if (stateUUID === control.states["position"]) {
                    details.sections.push(positionConfig);
                    this.editSection = positionConfig;
                }
            } else {
                // automatic mode supported?
                if (control.details && control.details.isAutomatic) {
                    details.sections.push(automaticConfig);
                }

                details.sections.push(positionConfig);
            }
        }

        _populateVirtualInputDetails(details, control) {
            details.forceHeader = false;
            var isDigitalText = control.type === ControlType.INFO_ONLY_DIGITAL && control.details.text,
                onText = isDigitalText ? control.details.text.on : _("active"),
                offText = isDigitalText ? control.details.text.off : _("inactive");
            var config = {
                type: AutopilotEnums.EventDetailTypes.Radio,
                state: control.states["active"],
                rows: [{
                    value: 1,
                    title: onText,
                    selected: this.event.value === 1
                }, {
                    value: 0,
                    title: offText,
                    selected: this.event.value === 0
                }]
            };
            details.sections.push(config);
            this.editSection = config;
        }

        _populateMeterDetails(details, control, stateUUID) {
            var actual = {
                title: _('meter.actual'),
                type: AutopilotEnums.EventDetailTypes.DecimalNumber,
                state: control.states["actual"],
                rows: [{
                    value: this.event.value || ""
                }]
            };
            var total = {
                title: _('meter.total'),
                type: AutopilotEnums.EventDetailTypes.DecimalNumber,
                state: control.states["total"],
                rows: [{
                    value: this.event.value || ""
                }]
            };

            if (stateUUID) {
                if (stateUUID === control.states["actual"]) {
                    this.editSection = actual;
                    details.sections.push(actual);
                } else if (stateUUID === control.states["total"]) {
                    details.sections.push(total);
                    this.editSection = total;
                }
            } else {
                details.sections.push(actual);
                details.sections.push(total);
            }
        }

        _populateAlarmClockDetails(details, control) {
            details.forceHeader = false;
            var config = {
                type: AutopilotEnums.EventDetailTypes.Radio,
                state: control.states["isAlarmActive"],
                rows: [{
                    value: 1,
                    title: _("controls.alarmClock.activeAlarm"),
                    selected: this.event.value === 1
                }, {
                    value: 0,
                    title: _("controls.alarmClock.inactiveAlarm"),
                    selected: this.event.value === 0
                }]
            };
            details.sections.push(config);
            this.editSection = config;
        }

    };
});
