'use strict';

define(['./presenceRoomSelectionScreen.jsx'], function (PresenceRoomSelectionScreen) {
    return class PresenceRoomSelection extends GUI.Screen {

        static ReactComp = PresenceRoomSelectionScreen.default;

        constructor(details) {
            super($('<div>'));
        }

    };
});
