'use strict';

define(["IRoomControllerV2ControlEnums"], function (IRoomControllerV2ControlEnums) {
    return class IRoomControllerV2ControlOverride extends Controls.ControlContent {
        constructor(details) {
            super(details);
            this.states = this.control.getStates();
            this.selectedModeId = this._predictMode(details.activeMode);
            this.timerModes = this.control.getTimerModes(); // Add the manual mode

            this.timerModes.push({
                name: _("controls.ircv2.op-mode.manual.extended-name"),
                id: IRoomControllerV2ControlEnums.Mode.MANUAL
            });
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.tableView = new GUI.TableViewV2(this, this);
            this.tableView.getElement().addClass("override__table-view");
            this.appendSubview(this.tableView);
            SandboxComponent.getMiniserverTimeInfo(this, function (utc) {
                this.currentTime = moment().utcOffset(utc);
                this.currentTime.seconds(0);

                if (this.currentTime.minute() === 0) {
                    this.currentTime.add(4 * 60, 'minutes');
                } else {
                    this.currentTime.add(5 * 60, 'minutes');
                    this.currentTime.minute(0);
                }
            }.bind(this), TimeValueFormat.MINISERVER_UTC_OFFSET);

            this._reload();
        }

        titleBarActionRight() {
            this._startOverride();
        }

        updateView(details) {
            super.updateView();
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        getURL() {
            return "Timer";
        }

        titleBarText() {
            return _("controls.daytimer.timer.start");
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.CLOSE,
                rightSide: TitleBarCfg.Button.TICK,
                style: 'transparent'
            };
        }

        // TableViewDataSource methods
        styleForTableView() {
            return TableViewStyle.GROUPED;
        }

        numberOfSections() {
            return this.tableContent.length;
        }

        numberOfRowsInSection(section) {
            return this.tableContent[section].rows.length;
        }

        cellTypeForCellAtIndex(section, row) {
            return this.tableContent[section].rows[row].type;
        }

        titleForHeaderInSection(section) {
            return this.tableContent[section].header;
        }

        contentForCell(cell, section, row) {
            return this.tableContent[section].rows[row].content;
        }

        // TableViewDelegate methods
        sliderDragged(cell, section, row, tableView, value) {
            this.manualTemp = value;
        }

        sliderClicked(cell, section, row, tableView, value) {
            this.manualTemp = value;
        }

        didCheckCell(cell, section, row, tableView, selected) {
            this.selectedModeId = this.timerModes[row].id;

            this._reload();
        }

        onPickerChanged(section, row, tableView, value) {
            this.currentTime = value;
        }

        // Private

        /**
         * Custom reload function to handle the tableContent
         * @private
         */
        _reload() {
            this.tableContent = this._createTableContent();
            this.tableView.reload();
        }

        /**
         * Creates the content for the TableView
         * @return {Array}
         * @private
         */
        _createTableContent() {
            var tableContent = [],
                currentMode = this._getCurrentSelectedMode(),
                section0,
                section1;

            section0 = {
                rows: []
            };
            this.timerModes.forEach(function (mode, idx) {
                section0.rows.push({
                    content: {
                        title: mode.name,
                        subtitle: this.control.getTempModeDesc(mode.id),
                        selected: mode.id === this.selectedModeId,
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED
                    },
                    type: GUI.TableViewV2.CellType.CHECKABLE
                });
            }.bind(this));
            section1 = {
                header: currentMode.name,
                rows: [{
                    content: {
                        iconSrc: Icon.IRCV2.TIMER,
                        title: _("controls.daytimer.entry.end"),
                        value: this.currentTime,
                        expanded: true
                    },
                    type: GUI.TableViewV2.CellType.DATE_TIME_PICKER
                }]
            };

            if (this.selectedModeId === IRoomControllerV2ControlEnums.Mode.MANUAL) {
                this.manualTemp = this.states.comfortTemperature;
                section1.rows.splice(0, 0, {
                    content: {
                        title: _('iroomcontroller.tempactual'),
                        minValue: this.states.frostProtectTemperature,
                        maxValue: this.states.heatProtectTemperature,
                        step: 0.5,
                        value: this.manualTemp,
                        format: this.control.details.format,
                        minIconSrc: Icon.MINUS,
                        maxIconSrc: Icon.PLUS
                    },
                    type: GUI.TableViewV2.CellType.SLIDER
                });
            }

            tableContent.push(section0);
            tableContent.push(section1);
            return tableContent;
        }

        /**
         * Sends the command to start the override timer and closes this view
         * @private
         */
        _startOverride() {
            var timeDifference = this.currentTime.getSecondsSince2009(),
                cmd;

            if (this.selectedModeId === IRoomControllerV2ControlEnums.Mode.MANUAL) {
                cmd = Commands.format(Commands.I_ROOM_V2_CONTROLLER.OVERRIDE.START_MANUAL_TEMP, this.selectedModeId, timeDifference, this.manualTemp);
            } else {
                cmd = Commands.format(Commands.I_ROOM_V2_CONTROLLER.OVERRIDE.START_UNTIL, this.selectedModeId, timeDifference);
            }

            this.sendCommand(cmd);
            this.ViewController.navigateBack();
        }

        _getCurrentSelectedMode() {
            return this.timerModes.find(function (mode) {
                return mode.id === this.selectedModeId;
            }.bind(this));
        }

        /**
         * Just ensures, that another mode will be returned as active
         * @param activeMode
         * @return {number}
         * @private
         */
        _predictMode(activeMode) {
            var predictedMode;

            switch (activeMode) {
                case IRoomControllerV2ControlEnums.Mode.COMFORT:
                    predictedMode = IRoomControllerV2ControlEnums.Mode.ECO;
                    break;

                case IRoomControllerV2ControlEnums.Mode.ECO:
                case IRoomControllerV2ControlEnums.Mode.ECO_PLUS:
                case IRoomControllerV2ControlEnums.Mode.MANUAL:
                default:
                    predictedMode = IRoomControllerV2ControlEnums.Mode.COMFORT;
                    break;
            }

            return predictedMode;
        }

    };
});
