'use strict';

define("AudioZoneNotReadyScreen", ["ControlActionCellsScreen"], function (ControlActionCellsScreen) {
    return class AudioZoneNotReadyScreen extends ControlActionCellsScreen {
        constructor(details) {
            super(...arguments);
            this.control = details.control;
        }

        closeAction() {
            this.ViewController.ViewController.dismiss();
        }

        shouldDisplayStateIcons() {
            var shouldShow = super.shouldDisplayStateIcons(...arguments);
            shouldShow &= this.control.getStates().universalIsLocked;
            return shouldShow;
        }

        handleModalBackgroundTapped() {
            return this.closeAction();
        }

        getAnimation() {
            return AnimationType.HD_MODAL_FULLSCREEN;
        }

        setSubtitle(subtitle) {
            if (this.control.getStates().universalIsLocked) {
                super.setSubtitle(...arguments);
            } else {
                // no subtitle needs to be set if not locked.
                super.setSubtitle(null);
            }
        }

        receivedStates(states) {
            super.receivedStates(...arguments);
            this.element.toggleClass("audio-zone-not-ready-screen--locked", states.universalIsLocked);
            this.bgView && this.toggleSubview(this.bgView, !states.universalIsLocked);

            if (states.universalIsLocked) {// nothing to be done.
            } else if (!this.currentServerState || this.currentServerState !== states.serverState) {
                this.currentServerState = states.serverState;
                this.setBackgroundView(states);
                this.ViewController.ViewController.setReady(this.currentServerState === MediaEnum.ClientState.ONLINE);
            }
        }

        setBackgroundView(states) {
            var icon = Icon.INDICATOR,
                title = states.texts.connectivityTextShort || "",
                message = states.texts.connectivityText || "",
                btnTxt,
                btnAction;

            if (this._shouldShowStartMusicServer(states)) {
                btnTxt = _("luring.start.now");
                btnAction = this._startMusicServer.bind(this);
            }

            if (this.bgView) {
                this.removeSubview(this.bgView);
            }

            this.bgView = new GUI.BackgroundView(icon, title, message, btnTxt, btnAction, true);
            this.insertSubviewBefore(this.bgView, this.actionTableView.getElement());
            this.bgView.getElement().addClass("action-view__background-view");
        }

        _startMusicServer() {
            this.control.sendCommand(Commands.AudioZone.SERVER.ON);
        }

        _shouldShowStartMusicServer(states) {
            var showStartBtn;

            if (MediaServerComp.Feature.V2_FIRMWARE) {
                // This check will always fail, as we don't have a sufficient way to determine the version without connecting to the MusicServer yet
                showStartBtn = states.serverState === MediaEnum.ClientState.OFFLINE;
            } else {
                showStartBtn = states.serverState === MediaEnum.ClientState.OFFLINE || states.serverState === MediaEnum.ClientState.NOT_REACHABLE;
            }

            return showStartBtn;
        }

    };
});
