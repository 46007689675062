import React, {
    useRef,
    useEffect,
    useContext,
    useMemo
} from "react";
import {
    View,
    TouchableOpacity,
    StyleSheet
} from "react-native";
import DraggableFlatList, {
    NestableScrollContainer,
    NestableDraggableFlatList,
    ScaleDecorator
} from "react-native-draggable-flatlist";
import PropTypes from "prop-types";
import LxReactFlexibleCell from "../LxReactFlexibleCell/LxReactFlexibleCell";
import globalStyles from "GlobalStyles";
import {
    LxReactSeparator,
    LxReactText,
    ReactWrapper,
    AmbientContext
} from "LxComponents";
import Icons from "IconLib";
import { Subject } from 'rxjs';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

function LxReactTableView(props) {
    const {isAmbientMode} = useContext(AmbientContext)
    const logTag = LxReactTableView.name;
    const channel = new Subject();
    const isDraggingRef = useRef(false);
    const hapticStartedRef = useRef(false);
    const legacyTableRef = useRef(null);
    const legacyTableViewRef = useRef({});

    const sanitizeLegacyTableContent = (tableContent) => {
        return tableContent.map(section => {
            return {
                ...section,
                rows: section.rows.map(row => {
                    if (!("content" in row)) {
                        row = {
                            content: row
                        };
                    }
                    return {
                        ...row,
                        content: {
                            ...row.content,
                            subtitle: row.content.subtitle || row.content.subTitle,
                            class: "base-cell--transparent",
                        },
                    }
                })
            }
        })
    }

    const reloadLegacyTableView = () => {
        if (legacyTableRef.current &&
            legacyTableViewRef.current.delegate &&
            legacyTableViewRef.current.dataSource &&
            legacyTableViewRef.current.tableView) {

            let sanitizedTableContent = sanitizeLegacyTableContent(props.tableContent);
            return Q.all([
                legacyTableViewRef.current.delegate.update(sanitizedTableContent),
                legacyTableViewRef.current.dataSource.update(sanitizedTableContent)
            ]).then(([delegateModified, dataSourceModified]) => {
                if (delegateModified || dataSourceModified) {
                    return legacyTableViewRef.current.tableView.reload().then(() => {
                        props.tableContent.forEach((section, idx) => {
                            let sectionMap = getEditMapOfSection(idx),
                                editable = sectionMap.removable || sectionMap.sortable;
                            legacyTableViewRef.current.tableView.setEditingModeForSection(
                                idx,
                                editable,
                                sectionMap.removable,
                                sectionMap.sortable,
                                editable ? sectionMap.startIdx : undefined,
                                editable ? Math.min((sectionMap.endIdx - sectionMap.startIdx) + 1, section.rows.length) : undefined
                            )
                        });
                    })
                }
            })
        }
        return Q.resolve();
    }

    useEffect(() => {
        reloadLegacyTableView();
    }, [props.tableContent]);

    useEffect(() => {
        if (legacyTableRef.current) {

            if (!legacyTableViewRef.current.delegate) {
                let altSrc = {
                    didRemoveCell: (section, row, tableView, cell) => {
                        props.onCellRemove(section, row);
                    },
                    didMoveCell: (section, row, tableView, cell, oldIdx, newIdx) => {
                        props.onCellMove(section, oldIdx, newIdx);
                    },
                    styleForTableView: () => {
                        return TableViewStyle.PLAIN;
                    }
                }

                let initialTableContent = sanitizeLegacyTableContent(props.tableContent);
                legacyTableViewRef.current.delegate = tableViewDelegate(initialTableContent, altSrc);
                legacyTableViewRef.current.dataSource = tableViewDataSource(initialTableContent, null, altSrc);

                legacyTableViewRef.current.tableView = new GUI.EditableTableView(
                    legacyTableViewRef.current.dataSource,
                    legacyTableViewRef.current.delegate
                );

                legacyTableViewRef.current.tableView.getElement().css("background-color", "transparent");
            }

            let legacyPlaceholder = $(legacyTableRef.current)
            if (!legacyTableViewRef.current.constructivePrms) {
                legacyTableViewRef.current.constructivePrms = Q.resolve();
            }
            if (!legacyTableViewRef.current.destructivePrms) {
                legacyTableViewRef.current.destructivePrms = Q.resolve();
            }

            legacyTableViewRef.current.constructivePrms = legacyTableViewRef.current.constructivePrms.then(() => {
                return legacyTableViewRef.current.destructivePrms.then(() => {
                    let prms;
                    if (legacyTableViewRef.current.tableView._viewDidLoadPassed) {
                        prms = Q.resolve();
                    } else {
                        prms = Q(legacyTableViewRef.current.tableView.viewDidLoad());
                    }
                    return prms.then(() => {
                        legacyPlaceholder.append(legacyTableViewRef.current.tableView.getElement());
                        return Q(legacyTableViewRef.current.tableView.viewWillAppear()).then(() => {
                            return Q(legacyTableViewRef.current.tableView.viewDidAppear()).then(() => {
                                return reloadLegacyTableView();
                            })
                        });
                    });
                });
            });

            return () => {
                legacyTableViewRef.current.destructivePrms = legacyTableViewRef.current.constructivePrms.then(() => {
                    return Q(legacyTableViewRef.current.tableView.viewWillDisappear()).then(() => {
                        return Q(legacyTableViewRef.current.tableView.viewDidDisappear()).then(() => {
                            legacyTableViewRef.current.tableView.getElement().remove();
                        });
                    });
                })
            }
        }
    }, [legacyTableRef.current, JSON.stringify(props.editMap)]);

    let insets = {
        top: "env(safe-area-inset-top)",
        left: "env(safe-area-inset-left)",
        right: "env(safe-area-inset-right)",
        bottom: "env(safe-area-inset-bottom)"
    };
    try {
        insets = useSafeAreaInsets();
    } catch (e) {
    }

    // region styles

    const styles = useMemo(() => {
        let tmpStyles = {
            Base: {
                SectionHeader: {
                    Base: {
                        flex: 1,
                        color: globalStyles.colors.text.secondary,
                        fontFamily: globalStyles.fontSettings.families.regular,
                        fontSize: globalStyles.fontSettings.sizes.small,
                        minHeight: "auto", // set explicitly via section props if required, otherwise multi-line texts are cut off, e.g. climate-control-settings
                        marginTop: 18,
                        //marginLeft: globalStyles.spacings.gaps.small
                    },
                    RightButton: {
                        paddingRight: globalStyles.spacings.gaps.small,
                        paddingBottom: globalStyles.spacings.gaps.small,
                        justifyContent: "center"
                    },
                    StrongTitle: {
                        marginTop: globalStyles.spacings.gaps.small,
                        marginBottom: 12,
                        ...globalStyles.textStyles.title3.bold,
                        color: globalStyles.colors.text.primary
                    },
                    BoldTitle: {
                        marginTop: globalStyles.spacings.gaps.small,
                        marginBottom: 12,
                        fontSize: globalStyles.fontSettings.sizes.medium,
                    }
                },
                SectionFooter: {
                    Base: {
                        color: globalStyles.colors.text.secondary,
                        fontFamily: globalStyles.fontSettings.families.regular,
                        fontSize: globalStyles.fontSettings.sizes.small,
                        marginTop: globalStyles.spacings.gaps.small,
                        marginBottom: globalStyles.spacings.gaps.verySmall,
                        //marginLeft: globalStyles.spacings.gaps.small,
                        marginRight: globalStyles.spacings.gaps.small
                    },
                    Content: {
                        Container: {
                            flexDirection: "row"
                        },
                        Icon: {
                            height: 24,
                            width: 24,
                            marginLeft: globalStyles.spacings.gaps.small,
                            marginRight: globalStyles.spacings.gaps.small
                        },
                        TextContainer: {
                            flex: 1
                        },
                        Title: {
                            fontSize: globalStyles.fontSettings.sizes.smallReg,
                            color: globalStyles.colors.text.secondary,
                            marginTop: 0,
                            marginLeft: 0,
                            marginRight: 0,
                            marginBottom: globalStyles.spacings.gaps.verySmall
                        },
                        Message: {
                            fontFamily: globalStyles.fontSettings.families.regular,
                            fontSize: globalStyles.fontSettings.sizes.smallReg,
                            color: globalStyles.colors.text.secondary,
                            marginTop: 0,
                            marginLeft: 0,
                            marginRight: 0,
                            marginBottom: globalStyles.spacings.gaps.verySmall
                        }
                    }
                },
                List: {
                    paddingBottom: globalStyles.spacings.gaps.small,
                    paddingHorizontal: globalStyles.spacings.contentHorizontal
                }
            },
            [TableViewStyle.GROUPED]: {
                List: {
                    padding: globalStyles.spacings.gaps.small
                }
            },
            [TableViewStyle.COMFORT_MODE_2020]: {
                SectionHeader: {
                    Base: {
                        color: globalStyles.colors.text.primary,
                        fontSize: globalStyles.fontSettings.sizes.smallReg
                    }
                }
            }
        }

        if (Array.isArray(props.insets) && props.insets.length) {
            tmpStyles.Base.List = tmpStyles.Base.List || {};
            props.insets.forEach(inset => {
                if (insets[inset]) { // if inset specifies 0 offset (e.g. Browser), don't override existing paddings.
                    tmpStyles.Base.List[`padding${inset.capitalize()}`] = insets[inset];
                }
            });
        }

        return tmpStyles;
    }, [props.insets])


    /**
     * Gets the right style matching the tableViewStyle
     * @returns Object
     */
    const getStyles = () => {
        let otherStyles = (props.tableViewStyle || "").split(" ").map(style => {
            return styles[style] || {};
        });
        if (props.styles) {
            otherStyles.push(props.styles);
        }

        // object.assign would simply overwrite e.g. the "List" property provided from the baseStyle with another one.
        let result = {};
        [styles.Base, ...otherStyles].forEach((style) => {
            Object.keys(style).forEach((styleKey) => {
                result[styleKey] = Object.assign(result[styleKey] || {}, style[styleKey]);
            })
        })
        return result;
    }

    const tableViewContainerStyle = useMemo(() => {
        return  {
            flex: 1,
            ...getStyles().List,
            overflow: "auto"
        }
    }, [props.tableViewStyle, styles])
    // endregion

    /**
     * Responsible for rendering a separator between two items.
     * NOT responsible for rendering separators between sections!
     * NOT responsible for rendering a separator after the last or before the first item of a section
     * @param sepProps  the properties for the separator, e.g. leading/trailing item/section.
     * @returns {JSX.Element|null}
     */
    const renderItemSeparator = (sepProps) => {
        if (shouldRenderItemSeparatorInSection(sepProps.leadingItem ? sepProps.leadingItem.sectionIdx : -1)) {
            return getItemSeparator();
        } else {
            return null;
        }
    }

    const getItemSeparator = () => {
        return (<LxReactSeparator style={separatorStyle}/>);
    }

    const shouldRenderItemSeparatorInSection = (sectionIdx) => {
        var shouldRender = !props.hideItemSeparator;
        if (shouldRender && props.tableContent[sectionIdx]) {
            shouldRender = !props.tableContent[sectionIdx].hideItemSeparator;
        }
        return shouldRender;
    }

    const separatorStyle = useMemo(() => {
        let style = {
            ...globalStyles.customStyles.separator,
            ...props.itemSeparatorStyle,
        }

        if (isAmbientMode) {
            style.backgroundColor = globalStyles.colors.borderColor.ambient;
        }

        return style;
    }, [props.itemSeparatorStyle, isAmbientMode])

    // region private methods
    const getEditMapOfSection = (section) => {
        let wantedEditMap;

        if (Array.isArray(props.editMap) && props.editMap[section]) {
            wantedEditMap = cloneObject(props.editMap[section]);
        } else if (typeof props.editMap === "object") {
            wantedEditMap = cloneObject(props.editMap);
        } else {
            wantedEditMap = {
                sortable: false,
                removable: false
            };
        }

        if (wantedEditMap.sortable) {
            wantedEditMap.startIdx = wantedEditMap.startIdx || 0;
            wantedEditMap.endIdx = wantedEditMap.endIdx || props.tableContent[section].rows.length - 1; // length - 1 === last index
        }

        return wantedEditMap;
    }

    const isRowInSectionEditable = (rowIdx, sectionIdx) => {
        let editMap = getEditMapOfSection(sectionIdx);
        return editMap.sortable &&
            rowIdx.isBetweenOrEqual(
                editMap.startIdx,
                editMap.endIdx
            );
    }

    const isRowInSectionRemovable = (rowIdx, sectionIdx) => {
        let editMap = getEditMapOfSection(sectionIdx);
        return editMap.removable &&
            rowIdx.isBetweenOrEqual(
                editMap.startIdx,
                editMap.endIdx
            );
    }

    const extractKeysFromItem = (item, depth = 0) => {
        let extracted = [];
        item && Object.keys(item).forEach((key) => {
            let isKeyOption = false;
            switch (key) {
                case "key":
                case "title":
                case "subtitle":
                case "subTitle":
                case "controlUuid":
                case "uuid":
                case "placeholder":
                case "cellType":
                    isKeyOption = true;
                    break;
            }
            if (isKeyOption && typeof item[key] === "string") {
                extracted.push(item[key]);
            } else if (typeof item[key] === "object" && depth < 5) {
                extracted.splice(0,0, ...extractKeysFromItem(item[key], depth + 1));
            }
        })
        return extracted;
    }

    const keyExtractor = (item, index) => {
        // Keep it simple, if this is too long it will render the whole tableView everytime something changes!
        var keyBase = "";
        if (item.cell && item.cell.content) {
            if (item.cell.content) {
                keyBase += (item.cell.content.title || "");
                keyBase += (item.cell.content.subtitle || item.cell.subTitle || "");
                keyBase += (item.cell.content.placeholder || "");
                keyBase += (item.cell.content.controlUuid || "");
            }
            keyBase += index.toString();
        } else if (item.cell && (item.cell.title || item.cell.cellType)) {
            keyBase += (item.cell.cellType || "");
            keyBase += (item.cell.title || "");
            keyBase += (item.cell.subtitle || item.cell.subTitle || "");
            keyBase += (item.cell.controlUuid || "");
            keyBase += (item.cell.uniqueId || "");
        } else {
            let keyBases = extractKeysFromItem(item);
            keyBase += keyBases.join("-");
        }


        if (keyBase.length === 0) {
            console.warn(LxReactTableView.name, "Failed to extract a key for item at index " + index + " - using index!", item);
            return index.toString();
        }

        return keyBase.hashCode().toString();
    }

    const onDragBegin = (...params) => {
        isDraggingRef.current = true;
    }

    const onDragMove = (...params) => {
        if (!hapticStartedRef.current && isDraggingRef.current) {
            HapticFeedback(HapticFeedback.STYLE.SELECTION_START);
            hapticStartedRef.current = true;
        } else if (isDraggingRef.current) {
            HapticFeedback(HapticFeedback.STYLE.SELECTION_CHANGE);
        }
    }

    const onDragEnd = ({from, to, data}) => {
        isDraggingRef.current = false;
        hapticStartedRef.current = false;
        HapticFeedback(HapticFeedback.STYLE.SELECTION_END);
        props.onCellMove && props.onCellMove(data[to].sectionIdx, from, to);
    }

    const onCellRemove= (sectionIdx, rowIdx) => {
        if (props.onCellRemove) {
            props.onCellRemove(sectionIdx, rowIdx);
        } else {
            console.warn(LxReactTableView.name, '"onCellRemove" not implemented!');
        }
    }

    /**
     * Renders the placeholder under a sorting cell (when actively sorting)
     * @param item
     * @param index
     * @returns {JSX.Element}
     */
    const renderSortingPlaceholder = ({item, index}) => {
        return <View/> // Just an empty view to have the change haptics
    }


    const renderEditableCrossSectionalList = () => {
        return (
            <DraggableFlatList
                data={getData()}
                containerStyle={
                    {
                        ...getStyles().List,
                        flex: 1
                    }
                }
                renderItem={renderEditableCrossSectionalListCell}
                keyExtractor={keyExtractor}
                maxToRenderPerBatch={30}
                disableVirtualization={true}
                removeClippedSubviews={true}
                ItemSeparatorComponent={renderItemSeparator}
                ListEmptyComponent={props.emptyComp}
                debug={Debug.GUI.TableView}
            />
        )
    }

    const getData = () => {
        let clonedContent = cloneObjectDeep(props.tableContent);
        switch (props.performanceStyle) {
            case LxReactTableView.PerformanceStyle.EditableCrossSectional:
                let data = [];
                clonedContent.forEach((section, idx) => {
                    let sectionKeys = Object.keys(section),
                        hasHeader = sectionKeys.filter((key) => {
                            return /^header/.test(key);
                        }).length > 0,
                        hasFooter = sectionKeys.filter((key) => {
                            return /^footer/.test(key);
                        }).length > 0;
                    section.idx = idx;
                    section.isLast = idx === (clonedContent.length - 1);
                    if (hasHeader) {
                        data.push({
                            isSectionHeader: true,
                            section: section
                        });
                    }
                    data.push(...section.rows.map((cell, rIdx) => {
                        return {
                            isRow: true,
                            cell,
                            sectionIdx: section.idx,
                            rowIdx: rIdx
                        }
                    }));
                    if (hasFooter) {
                        data.push({
                            isSectionFooter: true,
                            section: section
                        });
                    }
                });
                return data;
            case LxReactTableView.PerformanceStyle.Editable:
            default:
                return clonedContent.map((section, sectionIdx) => {
                    // We need to know the section index somehow
                    section.idx = sectionIdx;
                    section.isLast = sectionIdx === (clonedContent.length - 1);
                    section.editMap = getEditMapOfSection(sectionIdx);
                    // The React's list implementation requires the "data" attribute
                    if (section.rows) {
                        section.data = section.rows.map((row, rowIdx) => {
                            return {
                                cell: row,
                                sectionIdx: sectionIdx,
                                rowIdx: rowIdx,
                                sortable: isRowInSectionEditable(rowIdx, sectionIdx),
                                removable: isRowInSectionRemovable(rowIdx, sectionIdx)
                            }
                        });
                    } else {
                        section.data = [];
                    }

                    delete section.rows;
                    return section;
                }).filter(section => {

                    return section.data.length ||
                        Object.keys(section).find((key) => {
                            return /^footer|^header/.test(key);
                        })
                })
        }
    }

    const renderSectionHeader = ({section}) => {
        let headerElement, titleElement;
        if (section.headerContent) {
            headerElement = renderHeaderContent(section.headerContent);
        } else if (section.headerElement) {
            headerElement = renderHeaderElement(section.headerElement);
        } else if (section.headerStrongTitle) {
            titleElement = renderHeaderTitle({
                title: section.headerStrongTitle,
                style: StyleSheet.flatten([getStyles().SectionHeader.StrongTitle || {}, section.headerTitleStyle])
            });
        } else if (section.headerBoldTitle) {
            titleElement = renderHeaderTitle({
                title: section.headerBoldTitle,
                style: getStyles().SectionHeader.BoldTitle || {}
            });
        } else if (section.headerTitle || section.headerImage) {
            titleElement = renderHeaderTitle({
                title: section.headerTitle,
                iconSrc: section.headerImage,
                style: section.headerTitleStyle
            });
        }

        if (!headerElement && section.headerDescription && section.headerDescription !== "") {
            headerElement = (
                <View style={getStyles().SectionHeader.Base}>
                    {titleElement}
                    {renderHeaderTitle({
                        title: section.headerDescription,
                        style: {
                            marginTop: 4,
                            marginBottom: globalStyles.spacings.gaps.small,
                            marginLeft: 0,
                            marginRight: 0
                        }
                    })}
                </View>
            )
        }

        if (!headerElement && titleElement) {
            headerElement = titleElement;
        }

        if (section.sectionRightButtonTitle) {
            headerElement = (
                <View
                    key={`sectionHeaderWithButton.${section.idx}`}
                    style={[{flexDirection: "row"}, section.headerStyle]}>
                    {headerElement}
                    <TouchableOpacity
                        onPress={() => {
                            if (section.rightSectionButtonTapped) {
                                section.rightSectionButtonTapped(section.idx, this);
                            }
                        }}
                        style={getStyles().SectionHeader.RightButton}
                    >
                        <LxReactText style={{
                            color: globalStyles.colors.brand
                        }}>{section.sectionRightButtonTitle}</LxReactText>
                    </TouchableOpacity>
                </View>
            )
        }

        if (section.didSelectHeader) {
            return (
                <TouchableOpacity
                    key={`clickableSectionHeader.${section.idx}`}
                    onPress={() => {
                        section.didSelectHeader(section.idx, this);
                    }}>
                    {headerElement}
                </TouchableOpacity>
            );
        } else {
            return headerElement;
        }
    }

    const renderSectionListCell = ({item, section, getIndex}) => {
        return renderCell({
            cell: item.cell,
            section: section.idx,
            row: getIndex()
        })
    }

    const renderEditableListCell = ({isActive, item, getIndex, drag}) => {
        return renderCell({
            cell: item.cell,
            drag: drag,
            section: item.sectionIdx,
            row: getIndex(),
            sortable: item.sortable,
            onRemove: item.removable ? onCellRemove : null
        });
    }

    const renderEditableCrossSectionalListCell = ({isActive, item, getIndex, drag}) => {
        if (item.isSectionHeader) {
            return renderSectionHeader(item);
        } else if (item.isRow) {
            return renderCell({
                cell: item.cell,
                drag: drag,
                section: item.sectionIdx,
                row: getIndex()
            });
        } else if (item.isSectionFooter) {
            return renderSectionFooter(item);
        }
    }

    /**
     * Renders a cell
     * @param cell
     * @param [drag] Function to start dragging
     * @param itemId The unique ID of the cell
     * @returns {React.ReactElement<*&{drag}>}
     */
    const renderCell = ({cell, drag, section, row, sortable, onRemove}) => {
        cell.type = cell.type || window.GUI.TableViewV2.CellType.GENERAL;
        /*if (!(cell.type in window.LxCellReactMap)) {
            cell.content.title = (cell.content.title || "").debugify();
            cell.content.subtitle = `${cell.type} not yet implemented`;
            cell.content.subtitleColor = globalStyles.colors.orange;
            cell.type = window.GUI.TableViewV2.CellType.GENERAL;
        }*/
        if (typeof window.LxCellReactMap[cell.type] !== "function") {
            debugger;
        }
        let onDrag,
            cellElem = {},
            cellMap = window.LxCellReactMap[cell.type]({
                cellObj: cell,
                sectionIdx: section,
                rowIdx: row,
                get cell() {
                    return {
                        ...cellElem
                    };
                },
                table: {
                    ...this
                },
                get tableContent () {
                    return {
                        ...props.tableContent
                    }
                },
                reloadFn: (section, row, cellContent) => {
                    if (props.legacyCellMapperReloadFn) {
                        props.legacyCellMapperReloadFn(section, row, cellContent);
                    } else {
                        console.log(logTag, '"legacyCellMapperReloadFn" is not implemented in the Table implementation, is it still necessary?')
                    }
                }
            });

        if (sortable) {
            onDrag = drag
        }
        cellElem = React.createElement(cellMap.comp || LxReactFlexibleCell, {
            section,
            row,
            onDrag,
            onRemove,
            channel,
            ...cellMap.props,
            key: "item-" + section + ":"+ row
        });
        return <ScaleDecorator activeScale={1.01}>
            {cellElem}
        </ScaleDecorator>;
    }

    const renderSectionFooter = ({section}) => {
        let footerElement;
        if (section.footerElement) {
            footerElement = renderFooterElement(section);
        } else if (section.footer) {
            footerElement = renderFooterContent(section);
        }
        if (section.footerTitle) {
            footerElement = renderFooterTitle(section);
        }

        if (footerElement && section.didSelectFooter) {
            footerElement = (
                <TouchableOpacity
                    key={`clickableSectionFooter.${section.idx}`}
                    onPress={() => {
                        section.didSelectFooter(section.idx, this);
                    }}>
                    {footerElement}
                </TouchableOpacity>
            );
        }

        if (section.isLast && !props.showSectionSeparator) {
            return footerElement;
        } else {
            // draw separator below section
            return (<View style={{flexDirection: "column"}}>
                {footerElement}
                {props.hideItemSeparator || section.hideItemSeparator ? null : getItemSeparator()}
            </View>);
        }
    }

    const isDomElem = (el) => {
        return !!(el instanceof HTMLElement ||
            el[0] instanceof HTMLElement)
    }

    // region SectionHeader Renderer
    const renderHeaderTitle = ({
        title,
        style = getStyles().SectionHeader.Title || {},
        headerImage
    }) => {
        if (headerImage) {
            console.warn(logTag, '"headerImage" has been deprecated and won\'t be rendered');
        }
        if (nullEmptyString(title)) {
            return <LxReactText style={
                {
                    ...getStyles().SectionHeader.Base,
                    ...style
                }
            }>{title}</LxReactText>
        } else {
            return <View style={
                {
                    flex: getStyles().SectionHeader.Base.flex,
                    minHeight: getStyles().SectionHeader.Base.minHeight
                }
            }/>
        }
    }

    const renderHeaderContent = ({ message, align, color }) => {
        return <LxReactText style={
            {
                ...getStyles().SectionHeader.Base,
                ...getStyles().SectionHeader.headerContent || {},
                textAlign: align,
                color: color
            }
        }>{message}</LxReactText>
    }

    const renderHeaderElement = (elem) => {
        if (isDomElem(elem)) {
            console.warn(logTag, '"headerElement" as an HTML or jQuery element has been deprecated, use a React element instead!');
            return renderHeaderContent({
                message: "HTML or jQuery elements are deprecated",
                align: "center",
                color: globalStyles.colors.red
            });
        } else if (ReactWrapper.React.isValidElement(elem)) {
            return elem;
        } else {
            console.warn(logTag, `"headerElement" is of unknown type "${typeof elem}"`);
        }
    }
    // endregion

    //region SectionFooter Renderer
    const renderFooterTitle = ({
        footerTitle,
        footerColor = getStyles().SectionHeader.Base.color,
        idx
    }) => {
        return <LxReactText style={
            {
                ...getStyles().SectionFooter.Base,
                color: footerColor
            }
        }
        key={`sectionFooter.${idx}`}
        >{footerTitle}</LxReactText>
    }

    const renderFooterElement = ({footerElement, idx}) => {
        if (isDomElem(footerElement)) {
            console.warn(logTag, '"footerElement" as an HTML or jQuery element has been deprecated, use a React element instead!');
            return renderFooterContent({
                footer: {
                    title: "HTML or jQuery elements are deprecated",
                    message: "Please use a React element instead",
                    iconSrc: Icons.Error
                },
                idx: idx
            });
        } else if (ReactWrapper.React.isValidElement(footerElement)) {
            return footerElement;
        } else {
            console.warn(logTag, `"footerElement" is of unknown type "${typeof footerElement}"`);
        }
    }

    const renderFooterContent = ({footer, idx}) => {
        let {
            title,
            message,
            iconSrc
        } = footer;
        let renderTitle = () => {
                if (title) {
                    return renderHeaderTitle({
                        title: title,
                        style: getStyles().SectionFooter.Content.Title
                    })
                }
            },
            renderMessage = () => {
                if (message) {
                    return renderHeaderTitle({
                        title: message,
                        style: getStyles().SectionFooter.Content.Message
                    })
                }
            },
            footerComp = (
                <View
                    key={`sectionFooter.${idx}`}
                    style={getStyles().SectionFooter.Content.TextContainer}>
                    {renderTitle()}
                    {renderMessage()}
                </View>
            );
        if (iconSrc) {
            let iconComp,
                iconColor = globalStyles.colors.text.secondary;
            if (typeof iconSrc === "function" && iconSrc.isReactIcon) {
                iconComp = iconSrc;
            } else {
                iconComp = Icons.Default;
                iconColor = globalStyles.colors.red
            }
            return (
                <View
                    key={`sectionFooterWithIcon.${idx}`}
                    style={getStyles().SectionFooter.Content.Container}>
                    {React.createElement(iconComp, {
                        style: {
                            ...getStyles().SectionFooter.Content.Icon
                        },
                        fill: iconColor
                    })}
                    {footerComp}
                </View>
            )
        }
        return footerComp;
    }
    // endregion
    // endregion


    const depsArr = [
        isAmbientMode,
        props.tableContent,
        props.performanceStyle,
        props.hideItemSeparator,
        props.containerStyle,
        props.itemSeparatorStyle,
        props.useLegacyTableView,
        props.editMap,
        legacyTableRef.current,
        tableViewContainerStyle
    ]

    const editableList = useMemo(() => {
        let data = getData(),
            isEditable = data.reduce((previous, current, section) => {
                return previous || getEditMapOfSection(section).sortable
            }, false);
        if (isEditable && (props.useLegacyTableView || PlatformComponent.isAndroid() || Debug._DEV_FEATURE.SimulateAndroidTableView)) {
            return <View style={tableViewContainerStyle} ref={legacyTableRef}/>
        } else {
            return (
                <View style={tableViewContainerStyle}>
                    <NestableScrollContainer>
                        {
                            data.map((section, sectionIdx) => {
                                let elms = [],
                                    editMap = getEditMapOfSection(sectionIdx);
                                elms.pushObject(renderSectionHeader({
                                    section: section
                                }));
                                if (editMap.sortable) {
                                    // Wrapping the sortable and non-sortable regions in its own NestableDraggableFlatList
                                    let preSortRange = {
                                            start: 0,
                                            end: editMap.startIdx - 1,
                                            key: "pre-" + sectionIdx
                                        },
                                        sortableRange = {
                                            start: editMap.startIdx,
                                            end: editMap.endIdx,
                                            key: "sort-" + sectionIdx
                                        },
                                        postSortableRange = {
                                            start: editMap.endIdx + 1,
                                            end: section.data.length,
                                            key: "post-" + sectionIdx
                                        };

                                    // Now render all the regions
                                    [
                                        preSortRange,
                                        sortableRange,
                                        postSortableRange
                                    ].forEach(({start, end, style, key}, idx) => {
                                        if (end >= start) {
                                            elms.push(<NestableDraggableFlatList
                                                key={key}
                                                data={section.data.slice(start, end + 1)}
                                                renderItem={renderEditableListCell}
                                                keyExtractor={keyExtractor}
                                                onDragBegin={onDragBegin}
                                                scrollEnabled={true}
                                                onPlaceholderIndexChange={onDragMove}
                                                onDragEnd={onDragEnd}
                                                renderPlaceholder={renderSortingPlaceholder}
                                                ItemSeparatorComponent={renderItemSeparator}
                                                contentContainerStyle={props.containerStyle}
                                                debug={Debug.GUI.TableView}
                                            />)
                                        }
                                    });
                                } else {
                                    elms.push(<NestableDraggableFlatList
                                        key={`${sectionIdx}`}
                                        data={section.data}
                                        renderItem={renderEditableListCell}
                                        scrollEnabled={true}
                                        keyExtractor={keyExtractor}
                                        onDragBegin={onDragBegin}
                                        onPlaceholderIndexChange={onDragMove}
                                        onDragEnd={onDragEnd}
                                        ListHeaderComponent={
                                            () => {
                                                if (shouldRenderItemSeparatorInSection(sectionIdx)) {
                                                    return getItemSeparator();
                                                } else {
                                                    return null;
                                                }
                                            }
                                        }
                                        renderPlaceholder={renderSortingPlaceholder}
                                        ItemSeparatorComponent={renderItemSeparator}
                                        contentContainerStyle={props.containerStyle}
                                        debug={Debug.GUI.TableView}
                                    />);
                                }
                                elms.pushObject(renderSectionFooter({
                                    section: section
                                }));
                                return elms;
                            }).flat()
                        }
                    </NestableScrollContainer>
                </View>
            )
        }
    }, depsArr)


    switch (props.performanceStyle) {
        case LxReactTableView.PerformanceStyle.EditableCrossSectional:
            return renderEditableCrossSectionalList();
        default:
            return editableList;
    }
}

// region static variables
LxReactTableView.PerformanceStyle = {
    Normal: "normal",
    Editable: "editable",
    EditableCrossSectional: "editableCrossSectional"
}

LxReactTableView.propTypes = {
    useLegacyTableView: PropTypes.bool,
    tableContent: PropTypes.array.isRequired,
    onCellMove: PropTypes.func,
    onCellRemove: PropTypes.func,
    emptyComp: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.elementType
    ]),
    editMap: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.object),
        PropTypes.object
    ]),
    tableViewStyle: PropTypes.string,
    performanceStyle: PropTypes.oneOf(Object.values(LxReactTableView.PerformanceStyle)),
    legacyCellMapperReloadFn: PropTypes.func,
    itemSeparatorStyle: PropTypes.object,
    hideItemSeparator: PropTypes.bool,
    showSectionSeparator: PropTypes.bool,
    containerStyle: PropTypes.object,
    showItemSeparatorOnFirstItem: PropTypes.bool,
    insets: PropTypes.arrayOf(function(propValue, key, componentName, location, propFullName) {
        let possibleValues = ["top", "bottom", "left", "right"];
        if (!possibleValues.includes(propValue[key])) {
            return new Error(`Invalid prop '${propFullName}' supplied to '${componentName}'. Only [${possibleValues.join(", ")}] are possible!`);
        }
    })
}
// endregion

export default LxReactTableView;
