"use strict";

define(["PoolControlEnums"], function (PoolControlEnums) {
    return class ServiceModeStateView extends GUI.ControlStateView {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return $('<div class="state-view__service-mode"></div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(control) {
            super(control, $('<div />'));
            this.control = control;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.element = ServiceModeStateView.Template.getTemplate();
            this.tableView = new GUI.TableViewV2(this, this);
            this.tableView.getElement().addClass("service-mode__table-view");
            this.appendSubview(this.tableView);
        }

        receivedStates(states) {
            this.states = states;

            if (!this.tableViewContent) {
                this._prepareTableViewContent(states);

                this.tableView.reload();
            } else {
                var valvePositionName = this.control.getNameOfPoolValvePositions(states.valvePosition);
                this.tableView.cellForSectionAndRow(0, 0).setDisclosureText(valvePositionName);
                this.tableView.cellForSectionAndRow(1, 0).setActive(states.pump);
                this.tableView.cellForSectionAndRow(1, 1).setActive(states.drainValve); // temperature control

                var swimmingMachineSection = 2;

                if (this.control.hasTemperatureControl(this.control)) {
                    swimmingMachineSection++;
                    this.control.details.hasHeating && this.tableView.cellForSectionAndRow(2, 0).setActive(states.heatingApproved);
                    var coolingIndex = this.control.details.hasHeating ? 1 : 0;
                    this.control.details.hasCooling && this.tableView.cellForSectionAndRow(2, coolingIndex).setActive(states.coolingApproved);
                } // swimming machine


                var coverSection = swimmingMachineSection;

                if (this.control.details.swimmingMachineType === PoolControlEnums.SwimmingMachineType.DIGITAL) {
                    coverSection++;
                    this.tableView.cellForSectionAndRow(swimmingMachineSection, 0).setActive(states.swimmingMachine > 0);
                } else if (this.control.details.swimmingMachineType === PoolControlEnums.SwimmingMachineType.ANALOG) {
                    coverSection++;
                    this.tableView.cellForSectionAndRow(swimmingMachineSection, 0).setPosition(states.swimmingMachine * 100);
                } // cover


                if (this.control.details.hasCover) {
                    this.tableView.cellForSectionAndRow(coverSection, 0).setCoverProgressAndDirection(states.coverPosition, states.direction);
                }
            }
        }

        update(states) {// no longer active, the receivedStates takes over.
        }

        // TableViewDataSource Methods
        titleForHeaderInSection(section) {
            return this.tableViewContent[section].sectionTitle;
        }

        styleForTableView(tableView) {
            return TableViewStyle.GROUPED;
        }

        numberOfSections() {
            return this.tableViewContent.length;
        }

        numberOfRowsInSection(section) {
            return this.tableViewContent[section].cells.length;
        }

        cellTypeForCellAtIndex(section, row) {
            return this.tableViewContent[section].cells[row].cellType;
        }

        contentForCell(cell, section, row) {
            return this.tableViewContent[section].cells[row];
        }

        // TableViewDelegate methods
        didSelectCell(cell, section) {
            if (section === 0) {
                this.ViewController.showState(PoolControlEnums.ScreenState.VALVE_POSITION, null, {control: this.control});
            }
        }

        onSwitchChanged(value, section, row) {
            if (section === 1) {
                if (row === 0) {
                    this.sendCommand(Commands.format(Commands.POOL.PUMP, value));
                } else if (row === 1) {
                    this.sendCommand(Commands.format(Commands.POOL.DRAIN_VALVE, value));
                }
            } else if (section === 2 && this.control.hasTemperatureControl(this.control)) {
                if (row === 0) {
                    this.sendCommand(Commands.format(Commands.POOL.SET_HEATING_APPROVED, value));
                } else if (row === 1) {
                    this.sendCommand(Commands.format(Commands.POOL.SET_COOLING_APPROVED, value));
                }
            } else if (section === 2 && !this.control.hasTemperatureControl(this.control) || section == 3) {
                if (row === 0) {
                    this.sendCommand(Commands.format(Commands.POOL.SET_SWIMMING_MACHINE, value ? 1 : 0));
                }
            }
        }

        sliderDragged(cell, section, row, tableView, value) {
            this.sendCommand(Commands.format(Commands.POOL.SET_SWIMMING_MACHINE, value / 100), Commands.Type.OVERRIDE);
        }

        openCover() {
            this.sendCommand(Commands.POOL.COVER_OPEN);
        }

        closeCover() {
            this.sendCommand(Commands.POOL.COVER_CLOSE);
        }

        // Private stuff
        _prepareTableViewContent(states) {
            this.tableViewContent = [{
                sectionTitle: "",
                cells: [{
                    cellType: GUI.TableViewV2.CellType.GENERAL,
                    title: _("pool.control.valve-position"),
                    disclosureText: this.control.getNameOfPoolValvePositions(states.valvePosition),
                    disclosureIcon: true
                }]
            }, {
                sectionTitle: "",
                cells: [{
                    cellType: GUI.TableViewV2.CellType.SWITCH,
                    title: _("pool.control.pump"),
                    active: states.pump,
                    useAntiGhostTimer: true
                }, {
                    cellType: GUI.TableViewV2.CellType.SWITCH,
                    title: _("pool.control.drain-valve"),
                    active: states.drainValve,
                    onText: _("opened"),
                    offText: _("closed"),
                    useAntiGhostTimer: true
                }]
            }];
            var temperatureCells = [];

            if (this.control.details.hasHeating) {
                temperatureCells.push({
                    cellType: GUI.TableViewV2.CellType.SWITCH,
                    title: _("pool.control.heating"),
                    active: states.heatingApproved,
                    useAntiGhostTimer: true
                });
            }

            if (this.control.details.hasCooling) {
                temperatureCells.push({
                    cellType: GUI.TableViewV2.CellType.SWITCH,
                    title: _("pool.control.cooling"),
                    active: states.coolingApproved,
                    useAntiGhostTimer: true
                });
            }

            if (temperatureCells.length > 0) {
                this.tableViewContent.push({
                    sectionTitle: "",
                    cells: temperatureCells
                });
            }

            var swimmingModeCell = {
                title: _("pool.control.swimming-machine")
            };

            switch (this.control.details.swimmingMachineType) {
                case 0:
                    // no swimmingMachine we don't add a cell
                    break;

                case 1:
                    // digital swimmingMachine we add a Switch
                    swimmingModeCell.active = states.swimmingMachine;
                    swimmingModeCell.cellType = GUI.TableViewV2.CellType.SWITCH;
                    swimmingModeCell.useAntiGhostTimer = true;
                    this.tableViewContent.push({
                        sectionTitle: "",
                        cells: [swimmingModeCell]
                    });
                    break;

                case 2:
                    // analog swimingMachine we add a slider
                    swimmingModeCell.cellType = GUI.TableViewV2.CellType.SLIDER_SWITCH;
                    swimmingModeCell.value = states.swimmingMachine * 100;
                    swimmingModeCell.step = 1;
                    this.tableViewContent.push({
                        sectionTitle: "",
                        cells: [swimmingModeCell]
                    });
                    break;
            }

            if (this.control.details.hasCover) {
                var coverCell = {
                    cellType: GUI.TableViewV2.CellType.Special.COVER,
                    title: _("pool.control.cover"),
                    progress: states.coverPosition,
                    direction: states.direction
                };
                coverCell.hasCoverPosition = this.control.details.hasCoverPosition;
                this.tableViewContent.push({
                    sectionTitle: "",
                    cells: [coverCell]
                });
            }
        }

    };
});
