import { useMemo } from "react";

const availableModes = [
    StatisticV2Mode.EveryChangeMin,
    StatisticV2Mode.EveryChange,
    StatisticV2Mode.IntervalMin5,
    StatisticV2Mode.IntervalMin10,
    StatisticV2Mode.IntervalMin30,
    StatisticV2Mode.IntervalMin60,
    StatisticV2Mode.IntervalMin15
];

const availableModesMapping = {};

availableModesMapping[StatisticV2Mode.EveryChangeMin] = 1;
availableModesMapping[StatisticV2Mode.EveryChange] = 1;
availableModesMapping[StatisticV2Mode.IntervalMin5] = 5;
availableModesMapping[StatisticV2Mode.IntervalMin10] = 10;
availableModesMapping[StatisticV2Mode.IntervalMin30] = 30;
availableModesMapping[StatisticV2Mode.IntervalMin60] = 60;
availableModesMapping[StatisticV2Mode.IntervalMin15] = 15;


const useLowestMode = arrayOfStats => {
    return useMemo(() => {
        const justTheModes = arrayOfStats.map(s => (s || {}).mode).filter(mode => availableModes.indexOf(mode) > -1);
        const modesMappedToMinuteValues = justTheModes.map(mode => availableModesMapping[mode])
        let min = Number.MAX_SAFE_INTEGER;
        let minMode = null;
        modesMappedToMinuteValues.forEach((minuteValue, idx) => {
            if(minuteValue < min) {
                min = minuteValue;
                minMode = justTheModes[idx];
            }
        });

        if(availableModes.indexOf(minMode) === -1) {
            return StatisticV2Mode.EveryChangeMin;
        }
        return minMode;
    }, [arrayOfStats.map(s => (s || {}).mode).join(',')]);
}

export default useLowestMode;