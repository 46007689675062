'use strict';

define("ColorPickerControl", ["Control", "ColorPickerControlEnums"], function (Control, ColorPickerControlEnums) {
    return class ColorPickerControl extends Control {
        constructor() {
            super(...arguments);
        }

        specificCreateCmdText(cmdParts) {
            var text = null,
                cfg = {
                    name: this.name
                };

            switch (cmdParts[1]) {
                case "on":
                    text = _("cmdtext.colorpicker.on", cfg);
                    break;

                case "off":
                    text = _("cmdtext.colorpicker.off", cfg);
                    break;

                case "setfav":
                    text = _("cmdtext.colorpicker.setfav", cfg);
                    break;

                default:
                    text = _("cmdtext.colorpicker.color", cfg);
                    break;
            }

            return text;
        }

    };
});
