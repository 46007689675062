'use strict';

define([], function () {
    return {
        MAX_OUTPUTS: 7,
        OUTPUT_POWER_FORMAT: "%.2f kW",
        BATTERY_FORMAT: "%.0f%%",
        SupplyMode: {
            SUPPLY_ONLY: 1,
            SUPPLY_AND_BATTERY: 0
        },
        BatteryLevel: {
            LOW: 20 // if in backup mode and the level drops below 20%, a warning should be raised

        },
        DeviceInfoBit: {
            BACKUP_MODE_ACTIVE: 1,
            OVERCURRENT_DETECTED: 2,
            BATTERY_MISSING: 4,
            BATTERY_EMPTY: 8,
            // reserved for battery test
            DEVICE_OVERHEATING: 512
        }
    };
});
