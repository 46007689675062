import {
    LxReactSeparator,
    ControlContext,
    AmbientContext
} from "LxComponents";
import globalStyles from "GlobalStyles";
import { useContext } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import Icons from "IconLib"
import AutomaticScreen from "./AutomaticScreen";
import { useNavigation } from "@react-navigation/native";

export default function AutomaticSection() {
    const { states } = useContext(ControlContext)
    const activeText = states.active ? _("controls.spotpriceoptimizer.active") : null;
    const navigation = useNavigation();
    const { isAmbientMode } = useContext(AmbientContext)

    const showAutomaticScreen = () => {
        navigation.navigate(AutomaticScreen.name);
    }

    return (
        <View style={styles.automaticButtonContainer}>
            <LxReactSeparator />
            <TouchableOpacity onPress={showAutomaticScreen}>
                <View style={StyleSheet.compose(styles.automaticButton, isAmbientMode ? { backgroundColor: "transparent" } : {})}>
                    <Text style={styles.value}>{_("controls.spotpriceoptimizer.spot.automatic")}</Text>
                    <View style={styles.automaticButtonLeft}>
                        <Text style={styles.automaticButtonLabel}>{activeText}</Text>
                        <Icons.Chevron
                            fill={globalStyles.colors.text.secondary}
                            width={18}
                            height={18}
                        />
                    </View>
                </View>
            </TouchableOpacity>
            <LxReactSeparator />
        </View>
    );
}

const styles = StyleSheet.create({
    automaticButton: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        backgroundColor: globalStyles.colors.black,
        height: 56,
        alignItems: "center",
    },
    automaticButtonLeft: {
        flexDirection: "row",
        alignItems: "center",
    },
    automaticButtonContainer: {
        width: "100%",
        paddingTop: 39,
        paddingBottom: 16,
    },
    automaticButtonLabel: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.stateActive,
    },
    value: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.primary,
    },
})
