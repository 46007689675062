import globalStyles from "GlobalStyles";
import {getSubmenuCell} from "../../UtilityComp/utilities/helper";

export default class UniversalControlStatesSrc {
    static getStateSections(control, states) {
        let sections = [],
            stateRows = [],
            detailsRow = [],
            subCtrlRows = [],
            // Only show the states represented in the structure file, we don't need the getters of the stateContainer
            // E.g: stateText, stateIcon, stateColor, stateInfo, ...
            controlStatesKeys = Object.keys(states).filter((stateKey) => {
                return Object.keys(control.states).indexOf(stateKey) > -1;
            }); // Iterate over every structure defined state and display
        // Newly received states will be displayed green
        // Empty TextEvents will be replaced with "EMPTY STRING" and displayed in gray

        controlStatesKeys.forEach((stateKey) => {
            let isNewState = states.newStates.indexOf(stateKey) > -1,
                titleColor = isNewState ? globalStyles.colors.stateActive : null,
                stateValue = states[stateKey].value,
                isValueEvent = states[stateKey].isValueEvent,
                valueColor = null;

            if (stateValue === "") {
                stateValue = 'EMPTY STRING';
                valueColor = Color.STATE_INACTIVE_B;
            } else if (isNewState) {
                valueColor = globalStyles.colors.stateActive;
            }

            stateRows.push(this._getValueCell(stateKey, stateValue, valueColor, titleColor, isValueEvent));
        });

        if (stateRows.length > 0) {
            sections.push({
                headerTitle: "States",
                rows: stateRows
            });
        } // Display the controls states if available


        if (control.details) {
            let detailsKeys = Object.keys(control.details);
            detailsKeys.forEach((detailsKey) => {
                let detailsValue = control.details[detailsKey];

                if (typeof detailsValue === "object") {
                    detailsValue = JSON.stringify(detailsValue);
                }

                detailsRow.push(this._getValueCell(detailsKey, detailsValue));
            });

            if (detailsRow.length > 0) {
                sections.push({
                    headerTitle: "Details",
                    rows: detailsRow
                });
            }
        } // Display subControls if available, clicking them will result in navigation to the specific subControl


        if (control.subControls) {
            let subCtrlUuids = Object.keys(control.subControls);
            subCtrlUuids.forEach((ctrlUuid) => {
                let subControl = control.subControls[ctrlUuid];
                subCtrlRows.push(getSubmenuCell(subControl.name, this._openSubControl.bind(this, subControl)));
            });

            if (subCtrlRows.length > 0) {
                sections.push({
                    headerTitle: "SubControls",
                    rows: subCtrlRows
                });
            }
        }

        return sections;
    }

    static _getValueCell(name, value, valueColor, titleColor, isValueEvent) {
        // We need to use .toString() the value, if we pass 0 or false as the disclosureText nothing will show up
        try {
            if (value.toString) {
                value = value.toString();
            } else {
                value = JSON.stringify(value);
            }
        } catch (e) {
        } // undefined will stay undefined, we can't set this as the disclosureText, so change it to the string "undefined"


        if (value === undefined) {
            value = "undefined";
        } else if (value === null) {
            value = "null";
        }

        let cell = {
            content: {
                title: name,
                titleColor: titleColor,
                disclosureText: value,
                disclosureColor: valueColor,
                clickable: true,
                rightIconSrc: Icon.DISCLOSURE
            },
            action: this._openValueScreen.bind(this, name, value)
        };

        if (isValueEvent !== undefined && isValueEvent !== null) {
            cell.content.subtitle = isValueEvent ? "ValueEvent" : "TextEvent";
        }

        return cell;
    }

    static _openSubControl(subControl) {
        NavigationComp.showControlContent(subControl, null, AnimationType.PUSH_OVERLAP_LEFT);
    }

    static _openValueScreen(stateKey, stateValue) {
        NavigationComp.showState("UniversalControlValueScreen", {
            stateKey: stateKey,
            stateValue: stateValue
        });
    }
}