import PropTypes from "prop-types";
import {useState, useEffect} from "react";
import globalStyles from "GlobalStyles";
import Icons from "IconLib"
import {
    LxReactTableView,
    TopNavigator,
    LxReactPressable,
    navigatorConfig,
    useHD,
    LxReactText, 
    useBackNavigation
} from "LxComponents"


function CurrentUserScreen({navigation, route}) {
    let currentUser = ActiveMSComponent.getCurrentUser(),
        hasUserManagementPermissions = ActiveMSComponent.getRequiredUserManagementPermissionInfos().hasUserManagementPermissions;

    const [completeUser, setCompleteUser] = useState(currentUser);

    useBackNavigation(() => {
        navigation.goBack();
    }, [])

    useEffect(() => {
        navigation.setOptions({
            ...navigatorConfig({
                animationType: AnimationType.HD_MODAL_FULLSCREEN,
                title: _('user.settings')
            })
        });
    }, [])


    useEffect(() => {
        if (route.params.updateUser) {
            setCompleteUser(user => {
                let tmpUser = {...user}

                tmpUser[route.params.updateUser.key] = route.params.updateUser.value;

                return tmpUser;
            });
        } else if (route.params.needsReload) {
            setCompleteUser(route.params.user)
        }

    }, [route.params.updateUser])


    const _getContent = () => {
        let tableContent = [];

        tableContent.pushObject(_getUserRows());
        tableContent.pushObject(_getPasswordSection());
        tableContent.pushObject(_getVisuPasswordSection());
        tableContent.pushObject(_getMoreSettingsSection());

        return tableContent;
    }

    const _getUserRows = () => {
        let section = {
            headerTitle: _('adminIf.general.tab'),
            headerTitleStyle: globalStyles.customStyles.UserManagementHeaderTitle,
            rows: [{
                content: {
                    title: _('user'),
                    disclosureText: currentUser.name,
                    copyable: true
                }
            }]
        };

        if (currentUser.trustMember) {
            section.rows.push({
                content: {
                    title: _("controls.intercom.about.audio.host"),
                    disclosureText: "@" + currentUser.trustMember,
                    copyable: true
                }
            });
        }

        return section;
    }

    const _getPasswordSection = () => {
        let section = {
            headerTitle: _('password'),
            headerTitleStyle: globalStyles.customStyles.UserManagementHeaderTitle,
            rows: []
        }


        section.rows.push({
            content: {
                title: _("change-password"),
                titleColor: globalStyles.colors.green
            },
            action: () => {
                _openUserDetails();
            }
        })

        if (BiometricHelper.hasEnrolledBiometrics) {
            section.rows.push(_getBiometricAuthRow());
        }

        return section;
    }

    const _getVisuPasswordSection = () => {
        let section = {
            headerTitle: _('visu-password'),
            headerTitleStyle: globalStyles.customStyles.UserManagementHeaderTitle,
            rows: []
        }

        section.rows.push({
            content: {
                title: _("change-visu-password"),
                titleColor: globalStyles.colors.green
            },
            action: () => {
                _openUserDetails(true);
            }
        })

        if (BiometricHelper.hasEnrolledBiometrics) {
            section.rows.push(_getBiometricAuthRow(true));
        }

        return section;
    }

    const _getBiometricAuthRow = (isVisuPw) => {
        let secretType = isVisuPw ? BiometricHelper.SecretType.VisuPw : BiometricHelper.SecretType.UserPw; // The Biometric ID cell is optional and depends on if the device has enrolled biometrics (Face, Finger)
        return {
            type: GUI.TableViewV2.CellType.SWITCH,
            content: {
                title: _("use-x", {
                    x: BiometricHelper.getBiometricTypePhrase()
                }),
                subtitle: _("secured.password.biometric.description", {
                    biometricType: BiometricHelper.getBiometricTypePhrase(),
                    passwordType: isVisuPw ? _("visu-password") : _("password")
                }),
                active: PersistenceComponent.isBiometricSecretWithTypeEnabled(secretType),
                secretType: secretType
            },
            onSwitchChanged: value => {
                _onBiometricIdStateChange(value, secretType)
            }
        }
    }

    /**
     * Gets the more settings hint for the bottom of the screen that directly
     * links to the user in the usermanagemnet
     * @returns {{didSelectFooter: *, footerTitle: *}}
     * @private
     */
    const _getMoreSettingsSection = () => {
        if (hasUserManagementPermissions && Feature.USER_MANAGEMENT_REWORK) {
            return {
                footerTitle: (
                        <LxReactPressable
                            style={{
                                color: globalStyles.colors.brand,
                                marginTop: Styles.spacings.gaps.big,
                                fontSize: globalStyles.fontSettings.sizes.small
                            }}
                            onPress={_openUserDetailsScreen}
                        >
                            {_('current-user.more-settings-hint', {
                                userManagement: _('user.management'),
                            })}
                        </LxReactPressable>
                ),
            };
        }
    }

    const _onBiometricIdStateChange = (value, secretType) => {
        let biometricPhrase = BiometricHelper.getBiometricTypePhrase(); // Always delete the saved token, just to make sure...

        PersistenceComponent.setBiometricTokenForSecretType(null, secretType);

        if (value) {
            NavigationComp.showPopup({
                title: biometricPhrase,
                message: _("secured.password.biometric-dialog.activation-notice", {
                    biometricType: biometricPhrase,
                    passwordType: BiometricHelper.getPasswordTypeFromSecretType(secretType)
                }),
                buttonOk: _("okay"),
                icon: BiometricHelper.getBiometricGlyph(),
                color: globalStyles.colors.stateActive
            }); // We don't need any further onboarding...

            PersistenceComponent.setShowBiometricOnboardDialogForSecretType(true, secretType);
        }

        PersistenceComponent.setBiometricSecretWithSecretTypeEnabled(secretType, value); // Reset the visu password to ensure the user has to reenter the password again!

        if (secretType === BiometricHelper.SecretType.VisuPw) {
            SandboxComponent.resetVisuPassword();
        }
    }

    const _openUserDetailsScreen = () => {
        navigation.navigate(ScreenState.UserDetailsScreen, {
            isCurrentUser: true,
            user: completeUser,
        });
    }

    const _openUserDetails = (isVisuPw) => {
        let screenDetails = {
                title: isVisuPw ? _("change-visu-password") : _("change-password"),
                user: completeUser,
                type: isVisuPw ? "visuPw" : "userPw",
            },
            pwTypeString = isVisuPw ? _("visu-password") : _("password");

        if (SandboxComponent.checkPermission(MsPermission.CHANGE_PWD)) {
            if (isVisuPw) {
                screenDetails.changeVisuPassword = true;
            }

            navigation.navigate(ScreenState.ChangeSecretScreen, screenDetails);
        } else {
            NavigationComp.showPopup({
                title: _("change-password.not-allowed-dialog.title", {
                    passwordType: pwTypeString
                }),
                message: _("change-password.not-allowed-dialog.desc", {
                    passwordType: pwTypeString
                }),
                buttonOk: _("change-password.not-allowed-dialog.ok"),
                icon: Icon.INFO,
                color: globalStyles.colors.stateActive
            });
        }
    }

    return  <LxReactTableView
        tableContent={_getContent()}
        showSectionSeparator={false}
    />
}

CurrentUserScreen.propTypes = {
    completeUser: PropTypes.object,
    currentUser: PropTypes.object,
    hasUserManagementPermissions: PropTypes.bool,
    navigator: PropTypes.object
}

export default CurrentUserScreen;
