'use strict';

define(["AboutScreenBase", "LicensesScreen"], function (AboutScreenBase) {
    return class AboutAppScreen extends AboutScreenBase {
        constructor() {
            super($('<div />'));
            this.appInfo = PlatformComponent.getAppInfoObj();
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.setIcon(Icon.Menu.APP_ICON);
                this.setTitle(this.appInfo.appName);
            }.bind(this));
        }

        reloadTable() {
            this.tableContent = [];

            let rows = [];
            rows.push(this._createInfoCell(_("version"), this.appInfo.appVersion));
            if (Licences.length > 0) {
                rows.push({
                    content: {
                        title: _("app.open-source-licences"),
                        disclosureIcon: true
                    },
                    action: this._showLicences.bind(this)
                });
            }
            /*rows.push({
                content: {
                    title: _('app.rating'),
                    disclosureIcon: true
                },
                action: RatingRequester.showRatingPopup.bind(RatingRequester, true)
            })*/

            if (window.UPDATE_LEVEL !== UpdateComp.UpdateLevel.RELEASE) {
                rows.push(...[
                        "branch",
                        //"committer", the build server doesn't have a committer
                    ].map(gitKey => {
                        return this._createInfoCell(
                            gitKey.capitalize(),
                            gitKey === "committer" ? this._shortCommiterName(BuildInfo.git[gitKey]) : BuildInfo.git[gitKey]
                        );
                    }),
                    this._createInfoCell(
                        "Build at",
                        moment(BuildInfo.buildAt).format(DateType.DateAndTimeShort)
                    )
                );
            }

            this.tableContent.push({ rows: rows });

            return super.reloadTable(...arguments);
        }

        _shortCommiterName(fullName = "unknown") {
            if (fullName.indexOf(" <") > 0) {
                return fullName.substring(0, fullName.indexOf(" <"));
            } else {
                return fullName;
            }
        }

        _giveFeedback() {
            var link = "mailto:feedback@loxone.com?subject=" + encodeURIComponent("Feedback on Loxone App (" + this.appInfo.appVersion + ")");
            NavigationComp.openWebsite(link);
        }

        _showLicences() {
            this.ViewController.showState(ScreenState.Licenses);
        }

        _showDebugScreen() {
            GUI.DebugScreen.toggle();
        }

    };
});
