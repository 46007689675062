/**
 * Created by loxone on 20.06.16.
 */
'use strict';

define(['AddMediaViewControllerBase'], function (AddMediaViewControllerBase) {
    class AddMediaViewControllerZoneFavorite extends AddMediaViewControllerBase {
        constructor(details) {
            super(...arguments);
        }

        addItem(item, mediaInfo) {
            return Q(this.details.addFn({
                item: item,
                contentType: item.contentType,
                mediaTypeDetails: mediaInfo
            }).then(this.dismiss.bind(this)));
        }

        remoteItem(idx) {// Just do nothing...
        }

        moveItem(oldIdx, newIdx) {// Just do nothing...
        }

    }

    GUI.AddMediaViewControllerZoneFavorite = AddMediaViewControllerZoneFavorite;
    return GUI.AddMediaViewControllerZoneFavorite;
});
