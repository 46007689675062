"use strict";

window.MediaContextMenuHandler = {
    get Mixin() {
        return {
            __audioZoneControlEnums: require("AudioZoneControlEnums"),
            __audioZoneShortcutHelper: require("AudioZoneShortcutHelper"),

            CONTEXT: {
                LOCAL: "local",
                LMS: "lms",
                // -> Loxone Music Server
                SPOTIFY: "spotify"
            },

            /**
             * Shows the Contextual Menu for the given Item
             * @param item
             * @private
             */
            _showContextMenuForItem: function _showContextMenuForItem(item) {
                this.__ctxItem = item;
                var texts;

                if (this.__ctxItem instanceof GUI.Screen) {
                    texts = {
                        title: this.titleBarText()
                    };
                } else {
                    texts = MediaServerComp.getTitleSubtitleForItem(this.__ctxItem);
                }

                MediaServerComp.AudioViewController.showState(this.__audioZoneControlEnums.ScreenState.CONTEXT_MENU_BASE, null, {
                    control: this.control,
                    delegate: this,
                    title: decodeURIComponent(texts.title)
                }, AnimationType.MODAL);
            },
            _getActionWithTmplForCtx: function _getActionWithTmplForCtx(actionTmpl, ctx) {
                if (!ctx) {
                    console.warn("No context is provided, ignore action!");
                    return null;
                }

                actionTmpl.ctx = ctx;

                if (!actionTmpl.content.hasOwnProperty("clickable")) {
                    actionTmpl.content.clickable = true;
                }

                if (actionTmpl.content.hasOwnProperty("leftIconSrc") && !actionTmpl.content.hasOwnProperty("leftIconColor")) {
                    actionTmpl.content.leftIconColor = Color.TEXT_SECONDARY_B;
                }

                return actionTmpl;
            },

            /**
             * Builds the TableContent for the Contextual Menu Screen
             * @note Its compatible with promises!
             * @param hideFn
             * @returns {Promise}
             * @private
             */
            __getCtxMenuTableContent: function __getCtxMenuTableContent(hideFn) {
                var contexts = Object.values(this.CONTEXT),
                    tmpPrms = null,
                    tmpDefaultPrms = null,
                    ctxPrms = [],
                    tmpSection,
                    contextTableContent = []; // Add the custom provided context sections if available

                if (this.getCustomContextsForItem) {
                    try {
                        contexts = (this.getCustomContextsForItem(this.__ctxItem) || []).concat(contexts);
                    } catch (e) {
                        console.warn(this.name, "Can't get custom contexts for item! -> " + e.message);
                    }
                } // Get actions for every provided context


                contexts.forEach(function (ctx) {
                    if (this.getActionsForCtxAndItem) {
                        try {
                            tmpPrms = Q(this.getActionsForCtxAndItem(ctx, this.__ctxItem));
                            ctxPrms.push(tmpPrms);
                        } catch (e) {
                            console.warn(this.name, "Can't get actions for context and item! -> " + e.message);
                        }
                    }

                    tmpDefaultPrms = Q(this.__getDefaultActionsForCtx(ctx));
                    ctxPrms.push(tmpDefaultPrms);
                }.bind(this)); // Convert them into a TableContent

                return Q.all(ctxPrms).then(function (ctxsActions) {
                    contexts.forEach(function (ctx) {
                        tmpSection = {
                            headerTitle: this.__getCtxDesc(ctx),
                            rows: []
                        };
                        var wantedActions = [];
                        ctxsActions.forEach(function (ctxActions) {
                            if (ctxActions && ctxActions[0] && ctxActions[0].ctx === ctx) {
                                wantedActions = ctxActions.concat(wantedActions);
                            }
                        });
                        tmpSection.rows = wantedActions.filter(function (action) {
                            return action !== null;
                        });

                        if (tmpSection.rows.length) {
                            // Wrap around the provided action to hide the context menu once selecting a cell
                            tmpSection.rows.forEach(function (row) {
                                row.ogAction = row.action;

                                row.action = function () {
                                    Q(hideFn()).done(function () {
                                        row.ogAction && row.ogAction();
                                    }.bind(this));
                                }.bind(this);
                            }.bind(this));
                            contextTableContent.push(tmpSection);
                        }
                    }.bind(this));
                    return contextTableContent;
                }.bind(this));
            },

            /**
             * Gets the Default Actions for a given Context
             * @param ctx
             * @returns {Promise}
             * @private
             */
            __getDefaultActionsForCtx: function __getDefaultActionsForCtx(ctx) {
                var prms = [],
                    item;

                switch (ctx) {
                    case this.CONTEXT.LOCAL:
                        if (this.__ctxItem instanceof Controls.AudioZoneControl.MediaBrowserBase) {
                            item = this.__ctxItem.lastSelectedItem; // Resolve the actual item if the user tapped on the screens context menu
                        } else {
                            item = this.__ctxItem;
                        }

                        if (MediaServerComp.isFileTypePlayable(item.type, item.contentType)) {
                            if (!(this instanceof Controls.AudioZoneControl.AudioZoneControlPlayer)) {
                                prms.pushObject(this.__getPlayNowActionForCtxAndItem(ctx, item));
                                prms.pushObject(this.__getPlayNextActionForCtxAndItem(ctx, item));
                                prms.pushObject(this.__getAddToQueueActionForCtxAndItem(ctx, item));
                            }

                            prms.pushObject(this.__getAddToLMSPlaylistActionForCtxAndItem(ctx, item));
                        }

                        break;

                    case this.CONTEXT.LMS:
                        if (Feature.SHARED_USER_SETTINGS) {
                            prms.pushObject(this.__getShortcutActionForCtx(ctx));
                        }

                        prms.pushObject(this.__getSaveAsZoneFavForCtx(ctx));
                        prms.pushObject(this.__getSaveAsFavForCtx(ctx));
                        break;

                    case this.CONTEXT.SPOTIFY:
                        if (this.__ctxItem instanceof Controls.AudioZoneControl.MediaBrowserBase) {
                            item = this.__ctxItem.lastSelectedItem; // Resolve the actual item if the user tapped on the screens context menu
                        } else {
                            item = this.__ctxItem;
                        }

                        if (item.contentType === MediaEnum.MediaContentType.SERVICE && this.identifier === MediaEnum.Service.SPOTIFY) {
                            if (MediaServerComp.isFileTypePlayable(item.type, item.contentType)) {
                                prms.pushObject(this._getActionWithTmplForCtx({
                                    content: {
                                        title: _("media.playlist.add-to-list.spotify"),
                                        leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_ADD
                                    },
                                    action: this.__addItemToPlaylistOfContentType.bind(this, item, MediaEnum.MediaContentType.SERVICE, _("media.playlist.add-to-list.spotify"))
                                }, ctx));
                            }
                        }

                        break;

                    default:
                        break;
                }

                return Q.all(prms);
            },

            /**
             * Gets the default Shortcuts action for the given context
             * It automatically knows if the shortcut does already exist and offers add or remove functionality
             * @param ctx
             * @returns {*}
             * @private
             */
            __getShortcutActionForCtx: function __getShortcutActionForCtx(ctx) {
                // when no mediaInfos are available, we can't ensure that shortcut gets created correctly, like in
                // audioZoneControlPlayer
                if (!this.getMediaInfo) {
                    return null;
                }

                return this.__audioZoneShortcutHelper.getShortcuts(this.control.uuidAction).then(function (shortcuts) {
                    var shortcutItem = cloneObjectDeep(this.__ctxItem),
                        alreadyExists = false,
                        icon = this.__ctxItem.icon || Icon.DEFAULT;

                    if (this.__ctxItem instanceof GUI.Screen) {
                        if (this.__ctxItem instanceof Controls.AudioZoneControl.MediaBrowserBase) {
                            icon = this.__ctxItem.constructor.getIconObjForItem(this.__ctxItem.lastSelectedItem, this.__ctxItem.identifier).highRes;
                        }

                        shortcutItem = {
                            title: this.__ctxItem.titleBarText(),
                            screenState: "Controls.AudioZoneControl." + this.__ctxItem.constructor.name,
                            // TODO-goelzda: Is it a good idea to save the screenState?!?
                            details: this.__ctxItem.details,
                            icon: icon,
                            type: MediaEnum.FileType.SCREEN_STATE_SHORTCUT,
                            requiredPlatforms: this.__ctxItem.constructor.requiredPlatforms ? this.__ctxItem.constructor.requiredPlatforms() : Object.values(PlatformType),
                            requiredLMSFeatures: this.__ctxItem.constructor.requiredLMSFeatures ? this.__ctxItem.constructor.requiredLMSFeatures() : [],
                            requiresInternalConnection: this.__ctxItem.constructor.hasOwnProperty("requiresInternalConnection") ? this.__ctxItem.requiresInternalConnection : false
                        };
                    } else {
                        if (shortcutItem.contentType !== MediaEnum.MediaContentType.LINEIN) {
                            shortcutItem.config = {
                                username: this.username,
                                serviceIdentifier: this.identifier,
                                mediaType: this.mediaType,
                                lastSelectedItem: this.__ctxItem,
                                serviceObj: this.getMediaInfo && this.getMediaInfo(shortcutItem.contentType)
                            };
                        }

                        try {
                            shortcutItem.icon = MediaServerComp.getMediaBrowserForContentType(this.__ctxItem.contentType).getIconObjForItem(this.__ctxItem).highRes;
                        } catch (e) {// Just tried to get an icon and failed, the default one will be used instead
                        }
                    }

                    alreadyExists = !!shortcuts.find(function (shortcut) {
                        return JSON.stringify(shortcut) === JSON.stringify(shortcutItem);
                    });
                    return this._getActionWithTmplForCtx({
                        content: {
                            title: alreadyExists ? _("media.item.add-as-shortcut.remove") : _("media.item.add-as-shortcut.add"),
                            leftIconSrc: alreadyExists ? Icon.AudioZone.CONTEXT.SHORTCUT_REMOVE : Icon.AudioZone.CONTEXT.SHORTCUT_ADD
                        },
                        action: function () {
                            if (alreadyExists) {
                                this.__audioZoneShortcutHelper.removeShortcut(shortcutItem);
                            } else {
                                this.__audioZoneShortcutHelper.saveShortcuts(shortcutItem);
                            }
                        }.bind(this)
                    }, ctx);
                }.bind(this));
            },
            __getSaveAsZoneFavForCtx: function __getSaveAsZoneFavForCtx(ctx) {
                var item = this.__ctxItem;

                if (item instanceof Controls.AudioZoneControl.MediaBrowserBase) {
                    item = this.__ctxItem.lastSelectedItem;
                }

                if ((MediaServerComp.isFileTypePlayable(item.type, item.contentType) || item.type === MediaEnum.FileType.HW_INPUT) && !(item instanceof GUI.Screen) && item.contentType !== MediaEnum.MediaContentType.FAVORITES && item.contentType !== MediaEnum.MediaContentType.ZONE_FAVORITES) {
                    if (!this.getMediaInfo) {
                        console.warn(this.name, "this.getMediaInfo is required but missing!");
                        return;
                    }

                    if (item.contentType !== MediaEnum.MediaContentType.LINEIN || item.contentType === MediaEnum.MediaContentType.LINEIN && MediaServerComp.Feature.INPUT_ZONE_FAVS) {
                        return this._getActionWithTmplForCtx({
                            content: {
                                title: _("media.zone.fav.save-as", {
                                    zoneName: this.control.name
                                }) + "...",
                                leftIconSrc: Icon.AudioZone.CONTEXT.FAVORITE_ADD
                            },
                            action: function () {
                                MediaServerComp.AudioViewController.showState(this.__audioZoneControlEnums.ScreenState.ADD_ZONE_FAV, null, {
                                    delegate: this,
                                    control: this.control,
                                    itemToAdd: item
                                });
                            }.bind(this)
                        }, ctx);
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            },
            __getSaveAsFavForCtx: function __getSaveAsFavForCtx(ctx) {
                var item = this.__ctxItem;

                if (item instanceof Controls.AudioZoneControl.MediaBrowserBase) {
                    item = this.__ctxItem.lastSelectedItem;
                }

                if (MediaServerComp.isFileTypePlayable(item.type, item.contentType) && !(item instanceof GUI.Screen) && item.contentType !== MediaEnum.MediaContentType.FAVORITES && item.contentType !== MediaEnum.MediaContentType.ZONE_FAVORITES) {
                    return this._getActionWithTmplForCtx({
                        content: {
                            title: _("media.item.add-to-favorites-of-all-zones"),
                            leftIconSrc: Icon.AudioZone.CONTEXT.FAVORITE_ADD
                        },
                        action: MediaServerComp.addItemToFavorites.bind(MediaServerComp, item)
                    }, ctx);
                } else {
                    return null;
                }
            },
            __getPlayNowActionForCtxAndItem: function __getPlayNowActionForCtxAndItem(ctx, item) {
                return this._getActionWithTmplForCtx({
                    content: {
                        title: _("media.item.play-now"),
                        leftIconSrc: Icon.AudioZone.CONTEXT.PLAY
                    },
                    action: this.handleItemPlayCommand.bind(this, item, MediaEnum.PlayType.REPLACE)
                }, ctx);
            },
            __getPlayNextActionForCtxAndItem: function __getPlayNextActionForCtxAndItem(ctx, item) {
                // The Musicserver is not capable to execute this action for Zone Favorites as of now
                if (item.contentType !== MediaEnum.MediaContentType.ZONE_FAVORITES) {
                    return this._getActionWithTmplForCtx({
                        content: {
                            title: _("media.play.next"),
                            leftIconSrc: Icon.AudioZone.CONTEXT.PLAY_NEXT
                        },
                        action: this.handleItemPlayCommand.bind(this, item, MediaEnum.PlayType.NEXT)
                    }, ctx);
                }
            },
            __getAddToQueueActionForCtxAndItem: function __getAddToQueueActionForCtxAndItem(ctx, item) {
                // The Musicserver is not capable to execute this action for Zone Favorites as of now
                if (item.contentType !== MediaEnum.MediaContentType.ZONE_FAVORITES) {
                    return this._getActionWithTmplForCtx({
                        content: {
                            title: _("media.item.add-to-queue"),
                            leftIconSrc: Icon.AudioZone.CONTEXT.PLAY_LAST
                        },
                        action: this.handleItemPlayCommand.bind(this, item, MediaEnum.PlayType.ADD)
                    }, ctx);
                }
            },
            __getAddToLMSPlaylistActionForCtxAndItem: function __getAddToLMSPlaylistActionForCtxAndItem(ctx, item) {
                var title = _("media.playlist.add-to-list.local");

                return this._getActionWithTmplForCtx({
                    content: {
                        title: title,
                        leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_ADD
                    },
                    action: this.__addItemToPlaylistOfContentType.bind(this, item, MediaEnum.MediaContentType.PLAYLISTS, title)
                }, ctx);
            },
            __addItemToPlaylistOfContentType: function __addItemToPlaylistOfContentType(item, dstContentType, title) {
                var details = {
                    control: MediaServerComp.getActiveZoneControl(),
                    mediaType: MediaEnum.MediaType.PLAYLIST
                };

                if (dstContentType === MediaEnum.MediaContentType.SERVICE) {
                    details.identifier = MediaEnum.Service.SPOTIFY;
                    details.lastSelectedItem = {
                        id: MediaEnum.Spotify.TYPES.MY_PLAYLISTS,
                        contentType: dstContentType
                    };
                    details.username = this.username;
                } else if (dstContentType === MediaEnum.MediaContentType.PLAYLISTS) {
                    details.identifier = MediaEnum.Service.LMS;
                    details.lastSelectedItem = {
                        contentType: dstContentType
                    };
                    details.username = MediaEnum.NOUSER;
                } else {
                    console.warn("The contentType isn't supported yet!");
                    return;
                }

                details.contentTypes = [dstContentType];
                details.lastSelectedItem.name = title; // ViewController specific properties

                details.targetMediaScreenState = Controls.AudioZoneControl.MediaBrowserBase.getScreenStateForItem(details.lastSelectedItem);
                details.addTargetName = title;
                details.delegate = this;
                details.itemToAdd = item;
                details.blockedIds = [item.id];
                MediaServerComp.AudioViewController.showState(this.__audioZoneControlEnums.ScreenState.ADD_MEDIA_VC_PLAYLIST_PICKER, null, details);
            },
            handleItemPlayCommand: function handleItemPlayCommand(item, playType) {
                var isSearch = this instanceof Controls.AudioZoneControl.MediaSearchBase || this.details.isFromSearch,
                    contentType = isSearch ? MediaEnum.MediaContentType.SEARCH : item.contentType,
                    cmd = MediaServerComp.getPlayCommandForItem(item, playType, contentType, this.getMediaInfo(item.contentType)),
                    result = false;

                if (cmd) {
                    result = MediaServerComp.sendAudioZoneCommand(this.control.details[MediaEnum.Event.PLAYER_ID], {
                        cmd: cmd
                    });
                }

                return result;
            },
            __getCtxDesc: function __getCtxDesc(ctx) {
                var desc = null;

                switch (ctx) {
                    case this.CONTEXT.LMS:
                        desc = _("loxone-music-server");
                        break;

                    case this.CONTEXT.SPOTIFY:
                        desc = _("media.popup.spotify.title");
                        break;

                    default:
                        if (this.getCtxDesc) {
                            desc = this.getCtxDesc(ctx);
                        }

                        break;
                }

                return desc;
            }
        };
    }
};
