'use strict';

define(['AudioZoneControlEnums', 'AudioZoneDetailedContentBase'], function (AudioZoneControlEnums, AudioZoneDetailedContentBase) {
    class ZoneGroupOverviewScreen extends AudioZoneDetailedContentBase {
        constructor(details) {
            super(...arguments);
            this.details = details;
            this.control = MediaServerComp.getActiveZoneControl();
            this.stateSet = {};
            this.groupMap = {};
            this.tableContent = [];
            this.allZonesSet = MediaServerComp.getAvailableZones();
        }

        viewWillAppear() {
            this._registerForZoneStates();

            return super.viewWillAppear(...arguments);
        }

        destroy() {
            this._unregisterFromZoneStates();

            return super.destroy(...arguments);
        }

        titleBarText() {
            return this.control.getName();
        }

        getURL() {
            return "ZoneGroupOverviewScreen";
        }

        setTableContent() {
            this.tableContent = this.tableContent || [];

            if (Object.keys(this.stateSet).length && Object.keys(this.stateSet).length === Object.keys(this.allZonesSet).length) {
                this.used = {};
                this.usedGrouping = {};
                var groupings = [];
                Object.keys(this.allZonesSet).forEach(function (playerId) {
                    if (!this.usedGrouping[playerId]) {
                        if (this.allZonesSet[playerId].details.clientType !== MediaEnum.ClientType.UPNP) {
                            if (parseFloat(playerId) !== this.control.details.playerid && !(this.stateSet[playerId] && this._includesSelectedZone(playerId))) {
                                groupings.push(this._getGroupedZonesFor(playerId));
                            } else {
                                groupings.unshift(this._getGroupedZonesFor(playerId));
                            }
                        }
                    }
                }.bind(this));
                this.tableContent = groupings.map(function (grouping) {
                    return {
                        rows: [{
                            type: GUI.TableViewV2.CellType.Special.ZONE_GROUPING,
                            content: {
                                grouping: grouping,
                                clickable: true
                            },
                            action: this._showGroupScreen.bind(this, ActiveMSComponent.getStructureManager().getControlByUUID(grouping[0].uuidAction))
                        }]
                    };
                }.bind(this));
            }

            return super.setTableContent(...arguments);
        }

        _registerForZoneStates() {
            this._unregisterFromZoneStates();

            Object.keys(this.allZonesSet).forEach(function (key) {
                this.registrations.push(this.allZonesSet[key].uuidAction);
                SandboxComponent.registerForStateChangesForUUID(this.allZonesSet[key].uuidAction, this, function (states) {
                    this._zoneStatesReceived(states, key);
                }.bind(this));
            }.bind(this));
        }

        _unregisterFromZoneStates() {
            if (this.registrations) {
                // Unregister
                this.registrations.forEach(function (regUuid) {
                    SandboxComponent.unregisterForStateChangesForUUID(regUuid, this);
                }.bind(this));
            }

            this.registrations = [];
        }

        /**
         * Will update the stateSet for this player and also trigger an UI update if critical data has changed.
         * @param states
         * @param playerId
         * @private
         */
        _zoneStatesReceived(states, playerId) {
            var playStateChanged = true,
                clientStateChanged = true,
                groupingChanged = true,
                syncColorChanged = true; //Detect if these states really did change

            if (this.stateSet.hasOwnProperty(playerId)) {
                playStateChanged = this.stateSet[playerId].isPlaying !== states.isPlaying;
                clientStateChanged = this.stateSet[playerId].clientState !== states.clientState;
                groupingChanged = JSON.stringify(this.stateSet[playerId][MediaEnum.Event.SYNCED_ZONES]) !== JSON.stringify(states[MediaEnum.Event.SYNCED_ZONES]);
                syncColorChanged = this.stateSet[playerId].syncColor !== states.syncColor;
            }

            if (playStateChanged || clientStateChanged || groupingChanged || syncColorChanged) {
                Debug.Control.AudioZone.Group && console.log(this.name, "_zoneStatesReceived: " + playerId);
                this.stateSet[playerId] = cloneObject(states);

                if (!this.currentReloadPromise) {
                    this.currentReloadPromise = this.reloadTable().then(function () {
                        this.currentReloadPromise = null;

                        if (this.secondReloadIsNeed) {
                            return this.reloadTable();
                        } else {
                            return Q.resolve();
                        }
                    }.bind(this));
                } else {
                    this.secondReloadIsNeed = true;
                }
            }
        }

        _includesSelectedZone(playerId) {
            var includes = false;
            this.stateSet[playerId].syncedzones.forEach(function (playerIdObj) {
                if (playerIdObj.playerid === this.control.details.playerid) {
                    includes = true;
                }
            }.bind(this));
            return includes;
        }

        _getGroupedZonesFor(playerId) {
            if (this.stateSet[playerId].isSynced) {
                return this.stateSet[playerId].syncedzones.map(function (playerIdObj) {
                    this.usedGrouping[playerIdObj.playerid] = playerIdObj;
                    return this.allZonesSet[playerIdObj.playerid];
                }.bind(this));
            } else {
                return [this.allZonesSet[playerId]];
            }
        }

        _showGroupScreen(control) {

            this.ViewController.ViewController.showState(AudioZoneControlEnums.ScreenState.GROUP, null, {
                control: control
            });
        }

    }

    Controls.AudioZoneControl.ZoneGroupOverviewScreen = ZoneGroupOverviewScreen;
});
