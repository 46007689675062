import * as React from "react"
import Svg, { Path } from "react-native-svg"

function VolMinus(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path d="M9 19.654a1 1 0 01-1.65.759l-4.683-4.585H1a1 1 0 01-1-1v-6a1 1 0 011-1h1.667l4.682-4.585A1 1 0 019 4.002v15.652zm4-8.826a1 1 0 000 2h10a1 1 0 100-2H13z" />
        </Svg>
    )
}

export default VolMinus
