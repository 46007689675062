import * as React from "react"
import Svg from "react-native-svg"

function Weather07(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props} />
    )
}

export default Weather07
