'use strict';

define(["AudioZoneV2ControlEnums", "MediaBrowserV2Service"], function (AudioZoneV2ControlEnums, MediaBrowserV2Service) {
    class MediaBrowserV2Service12 extends MediaBrowserV2Service {
        //region Static
        static isRadio() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Service.isRadio.apply(this, arguments);
        }

        static getCellFromContentTypeItem() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Service.getCellFromContentTypeItem.apply(this, arguments);
        }

        static getCellButtonIconSrc() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Service.getCellButtonIconSrc.apply(this, arguments);
        }

        static getCellButtonIconColor() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Service.getCellButtonIconColor.apply(this, arguments);
        }

        static getCellTypeForItemCellAtIndex() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Service.getCellTypeForItemCellAtIndex.apply(this, arguments);
        }

        static getIconObjForItem() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Service.getIconObjForItem.apply(this, arguments);
        } //endregion Static


        constructor(details) {
            super(...arguments);
        }

    }

    // FileType = 12 = FOLLOWED_PLAYLIST
    Controls.AudioZoneV2Control.MediaBrowserV2Service12 = MediaBrowserV2Service12;
    return Controls.AudioZoneV2Control.MediaBrowserV2Service12;
});
