import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"

function SystemStateInfoCircled(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Circle cx={12} cy={12} r={12} />
            <Path
                fill="#FFF"
                d="M12 6c3.312 0 6 2.688 6 6s-2.688 6-6 6-6-2.688-6-6 2.688-6 6-6zm0 4.75a.25.25 0 00-.25.25v3.75a.25.25 0 00.5 0V11a.25.25 0 00-.25-.25zm0-1A.375.375 0 1012 9a.375.375 0 000 .75z"
            />
        </Svg>
    )
}

export default SystemStateInfoCircled
