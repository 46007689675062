import * as React from "react"
import Svg, { Path } from "react-native-svg"

function WallboxManager(props) {
    return (
        <Svg
            isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M3.92 0h3.621L2 7.85V1.888a.5.5 0 01.15-.358L3.57.142A.5.5 0 013.92 0zM2 22.112V9.12l10.86 14.316-.43.422a.5.5 0 01-.35.142H3.92a.5.5 0 01-.35-.142L2.15 22.47a.5.5 0 01-.15-.358zM13.85 22.47l-.449.438L2.465 8.492 8.459 0h3.62a.5.5 0 01.35.142l1.42 1.388a.5.5 0 01.151.358v20.224a.5.5 0 01-.15.358zM15 16.323v-2.591A2.002 2.002 0 0018 12a2.002 2.002 0 00-3-1.732V6.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v1.428c.395.1.832.226 1.165.388l1.017-1.015a.25.25 0 01.353 0l1.415 1.413a.25.25 0 010 .354l-1.017 1.016c.163.332.29.771.39 1.166h1.427a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-1.427c-.1.396-.227.835-.389 1.166l1.017 1.016a.25.25 0 010 .354l-1.415 1.414a.25.25 0 01-.353 0l-1.017-1.016c-.332.161-.77.289-1.166.389v1.427a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-1.427z"
            />
        </Svg>
    )
}

export default WallboxManager
