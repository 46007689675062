'use strict';

define(["AudioZoneControlEnums"], function (AudioZoneControlEnums) {
    class AudioZoneControlSettingsLibraryOverview extends GUI.TableViewScreenV2 {
        //region Private
        ERROR = MediaEnum.NAS_ERROR; //endregion Private

        constructor(details) {
            super($('<div />'));
            this.libObj = details.libObj;
            this.name = this.libObj.name;
        }

        updateView(libDiff) {
            Object.keys(libDiff).forEach(function (key) {
                this.libObj[key] = libDiff[key];
            }.bind(this));
            this.name = this.libObj.name;
            this.reloadTable();
            super.updateView(...arguments);
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            return _("media.library");
        }

        closeAction() {
            var args = arguments;

            if (this.name !== this.libObj.name) {
                NavigationComp.showWaitingFor(MediaServerComp.sendMediaServerCommand(Commands.format(MediaEnum.Commands.SETTINGS.LIBRARY.EDIT, this.libObj.id, btoa(unescape(encodeURIComponent(JSON.stringify({
                    name: this.name
                }))))))).done(res => {
                    this.libObj = res.data;

                    if (res.data.configerror !== this.ERROR.NONE) {
                        this._handleError();
                    }

                    super.closeAction(...args);
                });
            } else {
                super.closeAction(...args);
            }
        }

        reloadTable() {
            var credsSubtitle = "";

            if (!this.libObj.isGuest) {
                credsSubtitle = this.libObj.username;

                if (this.libObj.password) {
                    credsSubtitle += " / •••••";
                }
            }

            this.tableContent = [{
                customClass: this.libObj.configerror ? "lx-table-view__section--with-error" : null,
                rows: [{
                    content: {
                        placeholder: _("miniserver.name"),
                        value: this.name
                    },
                    type: GUI.TableViewV2.CellType.INPUT,
                    textChanged: function textChanged(section, row, tableView, value) {
                        this.name = value;
                    }.bind(this)
                }, {
                    content: {
                        title: this.libObj.server + "/" + this.libObj.folder,
                        subtitle: credsSubtitle,
                        rightIconSrc: Icon.SETTINGS_SMALL,
                        clickable: true
                    },
                    action: function action(section, row, tableView, value) {
                        this.ViewController.showState(AudioZoneControlEnums.ScreenState.SETTINGS_LIBRARY_ADD_OR_EDIT, null, {
                            libObj: this.libObj
                        });
                    }.bind(this)
                }],
                footerTitle: this._getErrorDescription()
            }, {
                rows: [{
                    content: {
                        title: _("media.library.remove")
                    },
                    type: GUI.TableViewV2.CellType.DELETE,
                    action: this._deleteLib.bind(this)
                }]
            }];
            return super.reloadTable(...arguments);
        }

        _deleteLib() {
            NavigationComp.showWaitingFor(MediaServerComp.sendMediaServerCommand(Commands.format(MediaEnum.Commands.SETTINGS.LIBRARY.DELETE, this.libObj.id))).done(function (res) {
                this.ViewController.navigateBack();
            }.bind(this));
        }

        _handleError() {
            var errorTitl = _("audio-server.library.nas.error.generic.title"),
                errorMsg = this._getErrorDescription();

            NavigationComp.showErrorPopup(false, errorTitl, errorMsg);
        }

        _getErrorDescription() {
            var desc = null;

            switch (this.libObj.configerror) {
                case this.ERROR.DUPLICATE:
                    desc = _("audio-server.library.nas.error.duplicate.message");
                    break;

                case this.ERROR.NON_EXISTENT:
                    desc = _("audio-server.library.nas.error.non-existent.message");
                    break;

                case this.ERROR.UNREACHABLE:
                    desc = _("audio-server.library.nas.error.not-reachable.message");
                    break;

                case this.ERROR.INVALIDE_CREDS:
                    desc = _("audio-server.library.nas.error.invalide-creds.message");
                    break;
            }

            return desc;
        }

    }

    Controls.AudioZoneControl.AudioZoneControlSettingsLibraryOverview = AudioZoneControlSettingsLibraryOverview;
});
