'use strict';

define(['../../../../../react-comps/AmbientMode/screens/AmbientDefaultLocationSelector'], function (AmbientDefaultLocationSelector) {
    return class EntryPointLocationSelectionScreen extends GUI.TableViewScreenV2 {

        static ReactComp = AmbientDefaultLocationSelector.default;

        get useNewTableView() {
            return true;
        }

        constructor(details) {
            super($('<div/>'));
            //this.locationSrc = EntryPointHelper.getLocationSources();
            //this.details = details || {};
        }

        /*getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            return _("entry-point.name");
        }

        titleBarAction() {
            this.ViewController.navigateBack(false, {}); // call with details for prev.view, the settings screen will update (due to location change)
        }

        reloadTable() {
            this.tableContent = [];
            Object.keys(this.locationSrc).forEach(function (srcKey, dstIdx) {
                var srcs = this.locationSrc[srcKey],
                    rows = [];
                srcs.content.forEach(function (src) {
                    var cell = {
                        type: GUI.TableViewV2.CellType.CHECKABLE,
                        content: {
                            title: src.name,
                            selected: EntryPointHelper.matchWithCurrentLocation(src.location),
                            radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE
                        },
                        didCheckCell: this.didCheckCell.bind(this)
                    };
                    rows.push(cell);
                }.bind(this));
                this.tableContent.push({
                    headerTitle: srcs.name,
                    rows: rows,
                    footerTitle: srcs.description
                });
            }.bind(this));
            return super.reloadTable(...arguments);
        }

        didCheckCell(cell, section, row, tableView, value) {
            var locationKeys = Object.keys(this.locationSrc);
            var src = this.locationSrc[locationKeys[section]].content[row];
            EntryPointHelper.setEntryPointLocation(src.location);

            if (this.details.initSetup) {
                // Navigate to the location and start the entry mode
                NavigationComp.showEntryPointLocation();
            }
        }*/

    };
});
