'use strict';

define(['FavoriteSelectorBase', 'ColorPreviewBar', 'ColorSelectorV2', 'ColorPickerV2ControlEnums'], function (FavoriteSelectorBase, ColorPreviewBar, ColorSelectorV2, ColorPickerV2ControlEnums) {
    var BRIGHTNESS_CACHE_TIMEOUT = 2500; // seconds until the local brightness cache is deleted.

    return class SelectorPageBase extends GUI.View {
        constructor(control) {
            super($('<div class="selector-page-base"/>'));
            applyMixins(this, StateHandler.Mixin);
            this.control = control;
            this.brightnessCache = false;
            this.ignoredUpdate = false;
            this.currentScrollOffset = 0;
            this.eventHandlers = [];
            this.isMobileDevice = PlatformComponent.isMobileDevice();
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                var promises = [];
                this.multiSelection = this.getSelectorMode() === ColorPickerV2ControlEnums.PickerMode.SEQUENCE;
                this.favoriteSelector = new FavoriteSelectorBase();
                promises.push(this.appendSubview(this.favoriteSelector));
                this.colorSelector = new ColorSelectorV2(this.multiSelection);
                promises.push(this.appendSubview(this.colorSelector));
                this.previewBar = new ColorPreviewBar();
                promises.push(this.appendSubview(this.previewBar));
                this.antiGhostTimer = new AntiGhostTimer(this._antiGhostTimeout.bind(this));
                this.tableView = new GUI.TableViewV2(this, this);
                promises.push(this.appendSubview(this.tableView));
                return Q.all(promises).then(function () {
                    if (!this.isMobileDevice) {
                        this.eventHandlers.push(Hammer(this.element[0].children[0]).on('dragleft dragright slideleft slideright', this._onDrag.bind(this)));
                        this.eventHandlers.push(Hammer(this.element[0].children[0]).on('dragend slideend', this._onDragEnd.bind(this)));
                    }

                    return this.tableView.reload().then(() => {
                        this._forceStateUpdate();
                        this._requestStates();
                    });
                }.bind(this));
            }.bind(this));
        }

        viewWillAppear() {
            var promise = super.viewWillAppear(...arguments);

            this._requestStates();

            return promise;
        }

        viewDidAppear() {
            var promise = super.viewDidAppear(...arguments);
            this.favoriteSelector.onFavoriteSelected = this.handleFavoriteSelected.bind(this);
            this.previewBar.onFavoriteChanged = this.handleToggleFavorite.bind(this);
            this.colorSelector.onColorPicked = this.respondToColorSelected.bind(this);
            this.colorSelector.onPickerReleased = this.respondToPickerRelease.bind(this);

            this._registerForStates();

            return promise;
        }

        viewWillDisappear() {
            this._unregisterStates(); // reset the anti ghost timer so it'll not fire when the view is not ready yet.


            this.antiGhostTimer.resetAntiGhostTimer(); // Reset the timer to prevent setting the value in the cell

            this.brightnessCacheTimeout && clearTimeout(this.brightnessCacheTimeout);
            this.favoriteSelector.onFavoriteSelected = null;
            return super.viewWillDisappear(...arguments);
        }

        receivedStates(states) {
            this.activeBrightness = states.activeBrightness;
            this.updateBrightnessCell();
            this.favoriteSelector.setFavorites(this.favorites); // don't update the selector/preview shortly after the color has been modified.

            if (this.antiGhostTimer.isActive()) {
                this.ignoredUpdate = true;
            } else {
                this.updatePreview();
                this.colorSelector.updateColorSelection(this.getPreviewColors());
            }

            if (this.control.tuneAbleWhiteOnly() && !this.control.isInDayLightMode()) {
                this.colorSelector.showTuneAbleWhiteOnly();
            }
        }

        destroy() {
            this.antiGhostTimer.resetAntiGhostTimer();
            return super.destroy();
        }

        // ------------------------------------------------------------------------
        //            Methods that have to be implemented by the subclass
        // ------------------------------------------------------------------------

        /**
         * Returns the value for the brightness slider.
         */
        getBrightnessValue() {
            throw new Error("getBrightnessValue needs to be implemented by the subclass!");
        }

        /**
         * Used for the color selector, it returns the current selector mode (HSV, Temp or sequence)
         */
        getSelectorMode() {
            throw new Error("getSelectorMode needs to be implemented by the subclass!");
        }

        /**
         * Will be called whenever one of the favorites has been selected.
         * @param idx       the index of the picked favorites inside the fav array.
         * @param fav       the favorite object that has been picked.
         */
        handleFavoriteSelected(idx, fav) {
            // antiGhostTime needs to be reseted before, setting the sequenzer
            this.antiGhostTimer.resetAntiGhostTimer();
            throw new Error("handleFavoriteSelected needs to be implemented by the subclass!");
        }

        /**
         * Will delete the favorite at the specified position.
         * @param favIdx    what favorite to delete
         */
        handleFavoriteDelete(favIdx) {
            throw new Error("handleFavoriteDelete needs to be implemented by the subclass!");
        }

        /**
         * Will save the current selection as new favorite at the specified position.
         * @param newIdx        where to save the new favorite to.
         */
        handleFavoriteSave(newIdx) {
            throw new Error("handleFavoriteSave needs to be implemented by the subclass!");
        }

        /**
         * Returns an array of colors to show as gradient in the previewBar
         */
        getPreviewColors() {
            throw new Error("getPreviewColors needs to be implemented by the subclass!");
        }

        /**
         * Called whenever the user modifies the brightness.
         * @param newBrightness
         * @param isDragging
         */
        handleBrightnessChanged(newBrightness, isDragging) {
            throw new Error("handleBrightnessChanged needs to be implemented by the subclass!");
        }

        /**
         * Called when a color is picked/changed using the colorSelection.
         * @param newColor  the current picked color
         * @param idx       the index of the picked color (for sequences)
         * @param dragging  if the selection process is still ongoing (still dragging along)
         */
        handleColorSelected(newColor, idx, dragging) {
            throw new Error("handleBrightnessChanged needs to be implemented by the subclass!");
        }

        // ------------------------------------------------------------------------
        //            Methods that might be overwritten
        // ------------------------------------------------------------------------

        /**
         * Will be called whenever the favorite toggle button is used.
         * @param isFav whether or not the current color should be marked as favorite or not
         */
        handleToggleFavorite(isFav) {
            var idx = this.getCurrentFavoriteIdx();

            if (idx >= 0) {
                // delete favorite
                this.handleFavoriteDelete(idx);
            } else {
                // save as new favorite
                this.handleFavoriteSave(this.favorites.length);
            }
        }

        /**
         * Called when a color is picked/changed using the colorSelection. Will update it with the current brightness
         * and then call handleColorSelected so subclasses can update their attributes.
         * @param newColor  the current picked color
         * @param idx       the index of the picked color (for sequences)
         * @param dragging  if the selection process is still ongoing (still dragging along)
         */
        respondToColorSelected(newColor, idx, dragging) {
            // start an anti ghost timer that avoids updating the UI with state updates for several seconds.
            this.antiGhostTimer.fire(); // We need to prevent any updates to the colorSelector, thus we only let the ghostTimer invalidate
            // if the user has released the colorSelector
            // Fire the antiGhostTimer every half ghostTimer duration

            if (!this.releaseTimer) {
                this.releaseTimer = setInterval(function () {
                    this.antiGhostTimer.fire();
                }.bind(this), this.antiGhostTimer.duration / 2);
            }

            this.isDragging = dragging;
            newColor.brightness = this.getBrightnessWithCache() || 100; // if the brightness was 0, immediately up it up.

            this.handleColorSelected(newColor, idx, dragging);
            this.updatePreview();

            if (!dragging) {
                // update right away, this way the UI is in a proper state. Important e.g. for sequences, as the latest
                // added point is not known yet.
                this.colorSelector.updateColorSelection(this.getPreviewColors());
            } // if the brightness is at 0 when the color is being picked, turn it up to 100!


            if (newColor.brightness === 0) {
                this._respondToBrightnessChanged(100);
            }
        }

        respondToPickerRelease() {
            // We need to prevent any updates to the colorSelector, thus we only let the ghostTimer invalidate
            // if the user has released the colorSelector
            // Clear the releaseTimer to let the antiGhostTimer invalidate
            if (this.releaseTimer) {
                clearInterval(this.releaseTimer);
                this.releaseTimer = null;
            }
        }

        getIsDragging() {
            return !!this.isDragging;
        }

        /**
         * Returns whether or not the current selection is a favorite or not
         */
        getCurrentFavoriteIdx() {
            var favIdx,
                currStr = JSON.stringify(this.selection);

            for (var i = 0; i < this.favorites.length; i++) {
                if (JSON.stringify(this.favorites[i]) === currStr) {
                    favIdx = i;
                    break;
                }
            }

            return favIdx;
        }

        /**
         * Will send the color object provided to the Miniserver.
         * @param color
         * @param isDragging
         * @param handleFavSelected
         */
        sendColorObject(color, isDragging, handleFavSelected) {
            if (this.control.isInDayLightMode() && !handleFavSelected) {
                this.sendCommand(Commands.format(Commands.COLOR_PICKER_V2.SET_BRIGHTNESS, color.brightness), Commands.Type.OVERRIDE, null, isDragging);
            } else {
                this.sendCommand(LxColorUtils.getStringFromColorObject(color), Commands.Type.OVERRIDE, null, isDragging);
            }
        }

        /**
         * Will update the preview bar. Both the colors and the isFavorite symbol.
         */
        updatePreview() {
            var colors = this.getPreviewColors(),
                favIdx = this.getCurrentFavoriteIdx();
            this.previewBar.setColors(colors);
            this.previewBar.setFavoriteActive(favIdx >= 0);
        }

        /**
         * If state updates have been ignored, this is the place to handle them. Its called when the anti ghost
         * timer fires.
         * @param forceUpdate   if true, the UI has to be updated.
         */
        handleAntiGhostTimeout(forceUpdate) {
            if (this.ignoredUpdate || forceUpdate) {
                this.updatePreview();
                this.colorSelector.updateColorSelection(this.getPreviewColors());
                this.ignoredUpdate = false;
            }
        }

        // ------------------------------------------------------------------------
        //            TableView Base
        // ------------------------------------------------------------------------

        /**
         * In the baseclass, the number of cells is always 1 --> brightness
         * @returns {number}
         */
        numberOfRowsInSection() {
            return 1;
        }

        numberOfSections() {
            return 1;
        }

        /**
         * The brightness-cell uses a gradient slider
         * @returns {string}
         */
        cellTypeForCellAtIndex() {
            return GUI.TableViewV2.CellType.Special.GRADIENT_SLIDER;
        }

        /**
         * @returns {{}}
         */
        contentForCell() {
            return {
                title: _("screensaver.settings.brightness"),
                format: "%.0f%",
                value: 100,
                minIconSrc: Icon.ColorPickerV2.Brightness.DOWN,
                maxIconSrc: Icon.ColorPickerV2.Brightness.UP,
                step: 1,
                gradientColors: [Color.STATE_INACTIVE, Color.Dimmer.ON]
            };
        }

        sliderDragged(cell, section, row, tableView, value, isDragging) {
            this._respondToBrightnessChanged(value, isDragging);
        }

        sliderClicked(cell, section, row, tableView, value) {
            this._respondToBrightnessChanged(value, false);
        }

        _respondToBrightnessChanged(newBrightness, isDragging) {
            // update the data set & send.
            this.handleBrightnessChanged(newBrightness, isDragging);
            this.brightnessCacheTimeout && clearTimeout(this.brightnessCacheTimeout);
            this.brightnessCacheTimeout = setTimeout(function () {
                this.brightnessCache = false;
                this.brightnessCacheTimeout = null;
                this.updateBrightnessCell();
            }.bind(this), BRIGHTNESS_CACHE_TIMEOUT);
            this.brightnessCache = newBrightness; // update the UI right away

            this.updatePreview();
            this.colorSelector.updateColorSelection(this.getPreviewColors());
        }

        /**
         * try to get the brightness from the local cache, speeds things up and avoids "flickering"
         * @returns {boolean|*}
         * @private
         */
        getBrightnessWithCache() {
            return this.brightnessCache !== false ? this.brightnessCache : this.getBrightnessValue();
        }

        _antiGhostTimeout() {
            this.handleAntiGhostTimeout(false); // don't force a UI update.
        }

        _onDrag(e) {
            e.currentTarget.scrollLeft = this.currentScrollOffset - e.gesture.deltaX;
        }

        _onDragEnd(e) {
            this.currentScrollOffset -= e.gesture.deltaX;
        }

    };
});
