'use strict';

define(["IRoomControlEnums"], function (IRoomControlEnums) {
    return class ManualModeViewV2 extends GUI.View {
        //region Static
        static Template = function () {
            var getView = function getView() {
                return $('<div class="iroomcontrol-manual-mode-view">' + '<div class="manual-mode__button-toucharea">' + ImageBox.getResourceImageWithClasses("resources/Images/General/button-minus.svg", "step-button") + '</div>' + '<div class="manual-mode__slider-placeholder">' + '<div class="slider-placeholder__indicator-line"></div>' + '</div>' + '<div class="manual-mode__button-toucharea toucharea--right">' + ImageBox.getResourceImageWithClasses("resources/Images/General/button-plus.svg", "step-button") + '</div></div>');
            };

            return {
                getView: getView
            };
        }(); //endregion Static

        constructor(control) {
            super(ManualModeViewV2.Template.getView());
            this.control = control;
            this.states = {};
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            let prms = [];
            this.elements = {
                sliderPlaceholder: this.element.find('.manual-mode__slider-placeholder'),
                sliderIndicator: this.element.find('.slider-placeholder__indicator-line')
            };
            this.buttons = {
                decreaseButton: new GUI.LxButton(this, this.element.find('.manual-mode__button-toucharea')[0], Color.BUTTON_GLOW, null, true, 0),
                increaseButton: new GUI.LxButton(this, this.element.find('.manual-mode__button-toucharea')[1], Color.BUTTON_GLOW, null, true, 0)
            };
            this.buttons.decreaseButton.useChildsAsActiveParts('fill');
            this.buttons.increaseButton.useChildsAsActiveParts('fill');
            this.addToHandledSubviews(this.buttons.decreaseButton);
            this.addToHandledSubviews(this.buttons.increaseButton);
            prms.push(this.appendReactComp({
                reactComp: GUI.LxSlider,
                compProps: {
                    activeColor: this.control.getColorOfIRCTempMode(IRoomControlEnums.IRCTempMode.MANUAL)
                },
                target: this.elements.sliderPlaceholder
            }).then(instance => this.temperatureSlider = instance));
            this.elements.sliderHandle = this.elements.sliderPlaceholder.find('.slider__handle-touch-area');
            this.antiGhostTimer = new AntiGhostTimer(this._antiGhostTimeout.bind(this));
            return Q.all(prms);
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);
            var buttonKeys = Object.keys(this.buttons);

            for (var i = 0; i < buttonKeys.length; i++) {
                var btnKey = buttonKeys[i];
                var btn = this.buttons[btnKey];
                btn.onButtonTapped = this._handleValueChange.bind(this);
                btn.onButtonTicked = this._handleValueChange.bind(this);
            }

            this.temperatureSlider.onPositionChanged = this._dispatchTemperature.bind(this);
        }

        viewDidDisappear() {
            var buttonKeys = Object.keys(this.buttons);

            for (var i = 0; i < buttonKeys.length; i++) {
                var btnKey = buttonKeys[i];
                var btn = this.buttons[btnKey];
                btn.onButtonTapped = null;
                btn.onButtonTicked = null;
            }

            this.temperatureSlider.onPositionChanged = null;
            super.viewDidDisappear(...arguments);
        }

        destroy() {
            this.antiGhostTimer.resetAntiGhostTimer();
            super.destroy();
        }

        updateManualMode(states) {
            if (!this.sliderOffset) {
                this.sliderOffset = this.elements.sliderPlaceholder[0].offsetLeft;
            } // TODO-sulzean show info if EMPTY_HOUSE-temp is greater than HEAT_PROTECTION-temp -> slider won't work with this (miss) config!


            this.minTemperature = states.activeTemperatures[IRoomControlEnums.IRCTempMode.EMPTY_HOUSE];
            this.maxTemperature = states.activeTemperatures[IRoomControlEnums.IRCTempMode.HEAT_PROTECTION];
            this.temperatureSlider.updateProperties({
                min: this.minTemperature,
                max: this.maxTemperature,
                step: 0.5
            });

            if (!this.antiGhostTimer.isAntiGhostTimerActive()) {
                this.targetTempGhostCache = null;
                this.targetTemperature = states.tempTarget;
                this.antiGhostTimer.resetAntiGhostTimer();
                this.temperatureSlider.setPosition(this.targetTemperature);

                this._updateIndicatorPosition(states.serviceMode === IRoomControlEnums.ServiceMode.OFF);
            } else {
                // save new target temp for when the antiGhostTimer fires (otherwise the buttons don't work properly)
                this.targetTempGhostCache = states.tempTarget;
            }
        }

        // ------------------------------------------------------------------------------
        //                UI Interaction
        // ------------------------------------------------------------------------------
        _handleValueChange(button) {
            var value = this.targetTemperature;

            if (button === this.buttons.decreaseButton) {
                value -= 0.5;
            } else if (button === this.buttons.increaseButton) {
                value += 0.5;
            }

            this._dispatchTemperature(value, true);
        }

        _updateIndicatorPosition(updateStateView) {
            var handleOffset = parseFloat(this.elements.sliderHandle.css('left')) - 1; // -1px because the width of the indicator line is 2px

            handleOffset += parseFloat(this.elements.sliderHandle.parent().css('margin-left'));
            this.elements.sliderIndicator.css('left', handleOffset + 'px');

            if (updateStateView) {
                var position = this.sliderOffset + handleOffset;
                this.updateArrowLines(position + 1); // +1px because the state view calculates the position according to the middle of the indicator line
            }
        }

        _antiGhostTimeout() {
            if (this.targetTempGhostCache !== null) {
                // a new target temp was cached while the ghost timer was active, set it!
                this.targetTemperature = this.targetTempGhostCache;
                this.targetTempGhostCache = null;
            }

            this.temperatureSlider.setPosition(this.targetTemperature);

            this._updateIndicatorPosition(true);
        }

        _dispatchTemperature(newTemp, doUpdateSlider, isDragging) {
            if (newTemp < this.minTemperature || newTemp > this.maxTemperature || newTemp === this.targetTemperature && isDragging) {
                return;
            }

            this.targetTemperature = newTemp;

            if (!!doUpdateSlider) {
                this.temperatureSlider.setPosition(this.targetTemperature);
            }

            this._updateIndicatorPosition(true);

            this.antiGhostTimer.fire();

            if (this.sendCommand) {
                var cmd = Commands.format(Commands.I_ROOM_CONTROLLER.MANUAL_TEMP, newTemp);
                this.sendCommand(cmd, Commands.Type.OVERRIDE, null, isDragging);
            }
        }

    };
});
