'use strict';

define(["DaytimerControlEnums"], function (DaytimerControlEnums) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var TIME_FORMAT = "HH:mm"; // Daytimer doesn't support AM/PM atm

    return class DaytimerListEntries extends GUI.View {
        //region Static
        static Template = function () {
            var switcherNavSrc = "resources/Images/General/arrow-back.svg";

            var getTemplate = function getContent(selectable) {
                var leftSwitcher = '';
                var rightSwitcher = '';

                if (selectable) {
                    leftSwitcher = '<div class="mode-switcher__btn btn--left clickable">' + ImageBox.getResourceImageWithClasses(switcherNavSrc, "mode-switcher__btn__icon") + '</div>';
                    rightSwitcher = '<div class="mode-switcher__btn btn--right clickable">' + ImageBox.getResourceImageWithClasses(switcherNavSrc, "mode-switcher__btn__icon") + '</div>';
                }

                return '<div class="list-entries__mode-switcher">' + leftSwitcher + '<span class="mode-switcher__title"></span>' + rightSwitcher + '</div>';
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(ctrl, fixedMode) {
            super($('<div class="list-entries"></div>'));
            Object.assign(this, StateHandler.Mixin);
            this.selectable = true;

            if (fixedMode) {
                this.selectable = !fixedMode;
            }

            this.control = ctrl; // the pool & irc-daytimer are passed in here as this.control - so be sure to have the pool & irc-control
            // itself ready too.

            this.parentControl = ActiveMSComponent.getControlByUUID(this.control.uuidParent);
            this.isEditMode = false;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.element.append(DaytimerListEntries.Template.getTemplate(this.selectable));
            this.tableView = new GUI.EditableTableView(this, this);
            this.tableView.getElement().addClass("list-entries__table-view");
            this.appendSubview(this.tableView);
            this.elements = {
                modeNameLabel: this.element.find('.mode-switcher__title')
            };

            if (this.selectable) {
                var btnPrev = this.element.find('.btn--left');
                var btnNext = this.element.find('.btn--right');
                this.previousModeButton = new GUI.LxButton(this, btnPrev[0], null, null, true);
                this.previousModeButton.useChildsAsActiveParts("fill");
                this.nextModeButton = new GUI.LxButton(this, btnNext[0], null, null, true);
                this.nextModeButton.useChildsAsActiveParts("fill");
                this.addToHandledSubviews(this.previousModeButton);
                this.addToHandledSubviews(this.nextModeButton);
            }
        }

        viewWillAppear() {
            // Request state updates before the subviews receive viewWillAppear in order to avoid issues
            // in the lifecycle methods if a subview is to be hidden/removed based on the states.
            // Lengthy tasks in received states should be performed async after viewDidAppear was called.
            this._requestStates();

            super.viewWillAppear(...arguments);
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);

            this._registerForStates();

            if (this.selectable) {
                this.previousModeButton.onButtonTapped = this._navigateToMode.bind(this, -1);
                this.nextModeButton.onButtonTapped = this._navigateToMode.bind(this, 1);
            }
        }

        viewWillDisappear() {
            if (this.selectable) {
                this.previousModeButton.onButtonTapped = null;
                this.nextModeButton.onButtonTapped = null;
            }

            this._unregisterStates();

            super.viewWillDisappear(...arguments);
        }

        receivedStates(states) {
            Debug.Control.Daytimer.ListView && console.log(this.name, "receivedStates");

            if (isNaN(this.shownModeNr)) {
                // no mode is shown yet
                this.shownModeNr = states.activeMode;
            }

            if (!this.isEditMode) {
                this.modeEntries = states.entries;
                this.modeNumbers = states.usedModes;
                this.firstModeNr = parseInt(this.modeNumbers[0]), // parse as it's a string otherwise
                    this.lastModeNr = parseInt(this.modeNumbers[this.modeNumbers.length - 1]);
            }

            this._showMode(this.shownModeNr);
        }

        // Public methods
        setEditMode(editingEnabled) {
            Debug.Control.Daytimer.ListView && console.log(this.name, "setEditMode: " + editingEnabled);
            this.isEditMode = editingEnabled;

            if (editingEnabled) {
                this.entriesCopy = cloneObjectDeep(this.modeEntries);
            }

            this.tableView.setEditingModeForSection(0, editingEnabled, true, false);
            this.tableView.reload(); // reload is needed, selectable or not changes
        }

        removeDeletedEntries() {
            var cmd = this.control.buildEntriesCommand(this.entriesCopy);

            if (this.control.type !== DaytimerControlEnums.ControlType.DAYTIMER) {
                // Wrike 48797388 - use uuidAction from Parent for IRC Daytimers!
                this.sendCommand(cmd, null, this.control.uuidParent);
            } else {
                this.sendCommand(cmd);
            }
        }

        selectMode(modeNr) {
            Debug.Control.Daytimer.ListView && console.log(this.name, "selectMode " + modeNr);

            this._showMode(modeNr);
        }

        // TableViewDataSource methods
        styleForTableView() {
            return TableViewStyle.GROUPED;
        }

        numberOfSections() {
            return 1;
        }

        numberOfRowsInSection() {
            var entries = this._getEntries(),
                rows = 0;

            if (entries && entries.hasOwnProperty(this.shownModeNr)) {
                rows = entries[this.shownModeNr].length;
            }

            return rows;
        }

        cellTypeForCellAtIndex() {
            return GUI.TableViewV2.CellType.GENERAL;
        }

        contentForCell(cell, section, row) {
            var data = this._prepareData(this._getEntries()[this.shownModeNr][row]),
                result = {
                    title: null,
                    titleColor: null,
                    subtitle: data.timeRange,
                    activationRequired: data.needsActivation,
                    disclosureIcon: !this.isEditMode
                };

            switch (this.control.type) {
                case DaytimerControlEnums.ControlType.DAYTIMER:
                    if (this.control.details.analog) {
                        result.title = data.value;
                    } else {
                        result.title = _('active');
                        result.titleColor = window.Styles.colors.stateActive;
                    }

                    if (data.needsActivation) {
                        result.subtitle += SEPARATOR_SYMBOL + _("controls.daytimer.activation-required");
                    }

                    break;

                case DaytimerControlEnums.ControlType.IRC_DAYTIMER:
                    result.title = this.parentControl.getNameOfIRCTempMode(data.tempMode, true);
                    result.leftIconSrc = this.parentControl.getIconOfIRCTempMode(data.tempMode);
                    result.leftIconColor = this.parentControl.getColorOfIRCTempMode(data.tempMode);
                    break;

                case DaytimerControlEnums.ControlType.IRCV2_DAYTIMER:
                    result.title = this.parentControl.getNameOfIRCTempMode(data.tempMode, true);
                    result.titleColor = this.parentControl.getColorOfIRCTempMode(data.tempMode);
                    result.leftIconSrc = Icon.IRCV2.REASON.CALENDAR;
                    result.leftIconColor = result.titleColor;

                    if (data.needsActivation) {
                        result.subtitle += SEPARATOR_SYMBOL + _("controls.ircv2021.activate-when-presence-detected");
                    }

                    break;

                case DaytimerControlEnums.ControlType.POOL_DAYTIMER:
                    result.title = this.parentControl.getNameOfPoolCycleMode(data.cycleMode);
                    result.titleColor = this.parentControl.getColorOfPoolCycleMode(data.cycleMode);
                    result.leftIconSrc = this.parentControl.getIconPathOfPoolCycleMode(data.cycleMode);
                    result.leftIconColor = this.parentControl.getColorOfPoolCycleMode(data.cycleMode);
                    break;
            }

            return result;
        }

        // TableViewDelegate methods
        didSelectCell(cell, section, row) {
            Debug.Control.Daytimer.ListView && console.log(this.name, "didSelectCell");

            if (section === 0) {
                var entryToEdit = this._getEntries()[this.shownModeNr][row];

                this.ViewController.showState(DaytimerControlEnums.ScreenState.ENTRY_DETAIL, null, {
                    control: this.control,
                    entry: entryToEdit,
                    isEditMode: true
                });
            }
        }

        didRemoveCell(section, row) {
            this.entriesCopy[this.shownModeNr].splice(row, 1);
        }

        // Private functions
        _navigateToMode(direction) {
            var currModeIdx = this.modeNumbers.indexOf(this.shownModeNr.toString()),
                nextModeIdx = currModeIdx + direction;

            if (nextModeIdx >= 0 && nextModeIdx < this.modeNumbers.length) {
                this._showMode(this.modeNumbers[nextModeIdx]);
            }
        }

        _showMode(modeNr) {
            var modeName = SandboxComponent.getStructureManager().getOperatingModes(modeNr); // before parse int (otherwise modeName = operatingModeList)

            Debug.Control.Daytimer.ListView && console.log(this.name, "_showMode: " + modeNr + " - " + modeName);
            modeNr = parseInt(modeNr) || 0;
            this.elements.modeNameLabel.text(modeName);
            this.shownModeNr = modeNr;

            var entries = this._getEntries();

            if (entries && entries[this.shownModeNr]) {
                this.tableView.setEditingModeForSection(0, this.isEditMode, true, false);
            } // update navigation buttons


            this.selectable && this.previousModeButton.setEnabled(modeNr !== this.firstModeNr);
            this.selectable && this.nextModeButton.setEnabled(modeNr !== this.lastModeNr);
            this.tableView.reload();
            this.tableView.reload(); // second reload is needed to fix BG-I29781, otherwise it will only update when re-entered it's the right panel in HD view
        }

        /**
         * Returns either the mode entries or the mode entries copy if the edit mode is active.
         * @returns {*}
         * @private
         */
        _getEntries() {
            return this.isEditMode ? this.entriesCopy : this.modeEntries;
        }

        _prepareData(entry) {
            var from = LxDate.minutesToDaytime(entry.from, TIME_FORMAT),
                to = LxDate.minutesToDaytime(entry.to, TIME_FORMAT),
                diff = entry.to - entry.from;
            var duration = LxDate.formatSeconds(diff * 60);
            var result = {
                timeRange: from + ' - ' + to,
                duration: duration,
                needsActivation: entry.needActivate === 1
            };

            if (this.control.type === DaytimerControlEnums.ControlType.DAYTIMER) {
                result.value = lxFormat(this.control.details.format, entry.value);
            } else if (this.control.type === DaytimerControlEnums.ControlType.IRC_DAYTIMER) {
                result.tempMode = entry.tempMode;
                result.targetTemp = lxFormat(this.control.details.format, entry.targetTemp);
            } else if (this.control.type === DaytimerControlEnums.ControlType.IRCV2_DAYTIMER) {
                result.tempMode = entry.tempMode;
                result.tempModeName = entry.targetTemp;
            } else if (this.control.type === DaytimerControlEnums.ControlType.POOL_DAYTIMER) {
                result.cycleMode = entry.cycleMode;
            }

            return result;
        }

    };
});
