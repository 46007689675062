import {
    LxReactTextView,
    navigatorConfig,
    LxReactPressable,
    LxReactImageView
} from "LxComponents";
import React, { useState, useRef } from "react";
import {ScrollView, View} from "react-native";
import globalStyles from "GlobalStyles";
import { useRequest } from "ahooks";
import Icons from "IconLib";

export default function ControlSettingsEditIconScreenComp({navigation, route}) {
    const setting = route.params.setting;
    const currentValue = route.params.value;
    const viewCtrl = route.params.viewCtrl;
    const onSelectedValue = route.params.onSelectedValue;

    // for some reason the state isn't reliable when filtered. using a ref instead.
    const [selectedValue, setSelectedValue] = useState(route.params.value || setting.value);
    const lastSelectedValue = useRef(null);

    const [query, setQuery] = useState("")

    const handleBack = () => {
        if (lastSelectedValue.current !== null) {
            viewCtrl.hasPermissionsGranted().done(() => {
                // verify now, to update the prev. view according to the correct value
                viewCtrl.verifyValue(setting, lastSelectedValue.current).then((newValue) => {
                    if (!newValue) {
                        developerAttention(ControlSettingsEditIconScreenComp.name + " RN1-I376 not yet fixed, verify of " + lastSelectedValue.current + " responded with " + newValue);
                    }
                    onSelectedValue({
                        setting: setting,
                        value: newValue || lastSelectedValue.current, //TODO-woessto: workaround for RN1-I376
                        changedSubSettings: route.params.changedSubSettings || {}
                    });
                    navigation.goBack();
                });
            }, () => {
                navigation.goBack();
            });
        } else if (route.params.changedSubSettings) {
            onSelectedValue({
                setting: setting,
                changedSubSettings: route.params.changedSubSettings || {}
            });
        } else {
            navigation.goBack();
        }
    }

    const topConfig = navigatorConfig({
        title: setting.name,
        onLeftAction: handleBack,
        animationType: AnimationType.MODAL
    });

    navigation.setOptions({
        ...topConfig
    });

    const styles = {
        rootCntr: {
            backgroundColor: globalStyles.colors.grey["600"],
            flexDirection: "column",
            flex: 1
        },
        scrollView: {
            flex: 1
        },
        scrollViewContent: {
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center"
        },
        iconCntr: {
            width: 48,
            height: 48,
            margin: 7,
            justifyContent: "center",
            alignItems: "center"
        },
        activeIconCntr: {
            backgroundColor: globalStyles.colors.grey["300a36"],
            borderRadius: 6
        },
        icon: {
            marginLeft: 12,
            width: 24,
            height: 24,
            margin: "auto",
            fill: globalStyles.colors.text.primary
        },
        activeIcon: {
        },
        searchInputContainer: {
              flexDirection: "row",
        },
        searchInput: {
            backgroundColor: globalStyles.colors.grey["300a36"],
            paddingLeft: 12,
            paddingRight: 12,
            borderRadius: 6,
            marginBottom: 16,
            marginLeft: 16,
            marginRight: 16,
            flex: 1
        },
        searchIcon: {
            fill: globalStyles.colors.text.secondary,
        },
        searchPlaceholder: {
            color: globalStyles.colors.text.secondary
        }

    }

    const shouldShow = (filterTags) => {
        let passFilter;
        if (!query) {
            passFilter = true;
        } else if (!filterTags) {
            passFilter = false;
        } else {
            passFilter = filterTags.indexOf(query) >= 0
        }
        return passFilter;
    }

    const renderIcon = (valueObj) => {
        let iconStyle = {...styles.icon};
        let containerStyle = {...styles.iconCntr};
        if (valueObj.id === selectedValue) {
            iconStyle = {...iconStyle, ...styles.activeIcon};
            containerStyle = { ...containerStyle, ...styles.activeIconCntr}
        }
        return <LxReactPressable key={valueObj.id} style={containerStyle} onPress={() => {
            // for some reason the state isn't reliable when filtered. using a ref instead.
            setSelectedValue(valueObj.id);
            lastSelectedValue.current = valueObj.id;
        }}>
            { valueObj.id ? <LxReactImageView source={valueObj.id} imageStyle={iconStyle} /> : <Icons.Placeholder style={iconStyle} /> }
        </LxReactPressable>
    }

    const renderIcons = () => {
        return setting.validValues.filter(valueObj => shouldShow(valueObj.filterTags)).map(renderIcon);
    }

    const textChanged = (newQuery) => {
        setQuery(newQuery.toLowerCase());
    }

    const {run} = useRequest(textChanged, {
        debounceWait: 250,
        manuel: true
    })

    return (
        <View style={styles.rootCntr}>
            <LxReactTextView
                style={styles.searchInputContainer}
                containerStyle={styles.searchInput}
                             textChanged={run}
                             placeholder={"Search"}
                             alignment={"left"}
                             placeholderTextColor={styles.searchPlaceholder.color}
                             leftIcon={Icons.Search}
                             leftIconStyle={styles.searchIcon}/>
            <ScrollView style={styles.scrollView} contentContainerStyle={styles.scrollViewContent} >
                {renderIcons()}
            </ScrollView>
        </View>
    )
}
