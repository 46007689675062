'use strict';

define("SmokeAlarmControl", ["Control", "SmokeAlarmControlEnums"], function (Control, SmokeAlarmControlEnums) {
    return class SmokeAlarmControl extends Control {
        constructor() {
            super(...arguments);
        }

        getLegacyScreens() {
            return [SmokeAlarmControlEnums.ScreenState.SERVICE_MODE]
        }

        specificCreateCmdText(cmdParts) {
            var text = null,
                cfg = {
                    name: this.name
                };

            switch (cmdParts[1]) {
                case Commands.ALARM.SMOKE_ALARM.MUTE:
                    text = _("cmdtext.alarm.mute", cfg);
                    break;

                case Commands.ALARM.SMOKE_ALARM.QUIT:
                    text = _("cmdtext.alarm.quit", cfg);
                    break;

                case Commands.ALARM.START_DRILL:
                    text = _("controls.alarm-base.drill.start");
                    break;

                default:
                    break;
            }

            return text;
        }

        getIconForAlarmState(state) {
            var icon;

            if (this.hasFireFlag(state) && this.hasWaterFlag(state)) {
                icon = Icon.SmokeAlarm.Types.FIRE_AND_WATER;
            } else if (this.hasWaterFlag(state)) {
                icon = Icon.SmokeAlarm.Types.WATER;
            } else if (this.hasFireFlag(state)) {
                switch (state) {
                    case SmokeAlarmControlEnums.AlarmCause.ARC_FAULT:
                        icon = Icon.SmokeAlarm.Types.ARC_FAULT;
                        break;

                    default:
                        icon = Icon.SmokeAlarm.Types.FIRE;
                        break;
                }
            } else {
                console.log("Unknown state for " + this.name + " - assuming fire and water alarm!");
                icon = Icon.SmokeAlarm.Types.FIRE_AND_WATER;
            }

            return icon;
        }

        /**
         * Will return true if one of the fire flags is set.
         * @param state
         * @return {boolean}
         */
        hasFireFlag(state) {
            return hasBit(state, SmokeAlarmControlEnums.AlarmCause.FIRE) || hasBit(state, SmokeAlarmControlEnums.AlarmCause.TEMPERATURE) || hasBit(state, SmokeAlarmControlEnums.AlarmCause.ARC_FAULT);
        }

        /**
         * Will return true if one the water flag is set.
         * @param state
         * @return {boolean}
         */
        hasWaterFlag(state) {
            return hasBit(state, SmokeAlarmControlEnums.AlarmCause.WATER);
        }

    };
});
