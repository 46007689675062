'use strict';

class GroupContentScreen extends GUI.TableViewScreen {
    constructor(details) {
        super($('<div class="group-content-screen">'));
        this.currentGroup = ActiveMSComponent.getStructureManager().getGroupByUUID(details.groupUUID);
        this.groups = ActiveMSComponent.getStructureManager().getGroupContentByUUID(this.currentGroup.uuid, this.currentGroup.groupType);
        this.sortByRating = PersistenceComponent.getMiniserverSettings().sortByRating;
        this.cache = {}; // beware, cache is here optional!
    }

    viewDidLoad() {
        super.viewDidLoad(...arguments); // title bar

        this.titleBar = this._initTitleBar(this.currentGroup.name, this.currentGroup.name[0]);
        /*if (Feature.EXPERT_MODE_FOR_GROUPS) {
            this.titleBar.setExpertModeInfo(null, this.currentGroup);
        }*/

        this.prependSubview(this.titleBar);

        this._reloadTable(); // the favorites, more specific their rating might change  - and therefore the order of the current list.


        this._favsChangedReg = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.FavoritesChanged, this._handleFavsChanged.bind(this));
        this._structureChangesReg = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.StructureChanged, this._checkStructureChanges.bind(this));
    }

    viewWillAppear() {
        this._checkSortByRating();

        super.viewWillAppear(...arguments);
    }

    viewDidAppear() {
        super.viewDidAppear(...arguments);

        this._checkSortByRating();

        this.titleBar.onLeftButtonTapped = function onLeftButtonTapped() {
            this.ViewController.navigateBack(false, {
                groupType: this.currentGroup.groupType
            });
            this.titleBar.onLeftButtonTapped = null;
        }.bind(this);

        this.titleBar.onRightButtonTapped = function onRightButtonTapped() {
            NavigationComp.showSearchScreen();
        }.bind(this);
    }

    viewDidDisappear() {
        this.titleBar.onLeftButtonTapped = null;
        this.titleBar.onRightButtonTapped = null;
        super.viewDidDisappear(...arguments);
    }

    getTableView() {
        return new GUI.ControlListTableView(this, this);
    }

    destroy() {
        NavigationComp.removeFromUIEvent(this._favsChangedReg);
        NavigationComp.removeFromUIEvent(this._structureChangesReg);
        this.titleBar = null;
        this.currentGroup = null;

        this._emptyCache();

        super.destroy();
    }

    updateView(details) {
        Debug.GUI.GroupContentScreen && console.log(this.name, "updateView");
        super.updateView(...arguments);
        this.currentGroup = ActiveMSComponent.getStructureManager().getGroupByUUID(details.groupUUID);
        this.groups = this.currentGroup ? ActiveMSComponent.getStructureManager().getGroupContentByUUID(this.currentGroup.uuid, this.currentGroup.groupType) : [];

        if (this.currentGroup) {
            this.titleBar.setTitleBarSideTexts(this.currentGroup.name);
            /*if (Feature.EXPERT_MODE_FOR_GROUPS) {
                this.titleBar.setExpertModeInfo(null, this.currentGroup);
            }*/
        }

        this._reloadTable(true);

        Debug.GUI.GroupContentScreen && console.log(this.name, "updateView < done");
    }

    getAnimation() {
        return HD_APP ? AnimationType.NONE : AnimationType.PUSH_OVERLAP_LEFT;
    }

    getURL() {
        return this.currentGroup.uuid;
    }

    hasHistory() {
        return false;
    }

    // Private methods
    _initTitleBar(currentGroupName, groupNameInitial) {
        var titleBarConfig = {
            leftSide: HD_APP ? 'text' : 'backButton',
            rightSide: TitleBarCfg.Button.SEARCH
        };
        var titleBar = new GUI.LxTitleBar(titleBarConfig);
        titleBar.setTitleBarSideTexts(currentGroupName, groupNameInitial);
        return titleBar;
    }

    /**
     * Called when either the sortByRating changes or the rating of the controls/groups might have changed.
     * @private
     */
    _respondToSortChange() {
        Debug.GUI.GroupContentScreen && console.log(this.name, "_respondToSortChange");
        this.groups = ActiveMSComponent.getStructureManager().getGroupContentByUUID(this.currentGroup.uuid, this.currentGroup.groupType);

        this._emptyCache();

        delete this.cache; // disable cache (otherwise cells won't update eg. the name)

        this._reloadTable().done(function () {
            this.cache = {}; // enable cache again
        }.bind(this));
    }

    /**
     * called whenever the favorites or their rating changes.
     * @private
     */
    _handleFavsChanged() {
        Debug.GUI.GroupContentScreen && console.log(this.name, "_handleFavsChanged");

        if (this.isVisible()) {
            this._respondToSortChange();
        } else {
            // ensures that on viewWillAppear the _respondToSortChange-Fn will be called.
            this.sortByRating = -1337;
        }
    }

    _checkStructureChanges(event, changes) {
        if (changes.controls) {
            this._handleFavsChanged();
        }

        if (this._checkForGroupUpdate(changes)) {
            // update title
            this.titleBar.setTitleBarSideTexts(this.currentGroup.name);
        }
    }

    /**
     * checks if the currentGroup is part of the changes
     * @param changes
     * @returns boolean if the currentGroup was updated
     * @private
     */
    _checkForGroupUpdate(changes) {
        if (this.currentGroup.groupType === GroupTypes.CATEGORY && changes.cats && changes.cats[this.currentGroup.uuid]) {
            this.currentGroup = changes.cats[this.currentGroup.uuid];
            return true;
        } else if (this.currentGroup.groupType === GroupTypes.ROOM && changes.rooms && changes.rooms[this.currentGroup.uuid]) {
            this.currentGroup = changes.rooms[this.currentGroup.uuid];
            return true;
        }

        return false;
    }

    // TableViewDataSource methods
    styleForTableView() {
        return TableViewStyle.TRANSLUCENT;
    }

    numberOfSections() {
        return this.groups.length;
    }

    groupForHeaderInSection(section) {
        return this.groups[section];
    }

    numberOfRowsInSection(section) {
        return this.groups[section].controls.length;
    }

    controlForCellAtIndex(section, row) {
        return this.groups[section].controls[row];
    }

    cellTypeForCellAtIndex(section, row) {
        var control = this.groups[section].controls[row];
        return control.getCellType();
    }

    contentForCell(cell, section, row) {
        return {
            control: this.groups[section].controls[row],
            displayAsCell: true
        };
    }

    // Factory
    dequeueReusableCell(reuseID) {
        var cachedInstance = this.cache && this.cache[reuseID];

        if (cachedInstance) {
            delete this.cache[reuseID];
            return cachedInstance;
        }
    }

    storeReusableCell(reuseID, instance) {
        if (this.cache) {
            this.cache[reuseID] = instance;
            return true;
        }

        return false;
    }

    // TableViewDelegate methods
    didSelectHeader(section) {
        Debug.GUI.GroupContentScreen && console.log(this.name, "didSelectHeader " + section);
        var group = this.groups[section],
            groupUUID = group.uuid,
            groupType = group.groupType;

        if (HD_APP) {
            this.emit("on-group-header-selected", groupUUID, groupType);
            this.updateView({
                groupUUID: groupUUID
            });
        } else {
            this.ViewController.showState(ScreenState.GroupContent, null, {
                groupUUID: groupUUID
            });
        }
    }

    _reloadTable(ignoreScrollPosition) {
        Debug.GUI.GroupContentScreen && console.log(this.name, "_reloadTable");

        if (ignoreScrollPosition) {
            this.tableView.resetScrollTop();
        }

        return this.tableView.reload().then(function () {
            !ignoreScrollPosition && this.tableView.restoreScrollTop();
        }.bind(this));
    }

    _emptyCache() {
        // destroy instances in cache
        if (!this.cache) {
            return;
        }

        var cacheKeys = Object.keys(this.cache),
            cacheKey;

        while (cacheKeys.length) {
            cacheKey = cacheKeys.shift();

            try {
                this.cache[cacheKey].destroy();
            } catch (e) {
                console.error(e.stack);
            } finally {
                delete this.cache[cacheKey];
            }
        }
    }

    /**
     * checks if the sortByRating did change, reloads table if so
     * @private
     */
    _checkSortByRating() {
        var currentSortByRating = PersistenceComponent.getMiniserverSettings().sortByRating;

        if (this.sortByRating !== currentSortByRating) {
            this.sortByRating = currentSortByRating;

            this._respondToSortChange();
        }
    }

}

GUI.GroupContentScreen = GroupContentScreen;
