import PropTypes from "prop-types";
import {
    LxReactWaitingIndicator,
    LxReactText,
    LxReactButton,
    ButtonType
} from "LxComponents";
import globalStyles from "GlobalStyles";
import { View } from "react-native";

export default function LxReactWaitingView(props) {

    const renderTitle = () => {
        if (props.title) {
            return <View key={"titleContainer"} style={styles.titleContainer}><LxReactText style={styles.title}>{props.title}</LxReactText></View>;
        }
        return null;
    }

    const renderMessage = () => {
        var msgs;
        if (props.message) {
            msgs = Array.isArray(props.message) ? props.message : [props.message];
            return <LxReactText key={"messageContainer"} style={styles.messageContainer}>
                { msgs.map((msg, idx) => <LxReactText key={`msg${idx}`} style={styles.message}>{msg}</LxReactText>)}
            </LxReactText>
        }
    }

    const renderButton = () => {
        if (props.buttonText && props.action) {
            return <View style={styles.buttonContainer}>
                <LxReactButton
                    buttonType={ButtonType.SECONDARY}
                    text={props.buttonText}
                    onPress={props.action}
                    pkey={"bottomButton"}
                />
            </View>
        }
        return null;
    }


    return <View style={{...styles.container, ...props.style}}>
                <View style={styles.topGap}/>
                <View style={styles.mainContainer}>
                    <LxReactWaitingIndicator key={"indicator"}/>
                    {renderTitle()}
                    {renderMessage()}
                </View>
                <View style={styles.bottomContainer}>
                    {renderButton()}
                </View>
            </View>;
}

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        height: "100%"
    },
    topGap: {
        alignSelf: "stretch",
        flex: 1
    },
    mainContainer: {
        alignSelf: "center",
        marginLeft: globalStyles.sizes.small,
        marginRight: globalStyles.sizes.small
    },
    bottomContainer: {
        alignSelf: "stretch",
        flex: 1,

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: globalStyles.sizes.small,
        marginRight: globalStyles.sizes.small
    },
    indicator: {

    },
    titleContainer: {
        marginTop: globalStyles.sizes.small,
        marginBottom: globalStyles.sizes.small,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    title: {
        color: globalStyles.colors.text.primary,
        fontSize: globalStyles.fontSettings.sizes.medium,
        lineHeight: globalStyles.sizes.medium,
        textAlign: "center"
    },
    messageContainer: {
        flexDirection: "column",
        display: "flex",
        justifyContent: "start",
        minHeight: 64
    },
    message: {
        color: globalStyles.colors.text.secondary,
        fontSize: globalStyles.fontSettings.sizes.small,
        textAlign: "center"
    },
    buttonContainer: {
        height: "100%",
        display: "flex",
        alignItems: "center"
    },
    buttonTitle: {
        color: globalStyles.colors.text.secondary,
        fontSize: globalStyles.fontSettings.sizes.small,
        textAlign: "center"
    }
}

LxReactWaitingView.propTypes = {
    title: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    buttonText: PropTypes.string,
    action: PropTypes.func
}
