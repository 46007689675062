import PropTypes from "prop-types";
import { useControl, LxReactText, LxReactImageView, LxReactPressable, LxReactContextMenuHandler } from "LxComponents"
import {View} from "react-native";
import {useRef} from "react";
import globalStyles from "GlobalStyles"
import {useNavigation} from "@react-navigation/native";
import Icons from "IconLib";

export default function EmLoadDisplay({controlUuid, loadObj}) {
    const navigation = useNavigation();
    const styles = {
        loadCntr: {
            flexDirection: "row",
            paddingTop: globalStyles.sizes.smallest,
            paddingBottom: globalStyles.sizes.smallest,
        },
        loadTextCntr: {
            marginLeft: globalStyles.sizes.smallest,
            marginRight: globalStyles.sizes.smallest,
            flexDirection: "column",
            justifyContent: "center",
            flex: 1
        },
        loadState: {
            ...globalStyles.textStyles.body.bold,
            color: globalStyles.colors.text.primary
        },
        loadName: {
            ...globalStyles.textStyles.body.default,
            color: globalStyles.colors.text.secondary
        },
        iconCntr: {
            width: 70,
            height: 70,
            alignItems: "center",
            justifyContent: "center",
            borderColor: globalStyles.colors.grey["300a36"],
            borderWidth: 3,
            borderRadius: "50%",
            borderStyle: "solid",
            marginTop: globalStyles.sizes.smallest,
            marginBottom: globalStyles.sizes.smallest,
            marginRight: globalStyles.sizes.smallest,
        },
        iconCntrActive: {
            borderColor: globalStyles.colors.orange,
        },
        icon: {
            justifyContent: "center",
            width: globalStyles.sizes.icons.regular,
            height: globalStyles.sizes.icons.regular,
            fill: globalStyles.colors.white
        },
        iconActive: {
        //fill: globalStyles.colors.orange
        },
        manualCtrlCntr: {
            marginRight: globalStyles.sizes.small,
            justifyContent: "center",
            alignItems: "center"
        },
        manualCtrlIcon: {
            width: 22,
            height: 22,
            fill: globalStyles.colors.text.primary
        },
        manualCtrlIconBg: {
            padding: 9,
            borderRadius: 3,
            width: 40,
            height: 40,
            justifyContent: "center",
            alignItems: "center",
            background: globalStyles.colors.fill.tertiary
        },
        
        manualIconCntr: {
            justifyContent: "center",
            alignContent: "center"
        },
        manualIconPressable: {
            height: "100%",
            paddingRight: globalStyles.sizes.smaller,
            paddingLeft: globalStyles.sizes.smaller,
            justifyContent: "center",
            alignContent: "center"
        },
        manualIcon: {
            width: globalStyles.sizes.icons.smaller,
            height: globalStyles.sizes.icons.smaller,
            stroke: globalStyles.colors.text.secondary
        }
    }
    const control = useControl(controlUuid);

    const _manualOverride = () => {
        return !!loadObj.activatedManually || !!loadObj.deactivatedManually;
    }

    const _iconStyle = () => {
        let style = {...styles.icon};
        if (loadObj.active) {
            style = { ...style, ...styles.iconActive };
        }
        return style;
    }

    const _iconCtnrStyle = () => {
        let style = {...styles.iconCntr};
        if (loadObj.active) {
            style = { ...style, ...styles.iconCntrActive };
        }
        return style;
    }

    const _renderIcon = () => {
        if (loadObj.icon) {
            return <LxReactImageView containerStyle={_iconStyle()} source={loadObj.icon} />
        }
        return <Icons.Flash style={_iconStyle()} />;
    }

    const handleOpenSettings = () => {
        navigation.navigate(ScreenState.ControlSettingsController, {
            settingsUtil: control.getSettingsUtilForLoad(loadObj),
            permission: MsPermission.EXPERT_MODE_LIGHT,
            animationType: AnimationType.MODAL
        });
    }

    const buttonRef = useRef(null);
    const showManualActionOptions = () => {
        let contextMenuOptions = [];

        if (Feature.EM2_RESET_TO_AUTO) {
            contextMenuOptions.push({
                title: _("energymanager2.automatic"),
                action: () => {
                    control && control.resetLoadToAuto(loadObj.uuid);
                }
            });
        }

        contextMenuOptions.push({
            title: _("energymanager2.off-until-midnight"),
            action: () => {
                _setLoadActive(false);
            }
        });
        contextMenuOptions.push({
            title: _("energymanager2.on-until-midnight"),
            action: () => {
                _setLoadActive(true);
            }
        });

        LxReactContextMenuHandler.shared.showContextMenu(contextMenuOptions, "", buttonRef.current, GUI.LxContextMenuV2);
    }

    const hasPermission = ActiveMSComponent.isAdminUser();

    const renderLoad = () => {
        if (loadObj) {
            return <LxReactPressable onPress={handleOpenSettings} disabled={!hasPermission}><View style={styles.loadCntr} key={loadObj.uuid}>
                <View style={_iconCtnrStyle()} >
                    {_renderIcon()}
                </View>
                <View style={styles.loadTextCntr}>
                    <LxReactText style={styles.loadState} key={"value"}>
                        {_getLoadStateValue(loadObj)}
                    </LxReactText>
                    <LxReactText style={styles.loadName} key={"name"}>
                        {loadObj.name}
                    </LxReactText>
                    <LxReactText style={styles.loadName} key={"autostate"}>
                        {_manualOverride() ? (loadObj.active ? _("energymanager2.on-until-midnight") : _("energymanager2.off-until-midnight")) : _("energymanager2.automatic")}
                    </LxReactText>
                </View>
                <View style={styles.manualCtrlCntr}>
                    <LxReactPressable pressableStyle={styles.manualCtrlIconBg} onPress={showManualActionOptions} ref={buttonRef}>
                            <Icons.Timer style={{...styles.manualCtrlIcon, ...(_manualOverride() ? {fill: globalStyles.colors.stateActive } : {})}} />
                    </LxReactPressable>
                </View>
            </View>
            </LxReactPressable>
        } else {

        }
    }

    const _getLoadStateValue = () => {
        if (loadObj.active) {
            return lxUnitConverter.convertAndApply(control.actualFormat, loadObj.pwr);
        } else {
            return _("off");
        }
    }

    const _setLoadActive = (active) => {
        if (control) {
            control.setManualLoadEnabled(loadObj.uuid, active);
        } else {
            console.warn(EmLoadDisplay.name, "cannot change load activity, control not ready!");
        }
    }

    return renderLoad();
}

EmLoadDisplay.propTypes = {
    controlUuid: PropTypes.string.isRequired,
    loadObj: PropTypes.object.isRequired
}
