'use strict';

define(["ControlActionCellsScreen", "LightV2ControlEnums"], function (ControlActionCellsScreen, LightV2ControlEnums) {
    return class LightV2ControlContent extends ControlActionCellsScreen {
        constructor() {
            super(...arguments);
        }

        receivedStates(states) {
            super.receivedStates(...arguments); // We only have a color if the LightControl is on
            // Set white as the fill color to have a better contrast
            this._checkDetailsButton();
            if (!states.stateColorForContent) {
                this.setStateIconFill(Color.WHITE);
            }
        }

        shouldShowDetailsButton() {
            return !this.control.isGrouped() && !this.control.hasSingleSwitch() && !this.control.getStates().universalIsLocked;
        }

        handleDetailsBtn() {
            this.ViewController.showState(LightV2ControlEnums.ScreenState.CONTROL_CONTENT_OLD);
        }

        _createCellObjectForCmd(cmdObj) {
            if (!this.control.hasSingleSwitch()) {
                var cellObj,
                    icon,
                    states = this.control.getStates(),
                    mood = cmdObj.details.mood,
                    selected = states.activeMoods.indexOf(mood.id) !== -1,
                    isMulti = states.activeMoods.length > 1 && selected,
                    additiveCommand = isMulti ? Commands.LIGHTCONTROL_V2.MOOD.REMOVE : Commands.LIGHTCONTROL_V2.MOOD.ADD;
                cellObj = {
                    type: mood.isAccumulative ? GUI.TableViewV2.CellType.BUTTON : null,
                    content: {
                        title: mood.name,
                        active: selected,
                        buttonSrc: isMulti ? Icon.REMOVE : Icon.Light.ComfortMode.PLUS,
                        moodId: mood.id,
                        clickable: true,
                        enabled: true,
                        selectable: false // Set to false to prevent a ghosting effect when recording a command (QuickAction, NFC, ...)

                    },
                    action: function () {
                        this.control.getCommand(Commands.format(Commands.LIGHTCONTROL_V2.MOOD.CHANGE_TOO, mood.id)).call(this.control); // make sure to not call with wrong attributes!
                    }.bind(this),
                    buttonTapped: function (section, row, tableView) {
                        var cellContent = tableView.table[section][row].content;
                        this.control.getCommand(Commands.format(additiveCommand, cellContent.moodId)).call(this.control); // make sure to not call with wrong attributes!
                    }.bind(this)
                };
                icon = this.control.getMoodIcon(mood);

                if (icon) {
                    var color = Color.CONTENT_INFO_BACKGROUND;

                    if (cellObj.type === GUI.TableViewV2.CellType.BUTTON) {
                        cellObj.content.stateIconSrc = icon;
                        cellObj.content.stateIconColor = color;
                    } else {
                        // generalcell
                        cellObj.content.rightIconSrc = icon;
                        cellObj.content.rightIconColor = color;
                        cellObj.content.rightIconSize = GUI.TableViewV2.Enums.GeneralCell.IconSize.MEDIUM;
                    }
                }

                return cellObj;
            } else {
                return super._createCellObjectForCmd(...arguments);
            }
        }

    };
});
