'use strict';
/*
 rightIconSrc
 title
 subtitle       string or array of strings (each item will be in a row)
 active         boolean
 activeColor

 onSwitchChanged(value, section, row, tableView)
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.SWITCH_SUBTITLE = "SwitchSubtitleCell";

    class SwitchSubtitleCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(content) {
                return $('<div class="sub-cell-container">' + '   <div class="sub-cell-container__first-line">' + '       <div class="first-line__title">' + '           <div class="title__text">' + '               ' + content.title + '           </div>' + '       </div>' + '       <div class="first-line__switch" />' + '   </div>' + '   <div class="sub-cell-container__second-line">' + '       <div class="second-line__text">' + '       ' + (typeof content.subtitle === "string" ? content.subtitle : content.subtitle.join('</div><div class="second-line__text">')) + '       </div>' + '       <div class="second-line__right-image">' + '           ' + (content.rightIconSrc ? ImageBox.getResourceImage(content.rightIconSrc) : "") + '       </div>' + '   </div>' + '</div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("switch-subtitle-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.contentPlaceholder.append(SwitchSubtitleCell.Template.getTemplate(this.content));
            this.switch = new GUI.LxSwitch(this, this.contentPlaceholder.find(".first-line__switch")[0], this.content.activeColor || window.Styles.colors.green);
            this.setActive(this.content.active);
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);
            this.switch.onStateChanged = this._onSwitchChanged.bind(this);
        }

        viewWillDisappear() {
            this.switch.onStateChanged = null;
            super.viewWillDisappear(...arguments);
        }

        setContent(content) {
            content.subtitle = content.subtitle || "";
            return super.setContent(...arguments);
        }

        destroy() {
            this.switch.destroy();
            this.switch = null;
            super.destroy();
        }

        clickableAllowed() {
            return true;
        }

        // Public Methods
        setActive(a) {
            if (this.switch) {
                this.switch.setActive(a);
            }
        }

        // Private Methods
        _onSwitchChanged(a) {
            this.delegate.onSwitchChanged.call(this.delegate, a, this.sectionIdx, this.rowIdx, this.tableView);
        }

    }

    GUI.TableViewV2.Cells.SwitchSubtitleCell = SwitchSubtitleCell;
    return GUI;
}(window.GUI || {});
