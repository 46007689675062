'use strict';

define([], function () {
    return function IndicatorView(data, time, ui, ctx, Helper, chartType, frequency, noEntryDefine) {
        var indicatorSize = 10,
            cDP0,
            cDP1,
            dpValDiff = 0,
            valPerUnit = 0,
            middleOffsetFromCDP = 0;
        /**
         * returns the correct value of the indicator according to chartType and frequency
         * returns NaN if no data is available
         * @returns {Number|NaN}
         */

        var getCenterValue = function getCenterValue() {
            if (frequency === Statistic.Frequency.EVERY_CHANGE || chartType === Statistic.Type.BAR_CHART) {
                // digital chart is also every change
                // bar chart is handled like every change
                cDP0 = data.source[data.source.middleIndex]; // The bar chart needs special handling if there is a missing entry in the range sequence

                if (chartType === Statistic.Type.BAR_CHART) {
                    return getCenterValueOfBarChart();
                } else if (cDP0.ts > time.middle) {
                    cDP0 = data.source[data.source.middleIndex - 1];
                }

                return cDP0 ? cDP0.value : NaN;
            } else {
                // calc an interpolated value
                if (data.source[data.source.middleIndex].ts === time.middle) {
                    return data.source[data.source.middleIndex].value;
                } else if (data.source[data.source.middleIndex].ts < time.middle) {
                    cDP0 = data.source[data.source.middleIndex];
                    cDP1 = data.source[data.source.middleIndex + 1];
                } else {
                    cDP0 = data.source[data.source.middleIndex - 1];
                    cDP1 = data.source[data.source.middleIndex];
                }

                if (!cDP0 || !cDP1) return NaN; // Handle the "noEntryDefine", ignore the value and display the min value (no line visible)
                // The "noEntryDefine" is used for the Touch & Grill if no sensor is connected

                if (cDP0.isNoDataEntry || cDP1.isNoDataEntry) return noEntryDefine;
                dpValDiff = cDP1.value - cDP0.value; // calc the value difference between the 2 points
                // calc with timestamps causes that peaks aren't calculated right!

                /*valPerUnit = dpValDiff / (cDP1.ts - cDP0.ts); // calc the value per time unit between the points
                 middleOffsetFromCDP = time.middle - cDP0.ts; // calc the offset from cDP0 to middle timestamp*/
                // instead calc with pixel!

                valPerUnit = dpValDiff / (Helper.getXForTimestamp(cDP1.ts) - Helper.getXForTimestamp(cDP0.ts)); // calc the value per time unit between the points

                middleOffsetFromCDP = Helper.getXForTimestamp(time.middle) - Helper.getXForTimestamp(cDP0.ts); // calc the offset from ts1 to middle timestamp

                return cDP0.value + valPerUnit * middleOffsetFromCDP; // calc the final value for middle timestamp
            }
        };
        /**
         * Calculating the center value the indicator should display
         * @note Account for the data range, or we see ghosting of the previous value if there is a gap between two ranges
         * @returns {number}
         */


        var getCenterValueOfBarChart = function getCenterValueOfBarChart() {
            var offset = 0,
                cdpMoment,
                cdpMomentEnd;

            if (cDP0.ts > time.middle) {
                offset -= 1;
            }

            cDP1 = data.source[data.source.middleIndex + offset];
            cdpMoment = moment.utc(cDP1.ts * 1000);
            cdpMomentEnd = cdpMoment.clone().add(1, data.range);

            if (cdpMomentEnd.unix() >= time.middle) {
                return cDP1 ? cDP1.value : NaN;
            } else {
                // Return the minimal value if there is a gap in the bar chart
                return 0;
            }
        };

        var centerVal,
            indicatorX = 0,
            indicatorY = 0;

        var draw = function drawIndicator() {
            Debug.Statistic.DrawTimes && console.time("drawIndicator");
            ctx.save();
            centerVal = getCenterValue();

            if (isNaN(centerVal) || centerVal === noEntryDefine) {
                return centerVal;
            }

            indicatorX = ui.canvasWidth / 2;
            indicatorY = Helper.getYForValue(centerVal); // circle

            ctx.beginPath();
            ctx.fillStyle = Color.ICON_A;
            ctx.arc(indicatorX, indicatorY, indicatorSize, 0, Math.PI * 2, true);
            ctx.closePath();
            ctx.fill(); // donut

            ctx.beginPath();
            ctx.strokeStyle = Color.ICON_B;
            ctx.lineWidth = indicatorSize / 3;
            ctx.arc(indicatorX, indicatorY, indicatorSize * 0.4, 0, Math.PI * 2, true);
            ctx.closePath();
            ctx.stroke(); // line

            ctx.beginPath();
            ctx.strokeStyle = Color.ICON_A;
            ctx.lineWidth = 1.5;
            ctx.moveTo(indicatorX, 0);
            ctx.lineTo(indicatorX, indicatorY - indicatorSize - 2);
            ctx.closePath();
            ctx.stroke();
            ctx.restore();
            Debug.Statistic.DrawTimes && console.timeEnd("drawIndicator");
            return centerVal;
        };

        return {
            draw: draw
        };
    };
});
