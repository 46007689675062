'use strict';
/*
 title
 title2
 subtitle
 icon            an icon element (img, svg)
 iconSrc         src of the icon
 iconColor,
 iconClass,
 activeIconColor,
 textColor,
 activeTextColor,
 msIcon          whether or not to load the image from the Miniserver or the resourceCache
 displayAsCell
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.CARD = "CardsCell";

    class CardsCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getCellContent = function getCellContent(content, iconBg) {
                return $('<div class="content__container">' + '<div class="container__wrapper">' + '   <div class="wrapper__icon-placeholder">' + '        ' + ImageBox.getResourceImageWithClasses(iconBg, "icon-placeholder__icon") + '   </div>' + '   <div class="wrapper__texts">' + '        <div class="texts__title text-overflow-ellipsis" />' + '        <div class="texts__title-2 text-overflow-ellipsis" />' + '       <div class="texts__subtitle text-overflow-ellipsis" />' + '   </div>' + '</div>' + '</div>');
            };

            var getSmallIcon = function getSmallIcon(iconSrc) {
                var isIconElement = iconSrc.startsWith("<svg"),
                    isMsIcon = !isIconElement && !iconSrc.startsWith("resources/"),
                    el;

                if (isMsIcon) {
                    console.error("not supported!");
                } else if (isIconElement) {
                    el = ImageBox.addClassesToSVG(iconSrc, "small-icon__icon");
                } else {
                    el = ImageBox.getResourceImageWithClasses(iconSrc, "small-icon__icon");
                }

                return $(el);
            };

            var getCellTitle = function getCellTitle(iconSrc) {
                return $('<div class="cell__title text-overflow-ellipsis" />');
            };

            return {
                getCellContent: getCellContent,
                getSmallIcon: getSmallIcon,
                getCellTitle: getCellTitle
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.enabled = true;
            this._setIconNr = 0; // Important to avoid overwriting state-icons by default icons when default images have to be downloaded first
        }

        setContent(content) {
            if (this.displayAsCell()) {
                content.displayAsCell = true;
            }

            if (content.displayAsCell) {
                this.element.addClass("cards-cell--as-cell");
            }

            if (content.showGroupDetails) {
                this.element.addClass("cards-cell--with-group-details");
            }

            super.setContent(...arguments);
        }

        viewDidLoad() {
            super.viewDidLoad();

            if (!this.content.iconClass) {
                this.content.iconClass = ""; // otherwise "undefined" would be added as a class
            }

            this.contentPlaceholder.append(CardsCell.Template.getCellContent(this.content, this._getBgIcon()));
            this.elements.contentContainer = this.element.find('.content__container');
            this.elements.iconCntr = this.element.find('.wrapper__icon-placeholder');
            this.elements.titleIconCntr = this.element.find('.wrapper__icon-placeholder .icon__big-icon');
            this.elements.smallIconCntr = this.element.find('.wrapper__icon-placeholder .icon__small-icon');
            this.elements.title = this.element.find(".wrapper__texts .texts__title");
            this.elements.title2 = this.element.find(".wrapper__texts .texts__title-2");
            this.elements.subtitle = this.element.find(".wrapper__texts .texts__subtitle");

            if (this.content.displayAsCell && this.content.showGroupDetails) {
                this.element.addClass("cards-cell--with-title");
                this.contentPlaceholder.prepend(CardsCell.Template.getCellTitle());
                this.elements.title = this.element.find(".cell__title");
            }

            this.setTitle(this.content.title);
            this.setTitle2(this.content.title2);
            this.setSubtitle(this.content.subtitle);

            if (this.content.iconSrc) {
                this.setIcon(this.content.iconSrc);
            } else {
                this._setColor();
            }
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments);

            if (this.content.displayAsCell) {
                this.element.parent().parent().addClass("section--as-rows");
            }
        }

        clickableAllowed() {
            return this.enabled;
        }

        rippleAllowed() {
            return this.enabled;
        }

        setEnabled(enabled) {
            this.enabled = enabled;
            super.setEnabled(enabled);
        }

        setActive(active) {
            this._setColor(active);
        }

        /**
         * checks content (either from param or from "this) or device size if it should be displayed as cell
         * @param content
         * @returns {boolean}
         */
        displayAsCell(content) {
            // TODO implement properly!
            content = this.content || content;
            return content && content.displayAsCell || PlatformComponent.shouldDisplayCardsAsCell();
        }

        /**
         * updates the icon
         * @param iconSrc
         */
        setIcon(iconSrc) {
            var currSetIconNr = ++this._setIconNr;
            this.content.iconSrc = iconSrc;

            if (this.elements.titleIcon) {
                GUI.animationHandler.remove(this.elements.titleIcon);
                delete this.elements.titleIcon;
            }

            if (!iconSrc) {
                return;
            }

            var isIconElement = iconSrc.startsWith("<svg"),
                isHttpIcon = iconSrc.startsWith("http"),
                isMsIcon = !isHttpIcon && !isIconElement && !iconSrc.startsWith("resources/");

            if (isMsIcon) {
                this._setMsIcon(currSetIconNr);

                return;
            } else if (isIconElement) {
                this.elements.titleIcon = $(ImageBox.addClassesToSVG(iconSrc, this.content.iconClass));
            } else {
                this._setSvgImage(iconSrc);
            }

            GUI.animationHandler.append(this.elements.titleIcon, this.elements.titleIconCntr);

            this._setColor();
        }

        _setMsIcon(currSetIconNr) {
            ImageBox.getImage(this.content.iconSrc).then(function (image) {
                if (this._setIconNr !== currSetIconNr) {
                    // Icon already changed, bail out.
                    return;
                } // remove old, to avoid 2 icons (could happen if the icon will be updated before the last icon was fully loaded)


                if (this.elements.titleIcon) {
                    GUI.animationHandler.remove(this.elements.titleIcon);
                }

                if (image.mimeType === "svg") {
                    this.elements.titleIcon = $(ImageBox.addClassesToSVG(image.image, this.content.iconClass));
                } else {
                    this._setSvgImage(image.image);
                }

                this._setColor();

                GUI.animationHandler.append(this.elements.titleIcon, this.elements.titleIconCntr);
            }.bind(this));
        }

        setTextColor(textColor, activeTextColor) {
            this.content.textColor = textColor;
            this.content.activeTextColor = activeTextColor;

            this._setColor();
        }

        setIconColor(iconColor, activeIconColor) {
            this.content.iconColor = iconColor;
            this.content.activeIconColor = activeIconColor;

            this._setColor();
        }

        /**
         * Takes an hex, rgb or rgba color, converts it into an rgba color with an alpha chanel of 0.4
         * @param color
         * @private
         */
        setTintColor(color) {
            GUI.animationHandler.setCssAttr(this.element, "background-color", color ? applyAlphaChannelToColorString(color, 0.4) : "");
        }

        /**
         * updates the small icon (in right bottom edge of "normal" icon)
         * @param icon
         */
        setSmallIcon(icon) {
            var actions = [];
            actions.push(this.elements.smallIconCntr.empty.bind(this.elements.smallIconCntr));
            actions.push(this.elements.iconCntr.toggleClass.bind(this.elements.iconCntr, "wrapper__icon-placeholder--with-small", !!icon));

            if (icon) {
                var iconEl = CardsCell.Template.getSmallIcon(icon.iconSrc);
                iconEl.css('fill', icon.color);
                actions.push(this.elements.smallIconCntr.append.bind(this.elements.smallIconCntr, iconEl));
            }

            GUI.animationHandler.scheduleAll(actions);
        }

        setTitle(title) {
            this._updateLabel(this.elements.title, title);
        }

        setTitle2(title2) {
            this._updateLabel(this.elements.title2, title2);
        }

        setSubtitle(subtitle) {
            this._updateLabel(this.elements.subtitle, subtitle);
        }

        // Private methods
        _updateLabel(lbl, text) {
            if (typeof text === "string" && text !== "") {
                GUI.animationHandler.schedule(function () {
                    lbl.text(text);
                });
            } else {
                GUI.animationHandler.schedule(function () {
                    lbl.text("");
                });
            }
        }

        _setColor(active) {
            var actions = [];

            if (active) {
                this.elements.titleIcon && actions.push(this.elements.titleIcon.css.bind(this.elements.titleIcon, "fill", this.content.activeIconColor || ""));
                this.elements.subtitle && actions.push(this.elements.subtitle.css.bind(this.elements.subtitle, "color", this.content.activeTextColor || ""));
            } else {
                this.elements.titleIcon && actions.push(this.elements.titleIcon.css.bind(this.elements.titleIcon, "fill", this.content.iconColor || ""));
                this.elements.subtitle && actions.push(this.elements.subtitle.css.bind(this.elements.subtitle, "color", this.content.textColor || ""));
            }

            GUI.animationHandler.scheduleAll(actions);
        }

        _setSvgImage(source) {
            var height = this.elements.titleIconCntr.attr("height"),
                width = this.elements.titleIconCntr.attr("width");
            this.elements.titleIcon = getIconElement(source, height, width);
        }

        /**
         * Returns the cells background icon
         * @return {string}
         * @private
         */
        _getBgIcon() {
            return this.content.displayAsCell ? Icon.STATE_ICON.CELL : Icon.STATE_ICON.TILE;
        }

    }

    GUI.TableViewV2.Cells.CardsCell = CardsCell;
    return GUI;
}(window.GUI || {});
