'use strict';

define(["AudioZoneControlEnums", "MediaBrowserBase"], function (AudioZoneControlEnums, MediaBrowserBase) {
    class MediaBrowserFavorites extends MediaBrowserBase {
        //region Static
        static getCellFromContentTypeItem(item) {
            var baseCell = Controls.AudioZoneControl.MediaBrowserBase.getCellFromContentTypeItem.apply(this, arguments);

            if (item.hasOwnProperty(MediaEnum.Event.FILE_TYPE) && Object.values(MediaEnum.FAVORITE_TYPES).indexOf(item[MediaEnum.Event.FILE_TYPE]) !== -1) {
                switch (item[MediaEnum.Event.FILE_TYPE]) {
                    case MediaEnum.FAVORITE_TYPES.TUNEIN:
                        baseCell.content.subtitle = _("media.radio-station");
                        break;

                    case MediaEnum.FAVORITE_TYPES.LMS_PLAYLIST:
                        baseCell.content.subtitle = _("media.playlist");
                        break;

                    case MediaEnum.FAVORITE_TYPES.LMS_LINEIN:
                        baseCell.content.subtitle = _("media.section.line-in");
                        break;

                    case MediaEnum.FAVORITE_TYPES.LIB_FOLDER:
                        baseCell.content.subtitle = _("media.library") + SEPARATOR_SYMBOL + _("media.library.folder");
                        break;

                    case MediaEnum.FAVORITE_TYPES.LIB_TRACK:
                        baseCell.content.subtitle = _("media.library") + SEPARATOR_SYMBOL + _("media.title");
                        break;

                    case MediaEnum.FAVORITE_TYPES.LIB_PLAYLIST:
                        baseCell.content.subtitle = _("media.library") + SEPARATOR_SYMBOL + _("media.playlist");
                        break;

                    case MediaEnum.FAVORITE_TYPES.SPOTIFY_PLAYLIST:
                        baseCell.content.subtitle = _("media.popup.spotify.title") + SEPARATOR_SYMBOL + _("media.playlist");
                        break;

                    case MediaEnum.FAVORITE_TYPES.SPOTIFY_ALBUM:
                        baseCell.content.subtitle = _("media.popup.spotify.title") + SEPARATOR_SYMBOL + _("media.album");
                        break;

                    case MediaEnum.FAVORITE_TYPES.SPOTIFY_ARTIST:
                        baseCell.content.subtitle = _("media.popup.spotify.title") + SEPARATOR_SYMBOL + _("media.artist");
                        break;

                    case MediaEnum.FAVORITE_TYPES.SPOTIFY_TRACK:
                        baseCell.content.subtitle = _("media.popup.spotify.title") + SEPARATOR_SYMBOL + _("media.title");
                        break;

                    default: // Just use the already defined (or not) subText

                }
            }

            return baseCell;
        }

        static getCellButtonIconSrc(item, contentType, vc) {
            return Controls.AudioZoneControl.MediaBrowserBase.getCellButtonIconSrc.apply(this, arguments);
        }

        static getCellTypeForItemCellAtIndex(item, contentType) {
            return GUI.TableViewV2.CellType.BUTTON;
        }

        static getIconObjForItem() {
            var baseIconObj = Controls.AudioZoneControl.MediaBrowserBase.getIconObjForItem.apply(this, arguments);
            Object.keys(baseIconObj).forEach(function (key) {
                var val = baseIconObj[key];

                if (val === Icon.AudioZone.NEW.NOTE) {
                    baseIconObj[key] = Icon.AudioZone.NEW.STAR;
                }
            });
            return baseIconObj;
        }

        static getZoneFavoriteEditCellForFavAtIdx(zoneFav, idx) {
            var iconObj = {
                    hasProvidedCover: false
                },
                title = null,
                leftIconColor = null;

            if (zoneFav) {
                iconObj = Controls.AudioZoneControl.MediaBrowserFavorites.getIconObjForItem(zoneFav);
                title = decodeURIComponent(MediaServerComp.getTitleSubtitleForItem(zoneFav).title);
                leftIconColor = null;

                if (!iconObj.hasProvidedCover) {
                    leftIconColor = iconObj.color;
                }
            }

            return {
                type: GUI.TableViewV2.CellType.GENERAL,
                content: {
                    title: zoneFav ? title : _("media.item.zone-fav.slot-name", {
                        slotNr: idx + 1
                    }),
                    titleColor: zoneFav ? null : Color.TEXT_SECONDARY_B,
                    leftIconBgEnabled: !iconObj.hasProvidedCover,
                    leftIconLarger: iconObj.hasProvidedCover,
                    leftIconColor: leftIconColor,
                    leftIconSrc: zoneFav ? iconObj.lowRes : Icon.EMPTY,
                    removeButtonIconSrc: zoneFav ? Icon.REMOVE : Icon.TRANSLUCENT_ADD,
                    class: zoneFav ? null : "editable-base-cell__add"
                }
            };
        } //endregion Static


        //region Private
        //fast-class-es6-converter: extracted from defineStatic({}) content
        CUSTOM_CTX = {
            ZONE_FAVS: "zoneFav",
            ALL_FAVS: "allFavs"
        }; //endregion Private

        //region Getter
        get desc() {
            return _("favorites.title");
        } //endregion Getter


        constructor(details) {
            super(...arguments);
        }

        getSectionForContentTypeAndItems(contentType, items, isBrowsable) {
            var section = super.getSectionForContentTypeAndItems(...arguments);

            if (contentType === this.EVENT_TYPES.ZONE_FAVORITES) {
                section.headerTitle = _("media.zone.x", {
                    name: this.control.getName()
                });

                if (this.ViewController instanceof GUI.AudioZoneControlDetailedViewController) {
                    section.sectionRightButtonTitle = MediaServerComp.isRestricted() ? null : _("edit");
                    section.rightSectionButtonTapped = this._editZoneFavorites.bind(this);
                }
            } else if (contentType === this.EVENT_TYPES.FAVORITES) {
                section.headerTitle = _("media.favorites.all");

                if (this.ViewController instanceof GUI.AudioZoneControlDetailedViewController) {
                    section.sectionRightButtonTitle = MediaServerComp.isRestricted() ? null : _("edit");
                    section.rightSectionButtonTapped = this._editAllFavorites.bind(this);
                }
            }

            return section;
        }

        getMediaInfo(controlType) {
            return super.getMediaInfo(...arguments);
        }

        getMediaId(controlType) {
            return super.getMediaId(...arguments);
        }

        getCustomContextsForItem(item) {
            if (item === this) {
                return Object.values(this.CUSTOM_CTX);
            }
        }

        getCtxDesc(ctx) {
            var desc = null;

            switch (ctx) {
                case this.CUSTOM_CTX.ZONE_FAVS:
                    desc = _("media.zone.fav");
                    break;

                case this.CUSTOM_CTX.ALL_FAVS:
                    desc = _("media.favorites.all", {
                        zoneName: this.control.getName()
                    });
                    break;
            }

            return desc;
        }

        getActionsForCtxAndItem(ctx, item) {
            var res;

            switch (ctx) {
                case this.CUSTOM_CTX.ZONE_FAVS:
                    res = this._getCustomZoneFavActionsForCtx(ctx);
                    break;

                case this.CUSTOM_CTX.ALL_FAVS:
                    res = this._getCustomAllFavActionsForCtx(ctx);
                    break;

                case this.CONTEXT.LMS:
                    res = this._getLmsFavActionsForCtx(ctx, item);
                    break;

                default:
                    res = null;
            }

            return res;
        }

        _getCustomZoneFavActionsForCtx(ctx) {
            return [this._getActionWithTmplForCtx({
                content: {
                    title: _("edit"),
                    leftIconSrc: Icon.AudioZone.CONTEXT.FAVORITES_EDIT
                },
                action: this._editZoneFavorites.bind(this)
            }, ctx)];
        }

        _getLmsFavActionsForCtx(ctx, item) {
            var actions = [];

            switch (item.contentType) {
                case MediaEnum.MediaContentType.ZONE_FAVORITES:
                    actions.push(this._getActionWithTmplForCtx({
                        content: {
                            title: _("media.zone.fav.delete"),
                            leftIconSrc: Icon.AudioZone.CONTEXT.FAVORITE_REMOVE
                        },
                        action: MediaServerComp.deleteZoneFavorite.bind(MediaServerComp, item, this.contentTypeItems[item.contentType].all.indexOf(item), this.details, this.ViewController)
                    }, ctx));
                    break;

                case MediaEnum.MediaContentType.FAVORITES:
                    actions.push(this._getActionWithTmplForCtx({
                        content: {
                            title: _("media.favorite.delete"),
                            leftIconSrc: Icon.AudioZone.CONTEXT.FAVORITE_REMOVE
                        },
                        action: function () {
                            MediaServerComp.sendMediaServerCommand(MediaEnum.Commands.FAVORITES.DELETE + item[MediaEnum.Event.ID]);
                        }
                    }, ctx));
                    break;
            }

            return actions;
        }

        _getCustomAllFavActionsForCtx(ctx) {
            return [this._getActionWithTmplForCtx({
                content: {
                    title: _("edit"),
                    leftIconSrc: Icon.AudioZone.CONTEXT.FAVORITES_EDIT
                },
                action: this._editAllFavorites.bind(this)
            }, ctx)];
        }

        _editZoneFavorites() {
            this.ViewController.showState(AudioZoneControlEnums.ScreenState.FAVORITE_EDIT, null, {
                control: this.control
            }, AnimationType.MODAL);
        }

        _editAllFavorites() {
            this.ViewController.showState(AudioZoneControlEnums.ScreenState.GLOBAL_FAVORITE_EDIT, null, {
                control: this.control
            }, AnimationType.MODAL);
        }

    }

    Controls.AudioZoneControl.MediaBrowserFavorites = MediaBrowserFavorites;
    return Controls.AudioZoneControl.MediaBrowserFavorites;
});
