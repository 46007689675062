'use strict';

class BackupAndSyncBackupScreen extends GUI.TableViewScreenV2 {
    //region Static
    static Template = class {
        //region Static
        static getContinueButtonTemplate() {
            return $("<div class='continue-button'>" + _("backup-and-sync.backup.restore-button-text") + "</div>");
        } //endregion Static


    }; //endregion Static

    constructor(details) {
        super($('<div />'));
        this._isSyncing = false;
        this._miniservers = details.miniservers;
        this._modificationDate = details.modificationDate;
    }

    viewDidLoad() {
        return super.viewDidLoad(...arguments).then(function () {
            this.continueButtonElem = BackupAndSyncBackupScreen.Template.getContinueButtonTemplate();
            this.continueButton = new GUI.LxButton(this, this.continueButtonElem);
            this.insertSubviewAfter(this.continueButton, this.tableView.getElement());
            this.continueButton.onButtonTapped = this._continueButtonAction.bind(this);
        }.bind(this));
    }

    getAnimation() {
        return AnimationType.NONE;
    }

    titleBarText() {
        return _("backup-and-sync.backup.title");
    }

    reloadTable() {
        var section = {
                headerTitle: _("backup-and-sync.backup.header"),
                rows: []
            },
            modificationDateString = _("screensaver.never"),
            amountOfMiniservers = Object.keys(this._miniservers || {}).length;

        if (this._modificationDate) {
            modificationDateString = moment(this._modificationDate).format(DateType.DateTextAndTime);
        }

        section.rows.pushObject(this._createCell(_("backup-and-sync.backup.title"), modificationDateString));
        section.rows.pushObject(this._createCell(_("backup-and-sync.settings.cells.amount-miniserver.title"), amountOfMiniservers.toString()));
        this.tableContent = [section];
        return super.reloadTable(...arguments);
    }

    closeAction() {
        this.ViewController.dismiss();
    }

    _continueButtonAction() {
        var callbackDefer = Q.defer(),
            waitingPromise = PersistenceComponent.backupAndSyncRestore();
        this.ViewController.showState(ScreenState.WaitingScreen, null, {
            cancelButtonText: _("cancel"),
            message: _("backup-and-sync.waiting.restore.message"),
            callbackDefer: callbackDefer,
            waitingPromise: waitingPromise
        });
        callbackDefer.promise.then(function () {
            var platform = PlatformComponent.getPlatformInfoObj().platform,
                def = Q.defer(),
                details = {
                    iconSrc: Icon.BACKUP_AND_SYNC,
                    title: _("backup-and-sync.restored.title"),
                    iconColor: window.Styles.colors.brand,
                    continueBtnText: _("finish"),
                    continueDef: def
                };

            if (platform === PlatformType.IOS || platform === PlatformType.DeveloperInterface) {
                details.message = _("backup-and-sync.restored.message.icloud");
            } else {
                details.meesage = _("backup-and-sync.restored.message.google");
            }

            this.ViewController.showState(ScreenState.BackupAndSyncRestored, null, details, AnimationType.NONE);
            def.promise.done(function (viaButton) {
                this.ViewController.dismiss();
            }.bind(this));
        }.bind(this), function (error) {
            this.ViewController.showErrorPopup(_("error"), error);
        }.bind(this));
    }

    _createCell(title, text) {
        var conf = {
            content: {
                title: title,
                disclosureText: text,
                clickable: false
            },
            type: GUI.TableViewV2.CellType.GENERAL
        };
        return conf;
    }

}

GUI.BackupAndSyncBackupScreen = BackupAndSyncBackupScreen;
