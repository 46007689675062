'use strict';

define([], function () {
    return {
        AlarmLevel: {
            ALARM: {
                NONE: 0,
                SILENT: 1,
                ACUSTIC: 2,
                OPTIC: 3,
                INTERNAL: 4,
                EXTERNAL: 5,
                REMOTE: 6
            }
        }
    };
});
