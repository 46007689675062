import PropTypes from "prop-types";


export const LxPropTypes = {
    Miniserver: PropTypes.shape({
        serialNo: PropTypes.string,
        activeUser: PropTypes.string,
        password: PropTypes.string,
        isInTrust: PropTypes.bool,
        msName: PropTypes.string,
        localUrl: PropTypes.string,
        remoteUrl: PropTypes.string
    })
}