import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Menu(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M1 5a1 1 0 011-1h20a1 1 0 110 2H2a1 1 0 01-1-1zM1 19a1 1 0 011-1h20a1 1 0 110 2H2a1 1 0 01-1-1zM2 11a1 1 0 100 2h20a1 1 0 100-2H2z"
            />
        </Svg>
    )
}

export default Menu
