'use strict';

class BackupAndSyncWelcomeScreen extends GUI.OnboardingScreen {
    //region Static
    static getShownIds() {
        return [];
    }

    static shouldShow(id) {
        return true;
    } //endregion Static


    constructor(details) {
        super($("<div />"));
        this.continueDef = details.continueDef;
        this.iconSrc = details.iconSrc;
        this.iconColor = details.iconColor;
        this.title = details.title;
        this.message = details.message;
        this.continueBtnText = details.continueBtnText;
    }

    getRightIcon() {
        if (this.rightButtonSrc) {
            return this.rightButtonSrc;
        }

        return super.getRightIcon(...arguments);
    }

    rightAction() {
        if (this.rightButtonAction) {
            return this.rightButtonAction();
        }

        return super.rightAction(...arguments);
    }

    getAnimation() {
        return AnimationType.NONE;
    }

    continueBtnAction() {
        var miniservers = PersistenceComponent.getAllMiniserver(),
            promise = PersistenceComponent.backupAndSyncGetBackup().then(function (backup) {
                if (backup.miniservers && backup.modificationDate) {
                    // Backup found on iCloud
                    var users = PersistenceComponent.backupAndSyncCheckForDifferentUser(backup.miniservers);

                    if (users) {
                        // The currently active user of this miniserver is different to the active user of the same miniserver at the backup
                        // -> show popup where the user can decide which user he wants to user with this miniserver
                        var title,
                            platform = PlatformComponent.getPlatformInfoObj().platform;

                        if (platform === PlatformType.IOS) {
                            title = _("backup-and-sync.popup.merge.title.icloud");
                        } else {
                            title = _("backup-and-sync.popup.merge.title.google");
                        }

                        return this._showMergePopup(backup, title, _("backup-and-sync.popup.merge.message") + _("backup-and-sync.popup.merge.user.message", {
                            localUser: users.localUser,
                            backupUser: users.backupUser
                        }), users).then(function (buttons) {
                        }.bind(this));
                    } else {
                        // Show the normal merge popup
                        return this._showMergePopup(backup, _("backup-and-sync.popup.merge.title.icloud"), _("backup-and-sync.popup.merge.message"));
                    }
                } else {
                    // No backup found on iCloud
                    PersistenceComponent.backupAndSyncSetBackup(miniservers);
                    PersistenceComponent.backupAndSyncSetLastSync(moment());
                    return PersistenceComponent.backupAndSyncSetEnabled(true);
                }
            }.bind(this));
        NavigationComp.showWaitingFor(promise, null, null, false).then(function (response) {
            var details = {
                miniservers: miniservers
            };
            return this.ViewController.showState(ScreenState.BackupAndSyncSettings, null, details);
        }.bind(this), function (error) {
            this.ViewController.showErrorPopup(_("error"), error);
        }.bind(this));
    }

    onTopLeftButtonTapped() {
        this.ViewController.dismiss();
    }

    _showMergePopup(backup, title, message, users) {
        var details = {
            title: title,
            message: message,
            buttonCancel: true
        };

        if (users) {
            details.buttons = [{
                title: _("backup-and-sync.popup.merge.user.buttons", {
                    user: users.localUser
                }),
                color: Color.STATE_INACTIVE
            }, {
                title: _("backup-and-sync.popup.merge.user.buttons", {
                    user: users.backupUser
                }),
                color: Color.STATE_INACTIVE
            }];
        } else {
            details.buttonOk = _("backup-and-sync.popup.merge.merge-button-text");
        }

        return NavigationComp.showPopup(details).then(function (button) {
            PersistenceComponent.backupAndSyncSetLastSync(moment());
            PersistenceComponent.backupAndSyncSetEnabled(true);

            if (button === 0) {
                PersistenceComponent.backupAndSyncMerge(backup, users.localUser);
            } else if (button === 1) {
                PersistenceComponent.backupAndSyncMerge(backup, users.backupUser);
            } else {
                PersistenceComponent.backupAndSyncMerge(backup);
            }
        }.bind(this), function () {
            this.ViewController.dismiss();
        }.bind(this));
    }

}

GUI.BackupAndSyncWelcomeScreen = BackupAndSyncWelcomeScreen;
