import globalStyles from "GlobalStyles";
import { StyleSheet, View, Text } from "react-native";

export default function ViewTypeInfoText({viewInfo}) {
    const suffix = useAmPm() ? "" : ` ${_("timeSuffix")}`,
        text = _("controls.spotpriceoptimizer.infotext", {
            date: ActiveMSComponent.getMomentFromUtcTimestamp(viewInfo.ts).format("L"),
            timeFrom: ActiveMSComponent.getMomentFromUtcTimestamp(viewInfo.start).format("LT"),
            timeTo: ActiveMSComponent.getMomentFromUtcTimestamp(viewInfo.end).format("LT") + suffix
        });
    return (
        <Text style={styles.label}>
            {text}
        </Text>
    )
}

const styles = StyleSheet.create({
    label: {
        ...globalStyles.textStyles.footNote.default,
        marginTop: 16,
        color: globalStyles.colors.text.secondary,
        textAlign: "center"
    },
})
