import PropTypes from "prop-types";
import {useLiveState, CircleGraph, useControl } from "LxComponents"
import {useEffect, useState} from "react";
import globalStyles from "GlobalStyles";

export default function EmOverview({controlUuid}) {
    const liveState = useLiveState(controlUuid,
        [
            "consumptionPower",
            "availablePower",
            "gridImportPower",
            "hasAvailablePower",
            "selfConsumptionPower",
            "isProducingPower"
        ]
    );

    const [circleGraphData, setCircleGraphData] = useState({ values: [], hideComparison: false});
    const control = useControl(controlUuid);

    useEffect(() => {
        (async () => {
            updateCircleGraphData();
        })();
    }, [JSON.stringify(liveState.states)])


    const updateCircleGraphData =  () => {
        const arr = [];
        if (!liveState.states) {
            arr.push({
                color: Color.BORDER_B,
                value: 0,
                label: "...",
                format: "%.0f"
            });

        } else if (liveState.states.isProducingPower) {
            arr.push({
                color: globalStyles.colors.stateActive,
                value: liveState.states.selfConsumptionPower,
                label: _("energymanager2.self-consumption"),
                format: control.actualFormat
            });

            arr.push({
                color: globalStyles.colors.text.secondary,
                value: liveState.states.availablePower,
                label: _("energymanager2.available-power"),
                format: control.actualFormat
            });
        } else if (!liveState.states.isProducingPower) {
            arr.push({
                color: globalStyles.colors.stateActive,
                value: 0,
                label: _("energymanager2.self-consumption"),
                format: control.actualFormat
            });

            arr.push({
                color: globalStyles.colors.text.secondary,
                value: 0,
                label: _("energymanager2.available-power"),
                format: control.actualFormat
            });
        }

        setCircleGraphData({ values: arr, hideComparison: !liveState.states.isProducingPower });
    }


    return <CircleGraph values={circleGraphData.values} hideComparison={circleGraphData.hideComparison} />
}

EmOverview.propTypes = {
    controlUuid: PropTypes.string.isRequired
}
