'use strict';

define(["DarkModeSettingsScreen", "SecuritySettingsScreen", "LoxoneControlSettingsScreen", "PresentationSettingsScreen"], function (DarkModeSettingsScreen, SecuritySettingsScreen, LoxoneControlSettingsScreen, PresentationSettingsScreen) {
    return class AppSettingsScreen extends GUI.TableViewScreen {
        constructor() {
            super($('<div class="app-settings-screen" />'));
        }

        // Private methods
        titleBarText() {
            var isWi = PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface,
                wiOrApp = isWi ? _('webinterface') : _('app');
            return wiOrApp + " " + _('settings');
        }

        reloadTable() {
            var section0 = {
                rows: SandboxComponent.getAppSettings(this._showState.bind(this))
            };
            this.tableContent = [section0];
            section0.rows.sort(function (a, b) {
                return a.content.title.localeCompare(b.content.title);
            });
            return super.reloadTable(...arguments);
        }

        _showState(state, details) {
            this.ViewController.showState(state, null, details);
        }

    };
});
