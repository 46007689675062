'use strict';
/*
This cell contains an EditableTableView which enables us to sort AutomaticDesigner Actions or Events within its group
 */

GUI.TableViewV2.CellType.Special.AUTOMATIC_DESIGNER_CONTAINER_TASK_DETAILS_CELL = "AutomaticDesignerContainerTaskDetailsCell";

class AutomaticDesignerContainerTaskDetailsCell extends GUI.TableViewV2.Cells.AutomaticDesignerContainerCell {
    constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
        super(...arguments);
    }

    _getActionRows(tableContentHelper) {
        return super._getActionRows(...arguments).filter(function (row) {
            return row.content.recorded;
        });
    }

}

GUI.TableViewV2.Cells.AutomaticDesignerContainerTaskDetailsCell = AutomaticDesignerContainerTaskDetailsCell;
