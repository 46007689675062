import {ControlContext} from "../../../react-comps/LxReactControlContent/LxControlContextProvider";
import {useContext, useMemo} from "react";


/**
 * Hook which acquires the values of a node on the root level using the wallbox-managers states.
 * @param nodeUuid
 * @returns {{limitedBy: *, fuse: *, assigned: *}}
 */
export default function useWallboxEmRootNodeValues(nodeUuid) {
    const {control, states} = useContext(ControlContext);
    const {actualState, limitedByState, fuse} = control?.getNode(nodeUuid);
    const {actualStateName, limitedByStateName} = useMemo(() => {
        return {
            actualStateName: control?.getStateNameForUuid(actualState),
            limitedByStateName: control?.getStateNameForUuid(limitedByState)
        }
    }, [control, actualState, limitedByState]);

    return {
        assigned: states[actualStateName],
        limitedBy: states[limitedByStateName],
        fuse: fuse
    }
}