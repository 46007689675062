'use strict';

import { LxReactContextMenuHandler } from "LxComponents";

define(
    "AcControlCommandSrc",
    [
        "ControlCommandSrc",
        "AcControlEnums",
    ], function (
        ControlCommandSrc,
        { ScreenState }
    ) {
    return class AcControlCommandSrc extends ControlCommandSrc {
        getCommands(control, states) {
            let commands = [
                this.createCommand(_("off"), Commands.SWITCH.OFF, undefined, { rowIdx: 0 }),
                this.createCommand(_("on"), Commands.SWITCH.ON, undefined, { rowIdx: 0 }),
            ];

            if (states.isOperatedExternally) {
                commands = [];
            }

            if(Feature.AC_CONTROL_UPDATES_V2 && states?.pauseUntil === 0 && states?.overrideTimer.until === 0) {
                commands.push(
                    this.createCommand(
                        _("steak.timer.start"),
                        this.showTimerScreen.bind(this, control),
                        undefined,
                        { rowIdx: states.isOperatedExternally ? 0 : 1 }
                    )
                );
            } else if (states?.pauseUntil === 0 && Feature.AC_CONTROL_UPDATES && !Feature.AC_CONTROL_UPDATES_V2) {
                commands.push(
                    this.createCommand(
                        _("controls.ac-control.start-sleep"),
                        this.showPicker.bind(this, control),
                        undefined,
                        { rowIdx: states.isOperatedExternally ? 0 : 1 }
                    )
                );
            }

            return commands;
        };

        showTimerScreen(control) {
            NavigationComp.showState(ScreenState.TIMER_SCREEN);
        }

        showPicker(control) {
            let options = {
                type: window.GUI.LxTimePickerContextMenu.TYPE.DATE_TIME,
                value: new LxDate(),
                onPickerDisappear: (ts) => {
                    const duration = ts.unix() - SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINISERVER_DATE_TIME).unix();
                    control.sendCommand(Commands.format(Commands.AC.START_SLEEP, duration), Commands.Type.OVERRIDE)
                },
            };

            LxReactContextMenuHandler.shared.showContextMenu(
                options,
                _("controls.ac-control.pause-until"),
                null,
                window.GUI.LxTimePickerContextMenu
            );
        }
    }
});
