'use strict';

import {
    ReactWrapper,
    LxReactRenderErrorView,
} from "LxComponents";

window.GUI = function (GUI) {
    var viewInstanceCount = 0;
    var viewCreationCount = 0;

    class View {
        //region Getter

        /**
         * Will only work if the view is registered for orientationChange
         * @return {boolean|undefined}
         */
        get isLandscape() {
            if (this._orientationChangeMediaQueryListener) {
                return this._orientationChangeMediaQueryListener.matches;
            } else {
                return window.isLandscape();
            }
        }

        get viewId() {
            return this._viewId;
        } //endregion Getter


        /**
         * Not all views should adopt to ambient mode, hence provide an option to intercept.
         * @returns {*}
         */
        isInAmbientMode() {
            return !!AMBIENT_MODE;
        }


        /**
         * On react errors, it may try to stringify a view to show it in the log (e.g. when provided as argument during
         * the old navigation procedure) --> which may likely result in a cyclic dependency if e.g. a button has a delegate
         * set.
         * This method ensures that JSON.stringify() won't crash due to cyclic dependencies.
         * @returns {string}
         */
        toJSON() {
            return { viewId: this.viewId };
        }

        /**
         * Base View Object
         * @param {HTMLElement} element of the view
         * @constructor
         */
        constructor(element) {
            Object.assign(this, EventHandler.Mixin);
            this.name = this.constructor.name;
            viewCreationCount++;
            this._viewId = this.name + " - " + viewCreationCount;
            viewInstanceCount++;
            Debug.GUI.Views && console.log(this.viewId, "ctor");
            Debug.Instances && console.info("ViewInstanceCount ++ " + viewInstanceCount);
            Debug.GUI.ViewLifeCycle && this._trackViewLifeCycleMethod("ctor");
            this.subviewMap = {};
            this.element = element || $('<div />');

            this._addCssClasses();

            this.element.attr("viewId", this.viewId); // create bound fn here to ensure it is removed properly.

            this.boundResizeFn = function () {
                this.onResize();
            }.bind(this); // create bound fn here to ensure it is removed properly.


            this._boundOrientationChangeFn = function (e) {
                this.onOrientationChange(e.matches);
            }.bind(this);

            this.viewDidAppearDeferred = Q.defer();
        }

        // no setter, if the viewId is changed, the view wouldn't be found in the subviews map.
        getElement() {
            return this.element;
        }

        /**
         * gets called ONCE when the view will be shown soon
         * view is NOT in DOM at this point
         */
        viewDidLoad() {
            Debug.GUI.Views && console.log(this.viewId, "viewDidLoad");
            Debug.GUI.ViewLifeCycle && this._trackViewLifeCycleMethod("viewDidLoad");

            if (this._viewDidLoadPassed || this._viewWillAppearPassed || this._viewDidAppearPassed) {
                this._printLifeCycleError("viewDidLoad");
            }

            this._viewDidLoadPassed = true;
            Debug.GUI.ViewIds && this.element.attr("title", this.viewId);
            return this._dispatchToSubviews("viewDidLoad", arguments);
        }

        /**
         * gets called when the view is about to appear (before transition)
         * view is in DOM at this point
         */
        viewWillAppear() {
            Debug.GUI.Views && console.log(this.viewId, "viewWillAppear");
            Debug.GUI.ViewLifeCycle && this._trackViewLifeCycleMethod("viewWillAppear");

            if (!this._viewDidLoadPassed || this._viewWillAppearPassed || this._viewDidAppearPassed) {
                this._printLifeCycleError("viewWillAppear");
            }

            this.registeredForResize && this.registerForResize();
            this._orientationChangeMediaQueryListener && this.registerForOrientationChange();
            this._viewWillAppearPassed = true;
            this._updateCssClasses();
            return this._dispatchToSubviews("viewWillAppear", arguments);
        }

        /**
         * gets called after the transition
         * view is in DOM at this point
         */
        viewDidAppear() {
            Debug.GUI.Views && console.log(this.viewId, "viewDidAppear");
            Debug.GUI.ViewLifeCycle && this._trackViewLifeCycleMethod("viewDidAppear");

            if (!this._viewDidLoadPassed || !this._viewWillAppearPassed || this._viewDidAppearPassed) {
                this._printLifeCycleError("viewDidAppear");
            } // ensure it's never called before the viewWillAppear - this may happen in the tableView.


            var promise = this._dispatchToSubviews("viewDidAppear", arguments, "viewWillAppear");

            this._viewDidAppearPassed = true;
            return promise.then(function (res) {
                if (this.viewDidAppearDeferred) {
                    setTimeout(function () {
                        this.viewDidAppearDeferred.resolve();
                        this.viewDidAppearDeferred = null;
                    }.bind(this), 0);
                } else {
                    console.warn(this.viewId, "viewDidAppear called, but no deferred around! proceed, but sth is fishy");
                }

                return res;
            }.bind(this));
        }

        viewWillDisappear() {
            Debug.GUI.Views && console.log(this.viewId, "viewWillDisappear");
            Debug.GUI.ViewLifeCycle && this._trackViewLifeCycleMethod("viewWillDisappear");

            if (!this._viewDidLoadPassed || !this._viewWillAppearPassed || !this._viewDidAppearPassed) {
                this._printLifeCycleError("viewWillDisappear");
            } // Remove boundFn to ensure it doesn't mess with the UI while not visible.


            this.registeredForResize && window.removeEventListener("resize", this.boundResizeFn); // Remove boundFn to ensure it doesn't mess with the UI while not visible.

            this._orientationChangeMediaQueryListener && this._orientationChangeMediaQueryListener.removeEventListener("change", this._boundOrientationChangeFn);
            this._viewWillAppearPassed = false;

            var promise = this._dispatchToSubviews("viewWillDisappear", arguments);

            this.emit("viewWillDisappear");
            this.viewDidAppearDeferred = Q.defer();
            return promise;
        }

        viewDidDisappear(viewRemainsVisible) {
            Debug.GUI.Views && console.log(this.viewId, "viewDidDisappear");
            Debug.GUI.ViewLifeCycle && this._trackViewLifeCycleMethod("viewDidDisappear");

            if (!this._viewDidLoadPassed || this._viewWillAppearPassed || !this._viewDidAppearPassed) {
                this._printLifeCycleError("viewDidDisappear");
            }

            this._viewDidAppearPassed = false;

            var promise = this._dispatchToSubviews("viewDidDisappear", arguments);

            this.emit("viewDidDisappear");
            return promise;
        }

        hasHistory() {
            Debug.GUI.Views && console.log(this.viewId, "hasHistory"); //console.info("overwrite history, default: true");

            return true;
        }

        wasAlreadyShown() {
            return typeof this._viewWillAppearPassed === "boolean" && typeof this._viewDidAppearPassed === "boolean";
        }

        /**
         * Used for processing data that is loaded async. Will not call the processFunction unless viewDidAppear
         * was passed.
         * @param loaderPromise         the promise that will resolve once the data was loaded async.
         * @param processFunction       function that will e.g. update the UI with the data received by the loader
         * @param failFunction          called when the loader fails
         */
        processAsync(loaderPromise, processFunction, failFunction) {
            if (this.viewDidAppearDeferred) {
                Q.all([this.viewDidAppearDeferred.promise, loaderPromise]).done(function (args) {
                    // Q.all resolves with an args-array, the success of viewDidAppearedDef & the loaderPromise.
                    processFunction(args ? args[1] : null);
                }, failFunction); // only the fail argument of the failed promise is returned
            } else {
                loaderPromise.done(processFunction, failFunction);
            }
        }

        /**
         * Calls the given processFunction as soon as viewDidAppear was passed. Called sync if the view is visible
         * already. Used e.g. for lengthy tasks such as redrawing the daytimer during the initial "receivedStates" call
         * triggered using "requestStates".
         * @param processFunction   the method to perform once the view is visible.
         */
        processWhenVisible(processFunction) {
            var resPrms;

            if (this.viewDidAppearDeferred) {
                resPrms = this.viewDidAppearDeferred.promise.then(function () {
                    return Q(processFunction() || true);
                }.bind(this));
            } else if (this.isVisible(true)) {
                resPrms = Q(processFunction() || true);
            } else {
                console.warn(this.viewId, "called processWhenVisible but the view is probably about to dismiss!");
                console.warn(this.viewId, "  Stack: " + JSON.stringify(getStack()));
                resPrms = Q.reject("changing-to-viewDidDisappear");
            }

            return resPrms;
        }

        /**
         * Returns true if the view is visible and no more animations are active at the time this is called.
         * @param withAnimations if true should be returned when the view is currently animating in
         * @returns {boolean}
         */
        isVisible(withAnimations) {
            if (withAnimations) {
                return this._viewWillAppearPassed && this._viewDidAppearPassed;
            } else {
                return this._viewDidAppearPassed;
            }
        }

        getAnimation() {
            Debug.GUI.Views && console.log(this.viewId, "getAnimation");
            return AnimationType.NONE; // don't change!
        }

        getURL() {
            Debug.GUI.Views && console.log(this.viewId, "getURL"); //console.info("overwrite getURL, default: '~'");

            return "~";
        }

        setViewController(vc) {
            if (this.ViewController !== vc) {
                Debug.GUI.Views && console.log(this.viewId, "setViewController:", vc.name);
                this.ViewController = vc;
                if (vc.reactNavigationProps) {
                    this.element.addClass("uses-react-safe-area")
                }
                Object.values(this.subviewMap).forEach(function (sv) {
                    sv.setViewController(vc);
                }.bind(this));
            }
        }

        registerForResize() {
            Debug.GUI.Views && console.log(this.viewId, "registerForResize");
            this.registeredForResize = true;
            window.addEventListener("resize", this.boundResizeFn);
        }

        onResize() {
            console.warn("You must register for the resize event. If not needed, don't call 'registerForResize'!");
        }

        registerForOrientationChange() {
            Debug.GUI.Views && console.log(this.viewId, "registerForOrientationChange");
            this._orientationChangeMediaQueryListener = window.matchMedia("(orientation: landscape)");

            this._orientationChangeMediaQueryListener.addEventListener("change", this._boundOrientationChangeFn);

            this._boundOrientationChangeFn(this._orientationChangeMediaQueryListener);
        }

        onOrientationChange(isLandscape) {
            console.warn("You must register for the orientationChange event. If not needed, don't call 'registerForOrientationChange'!");
        }

        destroyOnBackNavigation() {
            Debug.GUI.Views && console.log(this.viewId, " destroyOnBackNavigation"); //console.info("overwrite destroyOnBackNavigation, default: true");

            return true;
        }

        destroy() {
            Debug.GUI.Views && console.log(this.viewId, "destroy ✝");
            Debug.GUI.ViewLifeCycle && this._trackViewLifeCycleMethod("destroy");

            if (!this._viewDidLoadPassed || this._viewWillAppearPassed || this._viewDidAppearPassed) {
                this._printLifeCycleError("destroy");
            } // required as processWhenVisible may has cause pending promises.


            if (this.viewDidAppearDeferred) {
                this.viewDidAppearDeferred.promise.fail(function () {// install at least one handler so the rejection doesn't mess with the log.
                });
                this.viewDidAppearDeferred && this.viewDidAppearDeferred.reject("DESTROYED");
            }

            Object.keys(this.subviewMap).forEach(svKey => {
                let subview = this.subviewMap[svKey];
                if (subview && subview.isReactComp && subview.isCompMounted) {
                    ReactWrapper.unmountReact(subview.getElement().parent());
                }
            });

            this._dispatchToSubviews("destroy", arguments);

            this.emit("destroy");
            window.removeEventListener("resize", this.boundResizeFn);
            delete this.ViewController;
            this.element = null;
            this.name = null;
            this.destroyEventHandler();
            viewInstanceCount--;
            Debug.Instances && console.info("ViewInstanceCount -- " + viewInstanceCount);
        }

        // --------------------------------------------------------------------
        //                     Subview-Handling
        // --------------------------------------------------------------------
        //

        /**
         * stores the given view-subclass in the subviews array so it'll receive all UI lifecycle-method calls along the way.
         * viewDidLoad will be called right away. It will not be added to them DOM.
         * MUST append/prepend the view afterwards, otherwise the view lifecycle won't work.
         * @param subview   the subview to add to the arrays
         */
        addToHandledSubviews(subview) {
            this._addSubview(subview);

            return Q(this._prepareSubviewBeforeAdd(subview) || true).then(function () {
                return Q(this._prepareSubviewAfterAdd(subview) || true);
            }.bind(this));
        }

        /**
         * Will add the subviews to the map and if required, call viewDidLoad
         * @param subviews
         * @returns {Q.Promise}
         */
        bulkPrepareSubviewsBeforeAdd(subviews) {
            // sequential calls while waiting for the previous promise for each cell would result in long waiting times
            // in larger lists. Instead iterate over them, make each call and wait for them all to finish at the end.
            var preparePromises = [];
            subviews.forEach(function bulkPrepareSubviewBeforeAddStep(subview) {
                this._addSubview(subview);

                var vdlPrms;

                if (this._viewDidLoadPassed && !subview._viewDidLoadPassed) {
                    vdlPrms = Q(subview.viewDidLoad());
                } else {
                    vdlPrms = Q.resolve();
                }

                preparePromises.push(vdlPrms.then(function () {
                    if (this._viewDidAppearPassed && !subview._viewWillAppearPassed) {
                        return subview.viewWillAppear();
                    } else {
                        return Q.resolve();
                    }
                }.bind(this)));
            }.bind(this));
            return Q.all(preparePromises);
        }

        /**
         * Like individual addToHandledSubviews, but doesn't wait for the viewWill/didAppear function calls.
         * Will only add to handledSubviews and call viewDidLoad if the views subviews haven't been preloaded by
         * bulkPrepareSubviewsBeforeAdd or alike.
         * @param subviews      the array of subviews to add
         * @returns {Q.Promise}
         */
        bulkPrepareSubviewsAfterAdd(subviews) {
            var removePromises = [];
            subviews.forEach(function bulkPrepareSubviewAfterAddStep(subview) {
                var promise = null;

                try {
                    promise = this._prepareSubviewAfterAdd(subview);
                } catch (e) {
                    console.error("Couldn't prepare subview after add: " + e.message + ", stack: " + JSON.stringify(e.stack));
                    promise = Q.resolve();
                }

                removePromises.push(promise);
            }.bind(this));
            return Q.all(removePromises);
        }

        /**
         * Will remove the view from the handled subviews
         * Returns a function that will call viewDidDisappear and return a promise that resolves with the instance once
         * the viewLifeCycle-Methods have resolved.
         * The view must be removed AFTER calling this method and BEFORE calling the function returned.
         * The subview will NOT be destroyed! This way it can be eventually reused later on.
         * @param subview   the subview
         * @returns {(function(...[*]=))|{instance: *, cleanupFn: cleanupFn}}
         */
        removeFromHandledSubviews(subview) {
            this._removeFromSubviews(subview);

            var didAppearPassed = this._viewDidAppearPassed;
            var willAppearPassed = this._viewWillAppearPassed;
            var willDisappearPromise = null; // only dispatch if view was visible

            if ((didAppearPassed || willAppearPassed) && !subview.isHidden) {
                if (didAppearPassed) {
                    willDisappearPromise = Q(subview.viewWillDisappear() || true);
                } else {
                    willDisappearPromise = Q.resolve();
                } // the cleanup functions waits for viewWillDisappear and viewDidDisappear to resolve before returning
                // the view


                return function () {
                    var prms;

                    if (willAppearPassed) {
                        prms = willDisappearPromise.then(subview.viewDidDisappear.bind(subview));
                    } else {
                        prms = Q.resolve();
                    }

                    return prms.then(function () {
                        return subview;
                    });
                };
            } else {
                return function () {
                    // Return instance for reusing in TableView
                    return Q.resolve(subview);
                };
            }
        }

        /**
         * Appends the given subview to the target given. uses this.element if no target is specified
         * @param subview the instance of GUI.View that is to be appended to the target
         * @param [target] the optional jquery element that the subview is appended to
         */
        appendSubview(subview, target) {
            var promises = [true];

            this._addSubview(subview);

            promises.push(this._prepareSubviewBeforeAdd(subview));

            var appendTarget = this._getTarget(target);

            var appendElement = subview.getElement();
            promises.push(GUI.animationHandler.append(appendElement, appendTarget).then(function () {
                return this._prepareSubviewAfterAdd(subview);
            }.bind(this)));
            return Q.all(promises);
        }

        appendReactComp({
           reactComp,
           compProps,
           target = this._getTarget()
        }) {
            let def = Q.defer(),
                reactInstance,
                internalReactComp,
                internalCompProps;

            internalReactComp = reactComp;
            internalCompProps = compProps;

            reactInstance = ReactWrapper.render(internalReactComp, internalCompProps, target, e => {
                if (e) {
                    def.resolve(this.appendReactComp({
                        reactComp: LxReactRenderErrorView,
                        compProps: {
                            error: e
                        },
                        target
                    }));
                } else {
                    def.resolve();
                }
            });

            return def.promise.then((instance) => {
                if (reactInstance) {
                    // Don't re-add, it would cause errors and isn't necessary.
                    if (!this._containsSubview(reactInstance)) {
                        this._addSubview(reactInstance)
                    }
                    return reactInstance;
                } else {
                    return instance;
                }
            });
        }



        /**
         * Will replace an existing subview with a new one.
         * @param toReplace
         * @param replaceWith
         */
        replaceSubview(toReplace, replaceWith) {
            var addPreparePromise = this._prepareSubviewBeforeAdd(replaceWith);

            var removeCleanupFunction = this.removeFromHandledSubviews(toReplace);
            return addPreparePromise.then(function () {
                return GUI.animationHandler.replace(toReplace.getElement(), replaceWith.getElement()).then(function () {
                    this._addSubview(replaceWith);

                    return this._prepareSubviewAfterAdd(replaceWith).then(function () {
                        setTimeout(removeCleanupFunction, 5);
                    });
                }.bind(this));
            }.bind(this));
        }

        /**
         * Appends the given subview to the target given. uses this.element if no target is specified
         * Contrary to appendSubview, this method handles the view live cycles properly. viewDidLoad is being waited for
         * before calling viewWill- and viewDidAppear (if parent view already got those)
         * @param subview the instance of GUI.View that is to be appended to the target
         * @param [target] the optional jquery element that the subview is appended to
         * @returns {*} Promise that resolves once the view lifecycles have passed.
         */
        safeAppendSubview(subview, target) {
            this._addSubview(subview);

            return this._prepareSubviewBeforeAdd(subview).then(function () {
                var appendTarget = this._getTarget(target);

                var appendElement = subview.getElement();
                return GUI.animationHandler.append(appendElement, appendTarget).then(function () {
                    return this._prepareSubviewAfterAdd(subview);
                }.bind(this));
            }.bind(this));
        }

        /**
         * Inserts the provided subview before the provided target.
         * @param subview the instance of GUI.View that is to be appended to the target
         * @param target the jquery element that the subview is to be inserted before
         */
        insertSubviewBefore(subview, target) {
            var promises = [true];

            this._addSubview(subview);

            promises.push(this._prepareSubviewBeforeAdd(subview));

            var targetElem = this._getTarget(target);

            var elem = subview.getElement();
            promises.push(GUI.animationHandler.insertBefore(elem, targetElem).then(function () {
                return this._prepareSubviewAfterAdd(subview);
            }.bind(this)));
            return Q.all(promises);
        }

        /**
         * Inserts the provided subview after the provided target.
         * @param subview the instance of GUI.View that is to be appended to the target
         * @param target the jquery element that the subview is to be inserted after
         */
        insertSubviewAfter(subview, target) {
            var promises = [true];

            this._addSubview(subview);

            promises.push(this._prepareSubviewBeforeAdd(subview));

            var targetElem = this._getTarget(target);

            var elem = subview.getElement();
            promises.push(GUI.animationHandler.insertAfter(elem, targetElem).then(function () {
                return this._prepareSubviewAfterAdd(subview);
            }.bind(this)));
            return Q.all(promises);
        }

        /**
         * Appends the given subview array to the target given. uses this.element if no target is specified
         * @param subviews array of GUI.View-instances that are to be appended to the target
         * @param [target] the optional target that the subview is appended to
         */
        appendSubviews(subviews, target) {
            var i,
                elemFragment = document.createDocumentFragment();

            for (i = 0; i < subviews.length; i++) {
                this._addSubview(subviews[i]);

                this._prepareSubviewBeforeAdd(subviews[i]);

                elemFragment.appendChild(subviews[i].getElement()[0]);
            }

            var targetElem = this._getTarget(target);

            GUI.animationHandler.append(elemFragment, targetElem).then(function () {
                for (i = 0; i < subviews.length; i++) {
                    this._prepareSubviewAfterAdd(subviews[i]);
                }
            }.bind(this));
        }

        appendReactComps({
            reactCompProps = [],
            target
        }) {
            return Q.all(reactCompProps.map((reactCompProp) => {
                return this.appendReactComp({
                    reactComp: reactCompProp.comp,
                    compProps: reactCompProp.props,
                    target: target
                });
            }));
        }
        /**
         * Prepends the given subview to the target given. uses this.element if no target is specified
         * @param subview the instance of GUI.View that is to be prepended to the target
         * @param [target] the optional target that the subview is prepended to
         */
        prependSubview(subview, target) {
            this._addSubview(subview);

            this._prepareSubviewBeforeAdd(subview);

            var targetElem = this._getTarget(target);

            var elem = subview.getElement();
            GUI.animationHandler.prepend(elem, targetElem).then(function () {
                return this._prepareSubviewAfterAdd(subview);
            }.bind(this));
            return subview;
        }

        prependReactComp({ reactComp, compProps, target }) {
            let def = Q.defer(), reactInstance = null;
            target = this._getTarget(target);
            let reactWrapperElem = document.createElement("div");

            GUI.animationHandler.prepend(reactWrapperElem, target).then(() => {
                reactInstance = ReactWrapper.render(reactComp, compProps, reactWrapperElem, (e) => {
                    if (e) {
                        def.reject(e);
                    } else {
                        def.resolve();
                    }
                });
                this._addSubview(reactInstance);
            })
            return def.promise.then(() => {
                return reactInstance;
            });
        }

        /**
         * Prepends the given subview to the target given. uses this.element if no target is specified
         * @param subview the instance of GUI.View that is to be prepended to the target
         * @param [target] the optional target that the subview is prepended to
         */
        safePrependSubview(subview, target) {
            this._addSubview(subview);

            return this._prepareSubviewBeforeAdd(subview).then(function () {
                var targetElem = this._getTarget(target);

                var elem = subview.getElement();
                return GUI.animationHandler.prepend(elem, targetElem).then(function () {
                    return this._prepareSubviewAfterAdd(subview);
                }.bind(this));
            }.bind(this));
        }

        /**
         * Will either show or hide the subview provided.
         * @param subview
         * @param show   if the subview is to be shown or not
         * @returns {*}
         */
        toggleSubview(subview, show) {
            if (!this._isView(subview)) {
                throw "Cannot toggle subview, it's not a subclass of GUI.View";
            }

            var result = true;

            if (!!show && subview.isHidden) {
                result = this.showSubview(subview);
            } else if (!show && !subview.isHidden) {
                result = this.hideSubview(subview);
            }

            return result;
        }

        /**
         * Removes the given subview from it's parent
         * @param subview the instance of GUI.View that is to be removed
         * @param [keepAlive] if true, the instance won't be destroyed
         * @returns {Q.Promise<Boolean>}
         */
        removeSubview(subview, keepAlive) {
            if (!this._isView(subview)) {
                throw "Cannot remove subview, it's not a subclass of GUI.View";
            }

            var removePromise = null; // remove from subviews

            this._removeFromSubviews(subview);

            var viewWasAppeared = this._isSubviewShown(subview) && subview._viewDidAppearPassed;

            if (viewWasAppeared) {
                subview.viewWillDisappear();
            }

            if (subview.getElement()) {
                var subviewElement = subview.getElement()[0];

                if (!subviewElement.parentNode) {
                    removePromise = GUI.animationHandler.remove(subview.getElement());
                } else {
                    removePromise = GUI.animationHandler.schedule(function () {
                        subviewElement.parentNode.removeChild(subviewElement);
                    });
                }
            } else {
                console.warn("Removing subview that does not have an element attached!");
            }

            removePromise = removePromise || Q.resolve();
            return removePromise.then(function removeSubviewRemovePromiseResolved() {
                var vddPrms;

                if (viewWasAppeared) {
                    vddPrms = Q(subview.viewDidDisappear(false));
                } else {
                    vddPrms = Q.resolve();
                }

                return vddPrms.then(function () {
                    var reuseID = subview.getReuseID && subview.getReuseID() || false;

                    if (reuseID) {
                        return reuseID;
                    } else if (!keepAlive) {
                        subview.destroy();

                        if (subview.isReactComp) {
                            ReactWrapper.unmountReact(subview.getElement().parent())
                        }
                    }

                    return false;
                }.bind(this));
            }.bind(this));
        }

        removeReactComp(reactComp) {
            this._removeFromSubviews(reactComp);
            return Q(reactComp.viewWillDisappear()).then(() => {
                return Q(reactComp.viewDidDisappear()).then(() => {
                    return Q(reactComp.destroy()).then(() => {
                        reactComp.getElement().remove();
                    });
                });
            });
        }

        /**
         * Toggles the subview-elements visibility and calls the lifecycle methods neccessary. Keeps in mind whether
         * or not the parent view did receive the lifecycle methods already or not.
         * @param [animationPromise]  the subview will be hidden after the animationPromise resolves.
         * @param subview
         */
        hideSubview(subview, animationPromise) {
            var prms;

            if (!this._containsSubview(subview)) {
                throw "Cannot hide subview that is not part of the subviews array!";
            }

            if (subview.isReactComp) {
                throw "Cannot hide react component, handle it in the components render function!";
            }

            if (subview.isHidden) {
                return Q.resolve(); // avoid multiple view lifecycle calls
            }

            var viewWasAppeared = subview._viewDidAppearPassed;

            if (viewWasAppeared) {
                subview.viewWillDisappear();
            }

            subview.isHidden = true;

            if (animationPromise) {
                prms = animationPromise.done(function () {
                    GUI.animationHandler.setCssAttr(subview.getElement(), "display", "none").then(function () {
                        if (viewWasAppeared) {
                            subview.viewDidDisappear();
                        }
                    });
                });
            } else {
                prms = GUI.animationHandler.setCssAttr(subview.getElement(), "display", "none").then(function () {
                    if (viewWasAppeared) {
                        subview.viewDidDisappear();
                    }
                });
            }

            return prms;
        }

        /**
         * Toggles the subview-elements visibility and calls the lifecycle methods neccessary. Keeps in mind whether
         * or not the parent view did receive the lifecycle methods already or not.
         * @param subview
         * @param [animationPromise]  the subview will be shown with an animation. didAppearV2 will be called when this promise resolves
         */
        showSubview(subview, animationPromise) {
            if (!this._containsSubview(subview)) {
                throw "Cannot show subview that is not part of the subviews array!";
            }

            if (subview.isReactComp) {
                throw "Cannot show react component, handle it in the components render function!";
            }

            if (!subview.isHidden) {
                return Q.resolve(); // avoid multiple view lifecycle calls
            }

            if (!this._viewDidLoadPassed) {
                throw "Cannot show subview because it's parent view isn't loaded itself!";
            }

            if (this._viewDidLoadPassed && !subview._viewDidLoadPassed) {
                subview.viewDidLoad();
            }

            subview.isHidden = false;

            if (this._viewWillAppearPassed && !subview._viewWillAppearPassed) {
                subview.viewWillAppear();
            }

            return GUI.animationHandler.setCssAttr(subview.getElement(), "display", "").then(function showSubviewAfPassed() {
                if (animationPromise) {
                    if (this.viewDidAppearDeferred) {
                        // viewDidAppear not called yet and subview is shown with animation. Wait for both to finish
                        Q.all([this.viewDidAppearDeferred.promise, animationPromise]).then(function showSubviewParentViewDidAppearPassed() {
                            !subview._viewDidAppearPassed && subview.viewDidAppear();
                        });
                    } else {
                        animationPromise.then(function showSubviewAnimationPromisePassed() {
                            !subview._viewDidAppearPassed && subview.viewDidAppear();
                        });
                    }
                } else if (this._viewDidAppearPassed) {
                    !subview._viewDidAppearPassed && subview.viewDidAppear();
                }
            }.bind(this));
        }

        /**
         * Gives info whether or not the subview was hidden. Isn't using the elements visibilty on purpose to avoid
         * interfering with those parts of the app that aren't using showSubview/hideSubview. We'd have to listen
         * to a visibility event of our subviews then.
         * @param subview       the subview whos visibility we need to check
         * @returns {boolean}   returns if the subview shown or not.
         * @private
         */
        _isSubviewShown(subview) {
            return !subview.isHidden;
        }

        _addSubview(subviewOrReactComp) {
            if (!this._isView(subviewOrReactComp)) {
                throw "Cannot add subview, it's not a subclass of GUI.View";
            }

            if (this._containsSubview(subviewOrReactComp)) {
                throw "" + subviewOrReactComp.viewId + " - View '" + subviewOrReactComp.name + "' was already added to subviews!";
            }

            this.subviewMap[subviewOrReactComp.viewId] = subviewOrReactComp;
            if (!this.ViewController) {
                console.info("this view has no ViewController (" + this.name + ":" + subviewOrReactComp.name + ")");
            } else if (!subviewOrReactComp.ViewController) {
                subviewOrReactComp.ViewController = this.ViewController;
            }
        }

        _removeFromSubviews(subviewOrReactComp) {
            if (this._containsSubview(subviewOrReactComp)) {
                delete this.subviewMap[subviewOrReactComp.viewId];
            } else {
                // possible problem within using lx-table-view-v2, event listeners = 0, subviews = 0, this.table is not empty
                console.warn("View or ReactComp is not part of subviews array, it wasn't added with prependSubview or appendSubview. Or it was already removed.");
            }
        }

        _containsSubview(subview) {
            if (!subview) {
                debugger;
                return false;
            }
            return !!this.subviewMap[subview.viewId];
        }

        _isView(subview) {
            return subview && ((subview instanceof GUI.View) || subview.isReactComp);
        }

        _getTarget(target) {
            return target || this.element;
        }

        _prepareSubviewBeforeAdd(subview) {
            var promises = [true];

            if (this._viewDidLoadPassed && !subview._viewDidLoadPassed) {
                // check also subview (for reusing views)
                promises.push(subview.viewDidLoad() || true);
            }

            return Q.all(promises);
        }

        _prepareSubviewAfterAdd(subview) {
            var promise;

            if (this._isSubviewShown(subview)) {
                // when using addToHandledSubviews or alike inside a view that itself has been inserted into a view
                // using such methods, this method would be called even though the subview has already received the
                // viewWillAppear and viewDidAppear method calls. Check to ensure these aren't called twice.
                if (this._viewWillAppearPassed && !subview._viewWillAppearPassed) {
                    promise = Q(subview.viewWillAppear());
                } else {
                    promise = Q.resolve();
                } // wait for viewWillAppear to pass before proceeding with viewDidAppear


                promise = promise.then(function _prepareSubviewAfterAddWillAppearPassed() {
                    if (this._viewDidAppearPassed && !subview._viewDidAppearPassed) {
                        return Q(subview.viewDidAppear());
                    } else {
                        return Q.resolve();
                    }
                }.bind(this));
            }

            return promise || Q.resolve();
        }

        _dispatchToSubviews(method, args, dontCallBeforeMethod) {
            var crucialMethod = method === "destroy" || method === "viewDidLoad",
                all = [true]; // copy (DON'T clone) the subviews array, so subviews that are added on the way won't be repeatedly informed

            var subViewsCache = this.subviewMap ? Object.values(this.subviewMap) : [];

            for (var i = 0; subViewsCache && i < subViewsCache.length; i++) {
                var subview = subViewsCache[i],
                    requiredPrevMethodPassed = true;
                if (subview.isReactComp) {
                    if (crucialMethod) {
                        all.push(subview[method].apply(subview, args));
                    }
                    continue;
                }
                if (dontCallBeforeMethod) {
                    requiredPrevMethodPassed = subview["_" + dontCallBeforeMethod + "Passed"];
                }

                if ((this._isSubviewShown(subview) || crucialMethod) && requiredPrevMethodPassed && !subview["_" + method + "Passed"]) {
                    // check if eg. _viewWillAppearPassed wasn't called!
                    try {
                        var prms = subview[method].apply(subview, args);

                        if (Q.isPromiseAlike(prms)) {
                            all.push(prms);
                            prms.fail(function (err) {
                                console.error(this.viewId, "subview " + subview.viewId + " rejected on " + method);
                                console.error(this.viewId, err);
                                console.error(this.viewId, JSON.stringify(err));
                            }.bind(this));
                        } else {//console.warn(this.viewId, "The subview " + subview.viewId + " doesn't return a promise on " + method);
                        }
                    } catch (ex) {
                        console.error(this.viewId, "Exception while dispatching '" + method + "' to subview '" + subview.viewId + "'!");
                        console.error(this.viewId, ex.message);
                        console.error(this.viewId, JSON.stringify(ex.stack));
                    }
                }
            }

            return Q.all(all).fail(function (err) {
                console.error(this.viewId, "A subview rejected during " + method + "! ");
                console.error(this.viewId, err);
                console.error(this.viewId, JSON.stringify(err));
                return Q.reject(err);
            }.bind(this));
        }

        _printLifeCycleError(fnName) {
            console.error("+ " + this._viewId + ": ERROR in " + fnName + ", check view lifecycle methods!");
            console.log("        viewDidLoadPassed: " + (this._viewDidLoadPassed ? "passed" : "not passed"));
            console.log("     viewWillAppearPassed: " + (this._viewWillAppearPassed ? "passed" : "not passed"));
            console.log("      viewDidAppearPassed: " + (this._viewDidAppearPassed ? "passed" : "not passed"));
            Debug.GUI.ViewLifeCycle && this._printLifeCycleTrack();
        }

        _trackViewLifeCycleMethod(fnName) {
            this.__viewLifeCylcleTrack = this.__viewLifeCylcleTrack || [];
            var trackedObj = {
                fn: fnName,
                stack: getStack(1)
            };

            this.__viewLifeCylcleTrack.splice(0, 0, trackedObj);

            if (this.__viewLifeCylcleTrack.length > 15) {
                this.__viewLifeCylcleTrack.splice(0, 1);
            }

            if (this._shouldLogViewLifeCycle()) {
                console.log(this.viewId, fnName + ", viewController '" + (this.ViewController ? this.ViewController.viewId : "-no-view-controller-") + "'" + ", called by: " + this.__extractCaller(trackedObj.stack[2]));
            }
        }

        __extractCaller(stackEntry) {
            var parts = stackEntry.split(" at ");

            if (parts.length > 1) {
                parts.splice(0, 1);
                return parts.join(" at ");
            }

            return stackEntry;
        }

        _shouldLogViewLifeCycle() {
            return false; // can be used by subclasses to track view lifecycle methods within the bottommost view.
        }

        _printLifeCycleTrack() {
            console.log("      lifeCycleTrack: ");

            this.__viewLifeCylcleTrack.forEach(function (trackObj) {
                console.log("          - " + trackObj.fn);

                for (var i = 0; i < trackObj.stack.length && i < 5; i++) {
                    console.log("              * " + trackObj.stack[i]);
                }
            });
        }

        /**
         * adds for every class hierarchy a class (+ --hd)
         * @private
         */
        _addCssClasses() {
            this.__initialHdFlag = HD_APP;
            this.__initialAmbientFlag = this.isInAmbientMode();

            if (this.element && this.element.addClass) {
                var cssClasses = [],
                    cssClass,
                    proto = this.__proto__;

                while (proto && proto !== Object.constructor) {
                    if (proto.constructor.name === "defineInitMixinsConstructor") {
                        cssClasses.push("view");

                        if (HD_APP) {
                            cssClasses.push("view" + "--hd");
                        }

                        break;
                    }

                    cssClass = camelCaseToDash(proto.constructor.name);
                    cssClasses.push(cssClass);

                    if (this.__initialHdFlag) {
                        cssClasses.push(cssClass + "--hd");
                    }
                    if(this.__initialAmbientFlag) {
                        cssClasses.push(cssClass + "--ambient");
                    }

                    if (slowDevice) {
                        cssClasses.push("slow-device");
                    }

                    proto = proto.__proto__;
                }

                cssClasses.push(camelCaseToDash(PlatformComponent.getPlatformInfoObj().platform.toLowerCase()));
                this.element.addClass(cssClasses.join(" "));
            } else {
                console.warn("Initiate this View with an jQuery Element! (" + this.name + ")");
            }
        }

        _updateCssClasses() {
            let currentHdFlag = HD_APP,
                currentAmbientFlag = this.isInAmbientMode();
            if (this.__initialHdFlag === currentHdFlag && this.__initialAmbientFlag === currentAmbientFlag) {
                return; // no need.
            }
            if (this.element && this.element.addClass) {
                var cssClassName,
                    proto = this.__proto__;

                while (proto && proto !== Object.constructor) {
                    if (proto.constructor.name === "defineInitMixinsConstructor") {
                        this.element.toggleClass("view" + "--hd", currentHdFlag);
                        break;
                    }
                    cssClassName = camelCaseToDash(proto.constructor.name);
                    this.element.toggleClass(cssClassName + "--hd", currentHdFlag);
                    this.element.toggleClass(cssClassName + "--ambient", currentAmbientFlag);
                    proto = proto.__proto__;
                }
            } else {
                console.warn("Initiate this View with an jQuery Element! (" + this.name + ")");
            }
            this.__initialHdFlag = currentHdFlag;
            this.__initialAmbientFlag = currentAmbientFlag;
        }

    }

    GUI.View = View; // EventHandler Mixin

    return GUI;
}(window.GUI || {});
