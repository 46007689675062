import * as React from "react"
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg"

function Central(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <G clipPath="url(#clip0_1221_288)">
                <Path d="M15 3a3 3 0 11-6 0 3 3 0 016 0zM6 12a3 3 0 11-6 0 3 3 0 016 0zM12 24a3 3 0 100-6 3 3 0 000 6zM24 12a3 3 0 11-6 0 3 3 0 016 0zM12 15a3 3 0 100-6 3 3 0 000 6z" />
            </G>
            <Defs>
                <ClipPath id="clip0_1221_288">
                    <Path fill="#fff" d="M0 0H24V24H0z" />
                </ClipPath>
            </Defs>
        </Svg>
    )
}

export default Central
