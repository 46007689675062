import {
    LxReactTableView,
    LxReactQuickSelect,
    navigatorConfig,
    LxReactText,
    LxReactSwitch,
    useEntryPoint,
    LxReactSeparator,
    AmbientContext
} from "LxComponents";
import { useMemo, useEffect, useContext } from "react";
import globalStyles from "GlobalStyles";
import Icons from "IconLib";
import AmbientNewWallpaperPreview from "./AmbientNewWallpaperPreview";
import useAmbientSettings from "../hooks/useAmbientSettings";
import useEcoScreenSettings from "../../EcoScreen/useEcoScreenSettings";
import AmbientDefaultLocationSelector from "./AmbientDefaultLocationSelector";
import AmbientStackNavigator from "../AmbientStackNavigator";

export default function AmbientSettings({ navigation, route }) {
    const ambientSettings = useAmbientSettings();
    const ecoSettings = useEcoScreenSettings();
    const entryPointInfo = useEntryPoint();
    const { isAmbientMode } = useContext(AmbientContext)

    useEffect(() => {
        navigation.setOptions({
            ...navigatorConfig({
                isAmbientMode: true,
                title: _("ambient.name"),
                headerStyle: {
                    height: 70,
                    borderBottomWidth: 0
                },
                animationType: isAmbientMode ? AnimationType.NONE : AnimationType.MODAL,
                onLeftAction: () => {
                    navigation.navigate(ScreenState.AmbientScreen, {
                        screen: AmbientStackNavigator.name
                    });
                }
            })
        })
    }, [])

    // region ambient rows

    const wallpaperRow = useMemo(() => {
        return {
            content: {
                title: _("ambient.settings.wallpaper.choose"),
                disclosureIcon: true,
            },
            // navigation = AmbientSettingsStackNav, parent = AmbientStackNav
            action: () => navigation.getParent().navigate(AmbientNewWallpaperPreview.name)
        }
    }, [])

    const screenSaverRow = useMemo(() => {
        return {
            content: {
                title: _("screensaver.name"),
                disclosureIcon: true,
            },
            action: () => {
                Debug.EcoScreen && console.log("EcoScreenSettings: Opening Eco Screen Settings in ambient mode");
                navigation.push(ScreenState.EcoScreenSettingsScreen);
            }
        }
    }, []);

    const entryPointRow = useMemo(() => {
        let epTexts = [];
        entryPointInfo && epTexts.pushObject(entryPointInfo.name);
        entryPointInfo && epTexts.pushObject(entryPointInfo.description);
        return {
            disclosureIcon: true,
            title: _("entry-point.name"),
            mainRightContent: {
                comp: LxReactText,
                props: {
                    children: epTexts.join("\n"),
                    style: { ...globalStyles.customStyles.selectableText, maxWidth: 195 }
                }
            },
            onPress: () => navigation.push(AmbientDefaultLocationSelector.name, {})
        }
    }, [entryPointInfo]);

    const timeoutOptions = useMemo(() => {
        let options = ScreenSaverTimes.map(timeEntry => {
            return {
                title: `${timeEntry.title}${timeEntry.unit ? _(timeEntry.unit) : ""}`,
                value: timeEntry.value
            }
        });
        if (!options.find(obj => {
            return parseInt(obj.value) === parseInt(ambientSettings.timeout)
        })) {
            options.push({ title: `Custom (${ambientSettings.timeout}s)`, value: ambientSettings.timeout });
        }
        return options;
    }, [ScreenSaverTimes, ambientSettings.timeout]);

    const alwaysLaunchRow = useMemo(() => {
        return {
            title: _('ambient.settings.launch-in-ambient'),
            mainRightContent: {
                comp: LxReactSwitch,
                props: {
                    value: ambientSettings.timeout > 0,
                    onValueChanged: (newValue) => {
                        let newToValue = -1;
                        if (newValue) {
                            newToValue = timeoutOptions[2].value;
                        }
                        PersistenceComponent.updateAmbientModeSetting({ timeout: newToValue });
                        ambientSettings.timeout = newToValue;
                    },
                }
            }
        }
    }, [ambientSettings.timeout, timeoutOptions])
    const timeoutRow = useMemo(() => {
        if (ambientSettings.timeout < 0) {
            return null;
        }
        return {
            title: _('ambient.settings.reactivate'),
            mainRightContent: {
                comp: LxReactQuickSelect,
                props: {
                    selectedValue: ambientSettings.timeout,
                    options: timeoutOptions,
                    titleStyle: {
                        ...globalStyles.customStyles.selectableText,
                        padding: 0
                    },
                    tintColor: globalStyles.colors.text.secondary,
                    iconComp: Icons.ChevronsUpDown,
                    iconPosition: LxReactQuickSelect.IconPosition.Right,
                    onOptionSelected: value => {
                        let valtoStore = value;
                        if (typeof value === "string") {
                            valtoStore = parseInt(value);
                        }
                        PersistenceComponent.updateAmbientModeSetting({ timeout: valtoStore });
                    }
                }
            }
        };
    }, [timeoutOptions, ambientSettings.timeout]);
    // endregion

    // endregion

    const tableContent = useMemo(() => {
        let content = [];
        let firstSection = {
            rows: [alwaysLaunchRow]
        }
        firstSection.rows.pushObject(timeoutRow);
        content.push(firstSection);

        content.push({
            headerDescription: " ",
            rows: [
                entryPointRow,
                wallpaperRow,
                screenSaverRow,
                LxReactSeparator
            ]
        })

        return content;
    }, [timeoutRow, entryPointRow, ambientSettings.timeout, ecoSettings, screenSaverRow]);



    return <LxReactTableView tableContent={tableContent} />
}
