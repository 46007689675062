import * as React from "react"
import Svg, { Path, Rect } from "react-native-svg"

function Sunset(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            <Path
                d="M17 7V5a1 1 0 00-2 0v2a1 1 0 002 0zM10.3 8.9L8.9 7.5a.99.99 0 10-1.4 1.4l1.4 1.4a.99.99 0 001.4-1.4zM23.1 10.3l1.4-1.4a.99.99 0 00-1.4-1.4l-1.4 1.4a.99.99 0 001.4 1.4zM16 9.5A6.487 6.487 0 009.5 16h13A6.487 6.487 0 0016 9.5z"
                fill="#f4cd27"
            />
            <Rect
                x={4}
                y={17.8125}
                width={24}
                height={2}
                rx={1}
                ry={1}
                fill="#d5e2e9"
            />
            <Path
                d="M12.846 21.368h6.595a.701.701 0 01.496 1.197l-3.297 3.297a.701.701 0 01-.992 0l-3.297-3.297a.701.701 0 01.495-1.197z"
                fill="#d5e2e9"
            />
        </Svg>
    )
}

export default Sunset
