'use strict';

define(['AutopilotEnums', 'AutopilotUtility', 'CarChargerControlEnums', 'PoolControlEnums', 'IRoomControlEnums', 'RemoteControlEnums', 'IRoomControllerV2ControlEnums', 'SteakControlEnums', "FavoritesManager"], function (AutopilotEnums, AutopilotUtility, CarChargerControlEnums, PoolControlEnums, IRoomControlEnums, RemoteControlEnums, IRoomV2ControlEnums, SteakControlEnums, FavoritesManager) {
    return class AutopilotControlCommandsScreen extends GUI.Screen {
        constructor(details) {
            super($('<div class="autopilot-control-commands-screen" />'));
            this.control = details.control;
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments).then(function () {
                this.elements = {};
                this.tableView = new GUI.TableViewV2(this, this);
                this.appendSubview(this.tableView);
                return this._getDetailsByControl(this.control).then(function (sections) {
                    this.tableContent = {
                        sections: sections
                    };
                    this.tableView.reload();
                }.bind(this));
            }.bind(this)));
        }

        getURL() {
            return "Autopilot/ControlCommands";
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarText() {
            return this.control.getName();
        }

        titleBarAction() {
            this.ViewController.navigateBack();
        }

        // TABLE VIEW DELEGATES
        styleForTableView(tableView) {
            return TableViewStyle.GROUPED;
        }

        numberOfSections() {
            return this.tableContent.sections.length;
        }

        titleForHeaderInSection(sectionIdx) {
            return this.tableContent.sections[sectionIdx].title;
        }

        numberOfRowsInSection(sectionIdx) {
            var section = this.tableContent.sections[sectionIdx];

            if (section.commands) {
                return section.commands.length;
            } else if (section.slider || section.input) {
                return 2; // "Add action" button
            }

            return 0;
        }

        cellTypeForCellAtIndex(sectionIdx, rowIdx) {
            var section = this.tableContent.sections[sectionIdx];

            if (section.commands) {
                return GUI.TableViewV2.CellType.GENERAL;
            } else if (section.slider) {
                if (rowIdx === 0) {
                    return GUI.TableViewV2.CellType.SLIDER;
                } else if (rowIdx === 1) {
                    return GUI.TableViewV2.CellType.BUTTON_CELL_CUSTOM; // "Add action" button
                }
            } else if (section.input) {
                if (rowIdx === 0) {
                    return GUI.TableViewV2.CellType.INPUT;
                } else if (rowIdx === 1) {
                    return GUI.TableViewV2.CellType.BUTTON_CELL_CUSTOM; // "Add action" button
                }
            }
        }

        contentForCell(cell, sectionIdx, rowIdx) {
            var section = this.tableContent.sections[sectionIdx];

            if (section.commands) {
                var command = section.commands[rowIdx],
                    title = this._createCommandText(this.control, command),
                    subtitle = null;

                if (title.indexOf(". ") !== -1) {
                    var parts = title.split(". ");

                    if (parts.length === 2) {
                        // if we have two parts, we use the first as title, second: subtitle
                        title = parts[0];
                        subtitle = parts[1];
                    }
                } // always make the first char uppercase! (eg. Alarm commands are lowercase)


                title = title.replaceAt(0, title[0].toUpperCase());
                return {
                    title: title,
                    subtitle: subtitle,
                    showFullSubtitle: true,
                    rightIconSrc: Icon.TRANSLUCENT_ADD,
                    rightIconColor: window.Styles.colors.green,
                    clickable: true
                };
            } else if (section.slider) {
                if (rowIdx === 0) {
                    return section.slider;
                } else if (rowIdx === 1) {
                    // "Add action" button
                    return {
                        title: _("autopilot.rule.actions.add"),
                        color: window.Styles.colors.green
                    };
                }
            } else if (section.input) {
                if (rowIdx === 0) {
                    return section.input;
                } else if (rowIdx === 1) {
                    // "Add action" button
                    return {
                        title: _("autopilot.rule.actions.add"),
                        color: window.Styles.colors.green
                    };
                }
            }
        }

        didSelectCell(cell, sectionIdx, rowIdx) {
            // call edit rule screen
            var section = this.tableContent.sections[sectionIdx],
                command;

            if (section.commands) {
                command = section.commands[rowIdx];
            } else if (section.command) {
                command = section.command;
            }

            if (section.input && (!section.inputValue || section.inputValue === "")) {
                console.error("Cannot save command from input with no input value!");

                if (section.input.invalidTitle || section.input.invalidText) {
                    var popup = {
                        title: section.input.invalidTitle,
                        message: section.input.invalidText,
                        buttonOk: true,
                        icon: Icon.INFO
                    };
                    NavigationComp.showPopup(popup).done(function () {
                        var cell = this.tableView.cellForSectionAndRow(sectionIdx, 0);
                        cell && cell.focus();
                    }.bind(this));
                } else {
                    cell && cell.focus();
                }
            } else if (command) {
                this._saveChanges(command);
            }
        }

        // SLIDER
        sliderDragEnded(cell, section, row, tableView, value) {
            this._setValueForSlider(value, section, row);
        }


        sliderClicked(cell, section, row, tableView, value) {
            this._setValueForSlider(value, section, row);
        }

        userfriendlyValueForSlider(cell, sectionIdx, rowIdx, tableView, value) {
            var section = this.tableContent.sections[sectionIdx];

            if (section.userfriedlyValue) {
                return section.userfriedlyValue(value);
            } else {
                return value;
            }
        }

        // INPUT
        textChanged(section, row, tableView, text, isValid) {
            this._setValueForInput(text, section, row, isValid);
        }

        submitText(section, row, tableView, text, isValid) {
            this._setValueForInput(text, section, row, isValid);
        }

        _saveChanges(command) {
            this._dispatchAction(command);

            NavigationComp.disableAnimationsTemp();
            this.ViewController.navigateBackTo(AutopilotEnums.ScreenStates.AutopilotRuleScreen);
        }

        _dispatchAction(command) {
            NavigationComp.dispatchEventToUI(AutopilotEnums.CommunicationEvents.ADD_ACTION, {
                command: command,
                data: {
                    control: this.control
                },
                id: this.control.uuidAction,
                type: 0
            });
        }

        _getDetailsByControl(control) {
            var sectionsPrms = Q([]);

            switch (control.type) {
                case Control.Type.ALARM_CENTRAL:
                    sectionsPrms = this._populateAlarmCentralDetails(control);
                    break;

                case ControlType.ALARM:
                    sectionsPrms = this._populateAlarmDetails(control, false);
                    break;

                case ControlType.ALARM_CLOCK:
                    sectionsPrms = this._populateAlarmClockDetails(control);
                    break;

                case Control.Type.AUDIO_ZONE_CENTRAL:
                    sectionsPrms = this._populateAudioCentralDetails(control);
                    break;

                case ControlType.AUDIO_ZONE:
                    sectionsPrms = this._populateAudioZoneDetails(control);
                    break;

                case ControlType.AUDIO_ZONE_V2:
                    sectionsPrms = this._populateAudioZoneV2Details(control);
                    break;

                case ControlType.CAR_CHARGER:
                    sectionsPrms = this._populateCarChargerDetails(control);
                    break;

                case ControlType.DAYTIMER:
                    sectionsPrms = this._populateDaytimerDetails(control);
                    break;

                case ControlType.DIMMER:
                case ControlType.DIMMER_EIB:
                    sectionsPrms = this._populateDimmerDetails(control);
                    break;

                case Control.Type.GATE_CENTRAL:
                case ControlType.GATE:
                    sectionsPrms = this._populateGateDetails(control);
                    break;

                case ControlType.HOURCOUNTER:
                    sectionsPrms = this._populateHourcounterDetails(control);
                    break;

                case ControlType.INTERCOM:
                    sectionsPrms = this._populateIntercomDetails(control);
                    break;

                case ControlType.I_ROOM:
                    sectionsPrms = this._populateIRoomDetails(control);
                    break;

                case ControlType.I_Room_V2:
                    sectionsPrms = this._populateIRoomV2Details(control);
                    break;

                case Control.Type.JALOUSIE_CENTRAL:
                    sectionsPrms = this._populateJalousieDetails(control);
                    break;

                case ControlType.JALOUSIE:
                    sectionsPrms = this._populateJalousieDetails(control);
                    break;

                case ControlType.LIGHT:
                    sectionsPrms = this._populateLightDetails(control);
                    break;

                case Control.Type.LIGHT_CENTRAL:
                    sectionsPrms = this._populateLightCentralDetails(control);
                    break;

                case ControlType.LIGHT_V2:
                    sectionsPrms = this._populateLightV2Details(control);
                    break;

                case ControlType.LIGHTSCENE_RGB:
                    sectionsPrms = this._populateLightsceneRgbDetails(control);
                    break;

                case ControlType.MEDIA:
                case ControlType.REMOTE:
                    sectionsPrms = this._populateRemoteDetails(control);
                    break;

                case ControlType.POOL:
                    sectionsPrms = this._populatePoolDetails(control);
                    break;

                case ControlType.PUSHBUTTON:
                    sectionsPrms = this._populatePushbuttonDetails(control);
                    break;

                case ControlType.RADIO:
                    sectionsPrms = this._populateRadioDetails(control);
                    break;

                case ControlType.SAUNA:
                    sectionsPrms = this._populateSaunaDetails(control);
                    break;

                case ControlType.SLIDER:
                    sectionsPrms = this._populateSliderDetails(control);
                    break;

                case ControlType.SMOKE_ALARM:
                    sectionsPrms = this._populateAlarmDetails(control, true);
                    break;

                case ControlType.SWITCH:
                    sectionsPrms = this._populateSwitchDetails(control);
                    break;

                case ControlType.TIMED_SWITCH:
                    sectionsPrms = this._populateTimedSwitchDetails(control);
                    break;

                case ControlType.UP_DOWN_ANALOG:
                case ControlType.LEFT_RIGHT_ANALOG:
                    sectionsPrms = this._populateUdlrAnalogDetails(control);
                    break;

                case ControlType.UP_DOWN_DIGITAL:
                case ControlType.LEFT_RIGHT_DIGITAL:
                    sectionsPrms = this._populateUdlrDigitalDetails(control);
                    break;

                case ControlType.VALUE_SELECTOR:
                    sectionsPrms = this._populateValueSelectorDetails(control);
                    break;

                case ControlType.STEAK:
                    sectionsPrms = this._populateSteakDetails(control);
                    break;

                case ControlType.AAL_SMART_ALARM:
                    sectionsPrms = this._populateAalSmartAlarmDetails(control);
                    break;

                case ControlType.WINDOW:
                    sectionsPrms = this._populateWindowDetails(control);
                    break;

                case ControlType.TEXT_INPUT:
                    sectionsPrms = this._populateTextInputDetails(control);
                    break;

                case ControlType.MAILBOX:
                    sectionsPrms = this._populateMailBoxDetails(control);
                    break;

                case ControlType.AAL_EMERGENCY:
                    sectionsPrms = this._populateAalEmergencyAlarmDetails(control);

                default:
                    break;
            }

            return Q(sectionsPrms);
        }

        _populateAlarmCentralDetails(control) {
            return [{
                commands: [Commands.format(Commands.ALARM.ARM), Commands.format(Commands.ALARM.DELAYED_ARM_SINGLE)]
            }, {
                commands: [Commands.format(Commands.ALARM.DISARM)]
            }, {
                commands: [Commands.ALARM.ACKNOWLEDGE]
            }];
        }

        _populateAlarmDetails(control, isSmokeAlarm) {
            if (isSmokeAlarm) {
                return [{
                    commands: [Commands.ALARM.SMOKE_ALARM.MUTE]
                }, {
                    commands: [Commands.ALARM.START_DRILL]
                }, {
                    commands: [Commands.ALARM.SMOKE_ALARM.QUIT]
                }];
            } else {
                return [{
                    commands: [Commands.format(Commands.ALARM.ARM_SINGLE, 1), Commands.format(Commands.ALARM.ARM_SINGLE, 0), Commands.format(Commands.ALARM.DELAYED_ARM_SINGLE, 1), Commands.format(Commands.ALARM.DELAYED_ARM_SINGLE, 0)]
                }, {
                    commands: [Commands.ALARM.START_DRILL]
                }, {
                    commands: [Commands.ALARM.DISARM]
                }, {
                    commands: [Commands.ALARM.ACKNOWLEDGE]
                }];
            }
        }

        _populateAalSmartAlarmDetails(control) {
            return [{
                commands: [Commands.ALARM.AAL_SMART_ALARM.QUIT]
            }, {
                commands: [Commands.ALARM.START_DRILL]
            }];
        }

        _populateAlarmClockDetails(control) {
            var details = [{
                    commands: [Commands.ALARM_CLOCK.SNOOZE, Commands.ALARM_CLOCK.DISMISS]
                }],
                statesObj = SandboxComponent.getStatesForUUID(control.uuidAction),
                states = statesObj.states,
                entrySection = {
                    commands: []
                };

            var _formatAlarmEntry = function _formatAlarmEntry(entry, active) {
                return Commands.format(Commands.ALARM_CLOCK.ALARM_ENTRY, entry.id, entry.name, entry.alarmTime, active, entry.modes.toString() ? entry.modes.toString() : "");
            };

            if (states && states.sortedEntries) {
                entrySection.title = _("controls.alarmClock.entries.enable-disable");
                states.sortedEntries.forEach(function (entry) {
                    entrySection.commands.push(_formatAlarmEntry(entry, true));
                    entrySection.commands.push(_formatAlarmEntry(entry, false));
                });

                if (entrySection.commands.length > 0) {
                    details.push(entrySection);
                }
            }

            return details;
        }

        _populateAudioCentralDetails(control) {
            return [{
                commands: [Commands.AudioZone.CLIENT.PLAY, Commands.AudioZone.CLIENT.PAUSE]
            }];
        }

        _populateAudioZoneDetails(control) {
            var details = [],
                controlSection,
                favoritesSection,
                volumeSection,
                volumeSetSection,
                ttsSection,
                result = SandboxComponent.getStatesForUUID(control.uuidAction),
                states = result.states;
            controlSection = {
                commands: [Commands.AudioZone.CLIENT.PLAY, Commands.AudioZone.CLIENT.PAUSE, Commands.AudioZone.Queue.NEXT, Commands.AudioZone.Queue.PREV]
            };
            details.push(controlSection); // zone favorites

            favoritesSection = {
                title: _("media.favorites-of-zone", {
                    zone: control.getName()
                }),
                commands: []
            };

            if (states && states.zoneFavorites) {
                states.zoneFavorites.forEach(function (fav) {
                    favoritesSection.commands.push(Commands.AudioZone.CLIENT.SOURCE + fav.slot);
                });
            }

            details.push(favoritesSection); // Volume

            volumeSection = {
                title: _("media.volume"),
                commands: []
            };

            if (states && states.volumeStep) {
                volumeSection.commands.push(Commands.AudioZone.VOLUME_STEP + "/" + states.volumeStep);
                volumeSection.commands.push(Commands.AudioZone.VOLUME_STEP + "/" + states.volumeStep * -1);
            }

            details.push(volumeSection);

            if (states && states.maxVolume) {
                volumeSetSection = {
                    commandFormat: Commands.AudioZone.VOLUME_SET + "/%.0f",
                    command: Commands.AudioZone.VOLUME_SET + "/" + states.volume,
                    slider: {
                        title: _('media.volume'),
                        maxValue: states.maxVolume,
                        minIconSrc: Icon.AudioZone.VOL_MINUS,
                        maxIconSrc: Icon.AudioZone.VOL_PLUS,
                        step: states.volumeStep,
                        value: states.volume,
                        format: "%.0f %"
                    }
                };
                details.push(volumeSetSection);
            }

            if (Feature.TTS_VIA_MINISERVER) {
                var ttsCmd = Commands.AudioZone.TTS.FORMAT; // no volume = default tts volume

                ttsSection = {
                    title: _("media.tts.title"),
                    commandFormat: ttsCmd,
                    command: Commands.format(ttsCmd, _("media.tts.default-text")),
                    input: {
                        placeholder: _('media.tts.text'),
                        validationRegex: Regex.TEXT,
                        invalidTitle: _("media.tts.no-tts-text.title"),
                        invalidText: _("media.tts.no-tts-text.text")
                    }
                };
                details.push(ttsSection);
            }

            return details;
        }

        _populateAudioZoneV2Details(control) {
            control.audioserverComp.activeZoneControl = control;
            return FavoritesManager.shared(control).favorites().then(function (favs) {
                control.audioserverComp.activeZoneControl = null;
                var details = [],
                    controlSection,
                    favoritesSection,
                    volumeSection,
                    volumeSetSection,
                    ttsSection,
                    result = SandboxComponent.getStatesForUUID(control.uuidAction),
                    states = result.states;
                controlSection = {
                    commands: [Commands.AudioZone.CLIENT.PLAY, Commands.AudioZone.CLIENT.PAUSE, Commands.AudioZone.Queue.NEXT, Commands.AudioZone.Queue.PREV]
                };
                details.push(controlSection); // zone favorites

                favoritesSection = {
                    title: _("media.favorites-of-zone", {
                        zone: control.getName()
                    }),
                    commands: favs.map(function (fav) {
                        return Commands.AudioZoneV2.PLAY_SPECIFIC_FAV + "/" + fav.id;
                    })
                };
                details.push(favoritesSection); // Volume

                if (states && states.hasOwnProperty("volume")) {
                    volumeSetSection = {
                        title: _("media.volume"),
                        commandFormat: Commands.AudioZone.VOLUME_SET + "/%.0f",
                        command: Commands.AudioZone.VOLUME_SET + "/" + states.volume,
                        slider: {
                            title: _('media.volume'),
                            minIconSrc: Icon.AudioZone.VOL_MINUS,
                            maxIconSrc: Icon.AudioZone.VOL_PLUS,
                            step: states.volumeStep,
                            value: states.volume,
                            format: "%.0f %"
                        }
                    };
                    details.push(volumeSetSection);
                }

                var ttsCmd = Commands.AudioZone.TTS.FORMAT; // no volume = default tts volume

                ttsSection = {
                    title: _("media.tts.title"),
                    commandFormat: ttsCmd,
                    command: Commands.format(ttsCmd, _("media.tts.default-text")),
                    input: {
                        placeholder: _('media.tts.text'),
                        validationRegex: Regex.TEXT,
                        invalidTitle: _("media.tts.no-tts-text.title"),
                        invalidText: _("media.tts.no-tts-text.text")
                    }
                };
                details.push(ttsSection);
                return details;
            });
        }

        _populateCarChargerDetails(control) {
            var details = [{
                commands: [Commands.CAR_CHARGER.START_CHARGING, Commands.CAR_CHARGER.STOP_CHARGING]
            }];
            var states = SandboxComponent.getStatesForUUID(control.uuidAction);

            if (states && states.states.showLoadManagement) {
                details.push({
                    title: _('controls.car-charger.charging-limit'),
                    commands: [Commands.format(Commands.CAR_CHARGER.SET_LIMIT_MODE, CarChargerControlEnums.LimitMode.AUTO), Commands.format(Commands.CAR_CHARGER.SET_LIMIT_MODE, CarChargerControlEnums.LimitMode.MANUAL), Commands.format(Commands.CAR_CHARGER.SET_LIMIT_MODE, CarChargerControlEnums.LimitMode.OFF)]
                });
                details.push({
                    commandFormat: "%.3f kW",
                    command: Commands.format(Commands.CAR_CHARGER.SET_MAX_LIMIT, states.states.currentLimit),
                    slider: {
                        title: _('controls.car-charger.charging-limit'),
                        color: window.Styles.colors.blue,
                        minValue: states.states.minLimit,
                        maxValue: states.states.maxLimit,
                        minIconSrc: "resources/Images/Controls/CarCharger/icon-bolt.svg",
                        maxIconSrc: "resources/Images/Controls/CarCharger/icon-bolt.svg",
                        step: 0.1,
                        value: states.states.currentLimit,
                        format: "%.3f kW"
                    }
                });
            }

            return details;
        }

        _populateDimmerDetails(control) {
            var details = [{
                commands: [Commands.DIMMER.ON, Commands.DIMMER.OFF]
            }];
            var states = SandboxComponent.getStatesForUUID(control.uuidAction);

            if (states) {
                states = states.states;
                var minValue = Math.max(1, states.min);
                details.push({
                    userfriedlyValue: function (value) {
                        return map(value, [minValue, states.max], [1, 100]);
                    },
                    commandFormat: Commands.DIMMER.VALUE,
                    command: Commands.format(Commands.DIMMER.VALUE, states.pos),
                    slider: {
                        title: _('slider.value'),
                        minValue: minValue,
                        maxValue: states.max,
                        minIconSrc: Icon.Wallmount.SETTINGS.BRIGHTNESS_DOWN,
                        maxIconSrc: Icon.Wallmount.SETTINGS.BRIGHTNESS_UP,
                        preventMinIconScale: true,
                        step: states.step,
                        value: states.pos,
                        format: "%.0f%%"
                    }
                });
            }

            return details;
        }

        _populateGateDetails(control) {
            return [{
                commands: [Commands.GATE.OPEN, Commands.GATE.CLOSE, Commands.GATE.STOP]
            }];
        }

        _populateHourcounterDetails(control) {
            return [{
                commands: [Commands.HOURCOUNTER.RESET]
            }];
        }

        _populateIntercomDetails(control) {
            var details = [{
                commands: [Commands.INTERCOM.ANSWER]
            }];

            if (control.subControls && Object.keys(control.subControls).length > 0) {
                for (var subControlKey in control.subControls) {
                    if (control.subControls.hasOwnProperty(subControlKey)) {
                        var outputDetails = {
                            commands: []
                        };
                        var subControl = control.subControls[subControlKey],
                            outputID = subControl.uuidAction.split("/")[1];
                        outputDetails.commands.push(Commands.format(Commands.INTERCOM.OUTPUT_PULSE, outputID));
                        details.push(outputDetails);
                    }
                }
            }

            return details;
        }

        _populateJalousieDetails(control) {
            var details = [];
            details.push({
                title: _('actions'),
                commands: [Commands.JALOUSIE.FULL_UP, Commands.JALOUSIE.FULL_DOWN, Commands.JALOUSIE.STOP]
            });
            details.push({
                title: _("controls.jalousie.shade"),
                commands: [Commands.JALOUSIE.SHADE]
            });

            if (control.details && control.details.isAutomatic) {
                details.push({
                    title: _("autopilot.rule.name"),
                    commands: [Commands.JALOUSIE.AUTO_ON, Commands.JALOUSIE.AUTO_OFF]
                });
            } else if (control.isGrouped() && control.getStates().autopilotsAvailable_Sel) {
                details.push({
                    title: _("autopilot.rule.name"),
                    commands: [Commands.JALOUSIE.AUTO_ON, Commands.JALOUSIE.AUTO_OFF]
                });
            }

            return details;
        }

        _populateLightDetails(control) {
            var details = [{
                commands: [Commands.LIGHTCONTROL.ALL_ON, Commands.LIGHTCONTROL.ALL_OFF]
            }];
            var states = SandboxComponent.getStatesForUUID(control.uuidAction);

            if (states) {
                if (Object.keys(states.states.scenes).length > 0) {
                    var sceneCmds = [];

                    for (var sceneKey in states.states.scenes) {
                        if (states.states.scenes.hasOwnProperty(sceneKey)) {
                            sceneCmds.push(Commands.format(Commands.LIGHTCONTROL.SELECT_SCENE, sceneKey));
                        }
                    }

                    details.push({
                        title: _('controls.lightcontroller.scenes'),
                        commands: sceneCmds
                    });
                }
            }

            return details;
        }

        _populateLightV2Details(control) {
            var details = [],
                sceneCmds = [],
                states = control.getStates(); // Old two list UI

            /*control.moodsOfList(true, function found(mood) {
                sceneCmds.push(Commands.format(Commands.LIGHTCONTROL_V2.MOOD.CHANGE_TOO, mood.id));
            }.bind(this));
             control.moodsOfList(false, function found(mood) {
                sceneCmds.push(Commands.format(Commands.LIGHTCONTROL_V2.MOOD.CHANGE_TOO, mood.id));
            }.bind(this));*/

            if (states) {
                states.moodList.forEach(function (mood) {
                    sceneCmds.push(Commands.format(Commands.LIGHTCONTROL_V2.MOOD.CHANGE_TOO, mood.id));
                });
            }

            details.push({
                title: _('controls.lightV2controller.mood_plural'),
                commands: sceneCmds
            });
            return details;
        }

        _populateLightCentralDetails(control) {
            return [{
                title: _('controls.lightV2controller.mood_plural'),
                commands: [Commands.LIGHTCONTROL.ALL_ON, Commands.LIGHTCONTROL.ALL_OFF]
            }];
        }

        _populateLightsceneRgbDetails(control) {
            var details = [{
                commands: [Commands.LIGHTSCENE_RGB.ON, Commands.LIGHTSCENE_RGB.OFF]
            }];

            if (control.details && control.details.sceneList) {
                var sceneCmds = [],
                    sceneKey = Object.keys(control.details.sceneList);

                for (var i = 0; i < sceneKey.length; i++) {
                    sceneCmds.push(Commands.format(Commands.LIGHTSCENE_RGB.SELECT_SCENE, sceneKey[i]));
                }

                if (sceneCmds.length > 0) {
                    details.push({
                        title: _('controls.lightcontroller.scenes'),
                        commands: sceneCmds
                    });
                }
            }

            return details;
        }

        _populatePushbuttonDetails(control) {
            return [{
                commands: [Commands.PUSHBUTTON.PULSE]
            }];
        }

        _populateRadioDetails(control) {
            var details = [{
                commands: []
            }];

            if (typeof control.details.allOff === "string" && control.details.allOff.length > 0) {
                details[0].commands.push(Commands.RADIO.RESET);
            }

            if (Object.keys(control.details.outputs).length > 0) {
                for (var outputKey in control.details.outputs) {
                    if (control.details.outputs.hasOwnProperty(outputKey)) {
                        details[0].commands.push(Commands.format(Commands.RADIO.OUTPUT, outputKey));
                    }
                }
            }

            return details;
        }

        _populateRemoteDetails(control) {
            var details = [{
                commands: []
            }];
            var modes = mapToArray(control.details.modeList, true, true);

            if (Feature.REMOTE_CONTROL_RESET_COMMAND) {
                modes.push({
                    key: RemoteControlEnums.Modes.AllOff.toString(),
                    value: {
                        name: _("controls.lightcontroller.all-off")
                    }
                });
            }

            modes.forEach(function (mode) {
                if (mode.key > 0) {
                    details[0].commands.push(Commands.REMOTE.MODE + "/" + mode.key);
                } else {
                    details[0].commands.push(Commands.REMOTE.RESET);
                }
            });
            return details;
        }

        _populateSaunaDetails(control) {
            var details = [{
                commands: [Commands.SAUNA.ON, Commands.SAUNA.OFF, Commands.SAUNA.FAN_ON, Commands.SAUNA.FAN_OFF, Commands.SAUNA.START_TIMER]
            }];

            if (control.details.hasVaporizer) {
                var modeSection = {
                    commands: []
                };

                var _pushHelper = function _pushHelper(mode) {
                    modeSection.commands.push(Commands.format(Commands.SAUNA.MODE, mode.nr));
                };

                _pushHelper(Controls.SaunaControl.SaunaModes.FINNISH);

                _pushHelper(Controls.SaunaControl.SaunaModes.HERBAL);

                _pushHelper(Controls.SaunaControl.SaunaModes.SOFT_VAPOR_BATH);

                _pushHelper(Controls.SaunaControl.SaunaModes.WARM_AIR_BATH);

                _pushHelper(Controls.SaunaControl.SaunaModes.HUMIDITY_MANUAL);

                _pushHelper(Controls.SaunaControl.SaunaModes.FINNISH_MANUAL);

                _pushHelper(Controls.SaunaControl.SaunaModes.SAUNA_MANUAL);

                details.push(modeSection);
            }

            return details;
        }

        _populateIRoomDetails(control) {
            var timerDefault12 = 12 * 60,
                // 12 HRS
                timerStartCmd = Commands.I_ROOM_CONTROLLER.START_TIMER,
                stateObj = SandboxComponent.getStatesForUUID(control.uuidAction),
                states = stateObj ? stateObj.states : {},
                heating = this.control.isHeating(states.controllerMode),
                timerSection = {
                    title: _("controls.irc.timer"),
                    commands: []
                },
                modesSection = {
                    title: _("controls.irc.mode"),
                    commands: []
                },
                modeCmd = Commands.I_ROOM_CONTROLLER.MODE; // prepare modesSection

            modesSection.commands.push(Commands.format(modeCmd, IRoomControlEnums.IRCMode.FULL_AUTO), Commands.format(modeCmd, IRoomControlEnums.IRCMode.AUTO_COOLING), Commands.format(modeCmd, IRoomControlEnums.IRCMode.AUTO_HEATING)); // Prepare Timer Section

            timerSection.commands.push(Commands.format(timerStartCmd, IRoomControlEnums.IRCTempMode.ECONOMY, timerDefault12), Commands.format(timerStartCmd, IRoomControlEnums.IRCTempMode.PARTY, timerDefault12), Commands.format(timerStartCmd, IRoomControlEnums.IRCTempMode.INCREASED_HEAT, timerDefault12));

            if (heating) {
                timerSection.commands.push(Commands.format(timerStartCmd, IRoomControlEnums.IRCTempMode.COMFORT_HEATING, timerDefault12));
            } else {
                timerSection.commands.push(Commands.format(timerStartCmd, IRoomControlEnums.IRCTempMode.COMFORT_COOLING, timerDefault12));
            }

            timerSection.commands.push(Commands.I_ROOM_CONTROLLER.CANCEL_TIMER);
            return [modesSection, timerSection];
        }

        _populateDaytimerDetails(control) {
            // no override supported - entries that need activation can be prepared for that purpose.
            var details = [{
                commands: [Commands.DAYTIMER.ACTIVATE]
            }];

            if (!control.details.analog) {
                var defaultDuration = 60 * 60,
                    minValue = 60,
                    maxValue = 60 * 60 * 12,
                    stepValue = 60,
                    text = control.details.text ? control.details.text : {
                        on: _("active"),
                        off: _("not-active")
                    };
                var stopOverrideSection = {
                    title: _("controls.daytimer.timer.name"),
                    commands: [Commands.DAYTIMER.CANCEL_TIMER]
                };
                var activeOverrideSection = {
                    commandFormat: Commands.DAYTIMER.START_TIMER_ACTIVE,
                    command: Commands.format(Commands.DAYTIMER.START_TIMER_ACTIVE, defaultDuration),
                    slider: {
                        title: text.on,
                        minValue: minValue,
                        maxValue: maxValue,
                        minIconSrc: Icon.MINUS,
                        maxIconSrc: Icon.PLUS,
                        preventMinIconScale: true,
                        step: stepValue,
                        value: defaultDuration,
                        formatWithHTML: "<v.dur>"
                    }
                };
                var deactiveOverrideSection = {
                    commandFormat: Commands.DAYTIMER.START_TIMER_INACTIVE,
                    command: Commands.format(Commands.DAYTIMER.START_TIMER_INACTIVE, defaultDuration),
                    slider: {
                        title: text.off,
                        minValue: minValue,
                        maxValue: maxValue,
                        minIconSrc: Icon.MINUS,
                        maxIconSrc: Icon.PLUS,
                        preventMinIconScale: true,
                        step: stepValue,
                        value: defaultDuration,
                        formatWithHTML: "<v.dur>"
                    }
                };
                details.push(stopOverrideSection);
                details.push(activeOverrideSection);
                details.push(deactiveOverrideSection);
            }

            return details;
        }

        _populateIRoomV2Details(control) {
            return [{
                title: _("controls.irc.timer"),
                commands: [Commands.format(Commands.I_ROOM_V2_CONTROLLER.OVERRIDE.START, IRoomV2ControlEnums.Mode.COMFORT), Commands.format(Commands.I_ROOM_V2_CONTROLLER.OVERRIDE.START, IRoomV2ControlEnums.Mode.ECO), Commands.format(Commands.I_ROOM_V2_CONTROLLER.OVERRIDE.START, IRoomV2ControlEnums.Mode.ECO_PLUS), Commands.I_ROOM_V2_CONTROLLER.OVERRIDE.STOP]
            }];
        }

        _populatePoolDetails(control) {
            var details = [],
                coverSection = {
                    commands: []
                },
                tempModeSection = {
                    commands: []
                },
                swimMachine = {
                    commands: [],
                    title: _("pool.control.swimming-machine")
                },
                swimSpeed; // tempModeSection

            tempModeSection.title = _("pool.control.temp-control");

            var _pushHelper = function (mode) {
                tempModeSection.commands.push(Commands.format(Commands.POOL.SET_TEMP_MODE, mode));
            };

            _pushHelper(PoolControlEnums.PoolTempMode.OFF);

            _pushHelper(PoolControlEnums.PoolTempMode.FULL_AUTO);

            _pushHelper(PoolControlEnums.PoolTempMode.AUTO_HEATING);

            _pushHelper(PoolControlEnums.PoolTempMode.AUTO_COOLING);

            _pushHelper(PoolControlEnums.PoolTempMode.MAN_HEATING);

            _pushHelper(PoolControlEnums.PoolTempMode.MAN_COOLING);

            details.push(tempModeSection); // cover

            if (control.details.hasCover) {
                coverSection.title = _("pool.control.cover");
                coverSection.commands.push(Commands.POOL.COVER_OPEN);
                coverSection.commands.push(Commands.POOL.COVER_CLOSE);
                details.push(coverSection);
            } // swimming machine


            if (control.details.swimmingMachineType === 1) {
                swimMachine.commands.push(Commands.POOL.SWIMMING_MACHINE_ON);
                swimMachine.commands.push(Commands.POOL.SWIMMING_MACHINE_OFF);
                details.push(swimMachine);
            } else if (control.details.swimmingMachineType === 2) {
                swimMachine.commands.push(Commands.POOL.SWIMMING_MACHINE_OFF);
                details.push(swimMachine);
                swimSpeed = {
                    commandFormat: Commands.POOL.SET_SWIMMING_MACHINE,
                    command: Commands.format(Commands.POOL.SET_SWIMMING_MACHINE, 0.5),
                    slider: {
                        title: _("pool.control.swimmingmachine.intensity"),
                        minValue: 0,
                        maxValue: 1,
                        minIconSrc: Icon.MINUS,
                        maxIconSrc: Icon.PLUS,
                        preventMinIconScale: true,
                        step: 0.05,
                        value: 0.5,
                        format: "%.02f"
                    }
                };
                details.push(swimSpeed);
            }

            return details;
        }

        _populateSliderDetails(control) {
            var value = (control.details.max - control.details.min) / 2,
                states = SandboxComponent.getStatesForUUID(control.uuidAction);

            if (states) {
                value = states.states.pos;
            }

            return [{
                commandFormat: Commands.SLIDER.VALUE,
                command: Commands.format(Commands.SLIDER.VALUE, value),
                slider: {
                    title: _('slider.value'),
                    minValue: control.details.min,
                    maxValue: control.details.max,
                    minIconSrc: Icon.MINUS,
                    maxIconSrc: Icon.PLUS,
                    preventMinIconScale: true,
                    step: control.details.step,
                    value: value,
                    format: control.details.format
                }
            }];
        }

        _populateSwitchDetails(control) {
            return [{
                commands: [Commands.SWITCH.ON, Commands.SWITCH.OFF]
            }];
        }

        _populateTimedSwitchDetails(control) {
            return [{
                commands: [Commands.TIMED_SWITCH.ON, Commands.TIMED_SWITCH.OFF, Commands.TIMED_SWITCH.PULSE]
            }];
        }

        _populateUdlrAnalogDetails(control) {
            var value = (control.details.max - control.details.min) / 2,
                states = SandboxComponent.getStatesForUUID(control.uuidAction),
                isUpDown = control.type === ControlType.UP_DOWN_ANALOG,
                isLeftRight = control.type === ControlType.LEFT_RIGHT_ANALOG,
                leftIconSrc = isUpDown ? Icon.DOWN : isLeftRight ? Icon.LEFT : Icon.MINUS,
                rightIconSrc = isUpDown ? Icon.UP : isLeftRight ? Icon.RIGHT : Icon.PLUS;

            if (states) {
                value = states.states.value;
            }

            return [{
                commandFormat: Commands.UP_DOWN_LEFT_RIGHT.ANALOG.VALUE,
                command: Commands.format(Commands.UP_DOWN_LEFT_RIGHT.ANALOG.VALUE, value),
                slider: {
                    title: _('slider.value'),
                    minValue: control.details.min,
                    maxValue: control.details.max,
                    minIconSrc: leftIconSrc,
                    maxIconSrc: rightIconSrc,
                    preventMinIconScale: true,
                    step: control.details.step,
                    value: value,
                    format: control.details.format
                }
            }];
        }

        _populateUdlrDigitalDetails(control) {
            return [{
                commands: [Commands.UP_DOWN_LEFT_RIGHT.DIGITAL.DOWN_PULSE, Commands.UP_DOWN_LEFT_RIGHT.DIGITAL.UP_PULSE]
            }];
        }

        _populateValueSelectorDetails(control) {
            var states = SandboxComponent.getStatesForUUID(control.uuidAction);

            if (states) {
                return [{
                    commandFormat: Commands.UP_DOWN_LEFT_RIGHT.ANALOG.VALUE,
                    command: Commands.format(Commands.UP_DOWN_LEFT_RIGHT.ANALOG.VALUE, states.states.value),
                    slider: {
                        title: _('slider.value'),
                        minValue: states.states.min,
                        maxValue: states.states.max,
                        minIconSrc: Icon.MINUS,
                        maxIconSrc: Icon.PLUS,
                        preventMinIconScale: true,
                        step: states.states.step,
                        value: states.states.value,
                        format: control.details.format
                    }
                }];
            } else {
                return [];
            }
        }

        _populateMailBoxDetails(control) {
            var stepWidth = 3600 * 24,
                defaultDuration = stepWidth * 7,
                maxDuration = stepWidth * 60;
            return [{
                commands: [Commands.MailBox.CONF_MAIL]
            }, {
                title: _("notifications"),
                commandFormat: Commands.MailBox.NOTIF_DIS,
                command: Commands.format(Commands.MailBox.NOTIF_DIS, defaultDuration),
                slider: {
                    title: _('controls.mailbox.ad.disable-notif-for', {
                        duration: ""
                    }),
                    minValue: stepWidth,
                    maxValue: maxDuration,
                    minIconSrc: Icon.MINUS,
                    maxIconSrc: Icon.PLUS,
                    preventMinIconScale: true,
                    step: stepWidth,
                    value: defaultDuration,
                    format: "%s" // Use %s to directly display the userfriendly value string!

                },
                userfriedlyValue: function (durationSecs) {
                    return LxDate.formatSecondsShort(durationSecs, true, true);
                }
            }, {
                commands: [Commands.MailBox.NOTIF_ENABLE]
            }];
        }

        _populateAalEmergencyAlarmDetails(control) {
            var stepWidth = 3600 * 12,
                defaultDuration = stepWidth * 2,
                maxDuration = stepWidth * 60;
            return [{
                commands: [Commands.AalEmergency.TRIGGER, Commands.AalEmergency.QUIT]
            }, {
                title: _("controls.aalemergency.disable-timer"),
                commandFormat: Commands.AalEmergency.DISABLE,
                command: Commands.format(Commands.AalEmergency.DISABLE, defaultDuration),
                slider: {
                    title: _('controls.aalemergency.disable', {
                        duration: ""
                    }),
                    minValue: stepWidth,
                    maxValue: maxDuration,
                    minIconSrc: Icon.MINUS,
                    maxIconSrc: Icon.PLUS,
                    preventMinIconScale: true,
                    step: stepWidth,
                    value: defaultDuration,
                    format: "%s" // Use %s to directly display the userfriendly value string!

                },
                userfriedlyValue: function (durationSecs) {
                    return LxDate.formatSecondsShort(durationSecs, true, true);
                }
            }, {
                commands: [Commands.AalEmergency.REACTIVATE]
            }];
        }

        _populateSteakDetails(control) {
            var cmds = [{
                    commands: [Commands.Steak.QUIT_ALARM]
                }, {
                    commands: [Commands.Steak.SET_THIS_ACTIVE]
                }, {
                    commands: [Commands.format(Commands.Steak.SET_TOUCH_PROTECTION, 0), Commands.format(Commands.Steak.SET_TOUCH_PROTECTION, 1)]
                }, {
                    commands: [Commands.format(Commands.Steak.SET_DISPLAY_ALWAYS_ON_BAT, 0), Commands.format(Commands.Steak.SET_DISPLAY_ALWAYS_ON_BAT, 1)]
                }, {
                    commands: [Commands.format(Commands.Steak.SET_DISPLAY_ALWAYS_ON_DC, 0), Commands.format(Commands.Steak.SET_DISPLAY_ALWAYS_ON_DC, 1)]
                }, {
                    commands: [Commands.Steak.START_TIMER, Commands.Steak.STOP_TIMER]
                }],
                states = SandboxComponent.getStatesForUUID(control.uuidAction);

            if (states) {
                cmds.push({
                    commandFormat: Commands.Steak.START_TIMER,
                    command: Commands.format(Commands.Steak.START_TIMER, states.states.timerInfo.duration),
                    slider: {
                        title: _('steak.timer.start'),
                        minValue: SteakControlEnums.TIMER_BOUNDRY.MIN / 1000,
                        maxValue: SteakControlEnums.TIMER_BOUNDRY.MAX / 1000,
                        minIconSrc: Icon.MINUS,
                        maxIconSrc: Icon.PLUS,
                        preventMinIconScale: true,
                        step: 1,
                        // 1 Seconds steps
                        value: states.states.timerInfo.duration,
                        format: "%s" // Use %s to directly display the userfriendly value string!

                    },
                    userfriedlyValue: function (msDuration) {
                        return LxDate.formatSecondsIntoDigits(msDuration, true, true);
                    }
                });
            }

            return cmds;
        }

        _populateWindowDetails(control) {
            return [{
                commands: [Commands.WINDOW.OPEN.JOG_ON, Commands.WINDOW.OPEN.JOG_OFF]
            }, {
                commands: [Commands.WINDOW.OPEN.FULL]
            }, {
                commands: [Commands.WINDOW.CLOSE.JOG_ON, Commands.WINDOW.CLOSE.JOG_OFF]
            }, {
                commands: [Commands.WINDOW.CLOSE.FULL]
            }, {
                commands: [Commands.WINDOW.MOVE_TO]
            }];
        }

        _populateTextInputDetails(control) {
            var states = SandboxComponent.getStatesForUUID(control.uuidAction);
            return [{
                commandFormat: Commands.TEXT_INPUT.SET_VALUE,
                command: Commands.format(Commands.TEXT_INPUT.SET_VALUE, states.states.text),
                title: _("controls.textinput.autopilot.title.change-text-to"),
                input: {
                    autoFocus: true
                }
            }];
        }

        _setValueForSlider(value, sectionIdx, rowIdx) {
            var section = this.tableContent.sections[sectionIdx];
            section.command = Commands.format(section.commandFormat, value);
        }

        _setValueForInput(value, sectionIdx, rowIdx, isValid) {
            var section = this.tableContent.sections[sectionIdx];

            if (isValid) {
                section.inputValue = value;
            } else {
                delete section.inputValue;
            }

            section.command = Commands.format(section.commandFormat, value);
        }

        _createCommandText(control, cmd) {
            var commandParts = [control.uuidAction],
                cmdTxt = cmd;
            commandParts = commandParts.concat(cmd.split("/"));

            try {
                cmdTxt = createCmdText(control, commandParts); // The "Touch & Grill" has a command text that includes the string "Touch & Grill "
                // that shouldn't be removed!

                if (control.type !== ControlType.STEAK) {
                    cmdTxt = cmdTxt.replace(control.name + ": ", "") // also removing the control name!
                        .replace(control.name + " ", "");
                }
            } catch (e) {
                console.error(e.stack);
            }

            return cmdTxt;
        }

    };
});
