'use strict';

window.GUI = function (GUI) {
    class QuickGroupSelectorScreen extends GUI.GroupSelectorScreen {
        constructor(details) {
            super(...arguments);
            this.isQuickGroupSelector = true;
        }

        getURL() {
            return "QuickGroupSelector";
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

    }

    GUI.QuickGroupSelectorScreen = QuickGroupSelectorScreen;
    return GUI;
}(window.GUI || {});
