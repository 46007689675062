import {createStateCell} from "../../UtilityComp/utilities/helper";

export default class HeatmixerControlStatesSrc {
    static getStateSections(control, states) {
        return [
            {
                rows: [
                    createStateCell(_("controls.heatmixer.identifier.actual"), lxFormat(control.VALUE_FORMAT, states.actualValue)),
                    createStateCell(_("controls.heatmixer.identifier.target"), lxFormat(control.VALUE_FORMAT, states.targetValue))
                ]
            }
        ]
    }
}