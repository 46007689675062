import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Fan(props) {
  return (
    <Svg isreact="true"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M2.887 7.328c1.215.342 3.554 1.776 4.719 2.285A4.98 4.98 0 007 12c0 .959.27 1.856.739 2.617-1.129.616-3.441 2.178-4.652 2.641C1.582 17.835 0 16.697 0 15.023V9.625c0-1.58 1.422-2.709 2.887-2.297zM12 7c-.959 0-1.856.27-2.617.739-.617-1.129-2.178-3.441-2.641-4.652C6.165 1.582 7.303 0 8.977 0h5.398c1.58 0 2.709 1.422 2.297 2.887-.342 1.215-1.776 3.554-2.285 4.719A4.98 4.98 0 0012 7zM21.113 16.672c-1.215-.342-3.554-1.776-4.718-2.285A4.98 4.98 0 0017 12c0-.959-.27-1.856-.739-2.617 1.129-.617 3.441-2.178 4.652-2.641C22.418 6.165 24 7.303 24 8.977v5.398c0 1.58-1.422 2.709-2.887 2.297zM7.328 21.113c.342-1.215 1.776-3.554 2.285-4.718A4.98 4.98 0 0012 17c.959 0 1.856-.27 2.617-.739.616 1.129 2.178 3.441 2.641 4.652.577 1.505-.561 3.087-2.235 3.087H9.625c-1.58 0-2.709-1.422-2.297-2.887z"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.528 8.855A3.98 3.98 0 0112 8a3.98 3.98 0 012.315.737c.313.223.592.49.83.79A3.98 3.98 0 0116 12c0 .864-.273 1.662-.738 2.315a4.025 4.025 0 01-.79.83A3.98 3.98 0 0112 16a3.979 3.979 0 01-2.315-.738 4.024 4.024 0 01-.83-.79A3.98 3.98 0 018 12a3.98 3.98 0 011.528-3.145zM9.5 12a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0z"
      />
      <Path d="M10.5 12a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"  />
    </Svg>
  )
}

export default Fan
