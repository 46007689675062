import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"

function ExpandArrowsCircled(props) {
    return (
        <Svg isreact="true" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Circle cx={15} cy={15} r={15} fill={props.fill}/>
            <Path
                d="M20.5 14.875a.875.875 0 01-.875-.875v-2.388L16.737 14.5a.875.875 0 11-1.237-1.237l2.888-2.888H16a.875.875 0 010-1.75h4.5c.483 0 .875.392.875.875V14a.875.875 0 01-.875.875zM9.5 15.125c.483 0 .875.392.875.875v2.388l2.888-2.888a.875.875 0 111.237 1.237l-2.888 2.888H14a.875.875 0 010 1.75H9.5a.875.875 0 01-.875-.875V16c0-.483.392-.875.875-.875z"
                fill="#fff"
            />
        </Svg>
    )
}

export default ExpandArrowsCircled
