import globalStyles from "GlobalStyles";
import {Icons} from "IconLib";
import {PeerState} from "./TrustModels";
import React from "react";
import {LxReactPressable} from "LxComponents";

class TrustTableViewHelper {
    static getCellForUser (user, trustMember, onSelected, subTitle) {
        const cellProps = {
            type: user.locked ? GUI.TableViewV2.CellType.CHECKABLE_BUTTON : GUI.TableViewV2.CellType.CHECKABLE,
            content: {
                title: user.name,
                subtitle: subTitle,
                selected: user.used,
                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE,
                user: user,
                trustMember: trustMember,
                disabled: user.locked
            },
            didCheckCell: (cell, section, row, tableView, selected) => {
                if (!user.locked) {
                    onSelected(selected)
                }
            },
            didRequestCheckingCell: function didRequestCheckingCell(cell, section, row, tableView) {
                if (user.locked) {
                    return Q(false);
                } else if (user.used) {
                    return NavigationComp.showPopup({
                        message: _("usermanagement.trust.user.delete-warning"),
                        buttonOk: true
                    });
                } else {
                    return Q(true);
                }
            }
        };

        if (user.locked) {
            cellProps.content.mainRightContent = [{
                comp: LxReactPressable,
                props: {
                    key: user.uuid + "info",
                    pkey: user.uuid + "-info",
                    onPress: () => {
                        if (user.locked) {
                            NavigationComp.showPopup({
                                message: _("usermanagement.trust.user.inherent"),
                                buttonOk: true
                            });
                        }
                    },
                    children: <Icons.InfoCircled width={30} height={30} fill={globalStyles.colors.text.primary} key={user.uuid + "-info-icon"}/>
                }
            }]
        }

        return cellProps;
    }

    static getCellForPeer(peerInfo, peerName, onSelected) {
        const peerRow = {
            content: {
                title: peerName,
                subTitleStyle: globalStyles.textStyles.footNote.default,
                disclosureTextProps: {
                    style: {...styles.disclosureText}
                },
                disclosureIcon: true
            },
            action: onSelected
        }

        switch (peerInfo.state) {
            case PeerState.New:
            case PeerState.Waiting:
            case PeerState.Pending:
                peerRow.content.disclosureText = _("loading")
                break;
            case PeerState.Done:
                const userText = peerInfo.userCount === 1 ? _("user") : _("users");
                peerRow.content.disclosureText = peerInfo.userCount + " " + userText
                break;
            case PeerState.Failed:
                peerRow.content.disclosureText =  _("not-reachable")
                peerRow.content.disclosureTextProps.style.color = globalStyles.colors.red
        }

        return peerRow;
    }

    static getDummyCell(config = {showSubTitle: true}) {
        const cellConfig = {
            content: {
                title: "Dummy",
                leftIconSrc: Icons.Air,
                isLoading: true
            }
        }
        if (config.showSubTitle) {
            cellConfig.content.subtitle = "Dummy"
        }
        return cellConfig
    }

    static getDummyContent(numberOfCells = 5, cellConfig) {
        let dummyData = []

        for (let i = 0; i <= numberOfCells; i++) {
            dummyData.push(TrustTableViewHelper.getDummyCell(cellConfig))
        }

        return {
            headerTitle: _('miniserver.user'),
            rows: dummyData
        }
    }
}

const styles = {
    disclosureText: {
        ...globalStyles.textStyles.footNote.default,
        color: globalStyles.colors.text.secondary
    }
}

export default TrustTableViewHelper
