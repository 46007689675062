'use strict';

define(['ControlActionCellsScreen', 'SteakControlEnums', 'SteakTargetTempSelectorScreen', 'SteakRenameScreen', 'SteakControlContentSettings', "SteakTimeSelectorScreen"], function (ControlActionCellsScreen, SteakControlEnums, SteakTargetTempSelectorScreen, SteakRenameScreen, SteakControlContentSettings) {
    return class SteakControlContent extends ControlActionCellsScreen {
        constructor() {
            super(...arguments);
        }

        shouldDisplayStateIcons(states) {
            return !states.isActive || states.deviceState === SteakControlEnums.DeviceStates.OFFLINE || states.deviceState === SteakControlEnums.DeviceStates.SLEEPING;
        }

        getStateIDsToIgnore() {
            return ["timerRemaining"]; // the timer is handled by the cell itself
        }

        /*getStateRows(states) {
            if (this.shouldDisplayStateIcons(states)) {
                return;
            }

            var rows = [],
                orderedSensors = this._getOrderedSensors(states); // Connected sensors


            orderedSensors.connected.forEach(function (sensor) {
                rows.push(this._getSensorCell(states, sensor));
            }.bind(this)); // Timer

            rows.push(this._getTimerCell(states)); // Disconnected sensors

            orderedSensors.disconnected.forEach(function (sensor) {
                rows.push(this._getSensorCell(states, sensor));
            }.bind(this)); // Every SteakControl has a Tracker, show it!

            rows.push(this.getSubmenuCell(_('steak.tracker.last'), this._showTracker.bind(this))); // STATISTIC

            if (!!this.control.statistic) {
                this.getStatisticCells().forEach(function (statCell) {
                    rows.push(statCell);
                });
            }

            return rows;
        } */

        getAdditionalSection(states) {
            var additionalSection; // Do not show the following elements

            if (!states.isActive || states.deviceState === SteakControlEnums.DeviceStates.OFFLINE) {
                return null;
            } // Don't call the base, we already have a statistic cell in the section above


            additionalSection = {
                headerTitle: _("steak.product-name"),
                rows: []
            }; // Check if there are any available controls, we only have to display the cell if we have an option,
            // of locations

            if (this.control.getAvailableControls().length > 1 && states.isActive) {
                additionalSection.rows.push(this.getSubmenuCell(_('steak.location'), this._openLocationSelectorScreen.bind(this), null, this.control.getActiveControl(states).name));
            }

            if (Feature.STEAK_BATTERY_STATE) {
                additionalSection.rows.push(this._getBatterySocCell(states));
            } // Every SteakControl has Settings


            additionalSection.rows.push(this.getSubmenuCell(_('settings'), this._openSettings.bind(this)));
            return additionalSection;
        }

        // Override to directly be able to select the first connected sensor
       /* getStatisticCells() {
            var states = this.control.getStates(),
                orderedSensors = this._getOrderedSensors(states),
                statisticIdx = 0;

            if (orderedSensors.connected.length > 0) {
                statisticIdx = orderedSensors.connected[0].idx;
            }

            return [this.getSubmenuCell(_("statistics"), this.showStatisticForOutput.bind(this, statisticIdx))];
        } */

        // Overwrite this method to enable the disclosureText
        getSubmenuCell(title, action, iconSrc, disclosureText) {
            var cell = super.getSubmenuCell(...arguments);

            if (disclosureText !== null) {
                cell.content.disclosureText = disclosureText;
            }

            return cell;
        }

        _getBatterySocCell(states) {
            var socIcon = this._getSocIcon(states),
                isCharging = states.powerMode === SteakControlEnums.PowerMode.DC;

            return {
                content: {
                    title: isCharging ? _("steak.settings.dc-powered") : _("steak.settings.battery-powered"),
                    rightIconSrc: socIcon.iconSrc,
                    rightIconColor: socIcon.iconColor
                }
            };
        }

        //Delegate
        onChangeDescription(sensor) {
            this.ViewController.showState(SteakControlEnums.ScreenStates.RenameScreen, null, {
                sensor: sensor
            });
        }

        onTargetTempChange(sensor) {
            this.ViewController.showState(SteakControlEnums.ScreenStates.TargetTempSelectorScreen, null, {
                sensor: sensor
            });
        }

        onTimerStart() {
            this.sendCommand(Commands.Steak.START_TIMER);
        }

        onTimerStop() {
            var states = this.control.getStates();

            if (states.timerRemaining === 0 && states && states.timerInfo.active) {
                this.sendCommand(Commands.Steak.QUIT_ALARM);
            } else {
                this.sendCommand(Commands.Steak.STOP_TIMER);
            }
        }

        // We can only allow the user to set the time if no timer is active
        onTimerSetSelected() {
            var states = this.control.getStates();

            if (!states.timerInfo.active) {
                this.ViewController.showState(SteakControlEnums.ScreenStates.TimeSelectorScreen, null, {
                    duration: states.timerInfo.duration
                });
            }
        }

        // Private Section

        /**
         * Returns an object with the sensors ordered in the correct category (connected and disconnected)
         * @param states
         * @return {{connected: Array, disconnected: Array}}
         * @private
         */
        /*_getOrderedSensors(states) {
            var orderedSensors = {
                connected: [],
                disconnected: []
            };
            states.sensors.forEach(function (sensor, idx) {
                if (sensor.connected) {
                    orderedSensors.connected.push(sensor);
                } else {
                    orderedSensors.disconnected.push(sensor);
                }
            });
            return orderedSensors;
        }

        _getSensorCell(states, sensor) {
            if (sensor.connected) {
                return {
                    content: {
                        sensor: sensor,
                        sensorAlarmText: states.sensorAlarmTexts[sensor.idx],
                        sensorColor: this.control.getColorForSensorAtIndex(sensor.idx, states),
                        currentTemp: states.currentTemperatures[sensor.idx],
                        clickable: true
                    },
                    type: GUI.TableViewV2.CellType.Special.STEAK_SENSOR_CELL,
                    action: this._didSelectSensorCell.bind(this, sensor)
                };
            } else {
                return {
                    content: {
                        title: _("steak.sensor.not-connected", {
                            sensorName: sensor.name
                        }),
                        leftIconSrc: Icon.CIRCLE,
                        leftIconColor: this.control.getColorForSensorAtIndex(sensor.idx, states),
                        clickable: true
                    },
                    action: this._didSelectSensorCell.bind(this, sensor)
                };
            }
        }

        _getTimerCell(states) {
            return {
                content: {
                    timerInfo: states.timerInfo,
                    timerAlarm: states.timerAlarm,
                    remainingTime: states.timerRemaining,
                    uuidAction: this.control.uuidAction
                },
                type: GUI.TableViewV2.CellType.Special.STEAK_TIMER_CELL,
                action: this._didSelectTimerCell.bind(this)
            };
        } */

        _openSettings() {
            this.ViewController.showState(SteakControlEnums.ScreenStates.SettingsScreen);
        }

        /**
         * Opens the location selection screen, also handles the selection of such a location
         * @private
         */
        _openLocationSelectorScreen() {
            var def = Q.defer(),
                details = {
                    options: this._createAvailableControlsSelectorOptions(),
                    title: _('steak.location'),
                    footerTitle: _("steak.location.footer", {
                        productName: _('steak.product-name')
                    }),
                    mode: GUI.SelectorScreenMode.QUICK,
                    deferred: def
                };
            this.ViewController.showState(ScreenState.SelectorScreen, null, details);
            def.promise.then(function (result) {
                this._didSelectLocation(result[0]);
            }.bind(this));
        }

        _didSelectLocation(result) {
            var states = this.control.getStates(),
                currentSelectionIdx = states.activeControl; // only send the command if another location have been selected

            if (result.row !== currentSelectionIdx) {
                this.sendCommand(Commands.format(Commands.Steak.SET_ACTIVE, result.row));
            }
        }

        /**
         * Creates the option cells for the SelectorScreen
         * @returns {Array}
         * @private
         */
        _createAvailableControlsSelectorOptions() {
            var options = [],
                states = this.control.getStates(),
                currentSelectionIdx = states.activeControl;
            this.control.getAvailableControls().forEach(function (controlName, idx) {
                options.push({
                    title: controlName.name,
                    clickable: true,
                    item: controlName.name,
                    selected: currentSelectionIdx === idx
                });
            }.bind(this));
            return options;
        }

        /**
         * Shows the tracker of the control
         * @private
         */
        /*_showTracker() {
            var subControls = this.control.subControls,
                trackerUUID = Object.keys(subControls)[0],
                trackerControl = this.control.subControls[trackerUUID];
            NavigationComp.showControlContent(trackerControl);
        }*/

        /**
         * Handles the selection of a sensorCell
         * Default behaviour: Open the statistics of the selected sensor
         * If an alarm is active for the specific sensor the alarm will be acknowledged
         * @param sensor
         * @private
         */
        /*_didSelectSensorCell(sensor) {
            var states = this.control.getStates();

            if (states.sensorAlarmTexts[sensor.idx].ringing) {
                this.sendCommand(Commands.Steak.QUIT_ALARM);
            } else {
                this.showStatisticForOutput(sensor.idx);
            }
        } */

        /**
         * Handles the selection of the timerCell
         * Default behaviour: Open the time selector screen
         * If an timer is active and its remaining time is 0 the timer will be stopped
         * @private
         */
       /* _didSelectTimerCell() {
            var states = this.control.getStates();

            if (states && states.timerRemaining === 0 && states.timerInfo.active) {
                this.onTimerStop();
            } else {
                this.onTimerSetSelected();
            }
        } */

        /**
         * Gets the correct icon and color for the current battery State Of Charge
         * @param states
         * @return {{iconSrc: string, iconColor: *}}
         * @private
         */
        _getSocIcon(states) {
            var socIconSrc,
                socIconColor = window.Styles.colors.green,
                isCharging = states.powerMode === SteakControlEnums.PowerMode.DC;

            if (!isCharging) {
                socIconColor = Color.WHITE;
            }

            if (states.batteryPercentage.isBetweenOrEqual(75, 100)) {
                if (isCharging) {
                    socIconSrc = Icon.Steak.BATTERY_SOC.FULL_CHARGING;
                } else {
                    socIconSrc = Icon.Steak.BATTERY_SOC.FULL;
                }
            } else if (states.batteryPercentage.isBetweenOrEqual(50, 74)) {
                if (isCharging) {
                    socIconSrc = Icon.Steak.BATTERY_SOC.NEARLY_FULL_CHARGING;
                } else {
                    socIconSrc = Icon.Steak.BATTERY_SOC.NEARLY_FULL;
                }
            } else if (states.batteryPercentage.isBetweenOrEqual(25, 49)) {
                if (isCharging) {
                    socIconSrc = Icon.Steak.BATTERY_SOC.HALF_FULL_CHARGING;
                } else {
                    socIconSrc = Icon.Steak.BATTERY_SOC.HALF_FULL;
                }
            } else if (states.batteryPercentage.isBetweenOrEqual(SteakControlEnums.LOW_BAT_WARNING_PERCENTAGE, 24)) {
                if (isCharging) {
                    socIconSrc = Icon.Steak.BATTERY_SOC.NEARLY_EMPTY_CHARGING;
                } else {
                    socIconSrc = Icon.Steak.BATTERY_SOC.NEARLY_EMPTY;
                }
            } else {
                if (isCharging) {
                    socIconSrc = Icon.Steak.BATTERY_SOC.EMPTY_CHARGING;
                } else {
                    socIconSrc = Icon.Steak.BATTERY_SOC.EMPTY;
                }

                socIconColor = window.Styles.colors.red;
            }

            return {
                iconSrc: socIconSrc,
                iconColor: socIconColor
            };
        }

    };
});
