'use strict';
/**
 * This cell is used to display media items from the Music Server.
 *
 * @param [hasDetailButton]     if true, there will be a "detail"- (or "more"-)Button on the right side
 * @param [coverurl]            a client might provide a cover for this cell. However this is unused as of now because
 *                              it severely slows down scrolling, even if updated inside extendContent, which is performed
 *                              several seconds after the view did stop scrolling.
 */

window.GUI = function (GUI) {
    GUI.ReusingListView.CellType.MEDIA = "MediaListCell";
    GUI.LVContent.Media = {
        DETAIL: "hasDetailButton",
        COVER: MediaEnum.Event.COVER,
        PLAYING: "isPlaying" // true if this song is currently playing.

    };

    var Template = function () {
        var getLeftDefaultIcons = function getLeftDefaultIcons() {
            var icons = '',
                path,
                fileId,
                fileIdKeys = Object.keys(MediaEnum.FileType),
                classList = "left__icon cell__icon left__file-type-icon left__file-type-icon-";
            fileIdKeys.forEach(function (fileIdKey) {
                fileId = MediaEnum.FileType[fileIdKey];
                path = MediaServerComp.defaultIconForType(fileId);
                icons += ImageBox.getResourceImageWithClasses(path, classList + fileId);
            });
            return icons;
        };

        var getRightDefaultIcon = function getRightDefaultIcon() {
            return ImageBox.getResourceImageWithClasses(Icon.AudioZone.MORE, "right__icon cell__icon");
        };

        return {
            getLeftDefaultIcons: getLeftDefaultIcons,
            getRightDefaultIcon: getRightDefaultIcon
        };
    }();

    class GeneralCell extends GUI.ReusingListView.Cells.GeneralCell {
        constructor(delegate, cellType, listView) {
            super(...arguments);
            this.element.addClass("media-list-cell");
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function (res) {
                // insert default icons for all file types, that way they only need to be shown or hidden, the dom does
                // not need to be modified - which saves time, especially on safari, which is slow when it comes to
                // adding/removing svgs.
                this.preparedTypeIcons = $(GeneralCell.Template.getLeftDefaultIcons()); // the left icon is always shown in media cells.

                this.elements.leftCntr.show(); // insert a right side icon by default.

                this.elements.rightCntr.append(GeneralCell.Template.getRightDefaultIcon());
            }.bind(this));
        }

        /**
         * Called when a cell is being initialized with content, or when it is reused. A view must
         * @param content
         * @param scrolling  whether or not the cell is being updated during a scroll
         */
        updateContent(content, scrolling) {
            // as we're not using the cover right now, we might aswell remove it from content.
            if (content[GUI.LVContent.Media.COVER] === "") {
                delete content[GUI.LVContent.Media.COVER];
            } // ensure a proper title & subtitle are shown, if not provided.


            if (!content.hasOwnProperty(GUI.LVContent.TITLE)) {
                var obj = MediaServerComp.getTitleSubtitleForItem(content);
                content[GUI.LVContent.TITLE] = obj.title;
                content[GUI.LVContent.SUBTITLE] = obj.subtitle;
            } // this just comes handy when looking for errors inside the log.


            this.name = content[GUI.LVContent.ROW] + ": " + content[GUI.LVContent.TITLE];
            super.updateContent(...arguments);
            var isPlaying = this.cAttr(GUI.LVContent.Media.PLAYING, false); // e.g. in the queue the current item is to be adopted.

            GUI.animationHandler.toggleCssClass(this.getElement(), "media-list-cell--playing", isPlaying);
        }

        /**
         * The icons for the different file types already have been prepared. If they are not in the dom, they will
         * be inserted, otherwise it's just show & hide.
         * Covers are not supported as of now.
         */
        updateLeftIcon() {
            var currFileType = this.cAttr(MediaEnum.Event.FILE_TYPE, 0),
                i,
                classList,
                lookupName = "left__file-type-icon-" + currFileType; // check if the leftCntr already contains an icon for the current file type.

            if (this.elements.leftCntr.find("." + lookupName).length === 0) {
                // if not, insert it.
                this.elements.leftCntr.append(this.preparedTypeIcons[currFileType]);
                this.fileTypeIcons = this.elements.leftCntr.find(".left__file-type-icon");
            } // hide fileTypeIcons that are not needed right now, show the one that is.


            for (i = 0; i < this.fileTypeIcons.length; i++) {
                classList = this.fileTypeIcons[i].classList;

                if (classList[classList.length - 1] === lookupName) {
                    $(this.fileTypeIcons[i]).toggle(true);
                } else {
                    $(this.fileTypeIcons[i]).toggle(false);
                }
            }
        }

        /**
         * The right icon, which is the detail button, is only needed if the content says so and the editMode isn't
         * active.
         */
        updateRightIcon() {
            // don't show details if the edit mode is active!
            var hasDetails = !!this.content[GUI.LVContent.Media.DETAIL] && !this.isInEditMode();
            this.elements.rightCntr.toggle(hasDetails); // also the button needs to en- or disabled. in viewDidLoad of the baseClass it's disabled by default.

            this.rightButton.setEnabled(hasDetails);
        }

    }

    GUI.ReusingListView.Cells.MediaListCell = GeneralCell;
    return GUI;
}(window.GUI || {});
