import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import CalendarStyleAdjust from './CalendarStyleAdjust';
import {Calendar as CalendarDatePicker} from 'react-modern-calendar-datepicker';
import { Portal } from 'react-native-portalize';
import { View, TouchableOpacity } from "react-native";
import { useMemo } from "react";
import {useHeaderHeight} from "@react-navigation/elements";

const myCustomLocale = { // TODO: does the rest need a translation --> check customLocale to see which parts are already translated.
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    weekDays: [
      {
        name: 'Monday',
        short: 'MON',
      },
      {
        name: 'Tuesday',
        short: 'TUE',
      },
      {
        name: 'Wednesday',
        short: 'WED',
      },
      {
        name: 'Thursday',
        short: 'THU',
      },
      {
        name: 'Friday',
        short: 'FRI',
      },
      {
        name: 'Saturday',
        short: 'SAT',
        isWeekend: true,
      },
      {
        name: 'Sunday',
        short: 'SUN',
        isWeekend: true,
      },
    ],
    weekStartingIndex: 6,
    getToday(gregorainTodayObject) {
      return gregorainTodayObject;
    },
    toNativeDate(date) {
      return new Date(date.year, date.month - 1, date.day);
    },
    getMonthLength(date) {
      return new Date(date.year, date.month, 0).getDate();
    },
    transformDigit(digit) {
      return digit;
    },
    nextMonth: 'Next Month',
    previousMonth: 'Previous Month',
    openMonthSelector: 'Open Month Selector',
    openYearSelector: 'Open Year Selector',
    closeMonthSelector: 'Close Month Selector',
    closeYearSelector: 'Close Year Selector',
    from: 'from',
    to: 'to',
    defaultPlaceholder: 'Select...',
    digitSeparator: ',',
    yearLetterSkip: 0,
    isRtl: false,
};

export default function Calendar({localeAdjustment, onCanceled = () => {}, ...props}) {
    const headerHeight = useHeaderHeight();

    const customLocale = useMemo(() => {
        return {
            ...myCustomLocale,
            months: [
                _("mobiscroll.january"),
                _("mobiscroll.february"),
                _("mobiscroll.march"),
                _("mobiscroll.april"),
                _("mobiscroll.may"),
                _("mobiscroll.june"),
                _("mobiscroll.july"),
                _("mobiscroll.august"),
                _("mobiscroll.september"),
                _("mobiscroll.october"),
                _("mobiscroll.november"),
                _("mobiscroll.december")
            ],
            weekDays: [
                {
                    name: _("mobiscroll.monday"),
                    short: _("mobiscroll.monday-short"),
                },
                {
                    name: _("mobiscroll.tuesday"),
                    short: _("mobiscroll.tuesday-short"),
                },
                {
                    name: _("mobiscroll.wednesday"),
                    short: _("mobiscroll.wednesday-short"),
                },
                {
                    name: _("mobiscroll.thursday"),
                    short: _("mobiscroll.thursday-short"),
                },
                {
                    name: _("mobiscroll.friday"),
                    short: _("mobiscroll.friday-short"),
                },
                {
                    name: _("mobiscroll.saturday"),
                    short: _("mobiscroll.saturday-short"),
                    isWeekend: true,
                },
                {
                    name: _("mobiscroll.sunday"),
                    short: _("mobiscroll.sunday-short"),
                    isWeekend: true,
                },
            ]
        }
    }, []);

    return (
        <Portal>
            <CalendarStyleAdjust/>
            <TouchableOpacity
                activeOpacity={1}
                style={{ position: 'absolute', left: 0, top: 0, height: '100%', width: '100%', background: 'rgba(0,0,0,0.6)' }}
                onPress={onCanceled}
            />
            <View style={{position: "relative", top: (headerHeight + 120), height: "100%", alignItems: "center"}} pointerEvents="box-none" >
              <CalendarDatePicker
                  locale={Object.assign({}, customLocale, localeAdjustment)}
                  {...props}
              />
            </View>
        </Portal>
    )
}
