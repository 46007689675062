import {createStateCell} from "../../UtilityComp/utilities/helper";
import {BATTERY_FORMAT, ENERGY_FORMAT, ScreenState, VALUE_FORMAT} from "./froniusControlEnums";

export default class FroniusControlStatesSrc {
    static getStateSections(control, states) {
        let sections = [],
            section = {},
            rows = [],
            value,
            title,
            subtitle = null;

        if (states.hasEnergyMeter && states.hasProduction) {
            rows.push(this.getOverviewRow(control));
        } else {
            title = _("controls.fronius.production.current");
            value = lxFormat(VALUE_FORMAT, states.currProduction);
            rows.push(createStateCell(title, value));
            title = _("controls.fronius.production.total");
            value = lxFormat(ENERGY_FORMAT, states.productionTotal);
            rows.push(createStateCell(title, value));

            if (states.hasBattery) {
                title = _("controls.energy-monitor.battery");

                if (states.currBattery < 0) {
                    subtitle = lxFormat(BATTERY_FORMAT, states.stateOfCharge);
                    subtitle = _("controls.energy-monitor.charging-battery", {
                        charge: subtitle
                    });
                    value = lxFormat(VALUE_FORMAT, states.currBattery * -1);
                } else if (states.currBattery > 0) {
                    subtitle = lxFormat(BATTERY_FORMAT, states.stateOfCharge);
                    subtitle = _("controls.energy-monitor.using-battery", {
                        charge: subtitle
                    });
                    value = lxFormat(VALUE_FORMAT, states.currBattery);
                } else {
                    title = _("controls.energy-monitor.battery", {
                        charge: lxFormat(BATTERY_FORMAT, states.stateOfCharge)
                    });
                }

                rows.push(createStateCell(title, value, null, subtitle));
            }
        }

        section.rows = rows;
        sections.push(section);

        return sections;
    }

    static getOverviewRow(control) {
        return {
            content: {
                title: _("controls.fronius.overview"),
                clickable: true,
                rightIconSrc: Icon.DISCLOSURE
            },
            action: this.showFroniusOverview.bind(this, control)
        };
    }

    static showFroniusOverview(control) {
        NavigationComp.showState(ScreenState.OVERVIEW, { control }, AnimationType.HD_OVERLAY)
    }
}