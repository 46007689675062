'use strict';

define("RemoteControl", [
    "Control",
    "RemoteControlEnums",
    "IconLib"
], function (
    Control,
    RemoteControlEnums,
    {
        default: Icons
    }
) {
    return class RemoteControl extends Control {
        constructor() {
            super(...arguments);
            this.modeEntries = mapToArray(this.details.modeList, true, true);

            if (Feature.REMOTE_CONTROL_RESET_COMMAND) {
                this.modeEntries.push({
                    key: RemoteControlEnums.Modes.AllOff.toString(),
                    value: {
                        name: _("controls.lightcontroller.all-off")
                    }
                });
            }
        }

        getIcon() {
            return Icon.Remote.REMOTE;
        }

        specificCreateCmdText(cmdParts) {
            var text,
                cfg = {
                    name: this.name
                };

            if (cmdParts[1] === Commands.REMOTE.MODE) {
                var modeKey = cmdParts[2];
                cfg.mode = this.details.modeList[modeKey].name;
                text = _("cmdtext.remote.mode", cfg);
            } else {
                cfg.button = cmdParts[1];
                text = _("cmdtext.remote.button", cfg);
            }

            return text;
        }
        /**
         * Occasionally the basic states required for cards aren't sufficient (e.g. a remote control has needsWaiting,
         * which is providing info on wether or not to show the butttons)
         * @returns {*[]}
         */
        getAdditionalCardStateNames() {
            return [ "needsWaiting", "activeMode" ];
        }

        getButton0(states) {
            var button = null;

            if (this._volumeControlAllowed(states)) {
                button = {
                    iconSrc: Icon.PLUS,
                    reactIcon: Icons.VolPlus,
                    command: {
                        tap: [Commands.REMOTE.VOL_PLUS, Commands.REMOTE.VOL_PLUS_OFF],
                        hit: Commands.REMOTE.VOL_PLUS,
                        tick: Commands.REMOTE.VOL_PLUS,
                        release: Commands.REMOTE.VOL_PLUS_OFF // There mustn't be any double-tap features via app. T5/V+/V- via block inputs is okay

                    }
                };
            }

            return button;
        }

        getButton1(states) {
            var button = null;

            if (this._volumeControlAllowed(states)) {
                button = {
                    iconSrc: Icon.MINUS,
                    reactIcon: Icons.VolMinus,
                    command: {
                        tap: [Commands.REMOTE.VOL_MINUS, Commands.REMOTE.VOL_MINUS_OFF],
                        hit: Commands.REMOTE.VOL_MINUS,
                        tick: Commands.REMOTE.VOL_MINUS,
                        release: Commands.REMOTE.VOL_MINUS_OFF // There mustn't be any double-tap features via app. T5/V+/V- via block inputs is okay

                    }
                };
            }

            return button;
        }

        getModeName(modeNr) {
            var states = this.getStates();

            if (states.hasActiveMode) {
                return this.details.modeList[states.activeMode].name;
            } else {
                return _("controls.lightcontroller.all-off");
            }
        }

        /**
         * Returns an array of object with at least a "name" and (int)"id" property
         * @param detailsKey
         * @return {*[]}
         */
        getAutomaticDesignerDetailObjectsFromDetailsKey(detailsKey) {
            var wantedObj;

            switch (detailsKey) {
                case "modeList":
                    wantedObj = Object.values(this.modeEntries).map(function (mode) {
                        return {
                            id: parseInt(mode.key),
                            name: mode.value.name
                        };
                    }.bind(this));
                    break;

                default:
                    wantedObj = super.getAutomaticDesignerDetailObjectsFromDetailsKey(...arguments);
            }

            return wantedObj;
        }

        /**
         * Volume control is only enabled when a mode is active. Otherwise no buttons are to be shown.
         * @param states
         * @return {*|boolean}
         * @private
         */
        _volumeControlAllowed(states) {
            return states.activeMode && !states.needsWaiting;
        }

    };
});
