'use strict';
/*
--------------------------------------------------------------------
|  Title                                                            |
|                                                                   |
|   (-)                          value                        (+)   | <-- lxValueSelectionView
|                                                                   |
|                            actionButton                           |
--------------------------------------------------------------------

 content:
    [title]             the title visible on the upper left side
    [actionButton]
        [color]         the color of the text in the action button
        [title]         the title of the action button
        [actionFn]      the fn to call when the action button is pressed
    [valueSelection]    this object contains the details to be passed on to the value selection view.
                        Please check the description in lxValueSelectionView for more infos.
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.ValueSelectionCell = "ValueSelectionCell";

    class ValueSelectionCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return '' + '<div class="content__top">' + '<div class="top__text"></div>' + '</div>' + '<div class="content__bottom">' + '<div class="bottom__button">' + '<div class="button__title"></div>' + '</div>' + '</div>';
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.contentPlaceholder.append(ValueSelectionCell.Template.getTemplate());
            this.elements = {
                topElement: this.element.find(".content__top"),
                bottomElement: this.element.find(".content__bottom"),
                titleText: this.element.find(".top__text"),
                button: this.element.find(".bottom__button"),
                buttonTitle: this.element.find(".button__title")
            };
            this.valueSelectionView = new GUI.LxValueSelectionView(this.content.valueSelection);
            this.valueSelectionView.element.addClass("content__main");
            this.insertSubviewAfter(this.valueSelectionView, this.elements.topElement);
            this.buttons = {};

            if (this.content.actionButton) {
                this.buttons.action = new GUI.LxButton(this, this.elements.button[0]);
                this.buttons.action.onButtonTapped = this.content.actionButton.actionFn;
                this.elements.buttonTitle.text(this.content.actionButton.title);
                this.content.actionButton.color && this.elements.buttonTitle.css("color", this.content.actionButton.color);
                this.addToHandledSubviews(this.buttons.action);
            }

            this.elements.titleText.text(this.content.title);
            this.elements.topElement.toggle(!!this.content.title);
            this.elements.bottomElement.toggle(!!this.content.actionButton);
        }

        clickableAllowed() {
            return false;
        }

        setContent(content) {
            super.setContent(...arguments);
            this.valueSelectionView && this.valueSelectionView.setDetails(this.content.valueSelection);
            this.elements.titleText && this.elements.titleText.text(this.content.title);
            this.elements.topElement && this.elements.topElement.toggle(!!this.content.title);
            this.elements.bottomElement && this.elements.bottomElement.toggle(!!this.content.actionButton);
        }

    }

    GUI.TableViewV2.Cells.ValueSelectionCell = ValueSelectionCell;
    return GUI;
}(window.GUI || {});
