import {LxReactQuickSelect, LxReactSeparator} from "LxComponents";
import EcoScreenUtils from "./EcoScreenUtils";
import globalStyles from "GlobalStyles";
import {Icons} from "IconLib";
import EcoScreenPresenceControlSelection from "./EcoScreenPresenceControlSelection";

class EcoScreenTableViewHelper {
    static get END_OF_PRESENCE_OPTION() {
        return {
            title: _("ecoscreen.settings.presence.end-presence"),
            value: -2,
            timeoutValue: 0
        }
    };

    static get APPEARANCE_OPTIONS() {
        return [
            {
                title: _("ecoscreen.settings.fully-dim-screen"),
                value: false
            },
            {
                title: _("ecoscreen.settings.show-info"),
                value: true
            }
        ]
    };

    static createAppearanceRow(showTime) {
        return {
            title: _('menu.settings.app.presentation'),
            mainCenterStyle: {
                flexShrink: 0,
                flexBasis: "min-content",
                flexDirection: "row"
            },
            mainRightStyle: {
                maxWidth: "65%",
                flexShrink: 1,
                flexDirection: "row"
            },
            mainRightContent: {
                comp: LxReactQuickSelect,
                props: {
                    selectedValue: showTime,
                    options: this.APPEARANCE_OPTIONS,
                    noToggle: true,
                    titleStyle: {
                        ...Styles.SELECTED_OPTION,
                        ...globalStyles.customStyles.selectableText,
                        padding: 0
                    },
                    containerStyle: {
                        flex: 1,
                        alignItems: "flex-end"
                    },
                    tintColor: globalStyles.colors.text.secondary,
                    iconComp: Icons.ChevronsUpDown,
                    iconPosition: LxReactQuickSelect.IconPosition.Right,
                    onOptionSelected: value => {
                        // lxReactQuickSelect returns the option value as string!
                        let adoptedVal = value;
                        if (typeof value === "string") {
                            adoptedVal = value === "true";
                        }
                        EcoScreenUtils.updateSettings({showTime: adoptedVal});
                    }
                }
            }
        }
    }

    static createSelectPresenceRow(usePresence, presenceControls, navigation) {
        let detectedRoom, ctrl;
        if (presenceControls.length > 0) {
            ctrl = ActiveMSComponent.getStructureManager().getControlByUUID(presenceControls[0].uuidAction);
            detectedRoom = ctrl.getRoom();
        }

        return {
            type: GUI.TableViewV2.CellType.GENERAL,
            content: {
                title: _('ecoscreen.settings.presence.monitor'),
                disclosureText: detectedRoom ? detectedRoom.name : (ctrl ? ctrl.getName() : ""),
                disclosureColor: globalStyles.colors.stateActive,
                disclosureIcon: true,
            },
            action: () => navigation.navigate(EcoScreenPresenceControlSelection.name)
        }
    }

    static createShowInfoRow(showTime) {
        return {
            type: GUI.TableViewV2.CellType.SWITCH,
            content: {
                title: _("ecoscreen.settings.show-info"),
                active: showTime,
            },
            onSwitchChanged: (value) => {
                EcoScreenUtils.updateSettings({showTime: !!value});
            }
        }
    }

    static getTimeoutOptions() {
        let options = ScreenSaverTimes.map(timeEntry => {
            return {
                title: `${timeEntry.title}${timeEntry.unit ? _(timeEntry.unit) : ""}`,
                value: timeEntry.value
            }
        });
        options.push(this.END_OF_PRESENCE_OPTION);
        options.push({
            title: _("screensaver.never"),
            value: -1
        })
        return options;
    }

    static getSelectedTimeoutValue(timeout, usePresence) {
        if (!usePresence) {
            return timeout;
        } else {
           return this.END_OF_PRESENCE_OPTION.value;
        }
    }

    static createSelectTimeoutRow(timeout, usePresence) {
        return {
            title: _("ecoscreen.settings.activate"),
            mainCenterStyle: {
                flexShrink: 0,
                flexBasis: "min-content",
                flexDirection: "row"
            },
            mainRightStyle: {
                maxWidth: "65%",
                flexShrink: 1,
                flexDirection: "row"
            },
            mainRightContent: {
                comp: LxReactQuickSelect,
                props: {
                    selectedValue: this.getSelectedTimeoutValue(timeout, usePresence),
                    options: this.getTimeoutOptions(),
                    containerStyle: {
                        flex: 1,
                        alignItems: "flex-end"
                    },
                    titleStyle: {
                        ...Styles.SELECTED_OPTION,
                        ...globalStyles.customStyles.selectableText,
                        padding: 0
                    },
                    tintColor: globalStyles.colors.text.secondary,
                    iconComp: Icons.ChevronsUpDown,
                    iconPosition: LxReactQuickSelect.IconPosition.Right,
                    onOptionSelected: (value, index, reject) => {
                        let toValue = value,
                            isPresenceOption = value < -1;
                        if (isPresenceOption) {
                            toValue = this.END_OF_PRESENCE_OPTION.timeoutValue;
                            if (EcoScreenUtils.getPresenceControlCandidates().length === 0) {
                                toValue = timeout;
                                isPresenceOption = false;
                                NavigationComp.showPopup({
                                    title: _("ecoscreen.setting.no-presence-warning.title"),
                                    message: _("ecoscreen.setting.no-presence-warning.message"),
                                    buttonOk: true
                                });
                                reject();
                            }
                        }
                        EcoScreenUtils.updateSettings({timeout: toValue, usePresence: isPresenceOption});
                    }
                }
            }
        }
    }

    static createSettingsContent({timeout, usePresence, presenceControls, showTime}, navigation, embedded) {
        let content = [];
        let activationSection;
        let appearanceSection;

        activationSection = {
            rows: [
                EcoScreenTableViewHelper.createSelectTimeoutRow(timeout, usePresence),
            ]
        };
        usePresence && EcoScreenUtils.getPresenceControlCandidates().length > 0 && activationSection.rows.push(
            EcoScreenTableViewHelper.createSelectPresenceRow(usePresence, presenceControls, navigation)
        );
        content.push(activationSection);

        appearanceSection = {
            headerDescription: " ",
            rows: [
                EcoScreenTableViewHelper.createAppearanceRow(showTime),
                LxReactSeparator
            ]
        };
        content.push(appearanceSection);
        return content;
    }

    static createSettingsContentInfo(settings, navigation, embedded) {
        return {
            sections: this.createSettingsContent(settings, navigation, embedded)
        }
    }
}

export default EcoScreenTableViewHelper;
