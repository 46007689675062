'use strict';

define(['LightControlManualList', 'ColorPickerColorSelection', 'LightV2ControlSaveMoodScreen', 'LightV2ControlEnums', 'LightV2ControlReplaceMoodScreen', 'LightV2ControlEditMoodScreen', 'ControlNoteView'], function (LightControlManualList, ColorPickerColorSelection, LightV2ControlSaveMoodScreen, LightV2ControlEnums, LightV2ControlReplaceMoodScreen, LightV2ControlEditMoodScreen, ControlNoteView) {
    return class LightCircuitList extends LightControlManualList {
        constructor(control) {
            super(...arguments);
            this.previousStates = {};
            this._isInitialLoad = true;
            this._showingSaveCell = false; // Remove master controls

            for (var i = 0; i < this.controls.length; i++) {
                var controlObj = this.controls[i];

                if (controlObj && controlObj.details && controlObj.details.isMaster) {
                    this.controls.splice(i, 1);
                    i--;
                }
            }

            this._createControlTableViewContent();
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.TEXT,
                rightSide: TitleBarCfg.CUSTOM_ICON,
                rightIconSrc: Icon.TitleBar.PLUS
            };
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                this.titleBar.onRightButtonTapped = function () {
                    this.ViewController.showState(LightV2ControlEnums.ScreenState.NEW_MOOD, null, {
                        control: this.control
                    });
                }.bind(this);

                if (this.control.hasControlNotes && HD_APP) {
                    this._createAndInsertNoteView();
                } // register for structural changes as they might also concern the control note.


                this._structureChangesReg = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.StructureChanged, this._checkStructureChanges.bind(this));
            }.bind(this));
        }

        destroy() {
            NavigationComp.removeFromUIEvent(this._structureChangesReg);
            this.titleBar.onRightButtonTapped = null;
            super.destroy(...arguments);
        }

        // Public methods
        update(states) {
            var moodName = this.control.getMoodName();
            this.titleBar.setTitleBarSideTexts(_("controls.lightV2controller.mood.x", {
                mood: moodName
            })); // Use the state names for the light circuits

            var changedName = false;
            Object.keys(states["circuitNames"]).forEach(function (key) {
                if (this.previousStates[key] !== states["circuitNames"][key]) {
                    this.control.subControls[key].name = states["circuitNames"][key];
                    changedName = true;
                }
            }.bind(this)); // Only update controlList if moodName has changed

            if ((this.moodName !== moodName || changedName) && this.controlList) {
                this.moodName = moodName;

                this._createControlTableViewContent();

                if (this._isInitialLoad || changedName) {
                    this.controlList.reload();
                    this._isInitialLoad = false;
                } else {
                    this.controlList.updateSectionHeader(this.control.details.masterValue || this.control.details.masterColor ? 1 : 0);
                }
            } // Set this to LightV2ControlEnums.KNOWN_MOODS.MANUAL to display the save scene cell
            // this.activeSceneNr is used by the base class


            this.activeMoods = states.activeMoods;
            this.moodList = states.moodList;

            if (states.activeMoods.length === 0) {
                this.activeSceneNr = LightV2ControlEnums.KNOWN_MOODS.MANUAL;
            } else {
                this.activeSceneNr = states.activeMoods[0];
            }

            this.previousStates = cloneObjectDeep(states["circuitNames"]);

            if (this._showingSaveCell !== this._showSaveCell()) {
                this.tableView.reload();
            }
        }

        didSelectCell(cell, section, row) {
            if (row === 0) {
                if (this._showSaveCell()) {
                    this.ViewController.showState(LightV2ControlEnums.ScreenState.REPLACE_MOOD, null, {
                        control: this.control
                    });
                } else {
                    this.ViewController.showState(LightV2ControlEnums.ScreenState.EDIT_MOOD, null, {
                        control: this.control,
                        mood: this.activeSceneNr
                    });
                }
            }
        }

        // TableViewDataSource methods
        groupForHeaderInSection(section) {
            if (section === 1 || section === 0 && this.controlTableViewContent.length === 1) {
                return {
                    uuid: "nonsense",
                    name: this.controlTableViewContent[section].title,
                    descriptionText: this.controlTableViewContent[section].descriptionText,
                    iconSrc: this.controlTableViewContent[section].iconSrc
                };
            }
        }

        rightSectionButtonTapped() {
            NavigationComp._showState(ScreenState.RenameLightCircuitsScreen, {
                control: this.control
            });
        }

        numberOfSections(tableView) {
            if (tableView === this.controlList) {
                return this.controlTableViewContent.length;
            } // Editing a Mood is always possible!


            return 1;
        }

        numberOfRowsInSection(section, tableView) {
            if (tableView === this.controlList) {
                return this.controlTableViewContent[section].cells.length;
            } else {
                return 1;
            }
        }

        controlForCellAtIndex(section, row) {
            return this.controlTableViewContent[section].cells[row];
        }

        contentForCell(cell, section, row) {
            this._showingSaveCell = this._showSaveCell();

            if (this._showingSaveCell) {
                return {
                    leftIconSrc: 'resources/Images/Controls/Light/save.svg',
                    leftIconColor: window.Styles.colors.red,
                    title: _("controls.lightV2controller.mood.save"),
                    disclosureIcon: true
                };
            } // Editing a Mood is always possible!


            return {
                leftIconSrc: Icon.SETTINGS,
                leftIconColor: window.Styles.colors.green,
                title: _("controls.lightV2controller.mood.edit"),
                disclosureIcon: true
            };
        }

        // Private
        _createControlTableViewContent() {
            var descriptionText = _("controls.lightV2controller.lightMoodActive", {
                moodName: this.moodName || ""
            });

            if (this.control.activeMoods[0] === LightV2ControlEnums.KNOWN_MOODS.ALL_OFF) {
                descriptionText = _("controls.lightV2controller.mood.x", {
                    mood: '"' + this.moodName + '"' || ""
                });
            }

            this.controlTableViewContent = [];

            // The two master controls are in the first section of the TablView
            if (this.control.details.masterValue || this.control.details.masterColor) {
                this.controlTableViewContent.push({
                    title: UnassignedUUID,
                    cells: []
                });

                if (this.control.details.masterValue) {
                    this.controlTableViewContent[0].cells.push(this.control.subControls[this.control.details.masterValue]);
                }

                if (this.control.details.masterColor) {
                    this.controlTableViewContent[0].cells.push(this.control.subControls[this.control.details.masterColor]);
                }
            }

            this.controlTableViewContent.push({
                title: _("controls.lightV2controller.lightCircuits"),
                descriptionText: descriptionText,
                iconSrc: Feature.RENAME_LIGHT_CIRCUITS ? Icon.Light.SETTINGS : null,
                cells: this.controls
            })
        }

        /**
         * Returns true if the manual mood is active or two moods are combined
         * @returns {boolean}
         * @private
         */
        _showSaveCell() {
            return this.activeSceneNr === LightV2ControlEnums.KNOWN_MOODS.MANUAL || this.activeMoods && this.activeMoods.length > 1;
        }

        _isStaticMood() {
            // Get the currently active mood
            var mood = this.moodList.filter(function (m) {
                return m.id === this.activeSceneNr;
            }.bind(this))[0];
            return mood ? mood.static : false;
        }

        _createAndInsertNoteView() {
            this.noteView = new ControlNoteView(this.control);
            this.noteView.element.addClass('light-circuit-list__note-view');
            this.insertSubviewBefore(this.noteView, this.scrollContainer);
        }

        _checkStructureChanges(event, changes) {
            var isAffected = changes.controls && !!changes.controls[this.control.uuidAction];

            if (isAffected) {
                if (!this.noteView && this.control.hasControlNotes && HD_APP) {
                    // no view shown, but should show one
                    this._createAndInsertNoteView();
                } else if (this.noteView && !this.control.hasControlNotes) {
                    // note view shown, but shouldnt
                    this.removeSubview(this.noteView);
                } // otherwise, there's nothing to do

            }
        }

    };
});
