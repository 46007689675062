'use strict';

define("WeatherControl", ["Control"], function (Control) {
    return class WeatherControl extends Control {
        constructor(weatherServer) {
            super({
                uuidAction: WEATHER_UUID,
                type: 'weather',
                controlType: ControlType.WEATHER,
                states: weatherServer.states,
                details: weatherServer,
                name: _('weather')
            });
        }

        getName() {
            return super.getStateText() || super.getName(...arguments);
        }

        getCategory() {
            // we create a dummy category, so that in the central tab the city will be displayed (where normally the category would be)
            var location = ActiveMSComponent.getMiniserverLocation(),
                zipAndCity = location.split(", ")[1],
                city = zipAndCity && zipAndCity.substr(zipAndCity.indexOf(" ") + 1);
            return {
                name: city || location
            };
        }

    };
});
