import { useStatisticV2, DateViewTypeUtils, useControl } from "LxComponents"
import MeterHeaderValue from "./meterHeaderValue"

export default function StatisticsMeterHeaderValue({ text, color, controlUUID, viewInfo }) {
    const control = useControl(controlUUID);

    const reduceStatistics = (statistic) => {
        let newStats = { ...statistic, data: null }
        try {
            newStats.data = [statistic.data.reduce((prev, curr) => {
                let newDp = { ...prev, values: [...prev.values] }
                curr.values.forEach((val, idx) => {
                    newDp.values[idx] = newDp.values[idx] + val;
                });
                return newDp;
            })];
        } finally {
            return newStats
        }
    }

    const statistics = useStatisticV2(
        controlUUID,
        viewInfo.start,
        viewInfo.end,
        ["total"],
        DateViewTypeUtils.getStatisticDiffDataPointUnit(viewInfo.vt),
        reduceStatistics);

    const getValue = () => {
        let statsData = 0
        if (Array.isArray(statistics.data)) {
            statsData = statistics.data[0].values[0]
        }
        return lxUnitConverter.convertSplitAndApply(control.totalFormat, statsData)
    }

    const valueToDisplay = getValue()

    return <MeterHeaderValue
        valueTxt={valueToDisplay.valueTxt}
        unitTxt={valueToDisplay.succTxt}
        text={text}
        color={color}
    />
}