'use strict';

define("IrrigationControlCommandSrc", ["ControlCommandSrc", "IrrigationControlEnums"], function (ControlCommandSrc, IrrigationControlEnums) {
    return class IrrigationControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [];

            if (!states.hasZones) {// no commands!
            } else if (states.active) {
                cmds.push(this.createCommand(_("controls.irrigation.stop"), Commands.IRRIGATION.STOP));
            } else {
                cmds.push(this.createCommand(_("controls.irrigation.start"), Commands.IRRIGATION.START_FORCE));
            }

            return cmds;
        }

    };
});
