'use strict';

define([
    "ControlSettingsScreen",
    "ControlSettingsUtil",
    "LxComponents"
], function (
    ControlSettingsScreen,
    ControlSettingsUtil,
    {
        App
    }
) {
    return class ControlSettingsController extends GUI.ViewController {
        constructor(details) {
            super();
            this.settingsUtil = details.settingsUtil || new ControlSettingsUtil(details);
            this.object = details.object || this.settingsUtil.object; // energymanager provides the object this way.
            this.objectUUID = details.objectUUID;
            this.objectName = details.objectName;
            this.permission = details.permission;
            this.lightMode = this.permission === MsPermission.EXPERT_MODE_LIGHT;
            this._isControl = !!this.object.uuidAction;
            this.settingsUtil = details.settingsUtil || new ControlSettingsUtil(details);
            this._specialType = details.specialType;
            this.addMultipleView(ScreenState.ControlSettingEdit);
            this.addMultipleView(ScreenState.ControlSettingEditIcon);
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                return this._loadControlSettings(this.lightMode).then(function (settingsConfig) {
                    return this.showState(ScreenState.ControlSettings, null, {
                        object: this.object,
                        settingsConfig: settingsConfig,
                        lightMode: this.lightMode,
                        settingsUtil: this.settingsUtil,
                        specialType: this._specialType
                    }, AnimationType.NONE);
                }.bind(this), function (e) {
                    NavigationComp.showErrorPopup(true, null, e.message);
                });
            }.bind(this));
        }

        getPermissions() {
            return [{
                permission: this.permission
            }];
        }

        getAnimation() {
            return this.lightMode ? AnimationType.FADE : AnimationType.MODAL;
        }

        getURL() {
            return "ExpertMode/" + this.objectUUID + "/" + super.getURL();
        }

        destroyOnBackNavigation() {
            return true;
        }

        viewWillAppear() {
            this._changedToIconScreen = false; // reset this flag, see viewWillDisappear for details.
            return super.viewWillAppear(...arguments);
        }

        viewDidAppear() {
            var prms = super.viewDidAppear(...arguments);
            !this.lightMode && this._startRefreshTimer(); // only rq for expert mode.

            return prms;
        }

        viewWillDisappear() {
            var args = arguments;
            if (this._changedToIconScreen) {
                // this is a react screen, hence the viewController will disappear - but don't end the editing just yet.
                return super.viewWillDisappear(...args);
            }

            this._stopRefreshTimer();

            return this.hasPermissionsGranted().then(function () {
                if (!this._timedOut) {
                    if (this.settingsUtil.saveSettingsForObject) {
                        this.settingsUtil.saveSettingsForObject(this.objectUUID, this.objectName);
                    } else {
                        ActiveMSComponent.saveSettingsForObject(this.objectUUID, this.objectName);
                    }
                }
            }.bind(this), function (e) {
                console.warn(e.message);
                return Q.resolve();
            }).finally(() => {
                return super.viewWillDisappear(...args);
            });
        }

        // Public methods (for controller)
        valueDidChange(setting, value) {
            return !setting.compareValue(value);
        }

        verifyValue(setting, value) {
            var loadPromise = [true]; // Load the settingsConfig if it doesn't exist yet
            // Loading the config also starts the EditMode, this mode can only be started once per edit session, the
            // Miniserver will overwrite every previous change on every EditMode start.

            if (!this.settingsConfig) {
                loadPromise.push(this._loadControlSettings(false));
            }

            return Q.all(loadPromise).then(function () {
                let promise;
                if (this.settingsUtil.verifySettingForObject) {
                    promise = this.settingsUtil.verifySettingForObject(this.objectUUID, setting, value);
                } else {
                    promise = ActiveMSComponent.verifySettingForObject(this.objectUUID, setting, value);
                }
                return promise.then(null, function (e) {
                    if (e === ControlSettingResponseCode.TIMEOUT) {
                        this._timedOut = true;
                        this.ViewController.navigateBack();
                    }

                    throw e;
                }.bind(this));
            }.bind(this));
        }

        /**
         * if we display the ControlSettingEdit screen, we need to make sure, that this screen gets the correct setting (eg. validValues)
         * the settingsConfig will be requested if needed, and the details will be update
         * @returns {Promise}
         */
        showState(screenState, screen, details) {
            if (this.lightMode && screenState === ScreenState.ControlSettingEdit) {
                return this._checkLightModeShowState(arguments);
            }

            return this._checkIconSettingAndShow(...arguments);
        }

        // Private methods

        /**
         * Ensures that for icon settings the new edit component is used.
         * @param screenState
         * @param screen
         * @param details
         * @returns {Q.Promise<unknown>|*}
         * @private
         */
        _checkIconSettingAndShow(screenState, screen, details) {
            if (screenState === ScreenState.ControlSettingEdit && this._isIcon(details.setting.id)) {
                details.viewCtrl = this; // required for permission checking & so on.
                this._changedToIconScreen = true; // store this flag, so viewWillDisappear won't stop the refresh & save the previous settings (would end the edit mode)
                App.navigationRef.navigate(ScreenState.ControlSettingEditIcon, details);
                return Q.resolve();
            } else {
                return super.showState(...arguments);
            }
        }

        _isIcon(settingId) {
            let isIcon = false;
            switch (settingId) {
                case ControlSettingID.CONTROL_SPECIFIC.ENERGY_MANAGER.LOAD_ICON:
                case ControlSettingID.DEFAULT_ICON:
                case ControlSettingID.IMAGE:
                    isIcon = true;
                    break;
                default:
                    break;
            }
            return isIcon;
        }

        /**
         * Navigating back to the last screen without permissions
         * @note This function overwrites the base implementation to hide the whole ViewController instead of
         *       navigating back screen by screen. This is especially important, because this viewController handles the permissions
         * @private
         */
        _navigateBackDueToCanceledPermissionElevation() {
            this.dismiss();
        }

        /**
         * loads and initializes the settings config
         * @param light if light mode should be loaded and prepared
         * @returns {Promise}
         * @private
         */
        _loadControlSettings(light) {
            return this.settingsUtil.getSettings(light).then(function (settingsConfig) {
                if (!light) {
                    // don't save the settings config for light mode, because it's not the full settingsConfig!
                    // (just stuff to display, no more details about each setting)
                    // Detailed Settings like Color or icons must be loaded on demand and are not part of the settingsConfig
                    this.settingsConfig = settingsConfig;
                }

                return settingsConfig;
            }.bind(this));
        }

        /**
         * if we are in light mode, we need to make sure, that, once the user wants to edit a setting, we load the full settingsConfig and provide the correct infos for the edit screen
         * @param showStateArgs
         * @returns {Promise}
         * @private
         */
        _checkLightModeShowState(showStateArgs) {
            if (this.settingsConfig) {
                return this._updateSettingAndShowState(showStateArgs);
            } // settingsConfig not requested yet, request and then show state


            return this._loadControlSettings(false).then(function () {
                return this._updateSettingAndShowState(showStateArgs);
            }.bind(this), function (e) {
                NavigationComp.showErrorPopup(false, null, e.message);
            });
        }

        /**
         * makes sure, that the edit screen always has the correct setting from the Miniserver, not the locally prepared one (eg. validValues are missing..)
         * @param showStateArgs
         * @returns {Promise}
         * @private
         */
        _updateSettingAndShowState(showStateArgs) {
            var details = showStateArgs[2];
            details.setting = this._findSetting(details.setting.id); // update the setting
            // check if description is empty, if so, use the objects name to don't confuse the user

            if (details.setting.id === ControlSettingID.DESCRIPTION_VISU && !details.setting.value) {
                details.setting.value = this.object.name;
            }

            return this._checkIconSettingAndShow(...showStateArgs);
        }

        /**
         * finds and returns a setting for the given id in the settingsConfig
         * @param id
         * @private
         */
        _findSetting(id) {
            var keys = Object.keys(this.settingsConfig),
                i,
                j;

            for (i = 0; i < keys.length; i++) {
                for (j = 0; j < this.settingsConfig[keys[i]].length; j++) {
                    if (this.settingsConfig[keys[i]][j].id === id) {
                        return this.settingsConfig[keys[i]][j];
                    }
                }
            }

            return false;
        }

        _startRefreshTimer() {
            this._refreshTimer && this._stopRefreshTimer();

            if (Feature.EXPERT_MODE_REFRESH) {
                this._refreshTimer = setTimeout(this._refreshTimerFired.bind(this), 30 * 1000);
            }
        }

        _stopRefreshTimer() {
            this._refreshTimer && clearTimeout(this._refreshTimer);
            this._refreshTimer = null;
        }

        _refreshTimerFired() {
            // don't send as long as the permission isn't granted!
            if (SandboxComponent.checkGrantedPermission(MsPermission.EXPERT_MODE, true)) {
                let promise;
                if (this.settingsUtil.sendExpertModeRefresh) {
                    promise = this.settingsUtil.sendExpertModeRefresh(this.objectUUID);
                } else {
                    promise = ActiveMSComponent.sendExpertModeRefresh(this.objectUUID);
                }
                promise.then(function (response) {
                    this._refreshTimer = null;

                    this._startRefreshTimer();
                }.bind(this), function (err) {
                    console.error(this.name, "_refreshTimerFired - error received! " + JSON.stringify(err));
                }.bind(this));
            } else {
                this._startRefreshTimer();
            }
        }

    };
});
