'use strict';

define(['./cardScreen.jsx'], function (CardScreenComp) {
    return class CardScreen extends GUI.Screen {
        //region Static
        static ReactComp = CardScreenComp.default;

        constructor() {
            super();
        }
/*
        viewDidLoad() {
            if (this.reactNavigationProps) {
                this.reactNavigationProps.navigation.setOptions({
                    headerShown: false
                })
            }
            var currentSortingStructure = ActiveMSComponent.getSortingStructure();
            CompChannel.on(CCEvent.Resign, function () {
                this._appIsGoingInBackground = true;
            }.bind(this));
            return super.viewDidLoad(...arguments).then(function () {
                this._structureChangesReg = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.StructureChanged, this.handleStructureChanged.bind(this));
                this._favsChangedReg = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.FavoritesChanged, this.handleFavoritesChanged.bind(this));
                this._pauseReg = CompChannel.on(CCEvent.Pause, function () {
                    if (this._sortingPopup) {
                        NavigationComp.removePopup(this._sortingPopup);
                    }

                    this._stopSorting(false);
                }.bind(this));
                this._taskRecorderStartReg = CompChannel.on(CCEvent.TaskRecorderStart, function () {
                    this.leftTopButton.setEnabled(!this._shouldBlockTopLeftButton());
                }.bind(this));
                this._taskRecorderEndReg = CompChannel.on(CCEvent.TaskRecorderEnd, function () {
                    this.leftTopButton.setEnabled(true);
                }.bind(this));
                ActiveMSComponent.getSortingReadyPromise().then(function () {
                    var newSortingStructure = ActiveMSComponent.getSortingStructure();

                    if (currentSortingStructure !== newSortingStructure) {
                        this.reloadTable();
                    }
                }.bind(this));
                this.elements = {
                    logo: this.element.find(".logo-container__logo"),
                    leftTopBtn: this.element.find(".top__left-btn"),
                    weatherCntr: this.element.find(".top__weather"),
                    weatherTempLbl: this.element.find(".weather__temp"),
                    weatherIconPlaceholder: this.element.find(".weather__icon"),
                    rightBtnContainer: this.element.find(".top__right-btns"),
                    searchButton: this.element.find(".top__right-btns .right-btns__search"),
                    dynamicRightButton: this.element.find(".top__right-btns .right-btns__dynamic"),
                    homeNameLbl: this.element.find(".top__texts .texts__home-name"),
                    titleLbl: this.element.find(".top__texts .texts__title"),
                    notification: {
                        element: this.element.find(".card-screen__notification"),
                        text: this.element.find(".notification__message")
                    },
                    contentPlaceholder: this.element.find(".card-screen__content"),
                    contentPlaceholderTop: this.element.find(".card-screen__content .content__top"),
                    tableViewPlaceholder: this.element.find(".content__table-view-placeholder")
                };
                this.elements.homeNameLbl.text(ActiveMSComponent.getMiniserverName().toUpperCase());
                this.updateTitle();

                if (HD_APP) {
                    this.elements.timeLbl = this.element.find(".date-time__time"); //this.elements.dateLbl = this.element.find(".date-time__date .date__bottom");
                    //this.elements.dayLbl = this.element.find(".date-time__date .date__top");
                } // hidden feature: click on Loxone Logo navigates to Entry Point


                var logoBtn = new GUI.LxRippleButton(this.elements.logo);
                this.addToHandledSubviews(logoBtn);
                logoBtn.on(GUI.LxRippleButton.CLICK_EVENT, function () {
                    NavigationComp.showEntryPointLocation();
                });
                this.leftTopButton = new GUI.LxRippleButton(this.elements.leftTopBtn);
                this.leftTopButton.setEnabled(!this._shouldBlockTopLeftButton());
                this.addToHandledSubviews(this.leftTopButton);
                this.leftTopButton.on(GUI.LxRippleButton.CLICK_EVENT, this.onTopLeftButtonTapped.bind(this));
                this.weatherButton = new GUI.LxButton(this, this.elements.weatherCntr[0], "rgba(255,255,255,0.7)", null, true);
                this.weatherButton.useChildsAsActiveParts("color");
                this.addToHandledSubviews(this.weatherButton);
                this.weatherButton.onButtonTapped = this._showWeather.bind(this);
                this.tableViewDataSource = tableViewDataSource(null, null, this);
                this.tableViewDelegate = tableViewDelegate(null, this);
                this.collectionView = new GUI.CardView(this.tableViewDataSource, this.tableViewDelegate);
                this.appendSubview(this.collectionView, this.elements.tableViewPlaceholder);
                this.searchButton = new GUI.LxRippleButton(this.elements.searchButton);
                this.addToHandledSubviews(this.searchButton);
                this.searchButton.on(GUI.LxRippleButton.CLICK_EVENT, this._showSearchScreen.bind(this));
                this.dynamicRightButton = new GUI.LxButton(this, this.elements.dynamicRightButton[0], null, null, true);
                this.addToHandledSubviews(this.dynamicRightButton);
                this.hideSubview(this.dynamicRightButton);
                this.notificationButton = new GUI.LxButton(this, this.elements.notification.element[0], null, null, true);
                this.addToHandledSubviews(this.notificationButton);
                this.hideSubview(this.notificationButton);

                if (this.hasContextMenu()) {
                    this.addRightBtnToTitle(this._handleContextMenuBtn.bind(this));
                }

                this._sortingStructureChanged = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.SortingStructureChanged, this.handleSortingStructureChanged.bind(this));
                return this.reloadTable();
            }.bind(this));
        }

        updateView(details) {
            if (details.scrollToTop) {
                this._scrollTop = 0;

                if (this.isVisible()) {
                    this._updateScrollTop(true);
                }
            }
        }

        viewWillAppear() {
            if (this._needsReload) {
                ActiveMSComponent.getSortingReadyPromise().then(function () {
                    this.reloadTable();
                    this._needsReload = false;
                }.bind(this));
            }

            this._appIsGoingInBackground = false;
            super.viewWillAppear(...arguments);

            if (HD_APP) {
                this._registerTime();
            }

            this.deregNewsUpdateReceiver = LxServerComponent.registerForNewsHubChanges(this._newsChanged.bind(this)); // First we have to check if the user is limited, which means that the systemstate entries are not important for the current user.

            if (!MessageCenterHelper.isLimitedUser()) {
                this.deregMessageCenterUpdateReceiver = ActiveMSComponent.registerForMessageCenterUpdate(this._messageCenterUpdate.bind(this));
            }

            this._updateNotificationsCount(null, SandboxComponent.getNumberOfUnreadNotifications());

            this.notificationUpdateHandler = CompChannel.on(CCEvent.UnreadNotificationCount, this._updateNotificationsCount.bind(this));

            this._updateScrollTop(false);

            if (this._weatherControl) {
                this._registerForStates(this._weatherControl.uuidAction, ["currentTemperature", "hasValidData"]);
            } // when the ad screen has been clicked through, only the flag is set, there is no state change.
            // this is why it needs to be checked here and potentially adopted.


            if (this.shouldDisplayWeather() && this._showWeatherAd && PersistenceComponent.getMSInstructionFlag(UserInstructionFlag.WEATHER_AD)) {
                this._weatherControl && this.receivedStates(this._weatherControl.getStates());
            }

            this.dynamicRightButton.onButtonTapped = function () {
                // The MessageCenter is always preferred over the News
                if (this.hasActiveMessageCenterEntry) {
                    this._showMessageCenterMessagesScreen();
                } else {
                    this._showNewsHubScreen();
                }
            }.bind(this);

            this.notificationButton.onButtonTapped = this._showMessageCenterMessagesScreen.bind(this);
        }

        viewWillDisappear() {
            if (this.element.hasClass("card-screen--sorting")) {
                if (this._appIsGoingInBackground) {
                    this._stopSorting();

                    this._needsReload = true;
                    return this._defaultViewWillDisappear();
                } else {
                    return this._showEndSortingPopup().then(function (button) {
                        this._stopSorting(true);

                        return this._defaultViewWillDisappear();
                    }.bind(this), function () {
                        this._needsReload = true;
                        return this._defaultViewWillDisappear();
                    }.bind(this));
                }
            } else {
                // default handling
                return this._defaultViewWillDisappear();
            }
        }

        _showEndSortingPopup() {
            HapticFeedback(HapticFeedback.STYLE.WARNING); // show sorting will be not saved popup

            this._sortingPopup = NavigationComp.showPopup({
                title: _("new-sorting.popup.title"),
                message: _("new-sorting.popup.message"),
                icon: Icon.CAUTION,
                color: window.Styles.colors.red,
                buttonOk: _("new-sorting.popup.button.ok"),
                buttonCancel: _("new-sorting.popup.button.cancel")
            });
            return this._sortingPopup;
        }

        _defaultViewWillDisappear() {
            var viewWillDisappearPrms;
            this.deregNewsUpdateReceiver(); // this.deregMessageCenterUpdateReceiver(); -> this method should only be executed if it's necessary

            if (!MessageCenterHelper.isLimitedUser()) {
                try {
                    this.deregMessageCenterUpdateReceiver();
                } catch (ex) {
                    // BG-I8924 - apparently there are situations where this crashes and causes issues.
                    console.error(this.name, "viewWillDisappear - tried to call deregMessageCenterUpdateReceiver, failed!");
                    console.error(ex);
                }
            }

            viewWillDisappearPrms = super.viewWillDisappear(...arguments);

            if (HD_APP) {
                SandboxComponent.removeFromTimeInfo(this.timeIdentifier);
            }

            this.notificationUpdateHandler();

            if (HD_APP) {
                this._scrollTop = this.elements.tableViewPlaceholder.scrollTop();
            } else {
                this._scrollTop = this.elements.contentPlaceholder.scrollTop();
            }

            if (this._weatherControl) {
                this._unregisterStates(this._weatherControl.uuidAction);
            }

            this._stopSorting();

            this._hideContextMenu();

            return viewWillDisappearPrms;
        }

        destroy() {
            NavigationComp.removeFromUIEvent(this._structureChangesReg);
            NavigationComp.removeFromUIEvent(this._favsChangedReg);
            NavigationComp.removeFromUIEvent(this._tilePreChangedReg);
            NavigationComp.removeFromUIEvent(this._sortingStructureChanged);
            CompChannel.off(this._pauseReg);
            CompChannel.off(this._taskRecorderStartReg);
            CompChannel.off(this._taskRecorderEndReg);
            super.destroy();
        }

        getAnimation() {
            return AnimationType.FADE;
        }

        getTitle() {
            return "Override!";
        }

        handleStatusBarTap() {
            this._scrollTop = 0;

            this._updateScrollTop(true);
        }

        addControlToSection(section, control, displayAsCell, displayRoomName, showGroupDetails) {
            var hideActionElements = control.isGrouped() && control.isInCentralRoom();
            section.rows.push({
                type: control.getCellType(),
                content: {
                    control: control,
                    displayAsCell: displayAsCell,
                    displayRoomName: displayRoomName,
                    showGroupDetails: showGroupDetails,
                    hideActionElements: hideActionElements
                }
            });
        }

        getSortableOptions() {
            return {
                draggable: '.section__cell',
                scroll: true,
                scrollSensitivity: 100,
                scrollSpeed: 10,
                delay: 100,
                // time in milliseconds to define when the sorting should start
                delayOnTouchOnly: true,
                // only delay if user is using touch
                touchStartThreshold: 10,
                swapThreshold: 1,
                animation: 150,
                onChange: function () {
                    this._sortingChanged = true;
                }.bind(this)
            };
        }
*/
        /**
         * This method will iterate over the content and update individual content attributes. E.g. it will set the
         * proper flag if more than one control of a certain type is inside a section.
         * @param tableContent
         */
 /*
        verifyContent(tableContent) {
            var groupMap, ctrl, groupEntry, typeEntry, cleanType; // iterate over all sections

            tableContent.forEach(function (section) {
                groupMap = {}; // for each section, count how many instances of controls of the same type are in a room/category

                section.rows && section.rows.forEach(function (cellObj) {
                    ctrl = cellObj.content.control;

                    if (ctrl) {
                        cleanType = ctrl.controlType.replace("V2", ""); // e.g. LightControl

                        groupEntry = groupMap[ctrl.groupDetail] || {};
                        typeEntry = groupEntry[cleanType] || 0;
                        groupEntry[cleanType] = typeEntry + 1;
                        groupMap[ctrl.groupDetail] = groupEntry;
                    }
                }); // assign the displayControlName flag if there is more than one control of the same type in this section.

                section.rows && section.rows.forEach(function (cellObj) {
                    ctrl = cellObj.content.control;

                    if (ctrl) {
                        groupEntry = groupMap[ctrl.groupDetail];
                        cleanType = ctrl.controlType.replace("V2", ""); // e.g. LightControl

                        cellObj.content.displayControlName = groupEntry[cleanType] > 1;
                    }
                });
            });
        }

        onTopLeftButtonTapped() {
            NavigationComp.showMenu();
        }

        closeAction() {
            if (this.element.hasClass("card-screen--sorting")) {
                return this._showEndSortingPopup().then(function (button) {
                    this._stopSorting(true);

                    return Q.reject();
                }.bind(this), function () {
                    this._stopSorting();

                    this.reloadTable();
                    return Q.reject();
                }.bind(this));
            } else {
                if (!this._shouldBlockTopLeftButton()) {
                    var platform = PlatformComponent.getPlatformInfoObj().platform;

                    if (platform === PlatformType.Webinterface) {
                        NavigationComp.showState(ScreenState.AboutMiniserver);
                    } else {
                        NavigationComp.showState(ScreenState.MiniserverSelection);
                    }
                }
            }
        }

        shouldDisplayWeather() {
            return true;
        }

        reloadTable() {
            var tableContent = this.prepareTableContent();
            this.verifyContent(tableContent);
            this.tableViewDataSource.update(tableContent);
            this.tableViewDelegate.update(tableContent);
            return this.collectionView.reload().then(function () {
                this._checkEmptyScreen(tableContent.length === 0);
            }.bind(this));
        }

        startSorting() {
            this._sortingChanged = false;
            this.element.addClass("card-screen--sorting");
            this.collectionView.setEditingModeForAllSections(true);

            if (!this.sortingDoneButton) {
                var doneButton = this.element.find(".top-sorting__right-container");
                this.sortingDoneButton = new GUI.LxButton(this, doneButton[0], null, null, true);
                this.addToHandledSubviews(this.sortingDoneButton);
                this.sortingDoneButton.onButtonTapped = this._stopSortingAction.bind(this);
            }
        }

        handleSortingStructureChanged() {
            if (!this.collectionView.isEditing) {
                if (this.isVisible()) {
                    this.reloadTable();
                } else {
                    this._needsReload = true;
                }
            }
        }

        handleStructureChanged() {
            if (this.isVisible()) {
                this.reloadTable();
            } else {
                this._needsReload = true;
            }
        }
*/
        /**
         * Called whenever e.g. the per device favorites are changed.
         * @param affectedUuid
         */
/*
        handleFavoritesChanged(affectedUuid) {
            this.handleStructureChanged();
        }
*/
        /**
         * Forces a reload after the tile representation was changed
         */
/*
        handleTilePreChanged() {
            this.reloadTable();
        }
*/
        /**
         * adds a button to the right side of the title.
         * @param handler
         * @param [icon]    optional. default = Icon.Buttons.MORE2
         */
/*
        addRightBtnToTitle(handler, icon) {
            icon = icon || Icon.Buttons.MORE2;
            this.element.addClass("card-screen--with-right-btn");
            this.rightTopButton = new GUI.LxRippleButton(this.constructor.Template.getRightButton(icon));
            this.appendSubview(this.rightTopButton, this.elements.contentPlaceholderTop);
            this.rightTopButton.on(GUI.LxRippleButton.CLICK_EVENT, handler);
        }

        styleForTableView() {
            return TableViewStyle.TRANSLUCENT;
        }

        // Weather Update handling
        allStatesReceived(allStatesArrived) {
            if (allStatesArrived) {// will be updated..
            } else if (this._hadValidWeatherData) {
                this.elements.weatherIconPlaceholder.hide();
                this.elements.weatherTempLbl.text("---");
            }
        }

        receivedStates(states) {
            this._hasValidWeatherData = states.hasValidData;
            this._hadValidWeatherData = this._hadValidWeatherData || states.hasValidData; // remember if we had at any time valid data

            this.elements.weatherIconPlaceholder.empty();
            this._showWeatherAd = !this._hadValidWeatherData && !PersistenceComponent.getMSInstructionFlag(UserInstructionFlag.WEATHER_AD);
            this.elements.weatherTempLbl.toggle(this._hadValidWeatherData);
            this.elements.weatherIconPlaceholder.toggle(this._showWeatherAd || this._hasValidWeatherData);
            this.elements.weatherCntr.toggleClass("top__weather--ad", this._showWeatherAd);

            if (this._hasValidWeatherData) {
                this.elements.weatherTempLbl.text(lxFormat("%d°", states.currentTemperature));
                this.elements.weatherIconPlaceholder.append(ImageBox.getResourceImageWithClasses(states.stateIcon, "weather-icon__icon"));
            } else if (this._hadValidWeatherData) {
                this.elements.weatherTempLbl.text("---");
            } else if (this._showWeatherAd) {
                this.elements.weatherIconPlaceholder.append(ImageBox.getResourceImageWithClasses(Icon.Weather.AD_ICON, "weather-icon__icon"));
            }
        }

        // Context Menu
        hasContextMenu() {
            // override!
            return false;
        }

        getContextMenuOptions() {// override!
        }

        getContextMenuTitle() {
            return this.getTitle();
        }

        _handleContextMenuBtn(ev, hammerEv) {
            this._showContextMenu(this.getContextMenuOptions(), this.getContextMenuTitle(), hammerEv.currentTarget);
        }

        onContextMenuHide() {
            this.elements.tableViewPlaceholder.removeClass("stop-scrolling");
        }

        showSortingContextMenu(cell, section, row) {// don't use, causes bug on SD devices to scroll top
            //this.elements.tableViewPlaceholder.addClass("stop-scrolling");

            this._showContextMenu(this.getSortingContextMenuOptions(cell), "", cell.element[0], GUI.LxContextMenuV2);
        }

        hideSortingContextMenu(cell, section, row) {
            this.elements.tableViewPlaceholder.removeClass("stop-scrolling");

            this._hideContextMenu();
        }

        getSortingContextMenuOptions(cell) {
            var contextMenuContent = [],
                type = "control";

            if (!cell.content.control) {
                type = "group";
            }

            contextMenuContent.pushObject(this._createExpertModeLite(cell.content[type]));
            contextMenuContent.pushObject(this._createExpertMode(cell.content[type]));
            contextMenuContent.pushObject(this._createSortingMode());
            return contextMenuContent;
        }

        // Private Methods
        _getShowExpertMode(control) {
            return ActiveMSComponent.isExpertModeEnabled() && (!control.uuidParent || !!control.parentControl);
        }

        _getShowExpertModeLight(control) {
            return ActiveMSComponent.isExpertModeLightEnabled() && (!control.uuidParent || !!control.parentControl);
        }

        _createExpertModeLite(control) {
            if (this._getShowExpertModeLight(control)) {
                return {
                    title: _("context-menu.visualization"),
                    action: function () {
                        NavigationComp.showControlSettings(control, true);
                    }.bind(this)
                };
            }
        }

        _createExpertMode(control) {
            if (this._getShowExpertMode(control)) {
                return {
                    title: _("context-menu.expert-mode"),
                    action: function () {
                        NavigationComp.showControlSettings(control, false);
                    }.bind(this)
                };
            }
        }

        _addAsFavorite(control, location, groupUuid, group) {
            if (ActiveMSComponent.isExpertModeLightEnabled() && !NavigationComp.getCurrentActivityType()) {
                ActiveMSComponent.addFavorite(control, location, groupUuid, group);
            }
        }

        _removeAsFavorite(control, location, groupUuid, group) {
            if (ActiveMSComponent.isExpertModeLightEnabled() && !NavigationComp.getCurrentActivityType()) {
                ActiveMSComponent.removeFavorite(control, location, groupUuid, group);
            }
        }

        _createSortingMode() {
            if (ActiveMSComponent.isExpertModeLightEnabled() && !NavigationComp.getCurrentActivityType()) {
                return {
                    title: _("context-menu.change-sorting"),
                    action: function () {
                        this.startSorting();
                    }.bind(this)
                };
            } else {
                return null;
            }
        }

        _stopSortingAction() {
            this._stopSorting(true);
        }

        _stopSorting(save) {
            if (save && this._sortingChanged) {
                ActiveMSComponent.createSortingStructureForTab(this.collectionView, this._getFavForIndex.bind(this), this._getSortingLocation.bind(this));
                ActiveMSComponent.updateSharedData();
            }

            this.collectionView.setEditingModeForAllSections(false);
            this.element.removeClass("card-screen--sorting");
        }

        _getSortingLocation(section) {
            throw new Error("_getSortingLocation not implemented!");
        }

        _getFavForIndex(sectionAmount, section, row) {
            throw new Error("_getFavForIndex not implemented");
        }

        _updateScrollTop(animated) {
            var scrollContainer = HD_APP ? this.elements.tableViewPlaceholder : this.elements.contentPlaceholder;

            if (animated) {
                $(scrollContainer.children()[0]).velocity('stop').velocity('scroll', {
                    duration: 350,
                    easing: "easeOutCubic",
                    offset: this._scrollTop,
                    container: scrollContainer,
                    complete: function () {
                        // TODO does flicker on iOS if it's done while scrolling
                        scrollContainer.css("-webkit-overflow-scrolling", "auto");
                        scrollContainer.scrollTop(0);
                        scrollContainer.css("-webkit-overflow-scrolling", "touch");
                    }.bind(this)
                });
            } else {
                scrollContainer.scrollTop(this._scrollTop);
            }
        }

        _updateNotificationsCount(ev, count) {
            var show = false; // count > 0;

            if (show && !this.notificationsButton) {
                this.notificationsButton = new GUI.LxRippleButton($(ImageBox.getResourceImageWithClasses("resources/Images/ActiveMiniserver/Wallmount/button-notification.svg", "right-btns__btn")));
                this.prependSubview(this.notificationsButton, this.elements.rightBtnContainer);
                this.notificationsButton.on(GUI.LxRippleButton.CLICK_EVENT, this._showNotifications.bind(this));
            } else if (!show && this.notificationsButton) {
                this.removeSubview(this.notificationsButton);
                this.notificationsButton = null;
            }
        }

        _showNotifications() {
            SandboxComponent.showNotificationHistory(this.ViewController);
        }

        _showSearchScreen() {
            NavigationComp.showSearchScreen();
        }

        _newsChanged(ev, newsEntries) {
            this.cachedNewsEntries = newsEntries;

            if (this.hasActiveMessageCenterEntry) {
                return;
            }

            var unreadNews = newsEntries.newIds,
                unreadNewsCnt = unreadNews.length,
                newsIcon = ImageBox.getResourceImageWithClasses(Icon.Buttons.NEWS, "news-icon");

            if (unreadNewsCnt >= 1 && !LxServerComponent.getNewsHubOpened()) {
                this._updateDynamicRightButton(newsIcon, window.Styles.colors.green);
            } else {
                this._updateDynamicRightButton(newsIcon, Color.STATE_INACTIVE_B);
            } // jQuery is unable to toggleClass of SVGs, so we need to do it our self


            if (unreadNewsCnt === 0) {
                this.elements.dynamicRightButton[0].setAttribute("class", "right-btns__btn right-btns__dynamic right-btns__dynamic-read");
            } else {
                this.elements.dynamicRightButton[0].setAttribute("class", "right-btns__btn right-btns__dynamic");
            }

            this.toggleSubview(this.dynamicRightButton, newsEntries && newsEntries.feed);
        }
*/
        /**
         * MessageCenter has been updated, check what entry needs to be displayed
         * If no entry needs to be shown, the news will be shown instead if any are available
         * @param ev
         * @param messageCenterStructure
         * @private
         */
/*
        _messageCenterUpdate(ev, messageCenterStructure) {
            var shouldShowMessageElementButton = false; // Check if there are any entries, eg. There are no entries if the last one got deleted.

            if (messageCenterStructure && messageCenterStructure.entries.length) {
                var activeEntry = messageCenterStructure.entries.find(function (entry) {
                        return entry.entryUuid === messageCenterStructure.activeEntryUuid;
                    }),
                    unreadEntries = messageCenterStructure.entries.filter(function (entry) {
                        return !entry.readAt;
                    }),
                    severityColor,
                    icon; // Only show the MessageCenter if we have an activeEntry. The news are shown instead

                if (activeEntry) {
                    this.hasActiveMessageCenterEntry = true;
                    shouldShowMessageElementButton = true;
                    icon = ImageBox.getResourceImageWithClasses(Icon.MESSAGE_CENTER.HEALTH_CIRCLED, "health-status-icon severity-" + activeEntry.severity);
                    severityColor = MessageCenterHelper.getColorForSeverityEntry(activeEntry);

                    this._updateDynamicRightButton(icon, severityColor);

                    if (unreadEntries.length) {
                        this.elements.notification.element.show();

                        if (Color.APP_BACKGROUND_TERTIARY !== Color.APP_BACKGROUND_TERTIARY_B) {
                            console.info("MessageCenterNotification differs from the Dark color! -> " + Color.APP_BACKGROUND_TERTIARY);
                            console.info("  body classes -> " + $("body").attr("class"));
                            console.info("  DarkMode state -> " + NavigationComp.darkModeActive);
                        }

                        this.elements.notification.element.css("background-color", Color.APP_BACKGROUND_TERTIARY);
                        this.elements.notification.text.text(_("message-center.x-unread-entries", {
                            count: unreadEntries.length
                        }));
                    } else {
                        shouldShowMessageElementButton = false;
                        this.elements.notification.text.empty();
                    }
                } else {
                    this.hasActiveMessageCenterEntry = false;

                    this._newsChanged(null, this.cachedNewsEntries);
                }
            } else {
                this.hasActiveMessageCenterEntry = false;

                this._newsChanged(null, this.cachedNewsEntries);
            }

            this.toggleSubview(this.notificationButton, shouldShowMessageElementButton);
        }

        _generateNewsCntSvg(unreadNewsCnt) {
            return '<svg version="1.1" x="0px" y="0px" viewBox="0 0 50 50" xml:space="preserve">' + '   <circle cx="25" cy="25" r="25"/>' + '   <text x="25" y="34" style="font-family: ' + FONT.Regular + ';" text-anchor="middle" fill="white">' + '       <tspan style="font-size: 25px;">' + unreadNewsCnt + '</tspan>' + '   </text>' + '</svg>';
        }

        _showNewsHubScreen() {
            NavigationComp.showState(ScreenState.NewsHubScreen);
        }

        _showMessageCenterMessagesScreen() {
            NavigationComp.showState(ScreenState.MessageCenterMessagesScreen);
        }
*/
        /**
         * Allows updating this.elements.dynamicRightButtons html and fillColor with one simple function
         * @param svg
         * @param fillColor
         * @private
         */
/*
        _updateDynamicRightButton(svg, fillColor) {
            this.elements.dynamicRightButton.html(svg);

            if (fillColor) {
                this.elements.dynamicRightButton.css("fill", fillColor);
            }

            this.toggleSubview(this.dynamicRightButton, !!svg);
        }

        _showWeather() {
            if (this._hasValidWeatherData) {
                NavigationComp.showState(ScreenState.Weather);
            } else if (this._showWeatherAd) {
                NavigationComp.showState(ScreenState.WeatherAd);
            }
        }

        updateTitle() {
            this.elements.titleLbl.text(this.getTitle().toUpperCase());
        }

        _registerTime() {
            this.timeIdentifier = SandboxComponent.getMiniserverTimeInfo(this, function (now) {
                this.elements.timeLbl.text(now.format(LxDate.getTimeFormat(true))); //this.elements.dateLbl.text(now.format(LxDate.getDateFormat(DateType.DateText)));
                //this.elements.dayLbl.text(now.format(LxDate.getDateFormat(DateType.Weekday)));
            }.bind(this), TimeValueFormat.MINISERVER_DATE_TIME, 5000);
        }

        _checkEmptyScreen(isEmpty) {
            if (typeof this.getEmptyScreenConfig === "function") {
                this.elements.contentPlaceholderTop.css("display", isEmpty ? "none" : "");
                this.elements.tableViewPlaceholder.css("display", isEmpty ? "none" : "");

                if (isEmpty && !this.elements.emptyScreenElement) {
                    var emptyCfg = this.getEmptyScreenConfig();
                    this.elements.emptyScreenElement = this.constructor.Template.getEmptyScreen(emptyCfg.iconSrc, emptyCfg.title, emptyCfg.subtitle, emptyCfg.significantMsg, emptyCfg.message, emptyCfg.button);
                    this.elements.contentPlaceholder.append(this.elements.emptyScreenElement);

                    if (emptyCfg.button) {
                        this._updateHintBtn = new GUI.LxRippleButton($(this.elements.emptyScreenElement.find(".empty-screen__button")));

                        this._updateHintBtn.on(GUI.LxRippleButton.CLICK_EVENT, function () {
                            NavigationComp.openWebsite(emptyCfg.button.link);
                        }.bind(this));

                        this.addToHandledSubviews(this._updateHintBtn);
                    }
                } else if (this.elements.emptyScreenElement) {
                    this.elements.emptyScreenElement.remove();
                    this.elements.emptyScreenElement = null; // to show the favorite hint though

                    this._checkEmptyScreen(isEmpty);

                    if (this._updateHintBtn) {
                        this.removeSubview(this._updateHintBtn);
                        this._updateHintBtn = null;
                    }
                }
            }
        }

        _shouldBlockTopLeftButton() {
            return SandboxComponent.isRecordingTask();
        }
*/
    };
});
