import {
    useDeviceSearchExtensions,
    SearchableTable,
    LxReactText,
    LxReactDeviceSearchEnums,
    LxReactDeviceSearchResults,
    useBackNavigation
} from "LxComponents"
import {useMemo} from "react";
import globalStyles from "GlobalStyles";
import useTrackRouteInSearchContext from "./useTrackRouteInSearchContext"
import LxReactDeviceSearchBadge from "./LxReactDeviceSearchBadge";
import LxReactDeviceSearchIcon from "./LxReactDeviceSearchIcon";
import useDeviceSearchTitleBar from "./useDeviceSearchTitleBar";
import LxReactDeviceSearchRefresh from "./LxReactDeviceSearchRefresh";
import Icons from "IconLib";
import {View} from "react-native";
import {useConnectionReady} from "../Components";

export default function LxReactDeviceSearchExtensionSelection({navigation, route}) {

    useTrackRouteInSearchContext(LxReactDeviceSearchExtensionSelection.name, route);
    useDeviceSearchTitleBar(navigation, route);
    const connectionReady = useConnectionReady(true);

    useBackNavigation(() => {
        navigation.goBack();
    })

    const searchType = route.params.searchType;
    const miniserver = route.params.miniserver;
    const extensionList = useDeviceSearchExtensions(searchType, miniserver);

    const isOffline = (extension) => {
        return extension.hasOwnProperty("deviceState") && !extension.deviceState
    }

    const handleExtensionSelected = (extension, branch) => {
        navigation.navigate(LxReactDeviceSearchResults.name, {
            ...route.params,
            extension: extension,
            branch: branch,
            searchState: LxReactDeviceSearchEnums.SearchState.BROWSE_RESULTS
        });
    }

    const rightContentForExtension = (extension, branch) => {
        let result, foundDevices, loadingFoundDevices = false;
        if (isOffline(extension)) {
            result = {
                comp: LxReactText,
                props: {style: Style.offlineLabel, children: _("offline")}
            }
        } else {
            if (branch && branch.hasOwnProperty("foundDevices")) {
                foundDevices = branch.foundDevices;
                loadingFoundDevices = branch.loadingFoundDevices || loadingFoundDevices;
            } else {
                foundDevices = extension.foundDevices;
                loadingFoundDevices = extension.loadingFoundDevices || loadingFoundDevices;
            }
            if (foundDevices || ActiveMSComponent.canShowFoundDevices()) {
                result = {
                    comp: LxReactDeviceSearchBadge,
                    props: {
                        loading: loadingFoundDevices,
                        deviceCount: foundDevices
                    }
                }
            }
        }
        return result;
    }

    const getTotalFoundDevices = () => {
        let sum = 0;
        extensionList.forEach(ex => {
            if (ex.foundDevices && ex.foundDevices > 0) {
                sum += ex.foundDevices;
            }
        });
        return sum;
    }

    const getTotalIsLoadingFoundDevices = () => {
        let isLoading = false;
        extensionList.forEach(ex => {
            isLoading = isLoading || ex.loadingFoundDevices;
        });
        return isLoading;
    }


    const renderOnlineView = (icon, hasOnlineState = false, isOnline = false, isTotal = false) => {
        return {
            comp: LxReactDeviceSearchIcon,
            props: {
                icon,
                hasOnlineState,
                isOnline,
                isTotal
            }
        }
    }

    const createRowForSearchAllExtensions = () => {
        return {
            title: _("device-learning.total-found-devices"),
            disabled: !connectionReady,
            onPress: () => handleExtensionSelected(searchType),
            mainLeftContent: renderOnlineView(null, false, false, true),
            mainLeftStyle: Style.iconBox,
            mainRightContent: {
                comp: LxReactDeviceSearchBadge,
                props: {
                    loading: getTotalIsLoadingFoundDevices(),
                    deviceCount: getTotalFoundDevices()
                }
            }
        }
    }

    const createRowForExtension = (extension) => {
        Debug.DeviceSearch.Extensions && console.log(LxReactDeviceSearchExtensionSelection.name, "createRowForExtension: ", extension);
        return {
            title: extension.name,
            subTitle: extension ? ActiveMSComponent.getDeviceMountingLocation(extension) : null,
            onPress: () => handleExtensionSelected(extension),
            disabled: isOffline(extension),
            mainLeftContent: renderOnlineView(extension.imageUrl ?? ' ', false),
            mainLeftStyle: Style.iconBox,
            mainRightContent: rightContentForExtension(extension)
        }
    }

    const iconForBranch = (extension, branch) => {
        let image = extension.imageUrl;
        if (searchType === DeviceManagement.TYPE.TREE) {
            image = Icons.Clamp;
        }
        return renderOnlineView(image, false);
    }

    const createBranchRowsForExtension = (extension) => {
        Debug.DeviceSearch.Extensions && console.log(LxReactDeviceSearchExtensionSelection.name, "createBranchRowsForExtension: ", extension);
        let captions = [];
        if(Feature.GENERIC_DEVICE_SEARCH) {
            captions = extension.captions || [];
        } else {
            captions = extension.subCaptions || [];
        }
        return captions.map(branch => {
            let subtitle = extension.name,
                mountingLoc = ActiveMSComponent.getDeviceMountingLocation(extension);
            if (mountingLoc) {
                subtitle += (SEPARATOR_SYMBOL + mountingLoc);
            }
            return {
                title: branch.name,
                subTitle: subtitle,
                onPress: () => handleExtensionSelected(extension, branch),
                disabled: isOffline(extension),
                mainLeftContent: iconForBranch(extension, branch),
                mainLeftStyle: Style.iconBox,
                mainRightContent: rightContentForExtension(extension, branch)
            }
        });
    }

    const createRowsForExtensions = (extensions) => {
        let rows = [];
        extensions.forEach(extension => {
           if (extension.subCaptions && extension.subCaptions.length > 1 || extension.captions && extension.captions.length > 1) {
               rows.push(...createBranchRowsForExtension(extension));
           } else {
               rows.push(createRowForExtension(extension));
           }
        });

        if (!connectionReady) {
            rows.forEach(exRow => { exRow.disabled = true; });
        }

        return rows;
    }

    const tableContent = useMemo(() => {
        let content = [];

        const renderFooter = (text) => {
            return <LxReactText key="footer" style={globalStyles.customStyles.sectionFooter}>
                {text}
            </LxReactText>
        }
        const renderHeader = (text) => {
            return <LxReactText key="header"  style={[
                globalStyles.customStyles.sectionHeader,
                globalStyles.textStyles.title3.bold
            ]}>
                {text}
            </LxReactText>
        }

        if (extensionList && extensionList.length > 0) {
            // search on all extensions
            ActiveMSComponent.canSearchOverAllExtensions() && content.push({rows: [createRowForSearchAllExtensions()]});

            // create sections for each MS.
            let msMappedExtensions = {};
            extensionList.forEach(ex => { // msSerial is also available
                msMappedExtensions[ex.msSerial || ex.msName] = msMappedExtensions[ex.msSerial || ex.msName] || [];
                msMappedExtensions[ex.msSerial || ex.msName].push(ex);
            });

            if (miniserver || Object.values(msMappedExtensions).length === 1) {
                // create only one section for the selected/only available Miniserver.
                content.push({
                    headerElement: ActiveMSComponent.canSearchOverAllExtensions() ? renderHeader("") : null,
                    rows: createRowsForExtensions(miniserver ? extensionList : Object.values(msMappedExtensions)[0])
                });
            } else {
                Object.keys(msMappedExtensions).forEach(msKey => {
                    let msContent = {
                        headerElement: renderHeader(msMappedExtensions[msKey][0].msName),
                        rows: createRowsForExtensions(msMappedExtensions[msKey])
                    };
                    content.push(msContent);
                });
            }


        } else if (extensionList && extensionList.length === 0) {
            // nothing searchable
            content.push({
                rows: [],
                footerElement: renderFooter(_("device-learning.no-extensions-available"))
            })
        } else {
            content.push({
                rows: [],
                footerElement: renderFooter(_("device-learning.loading-extensions"))
            })
        }

        Debug.DeviceSearch.Extensions && console.log(LxReactDeviceSearchExtensionSelection.name, "extensions=" +JSON.stringify(extensionList));
        return content;
    }, [JSON.stringify(extensionList).hashCode(), connectionReady]);

    return <View style={Style.rootCntr}>
        <SearchableTable containerStyle={Style.table} tableContent={tableContent} />
        <LxReactDeviceSearchRefresh style={Style.refresh} searchType={searchType} />
    </View>
}

const Style = {
    offlineLabel: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.red
    },
    iconBox: {
        background: globalStyles.colors.grey["600"],
        width: 48,
        minHeight: 48,
        height: "100%",
        justifyContent: "center",
        alignContent: "center"
    },
    rootCntr: {
        flexDirection: "column",
        height: "100%",
    },
    table: {
        flex: 1
    }
}
