import * as React from "react"
import Svg, { Path } from "react-native-svg"

function TrustUserGroup(props) {
    return (
        <Svg isreact="true"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path d="M21 9a3 3 0 11-2.191 5.05l-3.15 1.717a3.006 3.006 0 010 1.466l3.15 1.718a3 3 0 11-.719 1.316l-3.15-1.717a3 3 0 110-4.1l3.15-1.717A3 3 0 0121 9zM9.334 5.539c.69.96 1.504 1.417 2.538 1.417l.146-.005.2-.017c.373-.037.917-.13 1.349-.317.031 2.038-.754 3.844-1.965 4.963l-.147.131v.478a4.498 4.498 0 00-1.887 1.129A4.486 4.486 0 008.25 16.5a4.486 4.486 0 002.013 3.75H.545a.556.556 0 01-.54-.496L0 19.676V17.97c0-1.335.764-2.536 1.916-3.04l.146-.06L6 13.387V11.71c-1.368-1.156-2.275-3.157-2.08-5.493.913.545 1.512.665 2.212.665 1.16 0 2.43-.559 3.202-1.342zm6.546 0c.69.96 1.505 1.417 2.539 1.417l.146-.005.2-.017c.373-.037.916-.13 1.348-.317a7.53 7.53 0 01-.049.98l-.03.007a4.492 4.492 0 00-2.216 1.214l-.14.147a4.483 4.483 0 00-1.173 2.817l-.003.108-1.407.768-.232-.132A4.48 4.48 0 0012.75 12l.017.001c1.214-1.368 1.99-3.476 1.887-5.621.464-.227.896-.507 1.226-.841zM8.727.75c2.312 0 4.244 2.014 4.728 4.697-.275.098-.596.2-.966.295-1.208.244-1.926-.154-2.641-1.47-.1-.183-.276-.27-.485-.29a.54.54 0 00-.434.243l-.04.067c-.372.754-1.687 1.44-2.757 1.44l-.246-.003c-.438-.02-.897-.127-1.777-.719C4.72 2.553 6.547.75 8.727.75zm6.546 0c2.311 0 4.243 2.014 4.729 4.697-.275.098-.597.2-.967.295-1.207.244-1.925-.154-2.64-1.47-.101-.183-.277-.27-.485-.29a.536.536 0 00-.435.242l-.039.067c-.161.326-.501.634-.917.884-.262-1.39-.86-2.626-1.688-3.58.717-.528 1.546-.845 2.442-.845z" />
        </Svg>
    )
}

export default TrustUserGroup
