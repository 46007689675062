'use strict';

define(["NfcCodeTouchControlEnums"], function (NfcCodeTouchControlEnums) {
    return class NfcCodeTouchControlContentLearnNfcTag extends Controls.ControlContent {
        constructor() {
            super(...arguments);
            this.currentNfcTag = "null";
            this.popupId = null;
        }

        viewDidLoad() {
            var all = [ActiveMSComponent.getUsers(), super.viewDidLoad(...arguments) || true];
            return Q.all(all).then(function (results) {
                this.users = results[0];
                this.setUpComfortUI(false);
            }.bind(this), function (e) {
                console.error("unable to download users");
                throw e;
            });
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments);
            this.setTitle(this.details.control.name);
            this.setSubtitle("Halten Sie Ihren NFC-Tag ");
            this.sendCommand("nfc/startlearn");
            this.sendCmd = setInterval(function () {
                this.sendCommand("nfc/startlearn"); //TODO ganselu: add command to command.js
            }.bind(this), 10000);
        }

        viewWillDisappear() {
            clearInterval(this.sendCmd);
            this.sendCommand("nfc/stoplearn"); //TODO ganselu: add command to command.js

            super.viewWillDisappear(...arguments);
        }

        getURL() {
            return "LEARN_NFCTAG";
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        receivedStates(states) {
            //response states.nfcLearnResult
            // {id: "25 A0 32 A1 A4 30 2B 40 EC", name: null, userUuid: null, deviceUuid: null}
            var nfcTag = states.nfcLearnResult;
            console.log(nfcTag);
            /*if (JSON.stringify(nfcTag) !== this.currentNfcTag) {
                if (this.popupId !== null) {
                    NavigationComp.removePopup(this.popupId)
                }
                 this.currentNfcTag = JSON.stringify(nfcTag);*/

            if (nfcTag !== null) {
                if (nfcTag.userUuid === null && nfcTag.deviceUuid === null) {
                    this._createNewTag(nfcTag); //.then(function(res) {
                    //this.popupId = res;
                    //}).bind(this);

                } else if (nfcTag.userUuid !== null || nfcTag.deviceUuid !== null) {
                    this._overrideNfcTag(nfcTag); //.then(function (res) {
                    //this.popupId = res;
                    //}).bind(this);

                }
            } //}

        }

        // Private functions
        _createNewTag(tagInfo) {
            var cmd;
            return NavigationComp.showPopup({
                title: "Tag wurde erkannt!",
                input: {
                    id: "nfcTagName",
                    required: false,
                    placeholder: _("nfc.nfc-tag")
                },
                buttonOk: "Übernehmen",
                buttonCancel: true
            }, PopupType.INPUT).then(function (res) {
                console.log("send Command"); //dev/sps/addusernfc/12db1b44-00ee-2665-ffff403fb0c34b9e/4A 74 48 7E DA 25 60 74 EC/Test

                ActiveMSComponent.sendAddNfcToUser(this.details.user, tagInfo.id, res.result).then(this._checkResponse.bind(this));
            }.bind(this));
        }

        _overrideNfcTag(tag) {
            var nfcTagAssignment;

            if (tag.userUuid !== null) {
                nfcTagAssignment = this._getUserName(tag); //nfcTagAssignment = tag.userUuid;
            } else if (tag.deviceUuid !== null) {
                nfcTagAssignment = tag.deviceUuid;
            }

            return NavigationComp.showPopup({
                title: "Tag wurde erkannt!",
                message: "Dieser NFC-Tag ist bereits zugeordnet: " + '"' + nfcTagAssignment + '"',
                input: {
                    id: "nfcTagName",
                    required: false,
                    placeholder: _("nfc.nfc-tag")
                },
                buttonOk: "Überschreiben",
                buttonCancel: true
            }, PopupType.INPUT).then(function (res) {
                //jdev/sps/addusernfc/12db1b44-00ee-2665-ffff403fb0c34b9e/4A 74 48 7E DA 25 60 74 EC/Teset
                ActiveMSComponent.sendAddNfcToUser(this.details.user, tag.id, res.result).then(this._checkResponse.bind(this));
            }.bind(this));
        }

        _checkResponse(res) {
            var stateCode = res.LL.Code,
                stateCodeText = "";

            switch (stateCode) {
                case "200":
                    stateCodeText = "Tag wurde erfolgreich eingelernt";
                    break;

                case "500":
                    stateCodeText = _("adminIf.error.unknown");
                    break;
            }

            return NavigationComp.showPopup({
                title: stateCodeText,
                buttonOk: _("finish"),
                buttonCancel: false
            }, PopupType.GENERAL);
        }

        _getUserName(tag) {
            for (var i = 0; i < this.users.length; i++) {
                if (tag.userUuid === this.users[i].uuid) {
                    return this.users[i].name;
                }
            }
        }

    };
});
