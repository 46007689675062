'use strict';
/**
 * Used to set limits for the average outside temperature to specify whether or not heating or cooling is allowed
 *
 * Important Note:
 * the IRC only supports temperature limits when both heating and cooling are allowed. This is different
 * than in the climate controller. This screen still is capable of handling both situations, as it may
 * be used as base for the climate-controllers screen in future.
 */

define(["IRoomControllerV2ControlEnums"], function (IRCV2Enums) {
    return class AvgTempLimitsScreen extends GUI.TableViewScreen {
        //region Static
        static Template = function () {
            var getInfoSectionHeader = function getInfoSectionHeader(infoText, heatingStateText, coolingStateText) {
                var currentState = "";

                if (heatingStateText) {
                    currentState += "<p class='info-text__current-state info-text__current-state--heating'>" + heatingStateText + "</p>";
                }

                if (coolingStateText) {
                    currentState += "<p class='info-text__current-state info-text__current-state--cooling'>" + coolingStateText + "</p>";
                }

                return $('<div class="section__header-view">' + '   <div class="header-view__info-text">' + infoText + currentState + '</div>' + '</div>');
            };

            return {
                getInfoSectionHeader: getInfoSectionHeader
            };
        }(); //endregion Static

        constructor(details) {
            super($('<div />'));
            this.control = details.control; // use the provided opMode, don't retrieve from the states, maybe the state updates aren't that fast!

            this.operatingMode = details.operatingMode;
            this.temps = {
                onlyHeating: this.getOnlyHeatingBoundary(),
                onlyCooling: this.getOnlyCoolingBoundary()
            };
            this.initialTemps = cloneObject(this.temps);
        }

        viewWillDisappear() {
            // Only send the temperatures if at least one of them has changed
            if (this.initialTemps.onlyHeating !== this.temps.onlyHeating) {
                this._send(this.getHeatingLimitCommand(), this.temps.onlyHeating);
            }

            if (this.initialTemps.onlyCooling !== this.temps.onlyCooling) {
                this._send(this.getCoolingLimitCommand(), this.temps.onlyCooling);
            }

            super.viewWillDisappear(...arguments);
        }

        titleBarText() {
            return _("controls.climate.temp-boundary", {
                count: this._supportsHeatingAndCooling() ? 2 : 1
            });
        }

        reloadTable() {
            this.tableContent = this._getTableContent();
            return super.reloadTable(...arguments);
        }

        // -----------------------------------------------------------------------------------------------
        //                      Overwrite if needed
        // -----------------------------------------------------------------------------------------------
        // ---- Commands -----
        getHeatingLimitCommand() {
            return Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.HEATING_LIMIT;
        }

        getCoolingLimitCommand() {
            return Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.COOLING_LIMIT;
        }

        // ---- Temperatures -----
        getAvgOutdoorTemp() {
            return this.control.getStates().averageOutdoorTemp;
        }

        getActualOutdoorTemp() {
            return this.control.getStates().actualOutdoorTemp;
        }

        getOnlyCoolingBoundary() {
            return this.control.getStates().coolingTempBoundary;
        }

        getOnlyHeatingBoundary() {
            return this.control.getStates().heatingTempBoundary;
        }

        // ---- Configuration -----
        getMaxTemperature() {
            return IRCV2Enums.TempBoundaries.Outside.MAX;
        }

        getMinTemperature() {
            return IRCV2Enums.TempBoundaries.Outside.MIN;
        }

        getTemperatureStep() {
            return 0.5;
        }

        // ---- Temp Boundary Info -----

        /**
         * Might return null, if not supported
         * @returns {*}
         */
        getTempBoundaryInfo() {
            return this.control.getStates().temperatureBoundaryInfo;
        }

        getBoundaryInfoHasNotEnoughData() {
            return this.getTempBoundaryInfo() === IRCV2Enums.BoundaryInfo.NOT_ENOUGH_DATA;
        }

        getBoundaryInfoIsOkay() {
            return this.getTempBoundaryInfo() === IRCV2Enums.BoundaryInfo.OK;
        }

        getBoundaryInfoHasNoData() {
            return this.getTempBoundaryInfo() === IRCV2Enums.BoundaryInfo.NO_DATA;
        }

        // ---- Operating Mode Detection -----
        getIsAutoHeatCool() {
            return this.getOpMode() === IRCV2Enums.OperatingMode.AUTOMATIC.HEATING_AND_COOLING || this.getOpMode() === IRCV2Enums.OperatingMode.MANUAL.HEATING_AND_COOLING;
        }

        getIsAutoHeatOnly() {
            return this.getOpMode() === IRCV2Enums.OperatingMode.AUTOMATIC.ONLY_HEATING || this.getOpMode() === IRCV2Enums.OperatingMode.MANUAL.ONLY_HEATING;
        }

        getIsAutoCoolOnly() {
            return this.getOpMode() === IRCV2Enums.OperatingMode.AUTOMATIC.ONLY_COOLING || this.getOpMode() === IRCV2Enums.OperatingMode.MANUAL.ONLY_COOLING;
        }

        getOpMode() {
            // use the opMode from details, don't retrieve from the states, maybe the state updates aren't that fast!
            return this.operatingMode;
        }

        // ---- Section Description Getters -----
        getNoHeatingAboveDescription() {
            return _("controls.ircv2.op-mode.heating-limit-description");
        }

        getNoCoolingBelowDescription() {
            return _("controls.ircv2.op-mode.cooling-limit-description");
        }

        // -----------------------------------------------------------------------------------------------
        //                      Private
        // -----------------------------------------------------------------------------------------------
        _getTableContent() {
            var content = [],
                infoText,
                avgTempStr = this.getAvgOutdoorTemp(),
                onlyCoolingObject,
                onlyHeatingObject,
                tempBoundaryInfo = this.getTempBoundaryInfo();
            var heatingStateText, coolingStateText;

            if (tempBoundaryInfo != null) {
                if (this.getBoundaryInfoHasNotEnoughData()) {
                    infoText = _("controls.climate.temp-boundary.info.not-enough-data"); // No avg temperature is available, set it to the current outdoor temp to prevent showing -1000 in the UI

                    avgTempStr = this.getActualOutdoorTemp();
                } else if (this.getBoundaryInfoIsOkay()) {
                    infoText = _("controls.climate.temp-boundary.info.ok", {
                        avgTemp: lxFormat("%.1f°", avgTempStr)
                    });
                } else if (this.getBoundaryInfoHasNoData()) {
                    infoText = _("controls.climate.temp-boundary.info.no-data");
                } // with a proper boundary, show which mode currently is active!


                if (this.getBoundaryInfoIsOkay()) {
                    if (this.getAvgOutdoorTemp() < this.getOnlyCoolingBoundary() && !this.getIsAutoCoolOnly()) {
                        heatingStateText = _("controls.climate.allow.heating.state");
                    } else {
                        heatingStateText = _("controls.climate.disallow.heating.state");
                    }

                    if (this.getAvgOutdoorTemp() > this.getOnlyHeatingBoundary() && !this.getIsAutoHeatOnly()) {
                        coolingStateText = _("controls.climate.allow.cooling.state");
                    } else {
                        coolingStateText = _("controls.climate.disallow.cooling.state");
                    }
                }

                content.push({
                    headerElement: AvgTempLimitsScreen.Template.getInfoSectionHeader(infoText, heatingStateText, coolingStateText)
                });
            }

            onlyCoolingObject = {
                title: _("controls.climate.temp.no.heating"),
                sectionTitle: _("controls.climate.allow.cooling.not-tempeature"),
                sectionDescription: this.getNoHeatingAboveDescription(),
                sectionValue: this.temps.onlyCooling,
                onSliderChanged: this._onCoolingBoundaryChange.bind(this)
            };
            onlyHeatingObject = {
                title: _("controls.climate.temp.no.cooling"),
                sectionTitle: _("controls.climate.allow.heating.not-tempeature"),
                sectionDescription: this.getNoCoolingBelowDescription(),
                sectionValue: this.temps.onlyHeating,
                onSliderChanged: this._onHeatingBoundaryChange.bind(this)
            }; // Add the needed sections to the content depending on the settings

            if (!this.getBoundaryInfoHasNoData()) {
                if (this.getIsAutoHeatCool()) {
                    content.push(this._createSection(onlyCoolingObject));
                    content.push(this._createSection(onlyHeatingObject));
                } else if (this.getIsAutoHeatOnly()) {
                    content.push(this._createSection(onlyCoolingObject));
                } else if (this.getIsAutoCoolOnly()) {
                    content.push(this._createSection(onlyHeatingObject));
                }
            }

            return content;
        }

        _supportsHeatingAndCooling() {
            return !this.getBoundaryInfoHasNoData() && this.getIsAutoHeatCool();
        }

        _onCoolingBoundaryChange(cell, rowIdx, sectionIdx, tableView, value) {
            this.temps.onlyCooling = value;
            this.temps.onlyHeating = Math.min(this.temps.onlyCooling, this.temps.onlyHeating); // Modify the position of the other cells slider to archive a relative like feeling

            if (this.getIsAutoHeatCool()) {
                this.tableView.cellForSectionAndRow(2, 0).setPosition(this.temps.onlyHeating);
            }

            this._updateHeaderText(true, value);
        }

        _onHeatingBoundaryChange(cell, rowIdx, sectionIdx, tableView, value) {
            this.temps.onlyHeating = value;
            this.temps.onlyCooling = Math.max(this.temps.onlyCooling, this.temps.onlyHeating); // Modify the position of the other cells slider to archive a relative like feeling

            if (this.getIsAutoHeatCool()) {
                this.tableView.cellForSectionAndRow(1, 0).setPosition(this.temps.onlyCooling);
            }

            this._updateHeaderText(false, value);
        }

        /**
         * creates a section from an object. so that the object could be identified the
         * "isHeatingObject" variable either be set to true or false
         * object includes:
         * - sectionTitle
         * - sectionDescription
         * - sectionValue
         * - avgTempStr
         * - onSliderChanged
         * - operator
         * @param object
         * @param isHeatingObject
         * @returns {Object}
         * @private
         */
        _createSection(object) {
            return {
                headerTitle: object.sectionTitle,
                rows: [{
                    content: {
                        title: object.title,
                        minValue: this.getMinTemperature(),
                        value: object.sectionValue,
                        maxValue: this.getMaxTemperature(),
                        step: this.getTemperatureStep(),
                        format: " %.1f°",
                        minIconSrc: Icon.MINUS,
                        maxIconSrc: Icon.PLUS,
                        valueColor: window.Styles.colors.stateActive,
                        antiGhostTimer: false
                    },
                    type: GUI.TableViewV2.CellType.SLIDER,
                    sliderDragged: object.onSliderChanged,
                    sliderClicked: object.onSliderChanged
                }],
                footerTitle: object.sectionDescription
            };
        }

        _updateHeaderText(isHeating, value) {
            var text, compareValue;

            if (!this.getIsAutoCoolOnly()) {
                compareValue = isHeating ? value : this.temps.onlyCooling;

                if (compareValue > this.getAvgOutdoorTemp()) {
                    text = _("controls.climate.allow.heating.state");
                } else {
                    text = _("controls.climate.disallow.heating.state");
                }

                this.element.find('.info-text__current-state--heating').text(text);
            }

            if (!this.getIsAutoHeatOnly()) {
                compareValue = isHeating ? this.temps.onlyHeating : value;

                if (compareValue < this.getAvgOutdoorTemp()) {
                    text = _("controls.climate.allow.cooling.state");
                } else {
                    text = _("controls.climate.disallow.cooling.state");
                }

                this.element.find('.info-text__current-state--cooling').text(text);
            }
        }

        _send(cmdFormat, temp) {
            SandboxComponent.sendCommand(this, this.control.uuidAction, Commands.format(cmdFormat, temp), null, this.control.isSecured, true // This is the "automatic" flag, enable it to prevent a "bad connection" popup
            );
        }

    };
});
