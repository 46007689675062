import * as React from "react"
import Svg, {Path} from "react-native-svg";

function ExpandArrows(props) {
    return (<Svg isreact="true"
                 width="20"
                 height="20"
                 viewBox="0 0 20 21"
                 xmlns="http://www.w3.org/2000/svg"
                 {...props}>
        <Path
            d="M13 0.5C12.4477 0.5 12 0.947715 12 1.5C12 2.05228 12.4477 2.5 13 2.5H16.5858L10.7929 8.29289C10.4024 8.68342 10.4024 9.31658 10.7929 9.70711C11.1834 10.0976 11.8166 10.0976 12.2071 9.70711L18 3.91421V7.5C18 8.05228 18.4477 8.5 19 8.5C19.5523 8.5 20 8.05228 20 7.5V1.5C20 0.947715 19.5523 0.5 19 0.5H13Z"
            fill="#EBEBF5"/><Path
        d="M7 20.5C7.55229 20.5 8 20.0523 8 19.5C8 18.9477 7.55229 18.5 7 18.5H3.41421L9.20711 12.7071C9.59763 12.3166 9.59763 11.6834 9.20711 11.2929C8.81658 10.9024 8.18342 10.9024 7.79289 11.2929L2 17.0858V13.5C2 12.9477 1.55228 12.5 1 12.5C0.447716 12.5 0 12.9477 0 13.5V19.5C0 20.0523 0.447716 20.5 1 20.5H7Z"
        fill="#EBEBF5"/>
    </Svg>)
}

export default ExpandArrows
