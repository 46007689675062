import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function LoxoneHeart(props) {
    return (
        <Svg isreact="true"
            width={120}
            height={120}
            viewBox="0 0 120 120"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <G fill="none">
                <Path
                    d="M0 24h60L30 0 0 24v48l30 24 30 24 30-24-30-24h60V24L90 0 60 24h60zm120 48L90 96 60 72h60z"
                    fill="#69C350"
                />
                <Path fill="#87CF73" d="M90 48L60 72 30 48z" />
                <Path
                    fill="#69C350"
                    d="M30 48L0 24v24zm30 24L30 48 0 72zm0-48L30 0 0 24zm60 48L90 48 60 72zm0-48L90 0 60 24zM90 96L60 72 30 96zm0-48L60 24 30 48zm30-24L90 48h30z"
                />
                <Path
                    fill="#87CF73"
                    d="M30 48L0 72V48zm90 24L90 48h30zM90 96l-30 24-30-24z"
                />
                <Path
                    fill="#77C861"
                    d="M60 72L30 96 0 72zm0-48L30 48 0 24zm60 48L90 96 60 72zm0-48L90 48 60 24z"
                />
            </G>
        </Svg>
    )
}

export default LoxoneHeart
