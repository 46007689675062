'use strict';

define('AboutScreenBase', [], function () {
    return class AboutScreenBase extends GUI.TableViewScreen {

        get useNewTableView() {
            return true;
        }

        constructor() {
            super($('<div />'));
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.setUpComfortUI(true);
                var resizeIconClass = this.resizeIconClass();

                if (resizeIconClass) {
                    GUI.animationHandler.addCssClass(this.element, resizeIconClass);
                }
            }.bind(this));
        }

        styleForTableView() {
            return TableViewStyle.TRANSLUCENT;
        }

        /**
         * Add a class that is responsible for resizing the top icon
         */
        resizeIconClass() {
            return "abut-screen-base--no-circle";
        }

        /**
         * Creates the cell object with title & descriptionText, which allows to copy the description text via a copy
         * popup.
         * @param title
         * @param value
         * @returns {{content: {title: *, disclosureText: *}}}
         * @private
         */
        _createInfoCell(title, value) {
            let rowObject = {
                content: {
                    title: title,
                    disclosureText: value,
                    disclosureTextProps: {
                        numberOfLines: 1
                    },
                    mainRightStyle: {
                        maxWidth: "50%"
                    }
                }
            };

            this._addCopyActionForDisclosureText(rowObject);

            return rowObject;
        }

        /**
         * Puts a copy action on the
         * @param rowObject the rowobject to put the action on.
         * @param [title] if not provided, the title of the rowObject content is used
         * @param [message] if not provided, the disclosureText of the rowObject content is used
         * @private
         */
        _addCopyActionForDisclosureText(rowObject, title, message) {
            if (message && message !== "" || rowObject.content.disclosureText && rowObject.content.disclosureText !== "") {
                rowObject.content.copyable = true;
            } else {
                rowObject.content.clickable = false;
            }
        }

    };
});
