import {createContext, useCallback, useMemo, useState} from "react";
import {useLiveState} from "LxComponents";
import PropTypes from "prop-types";
import useControlObject from "../customHooks/useControlObject";

export const ControlContext = createContext({
    reactableControl: null,
    control: null,
    states: null,
    contextMenuOptions: null,
    addContextMenuOption: null
})

function LxControlContextProvider (props) {
    const dynamicControl = useControlObject(props.controlUuid);
    const states = useLiveState(props.controlUuid).states;

    const [ctxtMenuOptions, setCtxtMenuOptions] = useState([]);

    /**
     * Adds the provided option to the context menu while returning a function to be called once it has to be removed
     * again.
     * @type {function(*): function(): void}
     */
    const insertCtxtMenuOption = useCallback((option) => {
        let randomId = getRandomIntInclusive(0, 999999);
        option._identifierId = randomId;
        setCtxtMenuOptions((prevState, props) => {
            return [...prevState, option]
        });

        return () => {
            setCtxtMenuOptions((prevState, props) => {
                return prevState.filter(existingOption => {
                    return existingOption._identifierId !== randomId;
                })
            });
        }
    }, [])

    const contextValue = useMemo(() => {
        return {
            dynamicControl,
            control: dynamicControl.control,
            states,
            isAlert: props.isAlert,
            contextMenuOptions: ctxtMenuOptions,
            addContextMenuOption: insertCtxtMenuOption
        }
    }, [dynamicControl, states, ctxtMenuOptions])

    return <ControlContext.Provider value={contextValue}>
        {props.children}
    </ControlContext.Provider>
}


LxControlContextProvider.propTypes = {
    controlUuid: PropTypes.string.isRequired,
    isAlert: PropTypes.bool
}

export default LxControlContextProvider;
