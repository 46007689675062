import PropTypes from "prop-types";
import {useStatisticV2, StatisticDataPointUnit,LxReactText, LxReactWaitingView, LxReactPressable, useControl } from "LxComponents"
import {ScrollView, View} from "react-native";
import {useRef} from "react";
import globalStyles from "GlobalStyles"

export default function LxReactGraphDummy({controlUuid, fromUnixUtcTs, toUnixUtcTs, outputNames, dataPointUnit, style, preProcessFn}) {

    const actualStat = useStatisticV2(controlUuid, fromUnixUtcTs, toUnixUtcTs, outputNames, dataPointUnit, preProcessFn);
    const control = useControl(controlUuid);
    const shareBtn = useRef(null);

    const handleHeaderPressed = () => {
        let csv = SandboxComponent.getCsvOfPackage(actualStat);
        let name = control.getName() + "-" + outputNames.join("+");
        let msName = ActiveMSComponent.getActiveMiniserver().msName,
            fileName = msName + "-" + name + ".csv";

        exportTextFile(fileName, csv, "text/csv", shareBtn.current);
    }

    const renderStatistics = () => {
        let rendered;
        if (actualStat.header) {
            let headerList = actualStat.header.map((header) => { return ensureLength(header.title) });
            rendered = (
                <View style={{...statStyles.rootCntr, ...style}}>
                    <LxReactPressable onPress={handleHeaderPressed}><View style={statStyles.statHeaderCntr} ref={shareBtn}>
                        <LxReactText style={statStyles.dataHeader}>{"ts         = since2009            "}</LxReactText>
                        <LxReactText style={statStyles.dataHeader}>{headerList.join("  ")}</LxReactText>
                    </View></LxReactPressable>
                    <ScrollView style={statStyles.statDataCntr}>
                        {
                            actualStat.data.map((dpRow, idx) => {
                                return renderDataPoint(dpRow, actualStat.header, idx);
                            })
                        }
                    </ScrollView>
                </View>
            );
        } else if (actualStat.hasOwnProperty("err")) {
            console.error(LxReactGraphDummy.name, "renderStatistics - no data available, failed: ", actualStat);
            rendered = <View style={{...statStyles.rootCntr, ...style}}>
                <LxReactText style={statStyles.error}>{JSON.stringify(actualStat.err)}</LxReactText>
            </View>
        } else {
            rendered = <View style={{...statStyles.rootCntr, ...style}} >
                <LxReactWaitingView message={"loading stats.."} />
            </View>
        }
        return rendered;
    }

    const renderDataPoint = (dp, header, dpIdx) => {
        let valueTexts = dp.values.map((val, idx) => {
            let result = lxFormat(header[idx].format, val);
            return ensureLength(result);
        });
        let datapoint = dp.ts + " = " + new LxDate((dp.ts) * 1000, false).format(DateType.DateAndTimeShortWithSeconds);
        return <LxReactText style={statStyles.data} key={dp.ts + "-" + dpIdx}>{
            datapoint + ": " + valueTexts.join("  ")
        }</LxReactText>
    }

    const ensureLength = (text) => {
        let reqLength = 10,
            result = text;
        for (; result.length < reqLength;) {
            result = " " + result;
        }
        if (text.length > reqLength) {
            result = text.substring(0, reqLength);
        }
        return result;
    }

    return renderStatistics();
}

const statStyles = {
    rootCntr: {
        flex: 1,
        flexDirection: "column",
        flexBasis: "fit-content",
    },
    data: {
        ...globalStyles.textStyles.footNote.default,
        fontFamily: "monospace",
        fontSize: globalStyles.fontSettings.sizes.tiny,
        color: globalStyles.colors.text.secondary
    },
    dataHeader: {
        ...globalStyles.textStyles.footNote.default,
        fontFamily: "monospace",
        fontSize: globalStyles.fontSettings.sizes.tiny,
        color: globalStyles.colors.text.secondary
    },
    statHeaderCntr: {
        flexDirection: "row",
    },
    statDataCntr: {
        flexDirection: "column",
    },
    error: {
        ...globalStyles.textStyles.footNote.default,
        color: globalStyles.colors.red,
        textAlign: "center"
    }
}


LxReactGraphDummy.propTypes = {
    style: PropTypes.object,
    controlUuid: PropTypes.string.isRequired,
    fromUnixUtcTs: PropTypes.number.isRequired,
    toUnixUtcTs: PropTypes.number.isRequired,
    outputNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    dataPointUnit: PropTypes.oneOf(Object.values(StatisticDataPointUnit)),
    preProcessFn: PropTypes.func
}
