import useTrackRouteInSearchContext from "./useTrackRouteInSearchContext";
import {
    useDeviceSearchMiniservers,
    LxReactDeviceSearchExtensionSelection,
    LxReactDeviceSearchEnums,
    LxReactText,
    LxReactDeviceSearchResults,
    SearchableTable,
    useBackNavigation,
} from "LxComponents";
import {useMemo} from "react";
import LxReactDeviceSearchBadge from "./LxReactDeviceSearchBadge";
import LxReactDeviceSearchIcon from "./LxReactDeviceSearchIcon";
import globalStyles from "GlobalStyles";
import useDeviceSearchTitleBar from "./useDeviceSearchTitleBar";
import {View} from "react-native";
import LxReactDeviceSearchRefresh from "./LxReactDeviceSearchRefresh";
import {useConnectionReady} from "../Components";

export default function LxReactDeviceSearchMiniserverSelection({navigation, route}) {
    useTrackRouteInSearchContext(LxReactDeviceSearchMiniserverSelection.name, route);
    const searchType = route.params.searchType;
    const { msList, totalFoundDevices, totalLoadingFoundDevices } = useDeviceSearchMiniservers(searchType);

    useDeviceSearchTitleBar(navigation, route);
    const connectionReady = useConnectionReady(true);

    useBackNavigation(() => {
        navigation.goBack();
    })

    const renderOnlineView = (icon = ' ', hasOnlineState = false, isOnline = false, isTotal = false) => {
        return {
            comp: LxReactDeviceSearchIcon,
            props: {
                icon,
                hasOnlineState,
                isOnline,
                isTotal
            }
        }
    }

    const rightContentForMiniserver = (ms) => {
        let result, foundDevices;
        if (ms.hasOwnProperty("deviceState") && !ms.deviceState) {
            result = {
                comp: LxReactText,
                props: {style: Style.offlineLabel}, text: _("offline")
            }
        } else {
            foundDevices = ms.foundDevices;
            if (foundDevices || ActiveMSComponent.canShowFoundDevices()) {
                result = {
                    comp: LxReactDeviceSearchBadge,
                    props: {
                        loading: ms.loadingFoundDevices,
                        deviceCount: ms.foundDevices
                    }
                }
            }
        }
        return result;
    }

    const handleMsSelected = (ms) => {
        navigation.navigate(LxReactDeviceSearchExtensionSelection.name, {
            ...route.params,
            miniserver: ms,
            searchState: LxReactDeviceSearchEnums.SearchState.SELECT_EXTENSION
        });
    }

    const handleSearchAllSelected = () => {
        navigation.navigate(LxReactDeviceSearchResults.name, {
            ...route.params,
            searchState: LxReactDeviceSearchEnums.SearchState.BROWSE_RESULTS
        });
    }

    const createRowForSearchAllMiniservers = () => {
        return {
            title: _("device-learning.total-found-devices"),
            onPress: () => handleSearchAllSelected(),
            disabled: !connectionReady,
            mainLeftContent: renderOnlineView(null, false, false, true),
            mainLeftStyle: Style.iconBox,
            mainRightContent: {
                comp: LxReactDeviceSearchBadge,
                props: {
                    loading: totalLoadingFoundDevices,
                    deviceCount: totalFoundDevices
                }
            }
        }
    }

    const createRowForMiniserver = (ms) => {
        const hasExtensions = ("extensions" in ms) && ms.extensions.length > 0;
        return {
            title: ms.name,
            subTitle: ms.typeName,
            disabled: !connectionReady || !hasExtensions,
            onPress: () => handleMsSelected(ms),
            mainLeftContent: renderOnlineView(ms.imageUrl, false),
            mainRightContent: rightContentForMiniserver(ms) || null
        }
    }

    const tableContent = useMemo(() => {
        let content = [];

        const renderFooter = (text) => {
            return <LxReactText key="footer" style={globalStyles.customStyles.sectionFooter}>
                {text}
            </LxReactText>
        }
        const renderHeader = (text) => {
            return <LxReactText key="header"  style={[
                globalStyles.customStyles.sectionHeader,
                globalStyles.textStyles.title3.bold
            ]}>
                {text}
            </LxReactText>
        }

        if (msList && msList.length > 0) {
            // search on all miniservers
            const canSearchAll = ActiveMSComponent.canSearchOverAllExtensions();
            canSearchAll && content.push({rows: [createRowForSearchAllMiniservers()]});

            let msContent = {
                headerElement: canSearchAll ? renderHeader("") : null,
                rows: msList.map(createRowForMiniserver)
            };
            content.push(msContent);

        } else if (msList && msList.length === 0) {
            // nothing searchable
            content.push({
                rows: [],
                footerElement: renderFooter(_("device-learning.no-miniservers-available"))
            })
        } else {
            content.push({
                rows: [],
                footerElement: renderFooter(_("device-learning.loading-miniservers"))
            })
        }

        Debug.DeviceSearch.Miniservers && console.log(LxReactDeviceSearchMiniserverSelection.name, "miniservers=" +JSON.stringify(msList));
        return content;
    }, [JSON.stringify(msList).hashCode(), totalFoundDevices, connectionReady]);

    return <View style={Style.rootCntr}>
        <SearchableTable containerStyle={Style.table} tableContent={tableContent} />
        <LxReactDeviceSearchRefresh style={Style.refresh} searchType={searchType} />
    </View>
}

const Style = {
    offlineLabel: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.red
    },
    iconBox: {
        background: globalStyles.colors.grey["600"],
        width: 48,
        minHeight: 48,
        height: "100%",
        justifyContent: "center",
        alignContent: "center"
    },
    rootCntr: {
        flexDirection: "column",
        height: "100%",
    },
    table: {
        flex: 1
    }
}
