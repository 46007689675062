import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Weather00d(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            <Path
                d="M16 9.5a6.5 6.5 0 106.5 6.5A6.487 6.487 0 0016 9.5zM17 7V5a1 1 0 00-2 0v2a1 1 0 002 0zm-6.7 1.9L8.9 7.5a.99.99 0 10-1.4 1.4l1.4 1.4a.99.99 0 001.4-1.4zM7 15H5a1 1 0 000 2h2a1 1 0 000-2zm1.9 6.7l-1.4 1.4a.99.99 0 001.4 1.4l1.4-1.4a.99.99 0 00-1.4-1.4zM15 25v2a1 1 0 002 0v-2a1 1 0 00-2 0zm6.7-1.9l1.4 1.4a.99.99 0 001.4-1.4l-1.4-1.4a.99.99 0 00-1.4 1.4zM25 17h2a1 1 0 000-2h-2a1 1 0 000 2zm-1.9-6.7l1.4-1.4a.99.99 0 00-1.4-1.4l-1.4 1.4a.99.99 0 001.4 1.4z"
                fill="#f4cd27"
            />
            <Path fill="none" d="M0 0H32V32H0z" />
        </Svg>
    )
}

export default Weather00d
