'use strict';

define(["AboutScreenBase", "LxComponents", "GlobalStyles", "IconLib"], function (AboutScreenBase, {
    LxReactButton,
    ButtonType,
    LxReactText
}, {
    default: globalStyles
}, {
    default: Icons
}) {

    return class AboutMiniserverScreen extends AboutScreenBase {
        constructor(details) {
            super($('<div />'));
            this.setDetails(details);
        }

        viewWillAppear() {
            var promise = super.viewWillAppear();
            GUI.animationHandler.toggleCssClass(this.element, "about-miniserver-screen--inactive-ms", !this.isActiveMiniserver);
            this.setTitle(this.miniserver.msName || this.miniserver.serialNo); // icon

            let msType = this.isActiveMiniserver ? ActiveMSComponent.getMiniserverType() : this.miniserver.miniserverType;
            if (!this.hasMSIcon()) {
                // we don't know the type if not the active MS..
                this.setIcon(Icon.PIN);

            } else {
                var imgSrc;
                switch (msType) {
                    case MiniserverType.MINISERVER_GO:
                    case MiniserverType.MINISERVER_GO_V2:
                        imgSrc = Icon.MINISERVER_GO_V2;
                        break;

                    case MiniserverType.MINISERVER_V2:
                        imgSrc = Icon.MINISERVER_V2;
                        break;

                    case MiniserverType.MINISERVER_COMPACT:
                        imgSrc = Icon.MINISERVER_COMPACT;
                        break;

                    default:
                        imgSrc = Icon.MINISERVER;
                        break;
                }

                this.setIcon(imgSrc);
            }

            return promise;
        }

        /**
         * This function defines, if the view should be reused
         * The updateView function will be called instead of the constructor if true is returned
         * @return {boolean}
         */
        shouldBeUpdated() {
            return true;
        }

        updateView(details) {
            super.updateView(...arguments);
            this.setDetails(details);
            this.reloadTable();
        }

        /**
         * It basically initializes all class variables dependent on the details serialNo property
         * ========
         * Keep in mind to add any detail dependent variable to this function to avoid inconsistent data because
         * variables initialized in the constructor will not be initialized if the view has already been initialized before and
         * is reused by the ViewController.
         * This function will be called from the constructor if the view hasn't been loaded yet, or from the updateView function
         * if the view has already been initialized and was part of the view stack.
         * ========
         * @param details
         */
        setDetails(details) {
            var serialNo = details && details.serialNo;
            if (!serialNo) {
                serialNo = ActiveMSComponent.getMiniserverSerialNo();
            }
            this.fromTrustList = !!details.fromTrustList;
            this.miniserver = details.miniserver || PersistenceComponent.getAllMiniserver()[serialNo];
            this.isWebinterface = PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface;
            this.isActiveMiniserver = ActiveMSComponent.getMiniserverSerialNo() === serialNo;
            this.reachMode = this.isActiveMiniserver ? CommunicationComponent.getCurrentReachMode() : ReachMode.NONE;
            this.noActions = details ? details.noActions : false;

            this.isPairedApp = PairedAppComponent.isPaired();
        }

        hasMSIcon() {
            let msType = this.isActiveMiniserver ? ActiveMSComponent.getMiniserverType() : this.miniserver.miniserverType;
            return !(msType === undefined || msType === null);
        }

        resizeIconClass() {
            if (this.hasMSIcon()) {
                var baseClass = super.resizeIconClass(...arguments);
                return baseClass + " about-miniserver-screen--big-img";
            }
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        reloadTable() {
            var rows = [];
            rows.pushObject(this._getMainActionButtonCell());
            !this.fromTrustList && rows.pushObject(this._getConnectionStateCell());
            rows.pushObject(this._createInfoCell(_('miniserver'), this.miniserver.msName || this.miniserver.serialNo));
            if (!this.isPairedApp) {
                rows.pushObject(this._getUserCell());
                rows.pushObject(this._getHostCell());
            }
            if (this.miniserver.localUrl) {
                rows.pushObject(this._createInfoCell(_('miniserver.url.local'), this.miniserver.localUrl));
            }

            // don't use this.miniserver.remoteUrl --> it might be adopted to cloud dns url
            // BUT: only if it's the active miniserver, otherwise it'll show the wrong one in the archive or on the trust-list
            if (this.isActiveMiniserver && ActiveMSComponent.getOriginalRemoteUrl()) {
                rows.pushObject(this._createInfoCell(_('miniserver.url.remote'), ActiveMSComponent.getOriginalRemoteUrl()));
            } else if (this.miniserver.remoteUrl) {
                rows.pushObject(this._createInfoCell(_('miniserver.url.remote'), this.miniserver.remoteUrl));
            }

            rows.pushObject(this._createInfoCell(_('miniserver.serial-number'), this.miniserver.serialNo));
            rows.pushObject(this._getFirmwareVersionCell());
            rows.pushObject(this._getMsLicensesCell());
            if (this.isActiveMiniserver) {
                rows.pushObject(this._getRebootCell());
            }

            if (!this.isPairedApp && !this.fromTrustList) {
                rows.pushObject(this._getDeleteMsCell());
            }
            if (this.isPairedApp) {
                rows.pushObject(this._getUnpairAppCell());
            }

            this.tableContent = [{ rows: rows }];

            return super.reloadTable(...arguments);
        }

        /**
         * Connect/Disconnect/Reboot Button Row
         * @private
         */
        _getMainActionButtonCell() {
            if (this.isPairedApp) {
                return null;
            }

            let centerContent = null,
                containerStyle = {
                    width: "100%",
                    height: 53
                },
                textStyle = { color: globalStyles.colors.text.primary };

            if (!this.isActiveMiniserver) {
                centerContent = {
                    comp: LxReactButton,
                    props: {
                        text: _('connect'),
                        onPress: () => {
                            if (this.fromTrustList) {
                                ActiveMSComponent.switchToTrustedMiniserver(this.miniserver);
                            } else {
                                if (this.isWebinterface) {
                                    location.reload();
                                    return;
                                }
                                NavigationComp.connectTo(this.miniserver);
                            }
                        },
                        containerStyle: containerStyle,
                        textStyle: textStyle
                    }
                };
            } else {
                centerContent = {
                    comp: LxReactButton,
                    props: {
                        buttonType: ButtonType.SECONDARY,
                        text: _('disconnect.connection'),
                        onPress: () => {
                            if (this.isWebinterface) {
                                location.reload();
                                return;
                            }
                            NavigationComp.disconnect();
                        },
                        containerStyle: { ...containerStyle, backgroundColor: globalStyles.colors.grey["300a36"]},
                        textStyle: textStyle
                    }
                };
            }
            return {
                content: {
                    mainCenterStyle: { flexDirection: "row", alignItems: "center" },
                    mainCenterContent: centerContent
                }
            }
        }

        _getConnectionStateCell() {
            var connectionIcon, connectionText;

            if (this.reachMode === ReachMode.LOCAL) {
                connectionText = _("miniserver.connected.internal");
            } else if (this.reachMode === ReachMode.REMOTE) {
                connectionText = _("miniserver.connected.external");
            } else {
                connectionText = _('miniserver.not-connected');
            }
            if (this.miniserver.tlsInfo && this.miniserver.tlsInfo[this.reachMode] === TlsState.PROVEN) {
                connectionIcon = {
                    comp: Icons.Lock,
                    props: {
                        style: {
                            fill: globalStyles.colors.green,
                            width: 16,
                            height: 16,
                            marginLeft: 2,
                            verticalMargin: "auto"
                        }
                    }
                };
            }

            return {
                content: {
                    title: _('conn'),
                    mainRightContent: [{
                            comp: LxReactText,
                            props: {
                                children: connectionText,
                                style: {
                                    color: globalStyles.colors.text.secondary,
                                    ...globalStyles.textStyles.body.default
                                }
                            }
                        },
                        connectionIcon
                    ]
                }
            }
        }

        _getUserCell() {
            if (this.isActiveMiniserver) {
                var userObj = ActiveMSComponent.getCurrentUser();
                return this._createInfoCell(_('user'), userObj.name);
            } else {
                return this._createInfoCell(_('user'), this.miniserver.activeUser);
            }
        }

        _getHostCell() {
            if (this.isActiveMiniserver && ActiveMSComponent.getCurrentUser().trustMember) {
                return this._createInfoCell(_("controls.intercom.about.audio.host"), "@" + ActiveMSComponent.getCurrentUser().trustMember);
            }
            return null;
        }

        _getFirmwareVersionCell() {
            if (this.isActiveMiniserver) {
                return this._createInfoCell(_('miniserver.firmware'), ActiveMSComponent.getConfigVersion());
            }
            return null;
        }

        _getMsLicensesCell() {
            if (this._isMiniserverGen2() && Feature.OPEN_SOURCE_LICENSES) {
                return {
                    content: {
                        title: _("app.open-source-licences"),
                        clickable: true,
                        disclosureIcon: true
                    },
                    action: this._showLicences.bind(this)
                };
            }
            return null;
        }


        _getRebootCell() {
            if (SandboxComponent.checkPermission(MsPermission.SYS_WS)) {
                return {
                    content: {
                        title: _("miniserver.trigger-reboot"),
                        titleColor: globalStyles.colors.brand
                    },
                    action: () => {
                        if (this.isActiveMiniserver) {
                            SandboxComponent.getPermission(MsPermission.SYS_WS).then(() => CommunicationComponent.send(Commands.REBOOT_MINISERVER));
                        } else {
                            console.error(AboutMiniserverScreen.name + "--> cannt restart inactive miniserver")
                        }
                    }
                };
            }
            return null;
        }

        _getDeleteMsCell() {
            return {
                content: {
                    title: _('miniserverlist.delete'),
                    titleColor: globalStyles.colors.red
                },
                action: this._handleDelete.bind(this)
            }
        }

        _getUnpairAppCell() {
            return {
                content: {
                    title: _("managed-tablet.unpair-tablet-long"),
                    titleColor: globalStyles.colors.red
                },
                action: () => {
                    PairedAppComponent.handleUnpairTapped(this.miniserver);
                }
            }
        }

        // Private methods

        _handleDelete() {
            var content = {
                title: _('miniserverlist.delete'),
                message: _('miniserverlist.delete.confirmation'),
                buttonOk: _('delete'),
                buttonCancel: true,
                icon: Icon.DELETE,
                color: globalStyles.colors.red
            };
            NavigationComp.showPopup(content).then(function () {

                PersistenceComponent.removeMiniserver(this.miniserver.serialNo);

                if (this.isActiveMiniserver) {
                    NavigationComp.disconnect();
                } else {
                    NavigationComp.showArchive();
                }

            }.bind(this), function () {
            });
        }

        _showLicences() {
            var miniserverIp = ActiveMSComponent.getActiveMiniserver().currConnectionUrl,
                url = miniserverIp + Commands.GET_LICENSES;
            NavigationComp.openWebsite(url);
        }

        _isMiniserverGen2() {
            var isGen2 = false;

            if (this.isActiveMiniserver) {
                switch (ActiveMSComponent.getMiniserverType()) {
                    case MiniserverType.MINISERVER_V2:
                    case MiniserverType.MINISERVER_GO_V2:
                    case MiniserverType.MINISERVER_COMPACT:
                        isGen2 = true;
                        break;

                    default:
                        isGen2 = false;
                        break;
                }
            }

            return isGen2;
        }

    };
});
