'use strict';

define(["HomeKitOnboardingScreen"], function (HomeKitOnboardingScreen) {
    class HomeKitSetupCodeScreen extends GUI.HomeKitOnboardingScreen {
        //region Static
        static getShownIds() {
            return [];
        }

        static shouldShow(id) {
            return true;
        } //endregion Static


        constructor(details) {
            super(details);
            this.pairingInfo = details.pairingInfo;
            this.bottomText = details.pairingInfo.setupCode;
            this.createHome = details.createHome;
            this.home = details.home;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.shareBtn.onButtonTapped = function () {
                    return this._shareButtonAction();
                }.bind(this);
            }.bind(this));
        }

        closeAction() {
            this.ViewController.dismiss();
        }

        continueBtnAction() {
            var callbackDefer = Q.defer(),
                waitingPromise;
            waitingPromise = ActiveMSComponent.pairMiniserverWithHomeKit(this.home, this.pairingInfo.accessoryName).then(function () {
                return this.ViewController.getHomeKitUsers();
            }.bind(this));
            this.ViewController.showState(ScreenState.WaitingScreen, null, {
                cancelButtonText: _("home-kit.loading.cancel"),
                message: _("home-kit.loading.message.connect", {
                    home: this.home
                }),
                callbackDefer: callbackDefer,
                waitingPromise: waitingPromise
            });
            callbackDefer.promise.then(function (users) {
                this.ViewController.showState(ScreenState.HomeKitUsers, null, {
                    users: users
                });
            }.bind(this), function (error) {
                if (JSON.stringify(error).indexOf("HMFErrorDomain : 12") >= 0) {
                    // 12 = objectNotAssociatedToAnyHome = setup-dialog cancelled.
                    console.warn(this.viewId, "continueBtnAction: pairMiniserverWithHomeKit cancelled! " + JSON.stringify(error)); // nothing to do.
                } else {
                    console.error(this.viewId, "continueBtnAction: pairMiniserverWithHomeKit failed = " + JSON.stringify(error));
                    this.ViewController.handleError(error, _("home-kit.error.pair-home"));
                }
            }.bind(this));
        }

        _shareButtonAction() {
            return ActiveMSComponent.getHomeKitPairingQRCode(this.pairingInfo.qrLocation).then(this._getBase64StringOfSvg).then(function (base64String) {
                var pdfBuffer = this._createSharePdf(base64String);

                exportBase64File("HomeKit Setup Code.pdf", arrayBufferToBase64String(pdfBuffer), "application/pdf", this.shareBtn.getElement()[0]);
            }.bind(this), function (error) {
                console.error(error);
            }.bind(this));
        }

        _createSharePdf(base64String) {
            var shareScreenInformation = this._getShareScreenInformation(),
                miniserverContent = shareScreenInformation.miniserverContent,
                userContent = shareScreenInformation.userContent,
                leftOffset = 48,
                leftOffsetBig = 192,
                defaultColor = '#000000',
                fontRegular = "Averta-Regular",
                fontBold = "Averta-Bold",
                linkWidth = 0,
                underlineHeight = 2,
                subString,
                maxMiniserverTextLength = 40;

            var sharePdf = new jsPDF('p', 'pt', 'a4');
            sharePdf.addFileToVFS(); // Config

            sharePdf.setFont(fontRegular);
            sharePdf.setFontSize(9); // Header Section

            sharePdf.text(_("home-kit.setup-code.pdf.header") + SEPARATOR_SYMBOL + userContent.user.message, leftOffset, 43);
            sharePdf.addImage(LOXONE_SLOGAN_NEW_JPEG_DATA_URI, 'JPEG', 466, 40, 80, 18); // Miniserver Name

            sharePdf.setFontSize(21);
            sharePdf.setFont(fontBold);

            if (miniserverContent.msname.message.length >= maxMiniserverTextLength) {
                // 40 max length of the text
                subString = miniserverContent.msname.message.substr(0, maxMiniserverTextLength);
                miniserverContent.msname.message = subString += '...';
            }

            sharePdf.text(miniserverContent.msname.message, leftOffset, 80); // Project Location

            sharePdf.setFontSize(9);
            sharePdf.setFont(fontRegular);
            sharePdf.text(shareScreenInformation.location, leftOffset, 100); // First Header

            sharePdf.setFontSize(14);
            sharePdf.setFont(fontBold);
            sharePdf.text(_('menu.header.miniserver'), leftOffset, 153);
            sharePdf.setFontSize(11);
            sharePdf.setFont(fontRegular); // First Table (Miniserver Information)

            Object.values(miniserverContent).forEach(function (entry, idx) {
                var yOffset = 183 + 22 * idx;
                sharePdf.text(entry.title, leftOffset, yOffset);

                if (entry.href) {
                    sharePdf.setDrawColor(Color.RED);
                    sharePdf.setTextColor(Color.RED);
                    linkWidth = sharePdf.textWithLink(entry.message, leftOffsetBig, yOffset, {
                        url: entry.href
                    });
                    sharePdf.line(leftOffsetBig, yOffset + underlineHeight, leftOffsetBig + linkWidth, yOffset + underlineHeight);
                    sharePdf.setDrawColor(defaultColor);
                    sharePdf.setTextColor(defaultColor);
                } else {
                    sharePdf.text(entry.message, leftOffsetBig, yOffset);
                }
            }.bind(this)); // Second Header

            sharePdf.setFontSize(14);
            sharePdf.setFont(fontBold);
            sharePdf.text(_('home-kit.menu'), leftOffset, 295);
            sharePdf.setFont(fontRegular);
            sharePdf.setFontSize(11);
            var yOffset = 325;
            sharePdf.text(_("home-kit.setup-code.pdf.setup-code"), leftOffset, yOffset);
            sharePdf.text(this.pairingInfo.setupCode, leftOffsetBig, yOffset); // Qr Code Section

            sharePdf.setFontSize(9);
            sharePdf.setTextColor(Color.ANTHRACITE);
            sharePdf.text(_("user.shared-data.scan-qr-code"), 416, 437);
            sharePdf.setTextColor(defaultColor); // HomeKit QR Code Square

            sharePdf.setDrawColor(Color.ANTHRACITE);
            sharePdf.rect(leftOffset, 461, 240, 222);
            sharePdf.setFontSize(14);
            sharePdf.setFont(fontBold);
            sharePdf.text(_("home-kit.setup-code.title"), leftOffset + 24, 639, {
                maxWidth: 212
            });
            sharePdf.setFont(fontRegular);
            sharePdf.setFontSize(11);
            sharePdf.setTextColor(Color.RED);
            sharePdf.setDrawColor(Color.RED);
            sharePdf.setTextColor(defaultColor);
            sharePdf.setDrawColor(defaultColor);
            sharePdf.addImage(base64String, 'JPEG', leftOffset + 12, 473, 140, 140);
            sharePdf.setDrawColor(defaultColor);
            sharePdf.text(_('user.shared-data.download-app'), leftOffset, 699);
            sharePdf.setTextColor(Color.RED);
            sharePdf.setDrawColor(Color.RED);
            linkWidth = sharePdf.textWithLink('www.loxone.com/app', leftOffset, 717, {
                url: 'http://www.loxone.com/app'
            });
            sharePdf.line(leftOffset, 717 + underlineHeight, leftOffset + linkWidth, 717 + underlineHeight);
            sharePdf.setTextColor(defaultColor);
            sharePdf.setDrawColor(defaultColor);
            return sharePdf.output('arraybuffer');
        }

        _getBase64StringOfSvg(svg) {
            var defer = Q.defer(),
                canvas = document.createElement("canvas"),
                ctx;
            canvas.height = 1024;
            canvas.width = 1024;
            ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            var img = document.createElement("img");
            img.setAttribute("src", "data:image/svg+xml;base64," + btoa(svg));

            img.onload = function () {
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                console.log(canvas.toDataURL("image/jpeg"));
                defer.resolve(canvas.toDataURL("image/jpeg"));
            };

            return defer.promise;
        }

        _getShareScreenInformation() {
            this.canvas = document.createElement('canvas');
            this.canvas.width = this.qrCodeSize;
            this.canvas.height = this.qrCodeSize;
            var miniserverContent = {},
                userContent = {},
                currentMiniserver = cloneObjectDeep(ActiveMSComponent.getActiveMiniserver()),
                objectForUrlStart = {};
            miniserverContent.msname = {
                title: _('menu.header.miniserver'),
                message: currentMiniserver.msName
            };
            miniserverContent.serialNumber = {
                title: _('miniserver.serial-number'),
                message: currentMiniserver.serialNo
            };
            objectForUrlStart.host = currentMiniserver.localUrl;
            objectForUrlStart.usr = ActiveMSComponent.getCurrentUser().name;
            miniserverContent.internalIp = {
                title: _('miniserver.url.local'),
                href: CommunicationComponent.getRequestProtocol() + currentMiniserver.localUrl,
                // The href must be the plain, unmodified link without any lineBreakPossibilities added in the next line
                message: currentMiniserver.localUrl,
                url: UrlHelper.createURLStart(objectForUrlStart)
            };

            if (currentMiniserver.remoteUrl) {
                objectForUrlStart.host = currentMiniserver.remoteUrl;
                miniserverContent.externalIp = {
                    title: _('miniserver.url.remote'),
                    href: CommunicationComponent.getRequestProtocol() + currentMiniserver.remoteUrl,
                    // The href must be the plain, unmodified link without any lineBreakPossibilities added in the next line
                    message: currentMiniserver.remoteUrl,
                    url: UrlHelper.createURLStart(objectForUrlStart)
                };
            }

            delete currentMiniserver.host;
            userContent.user = {
                title: _('media.service.add.input.username'),
                message: ActiveMSComponent.getCurrentUser().name
            };
            return {
                location: currentMiniserver.location,
                miniserverContent: miniserverContent,
                userContent: userContent
            };
        }

    }

    GUI.HomeKitSetupCodeScreen = HomeKitSetupCodeScreen;
});
