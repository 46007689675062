'use strict';

window.RippleContainer = {
    get Mixin() {
        return {
            /**
             * The ripple effect can be enabled or disabled.
             * @param enabled
             */
            setRippleEnabled: function setRippleEnabled(enabled) {
                this.rippleEnabled = enabled;
                this.element.toggleClass("lx-ripple-container--disabled", !enabled);
            },
            setDarkTheme: function setDarkTheme(active) {
                this.element.toggleClass("ripple-ink--b", active !== false);
            },

            /**
             * Starts a ripple based on a hammer.js event. Does not support multitouch!
             * @param e the hammer js event that triggers this ripple
             * @param customElement starts the ripple on a specific element, uses this.element if not set
             * @param preventFading
             */
            startHammerRipple: function startHammerRipple(e, customElement, preventFading) {
                if (!e) {
                    throw "Hammer event argument not provided!";
                }

                var pos = getEventPosition(e);
                this.startRipple(pos.x, pos.y, customElement, preventFading);
            },
            startHammerRippleHold: function startHammerRippleHold(e, customElement) {
                if (!e) {
                    throw "Hammer event argument not provided!";
                }

                var pos = getEventPosition(e);
                this.startRipple(pos.x, pos.y, customElement, true, true);
            },

            /**
             * Starts a ripple on this.element based on a x and y coordinate.
             * @param eX the x position of the click/touch that triggers this ripple
             * @param eY the y position of the click/touch that triggers this ripple
             * @param customElement starts the ripple on a specific element, uses this.element if not set
             * @param preventFading
             * @param holdRipple
             */
            startRipple: function startRipple(eX, eY, customElement, preventFading, holdRipple) {
                if (!this.rippleEnabled) {
                    return;
                }

                if (!eX || !eY) {
                    throw "Touch position not provided!";
                }

                Debug.GUI.Ripple && console.log(this.name, "startRipple");
                this._parent = customElement || $(this.element);
                var d, x, y;

                this._parent.css("overflow", "hidden");

                this._parent.addClass("ripple-ink");

                this._parent.removeClass("ripple-ink--hold");

                this._parent.toggleClass("ripple-ink-fade", !preventFading); // determine size of .ink
                // use parent's width or height whichever is larger for the diameter
                // to make a circle which can cover the entire element.


                d = Math.max(this._parent.outerWidth(), this._parent.outerHeight());
                x = eX - this._parent.offset().left - d / 2;
                y = eY - this._parent.offset().top - d / 2; // remove old styles

                this._style && this._style.remove();
                this._style = $('<style />');

                this._style.html('.ripple-ink:before { ' + '   top: ' + y + 'px; ' + '   left: ' + x + 'px; ' + '   height: ' + d + 'px; ' + '   width: ' + d + 'px; ' + '}');

                $('head').append(this._style); // iOS 13.4 has issues with the AnimationEnd events that's why we removed the eventlisteners
                // and are using the timeout to ensure the endFunction is called

                /*PrefixedEvent(this._parent[0], 'AnimationEnd', function() {
                    if (holdRipple) {
                        this._parent.addClass("ripple-ink--hold");
                    } else {
                        this.stopRipple(true); // important --> otherwise e.g. the selection of a cell won't be shown.
                    }
                }.bind(this));*/

                this._parent.addClass("ripple-ink--animate");

                if (this.__rippleTimeout) {
                    clearTimeout(this.__rippleTimeout);
                    this.__rippleTimeout = null;
                }

                this.__rippleTimeout = setTimeout(function () {
                    if (holdRipple) {
                        this._parent.addClass("ripple-ink--hold");
                    } else {
                        this.stopRipple(true); // important --> otherwise e.g. the selection of a cell won't be shown.
                    }
                }.bind(this), 450); // Why 450, as defined in SCSS
            },

            /**
             * Stops the ripple event. used e.g. in viewDidAppear when navigating back from a child view, the animate
             * class needs to be removed, otherwise the ripple might still be active.
             * @param [internal]    if true, the onRippleEnd is to be called, but the stopped attribute mustn't be set.
             */
            stopRipple: function stopRipple(internal) {
                if (!this.rippleEnabled) {
                    return;
                }

                if (this._style) {
                    this._parent.removeClass("ripple-ink ripple-ink--animate ripple-ink-fade ripple-ink--hold");

                    this._style.remove();

                    delete this._style;
                    typeof this.onRippleEnd === "function" && this.onRippleEnd(!internal);
                }
            }
        };
    }

};
