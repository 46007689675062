'use strict';

window.GUI = function (GUI) {
    class FavoritesScreen extends GUI.Screen {
        //region Static
        static Template = function () {
            var getFavoritesTemplate = function getFavoritesTemplate() {
                return $('<div class="favorites-screen"></div>');
            };

            var getScrollContainer = function getScrollContainer() {
                return $('<div class="favorites-screen__scroll-container"></div>');
            };

            var getControlsPlaceholder = function getControlsPlaceholder() {
                return $('<div class="scroll-container__controls-placeholder"></div>');
            };

            var getNoFavoritesTemplate = function getNoFavoritesTemplate() {
                return $('<div class="favorites-screen__no-favorites">' + '<div class="no-favorites__icon-placeholder">' + ImageBox.getResourceImageWithClasses(Icon.STAR, "icon-placeholder__icon") + '</div>' + '<div class="no-favorites__label-placeholder">' + '<div class="label-placeholder__title">' + _("favorites.no-favorites.title") + '</div>' + '<div class="label-placeholder__subtitle"></div>' + '</div>' + '</div>');
            };

            return {
                getFavoritesTemplate: getFavoritesTemplate,
                getScrollContainer: getScrollContainer,
                getNoFavoritesTemplate: getNoFavoritesTemplate,
                getControlsPlaceholder: getControlsPlaceholder
            };
        }(); //endregion Static

        constructor() {
            super(FavoritesScreen.Template.getFavoritesTemplate());
            this.elements = {};
            this.rooms = {};
            this.categories = {};
            this.controls = {}; // stay registered throughout the lifecycle.

            this._updateFavoritesFlag = true; // set initially to ensure that the data is updated on the first appearance.

            this._favsChangedReg = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.FavoritesChanged, this.handleFavoritesChanged.bind(this));
            this._structureChangesReg = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.StructureChanged, this.handleFavoritesChanged.bind(this));
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments); // TitleBar titleBar

            this.titleBar = this._initTitleBar();
            this.appendSubview(this.titleBar);
            this.elements.scrollContainer = FavoritesScreen.Template.getScrollContainer();

            this._setUpGroupList(); // always needed if favorites are available (at least for the function header, if no rooms or categories are given)


            this.elements.controlsContainer = FavoritesScreen.Template.getControlsPlaceholder();
            this.elements.scrollContainer.append(this.elements.controlsContainer);

            this._setUpControlsList();

            this.element.append(this.elements.scrollContainer);
            this.elements.noFavsPlaceholder = FavoritesScreen.Template.getNoFavoritesTemplate();
            this.elements.noFavsSubtitle = this.elements.noFavsPlaceholder.find('.label-placeholder__subtitle');
            this.element.append(this.elements.noFavsPlaceholder);
            this.element.toggleClass('favorites-screen--scrollable', !HD_APP || !MergeHdAndFavs);
        }

        viewWillAppear() {
            // if the data has changed while this view wasn't visible, update it now!
            // needs to be called before viewWillAppear, because otherwise the view-lifecycle check fails & writes log errors.
            if (this._updateFavoritesFlag) {
                this.updateFavorites();
            }

            super.viewWillAppear(...arguments); // set scrollPosition

            this.elements.scrollContainer && this.elements.scrollContainer.scrollTop(this.lastScrollPosition);
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);

            this.titleBar.onRightButtonTapped = function () {
                NavigationComp.showSearchScreen();
            };
        }

        viewWillDisappear() {
            super.viewWillDisappear(); // save scrollPosition

            if (this.elements.scrollContainer) {
                this.lastScrollPosition = this.elements.scrollContainer.scrollTop();
            }
        }

        viewDidDisappear() {
            this.titleBar.onRightButtonTapped = null;
            super.viewDidDisappear(...arguments);
        }

        destroy() {
            this.titleBar = null;
            this.elements = null;
            this.lastScrollPosition = null;
            this.controls = null;
            this.rooms = null;
            this.categories = null;
            NavigationComp.removeFromUIEvent(this._favsChangedReg);
            NavigationComp.removeFromUIEvent(this._structureChangesReg);
            super.destroy();
        }

        isReusable() {
            return true;
        }

        getURL() {
            return "Favorites";
        }

        destroyOnBackNavigation() {
            return false;
        }

        /**
         * responds to any event that indicates that the data has changed and therefore this view needs to be updated.
         * As these events might arrive while the view isn't visible, a flag will be set to ensure only one UI update
         * is processed even tough several changes where made while the view wasn't visible.
         */
        handleFavoritesChanged() {
            if (this.isVisible()) {
                this.updateFavorites();
            } else {
                this._updateFavoritesFlag = true;
            }
        }

        /**
         * This method will fetch the most recent data, update all views and reset the udpateFavorites flag.
         * @private
         */
        updateFavorites() {
            // fetch updated data
            this.rooms = ActiveMSComponent.getStructureManager().getFavoriteGroupsByGroupType(GroupTypes.ROOM);
            this.categories = ActiveMSComponent.getStructureManager().getFavoriteGroupsByGroupType(GroupTypes.CATEGORY);
            this.controls = ActiveMSComponent.getStructureManager().getFavoriteControls(0); // reload table views

            this.groupsView.reload(); // HD uses this as baseClass and might have a left- & rightControlList instad of one controlList

            this.controlList && this.controlList.reload();
            var hasFavs = !!this.controls.length || this.rooms.length > 0 || this.categories.length > 0;

            if (hasFavs) {
                this.elements.noFavsPlaceholder.hide();
                this.elements.scrollContainer.show();
            } else {
                this.elements.scrollContainer.hide();
                this.elements.noFavsPlaceholder.show();

                if (PersistenceComponent.getMiniserverSettings().manualFavorites.activated) {
                    this.elements.noFavsSubtitle.text(_("favorites.no-favorites.manual-message"));
                } else {
                    this.elements.noFavsSubtitle.text(_("favorites.no-favorites.message"));
                }
            } // reset the updateFavorites flag


            this._updateFavoritesFlag = false;
        }

        // Private methods
        _initTitleBar() {
            var titleBarConfig = {
                leftSide: TitleBarCfg.TEXT,
                rightSide: TitleBarCfg.Button.SEARCH
            };
            var titleBar = new GUI.LxTitleBar(titleBarConfig);
            titleBar.setTitleBarSideTexts(_("favorites.title"));
            return titleBar;
        }

        _setUpGroupList() {
            this.groupsView = new GUI.CollectionViewV2(this, this);
            this.groupsView.getElement().addClass("favorites-screen__group-collection-view");
            this.appendSubview(this.groupsView, this.elements.scrollContainer);
        }

        _setUpControlsList() {
            this.controlList = new GUI.ControlListTableView(this, this);
            this.controlList.getElement().addClass('control-list--favorites');
            this.appendSubview(this.controlList, this.elements.controlsContainer);
        }

        // groupsViewDataSource methods
        numberOfSections(tableView) {
            if (tableView === this.groupsView) {
                this.roomSection = -1;
                this.categorySection = -1;
                var sections = 0;

                if (this.rooms.length) {
                    this.roomSection = sections;
                    sections++;
                }

                if (this.categories.length) {
                    this.categorySection = sections;
                    sections++;
                }

                if (this.controls.length) {
                    sections++; // section for the "functions" header (the header isn't from ControlList!)
                }

                return sections;
            } else if (tableView === this.controlList) {
                return 1;
            }
        }

        titleForHeaderInSection(section) {
            var customTitles = ActiveMSComponent.getStructureManager().getCustomGroupTitles();

            if (section === this.roomSection) {
                return customTitles[GroupTypes.ROOM];
            } else if (section === this.categorySection) {
                return customTitles[GroupTypes.CATEGORY];
            } else {
                return _("favorites.title");
            }
        }

        numberOfRowsInSection(section, tableView) {
            if (tableView === this.groupsView) {
                var nrOfRows = 0;

                if (section === this.roomSection) {
                    nrOfRows = this.rooms.length;
                } else if (section === this.categorySection) {
                    nrOfRows = this.categories.length;
                }

                return nrOfRows;
            } else if (tableView === this.controlList) {
                return this.controls.length;
            }
        }

        cellTypeForCellAtIndex() {
            return GUI.TableViewV2.CellType.Special.COLLECTION;
        }

        controlForCellAtIndex(section, row) {
            return this.controls[row];
        }

        contentForCell(cell, section, row) {
            var group;

            if (section === this.roomSection) {
                group = this.rooms[row];
            } else if (section === this.categorySection) {
                group = this.categories[row];
            }

            if (group) {
                return {
                    title: group.name,
                    iconSrc: group.image,
                    iconColor: group.color,
                    msIcon: true
                };
            }
        }

        // groupsViewDelegate methods
        didSelectHeader(section, tableView) {
            if (tableView === this.groupsView) {
                var groupType;

                if (section === this.roomSection) {
                    groupType = GroupTypes.ROOM;
                } else if (section === this.categorySection) {
                    groupType = GroupTypes.CATEGORY;
                }

                if (groupType) {
                    NavigationComp.showState(ScreenState.GroupSelector, {
                        groupType: groupType
                    });
                }
            }
        }

        didSelectCell(cell, section, row, tableView) {
            if (tableView === this.groupsView) {
                var group;

                if (section === this.roomSection) {
                    group = this.rooms[row];
                } else if (section === this.categorySection) {
                    group = this.categories[row];
                }

                if (group) {
                    NavigationComp.showGroupContent(group.groupType, group.uuid);
                }
            }
        }

    }

    GUI.FavoritesScreen = FavoritesScreen;
    return GUI;
}(window.GUI || {});
