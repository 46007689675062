'use strict';

define(['AudioZoneV2ControlEnums'], function (AudioZoneV2ControlEnums) {
    class FavoriteEdit extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            applyMixins(this, MediaStateHandlerV2.Mixin);
            this.details = details;
            this.control = details.control;
            this.zoneFavorites = [];
            this.favSectionIdx = 1;
        }

        getTableView() {
            this.tableViewDelegate.didRequestRemovingCell = this.didRequestRemovingCell.bind(this);
            return new GUI.EditableTableView(this.tableViewDataSource, this.tableViewDelegate);
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                this._registerForMediaServerEvents(this.EVENT_TYPES.ZONE_FAVORITES, this.EVENT_TYPES.FAVORITES);
            }.bind(this));
        }

        destroy() {
            this._sendFavoriteChanges();

            this._unregisterForMediaServerEvents(this.EVENT_TYPES.ZONE_FAVORITES, this.EVENT_TYPES.FAVORITES);

            super.destroy();
        }

        titleBarText() {
            return this.details.title || _("edit");
        }

        receivedZoneFavoritesAllDataEvent(res) {
            this.zoneFavorites = Array.apply(Array, Array(MusicServerEnum.MAX_ZONE_FAVS)).map(function () {
                return null;
            }); // Create an empty array with 8 entries

            res.items.forEach(function (zoneFav) {
                this.zoneFavorites[zoneFav.slot - 1] = zoneFav;
            }.bind(this));
            this._ogZoneFavorites = cloneObject(this.zoneFavorites);
            this.reloadRowsSelective(this.favSectionIdx, 0, MusicServerEnum.MAX_ZONE_FAVS);
        }

        setTableContent() {
            this.tableContent = [{
                footerTitle: _("media.zone.fav.desc")
            }, this._getZoneFavSection()];
            this.tableContent.pushObject(this._getMigrateSection());
            return super.setTableContent(...arguments);
        }

        reloadTable() {
            return super.reloadTable(...arguments).then(function () {
                return this._setEditingModeForSections();
            }.bind(this));
        }

        reloadRowsSelective(section, startIdx, nCells) {
            this.setTableContent();
            return super.reloadRowsSelective(...arguments).then(function () {
                return this._setEditingModeForSections();
            }.bind(this));
        }

        _setEditingModeForSections() {
            return Q.all([this.tableView.setEditingModeForSection(this.favSectionIdx, true, true, false)]);
        }

        _getZoneFavSection() {
            return {
                rows: this.zoneFavorites.map(Controls.AudioZoneV2Control.MediaBrowserV2Favorites.getZoneFavoriteEditCellForFavAtIdx),
                didMoveCell: function didMoveCell(section, row, tableView, cell, oldIdx, newIdx) {
                    this.zoneFavorites.move(oldIdx, newIdx);
                }.bind(this)
            };
        }

        _getMigrateSection() {
            if (this.control.audioserverComp.Feature.COPY_ZONE_FAVS) {
                return {
                    rows: [{
                        content: {
                            title: _("media.zone.fav.get-from"),
                            titleColor: window.Styles.colors.green,
                            clickable: true
                        },
                        action: function () {
                            return this.control.audioserverComp.copyRoomFavsFrom(this.control, this.ViewController);
                        }.bind(this)
                    }, {
                        content: {
                            title: _("media.zone.fav.send-to", {
                                zoneName: this.control.name
                            }),
                            titleColor: window.Styles.colors.green,
                            clickable: true
                        },
                        action: function () {
                            return this.control.audioserverComp.copyRoomFavsTo(this.control, this.ViewController);
                        }.bind(this)
                    }]
                };
            } else {
                return null;
            }
        }

        didRequestRemovingCell(section, row, tableView, cell) {
            if (section === 1) {
                var zoneFav = this.zoneFavorites[row];

                if (zoneFav) {
                    this.zoneFavorites[row] = null;
                    this.reloadRowsSelective(section, row, 1);
                } else {
                    this.control.audioserverComp.selectNewZoneFavorite(this.details, this.ViewController.ViewController);
                }
            }

            return false;
        }

        _getFirstPossibleZoneFavPos() {
            return this.zoneFavorites.indexOf(null) + 1;
        }

        _sendFavoriteChanges() {
            if (JSON.stringify(this.zoneFavorites) !== JSON.stringify(this._ogZoneFavorites)) {
                var prms = this.zoneFavorites.map(function (fav, idx) {
                    var ogFav = this._ogZoneFavorites[idx],
                        cmd;

                    if (JSON.stringify(fav) !== JSON.stringify(ogFav)) {
                        if (fav) {
                            cmd = this._createSaveCommand(fav, idx + 1, fav.name);

                            if (cmd !== null) {
                                return this.control.audioserverComp.sendMediaServerCommand(cmd);
                            }
                        } else if (ogFav) {
                            return this.control.audioserverComp.deleteZoneFavorite(ogFav, idx, this.details, this.ViewController.ViewController);
                        }
                    }

                    return Q(true);
                }.bind(this));
                return NavigationComp.showWaitingFor(Q.all(prms));
            } else {
                return Q(true);
            }
        }

        _createSaveCommand(item, slot, name) {
            return this.control.audioserverComp.getZoneFavoriteSaveCommand(this.control.details.playerid, item, name, item.contentType, this.control);
        }

    }

    Controls.AudioZoneV2Control.FavoriteEdit = FavoriteEdit;
    return Controls.AudioZoneV2Control.FavoriteEdit;
});
