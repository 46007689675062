'use strict';

/**
 * Screen (transparent Overlay) with an image, title, message and a button to give the user a short introduction to something
 *
 * usage:
 * provide details:
 * iconSrc
 * title
 * message
 * btnTitle (default 'Let's go!')
 * onFinishFn (called when button tapped or screen closed)
 */

class QuickGuideScreen extends GUI.Screen {
    //region Static
    static Template = function () {
        var getTemplate = function getTemplate(iconSrc, title, message, btnTitle) {
            return $('<div class="quick-guide-screen">' + '   <div class="quick-guide-screen__flex-top" />' + ImageBox.getResourceImageWithClasses(iconSrc, "quick-guide-screen__icon") + '   <div class="quick-guide-screen__title">' + title + '</div>' + '   <div class="quick-guide-screen__message">' + message + '</div>' + '   <div class="quick-guide-screen__btn">' + btnTitle.toUpperCase() + '</div>' + '   <div class="quick-guide-screen__flex-bottom" />' + '</div>');
        };

        return {
            getTemplate: getTemplate
        };
    }(); //endregion Static

    constructor(details) {
        if (typeof details.onFinishFn !== "function") {
            throw "QuickGuideScreen needs details with 'onFinishFn'";
        }

        super(QuickGuideScreen.Template.getTemplate(details.iconSrc, details.title, details.message, details.btnTitle || _('luring.yes.letsgo')));
        this.onFinishFn = details.onFinishFn;
    }

    viewDidLoad() {
        return Q(super.viewDidLoad(...arguments)).then(() => {
            this.elements = {
                onFinishBtn: this.element.find(".quick-guide-screen__btn")
            };
        });
    }

    viewWillAppear() {
        return Q(super.viewWillAppear(...arguments)).then(() => {
            this.elements.onFinishBtn.on("click", this._boundCloseAction);
        });
    }

    viewDidDisappear() {
        this.elements.onFinishBtn.off("click", this._boundCloseAction);
        return super.viewDidDisappear();
    }

    getAnimation() {
        return AnimationType.MODAL;
    }

    getURL() {
        return "QuickGuide";
    }

    titleBarText() {
        return NBR_SPACE;
    }

    titleBarConfig() {
        return {
            leftSide: TitleBarCfg.Button.CLOSE
        }
    }

    closeAction() {
        this.ViewController.navigateBack();
        this.onFinishFn();
    }

}

GUI.QuickGuideScreen = QuickGuideScreen;
