'use strict';

define([], function () {
    return {
        IRCMode: {
            FULL_AUTO: 0,
            FULL_AUTO_HEATING: 1,
            FULL_AUTO_COOLING: 2,
            AUTO_HEATING: 3,
            AUTO_COOLING: 4,
            MANUAL_HEATING: 5,
            MANUAL_COOLING: 6
        },
        IRCTempMode: {
            COMFORT_HEATING: 1,
            COMFORT_COOLING: 2,
            ECONOMY: 0,
            PARTY: 6,
            INCREASED_HEAT: 5,
            EMPTY_HOUSE: 3,
            HEAT_PROTECTION: 4,
            MANUAL: 7
        },
        UserInfo: {
            HEATING_PHASE: 'heating-phase',
            COOLING_PHASE: 'cooling-phase',
            BIG_DIFF_ACTUAL_TARGET: 'big-difference',
            NO_PERIOD_ACTIVE: 'no-period',
            OPEN_WINDOW: 'window',
            ACTIVE_TIMER: 'timer',
            COMFORT_TIMER: 'comfort-timer',
            // only with feature IRC_MANUAL_MODE_STATE
            ECONOMY_TIMER: 'economy-timer',
            // only with feature IRC_MANUAL_MODE_STATE
            HAS_PRESENCE: 'presence',
            NOTHING: 0
        },
        ManualMode: {
            OFF: 0,
            COMFORT: 1,
            ECONOMY: 2,
            TIMER: 3,
            MOVEMENT: 4
        },
        PreparationPhase: {
            NOT_PREPARING: 0,
            HEATING_UP: 'prepare-heating',
            COOLING_DOWN: 'prepare-cooling'
        },
        ServiceMode: {
            OFF: 0,
            HEATING_OFF_COOLING_OFF: 1,
            HEATING_ON_COOLING_OFF: 2,
            HEATING_OFF_COOLING_ON: 3,
            HEATING_ON_COOLING_ON: 4
        },
        VisuType: {
            HEATING_AND_COOLING: 0,
            COOLING: 1,
            HEATING: 2
        },
        ScreenState: {
            TIMER: 'IRoomControlContentTimer',
            TEMPERATURE_SELECTION: 'IRoomControlContentTemperatureSelection',
            SETTINGS_LIST: 'IRoomControlContentSettingsList',
            CONTROLLER_MODE_LIST: 'IRoomControlContentControllerMode',
            PERIOD_VIEW: 'IRoomControlContentPeriod',
            TEMPERATURE_LIST: 'IRoomControlContentTemperatureList',
            MAN_TEMP_SCREEN: 'IRCManualTempSliderScreen',
            CALENDAR: 'IRCDaytimerControlContentCalendar'
        }
    };
});
