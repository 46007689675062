import {createStackNavigator, TransitionPresets} from "@react-navigation/stack";
import {
    LxReactScreenAdapter,
    TopNavigator,
    navigatorConfig
} from "LxComponents";
import {useNavigation} from "@react-navigation/native";

const screenOptions = (...params) => {
    return {
        ...TopNavigator.forDefault(),
        ...TransitionPresets.ModalFadeTransition,
        ...navigatorConfig({...params, isAmbientMode: true})
    }
}

function AmbientSearchStackNavigator(props) {
    const AmbientSearchStack = createStackNavigator();
    const navigation = useNavigation();


    return <AmbientSearchStack.Navigator
                initialRouteName={ScreenState.SearchScreen}
                screenOptions={
                    (params) => {
                        return {
                            ...screenOptions(params, true),
                            headerShown: false,
                            isAmbientMode: true,
                            inAmbientTabNav: true
                        }
                    }}>
            <AmbientSearchStack.Screen name={ScreenState.SearchScreen}
                                       initialParams={{navigation: navigation}}
                                       component={LxReactScreenAdapter} />
            <AmbientSearchStack.Screen name={ScreenState.SearchResultScreen} component={LxReactScreenAdapter} />
        </AmbientSearchStack.Navigator>
}

export default AmbientSearchStackNavigator;
