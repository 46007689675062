'use strict';

class NotificationHistoryScreen extends GUI.Screen {
    //region Static
    static Template = {
        getTemplate: function () {
            return $('<div class="notification-history-screen" />');
        },
        getMarkAllAsRead: function () {
            return $('<div class="notification-history-screen__mark-all-as-read">' + '   ' + _('notifications.mark-all-as-read') + '</div> ');
        }
    }; //endregion Static

    constructor() {
        super(NotificationHistoryScreen.Template.getTemplate());
        var currentMS = ActiveMSComponent.getActiveMiniserver();
        this._currentMAC = currentMS && currentMS.serialNo;
    }

    viewDidLoad() {
        return super.viewDidLoad(...arguments).then(function () {
            this.tableView = new GUI.TableViewV2(this, this);
            this.tableView.getElement().addClass("notification-history-screen__table");
            this.tableView.getElement().addClass("table-view-style-color-b");
            this.appendSubview(this.tableView);
            this.markAllAsReadBtn = new GUI.LxRippleButton(NotificationHistoryScreen.Template.getMarkAllAsRead());
            this.appendSubview(this.markAllAsReadBtn);
            this.markAllAsReadBtn.on(GUI.LxRippleButton.CLICK_EVENT, this._handleMarkAllAsRead.bind(this));
        }.bind(this));
    }

    viewWillAppear() {
        return super.viewWillAppear(...arguments).then(function () {
            if (!this._notificationListener) {
                this._notificationListener = this._updateNotifications.bind(this);
                var notifications = SandboxComponent.registerNotificationHandler(this._notificationListener);

                this._updateNotifications(notifications);
            }
        }.bind(this));
    }

    viewWillDisappear() {
        this._timeUpdater && this._timeUpdater(); // cancel prev. timers

        this._timeUpdater = null;
        SandboxComponent.unregisterNotificationHandler(this._notificationListener);
        delete this._notificationListener;
        return super.viewWillDisappear(...arguments);
    }

    titleBarText() {
        return _('notifications');
    }

    titleBarConfig() {
        return {
            style: 'transparent'
        };
    }

    getAnimation() {
        return AnimationType.MODAL;
    }

    getURL() {
        return "Notifications";
    }

    // TableViewDataSource Methods
    numberOfSections(tableView) {
        return 1;
    }

    numberOfRowsInSection(section, tableView) {
        return this._history.length;
    }

    cellTypeForCellAtIndex(section, row, tableView) {
        return GUI.TableViewV2.CellType.NOTIFICATION;
    }

    contentForCell(cell, section, row, tableView) {
        var pn = this._history[row],
            isCurrentMiniserver = this._currentMAC === pn.mac;
        var title = pn.title,
            message = pn.message;

        if (!isCurrentMiniserver && pn.miniserver) {
            if (title.length > 0 && message.length > 0) {
                message = title + SEPARATOR_SYMBOL + message;
            } else if (title.length > 0) {
                message = title;
            } // else message = message


            title = pn.miniserver;
        }

        return {
            uid: pn.uid,
            iconSrc: pn.iconSrc,
            iconColor: pn.iconColor,
            time: pn.time,
            title: title,
            message: message,
            clickable: this._isNotificationClickable(pn)
        };
    }

    // TableViewDelegate Methods
    didSelectCell(cell, section, row, tableView) {
        var pn = this._history[row];

        if (pn.level === PushNotificationLevel.SYSTEM_ERROR) {
            var link = extractLinkFromString(pn.message);

            if (link) {
                NavigationComp.openWebsite(link);
                return;
            }
        }

        switch (pn.type) {
            case PushNotificationType.NORMAL:
                // handle all other type 10 notifications
                if (pn.mac && pn.uuid) {
                    if (this._currentMAC === pn.mac) {
                        this.ViewController.navigateBack();
                        NavigationComp.navigateToControl(pn.uuid);
                    } else {
                        UrlHelper.apply(UrlHelper.createURLStart({
                            mac: pn.mac
                        }, {
                            control: pn.uuid
                        }));
                    }
                }

                break;

            case PushNotificationType.MS_DEVICE_MANAGER:
                NavigationComp.showBatteryMonitor(pn.original.data.uuids, pn.mac);
                break;

            default:
                break;
        }

        SandboxComponent.markNotificationAsRead(pn.uid, false);
    }

    markNotificationAsRead(cell, section, row, tableView, uid) {
        this._history.splice(row, 1);

        this.tableView.removeRow(section, row, true).done(function () {
            SandboxComponent.markNotificationAsRead(uid, false); // remove after the row has been fully removed, otherwise there will be a reload during removal of cell!
        });
    }

    // Private methods
    _startTimeUpdate() {
        // update every whole day the time (today, yesterday)
        this._timeUpdater && this._timeUpdater(); // cancel prev. timers

        this._timeUpdater = scheduleImpuls("day", function () {
            this.tableView.reload();
        }.bind(this));
    }

    _updateNotifications(notifications) {
        this._history = cloneObjectDeep(notifications);
        var styleResolvePromises = [];
        var isCurrentMiniserver, control;

        this._history.forEach(function (pn) {
            isCurrentMiniserver = this._currentMAC === pn.mac;
            control = isCurrentMiniserver ? ActiveMSComponent.getControlByUUID(pn.uuid) : null;
            styleResolvePromises.push(SandboxComponent.determineNotificationStyle(control, pn.level, pn.mac, pn.uuid).then(function (iconInfo) {
                if (isCurrentMiniserver) {
                    // only set when it's the current miniserver, otherwise the external icon is used!
                    pn.iconSrc = iconInfo.iconSrc || Icon.Notification.DEFAULT;
                }

                pn.iconColor = iconInfo.iconColor;
            }));
        }.bind(this));

        return Q.allSettled(styleResolvePromises).then(function () {
            return this.tableView.reload().then(function () {
                this._startTimeUpdate();
            }.bind(this));
        }.bind(this));
    }

    _isNotificationClickable(pn) {
        switch (pn.type) {
            case PushNotificationType.NORMAL:
                return (pn.mac && pn.uuid && this._currentMAC !== pn.mac) || pn.original?.dndQueued;

            case PushNotificationType.MS_DEVICE_MANAGER:
                return true;

            default:
                return false;
        }
    }

    _handleMarkAllAsRead() {
        SandboxComponent.markAllNotificationAsRead();
        this.ViewController.navigateBack();
    }

}

GUI.NotificationHistoryScreen = NotificationHistoryScreen;
