'use strict';


define("TextInputControl", ["Control", "TextInputControlEnums", "./content/controlContent"], function (Control, TextInputControlEnums, TextInputControlContent) {
    return class TextInputControl extends Control {
        constructor() {
            super(...arguments);
        }

        getReactControlContent() {
            return TextInputControlContent.default;
        }

        getFriendlyValueName(event, operator) {
            return this.name + " " + operator + " " + event.value;
        }

    };
});
