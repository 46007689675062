import globalStyles from "GlobalStyles";
import { LxReactButton, ControlContext } from "LxComponents";
import { StyleSheet, View, Text } from "react-native";
import { useContext, useMemo } from "react";

export default function SleepModeWidget() {
    const { control, states } = useContext(ControlContext)

    const endSleepMode = () => {
        control.sendCommand(Commands.AC.CANCEL_SLEEP, Commands.Type.OVERRIDE);
    }

    const sleepUntilTime = useMemo(() => {
        const sleepUntilMoment = moment.unix(states?.pauseUntil)
        return sleepUntilMoment.format(DateType.DateTextAndTime)
    }, [states?.pauseUntil])

    return (
        <>
            {states?.pauseUntil !== 0 ?
                <View style={styles.overview}>
                    <Text style={styles.text}>
                        {states?.pauseUntil === -1 ?
                            _("controls.ac-control.sleep-active") :
                            _("controls.ac-control.sleep-active-until", { time: sleepUntilTime })
                        }
                    </Text>
                    <LxReactButton
                        containerStyle={styles.endSleepButtonContainer}
                        textStyle={styles.text}
                        text={_("controls.ac-control.end-sleep")}
                        onPress={endSleepMode}
                    />
                </View>
                :
                null
            }
        </>
    )
}

const styles = StyleSheet.create({
    overview: {
        width: "100%",
        flexDirection: "column",
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        textAlignVertical: 'center',
        paddingVertical: 15,
        marginHorizontal: 15,
        paddingHorizontal: 15,
        marginBottom: 15,
        borderRadius: 3,
        backgroundColor: globalStyles.colors.fill.tertiary,
        marginTop: -35,
    },
    text: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.primary,
        marginBottom: 15,
    },
    endSleepButtonContainer: {
        width: "100%",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(118, 118, 128, 0.24)",
        marginHorizontal: 15,
        paddingHorizontal: 15,
    }
})