'use strict';

window.GUI = function (GUI) {
    class WeatherAdScreen extends GUI.Screen {
        constructor() {
            super($('<div class="weather-ad-screen" />'));
            Object.assign(this, StateHandler.Mixin);
        }

        viewDidLoad() {
            let prmsArr = [];

            return Q(super.viewDidLoad(...arguments)).then(() => {
                this.pages = [];
                this.pages.push(this._getPage(_("weather.weatherAd.slideOne.title"), _("weather.weatherAd.slideOne.message"), "umfangreiche-wetterdaten.png"));
                var image = PlatformComponent.getLanguage().substr(0, 2) === "de" ? "visualisierung.png" : "visualisierung-uk.png";
                this.pages.push(this._getPage(_("weather.weatherAd.slideTwo.title"), _("weather.weatherAd.slideTwo.message"), image));
                this.pages.push(this._getPage(_("weather.weatherAd.slideThree.title"), _("weather.weatherAd.slideThree.message"), "datengenauigkeit.png"));

                var lastPage = this._getPage(_("weather.weatherAd.slideFour.title"), _("weather.weatherAd.slideFour.message"), "miniserver-integration.png", true);

                this.pages.push(lastPage);
                this.pageView = new GUI.LxPageView(this.pages, null, null, {
                    overscroll: true,
                    pageControl: true
                });
                this.lastPageIndex = this.pageView.pages.length - 1;
                prmsArr.push(this.appendSubview(this.pageView));
                this.submitButton = new GUI.LxButton(this, lastPage.find(".button-container__button--subscription-button")[0], Color.BUTTON_GLOW, null, true);
                this.notNowButton = new GUI.LxButton(this, lastPage.find(".button-container__button--not-now-button")[0], Color.BUTTON_GLOW, null, true);
                prmsArr.push(this.addToHandledSubviews(this.submitButton));
                prmsArr.push(this.addToHandledSubviews(this.notNowButton));
                this.setUpComfortUI(true);
                this.elements.iconPlaceholder.hide();
                this.hideSubview(this.leftButton);

                return Q.all(prmsArr);
            });

        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);
            this.submitButton.useElementAsActivePart("background-color");
            this.notNowButton.useElementAsActivePart("color");
            this.submitButton.onButtonTapped = this._subscribeToWeather.bind(this);
            this.notNowButton.onButtonTapped = this._notNow.bind(this); // needed to change to the weather screen if valid data arrives!

            this._registerForStates(WEATHER_UUID);

            Feature.REQUEST_WEATHER_DATA && this._checkForWeatherData();
        }

        viewWillDisappear() {
            this._unregisterStates(WEATHER_UUID);

            super.viewWillDisappear(...arguments);
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        /**
         * Used to check if valid weather data arrives in the meantime. If so, the weather screen is to be shown instaed
         * @param states
         */
        receivedStates(states) {
            states.hasValidData && this._showWeatherScreen();
        }

        _getPage(title, message, image, showButtons) {
            var imgUrl = "resources/Images/WeatherAd/" + image;
            var content = '   <div class="slide__text-container">' + '       <span class="text-container__title">' + title + '</span>' + '       <span class="text-container__message">' + message + '</span>' + '   </div>' + '   <div class="slide__image-container">' + '       <img src="' + imgUrl + '" class="image-container__image">' + '   </div>';

            if (showButtons) {
                content += '<div class="slide__button-container">' + '   <div class="button-container__button button-container__button--subscription-button">' + '       <div class="button__title">' + _("weather.weatherAd.subscribeButton") + '</div>' + '   </div>' + '   <div class="button-container__button button-container__button--slim button-container__button--not-now-button">' + '       <div class="button__title">' + _("weather.weatherAd.notNowButton") + '</div>' + '   </div>' + '</div>';
            }

            return $(content);
        }

        _getButtonContainer() {
            return $('<div class="footer-content__button-view">' + '<button class="form__submit button-view__subscribe-button">' + _("weather.weatherAd.subscribeButton") + '</button>' + '<div class="form__submit button-view__not-now-button">' + _("weather.weatherAd.notNowButton") + '</div>' + '</div>');
        }

        _subscribeToWeather() {
            this._setViewed();

            NavigationComp.openWebsite(WEATHER_ABO_LINK);
        }

        _notNow() {
            this._setViewed();
        }

        _setViewed() {
            PersistenceComponent.setMSInstructionFlag(UserInstructionFlag.WEATHER_AD);
            this.ViewController.navigateBack();
        }

        /**
         * Recent Miniservers will stop asking for weather data if the server tells them that no abo is regisgtered.
         * If the customer buys a weather abo, he has to reboot the weather server or at least wait for at least a
         * day before the miniserver checks for data again. By sending a command when the user opens the weather add,
         * a weather data request is launched, causing the miniserver to ask for data.
         * @private
         */
        _checkForWeatherData() {
            var promise = CommunicationComponent.send(Commands.Weather.CHECK);
            promise.then(function () {
                /* everythings fine */
            }, function (err) {
                console.error("Weather check command failed: " + JSON.stringify(err));
            });
        }

        /**
         * Called when valid weather data arrives while the weather add screen is shown. if so, the screen needs
         * to change, as we want to present the proper weather.
         * @private
         */
        _showWeatherScreen() {
            this.ViewController.navigateBack();
            NavigationComp.showState(ScreenState.Weather);
        }

    }

    GUI.WeatherAdScreen = WeatherAdScreen;
    return GUI;
}(window.GUI || {});
