'use strict';

import LxSettingsScreen from "./content/settings/LxSettingsScreen";
import LxEditEntryScreen from "./content/editEntry/LxEditEntryScreen";
import LxOperatingModeSelectorScreen from "./content/editEntry/operatingModeSelection/LxOperatingModeSelectorScreen";

define("AlarmClockControl", [
    "Control",
    "AlarmClockControlEnums",
    "IconLib"
], function (
    Control,
    AlarmClockControlEnums,
    {
        default: Icons
    }
) {
    return class AlarmClockControl extends Control {
        constructor() {
            super(...arguments);
        }

        getReactScreens() {
            return [
                ...super.getReactScreens(...arguments),
                {
                    component: LxSettingsScreen,
                    name: AlarmClockControlEnums.ScreenState.DEVICE_SETTINGS
                },
                {
                    component: LxEditEntryScreen,
                    name: AlarmClockControlEnums.ScreenState.EDIT_ENTRY
                },
                {
                    component: LxOperatingModeSelectorScreen,
                    name: AlarmClockControlEnums.ScreenState.RECURRING_LIST
                }
            ]
        }

        getIcon() {
            return Icon.AlarmClock.ALARM_CLOCK;
        }

        getButton0(states) {
            if (states.isRinging || states.isSnoozing) {
                return {
                    iconSrc: Icon.AlarmClock.BTN_DISABLE,
                    reactIcon: Icons.AlarmClockOff,
                    command: Commands.ALARM_CLOCK.DISMISS
                };
            }
        }

        getButton1(states) {
            if (states.isRinging) {
                return {
                    iconSrc: Icon.AlarmClock.BTN_SNOOZE,
                    reactIcon: Icons.Snooze,
                    command: Commands.ALARM_CLOCK.SNOOZE
                };
            }
        }

        /**
         * Returns the Weekday and time of the currently active alarmEntry
         * The time is always the current time to prevent the user from thinking he still has some time left
         * Example: Monday, 17:43 Uhr
         * @returns {String}
         */
        getActiveEntryDateText() {
            var states = this.getStates();

            if (!states) {
                return "";
            }

            var modes = SandboxComponent.getStructureManager().getOperatingModes(true);
            var msTime = SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINISERVER_DATE_TIME);
            var alarmTime = msTime.format(DateType.TimeFormat); // Here we need to add 3 to the current Weekday number (msTime.format("e"))
            // Because the Monday in our modes is at index 3, not 0 like it is normally

            var weekDay = modes[parseInt(msTime.format("e")) + 3];
            return weekDay + ", " + alarmTime;
        }

        /**
         * Returns the Weekday and time of the next alarmEntry
         * Example: Mo, 17:44 Uhr • Work
         * @param shortText If the string should be displayed in a place with limited space
         * @returns {String}
         */
        getNextEntryDateText(shortText) {
            var states = this.getStates(),
                dateText,
                timeOffset,
                nextDate,
                now,
                endOfToday;

            if (!states) {
                return "";
            }

            dateText = LxDate.formatTimeFromSecondsToLocal(states.nextEntry.alarmTime, true);

            if (Feature.ALARM_CLOCK_NEXT_ENTRY_TIME) {
                timeOffset = SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINISERVER_UTC_OFFSET);
                nextDate = new LxDate(states.nextEntryTime, true).utcOffset(timeOffset);
                now = moment();
                endOfToday = now.clone().endOf("day");

                if (nextDate.isBefore(endOfToday)) {
                    // Only display "Today" if the entry is in todays future
                    dateText = _('controls.alarmClock.today', {
                        time: nextDate.format(DateType.TimeFormat)
                    });
                } else if (nextDate.diff(now, "days") >= 6) {
                    // Display the date only if the nextDate is later then 6 days
                    if (shortText) {
                        dateText = nextDate.format(LxDate.getDateTimeFormat("DD.MM", true));
                    } else {
                        dateText = nextDate.format(LxDate.getDateTimeFormat("ddd DD.MM", true));
                    }
                } else {
                    if (shortText) {
                        dateText = nextDate.format(LxDate.getDateTimeFormat(DateType.ShortWeekday, true));
                    } else {
                        // We don't need to display the weekday if the operating mode is already a Weekday
                        dateText = nextDate.format(LxDate.getDateTimeFormat(DateType.Weekday, true));
                    }
                } // We shouldn't show the weekDay if it is a Touch Nightlight entry according to the design
                // And we are showing the text in the controlContent


                if (!shortText && !states.nextEntry.nightLight) {
                    dateText += SEPARATOR_SYMBOL + states.nextEntry.name;
                }
            } else {
                dateText = states.nextEntry.name + ", " + dateText;
            }

            return dateText;
        }

        hasNightLightTouch() {
            // ATTENTION: details are optional!
            return !!this.details && !!this.details.hasNightLight;
        }

        getFriendlyValueName(event, operator) {
            switch (event.data.stateName) {
                case "isAlarmActive":
                    return this.name + " " + (event.value ? _("controls.alarmClock.activeAlarm") : _("controls.alarmClock.inactiveAlarm"));

                default:
                    return "";
            }
        }

        specificCreateCmdText(cmdParts) {
            var cfg = {
                    name: this.name
                },
                text;

            switch (cmdParts[1]) {
                case "entryList":
                    if (cmdParts[2] === "put") {
                        // detect activity
                        cfg.alarm = cmdParts[4];
                        cfg.time = LxDate.formatTimeFromSecondsToLocal(cmdParts[5], true);
                        var active = cmdParts[6] !== "0";

                        if (active) {
                            text = _("cmdtext.alarmclock.entry.activate", cfg);
                        } else {
                            text = _("cmdtext.alarmclock.entry.deactivate", cfg);
                        }
                    } else if (cmdParts[2] === "delete") {
                        text = _("cmdtext.alarmclock.entry.delete", cfg);
                    }

                    break;

                case Commands.ALARM_CLOCK.SNOOZE:
                    text = _("cmdtext.alarmclock.snooze", cfg);
                    break;

                case Commands.ALARM_CLOCK.DISMISS:
                    text = _("cmdtext.alarmclock.dismiss", cfg);
                    break;

                default:
                    text = _("cmdtext.alarmclock.edit", cfg);
                    break;
            }

            return text;
        }

    };
});
