'use strict';

define(['AddMediaViewControllerV2Playlist'], function (AddMediaViewControllerV2Playlist) {
    class AddMediaViewControllerV2PlaylistPicker extends AddMediaViewControllerV2Playlist {
        constructor(details) {
            super(...arguments);
            this.itemToAdd = this.details.itemToAdd;
            this.contentType = this.itemToAdd.contentType;
        }

        /**
         * We Explicitly don't want a right tick button, override the base!
         * @returns {null}
         */
        titleBarConfig() {
            return null;
        }

        addItem(playlist) {
            this.playlistId = playlist.id;
            this.control.audioserverComp.startEditing(playlist.contentType, this.currentView.getMediaInfo(playlist.contentType), this.playlistId, true).done(this._editModeEnded.bind(this), this._editModeError.bind(this), this._editModeStarted.bind(this));
        }

        _startEditMode() {// Just do nothing, prevent the default behaviour
        }

        _updateEditMode(editActive) {
            if (editActive) {
                this._editModeStarted();
            } else {
                this.dismiss();
            }
        }

        _editModeStarted() {
            return this.control.audioserverComp.addItem(this.itemToAdd).then(function () {
                return this.dismiss();
            }.bind(this));
        }

        shouldShowAddCounter() {
            return false; // either it's a single file or a playlist where we have no info on how many items it contains yet.
        }

    }

    GUI.AddMediaViewControllerV2PlaylistPicker = AddMediaViewControllerV2PlaylistPicker;
    return GUI.AddMediaViewControllerV2PlaylistPicker;
});
