'use strict';

window.GUI = function (GUI) {
    class HomeScreen extends GUI.Screen {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(location, hasWeather) {
                var weatherWidget = "",
                    partnerInfo = "";

                if (hasWeather) {
                    weatherWidget = '<div class="top__weather-widget"></div>';
                }

                return $('<div class="home-screen">' + '<div class="home-screen__top">' + getHeader(location) + weatherWidget + '</div>' + partnerInfo + '</div>');
            };

            var getHeader = function getHeader(location) {
                return '' + '<div class="top__header top__header--sd">' + ImageBox.getResourceImageWithClasses(Icon.PIN, 'header__annotation-pin') + '<div class="home__location">' + location + '</div>' + '<div class="home__date-time-container">' + '<div class="container__date"></div>' + '<div class="container__time"></div>' + '</div>' + '</div>';
            };

            var getPartnerInfo = function getPartnerInfo() {
                return '<div class="home-screen__partner-info"></div>';
            };

            return {
                getTemplate: getTemplate,
                getPartnerInfo: getPartnerInfo
            };
        }(); //endregion Static

        constructor() {
            let hasWeatherServer = !!ActiveMSComponent.getStructureManager().getWeatherServer();
            var location = ActiveMSComponent.getMiniserverLocation();
            super(HomeScreen.Template.getTemplate(location, hasWeatherServer));
            var activeMs = ActiveMSComponent.getActiveMiniserver();
            this.widgetConfig = activeMs.widget || {
                building: 0,
                skyline: 0
            };
            this.hasWeatherServer = hasWeatherServer;
        }

        viewDidLoad() {
            super.viewDidLoad();
            var isWebinterface = PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface;
            this.titleBar = this._initTitleBar(isWebinterface);
            this.prependSubview(this.titleBar);
            this.homeWidget = new GUI.HomeWidget(this.widgetConfig, true);
            this.appendSubview(this.homeWidget);
            this.elements = {
                timeLabel: this.element.find(".container__time"),
                dateLabel: this.element.find(".container__date"),
                homeWidget: this.homeWidget.getElement(),
                weatherWidgetContainer: this.element.find(".top__weather-widget"),
                header: this.element.find(".top__header")
            }; //Show WeatherAd if it has not been viewed before

            if (!this.hasWeatherServer) {
                this._showWeatherAdd();

                this.elements.homeWidget.addClass("home-widget--without-weather");
            }

            if (!isWebinterface) {
                this.titleBar.onDropDownStateChange = function (open) {
                    if (open) {
                        this.miniserverList = new GUI.TableViewV2(this, this);
                        this.titleBar.setDropDownListContent(this.miniserverList, true);
                        this.storedMiniserver = PersistenceComponent.getAllMiniserver(true);
                        this.miniserverList.reload();
                    } else {
                        this.miniserverList = null;
                        this.titleBar.setDropDownListContent(null);
                        this.storedMiniserver = null;
                    }
                }.bind(this);
            }

            this.titleBar.onRightButtonTapped = function () {
                NavigationComp.showSearchScreen();
            };
        }

        viewWillAppear() {
            // Check if weatherAdWidget should be removed (after user has read it)
            if (this.weatherAdWidget && PersistenceComponent.getMSInstructionFlag(UserInstructionFlag.WEATHER_AD)) {
                this.removeSubview(this.weatherAdWidget);
                this.weatherAdWidget = null;
            }

            super.viewWillAppear();
        }

        viewDidAppear() {
            super.viewDidAppear();

            if (this.hasWeatherServer) {
                SandboxComponent.registerForStateChangesForUUID(WEATHER_UUID, this, this.weatherStatesReceived.bind(this));
            }

            this.timeIdentifier = SandboxComponent.getMiniserverTimeInfo(this, this._updateDateTime.bind(this), TimeValueFormat.MINISERVER_DATE_TIME, 5000);
        }

        viewWillDisappear(viewRemainsVisible) {
            SandboxComponent.removeFromTimeInfo(this.timeIdentifier);
            SandboxComponent.unregisterForStateChangesForUUID(WEATHER_UUID, this);
            super.viewWillDisappear(viewRemainsVisible);
        }

        destroy() {
            this.miniserverList = null; //this.homeWidget.destroy();

            this.partnerHandler && this.partnerHandler.dispose();
            super.destroy();
        }

        getURL() {
            return "Home";
        }

        destroyOnBackNavigation() {
            return false;
        }

        isReusable() {
            return true;
        }

        weatherStatesReceived(states) {
            if (states.hasValidData) {
                if (this.weatherAdWidget) {
                    this.removeSubview(this.weatherAdWidget);
                    this.weatherAdWidget = null;
                }

                if (!this.weatherWidget) {
                    this.weatherWidget = new GUI.WeatherWidget();
                    this.appendSubview(this.weatherWidget, this.elements.weatherWidgetContainer);
                }
            } else {
                this._showWeatherAdd();
            }

            SandboxComponent.unregisterForStateChangesForUUID(WEATHER_UUID, this);
        }

        // Private methods

        /**
         * Will present a weather add. but only if the user did not yet click through it.
         * @private
         */
        _showWeatherAdd() {
            if (PersistenceComponent.getMSInstructionFlag(UserInstructionFlag.WEATHER_AD)) {
                return; // Did already see it.
            }

            if (this.weatherAdWidget) {
                return; // Don't add another one.
            }

            this.weatherAdWidget = new GUI.WeatherAdWidget();
            this.weatherAdWidget.getElement().toggleClass("cell__top-border-effect", !HD_APP);
            this.appendSubview(this.weatherAdWidget, this.elements.weatherWidgetContainer);
            this.weatherAdWidget.getElement().velocity({
                translateZ: 0,
                // Force HA by animating a 3D property
                translateX: ["0%", "-100%"]
            }, {
                duration: 500,
                easing: "spring"
            });
        }

        _initTitleBar(isWebinterface) {
            var titleBarConfig = {};

            if (isWebinterface) {
                // TODO-goelzda remove dropDownButton in Webinterface! Just removing leftSide is not working
                titleBarConfig.leftSide = 'text';
            } else {
                titleBarConfig.leftSide = 'dropDownButton';
            }

            titleBarConfig.rightSide = TitleBarCfg.Button.SEARCH;
            var titleBar = new GUI.LxTitleBar(titleBarConfig);
            titleBar.setTitleBarSideTexts(ActiveMSComponent.getMiniserverName());
            return titleBar;
        }

        _updateDateTime(now) {
            this.elements.timeLabel.text(now.format(LxDate.getDateFormat(DateType.TimeFormat)));
            this.elements.dateLabel.text(now.format(LxDate.getDateFormat(DateType.WeekdayAndDateText)));
        }

        // TableView delegate
        numberOfSections() {
            return 1;
        }

        numberOfRowsInSection() {
            return this.storedMiniserver.length + 1;
        }

        cellTypeForCellAtIndex(section, row) {
            if (row === this.storedMiniserver.length) {
                return GUI.TableViewV2.CellType.GENERAL;
            }

            return GUI.TableViewV2.CellType.Special.MINISERVER_CELL;
        }

        didSelectCell(cell, section, row) {
            if (row === this.storedMiniserver.length) {// NavigationComp.showAddMiniserverPopup(true); no longer exists
            } else if (this.storedMiniserver[row].serialNo === ActiveMSComponent.getMiniserverSerialNo()) {
                NavigationComp.askForDisconnection();
            } else {
                NavigationComp.connectTo(this.storedMiniserver[row]);
            }
        }

        contentForCell(cell, section, row) {
            var content = {};

            if (row === this.storedMiniserver.length) {
                content.leftIconSrc = "resources/Images/General/button-plus.svg";
                content.leftIconColor = window.Styles.colors.green;
                content.title = _("miniserverlist.add");
                content.clickable = true;
            } else {
                var ms = this.storedMiniserver[row];
                content.title = ms.msName;
                var connectedSerialNo = ActiveMSComponent.getMiniserverSerialNo();

                if (ms.serialNo === connectedSerialNo) {
                    var reachMode = CommunicationComponent.getCurrentReachMode();

                    if (reachMode === ReachMode.LOCAL) {
                        content.subtitle = _("miniserver.connected.internal");
                    } else if (reachMode === ReachMode.REMOTE) {
                        content.subtitle = _("miniserver.connected.external");
                    }

                    content.isConnected = reachMode !== ReachMode.NONE;
                } else if (ms.localUrl) {
                    content.subtitle = ms.localUrl;
                } else {
                    content.subtitle = ms.remoteUrl;
                }
            }

            return content;
        }

        infoForMiniserverCell(cell, section, row) {
            return this.storedMiniserver[row];
        }

    }

    GUI.HomeScreen = HomeScreen;
    return GUI;
}(window.GUI || {});
