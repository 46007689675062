'use strict';

define("DaytimerControl", ["DaytimerControlBase", "DaytimerControlEnums"], function (DaytimerControlBase, DaytimerControlEnums) {
    return class DaytimerControl extends DaytimerControlBase {
        constructor() {
            super(...arguments);
        }

        getIcon() {
            return this.defaultIcon
                ? this.defaultIcon
                : Icon.Daytimer.DAYTIMER;
        }

        getEntryValueString(entry) {
            var valStr = null;

            if (this.details.analog) {
                valStr = entry.value + ""; // it is required to return it as string.
            }

            return valStr;
        }

        specificCreateCmdText(cmdParts) {
            var text = null,
                cfg = {
                    name: this.name
                },
                value;

            switch (cmdParts[1]) {
                case Commands.DAYTIMER.ACTIVATE:
                    text = _("cmdtext.daytimer.activate", cfg);
                    break;

                case "default":
                    // used only in analog daytimers
                    if (this.details.analog) {
                        value = parseFloat(cmdParts[2]);
                        cfg.value = lxFormat(this.details.format, value);
                        text = _("cmdtext.daytimer.default", cfg);
                    }

                    break;

                case "startOverride":
                    // used only in analog daytimers
                    value = parseFloat(cmdParts[2]);
                    var time = parseInt(cmdParts[3]);
                    cfg.duration = LxDate.formatSecondsShort(time, true);

                    if (this.details.analog) {
                        cfg.value = lxFormat(this.details.format, value);
                    } else if (value !== 0) {
                        cfg.value = this.details.text.on;
                    } else {
                        cfg.value = this.details.text.off;
                    }

                    text = _("cmdtext.daytimer.startoverride", cfg);
                    break;

                case "set":
                    text = _("cmdtext.daytimer.set", cfg);
                    break;

                case "modeslist":
                    text = _("cmdtext.daytimer.modeslist", cfg);
                    break;

                default:
                    break;
            }

            return text;
        }

        getFriendlyValueName(event, operator) {
            switch (event.data.stateName) {
                case "needsActivation":
                    return this.name + " " + _('controls.daytimer.activation-required');

                case "value":
                    if (!this.details.analog) {
                        // only digital is handled!
                        return this.name + " " + (event.value ? this.details.text.on : this.details.text.off);
                    } else {
                        return "";
                    }

                default:
                    return "";
            }
        }

    };
});
