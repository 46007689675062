import {Image} from 'react-native';

function LxReactImage(props) {
    return <Image {...props}/>;
}

export default LxReactImage;

LxReactImage.propTypes = {
    ...Image.propTypes
};
