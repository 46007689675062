import {LxReactTableView, navigatorConfig} from "LxComponents"
import {useState, useEffect} from "react";
import PropTypes from "prop-types";

function PresenceDetectorScreen(props) {

    props.navigation.setOptions(
        navigatorConfig({
            animationType: AnimationType.MODAL,
            title: _("loxone-control.presence-detector")
        })
    );

    let tempTableContent = [],
        oldRoomUUID = PersistenceComponent.getMiniserverSettings().presenceRoom,
        defaultValue;

    if (oldRoomUUID) {
        defaultValue = oldRoomUUID;
    } else {
        defaultValue = DIFFERENT_ROOM_IDENTIFIER;
    }

    const [presenceControlUUID, setPresenceControlUUID] = useState(defaultValue);

    useEffect(() => {
        if (props.route.params.presenceControlUUID) {
            setPresenceControlUUID(props.route.params.presenceControlUUID);
        }
    }, [props.route.params.presenceControlUUID])

    const _getContent = () => {
        let section = {
                rows: []
            },
            sectionSelected = presenceControlUUID !== DIFFERENT_ROOM_IDENTIFIER;

        section.rows.push({
            type: GUI.TableViewV2.CellType.CHECKABLE_DESCRIPTION,
            content: {
                title: _("loxone-control.location.fix.title", {
                    computer: _getPlatformName()
                }),
                description: _("loxone-control.location.fix.message"),
                selected: sectionSelected,
                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE
            },
            didCheckCell: () => {
                _handleDidCheckCell()
            }
        });

        if (sectionSelected) {
            section.rows.push(_getSelectRoomCell());
        }

        section.rows.push({
            type: GUI.TableViewV2.CellType.CHECKABLE_DESCRIPTION,
            content: {
                title: _("loxone-control.location.mobile.title", {
                    computer: _getPlatformName()
                }),
                description: _("loxone-control.location.mobile.message"),
                selected: presenceControlUUID === DIFFERENT_ROOM_IDENTIFIER,
                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE
            },
            didCheckCell: () => {
                _handleDidCheckCell(DIFFERENT_ROOM_IDENTIFIER)
            }
        });

        return section;
    }

    /**
     * Returns the platform name (PC or Mac)
     * @param [plural] Weather or not to use plural
     * @return {*}
     * @private
     */
    const _getPlatformName = (plural) => {
        if (plural) {
            return PlatformComponent.isMacOS() ? _("loxone-control.configuration.macs") : _("loxone-control.configuration.pcs");
        } else {
            return PlatformComponent.isMacOS() ? _("loxone-control.configuration.mac") : _("loxone-control.configuration.pc");
        }
    }

    const _getSelectRoomCell = () => {
        let disclosureText,
            isControlUUID = ActiveMSComponent.getStructureManager().isPresenceControlUUID(presenceControlUUID),
            areMultiplePresenceControlsInRoom,
            control,
            roomName;

        if (isControlUUID) {
            areMultiplePresenceControlsInRoom = ActiveMSComponent.getStructureManager().getPresenceRooms().filter((prcControl) => {
                return prcControl.room.uuid === ActiveMSComponent.getStructureManager().getControlByUUID(presenceControlUUID).room;
            }).length > 1;
        }

        if (areMultiplePresenceControlsInRoom) {
            control = ActiveMSComponent.getStructureManager().getControlByUUID(presenceControlUUID);
            roomName = ActiveMSComponent.getStructureManager().getGroupByUUID(control.room, GroupTypes.ROOM).name;
            disclosureText = roomName + SEPARATOR_SYMBOL + control.getName();
        } else if (isControlUUID) {
            control = ActiveMSComponent.getStructureManager().getControlByUUID(presenceControlUUID);
            roomName = ActiveMSComponent.getStructureManager().getGroupByUUID(control.room, GroupTypes.ROOM).name;
            disclosureText = roomName;
        } else {
            disclosureText = ActiveMSComponent.getStructureManager().getGroupByUUID(presenceControlUUID, GroupTypes.ROOM).name;
        }

        return {
            content: {
                title: _("loxone-control.location", {
                    computer: _getPlatformName(true)
                }),
                disclosureText: disclosureText,
                disclosureIcon: true
            },
            action: () => {
                props.navigation.navigate(ScreenState.PresenceRoomSelection, {
                    presenceControlUUID
                });
            }
        };
    }

    const _handleDidCheckCell = (presenceControlUuid) => {
        let controlUuid;

        if (presenceControlUuid === DIFFERENT_ROOM_IDENTIFIER) {
            controlUuid = DIFFERENT_ROOM_IDENTIFIER;
        } else {
            controlUuid = _getFirstPresenceControlUUID();
        }

        _savePresenceAndWallmountRoom(controlUuid);
        setPresenceControlUUID(controlUuid);
    }

    const _getFirstPresenceControlUUID = () => {
        return ActiveMSComponent.getStructureManager().getPresenceRooms()[0].control.uuidAction;
    }

    const _savePresenceAndWallmountRoom = (controlUuid) => {
        let alsoWallmount = controlUuid !== DIFFERENT_ROOM_IDENTIFIER;
        PersistenceComponent.setPresenceRoom(controlUuid, null, alsoWallmount);
    }

    tempTableContent.push(_getContent());

    return <LxReactTableView
        tableContent={tempTableContent}
        showSectionSeparator={true}
    />
}

PresenceDetectorScreen.propTypes = {
    navigator: PropTypes.object
}

PresenceDetectorScreen.Title = _('loxone-control.presence-detector');

export default PresenceDetectorScreen;
