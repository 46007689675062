import {useMemo} from "react";
import { View, StyleSheet } from "react-native";
import { useLiveState, LxReactImageView } from "LxComponents";
import GaugeHigh from "../../../resources/Images/Controls/SpotPriceOptimizer/gauge-high@4x.png";
import GaugeHighest from "../../../resources/Images/Controls/SpotPriceOptimizer/gauge-highest@4x.png";
import GaugeLow from "../../../resources/Images/Controls/SpotPriceOptimizer/gauge-low@4x.png";
import GaugeLowest from "../../../resources/Images/Controls/SpotPriceOptimizer/gauge-lowest@4x.png";
import GaugeVHigh from "../../../resources/Images/Controls/SpotPriceOptimizer/gauge-vhigh@4x.png";
import GaugeVLow from "../../../resources/Images/Controls/SpotPriceOptimizer/gauge-vlow@4x.png";
import BaseGauge from "../../../resources/Images/Controls/SpotPriceOptimizer/gauge@4x.png";
import { Categorization, categorizeValue } from "../../../scripts/Controls/spotPriceOptimizerControl/utils";

export default function Gauge(props) {
    const {
            controlUuid,
            containerStyle,
            forCell
        } = props,
        {states = {}} = useLiveState(controlUuid, ["current", "statsForCurrentForecast"], "manualMax");

    const gaugeURI = useMemo(() => {
        const category = categorizeValue({
            value: states.current,
            stats: states.statsForCurrentForecast
        }, states.manualMax);
        switch(category) {
            case Categorization.HIGHEST:
                return GaugeHighest;
            case Categorization.V_HIGH:
                return GaugeVHigh;
            case Categorization.HIGH:
                return GaugeHigh;
            case Categorization.LOW:
                return GaugeLow;
            case Categorization.V_LOW:
                return GaugeVLow;
            case Categorization.LOWEST:
                return GaugeLowest
            default:
                return BaseGauge;
        }
    }, [states.current, JSON.stringify(states.statsForCurrentForecast)]);


    return (
        <View style={StyleSheet.compose(containerStyle, {flex: 1})}>
            <LxReactImageView
                source={gaugeURI}
                containerStyle={forCell ? styles.cellImageContainer : styles.cardImageContainer}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    cardImageContainer: {
        width: "100%",
        maxWidth: 48
    },
    cellImageContainer: {
        width: "100%",
        maxWidth: 66
    }
});
