import {LxBaseControlContent} from "LxComponents";
import LxTrackerView from "../trackerView/LxTrackerView";
import {useContext} from "react";
import {ControlContext} from "../../../react-comps/Components";

function TrackerControlContent (props) {
    const {states} = useContext(ControlContext)

    return <LxBaseControlContent showStateIcon={false} showStateText={!states.lastEntry}>
        <LxTrackerView />
    </LxBaseControlContent>
}

export default TrackerControlContent
