'use strict';

define(["ControlSetting"], function (ControlSetting) {
    return class ControlSettingsEditScreen extends GUI.TableViewScreen {
        //region Static
        static Template = function () {
            var getTemplate = function (setting) {
                return $('<div class="control-settings-edit-screen">' + '   <div class="control-settings-edit-screen__scroll-container"></div>' + '</div>');
            };

            var getSettingsWarningHeader = function getSettingsWarningHeader(icon, setting) {
                return $('<div class="section__header-view">' + '   <div class="header-view__icon-placeholder">' + '       ' + ImageBox.getResourceImageWithClasses(icon, "icon-placeholder__icon") + '   </div>' + '   <div class="header-view__texts">' + '       ' + '<div class="texts__title">' + setting + '</div>' + '       ' + '<div class="texts__detail">' + _('expert-mode.warning', {
                    setting: setting,
                    link: "<div class='detail__link'>" + setting + "..." + "</div>"
                }) + '       ' + '</div>' + '   </div>' + '</div>');
            };

            var lockedSectionHeader = function lockedSectionHeader(settingsId) {
                var lockedString;

                switch (settingsId) {
                    case ControlSettingID.DESCRIPTION:
                        lockedString = _('expert-mode.cant-be-changed');
                        break;

                    case ControlSettingID.CONTROL_SPECIFIC.LIGHT_V2_CONTROL.ALB:
                        lockedString = _('expert-mode.control-specific.lcv2.alb');
                        break;

                    default:
                        lockedString = _('expert-mode.value.cant-be-changed');
                }

                return $('<div class="section__header-view header-view--unsupported">' + '   <div class="header-view__title">' + lockedString + '</div>' + '</div>');
            };

            return {
                getTemplate: getTemplate,
                getSettingsWarningHeader: getSettingsWarningHeader,
                lockedSectionHeader: lockedSectionHeader
            };
        }(); //endregion Static

        constructor(details) {
            super(ControlSettingsEditScreen.Template.getTemplate(details.setting));
            this.setting = details.setting;
            this.settingsUtil = details.settingsUtil;
            this.object = details.object;

            if (this.setting.id === ControlSettingID.IMAGE) {
                this._sortValidValuesByName();
            }

            this.lightMode = details.lightMode;
            this._value = details.value; // if we have edited the value previously..

            this._changedSubSettings = details.changedSubSettings || {}; // if we have subSettings
        }

        updateView(details) {
            // is called when we just edited a value, and navigated back.. (from subSettings!)
            if (details.value !== undefined) {
                this._changedSubSettings[details.setting.id] = details.value;

                this._reloadSetting(details.setting.id);
            }
        }

        viewDidLoad() {
            var promise = super.viewDidLoad(...arguments);

            if (this.lightMode) {
                this.setUpComfortUI();
                this.setTitle(this.titleBarText());
            }

            this.simpleDesignIsActivated = PersistenceComponent.getSimpleDesignSetting();
            this._simpleDesignReg = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.SimpleDesignChanged, function (event, value) {
                this.needsTableViewReload = this.simpleDesignIsActivated !== value;
            }.bind(this));
            return promise;
        }

        viewDidAppear() {
            var promise = super.viewDidAppear(...arguments); // Reload the Table to update the tableView in case the user deactivated the simple UI
            // via the tableView Header

            if (this.needsTableViewReload) {
                this.reloadTable();
            }

            return promise;
        }

        getTableViewPlaceholder() {
            return this.element.find(".control-settings-edit-screen__scroll-container");
        }

        viewWillDisappear() {
            // Important Note: Don't send anything that required permissions in this function
            // All permissions are still available when executing "viewWillDisappear" when the app has been closed,
            // but they may not when the send function is executed, this will lead to error popups when re-opening the app.
            // Use the "_navigateBack" function instead!
            return super.viewWillDisappear(...arguments);
        }

        destroy() {
            NavigationComp.removeFromUIEvent(this._simpleDesignReg);
            return super.destroy(...arguments);
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        getURL() {
            return this.setting.id;
        }

        titleBarText() {
            // Set a non-breaking-space in case no name is provided due to a Miniserver bug
            // This prevents the titlebar from being not displayed
            return this.setting.name || NBR_SPACE;
        }

        titleBarAction() {
            this._navigateBack();
        }

        closeAction() {
            this._navigateBack();
        }

        reloadTable() {
            this.tableContent = [];
            var settingsSection = {
                rows: []
            };
            var locked = this.setting.isLocked(),
                format = this.setting.format,
                value = this._newValue !== undefined ? this._newValue : this._value !== undefined ? this._value : this.setting.value;

            if (format === ControlSettingType.BOOLEAN) {
                settingsSection.rows.push(this._prepareBooleanSetting(locked, format, value));
            } else if (format === ControlSettingType.NUMBER) {
                settingsSection.rows.push(this._prepareNumberSetting(locked, format, value));
            } else if (format === ControlSettingType.STRING) {
                settingsSection.rows.push(this._prepareStringSetting(locked, format, value));
            } else if (format === ControlSettingType.RADIO) {
                this.setting.validValues.forEach(function (validValue) {
                    settingsSection.rows.push(this._prepareRadioSetting(locked, format, value, validValue));
                }.bind(this));
            }

            if (this.setting.id === ControlSettingID.CATEGORY_COLOR && PersistenceComponent.getSimpleDesignSetting()) {
                settingsSection.headerElement = ControlSettingsEditScreen.Template.getSettingsWarningHeader(Icon.Menu.APPEARANCE, _('simple-design'));

                settingsSection.didSelectHeader = function () {
                    NavigationComp.showState(ScreenState.PresentationSettings, {}, AnimationType.HD_MODAL_FULLSCREEN);
                }.bind(this);
            }

            if ((this.setting.id === ControlSettingID.RATING_CONTROLS || this.setting.id === ControlSettingID.FAVORITE_CONTROLS || this.setting.id === ControlSettingID.RATING_GROUPS || this.setting.id === ControlSettingID.FAVORITE_GROUPS) && ActiveMSComponent.getDeviceFavoritesActive()) {
                settingsSection.headerElement = ControlSettingsEditScreen.Template.getSettingsWarningHeader(Icon.Menu.FAVORITES, _('device-favorites.title'));

                settingsSection.didSelectHeader = function () {
                    NavigationComp.showState(ScreenState.DeviceFavoritesSettings, {}, AnimationType.HD_MODAL_FULLSCREEN);
                }.bind(this);
            }

            if (this.setting.isLocked() && !this.setting.isCustomParameter()) {
                settingsSection.headerElement = ControlSettingsEditScreen.Template.lockedSectionHeader(this.setting.id);
            }

            if (settingsSection.rows.length > 0) {
                this.tableContent.push(settingsSection);
            } // SUB SETTINGS


            if (this.setting.subSettings && this._isSettingEnabled()) {
                this.tableContent.push(this._prepareSubSettings());
            } // HELP


            if (this._showHelpFootNote()) {
                // add help to last section
                var lastSection = this.tableContent[this.tableContent.length - 1];
                lastSection.footer = {
                    iconSrc: "resources/Images/General/icon-description.svg",
                    title: this.setting.name,
                    message: this.setting.help.replace(/\\n/g, "\n")
                };
            }

            return super.reloadTable(...arguments);
        }

        // TableViewDataSource Methods
        styleForTableView(tableView) {
            if (this.lightMode) {
                return TableViewStyle.TRANSLUCENT;
            }

            return TableViewStyle.GROUPED;
        }

        // GUI.TableViewV2.CellType.INPUT
        submitText(section, row, tableView, value, valid, valueDidChange) {
            if (this.setting.id !== ControlSettingID.HELP_TEXT && this._newValue !== undefined) {
                this._navigateBack();
            }
        }

        // Private methods
        _reloadSetting(settingID) {
            // we search for the correct section/row of the setting to reload...
            let subSetting = this.setting.subSettings.find(setting => {
                return setting.id === settingID;
            });

            if (subSetting) {
                this.reloadTable();
            }
        }

        _navigateBack() {
            // value changed..
            if (this._newValue !== undefined) {
                // Only verify if the user has the permissions!
                // The ViewController is handling the permissions (requesting, keepalive), if the user hasn't any
                // he canceled the permission request and thus isn't eligible to verify anything, so just navigate back
                // without any details to not call the "updateView" method of the previous view.
                // The "_navigateBack" is initiated by "this.ViewController.dismiss()" which is initiated by a
                // canceled permission request.
                this.ViewController.hasPermissionsGranted().done(function () {
                    // verify now, to update the prev. view according to the correct value
                    this.ViewController.verifyValue(this.setting, this._newValue).then(function (newValue) {
                        this.ViewController.navigateBack(false, {
                            setting: this.setting,
                            value: newValue,
                            changedSubSettings: this._changedSubSettings
                        });
                    }.bind(this)).finally(function () {
                        delete this._newValue;
                    }.bind(this));
                }.bind(this), function () {
                    this.ViewController.navigateBack();
                }.bind(this));
            } else if (this._changedSubSettings) {
                this.ViewController.navigateBack(false, {
                    setting: this.setting,
                    changedSubSettings: this._changedSubSettings
                });
            } else {
                this.ViewController.navigateBack();
            }
        }

        _sortValidValuesByName() {
            this.setting.validValues.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            });
        }

        _isSettingEnabled() {
            if (typeof this._newValue === "boolean") {
                if (this._newValue) {
                    return true;
                }
            } else if (typeof this._value === "boolean") {
                if (this._value) {
                    return true;
                }
            } else if (this.setting.value) {
                return true;
            }

            return false;
        }

        _prepareBooleanSetting(locked, format, value) {
            return {
                type: GUI.TableViewV2.CellType.SWITCH,
                content: {
                    title: this.setting.name,
                    active: value,
                    disabled: locked
                },
                onSwitchChanged: function onSwitchChanged(value, section, row, tableView, cell) {
                    var handled = false;
                    handled |= this._handleUseInVisuActiveChange(value, this.setting.id, cell);
                    handled |= this._handleStatisticActiveChange(value, this.setting.id, cell);

                    if (handled) {// Nothing to do, handled by handler fns.
                    } else if (this.setting.subSettings) {
                        // if we have subSettings, we verify the setting directly!
                        this.ViewController.verifyValue(this.setting, value).done(function (verifiedValue) {
                            this._newValue = verifiedValue;
                            this.reloadTable();
                        }.bind(this));
                    } else {
                        this._newValue = value;
                    }
                }.bind(this)
            };
        }

        _prepareNumberSetting(locked, format, value) {
            if (this.setting.isRatingSetting()) {
                return this._prepareRatingSetting.apply(this, arguments);
            }

            var content = {
                value: parseFloat(value),
                placeholder: this.setting.value,
                // always use the default value as placeholder..
                autoFocus: !locked,
                disabled: locked,
                type: "number",
                validationRegex: Regex.DECIMAL_VALUE,
                numberStep: this.setting.stepValue
            };

            if (this.setting.stepValue % 1 === 0) {
                content.validationRegex = Regex.INT_VALUE;
            }

            if (typeof this.setting.minValue === "number" && typeof this.setting.maxValue === "number") {
                content.numberMin = this.setting.minValue;
                content.numberMax = this.setting.maxValue;
            }

            return {
                type: GUI.TableViewV2.CellType.INPUT,
                content: content,
                textChanged: function textChanged(section, row, tableView, value, valid, valueDidChange) {
                    if (!valueDidChange) {
                        return;
                    }

                    value = parseFloat(value);

                    if (!isNaN(value)) {
                        this._newValue = value;
                    }
                }.bind(this)
            };
        }

        _prepareRatingSetting(locked, format, value) {
            return {
                type: GUI.TableViewV2.CellType.SLIDER,
                content: {
                    title: this.setting.name,
                    format: "%i",
                    value: value,
                    valueColor: window.Styles.colors.green,
                    step: this.setting.stepValue,
                    minValue: this.setting.minValue,
                    maxValue: this.setting.maxValue,
                    minIconSrc: Icon.STAR,
                    maxIconSrc: Icon.STAR,
                    antiGhostTimer: false
                },
                sliderClicked: function sliderClicked(cell, section, row, tableView, value) {
                    this._newValue = value;
                }.bind(this),
                sliderDragged: function sliderDragged(cell, section, row, tableView, value) {
                    this._newValue = value;
                }.bind(this)
            };
        }

        _prepareStringSetting(locked, format, value) {
            return {
                type: GUI.TableViewV2.CellType.INPUT,
                content: {
                    value: value.sanitizeHTML(),
                    // Prevent XSS injections
                    placeholder: this.setting.value || this.setting.name,
                    // always use the default value as placeholder..
                    autoFocus: !locked,
                    disabled: locked,
                    type: GUI.LxInputEnum.Type.TEXT,
                    multiline: this.setting.id === ControlSettingID.HELP_TEXT
                },
                textChanged: function textChanged(section, row, tableView, value, valid, valueDidChange) {
                    if (!valueDidChange) {
                        return;
                    }

                    if (!valid && value !== "") {
                        // allow empty strings
                        delete this._newValue;
                    } else {
                        this._newValue = value;
                    }
                }.bind(this)
            };
        }

        _prepareRadioSetting(locked, format, value, validValue) {
            var radioContent = {
                title: validValue.name,
                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                // eslint-disable-next-line eqeqeq
                selected: value == validValue.id // don't use === (value may be a number as a string due to verify command)

            };

            if (this.setting.id === ControlSettingID.CATEGORY_COLOR) {
                radioContent.rightIconSrc = Icon.CIRCLE;
                radioContent.rightIconColor = adoptConfigColor(validValue.id, true);
            } else if (this.setting.id === ControlSettingID.IMAGE) {
                radioContent.rightIconSrc = validValue.id;
                radioContent.rightIconColor = window.Styles.colors.brand;
            } else if (this.setting.id === ControlSettingID.DEFAULT_ICON || this.setting.id === ControlSettingID.CONTROL_SPECIFIC.ENERGY_MANAGER.LOAD_ICON) {
                radioContent.rightIconSrc = validValue.id;
                radioContent.rightIconColor = window.Styles.colors.brand;
            }

            return {
                type: GUI.TableViewV2.CellType.CHECKABLE,
                content: radioContent,
                didCheckCell: function didCheckCell(cell, section, row, tableView, selected) {
                    if (selected) {
                        this._newValue = this.setting.validValues[row].id;
                    }
                }.bind(this)
            };
        }

        _prepareSubSettings() {
            var section = {
                rows: []
            };
            this.setting.subSettings.forEach(function (subSetting) {
                section.rows.push(this._prepareSubSetting(subSetting));
            }.bind(this));
            return section;
        }

        _prepareSubSetting(subSetting) {
            subSetting = new ControlSetting(subSetting);
            var locked = subSetting.isLocked();
            return {
                content: {
                    title: subSetting.name,
                    titleColor: locked ? Color.TEXT_INACTIVE : null,
                    disclosureText: subSetting.userfriendlyValue(this._changedSubSettings[subSetting.id]),
                    // maybe we have a changedValue for it..
                    disclosureColor: locked ? Color.TEXT_INACTIVE : window.Styles.colors.green,
                    disclosureIcon: true,
                    clickable: true
                },
                action: function action() {
                    this.ViewController.showState(ScreenState.ControlSettingEdit, null, {
                        setting: subSetting,
                        value: this._changedSubSettings[subSetting.id]
                    });
                }.bind(this)
            };
        }

        _handleStatisticActiveChange(value, settingId, cell) {
            var doesHandle = settingId === ControlSettingID.STATISTIC && !value;
            doesHandle &= Feature.DELETE_STATS_ON_DISABLE;
            doesHandle &= this._value !== value; // don't handle if it was already off initially.

            if (doesHandle) {
                // ask user before..
                NavigationComp.showPopup({
                    title: _('caution'),
                    message: _("expert-mode.statistic-delete-warning"),
                    icon: Icon.CAUTION,
                    color: window.Styles.colors.red,
                    buttonOk: _('delete'),
                    buttonCancel: true
                }).done(function () {
                    if (this.object.uuidAction) {
                        SandboxComponent.deleteStatisticDataOf(this.object.uuidAction);
                    }

                    this._newValue = false;
                }.bind(this), function () {
                    cell.setActive(true);
                });
            }

            return doesHandle;
        }

        _handleUseInVisuActiveChange(value, settingId, cell) {
            var handle = !value && settingId === ControlSettingID.USE_IN_VISU;

            if (handle) {
                // ask user before..
                NavigationComp.showPopup({
                    title: _('caution'),
                    message: _('expert-mode.use-in-visu.warning'),
                    icon: Icon.CAUTION,
                    color: window.Styles.colors.red,
                    buttonOk: _('apply'),
                    buttonCancel: true
                }).done(function () {
                    this._newValue = false;
                }.bind(this), function () {
                    cell.setActive(true);
                });
            }

            return handle;
        }

        _showHelpFootNote() {
            let show = !this.lightMode;

            if (this.settingsUtil) {
                try {
                    show = this.settingsUtil.showHelpFootNote();
                } catch (ex) {}
            }

            return show;
        }

    };
});
