import {LxReactTableView, navigatorConfig} from "LxComponents"
import PropTypes from "prop-types";

function PresenceRoomSelectionScreen(props) {

    props.navigation.setOptions(
        navigatorConfig({
            animationType: AnimationType.MODAL,
            title: _("loxone-control.location", {
                computer: "PCs"
            })
        })
    );

    let tempTableContent = [],
        presenceControlUUID = props.route.params.presenceControlUUID,
        presenceRooms = ActiveMSComponent.getStructureManager().getPresenceRooms();

    const _getContent = () => {
        return {
            rows: presenceRooms.map(presenceObj => {
                let presenceControlRoomDetails = presenceObj.room,
                    isControlUUID = ActiveMSComponent.getStructureManager().isPresenceControlUUID(presenceControlUUID),
                    controlsInSameRoom,
                    areMultiplePresenceControlsInRoom; // Before the uuidAction from the control, we used the roomUuid
                // So we convert the last selected roomUuid to the last selected uuidAction in this room

                if (!isControlUUID) {
                    controlsInSameRoom = presenceRooms.filter((obj) => {
                        return obj.room.uuid === presenceControlUUID;
                    });
                    presenceControlUUID = controlsInSameRoom[controlsInSameRoom.length - 1].control.uuidAction;
                }

                areMultiplePresenceControlsInRoom = presenceRooms.filter((prcControl) => {
                    return prcControl.room.uuid === presenceObj.room.uuid;
                }).length > 1;
                return {
                    type: GUI.TableViewV2.CellType.CHECKABLE,
                    content: {
                        title: areMultiplePresenceControlsInRoom ? presenceObj.control.getName() : presenceControlRoomDetails.name,
                        subtitle: areMultiplePresenceControlsInRoom ? presenceControlRoomDetails.name : null,
                        selected: presenceObj.control.uuidAction === presenceControlUUID,
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED
                    },
                    didCheckCell: () => {
                        let alsoWallmount = presenceObj.control.uuidAction !== DIFFERENT_ROOM_IDENTIFIER;
                        PersistenceComponent.setPresenceRoom(presenceObj.control.uuidAction, null, alsoWallmount);

                        props.navigation.navigate(ScreenState.PresenceDetector, {
                            presenceControlUUID: presenceObj.control.uuidAction
                        });
                    }
                };
            }),
            footerTitle: _("loxone-control.location.other-room")
        }
    }

    tempTableContent.push(_getContent());

    return <LxReactTableView
        tableContent={tempTableContent}
        hideItemSeparator={true}
    />
}

PresenceRoomSelectionScreen.propTypes = {
    navigator: PropTypes.object,
    presenceControlUUID: PropTypes.string,
    def: PropTypes.func
}

PresenceRoomSelectionScreen.Title = _("loxone-control.location", {
    computer: "PCs"
});

export default PresenceRoomSelectionScreen;
