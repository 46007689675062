'use strict';

window.GUI = function (GUI) {
    class GroupSelectorScreen extends GUI.Screen {
        constructor(details) {
            super($('<div class="group-selector-screen"></div>'));

            if (details) {
                this.currentGroupType = details.groupType || GroupTypes.ROOM;
                this._currentGroup = details.currentGroup; // only stored temporarily until viewDidLoad!
            } else {
                this.currentGroupType = GroupTypes.ROOM;
            }

            this.sortByRating = PersistenceComponent.getMiniserverSettings().sortByRating;
        }

        viewDidLoad() {
            Debug.GUI.GroupSelector && console.log(this.name, "viewDidLoad");
            super.viewDidLoad();
            var groupNames = ActiveMSComponent.getStructureManager().getCustomGroupTitles();
            var segments = [{
                text: groupNames[GroupTypes.ROOM].toUpperCase()
            }, {
                text: groupNames[GroupTypes.CATEGORY].toUpperCase()
            }];
            this.segmentedControl = new GUI.LxSegmentedControl(segments);
            this.segmentedControl.on("on-segment-selected", function (ev, idx) {
                this.pageView.setActivePage(idx);
            }.bind(this));
            this.appendSubview(this.segmentedControl); // groups

            this.roomList = new GUI.LxGroupListTableView(GroupTypes.ROOM);
            this.catList = new GUI.LxGroupListTableView(GroupTypes.CATEGORY); // pageView

            var pages = [this.roomList.getElement(), this.catList.getElement()];
            this.pageView = new GUI.LxPageView(pages, null, this._onPageAnimationEnd.bind(this), {
                overscroll: false
            });
            this.pageView.disableUserInteraction(true);
            this.appendSubview(this.pageView);
            this.addToHandledSubviews(this.roomList);
            this.addToHandledSubviews(this.catList);

            if (this._currentGroup) {
                // the groupType might have changed without the group changing accordingly, e.g. by swapping from
                // the rooms to the cats without selecting a category.
                if (this._currentGroup.groupType !== this.currentGroupType) {
                    this.setGroupType(this._currentGroup.groupType);
                }

                if (this._currentGroup.groupType === GroupTypes.ROOM) {
                    this.roomList.selectGroup(this._currentGroup);
                } else {
                    this.catList.selectGroup(this._currentGroup);
                }

                delete this._currentGroup; // delete, it's no longer needed!
            }
        }

        _updatePageIdx() {
            // set active page
            var pageIdx = this.currentGroupType === GroupTypes.CATEGORY ? 1 : 0; // default or none is room

            if (pageIdx === this.pageIdx) {
                return; // Nothing to do.
            }

            this.pageIdx = pageIdx;
            Debug.GUI.GroupSelector && console.log("   current group type = " + this.currentGroupType);
            this.segmentedControl.setActiveSegment(pageIdx);
            this.pageView.setActivePage(pageIdx);
        }

        viewWillAppear() {
            Debug.GUI.GroupSelector && console.log(this.name, "viewWillAppear");
            Debug.GUI.GroupSelector && console.log("   current group type = " + this.currentGroupType); // check before calling viewWillAppear!

            var currentSortByRating = PersistenceComponent.getMiniserverSettings().sortByRating;

            if (this.sortByRating !== currentSortByRating) {
                this.roomList.reload();
                this.catList.reload();
                this.sortByRating = currentSortByRating;
            }

            super.viewWillAppear(...arguments);

            this._updatePageIdx();
        }

        viewDidAppear() {
            Debug.GUI.GroupSelector && console.log(this.name, "viewDidAppear");
            super.viewDidAppear(...arguments);
            this.roomSelectionListener = this.roomList.on("group-selected", this._onGroupSelected.bind(this));
            this.catSelectionListener = this.catList.on("group-selected", this._onGroupSelected.bind(this));
        }

        viewWillDisappear() {
            this.catList.off(this.catSelectionListener);
            this.roomList.off(this.roomSelectionListener);
            super.viewWillDisappear(...arguments);
        }

        getURL() {
            return this.currentGroupType;
        }

        isReusable() {
            return true;
        }

        destroy() {
            this.currentGroupType = null;
            this.lastScrollPosition = null;
            super.destroy();
        }

        updateView(details) {
            Debug.GUI.GroupSelector && console.log(this.name, "updateView: " + JSON.stringify(details));

            if (details.groupType === GroupTypes.ROOM || details.groupType === GroupTypes.CATEGORY) {
                this.currentGroupType = details.groupType; // the UI will be updated on viewWillAppear
            }
        }

        setGroupType(groupType) {
            this.currentGroupType = groupType;

            this._updatePageIdx();
        }

        setCurrentGroup(group) {
            if (this.currentGroupType === GroupTypes.ROOM) {
                this.roomList.selectGroup(group);
                this.catList.deselectAll();
            } else {
                this.catList.selectGroup(group);
                this.roomList.deselectAll();
            }
        }

        // Private methods
        _onPageAnimationEnd(pageIdx) {
            this.segmentedControl.setActiveSegment(pageIdx);
            this.currentGroupType = pageIdx === 0 ? GroupTypes.ROOM : GroupTypes.CATEGORY; // also update the URL

            NavigationComp.updateURL();
        }

        _onGroupSelected(ev, groupUUID, groupType) {
            if (HD_APP) {
                // deselect all cells from other table
                this._deselectGroup(groupType);

                this.emit("on-group-selected", groupUUID);
            } else if (this.isQuickGroupSelector) {
                this.ViewController.navigateBack();
                NavigationComp.showState(ScreenState.GroupContent, {
                    groupUUID: groupUUID
                });
            } else {
                this.ViewController.showState(ScreenState.GroupContent, null, {
                    groupUUID: groupUUID
                }).done(function () {
                    this._deselectGroup(groupType);
                }.bind(this));
            }
        }

        titleBarText() {
            if (!HD_APP) {
                return ActiveMSComponent.getMiniserverName();
            }
        }

        titleBarV2Config() {
            if (!HD_APP) {
                return {
                    leftIcon: Icon.TabBar.MENU,
                    rightIcons: [Icon.TitleBar.SEARCH]
                };
            }
        }

        titleBarAction() {
            NavigationComp.showMenu();
        }

        titleBarActionRight() {
            NavigationComp.showSearchScreen();
        }

        _deselectGroup(groupType) {
            // on hd we must deselect the other grouptype, on handheld the current grouptype!
            if (HD_APP) {
                if (groupType === GroupTypes.ROOM) {
                    this.catList.deselectAll();
                } else {
                    this.roomList.deselectAll();
                }
            } else {
                if (groupType === GroupTypes.ROOM) {
                    this.roomList.deselectAll();
                } else {
                    this.catList.deselectAll();
                }
            }
        }

    }

    GUI.GroupSelectorScreen = GroupSelectorScreen;
    return GUI;
}(window.GUI || {});
