import {
    useControl,
    LxReactTableView,
    navigatorConfig
} from "LxComponents"
import globalStyles from "GlobalStyles"
import {useState} from "react";
import {useBackNavigation} from "../../../../react-comps/Components";


export default function LoadPrioSettings({navigation, route}) {
    const ctrlUuid = route.params.controlUuid;
    const [intLoads, setIntLoads] = useState(route.params.loads);
    const control = useControl(ctrlUuid);

    useBackNavigation(() => {
        handleDone();
    });

    const handleDone = () => {
        control.setLoadPrioOrder(intLoads.map(loadObj => {
            return loadObj.uuid;
        })).finally(res => {
            navigation.goBack();
        })
    }

    const topNavCfg = navigatorConfig({
        title: _("energymanager2.priority-title"),
        onLeftAction: () => {handleDone()},
        animationType: AnimationType.MODAL
    });
    navigation.setOptions({
        ...topNavCfg
    })

    const styles = {
        rootCntr: {
           // ...globalStyles.customStyles.screenContent
        }
    }

    const createLoadCell = (loadObj, idx) => {
        let title = loadObj.name,
            subTitle = _("energymanager2.priority-number", {prioNr: idx + 1});

        return {
            cellElementStyle: globalStyles.customStyles.cellElement,
            title,
            titleStyle: globalStyles.customStyles.cellTitle,
            subTitle,
            subTitleStyle: globalStyles.customStyles.cellSubTitle
        }
    }

    const onCellMove = (section, from, to) => {
        setIntLoads(loads => {
            let tmpIntLoads = cloneObjectDeep(loads);
            tmpIntLoads.move(from, to);
            return tmpIntLoads;
        });
    }

    return <LxReactTableView tableContent={[
            {
                rows: intLoads.map(createLoadCell)
            }
        ]} editMap={{sortable: true, removable: false }} onCellMove={onCellMove} />
}
