'use strict';

define(["AutomaticDesignerSearchViewController"], function (AutomaticDesignerSearchViewController) {
    return class AutomaticDesignerRuleMenuViewController extends GUI.ModalViewController {

        constructor(details) {
            super();
            Object.assign(this, ContextMenuHandler.Mixin);

            if (details) {
                this.details = details;
                this.filterControls = details.filterControls || [];
            }
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                return this.showState(ScreenState.AutomaticDesigner.RuleMenuScreen, null, this.details, AnimationType.NONE).then(function () {
                    if (this.details && this.details.filterControls && this.details.filterControls.length) {
                        var filterDetails = this.details;
                        filterDetails.entry = this.details.filterControls[0];
                        return this.showState(ScreenState.AutomaticDesigner.RuleMenuScreen, null, filterDetails, AnimationType.NONE);
                    } else {
                        return Q.resolve();
                    }
                }.bind(this));
            }.bind(this));
        }

        titleBarText() {
            if (this.details.screenType === AutomaticDesignerEnums.SCREEN_TYPES.EVENTS) {
                return _('automatic-designer.rule.events.add');
            } else if (this.details.screenType === AutomaticDesignerEnums.SCREEN_TYPES.ACTIONS) {
                return _('automatic-designer.rule.actions.add');
            }
        }

        titleBarConfig() {
            return {
                rightSide: TitleBarCfg.Button.SEARCH
            };
        }

        titleBarActionRight() {
            this.showGlobalSearch();
        }

        closeAction() {
            return this.dismiss();
        }

        showSearchCell() {
            return this.history.stack.length === 0;
        }

        showGlobalSearch() {
            this.ViewController.showState(ScreenState.AutomaticDesigner.SearchScreenViewController, null, {
                screenType: this.details.screenType
            }, AnimationType.FADE);
        }

        getGlobalSearchSection() {
            return {
                rows: [{
                    content: {
                        leftIconSrc: Icon.SEARCH,
                        title: _("media.search.placeholder"),
                        clickable: true,
                        class: "search-cell"
                    },
                    action: this.showGlobalSearch.bind(this)
                }]
            };
        }

    };
});
