'use strict';

define(["IRoomControlEnums"], function (IRoomControlEnums) {
    return class TempListValueEditor extends GUI.View {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(ctrl, tempModeNr) {
                var mainContainerHtml,
                    className = "value-editor__main-container ";

                if (tempModeNr === IRoomControlEnums.IRCTempMode.COMFORT_HEATING || tempModeNr === IRoomControlEnums.IRCTempMode.COMFORT_COOLING) {
                    mainContainerHtml = createComfortEditor();
                    className += "main-container--comfort";
                } else if (tempModeNr === IRoomControlEnums.IRCTempMode.ECONOMY || tempModeNr === IRoomControlEnums.IRCTempMode.PARTY || tempModeNr === IRoomControlEnums.IRCTempMode.INCREASED_HEAT) {
                    mainContainerHtml = createRelativeEditor();
                    className += "main-container--relative";
                } else if (tempModeNr === IRoomControlEnums.IRCTempMode.EMPTY_HOUSE || tempModeNr === IRoomControlEnums.IRCTempMode.HEAT_PROTECTION) {
                    mainContainerHtml = createAbsoluteEditor();
                    className += "main-container--absolute";
                }

                return $('<div class="temp-list-value-editor">' + '<div class="editor__placeholder">' + '<div class="value-editor__header">' + ImageBox.getResourceImageWithClasses(ctrl.getIconOfIRCTempMode(tempModeNr), 'header__icon') + '<div class="header__title">' + ctrl.getNameOfIRCTempMode(tempModeNr) + '</div>' + '<div class="shadow-bottom-seperator-line"></div>' + '</div>' + '<div class="' + className + '">' + mainContainerHtml + '</div>' + '<div class="value-editor__footer">' + '<div class="footer__button-toucharea">' + ImageBox.getResourceImageWithClasses('resources/Images/General/success.svg', 'button__icon') + '</div>' + '<div class="footer__button-toucharea">' + ImageBox.getResourceImageWithClasses('resources/Images/General/close.svg', 'button__icon button__icon--right') + '</div>' + '</div>' + '</div>' + '</div>');
            };

            var createComfortEditor = function createComfortEditor() {
                return '' + '<div class="main-container__box cooling">' + createSwitchContainer('switch-container--comfort cooling') + '<div class="description-container cooling">' + '<div class="container__middle-placeholder">' + ImageBox.getResourceImageWithClasses('resources/Images/Controls/IRoom/cooling.svg', 'description__icon') + '<div class="description__text">' + _("controls.irc.cooling") + '</div>' + '</div>' + '</div>' + '</div>' + '<div class="main-container__box heating">' + createSwitchContainer('switch-container--comfort heating') + '<div class="description-container heating">' + '<div class="container__middle-placeholder">' + ImageBox.getResourceImageWithClasses('resources/Images/Controls/IRoom/heat-protection-temperature.svg', 'description__icon') + '<div class="description__text">' + _("controls.irc.heating") + '</div>' + '</div>' + '</div>' + '</div>';
            };

            var createRelativeEditor = function createRelativeEditor() {
                return '' + '<div class="main-container__box">' + createSplitValueLabel('relative__value-label value-label--cooling cooling') + '<div class="description-container cooling">' + '<div class="container__middle-placeholder">' + ImageBox.getResourceImageWithClasses('resources/Images/Controls/IRoom/cooling.svg', 'description__icon') + '<div class="description__text">' + _("controls.irc.cooling") + ' </div>' + '</div>' + '</div>' + createSwitchContainer('switch-container--relative') + createSplitValueLabel('relative__value-label value-label--heating heating') + '<div class="description-container heating">' + '<div class="container__middle-placeholder">' + ImageBox.getResourceImageWithClasses('resources/Images/Controls/IRoom/heat-protection-temperature.svg', 'description__icon') + '<div class="description__text">' + _("controls.irc.heating") + '</div>' + '</div>' + '</div>' + '</div>';
            };

            var createAbsoluteEditor = function createAbsoluteEditor() {
                return '' + '<div class="main-container__box">' + createSwitchContainer('switch-container--absolute') + '</div>';
            };

            var createSwitchContainer = function createSwitchContainer(className) {
                return '<div class="switch-container ' + className + '">' + '<div class="button__toucharea toucharea--left">' + ImageBox.getResourceImageWithClasses('resources/Images/General/button-minus.svg', 'button__icon') + '</div>' + '<div class="value-label__placeholder">' + createSplitValueLabel('placeholder__split-labels') + '</div>' + '<div class="button__toucharea toucharea--right">' + ImageBox.getResourceImageWithClasses('resources/Images/General/button-plus.svg', 'button__icon') + '</div>' + '</div>';
            };

            var createSplitValueLabel = function createSplitValueLabel(className) {
                return '<div class="' + className + '">' + '<span class="label__integer"></span>' + '<span class="label__float"></span>' + '<span class="label__unit"></span>' + '</div>';
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(ctrl, tempModeNr, temperatures, format, showHeating, showCooling) {
            super(TempListValueEditor.Template.getTemplate(ctrl, tempModeNr));
            this.control = ctrl;
            this.tempMode = tempModeNr;
            this.temperatures = temperatures;
            this.format = format;
            this.showHeating = showHeating;
            this.showCooling = showCooling;
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                var tempModeColor = this.control.getColorOfIRCTempMode(this.tempMode);
                this.elements = {
                    editorPlaceholder: this.element.children('.editor__placeholder')
                };
                var footerButtons = this.element.find('.footer__button-toucharea');
                this.buttons = {
                    acknowledgeButton: new GUI.LxButton(this, footerButtons[0], Color.BUTTON_GLOW, null, true),
                    cancelButton: new GUI.LxButton(this, footerButtons[1], Color.BUTTON_GLOW, null, true)
                };
                this.addToHandledSubviews(this.buttons.acknowledgeButton);
                this.addToHandledSubviews(this.buttons.cancelButton);
                this.buttons.acknowledgeButton.useChildsAsActiveParts('fill');
                this.buttons.cancelButton.useChildsAsActiveParts('fill');
                this.element.find('.header__icon').css('fill', tempModeColor);

                if (this.tempMode === IRoomControlEnums.IRCTempMode.COMFORT_HEATING || this.tempMode === IRoomControlEnums.IRCTempMode.COMFORT_COOLING) {
                    var coolingSwitchContainer = $(this.element.find('.value-label__placeholder')[0]);
                    var heatingSwitchContainer = $(this.element.find('.value-label__placeholder')[1]);
                    coolingSwitchContainer.css('background-color', window.Styles.colors.blue);
                    heatingSwitchContainer.css('background-color', window.Styles.colors.red);
                    this.currentCoolingValue = this.temperatures[IRoomControlEnums.IRCTempMode.COMFORT_COOLING];
                    this.currentHeatingValue = this.temperatures[IRoomControlEnums.IRCTempMode.COMFORT_HEATING];
                    this.elements.coolingElements = this.getSplitLabelsOfElem(coolingSwitchContainer.find('.placeholder__split-labels'));
                    this.elements.heatingElements = this.getSplitLabelsOfElem(heatingSwitchContainer.find('.placeholder__split-labels'));
                    this.buttons.coolingMinusButton = new GUI.LxButton(this, coolingSwitchContainer.parent().children('.toucharea--left')[0], Color.BUTTON_GLOW, null, true, 0);
                    this.buttons.coolingPlusButton = new GUI.LxButton(this, coolingSwitchContainer.parent().children('.toucharea--right')[0], Color.BUTTON_GLOW, null, true, 0);
                    this.buttons.coolingMinusButton.useChildsAsActiveParts('fill');
                    this.buttons.coolingPlusButton.useChildsAsActiveParts('fill');
                    this.buttons.heatingMinusButton = new GUI.LxButton(this, heatingSwitchContainer.parent().children('.toucharea--left')[0], Color.BUTTON_GLOW, null, true, 0);
                    this.buttons.heatingPlusButton = new GUI.LxButton(this, heatingSwitchContainer.parent().children('.toucharea--right')[0], Color.BUTTON_GLOW, null, true, 0);
                    this.buttons.heatingMinusButton.useChildsAsActiveParts('fill');
                    this.buttons.heatingPlusButton.useChildsAsActiveParts('fill');
                    this.addToHandledSubviews(this.buttons.coolingMinusButton);
                    this.addToHandledSubviews(this.buttons.coolingPlusButton);
                    this.addToHandledSubviews(this.buttons.heatingMinusButton);
                    this.addToHandledSubviews(this.buttons.heatingPlusButton);
                } else {
                    if (this.tempMode === IRoomControlEnums.IRCTempMode.ECONOMY || this.tempMode === IRoomControlEnums.IRCTempMode.PARTY || this.tempMode === IRoomControlEnums.IRCTempMode.INCREASED_HEAT) {
                        this.currentValue = this.temperatures[this.tempMode].offset;
                        this.currentCoolingValue = this.temperatures[this.tempMode].cooling;
                        this.currentHeatingValue = this.temperatures[this.tempMode].heating;
                        this.elements.coolingElements = this.getSplitLabelsOfElem(this.element.find('.value-label--cooling'));
                        this.elements.heatingElements = this.getSplitLabelsOfElem(this.element.find('.value-label--heating'));
                    } else if (this.tempMode === IRoomControlEnums.IRCTempMode.EMPTY_HOUSE || this.tempMode === IRoomControlEnums.IRCTempMode.HEAT_PROTECTION) {
                        this.currentValue = this.temperatures[this.tempMode];
                    }

                    var valueLabelPlaceholder = this.element.find('.value-label__placeholder');
                    this.elements.valueElements = this.getSplitLabelsOfElem(valueLabelPlaceholder.children('.placeholder__split-labels'));
                    valueLabelPlaceholder.css('background-color', tempModeColor);
                    this.buttons.minusButton = new GUI.LxButton(this, valueLabelPlaceholder.parent().children('.toucharea--left')[0], Color.BUTTON_GLOW, null, true, 0);
                    this.buttons.plusButton = new GUI.LxButton(this, valueLabelPlaceholder.parent().children('.toucharea--right')[0], Color.BUTTON_GLOW, null, true, 0);
                    this.buttons.minusButton.useChildsAsActiveParts('fill');
                    this.buttons.plusButton.useChildsAsActiveParts('fill');
                    this.addToHandledSubviews(this.buttons.minusButton);
                    this.addToHandledSubviews(this.buttons.plusButton);
                }

                this.element.toggleClass('temp-list-value-editor--hd', HD_APP);
                this.element.find(".heating").toggle(this.showHeating);
                this.element.find(".cooling").toggle(this.showCooling);
                this.updateValueLabels(this.currentValue, this.currentHeatingValue, this.currentCoolingValue);
            }.bind(this));
        }

        viewWillAppear() {
            return Q(super.viewWillAppear(...arguments) || true).then(function () {
                var height = this.elements.editorPlaceholder[0].clientHeight / 2;
                var percent;

                if (this.tempMode === IRoomControlEnums.IRCTempMode.COMFORT_HEATING || this.tempMode === IRoomControlEnums.IRCTempMode.COMFORT_COOLING) {
                    percent = 50;
                } else {
                    percent = 66;
                }

                this.elements.editorPlaceholder.css('top', 'calc(' + percent + '% - ' + height + 'px)');
            }.bind(this));
        }

        viewDidAppear() {
            return Q(super.viewDidAppear(...arguments) || true).then(function () {
                if (this.tempMode === IRoomControlEnums.IRCTempMode.COMFORT_HEATING || this.tempMode === IRoomControlEnums.IRCTempMode.COMFORT_COOLING) {
                    this.buttons.coolingMinusButton.onButtonTapped = this.handleValueButton.bind(this);
                    this.buttons.coolingPlusButton.onButtonTapped = this.handleValueButton.bind(this);
                    this.buttons.coolingMinusButton.onButtonTicked = this.handleValueButton.bind(this);
                    this.buttons.coolingPlusButton.onButtonTicked = this.handleValueButton.bind(this);
                    this.buttons.heatingMinusButton.onButtonTapped = this.handleValueButton.bind(this);
                    this.buttons.heatingPlusButton.onButtonTapped = this.handleValueButton.bind(this);
                    this.buttons.heatingMinusButton.onButtonTicked = this.handleValueButton.bind(this);
                    this.buttons.heatingPlusButton.onButtonTicked = this.handleValueButton.bind(this);
                } else {
                    this.buttons.minusButton.onButtonTapped = this.handleValueButton.bind(this);
                    this.buttons.plusButton.onButtonTapped = this.handleValueButton.bind(this);
                    this.buttons.minusButton.onButtonTicked = this.handleValueButton.bind(this);
                    this.buttons.plusButton.onButtonTicked = this.handleValueButton.bind(this);
                }

                this.buttons.acknowledgeButton.onButtonTapped = this.handleFooterButtonTap.bind(this);
                this.buttons.cancelButton.onButtonTapped = this.handleFooterButtonTap.bind(this);
            }.bind(this));
        }

        viewDidDisappear() {
            var buttonKeys = Object.keys(this.buttons);

            for (var i = 0; i < buttonKeys.length; i++) {
                var btnKey = buttonKeys[i];
                var button = this.buttons[btnKey];
                button.onButtonTapped = null;
                button.onButtonTicked = null;
            }

            super.viewDidDisappear(...arguments);
        }

        handleValueButton(button) {
            if (button === this.buttons.minusButton) {
                this.currentValue -= 0.5;

                if (this.tempMode === IRoomControlEnums.IRCTempMode.ECONOMY) {
                    this.currentCoolingValue -= 0.5;
                    this.currentHeatingValue += 0.5;
                } else if (this.tempMode === IRoomControlEnums.IRCTempMode.PARTY) {
                    this.currentCoolingValue += 0.5;
                    this.currentHeatingValue += 0.5;
                } else if (this.tempMode === IRoomControlEnums.IRCTempMode.INCREASED_HEAT) {
                    this.currentCoolingValue -= 0.5;
                    this.currentHeatingValue -= 0.5;
                }
            } else if (button === this.buttons.plusButton) {
                this.currentValue += 0.5;

                if (this.tempMode === IRoomControlEnums.IRCTempMode.ECONOMY) {
                    this.currentCoolingValue += 0.5;
                    this.currentHeatingValue -= 0.5;
                } else if (this.tempMode === IRoomControlEnums.IRCTempMode.PARTY) {
                    this.currentCoolingValue -= 0.5;
                    this.currentHeatingValue -= 0.5;
                } else if (this.tempMode === IRoomControlEnums.IRCTempMode.INCREASED_HEAT) {
                    this.currentCoolingValue += 0.5;
                    this.currentHeatingValue += 0.5;
                }
            }

            if (button === this.buttons.coolingMinusButton) {
                this.currentCoolingValue -= 0.5;
            } else if (button === this.buttons.coolingPlusButton) {
                this.currentCoolingValue += 0.5;
            } else if (button === this.buttons.heatingMinusButton) {
                this.currentHeatingValue -= 0.5;
            } else if (button === this.buttons.heatingPlusButton) {
                this.currentHeatingValue += 0.5;
            }

            this.updateValueLabels(this.currentValue, this.currentHeatingValue, this.currentCoolingValue);
        }

        handleFooterButtonTap(button) {
            if (button === this.buttons.acknowledgeButton) {
                if (this.tempMode === IRoomControlEnums.IRCTempMode.COMFORT_HEATING || this.tempMode === IRoomControlEnums.IRCTempMode.COMFORT_COOLING) {
                    this.closeWindowWithValue(this.tempMode, this.currentHeatingValue, this.currentCoolingValue);
                } else {
                    this.closeWindowWithValue(this.tempMode, this.currentValue);
                }
            } else if (button === this.buttons.cancelButton) {
                this.closeWindowWithValue(false);
            }
        }

        updateValueLabels(value, heatingValue, coolingValue) {
            if (!isNaN(value)) {
                this.setSplitLabelValues(this.elements.valueElements, getDetailValuesFromTemperatureFormat(lxFormat(this.format, value)));
            }

            if (!isNaN(heatingValue)) {
                this.setSplitLabelValues(this.elements.heatingElements, getDetailValuesFromTemperatureFormat(lxFormat(this.format, heatingValue)));
            }

            if (!isNaN(coolingValue)) {
                this.setSplitLabelValues(this.elements.coolingElements, getDetailValuesFromTemperatureFormat(lxFormat(this.format, coolingValue)));
            }
        }

        getSplitLabelsOfElem(element) {
            var intLabel = element.children('.label__integer');
            var floatLabel = element.children('.label__float');
            var unitLabel = element.children('.label__unit');
            return {
                intLabel: intLabel,
                floatLabel: floatLabel,
                unitLabel: unitLabel
            };
        }

        setSplitLabelValues(parent, values) {
            setDetailValuesFromTemperatureToLabels(values, parent.intLabel, parent.floatLabel, parent.unitLabel);
        }

    };
});
