/**
 * Helper to acquire data from our AWS server (files or their version info using a head request)
 */
class ServerFileLoader {

    //region static methods

    /**
     * One-shot for downloading a file from aws plus it's version info.
     * Use an instance of serverFileAccess to avoid
     * @param fileRequest info on the file to request
     * @returns {*|Q.Promise<void>|Q.Promise<*>}
     */
    static getFile(fileRequest) {
        return new ServerFileLoader(fileRequest).getFile();
    }

    //endregion


    /**
     * used to download data from aws, will acquire files and their respective version.
     * Will ensure to avoid unnecessary redownloads of files that have been fetched shortly before.
     * @param fileRequest:FileRequest object describing the file to acquire.
     */
    constructor(fileRequest) {
        this._fileRequest = fileRequest
    }

    /**
     * Fetches the current version on the server using a head-request
     * @returns {*|Q.Promise<void>|Q.Promise<unknown>}
     */
    getVersion() {
        // when downloading a file, don't launch another request to request the verion, the file request will
        // respond with a version anyway.
        if (this._getFilePromise) {
            return this._getFilePromise.then(result => {
                return result.version;
            });
        } else if (this._getVersionPromise) {
            return this._getVersionPromise;
        }

        if (this._fileRequest.versionDownloadFn) {
            this._getVersionPromise = this._fileRequest.versionDownloadFn(this._fileRequest);
        } else {
            this._getVersionPromise = this._ajaxVersionDownload();
        }

        return this._getVersionPromise.finally(() => {
            this._getVersionPromise = null;
        });
    }

    /**
     * Downloads from the server, resolves with an object containing both the version and the data loaded.
     * @returns {*|Q.Promise<void>|Q.Promise<unknown>}
     */
    getFile() {
        // prevent multiple getFile requests while one is pending.
        if (this._getFilePromise) {
            return this._getFilePromise;
        }

        if (this._fileRequest.fileDownloadFn) {
            this._getFilePromise = this._fileRequest.fileDownloadFn(this._fileRequest);
        } else {
            this._getFilePromise = this._ajaxFileDownload();
        }

        return this._getFilePromise.finally(() => {
            this._getFilePromise = null;
        })
    }

    // region ajax downloads

    _ajaxFileDownload() {
        let file;
        return Q($.get(this._fileRequest.url).then((data, text, response) => {
            // beware: the data is received in different types, string or parsed (eg. macOS other than Android)
            if (typeof data === "string" && this._fileRequest.dataType === DataType.OBJECT) {
                file = JSON.parse(data);
            } else {
                file = data;
            }
            this._fileRequest.data = file;
            this._fileRequest.version = this._extractVersion(response);
            return this._fileRequest;
        }));
    }

    _ajaxVersionDownload() {
        return Q($.ajax({
            type: "HEAD",
            url: this._fileRequest.url,
            async: true
        }).then((message, text, response) => {
            return this._extractVersion(response);
        }));
    }

    _extractVersion(response) {
        const modifiedTs = response.getResponseHeader("last-modified");
        const contentLength = response.getResponseHeader("content-length");
        return modifiedTs || contentLength
    }

    // endregion
}

export default ServerFileLoader;