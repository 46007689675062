'use strict';

define([], function () {
    return {
        ScreenState: {
            TIMER_SCREEN: "TimerScreen"
        },
        Modes: {
            AUTOMATIC: 1,
            HEAT: 2,
            COOL: 3,
            DRY: 4,
            FAN: 5,
        },
        ConnectedInputsBitmask: {
            targetTemperature: 8,
            currentTemperature: 16,
            mode: 32,
            fan: 64,
            ventMode: 128,
            windowContact: 256,
            sleepTimer: 512,
            loadShedding: 1024,
            silentMode: 4096,
        },
        ConnectedParametersBitmask: {
            externallyControlled: 2,
        },
        PauseReasonsBitmask: {
            none: 0,
            sleep: 1,
            windowOpen: 2,
            loadShedding: 4,
            pause_hvac_heat_prohibited: 16,
            pause_hvac_cool_prohibited: 32,
            pauseDiffmode: 64
        },
    }
});
