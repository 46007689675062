import { useRef } from "react";
// Docs: https://jeremybarbet.github.io/react-nyarn add react-native-portalizeative-modalize/#/
import { Modalize } from 'react-native-modalize/lib/index.js';
import { Dimensions } from "react-native";
import { useHeaderHeight } from '@react-navigation/elements';
import { Portal } from 'react-native-portalize';
import LxReactText from "../LxReactText"

const useBottomSheet = ({
    useMinHeight = true
} = {}) => {
    const bottomRef = useRef(null),
        headerHeight = useHeaderHeight(),
        minHeight = (Dimensions.get("window").height - headerHeight) * 25 / 100;

    const renderContent = () => {
        return <LxReactText style={{
            color: "red"
        }}>Add some content here!</LxReactText>
    }

    const BottomSheet = props => {
        const {
            children = renderContent()
        } = props;

        return (
            <Portal>
                <Modalize
                    ref={bottomRef}
                    adjustToContentHeight={true}
                    modalStyle={{
                        backgroundColor: "#212529"
                    }}
                    {...props}
                    children={children}
                    childrenStyle={{
                        minHeight: useMinHeight ? minHeight : 0
                    }}
                />
            </Portal>
        )
    }

    BottomSheet.propTypes = Modalize.propTypes;

    return [
        BottomSheet,
        () => {
            return bottomRef.current.open();
        },
        () => {
            return bottomRef.current.close();
        }
    ]
}

export default useBottomSheet
