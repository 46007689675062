import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Clamp(props) {
    const internalProps = {...props};
    internalProps.style = internalProps.style || {};
    internalProps.style.fill = "none";
    const clampColor = props.color || "#69C350";
    return (
        <Svg isreact="true"
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            { ...internalProps}
        >
            <Path fill={clampColor} d="M0 0.5H12V24.5H0z" />
            <Path fill="#fff" d="M12 0.5H24V24.5H12z" />
            <Path stroke="#000" d="M0.5 1H23.5V24H0.5z" />
            <Path fill="#000" d="M11.5 0.5H12.5V24.5H11.5z" />
        </Svg>
    )
}

export default Clamp
