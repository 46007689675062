import {useSystemStatusInfo} from "LxComponents";
import Icons from "IconLib"
import globalStyles from "GlobalStyles";
import PropTypes from "prop-types";
import {View} from "react-native";

function AmbientHealthIcon(props) {
    const statusInfo = useSystemStatusInfo();

    if (props.ecoMode) {
        return null
    } else {
        return <View style={globalStyles.customStyles.ambientHeaderIconContainer}>
            <Icons.HeartWithBeat fill={statusInfo.color || globalStyles.colors.text.primary}/>
        </View>
    }
}

AmbientHealthIcon.propTypes = {
    ecoMode: PropTypes.bool
}

export default AmbientHealthIcon
