'use strict';

define([], function () {
    return class LoxoneBrandingScreen extends GUI.Screen {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return $('<div class="loxone-branding-screen">' + '<div class="loxone-branding-screen__bg-placeholder">' + ImageBox.getResourceImageWithClasses(Icon.LOXONE_SLOGAN, "bg-placeholder__smart-home-logo") + '<div class="bg-placeholder__social-media">' + createSocialMediaButton('facebook', 'resources/Images/ActiveMiniserver/Menu/LoxoneBranding/facebook.svg') + createSocialMediaButton('twitter', 'resources/Images/ActiveMiniserver/Menu/LoxoneBranding/twitter.svg') + createSocialMediaButton('youtube', 'resources/Images/ActiveMiniserver/Menu/LoxoneBranding/youtube.svg') + createSocialMediaButton('blog', 'resources/Images/ActiveMiniserver/Menu/LoxoneBranding/blog.svg') + '</div>' + '<div class="bg-placeholder__loxone-link clickable">' + _("link.loxone.homepage-friendly") + '</div>' + '</div>' + '</div>');
            };

            var createSocialMediaButton = function createSocialMediaButton(name, image) {
                return '<div class="social-media__toucharea social-media--' + name + '">' + ImageBox.getResourceImageWithClasses(image, 'toucharea__icon clickable') + '</div>';
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(details) {
            super(LoxoneBrandingScreen.Template.getTemplate());
        }

        titleBarText() {
            return _("loxone.about");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.buttons = {
                websiteBtn: new GUI.LxButton(this, this.element.find('.bg-placeholder__loxone-link')[0], Color.BUTTON_GLOW, null, true),
                facebookBtn: new GUI.LxButton(this, this.element.find('.social-media--facebook')[0], Color.BUTTON_GLOW, null, true),
                twitterBtn: new GUI.LxButton(this, this.element.find('.social-media--twitter')[0], Color.BUTTON_GLOW, null, true),
                youtubeBtn: new GUI.LxButton(this, this.element.find('.social-media--youtube')[0], Color.BUTTON_GLOW, null, true),
                blogBtn: new GUI.LxButton(this, this.element.find('.social-media--blog')[0], Color.BUTTON_GLOW, null, true)
            };
            this.buttons.websiteBtn.useChildsAsActiveParts('fill');
            this.buttons.facebookBtn.useChildsAsActiveParts('fill');
            this.buttons.twitterBtn.useChildsAsActiveParts('fill');
            this.buttons.youtubeBtn.useChildsAsActiveParts('fill');
            this.buttons.blogBtn.useChildsAsActiveParts('fill');
            this.addToHandledSubviews(this.buttons.websiteBtn);
            this.addToHandledSubviews(this.buttons.facebookBtn);
            this.addToHandledSubviews(this.buttons.twitterBtn);
            this.addToHandledSubviews(this.buttons.youtubeBtn);
            this.addToHandledSubviews(this.buttons.blogBtn);
        }

        viewDidAppear() {
            super.viewDidAppear();
            this.buttons.websiteBtn.onButtonReleased = this._handleButtonReleased.bind(this);
            this.buttons.facebookBtn.onButtonReleased = this._handleButtonReleased.bind(this);
            this.buttons.twitterBtn.onButtonReleased = this._handleButtonReleased.bind(this);
            this.buttons.youtubeBtn.onButtonReleased = this._handleButtonReleased.bind(this);
            this.buttons.blogBtn.onButtonReleased = this._handleButtonReleased.bind(this);
        }

        viewDidDisappear() {
            this.buttons.websiteBtn.onButtonReleased = null;
            this.buttons.facebookBtn.onButtonReleased = null;
            this.buttons.twitterBtn.onButtonReleased = null;
            this.buttons.youtubeBtn.onButtonReleased = null;
            this.buttons.blogBtn.onButtonReleased = null;
            super.viewDidDisappear();
        }

        destroy() {
            this.elements = null;
            super.destroy();
        }

        // Private methods
        _handleButtonReleased(button) {
            var link = _('link.loxone.homepage');

            if (button === this.buttons.facebookBtn) {
                link = _('link.loxone.facebook');
            } else if (button === this.buttons.twitterBtn) {
                link = _('link.loxone.twitter');
            } else if (button === this.buttons.youtubeBtn) {
                link = _('link.loxone.youtube');
            } else if (button === this.buttons.blogBtn) {
                link = _('link.loxone.blog');
            }

            NavigationComp.openWebsite(link);
        }

    };
});
