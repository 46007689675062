import CardScreen from "../../ViewController/cardScreen/cardScreen.jsx";
import React, {useCallback, useEffect, useRef, useState} from "react";
import Icons from "IconLib";
import {
    useSetURLHook,
    LxReactWaitingView
} from "LxComponents";

function FavoritesScreenV2({navigation, route}) {

    useSetURLHook({
        urlPart: UrlStartLocation.FAVORITES
    });

    const headerTitle = _('favorites.title');

    const childRef = useRef(null);
    const [hasActiveMs, setHasActiveMs] = useState(false);

    useEffect(() => {
        ActiveMSComponent.comReadyPrms.depActiveMsDone(() => {
            ActiveMSComponent.getSortingReadyPromise().then(() => {
                setHasActiveMs(true);
            });
        })

        const deRegStopMSSession = CompChannel.on(CCEvent.StopMSSession, () => {
            setHasActiveMs(false);
        });

        return () => {
            CompChannel.off(deRegStopMSSession);
        };
    }, [])

    const _getContent = useCallback(() => {
        let activeMs = ActiveMSComponent.getActiveMiniserver();
        if (!activeMs || !activeMs.activeUser) {
            return [];
        }
        let tableContent = [],
            forceCellUI = !NavigationComp.tileRepresentationEnabled(),
            favControls = ActiveMSComponent.getStructureManager().getFavoriteControls(),
            ctrlSection = {
                rows: []
            };

        if (ActiveMSComponent.getCurrentSortingMode() === Sorting.STANDARD_SORTING) {
            ctrlSection.headerMessage = _("favorites.sorted-by-rating");
        }

        favControls.forEach((ctrl, idx) => {
            let hideActionElements = ctrl.isGrouped() && ctrl.isInCentralRoom();
            ctrlSection.rows.push({
                type: ctrl.getCellType(),
                content: {
                    control: cloneObjectDeep(ctrl),
                    displayAsCell: forceCellUI,
                    displayRoomName: false,
                    showGroupDetails: true,
                    hideActionElements: hideActionElements
                }
            });
        })

        if (ctrlSection.rows.length > 0) {
            tableContent.push(ctrlSection);
        }

        return tableContent;
    }, [])

    const getContextMenuOptions =  useCallback(() => {
        let contextMenuOptions = [];

        if (childRef.current) {
            contextMenuOptions.pushObject(childRef.current.getSortingOption());
        }

        contextMenuOptions.push({
            title: _('help'),
            action: () => {
                NavigationComp.showPopup({
                    title: _('help'),
                    message: _('favs.empty.message'),
                    buttonOk: true,
                    icon: Icon.INFO
                });
            }
        });

        return contextMenuOptions;
    }, [])

    const _getFavForIndex = useCallback((section, row) => {
        return true;
    }, [])

    const _getSortingLocation = useCallback((section) => {
        return UrlStartLocation.FAVORITES;
    }, [])

    const getSortingContextMenuOptions= useCallback((cell) => {
        var defaultContent = childRef.current.getSortingContextMenuOptions(cell),
            contextMenuContent = [];

        contextMenuContent.pushObject(_createRemoveControlFavoriteTab(cell.content.control));

        contextMenuContent = contextMenuContent.concat(defaultContent);

        return contextMenuContent;
    }, [])

    const _createRemoveControlFavoriteTab = (control) => {
        if (ActiveMSComponent.isExpertModeLightEnabled() && !NavigationComp.getCurrentActivityType()) {
            return {
                title: _("context-menu.remove-favorite"),
                action: () => {
                    childRef.current.removeAsFavorite(control, UrlStartLocation.FAVORITES)
                }
            }
        } else {
            return null;
        }
    }

    const getEmptyScreenConfig = () => {
        var obj = {
            iconSrc: Icons.Star,
            title: _('favs.empty.title'),
            subtitle: _('favs.empty.subtitle'),
            message: _('favs.empty.message')
        }
        if (!Feature.EXPERT_MODE_LIGHT) {
            obj.title = _('house.update.title');
            obj.subtitle = _('house.update.subtitle');
            obj.message = _('house.update.message');
            obj.significantMsg = _('house.update.significant-message');
            obj.button = {
                title: _('update.more-info'),
                link: PLEASE_UPDATE_LINK
            };
        }
        return obj;
    }
    const configRef = useRef(null);

    if (hasActiveMs) {
        if (!configRef.current) {
            configRef.current = {
                emptyScreen: getEmptyScreenConfig(),
                initialContent : _getContent()
            }
        }

        return <CardScreen
            ref={childRef}
            tableContentFromParent={configRef.current.initialContent}
            getTableContent={_getContent}
            emptyScreenConfig={configRef.current.emptyScreen}
            title={headerTitle}
            contextMenuOptions={getContextMenuOptions}
            getFavForIndex={_getFavForIndex}
            getSortingLocation={_getSortingLocation}
            getSortingContextMenuOptions={getSortingContextMenuOptions}
        />
    } else {
        return <LxReactWaitingView />
    }
}

export default FavoritesScreenV2;
