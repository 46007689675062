'use strict';

define(["ControlActionCellsScreen", "DaytimerControlInfoView", "DaytimerControlEnums", "DaytimerControlContentTimer", "DaytimerControlContentDefaultValue", "DaytimerControlContentCalendar", "DaytimerControlContentCalendarHD"], function (ControlActionCellsScreen, DaytimerControlInfoView, DaytimerControlEnums) {
    return class DaytimerControlContent extends ControlActionCellsScreen {
        constructor() {
            super(...arguments);
        }

        receivedStates(states) {
            super.receivedStates(...arguments);
            let timerActive = states.daytimerState === DaytimerControlEnums.DaytimerState.TIMER;
            this.toggleCustomInfoView(!timerActive && !states.resetActive && this.customContentVisible);
        }

        /**
         * This method can be overwritten, if a view is returned it will be shown inside the customInfoBox!
         * @return {null}
         */
        getCustomInfoView() {
            return new DaytimerControlInfoView(this.control);
        }

        /*getActionSection(states) {
            var section = super.getActionSection(...arguments) || {
                rows: []
            };

            if (states.daytimerState !== DaytimerControlEnums.DaytimerState.TIMER && !states.resetActive) {
                section.rows.push({
                    content: {
                        title: _("controls.daytimer.timer.start")
                    },
                    action: this.showScreen.bind(this, DaytimerControlEnums.ScreenState.TIMER),
                    type: GUI.TableViewV2.CellType.Special.COMFORT_ACTION
                });
            }

            return section;
        }*/

        /**
         * returns the "state" section (usually below the action section).
         * @param states
         * @return {null|object}
         */
        /*getStateRows(states) {
            var rows = []; // add the daytimer schedule entries cell

            rows.push({
                content: {
                    title: _("controls.daytimer.entries"),
                    disclosureIcon: true
                },
                type: GUI.TableViewV2.CellType.GENERAL,
                action: this.showScreen.bind(this, DaytimerControlEnums.ScreenState.CALENDAR)
            }); // if analog, add the default value cell

            if (this.control.details.analog) {
                rows.push({
                    content: {
                        title: _("controls.daytimer.default-value"),
                        disclosureText: lxFormat(this.control.details.format, states.defaultValue),
                        disclosureIcon: true
                    },
                    type: GUI.TableViewV2.CellType.GENERAL,
                    action: this.showScreen.bind(this, DaytimerControlEnums.ScreenState.DEFAULT_VALUE)
                });
            }

            return rows;
        } */

    };
});
