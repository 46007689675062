'use strict';

define([], function () {
    return class IntercomControlContentMissedView extends Controls.ControlContent {
        //region Static
        static Template = function () {
            var getStateView = function getStateView(ctrl) {
                return $('<div class="intercom-control-content-missed-view__state-view">' + '</div>');
            };

            return {
                getStateView: getStateView
            };
        }(); //endregion Static

        constructor(details) {
            Debug.Control.Intercom.General && console.log("IntercomControlContentMissedView ctor");
            super(details);
            this.tsObject = details.tsObject;
            this.tsObjects = details.tsObjects;
            this.imageIndex = this.tsObjects.indexOf(this.tsObject);
            var imageViews = [];

            for (var i = 0; i <= this.tsObjects.length - 1; i++) {
                imageViews.push(IntercomControlContentMissedView.Template.getStateView(this.control));
                ImageBox.getCamImage(this.control.uuidAction, this.tsObjects[i].timestamp).then(function (image) {
                    var img;

                    if (image.mimeType === "jpg") {
                        img = '<img class="state-view__icon" src="' + image.image + '">';
                    } else {
                        img = ImageBox.getResourceImageWithClasses(Icon.Intercom.MissedList.ERROR, "content__no-image");
                    } // The download might finish once this view is no longer here.


                    if (imageViews && imageViews[i]) {
                        imageViews[i].append(img);
                    }
                });
            }

            this.pageView = new GUI.LxPageView(imageViews, this._progressCallback.bind(this), this._doneAnimating.bind(this), {
                overscroll: true
            });
            this.pageView.disableUserInteraction(true);
        }

        viewDidLoad() {
            return Q.resolve(super.viewDidLoad(...arguments)).then(() => {
                this.elements = {};
                this.elements.stateView = IntercomControlContentMissedView.Template.getStateView(this.control);
                this.appendSubview(this.pageView);
                this.pageView.setActivePage(this.imageIndex);
            });
        }

        getTitlebar() {
            var titleBarConfig = {
                leftSide: TitleBarCfg.Button.CLOSE,
                style: 'transparent',
                subTitleBar: 'detailIconAndText'
            };
            var titleBar = new GUI.LxTitleBar(titleBarConfig);
            titleBar.setTitleBarSideTexts(this.control.name);
            titleBar.setDetailIconAndText(Icon.Intercom.MissedList.CLOCK, this.tsObject.dateAndTimeString);
            return titleBar;
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);

            this.titleBar.onLeftButtonTapped = function () {
                this.ViewController.navigateBack();
                this.titleBar.onLeftButtonTapped = null; // workaround for twice calls
            }.bind(this);
        }

        viewDidDisappear(viewRemainsVisible) {
            Debug.Control.Intercom.General && console.log("IntercomControlContentMissedView viewDidDisappear");
            this.titleBar.onLeftButtonTapped = null;
            super.viewDidDisappear(viewRemainsVisible);
        }

        getURL() {
            return "MissedImage";
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        // Event listener
        _doneAnimating(pageIndex) {
            this.titleBar.setDetailIconAndText(Icon.Intercom.MissedList.CLOCK, this.tsObjects[pageIndex].dateAndTimeString);
        }

        _progressCallback(percentage) {
        }

    };
});
