/**
 * Created by loxone on 20.06.16.
 */
'use strict';

define(['AddMediaViewControllerBase'], function (AddMediaViewControllerBase) {
    class AddMediaViewControllerPlaylist extends AddMediaViewControllerBase {
        constructor(details) {
            super(...arguments);
            this.delegate = details.delegate;
            this.playlistId = details.playlistId;
            this.contentType = MediaEnum.MediaContentType.PLAYLISTS;
        }

        destroy() {
            this._endEditMode();

            super.destroy(...arguments);
        }

        addItem(item, mediaInfo) {
            return MediaServerComp.addItem(item);
        }

        startEditMode() {
            return MediaServerComp.startEditing(this.contentType, this.delegate.getMediaInfo(this.contentType), this.playlistId, true).then(this._editModeEnded.bind(this), this._editModeError.bind(this), this._editModeStarted.bind(this));
        }

        _endEditMode() {
            MediaServerComp.stopEditing(this.playlistId);
        }

        _editModeEnded(cause) {
            Debug.Control.AudioZone.MediaEdit && console.log(this.name + ": _handleEditModeEnded: " + cause);

            if (cause === MediaEnum.EditEndCause.STOPPED) {
                this._updateEditMode(false);
            } else {
                this._handleEditModeError({
                    cause: cause
                });
            }
        }

        _editModeError(err) {
            console.error("Error with Edit Mode: error Object: " + JSON.stringify(err));

            var successFn = null,
                title = _("error"),
                message = !err ? "" : err[MediaEnum.Attr.Container.REASON],
                okayTitle = true,
                content;

            if (err && err.cause) {
                switch (err.cause) {
                    case MediaEnum.EditEndCause.MODIFIED:
                        title = _("media.edit.modified.title");
                        message = _("media.edit.modified.message", {
                            name: MediaServerComp.getNameForItem(this.delegate.lastSelectedItem)
                        });
                        successFn = this._updateEditMode.bind(this, true);
                        okayTitle = _("edit");
                        break;

                    case MediaEnum.EditEndCause.CONFLICT:
                        title = _("media.edit.conflict.title");
                        message = _("media.edit.conflict.message", {
                            name: MediaServerComp.getNameForItem(this.delegate.lastSelectedItem)
                        });
                        break;

                    default:
                        break;
                }

                content = {
                    title: title,
                    message: message,
                    buttonOk: okayTitle,
                    buttonCancel: okayTitle !== true,
                    icon: Icon.INFO
                };
                NavigationComp.showPopup(content).then(successFn);
            } else {
                console.error("Edit mode did fail but no error was provided! Don't respond");
            }
        }

        _editModeStarted() {
            MediaServerComp.prepareForAdding().finally(this.dismiss.bind(this));
        }

        _updateEditMode(editActive) {
            if (!editActive) {
                this.dismiss();
            }
        }

    }

    GUI.AddMediaViewControllerPlaylist = AddMediaViewControllerPlaylist;
    return GUI.AddMediaViewControllerPlaylist;
});
