'use strict';

define(['AddMediaViewControllerPlaylist'], function (AddMediaViewControllerPlaylist) {
    class AddMediaViewControllerPlaylistPicker extends AddMediaViewControllerPlaylist {
        constructor(details) {
            super(...arguments);
            this.itemToAdd = this.details.itemToAdd;
            this.contentType = this.itemToAdd.contentType;
        }

        _startEditMode() {// Just do nothing, prevent the default behaviour
        }

        addItem(playlist) {
            this.playlistId = playlist.id;
            MediaServerComp.startEditing(playlist.contentType, this.currentView.getMediaInfo(playlist.contentType), this.playlistId, true).done(this._editModeEnded.bind(this), this._editModeError.bind(this), this._editModeStarted.bind(this));
        }

        _editModeStarted() {
            MediaServerComp.addItem(this.itemToAdd);
            this.dismiss();
        }

    }

    GUI.AddMediaViewControllerPlaylistPicker = AddMediaViewControllerPlaylistPicker;
    return GUI.AddMediaViewControllerPlaylistPicker;
});
