'use strict';

define([], function () {
    return {
        Type: {
            KEBA: 0,
            BMW: 1
        },
        Status: {
            OFFLINE: 0,
            INITIALIZING: 1,
            ONLINE: 2
        },
        LimitMode: {
            OFF: 0,
            MANUAL: 1,
            AUTO: 2
        },
        ScreenState: {
            SETTINGS: "CarChargerControlContentSettings"
        }
    };
});
