import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Pause(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M6.5 2a.5.5 0 00-.5.5v19a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-19a.5.5 0 00-.5-.5h-3zM14.5 2a.5.5 0 00-.5.5v19a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-19a.5.5 0 00-.5-.5h-3z"
            />
        </Svg>
    )
}

export default Pause
