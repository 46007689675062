import React, { useRef, useMemo } from "react";
import { View, TouchableOpacity } from 'react-native';
import { LxReactText, useHD, LxReactPressable, useNopeAreaForRef } from "LxComponents";
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Icons from "IconLib";
import globalStyles from "GlobalStyles";
import useAmbientSettings from "../AmbientMode/hooks/useAmbientSettings";

function LxTabBar({ state, descriptors, navigation, isAmbientMode = false}) {
    const ref = useRef(null),
        insets = useSafeAreaInsets(),
        isHD = useHD();

    useNopeAreaForRef(ref);
    const ambientSettings = useAmbientSettings();


    const _handleEntryPointClicked = () => {
        NavigationComp.showEntryPointLocation();
    }

    const _handleAmbientOpenClicked = () => {
        NavigationComp.showAmbientMode();
    }

    const _showEntryPointMenu = () => {
        NavigationComp.showState(ScreenState.EntryPointLocationSelection);
    }

    const ambientTimeout = useMemo(() => {
        return ambientSettings ? ambientSettings.timeout : 0;
    }, [ambientSettings])

    const EntryPointIcon = () => {
        // if the ambient mode is configured to start by default, the default location pin is to be
        // replaced with an ambient-mode button!
        const containerStyle = [
            styles.entryPoint.container,
            { marginBottom: `calc(${insets.bottom}px + var(--instruction-bar-height, 0px)` }
        ]
        if (ambientTimeout > 0) {
            return (
                <LxReactPressable
                    onPress={_handleAmbientOpenClicked}
                    activeOpacity={0.6}
                    style={containerStyle}>
                    <Icons.AmbientModeFilled style={styles.entryPoint.icon}/>
                </LxReactPressable>
            )
        } else {
            return (
                <LxReactPressable
                    onPress={_handleEntryPointClicked}
                    onDoublePress={_showEntryPointMenu}
                    onLongPress={_showEntryPointMenu}
                    activeOpacity={0.6}
                    style={containerStyle}>
                    <Icons.PinWithCrossFilled style={styles.entryPoint.icon}/>
                </LxReactPressable>
            )
        }
    }

    return (
        <View style={[styles.container, isAmbientMode && styles.transparentContainer]}>
            <View ref={ref} style={{
                flexDirection: 'row',
                flex: 1,
                paddingLeft: HD_APP ? 24 : insets.left,
                paddingRight: insets.right,
                marginBottom: `calc(${isAmbientMode ? 0 : insets.bottom}px + var(--instruction-bar-height, 0px)`,
                justifyContent: "space-evenly",
                height: HD_APP ? 50 : "auto"
            }}>
                {state.routes.map((route, index) => {
                    const { options } = descriptors[route.key];
                    const label =
                        options.tabBarLabel !== undefined
                            ? options.tabBarLabel
                            : options.title !== undefined
                                ? options.title
                                : route.name;
                    const iconComp = options.icon || Icons.Default;

                    const isFocused = state.index === index;

                    const onPress = () => {
                        const event = navigation.emit({
                                type: 'tabPress',
                                target: route.key,
                                canPreventDefault: true,
                            }),
                            state = navigation.getState();

                        if (event.defaultPrevented) {
                            return;
                        }

                        if (isFocused) {
                            let routeIdx = state.routes.findIndex(r => r.name === route.name);

                            state.routes[routeIdx].params = {};
                            navigation.reset(state);
                        } else {
                            // The `merge: true` option makes sure that the params inside the tab screen are preserved
                            navigation.navigate({ name: route.name, merge: true });
                        }
                    };

                    const onLongPress = () => {
                        navigation.emit({
                            type: 'tabLongPress',
                            target: route.key,
                        });
                    };

                    const renderIcon = () => {
                        if (iconComp) {
                            return React.createElement(iconComp, {
                                fill: isFocused ? options.tabBarActiveTintColor : options.tabBarInactiveTintColor,
                                ...styles.icon
                            })
                        }
                    }

                    return (
                        <TouchableOpacity
                            accessibilityRole="button"
                            accessibilityState={isFocused ? { selected: true } : {}}
                            accessibilityLabel={options.tabBarAccessibilityLabel}
                            testID={options.tabBarTestID}
                            onPress={onPress}
                            onLongPress={onLongPress}
                            key={route.key}
                            style={[styles.iconContainer.base, isHD && !isAmbientMode ? styles.iconContainer.hd : styles.iconContainer.sd]}
                        >
                            <>
                                {renderIcon()}
                                <LxReactText style={{
                                    ...styles.iconText,
                                    color: isFocused ? options.tabBarActiveTintColor : options.tabBarInactiveTintColor,
                                }} numberOfLines={1}>
                                    {label}
                                </LxReactText>
                            </>
                        </TouchableOpacity>
                    );
                })}
            </View>
            {isHD && !isAmbientMode && <EntryPointIcon />}
        </View>

    );
}

const styles = {
    container: {
        flexDirection: 'row',
        width: "100%",
        borderTopColor: globalStyles.colors.borderColor.default,
        borderTopWidth: 1,
        borderRadius: 0,
        borderStyle: "solid",
        paddingVertical: 5,
        backgroundColor: globalStyles.colors.grey["700"]
    },
    transparentContainer: {
        backgroundColor: globalStyles.colors.transparent,
        borderTopColor: globalStyles.colors.borderColor.ambient
    },
    iconContainer: {
        base: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: globalStyles.spacings.gaps.smallest
        },
        hd: {
            flexDirection: "row",
            gap: globalStyles.spacings.gaps.small
        },
        sd: {
            flexDirection: "column",
            flex: 1,
            gap: globalStyles.spacings.gaps.smallest
        }
    },
    iconText: {
        ...globalStyles.textStyles.tabBarLabel,
        flex: 1
    },
    icon: {
        height: globalStyles.sizes.icons.small,
        width: globalStyles.sizes.icons.small,
    },
    entryPoint: {
        container: {
            marginRight: 24
        },
        icon: {
            marginVertical: "auto",
            fill: globalStyles.colors.tabBarGrey,
            height: globalStyles.sizes.icons.small,
            width: globalStyles.sizes.icons.small
        }
    }

}

export default LxTabBar;
