import * as React from "react";
import Svg, { Path } from "react-native-svg";

function AveragePrice(props) {
  return (
      <Svg
          viewBox="0 0 24 24"
          fill="none"
          isreact="true"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
      >
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.2071 2.20711C23.5976 1.81658 23.5976 1.18342 23.2071 0.792893C22.8166 0.402369 22.1834 0.402369 21.7929 0.792893L0.792893 21.7929C0.402369 22.1834 0.402369 22.8166 0.792893 23.2071C1.18342 23.5976 1.81658 23.5976 2.20711 23.2071L23.2071 2.20711ZM11.9995 1C13.947 1 15.7762 1.50608 17.363 2.39391L15.8798 3.87711C14.7049 3.31486 13.389 3 11.9995 3C7.02895 3 2.99951 7.02944 2.99951 12C2.99951 13.3894 3.31437 14.7053 3.87662 15.8802L2.39342 17.3634C1.5056 15.7767 0.999512 13.9475 0.999512 12C0.999512 5.92487 5.92438 1 11.9995 1ZM11.9995 23C10.052 23 8.22278 22.4939 6.63606 21.6061L8.11927 20.1229C9.29417 20.6851 10.6101 21 11.9995 21C16.9701 21 20.9995 16.9706 20.9995 12C20.9995 10.6106 20.6847 9.29466 20.1224 8.11975L21.6056 6.63655C22.4934 8.22327 22.9995 10.0525 22.9995 12C22.9995 18.0751 18.0746 23 11.9995 23Z"
            fill="#EBEBF5"
        />
      </Svg>
  )
}
export default AveragePrice;
