import {
    LxReactComp,
    ReactWrapper
} from "LxComponents"
import {View} from "react-native";
import PropTypes from "prop-types"
import globalStyles from "GlobalStyles";
import React from "react";

/**
 * Unscrollable vertical list of items at the same height. Will hide childComps if they take up too much space, ensures
 * the moreComp is visible at the end. If available.
 * @prop {Array} [childComps] array of objects each providing a function comp and a property attribute.
 * @prop {Object} [moreComp] optional, if provided this will be shown last if nothing left can be shown.
 */
class LxReactVerticalContainer extends LxReactComp {

    #styles = {
        container: {
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            overflow: "scroll"
        },
        child: {
            //marginHorizontal: globalStyles.spacings.gaps.smallest,
            marginVertical: globalStyles.spacings.gaps.smallest
        }
    };

    constructor(props) {
        console.log(LxReactVerticalContainer.name, "constructor");
        super(props);
        this.ref = React.createRef();
        this.state = {availableHeight: -1};
    }

    /**
     * Use the onLayout event to check if a re-render with fewer items is required. If so, trigger one & keep the info.
     * @param event
     */
    onLayout(event) {
        let heightAvailable = this.ref.current?.clientHeight;
        let heightRequired = this.ref.current?.scrollHeight;
        if (heightRequired > heightAvailable) {
            this.setState({availableHeight: heightAvailable});
        }
    }

    #getChildHeight() {
        return this.props.childHeight + this.#styles.child.marginVertical * 2;
    }

    #getMoreHeight() {
        return this.props.moreComp ? (this.props.moreHeight + this.#styles.child.marginVertical * 2) : 0;
    }

    renderContent() {
        var childProps,
            moreHeight = this.#getMoreHeight(),
            childHeight = this.#getChildHeight(),
            heightTaken = 0;

        var rendered = this.props.childComps.map((child, idx) => {
            childProps = {...child.props};
            childProps.style = {...this.#styles.child, ...childProps.style};
            if (this.state.availableHeight < 0 || this.state.availableHeight > (heightTaken + moreHeight + childHeight)) {
                heightTaken += childHeight;
                return (
                    <child.comp {...childProps}/>
                );
            } else {
                return null;
            }
        });

        if (this.props.moreComp) {
            childProps = {...this.props.moreComp.props};
            childProps.key = "moreItem";
            childProps.style = {...this.#styles.child, ...childProps.style};
            rendered.push(
                <this.props.moreComp.comp {...childProps}/>
            )
        }
        return rendered;
    }

    render() {
        return <View
            key="main"
            ref={this.ref}
            onLayout={this.onLayout.bind(this)}
            style={{
                ...this.#styles.container,
                ...this.#styles.style
            }}>
                {this.renderContent()}
        </View>;
    }

}

LxReactVerticalContainer.propTypes = {
    style: PropTypes.object,
    childComps: PropTypes.arrayOf(
        PropTypes.shape({
            comp: PropTypes.element.isRequired,
            props: PropTypes.object
        })
    ).isRequired,
    childHeight: PropTypes.number.isRequired,
    moreComp: PropTypes.shape({
        comp: PropTypes.element.isRequired,
        props: PropTypes.object
    }),
    moreHeight: PropTypes.number
};

export default LxReactVerticalContainer;
