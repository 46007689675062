import React, {useCallback, useRef, createContext, useEffect} from "react";
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {TransitionPresets} from "@react-navigation/stack";
import {View} from "react-native";
import {
    TopNavigator,
    LxReactCardScreenTopNavigator,
    navigatorConfig,
    useCCEvent
} from "LxComponents"
import AmbientSettingsNavigator from "./AmbientSettingsNavigator";
import AmbientStackNavigator from "./AmbientStackNavigator";
import {useFocusEffect} from "@react-navigation/native";
import AmbientSearchStackNavigator from "./AmbientSearchStackNavigator";
import AmbientSystemStatusStackNavigator from "./AmbientSystemStatusStackNavigator";
import {Icons} from "../Icons";
import globalStyles from "GlobalStyles";
import AmbientUtils from "./AmbientUtils";


export const AmbientContext = createContext({
    isAmbientMode: false
})

const screenOptions = (...params) => {
    return {
        ...TopNavigator.forDefault(),
        ...TransitionPresets.ModalFadeTransition,
        ...navigatorConfig(LxReactCardScreenTopNavigator(...params))
    }
}

function AmbientTabNavDummyBar({state, descriptors, navigation}) {
    return <View style={{height: 1}}/>
}

function AmbientTabNavigator({navigation}) {
    const isFocussed = useRef(false);
    useFocusEffect(useCallback(() => {
        Debug.AmbientMode && console.log("Ambient Mode opened")
        isFocussed.current = true;
        SandboxComponent.setAmbientNavigation(navigation);
        document.documentElement.style.setProperty("--view-bg", "transparent")
        document.documentElement.style.setProperty("--modal-bg", "transparent")
        document.documentElement.style.setProperty("--cell-bg", "transparent")

        return () => {
            Debug.AmbientMode && console.log("Ambient Mode closed")
            isFocussed.current = false;
            document.documentElement.style.setProperty("--view-bg", "black")
            document.documentElement.style.setProperty("--modal-bg", globalStyles.colors.grey["600"])
            document.documentElement.style.setProperty("--cell-bg", "#212529")
        }
    }, []))

    const persistTimeout = useRef(false);
    useEffect(() => {
        let unsubFn = navigation.addListener('state', (event) => {
            if (isFocussed.current && EntryPointHelper.getLocation() === UrlStartLocation.LAST_POSITION) {
                if (persistTimeout.current) {
                    clearTimeout(persistTimeout.current);
                }
                persistTimeout.current = setTimeout(() => {
                    persistTimeout.current = null;
                    SandboxComponent.cacheAmbientTabStateToPersist(event.data.state);
                }, 200);
            }
        });

        return () => {
            unsubFn();
            persistTimeout.current && clearTimeout(persistTimeout.current);
            persistTimeout.current = false;
        }
    }, []);

    const delayedDefaultReset = useRef(false);
    useEffect(() => {
        if (SandboxComponent.isEcoModeActive()) {
            // navigating while the ambient mode is "pushed" underneath the eco screen would
            // cause troubles, don't do that.
            delayedDefaultReset.current = true;
            Debug.AmbientMode && console.log(AmbientTabNavigator.name, "delay initial default location navigation -> eco active!");
        } else {
            AmbientUtils.navigateToDefaultLocation(navigation);
        }
    }, [])

    useCCEvent([CCEvent.EcoScreenDarkenerActive], useCallback((event, data) => {
        if (delayedDefaultReset.current) {
            Debug.AmbientMode && console.log(AmbientTabNavigator.name, "eco dismissed, performed stored default location navigation!");
            delayedDefaultReset.current = false;
            AmbientUtils.navigateToDefaultLocation(navigation);
        }
    }, []))

    /*
    // should no longer be part as there is a home-button available.
    useCCEvent([CCEvent.EcoScreenDarkenerActive], useCallback((event, {dark, src}) => {
        if (dark) {
            AmbientUtils.navigateToDefaultLocation(navigation);
        }
    }, []));  */
    useCCEvent([CCEvent.ResetAmbientToDefaultLocation], useCallback((event, data) => {
        AmbientUtils.navigateToDefaultLocation(navigation, data ? data.fromButton : false);
    }, []));


    const Tab = createBottomTabNavigator(),
        tabs = [
            {
                component: AmbientStackNavigator,
                options: {
                    title: "AmbientStack".debugify(),
                    icon: Icons.Star
                }
            },
            {
                component: AmbientSettingsNavigator,
                options: {
                    title: "SettingsStack".debugify(),
                    icon: Icons.Star
                }
            },
            {
                component: AmbientSearchStackNavigator,
                options: {
                    title: "SearchStack".debugify(),
                    icon: Icons.Star
                }
            },
            {
                component: AmbientSystemStatusStackNavigator,
                options: {
                    title: "SystemStatusStackNav".debugify(),
                    icon: Icons.Star
                }
            },
        ];

    const prevTabName = useRef(AmbientStackNavigator.name);
    const prevTabIdx = useRef(0);

    const resetTabNamed = (tabName, resetTabIdx) => {
        Debug.AmbientMode && console.log(AmbientStackNavigator.name, "resetTabNamed: " + tabName + ", resetTabIdx " + !!resetTabIdx);
        navigation.navigate(AmbientStackNavigator.name)
    }

    useCCEvent([CCEvent.AmbientTabEvent], useCallback((ev, arg) => {
        if (arg.reset) {
            Debug.AmbientMode && console.log(AmbientTabNavigator.name, 'reset event received! reset the previous tab named: ' + prevTabName.current + ", set selected tab to: " + 0);
            resetTabNamed(prevTabName.current, true);
        }
    }, [navigation]));

    return <Tab.Navigator sceneContainerStyle={{backgroundColor: "transparent"}}
                          id={"AmbientNavigator"}
                          screenOptions={
                              (params) => {
                                  return {
                                      ...screenOptions(params, true),
                                      headerShown: false,
                                      inAmbientTabNav: true
                                  }
                              }
                          }
                          screenListeners={{
                              state: (e) => {
                                  // Do something with the state
                                  let state = e.data.state;
                                  let currTabIdx = state.index;
                                  let currTabName = state.routes[currTabIdx].name;
                                  Debug.AmbientMode && console.log(AmbientTabNavigator.name, 'tab nav state changed ' + currTabIdx + "=" + currTabName, e.data);
                                  try {
                                      let oldTabName = prevTabName.current;

                                      if (oldTabName !== currTabName) {
                                          prevTabName.current = currTabName;
                                          prevTabIdx.current = currTabIdx;
                                          SandboxComponent.dispatchAmbientTabEvent({
                                              selectedTab: currTabIdx,
                                              selectedTabName: currTabName
                                          })
                                      }
                                  } catch (ex) {
                                      debugger;
                                  }
                              },
                          }}
                          tabBar={props => <AmbientTabNavDummyBar {...props} isAmbientMode={true}/>}>
        {
            tabs.map(tab => {
                return <Tab.Screen key={tab.component.name} name={tab.component.name} {...tab} />
            })
        }
    </Tab.Navigator>
}

export default AmbientTabNavigator;
