import {createStateCell} from "../../UtilityComp/utilities/helper";
import globalStyles from "GlobalStyles";

export default class HourcounterControlStatesSrc {
    static getStateSections(control, states, navigation) {
        let section = {
                rows: []
            };

        let txt = _("controls.hourcounter.current-operating-duration");
        section.rows.push(createStateCell(txt, LxDate.formatSeconds(states.activeSinceSeconds).replaceAll("&nbsp;", " ")));

        if (states.hasMaintenanceInterval) {
            if (states.overdue) {
                txt = _("controls.hourcounter.maintenance.overdue-since");
                section.rows.push(createStateCell(txt, LxDate.formatSeconds(states.overdueSinceSeconds).replaceAll("&nbsp;", " "), null, null, null, globalStyles.colors.red));
            } else {
                txt = _("controls.hourcounter.maintenance.next-in");
                section.rows.push(createStateCell(txt, LxDate.formatSeconds(states.remainingSeconds).replaceAll("&nbsp;", " ")));
            }
        }

        if (control.statistic) {
            section.rows.push({
                content: {
                    title: _("statistics"),
                    disclosureIcon: true,
                },
                action: () => {
                    let nr = 0;
                    if (control.statistic && control.statistic.outputs && control.statistic.outputs[nr]) {
                        nr = nr ? nr : 0;
                        navigation.navigate(ScreenState.Statistic, {
                            controlUUID: control.uuidAction,
                            statisticOutputUUID: control.statistic.outputs[nr].uuid
                        })
                    }
                }
            })
        }


        return [section];
    }
}
