import { LxReactTableView, navigatorConfig, LxPermissionHandler } from "LxComponents"
import PropTypes from "prop-types";
import {useState, useRef, useContext} from "react";
import globalStyles from "GlobalStyles";
import {DeviceCreateContext} from "../../../../../react-comps/PairedApp/AppPairingNavigator";

function ChangeSecretScreenComp({navigation, route}) {
    const details = route.params;
    const {updateProps} = useContext(DeviceCreateContext);

    let type = details.type,
        user = details.user,
        originalUsername = details.originalUsername || user.name, // user.name will change if the user is renamed, but to avoid undefined allow it to change only if it's not set
        requestCurrentPassword = details.requestCurrentPassword &&
            type !== ChangeSecretScreen.Type.VisuPw &&
            type !== ChangeSecretScreen.Type.AccessCode,
        currentPassword;

    const refs = useRef({
        newSecrete: null,
        validationSecrete: null,
        actualPassword: null,
        isInSecretValid: true,
        initSecretScore: details.initSecretScore
    })

    const [newPwScore, setNewPwScore] = useState(null)


    const _setNavOptions = (disableTopRight) => {
        navigation.setOptions(
            navigatorConfig({
                animationType: AnimationType.MODAL,
                navigation,
                title: details.title|| _('change-password'),
                rightActions: [{
                    action: refs.current.initSecretScore ===  UserManagement.EMPTY_PWD ? _('create.room.popup.button') : _('user.change'),
                    disabled: disableTopRight
                }],
                onRightAction: () => {
                    _handleRightNavigationButtonTapped();
                }
            })
        )
    }

    const _getContent = () => {
        let tableContent = [],
            tmpPwScore;

        switch (type) {
            case ChangeSecretScreen.Type.UserPw:
            case ChangeSecretScreen.Type.VisuPw:
                tableContent.push({
                    rows: [
                        {
                            type: GUI.TableViewV2.CellType.PASS_INPUT,
                            content: {
                                type: GUI.LxInputEnum.Type.PASSWORD,
                                placeholder: _("change-password.new-password"),
                                newPwScore: newPwScore,
                                validation: [
                                    {
                                        valFunc:  (input) => {
                                            return !CommunicationComponent.isKnownUserPassword(user.name, input);
                                        },
                                        message: _('miniserver.credentials.default-pw-warning')
                                    },
                                ]
                            },
                            textChanged: (sectionIdx, rowIdx, table, value, validInput) => {
                                refs.current.newSecrete = value;
                                if (nullEmptyString(value)) {
                                    tmpPwScore = PasswordEval.evaluatePassword(refs.current.newSecrete, 3);
                                } else {
                                    tmpPwScore = UserManagement.EMPTY_PWD;
                                }

                                setNewPwScore(tmpPwScore);
                                refs.current.isInSecretValid = !validInput
                                _setNavOptions( refs.current.isInSecretValid || !(refs.current.newSecrete === refs.current.validationSecrete && !!nullEmptyString(refs.current.newSecrete)));
                            }
                        },
                        {
                            type: GUI.TableViewV2.CellType.INPUT,
                            content: {
                                type: GUI.LxInputEnum.Type.PASSWORD,
                                placeholder: _("change-password.new-password.repeat"),
                                alignment: "left",
                            },
                            textChanged: (sectionIdx, rowIdx, table, value, validInput) => {
                                refs.current.validationSecrete = value;
                                _setNavOptions(refs.current.isInSecretValid || !(refs.current.newSecrete === refs.current.validationSecrete && !!nullEmptyString(refs.current.newSecrete)));
                            }
                        }
                    ]
                });
                break;
            case ChangeSecretScreen.Type.AccessCode:
                tableContent.push({
                    rows: [
                        {
                            type: GUI.TableViewV2.CellType.INPUT,
                            content: {
                                placeholder: _('user.access-code.new-code'),
                                alignment: "left",
                                validation: [
                                    {
                                        regExp: Regex.ACCESS_CODE,
                                        message: _('user.access-code.invalid')
                                    }
                                ],
                                keyboardType: "number-pad"
                            },
                            textChanged: (sectionIdx, rowIdx, table, value, validInput) => {
                                refs.current.isInSecretValid = !validInput;
                                _setNavOptions(refs.current.isInSecretValid);

                                if (validInput) {
                                    refs.current.newSecrete = value;
                                } else {
                                    refs.current.newSecrete = null;
                                }
                            }
                        }
                    ]
                });
                break;
        }

        if (requestCurrentPassword) {
            tableContent[0].rows.splice(0 ,0, _getCurrentPasswordCell());
        } else if (Feature.USER_MANAGEMENT_REWORK && (type === ChangeSecretScreen.Type.AccessCode && details.codeExists) ||
            (refs.current.initSecretScore !== UserManagement.EMPTY_PWD) && !(type !== ChangeSecretScreen.Type.VisuPw && user.isAdmin)) {
            let delTexts = {
                title: "",
                message: ""
            };

            switch (type) {
                case ChangeSecretScreen.Type.UserPw:
                    delTexts.title = _('user.deletepass.title');
                    delTexts.message = _('user.deletepass.message');
                    delTexts.buttonTitle = _('user.deletepass.title');
                    break;
                case ChangeSecretScreen.Type.VisuPw:
                    delTexts.title = _('user.deletevisupass.title');
                    delTexts.message = _('user.deletevisupass.message');
                    delTexts.buttonTitle = _('user.deletepass.title');
                    break;
                case ChangeSecretScreen.Type.AccessCode:
                    delTexts.title = _('user.access-codes.delete.title');
                    delTexts.message = _('user.access-codes.delete.message', {
                        user: user.name
                    });
                    delTexts.buttonTitle = _('user.access-codes.delete.title');
                    break;
            }

            tableContent[0].rows.push({
                type: GUI.TableViewV2.CellType.DELETE,
                content: {
                    title: delTexts.title
                },
                action: () => {
                    NavigationComp.showPopup({
                        title: delTexts.title,
                        message: delTexts.message,
                        buttonOk: delTexts.buttonTitle,
                        buttonCancel: true,
                        icon: Icon.Notification.CAUTION,
                        color: Styles.colors.red
                    }, PopupType.GENERAL).then(() => {
                        refs.current.newSecrete = "";
                        return _handleRightNavigationButtonTapped(UserManagement.EMPTY_PWD);
                    });
                }
            });
        }

        return tableContent;
    }

    const _getCurrentPasswordCell = () => {
        currentPassword = ActiveMSComponent.getCurrentCredentials().password;

        return  {
            type: GUI.TableViewV2.CellType.PASS_INPUT,
            content: {
                type: GUI.LxInputEnum.Type.PASSWORD,
                placeholder: _("change-password.current-password")
            },
            textChanged: (sectionIdx, rowIdx, table, value, validInput) => {
                refs.current.actualPassword = value;

                _setNavOptions(currentPassword !== refs.current.actualPassword);
            }
        }
    }

    const _handleRightNavigationButtonTapped = (tmpPwdScore) => {
        let changePrms;
        // resign focus. Important for WI, otherwise the command would be sent more often when hitting enter more than once.
        document.activeElement.blur();

        switch (type) {
            case ChangeSecretScreen.Type.UserPw:
                changePrms = ActiveMSComponent.changePassword(refs.current.newSecrete, {...user, name: originalUsername}, (tmpPwdScore || newPwScore)); // user.name will change if the user is renamed, so we need to use the original name not to grab a salt for a user that doesn't exist yet
                break;
            case ChangeSecretScreen.Type.VisuPw:
                if (details.fromPairing) {
                    // if used within the managed tablet pairing dialog, pass the new values back using the fn from the context.
                    changePrms = Q.resolve(updateProps({ visuPassword: refs.current.newSecrete, scoreVisuPWD: tmpPwdScore || newPwScore }));
                } else {
                    changePrms = ActiveMSComponent.changeVisuPassword(refs.current.newSecrete, {...user, name: originalUsername}, (tmpPwdScore || newPwScore)); // same as above
                }
                break;
            case ChangeSecretScreen.Type.AccessCode:
                changePrms = ActiveMSComponent.changeAccessCode(refs.current.newSecrete, user);
                break;
            default:
                changePrms = Q.reject(`Unknown Type "${type}"!`);
        }

        return NavigationComp.showWaitingFor(changePrms).then(res => {
            let object = {
                    type
                },
                warnPrms = Q.resolve();

            if (type === ChangeSecretScreen.Type.AccessCode && getLxResponseCode(res) === ResponseCode.CODE_IN_USE) {
                // Add the popup promise to await the user interaction before navigate back
                warnPrms = NavigationComp.showPopup({
                    title: _('user.access-code.change.success-message'),
                    message: _('caution') + ": " + _('user.access-code.existing'),
                    buttonOk: true,
                    icon: Icon.SUCCESS,
                    color: globalStyles.colors.orange
                });
            }

            return warnPrms.then(() => {
                switch (type) {
                    case ChangeSecretScreen.Type.UserPw:
                        object.key = UserManagement.SCORE_PWD;
                        object.value = tmpPwdScore || newPwScore;
                        object.text = refs.current.newSecrete;
                        break;
                    case ChangeSecretScreen.Type.VisuPw:
                        object.key = UserManagement.SCORE_VISU_PWD;
                        object.value = tmpPwdScore || newPwScore;
                        object.text = refs.current.newSecrete;
                        break;
                    case ChangeSecretScreen.Type.AccessCode:
                        object.key = UserManagement.ACCESS_CODE;
                        object.value = refs.current.newSecrete;
                        object.text = refs.current.newSecrete;
                        break;
                }

                let state = navigation.getState();

                if (state && state.routes) {
                    let prevRoute = state.routes[state.routes.length - 2];

                    navigation.navigate(prevRoute.name, {
                        details: object,
                        needsReload: type,
                        ts: moment().unix()
                    });

                }

            });
        }, error => _handleError(error));
    }

    const _handleError = (error) => {
        let content = {
            title: _("error"),
            message: _("change-password.failed-message"),
            buttonOk: true,
            icon: Icon.CAUTION,
            color: globalStyles.colors.red
        };

        switch (getLxResponseCode(error)) {
            case ResponseCode.CONFLICT:
                content.message = _('user.access-code.invalid');
                content.color = globalStyles.colors.orange;
                break;

            case ResponseCode.NOT_ACCEPTABLE:
                content.message = _('user.access-code.used-by-control');
                content.color = globalStyles.colors.orange;
                break;

            case ResponseCode.TOO_MANY_REQUESTS:
                content.title = _('user.access-code.change.too-many-changes.title');
                content.message = _('user.access-code.change.too-many-changes.message');
                content.color = globalStyles.colors.orange;
                break;

            case ResponseCode.TRUST_PEER_NOT_REACHABLE:
                content.title = _("change-password.failed-message");
                content.message = _("user.access-code.change.trust.peer-not-reachable", {
                    trustPeer: user.trustMember
                });
                break;
        }

        NavigationComp.showPopup(content);
    }


    _setNavOptions(refs.current.isInSecretValid);
    return <LxReactTableView
        tableContent={_getContent()}
        showSectionSeparator={true}
    />
}

function ChangeSecretScreen (props) {
    return <ChangeSecretScreenComp {...props}/>

}

ChangeSecretScreen.Type = {
    UserPw: "userPw",
    VisuPw: "visuPw",
    AccessCode: "accessCode"
}

ChangeSecretScreen.propTypes = {
    title: PropTypes.string,
    user: PropTypes.object,
    type: PropTypes.string,
    initSecretScore: PropTypes.number,
}

export default ChangeSecretScreen;
