'use strict';

define("ValueSelectorControl", [
    "UpDownLeftRightAnalogControl",
    "IconLib"
], function (
    UpDownLeftRightAnalogControl,
    {
        default: Icons
    }
) {
    return class ValueSelectorControl extends UpDownLeftRightAnalogControl {
        constructor() {
            super(...arguments);
        }

        getStepWidth() {
            return this.getStates().step;
        }

        validateValue(val) {
            var states = this.getStates();

            if (this.details.increaseOnly && val > states.max) {
                // when max reached, start at min
                return states.min;
            }

            if (val >= states.min && val <= states.max) {
                return val;
            }
        }

        supportsDecrease() {
            return !this.details.increaseOnly;
        }

        getButton0() {
            return {
                iconSrc: Icon.ValueSelector.PLUS,
                reactIcon: Icons.Plus,
                command: {
                    tap: this.getCommand.bind(this, Direction.UP),
                    tick: this.getCommand.bind(this, Direction.UP)
                }
            };
        }

        getButton1() {
            if (!this.details.increaseOnly) {
                return {
                    iconSrc: Icon.ValueSelector.MINUS,
                    reactIcon: Icons.Minus,
                    command: {
                        tap: this.getCommand.bind(this, Direction.DOWN),
                        tick: this.getCommand.bind(this, Direction.DOWN)
                    }
                };
            }
        }

        getButtonIcon(direction) {
            if (direction === Direction.DOWN) {
                return Icon.ValueSelector.MINUS;
            } else if (direction === Direction.UP) {
                return Icon.ValueSelector.PLUS;
            }
        }

        getContentButtonIcon(direction) {
            if (direction === Direction.DOWN) {
                return Icon.ValueSelector.MINUS_PLAIN;
            } else if (direction === Direction.UP) {
                return Icon.ValueSelector.PLUS_PLAIN;
            }
        }

    };
});
