'use strict';

define(["AudioZoneControlEnums"], function (AudioZoneControlEnums) {
    class AudioZoneSettingsAirplay extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            this.details = details;
            this.control = details.control;
            this.antiGhostTimer = new AntiGhostTimer(this._sendValue.bind(this), 250);
        }

        titleBarText() {
            return _("media.settings.audio-delay");
        }

        viewDidLoad() {
            var playerId = this.control.details.playerid,
                cmd = Commands.format(MediaEnum.Commands.SETTINGS.AUDIO_DELAY_GET, playerId),
                args = arguments;
            return MediaServerComp.sendMediaServerCommand(cmd).then(res => {
                this.delayValue = res.data[0].audio_delay;
                return super.viewDidLoad(...args);
            });
        }

        setTableContent() {
            this.tableContent = [{
                rows: [{
                    type: GUI.TableViewV2.CellType.SLIDER,
                    content: {
                        title: _('media.settings.audio-delay'),
                        value: this.delayValue,
                        minIconSrc: Icon.ValueSelector.MINUS_PLAIN,
                        minValue: MediaEnum.AudioDelay.MIN,
                        maxIconSrc: Icon.ValueSelector.PLUS_PLAIN,
                        maxValue: MediaEnum.AudioDelay.MAX,
                        format: MediaEnum.AudioDelay.FORMAT,
                        step: MediaEnum.AudioDelay.STEP
                    },
                    sliderDragged: this._onSliderChange.bind(this),
                    sliderClicked: this._onSliderChange.bind(this)
                }]
            }];
            return super.setTableContent(...arguments);
        }

        _onSliderChange(cell, section, row, tableView, value) {
            this.delayValue = value;
            this.antiGhostTimer.fire();
        }

        _sendValue() {
            var playerId = this.control.details.playerid,
                cmd = Commands.format(MediaEnum.Commands.SETTINGS.AUDIO_DELAY_SET, playerId, this.delayValue);
            MediaServerComp.sendMediaServerCommand(cmd);
        }

    }

    Controls.AudioZoneControl.AudioZoneControlZoneAudioDelaySettings = AudioZoneSettingsAirplay;
});
