'use strict';

window.GUI = function (GUI) {
    class HomeAndFavoriteScreen extends GUI.Screen {
        constructor(details) {
            super($('<div class="home-and-favorite-screen"><div class="home-and-favorite-screen__scroller"</div></div>'));
            this.homeVisible = null;
            this.yCoord = 0;

            if (details) {
                this.details = details;
            } else {
                this.details = {
                    isHome: NavigationComp.getHomeScreenAvailability()
                }; //console.log("Constructors details are missing isHome attribute, default: " + this.details.isHome);
            }
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.registerForResize();
            this.elements = {
                scrollContainer: this.element.find(".home-and-favorite-screen__scroller")
            };
            this.scrollView = new IScroll(this.element[0], {
                probeType: 3,
                tap: false,
                click: false,
                mouseWheel: true,
                scrollX: false,
                scrollY: true,
                disableMouse: true
            });
            this.homeScreen = new GUI.HomeScreenHD();
            this.appendSubview(this.homeScreen, this.elements.scrollContainer);
            this.favoritesScreen = new GUI.FavoritesScreenHD();
            this.appendSubview(this.favoritesScreen, this.elements.scrollContainer);
            this.favoritesScreen.onSplitListsChanged = this.onFavoriteSplitListsChanged.bind(this);
            this.favoriteTitleBar = this.getFavoriteTitleBar();
            this.appendSubview(this.favoriteTitleBar);
            this.homeTitleBar = this.getHomeTabTitleBar();
            this.appendSubview(this.homeTitleBar);
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments);
            this.miniserverList && this.miniserverList.viewWillAppear();

            this._adoptScrollContentHeight();
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);
            this.miniserverList && this.miniserverList.viewDidAppear();
            this.homeTitleBar.onDropDownStateChange = this.onDropDownStateChange.bind(this);
            this._onScroll = this.scrollView.on("scroll", function () {
                this.onScroll && this.onScroll(this.scrollView.x, this.scrollView.y);
            }.bind(this));
            this._boundExpandedChanged = this._boundExpandedChanged || this.onExpandedChanged.bind(this);
            document.body.addEventListener("ExpandedChanged", this._boundExpandedChanged);
            this.setSupportsHomeScreen(NavigationComp.getHomeScreenAvailability());
        }

        viewWillDisappear() {
            this.miniserverList && this.miniserverList.viewWillDisappear();
            super.viewWillDisappear();
        }

        viewDidDisappear(viewRemainsVisible) {
            this.miniserverList && this.miniserverList.viewDidDisappear();
            this.homeTitleBar.onDropDownStateChange = null;
            this.scrollView.off(this._onScroll); // remove from ExpandedChanged event

            document.body.removeEventListener("ExpandedChanged", this._boundExpandedChanged);
            this._boundExpandedChanged = null;
            super.viewDidDisappear(viewRemainsVisible);
        }

        destroy() {
            this.homeTitleBar = null;
            this.favoriteTitleBar = null;
            this.miniserverList && this.miniserverList.destroy();
            this.miniserverList = null;
            super.destroy();
        }

        updateView(details) {
            super.updateView(...arguments);
            this.details = details;

            this._scrollAccordingToDetails();
        }

        getURL() {
            return this.homeVisible ? "home" : "favorite";
        }

        setSupportsHomeScreen(hasHomeScreen) {
            if (this.hasHomeScreen === hasHomeScreen) {
                return;
            }

            this.hasHomeScreen = hasHomeScreen;
            this.homeScreen.getElement().toggle(hasHomeScreen);
            this.element.toggleClass('home-and-favorite-screen--no-home', !hasHomeScreen);

            if (hasHomeScreen) {
                this.favoriteStart = this.homeScreen.getElement()[0].clientHeight;

                if (this.homeVisible) {
                    this.favoriteStart -= this.homeTitleBar.getElement()[0].clientHeight;
                } else {
                    this.favoriteStart -= this.favoriteTitleBar.getElement()[0].clientHeight;
                }
            } else {
                this.favoriteStart = 0;
            }

            this._adoptScrollContentHeight();

            this.scrollView.refresh();
            this.onScroll(0, this.yCoord);
        }

        getHomeTabTitleBar() {
            var isWebinterface = PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface;
            var titleBarConfig = {
                style: 'transparent'
            };

            if (isWebinterface) {
                titleBarConfig.leftSide = 'text';
            } else {
                titleBarConfig.leftSide = 'dropDownButton';
            }

            var titleBar = new GUI.LxTitleBar(titleBarConfig); // titleBar.getElement() also contains the dropDown, so only look for the titleBar

            var tbElem = $(titleBar.getElement()[0]);
            tbElem.addClass('home-and-favorite-screen__title-bar');
            tbElem.addClass('home-and-favorite-screen__title-bar--home');
            titleBar.setTitleBarSideTexts(ActiveMSComponent.getMiniserverName());

            if (!isWebinterface) {
                this.prepareMiniserverDropDown();
            }

            return titleBar;
        }

        prepareMiniserverDropDown() {
            // intercept tableViewDelegate - we need to know when the scrolling is to be handled by the tableView itself
            this.miniserverList = new GUI.TableViewV2(this.homeScreen, this.homeScreen);
            this.homeTitleBar.setDropDownListContent(this.miniserverList, true);
            var miniserver = PersistenceComponent.getAllMiniserver();
            this.storedMiniserver = mapToArrayOld(miniserver);
            this.storedMiniserver.sort(function (a, b) {
                if (a.msName != null && b.msName != null) {
                    return a.msName.localeCompare(b.msName);
                } else {
                    return false;
                }
            });
            this.miniserverList.reload();
        }

        getFavoriteTitleBar() {
            var titleBarConfig = {
                leftSide: TitleBarCfg.TEXT
            };
            var titleBar = new GUI.LxTitleBar(titleBarConfig);
            titleBar.getElement().addClass('home-and-favorite-screen__title-bar');
            titleBar.getElement().addClass('home-and-favorite-screen__title-bar--favorites');
            titleBar.setTitleBarSideTexts(_("favorites.title"));
            return titleBar;
        }

        onScroll(x, y) {
            this.yCoord = y;
            var favoritesVisible = Math.abs(this.yCoord) >= this.favoriteStart;

            this._toggleFavsVisible(favoritesVisible);
        }

        onExpandedChanged() {
            this.scrollView.refresh();

            this._adoptScrollContentHeight();
        }

        onResize() {
            setTimeout(this._adoptScrollContentHeight.bind(this), 200);
        }

        onDropDownStateChange(open) {
            if (open) {
                this.scrollView.disable();
            } else {
                this.scrollView.enable();
            }
        }

        _scrollAccordingToDetails() {
            if (this.homeVisible != this.details.isHome) {
                var target = 0;

                if (!this.details.isHome) {
                    target = (this.favoriteStart + 10) * -1;
                }

                var animated = NavigationComp.animationsEnabled();

                if (animated) {
                    this.scrollView.scrollTo(0, target, 500, null);
                } else {
                    this.scrollView.scrollTo(0, target);
                }

                this._toggleFavsVisible(!this.details.isHome, animated);
            } else {// nothing to do
            }
        }

        /**
         * This method ensures that we can always scroll as far down as needed for the favorites to take up the screen.
         * @private
         */
        _adoptScrollContentHeight() {
            var target = (this.favoriteStart + 10) * -1;
            var scrollHeight = this.elements.scrollContainer[0].clientHeight;
            var screenHeight = this.element[0].clientHeight;
            var leftToScroll = screenHeight - scrollHeight;
            var resize = leftToScroll + target * -1;
            var favElem = this.favoritesScreen.getElement()[0];
            var favHeight = favElem.clientHeight;

            if (!isNaN(resize) && resize > 0) {
                // avoid decreasing the size. We never want to cut something off.
                favElem.style.height = favHeight + resize + 'px';
                this.scrollView.refresh();
            }
        }

        _toggleFavsVisible(favoritesVisible, animated) {
            if (!animated) {
                var homeElem = this.homeTitleBar.getElement(); // titleBars getElement() always returns the dropdown container too

                $(homeElem[0]).toggle(!favoritesVisible);
                this.favoriteTitleBar.getElement().toggle(favoritesVisible);
            }

            var didChange = this.homeVisible === favoritesVisible;
            this.homeVisible = !favoritesVisible; // check if we need to inform the activeMsTabScreen (our ViewController)

            if (didChange) {
                // set as new object, otherwise the reference will be adopted! -> leads to wrong tab selections
                this.details = {
                    isHome: !favoritesVisible
                }; // also update the details, needed for correct tab selection when navigating back form ControlContent eg.

                this.ViewController.updateHomeFavoriteSelection(!favoritesVisible);
                NavigationComp.updateURL();
            }
        }

        onFavoriteSplitListsChanged() {
            this._adoptScrollContentHeight();
        }

    }

    GUI.HomeAndFavoriteScreen = HomeAndFavoriteScreen;
    return GUI;
}(window.GUI || {});
