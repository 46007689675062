import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Open(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path d="M8.707 4.793a1 1 0 00-1.414 0l-6.5 6.5a1 1 0 000 1.414l6.5 6.5a1 1 0 001.414-1.414L2.914 12l5.793-5.793a1 1 0 000-1.414zM13 18.5a1 1 0 11-2 0v-13a1 1 0 112 0v13zm3.707-13.707a1 1 0 10-1.414 1.414L21.086 12l-5.793 5.793a1 1 0 001.414 1.414l6.5-6.5a1 1 0 000-1.414l-6.5-6.5z" />
        </Svg>
    )
}

export default Open;
