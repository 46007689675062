'use strict';

Window.Controls = function (Controls) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var SUCC_ID = "accountdata",
            ID_OKAY = "written",
            InputType = {
                TEXT: 'text',
                PASS: 'password',
                EULA: 'eula'
            };

        class ServiceLoginScreen extends GUI.Screen {
            //region Static
            static Template = class {
                //region Static
                static getServiceHeader(title, subtitle, iconSrc) {
                    return '<div class="service-header-container">' + '   <div class="service-header-content">' + '       <div class="service-icon-placeholder">' + '       ' + ImageBox.getResourceImageWithClasses(iconSrc, "service-icon") + '       </div>' + '       <div class="service-text-placeholder">' + '           <div class="service-text-title-label">' + title + '</div>' + '           <div class="service-text-subtitle-label">' + subtitle + '</div>' + '       </div>' + '   </div>' + '</div>';
                }

                static getSubmitButton(text) {
                    return '<input type="submit" ' + 'class="form__submit-button button--invalid btn btn--primary form__button" ' + 'value="' + text + '">';
                }

                static getScreenContent(service, isEditing, requiresAccount) {
                    var deleteButton = '';

                    if (isEditing) {
                        deleteButton = '<div class="form__delete-button  btn btn--primary form__button">' + _('delete') + '</div>';
                    }

                    var saveButton = '';

                    if (requiresAccount) {
                        var text;

                        if (isEditing) {
                            if (!this.control.audioserverComp.Feature.V2_FIRMWARE) {
                                // The v2 doesn't allow to change the account name
                                text = _('save');
                            }
                        } else {
                            text = _('media.preferences.services.editor.add-x-account', {
                                service: service.name
                            });
                        }

                        if (text) {
                            saveButton = this.getSubmitButton(text);
                        }
                    }

                    var registerButton = '';

                    if (requiresAccount && !isEditing && service.hasOwnProperty('registerlink')) {
                        registerButton = '' + '<div class="form__button form__button-slim form__plain-button form__register-button clickable">' + _("media.preferences.services.editor.register-account") + '</div>';
                    }

                    var helpButton = '';

                    if (service.hasOwnProperty('helplink')) {
                        helpButton = '' + '<div class="form__button form__button-slim form__plain-button form__help-button clickable">' + _("media.preferences.services.editor.more-info") + '</div>';
                    }

                    return '' + '<div class="service-content">' + '    <form class="service-content__form">' + '        <div class="form__table"></div>' + '        ' + saveButton + '        ' + deleteButton +
                        /*'        ' + registerButton +
                        '        ' + helpButton +*/
                        '    </form>' + '</div>';
                } //endregion Static


            }; //endregion Static

            constructor(details) {
                super($("<div/>"));
                this.service = details.service;
                this.control = details.control;
                this.isEditing = this.service.hasOwnProperty(MusicServerEnum.Attr.SERVICE.USER);
                this.requiresAccount = this.service.hasOwnProperty(MusicServerEnum.Attr.SERVICE.CFG);

                this._hasLoginMask = !this.control.audioserverComp.Feature.V2_FIRMWARE || this.service[MusicServerEnum.Attr.SERVICE.CMD] === "soundsuit"

                if (!this._hasLoginMask) {
                    this.config = [];
                } else {
                    this.config = (this.requiresAccount ? this.service.config : []).filter(function (config) {
                        return Object.values(InputType).indexOf(config.type) !== -1; // validate config
                    });
                }
            }

            viewDidLoad() {
                Debug.Control.AudioZone.ServiceLogin && console.log(this.name, "viewDidLoad");
                super.viewDidLoad(...arguments);
                this.element.append(ServiceLoginScreen.Template.getScreenContent(this.service, this.isEditing, this.requiresAccount));
                this.buttons = {};
                this.elements = {
                    tablePlaceholder: $(this.element.find('.form__table')),
                    form: $(this.element.find('.service-content__form')),
                    submitButton: this.element.find('.form__submit-button'),
                    deleteButton: this.element.find('.form__delete-button'),
                    registerButton: this.element.find('.form__register-button'),
                    moreInfoButton: this.element.find('.form__help-button'),
                    formHeader: this.element.find('.form__header')
                };
                this.tableView = new GUI.TableViewV2(this, this);
                this.appendSubview(this.tableView, this.elements.tablePlaceholder);

                if (this.isEditing) {
                    this.buttons.deleteButton = new GUI.LxButton(this, this.elements.deleteButton[0], Color.BUTTON_GLOW);
                    this.addToHandledSubviews(this.buttons.deleteButton);
                }
                /* else if (this.requiresAccount && this.service.hasOwnProperty(MusicServerEnum.Attr.SERVICE.REGISTERLINK)) {
                   this.buttons.registerButton = new GUI.LxButton(this, this.elements.registerButton[0], Color.BUTTON_GLOW);
                   this.addToHandledSubviews(this.buttons.registerButton);
                }
                if (this.service.hasOwnProperty(MusicServerEnum.Attr.SERVICE.HELPLINK)) {
                   this.buttons.moreInfoButton = new GUI.LxButton(this, this.elements.moreInfoButton[0], Color.BUTTON_GLOW);
                    this.addToHandledSubviews(this.buttons.moreInfoButton);
                }*/


                this.element.toggleClass("audio-zone-settings-service-login-screen--hd", HD_APP);
            }

            viewWillAppear() {
                Debug.Control.AudioZone.ServiceLogin && console.log(this.name, "viewWillAppear");
                super.viewWillAppear(...arguments);

                if (this.buttons.deleteButton) {
                    this.buttons.deleteButton.onButtonTapped = this._handleDeleteButtonTapped.bind(this);
                }

                if (this.buttons.registerButton) {
                    this.buttons.registerButton.onButtonTapped = this._handleRegisterButtonTapped.bind(this);
                }

                if (this.buttons.moreInfoButton) {
                    this.buttons.moreInfoButton.onButtonTapped = this._handleHelpButtonTapped.bind(this);
                }

                this.validities = this.config.map(function (config) {
                    return false;
                });
                this.tableView.reload();

                this._updateValidity();

                this.elements.form.on('submit', function () {
                    this._handleSubmit();

                    return false;
                }.bind(this));
            }

            viewDidDisappear(viewRemainsVisible) {
                Debug.Control.AudioZone.ServiceLogin && console.log(this.name, "viewDidDisappear");
                this.elements.form.off('submit');
                super.viewDidDisappear(viewRemainsVisible);
            }

            getAnimation() {
                return AnimationType.MODAL;
            }

            titleBarText() {
                if (this.control.audioserverComp.Feature.V2_FIRMWARE) {
                    return this.isEditing ? _("media.edit-favorites", {
                        favorite: this.service.user
                    }) : _("media.preferences.services.editor.add");
                } else {
                    return this.isEditing ? _("edit") : _("media.preferences.services.editor.add");
                }
            }

            getURL() {
                return "serviceEditor";
            }

            // TableView delegate
            sectionHeaderElement() {
                return ServiceLoginScreen.Template.getServiceHeader(this.service.name, _("media.preferences.music-service"), this.service.iconSrc);
            }

            styleForTableView() {
                return TableViewStyle.COMFORT_MODE_2020;
            }

            numberOfSections() {
                return 1;
            }

            numberOfRowsInSection() {
                return this.config.length;
            }

            cellTypeForCellAtIndex(section, row) {
                if (this.config[row].type === MusicServerEnum.Attr.SERVICE.EULA) {
                    return GUI.TableViewV2.CellType.Special.MEDIA_EULA_INPUT_CELL;
                }

                return GUI.TableViewV2.CellType.INPUT;
            }

            textChanged(section, row, tableView, text, isValid) {
                this.config[row].value = text;

                if (!this.validities) {
                    this.validities = [];
                }

                this.validities[row] = isValid;

                this._updateValidity();
            }

            eulaCheckboxChanged(section, row, checked) {
                if (!this.validities) {
                    this.validities = [];
                }

                this.validities[row] = checked;

                this._updateValidity();
            }

            _updateValidity() {
                var allValid = true;
                var i;

                for (i = 0; i < this.validities.length; i++) {
                    allValid = this.validities[i] && allValid;
                }

                this.elements.submitButton.toggleClass('button--invalid', !allValid);
            }

            contentForCell(cell, section, row) {
                var content = {};
                var configItem = this.config[row];

                if (configItem.type === MusicServerEnum.Attr.SERVICE.EULA) {
                    configItem.text = this._translateInputName(configItem.name);
                    return configItem;
                } else {
                    if (row === 0) {
                        if (!this.validities) {
                            this.validities = [];
                        }

                        this.validities[0] = true;
                    }

                    if (configItem.hasOwnProperty('regex')) {
                        // the music server passes a pattern as a string, needs to be converted to a regex.
                        content.validationRegex = new RegExp(decodeURIComponent(configItem.regex));
                    }

                    content.placeholder = this._translateInputName(configItem.name);

                    if (configItem.hasOwnProperty('value')) {
                        content.value = configItem.value;
                    }

                    switch (configItem.type) {
                        case InputType.TEXT:
                            content.leftIconSrc = Icon.Login.USER;
                            content.autoFocus = true;
                            break;

                        case InputType.PASS:
                            content.leftIconSrc = Icon.Login.PASS;
                            content.type = GUI.LxInputEnum.Type.PASSWORD;
                            break;

                        default:
                            break;
                    }

                    return content;
                }
            }

            _handleSubmit() {
                if (!this._hasLoginMask && !this.isEditing) {
                    AudioserverComp.sendMediaServerCommand(MusicServerEnum.Commands.SERVICES.CFG.GET_LINK + this.service.cmd).done(function (result) {
                        NavigationComp.openWebsite(result.data.link);
                        this.ViewController.navigateBack(); // Closes the Add screen, spotify account will appear in the management screen once added...
                    }.bind(this));
                } else {
                    // process values, check their validity & create a string containing the values
                    var configValueList = "",
                        i,
                        valid = true;

                    for (i = 0; i < this.config.length; i++) {
                        if (this.config[i].type === InputType.EULA) {
                            if (!this.validities[i]) {
                                // break and show a messageBox
                                NavigationComp.showPopup({
                                    title: _("error"),
                                    message: _("media.service.add.input.eula.not-checked"),
                                    buttonOk: true,
                                    icon: Icon.ERROR
                                });
                                valid = false;
                                break;
                            }
                        } else if (this.validities[i]) {
                            configValueList += this.config[i].value;
                        } else {
                            Debug.Control.AudioZone.ServiceLogin && console.error(this.name, this.config[i].name + " is invalid: '" + this.config[i].value + "'");
                            valid = false;
                            break;
                        }

                        if (i < this.config.length - 1) {
                            configValueList += "/";
                        }
                    }

                    if (valid) {
                        // Build Service Cfg Command
                        var cmd;

                        if (this.isEditing) {
                            cmd = MusicServerEnum.Commands.SERVICES.CFG.UPDATE + this.service.uid;
                        } else {
                            cmd = MusicServerEnum.Commands.SERVICES.CFG.ADD + this.service.cmd;
                        }
                        //Debug.Control.AudioZone.ServiceLogin && console.log(this.name, "_handleSubmit: " + cmd + "/{enc("+ configValueList + ")");
                        let encStr = AudioserverComp.encryptWithPublicKey(configValueList, true);
                        Debug.Control.AudioZone.ServiceLogin && console.log(this.name, "    encryptedPayload = " + encStr);
                        cmd += "/" + encStr;

                        Debug.Control.AudioZone.ServiceLogin && console.log(this.name, "             fullCmd = " + cmd);
                        document.activeElement.blur();

                        this._updateServiceConfig(cmd);
                    }
                }
            }

            /**
             * Sends the service config command to the Media Server, waits for it's response & processes it.
             * @param cmd   the service cfg command that is to be sent to the media server.
             * @private
             */
            _updateServiceConfig(cmd) {
                Debug.Control.AudioZone.ServiceLogin && console.log(this.name, "_updateServiceConfig");
                NavigationComp.showWaitingFor(AudioserverComp.sendMediaServerCommand(cmd).then(function (result) {
                    Debug.Control.AudioZone.ServiceLogin && console.log(this.name, "_updateServiceConfig - responded: " + JSON.stringify(result));

                    if (this.service[MusicServerEnum.Attr.SERVICE.CMD] === MusicServerEnum.Service.SOUNDSUIT) {
                        this._verifySoundsuitCfgResponse(result);
                    } else {
                        try {
                            var response = result.data[0][SUCC_ID];

                            if (response === ID_OKAY) {
                                this.ViewController.navigateBack();
                            } else {
                                this._handleInvalidResponse();
                            }
                        } catch (ex) {
                            console.error(this.name, "Exception while trying to process the servicecfg_result: " + ex);
                            this.ViewController.navigateBack();
                        }
                    }
                }.bind(this)));
            }

            _verifySoundsuitCfgResponse(response) {
                Debug.Control.AudioZone.ServiceLogin && console.log(this.name, "_verifySoundsuitCfgResponse: " + JSON.stringify(response));
                let responseData = response.data,
                    errorCode = responseData.error || MusicServerEnum.ServiceCfgErrorCode.NONE,
                    action = responseData.action,
                    user = responseData.user,
                    errorTxt = responseData.errorMsg;

                if (errorCode === MusicServerEnum.ServiceCfgErrorCode.NONE) {
                    HapticFeedback(HapticFeedback.STYLE.SUCCESS);
                    Controls.AudioZoneV2Control.SingleTones.CustomizationManager.shared(this.control).set(MusicServerEnum.ControlContentMenuId.SOUNDSUIT, {
                        enabled: true
                    });
                    this.ViewController.navigateBack();

                } else {
                    let txtPrts = [],
                        title = _("error"),
                        userTxt = user || ((this.config && this.config[0]) ? this.config[0].value : null);
                    switch (errorCode) {
                        case MusicServerEnum.ServiceCfgErrorCode.WRONG_CREDENTIALS:
                            // Unauthorised
                            title = _("authenticate.failed");
                            txtPrts.push(_("check-credentials"));
                            break;
                        case MusicServerEnum.ServiceCfgErrorCode.DUPLICATE_ACCOUNT:
                            // already exists!
                            txtPrts.push(_("media.service-login.duplicate.message", {user: userTxt || _("unknown")}));
                            break;
                        case MusicServerEnum.ServiceCfgErrorCode.NON_EXISTENT_ACCOUNT:
                            // account does not exist.
                            title = _("not-found");
                            txtPrts.push(_("check-credentials"));
                            break;
                        default:
                            txtPrts.pushObject(errorTxt);
                            txtPrts.push("(ID:" + errorCode + ")");
                            break;
                    }
                    Debug.Control.AudioZone.ServiceLogin && console.log(this.name, "     > failed! code=" + errorCode + ", text=" + txtPrts.join(SEPARATOR_SYMBOL));
                    HapticFeedback(HapticFeedback.STYLE.WARNING);
                    NavigationComp.showPopup({
                        title: title,
                        message: txtPrts.join(SEPARATOR_SYMBOL),
                        icon: Icon.WARNING,
                        color: window.Styles.colors.orange,
                        buttons: [{
                            title: _("okay"),
                            type: GUI.PopupBase.ButtonType.OK
                        }]
                    });
                }
            }

            /**
             * Called when a service cfg command responded with another value than "written", which means that
             * something went wrong. What exactly did go wrong will be presented by a dialog event.
             * @private
             */
            _handleInvalidResponse() {
                Debug.Control.AudioZone.ServiceLogin && console.log(this.name, "_handleInvalidResponse");
                this.tableView.reload();
            }

            _handleDeleteButtonTapped() {
                var message, title, content;

                if (this.requiresAccount) {
                    title = _("media.preferences.services.editor.remove-account.title");
                    message = _("media.preferences.services.editor.remove-account.message", {
                        service: this.service.name,
                        account: this.service[MusicServerEnum.Attr.SERVICE.USER]
                    });
                } else {
                    title = _('media.preferences.services.editor.remove-service.title', {
                        service: this.service.name
                    });
                    message = _("media.preferences.services.editor.remove-service.message", {
                        service: this.service.name
                    });
                }

                content = {
                    title: title,
                    message: message,
                    buttonOk: _("remove"),
                    buttonCancel: true,
                    color: window.Styles.colors.red,
                    icon: Icon.DELETE
                };
                NavigationComp.showPopup(content).done(function () {
                    this._deleteServiceConfirmed();
                }.bind(this));
            }

            _deleteServiceConfirmed() {
                var cmd = MusicServerEnum.Commands.SERVICES.CFG.DELETE + this.service[MusicServerEnum.Attr.SERVICE.CMD] + "/" + this.service[MusicServerEnum.Attr.SERVICE.ID];
                NavigationComp.showWaitingFor(AudioserverComp.sendMediaServerCommand(cmd)).then(function () {
                    this.ViewController.navigateBack();
                }.bind(this));
            }

            _handleRegisterButtonTapped() {
                this._openUrl(this.service.registerlink);
            }

            _handleHelpButtonTapped() {
                this._openUrl(this.service.helplink);
            }

            _openUrl(url) {
                NavigationComp.openWebsite(url);
            }

            _translateInputName(name) {
                var translationKey = "media.service.add.input." + name.toLowerCase();

                var translated = _(translationKey);

                if (translated === translationKey) {
                    translated = name; // no translation

                    Debug.Control.AudioZone.ServiceLogin && console.info(this.name, "no translation for '" + name + "'");
                }

                return translated;
            }

        }

        Controls.AudioZoneV2Control.ServiceLoginScreen = ServiceLoginScreen;
    }
    return Controls;
}(window.Controls || {});
