import {useState, useEffect, useRef } from "react";

export default function useGroups(groupType = GroupTypes.ROOM, sortedByName = true) {

    const getCurrentGroups = () => {
        // it is important to request them as object, otherwise it'd be filtered as some rooms may not contain any controls
        let currentGroups = ActiveMSComponent.getStructureManager().getGroupsByType(groupType, true);
        if (sortedByName) {
            currentGroups = Object.values(currentGroups).sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
        }
        return currentGroups;
    }

    const [groups, setGroups] = useState(getCurrentGroups);

    const handleStructureChanged = () => {
        setGroups(getCurrentGroups());
    }

    const structureChangedReg = useRef(null);
    useEffect(() => {
        structureChangedReg.current = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.StructureChanged, handleStructureChanged);
        return () => {
            structureChangedReg.current  && NavigationComp.removeFromUIEvent(structureChangedReg.current);
            structureChangedReg.current = null;
        }
    })

    return groups;
}