'use strict';

define(['AudioZoneControlEnums'], function (AudioZoneControlEnums) {
    class AudioZoneControlSettings extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            this.details = details;
            this.control = details.control;
            this.currentTableContent = details.currentTableContent;
        }

        viewDidLoad() {
            super.viewDidLoad(arguments);
            this.tableContent = this._getTableContent();
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            return _("settings");
        }

        _getTableContent() {
            var tableContent = [];

            if (Feature.SHARED_USER_SETTINGS) {
                tableContent.push(this._getUserSettingsSection());
            }

            tableContent.push(this._getZoneSettingsSection());
            tableContent.push(this._getMusicServerSettingsSection());
            tableContent.push({
                rows: [this._getAboutMusicServerCell()]
            });
            return tableContent;
        }

        // Zone Settings Section
        _getZoneSettingsSection() {
            var zoneSection = this._getSectionTemplate(_("media.preferences.zone") + " " + this.control.getName());

            zoneSection.rows.push(this._getVolumeCell());

            if (this.control.details.clientType !== MediaEnum.ClientType.UPNP) {
                zoneSection.rows.push(this._getEQCell());
            }

            zoneSection.rows.push(this._getDelaySection());
            zoneSection.rows.push(this._getZoneFavSection());
            zoneSection.rows.push(this._getExpertModeCell());
            return zoneSection;
        }

        // User Settings Section
        _getUserSettingsSection() {
            var section = this._getSectionTemplate(_('user'));

            section.rows.push(this._getDisplayEditSettings());
            section.rows.push(this._getShortcutsSettings());
            section.footerTitle = _("media.display-settings.disclaimer", {
                user: ActiveMSComponent.getCurrentUser().name
            });
            return section;
        }

        // Music Server Settings Sections
        _getMusicServerSettingsSection() {
            var musicServerSettingsSection = this._getSectionTemplate(_("media.popup.title"));

            musicServerSettingsSection.rows.pushObject(this._getAirPlayCell());
            musicServerSettingsSection.rows.pushObject(this._getAudioSourceCell());
            musicServerSettingsSection.rows.pushObject(this._getLibraryCell());
            musicServerSettingsSection.rows.pushObject(this._getTuneInRow());
            musicServerSettingsSection.rows.pushObject(this._getSpotifyCell());
            return musicServerSettingsSection;
        }

        _getAirPlayCell() {
            var target = AudioZoneControlEnums.ScreenState.AIRPLAY,
                detail = {
                    control: this.control
                };
            return this._getCellTemplate(_("media.preferences.airplay"), target, detail);
        }

        _getAudioSourceCell() {
            var target = AudioZoneControlEnums.ScreenState.SETTINGS_INPUT_LIST,
                detail = {
                    control: this.control
                };
            return this._getCellTemplate(_("media.section.line-in"), target, detail);
        }

        _getLibraryCell() {
            if (MediaServerComp.Feature.NAS_LIBRARY) {
                var target = AudioZoneControlEnums.ScreenState.SETTINGS_LIBRARY,
                    detail = {
                        control: this.control
                    };
                return this._getCellTemplate(_("media.library"), target, detail);
            }
        }

        _getSpotifyCell() {
            var target = AudioZoneControlEnums.ScreenState.SETTINGS_SERVICES_SPOTIFY_MANAGEMENT,
                detail = {
                    control: this.control
                };
            return this._getCellTemplate(_("media.popup.spotify.title"), target, detail);
        }

        _getTuneInRow() {
            var target = AudioZoneControlEnums.ScreenState.SETTINGS_SERVICES_RADIO,
                detail = {
                    control: this.control
                },
                cellTemplate = this._getCellTemplate(_("media.service.webradio"), target, detail);

            if (MediaServerComp.Feature.DISABLE_RADIOS) {
                cellTemplate.content.disclosureIcon = true;
            } else {
                cellTemplate.content.clickable = false;
            }

            return cellTemplate;
        }

        _getZonesCell() {
            var target = AudioZoneControlEnums.ScreenState.SETTINGS_ZONES_LIST,
                detail = {
                    control: this.control
                };
            return this._getCellTemplate(_("media.preferences.zones"), target, detail);
        }

        _getEQCell() {
            var target = AudioZoneControlEnums.ScreenState.SETTINGS_EQ,
                detail = {
                    control: this.control
                };
            return this._getCellTemplate(_("media.equalizer"), target, detail);
        }

        _getZoneFavSection() {
            var target = AudioZoneControlEnums.ScreenState.FAVORITE_EDIT,
                title = _("media.zone.fav"),
                detail = {
                    control: this.control,
                    title: title
                };

            return this._getCellTemplate(title, target, detail);
        }

        _getDelaySection() {
            var target = AudioZoneControlEnums.ScreenState.SETTINGS_AUDIO_DELAY,
                title = _("media.settings.audio-delay"),
                detail = {
                    control: this.control,
                    title: title
                };

            return this._getCellTemplate(title, target, detail);
        }

        _getExpertModeCell() {
            return {
                content: {
                    title: _("media.preferences.zone.expert-settings"),
                    clickable: true
                },
                action: NavigationComp.showControlSettings.bind(NavigationComp, this.control, false)
            };
        }

        _getVolumeCell() {
            var target,
                detail = {
                    control: this.control
                };

            if (Feature.NEW_AUDIO_ZONE_STATES) {
                target = AudioZoneControlEnums.ScreenState.SETTINGS_VOLUME;
            } else {
            }

            return this._getCellTemplate(_("media.volume"), target, detail);
        }

        _getAboutMusicServerCell() {
            var target = AudioZoneControlEnums.ScreenState.ABOUT_SERVER,
                detail = {
                    control: this.control
                };
            return this._getCellTemplate(_("media.preferences.about", {
                server: MediaServerComp.getServerName()
            }), target, detail);
        }

        // Additionl Section
        _getAdditionalSection() {
        }

        _showScreen(target, detail) {
            this.ViewController.showState(target, null, detail);
        }

        _getCellTemplate(title, target, details) {
            return {
                content: {
                    title: title,
                    clickable: true,
                    disclosureIcon: false,
                    class: "base-cell--with-chevron"
                },
                type: GUI.TableViewV2.CellType.GENERAL,
                action: function () {
                    if (target && details) {
                        this._showScreen(target, details);
                    }
                }.bind(this)
            };
        }

        _getSectionTemplate(title) {
            return {
                headerTitle: title,
                rows: []
            };
        }

        _getShortcutsSettings() {
            var target = AudioZoneControlEnums.ScreenState.SETTINGS_SHORTCUT_EDIT;
            return this._getCellTemplate(_('media.shortcuts.title'), target, {
                control: this.control
            });
        }

        _getDisplayEditSettings() {
            var target = AudioZoneControlEnums.ScreenState.SETTINGS_DISPLAY_EDIT,
                details = {
                    control: this.control,
                    currentTableContent: this.currentTableContent
                };
            return this._getCellTemplate(_('media.display-settings.title'), target, details);
        }

    }

    Controls.AudioZoneControl.AudioZoneControlSettings = AudioZoneControlSettings;
});
