import {
    ButtonType,
    LxReactButton, LxReactFlexibleCell, LxReactHeader,
    LxReactQuickSelect,
    LxReactText,
    LxReactWaitingView,
    navigatorConfig,
    useBackNavigation
} from "../Components";
import {useCallback, useEffect, useRef, useState} from "react";
import {View} from "react-native";
import QrScanner from "qr-scanner";
import globalStyles from "GlobalStyles";
import PairingConnectScreen from "./PairingConnectScreen";
import Icons from "IconLib";
import useBeforeNavBack from "./useBeforeNavBack";
import { Animated, Easing } from "react-native";
import PairedAppEnums from "../../PairedAppComp/pairedAppEnums";
import useOrientationChanged, {Orientation} from "../customHooks/useOrientationChanged";


export default function ScanQrSetupScreen({navigation, route}) {

    const [scanInfo, setScanInfo] = useState({
        setupFile: false,
        error: false
    });

    useEffect(() => {
        navigation.setOptions({
            ...navigatorConfig({
                title: "",
                animationType: AnimationType.MODAL,
            }),
            headerTransparent: true
        })
    }, [navigation]);
    useBackNavigation(() => {
        navigation.goBack();
    })

    useBeforeNavBack(useCallback(ev => {
       if (scanInfo.setupFile) {
           setScanInfo({});
           ev.preventDefault();
       }
    }), [scanInfo]);


    const pairConfirmed = () => {
        Debug.QrScan && console.log(ScanQrSetupScreen.name, "pairConfirmed: " + JSON.stringify(scanInfo.setupFile));
        navigation.goBack();
        setTimeout(() => {
            navigation.navigate(PairingConnectScreen.name);
            PairedAppComponent.startPairingWithFile(scanInfo.setupFile, PairedAppEnums.SetupSource.QR);
        }, 200)
    }
    const restartScan = () => {
        Debug.QrScan && console.log(ScanQrSetupScreen.name, "restartScan");
        setScanInfo({});
    }

    const ConfirmPairingView = ({payload}) => {
        let listCnt = 0;

        const ListCell = ({title, value}) => {
            listCnt++;
            return <LxReactFlexibleCell
                section={0}
                row={listCnt}
                key={"#"+listCnt}
                title={title}
                titleStyle={[globalStyles.customStyles.cellTitle, styles.title]}
                mainRightContent={
                    {
                        comp: LxReactText,
                        props: {
                            style:[globalStyles.customStyles.rightCellText, styles.text],
                            children: value
                        }
                    }
                }
            />
        }

        return <View style={styles.pairingInfoCntr}>
            <ListCell title={_("miniserver.serial-number")} value={payload?.serialNr} />
            <ListCell title={_("miniserver.url.local")} value={payload?.localUrl} />
            <ListCell title={_("miniserver.url.remote")} value={payload?.remoteUrl} />
            <LxReactButton containerStyle={styles.pairBtn} text={_("managed-tablet.complete-pairing")} pkey={"btn-pair"} onPress={pairConfirmed}/>
            <LxReactButton containerStyle={styles.retryBtn} text={_("miniserver.conn-failed.retry")} pkey={"btn-scan-again"} onPress={restartScan} buttonType={ButtonType.SECONDARY}/>
        </View>
    }


    const onScanned = useCallback((payload) => {
        Debug.QrScan && console.log(ScanQrSetupScreen.name, "onScanned: ", payload);
        let isValid = false;
        try {
            const pairingSetup = JSON.parse(payload);
            if (PairedAppComponent.validatePairingFile(pairingSetup, PairedAppEnums.SetupSource.QR)) {
                isValid = true;
                setScanInfo({ setupFile: pairingSetup });
            }
        } catch (ex) {
            console.error(ScanQrSetupScreen.name, "onScanned failed - couldn't parse payload: " + payload);
        }

        if (!isValid && !!payload) {
            return NavigationComp.showPopup({
                title: _("managed-tablet.invalid-qr-title"),
                message: _("managed-tablet.invalid-qr-message"),
                buttonOk: _('okay'),
                icon: Icon.CAUTION,
                color: globalStyles.colors.red,
                type: PopupType.GENERAL
            }).then(() => {}, () => {});
        }
        return Q.resolve();
    }, []);

    const onFailed = useCallback((err) => {
        console.error(ScanQrSetupScreen.name, "onFailed: " + err);
        setScanInfo({ error: err });
    }, []);

    return <View style={styles.pairingRoot}>

        <LxReactHeader
            style={scanInfo.setupFile ? styles.header : styles.scanHeader }
            title={scanInfo.setupFile ? _("managed-tablet.set-up-managed-tab") : _("managed-tablet.scan-setup-code")}
            icon={scanInfo.setupFile ? Icons.QrScan : null} />
        <View style={styles.body}>
        {
            scanInfo.setupFile ?
                <ConfirmPairingView payload={scanInfo.setupFile} /> :
                <QrScanView onResult={onScanned} onFail={onFailed} />
        }
        </View>
    </View>
}


/**
 * @param onResult called when a code has been scanned
 * @param onFail called when a code has been scanned
 * @returns {JSX.Element}
 * @constructor
 */
export function QrScanView({onResult, onFail}) {
    const videoRef = useRef(null);
    const selCamRef = useRef(null);
    const scannerRef = useRef(null);

    const orientation = useOrientationChanged();

    const [camInfo, setCamInfo] = useState({
        available: [],
        started: false
    } );


    const handleScanResult = useCallback(({cornerPoints, data}) => {
        Debug.QrScan && console.log(QrScanView.name, "handleScanResult", data);

        scannerRef.current.stop();
        onResult && onResult(data).then(res => {
            scannerRef.current.start();
        });
    }, [onResult]);

    const updateCameraOptions = () => {
        Debug.QrScan && console.log(QrScanView.name, "updateCameraOptions");
        QrScanner.listCameras(true).then((res) => {
            Debug.QrScan && console.log(QrScanView.name, "updateCameraOptions > received: " + JSON.stringify(res));
            if (res.length > 0) {
                if (res.length === 1 && PlatformComponent.isAndroid() && res[0].id === "") {
                    // Android may not disclose the available cameras, add options by default
                    setCamInfo(prev => {
                        return {
                            ...prev,
                            available: [
                                {id: DefaultQrCamDirections.User, label: _("qr-scan.select-camera-front")},
                                {id: DefaultQrCamDirections.Environment, label: _("qr-scan.select-camera-rear")}
                            ]
                        };
                    });
                } else {
                    setCamInfo(prev => {
                        return {...prev, available: res };
                    });
                }
            } else {
                onFail("no-camera");
            }
        });
    }

    useEffect(() => {
        Debug.QrScan && console.log(QrScanView.name, "creating QR Scanner!");
        const scanOptions = {
            onDecodeError: () => {},
            preferredCamera: selCamRef.current || DefaultQrCamDirections.User,
            returnDetailedScanResult: true
        }
        scannerRef.current = new QrScanner(videoRef.current, handleScanResult, scanOptions);
        selCamRef.current && scannerRef.current.setCamera(selCamRef.current);
        scannerRef.current.start().then((arg, a2) => {
            (camInfo.available.length === 0) && updateCameraOptions();
        });
        return () => {
            Debug.QrScan && console.log(QrScanView.name, "scanner about to be destroyed!");
            scannerRef.current.stop();
            scannerRef.current.destroy();
            scannerRef.current = null;
        }
    }, []);

    const CameraSelection = ({cameras}) => {
        const options = cameras.map(cam => {
            return {
                value: cam.id,
                title: cam.label
            }
        });
        if (options.length === 0) {
            options.push({value: DefaultQrCamDirections.User, title: "--"});
        }
        const cameraSelected = (camId) => {
            Debug.QrScan && console.log(QrScanView.name, "cameraSelected: " + camId);
            selCamRef.current = camId;
            setCamInfo(prevInfo => {
                return {...prevInfo, started: false};
            })
            scannerRef.current.setCamera(camId);
        }

        if (options.length === 1) {
            return <View style={styles.camSelector} />
        }

        return <LxReactQuickSelect
            notSelectedTitle={_("qr-scan.select-camera")}
            noToggle={true}
            selectedValue={selCamRef.current}
            options={options}
            onOptionSelected={cameraSelected}
            containerStyle={styles.camSelector}
        />
    }

    const scaleFactor = useRef(new Animated.Value(0.95)).current;

    useEffect(() => {
        camInfo.started && Animated.loop(
            Animated.sequence([
                Animated.timing(scaleFactor, {
                    toValue: 1.05,
                    easing: Easing.inOut(Easing.ease),
                    useNativeDriver: true,
                    duration: 400
                }),
                Animated.timing(scaleFactor, {
                    toValue: 0.95,
                    easing: Easing.inOut(Easing.ease),
                    useNativeDriver: true,
                    duration: 400
                }),
            ]),
            {
                useNativeDriver: true
            }
        ).start()
    }, [camInfo.started])

    const adoptedIconStyle = ({width, height, orientation}, currentOrientation) => {

        const elemDimension = {
            width: videoRef.current?.clientWidth,
            height: videoRef.current?.clientHeight,
        }
        /**
         * If the video is started in portrait, it e.g. has 200x400, when it's rotated to landscape later on
         * those values will still be on 200x400 but it should be 400x200
         * @param initW             width of the video when started
         * @param initH             height of the video when started
         * @param initOrientation     device orientation when started
         * @param currOrientation  current device orientation
         * @returns {{width, height}}
         */
        const fixVideoDimension = (initW, initH, initOrientation, currOrientation) => {
            let dimension;
            if (initOrientation === currOrientation) {
                dimension = { width: initW, height: initH };
            } else {
                dimension = { width: initH, height: initW };
            }
            return dimension;
        }
        const fixedVidDim = fixVideoDimension(width, height, orientation, currentOrientation);

        const getRenderedVideoDim = (realVidDim, cntrDim) => {
            const detectOrientation = ({width, height}) => {
                return (width > height) ? Orientation.LANDSCAPE : orientation.PORTRAIT;
            }
            const vidOrientation = detectOrientation(realVidDim);
            const cntrOrientation = detectOrientation(cntrDim);
            let factor;
            if (vidOrientation === cntrOrientation) {
                const maxCntr = Math.max(cntrDim.width, cntrDim.height);
                const maxVideo = Math.max(realVidDim.width, realVidDim.height);
                factor = maxCntr / maxVideo;

            } else if (cntrOrientation === Orientation.LANDSCAPE) {
                // container = landscape, video = portrait
                factor = cntrDim.height / realVidDim.height;
            } else {
                // container = landscape, video = portrait
                factor = cntrDim.width / realVidDim.width;
            }

            return { width: realVidDim.width * factor, height: realVidDim.height * factor };
        }
        const realVidDim = getRenderedVideoDim(fixedVidDim, elemDimension);

        if (realVidDim.width > realVidDim.height) {
            // landscape video
            Debug.QrScan && console.log(QrScanView.name, "adoptedIconStyle: LANDSCAPE-VIDEO: " + JSON.stringify(realVidDim));
            return {
                height: realVidDim.height * 0.68,
                width: "auto"
            };
        } else {
            // portrait video
            Debug.QrScan && console.log(QrScanView.name, "adoptedIconStyle: PORTRAIT-VIDEO: " + JSON.stringify(realVidDim));
            return {
                width: realVidDim.width * 0.68,
                height: "auto"
            }
        }
    }

    const ScanOverlay = ({started, dimension, currentOrientation}) => {
        return started ? <Animated.View style={{...styles.overlayContainer, scale: scaleFactor}}>
            <Icons.QrScanArea style={[styles.overlayIcon, adoptedIconStyle(dimension, currentOrientation)]} />
        </Animated.View> : <LxReactWaitingView style={styles.overlayContainer} />;
    }

    const handlePlayStarted = () => {
        // iOS: when rotating after the start --> the width becomes the height --> hence the overlay needs to be adopted.
        const dimension = {
            orientation: orientation,
            width: videoRef.current?.videoWidth,
            height: videoRef.current?.videoHeight
        }
        Debug.QrScan && console.log(QrScanView.name, "handlePlayStarted: " + JSON.stringify(dimension));
        setCamInfo(prevInfo => {
            return {...prevInfo, started: true, dimension};
        })
    }

    return <View style={styles.scanRootCntr}>
        <View style={styles.videoViewCntr}>
            <CameraSelection cameras={camInfo.available} />
            <View style={styles.videoAreaContainer}>
                <video
                    onPlaying={handlePlayStarted}
                    ref={videoRef}
                    style={{...styles.videoArea, ...(camInfo.started ? {} : {opacity: 0})}}
                />
               <ScanOverlay started={camInfo.started} dimension={camInfo.dimension} currentOrientation={orientation}/>
            </View>
        </View>
    </View>
}

const DefaultQrCamDirections = {
    User: 'user',
    Environment: 'environment'
}

const styles = {
    scanRootCntr: {
        flex: 1,
        justifyContent: "flex-start",
        alignItems: "center"
    },
    scanHeader: {
        marginLeft: globalStyles.sizes.small,
        marginTop: globalStyles.sizes.small,
        marginRight: globalStyles.sizes.small,
        marginBottom: "auto",
        width: "auto",
        maxHeight: 100
    },
    videoArea: {
        width: "100%",
        height: undefined,
        margin: "auto"
    },
    videoAreaContainer: {
        width: "auto",
        height: "auto",
        flex: 1
    },
    overlayContainer: {
        position: "absolute",
        width: "100%",
        height: "100%",
    },
    overlayIcon: {
        stroke: globalStyles.colors.stateActive,
        margin: "auto",
        width: "auto",
        height: "68%",
        aspectRatio: 1
    },
    videoViewCntr: {
        flexDirection: "column",
        justifyContent: "flex-start",
        flex: 1,
        marginHorizontal: globalStyles.spacings.gaps.medium
    },
    camSelector: {
        marginHorizontal: "auto",
        minHeight: 40
    },
    title: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.secondary
    },
    text: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.primary
    },
    error: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.red
    },


    pairingRoot: {
        flex: 1,
        justifyContent: "flex-start",
        alignItems: "center"
    },
    pairingInfoCntr: {
        width: "100%",
        maxWidth: globalStyles.sizes.contentMaxWidth,
        flexDirection: "column",
        marginBottom: "auto"
    },
    pairBtn: {
        marginVertical: globalStyles.spacings.gaps.small
    },
    retryBtn: {
        marginBottom: globalStyles.spacings.gaps.small
    },
    header: {
        marginLeft: globalStyles.sizes.small,
        marginTop: globalStyles.sizes.small,
        marginRight: globalStyles.sizes.small,
        marginBottom: "auto",
        width: "auto",
        maxHeight: 170
    },
    body: {
        flex: 1
    }
}