'use strict';

define("IRCV2DaytimerCalendarEntries", ["DaytimerCalendarEntries", "IRoomControllerV2ControlEnums", "IrcFixedSetpointUtil"],
    function (DaytimerCalendarEntries, IRoomControllerV2ControlEnums, {Util: FixedSetpointUtil}) {
    return class IRCV2DaytimerCalendarEntries extends DaytimerCalendarEntries {
        //region Static
        static Template = function () {
            /**
             *
             * @param ctrl          the iRoomController itself
             * @return {string}
             */
            var createLegendView = function createLegendView(ctrl) {
                return '<div class="legend-view">' + '<div class="legend-list legend-list--irc">' +
                    createLegendViewEntry(ctrl, IRoomControllerV2ControlEnums.Mode.COMFORT) +
                    createLegendViewEntry(ctrl, IRoomControllerV2ControlEnums.Mode.ECO) +
                    createLegendViewEntry(ctrl, IRoomControllerV2ControlEnums.Mode.ECO_PLUS) +
                    (Feature.IRC_SCHEDULE_SETPOINTS ? createLegendViewEntry(ctrl, FixedSetpointUtil.convertToValue(0, true, true)) : "") +
                    '</div>' + '<div class="legend-button">' + ImageBox.getResourceImageWithClasses('resources/Images/Controls/IRoom/info.svg', 'legend-button__icon') + '</div>' + '</div>';
            };

            var createLegendViewEntry = function createLegendViewEntry(control, modeNr) {
                var circleClass = "entry__circle  circle--circle-border",
                    name = control.getNameOfIRCTempMode(modeNr);
                return '<div id="mode-nr-' + modeNr + '" class="legend-list__entry">' +
                    '<div class="' + circleClass + '"></div>' +
                    '<div class="entry__name">' + name + '</div>' +
                    '<div class="entry__value"></div>' +
                    '</div>';
            };

            return {
                getLegend: createLegendView
            };
        }(); //endregion Static

        constructor(control, onScroll) {
            super(...arguments); // the irc daytimer is passed in here as this.control - so be sure to have the poolControl itself ready too.

            this.parentControl = ActiveMSComponent.getControlByUUID(this.control.uuidParent);
        }

        // -----------------------------------------------------------------------------------------
        // -----------------------------------------------------------------------------------------
        getCircleColorForMode(modeNr) {
            return {
                color: this.parentControl.getColorOfIRCTempMode(modeNr, true),
                cssAttr: 'background-color'
            };
        }

        getEntryBackgroundColor(entry) {
            return this.parentControl.getColorOfIRCTempMode(entry.tempMode, true, entry.needActivate);
        }

        hasLegend() {
            return true;
        }

        /**
         * Returns a jquery element that contains the legend for these calendar entries
         * @return {jQuery|HTMLElement}
         */
        getLegendTemplate() {
            // pass on the parent control as the ircDaytimer has no such methods!
            return $(IRCV2DaytimerCalendarEntries.Template.getLegend(this.parentControl));
        }

        updateLegend(states) {
            var valueLabels = this.elements.legendList.find('.entry__value'),
                label,
                tempModeNr;

            for (var i = 0; i < valueLabels.length; i++) {
                label = $(valueLabels[i]);
                tempModeNr = parseInt(label.parent()[0].id.replace('mode-nr-', ''));

                if (!FixedSetpointUtil.isFixedSetpointValue(tempModeNr)) {
                    label.text(this.parentControl.getTempStringOfMode(tempModeNr, false, true));
                }
            }
        }

        _appendDetailToElement(elem, entry) {
            if (Feature.IRC_SCHEDULE_SETPOINTS) {
                // important, do not use the "current capability" from states, as if the Climate-Controller is currently
                // off or set to cooling only, this would hide the heating temperature!
                const heatConfigured = this.parentControl.hasHeatingCapability();
                const coolConfigured = this.parentControl.hasCoolingCapability();
                let states = this.parentControl.getStates(),
                    info = {
                    heat: true,
                    cool: true,
                    showHeat: heatConfigured,
                    showCool: coolConfigured
                };
                if (FixedSetpointUtil.isFixedSetpointValue(entry.entry.tempMode)) {
                    var fsp = FixedSetpointUtil.extractFromValue(entry.entry.tempMode);
                    info.showHeat = fsp.heat;
                    info.showCool = fsp.cool;
                    info.heat = fsp.heat && heatConfigured;
                    info.cool = fsp.cool && coolConfigured;
                    info.heatTemp = fsp.target;
                    info.coolTemp = fsp.target;
                } else {
                    switch (entry.entry.tempMode) {
                        case IRoomControllerV2ControlEnums.Mode.COMFORT:
                            info.coolTemp = states.comfortTempMax;
                            info.heatTemp = states.comfortTempMin;
                            break;
                        case IRoomControllerV2ControlEnums.Mode.ECO_PLUS:
                            info.coolTemp = states.heatProtectTemperature;
                            info.heatTemp = states.frostProtectTemperature;
                            break;
                        default:
                            info.coolTemp = states.ecoTempMax;
                            info.heatTemp = states.ecoTempMin;
                            break;
                    }
                }
                this._appendHeatCoolWithTemp(elem,info);
            } else {
                super._appendDetailToElement(...arguments);
            }

            if (entry.entry.needActivate) {
                elem.addClass("column__entry-needs-activation");
            }
        }

        _appendHeatCoolWithTemp(elem, {heat, cool, heatTemp, coolTemp, showHeat, showCool}) {
            if (heatTemp === coolTemp) {
                var tempStr = this.parentControl.formatTemp(heatTemp);
                var row = '<div class="heat-cool-cntr__row heat-cool-cntr__row--heat">'
                        + (showHeat ? ImageBox.getResourceImageWithClasses(heat ? Icon.IRCV2021.Icons.HEATING : Icon.IRCV2021.Icons.NO_HEATING, "row__icon") : "")
                        + (showCool ? ImageBox.getResourceImageWithClasses(cool ? Icon.IRCV2021.Icons.COOLING : Icon.IRCV2021.Icons.NO_COOLING, "row__icon") : "")
                        + '<div class="row__target">' + tempStr + '</div>'
                        + '</div>';

                elem && elem.append($('<div class="entry__heat-cool-cntr">' + row + '</div>'));

            } else {
                var heatTempStr = this.parentControl.formatTemp(heatTemp);
                var coolTempStr = this.parentControl.formatTemp(coolTemp);
                var heatRow = '<div class="heat-cool-cntr__row heat-cool-cntr__row--heat">' +
                        ImageBox.getResourceImageWithClasses(heat ? Icon.IRCV2021.Icons.HEATING : Icon.IRCV2021.Icons.NO_HEATING, "row__icon") +
                        '<div class="row__target">' + heatTempStr + '</div>' +
                        '</div>',
                    coolRow =  '<div class="heat-cool-cntr__row heat-cool-cntr__row--cool">' +
                        ImageBox.getResourceImageWithClasses(cool ? Icon.IRCV2021.Icons.COOLING : Icon.IRCV2021.Icons.NO_COOLING, "row__icon") +
                        '<div class="row__target">' + coolTempStr + '</div>' +
                        '</div>';

                elem && elem.append($('<div class="entry__heat-cool-cntr">' + (showCool ? coolRow : "") +  (showHeat ? heatRow : "") + '</div>'));
            }
        }

    };
});
