'use strict';

define(["ControlActionCellsScreen", "FroniusControlInfoView", "FroniusControlEnums", "FroniusControlContentOverview"], function (ControlActionCellsScreen, FroniusControlInfoView, FroniusControlEnums) {
    return class FroniusControlContent extends ControlActionCellsScreen {
        constructor() {
            super(...arguments);
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(() => {
                this.remoteInfo = new GUI.LxInfoBar(_("controls.fronius.production.standby"));
                this.infoBar = this.remoteInfo;
                return this.appendSubview(this.remoteInfo).then(() => {
                    return this.hideSubview(this.infoBar);
                });
            });
        }

        /**
         * This method can be overwritten, if a view is returned it will be shown inside the customInfoBox!
         * @return {null}
         */
        getCustomInfoView() {
            return new FroniusControlInfoView(this.control);
        }

        receivedStates(states) {
            super.receivedStates(...arguments);
            this.toggleCustomInfoView(states.hasEnergyMeter);

            if (!states.isOnline && states.inStandby) {
                this.showSubview(this.infoBar);
            } else {
                this.hideSubview(this.infoBar);
            }
        }

        shouldDisplayStateIcons(states) {
            return !states.hasEnergyMeter;
        }

        /*getStateRows(states) {
            var rows = [],
                value,
                title,
                subtitle = null;

            if (states.hasEnergyMeter && states.hasProduction) {
                rows.push(this._getOverviewRow());
            } else {
                title = _("controls.fronius.production.current");
                value = lxFormat(FroniusControlEnums.VALUE_FORMAT, states.currProduction);
                rows.push(this.createStateCell(title, value));
                title = _("controls.fronius.production.total");
                value = lxFormat(FroniusControlEnums.ENERGY_FORMAT, states.productionTotal);
                rows.push(this.createStateCell(title, value));

                if (states.hasBattery) {
                    title = _("controls.energy-monitor.battery");

                    if (states.currBattery < 0) {
                        subtitle = lxFormat(FroniusControlEnums.BATTERY_FORMAT, states.stateOfCharge);
                        subtitle = _("controls.energy-monitor.charging-battery", {
                            charge: subtitle
                        });
                        value = lxFormat(FroniusControlEnums.VALUE_FORMAT, states.currBattery * -1);
                    } else if (states.currBattery > 0) {
                        subtitle = lxFormat(FroniusControlEnums.BATTERY_FORMAT, states.stateOfCharge);
                        subtitle = _("controls.energy-monitor.using-battery", {
                            charge: subtitle
                        });
                        value = lxFormat(FroniusControlEnums.VALUE_FORMAT, states.currBattery);
                    } else {
                        title = _("controls.energy-monitor.battery", {
                            charge: lxFormat(FroniusControlEnums.BATTERY_FORMAT, states.stateOfCharge)
                        });
                    }

                    rows.push(this.createStateCell(title, value, null, subtitle));
                }
            }

            return rows;
        }

        _getOverviewRow() {
            return {
                content: {
                    title: _("controls.fronius.overview"),
                    clickable: true,
                    rightIconSrc: Icon.DISCLOSURE
                },
                action: this.showScreen.bind(this, FroniusControlEnums.ScreenState.OVERVIEW)
            };
        } */

    };
});
