import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Category(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M11.824 1.433a1 1 0 00-1.696.077l-4.5 8A1 1 0 006.5 11h10a1 1 0 00.824-1.567l-5.5-8zM22.5 18a5 5 0 11-10 0 5 5 0 0110 0zM1.5 14.5a1 1 0 011-1h7a1 1 0 011 1v7a1 1 0 01-1 1h-7a1 1 0 01-1-1v-7z"
            />
        </Svg>
    )
}

export default Category
