'use strict';

/*
    delegate
    entry       Entry of the forecastObject

    // delegate method
    onForecastEntrySelected(this, this.entry)
 */

class WeatherForecastEntry extends GUI.View {
    //region Static
    static Template = function () {
        var getTimelineElement = function getTimelineElement() {
            return $('<div class="forecast-entry clickable">' + '   <canvas class="forecast-entry__curve"></canvas>' + '   <div class="forecast-entry__upper-label"></div>' + '       <div class="forecast-entry__weather-icon"></div>' + '   <div class="forecast-entry__lower-label"></div>' + '</div>');
        };

        return {
            getTimelineElement: getTimelineElement
        };
    }(); //endregion Static

    constructor(delegate, entry) {
        super(WeatherForecastEntry.Template.getTimelineElement());
        this.delegate = delegate;
        this.entry = entry;
    }

    viewDidLoad() {
        var promise = super.viewDidLoad(...arguments);
        this.elements = {
            canvas: this.element.find('.forecast-entry__curve'),
            title: this.element.find('.forecast-entry__upper-label'),
            icon: this.element.find('.forecast-entry__weather-icon'),
            lowerText: this.element.find('.forecast-entry__lower-label')
        };
        return promise;
    }

    viewDidAppear() {
        var promise = super.viewDidAppear(...arguments); // Don't use Hammer.js or LxButton, it will completely destroy the Android UX

        this.element.on('click', this._handleClick.bind(this));

        this._setContent(this.entry, true);

        return promise;
    }

    viewDidDisappear() {
        this.element.off('click');
        return super.viewDidDisappear(...arguments);
    }

    update(entry) {
        this._setContent(entry);
    }

    setSelected(active) {
        this.element.toggleClass("forecast-entry-selected", active); // There is an issue with a specific version of Nexus devices that crash the app, thats why we might not have a canvas

        this.element.toggleClass("forecast-entry-selected__canvas-error", active && hasCanvasError);
    }

    /**
     * Sets the content of the Timeline entry, also checks if a repaint of the canvas is needed
     * @param entry
     * @param forceRepaint
     * @private
     */
    _setContent(entry, forceRepaint) {
        var repaintIsNeeded = this._isRepaintNeeded(entry);

        this.entry = entry;

        if (repaintIsNeeded || forceRepaint) {
            GUI.animationHandler.schedule(function () {
                this.elements.title.text(this.entry.title);
                this.elements.icon.html(ImageBox.getResourceImageWithClasses(this.entry.weatherIcon, 'weather-icon__icon'));
                this.elements.lowerText.text(Math.round(this.entry.temperature) + "°");
            }.bind(this));

            this._drawTempGraph();
        }
    }

    /**
     * Check properties which are important for the graph (all temperature related properties)
     * @param newEntry
     * @returns {boolean}
     * @private
     */
    _isRepaintNeeded(newEntry) {
        return !this.entry || this.entry.inTemp !== newEntry.inTemp || this.entry.outTemp !== newEntry.outTemp || this.entry.temperature !== newEntry.temperature || this.entry.forecastMaxTemp !== newEntry.forecastMaxTemp || this.entry.forecastMinTemp !== newEntry.forecastMinTemp || this.entry.weatherIcon !== newEntry.weatherIcon || this.entry.title !== newEntry.title;
    }

    _handleClick() {
        this.delegate.onForecastEntrySelected && this.delegate.onForecastEntrySelected.call(this.delegate, this, this.entry);
    }

    /**
     * Drawing the graph
     * @private
     */
    _drawTempGraph() {
        // Now draw the canvas if possible
        // There is an issue with a specific version of Nexus devices that crash the app
        if (!hasCanvasError) {
            var canvas = this.elements.canvas[0];
            GUI.animationHandler.schedule(function () {
                checkAndPrepareCanvasContextForRetina(canvas);
                var ctx = canvas.getContext("2d");

                var middlePointY = this._getY(this.entry.temperature);

                ctx.fillStyle = applyAlphaChannelToColorString(Color.APP_BACKGROUND_SECONDARY, 0.8);
                ctx.strokeStyle = Color.APP_BACKGROUND_SECONDARY;
                ctx.lineJoin = "round";
                ctx.lineWidth = 2;
                ctx.strokeWidth = 2;
                ctx.beginPath();
                ctx.moveTo(0, this._getY(this.entry.inTemp));
                ctx.lineTo(GUI.WeatherForecastEntry.DIMENSIONS.WIDTH / 2, middlePointY);
                ctx.lineTo(GUI.WeatherForecastEntry.DIMENSIONS.WIDTH, this._getY(this.entry.outTemp));
                ctx.stroke();
                ctx.lineTo(GUI.WeatherForecastEntry.DIMENSIONS.WIDTH, GUI.WeatherForecastEntry.DIMENSIONS.HEIGHT);
                ctx.lineTo(0, GUI.WeatherForecastEntry.DIMENSIONS.HEIGHT);
                ctx.fill();
                ctx.closePath();
            }.bind(this));
        }
    }

    /**
     * Returns the Y-Position for the given Temperature
     * @param temp
     * @returns {number}
     * @private
     */
    _getY(temp) {
        var paddingTempRange = this.entry.forecastMaxTemp - this.entry.forecastMinTemp,
            paddingTemp = Math.ceil(paddingTempRange / 5),
            paddedMaxTemp = this.entry.forecastMaxTemp + paddingTemp,
            paddedMinTemp = this.entry.forecastMinTemp - paddingTemp,
            tempRange = paddedMaxTemp - paddedMinTemp;
        return GUI.WeatherForecastEntry.DIMENSIONS.HEIGHT / (tempRange / (paddedMaxTemp - temp));
    }

}

GUI.WeatherForecastEntry = WeatherForecastEntry;
/**
 * This is used in WeatherScreenV2 to calculate the scroll position
 * @type {{HEIGHT: number, WIDTH: number}}
 */

GUI.WeatherForecastEntry.DIMENSIONS = {
    HEIGHT: 106,
    WIDTH: 72
};
