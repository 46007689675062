'use strict';

define(['DaytimerControlEnums'], function (DaytimerControlEnums) {
    return class DaytimerControlContentOperatingModes extends Controls.ControlContent {
        constructor(details) {
            super(details);
            this.isEditMode = details.isEditMode === true;

            if (!this.isEditMode) {
                this.selectedModes = cloneObjectDeep(details.entry.equalModes);
            }

            this.miniserverModes = SandboxComponent.getStructureManager().getOperatingModes(true); // when true, it returns the whole list

            this.usedModesChanged = false; // indicates if a further mode has changed to a used mode

            this.listOrderChanged = false; // indicates if the order of used modes has changed
        }

        viewDidLoad() {
            var promise = super.viewDidLoad(...arguments);
            this.tableViewDataSource = tableViewDataSource(null, null, this);
            this.tableViewDelegate = tableViewDelegate(null, this);

            this._updateContent();

            this.tableView = new GUI.EditableTableView(this.tableViewDataSource, this.tableViewDelegate);
            this.tableView.getElement().addClass("operating-modes__table-view");
            this.appendSubview(this.tableView);
            return promise;
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        getURL() {
            return "OperatingModes";
        }

        titleBarActionRight() {
            this._userConfirmedChanges();
        }

        titleBarText() {
            if (this.isEditMode) {
                return _("controls.daytimer.modes.title.list-order");
            } else {
                return _("controls.daytimer.modes.title.selection");
            }
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.CLOSE,
                rightSide: TitleBarCfg.Button.TICK,
                style: 'transparent'
            };
        }

        receivedStates(states) {
            if (!this.entries) {
                this.usedModes = states.usedModes != null ? cloneObjectDeep(states.usedModes) : []; // empty array if no modes are used

                if (this.isEditMode) {
                    var i = 0;
                    this.weekDays = [];

                    while (i < this.usedModes.length) {
                        if (this._isWeekDay(this.usedModes[i])) {
                            var weedDayNr = this.usedModes.splice(i, 1)[0];
                            this.weekDays.push(weedDayNr);
                        } else {
                            i++;
                        }
                    }
                }

                this.entries = cloneObjectDeep(states.entries); // empty object if no entries are existing

                this._detectFurtherModes();

                this._reloadList();
            }
        }

        // PrepareContent
        _updateContent() {
            this.tableContent = [];
            var content = [],
                usedModes = {
                    rows: []
                },
                furtherModes = {},
                customModes = {
                    rows: []
                },
                dataSourceChanged = false,
                delegateChanged = false;

            if (this.usedModes && this.usedModes.length) {
                this.usedModes.forEach(function (modeNr, rowNr) {
                    if (this._isWeekDay(modeNr)) {
                        usedModes.rows.push(this._createUsedModeCell(modeNr, rowNr));
                    } else {
                        customModes.rows.push(this._createUsedModeCell(modeNr, rowNr));
                    }
                }.bind(this));
                customModes.headerTitle = _("controls.daytimer.op-mode.used");
                this.tableContent.push(customModes);
                this.tableContent.push(usedModes);
            }

            if (this.furtherModes && this.furtherModes.length) {
                furtherModes.rows = this._createFurtherModeCells();
                furtherModes.headerTitle = _("controls.daytimer.op-mode.other");
                this.tableContent.push(furtherModes);
            }

            dataSourceChanged = this.tableViewDataSource.update(this.tableContent);
            delegateChanged = this.tableViewDelegate.update(this.tableContent);
            return dataSourceChanged || delegateChanged;
        }

        _createUsedModeCell(modeNr, rowNr) {
            var row = {};
            row.type = GUI.TableViewV2.CellType.CHECKABLE;
            row.content = {
                title: this.miniserverModes[modeNr]
            };

            if (!this.isEditMode) {
                row.content.selected = this._isModeInCurrentEntry(modeNr);
                row.content.radioMode = GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE;
                row.content.checkable = true;
            }

            if (!this._isWeekDay(modeNr)) {
                row.content.subtitle = _("controls.daytimer.op-mode.priority", {
                    priority: rowNr + 1
                });
            }

            row.didCheckCell = function (cell, section, row, tableView, selected) {
                this._handleCellChecked(selected, modeNr);
            }.bind(this);

            return row;
        }

        _handleCellChecked(selected, modeNr) {
            // update current entry
            if (selected) {
                this.selectedModes.push(modeNr);
            } else {
                var index = this._isModeInCurrentEntry(modeNr, true);

                this.selectedModes.splice(index, 1);
            } // update current using modes


            if (selected && !this._isModeInUsedModes(modeNr)) {
                // add to current using modes when list type is edit or mode has no other entries until now
                this._addToUsedModes(modeNr); // forces the reload

            }
        }

        _createFurtherModeCells() {
            var rows = [];
            this.furtherModes.forEach(function (modeNr, rowNr) {
                rows.push(this._createFurtherModeCell(modeNr, rowNr));
            }.bind(this));
            return rows;
        }

        _createFurtherModeCell(modeNr, rowNr) {
            var row = {};

            if (this.isEditMode) {
                row.type = GUI.TableViewV2.CellType.GENERAL;
                row.content = {
                    leftIconSrc: "resources/Images/General/button-plus.svg",
                    leftIconColor: window.Styles.colors.green,
                    title: this.miniserverModes[modeNr],
                    clickable: true
                };

                row.action = function () {
                    this._addToUsedModes(modeNr);
                }.bind(this);
            } else {
                row.type = GUI.TableViewV2.CellType.CHECKABLE;
                row.content = {
                    title: this.miniserverModes[modeNr],
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE
                };
            }

            row.didSelectCell = function (cell, section, row) {
                this._addToUsedModes(modeNr);
            }.bind(this);

            row.didCheckCell = function (cell, section, row, tableView, selected) {
                this._handleCellChecked(selected, modeNr);
            }.bind(this);

            return row;
        }

        // TableViewDelegate methods
        styleForTableView() {
            return TableViewStyle.GROUPED;
        }

        didRequestRemovingCell(section, row) {
            return true; // always remove immediately without a warning.
        }

        didRemoveCell(section, row) {
            var modeNr = this.usedModes[row];

            this._removeFromUsingModes(modeNr);
        }

        didMoveCell(section, row, tableView, cell, oldIdx, newIdx) {
            this.listOrderChanged = true;
            var modeNr = this.usedModes.splice(oldIdx, 1)[0];
            this.usedModes.splice(newIdx, 0, modeNr);

            this._reloadList();
        }

        // Private functions
        _reloadList() {
            this._detectFurtherModes();

            var prms;

            if (this._updateContent()) {
                prms = this.tableView.reload();
            } else {
                prms = Q.resolve();
            }

            return prms.then(function () {
                if (this.usedModes) {
                    var counter = 0;

                    for (var i = 0; i < this.usedModes.length; i++) {
                        if (this._isWeekDay(this.usedModes[i])) {
                            break;
                        }

                        counter++;
                    }

                    this.tableView.setEditingModeForSection(0, true, this.isEditMode, true, 0, counter);
                }
            }.bind(this));
        }

        _addToUsedModes(modeNr) {
            this.usedModesChanged = true;
            this.usedModes.unshift(modeNr);

            this._reloadList();
        }

        _removeFromUsingModes(modeNr) {
            this.usedModesChanged = true;
            var index = this.usedModes.indexOf(modeNr);
            this.usedModes.splice(index, 1);

            this._reloadList();
        }

        _detectFurtherModes() {
            // detect it
            var furtherModes = [];

            for (var modeNr in this.miniserverModes) {
                if (this.miniserverModes.hasOwnProperty(modeNr)) {
                    if (!this._isModeInUsedModes(modeNr)) {
                        furtherModes.push(modeNr);
                    }
                }
            } // reset arrays if necessary


            if (furtherModes.length) {
                this.furtherModes = furtherModes;
            } else {
                this.furtherModes = null;
            }
        }

        _isModeInUsedModes(modeNr) {
            if (this.usedModes && this.usedModes.indexOf(modeNr) !== -1) {
                return true;
            } else if (this._isWeekDay(modeNr)) {
                return true;
            }

            return false;
        }

        _isModeInCurrentEntry(modeNr, indexNeeded) {
            for (var i = 0; i < this.selectedModes.length; i++) {
                if (modeNr === this.selectedModes[i]) {
                    return indexNeeded ? i : true;
                }
            }

            return false;
        }

        _isWeekDay(modeNr) {
            return parseInt(modeNr) >= 3 && parseInt(modeNr) <= 9;
        }

        _userConfirmedChanges() {
            if (this.usedModesChanged || this.listOrderChanged) {
                var newModeList = this.usedModes === null ? [] : this.usedModes;

                if (this.isEditMode) {
                    newModeList = newModeList.concat(this.weekDays);
                } // TODO-sulzean use array toString()


                var commandString = '';

                for (var i = 0; i < newModeList.length; i++) {
                    commandString += newModeList[i];

                    if (i < newModeList.length - 1) {
                        commandString += ',';
                    }
                } // send the new sorted operating mode list


                if (this.control.type === DaytimerControlEnums.ControlType.DAYTIMER) {
                    this.sendCommand(Commands.format(Commands.DAYTIMER.SET_MODES_LIST, commandString));
                } else if (this.control.type === DaytimerControlEnums.ControlType.IRC_DAYTIMER) {
                    var cmd;

                    if (this.control.details.isCooling) {
                        cmd = Commands.format(Commands.DAYTIMER.SET_MODES_LIST_C, commandString);
                    } else {
                        cmd = Commands.format(Commands.DAYTIMER.SET_MODES_LIST, commandString);
                    } // Wrike 48797388 - use uuidAction from Parent for IRC Daytimers!


                    this.sendCommand(cmd, null, this.control.uuidParent);
                } else if (this.control.type === DaytimerControlEnums.ControlType.POOL_DAYTIMER || this.control.type === DaytimerControlEnums.ControlType.IRCV2_DAYTIMER) {
                    this.sendCommand(Commands.format(Commands.DAYTIMER.SET_MODES_LIST, commandString), null, this.control.uuidParent);
                }
            }

            if (this.isEditMode) {
                this.ViewController.navigateBack();
            } else {
                this.ViewController.navigateBack(false, {
                    equalModes: this.selectedModes
                });
            }
        }

    };
});
