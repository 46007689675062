/**
 * Keeps working off a task on a periodic basis, one after another.
 * Once one task is finished, the timeout will start to trigger the next run.
 */
class PeriodicTaskRunner {
    static DefaultInterval = 60 * 1000;
    static ShowLog = false;

    /**
     * Creates & starts a periodic task with a one-liner that can be stopped by calling the returned fn.
     * @param taskFn    the job to do
     * @param interval  the interval to do the job.
     * @returns {(function(): void)|*}
     */
    static run(taskFn, interval= PeriodicTaskRunner.DefaultInterval) {
        let instance = new PeriodicTaskRunner(taskFn, interval);
        instance.start();
        return () => {
            instance.stop();
            instance = null;
        }
    }

    constructor(taskFn, interval = PeriodicTaskRunner.DefaultInterval) {
        this._interval = interval;
        this._taskFn = taskFn;
        this._timeout = null;
        this._started = false;
    }

    get started() {
        return this._started;
    }

    set taskFn(newVal) {
        this._taskFn = newVal;
    }

    start() {
        PeriodicTaskRunner.ShowLog && console.log("PeriodicTaskRunner", "start");
        this._started = true;
        return this._doWork();
    }

    stop() {
        PeriodicTaskRunner.ShowLog && console.log("PeriodicTaskRunner", "stop");
        this._started = false;
        this._stopTimeout();
    }

    force() {
        return this._doWork();
    }

    _doWork() {
        PeriodicTaskRunner.ShowLog && console.log("PeriodicTaskRunner", "_doWork");
        this._stopTimeout();
        return Q(this._taskFn()).finally(() => {
            PeriodicTaskRunner.ShowLog && console.log("PeriodicTaskRunner", "_doWork > done");
            // restart if not stopped or already restarted (e.g. due to a force in the meantime);
            this._started && !this._timeout && this._scheduleNext();
        })
    }

    _scheduleNext() {
        PeriodicTaskRunner.ShowLog && console.log("PeriodicTaskRunner", "_scheduleNext");
        this._timeout = setTimeout(this._doWork.bind(this), this._interval);
    }

    _stopTimeout() {
        PeriodicTaskRunner.ShowLog && console.log("PeriodicTaskRunner", "_stopTimeout");
        this._timeout && clearTimeout(this._timeout);
        this._timeout = null;
    }


}

export default PeriodicTaskRunner;