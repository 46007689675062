'use strict';

define(['ControlActionCellsScreen', 'PoolControlEnums'], function (ControlActionCellsScreen, PoolControlEnums) {
    return class PoolCoverScreen extends ControlActionCellsScreen {
        constructor() {
            super(...arguments);
        }

        viewDidLoad() {
            let promise = super.viewDidLoad(...arguments);

            return promise.then(() => {
                this._prepareLiveIcon();
    
                this.setTitle(_("pool.control.cover"));
                return promise;
            });
        }

        getStateIcon(states) {
            // 22 is the width of the part we need to animate
            var value = 22 * (states.coverPosition - 1);
            this.poolCover.transform("t" + value);
            return this.snapContainer.outerSVG();
        }

        getStateColor(states) {
            var isMoving = states.direction !== Direction.NOT_MOVING;
            return isMoving ? window.Styles.colors.stateActive : Color.WHITE;
        }

        getSmallIcon(states) {
            return null;
        }

        getStateIDs() {
            return ["coverPosText"];
        }

        receivedStates(states) {
            super.receivedStates(...arguments);
            this.setSubtitle(states.coverPosText);
            this.element.toggleClass("pool-cover-screen--active", states.direction !== Direction.NOT_MOVING);
        }

        getActionSection() {
            var section = {
                rows: []
            };
            section.rows.push(this._createActionCell(_("open"), Commands.POOL.COVER_OPEN));
            section.rows.push(this._createActionCell(_("close"), Commands.POOL.COVER_CLOSE));
            return section;
        }

        getStateRows(states) {
            return null;
        }

        getAdditionalSection() {
            return null;
        }

        _createActionCell(title, cmd) {
            var cmdObj = {
                name: title,
                cmd: cmd
            };
            return this._createCellObjectForCmd(cmdObj);
        }

        _prepareLiveIcon() {
            this._prepareSnapContainer(Icon.Pool.COVER_CLIPPATH);

            this.poolCover = this.snapContainer.select("#a");
        }

        _prepareSnapContainer(iconSrc) {
            this.snapContainer = Snap($(ImageBox.getResourceImage(iconSrc))[0]);
        }

    };
});
