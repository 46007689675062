import {useEffect, useState} from "react";
import {navigatorConfig} from "LxComponents";

export default function useDeviceSearchTitleBar(navigation, route) {
    const searchType = route.params.searchType;
    const miniserver = route.params.miniserver;
    const extension = route.params.extension;
    const branch = route.params.branch;
    const deviceTypeFilter =  route.params.deviceTypeFilter;
    const deviceTypeName =  route.params.deviceTypeName;


    const detectCurrentTitle = () => {
        let title;
        if (deviceTypeFilter) {
            title = deviceTypeName;

        } else if (branch && branch.name) {
            title = branch.name;

        } else if (extension && extension.name) {
            title = extension.name;

        } else if (miniserver && miniserver.name) {
            title = miniserver.name;
        } else {
            title = ActiveMSComponent.getTitleForDeviceSearchType(searchType);
        }
        return title;
    }

    const [title, setTitle] = useState(detectCurrentTitle);

    useEffect(() => {
        setTitle(detectCurrentTitle());
    }, [searchType, miniserver, extension, branch]);

    navigation.setOptions(
        navigatorConfig({
            animationType: AnimationType.PUSH_OVERLAP_LEFT,
            title: title
        })
    );
}