import {View} from "react-native";
import {LxReactText} from "LxComponents";
import globalStyles from "GlobalStyles";
import {useRef} from "react";

function ControlHistoryCell({entries}) {
    const columnWidthInfo = useRef({
        itemsCompared: 0,
        maxWidth: useAmPm() ? 115 : 80
    }).current;

    const EntryText = ({entry}) => {
        let txtParts = [],
            ctrl;

        if (nullEmptyString(entry.what)) {
            txtParts.push(entry.what);
        }

        let triggerText = nullEmptyString(entry.trigger);
        switch (entry.triggerType.toLowerCase()) {
            case "user":
                txtParts.push(_("control.history.by-user"));
                break;
            case "centralgw":
                // this type is provided if the MS has no info about the causing control, so we hope the app
                // may have the control in it's list.
                ctrl = ActiveMSComponent.getControlByUUID(entry.triggerUuid);
                if (ctrl) {
                    txtParts.push(_("control.history.generic-by"));
                    txtParts.push(ctrl.getControlTypeName());
                    triggerText = "'" + ctrl.getName() + "'";
                } else if (triggerText) {
                    txtParts.push(_("control.history.by-function-block"));
                } else {
                    txtParts.push(_("control.history.by-gateway-central-command"));
                }
                break;
            case "none":
                // special case where no trigger should be listed.
                triggerText = null;
                break;
            case "automaticrule":
            case "scene":
            case "control":
            case "device":
            case "generic":
            default:
                triggerText && txtParts.push(_("control.history.generic-by"));
                break;
        }

        if (triggerText) {
            txtParts = [txtParts.join(" "), " "];
            txtParts.push(<LxReactText style={styles.triggerLabel}>{triggerText}</LxReactText>);
        } else {
            txtParts = txtParts.join(" ");
        }

        return <LxReactText style={styles.messageLabel}>{txtParts}</LxReactText>
    }

    const EntryImpacts = ({entry}) => {
        return (entry.impacts || []).map(line => {
            return <LxReactText style={styles.impact}>{line}</LxReactText>
        })
    }

    const HistoryEntry = ({entry, key}) => {
        Debug.ControlHistory && console.log(ControlHistoryCell.name, "HistoryEntry: " + JSON.stringify(entry));
        let date = entry.timestamp.format(LxDate.getTimeFormat());

        return <View style={styles.outerContainer}>
            <View style={styles.innerContainer}>
                <View style={{minWidth: columnWidthInfo.maxWidth, maxWidth: columnWidthInfo.maxWidth}}>
                    <LxReactText style={styles.dateLabel}>{date}</LxReactText>
                </View>
                <View style={styles.messageContainer}>
                    <EntryText entry={entry} />
                    <EntryImpacts entry={entry} />
                </View>
            </View>
        </View>
    }

    return <View style={styles.container}>
        {entries && entries.map((entry, idx) => <HistoryEntry entry={entry} key={idx}/>)}
    </View>
}

const styles = {
    outerContainer: {
        justifyContent: "center",
        width: "100%",
        marginVertical: 14
    },
    innerContainer: {
        flexDirection: "row"
    },
    container: {
        marginVertical: globalStyles.spacings.gaps.small,
        width: "100%"
    },
    dateLabel: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.secondary,
        marginRight: globalStyles.spacings.gaps.verySmall,
        display: "flex",
    },
    messageContainer: {
        paddingHorizontal: globalStyles.spacings.gaps.verySmall,
        textAlign: "left",
        flexDirection: "column",
        display: "flex",
        flex: 1
    },
    messageLabel: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.primary,
        paddingHorizontal: globalStyles.spacings.gaps.verySmall,
        paddingBotom: globalStyles.spacings.gaps.verySmall,
        textAlign: "left"
    },
    triggerLabel: {
        ...globalStyles.textStyles.body.bold,
        color: globalStyles.colors.text.primary
    },
    impact: {
        ...globalStyles.textStyles.body.italic,
        color: globalStyles.colors.text.secondary,
        paddingHorizontal: globalStyles.spacings.gaps.verySmall,
        paddingTop: globalStyles.spacings.gaps.verySmall,
        textAlign: "left"
    }
}

export default ControlHistoryCell
