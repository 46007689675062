'use strict';

window.GUI = function (GUI) {
    class MediaCell extends GUI.TableViewV2.Cells.BaseCell {
        constructor() {
            super(...arguments);
            this.element.addClass("loading-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.contentPlaceholder.append(GUI.TableViewV2.Cells.LoadingCell.Template.getContent());
        }

        clickableAllowed() {
            return false;
        }

    }

    GUI.TableViewV2.Cells.LoadingCell = MediaCell;

    GUI.TableViewV2.Cells.LoadingCell.Template = function () {
        var getContent = function getContent() {
            return '<div class="content__spinner css-spinner-a"></div>';
        };

        return {
            getContent: getContent
        };
    }();

    GUI.TableViewV2.CellType.Special.LOADING = "LoadingCell";
    return GUI;
}(window.GUI || {});
