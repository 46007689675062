'use strict';

define("LightCentralControl", ["LightControl"], function (LightControl) {
    return class LightCentralControl extends LightControl {
        constructor() {
            super(...arguments);
        }

        /**
         * If true, controls of this type MAY use the rooms name instead of its own. E.g. this is important for light
         * controllers, heating controllers and other room based controls.
         */
        controlTypeUsesRoomName() {
            return false; // derived from LightControl --> but a central object won't show the room name!
        }

        getCentralIcon() {
            return Icon.Light.LIGHT_CONTROL;
        }

        getButton0() {// no button in light central
        }

    };
});
