import Svg, { Path } from "react-native-svg"

function Sun(props) {
    return (
        <Svg isreact="true"
            x="0px"
            y="0px"
            viewBox="0 0 30 30"
            xmlSpace="preserve"
            enableBackground="new 0 0 30 30"
            {...props}
        >
            <Path d="M15 20.526c.335 0 .606.271.606.606v2.262a.606.606 0 11-1.212 0v-2.262c0-.335.271-.606.606-.606zm-3.907-1.618a.605.605 0 010 .857l-1.599 1.599a.605.605 0 11-.857-.857l1.599-1.599a.605.605 0 01.857 0zM9.474 15a.606.606 0 01-.606.606H6.606a.606.606 0 110-1.212h2.262c.334 0 .606.271.606.606zm1.619-3.908a.605.605 0 01-.857 0l-1.6-1.599a.605.605 0 11.857-.857l1.599 1.599a.606.606 0 01.001.857zM15 9.474a.606.606 0 01-.606-.606V6.606a.606.606 0 111.212 0v2.262a.606.606 0 01-.606.606zm3.908 1.618a.605.605 0 010-.857l1.599-1.599a.605.605 0 11.857.857l-1.599 1.599a.605.605 0 01-.857 0zM20.526 15c0-.335.271-.606.606-.606h2.262a.606.606 0 110 1.212h-2.262a.606.606 0 01-.606-.606zm-1.618 3.908a.605.605 0 01.857 0l1.599 1.599a.605.605 0 11-.857.857l-1.599-1.599a.605.605 0 010-.857zM15 10.758a4.242 4.242 0 100 8.483 4.242 4.242 0 000-8.483z" />
        </Svg>
    )
}

export default Sun
