import React from "react";
import {View, TouchableOpacity} from 'react-native';
import { useHD, useLiveState, LxReactText, LxReactImageView } from "LxComponents"
import PropTypes from "prop-types";
import globalStyles from "GlobalStyles"

function WeatherComp({uuidAction, tintColor, dimensions, containerStyle, showWeatherAdButton = true, isInAmbientMode = false}) {
    let weatherStates;

    weatherStates = useLiveState(uuidAction);

    const isHD = useHD();

    const weatherStyle = {
        flexDirection: "row",
        alignItems: "center",
        width: "fit-content"
    }

    const _getContent = () => {
        let weatherTextStyle = isInAmbientMode ? styles.ambientLabel : styles.label;
        if (weatherStates && weatherStates.states) {
            if (weatherStates.states.hasValidData) {
                return (<View style={weatherStyle}>
                    {_getWeatherIcon()}
                    <LxReactText style={weatherTextStyle}>
                        {lxFormat("%d°", weatherStates.states.currentTemperature)}
                    </LxReactText>
                </View>)
            } else if (showWeatherAdButton) {
                return <LxReactImageView key={"weatherAdButton"} source={Icon.Weather.AD_ICON_NEW}
                    //imageStyle={{ height: dimensions.height, width: dimensions.width}}/>
                                         imageStyle={{ height: 35, width: 35}}/>
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    const _getWeatherIcon = () => {
        let CurrentIcon = weatherStates.states.forecasts.today[0].reactWeatherIcon
        let iconStyle = isInAmbientMode ? styles.ambientIcon : styles.icon;
        // Attention, there is a bug in some LoxBerry installation that leads to a weatherType = 0
        // which is invalid so we don't have an icon for that.
        // not checking on if we have an icon will result in a blackscreen!
        if ((isHD || isInAmbientMode)  && CurrentIcon) {
            return <CurrentIcon
                key={"weatherButton"}
                fill={globalStyles.colors.white}
                style={iconStyle}
                {...iconStyle}
            />
        }
    }

    const _handleIconPress = () => {
        if (weatherStates && weatherStates.states && weatherStates.states.hasValidData) {
            NavigationComp.showState(ScreenState.Weather);
        } else {
            NavigationComp.showState(ScreenState.WeatherAd);
        }
    }

    return (
        <TouchableOpacity
            style={containerStyle}
            onPress={_handleIconPress}
        >
            {_getContent()}
        </TouchableOpacity>
    )
}

const styles = {
    label: {
        color: globalStyles.colors.white,
        fontSize: 21
    },
    ambientLabel: {
        ...globalStyles.customStyles.ambientTextShadow,
        ...globalStyles.textStyles.largeTitle.default,
        color: globalStyles.colors.white,
        marginLeft: globalStyles.spacings.gaps.smallRegular
    },
    icon: {
        height: globalStyles.sizes.icons.veryBig,
        width: globalStyles.sizes.icons.veryBig
    },
    ambientIcon: {
        height: globalStyles.sizes.icons.weatherIcon,
        width: globalStyles.sizes.icons.weatherIcon
    },
    innerContainer: {

    }
}

WeatherComp.propTypes = {
    uuidAction: PropTypes.string.isRequired,
    tintColor: PropTypes.string,
    dimensions: PropTypes.object,
    containerStyle: PropTypes.object,
    showWeatherAdButton: PropTypes.bool,
    isInAmbientMode: PropTypes.bool
}

export default WeatherComp;
