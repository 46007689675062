'use strict';

class WaitingScreen extends GUI.Screen {
    constructor(details) {
        super($('<div />'));
        this.message = details.message;
        this.cancelButtonText = details.cancelButtonText;
        this.callbackDefer = details.callbackDefer;
        this.waitingPromise = details.waitingPromise;
        this.noBackNavigationOnPromiseRejection = details.noBackNavigationOnPromiseRejection || false;
    }

    shouldBeUpdated() {
        return false;
    }

    updateView(details) {
        super.updateView(...arguments);
        this.message = details.message;
        this.cancelButtonText = details.cancelButtonText;
        this.callbackDefer = details.callbackDefer;
        this.waitingPromise = details.waitingPromise;
        var config = {
            message: this.message,
            iconSrc: Icon.INDICATOR,
            noCircle: true,
            elementIsNotClickable: true
        };
        this.backGroundView.update(config);
        this.waitingPromise.then(function (result) {
            this.callbackDefer.resolve(result);
        }.bind(this), function (error) {
            return this._onError(error);
        }.bind(this));
    }

    viewDidLoad() {
        return super.viewDidLoad(...arguments).then(function () {
            this.backGroundView = new GUI.BackgroundView(Icon.INDICATOR, null, this.message, this.cancelButtonText, this._cancelButtonAction.bind(this), true, true);
            this.appendSubview(this.backGroundView);
            this.waitingPromise.then(function (result) {
                this.callbackDefer.resolve(result);
            }.bind(this), function (error) {
                this._onError(error);
            }.bind(this));
        }.bind(this));
    }

    getAnimation() {
        return AnimationType.FADE;
    }

    titleBarText() {
        return NBR_SPACE;
    }

    closeAction() {
        return this._cancelButtonAction();
    }

    _onError(error) {
        if (this.noBackNavigationOnPromiseRejection) {
            this.callbackDefer.reject(error);
        } else {
            return this.ViewController.navigateBack().then(function () {
                this.callbackDefer.reject(error);
            }.bind(this));
        }
    }

    _cancelButtonAction() {
        this.ViewController.navigateBack().then(function () {
            this.callbackDefer.reject();
        }.bind(this));
    }

}

GUI.WaitingScreen = WaitingScreen;
