'use strict';

define("AudioZoneV2ControlContentHD", ["ControlActionBaseScreen", "AudioZoneV2ControlEnums", "AudioZoneV2ControlContent", "ControlContentMenu"], function (ControlActionBaseScreen, AudioZoneV2ControlEnums, AudioZoneV2ControlContent, ControlContentMenu) {
    return class AudioZoneV2ControlContentHD extends ControlActionBaseScreen {
        //region Static
        static Template = function () {
            var getBody = function getBody() {
                return $('' + '<div class="audio-zone-v2-control-contenthd__container">' + '   <div class="container__detailed"></div>' + '   <div class="container__master"></div>' + '</div>' + '');
            };

            return {
                getBody: getBody
            };
        }(); //endregion Static

        constructor() {
            super(...arguments);
            this._customizationKey = null;
        }

        viewDidLoad() {
            var vdlPrms = [Q(super.viewDidLoad(arguments) || true)],
                initShowArgs = ControlContentMenu.shared(this.control).getInitialHDDetailedShowStateArgs();
            this.element.append(AudioZoneV2ControlContentHD.Template.getBody());

            if (!this.elements) {
                this.elements = {};
            }

            this.masterScreen = this.getMasterScreen(); // Don't use "this.appendSubview" we need to await the vdl and vwa promises!

            vdlPrms.push(this.addToHandledSubviews(this.masterScreen));
            this.elements.masterContainer = this.element.find('.container__master');
            this.elements.masterContainer.append(this.masterScreen.getElement()); // Overwrite the showState function to navigate in the "this.detailedScreen"

            this._ogMasterShowState = this.masterScreen.ViewController.showState;
            this.masterScreen.ViewController.showState = this.handleMasterShowState.bind(this);
            this._ogMaster_updateTableContent = this.masterScreen._updateTableContent;

            this.masterScreen._updateTableContent = function _updateTableContent() {
                if (this._customizationKey) {
                    this._setCellSelected(this._customizationKey);
                }

                return this._ogMaster_updateTableContent.apply(this.masterScreen, arguments);
            }.bind(this);

            return Q.all(vdlPrms).then(function () {
                Q(initShowArgs).then(function (args) {
                    this.detailedScreen = this.getDetailedScreen(initShowArgs); // Don't use "this.appendSubview" we need to await the vdl and vwa promises!

                    return this.addToHandledSubviews(this.detailedScreen).then(function () {
                        this.elements.detailedContainer = this.element.find('.container__detailed');
                        this.elements.detailedContainer.append(this.detailedScreen.getElement());

                        if (args[2] && args[2].customizationKey) {
                            this._setCellSelected(args[2].customizationKey);
                        }
                    }.bind(this));
                }.bind(this));
                return Q(true);
            }.bind(this));
        }

        viewDidAppear() {
            return super.viewDidAppear(...arguments).then(function () {
                if (this.ViewController._cachedStartItem) {
                    return this.ViewController.navigateToItem(this.ViewController._cachedStartItem);
                } else {
                    return Q.resolve();
                }
            }.bind(this));
        }

        getCustomInfoView() {
            return null; // We don't want an infoView here!
        }

        getMasterScreen() {
            return new AudioZoneV2ControlContent({
                control: this.control
            });
        }

        getDetailedScreen(initShowArgs) {
            return new GUI.AudioZoneV2ControlDetailedViewController({
                showStateArgs: [ScreenState.AudioZoneV2DetailedContentVC, // This is the actual VC we present our content and navigate in
                    null, {
                        showStateArgs: initShowArgs,
                        control: this.control
                    }, AnimationType.NONE // Set animationType to none, to immediately show the screens without any animation!
                ],
                control: this.control
            }); // This is just a container ViewController to help us with the ViewManagement
        }

        handleMasterShowState(identifier, view, details, animationType, forceParent) {
            if (GUI.AudioZoneV2ControlContentViewController.shouldHandleInControlContentVc(identifier) || forceParent) {
                return this._ogMasterShowState.apply(this.masterScreen.ViewController, arguments);
            } else {
                // The customizationKey is part of the showState details (3rd parameter of arguments)
                if (arguments[2] && arguments[2].customizationKey) {
                    this._setCellSelected(arguments[2].customizationKey);
                } // Check if we need to show the screen


                if (this.detailedScreen && this.detailedScreen.currentView && this.detailedScreen.currentView.currentView && this.detailedScreen.currentView.currentView.constructor.name === identifier.split(".").splice(-1)[0] && JSON.stringify(this.detailedScreen.currentView.currentView.details) === JSON.stringify(details)) {
                    console.info(this.name, identifier + " is already shown with the same details, won't navigate!");

                    return Q.resolve();
                } else {
                    return this.detailedScreen.showState.apply(this.detailedScreen, [ScreenState.AudioZoneV2DetailedContentVC, null, {
                        showStateArgs: arguments,
                        control: this.control
                    }, AnimationType.FADE]);
                }
            }
        }

        _setCellSelected(key) {
            this._customizationKey = key;
            ControlContentMenu.shared(this.control).setCellSelected(key);
        }

    };
});
