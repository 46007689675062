'use strict';
/**
 * onHardwareButtonUsed .. method called when either vol up or down was hit.
 */

define('HardwareVolumeButtonHandlerV2', [], function () {
    Controls.AudioZoneV2Control.SingleTones = Controls.AudioZoneV2Control.SingleTones || {};

    if (Controls.AudioZoneV2Control.SingleTones.HardwareVolumeButtonHandlerV2) {
        return Controls.AudioZoneV2Control.SingleTones.HardwareVolumeButtonHandlerV2;
    } else {
        class HardwareVolumeButtonHandlerV2 {
            //region Static
            static shared() {
                if (!this.__instance) {
                    this.__instance = new this(this);
                }

                return this.__instance;
            }

            static destroy() {
                this.__instance && this.__instance.destroy();
                delete this.__instance;
            } //endregion Static


            constructor(initiator, control) {
                applyMixins(this, EventHandler.Mixin, StateHandler.Mixin);

                if (!(initiator instanceof Function) && !Controls.AudioZoneV2Control.SingleTones.HardwareVolumeButtonHandlerV2) {
                    throw "The HardwareVolumeButtonHandlerV2 is a Singletone, use it like that! -> HardwareVolumeButtonHandlerV2.shared()";
                }

                this.onAudioserverVolumeChangeTimer = new AntiGhostTimer(function () {
                    if (window.hasOwnProperty("volumeControl") && this._isRegistered && this._osVolume !== this.states.volume / 100) {
                        this._osVolume = this.states.volume / 100;
                        Debug.Control.AudioZone.VolumeControl && console.log(this.name, "Setting Volume from Audioserver after Change ->" + this._osVolume); //volumeControl.setVolume(this._osVolume);
                    }
                }.bind(this), 300);
                this.onSystemVolumeChangeTimer = new AntiGhostTimer(function () {
                    this._audioserverVolumeChangeLocked = false;
                }.bind(this), 300);
                this.boundOnPause = this._pauseiOSVolumeListener.bind(this);
                this.boundOnResume = this._startiOSVolumeListener.bind(this);
                this.platform = PlatformComponent.getPlatformInfoObj().platform;
            }

            start(control) {
                this.name = "HardwareVolumeButtonHandlerV2";
                Debug.Control.AudioZone.VolumeControl && console.log(this.name, "start");

                if (!control) {
                    throw "The control needs to be specified in order to support HW-Buttons!";
                }

                this.name += " - " + control.getName();

                if (this.control && this.control.uuidAction !== control.uuidAction) {
                    Debug.Control.AudioZone.VolumeControl && console.log(this.name, "control did change, stopping previous registration");
                    this.stop();
                }

                this.control = control;

                if (!this._isRegistered) {
                    Debug.Control.AudioZone.VolumeControl && console.log(this.name, "start > _isRegistered = false, first register for states.");

                    this._registerForStates();

                    if (this.platform === PlatformType.IOS) {
                        if (!this._isAirplayActive()) {
                            this._osVolume = this.states.volume / 100;
                            Debug.Control.AudioZone.VolumeControl && console.log(this.name, "initializing -> " + this._osVolume);
                            /*volumeControl.init({
                                volume: this._osVolume,
                                hideVolumeNotification: true
                            }, this._onAbsoluteOSVolumeChange.bind(this));*/
                        } // Register to prevent setting the iOS system volume to the audioserver once closing the app, changing the volume and reopen again


                        document.addEventListener("pause", this.boundOnPause, false);
                        document.addEventListener("resume", this.boundOnResume, false);
                    } else {
                        // register all the HW-Buttons immediately. If otherwise they won't work in the TinyPlayerV2 if as long as
                        // the control slider didn't popup up.
                        // store the bound references to properly remove them later on.
                        this.boundHwDownButtonUsed = this._hwVolumeDownButtonUsed.bind(this);
                        this.boundHwUpButtonUsed = this._hwVolumeUpButtonUsed.bind(this);
                        document.addEventListener("volumedownbutton", this.boundHwDownButtonUsed, false);
                        document.addEventListener("volumeupbutton", this.boundHwUpButtonUsed, false);
                    }

                    this._isRegistered = true;
                } else {
                    Debug.Control.AudioZone.VolumeControl && console.log(this.name, "omit, already registered!");
                }
            }

            stop() {
                Debug.Control.AudioZone.VolumeControl && console.log(this.name, "stop");

                try {
                    if (this.platform === PlatformType.IOS) {
                        this._pauseiOSVolumeListener();

                        document.removeEventListener("pause", this.boundOnPause, false);
                        document.removeEventListener("resume", this.boundOnResume, false);
                    } else {
                        // remove the previously stored bound hw button callbacks
                        document.removeEventListener("volumedownbutton", this.boundHwDownButtonUsed, false);
                        document.removeEventListener("volumeupbutton", this.boundHwUpButtonUsed, false);
                    }
                } catch (ex) {
                    console.error(this.name, "stop - caught exception while trying to stop HW volume button handler! " + ex.message);
                }

                if (this.control) {
                    this._unregisterStates();
                }

                this._isRegistered = false;
                this.control = null;
            }

            /**
             * Called when an extension is destroyed
             */
            destroy() {
                Debug.Control.AudioZone.VolumeControl && console.log(this.name, "destroy");
                this.stop();
                this.onAudioserverVolumeChangeTimer.resetAntiGhostTimer();
                this.onSystemVolumeChangeTimer.resetAntiGhostTimer();
                this.onAudioserverVolumeChangeTimer.destroy();
                this.onSystemVolumeChangeTimer.destroy();
                Debug.Control.AudioZone.VolumeControl && console.log(this.name, "destroy");
            }

            receivedStates(states) {
                Debug.Control.AudioZone.VolumeControl && console.log(this.name, "receivedStates: " + JSON.stringify(getStack()));
                this.states = states;

                if (this.platform === PlatformType.IOS) {
                    if (this._isAirplayActive()) {
                        this.stop();
                        this._shouldRebind = true;
                    } else {
                        if (this._shouldRebind) {
                            this._shouldRebind = false;
                            this.start();
                        }

                        if (this._audioserverVolumeChangeLocked) {
                            this.onAudioserverVolumeChangeTimer.resetAntiGhostTimer();
                        } else {
                            this.onAudioserverVolumeChangeTimer.fire();
                        }
                    }
                }
            }

            // ---------------------------------------------------------------------
            _hwVolumeDownButtonUsed() {
                Debug.Control.AudioZone.VolumeControl && console.log(this.name, "_hwVolumeDownButtonUsed");

                this._sendVolumeChange(true);

                this.onHardwareButtonUsed && this.onHardwareButtonUsed(this, true);
            }

            _hwVolumeUpButtonUsed() {
                Debug.Control.AudioZone.VolumeControl && console.log(this.name, "_hwVolumeUpButtonUsed");

                this._sendVolumeChange(false);

                this.onHardwareButtonUsed && this.onHardwareButtonUsed(this, false);
            }

            /**
             * Will check if the volume step feature is already available. if so the step cmd is sent, if not, the absolute
             * volume level is computed and set.
             * @param decrease  if true, the volume is to be decreased.
             * @private
             */
            _sendVolumeChange(decrease) {
                var cmd;

                if (decrease) {
                    cmd = MusicServerEnum.AudioCommands.VOLUME.DOWN_BY + this.states.volumeStep;
                } else {
                    cmd = MusicServerEnum.AudioCommands.VOLUME.UP_BY + this.states.volumeStep;
                }

                this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                    cmd: cmd
                });
            }

            _onAbsoluteOSVolumeChange(osVol) {
                this._osVolume = parseFloat(osVol.toFixed(2)); // Convert "0.07999999821186066" to "0.08"

                var intVol = parseInt((this._osVolume * 100).toFixed(0)); // Convert "28.000000000000004" to 28

                Debug.Control.AudioZone.VolumeControl && console.log(this.name, "OSVolumeChange ->" + this._osVolume + " = " + intVol + "%");

                if (intVol !== this.states.volume) {
                    this.states.volume = intVol;
                    this.onAudioserverVolumeChangeTimer.resetAntiGhostTimer();
                    this._audioserverVolumeChangeLocked = true;
                    this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                        cmd: MusicServerEnum.AudioCommands.VOLUME.SET_TO + intVol
                    });
                    this.onSystemVolumeChangeTimer.fire();
                }
            }

            _pauseiOSVolumeListener() {
                if (window.hasOwnProperty("volumeControl")) {//Debug.Control.AudioZone.VolumeControl && console.log(this.name, "Destroying VolumeHandler!");
                    //volumeControl.destroy();
                }
            }

            _startiOSVolumeListener() {
                if (this._isRegistered && !this._isAirplayActive()) {
                    this._osVolume = this.states.volume / 100;
                    Debug.Control.AudioZone.VolumeControl && console.log(this.name, "Initializing again after app reset ->" + this.states.volume + "/100 = " + this._osVolume);
                    /*volumeControl.init({
                        volume: this._osVolume,
                        hideVolumeNotification: true
                    }, this._onAbsoluteOSVolumeChange.bind(this));*/
                }
            }

            _isAirplayActive() {
                var isApActive = false;

                if (this.control && this.control.getStates()) {
                    isApActive = this.control.getStates().audiopath === "airplay";
                } else if (!this.control) {
                    console.warn(this.name, "_isAirplayActive failed - defaulting to false, no control provided!");
                } else {
                    console.warn(this.name, "_isAirplayActive failed - defaulting to false, no states provided!");
                }

                Debug.Control.AudioZone.VolumeControl && console.log(this.name, "_isAirplayActive: " + !!isApActive);
                return isApActive;
            }

        }

        Controls.AudioZoneV2Control.SingleTones.HardwareVolumeButtonHandlerV2 = HardwareVolumeButtonHandlerV2;
        return Controls.AudioZoneV2Control.SingleTones.HardwareVolumeButtonHandlerV2;
    }
});
