import {createStateCell} from "../../UtilityComp/utilities/helper";

export default class InfoViewControlStatesSrc {
    static getStateSections(control, states) {
        let sections = [],
            section = {},
            rows = [];

        if (states.stateText !== "") {
            rows.push(createStateCell(_("status"), states.stateText, null, null, null, states.stateTextColor));
        }

        section.rows = rows;
        sections.push(section);

        return sections;
    }
}