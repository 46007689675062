'use strict';

define(["IRoomControlEnums"], function (IRoomControlEnums) {
    return class IRoomControlContentPeriod extends Controls.ControlContent {
        constructor(details) {
            super(details);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            var periodViewElements = $(PeriodViewTemplate.getTemplate());
            this.element.append(periodViewElements);
        }

        getURL() {
            return "Period";
        }

        titleBarText() {
            return _("controls.irc.heating.period");
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK,
                style: 'transparent'
            };
        }
    };

    var PeriodViewTemplate = function () {
        var getTemplate = function getContent() {
            return '<div></div>';
        };

        return {
            getTemplate: getTemplate
        };
    }();
});
