'use strict';

define(['ControlActionCellsScreen'], function (ControlActionCellsScreen) {
    return class ControlActionSliderScreen extends ControlActionCellsScreen {
        constructor() {
            super(...arguments);
        }

        viewDidLoad() {
            var promise = super.viewDidLoad(...arguments);
            var states = this.control.getStates();
            this.sliderActionView = new GUI.LxVerticalSliderButtonView(this.getSliderConfig(states)); // register callback

            this.sliderActionView.onPositionChanged = this._handleValueChanged.bind(this);
            this.sliderActionView.onPositionChanging = this._handleOngoingValueChange.bind(this);
            return promise.then(() =>
                this.insertSubviewAfter(
                    this.sliderActionView,
                    this.actionTableView.getElement(),
                ).then(() => {
                    return promise;
                }),
            );
        }

        /**
         * This function returns the config of the slider, it is crucial to implement it!
         * @param [states] The control states - if there are any already
         * @returns {{min, max, step, antiGhostTimer: boolean}}
         */
        getSliderConfig(states) {
            throw new Error("Not implemented exception, please implement 'getSliderConfig' in your subclass!");
        }

        updateProperties(prop) {
            this.sliderActionView.updateProperties(prop);
        }

        receivedStates(states) {
            super.receivedStates(...arguments);
            this.sliderActionView.setPosition(states.pos);
            var newSliderConfig = this.getSliderConfig(states); // Only update the sliders properties if the properties changed

            if (JSON.stringify(this.sliderActionView.config) !== JSON.stringify(newSliderConfig)) {
                this.sliderActionView.updateProperties(newSliderConfig);
            } // Add the control specific commands


            this._updateCommandsTable(states);
        }

        /**
         * This method is called e.g. when the lock state changed, it is used to hide/show customized content added
         * by the subclasses. controlActionScreen also sets "customContentVisible" property.
         * @param visible
         */
        setCustomContentVisible(visible) {
            super.setCustomContentVisible(...arguments);
            this.toggleSubview(this.sliderActionView, visible);
        }

        // Private

        /**
         * Will send the value according to the command provided.
         * @param pos       the new slider position
         * @param isOngoing true if the slider is being dragged (unused in this class)
         * @private
         */
        _sendValue(pos, isOngoing) {
            // Directly display the new value when sending it to prevent any delay
            // AntiGhostTimer handles the rest (Must be specified in the sliderConfig)
            // In some formats the html entities will be encoded
            this.setSubtitle(lxFormat(this.control.details.format, pos).decodeHtmlEntities());
            this.sendCommand(Commands.format(Commands.SLIDER.VALUE, pos), Commands.Type.OVERRIDE);
        }

        _handleOngoingValueChange(pos) {
            this._sendValue(pos, true);
        }

        _handleValueChanged(pos) {
            this._sendValue(pos, false);
        }

    };
});
