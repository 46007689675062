import {useContext, useEffect, useMemo} from "react";
import {LxReactTableView, ControlContext, navigatorConfig, useSetURLHook} from "LxComponents";
import {useNavigation} from "@react-navigation/native";
import {View} from "react-native";
import globalStyles from "GlobalStyles";
import {AUTO_MODE, CAPABILITIES, SCREEN_STATE} from "../../climateControlEnums";
import ClimateControlUtils from "../climateControlUtils";

function LxSettingsScreen(props) {
    const {control, states} = useContext(ControlContext);
    const navigation = useNavigation();

    useSetURLHook({
        urlPart: "Settings",
    });

    useEffect(() => {
        navigation.setOptions({
            ...navigatorConfig({
                title: _('settings'),
                animationType: AnimationType.HD_OVERLAY
            })
        })
    }, [])

    // region Maintenance
    const getMaintenanceStateCell = () => {
        let nextMaintenance = getNextMaintenanceObject();

        return {
            content: {
                title: nextMaintenance.isPast ? _("controls.hourcounter.maintenance.overdue-since") : _("controls.hourcounter.maintenance.next-in"),
                disclosureText: nextMaintenance.humanReadable,
                disclosureColor: nextMaintenance.isPast ? globalStyles.colors.red : globalStyles.colors.green
            }
        };
    }

    const getMaintenanceActionCell = () => {
        return {
            content: {
                title: _("controls.hourcounter.maintenance.reset-interval"),
                titleColor: globalStyles.colors.brand
            },
            action: () => control.sendCommand(Commands.CLIMATE_CONTROLLER.RESET_MAINTENANCE)
        };
    }

    /**
     * Returns the maintenance object with a human readable string when the next maintenance is/was,
     * to know if the maintenance is in the past check the isPast property
     * @return {{humanReadable: *, isPast: *}}
     * @private
     */
    const getNextMaintenanceObject = () => {
        let nextMaintenance = moment.unix(states.nextMaintenance),
            humanReadable = nextMaintenance.fromNow(true),
            // true suppresses the prefix "in" or suffix "ago"
            past = nextMaintenance.isBefore(moment());
        return {
            humanReadable: humanReadable,
            isPast: past
        };
    }

    // endregion

    // region Temperature Boundary Selection
    const getTemperatureBoundarySection = () => {
        let section = {};

        if (states.isAvgAutomatic) {
            section = {
                headerStrongTitle: ClimateControlUtils.getTemperatureBoundarySectionHeader(control.details.capabilities),
                headerDescription: ClimateControlUtils.getTemperatureBoundarySectionDescription(states.autoMode),
                rows: [{
                    content: {
                        title: getTemperatureBoundaryCellTitle(),
                        disclosureIcon: true
                    },
                    action: openBoundarySelector.bind(this)
                }]
            };
        }

        return section;
    }

    const getTemperatureBoundaryCellTitle = () => {
        let hasHeatingAndCooling = control.details.capabilities === CAPABILITIES.HEATING_AND_COOLING;
        return _("controls.climate.set.temp-boundary", {
            count: hasHeatingAndCooling ? 2 : 1
        });
    }

    const openBoundarySelector = () => {
        navigation.navigate(SCREEN_STATE.TEMPS, {control});
    }

    // endregion

    // region Automation Selection
    const getSelectAutomaticSection = () => {
        return {
            customClass: 'no-space',
            headerStrongTitle: control.getAutoModeDescription(states),
            headerDescription: ClimateControlUtils.getAutomaticSectionDescription(control.details.capabilities, states.isAvgAutomatic, control.modeByLogic),
            rows: getAutomaticRows()
        };
    }

    const getAutomaticRows = () => {
        let rows = [],
            setByLogic = control.modeByLogic;

        if (control.details.capabilities === CAPABILITIES.HEATING_AND_COOLING) {
            rows = [{
                content: {
                    title: _("controls.climate.allow.heating-and-cooling"),
                    selected: states.autoMode === AUTO_MODE.HEATING_AND_COOLING,
                    disabled: setByLogic
                },
                type: GUI.TableViewV2.CellType.CHECKABLE,
                didCheckCell: onAutoModeSelect.bind(this, AUTO_MODE.HEATING_AND_COOLING)
            }, {
                content: {
                    title: _("controls.climate.allow.heating"),
                    selected: states.autoMode === AUTO_MODE.HEATING_ONLY,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                    disabled: setByLogic
                },
                type: GUI.TableViewV2.CellType.CHECKABLE,
                didCheckCell: onAutoModeSelect.bind(this, AUTO_MODE.HEATING_ONLY)
            }, {
                content: {
                    title: _("controls.climate.allow.cooling"),
                    selected: states.autoMode === AUTO_MODE.COOLING_ONLY,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                    disabled: setByLogic
                },
                type: GUI.TableViewV2.CellType.CHECKABLE,
                didCheckCell: onAutoModeSelect.bind(this, AUTO_MODE.COOLING_ONLY)
            }];
        }
        if (Feature.CLIMATE_CONTROLLER_OFF) {
            // if there is no differentiation between the heating/cooling, simply add an "on" option that puts it into
            // full auto mode.
            if (rows.length === 0) {
                rows.push({
                    content: {
                        title: _("on"),
                        selected: states.autoMode === AUTO_MODE.HEATING_AND_COOLING,
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                        disabled: setByLogic
                    },
                    type: GUI.TableViewV2.CellType.CHECKABLE,
                    didCheckCell: onAutoModeSelect.bind(this, AUTO_MODE.HEATING_AND_COOLING)
                });
            }

            rows.push({
                content: {
                    title: ClimateControlUtils.getOffTextForCapability(control.details.capabilities),
                    selected: states.autoMode === AUTO_MODE.OFF,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                    disabled: setByLogic
                },
                type: GUI.TableViewV2.CellType.CHECKABLE,
                didCheckCell: onAutoModeSelect.bind(this, AUTO_MODE.OFF)
            })
        }

        return rows;
    }

    const onAutoModeSelect = (mode) => {
        control.sendCommand(Commands.format(Commands.CLIMATE_CONTROLLER.AUTO_MODE, mode));
    }

    // endregion

    const tableContent = useMemo(() => {
        let content = [];

        if (states) {
            if (Feature.FULL_CLIMATE) {
                content.push(getSelectAutomaticSection());
                content.push(getTemperatureBoundarySection());
            }

            if (states.nextMaintenance > 0) {
                content.push({
                    headerStrongTitle: _('controls.climate.settings.filter-change'),
                    rows: [getMaintenanceStateCell(), getMaintenanceActionCell()]
                });
            }
        }

        return content;
    }, [control.details, states.autoMode, states.isAvgAutomatic, control.modeByLogic, states.nextMaintenance])

    return <LxReactTableView tableContent={tableContent}/>
}

export default LxSettingsScreen
