'use strict';

define(["NotificationsSettingsBaseScreen"], function (NotificationsSettingsBaseScreen) {
    return class PushNotificationsSettingsScreen extends NotificationsSettingsBaseScreen {
        constructor() {
            super($('<div class="push-notifications-screen" />'));
            this._permissionsGranted = false;
            this._permissionChecked = false;
            this.dbgName = "PNS " + this.viewId
        }

        viewDidAppear() {
            pushNotificationService.checkPushPermissions().then(res => {
                Debug.PushNotification &&console.log(this.dbgName, "viewDidAppear - _isPushEnabledForMiniserver - true, permission granted!")
                this._permissionsGranted = true;
            }, (err) => {
                Debug.PushNotification && console.log(this.dbgName, "viewDidAppear - _isPushEnabledForMiniserver - true, permission not granted!")
                this._permissionsGranted = false;
            }).finally(() => {
                this._permissionChecked = true;
                this.reloadTable();
            });
            return super.viewDidAppear(...arguments);
        }

        viewDidLoad() {
            var mac = ActiveMSComponent.getActiveMiniserver().serialNo,
                allPrms = [super.viewDidLoad(...arguments) || true];
            return Q.all(allPrms).then(function () {
                Debug.PushNotification && console.log("PNS (PushNotificationsSettingsScreen)", "viewDidLoad - checkForMs: " + mac);
                return this._isPushEnabledForMiniserver(mac).then(function () {
                    Debug.PushNotification && console.log("PNS (PushNotificationsSettingsScreen)", "viewDidLoad - checkForMs - resolved");
                    return this._registeredForPush();
                }.bind(this), function (err) {
                    Debug.PushNotification && console.log("PNS (PushNotificationsSettingsScreen)", "viewDidLoad - checkForMs - rejected. " + JSON.stringify(err));
                    return true;
                });
            }.bind(this));
        }

        getSettings() {
            // true? We force to fetch the settings from the Miniserver
            return SandboxComponent.getPushNotificationSettings(true);
        }

        getURL() {
            return UrlStartLocation.PUSH_NOTIFICATIONS;
        }

        reloadTable() {
            var enabled = this._getSwitchState();

            this.tableContent = []; // WARN: KEEP SECTIONS IN MIND WHEN MOVING STUFF AROUND! (cellForSectionAndRow)

            this.tableContent.push({
                    rows: [{
                        // keep in mind that this must stay at index 0/0! (see action)
                        type: GUI.TableViewV2.CellType.SWITCH,
                        content: {
                            title: _('notifications.activate-push-notifications'),
                            active: !!enabled,
                            disabled: !this._permissionChecked || !this._permissionsGranted
                        },
                        onSwitchChanged: function onSwitchChanged(value, section, row, tableView) {
                            this._togglePushEnabled(value);
                        }.bind(this)
                    }, {
                        content: {
                            subtitle: _('notifications.push-notifications.help')
                        }
                    }]
                });

            if (this._permissionChecked && !this._permissionsGranted) {
                this.tableContent[0].footerTitle = _("notifications.enable-first");
                this.tableContent[0].footerColor = window.Styles.colors.red;
                this.tableContent[0].didSelectFooter = this._showNotificationSettings.bind(this)
            }

            return super.reloadTable(enabled);
        }

        // Private methods
        _registeredForPush(requestSettingsAgain) {
            //this.tableView.cellForSectionAndRow(0, 0).setActive(true);
            Debug.PushNotification && console.log("PNS (PushNotificationsSettingsScreen)", "_registeredForPush, requestSettingsAgain=" + !!requestSettingsAgain);
            return this.getSettings().then(function (settings) {
                this._settings = settings;
                return this.reloadTable();
            }.bind(this));
        }

        _unregisteredForPush() {
            // set all settings to false before reload --> in base needed
            Object.keys(this._settings).forEach(function (key) {
                this._settings[key] = false;
            }.bind(this));
            this.reloadTable().then(function () {
                delete this._settings;
            }.bind(this));
        }

        // Private Methods
        _changeSetting(setting, enabled) {
            Debug.PushNotification && console.log("PNS (PushNotificationsSettingsScreen)", "changing setting '" + setting + "' to " + enabled);
            this._settings[setting] = enabled;
            SandboxComponent.updatePushNotificationSettings(this._settings);

            if (!this._getSwitchState()) {
                this._togglePushEnabled(false);

                this.reloadTable();
            }
        }

        _togglePushEnabled(value) {
            if (value) {
                this._isPushEnabledForApp(true).done(function () {
                    NavigationComp.showWaitingFor(this._registerMiniserver4Push(ActiveMSComponent.getActiveMiniserver().serialNo).then(function (id) {
                        this._registeredForPush(true); // true -> request settings again!
                        //show Screen leading to Xiaomi Device Settings


                        var platformObj = PlatformComponent.getPlatformInfoObj();

                        if (platformObj.platform === PlatformType.Android && platformObj.manufacturer === "Xiaomi" && !platformObj.arePlayservicesAvailable) {
                            var importantContent = {
                                title: _('notification.xiaomi.title'),
                                message: _('notification.xiaomi.message'),
                                buttons: [{
                                    title: _('notification.xiaomi.button-text'),
                                    color: window.Styles.colors.green
                                }, {
                                    title: _('cancel'),
                                    color: Color.STATE_INACTIVE
                                }]
                            };
                            this._enableImportantPopup = NavigationComp.showPopup(importantContent); // store ref to be able to remove it (avoid multiple popups!)

                            this._enableImportantPopup.then(function (index) {
                                delete this._enableImportantPopup;

                                if (index === 0) {
                                    //intent to device settings
                                    pushNotification.goToXiaomiNotificationSettings();
                                }
                            }.bind(this));
                        }
                    }.bind(this), function (e) {
                        GUI.Notification.createGeneralNotification({
                            title: "Push Notifications registration failed!",
                            subtitle: e,
                            removeAfter: 3
                        }, NotificationType.ERROR); // set back switch

                        this.reloadTable();
                    }.bind(this)), _('please-wait'), _('notifications.registering'));
                }.bind(this), function (err) {
                    Debug.PushNotification && console.log(this.dbgName, "_togglePushEnabled - rejected, " + JSON.stringify(err));
                    var content = {
                        title: _('caution'),
                        message: _('notifications.enable-first'),
                        buttonOk: _('okay'),
                        icon: Icon.CAUTION
                    };
                    NavigationComp.showPopup(content).done(function () {
                        Debug.PushNotification && console.log(this.dbgName, "_togglePushEnabled - rejected, popup confirmed!");
                        // set back switch
                        this.reloadTable();
                    }.bind(this));
                }.bind(this));
            } else {
                NavigationComp.showWaitingFor(this._unregisterMiniserver4Push(ActiveMSComponent.getActiveMiniserver().serialNo).then(function (res) {
                    // update table
                    this._unregisteredForPush();
                }.bind(this), function (e) {
                    GUI.Notification.createGeneralNotification({
                        title: "Unregistered from Push Notifications failed!",
                        subtitle: e,
                        removeAfter: 3
                    }, NotificationType.ERROR); // set back switch

                    this.reloadTable();
                }.bind(this)));
            }
        }

        //region PushService access

        _shouldMockPushService() {
            return PlatformComponent.getPlatformInfoObj().platform === PlatformType.DeveloperInterface;
        }

        _isPushEnabledForApp(askUserForActivation) {
            if (this._shouldMockPushService()) {
                console.warn(this.dbgName, "_isPushEnabledForApp - mock, resolve")
                return Q.resolve();
            } else {
                return pushNotificationService.isPushEnabledForApp(askUserForActivation);
            }
        }

        _isPushEnabledForMiniserver(serialNo) {
            if (this._shouldMockPushService()) {
                Debug.PushNotification &&console.warn(this.dbgName, "_isPushEnabledForMiniserver - mock, resolve")
                return Q.resolve();
            } else {
                return pushNotificationService.isPushEnabledForMiniserver(serialNo);
            }
        }

        _registerMiniserver4Push(serialNo) {
            if (this._shouldMockPushService()) {
                console.warn(this.dbgName, "_registerMiniserver4Push - mock, resolve")
                return Q.resolve("1231242123");
            } else {
                Debug.PushNotification && console.log(this.dbgName, "_registerMiniserver4Push - verify permissions first!");
                return pushNotificationService.verifyPushPermissions().then(() => {
                    Debug.PushNotification && console.log(this.dbgName, "_registerMiniserver4Push - permission verified!");
                    return pushNotificationService.registerMiniserver4Push(serialNo);
                }, (err) => {
                    Debug.PushNotification && console.err(this.dbgName, "_registerMiniserver4Push - permission denied! " + JSON.stringify(err));
                    return Q.reject(err);
                })
            }
        }

        _unregisterMiniserver4Push(serialNo) {
            if (this._shouldMockPushService()) {
                console.warn(this.dbgName, "_unregisterMiniserver4Push - mock, resolve")
                return Q.resolve();
            } else {
                return pushNotificationService.unregisterMiniserver4Push(serialNo);
            }
        }

        _getSwitchState() {
            let ogState = super._getSwitchState(...arguments);
            Debug.PushNotification && console.log("PNS (PushNotificationsSettingsScreen)", "_getSwitchState: permission=" + this._permissionsGranted + ", setting=" + ogState);
            return ogState && this._permissionsGranted;
        }

        _showNotificationSettings() {
            Debug.PushNotification && console.log("PNS (PushNotificationsSettingsScreen)", "_showNotificationSettings");
            if (cordova && cordova.plugins && cordova.plugins.diagnostic) {
                var platformObj = PlatformComponent.getPlatformInfoObj();
                if (platformObj.platform === PlatformType.Android) {
                    //on android we can move straight to the notification settings
                    cordova.plugins.diagnostic.switchToNotificationSettings();
                } else {
                    //on ios there are is no direct way to the notification settings, just open the settings
                    cordova.plugins.diagnostic.switchToSettings();
                }
            }

        }

        //endregion PushService Mock

    };
});
