'use strict';

define("JalousieControlCommandSrc", ["ControlCommandSrc", "JalousieControlEnums"], function (ControlCommandSrc, JalousieControlEnums) {
    return class JalousieControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            var upText,
                downText,
                fullUpText,
                fullDownText,
                disabled = states.locked || states.safetyActive;

            if (control.isCurtain()) {
                upText = _('controls.jalousie.curtain.up');
                downText = _('controls.jalousie.curtain.down');
                fullUpText = _('controls.jalousie.curtain.up.full');
                fullDownText = _('controls.jalousie.curtain.down.full');
            } else if (control.isAwning()) {
                upText = _('controls.jalousie.awning.in');
                downText = _('controls.jalousie.awning.out');
                fullUpText = _('controls.jalousie.awning.in.full');
                fullDownText = _('controls.jalousie.awning.out.full');
            } else {
                upText = _('controls.jalousie.up');
                downText = _('controls.jalousie.down');
                fullUpText = _('controls.jalousie.up.full');
                fullDownText = _('controls.jalousie.down.full');
            } // TODO-goelzda implement active cell jalousieMode === auto_active

            let upPulseCmd, downPulseCmd;

            if (Feature.JALOUSIE_UP_DOWN_PULSE) {
                upPulseCmd = Commands.JALOUSIE.UP_PULSE;
                downPulseCmd = Commands.JALOUSIE.DOWN_PULSE;
            }

            let cmds = [
                this.createDeactivatable(upText, this.createCmdObj(Commands.JALOUSIE.UP, Commands.JALOUSIE.UP_OFF, upPulseCmd), control.getOpenIcon(), states.locked, { rowIdx: 0 }),
                this.createDeactivatable(downText, this.createCmdObj(Commands.JALOUSIE.DOWN, Commands.JALOUSIE.DOWN_OFF, downPulseCmd), control.getCloseIcon(), states.locked, { rowIdx: 0 }),
                this.createDeactivatable(fullUpText, Commands.JALOUSIE.FULL_UP, control.getOpenIcon(), disabled, { rowIdx: 1 }),
                this.createDeactivatable(fullDownText, Commands.JALOUSIE.FULL_DOWN, control.getCloseIcon(), disabled, { rowIdx: 1 }),
                this.createDeactivatable(_('controls.jalousie.shade'), Commands.JALOUSIE.SHADE, Icon.Jalousie.ComfortMode.SHADE_BUTTON, disabled)];

            if (control.isGrouped()) {
                cmds.forEach(cmd => {
                    delete cmd.icon;
                })
            }

            return cmds;
        }

    };
});
