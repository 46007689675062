import {
    LxReactQuickSelect,
    LxReactText,
    LxReactSelectorScreen
} from "LxComponents";
import globalStyles from "GlobalStyles";
import Icons from "IconLib";


const _getUserNameRegex = (currentUser, users) => {
    let existingUserNames = users
        .filter(user => user.uuid !== currentUser.uuid)
        .map(user => user.name).join("|")
        .replace(/[-[\]{}()*+?.,\\^$#\s]/g, '\\$&');

    return new RegExp(`^(?!(?:${existingUserNames})$).*`);
}

const _getGeneralSection = ({currentUser, users, isUserFromOtherTrustPeer, trustMember, allDataLoaded, updateCurrentUser, trustMemberName, localProperties, customFieldCaptions, userPropOptions, navigation}) => {
    let section = {
        headerTitle: _('adminIf.general.tab'),
        headerTitleStyle: headerTitleStyle,
        rows: []
    };
    let commonProps = {
        currentUser,
        updateCurrentUser,
        editable: true,
        optional: false,
        userPropOptions,
        navigation
    };

    if (allDataLoaded && !isUserFromOtherTrustPeer) {
        section.rows.pushObject(_getUserEditInputField(commonProps, localProperties.name, _('media.service.add.input.username'), {
            validationRegex: Regex.USERNAME,
            filterRegex: Regex.USERNAME_FILTER,
            validation: [
                {
                    regExp: _getUserNameRegex(currentUser, users),
                    message: _('invalid-input')
                },
                {
                    regExp: Regex.USERNAME,
                    message: _('invalid-input')
                }
            ]
        }));
    } else if (!allDataLoaded) {
        section.rows.push(_getDummyCell());
    }

    if (allDataLoaded) {
        if (Feature.USER_MANAGEMENT_USER_INFOS) {
            section.rows.pushObject(_getUserEditInputField(commonProps, localProperties.desc, _('description')));

            if (!isUserFromOtherTrustPeer) {
                section.rows.pushObject(_getUserEditInputField(commonProps, localProperties.firstName, _('firstname')));
                section.rows.pushObject(_getUserEditInputField(commonProps, localProperties.lastName, _('lastname')));
                section.rows.pushObject(_getUserEditInputField(commonProps, localProperties.email, _('email'),
                    { keyboardType: 'email-address', validation: [
                            { regExp: Regex.EMAIL_OR_EMPTY, message: _('invalid-input')}
                        ] }
                ));
                section.rows.pushObject(_getUserEditInputField(commonProps, localProperties.phone, _('phone-number'),
                    { keyboardType: 'phone-pad'} ));
            }
        }

        // Each MS within a trust stores its own NFC Code Touch ID, hence it can always be modified
        section.rows.pushObject(_getUserEditInputField(commonProps, localProperties.userid, _('user.user-id'),
            {
                validation:  [
                    {
                        regExp: Regex.NFC_CODE_TOUCH_ID,
                        message: _('invalid-input')
                    }

                ]}
        ));

        if (Feature.USER_MANAGEMENT_ADDITIONAL_USER_FIELDS) {
            if (!isUserFromOtherTrustPeer) {
                section.rows.pushObject(_getUserEditInputField(commonProps, localProperties.uniqueId, _("user.unique-id"),{ asynValidationOnEnd: (text) => {
                    return _validateUserIdOnBlur({currentUser, localProperties}, text);
                }}));
                section.rows.pushObject(_getUserPropSelectionField(commonProps, localProperties.company, _("user.company")));
                section.rows.pushObject(_getUserPropSelectionField(commonProps, localProperties.department, _("user.department")));
                section.rows.pushObject(_getUserEditInputField(commonProps, localProperties.personalno, _("user.personalno")));
                section.rows.pushObject(_getUserEditInputField(commonProps, localProperties.title, _("user.title")));
                section.rows.pushObject(_getUserEditInputField(commonProps, localProperties.debitor, _("user.debitor")));
            }

            let customFieldRows = _getCustomUserFields(commonProps, localProperties, customFieldCaptions);
            section.rows.splice(section.rows.length, 0, ...customFieldRows);
        }

    } else {
        section.rows.push(_getDummyCell());
        section.rows.push(_getDummyCell());
    }

    return section;
}

const _getTrustGeneralSection = ({currentUser, users, trustMember, allDataLoaded, updateCurrentUser, trustMemberName, localProperties, customFieldCaptions, userPropOptions, navigation}) => {
    const section = {
        headerTitle: _("usermanagement.trust.managed-by", {
            trustPeer: "@" + trustMemberName
        }),
        headerTitleStyle: headerTitleStyle,
        rows: []
    };

    let commonProps = {
        currentUser,
        updateCurrentUser,
        editable: false,
        optional: true,
        userPropOptions,
        navigation
    };

    if (allDataLoaded) {
        section.rows.pushObject(_getUneditableUserField(commonProps, localProperties.name, _('media.service.add.input.username')));
    } else {
        section.rows.push(_getDummyCell());
    }

    section.rows.push({
        content: {
            title: _("controls.intercom.about.audio.host"),
            disclosureText: "@" + trustMemberName,
            disclosureColor: globalStyles.colors.text.secondary
        }
    });

    if (allDataLoaded) {

        if (Feature.USER_MANAGEMENT_USER_INFOS) {
            // user infos from trust peers can only be viewed, not edited.
            section.rows.pushObject(_getUneditableUserField(commonProps, localProperties.firstName, _('firstname')));
            section.rows.pushObject(_getUneditableUserField(commonProps, localProperties.lastName, _('lastname')));
            section.rows.pushObject(_getUneditableUserField(commonProps, localProperties.email, _('email')));
            section.rows.pushObject(_getUneditableUserField(commonProps, localProperties.phone, _('phone-number')));
        }

        if (Feature.USER_MANAGEMENT_ADDITIONAL_USER_FIELDS) {
            section.rows.pushObject(_getUneditableUserField(commonProps, localProperties.uniqueId, _("user.unique-id")));
            section.rows.pushObject(_getUneditableUserField(commonProps, localProperties.company, _("user.company")));
            section.rows.pushObject(_getUneditableUserField(commonProps, localProperties.department, _("user.department")));
            section.rows.pushObject(_getUneditableUserField(commonProps, localProperties.personalno, _("user.personalno")));
            section.rows.pushObject(_getUneditableUserField(commonProps, localProperties.title, _("user.title")));
            section.rows.pushObject(_getUneditableUserField(commonProps, localProperties.debitor, _("user.debitor")));
        }
    } else {
        section.rows.push(_getDummyCell());
        section.rows.push(_getDummyCell());
    }
    return section;
}


    const _validateUserIdOnBlur = ({ currentUser, localProperties }, value) => {
    return ActiveMSComponent.getUserFromUniqueId(value).then((usrObj) => {
        if (usrObj.uuid === "" || usrObj.uuid === currentUser.uuid) {
            return true;
        } else {
            return _("user.error.non-unique-id") + " (" + usrObj.name + ")";
        }
    }, (ex) => {
        return "Failed, " + JSON.stringify(ex);
    })
}

/**
 *
 * @param currentUser
 * @param updateCurrentUser
 * @param localProperties
 * @param customFieldCaptions
 * @returns {*[]}
 * @private
 */
const _getCustomUserFields = ({currentUser, updateCurrentUser}, localProperties, customFieldCaptions) => {
    let customFieldRows = [],
        fieldProps = {currentUser, updateCurrentUser, editable: true, optional: false},
        propId;

    for (var i = 1; i <= 5; i++) {
        propId = "customField" + i;
        if (customFieldCaptions.hasOwnProperty(propId)) {
            customFieldRows.pushObject(_getUserEditInputField(fieldProps, localProperties[propId], customFieldCaptions[propId]));
        }
    }

    return customFieldRows
}

const _getUserEditInputField = ({currentUser, updateCurrentUser, editable = true, optional = false},
                                key, title, inputProps) => {
    if (editable) {
        return {
            type: GUI.TableViewV2.CellType.INPUT,
            content: {
                title: title,
                placeholder: currentUser[key] || title,
                value: currentUser[key],
                titleContainerStyle: {
                    maxWidth: '50%'
                },
                ...inputProps
            },
            onBlur: (value, validInput) => {
                const hasValidation = inputProps && (inputProps.asyncValidationOnEnd || inputProps.validation || inputProps.validationRegex)
                if (validInput || !hasValidation) {
                    updateCurrentUser(key, value);
                } else {
                    console.error("Invalid user property provided! " + value, validInput);
                }
            }
        };
    } else {
        return _getUneditableUserField({currentUser, optional}, key, title)
    }
}

const _getUserPropSelectionField = ({currentUser, updateCurrentUser, editable = true, optional = false, navigation, userPropOptions},
                                    key, title) => {
    if (editable) {
        let propOptions = (userPropOptions ? (userPropOptions[key] || []) : []).map((existing) => {
            return { id: existing, title: existing };
        });
        propOptions.push({id: "-none-", title: _("not-used")});

        return {
            content: {
                title: title,
                disclosureText: currentUser[key] || title,
                disclosureColor: nullEmptyString(currentUser[key]) ? globalStyles.colors.stateActive : globalStyles.colors.placeholder,
                mainCenterStyle: {
                    flexShrink: 0,
                },
                disclosureTextProps: {
                    numberOfLines: 1,
                }
            },
            action: () => {
                navigation && navigation.push(LxReactSelectorScreen.name, {
                    title: title,
                    options: propOptions,
                    selectedId: currentUser[key] || "-none-",
                    onSelected: (selection) => {
                        if (selection === "-none-") {
                            updateCurrentUser(key, "");
                        } else {
                            updateCurrentUser(key, selection);
                        }
                    },
                    createFn: (creation) => {
                        //updateCurrentUser(key, creation);
                        return { id: creation, name: creation };
                    },
                    autoClose: true
                });
            }
        };


    } else {
        return _getUneditableUserField({currentUser, optional}, key, title)
    }
}

const _getUneditableUserField = ({currentUser, optional}, key, title) => {
    if (!!nullEmptyString(currentUser[key]) || !optional) {
        return {
            content: {
                title: title,
                disclosureText: currentUser[key],
                disclosureColor: globalStyles.colors.placeholder,
                mainCenterStyle: {
                    flexShrink: 0,
                },
                disclosureTextProps: {
                    numberOfLines: 1,
                }
            }
        };
    } else {
        return null;
    }
}

const _getUserStateSection = ({currentUser, allDataLoaded, onUserStateChanged, onPickerChangedFn, onExpirationActionChanged, invalidUntilTime, isLastActiveAdmin}) => {
    let section = {
            headerTitle: _('status'),
            headerTitleStyle: headerTitleStyle,
            rows: []
        },
        fromPickerCell,
        untilPickerCell,
        options = [
            {
                title: _('active'),
                value: UserManagement.USER_STATES.ACTIVE
            },
            {
                title: _('user.inactive'),
                value: UserManagement.USER_STATES.DISABLED
            },
            {
                title: _('user.active-from'),
                value: UserManagement.USER_STATES.VALID_FROM
            },
            {
                title: _('user.active-until'),
                value: UserManagement.USER_STATES.VALID_UNTIL
            },
            {
                title: _('user.active-from-until'),
                value: UserManagement.USER_STATES.VALID_FROM_UNTIL
            }
        ],
        currentMsTime = ActiveMSComponent.getMiniserverTimeInfo().getSecondsSince2009(),
        validFrom = currentUser.validFrom || currentMsTime,
        validUntil = currentUser.validUntil || currentMsTime + 3600; // make the until time on hour later so the user doesn't get deativated immediately

    if (allDataLoaded) {
        section.rows.push({
            content: {
                title: _('status'),
                mainRightContent: {
                    comp: LxReactQuickSelect,
                    props: {
                        selectedValue: options.find(option => {
                            return option.value.toString() === currentUser.userState.toString();
                        }).value,
                        options: options,
                        titleStyle: {
                            fontSize: globalStyles.fontSettings.sizes.medium,
                            fontFamily: globalStyles.fontSettings.families.regular
                        },
                        tintColor: globalStyles.colors.text.secondary,
                        textColor: globalStyles.colors.green,
                        iconComp: Icons.ChevronsUpDown,
                        iconPosition: LxReactQuickSelect.IconPosition.Right,
                        onOptionSelected: (value, index, reject) => {
                            if (parseInt(value) !== UserManagement.USER_STATES.ACTIVE && isLastActiveAdmin) {
                                return Q(NavigationComp.showPopup({
                                    message: _("user.delete.last-admin.message"),
                                    buttonOk: true
                                })).then(() => {
                                    reject();
                                })
                            } else {
                                onUserStateChanged(parseInt(value));
                            }
                        }
                    }
                },
                //disabled: this.userIsLastPermanentActiveAdmin todo evaluate
            }
        });

        if (doesUserActivateLater(currentUser.userState)) {
            fromPickerCell = ActiveMSComponent.getDatePickerCell(currentUser, _('user.active-from'),
                new LxDate(validFrom, true), new LxDate(validUntil, true),
                true, invalidUntilTime, onPickerChangedFn);
            section.rows.push(fromPickerCell);
        }

        if (canUserExpire(currentUser.userState)) {
            untilPickerCell = ActiveMSComponent.getDatePickerCell(currentUser, _('user.active-until'),
                new LxDate(validFrom, true), new LxDate(validUntil, true),
                false, invalidUntilTime, onPickerChangedFn);
            section.rows.push(untilPickerCell);
            if (Feature.USER_EXPIRATION_ACTION) {
                section.rows.push(getExpirationActionRow(currentUser, onExpirationActionChanged))
            }
        }
    } else {
        section.rows.push(_getDummyCell());
    }


    return section;
}

export function canUserExpire(userState) {
    return [UserManagement.USER_STATES.VALID_UNTIL, UserManagement.USER_STATES.VALID_FROM_UNTIL].includes(userState)
}

export function doesUserActivateLater(userState) {
    return [UserManagement.USER_STATES.VALID_FROM, UserManagement.USER_STATES.VALID_FROM_UNTIL].includes(userState)
}

const getExpirationActionRow = (currentUser, onExpirationActionChanged) => {
    let expireOptions = [{
        title: _("user.deactivate.title"),
        value: UserManagement.EXPIRATION_ACTION.DEACTIVATE
    }, {
        title: _("user.delete.title"),
        value: UserManagement.EXPIRATION_ACTION.DELETE
    }]

    let hasValidAction = Object.values(UserManagement.EXPIRATION_ACTION).includes(currentUser.expirationAction)

    return {
        content: {
            title: _("user.expiration-action"),
            mainRightContent: {
                comp: LxReactQuickSelect,
                props: {
                    selectedValue: hasValidAction ? currentUser.expirationAction : UserManagement.EXPIRATION_ACTION.DEACTIVATE,
                    options: expireOptions,
                    noToggle: true,
                    titleStyle: {
                        fontSize: globalStyles.fontSettings.sizes.medium,
                        fontFamily: globalStyles.fontSettings.families.regular
                    },
                    tintColor: globalStyles.colors.text.secondary,
                    textColor: globalStyles.colors.green,
                    iconComp: Icons.ChevronsUpDown,
                    iconPosition: LxReactQuickSelect.IconPosition.Right,
                    onOptionSelected: (value, index, reject) => {
                        onExpirationActionChanged(parseInt(value))
                    }
                }
            }
        }
    }
}

const _getUserGroupSection = ({currentUser, isMasterUser, userIsLastPermanentAdmin, trustPeers, navigation, trustMemberName, allDataLoaded}) => {
    let details = null,
        section = {
            headerTitle: _('usergroups.headerTitle'),
            headerTitleStyle: headerTitleStyle,
            rows: []
        };
    section.rows = currentUser.usergroups.map(function (userGroup) {
        let groupTrustMemberName;
        if (userGroup.trustMember) {
            let trustMember = trustPeers.find(trustPeer => trustPeer.serial === userGroup.trustMember);
            if (trustMember) {
                groupTrustMemberName = "@" + trustMember.name
            } else {
                groupTrustMemberName = "@" + userGroup.trustMember
            }
        }

        return {
            content: {
                title: userGroup.name,
                subtitle: groupTrustMemberName,
                clickable: false,
                leftIconSrc: userGroup.trustMember ? Icons.TrustUserGroup : Icons.UserGroup,
                leftIconColor: userGroup.type === UserManagement.USER_GROUP_TYPE.FULL_ACCESS ? globalStyles.colors.orange : globalStyles.colors.stateActive
            }
        };
    }.bind(this));

    if (allDataLoaded) {
        details = {
            userGroups: cloneObject(currentUser.usergroups),
            // Clone to work with a new object in the UserGroupScreen
            isMasterUser,
            userIsLastPermanentAdmin,
            trustPeers
        };
        section.rows.push({
            content: {
                title: _('usergroups.select'),
                disclosureIcon: true,
                clickable: true
            },
            action: function () {
                navigation.navigate(ScreenState.UserGroupsScreen, details);
            }.bind(this)
        });
    } else {
        section.rows.push(_getDummyCell());
    }

    return section;
}


/**
 * Generates the security section for the user details table content helper.
 *
 * @param {Object} options - The options for generating the security section.
 * @param {boolean} options.isUserFromOtherTrustPeer - Indicates if the user is from another trust peer.
 * @param {string} options.trustMemberName - The name of the trust member.
 * @param {boolean} options.allDataLoaded - Indicates if all data is loaded.
 * @param {boolean} options.hasUserManagementPermissions - Indicates if the user has user management permissions.
 * @param {Object} options.currentUser - The current user object.
 * @param {string} options.originalUsername - The original username. This is passed from a ref to avoid running requests for user that might not exsist yet (i.e. when changing the username and password at the same time).
 * @param {function} options._updateCurrentUser - The function to update the current user.
 * @param {boolean} options.disableChangePassSwitch - Indicates if the change password switch is disabled.
 * @param {Object} options.navigation - The navigation object.
 * @returns {Object} The generated security section.
 */
const _getSecuritySection = ({isUserFromOtherTrustPeer, trustMemberName, allDataLoaded, hasUserManagementPermissions, currentUser, originalUsername, _updateCurrentUser, disableChangePassSwitch, navigation}) => {
    var section = {
        headerTitle: _("menu.settings.app.security.title", {
            count: 2
        }),
        headerTitleStyle: headerTitleStyle,
        // count is used to trigger the plural use of password
        rows: []
    };

    if (isUserFromOtherTrustPeer) {
        section.rows.push({
            content: {
                title: _('password'),
                subtitle: _("usermanagement.trust.managed-by", {
                    trustPeer: "@" + trustMemberName
                })
            }
        });

        if (hasUserManagementPermissions) {
            section.rows.push({
                content: {
                    title: _('visu-password'),
                    subtitle: _("usermanagement.trust.managed-by", {
                        trustPeer: "@" + trustMemberName
                    })
                }
            });
        }
    } else {
        section.rows.push(ActiveMSComponent.getPasswordCell({
            user: currentUser,
            originalUsername,
            navigation: navigation
        }));

        if (hasUserManagementPermissions) {
            section.rows.push(ActiveMSComponent.getPasswordCell({
                user: currentUser,
                originalUsername,
                isVisuPw: true,
                navigation: navigation
            }));
        }
    }

    if (Feature.USER_MANAGEMENT_REWORK && hasUserManagementPermissions && !isUserFromOtherTrustPeer) {
        if (allDataLoaded) {
            section.rows.push({
                type: GUI.TableViewV2.CellType.SWITCH,
                content: {
                    title: _('user.allow.changepass.title'),
                    active: currentUser.changePassword,
                    disabled: disableChangePassSwitch,
                    bottomContent: {
                        comp: LxReactText,
                        props: {
                            children: disableChangePassSwitch ? _('user.allow.changepass.footer.usermangement') : _('user.allow.changepass.footer'),
                            style: {
                                marginTop: globalStyles.spacings.gaps.small,
                                color: Color.TEXT_SECONDARY_B
                            }
                        }
                    }
                },
                onSwitchChanged: function onSwitchChanged(value) {
                    _updateCurrentUser("changePassword", value);
                }.bind(this)
            });
        } else {
            section.rows.push(_getDummyCell());
        }
    }

    return section;
}


// region nfcTagSection
const _getNfcSection = ({currentUser, isUserFromOtherTrustPeer, trustMemberName, navigation, hasAccessCode, allDataLoaded, nfcCodeTouches, allUsers}) => {
    let section = {
            headerTitle: _('search.controltype.nfccodetouch'),
            headerTitleStyle: headerTitleStyle,
            rows: []
        },
        hasNfcCodeTouchs = SandboxComponent.hasNfcCodeTouchs(); // only show if we have one or more NfcCodeTouch's!

    if (hasNfcCodeTouchs) {
        if (isUserFromOtherTrustPeer) {
            section.rows.push({
                content: {
                    title: _('user.access-code'),
                    subtitle: _("usermanagement.trust.managed-by", {
                        trustPeer: "@" + trustMemberName
                    })
                }
            });
        } else {
            section.rows.push(_getAccessCodeCell({
                hasAccessCode,
                currentUser,
                navigation
            }));
        }
    }

    if (Feature.ADD_NFC_TAG_OVER_APP && currentUser.nfcTags && currentUser.nfcTags.length) {

        currentUser.nfcTags.forEach(nfcTag => {
            section.rows.push(_createRowForTag({
                nfcTag,
                isUserFromOtherTrustPeer,
                trustMemberName,
                navigation,
                currentUser
            }))
        })
    }

    if (Feature.ADD_NFC_TAG_OVER_APP) {
        let nfcAddCell = _getNfcAddCell({
            nfcCodeTouches,
            navigation,
            currentUser,
            allUsers,
            allDataLoaded
        }); // only show if we have one or more NfcCodeTouch's!

        if (nfcAddCell) {
            if (allDataLoaded) {
                section.rows.push(nfcAddCell);
            } else {
                section.rows.push(_getDummyCell());
            }
        }
    }
    return !!section.rows.length ? section : null;
}

const _getAccessCodeCell = ({navigation, currentUser}) => {
    let title,
        rightIconSrc,
        leftIconSrc,
        leftIconColor,
        disclosureIcon,
        screenDetails = {
            user: currentUser,
            type: "accessCode",
        };

    if (Feature.USER_MANAGEMENT_REWORK && currentUser.keycodes && currentUser.keycodes.length) {
        title = _('user.access-code');
        screenDetails.codeExists = true;
        leftIconSrc = Icons.AccessCode;
        leftIconColor = globalStyles.colors.white;
    } else if (!Feature.USER_MANAGEMENT_REWORK) {
        title = _('user.access-code.change');
        disclosureIcon = true;
    } else {
        title = _('user.access-code.add');
        rightIconSrc = null;
        leftIconSrc = Icons.AccessCode;
        leftIconColor = globalStyles.colors.white;
        screenDetails.initSecretScore = UserManagement.EMPTY_PWD;
    }

    screenDetails.title = title;

    return {
        action: () => {
            navigation.navigate(ScreenState.ChangeSecretScreen, screenDetails);
        },
        content: {
            title: title,
            disclosureIcon: disclosureIcon,
            rightIconSrc: rightIconSrc,
            rightIconColor: Color.TEXT_SECONDARY_B,
            leftIconSrc: leftIconSrc,
            leftIconColor: leftIconColor
        }
    };
}
const _createRowForTag = ({nfcTag, isUserFromOtherTrustPeer, trustMemberName, navigation, currentUser}) => {
    if (isUserFromOtherTrustPeer) {
        return {
            content: {
                title: nfcTag.name,
                subtitle: _("usermanagement.trust.managed-by", {
                    trustPeer: "@" + trustMemberName
                })
            }
        };
    } else {
        return {
            action: () => {
                navigation.navigate(ScreenState.NfcEditDialog, {
                    nfcTag,
                    currentUser
                });
            },
            content: {
                title: nfcTag.name,
                leftIconSrc: Icons.NfcTag,
                leftIconColor: globalStyles.colors.white
            }
        };
    }
}

const _getNfcAddCell = ({nfcCodeTouches, navigation, currentUser, allUsers, allDataLoaded}) => {
    var cell;

    if (allDataLoaded) {
        if (nfcCodeTouches.readyToLearnTag.length) {
            cell = {
                content: {
                    leftIconSrc: Icons.PlusFilledCircle,
                    title: _('nfc.learning.addtag'),
                    leftIconColor: globalStyles.colors.green_fixed
                },
                action: () => {
                    navigation.navigate(ScreenState.LearnNfcTagScreen, {
                        user: currentUser,
                        users: allUsers,
                        nfcCodeTouches: nfcCodeTouches
                    });
                }
            };
        } else if (nfcCodeTouches.offline.length) {
            cell = {
                content: _createCellContentForNFCAddTag(),
                action: () => {
                    _showWarningPopupOffline(nfcCodeTouches.offline.length, nfcCodeTouches)
                }
            };
        } else if (nfcCodeTouches.canNotReadNfc.length) {
            cell = {
                content: _createCellContentForNFCAddTag(),
                action: () => {
                    _showWarningPopupCanNotLearnNFC(nfcCodeTouches.canNotReadNfc.length, nfcCodeTouches)
                }
            };
        }
    }

    return cell;
}

const _createCellContentForNFCAddTag = () => {
    return {
        rightIconSrc: Icon.WARNING,
        rightIconColor: globalStyles.colors.orange,
        leftIconSrc: Icons.PlusFilledCircle,
        title: _('nfc.learning.addtag'),
        //titleColor: Color.TEXT_SECONDARY_B,
        iconColor: Color.STATE_INACTIVE,
        leftIconColor: globalStyles.colors.green_fixed
    };
}

const _showWarningPopupOffline = (nfcTouchCnt, nfcTouchControls) => {
    var location = null,
        controlName = null;

    if (nfcTouchCnt === 1) {
        location = nfcTouchControls.offline[0].details.place ? nfcTouchControls.offline[0].details.place + SEPARATOR_SYMBOL + nfcTouchControls.offline[0].getRoom().name : nfcTouchControls.offline[0].getRoom().name;
        controlName = nfcTouchControls.offline[0].name;
    }

    var title = _("nfc.learning.offline");

    var message = _("nfc.learning.offline.message", {
        count: nfcTouchCnt,
        controlName: controlName,
        location: location
    });

    return NavigationComp.showPopup({
        title: title,
        message: message,
        buttonOk: true,
        buttonCancel: false,
        icon: Icon.WARNING,
        color: globalStyles.colors.orange
    }, PopupType.GENERAL);
}


const _showWarningPopupCanNotLearnNFC = (nfcTouchCnt, nfcTouchControls) => {
    var location = null,
        controlName = null;

    if (nfcTouchCnt === 1) {
        location = nfcTouchControls.canNotReadNfc[0].details.place ? nfcTouchControls.canNotReadNfc[0].details.place + SEPARATOR_SYMBOL + nfcTouchControls.canNotReadNfc[0].getRoom().name : nfcTouchControls.canNotReadNfc[0].getRoom().name;
        controlName = nfcTouchControls.canNotReadNfc[0].name;
    }

    var title = _("nfc.learning.offline");

    var message = _("nfc.learning.batterypower.message", {
        count: nfcTouchCnt,
        controlName: controlName,
        location: location
    });

    return NavigationComp.showPopup({
        title: title,
        message: message,
        buttonOk: true,
        buttonCancel: false,
        icon: Icon.WARNING,
        color: globalStyles.colors.orange
    }, PopupType.GENERAL);
}


// endregion

const _createDeleteUserCell = ({currentUser, navigation, userIsLastPermanentActiveAdmin, onDeleted}) => {
    let title = _('user.delete.title'),
        message = _('user.delete.message', {
            username: currentUser.name
        }),
        /*action = function () {
            return ActiveMSComponent.deleteUser(currentUser.uuid).then(function () {
                return this.ViewController.navigateBack();
            }.bind(this), function (err) {
                console.error(err);
                return this.ViewController.navigateBack();
            }.bind(this));
        }.bind(this),*/
        action = () => {
            return ActiveMSComponent.deleteUser(currentUser.uuid).then(() => {
                let state = navigation.getState();
                //callback for the screen to inform the previous screen, it should reload
                onDeleted()
                if (state && state.routes) {
                    let prevRoute = state.routes[state.routes.length - 2];
                    navigation.navigate(prevRoute.name, {
                        needsReload: true,
                        user: currentUser
                    });
                }
            });
        },
        buttonTitle = _('user.delete.title'),
        showCancelButton;

    if (userIsLastPermanentActiveAdmin) {
        title = _('user.mainuser.delete.message');
        message = _('user.delete.last-admin.message');
        buttonTitle = _('ok.short');
        showCancelButton = false;
        action = null;
    }

    return {
        action: () => {
            _showDeletePopup(title, message, buttonTitle, action, showCancelButton)
        },
        content: {
            title: _('user.delete.title'),
            clickable: true
        },
        type: GUI.TableViewV2.CellType.DELETE
    };
}


// #################################################################################################
// -------------------------------------------------------------------------------------------------
// Helper methods
// -------------------------------------------------------------------------------------------------
// #################################################################################################

const _checkIfOnlyOneAdminIsRemaining = (users, currentUser) => {
    let activeAdmins = users.filter(function (user) {
        return user.isAdmin && user.userState === UserManagement.USER_STATES.ACTIVE;
    }).length;

    return activeAdmins === 1 && currentUser.isAdmin && currentUser.userState === UserManagement.USER_STATES.ACTIVE;
}

const _getChangedUserGroups = (mainUserGroups, userGroupsToCompareWith, onlyReturnUuids, returnUserGroups) => {
    var returnArr,
        groupsChanged,
        mainUserGroupsUuids = [],
        userGroupsToCompareWithUuids = [];

    if (mainUserGroups) {
        returnArr = mainUserGroups;
    } else {
        returnArr = userGroupsToCompareWith;
    } // We have to compare the uuids of the userGroups,
    // because the userGroupObjects itself might be different


    if (mainUserGroups) {
        mainUserGroupsUuids = mainUserGroups.map(function (userGroup) {
            return userGroup.uuid;
        });
    }

    if (userGroupsToCompareWith) {
        userGroupsToCompareWithUuids = userGroupsToCompareWith.map(function (userGroup) {
            return userGroup.uuid;
        });
    }

    groupsChanged = JSON.stringify(userGroupsToCompareWithUuids.sort()) !== JSON.stringify(mainUserGroupsUuids.sort()); // We have to sort the entry, to be sure that they are in the same order --> same string if the user made no changes

    if (returnUserGroups || groupsChanged) {
        if (onlyReturnUuids) {
            return returnArr.map(function (userGroup) {
                return userGroup.uuid;
            });
        } else {
            return returnArr;
        }
    } else {
        return null;
    }
}

const _getChangedUser = ({localProperties, currentUser, originUser}) => {
    var changedUser = {},
        changedUserGroups;

    localProperties.forEach(function (key) {
        if (currentUser.hasOwnProperty(key) && currentUser[key] !== originUser[key]) {
            changedUser[key] = currentUser[key];
        }
    }.bind(this));

    switch (currentUser.userState) {
        case UserManagement.USER_STATES.VALID_UNTIL:
            if (changedUser.validUntil) {
                changedUser.userState = currentUser.userState;
                changedUser.validUntil = currentUser.validUntil;
            }
            break;
        case UserManagement.USER_STATES.VALID_FROM:
            if (changedUser.validFrom) {
                changedUser.userState = currentUser.userState;
                changedUser.validFrom = currentUser.validFrom;
            }
            break;
        case UserManagement.USER_STATES.VALID_FROM_UNTIL:
            if (changedUser.validUntil || changedUser.validFrom) {
                changedUser.userState = currentUser.userState;
                changedUser.validUntil = currentUser.validUntil;
                changedUser.validFrom = currentUser.validFrom;
            }
            break;
    }

    changedUserGroups = _getChangedUserGroups(currentUser.usergroups, originUser.usergroups, true);
    changedUserGroups && (changedUser.usergroups = changedUserGroups); // If we change a user we always have to send with the user uuid

    if (jQuery.isEmptyObject(changedUser)) {
        return false;
    } else {
        changedUser.uuid = currentUser.uuid;

        return changedUser;
    }
}

const _checkPermissions = (userFromMs, currentUser) => {
    let tmpPermissionObj = {
        isAdmin: false,
        changePassword: false,
        disableChangePassSwitch: false
    };

    if (userFromMs) {
        if (hasBit(currentUser.userRights, MsPermission.ADMIN)) {
            tmpPermissionObj.isAdmin = true;
            tmpPermissionObj.disableChangePassSwitch = true;
            tmpPermissionObj.changePassword = true;
        }

        if (hasBit(currentUser.userRights, MsPermission.USER_MANAGEMENT)) {
            tmpPermissionObj.changePassword = true;
            tmpPermissionObj.disableChangePassSwitch = true;
        }
    } else {
        currentUser.usergroups.forEach((usergroup) => {
            if (hasBit(usergroup.userRights, UserManagement.USER_GROUP_PERMISSIONS.LOXONE_CONFIG)) {
                tmpPermissionObj.isAdmin = true;
                tmpPermissionObj.disableChangePassSwitch = true;
                tmpPermissionObj.changePassword = true;
            }

            if (hasBit(usergroup.userRights, UserManagement.USER_GROUP_PERMISSIONS.USER_MANAGEMENT)) {
                tmpPermissionObj.changePassword = true;
                tmpPermissionObj.disableChangePassSwitch = true;
            }
        })
    }

    return tmpPermissionObj;
}


const _showDeletePopup = (title, message, buttonTitle, action, showCancelButton) => {
    return NavigationComp.showPopup({
        title: title,
        message: message,
        buttonOk: buttonTitle,
        buttonCancel: showCancelButton !== undefined ? showCancelButton : true,
        icon: Icon.Notification.CAUTION,
        color: globalStyles.colors.red
    }, PopupType.GENERAL).then(action);
}

const headerTitleStyle = globalStyles.customStyles.UserManagementHeaderTitle;

// #################################################################################################
// -------------------------------------------------------------------------------------------------
// DUMMY CELL
// -------------------------------------------------------------------------------------------------
// #################################################################################################

const _getDummyCell = () => {
    return  {
        content: {
            title: "Dummy",
            leftIconSrc: Icons.Air,
            isLoading: true
        }
    }
}

export {
    // region sections
    _getTrustGeneralSection,
    _getGeneralSection,
    _getUserStateSection,
    _getUserGroupSection,
    _getSecuritySection,
    _getNfcSection,
    _createDeleteUserCell,
    //endregion

    // region helper methods
    _checkIfOnlyOneAdminIsRemaining,
    _getChangedUserGroups,
    _getChangedUser,
    _checkPermissions
    // endregion
}
