'use strict';

define("UpDownLeftRightAnalogControl", ["UpDownLeftRightDigitalControl"], function (UpDownLeftRightDigitalControl) {
    return class UpDownLeftRightAnalogControl extends UpDownLeftRightDigitalControl {
        constructor() {
            super(...arguments);
        }

        getStepWidth() {
            return this.details.step;
        }

        validateValue(val) {
            if (val >= this.details.min && val <= this.details.max) {
                return val;
            } else if (val < this.details.min) {
                return this.details.min;
            } else if (val > this.details.max) {
                return this.details.max;
            }
        }

        getCommand(direction) {
            var states = this.getStates(),
                val = states.value;

            if (direction === Direction.UP) {
                val += this.getStepWidth();
            } else if (direction === Direction.DOWN) {
                val -= this.getStepWidth();
            }

            val = this.validateValue(val);

            if (typeof val === "number" && val !== states.value) {
                return Commands.format(Commands.UP_DOWN_LEFT_RIGHT.ANALOG.VALUE, val);
            }
        }

        getHitCommand(direction) {// no hit command
        }

        getReleaseCommand(direction) {// no hit command
        }

        getCommandInterval() {
            return 300;
        }

        supportsStates() {
            return true;
        }

        getButton0() {
            return {
                iconSrc: this.getButtonIcon(Direction.UP),
                reactIcon: this.getReactButtonIcon(Direction.UP),
                command: {
                    tap: this.getCommand.bind(this, Direction.UP),
                    tick: this.getCommand.bind(this, Direction.UP)
                }
            };
        }

        getButton1() {
            return {
                iconSrc: this.getButtonIcon(Direction.DOWN),
                reactIcon: this.getReactButtonIcon(Direction.DOWN),
                command: {
                    tap: this.getCommand.bind(this, Direction.DOWN),
                    tick: this.getCommand.bind(this, Direction.DOWN)
                }
            };
        }

        specificCreateCmdText(cmdParts) {
            var text,
                cfg = {
                    name: this.name
                };
            var value = parseFloat(cmdParts[1]);
            cfg.value = lxFormat(this.details.format, value);
            text = _("cmdtext.analog.value", cfg);
            return text;
        }

    };
});
