import React from "react";
import globalStyles from "GlobalStyles";
import PropTypes from "prop-types";
import {LxReactPressable} from "LxComponents";

/**
 * Returns a Touchable Component (https://reactnative.dev/docs/touchablewithoutfeedback), that contains an icon
 * @param {Function} props.onPress Called when button was tapped
 * @param {Function} props.onLongPress Called if the time after onPressIn lasts longer than 370 milliseconds. (customizable)
 * @param {Function} props.onPressIn Called when button is pressed (before onPress)
 * @param {Function} props.onPressOut Called when button is released
 * @param {Function} props.icon Icon that should be displayed in the button
 * @param {Object} [props.containerStyle] For overriding the Touchable Component Style
 * @param {Boolean} [props.disabled = false] defines if the button is clickable or not
 */
export default function LxReactIconButton(props) {

    const getChildProp = () => {
        if (!props.icon) {
            return undefined;
        }
        var iconStyle = {...styles.icon, ...props.iconStyle};
        return {
            comp: props.icon,
            props: {
                key: getKeyProp("lx-press-icon"),
                pkey: getKeyProp("lx-press-icon"),
                style: iconStyle,
                ...iconStyle
            }
        }
    }

    const getKeyProp = (suffix) => {
        return props.pkey + "-" + suffix;
    }

    return (
        <LxReactPressable
            ref={props.ref}
            style={{...styles.container, ...props.containerStyle}}
            disabled={props.disabled}
            onPress={props.onPress}
            onDoublePress={props.onDoublePress}
            onLongPress={props.onLongPress}
            onLongPressTick={props.onLongPressTick}
            longPressTickInterval={props.longPressTickInterval}
            onPressArgs={props.onPressArgs}
            childComp={getChildProp()}
            pkey={getKeyProp()}
            key={getKeyProp()}
            children={props.children}
        />
    );
}

LxReactIconButton.propTypes = {
    onPress: PropTypes.func,
    onPressArgs: PropTypes.array,
    onPressIn: PropTypes.func,
    onPressOut: PropTypes.func,
    onLongPress: PropTypes.func,
    onLongPressTick: PropTypes.func,
    longPressTickInterval: PropTypes.number,
    icon: PropTypes.func,
    iconStyle: PropTypes.object,
    containerStyle: PropTypes.object,
    disabled: PropTypes.bool,
    pkey: PropTypes.string.isRequired
}

const styles = {
    container: {
        padding: 12,
        backgroundColor: globalStyles.colors.grey["900"],
        borderRadius: "50%"
    },
    icon: {
        fill: globalStyles.colors.grey["200"],
        height: 20,
        width: 20
    }
}
