import {useControl, useLiveState, DateViewType, CircleGraph} from "LxComponents"
import {View} from "react-native";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import globalStyles from "GlobalStyles";

export default function MeterV2BidirectionalLiveEnergyBalance({controlUUID, viewType, style}) {
    const getStateName = (negative) => {
        let stateName;
        switch (viewType) {
            case DateViewType.Day:
            case DateViewType.Live:
                stateName = negative ? "totalNegDay" : "totalDay";
                break;
            case DateViewType.Week:
                stateName = negative ? "totalNegWeek" : "totalWeek";
                break;
            case DateViewType.Month:
                stateName = negative ? "totalNegMonth" : "totalMonth";
                break;
            case DateViewType.Year:
                stateName = negative ? "totalNegYear" : "totalYear";
                break;
            case DateViewType.Lifetime:
                stateName = negative ? "totalNeg" : "total";
                break;
            default:
                console.error(MeterV2BidirectionalLiveEnergyBalance.name, "ViewType " + viewType + " not supported via live state from Minsierver!");
                break;
        }
        return stateName ? (stateName + "Value") : null;
    }
    const [circleGraphValues, setCircleGraphValues] = useState([]);

    const control = useControl(controlUUID);
    const liveState = useLiveState(controlUUID, [getStateName(false), getStateName(true)]);

    const styles = {
        rootCntr: {
            flexDirection: "column"
        }
    }

    useEffect(() => {
        (async () => {
            updateCircleGraphValues();
        })();
    }, [JSON.stringify(liveState.states).hashCode(), viewType])

    const updateCircleGraphValues =  () => {
        const arr = [];

        let totalStateName = getStateName(false),
            totalNegStateName = getStateName(true);
        let total = liveState.states[totalStateName],
            totalNeg = liveState.states[totalNegStateName];

        arr.push({
            color: globalStyles.colors.green,
            value: totalNeg,
            label: control.getNameForOutput(totalNegStateName),
            format: control.totalFormat
        });

        arr.push({
            color: globalStyles.colors.orange,
            value: total,
            label: control.getNameForOutput(totalStateName),
            format: control.totalFormat
        })

        setCircleGraphValues(arr);
    }

    return <View style={{ ...styles.rootCntr, ...style }}>
        <CircleGraph values={circleGraphValues} />
    </View>
}


MeterV2BidirectionalLiveEnergyBalance.propTypes = {
    controlUUID: PropTypes.string.isRequired,
    viewType: PropTypes.string
}
