import { useState, useEffect, useRef } from 'react';
import globalStyles from "GlobalStyles";

/**
 * Hook returning the control identified by the uuid. Will update the control once the structure changes.
 * Will return null if the control isn't available (anymore - as it may have been removed from the UI)
 * @param controlUuid
 * @returns {control}
 */
function useControl(controlUuid) {
    const [control, setControl] = useState(ActiveMSComponent.getControlByUUID(controlUuid) || null);

    if (!controlUuid) {
        console.warn(useControl.name, "no controlUUID provided!");
    }
    let structureChangedReg = useRef(null);

    const unregister = () => {
        structureChangedReg.current && structureChangedReg.current;
    }

    const structureChanged = () => {
        setControl(ActiveMSComponent.getControlByUUID(controlUuid) || null);
    }

    useEffect(() => {
        unregister();
        if (control && control.uuidAction !== controlUuid) {
            structureChanged()
        }
        // fns stored as states will be called and the return value is stored (stateReducer) --> wrap in anonymous fn
        structureChangedReg.current = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.StructureChanged, structureChanged);

        return unregister;
    }, [controlUuid]);

    return control;
}

export default useControl;
