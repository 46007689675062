import { useLiveState, useControl } from 'LxComponents';
import {useContext, useMemo} from "react"
import { useNavigation } from "@react-navigation/native";
import {ControlContext} from "../Components";


function useControlCtxtMenuOptions(controlUUID, states, innerNavigation ) {
    const control = useControl(controlUUID);
    const liveState = states ? {states} : useLiveState(controlUUID, ["universalIsUnlockable", "universalIsLocked"]);
    const outerNavigation = useNavigation();

    // additional options may be provided using the control context.
    const {contextMenuOptions} = useContext(ControlContext);

    const getNavigation = () => innerNavigation || outerNavigation;

    const getShowExpertModeLight = () => {
        return !SandboxComponent.isRecordingTask() && ActiveMSComponent.isExpertModeLightEnabled() && (!control.uuidParent || !!control.parentControl);
    }

    const getShowExpertMode = () => {
        return !SandboxComponent.isRecordingTask() && ActiveMSComponent.isExpertModeEnabled() && (!control.uuidParent || !!control.parentControl);
    }

    const showControlLockScreen = () => {
        getNavigation().navigate(ScreenState.ControlLocking, {
            control: control
        });
    }

    const showControlSettings = (useLightMode) => {
        VendorHub.Usage.expertMode(FeatureUsage.ExpertMode.OPEN, FeatureUsage.ExpertMode.Type.CONTROL);
        getNavigation().navigate(ScreenState.ControlSettingsController, {
            object: control,
            objectUUID: control.uuidAction,
            objectName: control.name,
            permission: useLightMode ? MsPermission.EXPERT_MODE_LIGHT : MsPermission.EXPERT_MODE
        })
    }

    //TODO: This function is a duplicate of LxControlMoreSection, extract in an own file, maybe ControlUtils...
    const showStatistic = () => {
        let nr = 0;
        if (control.statistic && control.statistic.outputs && control.statistic.outputs[nr]) {
            nr = nr ? nr : 0;
            getNavigation().navigate(ScreenState.Statistic, {
                controlUUID: control.uuidAction,
                statisticOutputUUID: control.statistic.outputs[nr].uuid
            })
        }
    }

    const isLockable = () => {
        var lockable = false;

        if (control.supportsLocking() && SandboxComponent.hasAdminPermission()) {
            // control supports it, permissions granted - true if not locked already
            lockable = !liveState.states.universalIsUnlockable && !liveState.states.universalIsLocked;
        }

        return lockable;
    }

    return useMemo(() => {
        let options = [];
        if (control) {
            if (!!control.statistic && control.statistic.outputs && control.statistic.outputs.length) {
                options.push({
                    title: _('statistics.button.title'),
                    action: showStatistic.bind(this)
                });
            } // EXPERT MODE LIGHT


            if (getShowExpertModeLight()) {
                options.push({
                    title: _('adopt-ui-settings'),
                    action: showControlSettings.bind(this, true)
                });
            } // EXPERT MODE


            if (getShowExpertMode()) {
                options.push({
                    title: _('expert-settings'),
                    action: showControlSettings.bind(this, false)
                });
            } // CONTROL NOTES


            if (control.hasControlNotes) {
                options.push({
                    title: _("note"),
                    action: () => getNavigation().navigate(ScreenState.ControlHelp, { control })
                });
            } // PRESET


            var presetEntry = SandboxComponent.getPresetContextMenuEntryFor(control);
            presetEntry && options.push(presetEntry); // CONTROL LOCKING

            if (isLockable()) {
                options.push({
                    title: _("control.lock.lock-title"),
                    action: () => {
                        showControlLockScreen();
                    }
                });
            }

            // if options are be provided using the control context --> add them here.
            if (Array.isArray(contextMenuOptions)) {
                options = [...options, ...contextMenuOptions];
            }
        }
        return options;
    }, [control, liveState.states.universalIsUnlockable, liveState.states.universalIsLocked, contextMenuOptions]);
}

export default useControlCtxtMenuOptions;
