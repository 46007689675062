import LxReactCompPropTypes from "./LxReactCompPropTypes";
import PropTypes from "prop-types";
import LxReactText from "./LxReactText"
import globalStyles from "GlobalStyles";
import {View} from "react-native";
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export default function LxReactHeader(props) {

    const insets = useSafeAreaInsets();

    const Icon = () => {
        let item;
        if (props.icon) {
            let largeStyle = props.iconSize === HeaderIconSize.Large ? styles.iconSizeLarge : {};
            item = <props.icon style={[styles.icon, props.iconStyle, largeStyle]}/>;
        }

        return item ? <View style={[styles.iconCntr, props.iconContainerStyle]}>{item}</View> : null;
    }

    const Label = ({containerStyle, text, style}) => {
        return <View style={containerStyle}>
            <LxReactText style={style}>{text}</LxReactText>
        </View>
    }

    return <View style={{...styles.container, paddingTop: insets.top, ...props.style}}>
        <Icon/>
        {props.title && <Label containerStyle={styles.titleContainer} text={props.title} style={styles.titleText}/>}
        {props.subtitle && <Label containerStyle={styles.subtitleContainer} text={props.subtitle} style={styles.subtitleText}/>}
    </View>;
};

const styles = {
    container: {
        marginTop: 3,
        flex: 1,
        flexDirection: "column",
        width: "100%",
    },
    iconCntr: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: 104,
        marginHorizontal: "auto",
        marginTop: globalStyles.sizes.small,
        marginBottom: globalStyles.sizes.small
    },
    icon: {
        fill: globalStyles.colors.brand,
        width: 52,
        height: 52,
    },
    iconSizeLarge: {
        width: "100%",
        height: "100%",
    },
    titleContainer: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "fit-content"
    },
    titleText: {
        ...globalStyles.textStyles.title1.bold,
        color: globalStyles.colors.text.primary,
        textAlign: "center"
    },
    subtitleContainer: {
        marginTop: globalStyles.sizes.smaller,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        minHeight: "fit-content"
    },
    subtitleText: {
        color: globalStyles.colors.text.secondary,
        textAlign: "center"
    }
}

const HeaderIconSize = {
    Normal: "normal-52x52",
    Large: "large-104x104"
}


LxReactHeader.propTypes = {
    icon: PropTypes.func,
    iconSize: PropTypes.oneOf([HeaderIconSize.Normal, HeaderIconSize.Large]),
    iconStyle: PropTypes.object,
    iconContainerStyle: PropTypes.object,
    title: PropTypes.string,
    subtitle: PropTypes.string,
}
export const LxReactHeaderIconSize = HeaderIconSize;


