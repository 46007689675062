'use strict';

class CommandInstructionBar extends GUI.InstructionBar {
    constructor(type) {
        super(type);
        SandboxComponent.startTaskRecording(); // if NFC, prevent case if user touches a Tag while the interaction is in progress!

        if (this.type === Interaction.NFC.WRITE_COMMAND) {
            nfcService.preventListening(true);
        } else if (this.type === Interaction.QuickAction.WRITE_COMMAND) {
            this.quickActions = QuickActionUtility.getItems();
        }

        var platform = PlatformComponent.getPlatformInfoObj().platform;
        this.isAndroid = platform === PlatformType.Android;
        this._isLoxoneControl = LoxoneControl.hasLoxoneControl();
    }

    viewDidLoad() {
        super.viewDidLoad(...arguments);
        this.element.addClass("command-instruction-bar");
        this.elements.cancelBtn = this.addButton("resources/Images/ActiveMiniserver/NFC/button-cancel.svg", "cancel-btn");
        this.setText(_('command.execute'));
    }

    viewDidAppear() {
        super.viewDidAppear(...arguments);
        SandboxComponent.registerForNewTaskCommands(this._commandReceived.bind(this));
        this._boundCancel = this._onCancel.bind(this);
        this.elements.cancelBtn.on("click", this._boundCancel);
    }

    viewDidDisappear(viewRemainsVisible) {
        SandboxComponent.unregisterForNewTaskCommands();
        SandboxComponent.stopTaskRecording();
        this.elements.cancelBtn.off("click", this._boundCancel);
        super.viewDidDisappear(viewRemainsVisible);
    }

    destroy() {
        // if NFC, prevent case if user touches a Tag while the interaction is in progress!
        if (this.type === Interaction.NFC.WRITE_COMMAND) {
            nfcService.preventListening(false);
        }

        super.destroy();
    }

    // Private methods
    _commandReceived(commands) {
        var cmdObj = checkCommand(commands[commands.length - 1]);

        if (!cmdObj) {
            return;
        } // Here we check if the command is a secure command because its currently not supported
        // If it is secure we display a popup


        if (this.type === Interaction.QuickAction.WRITE_COMMAND) {
            if (cmdObj.isSecured) {
                var content = {
                    message: _("command.visu-pwd.not-supported"),
                    buttonOk: true,
                    icon: Icon.CAUTION,
                    color: window.Styles.colors.orange
                };
                NavigationComp.showPopup(content);
                return;
            }
        } // max. 2 commands allowed


        if (!this._firstCmd) {
            this._firstCmd = cmdObj;
            this._commandReceivedTimeout = setTimeout(function () {
                this._finish([cmdObj]);
            }.bind(this), 500);
        } else {
            // 2nd command, now it's enough
            clearTimeout(this._commandReceivedTimeout);

            this._finish([this._firstCmd, cmdObj]);
        }
    }

    _onCancel() {
        if (this.type === Interaction.QR.WRITE_COMMAND) {
            NavigationComp.showState(ScreenState.QRCode);
        } else if (this.type === Interaction.NFC.WRITE_COMMAND) {
            NavigationComp.showState(ScreenState.Nfc);
        } else if (this.type === Interaction.QuickAction.WRITE_COMMAND) {
            NavigationComp.showState(ScreenState.QuickActions);
        }

        super._onCancel();
    }

    _finish(commands) {
        NavigationComp.stopActivity(GUI.ActiveMiniserverViewController.Activity.Command);
        var finalCmdUrl = commands[0].fullCmd;

        if (commands.length === 2) {
            var secondCmd = getURIParameters(commands[1].fullCmd).cmd; // returns the decoded command

            finalCmdUrl = finalCmdUrl + "&cmd2=" + encodeURIComponent(secondCmd); // so we must encode the cmd again
        }

        if (this.type === Interaction.NFC.WRITE_COMMAND) {
            var nfcObj = {
                type: Interaction.NFC.WRITE_COMMAND,
                commandUrl: finalCmdUrl,
                mainText: commands[0].mainText,
                subText: commands[0].subText
            };
            NavigationComp.showState(ScreenState.NfcTagInteractionScreen, nfcObj);
        } else if (this.type === Interaction.QR.WRITE_COMMAND) {
            var qrObj = {
                type: Interaction.QR.WRITE_COMMAND,
                text: finalCmdUrl,
                mainText: commands[0].mainText,
                subText: commands[0].subText
            };
            NavigationComp.showState(ScreenState.QRCodeResult, qrObj);
        } else if (this.type === Interaction.QuickAction.WRITE_COMMAND) {
            var isUnique = true;
            var message;

            if (this.quickActions) {
                for (var actionIndex in this.quickActions) {
                    if (this.quickActions.hasOwnProperty(actionIndex)) {
                        var action = this.quickActions[actionIndex];

                        if (!action.cmds) {
                            continue;
                        } // Here we only need to check if the first cmd is equal,
                        // because if not it would be a different command


                        if (action.cmds[0] === getURIParameters(finalCmdUrl).cmd) {
                            isUnique = false;

                            if (this.isAndroid) {
                                message = _("shortcuts.android.save-duplicate");
                            } else if (this._isLoxoneControl) {
                                message = _('loxone-control.quick-action.save.duplicate');
                            } else {
                                message = _("quick-action.save.duplicate");
                            }

                            NavigationComp.showState(ScreenState.QuickActions);
                            var content = {
                                message: message,
                                buttonOk: true,
                                icon: Icon.CAUTION,
                                color: window.Styles.colors.orange
                            };
                            NavigationComp.showPopup(content);
                        }
                    }
                }
            }

            if (isUnique) {
                this._addQuickAction(finalCmdUrl, commands[0].mainText, commands[0].subText);
            }
        }
    }

    _addQuickAction(url, mainText, subText) {
        var message;
        QuickActionUtility.addCommand(url, mainText, subText).done(function () {
            NavigationComp.showState(ScreenState.QuickActions);

            if (this.isAndroid) {
                message = _("shortcuts.android.save-success");
            } else if (this._isLoxoneControl) {
                message = _('loxone-control.quick-action.save.success');
            } else {
                message = _("quick-action.save.success");
            }

            var content = {
                icon: Icon.SUCCESS,
                message: message,
                buttonOk: true
            };
            NavigationComp.showPopup(content);
        }.bind(this), function () {
            if (this.isAndroid) {
                message = _("shortcuts.android.save-fail");
            } else if (this._isLoxoneControl) {
                message = _('loxone-control.quick-action.save.fail');
            } else {
                message = _("quick-action.save.fail");
            }

            var content = {
                icon: Icon.ERROR,
                color: window.Styles.colors.red,
                message: message,
                buttonOk: true
            };
            NavigationComp.showPopup(content);
        }.bind(this));
    }

}

GUI.CommandInstructionBar = CommandInstructionBar; // TODO-thallth move to base-class once instructionBar + instructionNotification has a baseClass!

var checkCommand = function checkCommand(command) {
    var cmdObj = JSON.parse(JSON.stringify(command)),
        cmd = command.cmd,
        miniserver = ActiveMSComponent.getActiveMiniserver(),
        mac = miniserver.serialNo;
    cmdObj.fullCmd = UrlHelper.createCommandURLStart({
        mac: mac
    }, "jdev/sps/io/" + cmd);
    return cmdObj;
};
