import {useState, useEffect, useMemo, useCallback} from "react";
import DeviceSearchUtils from "./deviceSearchUtils";

export default function useDeviceSearchResults(searchType, extension, searchId, branch = null, miniserver = null) {
    const [info, setInfo] = useState({searchResults: [], isSearching: true, isRestarting: false, wasKicked: false});

    const searchTarget = useMemo(() => {
        let result;
        if (extension && extension.requestTarget) {
            result = extension.requestTarget;
        } else if (extension && extension.serialNr) {
            result = extension.serialNr;
        } else {
            result = searchType;
        }
        return result;
    }, [extension ? extension.serialNr : searchType])

    const _updateInfo = useCallback(({isSearching, wasKicked, results, isRestarting}) => {
        Debug.DeviceSearch.Results && console.log(useDeviceSearchResults.name, "_updateInfo: searching=" + isSearching + ", kicked=" + wasKicked + ", isRestarting=" + !!isRestarting + ", results=" + (results ? results.length : results));
        let filteredResults = DeviceSearchUtils.filterSearchResults(results, miniserver, branch);
        if (branch) {
            Debug.DeviceSearch.Results && console.log(useDeviceSearchResults.name, "     filtered by branch: " + filteredResults.length + ": " + JSON.stringify(filteredResults));
        } else if (miniserver) {
            Debug.DeviceSearch.Results && console.log(useDeviceSearchResults.name, "     filtered by MS: " + filteredResults.length+ ": " + JSON.stringify(filteredResults));
        }
        setInfo({ isSearching, wasKicked, isRestarting, searchResults: filteredResults });
    }, []);

    useEffect(() => {
        console.log(useDeviceSearchResults.name, "MOUNTED");

        let unregFn,
            didStart = false;

        if (ActiveMSComponent.getDeviceSearchingState()) {
            Debug.DeviceSearch.Results && console.log(useDeviceSearchResults.name, "   --> Already searching, no need to restart");
            didStart = false;
            _updateInfo({ isSearching: true, wasKicked: false, results: ActiveMSComponent.getDeviceSearchCurrentDeviceList() });
        } else {
            didStart = true;
            ActiveMSComponent.startDeviceSearch(searchTarget || searchType).then(() => {
                Debug.DeviceSearch.Results && console.log(useDeviceSearchResults.name, "   --> Search started");
            }, (err) => {
                console.error(useDeviceSearchResults.name, "failed to start search: "  + JSON.stringify(err));
            });
        }

        unregFn = ActiveMSComponent.registerForDeviceSearch(_updateInfo, searchType, searchId);

        return () => {
            Debug.DeviceSearch.Results && console.log(useDeviceSearchResults.name, "UNMOUNTED");
            unregFn && unregFn();

            if (didStart) {
                Debug.DeviceSearch.Results && console.log(useDeviceSearchResults.name, "UNMOUNTED -> stop device search");
                ActiveMSComponent.stopDeviceSearch();
            }
        }
    }, [searchType, searchTarget, searchId]);

    return info;
}