'use strict';

define(['ColorPickerControlEnums'], function (ColorPickerControlEnums) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var HUE_WARM_WHITE = 43;
    var HUE_COLD_WHITE = 200;
    var BRIGHTNESS_STEP = 5;
    return class ColorSelection extends GUI.View {
        //region Static
        static Template = function () {
            var getTemplate = function getContent() {
                return $('<div class="color-picker-control-content__state-view">' + '<div class="state-view__color-fields-placeholder">' + '<div class="color-fields-placeholder__field-wrapper' + (HD_APP ? ' color-fields-placeholder__field-wrapper--hd' : '') + '">' + '<div class="fields__color-field detail__kelvin-color-field">' + '<canvas class="color-field__canvas kelvin-color-field__canvas"></canvas>' + '<div class="color-field__border"></div>' + ImageBox.getResourceImageWithClasses("resources/Images/Controls/ColorPicker/color-picker.svg", "color__indicator kelvin-color-indicator") + '</div>' + '<div class="fields__color-field detail__hsv-color-field">' + '<canvas class="color-field__canvas hsv-color-field__canvas"></canvas>' + '<div class="color-field__border"></div>' + ImageBox.getResourceImageWithClasses("resources/Images/Controls/ColorPicker/color-picker.svg", "color__indicator hsv-color-indicator") + '</div>' + '</div>' + '</div>' + '</div>');
            };

            var drawKelvinColorPallet = function (field) {
                checkAndPrepareCanvasContextForRetina(field[0]);
                var ctx = field[0].getContext("2d");
                var width = field[0].clientWidth;
                var height = field[0].clientHeight; // warm spectrum gradient

                var grad = ctx.createLinearGradient(0, 0, 0, Math.ceil(height * 0.4));
                grad.addColorStop(0, "#ffea7f");
                grad.addColorStop(1, "white");
                ctx.fillStyle = grad;
                ctx.fillRect(0, 0, width, Math.ceil(height * 0.4)); // white spectrum

                ctx.fillStyle = "white";
                ctx.fillRect(0, Math.floor(height * 0.4), width, Math.ceil(height * 0.6)); // cold spectrum gradient

                grad = ctx.createLinearGradient(0, Math.floor(height * 0.6), 0, height);
                grad.addColorStop(0, "white");
                grad.addColorStop(1, "#87cfe8");
                ctx.fillStyle = grad;
                ctx.fillRect(0, Math.floor(height * 0.6), width, Math.ceil(height * 0.4));
            };

            var drawHSVColorPallet = function (field) {
                checkAndPrepareCanvasContextForRetina(field[0]);
                var ctx = field[0].getContext("2d");
                var width = field[0].clientWidth;
                var height = field[0].clientHeight; // hue spectrum gradient

                var grad = ctx.createLinearGradient(0, 0, width, 0);
                var hue, step;

                for (hue = 0; hue < 360; hue++) {
                    step = hue / 360;
                    grad.addColorStop(step, 'hsl(' + hue + ', 100%, 50%)');
                }

                ctx.fillStyle = grad;
                ctx.fillRect(0, 0, width, height); // saturation gradient

                grad = ctx.createLinearGradient(0, 0, 0, height);
                grad.addColorStop(0, "white");
                grad.addColorStop(1, "rgba(255, 255, 255, 0)");
                ctx.fillStyle = grad;
                ctx.fillRect(0, 0, width, height);
            };

            return {
                getTemplate: getTemplate,
                drawKelvinColorPallet: drawKelvinColorPallet,
                drawHSVColorPallet: drawHSVColorPallet
            };
        }(); //endregion Static

        constructor(details, control) {
            super(ColorSelection.Template.getTemplate());
            this.control = control; // detect if it is a color selection from a light-control or lightscene-rgb

            this.isColorPickerControl = control.type === 'Colorpicker'; // initial values when the user turn on the lights with the brightness slider

            if (this.isColorPickerControl && this.control.details.pickerType === 'Lumitech') {
                this.colorObject = {
                    type: ColorPickerControlEnums.PickerMode.LUMITECH,
                    kelvin: 4000
                };
            } else {
                this.colorObject = {
                    type: ColorPickerControlEnums.PickerMode.RGB,
                    hue: 0,
                    sat: 0
                };
            }

            this.antiGhostTimer = new AntiGhostTimer(this._antiGhostTimeUpdateIndicator.bind(this));
        }

        viewDidLoad() {
            var baseCall = super.viewDidLoad(...arguments);
            this.elements = {
                kelvinColorField: this.element.find('.kelvin-color-field__canvas'),
                hsvColorField: this.element.find('.hsv-color-field__canvas'),
                kelvinIndicator: this.element.find('.kelvin-color-indicator').hide(),
                hsvIndicator: this.element.find('.hsv-color-indicator').hide()
            };
            return baseCall;
        }

        viewWillAppear() {
            var baseCall = super.viewWillAppear(...arguments);
            var kelvinField = this.element.find('.kelvin-color-field__canvas');
            ColorSelection.Template.drawKelvinColorPallet(kelvinField);
            var hsvField = this.element.find('.hsv-color-field__canvas');
            ColorSelection.Template.drawHSVColorPallet(hsvField);
            return baseCall;
        }

        viewDidAppear() {
            var baseCall = super.viewDidAppear(...arguments).then(function () {
                this._updateColorIndicators(this.colorObject, this.states.selectedMode);
            }.bind(this)); // also register for dragleft and dragright, issue with the page view, page view can't register only for drag because of the slider in a page view which has also dragleft and dragright

            this.eventHandlers = [Hammer(this.elements.kelvinColorField[0], {
                preventDefault: true,
                dragMinDistance: 5
            }).on('tap click dragstart dragleft dragright drag dragend', this._handleTouchEvent.bind(this)), Hammer(this.elements.hsvColorField[0], {
                preventDefault: true,
                dragMinDistance: 5
            }).on('tap click dragstart dragleft dragright drag dragend', this._handleTouchEvent.bind(this))];
            return baseCall;
        }

        viewDidDisappear(viewRemainsVisible) {
            for (var i = 0; i < this.eventHandlers.length; i++) {
                this.eventHandlers[i].dispose();
            }

            return super.viewDidDisappear(viewRemainsVisible);
        }

        destroy() {
            this.antiGhostTimer.resetAntiGhostTimer();
            return super.destroy(...arguments);
        }

        update(states) {
            this.states = states;
            this.colorObject = states.colorObject;

            if (!states.powerOn) {
                this.elements.kelvinIndicator.hide();
                this.elements.hsvIndicator.hide();
            } else {
                if (!this.antiGhostTimer.isAntiGhostTimerActive()) {
                    this.elements.kelvinIndicator.toggle(states.selectedMode === ColorPickerControlEnums.PickerMode.LUMITECH);
                    this.elements.hsvIndicator.toggle(states.selectedMode === ColorPickerControlEnums.PickerMode.RGB); // indicators

                    this._updateColorIndicators(states.colorObject, states.selectedMode);
                }
            }
        }

        sendColorObject(colorObj, brightness, isDragging) {
            var cmd;

            if (colorObj.type === ColorPickerControlEnums.PickerMode.LUMITECH) {
                cmd = Commands.format(Commands.COLOR_PICKER.LUMITECH, brightness, colorObj.kelvin);
            } else if (colorObj.type === ColorPickerControlEnums.PickerMode.RGB) {
                cmd = Commands.format(Commands.COLOR_PICKER.HSV, colorObj.hue, colorObj.sat, brightness);
            }

            if (!this.isColorPickerControl) {
                cmd = Commands.format(Commands.LIGHTSCENE_RGB.SET_COLOR, cmd);
            }

            this.sendCommand(cmd, Commands.Type.OVERRIDE, null, isDragging);
        }

        _getXAndYPosFromEvent(event) {
            var canvasRect = event.target.getBoundingClientRect(),
                evPos = getEventPosition(event),
                xPos = evPos.x - canvasRect.left,
                yPos = evPos.y - canvasRect.top; //               Chrome, Safari...      Firefox

            var srcElement = event.srcElement || event.currentTarget;
            var x = xPos,
                y = yPos;

            if (xPos < 0) {
                x = 0;
            }

            if (xPos > srcElement.clientWidth) {
                x = srcElement.clientWidth;
            }

            if (yPos < 0) {
                y = 0;
            }

            if (yPos > srcElement.clientHeight) {
                y = srcElement.clientHeight;
            }

            return {
                x: x,
                y: y
            };
        }

        _getColorObjFromKelvinPosition(point, height, isKelvin) {
            var colorObj;

            if (isKelvin) {
                var kelvin = 2700;
                kelvin += (6500 - 2700) * (point.y / height);
                colorObj = {
                    type: ColorPickerControlEnums.PickerMode.LUMITECH,
                    kelvin: kelvin
                };
            } else {
                var hue = 0,
                    sat = 0;

                if (point.y < height / 2) {
                    // warm white
                    hue = HUE_WARM_WHITE;
                    sat = 75 - 75 * (point.y / (height / 2)); // max 75
                } else if (point.y > height / 2) {
                    // cold white
                    hue = HUE_COLD_WHITE;
                    sat = 40 * (point.y / (height / 2)) - 40; // max 40
                }

                colorObj = {
                    type: ColorPickerControlEnums.PickerMode.RGB,
                    hue: hue,
                    sat: sat
                };
            }

            return colorObj;
        }

        _getColorObjFromHSVPosition(point, height, width) {
            var hue = 360 * (point.x / width);
            var sat = 100 * (point.y / height);
            return {
                type: ColorPickerControlEnums.PickerMode.RGB,
                hue: hue,
                sat: sat
            };
        }

        _updateColorIndicators(colorObject, colorMode) {
            var pointX = 0,
                pointY = 0,
                indicator,
                previewColorCSS;
            var fieldWidth, fieldHeight;

            if (colorMode === ColorPickerControlEnums.PickerMode.LUMITECH) {
                indicator = this.elements.kelvinIndicator;
                fieldWidth = indicator.parent()[0].clientWidth;
                fieldHeight = indicator.parent()[0].clientHeight;

                if (colorObject.type === ColorPickerControlEnums.PickerMode.RGB) {
                    pointX = Math.round(fieldWidth / 2);

                    if (colorObject.hue === HUE_WARM_WHITE) {
                        pointY = Math.round((75 - colorObject.sat) / 75 * (fieldHeight / 2));
                    } else if (colorObject.hue === HUE_COLD_WHITE) {
                        pointY = Math.round((40 + colorObject.sat) / 40 * (fieldHeight / 2));
                    } else {
                        pointY = Math.round(fieldHeight / 2);
                    }
                } else {
                    pointX = Math.round(fieldWidth / 2);
                    pointY = Math.round((colorObject.kelvin - 2700) / (6500 - 2700) * fieldHeight);
                }
            } else if (colorMode === ColorPickerControlEnums.PickerMode.RGB) {
                indicator = this.elements.hsvIndicator;
                fieldWidth = indicator.parent()[0].clientWidth;
                fieldHeight = indicator.parent()[0].clientHeight;
                pointX = Math.round(colorObject.hue / 360 * fieldWidth);
                pointY = Math.round(colorObject.sat / 100 * fieldHeight);
            }

            pointX -= 25 / 2;
            pointY -= 30;
            indicator.css('left', pointX + 'px');
            indicator.css('top', pointY + 'px'); // Safari bug: Wrike: 178410163
            // We need to request an animation frame due to a new behaviour
            // in the newly introduced WebKit in iOS 11 and HighSierra.

            lxRequestAnimationFrame(function () {
                previewColorCSS = LxColorUtils.getCSSRGBPropertyFromColorObject(colorObject);
                indicator.css('fill', previewColorCSS);
            }, indicator);
        }

        // Event listeners
        _handleTouchEvent(e) {
            var isDragging = e.type === 'drag';
            stopEventPropagation(e); // on iOS drag events are too fast (or too much, pixels!)
            // save time and skip too fast events!

            if (isDragging) {
                if (e.timeStamp - this.lastDragTS < 40) {
                    //console.log("skipping drag event, too fast!");
                    return false;
                }

                this.lastDragTS = e.timeStamp; //console.log(this.lastDragTS);
            }

            var position = this._getXAndYPosFromEvent(e);

            var colorObject, selectedMode;
            this.elements.kelvinIndicator.toggle(e.currentTarget === this.elements.kelvinColorField[0]);
            this.elements.hsvIndicator.toggle(e.currentTarget === this.elements.hsvColorField[0]);

            if (e.currentTarget === this.elements.kelvinColorField[0]) {
                colorObject = this._getColorObjFromKelvinPosition(position, e.currentTarget.clientHeight, this.control.details.pickerType === 'Lumitech');
                selectedMode = ColorPickerControlEnums.PickerMode.LUMITECH;
            } else if (e.currentTarget === this.elements.hsvColorField[0]) {
                colorObject = this._getColorObjFromHSVPosition(position, e.currentTarget.clientHeight, e.currentTarget.clientWidth);
                selectedMode = ColorPickerControlEnums.PickerMode.RGB;
            }

            colorObject.brightness = this.colorObject.brightness; // otherwise it means that the color is black with no brightness

            var brightness;

            switch (e.type) {
                case 'dragstart':
                    //this.isDragging = true;
                    break;

                case 'dragend':
                    //this.isDragging = false;
                    // send also value at drag end!
                    brightness = this.colorObject.brightness > 0 ? this.colorObject.brightness : 100;
                    this.sendColorObject(colorObject, brightness, false);
                    break;

                case 'click':
                case 'tap':
                case 'drag':
                    this.antiGhostTimer.fire();
                    brightness = this.colorObject.brightness > 0 ? this.colorObject.brightness : 100;
                    this.sendColorObject(colorObject, brightness, isDragging);
                    break;

                default:
            } // indicators


            this._updateColorIndicators(colorObject, selectedMode);
        }

        _antiGhostTimeUpdateIndicator() {
            if (this.colorObject && this.states.selectedMode) {
                this._updateColorIndicators(this.colorObject, this.states.selectedMode);
            }
        }

    };
});
