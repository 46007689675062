'use strict';

define([], function () {
    return class LicenseDetailScreen extends GUI.Screen {
        //region Static
        static Template = class {
            //region Static
            static getLicenseContainer(licenseText) {
                return $("<pre>" + licenseText.sanitizeHTML() + "</pre>");
            } //endregion Static


        }; //endregion Static

        constructor(pkg) {
            super($("<div/>"));
            this.pkg = pkg;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.element.append(LicenseDetailScreen.Template.getLicenseContainer(this.pkg.licenseText));
            }.bind(this));
        }

        titleBarText() {
            return this.pkg.name;
        }

        getAnimation() {
            return AnimationType.MODAL;
        }
    };
});
