'use strict';

define(["ControlSelectionScreen", "GroupSelectionScreen"], function (ControlSelectionScreen, GroupSelectionScreen) {
    return class FavoritesSettingsScreen extends GUI.TableViewScreen {
        constructor() {
            super($('<div class="favorites-settings-screen" />'));
            var settings = PersistenceComponent.getMiniserverSettings();
            this.hasManualFavs = settings.manualFavorites.activated;
        }

        titleBarText() {
            return _("favorites.title");
        }

        reloadTable() {
            this.tableContent = [{
                footerTitle: _("manual-favorites.description-device") + "</br></br>" + "(" + _("manual-favorites.description-override") + ")",
                rows: [{
                    type: GUI.TableViewV2.CellType.SWITCH,
                    content: {
                        title: _("manual-favorites.custom-favorites"),
                        active: this.hasManualFavs
                    },
                    onSwitchChanged: function onSwitchChanged(value) {
                        this._toggleCustomFavorites(value);
                    }.bind(this)
                }]
            }];

            if (this.hasManualFavs) {
                this.tableContent[0].rows.push({
                    type: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        title: _("manual-favorites.select-favorites"),
                        clickable: true,
                        disclosureIcon: true
                    },
                    action: function action() {
                        this.ViewController.showState(ScreenState.GroupSelection);
                    }.bind(this)
                });
            }

            return super.reloadTable(...arguments);
        }

        _toggleCustomFavorites(value) {
            this.hasManualFavs = value;
            PersistenceComponent.activateManualFavorites(value);
            PersistenceComponent.activateDeviceFavorites(value); //TODO-woessto: remove debug code.

            NavigationComp.dispatchEventToUI(NavigationComp.UiEvents.FavoritesChanged);
            this.reloadTable();
        }

    };
});
