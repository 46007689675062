import {
    LxBaseControlContent,
    DateViewTypeSelector,
    DateViewType,
    ControlContext,
    AmbientContext
} from "LxComponents";
import { useState, useContext, useMemo } from "react";
import PriceSummary from "./PriceSummary";
import DayGraph from "./DayGraph";
import PriceLists from "./PriceList";
import AutomaticSection from "./AutomaticSection";

function ControlContent({ navigation, route }) {
    const {isAmbientMode} = useContext(AmbientContext);
    const msNow = SandboxComponent.getMiniserverTimeInfo(null, null, TimeValueFormat.MINISERVER_DATE_TIME),
        [viewInfo, setViewInfo] = useState({
            vt: DateViewType.Day,
            ts: msNow.unix(),
            manualSelectedTs: msNow.unix(),
            start: msNow.startOf("day").unix(),
            end: msNow.endOf("day").unix()
        }),
        { control, states } = useContext(ControlContext),
        [isGraphFullScreen, setGraphFullScreen] = useState(!!route?.params?.isGraphFullScreen);
    const onUpdateVt = (viewInfo) => {
        setViewInfo(viewInfo);
    };    

    const { minUnix, maxUnix } = useMemo(() => {
        let msNow = new LxDate(),
            _maxUnix = msNow.clone().endOf("day").unix(),
            _minUnix = msNow.clone().startOf("day").unix();
        if (states?.forecast?.length > 0 && states.forecast[states.forecast.length -1]?.s) {
            _minUnix = ActiveMSComponent.getMomentFromUtcTimestamp(states.forecast[0]?.s).startOf("day").unix();
            _maxUnix = ActiveMSComponent.getMomentFromUtcTimestamp(states.forecast[states.forecast.length -1]?.s).endOf("day").unix();
        }
        if (control.supportsStatisticV2) {
            return {
                maxUnix: _maxUnix
            }
        } else {
            return {
                minUnix: _minUnix,
                maxUnix: _maxUnix,
            }
        }
    }, [control, JSON.stringify(states.forecast)]);

    // region Fullscreen handling
    const openFullscreenGraph = () => {
        if (isAmbientMode) {
            NavigationComp.showControlContent(control, {
                isGraphFullScreen: true
            }, null, {isInAmbientMode: false})
        } else {
            setGraphFullScreen(true);
        }
    };

    const closeFullscreenGraph = () => {
        if (!!route.params?.isGraphFullScreen) {
            navigation.goBack();
        } else {
            setGraphFullScreen(false);
        }
    };
    // endregion

    const stickyTopCompProps = useMemo( () => ({
        availableViewTypes: [DateViewType.Day],
        borderBottomColor: "transparent",
        useFullWidth: true,
        onUpdateVt,
        minUnix,
        maxUnix
    }), [onUpdateVt, minUnix, maxUnix]);

    if (isGraphFullScreen) {
        return (
            <LxBaseControlContent
                stickyTopComp={DateViewTypeSelector}
                stickyTopCompProps={stickyTopCompProps}
                showStateIcon={false}
                showStateText={false}
                customTitle={_("controls.spotpriceoptimizer.graph.title")}
                customClose={closeFullscreenGraph}
                customLeftActionIconSrc={Icon.Buttons.BACK}
                useFullWidth={true}
                hideLinkedControls={true}
                hideControlNote={true}
            >
                <DayGraph
                    uuidAction={control.uuidAction}
                    viewInfo={viewInfo}
                    fullStretch={isGraphFullScreen}
                />
            </LxBaseControlContent>
        );
    } else {
        return (
            <LxBaseControlContent
                stickyTopComp={DateViewTypeSelector}
                stickyTopCompProps={stickyTopCompProps}
                showStateIcon={false}
                showStateText={false}
            >
                <PriceSummary uuidAction={control.uuidAction} viewInfo={viewInfo} />
                <DayGraph
                    uuidAction={control.uuidAction}
                    viewInfo={viewInfo}
                    onZoom={openFullscreenGraph}
                    fullStretch={isGraphFullScreen}
                />
                <PriceLists uuidAction={control.uuidAction} viewInfo={viewInfo} />
                {control?.details.hasAutomation && <AutomaticSection/>}
            </LxBaseControlContent>
        );
    }

}

export default ControlContent;
