import {ControlContext, LxReactTableView, navigatorConfig, useSetURLHook} from "LxComponents";
import {useContext, useEffect, useMemo, useState} from "react";
import {ScreenState} from "../../../alarmClockControlEnums";
import {View} from "react-native-web";
import globalStyles from "GlobalStyles";
import EditEntrySingelton from "../EditEntrySingelton";

function LxOperatingModeSelectorScreen({navigation, route}) {
    const {control} = useContext(ControlContext)
    const [internalModes, setInternalModes] = useState([]);

    useSetURLHook({
        urlPart: "OperatingModeList"
    })

    useEffect(() => {
        dataService.getSelectedModes().subscribe(modes => setInternalModes(modes))
        navigation.setOptions({
            ...navigatorConfig({
                title: control.name,
                animationType: AnimationType.MODAL,
            })
        })
    }, [])

    const modesContent = useMemo(() => {
        let tableContent = [],
            section = {
                rows: []
            };
        let modes = SandboxComponent.getStructureManager().getOperatingModes(true);

        for (let modeNr in modes) {
            if (parseInt(modeNr) >= 0 && parseInt(modeNr) <= 9) {
                let isWeekDay = parseInt(modeNr) > 2;
                let content = {
                    title: modes[modeNr],
                    selected: internalModes.indexOf(parseInt(modeNr)) !== -1,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE
                };

                if (!isWeekDay) {
                    content.activeTextColor = globalStyles.colors.blue;
                }

                section.rows.push({
                    content,
                    type: GUI.TableViewV2.CellType.CHECKABLE,
                    didCheckCell: (cell, section, row, tableView, selected) => {
                        if (selected) {
                            dataService.addMode(row);
                        } else {
                            dataService.removeMode(row);
                        }
                    }
                });
            }
        }
        tableContent.push(section)
        return tableContent
    }, [internalModes]);

    // List needs a height to be scrollable
    return <View style={{ height: "100%" }}>
        {modesContent && <LxReactTableView tableContent={modesContent} />}
    </View>
}

const dataService = EditEntrySingelton.getInstance();



export default LxOperatingModeSelectorScreen
