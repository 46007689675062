'use strict';
/*
 title
 selected

 Delegate
 */

window.GUI = function (GUI) {
    class SceneCell extends GUI.TableViewV2.Cells.CheckableCell {
        //region Static
        static Template = function () {
            var getCircle = function getCircle() {
                return $('<div class="circle"></div>');
            };

            return {
                getCircle: getCircle
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("scene-base-cell");
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.content.radioMode = GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED; // Prepare selection icon

                this.leftIcon = this.element.find(".content__check-icon-placeholder");
                this.circle = SceneCell.Template.getCircle();
                this.leftIcon.html(this.circle); // we 'draw' the circle, okay
            }.bind(this));
        }

        viewWillAppear() {
            var promise = super.viewWillAppear(...arguments);
            this.updateUI(this.content.selected);
            return promise;
        }

        setSelected(selected) {
            super.setSelected(...arguments);
            this.updateUI(selected);
        }

        updateUI(selected) {
            super._adjustColors(selected);

            this._setCircleSelected(selected);
        }

        // Private
        _setCircleSelected(selected) {
            this.circle && this.circle.toggleClass("circle--active", selected);
        }

    }

    GUI.TableViewV2.Cells.SceneBaseCell = SceneCell;
    return GUI;
}(window.GUI || {});
