import {LxReactTableView, useCCEvent, AmbientContext} from "LxComponents";
import {useMemo, useState, useEffect, useContext} from "react";
import {navigatorConfig} from "LxComponents";
import EcoScreenTableViewHelper from "./EcoScreenTableviewHelper";
import {useBackNavigation} from "../Components";

export default function EcoScreenSettings({navigation, route, embedded}) {
    const {isAmbientMode} = useContext(AmbientContext)
    const [settings, setSettings] = useState(PersistenceComponent.getEcoScreenSettings());

    useCCEvent([CCEvent.EcoScreenSettingChanged], () => {
        setSettings(PersistenceComponent.getEcoScreenSettings())
    })
    useBackNavigation(() => {
        navigation.goBack();
    }, [])

    useEffect(() => {
        Debug.EcoScreen && console.log("EcoScreenSettings: useEffect()");
        if (!embedded) {
            navigation.setOptions({
                ...navigatorConfig({
                    title: _("screensaver.name"),
                    headerStyle: {
                        height: 70,
                        borderBottomWidth: 0
                    },
                    animationType: AnimationType.FADE,
                    isAmbientMode: isAmbientMode
                })
            })
        }
    }, [])

    const tableContent = useMemo(() => {
        Debug.EcoScreen && console.log("EcoScreenSettings: building table content based on settings: " + JSON.stringify(settings));
        const tableContent = EcoScreenTableViewHelper.createSettingsContent(settings, navigation);
        Debug.EcoScreen && console.log("EcoScreenSettings: built table content: " + JSON.stringify(tableContent));
        return tableContent;
    }, [JSON.stringify(settings).hashCode()]);

    return <LxReactTableView tableContent={tableContent}/>
}
