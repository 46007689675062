'use strict';

class AlexaConfiguredScreen extends GUI.TableViewScreenV2 {
    //region Static
    static Template = function () {
        var unlinkButtonTemplate = function unlinkButtonTemplate() {
            return $('' + '<div class="bottom-button__container">' + '' + '<div class="container__button">Unlink from Alexa</div>' + '</div>');
        };

        return {
            unlinkButtonTemplate: unlinkButtonTemplate
        };
    }(); //endregion Static

    constructor(details) {
        super($("<div />"));
        this._isDifferentUser = details.isDifferentUser;
        this._ownerUserName = details.ownerUserName;
    }

    viewDidLoad() {
        var args = arguments,
            endpointsPromise = this._updateEndpoints(),
            emailPromise = this._getUserMailAddress();

        this._isAdminUser = ActiveMSComponent.getCurrentUser().isAdmin;
        return Q.all([endpointsPromise, emailPromise]).then(() => {
            return super.viewDidLoad(...args).then(function () {
                if (this._isDifferentUser) {
                    return Q.resolve();
                } else {
                    this._unlinkButton = new GUI.LxButton(this, AlexaConfiguredScreen.Template.unlinkButtonTemplate(), Color.BUTTON_GLOW, null, true, 250);
                    return this.appendSubview(this._unlinkButton, this.element);
                }
            }.bind(this));
        });
    }

    viewWillAppear() {
        var baseCall = super.viewWillAppear(...arguments);
        this._unlinkButton.onButtonTapped = this._handleUnlinkButtonTapped.bind(this);
        return baseCall;
    }

    viewWillDisappear() {
        this._unlinkButton.onButtonTapped = null;
        return super.viewWillDisappear(...arguments);
    }

    titleBarText() {
        return _('alexa.title');
    }

    closeAction() {
        this.ViewController.closeAction();
    }

    titleBarConfig() {
        return {
            leftSide: TitleBarCfg.Button.CLOSE,
            rightSide: TitleBarCfg.CUSTOM_ICON,
            rightIconSrc: Icon.Menu.INFO
        };
    }

    titleBarActionRight() {
        NavigationComp.showCopyPopup(_('alexa.plugin.version'), ActiveMSComponent.getAlexaAddonVersion());
    }

    updateView() {
        var endpointsPrms = this._updateEndpoints().then(function () {
            return this.reloadTable();
        }.bind(this));

        NavigationComp.showWaitingFor(endpointsPrms);
    }

    reloadTable() {
        this.tableContent = [];

        if (this._isDifferentUser) {
            this.tableContent.push(this._getOtherUserSection());
        } else {
            this.tableContent.push(this._getAlreadyConfiguredInfoSection());
            this.tableContent.push(this._getFunctionsSection());
        }

        return super.reloadTable(...arguments);
    }

    _getOtherUserSection() {
        return {
            footerTitle: _('alexa.plugin.other-user', {
                userName: this._ownerUserName
            })
        };
    }

    _getAlreadyConfiguredInfoSection() {
        return {
            footerTitle: _('alexa.configured.title', {
                email: this._userMail
            })
        };
    }

    _getFunctionsSection() {
        var section = {
                headerStrongTitle: _('functions'),
                rows: []
            },
            usedEndpoints = this._availableEndpoints.filter(function (endpoint) {
                return endpoint.visible;
            }).length;

        section.rows.push({
            content: {
                visible: usedEndpoints,
                total: this._availableEndpoints.length,
                max: Alexa.ENDPOINTS_LIMIT
            },
            type: GUI.TableViewV2.CellType.HOME_KIT_PROGRESS
        });
        section.rows.push({
            content: {
                title: _('home-kit.settings.owner.functions.edit'),
                clickable: true
            },
            action: function () {
                this.ViewController.showState(ScreenState.AlexaControlSelectorScreen, null, {
                    endpoints: this._availableEndpoints,
                    isInEditMode: true
                });
            }.bind(this)
        });
        return section;
    }

    _handleUnlinkButtonTapped() {
        var deletingPrms = ActiveMSComponent.deleteAlexaPlugin();
        NavigationComp.showWaitingFor(deletingPrms, _('alexa.plugin.delete')).then(function () {
            return this.ViewController.dismiss();
        }.bind(this));
    }

    _updateEndpoints() {
        if (this._isDifferentUser) {
            return Q.resolve();
        } else {
            return ActiveMSComponent.getEndpoints(true).then(function (endpoints) {
                this._availableEndpoints = endpoints;
            }.bind(this));
        }
    }

    _getUserMailAddress() {
        if (this._isDifferentUser) {
            return Q.resolve();
        } else {
            return ActiveMSComponent.getAlexaUserMail().then(function (mail) {
                this._userMail = mail;
            }.bind(this));
        }
    }

}

GUI.AlexaConfiguredScreen = AlexaConfiguredScreen;
