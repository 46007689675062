import React, {useEffect, useRef} from "react";
import {View, StyleSheet} from "react-native-web";
import PropTypes from "prop-types";

function LxReactImageView(props) {
    var ref = useRef(null);
    const getElement = () => {
        return $(ref.current);
    }

    let renderedIcon = useRef(null);
    let renderdIconDOMNode = useRef(null);

    const cleanIconSrc = (text) => {
        return text.replaceAll("\n", "").replaceAll("\r", "");
    }

    //needs to be checked because maybe while an image is downloading the source has changed, so the downloaded image has to be ignored
    const isCorrectSource = (iconSrc) => {
        return renderedIcon.current === iconSrc
    }

    const prepareIcon = (iconSrc = Icon.DEFAULT) => {
        try {
            iconSrc = cleanIconSrc(iconSrc)
            var isIconElement = iconSrc.startsWith("<svg"),
                isHttpIcon = /^(?:https?|app|file|data):/.test(iconSrc),
                isMsIcon = !isHttpIcon && !isIconElement && !iconSrc.startsWith("resources/"),
                isResourcesIcon = iconSrc.startsWith("resources/") && iconSrc.endsWith(".svg");

            // store ref to avoid unnecessary re-renders
            renderedIcon.current = iconSrc;

            if (isMsIcon) {
                ImageBox.getImage(iconSrc).then(image => {
                    let iconElement = null;
                    if (image.mimeType === "svg") {
                        iconElement = ImageBox.addClassesToSVG(image.image, props.iconClass);
                    } else if (image.mimeType === "png") {
                        iconElement = document.createElement("img");
                        iconElement.src = image.image;
                    }
                    isCorrectSource(iconSrc) && showIcon(iconElement);
                });
            } else if (isIconElement) {
                isCorrectSource(iconSrc) && showIcon(iconSrc);
            } else if (isResourcesIcon) {
                isCorrectSource(iconSrc) && showIcon(ImageBox.getResourceImage(iconSrc));
            } else {
                if (iconSrc.endsWith(".svg")) {
                    fetch(iconSrc).then(res => res.text()).then(svgText => isCorrectSource(iconSrc) && showIcon(svgText));
                } else {
                    let iconElement = document.createElement("img");
                    iconElement.src = iconSrc;
                    iconElement.style.objectFit = "scale-down";
                    isCorrectSource(iconSrc) && showIcon(iconElement);
                }
            }
        } catch (e) {
            //nothing to do here ;D
        }

    }

    let applySvgRetryTimeout = useRef(0);

    const applyTransformations = (svgNode, retryCnt = 0) => {

        if (applySvgRetryTimeout.current) {
            clearTimeout(applySvgRetryTimeout.current);
            applySvgRetryTimeout.current = null;
        }

        let failed = false;
        if (props.transformations) {
            Object.keys(props.transformations).forEach(elemId => {
                let svgPart = svgNode.getElementById(elemId);
                if (svgPart) {
                    svgPart.setAttribute("transform", props.transformations[elemId]);
                } else {
                    failed = true;
                }
            });
        }

        //TODO-schwama - fix issue & remove workaround
        if (failed) {
            if (retryCnt < 10) {
                console.warn(LxReactImageView.name, "applyTransformations failed! retry later! " + retryCnt);
                applySvgRetryTimeout.current = setTimeout(() => {
                    console.warn(LxReactImageView.name, "applyTransformations - retrying! " + (retryCnt + 1));
                    applyTransformations(renderdIconDOMNode.current, retryCnt + 1)
                }, 10);
            } else {
                console.error(LxReactImageView.name, "applyTransformations failed! STOP trying!");
            }

        }
    };

    /**
     *  RN1-I507 - custom icons where shown too large, since the style must be HTML and not react
     * @param key       e.g. width/height
     * @param value
     * @returns {string}
     * @private
     */
    const _ensureHtmlStyleProp = (key, value) => {
        let result = value;
        switch (key) {
            case "width":
            case "height":
                if (typeof value === "number") {
                    result = `${value}px`;
                }
                break;
            default:
                break;
        }
        return result;
    }

    const applyImageStyles = (imageNode) => {
        if (props.imageStyle) {
            Object.keys(props.imageStyle).forEach(key => {
                imageNode.style[key] = _ensureHtmlStyleProp(key, props.imageStyle[key]);
            });
        }
    }

    const applySvgPartStyles = (svgNode) => {
        if (props.partFillColors) {
            Object.keys(props.partFillColors).forEach(partName => {
                $(svgNode).find("*").filter(partName).attr("style", "fill: " +props.partFillColors[partName]);
            })
        }
    }

    const applyOperations = (imageNode) => {
        applyImageStyles(imageNode);
        if (imageNode.tagName === "svg") {
            applySvgPartStyles(imageNode)
            applyTransformations(imageNode)
        }
    }

    const showIcon = (iconElement) => {
        if (ref.current) {
            if (getElement().children().length > 0) {
                getElement().empty();
            }
            getElement().append(iconElement);

            let iconDOMNode = getElement().children().first()[0];
            if (iconDOMNode) {
                renderdIconDOMNode.current = iconDOMNode;
                applyOperations(iconDOMNode)
            }
        }
    }

    useEffect(() => {
        if (renderedIcon.current === props.source) {
            // don't prepare again, just adopt styles
            renderdIconDOMNode.current && applyOperations(renderdIconDOMNode.current);
        } else {
            prepareIcon(props.source);
        }
        return () => {
            //TODO-schwama: remove workaround
            clearTimeout(applySvgRetryTimeout.current);
        }
    }); // Don't add deps or carnage will happen!

    return (
        <View ref={ref} style={[styles.container, StyleSheet.flatten(props.containerStyle)]}/>
    )
}

export default React.memo(LxReactImageView);

const styles = {
    container: {
        flex: 1,
        justifyContent: "center"
    }
}

LxReactImageView.propTypes = {
    source: PropTypes.string,
    containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), //previous "style", react style of container
    imageStyle: PropTypes.object, //previous "svgStyle", style of image element, ATTENTION: style properties must be in html style not in react
    transformations: PropTypes.object,
    iconClass: PropTypes.string,
    partFillColors: PropTypes.object
}
