"use strict";

define(['JalousieControlContentConfigHelpScreen', 'JalousieControlEnums'], function (JalousieControlContentConfigHelpScreen, JalousieControlEnums) {
    return class JalousieControlContentConfigWaitingScreen extends GUI.Screen {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return $('<div class="jalousie-control-content-config-waiting-screen">' + '   <div class="jalousie-control-content-config-waiting-screen__loading-indicator-container">' + '       <div class="loading-indicator-container__info-button">' + '       ' + ImageBox.getResourceImageWithClasses("resources/Images/ActiveMiniserver/Menu/icon-info.svg", "info-button__icon") + '       </div>' + '       <div class="loading-indicator-container__spinner css-spinner-a" />' + '   </div>' + '   <div class="jalousie-control-content-config-waiting-screen__text-container">' + '       <div class="text-container__title">' + _("controls.jalousie.waiting.title") + '       </div>' + '       <div class="text-container__sub-title-container">' + _("controls.jalousie.waiting.subtitle") + '       </div>' + '   </div>' + '</div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(details) {
            let mode = details.mode;
            let step = details.step;
            super(JalousieControlContentConfigWaitingScreen.Template.getTemplate(mode, step));
            this.mode = details.mode;
            this.step = details.step;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.infoButtonElement = this.element.find(".loading-indicator-container__info-button");

            if (this.mode === JalousieControlEnums.ConfigModes.ADJUST_SLATS && (this.step === JalousieControlEnums.ConfigSteps.SLAT_ADJUSTMENT.MOVING_UP || this.step === JalousieControlEnums.ConfigSteps.SLAT_ADJUSTMENT.MOVING_DOWN)) {
                this.infoButton = new GUI.LxButton(this, this.infoButtonElement[0], window.Styles.colors.green, null, true);
                this.infoButton.setActiveParts([{
                    part: this.infoButtonElement.find(".info-button__icon")[0],
                    mode: "fill"
                }]);
                this.addToHandledSubviews(this.infoButton);
                this.infoButton.onButtonReleased = this._handleInfoButton.bind(this);
            } else {
                this.infoButtonElement.hide();
            }
        }

        getURL() {
            return "Waiting-" + this.mode + "-" + this.step;
        }

        getAnimation() {
            return AnimationType.FADE;
        }

        _handleInfoButton() {
            new JalousieControlContentConfigHelpScreen().show();
        }

    };
});
