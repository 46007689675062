// Fixed Setpoint Target Value & Mode Bit-Scheme
//  Value = xxxx xxxx vvvv vvvv vvvv vvvv ffff ffff
//  Flags = ffff ffff (lower 8 bits)
//          5th bit set = it's a custom target value, with heat/cool info   (0001 0000)
//          6th bit set = use heating to reach the target                   (0011 0000)
//          7th bit set = use cooling to reach the target                   (0101 0000)
//          both 7&8 set = use heating & cooling to reach the target        (0111 0000)
//  Target Value = vvvv vvvv vvvv vvvv (16 bits signed int => targetVal * 10)


/**
 * Provides static functions to convert a custom-setpoint-value to target-temp + heat/cool mode setting and vice versa.
 * - isFixedSetpointValue(value) --> returns boolean (whether or not it's a number)
 * - extractFromValue(value: number) --> returns { target:number, heat:boolean, cool:boolean }
 * - convertToValue(target:number, heat:boolean, cool:boolean) --> returns number (which holds those props masked)
 */
class Util {

    static isFixedSetpointValue(value) {
        let intValue = parseInt(value);
        return _hasBit(intValue, FIXED_SETPOINT_ENTRY_FLAG)
    }

    static isHeatingAllowed(value) {
        let intValue = parseInt(value);
        return _hasBit(intValue, FIXED_SETPOINT_HEATING_FLAG);
    }

    static isCoolingAllowed(value) {
        let intValue = parseInt(value);
        return _hasBit(intValue, FIXED_SETPOINT_COOLING_FLAG);
    }

    static isHeatCoolAllowed(value) {
        return Util.isCoolingAllowed(value) && Util.isHeatingAllowed(value);
    }

    static extractFromValue(value) {
        if (!Util.isFixedSetpointValue(value)) {
            return null;
        }
        Debug.Val2Obj && console.log(Debug.Name, "extractFromValue: " + value);

        let intVal = parseInt(value),
            heat = Util.isHeatingAllowed(intVal),
            cool = Util.isCoolingAllowed(intVal),
            target = parseInt(intVal);

        // then read the target temp value
        target = target & SelectMask.TargetOnly;
        Debug.Val2Obj && console.log(Debug.Name, "     #target:         " + _num2binStr(target));

        // shift to right, so only the are properly set
        target = target >>> 8;
        Debug.Val2Obj && console.log(Debug.Name, "     #shifted:        " + _num2binStr(target));

        // check if target value is negative (highest bit is set, 1000 0000 0000 0000)
        const signCheck = target >>> 15;
        Debug.Val2Obj && console.log(Debug.Name, "     #signCheck:      " + _num2binStr(signCheck));
        if (signCheck === 1) { // supposed to be negative
            // -2,5° = -25 = 1111111111100111
            // +2,5° = +25 = 0000000000011001

            // -2,4° = -24 = 1111111111101000
            // +2,4° = +24 = 0000000000011000
            target = _clearBit(SelectMask.Int16, target);
            Debug.Val2Obj && console.log(Debug.Name, "           #inverted:      " + _num2binStr(target));
            target = target + 1;
            Debug.Val2Obj && console.log(Debug.Name, "           #value:            " + target);
            target = target * -1;
        }

        // now convert to proper values
        target = target / 10;

        Debug.Val2Obj && console.log(Debug.Name, "    --> res target value:         " + _num2binStr(target) + " = " + target);

        Debug.Base && console.log(Debug.Name, "extractFromValue: " + intVal + " (" + _num2binStr(intVal) + ")" + " => T=" + target + ", H="+ heat + ", C=" + cool);
        return {target, heat, cool};
    }

    static convertToValue(target = 22.5, heat = false, cool = false) {
        Debug.Obj2Val && console.log(Debug.Name, "convertToValue: " + target + ", heat=" + heat + ", cool=" + cool);
        let value = Math.floor(target * 10);
        Debug.Obj2Val && console.log(Debug.Name, "           #raw value:        " + _num2binStr(value));

        const isNegative = value < 0;
        value = Math.abs(value);
        Debug.Obj2Val && console.log(Debug.Name, "           #absolute val:     " + _num2binStr(value));

        if (isNegative) {
            value = _clearBit(SelectMask.Int16, value);
            value = value + 1;
            Debug.Obj2Val && console.log(Debug.Name, "           #conv to neg:      " + _num2binStr(value));
        }

        // move the target to the left.
        value = value << 8
        Debug.Obj2Val &&  console.log(Debug.Name, "           #shifted:          " + _num2binStr(value));

        // ensure no other values are set.
        value = value & SelectMask.TargetOnly;
        Debug.Obj2Val && console.log(Debug.Name, "           #targetOnly:       " + _num2binStr(value));

        // set the custom temp value bit.
        value = _setBit(value, FIXED_SETPOINT_ENTRY_FLAG);
        Debug.Obj2Val && console.log(Debug.Name, "           #setPoint-Flag:    " + _num2binStr(value));

        // if required, set the heating and cooling bits
        if (heat) {
            value = _setBit(value, FIXED_SETPOINT_HEATING_FLAG);
        }
        if (cool) {
            value = _setBit(value, FIXED_SETPOINT_COOLING_FLAG);
        }
        Debug.Obj2Val && console.log(Debug.Name, "           #heatCool-Flag:    " + _num2binStr(value));

        Debug.Base && console.log(Debug.Name, "convertToValue: T=" + target + ", H="+ heat + ", C=" + cool + " => " + value + " (" + _num2binStr(value) + ")");
        return value;
    }
}

/*
class Test {
    static run() {
        const fails = [], specs = [];
        specs.push(Test._heatOnlyTest());
        specs.push(Test._coolOnlyTest());
        specs.push(Test._noHeatCoolTest());
        specs.push(Test._heatAndCoolTest());
        specs.push(Test._negativeHeatCoolTest());
        specs.push(Test._negativeHeatCoolTest2());
        specs.push(Test._negativeHeatCoolTest3());
        specs.push(Test._zeroHeatCoolTest());

        let testRes;
        specs.forEach(spec => {
            testRes = Test._runSpec(spec.testValue, spec.target, spec.heat, spec.cool);
            if (testRes.length > 0) {
                fails.push([spec.name, ...testRes].join(", "));
            }
        });

        if (fails.length > 0) {
            console.error(ebug.TestName, "Tests failed! (" + fails.length + " of " + specs.length + ")");
            fails.forEach(fail => {
                console.error(ebug.TestName, "       " + fail);
            });
            return false;
        }
        Debug.Test && console.log(Debug.TestName, "Tests succeeded! (" + specs.length + ")");
        return true;
    }

    static _heatOnlyTest() {
        return Test._getSpec(57648.0, 22.5, true, false, "heatOnly");
    }

    static _coolOnlyTest() {
        return Test._getSpec(57680.0, 22.5, false, true, "coolOnly");
    }

    static _noHeatCoolTest() {
        return Test._getSpec(57616.0, 22.5, false, false, "noHeatCool");
    }

    static _heatAndCoolTest() {
        return Test._getSpec(57712.0, 22.5, true, true, "heatAndCool");
    }

    static _negativeHeatCoolTest() {
        return Test._getSpec(16770928, -2.5, true, true, "negativeHeatCool -2,5");
    }

    static _negativeHeatCoolTest2() {
        return Test._getSpec(16771184, -2.4, true, true, "negativeHeatCool -2,4");
    }
    static _negativeHeatCoolTest3() {
        return Test._getSpec(16745328, -12.5, true, true, "negativeHeatCool -12,5");
    }
    static _zeroHeatCoolTest() {
        return Test._getSpec(112, 0, true, true, "zeroHeatCool 0°");
    }

    static _getSpec(testValue, target, heat, cool, name) {
        return {
            testValue, target, heat, cool, name
        }
    }

    static _runSpec(testValue, target, heat, cool) {
        const fails = [];
        const info = Util.extractFromValue(testValue);
        (info.target !== target) && fails.push("Target extracted from testValue doesn't fit (testValue: " + testValue + ", extracted: " + info.target + ", should be: " + target);
        (info.heat !== heat) && fails.push("Heating flag extracted from testValue doesn't fit (testValue: " + testValue + ", extracted: " + info.heat + ", should be: " + heat);
        (info.cool !== cool) && fails.push("Cooling flag extracted from testValue doesn't fit (testValue: " + testValue + ", extracted: " + info.cool + ", should be: " + cool);

        const resValue = Util.convertToValue(target, heat, cool);
        (resValue !== testValue) && fails.push("Converting obj to value failed: extracted: " + resValue + ", should be: " + testValue);

        return fails;
    }
}
*/

const FIXED_SETPOINT_ENTRY_FLAG = 16;      // 0001 0000 => 5th bit.
const FIXED_SETPOINT_HEATING_FLAG = 32;    // 0010 0000 => 6th bit
const FIXED_SETPOINT_COOLING_FLAG = 64;    // 0100 0000 => 7th bit

const SelectMask = {
    FlagsOnly: 112,         // 0000 0000 0000 0000 0000 0000 0111 0000
    TargetOnly: 16776960,   // 0000 0000 1111 1111 1111 1111 0000 0000
    Int16: 65535,           // 0000 0000 0000 0000 1111 1111 1111 1111
}

// region helper-fns

/**
 * Util fn for debugging, converts a number into a string representing the binary structure
 * Note: it does only prefix it with a minus if it's negative, but does not show the proper format.
 * @param num
 * @returns {string}
 * @private
 */
const _num2binStr = (num)  => {
    var sign = (num < 0 ? "-" : "");
    var result = Math.abs(num).toString(2);
    while (result.length < 32) {
        result = "0" + result;
    }
    return sign + result;
}

const _setBit = (value, bit) => {
    value |= bit;
    return value;
}

const _clearBit = (value, bit) => {
    value &= ~bit;
    return value;
}

const _hasBit = (value, bit) => {
    return (value & bit) === bit;
}

// endregion helper-fns


const Debug = {
    Base: false,
    Test: false,
    Val2Obj: false,
    Obj2Val: false,
    Name: "IrcFixedSetpoint-Util",
    TestName: "IrcFixedSetpoint-Test"
};


export {
    Util
};

