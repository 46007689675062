import * as React from "react"
import Svg, { Path } from "react-native-svg"

function PauseCircleFilled(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M12 24c6.617 0 12-5.383 12-12 0-6.616-5.383-12-12-12S0 5.384 0 12c0 6.617 5.383 12 12 12zM7 7.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v9a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-9zm6 9v-9a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v9a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5z"
            />
        </Svg>
    )
}

export default PauseCircleFilled
