/**
 * Created by loxone on 20.06.16.
 */
'use strict';

define(['AddMediaViewControllerV2Playlist'], function (AddMediaViewControllerV2Playlist) {
    class AddMediaViewControllerV2PlaylistSpotify extends AddMediaViewControllerV2Playlist {
        //region Private
        // We can't add any of these items to a Spotify Playlist!
        BANNED_CUSTOMIZATION_KEYS = [MusicServerEnum.ControlContentMenuId.PLAYLISTS, MusicServerEnum.ControlContentMenuId.RADIO, MusicServerEnum.ControlContentMenuId.LIB, MusicServerEnum.ControlContentMenuId.LINE_IN, MusicServerEnum.ControlContentMenuId.SOUNDSUIT]; //endregion Private

        constructor(details) {
            super(...arguments);
            this.control.audioserverComp.fillWithInputCustomizationKeys(this.BANNED_CUSTOMIZATION_KEYS);
        }

        _getConfirmationText() {
            return _("media.notifications.playlist.spotify.add");
        }

    }

    GUI.AddMediaViewControllerV2PlaylistSpotify = AddMediaViewControllerV2PlaylistSpotify;
    return GUI.AddMediaViewControllerV2PlaylistSpotify;
});
