'use strict';
/**
 * Created by loxone on 02.06.17.
 */

define(["RemoteControlEnums"], function (RemoteControlEnums) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var ButtonClass = {
        DPAD: 'dpad',
        NPAD: 'npad',
        ON: 'on',
        OFF: 'off',
        EXIT: 'exit',
        VOL_DOWN: 'voldown',
        VOL_UP: 'volup',
        PRG_DOWN: 'chdown',
        PRG_UP: 'chup',
        GUIDE: 'guide',
        INFO: 'info',
        RETURN: 'return',
        MENU: 'menu',
        MUTE: 'mute',
        PLAY: 'play',
        PAUSE: 'pause',
        STOP: 'stop',
        PREVIOUS: 'previous',
        REWIND: 'backwards',
        FORWARD: 'forwards',
        NEXT: 'next',
        RED: 'red',
        GREEN: 'green',
        YELLOW: 'yellow',
        BLUE: 'blue',
        OK: 'ok',
        CANCEL: 'cancel'
    };
    var ButtonActions = {};
    var ROW_CLASS = "keyboard__button-row";
    var BTN_CLASS = "remote-keyboard__button";
    var DPAD_CLASS = "keyboard__dpad";
    var NPAD_CLASS = "keyboard__npad";
    var DPAD_BUTTON_CLASS = "dpad-large";
    var DPAD_BUTTON_CLASS_UP = "#Up";
    var DPAD_BUTTON_CLASS_DOWN = "#Down";
    var DPAD_BUTTON_CLASS_LEFT = "#Left";
    var DPAD_BUTTON_CLASS_RIGHT = "#Right";
    var DPAD_BUTTON_CLASS_UP_ARROW = "#Up-Arrow";
    var DPAD_BUTTON_CLASS_DOWN_ARROW = "#Down-Arrow";
    var DPAD_BUTTON_CLASS_LEFT_ARROW = "#Left-Arrow";
    var DPAD_BUTTON_CLASS_RIGHT_ARROW = "#Right-Arrow";
    var DPAD_BUTTON_CLASS_CENTER = "#Okay";
    var NPAD_DISPLAY_CLASS = "npad__display";
    return class RemoteKeyboard extends GUI.StateViewBase {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(details) {
                return getTopBar() + getNumPad() + getDPad() + getLowerBar();
            };

            var getTopBar = function getTopBar() {
                return '' + '<div class="' + ROW_CLASS + '">' + getButton(Icon.Remote.ON, ButtonClass.ON) + getButton(Icon.Remote.OFF, ButtonClass.OFF) + getButton(Icon.Remote.EXIT, ButtonClass.EXIT) + getButton(Icon.Remote.NPAD_R, ButtonClass.NPAD) + getButton(Icon.Remote.DPAD_R, ButtonClass.DPAD) + '</div>' + '<div class="' + ROW_CLASS + '">' + getButton(Icon.Remote.VOL_DOWN, ButtonClass.VOL_DOWN) + getButton(Icon.Remote.VOL_UP, ButtonClass.VOL_UP) + getButton(Icon.Remote.PRG_DOWN, ButtonClass.PRG_DOWN) + getButton(Icon.Remote.PRG_UP, ButtonClass.PRG_UP) + '</div>';
            };

            var getNumPad = function getNumPad() {
                return '' + '<div class="keyboard__pad ' + NPAD_CLASS + '">' + '<div class="' + NPAD_DISPLAY_CLASS + '"></div>' + '<div class="npad__row">' + getNumButton(1) + getNumButton(2) + getNumButton(3) + '</div>' + '<div class="npad__row">' + getNumButton(4) + getNumButton(5) + getNumButton(6) + '</div>' + '<div class="npad__row">' + getNumButton(7) + getNumButton(8) + getNumButton(9) + '</div>' + '<div class="npad__row">' + getButton(Icon.TitleBar.TICK, ButtonClass.OK) + getNumButton(0) + getButton(Icon.TitleBar.CLOSE, ButtonClass.CANCEL) + '</div>' + '</div>';
            };

            var getDPad = function getDPad() {
                return '' + '<div class="keyboard__pad ' + DPAD_CLASS + '">' + getButton(Icon.Remote.DPAD_LARGE, DPAD_BUTTON_CLASS) + getButton(Icon.Remote.MENU, ButtonClass.MENU) + getButton(Icon.Remote.INFO, ButtonClass.INFO) + getButton(Icon.Remote.RETURN, ButtonClass.RETURN) + getButton(Icon.Remote.GUIDE, ButtonClass.GUIDE) + '</div>';
            };

            var getLowerBar = function getLowerBar() {
                return '' + '<div class="' + ROW_CLASS + '">' + getButton(Icon.Remote.MUTE, ButtonClass.MUTE) + getButton(Icon.Remote.PLAY, ButtonClass.PLAY) + getButton(Icon.Remote.PAUSE, ButtonClass.PAUSE) + getButton(Icon.Remote.STOP, ButtonClass.STOP) + '</div>' + '<div class="' + ROW_CLASS + '">' + getButton(Icon.Remote.PREVIOUS, ButtonClass.PREVIOUS) + getButton(Icon.Remote.REWIND, ButtonClass.REWIND) + getButton(Icon.Remote.FORWARD, ButtonClass.FORWARD) + getButton(Icon.Remote.NEXT, ButtonClass.NEXT) + '</div>' + '<div class="' + ROW_CLASS + '">' + getButton(null, ButtonClass.RED) + getButton(null, ButtonClass.GREEN) + getButton(null, ButtonClass.YELLOW) + getButton(null, ButtonClass.BLUE) + '</div>';
            };

            var getButton = function getButton(icon, btnClass) {
                var iconElem = icon ? ImageBox.getResourceImageWithClasses(icon, "button__icon") : '';
                return '' + '<div class="' + BTN_CLASS + ' ' + btnClass + '">' + iconElem + '</div>';
            };

            var getNumButton = function getNumButton(num) {
                var btnClass = BTN_CLASS + "-" + num;
                return '' + '<div class="' + BTN_CLASS + ' ' + btnClass + '">' + '<div class="button__text">' + num + '</div>' + '</div>';
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(control) {
            super($('<div />'));
            this.control = control;
            this.numberString = "";

            this._prepareButtons();
        }

        viewDidLoad() {
            return Q.all([super.viewDidLoad(...arguments), GUI.animationHandler.append($(RemoteKeyboard.Template.getTemplate()), this.element)]).then(function afViewDidLoad() {
                this.elements = {};

                this._findElem(ROW_CLASS);

                this._findElem(DPAD_CLASS);

                this._findElem(NPAD_CLASS); // find elements & create buttons


                this.buttons = {};
                Object.values(ButtonClass).forEach(function (btnClass) {
                    this._findElem(btnClass);

                    this._createButton(btnClass);
                }.bind(this)); // find & create dpad specific buttons

                this.controlPad = this.element.find("." + DPAD_BUTTON_CLASS);
                this.elements[DPAD_BUTTON_CLASS_UP] = this.controlPad.find(DPAD_BUTTON_CLASS_UP);
                this.elements[DPAD_BUTTON_CLASS_DOWN] = this.controlPad.find(DPAD_BUTTON_CLASS_DOWN);
                this.elements[DPAD_BUTTON_CLASS_LEFT] = this.controlPad.find(DPAD_BUTTON_CLASS_LEFT);
                this.elements[DPAD_BUTTON_CLASS_RIGHT] = this.controlPad.find(DPAD_BUTTON_CLASS_RIGHT);
                this.elements[DPAD_BUTTON_CLASS_UP_ARROW] = this.controlPad.find(DPAD_BUTTON_CLASS_UP_ARROW);
                this.elements[DPAD_BUTTON_CLASS_DOWN_ARROW] = this.controlPad.find(DPAD_BUTTON_CLASS_DOWN_ARROW);
                this.elements[DPAD_BUTTON_CLASS_LEFT_ARROW] = this.controlPad.find(DPAD_BUTTON_CLASS_LEFT_ARROW);
                this.elements[DPAD_BUTTON_CLASS_RIGHT_ARROW] = this.controlPad.find(DPAD_BUTTON_CLASS_RIGHT_ARROW);
                this.elements[DPAD_BUTTON_CLASS_CENTER] = this.controlPad.find(DPAD_BUTTON_CLASS_CENTER);

                this._createButton(DPAD_BUTTON_CLASS_UP);

                this._createButton(DPAD_BUTTON_CLASS_DOWN);

                this._createButton(DPAD_BUTTON_CLASS_LEFT);

                this._createButton(DPAD_BUTTON_CLASS_RIGHT);

                this._createButton(DPAD_BUTTON_CLASS_UP_ARROW);

                this._createButton(DPAD_BUTTON_CLASS_DOWN_ARROW);

                this._createButton(DPAD_BUTTON_CLASS_LEFT_ARROW);

                this._createButton(DPAD_BUTTON_CLASS_RIGHT_ARROW);

                this._createButton(DPAD_BUTTON_CLASS_CENTER); // find & create npad specific buttons.


                var numClass;

                for (var i = 0; i < 10; i++) {
                    numClass = BTN_CLASS + "-" + i;

                    this._findElem(numClass);

                    this._createButton(numClass);
                }

                this.elements.display = this.element.find("." + NPAD_DISPLAY_CLASS);
                this.waitingView = new GUI.LxWaitingView(_("controls.remote.waiting-for-devices"), null, true);
                this.waitingView.getElement().addClass("remote-keyboard__waiting-view");
                this.waitingView.setTranslucent(); // BG-I1853: They are hidden because, they destroy the view if now states are available

                GUI.animationHandler.schedule(function () {
                    this.elements[DPAD_CLASS].hide();
                    this.elements[NPAD_CLASS].hide();
                    this.elements[ROW_CLASS].hide();
                }.bind(this));
                this.appendSubview(this.waitingView);
            }.bind(this));
        }

        viewWillAppear() {
            var promise = super.viewWillAppear(...arguments);
            var button; // register buttons for UI interaction

            Object.keys(this.buttons).forEach(function (key) {
                button = this.buttons[key];

                switch (key) {
                    case ButtonClass.DPAD:
                    case ButtonClass.NPAD:
                        button.onButtonTapped = function () {
                            this._showNumPad(!this.numPadShown);
                        }.bind(this);

                        break;

                    default:
                        this._registerButtonAction(key, button);

                        break;
                }
            }.bind(this));
            return promise;
        }

        viewDidDisappear() {
            Object.values(this.buttons).forEach(function (btn) {
                btn.onButtonTapped = null;
                btn.onButtonHit = null;
                btn.onButtonReleased = null;
            });

            this._stopCmdInterval();

            return super.viewDidDisappear(...arguments);
        }

        /**
         * Returns the uuid of the state container to register with.
         * @return {string}
         */
        getStateContainerUuid() {
            return this.control.uuidAction;
        }

        receivedStates(states) {
            var showNumPad = false,
                showWaiting = states.needsWaiting;
            this.states = states;

            this._handleShowWaitingChange(showWaiting);

            if (Feature.REMOTE_PER_MODE_FAVPAD) {
                var currentMode = this.control.details.modeList[states.activeMode];

                if (currentMode) {
                    showNumPad = currentMode.favoritePad === RemoteControlEnums.Pads.NUMPAD;
                }
            }

            this._showNumPad(showNumPad, showWaiting);
        }

        _handleShowWaitingChange(showWaiting) {
            if (showWaiting && this.states.isOff) {
                this.waitingView.setTitle(_("controls.remote.waiting-for-devices-to-turn-off"));
            } else {
                this.waitingView.setTitle(_("controls.remote.waiting-for-devices"));
            }

            this.toggleSubview(this.waitingView, showWaiting);
        }

        _findElem(classStr) {
            var lookupStr = "." + classStr;
            this.elements[classStr] = this.element.find(lookupStr);
        }

        /**
         * Will create a button for this ID, will look up the element from the elements set via the ID provided
         * @param btnId
         * @private
         */
        _createButton(btnId) {
            var elem = this.elements[btnId];

            if (!elem || elem.length === 0) {
                return;
            }

            this.buttons[btnId] = new GUI.LxButton(this, elem[0], Color.BUTTON_GLOW, null, true);

            if (this._shouldUseFillAsActivePart(btnId)) {
                this.buttons[btnId].useElementAsActivePart('fill');
            } else {
                this.buttons[btnId].useElementAsActivePart('background');
            }

            this.addToHandledSubviews(this.buttons[btnId]);
        }

        /**
         * The core buttons of the dpad are being colored by "fill" instead of using a background color. That is why
         * here the activity-feedback needs to be shown using fill instead of background.
         * @param btnId
         * @return {boolean}
         * @private
         */
        _shouldUseFillAsActivePart(btnId) {
            var result = false;

            switch (btnId) {
                case DPAD_BUTTON_CLASS_UP:
                case DPAD_BUTTON_CLASS_DOWN:
                case DPAD_BUTTON_CLASS_LEFT:
                case DPAD_BUTTON_CLASS_RIGHT:
                case DPAD_BUTTON_CLASS_CENTER:
                    result = true;
                    break;

                default:
                    break;
            }

            return result;
        }

        /**
         * Will show/hide the n-/d-pad based on the arguments provided.
         * @param doShow
         * @param hideBoth  used e.g. when the waiting screen is to be shown
         * @private
         */
        _showNumPad(doShow, hideBoth) {
            var showNumpad, showDPad;
            this.numPadShown = doShow;

            if (hideBoth) {
                showNumpad = false;
                showDPad = false;
            } else {
                showNumpad = doShow;
                showDPad = !doShow;
            }

            GUI.animationHandler.schedule(function () {
                if (showNumpad) {
                    this.elements[NPAD_CLASS].css("display", "");
                    this.elements[ButtonClass.NPAD].hide();
                } else {
                    this.elements[NPAD_CLASS].hide();
                    this.elements[ButtonClass.NPAD].css("display", "");
                }

                if (showDPad) {
                    this.elements[DPAD_CLASS].css("display", "");
                    this.elements[ButtonClass.DPAD].hide();
                } else {
                    this.elements[DPAD_CLASS].hide();
                    this.elements[ButtonClass.DPAD].css("display", "");
                }

                if (hideBoth) {
                    this.elements[ROW_CLASS].hide();
                } else {
                    this.elements[ROW_CLASS].css("display", "");
                }
            }.bind(this));
        }

        /**
         * Will look up the corresponding actions for each button id based on the buttonActions set.
         * @param btnId
         * @param button
         * @private
         */
        _registerButtonAction(btnId, button) {
            var actionObj = ButtonActions[btnId];

            if (!actionObj) {
                return;
            }

            if (actionObj.tapFn) {
                button.onButtonTapped = actionObj.tapFn;
            } else if (actionObj.tap) {
                button.onButtonTapped = this._sendCommand.bind(this, actionObj.tap);
            }

            if (actionObj.hitFn) {
                button.onButtonHit = actionObj.hitFn;
            } else if (actionObj.hit) {
                button.onButtonHit = function () {
                    this._sendCommand(actionObj.hit);

                    this._startCmdInterval(actionObj.hit);
                }.bind(this);
            }

            if (actionObj.releaseFn) {
                button.onButtonReleased = actionObj.releaseFn;
            } else if (actionObj.release) {
                button.onButtonReleased = function () {
                    this._stopCmdInterval();

                    this._sendCommand(actionObj.release);
                }.bind(this);
            }
        }

        /**
         * Will send the cmd(s) provided.
         * @param cmd   either a single cmd string or an array of cmds to send.
         * @private
         */
        _sendCommand(cmd) {
            var cmdArr = Array.isArray(cmd) ? cmd : [cmd];
            cmdArr.forEach(function (cmdStr) {
                this.control.sendCommand(cmdStr);
            }.bind(this));
        }

        /**
         * Will prepare the buttonActions-set. For each button there will be an action predefined.
         * @private
         */
        _prepareButtons() {
            this._prepareButtonCmdAction(ButtonClass.ON, Commands.REMOTE.ON);

            this._prepareOffButton();

            this._prepareButtonCmdAction(ButtonClass.EXIT, Commands.REMOTE.EXIT); // There mustn't be any double-tap features via app. T5/V+/V- via block inputs is okay


            this._prepareInteractive(ButtonClass.VOL_DOWN, Commands.REMOTE.VOL_MINUS, Commands.REMOTE.VOL_MINUS_OFF);

            this._prepareInteractive(ButtonClass.VOL_UP, Commands.REMOTE.VOL_PLUS, Commands.REMOTE.VOL_PLUS_OFF);

            this._prepareInteractive(ButtonClass.PRG_DOWN, Commands.REMOTE.PRG_MINUS, Commands.REMOTE.PRG_MINUS_OFF);

            this._prepareInteractive(ButtonClass.PRG_UP, Commands.REMOTE.PRG_PLUS, Commands.REMOTE.PRG_PLUS_OFF);

            this._prepareButtonCmdAction(ButtonClass.MUTE, Commands.REMOTE.MUTE);

            this._prepareButtonCmdAction(ButtonClass.PLAY, Commands.REMOTE.PLAY);

            this._prepareButtonCmdAction(ButtonClass.PAUSE, Commands.REMOTE.PAUSE);

            this._prepareButtonCmdAction(ButtonClass.STOP, Commands.REMOTE.STOP);

            this._prepareButtonCmdAction(ButtonClass.PREVIOUS, Commands.REMOTE.PREVIOUS);

            this._prepareButtonCmdAction(ButtonClass.REWIND, Commands.REMOTE.REWIND);

            this._prepareButtonCmdAction(ButtonClass.FORWARD, Commands.REMOTE.FORWARD);

            this._prepareButtonCmdAction(ButtonClass.NEXT, Commands.REMOTE.NEXT);

            this._prepareButtonCmdAction(ButtonClass.RED, Commands.REMOTE.RED);

            this._prepareButtonCmdAction(ButtonClass.GREEN, Commands.REMOTE.GREEN);

            this._prepareButtonCmdAction(ButtonClass.BLUE, Commands.REMOTE.BLUE);

            this._prepareButtonCmdAction(ButtonClass.YELLOW, Commands.REMOTE.YELLOW);

            this._prepareDPadButtons();

            this._prepareNumPadButtons();
        }

        /**
         * Will put the proper actions of the dPad-View on the ButtonActions Set
         * @private
         */
        _prepareDPadButtons() {
            this._prepareButtonCmdAction(ButtonClass.MENU, Commands.REMOTE.MENU);

            this._prepareButtonCmdAction(ButtonClass.INFO, Commands.REMOTE.INFO);

            this._prepareButtonCmdAction(ButtonClass.GUIDE, Commands.REMOTE.GUIDE);

            this._prepareButtonCmdAction(ButtonClass.RETURN, Commands.REMOTE.RETURN);

            this._prepareInteractive(DPAD_BUTTON_CLASS_UP, Commands.REMOTE.UP, Commands.REMOTE.UP_OFF);

            this._prepareInteractive(DPAD_BUTTON_CLASS_DOWN, Commands.REMOTE.DOWN, Commands.REMOTE.DOWN_OFF);

            this._prepareInteractive(DPAD_BUTTON_CLASS_LEFT, Commands.REMOTE.LEFT, Commands.REMOTE.LEFT_OFF);

            this._prepareInteractive(DPAD_BUTTON_CLASS_RIGHT, Commands.REMOTE.RIGHT, Commands.REMOTE.RIGHT_OFF);

            this._prepareInteractive(DPAD_BUTTON_CLASS_UP_ARROW, Commands.REMOTE.UP, Commands.REMOTE.UP_OFF);

            this._prepareInteractive(DPAD_BUTTON_CLASS_DOWN_ARROW, Commands.REMOTE.DOWN, Commands.REMOTE.DOWN_OFF);

            this._prepareInteractive(DPAD_BUTTON_CLASS_LEFT_ARROW, Commands.REMOTE.LEFT, Commands.REMOTE.LEFT_OFF);

            this._prepareInteractive(DPAD_BUTTON_CLASS_RIGHT_ARROW, Commands.REMOTE.RIGHT, Commands.REMOTE.RIGHT_OFF);

            this._prepareButtonCmdAction(DPAD_BUTTON_CLASS_CENTER, Commands.REMOTE.OK);
        }

        /**
         * Will put the proper actions for each of the number pad buttons on the ButtonActions set.
         * @private
         */
        _prepareNumPadButtons() {
            var numClass;

            for (var i = 0; i < 10; i++) {
                numClass = BTN_CLASS + "-" + i;

                this._prepareButtonActionFn(numClass, this._handleNumberTap.bind(this, i));
            }

            this._prepareButtonActionFn(ButtonClass.OK, this._sendNumber.bind(this));

            this._prepareButtonActionFn(ButtonClass.CANCEL, this._clearDisplay.bind(this));
        }

        /**
         * Prepares & stores a button action bojcet that contains a function to be called when a button is tapped.
         * @param id
         * @param fn
         * @private
         */
        _prepareButtonActionFn(id, fn) {
            ButtonActions[id] = {
                id: id,
                tapFn: fn
            };
        }

        /**
         * Prepares & stores a button action object that contains cmds to send when a button is tapped/hit/released.
         * @param id
         * @param tap
         * @param hit
         * @param release
         * @private
         */
        _prepareButtonCmdAction(id, tap, hit, release) {
            ButtonActions[id] = {
                id: id,
                tap: tap,
                hit: hit,
                release: release
            };
        }

        /**
         * The off button response depends on a Miniserver feature.
         * @private
         */
        _prepareOffButton() {
            var cmd;

            if (Feature.REMOTE_CONTROL_RESET_COMMAND) {
                cmd = Commands.REMOTE.RESET;
            } else {
                cmd = Commands.REMOTE.OFF;
            }

            this._prepareButtonCmdAction(ButtonClass.OFF, cmd);
        }

        _prepareInteractive(id, hit, release) {
            this._prepareButtonCmdAction(id, [hit, release], hit, release);
        }

        _startCmdInterval(cmd) {
            this.cmdInterval = setInterval(this._sendCommand.bind(this, cmd), 500);
        }

        _stopCmdInterval() {
            clearInterval(this.cmdInterval);
        }

        // ------- NR BUTTONS --------------

        /**
         * Handles the number tap. Sends a cmd right away and also starts the send timeout.
         * @param nr
         * @private
         */
        _handleNumberTap(nr) {
            // check if a timeout is provided, if so a number with several digits may be entered before being sent to the Minsierver.
            if (this.states.timeout > 0) {
                this.numberString += nr;
                this.elements.display.text(this.numberString); // number updated, (re-)start the send timeout.

                this.sendTimeout && clearTimeout(this.sendTimeout);
                this.sendTimeout = setTimeout(this._sendNumber.bind(this), this.states.timeout);
            } // send the button hit right away


            this._sendCommand(Commands.format(Commands.REMOTE.NUMF, nr));
        }

        /**
         * Used either when the clear button is used or when the number is sent.
         * @private
         */
        _clearDisplay() {
            this.numberString = "";
            this.elements.display.text("");
            clearTimeout(this.sendTimeout);
            this.sendTimeout = null;
        }

        _sendNumber() {
            this._sendCommand(Commands.format(Commands.REMOTE.TOTAL_NUMBERF, this.numberString));

            this._clearDisplay();
        }

    };
});
