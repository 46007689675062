'use strict';

/**
 * Displayed round centered collectionCards
 */

class CenteredCardView extends GUI.CardView {
    constructor(dataSource, delegate, elem) {
        super(dataSource, delegate, elem);
    }

    // Do nothing, this
    _getTableViewSpecificCellClass() {
        return "specific-cell-class--cells-container__centered-card-cell";
    }

}

GUI.CenteredCardView = CenteredCardView;
