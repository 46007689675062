import {View} from "react-native";
import globalStyles from "GlobalStyles";
import {
    LxReactText,
    navigatorConfig,
    LxReactHeader,
    LxReactTableView,
    useBackNavigation,
    LxReactButton, LxReactWaitingIndicator, ButtonType, LxReactPressable
} from "../Components";
import Icons from "IconLib";
import useFoundMiniservers from "./useFoundMiniservers";
import {useMemo} from "react";
import PairingConnectScreen from "./PairingConnectScreen";
import ManualLoginScreen from "./ManualLoginScreen";

export default function ConnectToMsScreen({navigation}) {


    navigation.setOptions({
        ...navigatorConfig({
            title: ""
        })
    })

    useBackNavigation(() => {
        navigation.goBack();
    }, [])

    const msSearch = useFoundMiniservers();
    const noResults = !msSearch.started && !msSearch?.results?.length;

    const renderMsResult = (ms) => {
        return {
            title: ms.msName,
            subTitle: ms.localUrl,
            mainLeftContent: {
                comp: Icons.MiniserverLined,
                props: {
                    style: {
                        fill: globalStyles.colors.stateActive,
                        width: globalStyles.sizes.icons.regular,
                        height: globalStyles.sizes.icons.regular
                    }
                }
            },
            onPress: () => {
                PairedAppComponent.startPairingWith(ms);
                navigation.navigate(PairingConnectScreen.name);
            }
        }
    }

    const tableContent = useMemo(() => {
        if (msSearch && msSearch.results && msSearch.results.length > 0) {
            return [
                {
                    rows: msSearch.results.map(ms => renderMsResult(ms))
                }
            ]
        }
        return [];
    }, [msSearch && msSearch.results]);

    const ConnectManuallyButton = ({asLink = false}) => {
        const action = () => {
                navigation.navigate(ManualLoginScreen.name);
            },
            title = _("managed-tablet.connect-manually")
        if (asLink) {
            return <LxReactPressable onPress={action}>
                <LxReactText style={styles.link}>{title}</LxReactText>
            </LxReactPressable>
        } else {
            return  <LxReactButton
                text={title}
                containerStyle={styles.footerButton}
                pressableStyle={styles.footerButtonCntnt}
                onPress={action}
                buttonType={ButtonType.SECONDARY}
                pkey={"connect-manually"} />
        }
    }

    const EmptyComp = () => {
        return (
            <View style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center"
            }}>
                <LxReactText style={{color: globalStyles.colors.text.secondary}}>
                    {
                        msSearch.hasWiFi ?
                            _("miniserverlist.add.search.no-devices-found") :
                            _("miniserverlist.add.search.no-lan-connection")
                    }
                </LxReactText>
                {msSearch.hasWiFi ? null : <ConnectManuallyButton asLink={true} />}
            </View>
        )
    }

    const StartStopSearchButton = () => {
        if (msSearch.started) {
            return <LxReactButton
                text={_("miniserverlist.add.search.cancel")}
                pkey={"stop-search"}
                onPress={() => {
                    MiniserverFinder.stopMiniserverFinder();
                }}
                containerStyle={styles.footerButton}
                pressableStyle={styles.footerButtonCntnt}
                buttonType={ButtonType.SECONDARY}
                leftIcon={
                () => {
                        return <LxReactWaitingIndicator size={"small"} style={{height: globalStyles.sizes.icons.small}}/>
                    }
                }
                />
        } else {
            return <LxReactButton
                text={_("miniserverlist.add.search.retry")}
                pkey={"start-search"}
                onPress={() => {
                    MiniserverFinder.startMiniserverFinder();
                }}
                containerStyle={styles.footerButton}
                pressableStyle={styles.footerButtonCntnt}
                buttonType={ButtonType.SECONDARY}
            />
        }
    }

    const ResultsView = () => {
        if(noResults) {
            return <EmptyComp />;
        } else {
            return <LxReactTableView tableContent={tableContent}
                                     containerStyle={styles.table}/>
        }
    }

    const SearchingInfo = () => {
        if (msSearch.started) {
            return <LxReactText style={styles.waitingText}>
                {_("miniserver.waiting.searching")}
            </LxReactText>
        } else {
            return null;
        }
    }

    const ScreenFooter = () => {
        if (noResults && !msSearch.hasWiFi) {
            return null;
        } else {
            return <View style={styles.footerButtonsCntr}>
                <StartStopSearchButton />
                <ConnectManuallyButton />
            </View>
        }
    }

    return <View style={styles.rootCntr}>
        <LxReactHeader
            title={_("miniserverlist.add.search.title")}
            subtitle={_("managed-tablet.select-from-results")}
            icon={Icons.Search}
            iconStyle={styles.hdrIcon}
            style={styles.hdr}
        />
        <ResultsView />
        <SearchingInfo />
        <ScreenFooter />
    </View>

}

const styles = {
    text: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.secondary
    },
    hdr: {
        flex: 0,
        minHeight: 240,
        marginBottom: globalStyles.spacings.gaps.bigger
    },
    hdrIcon: {
        width: 48,
        height: 48,
        fill: globalStyles.colors.stateActive
    },
    table: {
        flex: 1
    },
    footerButtonsCntr: {
        flexDirection: "row",
        width: "100%",
        marginTop: globalStyles.spacings.gaps.small,
        marginBottom: globalStyles.spacings.gaps.big
    },
    waitingText: {
        ...globalStyles.textStyles.subheadline.italic,
        color: globalStyles.colors.text.secondary,
        marginTop: globalStyles.spacings.gaps.verySmall,
        textAlign: "center"
    },
    rootCntr: {
        maxWidth: globalStyles.sizes.contentMaxWidth,
        marginHorizontal: "auto",
        flex: 1,
        flexDirection: "column"
    },
    footerButton: {
        width: "50%",
        marginHorizontal: globalStyles.spacings.gaps.smallest
    },
    footerButtonCntnt: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    link: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.stateActive,
        marginTop: globalStyles.spacings.gaps.medium
    }
}
