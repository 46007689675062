'use strict';
/**
 * Expects a device or extension object, then shows it's infos. Used in the deviceLearning screens.
 */

define([], function () {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var TableDebugAction = {
        SHUFFLE_VALUES: "ShuffleValues",
        SHUFFLE_TABLE: "ShuffleTable",
        REMOVE_SECTION: "RemoveSection",
        REMOVE_CELL: "RemoveCell",
        SHUFFLE_HEADERS: "ShuffleHeaders",
        ADD_CELL: "AddCell",
        ADD_SECTION: "AddSection",
        UNCHANGED_RELOAD: "UnchangedReload"
    };
    var DEFAULT_INSTRUCTION_TIMEOUT = 3000;
    return class TableViewDebugScreen extends GUI.TableViewScreenV2 {
        constructor(device) {
            super($('<div />'));
            this._maxRowsPerSection = 15;
            this._minRowsPerSection = 5;
            this._maxSections = 20;
            this._minSections = 10;
            this._randomValueMap = [];

            for (var i = 10000; i >= 0; i--) {
                this._randomValueMap[i] = getRandomIntInclusive(1, 1000) / getRandomIntInclusive(1, 5);
            }

            this.timings = [];
            this._instructionList = [];
            /*
            for (i = 0; i < 1; i++) {
                this._instructionList.push({
                    action: TableDebugAction.SHUFFLE_VALUES,
                    delay: 2000,
                    minRows: (this._minRowsPerSection - 4),
                    maxRows: (this._maxRowsPerSection - 4)
                });
            }*/
            //this._instructionList.push({action: TableDebugAction.REMOVE_SECTION, delay: 3000, middle: true});
            //this._instructionList.push({action: TableDebugAction.SHUFFLE_HEADERS, delay: 3000});
            //this._instructionList.push({ action: TableDebugAction.SHUFFLE_TABLE, delay: 3000 });

            for (i = 0; i < 40; i++) {
                this._instructionList.push({
                    action: TableDebugAction.SHUFFLE_TABLE,
                    delay: 500
                });
                /*this._instructionList.push({
                    action: TableDebugAction.SHUFFLE_VALUES,
                    allRows: true,
                    allSections: true,
                    delay: 500
                });*/

            }
            /*this._instructionList = [
                { action: TableDebugAction.SHUFFLE_VALUES, delay: 3000, minRows: 90, maxRows: 90 },
                { action: TableDebugAction.SHUFFLE_VALUES, delay: 3000, minRows: 90, maxRows: 90 },
                { action: TableDebugAction.SHUFFLE_VALUES, delay: 3000, minRows: 90, maxRows: 90 },
                { action: TableDebugAction.SHUFFLE_VALUES, delay: 3000, minRows: 90, maxRows: 90 },
                { action: TableDebugAction.UNCHANGED_RELOAD, delay: 3000 },
                { action: TableDebugAction.SHUFFLE_VALUES, delay: 6000 },
                { action: TableDebugAction.SHUFFLE_TABLE, delay: 6000 },
                { action: TableDebugAction.REMOVE_SECTION, delay: 6000 },
            ];*/


            this._shuffleTableContent();
        }

        updateEmptyBackgroundView() {
            var bgView = null;

            if (!this.tableContent[0].rows.length) {
                bgView = new GUI.BackgroundView(Icon.MESSAGE_CENTER.HEALTH, "BG View Title", "BG View Message");
            }

            this.tableView.setBackgroundView(bgView);
        }

        viewDidLoad() {
            var prms = super.viewDidLoad(...arguments);
            this.setUpComfortUI();
            return prms;
        }

        viewDidAppear() {
            var prms = super.viewDidAppear(...arguments);
            prms.then(function () {
                this._instructionsTimeout = setTimeout(this._startInstructionTimeout.bind(this), 1500);
            }.bind(this));
            return prms;
        }

        viewWillDisappear() {
            this._instructionsTimeout && clearTimeout(this._instructionsTimeout);
            this._instructionsTimeout = null;
            return super.viewWillDisappear(...arguments);
        }

        _getInstruction() {
            return this._instructionList.length > 0 ? this._instructionList[0] : null;
        }

        _removeCurrentInstruction() {
            this._instructionList.splice(0, 1);
        }

        _startInstructionTimeout() {
            var instruction = this._getInstruction();

            var timeout;

            if (instruction) {
                timeout = instruction.delay || DEFAULT_INSTRUCTION_TIMEOUT;
                Debug.GUI.TableDebugScreen && console.log(this.name, "_startInstructionTimeout " + JSON.stringify(instruction));
                this._instructionsTimeout = setTimeout(this._processInstruction.bind(this), timeout);
            }
        }

        _processInstruction() {
            var currInstruction = this._getInstruction(),
                promise;

            Debug.GUI.TableDebugScreen && console.log(this.name, "_processInstruction " + JSON.stringify(currInstruction));

            if (currInstruction) {
                switch (currInstruction.action) {
                    case TableDebugAction.SHUFFLE_VALUES:
                        promise = this._shuffleCellValues(currInstruction);
                        break;

                    case TableDebugAction.SHUFFLE_TABLE:
                        promise = this._shuffleTable(currInstruction);
                        break;

                    case TableDebugAction.UNCHANGED_RELOAD:
                        promise = this.reloadTable();
                        break;

                    case TableDebugAction.REMOVE_SECTION:
                        promise = this._removeSection(currInstruction);
                        break;

                    case TableDebugAction.SHUFFLE_HEADERS:
                        promise = this._shuffleHeaders(currInstruction);
                        break;

                    default:
                        console.error("Instruction handling not yet defined for " + JSON.stringify(currInstruction));
                        promise = Q.reject();
                        break;
                }
            }

            promise.then(function () {
                this._removeCurrentInstruction();

                this._startInstructionTimeout();
            }.bind(this));
        }

        _shuffleTableContent() {
            var content = [],
                numSections = getRandomIntInclusive(this._minSections, this._maxSections),
                numRows,
                section,
                row;

            for (var secIdx = 0; secIdx < numSections; secIdx++) {
                section = {
                    rows: []
                };
                numRows = getRandomIntInclusive(this._minRowsPerSection, this._maxRowsPerSection);

                for (var rowIdx = 0; rowIdx < numRows; rowIdx++) {
                    row = this._getRandomRow(secIdx, rowIdx);
                    section.rows.push(row);
                }

                content.push(section);
            }

            this.tableContent = content;

            this._shuffleHeaderItems();
        }

        _rndBool() {
            return getRandomIntInclusive(0, 99) < 50;
        }

        _getRandomRow(section, row) {
            var rowObj = {
                content: {}
            };

            this._shuffleCellContent(section, row, rowObj);

            return rowObj;
        }

        _getRandomTextForRow(section, row) {
            var idx = (section * row * getRandomIntInclusive(1, 1000) + getRandomIntInclusive(1, 1000)) % this._randomValueMap.length;

            return lxFormat("%.2f", this._randomValueMap[idx]);
        }

        _shuffleCellValues(instruction) {
            Debug.GUI.TableDebugScreen && console.log("_shuffleCellValues");
            var sectionsStart,
                sectionsEnd,
                rowsStart,
                rowsEnd,
                rowObj,
                minRows = instruction.minRows || 1,
                maxRows = instruction.maxRows || this._maxRowsPerSection,
                rowRuns;
            var numValuesShuffled = 0;
            var prevHash = JSON.stringify(this.tableContent).hashCode();
            var newHash;
            var shuffleMap = {};
            var numShuffled = 0;

            for (var i = 0; numShuffled < minRows && numShuffled < maxRows; i++) {
                if (instruction.allSections) {
                    sectionsStart = 0;
                    sectionsEnd = this.tableContent.length;
                } else {
                    sectionsStart = getRandomIntInclusive(0, this.tableContent.length);
                    sectionsEnd = getRandomIntInclusive(sectionsStart + 1, this.tableContent.length);
                    sectionsEnd = Math.min(this.tableContent.length, sectionsEnd);
                }

                Debug.GUI.TableDebugScreen && console.log("   shuffle sections between " + sectionsStart + " and " + sectionsEnd);

                for (var secIdx = sectionsStart; secIdx < sectionsEnd; secIdx++) {
                    rowRuns = getRandomIntInclusive(1, 2);

                    for (var j = 0; j < rowRuns; j++) {
                        if (instruction.allRows) {
                            rowsStart = 0;
                            rowsEnd = this.tableContent[secIdx].rows.length;
                        } else {
                            rowsStart = getRandomIntInclusive(0, this.tableContent[secIdx].rows.length);
                            rowsEnd = getRandomIntInclusive(rowsStart + 1, this.tableContent[secIdx].rows.length);
                            rowsEnd = Math.min(this.tableContent[secIdx].rows.length, rowsEnd);
                        }

                        Debug.GUI.TableDebugScreen && console.log("      shuffle rows between " + rowsStart + " and " + rowsEnd);

                        for (var rowIdx = rowsStart; rowIdx < rowsEnd && numShuffled < maxRows; rowIdx++) {
                            var shuffleMapKey = sprintf("%03d/%03d", secIdx, rowIdx);

                            if (!shuffleMap[shuffleMapKey]) {
                                numShuffled++;
                                rowObj = this.tableContent[secIdx].rows[rowIdx];

                                this._shuffleCellContent(secIdx, rowIdx, rowObj);

                                shuffleMap[shuffleMapKey] = rowObj.content.disclosureText;
                                numValuesShuffled++;
                            }
                        }
                    }
                }
            }

            newHash = JSON.stringify(this.tableContent).hashCode();
            var sortedKeys = Object.keys(shuffleMap).sort(function (a, b) {
                return a.localeCompare(b);
            });
            Debug.GUI.TableDebugScreen && sortedKeys.forEach(function (key) {
                console.log("     " + key + " -> " + shuffleMap[key]);
            });
            Debug.GUI.TableDebugScreen && console.log("   values shuffled:  " + numValuesShuffled);
            Debug.GUI.TableDebugScreen && console.log("         prev Hash:  " + prevHash);
            Debug.GUI.TableDebugScreen && console.log("          new Hash:  " + newHash);
            return this.reloadTable();
        }

        _shuffleCellContent(secIdx, rowIdx, rowObj) {
            var rnd = getRandomIntInclusive(0, 100);

            if (rnd > 50) {
                this._setHasLongTitle(rowObj, rnd, secIdx, rowIdx, false);

                this._setHasAction(rowObj, rnd, secIdx, rowIdx, false);

                this._setSubtitle(rowObj, rnd, secIdx, rowIdx, false);

                this._setDisclosureText(rowObj, rnd, secIdx, rowIdx, false);

                this._setRightIcon(rowObj, rnd, secIdx, rowIdx, false);

                this._setLeftIcon(rowObj, rnd, secIdx, rowIdx, false);
            } else {
                this._setHasLongTitle(rowObj, rnd, secIdx, rowIdx, true);

                this._setHasAction(rowObj, rnd, secIdx, rowIdx, true);

                this._setSubtitle(rowObj, rnd, secIdx, rowIdx, true);

                this._setDisclosureText(rowObj, rnd, secIdx, rowIdx, true);

                this._setRightIcon(rowObj, rnd, secIdx, rowIdx, true);
            }
        }

        _setHasLongTitle(rowObj, rnd, secIdx, rowIdx, available) {
            rowObj.content.title = "Cell " + sprintf("%03d/%03d", secIdx, rowIdx);

            if (available) {
                rowObj.content.title += " ---> and here comes the very long title, that surely exceeds the available space";
            }
        }

        _setHasAction(rowObj, rnd, secIdx, rowIdx, available) {
            if (available) {
                rowObj.action = function () {
                    console.log("Cell in section " + secIdx + ", row " + rowIdx + " tapped!");
                };

                rowObj.content.disclosureIcon = true;
            } else {
                delete rowObj.action;
                delete rowObj.content.disclosureIcon;
            }
        }

        _setSubtitle(rowObj, rnd, secIdx, rowIdx, available) {
            if (available) {
                rowObj.content.subtitle = "" + rnd;
            } else {
                delete rowObj.content.subtitle;
            }
        }

        _setDisclosureText(rowObj, rnd, secIdx, rowIdx, available) {
            if (available) {
                rowObj.content.disclosureText = this._getRandomTextForRow(secIdx, rowIdx);
            } else {
                delete rowObj.content.disclosureText;
            }
        }

        _setRightIcon(rowObj, rnd, secIdx, rowIdx, available) {
            if (available) {
                rowObj.content.rightIconSrc = Icon.AIR_LOGO;
                rowObj.content.rightIconColor = rnd % 2 === 0 ? window.Styles.colors.red : window.Styles.colors.stateActive;
            } else {
                delete rowObj.content.rightIconSrc;
                delete rowObj.content.rightIconColor;
            }
        }

        _setLeftIcon(rowObj, rnd, secIdx, rowIdx, available) {
            if (available) {
                rowObj.content.leftIconSrc = Icon.AIR_LOGO;
                rowObj.content.leftIconColor = rnd % 2 === 0 ? window.Styles.colors.red : window.Styles.colors.stateActive;
            } else {
                delete rowObj.content.leftIconSrc;
                delete rowObj.content.leftIconColor;
            }
        }

        _shuffleTable() {
            Debug.GUI.TableDebugScreen && console.log("_shuffleTable");

            this._shuffleTableContent();

            return this.reloadTable();
        }

        _removeSection(instruction) {
            var numSections = this.tableContent.length,
                sectionToRemove,
                promise,
                minRequired = instruction.middle ? 3 : 2,
                startSec = instruction.middle ? 1 : 0,
                endSec = numSections - (instruction.middle ? 2 : 1);

            if (numSections >= minRequired) {
                sectionToRemove = getRandomIntInclusive(startSec, endSec);
                this.tableContent.splice(sectionToRemove, 1);
                Debug.GUI.TableDebugScreen && console.log(this.name, "_removeSection - removed section " + sectionToRemove);
                promise = this.reloadTable();
            } else {
                Debug.GUI.TableDebugScreen && console.error(this.name, "_removeSection - cannot remove as there are too few sections! " + numSections + " - required: " + minRequired);
                promise = Q.reject();
            }

            return promise;
        }

        _shuffleHeaders(instruction) {
            Debug.GUI.TableDebugScreen && console.log(this.name, "_shuffleHeaders");

            this._shuffleHeaderItems();

            return this.reloadTable();
        }

        _shuffleHeaderItems(instruction) {
            var section;

            for (var i = 0; i < this.tableContent.length; i++) {
                section = this.tableContent[i];

                if (section.headerTitle) {
                    if (this._rndBool()) {
                        Debug.GUI.TableDebugScreen && console.log(this.name, "    " + i + " - header removed");
                        delete section.headerTitle;
                    } else {
                        Debug.GUI.TableDebugScreen && console.log(this.name, "    " + i + " - header shuffled");
                        section.headerTitle = "Section " + i + "" + " ~" + getRandomIntInclusive(0, 100);
                    }
                } else if (this._rndBool()) {
                    section.headerTitle = "Section " + i + "";
                    Debug.GUI.TableDebugScreen && console.log(this.name, "    " + i + " - header added: " + section.headerTitle);
                } else {
                    Debug.GUI.TableDebugScreen && console.log(this.name, "    " + i + " - header remains: " + section.headerTitle);
                }
            }
        }

        reloadTable() {
            var start = timingNow(),
                delta;
            console.log("       ");
            console.log("       ");
            console.log(this.name, "reloadTable --> start");
            this.updateEmptyBackgroundView();
            return super.reloadTable(...arguments).then(function () {
                delta = timingDelta(start);
                this.timings.push(parseInt(delta));

                var evalTimings = this._evaluateTimings(this.timings);

                console.log(this.name, "reloadTable --> FIN: " + delta + "ms");
                console.log(this.name, "     numTimings: " + evalTimings.numRuns);
                console.log(this.name, "        average: " + evalTimings.avg);
                console.log(this.name, "         median: " + evalTimings.median);
                console.log(this.name, "           best: " + evalTimings.min);
                console.log(this.name, "          worst: " + evalTimings.max);
                console.log("       ");
                console.log("       ");
            }.bind(this));
        }

        _evaluateTimings(timings) {
            var min = 9999;
            var max = 0;
            var sum = 0;
            var spread;
            var sortedTimings = cloneObject(timings).sort(function (a, b) {
                return a - b;
            });
            var percentages = [];
            timings.forEach(function (delta) {
                min = Math.min(min, delta);
                max = Math.max(max, delta);
                sum += delta;
            }); // Compute and print graph

            spread = max - min;
            timings.forEach(function (delta) {
                percentages.push(Math.round((delta - min) / spread * 100));
            });
            var steps = 5,
                stepWidth = 100 / steps;
            var printLine, lowerLimit, upperLimit;
            console.log("Graph:");

            for (var i = steps; i > 0; i--) {
                printLine = "|";
                lowerLimit = stepWidth * (i - 1);
                upperLimit = stepWidth * i;
                percentages.forEach(function (pVal) {
                    if (pVal > lowerLimit && pVal <= upperLimit) {
                        printLine += "*";
                    } else {
                        printLine += " ";
                    }
                });
                printLine += "|";
                var lowerLimitVal = Math.round(lowerLimit * spread / 100) + min;
                var upperLimitVal = Math.round(upperLimit * spread / 100) + min;
                console.log(sprintf("%03d-%03d: ", lowerLimit, upperLimit) + printLine + " = " + lowerLimitVal + " - " + upperLimitVal);
            }

            console.log("Timings (unsorted, sorted)");
            console.log(JSON.stringify(timings));
            console.log(JSON.stringify(sortedTimings));
            return {
                min: min,
                max: max,
                median: sortedTimings[Math.round(sortedTimings.length / 2)],
                avg: Math.round(sum / timings.length),
                numRuns: timings.length
            };
        }

    };
});
