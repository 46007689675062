'use strict';

define(["AudioZoneControlEnums", "MediaBrowserBase"], function (AudioZoneControlEnums, MediaBrowserBase) {
    class MediaBrowserLineIn extends MediaBrowserBase {
        //region Static
        static getCellFromContentTypeItem() {
            var baseCell = Controls.AudioZoneControl.MediaBrowserBase.getCellFromContentTypeItem.apply(this, arguments);
            baseCell.content.class = null; // removes the chevron

            return baseCell;
        }

        static getCellButtonIconSrc(item, contentType, vc) {
            return Controls.AudioZoneControl.MediaBrowserBase.getCellButtonIconSrc.apply(this, arguments);
        }

        static getCellTypeForItemCellAtIndex() {
            return GUI.TableViewV2.CellType.BUTTON;
        }

        static getIconObjForItem(item) {
            return {
                color: item.enabled ? window.Styles.colors.green : Color.TEXT_SECONDARY_B,
                lowRes: MediaEnum.LineInMap[item.icontype || 0],
                highRes: MediaEnum.LineInMap[item.icontype || 0],
                hasProvidedCover: false
            };
        } //endregion Static


        //region Getter
        get desc() {
            return _("media.section.line-in");
        } //endregion Getter


        constructor(details) {
            super(...arguments);
        }

        handleOnItemCellClicked(item, contentType, section, row) {
            return MediaServerComp.sendPlayerCommandFromType(item);
        }

        processContentTypeDataChunk(data, contentType) {
            super.processContentTypeDataChunk(...arguments); // Filter out any disabled inputs

            Object.keys(this.contentTypeItems[contentType]).forEach(function (key) {
                if (this.contentTypeItems[contentType][key] instanceof Array) {
                    this.contentTypeItems[contentType][key] = this.contentTypeItems[contentType][key].filter(function (input) {
                        return input.enabled;
                    });
                }
            }.bind(this));
            return {
                newBrowsableCnt: this.contentTypeItems[contentType].browsable.length,
                newPlayableCnt: this.contentTypeItems[contentType].playable.length
            };
        }

        getActionsForCtxAndItem(ctx, item) {
            var actions = super.getActionsForCtxAndItem(...arguments);

            switch (ctx) {
                case this.CONTEXT.LOCAL:
                    if (item === this) {
                        actions.pushObject(this._getActionWithTmplForCtx({
                            content: {
                                title: _("media.preferences.line-ins.edit"),
                                leftIconSrc: Icon.AudioZone.CONTEXT.LINE_IN_EDIT
                            },
                            action: function () {
                                this.ViewController.ViewController.showState(AudioZoneControlEnums.ScreenState.SETTINGS_INPUT_LIST, null, {
                                    control: this.control
                                }, AnimationType.MODAL);
                            }.bind(this)
                        }, ctx));
                    } else {
                        actions.pushObject(this.__getPlayNowActionForCtxAndItem(ctx, item));
                    }

                    break;
            }

            return actions;
        }

    }

    Controls.AudioZoneControl.MediaBrowserLineIn = MediaBrowserLineIn;
    return Controls.AudioZoneControl.MediaBrowserLineIn;
});
