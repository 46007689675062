import globalStyles from "GlobalStyles";
import {View} from "react-native";
import {useMemo} from "react";
import {useLiveState} from "LxComponents";
import Icons from "IconLib";
import Svg, { Circle } from 'react-native-svg';


export default function WallboxStateIcon({controlUuid}) {

    const liveState = useLiveState(controlUuid, [ 
        "loadSheddingActive", 
        "isPaused", 
        "isBelowLimit", 
        "isCharging", 
        "carConnected", 
        "actualValue",
        "phaseSwitching",
    ])

    const styles = {
        rootCntr: {
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },
        iconCntr: {
            width: 140,
            height: 140,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            borderWidth: 4,
            borderColor: globalStyles.colors.grey["300a36"],
            marginTop: globalStyles.sizes.big,
            marginBottom: globalStyles.sizes.mediumBig
        },
        stateSvg: {
            width: 140,
            height: 140,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: globalStyles.sizes.big,
            marginBottom: globalStyles.sizes.mediumBig
        },
        icon: {
            position: "absolute",
            left: "auto",
            right: "auto",
            bottom: "auto",
            top: "auto",
            width: 34,
            height: 35
        },
        smallIcon: {
            fill: globalStyles.colors.grey["300a36"],
            width: 24,
            height: 24,
            position: "absolute",
            bottom: (globalStyles.sizes.mediumBig + -10),
            top: "auto",
            left: "auto",
            right: "auto",
        }
    }

    const {smallIcon} = useMemo(() => {
        let smallIcon, colorObj;
        // small icon
        if (liveState.states?.loadSheddingActive || liveState.states?.phaseSwitching) {
            smallIcon = <Icons.Pause style={{...styles.smallIcon, fill: globalStyles.colors.orange }} />;

        } else if (liveState.states.isBelowLimit) {
            smallIcon = <Icons.Flash style={{...styles.smallIcon, fill: globalStyles.colors.orange }} />;

        } else if (liveState.states.isPaused) {
            smallIcon = <Icons.Pause style={{...styles.smallIcon, fill: globalStyles.colors.stateActive }} />;

        } else {
            colorObj = {};
            if (Feature.WALLBOX2_UPDATED_BLOCK ? liveState.states.isCharging : liveState.states.actualValue !== 0) {
                colorObj.fill = globalStyles.colors.stateActive;
            }

            smallIcon = <Icons.Flash style={
                { ...styles.smallIcon, ...colorObj }
            } />;
        }

        return {smallIcon}
    },  [
        liveState.states.loadSheddingActive,
        liveState.states.isPaused,
        liveState.states.isBelowLimit,
        liveState.states.actualValue,
        liveState.states.phaseSwitching,
        liveState.states.isCharging
    ]);
    
    const {circleColor} = useMemo(() => {
        let shouldShowActiveColor = Feature.WALLBOX2_UPDATED_BLOCK ? liveState.states.isCharging : liveState.states.actualValue !== 0;
        let circleColor;
        if (shouldShowActiveColor) {
            circleColor = globalStyles.colors.stateActive;
        } else {
            circleColor = globalStyles.colors.grey["300a36"]
        }

        return {circleColor};
    }, [
        liveState.states.actualValue,
        ...(Feature.WALLBOX2_UPDATED_BLOCK ? [liveState.states.isCharging] : [])
    ]);

    const {mainColor} = useMemo(() => {
        let mainColor;

        if (liveState.states.carConnected || liveState.states.phaseSwitching) {
            mainColor = globalStyles.colors.stateActive;
        } else {
            mainColor = "#EBEBF5";
        }

        return {mainColor};
    }, [
        liveState.states.carConnected,
        liveState.states.phaseSwitching
    ]);

    const uniqueID = useMemo(() => {
        return getRandomIntInclusive(0,999999);
    }, []);
    const rectClipperId = useMemo(() => {
        return "rectClipperWb-" + uniqueID;
    }, [uniqueID])
    const circleClipperId = useMemo(() => {
        return "circleClipperWb-" + uniqueID;
    }, [uniqueID])

    return <View style={styles.rootCntr}>
        <Svg fill={circleColor} viewBox="0 0 140 140" style={styles.iconCntr} clipPath={"url(#"+ rectClipperId + ")"}>
            <Circle cx="70" cy="70" r="70" clipPath={"url(#"+circleClipperId+")"} />
            <clipPath id={rectClipperId}>
                <path d="M0 0 h140 v140 h-140 M58 140 h24 v-24 h-24" />
            </clipPath>
            <clipPath id={circleClipperId}>
                <path d="M0 0 h140 v140 h-140  M4 70 a 66,66 0 1,0 132,0  a 66,66 0 1,0 -132,0" />
            </clipPath>
        </Svg>
        <Icons.ECar style={{...styles.icon, fill: mainColor }} />
        {smallIcon}
    </View>

};
