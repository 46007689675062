'use strict';

define(["../../react-comps/PairedApp/AppPairingNavigator.jsx"], function (AppPairingNavigator) {
    return class AppPairingScreen extends GUI.Screen {

        static ReactComp = AppPairingNavigator.default;
        reactHeaderShown = false;

        constructor() {
            super();
        }
    };
});
