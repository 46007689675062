import React from 'react';
import { StyleSheet } from 'react-native';
import { LxReactQuickSelect, LxReactText } from 'LxComponents';
import globalStyles from 'GlobalStyles';
import Icons from 'IconLib';

export const sendValue = (control, command, payload) => {
    // make sure we don't send commands without payload
    // as the MS accepts it anyway
    const commandsWithPayload = Object.entries(Commands.AC).filter(([key, value]) => value.includes('%')).map(([key, value]) => Commands.AC[key]);
    
    if (commandsWithPayload.includes(command) && payload === undefined) {
        return;
    }

    control.sendCommand(
        Commands.format(command, payload),
        Commands.Type.OVERRIDE,
    );
};

export const getQuickSelect = (
    modes,
    action,
    type,
    isSetByLogic,
    control,
    states,
    zeroModeSettings,
    isOperatedExternally = false
) => {
    if(zeroModeSettings) {
        modes = [zeroModeSettings].concat(modes);
    }
    let mode = modes.find((mode) => {
        return mode.value.toString() === states[type].toString() || !!mode.value === states[type];
    });
    if (!isSetByLogic && !isOperatedExternally) {
        let selectedValueId = mode ? mode.value : null;
        const quickselect = {
            comp: LxReactQuickSelect,
            props: {
                options: modes,
                iconPosition: LxReactQuickSelect.IconPosition.Right,
                textColor: globalStyles.colors.green_fixed,
                iconComp: Icons.ChevronsUpDown,
                tintColor: globalStyles.colors.text.secondary,
                isSetByLogic: isSetByLogic,
                onOptionSelected: (value) => {
                    if(typeof action === 'string') {
                        sendValue(control, action, value);
                    } else if (typeof action === 'function') {
                        action(value);
                    }
                },
                noToggle: true,
            },
        };
        if (selectedValueId !== null) {
            quickselect.props.selectedValue = selectedValueId;
        }
        return quickselect;
    } else {
        return {
            comp: LxReactText,
            props: {
                children: mode ? mode.title : '--',
                isSetByLogic: isSetByLogic,
                isOperatedExternally,
                numberOfLines: 1,
                style: styles.value
            },
        };
    }
};

const styles = StyleSheet.create({
    value: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.secondary,
    },
});
