'use strict';

define(['DaytimerControlContentCalendar', 'PoolDaytimerCalendarEntries', 'PoolControlEnums'], function (DaytimerControlContentCalendar, PoolDaytimerCalendarEntries, PoolControlEnums) {
    return class PoolDaytimerControlContentCalendar extends DaytimerControlContentCalendar {
        constructor(details) {
            super(details);
        }

        /**
         * Can be overwritten by subclasses (IRC, Pool) to return their own entries subclass.
         * @return {*}
         */
        getCalendarEntries() {
            return new PoolDaytimerCalendarEntries(this.control);
        }

    };
});
