'use strict';

define("DeviceSearchResultScreen", function () {
    var AIR_PAIRING_HELP = 'https://www.loxone.com/help/learnAir';
    return class DeviceSearchResultScreen extends GUI.TableViewScreen {
        //region Static
        static Template = function () {
            var getAirSearchFooterElement = function getAirSearchFooterElement(footerText) {
                return $('<div class="air-search-footer">' + '<div class="air-search-footer__text">' + _("device-learning.searching.title") + "... " + _('air.device-learning.no-results.message') + '</div>' + '<a class="air-search-footer__link" href="' + AIR_PAIRING_HELP + '">' + _("air.device-learning.no-results.pairing-help") + '</a>' + '</div>');
            };

            return {
                getAirSearchFooterElement: getAirSearchFooterElement
            };
        }(); //endregion Static

        constructor(details) {
            super($('<div/>'));
            Object.assign(this, ContextMenuHandler.Mixin);
            this.searchType = details.searchType;
            this.isSearching = true;
            this.currentDataSet = [];
            this.antiGhostTimer = new AntiGhostTimer(this._handleFilterUpdate.bind(this), 250);
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                this.createFilterAndAddTitleBar();
                this.baseTitleBar = this.getTitleBar();
            }.bind(this));
        }

        viewWillAppear() {
            var baseCall = super.viewWillAppear(...arguments);
            this.unregisteDeviceCounterStateFn = ActiveMSComponent.registerForDeviceLearningCounterStateChange(this._processLiveLearnStateChange.bind(this));
            this.unregisteDeviceSearchStateFn = ActiveMSComponent.registerForDeviceLearningSearchStateChange(this._processSearchingStateChanged.bind(this));

            this._toggleSearchBar(this.isFilterModeActive);

            this.filterTitleBar.onRightButtonTapped = this._changeTitleBar.bind(this);
            this.filterInput.onTextChanged = this._handleInputEvent.bind(this);
            this.filterInput.onSubmit = this._handleInputEvent.bind(this);

            this._processLiveLearnStateChange(ActiveMSComponent.getDeviceSearchCurrentDeviceList());

            return baseCall;
        }

        /**
         * The base checks the granted permissions in viewDidAppear
         * --> this command requires extended permissions
         * @return {*}
         */
        viewDidAppear() {
            return Q(super.viewDidAppear(...arguments) || true).then(function () {
                ActiveMSComponent.startDeviceSearchKeepalive();
            }.bind(this));
        }

        /**
         * We are stopping the keep alive to prevent BiometricAuthentication isses
         * @return {*}
         */
        viewWillDisappear() {
            this.unregisteDeviceCounterStateFn();
            this.unregisteDeviceSearchStateFn();
            return super.viewWillDisappear(...arguments);
        }

        destroy() {
            // We don't care if the search is already stopped, send a stop anyway to ensure everything is stopped when closing the screen
            this._stopDeviceSearch();

            return super.destroy(...arguments);
        }

        titleBarConfig() {
            return {
                rightSide: TitleBarCfg.CUSTOM_ICON,
                rightIconSrc: Icon.SEARCH
            };
        }

        titleBarActionRight() {
            this._changeTitleBar();
        }

        titleBarText() {
            // tree.device-learning.title
            // air.device-learning.title
            return _(this.searchType + '.device-learning.title');
        }

        getURL() {
            return "deviceSearchResultScreen";
        }

        // TableView DataSource Methods
        styleForTableView() {
            return TableViewStyle.COMFORT_MODE_2020;
        }

        reloadTable() {
            this.tableContent = [];
            this.tableContent.push(this._createHeaderSection());
            this.tableContent.push(this._createSearchingSection());
            this.tableContent.push(this._createDevicesSection());
            return super.reloadTable(...arguments);
        }

        _processLiveLearnStateChange(allResults) {
            this.currentDataSet = allResults;

            if (this.isFilterModeActive) {
                this._handleFilterUpdate();
            } else {
                this.reloadTable();
            }
        }

        _processSearchingStateChanged(isSearching) {
            this.isSearching = isSearching;
            this.reloadTable();
        }

        _handleRowTapped(deviceType) {
            var details = {
                    deviceType: deviceType
                },
                dataSetLength = this.isFilterModeActive ? this.filteredDataSet.length : this.currentDataSet.length;

            if (this.filterText && this.filterText.length) {
                details.filterText = this.filterText;
            }

            details.selectedExtension = this.selectedExtension;

            if (dataSetLength > 0) {
                this.ViewController.showState(ScreenState.LearningAssistantAddDeviceScreen, null, details);
            }
        }

        _createHeaderSection() {
            return {
                headerTitle: "",
                headerDescription: _('device-learning.results.description'),
                rows: []
            };
        }

        _createSearchingSection() {
            var indicatorText = this.isSearching ? ImageBox.getResourceImageWithClasses(Icon.INDICATOR_BOLD, 'waiting-indicator') : "",
                section = {
                    headerTitle: _('miniserverlist.add.search.found-devices') + indicatorText,
                    rows: []
                },
                message = null,
                baseDataSet = this.isFilterModeActive ? this.filteredDataSet : this.currentDataSet;

            if (baseDataSet.length === 0) {
                if (this.isFilterModeActive) {
                    section.footerTitle = _('device-learning.empty-screen.filter-active.no-results-for', {
                        input: this.filterText
                    });
                } else {
                    // tree.device-learning.no-results.message
                    // air.device-learning.no-results.message
                    if (this.searchType === DeviceManagement.TYPE.AIR) {
                        section.footerElement = DeviceSearchResultScreen.Template.getAirSearchFooterElement();
                    } else {
                        section.footerTitle = _(this.searchType + '.device-learning.no-results.message');
                    }
                }
            } else if (baseDataSet.length > 1) {
                if (baseDataSet) {
                    message = _('device-learning.device-found.title', {
                        count: baseDataSet.length
                    });
                } // Handling for the first row


                section.rows.push({
                    type: GUI.TableViewV2.CellType.Special.DEVICE_LEARNING_CELL,
                    content: {
                        title: _('meter.total'),
                        subtitle: message,
                        leftIconSrc: Icon.DeviceLearning.SUM,
                        leftIconColor: Color.TEXT_SECONDARY_A,
                        clickable: true
                    },
                    action: this._handleRowTapped.bind(this, DeviceManagement.DEVICE_TYPE_ALL)
                });
            }

            return section;
        }

        _createDevicesSection() {
            var section = {
                    rows: []
                },
                message,
                counter,
                rowForName,
                rowForNameIndex,
                baseDataSet = this.isFilterModeActive ? this.filteredDataSet : this.currentDataSet;
            Debug.DeviceSearch.General && console.log("current tableViewDataSet length --> " + baseDataSet.length);
            baseDataSet.forEach(function (device) {
                rowForName = section.rows.find(function (row) {
                    return row.content.title === device.name;
                });

                if (baseDataSet.length === 1) {
                    message = baseDataSet[0].serialNr.formatStringToSerialNumber(this.searchType);
                } else {
                    counter = baseDataSet.filter(function (content) {
                        return content.type === device.type;
                    }).length;
                    message = _('device-learning.device-found.title', {
                        count: counter
                    });
                }

                if (rowForName) {
                    rowForNameIndex = section.rows.indexOf(rowForName);
                    section.rows[rowForNameIndex].content.subtitle = message;
                } else {
                    if (counter === 1) {
                        message = device.serialNr.formatStringToSerialNumber(this.searchType);
                    }

                    section.rows.push({
                        type: GUI.TableViewV2.CellType.Special.DEVICE_LEARNING_CELL,
                        content: {
                            title: device.name,
                            subtitle: message,
                            leftIconSrc: ActiveMSComponent.getImageUrlWithDeviceType(device.type),
                            clickable: true
                        },
                        action: this._handleRowTapped.bind(this, device.type)
                    });
                }
            }.bind(this));
            return section;
        }

        _stopDeviceSearch() {
            ActiveMSComponent.stopDeviceSearchKeepalive(); // Only send the command if the permissions are still granted,
            // sending a permissive command without granted permissions results in the authentication popup
            // If the app closes unexpected the search will automatically stop after 3 Minutes without a keepalive sent from the app

            this.hasPermissionsGranted().then(function () {
                ActiveMSComponent.stopDeviceSearch();
            });
        }

        // ------------- Filter Handling -------------
        createFilterAndAddTitleBar() {
            var inputCfg = {
                    placeholder: _('device-learning.filter.title'),
                    style: GUI.LxInputStyle.ROUNDED_SQUARE,
                    leftIconSrc: Icon.DeviceLearning.FILTER,
                    resetButton: true,
                    autoFocus: true,
                    isDark: true // will set isDark too.

                },
                filterInput = new GUI.LxInput(inputCfg, this),
                titlebarCfg = {
                    leftSide: TitleBarCfg.CUSTOM_LX_VIEW,
                    leftSideView: filterInput,
                    rightSide: TitleBarCfg.TEXT,
                    rightText: _('cancel')
                },
                filterTitleBar = new GUI.LxTitleBar(titlebarCfg);
            this.filterInput = filterInput;
            this.filterTitleBar = filterTitleBar;
            this.prependSubview(filterTitleBar);
        }

        _handleInputEvent(textInput) {
            this.filterText = textInput;

            if (this.filterText && this.filterText.length) {
                this.antiGhostTimer.fire();
            } else {
                this._handleFilterUpdate();
            }
        }

        _handleFilterUpdate() {
            var filteredDataSet = [];

            if (this.filterText && this.filterText.length) {
                this.filterText = this.filterText.toLowerCase();
                this.currentDataSet.forEach(function (entry) {
                    if (entry.name.toLowerCase().indexOf(this.filterText) !== -1 || entry.serialNr.toLowerCase().indexOf(this.filterText) !== -1 || entry.serialNr.formatStringToSerialNumber(this.searchType).toLowerCase().indexOf(this.filterText) !== -1) {
                        filteredDataSet.push(entry);
                    }
                }.bind(this));
                this.filteredDataSet = filteredDataSet;
            } else {
                this.filteredDataSet = this.currentDataSet;
            }

            this.reloadTable();
        }

        _changeTitleBar() {
            this._toggleSearchBar(!this.isFilterModeActive);

            if (this.isFilterModeActive) {
                this.antiGhostTimer.destroy();
                this.isFilterModeActive = false;
                this.filteredDataSet = this.currentDataSet;
                this.filterText = null;
                this.reloadTable();
            } else {
                this.filterInput.setValue("");
                this.isFilterModeActive = true;
                this.filteredDataSet = this.currentDataSet;
            }
        }

        /**
         * Needed if changing screens, to not hide the filter title bar if the user is filtering
         * @param show
         * @private
         */
        _toggleSearchBar(show) {
            if (show) {
                this.hideSubview(this.baseTitleBar);
                this.showSubview(this.filterTitleBar);
            } else {
                this.hideSubview(this.filterTitleBar);
                this.showSubview(this.baseTitleBar);
            }
        }

    };
});
