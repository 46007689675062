'use strict';

define(['AudioZoneV2ControlEnums'], function (AudioZoneV2ControlEnums) {
    class AudioInputList extends GUI.TableViewScreenV2 {
        //region Getter
        get inputs() {
            return this._inputs;
        } //endregion Getter


        //region Setter
        set inputs(newVal) {
            this._inputs = cloneObject(newVal);
            sortArrByFields(this._inputs, ["name", "uniqueSubtitle"]);
        } //endregion Setter


        constructor(details) {
            super($('<div />'));
            this.details = details;
            this.control = details.control;
            this.states = this.control.getStates();
        }

        viewDidLoad() {
            var args = arguments;
            return this._acquireInputs().then(() => {
                return super.viewDidLoad(...args);
            });
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function (res) {
                return this._acquireInputs().then(function (changed) {
                    return changed ? this.reloadTable() : Q.resolve();
                }.bind(this));
            }.bind(this));
        }

        titleBarText() {
            return _("media.section.line-in");
        }

        reloadTable() {
            this.states = this.control.getStates();
            this.tableContent = [{
                rows: []
            }];
            this.inputs.forEach(function (input) {
                this.tableContent[0].rows.push(this._getRowForInput(input));
            }.bind(this));
            return super.reloadTable(...arguments);
        }

        _acquireInputs() {
            return this.control.audioserverComp.getInputs().promise.then(function (inputs) {
                var changed = JSON.stringify(inputs) !== JSON.stringify(this.inputs);
                this.inputs = inputs;
                return changed;
            }.bind(this));
        }

        _getRowForInput(input) {
            return {
                type: GUI.TableViewV2.CellType.GENERAL,
                content: {
                    title: input.name,
                    subtitle: input.uniqueSubtitle,
                    clickable: true,
                    disclosureIcon: true,
                    leftIconSrc: MusicServerEnum.LineInMap[input.icontype]
                },
                action: this._showInputSetting.bind(this, input)
            };
        }

        _showInputSetting(input) {
            var target = AudioZoneV2ControlEnums.ScreenState.SETTINGS_INPUT;
            var detail = {
                control: this.control,
                input: input
            };
            return this.ViewController.showState(target, null, detail);
        }

    }

    Controls.AudioZoneV2Control.AudioInputList = AudioInputList;
    return Controls.AudioZoneV2Control.AudioInputList;
});
