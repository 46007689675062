'use strict';

define([], function () {
    return {
        /**
         * Specifies what states a window can be in - it's a Bitmap, certain states are combined (closed and locked)
         * @type {{UNKNOWN: number, CLOSED: number, TILTED: number, OPEN: number}}
         */
        State: {
            UNKNOWN: 0,
            CLOSED: 1,
            TILTED: 2,
            OPEN: 4,
            LOCKED: 8,
            // will never be active on its own, as they are always locked & closed
            UNLOCKED: 16 // will never be active on its own, as they are always unlocked & closed

        }
    };
});
