'use strict';

define(['AudioZoneControlEnums'], function (AudioZoneControlEnums) {
    class AudioZoneControlGlobalFavoriteEdit extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            Object.assign(this, MediaStateHandler.Mixin);
            this.details = details;
            this.control = details.control;
            this.globalFavorites = [];
        }

        getTableView() {
            return new GUI.EditableTableView(this.tableViewDataSource, this.tableViewDelegate);
        }

        viewWillAppear() {
            this._registerForMediaServerEvents(this.EVENT_TYPES.FAVORITES);

            return super.viewWillAppear(...arguments);
        }

        viewWillDisappear() {
            this._unregisterForMediaServerEvents(this.EVENT_TYPES.FAVORITES);

            return super.viewWillDisappear(...arguments);
        }

        titleBarText() {
            return this.details.title || _("edit");
        }

        receivedFavoritesAllDataEvent(res) {
            this.globalFavorites = res.items;
            this.globalFavorites.forEach(function (fav) {
                fav.contentType = this.EVENT_TYPES.FAVORITES;
            }.bind(this));
            this.reloadTable();
        }

        setTableContent() {
            this.tableContent = [{
                headerTitle: _("media.favorites.all"),
                rows: this.globalFavorites.map(function (globalFav) {
                    return {
                        content: {
                            title: globalFav.name,
                            leftIconBgEnabled: true,
                            leftIconLarger: true,
                            leftIconSrc: globalFav.coverurl
                        },
                        didRemoveCell: MediaServerComp.sendMediaServerCommand.bind(MediaServerComp, MediaEnum.Commands.FAVORITES.DELETE + globalFav[MediaEnum.Event.ID])
                    };
                }.bind(this))
            }];
            return super.setTableContent(...arguments);
        }

        reloadTable() {
            return super.reloadTable(...arguments).then(function () {
                return Q.all([this.tableView.setEditingModeForSection(0, true, true)]);
            }.bind(this));
        }

    }

    Controls.AudioZoneControl.AudioZoneControlGlobalFavoriteEdit = AudioZoneControlGlobalFavoriteEdit;
});
