import Svg, { Path } from "react-native-svg"

function Air(props) {
    return (
        <Svg isreact="true"
            height={24}
            viewBox="0 0 24 24"
            width={24}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm-.124 14.901a1 1 0 110 2.001 1 1 0 010-2zm0-3.695c.943 0 1.819.281 2.555.76l-.633.977a3.506 3.506 0 00-3.845 0l-.634-.975a4.664 4.664 0 012.557-.762zm0-3.002c1.545 0 2.982.462 4.188 1.248l-.633.976a6.489 6.489 0 00-7.11 0l-.635-.975a7.646 7.646 0 014.19-1.249zm0-3.001c2.147 0 4.144.641 5.822 1.735l-.634.975a9.473 9.473 0 00-10.377.001l-.634-.975a10.624 10.624 0 015.823-1.736z"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default Air
