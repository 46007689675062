"use strict";

define([], function () {
    return class SmokeAlarmControlContentServiceMode extends Controls.ControlContent {
        constructor(details) {
            super(details);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.tableView = new GUI.TableViewV2(this, this);
            this.tableView.getElement().addClass("smoke-alarm-control-content-service-mode__table-view");
            this.appendSubview(this.tableView); // Default is 1 hour and 30 minutes seconds will be ignored

            this.value = [1, 30, 0];
        }

        titleBarActionRight() {
            var seconds = this.value[0] * 3600 + this.value[1] * 60 + this.value[2];
            this.sendCommand(Commands.format(Commands.ALARM.SMOKE_ALARM.SERVICE_MODE, seconds));
            this.ViewController.navigateBack();
        }

        titleBarText() {
            return _('controls.alarm.fireAlarm.start.service-mode');
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.CLOSE,
                rightSide: TitleBarCfg.Button.TICK,
                style: "transparent"
            };
        }

        getURL() {
            return "StartServiceMode";
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        receivedStates(states) {
            this.tableView.reload();
        }

        // TableViewDataSource Methods
        styleForTableView(tableView) {
            return TableViewStyle.GROUPED;
        }

        numberOfSections(tableView) {
            return 1;
        }

        numberOfRowsInSection(section, tableView) {
            return 1;
        }

        cellTypeForCellAtIndex(section, row, tableView) {
            return GUI.TableViewV2.CellType.DURATION_PICKER;
        }

        contentForCell(cell, section, row, tableView) {
            return {
                title: _("controls.alarm.fireAlarm.start.service-mode.duration"),
                value: this.value,
                wheelOrder: "hhii" // We only need to display Hours and minutes in the Picker

            };
        }

        titleForFooterInSection(section) {
            return _("controls.alarm.fireAlarm.service-mode-desc-line-one");
        }

        onPickerChanged(section, row, tableView, value) {
            this.value = value;
        }

    };
});
