'use strict';

define([], function () {
    return {
        ZONE_ID_OFF: -1,
        ZONE_ID_ALL: 8,
        // can only be triggered via config.
        StateId: {
            ZONES: "zones"
        },
        ScreenState: {
            DURATION: "IrrigationDurationSettings"
        }
    };
});
