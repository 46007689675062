import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Weather(props) {
    return (
        <Svg isreact="true"
            height={24}
            viewBox="0 0 24 24"
            width={24}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M13.5 7.971a5.993 5.993 0 015.4 3.396l.049.106h.136c2.259.044 3.89 2.237 3.296 4.405l-.034.111.055.044a4.524 4.524 0 011.592 3.215l.006.237C24 21.973 21.983 24 19.5 24h-11C5.464 24 3 21.526 3 18.485c0-2.69 1.94-4.965 4.527-5.427l.047-.007.008-.057a6 6 0 015.685-5.019zm0 1a5 5 0 00-4.979 4.567.5.5 0 01-.451.455C5.775 14.209 4 16.157 4 18.485 4 20.975 6.017 23 8.5 23h11c1.93 0 3.5-1.578 3.5-3.515 0-1.174-.583-2.26-1.53-2.907a.5.5 0 01-.178-.612c.768-1.767-.677-3.713-2.602-3.474a.5.5 0 01-.529-.316A4.996 4.996 0 0013.5 8.97zM9.5 3c1.881 0 3.633.808 4.86 2.191a.5.5 0 11-.748.664A5.48 5.48 0 009.5 4 5.502 5.502 0 004 9.5c0 .618.104 1.22.306 1.793a.5.5 0 11-.944.332A6.382 6.382 0 013 9.5C3 5.912 5.911 3 9.5 3zm-8 6a.5.5 0 01.09.992L1.5 10h-1a.5.5 0 01-.09-.992L.5 9zm1.284-6.911l.07.057 1 1a.5.5 0 01-.638.765l-.07-.057-1-1a.5.5 0 01.638-.765zm14.07.057a.5.5 0 01.057.638l-.057.07-1 1a.5.5 0 01-.765-.638l.057-.07 1-1a.5.5 0 01.708 0zM9.5 0a.5.5 0 01.492.41L10 .5v1a.5.5 0 01-.992.09L9 1.5v-1a.5.5 0 01.5-.5z"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default Weather
