'use strict';
/**
 * The Autopilot screen has two sub views (AutopilotRulesScreen and the AutopilotHistoryScreen) and is the main
 * entry point for the autopilot generator
 */

define(['AutopilotCommunicator', 'AutopilotUtility', 'AutopilotEnums', 'AutopilotRulesScreen', 'AutopilotRuleScreen', 'AutopilotHistoryScreen'], function (AutopilotCommunicator, AutopilotUtility, AutopilotEnums, AutopilotRulesScreen, AutopilotRuleScreen, AutopilotHistoryScreen) {
    return class AutopilotScreen extends GUI.Screen {
        constructor(details) {
            super($('<div />'));
        }

        viewDidLoad() {
            var promise = super.viewDidLoad(...arguments);
            this.elements = {};
            this.rulesScreen = new AutopilotRulesScreen();
            this.historyScreen = new AutopilotHistoryScreen();
            this.addToHandledSubviews(this.rulesScreen);
            this.addToHandledSubviews(this.historyScreen);
            this.segmentedControl = new GUI.LxSegmentedControl([{
                text: _("autopilot.rule.name.plural")
            }, {
                text: _("autopilot.history")
            }]);
            this.segmentedControl.on("on-segment-selected", function (ev, idx) {
                this.pageView.setActivePage(idx);
            }.bind(this));
            this.pageView = new GUI.LxPageView([this.rulesScreen.getElement(), this.historyScreen.getElement()], false, this._doneAnimating.bind(this), {
                overscroll: true
            });
            this.pageView.disableUserInteraction(true);
            this.appendSubview(this.segmentedControl);
            this.appendSubview(this.pageView);
            return promise;
        }

        getPermissions() {
            return [{
                permission: MsPermission.AUTOPILOT
            }];
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            return _("autopilot.name");
        }

        titleBarConfig() {
            return {
                rightSide: TitleBarCfg.CUSTOM_ICON,
                rightIconSrc: Icon.NEW
            };
        }

        titleBarAction() {
            this.ViewController.navigateBack();
        }

        titleBarActionRight() {
            // show new rule screen
            NavigationComp.showState(ScreenState.Autopilot.RuleScreen);
        }

        _doneAnimating(pageIndex) {
            this.segmentedControl.setActiveSegment(pageIndex);
        }

    };
});
