'use strict';

define(['ControlActionCellsScreen', 'IRoomControlInfoView', 'IRoomControlEnums', 'IRoomControlContentTimer', 'IRoomControlContentSettingsList', 'IRoomControlContentControllerMode', 'IRoomControlContentTemperatureSelection', 'IRCManualTempSliderScreen', 'IRCDaytimerControlContentCalendar'], function (ControlActionCellsScreen, IRoomControlInfoView, IRoomControlEnums) {
    return class IRoomControlContent extends ControlActionCellsScreen {
        constructor() {
            super(...arguments);
        }

        receivedStates(states) {
            super.receivedStates(...arguments);
            this.element.toggleClass("i-room-control-action-screen--active", true);
            var showCustomInfoView = (states.stateInfo === null || states.stopActive) && // the stop active text shouldn't hide the info view.
                !states.isInManualMode;
            this.toggleCustomInfoView(showCustomInfoView);

            if (states.serviceMode) {
                this.setTitle(_("controls.irc.mode.service"));
            } else {
                this.setTitle(this.control.getNameOfIRCMode(states.controllerMode));
            }
        }

        /**
         * This method can be overwritten, if a view is returned it will be shown inside the customInfoBox!
         * @return {null}
         */
        getCustomInfoView() {
            return new IRoomControlInfoView(this.control);
        }

       /* getActionSection(states) {
            var section = super.getActionSection(...arguments) || {
                rows: []
            };

            if (states.serviceMode) {
                // The service mode cmds come from the command src.
                return section;
            }

            if (states.isInManualMode) {
                section.rows.push({
                    content: {
                        title: _("controls.irc.manual.temp")
                    },
                    action: this._showManualTempEditor.bind(this),
                    type: GUI.TableViewV2.CellType.Special.COMFORT_ACTION
                });
            }

            if (states.remainingTime <= 0 && !states.logicOverride && // ensures no timer is currently running
                states.userInfo !== IRoomControlEnums.UserInfo.NO_PERIOD_ACTIVE) {
                section.rows.push({
                    content: {
                        title: _("controls.irc.timer.start")
                    },
                    action: this.showScreen.bind(this, IRoomControlEnums.ScreenState.TIMER),
                    type: GUI.TableViewV2.CellType.Special.COMFORT_ACTION
                });
            }

            return section;
        } */

        getAdditionalSection() {
            var section = super.getAdditionalSection(...arguments) || {
                rows: []
            };

            if (this.control.getStates().serviceMode) {
                return section;
            }

            if (!this.control.isGrouped()) {
                section.rows.splice(0, 0, {
                    content: {
                        title: _("settings"),
                        clickable: true,
                        rightIconSrc: Icon.DISCLOSURE
                    },
                    action: this.showScreen.bind(this, IRoomControlEnums.ScreenState.SETTINGS_LIST)
                });
            }

            return section;
        }

        onIconTapped() {
            this.control.statistic && this.showStatisticForOutput(0);
        }

        onTitleTapped() {
            this.showScreen(IRoomControlEnums.ScreenState.CONTROLLER_MODE_LIST);
        }

        onSubtitleTapped() {
            var states = this.control.getStates();

            if (states.isInManualMode) {
                this._showManualTempEditor();
            } else {
                this.showScreen(IRoomControlEnums.ScreenState.TEMPERATURE_LIST, {
                    openTemperature: this.control.getStates().activeTempMode
                });
            }
        }

        _showManualTempEditor() {
            this.showScreen(IRoomControlEnums.ScreenState.MAN_TEMP_SCREEN);
        }

    };
});
