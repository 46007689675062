import {useContext, useEffect, useMemo, useState} from "react";
import {ControlContext} from "../../../react-comps/Components";

/**
 * Will return a map where the keys are the individual limit bits available on the wallboxes and the values are the
 * node uuids of the affected nodes.
 * @param nodeUuid  the uuid of the node who's children are to be checked.
 * @returns {{}}
 */
export default function useLimitedWallboxes({nodeUuid = null}) {
    const {control} = useContext(ControlContext);
    const [restrictedWallboxes, setRestrictedWallboxes] = useState({});

    const wallboxNodes = useMemo(() => {
        return control?.getWallboxNodes(nodeUuid);
    }, [control, nodeUuid]);

    const updateRestrictedWallboxes = () => {
        let wbLimit,
            newMap = {};
        wallboxNodes.forEach(wbNode => {
            if (control?.isWallboxNodeLimited(wbNode)) {
                wbLimit = control?.getNodeValue(wbNode.uuid).limitedBy;
                addToMap(wbNode, wbLimit, newMap);
            }
        })

        if (JSON.stringify(newMap) !== JSON.stringify(restrictedWallboxes)) {
            setRestrictedWallboxes(newMap);
        }
    };

    const addToMap = (node, limitedBy, map) => {
        const checkBit = (checkBit, storeBit) => {
            const mapBit = storeBit ? storeBit : checkBit;
            if (hasBit(limitedBy, checkBit)) {
                map[mapBit] = map[mapBit] || [];
                map[mapBit].push(node.uuid);
            }
        }

        checkBit(WallboxEmRestrictionCauses.ECO);
        checkBit(WallboxEmRestrictionCauses.PMAX);
        checkBit(WallboxEmRestrictionCauses.PARENT);
        checkBit(WallboxEmRestrictionCauses.STRAND);
    }

    useEffect(() => {
        let regs = [];
        updateRestrictedWallboxes();
        wallboxNodes.forEach(wbNode => {
            regs.push(SandboxComponent.registerFunctionForStateChangesForUUID(wbNode.ctrlUuid, () => {
                updateRestrictedWallboxes();
            }));
        });

        return () => {
            regs.forEach(unregFn => unregFn());
        }
    }, [wallboxNodes]);


    return restrictedWallboxes;
}

export function GetLimitedByTextForLimitBit(limitBit) {
    const compareBit = parseInt(limitBit);
    let cause = null;

    if (compareBit === WallboxEmRestrictionCauses.ECO) {
        cause = _("controls.wallboxmanager.insufficient-surplus");
    } else if (compareBit === WallboxEmRestrictionCauses.PMAX) {
        cause = _("controls.wallboxmanager.max-power-reached");
    } else if (hasBit(compareBit, WallboxEmRestrictionCauses.STRAND) ||
        hasBit(compareBit, WallboxEmRestrictionCauses.PARENT)) {
        cause = _("controls.wallboxmanager.max-fuse-reached");
    }

    return cause;
}

export const WallboxEmRestrictionCauses = {
    NONE: 0,
    ECO: 1,
    STRAND: 2,
    PARENT: 4,
    PMAX: 8,

    ALL: 255
}