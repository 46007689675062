import {
    useStatisticV2,
    StatisticDataPointUnit,
    useControl,
    useLiveState
} from "LxComponents";
import { useRequest } from "ahooks";
import {
    getStatsForDataPoints,
} from "../utils";

export default function useSpotPriceData({
    uuidAction,
    start,
    end
}) {
    // calls to moment.unix() without .utc() will result in the time being displayed in the devices local timezone, as moment is local time by default
    const msNow = SandboxComponent.getMiniserverTimeInfo(null, null, TimeValueFormat.MINISERVER_DATE_TIME),
        isToday = start === msNow.clone().startOf("day").unix(),
        isFuture = msNow.clone().endOf("day").isBefore(moment.unix(start)),
        statistics = cloneObject(useStatisticV2(uuidAction, start, end, ["current"], StatisticDataPointUnit.ALL)),
        supportedIntervals = [0, 15, 30],
        control = useControl(uuidAction),
        { states } = useLiveState(uuidAction, ["forecastTimestamp"]),
        { data: forecast = [], error, loading } = useRequest(() => {
            return control.getForecast({
                forecastTimestamp: states.forecastTimestamp
            });
        }, {
            refreshDeps: [
                states.forecastTimestamp
            ]
        });
        statistics.data =
            (statistics.data &&
                statistics.data.filter(
                    (statistic, index, self) =>
                        self.findIndex((stat) => stat.ts === statistic.ts) ===
                        index,
                )) ||
            []; // remove duplicate timestamps

    if (error && !loading) {
        console.error(error);
    }

    if (!loading && !error && (statistics.data || !control.supportsStatisticV2)) {
        if (isFuture) {
            // The statistics hook returns the current day if it's out of bounds (in the Future).
            // To not change the hook order I settled to just ignore the statistics if we are in the future.
            statistics.data = [];
        } else if (isToday) {
            // Remove the last entry, it is always the current time!
            // This would lead to duplicate entries in the graph
            statistics?.data?.splice(-1);
        }

        const { ts = (start -1) } = statistics?.data?.[statistics.data.length - 1] ?? {};// Start of hour here corrupts <60min resolution filling causing missing data points
        // Merging our historic data with the forecast
        statistics.data = [
            ...(statistics?.data ?? []),
            ...(forecast.filter(({ s }) => {
                return s.isBetweenOrEqual(ts, end) && s !== ts;
            }).map(({ s, v: value }) => {
                return {
                    ts: s,
                    values: [
                        value
                    ]
                }
            }))
        ];
    }

    // filter for full hours only because when being in another timezone than the 
    // miniserver the statistic will include the current hour as well
    statistics.data = statistics?.data?.filter((stat) => {
        const date = new Date(stat.ts * 1000);
        return supportedIntervals.includes(date.getMinutes()) && date.getSeconds() === 0;
      });

    const rawValues = statistics.data?.map(({ values }) => {
        return values[0];
    }) ?? [];

    return {
        loading,
        ...statistics,
        ...getStatsForDataPoints(rawValues)
    }
}
