import {useContext, useEffect} from "react";
import {UrlContext} from "../App";
import {useNavigation} from "@react-navigation/native";
import {AmbientContext} from "LxComponents";

const getURL = (screen) => {
    let activeMsVC = window.screenStateMap[ScreenState.ActiveMiniserver];
    let newURL;
    if (activeMsVC) {
        newURL = activeMsVC.getURL();
        let isNestedScreen = window.nestedScreens.includes(screen.name);
        if (isNestedScreen) {
            newURL += "/" + screen.getURL();
        }
    } else {
        newURL = screen.getURL();
    }
    return newURL;
}

function useSetLegacyURLHook({vc, screen, disablePop}) {

    const navigation = useNavigation();
    const {setUrl} = useContext(UrlContext);
    const {isAmbientMode} = useContext(AmbientContext)


    useEffect(() => {
        let unsubscribeFocus, unsubscribeBlur;

        if (isAmbientMode) {
            return;
        }

        unsubscribeFocus = navigation.addListener("focus", (e) => {

            if (vc && vc.currentView === null) {
                vc.currentView = screen;
            }

            if (screen instanceof GUI.ActiveMiniserverViewController) {
                return;
            } else if (screen instanceof  GUI.ControlContentViewController && screen.viewStack.length === 0 && !screen.getCurrentView()) {
                return; // presumably a new ReactControlContent!
            }

            setUrl({
                urlPart: getURL(screen),
                replace: true
            })
        })

        unsubscribeBlur = navigation.addListener("blur", (e) => {

            if(vc && vc.currentView === screen) {
                vc.currentView = null;
            }

            if (screen instanceof GUI.ActiveMiniserverViewController) {
                return
            }

            setUrl({
                urlPart: getURL(screen),
                replace: true
            });
        });

        return () => {
            unsubscribeBlur && unsubscribeBlur();
            unsubscribeFocus && unsubscribeFocus();
        }
    }, [isAmbientMode]);
}

export {
    getURL
}

export default useSetLegacyURLHook;
