'use strict';

define("SteakControl", [
    "Control",
    "SteakControlEnums",
    "IconLib"
], function (
    Control,
    SteakControlEnums,
    {
        default: Icons
    }
) {
    return class SteakControl extends Control {
        constructor() {
            super(...arguments);
        }

        specificCreateCmdText(cmdParts, argumentTexts) {
            var text,
                cfg = {
                    name: this.name,
                    productName: _('steak.product-name')
                };

            switch (cmdParts[1]) {
                case "quitAlarm":
                    text = _('steak.quitAlarm');
                    break;

                case "setThisActive":
                    text = _('steak.setActive', cfg);
                    break;

                case "startTimer":
                    text = _('steak.timer.start');

                    if (cmdParts[2]) {
                        text += " (" + LxDate.formatSecondsIntoDigits(cmdParts[2], true, true) + ")";
                    }

                    break;

                case "stopTimer":
                    text = _('steak.timer.stop');
                    break;

                case "setTouchProtection":
                    if (cmdParts[2] === "0") {
                        text = _('steak.touchProtection.inactive');
                    } else {
                        text = _('steak.touchProtection.active');
                    }

                    break;

                case "setDisplayAlwaysOnBat":
                    if (cmdParts[2] === "0") {
                        text = _('steak.displayAlwaysOn.bat.inactive');
                    } else {
                        text = _('steak.displayAlwaysOn.bat.active');
                    }

                    break;

                case "setDisplayAlwaysOnDc":
                    if (cmdParts[2] === "0") {
                        text = _('steak.displayAlwaysOn.dc.inactive');
                    } else {
                        text = _('steak.displayAlwaysOn.dc.active');
                    }

                    break;

                default:
                    text = "";
            }

            return text;
        }

        getFriendlyValueName(event, operator) {
            switch (event.data.stateName) {
                case "yellowAlarmActive":
                    if (event.value) {
                        return _('steak.sensor.alarm-active.active', {
                            color: _('steak.sensor.color.yellow')
                        });
                    } else {
                        return _('steak.sensor.alarm-active.inactive', {
                            color: _('steak.sensor.color.yellow')
                        });
                    }

                case "greenAlarmActive":
                    if (event.value) {
                        return _('steak.sensor.alarm-active.active', {
                            color: _('steak.sensor.color.green')
                        });
                    } else {
                        return _('steak.sensoralarmactive.inactive', {
                            color: _('steak.sensor.color.green')
                        });
                    }

                case "timerAlarmActive":
                    if (event.value) {
                        return _('steak.timer.start');
                    } else {
                        return _('steak.timer.stop');
                    }

                case "isActive":
                    if (event.value) {
                        return _('steak.sensor.active', {
                            name: this.name
                        });
                    } else {
                        return _('steak.sensor.inactive', {
                            name: this.name
                        });
                    }

                case "temperatureYellow":
                    return _('steak.sensor.temperature', {
                        color: _('steak.sensor.color.yellow')
                    }) + " " + operator + " " + event.value.toString().replace(",", ".");

                case "temperatureGreen":
                    return _('steak.sensor.temperature', {
                        color: _('steak.sensor.color.green')
                    }) + " " + operator + " " + event.value.toString().replace(",", ".");

                case "targetYellow":
                    return _('steak.sensor.target-temp', {
                        color: _('steak.sensor.color.yellow')
                    }) + " " + operator + " " + event.value.toString().replace(",", ".");

                case "targetGreen":
                    return _('steak.sensor.target-temp', {
                        color: _('steak.sensor.color.green')
                    }) + " " + operator + " " + event.value.toString().replace(",", ".");

                default:
                    return "";
            }
        }

        getButton0(states) {
            if (states && states.isActive && states.deviceState === SteakControlEnums.DeviceStates.IN_USE) {
                return {
                    iconSrc: states.timerInfo.active ? Icon.Steak.TIMER_STOP_BUTTON : Icon.Steak.TIMER_BUTTON,
                    reactIcon: states.timerInfo.active ? Icons.TimerStop : Icons.Timer,
                    command: states.timerInfo.active ? Commands.Steak.STOP_TIMER : Commands.Steak.START_TIMER
                };
            }
        }

        /**
         * Returns the specific color for a sensor at the given index
         * Left = Yellow, Right = Green
         * @param idx
         * @param [states]
         * @returns {string}
         */
        getColorForSensorAtIndex(idx, states) {
            if (!states) {
                states = this.getStates();
            }

            return states.sensors[idx].isLeft ? window.Styles.colors.yellow_fixed : window.Styles.colors.green;
        }

        /**
         * Returns all availableControls. One Touch & Grill device can be referenced in multiple SteakControls
         * Every control has a uuid and name property
         * @return {*}
         */
        getAvailableControls(states) {
            if (!states) {
                states = this.getStates();
            }

            return states.availableControls;
        }

        /**
         * Returns the currently active control with uuid and name
         * @return {*}
         */
        getActiveControl(states) {
            if (!states) {
                states = this.getStates();
            }

            return this.getAvailableControls(states)[states.activeControl];
        }

        hasConnectedSensors(states) {
            if (!states) {
                states = this.getStates();
            }

            return states.sensors.filter(function (sensor) {
                return sensor.connected;
            }).length > 0;
        }

        getStatisticNoDataValue() {
            return SteakControlEnums.STATS_NO_SENSOR_CONNECTED_VALUE;
        }

        getStatisticNoDataEntryLabelText() {
            return _("steak.stats.no-sensor");
        }

    };
});
