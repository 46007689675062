'use strict';

class HomeKitOnboardingScreen extends GUI.OnboardingScreen {
    //region Static
    static getShownIds() {
        return [];
    }

    static shouldShow(id) {
        return true;
    } //endregion Static


    constructor(details) {
        super($("<div />"));
        this.id = details.id;
        this.iconSrc = details.iconSrc;
        this.iconColor = details.iconColor;
        this.title = details.title;
        this.message = details.message;
        this.message2 = details.message2;
        this.continueBtnText = details.continueBtnText;
        this.continueDef = details.continueDef;
        this.bottomIcon = details.bottomIcon;
        this.bottomText = details.bottomText;
        this.shareButtonText = details.shareButtonText;
        this.textButtonText = details.textButtonText;
        this.rightButtonSrc = details.rightButtonSrc;
        this.rightButtonAction = details.rightButtonAction;
    }

    viewDidLoad() {
        return super.viewDidLoad(...arguments).then(function () {
            if (this.textButtonText) {
                this.textButton = new GUI.LxButton(this, '<div class="screen__text-button">' + this.textButtonText + '</div>');
                this.insertSubviewAfter(this.textButton, this.elements.subtitleLbl2);

                this.textButton.onButtonTapped = function () {
                    var link = "https://www.loxone.com";
                    NavigationComp.openWebsite(link);
                }.bind(this);
            }

            if (this.bottomText) {
                this.bottomText = $("<div class='screen__bottom-text'>" + this.bottomText + "</div>");
                GUI.animationHandler.insertAfter(this.bottomText, this.elements.subtitleLbl2);
            }

            if (this.bottomIcon) {
                var bottomIcon = $('<div class="screen__bottom-icon">' + ImageBox.getResourceImageWithClasses(this.bottomIcon, "state-icons__bg") + '</div>');

                if (this.bottomIcon.endsWith(".png")) {
                    bottomIcon = $("<img class='screen__bottom-png' src=" + this.bottomIcon + ">");
                }

                GUI.animationHandler.insertAfter(bottomIcon, this.elements.subtitleLbl2);
            }

            if (this.shareButtonText) {
                this.shareBtn = new GUI.LxButton(this, $("<div class='screen__share-button'>" + this.shareButtonText + "</div>"));
                this.insertSubviewAfter(this.shareBtn, this.elements.subtitleLbl2);

                this.shareBtn.onButtonTapped = function () {
                    console.log("not implemented");
                }.bind(this);
            }
        }.bind(this));
    }

    getRightIcon() {
        if (this.rightButtonSrc) {
            return this.rightButtonSrc;
        }

        return super.getRightIcon(...arguments);
    }

    rightAction() {
        if (this.rightButtonAction) {
            return this.rightButtonAction();
        }

        return super.rightAction(...arguments);
    }

    getAnimation() {
        return AnimationType.NONE;
    }

    continueBtnAction() {
        console.warn("Continue button action not implemented");
    }

    onTopLeftButtonTapped() {
        this.ViewController.dismiss();
    }

}

GUI.HomeKitOnboardingScreen = HomeKitOnboardingScreen;
