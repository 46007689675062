import {LxBaseControlContent} from "LxComponents";
import {useCallback} from "react";

function NfcCodeTouchControlContent ({ controlUUID }) {
    const onAppear = useCallback((control, states) => SandboxComponent.setVisuPasswordAutoInvalidation(false), [])
    const onDestroy = useCallback((control, states) => {
        SandboxComponent.setVisuPasswordAutoInvalidation(true);
        control.resetCache();
    }, [])

     return <LxBaseControlContent onAppear={onAppear} onDestroy={onDestroy}/>
}

export default NfcCodeTouchControlContent
