'use strict';

define(["./connectionWaitingScreen.jsx"], function (ConnectionWaitingComp) {
    return class ConnectingWaitingScreen extends GUI.Screen {
        //region Static
        static ReactComp = ConnectionWaitingComp.default;
        //endregion Static

        get reactHeaderShown() {
            return false;
        }

        constructor(details) {
            super($("<div />"));
        }

        viewDidLoad() {
            var prms = super.viewDidLoad(...arguments);
            this.setUpComfortUI(false);
            return prms;
        }

        getAnimation() {
            return AnimationType.FADE;
        }

        hasHistory() {
            return false;
        }
    };
});
