'use strict';

define(["IrrigationControlEnums"], function (CtrlEnums) {
    return class IrrigationDurationSettings extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div>'));
            Object.assign(this, StateHandler.Mixin, ContextMenuHandler.Mixin)
            this.control = details.control;
            this._changedDurations = {};
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function () {
                this._registerForStates(this.control.uuidAction, this.getStateIDs());
            }.bind(this));
        }

        viewWillDisappear() {
            var args = arguments;
            return this._sendChangedDurations().then(() => {
                return super.viewWillDisappear(...args);
            }, () => {
                return super.viewWillDisappear(...args);
            });
        }

        viewDidDisappear() {
            this._unregisterStates();

            return super.viewDidDisappear(...arguments);
        }

        getURL() {
            return this.control.uuidAction + "/durationSettings";
        }

        getAnimation() {
            return AnimationType.HD_OVERLAY;
        }

        titleBarText() {
            return _("controls.irrigation.duration");
        }

        receivedStates(states) {
            this.states = states;
            this.reloadTable();
        }

        reloadTable() {
            this.tableContent = [];
            var section = {
                rows: []
            };
            this.states && this.states.zones.forEach(function (zone) {
                section.rows.push(this._createCellForZone(zone));
            }.bind(this));
            this.tableContent.push(section);
            return super.reloadTable(...arguments);
        }

        getStateIDs() {
            return [CtrlEnums.StateId.ZONES];
        }

        _getZoneForIndex(idx) {
            return this.states.zones[idx];
        }

        _createCellForZone(zone) {
            let durationValue = this._getValueForZone(zone);

            let cellObj = {
                type: GUI.TableViewV2.CellType.GENERAL,
                content: {
                    title: zone.name,
                    disclosureText: LxDate.formatSecondsShort(durationValue, true, true),
                    clickable: !zone.setByLogic,
                    disclosureIcon: !zone.setByLogic
                },
                action: this._showDurationPickerForZone.bind(this)
            };

            if (zone.setByLogic) {
                cellObj.content.subtitle = _("controlled-via-logic");
                delete cellObj.action;
            }

            return cellObj;
        }

        _showDurationPickerForZone(cell, section, row) {
            var zone = this._getZoneForIndex(row);
            this._showContextMenu({
                type: GUI.LxTimePickerContextMenu.TYPE.DURATION,
                value: this._getValueForZone(zone),
                wheelOrder: "hhiiss",
                minTime: 0,
                // allowing 0 enables one to disable irrigating a certain zone (2021-07-06, AH)
                maxTime: 1000 * 60 * 60 * 20,
                // 20 hours top is okay (2021-07-06, AH)
                onPickerChanged: function (value) {
                    // Get seconds
                    value = value[0] * 60 * 60 + value[1] * 60 + value[2];

                    this._handleOnValueChange(value, zone.id);
                }.bind(this)
            }, _("controls.irrigation.duration-zone", {
                zone: zone.name
            }), null, GUI.LxTimePickerContextMenu);
        }

        _getValueForZone(zone) {
            var value;

            if (this._changedDurations.hasOwnProperty(zone.id + "")) {
                value = this._changedDurations[zone.id];
            } else {
                value = zone.duration;
            }

            return value;
        }

        _handleOnValueChange(value, zoneId) {
            this._changedDurations[zoneId] = value;
            this.reloadTable();
        }

        _sendChangedDurations() {
            var args,
                cmd = "",
                promise;
            args = Object.keys(this._changedDurations).map(function (zoneId) {
                return Commands.format(Commands.IRRIGATION.SET_DURATION_ARG, zoneId, this._changedDurations[zoneId]);
            }.bind(this));

            if (args.length === 1) {
                cmd = Commands.format(Commands.IRRIGATION.SET_SINGLE_DURATION, args[0]);
            } else if (args.length > 1) {
                cmd = Commands.format(Commands.IRRIGATION.SET_MULTIPLE_DURATIONS, args.join("/"));
            }

            if (cmd !== "") {
                promise = this.control.sendCommand(cmd);
            } else {
                promise = Q.resolve();
            }

            return promise;
        }

    };
});
