'use strict';

define(["GlobalStyles", "LxComponents"], function ({ default: globalStyles }, {MessageDetailScreen}) {
    return class MessageCenterMessageScreen extends GUI.TableViewScreen {
        static ReactComp = MessageDetailScreen

        get useNewTableView() {
            return true;
        }

        constructor(details) {
            super($('<div />'));
            this.entry = details.entry;
            this.messageCenterUuid = ActiveMSComponent.getStructureManager().getMessageCenter().uuidAction;
            this.styles = {
                List: {
                    width: "100%",
                    maxWidth: globalStyles.sizes.contentMaxWidth,
                    alignSelf: "center"
                }
            }
        }

        viewDidLoad() {
            let promise = super.viewDidLoad(...arguments);
            this.setUpComfortUI(true);
            this.setIcon(MessageCenterHelper.getIconForSeverityEntry(this.entry, false, true));
            this.setIconColor(MessageCenterHelper.getColorForSeverityEntry(this.entry, true));
            this.setTitle(this.entry.affectedName);
            this.setSubtitle(this.entry.title);
            return promise;
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        getURL() {
            return UrlStartLocation.MESSAGE_CENTER + "/" + this.entry.entryUuid;
        }

        viewWillAppear() {
            let promise = super.viewWillAppear(...arguments);
            this.deregMessageCenterUpdateReceiver = ActiveMSComponent.registerForMessageCenterUpdate(this._messageCenterUpdate.bind(this));
            return promise;
        }

        viewDidAppear() {
            let promise = super.viewDidAppear(...arguments);

            this._readEntry();

            return promise;
        }

        viewWillDisappear() {
            this.deregMessageCenterUpdateReceiver();
            return super.viewWillDisappear(...arguments);
        }

        _getTitleForEntry() {
            let date = moment.unix(this.entry.setHistoricAt).format(DateType.WeekdayAndDateTextAndTimeNoYear);

            if (this.entry.eventId === MessageCenterHelper.KNOWN_ENTRY_ID.DEVICE_UPDATE) {
                return _("message-center.device-ack-at", {
                    date: date
                });
            } else {
                return _("message-center.resolved-at", {
                    date: date
                });
            }
        }

        reloadTable() {
            let isHistoricEntry = !!this.entry.setHistoricAt,
                isConfirmedAt = !!this.entry.confirmedAt;

            this.tableContent = [{
                rows: [
                    {
                        content: {
                            title: this.entry.desc.replace(/<br>/gm, "\n"),
                        }
                    }
                ]
            }];

            if (this.entry.helpLink) {
                this.tableContent[0].rows.push({
                    content: {
                        title: this.entry.helpLink,
                        titleColor: window.Styles.colors.brand
                    },
                    action: this._openWebsite.bind(this, this.entry.helpLink)
                })
            }

            if (isHistoricEntry) {
                // Don't show the "Resolved at ..." label for this eventIds
                if (this.entry.eventId !== MessageCenterHelper.KNOWN_ENTRY_ID.REBOOT && this.entry.eventId !== MessageCenterHelper.KNOWN_ENTRY_ID.MS_UPDATE && this.entry.eventId !== MessageCenterHelper.KNOWN_ENTRY_ID.INIT_PRESENTATION && this.entry.eventId !== MessageCenterHelper.KNOWN_ENTRY_ID.OLD_SYSTEM_MESSAGE) {
                    this.tableContent[0].rows.push({
                        type: GUI.TableViewV2.CellType.LIGHT,
                        content: {
                            title: this._getTitleForEntry(),
                            color: window.Styles.colors.text.secondary
                        }
                    });
                }
            } else {

                // This screen can be displayed in two different ViewControllers
                // • ControlContentViewController: Opened directly from a ControlContent
                //  • Remove the "Jump to Control" action, the user just has to navigate back to go to the control
                // • ActiveMiniserverViewController: From the MessageCenterMessagesScreen
                //  • Display the "Jump to Control" action as usual


                if (this.ViewController instanceof GUI.ControlContentViewController) {
                    this.entry.actions = this.entry.actions.filter(function (action) {
                        return action.actionId !== MessageCenterHelper.KNOWN_ACTION_ID.JUMP_TO_CONTROL;
                    });
                }

                this.tableContent[0].rows.push(...this.entry.actions.map(this._getCellForAction.bind(this)));

                if (isConfirmedAt) {
                    this.tableContent[0].rows.push({
                        content: {
                            title: _("message-center.ack-at", {
                                date: moment.unix(this.entry.confirmedAt).format(DateType.WeekdayAndDateTextAndTimeNoYear)
                            }),
                            clickable: false,
                            color: window.Styles.colors.text.secondary
                        },
                        type: GUI.TableViewV2.CellType.LIGHT
                    });
                }
            }


            const location = MessageCenterHelper.getLocationForEntry(this.entry);
            const locationSuffix = location ? (SEPARATOR_SYMBOL + location) : "";
            this.tableContent[0].rows.push({
                type: GUI.TableViewV2.CellType.LIGHT,
                content: {
                    title: _("message-center.occurred-on", { date: MessageCenterHelper.getFormatedDate(this.entry) }) + locationSuffix,
                    color: window.Styles.colors.text.secondary
                }
            });


            return super.reloadTable(...arguments);
        }

        closeAction() {
            // This screen can be displayed in two different ViewControllers
            // • ControlContentViewController: Opened directly from a ControlContent
            //  • Hide the ViewController when navigating back (display the controlList), if the control is locked, we can't display the control in this state
            // • ActiveMiniserverViewController: From the MessageCenterMessagesScreen
            //  • Just make a regular navigateBack, this hides just this screen and reveals the underlying screen
            if (this.ViewController instanceof GUI.ControlContentViewController && this.entry && !this.entry.setHistoricAt && this.entry.isVisuLocked) {
                this.ViewController.dismiss();
            } else {
                super.closeAction(...arguments);
            }
        }

        /**
         * React to messageCenter updates by either update the tableView,
         * or navigate back, if the message has been removed
         * @param ev
         * @param messageCenterStructure
         * @private
         */
        _messageCenterUpdate(ev, messageCenterStructure) {
            var previousEntryString = JSON.stringify(this.entry);
            this.entry = messageCenterStructure.entries.find(function (entry) {
                return entry.entryUuid === this.entry.entryUuid;
            }.bind(this));

            if (!this.entry) {
                this.closeAction();
            } else if (previousEntryString !== JSON.stringify(this.entry)) {
                this.setIcon(MessageCenterHelper.getIconForSeverityEntry(this.entry, false, true));
                this.setIconColor(MessageCenterHelper.getColorForSeverityEntry(this.entry, true));
                this.setTitle(this.entry.affectedName);
                this.setSubtitle(this.entry.title);
                this.reloadTable(); // not needed anymore
                // ControlContentViewController handles this behavior

                /*if (this.entry.setHistoricAt &&
                    (this.ViewController instanceof GUI.ControlContentViewController) &&
                    this.entry.eventId === MessageCenterHelper.KNOWN_ENTRY_ID.INTERCOM_SABOTAGE) {
                     this.closeAction();
                }*/
            }
        }

        /**
         * Takes an action and returns its cell configuration
         * @param action
         * @return {{content: {title, clickable: boolean}, type: string, action}}
         * @private
         */
        _getCellForAction(action) {
            return {
                content: {
                    title: action.title,
                    textColor: window.Styles.colors.brand,
                    clickable: true
                },
                type: GUI.TableViewV2.CellType.Special.COMFORT_ACTION,
                action: this._executeEntryAction.bind(this, action)
            };
        }

        /**
         * Returns the cell to delete an entry
         * @return {{rows: *[]}}
         * @private
         */
        _getDeleteCell() {
            return {
                rows: [{
                    content: {
                        title: _('mobiscroll.clear')
                    },
                    type: GUI.TableViewV2.CellType.DELETE,
                    action: this._deleteEntry.bind(this)
                }]
            };
        }

        /**
         * Executes the defined action by either navigating to the provided location,
         * opening the hyperlink, or executing a remote action on the Miniserver
         * @param action
         * @param cell
         * @private
         */
        _executeEntryAction(action, cell) {
            // Some actions require specific actions.
            // Such actions can be defined in MessageCenterHelper.KNOWN_ACTIONS
            if (MessageCenterHelper.isActionKnown(action)) {
                MessageCenterHelper.executeKnownAction(action);
                return;
            }

            if (action.location) {
                window.handleOpenURL(action.location);
            } else if (action.link) {
                this._openWebsite(action.link);
            } else {
                var promise;
                this._thinkingTimeout = setTimeout(function () {
                    cell.setThinking(true);
                }.bind(this), 300);

                if (action.hasOwnProperty("requiredPermissions") && !!action.requiredPermissions) {
                    Debug.SystemState && console.log(this.name, "Command needs higher permissions", "Permissions: " + JSON.stringify(action.requiredPermissions));
                    promise = SandboxComponent.getPermission(action.requiredPermissions).then(this._sendCommand.bind(this, Commands.MESSAGE_CENTER.EXEC_ACTION, [action.actionId], action.isSecured));
                } else {
                    promise = this._sendCommand(Commands.MESSAGE_CENTER.EXEC_ACTION, [action.actionId], action.isSecured);
                }

                promise.then(function () {
                    if (action.actionId !== MessageCenterHelper.KNOWN_ACTION_ID.ACKNOWLEDGE) {
                        clearTimeout(this._thinkingTimeout);
                        cell.setThinking(false);
                    }
                }.bind(this), function (err) {
                    Debug.SystemState && console.log(this.name, "Command failed with error", JSON.stringify(err));

                    if (action.actionId === MessageCenterHelper.KNOWN_ACTION_ID.ACKNOWLEDGE) {
                        clearTimeout(this._thinkingTimeout);
                        cell.setThinking(false);
                        NavigationComp.showErrorPopup(false, null, _('wear-os.error-message.command-not-executed'));
                    }
                }.bind(this));
            }
        }

        /**
         * Opens the provided link:
         * Webinterface: Opens the link in a new tab
         * Native: Opens the link in the browser or the native in App WebView
         * @param link
         * @private
         */
        _openWebsite(link) {
            NavigationComp.openWebsite(link);
        }

        /**
         * Set an entry as read
         * @private
         */
        _readEntry() {
            this._sendCommand(Commands.MESSAGE_CENTER.READ_ENTRY);
        }

        /**
         * Deletes an entry. This is only possible if entry.setHistoricAt is available!
         * @private
         */
        _deleteEntry() {
            this._sendCommand(Commands.MESSAGE_CENTER.DELETE_ENTRY).finally(function () {
                this.ViewController.navigateBack();
            }.bind(this));
        }

        /**
         * Sends a command to the messageCenter
         * Note: the messageCenterUuid and entryUuid will be added automatically, additional parameters can be passed if the cmd requires it
         * @param cmd The command, it must include placeholders for the messageCenterUuid and entryUuid
         * @param [args] List of arguments used in the cmd argument
         * @param [isSecured] Indicates that the command requires a visu password
         * @return {*}
         * @private
         */
        _sendCommand(cmd, args, isSecured) {
            var formatArgs, prms; // The entry may already be removed by the Miniserver itself. Thus sending any command would fail because this.entry is null.
            // We don't need any special error handling because the Screen will remove it self if there is no entry anymore

            if (this.entry) {
                formatArgs = [cmd, this.entry.entryUuid];
                formatArgs = formatArgs.concat(args || []);
                cmd = Commands.format.apply(this, formatArgs); // Parameters: sender, uuid (used to build the command) cmd itself, type use default, if the command is secured, If the command is sent automatically without the users intervention, dontRecord

                prms = SandboxComponent.sendCommand(this, this.messageCenterUuid, cmd, null, isSecured, true, true);
            } else {
                prms = Q(true);
            }

            return prms;
        }

    };
});
