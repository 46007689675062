import globalStyles from "GlobalStyles";
import React, {useState, useContext, useMemo} from 'react';
import {View, Text} from 'react-native';
import {AmbientContext} from "LxComponents"
import { mapGraphValueToPixelSpace } from ".";

const Pills = ({ datapoints, rightInnerGraphEnd, dateFormat, markedIndex, valueFormat = "%.2f", forcedTimeRange, title }) => {
    const [displayWidth, setDisplayWidth] = useState(0);

    // To prevent rendering before onLayout returns the current with of the Pills we set the isWidthKnown variable to false whenever the content of the pills change
    const [isWidthKnown, setWidthKnown] = useState(false);
    useMemo(()=>{
        setWidthKnown(false);
    }, [JSON.stringify(datapoints), markedIndex]);

    const {isAmbientMode} = useContext(AmbientContext);
    const dataPointForPills = datapoints?.[markedIndex] ?? [];
    let flattendDataPoint = [dataPointForPills];
    if(Array.isArray(dataPointForPills)) {
        flattendDataPoint = dataPointForPills;
    }

    const headerTitle = useMemo(() => {
        if (!flattendDataPoint.length) {
            return "";
        }
        if (typeof title === "string") {
            return title;
        } else if (typeof title === "function") {
            return title(flattendDataPoint, dateFormat, valueFormat);
        } else if (flattendDataPoint.some(dP => dP.timestamp)) {
            return ActiveMSComponent.getMomentFromUtcTimestamp(flattendDataPoint[0].timestamp).format(dateFormat)
        }
    }, [title, flattendDataPoint, dateFormat]);

    if(!Array.isArray(datapoints) || !datapoints[markedIndex]) {
        return null;
    }
    const numDataPoints = datapoints.length;
    const dataPointWidth = rightInnerGraphEnd / numDataPoints;

    const centerX = !forcedTimeRange ?
        dataPointWidth * markedIndex + (dataPointWidth / 2):
        mapGraphValueToPixelSpace(forcedTimeRange.start, forcedTimeRange.end, 0, rightInnerGraphEnd, flattendDataPoint[0].timestamp);
    const pillHeight = 25;
    const textSize = 17;
    const withTimestamp = flattendDataPoint.some(dP => dP.timestamp);

    if(!Array.isArray(flattendDataPoint) || flattendDataPoint.length === 0) {
        return null;
    }

    const onLayoutCallback = (width) => {
        // onLayout is called after the first render so we need to set isWidthKnown to true for the next render to display the pills
        Promise.resolve().then(()=> setWidthKnown(true));
        setDisplayWidth(width);
    }

    const stuckOnLeft = centerX < displayWidth / 2;
    const stuckOnRight = centerX + displayWidth / 2 > rightInnerGraphEnd;

    const getPillText = (dP) => {
        if (typeof dP.displayString === "string") {
            return dP.displayString;
        } else if (typeof dP.displayValue === 'number') {
            return lxUnitConverter.convertAndApply(valueFormat, dP.displayValue, true);
        } else {
            return lxUnitConverter.convertAndApply(valueFormat, dP.value, true);
        }
    }

    return (
        <View style={{marginTop: -51, marginBottom: 9, height: 42, backgroundColor: isAmbientMode ? 'transparent' : 'black'}}>
            <View style={{height: 42}}>
                <View
                    onLayout={({nativeEvent: {layout: {width}}}) => onLayoutCallback(width)}
                    style={{
                        left: stuckOnRight ? (rightInnerGraphEnd - displayWidth) : stuckOnLeft ? 0 : centerX,
                        width: 'auto',
                        position: 'absolute',
                        transform: !stuckOnRight && !stuckOnLeft ? [{translateX: '-50%'}] : undefined,
                        bottom: 0,
                    }}
                >
                    {
                        headerTitle ? (
                            <Text style={{color: 'rgba(235, 235, 245, 0.6)', fontSize: textSize, fontFamily: globalStyles.fontSettings.families.bold, whiteSpace: 'nowrap'}}>
                                {headerTitle}
                            </Text>
                        ) : null
                    }
                    <View style={{flexDirection: 'row'}}>
                        {
                            flattendDataPoint.map((dP, idx) => {
                                return (
                                    <View key={`graph-pill-${idx}`} style={{height: pillHeight, alignItems: 'center', justifyContent: 'center', paddingLeft: 8, paddingRight: 8, borderRadius: pillHeight / 2, overflow: 'hidden', marginLeft: idx !== 0 ? 4 : 0, marginRight: idx !== flattendDataPoint.length - 1 ? 4 : 0}}>
                                        <View style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', backgroundColor: dP.color, opacity: 0.25}} />
                                        <Text style={{color: dP.color, fontSize: pillHeight * 0.68, letterSpacing: -pillHeight * 0.04}}>
                                            {getPillText(dP)}
                                        </Text>
                                    </View>
                                )
                            })
                        }
                    </View>
                </View>
            </View>
        </View>
    )
}

export default Pills
