
const useDeviceOrientation = () => {

    const defaultOrientation = HD_APP || AMBIENT_MODE ? DeviceOrientation.ANY : DeviceOrientation.PORTRAIT_PRIMARY;

    const supportsOrientation = () => {
        return (PlatformComponent.isDeveloperInterface() ||
            PlatformComponent.isCordova &&
            window.hasOwnProperty("screen") &&
            window.screen.hasOwnProperty("orientation") &&
            window.screen.orientation &&
            typeof window.screen.orientation.lock === "function");
    }

    const set = (orientation = defaultOrientation) => {
        if (supportsOrientation()) {
            if (orientation !== window._CURRENT_ORIENTATION) {
                window._CURRENT_ORIENTATION = orientation; // This is the bond between React and our legacy UI!
                if (PlatformComponent.isDeveloperInterface()) {
                    developerAttention(`Changing orientation to ${orientation}`);
                } else {
                    screen.orientation.lock(window._CURRENT_ORIENTATION);
                    Debug.Orientation && console.log(`Orientation: ${window._CURRENT_ORIENTATION}`);
                }
            }
        } else {
            Debug.Orientation && console.log(`Orientation not supported!`);
            Debug.Orientation && console.log(`Orientation not supported! orientation present=` + !!window.screen.orientation);
        }
    }

    const get = () => {
        if (supportsOrientation()) {
            return window._CURRENT_ORIENTATION || defaultOrientation;
        } else {
            return defaultOrientation
        }
    }

    return {
        get isSupported() {
            return supportsOrientation();
        },
        set: set,
        get: get
    }
}

export default useDeviceOrientation;
