import {
    useControl,
    useLiveState,
    DateViewType,
    DateViewTypeSelector,
    DateViewTypeUtils,
    LxReactControlStatsGraph,
    LxReactLinkedControlsList,
    AmbientContext,
    LxControlContextProvider,
    LxControlNoteView,
    useBorderColor
} from "LxComponents"
import { View, ScrollView } from "react-native";
import { useMemo } from "react";
import globalStyles from "GlobalStyles"
import MeterV2TotalValues from "./meterV2TotalValues.jsx";
import { useState, useEffect, useContext } from "react";
import MeterV2BidirectionalLiveEnergyBalance from "./meterV2BidirectionalLiveEnergyBalance";
import MeterV2BidirectionalEnergyBalance from "./meterV2BidirectionalEnergyBalance";
import MeterV2NoStatsControlContent from "./meterV2NoStatsControlContent";
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { StyleSheet } from "react-native-web";


export default function MeterV2ControlContent({ navigation, route, updateNavigation, updateDetailsForExpand }) {

    // state handling to show/hide the zoomed graph
    const [zoomedGraph, setZoomedGraph] = useState(route.params.zoomedGraph)
    const [viewInfo, setViewInfo] = useState(DateViewTypeUtils.initializeViewInfo(route.params.viewType, route.params.timestamp, route.params.fromEfm));

    const { isAmbientMode } = useContext(AmbientContext);
    const updateZoomedGraph = (newVal) => {
        const forceZoom = (newVal >= 0 && isAmbientMode);
        updateDetailsForExpand({ ...route.params, viewType: viewInfo.vt, timestamp: viewInfo.ts }, (forceZoom ? { zoomedGraph: newVal, expandedIntoGraph: forceZoom } : null));
        !forceZoom && setZoomedGraph(newVal);
    }
    useEffect(() => {
        return navigation.addListener('beforeRemove', (e) => {
            if (zoomedGraph >= 0 && !route.params.expandedIntoGraph) {
                updateZoomedGraph(-1);
                e.preventDefault();
            }
        });
    }, [navigation, zoomedGraph]);

    // mind the safe area when zoomed.lx
    const insets = useSafeAreaInsets();


    const ctrlUuid = route.params.controlUUID;
    const control = useControl(ctrlUuid);

    const liveState = useLiveState(ctrlUuid);

    // initialize the viewInfo with potentially provided infos (content may have been opened by the EFM which provides
    // those infos)
    const updateViewInfo = (newInfo) => {
        updateDetailsForExpand({ ...route.params, zoomedGraph: zoomedGraph, viewType: newInfo.vt, timestamp: newInfo.ts });
        setViewInfo({ ...newInfo });
    }

    const styles = {
        rootCntr: {
            flexDirection: "column",
            maxHeight: "100%",
            flex: 1
        },
        viewSelection: {
            minHeight: 60,
        },
        viewSelectionText: {
            ...globalStyles.textStyles.title1.bold,
            color: globalStyles.colors.text.primary,
            textAlign: "center",
        },
        viewContainer: {
            ...globalStyles.customStyles.screenContent,
            flex: 1,
            flexDirection: "column",
        },
        overview: {
            height: 210,
            justifyContent: "center",
            paddingLeft: globalStyles.sizes.small,
            paddingRight: globalStyles.sizes.small,
        },
        section: {
            ...globalStyles.borders.top,
            flexDirection: "column",
            paddingLeft: globalStyles.sizes.small,
            paddingRight: globalStyles.sizes.small,
            marginBottom: globalStyles.sizes.medium
        },
        sectionGraph: {
            marginTop: globalStyles.sizes.smallest,
            marginBottom: globalStyles.sizes.smallest
        },
        zoomedGraphCntr: {
            marginLeft: globalStyles.spacings.contentHorizontal,
            marginRight: globalStyles.spacings.contentHorizontal,
            marginBottom: insets.bottom,
            height: "100%",
            flex: 1
        },
        zoomedGraph: {
            width: "100%",
            height: "100%"
        },
        noteContainer: { // meter has an outer margin in it's content. ensure the note fits it.
            marginHorizontal: globalStyles.sizes.small
        }
    }

    const isBidirectional = () => {
        return control && control.isBidirectional();
    }

    const hasStorage = () => {
        return control && control.hasStorage();
    }

    const hasMiniserverStatesForViewType = () => {
        let hasLiveState = false,
            isViewingCurrent;
        if (viewInfo.vt === DateViewType.Lifetime) {
            isViewingCurrent = true;
        } else {
            let checkRange = viewInfo.vt === DateViewType.Live ? DateViewType.Day : viewInfo.vt;
            isViewingCurrent = ActiveMSComponent.isUnixUtcTsWithinCurrentRange(viewInfo.ts, checkRange);
        }
        switch (viewInfo.vt) {
            case DateViewType.Lifetime:
                hasLiveState = true; // total or actual states always present.
                break;
            case DateViewType.Day:
            case DateViewType.Live:
                hasLiveState = control.hasTotalDay && isViewingCurrent;
                break;
            case DateViewType.Week:
                hasLiveState = control.hasTotalWeek && isViewingCurrent;
                break;
            case DateViewType.Month:
                hasLiveState = control.hasTotalMonth && isViewingCurrent;
                break;
            case DateViewType.Year:
                hasLiveState = control.hasTotalYear && isViewingCurrent;
                break;
            default:
                break;
        }
        return hasLiveState;
    }

    const renderOverview = () => {
        if (isBidirectional()) {
            if (hasMiniserverStatesForViewType()) {
                return <MeterV2BidirectionalLiveEnergyBalance
                    controlUUID={ctrlUuid}
                    style={styles.overview}
                    viewType={viewInfo.vt}
                />
            } else {
                return <MeterV2BidirectionalEnergyBalance
                    controlUUID={ctrlUuid}
                    style={styles.overview}
                    fromUnixUtcTs={viewInfo.start}
                    toUnixUtcTs={viewInfo.end}
                    viewType={viewInfo.vt}
                />
            }
        } else {
            // unidirectional always shows energy for "today", "this month", "this year" - no matter what date/time is selected
            return <MeterV2TotalValues
                style={styles.overview}
                controlUUID={ctrlUuid}
                viewInfo={viewInfo} />
        }
    }

    const getTotalDataPointUnit = () => {
        let displayUnit = "all";
        switch (viewInfo.vt) {
            case DateViewType.Day:
            case DateViewType.Week:
            case DateViewType.Month:
            case DateViewType.Year:
            case DateViewType.Lifetime:
                displayUnit = DateViewTypeUtils.getStatisticDiffDataPointUnit(viewInfo.vt);
                break;
            default:
                break;
        }
        return displayUnit;
    }

    const createSectionsDataSet = () => {
        let result = [];
        if (viewInfo.vt === DateViewType.Live) {
            let isViewingCurrent = ActiveMSComponent.isUnixUtcTsWithinCurrentRange(viewInfo.ts, DateViewType.Day);

            result.push({
                titleLeft: control.getNameForOutput("actual"),
                titleRight: isViewingCurrent ? liveState.states.actualText : "",
                outputNames: ["actual"],
                ignoreNegative: !control.isBidirectional(),
                forcedColors: control.actualPowerStatColor.forcedColors,
                colorInfo: control.actualPowerStatColor.colorInfo,
                format: control.actualFormat,
                dataPointUnit: "all",
                legend: control.actualPowerStatLegend,
            });

            if (hasStorage()) {
                result.push({
                    titleLeft: control.getNameForOutput("storage"),
                    titleRight: isViewingCurrent ? liveState.states.storageText : "",
                    outputNames: ["storageLevel"],
                    ignoreNegative: true,
                    fixedUpperLimit: control.details.storageMax,
                    colorInfo: control.storageLevelStatColor.colorInfo,
                    format: control.storageFormat,
                    dataPointUnit: "all",
                    legend: control.storageLevelStatLegend,
                });
            }

        } else {
            let totalDataPointUnit = getTotalDataPointUnit();

            if (isBidirectional()) {
                result.push({
                    titleLeft: _("meter2.reading"),
                    outputNames: ["total", "totalNeg"],
                    dataPointUnit: totalDataPointUnit,
                    colorInfo: control.totalEnergyStatColor.colorInfo,
                    legend: control.totalEnergyStatLegend,
                    format: control.totalFormat
                });
            } else {
                result.push({
                    titleLeft: _("meter2.reading"),
                    outputNames: ["total"],
                    colorInfo: control.totalEnergyStatColor.colorInfo,
                    dataPointUnit: totalDataPointUnit,
                    legend: control.totalEnergyStatLegend,
                    format: control.totalFormat
                })
            }

            if (viewInfo.vt === DateViewType.Day && hasStorage()) {
                result.push({
                    titleLeft: control.getNameForOutput("storage"),
                    outputNames: ["storageLevel"],
                    colorInfo: control.storageLevelStatColor.colorInfo,
                    ignoreNegative: true,
                    fixedUpperLimit: control.details.storageMax,
                    format: control.storageFormat,
                    dataPointUnit: "all",
                    legend: control.storageLevelStatLegend,
                    forcedGraphType: "line"
                });
            }
        }

        // add universal stat props
        result.forEach((resultItem, idx) => {
            resultItem.onZoom = () => { updateZoomedGraph(idx) };
            resultItem.viewType = viewInfo.vt;
            resultItem.controlUuid = ctrlUuid;
            resultItem.fromUnixUtcTs = viewInfo.start;
            resultItem.toUnixUtcTs = viewInfo.end;
        });

        return result;
    }

    const borderColor = useBorderColor();
    const renderSections = () => {
        if (control.supportsStatisticV2) {
            return createSectionsDataSet().map((section, idx) => {
                return <View
                    style={StyleSheet.flatten([styles.section, { borderTopColor: borderColor }])}
                    key={"section-" + idx}>
                    <LxReactControlStatsGraph {...section} style={styles.sectionGraph} />
                </View>;
            });
        }
    }

    const renderDateViewTypeSelector = () => {
        if (control.supportsStatisticV2) {
            return <View style={styles.viewSelection}>
                <DateViewTypeSelector onUpdateVt={updateViewInfo} autoUpdateTs={true}
                    initialViewType={viewInfo.vt} initialTs={viewInfo.ts}
                    allowPastLive={true}
                    liveTypeName={control.getNameForOutput("actual")} />
            </View>
        }
    }

    const renderZoomedGraph = () => {
        let graphSource = createSectionsDataSet();
        if (graphSource && graphSource[zoomedGraph]) {
            let graphProps = {
                ...graphSource[zoomedGraph],
                fullStretch: true,
                style: styles.zoomedGraph
            };
            delete graphProps.onZoom;
            return <LxReactControlStatsGraph  {...graphProps} />
        } else {
            updateZoomedGraph(-1);
            return null;
        }
    }


    /**
     * This takes care of intervening with the back-navigation, in order to update the timestamp/viewType of the parent
     * efm - if there is one.
     */
    useEffect(() => {
        let updateProps = {};
        if (route.params.fromEfm) {
            updateProps.onLeftAction = () => {
                route.params.updateParentViewInfo && route.params.updateParentViewInfo(viewInfo);
                navigation.goBack();
            }
            updateProps.showBack = true;
        }

        if (zoomedGraph >= 0) {
            updateProps.title = createSectionsDataSet()[zoomedGraph].titleLeft;
            updateProps.showBack = true;
        }

        updateNavigation(updateProps);

    }, [route.params.fromEfm, viewInfo.vt, viewInfo.ts, zoomedGraph]); // important to update when one of these changes, to avoid passing back initial values.

    /**
     * Also wrapping the context in a condition to avoid unnecessary registrations
     * Wrapping to align its width with the remaining contnent.
     * @type {JSX.Element}
     */
    const controlNotes = useMemo(() => {
        return control && control.hasControlNotes ?
            <View style={styles.noteContainer}>
                <LxControlContextProvider controlUuid={ctrlUuid} isAlert={false}>
                    <LxControlNoteView showUpperSeparator={false} />
                </LxControlContextProvider>
            </View>
            : null;
    }, [control && control.hasControlNotes, ctrlUuid])

    return !control.supportsStatisticV2 ? (
        <MeterV2NoStatsControlContent navigation={navigation} route={route} />
    ) : (
        <View style={styles.rootCntr}>
            {renderDateViewTypeSelector()}
            {zoomedGraph < 0 ? (
                <ScrollView style={styles.viewContainer}>
                    {controlNotes}
                    {renderOverview()}
                    {renderSections()}
                    <LxReactLinkedControlsList controlUuid={ctrlUuid} />
                </ScrollView>
            ) : (
                <View style={styles.zoomedGraphCntr}>
                    {renderZoomedGraph()}
                </View>
            )}
        </View>
    );
}
