'use strict';

define("UpDownLeftRightDigitalControl", [
    "Control",
    "IconLib"
], function (
    Control,
    {
        default: Icons
    }
) {
    return class UpDownLeftRightDigitalControl extends Control {
        constructor() {
            super(...arguments);
        }

        isUpDown() {
            return this.type.startsWith("UpDown");
        }

        supportsDecrease() {
            return true;
        }

        supportsStates() {
            return false; // EIB Jalousie has states, but we don't need them!
        }

        getButton0() {
            let cmds = this.getCommands();

            if (cmds) {
                return {
                    iconSrc: this.getButtonIcon(Direction.UP),
                    reactIcon: this.getReactButtonIcon(Direction.UP),
                    command: cmds[1].cmd
                };
            }
        }

        getButton1() {
            let cmds = this.getCommands();

            if (cmds) {
                return {
                    iconSrc: this.getButtonIcon(Direction.DOWN),
                    reactIcon: this.getReactButtonIcon(Direction.DOWN),
                    command: cmds[0].cmd
                };
            }
        }

        getCommand(direction) {
            if (direction === Direction.DOWN) {
                return Feature.UP_DOWN_LEFT_RIGHT_PULSE_SUPPORT ? Commands.UP_DOWN_LEFT_RIGHT.DIGITAL.DOWN_PULSE : null;
            } else if (direction === Direction.UP) {
                return Feature.UP_DOWN_LEFT_RIGHT_PULSE_SUPPORT ? Commands.UP_DOWN_LEFT_RIGHT.DIGITAL.UP_PULSE : null;
            }
        }

        getHitCommand(direction) {
            if (direction === Direction.DOWN) {
                return Commands.UP_DOWN_LEFT_RIGHT.DIGITAL.DOWN_ON;
            } else if (direction === Direction.UP) {
                return Commands.UP_DOWN_LEFT_RIGHT.DIGITAL.UP_ON;
            }
        }

        getReleaseCommand(direction) {
            if (direction === Direction.DOWN) {
                return Commands.UP_DOWN_LEFT_RIGHT.DIGITAL.DOWN_OFF;
            } else if (direction === Direction.UP) {
                return Commands.UP_DOWN_LEFT_RIGHT.DIGITAL.UP_OFF;
            }
        }

        getButtonIcon(direction) {
            if (this.isUpDown()) {
                if (direction === Direction.DOWN) {
                    return Icon.UDLR.DOWN;
                } else if (direction === Direction.UP) {
                    return Icon.UDLR.UP;
                }
            } else {
                if (direction === Direction.DOWN) {
                    return Icon.UDLR.LEFT;
                } else if (direction === Direction.UP) {
                    return Icon.UDLR.RIGHT;
                }
            }
        }

        getReactButtonIcon(direction) {
            if (this.isUpDown()) {
                if (direction === Direction.DOWN) {
                    return Icons.ArrowDown;
                } else if (direction === Direction.UP) {
                    return Icons.ArrowUp;
                }
            } else {
                if (direction === Direction.DOWN) {
                    return Icons.ArrowLeft;
                } else if (direction === Direction.UP) {
                    return Icons.ArrowRight;
                }
            }
        }

        getContentButtonIcon(direction) {
            if (this.isUpDown()) {
                if (direction === Direction.DOWN) {
                    return Icon.UDLR.COMFORT_MODE.DOWN;
                } else if (direction === Direction.UP) {
                    return Icon.UDLR.COMFORT_MODE.UP;
                }
            } else {
                if (direction === Direction.DOWN) {
                    return Icon.UDLR.COMFORT_MODE.LEFT;
                } else if (direction === Direction.UP) {
                    return Icon.UDLR.COMFORT_MODE.RIGHT;
                }
            }
        }

    };
});
