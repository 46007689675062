'use strict';

define(['IRoomControllerV2ControlContent', 'IRoomControllerV2021ControlEnums', 'Irc2021StateOverview', 'IRoomControllerV2ControlEnums', 'LxComponents'], function (IRoomControllerV2ControlContent, CtrlEnums, Irc2021StateOverview, OldCtrlEnums, { LxReactContextMenuHandler }) {
    return class IRoomControllerV2021ControlContent extends IRoomControllerV2ControlContent {
        constructor() {
            super(...arguments);
            this.currentAdoptionTemp = -1000;
        }

        getCustomInfoView() {
            var bCanChooseMode = !this.control.isInputConnected(CtrlEnums.ConnectedInputs.MODE);
            return new Irc2021StateOverview(this.control, bCanChooseMode ? this._showModeSelectorScreen.bind(this) : null, this._showTemperaturesScreen.bind(this));
        }


        getActionSection(states) {
            var section = {
                rows: []
            };

            if (!states.isManual && !states.isOff) {
                this._addAutomaticContent(states, section); // change the type to the 2021 version.


                section.rows[0].type = GUI.TableViewV2.CellType.IRCV2021TimeLineCell;
            }

            return section;
        }

        getActionSections(states) {
            var sections = super.getActionSections(...arguments),
                adoptTempSection = this._getAdoptTempSection(states); // Add timer section.


            adoptTempSection && sections.push(adoptTempSection);
            return sections;
        }

        getAdditionalSection(states) {
            var section = super.getAdditionalSection(...arguments) || {
                rows: []
            };

            if (states.isOff) {
                section.rows.splice(0, 0, this._getModeCell(states));
                section.rows.splice(0, 0,
                    {
                        type: GUI.TableViewV2.CellType.GENERAL,
                        content: {
                            title: _("controls.irc.temperature.current"),
                            disabled: false,
                            clickable: !!this.control.statistic,
                            disclosureIcon: !!this.control.statistic,
                            disclosureText: this.control.formatTemp(states.tempActual),
                            disclosureColor: Color.TEXT_HINT_A
                        },
                        action: this.control.statistic ? this.showStatisticForOutput.bind(this, 0) : null
                    }
                );
                section.rows.pushObject(this._getLinkedAcCtrlsCell(states));
            } else {
                section.rows.splice(0, 0, this._getTemperaturesCell(states));
                !states.isManual && section.rows.splice(0, 0, this._getScheduleCell(states));
                section.rows.splice(0, 0, this._getModeCell(states));
                section.rows.pushObject(this._getLinkedAcCtrlsCell(states));
            }

            return section;
        }

        getTableContent(states) {
            var tableContent = super.getTableContent(...arguments);

            if (states.excessEnergyHeat || states.excessEnergyCool) {
                tableContent.splice(0, 0, {
                    rows: [this._getExcessEnergyCell(states)]
                });
            }

            return tableContent;
        }

        _getLinkedAcCtrlsCell() {
            let linkedCtrls = this.control.linkedAcControls,
                cell = null;

            if (linkedCtrls.length > 0) {
                cell = {
                    type: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        title: _("controls.ircv2021.connected-accontrol", { count: linkedCtrls.length }),
                        disabled: false,
                        clickable: true,
                        disclosureIcon: true,
                        disclosureText: null,
                        disclosureColor: Color.TEXT_HINT_A
                    },
                    action: this._showLinkedAcList.bind(this, linkedCtrls)
                };
            }

            return cell;
        }

        _getExcessEnergyCell(states) {
            var cell = {
                    type: GUI.TableViewV2.CellType.TEXT,
                    content: {
                        textColor: Color.WHITE,
                        largeText: true,
                        centered: true
                    }
                },
                offsetText = this.control.formatTemp(states.excessEnergyTempOffset),
                offsetHtml;

            if (states.excessEnergyHeat) {
                offsetHtml = this._getExcessOffsetHtml(offsetText, true);
                cell.content.text = _("controls.ircv2021.cheap-heating-energy") + " (" + offsetHtml + ")";
            } else if (states.excessEnergyCool) {
                offsetHtml = this._getExcessOffsetHtml(offsetText, false);
                cell.content.text = _("controls.ircv2021.cheap-cooling-energy") + " (" + offsetHtml + ")";
            }

            return cell;
        }

        _getExcessOffsetHtml(text, isHeating) {
            var textColor = isHeating ? window.Styles.colors.orange : window.Styles.colors.blue;
            return '<span style="color:' + textColor + '">' + (isHeating ? "+" : "") + text + '</span>';
        }

        // ---------------------------------------------------------------------------------
        // Overwritten baseclass methods.
        // ---------------------------------------------------------------------------------
        _showTemperaturesScreen() {
            this.ViewController.showState(CtrlEnums.ScreenState.TEMP_SETTINGS, null, {
                control: this.control
            });
        }

        _getMainStatusCell() {
            return null; // handled by infoView
        }

        _getSettingsCell() {
            return null; // avoid sub-screen with settings, every settings shown in controlContent
        }

        // ---------------------------------------------------------------------------------
        // Content Creation Methods.
        // ---------------------------------------------------------------------------------
        _getModeCell(states) {
            var setByLogic = this.control.isInputConnected(CtrlEnums.ConnectedInputs.MODE),
                stateText = states.activeOpModeText,
                title = _("controls.ircv2021.mode");
            let rightIconSrc, rightIconColor,
                rightIconSize = GUI.TableViewV2.Enums.GeneralCell.IconSize.MEDIUM;

            if (this.control.hasHeatingCapability() && this.control.hasCoolingCapability()) {
                switch (states.operatingMode) {
                    case OldCtrlEnums.OperatingMode.AUTOMATIC.ONLY_HEATING:
                    case OldCtrlEnums.OperatingMode.MANUAL.ONLY_HEATING:
                        rightIconSrc = Icon.ClimateUs.HEATING;
                        rightIconColor = window.Styles.colors.orange;
                        break;
                    case OldCtrlEnums.OperatingMode.AUTOMATIC.ONLY_COOLING:
                    case OldCtrlEnums.OperatingMode.MANUAL.ONLY_COOLING:
                        rightIconSrc = Icon.ClimateUs.COOLING;
                        rightIconColor = window.Styles.colors.blue;
                        break;
                    default:
                        break;
                }
            }

            if (setByLogic) {
                return {
                    type: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        title: title,
                        subtitle: _("controlled-via-logic"),
                        disabled: false,
                        clickable: false,
                        disclosureIcon: false,
                        disclosureText: stateText,
                        disclosureColor: Color.TEXT_HINT_A,
                        rightIconSrc: rightIconSrc,
                        rightIconColor: rightIconColor,
                        rightIconSize: rightIconSize
                    }
                };
            } else {
                return {
                    type: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        title: title,
                        disabled: false,
                        clickable: true,
                        disclosureIcon: true,
                        disclosureText: stateText,
                        disclosureColor: Color.TEXT_HINT_A,
                        rightIconSrc: rightIconSrc,
                        rightIconColor: rightIconColor,
                        rightIconSize: rightIconSize
                    },
                    action: this._showModeSelectorScreen.bind(this)
                };
            }
        }

        _getScheduleCell(states) {
            return this._createSettingCell(_("controls.ircv2021.schedule"), this._showTimesScreen.bind(this));
        }

        _getTemperaturesCell(states) {
            return this._createSettingCell(_("controls.ircv2021.temperatures"), this._showTemperaturesScreen.bind(this));
        }

        _createSettingCell(title, action, stateText) {
            var disabled = false;
            return {
                type: GUI.TableViewV2.CellType.GENERAL,
                content: {
                    title: title,
                    disabled: disabled,
                    clickable: !disabled,
                    disclosureIcon: !disabled,
                    disclosureText: stateText,
                    disclosureColor: Color.TEXT_HINT_A
                },
                action: action
            };
        }

        // --------------------------------------------------------------------------------
        // Temporary Adoption Handling
        // ---------------------------------------------------------------------------------
        _getAdoptTempSection(states) {
            var section = null;

            if (states.isOff) {
                return null;
            } else if (states.hasCurrentOverrideEntry && states.isTimerActive) {
                section = {
                    rows: [this._getStopOverrideCell(states)]
                };
            } else if (Debug.Control.IRoomControl.ExtensiveTempCell) {
                section = {
                    rows: [this._getAdoptionCell(states)]
                };
            } else {
                section = {
                    rows: [this._getSimpleAdoptionCell(states)]
                };
            }

            return section;
        }

        _getSimpleAdoptionCell(states) {
            return {
                type: GUI.TableViewV2.CellType.GENERAL,
                content: {
                    title: _("controls.ircv2021.temperature-adjustment"),
                    rightIconSrc: Icon.Steak.TIMER_BUTTON,
                    rightIconColor: Color.STATE_INACTIVE_B,
                    clickable: true,
                    disclosureText: this.control.formatTemp(states.tempTarget)
                },
                action: this._showTempAdoptionScreen.bind(this)
            };
        }

        _getAdoptionCell(states) {
            return {
                type: GUI.TableViewV2.CellType.ValueSelectionCell,
                content: {
                    title: _("controls.ircv2021.temperature-adjustment"),
                    valueSelection: {
                        value: this.currentAdoptionTemp === -1000 ? states.tempTarget : this.currentAdoptionTemp,
                        minValue: states.frostProtectTemperature,
                        maxValue: states.heatProtectTemperature,
                        stepWidth: this.control.getTempStep(),
                        valueFormat: this.control.getFormatForTemp(),
                        valueChangedFn: this._showTempAdoptionScreen.bind(this),
                        finishedDelay: 0
                    },
                    actionButton: {
                        title: _("controls.ircv2021.temperature-adjustment-more"),
                        color: window.Styles.colors.green,
                        actionFn: this._showTempAdoptionScreen.bind(this)
                    }
                }
            };
        }

        _getStopOverrideCell(states) {
            var texts = [states.overrideDescription],
                buttonTitle = _("controls.ircv2021.stop-temperature-adjustment-override"),
                buttonAction = this._stopOverride.bind(this);

            return {
                type: GUI.TableViewV2.CellType.STATUS_BUTTON_CELL,
                content: {
                    texts: texts,
                    buttonTitle: buttonTitle,
                    buttonAction: buttonAction
                }
            };
        }

        // ---------------------------------------------------------------------------------
        // Content Response Methods.
        // ---------------------------------------------------------------------------------
        _showModeSelectorScreen(title, action, stateValue) {
            this.ViewController.showState(OldCtrlEnums.ScreenState.OPERATING_MODES, null, {
                control: this.control,
                titleText: _("controls.ircv2021.mode")
            });
        }

        _stopOverride() {
            this.sendCommand(Commands.I_ROOM_V2_CONTROLLER.OVERRIDE.STOP);
        }

        _showTempAdoptionScreen() {
            this.ViewController.showState(CtrlEnums.ScreenState.TEMP_ADOPTION, null, {
                control: this.control,
                currentManualTemp: this.currentAdoptionTemp
            });
            this.currentAdoptionTemp = -1000;
        }

        _showLinkedAcList(ctrls) {
            if (ctrls.length === 1) {
                this._showLinkedAcControl(ctrls[0]);
                return;
            }
            let options = ctrls.map(ctrl => {
                return {
                    title: ctrl.getName(),
                    action: this._showLinkedAcControl.bind(this, ctrl)
                }
            })
            LxReactContextMenuHandler.shared.showContextMenu(
                options,
                _("controls.ircv2021.connected-accontrol", { count: ctrls.length })
            );
        }

        _showLinkedAcControl(linkedCtrl) {
            NavigationComp.showControlContent(linkedCtrl);
        }

    };
});
