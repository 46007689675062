'use strict';
/*
 activeColor
 colorsArray
 changingAllowed

 favoriteSelected(index, section, row, tableView)
 favoriteChanged(index, section, row, tableView)
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.COLOR_PICKER_FAVORITES = "ColorPickerFavoritesCell";
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        // Keep in sync with colorPickerBrightnessCell
        var MIN_BG_BRIGHTNESS = 10;

        class ColorPickerFavoritesCell extends GUI.TableViewV2.Cells.BaseCell {
            //region Static
            static Template = function () {
                var getTemplate = function getTemplate(content) {
                    var filler = '';

                    if (content.changingAllowed) {
                        filler = '<div class="favorites__filler">' + ImageBox.getResourceImageWithClasses("resources/Images/Controls/ColorPicker/color-picker.svg", "filler__indicator") + '<div class="filler__placeholder">' + '<div class="spinner pie"></div>' + '<div class="filler pie"></div>' + '<div class="mask"></div>' + '</div>' + '</div>';
                    }

                    return '<div class="content__fav-title">' + ImageBox.getResourceImageWithClasses('resources/Images/Controls/ColorPicker/favorite.svg', 'fav-title__icon') + '<div class="fav-title__text">' + _("controls.colorpicker.favorites") + '</div>' + '</div>' + '<div class="content__favorites">' + filler + '<div class="favorites__circle"></div>' + '<div class="favorites__circle"></div>' + '<div class="favorites__circle"></div>' + '<div class="favorites__circle"></div>' + '</div>';
                };

                return {
                    getTemplate: getTemplate
                };
            }(); //endregion Static

            constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
                super(...arguments);
                this.element.addClass("color-picker-favorites-cell");
            }

            viewDidLoad() {
                super.viewDidLoad();
                this.contentPlaceholder.append(ColorPickerFavoritesCell.Template.getTemplate(this.content)); // circles

                var favorites = this.contentPlaceholder.find('.favorites__circle');
                this.favoriteCircles = [];
                this.favoriteButtons = [];

                for (var i = 0; i < favorites.length; i++) {
                    this.favoriteCircles.push($(favorites[i]));
                    var btn = new GUI.LxButton(this, favorites[i], 'transparent');
                    this.addToHandledSubviews(btn);
                    this.favoriteButtons.push(btn);
                } // filler


                if (this.content.changingAllowed) {
                    this.favoriteIndicator = $(this.contentPlaceholder.find('.favorites__filler'));
                }
            }

            viewWillAppear() {
                super.viewWillAppear();

                if (this.content.colorsArray) {
                    this.setColorsArray(this.content.colorsArray);
                }
            }

            viewDidAppear() {
                super.viewDidAppear();

                for (var i = 0; i < this.favoriteButtons.length; i++) {
                    this.favoriteButtons[i].onButtonTapped = this._favoriteButtonTap.bind(this); // only for the color picker

                    if (this.content.changingAllowed) {
                        this.favoriteButtons[i].onButtonHit = this._favoriteButtonHit.bind(this);
                        this.favoriteButtons[i].onButtonReleased = this._favoriteButtonReleased.bind(this);
                    }
                }
            }

            viewWillDisappear() {
                for (var i = 0; i < this.favoriteButtons.length; i++) {
                    this.favoriteButtons[i].onButtonTapped = null; // only for the color picker

                    if (this.content.changingAllowed) {
                        this.favoriteButtons[i].onButtonHit = null;
                        this.favoriteButtons[i].onButtonReleased = null;
                        ;
                    }
                }

                super.viewWillDisappear(...arguments);
            }

            // Public methods
            setActiveColor(color) {
                this.content.activeColor = color;
            }

            setColorsArray(colors) {
                this.content.colorsArray = colors;

                for (var i = 0; i < colors.length; i++) {
                    this.favoriteCircles[i].css('background-color', LxColorUtils.getCSSRGBPropertyFromColorObject(colors[i]));
                    var darkMode = NavigationComp.getDarkModeState();
                    this.favoriteCircles[i].toggleClass("circle--light-bg", !darkMode && colors[i].sat < MIN_BG_BRIGHTNESS);
                    this.favoriteCircles[i].toggleClass("circle--dark-bg", darkMode && colors[i].brightness < MIN_BG_BRIGHTNESS);
                }
            }

            // Private methods
            _favoriteButtonTap(button) {
                for (var i = 0; i < this.favoriteButtons.length; i++) {
                    if (button === this.favoriteButtons[i]) {
                        this.delegate.favoriteSelected.call(this.delegate, i, this.section, this.row, this.tableView);
                        break;
                    }
                }
            }

            //TODO-woessto: from lindosi:  there is a problem on firefox/edge on setting the favorite a second time
            _favoriteButtonHit(button) {
                if (this.content.activeColor && this.content.activeColor.brightness > 0) {
                    // find element
                    var element, i;

                    for (i = 0; i < this.favoriteButtons.length; i++) {
                        if (button === this.favoriteButtons[i]) {
                            element = this.favoriteCircles[i];
                            break;
                        }
                    }

                    var offsetLeft = element[0].offsetLeft + (element.width() - this.favoriteIndicator.width()) / 2;
                    this.favoriteIndicator.css('left', offsetLeft + 'px');
                    var oldColor = LxColorUtils.getCSSRGBPropertyFromColorObject(this.content.colorsArray[i]);
                    var newColor = LxColorUtils.getCSSRGBPropertyFromColorObject(this.content.activeColor);
                    this.favoriteIndicator.children('.filler__placeholder').css('background-color', oldColor);
                    this.favoriteIndicator.find('.spinner').css('background-color', newColor);
                    this.favoriteIndicator.find('.filler').css('background-color', newColor); // only register "one", otherwise callback will fire 3 times

                    var filler = this.favoriteIndicator.children('.filler__placeholder').children('.filler');
                    filler.one('animationend webkitAnimationEnd MSAnimationEnd oAnimationEnd', this._animationCallback.bind(this, button));
                    this.favoriteIndicator.show();
                }
            }

            _favoriteButtonReleased() {
                this.favoriteIndicator.hide();
                var filler = this.favoriteIndicator.children('.filler__placeholder').children('.filler');
                filler.off('animationend webkitAnimationEnd oAnimationEnd');
            }

            _animationCallback(button) {
                // find element
                var element, i;

                for (i = 0; i < this.favoriteButtons.length; i++) {
                    if (button === this.favoriteButtons[i]) {
                        element = this.favoriteCircles[i];
                        break;
                    }
                }

                var newColor;

                if (this.content.activeColor.hasOwnProperty('kelvin')) {
                    newColor = LxColorUtils.getCSSRGBPropertyFromColorObject(this.content.activeColor);
                } else {
                    newColor = LxColorUtils.getCSSRGBPropertyFromColorObject(this.content.activeColor);
                }

                this.favoriteIndicator.children('.filler__placeholder').css('background-color', newColor);
                element.css('background-color', newColor);
                this.content.colorsArray[i] = this.content.activeColor;
                this.delegate.favoriteChanged.call(this.delegate, i, this.section, this.row, this.tableView);
            }

        }

        GUI.TableViewV2.Cells.ColorPickerFavoritesCell = ColorPickerFavoritesCell;
    }
    return GUI;
}(window.GUI || {});
