'use strict';

define(['AutopilotUtility', 'AutopilotEnums'], function (AutopilotUtility, AutopilotEnums) {
    return class AutopilotNotificationScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div class="autopilot-notification-screen" />'));
            this.selectedCallers = {};
            this.originalAction = details.action;
            this.action = details.action ? JSON.parse(JSON.stringify(details.action)) : {};
            this.editMode = typeof details.action !== 'undefined';
            this.notificationType = details.notificationType;
            this.recipientValid = true;

            if (!this.editMode) {
                this.action.type = details.notificationType;
            } else {
                // edit mode -> preselect the given caller
                this.selectedCallers[this.action.id] = true;
            }

            if (this.notificationType === AutopilotEnums.ActionTypes.Caller) {
                this.callerServices = [];

                if (this.editMode) {
                    // we just show the single caller in edit mode. We do not offer a way to add another one
                    var caller = AutopilotUtility.getCallerServices(this.action.id);

                    if (caller) {
                        this.callerServices.push(caller);
                    }
                } else {
                    this.callerServices = AutopilotUtility.getCallerServices();
                } // preselect caller if there is just a single one


                if (!this.editMode && this.callerServices.length === 1) {
                    this.action.id = this.callerServices[0].id;
                    this.selectedCallers[this.callerServices[0].id] = true;
                } else if (this.editMode) {
                    this.selectedCallers[this.action.id] = true;
                }
            }
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments); // Overwrite the default behaviour to prevent the "onLeftButtonTapped" function to be
            // nullified right after executing "this.titleBarAction" which will result in an error if the
            // inputs are not valid and are corrected and validated again

            this._titleBar.onLeftButtonTapped = this.titleBarAction.bind(this);
        }

        getURL() {
            return "Autopilot/Notification";
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarText() {
            return AutopilotEnums.NotificationTypesTitle[this.notificationType];
        }

        titleBarAction() {
            if (this.editMode) {
                this._save();
            } else {
                this.ViewController.navigateBack();
            }
        }

        numberOfSections() {
            var cntSections;

            if (this.notificationType === AutopilotEnums.ActionTypes.Notification) {
                cntSections = 2; // title + text
            } else if (this.notificationType === AutopilotEnums.ActionTypes.Caller) {
                cntSections = 2; // caller + text
            } else if (this.notificationType === AutopilotEnums.ActionTypes.CloudMailer) {
                cntSections = 3; // recipient + subject + text
            }

            if (!this.editMode) {
                cntSections++; // add the 'add button'
            }

            this.sectionCount = cntSections;
            return cntSections;
        }

        titleForHeaderInSection(section) {
            if (this.notificationType === AutopilotEnums.ActionTypes.Notification) {
                if (section === 0) {
                    return _("notification.title");
                } else if (section === 1) {
                    return _("notification.content");
                }
            } else if (this.notificationType === AutopilotEnums.ActionTypes.Caller) {
                if (section === 0) {
                    return _("notifications.types.caller");
                } else if (section === 1) {
                    return _("notification.content");
                }
            } else if (this.notificationType === AutopilotEnums.ActionTypes.CloudMailer) {
                if (section === 0) {
                    return _("notification.recipient");
                } else if (section === 1) {
                    return _("notification.subject");
                } else if (section === 2) {
                    return _("notification.content");
                }
            }

            return "";
        }

        numberOfRowsInSection(section) {
            if (this.notificationType === AutopilotEnums.ActionTypes.Caller) {
                if (section === 0) {
                    return this.callerServices.length;
                }
            }

            return 1;
        }

        cellTypeForCellAtIndex(section, row) {
            if (section === this.sectionCount - 1 && !this.editMode) {
                return GUI.TableViewV2.CellType.BUTTON_CELL_CUSTOM;
            }

            if (this.notificationType === AutopilotEnums.ActionTypes.Caller) {
                if (section === 0 && !this.editMode && this.callerServices.length > 1) {
                    return GUI.TableViewV2.CellType.CHECKABLE;
                } else if (section === 0) {
                    return GUI.TableViewV2.CellType.GENERAL;
                }
            }

            return GUI.TableViewV2.CellType.INPUT;
        }

        contentForCell(cell, section, row) {
            var content = {};

            if (section === this.sectionCount - 1 && !this.editMode) {
                return {
                    title: _("autopilot.rule.action.add"),
                    color: window.Styles.colors.green,
                    clickable: true
                };
            }

            if (this.notificationType === AutopilotEnums.ActionTypes.Notification) {
                if (section === 0) {
                    content.value = this.action.subject || "";
                    content.placeholder = _("notification.title");
                    content.type = "text";
                } else if (section === 1) {
                    content.value = this.action.text || "";
                    content.multiline = true;
                    content.placeholder = _("notification.content");
                    content.type = "text";
                }
            } else if (this.notificationType === AutopilotEnums.ActionTypes.Caller) {
                if (section === 0) {
                    var caller = this.callerServices[row]; // list callers

                    content.title = caller.name;
                    content.subtitle = caller.phoneNumber;
                    content.selected = this.selectedCallers[caller.id] || false;
                    content.radioMode = GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE;

                    if (this.editMode) {
                        content.leftIconSrc = Icon.Autopilot.CALLER;
                    }
                } else {
                    content.value = this.action.text || "";
                    content.multiline = true;
                    content.placeholder = _("notification.content");
                    content.type = "text";
                }
            } else if (this.notificationType === AutopilotEnums.ActionTypes.CloudMailer) {
                if (section === 0) {
                    content.value = this.action.recipient || "";
                    content.placeholder = _("notification.recipient");
                    content.type = "text";
                    content.validationRegex = Regex.EMAIL;
                } else if (section === 1) {
                    content.value = this.action.subject || "";
                    content.placeholder = _("notification.subject");
                    content.type = "text";
                } else if (section === 2) {
                    content.value = this.action.text || "";
                    content.multiline = true;
                    content.placeholder = _("notification.content");
                    content.type = "text";
                }
            }

            return content;
        }

        didCheckCell(cell, sectionIdx, row, tableView, selected) {
            var callerId = this.callerServices[row].id;
            this.selectedCallers[callerId] = selected;
        }

        didSelectCell(cell, sectionIdx, row) {
            this._save();
        }

        textChanged(section, row, tableView, text, valid, valueDidChange) {
            if (this.notificationType === AutopilotEnums.ActionTypes.Notification) {
                if (section === 0) {
                    this.action.subject = text;
                } else if (section === 1) {
                    this.action.text = text;
                }
            } else if (this.notificationType === AutopilotEnums.ActionTypes.Caller) {
                this.action.text = text;
            } else if (this.notificationType === AutopilotEnums.ActionTypes.CloudMailer) {
                if (section === 0) {
                    this.action.recipient = text;
                    this.recipientValid = valid;
                } else if (section === 1) {
                    this.action.subject = text;
                } else if (section === 2) {
                    this.action.text = text;
                }
            }
        }

        _validateInputs() {
            if (this.notificationType === AutopilotEnums.ActionTypes.Notification) {
                if (!this.action.subject || this.action.subject.length === 0) {
                    return _("autopilot.notification.no-title");
                }

                if (!this.action.text || this.action.text.length === 0) {
                    return _("autopilot.notification.no-text");
                }
            } else if (this.notificationType === AutopilotEnums.ActionTypes.Caller) {
                var callerSelected = false;

                for (var prop in this.selectedCallers) {
                    if (!this.selectedCallers.hasOwnProperty(prop)) {
                        continue;
                    }

                    if (this.selectedCallers[prop] === true) {
                        callerSelected = true;
                        break;
                    }
                }

                if (!callerSelected) {
                    return _("autopilot.notification.no-caller");
                }

                if (!this.action.text || this.action.text.length === 0) {
                    return _("autopilot.notification.no-text");
                }
            } else if (this.notificationType === AutopilotEnums.ActionTypes.CloudMailer) {
                if (!this.action.text || this.action.text.length === 0) {
                    return _("autopilot.notification.no-text");
                } else if (!this.action.recipient || this.action.recipient.length === 0 || !this.recipientValid) {
                    return _("autopilot.notification.no-recipient");
                } else if (!this.action.subject || this.action.subject.length === 0) {
                    return _("autopilot.notification.no-subject");
                }
            }

            return true;
        }

        _save() {
            var validationResult = this._validateInputs();

            if (validationResult === true) {
                if (this.editMode) {
                    AutopilotUtility.copyValues(this.action, this.originalAction);
                    NavigationComp.dispatchEventToUI(AutopilotEnums.CommunicationEvents.UPDATE_ACTION, this.originalAction);
                } else {
                    var actions = [];

                    if (this.notificationType === AutopilotEnums.ActionTypes.Caller) {
                        for (var prop in this.selectedCallers) {
                            if (!this.selectedCallers.hasOwnProperty(prop)) {
                                continue;
                            }

                            var caller = AutopilotUtility.getCallerServices(prop);

                            if (this.selectedCallers[prop] === true && caller) {
                                // if we have a caller, we use the this.action object as template
                                var action = {
                                    id: prop,
                                    type: this.action.type,
                                    text: this.action.text
                                };
                                actions.push(action);
                            }
                        }
                    } else {
                        actions.push(this.action);
                    }

                    actions.forEach(function (action) {
                        NavigationComp.dispatchEventToUI(AutopilotEnums.CommunicationEvents.ADD_ACTION, action);
                    }.bind(this));
                    NavigationComp.disableAnimationsTemp();
                }

                this.ViewController.navigateBackTo(AutopilotEnums.ScreenStates.AutopilotRuleScreen);
            } else {
                AutopilotUtility.showValidationError(validationResult);
            }
        }

    };
});
