'use strict';

define(['TargetValueSelectorScreenBase', 'SaunaControlEnums'], function (TargetValueSelectorScreenBase, SaunaControlEnums) {
    return class TargetHumiditySelectorScreen extends TargetValueSelectorScreenBase {
        constructor(control) {
            super(...arguments);
        }

        /**
         * These views always have a custom title.
         */
        getTitle() {
            return _("controls.sauna.desired.humidity");
        }

        /**
         * Such views only need to have one state ID.
         */
        getStateID() {
            return "humidTargetVal";
        }

        receivedStates(states) {
            super.receivedStates(...arguments);
            this.humidTargetVal = states.humidTargetVal;
        }

        viewWillAppear() {
            return Q(super.viewWillAppear(...arguments).then(res => {
                this.humidTargetVal && this.sliderActionView.setPosition(this.humidTargetVal);
            }));
        }

        /**
         * To send the value to the Miniserver.
         * @param value
         */
        formatCommand(value) {
            return Commands.format(Commands.SAUNA.HUMIDITY, value);
        }

        /**
         * To display the current value.
         */
        formatValue(value) {
            return lxFormat("%d%", value);
        }

        /**
         * This function returns the config of the slider, it is crucial to implement it!
         * @returns {{min, max, step, antiGhostTimer: boolean}}
         */
        getSliderConfig() {
            var cfg = super.getSliderConfig(...arguments);
            cfg.min = this.currentMode && this.currentMode.minHumidity && SaunaControlEnums.SaunaModes.SAUNA_MANUAL.minHumidity;
            cfg.max = this.currentMode && this.currentMode.maxHumidity && SaunaControlEnums.SaunaModes.SAUNA_MANUAL.maxHumidity;
            cfg.step = 1;
            return cfg;
        }

        /**
         * Returns an array of mode ids that are okay for this view. If the current mode is not contained at the
         * time when a value is sent, the mode will be changed to manual.
         */
        getAcceptibleModes() {
            var modes = super.getAcceptibleModes(...arguments);
            modes.push(SaunaControlEnums.SaunaModes.HUMIDITY_MANUAL.nr); // humidity manual is also okay!

            return modes;
        }

    };
});
