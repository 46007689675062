import {useContext, useMemo} from "react";
import {LxReactText, ControlContext, AmbientContext} from "LxComponents";
import globalStyles from "GlobalStyles";
import {View} from "react-native-web";

function LxTimeDisplay (props) {
    const {isAmbientMode} = useContext(AmbientContext)
    const {states} = useContext(ControlContext);

    const timeData = useMemo(() => {
        let defaultData = {
            seconds: 0,
            minutes: 0,
            hours: 0,
            days: 0
        }
        return states.total || defaultData;
    }, [states.total]);

    const TimeInfo = ({type, value = 0}) => {
        let identifier = getDurationIdentifier(type, value);
        let valueColor = states.active ? globalStyles.colors.stateActive : globalStyles.colors.text.primary;
        return <View style={styles.timeInfoContainer}>
            <LxReactText style={[globalStyles.textStyles.title2.default, {color: valueColor}]}>{value}</LxReactText>
            <LxReactText style={styles.labelText}>{identifier}</LxReactText>
        </View>
    }

    return <View style={isAmbientMode ? styles.transparentContainer : baseStyles.container}>
        <View style={styles.timeInfosContainer}>
            <TimeInfo type={TimeType.DAYS} value={timeData.days}/>
            <TimeInfo type={TimeType.HOURS} value={timeData.hours}/>
            <TimeInfo type={TimeType.MINUTES} value={timeData.minutes}/>
            <TimeInfo type={TimeType.SECONDS}  value={timeData.seconds}/>
        </View>
        <View style={styles.totalTextContainer}>
            <LxReactText style={styles.totalText}>{_('controls.hourcounter.total-operating-time')}</LxReactText>
        </View>
    </View>
}

const TimeType = {
    SECONDS: 0,
    MINUTES: 1,
    HOURS: 2,
    DAYS: 3
}

const getDurationIdentifier = (type, value = 0) => {
    let identifiers = LxDate.durationIdentifiers();
    switch (type) {
        case TimeType.SECONDS:
            return identifiers.seconds;
        case TimeType.MINUTES:
            return identifiers.minutes;
        case TimeType.HOURS:
            return identifiers.hours;
        case TimeType.DAYS:
            return _('dateTime.day', {
                count: parseInt(value)
            })
        default:
            return identifiers.seconds
    }
}

const baseStyles = {
    container: {
        width: "100%",
        //backgroundColor: globalStyles.colors.grey["600"], as designed
        padding: globalStyles.spacings.gaps.regular,
        marginVertical: globalStyles.spacings.gaps.small,
        borderRadius: 2
    },
}

const styles = {
    transparentContainer: {
      ...baseStyles.container,
      backgroundColor: globalStyles.colors.transparent
    },
    timeInfosContainer: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-evenly",
        padding: globalStyles.spacings.gaps.small
    },
    timeInfoContainer: {
        justifyContent: "center",
        alignItems: "center"
    },
    totalTextContainer: {
        width: "100%",
        marginTop: globalStyles.spacings.gaps.medium,
        justifyContent: "center",
        alignItems: "center"
    },
    totalText: {
        ...globalStyles.textStyles.body.bold,
        color: globalStyles.colors.text.primary
    },
    labelText: {
        ...globalStyles.textStyles.footNote.default,
        color: globalStyles.colors.text.secondary,
        textTransform: "uppercase"
    }
}

export default LxTimeDisplay;
