import {
    LxReactImageView,
    LxReactOnlineView
} from "LxComponents";
import {useMemo} from "react"
import {Image} from "react-native"
import globalStyles from "GlobalStyles"
import Icons from "IconLib";


export default function LxReactDeviceSearchIcon({icon, isTotal=false, hasOnlineState=false, isOnline=false, smallIcon = false}) {

    const imageComp = useMemo(() => {
        let imageComp;
        if (typeof icon === "string") {
            if (icon.startsWith("http") && !icon.endsWith(".svg")) {
                imageComp = {
                    comp: Image,
                    props: {
                        key: "icon",
                        source: {uri: icon},
                        style: [Style.iconContainer, Style.icon]
                    }
                }
            } else {
                imageComp = {
                    comp: LxReactImageView,
                    props: {
                        key: "icon",
                        source: icon,
                        containerStyle: {...Style.iconContainer, ...(smallIcon ? {height: globalStyles.sizes.icons.small, width: globalStyles.sizes.icons.small} : {})},
                        imageStyle: {...Style.icon, ...(smallIcon ? {height: globalStyles.sizes.icons.small, width: globalStyles.sizes.icons.small} : {})}
                    }
                }
            }
        } else {
            imageComp = {
                comp: isTotal ? Icons.Sum : (icon || Icon.Default),
                props: {
                    style: {...Style.icon, ...Style.reactIcon, ...(isTotal ? Style.totalIcon : {} ) }
                }
            }
        }
        return imageComp;
    }, [isTotal, icon])

    return <LxReactOnlineView
            style={Style.onlineView}
            key={"device-search-online-icon"}
            imageComp={imageComp}
                {...(hasOnlineState ? {isOnline: isOnline} : {})}
        />
}


const Style = {
    onlineView: { justifySelf: "center", alignContent: "center", backgroundColor: "green" },
    iconContainer: {
        fill: globalStyles.colors.text.primary,
        height: globalStyles.sizes.icons.bigger,
        width: globalStyles.sizes.icons.bigger,
        alignSelf: "center",
        justifySelf: "center",
        justifyContent: "center",
        alignContent: "center",
    },
    icon: {
        height: globalStyles.sizes.icons.bigger,
        width: globalStyles.sizes.icons.bigger,
        fill: globalStyles.colors.text.primary,
    },
    totalIcon: {
        height: globalStyles.sizes.icons.small,
        width: globalStyles.sizes.icons.small,
        justifySelf: "center",
        alignSelf: "center",
        justifyContent: "center",
        alignContent: "center"
    },
    reactIcon: {
        height: globalStyles.sizes.icons.small,
        width: globalStyles.sizes.icons.small,
        alignSelf: "center"
    },
    iconBox: {
        background: globalStyles.colors.grey["600"],
        width: 48,
        minHeight: 48,
        height: "100%",
        justifyContent: "center",
        alignContent: "center"
    }
}
