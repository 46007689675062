import * as React from "react"
import Svg, { Path } from "react-native-svg"

function ChevronBack(props) {
    return (
        <Svg isreact="true"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12 30"
            {...props}
        >
            <Path d="M9.77 6.65a.997.997 0 00-1.38 0L.32 14.27a.992.992 0 000 1.45l8.07 7.62c.39.37.99.37 1.38 0 .42-.4.42-1.07 0-1.46L2.48 15l7.29-6.88c.42-.4.42-1.07 0-1.47z" />
        </Svg>
    )
}

export default ChevronBack
