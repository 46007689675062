import {LxReactWaitingView} from "LxComponents";
import {useCallback} from "react";

const AIR_PAIRING_HELP = 'https://www.loxone.com/help/air-interface';
export default function LxReactDeviceSearchOngoingSearchView({searchType, style}) {

    const showAirPairingHelp = useCallback(() => {
        NavigationComp.openWebsite(AIR_PAIRING_HELP);
    }, []);

    let message = _("device-learning.searching.message"),
        buttonText, buttonAction;
    if (searchType === DeviceManagement.TYPE.TREE) {
        message = _("tree.device-learning.no-results.message");
    } else if (searchType === DeviceManagement.TYPE.AIR) {
        message = _("air.device-learning.no-results.message");
        buttonText = _("air.device-learning.no-results.pairing-help");
        buttonAction = showAirPairingHelp
    }
    return <LxReactWaitingView
                            style={style}
                            title={_("device-learning.searching.title")}
                            message={message}
                            buttonText={buttonText}
                            action={buttonAction}
        />
}