import React, { useEffect, useState, useContext, useMemo } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import {
    navigatorConfig,
    LxReactTableView,
    ControlContext,
} from 'LxComponents';
import globalStyles from 'GlobalStyles';
import TargetTemp from './TargetTemp';
import { sendValue, getQuickSelect } from '../utils';
import { useRequest } from 'ahooks';
import TempPicker from './TempPicker';

export const createSettingsCell = (title, rightContent) => {
    return {
        title: title,
        titleStyle: StyleSheet.flatten(styles.cellTitleStyle),
        cellElementStyle: styles.cellElementStyle,
        mainRightContent: {
            comp: rightContent.comp,
            props: {
                ...rightContent.props,
                containerStyle: styles.rightContentContainerStyle,
                titleStyle: styles.rightContentTitleStyle,
                tintColor: globalStyles.colors.text.secondary,
                truncateOptionLabel: 30,
            },
        },
        mainCenterStyle: styles.cellMainCenterStyle,
        mainRightStyle: styles.cellMainRightStyle,
    };
};

export default function SettingsScreen({ navigation, route }) {
    const { control, states } = useContext(ControlContext);
    const [showTempPicker, setShowTempPicker] = useState(false);
    const zeroModeSettings = {
        value: 0,
        title: _('controls.ac-control.settings.retain-last-value'),
    };
    const useFahrenheit =
        SandboxComponent.getTemperatureUnit() ===
        TempAppendix(TempUnit.FAHRENHEIT);
    const {
        data: settingsObj = Feature.AC_CONTROL_SETTINGS_AS_STATE ? states.settings : {
            SilentMode: -255,
            DefaultAirflow: -255,
            DefaultFanspeed: -255,
            DefaultTargetTemp: -255,
            AutomaticModeFanspeed: -255
        },
        refresh,
        mutate,
    } = useRequest(() =>
        CommunicationComponent.sendViaHTTP(
            Commands.format(
                'jdev/sps/io/%s/%s',
                control.uuidAction,
                Commands.AC.GET_DEFAULT_SETTINGS,
            ),
            EncryptionType.REQUEST_RESPONSE_VAL,
            null,
            null,
            null,
            { noLLResponse: true },
        ), {
            manual: Feature.AC_CONTROL_SETTINGS_AS_STATE
        }
    );

    useEffect(() => {
        if (Feature.AC_CONTROL_SETTINGS_AS_STATE) {
            mutate(oldSettings => ({...oldSettings, ...states.settings}));
        }
    }, [states.settings])

    useEffect(() => {
        navigation.setOptions({
            ...navigatorConfig({
                title: _('settings'),
                animationType: AnimationType.PUSH_OVERLAP_LEFT,
            }),
        });
    }, []);

    const displayTempPicker = () => {
        setShowTempPicker(true);
    };

    const handleTempPickerSelect = (selected) => {
        let temperature;
        if (selected[0].idx === 0) {
            temperature = -1;
        } else {
            temperature = Math.abs(selected[0].idx);
        }
        sendValue(control, Commands.AC.SET_DEFAULT_TARGET, temperature);
        if(!Feature.AC_CONTROL_SETTINGS_AS_STATE) {
            refresh();
        }
        setShowTempPicker(false);
    };

    const sendWithRefresh = (command, value) => {
        sendValue(control, command, value);
        if(!Feature.AC_CONTROL_SETTINGS_AS_STATE) {
            refresh();
        }
    };

    const tableContent = useMemo(() => {
        let content = [
            {
                headerStrongTitle: _('controls.ac-control.settings.defaults'),
                headerDescription: _(
                    'controls.ac-control.settings.defaults-description',
                ),
                rows: [],
            },
            {
                headerStrongTitle: _(
                    'controls.ac-control.settings.silent-mode',
                ),
                headerDescription: _('controls.ac-control.settings.silent-mode-description') + " " +
                    (
                        !control.isSetByLogic("silentMode") ?
                            _("controls.ac-control.settings.silent-mode-description-not-configured") :
                            ""
                    ),
                rows: [],
            },
        ];

        content[0].rows.push({
            title: _('controls.ac-control.target-temperature'),
            titleStyle: StyleSheet.flatten(styles.cellTitleStyle),
            mainCenterStyle: styles.cellMainCenterStyle,
            mainRightStyle: styles.cellMainRightStyle,
            cellElementStyle: styles.cellElementStyle,
            mainRightContentStyle: styles.rightContentContainerStyle,
            mainRightContent: {
                comp: TargetTemp,
                props: {
                    targetTemperature: settingsObj.DefaultTargetTemp,
                    displayTempPicker: displayTempPicker,
                    isSetByLogic: false,
                    additionalStyle: {
                        fontSize: globalStyles.fontSettings.sizes.smallReg,
                        lineHeight:
                            globalStyles.fontSettings.lineHeights.smallReg,
                        fontFamily: globalStyles.fontSettings.families.semiBold,
                    },
                },
            },
        });

        if (states.fanSpeeds?.length > 0) {
            content[0].rows.push(
                createSettingsCell(
                    _('controls.ac-control.fan-speed'),
                    getQuickSelect(
                        states.fanSpeeds,
                        (value) =>
                            sendWithRefresh(Commands.AC.SET_DEFAULT_FAN, value),
                        'custom',
                        false,
                        control,
                        { custom: settingsObj.DefaultFanspeed },
                        zeroModeSettings,
                    ),
                ),
            );
        }

        if (states.airflows?.length > 0) {
            content[0].rows.push(
                createSettingsCell(
                    _('controls.ac-control.air-flow-direction'),
                    getQuickSelect(
                        states.airflows,
                        (value) =>
                            sendWithRefresh(
                                Commands.AC.SET_DEFAULT_AIR_DIR,
                                value,
                            ),
                        'custom',
                        false,
                        control,
                        { custom: settingsObj.DefaultAirflow },
                        zeroModeSettings,
                    ),
                ),
            );
        }

        if (control.isSetByLogic("silentMode")) {
            content[1].rows.push(
                createSettingsCell(
                    _('controls.ac-control.fan-speed'),
                    getQuickSelect(
                        states.fanSpeeds,
                        (value) =>
                            sendWithRefresh(Commands.AC.SET_SILENT_MODE, value),
                        'custom',
                        false,
                        control,
                        { custom: settingsObj.SilentMode },
                        { ...zeroModeSettings, title: _('off') },
                    ),
                ),
            );
        }


        if (states.isOperatedExternally) {
            content.splice(0,1);
        }

        return content;
    }, [JSON.stringify(states), JSON.stringify(settingsObj)]);

    return (
        <>
            <ScrollView style={styles.container}>
                <LxReactTableView tableContent={tableContent} />
            </ScrollView>
            <TempPicker
                showTempPicker={showTempPicker}
                useFahrenheit={useFahrenheit}
                handleTempPickerSelect={handleTempPickerSelect}
            />
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        ...globalStyles.customStyles.screenContent,
        flexDirection: 'column',
        width: '100%',
    },

    cellTitleStyle: {
        ...globalStyles.customStyles.subTitle,
        fontSize: globalStyles.fontSettings.sizes.medium,
        numberOfLines: 1
    },

    cellElementStyle: {
        ...globalStyles.customStyles.cellElement,
        justifyContent: 'space-between'
    },

    rightContentContainerStyle: {
        flexShrink: 1,
        alignItems: 'flex-end',
    },

    rightContentTitleStyle: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.stateActive,
        paddingRight: 0,
    },

    cellMainCenterStyle: {
        flexGrow: 0,
        flexDirection: 'column',
        marginRight: 'auto'
    },

    cellMainRightStyle: {
        flex: 1,
        flexBasis: 75,
        marginLeft: globalStyles.sizes.small,
        justifyContent: 'flex-end',
        flexDirection: 'row',
    },
});
