import {LxBaseControlContent, LxReactVerticalSliderButtonView, ControlContext} from "LxComponents";
import {useContext, useMemo} from "react";

function SliderControlContent (props) {

    const {control, states} = useContext(ControlContext)

    const sliderConfig = useMemo(() => {
        return {
            max: control.details.max,
            min: control.details.min,
            step: control.details.step,
            antiGhostTimer: true,
            hasCustomRate: true,
            updateRate: 0.25
        }
    }, [control.details])

    return <LxBaseControlContent showStateIcon={false}>
        <LxReactVerticalSliderButtonView config={sliderConfig} value={states.pos} onValueChanged={sendValue.bind(this, control)}/>
    </LxBaseControlContent>
}

const sendValue = (control, pos) => {
    control.sendCommand(Commands.format(Commands.SLIDER.VALUE, pos), Commands.Type.OVERRIDE);
}

export default SliderControlContent