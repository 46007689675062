import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Bell(props) {
    return (
        <Svg isreact="true"
            height={24}
            viewBox="0 0 24 24"
            width={24}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M12 0a3.496 3.496 0 013.376 2.595l.018.078.255.11a8.995 8.995 0 015.347 7.934L21 11v5.5c0 1.382 1.118 2.5 2.5 2.5l.105.009c.527.093.527.889 0 .982L23.5 20h-8.036a3.501 3.501 0 01-6.928.001L.5 20l-.105-.009C-.165 19.892-.13 19 .5 19l.164-.005A2.5 2.5 0 003 16.5V11a8.99 8.99 0 015.347-8.217l.254-.11.02-.078a3.501 3.501 0 013.188-2.59zm2.45 20h-4.9a2.501 2.501 0 004.9 0zM12 1a2.5 2.5 0 00-2.469 2.117.5.5 0 01-.32.393A7.993 7.993 0 004 11v5.5c0 .966-.392 1.841-1.025 2.475L2.947 19H21.05l-.025-.025a3.488 3.488 0 01-1.02-2.283L20 16.5V11a7.996 7.996 0 00-5.215-7.49.5.5 0 01-.32-.393A2.496 2.496 0 0012 1z"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default Bell
