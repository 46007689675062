import {LxReactSelectorScreen, LxReactFlexibleCell} from "LxComponents";
import {useCallback, useEffect, useMemo, useState} from "react";
import EcoScreenUtils from "./EcoScreenUtils";

function EcoScreenPresenceControlSelection(props) {
    const [selectedControlUuids, setSelectedControlUuids] = useState([]);

    useEffect(() => {
        let currentPresenceControls = PersistenceComponent.getEcoScreenSettings().presenceControls;
        if (currentPresenceControls.length > 1) {
            currentPresenceControls = [currentPresenceControls[0]];
        }
        const currentPresenceControlsUuids = currentPresenceControls.map(ctrl => ctrl.uuidAction);
        setSelectedControlUuids(currentPresenceControlsUuids)

        props.navigation.setOptions({
            title: _("ecoscreen.settings.presence.monitor")
        })
    }, [])

    const options = useMemo(() => {
        return EcoScreenUtils.getPresenceRoomsOptions();
    }, [])

    const onOptionSelected = useCallback((controlUuid, selected) => {
        let newSelectedControlUuids = [controlUuid];

        const parsedControls = newSelectedControlUuids.map(controlUuid => {
            const control = ActiveMSComponent.getControlByUUID(controlUuid);
            return {
                uuidAction: controlUuid,
                controlType: control.controlType
            }
        })

        const updateSet = {
            presenceControls: parsedControls
        }
        PersistenceComponent.updateEcoScreenSetting(updateSet);
        setSelectedControlUuids(newSelectedControlUuids)
    }, [selectedControlUuids])

    const meetsFilter = (text, filterText) => {
        return text && text.toLowerCase().includes(filterText.toLowerCase());
    }

    const getFilteredContent = useCallback((filterText) => {
        const filteredControlOptions = options.filter(ctrlOption => meetsFilter(ctrlOption.title, filterText) || meetsFilter(ctrlOption.subTitle, filterText))
        const rows = filteredControlOptions.map(option => {
            return {
                controlUuid: option.id,
                id: option.id,
                title: option.title,
                subTitle: option.subTitle,
                checked: selectedControlUuids.includes(option.id),
                radioMode: LxReactFlexibleCell.RadioMode.Table
            }
        });
        return [{rows}]
    }, [options, selectedControlUuids, onOptionSelected])

    return <LxReactSelectorScreen title={_("ecoscreen.settings.presence.monitor")}
                                  autoClose={true}
                                  dispatchSelectionChange={true}
                                  options={options}
                                  animationType={AnimationType.FADE}
                                  selectedId={selectedControlUuids}
                                  onSelected={onOptionSelected}
                                  getCustomFilteredContent={getFilteredContent}
    />
}

export default EcoScreenPresenceControlSelection;

