'use strict';

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.TIME_COUNTER_CELL = "TimeCounterCell";

    class TimeCounterCell extends GUI.TableViewV2.Cells.GeneralCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        viewWillAppear() {
            var baseCall = super.viewWillAppear(...arguments),
                msTime,
                activeSinceTs,
                offset = this.content.activeSince;
            activeSinceTs = new LxDate(offset, true);
            SandboxComponent.getMiniserverTimeInfo(this, function (currentMsTime) {
                msTime = currentMsTime;
            }.bind(this), TimeValueFormat.MINISERVER_DATE_TIME);

            this._startInterval(msTime, activeSinceTs);

            return baseCall;
        }

        viewWillDisappear() {
            clearInterval(this.counterInterval);
            return super.viewWillDisappear(...arguments);
        }

        _startInterval(msTime, activeSinceTs) {
            var diff = msTime.diff(activeSinceTs, 'seconds');
            this.setDisclosureText("" + LxDate.formatSeconds(diff));
            this.counterInterval = setInterval(function () {
                diff++;
                this.setDisclosureText("" + LxDate.formatSeconds(diff));
            }.bind(this), 1000);
        }

    }

    GUI.TableViewV2.Cells.TimeCounterCell = TimeCounterCell;
    return GUI;
}(window.GUI || {});
