import {AppPairingContext, DeviceCreateContext} from "./AppPairingNavigator";
import {useCallback, useContext, useMemo, useState} from "react";
import {
    ButtonType,
    LxReactButton, LxReactFlexibleCell,
    LxReactHeader,
    LxReactTableView,
    LxReactWaitingView,
    navigatorConfig,
    LxReactQuickSelect,
    useBackNavigation, LxReactText
} from "../Components";
import PairedAppEnums from "../../PairedAppComp/pairedAppEnums";
import globalStyles from "GlobalStyles";
import Icons from "IconLib";
import useBeforeNavBack from "./useBeforeNavBack";
import LxBackgroundComp from "../LxBackgroundComp";
import {View} from "react-native";
import {useNavigation} from "@react-navigation/native";
import MsLogin from "./MsLogin";
import UserGroupSelectionScreen from "./UserGroupSelectionScreen";

const getMsName = (ms) => {
    if (ms.msName) {
        return ms.msName;
    } else if (ms.serialNo) {
        return ms.serialNo;
    } else if (ms.remoteUrl) {
        return ms.remoteUrl;
    } else if (ms.localUrl) {
        return ms.localUrl;
    } else if (ms.host) {
        return ms.host;
    } else {
        return _("miniserver");
    }
}

const AdminLoginForm = () => {
    const onSubmit = (user, pass) => {
        PairedAppComponent.authenticateAsAdmin(user, pass);
    }
    const { ms, stateArgs } = useContext(AppPairingContext);

    /**
     * If a user/pass is present from a previous login, reuse it.
     * @returns {{[p: string]: *}}
     */
    const addAuthToMsObj = () => {
        const retMs = {...ms};
        if (nullEmptyString(stateArgs.user)) {
            retMs.activeUser = stateArgs.user;
        }
        if (nullEmptyString(stateArgs.pass)) {
            retMs.password = VendorHub.Crypto.encrypt(stateArgs.pass);
        }
        return retMs;
    }

    const loginState = () => {
        if(stateArgs?.hasOwnProperty("loginState")) {
            return stateArgs.loginState;
        } else {
            return LoginState.WAITING_FOR_CREDS;
        }
    }

    const customError = () => {
        if (stateArgs.notAnAdmin) {
            return _("managed-tablet.admin-rights-required");
        } else {
            return false;
        }
    }

    return <MsLogin miniserver={addAuthToMsObj()}
                    onSubmit={onSubmit}
                    message={_("managed-tablet.enter-creds-message", {miniserver: getMsName(ms)})}
                    loginState={loginState()}
                    customErrorMessage={customError()}
                    headerIcon={Icons.Tablet}/>
}


const WaitingForReachability = () => {
    const navigation = useNavigation();
    const { ms } = useContext(AppPairingContext);


    return <LxReactWaitingView title={_("miniserver.waiting.reachability.known", {miniserverName: getMsName(ms)})}
                               buttonText={_("cancel")}
                               action={() => {navigation.goBack();}} />
}

const Authenticating = () => {
    const navigation = useNavigation();

    const { ms, stateArgs } = useContext(AppPairingContext);
    return <LxReactWaitingView title={_("miniserver.waiting.establishing.known", {miniserverName: getMsName(ms)})}
                               buttonText={_("cancel")}
                               action={() => {navigation.goBack();}} />
}

const PairingFailed =() => {
    const { stateArgs, ms } = useContext(AppPairingContext);
    const navigation = useNavigation();

    const FailedContent = () => {
        const RenderMessage = ({text}) => {
            return <LxReactText style={pairingFailedStyles.message}>{text}</LxReactText>
        }
        const isQR = stateArgs.infoSrc === PairedAppEnums.SetupSource.QR;
        const pairingFileType = isQR ? _("managed-tablet.qr-code"): _("managed-tablet.pairing-file");
        if (stateArgs?.status === PairedAppEnums.PairingFileError.ALREADY_USED) {
            const parts = [];
            parts.push(<RenderMessage text={_("managed-tablet.pairing-info-reused", {info: pairingFileType})}/>);
            parts.push(<RenderMessage text={_("managed-tablet.alternative-manually-pair")}/>);
            return parts;
        } else if (stateArgs?.status === PairedAppEnums.PairingFileError.INVALID) {
            const parts = [];

            parts.push(<RenderMessage text={_("managed-tablet.pairing-info-rejected", {info: pairingFileType})}/>);
            parts.push(<RenderMessage text={_("managed-tablet.alternative-manually-pair")}/>);
            return parts;
        } else {
            return <RenderMessage text={JSON.stringify(stateArgs)} />
        }
    }

    const GoBackButton = () => {
        return <LxReactButton
            text={_("back")}
            pkey={"goback"}
            onPress={() => {
                navigation.goBack();
            }}
            buttonType={ButtonType.SECONDARY}
            containerStyle={pairingFailedStyles.button}
        />
    }

    return <View style={pairingFailedStyles.wrapper}>
        <LxReactHeader
            icon={Icons.Error}
            title={_("managed-tablet.pairing-failed")}
            style={pairingFailedStyles.hdr}
            iconStyle={pairingFailedStyles.hdrIcon}
            />
        <FailedContent />
        <GoBackButton />
    </View>
}

const pairingFailedStyles = {
    wrapper: {
        justifyContent: "center",
        alignItems: "center",
        flex: 1
    },
    hdr: {
        flex: 0,
        minHeight: 250,
        marginTop: globalStyles.spacings.gaps.small,
        marginBottom: globalStyles.spacings.gaps.small
    },
    hdrIcon: {
        fill: globalStyles.colors.text.primary
    },
    message: {
        ...globalStyles.textStyles.subheadline.regular,
        color: globalStyles.colors.text.secondary,
        textAlign: "center",
        margin: globalStyles.spacings.gaps.small
    },
    button: {
        marginHorizontal: "auto"
    }
}

const PairingStopped = () => {
    const { stateArgs, ms } = useContext(AppPairingContext);
    const navigation = useNavigation();
    const getTexts = () => {
        let title, subTitle;
        switch (stateArgs?.stopReason) {
            case PairedAppEnums.PairingStoppedReason.OUTDATED_FW:
                title = _("unsupported-firmware.title");
                subTitle = _("unsupported-firmware.message", {
                    currVersion: stateArgs?.stopPayload,
                    rqVersion: PairedAppEnums.FwVersion,
                    miniserver: getMsName(ms)
                })
                break;
            default:
                title = _("managed-tablet.pairing-failed");
                break;
        }
        return {
            title, subTitle
        }
    };
    const {title, subTitle} = getTexts();
    return <LxBackgroundComp title={title}
                             subTitle={subTitle}
                             message={_("back")}
                             onMessageClickedFn={() => {navigation.goBack();}}
                          icon={Icons.Error}
    />
}

const DeviceSelection = () => {

    const ctxtData = useContext(AppPairingContext);
    const existingList = ctxtData?.stateArgs;
    const [selected, setSelected] = useState(false);

    const hasDevices = () => {
        return Array.isArray(existingList) && existingList.length > 0;
    }

    const tableContent = useMemo(() => {
        if (hasDevices()) {
            const rows = existingList.map((device) => {
                const nameParts = [];
                nameParts.pushObject(nullEmptyString(device.name));
                nameParts.pushObject(nullEmptyString(device.roomObj?.name));

                let mainRightContent = null;
                if (device.isPaired) {
                    mainRightContent = {
                        comp: LxReactText,
                        props: {
                            children: _("managed-tablet.already-paired"),
                            style: {
                                ...globalStyles.textStyles.subheadline.italic,
                                color: globalStyles.colors.text.secondary
                            }
                        }
                    }
                }

                return {
                    title: nameParts.join(SEPARATOR_SYMBOL),
                    mainLeftContent: {
                        comp: Icons.Tablet,
                        props: {
                            width: globalStyles.sizes.icons.small,
                            height: globalStyles.sizes.icons.small,
                            fill: globalStyles.colors.text.primary
                        }
                    },
                    mainRightContent,
                    checked: selected === device.uuid,
                    radioMode: LxReactFlexibleCell.RadioMode.Table,
                    onCheckedChange: (checked) => {
                        checked && setSelected(device.uuid);
                    },
                    onCheckedToggled: () => {
                        return true;
                    }
                }
            })
            return [{rows}];
        } else {
            return [];
        }
    }, [existingList])

    const getSelectedDevice = () => {
        if (Array.isArray(existingList) && existingList.length > 0 && selected) {
            return existingList.find(dev => dev.uuid === selected);
        } else {
            return null;
        }
    }

    const replaceSelected = () => {
        const selDev = getSelectedDevice();
        PairedAppComponent.replaceDevice(selDev);
    }

    const addNewTab = () => {
        PairedAppComponent.startNewDeviceCreation();
    }

    const replButtonText = () => {
        const selDev = getSelectedDevice();
        if (selDev?.isPaired) {
            return _("managed-tablet.replace-paired");
        } else {
            return _("managed-tablet.complete-pairing");
        }
    }

    const onReloadTapped = () => {
        PairedAppComponent.reloadPairedDevices();
    }

    const NoDevicesView = () => {
        return (
            <View style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center"
            }}>
                <LxReactButton onPress={onReloadTapped} pkey={"listrefresh"} text={_("update-str")} />
            </View>
        )
    }


    return <View style={deviceSelStyles.wrapper}>
        <LxReactHeader
            icon={Icons.ArrowLeftRight}
            iconStyle={deviceSelStyles.hdrIcon}
            title={_("managed-tablet.set-up-managed-tab")}
            subtitle={_("managed-tablet.choose-device")}
            style={deviceSelStyles.hdr}
        />
        { hasDevices() ? ( <LxReactTableView
                tableContent={tableContent}
                styles={deviceSelStyles.tableStyles}
                ListEmptyComponent={NoDevicesView}
            />) : <NoDevicesView />
        }
        <LxReactButton
            text={replButtonText()}
            pkey={"repl"}
            onPress={replaceSelected}
            containerStyle={deviceSelStyles.footerButton}
            disabled={!selected}
        />
        <LxReactButton
            text={_("managed-tablet.add-new-tablet")}
            pkey={"addnew"}
            buttonType={ButtonType.SECONDARY}
            containerStyle={deviceSelStyles.footerButton}
            onPress={addNewTab} />
    </View>
}

const deviceSelStyles = {
    wrapper: {
        flexDirection: "column",
        flex: 1
    },
    tableStyles: {
        List: {
            marginTop: globalStyles.spacings.gaps.bigger
        }
    },
    hdr: {
        marginBottom: "auto",
        flex: 0,
        minHeight: 240
    },
    hdrIcon: {
        width: globalStyles.sizes.icons.veryLarge,
        height: globalStyles.sizes.icons.veryLarge,
        fill: globalStyles.colors.stateActive
    },
    footerButton: {
        marginVertical: globalStyles.spacings.gaps.small
    }
}

const DeviceCreate = () => {
    const { deviceProps, updateProps } = useContext(DeviceCreateContext);
    const { stateArgs } = useContext(AppPairingContext);

    const nameRow = () => {
        return {
            type: GUI.TableViewV2.CellType.INPUT,
            content: {
                title: _('device-learning.designation'),
                placeholder: _("managed-tablet.title"),
                value: deviceProps?.name,
                titleContainerStyle: {
                    maxWidth: '50%'
                },
                validationRegex: Regex.DEV_NAME,
                filterRegex: Regex.DEV_NAME_FILTER,
                validation: [
                    {
                        regExp: Regex.DEV_NAME,
                        message: _('invalid-input')
                    }
                ]
            },
            onBlur: (value, validInput) => {
                updateProps({name: validInput ? value : null})
            }
        };
    }

    const roomSelectionRow = () => {
        const selectionOptions = (stateArgs?.rooms ?? []).map(roomObj => {
            return {
                value: roomObj.uuid,
                title: roomObj.name
            }
        })

        return {
            title: _("room"),
            mainRightContent: {
                comp: LxReactQuickSelect,
                props: {
                    selectedValue: deviceProps?.room,
                    options: selectionOptions,
                    noToggle: true,
                    containerStyle: {
                        flex: 1,
                        alignItems: "flex-end"
                    },
                    titleStyle: {
                        ...globalStyles.textStyles.body.default,
                        color: globalStyles.colors.stateActive,
                        paddingRight: 0
                    },
                    tintColor: globalStyles.colors.text.secondary,
                    iconComp: Icons.ChevronsUpDown,
                    iconPosition: LxReactQuickSelect.IconPosition.Right,
                    onOptionSelected: (roomUuid) => {
                        updateProps({room: roomUuid});
                    },
                    truncateOptionLabel: 30
                }
            },
        }
    }

    const navigation = useNavigation();
    const userGroupsSection = () => {
        let rows;
        if (Array.isArray(deviceProps?.userGroups) && deviceProps?.userGroups.length > 0) {
            rows = deviceProps?.userGroups.map(grpUuid => {
                const grp = stateArgs.availableUserGroupMap[grpUuid];
                return {
                    title: grp.description || grp.name,
                    clickable: false,
                    mainLeftContent: {
                        comp: grp.trustMember ? Icons.TrustUserGroup : Icons.UserGroup,
                        props: {
                            width: globalStyles.sizes.icons.small,
                            height: globalStyles.sizes.icons.small,
                            fill: grp.type === UserManagement.USER_GROUP_TYPE.FULL_ACCESS ? globalStyles.colors.orange : globalStyles.colors.stateActive
                        }
                    }
                }
            });
        } else {
            rows = [];
        }
        rows.push({
            title: _('usergroups.select'),
            titleStyle: {
                color: globalStyles.colors.stateActive
            },
            onPress: () => {
                navigation.push(UserGroupSelectionScreen.name, {userGroups: deviceProps?.userGroups});
            }
        })

        return  {
            headerTitle: _('usergroups.headerTitle').toUpperCase(),
            rows
        }
    }

    const securitySection = () => {
        const rows = [],
            user = {}

        if (deviceProps?.visuPassword) {
            user.scoreVisuPWD = deviceProps?.scoreVisuPWD
        }
        rows.push(ActiveMSComponent.getPasswordCell({
            user,
            isVisuPw: true,
            navigation,
            hideScore: !deviceProps?.visuPassword,
            fromPairing: true
        }));

        return {
            headerTitle: _("menu.settings.app.security.title", {
                count: 1
            }).toUpperCase(),
            rows
        }
    }


    const tableContent = () => {

        return [
            {
                rows: [ nameRow(), roomSelectionRow() ]
            },
            userGroupsSection(),
            securitySection()
        ]
    }

    const onPair = () => {
        const pairProps = {...deviceProps};
        delete pairProps.scoreVisuPWD;

        Debug.PairedApp && console.log(DeviceCreate.name, "onPair: " + JSON.stringify(pairProps));
        PairedAppComponent.pairAsNewDevice(pairProps);
    }

    return <View style={deviceSelStyles.wrapper}>
        <LxReactHeader
            icon={Icons.Tablet}
            iconStyle={deviceSelStyles.hdrIcon}
            title={_("managed-tablet.add-new-tablet")}
            subtitle={_("managed-tablet.add-new-tab-desc")}
            style={deviceSelStyles.hdr}
        />
        <LxReactTableView
            tableContent={tableContent()}
            styles={deviceSelStyles.tableStyles}
        />
        <LxReactButton
            text={_("managed-tablet.add-and-pair-tab")}
            pkey={"repl"}
            onPress={onPair}
            containerStyle={deviceSelStyles.footerButton}
            disabled={false}
        />
    </View>


}

export default function PairingConnectScreen({navigation}) {

    navigation.setOptions({
        ...navigatorConfig({
            title: ""
        })
    })

    useBackNavigation(() => {
        navigation.goBack();
    }, [])
    const { pairingState } = useContext(AppPairingContext);

    const stopPairing = useCallback((e) => {
        if (pairingState === PairedAppEnums.PairSetupState.ADMIN_AUTH_VERIFY) {
            PairedAppComponent.returnToAdminAuthentication();
            e.preventDefault();
        } else if (pairingState === PairedAppEnums.PairSetupState.PAIR_AS_NEW) {
            PairedAppComponent.returnToDeviceSelection();
            e.preventDefault();
        } else {
            PairedAppComponent.stopPairing();
        }

    }, [pairingState]);
    useBeforeNavBack(stopPairing);



    const LoginStateView = ({psState}) => {
        let view;
        switch (psState) {
            case PairedAppEnums.PairSetupState.ADMIN_AUTH_REQUIRED:
            case PairedAppEnums.PairSetupState.REACHABILITY_CONFIRMED:
                console.log(PairingConnectScreen.name, "login-state");
                view = <AdminLoginForm />
                break;
            case PairedAppEnums.PairSetupState.REACHABILITY_CHECK:
                console.log(PairingConnectScreen.name, "reach-check");
                view = <WaitingForReachability />;
                break;
            case PairedAppEnums.PairSetupState.ADMIN_AUTH_VERIFY:
                view = <Authenticating />
                break;
            case PairedAppEnums.PairSetupState.STOPPED:
                view = <PairingStopped />;
                break;
            case PairedAppEnums.PairSetupState.LOADING_DEVICES:
                view = <LxReactWaitingView title={_("managed-tablet.loading-tablets")}
                                           buttonText={_("cancel")}
                                           action={() => {navigation.goBack();}}
                />;
                break;
            case PairedAppEnums.PairSetupState.SELECT_DEVICE:
                view = <DeviceSelection />;
                break;
            case PairedAppEnums.PairSetupState.PAIR_AS_NEW:
                view = <DeviceCreate />;
                break;
            case PairedAppEnums.PairSetupState.PAIRING_FAILED:
                view = <PairingFailed />;
                break;
            case PairedAppEnums.PairSetupState.IDLE:
            default:
                console.log(PairingConnectScreen.name, "idle or undefined: " + JSON.stringify(psState), psState);
                view = <LxReactWaitingView title={_("please-wait")} />;
                break;
        }
        return view;
    }

    return <View style={styles.pairingConnectContainer}>
        <LoginStateView psState={pairingState}/>
    </View>;
}

const styles = {
    pairingConnectContainer: {
        maxWidth: globalStyles.sizes.contentMaxWidth,
        width: "100%",
        marginHorizontal: "auto",
        height: "100%"
    },

    hdr: {
        marginBottom: "auto",
        flex: 0,
        minHeight: 240
    },
    hdrIcon: {
        width: globalStyles.sizes.icons.veryLarge,
        height: globalStyles.sizes.icons.veryLarge,
        fill: globalStyles.colors.stateActive
    },
    table: {
        flex: 1,
        marginTop: globalStyles.spacings.gaps.bigger
    }
}
