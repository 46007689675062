'use strict';

define("PoolControlContentCycleSelection", ["PoolControlEnums"], function (PoolControlEnums) {
    return class PoolControlContentCycleSelection extends Controls.ControlContent {
        constructor(details) {
            super(details);
            this.selectedCycleMode = details.entry.cycleMode; // the pools daytimer is passed in here as this.control - so be sure to have the poolControl itself ready too.

            this.poolControl = ActiveMSComponent.getControlByUUID(this.control.uuidParent);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.listSource = [PoolControlEnums.PoolCycleMode.FILTER, PoolControlEnums.PoolCycleMode.FLUSHING, PoolControlEnums.PoolCycleMode.CIRCULATE];
            this.tableView = new GUI.TableViewV2(this, this);
            this.tableView.getElement().addClass("cycle-selection__table-view");
            this.appendSubview(this.tableView);
            this.tableView.reload();
        }

        getURL() {
            return "CycleList";
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarText() {
            return _("pool.control.cycles");
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK,
                style: 'transparent'
            };
        }

        // TableViewDataSource methods
        styleForTableView(tableView) {
            return HD_APP ? TableViewStyle.GROUPED : null;
        }

        numberOfSections() {
            return 1;
        }

        numberOfRowsInSection() {
            return this.listSource.length;
        }

        cellTypeForCellAtIndex() {
            return GUI.TableViewV2.CellType.CHECKABLE;
        }

        contentForCell(cell, section, row) {
            return {
                title: this.poolControl.getNameOfPoolCycleMode(this.listSource[row]),
                selected: this.selectedCycleMode === this.listSource[row],
                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                rightIconSrc: 'resources/Images/Controls/Pool/' + this.poolControl.getIconNameOfPoolCycleMode(this.listSource[row]) + '.svg',
                rightIconColor: this.poolControl.getColorOfPoolCycleMode(this.listSource[row])
            };
        }

        // TableViewDelegate methods
        didCheckCell(cell, section, row, tableView, selected) {
            if (selected) {
                this.selectedCycleMode = this.listSource[row];

                this._navigateBack();
            }
        }

        _navigateBack() {
            this.ViewController.navigateBack(false, {
                cycleMode: this.selectedCycleMode
            });
        }

    };
});
