import * as React from "react"
import Svg, { Path } from "react-native-svg"

function ECar(props) {
    return (
        <Svg isreact="true"
            width={20}
            height={24}
            viewBox="0 0 20 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.84 15l-1.876-4.686A.499.499 0 0015.5 10H11v1.5a1.5 1.5 0 110 3c-1.654 0-3-1.346-3-3V10H4.5a.499.499 0 00-.464.314L2.161 15H17.84zM15 20a.5.5 0 010-1h5v-1.5a.504.504 0 00-.146-.354l-1.145-1.144H1.291L.147 17.146A.5.5 0 000 17.5V19h5a.5.5 0 110 1H0v1.5a.5.5 0 00.5.5H1v1.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V22h10v1.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V22h.5a.5.5 0 00.5-.5V20h-5zM13 3h-1V1a.5.5 0 10-1 0v2H8V1a.5.5 0 10-1 0v2H6a.5.5 0 00-.5.5V4c0 2.033 1.532 3.699 3.5 3.949V11.5c0 1.102.898 2 2 2a.5.5 0 000-1c-.552 0-1-.449-1-1V7.949c1.968-.25 3.5-1.916 3.5-3.949v-.5A.5.5 0 0013 3z"
            />
        </Svg>
    )
}

export default ECar
