import React from 'react'
import { ScrollView } from 'react-native'
import useDraggableScroll from './useDraggableScroll'

const DraggableScrollView = React.forwardRef(function DraggableScrollView(props, ref) {
  const { refs } = useDraggableScroll({
    outerRef: ref,
    cursor: 'grab',
    vertical: !props.horizontal,
  })

  return <ScrollView ref={refs} {...props} style={[{overflowX: props.horizontal ? 'scroll' : undefined, overflowY: !props.horizontal ? 'scroll' : undefined, }, props.style]} />
})

export default DraggableScrollView