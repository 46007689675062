'use strict';
/*
 active
 disabled.
 supportHit          DEFAULT: false  if true, the hit event will be dispatched.
 supportRelease      DEFAULT: false  if true, the release will be dispatched.
 supportDoubleTap    DEFAULT: false  if true, double taps will be recognized.
 tickInterval        DEFAULT: 0  when the cell remains in the hold state, these are the ms after which onCellTick will be dispatched.
 [clickable] - boolean whether or not this cell should respond to taps, default: true
 selectable
 expandable
 expanded
 class
 supportFastInteraction     default: false, if true, the cell can be tapped faster (before ripple ends..)
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType = {
        GATE: "GateControlStateCell",
        JALOUSIE: "JalousieControlStateCell",
        ALARM: "AlarmControlStateCell"
    };
    GUI.TableViewV2.CellType.Special = {
        TRACKER: "TrackerCell"
    };
    GUI.TableViewV2.Cells = {};

    class BaseCell extends GUI.View {
        //region Static
        static Template = function () {
            var getCellTemplate = function getCellTemplate() {
                const transparencySuffix = AMBIENT_MODE ? " base-cell--transparent" : ""
                return $('<div class="section__cell base-cell' + transparencySuffix +  '" >' + '<div class="cell__content"></div>' + '</div>');
            };

            var getDetailTemplate = function getDetailTemplate() {
                return $('<div class="cell__detail"></div>');
            };

            return {
                getCellTemplate: getCellTemplate,
                getDetailTemplate: getDetailTemplate
            };
        }(); //endregion Static

        //region Getter
        get delegate() {
            return this.__delegate;
        }

        get dataSource() {
            return this.__dataSource;
        } //endregion Getter


        //region Setter
        set delegate(delegate) {
            this.__delegate = delegate;
        }

        set dataSource(dataSource) {
            this.__dataSource = dataSource;
        } //endregion Setter


        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(BaseCell.Template.getCellTemplate());
            Object.assign(this, RippleContainer.Mixin);
            this.delegate = delegate;
            this.dataSource = dataSource;
            this.cellType = cellType;
            this.sectionIdx = sectionIdx;
            this.rowIdx = rowIdx;
            this.tableView = tableView;
            this.eventHandlers = [];
        }

        viewDidLoad() {
            var promise = super.viewDidLoad(...arguments);
            this.element.attr("id", "row-" + this.rowIdx);
            this.elements = {};
            var content = this.dataSource.contentForCell.call(this.dataSource, this, this.sectionIdx, this.rowIdx, this.tableView) || {};
            this.setContent(content);

            try {
                this.contentPlaceholder = this.element.find('.cell__content');
            } catch (e) {
                // Just make a dummy element
                this.contentPlaceholder = $("<div>");
            }

            this.clickableContainer = this.contentPlaceholder;
            this.setRippleEnabled(this.rippleAllowed());

            if (this.content.disabled) {
                this.setEnabled(false);
            }

            if (this.content.class) {
                this.element.addClass(this.content.class);
            }

            if (this.content.active) {
                this.setActive(this.content.active);
            }

            if (this.content.hasOwnProperty("heightOverride")) {
                this._setHeightOverride(this.content.heightOverride);
            }

            return promise;
        }

        // ---------------------------------------------------------------------------------------------------------
        //   Methods for super fast tableContent updates
        // ---------------------------------------------------------------------------------------------------------
        // !!! When an instance of tableViewBaseCell implements "updateContent" (public) and calls _updateContent
        // a super fast tableContent update is performed.
        updateAttributesForReuse(delegate, dataSource, sectionIdx, rowIdx, tableView) {
            this.delegate = delegate;
            this.dataSource = dataSource;
            this.sectionIdx = sectionIdx;
            this.rowIdx = rowIdx;
            this.element.attr("id", "row-" + this.rowIdx);
            this.tableView = tableView;
            this.alreadySelected = false; // for safety reasons, reset it to ensure it's selectable again.
        }

        /**
         * Will be called once the cell is being returned to the global cell cache. used to reset the cell to a
         * default content, or remove custom stuff applied by the content it had.
         * @returns {*}
         */
        resetCellContent() {
            var prms;

            if (this.content.class) {
                prms = GUI.animationHandler.removeCssClass(this.element, this.content.class);
            } else {
                prms = Q.resolve();
            }

            return prms;
        }

        /**
         * Will cause an async reset content call.
         * @returns {*} promise that resolves once the reset has passed.
         */
        triggerResetCellContent() {
            var def = Q.defer();
            setTimeout(function () {
                def.resolve(this.resetCellContent());
            }.bind(this), 0);
            this._resetContentPrms = def.promise;
            return this._resetContentPrms;
        }

        isReadyForReuse() {
            var ready = true;

            if (this._viewDidAppearPassed || this._viewWillAppearPassed || this._resetContentPrms && this._resetContentPrms.isPending()) {
                ready = false;
            }

            return ready;
        }

        /**
         * Method is private, if a subclass is ready to support being reused, it must implement a public method
         * "updateContent" which then calls this private method of the baseclass.
         * This allows to refresh the cells contents instead of reloading the cells, which greatly improves the speed
         * of our tableViews.
         * @param newContent
         * @private
         */
        _updateContent(newContent) {
            var applyResp;
            this.content = cloneObjectDeep(newContent); //just like in setContent, here the contnet needs to be cloned too.

            applyResp = this._applyNewContent(this.content);

            if (!Q.isPromiseAlike(applyResp)) {
                applyResp = Q.resolve(applyResp);
                console.warn(this.name, "_applyNewContent did not return a Promise, this may lead to cell flickering!");
            }

            return applyResp;
        }

        /**
         * This Method is used to apply a new content object onto the cell instance. e.g. update texts and so on.
         * @param newContent
         * @private
         * @returns Q.Promise()
         */
        _applyNewContent(newContent) {
            this.setRippleEnabled(this.rippleAllowed());
            this.content.disabled = !!newContent.disabled;
            return GUI.animationHandler.schedule(function () {
                Debug._CYPRESS_TESTS_ACTIVE && this.element.attr("data-title", newContent.title);
                this.element.toggleClass("disabled", this.content.disabled);
                newContent.hasOwnProperty("class") && this.element.addClass(newContent.class);
                this.element.toggleClass("active", !!newContent.active);

                this._setHeightOverride(newContent.heightOverride);
            }.bind(this));
        }

        // ---------------------------------------------------------------------------------------------------------
        // ---------------------------------------------------------------------------------------------------------
        viewWillAppear() {
            var promise = super.viewWillAppear(...arguments);
            this.stopRipple();
            this.delegate.onViewWillAppear && this.delegate.onViewWillAppear.call(this.delegate, this, this.sectionIdx, this.rowIdx, this.tableView);

            this._verifyExpandState();

            return promise;
        }

        viewWillDisappear() {
            this.unregisterClickHandling();
            return super.viewWillDisappear(...arguments);
        }

        destroy() {
            if (this._hasExpandHandling() && this.isExpanded) {
                this.destroyDetail();
            }

            return super.destroy(...arguments);
        }

        _verifyExpandState() {
            this.clickableContainer = this._getClickableElement();

            if (this._hasClickHandling() || this._hasExpandHandling()) {
                GUI.animationHandler.addCssClass(this.clickableContainer, "clickable"); // Always check if the tap is already register to prevent multiple registrations

                if (this._hammerObject) {
                    this.unregisterClickHandling();
                }

                this.registerClickHandling();

                if (this._hasExpandHandling() && !this.isExpanded) {
                    GUI.animationHandler.addCssClass(this.element, "expandable-cell");
                    this.isExpanded = false;
                } else {
                    GUI.animationHandler.toggleCssClass(this.element, "expandable-cell", false);
                }
            } else {
                GUI.animationHandler.toggleCssClass(this.clickableContainer, "clickable", false);
            } // possibility to expand cell per default


            if (this._hasExpandHandling() && this._isExpandedPerDefault()) {
                if (!this.isExpanded) {
                    this.toggleExpandState();
                }
            }
        }

        // Public Methods
        setContent(content) {
            this.content = cloneObjectDeep(content);
        }

        setEnabled(enabled) {
            GUI.animationHandler.toggleCssClass(this.element, "disabled", !enabled);
            this.content.disabled = !enabled; // important, as otherwise alreadySelected will not be called (ripple wouldn't be started, as the ripple-
            // containers rippleEnabled flag isn't updated!)

            this.setRippleEnabled(this.rippleAllowed());
        }

        /**
         * Sets the cell active
         * @param active
         * @return Q.Promise<any>
         */
        setActive(active) {
            return GUI.animationHandler.toggleCssClass(this.element, "active", active);
        }

        // SELECTABLE addition!
        setSelected(active) {
            GUI.animationHandler.toggleCssClass(this.element, "base-cell--selected", active);

            if (!active) {
                this.stopRipple();
            }
        }

        // SELECTABLE addition!
        onRippleEnd(wasStopped) {
            if (!wasStopped && this.content.selectable) {
                GUI.animationHandler.addCssClass(this.element, "base-cell--selected");
            }

            this.alreadySelected = false;
        }

        onSelected(srcEvent) {
            if (this.content.disabled) {
                return;
            }

            Debug.GUI.TableViewCell && console.log(this.name, "onSelected");

            if (!this.alreadySelected) {
                this.alreadySelected = true; // SELECTABLE addition!
                // selectable is per default ON, must be set explicitly to off!

                if (typeof this.content.selectable !== "boolean" || this.content.selectable === true) {
                    // keep in mind: cells can be standalone (without table)
                    this.tableView && this.tableView.setSelectedCell && this.tableView.setSelectedCell(this, true);
                }

                this.delegate.didSelectCell && this.delegate.didSelectCell.call(this.delegate, this, this.sectionIdx, this.rowIdx, this.tableView, srcEvent); // alreadySelected will be set to false after the ripple ends.
                // The cell will never be marked as "deselected" if no ripple is allowed,
                // so lets set is as deselected if no ripple is allowed after all delegate calls

                if (!this.rippleAllowed()) {
                    this.alreadySelected = false;
                }
            }

            Debug.GUI.TableViewCell && console.log(this.name, "onSelected < passed");
        }

        toggleExpandState() {
            this.isExpanded = !this.isExpanded;
            var heightFrom, heightTo;
            var promise;

            if (this.isExpanded) {
                // open
                this.detail = BaseCell.Template.getDetailTemplate(); // pass through the detail element, adding directly the elements instead of returning

                this.initDetail(this.detail);
                promise = GUI.animationHandler.append(this.detail, this.element).then(function () {
                    // maybe there are some things to do in the DOM, to set the correct height for the next line
                    return this.expandStateChanged(ExpandState.Expanding).then(function () {
                        heightTo = this.detail.height();
                        this.detail[0].style.height = 0;
                        this.detail.velocity({
                            translateZ: 0,
                            // Force HA by animating a 3D property
                            height: [heightTo + "px", "0px"]
                        }, {
                            duration: this._isExpandedPerDefault() ? 0 : 300,
                            complete: function () {
                                //this.detail.css("height", "");
                                this.expandStateChanged(ExpandState.Expanded);

                                if (!this._isExpandedPerDefault()) {
                                    this.tableView.scrollTo(this.sectionIdx, this.rowIdx, true);
                                }
                            }.bind(this)
                        });
                    }.bind(this));
                }.bind(this));
            } else {
                // close
                heightFrom = this.detail.height();
                promise = this.expandStateChanged(ExpandState.Collapsing).then(function () {
                    this.detail.velocity({
                        translateZ: 0,
                        // Force HA by animating a 3D property
                        height: [0, heightFrom]
                    }, {
                        duration: 300,
                        complete: function () {
                            this.expandStateChanged(ExpandState.Collapsed); // close

                            this.destroyDetail();
                            GUI.animationHandler.remove(this.detail);
                            this.detail = null;
                        }.bind(this)
                    });
                }.bind(this));
            }

            return promise;
        }

        rippleAllowed() {
            return (this.clickableAllowed() || this.expandableAllowed()) && !this.content.disabled; // when clickable/expandable, we need ripple too
        }

        clickableAllowed() {
            return false;
        }

        expandableAllowed() {
            return false;
        }

        expandStateChanged(expandState) {
            // emit event - used e.g. in the homeAndFavorite-Screen to refresh the scrollview.
            if (expandState === ExpandState.Expanded || expandState === ExpandState.Collapsed) {
                var event = document.createEvent('HTMLEvents');
                event.initEvent("ExpandedChanged", true, true);
                event.detail = true;
                document.body.dispatchEvent(event);
            } // inform the delegate, if it's interested.


            this.delegate.onCellExpandedStateChanged && this.delegate.onCellExpandedStateChanged.call(this.delegate, expandState, this.rowIdx, this.sectionIdx, this.tableView);
            var elem = this.element;
            return GUI.animationHandler.schedule(function () {
                elem.toggleClass("expanding", expandState === ExpandState.Expanding);
                elem.toggleClass("expanded", expandState === ExpandState.Expanded);
                elem.toggleClass("collapsing", expandState === ExpandState.Collapsing);
            });
        }

        /**
         * Will ensure that clicking/tapping on the cell will be recognized and dispatched properly.
         */
        registerClickHandling() {
            // don't register the whole element, otherwise the editable cells and the scrollable lib get confused
            this.eventHandlers.push(this._hammer().on(tapEvent(), this._handleTap.bind(this)));

            if (this.content.supportHit || this.content.tickInterval) {
                this.eventHandlers.push(this._hammer().on('hold', this._dispatchButtonHit.bind(this)));
            }

            if (this.content.supportDoubleTap) {
                this.eventHandlers.push(this._hammer().on('doubletap', this._dispatchButtonDoubleTapped.bind(this)));
            }
        }

        /**
         * Removes the click handling that was put in place in registerClickHandling.
         */
        unregisterClickHandling() {
            while (this.eventHandlers.length) {
                this.eventHandlers.pop().dispose();
            }

            if (this._hammerObject) {
                this._hammerObject.dispose();

                this._hammerObject = null;
            }
        }

        // Private methods
        _setHeightOverride(override) {
            var el = this.element,
                cntnPlaceholder = this.contentPlaceholder,
                overrideVal = override || "",
                minHeightVal = override ? "auto" : "";
            el.css("height", overrideVal);
            el.css("max-height", overrideVal);
            el.css("min-height", minHeightVal);
            cntnPlaceholder.css("height", overrideVal);
            cntnPlaceholder.css("max-height", overrideVal);
            cntnPlaceholder.css("min-height", minHeightVal);
        }

        _hasClickHandling() {
            if (this.hasOwnProperty("content")) {
                return this.content.hasOwnProperty("clickable") && this.content.clickable || !this.content.hasOwnProperty("clickable") && this.clickableAllowed();
            } else {
                return false;
            }
        }

        _hasExpandHandling() {
            if (this.hasOwnProperty("content")) {
                return this.content.hasOwnProperty("expandable") && this.content.expandable || !this.content.hasOwnProperty("expandable") && this.expandableAllowed();
            } else {
                return false;
            }
        }

        _isExpandedPerDefault() {
            if (this.hasOwnProperty("content")) {
                return this.content.hasOwnProperty("expanded") && this.content.expanded === true;
            } else {
                return false;
            }
        }

        /**
         * Defines the clickable element
         * @returns {jQuery}
         * @private
         */
        _getClickableElement() {
            return this.contentPlaceholder;
        }

        _handleTap(e) {
            Debug.GUI.TableViewCell && console.log(this.name, "_handleTap");

            if (this._isHammerDisabled(e.target)) {
                return;
            }

            var rippleAllowed = this.rippleAllowed();
            stopEventPropagation(e);

            if (this.content.supportFastInteraction && this.alreadySelected && rippleAllowed) {
                this.stopRipple();
            }

            rippleAllowed && this.startHammerRipple(e, this._getCustomRippleElement(), this.content.selectable); // SELECTABLE addition!

            if (this._hasExpandHandling()) {
                this.toggleExpandState();
            } else {
                this.onSelected(e);
            }

            Debug.GUI.TableViewCell && console.log(this.name, "_handleTap < passed");
        }

        _isHammerDisabled(element) {
            if (!element) {
                return false;
            }
            return element.disableHammer;
        }

        _dispatchButtonDoubleTapped() {
            this._callDelegateMethod("onCellDoubleTapped");
        }

        _dispatchButtonHit(e) {
            /*
            Listen for the release event on the whole window to also catch releases of the button
            if another view is displayed on top or the button disappears while holding.
             don't add the events in registerClickHandling because the callback would call on every click
            */
            if (this.content.supportRelease || this.content.tickInterval) {
                this.hammerObjectWindow = Hammer(window);
                this.hammerObjectWindow.on('release', this._dispatchButtonReleased.bind(this));
                this.hammerObjectWindow.on('touchend', this._dispatchButtonReleased.bind(this));
            }

            var intervalCallback;
            this._buttonHit = true;

            this._callDelegateMethod("onCellHit");

            this._performOnHoldRipple(e);

            if (this.content.supportsTick) {
                this._stopInterval();

                intervalCallback = this._callDelegateMethod.bind(this, "onCellTick");
                !this.content.supportHit && intervalCallback(); // if no hit was sent, a tick will ensure an immediate response

                this._tickTimer = setTimeout(function () {
                    // use a fixed timeout before starting the interval
                    // Directly call the callback before starting the interval,
                    // Without the prior call the initial time the interval is executed is this.content.tickInterval * 2 (Timeout + Interval)
                    intervalCallback();
                    this._tickTimer = setInterval(intervalCallback, this.content.tickInterval);
                }.bind(this), this.content.tickInterval); // initial delay until the interval starts.
            }
        }

        _performOnHoldRipple(e) {
            this.startHammerRippleHold(e, this._getCustomRippleElement());
        }

        _dispatchButtonReleased(e) {
            e.preventDefault();
            this.hammerObjectWindow.off("release");
            this.hammerObjectWindow.off("touchend");
            this.hammerObjectWindow && this.hammerObjectWindow.dispose();
            this.hammerObjectWindow = null;

            if (this._buttonHit) {
                this._buttonHit = false;

                this._callDelegateMethod("onCellReleased");

                this.stopRipple();

                this._stopInterval();
            }
        }

        /**
         * Will check if the delegate implements this method. if so it'll call it.
         * @param delMethod
         * @private
         */
        _callDelegateMethod(delMethod) {
            if (this.delegate.hasOwnProperty(delMethod)) {
                this.delegate[delMethod].call(this.delegate, this, this.sectionIdx, this.rowIdx, this.tableView);
            }
        }

        _stopInterval() {
            if (this._tickTimer) {
                // clear both timeout and interval, because we use both!
                clearTimeout(this._tickTimer);
                clearInterval(this._tickTimer);
                this._tickTimer = null;
            }
        }

        _getCustomRippleElement() {
            return null;
        }

        _hammer() {
            if (!this._hammerObject) {
                this._hammerObject = Hammer(this.clickableContainer[0], this._getHammerOptions());
            }

            return this._hammerObject;
        }

        _getHammerOptions() {
            return {
                tapMaxTime: 169,
                holdTimeout: 170,
                tapAlways: true // otherwise no "fast taps" would be created, because of doubletap

            };
        }

    }

    GUI.TableViewV2.Cells.BaseCell = BaseCell;
    return GUI;
}(window.GUI || {});
