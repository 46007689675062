import AlarmControlContentBase from "../../alarmControlBase/content/controlContent";

function AalSmartAlarmControlContent(props) {
    return <AlarmControlContentBase navigation={props.navigation}
        drillMessage={ _("controls.aal-smart-alarm.drill.verify.message")}
                                    drillTitle={_("controls.aal-smart-alarm.drill.verify.title")}
                                    drillStartButtonTitle={_("controls.aal-smart-alarm.drill.start")} />
}

export default AalSmartAlarmControlContent
