'use strict';
/*
 iconSrc            The source of the left icon
 title              The title of the cell
 value              moment.js object
 minDate            Javascript Date object (default MS time + 1min)
 maxDate            Javascript Date object (default end of year +2 years)
 hideTimeAndYear    only day and month can be selected

 onPickerChanged(section, row, tableView, value)

 Examples:
 Label:                 26.01.2016 12:48 Uhr
 -- --- ----  -- --
 Wheel:     26 Jan 2016  12 48
 -- --- ----  -- --
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.DATE_TIME_PICKER = "DateTimePickerCell";

    class DateTimePickerCell extends GUI.TableViewV2.Cells.PickerBaseCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("date-time-picker-cell");
        }

        setContent(content) {
            super.setContent(content);

            if (this.content.hideTimeAndYear) {
                this.dateFormat = LxDate.getMobiscrollDateFormat(true);
            } else {
                this.dateFormat = LxDate.getMobiscrollDateFormat();
                this.timeFormat = LxDate.getMobiscrollTimeFormat(true);
            }
        }

        initDetail(detailElement) {
            super.initDetail(detailElement);
            detailElement.addClass("date-time-picker-cell__detail");
        }

        /**
         * This function initializes the Picker
         */
        preparePicker() {
            var msTime = SandboxComponent.getMiniserverTimeInfo();
            var pickerConfig = this.mergePickerConfig({
                minDate: this.content.minDate || ActiveMSComponent.convertToJsDate(msTime.add(1, "minute")),
                // minutes plus 1, min of the timer value
                maxDate: this.content.maxDate || ActiveMSComponent.convertToJsDate(msTime.add(2, 'year').endOf('year'))
            });

            if (this.content.hideTimeAndYear) {
                pickerConfig.dateFormat = this.dateFormat;
                pickerConfig.dateOrder = isUSDateOrder() ? "MMdd" : "ddMM"; // year gets hidden

                this.picker.date(pickerConfig);
            } else {
                var pickerTimeFormat = this.timeFormat.replace("mm", "ii");
                pickerConfig.dateFormat = this.dateFormat;
                pickerConfig.dateOrder = isUSDateOrder() ? "Mddyy" : "ddMyy";
                pickerConfig.timeFormat = pickerTimeFormat;
                pickerConfig.timeWheels = pickerTimeFormat.replace(":", "").replace(" ", "");
                this.picker.datetime(pickerConfig);
            }

            this.setPickerValue(this.content.value);
        }

        /**
         * This sets the given value to the mobiscroll picker
         * @param momentDate This is a moment.js object
         */
        setPickerValue(momentDate) {
            var jsDate = ActiveMSComponent.convertToJsDate(momentDate); // avoids issues with different timezones.

            this.picker.mobiscroll('setDate', jsDate);
        }

        /**
         * This function will be called once the pickerValue has changed
         * For example if the user turns one of the pickerWheels
         * @param v The value returned by mobiscroll in the given format
         */
        onChange(v) {
            var jsDateObj = this.picker.mobiscroll('getVal'),
                // v is a string, we use the date object.
                momentObj = ActiveMSComponent.convertToMsDate(jsDateObj); // avoids issues with different timezones.

            this.antiGhostTimer.fire();
            this.updateLabel(this.getValueAsString(momentObj));
            this.delegate.onPickerChanged.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView, momentObj);
        }

        /**
         * This function returns the string for the timeLabel
         * @param val This is a moment.js object
         */
        getValueAsString(val) {
            var format, stringValue;

            if (this.content.hideTimeAndYear) {
                format = LxDate.getDateFormat(DateType.DateTextNoYear);
            } else {
                format = this.dateFormat.toUpperCase() + " " + this.timeFormat;
            }

            stringValue = val.format(format);

            if (!useAmPm() && !this.content.hideTimeAndYear) {
                stringValue += " " + _("timeSuffix");
            }

            return stringValue;
        }

    }

    GUI.TableViewV2.Cells.DateTimePickerCell = DateTimePickerCell;
    return GUI;
}(window.GUI || {});
