'use strict';
/*
entries = [{time: "12:43:54", text: "Some Text"}]
 */

GUI.TableViewV2.CellType.Special.TRACKER = "TrackerCell";
{//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    class TrackerCell extends GUI.TableViewV2.Cells.BaseCell {

        static SEPARATOR_SYMBOL = '\x14';

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("tracker-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            var table, date, text, messages, textToAppend;
            table = '<table class="sub-cell-table">';

            for (var i = 0; i < this.content.entries.length; i++) {
                date = this.content.entries[i].timestamp.format(LxDate.getTimeFormat());
                text = this.content.entries[i].text;
                messages = text.split(TrackerCell.SEPARATOR_SYMBOL);
                textToAppend = messages.map(function (message, idx) {
                    return '<div>' + message.encodeSpecialHtmlEntities().addLineBreakPossibilities() + '</div>';
                });
                textToAppend = textToAppend.join('');
                table += '<tr class="sub-cell-row">';
                table += '<td class="sub-cell-row__time">' + date + '</td>';
                table += '<td class="sub-cell-row__text">' + textToAppend + '</td>';
                table += '</tr>';
            }

            table += '</table>';
            this.contentPlaceholder.append($(table));
        }

    }

    GUI.TableViewV2.Cells.TrackerCell = TrackerCell;
}
