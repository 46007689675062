import * as React from "react"
import Svg, { Path } from "react-native-svg"

function ArrowPointingInCircle(props) {
    return (
        <Svg isreact="true"
            height={24}
            viewBox="0 0 24 24"
            width={24}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M12.5 1C18.851 1 24 6.149 24 12.5S18.851 24 12.5 24a11.501 11.501 0 01-10.498-6.797.5.5 0 11.912-.41A10.502 10.502 0 0012.5 23C18.299 23 23 18.299 23 12.5S18.299 2 12.5 2C8.343 2 4.63 4.439 2.937 8.158a.5.5 0 01-.91-.414A11.502 11.502 0 0112.5 1zm.284 7.089l.07.057 4 4 .011.013a.503.503 0 01.033.039l-.044-.052A.502.502 0 0117 12.5v.02a.503.503 0 01-.005.052L17 12.5a.502.502 0 01-.089.284l-.013.018a.503.503 0 01-.033.04l-.011.012-4 4a.5.5 0 01-.765-.638l.057-.07L15.293 13H1.5a.5.5 0 01-.09-.992L1.5 12h13.793l-3.147-3.146a.5.5 0 01-.057-.638l.057-.07a.5.5 0 01.638-.057z"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default ArrowPointingInCircle
