'use strict';

define([], function () {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    // all the computation factors are depending on the height
    var HEIGHT_FACTOR = {};
    var DEFAULT_HEIGHT = 480;
    var GD_HEIGHT = 400;

    var prepareSizes = function prepareSizes(height) {
        var width = 320 / 480 * height;
        HEIGHT_FACTOR.outerDiameter = 70 / height;
        HEIGHT_FACTOR.spacing = 20 / height;
        HEIGHT_FACTOR.width = width / height;
        HEIGHT_FACTOR.centerOffset = 110 / height;
        HEIGHT_FACTOR.arrowsLength = 23 / height;
        HEIGHT_FACTOR.strokeWidth = 3.5 / height;
        HEIGHT_FACTOR.markerRadius = 5 / height;
        HEIGHT_FACTOR.lineOffset = 3 / height;
        HEIGHT_FACTOR.strokeMargin = 6.5 / height;
        HEIGHT_FACTOR.singlePx = 1 / height;
        HEIGHT_FACTOR.fontSize = 20 / height;
        HEIGHT_FACTOR.fontSizeMedium = 17 / height;
        HEIGHT_FACTOR.fontSizeSmall = 14 / height;
        HEIGHT_FACTOR.textSpacing = 4 / height;
    };

    var NodeType = {
        BATT: "BATTERY",
        CONS: "CONSUMPTION",
        PROD: "PRODUCTION",
        GRID: "GRID"
    };
    var ModeType = {
        EXP: "exporting",
        // exporting production to the grid
        CHRG_EXT: "charging-ext",
        // charging the battery from to the grid
        CONS_EXT: "consuming-ext",
        // consuming from the grid
        CONS_BATT: "consuming-batt",
        // consuming from the battery
        CONS_PROD: "consuming-prod",
        // consuming own production
        CHRG_PROD: "charging-prod" // charging the battery using own production

    };
    /**
     * used to tell e.g. draw arrows from where to where the flow arrows need to go.
     * @type {{RTL: number, LTR: number, UP: number, DOWN: number}}
     */

    var FlowDir = {
        RTL: 180,
        LTR: 0,
        UP: 270,
        DOWN: 90
    };
    return class EnergyMonitorBattAnimationView extends GUI.View {
        //region Static
        static Template = function () {
            var getSvg = function getSvg() {
                return '<svg class="energy-monitor-visualisation__svg" viewBox="0 0 32 48"></svg>';
            };
            /**
             * Helper method for creating points.
             * @param x
             * @param y
             * @returns {{x: *, y: *}}
             * @private
             */


            var p = function p(x, y) {
                return {
                    x: x,
                    y: y
                };
            };
            /**
             * Draws lines based on a array of points.
             * @param paper     the paper to draw the lines in.
             * @param points    array containint start- & endpoints of lines (startA, endA, startB, endB, ...)
             * @returns {*}     a group containing all these lines
             * @private
             */


            var _drawLines = function _drawLines(paper, points) {
                var idx,
                    start,
                    end,
                    i,
                    g = paper.g();

                for (i = 0; i < points.length / 2; i++) {
                    idx = i * 2;
                    start = points[idx];
                    end = points[idx + 1];

                    if (start && end) {
                        g.add(paper.path('M' + start.x + "," + start.y + // move to start position
                            "L" + end.x + "," + end.y)); // draw a line to the end pos
                    }
                }

                return g.addClass("svg__line");
            };
            /**
             * Draws a rect with the center provided and width and height in size's strokeWidth.
             * @param paper
             * @param center
             * @param size
             * @private
             */


            var _drawConnRect = function _drawConnRect(paper, center, size) {
                var x = center.x - size.strokeWidth / 2,
                    y = center.y - size.strokeWidth / 2,
                    w = size.strokeWidth,
                    h = size.strokeWidth;
                return paper.rect(x, y, w, h).attr({
                    strokeWidth: size.singlePx
                }).addClass("svg__conn-rect");
            };
            /**
             * Left to right arrow
             * @param paper
             * @param center
             * @param size
             * @returns {*}
             * @private
             */


            var _drawArrow = function _drawArrow(paper, center, size) {
                var arrWidth = size.strokeWidth / 1.5,
                    swh = size.strokeWidth / 2,
                    centerOff = arrWidth - swh,
                    pts = [center.x - arrWidth, // lt
                        center.y - swh, center.x + centerOff, // rt
                        center.y - swh, center.x + arrWidth, // arrow right
                        center.y, center.x + centerOff, // rb
                        center.y + swh, center.x - arrWidth, // lb
                        center.y + swh, center.x - centerOff, // arrow left
                        center.y, center.x - arrWidth, // lt
                        center.y - swh];
                return paper.polygon(pts);
            };
            /**
             * Draws 4 background-colored arrows pointing to the position specified. the will be rotated around that position
             * @param paper         where to draw
             * @param pointingTo    where do they point to
             * @param direction     how to rotate them (0-360)
             * @param size          the size info object.
             * @returns {*}         a group containing these flow-arrows.
             * @private
             */


            var _drawArrows = function _drawArrows(paper, pointingTo, direction, size) {
                var arrows = paper.group(),
                    arrowWidth = size.strokeWidth / 1.5,
                    arrowOffset = size.strokeWidth * 2,
                    arrPt1,
                    arrPt2,
                    arrPt3,
                    arrPt4,
                    rotationMatrix; // draw them as if they are pointing to it from left to right

                arrPt1 = EnergyMonitorBattAnimationView.Template.p(pointingTo.x - arrowWidth / 2, pointingTo.y);
                arrPt2 = EnergyMonitorBattAnimationView.Template.p(arrPt1.x - arrowOffset, arrPt1.y);
                arrPt3 = EnergyMonitorBattAnimationView.Template.p(arrPt2.x - arrowOffset, arrPt1.y);
                arrPt4 = EnergyMonitorBattAnimationView.Template.p(arrPt3.x - arrowOffset, arrPt1.y); // draw them

                arrows.add(_drawArrow(paper, arrPt1, size).addClass("flow-arrows__blank flow-arrows__blank--0"));
                arrows.add(_drawArrow(paper, arrPt2, size).addClass("flow-arrows__blank flow-arrows__blank--1"));
                arrows.add(_drawArrow(paper, arrPt3, size).addClass("flow-arrows__blank flow-arrows__blank--2"));
                arrows.add(_drawArrow(paper, arrPt4, size).addClass("flow-arrows__blank flow-arrows__blank--3"));
                rotationMatrix = new Snap.Matrix();
                rotationMatrix.rotate(direction, pointingTo.x, pointingTo.y);
                arrows.transform(rotationMatrix);
                arrows.addClass("svg__flow-arrows");
                return arrows;
            };

            var ICON_VIEWBOX = 25,
                // the default viewbox size of icons
                ICON_SIZE_FACTOR = 0.55; // make the icons a bit smaller to have some space around it

            var _drawNode = function _drawNode(type, paper, center, size, cssClass) {
                var iconSrc = Icon.EnergyMonitor[type],
                    iconGroup = paper.g().addClass("svg__node svg__node--" + cssClass),
                    radius = size.outerDiameter / 2;
                iconGroup.circle(0, 0, radius, radius).addClass('bg'); // load, position and add the icon

                var addToTarget = iconGroup; // Creating blob to pass to Snap

                var imageString = ImageBox.getResourceImage(iconSrc);
                var imgBlob = new Blob([imageString], {
                    type: "image/svg+xml;charset=utf-8"
                });
                Snap.load(URL.createObjectURL(imgBlob), function (f) {
                    var selected = f.select("path"); // get path from the loaded svg f

                    var selBBox = selected.getBBox();

                    var scaleX = (size.outerDiameter * ICON_SIZE_FACTOR) / ICON_VIEWBOX;
                    var scaleY = (size.outerDiameter * ICON_SIZE_FACTOR) / ICON_VIEWBOX; // center the icon in it's parent

                    var mvX = selBBox.cx * -1;
                    var mvY = selBBox.cy * -1;
                    var matrix = new Snap.Matrix();
                    matrix.scale(scaleX || 1, scaleY || 1);
                    matrix.translate(mvX, mvY);
                    selected.transform(matrix);
                    selected.addClass('content');
                    selected.appendTo(addToTarget);
                });
                iconGroup.transform("translate(" + center.x + "," + center.y + ")");
                return iconGroup;
            };
            /**
             * An aura is used to lay over the lines in order to make it look pretty.
             * @param paper
             * @param position
             * @param size
             * @returns {*}
             * @private
             */


            var _drawAura = function _drawAura(paper, position, size) {
                var radius = size.outerDiameter / 2 + size.strokeWidth;
                return paper.circle(position.x, position.y, radius).addClass("svg__aura");
            };
            /**
             * used to draw a circle and add a button class
             * @param paper
             * @param position
             * @param size
             * @returns {*}
             * @private
             */


            var _drawButton = function _drawButton(paper, position, size) {
                var radius = size.outerDiameter / 2 + size.strokeWidth;
                return paper.circle(position.x, position.y, radius).addClass("svg__button clickable");
            };
            /**
             * draws a nodes labels.  (alignment depending on the anchor).
             * @param paper             the paper to draw on
             * @param nodeType          for what node the labels are
             * @param bottomLocation    the bottom location where the text has to be (e.g. centrally below the battery)
             * @param title             used for the description. will be shown right away while not having any state info
             * @param anchor            start, end, middle -> describes how the text is going to align over the bottom location
             * @param size              the size info object
             * @returns {{value: *, unit: *, desc: *}}
             * @private
             */


            var _drawNodeLabels = function getNodeLabels(paper, nodeType, bottomLocation, title, anchor, size) {
                var attr = {
                        textAnchor: anchor,
                        fontSize: size.fontSizeMedium
                    },
                    descrLocation,
                    valueCntr,
                    descrLbl,
                    valueLbl,
                    unitLbl,
                    valNodeCntr; // first of all, prepare the value & unit labels

                valueCntr = paper.text(bottomLocation.x, bottomLocation.y, ["0", "kW"]);
                valueCntr.addClass('svg__node-label svg__node-value-cntr svg__node-value-cntr--' + nodeType);
                valueCntr.attr(attr);
                valNodeCntr = $(valueCntr.node).find('tspan');
                valueLbl = $(valNodeCntr[0]).addClass('node-value-cntr__value')[0];
                unitLbl = $(valNodeCntr[1]).addClass('node-value-cntr__unit')[0]; // now prepare the description label

                descrLocation = {
                    x: bottomLocation.x,
                    y: bottomLocation.y - (size.fontSizeMedium + size.textSpacing)
                };
                attr.fontSize = size.fontSizeSmall;
                descrLbl = paper.text(descrLocation.x, descrLocation.y, title);
                descrLbl.addClass('svg__node-label svg__node-descr svg__node-descr--' + nodeType);
                descrLbl.attr(attr); // return an object containing references to the labels.

                return {
                    value: valueLbl,
                    unit: unitLbl,
                    desc: descrLbl.node // the others are already unboxed

                };
            };
            /**
             *
             * @param paper     the paper to draw on
             * @param center    the center of the circle
             * @param isLeft    if left, draw counterclockwise.
             * @param size      the size infos for this circle.
             * @private
             */


            var _drawNodeCircle = function _drawNodeCircle(paper, center, isLeft, size, addClass) {
                var offset = size.strokeWidth / 2,
                    radius = (size.outerDiameter - offset) / 2,
                    g = paper.g(),
                    srcX = isLeft ? center.x - radius : center.x + radius;
                var arcSrc = EnergyMonitorBattAnimationView.Template.p(srcX, center.y - offset),
                    arcDst = EnergyMonitorBattAnimationView.Template.p(arcSrc.x, center.y + offset),
                    r = 2.7; // Start with a fixed full circle in the bg - it will be "cut" in pieces by lines and blanks on top of it.

                g.add(paper.circle(center.x, center.y, radius).addClass("node-circle--fixed")); // these are the two "components" that make up the energy mix.

                g.add(_arcTo(paper, arcSrc, arcDst, radius, isLeft).addClass("node-circle--grid"));
                g.add(_arcTo(paper, arcSrc, arcDst, radius, isLeft).addClass("node-circle--batt"));
                g[NodeType.GRID] = g[1];
                g[NodeType.BATT] = g[2]; // as the stroked line won't start at a proper position, both circles need to be rotated a bit.

                g[NodeType.GRID].transform("R" + r * -1 + "," + center.x + "," + center.y);
                g[NodeType.BATT].transform("R" + r + "," + center.x + "," + center.y);
                return g.addClass("svg__node-circle svg__node-circle--" + addClass);
            };
            /**
             * Draws a path for an arc from the given source to the destination point.
             * @param arcSrc
             * @param arcDst
             * @param radius
             * @param drawClockwise
             * @returns {*}
             * @private
             */


            var _arcTo = function _arcTo(paper, arcSrc, arcDst, radius, drawClockwise) {
                var sweepFlag = drawClockwise ? "1" : "0",
                    rx = radius,
                    ry = radius,
                    xAxisRotation = 0,
                    largeArcFlag = 1,
                    x = arcDst.x,
                    y = arcDst.y;
                var path = 'M' + arcSrc.x + "," + arcSrc.y;
                path += 'A' + rx + ',' + ry + ',' + xAxisRotation + "," + largeArcFlag + "," + sweepFlag + ',' + x + ',' + y;
                return paper.path(path);
            };
            /**
             * Helper method, will draw a red circle at the position given.
             * @param paper
             * @param pos
             * @param size
             * @private
             */


            var _markPosition = function _markPosition(paper, pos, size) {
                paper.circle(pos.x, pos.y, size.markerRadius).attr({
                    fill: "red"
                });
            };

            return {
                getSvg: getSvg,
                drawArrows: _drawArrows,
                drawAura: _drawAura,
                drawLines: _drawLines,
                drawConnRect: _drawConnRect,
                drawNode: _drawNode,
                drawNodeLabels: _drawNodeLabels,
                drawNodeCircle: _drawNodeCircle,
                drawButton: _drawButton,
                p: p
            };
        }(); //endregion Static

        constructor(comfortMode) {
            super($('<div class="energy-monitor-visualisation"></div>'));
            this.comfortMode = !!comfortMode;

            if (this.comfortMode) {
                prepareSizes(GD_HEIGHT);
                this.element.addClass("energy-monitor-visualisation--green-destiny");
            } else {
                prepareSizes(DEFAULT_HEIGHT);
            }
        }

        viewDidLoad() {
            return Q.resolve(super.viewDidLoad(...arguments) || true).then(() => {
                this.element.append(EnergyMonitorBattAnimationView.Template.getSvg());
                this.svgElem = this.element.find(".energy-monitor-visualisation__svg");
                var svg = this.svgElem[0],
                    paper = Snap(svg),
                    height = svg.viewBox.baseVal.height;
                this.size = {
                    height
                }; // compute the sizes to use based on the current height of the svg.

                Object.keys(HEIGHT_FACTOR).forEach(function (key) {
                    this.size[key] = height * HEIGHT_FACTOR[key];
                }.bind(this));
                paper.attr({
                    strokeWidth: this.size.strokeWidth
                }); // Compute Positions

                this._computePositions(this.size); // draw flow lines & arrows

                this._prepareFlowLines(paper, this.size); // draw label

                this._prepareLabels(paper, this.size); // prepare buttons

                this._prepareButtons(paper, this.size);

                this._prepareNodes(paper, this.size);
            });
        }

        viewWillAppear() {
            return Q(super.viewWillAppear(...arguments)).then(() => {
                Object.keys(this.buttons).forEach(function (btnKey) {
                    this.buttons[btnKey].onButtonReleased = this._handleButtonTap.bind(this, this.buttons[btnKey]);
                }.bind(this));
            });
        }

        viewWillDisappear() {
            // reset button handlers.
            Object.keys(this.buttons).forEach(function (btnKey) {
                this.buttons[btnKey].onButtonReleased = null;
            }.bind(this));
            return super.viewWillDisappear(...arguments);
        }

        /**
         * In this method all points are being computed that will be needed for drawing the animation svg.
         * @param size  the size object based on which the positions are being computed.
         * @private
         */
        _computePositions(size) {
            var nodeRadius = size.outerDiameter / 2,
                swh = size.strokeWidth / 2,
                // halve the stroke width;
                arrLen = size.arrowsLength; // first, start with the center

            this.center = EnergyMonitorBattAnimationView.Template.p(size.width / 2, size.height / 2); // since the lines mustn't overlap (looks bad), the center will be a simple rect and the lines doc at pts around it

            this.centerLeft = EnergyMonitorBattAnimationView.Template.p(this.center.x - swh, this.center.y);
            this.centerRight = EnergyMonitorBattAnimationView.Template.p(this.center.x + swh, this.center.y);
            this.centerTop = EnergyMonitorBattAnimationView.Template.p(this.center.x, this.center.y - swh * 2);
            this.centerBottom = EnergyMonitorBattAnimationView.Template.p(this.center.x, this.center.y + swh * 2); // center connectors (clockwise)

            this.centerPt1 = EnergyMonitorBattAnimationView.Template.p(this.center.x - size.strokeMargin, this.center.y - size.strokeMargin);
            this.centerPt2 = EnergyMonitorBattAnimationView.Template.p(this.center.x + size.strokeMargin, this.center.y - size.strokeMargin);
            this.centerPt3 = EnergyMonitorBattAnimationView.Template.p(this.center.x + size.strokeMargin, this.center.y + size.strokeMargin);
            this.centerPt4 = EnergyMonitorBattAnimationView.Template.p(this.center.x - size.strokeMargin, this.center.y + size.strokeMargin); // to avoid nasty graphic issues on where lines meet, draw small rects as connector pts

            this.connCenterPt11 = EnergyMonitorBattAnimationView.Template.p(this.centerPt1.x - swh, this.centerPt1.y);
            this.connCenterPt12 = EnergyMonitorBattAnimationView.Template.p(this.centerPt1.x, this.centerPt1.y - swh);
            this.connCenterPt21 = EnergyMonitorBattAnimationView.Template.p(this.centerPt2.x, this.centerPt2.y - swh);
            this.connCenterPt22 = EnergyMonitorBattAnimationView.Template.p(this.centerPt2.x + swh, this.centerPt2.y);
            this.connCenterPt31 = EnergyMonitorBattAnimationView.Template.p(this.centerPt3.x + swh, this.centerPt3.y);
            this.connCenterPt32 = EnergyMonitorBattAnimationView.Template.p(this.centerPt3.x, this.centerPt3.y + swh);
            this.connCenterPt41 = EnergyMonitorBattAnimationView.Template.p(this.centerPt4.x, this.centerPt4.y + swh);
            this.connCenterPt42 = EnergyMonitorBattAnimationView.Template.p(this.centerPt4.x - swh, this.centerPt4.y); // Node Positions

            this.gridCenter = EnergyMonitorBattAnimationView.Template.p(this.center.x, this.center.y - size.centerOffset);
            this.consCenter = EnergyMonitorBattAnimationView.Template.p(this.center.x + size.centerOffset, this.center.y);
            this.battCenter = EnergyMonitorBattAnimationView.Template.p(this.center.x, this.center.y + size.centerOffset);
            this.prodCenter = EnergyMonitorBattAnimationView.Template.p(this.center.x - size.centerOffset, this.center.y); // Grid Connectors

            this.gridPt1 = EnergyMonitorBattAnimationView.Template.p(this.center.x - size.strokeMargin, this.gridCenter.y + nodeRadius + size.lineOffset);
            this.gridPt2 = EnergyMonitorBattAnimationView.Template.p(this.center.x, this.gridPt1.y);
            this.gridPt3 = EnergyMonitorBattAnimationView.Template.p(this.center.x + size.strokeMargin, this.gridPt1.y); // pre points for arrows

            this.gridPt1Pre = EnergyMonitorBattAnimationView.Template.p(this.gridPt1.x, this.gridPt1.y + arrLen); // Battery Connectors

            this.battPt1 = EnergyMonitorBattAnimationView.Template.p(this.gridPt1.x, this.battCenter.y - (nodeRadius + size.lineOffset));
            this.battPt2 = EnergyMonitorBattAnimationView.Template.p(this.gridPt2.x, this.battPt1.y);
            this.battPt3 = EnergyMonitorBattAnimationView.Template.p(this.gridPt3.x, this.battPt1.y); // pre points for arrows

            this.battPt1Pre = EnergyMonitorBattAnimationView.Template.p(this.battPt1.x, this.battPt1.y - arrLen);
            this.battPt2Pre = EnergyMonitorBattAnimationView.Template.p(this.battPt2.x, this.battPt1Pre.y); // Consumption Connectors

            this.consPt1 = EnergyMonitorBattAnimationView.Template.p(this.consCenter.x - (nodeRadius + size.lineOffset), this.center.y - size.strokeMargin);
            this.consPt2 = EnergyMonitorBattAnimationView.Template.p(this.consPt1.x, this.center.y);
            this.consPt3 = EnergyMonitorBattAnimationView.Template.p(this.consPt1.x, this.center.y + size.strokeMargin); // pre points for arrows

            this.consPt1Pre = EnergyMonitorBattAnimationView.Template.p(this.consPt1.x - arrLen, this.consPt1.y);
            this.consPt2Pre = EnergyMonitorBattAnimationView.Template.p(this.consPt1Pre.x, this.consPt2.y);
            this.consPt3Pre = EnergyMonitorBattAnimationView.Template.p(this.consPt1Pre.x, this.consPt3.y); // Production Connectors

            this.prodPt1 = EnergyMonitorBattAnimationView.Template.p(this.prodCenter.x + nodeRadius + size.lineOffset, this.consPt1.y);
            this.prodPt2 = EnergyMonitorBattAnimationView.Template.p(this.prodPt1.x, this.consPt2.y);
            this.prodPt3 = EnergyMonitorBattAnimationView.Template.p(this.prodPt1.x, this.consPt3.y);
        }

        /**
         * Prepares the flow lines between the nodes. Also takes care of drawing the arrows for the flow.
         * @param paper
         * @param size
         * @private
         */
        _prepareFlowLines(paper, size) {
            // create a helper function for drawing corners.
            var drawCorner = function drawCorner(linePts, connPt, type) {
                EnergyMonitorBattAnimationView.Template.drawConnRect(paper, connPt, size).addClass("svg__conn-rect--" + type);
                EnergyMonitorBattAnimationView.Template.drawLines(paper, linePts).addClass("svg__line--" + type);
            }; // Draw exporting


            var expPts = [this.prodPt1, this.connCenterPt11, this.connCenterPt12, this.gridPt1Pre];
            drawCorner(expPts, this.centerPt1, ModeType.EXP);
            EnergyMonitorBattAnimationView.Template.drawLines(paper, [this.gridPt1Pre, this.gridPt1]).addClass("svg__line--" + ModeType.EXP + "-inactive"); // draw charging from ext

            var chargingExtPts = [this.gridPt2, this.centerTop, this.centerBottom, this.battPt2Pre];
            EnergyMonitorBattAnimationView.Template.drawLines(paper, chargingExtPts).addClass("svg__line--" + ModeType.CHRG_EXT);
            EnergyMonitorBattAnimationView.Template.drawLines(paper, [this.battPt2Pre, this.battPt2]).addClass("svg__line--" + ModeType.CHRG_EXT + "-inactive"); // draw consuming from ext

            var consExtPts = [this.gridPt3, this.connCenterPt21, this.connCenterPt22, this.consPt1Pre];
            drawCorner(consExtPts, this.centerPt2, ModeType.CONS_EXT);
            EnergyMonitorBattAnimationView.Template.drawLines(paper, [this.consPt1Pre, this.consPt1]).addClass("svg__line--" + ModeType.CONS_EXT + "-inactive"); // draw consuming production

            var consProdPts = [this.consPt2Pre, this.prodPt2];
            EnergyMonitorBattAnimationView.Template.drawLines(paper, consProdPts).addClass("svg__line--" + ModeType.CONS_PROD);
            EnergyMonitorBattAnimationView.Template.drawLines(paper, [this.consPt2Pre, this.consPt2]).addClass("svg__line--" + ModeType.CONS_PROD + "-inactive"); // draw consuming battery

            var consBattPts = [this.consPt3Pre, this.connCenterPt31, this.connCenterPt32, this.battPt3];
            drawCorner(consBattPts, this.centerPt3, ModeType.CONS_BATT);
            EnergyMonitorBattAnimationView.Template.drawLines(paper, [this.consPt3Pre, this.consPt3]).addClass("svg__line--" + ModeType.CONS_BATT + "-inactive"); // draw charging from production

            var chargingProdPts = [this.battPt1Pre, this.connCenterPt41, this.connCenterPt42, this.prodPt3];
            drawCorner(chargingProdPts, this.centerPt4, ModeType.CHRG_PROD);
            EnergyMonitorBattAnimationView.Template.drawLines(paper, [this.battPt1Pre, this.battPt1]).addClass("svg__line--" + ModeType.CHRG_PROD + "-inactive"); // then draw the flow arrows on top of the lines, the arrows have the background color.
            // Consumption

            EnergyMonitorBattAnimationView.Template.drawArrows(paper, this.consPt1, FlowDir.LTR, size).addClass("svg__flow-arrows--consuming-ext");
            EnergyMonitorBattAnimationView.Template.drawArrows(paper, this.consPt2, FlowDir.LTR, size).addClass("svg__flow-arrows--consuming-prod");
            EnergyMonitorBattAnimationView.Template.drawArrows(paper, this.consPt3, FlowDir.LTR, size).addClass("svg__flow-arrows--consuming-batt"); // Battery

            EnergyMonitorBattAnimationView.Template.drawArrows(paper, this.battPt1, FlowDir.DOWN, size).addClass("svg__flow-arrows--charging-prod");
            EnergyMonitorBattAnimationView.Template.drawArrows(paper, this.battPt2, FlowDir.DOWN, size).addClass("svg__flow-arrows--charging-ext"); // Grid

            EnergyMonitorBattAnimationView.Template.drawArrows(paper, this.gridPt1, FlowDir.UP, size).addClass("svg__flow-arrows--exporting");
        }

        /**
         * Will draw nodes with auras in the background.
         * @param paper
         * @param size
         * @private
         */
        _prepareNodes(paper, size) {
            // every node has an "aura"
            EnergyMonitorBattAnimationView.Template.drawAura(paper, this.gridCenter, size);
            EnergyMonitorBattAnimationView.Template.drawAura(paper, this.battCenter, size); // draw the "regular" nodes

            EnergyMonitorBattAnimationView.Template.drawNode(NodeType.GRID, paper, this.gridCenter, size, "grid");
            EnergyMonitorBattAnimationView.Template.drawNode(NodeType.BATT, paper, this.battCenter, size, "batt"); // as Production and Cons will have a circle around them, make them smaller

            EnergyMonitorBattAnimationView.Template.drawAura(paper, this.consCenter, size);
            EnergyMonitorBattAnimationView.Template.drawAura(paper, this.prodCenter, size); // draw the adopted nodes

            EnergyMonitorBattAnimationView.Template.drawNode(NodeType.CONS, paper, this.consCenter, size, "cons");
            EnergyMonitorBattAnimationView.Template.drawNode(NodeType.PROD, paper, this.prodCenter, size, "prod"); // draw the node circles around consumption and production. they show the current energy mix.

            this.consNode = EnergyMonitorBattAnimationView.Template.drawNodeCircle(paper, this.consCenter, true, size, "cons");
            var nodeElem = this.element.find(".svg__node-circle--cons");
            this.consNodeGrid = nodeElem.find(".node-circle--grid").hide();
            this.consNodeBatt = nodeElem.find(".node-circle--batt").hide();
        }

        /**
         * Will draw invisible buttons above the nodes. These will be used to create buttons, that detect taps and
         * inform the delegates that are listening for these events.
         * @param paper
         * @param size
         * @private
         */
        _prepareButtons(paper, size) {
            EnergyMonitorBattAnimationView.Template.drawButton(paper, this.prodCenter, size).addClass("svg__button--prod");
            EnergyMonitorBattAnimationView.Template.drawButton(paper, this.consCenter, size).addClass("svg__button--cons");
            EnergyMonitorBattAnimationView.Template.drawButton(paper, this.gridCenter, size).addClass("svg__button--grid");
            EnergyMonitorBattAnimationView.Template.drawButton(paper, this.battCenter, size).addClass("svg__button--batt");
            this.elements = {};
            this.elements.gridButton = this.element.find(".svg__button--grid");
            this.elements.battButton = this.element.find(".svg__button--batt");
            this.elements.prodButton = this.element.find(".svg__button--prod");
            this.elements.consButton = this.element.find(".svg__button--cons");
            this.buttons = {};
            this.buttons.grid = new GUI.LxButton(this, this.elements.gridButton[0], null, null, false);
            this.buttons.batt = new GUI.LxButton(this, this.elements.battButton[0], null, null, false);
            this.buttons.prod = new GUI.LxButton(this, this.elements.prodButton[0], null, null, false);
            this.buttons.cons = new GUI.LxButton(this, this.elements.consButton[0], null, null, false); // default active color is green --> set to glow color

            var transpColor = Color.STATE_INACTIVE;
            this.buttons.prod.setActiveColor(transpColor);
            this.buttons.cons.setActiveColor(transpColor);
            this.buttons.batt.setActiveColor(transpColor);
            this.buttons.grid.setActiveColor(transpColor);
            this.addToHandledSubviews(this.buttons.grid);
            this.addToHandledSubviews(this.buttons.batt);
            this.addToHandledSubviews(this.buttons.cons);
            this.addToHandledSubviews(this.buttons.prod);
        }

        /**
         * Will create the labels next to the nodes along with a predefined description
         * @param paper
         * @param size
         * @private
         */
        _prepareLabels(paper, size) {
            var textAnchor,
                nodeType,
                labelPos,
                title,
                nodeRadius = size.outerDiameter / 2,
                valueHeight = size.fontSizeMedium,
                descrHeight = size.fontSizeSmall,
                textSpacing = size.textSpacing,
                textHeight = valueHeight + descrHeight + textSpacing,
                nodeTxtOffset = textSpacing * 2;
            this.labels = {};
            title = _("controls.fronius.production");
            textAnchor = 'start';
            nodeType = NodeType.PROD;
            labelPos = EnergyMonitorBattAnimationView.Template.p(this.prodCenter.x - nodeRadius, this.prodCenter.y + (nodeRadius + textHeight + nodeTxtOffset));
            this.labels[nodeType] = EnergyMonitorBattAnimationView.Template.drawNodeLabels(paper, nodeType, labelPos, title, textAnchor, size);
            title = _("controls.fronius.consumption");
            textAnchor = 'end';
            nodeType = NodeType.CONS;
            labelPos = EnergyMonitorBattAnimationView.Template.p(this.consCenter.x + nodeRadius, labelPos.y);
            this.labels[nodeType] = EnergyMonitorBattAnimationView.Template.drawNodeLabels(paper, nodeType, labelPos, title, textAnchor, size);
            title = _("controls.energy-monitor.grid");
            textAnchor = 'middle';
            nodeType = NodeType.GRID;
            labelPos = EnergyMonitorBattAnimationView.Template.p(this.gridCenter.x, this.gridCenter.y - (nodeRadius + nodeTxtOffset));
            this.labels[nodeType] = EnergyMonitorBattAnimationView.Template.drawNodeLabels(paper, nodeType, labelPos, title, textAnchor, size);
            title = "";
            nodeType = NodeType.BATT;
            labelPos = EnergyMonitorBattAnimationView.Template.p(this.battCenter.x, this.battCenter.y + nodeRadius + nodeTxtOffset + textHeight);
            this.labels[nodeType] = EnergyMonitorBattAnimationView.Template.drawNodeLabels(paper, nodeType, labelPos, title, textAnchor, size);
        }

        /**
         * This is being called each time a value changes. It'll update the animation based on the new data.
         * @param currProduction    the current production in kW
         * @param currConsumption   the current consumption in kW
         * @param isOffline         if the connected inverter is on or offline
         * @param currGrid          current grid usage in kW (<0 = exporting)
         * @param currBattery       current battery usage in kW (<0 = charging)
         * @param currCharge        battery charge in %
         */
        update(currProduction, currConsumption, isOffline, inStandby, currGrid, currBattery, currCharge) {
            var validation = currProduction + currBattery + currGrid - currConsumption;

            if (validation !== 0) {
                console.error("The equation must result in a 0. the result is " + validation);
            }

            this.producing = currProduction > 0;
            this.consuming = currConsumption > 0;
            this.battActive = currBattery !== 0;
            this.gridActive = currGrid !== 0;
            this.currProduction = currProduction;
            this.currConsumption = currConsumption;
            this.currBattery = currBattery;
            this.currCharge = currCharge;
            this.currGrid = currGrid;
            this.isOffline = isOffline;
            this.inStandby = inStandby;

            this._computeFlowPercentages(); // adjust the nodes colors via css classes


            this.element.toggleClass("energy-monitor-visualisation--grid", this.gridActive);
            this.element.toggleClass("energy-monitor-visualisation--batt", this.battActive);
            this.element.toggleClass("energy-monitor-visualisation--cons", this.consuming);
            this.element.toggleClass("energy-monitor-visualisation--prod", this.producing);
            this.element.toggleClass("energy-monitor-visualisation--exporting", this.exporting > 0);
            this.element.toggleClass("energy-monitor-visualisation--charging-ext", this.chargingExt > 0);
            this.element.toggleClass("energy-monitor-visualisation--consuming-ext", this.consumingExt > 0);
            this.element.toggleClass("energy-monitor-visualisation--consuming-batt", this.consumingBatt > 0);
            this.element.toggleClass("energy-monitor-visualisation--consuming-prod", this.consumingProd > 0);
            this.element.toggleClass("energy-monitor-visualisation--charging-prod", this.chargingProd > 0);

            this._updateNodeTexts();

            this._updateNodeCircles(this.size); // bring the buttons back to the front by appending them again. z-index is useless in svg.


            this.svgElem[0].appendChild(this.buttons.grid.getElement()[0]);
            this.svgElem[0].appendChild(this.buttons.batt.getElement()[0]);
            this.svgElem[0].appendChild(this.buttons.prod.getElement()[0]);
            this.svgElem[0].appendChild(this.buttons.cons.getElement()[0]);
        }

        /**
         * Will compute percentages on how the produced energy is distrubted and from where the consumed power is
         * being supplied.
         * @private
         */
        _computeFlowPercentages() {
            var consGrid,
                consBatt,
                consProd,
                prodGrid,
                prodCons,
                prodBatt,
                factor,
                chargingValue = this.currBattery < 0 ? this.currBattery * -1 : 0; // how is the consumption supplied for?

            if (this.consuming) {
                consGrid = this.currGrid > 0 ? this.currGrid / this.currConsumption : 0;
                consBatt = this.currBattery > 0 ? this.currBattery / this.currConsumption : 0;
                consProd = this.currProduction / this.currConsumption;
                factor = 1 / (consGrid + consBatt + consProd); // total should be 1 - but isn't

                this.consumingExt = consGrid * factor;
                this.consumingBatt = consBatt * factor;
                this.consumingProd = consProd * factor;
            } else {
                this.consumingExt = 0;
                this.consumingBatt = 0;
                this.consumingProd = 0;
            } // how is the production used?


            if (this.producing) {
                prodGrid = this.currGrid < 0 ? Math.abs(this.currGrid) / this.currProduction : 0;
                prodBatt = this.currBattery < 0 ? Math.abs(this.currBattery) / this.currProduction : 0;
                prodCons = this.currConsumption / this.currProduction;
                factor = 1 / (prodGrid + prodBatt + prodCons); // total should be 1 - but isn't

                this.exporting = prodGrid * factor;
                this.chargingProd = prodBatt * factor;
            } else {
                this.chargingProd = 0;
                this.exporting = 0;
            } // only show charging external if the production is lower than the charging level.


            if (this.currBattery < 0 && this.currBattery + this.currProduction < 0) {
                this.chargingExt = Math.abs(this.currBattery + this.currProduction) / chargingValue;
            } else {
                this.chargingExt = 0;
            }
        }

        /**
         * Updates the text of all nodes (value, description)
         * @private
         */
        _updateNodeTexts() {
            // update the texsts
            var battDescr,
                battArg = {
                    charge: this.currCharge + "%"
                },
                gridDescr; // Grid descr

            if (this.gridActive) {
                gridDescr = this.exporting ? _("controls.fronius.export") : _("controls.fronius.import");
            } else {
                gridDescr = _("controls.energy-monitor.grid");
            } // Battery descr


            if (this.chargingExt || this.chargingProd) {
                battDescr = _("controls.energy-monitor.charging-battery", battArg);
            } else if (this.currBattery > 0) {
                battDescr = _("controls.energy-monitor.using-battery", battArg);
            } else {
                battDescr = _("controls.energy-monitor.battery-charge", battArg);
            }

            this._updateNodeText(NodeType.CONS, Math.abs(this.currConsumption));

            this._updateNodeText(NodeType.GRID, Math.abs(this.currGrid), gridDescr);

            if (this.isOffline && !this.inStandby) {
                this._updateNodeText(NodeType.PROD, Math.abs(this.currProduction), _("offline"));

                this._updateNodeText(NodeType.BATT, Math.abs(this.currBattery), _("offline"));
            } else {
                this._updateNodeText(NodeType.PROD, Math.abs(this.currProduction));

                this._updateNodeText(NodeType.BATT, Math.abs(this.currBattery), battDescr);
            }
        }

        /**
         * Updates the text of a single node.
         * @param nodeType
         * @param value
         * @param descr
         * @private
         */
        _updateNodeText(nodeType, value, descr) {
            var inputFormat = "%.2f kW";
            var splitTexts = lxUnitConverter.convertSplitAndApply(inputFormat, value);
            this.labels[nodeType].value.textContent = splitTexts.valueTxt;
            this.labels[nodeType].unit.textContent = splitTexts.succTxt;

            if (descr) {
                this.labels[nodeType].desc.textContent = descr;
            }
        }

        /**
         * Updates the circles around the production and the consumption nodes.
         * @private
         */
        _updateNodeCircles() {
            var consGrid = this.consumingExt,
                consBatt = this.consumingBatt,
                consProd = this.consumingProd; // consumption node

            this._updateNodeCircle(this.consNode, this.consNodeGrid, NodeType.GRID, consGrid, 0);

            this._updateNodeCircle(this.consNode, this.consNodeBatt, NodeType.BATT, consBatt, consProd + consGrid);

            this.consNode.toggleClass("svg__node-circle--active", this.consuming);
        }

        /**
         * Adopts the circle of one flowType for a node.
         * @param node      the node (production or consumption)
         * @param elem      the circle element (shown or hidden)
         * @param flowType  the flowtype of the circle to adopt.
         * @param val       the current value in percent (0..1)
         * @param offSetVal since one circle has several flows we use an offset
         * @private
         */
        _updateNodeCircle(node, elem, flowType, val, offSetVal) {
            if (val === 0) {
                elem.hide();
            } else if (val === 1) {
                elem.show();
                node[flowType].attr({});
            } else {
                elem.show();
                var totalLen = node[flowType].getTotalLength(),
                    offsetLength = Math.min(offSetVal * totalLen, totalLen),
                    strokeLength = val * totalLen; // the idea is to set a dash pattern that will start with an offset, then show a dash that matches
                // the values percentage of the circle followed by a blank that is so long that no other dash is shown.

                node[flowType].attr({
                    // dash blank dash blank
                    strokeDasharray: '0 ' + offsetLength + ' ' + strokeLength + ' ' + totalLen
                });
            }
        }

        /**
         * Responds to the button taps from the different buttons & calls registered delegate methods
         * if there are any.
         * @param src   what button was tapped
         * @private
         */
        _handleButtonTap(src) {
            switch (src) {
                case this.buttons.grid:
                    this.onGridTapped && this.onGridTapped();
                    break;

                case this.buttons.batt:
                    this.onBatteryTapped && this.onBatteryTapped();
                    break;

                case this.buttons.cons:
                    this.onConsumptionTapped && this.onConsumptionTapped();
                    break;

                case this.buttons.prod:
                    this.onProductionTapped && this.onProductionTapped();
                    break;
            }
        }

    };
});
