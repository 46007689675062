'use strict';

define("GateControl", [
    "Control",
    "GateControlEnums",
    "IconLib"
], function (
    Control,
    GateControlEnums,
    {
        default: Icons
    }
) {
    return class GateControl extends Control {
        constructor() {
            super(...arguments);
        }

        getCellType(forCentral) {
            return forCentral ? GUI.TableViewV2.CellType.GATE : ControlCard.BASE;
        }

        getButton0() {
            return {
                iconSrc: this.getOpenIcon(true),
                reactIcon: this.getReactOpenIcon(),
                command: Commands.GATE.OPEN
            };
        }

        getButton1() {
            return {
                iconSrc: this.getCloseIcon(true),
                reactIcon: this.getReactCloseIcon(),
                command: Commands.GATE.CLOSE
            };
        }

        getReactOpenIcon() {
            if (this.isUpDownDoor()) {
                return Icons.ArrowUp;
            } else {
                return Icons.Open;
            }
        }

        getReactCloseIcon() {
            if (this.isUpDownDoor()) {
                return Icons.ArrowDown;
            } else {
                return Icons.Close;
            }
        }

        getOpenIcon(circled) {
            if (this.isUpDownDoor()) {
                return circled ? Icon.Gate.UP : Icon.Jalousie.Arrows.UP;
            } else {
                return circled ? Icon.Gate.Circled.DOUBLE_OPEN : Icon.Gate.Arrows.DOUBLE_OPEN;
            }
        }

        getCloseIcon(circled) {
            if (this.isUpDownDoor()) {
                return circled ? Icon.Gate.DOWN : Icon.Jalousie.Arrows.DOWN;
            } else {
                return circled ? Icon.Gate.Circled.DOUBLE_CLOSE : Icon.Gate.Arrows.DOUBLE_CLOSE;
            }
        }

        isUpDownDoor() {
            return this.details.animation === GateControlEnums.GateAnimationType.GARAGE_DOOR;
        }

        isTurnDoorLeft() {
            return this.details.animation === GateControlEnums.GateAnimationType.TURN_DOOR_LEFT;
        }

        isTurnDoorRight() {
            return this.details.animation === GateControlEnums.GateAnimationType.TURN_DOOR_RIGHT;
        }

        isTurnDoorDouble() {
            return this.details.animation === GateControlEnums.GateAnimationType.TURN_DOOR_DOUBLE;
        }

        isSectionalDoorLeft() {
            return this.details.animation === GateControlEnums.GateAnimationType.SECTIONAL_DOOR_LEFT;
        }

        isSectionalDoorRight() {
            return this.details.animation === GateControlEnums.GateAnimationType.SECTIONAL_DOOR_RIGHT;
        }

        getFriendlyValueName(event, operator) {
            var result = "";

            switch (event.data.stateName) {
                case "position":
                    if (event.value === 1 && event.operator === 1) {
                        result = this.name + " " + _("controls.jalousie.up.full").toLowerCase();
                    } else if (event.value === 0 && event.operator === 1) {
                        result = this.name + " " + _("controls.jalousie.curtain.down.full").toLowerCase();
                    } else {
                        result = this.name + " " + event.data.stateTitle + " " + operator + " " + (event.value * 100.0).toFixed(0) + " %";
                    }

                    break;

                default:
                    result = "";
            }

            return result;
        }

    };
});
