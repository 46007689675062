'use strict';

define(['TempListValueEditor', 'IRoomControlEnums'], function (TempListValueEditor, IRoomControlEnums) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var TemperatureModesTemplate = function () {
        var getTemplate = function getTemplate(ctrl) {
            return createComfortTemperatureArea(ctrl) + createScrollArea(ctrl);
        };

        var createComfortTemperatureArea = function createComfortTemperatureArea(ctrl) {
            return '<div class="comfort-temp-area">' + '<div class="comfort__header">' + ImageBox.getResourceImageWithClasses('resources/Images/Controls/IRoom/cooling.svg', 'header__icon cooling') + '<div class="header__label cooling">' + _("controls.irc.temperatures.colling-mode") + '</div>' + '<div class="header__label heating">' + _("controls.irc.temperatures.heating-mode") + '</div>' + ImageBox.getResourceImageWithClasses('resources/Images/Controls/IRoom/heat-protection-temperature.svg', 'header__icon heating') + '</div>' + '<div id="temp-mode-' + IRoomControlEnums.IRCTempMode.COMFORT_HEATING + '" class="comfort__container">' + '<div class="container__title">' + '<div class="title__text">' + _("controls.irc.temp.comfort") + '</div>' + ImageBox.getResourceImageWithClasses('resources/Images/General/disclosure-icon.svg', 'title__disclosure-icon') + '</div>' + ImageBox.getResourceImageWithClasses(Icon.IRC.Temp.COMFORT, 'container__icon') + createSplitValueLabel('container__value-label value-label--cooling') + createSplitValueLabel('container__value-label value-label--heating') + '<svg class="temp-list-line container__line cooling">' + '<line x1="2" x2="2" y1="2" y2="38"/>' + '</svg>' + '<svg class="temp-list-line container__line heating">' + '<line x1="2" x2="2" y1="2" y2="38"/>' + '</svg>' + '</div>' + '</div>';
        };

        var createScrollArea = function createScrollArea(ctrl) {
            return '<div class="scroll-area">' + '<div class="relative-temp__header">' + ImageBox.getResourceImageWithClasses('resources/Images/Controls/IRoom/temp-adjustment.svg', 'header__icon') + '<div class="header__title">' + _("controls.irc.temperatures.comfort-adjustments") + '</div>' + '</div>' + createRelativeTempContainer(ctrl, IRoomControlEnums.IRCTempMode.ECONOMY) + createRelativeTempContainer(ctrl, IRoomControlEnums.IRCTempMode.PARTY) + createRelativeTempContainer(ctrl, IRoomControlEnums.IRCTempMode.INCREASED_HEAT) + '<svg class="temp-list-line scroll-area__line cooling">' + '<line x1="2" x2="2" y1="2" y2="390"/>' + '</svg>' + '<svg class="temp-list-line scroll-area__line heating">' + '<line x1="2" x2="2" y1="2" y2="390"/>' + '</svg>' + '<div class="absolute-temp__header">' + ImageBox.getResourceImageWithClasses('resources/Images/Controls/IRoom/shield.svg', 'header__icon') + '<div class="header__title">' + _("controls.irc.temperatures.freeze-heat-protection") + '</div>' + '</div>' + createAbsoluteTempContainer(ctrl, IRoomControlEnums.IRCTempMode.EMPTY_HOUSE) + createAbsoluteTempContainer(ctrl, IRoomControlEnums.IRCTempMode.HEAT_PROTECTION) + '</div>';
        };

        var createRelativeTempContainer = function createRelativeTempContainer(ctrl, tempModeNr) {
            return '<div id="temp-mode-' + tempModeNr + '" class="relative-temp__container">' + '<div class="container__title">' + '<div class="title__text">' + ctrl.getNameOfIRCTempMode(tempModeNr) + '</div>' + ImageBox.getResourceImageWithClasses('resources/Images/General/disclosure-icon.svg', 'title__disclosure-icon') + '</div>' + ImageBox.getResourceImageWithClasses(ctrl.getIconOfIRCTempMode(tempModeNr), 'container__icon') + '<div class="container__relative-value"></div>' + createSplitValueLabel('container__value-label value-label--cooling') + createSplitValueLabel('container__value-label value-label--heating') + '<svg class="temp-list-line container__line cooling">' + '<line x1="2" x2="18" y1="2" y2="2"/>' + '</svg>' + '<svg class="temp-list-line container__line heating">' + '<line x1="2" x2="18" y1="2" y2="2"/>' + '</svg>' + '</div>';
        };

        var createAbsoluteTempContainer = function createAbsoluteTempContainer(ctrl, tempModeNr) {
            return '<div id="temp-mode-' + tempModeNr + '" class="absolute-temp__container">' + '<div class="container__title">' + '<div class="title__text">' + ctrl.getNameOfIRCTempMode(tempModeNr) + '</div>' + ImageBox.getResourceImageWithClasses('resources/Images/General/disclosure-icon.svg', 'title__disclosure-icon') + '</div>' + '<div class="container__middle-container">' + ImageBox.getResourceImageWithClasses(ctrl.getIconOfIRCTempMode(tempModeNr), 'container__icon') + createSplitValueLabel('container__value-label') + '</div>' + '</div>';
        };

        var createSplitValueLabel = function createSplitValueLabel(className) {
            return '<div class="' + className + '">' + '<span class="label__integer"></span>' + '<span class="label__float"></span>' + '<span class="label__unit"></span>' + '</div>';
        };

        return {
            getTemplate: getTemplate
        };
    }();

    return class IRoomControlContentTemperatureList extends Controls.ControlContent {
        constructor(details) {
            super(details);
            this.shouldOpenTemperature = details.hasOwnProperty("openTemperature");
            this.openTemperature = details.openTemperature;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            var temperatureListElements = $(TemperatureModesTemplate.getTemplate(this.control));
            var comfortContainer = temperatureListElements.find('.comfort__container');
            var relativeContainers = temperatureListElements.find('.relative-temp__container');
            var absoluteContainers = temperatureListElements.find('.absolute-temp__container');
            this.elements = {
                comfortHeating: this._getSplitLabelsOfElem(comfortContainer.find('.value-label--heating')),
                comfortCooling: this._getSplitLabelsOfElem(comfortContainer.find('.value-label--cooling')),
                economyHeating: this._getSplitLabelsOfElem($(relativeContainers[0]).find('.value-label--heating')),
                economyCooling: this._getSplitLabelsOfElem($(relativeContainers[0]).find('.value-label--cooling')),
                partyHeating: this._getSplitLabelsOfElem($(relativeContainers[1]).find('.value-label--heating')),
                partyCooling: this._getSplitLabelsOfElem($(relativeContainers[1]).find('.value-label--cooling')),
                increasedHeatHeating: this._getSplitLabelsOfElem($(relativeContainers[2]).find('.value-label--heating')),
                increasedHeatCooling: this._getSplitLabelsOfElem($(relativeContainers[2]).find('.value-label--cooling')),
                emptyHouse: this._getSplitLabelsOfElem($(absoluteContainers[0]).find('.container__value-label')),
                heatProtection: this._getSplitLabelsOfElem($(absoluteContainers[1]).find('.container__value-label'))
            };
            comfortContainer.find('.value-label--heating').addClass('heating');
            comfortContainer.find('.value-label--cooling').addClass('cooling');
            relativeContainers.find('.value-label--heating').addClass('heating');
            relativeContainers.find('.value-label--cooling').addClass('cooling');
            this.elements.economyRelative = $(relativeContainers[0]).children('.container__relative-value');
            this.elements.partyRelative = $(relativeContainers[1]).children('.container__relative-value');
            this.elements.increasedHeatRelative = $(relativeContainers[2]).children('.container__relative-value');
            this.element.append(temperatureListElements);
            this.eventHandlers = [Hammer(comfortContainer[0])];
            var i, modeNr;

            for (i = 0; i < relativeContainers.length; i++) {
                modeNr = parseInt(relativeContainers[i].id.replace('temp-mode-', ''));
                $(relativeContainers[i]).find('.container__icon').css('fill', this.control.getColorOfIRCTempMode(modeNr));
                $(relativeContainers[i]).find('.container__relative-value').css('color', this.control.getColorOfIRCTempMode(modeNr));
                this.eventHandlers.push(Hammer(relativeContainers[i]));
            }

            for (i = 0; i < absoluteContainers.length; i++) {
                modeNr = parseInt(absoluteContainers[i].id.replace('temp-mode-', ''));
                $(absoluteContainers[i]).find('.container__icon').css('fill', this.control.getColorOfIRCTempMode(modeNr));
                $(absoluteContainers[i]).find('.container__value-label').css('color', this.control.getColorOfIRCTempMode(modeNr));
                this.eventHandlers.push(Hammer(absoluteContainers[i]));
            }

            this.element.toggleClass('i-room-control-content-temperature-list--hd', HD_APP);
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);

            for (var i = 0; i < this.eventHandlers.length; i++) {
                this.eventHandlers[i].on(tapEvent(), this._handleContainerTap.bind(this));
            }

            if (this.shouldOpenTemperature) {
                this.shouldOpenTemperature = false;

                this._openTempEditor(this.openTemperature);
            }
        }

        viewDidDisappear(viewRemainsVisible) {
            for (var i = 0; i < this.eventHandlers.length; i++) {
                this.eventHandlers[i].dispose();
            }

            super.viewDidDisappear(viewRemainsVisible);
        }

        destroy() {
            super.destroy();
        }

        getURL() {
            return "TemperatureList";
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarText() {
            return _("controls.irc.temperatures");
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK,
                style: 'transparent'
            };
        }

        receivedStates(states) {
            var targetValueDetails, targetValueDetailsCooling, relative;

            for (var tempModeNr in states.temperatures) {
                if (states.temperatures.hasOwnProperty(tempModeNr)) {
                    tempModeNr = parseInt(tempModeNr);

                    if (tempModeNr === IRoomControlEnums.IRCTempMode.COMFORT_HEATING || tempModeNr === IRoomControlEnums.IRCTempMode.COMFORT_COOLING || tempModeNr === IRoomControlEnums.IRCTempMode.EMPTY_HOUSE || tempModeNr === IRoomControlEnums.IRCTempMode.HEAT_PROTECTION) {
                        targetValueDetails = getDetailValuesFromTemperatureFormat(lxFormat(this.control.details.format, states.temperatures[tempModeNr]));

                        if (tempModeNr === IRoomControlEnums.IRCTempMode.COMFORT_HEATING) {
                            this._setSplitLabelValues(this.elements.comfortHeating, targetValueDetails);
                        } else if (tempModeNr === IRoomControlEnums.IRCTempMode.COMFORT_COOLING) {
                            this._setSplitLabelValues(this.elements.comfortCooling, targetValueDetails);
                        } else if (tempModeNr === IRoomControlEnums.IRCTempMode.EMPTY_HOUSE) {
                            this._setSplitLabelValues(this.elements.emptyHouse, targetValueDetails);
                        } else if (tempModeNr === IRoomControlEnums.IRCTempMode.HEAT_PROTECTION) {
                            this._setSplitLabelValues(this.elements.heatProtection, targetValueDetails);
                        }
                    } else if (tempModeNr === IRoomControlEnums.IRCTempMode.ECONOMY || tempModeNr === IRoomControlEnums.IRCTempMode.PARTY || tempModeNr === IRoomControlEnums.IRCTempMode.INCREASED_HEAT) {
                        targetValueDetails = getDetailValuesFromTemperatureFormat(lxFormat(this.control.details.format, states.temperatures[tempModeNr].heating));
                        targetValueDetailsCooling = getDetailValuesFromTemperatureFormat(lxFormat(this.control.details.format, states.temperatures[tempModeNr].cooling));
                        relative = lxFormat(this.control.details.format, states.temperatures[tempModeNr].offset);

                        if (tempModeNr === IRoomControlEnums.IRCTempMode.ECONOMY) {
                            this._setSplitLabelValues(this.elements.economyHeating, targetValueDetails);

                            this._setSplitLabelValues(this.elements.economyCooling, targetValueDetailsCooling);

                            this.elements.economyRelative.html('( &plusmn ' + relative + ')');
                        } else if (tempModeNr === IRoomControlEnums.IRCTempMode.PARTY) {
                            this._setSplitLabelValues(this.elements.partyHeating, targetValueDetails);

                            this._setSplitLabelValues(this.elements.partyCooling, targetValueDetailsCooling);

                            this.elements.partyRelative.text('( - ' + relative + ')');
                        } else if (tempModeNr === IRoomControlEnums.IRCTempMode.INCREASED_HEAT) {
                            this._setSplitLabelValues(this.elements.increasedHeatHeating, targetValueDetails);

                            this._setSplitLabelValues(this.elements.increasedHeatCooling, targetValueDetailsCooling);

                            this.elements.increasedHeatRelative.text('( + ' + relative + ')');
                        }
                    }
                }
            }

            this.showCooling = !(this.control.details.restrictedToMode === IRoomControlEnums.VisuType.HEATING && this.control.isHeating(states.controllerMode));
            var cooling = this.element.find(".cooling");
            cooling.toggle(this.showCooling);
            this.showHeating = !(this.control.details.restrictedToMode === IRoomControlEnums.VisuType.COOLING && this.control.isCooling(states.controllerMode));
            var heating = this.element.find(".heating");
            heating.toggle(this.showHeating);
            this.temperatures = states.temperatures;
        }

        // Private functions
        _getSplitLabelsOfElem(element) {
            var intLabel = element.children('.label__integer');
            var floatLabel = element.children('.label__float');
            var unitLabel = element.children('.label__unit');
            return {
                intLabel: intLabel,
                floatLabel: floatLabel,
                unitLabel: unitLabel
            };
        }

        _setSplitLabelValues(parent, values) {
            setDetailValuesFromTemperatureToLabels(values, parent.intLabel, parent.floatLabel, parent.unitLabel);
        }

        // Event listeners
        _handleContainerTap(event) {
            // TODO-woessto: show as overlay centered on the screen, adopt along with the audioZone "context" menue
            var tempModeNr = parseInt(event.currentTarget.id.replace('temp-mode-', ''));

            this._openTempEditor(tempModeNr);
        }

        _openTempEditor(tempModeNr) {
            this.valueEditor = new TempListValueEditor(this.control, tempModeNr, this.temperatures, this.control.details.format, this.showHeating, this.showCooling);
            this.appendSubview(this.valueEditor);
            this.valueEditor.closeWindowWithValue = this._removeValueEditor.bind(this);
        }

        _removeValueEditor(tempMode, value1, value2) {
            if (tempMode !== false) {
                if (tempMode === IRoomControlEnums.IRCTempMode.COMFORT_HEATING || tempMode === IRoomControlEnums.IRCTempMode.COMFORT_COOLING) {
                    this.sendCommand(Commands.format(Commands.I_ROOM_CONTROLLER.SET_TEMP_MODE, IRoomControlEnums.IRCTempMode.COMFORT_HEATING, value1));
                    this.sendCommand(Commands.format(Commands.I_ROOM_CONTROLLER.SET_TEMP_MODE, IRoomControlEnums.IRCTempMode.COMFORT_COOLING, value2));
                } else {
                    this.sendCommand(Commands.format(Commands.I_ROOM_CONTROLLER.SET_TEMP_MODE, tempMode, value1));
                }
            }

            this.valueEditor.closeWindowWithValue = null;
            this.removeSubview(this.valueEditor);
            this.valueEditor = null;
        }

    };
});
