'use strict';

window.GUI = function (GUI) {
    class ControlContentViewController extends GUI.ViewController {

        static allowMultiple = true;

        /**
         * ControlContentViewController is initialized once we have to show some ControlContent
         * by default, it'll show eg. the JalousieControlContent-View!
         * @param details
         * @constructor
         */
        constructor(details) {
            let control = ActiveMSComponent.getStructureManager().getControlByUUID(details.controlUUID); // call super

            var classSuffix = "control-content-view-controller";
            super($('<div class="' + classSuffix + ' ' + convertControlTypeToClassNameWithSuffix(control.controlType, classSuffix) + '" />'));
            Object.assign(this, ContextMenuHandler.Mixin, StateHandler.Mixin);
            this.control = control;
            this._didShowInitialView = false;

            if (details.contentIdentifier) {
                this._viewIdentifier = details.contentIdentifier;
            } else {
                this._viewIdentifier = this.control.getControlContentIdentifier();
            }

            if (details.hasOwnProperty("contentDetails")) {
                this._contentDetails = details.contentDetails;
            }

            if (details.hasOwnProperty("urlComps")) {
                this._contentDetails = this._contentDetails || {};
                this._contentDetails.urlComps = details.urlComps;
            }
        }

        viewDidLoad() {
            var vdlPrms = super.viewDidLoad(...arguments),
                rqDetailsPrms = !this._detailsReady() ? this._requestDetails() : true,
                rqContentDataPrms = this.control.prepareDataForContentScreen(); // e.g. for downloading the system scheme image

            return Q.all([vdlPrms, rqDetailsPrms, rqContentDataPrms]);
        }

        updateView(details) {
            // Just pass the information to the currentView, so it can be updated accordingly
            if (details && "urlComps" in details) {
                this.currentView.updateView({
                    urlComps: details.urlComps
                });
            }

            return super.updateView(...arguments);
        }

        viewWillAppear() {
            var all = [];

            if (!this._didShowInitialView) {
                this._didShowInitialView = true;

                if (HD_APP && !(this.ViewController instanceof GUI.ModalViewController) && !this._isIntercomFullscreenAlert()) {
                    this.element.addClass("control-content-view-controller--hd"); // add a dummy screen so that everything works correctly (HD_OVERLAY animation..)
                    // Account for the dummy screen when navigatingBackToRoot...

                    all.push(this._handleAddInitialDummy());
                }

                all.push(this._checkInitialView());
            }

            all.push(super.viewWillAppear(...arguments) || true);
            return Q.all(all);
        }

        viewDidAppear() {
            return super.viewDidAppear(...arguments).then(function () {
                this._registerForStates(this.control.uuidAction, this.getStateIDsForViewDetection());
            }.bind(this));
        }

        viewWillDisappear() {
            this._unregisterStates(this.control.uuidAction);

            return super.viewWillDisappear(...arguments);
        }

        /**
         * These state ids are used to trigger a detectAndShowView call.
         * @returns {[string]}
         */
        getStateIDsForViewDetection() {
            return ["universalIsLocked"];
        }

        getWaitingForMessage() {
            if (!this._detailsReady()) {
                return _("secured-details.loading", {
                    name: this.control.name
                });
            }
        }

        getWaitingForRetryMessage() {
            if (!this._detailsReady()) {
                return _("secured-details.error", {
                    name: this.control.name
                });
            }
        }

        getWaitingForRetryFunction() {
            var args = arguments; // getWaitingForRetry has to return a function

            return function () {
                if (!this._detailsReady) {
                    return this._requestDetails.apply(this, args);
                }
            }.bind(this);
        }

        navigateBack() {
            // check if we are HD and we did show a dummy view!
            if (HD_APP && this.history.length === 2 && !(this.ViewController instanceof GUI.ModalViewController)) {
                // this is the last (+ dummy) view, also navigate this.ViewController (parent VC) back!
                // this must be done to get the out animation!
                return super.navigateBack(...arguments).then(function () {
                    return this.ViewController.navigateBack();
                }.bind(this));
            } else {
                return super.navigateBack(...arguments);
            }
        }

        /**
         * Will navigate back until the root view of this viewController is visible.
         * Note: Override this method to account for the dummy view
         */
        navigateBackToRoot() {
            return super.navigateBackToRoot(+HD_APP); // Account for the dummy view +HD_APP will return 0 if false and 1 if true
        }

        // Overwritten ViewController methods
        approveAnimationType(animationType) {
            if (HD_APP) {
                if ((this._isIntercomFullscreenAlert() || !(this.ViewController instanceof GUI.ModalViewController)) && animationType === AnimationType.PUSH_OVERLAP_LEFT) {
                    animationType = AnimationType.HD_OVERLAY;
                }
            }

            return animationType;
        }

        getAnimation() {
            return AnimationType.FADE;
            /*if (this._isIntercomFullscreenAlert()) {
                return AnimationType.HD_MODAL_FULLSCREEN;
            } else if (this.alert) {
                return AnimationType.MODAL;
            } else if (HD_APP) {
                return AnimationType.OVERLAY;
            } else {
                return AnimationType.PUSH_OVERLAP_LEFT;
            }*/
        }

        getURL() {
            var url = (this.alert ? "ControlAlert" : "Control") + "/";

            for (var i = HD_APP ? 1 : 0; i < this.viewStack.length; i++) {
                var view = this.viewStack.viewAtIndex(i);

                if (view) {
                    url = url + view.getURL() + "/";
                }
            }

            this.currentView && (url = url + this.currentView.getURL());
            return url;
        }

        destroyOnBackNavigation() {
            return true;
        }

        navigateBackTo(id) {
            var idx = this.history.stack.indexOf(id); // find out if this identifier exists

            if (idx < 0) {
                var msg = "View to navigate back to (" + id + ") not found in this controlContentViewController!";
                console.error(msg);
                throw msg;
            }

            if (this.viewStack.stack.length + 1 !== this.history.length) {
                var msg = "navigateBackTo is not prepared for multiple history entries of an id that does not support multiple views";
                console.error(msg);
                throw msg;
            } // use the view-Stack-index, so the current view remains visible until the navigateBack call


            var i = this.viewStack.stack.length - 1;

            if (i >= 0) {
                if (i === 0 && this.viewStack.stack[i].id !== id) {// we're already at the view to dismiss, no other views are left!
                } else {
                    while (this.viewStack.stack[i].id !== id && i >= 0) {
                        // splice history
                        this.history.stack.splice(i, 1);
                        var view = this.viewStack.pop();
                        view.destroy();
                        i--;
                    }

                    this.navigateBack();
                }
            }
        }

        destroy() {
            if (this._deregMessageCenterUpdateReceiver) {
                this._deregMessageCenterUpdateReceiver();
            }

            this.control = null; // ensure that potentially parked states with their views are destroyed.

            this._destroyChangeStatePark();

            return super.destroy(...arguments);
        }

        /**
         * Checks if the controlContent has already been shown. If not, it will show it after making sure that all
         * the details for the content are ready. E.g. securedDetails are loaded now.
         * @private
         */
        _checkInitialView() {
            var def = Q.defer(); // only do this once!

            if (!this._initialViewLoaded) {
                // ensure the initial view can be shown. - if not present details first.
                def.resolve(this._presentInitialView());
            } else {
                def.resolve(true);
            }

            return def.promise.then(function () {
                this._initialViewLoaded = true;
            }.bind(this));
        }

        /**
         * Called when everything is ready and the control content can be shown.
         * @returns {*}
         * @private
         */
        _presentInitialView() {
            return this._detectAndShowView().then(function () {
                this._deregMessageCenterUpdateReceiver = ActiveMSComponent.registerSourceUuidForMessageCenterUpdate(this._messageCenterUpdateReceived.bind(this), this.control.uuidAction);
            }.bind(this));
        }

        _getViewForIdentifier(id, details) {
            // hook between, and provide control temporary
            if (!details) details = {};

            if (!details.control) {
                // if no control is given, use the "first" control
                details.control = this.control;
            }

            return super._getViewForIdentifier(id, details);
        }

        // Own methods
        showStatistics(output) {
            // use the ControlContentViewController
            this.showState(ScreenState.Statistic, null, {
                controlUUID: this.control.uuidAction,
                statisticOutputUUID: output.uuid
            });
        }

        // specific methods for controls:

        /**
         * Intercoms alerts are always shown as fullscreen alerts. It is no longer crucial if there is a video or
         * not. The new HD-design is always fullscreen.
         * @returns {*|boolean}
         * @private
         */
        _isIntercomFullscreenAlert() {
            return HD_APP && this.alert && this._isIntercomControl();
        }

        /**
         * checks if the current control is an Intercom
         * @returns {boolean}
         * @private
         */
        _isIntercomControl() {
            return this.control.controlType === ControlType.INTERCOM || this.control.controlType === ControlType.INTERCOM_GEN_2;
        }

        // ----------------------------------------------------------------------------------------------
        // ----------------------------------- SECURED DETAILS ------------------------------------------
        // ----------------------------------------------------------------------------------------------

        /**
         * Will return true if the details for this control are available. E.g. when secure details are in use for
         * an intercom but they have not yet been loaded - this will return false.
         * @returns {boolean}
         * @private
         */
        _detailsReady() {
            var ready = true;

            if (this.control.securedDetails === true) {
                // use get instead of load, as get returns immediately with the details if they exist or returns
                // false if they haven't been loaded yet.
                ready = ActiveMSComponent.getSecuredDetailsFor(this.control.uuidAction) !== false;
            }

            return ready;
        }

        /**
         * Will start a request to acquire the details for the current control
         * @returns {*} promise that resolves once the details are ready and rejects if loading fails.
         * @private
         */
        _requestDetails() {
            return ActiveMSComponent.loadSecuredDetailsFor(this.control.uuidAction, this._isIntercomControl());
        }

        _messageCenterUpdateReceived(ev, entries, sourceUuid) {
            Debug.GUI.ControlContentViewController && console.log(this.viewId, "_messageCenterUpdateReceived"); // Navigate to the specific message for this control if the first entry locks the visu

            this._detectAndShowView();
        }

        // ----------------------------------------------------------------------------------------------
        // ----------------------------------- New Handling ------------------------------------------
        // ----------------------------------------------------------------------------------------------

        /**
         * This method is called whenever the relevant states (see getStateIDsForViewDetection) are changed, or
         * a system status that blocks the visu is received or acknowledged.
         * @returns {*}
         * @private
         */
        _detectAndShowView() {
            Debug.GUI.ControlContentViewController && console.log(this.viewId, "_detectAndShowView");
            var activeMCEntries = MessageCenterHelper.findActiveEntriesWithSourceUuid(this.control.uuidAction),
                promise = null;

            if (activeMCEntries.length && activeMCEntries[0].isVisuLocked) {
                Debug.GUI.ControlContentViewController && console.log(this.viewId, "   > show systemstatus");
                promise = this._showIfNotVisibleAlready(ScreenState.MessageCenterMessageScreen, {
                    entry: activeMCEntries[0]
                }, AnimationType.NONE);
            } else if (this.control.getStates().universalIsLocked) {
                Debug.GUI.ControlContentViewController && console.warn(this.viewId, "  > should be showing lock screen instead!");
                promise = this._showIfNotVisibleAlready(ScreenState.ControlContentLockedScreen, {
                    control: this.control
                });
            } else {
                Debug.GUI.ControlContentViewController && console.log(this.viewId, "   > show controlContent");
                promise = this._showControlContent();
            }

            return promise;
        }

        /**
         * This method can be overwritten by subclasses if they have additional conditions - aside from system status
         * or locked screens - that may require something else than the control content to be shown.
         * @returns {*}
         * @private
         */
        _showControlContent() {
            var _viewIdentifier = this._viewIdentifier,
                _contentDetails = this._contentDetails; // identifier, view, details, animationType

            var animationType; // -> ask the View
            // handle "controls in controls" eg. tracker in alarm

            if (this.ViewController instanceof GUI.ModalViewController || this._isIntercomFullscreenAlert()) {
                animationType = AnimationType.NONE; // the animation is done with the ControlContentViewController or ModalViewController
            }

            return this._showIfNotVisibleAlready(_viewIdentifier, _contentDetails, animationType);
        }

        /**
         * The controlContentViewController has a set of states that may require changing the screens shown. E.g.
         * when a control is locked, the whole screen is replaced by a locked screen.
         * @param states
         */
        receivedStates(states) {
            var stateIdsToCheck = this.getStateIDsForViewDetection(),
                needsRedetect = false;
            this._previousStates = this._previousStates || {};
            stateIdsToCheck.forEach(function (stateId) {
                needsRedetect = needsRedetect || this._previousStates[stateId] !== states[stateId];
                this._previousStates[stateId] = states[stateId];
            }.bind(this));

            if (needsRedetect) {
                Debug.GUI.ControlContentViewController && console.log(this.viewId, "receivedStates: view-relevant states changed, detect proper view!", this._previousStates);

                this._detectAndShowView(); // ensures the proper view is shown.

            }
        }

        /**
         * In order to make things easier, this method is used instaed of showState when the viewController needs to
         * respond to state changes by changing from one state to another (e.g. controlContent -> lockedScreen).
         * It looks through the history and if the target screenState is already there, it doesn't modify anything.
         * Otherwise it'll change the states and park the old content as it may need to be retrieved later.
         * @param screenState
         * @param details
         * @param animation
         * @returns {Q.Promise<unknown>|*}
         * @private
         */
        _showIfNotVisibleAlready(screenState, details, animation) {
            if (this._exchangeInProgress) {
                // wait for previous operations to finish.
                Debug.GUI.ControlContentViewController && console.warn(this.viewId, "_showIfNotVisibleAlready - exchange in progress, enqueue!");
                return this._addToQueue("_showIfNotVisibleAlreadyAfterTO", arguments);
            }

            Debug.GUI.ControlContentViewController && console.log(this.viewId, "_showIfNotVisibleAlready: " + screenState + ", current=" + this.history.current(), getStackObj());

            var visible = this._isInHistory(screenState);

            if (!visible) {
                Debug.GUI.ControlContentViewController && console.log(this.viewId, "    not visible, show: " + screenState);
                return this._changeToState(screenState, details, animation);
            } else {
                Debug.GUI.ControlContentViewController && console.log(this.viewId, "    already visible");
                return Q.resolve();
            }
        }

        /**
         * Allow 100ms between the previous and the next operation, so the history can be updated too.
         * @returns {Q.Promise<unknown>}
         * @private
         */
        _showIfNotVisibleAlreadyAfterTO() {
            var def = Q.defer(),
                args = arguments;
            setTimeout(function () {
                def.resolve(this._showIfNotVisibleAlready.apply(this, args));
            }.bind(this), 50);
            return def.promise;
        }

        /**
         * Looks through the history to check if the state is already in there.
         * @param screenState
         * @returns {boolean}
         * @private
         */
        _isInHistory(screenState) {
            return this.history.indexOf(screenState) >= 0;
        }

        /**
         * This is not equal to showState, as it keeps the old state (including potential views) in a "parked" state
         * to allow changing back to them.
         * @param screenState
         * @param details
         * @param animation
         * @returns {*}
         * @private
         */
        _changeToState(screenState, details, animation) {
            var previousState = this.history.current(),
                promise = null;
            Debug.GUI.ControlContentViewController && console.log(this.viewId, "_changeToState: From: " + previousState + " -> To: " + screenState);

            if (this._hasDataParkedForChangeState(screenState)) {
                Debug.GUI.ControlContentViewController && console.log(this.viewId, "   > _changeToState - unpark");
                promise = this._unparkStateObject(screenState);
            } else {
                Debug.GUI.ControlContentViewController && console.log(this.viewId, "   > _changeToState - show state");
                promise = super.showState(screenState, null, details, animation);
            }

            this._changeToStatePromise = promise.then(function () {
                Debug.GUI.ControlContentViewController && console.log(this.viewId, "     > change to state of " + screenState + " passed");

                if (previousState) {
                    Debug.GUI.ControlContentViewController && console.log(this.viewId, "     > new content visible, park old history & viewStack of " + previousState);

                    this._parkHistoryAsStateObject(previousState);
                } else {
                    Debug.GUI.ControlContentViewController && console.log(this.viewId, "    > no previous state to clean up!");
                }

                return this.handleStateShown(screenState, this.history.current()).then(function () {
                    Debug.GUI.ControlContentViewController && this._printPark();
                }.bind(this));
            }.bind(this));
            return this._changeToStatePromise;
        }

        /**
         * Overwritten from viewController - should not be performed as long as history isn't up to date after
         * changeToState.
         * @private
         */
        _processQueue() {
            if (this._changeToStatePromise && this._changeToStatePromise.isPending()) {
                Debug.GUI.ControlContentViewController && console.warn(this.viewId, "_processQueue - changeToState still pending, wait");
                return this._changeToStatePromise.then(this._processQueue.bind(this, arguments));
            } else {
                Debug.GUI.ControlContentViewController && console.log(this.viewId, "_processQueue - changeToState not active, proceed");
                return super._processQueue(...arguments);
            }
        }

        /**
         * This method allows subclasses to respond to changeStateCalls - e.g. the audioZoneV2ViewController will
         * ensure that the miniPlayer is or is not shown - depending on the state.
         * @param screenState
         * @param visibleState
         * @returns {Q.Promise<unknown>}
         */
        handleStateShown(screenState, visibleState) {
            // nothing to do, but maybe in the subclass?
            return Q.resolve();
        }

        // ------------------------------------------------------------------------------------------------
        // parking an entire history
        // ------------------------------------------------------------------------------------------------

        /**
         * Will slice everything from the history & viewstack that is shown "underneath" the oldScreenState and
         * store it for restoring it later using _unparkStateObject.
         * @param oldScreenState
         * @private
         */
        _parkHistoryAsStateObject(oldScreenState) {
            Debug.GUI.ControlContentViewController && console.log(this.viewId, "_parkHistoryAsStateObject: " + oldScreenState); // slice everything out from the viewStack & history up to & including the oldScreenState.

            var oldTopIndex = this.history.indexOf(oldScreenState);

            var startIndex = this._getParkStartIndex();

            var oldHistory;
            var oldStack = [];
            var parkingId;
            var parkedView; // gather old history

            oldHistory = this.history.splice(startIndex, oldTopIndex - startIndex + 1); // get the id to park the content for (not the last, but the first state, as there may be substates shown underneath

            parkingId = oldHistory[0]; // always use the first from the screenStates to park (there may be other views shown on top)
            // retrieve views from stack

            oldHistory.forEach(function (screenState) {
                parkedView = this.viewStack.viewForID(screenState, true, false); // when parking old views, the parents (wrapperviews) may still be visible, ensure they are removed too.

                var parkedParent = parkedView.element ? parkedView.element[0].parentElement : null;

                if (parkedParent && $(parkedParent).parent().length > 0) {
                    $(parkedParent).remove();
                }

                oldStack.push(parkedView);
            }.bind(this)); // park an object containing both the history and the viewStack.

            this.changeStatePark = this.changeStatePark || {};

            if (this.changeStatePark.hasOwnProperty(parkingId)) {
                console.error(this.viewId, "_parkHistoryAsStateObject failed, there's already something parked for " + parkingId);
            } else {
                this.changeStatePark[parkingId] = {
                    views: oldStack,
                    history: oldHistory
                };
                Debug.GUI.ControlContentViewController && console.log(this.viewId, "   > parked with id " + parkingId, this.changeStatePark[parkingId]);
            }
        }

        _getParkStartIndex() {
            // HD may show an initial dummy, leave that out of our considerations.
            return this.history.indexOf("GUI.Screen") === 0 ? 1 : 0;
        }

        _unparkStateObject(screenState) {
            Debug.GUI.ControlContentViewController && console.log(this.viewId, "_unparkStateObject: " + screenState);
            var parkedObject = null;

            if (this._hasDataParkedForChangeState(screenState)) {
                parkedObject = this.changeStatePark[screenState];
                delete this.changeStatePark[screenState];
                return this._restoreParkedStateObject(parkedObject);
            } else {
                console.error(this.viewId, "_unparkStateObject failed, nothing stored to unpark from");
                return Q.reject();
            }
        }

        _restoreParkedStateObject(parkedObject) {
            Debug.GUI.ControlContentViewController && console.log(this.viewId, "_restoreParkedStateObject: ");
            Debug.GUI.ControlContentViewController && this._printParkedObject(parkedObject);
            var lastVisibleView = parkedObject.views.pop(),
                lastVisibleState = parkedObject.history.pop(); // present the last visible view

            Debug.GUI.ControlContentViewController && console.log(this.viewId, "   > showState of: " + lastVisibleState + " with storedView: ", lastVisibleView);
            return super.showState(lastVisibleState, lastVisibleView, null, null).then(function (defResult) {
                Debug.GUI.ControlContentViewController && console.log(this.viewId, "   > showState of: " + lastVisibleState + " passed, update history & viewStack from parked object!");
                Debug.GUI.ControlContentViewController && console.log(this.viewId, "        history before: " + JSON.stringify(this.history));
                Debug.GUI.ControlContentViewController && console.log(this.viewId, "      viewStack before: " + this.viewStack.stringify());
                Debug.GUI.ControlContentViewController && console.log(this.viewId, "        ... inserting previous history & views ...."); // put the old history & views back into place.

                for (var i = 0; i < parkedObject.history.length; i++) {
                    var parkedState = parkedObject.history[i];
                    var parkedView = parkedObject.views[i];
                    this.history.addAsPrevious(parkedState); // the current state is last in the history, insert just before that.

                    this.viewStack.add(parkedState, parkedView); // the current view is not shown, add to the end
                }

                Debug.GUI.ControlContentViewController && console.log(this.viewId, "        ... done ....");
                Debug.GUI.ControlContentViewController && console.log(this.viewId, "        history after: " + JSON.stringify(this.history));
                Debug.GUI.ControlContentViewController && console.log(this.viewId, "      viewStack after: " + this.viewStack.stringify());
            }.bind(this));
        }

        _hasDataParkedForChangeState(screenState) {
            return this.changeStatePark && this.changeStatePark.hasOwnProperty(screenState);
        }

        /**
         * Used to clean up when the view is destroyed, as potentially parked stateobjects with their views are no
         * longer needed.
         * @private
         */
        _destroyChangeStatePark() {
            Object.values(this.changeStatePark || {}).forEach(function (parkedObject) {
                parkedObject.views.forEach(function (parkedView) {
                    this._destroyView(parkedView);
                }.bind(this));
            }.bind(this));
            delete this.changeStatePark;
            this.changeStatePark = {};
        }

        _printPark() {
            console.log(this.viewId, "_printPark");
            this.changeStatePark && Object.keys(this.changeStatePark).forEach(function (screenState) {
                console.log(this.viewId, "       - parked state: " + screenState);

                this._printParkedObject(this.changeStatePark[screenState]);
            }.bind(this));
        }

        _printParkedObject(obj) {
            for (var i = 0; i < obj.views.length; i++) {
                console.log(this.viewId, "            - " + i + ": viewId=" + obj.views[i].viewId + ", history=" + obj.history[i]);
            }
        }

    }

    /**
     * The controlContentViewController has a capability regular view controllers don't have.
     * It can change states.
     * Why is that important?
     *    - For not opening the controlContent when it's locked by the systemState - or when it's simply locked.
     *    - But it can also be unlocked while open.
     *
     * But what does that mean?
     *  * Say the current history is: [A,B,C,D], we want to show [Z]
     * Previously, that would require navigating all the way back to an empty history and then showing Z.
     *      - [A,B,C,D], navBack -> [A,B,C]
     *      - [A,B,C] nachBack -> [A,B]
     *      - [A,B] navBack -> [A]
     *      - [A] -> show Z -> [Z]
     *      --> A-D are destroyed and gone.
     *
     * Change state proceeds as following:
     *      - show Z on top of [A,B,C,D] --> [A,B,C,D,Z]
     *      - "park" [A,B,C,D] outside of the history. --> [Z]
     *      - now the history is [Z]
     *
     * By parking the "old" history, it's now possible to change back from [Z] to [A,B,C,D] very fast.
     *      - [D] is shown on top of [Z] --> [Z,D]
     *      - [A,B,C] is "inserted" into the history --> [Z,A,B,C,D]
     *      - [D] is parked outside the history [A,B,C,D]
     *
     *
     *
     * @type {function(): *}
     */
    GUI.ControlContentViewController = ControlContentViewController;

    class ControlContentAlertViewController extends GUI.ControlContentViewController {
        /**
         * ControlContentAlertViewController only subclasses ControlContentViewController and adds isAlert to details
         * @param details
         * @constructor
         */
        constructor(details) {
            super(details);
            this.alert = true;
            this.fromNotification = details.fromNotification;
        }

        getShouldBlockScreenSaver() {
            return true;
        }

        dismissAlert() {
            this.ViewController.navigateBack();
        }

    }

    GUI.ControlContentAlertViewController = ControlContentAlertViewController;
    return GUI;
}(window.GUI || {});
