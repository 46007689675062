'use strict';

define("IRCDaytimerControl", ["DaytimerControlBase"], function (DaytimerControlBase) {
    return class IRCDaytimerControl extends DaytimerControlBase {
        constructor() {
            super(...arguments);
        }

        getSetEntriesCommand() {
            if (this.details.isCooling) {
                return Commands.DAYTIMER.SET_ENTRIES_COOLING;
            } else {
                return Commands.DAYTIMER.SET_ENTRIES;
            }
        }

        /**
         * Returns the string for the value of the entry that will be appended to the set entries command.
         * @param entry     the entry whos value is to be returned as a string for the setEntries-Cmd.
         * @return {null}   null only e.g. for a digital regular daytimer, as there is no value for it.
         */
        getEntryValueString(entry) {
            // Important to cast it to a string, otherwise 0 = eco mode would not be sent properly and one the comfort temp is chosen.
            return "" + entry.tempMode;
        }

    };
});
