'use strict';

define(["ControlInfoView", 'EnergyMonitorBattAnimationView', 'FroniusAnimationView', 'FroniusControlEnums'], function (ControlInfoView, EnergyMonitorBattAnimationView, FroniusAnimationView, FroniusControlEnums) {
    return class FroniusControlInfoView extends ControlInfoView {
        constructor() {
            super(...arguments);
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                // present a waiting view until the states arrive. Then we know what type of animationView is to be shown.
                var states = this.control.getStates();

                if (Object.values(states).length === 0) {
                    this.waitingView = new GUI.LxWaitingView();
                    this.waitingView.getElement().addClass("fronius-control-info-view__waiting-view");
                    return this.appendSubview(this.waitingView);
                } else {
                    this._animationViewPrms = this._addAnimationView(states.hasBattery);
                    return this._animationViewPrms;
                }
            }.bind(this));
        }

        receivedStates(states) {
            super.receivedStates(...arguments); // check if the animationView has already been loaded

            if (this.waitingView) {
                this.processWhenVisible(() => {})
                this.removeSubview(this.waitingView);

                this._animationViewPrms = this._addAnimationView(states.hasBattery);

                this.waitingView = null;
            }

            var isOnline = states.isOnline || states.generatorType === FroniusControlEnums.GeneratorType.Inputs; // AnimationView (NW-Devices might go to standby during night and therefore are temporarily offline)

            this._animationViewPrms && this._animationViewPrms.then(animationView => {
                animationView.update(states.currProduction, states.currConsumption, !isOnline, states.inStandby, states.currGrid, states.currBattery, states.stateOfCharge // these values might be undefined in older versions.
                );
            })
        }

        showStatistic(id) {
            if ("statistic" in this.control) {
                NavigationComp.showStatistic(this.control.uuidAction, this.control.statistic.outputs[id].uuid);
            }
        }

        _addAnimationView(hasBattery) {
            let animationView = hasBattery ? new EnergyMonitorBattAnimationView(true) : new FroniusAnimationView(true);
            return this.appendSubview(animationView).then(() => {
                animationView.getElement().addClass("fronius-control-info-view__animation");
                this.element.toggleClass("fronius-control-info-view--with-battery", hasBattery); // register for showing opening statistics on tapping the animationView

                animationView.onProductionTapped = this.showStatistic.bind(this, FroniusControlEnums.StatisticOutput.PROD_POWER);
                animationView.onConsumptionTapped = this.showStatistic.bind(this, FroniusControlEnums.StatisticOutput.CONS_POWER);
                animationView.onBatteryTapped = this.showStatistic.bind(this, FroniusControlEnums.StatisticOutput.BATT_CHARGE); // From this feature on there is a grid statistic, before that version, there was no statistic.

                if (Feature.ENERGY_MONITOR_9_STATS) {
                    animationView.onGridTapped = this.showStatistic.bind(this, FroniusControlEnums.StatisticOutput.GRID_POWER);
                }
                return animationView;
            })
        }

    };
});
