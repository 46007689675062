'use strict';
/*
    Description of the content: (Base content is excluded here, please check the base implementation for further information)
    content:
        timerInfo
        timerAlarm
        remainingTime
        uuidAction      uuid of the steak control to register for the timer countdown.

    Delegate:
        onTimerSetSelected(section, row, tableView)
        onTimerStop(section, row, tableView)
        onTimerStart(section, row, tableView)
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.STEAK_TIMER_CELL = "SteakTimerCell";

    class SteakTimerCell extends GUI.TableViewV2.Cells.DetailedContentBaseCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            Object.assign(this, StateHandler.Mixin);
            this.prominentButtons = [];
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.elements.prominentContainer.leftContainer = this.contentPlaceholder.find('.prominent-container__left-container');
            this.elements.prominentContainer.center = {
                element: this.contentPlaceholder.find('.prominent-container__center-container'),
                title: this.contentPlaceholder.find('.center-container__title')
            };
            this.elements.prominentContainer.rightContainer = this.contentPlaceholder.find('.prominent-container__right-container');
            this.elements.infoBar.container.time = $("<div class='info-container__time'></div>");
            this.elements.infoBar.container.element.prepend(this.elements.infoBar.container.time);
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);

            this._registerForStates(this.content.uuidAction, ["timerRemaining", "timerInfo", "timerAlarm"]);
        }

        viewWillDisappear() {
            this._unregisterStates(this.content.uuidAction);

            super.viewWillDisappear(...arguments);
        }

        receivedStates(states) {
            this.content.timerInfo = states.timerInfo;
            this.content.timerAlarm = states.timerAlarm;
            this.content.remainingTime = states.timerRemaining;
            this.applyContent();
        }

        applyContent() {
            var formatedDuration = LxDate.formatSecondsIntoDigits(this.content.timerInfo.duration, true, true);
            this.content.icon = Icon.Steak.ALARM;
            this.content.detail = {
                icon: Icon.Steak.TARGET,
                text: formatedDuration
            };
            this.content.prominent = {
                title: formatedDuration
            };

            if (this.content.timerAlarm.ringing) {
                this.content.color = window.Styles.colors.orange;
            } else {
                this.content.color = window.Styles.colors.stateActive;
            }

            this.content.title = _("steak.timer");

            if (this.content.timerInfo.active) {
                this._setActiveContent();
            } else {
                this.content.color = Color.WHITE;
                this.content.detail.text = formatedDuration;
                this.content.info = {
                    text: _("steak.timer.start")
                };
            }

            if (this.content.timerAlarm.ringing) {
                this.content.prominent.actions = [{
                    icon: Icon.Steak.ALARM_SILENT,
                    action: this._onTimerActionTapped.bind(this)
                }];
            }

            super.applyContent(...arguments);

            if (this.content.info && this.content.info.time) {
                this.elements.infoBar.container.time.text(this.content.info.time);
            }

            if (!this.content.timerAlarm.ringing) {
                if (!this.timerButton) {
                    this.elements.infoBar.container.element.addClass("button");
                    this.timerButton = new GUI.LxButton(this, this.elements.infoBar.container.element[0], Color.BUTTON_GLOW, null, true);
                    this.addToHandledSubviews(this.timerButton);
                    this.timerButton.onButtonTapped = this._onTimerActionTapped.bind(this);
                }
            } else {
                this.timerButton && this.removeSubview(this.timerButton);
                delete this.timerButton;
                this.elements.infoBar.container.element.css("background-color", applyAlphaChannelToColorString(Color.ORANGE, 0.4));
            }

            if (this.content.timerInfo.active) {
                this.elements.buttons.detailsButton.setEnabled(false);
            }
        }

        applyProminentContainerContent() {
            var prms = true;

            if (this.content.prominent && this.content.prominent.title) {
                this.elements.prominentContainer.center.title.text(this.content.prominent.title);
                this.elements.prominentContainer.center.title.css("color", this.content.color);

                if (this.content.timerInfo.active) {
                    // This blinks the title
                    this.elements.prominentContainer.center.title.toggleClass("timer-over", this.content.remainingTime === 0);
                }
            }

            if (this.content.prominent.actions) {
                this.content.prominent.actions.forEach(function (action, idx) {
                    if (action.icon && action.action) {
                        var button = new GUI.LxButton(this, this._getPrimaryButton(action, idx), Color.BUTTON_GLOW, null, true);
                        button.onButtonReleased = action.action.bind(this);
                        prms = this.appendSubview(button, this.elements.prominentContainer.rightContainer);
                        button.getElement().find(".action-button__icon").css("fill", this.content.color);
                        this.prominentButtons.push(button);
                    }
                }.bind(this));
            }

            return prms;
        }

        /**
         * Clears the cell and removes the previously applied content
         * Note: This will be called from this.clearContent
         */
        clearProminentContent() {
            this.elements.prominentContainer.center.title.empty();
            this.prominentButtons.forEach(function (button) {
                this.removeSubview(button);
            }.bind(this));
            this.prominentButtons = [];
        }

        getProminentContainer() {
            return '   <div class="prominent-container__left-container"></div>' + '      <div class="prominent-container__center-container">' + '          <div class="center-container__title"></div>' + '      </div>' + '      <div class="prominent-container__right-container"></div>';
        }

        // Private Section
        _setActiveContent() {
            var now = moment(),
                today = now.clone().startOf('day'),
                timeOffset = SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINISERVER_UTC_OFFSET),
                time,
                timeFormat;
            this.content.prominent = {
                title: LxDate.formatSecondsIntoDigits(this.content.remainingTime, true, true)
            };

            if (this.content.timerAlarm.ringing) {
                time = new LxDate(this.content.timerAlarm.time, true).utcOffset(timeOffset);

                if (time.isSame(today, "d")) {
                    timeFormat = DateType.TimeFormat;
                } else {
                    timeFormat = LxDate.getDateTimeFormat(DateType.WeekdayAndDateTextNumeralAndTime, true);
                }

                this.content.info = {
                    text: this.content.timerAlarm.text,
                    time: time.format(timeFormat)
                };
            } else {
                this.content.info = {
                    text: _("steak.timer.stop")
                };
            }
        }

        // Delegate Handling
        handleTopLeftButtonDelegate() {
            this.handleTopRightButtonDelegate();
        }

        handleTopRightButtonDelegate() {
            this.delegate.onTimerSetSelected && this.delegate.onTimerSetSelected.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
        }

        _onTimerActionTapped() {
            if (this.content.timerInfo.active) {
                this.delegate.onTimerStop && this.delegate.onTimerStop.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
            } else {
                this.delegate.onTimerStart && this.delegate.onTimerStart.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
            }
        }

        _getPrimaryButton(action, idx) {
            return '<div class="' + "action-button button-" + idx + '">' + '       ' + ImageBox.getResourceImageWithClasses(action.icon, "action-button__icon") + '   </div>';
        }

    }

    GUI.TableViewV2.Cells.SteakTimerCell = SteakTimerCell;
    return GUI;
}(window.GUI || {});
