'use strict';
/**
 * This view can be used to show large lists of the same reusable cells. It does not support using different cell types
 * as of now, even tough it was prepared to do so. This is not needed at the moment and hasn't been implemented therefore.
 *
 * The list can be either used "on its own" so it'll take care of scrolling, but it can also be used in a parent div that
 * takes care of scrolling. In this case it will respond to the parents scroll events.
 *
 * delegate
 *      onListCellTapped
 *
 * dataSource
 *      heightForCellInRow (row, list)
 *      cellTypeForRowInList (row, list)
 *      contentForRowInList (row, list)
 *      numberOfRowsInList (list)
 */

window.GUI = function (GUI) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var EXTEND_CONTENT_DELAY = 1000,
            // ms after which the content of a cell is being extended (show a richer content when no longer scrolling)
            SCROLL_ACTIVE_DELAY = 200,
            // ms after which the scrolling is still considered active (avoid unwanted touches)
            RESIZE_RESPONSE_DELAY = 200; // how many ms after a resizeEvent the pages are to be adopted.

        class ReusingListView extends GUI.View {
            //region Static
            static Template = function () {
                var getTemplate = function getTemplate() {
                    return $('<div class="reusing-list-view">' + '<div class="reusing-list-view__content"></div>' + '</div>');
                };

                return {
                    getTemplate: getTemplate
                };
            }(); //endregion Static

            /**
             * See comment above "class" declaration.
             * @param dataSource            where to get data from.
             * @param delegate              whom to call if cells where selected & so on.
             * @param [scrollContainer]     if the list itself is not the one who scrolls, but another element.
             */
            constructor(dataSource, delegate, scrollContainer) {
                super(ReusingListView.Template.getTemplate());
                this.dataSource = dataSource;
                this.delegate = delegate;
                this.numberOfRows = -1; // use a cntr to determine once the scrolling is no longer active.

                this.collector = new GUI.TableViewContentCollector(this, this.dataSource, this.delegate, true);
                this.internalDs = tableViewDataSource();
                this.internalDel = tableViewDelegate();
                this.dataSource.tableView = this;
                this.delegate.tableView = this;

                this.scrollCntr = 0;
                this.resizeCntr = 0;
                this.isScrolling = false;
                this.shouldExtendContent = false; // set to false initially, so the content will appear faster.

                this.extendContentAfterScrollTimeout = null;
                this._scrollTop = 0;

                if (scrollContainer) {
                    this.scroller = scrollContainer;
                    this.selfScroller = false;
                } else {
                    this.scroller = this.element;
                    this.selfScroller = true;
                }

                this.element.toggleClass("reusing-list-view--self-scroller", this.selfScroller);
            }

            viewDidLoad() {
                Debug.GUI.ListView && console.log(this.viewId, "viewDidLoad");
                this.elements = {};
                return super.viewDidLoad(...arguments).then(function () {
                    this.elements.content = this.element.find(".reusing-list-view__content");
                    this.boundScrollHandler = this._handleScroll.bind(this);
                    this.htmlElem = this.element[0];
                    this.registerForResize();
                }.bind(this));
            }

            viewWillAppear() {
                Debug.GUI.ListView && console.log(this.viewId, "viewWillAppear");
                return super.viewWillAppear(...arguments).then(function () {
                    this.scroller.scrollTop(this._scrollTop);
                    this.scroller[0].addEventListener("scroll", this.boundScrollHandler, {
                        passive: true
                    }); //this.scroller[0].addEventListener("wheel", this.boundScrollHandler, {passive: true});

                    Debug.GUI.ListView && console.log(this.viewId, "viewWillAppear passed");
                }.bind(this));
            }

            viewDidAppear() {
                Debug.GUI.ListView && console.log(this.viewId, "viewDidAppear");
                return super.viewDidAppear(...arguments).then(function () {
                    this._updateAllHeights();
                }.bind(this));
            }

            viewWillDisappear() {
                Debug.GUI.ListView && console.log(this.viewId, "viewWillDisappear");
                this.scroller[0].removeEventListener("wheel", this.boundScrollHandler);
                this.scroller[0].removeEventListener("scroll", this.boundScrollHandler);
                return super.viewWillDisappear(...arguments);
            }

            onResize() {
                if (!this.respondToResizeInterval) {
                    this.resizeCntr = 0;

                    this._startRespondToResizeInterval();
                } else {
                    this.resizeCntr++;
                }
            }

            isWaitingForReload() {
                return this.reloadDef && this.reloadDef.promise.inspect().state === "pending";
            }

            // ------------------------------------------------------------
            // -------             Public Methods                ----------
            // ------------------------------------------------------------



            __updateInternalContent() {
                this.collectedContent = this.collector.collectContent();
                // For testing purposes, don't forward the original DS/Del, but the internal one to cells
                this.internalDs.update(this.collectedContent.sections);
                this.internalDel.update(this.collectedContent.sections);
            }

            dsUpdated() {
                this.__updateInternalContent();
            }


            reloadRowsSelective() {
                return this.reload();
            }

            /**
             * Will be called each time the data needs to be updated.
             * The reload function also chains reloads to prevent dropped frames
             */
            reload() {
                this.__updateInternalContent();
                // There is already a reload in progress!
                if (this.isWaitingForReload()) {
                    Debug.GUI.ListView && console.log(this.viewId, "reload - in progress, enqueue", getStackObj());
                    var promise;

                    if (this._reloadEnqueued) {
                        promise = this._enqueuedPromise;
                    } else {
                        this._reloadEnqueued = true;
                        promise = this.reloadDef.promise.then(function () {
                            Debug.GUI.ListView && console.log(this.viewId, "reload - processing enqueued reload");
                            this._reloadEnqueued = false;
                            this.reloadDef = null;
                            return this.reload();
                        }.bind(this));
                        this._enqueuedPromise = promise;
                    }

                    return promise;
                } else {
                    var def = Q.defer();

                    if (!this.isVisible()) {
                        Debug.GUI.ListView && console.log(this.viewId, "reload --> delayed, not visible yet!");
                        this.processWhenVisible(function () {
                            Debug.GUI.ListView && console.log(this.viewId, "reload - delayed reload fires now");
                            return this.reload();
                        }.bind(this));
                        return Q(true);
                    }

                    this.reloadDef = Q.defer();
                    Debug.GUI.ListView && console.log(this.viewId, "reload");
                    var prevRowCnt = this.numberOfRows,
                        didDecrease,
                        newHeight;
                    Debug.GUI.ListViewTiming && debugLog(this.viewId, "reload"); // reset the number of rows (next call to _numRows) will reset it.

                    this.numberOfRows = -1;
                    didDecrease = this._numRows() < prevRowCnt; // if the dataset is empty, dump all pages & start from scratch when new data arrives.

                    if (this._numRows() === 0) {
                        this._destroyAllPages();

                        return Q(true);
                    } // first of all, assign the recomputed required height


                    newHeight = this._computeHeight();
                    this.elements.content.css("height", newHeight); // initially the pages need to be created.

                    if (!this.currPage) {
                        this._initializePages().done(function () {
                            def.resolve();
                        });
                    } else {
                        def.resolve();
                    }

                    return def.promise.then(function () {
                        Debug.GUI.ListView && console.log(this.viewId, "reload - preparations passed, now reload"); // if the number of cells did decrease, there are some special situations to deal with. If either
                        // the current or the lowerPage is a lastPage, the content needs to be completely updated. Otherwise
                        // a cell that has been removed might still be visible below the end of the list.

                        if (didDecrease && (this.lowerPage && this.lowerPage.isLastPage || this.currPage && this.currPage.isLastPage || this.currPage && this.currPage.endRow >= this._numRows()) // the current page will be the last page & will be reduced (e.g. 20 item list > 4 item list)
                        ) {
                            this._updateCurrentPages(); // the current pages have changed and their rows might have reduced.

                        } else {
                            // the current page might still have room, as it might have been initialized smaller.
                            this._checkAndFillPageWithRows(this.currPage); // update the data.


                            this._reloadPageContents();
                        } // after the first reload that contains items, call the initialExtendTimeout. This way the cells will
                        // appear fast without extendedContent initially and after some time the content will be extended - allowing
                        // faster initial responses.


                        if (this._numRows() > 0 && !this.initialExtendTimeout) {
                            this.initialExtendTimeout = animationProofTimeout(function () {
                                // make sure no scrolling is active right now, otherwise it'd extend the content during scrolling.
                                if (!this.isScrolling && !this.extendContentAfterScrollTimeout) {
                                    this.shouldExtendContent = true;

                                    this._extendCurrentVisibleContent();
                                }
                            }.bind(this), EXTEND_CONTENT_DELAY, this.element);
                        } // also adopt the style.


                        this._updateListViewStyle();

                        Debug.GUI.ListViewTiming && debugLog(this.viewId, "reload passed");
                        var sectionHeader;

                        if (this.collectedContent.sections[0].headerElement) {
                            sectionHeader = this.collectedContent.sections[0].headerElement;

                            if (this.headerElement && (!sectionHeader || !sectionHeader.is(this.headerElement))) {
                                this.headerElement.remove();
                                delete this.headerElement;
                            }

                            if (!this.headerElement && sectionHeader) {
                                this.headerElement = sectionHeader;
                                this.headerElement && this.headerElement.insertBefore(this.elements.content);
                            }
                        } else if (this.headerElement) {
                            this.headerElement.remove();
                            delete this.headerElement;
                        }

                        this.collectedContent.processAtTheEndOfReload && this.collectedContent.processAtTheEndOfReload(this);
                        this.reloadDef.resolve();
                        return this.reloadDef.promise;
                    }.bind(this));
                }
            }

            /**
             * Will be used when only data from a certain point downwards needs to be updated (incl. the height)
             * @param row
             */
            reloadAfter(row) {
                if (!this.currPage || !this.isVisible()) {
                    Debug.GUI.ListView && console.log(this.viewId, "reloadAfter >> whole reload " + row, getStackObj());
                    this.reload();
                    return;
                }

                Debug.GUI.ListView && console.log(this.viewId, "reloadAfter: " + row, getStackObj());
                Debug.GUI.ListViewTiming && debugLog(this.viewId, "reloadAfter: " + row); // as long as there data that is being reloaded isn't visible, there is not much to do.
                // re-retrieve the number of rows

                this.numberOfRows = -1; // and update the height.

                this.elements.content.css("height", this._computeHeight());

                this._checkAndFillPageWithRows(this.currPage); // if the (updated) isLastPage is not set, the lower page is also to be kept in mind.


                if (!this.currPage.isLastPage) {
                    if (this.lowerPage) {
                        this._checkAndFillPageWithRows(this.lowerPage);
                    } else {
                        // we need to initialize the lower page!
                        this.lowerPage = this._initializePage(this.currPage.endRow + 1);

                        this._presentPage(this.lowerPage);
                    }
                } // update the data.


                this._reloadPageContents();

                Debug.GUI.ListViewTiming && debugLog(this.viewId, "reloadAfter: " + row + " passed");
            }

            /**
             * This method will check if the page provided has room for more cells and also adopts the pages attributes,
             * as they might change if the data set grows larger (appending items might invalidate the "isLastPage" flag.
             * @private
             */
            _checkAndFillPageWithRows(page) {
                Debug.GUI.ListView && console.log(this.viewId, "_checkAndFillPageWithRows");

                if (!page) {
                    Debug.GUI.ListView && console.warn(this.viewId, "   - no page provided - no rows?");
                    return;
                }

                var currPageHeight = page.endPos - page.startPos;

                if (currPageHeight >= this.pageHeight) {
                    Debug.GUI.ListView && console.log("        the page is large enough!");
                } else if (page.endRow === this._numRows() - 1) {
                    Debug.GUI.ListView && console.log("        there are no cells left!");
                } else {
                    this._fillPageWithRows(page);
                }

                this._updatePageAttributes(page);
            }

            /**
             * This method is being called whenever there is data being appended but either the current or the lower page
             * are not yet full. Then they will be filled up with cells using this method. This method must not be called
             * for the upperPage as it must never be too small.
             * @param page
             * @private
             */
            _fillPageWithRows(page) {
                Debug.GUI.ListView && console.log(this.viewId, "_fillPageWithRows");

                var i = page.endRow + 1,
                    remainingHeight = this.pageHeight - (page.endPos - page.startPos),
                    totalRows = this._numRows(),
                    cell,
                    start = page.cells.length; // prepare the cells.


                while (remainingHeight > 0 && i < totalRows) {
                    cell = this._getCellForRow(i);
                    remainingHeight -= this.heightArray[i].height;
                    page.cells.push(cell);
                    i++;
                } // check if the page contains more cells than available


                if (page.endRow >= totalRows) {
                    var numExcessRows = page.endRow + 1 - totalRows;
                    var excessRows = page.cells.splice(page.cells.length - numExcessRows, numExcessRows);
                    excessRows.forEach(function (row) {
                        this.removeSubview(row);
                    }.bind(this)); // update start attribute!

                    start = page.cells.length;
                }

                this._presentPage(page, start);
            }

            // ------------------------------------------------------------
            // -------             Private Methods               ----------
            // ------------------------------------------------------------

            /**
             * Called when the dataSet did shrink & the currently visible area might be affected, e.g. due to removing an
             * item. The view will scroll to the correct position automatically when the height reduces. The pages however
             * might be out of date and the pages need to be reloaded.
             * @private
             */
            _updateCurrentPages() {
                Debug.GUI.ListView && console.log(this.viewId, "_updateCurrentPages (= destroy and recreate!)"); // for now, simply destroy the pages & cells and re-create them.

                this.upperPage && this._destroyPage(this.upperPage);
                this.currPage && this._destroyPage(this.currPage);
                this.lowerPage && this._destroyPage(this.lowerPage); // recreate them.

                this.currPage = this._initializePage(this._numRows() - 1, true);

                this._presentPage(this.currPage);

                if (this.currPage.startRow > 0) {
                    this.upperPage = this._initializePage(this.currPage.startRow - 1, true);

                    this._presentPage(this.upperPage);
                }

                this.lowerPage = null; // there is no lower page, as the view did scroll to the bottom
            }

            /**
             * When the size has changed, the number of cells in the pages is changed too. We simply assume that
             * the current page start remains the same and recreate the pages at the current position.
             * @private
             */
            _resizePages() {
                // store the currPageStart, it is reused for recreating the pages!
                var currPageStart = this.currPage.startRow; // destroy the current pages!

                this.currPage && this._destroyPage(this.currPage);
                this.lowerPage && this._destroyPage(this.lowerPage);
                this.upperPage && this._destroyPage(this.upperPage); // recreate the pages with the previous currPageStart

                return this._initializePages(currPageStart);
            }

            /**
             * Will ask the delegate for a listViewStyle and update the css classes accordingly. PLAIN is the default.
             * @private
             */
            _updateListViewStyle() {
                var newStyle = this.collectedContent.styleForReusingListView;

                if (!newStyle) {
                    newStyle = ReusingListViewStyle.PLAIN; // PLAIN is the default
                }

                if (newStyle !== this._currentViewStyle) {
                    this.element.removeClass(this._currentViewStyle);
                    this._currentViewStyle = newStyle;
                    this.element.addClass(this._currentViewStyle);
                }
            }

            /**
             * Will compute & return the total required height of the view and additionally it is going to build up an
             * array containing the offsets and heights of each cell in this list.
             * @returns {*} the total height of the list
             * @private
             */
            _computeHeight() {
                Debug.GUI.ListView && console.log(this.viewId, "_computeHeight");

                if (isNaN(this.offsetTop)) {
                    // reload called before viewDidAppear is finished.
                    this._updateAllHeights();
                }

                this.heightArray = [{
                    offset: this.offsetTop,
                    height: 0
                }];

                var numCells = this._numRows(),
                    height;

                for (var i = 0; i < numCells; i++) {
                    height = this.collectedContent.sections[0].rows[i].height;
                    this.heightArray[i].height = height;
                    this.heightArray[i].end = this.heightArray[i].offset + height;
                    this.heightArray[i + 1] = {
                        height: 0,
                        offset: this.heightArray[i].offset + height
                    };
                }

                Debug.GUI.ListView && console.log(this.viewId, "      height for all " + numCells + " cells: " + this.heightArray[numCells].offset); // as the height computed here is for the scroller and we need it for the list, substract the offsetTop.
                return this.heightArray[numCells].offset - this.offsetTop;
            }

            /**
             * Used to initialize the page objects.
             * @param [currPageStartRow]    optional, 0 if not provided - what row does the currRow start in.
             * @private
             */
            _initializePages(currPageStartRow) {
                Debug.GUI.ListView && console.log(this.viewId, "_initializePages");

                var row = !currPageStartRow ? 0 : currPageStartRow,
                    numRows = this._numRows(),
                    presentPagePrms = [];

                this.upperPage = null;
                this.lowerPage = null;
                this.currPage = null;
                Debug.GUI.ListViewTiming && debugLog(this.viewId, "_initializePages"); // If there are no rows, there's nothing to do.

                if (numRows > 0) {
                    if (row > 0) {
                        this.upperPage = this._initializePage(row, true);
                        presentPagePrms.push(this._presentPage(this.upperPage));
                    } //  there has to be a current page


                    this.currPage = this._initializePage(row);
                    presentPagePrms.push(this._presentPage(this.currPage)); // if there are rows left after initializing the first page, also prepare the next page.

                    row += this.currPage.cells.length;

                    if (row < numRows) {
                        this.lowerPage = this._initializePage(row);
                        presentPagePrms.push(this._presentPage(this.lowerPage));
                    }
                }

                Debug.GUI.ListViewTiming && debugLog(this.viewId, "_initializePages passed");
                return Q.all(presentPagePrms);
            }

            /**
             * Starts at a given row and then prepares cells until the specified page height is reached/exceeded.
             *      cells: array of reusableListCellBase subclasses, may have been reused, need content & new offset
             *      height: the height of the whole page
             *      startRow: the row idx of the first cell on this page
             *      endRow: the row of the last cell on this page
             *      startPos: the offset of the first cell of the page
             *      endPos: the position of the lower end of the last cell on the page
             * @param startRow
             * @param [scrollToUpper]   if this argument is set, the row count needs to be decreased, not increased.
             * @returns {{cells: Array, height: number, startRow: number, endRow: number, startPos: number, endPos: number}}
             * @private
             */
            _initializePage(startRow, scrollToUpper) {
                Debug.GUI.ListView && console.log(this.viewId, "_initializePage: from " + startRow + " " + (scrollToUpper ? "upwards" : "downwards"));

                var page = {
                        cells: [],
                        height: 0,
                        startRow: 0,
                        endRow: 0,
                        startPos: 0,
                        endPos: 0
                    },
                    currRow = startRow,
                    numRows = this._numRows(),
                    currHeight = 0,
                    cell; // not only check the page height, but also the rows - in both directions (up/down)


                while (currHeight < this.pageHeight && currRow < numRows && currRow >= 0) {
                    cell = this._getCellForRow(currRow);
                    currHeight += this.heightArray[currRow].height; // when scrollToUpper is set, it means that the page is being built up from bottom to top

                    if (scrollToUpper) {
                        // meaning that the new cells need to be prepended
                        page.cells.splice(0, 0, cell); // and the currRow index needs to be decreased

                        currRow--;
                    } else {
                        // otherwise it's append & increase
                        page.cells.push(cell);
                        currRow++;
                    }
                } // if the page is being set up from bottom to top, pages start row isn't equal to the startRow provided.


                if (scrollToUpper) {
                    page.startRow = startRow - page.cells.length + 1;
                } else {
                    page.startRow = startRow;
                } // initialize the page objects attributes


                this._updatePageAttributes(page);

                return page;
            }

            /**
             * Will append the pages cells to the container and then prepare the individual cells (set offset & assign
             * the content)
             * @param page
             * @param [start]   used for appending cells, will present and prepare cells from a given start idx. 0 if missing.
             * @private
             */
            _presentPage(page, start) {
                var allDef = Q.defer();

                if (page.animationFrame) {
                    window.cancelAnimationFrame(page.animationFrame);
                    delete page.animationFrame;
                }

                page.animationFrame = window.requestAnimationFrame(function () {
                    var i = !start ? 0 : start,
                        prms = [],
                        cell,
                        def;
                    Debug.GUI.ListView && console.log(this.viewId, "_presentPage: " + page.startRow + " - " + page.endRow + " - from " + i);
                    Debug.GUI.ListViewTiming && debugLog(this.viewId, "_presentPage: " + (page.endRow - page.startRow) + " rows");

                    for (i; i < page.cells.length; i++) {
                        cell = page.cells[i];
                        def = Q.defer();
                        prms.push(def.promise);

                        if (cell.animationFrame) {
                            window.cancelAnimationFrame(cell.animationFrame);
                            delete cell.animationFrame;
                        }

                        cell.animationFrame = window.requestAnimationFrame(function (i, def) {
                            this.addToHandledSubviews(page.cells[i]).then(function () {
                                try {
                                    this._prepareCellForRow(page.cells[i], page.startRow + i, page);
                                } catch (e) {// Do nothing, just in case we reload to quick
                                }

                                def.resolve(page.cells[i].getElement());
                            }.bind(this));
                        }.bind(this, i, def));
                    }

                    Debug.GUI.ListViewTiming && debugLog(this.viewId, "_presentPage   prepared the rows.");
                    Q.all(prms).then(function (viewsToAppend) {
                        this.elements.content.appendEach(viewsToAppend);
                        Debug.GUI.ListViewTiming && debugLog(this.viewId, "_presentPage   appended the rows.");
                        Debug.GUI.ListViewTiming && debugLog(this.viewId, "_presentPage done");
                        allDef.resolve();
                    }.bind(this));
                }.bind(this));
                return allDef.promise;
            }

            /**
             * Will return a cell for the row specified. It might reuse cells that we already have. If not, new ones
             * will be created.
             * @param row
             * @returns {*}
             * @private
             */
            _getCellForRow(row) {
                var cellType = this.collectedContent.sections[0].rows[row].type,
                    cell;

                try {
                    cell = new GUI.ReusingListView.Cells[cellType](this.internalDel, cellType, this);
                } catch (ex) {
                    console.error("Could not create a cell for cellType " + cellType + " at row " + row, ex);
                }

                return cell;
            }

            /**
             * Will reposition the cell at the right place and update the cells content properly. it will not append it
             * to a view.
             * @param cell
             * @param row
             * @private
             */
            _prepareCellForRow(cell, row, page) {
                // make sure that the coordinates of the cell are inside the list coordinate system, not in the scrollers (offsetTop)
                var topOffSet = this.heightArray[row].offset - this.offsetTop,
                    elem = cell.getElement(),
                    content = this._requestContentForRow(row),
                    endFn = this.shouldExtendContent ? cell.extendCellContent.bind(cell) : null,
                    isScrolling = this.isScrolling;

                content[GUI.LVContent.ROW] = row; // this is VERY important for reusing cells.
                // update cell classes.

                var numRows = this._numRows();

                GUI.animationHandler.schedule(function () {
                    elem.toggleClass("reusable-list-cell--last", row === numRows - 1);
                    elem.toggleClass("reusable-list-cell--first", row === 0);
                });
                this.moveCellTo(cell, topOffSet, cell.updateContent.bind(cell, content, isScrolling), endFn);
            }

            /**
             * Will move the cell provided to a new position in the most performant way.
             * @param cell      the cell to move
             * @param newPos    where to move it to
             * @param [beginFn] optional function to call when the animation starts
             * @param [endFn]   optional function to call when the animation is finished
             */
            moveCellTo(cell, newPos, beginFn, endFn) {
                var elem = cell.getElement(),
                    _beginFn = beginFn ? lxRequestAnimationFrame.bind(window, beginFn, elem) : null,
                    _endFn = endFn ? lxRequestAnimationFrame.bind(window, endFn, elem) : null; // calling "stop" before starting the animation will cancel any animations that have been started
                // before. This is important for the editable list, where cells can be moved.


                elem.velocity('stop', true);
                _beginFn && _beginFn();
                GUI.animationHandler.schedule(function () {
                    elem.css("transform", "translate3d(0, " + newPos + "px, 0)");
                });
                _endFn && _endFn();
            }

            /**
             * Will get the content from the delegate and return it. used to intercept and adopt the content. e.g. for
             * providing the editing attributes in the editableReusingListView subclass.
             * @param row
             * @returns {*}
             * @private
             */
            _requestContentForRow(row) {
                var content = this.collectedContent.sections[0].rows[row].content;
                Debug.GUI.ListView && console.log(this.viewId, "_requestContentForRow: " + row, content);
                return content;
            }

            /**
             * As soonas the startRow and the cells have been assigned, the rest of the attributes can be calculated
             * using this method.
             * @private
             */
            _updatePageAttributes(page) {
                if (!page.hasOwnProperty("startRow") || !page.hasOwnProperty("cells")) {
                    console.error("Cannot update page attributes without knowing the startIdx and cell count!");
                    throw new Error("_updatePageAttribtues failed, see console for details!");
                }

                var numRows = this._numRows();

                page.endRow = Math.max(page.startRow + page.cells.length - 1, 0); // if a page is reused it might happen that there are more cells to reuse than there is data to display.
                // in order to avoid dealing with this situation in a more complex way, simply check it here and adopt
                // the endRow accordingly. This leaves the cells array untouched and when the page is reused in a place
                // where all the cells can be reused again, it will effectively reuse all of them.

                if (page.endRow >= numRows) {
                    page.endRow = numRows - 1;
                } // the endRow must never be below 0 (e.g. that happens if there is no at to display)


                page.endRow = Math.max(page.endRow, 0);

                try {
                    page.startPos = this.heightArray[page.startRow].offset;
                    page.endPos = this.heightArray[page.endRow].offset + this.heightArray[page.endRow].height;
                } catch (ex) {
                    console.error("Could not update page attributes properly, start & endPos could not be updated! " + page.startRow + "-" + page.endRow);
                }

                page.height = page.endPos - page.startPos;
                page.isLastPage = page.endRow === numRows - 1;
                page.isFirstPage = page.startRow === 0;
            }

            // ------------------------------------------------------------------------
            // ------------          Responding to Scrolls                 ------------
            // ------------------------------------------------------------------------

            /**
             * Called whenever the scroller responds. it'll ensure that the proper pages (& therefore the proper cells)
             * are shown.
             * @private
             */
            _handleScroll(e) {
                var startTime = Date.now(),
                    endTime;
                Debug.GUI.ListView && console.log(this.viewId, "_handleScroll (selfScroller: " + this.selfScroller + ")");

                this._updatePageWindow();

                if (this._scrollTop > this.currPage.endPos || this._scrollTop < this.currPage.startPos) {
                    this._handlePageChange();
                } // since we might be scrolling fast through a fast amount of cells, it might be necessary to adopt their
                // content based on whether or not scrolling is active.


                this.scrollCntr++; // if scrolling has been stopped, it often occurs that a cell-tap event is triggered. This is not something
                // we want, we want the scrolling to stop first, and the next tap should be processed.

                if (!this.scrollingTimeout) {
                    this._startScrollingTimeout();
                } // if a cell has been visible for a longer period of time, the content may be extended. E.g. an additional
                // icon could be shown. In the mediaListCells the cover is something that will be loaded after the item
                // has been visible & scrolling hasn't been active for some time.


                if (!this.extendContentAfterScrollTimeout) {
                    this._startextendContentAfterScrollTimeout();
                }

                endTime = Date.now();

                if (endTime - startTime >= 2) {
                    console.log("_handleScroll took to long!");
                }
            }

            /**
             * This method will update all stored heights needed for both scroll and page handling.
             * @private
             */
            _updateAllHeights() {
                Debug.GUI.ListView && console.log(this.viewId, "_updateAllHeights");
                this.clientHeight = this.selfScroller ? this.htmlElem.clientHeight : this.scroller[0].clientHeight;
                this.offsetTop = this.selfScroller ? 0 : this.htmlElem.offsetTop;
                this.pageHeight = this.clientHeight * 1.5;

                this._updatePageWindow();
            }

            /**
             * Calling this method ensures all data needed for scrolling is up to date.
             * @private
             */
            _updatePageWindow() {
                Debug.GUI.ListView && console.log(this.viewId, "_updatePageWindow");
                this._scrollTop = this.scroller.scrollTop();
                var headerHeight = this.headerElement ? this.headerElement.height() : 0; // the currentWindow (start+end) should be in the coordinates of this.elements.content -> not this.element.
                // As the header is inserted before the content, it needs to be accounted for.

                this._currWindowStart = Math.max(this._scrollTop - headerHeight, 0);
                this._currWindowEnd = this._currWindowStart + this.clientHeight;
            }

            /**
             * Will start a timeout that will check whether or not the scrolling has been ended. In this case the current
             * pages cells need to be notified.
             * @private
             */
            _startScrollingTimeout() {
                Debug.GUI.ListView && console.log(this.viewId, "_startScrollingTimeout");
                var scrollStartCntr = this.scrollCntr;
                this.isScrolling = true;
                this.scrollingTimeout = animationProofTimeout(function () {
                    if (this.scrollCntr === scrollStartCntr) {
                        // no scrolling occurred.
                        this.isScrolling = false;
                        this.scrollingTimeout = null;

                        this._performOnVisibleCells("prepareAfterScroll");
                    } else {
                        this._startScrollingTimeout();
                    }
                }.bind(this), SCROLL_ACTIVE_DELAY, this.element);
            }

            /**
             * This interval ensures that the pages are only updated after the resizing has finished.
             * @private
             */
            _startRespondToResizeInterval() {
                if (this.respondToResizeInterval) {
                    return;
                }

                var currCnt = this.resizeCntr;
                this.respondToResizeInterval = animationProofInterval(function () {
                    if (currCnt === this.resizeCntr) {
                        clearInterval(this.respondToResizeInterval);
                        this.respondToResizeInterval = null; // update the height attributes for the new size

                        this._updateAllHeights(); // now make sure the pages are recreated.


                        this._resizePages();
                    }

                    currCnt = this.resizeCntr;
                }.bind(this), RESIZE_RESPONSE_DELAY, this.element);
            }

            /**
             * Will start a timeout that will determine if the cell content should be extended or not. It is a longer
             * timeout than the scrolling timeout, since extended content might have a high impact on performance.
             * @private
             */
            _startextendContentAfterScrollTimeout() {
                this.shouldExtendContent = false;
                this.extendContentAfterScrollTimeout = animationProofTimeout(function () {
                    if (!this.isScrolling) {
                        this.shouldExtendContent = true;
                        this.extendContentAfterScrollTimeout = null;

                        this._extendCurrentVisibleContent();
                    } else {
                        this._startextendContentAfterScrollTimeout();
                    }
                }.bind(this), EXTEND_CONTENT_DELAY, this.element);
            }

            /**
             * Will perform the extendCellContent function on all visible cells
             * @private
             */
            _extendCurrentVisibleContent() {
                Debug.GUI.ListView && console.log(this.viewId, "_extendCurrentVisibleContent");

                this._performOnVisibleCells("extendCellContent");
            }

            /**
             * Calls a function identified by the provieded performStr on each cell that is currently visible.
             * @param performStr    the string used to identify the function. there will be no arguments.
             * @private
             */
            _performOnVisibleCells(performStr) {
                Debug.GUI.ListView && console.log(this.viewId, "_performOnVisibleCells: " + performStr + " (" + this._currWindowStart + " - " + this._currWindowEnd + ") -> " + this.clientHeight);
                var checkUpper,
                    checkLower = false; // the upperPage might be visible

                checkUpper = this.upperPage && this.heightArray[this.currPage.startRow].offset > this._currWindowStart;

                if (checkUpper) {
                    Debug.GUI.ListView && console.log("   checkUpper: ");

                    this._performOnVisibleCellsOfPage(performStr, this.upperPage, false, true);
                } else {
                    // if the upper isn't visible, the lower might be.
                    checkLower = this.lowerPage && this.heightArray[this.currPage.endRow].end < this._currWindowEnd;
                } // now iterate over the current cells.


                Debug.GUI.ListView && console.log("   checkCurrent: ");

                this._performOnVisibleCellsOfPage(performStr, this.currPage, false); // if needed iterate over the lower cells


                if (checkLower) {
                    Debug.GUI.ListView && console.log("   checkLower: ");

                    this._performOnVisibleCellsOfPage(performStr, this.lowerPage, true);
                }
            }

            /**
             * Will iterate over all cells of a page and call the provided function (provide it as a string) on the cells
             * that are currently visible.
             * @param performFn
             * @param page      the page to check & whos cells to perform the fn on if visible
             * @param cancel    whether or not the iteration should be stopped after the first invisible cell
             * @param upwards   if true, the cells will be iterated from the last to the first cell
             * @private
             */
            _performOnVisibleCellsOfPage(performFn, page, cancel, upwards) {
                var i = !!upwards ? page.endRow : page.startRow,
                    cellStart,
                    cellEnd,
                    cell;

                for (i; i >= page.startRow && i <= page.endRow; !!upwards ? i-- : i++) {
                    cellStart = this.heightArray[i].offset;
                    cellEnd = this.heightArray[i].end;

                    if (cellEnd >= this._currWindowStart && cellStart <= this._currWindowEnd) {
                        cell = page.cells[i - page.startRow];
                        Debug.GUI.ListView && console.log("   extend content of row: " + i + ": " + cellStart + " - " + cellEnd);
                        cell && cell[performFn].apply(cell); // if there are no cells on the page, it might be undefined.
                    } else if (cancel) {
                        Debug.GUI.ListView && console.log("   stopping at " + i + ": " + cellStart + " - " + cellEnd);
                        break;
                    } else {
                        Debug.GUI.ListView && console.log("   ignoring " + i + ": " + cellStart + " - " + cellEnd);
                    }
                }
            }

            /**
             * Will take care of updating the upper-, curr- and lower-Page attributes depending on the scroll direction.
             * By updating these attributes the pages on the UI are also updated.
             * @private
             */
            _handlePageChange() {
                Debug.GUI.ListView && console.log(this.viewId, "_handlePageChange");
                var scrollToLower = this._scrollTop > this.currPage.endPos,
                    pageToReuse = scrollToLower ? this.upperPage : this.lowerPage,
                    // store so it can be updated after this call
                    reuseStartRow; // the order of the assignments is important, do not get them mixed up.

                if (scrollToLower && !this.currPage.isLastPage) {
                    // scrolling downwards e.g. from row 20 to row 100
                    this.upperPage = this.currPage; // the new upper page is the old current one (were moving to lower)

                    this.currPage = this.lowerPage; // the lower becomes the new current page

                    reuseStartRow = this.lowerPage.endRow + 1;
                    this.lowerPage = this._updatePageForRow(pageToReuse, reuseStartRow, !scrollToLower);
                } else if (!scrollToLower && !this.currPage.isFirstPage) {
                    // scrolling upwards e.g. from row 100 to row 20
                    this.lowerPage = this.currPage; // the current page becomes the new lower page

                    this.currPage = this.upperPage; // the upper page is the new current page

                    reuseStartRow = this.upperPage.startRow - 1;
                    this.upperPage = this._updatePageForRow(pageToReuse, reuseStartRow, !scrollToLower);
                } // Safety Net.


                if (this.lowerPage && (this.lowerPage === this.currPage || this.lowerPage.startRow === this.currPage.startRow)) {
                    console.error("Lower & Upper page are equal!");
                    this.lowerPage = null;
                }

                if (this.upperPage && (this.upperPage === this.currPage || this.upperPage.startRow === this.currPage.startRow)) {
                    console.error("Lower & Upper page are equal!");
                    this.upperPage = null;
                }

                Debug.GUI.ListViewPages && console.log(this.viewId, "_handlePageChange");
                Debug.GUI.ListViewPages && this.upperPage && console.log("       upper: " + this.upperPage.startRow + " - " + this.upperPage.endRow);
                Debug.GUI.ListViewPages && this.currPage && console.log("       curr:  " + this.currPage.startRow + " - " + this.currPage.endRow);
                Debug.GUI.ListViewPages && this.lowerPage && console.log("       lower: " + this.lowerPage.startRow + " - " + this.lowerPage.endRow);
            }

            /**
             *
             * @param page
             * @param startRow
             * @param scrollToUpper if this argument is set the row-indices need to be decreased (when scrolling upwards)
             * @returns {*}
             * @private
             */
            _updatePageForRow(page, startRow, scrollToUpper) {
                Debug.GUI.ListView && console.log(this.viewId, "_updatePageForRow from " + startRow + " " + (scrollToUpper ? "upwards" : "downwards"));

                var updatedPage,
                    i,
                    row,
                    numRows = this._numRows(),
                    reusedCell;

                if (startRow < 0 && scrollToUpper) {
                    // we've reached the top. don't update the page.
                    return page;
                } else if (startRow >= numRows) {
                    // lower page no longer needed.
                    Debug.GUI.ListView && console.warn(this.viewId, "Page no longer needed: start=" + startRow + " - numberOfRows=" + numRows);
                    page && this._destroyPage(page);
                    return null;
                }

                if (!page) {
                    Debug.GUI.ListView && console.log(this.viewId, "    Initializing a new page!");
                    updatedPage = this._initializePage(startRow, scrollToUpper);

                    this._presentPage(updatedPage);
                } else {
                    // reusing a page.
                    // assume the number of cells remains the same after updating pages
                    page.startRow = scrollToUpper ? startRow - (page.cells.length - 1) : startRow;
                    page.startRow = Math.max(0, page.startRow); // can't be lower than 0;

                    Debug.GUI.ListView && console.log(this.viewId, "    Reusing an existing page with " + page.cells.length + " cells"); // update the cells themselves (content & offset).

                    for (i = 0; i < page.cells.length; i++) {
                        row = page.startRow + i;

                        if (this.collectedContent.sections[0].rows[row] && this.collectedContent.sections[0].rows[row].type !== page.cells[i].cellType) {
                            throw new Error("Reusing lists have not yet been adopted to use different cell types!");
                        } // It might happen, that there are more cells left to reuse than there is data to display (e.g. when
                        // a page is reused as last page). simply don't prepare all cells.


                        if (row >= 0 && row < numRows) {
                            reusedCell = page.cells[i];

                            if (reusedCell.animationFrame) {
                                window.cancelAnimationFrame(row.animationFrame);
                                delete reusedCell.animationFrame;
                            }

                            reusedCell.animationFrame = window.requestAnimationFrame(function (reusedCell, row, i) {
                                // check if this cell can be reused at all.
                                if (!this._canReuseCell(reusedCell)) {
                                    // cannot be reused, replace the cell with a new one.
                                    this._replaceCell(reusedCell, this._getCellForRow(row), page, i); // get a reference to the new one.


                                    reusedCell = page.cells[i];
                                }

                                try {
                                    this._prepareCellForRow(reusedCell, row, page);
                                } catch (ex) {
                                    console.error("Could not prepare the cell for reuse! Row: " + row + " - " + ex);
                                }

                                delete reusedCell.animationFrame;
                            }.bind(this, reusedCell, row, i));
                        } else if (row >= numRows) {
                            // check for excess rows
                            var numExcess = row + 1 - numRows;
                            var excessRows = page.cells.splice(page.cells.length - numExcess, numExcess);
                            Debug.GUI.ListView && console.log(this.viewId, "   excess rows detected, remove! " + numExcess);
                            excessRows.forEach(function (excRow) {
                                this.removeSubview(excRow);
                            }.bind(this));
                        }
                    } // update the page attributes


                    this._updatePageAttributes(page);

                    updatedPage = page;
                }

                return updatedPage;
            }

            /**
             * Moved to a separate implementation to enable interference for subclasses. E.g. if a cell is being moved
             * it should not be reused. Can also be used to tell the table which cell types can be reused and which
             * cannot be reused.
             * @param cell          the cell in question
             * @returns {boolean}   whether or not it can be reused.
             * @private
             */
            _canReuseCell(cell) {
                return true;
            }

            /**
             * This method is called whenever a cell could not be reused and should be replaced with new cell. This
             * method will be overwritten by some subclasses. E.g. the editable list will make use of it to avoid having
             * the currently moving cell removed when the pages change.
             * @param oldCell   the cell to remove and destroy.
             * @param newCell   the cell that the oldCell should be replaced with.
             * @param page      the page that currently contains this cell.
             * @param i         the index of the cell inside the pages cell array.
             * @private
             */
            _replaceCell(oldCell, newCell, page, i) {
                this.replaceSubview(oldCell, newCell);
                page.cells.splice(i, 1, newCell);
            }

            /**
             * Used when a reload event is called.
             * @private
             */
            _reloadPageContents() {
                Debug.GUI.ListView && console.log(this.viewId, "_reloadPageContents");

                if (!this.currPage) {
                    return; // the current page is the minimum that has to exist for this to work.
                } // update the current page first, otherwise the upper and lower pages cannot be initialized


                this._updatePageForRow(this.currPage, this.currPage.startRow); // if the upper page exists, update the content, if not, initiaize it - if needed


                if (this.upperPage) {
                    Debug.GUI.ListView && console.log("   updating upper page");
                    this.upperPage = this._updatePageForRow(this.upperPage, this.upperPage.startRow);
                } else if (this.currPage.startRow > 0) {
                    Debug.GUI.ListView && console.log("   updating upper page");
                    this.upperPage = this._updatePageForRow(this.upperPage, this.currPage.startRow - 1, true);
                }

                if (this.lowerPage) {
                    Debug.GUI.ListView && console.log("   updating lower page");
                    this.lowerPage = this._updatePageForRow(this.lowerPage, this.lowerPage.startRow);
                } else if (this.currPage.endRow < this._numRows() - 1) {
                    Debug.GUI.ListView && console.log("   updating lower page");
                    this.lowerPage = this._updatePageForRow(this.lowerPage, this.currPage.endRow + 1);
                }
            }

            /**
             * Removes all cells of a page from the list.
             * @param page
             * @private
             */
            _destroyPage(page) {
                Debug.GUI.ListView && console.log(this.viewId, "_destroyPage: " + page.startRow + " - " + page.endRow);
                page.cells.forEach(function (cell) {
                    this.removeSubview(cell);
                }.bind(this));
            }

            /**
             * This will call destroyPage on all existing pages and null their references.
             * @private
             */
            _destroyAllPages() {
                Debug.GUI.ListView && console.log(this.viewId, "_destroyAllPages");
                this.upperPage && this._destroyPage(this.upperPage);
                this.currPage && this._destroyPage(this.currPage);
                this.lowerPage && this._destroyPage(this.lowerPage);
                this.upperPage = null;
                this.currPage = null;
                this.lowerPage = null;
            }

            // ------------------------------------------------------------------------
            // ------------             Helper Methods                     ------------
            // ------------------------------------------------------------------------
            _numRows() {
                if (this.numberOfRows < 0) {
                    this.numberOfRows = this.collectedContent.sections[0].rows.length;
                }

                return this.numberOfRows;
            }

        }

        GUI.ReusingListView = ReusingListView;
    }
    return GUI;
}(window.GUI || {});

var ReusingListViewStyle = {
    PLAIN: "reusing-list-view-style-plain",
    GROUPED: "reusing-list-view-style-grouped",
    COMFORT_MODE_2020: "reusing-list-view-style-grouped reusing-list-view-style-comfort-mode-2020"
};
