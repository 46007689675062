'use strict';

import LxSettingsScreen from "./content/settingsScreen/LxSettingsScreen";
import LxTemperatureScreen from "./content/settingsScreen/temperatureScreen/LxTemperatureScreen";

define("ClimateControl", ["Control", "ClimateControlEnums", "./content/controlContent"], function (Control, ClimateControlEnums, ClimateControlContent) {
    return class ClimateControl extends Control {
        constructor() {
            super(...arguments); // In this particular case we pretend, that the ClimateController is capable of heating and cooling

            if (this.details.capabilities === ClimateControlEnums.CAPABILITIES.NONE) {
                this.details.capabilities = ClimateControlEnums.CAPABILITIES.HEATING_AND_COOLING;
            }
        }

        get tlimcLogic() {
            return this.isParamSetByLogic(ClimateControlEnums.ParameterFlags.COOLING_BOUNDARY);
        }

        get tlimhLogic() {
            return this.isParamSetByLogic(ClimateControlEnums.ParameterFlags.HEATING_BOUNDARY);
        }

        get modeByLogic() {
            return this.isParamSetByLogic(ClimateControlEnums.ParameterFlags.MODE);
        }

        getReactScreens() {
            return [
                ...super.getReactScreens(...arguments),
                {
                    name: ClimateControlEnums.SCREEN_STATE.SETTINGS,
                    component: LxSettingsScreen
                },
                {
                    name: ClimateControlEnums.SCREEN_STATE.TEMPS,
                    component: LxTemperatureScreen
                }
            ]
        }

        getReactControlContent() {
            return ClimateControlContent.default;
        }

        specificCreateCmdText(cmdParts) {
            var text;

            switch (cmdParts[1]) {
                case "resetMaintenance":
                    text = _("controls.hourcounter.maintenance.reset-interval");
                    break;
            }

            return text;
        }

        hasHeatingCapability() {
            return this.details.capabilities === ClimateControlEnums.CAPABILITIES.HEATING_AND_COOLING || this.details.capabilities === ClimateControlEnums.CAPABILITIES.HEATING;
        }

        hasCoolingCapability() {
            return this.details.capabilities === ClimateControlEnums.CAPABILITIES.HEATING_AND_COOLING || this.details.capabilities === ClimateControlEnums.CAPABILITIES.COOLING;
        }

        /**
         * Gets the description of the controls capability
         * @param states
         * @return {*}
         */
        getAutoModeDescription(states) {
            if (states.isAvgAutomatic) {
                if (this.details.capabilities === ClimateControlEnums.CAPABILITIES.HEATING_AND_COOLING) {
                    return _("controls.climate.auto-mode.outside-temp.title.mixed");
                } else if (this.details.capabilities === ClimateControlEnums.CAPABILITIES.HEATING) {
                    return _("controls.climate.auto-mode.outside-temp.title.heating");
                } else if (this.details.capabilities === ClimateControlEnums.CAPABILITIES.COOLING) {
                    return _("controls.climate.auto-mode.outside-temp.title.cooling");
                }
            } else {
                if (this.details.capabilities === ClimateControlEnums.CAPABILITIES.HEATING_AND_COOLING) {
                    return _("controls.climate.auto-mode.like-demand.title.mixed");
                } else if (this.details.capabilities === ClimateControlEnums.CAPABILITIES.HEATING) {
                    return _("controls.climate.auto-mode.like-demand.title.heating");
                } else if (this.details.capabilities === ClimateControlEnums.CAPABILITIES.COOLING) {
                    return _("controls.climate.auto-mode.like-demand.title.cooling");
                }
            }
        }

        /**
         * Converts the currentMode state into a matching Demand state
         * @return {*}
         */
        getCurrentDemand() {
            var states = this.getStates(),
                demand;

            switch (states.currentMode) {
                case ClimateControlEnums.MODE.HEATING:
                case ClimateControlEnums.MODE.HEATING_BOOST:
                case ClimateControlEnums.MODE.EXTERNAL_HEATER:
                    demand = ClimateControlEnums.DEMAND.HEATING;
                    break;

                case ClimateControlEnums.MODE.COOLING:
                case ClimateControlEnums.MODE.COOLING_BOOST:
                    demand = ClimateControlEnums.DEMAND.COOLING;
                    break;

                default:
                    demand = ClimateControlEnums.DEMAND.NONE;
                    break;
            }

            return demand;
        }

        isParamSetByLogic(paramFlag) {
            if (!this.details.hasOwnProperty("connectedParameters")) {
                return false;
            }
            return hasBit(this.details.connectedParameters, paramFlag);
        }

        isInputSetByLogic(inputFlag) {
            if (!this.details.hasOwnProperty("connectedInputs")) {
                return false;
            }
            return hasBit(this.details.connectedInputs, inputFlag);
        }

    };
});
