import { useCCEvent } from "LxComponents"
import { useCallback, useState } from "react";

/**
 * Updates when the app state changes (background/foreground)
 * @returns {object or bool}  the active Miniserver object or false if we don't have an active Miniserver
 */
function useActiveMSHook() {
    const [getActiveMs, setActiveMs] = useState(ActiveMSComponent.getActiveMiniserver());

    const handleCCEvent = useCallback((event, arg) => {
        switch (event) {
            case CCEvent.StartMSSession:
                setActiveMs(ActiveMSComponent.getActiveMiniserver());
                break;
            case CCEvent.StopMSSession:
                setActiveMs(null);
                break;
        }
    }, []);

    useCCEvent([CCEvent.StartMSSession, CCEvent.StopMSSession], handleCCEvent);

    return getActiveMs;
}

export default useActiveMSHook;
