import * as React from "react"
import Svg, { Path } from "react-native-svg"

function TrashCan(props) {
    return (
        <Svg isreact="true"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 30 30"
            xmlSpace="preserve"
            enableBackground="new 0 0 30 30"
            {...props}
        >
            <Path d="M21 10H9v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V10m-3.5-4h-5l-1 1H8v2h14V7h-3.5l-1-1" />
        </Svg>
    )
}

export default TrashCan
