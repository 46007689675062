import * as React from "react"
import Svg, { Path } from "react-native-svg"

function User(props) {
    return (
        <Svg isreact="true"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M10.197 3.839C9.736 4.724 8.183 5.5 6.875 5.5c-.636 0-1.279-.125-2.182-.681C5.322 2.049 7.46 0 10 0c2.666 0 4.894 2.254 5.394 5.234l-.505.125-.608.15c-1.451.28-2.353-.193-3.206-1.687a.501.501 0 00-.878.017z"
            />
            <Path
                d="M7 11.941c-1.503-1.162-2.656-3.285-2.473-6.069.914.487 1.565.628 2.348.628 1.374 0 2.881-.653 3.735-1.549.797 1.11 1.737 1.635 2.931 1.635.293 0 1.109-.054 1.948-.348.105 2.356-.986 4.542-2.489 5.704v2.206l4.677 1.67A3.508 3.508 0 0120 19.114V21a.5.5 0 01-.5.5H.5A.5.5 0 010 21v-1.886a3.51 3.51 0 012.322-3.296L7 14.147v-2.206z"
            />
        </Svg>
    )
}

export default User
