'use strict';

class lxInfoBar extends GUI.View {
    //region Static
    static Template = function () {
        var getTemplate = function getTemplate() {
            return $("<div class='lx-info-bar'>" + "   <div class='info-bar__text'></div>" + "   <div class='info-bar__icon'></div>" + "</div>");
        };

        return {
            getTemplate: getTemplate
        };
    }(); //endregion Static

    constructor(text, icon) {
        super(lxInfoBar.Template.getTemplate());
        this.text = text;
        this.icon = icon;
    }

    viewDidLoad() {
        super.viewDidLoad(...arguments);
        this.iconView = this.element.find('.info-bar__icon');
        this.textView = this.element.find('.info-bar__text');

        if (this.text) {
            this.setText(this.text);
        }

        if (this.icon) {
            this.setIcon(this.icon);
        } else {
            this.iconView.hide();
        }
    }

    setText(text) {
        this.textView.text(text);
    }

    setIcon(iconSrc) {
        var icon = ImageBox.getResourceImageWithClasses(iconSrc, "info-bar__icon");
        this.iconView.html(icon);
    }

}

GUI.LxInfoBar = lxInfoBar;
