'use strict';
/**
 *  content:
 *      title
 *      stateText
 *      stateTextColor
 *      stateTextPopup  (default true)     if true, the cell checks if the stateText is clipped, and if so, a popup will be presented on click
 *      [clickable] (default = true)
 *      [color]     optional background color for the cell.
 *      [subtitle]  optionally shown below the title
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.COMFORT_STATE = "ComfortStateCell";

    class ComfortStateCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getStateCellContent = function getStateCellContent() {
                var subtitle = "";
                return $('<div class="content__texts-placeholder">' + '<div class="texts-placeholder__title">' + '<div class="title__text text-overflow-ellipsis"></div>' + '</div>' + '<div class="texts-placeholder__subtitle">' + '<div class="subtitle__text text-overflow-ellipsis"></div>' + '</div>' + '</div>' + '<div class="content__state-text">' + '   <div class="state-text0 text-overflow-ellipsis"></div>' + '</div>');
            };

            return {
                getStateCellContent: getStateCellContent
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        viewDidLoad() {
            var promises = [super.viewDidLoad(...arguments)];
            promises.push(GUI.animationHandler.append(ComfortStateCell.Template.getStateCellContent(), this.contentPlaceholder).then(function () {
                this.elements.title = this.element.find('.title__text');
                this.elements.titleCntr = this.element.find('.texts-placeholder__title');
                this.elements.subtitle = this.element.find('.subtitle__text');
                this.elements.subtitleCntr = this.element.find('.texts-placeholder__subtitle');
                this.elements.stateLbl0 = this.element.find(".content__state-text .state-text0");
                this.elements.stateLblCntr = this.element.find(".content__state-text");
                this.updateContent(this.content);
            }.bind(this)));
            return Q.all(promises);
        }

        // ---------------------------------------------------------------------------------------------------------
        //   Methods for super fast tableContent updates
        // ---------------------------------------------------------------------------------------------------------
        cellTypeForReuse() {
            return GUI.TableViewV2.CellType.Special.COMFORT_STATE;
        }

        updateContent(newContent) {
            return super._updateContent(...arguments);
        }

        /**
         * This Method is used to apply a new content object onto the cell instance. e.g. update texts and so on.
         * @param newContent
         * @private
         */
        _applyNewContent(newContent) {
            var promise = super._applyNewContent(...arguments);

            GUI.animationHandler.schedule(function () {
                this.element.css("background-color", this.content.color || "");
                this.elements.subtitle.css("color", this.content.subtitleColor || "");
                this.elements.stateLbl0.css("color", this.content.stateTextColor || "");
            }.bind(this));
            this.setTitle(newContent.title);
            this.setSubtitle(newContent.subtitle);
            this.setStateText(newContent.stateText);
            return promise;
        }

        // ---------------------------------------------------------------------------------------------------------
        // ---------------------------------------------------------------------------------------------------------
        setContent(content) {
            if (typeof content.stateTextPopup === "undefined") {
                content.stateTextPopup = true;
            }

            if (!content.hasOwnProperty("clickable")) {
                content.clickable = true; // make state cells clickable, eg. statistics..
            }

            return super.setContent(...arguments);
        }

        setTitle(text) {
            this._updLabel(this.elements.titleCntr, this.elements.title, text);
        }

        setSubtitle(text) {
            this._updLabel(this.elements.subtitleCntr, this.elements.subtitle, text);
        }

        setStateText(text) {
            this._updLabel(this.elements.stateLblCntr, this.elements.stateLbl0, text, true);
        }

        clickableAllowed() {
            return true;
        }

        onSelected() {
            if (this.content.stateTextPopup) {
                this._checkStateTextPopup();
            }

            super.onSelected(...arguments);
        }

        // Private methods
        _updLabel(lblCntr, lbl, text, useHtml) {
            GUI.animationHandler.schedule(function () {
                lblCntr.toggle(!!text);
                useHtml ? lbl.html(text) : lbl.text(text);
                lbl.prop("title", text);
            });
        }

        _checkStateTextPopup() {
            var width = this.elements.stateLbl0.width(),
                text = this.elements.stateLbl0.text();
            this.elements.stateLbl0.text(text + "'"); // add a "tiny" character and check if the width is the same.. if so, it's already clipped..

            if (width === this.elements.stateLbl0.width()) {
                // content is clipped..
                NavigationComp.showPopup({
                    title: this.content.title,
                    message: this.content.stateText,
                    buttonOk: _('close')
                });
            }

            this.elements.stateLbl0.text(text);
        }

    }

    GUI.TableViewV2.Cells.ComfortStateCell = ComfortStateCell;
    return GUI;
}(window.GUI || {});
