import {useCallback, useContext, useMemo} from "react";
import {LxReactPressable, LxReactImageView, ControlContext, LxReactText} from "LxComponents";
import {View, StyleSheet, FlatList} from "react-native";
import globalStyles from "GlobalStyles";
import {useNavigation} from "@react-navigation/native";

function LxControlCommands(props) {
    const {control, states} = useContext(ControlContext)
    const navigation = useNavigation();

    const hasCommands = () => {
        let commands = [];
        if (control) {
            commands = control.getCommands(states) || [];
        }
        return !!commands.length;
    }

    const execute = useCallback((cmd) => {
        if (typeof cmd === "function") {
            cmd();
        } else if (typeof cmd === "string") {
            control.sendCommand(cmd);
        }
    }, [control]);



    const RowItem = ({item, index}) => {
        return <View style={styles.row}>
            {item.map((cmd, idx) => <CommandItem cmd={cmd} execute={execute} index={idx} key={"cmd-"+idx}/>)}
        </View>
    }


    const commandContent = useMemo(() => {
        if (!hasCommands()) {
            return [];
        }

        let commandItems = [];

        let currRowIdx = -1;
        let commands = control.getCommands(navigation);
        commands.forEach(cmd => {
            let rowIdx = currRowIdx;
            if (cmd.details && typeof cmd.details.rowIdx === "number" && cmd.details.rowIdx > -1) {
                rowIdx = cmd.details.rowIdx;
                currRowIdx = rowIdx;
            } else {
                rowIdx = ++currRowIdx;
            }
            if (!commandItems[rowIdx]) {
                commandItems[rowIdx] = [cmd]
            } else {
                commandItems[rowIdx].push(cmd);
            }
        });

        return commandItems
    }, [control, states])


    if (hasCommands()) {
        return <View style={styles.container}>
            <FlatList data={commandContent} renderItem={RowItem}/>
        </View>
    } else {
        return null;
    }

}

const Command = ({cmd, execute, index, type = GUI.TableViewV2.CellType.Special.COMFORT_ACTION}) => {
    let actionItem = cmd.cmd,
        isObjectAction = typeof actionItem === "object",
        cellStyle = {},
        actionProps = {},
        showIcon = cmd.icon && (type === GUI.TableViewV2.CellType.ROUND_COMFORT_ACTION || !nullEmptyString(cmd.name))

    if (isObjectAction) {
        Object.keys(actionItem).forEach(actionName => {
            if (actionItem[actionName] && actionName in Command.ActionMap) {
                actionProps[Command.ActionMap[actionName]] = execute.bind(this, actionItem[actionName]);
            }
        })
    } else {
        actionProps.onPress = execute.bind(this, actionItem);
    }

    if (!showIcon && cmd.name) {
        cellStyle = {
            ...cellStyle,
            ...baseStyles.textCell
        };
    }

    if (cmd.disabled) {
        cellStyle = {
            ...cellStyle,
            ...styles.disabledCell
        };
    }

    if (cmd.isActive) {
        cellStyle = {
            ...cellStyle,
            ...styles.activeCell
        };
    }

    const getChildComp = () => {
        if (showIcon) {
            return <LxReactImageView
                source={cmd.icon}
                imageStyle={styles.cellIcon}
                containerStyle={
                    type === GUI.TableViewV2.CellType.ROUND_COMFORT_ACTION ? styles.cellIconContainer : baseStyles.iconCell
                }/>
        } else if (cmd.name) {
            return <LxReactText
                style={
                    StyleSheet.flatten([
                        cmd.disabled ? styles.disabledCellText : styles.cellText, styles.centeredCellText
                    ])
                }>
                {cmd.name}
            </LxReactText>
        }
    }

    const getCompStyle = () => {
        if (showIcon) {
            return [
                type === GUI.TableViewV2.CellType.Special.COMFORT_ACTION ? styles.rowItem : null,
                index > 0 ? styles.cellWithMargin : null
            ]
        } else if (cmd.name) {
            return [
                styles.rowItem, index > 0 ? styles.cellWithMargin : null
            ];
        }
    }

    return <LxReactPressable {...actionProps}
                             style={getCompStyle()}
                             pressableStyle={cellStyle}
                             disabled={cmd.disabled}>
        {getChildComp()}
    </LxReactPressable>
}

Command.ActionMap = {
    "hit": "onHit",
    "release": "onReleased",
    "tap": "onPress",
    "doubleTap": "onDoublePress"
}

const CommandItem = ({cmd, execute, index}) => {
    if (!cmd) {
        return null;
    }

    let props = {
        cmd,
        index,
        execute
    };

    return <Command {...props} type={(cmd.details || {}).type}/>
}

const baseStyles = {
    textCell: {
        backgroundColor: globalStyles.colors.grey["300a36"],
        borderRadius: 4,
        paddingHorizontal: globalStyles.spacings.gaps.small,
        paddingVertical: globalStyles.spacings.gaps.regular
    },
    iconCell: {
        backgroundColor: globalStyles.colors.grey["300a36"],
        borderRadius: 4,
        paddingHorizontal: globalStyles.spacings.gaps.small,
        paddingVertical: globalStyles.spacings.gaps.small,
        alignItems: "center",
        justifyContent: "center",
        flex: 1
    }
}

const styles = {
    container: {
        width: "100%",
        marginBottom: 40
    },
    row: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    rowItem: {
        flex: 1,
        marginVertical: globalStyles.spacings.gaps.verySmall
    },
    disabledCell: {
        backgroundColor: globalStyles.colors.fill.tertiary
    },
    activeCell: {
        backgroundColor: globalStyles.colors.selected
    },
    cellWithMargin: {
        marginLeft: globalStyles.spacings.gaps.small
    },
    invisibleCell: {
        backgroundColor: globalStyles.colors.transparent
    },
    cellIcon: {
        width: globalStyles.sizes.icons.big,
        height: globalStyles.sizes.icons.big,
        fill: globalStyles.colors.text.primary
    },
    cellIconContainer: {
        padding: globalStyles.spacings.gaps.smaller,
        backgroundColor: globalStyles.colors.grey["300a36"],
        borderRadius: "50%"
    },
    cellText: {
        ...globalStyles.textStyles.body.bold,
        color: globalStyles.colors.text.primary
    },
    disabledCellText: {
        ...globalStyles.textStyles.body.bold,
        color: globalStyles.colors.text.tertiary
    },
    centeredCellText: {
        width: "100%",
        textAlign: "center",
    }
}

export default LxControlCommands;
