import globalStyles from "GlobalStyles";

export default class PresenceDetectorControlStatesSrc {
    static getStateSections(control, states) {
        let sections = [];

        let section = {},
            rows = [],
            activeSince = new LxDate(states.activeSince, true),
            activeSinceIsToday = activeSince.isSame(moment(), "d"),
            activeSinceFormat = activeSinceIsToday ? LxDate.getTimeFormat(true) : LxDate.getDateFormat(DateType.DateAndTimeShort);

        if (states.active) {
            rows.push({
                content: {
                    title: _('controls.presence.active-since'),
                    disclosureColor: globalStyles.colors.text.secondary,
                    disclosureText: activeSince.format(activeSinceFormat) + " " + _('timeSuffix')
                }
            });
        }

        rows.push({
            content: {
                title: _("controls.aalemergency.history"),
                disclosureIcon: true,
                clickable: true
            },
            type: GUI.TableViewV2.CellType.GENERAL,
            action: this.showTracker.bind(this, control)
        });

        section.rows = rows;
        sections.push(section)

        return sections;
    }

    static showTracker(control) {
        let trackerUUID = Object.keys(control.subControls)[0];
        let trackerControl = control.subControls[trackerUUID];
        trackerControl.name = _("controls.aalemergency.history")
        NavigationComp.showControlContent(trackerControl);
    }
}
