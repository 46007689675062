'use strict';

define(["LightV2ControlMoodList", "LightV2ControlEditMoodScreen", "LightV2ControlEnums"], function (LightV2ControlMoodList, LightV2ControlEditMoodScreen, LightV2ControlEnums) {
    return class LightV2ControlEditMoodList extends LightV2ControlMoodList {

        static Template = {
            getSectionDescription() {
                return $('<div class="edit-mood-list__detailed-description">' + _('controls.lightV2controller.moods.edit.description', {
                    icon: ImageBox.getResourceImageWithClasses(Icon.Light.PLUS_SQUARE, "detailed-description__icon")
                }) + '</div>');
            }
        }



        constructor(control) {
            super(...arguments);
        }

        viewDidLoad() {
            var baseCall = super.viewDidLoad(...arguments);
            this.element.addClass("edit-mood-list");

            if (HD_APP) {
                this.element.addClass("edit-mood-list--hd");
            }

            return baseCall;
        }

        titleBarText() {
            return _("controls.lightV2controller.moods.edit");
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.CLOSE
            };
        }

        styleForTableView() {
            return TableViewStyle.GROUPED;
        }

        /**
         * The baseclass ensures no unnecessary reloads are performed. So only respond to this method instead of over-
         * writing the update() method.
         */
        handleTableReloaded() {
            super.handleTableReloaded(...arguments); // Making every section except the last one editable

            for (var sectionIdx = 0; sectionIdx < this.tableViewContent.length; sectionIdx++) {
                var section = this.tableViewContent[sectionIdx];

                if (section.editable) {
                    this.tableView.setEditingModeForSection(sectionIdx, true, false, true, 0, section.rows.length);
                } else {
                    this.tableView.setEditingModeForSection(sectionIdx, false, false, false, 0, section.rows.length);
                }
            }
        }

        didCheckCell() {// Ignore this function, just override it
        }

        // TableViewDelegate methods
        buttonTapped(section, row, tableView, cell) {
            this._showEditMoodScreen(cell.content.id);
        }

        buttonReleased(section, row, tableView, cell) {
            this._showEditMoodScreen(cell.content.id);
        }

        // Editable TableView delegates
        titleForFooterInSection(section, tableView) {
            var sectionObj = this.tableViewContent[section];

            if (sectionObj.hasOwnProperty("isFavorite") && sectionObj.rows.length === 0) {
                if (sectionObj.isFavorite) {
                    return _("controls.lightV2controller.moods.no-mood") + "<br>" + _("controls.lightV2controller.moods.no-mood.description", {
                        sign: "+",
                        moodList: _("controls.lightV2controller.moods.favorite")
                    });
                } else {
                    return _("controls.lightV2controller.moods.no-mood") + "<br>" + _("controls.lightV2controller.moods.no-mood.description", {
                        sign: "-",
                        moodList: _("controls.lightV2controller.moods.additional")
                    });
                }
            } else {
                return null;
            }
        }

        favoriteStateChanged(isFav, moodId, section, row) {
            if (isFav) {
                this.sendCommand(Commands.format(Commands.LIGHTCONTROL_V2.MOOD.FAVORITE.MOVE_TO, moodId));
            } else {
                this.sendCommand(Commands.format(Commands.LIGHTCONTROL_V2.MOOD.ADDITIONAL.MOVE_TO, moodId));
            }
        }

        didMoveCell(section, row, tableView, cell, oldIdx, newIdx) {
            var sectionObj = this.tableViewContent[section],
                id = sectionObj.rows[oldIdx].content.id;
            this.sendCommand(Commands.format(Commands.LIGHTCONTROL_V2.MOOD.MOVE, id, newIdx));
        }

        // Private
        _showEditMoodScreen(moodId) {
            this.sendCommand(Commands.format(Commands.LIGHTCONTROL_V2.MOOD.CHANGE_TOO, moodId)).then(function () {
                this.ViewController.showState(LightV2ControlEnums.ScreenState.EDIT_MOOD, null, {
                    control: this.control,
                    mood: moodId
                });
            }.bind(this));
        }

        _prepareTableViewContent(states) {
            this.tableViewContent = [];
            this.tableViewContent.push({
                rows: [{
                    type: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        title: _("controls.lightV2controller.mood.new"),
                        leftIconSrc: Icon.TRANSLUCENT_ADD,
                        clickable: true,
                        leftIconColor: window.Styles.colors.stateActive,
                        action: function () {
                            this.ViewController.showState(LightV2ControlEnums.ScreenState.NEW_MOOD, null, {
                                control: this.control
                            });
                        }.bind(this)
                    }
                }]
            });
            var moodsSection = {
                headerElement: LightV2ControlEditMoodList.Template.getSectionDescription(),
                rows: [],
                editable: true
            };
            states.moodList.forEach(function (mood) {
                moodsSection.rows.push(this._createMoodCellObj(mood, states));
            }.bind(this));
            this.tableViewContent.push(moodsSection);
        }

        _createMoodCellObj(mood, states) {
            var isFav = states.favoriteMoods.indexOf(mood.id) !== -1;
            return {
                type: GUI.TableViewV2.CellType.Special.MOOD_CELL,
                content: {
                    title: mood.name,
                    selected: states.activeMoods.includes(mood.id),
                    id: mood.id,
                    // Editing a Mood is always possible!
                    // Wrike: 179048785
                    showButton: true,
                    isMulti: false,
                    buttonSrc: Icon.SETTINGS,
                    selectable: true,
                    isFav: isFav,
                    iconSrc: this.control.getMoodIcon(mood)
                }
            };
        }

    };
});
