import FileRequest from "./FileRequest";

/**
 * Used to store/read files with their version in/from the storageHandler provided.
 */
class CacheFileHandler {
    static ShowLog = false;

    /**
     * @param storageHandler:StorageHandlerBase
     */
    constructor(storageHandler) {
        this._storageHandler = storageHandler;
    }

    purge(fileRequest) {
        this._storageHandler.deleteFile(fileRequest.name);
        this._storageHandler.deleteFile(this._getVersionFileRequest(fileRequest));
    }

    saveFile(fileRequest) {
        const fileSavePromises = [];
        fileSavePromises.push(this._saveFileToStorage(fileRequest));
        fileSavePromises.push(this._saveVersionToStorage(fileRequest));
        return Q.all(fileSavePromises);
    }

    getFile(fileRequest) {
        const filePromise = this._storageHandler.getFile(fileRequest.name, fileRequest.dataType);
        return Q.all([this.getVersion(fileRequest), filePromise]).then(results => {
            fileRequest.data = results[1];
            fileRequest.version = results[0];
            return fileRequest;
        });
    }

    getVersion(fileRequest) {
        const versionFileRequest = this._getVersionFileRequest(fileRequest);
        return this._storageHandler.getFile(versionFileRequest.name, DataType.STRING);
    }

    // region persistence

    _saveFileToStorage(fileRequest) {
        const name = fileRequest.name,
            dataType = fileRequest.dataType;
        return this._storageHandler.saveFile(name, fileRequest.data, dataType);
    }

    _saveVersionToStorage(fileRequest) {
        const versionFileRq = this._getVersionFileRequest(fileRequest);
        return this._storageHandler.saveFile(versionFileRq.name, fileRequest.version, DataType.STRING)
    }

    // endregion

    _getVersionFileRequest(fileRequest) {
        return new FileRequest(this._getVersionInfoFileName(fileRequest.name), DataType.STRING);
    }


    /**
     * Creates a separate file name for the version-info-string
     * @returns {string}
     * @private
     */
    _getVersionInfoFileName(name) {
        let periodIdx = name.lastIndexOf(".");
        if (periodIdx >= 1) {
            return name.substring(0, periodIdx) + ".modifiedVersion" + name.substring(periodIdx);
        } else {
            return name + ".modifiedVersion";
        }
    }
}

export default CacheFileHandler;
