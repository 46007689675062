import {useCallback, useEffect, useState} from "react";
import {useDebounceFn} from "ahooks";
import {useCCEvent, useIsInForeground} from "../Components";


export default function useFoundMiniservers() {
    const [results, setResults] = useState({
        results: [],
        started: false,
        hasWiFi: PlatformComponent.getNetworkStatus().status !== NetworkStatus.CELL // we may not know about the network, if so, assume it's WiFi
    });
    const isInForeGround = useIsInForeground();


    /**
     * Since search results arrive very fast, use a debounce to set the results. Otherwise it'll cause too many re-renders.
     */
    const { run, cancel, flush } = useDebounceFn((newState) => {
        setResults((prevState) => {
            return {...prevState, ...newState};
        })
    }, {
        wait: 200
    })

    useCCEvent([CCEvent.NetworkChanged], useCallback(()=> {
        const currStatus = PlatformComponent.getNetworkStatus().status;
        run({
            hasWiFi: currStatus !== NetworkStatus.CELL // we may not know about the network, if so, assume it's WiFi
        });
        flush();
    }, []));

    const foundMiniserver = (ev, newMiniservers, allMiniservers) => {
        run({results: [...allMiniservers]});
    }

    useEffect(() => {
        const listeners = [];

        if (isInForeGround) {
            listeners.push(MiniserverFinder.on(MiniserverFinder.Event.FOUND_MINISERVER, foundMiniserver));
            listeners.push(MiniserverFinder.on(MiniserverFinder.Event.NEW_SEARCH_STARTED, () => {
                run({started: true});
                flush(); // flush important, to ensure it switches to "searching"-state initially
            }));
            listeners.push(MiniserverFinder.on(MiniserverFinder.Event.SEARCH_FINISHED, () => {
                run({started: false});
                flush();
            }));
            MiniserverFinder.startMiniserverFinder();
        } else {
            MiniserverFinder.stopMiniserverFinder();
        }

        return () => {
            flush();
            listeners.forEach(removeFn => removeFn());
            MiniserverFinder.stopMiniserverFinder();
            cancel();
        }
    }, [isInForeGround])

    return results;
}