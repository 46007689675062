import TempDisplay from "./tempDisplay";

export default function SaunaTempDisplay({controlUuid, containerStyle, forCell = false}) {
    return <TempDisplay
        controlUuid={controlUuid}
        containerStyle={containerStyle}
        forCell={forCell}
        tempState={"tempActualVal"}
    />
}
