import {LxReactMiniserverList} from "LxComponents";
import globalStyles from "GlobalStyles";
import {useRef} from "react";
import useIsPairedApp from "../../../react-comps/PairedApp/useIsPairedApp";
import PairedTabletWarning, {PairedTabletWarningOrigin} from "../../../react-comps/PairedApp/PairedTabletWarning";

export default function ArchiveScreen({navigation, route}) {

    const hasMiniservers = useRef(PersistenceComponent.hasMiniservers());
    const isPairedApp = useIsPairedApp();

    if (isPairedApp) {
        // This is a safety net if for some reason the user manages to reach the archive while being paired.
        return <PairedTabletWarning origin={PairedTabletWarningOrigin.ARCHIVE} />
    }
    return <LxReactMiniserverList
        style={globalStyles.customStyles.screenContent}
        showAddMsInitially={!hasMiniservers.current}
        isQuickSelection={false}
    />
}
