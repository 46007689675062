'use strict';
/*
 title
 selected
 showButton
 buttonSrc
 isMulti -> If multiple moods are selected
 iconSrc    depends on the flags of the mood (isMovementMood, isT5Mood, isAlarmClockMood)

 Delegate
 buttonTapped(section, row, tableView)
 buttonHit(section, row, tableView)
 buttonReleased(section, row, tableView)
 favoriteStateChanged(isFav, moodId, section, row, tableView)
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.MOOD_CELL = "MoodCell";

    class MoodCell extends GUI.TableViewV2.Cells.SceneCell {
        //region Static
        static Template = function () {
            var getRightButton = function getRightButton(iconSrc, isMulti) {
                return $('<div class="content__button">' + getRightButtonIcon(iconSrc, isMulti) + '</div>');
            };

            var getIcon = function getIcon(iconSrc) {
                return $('<div class="cell__icon-placeholder">' + ImageBox.getResourceImageWithClasses(iconSrc, "icon-placeholder__icon icon--add") + '</div>');
            };

            var getAddButton = function getAddButton() {
                return $('<div class="cell__icon-placeholder">' + ImageBox.getResourceImageWithClasses(Icon.Light.PLUS_SQUARE, "icon-placeholder__icon icon--add") + '</div>');
            };

            var getRightButtonIcon = function getRightButtonIcon(iconSrc, isMulti) {
                var icon;

                if (isMulti) {
                    icon = ImageBox.getResourceImageWithClasses(Icon.REMOVE, 'button__icon button__icon--remove');
                } else {
                    icon = ImageBox.getResourceImageWithClasses(iconSrc, 'button__icon button__icon--right');
                }

                return icon;
            };

            return {
                getRightButton: getRightButton,
                getAddButton: getAddButton,
                getIcon: getIcon,
                getRightButtonIcon: getRightButtonIcon
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("mood-cell");
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                if (this.editMode) {
                    this._addLeftButton();
                }

                if (this.content.iconSrc) {
                    // insert right after the text placeholder
                    MoodCell.Template.getIcon(this.content.iconSrc).insertAfter(this.element.find(".content__texts-placeholder"));
                }
            }.bind(this));
        }

        startEditMode() {
            super.startEditMode(...arguments);

            this._addLeftButton();
        }

        updateUI(selected) {
            super._setCircleSelected(...arguments);

            this.element.toggleClass("mood-cell--selected", selected);

            if (this.content.showButton || this.content.isMulti) {
                this._addRightButton();
            } else if (this.rightButton && !this.rightButton.isHidden) {
                this._removeRightButton();
            }
        }

        destroy() {
            if (this.leftButton) {
                this.leftButton.onButtonTapped = null;
            }

            return super.destroy(...arguments);
        }

        // Private
        // Override this function
        _addRightButton() {
            if (!this.rightButton) {
                var rightButton = this._getTemplate().getRightButton(this.content.buttonSrc, this.content.isMulti);

                this.rightButton = new GUI.LxButton(this, rightButton[0], Color.BUTTON_GLOW, [{
                    part: rightButton.children()[0],
                    mode: 'fill'
                }], true);
                this.appendSubview(this.rightButton, this.element.find('.cell__content'));
            } else if (this.rightButton.isHidden) {
                this.showSubview(this.rightButton);
            }
        }

        _removeRightButton() {
            this.rightButton && this.hideSubview(this.rightButton);
        }

        _addLeftButton() {
            if (!this.content) {
                return; // content not ready yet, bail out.
            }

            if (this.editMode) {
                // Hide the circular icon of the moodCell it will occupy to much space. Regular hide wont work here,
                // must use "important" as the checkableCell enforces to show the icon using !important.
                this.leftIcon.attr('style', 'display: none !important'); // Only setup the leftButton if it doesn't exist yet

                if (!this.leftButton) {
                    var leftButton = MoodCell.Template.getAddButton(); // Tint the add-icon active if it is a favorite mood

                    if (this.content.isFav) {
                        leftButton.find(".icon--add").css("fill", window.Styles.colors.stateActive);
                    }

                    this.leftButton = new GUI.LxButton(this, leftButton[0], Color.BUTTON_GLOW, [{
                        part: leftButton.children()[0],
                        mode: 'fill'
                    }], true);
                    this.prependSubview(this.leftButton, this.element.find('.cell__content'));
                    this.leftButton.onButtonTapped = this._favoriteStateChanged.bind(this);
                }
            } else {
                this.leftIcon.show();
            }
        }

        _favoriteStateChanged() {
            if (this.delegate.favoriteStateChanged) {
                this.delegate.favoriteStateChanged.call(this.delegate, !this.content.isFav, this.content.id, this.sectionIdx, this.rowIdx, this.tableView);
            }
        }

        _getTemplate() {
            return MoodCell.Template;
        }

    }

    GUI.TableViewV2.Cells.MoodCell = MoodCell;
    return GUI;
}(window.GUI || {});
