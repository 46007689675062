'use strict';

import {
    LxWrapperButton
} from "LxComponents";
/**
 * A generic OnboardingScreen
 * @param [id] - defined in the static part of the screen to track the different onboardings
 * @param [iconSrc] - The iconSrc to show
 * @param [iconColor] - The icon fill color
 * @param title - The Title to show
 * @param message - The message to show
 * @param [continueBtnText] - The button text to show
 * @param [asView] - The screen will stay visible when clicking the continue button, also no screenButtons will be visible
 * @param continueDef - The defer that is resolved when the screen hides (via button (true) or closeAction(false))
 * @param [primaryColor] - The color of the continueButton
 */

class OnboardingScreen extends GUI.Screen {
    //region Static
    static IDS = {
        AUTOMATIC_DESIGNER: "automaticDesigner"
    };
    static STORAGE_ID = "shownOnboardingIds";

    static getShownIds() {
        return JSON.parse(PersistenceComponent.getLocalStorageItem(this.STORAGE_ID) || "[]");
    }

    static shouldShow(id) {
        return !this.getShownIds().includes(id);
    }

    static Template = class {
        //region Static
        static getButtonTemplate() {
            return $("<div class='button-container'></div>");
        } //endregion Static


    }; //endregion Static

    constructor(details) {
        super($("<div />"));
        this.id = details.id;
        this.iconSrc = details.iconSrc;
        this.iconColor = details.iconColor;
        this.title = details.title;
        this.message = details.message;
        this.message2 = details.message2;
        this.continueBtnText = details.continueBtnText;
        this.continueDef = details.continueDef;
        this.asView = details.asView;
        this.primaryColor = details.primaryColor;
    }

    viewDidLoad() {
        return super.viewDidLoad(...arguments).then(function () {
            this.element.toggleClass("onboarding-screen--as-view", !!this.asView);
            this.elements.subtitleLbl2 = $('<div class="screen__subtitle-lbl2" />');
            this.element.prepend(this.elements.subtitleLbl2);
            this.setUpComfortUI(!!this.iconSrc);
            this.setIcon(this.iconSrc);
            this.setIconColor(this.iconColor);
            this.setTitle(this.title);
            this.setSubtitle(this.message);
            this.setSubtitle2(this.message2);
            let buttonContainer = $('<div class="button-container" >');
            this.element.append(buttonContainer);

            if (this.continueBtnText) {
                let buttonStyle = {};

                if (this.primaryColor) buttonStyle["backgroundColor"] = this.primaryColor;
                this.appendReactComp({
                    reactComp: LxWrapperButton,
                    compProps: {
                        text: this.continueBtnText,
                        containerStyle: buttonStyle,
                        onButtonTapped: this.continueBtnAction.bind(this)
                    },
                    target: buttonContainer
                });
            }

            if (this.id) {
                var shownIds = this.constructor.getShownIds();
                shownIds.push(this.id);
                PersistenceComponent.setLocalStorageItem(this.constructor.STORAGE_ID, JSON.stringify(shownIds));
            }
        }.bind(this));
    }

    continueBtnAction() {
        if (this.asView) {
            this.continueDef.notify(true);
        } else {
            this.ViewController.navigateBack().done(function () {
                this.continueDef.resolve(true);
            }.bind(this));
        }
    }

    getStateIconTemplatePath() {
        return Icon.STATE_ICON.NFC_LEARN;
    }

    getAnimation() {
        return AnimationType.MODAL;
    }

    closeAction() {
        this.continueDef.resolve(false);
        return super.closeAction(...arguments);
    }

    getLeftIcon() {
        if (this.asView) {
            return null;
        } else {
            return super.getLeftIcon(...arguments);
        }
    }

    setSubtitle2(subtitle2) {
        if (this.elements.subtitleLbl2) {
            if (subtitle2) {
                this.elements.subtitleLbl2.text(subtitle2);
            } else {
                this.elements.subtitleLbl2.empty();
            }
        }
    }

    _getButtonTemplate() {
        return OnboardingScreen.Template.getButtonTemplate(this.continueBtnText);
    }

}

GUI.OnboardingScreen = OnboardingScreen;
