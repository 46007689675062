import {View} from "react-native";
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import {
    ControlContext,
    LxReactTableView,
    LxReactText,
    navigatorConfig,
    useSetURLHook
} from "LxComponents";
import globalStyles from "GlobalStyles";
import Icons from "IconLib"
import {ScreenState} from "../../alarmClockControlEnums";
import {shortenOperatingMode} from "../../../../UtilityComp/utilities/helper";
import EditEntrySingelton from "./EditEntrySingelton";

function LxEditEntryScreen({navigation, route}) {
    const entryFromParams = route.params && route.params.entry;
    const {control, states} = useContext(ControlContext);
    const [entry, setEntry] = useState(entryFromParams || generateTimeEntry());
    const [tableContent, setTableContent] = useState(null);

    const dataRef = useRef({
        time: getInitialTimeFromEntry(entry)
    });

    const getNewTime = () => dataRef.current.time

    useSetURLHook({
        urlPart: "EditEntry"
    })

    const isEditMode = () => !!entryFromParams;

    useEffect(() => {
        navigation.setOptions({
            ...navigatorConfig({
                title: isEditMode() ? _("controls.alarmClock.editEntry") : _("controls.alarmClock.addEntry"),
                animationType: AnimationType.MODAL,
                rightActions: [{
                    action: ({dimensions, props, key}) => {
                        return <Icons.Tick style={globalStyles.customStyles.reactTitleBarIcon}/>
                    }
                }],
                onRightAction: (idx) => {
                    if (entry.name && Regex.CMD_TEXT.test(entry.name)) {
                        // don't add if an invalid name is specified.
                        addNewEntry();
                    }
                }
            })
        })
    }, [entry])

    useEffect(() => {
        dataService.init(entry.modes)
        const modeSubscription = dataService.getSelectedModes().subscribe(newModes => {
            setEntry((prevEntry) => {
                return {
                    ...prevEntry,
                    modes: newModes
                }
            })
        })

        return () => {
            modeSubscription.unsubscribe();
        }
    }, [])

    useEffect(() => {
        if (!isEditMode()) {
            // don't use sort()
            // use sort(function(a, b){return a-b}) instead --> casts the strings to integers
            //Object.keys returns strings, we do need a numeric sort here
            let lastEntryID = parseInt(Object.keys(states.entryList).sort((a, b) => a - b).pop());

            if (isNaN(lastEntryID)) {
                lastEntryID = -1;
            }

            setEntry({
                ...entry,
                id: lastEntryID + 1
            })
        }
    }, [states.entryList])

    const deleteEntry = () => {
        control.sendCommand(Commands.format(Commands.ALARM_CLOCK.DELETE_ENTRY, entry.id));
        navigation.goBack();
    }

    const addNewEntry = () => {
        // As designed --> after editing an entry the entry has to be active
        // the entry is active by default if you are creating a new one
        let finalEntry = entry;
        if (finalEntry.name === "") {
            finalEntry.name = _('controls.alarmClock.defaultEntryName');
        }
        finalEntry.isActive = true;
        let times = getNewTime().split(":"),
            hours = parseInt(times[0]),
            minutes = parseInt(times[1]),
            seconds = hours * 3600 + minutes * 60,
            // We need to send the daily flag instead of the operating modes if this is a NightLight Touch
            // Modes must be either be a numerical value as a String or an array of numerical values separated by a ","
            // entry.daily is a Bool +entry.daily gives us a number
            modes = finalEntry.nightLight ? (+finalEntry.daily).toString() : finalEntry.modes.toString(),
            cmd = Commands.format(Commands.ALARM_CLOCK.ALARM_ENTRY, finalEntry.id, finalEntry.name, seconds, finalEntry.isActive, modes);
        control.sendCommand(cmd);
        navigation.goBack();
    }

    const showRecurringList = () => {
        navigation.navigate(ScreenState.RECURRING_LIST);
    }

    useEffect(() => {
        let times = getNewTime().split(":"),
        hour = parseInt(times[0]),
        minutes = parseInt(times[1]),
        weekdays = [],
        abnormalDays = [],
        maxEntries = false;

        for (let i = 0; i < entry.modes.length; i++) {
            let mode = shortenOperatingMode(entry.modes[i]);

            if (entry.modes[i] >= 3 && entry.modes[i] <= 9) {
                if (maxEntries) continue;

                if (weekdays.length <= 2) {
                    weekdays.push(mode);
                } else {
                    weekdays.push("…");
                    maxEntries = true;
                }
            } else {
                abnormalDays.push(mode);
            }
        }

        let tableContent = [{
            //Alarm name
            rows: [{
                type: GUI.TableViewV2.CellType.INPUT,
                content: {
                    type: "text",
                    value: entry.name,
                    placeholder: _("controls.alarmClock.enterDesignation"),
                    validationRegex: Regex.CMD_TEXT,
                    filterRegex: Regex.CMD_TEXT_FILTER,
                    showGreen: false
                },
                textChanged: (sectionIdx, rowIdx, table, value, isValid) => {
                    setEntry({
                        ...entry,
                        name: isValid || value !== "" ? value : null
                    })
                }
            }]
        }, {
            rows: [{
                type: GUI.TableViewV2.CellType.TIME_PICKER,
                content: {
                    iconSrc: Icon.AlarmClock.ALARM_CLOCK,
                    title: _("controls.alarmClock.wakingTime"),
                    value: [hour, minutes],
                    expanded: true
                },
                onPickerChanged: (sectionIdx, rowIdx, table, value) => {
                    dataRef.current.time = moment(value).format('HH:mm');
                }
            }]
        }]; // NightLight Touch does only support recurring on a daily or single alerts
        // Also NightLight Touch entries may not be deleted!

        if (entry.nightLight) {
            tableContent.splice(0, 0, {
                headerElement: <EditEntryHeaderView/>
            });
            tableContent.push({
                rows: [{
                    type: GUI.TableViewV2.CellType.SWITCH,
                    content: {
                        icon: Icon.AlarmClock.RECURRING,
                        title: _("daily"),
                        active: entry.daily,
                        useAntiGhostTimer: true
                    },
                    onSwitchChanged: (newValue, sectionIdx, rowIdx, table, cellObj) => {
                        setEntry({
                            ...entry,
                            daily: !entry.daily
                        })
                    }
                }]
            });
        } else {
            tableContent.push({
                rows: [{
                    type: GUI.TableViewV2.CellType.Special.ALARM_CLOCK_ENTRY,
                    content: {
                        iconSrc: Icon.AlarmClock.RECURRING,
                        name: _("controls.alarmClock.repeat"),
                        modes: entry.modes,
                        hideSwitch: true
                    },
                    action: showRecurringList
                }]
            });

            if (isEditMode()) {
                tableContent.push({
                    rows: [{
                        type: GUI.TableViewV2.CellType.DELETE,
                        action: deleteEntry
                    }]
                });
            }
        }
        setTableContent(tableContent);
    }, [entry]);

    return <View>
        {tableContent && <LxReactTableView tableContent={tableContent}/>}
    </View>
}

const getInitialTimeFromEntry = (entry) => LxDate.formatSecondsIntoDigits(entry.alarmTime, true, false, true);


const generateTimeEntry = () => {
    var msTime = SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINUTES_SINCE_MIDNIGHT) * 60,
        momentDayIdx = moment().day(),
        momentMsDayOffset = 2,
        // Moment.js Monday = 1, Miniserver Monday = 3
        nextDayMode = momentDayIdx + momentMsDayOffset + 1; // A new entry should repeat itself tomorrow (today + 1)
    // Restart on monday if creating entries on Sunday

    if (nextDayMode > 9) {
        nextDayMode = 3;
    }

    return {
        name: _('controls.alarmClock.defaultEntryName'),
        alarmTime: msTime,
        modes: [nextDayMode],
        isActive: true,
        nightLight: false
    };
}

function EditEntryHeaderView(props) {
    return <View style={styles.infoView.container}>
        <LxReactText style={styles.infoView.label}>{headerInfoText}</LxReactText>
    </View>
}

const headerInfoText = _("controls.alarmClock.default-alarm.message", {
    productName: _('controls.alarmClock.touchNightLight.product-name')
})


const styles = {
    infoView: {
        container: {
            backgroundColor: globalStyles.colors.grey["300a36"],
            padding: globalStyles.spacings.gaps.small,
            marginVertical: globalStyles.spacings.gaps.small,
            marginHorizontal: globalStyles.spacings.contentHorizontal
        },
        label: {
            ...globalStyles.textStyles.footNote.default,
            color: globalStyles.colors.text.secondary,
            marginTop: globalStyles.spacings.gaps.verySmall
        }
    }
}

const dataService = EditEntrySingelton.getInstance();

LxEditEntryScreen.propTypes = {
    entry: PropTypes.object
}

export default  LxEditEntryScreen
