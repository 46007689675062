'use strict';

define(["ConnectingWaitingScreen", "./initialSearchScreen.jsx"], function (ConnectingWaitingScreen, InitialSearchScreenReact) {
    return class InitialSearchScreen extends ConnectingWaitingScreen {

        static ReactComp = InitialSearchScreenReact.default

        constructor() {
            super({
                state: WaitingState.MiniserverSearch
            });
        }
    };
});
