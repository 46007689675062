'use strict';

define(["MessageCenterMessageScreen", "LxComponents"], function (
    MessageCenterMessageScreen,
    {
        ReactWrapper,
        LxReactQuickSelect
    }
) {
    return class MessageCenterMessagesScreen extends GUI.TableViewScreenV2 {

        #sortBySeverity = true;

        get useNewTableView() {
            return true;
        }

        set sortBySeverity(value) {
            this.#sortBySeverity = value;
            this.reloadTable();
        }

        constructor(details) {
            super($('<div />')); // This screen will forcefully fetch the messageCenter structure if either of these entries is set
            // this.entryUuid: Will try to navigate to the entry with this uuid if available,
            //                 if the entry has already been set historic the historic screen will be displayed
            // this.forceFetch: Just fetches the structure, no special navigation

            this.entryUuid = details ? details.entryUuid : null;
            this.forceFetch = details ? details.forceFetch : null;
            this.messageCenterStructure = null;
        }

        viewDidLoad() {
            // We need to forcefully fetch the messageCenter structure if we should open a specific entry, or the forceFetch flag is set
            // We can't ensure to have the most up to date entries available when opening the app with an messageCenter url scheme (PushNotification)
            var forceFetch = this.entryUuid || this.forceFetch || !ActiveMSComponent.hasMessageCenterStructure();
            return Q.all([
                forceFetch ? this._fetchStructure() : true,
                super.viewDidLoad(...arguments) || true]
            );
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            return _("message-center.name");
        }

        getRightIcon() {
            return Icon.Buttons.INFO;
        }

        rightAction() {
            NavigationComp.showPopup({
                title: _("message-center.about"),
                message: _("message-center.about.description"),
                buttonOk: _("update.more-info"),
                buttonCancel: true,
                icon: Icon.MESSAGE_CENTER.HEALTH,
                type: PopupType.GENERAL
            }).then(function () {
                NavigationComp.openWebsite("https://www.loxone.com/help/systemstatus");
            });
        }

        getURL() {
            return UrlStartLocation.MESSAGE_CENTER;
        }

        viewWillAppear() {
            var prms = super.viewWillAppear(...arguments);
            this.deregMessageCenterUpdateReceiver = ActiveMSComponent.registerForMessageCenterUpdate(this._messageCenterUpdate.bind(this));
            return prms;
        }

        viewWillDisappear() {
            this.deregMessageCenterUpdateReceiver();
            this.entryUuid = null;
            return super.viewWillDisappear(...arguments);
        }

        getWaitingForMessage() {
            return _("message-center.loading");
        }

        reloadTable() {
            let options = [
                _("message-center.active"),
                _("message-center.history")
            ]
            this.tableContent = [];
            if (this.messageCenterStructure) {
                if (this.#sortBySeverity) {
                    this.tableContent = this._getActiveTableContent();
                } else {
                    this.tableContent = this._getHistoricTableContent();
                }

                this.tableContent[0].headerElement = ReactWrapper.React.createElement(LxReactQuickSelect, {
                    options: options,
                    selectedValue: options[+!this.#sortBySeverity],
                    onOptionSelected: (value, idx) => {
                        this.sortBySeverity = !idx;
                    }
                })
            }

            return super.reloadTable(...arguments);
        }

        /**
         * Forcefully fetch the MessageCenter structure from the Miniserver,
         * also displays a waiting popup if the structure isn't returned after 500ms
         * @return {Promise}
         * @private
         */
        _fetchStructure() {
            return ActiveMSComponent.fetchMessageCenterStructure(true);
        }

        _messageCenterUpdate(ev, messageCenterStructure) {
            this.messageCenterStructure = messageCenterStructure;

            this._updateUI(); // Check if we have an entryUuid to navigate to (PushNotification)


            if (this.entryUuid) {
                // We need to validate, if the messageCenter contains the entry we should navigate to
                var wantedEntry = this.messageCenterStructure.entries.find(function (entry) {
                    return entry.entryUuid === this.entryUuid;
                }.bind(this));

                if (wantedEntry) {
                    NavigationComp.disableAnimationsTemp();

                    if (wantedEntry.setHistoricAt) {
                        // Directly navigate to the historic page
                        this.sortBySeverity = false;
                    } else {
                        this._actionForEntryCell(wantedEntry);
                    }
                } // Prevent any double navigation by deleting the property


                delete this.entryUuid;
            }
        }

        /**
         * Updates the Icon, title, subtitle and the TableView whenever the severities change
         * @private
         */
        _updateUI() {
            return this.reloadTable();
            if (!this.messageCenterStructure) {
                return Q.resolve();
            }

            var currEntries = JSON.stringify(this.messageCenterStructure.entries),
                didChange = this._prevEntries ? this._prevEntries !== currEntries : true;
            this._prevEntries = currEntries;

            if (!didChange) {
                return true;
            }

            if (this.messageCenterStructure.entries.length) {
                this.setTitle();
            } else {
                this.setTitle();
            }

            return Q.all([this._reloadActiveEntriesTableView(), this._reloadAllEntriesTableView()]);
        }

        _getCellForEntry(entry) {
            return {
                type: GUI.TableViewV2.CellType.Special.MESSAGE_CENTER_ENTRY_CELL,
                content: {
                    entry: entry,
                    checkConfirmed: true
                },
                action: this._actionForEntryCell.bind(this, entry)
            };
        }

        _actionForEntryCell(entry) {
            NavigationComp.showState(ScreenState.MessageCenterMessageScreen, {
                entry: entry
            });
        }

        _getActiveTableContent() {
            let entriesToMap = this.messageCenterStructure.entries.filter(function (entry) {
                return !entry.setHistoricAt;
            }).sort(function (left, right) {
                // Sorting
                // • Sort after the severity
                // • Sort for the timestamp if the severity is equal
                var result = right.severity - left.severity;

                if (result === 0) {
                    result = right.timestamps[right.timestamps.length - 1] - left.timestamps[left.timestamps.length - 1];
                }

                return result;
            }).sort(function (left, right) {
                // Sorting
                // • Sort every entry with the confirmedAt property to the bottom
                return left.confirmedAt ? 1 : right.confirmedAt ? -1 : 0;
            });

            return [
                {
                    rows: entriesToMap.map(this._getCellForEntry.bind(this))
                }
            ];
        }

        _getHistoricTableContent() {
            return [
                {
                rows: this.messageCenterStructure.entries.map(this._getCellForEntry.bind(this))
                }
            ];
        }

        _donePaging(idx) {
            this.segmentedControl.setActiveSegment(idx);
        }

        /**
         * Displays an informative background view if no tableContent is to be shown
         * @param tableView
         * @param tableViewContent
         * @private
         */
        _setAllOkBackgroundView(tableView, tableViewContent) {
            var bgView = null;

            if (!tableViewContent[0].rows.length) {
                bgView = new GUI.BackgroundView(Icon.MESSAGE_CENTER.HEALTH, _("message-center.everything-ok.title"), _("message-center.everything-ok.desc"));
            }

            tableView.setBackgroundView(bgView);
        }

    };
});
