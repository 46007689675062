'use strict';

define(['AudioZoneControlEnums', 'AudioZoneTableViewHelper', 'CustomizationHelper'], function (AudioZoneControlEnums, AudioZoneTableViewHelper, CustomizationHelper) {
    class AudioZoneControlZoneVolumeSettings extends GUI.TableViewScreenV2 {
        //region Private
        VOLUME_KEY_NAME_MAP = {
            defaultVolume: _("media.preferences.zone.default-volume"),
            maxVolume: _("media.preferences.zone.max-volume"),
            alarmVolume: _("controls.alarm.level.alarm"),
            bellVolume: _("media.preferences.zone.bell-volume"),
            buzzerVolume: _("search.controltype.alarmclock"),
            ttsVolume: _("media.preferences.zone.tts-volume")
        }; //endregion Private

        constructor(details) {
            super($('<div />'));
            Object.assign(this, StateHandler.Mixin);
            this.details = details;
            this.control = details.control;
            this._cachedDefaultVolume = null;
        }

        viewWillDisappear() {
            if (this._cachedDefaultVolume !== null) {
                this.control.sendCommand("defaultVolume/" + this._cachedDefaultVolume);
            }

            super.viewWillDisappear(...arguments);
        }

        titleBarText() {
            return _("media.volume");
        }

        reloadTable() {
            this.states = this.control.getStates();
            this.tableContent = [{
                rows: Object.keys({
                    defaultVolume: this.states.defaultVolume,
                    maxVolume: this.states.maxVolume
                }).map(this._getVolumeCell.bind(this))
            }, {
                headerTitle: _("media.preferences.zone.event-volume-header"),
                rows: Object.keys({
                    ttsVolume: this.states.ttsVolume,
                    buzzerVolume: this.states.buzzerVolume,
                    bellVolume: this.states.bellVolume,
                    alarmVolume: this.states.alarmVolume
                }).map(this._getVolumeCell.bind(this))
            }];
            return super.reloadTable(...arguments);
        }

        _getVolumeCell(volKey) {
            return {
                type: GUI.TableViewV2.CellType.SLIDER,
                content: {
                    title: this.VOLUME_KEY_NAME_MAP[volKey],
                    value: this.states[volKey],
                    minIconSrc: Icon.AudioZoneCentral.VOLUME_DOWN,
                    maxIconSrc: Icon.AudioZoneCentral.VOLUME_UP,
                    format: "%.0f%%",
                    volKey: volKey
                },
                sliderDragged: this._onVolumeChange.bind(this),
                sliderClicked: this._onVolumeChange.bind(this)
            };
        }

        _onVolumeChange(cell, section, row, tableView, value) {
            if (cell.content.volKey === "defaultVolume") {
                this.control.sendCommand(Commands.AudioZone.VOLUME_SET + "/" + value, Commands.Type.OVERRIDE);
                this._cachedDefaultVolume = value;
            } else {
                this.control.sendCommand(cell.content.volKey + "/" + value, Commands.Type.OVERRIDE);
            }
        }

    }

    Controls.AudioZoneControl.AudioZoneControlZoneVolumeSettings = AudioZoneControlZoneVolumeSettings;
});
