'use strict';

define("EnergyManagerControl", ["Control", "./content/controlContent"], function (Control, EneryManagerControlContent) {
    return class EnergyManagerControl extends Control {
        constructor() {
            super(...arguments);
        }

        getReactControlContent() {
            return EneryManagerControlContent.default
        }

    };
});
