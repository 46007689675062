'use strict';

define([], function () {
    return {
        DeviceState: {
            NO_DEVICE: 0,
            OFFLINE: 1,
            ONLINE: 2
        },
        ScreenState: {
            EDIT_ENTRY: 'AlarmClockControlContentEditEntry',
            RECURRING_LIST: 'AlarmClockControlContentOperatingModeList',
            DEVICE_SETTINGS: 'AlarmClockControlSettingsScreen'
        }
    };
});
