'use strict';
/*
 iconSrc
 title
 value
 minValue
 maxValue
 format

 saunaValueChanged(value, section, row, tableView)
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.SAUNA_VALUE = "SaunaValueCell";

    class SaunaValueCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(content) {
                return '<div class="content__icon-placeholder">' + ImageBox.getResourceImageWithClasses(content.leftIconSrc, "icon-placeholder__icon") + '</div>' + '<div class="content__title">' + content.title + '</div>' + '<div class="content__slider-placeholder"></div>' + '<div class="content__value-label"></div>' + '<div class="content__button-placeholder">' + ImageBox.getResourceImageWithClasses("resources/Images/Controls/Sauna/button-slider.svg", "button-placeholder__icon icon--slider") + ImageBox.getResourceImageWithClasses("resources/Images/General/success.svg", "button-placeholder__icon icon--success") + '</div>';
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("sauna-value-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            let prms = [];
            this.contentPlaceholder.append(SaunaValueCell.Template.getTemplate(this.content));
            this.elements = {
                titleLabel: this.contentPlaceholder.find('.content__title'),
                valueLabel: this.contentPlaceholder.find('.content__value-label'),
                sliderPlaceholder: this.contentPlaceholder.find('.content__slider-placeholder').hide(),
                sliderIcon: this.contentPlaceholder.find('.icon--slider'),
                checkIcon: this.contentPlaceholder.find('.icon--success').hide()
            };

            prms.push(this.appendReactComp({
                reactComp: GUI.LxSlider,
                compProps: {},
                target: this.elements.sliderPlaceholder
            }).then(instance => this.slider = instance));

            return Q.all(prms).then(() => {
                this.slider.onDraggingChanged = this._sliderSet.bind(this);
                this.slider.onPositionChanged = this._sliderDragged.bind(this);
                this.setPosition(this.content.value);
                this.button = new GUI.LxButton(this, this.contentPlaceholder.find('.content__button-placeholder')[0], Color.BUTTON_GLOW, null, true);
                this.button.onButtonTapped = this._changeCellContent;
                this.addToHandledSubviews(this.button);
                this.updateSaunaMode(this.content.minValue, this.content.maxValue);
            });
        }

        // Public methods
        setPosition(value) {
            this.content.value = value;
            this.elements.valueLabel.text(lxFormat(this.content.format, value));

            if (this.sliderVisible) {
                this.slider.setPosition(this.content.value);
            }
        }

        updateSaunaMode(min, max) {
            if (min !== undefined && max !== undefined) {
                this.slider.updateProperties({
                    min: min,
                    max: max
                });
            }

            this.sliderLocked = min === undefined && max === undefined;
            this.button.setEnabled(!this.sliderLocked);

            if (this.sliderLocked && this.sliderVisible) {
                this._changeCellContent();
            }
        }

        // Private methods
        _changeCellContent() {
            if (this.sliderLocked && !this.sliderVisible) {
                return;
            }

            this.sliderVisible = !this.sliderVisible;
            this.elements.titleLabel.toggle(!this.sliderVisible);
            this.elements.sliderPlaceholder.toggle(this.sliderVisible);

            if (this.sliderVisible) {
                this.slider.setPosition(this.content.value);
            }

            this.elements.sliderIcon.toggle(!this.sliderVisible);
            this.elements.checkIcon.toggle(this.sliderVisible);
        }

        _sliderSet(value) {
            this.elements.valueLabel.text(lxFormat(this.content.format, value));
            this.delegate.saunaValueChanged && this.delegate.saunaValueChanged.call(this.delegate, value, this.sectionIdx, this.rowIdx, this.tableView);
        }

        _sliderDragged(value) {
            // don't use onPositionChanged, it's sending values far too often. no need to adopt the temperature live
            this.elements.valueLabel.text(lxFormat(this.content.format, value));
        }

    }

    GUI.TableViewV2.Cells.SaunaValueCell = SaunaValueCell;
    return GUI;
}(window.GUI || {});
