import {useState, useEffect} from "react";
import { LxReactText } from "LxComponents"
import globalStyles from "GlobalStyles"

function TimerComp() {
    const [currentMsTimeText, setCurrentMsTimerText] = useState("");

    useEffect(() => {
        const timerIdentifier = SandboxComponent.getMiniserverTimeInfo(null, now => {
            setCurrentMsTimerText(now.format(LxDate.getTimeFormat(true)));
        }, TimeValueFormat.MINISERVER_DATE_TIME, 5000);

        return () => {
            SandboxComponent.removeFromTimeInfo(timerIdentifier);
        }
    })


    return (
        <LxReactText key={"CurrentTime"} style={{
        //color: props.tintColor,
            color: globalStyles.colors.white,
            fontSize: 35
        }}>
            {currentMsTimeText}
        </LxReactText>
    )
}

export default TimerComp;
