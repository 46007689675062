import React, { useEffect, useState, useContext, useMemo } from 'react';
import { View, Text, ScrollView, StyleSheet } from 'react-native';
import {
    navigatorConfig,
    LxReactTableView,
    ControlContext,
} from 'LxComponents';
import globalStyles from 'GlobalStyles';
import { getQuickSelect, sendValue } from '../utils';
import Icons from 'IconLib';
import TargetTemp from './TargetTemp';
import TempPicker from './TempPicker';

export default function TimerScreen({ navigation, route }) {
    const { control, states } = useContext(ControlContext);
    const [showTempPicker, setShowTempPicker] = useState(false);
    const useFahrenheit = SandboxComponent.getTemperatureUnit() === TempAppendix(TempUnit.FAHRENHEIT);
    const [selectedOptions, setSelectedOptions] = useState({
        status: states.status,
        mode: states.mode,
        targetTemperature: states.targetTemperature,
        fanSpeed: states.fan,
        airFlowDirection: states.ventMode,
        runtimeUntil: SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINISERVER_DATE_TIME).add(2, "hour"),
    });

    const displayTempPicker = () => {
        setShowTempPicker(true);
    };

    const handleTempPickerSelect = (selected) => {
        let temperature = selected[0].idx;
        setSelectedOptions((state) => ({ ...state, targetTemperature: temperature }));
        setShowTempPicker(false);
    };

    useEffect(() => {
        navigation.setOptions({
            ...navigatorConfig({
                title: _('steak.timer'),
                animationType: AnimationType.MODAL,
                rightActions: [{
                    action: () => {
                        return <Icons.Tick style={globalStyles.customStyles.reactTitleBarIcon}/>
                    }
                }],
                onRightAction: (idx) => {
                    const {status, runtimeUntil} = selectedOptions;
                    if (status) {
                        const { mode, targetTemperature, fanSpeed, airFlowDirection } = selectedOptions;
                        const duration = runtimeUntil.diff(SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINISERVER_DATE_TIME), 'seconds');
                        if(duration > 0) {
                            
                            sendValue(control, Commands.format(Commands.AC.SET_OVERRIDE, duration, mode, fanSpeed, airFlowDirection, targetTemperature, status));
                        }
                    } else {
                        const duration = runtimeUntil.diff(SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINISERVER_DATE_TIME), 'seconds');
                        if(duration > 0) {
                            sendValue(control, Commands.format(Commands.AC.START_SLEEP, duration));
                        }
                    }
                    navigation.goBack();
                }
            }),
        });
    });

    const tableContent = useMemo(() => {
        let content = [
            {
                headerStrongTitle: _('settings'),
                rows: [],
            },
            {
                headerStrongTitle: _('timespan'),
                rows: [],
            },
        ];

        content[0].rows.push({
            title: _('controls.ac-control.actual-ac-status'),
            mainRightContent: getQuickSelect(
                [{value: 0, title: _('off')}, {value: 1, title: _('on')}],
                (value) => setSelectedOptions((state) => ({ ...state, status: !!Number(value) })),
                'status',
                false,
                control,
                states,
            ),
        });

        if(selectedOptions.status) {
            content[0].rows.push({
                title: _('controls.ac-control.actual-ac-mode'),
                mainRightContent: getQuickSelect(
                    states.operatingModes,
                    (value) => setSelectedOptions((state) => ({ ...state, mode: value })),
                    'mode',
                    false,
                    control,
                    states,
                ),
            });
    
            content[0].rows.push({
                title: _('controls.ac-control.target-temperature'),
                mainRightContent: {
                    comp: TargetTemp,
                    props: {
                        targetTemperature: selectedOptions.targetTemperature,
                        displayTempPicker: displayTempPicker,
                        useFahrenheit: useFahrenheit,
                        isSetByLogic: false,
                    },
                },
            });
    
            content[0].rows.push({
                title: _('controls.ac-control.fan-speed'),
                mainRightContent: getQuickSelect(
                    states.fanSpeeds,
                    (value) => setSelectedOptions((state) => ({ ...state, fanSpeed: value })),
                    'fan',
                    false,
                    control,
                    states,
                ),
            });
    
            content[0].rows.push({
                title: _('controls.ac-control.air-flow-direction'),
                mainRightContent: getQuickSelect(
                    states.airflows,
                    (value) => setSelectedOptions((state) => ({ ...state, airFlowDirection: value })),
                    'ventMode',
                    false,
                    control,
                    states,
                ),
            });
        }

        content[1].rows.push({
            type: GUI.TableViewV2.CellType.DATE_TIME_PICKER,
            onPickerChanged: (sectionIdx, rowIdx, table, value) => {
                setSelectedOptions((state) => ({ ...state, runtimeUntil: value }));
            },
            content: {
                title: _("controls.spotpriceoptimizer.runtime"),
                value: selectedOptions.runtimeUntil,
                minDate: SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINISERVER_DATE_TIME),
                maxDate: SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINISERVER_DATE_TIME).add(24, 'hours'),
                marked: false,
                hideTimeAndYear: false, 
            },
        });

        return content;
    }, [JSON.stringify(states), selectedOptions]);

    return (
        <ScrollView style={styles.container}>
            <LxReactTableView
                tableContent={tableContent}
            />
            <TempPicker
                showTempPicker={showTempPicker}
                useFahrenheit={useFahrenheit}
                handleTempPickerSelect={handleTempPickerSelect}
                canRetainLast={false}
            />
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        width: '100%',
    },
});
