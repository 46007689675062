import {useState, useRef, useEffect} from "react";
import {View, StyleSheet} from 'react-native';
import PropTypes from "prop-types";
import globalStyles from "GlobalStyles";
import {
    LxReactText,
    LxReactPressable,
    LxReactContextMenuHandler
} from "LxComponents";

export default function LxReactDateTimePicker(props) {

    let { value = moment(), onChange, type = LxReactDateTimePicker.Types.Date } = props,
        valueFormat,
        previewFormat = props.previewFormat,
        [inputValue, setInputValue] = useState(value),
        ref = useRef(null);

    useEffect(() => {
        setInputValue(value);
    }, [value])

    const ContextMenuMap = {
        [LxReactDateTimePicker.Types.Date]: {
            type: GUI.LxTimePickerContextMenu.TYPE.DATE,
            convertValue: value => value,
            convertResult: result => result
        },
        [LxReactDateTimePicker.Types.Time]: {
            type: GUI.LxTimePickerContextMenu.TYPE.TIME,
            convertValue: value => {
                return [value.hours(), value.minutes()]
            },
            convertResult: ([hours = 0, minutes = 0, seconds = 0]) => {
                let mockDate = new Date()

                mockDate.setHours(hours);
                mockDate.setMinutes(minutes);
                mockDate.setSeconds(seconds);
                mockDate.setMilliseconds(0);

                return moment(mockDate);
            }
        },
        [LxReactDateTimePicker.Types.DateTime]: {
            type: GUI.LxTimePickerContextMenu.TYPE.DATE_TIME,
            convertValue: value => value,
            convertResult: result => result
        }
    }

    const openPickerContext = () => {
        let ctxMap = ContextMenuMap[type],
            options = {
                type: ctxMap.type,
                value: ctxMap.convertValue(inputValue),
                onPickerChanged: res => {
                    let momObj = ctxMap.convertResult(res);
                    onChange(momObj);
                    setInputValue(momObj);
                },
                hideTimeAndYear: props.hideTimeAndYear
            };

        if (props.min) {
            options.min = ActiveMSComponent.convertToJsDate(props.min);
        }
        if (props.max) {
            options.max = ActiveMSComponent.convertToJsDate(props.max);
        }
        if (props.marked) {
            options.marked = props.marked;
        }
        LxReactContextMenuHandler.shared.showContextMenu(options, props.title, null, window.GUI.LxTimePickerContextMenu);
    }

    switch (type) {
        case LxReactDateTimePicker.Types.Date:
            valueFormat = DateType.Norm_Date;
            if (!props.previewFormat) {
                previewFormat = DateType.DateText;
            }
            break;
        case LxReactDateTimePicker.Types.Time:
            valueFormat = LxDate.getMobiscrollTimeFormat(true);
            if (!props.previewFormat) {
                previewFormat = valueFormat;
            }
            break;
        case LxReactDateTimePicker.Types.DateTime:
            valueFormat = "YYYY-MM-DDThh:mm";
            if (!props.previewFormat) {
                previewFormat = DateType.DateText + ", " + LxDate.getTimeFormat(true);
            }
            break;
    }

    return (
        <View ref={ref} style={styles.container}>
            <LxReactPressable onPress={openPickerContext}>
                <LxReactText style={StyleSheet.flatten([styles.text, props.valueStyle || {}])}>{inputValue.format(previewFormat)}</LxReactText>
            </LxReactPressable>
        </View>
    );
}

const styles = {
    container: {
        //backgroundColor: globalStyles.colors.fill.tertiary,
        borderRadius: 2,
        padding: 5
    },
    text: {
        color: globalStyles.colors.green_fixed,
        fontSize: globalStyles.fontSettings.sizes.medium
    }
}

LxReactDateTimePicker.Types = {
    Date: "date",
    Time: "time",
    DateTime: "datetime-local"
}

LxReactDateTimePicker.propTypes = {
    title: PropTypes.string,
    value: PropTypes.instanceOf(moment),
    valueStyle: PropTypes.object,
    type: PropTypes.oneOf(Object.values(LxReactDateTimePicker.Types)),
    min: PropTypes.instanceOf(moment),
    max: PropTypes.instanceOf(moment),
    previewFormat: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    hideTimeAndYear: PropTypes.bool,
    marked: PropTypes.array
}
