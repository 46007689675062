/**
 * Created by loxone on 14.12.17.
 */
'use strict';

define(['VentilationControlEnums'], function (VentilationControlEnums) {
    return class VentilationControlModesScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div />'));
            this.control = details.control;
            this.selectedModeId = details.selectedModeId;
        }

        titleBarText() {
            return _('controls.leaf.timer');
        }

        titleBarAction() {
            // Force an updateView on the parentView
            this.ViewController.navigateBack(false, {
                selectedModeId: this.selectedModeId
            });
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        reloadTable() {
            var availableModes = this.control.details.modes;
            this.tableContent = [{
                rows: []
            }]; // Iterate over all available modes to add checkable
            // Cells to the first section of the TableView

            for (var i = 0; i < availableModes.length; i++) {
                this.tableContent[0].rows.push(this._getCheckableCell(availableModes[i]));
            }

            return super.reloadTable(...arguments);
        }

        /**
         * Returns a checkable cell for the given mode
         * @param mode
         * @returns {{type: string, content: {title: *, selected: boolean, radioMode: string}, didCheckCell: (function(this:_getCheckableCell))}}
         * @private
         */
        _getCheckableCell(mode) {
            return {
                type: GUI.TableViewV2.CellType.CHECKABLE,
                content: {
                    title: mode.name,
                    selected: mode.id === this.selectedModeId,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED
                },
                didCheckCell: this.didSelectMode.bind(this)
            };
        }

        didSelectMode(cell, section, row, tableView, selected) {
            this.selectedModeId = this.control.details.modes[row].id;
        }

    };
});
