import * as React from "react"
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg"

function NfcTag(props) {
    return (
        <Svg isreact="true"
            width={22}
            height={22}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <G clipPath="url(#clip0_966_328)">
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.5 0A1.5 1.5 0 0122 1.5v19a1.5 1.5 0 01-1.5 1.5h-19A1.5 1.5 0 010 20.5v-19A1.5 1.5 0 011.5 0h19zm-3.788 13.256c-1.84 0-2.864 1.304-2.864 2.92 0 1.864 1.32 2.872 2.864 2.872 1 0 1.752-.464 2.24-1.072l-.712-.608c-.36.448-.888.776-1.528.776-1.08 0-1.856-.8-1.856-1.968 0-1.2.752-2.008 1.856-2.008.608 0 1.16.328 1.528.776l.712-.6a2.863 2.863 0 00-2.24-1.088zm-12.556.064h-.96V19h.968v-4.024L6.924 19h.984v-5.68h-.96v4.08h-.016l-2.776-4.08zm9.006 0H9.45V19h.968v-2.248h2.304v-.872h-2.304v-1.696h2.744v-.864zM6.5 3h-2A1.5 1.5 0 003 4.5v2A1.5 1.5 0 004.5 8h2A1.5 1.5 0 008 6.5v-2A1.5 1.5 0 006.5 3z"
                />
            </G>
            <Defs>
                <ClipPath id="clip0_966_328">
                    <Path fill="#fff" d="M0 0H22V22H0z" />
                </ClipPath>
            </Defs>
        </Svg>
    )
}

export default NfcTag
