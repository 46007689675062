'use strict';

window.GUI = function (GUI) {
    GUI.ReusingListView.CellType.MEDIA_ITEM = "MediaItemListCell";

    class MediaItemListCell extends GUI.ReusingListView.Cells.GeneralCell {
        constructor() {
            super(...arguments);
            this.element.addClass("media-item-list-cell");
        }

        updateLeftIcon() {
            var hasItem = this.hasCAttr("item");

            if (hasItem) {
                var item = this.cAttr("item"),
                    details = this.cAttr("details"),
                    iconObj = Controls.AudioZoneV2Control.MediaBrowserV2Base.getConstructorForItem(item).getIconObjForItem(item, details.identifier);

                if (!this.coverView) {
                    if (!this.isScrolling) {
                        this.coverView = new Controls.AudioZoneV2Control.CoverViewV2(this.elements.leftCntr, iconObj, true);
                        this.addToHandledSubviews(this.coverView);
                    }
                } else {
                    this.coverView.setIconObject(iconObj);
                }
            }

            GUI.animationHandler.toggle(this.elements.leftCntr, hasItem);
        }

    }

    GUI.ReusingListView.Cells.MediaItemListCell = MediaItemListCell;
    return GUI;
}(window.GUI || {});
