'use strict';

define([], function () {
    return class ExpertModeSettingsScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div class="expert-mode-settings-screen" />'));
            this._searchResult = details && details.isSearchResult;
        }

        titleBarText() {
            return _('expert-mode');
        }

        titleBarAction() {
            this.ViewController.navigateBack(false, {}); // call with details for prev.view, the settings screen will update (due to ExpertMode cell on/off)
        }

        getAnimation() {
            return this._searchResult ? AnimationType.MODAL : AnimationType.PUSH_OVERLAP_LEFT;
        }

        reloadTable() {
            this.tableContent = [{
                footer: this._createFooterContent(),
                rows: [{
                    type: GUI.TableViewV2.CellType.SWITCH,
                    content: {
                        title: _('expert-mode'),
                        active: PersistenceComponent.expertModeEnabled(),
                        activeColor: window.Styles.colors.red
                    },
                    onSwitchChanged: function onSwitchChanged(value) {
                        PersistenceComponent.setExpertModeEnabled(value);
                    }
                }]
            }];
            return super.reloadTable(...arguments);
        }

        _createFooterContent() {
            var msgArg = {
                    icon: ImageBox.getResourceImageWithClasses(Icon.EXPERT_MODE, "expert-mode-settings-screen__help-icon")
                },
                message = _('expert-mode.description', msgArg);

            message += '<br/><br/><div class="expert-mode-settings-screen__help-warning">' + _('settings.only-expert') + '</div>';
            return {
                iconSrc: "resources/Images/General/icon-description.svg",
                title: _('expert-mode'),
                message: message
            };
        }

    };
});
