"use strict";
/*
 rightIconSrc       // source of the right icon, if provided, buttonTapped will be called when icon is tapped

 isOnline           // whether or not the device is online

 buttonTapped(section, row, tableView)
 */

GUI.TableViewV2.CellType.Special.DEVICE_LEARNING_CELL = "DeviceLearningCell";

class DeviceLearningCell extends GUI.TableViewV2.Cells.GeneralCell {
    constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
        super(...arguments);
    }

    viewDidLoad() {
        return super.viewDidLoad(...arguments).then(function () {
            if (this.elements.rightIconCntr) {
                this.buttons = this.buttons || {};
                this.buttons.rightIconButton = new GUI.LxButton(this, this.elements.rightIconCntr);
                this.addToHandledSubviews(this.buttons.rightIconButton);
            }
        }.bind(this));
    }

    viewWillAppear() {
        var prms = super.viewWillAppear(...arguments);

        if (this.content.hasOwnProperty('isOnline')) {
            var leftIconPlaceholderElem = this.element.find('.content__left-icon-placeholder');
            leftIconPlaceholderElem.toggleClass('content__left-icon-placeholder--offline', !this.content.isOnline);
            leftIconPlaceholderElem.toggleClass('content__left-icon-placeholder--online', this.content.isOnline);
        }

        if (this.buttons && this.buttons.rightIconButton && this.delegate.buttonTapped) {
            this.buttons.rightIconButton.onButtonTapped = function () {
                this.delegate.buttonTapped.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
            }.bind(this);
        }

        return prms;
    }

    viewWillDisappear() {
        if (this.buttons && this.buttons.rightIconButton) {
            this.buttons.rightIconButton.onButtonTapped = null;
        }

        return super.viewWillDisappear(...arguments);
    }

}

GUI.TableViewV2.Cells.DeviceLearningCell = DeviceLearningCell;
