import Svg, { Circle } from "react-native-svg"

function CheckMarkUnchecked(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Circle cx={12} cy={12} r={11} stroke={props.strokeColor} />
        </Svg>
    )
}

export default CheckMarkUnchecked
