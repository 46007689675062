import {View} from 'react-native';

function LxReactView(props) {
    return <View {...props} />;
}

export default LxReactView;

LxReactView.propTypes = {
    ...View.propTypes
};