import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Link(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path d="M23 19a4 4 0 01-4 4H5a4 4 0 01-4-4V5a4 4 0 014-4h6a1 1 0 110 2H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-6a1 1 0 112 0v6z" />
            <Path d="M11.293 11.293L19.586 3H15a1 1 0 110-2h7a1 1 0 011 1v7a1 1 0 11-2 0V4.414l-8.293 8.293a1 1 0 01-1.414-1.414z" />
        </Svg>
    )
}

export default Link
