'use strict';

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.MEDIA_V2_COVER = "MediaV2CoverCell";

    class MediaV2CoverCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getCellTemplate = function getCellTemplate(ignoreCtxMenu) {
                var temp = '<div class="cell-container">' +
                    '<div class="cell-container__icon">' +
                    '       <div class="icon__placeholder"/>' +
                    '       <div class="icon__control-button">' +
                    '          ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.PLAY_FULL_B, 'play-icon') +
                    '          ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.PAUSE_FULL_B, 'pause-icon') +
                    '          <div class="icon__shuffle-button">' +
                    '             ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.SHUFFLE, 'shuffle-icon') +
                    '          </div>' +
                    '       </div>';

                if (!ignoreCtxMenu) {
                    temp += '       <div class="icon__ctx-button">' +
                        '          ' + ImageBox.getResourceImageWithClasses(Icon.Buttons.MORE_HORIZONTAL, 'ctx-icon') +
                        '       </div>';
                }

                temp += '       <div class="icon__edit-button">' +
                    '          ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.GEAR, 'edit-icon') +
                    '       </div>';

                temp += '   </div>' + '<div class="cell-container__title text-overflow-ellipsis"></div>' + '<div class="cell-container__subtitle text-overflow-ellipsis"></div>' + '</div>';
                return temp;
            };

            return {
                getCellTemplate: getCellTemplate
            };
        }(); //endregion Static

        //region Private
        //fast-class-es6-converter: extracted from defineStatic({}) content
        PRESS_HOLD_DURATION = 25; //endregion Private

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            Object.assign(this, StateHandler.Mixin, MediaContextMenuHandlerV2.Mixin);
            this.element.addClass("media-v2-cover-cell");
            this._hasTouch = 'ontouchstart' in window || window.DocumentTouch && document instanceof DocumentTouch;
            this._ctxTimerId = null;
            this._ctxTimerCnt = 0;
        }

        onSelected() {
            super.onSelected();

            this._cancelPress();
        }

        cellTypeForReuse() {
            return GUI.TableViewV2.CellType.Special.MEDIA_V2_COVER;
        }

        updateContent() {
            return super._updateContent(...arguments);
        }

        viewDidLoad() {
            var prms = [super.viewDidLoad(...arguments)];
            var contentTemplate = MediaV2CoverCell.Template.getCellTemplate(this.ignoreCtxMenu);
            prms.push(GUI.animationHandler.append(contentTemplate, this.contentPlaceholder).then(function viewDidLoadPassed() {
                this.elements.title = this.element.find(".cell-container__title");
                this.elements.subtitle = this.element.find(".cell-container__subtitle");
                this.elements.controlButton = this.element.find('.icon__control-button');
                this.elements.shuffleButton = this.element.find('.icon__shuffle-button');
                this.elements.editButton = this.element.find('.icon__edit-button');
                this.elements.ctxButton = this.element.find(".icon__ctx-button");
                this.controlButton = new GUI.LxButton(this, this.elements.controlButton[0], Color.BUTTON_GLOW);

                this.controlButton.onButtonTapped = function () {
                    this._cancelPress();

                    this._handlePlayButtonTapped();
                }.bind(this);

                this.addToHandledSubviews(this.controlButton);
                this.ctxBtn = new GUI.LxButton(this, this.elements.ctxButton[0], Color.BUTTON_GLOW);

                this.ctxBtn.onButtonTapped = function () {
                    this._cancelPress();

                    if (!this._hasTouch) {
                        this._showContextMenuForItem(this.content.item);
                    }
                }.bind(this);

                this.addToHandledSubviews(this.ctxBtn);
                var iconObj = Controls.AudioZoneV2Control.MediaBrowserV2Base.getConstructorForItem(this.content.item).getIconObjForItem(this.content.item, this.content.details.identifier);
                this.coverView = new Controls.AudioZoneV2Control.CoverViewV2(this.element.find(".icon__placeholder"), iconObj);
                this.addToHandledSubviews(this.coverView);

                if (this._hasTouch) {
                    this.clickableContainer[0].addEventListener("touchstart", this._touchStart.bind(this), false);
                    this.clickableContainer[0].addEventListener("touchmove", this._cancelPress.bind(this), false);
                    this.clickableContainer[0].addEventListener("touchend", this._touchEnd.bind(this), false);
                } else {
                    GUI.animationHandler.addCssClass(this.element, "media-v2-cover-cell--hoverable");
                }

                this.rightTopButton = new GUI.LxButton(this, this.elements.editButton[0], Color.BUTTON_GLOW);
                this.addToHandledSubviews(this.rightTopButton);

                this.updateContent(this.content);
            }.bind(this)));
            return Q.all(prms);
        }

        viewWillDisappear() {
            this.control && this._unregisterStates(this.control.uuidAction);
            return Q(super.viewWillDisappear(...arguments));
        }

        destroy() {
            this.control && this._unregisterStates(this.control.uuidAction);
            return super.destroy(...arguments);
        }

        getMediaInfo() {
            return this.content.details;
        }

        receivedStates(states) {// According to Issue AUDIO1-I1001 The play/pause function should not be implemented

            /*this.parent = states.parent;
            if (states.parent && states.parent.id === this.content.item.id) {
                this.isPlaying = states.isPlaying
                this.controlButton.getElement().toggleClass("icon__control-button--is-playing", states.isPlaying);
            } else if (this.isPlaying) {
                this.controlButton.getElement().removeClass("icon__control-button--is-playing");
            }*/
        }

        setTitle(title) {
            GUI.animationHandler.setHtml(this.elements.title, title);
        }

        setSubtitle(subtitle) {
            subtitle = subtitle || "";
            GUI.animationHandler.setHtml(this.elements.subtitle, subtitle);
        }

        resetCellContent() {
            var emptyPrms;
            this.control && this._unregisterStates(this.control.uuidAction);

            if (this.coverView) {
                emptyPrms = this.coverView.empty();
            } else {
                emptyPrms = Q.resolve();
            }

            GUI.animationHandler.schedule(function () {
                this.elements.title.html("...");
                this.elements.subtitle.html("");
                this.elements.shuffleButton.hide();
            }.bind(this));
            return Q.all([emptyPrms, super.resetCellContent(...arguments)]);
        }

        _applyNewContent() {
            var promises = [super._applyNewContent(...arguments)],
                shuffleButtonVisible;
            this.control = this.content.details.control;
            this.ignoreCtxMenu = this.content.details.ignoreCtxMenu;

            if (this.content.hidePlayButton) {
                delete this.item;
                delete this.username;
                shuffleButtonVisible = false;
            } else {
                this.item = this.content.item;
                this.username = this.content.username;
                shuffleButtonVisible = this._showShuffleIcon();
            }

            this.control && this._registerForStates(this.control.uuidAction, ["parent", "isPlaying"]);
            promises.push(GUI.animationHandler.schedule(function () {
                this.elements.title.html(this.content.title);
                this.elements.subtitle.html(this.content.subtitle || "");
                this.elements.shuffleButton.toggle(shuffleButtonVisible);
            }.bind(this)));
            this.toggleSubview(this.controlButton, !this.content.hidePlayButton);
            this.toggleSubview(this.ctxBtn, !this.ignoreCtxMenu);
            this.coverView.setIconObject(Controls.AudioZoneV2Control.MediaBrowserV2Base.getConstructorForItem(this.content.item).getIconObjForItem(this.content.item, this.content.details.identifier));

            //Toggle EditButton
            this.toggleSubview(this.rightTopButton, !!this.content.editButtonFn);
            this.rightTopButton.onButtonTapped = this.content.editButtonFn


            return Q.all(promises);
        }

        _touchStart(e) {
            if (this.pressTimeout) {
                clearTimeout(this.pressTimeout);
                this.pressTimeout = null;
            }

            this.pressTimeout = setTimeout(function () {
                // Start the timer
                requestAnimationFrame(this._onPressIncrement.bind(this));
                e.preventDefault();
            }.bind(this), 250);
        }

        _touchEnd() {
            // Stop the timer
            cancelAnimationFrame(this._ctxTimerId);
            this._ctxTimerCnt = 0;
            this.element[0].style.setProperty("--scale-value", 1);
        }

        _onPressIncrement() {
            if (!this.ignoreCtxMenu) {
                if (this._ctxTimerCnt < this.PRESS_HOLD_DURATION) {
                    this._ctxTimerId = requestAnimationFrame(this._onPressIncrement.bind(this));
                    this._ctxTimerCnt++;
                    this.element[0].style.setProperty("--scale-value", 1 + this._ctxTimerCnt / this.PRESS_HOLD_DURATION / 15);
                } else {
                    this._showContextMenuForItem(this.content.item);
                }
            }
        }

        _cancelPress() {
            if (this.pressTimeout) {
                clearTimeout(this.pressTimeout);
                this.pressTimeout = null;
            }

            this._touchEnd();
        }

        _handlePlayButtonTapped() {
            // According to Issue AUDIO1-I1001 The play/pause function should not be implemented
            // We need to check if this item is playing, not just any arbitrary item

            /*if (this.parent && this.parent.id === this.content.item.id) {
                if (this.isPlaying) {
                    this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                        cmd: MusicServerEnum.AudioCommands.PAUSE
                    });
                } else {
                    this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                        cmd: MusicServerEnum.AudioCommands.PLAY
                    });
                }
            } else {*/
            if (this.content.details) {
                this.control.audioserverComp.sendPlayerCommandFromType(this.item, this.rowIdx, {
                    username: this.username,
                    identifier: this.content.details.identifier,
                    mediaType: this.content.details.mediaType,
                    lastSelectedItem: this.content.details.lastSelectedItem,
                    service: this.content.details.service,
                    noShuffle: !this.control.audioserverComp.useShufflePlay(this.item)
                });
            } else {
                this.control.audioserverComp.sendPlayerCommandFromType(this.item);
            } //}

        }

        _hammer() {
            var hammerObj = super._hammer(...arguments);

            this.clickableContainer.css("touch-action", "auto");
            return hammerObj;
        }

        _showShuffleIcon() {
            return this.control.audioserverComp.useShufflePlay(this.item);
        }

    }

    GUI.TableViewV2.Cells.MediaV2CoverCell = MediaV2CoverCell;
    return GUI;
}(window.GUI || {});
