'use strict';

define(["ClimateUsControlEnums", "TempLimitsView"], function (CtrlEnums, TempLimitsView) {
    return class TempLimitsScreen extends GUI.Screen {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return '<div class="temp-limits-screen__content"><div class="temp-limits-screen__info-box"></div></div>';
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(details) {
            super($('<div />'));
            Object.assign(this, StateHandler.Mixin);
            this.control = details.control;
        }

        viewDidLoad() {
            var promise = super.viewDidLoad(...arguments);
            this.element.append(TempLimitsScreen.Template.getTemplate());
            this.elements = this.elements || {};
            this.elements.contentContainer = this.element.find(".temp-limits-screen__content");
            this.elements.infoBox = this.element.find(".temp-limits-screen__info-box");
            this.elements.infoBoxText = $('<p class="temp-limits-screen__info__text"></p>');
            this.elements.infoBox.append(this.elements.infoBoxText);
            this.tempView = new TempLimitsView(this.control, CtrlEnums.OutsideTemp.MIN, CtrlEnums.OutsideTemp.MAX);
            this.tempView.getElement().addClass("content__temp-view");
            this.appendSubview(this.tempView, this.elements.contentContainer);
            return promise;
        }

        viewWillAppear() {
            var promise = super.viewWillAppear(...arguments);

            this._registerForStates();

            return promise;
        }

        viewWillDisappear() {
            this._unregisterStates();

            return super.viewWillDisappear(...arguments);
        }

        receivedStates(states) {
            this.states = states;

            this._handleOutsideTempChange();
        }

        getAnimation() {
            return AnimationType.HD_OVERLAY;
        }

        titleBarText() {
            return _("controls.climate.us.outside-temp.title");
        }

        _handleOutsideTempChange() {
            if(!this.control.hasOutsideTemperature()) {
                switch(this.states.outdoorTempMode) {
                    case CtrlEnums.OutdoorTempMode.AVG_PAST_48H:
                        this.elements.infoBoxText.text(_("controls.climate.us.outside-temp.unavailable-past"));
                        break;
                    case CtrlEnums.OutdoorTempMode.AVG_FORECAST_48H:
                        this.elements.infoBoxText.text(_("controls.climate.us.outside-temp.unavailable-forecast"));
                        break;
                    case CtrlEnums.OutdoorTempMode.CURR_TEMP:
                        this.elements.infoBoxText.text(_("controls.climate.us.outside-temp.unavailable-current"));
                        break;
                    case CtrlEnums.OutdoorTempMode.NOT_USED:
                    default:
                        this.elements.infoBox.remove();
                        break;
                }
            } else {
                this.elements.infoBox.remove();
            }
        }
    };
});
