import * as React from "react"
import Svg, { Path } from "react-native-svg"

function CloseScreen(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M3.707 2.293a1 1 0 00-1.414 1.414L10.586 12l-8.293 8.293a1 1 0 101.414 1.414L12 13.414l8.293 8.293a1 1 0 001.414-1.414L13.414 12l8.293-8.293a1 1 0 00-1.414-1.414L12 10.586 3.707 2.293z"
            />
        </Svg>
    )
}

export default CloseScreen
