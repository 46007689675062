import * as React from "react"
import Svg, { Path } from "react-native-svg"

function BackArrow(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.664 2.253a1 1 0 01.083 1.411L8.338 12l7.41 8.336a1 1 0 01-1.495 1.328l-8-9a1 1 0 010-1.328l8-9a1 1 0 011.411-.083z"
            />
        </Svg>
    )
}

export default BackArrow
