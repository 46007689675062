'use strict';

define(['AudioZoneV2ControlEnums'], function (AudioZoneV2ControlEnums) {
    class VolumeSettings extends GUI.TableViewScreenV2 {
        //region Private
        VOLUME_KEY_NAME_MAP = {
            defaultVolume: _("media.preferences.zone.default-volume"),
            alarmVolume: _("controls.alarm.level.alarm"),
            bellVolume: _("media.preferences.zone.bell-volume"),
            buzzerVolume: _("search.controltype.alarmclock"),
            ttsVolume: _("media.preferences.zone.tts-and-announcement")
        };
        VOLUME_KEY_PARAM_BIT_MAP = {
            defaultVolume: 1, // Vd
            alarmVolume: 4, // Va,
            bellVolume: 8, // Vbe
            buzzerVolume: 16, // Vbu
            ttsVolume: 32 // Vt

        };
        DEFAULT_VOLUME = {
            DYNAMIC: -1,
            DEFAULT: 25
        }; //endregion Private

        get useNewTableView() {
            return true;
        }

        constructor(details) {
            super($('<div />'));
            applyMixins(this, StateHandler.Mixin);
            this.details = details;
            this.control = details.control;
            this.states = cloneObjectDeep(this.control.getStates()); // used to locally keep track, will be modified.
            this._cachedDefaultVolume = null;
            this._hasVariableDefaultVol = this.states.defaultVolume === -1;
            this._previousDefaultVolume = this.states.defaultVolume;
        }

        viewWillDisappear() {
            if (this._cachedDefaultVolume !== null) {
                this.control.sendCommand("defaultVolume/" + this._cachedDefaultVolume);
            }

            super.viewWillDisappear(...arguments);
        }

        titleBarText() {
            return _("media.volume");
        }

        reloadTable() {
            this.states = this.control.getStates();
            this.tableContent = [{
                rows: [this._getVolumeCell("defaultVolume", true, this._hasVariableDefaultVol), {
                    type: GUI.TableViewV2.CellType.SWITCH,
                    content: {
                        title: _("media.preferences.zone.variable-default-volume"),
                        active: this._hasVariableDefaultVol,
                        volKey: "defaultVolume"
                    },
                    onSwitchChanged: function (active, section, row, tableView, cell) {
                        var prms = Q(true);

                        if (active) {
                            this._cachedDefaultVolume = this.DEFAULT_VOLUME.DYNAMIC;
                        } else {
                            if (this._previousDefaultVolume !== this.DEFAULT_VOLUME.DYNAMIC) {
                                this._cachedDefaultVolume = this._previousDefaultVolume;
                            } else {
                                prms = this._onVolumeChange(cell, section, row, tableView, this.DEFAULT_VOLUME.DEFAULT);
                            }
                        }

                        this._hasVariableDefaultVol = active;
                        prms.finally(function () {
                            return this.reloadTable();
                        }.bind(this));
                    }.bind(this)
                }],
                footerTitle: _("media.preferences.zone.variable-default-volume-footer")
            }, {
                headerTitle: _("media.preferences.zone.event-volume-header"),
                rows: Object.keys({
                    ttsVolume: this.states.ttsVolume,
                    buzzerVolume: this.states.buzzerVolume,
                    bellVolume: this.states.bellVolume,
                    alarmVolume: this.states.alarmVolume
                }).map(function (key) {
                    return this._getVolumeCell(key, true);
                }.bind(this))
            }];
            return super.reloadTable(...arguments);
        }

        _getVolumeCell(volKey, onFiniteOnly, disabled) {
            if (hasBit(this.control.details.parameterConnections, this.VOLUME_KEY_PARAM_BIT_MAP[volKey])) {
                return {
                    content: {
                        title: this.VOLUME_KEY_NAME_MAP[volKey],
                        subtitle: _("controlled-via-logic"),
                        disclosureText: this.states[volKey] + "%"
                    }
                };
            } else {
                var value = this.states[volKey];

                if (volKey === "defaultVolume") {
                    if (value === this.DEFAULT_VOLUME.DYNAMIC && this._cachedDefaultVolume !== this.DEFAULT_VOLUME.DYNAMIC) {
                        value = this._cachedDefaultVolume;
                    } else if (this._hasVariableDefaultVol) {
                        value = 0;
                    }
                }

                return {
                    type: GUI.TableViewV2.CellType.SLIDER,
                    content: {
                        title: this.VOLUME_KEY_NAME_MAP[volKey],
                        value: value,
                        minIconSrc: Icon.AudioZoneCentral.VOLUME_DOWN,
                        maxIconSrc: Icon.AudioZoneCentral.VOLUME_UP,
                        format: disabled ? "" : "%.0f%%",
                        volKey: volKey,
                        disabled: disabled
                    },
                    sliderDragged: onFiniteOnly ? this._onInternalDataUpdate.bind(this) : this._onVolumeChange.bind(this),
                    sliderDragEnded: this._onVolumeChange.bind(this),
                    sliderClicked: this._onVolumeChange.bind(this)
                };
            }
        }

        /**
         * Used to keep the UI up to date without sending commands to the MS
         * @param cell
         * @param section
         * @param row
         * @param tableView
         * @param value
         * @private
         */
        _onInternalDataUpdate(cell, section, row, tableView, value) {
            this.states[cell.content.volKey] = value;
            this.reloadTable(); // important as otherwise the UI won't update, will not interfere with dragging (new table)
        }

        /**
         * Used to dispatch values to the MS
         * @param cell
         * @param section
         * @param row
         * @param tableView
         * @param value
         * @returns {*}
         * @private
         */
        _onVolumeChange(cell, section, row, tableView, value) {
            this.states[cell.content.volKey] = value;
            let prms;
            if (cell.content.volKey === "defaultVolume") {
                this._cachedDefaultVolume = value;
                this._previousDefaultVolume = value;
                prms = this.control.sendCommand(Commands.AudioZone.VOLUME_SET + "/" + value, Commands.Type.OVERRIDE);
            } else {
                prms = this.control.sendCommand(cell.content.volKey + "/" + value, Commands.Type.OVERRIDE);
            }
            this.reloadTable(); // important as otherwise the UI won't update, will not interfere with dragging (new table)
            return prms;
        }

    }

    Controls.AudioZoneV2Control.VolumeSettings = VolumeSettings;
    return Controls.AudioZoneV2Control.VolumeSettings;
});
