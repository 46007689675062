'use strict';

define(["IRoomControlEnums", "DaytimerControlEnums"], function (IRoomControlEnums, DaytimerControlEnums) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var TIME_LINE_UPDATE_INTERVAL = 10000;
    var MIN_ENTRY_HEIGHT = 7;
    var MIN_VISIBLE_TEXT_HEIGHT = 25;
    var HIDE_WHEN_TOO_LOW = true; // https://www.wrike.com/open.htm?id=68920686

    return class TimeLine extends GUI.View {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return $('<div class="irc-time-line">' + '<div class="time-line__entries-placeholder"></div>' + '<canvas class="time-line__canvas"></canvas>' + '<div class="time-line__label-placeholder">' + '<span class="placeholder__time-label">00</span>' + '<span class="placeholder__time-label">06</span>' + '<span class="placeholder__time-label">12</span>' + '<span class="placeholder__time-label">18</span>' + '<span class="placeholder__time-label">24</span>' + '</div>' + '<div class="time-line__indicator">' + '<div class="indicator__line"></div>' + '<div class="indicator__circle"></div>' + '</div>' + '<div class="time-line__user-info"></div>' + '</div>');
            };

            var getUserInfoTemplate = function getUserInfoTemplate(userInfo) {
                var result = '';
                var icon, titleText, subtitleText;

                switch (userInfo) {
                    case IRoomControlEnums.UserInfo.HEATING_PHASE:
                    case IRoomControlEnums.UserInfo.COOLING_PHASE:
                        if (userInfo === IRoomControlEnums.UserInfo.HEATING_PHASE) {
                            icon = ImageBox.getResourceImageWithClasses('resources/Images/Controls/IRoom/cooling.svg', 'user-info__left-icon cooling-phase');
                            titleText = _("controls.irc.exception.heating-active");
                            subtitleText = _("controls.irc.exception.heating-reason");
                        } else {
                            icon = ImageBox.getResourceImageWithClasses('resources/Images/Controls/IRoom/heat-protection-temperature.svg', 'user-info__left-icon heating-phase');
                            titleText = _("controls.irc.exception.cooling-active");
                            subtitleText = _("controls.irc.exception.cooling-reason");
                        }

                        result += icon + ImageBox.getResourceImageWithClasses('resources/Images/General/caution.svg', 'user-info__small-caution') + '<div class="user-info__label-container">' + '<div class="label-container__title">' + titleText + '</div>' + '<div class="label-container__subtitle">' + subtitleText + '</div>' + '</div>';
                        break;

                    case IRoomControlEnums.UserInfo.BIG_DIFF_ACTUAL_TARGET:
                        result += ImageBox.getResourceImageWithClasses('resources/Images/Controls/IRoom/info.svg', 'user-info__left-icon big-difference') + '<div class="user-info__label-container">' + '<div class="label-container__subtitle">' + _("controls.irc.exception.big-temp-diff") + '</div>' + '</div>' + '<div class="user-info__right-toucharea clickable">' + ImageBox.getResourceImageWithClasses('resources/Images/General/close.svg', 'user-info__right-icon') + '</div>';
                        break;

                    case IRoomControlEnums.UserInfo.NO_PERIOD_ACTIVE:
                        result += ImageBox.getResourceImageWithClasses('resources/Images/General/Notifications/icon-error.svg', 'user-info__left-icon left-icon--error no-period') + '<div class="user-info__label-container">' + '<div class="label-container__title">' + _("controls.irc.exception.no-operating-mode.title") + '</div>' + '<div class="label-container__subtitle">' + _("controls.irc.exception.no-operating-mode.subtitle") + '</div>' + '</div>';
                        break;

                    case IRoomControlEnums.UserInfo.ACTIVE_TIMER:
                    case IRoomControlEnums.UserInfo.COMFORT_TIMER:
                    case IRoomControlEnums.UserInfo.ECONOMY_TIMER:
                    case IRoomControlEnums.UserInfo.HAS_PRESENCE:
                        // comfort and economy can have remaining time 0, and can change while user info doesn't change
                        if (userInfo === IRoomControlEnums.UserInfo.HAS_PRESENCE) {
                            icon = ImageBox.getResourceImageWithClasses('resources/Images/Controls/IRoom/presence-state.svg', 'user-info__left-icon');
                            titleText = _("controls.irc.exception.presence");
                        } else {
                            icon = ImageBox.getResourceImageWithClasses('resources/Images/Controls/IRoom/timer.svg', 'user-info__left-icon no-period');
                            titleText = _("controls.irc.timer");
                        }

                        result += icon + '<div class="user-info__label-container">' + '<div class="label-container__title">' + titleText + '</div>' + '<div class="label-container__subtitle"></div>' + '</div>' + '<div class="user-info__right-toucharea clickable">' + ImageBox.getResourceImageWithClasses('resources/Images/General/close.svg', 'user-info__right-icon') + '</div>';
                        break;

                    case IRoomControlEnums.UserInfo.OPEN_WINDOW:
                        result += ImageBox.getResourceImageWithClasses('resources/Images/Controls/IRoom/window-state.svg', 'user-info__left-icon') + '<div class="user-info__label-container">' + '<div class="label-container__title">' + _("controls.irc.exception.open-window") + '</div>' + '<div class="label-container__subtitle"></div>' + '</div>';
                        break;
                }

                return result;
            };

            var drawTimeLine = function drawTimeLine(timeLineElement) {
                var timeCanvas = timeLineElement[0];
                checkAndPrepareCanvasContextForRetina(timeCanvas);
                var ctx = timeCanvas.getContext("2d");
                var width = timeCanvas.clientWidth;
                var height = timeCanvas.clientHeight;
                ctx.beginPath();
                ctx.strokeStyle = Color.TIME_LINE_VALUE_INDICATORS;
                ctx.lineWidth = 2;
                ctx.moveTo(1, 0);
                ctx.lineTo(1, height); // big steps

                ctx.lineWidth = 1;
                var i;

                for (i = 0; i <= 24; i++) {
                    if (i % 3 === 0) {
                        ctx.moveTo((width - 1) / 24 * i, 0);
                        ctx.lineTo((width - 1) / 24 * i, height);
                    }
                }

                ctx.stroke(); // small steps

                ctx.lineWidth = 0.5;

                for (i = 0; i < 24; i++) {
                    if (i % 3 !== 0) {
                        ctx.moveTo(width / 24 * i, 2);
                        ctx.lineTo(width / 24 * i, height);
                    }
                }

                ctx.stroke();
            };

            return {
                getTemplate: getTemplate,
                getUserInfoTemplate: getUserInfoTemplate,
                drawTimeLine: drawTimeLine
            };
        }(); //endregion Static

        constructor(ctrl) {
            super(TimeLine.Template.getTemplate());
            this.control = ctrl;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.elements = {
                timeLineEntries: this.element.find('.time-line__entries-placeholder'),
                timeLineIndicator: this.element.find('.time-line__indicator'),
                tlIndicatorCircle: this.element.find('.indicator__circle').hide(),
                tlIndicatorLine: this.element.find('.indicator__line'),
                userInfo: this.element.find('.time-line__user-info').hide()
            };
            this.buttons = {};
            this.userInfo = IRoomControlEnums.UserInfo.NOTHING;
            this.openCalendarHandler = Hammer(this.element[0]);
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments);
            var timeLine = this.element.find('.time-line__canvas');
            TimeLine.Template.drawTimeLine(timeLine);
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);
            this.openCalendarHandler.on(tapEvent(), function () {
                var isHeating = this.control.isHeating(this.states.controllerMode);

                if (isHeating || this.control.isCooling(this.states.controllerMode)) {
                    var uuid = this.control.getDaytimerUuidFromIRoomControl(isHeating);

                    if (this.userInfo === IRoomControlEnums.UserInfo.NOTHING || this.userInfo === IRoomControlEnums.UserInfo.BIG_DIFF_ACTUAL_TARGET) {
                        var daytimerControl = ActiveMSComponent.getStructureManager().getControlByUUID(uuid);
                        this.ViewController.showState(DaytimerControlEnums.ScreenState.CALENDAR, null, {
                            control: daytimerControl
                        });
                    }
                }
            }.bind(this));
            this.timeIdentifier = SandboxComponent.getMiniserverTimeInfo(this, this.updateIndicatorPosition.bind(this), TimeValueFormat.MINUTES_SINCE_MIDNIGHT, TIME_LINE_UPDATE_INTERVAL); // call again, since before it wasn't displayed properly (too narrow)

            this.states && this.updateTimeLine(this.states);
        }

        viewWillDisappear() {
            SandboxComponent.removeFromTimeInfo(this.timeIdentifier);
            super.viewWillDisappear(...arguments);
        }

        destroy() {
            this.openCalendarHandler.dispose();
            super.destroy();
        }

        updateTimeLine(states) {
            // time line
            var usedValues = this.updateTimeLineEntries(this.elements.timeLineEntries, states.timeLineEntries, states.activeTemperatures, this.control.details.format);

            if (states.activeTempMode != null) {
                this.elements.tlIndicatorCircle.show();
                this.elements.tlIndicatorCircle.css('background-color', this.control.getColorOfIRCTempMode(states.activeTempMode)); // indicator - show must be before, otherwise client width will be 0

                var temperatureAbsolute = usedValues.max - usedValues.min;
                var heightPercent = ((states.preparationPhase ? states.tempActual : states.tempTarget) - usedValues.min) / temperatureAbsolute; // subtract and add 10 for ignoring minimum height, subtract half circle, subtract 1 for the 3px border

                var circleBottom = (this.elements.timeLineEntries[0].clientHeight - MIN_ENTRY_HEIGHT) * heightPercent + MIN_ENTRY_HEIGHT - this.elements.tlIndicatorCircle[0].clientWidth / 2 - 1;
                this.elements.tlIndicatorCircle.css('bottom', circleBottom + 'px');
                this.elements.tlIndicatorLine.css('bottom', circleBottom + this.elements.tlIndicatorCircle.height() / 2 + 'px');
            } else {
                this.elements.tlIndicatorCircle.hide();
            }

            if (states.userInfo !== IRoomControlEnums.UserInfo.NO_PERIOD_ACTIVE) {
                this.elements.tlIndicatorLine.css('background-color', this.control.getColorOfIRCTempMode(states.currentTimeSpan.tempMode));
            } // user info
            // only show info when the info has changed, priority comes from the stateContainer
            // when the user hides the big-difference info or quit the timer, info can't open after another status update because user info is equal with before


            if (this.userInfo !== states.userInfo || states.currentTimeSpan && this.userInfoTemp !== states.currentTimeSpan.tempMode) {
                // the temp might be shown in the userInfo and might change (e.g. open window --> Heat Protection / Frost Protection)
                // so also update it when the tempMode changes.
                this.userInfoTemp = states.currentTimeSpan ? states.currentTimeSpan.tempMode : null; // remove old

                this.removeUserInfo.call(this, states.userInfo !== IRoomControlEnums.UserInfo.NOTHING, false); // add new

                this.userInfo = states.userInfo;

                if (this.userInfo !== IRoomControlEnums.UserInfo.NOTHING) {
                    var newInfo = this.createNewUserInfo.call(this, states);
                    this.elements.userInfo.append(newInfo);
                }
            } // only if a timer is active


            if (this.anyTimerPossible(this.userInfo)) {
                // update timer
                this.updateTimerInfo(this.userInfoElements, states);
            }

            this.states = states; // also update left position of the indicator after a state change

            SandboxComponent.getMiniserverTimeInfo(this, this.updateIndicatorPosition.bind(this), TimeValueFormat.MINUTES_SINCE_MIDNIGHT);
        }

        updateTimeLineEntries(placeholder, entries, temperatures, format) {
            placeholder.empty();
            var minHeight = HIDE_WHEN_TOO_LOW ? MIN_ENTRY_HEIGHT : MIN_VISIBLE_TEXT_HEIGHT;
            var timeLineWidth = placeholder[0].clientWidth;
            var timeLineHeight = placeholder[0].clientHeight - minHeight; // minimum height

            var minutesAbsolute = 24 * 60;
            var minTemp = Number.MAX_VALUE,
                maxTemp = Number.MIN_VALUE,
                temperatureAbsolute;

            if (entries.length > 1) {
                for (var i = 0; i < entries.length; i++) {
                    var temperature = temperatures[entries[i].tempMode];
                    minTemp = Math.min(minTemp, temperature);
                    maxTemp = Math.max(maxTemp, temperature);
                }

                temperatureAbsolute = maxTemp - minTemp;
            } else if (entries.length === 1) {
                temperatureAbsolute = temperatures[entries[0].tempMode];
                maxTemp = temperatureAbsolute;
                minTemp = 0;
            }

            var fragment = $(document.createDocumentFragment());

            for (i = 0; i < entries.length; i++) {
                var entry = entries[i];
                var startPercent = entry.from / minutesAbsolute;
                var endPercent = (minutesAbsolute - entry.to) / minutesAbsolute;
                var heightPercent = (temperatures[entry.tempMode] - minTemp) / temperatureAbsolute;
                var entryElement = $('<div class="entries-placeholder__entry"></div>');
                entryElement.css('left', timeLineWidth * startPercent + 'px');
                entryElement.css('right', timeLineWidth * endPercent + 'px');
                var entryHeight = timeLineHeight * heightPercent + minHeight;
                entryElement.css('height', entryHeight + 'px'); // minimum height

                var hexColor = this.control.getColorOfIRCTempMode(entry.tempMode);
                entryElement.css('background-color', hexColor);

                if (entryHeight >= MIN_VISIBLE_TEXT_HEIGHT) {
                    entryElement.append('<div class="entry__value-label text-overflow-ellipsis">' + lxFormat(format, temperatures[entry.tempMode]) + '</div>');
                }

                fragment.append(entryElement);
            }

            placeholder.append(fragment);
            return {
                min: minTemp,
                max: maxTemp
            };
        }

        updateIndicatorPosition(minutesSinceMidnight) {
            var leftPos = minutesSinceMidnight / (24 * 60) * this.elements.timeLineEntries[0].clientWidth - this.elements.timeLineIndicator[0].clientWidth / 2;
            leftPos += 10; // offset from entries placeholder to the time line

            this.elements.timeLineIndicator.css('left', leftPos + 'px');

            if (this.states && this.states.controllerMode !== IRoomControlEnums.IRCMode.MANUAL_HEATING && this.states.controllerMode !== IRoomControlEnums.IRCMode.MANUAL_COOLING && this.states.serviceMode === IRoomControlEnums.ServiceMode.OFF && this.userInfo === IRoomControlEnums.UserInfo.NOTHING) {
                this.updateArrowLines(leftPos + this.elements.timeLineIndicator[0].clientWidth / 2);
            }
        }

        createNewUserInfo(states) {
            Debug.Control.IRC.TimeLine && console.log(this.name + ": createNewUserInfo");
            var infoElement = $(TimeLine.Template.getUserInfoTemplate(this.userInfo));

            if (this.userInfo === IRoomControlEnums.UserInfo.BIG_DIFF_ACTUAL_TARGET || this.anyTimerPossible(this.userInfo)) {
                this.userInfoElements = {
                    closeButton: infoElement.siblings('.user-info__right-toucharea')
                };
                this.buttons.closeButton = new GUI.LxButton(this, this.userInfoElements.closeButton[0], Color.BUTTON_GLOW, null, true);
                this.buttons.closeButton.onButtonTapped = this.removeUserInfo.bind(this, false, true);
                this.buttons.closeButton.useChildsAsActiveParts('fill');
                this.addToHandledSubviews(this.buttons.closeButton); // only if a timer is active

                if (this.anyTimerPossible(this.userInfo)) {
                    this.userInfoElements.titleLabel = infoElement.find('.label-container__title');
                    this.userInfoElements.subtitleLabel = infoElement.find('.label-container__subtitle');
                    infoElement.find('.user-info__right-icon').css('fill', this.control.getColorOfIRCTempMode(states.activeTempMode));
                }
            } else if (this.userInfo === IRoomControlEnums.UserInfo.OPEN_WINDOW) {
                var tempName = this.control.getNameOfIRCTempMode(states.currentTimeSpan.tempMode);

                var text = _("controls.irc.timer.temp-activated", {
                    tempName: tempName
                });

                Debug.Control.IRC.TimeLine && console.log("     window open, current temp: " + tempName);
                infoElement.find('.label-container__subtitle').text(text);
            }

            return infoElement;
        }

        removeUserInfo(hasNewInfo, wasUser) {
            Debug.Control.IRC.TimeLine && console.log(this.name + ": removeUserInfo (new info: " + hasNewInfo + ")"); // remove info

            this.elements.userInfo.empty();

            if (this.buttons.closeButton) {
                this.buttons.closeButton.destroy();
                delete this.buttons.closeButton;
            } // only if a timer is active


            if (wasUser && this.anyTimerPossible(this.userInfo)) {
                this.sendCommand(Commands.I_ROOM_CONTROLLER.CANCEL_TIMER);
            }

            if (hasNewInfo) {
                this.elements.userInfo.show();
            } else {
                this.elements.userInfo.hide();
                var leftPos = parseInt(this.elements.timeLineIndicator.css('left'));
                this.updateArrowLines(leftPos + this.elements.timeLineIndicator[0].clientWidth / 2);
            }
        }

        updateTimerInfo(userInfoElements, states) {
            var title;

            if (states.userInfo === IRoomControlEnums.UserInfo.HAS_PRESENCE) {
                title = _("controls.irc.exception.presence");
            } else {
                title = _("controls.irc.timer");
            }

            if (states.remainingTime > 0) {
                title += ' ' + LxDate.formatSecondsShort(states.remainingTime);
            }

            userInfoElements.titleLabel.html(title);
            var tempName = this.control.getNameOfIRCTempMode(states.activeTempMode);
            userInfoElements.subtitleLabel.text(_("controls.irc.timer.temp-activated", {
                tempName: tempName
            })); // timer is always cancelable
            //userInfoElements.closeButton.toggle(states.remainingTime > 0);
        }

        /**
         * Checks if it's possible that a timer will be started in a while, or is already started yet (virtual timer, manual timer, presence, ...)
         * @param userInfo information to identify possibility
         * @returns {boolean|string}
         */
        anyTimerPossible(userInfo) {
            return userInfo === IRoomControlEnums.UserInfo.ACTIVE_TIMER || userInfo === IRoomControlEnums.UserInfo.HAS_PRESENCE || userInfo === IRoomControlEnums.UserInfo.COMFORT_TIMER || userInfo === IRoomControlEnums.UserInfo.ECONOMY_TIMER;
        }

    };
});
