import {useFocusEffect} from "@react-navigation/native";
import {useCallback} from "react";

const useBackNavigation = (onBackNav = () => {}, deps = []) => {
    useFocusEffect(
        useCallback(() => {
            let deReg = NavigationComp.registerForBackNavigation(onBackNav);
            return () => {
                deReg();
            }
        }, deps)
    )
}

export default useBackNavigation;
