'use strict';

define([], function () {
    return class LoxoneControlSettingsScreen extends GUI.TableViewScreen {

        get useNewTableView() {
            return true;
        }

        constructor() {
            super($('<div class="loxone-control-settings-screen" />'));

            if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.DeveloperInterface) {
                this.isVisibleInStatusBar = true;
                this.startsWithSystem = true;
            } else {
                this.isVisibleInStatusBar = LoxoneControl.isVisibleInStatusBar();
                this.startsWithSystem = LoxoneControl.isStartingWithSystem();
            }
        }

        titleBarText() {
            return _("loxone-control.menu-bar");
        }

        _isLinux() {
            if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.DeveloperInterface) {
                return false;
            }
            // Startup item is not supported on Linux!#
            // https://www.electronjs.org/docs/latest/api/app#appsetloginitemsettingssettings-macos-windows
            return electron && electron.remote.platform === "linux";
        }

        setTableContent() {
            this.tableContent = [];
            var section = {
                rows: []
            };

            section.rows.push({
                type: GUI.TableViewV2.CellType.SWITCH,
                content: {
                    title: _("loxone-control.settings.statusbar-visibility"),
                    active: this.isVisibleInStatusBar
                },
                onSwitchChanged: this._onStatusBarVisibilitySwitchChanged.bind(this)
            })

            if (this.isVisibleInStatusBar) {
                if (!this._isLinux()) {
                    section.rows.push({
                        type: GUI.TableViewV2.CellType.SWITCH,
                        content: {
                            title: _("loxone-control.settings.systemstart"),
                            active: this.startsWithSystem
                        },
                        onSwitchChanged: this._systemStartChanged.bind(this)
                    });
                }
            }

            this.tableContent.push(section);
            return super.setTableContent(...arguments);
        }

        _onStatusBarVisibilitySwitchChanged(value) {
            if (value) {
                this._changeStatusbarVisibility(value);
            } else {
                var content = {
                    title: _("warning"),
                    message: _("loxone-control.deactivation-message"),
                    buttonOk: true,
                    buttonCancel: true,
                    icon: Icon.CAUTION,
                    color: window.Styles.colors.red
                };
                NavigationComp.showPopup(content).then(function () {
                    this._changeStatusbarVisibility(value);
                }.bind(this), this.reloadTable.bind(this));
            }
        }

        // Private methods
        _changeStatusbarVisibility(active) {
            LoxoneControl.setVisibleInStatusBar(active).finally(function () {
                this.isVisibleInStatusBar = LoxoneControl.isVisibleInStatusBar();
                this.startsWithSystem = LoxoneControl.isStartingWithSystem(); // reload required, number of rows did change

                this.reloadTable();
            }.bind(this));
        }

        _systemStartChanged(active) {
            LoxoneControl.setStartWithSystem(active).then(function () {
                this.startsWithSystem = active;
            }.bind(this), function () {
                this.reloadTable(); // set back checkmark
            }.bind(this));
        }

    };
});
