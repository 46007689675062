'use strict';

define([], function () {
    var TIME_LINE_UPDATE_INTERVAL = 10000;
    return class DaytimerTimeLine extends GUI.View {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return '<div class="time-line__entries-placeholder"></div>' + '<canvas class="time-line__canvas"></canvas>' + '<div class="time-line__label-placeholder">' + '<span class="placeholder__time-label">00</span>' + '<span class="placeholder__time-label">06</span>' + '<span class="placeholder__time-label">12</span>' + '<span class="placeholder__time-label">18</span>' + '<span class="placeholder__time-label">24</span>' + '</div>' + '<div class="time-line__indicator"></div>' + '<div class="time-line__cancel-timer">' + ImageBox.getResourceImageWithClasses('resources/Images/Controls/Daytimer/timer.svg', 'cancel-timer__icon') + '<div class="cancel-timer__label">' + _("controls.daytimer.manual.end-button") + '</div>' + '<div class="cancel-timer__button">' + ImageBox.getResourceImageWithClasses('resources/Images/General/close.svg', 'button__icon') + '</div>' + '</div>';
            };

            var drawTimeLine = function drawTimeLine(timeLineElement) {
                var timeCanvas = timeLineElement[0];
                checkAndPrepareCanvasContextForRetina(timeCanvas);
                var ctx = timeCanvas.getContext("2d");
                var width = timeCanvas.clientWidth;
                var height = timeCanvas.clientHeight;
                ctx.beginPath();
                ctx.strokeStyle = Color.TIME_LINE_VALUE_INDICATORS;
                ctx.lineWidth = 2;
                ctx.moveTo(1, 0);
                ctx.lineTo(1, height); // big steps

                ctx.lineWidth = 1;
                var i;

                for (i = 0; i <= 24; i++) {
                    if (i % 3 === 0) {
                        ctx.moveTo((width - 1) / 24 * i, 0);
                        ctx.lineTo((width - 1) / 24 * i, height);
                    }
                }

                ctx.stroke(); // small steps

                ctx.lineWidth = 0.5;

                for (i = 0; i < 24; i++) {
                    if (i % 3 !== 0) {
                        ctx.moveTo(width / 24 * i, 2);
                        ctx.lineTo(width / 24 * i, height);
                    }
                }

                ctx.stroke();
            };

            return {
                getTemplate: getTemplate,
                drawTimeLine: drawTimeLine
            };
        }(); //endregion Static

        constructor(control) {
            super($('<div class="action-view__time-line"></div>'));
            this.control = control;
            this.elements = {};
        }

        viewDidLoad() {
            Debug.Control.Daytimer.TimeLine && console.log(this.name + ": viewDidLoad");
            super.viewDidLoad();
            this.element.append(DaytimerTimeLine.Template.getTemplate());
            this.elements = {
                timeLineEntries: this.element.find('.time-line__entries-placeholder'),
                timeLineIndicator: this.element.find('.time-line__indicator'),
                cancelTimer: this.element.find('.time-line__cancel-timer').hide()
            };
            var button = this.element.find('.cancel-timer__button');
            this.cancelButton = new GUI.LxButton(this, button[0], Color.BUTTON_GLOW, [{
                part: this.element.find('.cancel-timer__button').children()[0],
                mode: 'fill'
            }], true);
            this.addToHandledSubviews(this.cancelButton);
            this.openCalendarHandler = Hammer(this.element[0]);
            this.registerForResize();
        }

        viewWillAppear() {
            Debug.Control.Daytimer.TimeLine && console.log(this.name + ": viewWillAppear");
            super.viewWillAppear();
            var timeLine = this.element.find('.time-line__canvas');
            DaytimerTimeLine.Template.drawTimeLine(timeLine);

            this._updateIndicatorPosition(SandboxComponent.getMiniserverTimeInfo(null, null, TimeValueFormat.MINUTES_SINCE_MIDNIGHT));
        }

        viewDidAppear() {
            Debug.Control.Daytimer.TimeLine && console.log(this.name + ": viewDidAppear");
            super.viewDidAppear();
            this.openCalendarHandler.on(tapEvent(), function () {
                if (this.daytimerState !== DaytimerControlEnums.DaytimerState.TIMER) {
                    var target = DaytimerControlEnums.ScreenState.CALENDAR;
                    this.ViewController.showState(target, null, {
                        control: this.control
                    });
                }
            }.bind(this));
            this.timeIdentifier = SandboxComponent.getMiniserverTimeInfo(this, this._updateIndicatorPosition.bind(this), TimeValueFormat.MINUTES_SINCE_MIDNIGHT, TIME_LINE_UPDATE_INTERVAL);

            this.cancelButton.onButtonTapped = function stopTimer() {
                this.sendCommand(Commands.DAYTIMER.CANCEL_TIMER);
            }.bind(this);
        }

        destroy() {
            Debug.Control.Daytimer.TimeLine && console.log(this.name + ": destroy");
            this.openCalendarHandler.dispose();
            SandboxComponent.removeFromTimeInfo(this.timeIdentifier);
            super.destroy();
        }

        updateTimeLine(states) {
            Debug.Control.Daytimer.TimeLine && console.log(this.name + ": updateTimeLine");
            this._states = states;
            this.elements.timeLineEntries.empty();
            var timeLineWidth = this.elements.timeLineEntries[0].clientWidth;
            var minutesAbsolute = 24 * 60;
            var fragment = $(document.createDocumentFragment());

            var entries = this._getEntriesOfMode(states.entries, states.activeMode);

            for (var i = 0; i < entries.length; i++) {
                var entry = entries[i];
                var startPercent = entry.from / minutesAbsolute;
                var endPercent = (minutesAbsolute - entry.to) / minutesAbsolute;
                var entryElement = $('<div class="entries-placeholder__entry"></div>');
                entryElement.css('left', timeLineWidth * startPercent + 'px');
                entryElement.css('right', timeLineWidth * endPercent + 'px');

                if (entry.needActivate) {
                    entryElement.css('background-color', window.Styles.colors.orange);
                    var iconSrc = Icon.Daytimer.LOCK.CLOSED;

                    if (entry.from === states.currentTimeSpan.from && states.daytimerState === DaytimerControlEnums.DaytimerState.ON) {
                        iconSrc = Icon.Daytimer.LOCK.OPENED;
                    }

                    entryElement.append('<div class="entry__activation-circle">' + ImageBox.getResourceImageWithClasses(iconSrc, 'activation-circle__icon') + '</div>');
                }

                if (this.control.details.analog) {
                    entryElement.append('<div class="entry__value-label text-overflow-ellipsis">' + lxFormat(this.control.details.format, entry.value) + '</div>');
                }

                fragment.append(entryElement);
            }

            this.elements.timeLineEntries.append(fragment);
            this.elements.timeLineIndicator.toggleClass('indicator--active', states.daytimerState === DaytimerControlEnums.DaytimerState.ON);

            if (states.daytimerState === DaytimerControlEnums.DaytimerState.TIMER) {
                this.elements.cancelTimer.show();
            } else {
                this.elements.cancelTimer.hide();
            }

            this.daytimerState = states.daytimerState;
        }

        onResize() {
            Debug.Control.Daytimer.TimeLine && console.log(this.name + ": onResize"); // make sure the UI is updated asap, but not in a high frequency

            if (this.resizeTimeout) {
                return; // it'll update itself after the timer.
            }

            this.updateTimeLine(this._states);

            this._repositionIndicator(this._minutesSinceMidnight); // no timer running, update immediately
            // start a timer to avoid too many refreshes.


            this.resizeTimeout = setTimeout(function () {
                // worst case 2 updates where one was needed.
                this.resizeTimeout = null;
            }.bind(this), 200);
        }

        /**
         * Updates the minutesSinceMidnight Attribute and calls repositionIndicator to update the UI too.
         * @param minutesSinceMidnight
         * @private
         */
        _updateIndicatorPosition(minutesSinceMidnight) {
            Debug.Control.Daytimer.TimeLine && console.log(this.name + ": _updateIndicatorPosition");
            this._minutesSinceMidnight = minutesSinceMidnight;

            this._repositionIndicator(this._minutesSinceMidnight);
        }

        /**
         * Ensures the indicator is drawn at the proper position based on the current time and view size.
         * @param minutesSinceMidnight
         * @private
         */
        _repositionIndicator(minutesSinceMidnight) {
            Debug.Control.Daytimer.TimeLine && console.log(this.name + ": _repositionIndicator");
            var leftPos = minutesSinceMidnight / (24 * 60) * this.elements.timeLineEntries[0].clientWidth - this.elements.timeLineIndicator[0].clientWidth / 2;
            this.elements.timeLineIndicator.css('left', 10 + leftPos + 'px');
        }

        /**
         * Extracts a list entries for a specific mode index.
         * @param entries   the entries where the given modes entries are to be picked from
         * @param mode      the mode index number (-1,0,5,..)
         * @returns {*}     either the entries-array or FALSE if no entries exist.
         * @private
         */
        _getEntriesOfMode(entries, mode) {
            Debug.Control.Daytimer.TimeLine && console.log(this.name + ": _getEntriesOfMode: " + mode + ", entries: " + JSON.stringify(entries)); // mode 0 is "celebration day", so don't just compare with if(mode)!

            if (entries && (mode || mode === 0) && entries[mode]) {
                return entries[mode];
            }

            return false;
        }

    };
});
