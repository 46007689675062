'use strict';

define("SteakRenameScreen", [], function () {
    return class SteakRenameScreen extends Controls.ControlContent {
        constructor(details) {
            super(...arguments);
            this.sensor = details.sensor;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.setUpComfortUI(true);
            this.toggleStateIcons(false);
            this.nameInput = new GUI.LxInput({
                value: this.sensor.name,
                placeholder: _('autopilot.rule.title'),
                type: GUI.LxInputEnum.Type.TEXT,
                resetButton: true,
                autoFocus: true,
                validationRegex: Regex.CMD_TEXT,
                filterRegex: Regex.CMD_TEXT_FILTER,
                translucent: true // will set isDark too.

            }, this);
            this.nameInput.getElement().addClass("rename-text-field__input");
            this.appendSubview(this.nameInput);
            this.setTitle(_('autopilot.rule.title'));
        }

        viewWillDisappear() {
            // Only change the name if it is not the same name, also only send valid names (checked by the inputs regex)
            if (this.newName && this.newName !== this.sensor.name && this.nameInput.isValid()) {
                this.control.sendCommand(Commands.format(Commands.Steak.SET_SENSOR, this.sensor.idx, this.sensor.targetTemperature, this.newName));
            }

            super.viewWillDisappear(...arguments);
        }

        destroy() {
            if (this.control.isGrouped()) {
                this.control.updateGroupSelection(null);
            }

            super.destroy();
        }

        /**
         * Called whenever the search field was "submitted", eg. User entered the return key.
         * @param value
         * @param valid
         * @param valueDidChange
         */
        submitText(value, valid, valueDidChange) {
            this.newName = value;
            this.ViewController.navigateBack();
        }

        textChanged(value, valid, valueDidChange) {
            this.newName = value;
        }

    };
});
