import CachedServerFileLoader from "../../shared/logic/fileAccess/CachedServerFileLoader";
import FileRequest from "../../shared/logic/fileAccess/FileRequest";
import PersistentStorageHandler from "../../projectSpecific/logic/storage/PersistentStorageHandler";
import CacheFileHandler from "../../shared/logic/fileAccess/CacheFileHandler";


const WALLPAPER_PATH = "https://app-content.loxone.com/wallpapers.json";

export default class WallpaperOptions {

    static get() {
        return CachedServerFileLoader.getFile(
            FileRequest.remote(WALLPAPER_PATH, DataType.OBJECT),
            { cacheHandler: new CacheFileHandler(new PersistentStorageHandler()) }
        ).then(({data}) => {
            return data.wallpapers;
        });
    }
}