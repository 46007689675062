import * as React from "react"
import Svg, { Path } from "react-native-svg"

function PinWithCross(props) {
    return (
        <Svg isreact="true"
            height={24}
            viewBox="0 0 24 24"
            width={24}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M16.974 20.342a.5.5 0 01-.233.596l-.083.036L13.582 22l3.076 1.026a.5.5 0 01-.228.97l-.088-.022L12 22.527l-4.342 1.447a.5.5 0 01-.399-.912l.083-.036L10.418 22l-3.076-1.026a.5.5 0 01.228-.97l.088.022L12 21.472l4.342-1.446a.5.5 0 01.632.316zM12 0a8 8 0 018 8 8 8 0 01-6.023 7.75l-.194.045-1.304 4.349c-.133.443-.728.473-.923.088l-.035-.088-1.305-4.349-.193-.046a8 8 0 01-6.019-7.492L4 8a8 8 0 018-8zm0 1a7 7 0 00-1.298 13.876.5.5 0 01.387.347L12 18.261l.911-3.038a.5.5 0 01.298-.322l.089-.025A7 7 0 0012 1zm0 2a5 5 0 110 10 5 5 0 010-10zm0 1a4 4 0 100 8 4 4 0 000-8z"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default PinWithCross
