'use strict';
/**
 * @param rowIdx          resuable cells have to know about what row they are being reused in.
 * @param [clickable]     whether or not the cell itself can be clicked --> TRUE if missing
 *
 * @delegate onListCellTapped (cell, row, list)
 */

window.GUI = function (GUI) {
    GUI.LVContent.DELETABLE = "deletable";
    GUI.LVContent.MOVABLE = "movable";
    GUI.LVContent.Y_POS = "yPos";
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var MOVING_CLASS = "editable-reusable-list-cell--moving";

        class EditableReusableListCell extends GUI.ReusingListView.Cells.BaseCell {
            //region Static
            static Template = function () {
                var getDeleteButton = function getDeleteButton() {
                    return $('<div class="editable-reusable-list-cell__icon-cntr editable-reusable-list-cell__delete">' + ImageBox.getResourceImageWithClasses(Icon.REMOVE, "icon-cntr__icon") + '</div>');
                };

                var getMoveSymbol = function getMoveSymbol() {
                    return $('<div class="editable-reusable-list-cell__icon-cntr editable-reusable-list-cell__move clickable">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.BTN_SORT, "icon-cntr__icon") + '</div>');
                };

                return {
                    getDeleteButton: getDeleteButton,
                    getMoveSymbol: getMoveSymbol
                };
            }(); //endregion Static

            constructor(delegate, cellType, listView) {
                super(...arguments);
                this.element.addClass("editable-reusable-list-cell");
            }

            viewDidLoad() {
                return super.viewDidLoad(...arguments).then(function (res) {
                    var delButton, moveSymbol;
                    delButton = EditableReusableListCell.Template.getDeleteButton();
                    this.deleteButton = new GUI.LxButton(this, delButton[0], Color.BUTTON_GLOW);
                    this.deleteButton.useChildsAsActiveParts('fill');
                    this.prependSubview(this.deleteButton);
                    this.hideSubview(this.deleteButton);
                    moveSymbol = EditableReusableListCell.Template.getMoveSymbol();
                    this.getElement().append(moveSymbol);
                    this.elements.moveSymbol = this.getElement().find(".editable-reusable-list-cell__move").hide();
                    return res;
                }.bind(this));
            }

            viewDidAppear() {
                var promise = super.viewDidAppear(...arguments);
                this.deleteButton.onButtonTapped = this._deleteButtonTapped.bind(this);

                this._registerForHammerEvents();

                return promise;
            }

            viewWillDisappear() {
                this._unregisterFromHammerEvents();

                this.deleteButton.onButtonTapped = null;
                return super.viewWillDisappear(...arguments);
            }

            /**
             * Called when a cell is being initialized with content, or when it is reused.
             * @param content
             * @param scrolling  if the cell is being updated while scrolling is active.
             */
            updateContent(content, scrolling) {
                super.updateContent(...arguments);
                var deletable = this.cAttr(GUI.LVContent.DELETABLE),
                    movable = this.cAttr(GUI.LVContent.MOVABLE);
                this.toggleSubview(this.deleteButton, deletable);
                GUI.animationHandler.toggle(this.elements.moveSymbol, movable);
            }

            /**
             * Returns whether or not this cell is currently in edit mode, either movable or deletable
             * @returns {*}
             */
            isInEditMode() {
                return this.cAttr(GUI.LVContent.DELETABLE, false) || this.cAttr(GUI.LVContent.MOVABLE, false);
            }

            /**
             * Will ask the delegate for permission first (if implemented) then it'll tell the listView to remove the
             * cell and inform the delegate that the cell has been removed afterwards.
             * @private
             */
            _deleteButtonTapped() {
                var result = true;

                if (this.delegate.didRequestRemovingListCell) {
                    result = this.delegate.didRequestRemovingListCell.call(this.delegate, this.content.rowIdx, this.listView);
                }

                Q.when(result).done(function () {
                    // can be a promise or a boolean
                    if (result) {
                        // the delegate might want to update sth after the cell was removed
                        if (this.delegate.removeListCell) {
                            this.delegate.removeListCell.call(this.delegate, this.content.rowIdx, this.listView);
                        }

                        if (this.listView._didRemoveCell) {
                            this.listView._didRemoveCell.call(this.listView, this, this.content.rowIdx);
                        } else {
                            console.error("A cell cannot remove itself!");
                            throw new Error("A cell cannot remove itself, the listView needs to implement _removeCell!");
                        }
                    }
                }.bind(this), function () {
                    console.info("Promise said no to delete this cell!");
                });
            }

            // ----------------------------------------------------------------------------------------------------
            //           Pure Hammer.js -> As in calendarEntires.js
            // ----------------------------------------------------------------------------------------------------
            _registerForHammerEvents() {
                var options = {}; // no options as there is no need to detect a hold.

                this.combiHammer = new Hammer(this.elements.moveSymbol[0], options);
                this.hammerHandler = this.combiHammer.on("touch touchmove drag release", this._handleCombiHammer.bind(this));
            }

            _unregisterFromHammerEvents() {
                this.hammerHandler.dispose();
                this.hammerHandler = null;
            }

            /**
             * Handles the different events that indicate a cell movement
             * Desktop: touch -> dragstart -> drag -> dragend -> release
             * Touch: touch -> touchmove -> release
             * @param event
             * @private
             */
            _handleCombiHammer(event) {
                Debug.GUI.ListViewCells && console.log(this.name + ": _handleCombiHammer: " + event.type);
                var newPos = this.cAttr(GUI.LVContent.Y_POS);

                switch (event.type) {
                    case 'touch':
                        this.initialEvent = event; // add the "ghost"-effect (transparent)

                        this.getElement().toggleClass(MOVING_CLASS, true);

                        this.listView._didStartMovingCell.call(this.listView, this, this.content.rowIdx, newPos);

                        break;

                    case 'drag':
                    case 'touchmove':
                        if (this.initialEvent) {
                            this._handleCombiMove(event);

                            this._stopEvent(event);
                        }

                        break;

                    case 'release':
                        if (this.initialEvent) {
                            newPos = typeof this.lastPos === "number" ? this.lastPos : newPos; // remove the "ghost"-effect (transparent)

                            this.getElement().toggleClass(MOVING_CLASS, false); // inform the delegate -> beware the cell might be destroyed after this call!

                            this.listView._didStopMovingCell.call(this.listView, this, this.content.rowIdx, newPos);

                            this.initialEvent = null;
                        }

                        break;

                    default:
                        break;
                }
            }

            _handleCombiMove(evt) {
                var dy = 0,
                    eventPos = 51; // not moving.

                if (evt.hasOwnProperty("gesture")) {
                    dy = evt.gesture.deltaY;
                    eventPos = evt.gesture.center.clientY;
                } else if (this.initialEvent) {
                    var touch = evt.touches[0];

                    if (this.initialEvent.clientY) {
                        dy = touch.clientY - this.initialEvent.clientY;
                    } else if (this.initialEvent.gesture) {
                        dy = touch.clientY - this.initialEvent.gesture.center.clientY;
                    }

                    eventPos = touch.clientY;
                }

                Debug.GUI.ListViewCells && console.log(this.name + ": _handleCombiMove: " + evt.type + " dy=" + dy);

                this._updatePostion(dy, eventPos);
            }

            _updatePostion(deltaY, eventPos) {
                Debug.GUI.ListViewCells && console.log(this.name + ": _updatePosition: " + deltaY);
                this.lastPos = this.cAttr(GUI.LVContent.Y_POS) + deltaY;

                this.listView._didMoveCell.call(this.listView, this, this.content.rowIdx, this.lastPos, eventPos);
            }

            _stopEvent(event) {
                if (event.cancelable) {
                    event.preventDefault();
                    event.stopPropagation();
                }
            }

        }

        GUI.ReusingListView.Cells.EditableBaseCell = EditableReusableListCell;
    }
    return GUI;
}(window.GUI || {});
