import Svg, { Path } from "react-native-svg"

function BatteryMonitor(props) {
    return (
        <Svg isreact="true"
            height={24}
            viewBox="0 0 24 24"
            width={24}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M20 6a1 1 0 011 1v10a1 1 0 01-1 1H1a1 1 0 01-1-1V7a1 1 0 011-1zm0 1H1v10h19zM2.5 8a.5.5 0 01.492.41L3 8.5v7a.5.5 0 01-.992.09L2 15.5v-7a.5.5 0 01.5-.5zm2 0a.5.5 0 01.492.41L5 8.5v7a.5.5 0 01-.992.09L4 15.5v-7a.5.5 0 01.5-.5zm2 0a.5.5 0 01.492.41L7 8.5v7a.5.5 0 01-.992.09L6 15.5v-7a.5.5 0 01.5-.5zm2 0a.5.5 0 01.492.41L9 8.5v7a.5.5 0 01-.992.09L8 15.5v-7a.5.5 0 01.5-.5zM23 9a1 1 0 01.993.883L24 10v4a1 1 0 01-.883.993L23 15h-.5a.5.5 0 01-.09-.992L22.5 14h.5v-4h-.5a.5.5 0 01-.09-.992L22.5 9z"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default BatteryMonitor
