export const fontSettings = {
    families: {
        regular: "Averta-Regular",
        regularItalic: "Averta-RegularItalic",
        bold: "Averta-Bold",
        extraBold: "Averta-ExtraBold",
        semiBold: "Averta-SemiBold",
        semiBoldItalic: "Averta-SemiBoldItalic",
        light: "Averta-Light",
        italic: "Averta-Italic"
    },
    sizes: {
        micro: 9,
        tiny: 11,
        smaller: 12,
        small: 14,
        smallReg: 15,
        regular: 16,
        medium: 17,
        large: 20,
        title3: 20,
        title2: 22,
        veryLarge: 25,
        title1: 28,
        largeTitle: 34,
        pageHeading: 40,
        ambientModeDate: 72,
        ecoScreenDate: 88,// e.g. on the cards screen or in the menu
    },
    lineHeights: {
        small: 17,
        smallReg: 19,
        medium: 21,
        title3: 25,
        title2: 28,
        title1: 35,
        largeTitle: 42,
        pageHeading: 48,
        cc_more_hd: 55,
        ambientTime: 69,
    }
}

const textStyleBase = {
    medium: {
        fontSize: fontSettings.sizes.medium,
        lineHeight: fontSettings.lineHeights.medium
    },
    small: {
        fontSize: fontSettings.sizes.small,
        lineHeight: fontSettings.lineHeights.small
    },
    title1: {
        fontSize: fontSettings.sizes.title1,
        lineHeight: fontSettings.lineHeights.title1
    },
    title2: {
        fontSize: fontSettings.sizes.title2,
        lineHeight: fontSettings.lineHeights.title2
    },
    title3: {
        fontSize: fontSettings.sizes.title3,
        lineHeight: fontSettings.lineHeights.title3
    },
    large: {
        fontSize: fontSettings.sizes.large,
        lineHeight: fontSettings.lineHeights.title3
    },
    largeTitle: {
        fontSize: fontSettings.sizes.largeTitle,
        lineHeight: fontSettings.lineHeights.largeTitle
    },
    pageHeading: {
        fontSize: fontSettings.sizes.pageHeading,
        lineHeight: fontSettings.lineHeights.largeTitle
    }
}

export const textStyles = {
    body: {
        default: {
            ...textStyleBase.medium,
            fontFamily: fontSettings.families.regular,
        },
        italic: {
            ...textStyleBase.medium,
            fontFamily: fontSettings.families.regularItalic,
        },
        bold: {
            ...textStyleBase.medium,
            fontFamily: fontSettings.families.semiBold,
        },
        boldItalic: {
            ...textStyleBase.medium,
            fontFamily: fontSettings.families.semiBoldItalic,
        }
    },
    footNote: {
        default: {
            ...textStyleBase.small,
            fontFamily: fontSettings.families.regular
        },
        bold: {
            ...textStyleBase.small,
            fontFamily: fontSettings.families.semiBold
        }
    },
    title1: {
        default: {
            ...textStyleBase.title1,
            fontFamily: fontSettings.families.semiBold
        },
        bold: {
            ...textStyleBase.title1,
            fontFamily: fontSettings.families.extraBold
        }
    },
    title2: {
        default: {
            ...textStyleBase.title2,
            fontFamily: fontSettings.families.semiBold
        },
        bold: {
            ...textStyleBase.title2,
            fontFamily: fontSettings.families.extraBold
        }
    },
    title3: {
        normal: {
            ...textStyleBase.title3,
            fontFamily: fontSettings.families.bold
        },
        default: {
            ...textStyleBase.title3,
            fontFamily: fontSettings.families.extraBold
        },
        bold: {
            ...textStyleBase.title3,
            fontFamily: fontSettings.families.extraBold
        }
    },
    largeTitle: {
        default: {
            ...textStyleBase.largeTitle,
            fontFamily: fontSettings.families.regular
        },
        bold: {
            ...textStyleBase.largeTitle,
            fontFamily: fontSettings.families.extraBold
        }
    },
    pageHeading: {
        default: {
            ...textStyleBase.pageHeading,
            fontFamily: fontSettings.families.regular
        },
        bold: {
            ...textStyleBase.pageHeading,
            fontFamily: fontSettings.families.extraBold
        }
    },
    titleBar: {
        title: {
            ...textStyleBase.medium,
            fontFamily: fontSettings.families.semiBold,
        },
        action: {
            default: {
                fontFamily: fontSettings.families.regular,
                fontSize: fontSettings.sizes.medium,
                lineHeight: fontSettings.lineHeights.small
            }
        }
    },
    ecoScreenDate: {
        fontFamily: fontSettings.families.extraBold,
        fontSize: fontSettings.sizes.ecoScreenDate,
        lineHeight: fontSettings.lineHeights.ambientTime,
        marginVertical: 10.5
    },
    ambientModeDate: {
        fontFamily: fontSettings.families.extraBold,
        fontSize: fontSettings.sizes.ambientModeDate,
        lineHeight: fontSettings.lineHeights.ambientTime,
        marginVertical: 8.5
    },
    miniserver: {
        fontFamily: fontSettings.families.semiBold,
        fontSize: fontSettings.sizes.small,
        textTransform: "uppercase"
    },
    tabBarLabel: {
        fontFamily: fontSettings.families.semiBold,
        fontSize: fontSettings.sizes.smaller,
    },
    default: {
        fontFamily: fontSettings.families.regular,
        fontSize: fontSettings.sizes.regular
    },
    titleLarge: {
        ...textStyleBase.large,
        fontFamily: fontSettings.families.extraBold
    },
    subheadline: {
        regular: {
            fontSize: fontSettings.sizes.smallReg,
            lineHeight: 20,
            fontFamily: fontSettings.families.regular
        },
        italic: {
            fontSize: fontSettings.sizes.smallReg,
            lineHeight: 20,
            fontFamily: fontSettings.families.regularItalic,
        },
        strong: {
            fontSize: fontSettings.sizes.smallReg,
            lineHeight: 20,
            fontFamily: fontSettings.families.semiBold
        }
    }
}
