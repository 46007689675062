import * as React from "react"
import Svg, { Path } from "react-native-svg"

function MoonAndStars(props) {
    return (
        <Svg isreact="true"
            height={24}
            viewBox="0 0 24 24"
            width={24}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M9.867 4.006a.5.5 0 01.485.646C8.777 9.792 12.622 15 18 15l.114-.007a.5.5 0 01.49.646A9 9 0 011 13c0-4.92 3.952-8.922 8.867-8.994zm-.652 1.037l-.08.008A7.997 7.997 0 0010 21a8.001 8.001 0 007.376-4.903l.046-.116-.201-.014c-5.38-.455-9.163-5.578-8.022-10.853zm11.317 3.782a.5.5 0 01.936 0l.465 1.24 1.243.467a.5.5 0 01.085.895l-.086.041-1.242.465-.465 1.242a.5.5 0 01-.895.087l-.041-.087-.466-1.242-1.241-.465a.5.5 0 01-.087-.895l.086-.041 1.242-.467zm.468 1.6l-.077.204a.5.5 0 01-.292.293l-.206.077.205.078a.5.5 0 01.256.215l.037.078.077.203.077-.203a.5.5 0 01.215-.256l.078-.037.204-.078-.205-.077a.5.5 0 01-.255-.215l-.037-.078zm-5.468-9.6a.5.5 0 01.936 0l1.011 2.694 2.697 1.013a.5.5 0 01.086.895l-.087.041-2.696 1.01-1.01 2.698a.5.5 0 01-.896.086l-.041-.086-1.012-2.698-2.695-1.01a.5.5 0 01-.087-.895l.086-.041 2.696-1.013zM16 2.423l-.623 1.66a.5.5 0 01-.292.292L13.424 5l1.66.623a.5.5 0 01.256.215l.037.077.623 1.66.623-1.66a.5.5 0 01.215-.255l.078-.037 1.659-.623-1.66-.623a.5.5 0 01-.255-.215l-.037-.077z"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default MoonAndStars
