'use strict';

define("ValueSelectorControlCommandSrc", ["UpDownLeftRightAnalogControlCommandSrc"], function (UpDownLeftRightAnalogControlCommandSrc) {
    return class ValueSelectorControlCommandSrc extends UpDownLeftRightAnalogControlCommandSrc {
        constructor() {
            super(...arguments);
        }

    };
});
