'use strict';

define(['AudioZoneV2ControlEnums'], function (AudioZoneV2ControlEnums) {
    class MusicServerSettings extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            this.details = details;
            this.control = details.control;
            this.currentTableContent = details.currentTableContent;
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            return _("settings");
        }

        reloadTable() {
            // ensure the line in row is hidden if none is available
            return this.control.audioserverComp.getInputs().promise.then(inputs => {
                this.tableContent = [this._getZoneSettingsSection(), this._getMusicServerSettingsSection(inputs), {
                    rows: [this._getAboutMusicServerCell()]
                }];
                return super.reloadTable(...arguments);
            });
        }

        // Zone Settings Section
        _getZoneSettingsSection() {
            var zoneSection = this._getSectionTemplate(_("media.preferences.zone.x", {
                x: this.control.getName()
            })); // only show volume settings if reasonable - if external volume is used, this doesn't have any effect.

           !this.control.externalUpDownVolume && zoneSection.rows.pushObject(this._getVolumeCell());
            zoneSection.rows.pushObject(this._getEQCell()); // Airplay is removed until it officially works
            //zoneSection.rows.pushObject(this._getAirPlayCell());

            return zoneSection;
        }

        // Music Server Settings Sections
        _getMusicServerSettingsSection(inputs) {
            var musicServerSettingsSection = this._getSectionTemplate(_("audio-server.popup.title"));

            musicServerSettingsSection.rows.pushObject(this._getSpotifyCell());
            musicServerSettingsSection.rows.pushObject(this._getSoundsuitCell());
            musicServerSettingsSection.rows.pushObject(this._getLibraryCell());
            inputs.length > 0 && musicServerSettingsSection.rows.pushObject(this._getAudioSourceCell(inputs));

            musicServerSettingsSection.rows.pushObject(this._getBluetoothCell());
            return musicServerSettingsSection;
        }

        _getAirPlayCell() {
            var target = AudioZoneV2ControlEnums.ScreenState.AIRPLAY,
                detail = {
                    control: this.control
                };
            return this._getCellTemplate(_("media.preferences.airplay"), target, detail);
        }

        _getAudioSourceCell(inputs) {
            var target = AudioZoneV2ControlEnums.ScreenState.SETTINGS_INPUT_LIST,
                detail = {
                    control: this.control
                },
                title = _("media.section.line-in"),
                disclosureText;

            if (inputs.length === 1) {
                target = AudioZoneV2ControlEnums.ScreenState.SETTINGS_INPUT;
                detail.input = inputs[0];
            }

            return this._getCellTemplate(title, target, detail);
        }

        _getLibraryCell() {
            if (this.control.audioserverComp.Feature.NAS_LIBRARY) {
                var target = AudioZoneV2ControlEnums.ScreenState.SETTINGS_LIBRARY,
                    detail = {
                        control: this.control
                    };
                return this._getCellTemplate(_("media.library"), target, detail);
            }
        }

        _getSpotifyCell() {
            var target = AudioZoneV2ControlEnums.ScreenState.SETTINGS_SERVICES_SPOTIFY_MANAGEMENT,
                detail = {
                    control: this.control
                };
            return this._getCellTemplate(_("media.popup.spotify.title"), target, detail);
        }

        _getSoundsuitCell() {
            var target = AudioZoneV2ControlEnums.ScreenState.SETTINGS_SERVICES_SOUNDSUIT_MANAGEMENT,
                detail = {
                    control: this.control
                };
            return this._getCellTemplate(SOUNDSUIT_TITLE, target, detail);
        }

        _getZonesCell() {
            var target = AudioZoneV2ControlEnums.ScreenState.SETTINGS_ZONES_LIST,
                detail = {
                    control: this.control
                };
            return this._getCellTemplate(_("media.preferences.zones"), target, detail);
        }

        _getEQCell() {
            if (!this.control.supportsEQ) {
                return null;
            }
            var target = AudioZoneV2ControlEnums.ScreenState.SETTINGS_EQ,
                detail = {
                    control: this.control
                };
            return this._getCellTemplate(_("media.equalizer"), target, detail);
        }

        _getBluetoothCell() {
            if (!this.control.supportsBluetooth) {
                return null;
            }
            var target = AudioZoneV2ControlEnums.ScreenState.SETTINGS_BLUETOOTH,
                detail = {
                    control: this.control
                };
            return this._getCellTemplate(
                _("audio-server.settings.bluetooth.title"),
                target,
                detail
            );
        }

        _getVolumeCell() {
            var target = AudioZoneV2ControlEnums.ScreenState.SETTINGS_VOLUME,
                detail = {
                    control: this.control
                };
            return this._getCellTemplate(_("media.volume"), target, detail);
        }

        _getAboutMusicServerCell() {
            var target = AudioZoneV2ControlEnums.ScreenState.SETTINGS.ABOUT,
                detail = {
                    control: this.control
                };
            return this._getCellTemplate(_("media.preferences.about", {
                server: this.control.audioserverComp.getServerName()
            }), target, detail);
        }

        _showScreen(target, detail) {
            this.ViewController.showState(target, null, detail);
        }

        _getCellTemplate(title, target, details) {
            return {
                content: {
                    title: title,
                    clickable: true,
                    disclosureIcon: false,
                    class: "base-cell--with-chevron"
                },
                type: GUI.TableViewV2.CellType.GENERAL,
                action: function () {
                    if (target && details) {
                        this._showScreen(target, details);
                    }
                }.bind(this)
            };
        }

        _getSectionTemplate(title) {
            return {
                headerTitle: title,
                rows: []
            };
        }

    }

    Controls.AudioZoneV2Control.MusicServerSettings = MusicServerSettings;
    return Controls.AudioZoneV2Control.MusicServerSettings;
});
