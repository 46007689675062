import PropTypes from "prop-types";
import React, {useState, useRef, useEffect, useCallback} from "react";
import { View } from "react-native";
import * as NfcCodeTouchControlEnums from "NfcCodeTouchControlEnums";
import globalStyles from "GlobalStyles";
import UserSharePDF from "./userSharePDF";
import Icons from "IconLib";
import {
    LxReactTableView,
    navigatorConfig,
    LxReactText
} from "LxComponents"
//tableContentHelper
import {
    _getTrustGeneralSection,
    _getGeneralSection,
    _getUserStateSection,
    _getUserGroupSection,
    _getSecuritySection,
    _getNfcSection,
    _createDeleteUserCell,
    _checkIfOnlyOneAdminIsRemaining,
    _getChangedUserGroups,
    _checkPermissions,
    _getChangedUser,
    doesUserActivateLater,
    canUserExpire,
} from "./userDetailsTableContentHelper";
import useCustomFieldCaptions from "./useCustomFieldCaptions";
import ChangeSecretScreen from "../../changeSecret/changeSecretScreen";
import useUserPropOptions from "./useUserPropOptions";

function UserDetailsScreen({navigation, route}) {
    const details = route.params,
        localProperties = {
            isAdmin: "isAdmin",
            changePassword: "changePassword",
            name: "name",
            userid: "userid", // this is the NFC Code Touch ID, it can be modified for trust users
            firstName: "firstName",
            desc: "desc",  // can be modified for trust users
            lastName: "lastName",
            email: "email",
            phone: "phone",
            userState: "userState", // can be modified for trust users
            validFrom: "validFrom", // can be modified for trust users
            validUntil: "validUntil", // can be modified for trust users
            expirationAction: "expirationAction", // can be modified for trust users

            // the trailing once may be available since 14.3.7.13
            uniqueId: "uniqueUserId",
            company: "company",
            department: "department",
            personalno: "personalno",
            title: "title",
            debitor: "debitor",

            customField1: "customField1",// can be modified for trust users
            customField2: "customField2",// can be modified for trust users
            customField3: "customField3",// can be modified for trust users
            customField4: "customField4",// can be modified for trust users
            customField5: "customField5",// can be modified for trust users
        };

    let userUuid,
        hasUserManagementPermissions = ActiveMSComponent.getRequiredUserManagementPermissionInfos().hasUserManagementPermissions,
        isCurrentUser;
    const customFieldCaptions = useCustomFieldCaptions();
    const userPropOptions = useUserPropOptions();

    // region states
    const [currentUser, setCurrentUser] = useState(cloneObject(details.user));
    // endregion
    if (details.user) {
        userUuid = details.user.uuid;
    } else {
        userUuid = currentUser.uuid;
    }

    isCurrentUser = userUuid === ActiveMSComponent.getCurrentUser().uuid

    // region Refs
    const refs = useRef({
        users: details.users,
        userIsLastPermanentActiveAdmin: true,
        originUser: {},
        allDataLoaded: false,
        trustPeers: details.trustPeers,
        trustMember: false,
        isUserFromOtherTrustPeer: false,
        trustMemberName: "",
        invalidUntilTime: false,
        isMasterUser: false,
        sortedCodeTouches: {},
        needsReload: details.isNewUser,
        isNewUser: details.isNewUser,
        homeKitDefer: details.homeKitDefer,
        changesSaved: false
    });

    const originalUsername = useRef(currentUser.name); // keep original username so that we don't run the requests against the user that may not exist yet (i.e when changing username and password/visuPassword at the same time)

    const permissionRefs = useRef({
        isAdmin: false,
        disableChangePassSwitch: true,
        changePassword: false
    })
    // endregion


    useEffect(() => {
        const getRightAction = () => {
            if (refs.current.homeKitDefer) {
                return [{
                    action: ({dimensions, props, key}) => {
                        return <Icons.ArrowRightCircled
                            fill={globalStyles.colors.buttonDisabledBg}
                            height={globalStyles.sizes.icons.big}
                            width={globalStyles.sizes.icons.big}/>
                    }
                }]
            } else {
                return  [];
            }
        }

        const onRightActionClicked = () => {
            return _sendNewProperties().then(() => {
                if (permissionRefs.current.isAdmin && currentUser.scorePWD === UserManagement.EMPTY_PWD) {
                    _showPasswordRequiredPopup();
                } else if (currentUser.usergroups.length === 0) {
                    return NavigationComp.showPopup({
                        title: _("home-kit.users.missing-group-hint"),
                        buttonOk: _('ok'),
                        buttonCancel: false
                    }, PopupType.GENERAL);
                } else {
                    refs.current.homeKitDefer.resolve(currentUser);
                    navigation.goBack();
                }
            });
        }

        navigation.setOptions(
            navigatorConfig({
                animationType: refs.current.isNewUser || refs.current.homeKitDefer ? AnimationType.MODAL : AnimationType.PUSH_OVERLAP_LEFT,
                title: details.displayCurrentUser ? _('menu.current-user') : _('user.management'),
                disableBackNavigation: refs.current.invalidUntilTime,
                onLeftAction: () => navigation.goBack(),
                rightActions: getRightAction(),
                onRightAction: () => {
                    return onRightActionClicked();
                }
            })
        );

        const unsubscribeListener = navigation.addListener('beforeRemove', (e) => {
// if the EFM has been opened by expanding a graph from ambient mode, it should be closed when when clicking back.
            if (!refs.current.changesSaved) {
                e.preventDefault();
            }

            if (!refs.current.changesSaved && !refs.current.invalidUntilTime) {
                _handleNavigateBack()
            }
        });

        return () => {
            unsubscribeListener && unsubscribeListener();
        }
    }, [currentUser])

    useEffect(() => {
        _loadInitialInformation(true);
    }, [])

    useEffect(() => {
        if (route.params.updateUser) {
            setCurrentUser(user => {
                let tmpUser = {...user};

                tmpUser[route.params.updateUser.key] = route.params.updateUser.value;
                if (route.params.updateUser.type) {
                    refs.current[route.params.updateUser.type] = route.params.updateUser.text;
                }

                permissionRefs.current = _checkPermissions(false, tmpUser);

                return tmpUser;
            })
        } else if (route.params.hasOwnProperty("needsReload")) {
            _getUser().then((msUser) => {
                setCurrentUser(user => {
                    let tmpUser = user;

                    switch (route.params.needsReload) {
                        case ChangeSecretScreen.Type.UserPw:
                        case ChangeSecretScreen.Type.VisuPw:
                        case ChangeSecretScreen.Type.AccessCode:
                            refs.current[route.params.needsReload] = route.params.details.text;
                            delete tmpUser[route.params.details.key];

                            break;
                        default:
                            delete tmpUser[route.params.needsReload];
                            break;
                    }

                    tmpUser = {
                        ...msUser,
                        ...tmpUser
                    }

                    return tmpUser;
                });
            });
        }
    }, [route.params.updateUser, route.params.needsReload, route.params.ts])

    const _getContent = useCallback(() => {
        let tableContent = [];

        if (currentUser.masterAdmin) {
            tableContent.push({
                footerElement: <View style={{
                    paddingHorizontal: 4,
                    marginVertical: 19
                }}>
                    <LxReactText style={{
                        color: globalStyles.colors.red,
                        ...globalStyles.textStyles.body.default
                    }}>
                        {`${_('user.mainuser.title')} ${_('user.mainuser.delete.message')}`}
                    </LxReactText>
                </View>
            })
        }


        if (Feature.USER_MANAGEMENT_REWORK && hasUserManagementPermissions) {
            // USERNAME TEXTBOX

            if (refs.current.isUserFromOtherTrustPeer) {
                tableContent.pushObject(_getTrustGeneralSection({
                    currentUser,
                    localProperties,
                    customFieldCaptions,
                    userPropOptions,
                    navigation,
                    isUserFromOtherTrustPeer: refs.current.isUserFromOtherTrustPeer,
                    trustMember: refs.current.trustMember,
                    trustMemberName: refs.current.trustMemberName,
                    users: refs.current.users,
                    allDataLoaded: refs.current.allDataLoaded,
                    updateCurrentUser: _updateCurrentUser,
                    /*onUsernameChanged: (name) => {
                        _updateCurrentUser(localProperties.name, name);
                    },
                    onUserIdChanged: (userId) => {
                        _updateCurrentUser(localProperties.userid, userId)
                    }*/
                }));
            }

            tableContent.pushObject(_getGeneralSection({
                    currentUser,
                    localProperties,
                    customFieldCaptions,
                    userPropOptions,
                    navigation,
                    isUserFromOtherTrustPeer: refs.current.isUserFromOtherTrustPeer,
                    trustMember: refs.current.trustMember,
                    trustMemberName: refs.current.trustMemberName,
                    users: refs.current.users,
                    allDataLoaded: refs.current.allDataLoaded,
                    updateCurrentUser: _updateCurrentUser,
                    /*onUsernameChanged: (name) => {
                        _updateCurrentUser(localProperties.name, name);
                    },
                    onUserIdChanged: (userId) => {
                        _updateCurrentUser(localProperties.userid, userId)
                    }*/
                })
            );


            if (Feature.USER_CAN_BE_DISABLED) {
                tableContent.pushObject(_getUserStateSection({
                    currentUser,
                    isLastActiveAdmin: refs.current.userIsLastPermanentActiveAdmin,
                    allDataLoaded: refs.current.allDataLoaded,
                    invalidUntilTime: refs.current.invalidUntilTime,
                    onUserStateChanged: (state) => {
                        let currentMsTime = ActiveMSComponent.getMiniserverTimeInfo().getSecondsSince2009(),
                            initialValues = [{
                            key: localProperties.userState,
                            value: state
                        }]

                        if (doesUserActivateLater(state)) {
                            initialValues.push({
                                key: localProperties.validFrom,
                                value: currentMsTime
                            })
                        }

                        if (canUserExpire(state)) {
                            initialValues.push({
                                key: localProperties.validUntil,
                                value: currentMsTime + 3600 // make the until time on hour later so the user doesn't get deativated immediately
                            });
                            if (Feature.USER_EXPIRATION_ACTION) {
                                initialValues.push({
                                    key: localProperties.expirationAction,
                                    value: UserManagement.EXPIRATION_ACTION.DEACTIVATE
                                })
                            }
                        }
                        _updateCurrentUser(null, null, initialValues);
                    },
                    onPickerChangedFn: (fromTime, untilTime, invalidUntilTime) => {
                        refs.current.invalidUntilTime = invalidUntilTime; // todo check
                        _updateCurrentUser(null, null, [
                            {
                                key: localProperties.validFrom,
                                value: fromTime.getSecondsSince2009()
                            },
                            {
                                key: localProperties.validUntil,
                                value: untilTime.getSecondsSince2009()
                            }
                        ])
                    },
                    onExpirationActionChanged: (action) => {
                        _updateCurrentUser(localProperties.expirationAction, action)
                    }
                }));
            } // ASSIGNED USER GROUPS FOR USER


            if (currentUser.usergroups) {
                // ADD USER TO USER GROUP
                tableContent.pushObject(_getUserGroupSection({
                    currentUser,
                    isMasterUser: refs.current.isMasterUser,
                    userIsLastPermanentAdmin: refs.current.userIsLastPermanentActiveAdmin,
                    trustPeers: refs.current.trustPeers,
                    trustMemberName: refs.current.trustMemberName,
                    allDataLoaded: refs.current.allDataLoaded,
                    navigation
                }));
            }
        }

        // ACTIONS
        tableContent.pushObject(_getSecuritySection({
            isUserFromOtherTrustPeer: refs.current.isUserFromOtherTrustPeer,
            trustMemberName: refs.current.trustMemberName,
            hasUserManagementPermissions: hasUserManagementPermissions,
            currentUser,
            originalUsername: originalUsername.current,
            disableChangePassSwitch: permissionRefs.current.disableChangePassSwitch,
            allDataLoaded: refs.current.allDataLoaded,
            _updateCurrentUser,
            navigation
        }));
        // NFC section can only be shown after all data was loaded, since isUserFromOtherTrustPeer is only set after all data was loaded
        if (hasUserManagementPermissions && refs.current.allDataLoaded && !refs.current.isUserFromOtherTrustPeer) {
            tableContent.pushObject(_getNfcSection({
                currentUser,
                isUserFromOtherTrustPeer: refs.current.isUserFromOtherTrustPeer,
                trustMemberName: refs.current.trustMemberName,
                navigation,
                allDataLoaded: refs.current.allDataLoaded,
                nfcCodeTouches: refs.current.sortedCodeTouches,
                allUsers: refs.current.users,

            }));
        }

        if (hasUserManagementPermissions && Feature.USER_MANAGEMENT_REWORK) {
            let lastSection = {
                headerTitle: NBR_SPACE,
                hideItemSeparator: true,
                rows: []
            };

            lastSection.rows.push({
                content: {
                    title: _("user.shared-data.action"),
                    titleStyle: {
                        color: globalStyles.colors.brand
                    }
                },
                action: () => {
                    _sendNewProperties().then(UserSharePDF.getForUser({
                        user: currentUser,
                        trustPeers: refs.current.trustPeers,
                        pwd: refs.current[ChangeSecretScreen.Type.UserPw],
                        visuPwd: refs.current[ChangeSecretScreen.Type.VisuPw],
                        accessCode: refs.current[ChangeSecretScreen.Type.AccessCode]
                    }))
                }
            })

            // DELETE USER
            if (!(currentUser.masterAdmin || refs.current.userIsLastPermanentActiveAdmin || refs.current.isUserFromOtherTrustPeer)) {
                lastSection.rows.push(_createDeleteUserCell({
                    currentUser,
                    navigation,
                    userIsLastPermanentActiveAdmin: refs.current.userIsLastPermanentActiveAdmin,
                    onDeleted: () => refs.current.needsReload = true
                }));
            }

            tableContent.push(lastSection);
        }

        return tableContent.filter(section => {
            return !!section;
        });
    }, [currentUser, customFieldCaptions, userPropOptions])

    const _updateCurrentUser = (key, value, updateArr) => {
        setCurrentUser(user => {
            let tmpUser = {
                ...user
            };

            if (updateArr) {
                updateArr.forEach(entry => {
                    tmpUser[entry.key] = entry.value;
                })
            } else {
                tmpUser[key] = value;
            }

            return tmpUser;
        });
    }

    const _handleNavigateBack = () => {
        if (!refs.current.allDataLoaded) {
            refs.current.changesSaved = true;
            let state = navigation.getState();

            if (state && state.routes) {
                let prevRoute = state.routes[state.routes.length - 2];
                navigation.navigate(prevRoute.name, {
                    needsReload: false
                });
            }
        } else if (refs.current.homeKitDefer) {
            refs.current.changesSaved = true;
            refs.current.homeKitDefer.reject();
            navigation.goBack();
        } else {
            if (permissionRefs.current.isAdmin && currentUser.scorePWD === UserManagement.EMPTY_PWD || refs.current.invalidUntilTime) {
                if (!refs.current.invalidUntilTime) {
                    _showPasswordRequiredPopup();
                }

            } else {
                _sendNewProperties().then(() => {
                    let state = navigation.getState();

                    if (state && state.routes) {
                        refs.current.changesSaved = true;
                        let prevRoute = state.routes[state.routes.length - 2];
                        navigation.navigate(prevRoute.name, {
                            needsReload: refs.current.needsReload || refs.current.isNewUser,
                            user: currentUser
                        });
                    }
                })
            }
        }
    }

    const _sendNewProperties = () => {
        var userChanges,
            prms = [true]; // we only send the properties what have changed

        if (Feature.USER_MANAGEMENT_REWORK) {
            userChanges = _getChangedUser({
                localProperties: Object.values(localProperties),
                currentUser,
                originUser: refs.current.originUser
            });

            refs.current.needsReload = !!userChanges || refs.current.needsReload; // userChanges === false, if we don't have any changes

            if (userChanges) {
                userChanges.uuid = currentUser.uuid;

                if (isCurrentUser && _isLogOffInfoNeeded()) {
                    prms.push(NavigationComp.showPopup({
                        title: _('user.changed.title'),
                        message: _('user.changed.message'),
                        buttonOk: _('ok.short'),
                        buttonCancel: false,
                        icon: Icon.CAUTION,
                        color: globalStyles.colors.orange
                    }, PopupType.GENERAL).then(function () {
                        return ActiveMSComponent.addOrEditUser(userChanges);
                    }.bind(this)));
                } else {
                    prms.push(ActiveMSComponent.addOrEditUser(userChanges));
                }
            }
        } // Use finally to also close the screen even if the user declines a potential permission authentication


        return Q.all(prms);
    }

    const _isLogOffInfoNeeded = () => {
        let userLogOff = false;

        if (refs.current.originUser.name !== currentUser.name) {
            userLogOff = true;
        }

        if (currentUser.userState === UserManagement.USER_STATES.DISABLED) {
            userLogOff = true;
        }

        if (_getChangedUserGroups(currentUser.usergroups, refs.current.originUser.usergroups, true)) {
            userLogOff = true;
        }

        return userLogOff;
    }

    const _showPasswordRequiredPopup = () => {
        if (!refs.current.isUserFromOtherTrustPeer) {
            return NavigationComp.showPopup({
                title: _('user.pass-required.title'),
                message: _('user.pass-required.message'),
                buttonOk: _('user.addpass'),
                buttonCancel: true,
                color: globalStyles.colors.stateActive,
                icon: Icon.INFO
            }, PopupType.GENERAL).then(() => {
                navigation.navigate(ScreenState.ChangeSecretScreen, {
                    user: currentUser,
                    title: _('user.addpass'),
                    type: "userPw"
                });
            });
        }
    }

    const _loadInitialInformation = (isInitial) => {
        let getUserPromise = _getUser(),
            getUsersPromise = true,
            getTrustPeersPromise = true;

        if (Feature.USER_MANAGEMENT_REWORK && hasUserManagementPermissions) {
            // Needed to validate the username, if the name is already in use
            getUsersPromise = ActiveMSComponent.getUsers(true).then((users) => {
                _updateUsersInRefs(users);
                refs.current.userIsLastPermanentActiveAdmin = _checkIfOnlyOneAdminIsRemaining(users, currentUser);
            });
        }

        if (Feature.TRUST && !refs.current.trustPeers) {
            getTrustPeersPromise = ActiveMSComponent.getTrustPeers().then((response) => {
                let thisSerial = ActiveMSComponent.getActiveMiniserver().serialNo;

                refs.current.trustPeers = response.peers.filter(function (peers) {
                    return peers.serial !== thisSerial;
                });
            });
        }

        Q.all([getUserPromise, getUsersPromise, getTrustPeersPromise]).then(([msUser]) => {
            _sortCodeTouches();
            permissionRefs.current = _checkPermissions(true, msUser);
            refs.current.trustMember = msUser.trustMember;
            refs.current.isUserFromOtherTrustPeer = !!refs.current.trustMember;
            refs.current.allDataLoaded = true;

            if (refs.current.trustMember) {
                refs.current.trustMemberName = _getTrustPeerName(refs.current.trustMember);
            }

            if (isInitial) {
                refs.current.originUser = cloneObject(msUser);
            }

            // replace the usergroups from the user with the usergroups from the miniserver to get all the informations needed
            msUser.usergroups = msUser.usergroups.map(group => {
                return refs.current.usergroups.find(msUserGroup => {
                    return msUserGroup.uuid === group.uuid;
                })
            })

            // IMPORTANT --> do after the ref is set
            setCurrentUser(ogUser => {
                let tmpUser;

                tmpUser = {
                    ...msUser,
                    ...ogUser
                }

                return tmpUser;
            });
        });
    }

    const _getUser = () => {
        let msUser;

        if (Feature.USER_MANAGEMENT_REWORK) {
            return ActiveMSComponent.getUser(userUuid).then((user) => {
                let oldUser = cloneObject(currentUser);

                msUser = JSON.parse(user.LL.value);

                // Why do we get the groupList?
                // The miniserver is not sending us all the required information!
                return ActiveMSComponent.getGroupList().then(userGroups => {
                    refs.current.usergroups = _getChangedUserGroups(oldUser.usergroups, userGroups, false, true);
                    Object.values(localProperties).forEach((key) => {
                        if (oldUser.hasOwnProperty(key)) {
                            msUser[key] = oldUser[key];
                        }
                    });

                    return msUser;
                })
            });
        } else if (Feature.ADD_NFC_TAG_OVER_APP) {
            return ActiveMSComponent.getUsers().then((users) => {
                _updateUsersInRefs(users);

                msUser = users.find((user) => {
                    return user.uuid === userUuid;
                });
                setCurrentUser(msUser);
            });
        }
    }

    const _getTrustPeerName = (peerSerial) => {
        return (refs.current.trustPeers.find(function (peer) {
            return peer.serial === peerSerial;
        }) || {}).name || peerSerial;
    }

    const _sortCodeTouches = () => {
        let allCodeTouches = ActiveMSComponent.getStructureManager().getControlsByType(ControlType.NFC_CODE_TOUCH),
            readyToLearnTag = [],
            offline = [],
            dummy = [],
            canNotReadNfc = [],
            sortedCodeTouches = {},
            deviceState = null;

        allCodeTouches.forEach(function (codeTouch, idx) {
            deviceState = codeTouch.deviceState();

            if (deviceState === NfcCodeTouchControlEnums.DeviceStates.READY_TO_LEARN_TAG) {
                readyToLearnTag.push(codeTouch);
            } else if (deviceState === NfcCodeTouchControlEnums.DeviceStates.DUMMY_AIR || deviceState === NfcCodeTouchControlEnums.DeviceStates.DUMMY_TREE) {
                dummy.push(codeTouch);
            } else if (deviceState === NfcCodeTouchControlEnums.DeviceStates.CAN_NOT_READ_TAG) {
                canNotReadNfc.push(codeTouch);
            } else {
                offline.push(codeTouch);
            }
        });
        sortedCodeTouches[NfcCodeTouchControlEnums.CodeTouchSortKey.READY_TO_LEARN_TAG] = readyToLearnTag;
        sortedCodeTouches[NfcCodeTouchControlEnums.CodeTouchSortKey.OFFLINE] = offline;
        sortedCodeTouches[NfcCodeTouchControlEnums.CodeTouchSortKey.DUMMY] = dummy;
        sortedCodeTouches[NfcCodeTouchControlEnums.CodeTouchSortKey.CAN_NOT_READ_TAG] = canNotReadNfc;

        refs.current.sortedCodeTouches = sortedCodeTouches;
    }

    const _updateUsersInRefs = (users) => {
        refs.current = {
            ...refs.current,
            users
        }
    }

    return (
        <>
            <LxReactTableView
                tableContent={_getContent()}
                showSectionSeparator={true}
                showItemSeparatorOnFirstItem={true}
                insets={["bottom"]}
            />
        </>
    )
}

UserDetailsScreen.propTypes = {
    userUuid: PropTypes.string,
    user: PropTypes.object,
    users: PropTypes.arrayOf(PropTypes.object),
    displayCurrentUser: PropTypes.bool,
    isNewUser: PropTypes.bool,
    homeKitDefer: PropTypes.func,
    trustPeers: PropTypes.arrayOf(PropTypes.object)
}

export default UserDetailsScreen;
