import * as React from "react"
import Svg, { Path } from "react-native-svg"

function KeyholeCircled(props) {
    return (
        <Svg isreact="true"
            height={24}
            viewBox="0 0 24 24"
            width={24}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm0 4a4 4 0 014 4 3.993 3.993 0 01-1.899 3.4l-.032.017.925 6.007a.5.5 0 01-.404.568L14.5 19h-5a.5.5 0 01-.494-.576l.923-6.006-.222-.144A3.992 3.992 0 018.005 9.21L8 9c0-2.21 1.79-4 4-4zm0 1a2.999 2.999 0 00-1.305 5.698.5.5 0 01.276.526L10.083 18h3.833l-.888-5.776a.5.5 0 01.197-.478l.08-.048A3 3 0 0012 6z"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default KeyholeCircled
