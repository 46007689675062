import * as React from "react"
import Svg, { Path } from "react-native-svg"

function HeartWithBeat(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M1.197 9.095A6.5 6.5 0 0112 2.81a6.5 6.5 0 0110.953 5.477c-.052.56-.155 1.133-.304 1.714h-6.718l-.646-1.614a3 3 0 00-5.226-.63l-.49.687A3 3 0 005.6 9.2L5 10H1.434c-.094-.308-.173-.61-.237-.905zM17.243 16h2.588c-2.576 3.887-6.067 7-7.831 7-1.7 0-5.005-3.214-7.548-7H6.5a3 3 0 001.75-.563 3 3 0 003.417.057l.048.12a3 3 0 004.906 1.007l.622-.621z"
            />
            <Path
                d="M13.428 9.129a1 1 0 00-1.742-.21l-1.814 2.54-1.165-1.166A1 1 0 007.2 10.4L6 12H1a1 1 0 100 2h5.5a1 1 0 00.8-.4l.808-1.078 1.185 1.185a1 1 0 001.52-.126l1.438-2.012 1.32 3.302a1 1 0 001.636.336L16.414 14H23a1 1 0 100-2h-7a1 1 0 00-.707.293l-.428.428-1.437-3.592z"
            />
        </Svg>
    )
}

export default HeartWithBeat
