'use strict';

define("HeatmixerControl", ["Control"], function (Control) {
    return class HeatmixerControl extends Control {
        //region Private
        VALUE_FORMAT = "%.1f°"; //endregion Private

        constructor() {
            super(...arguments);
        }

        getIcon() {
            return Icon.Heatmixer.HEATMIXER;
        }

    };
});
