'use strict';

import {
    useDeviceOrientation,
    LxReactScreenAdapter
} from "LxComponents";

window.GUI = function (GUI) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var Settings = {
            FADE: {
                DURATION: 150
            },
            MODAL: {
                DURATION: 250,
                EASING: "ease-out-expo"
            },
            PUSH_UP_DOWN: {
                DURATION: 250,
                EASING: "ease-out-expo"
            },
            PUSH_LEFT_RIGHT: {
                DURATION: 250,
                EASING: "ease-out-expo"
            },
            HD_DARKENER: {
                DURATION: 300,
                EASING: "ease-out-expo"
            },
            HD_MODAL: {
                DURATION: 300,
                EASING: "ease-out-expo"
            },
            HD_OVERLAY: {
                DURATION: 300,
                EASING: "ease-out-expo"
            },
            HD_OVERLAY_FULLSCREEN: {
                DURATION: 500,
                EASING: "ease-out-expo"
            }
        };
        var DefaultSettings = cloneObjectDeep(Settings);

        class ViewController extends GUI.Screen {

            //static RouteNames = [];
            //region Getter

            /**
             * When quering a viewController if an exchange is in progress, it must also keep in mind that it's current
             * view may also be a viewController which also may be currently have an active exchange in progress.
             * When the sub-viewcontroller is done, it'll inform it's parent viewController via processQueue.
             * @returns {boolean}
             */
            get exchangeInProgress() {
                if (this._exchangeInProgress) {
                    return true;
                } else if (this.currentView && this.currentView instanceof GUI.ViewController) {
                    // also mind a currently shown viewController that may be exchanging
                    return this.currentView.exchangeInProgress;
                } else {
                    return false;
                }
            } //endregion Getter


            //region Setter
            set exchangeInProgress(isInProgress) {
                this._exchangeInProgress = isInProgress;
            } //endregion Setter

            constructor(view) {
                super(view || $('<div class="full-screen" />'));
                this.details = {}; // array with viewIDs which are allowed to show multiple times

                this.multipleViews = [];
                this.queue = [];
                this._queueDef = Q.defer();
                this.viewStack = new ViewStack(); // is only a "factory", not in correct order!

                this.history = new History(); //this.exchangeInProgress = true; // set to true, to wait until viewDidAppear

                this.wrapperViewFactory = new Factory(function newInstance() {
                    var wrapper = $('<wrapper-view/>');
                    this.getFactoryClasses().forEach(function (className) {
                        wrapper.addClass(className);
                    });
                    return wrapper;
                }.bind(this), function recycle(instance) {
                    var factoryClasses = instance.attr("factory-class");
                    instance.empty();
                    instance.removeAllAttributes();
                    this.getFactoryClasses().forEach(function (className) {
                        instance.addClass(className);
                    });

                    if (factoryClasses) {
                        factoryClasses.split(" ").forEach(function (className) {
                            instance.addClass(className);
                        });
                    }

                    return instance;
                }.bind(this)); // darkened views

                this._darkenedViews = [];
            }

            getFactoryClasses() {
                return ["full-screen"];
            }

            // overwritten methods of View
            updateView(details) {
                Debug.GUI.ViewController && console.log(this.name, "updateView"); // forward to currentView

                if (this.currentView && details) {
                    var result = this.currentView.updateView(details);

                    if (!Q.isPromiseAlike(result)) {
                        console.warn(this.viewId, "updateView - the currentView " + this.currentView.viewId + " doesn't return a promise!");
                        result = Q.resolve(result);
                    }

                    return result.then(null, function (err) {
                        this.__printViewControllerError("updateView failed!", err, details);

                        return Q.reject(err);
                    }.bind(this));
                }
            }

            supportedOrientation() {
                if (this.currentView) {
                    return this.currentView.supportedOrientation();
                } else {
                    return super.supportedOrientation();
                }
            }

            viewDidLoad() {
                Debug.GUI.ViewController && console.log(this.name, "viewDidLoad");

                this._trackOperation("viewDidLoad");

                var all = [true, super.viewDidLoad() || true]; // don't forward anything to subview!
                // now start with queue!

                all.push(this.processQueue());
                this.applyDragRegion();
                return this.__operationWatchDog("viewDidLoad-QAll", Q.all(all));
            }

            applyDragRegion() {
                // Add a virtual titlebar to allow the window to be dragged around
                if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.Mac) {
                    this.element.prepend("<div style='height: 20px; width: 100%; position: absolute; z-index: 800; -webkit-app-region: drag;'/>");
                }
            }

            viewWillAppear() {
                Debug.GUI.ViewController && console.log(this.name, "viewWillAppear");

                this._trackOperation("viewWillAppear");

                var all = [true, super.viewWillAppear(...arguments) || true];

                if (this.currentView) {
                    try {
                        Debug.GUI.ViewController && console.log(this.name, "   passing on viewWillAppear to currentView: " + this.currentView.viewId);
                        all.push(this.currentView.viewWillAppear() || true);
                    } catch (e) {
                        this.__printViewControllerError("Exception raised while passing on viewWillAppear to currentView " + this.currentView.viewId, e.message, e.stack);
                    }
                } else {
                    Debug.GUI.ViewController && console.log(this.name, "   no currentView to pass viewWillAppear on to", this.viewStack);
                }

                return this.__operationWatchDog("viewWillAppear-QAll", Q.all(all));
            }

            viewDidAppear() {
                this._trackOperation("viewDidAppear");

                Debug.GUI.ViewController && console.log(this.name, "viewDidAppear");
                var all = [true, super.viewDidAppear(...arguments) || true];

                if (this.currentView) {
                    try {
                        Debug.GUI.ViewController && console.log(this.name, "   passing on viewDidAppear to currentView: " + this.currentView.viewId);
                        all.push(this.currentView.viewDidAppear() || true);
                    } catch (e) {
                        this.__printViewControllerError("Exception raised while passing on viewDidAppear to currentView " + this.currentView.viewId, e.message, e.stack);
                    }
                } else {
                    Debug.GUI.ViewController && console.log(this.name, "   no currentView to pass viewDidAppear on to", this.viewStack);
                }

                if (HD_APP) {
                    this._darkenedViews.forEach(function (darkenedView) {
                        this._registerDarkenerOnClick(darkenedView.darkener, darkenedView.onClick);
                    }.bind(this));
                }

                if (!this.exchangeInProgress) {
                    all.push(this.processQueue());
                }

                return this.__operationWatchDog("viewDidAppear-QAll", Q.all(all));
            }

            viewWillDisappear() {
                if (this.exchangeInProgress) {
                    // ensure that any destructive operation waits for the exchange in progress.
                    // happens e.g. when navigating into a control content and before it's done, the connecting screen appears.
                    // in this situation viewWillDisappear would be called twice on the view that is currently disappearing
                    // due to to the exchange.
                    return this._addToQueue("viewWillDisappear", arguments);
                }

                this._trackOperation("viewWillDisappear");

                Debug.GUI.ViewController && console.log(this.name, "viewWillDisappear");
                var promise;

                if (this.currentView) {
                    try {
                        promise = this.currentView.viewWillDisappear();

                        if (!Q.isPromiseAlike(promise)) {
                            console.error(this.viewId, "viewWillDisappear - the currentView " + this.currentView.viewId + " doesn't return a promise!");
                            promise = Q.resolve();
                        }
                    } catch (e) {
                        this.__printViewControllerError("Exception raised while passing on viewWillDisappear to currentView " + this.currentView.viewId, e.message, e.stack);

                        promise = Q.reject(e);
                    }
                } else {
                    promise = Q.resolve();
                } // set to true to be sure to continue/start with navigations after viewDidAppear
                // (navigations are forbidden while view isn't visible!)
                //this.exchangeInProgress = true;


                this.__operationWatchDog("viewWillDisappear-currentView-viewWillDisappear", promise);

                return this.__operationWatchDog("viewWillDisappear-QAll", Q.allSettled([super.viewWillDisappear(...arguments), promise]));
            }

            viewDidDisappear(viewRemainsVisible) {
                this._trackOperation("viewDidDisappear");

                Debug.GUI.ViewController && console.log(this.name, "viewDidDisappear");
                var promise;

                if (this.currentView) {
                    try {
                        promise = this.currentView.viewDidDisappear(viewRemainsVisible);

                        if (!Q.isPromiseAlike(promise)) {
                            console.error(this.viewId, "viewDidAppear - the currentView " + this.currentView.viewId + " doesn't return a promise!");
                            promise = Q.resolve();
                        }
                    } catch (e) {
                        this.__printViewControllerError("Exception raised while passing on viewDidDisappear to currentView " + this.currentView.viewId, e.message, e.stack);

                        promise = Q.reject(e);
                    }
                } else {
                    promise = Q.resolve();
                }

                if (HD_APP) {
                    this._darkenedViews.forEach(function (darkenedView) {
                        this._unregisterDarkenerOnClick(darkenedView.darkener);

                        this._unregisterDarkenerOnSwipe(darkenedView.darkener);
                    }.bind(this));
                }

                this.__operationWatchDog("viewDidDisappear-currentView-viewDidDisappear", promise);

                return this.__operationWatchDog("viewDidDisappear-QAll", Q.allSettled([super.viewDidDisappear(...arguments), promise]));
            }

            destroyOnBackNavigation() {
                return false;
            }

            hasHistory() {
                return true;
            }

            updateURL() {
                NavigationComp.updateURL();
            }

            getURL() {
                return this.currentView ? this.currentView.getURL() : "";
            }

            handleStatusBarTap() {
                if (this.currentView) {
                    this.currentView.handleStatusBarTap();
                }
            }

            destroy() {
                this._trackOperation("destroy");

                Debug.GUI.ViewController && console.log(this.name, "destroy ✝"); // clear history, everything will be destroyed. Destroying the views may call viewCtrl methods like in
                // the audio zone, where the a navigateBackToRoot is called where having a history will result in erros.

                while (this.history.current()) {
                    this.history.remove(this.history.current());
                }

                while (this.viewStack.length > 0) {
                    lxRequestAnimationFrame(this._destroyView.bind(this, this.viewStack.pop()));
                }

                if (this.currentView) {
                    try {
                        this.currentView.destroy();
                    } catch (e) {
                        this.__printViewControllerError("Exception raised in destroy of viewCtrl while while destroying currentView " + this.currentView.viewId, e.message, e.stack);
                    }

                    this.currentView = null;
                }

                this.currentWrapperView = null;
                this.currentViewIdentifier = null;
                super.destroy();
            }

            // ViewController specific methods

            /**
             * The screen passed should no longer be visible. This can be achieved by two means:
             *  - it is the currently visible one - just navigate back.
             *  - it is somewhere in the stack - remove from the history/stack
             *  If the screen passed is a view controller, all subviews are going to disappear too - skipSubview will be true.
             * @param screen  the screen that should be dismissed
             * @returns {Q.Promise<unknown>}
             */
            dismissScreen(screen) {
                if (this.exchangeInProgress) {
                    Debug.GUI.ViewController && console.log(this.viewId, "dismissScreen: " + screen.viewId + ", exchange in progress, enqueue!");
                    return this._addToQueue("dismissScreen", arguments);
                } else {
                    var currView = this.getCurrentView(),
                        prms;

                    if (screen === currView) {
                        Debug.GUI.ViewController && console.log(this.viewId, "dismissScreen: " + screen.viewId + ", current view, nav back!");
                        prms = this.navigateBack(true);
                    } else {
                        // look for the viewController that requested navigate back in the viewStack.
                        var foundViewId = this._getIdForView(screen);

                        if (foundViewId) {
                            Debug.GUI.ViewController && console.info(this.viewId, "dismissScreen: " + screen.viewId + ", isn't the current one, remove from history!"); // ensure the wrapper is gone too.

                            var dismissedWrapper = screen.getElement().parent();
                            dismissedWrapper && dismissedWrapper.remove(); // cleanup history & stack

                            this.viewStack.remove(foundViewId);
                            this.history.remove(foundViewId);

                            this._destroyView(screen);

                            prms = Q.resolve();
                        } else {
                            // the view that requested to be dismissed using navigateBackFrom is no longer the currentView!
                            this.__printViewControllerError("dismissScreen was requested by a screen that is neither the currentView and nor handled by this viewController!");

                            prms = Q.reject();
                        } // ensure other potentially enqueued methods are processed afterwards.


                        prms.finally(function () {
                            this.processQueue();
                        }.bind(this));
                    }

                    return prms;
                }
            }

            /**
             * @see this._showState
             */
            showState(identifier) {
                this._trackOperation("showState", identifier); // only forward to private method, needed because of overwriting "showState" and queue!


                return this.__operationWatchDog("showState", this._showState.apply(this, arguments), identifier);
            }

            approveAnimationType(animationType) {
                if (this._inAnimation === AnimationType.MODAL || this.ViewController instanceof GUI.ModalViewController) {
                    // only a few animations work in modal!
                    if (animationType !== AnimationType.NONE && animationType !== AnimationType.FADE && animationType !== AnimationType.PUSH_OVERLAP_LEFT) {
                        animationType = AnimationType.PUSH_OVERLAP_LEFT;
                    }
                }

                return animationType;
            }

            /**
             * In ModalScreens or screens with their own viewControllers, it is crucial that those viewControllers have
             * a dummy screen in their viewStack/history. Without such a dummy, a screen change within those VCTRLs
             * would navigate back - then it'd discover that there are no views left and dismiss the viewCtrl itself.
             * With such a dummy screen, theres always 1 layer left. E.g. open up an audioZone and navigate from spotify
             * to the radio-stations or alike.
             * @private
             */
            _handleAddInitialDummy() {
                Debug.GUI.ViewController && console.log(this.name, "_handleAddInitialDummy"); // Earlier this was solved by base.showState.call(this, "GUI.Screen", null, $("<div>"), AnimationType.NONE));
                // this solution did cause troubles with the performance optimized handling, as the screen would have been
                // dismissed by the initialScreen before the viewDidAppear is called on the dummy.

                return Q(LxReactScreenAdapter.ScreenUtils.getScreen("GUI.Screen", null)).then(function (loaded) {
                    loaded.viewDidLoad();
                    this.history.add("GUI.Screen");
                    this.viewStack.add("GUI.Screen", loaded);
                }.bind(this), function _handleAddInitialDummy_loadViewAsyncErr(err) {
                    this.__printViewControllerError("_handleAddInitialDummy - loadViewAsync failed", err);

                    return Q.reject(err);
                }.bind(this));
            }

            /**
             * @param identifier
             * @param view
             * @param details
             * @param animationType
             * @returns {*} Promise
             */
            _showState(identifier, view, details, animationType) {
                Debug.GUI.ViewController && console.log(this.name, "_showState: " + identifier);

                if (this.exchangeInProgress || !this._viewDidLoadPassed) {
                    // only add to queue, and return other promise
                    //Debug.GUI.ViewController &&
                    console.log(this.name, "   enqueueing showState of " + identifier);
                    return this._addToQueue("_showState", arguments);
                }

                SandboxComponent.activityTick()
                var defer = Q.defer();
                this.exchangeInProgress = true;
                var newView = null,
                    newWrapperView = null,
                    previousView = this.currentView,
                    previousWrapperView = this.currentWrapperView; // check, if view is shown atm

                if (!this._multipleViewAllowed(identifier) && (identifier === this.currentViewIdentifier || view != null && view === this.currentView) && (!this.currentView || this.currentView.shouldBeUpdated())) {
                    // only update
                    var res = true;

                    if (details != null) {
                        try {
                            res = this.currentView.updateView(details);

                            if (!Q.isPromiseAlike(res)) {
                                console.error(this.viewId, "updateView of " + this.currentView.viewId + " failed to return a promise!");
                                res = Q.resolve(res);
                            }
                        } catch (ex) {
                            this.__printViewControllerError("updateView in showState failed for " + identifier, ex.message, ex.stack);

                            res = Q.reject();
                        }
                    }

                    newView = this.currentView;
                    newWrapperView = this.currentWrapperView;
                    Q.allSettled([res]).done(function () {
                        defer.resolve(true); // explicitly resolve with true!
                    });
                } else {
                    newWrapperView = this.wrapperViewFactory.getInstance();

                    this._getViewForAnimation(view, identifier, details, animationType).done(function (res) {
                        newView = res[0];
                        animationType = res[1]; // use this animationType (its from the actual screen!)

                        this._startViewTransition(defer, identifier, newView, newWrapperView, previousView, previousWrapperView, animationType);
                    }.bind(this), function (e) {
                        // something went wrong..
                        defer.reject(e); // only show a popup if an error has been returned. e.g. when loading securedDetails, opening
                        // the view might be rejected as no details could be loaded.

                        if (e && e !== GUI.PopupBase.ButtonType.CANCEL) {
                            // don't show an error if a popup has been cancelled (e.g. asking for expert mode permission)
                            console.error(e.stack);
                            if (window.UPDATE_LEVEL !== UpdateComp.UpdateLevel.RELEASE) {
                                NavigationComp.showErrorPopup(false, null, e.message || _('error.view-cant-be-loaded'));
                            }
                        }
                    }.bind(this));
                }

                return defer.promise.then(function success(isPreviousViewVisible) {
                    // be careful here, because of fast showState calls - don't use <this> references!
                    // instead create closure vars and use them!
                    this._handleShowStatePassed(isPreviousViewVisible, newView, newWrapperView, identifier);

                    return isPreviousViewVisible;
                }.bind(this), function error(e) {
                    this.__printViewControllerError("_showState of " + identifier + " failed", e);

                    this.exchangeInProgress = false;
                    this.processQueue();
                    throw e;
                }.bind(this));
            }

            _getAnimationFromView(view, animationType) {
                return this.approveAnimationType(animationType || view.getAnimation && view.getAnimation() || AnimationType.NONE);
            }

            /**
             * loads the screen (if modal, also loads the ModalVC)
             * @param view
             * @param identifier
             * @param details
             * @param animationType
             * @returns {Promise} is resolved with array -> [view, animationType]
             * @private
             */
            _getViewForAnimation(view, identifier, details, animationType) {
                var pastView, newView; // check if view was shown in past

                if (!this._multipleViewAllowed(identifier)) {
                    pastView = this.viewStack.viewForID(identifier, true);
                }

                if (pastView && pastView.isReusable && !pastView.isReusable()) {
                    pastView = null;
                }

                if (!pastView && !view) {
                    return this._getViewForIdentifier(identifier, details).then(function gotViewForAnimation(createdView) {
                        animationType = this._getAnimationFromView(createdView, animationType);
                        createdView._intendedInAnimation = animationType; // set the correct (intended!) animation type for the view!
                        // handle modal views

                        if (animationType === AnimationType.MODAL || animationType === AnimationType.HD_MODAL_FULLSCREEN) {
                            return this._getViewForIdentifier("GUI.ModalViewController").then(function (modalViewController) {
                                modalViewController.setViewController(this);
                                createdView.setViewController(modalViewController);
                                return this._handleViewDidLoad(createdView).then(function () {
                                    return this._handleViewDidLoad(modalViewController).then(function () {
                                        // don't provide details, because its not wanted to be initialized with the details and update immediately again
                                        // make sure that the showState is finished, before animating the createdView! (otherwise it's undefined which one will be animated first - problems with view lifecycle mehtods would occur!)
                                        return modalViewController.showState(identifier, createdView, null, AnimationType.NONE).then(function () {
                                            return [modalViewController, animationType]; // now return the new modalViewController
                                        });
                                    }.bind(this));
                                }.bind(this));
                            }.bind(this));
                        }

                        createdView.setViewController(this);
                        return this._handleViewDidLoad(createdView).then(function (view) {
                            return [view, animationType];
                        });
                    }.bind(this), function _getViewForAnimation_getViewForIdentifierErr(err) {
                        this.__printViewControllerError("_getViewForAnimation_getViewForIdentifierErr " + identifier + " failed", err);

                        return Q.reject(err);
                    }.bind(this));
                }

                var all = [true];

                if (pastView) {
                    // we have a view with this id in stack
                    newView = pastView;

                    if (details != null) {
                        all.push(newView.updateView(details) || true);
                    }
                } else if (view) {
                    // we have a given view, show this one!
                    newView = view;
                    newView.setViewController(this); // set the ViewController to be always sure we have set it! (if view is provided via parameter)

                    if (!newView._viewDidLoadPassed) {
                        all.push(this._handleViewDidLoad(newView));
                    }

                    if (details != null) {
                        all.push(newView.updateView(details) || true);
                    }
                }

                return Q.all(all).then(function () {
                    return [newView, this._getAnimationFromView(newView, animationType)];
                }.bind(this), function _getViewForAnimationQAllFail(err) {
                    this.__printViewControllerError("_getViewForAnimation " + identifier + " failed", err);

                    return Q.reject(err);
                }.bind(this));
            }

            /**
             * calls viewDidLoad and returns the view when resolved
             * @param view
             * @returns {*}
             * @private
             */
            _handleViewDidLoad(view) {
                Debug.GUI.Timings && console.time("# " + this.name + " " + view.name + "::viewDidLoad");
                var vdl = view.viewDidLoad() || false;

                if (vdl) {
                    return NavigationComp.showWaitingFor(vdl, view.getWaitingForTitle(), view.getWaitingForMessage(), view.isWaitingForCancelable(), view.getWaitingForRetryFunction.bind(view), view.getWaitingForRetryMessage()).then(function () {
                        Debug.GUI.Timings && console.timeEnd("# " + this.name + " " + view.name + "::viewDidLoad");
                        return view;
                    }.bind(this));
                } else {
                    Debug.GUI.Timings && console.timeEnd("# " + this.name + " " + view.name + "::viewDidLoad");
                    return Q.when(view);
                }
            }

            _startViewTransition(defer, identifier, newView, newWrapperView, previousView, previousWrapperView, animationType) {
                newWrapperView.attr("view", identifier);

                this._handleDeviceOrientation(newView, previousView);

                GUI.animationHandler.append(newView.getElement(), newWrapperView).then(function _startViewTransitionNewViewAppended() {
                    newView._inAnimation = animationType;

                    if (newView.hasHistory()) {
                        this.history.add(identifier);
                    }

                    defer.resolve(this._exchangeViews(newView, newWrapperView, previousView, previousWrapperView, animationType));
                }.bind(this));
            }

            _handleShowStatePassed(isPreviousViewVisible, newView, newWrapperView, identifier) {
                Debug.GUI.ViewController && console.log(this.name, "_handleShowStatePassed - newView: " + newView.viewId); // put "old" view into stack
                // check if newView is set - otherwise we only updated the current view!

                if (newView && this.currentViewIdentifier && this.currentView && newView !== this.currentView) {
                    if (isPreviousViewVisible || this.currentView.isReusable && this.currentView.isReusable() || this.currentView.hasHistory && this.currentView.hasHistory()) {
                        this.viewStack.add(this.currentViewIdentifier, this.currentView);
                    } else {
                        Debug.GUI.ViewController && console.log("destroying view: " + this.currentViewIdentifier);

                        if (this.currentView.hasHistory()) {
                            this.history.remove(this.currentViewIdentifier);
                        }

                        try {
                            this.currentView.destroy();
                        } catch (e) {
                            this.__printViewControllerError("Exception raised during destroy of currentView " + this.currentView.viewId, e.message, e.stack);
                        }
                    }
                }

                this.currentViewIdentifier = identifier;

                if (newView) {
                    this.currentView = newView;
                }

                if (newWrapperView) {
                    if (!isPreviousViewVisible) {
                        this.currentWrapperView && this.wrapperViewFactory.returnInstance(this.currentWrapperView); // recycle old
                    }

                    this.currentWrapperView = newWrapperView;
                }

                Debug.GUI.ViewController && setTimeout(this.status.bind(this), 50);
                this.exchangeInProgress = false;
                this.processQueue();
                Debug.GUI.ViewController && console.log(this.name, "updateURL");
                this.updateURL()
                return isPreviousViewVisible;
            }

            /**
             * whether the view or the subView can navigate back
             * @param skipSubView whether to call canNavigateBack on subView(Controller) - used eg. at RootViewController!
             * @returns {boolean}
             */
            canNavigateBack(skipSubView) {
                var iCanNavigateBack = this.history.length > 1 || this.history.length === 1 && (!this.currentView || !this.currentView.hasHistory());
                var currentViewCanNavigateBack = false;

                if (this.currentView && this.currentView.canNavigateBack) {
                    currentViewCanNavigateBack = this.currentView.canNavigateBack();
                }

                Debug.GUI.ViewController && console.log(this.name, "canNavigateBack - skipSubView: " + skipSubView + " = " + (iCanNavigateBack || currentViewCanNavigateBack));
                return iCanNavigateBack || currentViewCanNavigateBack;
            }

            /**
             * navigates back
             * @param [skipSubView] whether to call navigateBack on subView(Controller) - used eg. at RootViewController!
             * @param [detailsForPrevView] provide some details for the previous view eg. TextEditView gives new text back to previous view!
             * @returns {Promise}
             */
            navigateBack(skipSubView, detailsForPrevView) {
                this._trackOperation("navigateBack", {
                    skipSubview: skipSubView,
                    detailsForPrevView: detailsForPrevView
                }); // only forward to private method, needed because of overwriting "navigateBack" and queue!


                return this.__operationWatchDog("navigateBack", this._navigateBack.apply(this, arguments), {
                    skipSubview: skipSubView,
                    detailsForPrevView: detailsForPrevView
                });
            }

            /**
             * Dismisses the whole ViewController
             */
            dismiss(details) {
                this._trackOperation("dismiss"); // We can't navigate back if there is no underlying ViewController to navigate to


                if (!this.ViewController) {
                    Debug.GUI.ViewController && console.warn("ViewController can't be dismissed because it doesn't have a ViewController.");

                    this.__printViewControllerError("ViewController can't be dismissed because it doesn't have a ViewController.");

                    return;
                } // Dismisses the current ViewController
                // Why the true? It'll call "navigateBack" on all its screens in the ViewStack


                return this.__operationWatchDog("dismiss", this.ViewController.navigateBack(true, details));
            }

            /**
             * A child view controller explicitly requests to be removed using navigate. But as the might be put into the
             * queue and then, when processed another screen/viewController may already be visible. This would lead to
             * an invalid view being dismissed using navigate back.
             * @param viewCtrl  the child view controller that requested a navigate back so it's dismissed.
             * @param skipSubView
             * @param detailsForPrevView
             * @private
             */
            navigateBackFromViewCtrl(viewCtrl, skipSubView, detailsForPrevView) {
                this._trackOperation("navigateBackFromViewCtrl: " + viewCtrl.viewId, {
                    skipSubview: skipSubView,
                    detailsForPrevView: detailsForPrevView
                });

                if (this.exchangeInProgress) {
                    console.log(this.viewId, "navigateBackFromViewCtrl - a view exchange is in progress, enqueue");
                    return this._addToQueue("navigateBackFromViewCtrl", arguments);
                }

                if (this.currentView && this.currentView.viewId === viewCtrl.viewId) {
                    // okay, regular navigate back will do.
                    return this._navigateBack(skipSubView, detailsForPrevView);
                } else {
                    // look for the viewController that requested navigate back in the viewStack.
                    var foundViewId = this._getIdForView(viewCtrl); // if found, remove & destory - otherwise there's an issue here.


                    if (foundViewId) {
                        console.info(this.viewId, "navigateBackFromViewCtrl - screen with id " + foundViewId + " no longer visible, will be removed from viewController");
                        this.viewStack.remove(foundViewId);
                        this.history.remove(foundViewId);

                        this._destroyView(viewCtrl);

                        return Q.resolve();
                    } else {
                        // the view that requested to be dismissed using navigateBackFrom is no longer the currentView!
                        this.__printViewControllerError("the view that requested to be dismissed using navigateBackFrom is no longer the currentView and not in the viewController!");

                        return Q.reject();
                    }
                }
            }

            /**
             * Looks for the ID of the view in the viewStack, identifies the view based on the viewId (every view has a
             * viewId that is unique)
             * @param wantedView
             * @returns {null}
             * @private
             */
            _getIdForView(wantedView) {
                var viewFromStack = null,
                    foundViewId = null;

                for (var i = 0; i < this.viewStack.length && !foundViewId; i++) {
                    viewFromStack = this.viewStack.viewAtIndex(i);

                    if (viewFromStack.viewId === wantedView.viewId) {
                        foundViewId = this.viewStack.idOfViewAtIndex(i);
                    }
                }

                return foundViewId;
            }

            _navigateBack(skipSubView, detailsForPrevView) {
                Debug.GUI.ViewController && console.log(this.name, "_navigateBack:", arguments); // IMPORTANT: add to queue before do anything else (eg adding this _navigateBack call to the queue)
                // the activity tick removes the ScreenSaver and this has to be done first!

                SandboxComponent.activityTick()

                if (this.exchangeInProgress || !this._viewDidLoadPassed) {
                    // only add to queue, and return other promise
                    console.log(this.name, "enqueuing navigateBack:", arguments);
                    return this._addToQueue("_navigateBack", arguments);
                }

                if (!skipSubView && this.currentView.canNavigateBack && this.currentView.canNavigateBack()) {
                    return this.currentView.navigateBack();
                } else {
                    var defer = Q.defer();
                    this.exchangeInProgress = true;
                    var previousView = null,
                        previousViewID = null,
                        previousWrapperView = null;

                    if (this.currentView.hasHistory()) {
                        previousViewID = this.history.previous();

                        if (!previousViewID) {
                            if (this.ViewController) {
                                // important to unset this flag, otherwise viewWillDisappear of this viewController will
                                // block the navigateBack operation flow of the parent view controller.
                                this.exchangeInProgress = false; // it's important to pass along skipSubView, e.g. when a zoneFavorite was added in the audioZone

                                defer.resolve(this.ViewController.navigateBackFromViewCtrl.apply(this.ViewController, [this, skipSubView, detailsForPrevView])); // return the promise immediately, don't register for the .then()! (leads to duplicated _handleNavigateBackPassed calls!)
                                // the parent ViewController will handle the _handleNavigateBackPassed!

                                return defer.promise;
                            } else {
                                this.__printViewControllerError("Current view has no history and there's no parent view controller!");

                                defer.reject("can't navigate back - no history record");
                            }
                        } else {
                            this.history.remove(this.currentViewIdentifier);
                        }
                    } else {
                        // currentView has no history -> previousView = most recent in history stack
                        previousViewID = this.history.current();
                    }

                    if (previousViewID) {
                        previousView = this.viewStack.viewForID(previousViewID, true);

                        if (!previousView) {
                            // we have to init a new view
                            previousView = this._getViewForIdentifier(previousViewID, detailsForPrevView).then(function (createdView) {
                                createdView.setViewController(this);
                                return this._handleViewDidLoad(createdView);
                            }.bind(this));
                        } else if (detailsForPrevView) {
                            previousView && previousView.updateView(detailsForPrevView);
                        }

                        defer.resolve(Q.when(previousView).then(function (prevView) {
                            previousView = prevView; // assign correctly, so that below the view is used, not the promise
                            // take the particular in-animation of the current view and invert it!

                            var animationType = invertAnimation(this.currentView._inAnimation);
                            var prms;
                            previousWrapperView = previousView.getElement().parent();

                            if (previousWrapperView.length === 0) {
                                previousWrapperView = this.wrapperViewFactory.getInstance();
                                previousWrapperView.attr("view", previousViewID);
                                prms = GUI.animationHandler.append(previousView.getElement(), previousWrapperView);
                            } else {
                                prms = Q.resolve();
                            }

                            return prms.then(this._exchangeViews.bind(this, previousView, previousWrapperView, this.currentView, this.currentWrapperView, animationType));
                        }.bind(this)));
                    } else {
                        // No previous view to navigate back to.
                        this.__printViewControllerError("No previous view to navigate back to", previousViewID);

                        defer.reject("navigate back failed");
                    }

                    return defer.promise.then(function success() {
                        this._handleNavigateBackPassed(previousViewID, detailsForPrevView, previousView, previousWrapperView);

                        return true;
                    }.bind(this), function navigateBackError(e) {
                        this.__printViewControllerError("Error while navigating back", e);

                        this.exchangeInProgress = false;
                        this.processQueue();
                        throw e;
                    }.bind(this));
                }
            }

            _handleNavigateBackPassed(previousViewID, detailsForPrevView, previousView, previousWrapperView) {
                this._handleDeviceOrientation(previousView, this.currentView);

                if (this instanceof GUI.ModalViewController && !previousViewID && detailsForPrevView) {
                    // now we have to give the details back to the parents VC currentView
                    // the currentView is already the correct view, because the parent navigated back already
                    this.ViewController.currentView.updateView(detailsForPrevView);
                }

                if (!this.currentView.destroyOnBackNavigation || this.currentView.destroyOnBackNavigation()) {
                    // destroy view
                    this._destroyView(this.currentView);

                    this.currentView = null;
                } else {
                    // add view to stack
                    this.viewStack.add(this.currentViewIdentifier, this.currentView);
                }

                if (previousView) {
                    this.currentView = previousView;
                }

                if (previousViewID) {
                    this.currentViewIdentifier = previousViewID;
                }

                if (previousWrapperView) {
                    this.wrapperViewFactory.returnInstance(this.currentWrapperView);
                    this.currentWrapperView = previousWrapperView;
                }

                this.exchangeInProgress = false;
                this.processQueue();
                Debug.GUI.ViewController && console.log(this.name, "updateURL");
                this.updateURL();
                Debug.GUI.ViewController && setTimeout(this.status.bind(this), 50);
            }

            /**
             * Will navigate back n levels.
             * @param n     the number of levels to navigate back.
             * @returns {*}
             */
            bulkNavigateBack(n) {
                this._trackOperation("bulkNavigateBack", n);

                var i = n,
                    all = [true];

                while (i > 0) {
                    all.push(this.navigateBack());
                    i--;
                }

                return this.__operationWatchDog("bulkNavigateBack-QAll", Q.all(all), n);
            }

            navigateBackToScreenWithoutPermission() {
                var viewStack = this.viewStack.stack,
                    i = viewStack.length - 1,
                    hasPermissions = true,
                    viewIdToNavigateTo;

                while (i >= 0 && hasPermissions) {
                    hasPermissions = !!viewStack[i].view.getPermissions();

                    if (hasPermissions) {
                        viewStack[i].view._preventPermissionRegistration = true;
                    } else {
                        viewIdToNavigateTo = viewStack[i].id;
                    } //Debug.GUI.Screens && console.log(viewStack[i].id + " has permissions " + hasPermissions);


                    i--;
                }

                NavigationComp.disableAnimationsTemp();

                if (viewIdToNavigateTo) {
                    this.navigateBackTo(viewIdToNavigateTo);
                } else {
                    this.dismiss();
                }
            }

            navigateBackTo(id) {
                this._trackOperation("navigateBackTo", id);

                var idx = this.history.indexOf(id),
                    all = [true]; // find out if this identifier exists

                if (idx < 0) {
                    console.error("View to navigate back to (" + id + ") not found in this " + this.name + "!");
                }

                var i = this.history.length - 1;

                while (i > idx) {
                    all.push(this.navigateBack());
                    i--;
                }

                return this.__operationWatchDog("navigateBackTo-QAll", Q.all(all), id);
            }

            /**
             * Will navigate back until the root view of this viewController is visible.
             */
            navigateBackToRoot(offset) {
                this._trackOperation("navigateBackToRoot", offset);

                offset = offset || 0;
                var i = this.history.stack.length - (1 + offset),
                    all = [true];

                while (i > 0) {
                    all.push(this.navigateBack());
                    i--;
                }

                return this.__operationWatchDog("navigateBackToRoot-QAll", Q.all(all), offset);
            }

            /**
             * Will go through the history and navigateBack as long as the views have the given ID.
             * It'll stop as soon as a different ID was encountered.
             * @param id
             */
            dismissAllViewsWithId(id) {
                this._trackOperation("dismissAllViewsWithId", id); // copy viewStack, it'll be changed while we navigate back.


                var cache = [],
                    i;

                for (i = 0; i < this.viewStack.stack.length; i++) {
                    cache.push(this.viewStack.stack[i].id);
                }

                i = cache.length - 1;

                while (cache[i] === id && i >= 0) {
                    this.navigateBack();
                    i--;
                }

                this.navigateBack();
            }

            /**
             * allows views with this id to be shown multiple times
             * otherwise the view will be updated or reused when trying to show a same view
             * eg. GroupContent on ControlTab
             * @param viewID
             */
            addMultipleView(viewID) {
                this.multipleViews.push(viewID);
            }

            /**
             * whether animations should be used or not
             * @param enabled
             */
            enableAnimations(enabled) {
                Debug.GUI.ViewController && console.info("#", this.name, "enableAnimations", enabled);

                for (var key in Settings) {
                    if (Settings.hasOwnProperty(key)) {
                        Settings[key].DURATION = enabled ? DefaultSettings[key].DURATION : 0;
                    }
                }

                this._animationsEnabled = enabled;
            }

            getCurrentView() {
                if (this.currentView instanceof GUI.ModalViewController) {
                    return this.currentView.getCurrentView();
                }

                return this.currentView;
            }

            /**
             * returns a promise, which resolves, after the VC queue is fully processed!
             * even waits, when the queue is "edited" after calling this function (calls recursive..)
             */
            getQueueFinishedPromise() {
                Debug.GUI.ViewController && console.log(this.name, "getQueueFinishedPromise", this.queue.length);
                var all = [this._queueDef.promise // add the _queueDef.promise, so that the currently running animation also is considered
                ];
                this.queue.forEach(function (object) {
                    all.push(object.defer.promise);
                });
                return Q.allSettled(all).then(function () {
                    if (this.queue.length > 0) {
                        // check if maybe in meantime the queue was "filled" again
                        Debug.GUI.ViewController && console.log(this.name, "getQueueFinishedPromise recursive!");
                        return this.getQueueFinishedPromise();
                    }

                    Debug.GUI.ViewController && console.log(this.name, "getQueueFinishedPromise now!");
                }.bind(this));
            }

            /**
             * adds information about a view transition to the queue
             * we use a queue, because of fast transitions
             * -> but we start with the first transition, with the appearing of the viewController!
             * @param option
             * @param args
             * @private
             */
            _addToQueue(option, args) {
                Debug.GUI.ViewController && console.log("adding to queue '" + option + "':", args[0]);
                var defer = Q.defer();
                var queueItem = {
                    option: option,
                    defer: defer,
                    args: args
                };

                this.__startQueueItemWatchDog(queueItem);

                this.queue.push(queueItem);
                Debug.GUI.ViewController && console.log("queue: " + this.queue.length);

                if (!this._queueDef || !this._queueDef.promise.isPending()) {
                    this._queueDef = Q.defer();
                }

                return defer.promise;
            }

            /**
             * processes the queue -> start next transition and show next view
             * @private
             */
            processQueue() {
                Debug.GUI.ViewController && console.log(this.name, "processQueue", getStackObj());

                if (this.queue.length > 0) {
                    var nextProcess = this.queue.shift();

                    this.__removeQueueItemWatchDog(nextProcess);

                    nextProcess.defer.resolve(this[nextProcess.option].apply(this, nextProcess.args));
                } else {
                    this._queueDef.resolve(); // this view controller is done, maybe the parent has some more to do (it may have been waiting due
                    // to this viewControllers exchange). Important to check if parent's exchange in progress is set
                    // before informing it. as exchangeInProgress will be unset.


                    if (this.ViewController && !this.ViewController.exchangeInProgress) {
                        Debug.GUI.ViewController && console.log(this.name, "processQueue -> done, check parents queue!");
                        this.ViewController.processQueue();
                    }
                }

                //return this._queueDef.promise;
                return Q.resolve();
            }

            __startQueueItemWatchDog(queueItem) {
                var queueId = getRandomIntInclusive(0, 1000000);
                var queueWatchDog = setTimeout(function () {
                    console.error(this.viewId, "Queue Watchdog fired!");

                    if (this.queue.some(function (item) {
                        return item.id === queueId;
                    })) {
                        this.__printViewControllerError("Enqueued object not processed after 10 seconds!");
                    } else {
                        console.warn(this.viewId, "    item no longer found, watchdog wasn't removed!");
                    }
                }.bind(this), 10000);
                queueItem.id = queueId;
                queueItem.watchDog = queueWatchDog;
            }

            __removeQueueItemWatchDog(queueItem) {
                if (queueItem.watchDog) {
                    clearTimeout(queueItem.watchDog);
                }
            }

            /**
             * exchanges the views!
             * @param newView
             * @param newWrapperView
             * @param previousView
             * @param previousWrapperView
             * @param animationType
             * @returns {*}
             * @private
             */
            _exchangeViews(newView, newWrapperView, previousView, previousWrapperView, animationType) {
                Debug.GUI.ViewController && console.log(this.name, "_exchangeViews with animation", animationType);

                if (!(newView instanceof GUI.Screen)) {
                    console.error("#", this.name, "newView needs to be a subclass of GUI.Screen!");
                }

                if (Debug.GUI.ViewController && !this._isOutAnimation(animationType)) {
                    newWrapperView.attr("animation", animationType);
                    newWrapperView.attr("vc", this.name);
                } // before exchanging


                var all = [true];
                var preRemovePromise;

                try {
                    if (previousView) {
                        if (!previousView._viewDidAppearPassed && previousView._viewWillAppearPassed) {
                            console.warn(this.name, "_exchangeViews - previous view " + previousView.viewId + " is waiting for viewDidAppear, but already disappearing, wait for viewDidAppear to pass!");
                            preRemovePromise = previousView.viewDidAppearDeferred.promise;
                        } else {
                            preRemovePromise = Q.resolve();
                        } // store it on all to ensure it's waiting for it.


                        all.push(preRemovePromise);
                        preRemovePromise.then(function () {
                            if (previousView._viewWillAppearPassed) {
                                Debug.GUI.ViewController && console.log(this.name, "   viewWillDisappear on previous view");
                                Debug.GUI.Timings && console.time("# " + this.name + " " + previousView.name + "::viewWillDisappear");
                                var vwdPromise;

                                try {
                                    vwdPromise = previousView.viewWillDisappear();
                                } catch (e) {
                                    this.__printViewControllerError("Exception raised in exchangeViews during viewWillDisappear of currentView " + this.currentView.viewId, e.message, e.stack);
                                }

                                if (vwdPromise) {
                                    vwdPromise.then(null, function () {
                                        console.error(this.viewId, "viewWillDisappear on previous view " + previousView.viewId + " rejected!");
                                    }.bind(this));

                                    if (Debug.GUI.Timings) {
                                        vwdPromise.then(function () {
                                            console.timeEnd("# " + this.name + " " + previousView.name + "::viewWillDisappear");
                                        }.bind(this));
                                    }

                                    all.push(vwdPromise);
                                } else {
                                    Debug.GUI.Timings && console.timeEnd("# " + this.name + " " + previousView.name + "::viewWillDisappear");
                                }
                            } else {
                                console.info(this.viewId, "skipping viewWillDisappear on " + previousView.viewId + " - viewWillAppearPassed not set!");
                            }
                        }.bind(this));
                    }
                } catch (e) {
                    var defer = Q.defer();
                    console.error(this.viewId, "Failed during viewWillDisappear on previousView in exchangeViews", e);
                    defer.reject(e);
                    return defer.promise;
                } finally {
                    if (this.element) {
                        // to avoid flexbox bug
                        this.element.children().css("display", "");
                    } else {
                        console.error(this.viewId, "Failed to handle flexbox bug - this.element no longer exists!");
                    }
                }

                return Q.all(all).then(function () {
                    return this._processWithAnimation(newView, newWrapperView, previousView, previousWrapperView, animationType);
                }.bind(this), function (e) {
                    console.error(e);
                    return e;
                });
            }

            _useReplaceAnimationInstead(animationType) {
                let shouldReplace = false;
                if (AMBIENT_MODE) {
                    shouldReplace = true;
                    switch (animationType) {
                        case AnimationType.MODAL:
                        case AnimationType.MODAL_OUT:
                        case AnimationType.HD_MODAL_FULLSCREEN:
                        case AnimationType.HD_MODAL_FULLSCREEN_OUT:
                        case AnimationType.OVERLAY:
                        case AnimationType.OVERLAY_OUT:
                        case AnimationType.HD_OVERLAY:
                        case AnimationType.HD_OVERLAY_OUT:
                        case AnimationType.HD_OVERLAY_FULLSCREEN:
                        case AnimationType.HD_OVERLAY_FULLSCREEN_OUT:
                            shouldReplace = false;
                            break;
                        default:
                            break;
                    }
                }
                return shouldReplace;
            }

            _processWithAnimation(newView, newWrapperView, previousView, previousWrapperView, animationType) {
                Debug.GUI.ViewController && console.log(this.name, "_processWithAnimation " + animationType);
                var defer = Q.defer(); // default case outsourced to this method! (= AnimationType.NONE)

                var defaultCase = function (removePreviousView) {
                    if (typeof removePreviousView !== "boolean") {
                        removePreviousView = true;
                    }

                    this._handleViewWillAppear(newView).done(function _processWithAnimationViewWillAppearPassed() {
                        return GUI.animationHandler.append(newWrapperView, this.element).then(function _processWithAnimationWrapperAppended() {
                            this._onExchangeFinished(removePreviousView, newView, newWrapperView, previousView, previousWrapperView, animationType, defer);
                        }.bind(this), defer.reject);
                    }.bind(this), defer.reject);
                }.bind(this);

                var landscape = isLandscape(),
                    newViewAlreadyInDOM = newWrapperView && newWrapperView.parent().length === 1;

                // ensure that in ambient mode no animations that mess up with transparency are used - replace instead
                if (this._useReplaceAnimationInstead(animationType)) {
                    this._handleViewWillAppear(newView).then(() => {
                        return GUI.animationHandler.schedule(() => {
                            previousWrapperView && previousWrapperView.remove();
                            this.element.append(newWrapperView);
                        }).then(() => {
                            return this._onExchangeFinished(false, newView, newWrapperView, previousView, previousWrapperView, animationType, defer);
                        })
                    }, defer.reject);
                    return defer.promise;
                }

                switch (animationType) {
                    case AnimationType.FADE:
                    case AnimationType.FADE_OUT: {
                        this._animateFadeInOut(newView, newWrapperView, newViewAlreadyInDOM, previousView, previousWrapperView, animationType, defer);

                        break;
                    }

                    case AnimationType.PUSH_OVERLAP_LEFT: {
                        if (previousWrapperView) {
                            this._animatePushOverlapLeft(newView, newWrapperView, previousView, previousWrapperView, defer);
                        } else {
                            defaultCase();
                        }

                        break;
                    }

                    case AnimationType.PUSH_OVERLAP_LEFT_OUT: {
                        if (previousWrapperView) {
                            this._animatePushOverlapLeftOut(newView, newWrapperView, previousView, previousWrapperView, defer);
                        } else {
                            defaultCase();
                        }

                        break;
                    }

                    case AnimationType.OVERLAY_RIGHT: {
                        this._animateOverlayRight(newView, newWrapperView, previousView, previousWrapperView, defer);

                        break;
                    }

                    case AnimationType.OVERLAY_RIGHT_OUT: {
                        this._animateHdOverlayRightOut(newView, newWrapperView, previousView, previousWrapperView, defer);

                        break;
                    }

                    case AnimationType.MODAL:
                    case AnimationType.HD_MODAL_FULLSCREEN: {
                        this._animateModal(newView, newWrapperView, previousView, previousWrapperView, animationType, defer);

                        break;
                    }

                    case AnimationType.MODAL_OUT:
                    case AnimationType.HD_MODAL_FULLSCREEN_OUT: {
                        this._animateModalOut(newView, newWrapperView, newViewAlreadyInDOM, previousView, previousWrapperView, animationType, defer);

                        break;
                    }

                    case AnimationType.OVERLAY: {
                        newWrapperView.addClass("overlay-wrapper");
                        defaultCase(false);
                        break;
                    }

                    case AnimationType.OVERLAY_OUT: {
                        newWrapperView.attr("style", null);

                        this._handleViewWillAppear(newView).done(function () {
                            this._onExchangeFinished(true, newView, newWrapperView, previousView, previousWrapperView, animationType, defer);
                        }.bind(this), defer.reject);

                        break;
                    }

                    case AnimationType.HD_OVERLAY: {
                        this._animateHdOverlay(newView, newWrapperView, previousView, previousWrapperView, defer, landscape);

                        break;
                    }

                    case AnimationType.HD_OVERLAY_OUT: {
                        this._animateHdOverlayOut(newView, newWrapperView, previousView, previousWrapperView, defer, landscape);

                        break;
                    }

                    case AnimationType.HD_OVERLAY_FULLSCREEN: {
                        this._animateHdOverlayFullscreen(newView, newWrapperView, previousView, previousWrapperView, defer);

                        break;
                    }

                    case AnimationType.HD_OVERLAY_FULLSCREEN_OUT: {
                        this._animateHdOverlayFullscreenOut(newView, newWrapperView, previousView, previousWrapperView, defer);

                        break;
                    }

                    case AnimationType.NONE:
                    default: {
                        defaultCase();
                        break;
                    }
                }

                return defer.promise;
            }

            /**
             * calls viewWillAppear on the view if needed (if this view is also "visible" atm)
             * @param view
             * @returns {Promise}
             * @private
             */
            _handleViewWillAppear(view) {
                Debug.GUI.ViewController && console.log(this.name, "_handleViewWillAppear (" + view.viewId + ")");

                try {
                    var all = [true];

                    if (this._viewWillAppearPassed) {
                        Debug.GUI.ViewController && console.log(this.name, "    about to call viewWillAppear on " + view.viewId + "!");
                        Debug.GUI.Timings && console.time("# " + this.name + " " + view.name + "::viewWillAppear");
                        var vwaPromise = view.viewWillAppear();

                        if (vwaPromise) {
                            if (Debug.GUI.Timings) {
                                vwaPromise.then(function () {
                                    console.timeEnd("# " + this.name + " " + view.name + "::viewWillAppear");
                                }.bind(this));
                            }

                            all.push(vwaPromise);
                        } else {
                            Debug.GUI.Timings && console.timeEnd("# " + this.name + " " + view.name + "::viewWillAppear");
                        }
                    } else {
                        console.warn(this.name, "    won't call viewWillAppear on " + view.viewId + "! viewWillAppear not passed yet this controller! " + this.viewId);
                    }

                    return Q.all(all);
                } catch (e) {
                    this.__printViewControllerError("Exception raised during _handleViewWillAppear on " + view.viewId, e.message, e.stack);

                    return Q.fcall(function () {
                        throw e;
                    });
                }
            }

            _onExchangeFinished(removePreviousView, newView, newWrapperView, previousView, previousWrapperView, animationType, defer) {
                Debug.GUI.ViewController && console.log(this.name, "_onExchangeFinished");

                try {
                    var all = [true]; // safety net, independent from animationType, at latest when the exchange has finished, the new view
                    // mustn't be darkened. Method is safe to call regardless if or the view has been darkened before or not.

                    this._lightenView(newWrapperView); // important e.g. after closing a control opened from the search results.


                    if (previousView) {
                        // only these animations can be removed from DOM after animation without problems!
                        var prevInAnimation = previousView._inAnimation,
                            preventPrevViewHiding = false; // if you change something here, check HD Modal/ControlContent -> Screensaver -> remove Screensaver

                        if (removePreviousView && !this._isOutAnimation(animationType)) {
                            if (!(prevInAnimation === AnimationType.NONE || prevInAnimation === AnimationType.FADE || prevInAnimation === AnimationType.PUSH_OVERLAP_LEFT || !HD_APP && prevInAnimation === AnimationType.MODAL)) {
                                Debug.GUI.ViewController && console.log("don't remove previousView due to animation type " + prevInAnimation);
                                removePreviousView = false;
                                preventPrevViewHiding = true;
                            }
                        }

                        if (removePreviousView) {
                            Debug.GUI.ViewController && console.log("    about to remove the previousView and its wrapperView"); //previousView.getElement().remove(); // don't remove the element from it's wrapper, static screens don't work otherwise

                            previousWrapperView && previousWrapperView.remove(); // delaying this to the AF handler will cause flickering after navigating back!
                        } else if (preventPrevViewHiding) {
                            // hide all other views
                            // Always ensure we hide wrapper-views, not comfort mode buttons or titles!
                            this.element.children("wrapper-view:not(:last-child)").hide(); // don't hide the last child, it's the new view
                        }

                        try {
                            if (previousView._viewDidAppearPassed) {
                                Debug.GUI.Timings && console.time("# " + this.name + " " + previousView.name + "::viewDidDisappear");
                                var vddPromise = previousView.viewDidDisappear(!removePreviousView);

                                if (vddPromise) {
                                    if (Debug.GUI.Timings) {
                                        vddPromise.then(function () {
                                            console.timeEnd("# " + this.name + " " + previousView.name + "::viewDidDisappear");
                                        }.bind(this));
                                    }

                                    all.push(vddPromise);
                                } else {
                                    Debug.GUI.Timings && console.timeEnd("# " + this.name + " " + previousView.name + "::viewDidDisappear");
                                }
                            }
                        } catch (e) {
                            this.__printViewControllerError("Exception raised during viewDidDisappear of previousView " + previousView.viewId, e.message, e.stack);
                        }
                    }

                    if (this._viewDidAppearPassed) {
                        Debug.GUI.ViewController && console.log(this.name, "   forwarding viewDidAppear to " + newView.viewId);
                        Debug.GUI.Timings && console.time("# " + this.name + " " + newView.name + "::viewDidAppear");
                        var vdaPromise = newView.viewDidAppear();

                        if (vdaPromise) {
                            if (Debug.GUI.Timings) {
                                vdaPromise.then(function () {
                                    console.timeEnd("# " + this.name + " " + newView.name + "::viewDidAppear");
                                }.bind(this));
                            }

                            all.push(vdaPromise);
                        } else {
                            Debug.GUI.Timings && console.timeEnd("# " + this.name + " " + newView.name + "::viewDidAppear");
                        }
                    } else {
                        Debug.GUI.ViewController && console.warn(this.name, "   viewController didn't pass viewDidAppear " + "yet, not forwarding to " + newView.viewId + " yet");
                    }

                    Q.allSettled(all).then(function () {
                        Debug.GUI.ViewController && console.log(this.name, "   _onExchangeFinished passed! " + newView.viewId);
                        defer.resolve(!removePreviousView); // separate parameter must be provided!
                    }.bind(this));
                } catch (e) {
                    this.__printViewControllerError("Exception raised during _onExchangeFinished", e.message, e.stack);

                    defer.reject(e);
                }
            }

            _animateFadeInOut(newView, newWrapperView, newViewAlreadyInDOM, previousView, previousWrapperView, animationType, defer) {
                var prms = null;

                if (!newViewAlreadyInDOM) {
                    prms = GUI.animationHandler.schedule(function () {
                        if (animationType === AnimationType.FADE) {
                            newWrapperView.css("opacity", 0);
                            this.element.append(newWrapperView);
                        } else {
                            newWrapperView.insertBefore(previousWrapperView);
                        }
                    }.bind(this));
                } else {
                    prms = Q.resolve();
                }

                prms.then(function () {
                    this._handleViewWillAppear(newView).done(function () {
                        Debug.GUI.Timings && console.warn("---------- FadeInOut ----------");
                        var opacity, viewToAnimate;

                        if (animationType === AnimationType.FADE) {
                            opacity = [1, 0];
                            viewToAnimate = newWrapperView;
                        } else {
                            opacity = [0, 1];
                            viewToAnimate = previousWrapperView;
                        }

                        this.__animate(viewToAnimate, {
                            translateZ: 0,
                            // Force HA by animating a 3D property
                            opacity: opacity
                        }, {
                            duration: this.isVisible() ? Settings.FADE.DURATION : 0,
                            complete: function _animateFadeInOutFrameComplete() {
                                Debug.GUI.Timings && console.warn("---------- /FadeInOut ----------");

                                this._onExchangeFinished(true, newView, newWrapperView, previousView, previousWrapperView, animationType, defer);
                            }.bind(this)
                        });
                    }.bind(this), defer.reject);
                }.bind(this));
            }

            _animatePushOverlapLeft(newView, newWrapperView, previousView, previousWrapperView, defer) {
                var width100 = this.element.width(),
                    width30 = width100 * 0.3;
                newWrapperView.addClass("shadow-wrapper");
                newWrapperView.css({
                    transform: "translateX(" + width100 + "px)"
                });
                GUI.animationHandler.append(newWrapperView, this.element).then(function () {
                    this._handleViewWillAppear(newView).done(function () {
                        Debug.GUI.Timings && console.warn("---------- PushOverlapLeft ----------");

                        this.__animate(previousWrapperView, {
                            translateZ: 0,
                            // Force HA by animating a 3D property
                            translateX: [-width30, 0]
                        }, {
                            duration: this.isVisible() ? Settings.PUSH_LEFT_RIGHT.DURATION : 0,
                            easing: Settings.PUSH_LEFT_RIGHT.EASING,
                            complete: function _animatePushOverlapLeftPrevComplete() {
                                Debug.GUI.Timings && console.warn("---------- /PushOverlapLeft (prev) ----------");
                            }.bind(this)
                        });

                        this.__animate(newWrapperView, {
                            translateZ: 0,
                            // Force HA by animating a 3D property
                            translateX: [0, width100]
                        }, {
                            duration: this.isVisible() ? Settings.PUSH_LEFT_RIGHT.DURATION : 0,
                            easing: Settings.PUSH_LEFT_RIGHT.EASING,
                            complete: function _animatePushOverlapLeftNewWrapper() {
                                Debug.GUI.Timings && console.warn("---------- /PushOverlapLeft (new) ----------");
                                newWrapperView.removeClass("shadow-wrapper");
                                newWrapperView.css({
                                    transform: ""
                                });

                                this._onExchangeFinished(true, newView, newWrapperView, previousView, previousWrapperView, AnimationType.PUSH_OVERLAP_LEFT, defer);
                            }.bind(this)
                        });
                    }.bind(this), defer.reject);
                }.bind(this));
            }

            _animatePushOverlapLeftOut(newView, newWrapperView, previousView, previousWrapperView, defer) {
                var width100 = this.element.width(),
                    width30 = width100 * 0.3;
                previousWrapperView.addClass("shadow-wrapper");
                newWrapperView.css({
                    transform: "translateX(-" + width30 + "px)"
                });
                var prms;

                if (newWrapperView.parent().length === 0) {
                    prms = GUI.animationHandler.schedule(function () {
                        newWrapperView.insertBefore(previousWrapperView);
                    }).then(function () {
                        newWrapperView = this.element.find("wrapper-view:first-of-type");
                    }.bind(this));
                } else {
                    prms = Q.resolve();
                }

                prms.then(function () {
                    this._handleViewWillAppear(newView).done(function () {
                        Debug.GUI.Timings && console.warn("---------- PushOverlapLeftOut ----------");

                        this.__animate(newWrapperView, {
                            translateZ: 0,
                            // Force HA by animating a 3D property
                            translateX: [0, -width30]
                        }, {
                            duration: this.isVisible() ? Settings.PUSH_LEFT_RIGHT.DURATION : 0,
                            easing: Settings.PUSH_LEFT_RIGHT.EASING,
                            complete: function _animatePushOverlapLeftOutPrevComplete() {
                                Debug.GUI.Timings && console.warn("---------- /PushOverlapLeftOut (prev) ----------");
                            }.bind(this)
                        });

                        this.__animate(previousWrapperView, {
                            translateZ: 0,
                            // Force HA by animating a 3D property
                            translateX: [width100, 0]
                        }, {
                            duration: this.isVisible() ? Settings.PUSH_LEFT_RIGHT.DURATION : 0,
                            easing: Settings.PUSH_LEFT_RIGHT.EASING,
                            complete: function _animatePushOverlapLeftOutNewWrapper() {
                                Debug.GUI.Timings && console.warn("---------- /PushOverlapLeftOut (new) ----------");
                                newWrapperView.css({
                                    transform: ""
                                });

                                this._onExchangeFinished(true, newView, newWrapperView, previousView, previousWrapperView, AnimationType.PUSH_OVERLAP_LEFT_OUT, defer);
                            }.bind(this)
                        });
                    }.bind(this), defer.reject);
                }.bind(this));
            }

            _animateModal(newView, newWrapperView, previousView, previousWrapperView, animationType, defer) {
                var height100 = this.element.height(),
                    height80 = height100 - 20,
                    height130 = height100 * 1.3;

                if (previousWrapperView) {
                    this._darkenView(previousWrapperView, function () {
                        return newView.modalBackgroundTapped();
                    }.bind(this));
                }

                if (HD_APP && !(animationType === AnimationType.HD_MODAL_FULLSCREEN)) {
                    newWrapperView.addClass("hd-modal-wrapper"); // Save the original classes for later recycling of the wrapper view

                    newWrapperView.attr("factory-class", newWrapperView.attr("class"));
                    newWrapperView.css({
                        transform: "translateY(" + height130 + "px)"
                    });
                    GUI.animationHandler.append(newWrapperView, this.element).then(function () {
                        this._handleViewWillAppear(newView).done(function () {
                            Debug.GUI.Timings && console.warn("---------- Modal ----------");

                            this.__animate(newWrapperView, {
                                translateZ: 0,
                                // Force HA by animating a 3D property
                                translateY: [0, height130]
                            }, {
                                duration: this.isVisible() ? Settings.HD_MODAL.DURATION : 0,
                                easing: Settings.HD_MODAL.EASING,
                                complete: function _animateModalFullScreenNewWrapperComplete() {
                                    Debug.GUI.Timings && console.warn("---------- /Modal ----------");
                                    newWrapperView.attr("style", null); // fix for iOS (transform wasn't removed)

                                    this._onExchangeFinished(false, newView, newWrapperView, previousView, previousWrapperView, animationType, defer);
                                }.bind(this)
                            });
                        }.bind(this), defer.reject);
                    }.bind(this));
                } else {
                    newWrapperView.addClass("modal-wrapper"); // Save the original classes for later recycling of the wrapper view
                    AMBIENT_MODE && newWrapperView.addClass("modal-wrapper--ambient");

                    newWrapperView.attr("factory-class", newWrapperView.attr("class"));
                    newWrapperView.css({
                        transform: "translateY(" + height80 + "px)"
                    });
                    GUI.animationHandler.append(newWrapperView, this.element).then(function () {
                        this._handleViewWillAppear(newView).done(function () {
                            Debug.GUI.Timings && console.warn("---------- Modal ----------");

                            this.__animate(newWrapperView, {
                                translateZ: 0,
                                // Force HA by animating a 3D property
                                translateY: [0, height80]
                            }, {
                                duration: this.isVisible() ? Settings.MODAL.DURATION : 0,
                                easing: Settings.MODAL.EASING,
                                complete: function _animateModalNewWrapperComplete() {
                                    Debug.GUI.Timings && console.warn("---------- /Modal ----------");
                                    newWrapperView.attr("style", null); // fix for iOS (transform wasn't removed)

                                    this._onExchangeFinished(false, newView, newWrapperView, previousView, previousWrapperView, animationType, defer);
                                }.bind(this)
                            });

                            this.__animate(previousWrapperView, {
                                translateZ: 0,
                                // Force HA by animating a 3D property
                                scaleX: [0.98, 1],
                                translateY: [NavigationComp.getStatusBarHeight(), 0],
                                scaleY: [0.98, 1],
                                borderTopLeftRadius: ["6px", "0px"],
                                borderTopRightRadius: ["6px", "0px"]
                            }, {
                                duration: this.isVisible() ? Settings.MODAL.DURATION : 0,
                                easing: Settings.MODAL.EASING
                            });
                        }.bind(this), defer.reject);
                    }.bind(this));
                }
            }

            _animateModalOut(newView, newWrapperView, newViewAlreadyInDOM, previousView, previousWrapperView, animationType, defer) {
                var height100 = this.element.height(),
                    height80 = height100 - 20,
                    height130 = height100 * 1.3; // ensure newWrapperView is in the dom!

                var prms;

                if (!newViewAlreadyInDOM) {
                    prms = GUI.animationHandler.schedule(function () {
                        newWrapperView.insertBefore(previousWrapperView);
                    }).then(function () {
                        newWrapperView = this.element.find("wrapper-view:first-of-type");
                    }.bind(this));
                } else {
                    prms = Q.resolve();
                }

                prms.then(function () {
                    this._lightenView(newWrapperView);

                    if (HD_APP && !(animationType === AnimationType.HD_MODAL_FULLSCREEN_OUT)) {
                        this._handleViewWillAppear(newView).done(function () {
                            Debug.GUI.Timings && console.warn("---------- ModalOut ----------");

                            this.__animate(previousWrapperView, {
                                translateZ: 0,
                                // Force HA by animating a 3D property
                                translateY: [height130, 0]
                            }, {
                                duration: this.isVisible() ? Settings.HD_MODAL.DURATION : 0,
                                easing: Settings.HD_MODAL.EASING,
                                complete: function _animateModalOutFullScreenComplete() {
                                    Debug.GUI.Timings && console.warn("---------- /ModalOut ----------");
                                    previousWrapperView.attr("factory-class", null);

                                    this._onExchangeFinished(true, newView, newWrapperView, previousView, previousWrapperView, animationType, defer);
                                }.bind(this)
                            });

                            if (!newViewAlreadyInDOM) {
                                // if it wasn't in the dom it may have opacity: 1 and display: none set!
                                newWrapperView.attr("style", null);
                            }
                        }.bind(this), defer.reject);
                    } else {
                        this._handleViewWillAppear(newView).done(function () {
                            Debug.GUI.Timings && console.warn("---------- ModalOut ----------");

                            this.__animate(previousWrapperView, {
                                translateZ: 0,
                                // Force HA by animating a 3D property
                                translateY: [height80, 0]
                            }, {
                                duration: this.isVisible() ? Settings.MODAL.DURATION : 0,
                                easing: Settings.MODAL.EASING,
                                complete: function _animateModalOutCompletePrevious() {
                                    Debug.GUI.Timings && console.warn("---------- /ModalOut ----------");
                                    previousWrapperView.attr("factory-class", null);

                                    this._onExchangeFinished(true, newView, newWrapperView, previousView, previousWrapperView, animationType, defer);
                                }.bind(this)
                            });

                            this.__animate(newWrapperView, {
                                translateZ: 0,
                                // Force HA by animating a 3D property
                                translateY: [0, NavigationComp.getStatusBarHeight()],
                                scaleX: [1, 0.98],
                                scaleY: [1, 0.98],
                                borderTopLeftRadius: ["0px", "6px"],
                                borderTopRightRadius: ["0px", "6px"]
                            }, {
                                duration: this.isVisible() ? Settings.MODAL.DURATION : 0,
                                easing: Settings.MODAL.EASING,
                                complete: function _animateModalOutCompleteNewWrapper() {
                                    newWrapperView.attr("style", null); // fix for iOS (transform wasn't removed)
                                }
                            });
                        }.bind(this), defer.reject);
                    }
                }.bind(this));
            }

            _animateHdOverlay(newView, newWrapperView, previousView, previousWrapperView, defer, landscape) {
                if (previousWrapperView) {
                    this._darkenView(previousWrapperView, function (navigateAllBack) {
                        if (this.currentView instanceof GUI.ModalViewController) {
                            // ask the current view if a tap on a darkener should remove the view..
                            var currentView = this.getCurrentView(); // is the actual view

                            if (currentView && typeof currentView.handleModalBackgroundTapped === "function") {
                                if (!currentView.handleModalBackgroundTapped()) {
                                    return;
                                }
                            }
                        }

                        return this.navigateBack();
                    }.bind(this, this.history.length === 2));
                }

                newWrapperView.addClass("hd-overlay");

                var stackChild = function (child, stacked) {
                    var width100 = NaN,
                        width50 = NaN,
                        width25 = NaN,
                        def = Q.defer();
                    var opts = {
                        translateZ: 0 // Force HA by animating a 3D property

                    };

                    var updateOpts = function () {
                        width100 = child.width();
                        width50 = width100 * 0.5;
                        width25 = width100 * 0.25;

                        if (landscape) {
                            // is right positioned
                            if (stacked) {
                                opts.translateX = [-width50, 0];
                            } else {
                                opts.translateX = [0, width100];
                            }
                        } else {
                            // is left positioned
                            if (stacked) {
                                opts.translateX = [-width25, 0];
                            } else {
                                opts.translateX = [0, width100];
                            }
                        }
                    };

                    var doAnimate = function () {
                        this.__animate(child, opts, {
                            duration: this.isVisible() ? Settings.HD_OVERLAY.DURATION : 0,
                            easing: Settings.HD_OVERLAY.EASING,
                            complete: function _animateHdOverlayChildComplete() {
                                child.attr("style", null);

                                if (stacked) {
                                    child.addClass("hd-overlay-stacked");
                                }

                                def.resolve();
                            }.bind(this)
                        });
                    }.bind(this);

                    if (!stacked) {
                        child.css({
                            transform: "translateX(100%)" // we must use percent here (we don't have a size at this point)

                        });
                        GUI.animationHandler.append(child, this.element).then(function () {
                            updateOpts();

                            this._handleViewWillAppear(newView).done(doAnimate, defer.reject);
                        }.bind(this));
                    } else {
                        updateOpts();
                        doAnimate();
                    }

                    return def.promise;
                }.bind(this); // Per new design we don't want the stacking animation on HD devices anymore
                // ==== Stacking animation ====
                // move other childs

                /*var childs = this.element.children(),
                    animations = [];
                childs.push(newWrapperView); // add it to the array, will be added just before animation due to iOS flickering
                var greaterThan = Math.max(0, childs.length - 3);
                for (var i = childs.length - 1; i > greaterThan; i--) {
                    if (!$(childs[i]).hasClass("hd-overlay")) break;
                    animations.push(stackChild($(childs[i]), i === childs.length - 2));
                }
                 Q.all(animations).done(function() {
                    this._onExchangeFinished(false, newView, newWrapperView, previousView, previousWrapperView, AnimationType.HD_OVERLAY, defer);
                }.bind(this));*/
                // ==== Stacking animation ====


                stackChild(newWrapperView, false).done(function () {
                    this._onExchangeFinished(false, newView, newWrapperView, previousView, previousWrapperView, AnimationType.HD_OVERLAY, defer);
                }.bind(this));
            }

            _animateHdOverlayOut(newView, newWrapperView, previousView, previousWrapperView, defer, landscape) {
                var childs,
                    lastHDOverlay = null;
                var prms;

                if (newWrapperView.parent().length === 0) {
                    prms = GUI.animationHandler.schedule(function () {
                        // if it wasn't in the dom it may have opacity: 1 and display: none set!
                        newWrapperView.attr("style", null);
                        newWrapperView.insertBefore(previousWrapperView);
                    }).then(function () {
                        newWrapperView = this.element.find("wrapper-view:first-of-type");
                    }.bind(this));
                } else {
                    prms = Q.resolve();
                }

                prms.then(function () {
                    childs = this.element.children();

                    this._lightenView(newWrapperView);

                    this._handleViewWillAppear(newView).done(function () {
                        var unstackChild = function (child, wasStacked) {
                            var width100 = child.width(),
                                width50 = width100 * 0.5,
                                width25 = width100 * 0.25,
                                def = Q.defer();
                            var opts = {
                                translateZ: 0 // Force HA by animating a 3D property

                            };

                            if (landscape) {
                                // is right positioned
                                if (wasStacked) {
                                    opts.translateX = [0, -width50];
                                } else {
                                    opts.translateX = [width100, 0];
                                }
                            } else {
                                if (wasStacked) {
                                    opts.translateX = [0, -width25];
                                } else {
                                    opts.translateX = [width100, 0];
                                }
                            }

                            this.__animate(child, opts, {
                                duration: this.isVisible() ? Settings.HD_OVERLAY.DURATION : 0,
                                easing: Settings.HD_OVERLAY.EASING,
                                complete: function _animateHdOverlayOutChildComplete() {
                                    child.attr("style", null);

                                    if (wasStacked) {
                                        child.removeClass("hd-overlay-stacked");
                                    } else {
                                        child.remove();
                                    }

                                    def.resolve();
                                }.bind(this)
                            });

                            return def.promise;
                        }.bind(this); // Per new design we don't want the stacking animation on HD devices anymore
                        // ==== Stacking animation ====

                        /*var childs = this.element.children(),
                            animations = [],
                            greaterThan = Math.max(0, childs.length - 3);
                        for (var i = childs.length - 1; i > greaterThan; i--) {
                            if (!$(childs[i]).hasClass("hd-overlay")) break;
                            animations.push(unstackChild($(childs[i]), i === childs.length - 2));
                        }
                         Q.all(animations).done(function() {
                            newWrapperView.removeClass("passive-wrapper");
                            this._onExchangeFinished(true, newView, newWrapperView, previousView, previousWrapperView, AnimationType.HD_OVERLAY_OUT, defer);
                        }.bind(this));*/
                        // ==== Stacking animation ====


                        for (var i = childs.length; i > 0 && lastHDOverlay == null; --i) {
                            if ($(childs[i]).hasClass("hd-overlay")) {
                                lastHDOverlay = $(childs[i]);
                            }
                        }

                        unstackChild(lastHDOverlay, false).done(function () {
                            newWrapperView.removeClass("passive-wrapper");

                            this._onExchangeFinished(true, newView, newWrapperView, previousView, previousWrapperView, AnimationType.HD_OVERLAY_OUT, defer);
                        }.bind(this));
                    }.bind(this), defer.reject);
                }.bind(this));
            }

            _animateHdOverlayFullscreen(newView, newWrapperView, previousView, previousWrapperView, defer) {
                var width100 = this.element.width();
                previousWrapperView.addClass("passive-wrapper");

                this._darkenView(previousWrapperView, function () {
                    return this.ViewController.navigateBack(true);
                }.bind(this));

                newWrapperView.addClass("hd-overlay-fullscreen");
                newWrapperView.css({
                    transform: "translateX(" + width100 + "px)"
                });
                GUI.animationHandler.append(newWrapperView, this.element).then(function () {
                    this._handleViewWillAppear(newView).done(function () {
                        Debug.GUI.Timings && console.warn("---------- HdOverlayFullscreen ----------");

                        this.__animate(newWrapperView, {
                            translateZ: 0,
                            // Force HA by animating a 3D property
                            translateX: [0, width100]
                        }, {
                            duration: this.isVisible() ? Settings.HD_OVERLAY_FULLSCREEN.DURATION : 0,
                            easing: Settings.HD_OVERLAY_FULLSCREEN.EASING,
                            complete: function _animateHdOverlayFullscreenNewWrapperComplete() {
                                Debug.GUI.Timings && console.warn("---------- /HdOverlayFullscreen ----------");
                                newWrapperView.attr("style", null);

                                this._onExchangeFinished(false, newView, newWrapperView, previousView, previousWrapperView, AnimationType.HD_OVERLAY_FULLSCREEN, defer);
                            }.bind(this)
                        });
                    }.bind(this), defer.reject);
                }.bind(this));
            }

            _animateHdOverlayFullscreenOut(newView, newWrapperView, previousView, previousWrapperView, defer) {
                var width100 = this.element.width();
                var prms;

                if (newWrapperView.parent().length === 0) {
                    prms = GUI.animationHandler.schedule(function () {
                        // if it wasn't in the dom it may have opacity: 1 and display: none set!
                        newWrapperView.attr("style", null);
                        newWrapperView.insertBefore(previousWrapperView);
                    }).then(function () {
                        newWrapperView = this.element.find("wrapper-view:first-of-type");
                    }.bind(this));
                } else {
                    prms = Q.resolve();
                }

                prms.then(function () {
                    this._lightenView(newWrapperView);

                    this._handleViewWillAppear(newView).done(function () {
                        Debug.GUI.Timings && console.warn("---------- HdOverlayFullscreenOut ----------");

                        this.__animate(previousWrapperView, {
                            translateZ: 0,
                            // Force HA by animating a 3D property
                            translateX: [width100, 0]
                        }, {
                            duration: this.isVisible() ? Settings.HD_OVERLAY_FULLSCREEN.DURATION : 0,
                            easing: Settings.HD_OVERLAY_FULLSCREEN.EASING,
                            complete: function _animateHdOverlayFullscreenOutComplete() {
                                Debug.GUI.Timings && console.warn("---------- /HdOverlayFullscreenOut ----------");
                                newWrapperView.attr("style", null); // fix for iOS (transform wasn't removed)

                                this._onExchangeFinished(true, newView, newWrapperView, previousView, previousWrapperView, AnimationType.HD_OVERLAY_FULLSCREEN_OUT, defer);
                            }.bind(this)
                        });
                    }.bind(this), defer.reject);
                }.bind(this));
            }

            _animateOverlayRight(newView, newWrapperView, previousView, previousWrapperView, defer) {
                this._darkenView(previousWrapperView, function () {
                    return this.navigateBack();
                }.bind(this), function () {
                    // swipe for closing e. g. the burger menu
                    return this.navigateBack();
                }.bind(this));

                if (HD_APP) {
                    newWrapperView.addClass("hd-overlay-right");
                } else {
                    newWrapperView.addClass("sd-overlay-right");
                }

                newWrapperView.css({
                    transform: "translateX(-100%)"
                });
                GUI.animationHandler.append(newWrapperView, this.element).then(function () {
                    var width100 = newWrapperView.width();
                    var opts = {
                        translateZ: 0,
                        // Force HA by animating a 3D property
                        translateX: [0, -width100]
                    };

                    this._handleViewWillAppear(newView).done(function () {
                        Debug.GUI.Timings && console.warn("---------- HdOverlayRight ----------");

                        this.__animate(newWrapperView, opts, {
                            duration: this.isVisible() ? Settings.HD_OVERLAY.DURATION : 0,
                            easing: Settings.HD_OVERLAY.EASING,
                            complete: function _animateOverlayRightComplete() {
                                Debug.GUI.Timings && console.warn("---------- /HdOverlayRight ----------");
                                newWrapperView.attr("style", null);

                                this._onExchangeFinished(false, newView, newWrapperView, previousView, previousWrapperView, AnimationType.OVERLAY_RIGHT, defer);
                            }.bind(this)
                        });
                    }.bind(this), defer.reject);
                }.bind(this));
            }

            _animateHdOverlayRightOut(newView, newWrapperView, previousView, previousWrapperView, defer) {
                var width100 = previousWrapperView.width();

                this._lightenView(newWrapperView);

                var opts = {
                    translateZ: 0,
                    // Force HA by animating a 3D property
                    translateX: [-width100, 0]
                };

                this._handleViewWillAppear(newView).done(function () {
                    Debug.GUI.Timings && console.warn("---------- HdOverlayRightOut ----------");

                    this.__animate(previousWrapperView, opts, {
                        duration: this.isVisible() ? Settings.HD_OVERLAY.DURATION : 0,
                        easing: Settings.HD_OVERLAY.EASING,
                        complete: function _animateHdOverlayRightOutComplete() {
                            Debug.GUI.Timings && console.warn("---------- /HdOverlayRightOut ----------");
                            newWrapperView.removeClass("passive-wrapper");

                            this._onExchangeFinished(true, newView, newWrapperView, previousView, previousWrapperView, AnimationType.OVERLAY_RIGHT_OUT, defer);
                        }.bind(this)
                    });
                }.bind(this), defer.reject);
            }

            /**
             * creates a view for the id
             * @param id .-seperated path: "GUI.ArchiveScreen"
             * @param details param for ctor function
             * @returns {Promise}
             * @private
             */
            _getViewForIdentifier(id, details) {
                Debug.GUI.Timings && console.time("# " + this.name + " loading " + id);
                return Q(LxReactScreenAdapter.ScreenUtils.getScreen(id, details)).then(screen => {
                    Debug.GUI.Timings && console.timeEnd("# " + this.name + " loading " + id);
                    return screen;
                });
            }

            /**
             * checks, if multiple views for the given viewID are allowed
             * eg. GroupContent -> 2x
             * a separate view instance will be created then!
             * @param viewID
             * @returns {boolean} returns true if the view can be shown twice
             * @private
             */
            _multipleViewAllowed(viewID) {
                if (this.multipleViews.length > 0) {
                    return this.multipleViews.indexOf(viewID) !== -1;
                }

                return false;
            }

            /**
             * destroys the view after the set timeout
             * @param view
             * @private
             */
            _destroyView(view) {
                try {
                    view.destroy();
                } catch (e) {
                    this.__printViewControllerError("Exception raised while destroying view!", e.message, e.stack);
                }
            }

            /**
             * adds a darkener element to the wrapper and darkens it down
             * you are able to add an event handler to the dark background
             * @param viewToDarken
             * @param onClick
             * @param [onLeftSwipe]
             * @private
             */
            _darkenView(viewToDarken, onClick, onLeftSwipe) {
                viewToDarken.addClass("passive-wrapper");
                var isIosHd = HD_APP && PlatformComponent.getPlatformInfoObj().platform === PlatformType.IOS; // add the darkener

                var ambientClass = AMBIENT_MODE ? "passive-wrapper__darkener--ambient" : "";
                var darkener = $('<div class="passive-wrapper__darkener ' + ambientClass + '">' + '   ' + (isIosHd ? '<div class="darkener__scrollfix" />' : '') + // fixes scrolling issues (97715926)
                    '</div>');
                GUI.animationHandler.append(darkener, viewToDarken).then(function () {
                    this.__animate(darkener, {
                        translateZ: 0,
                        // Force HA by animating a 3D property
                        opacity: 0.8
                    }, {
                        duration: this.isVisible() ? Settings.HD_DARKENER.DURATION : 0,
                        easing: Settings.HD_DARKENER.EASING,
                        complete: function _darkenViewComplete() {
                            if (onLeftSwipe) {
                                this._registerDarkenerLeftSwipe(darkener, onLeftSwipe);
                            }

                            this._registerDarkenerOnClick(darkener, onClick);
                        }.bind(this)
                    });

                    this._darkenedViews.push({
                        darkener: darkener,
                        onClick: onClick
                    });
                }.bind(this));
            }

            /**
             * removes the darkener from the previously darkened view (with _darkenView())
             * @param darkenedView
             * @private
             */
            _lightenView(darkenedView) {
                var darkener = darkenedView.children(".passive-wrapper__darkener");

                if (darkener.length > 0) {
                    // remove from array
                    var darkenerIdx = -1;

                    for (var i = 0; i < this._darkenedViews.length; i++) {
                        if (this._darkenedViews[i].darkener.is(darkener)) {
                            darkenerIdx = i;
                            break;
                        }
                    }

                    darkenerIdx !== -1 && this._darkenedViews.splice(darkenerIdx, 1);

                    this._unregisterDarkenerOnClick(darkener);

                    this._unregisterDarkenerOnSwipe(darkener);

                    this.__animate(darkener, {
                        translateZ: 0,
                        // Force HA by animating a 3D property
                        opacity: 0
                    }, {
                        duration: this.isVisible() ? Settings.HD_DARKENER.DURATION : 0,
                        easing: Settings.HD_DARKENER.EASING,
                        complete: function _lightenViewComplete() {
                            darkenedView.removeClass("passive-wrapper");
                            darkener.remove();
                        }.bind(this)
                    });
                }
            }

            _registerDarkenerLeftSwipe(darkener, onSwipe) {
                if (typeof onSwipe === "function") {
                    darkener[0]._onSwipeLeft = function (ev) {
                        if (!darkener[0]._darknerSwipePrm) {
                            darkener[0]._darknerSwipePrm = Q(onSwipe());

                            darkener[0]._darknerSwipePrm.finally(function () {
                                delete darkener[0]._darknerSwipePrm;

                                if (darkener[0]._swipeHandler) {
                                    darkener[0]._swipeHandler.dispose();

                                    delete darkener[0]._swipeHandler;
                                }
                            });
                        }

                        ev.stopPropagation();
                        ev.preventDefault();
                    };

                    var swipeOptions = {
                        swipeVelocityX: 0.1
                    };
                    darkener[0]._swipeHandler = Hammer(this.element[0], swipeOptions).on('swipeleft', darkener[0]._onSwipeLeft.bind(this));
                }
            }

            _registerDarkenerOnClick(darkener, onClick) {
                if (typeof onClick === "function") {
                    darkener[0]._onClickFn = function (ev) {
                        if (!darkener[0]._darknerClickPrm) {
                            darkener[0]._darknerClickPrm = Q(onClick());

                            darkener[0]._darknerClickPrm.finally(function () {
                                delete darkener[0]._darknerClickPrm;
                            });
                        }

                        ev.stopPropagation();
                        ev.preventDefault();
                    };

                    darkener.on("click", darkener[0]._onClickFn); // hammer.on(tapEvent()) was called twice!
                }
                /*if (HD_APP && PlatformComponent.getPlatformInfoObj().platform === PlatformType.IOS) {
                    // to prevent scrolling on background -> fixed with bigger wrapper child element now!
                    darkener.on("touchmove", function(ev) {
                        //ev.stopPropagation(); // -> prevents other child event listeners (like sliders)
                        ev.preventDefault();
                    });
                }*/

            }

            _unregisterDarkenerOnClick(darkener) {
                if (typeof darkener[0]._onClickFn === "function") {
                    darkener.off("click", darkener[0]._onClickFn);
                    delete darkener[0]._onClickFn;
                    delete darkener[0]._darknerClickPrm;
                }
                /*if (HD_APP && PlatformComponent.getPlatformInfoObj().platform === PlatformType.IOS) {
                    darkener.off("touchmove");
                }*/

            }

            _unregisterDarkenerOnSwipe(darkener) {
                if (typeof darkener[0]._onSwipeLeft === "function") {
                    if (darkener[0]._swipeHandler) {
                        darkener[0]._swipeHandler.dispose();

                        delete darkener[0]._swipeHandler;
                    }

                    delete darkener[0]._onSwipeLeft;
                    delete darkener[0]._darknerSwipePrm;
                }
            }

            _isOutAnimation(animationType) {
                return animationType.indexOf("out") === animationType.length - 3;
            }

            /**
             * prints some status after a transition
             */
            status() {
                Debug.GUI.ViewController && console.log("========= ", this.name, "Status =========");
                Debug.GUI.ViewController && console.log("history:", JSON.stringify(this.history.stack));
                Debug.GUI.ViewController && console.log("viewStack:", this.viewStack.stringify());
                Debug.GUI.ViewController && console.log("========================================================================");
            }

            setOrientation(orientation = HD_APP || AMBIENT_MODE ? DeviceOrientation.ANY : DeviceOrientation.PORTRAIT_PRIMARY) {
                useDeviceOrientation().set(orientation);
            }

            _handleDeviceOrientation(newView, previousView) {
                if (this.hasOrientationPlugin) {
                    var newScreenOrientation = newView.supportedOrientation(),
                        prevScreenOrientation = useDeviceOrientation().get() || this.supportedOrientation();

                    if (newScreenOrientation !== prevScreenOrientation) {
                        this.setOrientation(newScreenOrientation);
                    }
                }
            }

            /**
             * Keeps the last 20 actions in the history.
             * @param identifier
             * @param argument
             * @private
             */
            _trackOperation(identifier, argument) {
                var trackObj = {
                        action: identifier,
                        argument: cloneObject(argument),
                        stack: getStack(1),
                        ts: timingNow(),
                        date: new LxDate()
                    },
                    trackMaxLen = 20; // view lifecycle logged by view.js

                if (Debug.GUI.ViewLifeCycle && Debug.GUI.ScreenViewLifeCycle) {
                    switch (identifier) {
                        case "viewDidLoad":
                        case "viewWillAppear":
                        case "viewDidAppear":
                        case "viewWillDisappear":
                        case "viewDidDisappear":
                        case "destroy":
                            break;

                        default:
                            console.log(this.viewId, identifier + (argument ? ": " + JSON.stringify(argument) : ""));
                            break;
                    }
                } else {
                    console.log(this.viewId, identifier + (argument ? ": " + JSON.stringify(argument) : ""));
                }

                this._operationTrack = this._operationTrack || [];

                this._operationTrack.splice(0, 0, trackObj);

                if (this._operationTrack.length > trackMaxLen) {
                    this._operationTrack.splice(trackMaxLen, this._operationTrack.length - trackMaxLen);
                }
            }

            __printOperationTrack(limit) {
                this._operationTrack = this._operationTrack || [];
                console.log(this.viewId, " Operation Track: " + this._operationTrack.length);

                for (var i = 0; i < this._operationTrack.length && i < limit; i++) {
                    var trackObj = this._operationTrack[i];
                    var timeString = trackObj.date.format(LxDate.getTimeFormat());
                    console.log("       - " + timeString + " " + trackObj.action + ", arg: " + JSON.stringify(trackObj.argument));
                    trackObj.stack.forEach(function (stackEntry) {
                        console.log("                     * " + stackEntry);
                    }.bind(this));
                }
            }

            __printViewControllerError(err, arg, arg2) {
                console.error(this.viewId, "Error: " + JSON.stringify(err));
                console.log("          Arg: " + JSON.stringify(arg));
                console.log("         Arg2: " + JSON.stringify(arg2));
                console.log("      History: " + JSON.stringify(this.history));
                console.log("    ViewStack: " + this.viewStack.stringify());
                console.log("   StackTrace: " + JSON.stringify(getStack(1)));

                this.__printOperationTrack(3);
            }

            __operationWatchDog(identifier, promise, arg) {
                if (!promise || !Q.isPromiseAlike(promise)) {
                    console.error(this.viewId, "Failed to launch OP watchdog for " + identifier + " - no promise!");
                    return Q.resolve(promise); // resolve with whatever arrives as "promise"
                }

                var watchDogTimeout = setTimeout(function () {
                    if (promise.isPending()) {
                        this.__printViewControllerError("Operation " + identifier + " still pending after 5 seconds!", arg);
                    }
                }.bind(this), 5000);
                promise.then(function (succ) {
                    clearTimeout(watchDogTimeout);
                }, function (err) {
                    clearTimeout(watchDogTimeout);

                    this.__printViewControllerError("Operation " + identifier + " failed!", err, arg);

                    return Q.reject(err);
                }.bind(this));
                return promise;
            }

            __animate(target, animationArgs, animationSettings) {
                return lxAnimate(target, animationArgs, animationSettings);
            }

        }

        GUI.ViewController = ViewController;
    }

    class ModalViewController extends GUI.ViewController {
        //region Static
        static Events = {
            BackgroundTapped: "modal-background-tapped"
        }; //endregion Static

        /**
         * @see GUI.ViewController
         */
        constructor(element) {
            if (element instanceof jQuery) {
                super(...arguments);
            } else {
                super(); // don't apply with arguments, could be details (-> element would be details)
            }
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        destroyOnBackNavigation() {
            return true;
        }

        modalBackgroundTapped() {
            Debug.GUI.ViewController && console.log(this.name, "modalBackgroundTapped");
            this.emit(GUI.ModalViewController.Events.BackgroundTapped);

            if (this.currentView && typeof this.currentView.handleModalBackgroundTapped === "function") {
                if (!this.currentView.handleModalBackgroundTapped()) {
                    return;
                }
            }

            this.dismissModal();
        }

        dismissModal() {
            Debug.GUI.ViewController && console.log(this.name, "dismissModal");
            this.dismiss();
        }

    }

    GUI.ModalViewController = ModalViewController;
    return GUI;
}(window.GUI || {});
