'use strict';
/*
 title
 iconSrc
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.DELETE = "DeleteCell";

    class DeleteCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(title, iconSrc) {
                iconSrc = iconSrc ? iconSrc : "resources/Images/General/trash-can-v2.svg";
                title = title ? title : _("delete");
                return $(ImageBox.getResourceImageWithClasses(iconSrc, "delete-cell__trash-can") + '<div class="delete-cell__title text-overflow-ellipsis">' + title + '</div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("delete-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.contentPlaceholder.append(DeleteCell.Template.getTemplate(this.content && this.content.title, this.content && this.content.iconSrc));
        }

        clickableAllowed() {
            return true;
        }

    }

    GUI.TableViewV2.Cells.DeleteCell = DeleteCell;
    return GUI;
}(window.GUI || {});
