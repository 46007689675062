'use strict';

define([], function () {
    return {
        TYPE: {
            GENERIC: 0,
            LEAF: 1
        },
        VENT_REASONS: {
            BASE_VENTILATION: 0,
            HIGH_HUMIDITY: 1,
            AUTOMATIC_TEMP: 2,
            BAD_CO2: 3,
            STOP: 4,
            OPEN_WINDOW: 5,
            TURBO: 6,
            MANUAL: 7,
            EXHAUST_AIR: 8,
            FALL_ASLEEP: 9,
            FROST_PROTECTION: 10
        },
        TIMER_PRESET: {
            SETTINGS: -3,
            OFF: -2,
            MANUAL: -1
        },
        SCREEN_STATES: {
            VENT_TIMER_SCREEN: "VentilationControlVentTimerScreen",
            VENT_MODES_SCREEN: "VentilationControlModesScreen",
            VENT_SETTINGS_SCREEN: "VentilationControlSettingsScreen"
        },
        CONTROL_INFO: {
            OK: 0,
            ERROR: 1,
            WARNING: 2,
            INFO: 3
        },
        TEMPERATURE_SUPPORT: {
            COOLING: -1,
            NONE: 0,
            HEATING: 1
        },
        SETTING_TIMER_DURATION: 20,
        MODES: {
            EXHAUSE: 0,
            INTAKE: 1,
            HEAT_EXCHANGER_ON: 2,
            HEAT_EXCHANGER_OFF: 3
        },
        Hysteresis: {
            CO2_FACTOR: 0.95,
            // 5%, e.g. max is 1000ppm, target is to be below 950ppm
            HUMIDITY_OFFSET: 3 // 3%, e.g. max is 60%, target is 57%

        }
    };
});
