import * as React from "react"
import Svg, { Path } from "react-native-svg"

function WeatherThunderstorm(props) {
    return (
        <Svg isreact="true"
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            viewBox="0, 0, 32, 32"
            {...props}
        >
            <Path
                d="M2 17.75a5.252 5.252 0 012.627-4.547 7.876 7.876 0 0114.572-3.971 5.696 5.696 0 016.939 3.453A5.251 5.251 0 0124.75 23H7.25A5.25 5.25 0 012 17.75z"
                fill="#D5E2E9"
            />
            <Path
                d="M14.822 17.501h3.927l-2.244 4.583h3.161l-6.415 6.415 1.309-4.583h-2.226z"
                fill="#F4CD27"
            />
        </Svg>
    )
}

export default WeatherThunderstorm
