'use strict';

define("LightCentralControlCommandSrc", ["LightControlCommandSrc"], function (LightControlCommandSrc) {
    return class LightCentralControlCommandSrc extends LightControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            return [this.createCommand(_('off'), Commands.LIGHTCONTROL.ALL_OFF), this.createCommand(_('on'), Commands.LIGHTCONTROL.ALL_ON)];
        }

    };
});
