'use strict';

define([], function () {
    return {
        GateAnimationType: {
            GARAGE_DOOR: 0,
            TURN_DOOR_LEFT: 1,
            TURN_DOOR_RIGHT: 2,
            TURN_DOOR_DOUBLE: 3,
            SECTIONAL_DOOR_LEFT: 4,
            SECTIONAL_DOOR_RIGHT: 5
        }
    };
});
