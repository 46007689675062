'use strict';

define("IRoomControlStateContainer", ["ControlStateContainer", 'IRoomControlEnums'], function (ControlStateContainer, IRoomControlEnums) {
    class IRoomControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
            this.heatingTimer = this.control.subControls[this.control.getDaytimerUuidFromIRoomControl(true)];
            this.coolingTimer = this.control.subControls[this.control.getDaytimerUuidFromIRoomControl(false)];
        }

        getUUIDsToRegister() {
            // get all uuid's from IRoomControl
            var uuids = super.getUUIDsToRegister();
            uuids.push(this.heatingTimer.states.entriesAndDefaultValue);
            uuids.push(this.heatingTimer.states.mode);
            uuids.push(this.coolingTimer.states.entriesAndDefaultValue);
            uuids.push(this.coolingTimer.states.mode);
            return uuids;
        }

        prepareStates(newVals) {
            // current temperature values
            this.states.tempActual = newVals[this.control.states.tempActual];
            this.states.tempTarget = newVals[this.control.states.tempTarget]; // heating or cooling phase, big difference between actual and target

            this.states.error = newVals[this.control.states.error]; // active mode

            this.states.controllerMode = newVals[this.control.states.mode];
            this.states.serviceMode = newVals[this.control.states.serviceMode]; // temperatures

            var temperatureResult = IRoomControlStateContainer.prepareTemperaturesFromStates(newVals, this.control, this.control.isHeating(this.states.controllerMode), this.control.isCooling(this.states.controllerMode));
            this.states.temperatures = temperatureResult.temperatures;
            this.states.activeTemperatures = temperatureResult.activeTemperatures; // current temperature index and daytimer info - information depending on the current controller Mode (heating/cooling)

            var daytimerEntries;

            if (this.control.isHeating(this.states.controllerMode)) {
                this.states.activeTempMode = newVals[this.control.states.currHeatTempIx];
                this.states.activeOperatingMode = newVals[this.heatingTimer.states.mode];
                daytimerEntries = newVals[this.heatingTimer.states.entriesAndDefaultValue].entries;
            } else if (this.control.isCooling(this.states.controllerMode)) {
                this.states.activeTempMode = newVals[this.control.states.currCoolTempIx];
                this.states.activeOperatingMode = newVals[this.coolingTimer.states.mode];
                daytimerEntries = newVals[this.coolingTimer.states.entriesAndDefaultValue].entries;
            } else {
                this.states.activeTempMode = null;
                this.states.activeOperatingMode = null;
            }

            if (this.states.activeOperatingMode !== null) {
                this.states.activeOperatingModeName = SandboxComponent.getStructureManager().getOperatingModes(this.states.activeOperatingMode);
            } // entries for active operating mode and active entry (currentTimeSpan)


            if (this.states.activeTempMode !== null && this.states.activeOperatingMode !== null) {
                this.states.timeLineEntries = this._prepareTimeLineEntries(daytimerEntries);
            } else {
                // when no heating or cooling period is active
                this.states.timeLineEntries = [];
            }

            if (this.states.activeTempMode === IRoomControlEnums.IRCTempMode.MANUAL) {
                this.states.currentTimeSpan = {
                    from: 0,
                    to: 0,
                    tempMode: IRoomControlEnums.IRCTempMode.MANUAL,
                    targetTemp: this.states.tempTarget
                };
            } else if (this.states.activeTempMode !== null) {
                var currentTimeSpan = this._getCurrentTimeSpanInformation();

                if (currentTimeSpan.tempMode !== this.states.activeTempMode) {
                    currentTimeSpan = {
                        from: 0,
                        to: 0,
                        tempMode: this.states.activeTempMode,
                        targetTemp: this.states.tempTarget
                    };
                }

                this.states.currentTimeSpan = currentTimeSpan;
            } else {
                this.states.currentTimeSpan = this._getCurrentTimeSpanInformation();
            } // timer - comparable with the manual mode in the daytimer
            //var totalTime = newVals[this.control.states.overrideTotal];
            //this.states.remainingPercent = newVals[this.control.states.override] / totalTime; // not used  at this time


            this.states.remainingTime = newVals[this.control.states.override]; // info icon in the header and user info in the detail (time line)

            this.states.preparationPhase = this._detectPreparingPhase(newVals[this.control.states.isPreparing], this.states.controllerMode);
            var openWindow = newVals[this.control.states.openWindow] === 1;
            var doorOrManual;

            if (Feature.IRC_MANUAL_MODE_STATE) {
                doorOrManual = newVals[this.control.states.manualMode];
            } else {
                doorOrManual = newVals[this.control.states.movement];
            }

            this.states.userInfo = this._detectUserInfoFromStates(this.states, openWindow, doorOrManual);
            this.states.logicOverride = this._detectLogicOverride();
            this.states.isInManualMode = this.states.activeTempMode === IRoomControlEnums.IRCTempMode.MANUAL;

            this._detectMinMaxTemp(); // config 9.0+ have a stop state, which corresponds to the stop input on the IRC


            if (Feature.IRC_STOP) {
                this.states.stopActive = newVals[this.control.states.stop];
            } else {
                this.states.stopActive = false;
            }
        }

        getStateText() {
            if (!this.states.serviceMode) {
                return lxFormat(this.control.details.format, this.states.tempActual);
            } else {
                return _("controls.irc.mode.service.info");
            }
        }

        getStateTextShort() {
            if (this.states.logicOverride) {
                return _("controls.irc.logicOverride");
            } else if (!this.states.serviceMode && this.states.currentTimeSpan) {
                return this.control.getNameOfIRCTempMode(this.states.activeTempMode);
            } else if (this.states.serviceMode) {
                return this._getServiceModeText();
            }
        }

        getStateTextForContent() {
            var targetTemp = lxFormat(this.control.details.format, this.states.tempTarget),
                txt;

            if (this.states.serviceMode) {
                return _("controls.irc.mode.service.info");
            } else if (this.states.logicOverride) {
                return null;
            } else if (this.states.activeTempMode === IRoomControlEnums.IRCTempMode.MANUAL) {
                txt = _("controls.irc.manual.target-temp");
            } else {
                txt = this.control.getNameOfIRCTempMode(this.states.activeTempMode);
            }

            if (this.states.userInfo === IRoomControlEnums.UserInfo.NO_PERIOD_ACTIVE) {
                return null;
            } else {
                return txt + " (" + targetTemp + ")";
            }
        }

        getStateTextColor() {
            if (this.states.serviceMode) {
                return window.Styles.colors.red;
            } else if (this.states.userInfo !== IRoomControlEnums.UserInfo.NO_PERIOD_ACTIVE && this.states.currentTimeSpan && !this.states.logicOverride) {
                return this.control.getColorOfIRCTempMode(this.states.currentTimeSpan.tempMode);
            }
        }

        getStateIcon() {
            return this._generateTempSvg();
        }

        getStateIconSmall() {
            if (this.states.stopActive) {
                return {
                    iconSrc: Icon.CAUTION,
                    color: window.Styles.colors.red
                };
            } else if (this.states.serviceMode) {
                return {
                    iconSrc: "resources/Images/General/wrench.svg",
                    color: window.Styles.colors.red
                };
            } else if (this.states.logicOverride) {
                return {
                    iconSrc: Icon.INFO2,
                    color: window.Styles.colors.orange
                };
            } else if (this.states.userInfo === IRoomControlEnums.UserInfo.NOTHING && this.states.currentTimeSpan) {
                return {
                    iconSrc: this.control.getIconOfIRCTempMode(this.states.activeTempMode),
                    color: this.control.getColorOfIRCTempMode(this.states.activeTempMode)
                };
            } else {
                var iconSrc,
                    color = window.Styles.colors.red; // get icon

                switch (this.states.userInfo) {
                    case IRoomControlEnums.UserInfo.ACTIVE_TIMER:
                        iconSrc = "resources/Images/Controls/IRoom/icon-stop-watch.svg";
                        break;

                    case IRoomControlEnums.UserInfo.OPEN_WINDOW:
                        iconSrc = "resources/Images/Controls/IRoom/window-state.svg";
                        break;

                    case IRoomControlEnums.UserInfo.COMFORT_TIMER:
                    case IRoomControlEnums.UserInfo.ECONOMY_TIMER:
                        iconSrc = "resources/Images/Controls/IRoom/icon-stop-watch.svg";
                        break;

                    case IRoomControlEnums.UserInfo.HAS_PRESENCE:
                        iconSrc = "resources/Images/Controls/IRoom/presence-state.svg";
                        break;

                    default:
                        iconSrc = "resources/Images/General/Notifications/icon-error.svg";
                        break;
                } // get color


                switch (this.states.userInfo) {
                    case IRoomControlEnums.UserInfo.ACTIVE_TIMER:
                        color = window.Styles.colors.purple;
                        break;

                    case IRoomControlEnums.UserInfo.OPEN_WINDOW:
                    case IRoomControlEnums.UserInfo.COMFORT_TIMER:
                    case IRoomControlEnums.UserInfo.ECONOMY_TIMER:
                    case IRoomControlEnums.UserInfo.HAS_PRESENCE:
                        color = this.control.getColorOfIRCTempMode(this.states.currentTimeSpan.tempMode);
                        break;
                }

                return {
                    iconSrc: iconSrc,
                    color: color
                };
            }
        }

        getStateInfo() {
            var info = null,
                activeTempName = this.control.getNameOfIRCTempMode(this.states.activeTempMode),
                activeTempTxt = _("controls.irc.temp-active", {
                    tempName: activeTempName
                });

            if (this.states.stopActive) {
                info = this._createStateInfo(_("controls.irc.stopActive"), null, window.Styles.colors.red);
            } else if (this.states.serviceMode) {
                info = this._createServiceStateModeInfo();
            } else if (this.states.logicOverride) {
                info = this._createLogicOverrideInfo();
            } else {
                switch (this.states.userInfo) {
                    case IRoomControlEnums.UserInfo.OPEN_WINDOW:
                        info = this._createStateInfo(_("controls.irc.exception.open-window-text", {
                            temp: activeTempName
                        }));
                        break;

                    case IRoomControlEnums.UserInfo.HAS_PRESENCE:
                        info = this._createStateInfo(_("controls.irc.exception.presence-detected"));
                        break;

                    case IRoomControlEnums.UserInfo.COMFORT_TIMER:
                    case IRoomControlEnums.UserInfo.ECONOMY_TIMER:
                        // comfort or econ temp are activated via object inputs.
                        if (this.states.remainingTime > 0) {
                            info = this._createTimerEndInfo();
                        } else {
                            // timer not running yet, comfort or economy temp permanently activate right now.
                            info = this._createStateInfo(activeTempTxt);
                        }

                        break;

                    case IRoomControlEnums.UserInfo.ACTIVE_TIMER:
                        info = this._createTimerEndInfo();
                        break;

                    case IRoomControlEnums.UserInfo.NO_PERIOD_ACTIVE:
                        info = this._createStateInfo(_("controls.irc.exception.no-operating-mode.subtitle"), _("controls.irc.exception.no-operating-mode.title"));
                        break;

                    case IRoomControlEnums.UserInfo.BIG_DIFF_ACTUAL_TARGET:
                        info = this._createStateInfo(_("controls.irc.exception.big-temp-diff"), activeTempTxt);
                        break;

                    case IRoomControlEnums.UserInfo.HEATING_PHASE:
                        info = this._createStateInfo(_("controls.irc.exception.heating-reason"), _("controls.irc.exception.heating-active"));
                        break;

                    case IRoomControlEnums.UserInfo.COOLING_PHASE:
                        info = this._createStateInfo(_("controls.irc.exception.cooling-reason"), _("controls.irc.exception.cooling-active"));
                        break;
                }
            }

            return info;
        }

        _createLogicOverrideInfo() {
            return this._createStateInfo(_("controls.irc.logicOverride"));
        }

        _createServiceStateModeInfo() {
            return this._createStateInfo(this._getServiceModeText());
        }

        _getServiceModeText() {
            var txt = '';

            switch (this.states.serviceMode) {
                case IRoomControlEnums.ServiceMode.HEATING_OFF_COOLING_OFF:
                    txt = _("controls.irc.service.no-heat-no-cool");
                    break;

                case IRoomControlEnums.ServiceMode.HEATING_OFF_COOLING_ON:
                    txt = _("controls.irc.service.no-heat-cool");
                    break;

                case IRoomControlEnums.ServiceMode.HEATING_ON_COOLING_OFF:
                    txt = _("controls.irc.service.heat-no-cool");
                    break;

                case IRoomControlEnums.ServiceMode.HEATING_ON_COOLING_ON:
                    txt = _("controls.irc.service.heat-cool");
                    break;
            }

            return txt;
        }

        /**
         * Will return a stateInfo object for a running timer.
         * @return {*|{message, title, color}}
         * @private
         */
        _createTimerEndInfo() {
            var arg = {},
                info,
                remaining = this.states.remainingTime;
            var msTimeObj = SandboxComponent.getMiniserverTimeInfo(),
                endObj = SandboxComponent.getMiniserverTimeInfo().add(remaining, 'seconds');
            arg.time = endObj.format(DateType.TimeFormat);
            arg.date = endObj.format(msTimeObj.isSame(endObj, "year") ? DateType.DateTextNoYear : DateType.DateText);

            if (msTimeObj.isSame(endObj, "day")) {
                info = this._createStateInfo(_("controls.irc.timer.active-until.time", arg));
            } else {
                info = this._createStateInfo(_("controls.irc.timer.active-until.date", arg));
            }

            return info;
        }

        _generateTempSvg() {
            var temp = this.states.tempActual;

            if (typeof temp !== "number") {
                temp = 0;
            }

            return generateValueSvg(temp);
        }

        _prepareTimeLineEntries(daytimerEntries) {
            var i; // get all entries for mode

            var entries = [];

            if (daytimerEntries) {
                // If neither the cooling or the heating mode is active, there are no entries!
                for (i = 0; i < daytimerEntries.length; i++) {
                    if (daytimerEntries[i].mode === this.states.activeOperatingMode) {
                        daytimerEntries[i].tempMode = daytimerEntries[i].value;
                        daytimerEntries[i].targetTemp = this.states.activeTemperatures[daytimerEntries[i].tempMode];
                        delete daytimerEntries[i].value;
                        delete daytimerEntries[i].nr;
                        delete daytimerEntries[i].needActivate;
                        entries.push(daytimerEntries[i]);
                    }
                }
            } // add entries between for economy temperature gaps


            var allEntries = [];
            var economyIndex = IRoomControlEnums.IRCTempMode.ECONOMY;

            for (i = 0; i < entries.length; i++) {
                // check first
                if (i === 0 && allEntries.length === 0) {
                    if (entries[i].from !== 0) {
                        allEntries.push({
                            from: 0,
                            to: entries[i].from,
                            tempMode: economyIndex,
                            targetTemp: this.states.activeTemperatures[economyIndex]
                        });
                        i--;
                    } else {
                        allEntries.push(entries[i]);
                    }
                } else {
                    var lastEndTime = allEntries[allEntries.length - 1].to;

                    if (lastEndTime !== entries[i].from) {
                        allEntries.push({
                            from: lastEndTime,
                            to: entries[i].from,
                            tempMode: economyIndex,
                            targetTemp: this.states.activeTemperatures[economyIndex]
                        });
                    }

                    allEntries.push(entries[i]);
                }
            }

            if (!entries.length || allEntries.length && allEntries[allEntries.length - 1].to !== 24 * 60) {
                var lastEntry = {
                    to: 24 * 60,
                    tempMode: economyIndex,
                    targetTemp: this.states.activeTemperatures[economyIndex]
                };

                if (!entries.length) {
                    lastEntry.from = 0;
                } else {
                    lastEntry.from = allEntries[allEntries.length - 1].to;
                }

                allEntries.push(lastEntry);
            }
            /*console.log(' ');
             console.log(this.control.name);
             for (var j = 0; j < allEntries.length; j++) {
             console.log(allEntries[j].from + '-' + allEntries[j].to + ', ')
             }
             console.log(' ');*/


            return allEntries;
        }

        _getCurrentTimeSpanInformation() {
            var minutesSinceMidnight = SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINUTES_SINCE_MIDNIGHT);
            var res;

            for (var i = 0; i < this.states.timeLineEntries.length; i++) {
                var entry = this.states.timeLineEntries[i]; // entry from must be greater than minutes since midnight or minutes since midnight plus one (time difference
                // and the value of the entry must match with the current value, to get sure that this is the correct entry

                if (entry.from <= minutesSinceMidnight && entry.to > minutesSinceMidnight) {
                    // this is the currentEntry
                    res = entry;
                    break;
                } else if (entry.from > minutesSinceMidnight) {
                    // there is a gap between entries - calculate it and create dummy entry
                    res = {
                        to: entry.from,
                        tempMode: IRoomControlEnums.IRCTempMode.ECONOMY,
                        targetTemp: this.states.activeTemperatures[IRoomControlEnums.IRCTempMode.ECONOMY]
                    };

                    if (i === 0) {
                        res.from = 0;
                    } else {
                        res.from = this.states.timeLineEntries[i - 1].to;
                    }

                    break;
                }
            }

            return res;
        }

        _detectUserInfoFromStates(states, openWindow, doorOrManual) {
            if (states.activeTempMode === null && states.activeOperatingMode === null) {
                //  neither in heating nor in cooling period
                return IRoomControlEnums.UserInfo.NO_PERIOD_ACTIVE;
            } else if (states.tempActual > states.activeTemperatures[IRoomControlEnums.IRCTempMode.HEAT_PROTECTION] && states.error) {
                // the current temperature is greater than the heat-Protection temp --> and we've got an error
                return IRoomControlEnums.UserInfo.COOLING_PHASE; // the IRC will use the cooling outputs regardless if he is in the cooling phase or not
            } else if (states.tempActual < states.activeTemperatures[IRoomControlEnums.IRCTempMode.EMPTY_HOUSE] && states.error) {
                // the current temperature is lower than the empty-house (aka frost-Protection) temp --> and we've got an error
                return IRoomControlEnums.UserInfo.HEATING_PHASE; // the IRC will use the heating outputs regardless if he is in the heating phase or not
            } else if (states.error) {
                return IRoomControlEnums.UserInfo.BIG_DIFF_ACTUAL_TARGET;
            }

            if (Feature.IRC_MANUAL_MODE_STATE) {
                if (doorOrManual === IRoomControlEnums.ManualMode.TIMER) {
                    return IRoomControlEnums.UserInfo.ACTIVE_TIMER;
                } else if (openWindow) {
                    return IRoomControlEnums.UserInfo.OPEN_WINDOW;
                } else if (doorOrManual === IRoomControlEnums.ManualMode.COMFORT) {
                    return IRoomControlEnums.UserInfo.COMFORT_TIMER;
                } else if (doorOrManual === IRoomControlEnums.ManualMode.ECONOMY) {
                    return IRoomControlEnums.UserInfo.ECONOMY_TIMER;
                } else if (doorOrManual === IRoomControlEnums.ManualMode.MOVEMENT) {
                    return IRoomControlEnums.UserInfo.HAS_PRESENCE;
                }
            } else {
                if (states.remainingTime > 0) {
                    return IRoomControlEnums.UserInfo.ACTIVE_TIMER;
                } else if (openWindow) {
                    return IRoomControlEnums.UserInfo.OPEN_WINDOW;
                } else if (doorOrManual) {
                    return IRoomControlEnums.UserInfo.HAS_PRESENCE;
                }
            }

            return IRoomControlEnums.UserInfo.NOTHING;
        }

        _detectPreparingPhase(prepareState, controllerMode) {
            if (prepareState) {
                if (this.control.isHeating(controllerMode)) {
                    return IRoomControlEnums.PreparationPhase.HEATING_UP;
                } else if (this.control.isCooling(controllerMode)) {
                    return IRoomControlEnums.PreparationPhase.COOLING_DOWN;
                }
            } else {
                return IRoomControlEnums.PreparationPhase.NOT_PREPARING;
            }
        }

        _detectMinMaxTemp() {
            // detect max and min temp
            var minTemp = Number.MAX_VALUE,
                maxTemp = Number.MIN_VALUE,
                entries = this.states.timeLineEntries,
                temperatures = this.states.activeTemperatures;

            if (entries.length > 1) {
                for (var i = 0; i < entries.length; i++) {
                    var temperature = temperatures[entries[i].tempMode];
                    minTemp = Math.min(minTemp, temperature);
                    maxTemp = Math.max(maxTemp, temperature);
                }
            } else if (entries.length === 1) {
                maxTemp = temperatures[entries[0].tempMode];
                minTemp = 0;
            }

            this.states.maxTemp = maxTemp;
            this.states.minTemp = minTemp;
        }

        /**
         * Will return true if the controller is overridden by logic on one of the inputs (Mv, Is, Ic). If one of
         * those is on and remains on, this method will return true.
         * @return {boolean}
         * @private
         */
        _detectLogicOverride() {
            var potential = false;

            switch (this.states.userInfo) {
                case IRoomControlEnums.UserInfo.COMFORT_TIMER:
                case IRoomControlEnums.UserInfo.ECONOMY_TIMER:
                case IRoomControlEnums.UserInfo.ACTIVE_TIMER:
                    potential = true;
                    break;
            }

            return potential && this.states.remainingTime <= 0;
        }

    }

    IRoomControlStateContainer.prepareTemperaturesFromStates = function prepareTemperaturesFromStates(states, control, isHeating, isCooling) {
        var temperatures = {},
            activeTemperatures = {};

        for (var i = 0; i < control.states.temperatures.length; i++) {
            if (control.details.temperatures[i].isAbsolute) {
                temperatures[i] = states[control.states.temperatures[i]];

                if (isHeating || isCooling) {
                    activeTemperatures[i] = states[control.states.temperatures[i]];
                }
            } else {
                temperatures[i] = {
                    offset: states[control.states.temperatures[i]]
                };
            }
        }

        var comfortHeating = temperatures[IRoomControlEnums.IRCTempMode.COMFORT_HEATING];
        var comfortCooling = temperatures[IRoomControlEnums.IRCTempMode.COMFORT_COOLING];

        for (var tempMode in control.details.temperatures) {
            if (control.details.temperatures.hasOwnProperty(tempMode)) {
                if (!control.details.temperatures[tempMode].isAbsolute) {
                    var heatingValue, coolingValue;

                    if (parseInt(tempMode) === IRoomControlEnums.IRCTempMode.ECONOMY) {
                        heatingValue = comfortHeating - temperatures[tempMode].offset;
                        coolingValue = comfortCooling + temperatures[tempMode].offset;
                    } else if (parseInt(tempMode) === IRoomControlEnums.IRCTempMode.PARTY) {
                        heatingValue = comfortHeating - temperatures[tempMode].offset;
                        coolingValue = comfortCooling - temperatures[tempMode].offset;
                    } else if (parseInt(tempMode) === IRoomControlEnums.IRCTempMode.INCREASED_HEAT) {
                        heatingValue = comfortHeating + temperatures[tempMode].offset;
                        coolingValue = comfortCooling + temperatures[tempMode].offset;
                    }

                    temperatures[tempMode].heating = heatingValue;
                    temperatures[tempMode].cooling = coolingValue;

                    if (isHeating) {
                        activeTemperatures[tempMode] = heatingValue;
                    } else if (isCooling) {
                        activeTemperatures[tempMode] = coolingValue;
                    }
                }
            }
        }

        return {
            temperatures: temperatures,
            activeTemperatures: activeTemperatures
        };
    };

    return IRoomControlStateContainer;
});
