'use strict';

define([], function () {
    return class MiniserverSearchScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div />'));
            this.miniservers = details && details.miniservers || [];
            this.listeners = [];
            this.isSearching = false;
            this.networkStatus = PlatformComponent.getNetworkStatus().status;
            this._shouldAutoStart = false;
        }

        viewDidLoad() {
            var platformObject = PlatformComponent.getPlatformInfoObj(),
                versionArray = platformObject.version.split(".");
            super.viewDidLoad(...arguments);
            this.setUpComfortUI(true);
            this.listeners.push(MiniserverFinder.on(MiniserverFinder.Event.NEW_SEARCH_STARTED, this._searchStarted.bind(this)));
            this.listeners.push(MiniserverFinder.on(MiniserverFinder.Event.FOUND_MINISERVER, this._foundMiniserver.bind(this)));
            this.listeners.push(MiniserverFinder.on(MiniserverFinder.Event.SEARCH_FINISHED, this._finished.bind(this)));
            this.listeners.push(CompChannel.on(CCEvent.NetworkChanged, this.onNetworkChanged.bind(this)));
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);

            if (!this.miniservers || this.miniservers.length === 0) {
                this._startSearch();
            }
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments);
            this.setTitle(_('miniserverlist.add.search.title'));
            this.setIcon(Icon.SEARCH);
        }

        destroy() {
            this._cancelSearch(); // unregister


            this.listeners.forEach(function (removeFn) {
                removeFn();
            });
            this.listeners = [];
            return super.destroy(...arguments);
        }

        reloadTable() {
            this.tableContent = this.getTableContent();
            return super.reloadTable(...arguments);
        }

        onNetworkChanged(event, status) {
            this.networkStatus = status;

            if (status === NetworkStatus.LAN) {
                if (this._shouldAutoStart) {
                    this._startSearch();
                }
            } else {
                this._cancelSearch();

                this._shouldAutoStart = true;
            }
        }

        getTableContent() {
            var actionSection = {
                    rows: []
                },
                deviceSection = {
                    rows: []
                };
            actionSection.rows.push(this._createSearchActionCell());

            if (this.networkStatus !== NetworkStatus.LAN) {
                deviceSection.rows.push({
                    content: {
                        title: _("miniserverlist.add.search.no-lan-connection"),
                        clickable: false
                    },
                    type: GUI.TableViewV2.CellType.CENTER
                });
            } else if (this.miniservers.length > 0 || this.isSearching) {
                this.miniservers.sort(function (a, b) {
                    if (typeof a.msName === "string" && typeof b.msName === "string") {
                        return a.msName.localeCompare(b.msName);
                    } else {
                        return false;
                    }
                });
                this.miniservers.forEach(function (ms) {
                    var content = {
                        title: ms.msName,
                        subtitle: ms.localUrl,
                        clickable: true
                    };
                    deviceSection.rows.push({
                        content: content,
                        action: this._handleMsTap.bind(this, ms)
                    });
                }.bind(this));
            } else {
                deviceSection.rows.push({
                    content: {
                        title: _("miniserverlist.add.search.no-devices-found"),
                        clickable: false
                    },
                    type: GUI.TableViewV2.CellType.CENTER
                });
            }

            return [actionSection, deviceSection];
        }

        getAnimation() {
            return AnimationType.FADE;
        }

        styleForTableView() {
            return TableViewStyle.TRANSLUCENT;
        }

        /**
         * Overwritten, see enterUrlScreen for details on why to overwrite it..
         * PLUS: the search needs to be stopped!
         **/
        closeAction() {
            MiniserverFinder.stopMiniserverFinder();
            if (PersistenceComponent.hasMiniservers()) {
                this.ViewController.navigateBack();
            } else {
                this.ViewController.navigateBack();
                this.ViewController.navigateBack();
            }
        }

        // Private methods
        _handleMsTap(ms) {
            // clone, as otherwise credentials are stored on these objects. If the attempt fails and it is retried,
            // it would always reuse these credentials.
            NavigationComp.connectTo(cloneObject(ms));
        }

        _createSearchActionCell() {
            var title, action;

            if (this.isSearching) {
                title = _("miniserverlist.add.search.cancel");
                action = this._cancelSearch.bind(this);
            } else {
                title = _("miniserverlist.add.search.retry");
                action = this._startSearch.bind(this);
            }

            return {
                type: GUI.TableViewV2.CellType.CENTER,
                content: {
                    title: title,
                    active: true,
                    clickable: true,
                    disabled: this.networkStatus !== NetworkStatus.LAN
                },
                action: action
            };
        }

        _cancelSearch() {
            if (this.isSearching) {
                MiniserverFinder.stopMiniserverFinder();

                this._searchEnded();
            }
        }

        _startSearch() {
            if (!this.isSearching) {
                MiniserverFinder.startMiniserverFinder(true);
            }
        }

        // Callbacks
        _searchStarted() {
            this.isSearching = true;
            this.miniservers = [];
            this.setSmallIcon({
                iconSrc: Icon.INDICATOR
            });
            this.reloadTable();
        }

        _foundMiniserver(ev, newMiniservers, allMiniservers) {
            this.miniservers = allMiniservers;
            clearTimeout(this.reloadTimeout);
            this.reloadTimeout = setTimeout(this.reloadTable.bind(this), 100);
        }

        _finished(ev, miniservers) {
            this.miniservers = miniservers;

            this._searchEnded();
        }

        _searchEnded() {
            this.isSearching = false;
            this.setSmallIcon();
            clearTimeout(this.reloadTimeout);
            this.reloadTable();
        }

    };
});
