'use strict';

define("SaunaControlCommandSrc", ["ControlCommandSrc", "SaunaControlEnums"], function (ControlCommandSrc, SaunaControlEnums) {
    return class SaunaControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states, navigation) {
            let cmds = [];

            if (!states.isActive && !states.saunaError && control.details.hasVaporizer) {
                cmds.push(this.createCommand(_("controls.sauna.start"), this.showSaunaStartSelector.bind(this, control, navigation), null))
            } else if (states.fanActive) {
                cmds.push(this.createCommand(_("controls.sauna.airing.end"), Commands.SAUNA.NEXT_MODE, null));
            } else if (states.drying) {
                cmds.push(this.createCommand(_("controls.sauna.drying.end"), Commands.SAUNA.NEXT_MODE, null));
            } else if (states.isActive) {
                // (re-)start timer.
                cmds.push(this.createCommand(states.timerActive ? _("controls.sauna.timer.restart") : _("controls.sauna.timer.start"), Commands.SAUNA.START_TIMER, null)); // end sauna

                cmds.push(this.createCommand(_("controls.sauna.turn-off"), Commands.SAUNA.NEXT_MODE, null));
            } else if (!states.saunaError) {
                // it's not active.
                cmds.push(this.createCommand(_("controls.sauna.turn-on"), Commands.SAUNA.ON, null));
            }

            return cmds;
        }

        showSaunaStartSelector(control, navigation) {
            let options = [],
                def = Q.defer(),
                candidates = Object.values(SaunaControlEnums.SaunaModes); // prepare the options = profiles

            for (let i = 0; i < candidates.length; i++) {
                if (candidates[i].nr >= 0) {
                    options.push(this.createStartSelectorOption(candidates[i]));
                }
            } // prepare the details for the selector screen


           let details = {
                options: options,
                title: control.name,
                subtitle: _("controls.sauna.choose-program"),
                mode: GUI.ComfortModeSelectorScreenMode.QUICK,
                deferred: def,
                noIcon: true
            }; // create a promise, this is used to wait until the sauna is turned on, after a mode is selected.. otherwise the UI would jump around
            navigation.navigate(ScreenState.ComfortModeSelectorScreen, details); // take action after a profile was picked.

            def.promise.then((result) => {
                let modeNr = options[result[0].row].item.nr;

                control.sendCommand(Commands.format(Commands.SAUNA.MODE, modeNr));

                control.sendCommand(Commands.SAUNA.ON);
            }, (err) => {
            });
        }
        /**
         * Creates an option for the program start selector. An option is a mode in which to start the sauna.
         * @param candidate
         * @return {{title, subtitle: *, clickable: boolean, item: *}}
         * @private
         */
        createStartSelectorOption(candidate) {
            let descr = null;

            if (candidate.temp || candidate.humidity && candidate.humidity > 0) {
                descr = this.getTempText(candidate.temp);
                descr += ", " + this.getHumidityText(candidate.humidity);
            }

            return {
                title: candidate.name,
                subtitle: descr,
                clickable: true,
                item: candidate
            };
        }

        /**
         * Returns the description for a sauna modes temperature setting (e.g. Temperature: 60° or Temperature: Manual)
         * @param value
         * @return {string}
         * @private
         */
        getTempText(value) {
            let txt = _("temperature") + ": ";

            if (typeof value === "string") {
                txt += _("manually");
            } else if (!value) {
                txt += _("off");
            } else {
                txt += lxFormat("%d°", SandboxComponent.getTemperatureForUI(value));
            }

            return txt;
        }

        /**
         * Returns the description for a sauna modes temperature setting (e.g. Humidity: 60% or Humidity: Off)
         * @param value
         * @return {string}
         * @private
         */
        getHumidityText(value) {
            let txt = _("humidity") + ": ";

            if (typeof value === "string") {
                txt += _("manually");
            } else if (!value) {
                txt += _("off");
            } else {
                txt += lxFormat("%d%", value);
            }

            return txt;
        }

    };
});
