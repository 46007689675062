import {useEffect, useRef} from 'react';
import globalStyles from "GlobalStyles";
import MsLogin from "../../../react-comps/PairedApp/MsLogin";
import useIsPairedApp from "../../../react-comps/PairedApp/useIsPairedApp";
import PairedTabletWarning, {PairedTabletWarningOrigin} from "../../../react-comps/PairedApp/PairedTabletWarning";


export default function CredentialsScreen(props) {
    const {
            miniserver,
            lastPwdChange,
            loginRetryCount,
            affectedToken
        } = props.route.params;
    const loginState = props.route.params.state;

    const handleSubmit = (user, pass) => {
        miniserver.activeUser = user;
        miniserver.password = VendorHub.Crypto.encrypt(pass);
        NavigationComp.connectTo(miniserver);
        let connEst = CompChannel.on(CCEvent.ConnEstablished, () => {
            if (pass && BiometricHelper.hasEnrolledBiometrics && !PersistenceComponent.getBiometricTokenForSecretType(BiometricHelper.SecretType.UserPw, miniserver.serialNo, miniserver.activeUser)) {
                BiometricHelper.setSecretOfType(pass, BiometricHelper.SecretType.UserPw, miniserver);
            }
            connEst()
        });
    }

    // shows popups when the loginState changes
    useEffect(() => {
        switch (loginState) {
            case LoginState.ON_USER_CHANGED:
                handleOnUserChanged();
                break;
            case LoginState.ON_THIS_USER_CHANGED:
                deleteUserStructure();
                break;
            case LoginState.USER_IS_DISABLED:
                handleDisabledUser();
                break;
            default:
                break;
        }
    }, [loginState])

    // region User Status Popups

    // region popup handling
    const popupPromiseRef = useRef(null);
    const showPopupTimeoutRef = useRef(null);

    useEffect(() => {
        return () => {
            clearTimeout(showPopupTimeoutRef.current);
            popupPromiseRef.current && NavigationComp.removePopup(popupPromiseRef.current);
        }
    }, [])

    const _handleShowPopup = (content, resolveFn) => {
        // avoid showing duplicate popups
        if (showPopupTimeoutRef.current || (popupPromiseRef.current && popupPromiseRef.current.isPending())) {
            return;
        }

        let popupDef = Q.defer();
        popupPromiseRef.current = popupDef.promise;

        // use a timeout, otherwise the screen won't render properly.
        showPopupTimeoutRef.current = setTimeout(() => {
            popupDef.resolve(NavigationComp.showPopup(content, PopupType.GENERAL));
        }, 50);

        // clean up once the popup has been confirmed or dismissed
        popupPromiseRef.current.finally(() => {
            popupPromiseRef.current = null;
            showPopupTimeoutRef.current = null;
        });

        return popupDef.promise.then(() => {
            resolveFn && resolveFn();
        });
    }

    // endregion

    const handleOnUserChanged = () => {
        _handleShowPopup({
            title: _('miniserver.credentials.user-changed.title'),
            message: _('miniserver.credentials.user-changed.message', {
                username: miniserver.activeUser
            }),
            buttonOk: _('ok.short'),
            buttonCancel: false,
            icon: Icon.CAUTION,
            color: globalStyles.colors.orange
        }, deleteUserStructure);
    }

    const handleDisabledUser = () => {
        _handleShowPopup({
            title: _('user.disabled.title'),
            message: _('user.disabled.message', {
                username: miniserver.activeUser
            }),
            buttonOk: _('ok.short'),
            buttonCancel: false,
            icon: Icon.CAUTION,
            color: globalStyles.colors.orange
        });
    }

    const deleteUserStructure = () => {
        PersistenceComponent.deleteStructureForUser(
            miniserver.serialNo,
            CommunicationComponent.getCurrentReachMode(),
            miniserver.activeUser);
    }

    // endregion

    const isPairedApp = useIsPairedApp();
    if (isPairedApp) {
        return <PairedTabletWarning
            origin={PairedTabletWarningOrigin.CREDENTIALS}
            details={
                {
                    loginState,
                    lastPwdChange,
                    loginRetryCount,
                    remainingBlockedTime: props.route.params.remainingBlockedTime
                }
            }/>
    }

    return <MsLogin
        remainingBlockedTime={props.route.params.remainingBlockedTime}
        miniserver={miniserver}
        lastPwdChange={lastPwdChange}
        loginRetryCount={loginRetryCount}
        affectedToken={affectedToken}
        loginState={loginState}
        onSubmit={handleSubmit}
    />
};