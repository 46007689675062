'use strict';

define([], function () {
    return {
        // Details
        Capabilities: {
            NONE: 0,
            HEATING: 1,
            COOLING: 2,
            EMERGENCY_HEAT: 4,
            FAN: 8, // Since 15.1
            SWITCH_ON_THRESHOLD: 16, // Since 15.1
            SERVICE_MODE: 32 // Since 15.1
        },
        ExcessEnergy: { // Since 15.1
            NONE: 0,
            EMERGENCY_HEAT: 1,
            EMERGENCY_COOL: 2
        },
        ServiceModeSettings: { // Since 15.1
            OFF: 0,
            STANDBY: 1,
            HEATING: 2,
            COOLING: 3,
            FAN: 4,
        },
        ConnectedInputs: {
            NONE: 0,
            MODE: 1,
            OUTSIDE_TEMP: 2,
            BOOST: 4,
            STOP: 8,
            EMERGENCY_HEATER: 16,
            ACTUAL_HUMIDITY: 32
        },
        ConnectedParameters: {
            NONE: 0,
            MIN_OFF_TIME: 1,
            ON_THRESHOLD: 2,
            FAN_OVERRUN_TIME: 4,
            SECOND_STAGE_TIME: 8,
            TEMP_SECOND_STAGE: 16,
            HEATING_LIMIT: 32,
            COOLING_LIMIT: 64,
            HUMIDITY_TARGET: 128,
        },
        ConnectedOutputs: {
            NONE: 0,
            HEAT_STAGE_1: 1,
            HEAT_STAGE_2: 2,
            COMPRESSOR: 4,
            COOL_STAGE_2: 8,
            HEAT_EMERGENCY: 16,
            REVERSING_VALVE: 32,
            FAN: 64,
            HUMIDIFIER: 128
        },
        // Commands / States
        Mode: {
            OFF: 0,
            HEATING_AND_COOLING: 1,
            HEATING_ONLY: 2,
            COOLING_ONLY: 3,
        },
        // Bitmask for all state infos
        CurrentStatus: {
            HEATING_STAGE_1: 1,
            HEATING_STAGE_2: 2,
            HEATING_EMERGENCY: 4,
            COOLING_STAGE_1: 8,
            COOLING_STAGE_2: 16,
            SWITCHING_TO_COOL: 32,
            SWITCHING_TO_HEAT: 64,
            SWITCHING_DELAYED_REVERSING_VALVE: 128,
            SWITCHING_DELAYED_FAN_OVERRUN_TIME: 256,
            SWITCHING_DELAYED_MINIMUM_OFF_TIME: 512,
            NEED_HEAT_BUT_TOO_HOT: 1024,
            NEED_COOL_BUT_TOO_COLD: 2048,
            COOL_DEMAND_BUT_TOO_LITTLE: 4096,
            HEAT_DEMAND_BUT_TOO_LITTLE: 8192
        },
        Demand: {
            COOLING: -1,
            NONE: 0,
            HEATING: 1
        },
        ScreenState: {
            IRC_LIST: "ClimateUsControlIrcList",
            MODE_SELECTOR: "ClimateUsControlModeSelectorScreen",
            OUTSIDE_TEMP_SCREEN: "ClimateUsControlOutsideTempScreen"
        },
        OutsideTemp: {
            get MIN() {
                return pickCelsiusOrFahrenheit(-10, 14);
            },

            get MAX() {
                return pickCelsiusOrFahrenheit(40, 104);
            }

        },
        OutdoorTempInfo: {
            NOT_ENOUGH_DATA: 0, // only for past 48h avg
            OK: 1,
            NO_DATA: 2
        },
        OutdoorTempMode: {
            NOT_USED: 0,
            AVG_PAST_48H: 1,
            AVG_FORECAST_48H: 2,
            CURR_TEMP: 3
        }
    };
});
