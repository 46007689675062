'use strict';

define(['ControlActionSliderScreen', 'IRoomControlEnums'], function (ControlActionSliderScreen, IRoomControlEnums) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var SLIDER_RATE = 0.5; // seconds

    var STEP_WIDTH = 0.5; // °

    return class IRCManualTempSliderScreen extends ControlActionSliderScreen {
        constructor() {
            super(...arguments);
            this.antiGhostTimer = new AntiGhostTimer(this._updateSubtitle.bind(this));
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.setTitle(_("controls.irc.manual.target-temp"));
        }

        destroy() {
            this.antiGhostTimer && this.antiGhostTimer.resetAntiGhostTimer();
            super.destroy(...arguments);
        }

        shouldDisplayStateIcons() {
            return false;
        }

        /**
         * This function returns the config of the slider, it is crucial to implement it!
         * @param [states] The control states - if there are any already
         * @returns {{min, max, step, antiGhostTimer: boolean}}
         */
        getSliderConfig(states) {
            var min, max; // We may not have any states yet if the app has been moved to the background and foreground in
            // a short period of time

            if (Object.keys(states).length > 0) {
                min = states.activeTemperatures[IRoomControlEnums.IRCTempMode.EMPTY_HOUSE];
                max = states.activeTemperatures[IRoomControlEnums.IRCTempMode.HEAT_PROTECTION];
            }

            return {
                min: min,
                max: max,
                step: STEP_WIDTH,
                antiGhostTimer: true,
                updateRate: SLIDER_RATE
            };
        }

        getStateIDs() {
            return ["tempTarget", "activeTemperatures"];
        }

        receivedStates(states) {
            // do NOT pass the states on to the subclass, otherwise it would adopt the state texts, icons and
            // so on based on the stateContainer of the IRC, which is not what we want.
            this.sliderActionView.setPosition(states.tempTarget);
            this.updateProperties({
                min: states.activeTemperatures[IRoomControlEnums.IRCTempMode.EMPTY_HOUSE],
                max: states.activeTemperatures[IRoomControlEnums.IRCTempMode.HEAT_PROTECTION],
                step: STEP_WIDTH,
                antiGhostTimer: true
            });
            this._canUpdateUI() && this._updateSubtitle(states.tempTarget);
        }

        getAdditionalSection() {
            return null;
        }

        // Private
        _sendValue(pos) {
            this._updateSubtitle(pos);

            this.antiGhostTimer.fire();
            this.sendCommand(Commands.format(Commands.I_ROOM_CONTROLLER.MANUAL_TEMP, pos), Commands.Type.OVERRIDE);
        }

        _canUpdateUI() {
            return this.antiGhostTimer && !this.antiGhostTimer.isAntiGhostTimerActive();
        }

        _updateSubtitle(targetTemp) {
            if (!targetTemp && targetTemp !== 0) {
                targetTemp = this.control.getStates().tempTarget;
            }

            this.setSubtitle(lxFormat(this.control.details.format, targetTemp));
        }

    };
});
