import {LxReactTableView, navigatorConfig} from "../../Components";
import useAmbientSettings from "../hooks/useAmbientSettings";
import {useCallback, useEffect, useMemo} from "react";
import AmbientTableviewHelper from "../AmbientTableviewHelper";
import AmbientUtils from "../AmbientUtils";

function AmbientShortcutEditScreen(props) {
    const ambientSettings = useAmbientSettings();

    useEffect(() => {
        props.navigation.setOptions({
            ...navigatorConfig({
                title: _("ambient.settings.shortcuts.edit")
            })
        })
    }, [])

    const {tableContent, editMap} = useMemo(() => {
        const content = [
            ...AmbientTableviewHelper.createShorcutsSections(AmbientUtils.getShortcuts(ambientSettings), true, props.navigation)
        ]
        return {
            tableContent: content,
            editMap: [{
                sortable: true,
                removable: true,
                startIdx: 0,
                endIdx: ambientSettings.quickAccessControls.length - 1
            }]
        };
    }, [ambientSettings])

    const onCellMove = useCallback((section, from, to) => {
        let latestSettings = PersistenceComponent.getAmbientModeSettings();
        let latestShortcuts = latestSettings && latestSettings.quickAccessControls ? latestSettings.quickAccessControls : ambientSettings.quickAccessControls;
        let newList = [...latestShortcuts]
        newList.splice(to, 0, newList.splice(from, 1)[0]);
        PersistenceComponent.updateAmbientModeSetting({
            quickAccessControls: newList
        })
    }, [ambientSettings])

    const onCellRemove = useCallback((sectionIdx, rowIdx) => {
        let latestSettings = PersistenceComponent.getAmbientModeSettings();
        let latestShortcuts = latestSettings && latestSettings.quickAccessControls ? latestSettings.quickAccessControls : ambientSettings.quickAccessControls;
        let newList = [...latestShortcuts]
        newList.splice(rowIdx, 1);
        PersistenceComponent.updateAmbientModeSetting({
            quickAccessControls: newList
        })
    }, [ambientSettings])

    return <LxReactTableView
        performanceStyle={LxReactTableView.PerformanceStyle.Editable} tableContent={tableContent} editMap={editMap} onCellMove={onCellMove} onCellRemove={onCellRemove}/>
}

export default AmbientShortcutEditScreen
