import globalStyles from '../../../styles/react/styles';
import SceneSelectorScreen from "./SceneSelectorScreen";

export const Sections = {
    HEADER: 'Header',
    ACTIONS: 'Actions',
    FOOTER: 'Footer',
}

export const ActionType = {
    Position: 0,
    Command: 1,
    Scene: 2
}

const MAX_NUMBER_OF_QUICK_ACTIONS = 4;

export function getPlatformSettings() {
    const isLoxoneControl = LoxoneControl.hasLoxoneControl();
    const platform = PlatformComponent.getPlatformInfoObj().platform;
    const isAndroid = platform === PlatformType.Android;
    return { isLoxoneControl, platform, isAndroid };
}

export function titleBarText() {
    const { platform } = getPlatformSettings();
    if (platform.isAndroid) {
        return _("media.shortcuts.title");
    } else if (platform.isLoxoneControl) {
        return _("loxone-control.quick-action");
    } else {
        return _("quick-action.quick-action-generator");
    }
}

export function getHeaderTitleForSection(section) {
    const {isLoxoneControl, isAndroid} = getPlatformSettings();
    switch(section) {
        case Sections.HEADER: {
            if (isAndroid) {
                return _("shortcuts.android.create-new");
            } else if (isLoxoneControl) {
                return _('loxone-control.quick-action.create-new');
            } else {
                return _('quick-action.create-new');
            }
            break;
        }
        case Sections.ACTIONS: {
            if (isAndroid) {
                return _("shortcuts.android.saved");
            } else if (isLoxoneControl) {
                return _('loxone-control.quick-action.saved');
            } else {
                return _('quick-action.saved');
            }
        }
        case Sections.FOOTER: {
            if(!isLoxoneControl && isAndroid) {
                return _('shortcuts.android.only-four');
            } else {
                return _('quick-action.info.only-four')
            }
        }
        default: {
            throw new Error('Wrong section was selected');
        }
    }
}

export const performActionForType = (type, navigation) => {
    if (type === ActionType.Command && !Feature.NFC_QR_QA_COMMANDS) {
        NavigationComp.showCurrentlyNotSupportedPopup(_('command.save.not-supported'));
        return;
    }

    const { isLoxoneControl, isAndroid } = getPlatformSettings();
    let actions = QuickActionUtility.getItems(isLoxoneControl);

    if(actions.length < MAX_NUMBER_OF_QUICK_ACTIONS || isLoxoneControl) {
        switch(type) {
            case ActionType.Position: {
                navigation.goBack();
                const onFinish = () => {
                    NavigationComp.startActivity(GUI.ActiveMiniserverViewController.Activity.Position, Interaction.QuickAction.WRITE_POSITION);
                };

                if (PersistenceComponent.getAppInstructionFlag(UserInstructionFlag.QA_POSITION)) {
                    onFinish();
                } else {
                    PersistenceComponent.setAppInstructionFlag(UserInstructionFlag.QA_POSITION);

                    let msg = _('app-position.help');

                    msg += ImageBox.getResourceImageWithClasses("resources/Images/ActiveMiniserver/QuickActions/quick-actions-logo.svg");
                    NavigationComp.showState(ScreenState.QuickGuide, {
                        iconSrc: "resources/Images/ActiveMiniserver/QuickActions/icon-quick-actions-logo.svg",
                        title: _('app-position.save'),
                        message: msg,
                        onFinishFn: onFinish
                    });
                }
                break;
            }
            case ActionType.Command: {
                navigation.goBack();
                // Command / Task
                const onFinish = () => {
                    NavigationComp.startActivity(GUI.ActiveMiniserverViewController.Activity.Command, Interaction.QuickAction.WRITE_COMMAND);
                };

                if (PersistenceComponent.getAppInstructionFlag(UserInstructionFlag.QA_COMMAND)) {
                    onFinish();
                } else {
                    PersistenceComponent.setAppInstructionFlag(UserInstructionFlag.QA_COMMAND);
                    NavigationComp.showState(ScreenState.QuickGuide, {
                        iconSrc: "resources/Images/ActiveMiniserver/QuickActions/icon-quick-actions-logo.svg",
                        title: _('command.save'),
                        message: _('command.help'),
                        onFinishFn: onFinish
                    });
                }
                break;
            }
            case ActionType.Scene: {
                const actions = QuickActionUtility.getItems(isLoxoneControl);
                navigation.navigate(SceneSelectorScreen.name, {
                    selectedCmds: actions?.flatMap(action => action?.cmds) || []
                })
            }
        }
    } else {
        NavigationComp.showPopup({
            message: isAndroid ? _("shortcuts.android.full") : _("quick-action.full"),
            buttonOk: true,
            icon: Icon.CAUTION,
            color: window.Styles.colors.orange
        });
    }
}

function getIconForAction(action, isEditMode) {
    if (isEditMode) {
        return;
    }

    if (!!action.cmds) {
        let controlUUID = UrlHelper.getControlUUIDFromCommandString(action.cmds[0]),
            control = ActiveMSComponent.getControlByUUID(controlUUID);

        if (control && control.isAutomaticScene) {
            return Icon.AutomaticDesigner.SCENE; //  Icons.PlayStripeCircled
        } else {
            return "resources/Images/ActiveMiniserver/NFC/icon-save-task.svg"; // Icons.PlayCircled
        }
    } else {
        return "resources/Images/ActiveMiniserver/NFC/icon-save-position.svg"; // Icons.PinWithCross
    }
}

export function getSavedSection(isEditMode) {
    const { isLoxoneControl } = getPlatformSettings();
    const actions = QuickActionUtility.getItems(isLoxoneControl);

    if(actions.length) {
        const savedTableContent = {
            headerTitle: getHeaderTitleForSection(Sections.ACTIONS),
            rows: actions.map(action => {
                let subtitle = action.subtitle;
                if(action.msName) {
                    subtitle = !subtitle ? action.msName + SEPARATOR_SYMBOL + subtitle : action.msName;
                }

                return {
                    content: {
                        title: action.title,
                        subtitle: subtitle,
                        leftIconSrc: getIconForAction(action, isEditMode),
                        leftIconColor: globalStyles.colors.brand
                    }
                }
            })
        }
        return savedTableContent;
    }
}

