import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SystemStateWarning(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path d="M23.944 22.254l-11.477-22c-.177-.339-.757-.339-.934 0l-11.477 22c-.173.33.077.724.466.724h22.955c.39 0 .641-.394.467-.724zM11.5 8.13a.5.5 0 111 0v7.652a.5.5 0 11-1 0V8.13zm.5 11.62a.75.75 0 110-1.5.75.75 0 010 1.5z" />
        </Svg>
    )
}

export default SystemStateWarning
