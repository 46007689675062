import {useContext, useMemo} from "react";
import globalStyles from "GlobalStyles";
import {AmbientContext} from "LxComponents";

export default function useBorderColor() {
    const {isAmbientMode} = useContext(AmbientContext);
    const color = useMemo(() => {
        return isAmbientMode ? globalStyles.colors.borderColor.ambient : globalStyles.colors.borderColor.default;
    }, [isAmbientMode])
    return color;
}