'use strict';

class PositionInstructionBar extends GUI.InstructionBar {
    constructor(type) {
        if (type !== Interaction.NFC.WRITE_POSITION && type !== Interaction.QR.WRITE_POSITION && type !== Interaction.QuickAction.WRITE_POSITION) {
            throw new Error("unsupported type " + type);
        }

        super(type);
        this.element.addClass("position-instruction-bar"); // if NFC, prevent case if user touches a Tag while the interaction is in progress!

        if (this.type === Interaction.NFC.WRITE_POSITION) {
            nfcService.preventListening(true);
        }

        var platform = PlatformComponent.getPlatformInfoObj().platform;
        this.isAndroid = platform === PlatformType.Android;
        this._isLoxoneControl = LoxoneControl.hasLoxoneControl();
    }

    viewDidLoad() {
        super.viewDidLoad(...arguments);
        this.elements.cancelBtn = this.addButton("resources/Images/ActiveMiniserver/NFC/button-cancel.svg", "cancel-btn");
        var iconSrc, iconClass;

        if (this.type === Interaction.NFC.WRITE_POSITION) {
            iconSrc = "resources/Images/ActiveMiniserver/NFC/icon-nfc-logo.svg";
            iconClass = "nfc-icon";
        } else if (this.type === Interaction.QR.WRITE_POSITION) {
            iconSrc = "resources/Images/ActiveMiniserver/QRCode/icon-qr-logo.svg";
            iconClass = "qr-icon";
        } else if (this.type === Interaction.QuickAction.WRITE_POSITION) {
            iconSrc = "resources/Images/ActiveMiniserver/QuickActions/icon-quick-actions-logo.svg";
            iconClass = "quick-action-icon";
        }

        this.elements.finishBtn = this.addButton(iconSrc, "finish-btn " + iconClass);
        this.setText(_('app-position.navigate'));
    }

    viewDidAppear() {
        super.viewDidAppear(...arguments);
        this._boundCancel = this._onCancel.bind(this);
        this._boundFinish = this._finish.bind(this);
        this.elements.cancelBtn.on("click", this._boundCancel);
        this.elements.finishBtn.on("click", this._boundFinish); // TODO-thallth implement a "service" where we can register for this event (NavigationComp!) hashchange doesn't work properly everywhere!
        // listen to hashchange event to be able to approve the position

        this._boundHashChangeFn = this._hashChange.bind(this);
        window.addEventListener("hashchange", this._boundHashChangeFn); // also start interval as fallback for hashchange

        this._interval = setInterval(function () {
            this._approvePosition();
        }.bind(this), 500);
    }

    viewDidDisappear(viewRemainsVisible) {
        this.elements.cancelBtn.off("click", this._boundCancel);
        this.elements.finishBtn.off("click", this._boundFinish);
        this._boundCancel = null;
        this._boundFinish = null;
        window.removeEventListener("hashchange", this._boundHashChangeFn);
        this._boundHashChangeFn = null;
        clearInterval(this._interval);
        super.viewDidDisappear(viewRemainsVisible);
    }

    destroy() {
        if (this.type === Interaction.NFC.WRITE_POSITION) {
            nfcService.preventListening(false);
        }

        super.destroy();
    }

    // Private methods
    _hashChange() {
        //console.log("#hashchange event");
        this._approvePosition();
    }

    _approvePosition() {
        // approve if the position is supported
        try {
            this.urlScheme = UrlHelper.createUrlStartFromCurrentLocation(); //console.info("current position valid!");
        } catch (e) {
            delete this.urlScheme; //console.info("current position not valid!");
        }

        var isValid = !!this.urlScheme; // always wait till the 2nd time!

        if (this.isValid || isValid) {
            this.setText(_('app-position.navigate'));
            this.element.removeClass("position-invalid");
        } else if (!isValid) {
            this.setText(_('app-position.not-possible'));
            this.element.addClass("position-invalid");
        }

        this.isValid = isValid;
    }

    _finish() {
        NavigationComp.stopActivity(GUI.ActiveMiniserverViewController.Activity.Position);

        if (this.urlScheme) {
            if (this.type === Interaction.NFC.WRITE_POSITION) {
                NavigationComp.showState(ScreenState.NfcTagInteractionScreen, {
                    type: Interaction.NFC.WRITE_POSITION,
                    urlScheme: this.urlScheme
                });
            } else if (this.type === Interaction.QR.WRITE_POSITION) {
                NavigationComp.showState(ScreenState.QRCodeResult, {
                    type: Interaction.QR.WRITE_POSITION,
                    text: this.urlScheme
                });
            } else if (this.type === Interaction.QuickAction.WRITE_POSITION) {
                var params = getURIParameters(this.urlScheme);
                var locInfo = UrlHelper.getLocationInfoFromLocationString(params.loc);
                var title = params.loc;
                var message;

                if (locInfo.control) {
                    var control = ActiveMSComponent.getControlByUUID(locInfo.control);
                    title = control.name;
                } else if (locInfo.room) {
                    var room = ActiveMSComponent.getStructureManager().getGroupByUUID(locInfo.room, GroupTypes.ROOM);
                    title = room.name;
                } else if (locInfo.category) {
                    var cat = ActiveMSComponent.getStructureManager().getGroupByUUID(locInfo.category, GroupTypes.CATEGORY);
                    title = cat.name;
                }

                QuickActionUtility.addPosition(title, this.urlScheme).done(function () {
                    NavigationComp.showState(ScreenState.QuickActions);

                    if (this.isAndroid) {
                        message = _("shortcuts.android.save-success");
                    } else if (this._isLoxoneControl) {
                        message = _('loxone-control.quick-action.save.success');
                    } else {
                        message = _("quick-action.save.success");
                    }

                    var content = {
                        message: message,
                        buttonOk: true,
                        icon: Icon.SUCCESS
                    };
                    NavigationComp.showPopup(content);
                }.bind(this), function () {
                    if (this.isAndroid) {
                        message = _("shortcuts.android.save-fail");
                    } else if (this._isLoxoneControl) {
                        message = _('loxone-control.quick-action.save.fail');
                    } else {
                        message = _("quick-action.save.fail");
                    }

                    var content = {
                        message: message,
                        buttonOk: true,
                        icon: Icon.ERROR,
                        color: window.Styles.colors.red
                    };
                    NavigationComp.showPopup(content);
                }.bind(this));
            }
        }
    }

    _onCancel() {
        if (this.type === Interaction.QR.WRITE_POSITION) {
            NavigationComp.showState(ScreenState.QRCode);
        } else if (this.type === Interaction.NFC.WRITE_POSITION) {
            NavigationComp.showState(ScreenState.Nfc);
        } else if (this.type === Interaction.QuickAction.WRITE_POSITION) {
            NavigationComp.showState(ScreenState.QuickActions);
        }

        super._onCancel();
    }

}

GUI.PositionInstructionBar = PositionInstructionBar;
