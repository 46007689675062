'use strict';

define(["ContentOverviewScreenBase", "MediaBrowserV2Service"], function (ContentOverviewScreenBase, MediaBrowserV2Service) {
    return class LineInOverviewScreen extends ContentOverviewScreenBase {
        constructor(details) {
            super(...arguments);
        }

        titleBarText() {
            return this.input ? this.input.name : NBR_SPACE;
        }

        loadData() {
            return super.loadData(...arguments).then(function () {
                this._registerForMediaServerEvents(this.EVENT_TYPES.LINEIN);
            }.bind(this));
        }

        viewDidDisappear() {
            this._unregisterForMediaServerEvents(this.EVENT_TYPES.LINEIN);

            super.viewDidDisappear(...arguments);
        }

        receivedLineInAllDataEvent(data) {
            var filteredInputs = this.control.audioserverComp.filterCurrentInputs(data.items);

            if (filteredInputs.length > 0) {
                this.input = filteredInputs[0];
                this.control.audioserverComp.sendPlayerCommandFromType(this.input);
                this.updateBackgroundView();
            }

            this.ViewController._checkTitlebar();
        }

        getRightVCButtonIcon() {
            return null;
        }

        getRightVCButtonIconFn() {
            return null;
        }

        updateBackgroundView() {
            this.tableView.setBackgroundView(new GUI.BackgroundView(MusicServerEnum.LineInMap[this.input.icontype], _("audio-server.input.play-input-x", {
                input: this.input.name
            }), null, null, null, true));
        }

        _shouldShowFavorites() {
            return false;
        }

    };
});
