'use strict';

define("TimedSwitchControl", [
    "Control",
    "IconLib"
], function (
    Control,
    {
        default: Icons
    }
) {
    return class TimedSwitchControl extends Control {
        constructor() {
            super(...arguments);
        }

        getButton0(states) {
            var tapShouldSendOff = this.details.isStairwayLs && states.isOn && !states.timerActive,
                // stairway ls doesn't switch off if it's permanently on and pulse is sent
                doubleTapShouldTurnOn = this.details.isStairwayLs ? !states.isOn : !states.isOn || states.timerActive;
            return {
                iconSrc: Icon.TimedSwitch.TIMER,
                reactIcon: Icons.Timer,
                command: {
                    tap: tapShouldSendOff ? Commands.TIMED_SWITCH.OFF : Commands.TIMED_SWITCH.PULSE,
                    doubleTap: doubleTapShouldTurnOn ? Commands.TIMED_SWITCH.ON : Commands.TIMED_SWITCH.OFF
                }
            };
        }

        /*,
                    // Wrike: 167063868
                    getSwitch: function getSwitch() {
                        var states = this.getStates();
                        return {
                            active: states.isOn,
                            command0: Commands.TIMED_SWITCH.OFF,
                            command1: Commands.TIMED_SWITCH.ON
                        };
                    }*/


    };
});
