'use strict';

define([], function () {
    Controls.AudioZoneV2Control.SingleTones = Controls.AudioZoneV2Control.SingleTones || {};

    if (Controls.AudioZoneV2Control.SingleTones.FavoritesManager) {
        return Controls.AudioZoneV2Control.SingleTones.FavoritesManager;
    } else {
        class FavoritesManager {
            //region Static
            static shared(control) {
                if (!control) {
                    console.warn("No control provided, try to deduce one!");

                    if (window.hasOwnProperty("AudioserverComp") && AudioserverComp.getActiveZoneControl()) {
                        control = AudioserverComp.getActiveZoneControl();
                    } else {
                        throw "No control available!";
                    }
                }

                this.__instances = this.__instances || {};

                if (!this.__instances.hasOwnProperty(control.uuidAction)) {
                    this.__instances[control.uuidAction] = new this(this, control);
                }

                return this.__instances[control.uuidAction];
            }

            static destroy(control) {
                if (control && this.__instances && this.__instances.hasOwnProperty(control.uuidAction)) {
                    this.__instances[control.uuidAction].destroy();

                    delete this.__instances;
                }
            }

            static MAX_RETRY = 5; //endregion Static

            constructor(initiator, control) {
                applyMixins(this, MediaStateHandlerV2.Mixin);

                if (!(initiator instanceof Function) && !Controls.AudioZoneV2Control.SingleTones.FavoritesManager) {
                    throw "The Favorite manager is a Singletone, use it like that! -> FavoritesManager.shared(this.control)";
                }

                this.name = "FavoritesManager@" + control.getName();
                Debug.Control.AudioZone.FavoritesManager && console.log(this.name, "CTOR");
                this.control = control;
                this._favDef = Q.defer();
                this._errorCnt = 0;

                this._registerForMediaServerEvents(this.EVENT_TYPES.ZONE_FAVORITES);
            }

            destroy() {
                Debug.Control.AudioZone.FavoritesManager && console.log(this.name, "destroy");

                this._unregisterForMediaServerEvents(this.EVENT_TYPES.ZONE_FAVORITES);
            }

            favorites() {
                if (this._favs) {
                    Debug.Control.AudioZone.FavoritesManager && console.log(this.name, "favorites: already present, return", this._favs);
                    return Q(cloneObject(this._favs));
                } else {
                    Debug.Control.AudioZone.FavoritesManager && console.log(this.name, "favorites: not present, requestContent");

                    this._requestEventContent(this.EVENT_TYPES.ZONE_FAVORITES);

                    return this._favDef.promise;
                }
            }

            getExistingFavItem(item) {
                return this.favorites().then(function (favs) {
                    return favs.find(function (fav) {
                        if (fav.hasOwnProperty("audiopath")) {
                            if (item.contentType === MusicServerEnum.MediaContentType.FAVORITES || item.contentType === MusicServerEnum.MediaContentType.ZONE_FAVORITES) {
                                return fav.audiopath === item.audiopath;
                            } else if (item.contentType === MusicServerEnum.MediaContentType.LINEIN) {
                                return fav.audiopath === (item.audiopath || item.cmd); // the lineInExt ensures a proper audiopath.
                            } else {
                                // Fav items are stripped of the @user part, so we need to compare the audiopath without it.
                                if("audiopath" in item && item.audiopath.includes("@")) {
                                    const [serviceIdentifier, itemType, itemId] = item.audiopath.split(":");
                                    return fav.audiopath === [serviceIdentifier.split("@")[0], itemType, itemId].join(":");
                                } else {
                                    return fav.audiopath === (item.audiopath || item.id);
                                }
                            }
                        }
                    }.bind(this));
                }.bind(this));
            }

            saveItemAsFav(item, mediaInfo) {
                Debug.Control.AudioZone.FavoritesManager && console.log(this.name, "saveItemAsFav: " + JSON.stringify(item));
                var cmd = this.control.audioserverComp.getZoneFavoriteSaveCommand(this.control.details.playerid, item, this.control.audioserverComp.getTitleSubtitleForItem(item).title, item.contentType, mediaInfo);
                return this.control.audioserverComp.sendMediaServerCommand(cmd).then(function () {
                    this.control.audioserverComp.showConfirmationFeedback(_("media.notifications.fav.add"));
                }.bind(this), function () {
                    this.control.audioserverComp.showConfirmationFeedback(_("media.notifications.fav.add.error"));
                }.bind(this));
            }

            deleteItemFromFav(fav) {
                Debug.Control.AudioZone.FavoritesManager && console.log(this.name, "deleteItemFromFav: " + JSON.stringify(fav));
                return this.favorites().then(function (favs) {
                    if (favs.length > 1) {
                        return this.control.audioserverComp.deleteZoneFavorite(fav).then(function () {
                            this.control.audioserverComp.showConfirmationFeedback(_("media.notifications.fav.delete"));
                        }.bind(this), function () {
                            this.control.audioserverComp.showConfirmationFeedback(_("media.notifications.fav.delete.error"));
                        }.bind(this));
                    } else {
                        NavigationComp.showPopup({
                            title: _("audio-server.favorites.last-fav.delete.title"),
                            message: _("audio-server.favorites.last-fav.delete.desc"),
                            buttonOk: true,
                            buttonCancel: false
                        }, PopupType.GENERAL);
                        throw "Can't delete last fav!";
                    }
                }.bind(this));
            }

            receivedZoneFavoritesAllDataEvent(data) {
                Debug.Control.AudioZone.FavoritesManager && console.log(this.name, "receivedZoneFavoritesAllDataEvent" + JSON.stringify(data));
                this._favs = data.items;

                this._favDef.resolve(cloneObject(this._favs));

                this._favDef = Q.defer();
                var stateContainer = SandboxComponent.getStateContainerForUUID(this.control.uuidAction);
                stateContainer && stateContainer.injectStates({
                    favorites: this._favs
                });
                this._errorCnt = 0;
            }

            receivedZoneFavoritesErrorEvent(e) {
                Debug.Control.AudioZone.FavoritesManager && console.log(this.name, "receivedZoneFavoritesErrorEvent");

                if (++this._errorCnt < this.constructor.MAX_RETRY) {
                    this._requestEventContent(this.EVENT_TYPES.ZONE_FAVORITES);
                } else {
                    console.warn("FavoritesManager", "Couldn't load favorites for the " + this.constructor.MAX_RETRY + "th consecutive time!");
                }
            }

            receivedZoneFavoritesReloadEvent() {
                Debug.Control.AudioZone.FavoritesManager && console.log(this.name, "receivedZoneFavoritesReloadEvent");
                this._favs = null;
                this._errorCnt = 0;
            }

            getMediaId() {
                Debug.Control.AudioZone.FavoritesManager && console.log(this.name, "getMediaId");
                return this.control.details.playerid;
            }

            changeFavoriteId(fromId, toId) {
                var cmd = "audio/cfg/roomfavs/" + this.control.details.playerid + "/" + MusicServerEnum.AudioCommands.ZONE_FAV.SET_ID + fromId + "/" + toId;
                return this.control.audioserverComp.sendMediaServerCommand(cmd);
            }

            renameFavorite(favId, newName) {
                var cmd = "audio/cfg/roomfavs/" + this.control.details.playerid + "/" + MusicServerEnum.AudioCommands.ZONE_FAV.RENAME + favId + "/" + newName;
                return this.control.audioserverComp.sendMediaServerCommand(cmd);
            }

            setFavoriteInPlusLoop(favId, isInPlus) {
                var cmd = "audio/cfg/roomfavs/" + this.control.details.playerid + "/" + MusicServerEnum.AudioCommands.ZONE_FAV.SET_PLUS + favId + "/" + Number(isInPlus);
                return this.control.audioserverComp.sendMediaServerCommand(cmd);
            }

            sendNewFavoritesOrder(favorites) {
                var cmd = "audio/cfg/roomfavs/" + this.control.details.playerid + "/" + MusicServerEnum.AudioCommands.ZONE_FAV.REORDER + this._getFavOrder(favorites);

                return this.control.audioserverComp.sendMediaServerCommand(cmd);
            }

            _getFavOrder(favs) {
                if (favs && favs instanceof Array) {
                    return favs.map(function (fav) {
                        return fav.id;
                    }).join(",");
                } else {
                    return "";
                }
            }

        }

        Controls.AudioZoneV2Control.SingleTones.FavoritesManager = FavoritesManager;
    }

    return Controls.AudioZoneV2Control.SingleTones.FavoritesManager;
});
