'use strict';
/**
 * This screen is used to search for features, controls, rooms and categories this app supports.
 */

define([], function () {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var RESULT_LIMIT = 4,
        RESULT_LIMIT_HD = 9;
    var SEARCH_HISTORY_STORAGE = "SearchHistoryStorage";
    var DEFAULT_LIGHT_CELLS = GUI.TableViewV2.CellType.LIGHT;
    return class SearchScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div />'));
            this.searchResult = {}; // try to read keyword and location from the details (if provided)

            details = details || {};
            this.keyword = details.keyword || "";
            this.location = details.location || SearchLocation.ALL;
            this.searchStarted = false; // store a bound reference, avoids rebinding each search. (causes search index recreation)

            this._showStateFn = this._showResultState.bind(this); // detect the size of the index. this will allow to adopt the search timeout accordingly.

            ActiveMSComponent.updateSearchIndex(this._showStateFn);
            this.indexSize = ActiveMSComponent.getSearchIndexSize(this.location); // prepare for keeping track of search keywords.

            this._loadLastUsedKeywords(); // checks if the simple disign setting is active


            this.simpleDesignActive = PersistenceComponent.getSimpleDesignSetting();
        }

        viewDidLoad() {
            if (this.reactNavigationProps) {
                this.reactNavigationProps.navigation.setOptions({
                    title: ""
                })
            }
            var prms = super.viewDidLoad(...arguments);
            this.setUpComfortUI(false, false);
            this.toggleComfortModeUI(false);
            var inputCfg = {
                type: GUI.LxInputEnum.Type.SEARCH,
                placeholder: _("search.enter-keyword-placeholder"),
                style: GUI.LxInputStyle.ROUND,
                resetButton: true,
                autoFocus: true,
                translucent: true // will set isDark too.
            };
            this.inputView = new GUI.LxInput(inputCfg);
            this.prependSubview(this.inputView);
            return prms;
        }

        updateView(details) {
            if (this._lastAmbientOpenId !== details.ambientOpenId && this.keyword !== "") {
                this._lastAmbientOpenId = details.ambientOpenId;
                this.inputView && this.inputView.setValue("");
                this._respondToEmptyKeyword();
            }
            return super.updateView(...arguments);
        }

        viewDidAppear() {
            var prms = super.viewDidAppear(...arguments);
            this.inputView.onTextChanged = this._onKeywordChanged.bind(this);
            this.inputView.onSubmit = this._onSubmit.bind(this);
            return prms;
        }

        getLeftIcon() {
            if (!this.isInAmbientMode()) {
                return super.getLeftIcon(...arguments);
            } else {
                return false;
            }
        }

        getURL() {
            return "SearchScreen";
        }

        getAnimation() {
            return AnimationType.HD_OVERLAY;
        }

        reloadTable() {
            this._updateContent();

            return super.reloadTable(...arguments);
        }

        styleForTableView() {
            return TableViewStyle.TRANSLUCENT;
        }

        didSelectCell(cell, section, row) {
            VendorHub.Usage.search(FeatureUsage.Search.USING_RESULT, this.sectionTypes[section]);
            var content = this.tableContent[section].rows[row];
            content.action && content.action();
        }

        didSelectControl(cell, section, row) {
            VendorHub.Usage.search(FeatureUsage.Search.USING_RESULT, SearchLocation.CONTROLS);
        }

        _updateContent() {
            this.tableContent = [];
            this.sectionTypes = [];
            this.groupNames = ActiveMSComponent.getStructureManager().getCustomGroupTitles();

            if (this.searchStarted) {
                this.sectionIdx = 0; // important for creating the control cells.

                this._addSection(this._createBestResultSection(this.searchResult.bestResult));

                this._addSection(this._createRoomsSection(this.searchResult));

                this._addSection(this._createCategorySection(this.searchResult));

                this._addSection(this._createControlsSection(this.searchResult));

                this._addSection(this._createMenuSection(this.searchResult));

                this._addSection(this._createMsSettingsSection(this.searchResult));

                this._addSection(this._createAppSettingsSection(this.searchResult));

                this._addSection(this._createArchiveSection(this.searchResult));

                this._addSection(this._createTrustSection(this.searchResult));

                if (this.tableContent.length === 0) {
                    this.tableContent.push(this._createNoResultSection());
                }
            } else {
                this._addSection(this._createLastSearchesSection()); // not needed right now
                //this._addSection(this._createNewSection());


                this._addSection(this._createToolsSection());
            }
        }

        /**
         * Will add the section provided to the tableContent if the number of rows is creater than 0.
         * @param section
         * @private
         */
        _addSection(section) {
            if (section.rows.length > 0) {
                this.tableContent.push(section);
            }
        }

        /**
         * Creates a section that will indicate that there have not been any matches.
         * @returns {{rows: [*]}}
         * @private
         */
        _createNoResultSection() {
            return {
                footerTitle: _("search.matches", {
                    count: 0
                }),
                rows: []
            };
        }

        _createBestResultSection(bestResult) {
            var rows = [],
                createFn,
                title;

            if (bestResult) {
                title = _("search.top-hit");
                createFn = this._getCreateFnForLocation(bestResult.location);
                rows = [createFn(bestResult, 0, 0)];
                this.sectionTypes.push(bestResult.location);
            }

            return {
                headerTitle: title,
                rows: rows
            };
        }

        _getCreateFnForLocation(location) {
            var createFn;

            switch (location) {
                case SearchLocation.CONTROLS:
                    createFn = this._createControlCellContent.bind(this);
                    break;

                case SearchLocation.ROOMS:
                    createFn = this._createRoomCellContent.bind(this);
                    break;

                case SearchLocation.CATEGORIES:
                    createFn = this._createCategoryCellContent.bind(this);
                    break;

                case SearchLocation.MENU:
                case SearchLocation.MINISERVER_SETTINGS:
                case SearchLocation.APP_SETTINGS:
                    createFn = this._createMenuCellContent.bind(this);
                    break;

                case SearchLocation.TRUST:
                    createFn = this._createTrustedMiniserverCellContent.bind(this);
                    break;

                case SearchLocation.ARCHIVE:
                    createFn = this._createArchiveMiniserverCellContent.bind(this);
                    break;

                default:
                    createFn = this._createResultCellContent.bind(this);
                    break;
            }

            return createFn;
        }

        _createCategorySection(results) {
            return this._createResultSection(results, SearchLocation.CATEGORIES, this.groupNames[GroupTypes.CATEGORY], this._createCategoryCellContent.bind(this));
        }

        _createRoomsSection(results) {
            return this._createResultSection(results, SearchLocation.ROOMS, this.groupNames[GroupTypes.ROOM], this._createRoomCellContent.bind(this));
        }

        _createControlsSection(results) {
            return this._createResultSection(results, SearchLocation.CONTROLS, _("search.function"), this._createControlCellContent.bind(this));
        }

        _createMenuSection(results) {
            return this._createResultSection(results, SearchLocation.MENU, _("menu"), this._createMenuCellContent.bind(this));
        }

        _createMsSettingsSection(results) {
            return this._createResultSection(results, SearchLocation.MINISERVER_SETTINGS, _('search.miniserver-settings'), this._createMenuCellContent.bind(this));
        }

        _createAppSettingsSection(results) {
            return this._createResultSection(results, SearchLocation.APP_SETTINGS, _('search.app-settings'), this._createMenuCellContent.bind(this));
        }

        _createArchiveSection(results) {
            return this._createResultSection(results, SearchLocation.ARCHIVE, _("miniserverlist.title"), this._createArchiveMiniserverCellContent.bind(this));
        }

        _createTrustSection(results) {
            return this._createResultSection(results, SearchLocation.TRUST, _("trust.tab-name"), this._createTrustedMiniserverCellContent.bind(this));
        }

        _createResultSection(results, source, title, createFn) {
            var section = {
                    headerTitle: title,
                    rows: [],
                    allRows: []
                },
                limit = HD_APP ? RESULT_LIMIT_HD : RESULT_LIMIT,
                i,
                cntnt;
            results = results && results[source] || [];
            createFn = createFn || this._createResultCellContent.bind(this);

            for (i = 0; i < results.length; i++) {
                cntnt = createFn(results[i], this.sectionIdx, i);

                if (!cntnt) {
                    cntnt = this._createResultCellContent.bind(this);
                }

                i < limit && !results[i].isBest && section.rows.push(cntnt);
                section.allRows.push(cntnt);
            }

            if (results.length > limit) {
                section.sectionRightButtonTitle = _("search.show-all");
                section.sectionRightButtonColor = window.Styles.colors.brand;

                section.rightSectionButtonTapped = function (sectionIdx, tableView) {
                    this._showFullResultOfSection(sectionIdx);
                }.bind(this);
            }

            if (section.rows.length > 0) {
                // Store what kind of content is displayed in this section - only if it will be displayed
                this.sectionTypes[this.sectionIdx] = source;
            } // this section was created, go on to the next one.


            this.sectionIdx++;
            return section;
        }

        _createResultCellContent(searchResult) {
            return {
                type: GUI.TableViewV2.CellType.HALO_ICON,
                content: {
                    title: searchResult.name,
                    subtitle: searchResult.description,
                    clickable: false
                }
            };
        }

        _createMenuCellContent(searchResult) {
            if (searchResult.item.content) {
                let resultItem = {};
                Object.assign(resultItem, searchResult.item);

                const initialAction = searchResult.item.action;
                resultItem.action = function() {
                    this.ViewController.dismissScreen(this);
                    return initialAction ? initialAction() : null;
                }.bind(this);
                return resultItem; // if it's a proper feature, the item represents the cell content
            } else {
                return null;
            }
        }

        _createMiniserverCellContent(searchResult, isTrusted) {
            if (searchResult.item.content) {
                let resultItem = {};
                Object.assign(resultItem, searchResult.item);

                const initialAction = searchResult.item.action;
                resultItem.action = function() {
                    this.ViewController.dismissScreen(this);
                    return initialAction ? initialAction() : null;
                }.bind(this);
                return resultItem; // if it's a proper feature, the item represents the cell content
            } else {
                return null;
            }
        }

        _createTrustedMiniserverCellContent(searchResult) {
            return this._createMiniserverCellContent(searchResult, true);
        }

        _createArchiveMiniserverCellContent(searchResult) {
            return this._createMiniserverCellContent(searchResult, false);
        }

        _createControlCellContent(result) {
            var room = ActiveMSComponent.getStructureManager().getGroupByUUID(result.item.room, GroupTypes.ROOM);
            return {
                type: result.item.getCellType(),
                content: {
                    control: result.item,
                    displayAsCell: true,
                    displayControlName: room && result.item.name !== room.name,
                    showGroupDetails: true,
                    isInAmbientMode: this.isInAmbientMode(),
                    fromSearchResults: true,
                    isShortcut: this.isInAmbientMode(),
                    hideActionElements: result.item.isGrouped()
                },
                action: function action() {
                    this.ViewController.dismissScreen(this);
                }.bind(this)
            };
        }

        _createRoomCellContent(result) {
            return this._createGroupCellContent(result, GroupTypes.ROOM);
        }

        _createCategoryCellContent(result) {
            return this._createGroupCellContent(result, GroupTypes.CATEGORY);
        }

        _createGroupCellContent(result, groupType) {
            return {
                type: GUI.TableViewV2.CellType.Special.GROUP_CARD,
                content: {
                    groupName: result.name,
                    group: result.item,
                    displayAsCell: true,
                    useSimpleDesign: this.simpleDesignActive,
                    onPress: () => {
                        let group = result.item;
                        NavigationComp.showGroupContent(group.groupType, group.uuid);
                    }

                },
                action: function action() {
                    this.ViewController.dismissScreen(this);
                }.bind(this)
            };
        }

        _onKeywordChanged(value) {
            if (value === this.keyword) {// nothing changed.
            } else if (value !== "") {
                this.keyword = value;

                this._startSearchWithTimeout();
            } else {
                this._respondToEmptyKeyword();
            }
        }

        /**
         * The user did hit enter - this is when the search must be triggered immediately.
         * @param value
         * @private
         */
        _onSubmit(value) {
            this.inputView.removeFocus();

            this._clearSearchTimeout(); // if the result is already present and no softkeyboard is visible, submit means GO


            var instantOpen = this.searchResult && this.searchResult.keyword === value && this.searchResult.bestResult && !PlatformComponent.usesSoftKeyboard();

            if (instantOpen) {
                Debug.Search.Screen && console.log("Trigger the action for " + JSON.stringify(this.searchResult.bestResult.item));
                this.didSelectCell(this.tableView.cellForSectionAndRow(0, 0), 0, 0);
            } else if (value === "") {
                this._respondToEmptyKeyword();
            } else {
                this.keyword = value;

                this._launchSearch();
            }
        }

        /**
         * Handles when the keyword input is empty.
         * @private
         */
        _respondToEmptyKeyword() {
            // don't search if there is no keyword
            this.keyword = "";

            this._clearSearchTimeout();

            this.searchStarted = false;
            this.searchResult = {};
            this.reloadTable();
        }

        /**
         * Will ensure the search is started asap, but not too often and not unnecessarily often while typing
         * @private
         */
        _startSearchWithTimeout() {
            var timeout = this._getSearchTimeout(this.keyword, this.indexSize);

            this._clearSearchTimeout();

            this._searchTimeout = setTimeout(function () {
                this._launchSearch();

                this._searchTimeout = null;
            }.bind(this), timeout);
        }

        /**
         * Stops any running timeout and resets the _searchTimeout attribute
         * @private
         */
        _clearSearchTimeout() {
            if (this._searchTimeout) {
                clearInterval(this._searchTimeout);
            }

            this._searchTimeout = null;
        }

        /**
         * Effectively launches the search itself using the searchExt & also triggers a table reload when the
         * result returns.
         * @private
         */
        _launchSearch() {
            Debug.Search.Screen && console.log(this.name, "_launchSearch: kw: '" + this.keyword + "'");
            this.searchResult = ActiveMSComponent.searchFor(this.keyword, this.location, this._showStateFn);
            this.searchStarted = true;
            this.reloadTable(); // if the search was successful, store the keyword to make reusing it later easier.

            if (this.searchResult.count > 0) {
                this._storeKeyword();
            }
        }

        /**
         * Will trigger a search for that very keyword.
         * @param keyword
         * @private
         */
        _launchSearchFor(keyword) {
            this.keyword = keyword;
            this.inputView.setValue(keyword);

            this._launchSearch();
        }

        /**
         * Will return a search timeout that fits the keyword length and the indexSize. This ensures that the
         * UI is not being updated too often with too much data, which would cause lags.
         * @param keyword
         * @param indexSize
         * @returns {number}
         * @private
         */
        _getSearchTimeout(keyword, indexSize) {
            var timeout = 250,
                addTimeout = 250,
                kwLength = keyword.length,
                delayLimit = 6; // larger indexes will return larger results, ensure we don't search unneccessarily

            if (indexSize > 200) {
                addTimeout = 200;
            } else if (indexSize > 150) {
                addTimeout = 150;
            } else {
                addTimeout = indexSize;
            } // adopt the timeout, if the keyword is short - it shouldn't search right away - there might be more to come


            if (kwLength <= delayLimit) {
                timeout += (delayLimit + 1 - kwLength) * addTimeout;
            }

            Debug.Search.Screen && console.log(this.name, "_getSearchTimeout: kw: " + kwLength + ", items: " + indexSize + " with timeout: " + timeout);
            return timeout;
        }

        _showResultState(state, details) {
            //Potential to fail on HD-Screens since open Search is no navigation!
            this.ViewController.dismissScreen(this); //Currently fails on iPad, because viewController -> previousViewID sometimes is undefined -> this.history.current is undefined!

            /*
            this.closeAction().finally(function() {
                // inform the views that they are being presented from a search (requires a different animationType)
                if (!details) {
                    details = {};
                }
                details.isSearchResult = true;
                 NavigationComp.showState(state, details);
            });*/
            // inform the views that they are being presented from a search (requires a different animationType)

            if (!details) {
                details = {};
            }

            details.isSearchResult = true;
            NavigationComp.showState(state, details);
        }

        // - - - -- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        // - - - -- - - - - - - - - - - Pre Search Stuff  - - - - - - - - - - - - - - - - - -
        // - - - -- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        _loadLastUsedKeywords() {
            this._lastUsedKeywords = PersistenceComponent.getForMiniserver(SEARCH_HISTORY_STORAGE) || [];
        }

        _saveLastUsedKeywords() {
            PersistenceComponent.saveForMiniserver(SEARCH_HISTORY_STORAGE, this._lastUsedKeywords);
        }

        _storeKeyword() {
            var index = this._lastUsedKeywords.indexOf(this.keyword); // remove the keyword from the list, if it is already on it.


            if (index >= 0) {
                this._lastUsedKeywords.splice(index, 1);
            } // insert the new one at position 0


            this._lastUsedKeywords.splice(0, 0, this.keyword); // ensure it doesn't exceed 4 keywords;


            if (this._lastUsedKeywords.length > 4) {
                this._lastUsedKeywords.splice(3, this._lastUsedKeywords.length - 4);
            } // update the persisted file.


            this._saveLastUsedKeywords();
        }

        _createLastSearchesSection() {
            var section = {
                headerTitle: _("search.last-used"),
                sectionRightButtonTitle: _("delete"),
                sectionRightButtonColor: window.Styles.colors.brand,
                rightSectionButtonTapped: function () {
                    this._lastUsedKeywords = [];

                    this._saveLastUsedKeywords();

                    this.reloadTable();
                }.bind(this),
                rows: []
            };
            this._lastUsedKeywords && this._lastUsedKeywords.forEach(function (keyword) {
                section.rows.pushObject({
                    content: {
                        title: keyword,
                        color: window.Styles.colors.brand
                    },
                    action: this._launchSearchFor.bind(this, keyword),
                    type: DEFAULT_LIGHT_CELLS
                });
            }.bind(this));
            return section;
        }

        _createToolsSection() {
            var section = {
                    headerTitle: _("search.tools-and-settings"),
                    sectionRightButtonTitle: _("search.more"),
                    sectionRightButtonColor: window.Styles.colors.brand,
                    rightSectionButtonTapped: function () {
                        return this._showStateFn(ScreenState.BurgerMenuScreen);
                    }.bind(this),
                    rows: []
                },
                items = [];
            items.pushObject(SandboxComponent.getMenuUserManagementItem(this._showStateFn));
            items.pushObject(SandboxComponent.getDeviceSearchTreeItem(this._showStateFn));
            items.pushObject(SandboxComponent.getDeviceSearchItem(this._showStateFn));
            items.pushObject(SandboxComponent.getMenuAutomaticDesignerItem(this._showStateFn));
            items.pushObject(SandboxComponent.getMenuOperatingModesItem(this._showStateFn));
            items.pushObject(SandboxComponent.getMenuBatteryMonitorItem(this._showStateFn));
            items.pushObject(SandboxComponent.getMenuQRCodeItem(this._showStateFn));
            items.pushObject(SandboxComponent.getMenuQuickActionItem(this._showStateFn));
            items.pushObject(SandboxComponent.getMenuNFCItem(this._showStateFn));
            items.pushObject(SandboxComponent.getSystemStateItem(this._showStateFn));
            items.sort(function (a, b) {
                if (a.content.title < b.content.title) {
                    return -1;
                }

                if (a.content.title > b.content.title) {
                    return 1;
                }

                return 0;
            });
            section.rows = items;
            section.rows.forEach(function (item) {
                item.type = DEFAULT_LIGHT_CELLS;
                item.content.clickable = true;
                item.content.leftIconSrc = null;
                item.content.color = window.Styles.colors.brand;
            });
            return section;
        }

        _createNewSection() {
            var section = {
                headerTitle: _("menu.new"),
                rows: []
            }; //The results has to be changed every release!!

            section.rows.pushObject(SandboxComponent.getDeviceSearchTreeItem(this._showStateFn));
            section.rows.forEach(function (item) {
                item.type = DEFAULT_LIGHT_CELLS;
                item.content.clickable = true;
                item.content.leftIconSrc = null;
                item.content.color = window.Styles.colors.brand;
            });
            return section;
        }

        _showFullResultOfSection(section) {
            var resultSection = {},
                srcSection = this.tableContent[section]; // the rows in this section are filtered, use allRows, which isn't filtered..

            resultSection.headerTitle = srcSection.headerTitle;
            resultSection.rows = srcSection.allRows;
            NavigationComp.showState(ScreenState.SearchResultScreen, {
                content: [resultSection]
            });
        }

    };
});
