'use strict';

define("AudioZoneCentralControl", [
    "AudioZoneControl",
    "IconLib"
], function (
    AudioZoneControl,
    {
        default: Icons
    }
) {
    return class AudioZoneCentralControl extends AudioZoneControl {
        constructor() {
            super(...arguments);
        }

        /**
         * If true, controls of this type MAY use the rooms name instead of its own. E.g. this is important for light
         * controllers, heating controllers and other room based controls.
         */
        controlTypeUsesRoomName() {
            return false; // derived from audioZoneControl --> but a central object won't show the room name!
        }

        getCentralIcon() {
            return Icon.AudioZone.AUDIO_ZONE;
        }

        getButton0(states) {
            if (states.nrOfPlaying > 0) {
                return {
                    iconSrc: Icon.AudioZone.PAUSE_FULL,
                    reactIcon: Icons.Pause,
                    command: Commands.AudioZone.CLIENT.PAUSE
                };
            }
        }

        getButton1() {// just override, so that base won't be called
        }

        filterCommand(control, command, states) {
            // BG-I12298 the commands aren't filtered when all controls are targeted, so there's no need to filter here.
            //return states.isPlaying || command === Commands.AudioZone.CLIENT.PLAY;
            return true;
        }

        getControlContentViewControllerIdentifier() {
            return ScreenState.ControlContent;
        }

        getControlContentIdentifier() {
            return "AudioZoneCentralControlContent";
        }

        _sendCommand(cmd, type, customActionId, dontRecord, argumentTexts, source, automatic) {
            // Prevent spamming by send the command as override. This will override any queued command
            return super._sendCommand(cmd, Commands.Type.OVERRIDE, customActionId, dontRecord, argumentTexts, source, automatic);
        }

    };
});
