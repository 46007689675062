'use strict';

define(['LightControlEditSceneScreen', 'LightV2ControlEnums'], function (LightControlEditSceneScreen, LightV2ControlEnums) {
    return class LightV2ControlEditMoodScreen extends LightControlEditSceneScreen {
        //region Static
        static Template = function () {
            var getMoodDescrTemplate = function getMoodDescrTemplate() {
                return '<div class="table-view-wrapper__mood-description"></div>';
            };

            return {
                getMoodDescrTemplate: getMoodDescrTemplate
            };
        }(); //endregion Static

        constructor(details) {
            super(details);
            this.moodNr = details.mood; // Remove master controls

            for (var i = 0; i < this.controls.length; i++) {
                var controlObj = this.controls[i];

                if (controlObj && controlObj.details && controlObj.details.isMaster) {
                    this.controls.splice(i, 1);
                    i--;
                }
            }
        }

        viewDidLoad() {
            return Q.resolve(super.viewDidLoad(...arguments)).then(function () {
                this.elements.tableViewWrapper.prepend(LightV2ControlEditMoodScreen.Template.getMoodDescrTemplate());
                this.elements.moodDescription = this.element.find(".table-view-wrapper__mood-description");
                this.receivedStates(this.control.getStates());
            }.bind(this));
        }

        getURL() {
            return "EditMood";
        }

        titleBarText() {
            return _("controls.lightV2controller.mood.edit");
        }

        receivedStates(states) {
            // Getting the mood name for the currently selected mood
            for (var i = 0; i < states.moodList.length; i++) {
                var mood = states.moodList[i];

                if (mood.id === this.moodNr) {
                    this.moodName = mood.name.regexFilter(Regex.NAME_FILTER);
                    this.mood = mood;
                    break;
                }
            }

            this.elements.moodDescription.text(this._getMoodDescription());
            this.sceneNameTableView.reload();
        }

        // TableViewDataSource methods
        titleForHeaderInSection(section, tableView) {
            if (tableView === this.sceneNameTableView && section === 0) {
                return _("controls.lightV2controller.mood.name").toUpperCase();
            }
        }

        groupForHeaderInSection(section) {
            return {
                name: _("controls.lightV2controller.lightCircuits").toUpperCase()
            };
        }

        numberOfSections(tableView) {
            // Editing or deleting All off is prohibited, renaming is allowed though
            // Wrike: 179048785
            if (this.moodNr === LightV2ControlEnums.KNOWN_MOODS.ALL_OFF) {
                if (tableView === this.deleteSceneTableView || tableView === this.controlList) {
                    return 0;
                }
            }

            return super.numberOfSections(...arguments);
        }

        contentForCell(cell, section, row, tableView) {
            if (tableView === this.sceneNameTableView) {
                return {
                    value: this.moodName,
                    placeholder: _("autopilot.rule.title"),
                    validationRegex: Regex.NAME,
                    // disallow , and =
                    filterRegex: Regex.NAME_FILTER,
                    showGreen: false
                };
            } else if (tableView === this.deleteSceneTableView) {
                return {
                    title: _("controls.lightV2controller.mood.delete")
                };
            }
        }

        // TableViewDelegate methods
        didSelectCell(cell, section, row, tableView) {
            if (tableView === this.deleteSceneTableView) {
                // Moods activatable via a presence detector T5 or AlarmClock can't be deleted without notice
                // The user must be informed, that deleting the mood will have consequences
                if (this.mood.used) {
                    var content = {
                        title: _('controls.lightV2controller.mood.delete.question'),
                        message: this._getMoodDescription(),
                        buttonOk: _('controls.lightV2controller.mood.delete'),
                        buttonCancel: true,
                        color: window.Styles.colors.red,
                        icon: Icon.DELETE_2
                    };
                    NavigationComp.showPopup(content).then(this._deleteMood.bind(this));
                } else {
                    this._deleteMood();
                }
            }
        }

        textChanged(section, row, tableView, value, valid) {
            if (valid) {
                this.moodName = value;
            } else {
                this.moodName = null;
            }
        }

        // Private methods
        _sendChanges() {
            if (this.moodName) {
                this.sendCommand(Commands.format(Commands.LIGHTCONTROL_V2.MOOD.LEARN, this.moodNr, this.moodName));
                this.ViewController.navigateBack();
            }
        }

        _deleteMood() {
            this.sendCommand(Commands.format(Commands.LIGHTCONTROL_V2.MOOD.DELETE, this.moodNr));
            this.ViewController.navigateBack();
        }

        _getMoodDescription() {
            var descr = "",
                descrItems = [];

            if (this.mood) {
                if (this.mood.isAlarmClockMood) {
                    descrItems.push(_("controls.lightV2controller.mood.alarm-clock"));
                }

                if (this.mood.isMovementMood) {
                    descrItems.push(_("controls.lightV2controller.mood.presence"));
                }

                if (this.mood.isT5Mood) {
                    descrItems.push(_("controls.lightV2controller.mood.logic-t5"));
                }

                descr = descrItems.join("\n");
            } else {
                descr = _("loading");
            }

            return descr;
        }

    };
});
