'use strict';

define("AudioZoneV2Control", [
    "Control",
    "AudioZoneV2ControlEnums",
    "IconLib"
], function (
    Control,
    AudioZoneV2ControlEnums,
    {
        default: Icons
    }
) {
    return class AudioZoneV2Control extends Control {
        //region Getter
        get isFixedGroup() {
            // for some reasion, there may only be a single player in a fixed group -- hence it must still count as one.
            return this.resolveFixedGroup().length > 0;
        }

        get isStacked() {
            if (this.isFixedGroup) {
                return !!this.resolveFixedGroup().find(function (control) {
                    return control && control.details.server !== this.details.server;
                }.bind(this));
            } else {
                return false;
            }
        }

        get externalUpDownVolume() {
            var result = false;

            if (this.details && this.details.hasOwnProperty("volumeModes")) {
                result = this.details.volumeModes === MusicServerEnum.VolumeMode.EXT_UP_DOWN;
            }

            return result;
        }

        get supportsEQ() {
            var result = true;
            if (this.details && this.details.hasOwnProperty("disableFeature")) {
                result = !hasBit(this.details.disableFeature, MusicServerEnum.DisabledFeature.EQ);
            }
            return result;
        }

        get supportsBluetooth() {
            var result = false;
            if (this.details && this.details.hasOwnProperty("speakerConfig")) {
                result = hasBit(this.details.speakerConfig, MusicServerEnum.SpeakerConfig.BLUETOOTH_SUPPORTED);
            }
            if (result && this.details && this.details.hasOwnProperty("disableFeature")) {
                result = !hasBit(this.details.disableFeature, MusicServerEnum.DisabledFeature.BLUETOOTH);
            }
            return result;
        }

        get bluetoothSetByLogic() {
            var result = false;
            if (this.details && this.details.hasOwnProperty("parameterConnections")) {
                result = hasBit(this.details.parameterConnections, MusicServerEnum.ParameterConnections.BLUETOOTH);
            }
            return result;
        }

        get audioserverComp() {
            var comp = null;

            if (window.audioserverComps) {
                comp = window.audioserverComps[this.details.server];
            } else {
                console.warn(this.name, "Failed to acquire audioserverComp - window.audioserverComps doesn't exist!");
            }

            if (!comp) {
                console.warn(this.name, "Failed to acquire audioserverComp from list of audioserverComps! server: " + this.details.server);
                comp = AudioserverComp;
            }

            if (!comp) {
                console.error(this.name, "Failed to acquire audioserverComp - neither one stored in audioserverComps list for this control, nor a global one is available!");
            }

            return comp;
        } //endregion Getter


        constructor() {
            super(...arguments);
        }

        getName(useGroupName) {
            var name;

            if (useGroupName && this.isFixedGroup) {
                if (this.isInDevelopment()) {
                    name = this.details.groupName.debugify();
                } else {
                    name = this.details.groupName;
                }
            } else {
                name = super.getName(...arguments);
            }

            if (Debug.Control.AudioZone.DetailedName) {
                name = "#" + this.details.playerid + " " + name;

                if (this.isStacked) {
                    name = "📚 " + name;
                }
            }

            return name;
        }

        getSubtitle() {
            if (this.getRoom()) {
                return this.hasSiblingsInRoom ? this.getRoom().name : null;
            } else {
                return null;
            }
        }

        /**
         * If true, controls of this type MAY use the rooms name instead of its own. E.g. this is important for light
         * controllers, heating controllers and other room based controls.
         */
        controlTypeUsesRoomName() {
            return true;
        }

        resolveFixedGroup() {
            if (this.details.hasOwnProperty("groupObjects")) {
                var groupedControls = [this];
                this.details.groupObjects.forEach(function (obj) {
                    var control = ActiveMSComponent.getStructureManager().getControlByUUID(obj.uuid);

                    if (control) {
                        groupedControls.push(control);
                    }
                });
                return groupedControls;
            } else {
                return [];
            }
        }

        getIcon() {
            var states = this.getStates();

            if (states && states.coverurl && states.isPlaying) {
                return null;
            } else {
                return Icon.AudioZone.AUDIO_ZONE;
            }
        }

        getButton0(states) {
            if (!this._shouldHideButtonsForStates(states)) {
                return {
                    iconSrc: Icon.PLUS,
                    reactIcon: Icons.VolPlus,
                    command: {
                        tap: this.changeVolume.bind(this, Direction.UP),
                        tick: this.changeVolume.bind(this, Direction.UP),
                        doubleTap: this.dblClick.bind(this, Direction.UP)
                    }
                };
            }
        }

        getButton1(states) {
            if (!this._shouldHideButtonsForStates(states)) {
                return {
                    iconSrc: Icon.MINUS,
                    reactIcon: Icons.VolMinus,
                    command: {
                        tap: this.changeVolume.bind(this, Direction.DOWN),
                        tick: this.changeVolume.bind(this, Direction.DOWN),
                        doubleTap: this.dblClick.bind(this, Direction.DOWN)
                    }
                };
            }
        }

        isControlPartOfFixedGroup(control) {
            if (this.isFixedGroup && control.isFixedGroup) {
                return !!this.details.groupObjects.find(function (group) {
                    return group.uuid === control.uuidAction;
                });
            } else {
                return false;
            }
        }

        /**
         * !! NOT SUPPORTING DYNAMIC GROUPS YET !!
         * @param groupUuid
         * @returns {*}
         */
        isControlPartOfGroup(groupUuid) {
            return this.details.groupObjects && this.details.groupObjects.some(function (group) {
                return group.uuid === groupUuid;
            });
        }

        getControlContentViewControllerIdentifier() {
            return GUI.AudioZoneV2ControlContentViewControllerReactAbstractor.name;
        }

        getControlContentIdentifier() {
            if (HD_APP) {
                return "AudioZoneV2ControlContentHD";
            } else {
                return super.getControlContentIdentifier(...arguments);
            }
        }

        changeVolume(dir) {
            var states = this.getStates(),
                volStep;

           if (this.externalUpDownVolume) {
                this.sendCommand(dir === Direction.DOWN ? MusicServerEnum.AudioCommands.VOLUME.DOWN : MusicServerEnum.AudioCommands.VOLUME.UP);
            } else if (states) {
                volStep = states.volumeStep;

                if (dir === Direction.DOWN) {
                    this.audioserverComp.sendAudioZoneCommand(this.details.playerid, {
                        cmd: MusicServerEnum.AudioCommands.VOLUME.DOWN_BY + volStep
                    });
                } else {
                    this.audioserverComp.sendAudioZoneCommand(this.details.playerid, {
                        cmd: MusicServerEnum.AudioCommands.VOLUME.UP_BY + volStep
                    });
                }
            }
        }

        dblClick(dir) {
            if (dir === Direction.DOWN) {
                this.audioserverComp.sendAudioZoneCommand(this.details.playerid, {
                    cmd: MusicServerEnum.AudioCommands.PAUSE
                });
            } else {
                this.audioserverComp.sendAudioZoneCommand(this.details.playerid, {
                    cmd: MusicServerEnum.AudioCommands.ZONE_FAV.NEXT
                });
            }
        }

        isNetworkClient() {
            return this.details.clientType === MusicServerEnum.ClientType.UPNP || // Connected via Network (LAN/ WIFI)
                this.details.clientType === MusicServerEnum.ClientType.RPI || // Connected via Network (LAN/ WIFI)
                this.details.clientType === MusicServerEnum.ClientType.EXTENSION; // Connected via Tree Turbo
        }

        getStackedAudioServers() {
            var musSrv = [];

            if (this.isStacked) {
                this.details.groupObjects.forEach(function (obj) {
                    var ctrl = ActiveMSComponent.getStructureManager().getControlByUUID(obj.uuid);
                    musSrv.pushIfNoDuplicate(this.control.audioserverComp.getMediaServer(ctrl.details.server));
                });
            }

            return musSrv;
        }

        // ------------------------------------------------------------
        // Previously on the AudioZoneControl as Static Methods
        // ------------------------------------------------------------
        specificCreateCmdText(cmdParts, cmdArguments) {
            var text = null,
                cfg = {
                    name: this.name
                };

            switch (cmdParts[1]) {
                case Commands.AudioZoneV2.Queue.PREV:
                    text = _("cmdtext.audiozone.queueminus", cfg);
                    break;

                case Commands.AudioZoneV2.Queue.NEXT:
                    text = _("cmdtext.audiozone.queueplus", cfg);
                    break;

                case Commands.AudioZoneV2.VOLUME_SET:
                    cfg.vol = cmdParts[2];
                    text = _("cmdtext.audiozone.volume", cfg);
                    break;

                case Commands.AudioZoneV2.TTS.ID:
                    cfg.text = cmdParts[2];
                    text = _("cmdtext.audiozone.tts", cfg);
                    break;

                case Commands.AudioZoneV2.PLAY_SPECIFIC_FAV:
                    var favId = parseInt(cmdParts[2]);
                    cfg.item = _("media.item.zone-fav.slot-name", {
                        slotNr: favId
                    });
                    text = _("cmdtext.audiozone.playitem", cfg);
                    break;

                default:
                    break;
            }

            return text;
        }

        _shouldHideButtonsForStates(states) {
            return !states.canPlayMusic && this.isNetworkClient();
        }

        static get Enums() {
            return AudioZoneV2ControlEnums;
        }

    };
});
