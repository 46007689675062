import {createStackNavigator} from "@react-navigation/stack";
import {
    LxPermissionHandler,
    navigatorConfig,
    LxReactScreenAdapter,
    useHD
} from "LxComponents";
import UserManagementScreen from "./userManagementScreen";
import UserDetailsScreen from "./userDetails/userDetailsScreen";
import TrustPeerUserSelectScreen from "./trustUsers/trustPeerUserSelectScreen";
import UserGroupsScreen from "./userDetails/userGroupsScreen/userGroupsScreen";
import NfcEditDialogScreen from "./userDetails/nfcEditDialog/nfcEditDialogScreen";
import CurrentUserScreen from "../currentUser/currentUserScreen";
import ChangeSecretScreen from "../changeSecret/changeSecretScreen";
import {useCallback, useEffect} from "react";
import {useFocusEffect} from "@react-navigation/native";
import TrustPeerSelectScreen from "./trustUsers/trustPeerSelectScreen";

function LxReactUserManagementNavigator({navigation, route}) {
    const UserManagementStack = createStackNavigator();
    const isHD = useHD();

    useFocusEffect(
        useCallback(() => {
            let unblockFn = SandboxComponent.blockAmbientAndScreensaver();
            return () => {
                unblockFn && unblockFn();
            }
        }, [])
    )

    useEffect(() => {
        navigation.setOptions(
            navigatorConfig({
                animationType: isHD ? AnimationType.MODAL : AnimationType.HD_MODAL_FULLSCREEN,
                headerShown: false
            })
        );
    }, [])

    return (
        <UserManagementStack.Navigator
            initialRouteName={route.params.initialRouteName}
        >
            <UserManagementStack.Screen
                name={ScreenState.UserManagementScreen}
                component={UserManagementScreen}
                initialParams={{}}
            />
            <UserManagementStack.Screen
                name={ScreenState.TrustPeerSelectScreen}
                component={TrustPeerSelectScreen}
            />
            <UserManagementStack.Screen
                name={ScreenState.TrustPeerUserSelectScreen}
                component={TrustPeerUserSelectScreen}
            />
            <UserManagementStack.Screen
                name={ScreenState.UserDetailsScreen}
                component={UserDetailsScreen}
            />
            <UserManagementStack.Screen
                name={ScreenState.UserGroupsScreen}
                component={UserGroupsScreen}
            />
            <UserManagementStack.Screen
                name={ScreenState.NfcEditDialog}
                component={NfcEditDialogScreen}
            />
            <UserManagementStack.Screen
                name={ScreenState.LearnNfcTagScreen}
                component={LxReactScreenAdapter}
            />
            <UserManagementStack.Screen
                name={ScreenState.ChangeSecretScreen}
                component={ChangeSecretScreen}
            />

            <UserManagementStack.Screen
                name={ScreenState.CurrentUserScreen}
                component={CurrentUserScreen}
                initialParams={{}}
            />
        </UserManagementStack.Navigator>
    )
}

export default function UserManagementNavigator(props) {
    let permissions = ActiveMSComponent.getRequiredUserManagementPermissionInfos(),
        requiredPermissions;

    if (permissions.hasUserManagementPermissions) {
        requiredPermissions = permissions.requiredPermissions
    } else {
        requiredPermissions = MsPermission.CHANGE_PWD;
    }

    return (<LxPermissionHandler
        requiredPermissions={requiredPermissions}>
        <LxReactUserManagementNavigator {...props} />
    </LxPermissionHandler>);
}
