'use strict';
/*
 description
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.CHECKABLE_BUTTON = "CheckableButtonCell";

    class CheckableButtonCell extends GUI.TableViewV2.Cells.CheckableCell {
        //region Static
        static Template = function () {
            var getButtonContainer = function getButtonContainer(isSecond) {
                return $('<div class="cell__button-placeholder ' + (!!isSecond ? "cell__button-placeholder2" : "") + '" />');
            };

            var getButtonIcon = function getButtonIcon(buttonSrc) {
                return ImageBox.getResourceImageWithClasses(buttonSrc, "button-placeholder__icon");
            };

            return {
                getButtonContainer: getButtonContainer,
                getButtonIcon: getButtonIcon
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        viewDidLoad() {
            Debug.GUI.CheckableCell && console.log(this.viewId, "viewDidLoad");
            return super.viewDidLoad(...arguments).then(function (res) {
                Debug.GUI.CheckableCell && console.log(this.viewId, "viewDidLoad >> basecall passed!");
                this.elements.button2Elm = CheckableButtonCell.Template.getButtonContainer(true);
                this.contentPlaceholder.append(this.elements.button2Elm);
                this.rightButton2 = new GUI.LxButton(this, this.elements.button2Elm, Color.BUTTON_GLOW, null, true);
                this.rightButton2.useChildsAsActiveParts("fill");
                this.addToHandledSubviews(this.rightButton2);
                this.elements.buttonElm = CheckableButtonCell.Template.getButtonContainer();
                this.contentPlaceholder.append(this.elements.buttonElm);
                this.rightButton = new GUI.LxButton(this, this.elements.buttonElm, Color.BUTTON_GLOW, null, true);
                this.rightButton.useChildsAsActiveParts("fill");
                this.addToHandledSubviews(this.rightButton);
                this.updateContent(this.content);
                return res;
            }.bind(this));
        }

        viewWillAppear() {
            Debug.GUI.CheckableCell && console.log(this.viewId, "viewWillAppear");
            return super.viewWillAppear(...arguments).then(function (res) {
                this.rightButton.onButtonTapped = function () {
                    this.delegate.buttonTapped && this.delegate.buttonTapped.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
                };

                this.rightButton2.onButtonTapped = function () {
                    this.delegate.button2Tapped && this.delegate.button2Tapped.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
                };

                return res;
            }.bind(this));
        }

        viewDidDisappear() {
            Debug.GUI.CheckableCell && console.log(this.viewId, "viewDidDisappear");
            this.rightButton.onButtonTapped = null;
            this.rightButton2.onButtonTapped = null;
            return super.viewDidDisappear(...arguments);
        }

        // ---------------------------------------------------------------------------------------------------------
        //   Methods for super fast tableContent updates
        // ---------------------------------------------------------------------------------------------------------
        cellTypeForReuse() {
            return GUI.TableViewV2.CellType.CHECKABLE_BUTTON;
        }

        updateContent(newContent) {
            return super._updateContent(...arguments);
        }

        /**
         * This Method is used to apply a new content object onto the cell instance. e.g. update texts and so on.
         * @param newContent
         * @private
         */
        _applyNewContent(newContent) {
            Debug.GUI.CheckableCell && console.log(this.viewId, "_applyNewContent: " + JSON.stringify(newContent)); // call verify first, as it'll ensure the proper icons are set.

            var promises = [super._applyNewContent(...arguments)];
            var newBtnIcon = newContent.buttonSrc ? CheckableButtonCell.Template.getButtonIcon(newContent.buttonSrc) : false,
                newBtnIcon2 = newContent.button2Src ? CheckableButtonCell.Template.getButtonIcon(newContent.button2Src) : false;
            promises.push(GUI.animationHandler.schedule(function () {
                this.elements.buttonElm.html(newBtnIcon || "");
                this.elements.buttonElm.css("fill", newContent.buttonFill || Color.TEXT_SECONDARY_B);
                this.elements.button2Elm.html(newBtnIcon2 || "");
            }.bind(this)));
            this.rightButton && this.toggleSubview(this.rightButton, !!newBtnIcon);
            this.rightButton2 && this.toggleSubview(this.rightButton2, !!newBtnIcon2);
            return Q.all(promises);
        }

    }

    GUI.TableViewV2.Cells.CheckableButtonCell = CheckableButtonCell;
    return GUI;
}(window.GUI || {});
