'use strict';

define("RemoteControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class RemoteControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [];
            control.modeEntries.forEach(function (source) {
                var id = parseInt(source.key),
                    cmd = id === 0 ? Commands.REMOTE.RESET : Commands.format(Commands.REMOTE.MODEF, id),
                    obj = this.createCommand(source.value.name, cmd, null);
                obj.isActive = states.activeMode === id;
                cmds.push(obj);
            }.bind(this));
            return cmds;
        }

    };
});
