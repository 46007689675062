'use strict';

define([], function () {
    class CoverView extends GUI.View {
        //region Static
        static Template = class {
            //region Static
            static getCoverElement(iconObj, showThumb) {
                var coverElm = $('<div class="cover-placeholder cover-placeholder--default">' + '   ' + ImageBox.getResourceImageWithClasses(Icon.EMPTY, 'cover-placeholder__icon') + '</div>');
                this.setCover(coverElm, iconObj, iconObj.hasProvidedCover, showThumb);

                if (iconObj.hasProvidedCover) {
                    this.setCover(coverElm, iconObj, null, showThumb);
                }

                return coverElm;
            }

            static setCover(coverElm, iconObj, useFallback, showThumb) {
                var iconSrc = useFallback ? iconObj.fallback || (showThumb ? iconObj.lowRes : iconObj.highRes) : showThumb ? iconObj.lowRes : iconObj.highRes,
                    isInitialLocalSvg = iconSrc.hasPrefix("resources/Images"),
                    fallbackImgElm = ImageBox.getResourceImageWithClasses(iconObj.fallback || Icon.AudioZone.NEW.NOTE, 'cover-placeholder__icon');

                // Important: There's a special handling inside lxUtils getSvgImage, which the ImageBox relies on, for
                // bypassing the imageproxy on the Music Server if the request fails. It'll then try to grab the image
                // straight from the server the proxy will grab it from. The proxy should take care of CORS headers, but
                // does not on the Music Server --> the spotify/tuneIn servers don't reliably send the headers, but do
                // more reliably then the music server.
                ImageBox.getImageElement(iconSrc, 'cover-placeholder__icon', null, iconObj.fallback || Icon.AudioZone.NEW.NOTE).then(function (res) {
                    if (res[0] instanceof SVGImageElement) {
                        try {
                            // This will set the image as big as the element, it basically fills the image by preserving the aspect Ratio
                            res[0].setAttributeNS(null, 'preserveAspectRatio', "xMinYMin slice");
                        } catch (e) {
                            console.log("setAttributeNS preserveAspectRatio failed!");
                        }
                    }

                    coverElm.removeClass("cover-placeholder--default");
                    var coverPlaceholder = coverElm.find(".cover-placeholder__icon"),
                        backgroundElm = '<rect class="icon--bg" x="0" y="0" width="100%" height="100%"/>'; // Is the typical icon shape (square with rounded corners)

                    if (iconObj.rounded) {
                        coverPlaceholder.attr("class", coverPlaceholder.attr("class") + " cover-placeholder__icon--rounded");
                    }

                    if (res[0].outerHTML.hasPrefix("<svg") && (isInitialLocalSvg || res[0].outerHTML === fallbackImgElm)) {
                        // Check if it is a local SVG
                        res.css("fill", iconObj.color); // Embed the SVG element into a group to be able to apply styles to it!

                        coverElm && coverPlaceholder.attr("class", coverPlaceholder.attr("class") + " cover-placeholder__icon--local-svg"); // It's an SVG, we can't use addClass!

                        coverElm && coverPlaceholder.html(backgroundElm + "<g class='icon--local-svg' transform='matrix(0.5, 0, 0, 0.5, 6, 6)'>" + res[0].outerHTML + "</g>");
                    } else {
                        coverElm && coverPlaceholder.attr("class", (coverPlaceholder.attr("class") || "").replace(" cover-placeholder__icon--local-svg", ""));
                        coverElm && coverPlaceholder.html(backgroundElm + res[0].outerHTML);
                    }
                });
            } //endregion Static


        }; //endregion Static

        constructor(elm, initIconObj, showThumb) {
            super($(elm));
            Object.assign(this, StateHandler.Mixin);
            this._previousIconObj = initIconObj;
            this._showThumb = showThumb;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.coverElement = CoverView.Template.getCoverElement(this._previousIconObj, this._showThumb);
                this.element.append(this.coverElement);
            }.bind(this));
        }

        setIconObject(iconObj) {
            // Only update the cover if the iconObj did change!
            if (JSON.stringify(this._previousIconObj) !== JSON.stringify(iconObj)) {
                console.log(this.name, "CoverDiff: old -> " + this._previousIconObj.highRes);
                console.log(this.name, "CoverDiff: new -> " + iconObj.highRes);
                CoverView.Template.setCover(this.element.find(".cover-placeholder"), iconObj, null, this._showThumb);
                this._previousIconObj = iconObj;
            }
        }

    }

    Controls.AudioZoneControl.CoverView = CoverView;
    return Controls.AudioZoneControl.CoverView;
});
