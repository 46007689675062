'use strict';

define("AlarmCentralControlCommandSrc", ["AlarmControlCommandSrc"], function (AlarmControlCommandSrc) {
    return class AlarmCentralControlCommandSrc extends AlarmControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [],
                disarmEnabled = states.nrOfArmed_Sel > 0 || states.nrOfDelayedArmed_Sel > 0,
                armEnabled = !states.allArmed_Sel;

            cmds.push(this.createDeactivatable(_('controls.alarm.arm'), Commands.ALARM.ARM, null, !armEnabled));
            cmds.push(this.createDeactivatable(_('controls.alarm.arm.delayed'), Commands.ALARM.DELAYED_ARM, null, !armEnabled));

            cmds.push(this.createDeactivatable(_('controls.alarm.disarm'), Commands.ALARM.DISARM, null, !disarmEnabled));

            if (states.nrOfAlarmActive_Sel > 0) {
                cmds.push(this.createCommand(_("controls.alarm.acknowledge"), Commands.ALARM.ACKNOWLEDGE));
            }

            return cmds;
        }

    };
});
