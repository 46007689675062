import {useState, useEffect, useContext} from 'react';
import {
    ControlContext
} from "LxComponents";

function useControlHistory(providedCtrl = null) {
    const [entries, setEntries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {control, states} = useContext(ControlContext)

    const getControl = () => {
        return control || providedCtrl;
    }

    const getMaxEntries = () => {
        let ctrl = getControl();
        return ctrl ? ctrl.historyMaxLength : 0
    }

    const updateHistory = (fromUseEffect = false) => {
        Debug.ControlHistory && console.log(useControlHistory.name, "updateHistory: fromUseEffect=" + !!fromUseEffect);
        setIsLoading(true);
        let cmd = Commands.format(Commands.ControlHistory.GET, getControl().uuidAction);
        return CommunicationComponent.sendViaHTTP(cmd, EncryptionType.REQUEST, true, null, null, { noLLRequest: true}).then(result => {
            processResult(result);
        }).fail(err => {
            processResult(null);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const processResult = (result) => {
        let value = result;

        Debug.ControlHistory && console.log(useControlHistory.name, "processResult: " + JSON.stringify(result));
        value = _sanitizeHistoryEntries(value);
        value = _groupHistoryEntriesByDay(value);

        Debug.ControlHistory && console.log(useControlHistory.name, "          --> " + JSON.stringify(value));
        setEntries(value);
    }

    const _sanitizeHistoryEntries = (newEntries) => {
        if (Array.isArray(newEntries)) {
            const dateFormat = LxDate.getDateFormat(DateType.DateText);
            const timeFormat = LxDate.getDateFormat(DateType.TimeFormatWithSeconds);
            let momObj, newEntry;

            // reverse the array, the latest entries should be on top of the list.
            return newEntries.reverse().map((entry) => {
                momObj = LxDateFromUnix(entry.ts);
                newEntry = cloneObject(entry);
                newEntry.date = momObj.format(dateFormat).toUpperCase();
                newEntry.time = momObj.format(timeFormat);
                newEntry.timestamp = momObj;

                // safety harness to avoid problems if type isn't present due to a MS error
                newEntry.triggerType = newEntry.triggerType || "-unknown-";

                return newEntry;
            });
        } else {
            return null;
        }
    }

    const _groupHistoryEntriesByDay = (entries) => {
        let dayEntry,
            dayList = [];
        entries && entries.forEach((entry) => {
            if (!dayEntry || dayEntry.date !== entry.date) {
                dayList.pushObject(dayEntry);
                dayEntry = { date: entry.date, entries: [] };
            }
            dayEntry.entries.push(entry);
        })
        dayList.pushObject(dayEntry);
        return dayList;
    }

    useEffect(() => {
        updateHistory(true);
    }, []);

    return { entries, loading: isLoading, reloadFn: updateHistory, maxEntries: getMaxEntries() };
}

export default useControlHistory;
