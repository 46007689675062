import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {View} from "react-native";
import PropTypes from "prop-types";
import {
    LxReactQuickSelect,
    LxReactTableView,
    navigatorConfig,
    useSetURLHook,
    AmbientContext,
    useBackNavigation
} from "LxComponents";
import {useFocusEffect} from "@react-navigation/native";
import Icons from "IconLib"
import globalStyles from "GlobalStyles";
import AmbientStackNavigator from "../AmbientMode/AmbientStackNavigator";

function MessageOverviewScreen(props) {
    const {
        navigation,
        forceFetch = null,
        entryUuid = null
    } = props;
    const {isAmbientMode} = useContext(AmbientContext)
    const [showHistory, setShowHistory] = useState(false);
    const defaultMessageCenterStructure = {
        entries: []
    };
    const [messageCenterStructure, setMessageCenterStructure] = useState(defaultMessageCenterStructure)
    const updateReciever = useRef(null);

    useBackNavigation(() => {
        navigation.goBack();
    });

    useSetURLHook({
        urlPart: UrlStartLocation.MESSAGE_CENTER
    });

    const messageListOptions = useRef([
        _("message-center.active"),
        _("message-center.history")
    ]).current;


    useEffect(() => {
        const hasMCStructure = ActiveMSComponent.hasMessageCenterStructure();
        // We need to forcefully fetch the messageCenter structure if we should open a specific entry, or the forceFetch flag is set
        // We can't ensure to have the most up to date entries available when opening the app with an messageCenter url scheme (PushNotification)
        Debug.SystemState && console.log(`SystemState: MessageOverview, useEffect entryUuid=${entryUuid}, forceFetch=${forceFetch}, ActiveMsComp.hasMCStructure=${hasMCStructure}`)
        if (entryUuid || forceFetch || !hasMCStructure) {
            fetchStructure()
        }

        let screenTitle = (messageCenterStructure.entries.length) ? _("message-center.name") : _("message-center.everything-ok.title");

        navigation.setOptions({
            ...navigatorConfig({
                title: screenTitle,
                rightActions: [{
                    action: ({dimensions, props, key}) => {
                        return <Icons.InfoCircled width={30} height={30} fill={globalStyles.colors.text.primary}/>
                    }
                }],
                onRightAction: showMessageCenterInfo,
                animationType: isAmbientMode ? AnimationType.NONE : AnimationType.MODAL,
                isAmbientMode: isAmbientMode,
                onLeftAction: () => {
                    if (isAmbientMode) {
                        navigation.navigate(ScreenState.AmbientScreen, {
                            screen: AmbientStackNavigator.name
                        });
                    } else if (navigation.canGoBack()) {
                        navigation.goBack();
                    }
                }
            })
        })
    }, [messageCenterStructure.entries]);

    useFocusEffect(useCallback(() => {
        Debug.SystemState && console.log("SystemState: MessageOverview useFocusEffect(), register for update...")
        updateReciever.current = ActiveMSComponent.registerForMessageCenterUpdate(onMessageUpdateRecieved.bind(this));
        return () => {
            Debug.SystemState && console.log("SystemState: MessageOverview useFocusEffect(), unregister for update...")
            updateReciever.current && updateReciever.current();
        }
    }, []));

    const onMessageUpdateRecieved = (ev, structure) => {
        Debug.SystemState && console.log(`SystemState: MessageOverview, update recieved: ${structure.entries?.length} entries`)
        setMessageCenterStructure(structure || defaultMessageCenterStructure);

        if (entryUuid) {
            // We need to validate, if the messageCenter contains the entry we should navigate to
            let wantedEntry = messageCenterStructureRef.current.entries.find((entry) => entry.entryUuid === entryUuid);

            if (wantedEntry) {
                NavigationComp.disableAnimationsTemp();

                if (wantedEntry.setHistoricAt) {
                    // Directly navigate to the historic page
                    setShowHistory(true)
                } else {
                    setShowHistory(wantedEntry);
                }
            } // Prevent any double navigation by deleting the property
        }
    }

    const showEntry = (entry) => {
        navigation.navigate("MessageDetailScreen", {entry});
    }

    const getEntries = () => {
        let resultEntries = [];
        let allEntries = messageCenterStructure.entries;

        if (!showHistory) {
            resultEntries = allEntries.filter((entry) => !entry.setHistoricAt).sort((left, right) => {
                // Sorting
                // • Sort after the severity
                // • Sort for the timestamp if the severity is equal
                let result = right.severity - left.severity;

                if (result === 0) {
                    result = right.timestamps[right.timestamps.length - 1] - left.timestamps[left.timestamps.length - 1];
                }

                return result;
            }).sort((left, right) => {
                // Sorting
                // • Sort every entry with the confirmedAt property to the bottom
                return left.confirmedAt ? 1 : right.confirmedAt ? -1 : 0;
            });
        } else {
            resultEntries = allEntries
        }

        return resultEntries;
    }

    const messagesContent = useMemo(() => {
        Debug.SystemState && console.log(`SystemState: MessageOverview, building table content based on ${messageCenterStructure.entries?.length} entries`)
        return [{
            rows: getEntries().map(entry => {
                return {
                    type: GUI.TableViewV2.CellType.Special.MESSAGE_CENTER_ENTRY_CELL,
                    content: {
                        entry: entry,
                        checkConfirmed: true
                    },
                    action: showEntry.bind(this, entry)
                }
            })
        }]
    }, [messageCenterStructure.entries, showHistory])

    return <View style={styles.container}>
        <LxReactQuickSelect containerStyle={styles.quickSelectContainer} selectedValue={messageListOptions[+showHistory]} options={messageListOptions} onOptionSelected={(value, idx) => setShowHistory(idx)}/>
        {messagesContent && <LxReactTableView tableContent={messagesContent}/>}
    </View>
}

const styles = {
    container: {
        height: "100%",
        width: "100%"
    },
    quickSelectContainer: {
        paddingLeft: globalStyles.spacings.contentHorizontal
    }
}

const showMessageCenterInfo = () => {
    NavigationComp.showPopup({
        title: _("message-center.about"),
        message: _("message-center.about.description"),
        buttonOk: _("update.more-info"),
        buttonCancel: true,
        icon: Icon.MESSAGE_CENTER.HEALTH,
        type: PopupType.GENERAL
    }).then(() => {
        NavigationComp.openWebsite("https://www.loxone.com/help/systemstatus");
    });
}

/**
 * Forcefully fetch the MessageCenter structure from the Miniserver,
 * also displays a waiting popup if the structure isn't returned after 500ms
 * @return {Promise}
 * @private
 */
const fetchStructure = () => {
    Debug.SystemState && console.log(`SystemState: MessageOverview, fetching structure...`)
    return ActiveMSComponent.fetchMessageCenterStructure(true);
}

MessageOverviewScreen.propTypes = {
    forceFetch: PropTypes.bool,
    entryUuid: PropTypes.number
}

export default MessageOverviewScreen
