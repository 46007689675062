'use strict';

define("AudioZoneCentralControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class AudioZoneCentralControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [this.createCommand(null, Commands.AudioZone.CLIENT.PLAY, Icon.AudioZoneCentral.PLAY_ICON), this.createCommand(null, Commands.AudioZone.CLIENT.PAUSE, Icon.AudioZoneCentral.PAUSE_ICON)]; // Needed later

            /*if (this._isAudioRecordingIsSupported(control)) {
                cmds.push(this.createCommand(_('media.menu.announcement'),
                    this._onMicButtonTapped.bind(this, control), Icon.AudioZone.MIC_ICON));
            }*/

            if (Feature.AUDIO_CENTRAL_VOL_CONTROL) {
                cmds.push(this.createCommand(null, this.createCmdObj(Commands.AudioZoneCentral.VOL_DOWN, null, Commands.AudioZoneCentral.VOL_DOWN, Commands.AudioZoneCentral.VOL_DOWN, Commands.AudioZoneCentral.VOL_DOWN), Icon.AudioZoneCentral.VOLUME_DOWN), this.createCommand(null, this.createCmdObj(Commands.AudioZoneCentral.VOL_UP, null, Commands.AudioZoneCentral.VOL_UP, Commands.AudioZoneCentral.VOL_UP, Commands.AudioZoneCentral.VOL_UP), Icon.AudioZoneCentral.VOLUME_UP));
            }

            return cmds;
        } // Needed later

        /*_onMicButtonTapped: function _onMicButtonTapped(control) {
                        var targetMediaServerSet = {},
                            affectedCtrls,
                            mediaServerGroups,
                            popupPromise = Q(true);

                        if (CommunicationComponent.getCurrentReachMode() === ReachMode.REMOTE) {
                            popupPromise = this._showRemoteConnectedPopup();

                        } else {
                            if (control.selectedControls.length) {
                                affectedCtrls = control.selectedControls;
                            } else {
                                affectedCtrls = control.details.controls.map(function (control) {
                                    return control.uuid;
                                });
                            }
                            targetMediaServerSet = this._getTargetMediaServerSet(affectedCtrls);

                            // Checks if all music servers can handel the audio recording feature
                            mediaServerGroups = this._groupMediaServer(targetMediaServerSet);

                            if (mediaServerGroups.unstartedMediaServers.length) {
                                popupPromise = this._showUnstartedPopup(mediaServerGroups.unstartedMediaServers);

                            } else {
                                if (mediaServerGroups.mediaServerWithoutFeature.length) {
                                    popupPromise = this._showUnsupportedPopup(mediaServerGroups.mediaServerWithoutFeature, targetMediaServerSet);
                                }

                                if (!jQuery.isEmptyObject(targetMediaServerSet)) {
                                    popupPromise.then(this._showRecordingPopup.bind(this, targetMediaServerSet));
                                }
                            }
                        }

                        return popupPromise;
                    },

                    _showRemoteConnectedPopup: function _showRemoteConnectedPopup() {
                        var config = {
                            title: _('not-available'),
                            message: _('media.remote-not-available.text'),
                            buttonOk: true,
                            buttonCancel: false,
                            icon: Icon.INFO,
                            color: window.Styles.colors.green
                        };

                        return NavigationComp.showPopup(config, PopupType.GENERAL);
                    },

                    _showUnsupportedPopup: function _showUnsupportedPopup(mediaServerWithoutFeature, targetMediaServerSet) {
                        var okButton = _('media.announcement.continue-anyway'),
                            config;

                        if (jQuery.isEmptyObject(targetMediaServerSet)) {
                            okButton = false;
                        }
                        config = {
                            message: _('media.announcement.not-fully-supported', {
                                ip: mediaServerWithoutFeature.join(', '),
                                count: mediaServerWithoutFeature.length
                            }),
                            buttonOk: okButton,
                            buttonCancel: true,
                            icon: Icon.INFO,
                            color: window.Styles.colors.green
                        };

                        return NavigationComp.showPopup(config, PopupType.GENERAL);
                    },

                    _showUnstartedPopup: function _showUnstartedPopup(unstartedMediaServer) {
                        var config = {
                            title: _('media.announcement.music-server.not-ready.title'),
                            message: _('media.announcement.music-server.not-ready.message'),
                            buttonCancel: true,
                            buttonOk: _('media.announcement.music-server.not-ready.button.title'),
                            icon: Icon.WARNING,
                            color: window.Styles.colors.orange
                        };

                        return NavigationComp.showPopup(config, PopupType.GENERAL)
                            .then(this._wakeMediaServers.bind(this, unstartedMediaServer));
                    },

                    _wakeMediaServers: function _wakeMediaServers(unstartedMediaServer) {
                        var currentZone;

                        unstartedMediaServer.forEach(function (svrObj) {
                            currentZone = ActiveMSComponent.getStructureManager().getControlByUUID(svrObj.zones[0].uuidAction);
                            currentZone.sendCommand(Commands.AudioZone.SERVER.ON);
                        });
                    },

                    _groupMediaServer: function _groupMediaServer(targetMediaServerSet) {
                        var mediaServerWithoutFeature = [],
                            unstartedMediaServers = [],
                            svrStates;

                        Object.keys(targetMediaServerSet).forEach(function (key) {
                            svrStates = SandboxComponent.getStatesForUUID(key).states;

                            if (svrStates.connState !== MediaEnum.ServerConnState.READY) {
                                unstartedMediaServers.push(targetMediaServerSet[key]);

                            } else if (!svrStates.features.VOICE_RECORDING) {
                                mediaServerWithoutFeature.push(targetMediaServerSet[key].ip);
                                delete targetMediaServerSet[key];
                            }
                        });

                        return {
                            mediaServerWithoutFeature: mediaServerWithoutFeature,
                            unstartedMediaServers: unstartedMediaServers
                        }
                    },

                    /**
                     * Returns a custom object which's keys are MediaServer uuids
                     * @note 0c9d73b9-0068-527e-ffff2db6f98afda5: {
                     *          ip: "10.7.7.214:7091",
                     *          name: "Testmusicserver",
                     *          zones: [{
                     *              name: "2 Lukas's Zone",
                     *              playerid: 2,
                     *              uuidAction: "0c9d73b9-0068-527e-ffff2db6f98afda5"
                     *          }]
                     *      }
                     * @param selectedZoneUuids     array of audio zone control uuids
                     * @private
                     * @returns Object
                     */

        /*
                    _getTargetMediaServerSet: function _getTargetMediaServerSet(selectedZoneUuids) {
                        var currentZone,
                            currentMediaServer,
                            obj = {};

                        selectedZoneUuids.forEach(function (zoneUuid) {
                            currentZone = ActiveMSComponent.getStructureManager().getControlByUUID(zoneUuid);
                            currentMediaServer = ActiveMSComponent.getStructureManager().getMediaServerSet()[currentZone.details.server];

                            if (!obj.hasOwnProperty(currentZone.details.server)) {
                                obj[currentZone.details.server] = {};
                                obj[currentZone.details.server].ip = currentMediaServer.host;
                                obj[currentZone.details.server].name = currentMediaServer.name;
                                obj[currentZone.details.server].zones = [];
                            }

                            obj[currentZone.details.server].zones.push({
                                name: currentZone.name,
                                playerid: currentZone.details.playerid,
                                uuidAction: currentZone.uuidAction
                            });
                        });

                        return obj;
                    },

                    _getAudioRecordingPopupSubTitle: function _getAudioRecordingPopupSubTitle(targetMediaServerSet) {
                        var counter = 0;

                        Object.values(targetMediaServerSet).forEach(function (mediaServer) {
                            counter += mediaServer.zones.length;
                        });

                        return _('media.announcement.selected-zones', { count: counter });
                    },

                    _isAudioRecordingIsSupported: function _isAudioRecordingIsSupported(audioZoneCentralControl) {
                        var returnValue = false,
                            mediaServerUuid,
                            currentStates,
                            checkedMediaServers = [];

                        if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.IOS ||
                            PlatformComponent.getPlatformInfoObj().platform === PlatformType.Android ||
                            PlatformComponent.getPlatformInfoObj().platform === PlatformType.DeveloperInterface) {

                            audioZoneCentralControl.details.controls.forEach(function (entry) {
                                mediaServerUuid = entry.control.details.server;

                                if (!checkedMediaServers.includes(mediaServerUuid)) {
                                    currentStates = SandboxComponent.getStatesForUUID(mediaServerUuid).states;

                                    if (currentStates) {
                                        returnValue = returnValue || currentStates.features.VOICE_RECORDING;
                                    }

                                    checkedMediaServers.push(mediaServerUuid);
                                }
                            });
                        }

                        return returnValue;
                    },

                    _showRecordingPopup: function _showRecordingPopup(targetMediaServerSet) {
                        var config = {
                                title: _('media.menu.announcement'),
                                subTitle: this._getAudioRecordingPopupSubTitle(targetMediaServerSet),
                                messageColor: window.Styles.colors.red,
                                buttons: [{ title: _('media.announcement.send-recording'), color: Color.STATE_INACTIVE }],
                                buttonCancel: true,
                                icon: Icon.AudioZone.MIC_COVER_ICON,
                                color: Color.STATE_INACTIVE
                            },
                            zonesToPlay,
                            playPromises = [],
                            uploadPromises = [];

                        return NavigationComp.showPopup(config, PopupType.AUDIO_RECORD_POPUP).then(function(audioResult) {
                            MediaServerComp.initVoiceRecorderExtension();

                            Object.values(targetMediaServerSet).forEach(function (mediaServer) {
                                uploadPromises.push(MediaServerComp.uploadAudioFile(audioResult, mediaServer.ip));
                            }.bind(this));

                            return Q.all(uploadPromises).then(function () {
                                Object.values(targetMediaServerSet).forEach(function (mediaServer) {
                                    zonesToPlay = mediaServer.zones.map(function (zone) {
                                        return zone.playerid;
                                    });

                                    playPromises.push(MediaServerComp.playRecordedFile(audioResult, zonesToPlay, mediaServer.ip));
                                });

                                return Q.all(playPromises).then(function () {
                                    return MediaServerComp.deleteFile(audioResult).then(function () {
                                        return MediaServerComp.destroyAllExtensions();
                                    });
                                });
                            }.bind(this));
                        }.bind(this));
                    }*/


    };
});
