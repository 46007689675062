import * as React from "react"
import Svg, { Path } from "react-native-svg"

function UserGroup(props) {
    return (
        <Svg isreact="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path d="M15.88 7.039c.69.96 1.505 1.417 2.539 1.417.266 0 1.09-.076 1.694-.339.032 2.12-.82 3.989-2.113 5.094v1.675l3.938 1.483c1.234.465 2.062 1.71 2.062 3.1v1.707a.56.56 0 01-.545.574h-4.91v-2.28c0-1.873-1.119-3.554-2.785-4.181l-3.215-1.212v-.34c1.34-1.353 2.217-3.584 2.11-5.857.463-.227.895-.507 1.225-.841zm-6.546 0c.69.96 1.504 1.417 2.538 1.417.267 0 1.09-.076 1.695-.339.032 2.12-.819 3.989-2.112 5.094v1.675l3.938 1.483c1.234.465 2.062 1.71 2.062 3.1v1.707a.56.56 0 01-.546.574H.545A.56.56 0 010 21.176V19.47c0-1.39.83-2.636 2.062-3.1L6 14.887V13.21c-1.368-1.156-2.275-3.157-2.08-5.493.913.545 1.512.665 2.212.665 1.16 0 2.43-.559 3.202-1.342zM8.727 2.25c2.312 0 4.244 2.014 4.728 4.697-.275.098-.596.2-.966.295-1.208.244-1.926-.154-2.641-1.47-.1-.183-.276-.27-.485-.29a.545.545 0 00-.473.31c-.373.754-1.688 1.44-2.758 1.44-.526 0-.979-.019-2.023-.722.61-2.457 2.438-4.26 4.618-4.26zm6.546 0c2.311 0 4.243 2.014 4.729 4.697-.275.098-.597.2-.967.295-1.207.244-1.925-.154-2.64-1.47-.101-.183-.277-.27-.485-.29a.542.542 0 00-.474.309c-.161.326-.501.634-.917.884-.262-1.39-.86-2.626-1.688-3.58.717-.528 1.546-.845 2.442-.845z" />
        </Svg>
    )
}

export default UserGroup
