import globalStyles from "GlobalStyles";
import {View, Animated} from "react-native-web";
import svgGradient from "svg-gradient";
import svgToDataURL from "svg-to-dataurl"
import LxReactSliderAnimatedLabel from "./LxReactSliderAnimatedLabel";
import {defaultSettings, defaultColors} from "./LxReactSliderDefaultSettings";
import {isValidSliderValue} from "./LxReactSliderUtils";
import PropTypes from "prop-types";
import React, {useRef, useState} from "react";
import MultiSlider from "react-native-multi-slider";
import {useCallback} from "react";

/**
 * Returns a Slider Component based on react-native-slider (https://github.com/ptomasroos/react-native-multi-slider) which is capable of displaying labels, step marks and can handle ranges
 * @prop {number} [min = 0] Minimum Value of slider
 * @prop {number} [max = 100] Maximum Value of slider
 * @prop {number} [step = 1] Step size of the slider
 * @prop {number | number[]} [value = 0] Initial value/values of the slider, must be updated in onValueChanged, otherwise slider resets to initial value
 * @prop {Function} onValueChanged Callback function that is triggered when slider was moved, returns new value(s) as param
 * @prop {Function} onDraggingChanged Callback function that is triggered when dragging starts or ends, parameter is boolean isStarted
 * @prop {Boolean} [showLabels = false] Show Label with the value above the thumbs
 * @prop {Array} [gradientColors] Colors to make a gradient track, the active track color will be transparent
 * @prop {Color} [activeColor = brand] Color of the selected track part and the label texts
 * @prop {Color} [unselectedTrackColor = mantisgreen] Color of the unselected track part
 * @prop {Boolean} [disabled = false] If false, taps on switch are not processed
 * @prop {Object} [containerStyle = null]:   Style to apply on slider container
 */
function LxReactHorizontalSlider(props) {

    const [containerSize, setContainerSize] = useState(0);
    const labelOpacityRef = useRef(0);
    const optionsRef = useRef({
        isDragging: false
    })

    const getSelectedTrackColor = () => {
        if (props.disabled) {
            return defaultColors.SELECTED_DISABLED;
        } else if (props.gradientColors) {
            return defaultColors.GRADIENT_TRACK;
        } else {
            return props.activeColor || defaultColors.SELECTED;
        }
    }

    const getLabelColor = () => {
        if (props.gradientColors) {
            return props.gradientColors[1];
        } else {
            return props.activeColor || defaultColors.SELECTED;
        }
    }

    const getUnselectedTrackColor = () => {
        let defaultColor = defaultColors.UNSELECTED;
        if (props.disabled) {
            return defaultColors.UNSELECTED_DISABLED;
        } else if (props.gradientColors) {
            defaultColor = defaultColors.GRADIENT_TRACK
        }
        return props.unselectedTrackColor || defaultColor;
    }

    const getThumbColor = () => {
        return props.disabled ? defaultColors.THUMB_DISABLED : defaultColors.THUMB;
    }

    const onDraggingChanged = (started, lastValue) => {
        toggleLabels(started)
        optionsRef.current.isDragging = started;
        if (props.onDraggingChanged) {
            props.onDraggingChanged(started, lastValue);
        }
    }

    const toggleLabels = (show) => {
        if (!props.showLabels) {
            return
        }
        Animated.timing(labelOpacityRef.current, {
            toValue: show ? 1 : 0,
            duration: 200
        }).start();
    }

    const getValidGradientColor = (colorString) => {
        if (colorString.startsWith("hs")) {
            return LxColorUtils.getCSSRGBPropertyFromColorObject(LxColorUtils.createHSVObjectFromHSVString(colorString))
        } else {
            return colorString;
        }
    }

    let gradientUrl = null;
    if (props.gradientColors) {
        let gradientColors = props.gradientColors.map(color => getValidGradientColor(color));
        let cssGradientString = 'linear-gradient(90deg,' + gradientColors[0] + " 0%," + gradientColors[1] + " 100%)";
        gradientUrl = svgToDataURL(svgGradient(cssGradientString));
    }

    const getContainerSize = (event) => {
        setContainerSize(event.nativeEvent.layout.width)
    }

    let values;
    if (isValidSliderValue(props.value)) {
        if (Array.isArray(props.value)) {
            values = props.value;
        } else {
            values = [props.value];
        }
    } else {
        values = [props.min || defaultSettings.VALUE];
    }

    /**
     * Checks if the value provided was an array (multi-value-slider), if so, ensures the value returned will be an array too.
     * If it was a scalar datatype number (regular single value slider), it returns a number too.
     * @param newVal
     * @returns {*|*[]}
     */
    const getCallbackValue = useCallback((newVal) => {
        if (Array.isArray(props.value)) {
            return Array.isArray(newVal) ? newVal : [newVal];
        } else if (Array.isArray(newVal)) {
            return newVal[0];
        } else {
            return newVal;
        }
    }, [props.value]);

    return (
        <View style={[styles.container, props.containerStyle]} onLayout={getContainerSize}>
            <MultiSlider
                disabled={props.disabled}
                min={isValidSliderValue(props.min) ? props.min : defaultSettings.MIN}
                max={isValidSliderValue(props.max) ? props.max : defaultSettings.MAX}
                step={isValidSliderValue(props.step) ? props.step : defaultSettings.STEP}
                values={values}
                onValuesChange={(newVal) => {
                    props.onValueChanged(getCallbackValue(newVal), optionsRef.current.isDragging);
                }}
                onValuesChangeStart={() => onDraggingChanged(true)}
                onValuesChangeFinish={(lastValues) => {
                    return onDraggingChanged(false, getCallbackValue(lastValues))
                }}
                sliderLength={containerSize}
                stepMarkerStyle={styles.mark}
                trackStyle={styles.track}
                markerStyle={{
                    ...styles.thumb,
                    backgroundColor: getThumbColor(), ...props.thumbStyle, ...props.disabledThumbStyle
                }}
                disabledMarkerStyle={{
                    ...styles.thumb,
                    backgroundColor: getThumbColor(), ...props.thumbStyle
                }}
                selectedStyle={[styles.selectedTrack, {backgroundColor: getSelectedTrackColor()}]}
                unselectedStyle={{backgroundColor: getUnselectedTrackColor()}}
                enabledOne={!props.disabled}
                enabledTwo={!props.disabled}
                snapped={props.step > 1}
                enableLabel={props.showLabels}
                showSteps={false}
                showStepLabels={false}
                customLabel={LxReactSliderAnimatedLabel}
                labelOpacity={labelOpacityRef.current}
                labelTextStyle={{...styles.labelText, color: getLabelColor()}}
                imageBackgroundSource={{uri: gradientUrl, height: 4}}
            />
        </View>
    )
}

const styles = {
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    selectedTrack: {
        height: 6
    },
    track: {
        height: 4,
        borderRadius: 4
    },
    thumb: {
        height: 20,
        width: 20,
        border: "none",
        marginBottom: -5,
        ...globalStyles.shadows.thumbShadow
    },
    mark: {
        backgroundColor: globalStyles.colors.white,
        height: 2,
        width: 2,
        borderRadius: 1
    },
    labelText: {
        fontSize: globalStyles.fontSettings.sizes.small,
        fontFamily: globalStyles.fontSettings.families.regular,
        fontWeight: 600,
        lineHeight: 16,
    }
}


LxReactHorizontalSlider.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    onValueChanged: PropTypes.func,
    onDraggingChanged: PropTypes.func,
    showLabels: PropTypes.bool,
    gradientColors: PropTypes.arrayOf(PropTypes.string),
    activeColor: PropTypes.string,
    unselectedTrackColor: PropTypes.string,
    disabled: PropTypes.bool,
    containerStyle: PropTypes.object,
    thumbStyle: PropTypes.object,
    disabledThumbStyle: PropTypes.object
}

export default LxReactHorizontalSlider;
