import GroupsScreen from "../groups/groupsScreen.jsx";
import GroupCardContentScreen from "../groupCardContent/groupCardContentScreen.jsx";
import {createStackNavigator, TransitionPresets} from '@react-navigation/stack';
import {
    TopNavigator,
    useSetURLHook,
    LxReactCardScreenTopNavigator,
    navigatorConfig,
    AmbientContext
} from "LxComponents";
import Icons from "IconLib";
import {useContext} from "react";

const screenOptions = (...params) => {
    return {
        ...TopNavigator.forDefault(),
        ...TransitionPresets.ModalFadeTransition,
        ...navigatorConfig(LxReactCardScreenTopNavigator(...params))
    }
}

function RoomsScreen({navigation, route}) {
    const {isAmbientMode} = useContext(AmbientContext)
    useSetURLHook({
        urlPart: UrlStartLocation.ROOM,
        appendIndex: 1
    });

    const Stack = createStackNavigator(),
        customGroupTitles = ActiveMSComponent.getStructureManager().getCustomGroupTitles(),
        headerTitle = customGroupTitles[GroupTypes.ROOM]

    const getEmptyScreenConfig = () => {
        return {
            iconSrc: Icons.Search,
            title: headerTitle,
            subtitle: _('empty.group-screen.title'),
            message: _('empty.group-screen.message')
        };
    }

    return (
        <Stack.Navigator screenOptions={(params) => screenOptions(params, isAmbientMode)}>
            <Stack.Screen name={ScreenState.RoomsOverview} component={GroupsScreen} initialParams={
                {
                    groupType: GroupTypes.ROOM,
                    emptyScreenConfig: getEmptyScreenConfig(),
                    headerTitle: headerTitle
                }
            } />
            <Stack.Screen name={ScreenState.RoomDetail} component={GroupCardContentScreen}/>
        </Stack.Navigator>
    );
}

export default RoomsScreen;
