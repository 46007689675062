'use strict';

define("PoolDaytimerCalendarEntries", ["DaytimerCalendarEntries", "PoolControlEnums"], function (DaytimerCalendarEntries, PoolControlEnums) {
    return class PoolDaytimerCalendarEntries extends DaytimerCalendarEntries {
        //region Static
        static Template = function () {
            var createLegendView = function createLegendView(ctrl) {
                return '' + '<div class="legend-view">' + '<div class="legend-list legend-list--pool">' + _createLegendViewEntry(ctrl, PoolControlEnums.PoolCycleMode.FILTER, ctrl.type) + _createLegendViewEntry(ctrl, PoolControlEnums.PoolCycleMode.FLUSHING, ctrl.type) + _createLegendViewEntry(ctrl, PoolControlEnums.PoolCycleMode.CIRCULATE, ctrl.type) + '</div>' + '<div class="legend-button">' + ImageBox.getResourceImageWithClasses('resources/Images/Controls/IRoom/info.svg', 'legend-button__icon') + '</div>' + '</div>';
            };

            var _createLegendViewEntry = function _createLegendViewEntry(ctrl, modeNr, type) {
                return '<div id="mode-nr-' + modeNr + '" class="legend-list__entry">' + '<div class="entry__circle"></div>' + '<div class="entry__name">' + ctrl.getNameOfPoolCycleMode(modeNr) + '</div>' + '</div>';
            };

            return {
                getLegend: createLegendView
            };
        }; //endregion Static

        constructor(control, onScroll) {
            super(...arguments); // the pools daytimer is passed in here as this.control - so be sure to have the poolControl itself ready too.

            this.poolControl = ActiveMSComponent.getControlByUUID(this.control.uuidParent);
        }

        /**
         * Returns a jquery element that contains the legend for these calendar entries
         * @return {jQuery|HTMLElement}
         */
        getLegendTemplate() {
            return $(PoolDaytimerCalendarEntries.Template.getLegend(this.control));
        }

        getCircleColorForMode(modeNr) {
            return {
                color: this.poolControl.getColorOfPoolCycleMode(modeNr),
                cssAttr: 'background-color'
            };
        }

        getImageElementForEntry(entryInfoObj) {
            var imagePath = this.poolControl.getIconPathOfPoolCycleMode(entryInfoObj.entry.cycleMode);
            return $(ImageBox.getResourceImageWithClasses(imagePath, 'entry__icon cycle-mode-image'));
        }

        getEntryBackgroundColor(entry) {
            return this.poolControl.getColorOfPoolCycleMode(entry.cycleMode);
        }

    };
});
