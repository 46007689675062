'use strict';

define(['CoverView'], function (CoverView) {
    class CurrentCoverView extends GUI.View {
        constructor(control) {
            super($("<div class='current-cover-view'></div>"));
            Object.assign(this, StateHandler.Mixin);
            this.control = control;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.coverView = new CoverView(this.element, this._getIconObject(cloneObject(this.control.getStates())));
                this.addToHandledSubviews(this.coverView);
            }.bind(this));
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function () {
                this._registerForStates(this.control.uuidAction, ['coverurl']);
            }.bind(this));
        }

        viewWillDisappear() {
            this._unregisterStates(this.control.uuidAction);

            return super.viewWillDisappear(...arguments);
        }

        destroy() {
            this._unregisterStates(this.control.uuidAction);

            return super.destroy(...arguments);
        }

        receivedStates(states) {
            var iconObj = this._getIconObject(cloneObject(states));

            this.coverView.setIconObject(iconObj);
        }

        _getIconObject(item) {
            var ctor = Controls.AudioZoneControl.MediaBrowserBase.getConstructorForItem(item);
            return ctor.getIconObjForItem(item);
        }

    }

    Controls.AudioZoneControl.CurrentCoverView = CurrentCoverView;
    return Controls.AudioZoneControl.CurrentCoverView;
});
