import {useEffect, useContext} from "react";
import {LxReactDeviceSearchContext} from "LxComponents"


export default function useTrackRouteInSearchContext(name, route) {
    const {addToRoute, removeFromRoute} = useContext(LxReactDeviceSearchContext);

    useEffect(() => {
        addToRoute(name, route.params, route.key);
        return () => {
            removeFromRoute(name, route.params, route.key);
        }
    }, []);
}