'use strict';

define(["AudioZoneV2ControlEnums", "AudioZoneV2DetailedContentBase", "FavoritesManager", "EditSpecificFavScreen"], function (AudioZoneV2ControlEnums, AudioZoneV2DetailedContentBase, FavoritesManager, EditSpecificFavScreen) {
    return class FavoriteEditScreen extends AudioZoneV2DetailedContentBase {
        //region Getter
        get RIGHT_SIDE_BUTTON() {
            let baseVal = super.RIGHT_SIDE_BUTTON;
            baseVal.SAVE = "right-side__touch-area--save";
            return baseVal;
        } //endregion Getter


        constructor(details) {
            super(...arguments);
            applyMixins(this, MediaStateHandlerV2.Mixin);
            this.zoneFavorites = [];
            this._ogZoneFavorites = [];
        }

        getAnimation() {
            return AnimationType.FADE;
        }

        titleBarText() {
            return _("audio-server.favorites.edit.title");
        }

        getTableView() {
            return new GUI.EditableTableView(this.tableViewDataSource, this.tableViewDelegate);
        }

        viewDidLoad() {
            Debug.Control.AudioZone.ZoneFavoritesEdit && console.log(this.viewId, "viewDidLoad");
            var args = arguments;
            return this._getFavs().then(() => {
                return super.viewDidLoad(...args);
            });
        }

        viewWillAppear() {
            Debug.Control.AudioZone.ZoneFavoritesEdit && console.log(this.viewId, "viewWillAppear");
            return Q(super.viewWillAppear(...arguments) || true).then(function () {
                this._registerForMediaServerEvents(this.EVENT_TYPES.ZONE_FAVORITES);
            }.bind(this));
        }

        viewWillDisappear() {
            Debug.Control.AudioZone.ZoneFavoritesEdit && console.log(this.viewId, "viewWillDisappear");

            this._sendReorderCommand();

            return super.viewWillDisappear(...arguments);
        }

        destroy() {
            Debug.Control.AudioZone.ZoneFavoritesEdit && console.log(this.viewId, "destroy");

            this._unregisterForMediaServerEvents(this.EVENT_TYPES.ZONE_FAVORITES);

            return super.destroy(...arguments);
        }

        reloadTable() {
            Debug.Control.AudioZone.ZoneFavoritesEdit && console.log(this.viewId, "reloadTable");
            return super.reloadTable(...arguments).then(function () {
                return this._setEditingModeForSections();
            }.bind(this));
        }

        titleBarActionRight(buttonId) {
            Debug.Control.AudioZone.ZoneFavoritesEdit && console.log(this.viewId, "titleBarActionRight");

            if (buttonId === this.RIGHT_SIDE_BUTTON.SAVE) {
                this._unregisterForMediaServerEvents(this.EVENT_TYPES.ZONE_FAVORITES); // avoids receiving events when already closing.


                return this.ViewController.navigateBack();
            } else {
                return super.titleBarActionRight(...arguments);
            }
        }

        closeAction() {
            Debug.Control.AudioZone.ZoneFavoritesEdit && console.log(this.viewId, "closeAction");

            this._unregisterForMediaServerEvents(this.EVENT_TYPES.ZONE_FAVORITES);

            return super.closeAction(...arguments);
        }

        receivedZoneFavoritesReloadEvent(data) {
            Debug.Control.AudioZone.ZoneFavoritesEdit && console.log(this.viewId, "receivedZoneFavoritesReloadEvent");
            return this._getFavs().then(function () {
                return this.reloadTable();
            }.bind(this));
        }

        setTableContent() {
            Debug.Control.AudioZone.ZoneFavoritesEdit && console.log(this.viewId, "setTableContent");
            this.tableContent = [this._getDescriptionSection(), this._getAddSection(), this._getFavSection(), this._getMigrateSection()];
            return super.setTableContent(...arguments);
        }

        getRightVCButtonIcon() {
            return null;
        }

        onSpecificFavUpdate(data) {
            if (data && data.hasOwnProperty("action") && data.hasOwnProperty("fav")) {
                Debug.Control.AudioZone.ZoneFavoritesEdit && console.log(this.viewId, "onSpecificFavupdate: ", data);
                var fav = data.fav,
                    favIdx = this.zoneFavorites.findIndex(function (zoneFav) {
                        return zoneFav.audiopath === fav.audiopath;
                    });

                if (favIdx !== -1) {
                    switch (data.action) {
                        case EditSpecificFavScreen.ACTIONS.MODIFY:
                            this.zoneFavorites[favIdx] = fav;
                            break;

                        case EditSpecificFavScreen.ACTIONS.DELETE:
                            this.zoneFavorites.splice(favIdx, 1);
                            break;
                    }

                    this.reloadTable();
                }
            }
        }

        _getFavs() {
            Debug.Control.AudioZone.ZoneFavoritesEdit && console.log(this.viewId, "_getFavs");
            return FavoritesManager.shared(this.control).favorites().then(function (favs) {
                Debug.Control.AudioZone.ZoneFavoritesEdit && console.log(this.viewId, "_getFavs > updated");
                this.zoneFavorites = favs;
                this._ogZoneFavorites = cloneObject(favs);
            }.bind(this));
        }

        _getDescriptionSection() {
            return {
                footerTitle: _('audio-server.favorites.edit.desc', {
                    playerName: this.control.getName()
                })
            };
        }

        _getAddSection() {
            return {
                rows: [{
                    content: {
                        title: _('audio-server.favorites.edit.new'),
                        leftIconSrc: Icon.ADD,
                        leftIconColor: window.Styles.colors.stateActive,
                        clickable: true
                    },
                    action: this._addNewFav.bind(this)
                }]
            };
        }

        _getFavSection() {
            return {
                rows: this.zoneFavorites.map(function (fav, idx) {
                    return Controls.AudioZoneV2Control.MediaBrowserV2Favorites.getZoneFavoriteEditCellForFavAtIdx(fav, this.details, idx, function onEditButtonTapped(section, row, tableView) {
                        // Attention: Don't use fav, it won't work after reordering the favorites
                        var selectedFav = this.zoneFavorites[row];
                        this.control.audioserverComp.AudioViewController.showState(AudioZoneV2ControlEnums.ScreenState.EDIT_SPECIFIC_FAV, null, {
                            delegate: this,
                            control: this.control,
                            favorite: cloneObject(selectedFav),
                            favorites: cloneObject(this.zoneFavorites)
                        }, AnimationType.MODAL);
                    }.bind(this), function onPlusChanged(cell, section, row, tableView, selected) {
                        this.zoneFavorites[row].plus = selected; // Sync our dataset with the Audioserver, then send the plus change event

                        NavigationComp.showWaitingFor(this._sendReorderCommand().then(function () {
                            var fav = this.zoneFavorites[row];
                            return FavoritesManager.shared(this.control).setFavoriteInPlusLoop(fav.id, fav.plus);
                        }.bind(this)));
                    }.bind(this));
                }.bind(this)),
                didMoveCell: function didMoveCell(section, row, tableView, cell, oldIdx, newIdx) {
                    this.zoneFavorites.move(oldIdx, newIdx);
                }.bind(this)
            };
        }

        _getMigrateSection() {
            return {
                rows: [{
                    content: {
                        title: _("media.zone.fav.get-from"),
                        titleColor: window.Styles.colors.green,
                        clickable: true
                    },
                    action: function () {
                        return this.control.audioserverComp.copyRoomFavsFrom(this.control, this.control.audioserverComp.AudioViewController);
                    }.bind(this)
                }, {
                    content: {
                        title: _("media.zone.fav.send-to", {
                            zoneName: this.control.getName()
                        }),
                        titleColor: window.Styles.colors.green,
                        clickable: true
                    },
                    action: function () {
                        return this.control.audioserverComp.copyRoomFavsTo(this.control, this.control.audioserverComp.AudioViewController);
                    }.bind(this)
                }]
            };
        }

        _getLeftSideForTitleBar() {
            return TitleBarCfg.TEXT;
        }

        _sendReorderCommand() {
            var def = Q.defer(),
                currentFavOrder = this._getFavOrder(this.zoneFavorites),
                ogFavOrder = this._getFavOrder(this._ogZoneFavorites);

            if (currentFavOrder !== ogFavOrder) {
                def.resolve(NavigationComp.showWaitingFor(FavoritesManager.shared(this.control).sendNewFavoritesOrder(this.zoneFavorites)));
            } else {
                // The order did not change, nothing to do here...
                def.resolve();
            }

            return def.promise;
        }

        _getRightSideTitleBarButtons() {
            return [this._getRightSideTitleBarButtonElm(this.RIGHT_SIDE_BUTTON.SAVE, Icon.TitleBar.TICK)];
        }

        _addNewFav() {
            this.control.audioserverComp.selectNewZoneFavorite(this.details, this.control.audioserverComp.AudioViewController);
        }

        _setEditingModeForSections() {
            return Q(this.tableView.setEditingModeForSection(2, true, false, true, 0));
        }

        _getFavOrder(favs) {
            if (favs && favs instanceof Array) {
                return favs.map(function (fav) {
                    return fav.id;
                }).join(",");
            } else {
                return "";
            }
        }

    };
});
