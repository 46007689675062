import {createStackNavigator, TransitionPresets} from "@react-navigation/stack";
import MessageOverviewScreen from "../LxReactMessageCenter/MessageOverviewScreen";
import {
    MessageDetailScreen,
    TopNavigator,
    navigatorConfig,
    LxReactCardScreenTopNavigator
} from "LxComponents";

const screenOptions = (...params) => {
    return {
        ...TopNavigator.forDefault(),
        ...TransitionPresets.ModalFadeTransition,
        ...navigatorConfig(LxReactCardScreenTopNavigator(...params))
    }
}

function AmbientSystemStatusStackNavigator(props) {
    const AmbientSystemStatusStack = createStackNavigator();
    return <AmbientSystemStatusStack.Navigator
        initialRouteName={MessageOverviewScreen.name}
        screenOptions={
            (params) => {
                return {
                    ...screenOptions(params, true),
                    headerShown: false,
                    inAmbientTabNav: true
                }
            }}>
        <AmbientSystemStatusStack.Screen name={MessageOverviewScreen.name} component={MessageOverviewScreen} />
        <AmbientSystemStatusStack.Screen name={MessageDetailScreen.name} component={MessageDetailScreen} />
    </AmbientSystemStatusStack.Navigator>
}

export default AmbientSystemStatusStackNavigator;
