'use strict';

define("AtheneMediaHandler", ["AtheneControlEnums", "WebsocketSignaling", "ActivityImage", "sdp-transform"], function (AtheneEnums, WebsocketSignaling, ActivityImage, sdpTransform) {
    Controls.AtheneControl = Controls.AtheneControl || {};
    Controls.AtheneControl.SingleTons = Controls.AtheneControl.SingleTons || {};

    if (Controls.AtheneControl.SingleTons.MediaHandler) {
        return Controls.AtheneControl.SingleTons.MediaHandler;
    } else {
        class MediaHandler {
            //region Static
            static MAX_SIGNALING_ERROR_CNT = 5;
            static SIGNALING_RETRY_TIMEOUT = 5 * 1000;
            static LX_STUN_TURN = "stun.loxonecloud.com:3478";
            static LAST_ACTIVITY_PATH = "/jpg/image.jpg";
            static COMMANDS = {
                REBOOT: "/dev/sys/reboot",
                DETAILED_TECH_REPORT: "/dev/sys/detailedtechreport"
            };
            static DEVICE_KIND = {
                AUDIO: {
                    INPUT: "audioinput",
                    OUTPUT: "audiooutput"
                },
                VIDEO: {
                    INPUT: "videoinput"
                }
            };
            static REMOTE_STREAM_STATE = {
                ERROR: -1,
                IDLE: 0,
                ESTABLISHING: 1,
                ESTABLISHED: 2,
                VIDEO_PENDING: 3
            };
            static REMOTE_STREAM_ERROR = {
                NONE: 0,
                RTC_PEER_CONNECTION_INIT: 1,
                GET_USER_MEDIA: 2,
                GET_USER_MEDIA_RESPONSE: 3,
                CREATE_SESSION_DESCRIPTION: 4,
                SET_SESSION_DESCRIPTION: 5,
                ICE_CANDIDATES_TIMEOUT: 6,
                SEND_SDP_OFFER: 7,
                KICKED: 8,
                NO_ICE_MATCH_FOUND: 9,
                EXCESSIVE_TEMP: 10
            };
            static OFFER_ERROR = WebsocketSignaling.OFFER_ERROR;
            static CALL_STATE = WebsocketSignaling.CALL_STATE;
            static KICK_TYPE = WebsocketSignaling.KICK_TYPE;
            static LOCAL_STREAM_STATE = {
                ERROR: -1,
                IDLE: 0,
                ESTABLISHING: 1,
                ESTABLISHED: 2
            };
            static LOCAL_STREAM_ERROR = {
                NONE: 0,
                GET_USER_MEDIA: 2,
                GET_USER_MEDIA_RESPONSE: 3
            };
            static FEATURES = {
                MS_TOKEN: "12.04.05.02"
            };
            static PERMISSIONS = [{
                permission: MsPermission.NONE,
                revoke: true
            }];
            static _DELEGATE_FN = {
                HEALTH_CHANGED: "onHealthChanged",
                COM_READY: "onComReady",
                COM_CLOSED: "onComClosed",
                COM_ERROR: "onComError",
                CALL_STATE_CHANGE: "onCallStateChanged",
                REMOTE_STREAM_CHANGED: "onRemoteStreamStateChanged",
                LOCAL_STREAM_CHANGED: "onLocalStreamStateChanged",
                LOCAL_DEVICES_CHANGED: "onLocalDevicesChanged",
                ON_NEW_LAST_ACTIVITIES: "onNewLastActivities",
                EXCESSIVE_TEMP: "onExcessiveTemp",
                ONLINE_CHANGED: "onInternetChange"
            };
            static _ARBITRARY_METHODS = {
                INFO: "info",
                GET_LAST_ACTIVITIES: "getLastActivities"
            };
            static _VIDEO_STREAM_QUOTA = 3;

            static get requiresIOSSpeakerFix() {
                if (PlatformComponent.isIOS() && !PlatformComponent.isIpad) {
                    let { version } = PlatformComponent.getPlatformInfoObj();
                    // Thankfully, iOS 15.4.0 fixes this issue: https://bugs.webkit.org/show_bug.cgi?id=218012
                    return versionCompare("15.4.0", version) === 1;
                } else {
                    return false;
                }
            }

            static get CrashableDeviceMap() {
                return {
                    "iPhone15,2": "iPhone 14 Pro",
                    "iPhone15,3": "iPhone 14 Pro Max"
                }
            }

            static get mayCrashOnVideo() {
                let { model, version } = PlatformComponent.getPlatformInfoObj();

                return (
                    Object.keys(MediaHandler.CrashableDeviceMap).includes(model) &&
                    versionCompare("16.0.3", version) === 1
                    ) || Debug.Control.Athene.DevFeatures.SIMULATE.IPHONE_14_PRO_CRASH
            }

            static get offerException() {
                let extensions = [];

                if (this.mayCrashOnVideo) {
                    extensions.push(WebsocketSignaling.OFFER_EXTENSION.STATIC_VIDEO);
                }

                return extensions.reduce((sum, right) => {
                    return sum | right;
                }, WebsocketSignaling.OFFER_EXTENSION.NONE);
            }

            static shared(control) {
                if (!control) {
                    throw "No control provided!";
                }

                this.__instances = this.__instances || {};

                if (!this.__instances.hasOwnProperty(control.uuidAction)) {
                    this.__instances[control.uuidAction] = new this(this, control);
                }

                return this.__instances[control.uuidAction];
            }

            static destroy(control) {
                if (control && this.__instances && this.__instances.hasOwnProperty(control.uuidAction)) {
                    this.__instances[control.uuidAction].destroy();

                    delete this.__instances[control.uuidAction];
                }
            }

            static getAudioContext() {
                if (!this.__audioCtx || this.__audioCtx.state !== "running") {
                    this.__audioCtx = new (window.AudioContext || window.webkitAudioContext)();
                }

                return this.__audioCtx;
            }

            static closeAudioContext() {
                if (this.__audioCtx || this.__audioCtx.state !== "closed" && this.__audioCtx.state !== "closing") {
                    return this.__audioCtx.close();
                }

                return Q.resolve();
            }

            static validateSDP(sdp) {
                var manufacturer = PlatformComponent.getPlatformInfoObj().manufacturer,
                    h264regex = /^a=rtpmap:(\d+) H264\/(?:\d+)/mg,
                    h264match = h264regex.exec(sdp),
                    h264ids = [];

                if (manufacturer === "HUAWEI") {
                    while (null != h264match) {
                        h264ids.push(h264match[1]);
                        h264match = h264regex.exec(sdp);
                    }

                    sdp = sdp.replace(/(m=video 9 UDP\/TLS\/RTP\/SAVPF )(\d+(?: \d+)+)/, function (match, p1, p2) {
                        var i,
                            others = p2.split(" ");

                        for (i = 0; i < h264ids.length; i++) {
                            others = others.filter(function (e) {
                                return e !== h264ids[i];
                            });
                        }

                        return p1 + h264ids.join(" ") + " " + others.join(" ");
                    });
                }

                return sdp;
            }

            static deviceSupportsH264(callCnt = 0) {
                var def = Q.defer(),
                    testPc,
                    testVideoTransceiver,
                    h264 = false;

                if (Debug.Control.Athene.DevFeatures.SIMULATE.NO_H264_SUPPORT) {
                    def.resolve(false);
                } else {
                    testPc = new RTCPeerConnection();
                    testVideoTransceiver = testPc.addTransceiver('video', {
                        direction: "recvonly"
                    });
                    testPc.createOffer().then(function (desc) {
                        try {
                            // Simply check if the SDP contains the H264 codec
                            h264 = !!sdpTransform.parse(desc.sdp).media.find(function (media) {
                                return media.type = "video";
                            }).rtp.find(function (rtp) {
                                return rtp.codec === "H264";
                            });
                        } catch (e) {
                            h264 = false;
                        }

                        def.resolve(h264);
                    }, function (e) {
                        def.resolve(h264);
                    });
                }

                return def.promise.then(function (h264Support) {
                    testVideoTransceiver && this.stopTransceiver(testVideoTransceiver);
                    testPc && testPc.close();

                    if (callCnt <= 5) {
                        if (h264Support) {
                            return h264Support;
                        } else {
                            var def = Q.defer(); // Some devices may not tell us that H264 is supported the first time, lets just try it a few times

                            setTimeout(function () {
                                def.resolve(this.deviceSupportsH264(++callCnt));
                            }.bind(this), 150);
                            return def.promise;
                        }
                    } else {
                        return false;
                    }
                }.bind(this));
            }

            static stopTransceiver(transceiver) {
                if (transceiver) {
                    try {
                        if (transceiver.direction === "sendrecv") {
                            transceiver.direction = "recvonly";
                        } else {
                            transceiver.direction = "inactive";
                        }
                    } catch (e) {
                        console.error(e.message);
                    }

                    try {
                        if (typeof transceiver.stop === "function") {
                            transceiver.stop();
                        } else {// The browser doesn't support the RTCRtpTransceiver.stop() function
                        }
                    } catch (e) {
                        console.error(e.message);
                    }

                    transceiver = null;
                }
            }

            static isAuthTokenValid = (tokenObj) => {
                return tokenObj && "msSerial" in tokenObj.jwt.payload;
            } //endregion Static


            //region Getter
            get features() {
                return cloneObject(this._features);
            }

            get connectionIsOnDemand() {
                if (this.control) {
                    return this.control.isTrustMember;
                } else {
                    return false;
                }
            }

            get readyPrms() {
                if (this.connectionIsOnDemand) {
                    return Q.resolve();
                } else if (this.control) {
                    return this._prepareSignaling();
                } else {
                    return null;
                }
            }

            get deviceInfo() {
                return this._deviceInfo || {};
            }

            get peerConnection() {
                if (this._pc && this._pc.signalingState !== "closed") {
                    return this._pc;
                } else {
                    return null;
                }
            }

            /**
             * Whether the Intercom has an active Internet connection
             * @return {boolean}
             */
            get hasInternet() {
                return this._hasInternet;
            }

            get useNewAuthMethod() {
                return Feature.TOKEN_PERMISSION_NONE && "_features" in this && this._features.MS_TOKEN;
            }

            get lastActivitiesPrms() {
                if (this._lastActivitiesDef) {
                    return this._lastActivitiesDef.promise;
                } else {
                    return null;
                }
            }

            get availableFeatures() {
                var platformInfoObj = PlatformComponent.getPlatformInfoObj(),
                    platformVersionParts = platformInfoObj.version.split(".").map(function (part) {
                        return parseInt(part);
                    }),
                    featuresObject = {
                        multiMedia: true,
                        canSetMicrophone: PlatformComponent.isElectron || PlatformComponent.isWebInterface() || PlatformComponent.isDeveloperInterface()
                    };

                if (PlatformComponent.isIOS()) {
                    // iOS 14.5 is the Minimum supported Version
                    if (platformVersionParts[0] <= 13 || platformVersionParts[0] === 14 && platformVersionParts[1] < 5) {
                        featuresObject.multiMedia = false;
                        featuresObject.localizedIncompatibilityNote = _("athene.incompatibility-note.below-ios-14_5");
                    }
                } else if (PlatformComponent.isWebInterface()) {
                    switch (ActiveMSComponent.getMiniserverType()) {
                        case MiniserverType.MINISERVER:
                        case MiniserverType.MINISERVER_GO:
                            // The user might have a change if he has set up its own HTTPS Reverse Proxy or some other kind of HTTPS forwarding
                            if (CommunicationComponent.getRequestProtocol() !== "https://") {
                                featuresObject.multiMedia = false;
                                featuresObject.localizedIncompatibilityNote = _("athene.incompatibility-note.ms-gen-1-no-https");
                            }

                            break;

                        default:
                            if (!(navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia)) {
                                featuresObject.multiMedia = false;
                                featuresObject.localizedIncompatibilityNote = _("athene.incompatibility-note.browser");
                            }

                    }

                    if (featuresObject.multiMedia) {
                        // Check if the webinterface runs on an incompatible device
                        if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
                            var versionParts = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/).slice(1).map(function (vPart) {
                                return parseInt(vPart || 0, 10);
                            }); // iOS 14.5 is the Minimum supported Version

                            if (versionParts[0] <= 13 || versionParts[0] === 14 && versionParts[1] < 5) {
                                featuresObject.multiMedia = false;
                                featuresObject.localizedIncompatibilityNote = _("athene.incompatibility-note.below-ios-14_5");
                            }
                        }
                    }
                } // Finally, check if the Codec is supported


                if (featuresObject.multiMedia) {
                    featuresObject.multiMedia = function () {
                        var testEl = document.createElement("video"),
                            h264;

                        if (testEl.canPlayType) {
                            // Check for h264 support
                            h264 = "" !== (testEl.canPlayType('video/mp4; codecs="avc1.42001F"') || testEl.canPlayType('video/mp4; codecs="avc1.42001F, mp4a.40.2"'));
                        }

                        return h264;
                    }();

                    if (!featuresObject.multiMedia) {
                        featuresObject.localizedIncompatibilityNote = _("athene.incompatibility-note.browser");
                    }
                }

                if (Debug.Control.Athene.DevFeatures.SIMULATE.INCOMPATIBILITY) {
                    featuresObject.multiMedia = false;
                    featuresObject.localizedIncompatibilityNote = "🙅".debugify();
                }

                return featuresObject;
            }

            get remoteStreamState() {
                return this._remoteStreamState;
            }

            get remoteStreamError() {
                var errorObj = null;

                if (this.constructor.REMOTE_STREAM_STATE && this._remoteStreamState === this.constructor.REMOTE_STREAM_STATE.ERROR) {
                    errorObj = {};
                    errorObj.id = this.rsError;
                    errorObj.idText = this.__translRsError(this.rsError);
                    errorObj.info = this.rsErrorInfo;
                }

                return errorObj;
            }

            get remoteVideoSource() {
                return this._remoteVideoStream ? this._remoteVideoStream : null;
            }

            get localInputDevices() {
                if (this._localDevices) {
                    return this._localDevices.audioInput;
                } else {
                    return [];
                }
            }

            get localOutputDevices() {
                if (this._localDevices) {
                    return this._localDevices.audioOutput;
                } else {
                    return [];
                }
            }

            get localInputDevice() {
                if (this._preferredInputDeviceId && this._localDevices) {
                    return this._localDevices.audioInput.find(function (device) {
                        return device.deviceId === this._preferredInputDeviceId;
                    }.bind(this));
                } else {
                    return null;
                }
            }

            get localOutputDevice() {
                developerAttention("DEPRECATED: localOutputDevice may not work as expected!");

                if (this._preferredOutputDeviceId && this._localDevices) {
                    return this._localDevices.audioOutput.find(function (device) {
                        return device.deviceId === this._preferredOutputDeviceId;
                    }.bind(this));
                } else {
                    return null;
                }
            }

            get localStreamState() {
                return this._localStreamState;
            }

            get localStreamError() {
                var errorObj = null;

                if (this.constructor.LOCAL_STREAM_STATE && this._localStreamState === this.constructor.LOCAL_STREAM_STATE.ERROR) {
                    errorObj = {};
                    errorObj.id = this.lsError;
                    errorObj.idText = this.__translLsError(this.lsError);
                    errorObj.info = this.lsErrorInfo;
                }

                return errorObj;
            }

            get isActivelyCalling() {
                return (
                    (this.constructor.LOCAL_STREAM_STATE &&
                        (this._localStreamState ===
                            this.constructor.LOCAL_STREAM_STATE.ESTABLISHING ||
                            this._localStreamState ===
                                this.constructor.LOCAL_STREAM_STATE
                                    .ESTABLISHED)) ||
                    (this.constructor.REMOTE_STREAM_STATE &&
                        (this._remoteStreamState ===
                            this.constructor.REMOTE_STREAM_STATE.ESTABLISHING ||
                            this._remoteStreamState ===
                                this.constructor.REMOTE_STREAM_STATE
                                    .ESTABLISHED))
                );
            }

            get isRemoteVideoPending() {
                return this._remoteStreamState === this.constructor.REMOTE_STREAM_STATE.VIDEO_PENDING;
            }

            get localAudioMuted() {
                return this._localAudioMuted;
            }

            get peerIsReadyToProcessIceCandidates() {
                return this._peerIsReadyToProcessIceCandidates;
            }

            get _offerAction() {
                var tmpAction = this.__offerAction;
                delete this.__offerAction;
                return tmpAction;
            } //endregion Getter


            //region Setter
            set delegate(delegate) {
                if (this.connectionIsOnDemand) {
                    this._notifyDelegates(this.constructor._DELEGATE_FN.COM_READY);
                }

                this.__delegates = this.__delegates || [];
                delegate && this.__delegates.pushIfNoDuplicate(delegate);
            }

            set peerConnection(pc) {
                this._pc = pc;
            }

            set localInputDevice(inputDevice) {
                if (inputDevice && this._preferredInputDeviceId !== inputDevice.deviceId) {
                    this._preferredInputDeviceId = inputDevice.deviceId;
                    this.stopAudio();
                    this.startAudio();
                }
            }

            set localOutputDevice(outputDevice) {
                developerAttention("DEPRECATED: localOutputDevice may not work as expected!");
                var sanitizedDeviceId;

                if (outputDevice && this._preferredOutputDeviceId !== outputDevice.deviceId) {
                    sanitizedDeviceId = outputDevice.deviceId; // The sinkId is inconsistent, we need to sanitize it!

                    if (sanitizedDeviceId === "default") {
                        sanitizedDeviceId = "";
                    }

                    this._preferredOutputDeviceId = sanitizedDeviceId;
                }
            }

            /**
             * Sends cached ICE Candidates if available
             * @param ready
             */
            set peerIsReadyToProcessIceCandidates(ready) {
                this._peerIsReadyToProcessIceCandidates = ready;

                if (ready) {
                    if (this._cachedLocalIceCandidates && Object.values(this._cachedLocalIceCandidates).length) {
                        Object.values(this._cachedLocalIceCandidates).reduce(function (prevPrms, candidates) {
                            return prevPrms.then(function () {
                                return candidates.reduce(function (prevCandidatesPrms, candidate) {
                                    return prevCandidatesPrms.then(function () {
                                        var def = Q.defer();

                                        this._onIceCandidate({
                                            candidate: candidate
                                        });

                                        setTimeout(function () {
                                            def.resolve();
                                        }, 100);
                                        return def.promise;
                                    }.bind(this));
                                }.bind(this), Q.resolve());
                            }.bind(this));
                        }.bind(this), Q.resolve()); // Flushing all local ICE Candidates to the Intercom

                        Object.values(this._cachedLocalIceCandidates).forEach(function (candidates) {
                            candidates.forEach(function (candidate) {
                                this._onIceCandidate({
                                    candidate: candidate
                                });
                            }.bind(this));
                        }.bind(this));
                    }

                    if (this._cachedRemoteIceCandidates && Object.values(this._cachedRemoteIceCandidates).length) {
                        // Flushing all Remote ICE Candidates to the Peer Connection
                        Object.values(this._cachedRemoteIceCandidates).forEach(function (candidates) {
                            candidates.forEach(function (candidate) {
                                this.onAddIceCandidate(candidate);
                            }.bind(this));
                        }.bind(this));
                    }
                }

                this._cachedLocalIceCandidates = {};
                this._cachedRemoteIceCandidates = {};
            }

            set _offerAction(value) {
                this.__offerAction = value;
            } //endregion Setter


            constructor(initiator, control) {
                Object.assign(this, DelegateHandler.Mixin, StateHandler.Mixin);
                this.name = "AtheneMediaHandler";
                this.control = control;
                this.host = null; // Check if the Singleton has been called the right way

                if (!(initiator instanceof Function) && !Controls.AtheneControl.SingleTons.MediaHandler) {
                    throw "The MediaHandler is a Singletone, use it like that! -> MediaHandler.shared(this.control)";
                } // Setter containers


                this.__delegates = []; // Private variables

                this._features = {};
                this._addressResolveDef = Q.defer();
                this._lastActivitiesDef = Q.defer();
                this._signaling = null;
                this._signalingComErrorCnt = 0;
                this._isPolitePeer = true;
                this._makingOffer = false;
                this._ignoreOffer = false;
                this._iceVideoErrorTimeout = null;
                this._iOSSpeakerFixRemoteRtpSender = null;
                this._remoteVideoStream = null;
                this._remoteStreamState = this.constructor.REMOTE_STREAM_STATE.IDLE;
                this._preferredInputDeviceId = null;
                this._preferredOutputDeviceId = "default";
                this._localDevices = null;
                this._localStreamState = this.constructor.LOCAL_STREAM_STATE.IDLE;
                this._localAudioMuted = false;
                this._callState = this.constructor.CALL_STATE.UNKNOWN;
                this._excessiveTempReached = false;
                this._hasInternet = control.isTrustMember;
                this._ttsInfo = [];
                this._lastBellUnix = this._lastBellUnixStates = -1;
                this._thisDeviceMicStreams = [];
                this._parsedLocalDescription = null;
                this._peerIsReadyToProcessIceCandidates = false;
                this._cachedLocalIceCandidates = {};
                this._cachedRemoteIceCandidates = {};
                this._authTokenDeRegs = [];
                this._offerAction = WebsocketSignaling.OFFER_ACTION.NEW;

                this._registerForStates(this.control.uuidAction, this._getStateIds());

                this._boundPauseHandler = function () {
                    this._teardown();

                    this._forceStateUpdate();
                }.bind(this);

                this._boundResumeHandler = function () {
                    this._msConnEstablishedDef.promise.done(function () {
                        delete this._resolvedAddress;
                        delete this._bellState;
                        delete this._deviceState;

                        this._requestStates(this.control.uuidAction, this._getStateIds());
                    }.bind(this));
                }.bind(this);

                this._boundConnEstablished = function () {
                    this._msConnEstablishedDef.resolve();
                }.bind(this);

                this._boundConnClosed = function () {
                    this._msConnEstablishedDef.reject();

                    this._msConnEstablishedDef = Q.defer();
                }.bind(this);

                document.addEventListener("pause", this._boundPauseHandler);
                document.addEventListener("resume", this._boundResumeHandler);
                window.onbeforeunload = this._boundPauseHandler;
                this._msConnEstablishedDef = Q.defer();

                this._msConnEstablishedDef.resolve();

                this._connEstablishedReg = CompChannel.on(ActiveMSComp.ECEvent.ConnEstablished, this._boundConnEstablished);
                this._connClosedReg = CompChannel.on(ActiveMSComp.ECEvent.ConnClosed, this._boundConnClosed);

                this._updateFeatures("0");
            }

            getAuthToken() {
                Debug.Control.Athene.Media && console.log(this.name, "AtheneMediaHandler", "getAuthToken");

                if (this.useNewAuthMethod) {
                    Debug.Control.Athene.Media && console.log(this.name, "Using new auth method");
                    let commPerms = CommunicationComponent.getRequiredConnectionPermission(),
                        {
                            username
                        } = ActiveMSComponent.getCurrentCredentials(),
                        commToken = CommunicationComponent.getToken(commPerms, username),
                        permission = this.constructor.PERMISSIONS.map(perm => {
                            return perm.permission;
                        }).reduce(function (sum, right) {
                            return sum | right; // Add the permissions to one bitmap
                        }),
                        authTokenObj = CommunicationComponent.getToken(permission, username);

                    if (this.constructor.isAuthTokenValid(authTokenObj)) {
                        return Q.resolve(authTokenObj.token);
                    } else {
                        // Request a specific token, we don't want to just register for a permission as the Token is
                        // sent to the intercom, to prevent any Token leakage we simply request a Token with Permission NONE
                        // As there is a token with just the specific permission we then can simply register for the permission
                        // and handle the de-registration as usual
                        return CommunicationComponent.requestToken({
                            user: username,
                            tokenObj: commToken,
                            msPermission: permission
                        }).then(tokenObj => {
                            if (this.constructor.isAuthTokenValid(tokenObj)) {
                                // Keep the token alive!
                                this._authTokenDeRegs.push(SandboxComponent.registerForPermissions(this.constructor.PERMISSIONS));

                                return tokenObj.token;
                            } else {
                                Debug.Control.Athene.Media && console.log(this.name, "Token misses 'msSerial' payload!");
                                return Q.reject(new Error("Token misses 'msSerial' payload!"));
                            }
                        });
                    }
                } else if (this._signaling && this._signaling.sessionToken) {
                    Debug.Control.Athene.Media && console.log(this.name, "New auth method not supported, returning sessionToken");
                    return Q.resolve(this._signaling.sessionToken);
                } else {
                    Debug.Control.Athene.Media && console.log(this.name, "NONE token is not yet supported, also no sessionToken exists yet!");
                    return Q.reject(new Error("NONE token is not yet supported, also no sessionToken exists yet!"));
                }
            }

            verifyReachability() {
               return this._prepareSignaling().fail(() => {
                    let retryDef = Q.defer();
                    setTimeout(() => {

                this.verifyReachability().then(retryDef.resolve, retryDef.reject);

                        }, 2000);
                    return retryDef.promise;
                });
            }

            destroy() {
                Debug.Control.Athene.Media && console.log(this.name + "🪦 destroy");

                this._unregisterStates(this.control.uuidAction);

                this.__delegates = null;
                document.removeEventListener("pause", this._boundPauseHandler);
                document.removeEventListener("resume", this._boundResumeHandler);
                CompChannel.off(this._connEstablishedReg);
                CompChannel.off(this._connClosedReg);

                try {
                    this._teardown();
                } catch (e) {
                }

                this._authTokenDeRegs.forEach(({ deReg }) => deReg());
                this._authTokenDeRegs = [];

                this._boundConnClosed();
            }

            // --------------------------------------------------------------------------------------------------------
            // Remote Stream Public
            // --------------------------------------------------------------------------------------------------------

            /**
             * Starts the remote stream
             * @param noVideo
             * @param [force] Will close the oldest WebRTC session in favour of this one, defaults to false
             * @return {Promise}
             */
            startRemoteVideoStream(noVideo, force = false) {
                Debug.Control.Athene.Media && console.log(this.name + " startRemoteVideoStream");
                return this._prepareSignaling().then(() => {
                    if (this._remoteVideoDef && this._remoteVideoDef.promise.inspect().state === "pending") {
                        Debug.Control.Athene.Media && console.log(this.name + " startRemoteVideoStream already pending...");
                        return this._remoteVideoDef.promise;
                    } else {
                        let stopPromise = Q.resolve();
                        this._updateRsState(this.constructor.REMOTE_STREAM_STATE.VIDEO_PENDING);

                        if (this._remoteVideoDef && this._remoteVideoDef.promise.inspect().state === "fulfilled") {
                            stopPromise = this.stopRemoteVideoStream();
                        }

                        return stopPromise.then(() => {
                            this._remoteVideoDef = Q.defer();
                            let rtcPeerConnectionSuccesfull = false;

                            if (this._excessiveTempReached) {
                                this._remoteVideoDef.reject("Excessive Temperature Reached!");

                                this.onExcessiveTemp(this._excessiveTempReached);
                            } else {
                                this.remoteVideoEnabled = !noVideo;

                                rtcPeerConnectionSuccesfull = this._initializeRTCPeerConnection(force);
                            }

                            if (rtcPeerConnectionSuccesfull) {
                                return this._remoteVideoDef.promise.then((res) => {
                                    clearTimeout(this._iceVideoErrorTimeout);
                                    delete this._iceVideoErrorTimeout;
                                    this._makingOffer = false;
                                    this._updateRsState(this.constructor.REMOTE_STREAM_STATE.IDLE);
                                    return Q.resolve(res);
                                }).catch((e) => {
                                    clearTimeout(this._iceVideoErrorTimeout);
                                    delete this._iceVideoErrorTimeout;
                                    this._onCreateSessionDescriptionError(new Error('Failed to create session description: ' + e));
                                    this._updateRsState(this.constructor.REMOTE_STREAM_STATE.ERROR);
                                    return Q.reject(e);
                                });
                            } else {
                                this._remoteVideoDef.reject("Failed to initialize RTCPeerConnection!");

                                this._updateRsState(this.constructor.REMOTE_STREAM_STATE.ERROR);
                                return Q.reject("Failed to initialize RTCPeerConnection!");
                            }
                        })
                    }
                }, function (e) {
                    this._notifyAboutError("Black/Missing Video? => No Signaling");

                    this._remoteVideoDef && this._remoteVideoDef.reject(e);
                    this._remoteVideoDef = null;
                }.bind(this));
            }

            stopRemoteVideoStream() {
                Debug.Control.Athene.Media && console.log(this.name + " stopRemoteVideoStream");

                this._removeCachedCandidatesForType("video");

                clearTimeout(this._iceVideoErrorTimeout);
                delete this._iceVideoErrorTimeout;
                var hangupPrms;
                this.constructor.stopTransceiver(this._remoteVideoTransceiver);
                this.peerConnection && this.peerConnection.close();
                this.stopAudio();

                if (this._signaling) {
                    hangupPrms = this._signaling.hangUp().fail(function (e) {
                        // Ignore any errors here, we may have lost the connection
                        return Q.resolve();
                    });
                } else {
                    hangupPrms = Q.resolve();
                }

                this._remoteVideoDef && this._remoteVideoDef.reject();
                return hangupPrms.finally(function () {
                    // leave state on error, if there was one.
                    if (this.remoteStreamError === null) {
                        this._updateRsState(this.constructor.REMOTE_STREAM_STATE.IDLE);
                    }

                    this._remoteVideoDef = null;
                }.bind(this));
            }

            pauseRemoteVideo(pause) {
                Debug.Control.Athene.Media && console.log(this.name + " pauseRemoteVideo: " + pause);
                Debug.Control.Athene.Media && console.log(this.name + "" + (this._remoteVideoStream ? this._remoteVideoStream : "no-video-source"));
                this._remoteVideoStream && this._remoteVideoStream.getVideoTracks().forEach(function (track) {
                    track.enabled = !pause;
                    Debug.Control.Athene.Media && console.log("   - " + track.label + " (" + track.kind + "): paused " + track.enabled);
                });
            }

            /**
             * Returns a promise which resolves with an array of objects containing the following properties
             * • data: Moment.js object
             * • imgSrc: URL of the image
             * @param [from] start idx - defaults to 0
             * @param [to] end idx - defaults to 100
             * @param [viaHttp] if the information should be gathered via HTTP - defaults to false
             * @return {Promise<T>}
             */
            getLastActivities({
                                  from = 0,
                                  to = 100
                              } = {}) {
                let params = [0, 100];

                if (this.useNewAuthMethod) {
                    //version, defaults to 0 (legacy authentication)
                    params.push(2);
                }

                if (this._lastBellUnixStates > this._lastBellUnix || this._lastBellUnix === -1 || !this._signalingReadyDef || this._signalingReadyDef.promise.inspect().state !== "fulfilled") {
                    if (this._lastActivitiesDef.promise.inspect().state !== "pending") {
                        this._lastActivitiesDef = Q.defer();
                    }

                    this._lastActivitiesDef.resolve(this._prepareSignaling().then(function () {
                        return this._signaling.execArbitraryMethod(this.constructor._ARBITRARY_METHODS.GET_LAST_ACTIVITIES, params).then(function (lastActivities) {
                            let activities = lastActivities.sort(function (a, b) {
                                return b.date - a.date;
                            }).map(function (activity) {
                                return new ActivityImage(this.control, activity);
                            }.bind(this));
if (activities.length > 0) {                            this._lastBellUnix = activities[0].getDate().unix();} else {
                                console.error("AtheneMediaHandler", "getLastBellUnix failed - no last activities returned via sigSock!");
                                console.error("AtheneMediaHandler", "    lastBellUnixStates=" + JSON.stringify(this._lastBellUnixStates));
                            }
                            return activities;
                        }.bind(this));
                    }.bind(this)));
                } else if (this._lastActivitiesDef.promise.inspect().state === "rejected") {
                    this._lastBellUnix = -1;

                    this._lastActivitiesDef.resolve(this.getLastActivities({
                        from: from,
                        to: to
                    }));
                }

                return this._lastActivitiesDef.promise.then(activities => {
                    return activities.slice(from, to);
                });
            }

            canPlayTTSAtIndex(idx) {
                if (this._ttsInfo) {
                    return !!this._ttsInfo[idx];
                } else {
                    return false;
                }
            }

            // --------------------------------------------------------------------------------------------------------
            // Local Stream Public
            // --------------------------------------------------------------------------------------------------------
            startAudio() {
                if (this._audioDef && this._audioDef.promise.inspect().state === 'pending') {
                    Debug.Control.Athene.Media && console.log(this.name + " startAudio already pending...");
                    return this._audioDef.promise;
                }
                this._audioDef = Q.defer();
                if (this._callState === WebsocketSignaling.CALL_STATE.AVAILABLE) {
                    this._initializeRTCPeerConnection();

                    Debug.Control.Athene.Media && console.log(this.name + " startAudio");
                    this._iOSSpeakerFixDef = Q.defer();

                    this._updateLsState(this.constructor.LOCAL_STREAM_STATE.ESTABLISHING);

                    this._updateRsState(this.constructor.REMOTE_STREAM_STATE.ESTABLISHING);

                    this._offerAction = WebsocketSignaling.OFFER_ACTION.ADD_AUDIO;

                    if (this.constructor.requiresIOSSpeakerFix) {
                        this._iOSSpeakerFixRemoteRtpSender = this.peerConnection.addTrack(this.constructor.getAudioContext().createMediaStreamDestination().stream.getAudioTracks()[0]);
                    } else {
                        // Immediately resolve the def as we don't need to await the promise
                        this._iOSSpeakerFixDef.resolve();
                    }

                    this._audioDef.resolve(this._iOSSpeakerFixDef.promise.then(function () {
                        var prms;

                        if (Debug.Control.Athene.DevFeatures.SEND_SILENCE) {
                            var destination = this.constructor.getAudioContext().createMediaStreamDestination();
                            this.audioDebugNotification = GUI.Notification.createGeneralNotification({
                                title: "Sending Silence".debugify(),
                                subtitle: "Control.Athene.DevFeatures.SEND_SILENCE=true",
                                closeable: false,
                                clickable: true
                            }, NotificationType.ERROR);
                            prms = this._gotLocalStream(destination.stream);
                        } else {
                            prms = this._getUserMedia().then(function (stream) {
                                return this._gotLocalStream(stream);
                            }.bind(this), this._failedToGetLocalStream.bind(this));
                        }

                        return prms.finally(function () {
                            if (this.constructor.requiresIOSSpeakerFix) {
                                return this.constructor.closeAudioContext().finally(function () {
                                    return Q.resolve();
                                });
                            } else {
                                return Q.resolve();
                            }
                        }.bind(this));
                    }.bind(this)));
                } else {
                    this._audioDef.reject(new Error("Device already occupied, try again later!"));
                }

                return this._audioDef.promise.finally(() => {
                    delete this._audioDef;
                });
            }

            stopAudio() {
                this.audioDebugNotification && this.audioDebugNotification.remove(true);
                Debug.Control.Athene.Media && console.log(this.name + " stopAudio");

                this._removeCachedCandidatesForType("audio");

                this._stopScreenSaverBlocker();

                this._offerAction = WebsocketSignaling.OFFER_ACTION.REMOVE_AUDIO;

                if (this.peerConnection) {
                    this.peerConnection.getSenders().forEach(function (sender) {
                        this.peerConnection.removeTrack(sender);
                    }.bind(this));
                }

                if(this.originalAudioMode) {
                    AudioToggle.setAudioMode(this.originalAudioMode);
                    this.originalAudioMode = null;
                }

                if (!this._remoteVideoDef) {
                    this.peerConnection && this.peerConnection.close();
                } // These are the actual streams from the Microphone


                this._thisDeviceMicStreams.forEach(function (micStream) {
                    try {
                        micStream.getTracks().forEach(function (micTrack) {
                            if (micTrack.readyState === "live") {
                                micTrack.stop();
                            }
                        });
                    } catch (e) {
                        console.error(e.message);
                    }
                });

                this._thisDeviceMicStreams = [];

                if (this.isActivelyCalling) {
                    // Manually update the callState to have a smother transition between the call states
                    // We will have flickering UI if we wait for the intercom to update the callState after
                    // hanging up
                    this.onCallStateChange(WebsocketSignaling.CALL_STATE.AVAILABLE);
                }

                this._iOSSpeakerFixDef = null; // leave state on error, if there was one.

                if (this.localStreamError === null) {
                    this._updateLsState(this.constructor.LOCAL_STREAM_STATE.IDLE);
                } // leave state on error, if there was one.


                if (this.remoteStreamError === null) {
                    this._updateRsState(this.constructor.REMOTE_STREAM_STATE.IDLE);
                }

                navigator.mediaDevices.ondevicechange = null;
            }

            /**
             * Mute or unmute the local microphone
             * @param mute
             */
            muteLocalAudio(mute) {
                Debug.Control.Athene.Media && console.log(this.name + " muteLocalAudio: " + mute);

                this._thisDeviceMicStreams.forEach(function (audioStream) {
                    try {
                        audioStream.getAudioTracks().forEach(function (track) {
                            track.enabled = !mute;
                        });
                    } catch (e) {
                        console.error("Couldn't enable stream: " + e.message);
                    }
                });

                this._localAudioMuted = mute;
            }

            /**
             * Sends an arbitrary command to the Intercom Gen. 2 via HTTP, will automatically append the sessionToken
             * for authentication
             * @param cmd
             * @return {Q.Promise<*>}
             */
            sendCommandViaHTTP(cmd) {
                return this._getRemoteHost().then(function (host) {
                    let authParam = "auth"; // Legacy

                    if (this.useNewAuthMethod) {
                        authParam = "autht";
                    }
                    return this.getAuthToken().then(authToken => {
                        return Q($.ajax({
                            url: `${host.replace(/\/$/g, "")}/${cmd.replace(/^\//g, "")}?${authParam}=${authToken}`
                        }));
                    });
                }.bind(this));
            }

            /**
             * Loads the TechReport of the Intercom Gen. 2
             * @return {Q.Promise<String>}
             */
            loadTechReport() {
                return this.sendCommandViaHTTP(this.constructor.COMMANDS.DETAILED_TECH_REPORT);
            }

            // --------------------------------------------------------------------------------------------------------
            // Signaling Delegates
            // --------------------------------------------------------------------------------------------------------

            /**
             * The signaling did close with a reason
             * @param reason
             */
            onSignalingClosed(reason) {
                Debug.Control.Athene.Media && console.log("Signaling channel closed with reason: '" + reason + "'");
                this.onComError(reason);
            }

            /**
             * We got a communication error!
             * @param e
             */
            onComError(e) {
                this._teardown().finally(function () {
                    if (this._signalingComErrorCnt >= this.constructor.MAX_SIGNALING_ERROR_CNT) {
                        Debug.Control.Athene.Media && console.log(this.name, "Max Signaling error count reached!");

                        this._notifyDelegates(this.constructor._DELEGATE_FN.COM_ERROR, e);
                    }

                    this._comErrorRetryTimer = setTimeout(function () {
                        if (this._deviceState === AtheneEnums.DEVICE_STATE.OK && !this.connectionIsOnDemand) {
                            this._prepareSignaling();
                        }
                    }.bind(this), this.constructor.SIGNALING_RETRY_TIMEOUT);
                }.bind(this));
            }

            /**
             * The remote peer sent us an ice candidate, process it!
             * @param candidate
             * @private
             */
            onAddIceCandidate(candidate) {
                if (this.peerConnection) {
                    if (this.peerIsReadyToProcessIceCandidates) {
                        this.peerConnection.addIceCandidate(candidate).then(function () {
                            Debug.Control.Athene.ICE && console.log(this.name, "Got Candidate (from Intercom) successfully added: " + JSON.stringify(candidate));
                        }.bind(this), function (e) {
                            Debug.Control.Athene.ICE && console.log(this.name, "Got Candidate (from Intercom) couldn't be added: " + JSON.stringify(candidate));
                            console.error(e);
                        }.bind(this));
                    } else {
                        Debug.Control.Athene.ICE && console.log(this.name, "Got Candidate (from Intercom) cache it!: " + JSON.stringify(candidate));

                        if (!this._cachedRemoteIceCandidates[candidate.sdpMid]) {
                            this._cachedRemoteIceCandidates[candidate.sdpMid] = [];
                        }

                        this._cachedRemoteIceCandidates[candidate.sdpMid].pushIfNoDuplicate(candidate);
                    }
                }
            }

            /**
             * The call state did change for the Intercom
             * @param callState
             * @private
             */
            onCallStateChange(callState) {
                if (this._callState !== callState) {
                    console.log(this.name, "CallState did change to '" + translateEnum(WebsocketSignaling.CALL_STATE, callState) + "'");
                    this._callState = callState;

                    this._notifyDelegates(this.constructor._DELEGATE_FN.CALL_STATE_CHANGE, this._callState);
                }
            }

            /**
             * We just were told to tear down all WebRTC related processes by the intercom
             * @param type The type of the kick
             * @param info Additional information regarding the kick
             * @private
             */
            onWebRtcKick(type, info) {
                console.log(this.name, "Received Kick of type " + translateEnum(WebsocketSignaling.KICK_TYPE, type) + " with info: '" + info + "'");
                var keepVideo = type === WebsocketSignaling.KICK_TYPE.RE_RING;

                this._rsFailedWith(this.constructor.REMOTE_STREAM_ERROR.KICKED, {
                    type: type,
                    info: info
                }, keepVideo);

                if (!keepVideo) {
                    this._teardownWebRtc();
                }
            }

            /**
             * No ICE candidate match could be found by the intercom!
             * @private
             */
            onNoIceMatchFound() {
                let msg = "No ICE Candidates did match, please create a Techreport to help us investigate this.";
                console.warn(this.name, "No ICE Candidate match has been found by the Intercom");

                if ("DebugScreen" in GUI) {
                    NavigationComp.showPopup({
                        title: "Ups, something went wrong",
                        message: msg,
                        buttonOk: "Open Admin Interface",
                        buttonCancel: false,
                        color: window.Styles.colors.red
                    }).then(res => {
                        NavigationComp.openWebsite(this.host);
                    });
                }

                this._rsFailedWith(this.constructor.REMOTE_STREAM_ERROR.NO_ICE_MATCH_FOUND, {
                    message: msg
                });

                this._teardownWebRtc();
            }

            /**
             * The Intercom detected excessive temperature and deactivated the camera
             * @params toHot if the Intercom is too hot or not (enable or disable)
             * @private
             */
            onExcessiveTemp(toHot) {
                var wasToHot = this._excessiveTempReached;
                this._excessiveTempReached = toHot;

                if (wasToHot !== toHot) {
                    this._notifyDelegates(this.constructor._DELEGATE_FN.EXCESSIVE_TEMP, toHot);

                    if (toHot) {
                        console.warn(this.name, "Excessive Temperature: To Hot!");
                        return this._rsFailedWith(this.constructor.REMOTE_STREAM_ERROR.EXCESSIVE_TEMP, {});
                    } else {
                        console.warn(this.name, "Excessive Temperature: Device did cool down");
                    }
                }
            }

            /**
             * The Intercom detected internet state change
             * @params hasInternet If internet is available
             * @private
             */
            onOnlineChanged(hasInternet) {
                var hadInternet = this._hasInternet;
                this._hasInternet = hasInternet; // TODO-goelzda: Implement once ready

                if (hadInternet !== hasInternet) {
                    this._notifyDelegates(this.constructor._DELEGATE_FN.ONLINE_CHANGED, hasInternet);
                }
            }

            /**
             * The Intercom detected internet state change
             * @params ttsInfo0
             * @params ttsInfo1
             * @params ttsInfo2
             * @private
             */
            onTTSInfoChanged(...ttsInfo) {
                this._ttsInfo = ttsInfo;
            }

            receivedStates(states) {
                var prevResolvedAddress = this._resolvedAddress,
                    prevBell = this._bellState,
                    prevDeviceState = this._deviceState,
                    prevVersionString = this._versionStringState;
                this._resolvedAddress = states.address;
                this._bellState = states.bell;
                this._lastBellUnixStates = states.lastBellUnix;
                this._deviceState = states.deviceState;
                this._versionStringState = states.version;

                if (prevVersionString !== this._versionStringState) {
                    this._updateFeatures(this._versionStringState);
                }

                if (prevDeviceState !== states.deviceState) {
                    if (states.deviceState === AtheneEnums.DEVICE_STATE.OK) {
                        this._prepareSignaling();
                    } else {
                        this._teardown();
                    }
                }

                if (this.control.isTrustMember) {
                    if (this._addressResolveDef.promise.inspect().state === "pending") {
                        this._addressResolveDef.resolve("IsTrustMember");
                    }
                } else if (prevResolvedAddress !== states.address) {
                    if (this._addressResolveDef.promise.inspect().state === "pending" && states.address) {
                        this._addressResolveDef.resolve(states.address);
                    } else {
                        this.onComError(new Error("Network Change"));
                    }
                }

                if (prevBell !== states.bell && states.bell) {
                    this._notifyNewLastActivities();
                }
            }

            // --------------------------------------------------------------------------------------------------------
            // Private
            // --------------------------------------------------------------------------------------------------------
            _updateFeatures(version = "0") {
                this._features = {};
                let intercomVersion = new ConfigVersion(version);
                Object.keys(this.constructor.FEATURES).forEach(featureName => {
                    this._features[featureName] = intercomVersion.greaterThanOrEqualTo(this.constructor.FEATURES[featureName]);
                });
            }

            /**
             * Will prepare the signaling if needed
             * @return {Promise}
             * @private
             */
            _prepareSignaling() {
                return this._msConnEstablishedDef.promise.then(function () {
                    if (this._signalingReadyDef && this._signalingReadyDef.promise.inspect().state !== "rejected") {
                        return this._signalingReadyDef.promise;
                    } else {
                        this._signalingReadyDef = Q.defer();
                        this._signalingComErrorCnt++;

                        if (this.control.isConfigured()) {
                            this._signalingReadyDef.resolve(this._getRemoteHost().then(function (host) {
                                this._signalingComErrorCnt = 0;
                                this.host = host;
                                Debug.Control.Athene.Media && console.log("RemoteHost: ", host); // (Re)use any existing signaling

                                if (!this._signaling) {
                                    this._signaling = new WebsocketSignaling({
                                        url: this.host,
                                        onDemand: this.connectionIsOnDemand,
                                        delegate: this
                                    });
                                }

                                return this._signaling.setupComLayer().then(function () {
                                    return this.getAuthToken().then(authToken => {
                                        return this._getInfo().then(function () {
                                            this._notifyNewLastActivities();
                                            this._notifyDelegates(this.constructor._DELEGATE_FN.COM_READY);
                                        }.bind(this));
                                    })
                                }.bind(this));
                            }.bind(this)));
                        } else {
                            this._signalingReadyDef.reject("Control is not configured!");
                        }

                        return this._signalingReadyDef.promise;
                    }
                }.bind(this));
            }

            _getStateIds() {
                return ["address", "bell", "deviceState"];
            }

            _notifyNewLastActivities() {
                this.getLastActivities().done(function (lastActivities) {
                    this._notifyDelegates(this.constructor._DELEGATE_FN.ON_NEW_LAST_ACTIVITIES, [lastActivities || []]);
                }.bind(this));
            }

            // --------------------------------------------------------------------------------------------------------
            // Remote Stream Private
            // --------------------------------------------------------------------------------------------------------
            _initializeRTCPeerConnection(force) {
                Debug.Control.Athene.Media && console.log(this.name + " _initializeRTCPeerConnection");

                if (this.peerConnection) {
                    Debug.Control.Athene.Media && console.warn(this.name + " already existing RTCPeerConnection!");
                    return true;
                }

                this._offerAction = WebsocketSignaling.OFFER_ACTION.NEW;
                var success = false,
                    rtcConf = {
                        offerToReceiveVideo: this.remoteVideoEnabled,
                        iceServers: [],
                        iceTransportPolicy: "all"
                    };

                if (Debug.Control.Athene.DevFeatures.StunTurn.ForceTurn) {
                    developerAttention("Forcing TURN due to DevFeature");
                    rtcConf.iceTransportPolicy = "relay";
                }

                if (this._deviceInfo.turnuser && this._deviceInfo.turnpass) {
                    rtcConf.iceServers.push({
                        urls: ["turn:" + this.constructor.LX_STUN_TURN],
                        username: this._deviceInfo.turnuser,
                        credential: this._deviceInfo.turnpass
                    }, {
                        urls: ["stun:" + this.constructor.LX_STUN_TURN]
                    });
                } // This is our fallback STUN server


                rtcConf.iceServers.push({
                    urls: ["stun:stun.l.google.com:19302"]
                });

                try {
                    this.peerConnection = new RTCPeerConnection(rtcConf);

                    this.peerConnection.onconnectionstatechange = function onconnectionstatechange({
                                                                                                       target
                                                                                                   }) {
                        switch (target.connectionState) {
                            case "closed":
                                this._removeCachedCandidatesForType("audio");

                                this._removeCachedCandidatesForType("video");

                                break;
                        }
                    }.bind(this);

                    this.peerConnection.ontrack = this._onRemoteTrack.bind(this);
                    this.peerConnection.onicecandidate = this._onIceCandidate.bind(this);

                    this.peerConnection.onicegatheringstatechange = function onicegatheringstatechange(e) {
                        if (this.peerConnection.iceGatheringState === "complete") {
                            this._signaling.onIceGatheringFinished();
                        }
                    }.bind(this);

                    this.peerConnection.onnegotiationneeded = function onnegotiationneeded(ev) {
                        Debug.Control.Athene.Media && console.log(this.name, "negotiation Needed");
                        this.peerIsReadyToProcessIceCandidates = false;

                        try {
                            this._createOffer(force);
                        } catch (e) {
                            console.error(e);
                        }
                    }.bind(this);

                    if (this.remoteVideoEnabled) {
                        this._remoteVideoTransceiver = this.peerConnection.addTransceiver('video', {
                            direction: "recvonly"
                        });
                    }

                    success = true;
                } catch (ex) {
                    this._rsFailedWith(this.constructor.REMOTE_STREAM_ERROR.RTC_PEER_CONNECTION_INIT, ex);
                }

                return success;
            }

            _createOffer(force) {
                if (!this._peerConnectionOfferDef || this._peerConnectionOfferDef.promise.inspect().state === "pending") {
                    this._makingOffer = true;
                    this._peerConnectionOfferDef = Q.defer();
                    return this.peerConnection.createOffer().then(function (desc) {
                        //desc.sdp = this.constructor.validateSDP(desc.sdp);
                        this._parsedLocalDescription = sdpTransform.parse(desc.sdp);

                        this._offerCreated(desc, force).then(() => {
                            this._peerConnectionOfferDef.resolve();
                        }).finally(() => {
                            this._peerConnectionOfferDef = null;
                        });
                    }.bind(this), this._onCreateSessionDescriptionError.bind(this));
                } else {
                    Debug.Control.Athene.Media && console.log(this.name + " _createOffer skipped, most likely a promise stall");
                    return Q.reject(new Error("Offer already pending!"));
                }
            }

            _offerCreated(desc, force) {
                Debug.Control.Athene.Media && console.log(this.name + " _offerCreated, from pc1");
                Debug.Control.Athene.Media && console.log(JSON.stringify(desc));

                if (this.peerConnection.signalingState !== "stable") {
                    this._makingOffer = false;
                    return this._peerConnectionOfferDef.reject(new Error("Signaling state is not stable!"));
                } else {
                    return this.peerConnection.setLocalDescription(desc).then(function () {
                        Debug.Control.Athene.Media && console.log(this.name + " setLocalDescription passed");

                        this._sendOffer(this.peerConnection.localDescription, force);
                    }.bind(this), this._onSetSessionDescriptionError.bind(this));
                }
            }

            _onCreateSessionDescriptionError(error) {
                console.timeEnd(this.name + " creatingOffer");
                this._makingOffer = false;
                this._peerConnectionOfferDef.reject(error);
                this._rsFailedWith(this.constructor.REMOTE_STREAM_ERROR.CREATE_SESSION_DESCRIPTION, error);
            }

            _onIceCandidate(event) {
                if (event.candidate) {
                    // The browser got a new local ice candidate, send it to the remote peer
                    if (this.peerIsReadyToProcessIceCandidates) {
                        this._signaling.addIceCandidate(event.candidate).done(function () {
                            clearTimeout(this._iceVideoErrorTimeout);
                            delete this._iceVideoErrorTimeout;
                            Debug.Control.Athene.ICE && console.log(this.name, "Got Candidate (from App) successfully added: " + JSON.stringify(event.candidate.candidate));
                        }.bind(this), function (e) {
                            var iceType = this._getIceCandidateMediaType(event.candidate);

                            Debug.Control.Athene.ICE && console.log(this.name, "ICE Candidate of type '" + iceType + "' did time out!");

                            if (iceType === "video") {
                                clearTimeout(this._iceVideoErrorTimeout);
                                this._iceVideoErrorTimeout = setTimeout(function () {
                                    if (this._remoteStreamState !== this.constructor.REMOTE_STREAM_STATE.ESTABLISHED) {
                                        this._notifyAboutError("Black/Missing Video?");
                                    }
                                }.bind(this), 5 * 1000);
                            }

                            Debug.Control.Athene.ICE && console.log(this.name, "Got Candidate (from App) couldn't be added: " + JSON.stringify(event.candidate.candidate));
                            console.error(e.message);
                        }.bind(this));
                    } else {
                        Debug.Control.Athene.ICE && console.log(this.name, "Got Candidate (from App) cache it!: " + JSON.stringify(event.candidate.candidate));

                        if (!this._cachedLocalIceCandidates[event.candidate.sdpMid]) {
                            this._cachedLocalIceCandidates[event.candidate.sdpMid] = [];
                        }

                        this._cachedLocalIceCandidates[event.candidate.sdpMid].pushIfNoDuplicate(event.candidate);
                    }
                }
            }

            _sendOffer(localDescription, force) {
                Debug.Control.Athene.Media && console.log(this.name + " _sendOffer");

                if (localDescription) {
                    Debug.Control.Athene.Media && console.log(this.name + " offer: " + localDescription.sdp);
                    return this._signaling.sendOffer({
                        localDescription,
                        offerAction: this._offerAction,
                        force,
                        exception: MediaHandler.offerException
                    }).then(this._receivedOfferResponse.bind(this), this._onSendSdpError.bind(this));
                } else {
                    return Q.resolve();
                }
            }

            _receivedOfferResponse(remoteDescription) {
                Debug.Control.Athene.Media && console.log("set remote description -- SDP Answer");
                Debug.Control.Athene.Media && console.log(this.name + " sdp answer: " + remoteDescription.sdp);
                var offerCollision = remoteDescription.type === "offer" && (this._makingOffer || this.peerConnection.signalingState !== "stable");
                this._ignoreOffer = !this._isPolitePeer && offerCollision;
                this._makingOffer = false;

                if (this._ignoreOffer) {
                    return;
                }

                return this.peerConnection.setRemoteDescription(remoteDescription).then(function () {
                    Debug.Control.Athene.Media && console.log(this.name + " setRemoteDescription: --> success");
                    this.peerIsReadyToProcessIceCandidates = true;
                }.bind(this), this._onSetSessionDescriptionError.bind(this));
            }

            _onSendSdpError(error) {
                Debug.Control.Athene.Media && console.log(this.name + " _onSendSdpError: " + error.message);
                this._makingOffer = false;

                this._rsFailedWith(this.constructor.REMOTE_STREAM_ERROR.SEND_SDP_OFFER, error);
            }

            _onRemoteTrack(e) {
                Debug.Control.Athene.Media && console.log(this.name + " _onRemote" + e.track.kind.capitalize() + "Track");
                let videoTracks = e.streams[0].getVideoTracks(),
                    audioTracks = e.streams[0].getAudioTracks(),
                    audio,
                    audioPlayPrms;

                if (audioTracks.length > 0) {
                    this._remoteAudioStream = new MediaStream();
                    audioTracks.forEach(function (track) {
                        this._remoteAudioStream.addTrack(track);
                    }.bind(this));
                    audio = new Audio();
                    audio.srcObject = this._remoteAudioStream;

                    if (this.constructor.requiresIOSSpeakerFix) {
                        audioPlayPrms = audio.play().then(function () {
                            this._iOSSpeakerFixDef.resolve();
                        }.bind(this));
                    } else {
                        audioPlayPrms = audio.play();
                    }

                    audioPlayPrms.then(function () {
                        if ("AudioToggle" in window && PlatformComponent.getPlatformInfoObj().platform === PlatformType.Android) {
                            AudioToggle.getAudioMode().then(function (mode) {
                                this.originalAudioMode = mode;
                                if (mode !== AudioToggle.EARPIECE) {
                                    AudioToggle.setAudioMode(AudioToggle.EARPIECE);
                                }
                            }.bind(this));
                        }
                        this._updateRsState(this.constructor.REMOTE_STREAM_STATE.ESTABLISHED);
                    }.bind(this))
                } else {
                    this._remoteVideoStream = new MediaStream();
                    videoTracks.forEach(track => this._remoteVideoStream.addTrack(track));

                    this._remoteVideoDef.resolve(this._remoteVideoStream);
                }
            }

            _onSetSessionDescriptionError(error) {
                this._makingOffer = false;
                this._rsFailedWith(this.constructor.REMOTE_STREAM_ERROR.SET_SESSION_DESCRIPTION, error);
            }

            _updateRsState(newState) {
                if (newState !== this._remoteStreamState || newState !== this.constructor.REMOTE_STREAM_ERROR.NONE) {
                    Debug.Control.Athene.Media && console.log(this.name + " _updatesState: from " + this.__translRsState(this._remoteStreamState) + " to " + this.__translRsState(newState));
                    this._remoteStreamState = newState;

                    this._notifyDelegates(this.constructor._DELEGATE_FN.REMOTE_STREAM_CHANGED, newState);
                }
            }

            _rsFailedWith(errorId, errorInfo, keepVideo = false) {
                console.error(this.name + " _rsFailedWith: " + this.__translRsError(errorId));
                console.error(errorInfo);
                console.error(JSON.stringify(errorInfo));
                this.rsError = errorId;
                this.rsErrorInfo = errorInfo;
                this._makingOffer = false;
                this._peerConnectionOfferDef.reject(errorInfo);
                setTimeout(function () {
                    this.remoteVideoEnabled = keepVideo;

                    this._updateRsState(this.constructor.REMOTE_STREAM_STATE.ERROR);

                    if (!keepVideo) {
                        this.stopRemoteVideoStream();
                    }
                }.bind(this), 1);
            }

            // --------------------------------------------------------------------------------------------------------
            // Local Stream Private
            // --------------------------------------------------------------------------------------------------------
            _getUserMedia() {
                Debug.Control.Athene.Media && console.log(this.name + " _getUserMedia");
                var constraints = {
                        audio: true,
                        video: false
                    },
                    mediaDevices = null,
                    userMediaReq;

                try {
                    mediaDevices = navigator.mediaDevices;

                    if (!!mediaDevices) {
                        if (PlatformComponent.isMacOS()) {
                            userMediaReq = electron.remote.systemPreferences.askForMediaAccess("microphone").then(function (granted) {
                                if (granted) {
                                    return Q(mediaDevices.getUserMedia(constraints));
                                } else {
                                    throw {
                                        name: "PermissionDeniedError"
                                    };
                                }
                            });
                        } else {
                            userMediaReq = Q(mediaDevices.getUserMedia(constraints));
                        }
                    } else {
                        userMediaReq = Q.reject(new Error("No access to audio on device"));
                    }
                } catch (ex) {
                    userMediaReq = Q.reject(ex);
                }

                return userMediaReq.then(function (stream) {
                    this._thisDeviceMicStreams.push(stream);

                    return stream;
                }.bind(this));
            }

            _gotLocalStream(stream) {
                Debug.Control.Athene.Media && console.log(this.name + " _gotLocalStream");
                Debug.Control.Athene.Media && console.log(JSON.stringify(stream));
                var micTrack, continuePrms;
                micTrack = stream.getAudioTracks()[0];

                if (this.constructor.requiresIOSSpeakerFix) {
                    if (this._iOSSpeakerFixRemoteRtpSender) {
                        continuePrms = this._iOSSpeakerFixRemoteRtpSender.replaceTrack(micTrack);
                    } else {
                        continuePrms = Q.resolve();
                    }
                } else {
                    this.peerConnection.addTrack(micTrack);
                    continuePrms = Q.resolve();
                }

                return continuePrms.then(function () {
                    this.muteLocalAudio(this._localAudioMuted);

                    this._startScreenSaverBlocker();

                    this._preferredInputDeviceId = micTrack.getSettings().deviceId;
                    return this._emitLocalStreamDeviceList().then(function () {
                        navigator.mediaDevices.ondevicechange = this._emitLocalStreamDeviceList.bind(this);

                        this._updateLsState(this.constructor.LOCAL_STREAM_STATE.ESTABLISHED);
                    }.bind(this));
                }.bind(this));
            }

            _failedToGetLocalStream(e) {
                this._lsFailedWith(this.constructor.LOCAL_STREAM_ERROR.GET_USER_MEDIA_RESPONSE, e);
            }

            _updateLsState(newState) {
                if (newState !== this._localStreamState || newState !== this.constructor.LOCAL_STREAM_STATE.IDLE) {
                    Debug.Control.Athene.Media && console.log(this.name + " _updateLsState: from " + this.__translLsState(this._localStreamState) + " to " + this.__translLsState(newState));
                    this._localStreamState = newState;

                    this._notifyDelegates(this.constructor._DELEGATE_FN.LOCAL_STREAM_CHANGED, newState);
                }
            }

            _lsFailedWith(errorId, error) {
                console.error(this.name + " _lsFailedWith: " + this.__translLsError(errorId));
                this.lsError = errorId;
                this.lsErrorInfo = error;
                setTimeout(function () {
                    this._updateLsState(this.constructor.LOCAL_STREAM_STATE.ERROR);

                    this.stopAudio();
                }.bind(this), 1);
            }

            __translLsError(error) {
                return translateEnum(this.constructor.LOCAL_STREAM_ERROR, error);
            }

            __translLsState(state) {
                return translateEnum(this.constructor.LOCAL_STREAM_STATE, state);
            }

            // --------------------------------------------------------------------------------------------------------
            // Others Private
            // --------------------------------------------------------------------------------------------------------
            _getRemoteHost() {
                let reachMode = CommunicationComponent.getCurrentReachMode(),
                    proto = CommunicationComponent.getRequestProtocol();

                if (this.control.isTrustMember) {
                    if (isCloudDnsUrl(this.control.details.trustAddress)) {
                        return new ConnectivityTools.CloudDNSResolver().resolve(getSerialNo(this.control.details.trustAddress)).then(resolveInfo => {
                            let {
                                    host,
                                    port
                                } = splitHostAndPort(resolveInfo.ipHttps),
                                dataCenter = getCertTldFromDataCenter(resolveInfo.dataCenter),
                                address = sprintf(getDynDnsSslHost(dataCenter), host.replace(/[.|:]/g, "-").replace(/[\[\]]/g, ""), resolveInfo.snr, port);
                            return `https://${address}/proxy/${this.control.details.deviceUuid}/`;
                        });
                    } else {
                        Q.resolve(`https://${this.control.details.trustAddress}/proxy/${this.control.details.deviceUuid}/`);
                    }
                } else {
                    if (reachMode === ReachMode.LOCAL && (!proto.startsWith("https") || PlatformComponent.isDeveloperInterface())) {
                        return this._addressResolveDef.promise.then(address => {
                            return Q.resolve(`http://${address}`);
                        });
                    } else {
                        return Q.resolve(`${ActiveMSComponent.getMiniserverConnectionUrl()}/proxy/${this.control.details.deviceUuid}/`);
                    }
                }
            }

            _emitLocalStreamDeviceList() {
                return navigator.mediaDevices.enumerateDevices().then(function (devices) {
                    devices = devices.filter(function (device) {
                        // Filter out any unauthorized/uninitialized devices (i.e: on Raspberry Pi)
                        return !!nullEmptyString(device.deviceId);
                    }); // Normalize the labels

                    devices = devices.map(function (device, idx) {
                        var normalizedDevice = device.toJSON();

                        if (!nullEmptyString(normalizedDevice.label)) {
                            switch (normalizedDevice.kind) {
                                case this.constructor.DEVICE_KIND.AUDIO.INPUT:
                                    normalizedDevice.label = _("controls.intercom.settings.mic") + (idx + 1);
                                    break;

                                case this.constructor.DEVICE_KIND.AUDIO.OUTPUT:
                                    normalizedDevice.label = _("controls.intercom.settings.speaker") + (idx + 1);
                                    break;
                            }
                        }

                        return normalizedDevice;
                    }.bind(this));
                    this._localDevices = {
                        audioInput: devices.filter(function (device) {
                            return device.kind === this.constructor.DEVICE_KIND.AUDIO.INPUT;
                        }.bind(this)),
                        audioOutput: devices.filter(function (device) {
                            return device.kind === this.constructor.DEVICE_KIND.AUDIO.OUTPUT;
                        }.bind(this))
                    };

                    this._notifyDelegates(this.constructor._DELEGATE_FN.LOCAL_DEVICES_CHANGED, this._localDevices);
                }.bind(this));
            }

            _teardownWebRtc() {
                return this.stopRemoteVideoStream();
            }

            _teardown() {
                this._notifyDelegates(this.constructor._DELEGATE_FN.COM_CLOSED); // Notify before the teardown, as the intercom may already be offline, thus the webservice will timeout


                var tearDownPrms;

                try {
                    tearDownPrms = this._teardownWebRtc();
                } catch (e) {
                    tearDownPrms = Q.resolve();
                }

                clearTimeout(this._iceVideoErrorTimeout);
                clearTimeout(this._comErrorRetryTimer);
                delete this._iceVideoErrorTimeout;
                this._signalingReadyDef = null;
                delete this._resolvedAddress;
                delete this._bellState;
                this._addressResolveDef && this._addressResolveDef.reject("teardown");
                this._addressResolveDef = Q.defer();
                this._signaling && this._signaling.destroy();
                this._signaling = null;
                return tearDownPrms;
            }

            _checkSignaling() {
                if (this._signalingReadyDef && this._signalingReadyDef.promise.inspect().state !== "rejected") {
                    return this._signalingReadyDef.promise;
                } else {
                    return Q.reject(new Error("No signaling!"));
                }
            }

            _getInfo() {
                return this._signaling.execArbitraryMethod(this.constructor._ARBITRARY_METHODS.INFO).then(function (info) {
                    this._deviceInfo = info;

                    if ("callState" in info) {
                        this.onCallStateChange(info.callState);
                    } else {
                        this.onCallStateChange(this.constructor.CALL_STATE.AVAILABLE);
                    }

                    if ("excessiveTemp" in info) {
                        this.onExcessiveTemp(info.excessiveTemp);
                    } else {
                        this.onExcessiveTemp(false);
                    }

                    if ("isOnline" in info) {
                        this.onOnlineChanged(info.isOnline);
                    } else if ("hasInternet" in info) {
                        // Legacy
                        this.onOnlineChanged(info.hasInternet);
                    } else {
                        this.onOnlineChanged(true);
                    }

                    if ("ttsInfo" in info && !this.control.isTrustMember) {
                        this.onTTSInfoChanged(...info.ttsInfo);
                    } else {
                        this.onTTSInfoChanged(...this.control.getStates().answers.map(function () {
                            return true;
                        }));
                    }

                    if (this._versionStringState !== this._deviceInfo.version) {
                        this._updateFeatures(this._deviceInfo.version);
                    } // This information is only used when initially establishing a session


                    delete this._deviceInfo.callState;
                    delete this._deviceInfo.excessiveTemp;
                    delete this._deviceInfo.hasInternet;
                    delete this._deviceInfo.isOnline;
                    delete this._deviceInfo.ttsInfo;

                    if (!this._deviceInfo.hasOwnProperty("videoStreamQuota")) {
                        this._deviceInfo.videoStreamQuota = this.constructor._VIDEO_STREAM_QUOTA;
                    }
                }.bind(this));
            }

            _isSafari() {
                return /^((?!chrome|android).)*safari/i.test(navigator.userAgent) || PlatformComponent.isIOS();
            }

            _startScreenSaverBlocker() {
                this._stopScreenSaverBlocker();

                SandboxComponent.activityTick();
                this._screenSaverBlockerTimer = setTimeout(this._startScreenSaverBlocker.bind(this), 20 * 1000);
            }

            _stopScreenSaverBlocker() {
                this._screenSaverBlockerTimer && clearTimeout(this._screenSaverBlockerTimer);
                this._screenSaverBlockerTimer = null;
            }

            _notifyAboutError(reason = "Intercom Gen. 2 Issue") {
                this.loadTechReport().then(function (techReport) {
                    console.log("###############################################");
                    console.log("######## Detailed Intercom Tech Report ########");
                    console.log("###############################################");
                    console.log(techReport);
                    console.log("###############################################");

                    if (!!GUI.DebugScreen) {//NavigationComp.requestDebuglog(reason, reason, "Please save the debuglog and notify us about this! The detailed Tech Report has already been obtained and is part of the Debuglog.", null, PlatformComponent.isDeveloperInterface);
                    }
                }, function (e) {
                    console.log("###############################################");
                    console.log("######## Detailed Intercom Tech Report ########");
                    console.log("###############################################");
                    console.log("Couldn't gather a detailed Tech Report");
                    console.log(e);
                    console.log("###############################################");

                    if (!!GUI.DebugScreen) {//NavigationComp.requestDebuglog(reason, reason, "Please save the debuglog and notify us about this! The detailed Tech Report couldn't be loaded (Intercom offline/restarting?).", null, PlatformComponent.isDeveloperInterface);
                    }
                });
            }

            _removeCachedCandidatesForType(mediaType) {
                if (this._parsedLocalDescription && this._parsedLocalDescription.media) {
                    var mediaSDP = this._parsedLocalDescription.media.find(function (media) {
                        return media.type === mediaType;
                    });

                    if (mediaSDP && "mid" in mediaSDP) {
                        delete this._cachedLocalIceCandidates[mediaSDP.mid];
                        delete this._cachedRemoteIceCandidates[mediaSDP.mid];
                    } else {
                        Debug.Control.Athene.Media && console.log(this.name, "Couldn't remove cached Candidates for type '" + mediaType + "'");
                    }
                }
            }

            _getIceCandidateMediaType(candidate) {
                if (!candidate) {
                    return "NULL";
                }

                if (this._parsedLocalDescription && this._parsedLocalDescription.media) {
                    var mediaSDP = this._parsedLocalDescription.media.find(function (media) {
                        return media.mid.toString() === candidate.sdpMid.toString();
                    });

                    return mediaSDP.type;
                } else {
                    return "UNKNOWN";
                }
            }

            // Fileprivate
            __translRsState(streamState) {
                return translateEnum(this.constructor.REMOTE_STREAM_STATE, streamState);
            }

            __translRsError(error) {
                return translateEnum(this.constructor.REMOTE_STREAM_ERROR, error);
            }

        }

        /**
         * /**
         * Singleton, will create one and only one MediaHandler per provided control to prevent multiple API calls
         * @note: WebRTC is basically still in early stage of development, the standard is not followed by all browsers or operating systems.
         * Notably iOS (Safari) differs from the default implementation
         * @Safari-Quirks:
         *  • Does only support the H264 codec, no VP8 or VP9!
         *  • Is very picky regarding the SDP
         *  • The intercoms audio is routed through the **iPhones** earpiece rather than the devices main speaker,
         *      there is currently an ongoing issue, check it out in Apples Feedback App: FB9448512
         *      UPDATE: iOS 15.3 Beta 1 finally fixes this issue: https://bugs.webkit.org/show_bug.cgi?id=218012
         *   • iOS 15 devices will send an empty microphone stream after, so no audio can be heard on the intercom side
         *      A workaround has been implemented using Custom Scheme Handler on iOS: FB9492341
         * @Android-Quirks:
         *  • Not all Android devices *cough* Huawei *cough* support H264. This is particularly bad, as our intercom ONLY supports this coded
         *      • This is due to the use of HI (HiSilicon) Kirin chips which don't support hardware H264 decoding. Funny enough, that we use HI chips in the intercom with H264 encoding 🤦
         *  • Some Android devices can't handle the `requiresIOSSpeakerFix`, so it only applies to iOS devices, which makes the understanding quite difficult
         *  @Other-Quirks:
         *  • Check the `availableFeatures` getter for a detailed overview regarding the feature fingerprinting and HTTP(s) restrictions
         *  @FYI:
         *  SDP information: https://www.msxfaq.de/skype_for_business/technik/sdp.htm
         * @type {*|FunctionConstructor}
         */
        Controls.AtheneControl.SingleTons.MediaHandler = MediaHandler;
    }

    return Controls.AtheneControl.SingleTons.MediaHandler;
});
