// We have multiple modules so save the brandColorSet globally to ensure we have the same colors throughout the app
if (!("__brandColorSet" in window)) {
    window.__brandColorSet = {};
}

export const colors = {

    // region brandable colors

    setBrandingColorSet(colorSet) {
        console.log("AppBranding", "setBrandingColorSet > input = " + JSON.stringify(colorSet));
        window.__brandColorSet = {};
        const colorStringRgx = /^#(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$/
        Object.keys(colorSet)
            .filter(prop => { // check if the color is brandable to begin with
                let brandable = false;
                switch (prop) {
                    case "green":
                    case "red":
                    case "orange":
                    case "yellow":
                    case "purple":
                    case "blue":
                    case "anthracite":
                    case "brand-color":
                        brandable = true;
                        break;
                    default:
                        console.log("AppBranding", "   " + prop + " is not brandable!");
                        break;
                }
                return brandable;
            })
            .filter(prop => { // ensure it's a color string, not something malicious
                if (!colorStringRgx.test(colorSet[prop])) {
                    console.log("AppBranding", "   " + prop + " is not a valid color according to regex-check! '" + colorSet[prop] + "'");
                    return false;
                }
                return true;
            })
            // last but not least, map it.
            .forEach(colorProp => {
                if (colorProp === "brand-color") {
                    window.__brandColorSet["brand"] = colorSet[colorProp];
                } else {
                    window.__brandColorSet[colorProp] = colorSet[colorProp];
                }
            });
        console.log("AppBranding", "setBrandingColorSet > result = " + JSON.stringify(window.__brandColorSet));
    },
    unsetBrandingColorSet() {
        console.log("AppBranding", "unsetBrandingColorSet");
        window.__brandColorSet = {};
    },

    _getColor(colorName) {
        if (window.__brandColorSet && window.__brandColorSet.hasOwnProperty(colorName)) {
            return window.__brandColorSet[colorName];
        }
        return colors[colorName + "_fixed"];
    },

    green_fixed: "#69C350",
    red_fixed: "#E73246",
    orange_fixed: "#f7b55C",
    yellow_fixed: "#fdd835",
    purple_fixed: "#b962d9",
    blue_fixed: "#00B0DB",
    anthracite_fixed: "#575C61",
    brand_fixed: "#69C350",

    get green() {
        return colors._getColor("green");
    },
    get red() {
        return colors._getColor("red");
    },
    get orange() {
        return colors._getColor("orange");
    },
    get yellow() {
        return colors._getColor("yellow");
    },
    get purple() {
        return colors._getColor("purple");
    },
    get blue() {
        return colors._getColor("blue");
    },
    get anthracite() {
        return colors._getColor("anthracite");
    },
    get brand() {
        return colors._getColor("brand");
    },

    // endregion brandable colors

    stateInActive: "rgba(0,0,0,0.24)",
    get stateActive() {
        return colors.green; // state active is per default the defined green color
    },
    get selected () {
      return "#414952"
    },
    oak: "#BCA78A",

    greenLed: "#00FF00",

    alexaBlue: "#5FCAF4",
    anthraciteFixed: "#575C61",
    black: "#000",
    grey: {
        "50": "#F5F5F5",
        "200": "#D8D8D8",
        "300": "#787880", // grey300 + 30% alpha
        "300a18": "rgba(120,120,128,0.18)", // grey300 + 30% alpha
        "300a36": "rgba(120,120,128,0.36)", // grey300 + 36% alpha
        "400": "#3A3A3C",
        "500": "#2C2C2E",
        "600": "#1C1C1E",
        "700": "#0E0E0F",
        "800": "#3F3F3F",
        "900": "#202020",
        "1000": "#1D1D1F",
    },
    greySlider: "#4e5761",
    mantisGreen: {
        "800": "#2A4E20"
    },
    transparent: "transparent",
    white: "#FFF",
    yellowFixed: "#fdd835",

    tabBarGrey: "#757575",

    text: {
        primary: "#EBEBF5",
        secondary: "rgba(234,234,245,0.6)",
        tertiary: 'rgba(235, 235, 245, 0.3)',
        quaternary: 'rgba(235, 235, 245, 0.15)',
    },
    placeholder: "rgba(235, 235, 245, 0.6)",
    fill: {
        primary: "rgba(120,120,128,0.36)",
        tertiary: "rgba(118,118,128,0.24)",
        quaternary: "rgba(116, 116, 128, 0.18)"
    },
    border: "rgba(120,120,128,0.16)",
    borderColor: {
        default: "rgba(49, 49, 51, 0.65)",
        ambient: "rgba(255,255,255,0.06)",
    },

    ambientBg: "rgba(28,28,30,0.64)",

    buttonDisabledBg: "rgba(102,115,128,0.24)",
    buttonDisabled: "#4d5660",

    darkener: "rgba(0,0,0,0.1)",

    flexibleCell: {
        debug: {
            main: "rgba(245,40,145,0.3)",
            mainLeftRight: "rgba(255,0,0,0.3)",
            leftAccessory: "rgba(50, 0, 255,0.3)",
            top: "rgba(50,255,50, 0.3)",
            bottom: "rgba(244,100,60,0.3)"
        }
    },

    stateInactiveB: "rgba(102,115,128,0.24)",
}
