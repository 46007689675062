'use strict';

define(["ClimateUsControlEnums"], function (CtrlEnums) {
    return class ClimateUsModeScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div />'));
            Object.assign(this, StateHandler.Mixin);
            this.control = details.control;
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function () {
                this._registerForStates(this.control.uuidAction, this.getStateIDs());
            }.bind(this));
        }

        viewWillDisappear() {
            this._unregisterStates();

            return super.viewWillDisappear(...arguments);
        }

        getAnimation() {
            return AnimationType.HD_OVERLAY;
        }

        titleBarText() {
            return _("controls.climate.us.mode.title");
        }

        reloadTable() {
            this.tableContent = this._getContent(this.control.getStates());
            return super.reloadTable(...arguments);
        }

        // -----------------------------------------------------------------------------------------------
        //                      State Handler
        // -----------------------------------------------------------------------------------------------
        getStateIDs() {
            return ["mode"];
        }

        receivedStates(states) {
            this.reloadTable();
        }

        // -----------------------------------------------------------------------------------------------
        //                      Content
        // -----------------------------------------------------------------------------------------------
        _getContent(states) {
            var content = [];
            content.push(this._getModesSection(states));
            content.push({
                rows: [this._getStartTimerCell(states)]
            });
            return content;
        }

        _getModesSection(states) {
            var section = null,
                activeOpMode = states.mode;
            section = {
                headerTitle: _("controls.climate.us.mode.title"),
                rows: []
            };
            section.rows.push(this._getModeCell(_("controls.climate.us.mode.auto"), CtrlEnums.Mode.HEATING_AND_COOLING, activeOpMode));
            section.rows.push(this._getModeCell(_("controls.climate.us.mode.heating"), CtrlEnums.Mode.HEATING_ONLY, activeOpMode));
            section.rows.push(this._getModeCell(_("controls.climate.us.mode.cooling"), CtrlEnums.Mode.COOLING_ONLY, activeOpMode));
            section.rows.push(this._getModeCell(_("controls.climate.us.mode.off"), CtrlEnums.Mode.OFF, activeOpMode));
            return section;
        }

        _getModeCell(name, number, activeNr) {
            return {
                type: GUI.TableViewV2.CellType.CHECKABLE,
                content: {
                    title: name,
                    selected: number === activeNr,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED
                },
                didCheckCell: this._handleModeSelection.bind(this, number)
            };
        }

        _getStartTimerCell(states) {
            return {
                type: GUI.TableViewV2.CellType.GENERAL,
                content: {
                    title: _("controls.climate.us.mode.start-timer"),
                    clickable: true,
                    rightIconSrc: Icon.TimedSwitch.ICON,
                    rightIconColor: Color.TEXT_B
                },
                action: this._openModeTimerScreen.bind(this)
            };
        }

        // -----------------------------------------------------------------------------------------------
        //                      Commands
        // -----------------------------------------------------------------------------------------------
        _handleModeSelection(opModeNr) {
            this.control.sendCommand(Commands.format(Commands.CLIMATE_CONTROLLER_US.SET_MODE, opModeNr));
        }

        // -----------------------------------------------------------------------------------------------
        //                      Timer Handling
        // -----------------------------------------------------------------------------------------------
        _openModeTimerScreen() {
            var details = {},
                states = this.control.getStates(),
                returnToMode = states.mode,
                // return to the current mode
                suggestedMode; // Determine a candidate for the timer mode

            if (states.isHeating && states.hasCoolingDemand) {
                suggestedMode = CtrlEnums.Mode.COOLING_ONLY;
            } else if (states.isCooling && states.hasHeatingDemand) {
                suggestedMode = CtrlEnums.Mode.HEATING_ONLY;
            } else if (states.modeOff) {
                suggestedMode = CtrlEnums.Mode.AUTO;
            } else {
                suggestedMode = CtrlEnums.Mode.OFF;
            }

            details.title = _("controls.climate.us.mode.start-timer");
            details.callbackFn = this._handleModeTimerScreenCallback.bind(this);
            details.returnTimepoint = true;
            details.offerAlways = false;
            details.alwaysSwitchText = _("controls.climate.us.mode.always-active");
            details.pickerText = _("controls.climate.us.mode.active-until");
            details.timerOptions = this._getTimerModeOptions(suggestedMode);
            details.timerOptionsTitle = _("controls.climate.us.mode.select-mode");
            details.afterTimerOptions = this._getTimerModeOptions(returnToMode);
            details.afterTimerOptionsTitle = _("controls.climate.us.mode.mode-after-timer");
            this.ViewController.showState(ScreenState.TIMER_UNTIL_OPTIONS_SCREEN, null, details);
        }

        _getTimerModeOptions(selectedNr) {
            var rows = [];
            rows.push(this._getTimerModeOption(_("controls.climate.us.mode.auto"), CtrlEnums.Mode.HEATING_AND_COOLING, selectedNr));
            rows.push(this._getTimerModeOption(_("controls.climate.us.mode.heating"), CtrlEnums.Mode.HEATING_ONLY, selectedNr));
            rows.push(this._getTimerModeOption(_("controls.climate.us.mode.cooling"), CtrlEnums.Mode.COOLING_ONLY, selectedNr));
            rows.push(this._getTimerModeOption(_("controls.climate.us.mode.off"), CtrlEnums.Mode.OFF, selectedNr));
            return rows;
        }

        _getTimerModeOption(name, number, selectedNr) {
            return {
                id: number,
                title: name,
                selected: number === selectedNr
            };
        }

        _handleModeTimerScreenCallback(activeUntil, timerOption, afterTimerOption) {
            var cmd = Commands.format(Commands.CLIMATE_CONTROLLER_US.START_MODE_TIMER, timerOption.id, activeUntil, afterTimerOption.id);
            this.control.sendCommand(cmd);
            this.closeAction();
        }

    };
});
