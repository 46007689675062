import { View } from "react-native"
import { useMemo } from "react"
import { LxReactText } from "LxComponents"
import globalStyles from "GlobalStyles"

export default function MeterHeaderValue({ valueTxt, unitTxt, text, color }) {

    const topColor = useMemo(() => {
        return color || globalStyles.colors.stateActive;
    }, [color]);

    return <View style={styles.totalItem}>
        <LxReactText style={styles.itemValueCntr}>
            <LxReactText style={{ ...styles.itemValue, color: topColor }}>{valueTxt}</LxReactText>
            <LxReactText style={{ ...styles.itemUnit, color: topColor }}>{unitTxt}</LxReactText>
        </LxReactText>
        <LxReactText style={styles.itemTitle}>{text}</LxReactText>
    </View>
}

const styles = {
    totalItem: {
        flexDirection: "column",
        flex: 1,
        marginLeft: globalStyles.sizes.smallest,
        marginRight: globalStyles.sizes.smallest,
        justifyContent: "center"
    },
    itemValueCntr: {
        flexDirection: "row",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "flex-end"
    },
    itemValue: {
        ...globalStyles.textStyles.footNote.default,
        fontSize: globalStyles.fontSettings.sizes.large,
    },
    itemUnit: {
        ...globalStyles.textStyles.footNote.default,
        marginBottom: 2
    },
    itemTitle: {
        ...globalStyles.textStyles.footNote.default,
        color: globalStyles.colors.text.primary,
        fontSize: globalStyles.fontSettings.sizes.medium,
        textAlign: "center"
    }
}