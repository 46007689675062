'use strict';

define("DeviceLearningSelectorScreen", function () {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var LIGHT_GROUP_TYPE_DATASET = [{
        name: _('dont-use'),
        id: 0
    }, {
        name: _('device-learning.colored-light'),
        id: 1
    }, {
        name: _('device-learning.warm-white'),
        id: 2
    }];
    return class DeviceLearningSelectorScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div/>'));
            this.selectorType = details.type;

            switch (details.type) {
                case DeviceManagement.SELECTOR_TYPES.ROOM:
                    this.lastSelectedRow = details.roomUuid;
                    break;

                case DeviceManagement.SELECTOR_TYPES.LIGHT_GROUP:
                    this.lastSelectedRow = details.lightgroup;
                    this.dataCommand = Commands.DEVICE_MANAGEMENT.GET_LIGHT_GROUPS;
                    this.lastSelectedRoomUuid = details.roomUuid; //this.lightType = details.lightType;

                    break;

                case DeviceManagement.SELECTOR_TYPES.LIGHT_GROUP_TYPE:
                    this.lastSelectedRow = details.groupTypeId;
                    break;

                case DeviceManagement.SELECTOR_TYPES.SWITCHBOARD:
                    this.lastSelectedRow = details.switchboardName;
                    this.dataset = details.switchboards;
                    break;

                case DeviceManagement.SELECTOR_TYPES.HEAT_SWITCHBOARD:
                    this.lastSelectedRow = details.heatSwitchboardName;
                    this.dataset = details.heatSwitchboards;
                    break;
            }
        }

        setViewController() {
            super.setViewController(...arguments);
            this.ViewController.addMultipleView(ScreenState.DeviceLearningSelectorScreen);
        }

        viewDidLoad() {
            var args = arguments,
                def = Q.defer(),
                availableRooms;

            switch (this.selectorType) {
                case DeviceManagement.SELECTOR_TYPES.SWITCHBOARD:
                case DeviceManagement.SELECTOR_TYPES.HEAT_SWITCHBOARD:
                    def.resolve(this.dataset);
                    break;

                case DeviceManagement.SELECTOR_TYPES.ROOM:
                    availableRooms = ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.ROOM, true);
                    def.resolve(availableRooms);
                    break;

                case DeviceManagement.SELECTOR_TYPES.LIGHT_GROUP_TYPE:
                    def.resolve(LIGHT_GROUP_TYPE_DATASET);
                    break;

                case DeviceManagement.SELECTOR_TYPES.LIGHT_GROUP:
                    SandboxComponent.sendWithPermission(this.dataCommand, MsPermission.DEVICE_MANAGEMENT).then(function (res) {
                        def.resolve(res);
                    }.bind(this));
                    break;
            }

            return def.promise.then(dataset => {
                if (this.selectorType === DeviceManagement.SELECTOR_TYPES.LIGHT_GROUP) {
                    this.dataset = this._ensureLightGroupNoneExists(getLxResponseValue(dataset));
                } else {
                    this.dataset = dataset;
                }

                return super.viewDidLoad(...args);
            });
        }

        destroy() {
            if (this._structureChangesReg) {
                NavigationComp.removeFromUIEvent(this._structureChangesReg);
            }

            super.destroy(...arguments);
        }

        titleBarAction() {
            if (this.selectedEntry) {
                return this.ViewController.navigateBack(false, {
                    type: this.selectorType,
                    value: this.selectedEntry,
                    dataset: this.dataset
                });
            } else {
                return this.ViewController.navigateBack();
            }
        }

        titleBarText() {
            var titleBarText;

            switch (this.selectorType) {
                case DeviceManagement.SELECTOR_TYPES.ROOM:
                    titleBarText = _('room');
                    break;

                case DeviceManagement.SELECTOR_TYPES.LIGHT_GROUP:
                    titleBarText = _('device-learning.light-group');
                    break;

                case DeviceManagement.SELECTOR_TYPES.SWITCHBOARD:
                    titleBarText = _('device-learning.switchboard');
                    break;

                case DeviceManagement.SELECTOR_TYPES.HEAT_SWITCHBOARD:
                    titleBarText = _('device-learning.distributor');
                    break;

                case DeviceManagement.SELECTOR_TYPES.LIGHT_GROUP_TYPE:
                    titleBarText = _('device-learning.group-type');
                    break;
            }

            return titleBarText;
        }

        // TableView DataSource Methods
        styleForTableView() {
            return TableViewStyle.COMFORT_MODE_2020;
        }

        updateView(details) {
            switch (this.selectorType) {
                case DeviceManagement.SELECTOR_TYPES.LIGHT_GROUP:
                    this.lastSelectedRow = details.uuid;
                    this.createdLightGroup = details;
                    SandboxComponent.sendWithPermission(this.dataCommand, MsPermission.DEVICE_MANAGEMENT).then(function (res) {
                        this.dataset = this._ensureLightGroupNoneExists(getLxResponseValue(res));
                        this.reloadTable();
                    }.bind(this));
                    break;

                case DeviceManagement.SELECTOR_TYPES.SWITCHBOARD:
                case DeviceManagement.SELECTOR_TYPES.HEAT_SWITCHBOARD:
                    this.lastSelectedRow = details.name;
                    this.dataset.push(details);
                    this.reloadTable();
                    break;
            }
        }

        reloadTable() {
            this.tableContent = [];

            switch (this.selectorType) {
                case DeviceManagement.SELECTOR_TYPES.ROOM:
                    this.tableContent.push(this._getRoomsSection());

                    if (SandboxComponent.checkPermission(MsPermission.EXPERT_MODE) && Feature.CREATE_ROOMS) {
                        this.tableContent.push({
                            rows: [{
                                type: GUI.TableViewV2.CellType.BUTTON,
                                content: {
                                    title: _("create.room.button")
                                },
                                action: function () {
                                    this._showCreateRoomPopup();
                                }.bind(this)
                            }]
                        });
                    }

                    break;

                case DeviceManagement.SELECTOR_TYPES.SWITCHBOARD:
                    this.tableContent.push(this._getSwitchboardSection());
                    this.tableContent.push(this._getCreateNewSection(_('device-learning.create.switchboard')));
                    break;

                case DeviceManagement.SELECTOR_TYPES.HEAT_SWITCHBOARD:
                    this.tableContent.push(this._getSwitchboardSection());
                    this.tableContent.push(this._getCreateNewSection(_('device-learning.create.heat-switchboard')));
                    break;

                case DeviceManagement.SELECTOR_TYPES.LIGHT_GROUP:
                    this.tableContent.push(this._getLightGroupSection());
                    this.tableContent.push(this._getCreateNewSection(_('device-learning.create.light-group')));
                    break;

                case DeviceManagement.SELECTOR_TYPES.LIGHT_GROUP_TYPE:
                    this.tableContent.push(this._getLightGroupTypeSection());
                    break;
            }

            return super.reloadTable(...arguments);
        }

        handleStructureChanged(ev, updatePacket) {
            if (updatePacket && updatePacket.rooms && Object.keys(updatePacket.rooms).length > 0 && this._creatingRoom) {
                var uuid = Object.keys(updatePacket.rooms)[0];
                this.lastSelectedRow = uuid;
                NavigationComp.showGroupSettings(updatePacket.rooms[uuid], false, EXPERT_MODE.SPECIAL_TYPE.ADD_ROOM);
                this._creatingRoom = false;
            }

            this._createRoomDef.resolve();

            this.reloadTable();
        }

        _showCreateRoomPopup() {
            this._structureChangesReg = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.StructureChanged, this.handleStructureChanged.bind(this));
            NavigationComp.showPopup({
                title: _("create.room.popup.title"),
                message: _("create.room.popup.message"),
                input: {
                    id: "roomName",
                    required: true,
                    placeholder: _("room"),
                    type: GUI.LxInputEnum.TEXT
                },
                buttonOk: _("create.room.popup.button"),
                buttonCancel: true
            }, PopupType.INPUT).then(function (inputObject) {
                if (inputObject && inputObject.result) {
                    this._createRoom(inputObject.result);
                } else {
                    console.warn("No inputObject set");
                }
            }.bind(this));
        }

        _createRoom(roomName) {
            this._creatingRoom = true;
            this._createRoomDef = Q.defer();
            NavigationComp.showWaitingFor(this._createRoomDef.promise);
            var cmd = Commands.format(Commands.DEVICE_MANAGEMENT.CREATE_ROOM, roomName);
            SandboxComponent.sendWithPermission(cmd, MsPermission.EXPERT_MODE).fail(function (e) {
                console.log("Error creating new room: " + e);

                this._createRoomDef.resolve();
            }.bind(this));
        }

        _getRoomsSection() {
            var section = {
                    rows: []
                },
                isSelected,
                sortedRooms;
            sortedRooms = Object.values(this.dataset).sort(function (a, b) {
                return compareObjByName(a, b);
            });
            section.rows = sortedRooms.map(function (room) {
                isSelected = this.lastSelectedRow === room.uuid;

                if (isSelected) {
                    this.selectedEntry = room;
                }

                return this._getCellTemplate(room.name, isSelected, room);
            }.bind(this));
            return section;
        }

        _getSwitchboardSection() {
            var section = {
                    rows: []
                },
                isSelected;
            section.rows = this.dataset.map(function (entry) {
                isSelected = this.lastSelectedRow === entry.name;

                if (isSelected) {
                    this.selectedEntry = entry;
                }

                return this._getCellTemplate(entry.name, isSelected, entry);
            }.bind(this));
            return section;
        }

        _getLightGroupSection() {
            var section = {
                    rows: []
                },
                isSelected,
                availableRooms = ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.ROOM, true),
                roomName;
            this.dataset.forEach(function (entry) {
                isSelected = this.lastSelectedRow === entry.uuid;
                roomName = (entry.roomUuid ? availableRooms[entry.roomUuid].name : null);

                if (isSelected) {
                    this.selectedEntry = entry;
                }

                section.rows.push(this._getCellTemplate(entry.name === "" ? "-" : entry.name, isSelected, entry, roomName));
            }.bind(this));
            return section;
        }

        _getCreateNewSection(rowTitle) {
            var section = {
                    rows: []
                },
                details = {
                    type: this.selectorType,
                    datasetLength: this.dataset.length
                };

            if (this.selectorType === DeviceManagement.SELECTOR_TYPES.LIGHT_GROUP) {
                details.roomUuid = this.lastSelectedRoomUuid;
            }

            section.rows.push({
                content: {
                    title: rowTitle,
                    titleColor: window.Styles.colors.stateActive,
                    clickable: true
                },
                action: function () {
                    this.ViewController.showState(ScreenState.DeviceLearningCreateAttributeScreen, null, details);
                }.bind(this)
            });
            return section;
        }

        /**
         *
         * @param cellTitle
         * @param isSelected
         * @param navigateBackParam
         * @param [subtitle]
         * @return cellObject
         * @private
         */
        _getCellTemplate(cellTitle, isSelected, navigateBackParam, subtitle) {
            return {
                type: GUI.TableViewV2.CellType.CHECKABLE,
                content: {
                    title: cellTitle,
                    subtitle: subtitle || "",
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                    selected: isSelected
                },
                didCheckCell: function () {
                    //PersistenceComponent.saveFile(FILENAME, room, DataType.OBJECT);
                    this.ViewController.navigateBack(false, {
                        type: this.selectorType,
                        value: navigateBackParam,
                        dataset: this.dataset
                    });
                }.bind(this)
            };
        }

        _getLightGroupTypeSection() {
            var section = {
                    rows: []
                },
                isSelected;
            section.rows = this.dataset.map(function (entry) {
                isSelected = entry.id === this.lastSelectedRow;
                return this._getCellTemplate(entry.name, isSelected, entry);
            }.bind(this));
            return section;
        }

        /**
         * Goes through the available lighting groups list, checks if an entry exists for "no lighting group".
         * @param dataset   dataset to ensure it contains the none-option
         * @returns {*}     the (potentially modified) dataset that has a none selection.
         * @private
         */
        _ensureLightGroupNoneExists(dataset) {
            // ensure a "no lighting scene selected" entry is avilable
            if (!dataset.find((entry) => entry.id === 0)) {
                dataset.splice(0, 0, { id: 0, name: "" });
            }
            return dataset;
        }

    };
});
