import {createStateCell} from "../../UtilityComp/utilities/helper";

export default class WindowMonitorControlStatesSrc {
    static getStateSections(control, states) {
        let sections = [],
            rows;
        states && states.sortedStateArray && states.sortedStateArray.forEach((windowList) => {
            rows = [];
            windowList.forEach((entry) => {
                rows.push(this.createWindowStateCell(control, entry));
            });
            rows.length > 0 && sections.push({
                rows: rows
            });
        });
        return sections;
    }

    /**
     * Will create a state cell object for this window state entry.
     * @param entry
     * @param control
     * @return {*|{content: {title: *, stateText: *}, type: string}}
     */
    static createWindowStateCell(control, entry) {
        // only display room name if we have windows in more than 1 room..
        var locationName = null;

        if (entry.room) {
            locationName = entry.room.name;
            if(entry.installPlace) {
                locationName += `${SEPARATOR_SYMBOL}${entry.installPlace}`;
            }
        }

        // Some windows may have a uuid that references their corresponding control - if available, open it on tap.
        const windowCtrl = entry.uuid ? ActiveMSComponent.getStructureManager().getControlByUUID(entry.uuid) : null,
            openAction = windowCtrl ? () => {NavigationComp.showControlContent(windowCtrl);} : undefined;

        return createStateCell(entry.name, entry.stateText, null, locationName, openAction, entry.stateColor, null, !!windowCtrl);
    }
}
