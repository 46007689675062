'use strict';

define(["AudioZoneV2ControlEnums", "ControlContentMenu", "AudioZoneV2SearchInfoView"], function (AudioZoneV2ControlEnums, ControlContentMenu, AudioZoneV2SearchInfoView) {
    class AddMediaV2Screen extends GUI.TableViewScreenV2 {
        //region Private
        ALLOWED_CUSTOMIZATION_KEYS = [
            MusicServerEnum.ControlContentMenuId.PLAYLISTS,
            MusicServerEnum.ControlContentMenuId.RADIO,
            MusicServerEnum.ControlContentMenuId.SPOTIFY,
            MusicServerEnum.ControlContentMenuId.SOUNDSUIT,
            MusicServerEnum.ControlContentMenuId.LIB,
            MusicServerEnum.ControlContentMenuId.LINE_IN
        ]; //endregion Private

        constructor(details) {
            super($('<div />'));
            applyMixins(this, ContextMenuHandler.Mixin);
            this.details = details;
            this.control = details.control;
            this.control.audioserverComp.fillWithInputCustomizationKeys(this.ALLOWED_CUSTOMIZATION_KEYS);
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                this.searchView = new AudioZoneV2SearchInfoView(this.control, this, this.isInAmbientMode());
                this.prependSubview(this.searchView);
                this.ViewController.startEditMode();
            }.bind(this));
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        setTableContent() {
            var args = arguments;
            this.states = this.control.getStates();
            return ControlContentMenu.shared(this.control).getTableContent(this.tableView, false, this.ViewController).then(content => {
                this.tableContent = content.filter(function (section) {
                    return section.isContentSection;
                });
                this.tableContent[0].rows = this.tableContent[0].rows.filter(this.tableContentRowFilter.bind(this));
                return super.setTableContent(...args);
            });
        }

        /**
         * Filters the TableContents rows
         * @param row
         * @returns {boolean}
         */
        tableContentRowFilter(row) {
            return this.ALLOWED_CUSTOMIZATION_KEYS.filter(function (key) {
                return this.ViewController.BANNED_CUSTOMIZATION_KEYS.indexOf(key) === -1;
            }.bind(this)).indexOf(row.key) !== -1;
        }

    }

    Controls.AudioZoneV2Control.AddMediaV2Screen = AddMediaV2Screen;
    return Controls.AudioZoneV2Control.AddMediaV2Screen;
});
