import {useWindowDimensions} from "react-native";
import {useContext} from "react";
import {AmbientContext} from "LxComponents";


export default function useLxWindowDimensions(isEco = false) {
    const {height, width} = useWindowDimensions();
    const {miniAppSize} = useContext(AmbientContext);
    return isEco ? {
        height: Math.min(miniAppSize.height, 680),
        width : miniAppSize.width
    } : {height, width};
}
