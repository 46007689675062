import GroupsScreen from "../groups/groupsScreen.jsx";
import GroupCardContentScreen from "../groupCardContent/groupCardContentScreen.jsx"
import Icons from "IconLib";
import {createStackNavigator, TransitionPresets} from '@react-navigation/stack';
import {
    TopNavigator,
    useSetURLHook,
    LxReactCardScreenTopNavigator,
    navigatorConfig,
    AmbientContext
} from "LxComponents";
import {useContext} from "react";

const screenOptions = (...params) => {
    return {
        ...TopNavigator.forDefault(),
        ...TransitionPresets.ModalFadeTransition,
        ...navigatorConfig(LxReactCardScreenTopNavigator(...params))
    }
}


function CategoriesScreen({navigation, route}) {
    const { isAmbientMode } = useContext(AmbientContext)
   useSetURLHook({
        urlPart: UrlStartLocation.CATEGORY,
        appendIndex: 1
    });

    const Stack = createStackNavigator(),
        customGroupTitles = ActiveMSComponent.getStructureManager().getCustomGroupTitles(),
        headerTitle = customGroupTitles[GroupTypes.CATEGORY];

    const getEmptyScreenConfig = () => {
        return {
            iconSrc: Icons.Search,
            title: customGroupTitles[GroupTypes.CATEGORY],
            subtitle: _('empty.group-screen.title'),
            message: _('empty.group-screen.message')
        };
    }
    return (
        <Stack.Navigator initialRouteName={ScreenState.CategoriesOverview}
                         screenOptions={(params) => {
                             let options = {
                                 ...screenOptions(params, isAmbientMode),
                                 ...(isAmbientMode ? {cardOverlayEnabled: false} : {})
                             }
                             return options;
                         }}>
            <Stack.Screen  name={ScreenState.CategoriesOverview} component={GroupsScreen} initialParams={
                {
                    groupType: GroupTypes.CATEGORY,
                    emptyScreenConfig: getEmptyScreenConfig(),
                    headerTitle: headerTitle
                }
            }
            />
            <Stack.Screen name={ScreenState.CategoryDetail} component={GroupCardContentScreen}/>
        </Stack.Navigator>
    );
}

export default CategoriesScreen;
