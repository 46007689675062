import React, {useState} from 'react';
import {
    DndContext,
    closestCenter,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {SortableItem} from "./SortableItem";
import {LxReactFlexibleCell} from "../Components";
import globalStyles from "GlobalStyles";
import {View} from "react-native";

function DndSortableList({content, onCellMove, onCellRemove, listStyle, cellTitleStyle, cellSubTitleStyle, cellContainerStyle}) {
    const sensors = useSensors(
        useSensor(MouseSensor, {
            // Require the mouse to move by 10 pixels before activating
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(TouchSensor, {
            // Press delay of 250ms, with tolerance of 5px of movement
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            },
        })
    );
    const [items, setItems] = useState(content);

    const handleDragEnd = (event) => {
        const {active, over} = event;

        if (active.id !== over.id) {
            //onCellMove(0, active.id, over.id);
            setItems((tmpItems) => {
                const oldIndex = tmpItems.findIndex(idx => idx.id === active.id);
                const newIndex = tmpItems.findIndex(idx => idx.id === over.id);

                onCellMove(0, oldIndex, newIndex);
                return arrayMove(tmpItems, oldIndex, newIndex);
            });
        }
    }

    const handleOnRemove = (itemId) => {
        setItems((tmpItems) => {
            const oldIndex = tmpItems.findIndex(idx => idx.id === itemId);

            onCellRemove(0, oldIndex)
            tmpItems.splice(oldIndex, 1);

            return tmpItems;
        });
    }

    return (
        <View style={listStyle ?? styles.container} testID={"ContainerView"}>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                autoScroll={true}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={items}
                    strategy={verticalListSortingStrategy}
                >
                    {items.map(item => {
                        item.props.onRemove = () => {
                            handleOnRemove(item.id);
                        }

                        item.props.useInDnd = !!item.id;
                        item.props.id = item.id;
                        return <SortableItem key={item.id} id={item.id}>
                            <LxReactFlexibleCell section={0} row={item.id} {...item.props} titleStyle={[item.props.titleStyle, cellTitleStyle]} subTitleStyle={[item.props.subTitleStyle, cellSubTitleStyle]} cellContainerStyle={cellContainerStyle}/>
                        </SortableItem>
                    })}
                </SortableContext>
            </DndContext>
        </View>
    );
}

const styles = {
    container: {
        paddingHorizontal: globalStyles.spacings.gaps.regular,
        touchAction: "auto",
        overflow: 'auto',
        height: "100%",
        maxHeight: 220,
        marginBottom: globalStyles.spacings.gaps.smaller,
        width: "100%",
        minWidth: 400,
        maxWidth: 450,
        ...globalStyles.customStyles.blurredBackground,
    }
}

export default DndSortableList
