import {Pressable, Text, View} from "react-native";
import {useMemo} from "react";
import globalStyles from "GlobalStyles";
import PropTypes from "prop-types";
import {LxReactButton, ButtonType} from "LxComponents";

function AmbientCardComp({leftButtonText, leftButtonAction, rightButtonText, rightButtonAction, rightButtonDisabled, children}) {
    const rightButtonStyle = useMemo(() => {
        return {
            backgroundColor: rightButtonDisabled ? globalStyles.colors.fill.primary : globalStyles.colors.brand
        }
    }, [rightButtonDisabled])

    const rightButtonTextStyle = useMemo(() => {
        return {
            color: rightButtonDisabled ? globalStyles.colors.text.tertiary : globalStyles.colors.white
        }
    }, [rightButtonDisabled])


    return <View style={styles.mainContainer}>
        <View style={styles.card}>
            <View style={styles.container}>
                {children}
                <View style={styles.buttonContainer}>
                    <LxReactButton text={leftButtonText} textStyle={styles.textStyle} containerStyle={styles.notNowButton} onPress={leftButtonAction}/>
                    <LxReactButton text={rightButtonText} textStyle={rightButtonTextStyle} disabled={rightButtonDisabled} onPress={rightButtonAction} buttonType={ButtonType.PRIMARY} containerStyle={rightButtonStyle}/>
                </View>
            </View>
        </View>
    </View>

}

const styles = {
    mainContainer: {
        backgroundColor: globalStyles.colors.black,
        height: "100%",
        width: "100%",
        justifyContent: "center",
        flexWrap: "wrap",
        alignContent: "center"
    },
    card: {
        height: 600,
        width: 544,
        borderRadius: 12,
        backgroundColor: "#1C1C1E"
    },
    container: {
        height: "100%",
        width: "100%",
        padding: 32,
        flexDirection: "column"
    },
    buttonContainer: {
        justifyContent: "space-between",
        flexDirection: "row"
    },
    notNowButton: {
        backgroundColor: "transparent",
    },
    textStyle: {
        margin: "auto",
        textAlign: "center",
        color: globalStyles.colors.text.tertiary
    }
}


AmbientCardComp.propTypes = {
    leftButtonText: PropTypes.string,
    leftButtonAction: PropTypes.func,
    rightButtonText: PropTypes.string,
    rightButtonAction: PropTypes.func
};

export default AmbientCardComp
