'use strict';

define(["AudioZoneControlEnums", "MediaBrowserBase"], function (AudioZoneControlEnums, MediaBrowserBase) {
    class MediaBrowserLibrary extends MediaBrowserBase {
        //region Static
        static getCellFromContentTypeItem(item, details, contentType, isPlaylist, isBrowsable, actionClb, buttonTappedClb, vc, disabled) {
            var baseCell = Controls.AudioZoneControl.MediaBrowserBase.getCellFromContentTypeItem.apply(this, arguments);

            if (item.type === MediaEnum.FileType.LOCAL_DIR && item.external && item.configerror) {
                baseCell.content.class = null;
                baseCell.content.buttonSrc = Icon.INFO2;
                baseCell.content.color = window.Styles.colors.red;
            }

            return baseCell;
        }

        static getCellButtonIconSrc(item, contentType, vc) {
            return Controls.AudioZoneControl.MediaBrowserBase.getCellButtonIconSrc.apply(this, arguments);
        }

        static getCellTypeForItemCellAtIndex(item, contentType) {
            return Controls.AudioZoneControl.MediaBrowserBase.getCellTypeForItemCellAtIndex.apply(this, arguments);
        }

        static getIconObjForItem(item) {
            return Controls.AudioZoneControl.MediaBrowserBase.getIconObjForItem.apply(this, arguments);
        } //endregion Static


        //region Getter
        get desc() {
            return _("media.library");
        } //endregion Getter


        constructor(details) {
            super(...arguments);
            this.legacySearchIdentifier = MediaEnum.SearchLoc.LIBRARY;
        }

        viewWillAppear() {
            if (!this.libraryScanRegId) {
                this.libraryScanRegId = MediaServerComp.registerForScanEvents(this.onLibraryScanEvent.bind(this));
            }

            return super.viewWillAppear(...arguments);
        }

        viewDidDisappear() {
            if (!this._viewShouldRemainVisible) {
                this.libraryScanRegId && MediaServerComp.unregisterFromScanEvents(this.libraryScanRegId);
                this.libraryScanRegId = null;
            }

            return super.viewDidDisappear(...arguments);
        }

        onAllContentTypeData(reason, contentType) {
            if (this.isReindexing) {
                return Q(true);
            } else {
                return super.onAllContentTypeData(...arguments);
            }
        }

        onContentTypeDataChunk(reason, contentType) {
            if (this.isReindexing) {
                return Q(true);
            } else {
                return super.onContentTypeDataChunk(...arguments);
            }
        }

        onContentTypeReload(reason, contentType) {
            if (this.isReindexing) {
                return Q(true);
            } else {
                return super.onContentTypeReload(...arguments);
            }
        }

        onLibraryScanEvent(source, isReindexing) {
            if (isReindexing) {
                this.contentTypes.forEach(function (contentType) {
                    this.onContentTypeReload(MediaEnum.ReloadCause.WCHANGE, contentType);
                }.bind(this));
            }

            this.isReindexing = isReindexing;
            this.updateEmptyViewConfig();
        }

        getEmptyViewIconSrc() {
            var baseIcon = super.getEmptyViewIconSrc(...arguments);

            if (this.receivedContent) {
                baseIcon = null;
            }

            return baseIcon;
        }

        getEmptyViewTitle() {
            var baseTitle = super.getEmptyViewTitle(...arguments);

            if (this.isReindexing) {
                baseTitle = _("media.library.re-index") + "...";
            } else if (this.receivedContent) {
                baseTitle = _('media.library.upload.title');
            }

            return baseTitle;
        }

        getEmptyViewMessage() {
            var baseMsg = super.getEmptyViewMessage(...arguments);

            if (this.receivedContent) {
                baseMsg = _("media.library.info", {
                    target: MediaServerComp.getServerIp()
                });
            }

            return baseMsg;
        }

        handleOnItemCellClicked(item, contentType, section, row) {
            if (item.type === MediaEnum.FileType.LOCAL_DIR && item.external && item.configerror) {
                return this.handleOnCellButtonClicked.apply(this, arguments);
            } else {
                return super.handleOnItemCellClicked(...arguments);
            }
        }

        handleOnCellButtonClicked(item, contentType) {
            if (item.type === MediaEnum.FileType.LOCAL_DIR && item.external && item.configerror) {
                return NavigationComp.showPopup({
                    title: _("media.library.network-share.not-reachable.title"),
                    message: _("media.library.network-share.not-reachable.message", {
                        sharedName: item.name
                    }),
                    color: window.Styles.colors.orange,
                    buttonOk: true
                });
            } else {
                return super.handleOnCellButtonClicked(...arguments);
            }
        }

        getActionsForCtxAndItem(ctx, item) {
            var actions = super.getActionsForCtxAndItem(...arguments);

            switch (ctx) {
                case this.CONTEXT.LMS:
                    if (item === this) {
                        if (MediaServerComp.Feature.NAS_LIBRARY) {
                            actions.pushObject(this._getActionWithTmplForCtx({
                                content: {
                                    title: _("media.library.add") + "...",
                                    leftIconSrc: Icon.AudioZone.CONTEXT.LIBRARY_ADD
                                },
                                action: this.ViewController.ViewController.showState.bind(this.ViewController.ViewController, AudioZoneControlEnums.ScreenState.SETTINGS_LIBRARY_ADD_OR_EDIT)
                            }, ctx));
                        }

                        actions.pushObject(this._getActionWithTmplForCtx({
                            content: {
                                title: _("media.library.re-index") + "...",
                                leftIconSrc: Icon.AudioZone.CONTEXT.LIBRARY_SYNC
                            },
                            action: this._reloadLib.bind(this, this.lastSelectedItem)
                        }, ctx));
                    }

                    break;
            }

            return actions;
        }

        _reloadLib(item) {
            var mediaId = this.getMediaId(item.contentType);

            if (mediaId) {
                return MediaServerComp.sendMediaServerCommand(Commands.format(MediaEnum.Commands.LIBRARY.RESCAN_SPECIFIC, mediaId));
            } else {
                return MediaServerComp.sendMediaServerCommand(MediaEnum.Commands.LIBRARY.RESCAN_ALL);
            }
        }

    }

    Controls.AudioZoneControl.MediaBrowserLibrary = MediaBrowserLibrary;
    return Controls.AudioZoneControl.MediaBrowserLibrary;
});
