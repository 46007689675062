import {createStateCell} from "../../UtilityComp/utilities/helper";

export default class MeterControlStatesSrc {
    static getStateSections(control, states) {
        return [
            {
                rows: [
                    createStateCell(_("meter.actual"), states.actualValueText + states.actualSuccText),
                    createStateCell(_("meter.total"), states.totalValueText + states.totalSuccText)
                ]
            }
        ]
    }
}