'use strict';

define(["NfcCodeTouchControlEnums"], function (NfcCodeTouchControlEnums) {
    return class LearnNfcTagScreen extends GUI.Screen {
        //region Static
        static Template = function template() {
            var getTemplate = function getTemplate() {
                return $('' + '<div class="change-nfc-code-touch-button-container">' + '   <div class="change-nfc-code-touch-button-container__button">' + '       <div class="button__title"></div>' + '       <div class="button__subtitle"></div>' + '   </div>' + '   <div class="change-nfc-code-touch-button-container__description"></div>' + '</div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(details) {
            super($('<div class="nfc-screen" />'));
            this.allNfcCodeTouches = details.nfcCodeTouches;
            this.currentCodeTouch = null;
            this.userUuid = details.user.uuid;
            this.user = details.user;
            this.userName = details.user.name;
            this.users = details.users;
            this.isInLearnMode = false;
            this.canSwitchCodeTouches = false;
            this.identifiedTagId = null;
            SandboxComponent.setVisuPasswordAutoInvalidation(false);

            this.NFC_ERROR_IDS = {
                READ_ERROR:             "00 00 00 00 00 00 00 00 E8",
                AUTHENTICATION_ERROR:   "00 00 00 00 00 00 00 00 EE",
                INIT_ERROR:             "00 00 00 00 00 00 00 00 EF",
                INVALID_TAG:            "00 00 00 00 00 00 00 00"       // there are only 8 octets in this case.
            };
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.element.append(LearnNfcTagScreen.Template.getTemplate());

            this._registerForAllStates.bind(this, this.allNfcCodeTouches);

            this.setUpComfortUI(true);
        }

        viewWillAppear() {
            return Q(super.viewWillAppear(...arguments)).then(() => {
                this.msSerialNumber = ActiveMSComponent.getActiveMiniserver().serialNo;
                var savedNfcCodeTouchUuid = PersistenceComponent.getLastSelectedCodeTouch(this.msSerialNumber),
                    buttonSubtitleText;

                if (savedNfcCodeTouchUuid) {
                    this.allNfcCodeTouches.readyToLearnTag.forEach(function (nfcCodeTouch) {
                        if (savedNfcCodeTouchUuid === nfcCodeTouch.uuidAction) {
                            this.currentCodeTouch = nfcCodeTouch;
                        }
                    }.bind(this));
                }

                if (!this.currentCodeTouch) {
                    if (this.allNfcCodeTouches.readyToLearnTag.length) {
                        this.currentCodeTouch = this.allNfcCodeTouches.readyToLearnTag[0];
                    } else {
                        this.allNfcCodeTouches.readyToLearnTag.sort(function (l, r) {
                            return r.defaultRating - l.defaultRating;
                        });
                        this.currentCodeTouch = this.allNfcCodeTouches.readyToLearnTag[0];
                    }
                }

                if (this.currentCodeTouch.details.place) {
                    if (this.currentCodeTouch.getRoom()) {
                        buttonSubtitleText = this.currentCodeTouch.details.place + SEPARATOR_SYMBOL + this.currentCodeTouch.getRoom().name;
                    } else {
                        buttonSubtitleText = this.currentCodeTouch.details.place;
                    }
                } else {
                    buttonSubtitleText = this.currentCodeTouch.groupDetail;
                }

                this.setIcon(Icon.NfcCodeTouch.CODE_TOUCH_NFC_READER);
                this.setTitle(_("nfc.learning.learntag"));
                this.setSubtitle(_("nfc.learning.learntag.message"));
                this.elements.button = this.element.find(".change-nfc-code-touch-button-container");
                this.elements.buttonTitle = this.elements.button.find(".button__title").text(this.currentCodeTouch.name);
                this.elements.buttonSubtitle = this.elements.button.find(".button__subtitle").text(buttonSubtitleText);
                this.elements.buttonDescription = this.elements.button.find(".change-nfc-code-touch-button-container__description").text(_("nfc.learning.changecodetouch"));
                this.button = new GUI.LxButton(this, this.elements.button[0]);
                this.button.onButtonTapped = this._changeNfcCodeTouch.bind(this, this.allNfcCodeTouches);
                this._registerForAllStates();
                return this.addToHandledSubviews(this.button);
            });


        }

        /**
         * The base checks the granted permissions in viewDidAppear
         * --> this command requires extended permissions
         * @return {*}
         */
        viewDidAppear() {
            return Q(super.viewDidAppear(...arguments) || true).then(function () {
                this._startLearnMode();
            }.bind(this));
        }

        /**
         * receiving states from all NFC Code Touches
         * needed, to sort them by the devicestate
         * @param states
         * @param control
         */
        receivedStatesForControl(states, control) {
            this._sortAllNfcCodeTouchesObject(states, control);

            if (states.nfcLearnResult !== null && this.identifiedTagId !== states.nfcLearnResult.id) {
                this._nfcTagDetected(states.nfcLearnResult);
            }

            this.canSwitchCodeTouches = this.allNfcCodeTouches.offline.length > 0 && this.allNfcCodeTouches.readyToLearnTag.length > 0 || this.allNfcCodeTouches.canNotReadNfc.length > 0 && this.allNfcCodeTouches.readyToLearnTag.length > 0 || this.allNfcCodeTouches.readyToLearnTag.length > 1;
            this.elements.buttonDescription.toggle(this.canSwitchCodeTouches);
        }

        viewWillDisappear() {
            this._stopLearnMode();

            this._unregisterForAllStates();

            return super.viewWillDisappear(...arguments);
        }

        destroy() {
            SandboxComponent.setVisuPasswordAutoInvalidation(true);
            super.destroy();
        }

        getStateIconTemplatePath() {
            return Icon.STATE_ICON.NFC_LEARN;
        }

        getURL() {
            return "LearnNfcTagScreen";
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        closeAction() {
            this._stopLearModeIfNeeded();

            this.ViewController.navigateBack(false, {
                needsReload: "nfcTags",
                ts: moment().unix()
            });
        }

        //Private Section

        /**
         * Description navigates to the selector screen
         * @param allCodeTouches Array of NFCCodeTouches
         * @private
         */
        _changeNfcCodeTouch(allCodeTouches) {
            if (!this.canSwitchCodeTouches) {
                return;
            }

            var def = Q.defer(),
                details = {
                    sortedNfcCodeTouches: allCodeTouches,
                    selectedNfcCodeTouchUuid: this.currentCodeTouch.uuidAction,
                    def: def
                };
            this.ViewController.showState(ScreenState.NfcSelectorScreen, null, details);
            def.promise.then(function (res) {
                this.currentCodeTouch = res;
                PersistenceComponent.setLastSelectedCodeTouch(this.currentCodeTouch.uuidAction, this.msSerialNumber);
            }.bind(this));
        }

        _sortAllNfcCodeTouchesObject(states, control) {
            this.allNfcCodeTouches.readyToLearnTag.forEach(function (nfcCodeTouchObj, idx) {
                if (nfcCodeTouchObj.uuidAction === control.uuidAction) {
                    if (states.deviceState !== NfcCodeTouchControlEnums.DeviceStates.READY_TO_LEARN_TAG) {
                        this.allNfcCodeTouches.readyToLearnTag.splice(idx, 1);
                    }
                }
            }.bind(this));
            this.allNfcCodeTouches.offline.forEach(function (nfcCodeTouchObj, idx) {
                if (nfcCodeTouchObj.uuidAction === control.uuidAction) {
                    if (states.deviceState !== NfcCodeTouchControlEnums.DeviceStates.OFFLINE) {
                        this.allNfcCodeTouches.offline.splice(idx, 1);
                    }
                }
            }.bind(this));
            this.allNfcCodeTouches.canNotReadNfc.forEach(function (nfcCodeTouchObj, idx) {
                if (nfcCodeTouchObj.uuidAction === control.uuidAction) {
                    if (states.deviceState !== NfcCodeTouchControlEnums.DeviceStates.CAN_NOT_READ_TAG) {
                        this.allNfcCodeTouches.canNotReadNfc.splice(idx, 1);
                    }
                }
            }.bind(this));

            switch (states.deviceState) {
                case NfcCodeTouchControlEnums.DeviceStates.READY_TO_LEARN_TAG:
                    this.allNfcCodeTouches.readyToLearnTag.pushIfNoDuplicate(control);
                    break;

                case NfcCodeTouchControlEnums.DeviceStates.OFFLINE:
                    this.allNfcCodeTouches.offline.pushIfNoDuplicate(control);

                    if (this.currentCodeTouch.uuidAction === control.uuidAction) {
                        this._abortLearnSessionDueToOfflineState();
                    }

                    break;

                case NfcCodeTouchControlEnums.DeviceStates.CAN_NOT_READ_TAG:
                    this.allNfcCodeTouches.canNotReadNfc.pushIfNoDuplicate(control);

                    if (this.currentCodeTouch.uuidAction === control.uuidAction) {
                        this._abortLearnSessionDueToNfcCapabilityChange();
                    }

                    break;
            }
        }

        _abortLearnSessionDueToOfflineState() {
            this.ViewController.navigateBack();
            NavigationComp.showPopup({
                title: _("nfc.learning.notreachable"),
                message: _("nfc.learning.notreachable.message"),
                buttonOk: true,
                icon: Icon.WARNING,
                color: window.Styles.colors.orange
            }, PopupType.GENERAL);
        }

        _abortLearnSessionDueToNfcCapabilityChange() {
            this.ViewController.navigateBack();
        }

        /**
         * registers for all state updates for all code touches
         * @private
         */
        _registerForAllStates() {
            this.allNfcCodeTouches.readyToLearnTag.forEach(function (codeTouch) {
                SandboxComponent.registerForStateChangesForUUID(codeTouch.uuidAction, this, function (states) {
                    this.receivedStatesForControl(states, codeTouch);
                }.bind(this));
            }.bind(this));
            this.allNfcCodeTouches.offline.forEach(function (codeTouch) {
                SandboxComponent.registerForStateChangesForUUID(codeTouch.uuidAction, this, function (states) {
                    this.receivedStatesForControl(states, codeTouch);
                }.bind(this));
            }.bind(this));
            this.allNfcCodeTouches.canNotReadNfc.forEach(function (codeTouch) {
                SandboxComponent.registerForStateChangesForUUID(codeTouch.uuidAction, this, function (states) {
                    this.receivedStatesForControl(states, codeTouch);
                }.bind(this));
            }.bind(this));
        }

        _unregisterForAllStates() {
            Object.keys(this.allNfcCodeTouches).forEach(function (key) {
                this.allNfcCodeTouches[key].forEach(function (nfcCodeTouch) {
                    SandboxComponent.unregisterForStateChangesForUUID(nfcCodeTouch.uuidAction, this);
                }.bind(this));
            }.bind(this));
        }

        /**
         * this function decides, which Popup we have to show
         * @param tagInfo all information we get from the Nfc Tag
         * @private
         */
        _nfcTagDetected(tagInfo) {
            this.identifiedTagId = tagInfo.id;

            if (tagInfo.id === this.NFC_ERROR_IDS.READ_ERROR) {
                this._showErrorPopup(this.NFC_ERROR_IDS.READ_ERROR);
            } else if (tagInfo.id === this.NFC_ERROR_IDS.AUTHENTICATION_ERROR) {
                this._showErrorPopup(this.NFC_ERROR_IDS.AUTHENTICATION_ERROR);
            } else if (tagInfo.id === this.NFC_ERROR_IDS.INVALID_TAG) {
                this._showErrorPopup(this.NFC_ERROR_IDS.INVALID_TAG);
            } else if (tagInfo.id === this.NFC_ERROR_IDS.INIT_ERROR) {
                this._showErrorPopup(this.NFC_ERROR_IDS.INIT_ERROR);
            } else if (tagInfo.userUuid === null && tagInfo.deviceUuid === null && tagInfo.id !== "") {
                this._addTag(tagInfo);
            } else if (tagInfo.id !== "") {
                this._reassignTag(tagInfo);
            }
        }

        _addTag(tagInfo) {
            ActiveMSComponent.addNfcTag(this.userUuid, tagInfo.id, tagInfo.name).then(function (response) {
                if (parseInt(response.LL.Code) === ResponseCode.OK) {
                    this._stopLearnMode();

                    return NavigationComp.showPopup({
                        title: _("nfc.learning.success"),
                        message: _("nfc.learning.assigned", {
                            user: this.userName
                        }),
                        buttonOk: _("finish"),
                        buttonCancel: false,
                        icon: Icon.SUCCESS,
                        color: window.Styles.colors.stateActive,
                        input: {
                            id: "nfcTagName",
                            required: false,
                            value: tagInfo.name
                        }
                    }, PopupType.INPUT).then(function (res) {
                        return ActiveMSComponent.addNfcTag(this.userUuid, tagInfo.id, res.result);
                    }.bind(this)).finally(function () {
                        this.ViewController.navigateBack(false, {
                            needsReload: "nfcTags",
                            ts: moment().unix()
                        }); // force updateView on the previous View
                    }.bind(this));
                }
            }.bind(this));
        }

        _reassignTag(tagInfo) {
            var oldUserName = this._getUserForTag(tagInfo);

            this._stopLearnMode();

            return NavigationComp.showPopup({
                title: _("nfc.learning.already.assigned"),
                message: _("nfc.learning.already.assigned.text", {
                    oldUser: oldUserName,
                    newUser: this.userName
                }),
                buttonOk: _("nfc.learning.override"),
                buttonCancel: true,
                icon: Icon.WARNING,
                color: window.Styles.colors.orange
            }, PopupType.GENERAL).then(function () {
                return ActiveMSComponent.addNfcTag(this.userUuid, tagInfo.id, tagInfo.name).then(function (res) {
                    // after reassiging, the user can assign a new name
                    return this._addTag(tagInfo);
                }.bind(this));
            }.bind(this), function () {
                this._startLearnMode();

                this.identifiedTagId = null;
            }.bind(this));
        }

        _showErrorPopup(errorId) {
            var title = "";
            var message = "";

            switch (errorId) {
                case this.NFC_ERROR_IDS.READ_ERROR:
                    title = _("nfc.learning.readerror");
                    message = _("nfc.learning.readerror.message");
                    break;

                case this.NFC_ERROR_IDS.AUTHENTICATION_ERROR:
                    title = _("nfc.learning.authenticationerror");
                    message = _("nfc.learning.authenticationerror.message");
                    break;

                case this.NFC_ERROR_IDS.INIT_ERROR:
                    title = _("nfc.learning.initerror");
                    message = _("nfc.learning.initerror.message");
                    break;

                case this.NFC_ERROR_IDS.INVALID_TAG:
                    title = _("nfc.learning.invalidtag.title");
                    message = _("nfc.learning.invalidtag.message");
                    break;
            }

            return NavigationComp.showPopup({
                title: title,
                message: message,
                buttonOk: true,
                icon: Icon.WARNING,
                color: window.Styles.colors.orange
            }, PopupType.GENERAL).finally(function () {
                this.identifiedTagId = null;
            }.bind(this));
        }

        /**
         * Identifys user by userUuid from tag
         * @param allUsers UserList
         * @param tagInfo
         * @returns {string} username from tag
         * @private
         */
        _getUserForTag(tagInfo) {
            var tagUserUuid = tagInfo.userUuid,
                userName = "";
            this.users.forEach(function (user) {
                if (user.uuid === tagUserUuid) {
                    userName = user.name;
                }
            });
            return userName;
        }

        _startLearnMode() {
            this.currentCodeTouch.sendCommand(Commands.NFC_CODE_TOUCH.NFC_CODE_TOUCH_START_LEARN, null, null, true).then(function (res) {
                this.isInLearnMode = true;
                this.nfcCodeTouchLearningInterval = setInterval(function () {
                    this.currentCodeTouch.sendCommand(Commands.NFC_CODE_TOUCH.NFC_CODE_TOUCH_START_LEARN, null, null, true);
                }.bind(this), 10000 / 3); // Miniserver is timing the NFC Code Touch in learn mode out after 10 seconds
            }.bind(this), function (res) {
                if (typeof res === "string" && res === Commands.SendState.FAILED) {
                    this.ViewController.navigateBack();
                }
            }.bind(this));
        }

        _stopLearnMode() {
            this._clearLearningInterval();

            this._stopLearModeIfNeeded();
        }

        _clearLearningInterval() {
            clearInterval(this.nfcCodeTouchLearningInterval);
            this.nfcCodeTouchLearningInterval = null;
        }

        _stopLearModeIfNeeded() {
            if (this.isInLearnMode) {
                this.currentCodeTouch.sendCommand(Commands.NFC_CODE_TOUCH.NFC_CODE_TOUCH_STOP_LEARN, null, null, true);
            }
        }

    };
});
