import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Copy(props) {
    return (
        <Svg isreact="true"
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 1.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v13a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-13zM2 14V2h12v12H2z"
            />
            <Path
                d="M16 6a.5.5 0 000 1h2v11H7v-2a.5.5 0 00-1 0v2.4a.6.6 0 00.6.6h11.8a.6.6 0 00.6-.6V6.6a.6.6 0 00-.6-.6H16z"
            />
        </Svg>
    )
}

export default Copy
