import { View, StyleSheet } from "react-native";
import { useContext, useEffect, useMemo, useState } from "react";
import {
    LxReactText,
    LxReactPressable,
    LxReactTableView,
    navigatorConfig,
    ControlContext
} from "LxComponents";
import globalStyles from 'GlobalStyles';
import { getColorFor, determineResolution } from '../utils';
import { useRequest } from "ahooks";

const mergeValuesWithinSection = (data, iteratorFn) => {
    if (data.length === 0) return data;

    const normalisedData = data.map(({ s, e, v }) => ({ s, e, v: v.toFixed(3) }));

    const mergedData = [];
    let currentGroup = { s: [], e: [], v: [] };
    const dataLength = normalisedData.length;

    for (let i = 0; i < dataLength; i++) {
        const curr = normalisedData[i];
        const prev = normalisedData[i - 1];

        if (i > 0 && curr.v === prev.v && iteratorFn(moment.unix(prev.s)).unix() === curr.s) {
            currentGroup.s.push(curr.s);
            currentGroup.e.push(curr.e);
            currentGroup.v = curr.v;
        } else {
            if (currentGroup.s.length > 0) {
                mergedData.push({ ...currentGroup });
            }
            currentGroup = { s: [curr.s], e: [curr.e], v: curr.v };
        }
    }

    // Push the last group
    if (currentGroup.s.length > 0) {
        mergedData.push({ ...currentGroup });
    }

    return mergedData;
};

export default function AutomaticScreen({ navigation }) {
    const { control, states } = useContext(ControlContext);
    const msNow = SandboxComponent.getMiniserverTimeInfo(null, null, TimeValueFormat.MINISERVER_DATE_TIME);
    const msUTCOffset = SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINISERVER_UTC_OFFSET);

    const [automaticSettings, setAutomaticSettings] = useState({
        demand: states.demand,
        isDemandValid: true,
        period: states.period,
        isPeriodValid: true
    })
    const [periodToLong, setPeriodToLong] = useState(false);

    navigation.setOptions(
        navigatorConfig({
            title: _("controls.spotpriceoptimizer.spot.automatic")
        })
    );

    const { data: forecast = [], error, loading } = useRequest(() => {
        return control.getForecast({
            forecastTimestamp: states.forecastTimestamp,
        })
    }, {
        refreshDeps: [
            states.forecastTimestamp
        ]
    });

    const { resolutionMultiplier, iteratorFn } = determineResolution(forecast, 2, true);

    useEffect(() => {
        if (!loading && !error) {
            const lastForecastEndUnix = forecast?.[forecast.length -1]?.e ?? msNow.clone();
            const lastHour = ActiveMSComponent.getMomentFromUtcTimestamp(lastForecastEndUnix).utc();
            const now = msNow.clone();

            const duration = moment.duration(lastHour.diff(now));
            const diffInHours = duration.asHours();
            const roundedHours = Math.ceil(diffInHours);

            if (roundedHours < automaticSettings.period) {
                setPeriodToLong(true);
            } else {
                setPeriodToLong(false);
            }
        }
    }, [JSON.stringify(forecast), automaticSettings.period, automaticSettings.demand, states.isArmed, loading, error])

    const mostCostEffectiveTimes = useMemo(() => {
        if (!loading && !error && forecast) {
            const automationProposed = cloneObject(forecast).some(item => item.hasOwnProperty("planned"));
            const times = automationProposed ? cloneObject(forecast).filter(item => item.planned) : cloneObject(forecast)
                .filter(item => item.s >= msNow.clone().utc().startOf('hour').unix())
                .splice(0, automaticSettings.period * resolutionMultiplier)
                .sort((a, b) => {
                    const diff = a.v - b.v;
                    if (diff === 0) {
                        return a.s - b.s;
                    } else {
                        return diff;
                    }
                })
                .slice(0, automaticSettings.demand * resolutionMultiplier)
                .sort((a, b) => a.s - b.s);
            return mergeValuesWithinSection(times, iteratorFn);
        } else {
            return []
        }
    }, [JSON.stringify(forecast), automaticSettings.demand, automaticSettings.period, loading, error, states.active]);

    const sendValue = () => {
        if (states.isArmed) {
            control.sendCommand(Commands.SPOT_PRICE_OPTIMIZER.CANCEL, Commands.Type.OVERRIDE);
        } else {
            control.sendCommand(
                Commands.format(Commands.SPOT_PRICE_OPTIMIZER.START,
                    Number(automaticSettings.demand),
                    Number(automaticSettings.period)
                ), Commands.Type.OVERRIDE);
        }
    }

    const demandRow = useMemo(() => {
        return {
            type: GUI.TableViewV2.CellType.INPUT,
            content: {
                title: _("controls.spotpriceoptimizer.demand"),
                placeholder: states.demand,
                type: GUI.LxInputEnum.Type.NUMBER,
                keyboardType: "number-pad",
                validation: [
                    { regExp: Regex.INT_VALUE, message: _("invalid-input")}
                ],
                value: automaticSettings.demand,
                customErrorMessage: automaticSettings.isDemandValid ? null : _("invalid-input"),
                disabled: states.isArmed || control.details.demandByLogic,
                subtitle: control.details.demandByLogic ? _("controls.ac-control.set-by-logic") : null,
            },
            textChanged: (sectionIdx, rowIdx, table, value, validInput) => {
                setAutomaticSettings((prevState) => ({
                    ...automaticSettings,
                    demand: validInput ? parseInt(value) : prevState.demand,
                    isDemandValid:
                        validInput &&
                        parseInt(value) < automaticSettings.period,
                    isPeriodValid:
                        validInput &&
                        automaticSettings.period > parseInt(value),
                }));
            },
        }
    }, [states.isArmed, states.demand, JSON.stringify(automaticSettings), states.period])

    const timePeriodRow = useMemo(() => {

        return {
            type: GUI.TableViewV2.CellType.INPUT,
            content: {
                title: _("controls.spotpriceoptimizer.timeperiod"),
                placeholder: states.period,
                type: GUI.LxInputEnum.Type.NUMBER,
                keyboardType: "number-pad",
                validation: [
                    { regExp: Regex.INT_VALUE, message: _("invalid-input")},
                ],
                value: automaticSettings.period,
                customErrorMessage: automaticSettings.isPeriodValid ? null : _("invalid-input"),
                disabled: states.isArmed || control.details.periodByLogic,
                subtitle: control.details.periodByLogic ? _("controls.ac-control.set-by-logic") : null,
            },
            textChanged: (sectionIdx, rowIdx, table, value, validInput) => {
                setAutomaticSettings((prevState) => ({
                    ...automaticSettings,
                    period: validInput ? parseInt(value) : prevState.period,
                    isPeriodValid:
                        validInput &&
                        parseInt(value) > automaticSettings.demand,
                    isDemandValid:
                        validInput &&
                        automaticSettings.demand < parseInt(value),
                }));
            },
        }
    }, [states.isArmed, states.period, periodToLong, JSON.stringify(automaticSettings), states.demand])

    const runTimeRow = useMemo(() => {
        const lastForecastEnd = ActiveMSComponent.getMomentFromUtcTimestamp(forecast?.[forecast.length -1]?.e ?? msNow.clone()),
            subtitleRuntimeToLong = periodToLong ? _("controls.spotpriceoptimizer.runtime.warning", {
                time: lastForecastEnd.clone().endOf("h").format("LT"),
                date: lastForecastEnd.format(LxDate.getDateFormat(DateType.DayMonth)),
            }) : null;

        let runtimeMoment;

        if (states.isArmed) {
            runtimeMoment = new LxDate(states.cycleUntil, true);
        } else {
            runtimeMoment = msNow.clone().add(automaticSettings.period -1, "h");
        }

        return {
            title: _("controls.spotpriceoptimizer.runtime"),
            mainRightContent: {
                comp: LxReactText,
                props: {
                    style: styles.runtimeText,
                    children: runtimeMoment.add(1, "h").startOf("hour").format(`${LxDate.getDateFormat(DateType.DayMonth)} LT`)
                }
            },
            bottomContent:  {
                comp: LxReactText,
                props: {
                    children: subtitleRuntimeToLong,
                    style: styles.warningText,
                }
            }
        }
    }, [states.cycleUntil, states.isArmed, mostCostEffectiveTimes, automaticSettings.period, periodToLong])

    const startRow = useMemo(() => {
        const isValid = automaticSettings.isDemandValid && automaticSettings.isPeriodValid,
            text = {
            comp: LxReactText,
            props: {
                style: isValid ? styles.pressable : styles.pressableDisabled,
                children: states.isArmed ?
                    _("controls.spotpriceoptimizer.stop.automatic") :
                    _("controls.spotpriceoptimizer.start.automatic"),
            }
        }

        return {
            content: {
                mainLeftContent: {
                    comp: LxReactPressable,
                    props: {
                        disabled: !isValid,
                        onPress: () => sendValue(),
                        pressableStyle: isValid ? styles.pressable : styles.pressableDisabled,
                        childComp: text,
                    }
                }
            }
            
        }
    }, [JSON.stringify(automaticSettings), states.isArmed]);

    const mostEffectiveRows = useMemo(() => {
        let suffix = "";
        if(_('timeSuffix') !== " ") {
            suffix = useAmPm() ? "" : ` ${_('timeSuffix')}`;
        }

        return mostCostEffectiveTimes.map((item, index) => {
            const valueText = lxUnitConverter.convertSplitAndApply(control?.details?.format ?? " ", parseFloat(item.v))
            const value = `${valueText.valueTxt} ${valueText.succTxt}`
            const color = item.e < msNow.startOf('hour').clone().unix() 
                ? globalStyles.colors.text.tertiary
                : getColorFor({ value: item.v, stats: states.statsForCurrentForecast }, states.manualMax);
            const leftItem = item.s[0] || item.s;
            const rightItem = item.e[item.e.length - 1] || item.e;

            return {
                title: `${moment.unix(leftItem).utc().utcOffset(msUTCOffset).format("LT")}${suffix} - ${moment.unix(rightItem).add(1, 'minute').utc().utcOffset(msUTCOffset).format("LT")}${suffix}`,
                mainRightContent: {
                    comp: LxReactText,
                    props: {
                        style: StyleSheet.compose(styles.text, { color: color }),
                        children: value,
                    }
                }
            }
        });
    }, [mostCostEffectiveTimes, JSON.stringify(states.statsForCurrentForecast), automaticSettings.period, msNow.startOf('hour').utc().unix()]);

    const tableContent = useMemo(() => {
        let content = [];
        let descriptionSection = {
            headerDescription: _("controls.spotpriceoptimizer.description"),
            hideItemSeparator: true
        }
        let settingsSection = {
            headerTitle: _("controls.spotpriceoptimizer.settings"),
            rows: [
                demandRow,
                timePeriodRow,
                runTimeRow,
                startRow
            ]
        }

        let costEffectiveSection = {
            headerTitle: _("controls.spotpriceoptimizer.most.effective"),
            rows: mostEffectiveRows
        }
        content.push(descriptionSection);
        content.push(settingsSection);
        content.push(costEffectiveSection);

        return content;
    }, [JSON.stringify(forecast), mostEffectiveRows, demandRow, timePeriodRow, runTimeRow, startRow]);

    return <View style={styles.container}>
        <LxReactTableView tableContent={tableContent} />
    </View>
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: "100%",
        maxWidth: globalStyles.sizes.contentMaxWidth,
        alignSelf: "center",
    },
    text: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.primary,
    },
    runtimeText: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.secondary,
    },
    pressable: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.green_fixed,
    },
    pressableDisabled: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.grey["300a36"]
    },
    description: {
        ...globalStyles.textStyles.footNote.default,
        color: globalStyles.colors.text.secondary,
        marginBottom: 16,
        width: "90%",
        alignSelf: "center",
    },
    warningText: {
        ...globalStyles.textStyles.footNote.default,
        color: globalStyles.colors.orange_fixed,
        marginTop: globalStyles.spacings.gaps.smaller
    }
})
