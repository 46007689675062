import {LxReactText} from 'LxComponents';
import PropTypes from "prop-types";
import globalStyles from "GlobalStyles";

function LxReactLinkText(props) {

    const renderText = () => {
        let translatedText = _(props.originalString)
        let replacements = [];

        props.identifiers.forEach((identifier, idx) => {
            let searchTerm = `${identifier.name}`;
            let startIndex = translatedText.indexOf(searchTerm);
            let endIndex = startIndex + searchTerm.length;

            let replacement = (<LxReactText onPress={identifier.onPress} style={[
                styles.defaultText,
                props.textStyle,
                identifier.style
            ]}>{identifier.text}</LxReactText>);
            replacements.push({
                startIndex,
                endIndex,
                replacement
            });
        });

        let textParts = [];
        let lastIndex = 0;
        let i;
        for (i = 0; i < translatedText.length; i++) {
            let possibleReplacement = replacements.find(replacement => replacement.startIndex === i);
            if (possibleReplacement) {
                let preText = translatedText.substring(lastIndex, i);
                textParts.push(preText)
                textParts.push(possibleReplacement.replacement);
                lastIndex = possibleReplacement.endIndex;
                i = possibleReplacement.endIndex;
            }
        }
        if (i !== lastIndex) {
               textParts.push(translatedText.substring(lastIndex, i));
        }

        return textParts;
    }

    return (
        <div style={props.containerStyle}>
            <LxReactText style={[styles.defaultText, props.textStyle, {flexDirection: "row"}]}>{renderText()}</LxReactText>
        </div>
    );
}

const styles = {
    defaultText: {
        color:  globalStyles.colors.text.secondary,
        fontSize: globalStyles.fontSettings.sizes.small
    }
}

export default LxReactLinkText;

LxReactLinkText.propTypes = {
    originalString: PropTypes.string.isRequired,
    textStyle: PropTypes.object,
    containerStyle: PropTypes.object,
    identifiers: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            style: PropTypes.object,
            text: PropTypes.string,
            onPress: PropTypes.func,
        }),
    )
};
