'use strict';
/**
 * !!!!!!!!!!  CANNOT HANDLE THE EXPERT MODE RIGHT NOW (ID: 146486766) !!!!!!!!!!!!!!!!
 *
 * leftIcon
 * title
 * contentView  if a view-subclass is passed in, the title area will be replaced with this content view.
 * rightIcons   It will be an array of icon sources for the right side.
 *
 *
 * delegates
 *      onLeftButtonTapped()
 *      onRightButtonTapped(idx)    // idx corresponds to the pos in the rightIcons list. 0 = left most button
 */

{//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var LEFT_CNTR_CLASS = "content-bar__left",
        RIGHT_CNTR_CLASS = "content-bar__right",
        CENTER_CNTR_CLASS = "content-bar__center",
        CENTER_ITEM_CLASS = "center__item",
        ITEM_CNTR_CLASS = "content-bar__item-cntr",
        RIGHT_ICON_CLASS = "right__icon-cntr",
        OS_BAR_CLASS = "title-bar-v2__os-bar",
        CONTENT_BAR_CLASS = "title-bar-v2__content-bar";

    class LxTitleBarV2 extends GUI.View {
        //region Static
        static Template = function () {
            var getTitleBar = function getTitleBar(config, useOsPlaceholder) {
                var osBar = useOsPlaceholder ? '<div class="' + OS_BAR_CLASS + '"></div>' : '',
                    leftContent = "",
                    centerContent,
                    rightContent = "",
                    greenLine = "";

                if (config.leftIcon) {
                    leftContent = '<div class="' + LEFT_CNTR_CLASS + ' ' + ITEM_CNTR_CLASS + '">' + getIcon(config.leftIcon, "left__icon-cntr") + '</div>';
                }

                if (config.rightIcons && config.rightIcons.length > 0) {
                    rightContent = '<div class="' + RIGHT_CNTR_CLASS + ' ' + ITEM_CNTR_CLASS + '">';

                    for (var i = 0; i < config.rightIcons.length; i++) {
                        rightContent += getIcon(config.rightIcons[i], RIGHT_ICON_CLASS + " " + RIGHT_ICON_CLASS + "-" + i);
                    }
                }

                centerContent = '<div class="' + CENTER_CNTR_CLASS + ' ' + ITEM_CNTR_CLASS + '">';

                if (config.title && !config.contentView) {
                    centerContent += config.title;
                }

                centerContent += '</div>';

                if (config.greenLine) {
                    greenLine = '<div class="title-bar-v2__green-line" />';
                }

                return $('' + '<div class="title-bar-v2">' + osBar + '<div class="' + CONTENT_BAR_CLASS + '">' + leftContent + centerContent + rightContent + '</div>' + greenLine + '</div>');
            };

            var getIcon = function getIcon(icon, customClasses) {
                var classes = "item-cntr__icon-cntr " + (customClasses || "");
                return '<div class="' + classes + '">' + ImageBox.getResourceImageWithClasses(icon, "icon-cntr__icon") + '</div>';
            };

            return {
                getTitleBar: getTitleBar
            };
        }(); //endregion Static

        constructor(config) {
            if (!config.rightIcons) {
                config.rightIcons = [];
            }

            let useOsPlaceholder = PlatformComponent.getPlatformInfoObj().platform === PlatformType.IOS;
            super($(LxTitleBarV2.Template.getTitleBar(config, useOsPlaceholder)));
            this.config = config;

            if (this.config.contentView) {
                this.contentView = this.config.contentView;
            }

            this._boundLeftButtonTapped = this._leftButtonTapped.bind(this);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.elements = {
                leftCntr: this.element.find("." + LEFT_CNTR_CLASS),
                centerCntr: this.element.find("." + CENTER_CNTR_CLASS),
                rightCntr: this.element.find("." + RIGHT_CNTR_CLASS),
                rightIcons: this.element.find("." + RIGHT_ICON_CLASS)
            };

            if (this.contentView) {
                this.appendSubview(this.contentView, this.elements.centerCntr);
                this.contentView.getElement().addClass(CENTER_ITEM_CLASS);
            }

            this.buttons = {};

            if (this.config.leftIcon) {
                this.buttons.left = this._createButton(this.elements.leftCntr[0]);
            } else {
                this.buttons.left = this._createButton(this.elements.centerCntr[0]);
            }

            this.buttons.right = [];

            for (var i = 0; i < this.config.rightIcons.length; i++) {
                this.buttons.right.push(this._createButton(this.elements.rightIcons[i]));
            }
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);
            NavigationComp.registerForBackNavigation(this._boundLeftButtonTapped);
            this.buttons.left.onButtonTapped = this._boundLeftButtonTapped;

            for (var i = 0; i < this.buttons.right.length; i++) {
                this.buttons.right[i].onButtonTapped = this._rightButtonTapped.bind(this, i);
            }
        }

        viewWillDisappear() {
            this.buttons.left.onButtonTapped = null;

            for (var i = 0; i < this.buttons.right.length; i++) {
                this.buttons.right[i].onButtonTapped = null;
            }

            super.viewWillDisappear(...arguments);
        }

        _createButton(elem) {
            var btn = new GUI.LxButton(this, elem, Color.BUTTON_GLOW, null, true);
            btn.useChildsAsActiveParts("fill");
            this.addToHandledSubviews(btn);
            return btn;
        }

        _leftButtonTapped() {
            this.onLeftButtonTapped && this.onLeftButtonTapped();
        }

        _rightButtonTapped(idx) {
            this.onRightButtonTapped && this.onRightButtonTapped(idx);
        }

        /**
         * On iOS the content of the app needs to be pushed "down" in order to avoid presenting content underneath
         * the iOS system bar.
         * @returns {boolean}
         * @private
         */
        _useOsPlaceholder() {
            return PlatformComponent.getPlatformInfoObj().platform === PlatformType.IOS;
        }

    }

    GUI.LxTitleBarV2 = LxTitleBarV2;
}
