'use strict';

define([], function () {
    return {
        Limit: {
            None: 0,
            Eco: 1,
            Strand: 2,
            ParentStrand: 4,
            Pmax: 8
        }
    };
});
