'use strict';

define(["NfcCodeTouchControlEnums", "NfcCodeTouchControl"], function (NfcCodeTouchControlEnums, NfcCodeTouchControl) {
    return class NfcLearningDialog extends GUI.TableViewScreen {
        constructor(details) {
            super($("<div/>"));
            this.userUuid = details.user.uuid;
        }

        viewDidLoad() {
            super.viewDidLoad(); // Load informations about Tags and NFC-Codetouches here and return promise
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments);
            var getAllNfcCodeTouches = ActiveMSComponent.getStructureManager().getControlsByType("NfcCodeTouch");
            this.tags = [];
            this.tableContent = [{
                title: "Tags",
                rows: []
            }];

            for (var i = 0; i < getAllNfcCodeTouches.length; i++) {
                var canLearnNfc = getAllNfcCodeTouches[i].canLearnNfc();
                canLearnNfc && this.tableContent[0].rows.push(this._getRowForNfcTouch(getAllNfcCodeTouches[i]));
            }

            this.reloadTable();
        }

        _getRowForNfcTouch(getNfcCodeTouch) {
            var rowForNfcCodeTouch,
                nfcCodeTouchControl = getNfcCodeTouch;
            rowForNfcCodeTouch = {
                type: "",
                content: {
                    title: nfcCodeTouchControl.name,
                    subtitle: nfcCodeTouchControl.groupDetail,
                    disclosureIcon: true
                },
                action: function () {
                    this.ViewController.showState(NfcCodeTouchControlEnums.ScreenState.LEARN_NFCTAG, null, {
                        user: this.userUuid,
                        control: nfcCodeTouchControl
                    });
                }.bind(this)
            };
            return rowForNfcCodeTouch;
        }

        getURL() {
            return "nfcLearningDialog";
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarText() {
            return _("search.controltype.nfccodetouch");
        }

    };
});
