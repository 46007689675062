'use strict';

define(['AudioZoneShortcutHelper'], function (AudioZoneShortcutHelper) {
    class ShortcutEditScreen extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div class="shortcut-edit-screen" />'));
            this.control = details.control;
        }

        viewDidLoad() {
            var args = arguments;
            return AudioZoneShortcutHelper.getShortcuts().then(res => {
                this.allShortcuts = res;
                this.shortcutsFromMiniserver = res[this.control.uuidAction] || [];
                this.savedShortcuts = cloneObject(this.shortcutsFromMiniserver);
                return super.viewDidLoad(...args);
            });
        }

        getTableView() {
            return new GUI.EditableTableView(this.tableViewDataSource, this.tableViewDelegate);
        }

        viewWillDisappear() {
            this.tableView.setEditingModeForSection(1, false, false, false);
            this.allShortcuts[this.control.uuidAction] = this.shortcutsFromMiniserver;

            if (JSON.stringify(this.savedShortcuts) !== JSON.stringify(this.shortcutsFromMiniserver)) {
                PersistenceComponent.setShared(MediaEnum.CUSTOMIZATION_KEYS.SHORTCUTS, this.allShortcuts);
                PersistenceComponent.synchronizeShared();
            }

            super.viewWillDisappear(...arguments);
        }

        titleBarText() {
            return _('media.shortcuts.title');
        }

        getURL() {
            return "ShortcutEditScreen";
        }

        reloadTable() {
            this.tableContent = [this._getShortcutSection()];
            return super.reloadTable(...arguments).then(function () {
                return this.tableView.setEditingModeForSection(0, true, true, true);
            }.bind(this));
        }

        _getShortcutSection() {
            let shortcut, texts;
            return {
                headerTitle: "",
                headerDescription: _('media.shortcuts.header-title', {
                    username: ActiveMSComponent.getCurrentUser().name
                }),
                rows: this.shortcutsFromMiniserver.map(function (shortcut) {
                    texts = MediaServerComp.getTitleSubtitleForItem(shortcut);
                    return {
                        content: {
                            title: decodeURIComponent(texts.title),
                            subtitle: decodeURIComponent(texts.subtitle)
                        },
                        didRemoveCell: function didRemoveCell(section, row, tableView, cell) {
                            this.shortcutsFromMiniserver.splice(row, 1);
                        }.bind(this)
                    };
                }.bind(this)),
                didMoveCell: function didMoveCell(section, row, tableView, cell, oldIdx, newIdx) {
                    shortcut = this.shortcutsFromMiniserver.splice(oldIdx, 1);
                    this.shortcutsFromMiniserver.splice(newIdx, 0, shortcut[0]);
                }.bind(this)
            };
        }

    }

    Controls.AudioZoneControl.ShortcutEditScreen = ShortcutEditScreen;
    return Controls.AudioZoneControl.ShortcutEditScreen;
});
