import {getSubmenuCell} from "../../UtilityComp/utilities/helper";

export default class MailBoxControlStatesSrc {
    static getStateSections(control, states) {
        let sections = [],
            section = {},
            rows = [];

        rows.push(getSubmenuCell(_("controls.mailbox.history"), this._showHistory.bind(this, control)));

        section.rows = rows;
        sections.push(section);

        return sections;
    }

    static _showHistory(control) {
        Debug.MailBox && console.log("Show history!");
        let trackerCtrl = Object.values(control.subControls)[0];
        trackerCtrl.name = _("controls.mailbox.history");
        NavigationComp.showControlContent(trackerCtrl);
    }
}