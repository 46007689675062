import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Compass(props) {
    return (
        <Svg isreact="true"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 32 32"
            xmlSpace="preserve"
            enableBackground="new 0 0 32 32"
            {...props}
        >
            <Path d="M22 13h-5v12h-2V13h-5l6-6 6 6zm10 3c0 8.8-7.2 16-16 16S0 24.8 0 16 7.2 0 16 0s16 7.2 16 16zm-2.5 0c0-7.4-6.1-13.5-13.5-13.5S2.5 8.6 2.5 16 8.6 29.5 16 29.5 29.5 23.4 29.5 16z" />
        </Svg>
    )
}

export default Compass
