'use strict';

define(["AutopilotEnums", 'AutopilotControlCommandsScreen', 'AutopilotEventDetailsControlScreen'], function (AutopilotEnums) {
    return class AutopilotCategoryScreen extends GUI.Screen {
        constructor(details) {
            super($('<div class="autopilot-category-screen" />'));
            this.category = details.category;
            this.rooms = this.category.rooms;
            this.type = details.type;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.elements = {};
            this.tableView = new GUI.TableViewV2(this, this);
            this.appendSubview(this.tableView);
        }

        viewWillAppear() {
            this.tableView.reload();
            super.viewWillAppear(...arguments);
        }

        getURL() {
            return "Autopilot/Categories";
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarText() {
            return this.category.title;
        }

        titleBarAction() {
            this.ViewController.navigateBack();
        }

        // TABLE VIEW DELEGATES
        styleForTableView(tableView) {
            return TableViewStyle.GROUPED;
        }

        numberOfSections() {
            return this.rooms.length;
        }

        titleForHeaderInSection(section) {
            return this.rooms[section].title;
        }

        numberOfRowsInSection(section) {
            var room = this.rooms[section];
            return room.value.length;
        }

        cellTypeForCellAtIndex(section, row) {
            return GUI.TableViewV2.CellType.GENERAL;
        }

        contentForCell(cell, section, row) {
            var controls = this.rooms[section].value,
                showDisclosureIcon = !controls[row].isSecured;

            if (controls[row].isGrouped()) {
                controls[row].forEachSubControl(function (control) {
                    if (control.isSecured) {
                        showDisclosureIcon = false;
                    }
                });
            }

            return {
                title: controls[row].getName(),
                disclosureIcon: showDisclosureIcon,
                clickable: true
            };
        }

        didSelectCell(cell, section, row) {
            // show different function depending on control
            var selectedControl = this.rooms[section].value[row],
                isSecured = selectedControl.isSecured,
                title = _("autopilot.rule.action.no-secured.title"),
                message = _("autopilot.rule.action.no-secured.message"),
                counter = 0;

            if (selectedControl.isGrouped()) {
                selectedControl.forEachSubControl(function (control) {
                    if (control.isSecured) {
                        isSecured = true;
                        counter++;
                    }
                });
                message = _('autopilot.rule.action.secured.central', {
                    count: counter
                });
            }

            if (isSecured) {
                NavigationComp.showPopup({
                    title: title,
                    message: message,
                    icon: Icon.INFO,
                    buttonOk: true
                });
            } else if (this.type === AutopilotEnums.CommunicationEvents.ADD_ACTION) {
                // means we have to show the control commands screen
                this.ViewController.showState(AutopilotEnums.ScreenStates.AutopilotControlCommandsScreen, null, {
                    control: selectedControl
                });
            } else if (this.type === AutopilotEnums.CommunicationEvents.ADD_EVENT) {
                this.ViewController.showState(AutopilotEnums.ScreenStates.AutopilotEventDetailsControlScreen, null, {
                    event: {
                        controlUUID: selectedControl.uuidAction,
                        type: AutopilotEnums.EventTypes.Control,
                        editMode: false
                    }
                });
            }
        }

    };
});
