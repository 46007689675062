import globalStyles from "GlobalStyles";
import {PercentageCircle} from "../../../react-comps/CircleGraph";
import {View} from "react-native";
import {ControlContext, LxReactText} from "../../../react-comps/Components";
import {ValueUnitText} from "./wbEmLoadText";
import {useContext} from "react";
import useWallboxEmRootNodeValues from "./useWallboxEmRootNodeValues";
import useWallboxEmStatusRequest from "./useWallboxEmStatusRequest";


function WallboxManagerCircleGraphText({value, format, text, color}) {

    return <View style={styles.circleGraphText}>
        <ValueUnitText {...lxUnitConverter.convertSplitAndApply(format, value, true)} color={color}/>
        <LxReactText style={styles.label}>{text}</LxReactText>
    </View>
}

export default function WallboxManagerCircleGraph({nodeUuid = null, format = "%.2f"}) {
    const {control, states} = useContext(ControlContext);
    const node = control?.getNode(nodeUuid);

    if (node?.isRoot) {
        return <WallboxManagerCircleGraphRootNode nodeUuid={nodeUuid} format={format}/>
    } else if (node) {
        return <WallboxManagerCircleGraphGroupNode nodeUuid={nodeUuid} format={format}/>
    } else {
        return <WallboxManagerCircleGraphBase
            assigned={states?.assignedPower}
            pmax={states?.maxPower}
            fuse={false}
            peco={states?.ecoPower}
            limit={states?.maxPower}
            active={states?.currentPower > 0 || states?.limitedBy !== 0}
            format={format}
        />
    }
}

function WallboxManagerCircleGraphRootNode({nodeUuid, format}) {

    const {assigned, limitedBy, fuse} = useWallboxEmRootNodeValues(nodeUuid);

    return <WallboxManagerCircleGraphBase
        assigned={assigned}
        fuse={fuse}
        limit={fuse}
        active={assigned > 0 || limitedBy !== 0}
        format={format}
    />
}

function WallboxManagerCircleGraphGroupNode({nodeUuid, format}) {

    const {assigned, limitedBy, fuse} = useWallboxEmStatusRequest(nodeUuid);

    return <WallboxManagerCircleGraphBase
        assigned={assigned}
        limit={fuse}
        fuse={fuse}
        active={assigned > 0 || limitedBy !== 0}
        format={format}
    />
}

function WallboxManagerCircleGraphBase({
                                           assigned = 0,
                                           limit= 0,
                                           peco = false,
                                           fuse=false,
                                           pmax= false,
                                           active= false,
                                           format = "%.2f"
}) {

    const defaultColor = "#8E8E93";
    const activeColor = () => {
        return active ? globalStyles.colors.orange : defaultColor;
    }

    const getMaxValue = () => {
        return pmax === false ? fuse : pmax;
    }
    const getEcoPart = () => {
        if (peco === false) {
            return 0;
        }
        let ecoPart = Math.max(peco - assigned, 0),
            restPart = Math.max(0, getMaxValue() - assigned);
        if (ecoPart > restPart) {
            ecoPart = restPart;
        }
        return ecoPart;
    }
    const getEcoColor = () => {
        if (getEcoPart() > 0) {
            return globalStyles.colors.green;
        }
        return;
    }

    const graphValues = () => {
        let restPart = Math.max(0, getMaxValue() - assigned),
            ecoPart = getEcoPart();

        const values = [];
        if (assigned > 0) {
            values.push({ value: assigned, color: activeColor() });
        }
        if (ecoPart > 0) {
            values.push( { value: ecoPart, color: getEcoColor() });
            restPart = restPart - ecoPart;
        }
        values.push({ value: restPart, color: defaultColor });

        return values;
    }

    return <View style={styles.container}>
        <View style={styles.labels}>
            <WallboxManagerCircleGraphText value={assigned} format={format}
                                           text={_("controls.wallboxmanager.assigned-power")}
                                           color={activeColor()} />
            {peco !== false ? <WallboxManagerCircleGraphText value={getEcoPart()} format={format} text={_("controls.wallboxmanager.available-eco-power")} color={getEcoColor()} /> : null}
            {fuse !== false ? <WallboxManagerCircleGraphText value={fuse} format={format} text={_("controls.wallboxmanager.line-protection")} /> : null}
            {pmax !== false ? <WallboxManagerCircleGraphText value={pmax} format={format} text={_("controls.wallboxmanager.max-power")} /> : null}
        </View>
        <PercentageCircle values={graphValues()} />
    </View>
}

const styles = {
    container: {
        minHeight: 168,
        flexDirection: "row",
        flex: 1,
        justifyContent: "stretch",
        alignItems: "center"
    },
    labels: {
        flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "stretch"
    },
    activePower: {
        ...globalStyles.textStyles.title3.bold,
        color: globalStyles.colors.orange
    },
    label: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.primary
    },
    circleGraphText: {
        flexDirection: "column",
        marginVertical: 5
    }
}
