'use strict';

define(['AboutScreenBase'], function (AboutScreenBase) {
    return class AudioZoneControlAboutMediaServer extends AboutScreenBase {
        constructor(details) {
            super($('<div />'));
            this.control = MediaServerComp.getActiveZoneControl();
            this.mediaServer = MediaServerComp.getActiveMediaServer();
            this.serverIp = MediaServerComp.getServerIp();
            this.name = MediaServerComp.getServerName();
            this.serialNo = MediaServerComp.getServerSerialNumber();
            this.firmware = MediaServerComp.getServerFirmwareVersion();
            this.isV2 = this.serialNo.toUpperCase().replace(":", "").hasPrefix("504F94F0");
        }

        viewWillAppear() {
            return Q(super.viewWillAppear(...arguments) || true).then(function () {
                this.setTitle(this.name);
                var iconSrc = Icon.AudioZone.DEVICES.V1;

                if (this.isV2) {
                    iconSrc = Icon.AudioZone.DEVICES.V2;
                }

                this.setIcon(iconSrc);
                this.setIconColor(Color.GREEN);
            }.bind(this));
        }

        resizeIconClass() {
            var baseClass = super.resizeIconClass(...arguments);

            if (!this.isV2) {
                baseClass += " audio-zone-control-about-media-server--big-img";
            }

            return baseClass;
        }

        reloadTable() {
            this.tableContent = [{
                rows: [this._getCellWithTitleAndDetail(_("miniserver.url.local"), this.serverIp), this._getCellWithTitleAndDetail(_("miniserver.serial-number"), this.serialNo), this._getCellWithTitleAndDetail(_("miniserver.firmware"), this.firmware)]
            }, {
                rows: [{
                    content: {
                        title: _("media.preferences.services.editor.more-info"),
                        clickable: true,
                        class: "base-cell--with-chevron",
                        disclosureIcon: true
                    },
                    action: function openAdminIF() {
                        NavigationComp.openWebsite("http://" + this.serverIp);
                    }.bind(this)
                }]
            }];
            return super.reloadTable(...arguments);
        }

        _getCellWithTitleAndDetail(title, detail) {
            var rowobject = {
                type: GUI.TableViewV2.CellType.Special.COMFORT_STATE,
                content: {
                    title: title,
                    stateText: detail
                }
            };

            this._addCopyActionForDisclosureText(rowobject, title, detail);

            return rowobject;
        }

    };
});
