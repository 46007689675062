'use strict';

define("CentralWindowControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class WindowCentralControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let details = { type: GUI.TableViewV2.CellType.ROUND_COMFORT_ACTION, rowIdx: 0 };
            return [
                this.createCommand(_("close"), Commands.WINDOW_CENTRAL.CLOSE.FULL, Icon.Window.CLOSE, details),
                this.createCommand(_("open"), Commands.WINDOW_CENTRAL.OPEN.FULL, Icon.Window.OPEN, details),
                this.createCommand(_('mobiscroll.stop'), Commands.WINDOW_CENTRAL.STOP, Icon.Jalousie.STOP, details)
            ];
        }

    };
});
