import {useContext, useMemo} from "react";
import {LxReactTableView, ControlContext, AmbientContext} from "LxComponents";
import {View} from "react-native-web";
import globalStyles from "GlobalStyles";

function LxControlSubControls(props) {
    const {isAmbientMode} = useContext(AmbientContext)
    const {control, states} = useContext(ControlContext);

    const selectControl = (subControl) => {
        control.updateGroupSelection(subControl && subControl.uuidAction);
    }

    const content = useMemo(() => {
        if (!control || !control.isGrouped()) {
            return;
        }

        let section = {
                rows: []
            }, allSelected = control.isControlSelected(),
            subControls = control.getSubControls();


        // detect whether or not the controls are from the same room as the "container"-control.
        let differentRooms = false, roomCountMap = {};

        subControls.forEach(subCtrl => {
            differentRooms = differentRooms || (subCtrl.room !== control.room);
            if (roomCountMap.hasOwnProperty(subCtrl.room)) {
                roomCountMap[subCtrl.room] = roomCountMap[subCtrl.room] + 1;
            } else {
                roomCountMap[subCtrl.room] = 1;
            }
        });

        let compareOptions = {
            numeric: true,
            ignorePunctuation: true
        };

        subControls.sort((ctrlA, ctrlB) => {
            let res,
                nameCompared = ctrlA.getName().toUpperCase().localeCompare(ctrlB.getName().toUpperCase(), PlatformComponent.getLanguage(), compareOptions);

            if (ctrlA.room && ctrlB.room) {
                if (ctrlA.room === ctrlB.room) {
                    res = nameCompared
                } else {
                    res = ctrlA.getRoom().name.toUpperCase().localeCompare(ctrlB.getRoom().name.toUpperCase(), PlatformComponent.getLanguage(), compareOptions);
                }
            } else if (ctrlA.room) {
                res = -1;
            } else if (ctrlB.room) {
                res = 1;
            } else {
                res = nameCompared
            }

            return res
        })

        subControls.forEach(ctrl => {
            let isSingleSelected = !allSelected && control.isControlSelected(ctrl.uuidAction);
            let subStates = ctrl.getStates();
            let rightIconSrc = subStates.stateIcon;
            let disclosureText = subStates.stateTextShort
            let tintTextColor = subStates.stateTextColor || subStates.stateTintColor || globalStyles.colors.text.primary
            let tintIconColor = subStates.stateIconColor || subStates.stateTintColor || globalStyles.colors.text.primary;
            let cellStyle = {...(isSingleSelected ? styles.selectedCell : baseStyles.cell)}
            if (isSingleSelected && isAmbientMode) {
                cellStyle.backgroundColor = globalStyles.colors.grey["300a18"]
            }

            // when displaying ctrls from various rooms, make sure the subtitle allows to locate them.
            let subTitleObj = null;
            let title = ctrl.name;

            if (ctrl.room) {
                if (differentRooms && roomCountMap[ctrl.room] > 1) {
                    // if there is more than one control in a room different from the containers room, show as subtitle,
                    // this way the title still allows to tell them appart.
                    subTitleObj = {subtitle: ctrl.getRoom().name};

                } else if (differentRooms) {
                    // if there is only one control inside a room, use the room name as title instead of the control's name.
                    title = ctrl.getRoom().name;

                } else {
                    // if all controls are in the same room as the container, just show the control names.
                }
            }

            if (subStates.universalIsLocked) {
                disclosureText = "";
                rightIconSrc = Icon.LOCK;
                tintIconColor = globalStyles.colors.red
            }

            section.rows.push({
                type: ctrl.getCellType(true),
                content: {
                    title: title,
                    disclosureText,
                    disclosureColor: tintTextColor,
                    rightIconSrc,
                    rightIconSize: globalStyles.sizes.icons.medium,
                    rightIconColor: tintIconColor,
                    cellElementStyle: cellStyle,
                    ...subTitleObj,
                    mainRightStyle: {
                        minWidth: globalStyles.sizes.icons.medium
                    }
                },
                control: ctrl,
                states: subStates,
                action: () => selectControl(ctrl)
            })
        })
        return [section]
    }, [control, states])

    return <View style={styles.container}>
        {control && control.isGrouped() && <LxReactTableView styles={styles.tableView} tableContent={content} showSectionSeparator={true}/>}
    </View>
}

const baseStyles = {
    cell: {
        borderRadius: 4,
        paddingHorizontal: globalStyles.spacings.gaps.small
    }
}

const styles = {
    container: {
        width: "100%",
    },
    selectedCell: {
        ...baseStyles.cell,
        backgroundColor: globalStyles.colors.grey["300a36"]
    },
    subIcon: {
        height: globalStyles.sizes.icons.medium,
        width: globalStyles.sizes.icons.medium
    },
    tableView: {
        List: {
            paddingHorizontal: 0
        }
    }
}

export default LxControlSubControls;
