'use strict';

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.MEDIA_COVER = "MediaCoverCell";

    class MediaCoverCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getCellTemplate = function getCellTemplate(title, subtitle) {
                subtitle = subtitle || "";
                return '<div class="cell-container">' + '<div class="cell-container__icon cell-container__icon--with-local-svg">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.NOTE, 'cover-icon') + '<div class="icon__play-button">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.PLAY_FULL_B, 'play-icon') + '</div>' + '</div>' + '<div class="cell-container__title text-overflow-ellipsis">' + title + '</div>' + '<div class="cell-container__subtitle text-overflow-ellipsis">' + subtitle + '</div>' + '</div>';
            };

            return {
                getCellTemplate: getCellTemplate
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("media-cover-cell");
        }

        viewDidLoad() {
            var promise = super.viewDidLoad(...arguments);
            this.elements = {};
            this.contentPlaceholder.append(MediaCoverCell.Template.getCellTemplate(this.content.title, this.content.subtitle));

            if (this.content.hidePlayButton) {
                this.element.find('.icon__play-button').remove();
            } else if (!this.content.buttonSameFunctionAsBackground) {
                this.playButton = new GUI.LxButton(this, this.element.find('.icon__play-button')[0], Color.BUTTON_GLOW);
                this.addToHandledSubviews(this.playButton);
                this.item = this.content.item;
                this.username = this.content.username;
            }

            return Q.all([promise, ImageBox.getImageElement(this.content.iconSrc, 'loaded-cover-icon').then(function (res) {
                var coverIcon, isLocalSvg;

                if (this.element) {
                    coverIcon = this.element.find('.cover-icon');
                    isLocalSvg = res[0].outerHTML.hasPrefix("<svg") && this.content.iconSrc.hasPrefix("resources/Images");
                    return GUI.animationHandler.schedule(function () {
                        coverIcon.html(res[0]);

                        if (isLocalSvg) {
                            // Check if it is a local SVG
                            coverIcon.parent().addClass("cell-container__icon--with-local-svg");
                        } else {
                            coverIcon.parent().removeClass("cell-container__icon--with-local-svg");
                        }
                    });
                }
            }.bind(this))]);
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments);

            if (this.playButton) {
                this.playButton.onButtonTapped = this._handlePlayButtonTapped.bind(this);
            }
        }

        _handlePlayButtonTapped() {
            if (this.content.details) {
                this.item.config = {
                    username: this.username,
                    serviceIdentifier: this.content.details.identifier,
                    mediaType: this.content.details.mediaType,
                    lastSelectedItem: this.content.details.lastSelectedItem
                };
                MediaServerComp.sendPlayerCommandFromType(this.item, this.rowIdx, MediaServerComp.getCellConfigWithServiceItem(this.item));
            } else {
                MediaServerComp.sendPlayerCommandFromType(this.item, 0, MediaServerComp.getCellConfigWithServiceItem(this.item));
            }
        }

        _hammer() {
            var hammerObj = super._hammer(...arguments);

            this.clickableContainer.css("touch-action", "auto");
            return hammerObj;
        }

    }

    GUI.TableViewV2.Cells.MediaCoverCell = MediaCoverCell;
    return GUI;
}(window.GUI || {});
