import {LxReactSelectorScreen, LxReactFlexibleCell, useEntryPoint} from "LxComponents";
import {useCallback, useEffect, useMemo} from "react";
import {useBackNavigation} from "../../Components";

export default function AmbientDefaultLocationSelector(props) {
    const currDefaultLoc = useEntryPoint();

    useBackNavigation(() => {
        props.navigation.goBack();
    }, [])

    useEffect(() => {
        props.navigation.setOptions({
            title: _("entry-point.name")
        })
    }, [])

    /**
     *
     * @param locObject {name, location, [content]}
     */
    const createRowForLocation = ({name, description, location, content}) => {
        return {
            title: name,
            ...(description ? { subTitle: description } : {}),
            ...(location ? { id: location } : {}),
            ...(content ? { options: content.map(createRowForLocation) } : {})
        }
    }

    const options = useMemo(() => {

        const dataSet = EntryPointHelper.getLocationSources(true, true);
        let options = dataSet.predefined.content.filter(loc => {
            let available;
            switch (loc.location) {
                case UrlStartLocation.AMBIENT_MODE:
                    available = false;
                    break;
                default:
                    available = true;
                    break;
            }
            return available;
        }).map(createRowForLocation);
        options.push(createRowForLocation(dataSet.rooms));
        options.push(createRowForLocation(dataSet.cats));

        return options;
    }, []);

    const findOptionById = (id, lookupOptions) => {
        let found = null;
        lookupOptions.some(option => {
            if (option.id === id) {
                found = option;
            } else if (Array.isArray(option.options)) {
                found = findOptionById(id, option.options);
            }
            return found !== null;
        });
        return found;
    }

    const handleLocationSelected = (id) => {
        let foundOption = findOptionById(id, options);
        if (foundOption) {
            let locObj = {
                name: foundOption.title,
                ...(foundOption.subTitle ? {description: foundOption.subTitle} : {}),
                location: id
            };
            Debug.AmbientMode && console.log(AmbientDefaultLocationSelector.name, "handleLocationSelected: " + id, locObj);
            EntryPointHelper.setEntryPointLocation(locObj.location);
        }
    };

    const meetsFilter = (text, filterText) => {
        return text && text.toLowerCase().includes(filterText.toLowerCase());
    }
    const createOption = (option) => {
        return {
            uniqueId: getRandomIntInclusive(0,9999999),
            title: option.title,
            subTitle: option.subTitle,
            searchTags: option.searchTags,
            checked: currDefaultLoc.location === option.id,
            radioMode: LxReactFlexibleCell.RadioMode.Inactive,
            onCheckedToggled: checked => {
                return Q.resolve(true)
            },
            onCheckedChange: (checked, dueToRadioMode = false) => {
                handleLocationSelected(option.id);
                closeAllSelectorScreens();
            }
        }
    }

    const getCustomFilteredContent = useCallback((filterText) => {
        const content = [];
        const topOptions = options.filter(option => meetsFilter(option.title, filterText));
        const allRoomOptions = options.filter(option => UrlStartLocation.ROOM === option.id).flatMap(option => option.options)
        const filteredRoomOptions = allRoomOptions.filter(option => meetsFilter(option.title, filterText));
        const categoryOptions = options.filter(option => UrlStartLocation.CATEGORY === option.id).flatMap(option => option.options).filter(option => meetsFilter(option.title, filterText));
        const controlOptions = allRoomOptions.flatMap(option => {
            let controls = option.options;
            controls.forEach(control => control.searchTags = option.title)
            return controls
        }).unique().filter(option => meetsFilter(option.title, filterText) || meetsFilter(option.subTitle, filterText) || meetsFilter(option.searchTags, filterText));

        topOptions.length && content.push({
            rows: topOptions.map(createOption)
        })

        filteredRoomOptions.length && content.push({
            headerStrongTitle: _("rooms"),
            rows: filteredRoomOptions.map(createOption)
        })

        categoryOptions.length && content.push({
            headerStrongTitle: _("categories"),
            rows: categoryOptions.map(createOption)
        })

        controlOptions.length && content.push({
            headerStrongTitle: _("functions"),
            rows: controlOptions.map(createOption)
        })

        return content;
    }, [currDefaultLoc])

    const closeAllSelectorScreens = () => {
        const routes = props.navigation.getState().routes;
        let selectorRoutesCount = 0;
        for (let i = routes.length-1; i >= 0; i--) {
            if ([LxReactSelectorScreen.name, AmbientDefaultLocationSelector.name].includes(routes[i].name)) {
                selectorRoutesCount++;
            } else {
                break;
            }
        }
        if (selectorRoutesCount > 1) {
            props.navigation.pop(selectorRoutesCount);
        } else {
            props.navigation.goBack();
        }
    }

    return <LxReactSelectorScreen
        {...props}
        options={options}
        selectedId={currDefaultLoc ? currDefaultLoc.location : null}
        autoClose={true}
        title={ _("entry-point.name")}
        animationType={AnimationType.FADE}
        onSelected={handleLocationSelected}
        getCustomFilteredContent={getCustomFilteredContent}
        filterTitle={_("search.title")}
    />
}
