import * as React from "react"
import Svg, { Path } from "react-native-svg"

function VolPlus(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path d="M9 19.826a1 1 0 01-1.65.76L2.666 16H1a1 1 0 01-1-1V9a1 1 0 011-1h1.667l4.682-4.585A1 1 0 019 4.175v15.65zM12 12a1 1 0 011-1h4V7a1 1 0 112 0v4h4a1 1 0 110 2h-4v4a1 1 0 11-2 0v-4h-4a1 1 0 01-1-1z" />
        </Svg>
    )
}

export default VolPlus
