'use strict';
/*
This cell contains an EditableTableView which enables us to sort AutomaticDesigner Actions or Events within its group
 */


GUI.TableViewV2.CellType.Special.AUTOMATIC_DESIGNER_CONTAINER_CELL = "AutomaticDesignerContainerCell";
{//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    let AutomaticDesignerTableContentHelper = require("AutomaticDesignerTableContentHelper");

    class AutomaticDesignerContainerCell extends GUI.TableViewV2.Cells.BaseCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        viewDidLoad() {
            var baseCall = super.viewDidLoad(...arguments);
            this._isDragging = false;
            this._containerTableViewDataSource = tableViewDataSource(null, null, this);
            this._containerTableViewDelegate = tableViewDelegate(null, this);
            this._containerTableView = new GUI.EditableTableView(this._containerTableViewDataSource, this._containerTableViewDelegate, null, 0, this.content.draggable, false, this.content.draggable);
            return this.appendSubview(this._containerTableView, this.contentPlaceholder).then(function () {
                return baseCall.then(function () {
                    return this.updateContent(this.content);
                }.bind(this));
            }.bind(this));
        }

        // ---------------------------------------------------------------------------------------------------------
        //   Methods for super fast tableContent updates
        // ---------------------------------------------------------------------------------------------------------
        // Don't reuse this cell! --> BG-I13401
        // It may won't be updated!

        /*cellTypeForReuse: function cellTypeForReuse() {
                    return GUI.TableViewV2.CellType.Special.AUTOMATIC_DESIGNER_CONTAINER_CELL;
                },*/
        updateContent(newContent) {
            return super._updateContent(...arguments);
        }

        /**
         * This Method is used to apply a new content object onto the cell instance. e.g. update texts and so on.
         * @param newContent
         * @private
         */
        _applyNewContent(newContent) {
            var basePrms = super._applyNewContent(...arguments);

            this.currentEditRule = AutomaticDesignerComponent.getCurrentEditRule();

            this._containerTableView.getElement().attr("groupIdx", this.content.groupIdx);

            this._containerTableView.setEditingModeForSection(0, this.content.draggable, false, this.content.draggable);

            return Q.all([basePrms, this.reloadTable()]);
        }

        reloadTable() {
            var needsReload = false;

            var tableContent = this._getTableContent();

            needsReload |= this._containerTableViewDataSource.update(tableContent);
            needsReload |= this._containerTableViewDelegate.update(tableContent);

            if (needsReload) {
                return this._containerTableView.reload();
            } else {
                return Q.resolve();
            }
        }

        getSortableOptions() {
            var emptyEventGroupsIdxArr = [];
            return {
                draggable: '.section__cell',
                scroll: true,
                scrollSensitivity: 100,
                scrollSpeed: 10,
                delay: 100,
                // time in milliseconds to define when the sorting should start
                delayOnTouchOnly: true,
                // only delay if user is using touch
                touchStartThreshold: 10,
                swapThreshold: 1,
                animation: 150,
                group: this.content.type,
                // Allows us to drag between groups of the same type (events in particular)
                groupIdx: this.content.groupIdx,
                ghostClass: "sortable-ghost__custom",
                onStart: function (evt) {
                    var cell = this._containerTableView.table[0][evt.oldIndex];

                    if (cell && cell.setDragging) {
                        cell.setDragging(true);
                    }
                }.bind(this),
                onEnd: function (evt) {
                    var fromTableViewElm = $(evt.from.parentNode.parentNode),
                        toTableViewElm = $(evt.to.parentNode.parentNode),
                        srcGroupIdx = fromTableViewElm.attr("groupIdx"),
                        dstGroupIdx = toTableViewElm.attr("groupIdx"),
                        capabilityToMove;

                    if (this.content.type === AutomaticDesignerEnums.SCREEN_TYPES.EVENTS) {
                        if (srcGroupIdx !== dstGroupIdx) {
                            capabilityToMove = this.currentEditRule.eventGroups[srcGroupIdx].events.splice(evt.oldIndex, 1)[0];
                            this.currentEditRule.eventGroups[dstGroupIdx].events.splice(evt.newIndex, 0, capabilityToMove);
                            this.currentEditRule.eventGroups.forEach(function (eventGroup, idx) {
                                if (!eventGroup.events.length) {
                                    emptyEventGroupsIdxArr.push(idx);
                                }
                            }.bind(this));
                            emptyEventGroupsIdxArr.forEach(function (eventGroupIdx) {
                                this.currentEditRule.eventGroups.splice(eventGroupIdx, 1);
                            }.bind(this));
                        } else {
                            if (evt.oldIndex !== evt.newIndex) {
                                this.currentEditRule.eventGroups[dstGroupIdx].events.move(evt.oldIndex, evt.newIndex);
                            }
                        }
                    } else {
                        if (evt.oldIndex !== evt.newIndex) {
                            this.currentEditRule.actions.move(evt.oldIndex, evt.newIndex);
                        }
                    } // always update the dataset in the comp --> reload in parent fetches the dataset from the comp


                    AutomaticDesignerComponent.setCurrentRuleToEdit(this.currentEditRule);
                    this.delegate.onGroupChange && this.delegate.onGroupChange.call(this.delegate);
                    var cell = this._containerTableView.table[0][evt.oldIndex];

                    if (cell && cell.setDragging) {
                        cell.setDragging(false);
                    }
                }.bind(this)
            };
        }

        capabilityValidityChange(valid, id) {
            this.delegate.validityDidChange && this.delegate.validityDidChange.call(this.delegate, this.sectionIdx, this.rowIdx, this._containerTableView, this, valid, id);
        }

        capabilityRemoved(id) {
            this.delegate.didRemoveCapability && this.delegate.didRemoveCapability.call(this.delegate, this.sectionIdx, this.rowIdx, this._containerTableView, this, id);
            this.delegate.onGroupChange && this.delegate.onGroupChange.call(this.delegate);
        }

        _getTableContent() {
            if (this.content.type === AutomaticDesignerEnums.SCREEN_TYPES.EVENTS) {
                return [{
                    rows: this._getEventRows(new AutomaticDesignerTableContentHelper(this, this.content.type, this.content.type))
                }];
            } else {
                return [{
                    rows: this._getActionRows(new AutomaticDesignerTableContentHelper(this, this.content.type, this.content.type))
                }];
            }
        }

        _getEventRows(tableContentHelper) {
            var knownTypes = AutomaticDesignerEnums.KNOWN_TYPES.EVENTS,
                lastSelectedEntry;
            return this.content.capabilities.map(function (capability, capabilityIdx) {
                var cellContent = {
                    groupIdx: this.content.groupIdx,
                    entryIdx: capabilityIdx,
                    descID: capability.descID,
                    editMode: true,
                    isEvent: true
                };
                lastSelectedEntry = null; // Null it again we are in an iteration!

                if (knownTypes.FUNCTIONS.INT.includes(capability.type)) {
                    lastSelectedEntry = ActiveMSComponent.getControlByUUID(capability.controlUUID || capability.id);
                } else if (knownTypes.WEATHER.INT.includes(capability.type)) {
                    lastSelectedEntry = ActiveMSComponent.getStructureManager().getWeatherFieldTypes(capability.id);
                } else if (knownTypes.OPERATING_MODES.INT.includes(capability.type)) {
                    lastSelectedEntry = {
                        name: ActiveMSComponent.getStructureManager().getOperatingModes(capability.id),
                        id: capability.id
                    };
                }

                if (!lastSelectedEntry) {
                    lastSelectedEntry = {};
                }

                lastSelectedEntry._apType = capability.type;
                return tableContentHelper.createDetailedCellForRow(cellContent, lastSelectedEntry, true);
            }.bind(this));
        }

        _getActionRows(tableContentHelper) {
            var knownTypes = AutomaticDesignerEnums.KNOWN_TYPES.ACTIONS,
                lastSelectedEntry;
            return this.content.capabilities.map(function (capability, idx) {
                var cellContent = {
                    // Don't go for descID. The descID isn't an uid. So if there are more then one entry from the same type
                    // the old findIndex function always returned the first entry .
                    // All entries from the same type showed the same values.
                    entryIdx: idx,
                    descID: capability.descID,
                    recorded: capability.recorded,
                    editMode: true
                };
                lastSelectedEntry = null; // Null it again we are in an iteration!

                if (knownTypes.FUNCTIONS.INT.includes(capability.type) || knownTypes.SCENES.INT.includes(capability.type)) {
                    lastSelectedEntry = ActiveMSComponent.getControlByUUID(capability.id);
                } else if (knownTypes.OPERATING_MODES.INT.includes(capability.type)) {
                    lastSelectedEntry = {
                        name: ActiveMSComponent.getStructureManager().getOperatingModes(capability.id),
                        id: capability.id
                    };
                }

                if (!lastSelectedEntry) {
                    lastSelectedEntry = {};
                }

                lastSelectedEntry._apType = capability.type;
                return tableContentHelper.createDetailedCellForRow(cellContent, lastSelectedEntry);
            }.bind(this));
        }

    }

    GUI.TableViewV2.Cells.AutomaticDesignerContainerCell = AutomaticDesignerContainerCell;
}
