'use strict';
/*
 title
 value
 step
 color

 [minValue] default is 0,
 [maxValue] default is 100,
 [format] default is <v.0> %

 onSwitchChanged(value, section, row, tableView)
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.SLIDER_SWITCH = "SliderSwitchCell";

    class SliderSwitchCell extends GUI.TableViewV2.Cells.SliderCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("slider-switch-cell");
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                var labelPlaceholder = this.contentPlaceholder.find(".label-placeholder").remove();
                var newPlaceholder = $('<div class="content__label-switch-placeholder"></div>');
                newPlaceholder.append(labelPlaceholder);
                newPlaceholder.append('<div class="switch-placeholder"></div>');
                this.contentPlaceholder.prepend(newPlaceholder);
                this.switch = new GUI.LxSwitch(this, this.element.find(".switch-placeholder")[0], window.Styles.colors.stateActive);
                this.content.minValue = this.content.minValue || 0;
                this.content.maxValue = this.content.maxValue || 100;
                this.content.format = this.content.format || "<v.0> %";
                this.switch.setActive(this.content.value > this.content.minValue);
            }.bind(this));
        }

        viewDidAppear() {
            var baseCall = super.viewDidAppear(...arguments);
            this.switch.onStateChanged = this._onSwitchChanged.bind(this);
            return baseCall;
        }

        viewDidDisappear() {
            this.switch.onStateChanged = null;
            return super.viewDidDisappear(...arguments);
        }

        destroy() {
            this.switch.destroy();
            this.switch = null;
            return super.destroy(...arguments);
        }

        // Public methods
        setPosition(value) {
            super.setPosition(...arguments);
            this.switch && this.switch.setActive(value > this.content.minValue);
        }

        // Private methods
        _onSwitchChanged(a) {
            this.slider.resetAntiGhost();
            this.delegate.onSwitchChanged && this.delegate.onSwitchChanged.call(this.delegate, a, this.sectionIdx, this.rowIdx, this.tableView);
        }

    }

    GUI.TableViewV2.Cells.SliderSwitchCell = SliderSwitchCell;
    return GUI;
}(window.GUI || {});
