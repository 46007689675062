import globalStyles from "GlobalStyles";

import React, { useMemo, useCallback, useState, Fragment, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';

import Svg, { Circle, Line, Path } from "react-native-svg";
import { LxReactText } from "LxComponents"

import EnergyIcon from './EnergyIcon';

const SvgArrow = ({ col = globalStyles.colors.green, up = true }) => <Svg width="13" height="17" style={[{ width: 13, height: 17, top: 2, marginRight: 6, position: 'relative' }, up === true ? {} : { transform: [{ rotate: '180deg' }] }]} viewBox="0 0 13 17" fill="none"><Path fill={col} d="M7.38388 1.07315C6.89573 0.584992 6.10427 0.584992 5.61612 1.07315L0.46967 6.21959C0.176777 6.51249 0.176777 6.98736 0.46967 7.28025C0.762563 7.57315 1.23744 7.57315 1.53033 7.28025L5.75 3.06058V15.4999C5.75 15.9141 6.08579 16.2499 6.5 16.2499C6.91421 16.2499 7.25 15.9141 7.25 15.4999V3.06058L11.4697 7.28025C11.7626 7.57315 12.2374 7.57315 12.5303 7.28025C12.8232 6.98736 12.8232 6.51249 12.5303 6.21959L7.38388 1.07315Z" /></Svg>;

const nodeWidth = 72;
const extraLabelSpace = 25;
const extraVerticalLabelSpace = 70;

/* #region  Utility Functions */
const rotate = ({ cx, cy, x, y, degrees }) => {
    var radians = (Math.PI / 180) * degrees,
        cos = Math.cos(radians),
        sin = Math.sin(radians),
        nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
        ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
    return { x: nx, y: ny };
}

const getLineLength = ({ availableWidth, numNodes }) => {
    if (numNodes === 2 || numNodes === 4) {
        return (availableWidth / 2) - (nodeWidth / 2);
    } else if (numNodes === 3) {
        const lowerRightAngle = 60; // 180 - ((360 / 3) - 90) - 90
        const upperTriangleWidth = (availableWidth / 2) - (nodeWidth / 2);
        return upperTriangleWidth * Math.sin(90 * Math.PI / 180) / Math.sin(lowerRightAngle * Math.PI / 180);
    } else {
        const leftLowerAngle = 90 - (360 / numNodes);
        const lowerTriangleSide = (availableWidth / 2) - (nodeWidth / 2);
        const remainingAngle = 180 - 90 - leftLowerAngle;
        return lowerTriangleSide * Math.sin(90 * Math.PI / 180) / Math.sin(remainingAngle * Math.PI / 180);
    }
}

const getSpiderHeight = ({ lineLength, numNodes }) => {
    if (numNodes === 2 || numNodes === 4 || numNodes === 6) {
        return {
            spiderHeight: lineLength * 2 + nodeWidth + (extraVerticalLabelSpace * 2),
            spiderOffset: 0,
        };
    } else if (numNodes === 3 || numNodes === 5) {
        const upperLeftAngle = numNodes === 3 ? 30 : 54; // (360 / 3) - 90 || ((360 / 5) * 2) - 90
        const triangleHeight = lineLength * Math.sin(upperLeftAngle * Math.PI / 180) / Math.sin(90 * Math.PI / 180);
        return {
            spiderHeight: lineLength + triangleHeight + nodeWidth + (extraVerticalLabelSpace * 2),
            spiderOffset: (lineLength - triangleHeight) / 2,
        };
    }
}
/* #endregion */

const Label = ({ node, labelIsAbove = false, forListView }) => {
    let formattedTexts = lxUnitConverter.convertSplitAndApply(node.format, node.value);
    let formattedSecondTexts = false;
    if (typeof node.secondValue === 'number') {
        formattedSecondTexts = lxUnitConverter.convertSplitAndApply(node.secondFormat || node.format, node.secondValue);
    }

    if (forListView) {
        return (
            <View style={styles.columnViewHorizontal}>
                <Text style={[styles.energyText, { textAlign: 'left' }]}>{node.topArrow ? <SvgArrow {...node.topArrow} /> : null}{formattedTexts.valueTxt} {formattedTexts.succTxt}</Text>
                {
                    formattedSecondTexts ? (
                        <Text style={[styles.energyText, { textAlign: 'left' }]}>{node.bottomArrow ? <SvgArrow {...node.bottomArrow} /> : null}{formattedSecondTexts.valueTxt} {formattedSecondTexts.succTxt}</Text>
                    ) : null
                }
                <Text style={[styles.energyText, styles.subEnergyText, { textAlign: 'left' }]}>{node.name}</Text>
            </View>
        )
    }

    return labelIsAbove ? (
        <View style={styles.label}>
            {
                formattedSecondTexts ? (
                    <Text style={styles.energyText}>{node.bottomArrow ? <SvgArrow {...node.bottomArrow} /> : null}{formattedSecondTexts.valueTxt} {formattedSecondTexts.succTxt}</Text>
                ) : null
            }
            <Text style={styles.energyText}>{node.topArrow ? <SvgArrow {...node.topArrow} /> : null}{formattedTexts.valueTxt} {formattedTexts.succTxt}</Text>
            <Text style={[styles.energyText, styles.subEnergyText]}>{node.name}</Text>
        </View>
    ) : (
        <View style={styles.label}>
            <Text style={[styles.energyText, styles.subEnergyText]}>{node.name}</Text>
            <Text style={styles.energyText}>{node.topArrow ? <SvgArrow {...node.topArrow} /> : null}{formattedTexts.valueTxt} {formattedTexts.succTxt}</Text>
            {
                formattedSecondTexts ? (
                    <Text style={styles.energyText}>{node.bottomArrow ? <SvgArrow {...node.bottomArrow} /> : null}{formattedSecondTexts.valueTxt} {formattedSecondTexts.succTxt}</Text>
                ) : null
            }
        </View>
    )
}

const ListNode = ({ node, longPressedNodeIndex, setLongPressedNodeIndex, index, topLevel }) => {
    const hasContextMenu = Array.isArray(node.contextMenu) && node.contextMenu.length > 0;
    const flowIsPositive = node.positiveDirection < 0 && node.flow < 0 || node.positiveDirection > 0 && node.flow > 0;
    const headingStyle = useMemo(() => {
        let style = {};

        if (node.isHeading) {
            style = {
                marginLeft: (nodeWidth / -2) + 7,
            }
        } else {
            style = {
                marginLeft: 7,
            }
        }

        return style;
    }, [node.isHeading]);
    return (
        <View style={{ ...headingStyle, flexDirection: 'row', alignItems: 'center', paddingTop: 18, paddingBottom: 18, zIndex: hasContextMenu && longPressedNodeIndex === index ? 1 : undefined, }}>
            <EnergyIcon
                icon={node.icon}
                onClick={longPressedNodeIndex === -1 ? node.onClick : () => setLongPressedNodeIndex(-1)}
                hasChildren={node.hasChildren}
                groupHeading={node.isHeading}
                positivity={node.flow === 0 ? 0 : flowIsPositive ? 1 : -1}
                style={{ marginRight: 8 }}
                onLongPress={hasContextMenu ? () => setLongPressedNodeIndex(index) : undefined}
            />
            <Label node={node} forListView={true} />
            {
                longPressedNodeIndex === index && hasContextMenu ? (
                    <View style={{
                        backgroundColor: '#202021',
                        position: 'absolute',
                        top: nodeWidth + 5,
                        left: 0,
                        borderRadius: 12,
                    }}>
                        {
                            node.contextMenu.map((menuItem, idx) => (
                                <TouchableOpacity
                                    key={`context-menu-item-${index}-${idx}`}
                                    onPress={() => { menuItem.onClick(); setLongPressedNodeIndex(-1) }}
                                    style={{
                                        paddingTop: 11, paddingBottom: 11,
                                        paddingLeft: 16, paddingRight: 16,
                                        borderBottomColor: 'rgba(84, 84, 88, 0.65)',
                                        borderBottomWidth: idx !== node.contextMenu.length - 1 ? 1 : 0,
                                    }}
                                >
                                    <LxReactText style={{
                                        whiteSpace: 'nowrap',
                                        fontFamily: globalStyles.fontSettings.families.regular,
                                        color: 'white',
                                        fontSize: globalStyles.fontSettings.sizes.medium,
                                        maxWidth: 200,
                                    }}
                                    numberOfLines={1}
                                    >{menuItem.label}</LxReactText>
                                </TouchableOpacity>
                            ))
                        }
                    </View>
                ) : null
            }
        </View>
    )
}

const SpiderNode = ({ node, index, centerPoint, width, longPressedNodeIndex, setLongPressedNodeIndex, labelAboveIndices }) => {
    const labelIsAbove = labelAboveIndices.indexOf(index) > -1;
    const flowIsPositive = node.positiveDirection < 0 && node.flow < 0 || node.positiveDirection > 0 && node.flow > 0;

    const hasContextMenu = Array.isArray(node.contextMenu) && node.contextMenu.length > 0;
    const isLeftOfCenter = centerPoint.x < width / 2;
    const isRightOfCenter = centerPoint.x > width / 2;

    return (
        <Fragment>
            <View style={{
                position: 'absolute',
                width: nodeWidth + (extraLabelSpace * 2),
                left: centerPoint.x - nodeWidth / 2 - extraLabelSpace,
                top: centerPoint.y - nodeWidth / 2 - (labelIsAbove ? extraVerticalLabelSpace : 0),
                zIndex: hasContextMenu && longPressedNodeIndex === index ? 1 : undefined,
            }}>
                {labelIsAbove ? <Label node={node} labelIsAbove={labelIsAbove} /> : null}
                <View style={{
                    marginLeft: extraLabelSpace,
                }}>
                    <EnergyIcon
                        icon={node.icon}
                        onClick={longPressedNodeIndex === -1 ? node.onClick : () => setLongPressedNodeIndex(-1)}
                        hasChildren={node.hasChildren}
                        positivity={node.flow === 0 ? 0 : flowIsPositive ? 1 : -1}
                        onLongPress={hasContextMenu ? () => setLongPressedNodeIndex(index) : undefined}
                    />
                </View>
                {!labelIsAbove ? <Label node={node} labelIsAbove={labelIsAbove} /> : null}
                {
                    longPressedNodeIndex === index && hasContextMenu ? (
                        <View style={{
                            backgroundColor: '#202021',
                            position: 'absolute',
                            top: labelIsAbove ? nodeWidth + extraVerticalLabelSpace + 5 : undefined,
                            bottom: !labelIsAbove ? nodeWidth + extraVerticalLabelSpace + 5 : undefined,
                            left: isLeftOfCenter ? -extraLabelSpace : isRightOfCenter ? undefined : '50%',
                            right: isRightOfCenter ? -extraLabelSpace : undefined,
                            transform: !isLeftOfCenter && !isRightOfCenter ? [{ translate: '-50%' }] : undefined,
                            borderRadius: 12,
                        }}>
                            {
                                node.contextMenu.map((menuItem, idx) => (
                                    <TouchableOpacity
                                        key={`spider-node-contextmenu-${idx}`}
                                        onPress={() => { menuItem.onClick(); setLongPressedNodeIndex(-1) }}
                                        style={{
                                            paddingTop: 11, paddingBottom: 11,
                                            paddingLeft: 16, paddingRight: 16,
                                            borderBottomColor: 'rgba(84, 84, 88, 0.65)',
                                            borderBottomWidth: idx !== node.contextMenu.length - 1 ? 1 : 0,
                                        }}
                                    >
                                        <LxReactText style={{
                                            whiteSpace: 'nowrap',
                                            fontFamily: globalStyles.fontSettings.families.regular,
                                            color: 'white',
                                            fontSize: globalStyles.fontSettings.sizes.medium,
                                            maxWidth: 200,
                                        }}
                                        numberOfLines={1}
                                        >{menuItem.label}</LxReactText>
                                    </TouchableOpacity>
                                ))
                            }
                        </View>
                    ) : null
                }
            </View>
        </Fragment>
    )
}

export default ({ nodes, viewType, topLevel, backgroundClick }) => {
    const [width, setWidth] = useState(333)
    const [height, setHeight] = useState(0)
    const [centerAdjustment, setCenterAdjustment] = useState(0)
    const [longPressedNodeIndex, setLongPressedNodeIndex] = useState(-1)
    const onLayout = useCallback(({ nativeEvent: { layout: { width } } }) => { setWidth(width) }, []);

    let asSpider = (nodes.length >= 2 && nodes.length <= 6);
    if (topLevel === false) { asSpider = false; }

    useEffect(() => setLongPressedNodeIndex(-1), [viewType, backgroundClick]);

    const { htmlTags, svgTags } = useMemo(() => {
        if (!Array.isArray(nodes) || nodes.length === 0 || !width || width <= 0) {
            return { htmlTags: [], svgTags: [] };
        }         
        
        const clonedNodes = cloneObjectDeep(nodes);

        if (!asSpider) {
            return {
                htmlTags: nodes.map((node, index) => {

                    // add room name to nodes with the same name
                    let nodesWithSameName = clonedNodes.filter((n) => n.name === node.name);
                    if (nodesWithSameName.length > 1 && node.roomName) {
                        node.name = node.name + SEPARATOR_SYMBOL + node.roomName;
                    }

                    return (
                        <ListNode
                            key={`spider-list-node-${index}`}
                            node={node}
                            longPressedNodeIndex={longPressedNodeIndex}
                            setLongPressedNodeIndex={setLongPressedNodeIndex}
                            index={index}
                            topLevel={topLevel}
                        />
                    )
                }),
                svgTags: [],
            }
        } else {
            const numNodes = nodes.length;
            const degrees = 360 / numNodes;
            // First node always top center
            // Rotate second node around first, gives 
            // reducing available width, to get space left and right for label overlap
            const lineLength = getLineLength({ availableWidth: width - (extraLabelSpace * 2), numNodes });
            const { spiderHeight, spiderOffset } = getSpiderHeight({ lineLength, numNodes });
            setHeight(spiderHeight);
            setCenterAdjustment(spiderOffset);
            const flowCenterX = width / 2;
            const flowCenterY = spiderHeight / 2 + spiderOffset;
            let centerPoint = { x: flowCenterX, y: flowCenterY - lineLength }
            let lineEndPoint = { x: flowCenterX, y: flowCenterY - (lineLength - (72 / 2)) }
            let labelAboveIndices = [0, 1, numNodes - 1];
            if (numNodes === 3 || numNodes === 2) { labelAboveIndices = [0] }
            const htmlTags = [];
            const svgTags = [];
            nodes.forEach((node, index) => {
                const flowIsPositive = node.positiveDirection < 0 && node.flow < 0 || node.positiveDirection > 0 && node.flow > 0;
                const inward = node.flow > 0;

                htmlTags.push(<SpiderNode
                    key={`spider-node-${index}`}
                    node={node}
                    index={index}
                    centerPoint={centerPoint}
                    width={width}
                    longPressedNodeIndex={longPressedNodeIndex}
                    setLongPressedNodeIndex={setLongPressedNodeIndex}
                    labelAboveIndices={labelAboveIndices}
                />);

                let individualLineEndPoint = { ...lineEndPoint };

                // lines towards group-nodes are shorter, so they aren't visible underneath the node in the ambientMode.
                if (index >= 4 && node.hasChildren) {
                    // rotate it back to a vertical line, shorten it by ~10px and then rotate it back
                    const totalDegrees = index * -60;
                    individualLineEndPoint = rotate({ ...individualLineEndPoint, cx: flowCenterX, cy: flowCenterY, degrees: totalDegrees });
                    individualLineEndPoint.y = individualLineEndPoint.y - 10;
                    individualLineEndPoint = rotate({ ...individualLineEndPoint, cx: flowCenterX, cy: flowCenterY, degrees: -totalDegrees });
                }

                svgTags.push((
                    <Line
                        key={`spider-line-${index}`}
                        x1={flowCenterX}
                        y1={flowCenterY}
                        x2={individualLineEndPoint.x}
                        y2={individualLineEndPoint.y}
                        stroke="rgba(142, 142, 147, 1)"
                        strokeWidth="2"
                    />
                ));
                if (node.flow !== 0 && node.showFlowAnimation) {
                    svgTags.push((
                        <Line
                            key={`spider-line-${index}-animated`}
                            x1={flowCenterX}
                            y1={flowCenterY}
                            x2={individualLineEndPoint.x}
                            y2={individualLineEndPoint.y}
                            stroke={!flowIsPositive ? globalStyles.colors.orange : globalStyles.colors.green}
                            strokeWidth="7"
                            strokeDasharray={`0 ${lineLength}`}
                            strokeLinecap="round"
                        >
                            <animate
                                attributeName="stroke-dashoffset"
                                values={inward ? `0;${lineLength}` : `${lineLength};0`}
                                dur={`${1.5 + (0.1 * index)}s`}
                                repeatCount="indefinite"
                            />
                        </Line>
                    ));
                }

                centerPoint = rotate({ ...centerPoint, cx: flowCenterX, cy: flowCenterY, degrees: -degrees }); // rotate here
                lineEndPoint = rotate({ ...lineEndPoint, cx: flowCenterX, cy: flowCenterY, degrees: -degrees }); // rotate here
            })
            return { htmlTags, svgTags }
        }
    }, [nodes, width, longPressedNodeIndex, width]);

    return (
        <View onStartShouldSetResponder={() => true} onResponderStart={() => setLongPressedNodeIndex(-1)} style={[asSpider ? styles.container : styles.leftAlignedContainer, asSpider ? { height, marginTop: 30 } : { paddingTop: 77, paddingBottom: 34 }]} onLayout={onLayout}>
            {
                asSpider ? (
                    <>
                        {/* SVG as Background */}
                        <Svg width="100%" height="100%" viewBox={`0 0 ${width} ${height}`} style={{ position: 'absolute', left: 0, top: 0 }}>
                            {svgTags}
                            <Circle cx={width / 2} cy={height / 2 + centerAdjustment} r="6" fill="rgba(142, 142, 147, 1)" />
                        </Svg>
                        {/* Absolute positioned divs as nodes */}
                        {htmlTags}
                    </>
                ) : (
                    <View style={{ flexDirection: 'row', maxWidth: '100%' }}>
                        <View style={{ maxWidth: '100%', width: "100%" }}>
                            {htmlTags}
                        </View>
                    </View>
                )
            }
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        maxWidth: 333,
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: "transparent",
        marginBottom: 26,
        position: 'relative',
        zIndex: 1,
    },
    leftAlignedContainer: {
        width: '100%',
        backgroundColor: "transparent",
        paddingLeft: 29,
        paddingRight: 29,
        marginBottom: 26,
        position: 'relative',
        zIndex: 1,
    },
    button: {
        margin: 10,
        width: 70,
        height: 70,
        backgroundColor: 'transparent',
        borderRadius: 35,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 3,
        borderColor: '#42ff49'
    },
    label: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: extraVerticalLabelSpace,
    },
    energyText: {
        fontSize: 17,
        lineHeight: 21,
        color: 'white',
        fontFamily: globalStyles.fontSettings.families.bold,
        textAlign: 'center',
    },
    subEnergyText: {
        fontFamily: globalStyles.fontSettings.families.regular,
        color: 'rgba(235, 235, 245, 0.6)',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
    },
    columnViewHorizontal: {
        flexDirection: 'column',
        flex: 1,
        height: 84,
        justifyContent: 'center',
    },
    columnViewCircle: {
        flexDirection: 'column',
        alignItems: 'center'
    },
    middle: {
        justifyContent: 'center',
        alignItems: 'center'
    },
});
