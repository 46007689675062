'use strict';

define("LightsceneRGBControl", ["LightControl"], function (LightControl) {
    return class LightsceneRGBControl extends LightControl {
        constructor() {
            super(...arguments);
        }

        getIcon() {
            return Icon.Light.LIGHT_CONTROL;
        }

        getButton0() {// no button
        }

    };
});
