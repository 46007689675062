import * as React from "react"
import Svg, { Path } from "react-native-svg"

function AwningExtend(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path d="M1.955.45A.5.5 0 012.452 0H4.5a.5.5 0 01.5.5V8H1L1.955.45zM3 11a2 2 0 002-2H1a2 2 0 002 2zm8-2a2 2 0 11-4 0h4zm6 0a2 2 0 11-4 0h4zm6 0a2 2 0 11-4 0h4zM7.5 0a.5.5 0 00-.5.5V8h4V.5a.5.5 0 00-.5-.5h-3zm6 0a.5.5 0 00-.5.5V8h4V.5a.5.5 0 00-.5-.5h-3zm6 0a.5.5 0 00-.5.5V8h4L22 .5a.5.5 0 00-.5-.5h-2zm-18 23.5A.5.5 0 012 23h20a.5.5 0 010 1H2a.5.5 0 01-.5-.5zm12.688-5.39a.5.5 0 11.624.78l-2.495 1.997a.498.498 0 01-.632.001L9.188 18.89a.5.5 0 11.624-.78l1.688 1.35V13.5a.5.5 0 011 0v5.96l1.688-1.35z" />
        </Svg>
    )
}

export default AwningExtend
