import LxReactComp from "../LxReactComp";
import LxReactTableView from "./LxReactTableView";
import PropTypes from "prop-types";


class LxReactTableViewWrapper extends LxReactComp {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <LxReactTableView
                {...this.props}
            />
        );
    }
}

export default LxReactTableViewWrapper

LxReactTableViewWrapper.propTypes = {
    ...LxReactTableView.propTypes
}
