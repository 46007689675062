import {useContext, useMemo} from "react";
import {LxReactTableView, ControlContext} from "LxComponents";
import {View} from "react-native";
import {useNavigation} from "@react-navigation/native";

function LxControlStatesSections (props) {
    const {control, states} = useContext(ControlContext);
    const navigation = useNavigation();

    const statesContent = useMemo(() => {
        const content = control.getStateSections && control.getStateSections(states, navigation);
        return content;
        }, [states])

    return <>
        {statesContent && <View style={styles.container}>
            { statesContent && statesContent.length ? <LxReactTableView styles={styles.tableView} tableContent={statesContent} /> : null}
        </View>}
    </>
}

const styles = {
    container: {
        width: "100%"
    },
    tableView: {
        List: {
            paddingHorizontal: 0
        }
    }
}

export default LxControlStatesSections
