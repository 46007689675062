'use strict';

define("InfoViewControl", ["Control"], function (Control) {
    return class InfoViewControl extends Control {
        constructor() {
            super(...arguments);
        }

        getFriendlyValueName(event, operator) {
            if (this.type === ControlType.INFO_ONLY_DIGITAL) {
                switch (event.data.stateName) {
                    case "active":
                        if (this.details.text) {
                            return this.name + " " + (event.value ? this.details.text.on : this.details.text.off);
                        }

                        return this.name + " " + (event.value ? _("on") : _("off")).toLowerCase();

                    default:
                        return "";
                }
            } else if (this.type === ControlType.INFO_ONLY_ANALOG) {
                switch (event.data.stateName) {
                    case "value":
                        return this.name + " " + event.data.stateTitle + " " + operator + " " + event.value.toString().replace(",", ".");

                    default:
                        return "";
                }
            }

            return "";
        }

    };
});
