'use strict';

define([], function () {
    return {
        ServerState: {
            INVALID_ZONE: -3,
            NOT_REACHABLE: -2,
            UNKNOWN: -1,
            OFFLINE: 0,
            INITIALIZING: 1,
            ONLINE: 2
        },
        PlayState: {
            UNKNOWN: -1,
            STOPPED: 0,
            PAUSED: 1,
            PLAYING: 2
        },
        RepeatState: {
            NONE: 0,
            ALL: 1,
            ONE: 2
        }
    };
});
