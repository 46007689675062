import { LxReactText } from '../../react-comps/Components';
import LxReactFlexibleCell from '../../react-comps/LxReactFlexibleCell/LxReactFlexibleCell';
export default class StatusMonitorControlStatesSrc {
    static getStateSections(control, states) {
        let rows = {};
        rows =
            states &&
            states.sortedInputObjectArray &&
            states.sortedInputObjectArray.map((input) =>
                this.createMonitorStateCell(control, input),
            );
        return [{ rows }];
    }

    /**
     * Will create a state cell object for this monitor state entry.
     * @param entry
     * @param control
     * @return {*|{content: {title: *, stateText: *}, type: string}}
     */
    static createMonitorStateCell(control, entry) {
        var locationName = null;

        if (entry.room) {
            locationName = entry.room.name;
            if(entry.installPlace) {
                locationName += `${SEPARATOR_SYMBOL}${entry.installPlace}`;
            }
        }

        const inputCtrl = entry.uuid
                ? ActiveMSComponent.getStructureManager().getControlByUUID(
                      entry.uuid,
                  )
                : null,
            openAction = inputCtrl
                ? () => {
                      NavigationComp.showControlContent(inputCtrl);
                  }
                : undefined;

        return {
            type: 'CustomCell',
            comp: LxReactFlexibleCell,
            props: {
                title: entry.name,
                subTitle: locationName,
                mainRightContent: {
                    comp: LxReactText,
                    props: {
                        numberOfLines: 1,
                        children: entry.stateText,
                        style: {
                            color: entry.stateColor,
                        },
                    },
                },
                mainCenterStyle: {
                    flexShrink: 0,
                },
                titleStyle: {
                    numberOfLines: 1,
                },
                subTitleStyle: {
                    numberOfLines: 1,
                },
                disclosureIcon: !!openAction,
                onPress: openAction,
            },
        };
    }
}
