import {
    useSetURLHook,
    LxReactWaitingView
} from "LxComponents";
import React, {
    useEffect,
    useRef,
    useMemo,
    useState,
    useCallback
} from "react";

import CardScreen from "../../ViewController/cardScreen/cardScreen.jsx";

function GroupCardContentScreen({navigation, route}) {
    const childRef = useRef(null);
    const [hasActiveMs, setHasActiveMs] = useState(false);

    const group = useMemo(() => {
        return ActiveMSComponent.getStructureManager().getGroupByUUID(route.params.groupUUID, route.params.groupType);
    }, [route.params.groupUUID, route.params.groupType])


    const groupContent = useMemo(() => {
        return ActiveMSComponent.getStructureManager().getControlsInGroup(route.params.groupType, route.params.groupUUID);
    }, [route.params.groupUUID, route.params.groupType])

    useEffect(() => {
        if (!group || !groupContent || !groupContent.length) {
            EntryPointHelper.resetEntryPointLocation();
            navigation.goBack();
        }
    }, [group])

    useSetURLHook({
        urlPart: group && group.uuid
    });

    useEffect(() => {
        ActiveMSComponent.comReadyPrms.depActiveMsDone(() => {
            ActiveMSComponent.getSortingReadyPromise().then(() => {
                setHasActiveMs(true);
            });
        });

        const deRegStopMSSession = CompChannel.on(CCEvent.StopMSSession, () => {
            setHasActiveMs(false);
        });

        const unsubscribeBlur = navigation.getParent().addListener("tabPress", () => {
            let navigationState = navigation.getState();
            if (navigationState.index !== 0 || (navigationState.index === 0 && navigationState.routes.length === 1)) {
                let groupScreenState = group.groupType === GroupTypes.CATEGORY ? ScreenState.CategoriesOverview : ScreenState.RoomsOverview;
                let detailScreenState = group.groupType === GroupTypes.CATEGORY ? ScreenState.CategoryDetail : ScreenState.RoomDetail;
                navigation.reset({
                    index: 0,
                    routeNames: [groupScreenState, detailScreenState],
                    routes: [{
                        name: groupScreenState,
                        params: null
                    }],
                    params: {}
                });
            }
        });
        return () => {
            unsubscribeBlur();
            CompChannel.off(deRegStopMSSession);
        };
    }, [])

    let hasFavorites;

    const _getContent = useCallback(() => {
        if (!group) {
            return []
        }
        let content = [],
            ratedSection = _createRatedSection(group.groupType === GroupTypes.CATEGORY),
            subGroupType = _getSubGroupType();

        if (ratedSection) {
            hasFavorites = true;
            content.push(ratedSection);
        } // prepare data sources for the subGroups. (if this is a room, categories are the subgroups & vice versa)


        let allOtherGroups = ActiveMSComponent.getStructureManager().getGroupsByType(subGroupType, true),
            subGroupObjects = {},
            subGroupTypeId = subGroupType === GroupTypes.ROOM ? "room" : "cat";

        groupContent.forEach((control) => {
            if (!subGroupObjects[control[subGroupTypeId]]) {
                subGroupObjects[control[subGroupTypeId]] = {
                    controls: []
                };
                subGroupObjects[control[subGroupTypeId]][subGroupTypeId] = allOtherGroups[control[subGroupTypeId]];
            }

            subGroupObjects[control[subGroupTypeId]].controls.push(control);
        }); // transform the set into an array, then sort it

        subGroupObjects = Object.values(subGroupObjects);
        subGroupObjects.sort((a, b) => {
            return _compareByAttributeName(a, b, subGroupTypeId);
        }); // iterate over subgroups and create sections for each one.

        subGroupObjects.forEach((subGroup) => {
            content.push(_createSectionForSubgroup(subGroup));
        });

        return content;
    }, [group])

    const _getSubGroupType = () => {
        return group.groupType === GroupTypes.ROOM ? GroupTypes.CATEGORY : GroupTypes.ROOM;
    }

    /**
     * Will return a section containing all rated controls (optionally also only favorites). When showing a category,
     * it is not good to show all controls that have a rating as a card. There would be too many of them and the
     * rating is based on the room-view. That is why categorys show favorites, sorted by rating.
     * @param isCategory    categories are handled a bit different
     * @return {*}
     * @private
     */
    const _createRatedSection = (isCategory)  => {
        var section0 = {
                rows: []
            },
            // as designed (Tom)
            ratedControls = ActiveMSComponent.getStructureManager().getRatedControls(group.uuid, false),
            forceCellUI = !NavigationComp.tileRepresentationEnabled();
        ratedControls.forEach((rCtrl, idx) => {
            addControlToSection(section0, rCtrl, forceCellUI, isCategory, true);
        });

        if (section0.rows.length > 0) {
            return section0;
        } else {
            return null;
        }
    }


    /**
     * Returns a comparison result usable for sorting. It compares two objects based on their attributes names.
     * E.g. two control objects based on their room or category name.
     * @param a
     * @param b
     * @param attributeId
     * @return {number}
     * @private
     */
    const _compareByAttributeName = (a, b, attributeId) => {
        var aGrp = a[attributeId],
            bGrp = b[attributeId];

        if (aGrp && bGrp) {
            //if (a.cat.defaultRating === b.cat.defaultRating) {
            return aGrp.name.toUpperCase().localeCompare(bGrp.name.toUpperCase()); //}
            //return a.cat.defaultRating < b.cat.defaultRating ? 1 : -1;
        } else if (aGrp) {
            return -1;
        } else if (bGrp) {
            return 1;
        } else {
            return 0;
        }
    }

    /**
     * Will create a section for the sub-group provided. E.g. for a category inside a room-screen.
     * @param sourceObj
     * @private
     */
    const _createSectionForSubgroup = (sourceObj) => {
        var subGroupType = _getSubGroupType(),
            subGroupTypeId = subGroupType === GroupTypes.ROOM ? "room" : "cat",
            noSubGroupTitle = subGroupType === GroupTypes.ROOM ? _('room.without') : _('category.without'),
            currSubGroup = sourceObj[subGroupTypeId],
            section = {
                headerTitle: currSubGroup ? currSubGroup.name : noSubGroupTitle,
                didSelectHeader: () => {
                    NavigationComp.showGroupContent(subGroupType, currSubGroup.uuid)
                },
                rows: []
            };

        if (Debug.Sorting && currSubGroup) {
            section.headerTitle = "(" + currSubGroup.defaultRating + ") " + section.headerTitle;
        }

        sourceObj.controls.forEach((ctrl) => {
            addControlToSection(section, ctrl, true, false);
        }); // sort controls by name and defaultRating

        section.rows.sort(_sectionContentCompare.bind(this));
        return section;
    }

    /**
     * Will compare two contents withing a section. The result is then used to sort.
     * @param a
     * @param b
     * @return {number}
     * @private
     */
    const _sectionContentCompare = (a, b) => {
        try {
            if (!ActiveMSComponent.getSortingStructure()) {
                // old star sorting
                if (a.content.control.isGrouped() && b.content.control.isGrouped()) {
                    return a.content.control.getName().toUpperCase().localeCompare(b.content.control.getName().toUpperCase());
                } else if (a.content.control.isGrouped()) {
                    return -1;
                } else if (b.content.control.isGrouped()) {
                    return 1;
                } else {
                    return a.content.control.getName().toUpperCase().localeCompare(b.content.control.getName().toUpperCase());
                }
            } else {
                var groupIdentifier, location;

                if (group.groupType === GroupTypes.ROOM) {
                    groupIdentifier = a.content.control.cat || ActiveMSComponent.getCategoryFor(a.content.control);
                    location = UrlStartLocation.ROOM;
                } else {
                    groupIdentifier = a.content.control.room || ActiveMSComponent.getRoomFor(a.content.control);
                    location = UrlStartLocation.CATEGORY;
                }

                var objA = ActiveMSComponent.getSortingStructureForObject(a.content.control.uuidAction, location + "/" + groupIdentifier);
                var objB = ActiveMSComponent.getSortingStructureForObject(b.content.control.uuidAction, location + "/" + groupIdentifier);

                if (objA && objB && objA.position > objB.position) {
                    return 1;
                }

                if (objA && objB && objA.position < objB.position) {
                    return -1;
                }

                return a.content.control.getName().toUpperCase().localeCompare(b.content.control.getName().toUpperCase());
            }
        } catch (ex) {
            console.error(ex);
            console.error("A: " + a.content.control.type + " " + a.content.control.getName());
            console.error("B: " + b.content.control.type + " " + b.content.control.getName());
        }
    }

    const addControlToSection = (section, control, displayAsCell, displayRoomName, showGroupDetails) => {
        var hideActionElements = control.isGrouped() && control.isInCentralRoom();
        section.rows.push({
            type: control.getCellType(),
            content: {
                control: control,
                displayAsCell: displayAsCell,
                displayRoomName: displayRoomName,
                showGroupDetails: showGroupDetails,
                hideActionElements: hideActionElements
            }
        });
    }

    const getContextMenuOptions = useCallback(() => {
        let options = [];

        if (childRef.current) {
            options.pushObject(childRef.current.getSortingOption());
        }

        if (ActiveMSComponent.isExpertModeLightEnabled()) {
            options.push({
                title: _('adopt-ui-settings'),
                action: () => {
                    NavigationComp.showGroupSettings(group, true);
                }
            });
        }

        if (ActiveMSComponent.isExpertModeEnabled()) {
            options.push({
                title: _('expert-settings'),
                action: () => {
                    NavigationComp.showGroupSettings(group);
                }
            });
        }

        if (SandboxComponent.isAutopilotAvailable() && Feature.AUTOMATIC_DESIGNER_SCENES) {
            options.push({
                title: _("scenes.create"),
                action: () => {
                    let rule = cloneObject(AutomaticDesignerEnums.SCENE_TEMPLATE);

                    if (group.groupType === GroupTypes.ROOM) {
                        rule.visu.room = group.uuid;
                    } else {
                        rule.visu.cat = group.uuid;
                    }

                    NavigationComp.showState(ScreenState.AutomaticDesigner.RuleViewController, {
                        rule: rule,
                        extension: AutomaticDesignerEnums.RULE_EXTENSION.SCENE
                    });
                }
            });
        }

        return options;
    }, [])

    const _getFavForIndex = useCallback((section, numberOfSections) => {
        if (numberOfSections === 1) {
            return false;
        } else {
            return section === 0;
        }
    }, [])


    const _getSortingLocation = useCallback((section, tableContent) => {
        var location,
            groupIdentifier,
            control = tableContent[section].rows[0].content.control;

        if (group.groupType === GroupTypes.ROOM) {
            location = UrlStartLocation.ROOM;
            groupIdentifier = control.cat || ActiveMSComponent.getCategoryFor(control);
        } else {
            location = UrlStartLocation.CATEGORY;
            groupIdentifier = control.room || ActiveMSComponent.getRoomFor(control);
        }

        if (hasFavorites) {
            if (section === 0) {
                return location;
            } else {
                return location + "/" + groupIdentifier;
            }
        } else {
            return location + "/" + groupIdentifier;
        }
    }, [])

    const getSortingContextMenuOptions = useCallback((cell) => {
        let defaultContent = childRef.current.getSortingContextMenuOptions(cell),
            contextMenuContent = [];

        contextMenuContent.pushObject(_createOnFavEntry(cell.content.control));
        contextMenuContent.pushObject(_createOnGroupEntry(cell.content.control));
        contextMenuContent = contextMenuContent.concat(defaultContent);
        return contextMenuContent;
    }, [])

    const _createOnFavEntry = (control) => {
        if (ActiveMSComponent.isExpertModeLightEnabled() && !NavigationComp.getCurrentActivityType()) {
            if (!ActiveMSComponent.getSortingStructure()) {
                if (control.isFavorite) {
                    return {
                        title: _("context-menu.remove-tab-favorite"),
                        action: () => {
                            childRef.current.removeAsFavorite(control, UrlStartLocation.FAVORITES);
                        }
                    };
                } else {
                    return {
                        title: _("context-menu.add-tab-favorite"),
                        action: () => {
                            childRef.current.addAsFavorite(control, UrlStartLocation.FAVORITES);
                        }
                    };
                }
            } else {
                let sortingObject = ActiveMSComponent.getSortingStructureForObject(control.uuidAction, UrlStartLocation.FAVORITES);

                if (sortingObject && sortingObject.isFav) {
                    return {
                        title: _("context-menu.remove-tab-favorite"),
                        action: () => {
                            childRef.current.removeAsFavorite(control, UrlStartLocation.FAVORITES);
                        }
                    };
                } else {
                    return {
                        title: _("context-menu.add-tab-favorite"),
                        action: () => {
                            childRef.current.addAsFavorite(control, UrlStartLocation.FAVORITES);
                        }
                    };
                }
            }
        } else {
            return null;
        }
    }

    const _createOnGroupEntry = (control) => {
        if (ActiveMSComponent.isExpertModeLightEnabled() && !NavigationComp.getCurrentActivityType()) {
            let location, sortingObject;

            if (group.groupType === GroupTypes.ROOM) {
                location = UrlStartLocation.ROOM;
            } else {
                location = UrlStartLocation.CATEGORY;
            }

            if (!ActiveMSComponent.getSortingStructure()) {
                if (control.defaultRating && control.defaultRating > 0 && control.isFavorite && location === UrlStartLocation.CATEGORY || control.defaultRating && control.defaultRating > 0 && location === UrlStartLocation.ROOM) {
                    return _createRemoveFavoriteEntry(control, location);
                } else {
                    return _createAddFavoriteEntry(control, location);
                }
            } else {
                sortingObject = ActiveMSComponent.getSortingStructureForObject(control.uuidAction, location);

                if (sortingObject && sortingObject.isFav) {
                    return _createRemoveFavoriteEntry(control, location);
                } else {
                    return _createAddFavoriteEntry(control, location);
                }
            }
        } else {
            return null;
        }
    }

    const _createAddFavoriteEntry = (control, location) => {
        let title;

        if (group.groupType === GroupTypes.ROOM) {
            title = _("context-menu.add-room-favorite");
        } else {
            title = _("context-menu.add-category-favorite");
        }

        return {
            title: title,
            action: () => {
                childRef.current.addAsFavorite(control, location, group.uuid);
            }
        };
    }


    const _createRemoveFavoriteEntry = (control, location) => {
        let title;

        if (group.groupType === GroupTypes.ROOM) {
            title = _("context-menu.remove-room-favorite");
        } else {
            title = _("context-menu.remove-category-favorite");
        }

        return {
            title: title,
            action: () => {
                childRef.current.removeAsFavorite(control, location, group.uuid);
            }
        };
    }

    const initialContent = useMemo(() => {
        return hasActiveMs ? _getContent() : null;
    }, [hasActiveMs, group]);

    if (hasActiveMs && group) {

        return <CardScreen
            ref={childRef}
            tableContentFromParent={initialContent}
            showBackNavigationButton={true}
            getTableContent={_getContent}
            contextMenuOptions={getContextMenuOptions}
            title={group.name}
            getFavForIndex={_getFavForIndex}
            getSortingLocation={_getSortingLocation}
            getSortingContextMenuOptions={getSortingContextMenuOptions}
        />
    } else {
        return <LxReactWaitingView />
    }
}



export default GroupCardContentScreen;
