'use strict';

window.GUI = function (GUI) {
    /**
     * A Cell with a "+" on the rightHandSide
     * The content represents the item that is being shown, plus a few other attributes such as:
     *
     * addButton        is there an Add button to be shown?
     * disclosureIcon   should there be a disclosure icon?
     */
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        /**
         * Enum identifying the states the mediaCell can be in.
         * @type {{ADD: string, SPINNING: string, DONE: string, ERROR: string}}
         */
        var MediaAddState = {
            ADD: 'add',
            SPINNING: 'spinning',
            DONE: 'done',
            ERROR: 'error'
        };

        class MediaAddCell extends GUI.TableViewV2.Cells.BaseCell {
            constructor() {
                super(...arguments);
                this.element.addClass("media-add-cell");
                this.mediaAddState = MediaAddState.ADD;
            }

            viewDidLoad() {
                super.viewDidLoad();

                if (!this.elements) {
                    this.elements = {};
                }

                if (!this.buttons) {
                    this.buttons = {};
                }

                var texts,
                    hasDisclosureIcon = this.content.hasOwnProperty("disclosureIcon") && this.content.disclosureIcon,
                    coverUrl = null,
                    hasAddButton = this.content.hasOwnProperty("addButton") && this.content.addButton,
                    isTarget = this.content.hasOwnProperty("isTarget") && this.content.isTarget;

                if (this.content.hasOwnProperty("coverUrl")) {
                    coverUrl = this.content.coverUrl;
                }

                if (this.content.hasOwnProperty("title")) {
                    texts = {
                        title: this.content.title,
                        subtitle: this.content.subtitle
                    };
                } else {
                    texts = MediaServerComp.getTitleSubtitleForItem(this.content);
                }

                this.contentPlaceholder.append(GUI.TableViewV2.Cells.MediaAddCell.Template.getMediaAddCellContent(texts.title, texts.subtitle, hasDisclosureIcon, hasAddButton));
                this.elements.coverPlaceholder = this.contentPlaceholder.find('.media-add-cell__left-icon-placeholder');
                this.elements.coverView = new Controls.AudioZoneControl.CoverView(this.content);
                this.elements.coverView.element.addClass("left-icon-placeholder__icon");
                this.appendSubview(this.elements.coverView, this.elements.coverPlaceholder);

                if (hasAddButton) {
                    this.elements.addIcon = this.contentPlaceholder.find('.right-icon-placeholder__icon-add');
                    this.elements.errorIcon = this.contentPlaceholder.find('.right-icon-placeholder__icon-error').hide();
                    this.elements.doneIcon = this.contentPlaceholder.find('.right-icon-placeholder__icon-done').hide();
                    this.elements.spinningIcon = this.contentPlaceholder.find('.right-icon-placeholder__icon-spinning').hide();
                    this.elements.addButton = this.contentPlaceholder.find('.media-add-cell__right-icon-placeholder');

                    if (!isTarget) {
                        this.buttons.addButton = new GUI.LxButton(this, this.elements.addButton[0], Color.BUTTON_GLOW);
                        this.buttons.addButton.useChildsAsActiveParts('fill,color');
                        this.addToHandledSubviews(this.buttons.addButton);
                    }
                }

                this.element.toggleClass("media-add-cell--add-item-target", isTarget);
            }

            viewDidAppear() {
                super.viewDidAppear(...arguments);

                if (this.buttons.addButton) {
                    this.buttons.addButton.onButtonTapped = this._handleAddButton.bind(this);
                }
            }

            viewWillDisappear() {
                super.viewWillDisappear(...arguments);

                if (this.buttons.addButton) {
                    this.buttons.addButton.onButtonTapped = null;
                }
            }

            clickableAllowed() {
                return true;
            }

            _handleAddButton() {
                if (!this.delegate.onMediaAddButtonTapped) {
                    console.error(this.delegate + " does not handle onMediaAddButtonTapped!");
                    return;
                }

                if (this.mediaAddState === MediaAddState.SPINNING) {
                    console.error(this.name + " is already adding");
                    return;
                }

                this._showAdding();

                var promise = this.delegate.onMediaAddButtonTapped.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);

                if (promise) {
                    promise.done(this._showDone.bind(this), this._showError.bind(this));
                } else {
                    this._showPlus();
                }
            }

            /**
             * Replaces the add button with a spinning indicator.
             * @private
             */
            _showAdding() {
                this.elements.addIcon.hide();
                this.elements.errorIcon.hide();
                this.elements.doneIcon.hide();
                this.elements.spinningIcon.show();
                this.mediaAddState = MediaAddState.SPINNING;
            }

            /**
             * Replaces the spinning indicator button with a tickmark.
             * @param dismiss   if true, this view needs to be dismissed.
             * @private
             */
            _showDone() {
                this._swapElements(this.elements.spinningIcon, this.elements.doneIcon);

                this.mediaAddState = MediaAddState.DONE;
                this.elements.errorIcon.hide();
                this.elements.addIcon.hide();
                setTimeout(this._showAdd.bind(this), 2000);
            }

            _swapElements(hideElem, showElem, duration) {
                if (!duration) {
                    duration = 350;
                }

                var outDuration = duration * 0.8,
                    inDuration = duration * 1.2;
                hideElem.velocity("fadeOut", {
                    duration: outDuration,
                    complete: lxRequestAnimationFrame.bind(window, function () {
                        hideElem.hide();
                    }.bind(this), hideElem)
                });
                showElem.velocity("fadeIn", {
                    begin: lxRequestAnimationFrame.bind(window, function () {
                        showElem.show();
                    }.bind(this), showElem),
                    duration: inDuration
                });
            }

            /**
             * Replaces the tickmark with a plus icon.
             * @private
             */
            _showAdd() {
                this._swapElements(this.elements.doneIcon, this.elements.addIcon, 1000);

                this.mediaAddState = MediaAddState.ADD;
                this.elements.errorIcon.hide();
                this.elements.spinningIcon.hide();
            }

            /**
             * Replaces the spinning indicator with an error symbol
             * @private
             */
            _showError(error) {
                if (error === "cancel") {
                    // don't show an error if the user has cancelled it!
                    this._showPlus();

                    return;
                }

                this.elements.addIcon.hide();
                this.elements.errorIcon.show();
                this.elements.doneIcon.hide();
                this.elements.spinningIcon.hide();
                this.mediaAddState = MediaAddState.ERROR;
                var content = {
                    title: _('media.playlist.add.error.title', {
                        item: MediaServerComp.getNameForItem(error.item)
                    }),
                    message: error.cause,
                    buttonOk: _('okay'),
                    icon: Icon.CAUTION,
                    type: PopupType.GENERAL
                };
                NavigationComp.showPopup(content);
            }

            /**
             * Replaces any other icon with the plus.
             * @private
             */
            _showPlus() {
                this.elements.addIcon.show();
                this.elements.errorIcon.hide();
                this.elements.doneIcon.hide();
                this.elements.spinningIcon.hide();
                this.mediaAddState = MediaAddState.ADD;
            }

        }

        GUI.TableViewV2.Cells.MediaAddCell = MediaAddCell;
    }

    GUI.TableViewV2.Cells.MediaAddCell.Template = function () {
        var getMediaAddCellContent = function getMediaAddCellContent(title, subtitle, hasDisclosureIcon, hasAddButton) {
            var icon = '<div class="media-add-cell__left-icon-placeholder"></div>';
            var infoTag = '';

            if (subtitle && subtitle !== '') {
                infoTag = '<div class="texts-placeholder__info">' + subtitle + '</div>';
            }

            var addButton = '';

            if (hasAddButton) {
                var iconClass = 'right-icon-placeholder__icon right-icon-placeholder__icon-';
                addButton = '<div class="media-add-cell__right-icon-placeholder">' + ImageBox.getResourceImageWithClasses("resources/Images/General/button-plus.svg", iconClass + "add") + '<div class="right-icon-placeholder__icon-spinning">' + '<div class="icon-spinning__spinner"></div>' + // boxed, otherwise the whole cell'd be shaking.
                    '</div>' + ImageBox.getResourceImageWithClasses("resources/Images/General/button-checked.svg", iconClass + 'done') + ImageBox.getResourceImageWithClasses("resources/Images/General/caution.svg", iconClass + 'error') + '</div>';
            }

            var disclosureIcon = '';

            if (hasDisclosureIcon) {
                disclosureIcon = ImageBox.getResourceImageWithClasses('resources/Images/General/disclosure-icon.svg', 'name__disclosure-icon');
            }

            return icon + '<div class="media-add-cell__texts-placeholder">' + '<div class="texts-placeholder__name">' + '<div class="name__text">' + title + '</div>' + disclosureIcon + '</div>' + infoTag + '</div>' + addButton;
        };

        return {
            getMediaAddCellContent: getMediaAddCellContent
        };
    }();

    GUI.TableViewV2.CellType.Special.MEDIA_ADD = "MediaAddCell";
    return GUI;
}(window.GUI || {});
