import * as React from "react"
import Svg, { Path } from "react-native-svg"

function WeatherSnow(props) {
    return (
        <Svg isreact="true"
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            viewBox="0, 0, 32, 32"
            {...props}
        >
            <Path
                d="M2 17.75a5.252 5.252 0 012.627-4.547 7.876 7.876 0 0114.572-3.971 5.696 5.696 0 016.939 3.453A5.251 5.251 0 0124.75 23H7.25A5.25 5.25 0 012 17.75z"
                fill="#D5E2E9"
            />
            <Path
                d="M16.423 22.65h-1.191l.312-.312a.75.75 0 00-1.059-1.059l-.312.312V20.4a.748.748 0 00-1.497 0v1.191l-.312-.312a.75.75 0 00-1.059 1.059l.312.312h-1.191a.748.748 0 000 1.497h1.191l-.312.312a.748.748 0 001.059 1.059l.312-.312v1.191a.748.748 0 001.497 0v-1.191l.312.312a.748.748 0 001.059-1.059l-.312-.312h1.191a.748.748 0 000-1.497zM21.824 19.435h-.792l.207-.208a.497.497 0 00-.704-.704l-.207.207v-.792a.499.499 0 00-.997 0v.792l-.207-.207a.498.498 0 00-.704.704l.207.208h-.792a.498.498 0 000 .996h.792l-.207.207a.498.498 0 00.704.705l.207-.208v.792a.498.498 0 00.997 0v-.792l.207.208a.498.498 0 00.704-.705l-.207-.207h.792a.498.498 0 000-.996z"
                fill="#51A9DF"
            />
        </Svg>
    )
}

export default WeatherSnow
