'use strict';

define([], function () {
    return class NewsHubScreen extends GUI.TableViewScreen {

        //region static
        static get Template() {
            return function () {
                var buttonTemplate = function () {
                    return '<div class="jobs-button-button">' + _('jobs.title') + '</div>';
                };

                return {
                    buttonTemplate: buttonTemplate
                };
            }()
        }
        //endregion

        constructor() {
            super($('<div />'));
            this.newsHubEntries = LxServerComponent.getLatestNewsHubEntries();
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                this.setUpComfortUI(false);
                this.setTitle(_('menu.miniserver.news'));
                this.deregNewsUpdateReceiver = LxServerComponent.registerForNewsHubChanges(this._newsChanged.bind(this));
                this.jobsButton = new GUI.LxButton(this, NewsHubScreen.Template.buttonTemplate(), Color.BUTTON_GLOW, null, true, 0);
                return this.appendSubview(this.jobsButton, this.element);
            }.bind(this));
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        viewWillAppear() {
            let baseCall = super.viewWillAppear(...arguments); // Hide the NewsHubNotification, we don't want to show it when the newsHub is displayed.

            LxServerComponent.hideNewsHubNotification();

            this.jobsButton.onButtonTapped = function () {
                NavigationComp.openWebsite("https://jobs.loxone.com/");
            }.bind(this);

            return baseCall;
        }

        viewDidAppear() {
            let baseCall = super.viewDidAppear(...arguments);
            LxServerComponent.setNewsHubOpened(true);
            return baseCall;
        }

        viewWillDisappear() {
            this.deregNewsUpdateReceiver();
            return super.viewWillDisappear(...arguments);
        }

        styleForTableView() {
            return TableViewStyle.TRANSLUCENT;
        }

        reloadTable() {
            this.tableContent = [{
                rows: []
            }];
            (this.newsHubEntries?.feed || []).forEach(function (feedEntry, feedEntryIdx) {
                this.tableContent[0].rows.push({
                    type: GUI.TableViewV2.CellType.Special.NEWS_HUB,
                    content: {
                        entry: feedEntry,
                        isNew: this.newsHubEntries.newIds.indexOf(feedEntry.timestamp) >= 0
                    },
                    action: this.openNewsEntry.bind(this, feedEntryIdx)
                });
            }.bind(this));
            return super.reloadTable(...arguments);
        }

        openNewsEntry(entryIdx) {
            let clickedEntry = this.newsHubEntries.feed[entryIdx];
            LxServerComponent.onNewsHubEntryOpened(clickedEntry.timestamp);
            NavigationComp.openWebsite(clickedEntry.link);
        }

        _newsChanged(ev, news) {
            // Hide the NewsHubNotification, we don't want to show it when the newsHub is displayed.
            LxServerComponent.hideNewsHubNotification();
            this.newsHubEntries = news;
            this.reloadTable();
        }

    };
});
