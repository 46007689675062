import * as React from "react"
import Svg, { Path } from "react-native-svg"

function AccessCode(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M21.5 5h-19A2.503 2.503 0 000 7.5v8C0 16.878 1.122 18 2.5 18h19c1.378 0 2.5-1.122 2.5-2.5v-8C24 6.122 22.878 5 21.5 5zM7.853 13.854a.5.5 0 01-.708 0l-1.646-1.646-1.646 1.646a.5.5 0 01-.707-.707L4.792 11.5 3.145 9.854a.5.5 0 01.707-.707l1.647 1.646 1.646-1.647a.5.5 0 01.708.708L6.207 11.5l1.647 1.646a.5.5 0 01-.001.708zm6.501 0a.5.5 0 01-.708 0L12 12.208l-1.646 1.646a.5.5 0 01-.707-.707l1.646-1.647-1.647-1.646a.5.5 0 01.707-.707L12 10.793l1.646-1.647a.5.5 0 01.707.708L12.708 11.5l1.646 1.646a.5.5 0 010 .708zM20.5 14h-4a.5.5 0 010-1h4a.5.5 0 010 1z"
            />
        </Svg>
    )
}

export default AccessCode
