'use strict';
/**
 * This view is used to show buttons in one row, centered. Buttons may be shown in a small or regular size. They are
 * only using icons, no text. Individual buttons may use individual callbacks, otherwise the views delegate is being
 * informed when a button is tapped.
 *
 * dataSource
 *      getConfigForButtons(overlayButtonBar)
 *      getButtonBarStyle(overlayButtonBar)     // if the background should be "dimmed" or "transparent"
 *
 * delegate
 *      onOverlayButtonTapped(idx)
 *      onBackgroundTapped(overlayButtonBar)    // called when the area outside was tapped.
 */

window.GUI = function (GUI) {
    class OverlayButtonBar extends GUI.View {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return $('' + '<div class="overlay-button-bar">' + '<div class="overlay-button-bar__buttons-bar"></div>' + '</div>');
            };

            var getButton = function getButton(buttonConfig) {
                var smallClass = '';

                if (buttonConfig.isSmaller) {
                    smallClass = 'buttons-bar__btn-cntr--small';
                }

                return $('<div class="buttons-bar__btn-cntr ' + smallClass + '">' + ImageBox.getResourceImageWithClasses(buttonConfig.iconSrc, "btn-cntr__icon") + '</div>');
            };

            return {
                getTemplate: getTemplate,
                getButton: getButton
            };
        }(); //endregion Static

        /**
         * Constructor for the OverlayButtonBar.
         * @param dataSource    mandatory. otherwise no buttons can be shown.
         * @param [delegate]    optional, whom to call when a button was tapped that hasn't got a callback fn.
         */
        constructor(dataSource, delegate) {
            super(OverlayButtonBar.Template.getTemplate());
            this.dataSource = dataSource;
            this.delegate = delegate;
            this.buttons = [];
            this.buttonsConfig = [];
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.bgButton = new GUI.LxButton(this, this.element[0]);
            this.addToHandledSubviews(this.bgButton);
            this.elements = {};
            this.elements.buttonsBar = this.element.find(".overlay-button-bar__buttons-bar");
        }

        viewDidAppear() {
            this.bgButton.onButtonTapped = this._handleBackgroundTapped.bind(this);

            this._registerButtons();

            super.viewDidAppear(...arguments);
        }

        viewWillDisappear() {
            this.bgButton.onButtonTapped = null;

            this._unregisterButtons();

            super.viewWillDisappear(...arguments);
        }

        // -----------------------------------------------------------------

        /**
         * Will ask the delegate for the most recent config for the overlaybuttons. if the cfg did change, it will
         * remove all current buttons from the UI and create new buttons fitting for the config & show them.
         */
        reload() {
            var newButtonsConfig = this.dataSource.getConfigForButtons.call(this.dataSource, this),
                button,
                i = 0,
                style = OverlayButtonBarStyle.TRANSPARENT;

            if (JSON.stringify(this.buttonsConfig) === JSON.stringify(newButtonsConfig)) {
                return;
            }

            this.buttonsConfig = newButtonsConfig; // unregister old buttons - if not visible, they've received the lifecycle methods already

            this.isVisible() && this._unregisterButtons(); // remove old buttons

            this.buttons.forEach(function (btn) {
                this.removeSubview(btn);
            }.bind(this)); // create new buttons

            this.buttons = [];

            for (i; i < this.buttonsConfig.length; i++) {
                button = this._createButton(this.buttonsConfig[i]);
                button.overlayButtonId = i;
                this.appendSubview(button, this.elements.buttonsBar);
                this.buttons.push(button);
            } // adopt the button bar style


            if (this.delegate.getButtonBarStyle) {
                style = this.delegate.getButtonBarStyle.call(this.delegate, this);
            }

            this.element.toggleClass("overlay-button-bar--transparent", style === OverlayButtonBarStyle.TRANSPARENT);
            this.element.toggleClass("overlay-button-bar--shaded", style === OverlayButtonBarStyle.SHADED); // register new buttons - if not visible, they'll receive the livecycle methods from the superview.

            this.isVisible() && this._registerButtons();
        }

        // ------------------------------------------------------------------
        //       Private Methods
        // ------------------------------------------------------------------

        /**
         * Will create a button using the element provided. The button will then be added to the handled subviews
         * and configured properly before being returned.
         * @param buttonConfig
         * @returns {*}
         * @private
         */
        _createButton(buttonConfig) {
            var btn = new GUI.LxButton(this, OverlayButtonBar.Template.getButton(buttonConfig), Color.BUTTON_GLOW, null, true);
            btn.useChildsAsActiveParts('fill');
            btn.setRippleEnabled(true);

            if (buttonConfig.color) {
                btn.getElement().css('fill', buttonConfig.color);
            }

            return btn;
        }

        _registerButtons() {
            this.buttons.forEach(function (btn) {
                btn.onButtonTapped = this._handleButtonTapped.bind(this);
            }.bind(this));
        }

        _unregisterButtons() {
            this.buttons.forEach(function (btn) {
                btn.onButtonTapped = null;
            });
        }

        /**
         * This method will first check if the button that was tapped has its own callback. If not it'll inform
         * the delegate (assuming it implements onOverlayButtonTapped).
         * @param src   the button that was tapped
         * @param ev    the tap-event.
         * @private
         */
        _handleButtonTapped(src, ev) {
            var id = src.overlayButtonId,
                btnCb = this.buttonsConfig[id].callback;

            if (btnCb) {
                btnCb();
            } else if (this.delegate.onOverlayButtonTapped) {
                this.delegate.onOverlayButtonTapped.call(this.delegate, id, src, this);
            }
        }

        /**
         * Called whenever the background was tapped.
         * @private
         */
        _handleBackgroundTapped() {
            if (this.delegate.onBackgroundTapped) {
                this.delegate.onBackgroundTapped.call(this.delegate, this);
            }
        }

    }

    GUI.OverlayButtonBar = OverlayButtonBar;
    /**
     * Helper methods for dataSources, it'll return button configs that work smoothly with this view.
     * @param icon
     * @param [cb]          method that will be called when the button was tapped (instead of using the delegate)
     * @param [color]       the color to use for the icon in the button.
     * @param [smaller]     boolean value indicating if the icon is to be shown smaller
     */

    GUI.OverlayButtonBar.configForButton = function configForButton(icon, cb, color, smaller) {
        return {
            iconSrc: icon,
            callback: cb,
            isSmaller: !!smaller,
            color: color
        };
    };

    return GUI;
}(window.GUI || {});
/**
 * Speficies how the background behind the buttons is to be themed.
 * @type {{TRANSPARENT: string, SHADED: string}}
 */


var OverlayButtonBarStyle = {
    TRANSPARENT: "transparent",
    SHADED: "shaded"
};
