'use strict';

define(["LightControlContentOldHD", "LightV2ControlMoodList", "LightV2ControlLightCircuitList", "LightV2ControlNewMoodScreen"], function (LightControlContentOldHD, LightV2ControlMoodList, LightV2ControlLightCircuitList, LightV2ControlNewMoodScreen) {
    return class LightV2ControlContentOldHD extends LightControlContentOldHD {
        constructor(details) {
            super(details); // This is no RGBLightControl
            // Overriding this variable from the base class

            this.isLightControl = true;
            this.sceneList = new LightV2ControlMoodList(this.control);
            this.manualList = new LightV2ControlLightCircuitList(this.control);
        }

        viewDidLoad() {
            var promise = super.viewDidLoad(...arguments);
            this.element.addClass("light-control-contenthd");
            return promise;
        }

    };
});
