import { useMemo } from "react";
import { DateViewType, DateViewTypeUtils } from "LxComponents";

/**
 * Prints the provided data points list
 * @param logKey    log-prefix
 * @param title     header to be logged before the dps are listed
 * @param dps       the datapoints to log
 */
const printDps = (logKey, title, dps) => {
    console.log(logKey, title + ", " + dps.length + " datapoints");
    let lastDpVals,
        lastDpHidden = false,
        hiddenStartIdx = -1;

    dps.forEach((dp, idx) => {
        if (
            lastDpVals && // not the first one
            dp.values.every((val, idx) => lastDpVals[idx] === val) && // values unchanged
            idx < dps.length - 1 // not the last one
        ) {
            // unchanged - don't log
            lastDpHidden = true;
            if (hiddenStartIdx < 0) {
                hiddenStartIdx = idx;
            }

        } else {
            // initial or dp with modified values
            if (lastDpHidden) {
                console.log(logKey, `       ... ${lxFormat("%04.0f", idx - hiddenStartIdx)} dps with unchanged values = ${lastDpVals.join(", ")}`);
                hiddenStartIdx = -1;
            }
            lastDpHidden = false;
            lastDpVals = dp.values;
            printDp(logKey, dp, idx);
        }
    });
}
const printDp = (logKey, dp, idx, prefix = "") => {
    console.log(logKey, `   ${prefix}${lxFormat("%04.0f", idx)}: ${moment.unix(dp.ts).format(DateType.CSV)} = ${dp.values.join(", ")}`);
}

const extendNonActualData = ({stats, startTs, endTs, viewType}) => {
    if(stats.data.length === 0) {
        return stats.data;
    }
    const viewIdx = [
        DateViewType.Day,
        DateViewType.Week,
        DateViewType.Month,
        DateViewType.Year,
        DateViewType.Lifetime,
    ].indexOf(viewType);
    if(viewIdx === -1) {return stats.data}

    const depth = stats.data[0].values.length;

    if(viewType === DateViewType.Lifetime) {
        startTs = ActiveMSComponent.getMomentFromUtcTimestamp(stats.data[0].ts).startOf('year').unix();
        endTs = ActiveMSComponent.getMomentFromUtcTimestamp(stats.data[stats.data.length - 1].ts).endOf('year').unix();
    }

    const finalisedEntries = [];

    let statDiffUnit = DateViewTypeUtils.getStatisticDiffDataPointUnit(viewType); // = hour, day, month, year
    let momentTs =  ActiveMSComponent.getMomentFromUtcTimestamp(startTs),
        endMomentTs = ActiveMSComponent.getMomentFromUtcTimestamp(endTs),
        currDpMomentTs;

    const fillEmtpyDp = (momentTs) => {
        const entry = {
            ts: momentTs.unix(),
            values: [],
        }
        for(let y = 0; y < depth; y++) {entry.values.push(0);}
        finalisedEntries.push(entry);
    }

    stats.data.forEach((dp) => {
        // fill gap between last dp timestamp and this one
        currDpMomentTs = ActiveMSComponent.getMomentFromUtcTimestamp(dp.ts);
        while (currDpMomentTs.isAfter(momentTs, statDiffUnit)) {
            fillEmtpyDp(momentTs);
            momentTs.add(1, statDiffUnit);
        }
        // add the current timestamp
        finalisedEntries.push(dp);
        momentTs.add(1, statDiffUnit);
    });

    // fill up until the end of the range
    while (!momentTs.isAfter(endMomentTs, statDiffUnit)) {
        fillEmtpyDp(momentTs);
        momentTs.add(1, statDiffUnit);
    }

    return finalisedEntries;
}

const extendActualStatsData = ({usedMode, stats, startTs, endTs, viewType}) => {
    // check if the first stats data ts is between start and end ts 
    if (viewType === DateViewType.Live && !(startTs <= stats.data[0]?.ts && stats.data[0]?.ts <= endTs)) {
        return [];
    }

    let workingData = [...stats.data].filter(datapoint => datapoint !== null);
    if(workingData.length === 0) {
        return workingData;
    }
    workingData.sort((a, b) => {
        if (a.ts > b.ts) {
            return 1; // a after b
        } else if (a.ts < b.ts) {
            return -1; // a before b
        } else {
            return 0;
        }
    });

    //printDps("extendActualStatsData", "Initial Dataset: " + viewType + ", mode=" + stats. mode, stats.data);
    const depth = stats.data[0].values.length;
    const finalisedEntries = [];

    const modeIdx = [
        StatisticV2Mode.EveryChangeMin,
        StatisticV2Mode.EveryChange,
        StatisticV2Mode.IntervalMin5,
        StatisticV2Mode.IntervalMin10,
        StatisticV2Mode.IntervalMin30,
        StatisticV2Mode.IntervalMin60,
        StatisticV2Mode.IntervalMin15
    ].indexOf(typeof usedMode === 'number' ? usedMode : stats.mode);
    const unit = ["minute", "minute", "minute", "minute", "minute", "hour", "minute"][modeIdx];
    const factor = [1, 1, 5, 10, 30, 1, 15][modeIdx];

    let momentTs =  ActiveMSComponent.getMomentFromUtcTimestamp(startTs),
        endMomentTs = ActiveMSComponent.getMomentFromUtcTimestamp(endTs),
        currDpMomentTs, lastDpValues;

    const fillEmtpyDp = (momentTs) => {
        const entry = {
            ts: momentTs.unix(),
            values: []
        }
        for(let y = 0; y < depth; y++) {entry.values.push(0);}
        finalisedEntries.push(entry);
    }

    /**
     * Used for extending actual data (e.g. every change)
     * @param momentTs
     */
    const fillLastValuesDp = (momentTs) => {
        const entry = {
            ts: momentTs.unix(),
            values: []
        }
        entry.values = [...lastDpValues];
        finalisedEntries.push(entry);
    }

    const incrementMomentTs = (momentTs, dpTs) => {

        if (dpTs >= momentTs.unix()) {
            momentTs.add(factor, unit);
        }
    }

    workingData.forEach((dp, idx) => {
        // fill gap between last dp timestamp and this one
        currDpMomentTs = ActiveMSComponent.getMomentFromUtcTimestamp(dp.ts);
        while (currDpMomentTs.isAfter(momentTs, unit)) {
            if (lastDpValues) {
                fillLastValuesDp(momentTs);
            } else {
                fillEmtpyDp(momentTs);
            }
            incrementMomentTs(momentTs, finalisedEntries[finalisedEntries.length - 1].ts);
        }
        // add the current timestamp
        finalisedEntries.push(dp);
        lastDpValues = [...dp.values];
        incrementMomentTs(momentTs, dp.ts);
    });

    // fill up until the end of the range
    while (!momentTs.isAfter(endMomentTs, unit)) {
        fillEmtpyDp(momentTs);
        incrementMomentTs(momentTs, finalisedEntries[finalisedEntries.length - 1].ts);
    }

    //printDps("extendActualStatsData", "finalisedEntries:", finalisedEntries);
    return finalisedEntries;
}

export default ({usedMode, stats, startTs, endTs, viewType, newExtend = false}) => {
    const extendedStatsArr = useMemo(() => {
        if(!stats || !stats.data || !Array.isArray(stats.data) || stats.data.length === 0 || viewType !== DateViewType.Live) {
            if(stats && stats.data) {
                return extendNonActualData({stats, startTs, endTs, viewType});
            } else {
                return stats;
            }
        } else if (newExtend) {
            // used e.g. in the storageLevel graph
            return extendActualStatsData({usedMode, stats, startTs, endTs, viewType});
        }
        const modeIdx = [
            StatisticV2Mode.EveryChangeMin,
            StatisticV2Mode.IntervalMin5,
            StatisticV2Mode.IntervalMin10,
            StatisticV2Mode.IntervalMin30,
            StatisticV2Mode.IntervalMin60,
            StatisticV2Mode.IntervalMin15
        ].indexOf(typeof usedMode === 'number' ? usedMode : stats.mode);
        if(modeIdx === -1) {
            return stats.data;
        }
        const interval = [60, 300, 600, 1800, 3600, 900][modeIdx];

        const depth = stats.data[0].values.length;
    
        const statsObject = {}
        stats.data.forEach(stat => {if(stat) {statsObject[stat.ts] = stat}});
    
        let currentTs = startTs - interval;
        let currentValues = stats.data[0].values;
        const firstTs = stats.data[0].ts;
        const lastTs = stats.data[stats.data.length - 1].ts;
        while(currentTs < firstTs) {
            currentTs += interval;

            if(!statsObject[currentTs]) {
                statsObject[currentTs] = {
                    ts: currentTs,
                    values: [],
                }
                for(let i = 0; i < depth; i++) {statsObject[currentTs].values.push(0);}
            }
        }
        while(currentTs <= lastTs) {
            if(!statsObject[currentTs]) {
                statsObject[currentTs] = {
                    ts: currentTs,
                    values: [].concat(currentValues),
                }
            }

            currentTs += interval;
        }
        while(currentTs < endTs - interval) {
            currentTs += interval;
    
            if(!statsObject[currentTs]) {
                statsObject[currentTs] = {
                    ts: currentTs,
                    values: [],
                }
                for(let i = 0; i < depth; i++) {statsObject[currentTs].values.push(0);}
            }
        }
    
        const extendedStatsArr = Object.values(statsObject);
        extendedStatsArr.sort((a, b) => a.ts - b.ts);
        return extendedStatsArr;
    }, [stats, startTs, endTs, viewType])

    return extendedStatsArr;
}
