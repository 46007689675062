'use strict';

define("IntercomControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class IntercomCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [],
                isActive,
                subControlUuids = Object.keys(control.subControls);

            for (var keyIdx = 0; keyIdx < subControlUuids.length; keyIdx++) {
                var uuidAction = subControlUuids[keyIdx],
                    subControl = control.subControls[uuidAction],
                    subControlId = subControl.uuidAction.split("/")[1];

                if (states.hasOwnProperty('outputStates')) {
                    // TODO-goelzda Use it once implemented!
                    isActive = states.outputStates[subControl.uuidAction];
                }

                cmds.push(this.createCommand(subControl.name, this._createCommandObjFor(subControlId), Icon.PUSHBUTTON));
            }

            return cmds;
        }

        _createCommandObjFor(subControlId) {
            return this.createCmdObj(Commands.format(Commands.INTERCOM.OUTPUT_ON, subControlId), Commands.format(Commands.INTERCOM.OUTPUT_OFF, subControlId), Feature.INTERCOM_OUTPUT_PULSE ? Commands.format(Commands.INTERCOM.OUTPUT_PULSE, subControlId) : null, null, Commands.format(Commands.INTERCOM.OUTPUT_ON, subControlId));
        }

    };
});
