'use strict';

define("MediaClientControl", [
    "Control",
    "IconLib"
], function (
    Control,
    {
        default: Icons
    }
) {
    return class MediaClientControl extends Control {
        constructor() {
            super(...arguments);
        }

        getCommandSrcRequirePath() {
            return 'MediaClientControlCommandSrc';
        }

        getButton0() {
            return {
                iconSrc: Icon.PLUS,
                reactIcon: Icons.VolPlus,
                command: this.commandSrc.getVolumeUpCommand(this).cmd
            };
        }

        getButton1() {
            return {
                iconSrc: Icon.MINUS,
                reactIcon: Icons.VolMinus,
                command: this.commandSrc.getVolumeDownCommand(this).cmd
            };
        }

    };
});
