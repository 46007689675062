'use strict';

class InfoScreen extends GUI.Screen {
    //region Static
    static Template = function () {
        var getTemplate = function getTemplate(displayBundle) {
            var actionIndex = 0;
            var featureHtml;
            var html = '<div class="info-screen__scrollable">';
            var featureArr;
            html += '<div class="scrollable__title">' + displayBundle.title + '</div>';
            html += '<div class="scrollable__bundleTitle">' + displayBundle.bundleHeading + '</div>';
            displayBundle.features.forEach(function (feature) {
                featureArr = _getFeature(feature, actionIndex);
                featureHtml = featureArr[0];
                actionIndex = featureArr[1];
                html += featureHtml;
            });
            html += '</div><div class="info-screen__btn-container"></div>';
            return $(html);
        };

        var getButton = function getButton(title, index) {
            var identifier = "button-" + index;
            return $('<button id="' + identifier + '" class="feature__button text-overflow-ellipsis clickable" type="button">' + title + '</button>');
        };

        var getSubmitButton = function getSubmitButton(title) {
            return $('<button class="btn-container__btn buttons__button text-overflow-ellipsis clickable" type="button">' + title + '</button>');
        };

        var _getFeature = function _getFeature(feature, actionIndex) {
            var heading = feature.featureHeading === "" ? feature.featureHeading : _(feature.featureHeading);
            var html = "",
                compHtml;
            var compArr;
            html += '<div class="scrollable__feature"><div class="feature__heading">' + heading + '</div>';
            feature.components.forEach(function (component) {
                compArr = _getComponent(component, actionIndex);
                compHtml = compArr[0];
                actionIndex = compArr[1];
                html += compHtml;
            });
            html += '</div>';
            return [html, actionIndex];
        };

        var _getComponent = function _getComponent(component, actionIndex) {
            var text,
                linkText,
                html = "";

            if (typeof component === "string") {
                text = component === "" ? component : _(component);
                html += '<div class="feature__text">' + text + '</div>';
            } else {
                switch (component.type) {
                    case "image":
                        html += '<img class="feature__image" src="' + component.src + '"/>';
                        break;

                    case "hyperlink":
                        linkText = component.text === "" ? component.text : _(component.text);
                        html += '<div class="feature__text"><a href="' + component.href + '">' + linkText + '</a></div>';
                        break;

                    case "action":
                        html += '<div class="feature__action" id="action-' + actionIndex + '"></div>';
                        actionIndex++;
                        break;

                    case "list":
                        html += '<div class="feature__list"><ul>';
                        component.entries.forEach(function (entry) {
                            html += '<li class="list__entry">' + _(entry) + '</li>';
                        });
                        html += '</ul></div>';

                    default:
                        break;
                }
            }

            return [html, actionIndex];
        };

        return {
            getTemplate: getTemplate,
            getButton: getButton,
            getSubmitButton: getSubmitButton
        };
    }(); //endregion Static

    constructor(displayBundle) {
        super($('<div />'));
        this.featureBundle = displayBundle;
        this.actions = []; //Search Components for actions

        displayBundle.features.forEach(function (feature) {
            feature.components.forEach(function (component) {
                if (component.hasOwnProperty("type") && component.type === "action") {
                    this.actions.push(component);
                }
            }.bind(this));
        }.bind(this));
    }

    viewDidLoad() {
        super.viewDidLoad(...arguments);
        var buttonElm, button, submitButtonElm, submitButton;
        this.element.append(InfoScreen.Template.getTemplate(this.featureBundle)); //Create Buttons for action components of features

        this.elements.buttons = [];
        this.actions.forEach(function (action, index) {
            this.elements.buttonContainer = this.element.find('#action-' + index);
            buttonElm = InfoScreen.Template.getButton(_(action.text), index);
            button = new GUI.LxButton(this, buttonElm);
            this.elements.buttons.push(button);
            this.appendSubview(button, this.elements.buttonContainer);
        }.bind(this)); //Add submit button

        this.elements.submitButton = {};
        this.elements.submitButtonContainer = this.element.find('.info-screen__btn-container');
        submitButtonElm = InfoScreen.Template.getSubmitButton(_("infoscreen.start"));
        submitButton = new GUI.LxButton(this, submitButtonElm);
        this.elements.submitButton = submitButton;
        this.appendSubview(submitButton, this.elements.submitButtonContainer);
    }

    viewDidAppear() {
        super.viewDidAppear(...arguments); //Create event listeners for buttons

        this.elements.buttons.forEach(function (button, i) {
            button.onButtonTapped = this._buttonTapped.bind(this, i);
        }.bind(this));
        this.elements.submitButton.onButtonTapped = this._submitButtonTapped.bind(this);
    }

    viewWillDisappear() {
        InfoScreenComp.persistFeatures(this.featureBundle.featureKeys);
        super.viewWillDisappear(...arguments);
    }

    getAnimation() {
        return AnimationType.MODAL;
    }

    getURL() {
        return "InfoScreen";
    }

    _buttonTapped(index) {
        var screen = ScreenState[this.actions[index]["screen"]]; //Navigate to screen defined in action component of a feature

        if (screen) {
            NavigationComp.showState(screen);
        }
    }

    _submitButtonTapped() {
        this.ViewController.navigateBack();
    }

}

GUI.InfoScreen = InfoScreen;
