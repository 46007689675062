'use strict';

import TrackerControlContent from "./content/controlContent";

define("TrackerControl", ["Control"], function (Control) {
    class TrackerControl extends Control {
        //region Getter
        get showLatestDate() {
            return this._showLatestDate;
        } //endregion Getter


        //region Setter
        set showLatestDate(val) {
            this._showLatestDate = val;
        } //endregion Setter


        constructor() {
            super(...arguments);
            this._showLatestDate = true;
        }

        getReactControlContent() {
            return TrackerControlContent;
        }
    }

    TrackerControl.State = {
        ENTRIES: "trackerEntries"
    }
    return TrackerControl;
});
