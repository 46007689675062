'use strict';
/*
Options:
 overscroll             boolean
 noScroll               boolean, if set to true, it will disable scrolling
 pageControl            boolean
 retainPageControlSize  the space on top and bottom of pages is applied, even if only 1 page is given

 */

window.GUI = function (GUI) {
    class LxPageView extends GUI.View {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return $('<div class="lx-page-view">' + '   <div class="lx-page-view__pages" />' + '</div>');
            };

            var createPage = function createPage(content) {
                return $('<div class="pages__page" />').append(content);
            };

            var createPageControl = function createPageControl(pages) {
                var returnElement = '<div class="page-control">';
                returnElement += '<div class="page-control__left" />';
                returnElement += '<div class="page-control__right" />';

                for (var i = 0; i < pages.length; i++) {
                    returnElement += '<div id="' + i + '" class="page-control__dot" />';
                }

                returnElement += '</div>';
                return $(returnElement);
            };

            return {
                getTemplate: getTemplate,
                createPage: createPage,
                createPageControl: createPageControl
            };
        }(); //endregion Static

        constructor(pages, progressCallback, animationEndCallback, options) {
            super(LxPageView.Template.getTemplate());
            Debug.GUI.LxPageView && console.log(this.name, "ctor");
            this.eventHandlers = [];
            this._pages = pages;
            this.pages = [];
            this.progressCallback = progressCallback;
            this.animationEndCallback = animationEndCallback;
            this.options = options || {};
            this.activePageIndex = 0;
            this.currentleft = 0;
        }

        viewDidLoad() {
            var prms = super.viewDidLoad(...arguments);
            Debug.GUI.LxPageView && console.log(this.name, "viewDidLoad");
            this.pageContainer = this.element.children(".lx-page-view__pages");
            this.prependPage.apply(this, this._pages);
            return prms;
        }

        viewDidAppear() {
            var prms = super.viewDidAppear(...arguments);
            Debug.GUI.LxPageView && console.log(this.name, "viewDidAppear");

            if (!this.options.noScroll && this.pages.length > 1) {
                Debug.GUI.LxPageView && console.log(this.name, "    register hammer event handlers");
                var swipeOptions = {
                    swipeVelocityX: 0.1
                };
                var dragOptions = {
                    dragLockToAxis: true,
                    dragLockMinDistance: 0
                };
                if (!this.options.hammerDisabled) {
                    this.eventHandlers.push(Hammer(this.element[0]).on('drag', this._onDrag.bind(this)));
                    this.eventHandlers.push(Hammer(this.element[0], dragOptions).on('dragleft dragright', this._onDragHorizontal.bind(this)));
                    this.eventHandlers.push(Hammer(this.element[0], swipeOptions).on('swipeleft', this._onSwipeLeft.bind(this)));
                    this.eventHandlers.push(Hammer(this.element[0], swipeOptions).on('swiperight', this._onSwipeRight.bind(this)));
                    this.eventHandlers.push(Hammer(this.element[0], dragOptions).on('dragend', this._onDragEnd.bind(this)));
                }

            } // workaround for bug when navigating forward/back to a pageView

            /*var leftOffset = -(this.activePageIndex * 100);
            this.pageContainer.css({
                transform: "translateX(" + leftOffset + "%)"
            });
            this._currentLeftOffset = leftOffset;*/


            this._elementWidth = this.element.width(); // TODO update on resize

            this._updatePageControl();

            return prms.then(function () {
                this.pageContainer.children().each(function (idx, page) {
                    $(page).toggleClass('selected', idx === this.activePageIndex);
                }.bind(this));
            }.bind(this));
        }

        viewDidDisappear() {
            Debug.GUI.LxPageView && console.log(this.name, "viewDidDisappear");

            for (var i = 0; i < this.eventHandlers.length; i++) {
                this.eventHandlers[i].dispose();
            }

            this.eventHandlers = [];
            return super.viewDidDisappear(...arguments);
        }

        destroy() {
            Debug.GUI.LxPageView && console.log(this.name, "destroy");

            for (var i = 0; i < this.eventHandlers.length; i++) {
                this.eventHandlers[i].dispose();
            }

            return super.destroy();
        }

        disableUserInteraction(disable) {
            this.userInteractionDisabled = disable;
        }

        getActivePageIndex() {
            Debug.GUI.LxPageView && console.log(this.name, "getActivePageIndex");
            return this.activePageIndex;
        }

        setActivePage(pageNumber) {
            Debug.GUI.LxPageView && console.log(this.name, "setActivePage", pageNumber);

            if (pageNumber === this.activePageIndex) {
                return;
            } else if (pageNumber < 0 || pageNumber >= this.pages.length) {
                Debug.GUI.LxPageView && console.info("page " + pageNumber + " out of bounds..");
                return;
            }

            this._animateToPage(pageNumber, 0, pageNumber > this.activePageIndex ? Hammer.DIRECTION_LEFT : Hammer.DIRECTION_RIGHT, false);
        }

        prependPage() {
            var newPages = Array.from(arguments).reverse();
            this.pages.unshift.apply(this.pages, newPages);
            newPages.forEach(function (page) {
                this.pageContainer.prepend(LxPageView.Template.createPage(page));
            }.bind(this));
            this.onPageUpdateFinished();
        }

        appendPage() {
            var newPages = Array.from(arguments);
            this.pages.push.apply(this.pages, newPages);
            newPages.forEach(function (page) {
                this.pageContainer.append(LxPageView.Template.createPage(page));
            }.bind(this));
            this.onPageUpdateFinished();
        }

        onPageUpdateFinished() {
            this.pageContainer.width(this._getPageWidth() * this.pages.length + "%");

            this._checkPageControl();

            this.didSnapToIndex(this.activePageIndex);
        }

        /**
         * Returns the width of a page in percent
         * @return {number}
         * @private
         */
        _getPageWidth() {
            return 100;
        }

        _checkPageControl() {
            if (this.options.pageControl || this.options.retainPageControlSize) {
                this.element.addClass("lx-page-view--with-page-control");

                if (this.pages.length > 1) {
                    this._addPageControl();
                }
            }
        }

        _onDragHorizontal(e) {
            Debug.GUI.LxPageView && console.log(this.name, "_onDragHorizontal");

            if (this.userInteractionDisabled) {
                Debug.GUI.LxPageView && console.log(this.name, "userInteraction is disabled!");
                return;
            }

            this._dragging = true;
            var currentXDelta = e.gesture.deltaX / this._elementWidth * 100;
            currentXDelta = currentXDelta / this.pages.length; //console.log("currentXDelta " + currentXDelta);

            var leftBase = -(100 / this.pages.length * this.activePageIndex); //console.log("leftBase " + leftBase);

            var leftOffset = leftBase + currentXDelta; // handling of overscroll (like iOS)
            // TODO implement overscroll properly again

            /*console.log("leftOffset" + leftOffset);
             console.log("currentXDelta" + currentXDelta * this.pages.length);
             if (!this.options.overscroll) {
             leftOffset = leftBase;
             } else {
             var percent = currentXDelta * this.pages.length;
             console.log("percent " + percent);
             if (leftOffset > 0) {
             // overflow to left
             console.log("overflow to left " + (1 - percent / 100));
             leftOffset = leftBase + (currentXDelta * (1 - percent / 100));
             } else if (leftOffset < -(100 - 100 / this.pages.length)) {
             // overflow to right
             console.log("overflow to right " + (percent / 100));
             leftOffset = leftBase + (currentXDelta * (percent / 100));
             }
             }*/

            /*var overscrollPercentOfWidth = Math.abs(currentXDelta) / this.element.width() / 1.5; // take 1.5, looks better
             if (leftOffset > 0) {
             if (!this.options.overscroll) {
             leftOffset = 0;
             } else {
             leftOffset = leftBase + Math.max(0, currentXDelta * (1 - overscrollPercentOfWidth)); // not negative values!
             }
             } else if (leftOffset < -(this.element.width() * (this.pages.length - 1))) {
             if (!this.options.overscroll) {
             leftOffset = -(this.element.width() * (this.pages.length - 1));
             } else {
             leftOffset = leftBase + currentXDelta * (1 - overscrollPercentOfWidth);
             }
             }*/

            /*this.pageContainer.css({
             transform: "translateX(" + leftOffset + "%)"
             });*/

            this._currentLeftOffset = leftOffset; // left offset must be saved, to guarantee a smooth animation (from last offset to new offset...)

            this.isAnimating = false; // set to false (eg. when swiping and then starting to drag, then release -> would hang!)

            this.pageContainer.velocity("stop").velocity({
                translateZ: 0,
                // Force HA by animating a 3D property
                translateX: leftOffset + "%"
            }, {
                duration: 0
            }); //this._calcPercentage(leftOffset, false, e.gesture.interimDirection); // TODO check weather
            //this._calcPagePercent(Math.abs(currentXDelta), e.gesture.direction);
        }

        _onDrag(e) {
            Debug.GUI.LxPageView && console.log(this.name, "_onDrag");

            if (this.userInteractionDisabled) {
                Debug.GUI.LxPageView && console.log(this.name, "userInteraction is disabled!");
                return;
            }

            if (this._dragging) {
                // prevent browser scrolling
                e.preventDefault();
                e.stopPropagation();
                e.gesture.preventDefault();
                e.gesture.stopPropagation(); // if we started dragging horizontally, but then dragging up/down, forward it an handle normally..

                if (e.gesture.direction !== Hammer.DIRECTION_RIGHT && e.gesture.direction !== Hammer.DIRECTION_LEFT) {
                    this._onDragHorizontal(e);
                }
            }
        }

        _onSwipeLeft() {
            Debug.GUI.LxPageView && console.log(this.name, "_onSwipeLeft");

            if (this.userInteractionDisabled) {
                Debug.GUI.LxPageView && console.log(this.name, "userInteraction is disabled!");
                return;
            }

            this.setActivePage(this.activePageIndex + 1);
        }

        _onSwipeRight() {
            Debug.GUI.LxPageView && console.log(this.name, "_onSwipeRight");

            if (this.userInteractionDisabled) {
                Debug.GUI.LxPageView && console.log(this.name, "userInteraction is disabled!");
                return;
            }

            this.setActivePage(this.activePageIndex - 1);
        }

        _onDragEnd(e) {
            Debug.GUI.LxPageView && console.log(this.name, "_onDragEnd");

            if (this.userInteractionDisabled) {
                Debug.GUI.LxPageView && console.log(this.name, "userInteraction is disabled!");
                return;
            }
            /*if (e.gesture.direction !== Hammer.DIRECTION_LEFT &&
             e.gesture.direction !== Hammer.DIRECTION_RIGHT) {
             return;
             }*/


            if (this._dragging) {
                var direction,
                    directionChanged = false,
                    tmpIndex = this.activePageIndex;
                var viewWidthHalf = this.element.width() / 2;

                if (e.gesture.direction === Hammer.DIRECTION_LEFT) {
                    if (Math.abs(e.gesture.deltaX) < viewWidthHalf || this.activePageIndex === this.pages.length - 1) {
                        //console.log("snap back to view");
                        direction = Hammer.DIRECTION_RIGHT;
                        directionChanged = true;
                    } else {
                        //console.log("snap to next view");
                        direction = Hammer.DIRECTION_LEFT;
                        tmpIndex++;
                    }
                } else if (e.gesture.direction === Hammer.DIRECTION_RIGHT) {
                    if (Math.abs(e.gesture.deltaX) < viewWidthHalf || this.activePageIndex === 0) {
                        //console.log("snap back to view");
                        direction = Hammer.DIRECTION_LEFT;
                        directionChanged = true;
                    } else {
                        //console.log("snap to previous view");
                        direction = Hammer.DIRECTION_RIGHT;
                        tmpIndex--;
                    }
                }

                this._animateToPage(tmpIndex, e.gesture.deltaX, direction, directionChanged);
            }

            this._dragging = false;
        }

        _animateToPage(index, currentXDelta, direction, directionChanged) {
            Debug.GUI.LxPageView && console.log(this.name, "_animateToPage " + index);

            if (Debug._CYPRESS_TESTS_ACTIVE) {
                this.pageContainer.children().each(function (idx, page) {
                    $(page).toggleClass('selected', idx === index);
                });
            }

            if (!this.isAnimating) {
                this.isAnimating = true;
                index = Math.max(0, Math.min(index, this.pages.length - 1)); // starting from 0!

                /*if (directionChanged) {
                 var restOffset = this.element.width() - currentXDelta;
                 }
                  console.log("currentXDelta", currentXDelta);
                 console.log("direction", direction);
                 // calc rest width (rest of animation) and multiply it with the percentage of the animation
                 //var restWidth = this.element.width() - Math.abs(currentXOffset);*/

                var leftOffset = -(100 / this.pages.length * index);
                this.pageContainer.velocity("stop").velocity({
                    translateZ: 0,
                    // Force HA by animating a 3D property
                    translateX: [leftOffset + "%", this._currentLeftOffset + "%"]
                }, {
                    duration: this.isVisible() ? 200 : 0,
                    progress: function (el, percent) {
                        var translate = el[0].style.transform;

                        if (translate === undefined) {
                            translate = el[0].style.webkitTransform;
                        }

                        var translateX = translate.split(" ")[0];
                        var translateXValue = translateX.replace("translateX(", "").replace("px)", "");

                        this._calcPercentage(parseFloat(translateXValue), true, direction);

                        var offset = currentXDelta * (percent * 100); //this._calcPagePercent(offset, direction);
                    }.bind(this),
                    complete: function () {
                        this.isAnimating = false;
                        this.activePageIndex = index;
                        this.didSnapToIndex(this.activePageIndex);

                        this._updatePageControl();
                    }.bind(this)
                });
                this._currentLeftOffset = leftOffset;
            }
        }

        _calcPercentage(currentXOffset) {
            var percentage = 100 * ((Math.abs(currentXOffset) - 100 / this.pages.length * this.activePageIndex) / (100 / this.pages.length)); //currentXOffset = (currentXOffset * -1) - (this.activePageIndex * this.element.width());
            //var percentage = currentXOffset / this.element.width() * 100;

            this.progressCallback && this.progressCallback(percentage, this.activePageIndex);
        }

        _addPageControl() {
            this.pageControl = LxPageView.Template.createPageControl(this.pages);
            this.element.append(this.pageControl);
            var leftBtn = new GUI.LxRippleButton(this.pageControl.find(".page-control__left"));
            leftBtn.setRippleEnabled(false);
            this.addToHandledSubviews(leftBtn);
            leftBtn.on(GUI.LxRippleButton.CLICK_EVENT, function () {
                this.setActivePage(this.activePageIndex - 1);
            }.bind(this));
            var rightBtn = new GUI.LxRippleButton(this.pageControl.find(".page-control__right"));
            rightBtn.setRippleEnabled(false);
            this.addToHandledSubviews(rightBtn);
            rightBtn.on(GUI.LxRippleButton.CLICK_EVENT, function () {
                this.setActivePage(this.activePageIndex + 1);
            }.bind(this));
        }

        _updatePageControl() {
            if (!this.pageControl) {
                return;
            }

            Debug.GUI.LxPageView && console.log(this.name, "_updatePageControl");

            if (this._previousActivePageIdx === this.activePageIndex && this.activePageIndex !== 0) {
                Debug.GUI.LxPageView && console.log(this.name, "    bail out");
                return;
            }

            this._previousActivePageIdx = this.activePageIndex;
            this.pageControl.find(".page-control__dot--active").removeClass("page-control__dot--active");
            var activePageCtrl = this.pageControl.find("#" + this.activePageIndex);
            activePageCtrl.addClass("page-control__dot--active"); // update the left/right btn

            var width = this.pageControl.width(),
                pageCtrlCenter = activePageCtrl[0].offsetLeft + activePageCtrl.width() / 2,
                leftWidth = pageCtrlCenter,
                rightWidth = width - leftWidth;
            this.pageControl.find(".page-control__left").css("width", leftWidth + "px");
            this.pageControl.find(".page-control__right").css("width", rightWidth + "px");
        }

        didSnapToIndex(idx) {
            this.animationEndCallback && this.animationEndCallback(this.activePageIndex);
        }

    }

    GUI.LxPageView = LxPageView;
    return GUI;
}(window.GUI || {});

GUI.LxPageView.direction = {
    LEFT: "left",
    RIGHT: "right"
};
