'use strict';

define("IRoomControl", [ "Control", 'IRoomControlEnums', 'LxComponents', 'GlobalStyles' ], function (Control, IRoomControlEnums, {
    IrcTempDisplay
}, {
    default: globalStyles
}) {
    return class IRoomControl extends Control {
        constructor() {
            super(...arguments);
        }

        /**
         * If true, controls of this type MAY use the rooms name instead of its own. E.g. this is important for light
         * controllers, heating controllers and other room based controls.
         */
        controlTypeUsesRoomName() {
            return true;
        }

        getIcon() {
            if (this.isGrouped()) {
                return this.getCentralIcon();
            }

            return this.getStates().stateIcon;
        }

        get reactIcon() {
            if (this.isGrouped()) {
                return null;
            }
            return IrcTempDisplay;
        }

        isHeating(controllerMode) {
            return controllerMode === IRoomControlEnums.IRCMode.FULL_AUTO_HEATING || controllerMode === IRoomControlEnums.IRCMode.AUTO_HEATING || controllerMode === IRoomControlEnums.IRCMode.MANUAL_HEATING;
        }

        isCooling(controllerMode) {
            return controllerMode === IRoomControlEnums.IRCMode.FULL_AUTO_COOLING || controllerMode === IRoomControlEnums.IRCMode.AUTO_COOLING || controllerMode === IRoomControlEnums.IRCMode.MANUAL_COOLING;
        }

        getNameOfIRCMode(modeNr) {
            switch (modeNr) {
                case IRoomControlEnums.IRCMode.FULL_AUTO:
                    return _("controls.irc.mode.full-autopilot.general");

                case IRoomControlEnums.IRCMode.FULL_AUTO_HEATING:
                    return _("controls.irc.mode.full-autopilot.heat");

                case IRoomControlEnums.IRCMode.FULL_AUTO_COOLING:
                    return _("controls.irc.mode.full-autopilot.cool");

                case IRoomControlEnums.IRCMode.AUTO_HEATING:
                    return _("controls.irc.mode.autopilot.heat");

                case IRoomControlEnums.IRCMode.AUTO_COOLING:
                    return _("controls.irc.mode.autopilot.cool");

                case IRoomControlEnums.IRCMode.MANUAL_HEATING:
                    return _("controls.irc.mode.manual.heat");

                case IRoomControlEnums.IRCMode.MANUAL_COOLING:
                    return _("controls.irc.mode.manual.cool");
            }
        }

        getIconOfIRCTempMode(tempModeNr) {
            switch (tempModeNr) {
                case IRoomControlEnums.IRCTempMode.COMFORT_HEATING:
                case IRoomControlEnums.IRCTempMode.COMFORT_COOLING:
                    return Icon.IRC.Temp.COMFORT;

                case IRoomControlEnums.IRCTempMode.ECONOMY:
                    return Icon.IRC.Temp.ECONOMY;

                case IRoomControlEnums.IRCTempMode.PARTY:
                    return Icon.IRC.Temp.PARTY;

                case IRoomControlEnums.IRCTempMode.INCREASED_HEAT:
                    return Icon.IRC.Temp.INCREASED_HEAT;

                case IRoomControlEnums.IRCTempMode.EMPTY_HOUSE:
                    return Icon.IRC.Temp.EMPTY_HOUSE;

                case IRoomControlEnums.IRCTempMode.HEAT_PROTECTION:
                    return Icon.IRC.Temp.HEAT_PROTECTION;

                case IRoomControlEnums.IRCTempMode.MANUAL:
                    return Icon.IRC.Temp.MANUAL;
            }
        }

        getNameOfIRCTempMode(tempModeNr, short) {
            switch (parseInt(tempModeNr)) {
                case IRoomControlEnums.IRCTempMode.COMFORT_HEATING:
                case IRoomControlEnums.IRCTempMode.COMFORT_COOLING:
                    return short ? _("controls.irc.temp.comfort.short") : _("controls.irc.temp.comfort");

                case IRoomControlEnums.IRCTempMode.ECONOMY:
                    return short ? _("controls.irc.temp.economy.short") : _("controls.irc.temp.economy");

                case IRoomControlEnums.IRCTempMode.PARTY:
                    return _("controls.irc.temp.party");

                case IRoomControlEnums.IRCTempMode.INCREASED_HEAT:
                    return short ? _("controls.irc.temp.increased.short") : _("controls.irc.temp.increased");

                case IRoomControlEnums.IRCTempMode.EMPTY_HOUSE:
                    return short ? _("controls.irc.temp.empty-house.short") : _("controls.irc.temp.empty-house");

                case IRoomControlEnums.IRCTempMode.HEAT_PROTECTION:
                    return short ? _("controls.irc.temp.heat-protection.short") : _("controls.irc.temp.heat-protection");

                case IRoomControlEnums.IRCTempMode.MANUAL:
                    return _("controls.irc.temp.manual");

                default:
                    return "--unknown:" + tempModeNr + "--";
            }
        }

        getColorOfIRCTempMode(tempMode) {
            switch (tempMode) {
                case IRoomControlEnums.IRCTempMode.COMFORT_HEATING:
                case IRoomControlEnums.IRCTempMode.COMFORT_COOLING:
                    return globalStyles.colors.green;

                case IRoomControlEnums.IRCTempMode.ECONOMY:
                    return "#90a4ae";

                case IRoomControlEnums.IRCTempMode.PARTY:
                    return globalStyles.colors.purple;

                case IRoomControlEnums.IRCTempMode.INCREASED_HEAT:
                    return globalStyles.colors.red;

                case IRoomControlEnums.IRCTempMode.EMPTY_HOUSE:
                    return globalStyles.colors.blue;

                case IRoomControlEnums.IRCTempMode.HEAT_PROTECTION:
                    return globalStyles.colors.orange;

                case IRoomControlEnums.IRCTempMode.MANUAL:
                    return globalStyles.colors.green;
            }
        }

        getDaytimerUuidFromIRoomControl(isHeating) {
            var uuid;

            for (var subCtrlUuid in this.subControls) {
                if (this.subControls.hasOwnProperty(subCtrlUuid)) {
                    if (this.subControls[subCtrlUuid].details.isHeating && isHeating === true) {
                        uuid = subCtrlUuid;
                        break;
                    } else if (this.subControls[subCtrlUuid].details.isCooling && isHeating === false) {
                        uuid = subCtrlUuid;
                        break;
                    }
                }
            }

            return uuid;
        }

        specificCreateCmdText(cmdParts, cmdArguments) {
            var text = null,
                arg0,
                arg1;

            switch (cmdParts[1]) {
                case "mode":
                    // MODE: "mode/%i", // modeNr
                    arg0 = parseInt(cmdParts[2]);
                    text = this.getNameOfIRCMode(arg0);
                    break;

                case "service":
                    // SERVICE: "service/%i", // serviceNr
                    arg0 = parseInt(cmdParts[2]);

                    if (arg0 === 0) {
                        text = _("controls.irc.service.end");
                    } else {
                        text = _("controls.irc.mode.service") + " ";

                        if (arg0 === 1) {
                            text += _("controls.irc.service.no-heat-no-cool");
                        } else if (arg0 === 2) {
                            text += _("controls.irc.service.heat-no-cool");
                        } else if (arg0 === 3) {
                            text += _("controls.irc.service.no-heat-cool");
                        } else if (arg0 === 4) {
                            text += _("controls.irc.service.heat-cool");
                        }
                    }

                    break;

                case "starttimer":
                    // START_TIMER: "starttimer/%i/%i", // tempModeNr, time in minutes
                    arg0 = parseInt(cmdParts[2]);
                    arg0 = this.getNameOfIRCTempMode(arg0);
                    arg1 = parseInt(cmdParts[3]);
                    arg1 = LxDate.formatSecondsShort(arg1 * 60, true);
                    text = _("cmdtext.irc.starttimer", {
                        duration: arg1,
                        temp: arg0
                    });
                    break;

                case "stoptimer":
                    // CANCEL_TIMER: "stoptimer",
                    text = _("cmdtext.irc.stoptimer");
                    break;

                case "settemp":
                    // MANUAL_TEMP: "settemp/7/%f", // tempValue,   7 = IRoomControlEnums.IRCTempMode.MANUAL
                    // SET_TEMP_MODE: "settemp/%i/%f" // tempModeNr, value
                    arg0 = parseInt(cmdParts[2]);
                    arg0 = this.getNameOfIRCTempMode(arg0);
                    arg1 = parseFloat(cmdParts[3]);
                    arg1 = lxFormat("%.1f°", arg1);
                    text = _("cmdtext.irc.settemp", {
                        temp: arg0,
                        value: arg1
                    });
                    break;

                case "set":
                    text = _("cmdtext.irc.daytimer.heat.entries");
                    break;

                case "modeslist":
                    text = _("cmdtext.irc.daytimer.heat.modes");
                    break;

                case "setc":
                    text = _("cmdtext.irc.daytimer.cool.entries");
                    break;

                case "modeslistc":
                    text = _("cmdtext.irc.daytimer.cool.modes");
                    break;

                default:
                    console.error("IRoomControl", "Couldn't create a cmd for " + cmdParts.join("/"));
                    throw new Error("Command not supported in createCmd of IRC!");
                    break;
            }

            return text;
        }

        detectColorForStates(states) {
            var color = '';

            if (states.serviceMode !== IRoomControlEnums.ServiceMode.OFF) {
                color = globalStyles.colors.red;
            } else {
                color = globalStyles.colors.green;
            }

            return color;
        }

        getFriendlyValueName(event, operator) {
            switch (event.data.stateName) {
                case "tempActual":
                    return this.name + " " + event.data.stateTitle + " " + operator + " " + event.value.toString().replace(",", ".") + " °";

                case "openWindow":
                    return this.name + " " + event.data.stateTitle;

                default:
                    return "";
            }
        }

    };
});
