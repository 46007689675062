'use strict';

define(["AudioZoneV2ControlEnums", "MediaBrowserV2Base", "SpotifyAccountManager"], function (AudioZoneV2ControlEnums, MediaBrowserV2Base, SpotifyAccountManager) {
    class MediaBrowserV2Favorites extends MediaBrowserV2Base {
        //region Static
        static getCellFromContentTypeItem(item, details, contentType, isPlaylist, isBrowsable, actionClb, buttonTappedClb, vc, isInEditMode) {
            var baseCell = Controls.AudioZoneV2Control.MediaBrowserV2Base.getCellFromContentTypeItem.call(this, item, details, contentType, true, isBrowsable, actionClb, buttonTappedClb, vc, isInEditMode);

            if (item.hasOwnProperty(MusicServerEnum.Event.FILE_TYPE) && Object.values(MusicServerEnum.FAVORITE_TYPES).indexOf(item[MusicServerEnum.Event.FILE_TYPE]) !== -1) {
                switch (item[MusicServerEnum.Event.FILE_TYPE]) {
                    case MusicServerEnum.FAVORITE_TYPES.TUNEIN:
                    case MusicServerEnum.FAVORITE_TYPES.CUSTOM_STREAM:
                        baseCell.content.subtitle = _("media.radio-station");
                        break;

                    case MusicServerEnum.FAVORITE_TYPES.LMS_PLAYLIST:
                        baseCell.content.subtitle = _("media.playlist");
                        break;

                    case MusicServerEnum.FAVORITE_TYPES.LMS_LINEIN:
                        baseCell.content.subtitle = _("media.section.line-in");
                        break;

                    case MusicServerEnum.FAVORITE_TYPES.LIB_FOLDER:
                        baseCell.content.subtitle = _("media.library") + SEPARATOR_SYMBOL + _("media.library.folder");
                        break;

                    case MusicServerEnum.FAVORITE_TYPES.LIB_TRACK:
                        baseCell.content.subtitle = _("media.library") + SEPARATOR_SYMBOL + _("media.title");
                        break;

                    case MusicServerEnum.FAVORITE_TYPES.LIB_PLAYLIST:
                        baseCell.content.subtitle = _("media.library") + SEPARATOR_SYMBOL + _("media.playlist");
                        break;

                    case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_PLAYLIST:
                        baseCell.content.subtitle = item.owner || _("media.popup.spotify.title") + SEPARATOR_SYMBOL + _("media.playlist");
                        break;

                    case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_COLLECTION:
                        baseCell.content.subtitle = item.owner;
                        break;

                    case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_SHOW:
                        baseCell.content.subtitle = _("media.popup.spotify.title") + SEPARATOR_SYMBOL + _("media.podcast");
                        break;

                    case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_EPISODE:
                        baseCell.content.subtitle = _("media.popup.spotify.title") + SEPARATOR_SYMBOL + _("media.episode");
                        break;

                    case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_ALBUM:
                        baseCell.content.subtitle = _("media.popup.spotify.title") + SEPARATOR_SYMBOL + (item.artist || _("media.album"));
                        break;

                    case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_ARTIST:
                        baseCell.content.subtitle = _("media.popup.spotify.title") + SEPARATOR_SYMBOL + _("media.artist");
                        break;

                    case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_TRACK:
                        baseCell.content.subtitle = _("media.popup.spotify.title") + SEPARATOR_SYMBOL + _("media.title");
                        break;

                    default: // Just use the already defined (or not) subText

                }
            }

            return baseCell;
        }

        static getCellButtonIconSrc() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Base.getCellButtonIconSrc.apply(this, arguments);
        }

        static getCellButtonIconColor() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Base.getCellButtonIconColor.apply(this, arguments);
        }

        static getIconObjForItem(item, identifier) {
            var iconObj;

            if (item.type === MusicServerEnum.FAVORITE_TYPES.SPOTIFY_COLLECTION) {
                iconObj = Controls.AudioZoneV2Control.MediaBrowserV2Service.getIconObjForCollection.apply(this, arguments);
            } else {
                iconObj = Controls.AudioZoneV2Control.MediaBrowserV2Base.getIconObjForItem.apply(this, arguments);
                Object.keys(iconObj).forEach(function (key) {
                    var val = iconObj[key];

                    if (val === Icon.AudioZone.NEW.NOTE) {
                        iconObj[key] = Icon.AudioZone.NEW.STAR;
                    }
                });
            }

            return iconObj;
        }

        static getZoneFavoriteEditCellForFavAtIdx(zoneFav, details, idx, boundOnEdit, boundDidCheckCell) {
            var title = decodeURIComponent(AudioserverComp.getTitleSubtitleForItem(zoneFav).title);
            return {
                type: GUI.TableViewV2.CellType.CHECKABLE_BUTTON_MEDIA,
                content: {
                    title: title,
                    titleColor: Color.TEXT_SECONDARY_B,
                    subtitle: "ID " + zoneFav.id,
                    checkedIcon: Icon.Light.PLUS_SQUARE,
                    uncheckedIcon: Icon.Light.PLUS_SQUARE,
                    buttonSrc: Icon.SETTINGS_SMALL,
                    class: "editable-base-cell__add",
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE,
                    forceSelection: true,
                    selected: !!zoneFav.plus,
                    item: zoneFav,
                    details: details
                },
                buttonTapped: boundOnEdit,
                didCheckCell: boundDidCheckCell
            };
        }

        static getOpenFavoriteAction(control, item, showStateFn) {
            var action = null,
                showStateArgs = {
                    identifier: MusicServerEnum.ControlContentIdentifiers.ZONE_FAVORITES,
                    control: control,
                    lastSelectedItem: cloneObject(item)
                },
                isBrowsable = false,
                spotifyInfo;

            switch (item[MusicServerEnum.Event.FILE_TYPE]) {
                case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_PLAYLIST:
                case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_SHOW:
                case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_ALBUM:
                case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_COLLECTION:
                case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_ARTIST:
                    if (!SpotifyAccountManager.shared(control).isPrepared()) {
                        console.error(this.viewId || this.name, "Browsing spotify favorites may not work, not prepared!", getStackObj());
                        SpotifyAccountManager.shared(control).prepare().then(function () {
                            spotifyInfo = this._getCurrentSpotifyInfo(control);
                            showStateArgs.username = spotifyInfo.user;
                            showStateArgs.service = spotifyInfo.service;
                        }.bind(this));
                    } else {
                        spotifyInfo = this._getCurrentSpotifyInfo(control);
                        showStateArgs.username = spotifyInfo.user;
                        showStateArgs.service = spotifyInfo.service;
                    }

                    showStateArgs.mediaType = MusicServerEnum.MediaType.SERVICE;
                    showStateArgs.identifier = MusicServerEnum.Service.SPOTIFY;
                    showStateArgs.lastSelectedItem.contentType = MusicServerEnum.MediaContentType.SERVICE;
                    isBrowsable = true;
                    break;

                case MusicServerEnum.FAVORITE_TYPES.LIB_FOLDER:
                    showStateArgs.mediaType = MusicServerEnum.MediaType.LIBRARY;
                    showStateArgs.lastSelectedItem.contentType = MusicServerEnum.MediaContentType.LIBRARY;
                    isBrowsable = true;
                    break;

                case MusicServerEnum.FAVORITE_TYPES.LMS_PLAYLIST:
                    showStateArgs.mediaType = MusicServerEnum.MediaType.PLAYLIST;
                    showStateArgs.identifier = MusicServerEnum.Service.LMS;
                    showStateArgs.lastSelectedItem.contentType = MusicServerEnum.MediaContentType.PLAYLISTS;
                    isBrowsable = true;
                    break;

                case MusicServerEnum.FAVORITE_TYPES.LIB_PLAYLIST: // a locally stored playlist file.

                case MusicServerEnum.FAVORITE_TYPES.TUNEIN:
                case MusicServerEnum.FAVORITE_TYPES.LMS_LINEIN:
                case MusicServerEnum.FAVORITE_TYPES.LIB_TRACK:
                case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_TRACK:
                case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_EPISODE:
                    isBrowsable = false;
                    break;

                default:
                    break;
            }

            if (isBrowsable) {
                showStateArgs.lastSelectedItem.type = this._getFileTypeFromFavoriteType(item.type, item);
                showStateArgs.contentTypes = [showStateArgs.lastSelectedItem.contentType];
                showStateArgs.lastSelectedItem.id = showStateArgs.lastSelectedItem.audiopath;

                action = function handleOnFavoriteItemClicked(cell, contentType, section, row) {
                    showStateFn(Controls.AudioZoneV2Control.MediaBrowserV2Base.getScreenStateForItem(showStateArgs.lastSelectedItem), null, showStateArgs);
                }.bind(this);
            }

            return action;
        }

        static _getFileTypeFromFavoriteType(favType, item) {
            var fileType;

            switch (favType) {
                case MusicServerEnum.FAVORITE_TYPES.TUNEIN:
                    fileType = MusicServerEnum.FileType.UNDEFINED;
                    break;

                case MusicServerEnum.FAVORITE_TYPES.LMS_PLAYLIST:
                    fileType = MusicServerEnum.FileType.PLAYLIST_EDITABLE;
                    break;

                case MusicServerEnum.FAVORITE_TYPES.LMS_LINEIN:
                    fileType = MusicServerEnum.FileType.HW_INPUT;
                    break;

                case MusicServerEnum.FAVORITE_TYPES.LIB_FOLDER:
                    fileType = MusicServerEnum.FileType.LOCAL_DIR;
                    break;

                case MusicServerEnum.FAVORITE_TYPES.LIB_TRACK:
                    fileType = MusicServerEnum.FileType.LOCAL_FILE;
                    break;

                case MusicServerEnum.FAVORITE_TYPES.LIB_PLAYLIST:
                    fileType = MusicServerEnum.FileType.LOCAL_PLAYLIST;
                    break;

                case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_PLAYLIST:
                    if (item.hasOwnProperty("ownerId")) {
                        // added to AS on 2021.11.09
                        // cannot verify the ownership here, spotify user may not be known yet. Assume editable and verify
                        // when the context menu is opened via getActionsForCtxAndItem in mediaBrowser_Service-11.
                        fileType = MusicServerEnum.FileType.PLAYLIST_EDITABLE;
                    } else {
                        fileType = MusicServerEnum.FileType.PLAYLIST_BROWSABLE;
                    }

                    break;

                case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_COLLECTION:
                    fileType = MusicServerEnum.FileType.PLAYLIST_BROWSABLE;
                    break;

                case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_SHOW:
                    fileType = MusicServerEnum.FileType.PLAYLIST_BROWSABLE;
                    break;

                case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_ALBUM:
                    fileType = MusicServerEnum.FileType.PLAYLIST_BROWSABLE;
                    break;

                case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_ARTIST:
                    fileType = MusicServerEnum.FileType.PLAYLIST_BROWSABLE;
                    break;

                case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_TRACK:
                case MusicServerEnum.FAVORITE_TYPES.SPOTIFY_EPISODE:
                    fileType = MusicServerEnum.FileType.FAVORITE;
                    break;

                default:
                    fileType = MusicServerEnum.FileType.UNDEFINED;
                    break;
            }

            return fileType;
        }

        static _getCurrentSpotifyInfo(control) {
            var activeSpotifyUser = SpotifyAccountManager.shared(control).activeUser;
            return {
                user: SpotifyAccountManager.getUserUnique(activeSpotifyUser),
                service: activeSpotifyUser
            };
        } //endregion Static


        //region Getter
        get RIGHT_SIDE_BUTTON() {
            let baseVal = super.RIGHT_SIDE_BUTTON;
            baseVal.EDIT = "right-side__touch-area--edit";
            return baseVal;
        }

        get desc() {
            return _("favorites.title");
        } //endregion Getter


        constructor(details) {
            super(...arguments);
            SpotifyAccountManager.shared(this.control).prepare(); // required for browsing spotify favorites
        }

        getRightVCButtonIcon() {
            return Icon.Buttons.SETTINGS;
        }

        getRightVCButtonIconFn() {
            return this._editZoneFavorites.bind(this);
        }

        getMediaId() {
            return this.control.details.playerid;
        }

        titleBarActionRight(buttonId) {
            if (buttonId === this.RIGHT_SIDE_BUTTON.EDIT) {
                this._editZoneFavorites();
            } else {
                super.titleBarActionRight(...arguments);
            }
        }

        handleOnItemCellClicked(item, contentType, section, row) {
            console.log(this.viewId, "handleOnItemCellClicked", item);
            var action = this.constructor.getOpenFavoriteAction(this.control, item, this.ViewController.showState.bind(this.ViewController));

            if (action) {
                return action();
            } else {
                return this._playItem(item);
            }
        }

        getTableView() {
            return new GUI.CardView(this.tableViewDataSource, this.tableViewDelegate);
        }

        _editZoneFavorites() {
            this.ViewController.showState(AudioZoneV2ControlEnums.ScreenState.FAVORITE_EDIT, null, {
                control: this.control
            });
        }

        _getRightSideTitleBarButtons() {
            return [this._getRightSideTitleBarButtonElm(this.RIGHT_SIDE_BUTTON.EDIT, Icon.SETTINGS_SMALL)];
        }

    }

    Controls.AudioZoneV2Control.MediaBrowserV2Favorites = MediaBrowserV2Favorites;
    return Controls.AudioZoneV2Control.MediaBrowserV2Favorites;
});
