import {LxReactControl, LxReactPressable, LxReactTableView} from "LxComponents";
import {ScrollView, View} from "react-native-web";
import globalStyles from "GlobalStyles";
import React, {forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import useAmbientSettings from "../hooks/useAmbientSettings";
import AmbientUtils from "../AmbientUtils";
import Icons from "IconLib";
import AmbientTableviewHelper from "../AmbientTableviewHelper";
import {useNavigation} from "@react-navigation/native";
import DndSortableList from "../../LxReactTableView/SortableList";

const AmbientShortcutsView = memo(({editActive, navigateNested}) => {
    const settings = useAmbientSettings();
    const scrollViewRef = useRef();
    const paginatorRef = useRef();

    const dummySections = useMemo(() => {
        const calculatedSectionCount = Math.ceil(settings.quickAccessControls.length / 3);
        const safeSectionCount = Math.min(calculatedSectionCount, AmbientUtils.MAX_SECTIONS);
        return Array.from(Array(safeSectionCount));
    }, [settings.quickAccessControls && settings.quickAccessControls.length])

    const shortcuts = useMemo(() => {
        return AmbientUtils.getShortcuts(settings);
    }, [settings])


    const ControlSection = ({idx}) => {
        let controls = shortcuts.slice(idx * 3, idx * 3 + 3);

        return <View style={styles.controlSectionContainer}>
            {controls.map((control) => <View key={"shortcut-" + control.uuidAction} style={styles.controlBackground} >
                {getLxReactControl(control)}
            </View>)}
        </View>
    }

    const getLxReactControl = (control) => {
        let borderStyle = JSON.stringify({borderRadius: 4}),
            shouldShowGroupDetails;

        shouldShowGroupDetails = shortcuts.filter(shortcut => {
            return shortcut.getName() === control.getName() && shortcut.type === control.type;
        }).length >= 2;

        return <LxReactControl key={control.uuidAction} showGroupDetails={shouldShowGroupDetails} controlUuid={control.uuidAction} displayAsCell={true} isShortcut={true} borderStyle={borderStyle}/>

    }

    const handlePaginatorTap = useCallback((idx) => {
        scrollViewRef.current && scrollViewRef.current.scrollTo({
            y: idx * CONTROL_SECTION_HEIGHT,
            animated: true
        });
    }, [])

    const updateActivePage = useCallback(({nativeEvent}) => {
        const yOffset = nativeEvent.contentOffset.y;
        let expectedSection = Math.floor(yOffset / CONTROL_SECTION_HEIGHT);
        if (expectedSection < 0 ) {
            expectedSection = 0;
        }
        paginatorRef.current && paginatorRef.current.setFocusedSection(expectedSection);
    }, [])

    const RegularContent = () => {
        return <>
            <View style={styles.paginatorContainer}>
                {shortcuts.length <= 3 ? null : <Paginator ref={paginatorRef} sections={dummySections} onTap={handlePaginatorTap} />}
            </View>
            <ScrollView ref={scrollViewRef}
                        style={styles.controlsContainer}
                        pagingEnabled={true}
                        showsVerticalScrollIndicator={true}
                        scrollEventThrottle={20}
                        onScroll={updateActivePage}>
                {dummySections.map((item, index) => <ControlSection idx={index} key={"section-" + index}/>)}
            </ScrollView>
        </>;
    }

    const navigation = useNavigation();

    const EditContent =useCallback( () => {
        let shortcuts = AmbientUtils.getShortcuts(settings);
        let section = [...AmbientTableviewHelper.createShorcutsSections(shortcuts, true, navigation, navigateNested)];
        let addSection = AmbientTableviewHelper.addShortcutSection(shortcuts, true, navigation, navigateNested);

        let items = section[0].rows.map((cell, rowIdx) => {
            cell.type = GUI.TableViewV2.CellType.EDITABLE_CELL;

            cell.content = {
                title: cell.title,
                subtitle: cell.subTitle,
                leftIconSrc: Icons.REMOVE,
                leftIconColor: globalStyles.colors.red
            }
            return {
                id: rowIdx+1,
                ...window.LxCellReactMap[cell.type]({
                    cellObj: cell,
                    sectionIdx: section,
                    rowIdx
                })
            }
        })

        return <View style={{flexDirection: "column"}}>
            <DndSortableList onCellMove={onCellMove} content={items} onCellRemove={onAmbientCellRemove}/>
            <View style={[styles.controlBackground, {marginTop: 4, paddingRight: 0}]}>
                <LxReactTableView styles={{List: {paddingBottom: 0}}} tableContent={addSection}/>
            </View>
        </View>
    }, [editActive, settings.quickAccessControls.length])


    const onCellMove = useCallback((section, from, to) => {
        PersistenceComponent.updateAmbientModeSetting({
            quickAccessControls: [...settings.quickAccessControls.move(from, to)]
        })
    }, [settings.quickAccessControls])

    const onAmbientCellRemove = useCallback((sectionIdx, rowIdx) => {
        let newList = [...settings.quickAccessControls]
        newList.splice(rowIdx, 1);
        PersistenceComponent.updateAmbientModeSetting({
            quickAccessControls: newList
        })
    }, [settings.quickAccessControls])

    return <View style={styles.contentContainer}>
        {editActive ? <EditContent /> : <RegularContent />}
    </View>
});

const Paginator = forwardRef(({sections, onTap}, ref) => {
    const [focusedSection, setFocusedSection] = useState(0);
    useImperativeHandle(ref, () => {
        return {
            setFocusedSection
        }
    })

    return <>
        {sections.map((item, index) => <PaginatorButton idx={index} isActive={index === focusedSection} onTap={onTap} key={"paginator-" + index}/>)}
    </>
});

const PaginatorButton = ({idx, isActive, onTap}) => {
    return <LxReactPressable
        style={[styles.paginatorButton, idx > 0 && styles.paginatorButtonWithMargin, isActive && styles.activePaginatorButton]}
        onPress={onTap.bind(this, idx)}></LxReactPressable>
}

// region Constants
const CONTROL_HEIGHT = 76;
const CONTROL_MARGIN = globalStyles.spacings.gaps.smaller
const SECTION_MARGIN_BOTTOM = globalStyles.spacings.gaps.smaller / 2;
const CONTROL_SECTION_HEIGHT = 3 * CONTROL_HEIGHT + 2 * CONTROL_MARGIN + SECTION_MARGIN_BOTTOM
// endregion

const styles = {
    contentContainer: {
        flexDirection: "row",
        alignItems: "center",
        marginTop: globalStyles.spacings.gaps.bigger
    },
    paginatorContainer: {
        justifyContent: "center",
        left: -28,
        width: 0
    },
    paginatorButton: {
        borderRadius: "50%",
        width: globalStyles.sizes.icons.tiny,
        height: globalStyles.sizes.icons.tiny,
        backgroundColor: "rgba(61, 61, 61, 0.72)"
    },
    paginatorButtonWithMargin: {
        marginTop: globalStyles.spacings.gaps.verySmall
    },
    activePaginatorButton: {
        backgroundColor: globalStyles.colors.white
    },
    controlsContainer: {
        maxHeight: CONTROL_SECTION_HEIGHT
    },
    controlSectionContainer: {
        height: CONTROL_SECTION_HEIGHT,
        marginBottom: SECTION_MARGIN_BOTTOM
    },
    controlBackground: {
        ...globalStyles.customStyles.blurredBackground,
        marginBottom: CONTROL_MARGIN,
        paddingRight: globalStyles.spacings.gaps.small,
        width: "100%",
        borderRadius: 4,
        //minWidth: 400,
        maxWidth: 430
    }
}

export default AmbientShortcutsView;
