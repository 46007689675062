import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Weather17(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            <Path
                d="M3 16.2a4.79 4.79 0 012.44-4.158A7.26 7.26 0 0112.75 5a7.333 7.333 0 016.22 3.412A5.36 5.36 0 0120.47 8.2a5.28 5.28 0 014.944 3.37A4.793 4.793 0 0124.125 21H7.875A4.838 4.838 0 013 16.2z"
                fill="#d5e2e9"
                fillRule="evenodd"
            />
            <Path
                d="M15.08 23h3.178l-1.816 2.582H19l-6 5.415 1.869-3.582h-1.801z"
                fill="#f4cd27"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default Weather17
