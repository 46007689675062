import { useEffect, useRef, useMemo } from 'react'
import { Platform, findNodeHandle } from 'react-native'

function mergeRefs(refs) {
    return (value) => {
        refs.forEach((ref) => {
            if (typeof ref === "function") {
                ref(value);
            } else if (ref != null) {
                ref.current = value;
            }
        });
    };
}

export default function useDraggableScroll({
  outerRef,
  cursor = 'grab',
  vertical = true,
} = {}) {
  const ref = useRef(null)

  useEffect(
    function listeners() {
      if (Platform.OS !== 'web' || !ref.current) {
        return
      }
      const slider = (findNodeHandle(ref.current))
      if (!slider) {
        return
      }
      let isDragging = false
      let start = 0
      let scrollPos = 0

      const mouseDown = (e) => {
        isDragging = true
        start = (vertical ? e.pageY : e.pageX) - (vertical ? slider.offsetTop : slider.offsetLeft)
        scrollPos = (vertical ? slider.scrollTop : slider.scrollLeft)

        slider.style.cursor = cursor
      }
      const mouseLeave = () => {
        isDragging = false
      }

      const mouseUp = () => {
        isDragging = false
        slider.style.cursor = 'default'
      }

      const mouseMove = (e) => {
        if (!isDragging) return
        e.preventDefault()
        const pos = (vertical ? e.pageY : e.pageX) - (vertical ? slider.offsetTop : slider.offsetLeft)
        const walk = pos - start
        if(vertical) {
          slider.scrollTop = scrollPos - walk
        } else {
          slider.scrollLeft = scrollPos - walk
        }
      }

      slider.addEventListener('mousedown', mouseDown)
      slider.addEventListener('mouseleave', mouseLeave)
      slider.addEventListener('mouseup', mouseUp)
      slider.addEventListener('mousemove', mouseMove)

      return () => {
        slider.removeEventListener('mousedown', mouseDown)
        slider.removeEventListener('mouseleave', mouseLeave)
        slider.removeEventListener('mouseup', mouseUp)
        slider.removeEventListener('mousemove', mouseMove)
      }
    },
    [cursor]
  )

  const refs = useMemo(() => mergeRefs(outerRef ? [ref, outerRef] : [ref]), [
    ref,
    outerRef,
  ])

  return {
    refs,
  }
}