import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Default(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
            <Path d="M27 5H1v21h28V5h-2zm0 19H3V7h24v17zm-7-8l-4 3-6-7-5 10h20zm1-2a2 2 0 10-2-2 2 2 0 002 2z" />
        </Svg>
    )
}

export default Default
