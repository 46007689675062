'use strict';
/**
 * This screen is used to display search results collected by the search screen. This screen is capable of showing all
 * of them, while on the previous screen they are limited to the top x search results.
 */

define([], function () {
    return class SearchScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div />'));
            details = details || {};
            this.tableContent = details.content;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.setUpComfortUI();
            this.toggleComfortModeUI(true);
            this.setTitle(_("search.title"));
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);
        }

        getURL() {
            return "SearchResultScreen";
        }

        getAnimation() {
            return HD_APP ? AnimationType.HD_OVERLAY : AnimationType.PUSH_OVERLAP_LEFT;
        }

        styleForTableView() {
            return TableViewStyle.TRANSLUCENT;
        }

        didSelectCell(cell, section, row) {
            var content = this.tableContent[section].rows[row];

            if (content.action) {
                this.ViewController.dismissScreen(this);
                content.action();
            }
        }

    };
});
