'use strict';

define(['AutopilotUtility', 'AutopilotEnums', 'AutopilotCategoryScreen'], function (AutopilotUtility, AutopilotEnums) {
    var MAX_PREVIEW_EVENTS = 5;
    var Sections = {
        Times: 0,
        Categories: 1,
        OperatingModes: 2,
        Weather: 3
    };
    var SectionExpandedStates = {
        0: false,
        1: false,
        2: false,
        3: false
    };
    return class AutopilotEventScreen extends GUI.Screen {
        constructor() {
            super($('<div class="autopilot-event-screen" />'));
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.elements = {};
            this.tableView = new GUI.TableViewV2(this, this);
            this.appendSubview(this.tableView);
            var weatherStates = SandboxComponent.getStatesForUUID(WEATHER_UUID);
            this.hasWeatherService = weatherStates && weatherStates.states.hasValidData;

            this._updateEventTable();
        }

        getURL() {
            return "Autopilot/Events";
        }

        getAnimation() {
            return HD_APP ? AnimationType.PUSH_OVERLAP_LEFT : AnimationType.MODAL;
        }

        titleBarText() {
            return _("autopilot.rule.event.add");
        }

        titleBarAction() {
            this.ViewController.navigateBack();
        }

        _updateEventTable() {
            this.timeEvents = AutopilotUtility.getTimeEvents();
            this.weatherEvents = AutopilotUtility.getWeatherEvents();
            var supportedEventControls = cloneObject(AutopilotEnums.SupportedEventBlocks);

            if (!Feature.LIGHTV2_AUTO_EVENTS) {
                // the light v2 controller has not been supported as an event source since its introduction.
                supportedEventControls.splice(supportedEventControls.indexOf(ControlType.LIGHT_V2), 1);
            }

            this.categories = AutopilotUtility.getCategoriesFromControlTypes(supportedEventControls);
            this.operatingModes = AutopilotUtility.getOperatingModes(true);
            this.tableView.reload();
        }

        // TABLE VIEW DELEGATES
        styleForTableView(tableView) {
            return TableViewStyle.GROUPED;
        }

        numberOfSections() {
            var cntSections = Object.keys(Sections).length;

            if (!this.hasWeatherService) {
                return cntSections - 1;
            }

            return cntSections;
        }

        titleForHeaderInSection(section) {
            if (section === Sections.Times) {
                return _("autopilot.rule.event.times");
            } else if (section === Sections.Weather) {
                return _("weather");
            } else if (section === Sections.Categories) {
                return _("category");
            } else if (section === Sections.OperatingModes) {
                return _("pool.control.settings.operating-mode");
            }
        }

        imageForHeaderInSection(section) {
            if (section === Sections.Times) {
                return Icon.Autopilot.TIMES;
            } else if (section === Sections.Weather) {
                return Icon.Autopilot.WEATHER;
            } else if (section === Sections.Categories) {
                return Icon.Autopilot.CATEGORIES;
            } else if (section === Sections.OperatingModes) {
                return Icon.Autopilot.OPERATING_MODES;
            }
        }

        numberOfRowsInSection(section) {
            function _eventNumbersForSection(events) {
                if (SectionExpandedStates[section]) {
                    return events.length + 1;
                } else if (events.length <= MAX_PREVIEW_EVENTS + 1) {
                    // +1 because it makes no sense to show "show more" button for 1 more cell
                    return events.length;
                } else {
                    return MAX_PREVIEW_EVENTS + 1;
                }
            }

            if (section === Sections.Times) {
                return _eventNumbersForSection(this.timeEvents); //+1 for custom time
            } else if (section === Sections.Weather) {
                return _eventNumbersForSection(this.weatherEvents);
            } else if (section === Sections.Categories) {
                // Check if their are any categories available
                if (this.categories.length > 0) {
                    return _eventNumbersForSection(this.categories);
                } else {
                    return 1;
                }
            } else if (section === Sections.OperatingModes) {
                return _eventNumbersForSection(this.operatingModes);
            }
        }

        cellTypeForCellAtIndex(section, row) {
            if (this._isMoreOrLessCell(this._getEventsForSection(section), section, row)) {
                return GUI.TableViewV2.CellType.CENTER;
            }

            return GUI.TableViewV2.CellType.GENERAL;
        }

        contentForCell(cell, section, row) {
            var title = "";
            var showDisclosureIcon = true;

            var events = this._getEventsForSection(section);

            var cellIsClickable = true;
            var cellIsDisabled = false;

            var showMoreShowLessButton = this._isMoreOrLessCell(events, section, row);

            if (showMoreShowLessButton) {
                return {
                    title: SectionExpandedStates[section] && row === events.length ? AutopilotEnums.ShowLess : AutopilotEnums.ShowMore
                };
            } else if (section === Sections.Times) {
                title = _("times." + events[row].id);
                showDisclosureIcon = !AutopilotUtility.isPulseEvent(events[row]); // no deeper navigation, if it's a pulse
            } else if (section === Sections.Weather) {
                title = _("weather." + events[row].id);
            } else if (section === Sections.Categories) {
                if (events.length > 0) {
                    title = events[row].title;
                } else {
                    title = _('autopilot.rule.no-event', {
                        event: _('autopilot.rule.events')
                    });
                    showDisclosureIcon = false;
                    cellIsClickable = false;
                    cellIsDisabled = true;
                }
            } else if (section === Sections.OperatingModes) {
                title = events[row].value;
            }

            return {
                title: title,
                disclosureIcon: showDisclosureIcon,
                clickable: cellIsClickable,
                disabled: cellIsDisabled
            };
        }

        didSelectCell(cell, section, row) {
            var details,
                events = this._getEventsForSection(section); // check here if we tapped the show more/less cell


            if (this._isMoreOrLessCell(events, section, row)) {
                SectionExpandedStates[section] = !SectionExpandedStates[section];
                this.tableView.reload();
                return;
            }

            if (section === Sections.Times) {
                var timeEvent = this.timeEvents[row];
                details = {
                    title: _("times." + timeEvent.id),
                    eventType: AutopilotEnums.EventTypes.Time,
                    id: timeEvent.id
                };

                if (timeEvent.id === AutopilotEnums.SpecialEvents.Weekdays) {
                    // it's an analog value, but we have to show user friendly options
                    details.options = AutopilotEnums.Weekdays;
                    details.type = AutopilotEnums.EventDetailTypes.Radio;
                } else if (AutopilotUtility.isPulseEvent(timeEvent)) {
                    // create event and return!
                    var event = {
                        id: timeEvent.id,
                        type: AutopilotEnums.EventTypes.Time,
                        value: 1
                    };
                    NavigationComp.dispatchEventToUI(AutopilotEnums.CommunicationEvents.ADD_EVENT, event);
                    this.ViewController.navigateBack();
                    return;
                } else if (timeEvent.id === AutopilotEnums.SpecialEvents.CustomTime) {
                    details.type = AutopilotEnums.EventDetailTypes.Time;
                    details.eventType = AutopilotEnums.EventTypes.CustomTime;
                } else if (timeEvent.analog) {
                    details.type = AutopilotEnums.EventDetailTypes.Number;
                } else {
                    details.options = AutopilotEnums.YesNoOptions;
                    details.type = AutopilotEnums.EventDetailTypes.Radio;
                }

                this.ViewController.showState(AutopilotEnums.ScreenStates.AutopilotEventDetailsSimpleScreen, null, details);
            } else if (section === Sections.Weather) {
                var weatherEvent = this.weatherEvents[row];
                details = {
                    title: _("weather." + weatherEvent.id),
                    eventType: AutopilotEnums.EventTypes.Weather,
                    id: weatherEvent.id
                };

                if (weatherEvent.analog) {
                    details.type = AutopilotEnums.EventDetailTypes.DecimalNumber;
                } else {
                    details.options = AutopilotEnums.YesNoOptions;
                    details.type = AutopilotEnums.EventDetailTypes.Radio;
                }

                this.ViewController.showState(AutopilotEnums.ScreenStates.AutopilotEventDetailsSimpleScreen, null, details);
            } else if (section === Sections.Categories) {
                this.ViewController.showState(AutopilotEnums.ScreenStates.AutopilotCategoryScreen, null, {
                    category: this.categories[row],
                    type: AutopilotEnums.CommunicationEvents.ADD_EVENT
                });
            } else if (section === Sections.OperatingModes) {
                this.ViewController.showState(AutopilotEnums.ScreenStates.AutopilotOperatingModeScreen, null, {
                    operatingMode: this.operatingModes[row],
                    event: {} // to create a new event

                });
            }
        }

        _getEventsForSection(section) {
            if (section === Sections.Times) {
                return this.timeEvents;
            } else if (section === Sections.Weather) {
                return this.weatherEvents;
            } else if (section === Sections.Categories) {
                return this.categories;
            } else if (section === Sections.OperatingModes) {
                return this.operatingModes;
            }
        }

        _isMoreOrLessCell(events, section, row) {
            return events && events.length > MAX_PREVIEW_EVENTS + 1 && (SectionExpandedStates[section] && row === events.length || !SectionExpandedStates[section] && row === MAX_PREVIEW_EVENTS);
        }

    };
});
