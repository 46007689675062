'use strict';

class RootViewController extends GUI.ViewController {
    constructor() {
        super($('<root-view />'));
        addEvent("statusTap", window, this._handleStatusBarTap.bind(this));
    }

    _getStaticWrapperView() {
        var wrapper = this.wrapperViewFactory.getInstance();
        wrapper.addClass("overlay-wrapper static-screen");
        this.element.append(wrapper);
        return wrapper;
    }

    _handleStatusBarTap() {
        this.getCurrentView().handleStatusBarTap();
    }

}

GUI.RootViewController = RootViewController;
