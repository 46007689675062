import {colors} from "./colors";

export const borders = {
    top: {
        borderTop: 1,
        borderTopColor: colors.borderColor.default,
        borderTopStyle: "solid",
    },
    bottom: {
        borderBottom: 1,
        borderBottomColor: colors.borderColor.default,
        borderBottomStyle: "solid",
    }
}
