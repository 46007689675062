'use strict';

define(['AudioZoneV2ControlEnums', 'CurrentCoverViewV2', 'QueueScreenV2', 'SpotifyAccountManager', 'LxComponents'], function (AudioZoneV2ControlEnums, CurrentCoverViewV2, QueueScreenV2, SpotifyAccountManager, { LxSlider }) {
    class PlayerV2 extends GUI.Screen {
        //region Static
        static Template = function () {
            var getControlTemplate = function getContainer(ctrl) {
                return '' + '<div class="container__wrapper">' + '<div class="container__queue-view"></div>' + '<div class="container__hide-able">' + '<div class="container__state-view">' + '<div class="media__cover__container"></div>' + '</div>' + '<div class="container__action-view">' + getMediaInfoBar() + getTimeBar() + createControlBar() + '</div>' + '</div>' + '</div>';
            };

            var getMediaInfoBar = function getMediaInfoBar() {
                return '' + '<div class="action-view__media-info-bar action-view__bar">' + '<div class="media-info-bar__center-container">' + '<div class="center-container__top media-info-bar__main-label text-overflow-ellipsis">' + '...' + '</div>' + '<div class="center-container__bottom media-info-bar__sub-label text-overflow-ellipsis">' + '...' + '</div>' + '</div>' + '<div class="media-info-bar__right-container media-info-bar__outer-container more__button clickable">' + ImageBox.getResourceImageWithClasses(Icon.Buttons.MORE_HORIZONTAL, "more__icon outer-container__icon") + '</div>' + '</div>';
            };

            var getTimeBar = function getTimeBar() {
                return '' + '<div class="action-view__time-bar action-view__bar">' + '<div class="time-bar__bottom time__slider-container">' + '</div>' + '<div class="time-bar__top">' + '<div class="top__left time__current">' + '</div>' + '<div class="top__right time__total">' + '</div>' + '</div>' + '</div>';
            };

            var createControlBar = function createControlBar() {
                return '' + '<div class="action-view__control-bar action-view__bar">' + '<div class="control-bar__repeat control-bar__icon-container control-bar__icon-container--small control-bar__repeat--0">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.REPEAT_ALL, "control-bar__repeat control-bar__repeat--all control-bar__icon") + ImageBox.getResourceImageWithClasses(Icon.AudioZone.REPEAT_CURRENT, "control-bar__repeat control-bar__repeat--current control-bar__icon") + '</div>' + '<div class="control-bar__prev-button control-bar__icon-container">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PREV, "control-bar__prev control-bar__icon") + '</div>' + '<div class="control-bar__play-pause-button control-bar__icon-container control-bar__play-pause clickable">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PLAY, "control-bar__play control-bar__icon control-bar__play-pause-icon") + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PAUSE, "control-bar__pause control-bar__icon control-bar__play-pause-icon") + '</div>' + '<div class="control-bar__next-button control-bar__icon-container">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.NEXT, "control-bar__next control-bar__icon") + '</div>' + '<div class="control-bar__shuffle control-bar__icon-container control-bar__icon-container--small">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.SHUFFLE, "control-bar__next control-bar__icon") + '</div>' + '</div>';
            };

            return {
                getControlTemplate: getControlTemplate
            };
        }(); //endregion Static

        static SUPPORTS_SLIDER = false;

        //region Getter
        get identifier() {
            var identifier;

            switch (this.states.contentType) {
                case MusicServerEnum.MediaContentType.SERVICE:
                    if ((this.states.id || this.states.audiopath).indexOf("spotify") !== -1) {
                        identifier = MusicServerEnum.Service.SPOTIFY;
                    } else {
                        identifier = MusicServerEnum.Service.LOCAL;
                    }

                    break;

                case MusicServerEnum.MediaContentType.LIBRARY:
                    identifier = MusicServerEnum.Service.LMS;
                    break;
            }

            return identifier;
        }

        get username() {
            var user = MusicServerEnum.NOUSER;

            switch (this.states.contentType) {
                case MusicServerEnum.MediaContentType.SERVICE:
                    if ((this.states.id || this.states.audiopath).indexOf("spotify") !== -1) {
                        user = SpotifyAccountManager.getUserUnique(SpotifyAccountManager.shared(this.control).activeUser);
                    }

                    break;
            }

            return user;
        } //endregion Getter


        constructor(details) {
            Debug.Control.AudioZone.Player && debugLog("PlayerV2", " ctor");
            super($('<div />'));
            applyMixins(this, StateHandler.Mixin, MediaContextMenuHandlerV2.Mixin);
            this.control = details.control;
            this.showQueue = details.showQueue;
            this.states = {};
            this.swipeHandlers = [];
        }

        viewDidLoad() {
            Debug.Control.AudioZone.Player && debugLog("PlayerV2", " viewDidLoad");
            return Q(super.viewDidLoad(...arguments) || true).then( () => {
                this.element.append(PlayerV2.Template.getControlTemplate(this.control));
                this.elements = {
                    stateView: $(this.element.find('.container__state-view')),
                    actionView: $(this.element.find('.container__action-view')),
                    hideAbleView: $(this.element.find('.container__hide-able')),
                    queueView: $(this.element.find('.container__queue-view'))
                };
                this.elements.coverContainer = $(this.elements.stateView.find('.media__cover__container'));
                this.elements.coverView = new CurrentCoverViewV2(this.control);
                this.appendSubview(this.elements.coverView, this.elements.coverContainer); // Bars

                this.elements.mediaInfoBar = this.elements.actionView.find('.action-view__media-info-bar');
                this.elements.timeBar = this.elements.actionView.find('.action-view__time-bar');
                this.elements.controlBar = this.elements.actionView.find('.action-view__control-bar'); // Media-Info

                this.elements.mainLabel = $(this.elements.mediaInfoBar.find('.media-info-bar__main-label'));
                this.elements.subLabel = $(this.elements.mediaInfoBar.find('.media-info-bar__sub-label'));
                this.elements.media = {}; // Time

                this.elements.time = {
                    sliderContainer: $(this.elements.timeBar.find('.time__slider-container')),
                    current: $(this.elements.timeBar.find('.time__current')),
                    total: $(this.elements.timeBar.find('.time__total'))
                }; // Control

                this.elements.prevButton = this.elements.controlBar.find('.control-bar__prev-button');
                this.elements.playPauseButton = this.elements.controlBar.find('.control-bar__play-pause-button');
                this.elements.nextButton = this.elements.controlBar.find('.control-bar__next-button');
                this.elements.playButtonIcon = this.elements.actionView.find('.control-bar__play');
                this.elements.pauseButtonIcon = this.elements.actionView.find('.control-bar__pause').hide(); // Bottom Buttons

                this.elements.shuffleButton = this.elements.actionView.find('.control-bar__shuffle');
                this.elements.repeatButton = this.elements.actionView.find('.control-bar__repeat');
                this.elements.moreButton = this.elements.mediaInfoBar.find('.more__button'); // ---------------------
                //    active controls
                // ---------------------

                this.buttons = {
                    prevButton: new GUI.LxButton(this, this.elements.prevButton[0], Color.BUTTON_GLOW, null, true, 250),
                    playPauseButton: new GUI.LxButton(this, this.elements.playPauseButton[0], Color.BUTTON_GLOW, null, true, 250),
                    nextButton: new GUI.LxButton(this, this.elements.nextButton[0], Color.BUTTON_GLOW, null, true, 250),
                    shuffleButton: new GUI.LxButton(this, this.elements.shuffleButton[0], Color.BUTTON_GLOW, null, true, 250),
                    repeatButton: new GUI.LxButton(this, this.elements.repeatButton[0], Color.BUTTON_GLOW, null, true, 250),
                    moreButton: new GUI.LxButton(this, this.elements.moreButton[0], Color.BUTTON_GLOW, null, true, 250)
                };
                Object.values(this.buttons).forEach((button) => {
                    this.addToHandledSubviews(button);
                    button.useChildsAsActiveParts("fill");
                }); // Timeline-Slider

                this.appendReactComp({
                        reactComp: LxSlider,
                        compProps: {
                            activeColor: window.Styles.colors.stateActive,
                            thumbStyle: {display:"none"},
                            updateRate: 0.5,
                            antiGhostTimer: true
                        },
                        target: this.elements.time.sliderContainer
                }).then(instance => {
                    this.timeLineSlider = instance;
                    this.timeLineSlider.getElement().addClass('time-line__slider');
                    if (!HD_APP) {
                        this.controlStrip = new GUI.AudioZoneV2ControlStrip(this.control);
                        this.appendSubview(this.controlStrip);
                    }
                })

                if (!HD_APP) {
                    this.controlStrip = new GUI.AudioZoneV2ControlStrip(this.control);
                    this.appendSubview(this.controlStrip);
                }
            });
        }

        viewWillAppear() {
            Debug.Control.AudioZone.Player && debugLog("PlayerV2", " viewWillAppear");
            super.viewWillAppear(...arguments);

            this._registerForStates(this.control.uuidAction);

            if (this.showQueue && !this.qIsVisable) {
                this._handleQueueButtonTapped();
            }
        }

        viewDidAppear() {
            Debug.Control.AudioZone.Player && debugLog("PlayerV2", " viewDidAppear");
            super.viewDidAppear(...arguments);

            this._registerButtons(); // Don't use this, it'll destroy our sortable list (queue)
            //this._registerSwipeHandlers();

            this.timeLineSlider.onDraggingChanged = this._handleTimeLineSlider.bind(this);
        }

        viewWillDisappear() {
            this._unregisterStates();

            super.viewWillDisappear(...arguments);
        }

        viewDidDisappear(viewRemainsVisible) {
            Debug.Control.AudioZone.Player && debugLog("PlayerV2", " viewDidDisappear"); // unregister buttons

            var buttonKeys = Object.keys(this.buttons),
                i,
                btnKey,
                button;

            for (i = 0; i < buttonKeys.length; i++) {
                btnKey = buttonKeys[i];
                button = this.buttons[btnKey];
                button.onButtonTapped = null;
            }

            this._unregisterSwipeHandlers();
            this.timeLineSlider.onDraggingChanged = null;

            // hide after it did disappear, otherwise it looks odd.
            this.elements.bgCoverView && this.hideSubview(this.elements.bgCoverView);
            super.viewDidDisappear(...arguments);
        }

        updateView(details) {
            Debug.Control.AudioZone.Player && console.log(this.name, "updateView - " + JSON.stringify(details));
            super.updateView(...arguments); // when a queue wants to dismiss the player too, only if it's not root

            if (details.hasOwnProperty("target")) {
                if (details.target === AudioZoneV2ControlEnums.ScreenState.DETAILS) {
                    // not root, so obviously an overlay over any child of menu
                    this.ViewController.navigateBack();
                }
            }

            if (details.hasOwnProperty("showQueue")) {
                this.showQueue = !this.showQueue;

                this._handleQueueButtonTapped();
            }
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.DOWN,
                style: 'transparent'
            };
        }

        titleBarText() {
            return NBR_SPACE;
        }

        getURL() {
            return "player";
        }

        getAnimation() {
            var platform = PlatformComponent.getPlatformInfoObj().platform;

            if (platform === PlatformType.IOS || platform === PlatformType.Android || platform === PlatformType.DeveloperInterface) {
                return HD_APP ? AnimationType.HD_MODAL_FULLSCREEN : AnimationType.MODAL;
            } else {
                return AnimationType.MODAL;
            }
        }

        // Received states preprocessed by the stateContainer
        receivedStates(states) {
            // base.receivedStates.call(this, states);
            // don't pass on, it'd show a disconnected notification, we don't want that here
            Debug.Control.AudioZone.Player && console.log(this.name, "receivedStates " + JSON.stringify(states));
            updateFields(this.states, states);
            this.controlButtonsDisabled = this.states.isStream; // Updates the UI

            if (!this.qIsVisable) {
                this._togglePlayerState(this.states.isStream);
            } // Update Media Info


            var mainText, subText;

            if (this.states.texts.connectivityText) {
                mainText = this.control.getName();
                subText = this.states.texts.connectivityText;
            } else {
                mainText = this.states.texts.mainText;
                subText = this.states.texts.subText;
            }

            Debug.Control.AudioZone.Player && console.log('receivedStates: mainText --> ' + mainText);
            Debug.Control.AudioZone.Player && console.log('receivedStates: subText --> ' + subText);
            GUI.animationHandler.schedule(function () {
                this.elements.prevButton.toggleClass('disabled', this.states.isStream);
                this.elements.nextButton.toggleClass('disabled', this.states.isStream);
                this.elements.shuffleButton.toggleClass('disabled', this.states.isStream);
                this.elements.repeatButton.toggleClass('disabled', this.states.isStream); // PlayState

                this.elements.playPauseButton.toggleClass('active', this.states.isPlaying);
                this.elements.playButtonIcon.toggle(!this.states.isPlaying);
                this.elements.pauseButtonIcon.toggle(!!this.states.isPlaying);
                this.elements.mainLabel.text(mainText || "");
                this.elements.subLabel.text(subText || "");
                this.elements.repeatButton.toggleClass('active', this.states.plrepeat !== 0);
                this.elements.repeatButton.toggleClass("control-bar__repeat--3", this.states.plrepeat === 3);
            }.bind(this));
        }

        // --------------------------------------------------------
        // --------------------------------------------------------
        // Connection Handling
        // --------------------------------------------------------
        // --------------------------------------------------------

        /**
         * Decides what channel to use to transmit the cmd (via media comp or miniserver)
         * it's sendCommand, but a different name was used to avoid confusion with base.sendCommand.
         * @param cmd
         */
        dispatchCmd(cmd) {
            var cmdObj = {
                cmd: cmd
            };
            AudioserverComp.sendAudioZoneCommand(this.control.details.playerid, cmdObj);
        }

        // Present the Queue
        _presentQueue() {
            var detail = {
                control: this.control
            };

            if (this.states.hasOwnProperty(MusicServerEnum.Event.QUEUE_INDEX)) {
                detail[MusicServerEnum.Event.QUEUE_INDEX] = this.states[MusicServerEnum.Event.QUEUE_INDEX];
            }

            this.ViewController.showState(AudioZoneV2ControlEnums.ScreenState.QUEUE, null, detail);
        }

        // --------------- Prepareing the UI
        _registerButtons() {
            var buttonKeys = Object.keys(this.buttons);

            for (var i = 0; i < buttonKeys.length; i++) {
                var btnKey = buttonKeys[i];
                var button = this.buttons[btnKey];
                button.onButtonTapped = this._handleButtonTapped.bind(this);
            }
        }

        // ------------------------------------------------------------------------------
        // ------------------------------------------------------------------------------
        //                UI Interaction
        // ------------------------------------------------------------------------------
        _registerSwipeHandlers() {
            var swipeOptions = {
                swipeVelocityX: 0.1
            };
            this.swipeHandlers.push(Hammer(this.element[0], swipeOptions).on('swipedown', this._onSwipeDown.bind(this)));
        }

        _unregisterSwipeHandlers() {
            // Unregister swipe handlers
            for (var i = 0; i < this.swipeHandlers.length; i++) {
                this.swipeHandlers[i].dispose();
            }
        }

        _onSwipeDown() {
            this.ViewController.navigateBack();
        }

        _handleTimeLineSlider(progress) {
            if (this.timeLineSlider.isDragging()) {
                return;
            }
            console.log("_handleTimeLineSliderOnDragChanged ", progress);
            Debug.Control.AudioZone.Player && console.log("time line set to " + progress);
            var value = progress;

            if (Math.round(value) === Math.round(this.states[MusicServerEnum.Event.DURATION])) {
                value = value - 1;
            }

            this.dispatchCmd(MusicServerEnum.AudioCommands.SEEK.TO + value);
        }

        _handleQueueButtonTapped() {
            if (!this.queueView) {
                this.queueView = new Controls.AudioZoneV2Control.QueueScreenV2({
                    control: this.control
                });
                this.appendSubview(this.queueView, this.elements.queueView);
            }

            this.toggleSubview(this.queueView, !this.qIsVisable);
            this.toggleSubview(this.elements.coverView, this.qIsVisable);
            this.elements.hideAbleView.toggle(this.qIsVisable);
            this.qIsVisable = !this.qIsVisable;
        }

        _handleGroupButtonTapped() {
            NavigationComp.showState(AudioZoneV2ControlEnums.ScreenState.GROUP_OVERVIEW);
        }

        _nextTrack() {
            AudioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                cmd: MusicServerEnum.AudioCommands.QUEUE.NEXT
            });
        }

        _prevTrack() {
            AudioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                cmd: MusicServerEnum.AudioCommands.QUEUE.PREV
            });
        }

        _handleButtonTapped(sourceBtn) {
            switch (sourceBtn) {
                case this.buttons.prevButton:
                    if (this.controlButtonsDisabled) {
                        break;
                    }

                    this._prevTrack();

                    break;

                case this.buttons.playPauseButton:
                    if (this.states.isPlaying) {
                        AudioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                            cmd: MusicServerEnum.AudioCommands.PAUSE
                        });
                    } else {
                        AudioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                            cmd: MusicServerEnum.AudioCommands.PLAY
                        });
                    }

                    break;

                case this.buttons.nextButton:
                    if (this.controlButtonsDisabled) {
                        break;
                    }

                    this._nextTrack();

                    break;

                case this.buttons.moreButton:
                    this._handleContextMenuButtonTapped();

                    break;

                case this.buttons.queueButton:
                    this._handleQueueButtonTapped();

                    break;

                case this.buttons.shuffleButton:
                    this._handleShuffleButtonTapped();

                    break;

                case this.buttons.repeatButton:
                    this._handleRepeatButtonTapped();

                    break;

                case this.buttons.zoneGroupButton:
                    this._handleGroupButtonTapped();

                    break;

                default:
                    console.log(JSON.stringify(sourceBtn) + " not handled");
                    break;
            }
        }

        /**
         * Will acquire the currently playing identification and as soon as that is here, it'll
         * @private
         */
        _handleContextMenuButtonTapped() {
            this._showContextMenuForItem(this.states);
        }

        // -------------- ContextButtonMixin ----------------------
        getMediaInfo() {
            return {
                service: {
                    uid: this.identifier + "/" + this.username,
                    cmd: (this.states.audiopath || this.states.id).replace(/(^spotify)@.*$/, "$1")
                }
            };
        }

        /**
         * Returns the current mediaTypeDetails.
         * @returns {*}
         */
        getMediaTypeDetails(item) {
            var typeDetails = null,
                service = {};

            try {
                if (this.currentItemInfo) {
                    service[MusicServerEnum.Attr.SERVICE.CMD] = item[MusicServerEnum.Attr.SERVICE._];
                    service[MusicServerEnum.Attr.SERVICE.USER] = item[MusicServerEnum.Attr.SERVICE.USER];
                    service[MusicServerEnum.Attr.SERVICE.UID] = service[MusicServerEnum.Attr.SERVICE.CMD] + "/" + service[MusicServerEnum.Attr.SERVICE.USER];
                    service[MusicServerEnum.Event.NAME] = AudioserverComp.getServiceName(service[MusicServerEnum.Attr.SERVICE.CMD]);
                    typeDetails = {
                        service: service
                    };
                }
            } catch (ex) {
                console.error("Could not determine source of the current item!");
            }

            return typeDetails;
        }

        _togglePlayerState(isStreaming) {
            if (isStreaming) {
                this._setStreamingPlayer();
            } else {
                this._setFinitePlayer();
            }
        }

        _setStreamingPlayer() {
            GUI.animationHandler.schedule(function () {
                this.elements.timeBar.toggleClass("action-view__time-bar--hidden", true); // Don't use ".hide()" it'll set the "display: none" property which will destroy flexbox!
            }.bind(this));
        }

        _setFinitePlayer() {
            this.elements.timeBar.removeClass("action-view__time-bar--hidden"); // Don't use ".show()" it'll set the "display: block" property which will destroy flexbox!
            // Update Time
            // - bar

            this.timeLineSlider.updateProperties({
                max: this.states[MusicServerEnum.Event.DURATION_INTERNAL]
            });
            this.timeLineSlider.setPosition(this.states[MusicServerEnum.Event.TIME_INTERNAL]); // - texts
            this.timeLineSlider.setColor(this.states.isPlaying ? window.Styles.colors.stateActive : Color.WHITE); // white if nothing is playing

            GUI.animationHandler.schedule(function () {
                if (this.states.texts.time) {
                    this.elements.time.current.text(this.states.texts.time.current);
                    this.elements.time.total.text(this.states.texts.time.total);
                } else {
                    this.elements.time.current.text("");
                    this.elements.time.total.text("");
                }
            }.bind(this));
            //this.timeLineSlider.setIsPlaying(this.states.isPlaying);
        }

        _handleShuffleButtonTapped() {
            AudioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                cmd: MusicServerEnum.AudioCommands.SHUFFLE
            });
        }

        _handleRepeatButtonTapped() {
            var repeatValue;
            Debug.Control.AudioZone.Player && console.log(this.name + " repeateMode: " + this.states.plrepeat);

            switch (this.states.plrepeat) {
                case 0:
                    repeatValue = 1;
                    break;

                case 1:
                    repeatValue = 3;
                    break;

                default:
                    repeatValue = 0;
                    break;
            }

            AudioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                cmd: MusicServerEnum.AudioCommands.REPEAT.PLAIN + repeatValue
            });
        }

    }

    Controls.AudioZoneV2Control.PlayerV2 = PlayerV2;
    return Controls.AudioZoneV2Control.PlayerV2;
});
