import {useStatisticV2, StatisticDataPointUnit } from "LxComponents"
import {useCallback, useState, useEffect} from "react";

/**
 * Will download the saved-money-statistics from the EFM provided by the uuid & for the time specified with start/end
 * @param efmUuid<string>   identifying the efm to request from
 * @param start<number>     unix ts, from what point in time on the money saved is of interest
 * @param end<number>       unix ts, last point in time to request the money saved for
 * @returns {{loaded: boolean, value: number|null, firstDpTs: number|null}}
 */
export default function useMoneySavedStats(efmUuid, start, end) {

    useEffect(() => {
        // reset to empty data if crucial props change.
        setResult(createResultObj());
    }, [start, end, efmUuid])

    const createResultObj = (value = null, firstDpTs = null) => {
        return {
            value: firstDpTs === start ? value : null,
            loaded: firstDpTs !== null,
            firstDpTs: firstDpTs
        };
    }
    const [result, setResult] = useState({ loaded: false, value: null, firstDpTs: null });

    const sumUpYield = useCallback((statsPackage) => {
        let value = null,
            firstDpTs = null;
        if (statsPackage && Array.isArray(statsPackage.data)) {
            firstDpTs = statsPackage.data[0].ts;
            value = statsPackage.data.reduce((accumulator, currDp) => {
                return accumulator + currDp.values[0];
            }, 0);
        }
        return {value, firstDpTs};
    }, []);

    const res = useStatisticV2(efmUuid, start, end, ["OYt"], StatisticDataPointUnit.DAY, sumUpYield); 

    useEffect(() => {
        if (res) {
            setResult(createResultObj(...Object.values(res)));
        } else {
            setResult(createResultObj(null, start));
        }
    }, [res])

    return result;
}
