import {
    LxReactTableView,
    navigatorConfig
} from "LxComponents"
import globalStyles from "GlobalStyles";
import {useEffect} from "react";
import {useBackNavigation} from "../../../../../react-comps/Components";

function PresentationSettingsScreen(props) {
    useBackNavigation(() => {
        props.navigation.goBack();
    }, [])


    useEffect(() => {
        props.navigation.setOptions({
            ...navigatorConfig({
                animationType: AnimationType.MODAL,
                title: _('menu.settings.app.presentation')
            })
        });
    }, []);

    const getSettingCell = (title, subtitle, isActive, onActiveChanged) => {
        return {
            type: GUI.TableViewV2.CellType.SWITCH, content: {
                title,
                subtitle,
                subTitleStyle: globalStyles.textStyles.footNote.default,
                active: isActive
            },
            onSwitchChanged: onActiveChanged
        }
    }

    let tempTableContent = []

    tempTableContent.push({
        rows: [{
            ...getSettingCell(_('simple-design'), _("simple-design.description"), PersistenceComponent.getSimpleDesignSetting(), (value) => {
                PersistenceComponent.setSimpleDesignSetting(value);
            })
        }]
    })

    //Do not display option for cards view on devices where it isn't available
    if (!PlatformComponent.shouldDisplayCardsAsCell()) {
        tempTableContent.push({
            rows: [{
                ...getSettingCell(_('presentation.tile-cell.title'), _('presentation.tile-cell.footer-title'), NavigationComp.tileRepresentationEnabled(), (value) => {
                    NavigationComp.enabledTileRepresentation(value);
                })
            }],
        });
    }

    /*tempTableContent.push({
        rows: [{
            ...getSettingCell(_('use-animations'), _('animations.footer-title'), NavigationComp.animationsEnabled(), (value) => {
                NavigationComp.enableAnimations(value);
                // To also take effect on the dismiss of the current screen
                props.navigation.setOptions({
                    animationEnabled: value
                })
            })
        }],
    });*/

    return <LxReactTableView
        tableContent={tempTableContent}
    />
}

PresentationSettingsScreen.Title = _('menu.settings.app.presentation');

export default PresentationSettingsScreen;
