import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Tree(props) {
    return (
        <Svg isreact="true"
            height={24}
            viewBox="0 0 24 24"
            width={24}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 1C5.925 1 1 5.925 1 12c0 5.92 4.677 10.748 10.537 10.99v-3.763l-1.177-1.812H7.956a1.016 1.016 0 11.001-1h1.754l-1.656-2.55a1.015 1.015 0 11.83-.558l2.652 4.083v-3.621L8.962 9.804l-.08-.003a1.015 1.015 0 11.969-.467l1.686 2.599v-6.32a1.015 1.015 0 111.001 0l-.001 5.328 1.691-2.605a1.015 1.015 0 11.891.468l-2.582 3.975v7.748l2.036-3.135v-4.163a1.015 1.015 0 111 0v2.623l.991-1.526a1.015 1.015 0 11.896.537l-.051-.003-4.872 7.501.001.626C18.363 22.707 23 17.895 23 12c0-6.075-4.925-11-11-11z"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default Tree
