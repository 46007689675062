
const PairedAppEnums = {
    FileName: "PairedAppInfo.json",


    FwVersion: "14.5.11.13",
    SetupSource: {
        MANUAL: "manual",
        QR: "qr",
        FILE: "file"
    },
    PairSetupState: {
        IDLE: "idle",
        REACHABILITY_CHECK: "reach-check",
        REACHABILITY_CONFIRMED: "reach-conf",
        ADMIN_AUTH_REQUIRED: "admin-auth-request",
        ADMIN_AUTH_VERIFY: "admin-auth-verify",
        LOADING_DEVICES: "loading-devices",
        SELECT_DEVICE: "select-device",
        PAIR_AS_NEW: "pair-new-device",
        PAIRING: "pairing-device",
        PAIRED_DONE: "paired-done",
        PAIRING_FAILED: "pairing-failed",
        STOPPED: "stopped"
    },
    PairingFileError: {
        INVALID: 401, // the code isn't valid, it cannot be used to pair, e.g. recreated setup code.
        ALREADY_USED: 410 // the code has already been used to set up a pairing!
    },
    PairingStoppedReason: {
        OUTDATED_FW: "outdated-fw",
        MANUAL: "manually-stopped"
    },
    Cmd: {
        LIST_DEVICES: "jdev/sps/apppairing/list",
        CREATE_DEVICE: "jdev/sps/apppairing/create/%s", // configjson-as-string, response = pairing infos
        REPLACE: "jdev/sps/apppairing/replace/%s/%s", // uuid-of-existing, config-json-subset of new device
        REMOVE: "jdev/sps/apppairing/remove/%s", // uuid-of-existing, will be unpaired & the device removed from cfg
        UNPAIR: "jdev/sps/apppairing/unpair/%s", // uuid-of-existing, will be unpaired, device becomes a dummy.

        PROPS: "dev/sps/pairedapp/cfg/properties", // http-get to download, http-post to update.

        IDENTIFY_START: "jdev/sps/apppairing/identify/%s/%d", // uuid-of-existing, ms of identify duration
        IDENTIFY_STOP: "jdev/sps/apppairing/identify/%s/0", // uuid of existing, device stops to identify
    },
    StateCmd: {
        POWER: "jdev/sps/pairedapp/state/powersupply/%d/%d", // {isPlugged,0-1}, {stateofcharge,0-100}
        INTERACTION: "jdev/sps/pairedapp/state/userinteraction/%d", // {active,0-1}
        SCREENSAVER: "jdev/sps/pairedapp/state/screensaver/%d", // {0 = off & app visible, 1 = screensaver on}
        VERSIONS: "jdev/sps/pairedapp/state/versionInfos/%s/%s", // appVersion, osVersion
        BRIGHTNESS: "jdev/sps/pairedapp/state/currentbrightness/%d", //0-100
    },
    ECEvent: {
        UserInteractionChanged: "UserInteractionChanged"
    },
    RPCMethod: {
        BRIGHTNESS: "brightness",
        DND: "donotdisturb",
        PRESENCE: "roompresence",
        GOTO_DEFAULT: "navigateToEntryPoint",
        UNPAIR: "unpair",
        NAVIGATE_TO: "navigateTo",
        IDENTIFY: "identify"
    },
    Identify: {
        MP3: "identify.mp3",
        SHOULD_LOOP: true
    },
    SupportedPermissions: [
        MsPermission.WEB,
        MsPermission.APP,
        MsPermission.OP_MODES,
        MsPermission.AUTOPILOT,
        MsPermission.EXPERT_MODE_LIGHT,
    ]

};


export default PairedAppEnums;