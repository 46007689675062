'use strict';

define([ "ContentOverviewScreenBase", "SoundsuitAccountManager", "MediaBrowserV2Base", "OverviewContentLoader" ], function (ContentOverviewScreenBase, SoundsuitAccountManager, MediaBrowserV2Base, OverviewContentLoader) {
    return class SoundsuitContentOverviewScreen extends ContentOverviewScreenBase {
        //region Getter
        get soundsuitId() {
            var currId = SoundsuitAccountManager.getUserUnique(SoundsuitAccountManager.shared(this.control).activeUser);

            if (currId) {
                this._soundsuitId = currId;
            } else {
                console.warn(this.viewId, "soundsuitId from manager is null or undefined, use previous! " + this._soundsuitId);
                currId = this._soundsuitId;
            }

            return currId;
        }

        get soundsuitUsername() {
            return SoundsuitAccountManager.shared(this.control).activeUserName;
        } //endregion Getter


        constructor(details) {
            super(...arguments);
            this._soundsuitLoaders = {};
            this._loaderResults = {};

            // filter the service mediaContentType, as soundsuit will have it's own content type (and own loader)
            this.reloadContentTypes.push(this.EVENT_TYPES.SOUNDSUIT);


            Object.values(MusicServerEnum.Soundsuit.TYPES).forEach(function (type) {
                this._loaderResults[type] = [];
            }.bind(this));
        }

        viewDidLoad() {
            //this.isSoundsuitOnboarding = this.ViewController instanceof GUI.AddMediaViewControllerSpotifyOnboarding;
            return super.viewDidLoad(...arguments);
        }

        setViewController() {
            super.setViewController(...arguments);
            this.setViewControllerHtmlAttr(MusicServerEnum.HtmlProp.CONTENT_ID, MusicServerEnum.ControlContentIdentifiers.SOUNDSUIT);
            this.setViewControllerHtmlAttr(MusicServerEnum.HtmlProp.ACC_DEP_CONTENT, true);

            if (!this.ViewController.handleOnSubtitleTapped) {
                this.ViewController.handleOnSubtitleTapped = function () {
                    SoundsuitAccountManager.shared(this.control).showAccountSelector(this.ViewController);
                }.bind(this);
            }
        }

        receivedSoundsuitReloadEvent(reason) {
            this._loaderResults = {};
            Object.values(MusicServerEnum.Soundsuit.TYPES).forEach(function (type) {
                this._loaderResults[type] = [];
            }.bind(this));
            this._soundsuitLoaders = {};

            this.ViewController._checkTitlebar(); // this updates the soundsuit account in the titlebar


            if (reason !== MusicServerEnum.ReloadCause.STOPPED) {
                this._loadedInitialContent = false;
                return this.setup();
            } else {
                return Q(true);
            }
        }

        loadData() {
            var promises = [super.loadData(...arguments), SoundsuitAccountManager.shared(this.control).prepare() // important when bg/foreground changes´
            ];
            return Q.all(promises).then(function () {
                if (SoundsuitAccountManager.shared(this.control).availableUsers.length) {
                    Object.values(MusicServerEnum.Soundsuit.TYPES).map(function (id) {
                        return {
                            id: id,
                            contentType: this.EVENT_TYPES.SOUNDSUIT
                        };
                    }.bind(this)).forEach(function (item) {
                        this._loaderResults[item.id] = [];
                        this._soundsuitLoaders[item.id] = new OverviewContentLoader(this.control, item, this.MAX_OVERVIEW_ITEM_CNT, this.getMediaInfo(item.contentType));

                    }.bind(this));
                }

                return this._handleLoaderResponses();
            }.bind(this));
        }

        /**
         * Will return a promise that resolves once all loaders did at least notify with a result.
         * @returns {Q.Promise<unknown>}
         * @private
         */
        _handleLoaderResponses() {
            var contentReadyChecklist = {};
            var contentReadyDeferred = Q.defer();
            var postResolveReloadRequired = false;
            var allLoaderPromises = [];
            Object.values(this._soundsuitLoaders).forEach(function (loader) {
                contentReadyChecklist[loader.item.id] = false;
                allLoaderPromises.push(loader.fetch().then(function (res) {
                    // loader finished downloading data!
                    contentReadyChecklist[loader.item.id] = true;
                    this._loaderResults[loader.item.id] = res;

                    if (loader.wasCacheUpdated() && !contentReadyDeferred.promise.isPending()) {
                        postResolveReloadRequired = true;
                    } else if (!loader.gotFromCache()) {
                        this._checkSoundsuitContentReady(contentReadyChecklist, contentReadyDeferred);
                    }
                }.bind(this), function (error) {
                    // loader encountered an error while downloading data!
                    // ignore that no data was acquired, there's still some cached data.
                    if (!loader.gotFromCache()) {
                        console.error(this.viewId, "Loader failed & no cached content has been returned!");
                        contentReadyChecklist[loader.item.id] = true;
                        this._loaderResults[loader.item.id] = [];

                        this._checkSoundsuitContentReady(contentReadyChecklist, contentReadyDeferred);
                    } else {
                        console.error(this.viewId, "Loader failed, but a cached content has been returned!");
                    }
                }.bind(this), function (notifRes) {
                    // loader got something from the cache!
                    contentReadyChecklist[loader.item.id] = true;
                    this._loaderResults[loader.item.id] = notifRes;

                    this._checkSoundsuitContentReady(contentReadyChecklist, contentReadyDeferred);
                }.bind(this)));
            }.bind(this));

            this._checkSoundsuitContentReady(contentReadyChecklist, contentReadyDeferred); // when all loaders are done, check if the table needs to be reloaded (e.g. cache was updated)


            Q.all(allLoaderPromises).finally(function () {
                postResolveReloadRequired && this.reloadTable();
            }.bind(this));
            return contentReadyDeferred.promise;
        }

        /**
         * Will check if all loaders did return content, if so, it will resolve the deferred.
         * @param checklist
         * @param deferred
         * @private
         */
        _checkSoundsuitContentReady(checklist, deferred) {
            var allContentReady = Object.values(checklist).every(function (loaderFinished) {
                return loaderFinished;
            });

            if (allContentReady && deferred.promise.isPending()) {
                deferred.resolve();
            }
        }

        updateBackgroundView() {
            if (this.tableContent && this.tableContent.length) {
                this.tableView.setBackgroundView(null);
            } else if (this._loadedInitialContent && SoundsuitAccountManager.shared(this.control).availableUsers.length === 0) {
                this.tableView.setBackgroundView(new GUI.NoSoundsuitAccountView(this.control));
            } else {
                super.updateBackgroundView(...arguments);
            }
        }

        titleBarText() {
            return SOUNDSUIT_TITLE;
        }

        viewControllerSubtitleText() {
            if (SoundsuitAccountManager.shared(this.control).activeUser) {
                return SoundsuitAccountManager.shared(this.control).activeZoneName;
            }

            return null;
        }

        getRightVCButtonIcon() {
            return null;
        }

        getRightVCButtonIconFn() {
            return null;
        }

        _shouldShowFavorites() {
            return false;
        }

        getTableContent() {
            return super.getTableContent(...arguments).then(function (tableContent) {
                if (!this._hasError) {
                    tableContent.pushObject(this._getSoundsuitSection(MusicServerEnum.Soundsuit.TYPES.MOODS));
                    tableContent.pushObject(this._getSoundsuitSection(MusicServerEnum.Soundsuit.TYPES.SCHEDULES));
                }

                return Q.all(tableContent);
            }.bind(this));
        }

        getMediaInfo(contentType) {
            if (contentType === this.EVENT_TYPES.SOUNDSUIT) {
                return {
                    service: {
                        uid: MusicServerEnum.Service.SOUNDSUIT + "/" + this.soundsuitId,
                        cmd: MusicServerEnum.Service.SOUNDSUIT,
                        user: this.soundsuitUsername
                    }
                };
            } else {
                return super.getMediaInfo(...arguments);
            }
        }

        getMediaId(contentType) {
            if (contentType === this.EVENT_TYPES.SOUNDSUIT) {
                //TODO-woessto: what if it's about schedules?
                return MusicServerEnum.Soundsuit.TYPES.MOODS;
            } else {
                return super.getMediaId(...arguments);
            }
        }

        handleOnItemCellClicked(item, contentType, section, row) {
            if (contentType === MusicServerEnum.MediaContentType.SERVICE) {
                var details = {
                    username: this.soundsuitId,
                    lastSelectedItem: item,
                    contentTypes: [contentType],
                    identifier: MusicServerEnum.Service.SOUNDSUIT,
                    mediaType: MusicServerEnum.MediaType.SERVICE,
                    control: this.control
                };
                return this.ViewController.showState(Controls.AudioZoneV2Control.MediaBrowserV2Base.getScreenStateForItem(item), null, details);
            }

            return super.handleOnItemCellClicked(...arguments);
        }

        _getSoundsuitSection(type) {
            var loader = this._soundsuitLoaders[type];

            if (loader) {
                return this._getSection(this._soundsuitLoaders[type].title, this._loaderResults[type].map(function (item) {
                    return this._getSoundsuitRow(item, type);
                }.bind(this)));
            }
        }

        /* Shouldn't be required as there are only 6 moods, hence they'll always fit.
        _openDetailedSoundsuitList(id) {
            var item = this._soundsuitLoaders[id].item;
            this.ViewController.showState(MediaBrowserV2Base.getScreenStateForItem(item), null, {
                username: this.soundsuitId,
                lastSelectedItem: item,
                contentTypes: [item.contentType],
                mediaType: MusicServerEnum.MediaType.SERVICE,
                identifier: MusicServerEnum.Service.SOUNDSUIT,
                control: this.control
            });
        }
         */

        _getSoundsuitRow(item, soundsuitType) {
            item.contentType = this.EVENT_TYPES.SOUNDSUIT;
            var details,
                ignoreContextMenu = false,
                cell,
                isLikedSongsItem = false;

            details = {
                username: this.soundsuitId,
                identifier: MusicServerEnum.Service.SOUNDSUIT,
                service: this.getMediaInfo(item.contentType).service,
                mediaType: MusicServerEnum.MediaType.SERVICE,
                lastSelectedItem: this._soundsuitLoaders[soundsuitType] ? this._soundsuitLoaders[soundsuitType].item : item,
                control: this.control,
                ignoreCtxMenu: ignoreContextMenu
            };

            cell = MediaBrowserV2Base.getConstructorForItem(item).getCellFromContentTypeItem(
                item, details, item.contentType,
                false, // isPlaylist
                false, // isBrowsable
                this.handleOnItemCellClicked.bind(this),
                this.handleOnCellButtonClicked.bind(this),
                this.ViewController);

            if (!this.isInAddMode()) {
                cell.type = GUI.TableViewV2.CellType.Special.MEDIA_V2_COVER;
                //cell.content.hidePlayButton = !soundsuitType && !isLikedSongsItem || soundsuitType === MusicServerEnum.Soundsuit.TYPES.MOODS;
                delete cell.content.class; // Removes the chevron class
            }


            return cell;
        }

    };
});
