'use strict';

define("NfcCodeTouchControl", [
    "Control",
    "NfcCodeTouchControlEnums",
    "IconLib",
    "./content/controlContent",
    "./content/history/history"
], function (
    Control,
    NfcEnums,
    {
        default: Icons
    },
    NfcTouchControlContent,
    NfcCodeTouchControlContentHistory
) {
    return class NfcCodeTouchControl extends Control {
        constructor() {
            super(...arguments);
            this.defaultOutput = this._getDefaultOutput();
        }

        get keyPadAuthType() {
            if(Feature.NFC_CODE_TOUCH_DYNAMIC_AUTH_TYPE) {
                return this.getStates().keyPadAuthType;
            } else {
                if (this.details.hasOwnProperty("keyPadAuthType")) {
                    return this.details.keyPadAuthType;
                } else {
                    return NfcEnums.AuthType.CodeOrNFc;
                }
            }
        }

        get supportsCodes() {
            return this.keyPadAuthType !== NfcEnums.AuthType.Nfc;
        }

        getLegacyScreens() {
            return Object.values(NfcEnums.ScreenState).filter(screenName => {return screenName !== NfcEnums.ScreenState.HISTORY})
        }
        getReactScreens() {
            return [
                ...super.getReactScreens(...arguments),
                { name: NfcEnums.ScreenState.HISTORY, component: NfcCodeTouchControlContentHistory.default }
            ]
        }


        getReactControlContent() {
            return NfcTouchControlContent.default;
        }

        getIcon() {
            return "resources/Images/Controls/NfcCodeTouch/IC-nfc-code-touch.svg";
        }

        getButton0() {
            if (!this.defaultOutput) {
                // Incomplete configuration, don't display any buttons!
                return;
            }

            return {
                iconSrc: Icon.Pushbutton.PUSHBUTTON2,
                reactIcon: Icons.Circle,
                command: this.sendOutputCommand.bind(this, this.defaultOutput.nr)
            };
        }

        deviceState() {
            return this.getStates().deviceState;
        }

        /**
         * returns name of output for the given nr
         * @param nr
         * @returns {String} name of output
         */
        getOutputForNr(nr) {
            var id, output, result; // don't show the unauthorized output number.

            if (nr === NfcEnums.OutputBitmask.UNAUTHORIZED || nr === NfcEnums.OUTPUT_UNAUTHORIZED) {
                result = "";
            } else {
                id = "q" + nr;
                output = this.details.accessOutputs[id];

                if (output && output.length > 0) {
                    result = output;
                } else {
                    result = _("controls.access-keypad.prefix", {
                        prefix: nr
                    });
                }
            }

            Debug.Control.NfcCodeTouch && console.log(this.name, "getOutputForNr: " + nr + " = '" + result + "'");
            return result;
        }

        /**
         * returns array of outputs have the permission (bitmask with output numbers)
         * @param permissions
         * @returns {Array}
         */
        getUsedOutputsWithPermissions(permissions) {
            var outputs = this.getUsedOutputs();
            return outputs.filter(function (output) {
                return permissions & 1 << output.nr - 1;
            });
        }

        /**
         * returns the bitmask with the given output enabled
         * @param output
         * @returns {number} bitmask
         */
        getBitmaskForOutput(output) {
            return 1 << output.nr - 1;
        }

        /**
         * returns all used outputs (object with nr, id, name)
         * @returns {Array}
         */
        getUsedOutputs() {
            var outputs = [],
                outputKey,
                output;

            for (outputKey in this.details.accessOutputs) {
                if (this.details.accessOutputs.hasOwnProperty(outputKey)) {
                    output = this.details.accessOutputs[outputKey];

                    if (output.length > 0 && output.toLowerCase() !== outputKey.toLowerCase()) {
                        outputs.push({
                            nr: parseInt(outputKey.substr(1)),
                            id: outputKey,
                            name: output
                        });
                    }
                }
            }

            return outputs.sort(function (a, b) {
                return a.id.localeCompare(b.id);
            });
        }

        /**
         * wrapper for sending commands...
         * also handles a few error codes and navigates back when needed
         * @param cmd
         * @param viewController
         * @returns {Promise}
         */
        sendCommandWithErrorHandling(cmd, viewController) {
            var promise = SandboxComponent.sendCommand(this, this.uuidAction, cmd, null, this.isSecured).then(function () {
                viewController.dismissModal();
            }.bind(this), function (error) {
                this._handleNfcCodeError(error, viewController);
            }.bind(this));
            NavigationComp.showWaitingFor(promise);
            return promise;
        }

        sendOutputCommand(outputNr) {
            return this.sendCommand(Commands.format(Commands.NFC_CODE_TOUCH.OUTPUT, outputNr)).done(null, this._handleOutputCmdError.bind(this));
        }

        /**
         * Checks the error & translates it into a proper popup. Also removes the modal if the error does not indicate
         * otherwise.
         * @param error
         * @param viewController
         * @private
         */
        _handleNfcCodeError(error, viewController) {
            var errorId = getLxResponseValue(error, true),
                dismiss = false,
                message,
                content;
            console.error("Handling NFC Code Error: " + getLxResponseCode(error) + " = " + errorId);

            switch (errorId) {
                case NfcEnums.ErrorResponse.BRUTE_FORCE:
                    content = {
                        title: _('user.access-code.change.too-many-changes.title'),
                        message: _('user.access-code.change.too-many-changes.message'),
                        buttonOk: true,
                        icon: Icon.CAUTION,
                        color: window.Styles.colors.orange
                    };
                    break;

                case NfcEnums.ErrorResponse.ALREADY_IN_USE:
                    message = _("controls.access-keypad.access-code.existing");
                    break;

                default:
                    // unknown errors should dismiss the modal.
                    dismiss = true;
                    break;
            }

            if (errorId === NfcEnums.ErrorResponse.BRUTE_FORCE) {
                NavigationComp.showPopup(content);
            } else {
                NavigationComp.showErrorPopup(false, false, message);
            }

            dismiss && viewController && viewController.dismissModal();
        }

        /**
         * @param force will force to send the command
         * @returns {*}
         */
        getHistory(force) {
            Debug.Control.NfcCodeTouch && console.log(this.name, "getHistory (force: " + force + ")");
            /*
             {
             ts: 1474803385,        // unix ts in utc
             output: 1,             // 1-6
             type: 0,               // 0 = code (can be associated with one user), 1 = code (with name), 2 = code (ambiguous), 3 = nfc (can be associated with one user), 4 = nfc (with name), 5 = nfc (ambiguous), 6 = app
             user: "Thomas",        // optional, if type is 0/3/6 username
             description: "Tag",    // optional, if type is 1/3/4 (1: name of code, 3/4: tag name)
             }
             */

            var currentDate = this.getStates().historyDate; // safe as var, may change during loading

            if (this._historyDate === currentDate && !force) {
                Debug.Control.NfcCodeTouch && console.log(this.name, "      using cached data");
                var def = Q.defer();
                def.resolve(this._history);
                return def.promise;
            } else {
                Debug.Control.NfcCodeTouch && console.log(this.name, "      redownload history of NFC Code Touch"); // The forth flag indicates that this command shouldn't be recorded by NFC, QuickAction, QR…

                var promise = this.sendCommand(Commands.NFC_CODE_TOUCH.GET_HISTORY, null, null, true).then(function (result) {
                    this._history = this._prepareHistory(JSON.parse(result.LL.value));
                    this._historyDate = currentDate;
                    return this._history;
                }.bind(this));
                NavigationComp.showWaitingFor(promise, _('loading.data'));
                return promise;
            }
        }

        /**
         * @param force will force to send the command
         * @returns {*}
         */
        getCodes(force) {
            /*
             {
             uuid: "523CA765-D52B-49BF-8D59-531D4BF202DE",
             name: "Briefträger",       // string
             isActive: true,            // if code is active
             type: 0,                   // 0 = permanent code, 1 = one-time code, 2 = time-dependent code
             outputs: 3,                // bitmask
             standardOutput: 1,         // 1-6
             timeFrom: 1475087898,      // optional, if type is 2
             timeTo: 1475097898,        // optional, if type is 2
             }
             */
            var currentDate = this.getStates().codeDate; // safe as var, may change during loading, but we need to set the correct date after loading..

            if (this._codeDate === currentDate && !force) {
                var def = Q.defer();
                def.resolve(this._codes);
                return def.promise;
            } else {
                var promise = this.sendCommand(Commands.NFC_CODE_TOUCH.GET_CODES).then(function (result) {
                    var codes = JSON.parse(result.LL.value);
                    codes = codes.sort(function (a, b) {
                        return a.name.localeCompare(b.name);
                    });
                    this._codes = this._splitUpCodes(codes);
                    this._codeDate = currentDate;
                    return this._codes;
                }.bind(this));
                NavigationComp.showWaitingFor(promise, _('loading.data'));
                return promise;
            }
        }

        resetCache() {
            delete this._historyDate;
            delete this._codeDate;
        }

        /**
         * prepares the history like that:
         * [ { ts: 1474840800, entries: [ {} ] }, { ts: 1474927200, entries: [ {} ] } ]
         * @param history
         * @returns {Array}
         * @private
         */
        _prepareHistory(history) {
            Debug.Control.NfcCodeTouch && console.log(this.name, "_prepareHistory");
            var _history = [],
                entry,
                days = {},
                day,
                dayTs,
                i;

            for (i = 0; i < history.length; i++) {
                entry = history[i];
                Debug.Control.NfcCodeTouch && console.log(this.name, "        - ", entry); // The miniserver may not adopt the history type, when declining app access requests

                if (entry.type === NfcEnums.HistoryType.VISU && entry.output === NfcEnums.OUTPUT_UNAUTHORIZED) {
                    // the Miniserver might not set it yet, as it is new to the API, so set it here.
                    entry.type = NfcEnums.HistoryType.VISU_DENIED;
                } // group by day


                dayTs = moment.unix(entry.ts).startOf("day").unix();

                if (days[dayTs]) {
                    day = days[dayTs];
                } else {
                    day = {
                        ts: dayTs,
                        entries: []
                    };
                    days[dayTs] = day;

                    _history.push(day);
                } // prepare entry

                entry.outputName = this.getOutputForNr(entry.output);
                day.entries.push(entry);
            } // sort days


            _history = _history.sort(function (a, b) {
                return b.ts - a.ts;
            }); // sort entries of each day

            for (i = 0; i < _history.length; i++) {
                _history[i].entries = _history[i].entries.sort(function (a, b) {
                    return b.ts - a.ts;
                });
            }

            Debug.Control.NfcCodeTouch && console.log(this.name, "   --> ", _history);
            return _history;
        }

        /**
         * splits the codes into active and inactive
         * @param codes
         * @returns {{activeCodes: Array, inactiveCodes: Array}}
         * @private
         */
        _splitUpCodes(codes) {
            var activeCodes = [],
                inactiveCodes = [],
                code,
                i;

            for (i = 0; i < codes.length; i++) {
                code = codes[i];

                if (code.isActive) {
                    activeCodes.push(code);
                } else {
                    inactiveCodes.push(code);
                }
            }

            return {
                activeCodes: activeCodes,
                inactiveCodes: inactiveCodes
            };
        }

        // Private Methods

        /**
         * handles the error when the users tries to execute an output command
         * @param res
         */
        _handleOutputCmdError(res) {
            if (!res || !res.LL) {
                return;
            }

            switch (parseInt(res.LL.Code)) {
                case ResponseCode.FORBIDDEN:
                    NavigationComp.showPopup({
                        title: _("error"),
                        message: _("controls.access-keypad.not-permitted"),
                        buttonOk: true,
                        color: window.Styles.colors.orange
                    });
                    break;
            }
        }

        _getDefaultOutput() {
            return this.getUsedOutputs()[0];
        }

    };
});
