import {
    LxReactText,
    LxReactPressable,
    LxReactControl,
    LxReactImageView,
    LxReactContextMenuHandler,
    App
} from "LxComponents";
import PropTypes from "prop-types";
import globalStyles from "GlobalStyles";
import {useState, useEffect, useRef, useCallback, isValidElement} from 'react';
import {View, StyleSheet} from "react-native-web";

export default function LxReactCardCell({content, getSortingContextMenuOptions, borderStyle, removeLeftPadding}) {
    const elementRef = useRef(null);

    const [useSimpleDesign, setUseSimpleDesign] = useState(content.useSimpleDesign);

    useEffect(() => {
        let _simpleDesignReg = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.SimpleDesignChanged, (ev, enabled) => {
            setUseSimpleDesign(enabled);
        });

        return () => {
            NavigationComp.removeFromUIEvent(_simpleDesignReg);
        }
    }, [])

    const openContent = useCallback(() => {
        let group = content.group;

        let detailScreenState =  group.groupType === GroupTypes.ROOM ? ScreenState.RoomDetail : ScreenState.CategoryDetail;

        App.navigationRef.navigate(detailScreenState, {
            groupType: group.groupType,
            groupUUID: group.uuid
        });
    }, [content]);

    const _showContextMenu = useCallback(() => {
        let contextMenuOptions;

        contextMenuOptions = getSortingContextMenuOptions({content});

        if (contextMenuOptions?.length) {
            LxReactContextMenuHandler.shared.showContextMenu(contextMenuOptions, "", elementRef.current, GUI.LxContextMenuV2);
        }
    }, [content]);

    const StateIcon = () => {
        let containerStyle = content.displayAsCell ? styles.cell.stateIcon : styles.card.stateIcon;

        containerStyle.fill = useSimpleDesign ? globalStyles.colors.white : content.iconColor
        if (isValidElement(content.iconSrc)) {
            return content.iconSrc;
        }
        return <LxReactImageView source={content.iconSrc} iconClass={content.iconClass} containerStyle={containerStyle} />
    }

    const TitleText = () => {
        let textAlign = content.displayAsCell ? null : "center";
        let textStyle = StyleSheet.flatten([baseStyles.primary, {textAlign}]);
        return <View style={content.displayAsCell ? styles.cell.textCntr : styles.card.textCntr}>
            <LxReactText style={textStyle} numberOfLines={1}>{content.title2}</LxReactText>
        </View>
    }

    const Card = () => {
        return <View style={styles.card.container}>
            <View style={styles.card.stateIconCntr}>
                <StateIcon />
            </View>
            <TitleText />
        </View>
    }

    const Cell = () => {
        let stateIconCntrStyle = {...styles.cell.stateIconCntr};
        if (removeLeftPadding) {
            delete stateIconCntrStyle.paddingLeft;
        }
        return <View style={styles.cell.container}>
            <View style={stateIconCntrStyle}>
                <StateIcon />
            </View>
            <TitleText />
        </View>
    }


    return <LxReactPressable ref={elementRef} onPress={content.onPress ||openContent} onLongPress={_showContextMenu}
                             style={[baseStyles.pressable, JSON.parse(borderStyle || "{}")]}>
        {content.displayAsCell ?<Cell /> : <Card/>}
    </LxReactPressable>;
}

const baseStyles = {
    pressable: {
        borderStyle: "solid",
        borderRadius: 0,
        borderColor: globalStyles.colors.borderColor.default,
        //borderColor: "deeppink",
        flex: 1,
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
    },
    container: {
        flex: 1,
        display: "flex",
        width: "100%",
        height: "100%",
        borderStyle: "solid",
    },
    primary: {
        ...globalStyles.textStyles.body.bold,
        color: globalStyles.colors.text.primary
    },
    stateIconCntr: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    },
    stateIcon: {
        fill: globalStyles.colors.white,
    },
    textCntr: {
        display: "flex",
        flexDirection: "column",
        flex: 1
    }
};
const styles = {
    cell: {
        container: {
            ...baseStyles.container,
            flexDirection: "row",
            minHeight: globalStyles.sizes.cellH,
            alignItems: "center"
        },
        stateIconCntr: {
            ...baseStyles.stateIconCntr,
            minWidth: globalStyles.sizes.bigger,
            marginRight: globalStyles.sizes.smallest,
            height: "initial",
            alignSelf: "center",
            paddingRight: globalStyles.spacings.gaps.small,
            paddingLeft: globalStyles.spacings.gaps.smallRegular
        },
        stateIcon: {
            ...baseStyles.stateIcon,
            width: globalStyles.sizes.icons.bigger,
            height: globalStyles.sizes.icons.bigger,
        },
        textCntr: {
            ...baseStyles.textCntr,
            height: "100%",
            justifyContent: "center"
        }
    },
    card: {
        container: {
            ...baseStyles.container,
            height: "100%",
            flexDirection: "column",
            paddingTop: 0,
            paddingHorizontal: globalStyles.spacings.contentHorizontal,
            paddingBottom: 26,
            justifyContent: "center",
            minHeight: 135
        },
        stateIconCntr: {
            ...baseStyles.stateIconCntr,
            display: "flex",
            flexDirection: "column",
            height: 88,
            width: "100%",
        },
        stateIcon: {
            ...baseStyles.stateIcon,
            width: 48,
            height: 48,
            justifyContent: "center"
        },
        textCntr: {
            ...baseStyles.textCntr,
            alignItems: "center",
        }
    }
}


LxReactControl.propTypes = {
    content: PropTypes.object,
    getSortingContextMenuOptions: PropTypes.func,
    borderStyle: PropTypes.string
}
