/**
 * Created by loxone on 14.12.17.
 */
'use strict';

define(['VentilationControlEnums', 'VentilationControlModesScreen'], function (VentilationControlEnums) {
    return class VentilationControlVentTimerScreen extends GUI.TableViewScreen {
        //region Getter
        get ventUntil() {
            return this._ventUntil;
        }

        get intensity() {
            return this._intensity;
        }
        //endregion Getter


        //region Setter
        set ventUntil(newVal) {
            Debug.Control.Ventilation && console.log(this.viewId, "setting ventUntil to " + newVal, getStackObj());
            this._ventUntil = newVal;
            return newVal;
        }

        set intensity(newVal) {
            Debug.Control.Ventilation && console.log(this.viewId, "setting intensity to " + newVal, getStackObj());
            this._intensity = newVal;
            return newVal;
        } //endregion Setter


        constructor(details) {
            var preselectedTimerProfile;
            super($('<div />'));
            Object.assign(this, ContextMenuHandler.Mixin);
            this.control = details.control; // clone the object, we add the manual mode later

            this.timerProfiles = cloneObject(this.control.details.timerProfiles);

            this._prepareManualMode();

            this.manualProfileIndex = this.timerProfiles.length - 1;
            this.selectedProfileIndex = 0;
            preselectedTimerProfile = this._getCurrentTimerProfile();
            this.intensity = preselectedTimerProfile.speed.value;
            this.ventUntil = moment().add(preselectedTimerProfile.interval, 'seconds');
            this.modesAreBlocked = false;
        }

        updateView(details) {
            // Update the mode if the newly selected mode is not equal to the current selected mode
            // to be able to display the new mode and switch to the manual mode without loosing the new mode
            var currentTimerProfile = this._getCurrentTimerProfile(),
                modeCell; // Only update, if the modes have changed


            if (currentTimerProfile.defaultModeId !== details.selectedModeId) {
                // Save the new newly selected modeID as the current timers defaultModeId
                currentTimerProfile.defaultModeId = details.selectedModeId; // Then set the manualMode as active, but only if it is not active yet

                if (this.selectedProfileIndex !== this.manualProfileIndex) {
                    this._setManualPresetActive();
                } // Update the disclosureText, this prevents us from reloading the whole table


                modeCell = this.tableView.cellForSectionAndRow(1, 2);
                modeCell.setDisclosureText(this.control.getModeWithId(details.selectedModeId).name);
            }
        }

        titleBarText() {
            return _('controls.leaf.timer');
        }

        titleBarConfig() {
            return {
                rightSide: TitleBarCfg.Button.TICK
            };
        }

        titleBarActionRight() {
            Debug.Control.Ventilation && console.log(this.viewId, "titleBarActionRight");

            var msTime = SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINISERVER_DATE_TIME),
                timeDifference = this.ventUntil.diff(msTime, 'seconds'),
                modeId = this._getCurrentTimerProfile().defaultModeId,
                presetId = this.selectedProfileIndex,
                command;

            if (this.selectedProfileIndex === this.manualProfileIndex) {
                presetId = VentilationControlEnums.TIMER_PRESET.MANUAL;
            }

            Debug.Control.Ventilation && console.log(this.viewId, "    ventUntil says " + this.ventUntil);
            Debug.Control.Ventilation && console.log(this.viewId, "    this results in " + timeDifference + " seconds");
            command = Commands.format(Commands.VENTILATION.TIMER.SET, timeDifference, this.intensity, modeId, presetId);
            Debug.Control.Ventilation && console.log(this.viewId, "    cmd: " + command);
            this.control.sendCommand(command);
            this.ViewController.navigateBack();
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        reloadTable() {
            Debug.Control.Ventilation && console.log(this.viewId, "reloadTable");
            this.tableContent = [this._getPresetSection(), this._getValueSection()];
            return super.reloadTable(...arguments);
        }

        didSelectPreset(cell, section, row, tableView, selected) {
            if (this.selectedProfileIndex !== row) {
                Debug.Control.Ventilation && console.log(this.viewId, "didSelectPreset: " + row + " - selected: " + !!selected);
                this.selectedProfileIndex = row;

                var selectedPreset = this._getCurrentTimerProfile();

                this.ventUntil = moment().add(selectedPreset.interval, 'seconds');
                this.intensity = selectedPreset.speed.value;
                this.reloadTable();
            } else {
                Debug.Control.Ventilation && console.warn(this.viewId, "didSelectPreset: UNCHANGED, " + row + " - selected: " + !!selected);
            }
        }

        intensityChanged(cell, section, row, tableView, value, isDragging) {
            Debug.Control.Ventilation && console.log(this.viewId, "intensityChanged: " + value + ", isDragging: " + !!isDragging);
            var modeCell = this.tableView.cellForSectionAndRow(1, row + 1),
                modeCellDiscColor,
                selectedMode;
            this.intensity = value;

            if (this.selectedProfileIndex !== this.manualProfileIndex) {
                this._setManualPresetActive();
            }

            this.reloadTable();
        }

        /**
         * Returns the preset section
         * @returns {{rows: Array}}
         * @private
         */
        _getPresetSection() {
            return {
                rows: this.timerProfiles.map(this._getTimerProfileCell.bind(this))
            };
        }

        /**
         * Prepares the cell for a given timerProfile
         * @param timerProfile
         * @param idx
         * @return {{type: string, content: {title: *, selected: boolean, radioMode: string}, didCheckCell: *}}
         * @private
         */
        _getTimerProfileCell(timerProfile, idx) {
            var type = GUI.TableViewV2.CellType.CHECKABLE,
                content = {
                    title: timerProfile.name,
                    selected: idx === this.selectedProfileIndex,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED
                };

            if (timerProfile.useCase) {
                type = GUI.TableViewV2.CellType.CHECKABLE_DESCRIPTION;
                content.description = timerProfile.useCase;
            }

            return {
                type: type,
                content: content,
                didCheckCell: this.didSelectPreset.bind(this)
            };
        }

        /**
         * Returns the Value section
         * @private
         */
        _getValueSection() {
            Debug.Control.Ventilation && console.log(this.viewId, "_getValueSection");

            var selectedPreset = this._getCurrentTimerProfile(),
                selectedMode = this.control.getModeWithId(selectedPreset.defaultModeId),
                modes = this.control.details.modes,
                valuePresetSection = {
                    headerBoldTitle: selectedPreset.name,
                    rows: [this._getEndDateCell(this.ventUntil), this._getIntensityCell(this.intensity)]
                }; // Check if we have modes to add, modes are optional


            if (modes && modes.length > 0) {
                // It doesn't make any sense to select any mode if the vent if off
                // Lets block the mode selection
                this.modesAreBlocked = !(this.intensity > 0);
                let modeTextColor;
                if (this.modesAreBlocked) {
                    modeTextColor = AMBIENT_MODE ? Color.TEXT_SECONDARY_B : Color.STATE_INACTIVE;
                } else {
                    modeTextColor = window.Styles.colors.stateActive;
                }
                valuePresetSection.rows.push({
                    type: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        title: _('pool.control.mode'),
                        disclosureText: selectedMode.name,
                        disclosureColor: modeTextColor,
                        disclosureIcon: !this.modesAreBlocked,
                        clickable: !this.modesAreBlocked
                    },
                    action: this._openModesSelector.bind(this)
                });
            }

            return valuePresetSection;
        }

        _getEndDateCell(untilDate) {
            var dateTextFormat = LxDate.getDateFormat(DateType.DateAndTimeShort),
                endDateText = untilDate.format(dateTextFormat);
            Debug.Control.Ventilation && console.log(this.viewId, "_getEndDateCell: " + endDateText);
            return {
                type: GUI.TableViewV2.CellType.GENERAL,
                content: {
                    title: _('controls.leaf.timer.venting-until'),
                    disclosureText: endDateText,
                    clickable: true,
                    disclosureIcon: true
                },
                action: function () {
                    NavigationComp.showContextMenu(this, {
                            type: GUI.LxTimePickerContextMenu.TYPE.DATE_TIME,
                            value: untilDate,
                            onPickerChanged: function (newVal) {
                                Debug.Control.Ventilation && console.log(this.viewId, "onPickerChanged: " + newVal);
                                this.ventUntil = newVal;
                                this.reloadTable();
                            }.bind(this)
                        }, _('controls.leaf.timer.venting-until'), null, // center in screen on HD
                        GUI.LxTimePickerContextMenu);
                }.bind(this)
            };
        }

        _getIntensityCell(intensity) {
            Debug.Control.Ventilation && console.log(this.viewId, "_getIntensityCell: " + this.intensity + "%");
            return {
                type: GUI.TableViewV2.CellType.SLIDER,
                content: {
                    title: _('controls.leaf.vent-intensity'),
                    value: intensity,
                    format: "%i%%",
                    valueColor: window.Styles.colors.stateActive,
                    minIconSrc: Icon.ValueSelector.MINUS_PLAIN,
                    maxIconSrc: Icon.ValueSelector.PLUS_PLAIN
                },
                sliderDragEnded: this.intensityChanged.bind(this),
                sliderClicked: this.intensityChanged.bind(this)
            };
        }

        /**
         * Sets the manualPreset as the selectedPreset
         * @private
         */
        _setManualPresetActive() {
            var manualCell; // Set the defaultModeId of the currently selected mode as the defaultModeId of the manualPreset

            this.manualPreset.defaultModeId = this._getCurrentTimerProfile().defaultModeId; // Trigger a selection on the manualCell, this will reload the table and display the manual mode

            this.selectedProfileIndex = this.manualProfileIndex;
        }

        _openModesSelector() {
            if (!this.modesAreBlocked) {
                this.ViewController.showState(VentilationControlEnums.SCREEN_STATES.VENT_MODES_SCREEN, null, {
                    control: this.control,
                    selectedModeId: this._getCurrentTimerProfile().defaultModeId
                });
            }
        }

        /**
         * Returns the currently selected timer profile
         * @returns {*}
         * @private
         */
        _getCurrentTimerProfile() {
            return this.timerProfiles[this.selectedProfileIndex];
        }

        /**
         * Generates and pushes the manual mode to the timerProfiles
         * @private
         */
        _prepareManualMode() {
            // Manual preset has all modes available, iterate over the modesArray to add every single mode ID
            var modes = [],
                mode,
                i,
                availableModes = this.control.details.modes;

            for (i = 0; i < availableModes.length; i++) {
                mode = availableModes[i];
                modes.push(mode.id);
            } // Speed and interval will be added dynamically


            this.manualPreset = {
                name: _('manually').capitalize(),
                interval: 9000,
                // default to 15 minutes
                speed: {
                    value: 100,
                    // default to 100%
                    enabled: true
                },
                modes: modes,
                defaultModeId: VentilationControlEnums.MODES.HEAT_EXCHANGER_ON
            };
            this.timerProfiles.push(this.manualPreset);
        }

    };
});
