'use strict';

define(["AudioZoneV2ControlEnums"], function (AudioZoneV2ControlEnums) {
    class ServiceRadioSettings extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            this.details = details;
            this.control = details.control;

            this._setTableContent();
        }

        viewWillAppear() {
            super.viewWillAppear(arguments);

            this._reloadRadios(true);
        }

        titleBarText() {
            return _("edit");
        }

        onSwitchChanged(active, section, row, table) {
            var cmd;

            if (active) {
                cmd = MusicServerEnum.Commands.SERVICES.RADIO.ENABLE;
            } else {
                cmd = MusicServerEnum.Commands.SERVICES.RADIO.DISABLE;
            }

            AudioserverComp.sendMediaServerCommand(cmd).then(function () {
                this._setTableContent();
            }.bind(this));
        }

        _setTableContent() {
            this.tableContent = [{
                rows: [{
                    content: {
                        title: _("media.service.webradio.show")
                    },
                    type: GUI.TableViewV2.CellType.SWITCH
                }]
            }];
        }

        _updateRadios(radios) {
            if (!radios || JSON.stringify(this.radios) === JSON.stringify(radios)) {
                return;
            }

            this.radios = radios;
            this.tableView.cellForSectionAndRow(0, 0).setActive(this.radios && this.radios.length > 0);
        }

        _reloadRadios(initial) {
            var result = AudioserverComp.getRadios();
            this.processAsync(result.promise, this._updateRadios.bind(this)); // to avoid "ghosts" only use the direct result if it was the initial response

            if (!!initial) {
                this._updateRadios(result.data);
            }
        }

    }

    Controls.AudioZoneV2Control.ServiceRadioSettings = ServiceRadioSettings;
});
