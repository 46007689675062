import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Weather01d(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            <Path
                d="M19 8.583A5.417 5.417 0 1024.417 14 5.406 5.406 0 0019 8.583zm.833-2.083V4.833a.833.833 0 10-1.666 0V6.5a.833.833 0 101.666 0zM14.25 8.083l-1.167-1.166a.825.825 0 10-1.166 1.166l1.166 1.167a.825.825 0 001.167-1.167zm-2.75 5.084H9.833a.833.833 0 100 1.666H11.5a.833.833 0 000-1.666zm1.583 5.583l-1.166 1.167a.825.825 0 101.166 1.166l1.167-1.166a.825.825 0 10-1.167-1.167zm5.084 2.75v1.667a.833.833 0 001.666 0V21.5a.833.833 0 00-1.666 0zm5.583-1.583l1.167 1.166a.825.825 0 001.166-1.166l-1.166-1.167a.825.825 0 00-1.167 1.167zm2.75-5.084h1.667a.833.833 0 000-1.666H26.5a.833.833 0 100 1.666zM24.917 9.25l1.166-1.167a.825.825 0 00-1.166-1.166L23.75 8.083a.825.825 0 101.167 1.167z"
                fill="#f4cd27"
            />
            <Path
                d="M18.19 27H7.81A3.852 3.852 0 014 23.116a3.924 3.924 0 011.58-3.148A5.441 5.441 0 0110.924 15a5.3 5.3 0 014.281 2.168 4.122 4.122 0 014.324 2.31A3.893 3.893 0 0122 23.116 3.852 3.852 0 0118.19 27z"
                fill="#d5e2e9"
            />
        </Svg>
    )
}

export default Weather01d
