import React, {useCallback, useEffect, useState} from 'react';
import {View} from 'react-native';
import PropTypes from 'prop-types';

import Svg from 'react-native-svg';

function AspectRatioSvg({aspectRatio = 0.5707070707, fixedHeight, onSize = () => {}, children, stretchInstead = false, ...props}) {
    const [{width, height}, setSize] = useState({width: 0, height: 0})
    const onLayout = useCallback(({nativeEvent: {layout: {width, height}}}) => {
        const nextSize = {width, height: stretchInstead || fixedHeight ? height : Math.round(width * aspectRatio)};
        setSize(nextSize);
        onSize(nextSize);
    }, [stretchInstead]);

    useEffect(() => {
      setSize({width: 0, height: 0})
    }, [stretchInstead])

    return (
      <View onLayout={onLayout} style={[{pointerEvents: 'none'}, stretchInstead ? {flex: 1} : fixedHeight ? {width: '100%', height: fixedHeight, maxHeight: fixedHeight} : {width: '100%'}]}>
          <Svg width={width} height={height} {...props}>{children}</Svg>
      </View>
    );
}
AspectRatioSvg.propTypes = {
    aspectRatio: PropTypes.number, // TODO: Ideally, check for positive numbers
    onSize: PropTypes.func,
}

export default AspectRatioSvg
