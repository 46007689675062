'use strict';

window.GUI = function (GUI) {
    class EditableBaseCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Getter
        get removable() {
            return !!this._removable;
        }

        get sortable() {
            return !!this._sortable;
        } //endregion Getter


        //region Setter
        set removable(val) {
            this._removable = !!val;

            if (this.element) {
                GUI.animationHandler.setAttr(this.element, "removable", this._removable);
            }
        }

        set sortable(val) {
            this._sortable = !!val;

            if (this.element) {
                GUI.animationHandler.setAttr(this.element, "sortable", this._sortable);
            }
        } //endregion Setter


        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("editable-base-cell");
        }

        viewDidLoad() {
            var promise = super.viewDidLoad(...arguments);
            this.editMode = this._isWithinEditableRange();

            if (this.editMode) {
                this.removable = this.tableView.editSectionsMap[this.sectionIdx].removeEnabled;
                this.sortable = this.tableView.editSectionsMap[this.sectionIdx].sortEnabled;
            }

            this.element.prepend(GUI.TableViewV2.Cells.EditableBaseCell.Template.getDeleteIcon(this.getDeleteButtonIcon()));
            this.element.append(GUI.TableViewV2.Cells.EditableBaseCell.Template.getSortIcon());
            this.elements.deleteIcon = this.element.find('.cell__delete-icon-placeholder');
            this.elements.sortIcon = this.element.find('.cell__sort-icon-placeholder');
            this.buttons = {
                deleteButton: new GUI.LxButton(this, this.elements.deleteIcon[0], Color.BUTTON_GLOW)
            };
            this.addToHandledSubviews(this.buttons.deleteButton);
            return promise;
        }

        // ---------------------------------------------------------------------------------------------------------
        //   Methods for super fast tableContent updates
        // ---------------------------------------------------------------------------------------------------------

        /**
         * This Method is used to apply a new content object onto the cell instance. e.g. update texts and so on.
         * @param newContent
         * @private
         */
        _applyNewContent(newContent) {
            var prms = super._applyNewContent(...arguments);

            this.editMode = this._isWithinEditableRange();

            if (this.editMode) {
                this.removable = this.tableView.editSectionsMap[this.sectionIdx].removeEnabled;
                this.sortable = this.tableView.editSectionsMap[this.sectionIdx].sortEnabled;
            } else {
                this.removable = false;
                this.sortable = false;
            }

            return prms;
        }

        // ---------------------------------------------------------------------------------------------------------
        // ---------------------------------------------------------------------------------------------------------
        viewDidAppear() {
            var promise = super.viewDidAppear(...arguments);
            this.buttons.deleteButton.onButtonTapped = this._deleteButtonTapped.bind(this);
            return promise;
        }

        viewDidDisappear() {
            this.buttons.deleteButton.onButtonTapped = null;
            return super.viewDidDisappear(...arguments);
        }

        startEditMode(removable, sortable) {
            this.editMode = true;
            this.removable = removable;
            this.sortable = sortable;
        }

        stopEditMode() {
            this.editMode = false;
            this.removable = false;
            this.sortable = false;
        }

        onSelected() {
            if (!this.editMode || this.content.forceSelection) {
                super.onSelected(...arguments);
            } else {// do nothing
            }
        }

        setDeleteButtonIcon(iconSrc) {
            this.elements.deleteIcon.find(".delete-icon-placeholder__icon").replaceWith(ImageBox.getResourceImageWithClasses(iconSrc, "delete-icon-placeholder__icon icon-placeholder__icon"));
        }

        /**
         * Used to replace the icon of the base delete button. Used eg in the editMoodLists moodCell
         */
        getDeleteButtonIcon() {
            return Icon.REMOVE;
        }

        _deleteButtonTapped() {
            var result = true;

            if (this.delegate.didRequestRemovingCell) {
                result = this.delegate.didRequestRemovingCell.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView, this);
            }

            if (result === undefined) {
                result = true;
            }

            Q(result).done(function (res) {
                if (res) {
                    // either the tableView handles the removing, or the cell.
                    if (this.tableView._removeCell) {
                        this.tableView._removeCell.call(this.tableView, this, this.sectionIdx, this.rowIdx);
                    } else {
                        $(this.element).remove(); // TODO-woessto when do you remove the event listener and so on?

                        console.error("editable base cell: cells won't be unregistered");
                    } // the delegate might want to update sth after the cell was removed


                    if (this.delegate.didRemoveCell) {
                        this.delegate.didRemoveCell.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView, this);
                    }
                }
            }.bind(this), function () {
                console.info("Promise said no to delete this cell!");
            });
        }

        /**
         * Validates if the cell if inside the editable range of the current active editMap
         * @returns {boolean|*}
         * @private
         */
        _isWithinEditableRange() {
            var isInRange = false;

            if (this.tableView instanceof GUI.EditableTableView) {
                var editMap = this.tableView.editSectionsMap[this.sectionIdx];
                isInRange = editMap && editMap.editActive && editMap.numberOfEditableCells > 0 && this.rowIdx.isBetween(editMap.editStartIndex - 1, editMap.editStartIndex + editMap.numberOfEditableCells);
            }

            return isInRange;
        }

    }

    GUI.TableViewV2.Cells.EditableBaseCell = EditableBaseCell;

    GUI.TableViewV2.Cells.EditableBaseCell.Template = function () {
        var getDeleteIcon = function getDeleteIcon(iconSrc) {
            return '<div class="cell__delete-icon-placeholder cell__icon-placeholder">' + ImageBox.getResourceImageWithClasses(iconSrc, "delete-icon-placeholder__icon icon-placeholder__icon") + '</div>';
        };

        var getSortIcon = function getSortIcon() {
            return '<div class="cell__sort-icon-placeholder cell__icon-placeholder">' + ImageBox.getResourceImageWithClasses(Icon.SORT, "sort-icon-placeholder__icon icon-placeholder__icon") + '</div>';
        };

        return {
            getDeleteIcon: getDeleteIcon,
            getSortIcon: getSortIcon
        };
    }();

    GUI.TableViewV2.CellType.EDITABLE_CELL = "EditableBaseCell";
    return GUI;
}(window.GUI || {});
