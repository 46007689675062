import * as React from "react"
import Svg, { Path } from "react-native-svg";

function LoxoneSlogan(props) {
    return (
        <Svg isreact="true"
            height={32}
            viewBox="0 0 144 32"
            width={144}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M23.255 26.572h11.117v-3.544h-8.586V5.47H22v19.188zm24.988-6.773c0 1.399-.496 3.748-3.831 3.79h-1.168c-1.408 0-3.782-.496-3.816-3.79v-7.598c0-1.399.496-3.756 3.816-3.79h1.183c1.412 0 3.782.496 3.816 3.79zm2.416 3.915c.923-1.592 1.022-3.27 1.022-3.915v-7.617c-.038-3.563-1.82-5.321-3.308-6.17C46.77 5.099 45.08 5 44.43 5h-1.202c-3.587.038-5.358 1.808-6.213 3.286-.92 1.592-1.019 3.27-1.019 3.915v7.617c.038 3.563 1.82 5.321 3.309 6.17 1.603.913 3.293 1.012 3.942 1.012h1.194c3.587-.042 5.362-1.808 6.217-3.286m12.73-7.682L56.482 5.47h-4.24l6.907 10.562-6.907 10.562h4.24zm.95-1.451l5.957-9.111h-4.24l-3.838 5.87zm1.717 12.013h4.24l-5.957-9.11-2.118 3.24zm17.05-6.795c0 1.399-.5 3.748-3.83 3.79h-1.168c-1.412 0-3.782-.496-3.816-3.79v-7.598c0-1.399.5-3.756 3.816-3.79h1.183c1.408 0 3.781.496 3.816 3.79zm2.416 3.915c.924-1.592 1.023-3.27 1.023-3.915v-7.617c-.038-3.563-1.82-5.321-3.309-6.17C81.634 5.099 79.943 5 79.294 5h-1.202c-3.587.038-5.357 1.808-6.212 3.286-.92 1.592-1.02 3.27-1.02 3.915v7.617c.04 3.563 1.821 5.321 3.31 6.17C75.771 26.901 77.462 27 78.11 27h1.195c3.587-.042 5.361-1.808 6.216-3.286m19.92 2.846h.435V5.47h-3.461v15.19L94.624 5.47H90.02v21.09h3.462V10.635l8.17 15.925h.76zm7.972-3.532v-5.825h5.526V13.66h-5.526v-2.752l-1.248-1.91H122V5.455h-12.372v19.173l1.275 1.948H122v-3.544h-8.586z"
            />
        </Svg>
    )
}

export default LoxoneSlogan
