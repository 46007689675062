import PropTypes from "prop-types";
import {View} from "react-native";
import globalStyles from "GlobalStyles";
import {LxReactText} from "LxComponents"
import {LxReactPressable} from "./Components";


function LxReactRenderErrorView(props) {
    const textStyles = {
        color: "white"
    };

    const getHumanReadableError = () => {
        if (props.error instanceof Error) {
            return (
                <>
                    <LxReactText style={{
                        ...textStyles,
                        ...globalStyles.textStyles.title1.bold
                    }}>{props.error.name}</LxReactText>
                    <LxReactText style={{
                        ...textStyles,
                        ...globalStyles.textStyles.body.default
                    }}>{props.error.message}</LxReactText>
                    <LxReactText style={{
                        ...textStyles,
                        ...globalStyles.textStyles.footNote.default
                    }}>{props.error.cause}</LxReactText>
                </>
            )
        } else if (props.error instanceof Object && "code" in props.error && "message" in props.error) {
            return (
                <>
                    <LxReactText style={{
                        ...textStyles,
                        ...globalStyles.textStyles.body.default
                    }}>{props.error.code}</LxReactText>
                    <LxReactText style={{
                        ...textStyles,
                        ...globalStyles.textStyles.footNote.default
                    }}>{props.error.message}</LxReactText>
                </>
            )
        } else {
            return <LxReactText style={{
                ...textStyles,
                ...globalStyles.textStyles.body.default
            }}>{`${props.error}`}</LxReactText>
        }
    }

    const getDebugArea = () => {
        return (<>
            <LxReactText key={"debugLogText"} style={{
                ...textStyles,
                ...globalStyles.textStyles.body.default,
                paddingHorizontal: 10
            }}>
                An issue occurred, please send the debuglog to issue@loxone.com and notify us about this in Teams
            </LxReactText>
            <LxReactPressable pkey={"debugLogButton"} onPress={() => {
                GUI.DebugScreen.show();
            }}>
                <LxReactText key={"debugLogText"} style={{
                    ...textStyles,
                    ...globalStyles.textStyles.body.default,
                    backgroundColor: globalStyles.colors.anthracite,
                    padding: 10,
                    borderRadius: 13
                }}>
                    Open DebugLog
                </LxReactText>
            </LxReactPressable>
        </>)
    }

    console.warn("It looks like we ran into an error!");
    console.error(props.error);

    return (
        <View style={{
            backgroundColor: globalStyles.colors.red,
            flex: 1,
            justifyContent: "center",
            alignItems: "center"
        }}>
            {getHumanReadableError()}
            {getDebugArea()}
        </View>
    )
}

LxReactRenderErrorView.propTypes = {
    error: PropTypes.oneOfType([
        PropTypes.instanceOf(Error),
        PropTypes.string,
        PropTypes.shape({
            code: PropTypes.number,
            message: PropTypes.string
        })
    ])
}

export default LxReactRenderErrorView;

