'use strict';

define("RadioControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class RadioControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [],
                outputs = control.details.outputs,
                isActive,
                activeNr = parseInt(states.activeChoiceNr);
            Object.keys(outputs).forEach(function (key) {
                isActive = parseInt(key) === activeNr;
                cmds.push(this.createSelectable(outputs[key], key, null, isActive));
            }.bind(this));

            if (control.details.allOff) {
                isActive = activeNr === 0;
                cmds.push(this.createSelectable(control.details.allOff, Commands.RADIO.RESET, null, isActive));
            }

            return cmds;
        }

    };
});
