'use strict';

define("IRoomControllerV2021ControlCommandSrc", ["IRoomControllerV2ControlCommandSrc", "IRoomControllerV2021ControlEnums"], function (IRoomControllerV2ControlCommandSrc, CtrlEnums) {
    return class IRoomControllerV2021ControlCommandSrc extends IRoomControllerV2ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

    };
});
