import {Animated} from "react-native-web";
import {useEffect,useRef} from "react";

/**
 * Will move it's top-property from height * -1 to 0.
 * @param children  content to be rendered
 * @param style     adoptions to the style
 * @param show      whether or not it is to be shown, if true it moves down, if false it moves up and disappears
 * @param height    the height of the content
 * @returns {JSX.Element}
 * @constructor
 */
export default function LxReactNotification({children, style, show, height = -60}) {
    const moveDownAnim = useRef(new Animated.Value(height * -1));

    const notifStyle = { zIndex: 1 };

    useEffect(() => {
        Animated.timing(
            moveDownAnim.current,
            {
                toValue: show ? 0 : (height * -1),
                duration: 300,
                useNativeDriver: true,
            }
        ).start();
    }, [show]);

    return <Animated.View style={[notifStyle, style, {top: moveDownAnim.current}]}>
        {children}
    </Animated.View>
}
