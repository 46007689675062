import * as React from "react"
import Svg, { Path } from "react-native-svg"

function HomeKit(props) {
    return (
        <Svg isreact="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path d="M12.05 1c.167 0 .313.048.549.235l4.557 3.605.442.349.444.352.445.351.221.175V4.24c0-.298.133-.341.35-.341h1.61c.188 0 .293.076.293.341v3.593s2.454 1.957 2.554 2.035c.382.302.275.738-.151.738H21.34v10.999c0 .498-.15.776-.466.905a1.27 1.27 0 01-.494.09H3.72a1.27 1.27 0 01-.494-.09c-.316-.129-.466-.407-.466-.905V10.606H.736c-.426 0-.533-.436-.15-.738l5.067-4.007.56-.443L11.5 1.235c.236-.187.383-.235.549-.235zm0 1.833c-.151 0-.305.061-.484.203L8.512 5.411l-.695.54a2298.54 2298.54 0 00-3.15 2.451c-.307.243-.577.638-.577 1.255v10.631c0 .508.261.861.791.861h14.332c.529 0 .79-.353.79-.861V9.657c0-.617-.27-1.012-.577-1.255-.143-.113-1.605-1.251-3.146-2.45l-.464-.361-3.282-2.555c-.18-.142-.338-.203-.484-.203zm0 2.851a.61.61 0 01.368.146l.069.054 4.76 3.765c.3.238.4.481.4.903v7.635c0 .435-.247.638-.608.638H7.06c-.362 0-.607-.203-.607-.638v-7.635c0-.422.099-.665.398-.903l4.761-3.765.069-.054a.61.61 0 01.368-.146zm0 1.69a.488.488 0 00-.308.12l-.069.054c-.492.39-3.384 2.674-3.556 2.813-.282.227-.282.433-.282.693v5.82c0 .37.205.514.486.514h7.458c.281 0 .487-.144.487-.513v-5.916c-.005-.217-.036-.4-.283-.598-.086-.07-.852-.675-1.658-1.312l-.372-.295-1.526-1.206-.068-.054a.502.502 0 00-.309-.12zm0 2.535c.055 0 .147.028.209.076.062.05 1.825 1.401 1.906 1.467a.612.612 0 01.225.477v3.267c0 .263-.131.322-.316.322h-4.046c-.185 0-.316-.059-.316-.322V11.93c0-.174.072-.353.224-.477.055-.044.856-.66 1.402-1.08l.247-.19c.145-.11.243-.186.256-.197a.385.385 0 01.21-.076zm.001 1.692a.156.156 0 00-.086.031l-.775.61a.254.254 0 00-.093.197v1.543c0 .11.054.134.131.134h1.647c.076 0 .131-.024.131-.134V12.44a.254.254 0 00-.094-.198l-.774-.609a.159.159 0 00-.087-.031z" />
        </Svg>
    )
}

export default HomeKit
