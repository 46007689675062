import PropTypes from "prop-types";
import { useLiveState, LxReactText, LxReactPressable } from "LxComponents"
import {View} from "react-native";
import globalStyles from "GlobalStyles"
import EmLoadDisplay from "./emLoadDisplay";
import { useNavigation } from "@react-navigation/native";
import LoadPrioSettings from "./loadPrioSettings";
import Icons from "IconLib";

export default function EmLoadsList({controlUuid}) {
    const liveState = useLiveState(controlUuid, ["loads"]);
    const navigation = useNavigation();
    const styles = {
        rootCntr: {
            flexDirection: "column"
        },
        loadListHeaderCntr: {
            flexDirection: "row"
        },
        loadListHeading: {
            ...globalStyles.textStyles.title3.bold,
            color: globalStyles.colors.text.primary,
            flex: 1
        },
        sortButton: {
            paddingRight: globalStyles.sizes.small,
            paddingLeft: globalStyles.sizes.small
        },
        sortButtonIcon: {
            width: globalStyles.sizes.icons.small,
            height: globalStyles.sizes.icons.small,
            justifySelf: "center",
            alignSelf: "center",
            fill: globalStyles.colors.text.primary,
        },
        loadsCntr: {
            flexDirection: "column",
        },
    }

    const renderLoads = () => {
        if (liveState.states && Array.isArray(liveState.states.loads)) {
            return <View style={styles.loadsCntr}>
                {liveState.states.loads.map(renderLoad)}
            </View>;
        }
    }

    const renderLoad = (loadObj) => {
        return <EmLoadDisplay controlUuid={controlUuid} loadObj={loadObj} key={loadObj.uuid} />
    }

    const handleModifyModes = () => {
        navigation.navigate(LoadPrioSettings.name, {
            loads: liveState.states.loads,
            controlUuid: controlUuid
        });
    }

    const renderHeader = () => {
        return <View style={styles.loadListHeaderCntr}>
            <LxReactText style={styles.loadListHeading}>{_("energymanager2.loads")}</LxReactText>
            <LxReactPressable style={styles.sortButton} hitSlop={25} onPress={handleModifyModes}>
                <Icons.ListIndicator style={styles.sortButtonIcon}/>
            </LxReactPressable>
        </View>
    }

    return <View style={styles.rootCntr}>
        {renderHeader()}
        {renderLoads()}
    </View>
}

EmLoadsList.propTypes = {
    controlUuid: PropTypes.string.isRequired
}
