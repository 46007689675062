import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"

function SystemStateWarningCircled(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Circle cx={12} cy={12} r={12} />
            <Path
                fill="#FFF"
                d="M17.972 17.127l-5.739-11c-.089-.169-.379-.169-.467 0l-5.739 11c-.087.165.039.362.233.362h11.477c.195 0 .32-.197.234-.362zm-6.222-7.062a.252.252 0 01.251-.25.25.25 0 01.249.25v3.826a.25.25 0 11-.5 0v-3.826zm.25 5.81a.375.375 0 110-.75.375.375 0 010 .75z"
            />
        </Svg>
    )
}

export default SystemStateWarningCircled
