'use strict';

define([], function () {
    return class ControlSelectionScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div class="control-selection-screen" />'));
            this.currentGroup = ActiveMSComponent.getStructureManager().getGroupByUUID(details.groupUUID);
            this.groups = ActiveMSComponent.getStructureManager().getGroupContentByUUID(this.currentGroup.uuid, this.currentGroup.groupType);
            this.selectionSource = details.selectionSource;
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarText() {
            return _("manual-favorites.select-favorites");
        }

        // TableViewDataSource Methods
        numberOfSections() {
            return this.groups.length;
        }

        titleForHeaderInSection(section) {
            return this.groups[section].name;
        }

        numberOfRowsInSection(section) {
            return this.groups[section].controls.length;
        }

        cellTypeForCellAtIndex() {
            return GUI.TableViewV2.CellType.CHECKABLE_RATING;
        }

        contentForCell(cell, section, row) {
            var control = this.groups[section].controls[row];
            var checked = typeof this.selectionSource.controls[control.uuidAction] === "number";
            var rating = 0;

            if (checked) {
                rating = this.selectionSource.controls[control.uuidAction];
            }

            return {
                title: control.name,
                selected: checked,
                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE,
                rating: rating
            };
        }

        // TableViewDelegate Methods
        didCheckCell(cell, section, row, tableView, selected) {
            var controlsOfGroup = this.groups[section].controls;

            if (selected) {
                this.selectionSource.controls[controlsOfGroup[row].uuidAction] = 0;
            } else {
                delete this.selectionSource.controls[controlsOfGroup[row].uuidAction];
            }
        }

        didChangeRating(rating, section, row) {
            var controlsOfGroup = this.groups[section].controls;
            this.selectionSource.controls[controlsOfGroup[row].uuidAction] = rating;
        }

    };
});
