import {LxReactTableView, LxReactControl, useControl} from "LxComponents"
import {useMemo} from "react"
import {View} from "react-native"
import globalStyles from "GlobalStyles"

export default function LxReactLinkedControlsList({controlUuid, style}) {

    const control = useControl(controlUuid);

    const getTableContent = useMemo(() => {
        if (!control) {
            return [];
        }
        return [{
            headerTitle: _("control.linked-controls"),
            rows: control.linkedControls.map((linkedCtrl) => {
                return {
                    mainContent: {
                        comp: LxReactControl,
                        props: {
                            controlUuid: linkedCtrl.uuidAction,
                            displayAsCell: true,
                            showGroupDetails: true,
                            displayRoomName: true
                        }
                    }
                }
            })
        }]
    }, [controlUuid, control]);

    return control.hasLinkedControls ? <View style={[styles.rootCntr, style]}>
        <LxReactTableView tableContent={getTableContent} />
    </View> :
        null;
}

const styles = {
    rootCntr: {
        height: "auto",
        marginTop: globalStyles.sizes.medium
    }
}