'use strict';

define(["ControlInfoView", 'IntercomVideoView', 'IntercomControlEnums'], function (ControlInfoView, IntercomVideoView, IntercomControlEnums) {
    return class IntercomControlInfoView extends ControlInfoView {
        constructor() {
            super(...arguments);
        }

        viewDidLoad() {
            var prmsArr = [super.viewDidLoad(...arguments)];
            var errorButtonEl = $('<div class="intercom-control-info-view__err-button overlay-button">' + ImageBox.getResourceImageWithClasses(Icon.Intercom.Button.CAUTION, "err-button__icon overlay-button__icon") + '</div>');
            this.errorButton = new GUI.LxButton(this, errorButtonEl[0], window.Styles.colors.red, [], true);
            this.errorButton.useChildsAsActiveParts('fill');
            this.videoView = new IntercomVideoView(this.control);
            this.videoView.getElement().addClass('intercom-control-info-view__video-view');
            this.videoView.onVideoStarted = this.onVideoStarted.bind(this);
            this.videoView.onVideoStopped = this.onVideoStopped.bind(this);
            this.videoView.onNoVideo = this.onNoVideo.bind(this);
            prmsArr.push(this.appendSubview(this.videoView)); // Append after the videoView to ensure the button is clickable

            prmsArr.push(this.appendSubview(this.errorButton)); // Initially hide this button, it will be shown when the video stream has an error

            prmsArr.push(this.hideSubview(this.errorButton));
            return Q.all(prmsArr);
        }

        viewDidDisappear() {
            this.errorButton.onButtonTapped = null;
            return super.viewDidDisappear(...arguments);
        }

        onVideoStarted() {
            this.hideSubview(this.errorButton);
        }

        onVideoStopped() {
            this.hideSubview(this.errorButton);
        }

        onNoVideo(cause) {
            this.showSubview(this.errorButton);

            this.errorButton.onButtonTapped = function () {
                this.ViewController.showState(IntercomControlEnums.ScreenState.ABOUT, null, {
                    control: this.control,
                    error: this.control.getVideoFailedTexts(cause)
                });
            }.bind(this);
        }

        presentSettingsWindow() {
            if (this.control.hasAudio()) {
                this.ViewController.showState(IntercomControlEnums.ScreenState.SETTINGS, null, {
                    control: this.control,
                    isTalking: this.control.isTalking
                });
            } else {
                this.ViewController.showState(IntercomControlEnums.ScreenState.ABOUT, null, {
                    control: this.control
                });
            }
        }

    };
});
