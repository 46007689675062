import * as React from "react"
import Svg, { Path } from "react-native-svg"

function HouseLined(props) {
    return (
        <Svg
            isreact="true"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M10.835 1.284a1.75 1.75 0 012.33 0l8.25 7.358c.372.332.585.807.585 1.306V21.25A1.75 1.75 0 0120.25 23h-4a1.75 1.75 0 01-1.75-1.75v-5a.25.25 0 00-.25-.25h-4.5a.25.25 0 00-.25.25v5A1.75 1.75 0 017.75 23h-4A1.75 1.75 0 012 21.25V9.948c0-.499.213-.974.585-1.306l8.25-7.358zm1.331 1.12a.25.25 0 00-.332 0L3.584 9.76a.25.25 0 00-.084.187V21.25c0 .138.112.25.25.25h4a.25.25 0 00.25-.25v-5c0-.966.784-1.75 1.75-1.75h4.5c.966 0 1.75.784 1.75 1.75v5c0 .138.112.25.25.25h4a.25.25 0 00.25-.25V9.948a.25.25 0 00-.084-.187l-8.25-7.358z"
            />
        </Svg>
    )
}

export default HouseLined
