import {View} from "react-native";
import AmbientWeatherInfoView from "./AmbientWeatherInfoView";
import AmbientDateInfoView from "./AmbientDateInfoView";
import PropTypes from "prop-types";

function AmbientHeaderInfoView(props) {
    const showInfoInEcoScreen = PersistenceComponent.getEcoScreenSettings().showTime;

    if (props.ecoModeActive && !showInfoInEcoScreen) {
        return null;
    }

    return <View style={{width: "fit-content"}}>
        <AmbientWeatherInfoView/>
        <AmbientDateInfoView ecoModeActive={props.ecoModeActive}/>
    </View>
}

AmbientHeaderInfoView.propTypes = {
    ecoModeActive: PropTypes.bool
}

export default AmbientHeaderInfoView;
