import * as React from "react"
import Svg, { Path } from "react-native-svg"

function ArrowRight(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path
                fillRule="evenodd"
                d="M17.747 11.336a1 1 0 010 1.328l-8 9a1 1 0 01-1.494-1.328L15.662 12l-7.41-8.336a1 1 0 111.495-1.328l8 9z"
                clipRule="evenodd"
            />
        </Svg>
    )
}

export default ArrowRight
