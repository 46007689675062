'use strict';

define([], function () {
    return {
        DeviceType: {
            NONE: 0,
            AIR: 1,
            TREE: 2
        }
    };
});
