import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"

function ArrowRightCircled(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
            <Circle cx={15} cy={15} r={15} />
            <Path
                d="M17.542 15l-.277.265-5.309 4.949a.783.783 0 00-.021 1.138l.083.079a.877.877 0 00.6.235.847.847 0 00.6-.224l6.245-5.862a.828.828 0 00.01-1.15l-6.23-5.87a.902.902 0 00-.601-.227.867.867 0 00-.601.236l-.082.079a.858.858 0 00.011 1.14z"
                fill="#fff"
            />
        </Svg>
    )
}

export default ArrowRightCircled
