'use strict';
/*
 A significant message view, displays with an icon and a text
 */

window.GUI = function (GUI) {
    class SignificantMessageView extends GUI.View {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(message, icon) {
                return $('<div>' + ImageBox.getResourceImageWithClasses(icon, "significant-message-view__icon") + '<div class="significant-message-view__message-text">' + message + '</div>' + '</div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        /**
         *
         * @param delegate
         * @param message
         * @param [color]   default: window.Styles.colors.red
         * @param [icon]    default: Icon.WARNING
         */
        constructor(delegate, message, color, icon) {
            super(SignificantMessageView.Template.getTemplate(message, icon || Icon.WARNING));
            this.delegate = delegate;
            this.message = message;
            this.color = color || window.Styles.colors.red;
        }

        viewWillAppear() {
            super.viewWillAppear();
            this.element.css("background-color", this.color);
            this.element.toggleClass("clickable", this.delegate.didTapOnSignificantMessage);
        }

        viewDidAppear() {
            super.viewDidAppear();

            if (this.delegate.didTapOnSignificantMessage) {
                this._significantActionHandler = Hammer(this.element[0]).on(tapEvent(), this.delegate.didTapOnSignificantMessage.bind(this.delegate, this));
            }
        }

        viewDidDisappear() {
            if (this._significantActionHandler) {
                this._significantActionHandler.dispose();

                this._significantActionHandler = null;
            }

            super.viewDidDisappear();
        }

    }

    GUI.SignificantMessageView = SignificantMessageView;
    return GUI;
}(window.GUI || {});
