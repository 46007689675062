import React, {useRef} from "react";
import {WeatherComp} from "LxComponents";
import globalStyles from "GlobalStyles";
function AmbientWeatherInfoView(props) {
    const weatherControl = useRef(ActiveMSComponent.getStructureManager().getWeatherServer()).current;

    return <>
        {
            weatherControl && <WeatherComp containerStyle={styles.container}
                uuidAction={weatherControl.uuidAction}
                tintColor={globalStyles.colors.white} showWeatherAdButton={false} isInAmbientMode={true}/>
        }
    </>
}

const styles = {
    container: {
        width: "fit-content"
    }
}

export default AmbientWeatherInfoView
