'use strict';

define("AtheneControl", ["Control", "AtheneMediaHandler", "ActivityImageCache"], function (Control, AtheneMediaHandler, ActivityImageCache) {
    return class AtheneControl extends Control {

        //region Getter
        get isTrustMember() {
            return "trustAddress" in this.details;
        } //endregion Getter


        constructor() {
            super(...arguments);
            ActivityImageCache.shared(this).prepare();
        }

        getIcon() {
            return Icon.Athene.BELL_TILE;
        }

        getControlContentIdentifier() {
            var contentIdentifier = super.getControlContentIdentifier(...arguments);

            if (!AtheneMediaHandler.shared(this).availableFeatures.multiMedia) {
                contentIdentifier = "AtheneControlContentUnsupported";
            }

            return contentIdentifier;
        }

    };
});
