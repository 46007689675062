'use strict';

define("AudioZoneCentralControlContent", ["ControlActionCardsScreen", "AudioZoneControl"], function (ControlActionCardsScreen, AudioZoneControl) {
    return class AudioZoneCentralControlContent extends ControlActionCardsScreen {
        constructor() {
            super(...arguments);
        }

        getControlSelectionCellType(ctrl) {
            if (ctrl instanceof AudioZoneControl) {
                return GUI.TableViewV2.CellType.AUDIO_ZONE;
            } else {
                return GUI.TableViewV2.CellType.AUDIO_ZONE_V2;
            }
        }

        getActionTableView() {
            return new GUI.CenteredCardView(this.tableViewDataSource, this.tableViewDelegate);
        }

        getActionTableViewCellType() {
            return GUI.TableViewV2.CellType.ROUND_COMFORT_ACTION;
        }

        // Private
        _createCellObjectForCmd(cmdObj) {
            var cellObj = super._createCellObjectForCmd(...arguments); // Override the default cell type (COMFORT_ACTION) with ROUND_COMFORT_ACTION


            cellObj.type = this.getActionTableViewCellType();
            return cellObj;
        }

    };
});
