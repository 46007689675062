import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Snowflake(props) {
  return (
    <Svg isreact="true"
      width={22}
      height={24}
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M11.5.5a.5.5 0 00-1 0v3.293L8.854 2.146a.5.5 0 10-.708.708L10.5 5.207V7h-1a.5.5 0 000 1h1v3.134L7.786 9.567l.5-.866a.5.5 0 10-.866-.5l-.5.866-1.553-.897-.861-3.215a.5.5 0 10-.966.26l.602 2.248-2.851-1.646a.5.5 0 10-.5.866l2.851 1.646-2.249.603a.5.5 0 00.26.966l3.214-.861 1.553.896-.5.866a.5.5 0 00.866.5l.5-.866L10 12l-2.714 1.567-.5-.866a.5.5 0 10-.866.5l.5.866-1.553.896-3.215-.861a.5.5 0 10-.259.966l2.25.603L.79 17.317a.5.5 0 00.5.866l2.851-1.646-.602 2.249a.5.5 0 00.966.258l.861-3.215 1.553-.896.5.866a.5.5 0 00.866-.5l-.5-.866 2.714-1.567V16h-1a.5.5 0 000 1h1v1.793l-2.354 2.354a.5.5 0 10.708.707l1.646-1.647V23.5a.5.5 0 001 0v-3.293l1.646 1.647a.5.5 0 00.707-.707L11.5 18.793V17h1a.5.5 0 000-1h-1v-3.134l2.714 1.567-.5.866a.5.5 0 10.866.5l.5-.866 1.553.897.861 3.215a.5.5 0 10.966-.26l-.602-2.248 2.851 1.646a.5.5 0 00.5-.866l-2.851-1.646 2.249-.603a.5.5 0 10-.26-.966l-3.214.862-1.553-.897.5-.866a.5.5 0 10-.866-.5l-.5.866L12 12l2.714-1.567.5.866a.5.5 0 10.866-.5l-.5-.866 1.553-.896 3.215.861a.5.5 0 10.259-.966l-2.25-.602 2.852-1.647a.5.5 0 00-.5-.866l-2.851 1.647.602-2.25a.5.5 0 10-.966-.258l-.861 3.215-1.553.896-.5-.866a.5.5 0 10-.866.5l.5.866-2.714 1.567V8h1a.5.5 0 000-1h-1V5.207l2.354-2.353a.5.5 0 10-.708-.708L11.5 3.793V.5z"
      />
    </Svg>
  )
}

export default Snowflake
