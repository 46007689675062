import globalStyles from "GlobalStyles";
import {
    LxReactContextMenuHandler,
    LxReactText,
    LxReactImageView,
    navigatorConfig,
    LxReactPressable,
    useControlCtxtMenuOptions,
    LxControlContentContainer,
    ControlContext,
    AmbientContext,
    useBackNavigation
} from "LxComponents";
import React, {useCallback, useContext, useEffect, useMemo, useRef} from "react";
import {View} from "react-native-web";
import {useNavigation} from "@react-navigation/native";
import LxControlCommands from "./LxControlCommands";
import LxControlLinkedControls from "./LxControlLinkedControls";
import LxControlSubControls from "./LxControlSubControls";
import LxControlNoteView from "./LxControlNoteView";
import LxControlStatesSections from "./LxControlStatesSections";
import PropTypes from "prop-types";
import Icons from "IconLib";
import LxControlLockedContent from "./LxControlLockedContent";
import LxControlMoreSection from "./LxControlMoreSection";
import useCentralControlFeatures from "./useCentralControlFeatures";
import LxReactHistorySection from "../controlHistory/LxReactHistorySection";

function LxBaseControlContent(props) {
    const {isAmbientMode} = useContext(AmbientContext)
    const {dynamicControl, control, states} = useContext(ControlContext)
    const isCentralControl = useCentralControlFeatures(control);
    const {
        stickyTopComp,
        stickyTopCompProps,
        showStateText = true,
        showStateIcon = true,
        showStatesFirst = false,
        showCommands = true,
        showStates = true,
        showMoreSection = true,
        hideLinkedControls = false,
        hideControlNote = false,
        customTitle,
        customStateText,
        customClose,
        customLeftActionIconSrc,
        onAppear,
        onDestroy,
        onIconTapped,
        onSubtitleTapped,
        getAdditionalContextMenuOptions,
        useFullWidth = false,
        children
    } = {
        ...props,
        ...((props.route && props.route.params) || {}),
        ...(control ? control.getReactControlContentFlags(states) : {})
    };

    const moreButtonStyles = useMemo(() => {
        return {
            container: {
                display: "flex",
                height: HD_APP ? globalStyles.sizes.cc_more_hd : globalStyles.sizes.bigger,
                width: "100%",
                marginTop: "auto"
            },
            pressable: {
                justifyContent: "flex-end",
                flexDirection: "row",
            },
            text: {
                alignSelf: "center",
                lineHeight: HD_APP ? globalStyles.fontSettings.lineHeights.cc_more_hd : globalStyles.fontSettings.lineHeights.title1,
                color: globalStyles.colors.text.secondary,
                fontFamily: globalStyles.fontSettings.families.regular,
                fontSize: HD_APP ? globalStyles.fontSettings.sizes.smallReg : globalStyles.fontSettings.sizes.smaller
            },
            icon: {
                alignSelf: "center",
                height: HD_APP ? globalStyles.sizes.icons.big : globalStyles.sizes.icons.small,
                width: HD_APP ? globalStyles.sizes.icons.big : globalStyles.sizes.icons.small,
                fill: globalStyles.colors.buttonDisabledBg,
                margin: globalStyles.sizes.smaller,
                marginRight: HD_APP ? globalStyles.sizes.small : null
            }
        }
    }, [HD_APP])


    const rightActionCodesRef = useRef({});
    const navigation = useNavigation();
    const contextMenuOptions = useControlCtxtMenuOptions((control || {}).uuidAction, states, navigation)

    useEffect(() => {
        onAppear && onAppear(control, states)

        return () => {
            onDestroy && onDestroy(control, states)
        }
    }, [])

    // region Title Bar
    const shouldShowStatesFirst = () => {
        if (typeof showStatesFirst === "function") {
            return showStatesFirst(control, states)
        } else {
            return !!showStatesFirst;
        }
    }

    const shouldShowStateIcons = () => {
        if (typeof showStateIcon === "function") {
            return showStateIcon(control, states)
        } else {
            return !!showStateIcon;
        }
    }
    const hasMessageCenterEntries = () => states.messageCenterEntries && states.messageCenterEntries.length;

    const allContextMenuOptions = useMemo(() => {
        let additionalContextMenuOptions = getAdditionalContextMenuOptions ? getAdditionalContextMenuOptions(control, states) : [];
        return [...contextMenuOptions, ...additionalContextMenuOptions];
    }, [contextMenuOptions, getAdditionalContextMenuOptions])

    const handleShowContextMenu = useCallback(() => {
        if (allContextMenuOptions && allContextMenuOptions.length) {
            LxReactContextMenuHandler.shared.showContextMenu(allContextMenuOptions, control.getName());
        }
    }, [dynamicControl, contextMenuOptions]);

    const openMessageCenterEntry = (mscEntry) => NavigationComp.showState(ScreenState.MessageCenterMessageScreen, {entry: mscEntry}, AnimationType.NONE)


    const onRightAction = (idx) => {
        let actionCode = rightActionCodesRef.current[idx];
        switch(actionCode) {
            case RightActionIndexes.MESSAGE_CENTER:
                openMessageCenterEntry(states.messageCenterEntries[0])
                break;
            case RightActionIndexes.MORE:
                handleShowContextMenu()
                break;
            case RightActionIndexes.EXPAND:
                NavigationComp.showControlContent(control, null, null, {isInAmbientMode: false})
                break;
        }
    }

    useEffect(() => {
        if (!control) {
            return;
        }
        let title = customTitle || control.getName();

        if (states.universalIsLocked) {
            title = _("control.lock.locked-title")
        }

        let navCfg = {
            title,
            leftActions: [{
                action: ({dimensions, props, key}) => {
                    return <LxReactImageView source={customLeftActionIconSrc ?? Icon.Buttons.CLOSE}
                                             imageStyle={globalStyles.customStyles.titleBarIcon}/>
                }
            }],
            rightActions: [],
            navigation,
            isAmbientMode,
            onRightAction: onRightAction,
            headerStyle: [styles.header, {backgroundColor: isAmbientMode ? globalStyles.colors.transparent : globalStyles.colors.black}]
        }

        if (customClose) {
            navCfg.onLeftAction = customClose;
        }

        let actionIndex = 0;
        if (hasMessageCenterEntries()) {
            navCfg.rightActions.push({
                action: ({dimensions, props, key}) => {
                    return <MessageCenterIcon/>
                }
            })
            rightActionCodesRef.current[actionIndex] = RightActionIndexes.MESSAGE_CENTER;
            actionIndex++
        }
        if (isAmbientMode) {
            navCfg.rightActions.push({
                action: ({dimensions, props, key}) => {
                    return <Icons.ExpandArrowsCircled {...globalStyles.customStyles.titleBarIcon}/>
                }
            })
            rightActionCodesRef.current[actionIndex] = RightActionIndexes.EXPAND;
            actionIndex++
        }

        if (allContextMenuOptions && allContextMenuOptions.length) {
            navCfg.rightActions.push({
                action: ({dimensions, props, key}) => {
                    return <LxReactImageView source={Icon.Buttons.MORE2}
                                             imageStyle={globalStyles.customStyles.titleBarIcon}/>
                }
            })
        }

        rightActionCodesRef.current[actionIndex] = RightActionIndexes.MORE;

        navigation.setOptions({
            ...navigatorConfig(navCfg)
        })
    }, [states, dynamicControl, customClose])
    // endregion

    useBackNavigation(() => {
        if (customClose) {
            customClose();
        } else {
            navigation.goBack();
        }
    }, [customClose]);

    // region Inner Components
    const MessageCenterIcon = (props) => {
        let entry = states.messageCenterEntries[0];
        let Icon = MessageCenterHelper.getIconForSeverityEntry(entry, true)
        let iconColor = MessageCenterHelper.getColorForSeverityEntry(entry)

        return <Icon {...globalStyles.customStyles.titleBarIcon} fill={iconColor}/>
    }

    const StateText = useMemo(() => {
        let tintColor = states.stateTextColor || states.stateTintColor || globalStyles.colors.text.secondary
        if (states.stateTextForContent) {
            if (onSubtitleTapped) {
                return <LxReactPressable style={styles.stateTextContainer}
                                         onPress={onSubtitleTapped.bind(this, control, states)}>
                    <LxReactText
                        style={[{color: tintColor}, globalStyles.textStyles.body.default]}>{customStateText || states.stateTextForContent}</LxReactText>
                </LxReactPressable>
            } else {
                return <View style={styles.stateTextContainer}>
                    <LxReactText
                        style={[{color: tintColor}, globalStyles.textStyles.body.default]}>{customStateText || states.stateTextForContent}</LxReactText>
                </View>
            }
        } else {
            return null;
        }
    }, [customStateText, states.stateTextColor, states.stateTextForContent]);

    const StateIcon = useMemo(() => {
        if (states.universalIsLocked) {
            return <View style={styles.stateIconContainer}>
                <Icons.Lock {...styles.stateIcon} fill={globalStyles.colors.red}/>
            </View>
        }

        let tintColor = states.stateIconColor || states.stateTintColor || globalStyles.colors.text.primary;

        let smallIcon = null;
        if (states.stateIconSmallForContent) {
            let outerContainerStyle = {...styles.smallStateIconOuterContainer};
            if (isAmbientMode) {
                outerContainerStyle.backgroundColor = "rgb(64,64,64)";
            }
            smallIcon = <View style={outerContainerStyle}>
                <LxReactImageView
                    containerStyle={[styles.smallStateIconContainer, {backgroundColor: states.stateIconSmallForContent.color || globalStyles.colors.text.primary}]}
                    imageStyle={styles.smallStateIcon}
                    partFillColors={styles.smallStateIconParts}
                    source={states.stateIconSmallForContent.iconSrc}/>
            </View>
        }

        let largeIcon;

        if (control) {
            if (control.reactIcon && control.useReactIconInContent) {
                largeIcon =  <control.reactIcon controlUuid={control.uuidAction}
                                                containerStyle={{...styles.stateIcon, fill: tintColor}}
                                                forCell={false}
                                                forContent={true}/>;
            } else {
                largeIcon = <LxReactImageView source={states.liveStateIcon || control.getIcon()}
                                              transformations={states.transformations} containerStyle={{fill: tintColor}}
                                              imageStyle={styles.stateIcon}/>
            }
        }

        if (onIconTapped) {
            return <LxReactPressable style={styles.stateIconContainer} onPress={onIconTapped}>
                {smallIcon}
                {largeIcon}
            </LxReactPressable>
        } else {
            return <View style={styles.stateIconContainer}>
                {smallIcon}
                {largeIcon}
            </View>
        }

    }, [dynamicControl, states.universalIsLocked, states.stateIconColor, states.liveStateIcon, states.stateIconSmallForContent, states.transformations])

    const StateInfo = useMemo(() => {
        if (states.stateInfo && (states.stateInfo.message || states.stateInfo.title)) {
            let info = states.stateInfo;
            let textColor = info.color || globalStyles.colors.text.primary;
            let boxColor = info.color ? applyAlphaChannelToColorString(info.color, 0.24) : globalStyles.colors.grey["600"];
            if (isAmbientMode) {
                boxColor = globalStyles.colors.transparent
            }
            return <View style={[styles.stateInfo.cell, {backgroundColor: boxColor}]}>
                {states.stateInfo.title && <LxReactText style={styles.stateInfo.title}>{info.title}</LxReactText>}
                {states.stateInfo.message &&
                    <LxReactText style={[styles.stateInfo.message, {color: textColor}]}>{info.message}</LxReactText>}
            </View>

        } else {
            return null;
        }
    }, [states.stateInfo])

    const getChildrenForLocation = useCallback((location) => {
        if (!children) {
            return null;
        } else if (Array.isArray(children)) {
            return children.filter(child => child && (child.props.location || CCLocation.DEFAULT) === location)
        } else if ((children.props.location || CCLocation.DEFAULT) === location) {
            return children
        } else {
            return null;
        }
    }, [children]);
    // endregion Inner Components

    const MoreButton = () => {
        if (control && control.isGrouped() && control.getSelectedControlUuids().length === 1) {
            return  <View style={moreButtonStyles.container}>
                <LxReactPressable onPress={handleMoreButtonTapped} pressableStyle={moreButtonStyles.pressable}>
                    <LxReactText style={moreButtonStyles.text}>{_('more').toUpperCase()}</LxReactText>
                    <Icons.ArrowRightCircled style={moreButtonStyles.icon}/>
                </LxReactPressable>
            </View>
        } else {
            return null;
        }
    }

    const handleMoreButtonTapped = () => {
        NavigationComp.showControlContent(control.getSelectedControls()[0]);
    }

    return <>
        <LxControlContentContainer
            useFullWidth={useFullWidth}
            stickyTopComp={stickyTopComp}
            stickyTopCompProps={stickyTopCompProps}
        >
            {showStateText && StateText}
            {!hideControlNote && <LxControlNoteView/>}
            {shouldShowStateIcons() && StateIcon}
            {!states.universalIsLocked && getChildrenForLocation(CCLocation.START)}
            {StateInfo}
            {!states.universalIsLocked && showStates && shouldShowStatesFirst() && <LxControlStatesSections/>}
            {!states.universalIsLocked && showCommands && <LxControlCommands/>}
            {!states.universalIsLocked && showStates && !shouldShowStatesFirst() && <LxControlStatesSections/>}
            <LxReactHistorySection />
            {isCentralControl && <LxControlSubControls/>}
            {!states.universalIsLocked && getChildrenForLocation(CCLocation.DEFAULT)}
            {!states.universalIsLocked && showMoreSection && <LxControlMoreSection/>}
            {states.universalIsLocked && <LxControlLockedContent/>}
            {!hideLinkedControls && <LxControlLinkedControls/>}
            <MoreButton/>
        </LxControlContentContainer>
    </>
}

const RightActionIndexes = {
    MESSAGE_CENTER: 0,
    EXPAND: 1,
    MORE: 2
}

export const CCLocation = {
    DEFAULT: 0,
    START: 1,
    AFTER_STATES: 2
}

const styles = {
    header: {
        borderBottomWidth: 0
    },
    expandArrowIconContainer: {
        height: globalStyles.sizes.icons.big,
        width: globalStyles.sizes.icons.big,
        padding: globalStyles.spacings.gaps.small,
        backgroundColor: globalStyles.colors.buttonDisabledBg,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%"
    },
    stateTextContainer: {
        paddingVertical: globalStyles.spacings.gaps.smallest,
        marginBottom: globalStyles.spacings.gaps.regular,
        textAlign: "center"
    },
    stateIconContainer: {
        marginVertical: globalStyles.spacings.gaps.veryBig
    },
    stateIcon: {
        width: globalStyles.sizes.icons.stateIcon,
        height: globalStyles.sizes.icons.stateIcon
    },
    stateIconParts: {
        path: globalStyles.colors.brand
    },
    smallStateIconOuterContainer: {
        flex: 0,
        position: "absolute",
        top: -globalStyles.spacings.gaps.smallRegular,
        left: -globalStyles.spacings.gaps.smallRegular,
        borderRadius: "50%",
        paddingRight: globalStyles.spacings.gaps.smallest,
        paddingBottom: globalStyles.spacings.gaps.smallest,
        backgroundColor: globalStyles.colors.black,
        zIndex: 1
    },
    smallStateIconContainer: {
        borderRadius: "50%"
    },
    smallStateIcon: {
        width: globalStyles.sizes.icons.regular,
        height: globalStyles.sizes.icons.regular,
        padding: globalStyles.spacings.gaps.verySmall
    },
    smallStateIconParts: {
        path: globalStyles.colors.black,
        rect: globalStyles.colors.black
    },
    ecoSmallStateIconParts: {
        path: globalStyles.colors.white,
        rect: globalStyles.colors.white
    },
    stateInfo: {
        cell: {
            width: "100%",
            marginVertical: globalStyles.spacings.gaps.small,
            paddingVertical: globalStyles.spacings.gaps.regular,
            backgroundColor: globalStyles.colors.grey["600"],
        },
        title: {
            ...globalStyles.textStyles.body.bold,
            color: globalStyles.colors.text.primary,
            width: "100%",
            textAlign: "center"
        },
        message: {
            ...globalStyles.textStyles.footNote.default,
            color: globalStyles.colors.text.secondary,
            width: "100%",
            textAlign: "center"
        }
    }
}

LxBaseControlContent.propTypes = {
    stickyTopComp: PropTypes.element,
    stickyTopCompProps: PropTypes.object,
    showStateIcon: PropTypes.bool,
    showCommands: PropTypes.bool,
    showStates: PropTypes.bool,
    showStatesFirst: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    hideLinkedControls: PropTypes.bool,
    hideControlNote: PropTypes.bool,
    customTitle: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    customStateText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    customClose: PropTypes.func,
    customLeftActionIconSrc: PropTypes.string,
    onAppear: PropTypes.func,
    onDestroy: PropTypes.func,
    onIconTapped: PropTypes.func,
    onSubtitleTapped: PropTypes.func,
    getAdditionalContextMenuOptions: PropTypes.func,
    hideMoreSection: PropTypes.bool,
    showMoreSection: PropTypes.bool,
    useFullWidth: PropTypes.bool
}

export default LxBaseControlContent;
