'use strict';

import globalStyles from "GlobalStyles";
import { useDeviceOrientation } from "LxComponents";

define(["ControlActionCellsScreen", "AtheneControlEnums", "AtheneMediaHandler", "AtheneSettingsScreen"], function (ControlActionCellsScreen, AtheneEnums, MediaHandler) {
    return class AtheneControlContentBase extends ControlActionCellsScreen {
        //region Getter
        get _shouldShowVideo() {
            if ("control" in this) {
                if (this.control.isTrustMember) {
                    return !!this.bell || "mediaHandler" in this && this.mediaHandler.isActivelyCalling;
                } else {
                    return !(this.states && this.states.universalIsLocked);
                }
            }
        } //endregion Getter


        constructor(details) {
            super(...arguments);
            this._urlComps = details.urlComps;
            this.remoteVideoEnabled = true;
            this.newActivities = false;
            this.lastActivities = [];
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.mediaHandler = MediaHandler.shared(this.control);
                this.mediaHandler.delegate = this;
                this.features = this.mediaHandler.availableFeatures;
                this.settingsTableViewDataSource = tableViewDataSource(null, null, this);
                this.settingsTableViewDelegate = tableViewDelegate(null, this);
                this.settingsTableView = new GUI.TableViewV2(this.settingsTableViewDataSource, this.settingsTableViewDelegate);
                this.leftButton = this.element.find('.screen__button-left');
                return this.appendSubview(this.settingsTableView, this.getActionTablePlaceholder());
            }.bind(this));
        }

        updateView(details) {
            // Just save the information we get here, it'll be processed in the viewDidAppear if necessary
            if (details && details.urlComps !== this._urlComps) {
                this._urlComps = details.urlComps;
            }

            return super.updateView(...arguments);
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function () {
                this.mediaHandler.lastActivitiesPrms.done(this.onNewLastActivities.bind(this));
            }.bind(this));
        }

        viewWillDisappear() {
            if (this._notReadyDelayTimeout) {
                clearTimeout(this._notReadyDelayTimeout);
            }

            return super.viewWillDisappear(...arguments);
        }

        receivedStates(states) {
            this.states = states;
            this.muted = states.muted;
            this.ttsTexts = states.answers;
            var prevBellState = this.bell;
            this.bell = states.bell;
            this.bellStateChanged = prevBellState !== states.bell;


            this._checkNotReadyScreen(states);

            this._reloadSettingsTable(states);

            super.receivedStates(...arguments);
        }

        shouldDisplayStateIcons() {
            return false;
        }

        shouldDismissAfterTimeout(states) {
            return this.bellStateChanged && !this.bell && !this.mediaHandler.isActivelyCalling && (this.customInfoView ? !this.customInfoView.isVideoPlaying : true) && !this.ViewController.fromNotification;
        }

        getShouldBlockScreenSaver() {
            return this.ViewController.alert;
        }

        // ###################################################
        // ######### AtheneMediaHandler Delegates ############
        // ###################################################
        onNewLastActivities(activities) {
            this.lastActivities = activities;
            PersistenceComponent.getShared(this._createKey(AtheneEnums.SETTINGS.LAST_SEEN_ACTIVITIES)).then(function (lastViewedActivityUnix) {
                if (this.lastActivities.length) {
                    this.newActivities = lastViewedActivityUnix < this.lastActivities[0].getDate().unix();
                } else {
                    this.newActivities = false;
                }

                if (this.newActivities) {
                    this.actionTableView.reload();
                }
            }.bind(this));
        }

        onComError(e) {
            developerAttention("Couldn't establish a connection to the Intercom Gen. 2");
        }

        destroy() {
            super.destroy(...arguments);
            useDeviceOrientation().set();
        }

        // ###################################################
        // ################# Private #########################
        // ###################################################
        _createCellObjectForCmd(cmdObj) {
            var cellObj = super._createCellObjectForCmd(...arguments);

            if (!this.isInAmbientMode() && (HD_APP && this.isLandscape || !HD_APP && this.isLandscape)) {
                cellObj.type = GUI.TableViewV2.CellType.Special.INTERCOM_ACTION_CELL;
                cellObj.content.color = Color.WHITE;
                cellObj.content.fillColor = ColorLight.APP_BACKGROUND_TERTIARY_B;
            } else {
                delete cellObj.content.icon;

                if (this.isInAmbientMode()) {
                    cellObj.content.tintColor = globalStyles.colors.grey["300a36"];
                }
            }

            cellObj.content.title = cmdObj.name;
            return cellObj;
        }

        _checkNotReadyScreen(states) {
            console.log(this.name, "deviceState = " + translateEnum(AtheneEnums.DEVICE_STATE, states.deviceState));
            console.log(this.name, "comReady = " + states.comReady);

            if (this._currentDeviceState !== states.deviceState && this._currentComReady !== states.comReady && (!states.comReady || states.deviceState !== AtheneEnums.DEVICE_STATE.OK)) {
                if (this._notReadyDelayTimeout) {
                    clearTimeout(this._notReadyDelayTimeout);
                }

                this._notReadyDelayTimeout = setTimeout(function () {
                    this.ViewController.showState(AtheneEnums.Screens.NOT_READY, null, {
                        control: this.control
                    });
                    delete this._notReadyDelayTimeout;
                }.bind(this), WAITING_INFO_TIMEOUT);
            } else if (this._notReadyDelayTimeout) {
                clearTimeout(this._notReadyDelayTimeout);
            }

            this._currentDeviceState = states.deviceState;
            this._currentComReady = states.comReady;
        }

        _playTtsMessage(index) {
            if (this.mediaHandler.canPlayTTSAtIndex(index)) {
                this.sendCommand(Commands.format(Commands.INTERCOM_GEN_2.PLAY_TTS, index));

                this._answer(false, true);
            } else {
                NavigationComp.showPopup({
                    title: _("athene.tts-error.title"),
                    message: _("athene.tts-error.message"),
                    buttonOk: true
                });
            }
        }

        _answer(shouldDismiss, automatic) {
            if (this.ViewController.alert && shouldDismiss) {
                this.dismissAlert();
            }

            if (this.bell) {
                this.sendCommand(Commands.format(Commands.INTERCOM_GEN_2.ANSWER), null, null, !!automatic, null, !!automatic);
            }
        }

        // ###################################################
        // ############# Settings table view #################
        // ###################################################
        _getSettingsTableContent(states) {
            if (states.universalIsLocked) {
                return []
            }
            var tableContent = [],
                settingsSection = {
                    rows: []
                };
            settingsSection.rows.pushObject(this._getMuteRow(GUI.TableViewV2.CellType.SWITCH));
            settingsSection.rows.push({
                content: {
                    title: _("athene.settings.title"),
                    clickable: true
                },
                action: function () {
                    NavigationComp.showState(AtheneEnums.Screens.SETTINGS, {
                        control: this.control
                    });
                }.bind(this)
            });
            tableContent.push(settingsSection);
            return tableContent;
        }

        _getTtsRow() {
            var hasTTSTexts = !!this.ttsTexts.filter(function (tts) {
                return tts;
            }).length;

            if (hasTTSTexts) {
                var row = {
                    content: {
                        title: _("athene.tts"),
                        icon: Icon.Athene.SPEECH_BUBBLE,
                        fillColor: Color.WHITE,
                        color: Color.STATE_INACTIVE
                    },
                    action: this._showTTSOptions.bind(this)
                };

                if (this.isLandscape) {
                    row.content.color = Color.WHITE;
                    row.content.fillColor = ColorLight.APP_BACKGROUND_TERTIARY_B;
                }

                return row;
            }

            return null;
        }

        _showTTSOptions() {
            var hasTTSTexts = !!this.ttsTexts.filter(function (tts) {
                return tts;
            }).length;

            if (hasTTSTexts) {
                this._showContextMenu(this.ttsTexts.map(function (answer, index) {
                    return {
                        title: answer,
                        action: function () {
                            this._playTtsMessage(index);
                        }.bind(this)
                    };
                }.bind(this)));
            }
        }

        _getIgnoreRow() {
            return {
                content: {
                    title: _("athene.bell.ignore"),
                    icon: Icon.Athene.BELL_MUTED,
                    fillColor: Color.WHITE,
                    color: window.Styles.colors.red
                },
                action: this._answer.bind(this, true, false)
            };
        }

        _reloadSettingsTable(states) {
            var tableContent = this._getSettingsTableContent(states),
                needsReload = false;

            needsReload |= this.settingsTableViewDataSource.update(tableContent);
            needsReload |= this.settingsTableViewDelegate.update(tableContent);

            if (needsReload) {
                return this.settingsTableView.reload();
            } else {
                return Q.resolve();
            }
        }

        _getMuteRow(type) {
            if (!this.mediaHandler.isActivelyCalling && !this.bell) {
                var row = {
                    content: {
                        title: _("athene.mute-bell"),
                        clickable: true,
                        active: this.muted
                    },
                    type: type,
                    onSwitchChanged: function onSwitchChanged(value) {
                        this.sendCommand(Commands.format(Commands.INTERCOM_GEN_2.MUTE, value));
                    }.bind(this)
                };

                if (type === GUI.TableViewV2.CellType.Special.INTERCOM_ACTION_CELL) {
                    row.content.icon = Icon.Athene.BELL_MUTED;
                    row.content.fillColor = this.muted ? window.Styles.colors.red : ColorLight.APP_BACKGROUND_TERTIARY_B;
                    row.content.color = Color.WHITE;
                    row.content.title = _("athene.mute-bell-hd");

                    row.action = function () {
                        this.sendCommand(Commands.format(Commands.INTERCOM_GEN_2.MUTE, !this.muted));
                    }.bind(this);
                }

                return row;
            }

            return null;
        }

        _getActivitiesRow() {
            if (!this.mediaHandler.isActivelyCalling && !this.bell) {
                var row = {
                    content: {
                        icon: Icon.Athene.CLOCK,
                        title: _("athene.activities.title"),
                        clickable: true,
                        disclosureIcon: true,
                        fillColor: this.newActivities ? Color.WHITE : Color.APP_BACKGROUND_TERTIARY_B,
                        color: this.newActivities ? window.Styles.colors.orange : Color.WHITE,
                        disabled: this.mediaHandler.useNewAuthMethod ? false : !this.states.comReady
                    },
                    action: function () {
                        if (this.lastActivities && this.lastActivities.length) {
                            PersistenceComponent.setShared(this._createKey(AtheneEnums.SETTINGS.LAST_SEEN_ACTIVITIES), this.lastActivities[0].getDate().unix());
                        }

                        NavigationComp.showState(AtheneEnums.Screens.ACTIVITIES, {
                            control: this.control
                        });
                    }.bind(this)
                };
                return row;
            }

            return null;
        }

        _getPlaceholderRow() {
            return {
                content: {
                    isPlaceholder: true
                }
            };
        }

        _createKey(setting) {
            return setting + "_" + this.control.uuidAction;
        }

    };
});
