'use strict';

define(["FavoritesManager"], function (FavoritesManager) {
    return class EditSpecificFavScreen extends GUI.TableViewScreenV2 {
        //region Static
        static ACTIONS = {
            DELETE: "delete",
            MODIFY: "modify"
        }; //endregion Static

        constructor(details) {
            super($('<div />'));
            this.details = details;
            this.delegate = details.delegate;
            this.control = details.control;
            this.favorite = details.favorite;
            this.changedFavorite = cloneObject(this.favorite);
            this.favorites = details.favorites;
        }

        viewWillDisappear() {
            return super.viewWillDisappear(...arguments).then(function () {
                this._saveNewFavName();

                return Q.resolve();
            }.bind(this));
        }

        destroy() {
            this._saveNewFavId(); // keep save ID + name separated, otherwise one of the two may not be saved by the AS.


            return super.destroy(...arguments);
        }

        titleBarText() {
            return _("audio-server.favorites.edit.title-single");
        }

        closeAction() {
            // Can't use navigateBack with the details for previous view because
            // the previous View isn't the view we want on HD, use a delegateCall instead
            this._onSpecificFavUpdate({
                action: this.constructor.ACTIONS.MODIFY,
                fav: this.changedFavorite
            });

            return super.closeAction(...arguments);
        }

        reloadTable() {
            // 0 is not allowed by the Audioserver
            var prohibitedFavIds = [0].concat(this.favorites.map(function (fav) {
                return fav.id;
            }).filter(function (favId) {
                return favId !== this.favorite.id;
            }.bind(this)));
            this.tableContent = [{
                rows: [{
                    type: GUI.TableViewV2.CellType.INPUT,
                    content: {
                        title: _("device-learning.designation"),
                        value: this.favorite.name,
                        placeholder: this.favorite.name,
                        autofocus: false,
                        type: GUI.LxInputEnum.Type.TEXT
                    },
                    textChanged: function (section, row, tableView, value) {
                        this.changedFavorite.name = value;
                    }.bind(this)
                }, {
                    type: GUI.TableViewV2.CellType.INPUT,
                    content: {
                        title: _("audio-server.favorites.edit.id"),
                        value: this.favorite.id,
                        placeholder: this.favorite.id,
                        autofocus: false,
                        type: GUI.LxInputEnum.Type.NUMBER,
                        numberMin: 1,
                        numberMax: 2147483647,
                        // = integer max, was on 200, but
                        // Create a validationRegex with all the already existing favorite IDS with a negative
                        // lockhead excluding the id of the current selected fav
                        // => This basically disallows all used ids
                        validationRegex: new RegExp("^(?!(?:" + prohibitedFavIds.map(function (id) {
                            return id;
                        }).join("|") + ")$)\\d+"),
                        validationErrorText: _("invalid-input")
                    },
                    textChanged: function (section, row, tableView, value, valid) {
                        if (valid) {
                            this.changedFavorite.id = parseInt(value);
                        }
                    }.bind(this)
                }]
            }, {
                rows: [{
                    type: GUI.TableViewV2.CellType.SWITCH,
                    content: {
                        title: _("audio-server.favorites.t5-source"),
                        active: this.favorite.plus
                    },
                    onSwitchChanged: function onSwitchChanged(value, section, row, tableView, cell) {
                        this.changedFavorite.plus = value;
                        FavoritesManager.shared(this.control).setFavoriteInPlusLoop(this.favorite.id, this.changedFavorite.plus);
                    }.bind(this)
                }]
            }, {
                rows: [{
                    content: {
                        title: _("media.favorite.delete"),
                        titleColor: window.Styles.colors.red,
                        clickable: true
                    },
                    action: function () {
                        FavoritesManager.shared(this.control).deleteItemFromFav(this.changedFavorite).done(function () {
                            // Can't use navigateBack with the details for previous view because
                            // the previous View isn't the view we want on HD, use a delegateCall instead
                            this._onSpecificFavUpdate({
                                action: this.constructor.ACTIONS.DELETE,
                                fav: this.changedFavorite
                            });

                            this.ViewController.navigateBack();
                        }.bind(this));
                    }.bind(this)
                }]
            }];
            return super.reloadTable(...arguments);
        }

        _saveNewFavName() {
            if (this.favorite.name !== this.changedFavorite.name) {
                return FavoritesManager.shared(this.control).renameFavorite(this.favorite.id, this.changedFavorite.name);
            } else {
                return Q.resolve();
            }
        }

        _saveNewFavId() {
            if (this.favorite.id !== this.changedFavorite.id) {
                return FavoritesManager.shared(this.control).changeFavoriteId(this.favorite.id, this.changedFavorite.id);
            } else {
                return Q.resolve();
            }
        }

        _onSpecificFavUpdate(data) {
            this.delegate && this.delegate.onSpecificFavUpdate && this.delegate.onSpecificFavUpdate(data);
        }

    };
});
