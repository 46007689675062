import { Pressable, View, useWindowDimensions } from "react-native";
import React, {useEffect, useMemo, useRef, useState, useCallback, useContext, useLayoutEffect} from "react";
import { Host } from "react-native-portalize";
import AmbientShortcutsView from "../views/AmbientShortcutsView";
import { LxReactImageView, navigatorConfig, useCCEvent, useCanShowAmbientMode } from "LxComponents";
import globalStyles from "GlobalStyles";
import Icons from "IconLib"
import AmbientHeaderInfoView from "../views/AmbientHeaderInfoView";
import useAmbientWallpaper from "../hooks/useAmbientWallpaper";
import { useFocusEffect } from "@react-navigation/native";
import AmbientHealthIcon from "./AmbientHealthIcon";
import { useSize } from 'ahooks';

import AmbientTabNavigator, { AmbientContext } from "../AmbientTabNavigator";
import AmbientSettingsNavigator from "../AmbientSettingsNavigator";
import AmbientSearchStackNavigator from "../AmbientSearchStackNavigator";
import AmbientSystemStatusStackNavigator from "../AmbientSystemStatusStackNavigator";
import AmbientSettings from "./AmbientSettings";
import MessageOverviewScreen from "../../LxReactMessageCenter/MessageOverviewScreen";
import AmbientStackNavigator from "../AmbientStackNavigator";
import useIsPairedApp from "../../PairedApp/useIsPairedApp";


function AmbientMiniContainer({ navigation }) {
    const { miniAppSize } = useContext(AmbientContext);
    const { shouldRotate, shouldResize, notSupported } = useCanShowAmbientMode();
    const tabContainerRef = useRef(null);
    const tabContainerSize = useSize(tabContainerRef) || { height: 0, width: 0 };
    const layoutCallback = useCallback((event) => {
        let layout = event.nativeEvent.layout;
        miniAppSize.width = layout.width;
        miniAppSize.height = layout.height;
    }, [])

    const shouldHideAmbientMode = useMemo(() => {
        return shouldResize || shouldRotate || notSupported;
    }, [shouldResize, shouldRotate, notSupported])

    useEffect(() => {
        // is this for the intercom?
        if (!shouldHideAmbientMode) {
            if (tabContainerSize.height) {
                document.documentElement.style.setProperty("--100vh", `${tabContainerSize.height}px`);
            } else {
                document.documentElement.style.setProperty("--100vh", `100vh`);
            }

            if (tabContainerSize.width) {
                document.documentElement.style.setProperty("--100vw", `${tabContainerSize.width}px`);
            } else {
                document.documentElement.style.setProperty("--100vw", `100vw`);
            }
        }

        return () => {
            document.documentElement.style.setProperty("--100vh", `100vh`);
            document.documentElement.style.setProperty("--100vw", `100vw`);
        }
    }, [tabContainerSize, shouldHideAmbientMode]);

    return <View onLayout={layoutCallback} ref={tabContainerRef} style={styles.tabContainer}>
        <Host>
            <AmbientTabNavigator navigation={navigation} />
        </Host>
    </View>
}

function AmbientContextContainer({ children, navigation }) {
    return <AmbientContext.Provider value={ambientContextValue}>
        <AmbientMiniContainer navigation={navigation} />
    </AmbientContext.Provider>
}

const ambientContextValue = {
    isAmbientMode: true,
    miniAppSize: { width: 0, height: 0 }
}


function AmbientScreen({ navigation, route, children }) {
    const bgImg = useAmbientWallpaper();
    const { shouldRotate, shouldResize, notSupported } = useCanShowAmbientMode();
    const popupRef = useRef(null);
    const { height, width } = useWindowDimensions();
    const isPairedApp = useIsPairedApp();

    const shouldHideAmbientMode = useMemo(() => {
        return shouldResize || shouldRotate || notSupported;
    }, [shouldResize, shouldRotate, notSupported])

    const toggleAmbientModeShown = (shouldShow) => {
        const changed = toggleAmbientMode(shouldShow);
        if (changed) {
            SandboxComponent.toggleAmbientModeShown(shouldShow)
        }
    }

    //is called before screen is painted, necessary because we need the earliest moment possible to set the global variable to true
    useLayoutEffect(() => {
        toggleAmbientModeShown(true)
    }, []);

    useFocusEffect(useCallback(() => {
        toggleAmbientModeShown(true)

        return () => {
           toggleAmbientModeShown(false)
        }
    }, []))

    const [visibleScreens, setVisibleScreens] = useState({ settings: false, systemStatus: false, search: false });
    useCCEvent([CCEvent.AmbientTabEvent], useCallback((ev, arg) => {
        let newVisibilityMap = {
            settings: arg.selectedTabName === AmbientSettingsNavigator.name,
            search: arg.selectedTabName === AmbientSearchStackNavigator.name,
            systemStatus: arg.selectedTabName === AmbientSystemStatusStackNavigator.name,
        }
        setVisibleScreens(newVisibilityMap);
    }, []));

    useFocusEffect(useCallback(() => {
        let popupContent;

        if (shouldHideAmbientMode) {

            if (notSupported) {
                popupContent = {
                    title: _('ambient.device-not-supported.title'),
                    message: _('ambient.device-not-supported.message', { height, width }),
                    buttonOk: _("ambient.close"),
                }
            } else if (shouldRotate) {
                popupContent = {
                    icon: Icon.ROTATE,
                    title: _("ambient.orientation-warning.title"),
                    message: _("ambient.orientation-warning.message"),
                    buttonOk: _("ambient.close"),
                }
            } else if (shouldResize) {
                popupContent = {
                    icon: Icon.RESIZE,
                    title: _("ambient.dimension-warning.title"),
                    message: _("ambient.dimension-warning.message"),
                    buttonOk: _("ambient.close"),
                }
            }

            let popupPromise = NavigationComp.showPopup(popupContent);

            popupRef.current = popupPromise;

            popupPromise.then(() => {
                navigation.goBack();
            }, (action) => {
                if (action === GUI.PopupBase.ButtonType.CANCEL) {
                    // is needed to handle the esc key correctly
                    navigation.goBack();
                }
            })
            SandboxComponent.toggleAmbientModeShown(false);

            // params have to be resetted otherwise the default location stays in the params which causes the AmbientStackNavigator to initial render the default location
            // instead of the ActiveMiniserversreen when the Ambient Mode is shown again.
            navigation.setParams({screen: null, params: null})
        } else {
            popupRef.current && NavigationComp.removePopup(popupRef.current);
            popupRef.current = null;

            // The Tab Navigator is built new when coming back from popup and starts with the AmbientStackNavigator,
            // we don't get information about this reset, so we have to reset the state reset manually, otherwise visiblescreens
            // is still set to the last open tab
            setVisibleScreens({
                settings: false,
                search: false,
                systemStatus: false
            })

            SandboxComponent.toggleAmbientModeShown(true);
        }
    }, [shouldHideAmbientMode]))

    const HeaderTitle = React.memo(props => {
        let dimensions = {
            height: 32,
            width: 144
        }

        if (shouldHideAmbientMode) {
            return null;
        } else {
            if (AppBranding.brandLogoUrl) {
                return <LxReactImageView
                    key={"headerTitleLogo"}
                    source={AppBranding.brandLogoUrl}
                    imageStyle={{
                        ...dimensions,
                        objectFit: "scale-down"
                    }}
                    containerStyle={{
                        ...dimensions,
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                />
            } else {
                return <Icons.LoxoneSlogan fill={globalStyles.colors.white} {...dimensions} />
            }
        }
    })

    const HeaderActions = {
        BRIGHTNESS_SWITCH: 0,
        SETTINGS: 1,
        SYSTEMSTATE: 2,
        SEARCH: 3
    }

    useEffect(() => {

        const getLeftActions = () => {
            if (!shouldHideAmbientMode) {
                return [{
                    action: ({ dimensions, props, key }) => {
                        return <AmbientHeaderIcon Icon={Icons.CloseScreen} imageFill={globalStyles.colors.white} containerStyle={styles.leftHeaderIcon} />
                    }
                }]
            } else {
                return [];
            }
        }

        const getRightActions = () => {
            if (shouldHideAmbientMode) {
                return [];
            } else {
                let actions = [{
                    action: ({ dimensions, props, key }) => {
                        return <AmbientHeaderIcon Icon={Icons.EcoScreenFilled} />
                    }
                }];

                if (isPairedApp) {
                    HeaderActions.SETTINGS = -1; // no settings
                } else {
                    HeaderActions.SETTINGS =  actions.length;
                    actions.push({
                        action: ({ dimensions, props, key }) => {
                            return <AmbientHeaderIcon Icon={Icons.Gear} active={!!visibleScreens.settings} />
                        }
                    })
                }

                if (MessageCenterHelper.isAvailable()) {
                    const messageCenterAction = {
                        action: ({ dimensions, props, key }) => {
                            return <AmbientHealthIcon active={!!visibleScreens.systemStatus} />
                        }
                    }

                    HeaderActions.SYSTEMSTATE = actions.length;
                    actions.push(messageCenterAction);
                } else {
                    HeaderActions.SYSTEMSTATE = -1; // no system states
                }


                HeaderActions.SEARCH = actions.length;
                actions.push(
                    {
                        action: ({ dimensions, props, key }) => {
                            return <AmbientHeaderIcon Icon={Icons.Search} active={!!visibleScreens.search} containerStyle={styles.rightHeaderIcon} />
                        }
                    }
                )

                return actions;
            }
        }

        const onLeftAction = () => {
            if (shouldHideAmbientMode) {
                return null;
            } else {
                return () => navigation.goBack()
            }
        }

        const onRightAction = () => {
            if (shouldHideAmbientMode) {
                return null;
            } else {
                return handleMenuAction
            }
        }

        const navCfg = {
            headerTransparent: true,
            headerTitle: props => {
                return <HeaderTitle />
            },
            headerStyle: {
                height: 92
            },
            leftActions: getLeftActions(),
            rightActions: getRightActions(),
            onLeftAction: onLeftAction(),
            onRightAction: onRightAction(),
        }

        navigation.setOptions({
            ...navigatorConfig(navCfg)
        })
    }, [
        shouldHideAmbientMode,
        visibleScreens.settings,
        visibleScreens.search,
        visibleScreens.systemStatus,
        isPairedApp
    ]);

    const toggleNightMode = useCallback(() => {
        SandboxComponent.toggleEcoModeShown(true, true);
    }, []);



    const handleMenuAction = (actionIndex) => {
        switch (actionIndex) {
            case HeaderActions.BRIGHTNESS_SWITCH:
                toggleNightMode()
                break;
            case HeaderActions.SETTINGS:
                if (visibleScreens.settings) {
                    navigateNested(AmbientStackNavigator.name);
                } else {
                    navigateNested(AmbientSettingsNavigator.name, { screen: AmbientSettings.name })
                }
                break;
            case HeaderActions.SYSTEMSTATE:
                if (visibleScreens.systemStatus) {
                    navigateNested(AmbientStackNavigator.name);
                } else {
                    navigateNested(AmbientSystemStatusStackNavigator.name, { screen: MessageOverviewScreen.name })
                }
                break;
            case HeaderActions.SEARCH:
                if (visibleScreens.search) {
                    navigateNested(AmbientStackNavigator.name);
                } else {
                    navigateNested(AmbientSearchStackNavigator.name, {
                        screen: ScreenState.SearchScreen,
                        params: { // important to reset the legacy search screen when re-opened
                            ambientOpenId: getRandomIntInclusive(0, 100)
                        }
                    })
                }
                break;
        }
    }

    const navigateNested = (screenState, params) => {
        navigation.navigate(ScreenState.AmbientScreen, {
            screen: screenState,
            params
        })
    }

    const handleHomeButtonPressed = () => {
        ActiveMSComponent.resetAmbientToDefaultLocation(true);
    }

    const backgroundImage = useMemo(() => {
        if (shouldHideAmbientMode) {
            return null;
        } else {
            let style = [
                styles.backgroundImage
            ];
            if (bgImg && "source" in bgImg) {
                let backgroundStyle = {
                    backgroundImage: `url(${bgImg.source.uri})`,
                    opacity: 0.85
                }
                if (bgImg.source.sizing) {
                    const isPortrait = bgImg.source.sizing.height > bgImg.source.sizing.width
                    let scale = bgImg.source.sizing.scale

                    backgroundStyle = {
                        ...backgroundStyle,
                        backgroundPosition: `calc(50% + ${bgImg.source.sizing.translateX}px) calc(50% + ${bgImg.source.sizing.translateY}px)`,
                        backgroundRepeat: "no-repeat"
                    }
                    if (isPortrait) {
                        backgroundStyle.backgroundSize = "auto " + `${scale * 100}%`
                    } else {
                        if (width / height < 1.6) {
                            backgroundStyle.backgroundSize = "auto " + `${scale * 100}%`
                        } else {
                            backgroundStyle.backgroundSize = `${scale * 100}% ` + "auto"
                        }
                    }
                } else {
                    backgroundStyle = {
                        ...backgroundStyle,
                        backgroundPosition: "center center",
                        backgroundSize: `cover`,
                        backgroundRepeat: "no-repeat"
                    }
                }
                style.push(backgroundStyle);
            }
            return <View style={style} />
        }
    }, [shouldHideAmbientMode, bgImg, height, width]);

    const ambientContainer = useMemo(() => {
        if (shouldHideAmbientMode) {
            return <View style={{ backgroundColor: "black", height: "100%", width: "100%" }}></View>
        } else {
            return <>
                <View style={styles.container}>
                    <View style={styles.contentContainer}>
                        <View style={styles.leftContainer}>
                            <View style={styles.leftContentContainer}>
                                <View style={styles.leftTopContainer}>
                                    <AmbientHeaderInfoView />
                                </View>
                                <View style={styles.leftBottomContainer}>
                                    <AmbientShortcutsView editActive={visibleScreens.settings} navigateNested={navigateNested} />
                                </View>
                            </View>
                        </View>

                        <View style={styles.rightContainer}>
                            <AmbientContextContainer navigation={navigation} />
                        </View>
                    </View>
                </View>
                <Pressable style={styles.housePressableStyle} onPress={handleHomeButtonPressed}>
                    <AmbientHeaderIcon Icon={Icons.House} />
                </Pressable>
            </>
        }
    }, [visibleScreens, shouldHideAmbientMode]);

    return <View style={styles.container}>
        {backgroundImage}
        {ambientContainer}
    </View>
}

export const AmbientHeaderIcon = ({ Icon, containerStyle, active = false }) => {
    return <View style={[globalStyles.customStyles.ambientHeaderIconContainer, containerStyle]}>
        <Icon {...styles.headerIcon.imageStyle} />
    </View>
}

const styles = {
    container: {
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center"
    },
    backgroundImage: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    contentContainer: {
        width: "100%",
        height: "100%",
        minWidth: 820,
        maxWidth: 1700,
        maxHeight: 900,
        paddingHorizontal: 92,
        paddingBottom: 56,
        paddingTop: 92,
        flexDirection: "row",
        flex: 1,
        alignItems: "flex-start"
    },
    leftContainer: {
        flex: 1,
        height: "100%",
        width: "100%",
        justifyContent: "center",
        minWidth: 375,
        maxWidth: "65%"
    },
    leftContentContainer: {
        width: "100%",
        height: "100%",
        maxWidth: "100%"
    },
    leftTopContainer: {
        flex: 1,
        justifyContent: "flex-end"
    },
    leftBottomContainer: {
        flex: 1,
        justifyContent: "flex-start"
    },
    rightContainer: {
        flex: 1,
        height: "100%",
        width: "100%",
        backgroundColor: "none",
        minWidth: 200,
        marginLeft: 90,
        alignItems: "flex-end",
        justifyContent: "center"
    },
    tabContainer: {
        ...globalStyles.customStyles.blurredBackground,
        width: "100%",
        height: "100%",
        minWidth: 375,
        //minHeight: 580,
        maxWidth: 430,
        maxHeight: 830,
        borderRadius: 4
    },
    headerIcon: {
        imageStyle: {
            height: globalStyles.sizes.icons.small,
            width: globalStyles.sizes.icons.small,
            fill: globalStyles.colors.text.primary,
        }
    },
    leftHeaderIcon: {
        marginLeft: globalStyles.spacings.gaps.smallRegular
    },
    rightHeaderIcon: {
        marginRight: globalStyles.spacings.gaps.smallRegular
    },
    housePressableStyle: {
        alignSelf: "end",
        right: 22,
        bottom: 22,
        position: "absolute"
    },
    activeIcon: {
        imageStyle: {
            fill: globalStyles.colors.stateActive
        }
    }
}

export default AmbientScreen
