import {LxBaseControlContent, ControlContext} from "LxComponents";
import {useCallback, useContext} from "react";
import {LxReactFlexibleCell} from "LxComponents";
import PoolControlEnums from "../poolControlEnums"
import {useNavigation} from "@react-navigation/native";

function PoolControlContent({controlUUID}) {

    const navigation = useNavigation();

    /**
     * Will present the statistic for the output provided.
     * @param nr    the output number, 0 if not provided.
     * @param control
     */
    const showStatisticForOutput = (nr, control) => {
        if (control.statistic && control.statistic.outputs && control.statistic.outputs[nr]) {
            nr = nr ? nr : 0;
            NavigationComp.showStatistic(control.uuidAction, control.statistic.outputs[nr].uuid);
        }
    }

    const onSubTitleTapped = useCallback((control, states) => {
        navigation.navigate(PoolControlEnums.ScreenState.OPERATING_MODES, {control})
    }, [])

    const onIconTapped = (control, states) => {
        control.statistic && showStatisticForOutput(0);
    }

    function SettingsCell(props) {
        const {control, states} = useContext(ControlContext);

        if (control.isGrouped() || states.error) {
            return null;
        } else {
            let title = _("settings");
            let screen = PoolControlEnums.ScreenState.SETTINGS;

            if (states.serviceModeActive) {
                title += " (" + _("pool.control.OP-modes.servicemode") + ")";
                screen = PoolControlEnums.ScreenState.SERVICE;
            }
            return <LxReactFlexibleCell title={title} disclosureIcon={true} onPress={() => navigation.navigate(screen, {control})}
                                        containerStyle={styles.cellContainer}/>
        }
    }

    return <LxBaseControlContent onSubtitleTapped={onSubTitleTapped} onIconTapped={onIconTapped}>
        <SettingsCell/>
    </LxBaseControlContent>
}



const styles = {
    cellContainer: {
        width: "100%"
    }
}

export default PoolControlContent;