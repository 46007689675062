'use strict';
/**
 * This is a generic cell with up to two icons (left and right), a title and optionally a subtitle plus a disclosure
 * indicator if needed. The rightButton can also be used as a button.
 *
 * @param title             text to show in the center main div
 *
 * @param [subtitle]        text to show in the center sub div
 * @param [leftIconSrc]     src of the icon to be shown on the left side
 * @param [leftColor]       color of the icon shown on the left side, default: $color-icon-a (or -b in dark-mode)
 * @param [rightIconSrc]    src of the icon to be shown on the right side
 * @param [rightColor]      color of the icon to be shown on the right side, default $color-icon-a (or -b in dark-mode)
 * @param [hasRightButton] flag, whether or not the right icon (if provided) is used as a button, default: false
 * @param [disclosureIcon]  whether or not to show a disclosure icon. Will never be used if clickable is false.
 *
 * @delegate onListCellRightButtonTapped (cell, row, list)
 */

window.GUI = function (GUI) {
    GUI.ReusingListView.CellType.GENERAL = "GeneralCell";
    GUI.LVContent.TITLE = "title";
    GUI.LVContent.SUBTITLE = "subtitle";
    GUI.LVContent.LEFT_ICON = "leftIconSrc";
    GUI.LVContent.LEFT_COLOR = "leftColor";
    GUI.LVContent.RIGHT_ICON = "rightIconSrc";
    GUI.LVContent.RIGHT_COLOR = "rightColor";
    GUI.LVContent.RIGHT_BUTTON = "hasRightButton";
    GUI.LVContent.DISCLOSURE = "disclosureIcon";
    GUI.LVContent.DISABLED = "disabled";

    class GeneralCell extends GUI.ReusingListView.Cells.EditableBaseCell {
        //region Static
        static Template = function () {
            var getContent = function getContent() {
                return $('<div class="content__left"></div>' + '<div class="content__center">' + '<div class="center__top">' + '<div class="top__text"></div>' + ImageBox.getResourceImageWithClasses(Icon.DISCLOSURE, 'top__disclosure') + '</div>' + '<div class="center__bottom"></div>' + '</div>' + '<div class="content__right"></div>' + '</div>');
            };

            return {
                getContent: getContent
            };
        }(); //endregion Static

        constructor(delegate, cellType, listView) {
            super(...arguments);
            this.element.addClass("general-list-cell");
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function (res) {
                this.contentPlaceholder.append(GeneralCell.Template.getContent());
                this.elements.leftCntr = this.contentPlaceholder.find(".content__left").hide();
                this.elements.centerCntr = this.contentPlaceholder.find(".content__center");
                this.elements.rightCntr = this.contentPlaceholder.find(".content__right").hide();
                this.elements.centerTop = this.contentPlaceholder.find(".center__top");
                this.elements.centerBottom = this.contentPlaceholder.find(".center__bottom").hide();
                this.elements.topText = this.contentPlaceholder.find(".top__text");
                this.elements.disclosureIcon = this.contentPlaceholder.find(".top__disclosure").hide();
                this.rightButton = new GUI.LxButton(this, this.elements.rightCntr, Color.BUTTON_GLOW);
                this.rightButton.useChildsAsActiveParts("fill");
                this.addToHandledSubviews(this.rightButton);
                this.rightButton.setEnabled(false); // disabled by default, reenabled in updateContent
            }.bind(this));
        }

        viewWillAppear() {
            var promise = super.viewWillAppear(...arguments);
            this.rightButton.onButtonTapped = this.handleRightButtonTapped.bind(this);
            return promise;
        }

        viewDidDisappear() {
            this.rightButton.onButtonTapped = null;
            return super.viewDidDisappear(...arguments);
        }

        /**
         * Called when a cell is being initialized with content, or when it is reused. A view must
         * @param content
         */
        updateContent(content) {
            Debug.GUI.ListViewCells && console.log(this.viewId, "updateContent start - " + content.rowIdx + " - " + content.title, getStackObj());
            super.updateContent(...arguments);
            var title = this.cAttr(GUI.LVContent.TITLE, ""),
                subtitle = this.cAttr(GUI.LVContent.SUBTITLE, ""),
                clickable = this.cAttr(GUI.LVContent.CLICKABLE, true) && !this.cAttr(GUI.LVContent.DISABLED, true),
                disclosure = this.cAttr(GUI.LVContent.DISCLOSURE, false);
            this.updateLeftIcon();
            this.updateRightIcon();
            return GUI.animationHandler.schedule(function () {
                // assign title text (empty string if not assigned!)
                this.elements.topText.text(title);
                this.elements.topText.prop("title", title);

                // assign & toggle subtitle text
                var visible = subtitle !== undefined && subtitle !== "";
                this.elements.centerBottom.text(subtitle);
                this.elements.centerBottom.prop("title", subtitle);
                this.elements.centerBottom.toggle(visible);

                // is a disclosure icon to be shown (needs to be clickable while having hasDisclosure set)
                this.elements.disclosureIcon.toggle(clickable && disclosure);
            }.bind(this));
            Debug.GUI.ListViewCells && console.log(this.viewId, "updateContent end");
        }

        /**
         * Extracted to a separate method to make it easier to interfere in subclasses.
         */
        updateLeftIcon() {
            // toggle and assign left icon
            var hasLeftIcon = this.hasCAttr(GUI.LVContent.LEFT_ICON);
            var leftImage = "";
            var leftColor = "";

            if (hasLeftIcon) {
                leftImage = ImageBox.getResourceImageWithClasses(this.cAttr(GUI.LVContent.LEFT_ICON), "left__icon cell__icon");
                leftColor = this.cAttr(GUI.LVContent.LEFT_COLOR, "");
            }

            GUI.animationHandler.schedule(function () {
                this.elements.leftCntr.html(leftImage);
                this.elements.leftCntr.css("fill", leftColor);
                this.elements.leftCntr.toggle(hasLeftIcon);
            }.bind(this));
        }

        /**
         * Extracted to a separate method to make it easier to interfere in subclasses
         */
        updateRightIcon() {
            var hasRightIcon = this.hasCAttr(GUI.LVContent.RIGHT_ICON),
                rightIcon = "",
                rightIconColor = "",
                rightButtonEnabled = false; // toggle and assign right Icon

            if (hasRightIcon) {
                rightButtonEnabled = this.cAttr(GUI.LVContent.RIGHT_BUTTON, false) && !this.cAttr(GUI.LVContent.DISABLED, false);
                rightIconColor = this.cAttr(GUI.LVContent.RIGHT_COLOR, "");
                rightIcon = ImageBox.getResourceImageWithClasses(this.cAttr(GUI.LVContent.RIGHT_ICON), "right__icon cell__icon");
            }

            GUI.animationHandler.schedule(function () {
                this.elements.rightCntr.html(rightIcon);
                this.elements.rightCntr.toggle(hasRightIcon);
                this.elements.rightCntr.css("fill", rightIconColor);
                this.rightButton.setEnabled(rightButtonEnabled);
            }.bind(this));
        }

        updateEnabled(enabled) {
            GUI.animationHandler.toggleCssClass(this.element, "disabled", !enabled);
            this.rightButton.setEnabled(enabled);
        }

        /**
         * Will take care of informing the delegate that the right button has been tapped. Might be overwritten by
         * subclasses that require a different method to be sent to the delegate.
         */
        handleRightButtonTapped() {
            if (this.delegate && this.delegate.onListCellRightButtonTapped) {
                this.delegate.onListCellRightButtonTapped.call(this.delegate, this, this.content[GUI.LVContent.ROW], this.listView);
            }
        }

    }

    GUI.ReusingListView.Cells.GeneralCell = GeneralCell;
    return GUI;
}(window.GUI || {});
