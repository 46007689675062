'use strict';

define(['AutopilotUtility', 'AutopilotEnums', 'AutopilotCategoryScreen'], function (AutopilotUtility, AutopilotEnums) {
    var MAX_PREVIEW_ACTIONS = 5;
    var Sections = {
        //TaskRecorder: 0,
        Categories: 0,
        OperatingModes: 1,
        Notifications: 2 // Central: 3, // not part of the first step -> has to be implemented on the miniserver side

    };
    var SectionExpandedStates = {
        0: false,
        1: false,
        2: false,
        3: false
    };
    return class AutopilotActionsScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div class="autopilot-actions-screen" />'));
            this.rule = details.rule;
            this.categories = AutopilotUtility.getCategoriesFromControlTypes(AutopilotEnums.SupportedActionBlocks);
            this.operatingModes = AutopilotUtility.getOperatingModes(false);
        }

        getURL() {
            return "Autopilot/Actions";
        }

        getAnimation() {
            return HD_APP ? AnimationType.PUSH_OVERLAP_LEFT : AnimationType.MODAL;
        }

        titleBarText() {
            return _("autopilot.rule.action.add");
        }

        titleBarAction() {
            this.ViewController.navigateBack();
        }

        // TABLE VIEW DELEGATES
        numberOfSections() {
            return Object.keys(Sections).length;
        }

        titleForHeaderInSection(section) {
            if (section === Sections.TaskRecorder) {
                return _("taskrecorder");
            } else if (section === Sections.Categories) {
                return ActiveMSComponent.getStructureManager().getCustomGroupTitles()[GroupTypes.CATEGORY];
            } else if (section === Sections.Central) {
                return _("autopilot.rule.action.central-function");
            } else if (section === Sections.OperatingModes) {
                return _("pool.control.settings.operating-mode");
            } else if (section === Sections.Notifications) {
                return _("messages");
            }
        }

        imageForHeaderInSection(section) {
            if (section === Sections.TaskRecorder) {
                return Icon.Autopilot.TASKRECORDER;
            } else if (section === Sections.Categories) {
                return Icon.Autopilot.CATEGORIES;
            } else if (section === Sections.OperatingModes) {
                return Icon.Autopilot.OPERATING_MODES;
            } else if (section === Sections.Notifications) {
                return Icon.Autopilot.MESSAGES;
            }
        }

        numberOfRowsInSection(section) {
            function _actionsNumbersForSection(actions) {
                if (SectionExpandedStates[section]) {
                    return actions.length + 1;
                } else if (actions.length <= MAX_PREVIEW_ACTIONS + 1) {
                    // +1 because it makes no sense to show "show more" button for 1 more cell
                    return actions.length;
                } else {
                    return MAX_PREVIEW_ACTIONS + 1;
                }
            }

            if (section === Sections.TaskRecorder) {
                return 1;
            } else if (section === Sections.Categories) {
                // Check if their are any categories available
                if (this.categories.length > 0) {
                    return _actionsNumbersForSection(this.categories);
                } else {
                    return 1;
                }
            } else if (section === Sections.Central) {
            } else if (section === Sections.OperatingModes) {
                return _actionsNumbersForSection(this.operatingModes);
            } else if (section === Sections.Notifications) {
                if (AutopilotUtility.getCallerServices().length === 0) {
                    return 2;
                }

                return 3;
            }
        }

        cellTypeForCellAtIndex(section, row) {
            if (this._isMoreOrLessCell(this._getActionsForSection(section), section, row)) {
                return GUI.TableViewV2.CellType.CENTER;
            }

            return GUI.TableViewV2.CellType.GENERAL;
        }

        contentForCell(cell, section, row) {
            var title = null,
                actions = this._getActionsForSection(section),
                cellIsClickable = true,
                cellIsDisabled = false,
                showDisclosureIcon = true;

            var showMoreShowLessButton = this._isMoreOrLessCell(actions, section, row);

            if (showMoreShowLessButton) {
                return {
                    title: SectionExpandedStates[section] && row === actions.length ? AutopilotEnums.ShowLess : AutopilotEnums.ShowMore
                };
            } else if (section === Sections.TaskRecorder) {
                title = _("taskrecorder.record-new-task");
            } else if (section === Sections.Categories) {
                if (actions.length > 0) {
                    title = this.categories[row].title;
                } else {
                    title = _('autopilot.rule.no-event', {
                        event: _('autopilot.rule.actions')
                    });
                    showDisclosureIcon = false;
                    cellIsClickable = false;
                    cellIsDisabled = true;
                }
            } else if (section === Sections.Central) {
            } else if (section === Sections.OperatingModes) {
                title = this.operatingModes[row].value;
            } else if (section === Sections.Notifications) {
                if (row === 0) {
                    title = AutopilotEnums.NotificationTypesTitle[AutopilotEnums.ActionTypes.CloudMailer];
                } else if (row === 1) {
                    title = AutopilotEnums.NotificationTypesTitle[AutopilotEnums.ActionTypes.Notification];
                } else if (row === 2) {
                    title = AutopilotEnums.NotificationTypesTitle[AutopilotEnums.ActionTypes.Caller];
                }
            }

            if (title) {
                return {
                    title: title,
                    disclosureIcon: showDisclosureIcon,
                    clickable: cellIsClickable,
                    disabled: cellIsDisabled
                };
            }
        }

        // TableView Delegates
        didSelectCell(cell, section, row) {
            var actions = this._getActionsForSection(section); // check here if we tapped the show more/less cell


            if (this._isMoreOrLessCell(actions, section, row)) {
                SectionExpandedStates[section] = !SectionExpandedStates[section];
                this.tableView.reload();
                return;
            }

            if (section === Sections.TaskRecorder) {
                this.ViewController.dismissModal();
                NavigationComp.showState(ScreenState.FavoritesV2).then(function (succ) {
                    NavigationComp.startActivity(GUI.ActiveMiniserverViewController.Activity.Autopilot, false, this.rule);
                }.bind(this));
            } else if (section === Sections.Categories) {
                this.ViewController.showState(AutopilotEnums.ScreenStates.AutopilotCategoryScreen, null, {
                    category: this.categories[row],
                    type: AutopilotEnums.CommunicationEvents.ADD_ACTION
                });
            } else if (section === Sections.OperatingModes) {
                this.ViewController.showState(AutopilotEnums.ScreenStates.AutopilotOperatingModeScreen, null, {
                    operatingMode: this.operatingModes[row],
                    action: {} // to create a new action

                });
            } else if (section === Sections.Notifications) {
                var notificationType;

                if (row === 0) {
                    notificationType = AutopilotEnums.ActionTypes.CloudMailer;
                } else if (row === 1) {
                    notificationType = AutopilotEnums.ActionTypes.Notification;
                } else if (row === 2) {
                    notificationType = AutopilotEnums.ActionTypes.Caller;
                }

                var details = {
                    notificationType: notificationType
                };
                this.ViewController.showState(AutopilotEnums.ScreenStates.AutopilotNotificationScreen, null, details);
            }
        }

        _getActionsForSection(section) {
            if (section === Sections.Categories) {
                return this.categories;
            } else if (section === Sections.OperatingModes) {
                return this.operatingModes;
            }
        }

        _isMoreOrLessCell(actions, section, row) {
            return actions && actions.length > MAX_PREVIEW_ACTIONS + 1 && (SectionExpandedStates[section] && row === actions.length || !SectionExpandedStates[section] && row === MAX_PREVIEW_ACTIONS);
        }

    };
});
