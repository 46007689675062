'use strict';

define([], function () {
    return {
        AlarmCause: {
            FIRE: 1,
            WATER: 2,
            TEMPERATURE: 4,
            ARC_FAULT: 8 // due to defect wiring, should prevent fires.

        },
        ALARM_LEVEL: {
            NO_ALARM: 0,
            PRE_ALARM: 1,
            MAIN_ALARM: 2
        },
        ScreenState: {
            SETTINGS: "SmokeAlarmControlContentSettings",
            SERVICE_MODE: "SmokeAlarmControlContentServiceMode"
        }
    };
});
