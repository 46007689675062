'use strict';

define(["SpotifyAccountManager"], function (SpotifyAccountManager) {
    Controls.AudioZoneV2Control.SingleTones = Controls.AudioZoneV2Control.SingleTones || {};

    if (Controls.AudioZoneV2Control.SingleTones.HistoryManager) {
        return Controls.AudioZoneV2Control.SingleTones.HistoryManager;
    } else {
        class HistoryManager {
            //region Static
            static shared(control) {
                if (!control) {
                    console.warn("No control provided, try to deduce one!");

                    if (window.hasOwnProperty("AudioserverComp") && AudioserverComp.getActiveZoneControl()) {
                        control = AudioserverComp.getActiveZoneControl();
                    } else {
                        throw "No control available!";
                    }
                }

                this.__instances = this.__instances || {};

                if (!this.__instances.hasOwnProperty(control.uuidAction)) {
                    this.__instances[control.uuidAction] = new this(this, control);
                }

                return this.__instances[control.uuidAction];
            }

            static destroy(control) {
                if (control && this.__instances && this.__instances.hasOwnProperty(control.uuidAction)) {
                    this.__instances[control.uuidAction].destroy();

                    delete this.__instances;
                }
            }

            static MAX_ENTRIES = 30; //endregion Static

            //region Getter
            get _history() {
                if (this.control) {
                    return this._sharedHistory[this.control.uuidAction] || [];
                }
            }

            get historyChangeCount() {
                return this._historyChangeCount;
            } //endregion Getter


            //region Setter
            set _history(newHistory) {
                if (this.control && newHistory) {
                    this._historyChangeCount++;
                    var dstUuids = [this.control.uuidAction]; // Also resolve the fixed group to also sync between these players

                    if (this.control.isFixedGroup) {
                        this.control.resolveFixedGroup().forEach(function (ctrol) {
                            dstUuids.pushIfNoDuplicate(ctrol.uuidAction);
                        });
                    }

                    dstUuids.forEach(function (uuidAction) {
                        this._sharedHistory[uuidAction] = newHistory.slice(0, this.constructor.MAX_ENTRIES);
                    }.bind(this));

                    this._onHistoryChange();

                    PersistenceComponent.setShared(MusicServerEnum.CUSTOMIZATION_KEYS.LAST_PLAYED, this._sharedHistory);
                }
            } //endregion Setter


            constructor(initiator, control) {
                if (!(initiator instanceof Function) && !Controls.AudioZoneV2Control.SingleTones.HistoryManager) {
                    throw "The History manager is a Singletone, use it like that! -> HistoryManager.shared(this.control)";
                }

                this.control = control;
                this.name = "HistoryManager-" + this.control.getName();
                Debug.Control.AudioZone.HistoryManager && console.log(this.name, "+CTOR");
                this._boundOnRemoteHistoryChange = this._onRemoteHistoryChange.bind(this);
                this._sharedHistory = {};
                this._registeredFunctions = [];
                this._historyChangeCount = 1;
            }

            prepare() {
                if (!this._prepareDef) {
                    Debug.Control.AudioZone.HistoryManager && console.log(this.name, "prepare");
                    this._prepareDef = Q.defer();
                    return PersistenceComponent.getShared(MusicServerEnum.CUSTOMIZATION_KEYS.LAST_PLAYED).then(function (history) {
                        this._sharedHistory = history || {};
                        Debug.Control.AudioZone.HistoryManager && console.log(this.name, "prepare >> new history: " + this._history.length);
                        PersistenceComponent.registerForSharedKeyChange(MusicServerEnum.CUSTOMIZATION_KEYS.LAST_PLAYED, this._boundOnRemoteHistoryChange, true);

                        this._prepareDef.resolve();
                    }.bind(this));
                } else {
                    Debug.Control.AudioZone.HistoryManager && console.log(this.name, "prepare - already passed or ongoing");
                }

                return this._prepareDef.promise;
            }

            isPrepared() {
                return this._prepareDef && !this._prepareDef.promise.isPending();
            }

            destroy() {
                Debug.Control.AudioZone.HistoryManager && console.log(this.name, "-DESTROY");
                PersistenceComponent.unregisterFromSharedKeyChange(MusicServerEnum.CUSTOMIZATION_KEYS.LAST_PLAYED, this._boundOnRemoteHistoryChange);
            }

            registerForChange(boundFunction) {
                Debug.Control.AudioZone.HistoryManager && console.log(this.name, "registerForHistoryChange", getStackObj());
                this._registeredFunctions.pushIfNoDuplicate(boundFunction);

                return this.historyChangeCount;
            }

            unregisterFromChange(boundFunction) {
                Debug.Control.AudioZone.HistoryManager && console.log(this.name, "unregisterFromChange", getStackObj());
                var regIdx = this._registeredFunctions.indexOf(boundFunction);

                if (regIdx !== -1) {
                    this._registeredFunctions.splice(regIdx, 1);
                }
            }

            getHistory() {
                Debug.Control.AudioZone.HistoryManager && console.log(this.name, "getHistory: " + this._history.length, getStackObj());
                return this._history;
            }

            addItem(item, details) {
                details = cloneObject(details);

                var existingIdx = -1,
                    tempHistory,
                    historyItem = this._getHistoryItem(item, details);

                if (historyItem) {
                    historyItem._historyDetails = details || {
                        control: this.control
                    };
                    tempHistory = cloneObject(this._history);
                    existingIdx = tempHistory.findIndex(function (existingItem) {
                        return (historyItem.audiopath || historyItem.id) === (existingItem.audiopath || existingItem.id);
                    });

                    if (existingIdx !== -1) {
                        tempHistory.splice(existingIdx, 1);
                    }

                    tempHistory.unshift(historyItem);
                    this._history = tempHistory;
                    return true;
                }

                return false;
            }

            /**
             * Removes all items from the history.
             */
            clear() {
                this._history = [];
            }

            /**
             * Ensures that when adding an item to the history, the proper infos are stored. E.g. when a spotify playlist
             * is played from the favorites, it shouldn't be stored as if it's a favorite, but as a spotify playlist.
             * @param item
             * @param details
             * @returns {*}
             * @private
             */
            _normalizeItem(item, details) {
                var normalizedItem = item;

                if (item.contentType === MusicServerEnum.MediaContentType.ZONE_FAVORITES) {
                    // pass the current spotify user along, required to browse spotify playlists played from favs.
                    details.spotifyUser = SpotifyAccountManager.shared(this.control).activeUser;
                    normalizedItem = cloneObjectDeep(item);
                    normalizedItem = this.control.audioserverComp.normalizeFavoriteItem(normalizedItem, details);
                    details && Object.assign(details, normalizedItem.details);
                }

                return normalizedItem;
            }

            _notifyListeners() {
                Debug.Control.AudioZone.HistoryManager && console.log(this.name, "_notifyListeners: " + this._registeredFunctions.length + ", new-length: " + this._history.length);

                this._registeredFunctions.forEach(function (boundFunction) {
                    boundFunction && boundFunction(this._history);
                }.bind(this));
            }

            _onRemoteHistoryChange(key, newHistory) {
                Debug.Control.AudioZone.HistoryManager && console.log(this.name, "_onRemoteHistoryChange");
                this._sharedHistory = newHistory || {};

                this._onHistoryChange();
            }

            _onHistoryChange() {
                Debug.Control.AudioZone.HistoryManager && console.log(this.name, "_onHistoryChange");

                this._notifyListeners();
            }

            _getHistoryItem(item, details) {
                var historyItem = this._normalizeItem(item, details);

                if (details) {
                    if (this.control.audioserverComp.isFileTypeBrowsable(historyItem.type)) {
                        details.lastSelectedItem = cloneObject(historyItem);
                    } else {
                        delete details.lastSelectedItem;
                    }
                }

                return historyItem;
            }

        }

        Controls.AudioZoneV2Control.SingleTones.HistoryManager = HistoryManager;
    }

    return Controls.AudioZoneV2Control.SingleTones.HistoryManager;
});
