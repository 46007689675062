import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Star(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M4.586 22.66a.916.916 0 00.754.182c.28-.05.585-.197.915-.441l5.697-4.19 5.708 4.19c.33.244.635.391.915.441.28.05.532-.01.754-.183.223-.165.357-.386.404-.662.047-.276.002-.609-.134-.996l-2.251-6.71 5.75-4.125c.338-.236.571-.48.7-.732.13-.251.151-.51.065-.775a.985.985 0 00-.506-.582c-.251-.13-.585-.194-1.002-.194l-7.054.054-2.143-6.742c-.129-.394-.292-.692-.49-.893A.967.967 0 0011.952 0c-.273 0-.508.1-.705.302-.198.2-.361.499-.49.893L8.614 7.937l-7.055-.054c-.416 0-.75.065-1.001.194a.985.985 0 00-.506.582.976.976 0 00.064.775c.13.251.363.496.7.732l5.751 4.125-2.25 6.71c-.137.387-.182.72-.135.996.046.276.181.497.404.662z"
            />
        </Svg>
    )
}

export default Star
