import AmbientUtils from "./AmbientUtils";
import AmbientShortcutSelection from "./screens/AmbientShortcutSelection";
import globalStyles from "../../../styles/react/styles";
import AmbientShortcutEditScreen from "./screens/AmbientShortcutEditScreen";
import AmbientSettingsNavigator from "./AmbientSettingsNavigator";

export default class AmbientTableviewHelper {
    static createShortcutRow(control) {
        let locInfos = [];
        control.getRoom() && locInfos.push(control.getRoom().name);
        control.getCategory() && locInfos.push(control.getCategory().name);
        return {
            title: control.getName(),
            subTitle: locInfos.join(SEPARATOR_SYMBOL)
        }
    }

    static openShortcutSelector(shortcutCount, navigateNested) {
        if (shortcutCount === AmbientUtils.MAX_SHORTCUTS) {
            AmbientUtils.showMaxShorcutReachedPopup()
        } else {
            navigateNested(AmbientSettingsNavigator.name, {
                screen: AmbientShortcutSelection.name,
                params: {}
            });
        }
    }

    static createShorcutsSections(shortcuts, editable, navigation, navigateNested) {
        //console.log("Shorcuts to show in AmbientSettings: ", shortcuts.length)
        let shortcutSection = {
            headerStrongTitle: _("ambient.settings.shortcuts.title"),
            headerDescription: _("ambient.settings.shortcuts.description"),
            rows: shortcuts.map(AmbientTableviewHelper.createShortcutRow)
        };

        delete shortcutSection.headerDescription;
        delete shortcutSection.headerStrongTitle;

        return [shortcutSection];
    }

    static addShortcutSection(shortcuts, editable, navigation, navigateNested) {
        let section;

        if (editable) {
            section = {
                rows: [{
                    //type: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        clickable: true,
                        leftIconSrc: Icon.PLUS,
                        leftIconColor: globalStyles.colors.green,
                        title: _('create.room.popup.button')
                    },
                    action: this.openShortcutSelector.bind(this, shortcuts.length, navigateNested)
                }]
            }
        } else {
            section = {
                rows: [{
                    content: {
                        title: _("edit"),
                        titleColor: globalStyles.colors.green
                    },
                    action: () => navigation.navigate(AmbientShortcutEditScreen.name)
                }]
            }
        }

        return [section];
    }
}
