'use strict';

define("SteakControlCommandSrc", [ "ControlCommandSrc", "SteakControlEnums" ], function (ControlCommandSrc, SteakControlEnums) {
    return class SteakControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [],
                hasRoom,
                activeControl;

            if (states.deviceState !== SteakControlEnums.DeviceStates.OFFLINE) {
                if (!states.isActive && states.availableControls.length > 1) {
                    hasRoom = !!control.getRoom();
                    activeControl = states.availableControls[states.activeControl];
                    cmds.push(this.createCommand(_("steak.activate", {
                        room: hasRoom ? control.getRoom().name : control.getName()
                    }), Commands.Steak.SET_THIS_ACTIVE));

                    if (activeControl) {
                        if (ActiveMSComponent.getStructureManager().getControlByUUID(activeControl.uuid)) {
                            cmds.push(this.createCommand(_('steak.navigate-to-active', {
                                activeControlName: activeControl.name
                            }), function () {
                                NavigationComp.navigateToControl(activeControl.uuid);
                            }));
                        }
                    }
                }
            }

            return cmds;
        }

    };
});
