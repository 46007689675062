/**
 * Created by loxone on 20.06.16.
 */
'use strict';

define(['AddMediaV2Screen', 'EditMediaScreenV2'], function () {
    class AddMediaViewControllerV2Base extends GUI.ModalViewController {
        //region Static
        static Template = class {
            //region Static
            static getAddCounterElm(initMessage) {
                return $("<div class='add-media-v2-screen__add-counter'>" + initMessage + "</div>");
            } //endregion Static


        }; //endregion Static

        //region Private
        //fast-class-es6-converter: extracted from defineStatic({}) content
        BANNED_CUSTOMIZATION_KEYS = []; //endregion Private

        constructor(details) {
            super(...arguments);
            this.addItemCnt = 0;
            this.details = details;
            this.targetMediaScreenState = details.targetMediaScreenState;
            this.control = AudioserverComp.getActiveZoneControl();
        }

        viewWillAppear() {
            var args = arguments,
                prms = [true];

            if (!this.didAlreadyShowState) {
                // add a dummy screen so that everything works correctly (HD_OVERLAY animation..)
                // Account for the dummy screen when navigatingBackToRoot...
                prms.push(this._handleAddInitialDummy());
                prms.push(super.showState(this.targetMediaScreenState, null, this.details, AnimationType.NONE));
            }

            return Q.all(prms).then(() => {
                return super.viewWillAppear(...args).then(function () {
                    if (!(this.currentView instanceof Controls.AudioZoneV2Control.EditMediaScreen) && !this.didAlreadyShowState && this.shouldShowAddCounter()) {
                        this.element.addClass("view-controller--with-add-counter");
                        this.elements.addCounter = AddMediaViewControllerV2Base.Template.getAddCounterElm(this.getAddCounterString());
                        this.element.append(this.elements.addCounter);
                    }

                    this.didAlreadyShowState = true;
                }.bind(this));
            });
        }

        getURL() {
            return this.control.uuidAction + "/addItem";
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.TEXT,
                rightSide: TitleBarCfg.Button.TICK
            };
        }

        titleBarText() {
            return this.details.addTargetName || _("edit");
        }

        titleBarActionRight() {
            this.dismiss();
        }

        showState(identifier, view, details, animationType) {
            //It's not a controlContentViewController - so pass along the control here.
            details = details || {};
            details.control = this.control;
            return super.showState(...arguments);
        }

        navigateBackToRoot() {
            return super.navigateBackToRoot(1); // Account for the dummy view
        }

        navigateBack() {
            // check if we are HD and we did show a dummy view!
            if (this.history.length === 2) {
                return this.dismissModal();
            } else {
                return super.navigateBack(...arguments);
            }
        }

        destroyOnBackNavigation() {
            return true;
        }

        startEditMode() {// Can be overwritten in subclasses
        }

        addItem(item) {
            return AudioserverComp.addItem(item).then(function (res) {
                this.addItemCnt += res.items.length;

                if (this.shouldBlockItemAfterAdd(item)) {
                    this.addToBlockedItems(item);
                }

                this._updateAddCounter();

                return res;
            }.bind(this));
        }

        removeItem(idx) {
            return AudioserverComp.removeItem(idx);
        }

        moveItem(oldIdx, newIdx) {
            return AudioserverComp.moveItem(oldIdx, newIdx);
        }

        getAddCounterString() {
            return _("media.added", {
                count: this.addItemCnt
            });
        }

        shouldShowAddCounter() {
            return true;
        }

        /**
         * Whether or not an item added should be available to be added again.
         * @param item
         * @returns {boolean}
         */
        shouldBlockItemAfterAdd(item) {
            return false;
        }

        addToBlockedItems(item) {
            this.details.blockedIds = this.details.blockedIds || [];
            this.details.blockedIds.push(item.audiopath || item.id);
        }

        isBlockedItem(item) {
            var isBlocked = this.details.blockedIds.indexOf(item.id) >= 0;
            isBlocked = isBlocked || this.details.blockedIds.indexOf(item.audiopath) >= 0;
            return isBlocked;
        }

        _updateAddCounter() {
            this.elements.addCounter && this.elements.addCounter.text(this.getAddCounterString());
        }

        _checkTitlebar() {
            if (this._titleBar) {
                this.removeSubview(this._titleBar);
            }

            super._checkTitlebar(...arguments);

            if (this._titleBar) {
                this.element.addClass("view-controller--with-title-bar");
            }

            this._attachTitleBarDelegates();
        }

    }

    GUI.AddMediaViewControllerV2Base = AddMediaViewControllerV2Base;
    return GUI.AddMediaViewControllerV2Base;
});
