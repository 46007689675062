'use strict';

window.GUI = function (GUI) {
    class HelpFeedbackScreen extends GUI.TableViewScreenV2 {

        get useNewTableView() {
            return true;
        }

        constructor(details) {
            super($('<div />'));
            this.appInfo = PlatformComponent.getAppInfoObj();
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            return _("app.help-and-feedback");
        }

        styleForTableView() {
            return TableViewStyle.COMFORT_MODE_2020;
        }

        _showState(state, details) {
            return this.ViewController.showState(state, details);
        }

        reloadTable() {
            var sectionOne = {
                    headerTitle: _("app.help-and-feedback"),
                    rows: []
                },
                sectionTwo = {
                    headerTitle: _("app.info"),
                    rows: []
                },
                subtitle;
            sectionOne.rows.pushObject(SandboxComponent.getPartnerBrandingItem(this._showState.bind(this)));
            sectionOne.rows.push({
                content: {
                    title: _('app.feedback'),
                    clickable: true
                },
                action: this._giveFeedback.bind(this)
            });
            /*sectionOne.rows.push({
                content: {
                    title: _('app.rating'),
                    clickable: true
                },
                action: RatingRequester.showRatingPopup.bind(RatingRequester, true)
            });*/
            sectionTwo.rows.push(SandboxComponent.getAboutAppHelpFeedbackMenu(this._showState.bind(this)));

            if (GUI.DebugScreen.enabled) {
                if (PlatformComponent.isMobileDevice()) {
                    subtitle = _("debug-screen.info.mobile");
                } else {
                    subtitle = _("debug-screen.info.stationary");
                }

                sectionTwo.rows.push({
                    content: {
                        title: _("debug-screen.text"),
                        subtitle: subtitle,
                        clickable: true
                    },
                    action: this._showDebugScreen.bind(this)
                });
            }

            sectionTwo.rows.push(SandboxComponent.getAboutLoxoneHelpFeedbackMenu(this._showState.bind(this)));

            // ensure disclosure icons are shown on each row
            sectionTwo.rows.forEach(sectionRow => { sectionRow.content.disclosureIcon = true });
            sectionOne.rows.forEach(sectionRow => { sectionRow.content.disclosureIcon = true });

            this.tableContent = [ sectionOne, sectionTwo ];
            return super.reloadTable(...arguments);
        }

        _giveFeedback() {
            var link = "mailto:feedback@loxone.com?subject=" + encodeURIComponent("Feedback on Loxone App (" + this.appInfo.appVersion + ")");
            NavigationComp.openWebsite(link);
        }

        _showDebugScreen() {
            GUI.DebugScreen.toggle();
        }

        _showLicences() {
            this.ViewController.showState(ScreenState.Licenses);
        }

    }

    GUI.HelpFeedbackScreen = HelpFeedbackScreen;
    return GUI;
}(window.GUI || {});
