'use strict';

window.GUI = function (GUI) {
    class TinySeparatorCell extends GUI.TableViewV2.Cells.BaseCell {
        constructor() {
            super(...arguments);
            this.element.addClass("tiny-separator-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.contentPlaceholder.append(GUI.TableViewV2.Cells.TinySeparatorCell.Template.getContent());
        }

    }

    GUI.TableViewV2.Cells.TinySeparatorCell = TinySeparatorCell;

    GUI.TableViewV2.Cells.TinySeparatorCell.Template = function () {
        var getContent = function getContent() {
            var row = '' + '<div class="tiny-separator-cell__row ">' + '<div class="row__left-col"></div>' + '<div class="row__right-col"></div>' + '</div>';
            return $(row + row);
        };

        return {
            getContent: getContent
        };
    }();

    GUI.TableViewV2.CellType.TINY_SEPARATOR = "TinySeparatorCell";
    return GUI;
}(window.GUI || {});
