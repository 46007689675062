import FavoritesScreenV2 from "../../GUI/ActiveMiniserver/favorites/favoritesScreenV2";
import HouseScreen from "../../GUI/ActiveMiniserver/house/houseScreen";
import CategoriesScreen from "../../GUI/ActiveMiniserver/categories/categoriesScreen";
import RoomsScreen from "../../GUI/ActiveMiniserver/rooms/roomsScreen";
import AmbientScreen from "./screens/AmbientScreen";
import ActiveMiniserverScreen from "../../GUI/ActiveMiniserver/activeMiniserverScreen/activeMiniserverScreen";
import {launchImageLibrary} from "react-native-image-picker";
import {OptionType} from "./hooks/useAmbientWallpaperOptions";
import globalStyles from "GlobalStyles";
import {CommonActions} from "@react-navigation/native";
import AmbientStackNavigator from "./AmbientStackNavigator";
import {getRouteFromNavigationState} from "../navigation/LxReactNavigationStateAnalyzer";

class AmbientUtils {
    static MAX_SHORTCUTS = 9;
    static MAX_SECTIONS = 3;
    static tabLocationsMap = {
        [UrlStartLocation.FAVORITES]: FavoritesScreenV2.name,
        [UrlStartLocation.CENTRAL]: HouseScreen.name,
        [UrlStartLocation.CATEGORY]: CategoriesScreen.name,
        [UrlStartLocation.ROOM]: RoomsScreen.name
    };

    static showMaxShorcutReachedPopup() {
        NavigationComp.showPopup({
            message: _("ambient.settings.shortcuts.maximum-reached"),
            buttonOk: true,
            icon: Icon.CAUTION,
            color: globalStyles.colors.orange
        });
    }

    static getShortcuts = (settings, uuidsOnly) => {
        if (!settings.quickAccessControls || !settings.quickAccessControls.length) {
            return [];
        }
        return settings.quickAccessControls.map((ctrlUuid) => {
            return uuidsOnly ? ctrlUuid : ActiveMSComponent.getStructureManager().getControlByUUID(ctrlUuid);
        }).filter(control => control)
    }

    static getTabScreenForLocation(defaultLocation) {
        return this.tabLocationsMap[defaultLocation] || null
    }

    // region arguments for navigation.navigate(...)

    static getScreenStateForGroup(groupType, groupUUID, subState = {}) {
        const groupScreen = groupType === GroupTypes.ROOM ? RoomsScreen.name : CategoriesScreen.name;
        const groupDetailScreen = groupType === GroupTypes.ROOM ? ScreenState.RoomDetail : ScreenState.CategoryDetail;
        return {
            screen: groupScreen,
            params: {
                screen: groupDetailScreen,
                params: {
                    groupUUID,
                    groupType,
                    ...subState
                }
            }
        }
    }

    static getAmbientControlContentName(control) {
        if (!control) {
            return UrlStartLocation.FAVORITES;
        }

        let contentScreenState = ScreenState.AmbientControlContent;

        if (typeof control.getReactControlContent() === "function") {
            contentScreenState = "ControlContent"
        }

        if (control.controlType === "AudioZone") {
            contentScreenState = ScreenState.AmbientAudioZoneControlContent;
        }

        if (control.controlType === "AudioZoneV2") {
            contentScreenState = ScreenState.AmbientAudioZoneV2ControlContent;
        }

        return contentScreenState;
    }

    static getScreenStateFromLocationUrl(location) {
        if (!location) {
            console.warn("AmbientUtils", "getScreenStateFromLocationUrl: no location provided, assuming favorites!");
            location = UrlStartLocation.FAVORITES;
        }
        let tabScreen = this.getTabScreenForLocation(location);
        let resultState;

        if (tabScreen) {
            resultState = {
                screen: tabScreen
            }
        } else {
            const locationParts = location.split("/");
            if (locationParts[0] === GroupTypes.ROOM || locationParts[0] === GroupTypes.CATEGORY) {
                const groupType = locationParts[0],
                    groupUUID = locationParts[1];
                resultState = AmbientUtils.getScreenStateForGroup(groupType, groupUUID);
            } else if (locationParts[0] === UrlStartLocation.CONTROL || locationParts[0] === UrlStartLocation.CONTROL_ALERT) {
                NavigationComp.removeLastControlContentRouteInAmbient();
                const controlUUID = locationParts[1];
                const control = ActiveMSComponent.getControlByUUID(controlUUID)

                if (!control) {
                    EntryPointHelper.resetEntryPointLocation();
                    return {
                        screen: AmbientScreen.name,
                        params: {
                            screen: AmbientStackNavigator.name,
                            params: {
                                screen: ActiveMiniserverScreen.name,
                                params: {
                                    screen: this.getTabScreenForLocation(UrlStartLocation.FAVORITES)
                                }
                            }
                        }
                    }
                }

                let contentScreenState = this.getAmbientControlContentName(control);

                return {
                    screen: AmbientScreen.name,
                    params: {
                        screen: AmbientStackNavigator.name,
                        params: {
                            screen: contentScreenState,
                            params: {controlUUID}
                        }
                    }
                }
            }
        }

        return {
            screen: AmbientScreen.name,
            params: {
                screen: AmbientStackNavigator.name,
                params: {
                    screen: ActiveMiniserverScreen.name,
                    params: resultState
                }
            }
        }
    }

    // endregion

    static shouldStartWithAmbient(location) {
        const canShow = SandboxComponent.canShowAmbientMode(),
            showInitial = PersistenceComponent.getStartAmbientModeInitially(),
            locationHandled = AmbientUtils.locationHandledByAmbient(location);
        let showInAmbient = canShow && showInitial && locationHandled;
        Debug.AmbientMode && console.log("AmbientUtils", "shouldStartWithAmbient=" + showInAmbient);
        Debug.AmbientMode && console.log("AmbientUtils", "                      location=" + location);
        Debug.AmbientMode && console.log("AmbientUtils", "      locationHandledByAmbient=" + locationHandled);
        Debug.AmbientMode && console.log("AmbientUtils", "            canShowAmbientMode=" + canShow);
        Debug.AmbientMode && console.log("AmbientUtils", "     startAmbientModeInitially=" + showInitial);
        return showInAmbient;
    }

    static locationHandledByAmbient(location) {
        if (!location) {
            console.warn("AmbientUtils", "locationHandledByAmbient - no location provided!");
            return false;
        }
        let locParts = location.split("/");
        let handled = false;
        switch (locParts[0]) {
            case UrlStartLocation.FAVORITES:
            case UrlStartLocation.CENTRAL:
            case UrlStartLocation.ROOM:
            case UrlStartLocation.CATEGORY:
            case UrlStartLocation.LIKE_PRESENCE_DETECTION:
            case UrlStartLocation.CONTROL:
                handled = true;
                break;
            case UrlStartLocation.LAST_POSITION:
                // depends where the last position was, wether or not it can be handled. e.g. deviceLearning cannot be handled.
                const epUrl = NavigationComp.getEntryPointURL();
                handled = true;
                if (epUrl && Array.isArray(epUrl)) {
                    if (epUrl[0] === ActiveMSComponent.getActiveMiniserverSerial().toLowerCase()) {
                        epUrl.shift()
                    }
                    handled = this.locationHandledByAmbient(epUrl[0])
                }
                break;
            default:
                break;
        }
        return handled;
    }

    static getDefaultLocationScreenState() {
        let promise;
        let location = EntryPointHelper.getLocation();


        if (location === UrlStartLocation.LIKE_PRESENCE_DETECTION) {
            promise = NavigationComp.getLikePresenceDetectionRoomLocation();
        } else {
            // some urlStart-Locations aren't handled by getDefaultLocationScreenState
            if (location === UrlStartLocation.LAST_POSITION) {
                location = UrlStartLocation.FAVORITES;

            } else if (!this.locationHandledByAmbient(location)) {
                location = UrlStartLocation.FAVORITES;
            }
            promise = Q.resolve(location);
        }

        return promise.then((validatedLoc) => {
            return validatedLoc ? AmbientUtils.getScreenStateFromLocationUrl(validatedLoc) : null;
        })
    }



    // region ambient mode state resetting

    static resetAmbientStateTo(state, defaultNavParams) {
        Debug.AmbientMode && console.log("AmbientUtils", "resetAmbientState: " + JSON.stringify(defaultNavParams));
        let readyToReset = false;

        let existingAmbientRoute = getRouteFromNavigationState(state, AmbientScreen.name);
        if (!existingAmbientRoute) {
            console.error("AmbientUtils", "resetAmbientStateTo failed - ambientScreen not in route! Must be shown for this to work! ", state);
            console.error("AmbientUtils", JSON.stringify(state));

        } else if (!defaultNavParams || defaultNavParams.screen !== AmbientScreen.name) {
            console.error("AmbientUtils", "resetAmbientStateTo failed - defaultState doesn't start with ambientScreen", defaultNavParams);
            console.error("AmbientUtils", JSON.stringify(defaultNavParams));

        } else if (!existingAmbientRoute.state) {
            existingAmbientRoute.params = defaultNavParams.params;
            readyToReset = true;

        } else if (existingAmbientRoute.state.type !== "tab") {
            console.error("AmbientUtils", "resetAmbientStateTo failed - ambientScreen doesnt have ambientTabNavigator!", existingAmbientRoute);
            console.error("AmbientUtils", JSON.stringify(existingAmbientRoute));

        } else {
            existingAmbientRoute.params = defaultNavParams.params;
            readyToReset = this.resetStateToShow(existingAmbientRoute.state, defaultNavParams.params);
            //readyToReset = this.resetAmbientTabNavigatorTo(existingAmbientRoute.state, defaultNavParams.params);
        }

        Debug.AmbientMode && console.log("AmbientUtils", "   --> ready to reset = " + !!readyToReset);
        return readyToReset;
    }

    /**
     * Adopts the navigatorState passed in to show the info represented by visibleInRoute. Returns false if not possible.
     * @param navigatorState {{[index]:number, routes:array, type:string}}  the state to adopt/check
     * @param visibleInRoute {{screen:string, [params]:object}} the info on what is to be shown inside/underneath this navigator.
     * @returns {boolean} whether or not the state could be adoped or needs to be deleted & recreated.
     */
    static resetStateToShow(navigatorState, visibleInRoute) {
        if (navigatorState && (!visibleInRoute || !visibleInRoute.screen)) {
            //showing something, but nothing should be visible underneath
            return false;
        }
        let isStateValid = true;
        let routesIdx = navigatorState.routes.findIndex(routeEntry => routeEntry.name === visibleInRoute.screen);
        if (routesIdx >= 0) {
            if (navigatorState.type === "tab") {
                // ensure tab navigator has the proper state selected.
                navigatorState.index = routesIdx;

            } else if (routesIdx !== navigatorState.routes.length - 1) {
                // moving screen to the top of navigator, discard others shown on top
                navigatorState.routes = navigatorState.routes.filter((re, idx) => idx <= routesIdx);
                navigatorState.index = routesIdx;
            }

            // if the route has a sub-state, verify it also shows the proper info!
            if (!!navigatorState.routes[routesIdx].state && !this.resetStateToShow(navigatorState.routes[routesIdx].state, visibleInRoute.params)) {
                // deleting sub-state at routeIdx --> will be re-created using params set below.
                delete navigatorState.routes[routesIdx].state;
            }

            // ensure the params are right.
            if (hashForObject(navigatorState.routes[routesIdx].params) !== hashForObject(visibleInRoute.params)) {
                // adopting params of sub-route, didn't match! important as sub-routes may be recreated based on params
                // if state has been reset.
                navigatorState.routes[routesIdx].params = visibleInRoute.params;
            }

        } else {
            isStateValid = false; // reset the whole navigatorState - visbile screen not founde in states-route
        }

        return isStateValid;
    }
    // endregion

    static async navigateToDefaultLocation(navigationProp, manual) {
        if (EntryPointHelper.getLocation() === UrlStartLocation.LAST_POSITION && !manual) {
            let recoveredState = SandboxComponent.getPersistedAmbientTabState();
            Debug.AmbientMode && console.log("AmbientUtils", "navigateToDefaultLocation --> last position: ", cloneObjectDeep(recoveredState));
            setAmbientStateBlock(true);

            const currentState = navigationProp.getState();
            if (currentState.routes[0] && currentState.routes[0].state) {
                // update the current route of the background app, so if we close the ambient mode we are where we left before the ambient mode opened
                recoveredState.routes[0] = currentState.routes[0];
            }

            //The reset caused the AMBIENT_MODE variable to be false for a short period, although the Ambient Mode was still active
            //therefore we are blocking the AMBIENT_MODE variable for the time the reset takes
            await recoveredState && navigationProp.reset(recoveredState);
            setAmbientStateBlock(false);
        } else {
            AmbientUtils.getDefaultLocationScreenState().then((defLocState) => {
                navigationProp.dispatch(state => {
                    let newState = cloneObjectDeep(state);
                    // cannot recover states where e.g. a control content has to be above a room screen.
                    // In this situation the AmbientScreen shows the activeMiniserverscreen that in turn shows the room-tab and
                    // within it the room itself --> and ontop of the activeMiniserverScreen theres as second route that shows
                    // the control itself.
                    if (this.resetAmbientStateTo(newState, defLocState)) {
                        // root state correct or adopted, reset to
                        return CommonActions.reset(newState);
                    } else {
                        // root state incorrect, use navigate with screen+params to recreate route.
                        return CommonActions.navigate(defLocState.screen, defLocState.params);
                    }
                })
            });
        }
    }

    static uploadCustomBackgroundImage() {
        const processResultImage = (res) => {
            PersistenceComponent.updateAmbientModeSetting(
                {
                    imageObject: {
                        title: "Custom",
                        source: res.assets[0],
                        type: OptionType.BASE_IMAGE
                    }
                }
            );
        }

        launchImageLibrary({
            mediaType: "photo"
        }, processResultImage)
    }

    static resetToStackNavigator(sourceTabName) {
        SandboxComponent.dispatchAmbientTabEvent({
            selectedTab: 0,
            selectedTabName: AmbientStackNavigator.name,
            resetTabName: sourceTabName,
            reset: true
        })
    }
}

export default AmbientUtils
