'use strict';

define(['MediaSearchBase', 'AudioZoneControlEnums'], function (MediaSearchBase, AudioZoneControlEnums) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var DEFAULT_LIGHT_CELLS = GUI.TableViewV2.CellType.LIGHT,
            GLOBAL_IDENTIFIER = 'global',
            LAST_SELECTED_INDEX = 'lastSelectedIndex';

        class GlobalSearchScreen extends MediaSearchBase {
            //region Getter
            get inputPlaceholder() {
                return _('media.global-search-button.title');
            }

            get contentType() {
                return this._getContentTypeForTag(this.tag);
            }

            get identifier() {
                return this._getIdentifierForTag(this.tag);
            }

            get mediaType() {
                return this._getMediaTypeForTag(this.tag);
            }

            get username() {
                if (this.tag === MediaEnum.Search_Result.SPOTIFY) {
                    return this.spotifyHelper.getCurrentSpotifyUserObj().user;
                } else {
                    return MediaEnum.NOUSER;
                }
            } //endregion Getter


            constructor(details) {
                super(...arguments);
                this.spotifyHelper = details.spotifyHelper;
                this.searchResult = {};
                this.uniqueIdentifier = "";
                this.searchResultSections = [];
            }

            viewDidLoad() {
                var args = arguments,
                    loadServicesPromise,
                    keyWordsPromise,
                    loadFilePromise;
                loadServicesPromise = MediaServerComp.getCurrentServices().promise.then(function (services) {
                    this.hasServices = !!services.length;
                    this.services = services;
                    this.tags.push(MediaEnum.Search_Result.LOCAL);

                    if (this.hasServices) {
                        this.tags.push(MediaEnum.Search_Result.SPOTIFY);
                    }

                    this.tags.push(MediaEnum.Search_Result.TUNE_IN);
                }.bind(this));
                keyWordsPromise = this._getSavedKeywords();
                loadFilePromise = this._loadLastSelectedSegment();
                return loadServicesPromise.then(() => {
                    return Q.all([keyWordsPromise, loadFilePromise, Q(super.viewDidLoad(...args) || true)]).finally(function () {
                        return this._updateAllTableViews([this._createLastSearchesSection()]);
                    }.bind(this));
                });
            }

            viewWillAppear() {
                if (this.lastSelectedSegmentIndex > this.tags.length - 1) {
                    this.lastSelectedSegmentIndex = this.tags.length - 1;
                }

                if (this.ViewController instanceof GUI.AddMediaViewControllerPlaylistSpotify) {
                    this.lastSelectedSegmentIndex = 1;
                }

                this._titleBar.segmentedControl.setActiveSegment(this.lastSelectedSegmentIndex, true);

                this._titleBar.segmentedControl.setDisabled(this.ViewController instanceof GUI.AddMediaViewControllerPlaylistSpotify);

                this.pageView.disableUserInteraction(this.ViewController instanceof GUI.AddMediaViewControllerPlaylistSpotify);
                this.pageView.setActivePage(this.lastSelectedSegmentIndex);
                return super.viewWillAppear(...arguments);
            }

            viewWillDisappear() {
                PersistenceComponent.saveFile(LAST_SELECTED_INDEX, this.segmentIndex.toString(), DataType.STRING);
                super.viewWillDisappear(...arguments);
            }

            rightSectionButtonTapped(section, tableView) {
                var lastSelectedItem, showStateArgs;

                if (section === 0 && !this.isSearching) {
                    this._lastUsedKeywords = [];
                    MediaServerComp.deleteKeywords(GLOBAL_IDENTIFIER, this.control.uuidAction).done(this._updateAllTableViews.bind(this, [this._createLastSearchesSection()]));
                } else if (this.isSearching) {
                    lastSelectedItem = tableView.dataSource.tableContent[section];
                    lastSelectedItem.contentType = this.contentType;
                    lastSelectedItem.name = lastSelectedItem.headerTitle + " - " + this.keyword;
                    showStateArgs = [Controls.AudioZoneControl.MediaBrowserBase.getScreenStateForItem(lastSelectedItem), null, {
                        username: this.username,
                        lastSelectedItem: lastSelectedItem,
                        identifier: this.identifier,
                        contentTypes: [this.contentType],
                        mediaType: this.mediaType,
                        srcCmd: lastSelectedItem.link
                    }];
                    this.ViewController.showState.apply(this.ViewController, showStateArgs);
                }
            }

            getMediaInfo() {
                var mediaInfo;

                switch (this.tag) {
                    case MediaEnum.Search_Result.LOCAL:
                    case MediaEnum.Search_Result.TUNE_IN:
                        mediaInfo = {
                            service: {
                                uid: this.identifier + "/" + MediaEnum.NOUSER
                            }
                        };
                        break;

                    case MediaEnum.Search_Result.SPOTIFY:
                        mediaInfo = {
                            service: {
                                uid: this.identifier + "/" + this.username
                            }
                        };
                        break;
                }

                return mediaInfo;
            }

            /**
             * The user did hit enter - this is when the search must be triggered immediately.
             * @param value
             * @private
             */
            _onSubmit(value) {
                super._onSubmit(...arguments);

                MediaServerComp.saveKeyword(GLOBAL_IDENTIFIER, value, this.control.uuidAction);
            }

            _clearSearch() {
                super._clearSearch(...arguments);

                this._getSavedKeywords(GLOBAL_IDENTIFIER, this.control.uuidAction).then(function () {
                    this.searchResultSections = [];
                    this.tags.forEach(function (tag) {
                        this._setBackgroundViewForTag(tag, this.EMPTY_VIEW);
                    }.bind(this));

                    this._updateAllTableViews([this._createLastSearchesSection()]);
                }.bind(this));
            }

            _onSearchError(response) {
                super._onSearchError(...arguments);

                this.uniqueIdentifier = "";
            }

            _createLastSearchesSection() {
                var section = {
                    headerTitle: _('media.global-search.keywords'),
                    sectionRightButtonTitle: _("delete"),
                    rows: []
                };
                this._lastUsedKeywords && this._lastUsedKeywords.forEach(function (keyword) {
                    section.rows.pushObject({
                        content: {
                            title: keyword,
                            color: window.Styles.colors.green
                        },
                        action: this._launchSearchFor.bind(this, keyword),
                        type: DEFAULT_LIGHT_CELLS
                    });
                }.bind(this));
                return section;
            }

            _getSavedKeywords() {
                return MediaServerComp.loadLastUsedKeywords(GLOBAL_IDENTIFIER, this.control.uuidAction).then(function (res) {
                    if (res) {
                        this._lastUsedKeywords = res.reverse();
                    }
                }.bind(this), function () {
                    this._lastUsedKeywords = [];
                }.bind(this));
            }

            _loadLastSelectedSegment() {
                return PersistenceComponent.loadFile(LAST_SELECTED_INDEX, DataType.STRING).then(function (idx) {
                    this.lastSelectedSegmentIndex = parseInt(idx) || 0;
                }.bind(this), function (err) {
                    this.lastSelectedSegmentIndex = 0; // There is no problem, if the file doesn't exists
                    // We don't have to throw an error
                }.bind(this));
            }

            _setSegmentSelectedType(idx) {
                var segmentEntry = this.segmentEntries[idx],
                    mediaType = segmentEntry.mediaType,
                    contentType = segmentEntry.contentType;
                MediaServerComp.setSelectedSegmentType(mediaType, contentType);
                this._selectedSegmentedEntry = segmentEntry;
                this._selectedContentType = contentType;
            }

            _getNameForTag(tag) {
                var name;

                switch (tag) {
                    case MediaEnum.Search_Result.LOCAL:
                        name = _("media.library");
                        break;

                    case MediaEnum.Search_Result.SPOTIFY:
                        name = _("media.popup.spotify.title");
                        break;

                    case MediaEnum.Search_Result.TUNE_IN:
                        name = _("media.service.webradio");
                        break;

                    default:
                        name = tag;
                }

                return name;
            }

            _getContentTypeForTag(tag) {
                var contentType;

                switch (tag) {
                    case MediaEnum.Search_Result.LOCAL:
                        contentType = MediaEnum.MediaContentType.LIBRARY;
                        break;

                    case MediaEnum.Search_Result.SPOTIFY:
                    case MediaEnum.Search_Result.TUNE_IN:
                        contentType = MediaEnum.MediaContentType.SERVICE;
                        break;

                    default:
                        contentType = null;
                }

                return contentType;
            }

            _getIdentifierForTag(tag) {
                var identifier;

                switch (tag) {
                    case MediaEnum.Search_Result.LOCAL:
                        identifier = MediaEnum.Service.LOCAL;
                        break;

                    case MediaEnum.Search_Result.SPOTIFY:
                        identifier = MediaEnum.Service.SPOTIFY;
                        break;

                    default:
                        identifier = null;
                }

                return identifier;
            }

            _getMediaTypeForTag(tag) {
                var mediaType;

                switch (tag) {
                    case MediaEnum.Search_Result.LOCAL:
                        mediaType = MediaEnum.MediaType.LIBRARY;
                        break;

                    case MediaEnum.Search_Result.SPOTIFY:
                    case MediaEnum.Search_Result.TUNE_IN:
                        mediaType = MediaEnum.MediaType.SERVICE;
                        break;

                    default:
                        mediaType = null;
                }

                return mediaType;
            }

        }

        Controls.AudioZoneControl.GlobalSearchScreen = GlobalSearchScreen;
    }
    return Controls.AudioZoneControl.GlobalSearchScreen;
});
