import * as React from "react"
import Svg, { Path } from "react-native-svg"

function LoxoneTrilogy(props) {
    return (
        <Svg isreact="true"
            height={24}
            viewBox="0 0 24 24"
            width={24}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M20.5 1A2.5 2.5 0 0123 3.5v17a2.5 2.5 0 01-2.5 2.5h-17A2.5 2.5 0 011 20.5v-17A2.5 2.5 0 013.5 1zM9.727 2H3.5A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.412-.993zM20.5 2h-.258l-5.137 6.538L22 19.295V3.5a1.5 1.5 0 00-1.356-1.493zm-1.53 0h-8.056l3.62 5.647z"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default LoxoneTrilogy
