/**
 * Loads a file from the server, keeps it cached until the http-head-request reports a modification.
 * get will resolve with an object containing the file in a data-attribute and the version in a separate attr.
 */
import ServerFileLoader from "./ServerFileLoader";
import VolatileStorageHandler from "../storage/VolatileStorageHandler";
import CacheFileHandler from "./CacheFileHandler";

class CachedServerFileLoader extends ServerFileLoader {
    static debugName = "CachedServerFileLoader";
    static ShowLog = true;

    static getFile(fileRequest, props) {
        return new CachedServerFileLoader(fileRequest, props).getFile();
    }

    constructor(fileRequest, {cacheHandler} = {}) {
        super(fileRequest);
        this._debugName = CachedServerFileLoader.debugName + "@" + this._fileRequest.name;

        // if no cacheFileHandler has been provided, initialize one with a volatile storage.
        this._cacheHandler = cacheHandler || new CacheFileHandler(new VolatileStorageHandler());
    }

    getFile() {
        CachedServerFileLoader.ShowLog && console.log(this._debugName, "getFile")

        return this._isCacheUpToDate().then(() => {
                // local data is up to date, use it.
                return this._cacheHandler.getFile(this._fileRequest);
            }, err => {
                // outdated or sth went wrong - or it's outdated, fetch from server
                return super.getFile().then(result => {
                    this._cacheHandler.saveFile(result);
                    return result;
                });
            });
    }

    _isCacheUpToDate() {
        let versionPromises = [ super.getVersion(), this._cacheHandler.getVersion(this._fileRequest) ];
        return Q.allSettled(versionPromises).then((versionResults) => {
            let svrVersion, localVersion;
            if (versionResults[0].state === 'fulfilled') {
                svrVersion = versionResults[0].value;
            }
            if (versionResults[1].state === 'fulfilled') {
                localVersion = versionResults[1].value;
            }

            if (localVersion && !svrVersion) {
                CachedServerFileLoader.ShowLog && console.log(this._debugName, "_isCacheUpToDate: no server version available, but a local version -> assume yes!")
                // assuming yes, server not checkable atm
                return Q.resolve();

            } else if (svrVersion && localVersion && svrVersion === localVersion) {
                // checked - it's a match!
                CachedServerFileLoader.ShowLog && console.log(this._debugName, "_isCacheUpToDate: exact match, yes!");
                return Q.resolve();
            } else {
                // not up to date!
                CachedServerFileLoader.ShowLog && console.log(this._debugName, "_isCacheUpToDate: no, outdated!");
                return Q.reject();
            }
        }).fail(err => {
            CachedServerFileLoader.ShowLog && console.log(this._debugName, "_isCacheUpToDate: failed, assume outdated!", err);
            return Q.reject(err);
        });
    }
}


export default CachedServerFileLoader;