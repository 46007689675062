'use strict';
/*
 duration
 checkable
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.DURATION_SUBTITLE = "DurationSubtitleCell";

    class DurationSubtitleCell extends GUI.TableViewV2.Cells.CheckableCell {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(subtitleText, duration) {
                var subtitle = '',
                    durationExtension = '';

                if (subtitleText) {
                    subtitle = '<div class="subtitle__text">' + subtitleText + '</div>';
                }

                if (duration) {
                    durationExtension = ImageBox.getResourceImageWithClasses("resources/Images/Controls/Daytimer/clock.svg", "subtitle__icon") + '<div class="subtitle__text">' + duration + '</div>';
                }

                return $(subtitle + durationExtension);
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("duration-subtitle-cell");
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                var subtitleElement = this.element.find(".texts-placeholder__subtitle");

                if (subtitleElement.length === 0) {
                    this.element.find(".content__texts-placeholder").append('<div class="texts-placeholder__subtitle text-overflow-ellipsis">');
                    subtitleElement = this.element.find(".texts-placeholder__subtitle");
                }

                var subtitleText = subtitleElement.text();
                subtitleElement.empty();
                subtitleElement.addClass("placeholder__subtitle-container");
                subtitleElement.append(DurationSubtitleCell.Template.getTemplate(subtitleText, this.content.duration));

                if (!this.content.checkable) {
                    this.elements.checkIconCntr.hide();
                }
            }.bind(this));
        }

        clickableAllowed() {
            return true;
        }

    }

    GUI.TableViewV2.Cells.DurationSubtitleCell = DurationSubtitleCell;
    return GUI;
}(window.GUI || {});
