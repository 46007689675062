import * as React from "react"
import Svg, { Path } from "react-native-svg"

function UserWithClock(props) {
    return (
        <Svg isreact="true"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M6.875 6c1.308 0 2.861-.776 3.322-1.661a.501.501 0 01.878-.017c.853 1.494 1.755 1.967 3.206 1.687l.608-.15.505-.125C14.894 2.754 12.666.5 10 .5 7.46.5 5.322 2.549 4.693 5.319 5.596 5.875 6.239 6 6.875 6z"
            />
            <Path
                d="M12 19c0-1.585.572-3.038 1.519-4.168L13 14.648v-2.206c1.503-1.162 2.594-3.348 2.489-5.704a6.453 6.453 0 01-1.948.348c-1.194 0-2.134-.525-2.931-1.635C9.756 6.347 8.249 7 6.875 7c-.783 0-1.434-.141-2.348-.628-.183 2.784.97 4.907 2.473 6.069v2.206l-4.678 1.671A3.51 3.51 0 000 19.614V22a.5.5 0 00.5.5h12.531A6.462 6.462 0 0112 19z"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 19c0-3.032 2.467-5.5 5.5-5.5S24 15.968 24 19c0 3.033-2.467 5.5-5.5 5.5A5.506 5.506 0 0113 19zm5.5 1.5h2a.5.5 0 000-1H19v-3a.5.5 0 00-1 0V20a.5.5 0 00.5.5z"
            />
        </Svg>
    )
}

export default UserWithClock
