import { View, ScrollView } from "react-native";
import { useMemo } from "react";
import {
    useLiveState,
    useControl,
    LxReactText,
    LxReactTextView,
    LxReactTableView,
    navigatorConfig,
    LxReactContextMenuHandler,
    useBackNavigation
} from "LxComponents";
import Wb2Enums from "./wallboxEnums.js"
import globalStyles from "GlobalStyles";

export default function WbModeSettings({ navigation, route }) {
    const details = route.params;
    const controlUuid = details.controlUuid;

    const control = useControl(controlUuid);
    const liveState = useLiveState(controlUuid, ["modes", "unplugLimitModeId"])

    navigation.setOptions(
        navigatorConfig({
            title: _('settings')
        })
    );

    useBackNavigation(() => {
        navigation.goBack();
    }, []);

    const styles = {
        cellTitle: {
            ...Wb2Enums.styles.cellTitle,
        },
        inputStyle: {
            fontSize: globalStyles.fontSettings.sizes.medium,
            color: globalStyles.colors.stateActive,
            marginRight: 0
        },
        inputClearStyle: {
            marginLeft: 10
        }
    }

    const limitUnit = useMemo(() => {
        return lxSplitFormat(control.details.actualFormat).succTxt.replace(" ", "");
    }, [control.details.actualFormat])
    
    const renderedLimitBar = useMemo(() => {
        let main = _("controls.wallbox2.allowed-charging-power"),
            minTxt = lxFormat(control.details.actualFormat, control.minChargingPower, true),
            maxTxt = lxFormat(control.details.actualFormat, control.maxChargingPower, true),
            unit = "",
            text = `${main}: ${minTxt}...${maxTxt} ${unit}`;

        return <View style={Wb2Enums.styles.notificationCntr}>
            <LxReactText style={Wb2Enums.styles.notificationText}>{text}</LxReactText>
        </View>
    }, [control.minChargingPower, control.maxChargingPower]);

    const showModeLimitSlider = (mode) => {
        const sliderFormat = control.details.actualFormat.split(/(\.\df).+$/) 
        let options = {
            value: mode.value,
            format: [sliderFormat[0], sliderFormat[1]].join(""), // no unit, provided in description
            minValue: control.minChargingPower,
            maxValue: control.maxChargingPower,
            step: control.getStepSize(control.details.actualFormat),
            onSliderDisappear: (value) => {
                if (value !== mode.value) {
                    control.updateMode(mode.id, mode.name, value);
                }
            }
        };
        let title = `${getModeName({ id: mode.id })}: ${_("controls.wallbox2.charging-power-limit") + " [" + limitUnit + "]"}`
        LxReactContextMenuHandler.shared.showContextMenu(options, title, null, GUI.LxSliderContextMenu);
    }

    const getModeName = (mode) => {
        return mode.name || (_("controls.wallbox2.mode") + " " + mode.id);
    }

    const getModeSection = (mode) => {
        return {
            headerStrongTitle: _("controls.wallbox2.mode") + " " + mode.id,
            headerTitleStyle: Wb2Enums.styles.sectionHeaderTitleStyle,
            rows: [
                {
                    title: _("athene.settings.about.name"),
                    titleStyle: styles.cellTitle,
                    mainRightContent: {
                        comp: LxReactTextView,
                        props: {
                            value: mode.name,
                            textStyle: styles.inputStyle,
                            placeholderTextColor: globalStyles.colors.text.secondary,
                            hideRightIcon: true,
                            placeholder: "-",
                            displayInCell: true,

                            onBlur: (newName, valid, modified) => {
                                if (newName !== mode.name && modified) {
                                    control.updateMode(mode.id, newName, mode.value);
                                    // if imidiately after changing the mode name here the user clicks on the limit slider, 
                                    // the mode name is not updated because it will be immediately overwritten by the value 
                                    // from the other update mode command. Thats why we modify the name here
                                    mode.name = newName;
                                }
                            },
                            filterRegex: /[\r\n\t\f\v]/g // This is the exact regex the Wallbox Gen. 2 dialog uses on the config side
                        }
                    }
                },
                {
                    title: _("controls.wallbox2.charging-power-limit") + " [" + limitUnit + "]",
                    titleStyle: styles.cellTitle,
                    //cellElementStyle: Wb2Enums.styles.cellElement,
                    subTitle: mode.setByLogic ? _("controlled-via-logic") : null,
                    mainRightContent: {
                        comp: LxReactText,
                        props: {
                            children: (!mode.name && mode.value === 0) ? "-" : lxFormat(control.details.actualFormat, mode.value, true),
                            style: styles.inputStyle
                        }
                    },
                    onPress: mode.setByLogic ? null : () => {
                        showModeLimitSlider(mode);
                    }
                }
            ]
        }
    }

    const getUnplugModeSection = () => {
        const unplugModesFiltered = control.unplugModes.filter((mode) => {
            return mode.id !== control.manualModeId;
        });

        return {
            headerStrongTitle: _("controls.wallbox2.unplug-mode-header"),
            headerTitleStyle: Wb2Enums.styles.sectionHeaderTitleStyle,
            rows: unplugModesFiltered.map((mode) => {
                let modeActive = ("" + mode.id) === ("" + liveState.states.unplugLimitModeId);
                return {
                    content: {
                        title: getModeName(mode),
                        selected: modeActive,
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED
                    },
                    type: GUI.TableViewV2.CellType.CHECKABLE,
                    didCheckCell: () => {
                        control.setUnplugMode(mode.id);
                    }
                }
            })
        }
    }

    const tableContent = useMemo(() => {
        let result = [];

        if (control.modes) {
            Object.values(control.modes.filter((v) => v.id !== control.manualModeId)).forEach((mode) => {
                result.pushObject(getModeSection(mode));
            });
        }

        control.carConnectedInfoAvailable && result.pushObject(getUnplugModeSection());

        return result;
    }, [liveState.states.modes, liveState.states.unplugLimitModeId, control.carConnectedInfoAvailable]);


    return <View style={Wb2Enums.styles.rootCntr}>
        {renderedLimitBar}
        <ScrollView style={Wb2Enums.styles.contentCntr}>
            <LxReactTableView tableContent={tableContent} />
        </ScrollView>
    </View>
}
