'use strict';

define("AudioZoneControlContentHD", ["ControlActionBaseScreen", "AudioZoneControlEnums", "AudioZoneControlContent", "AudioZoneControlContentHomeScreen"], function (ControlActionBaseScreen, AudioZoneControlEnums, AudioZoneControlContent, AudioZoneControlContentHomeScreen) {
    return class AudioZoneControlContentHD extends ControlActionBaseScreen {
        //region Static
        static Template = function () {
            var getBody = function getBody() {
                return $('' + '<div class="audio-zone-control-contenthd__container">' + '   <div class="container__master"></div>' + '   <div class="container__detailed"></div>' + '</div>' + '');
            };

            return {
                getBody: getBody
            };
        }(); //endregion Static

        constructor() {
            super(...arguments);
        }

        viewDidLoad() {
            var vdlPrms = [Q(super.viewDidLoad(arguments) || true)];
            this.element.append(AudioZoneControlContentHD.Template.getBody());

            if (!this.elements) {
                this.elements = {};
            }

            this.masterScreen = this.getMasterScreen(); // Don't use "this.appendSubview" we need to await the vdl and vwa promises!

            vdlPrms.push(this.addToHandledSubviews(this.masterScreen));
            this.elements.masterContainer = this.element.find('.container__master');
            this.elements.masterContainer.append(this.masterScreen.getElement());
            this.detailedScreen = this.getDetailedScreen();
            this.detailedScreen.addMultipleView(ScreenState.AudioZoneDetailedVC); // Don't use "this.appendSubview" we need to await the vdl and vwa promises!

            vdlPrms.push(this.addToHandledSubviews(this.detailedScreen));
            this.elements.detailedContainer = this.element.find('.container__detailed');
            this.elements.detailedContainer.append(this.detailedScreen.getElement()); // Overwrite the showState function to navigate in the "this.detailedScreen"

            this._ogMasterShowState = this.masterScreen.ViewController.showState;
            this.masterScreen.ViewController.showState = this.handleMasterShowState.bind(this);
            return Q.all(vdlPrms);
        }

        getMasterScreen() {
            return new AudioZoneControlContent({
                control: this.control
            });
        }

        getDetailedScreen() {
            // we're showing a detailedViewController, that contains a detailedViewController showing the Home Screen.
            // Just like we do in handleMasterShowsState --> Extremely dirty, but does the trick.
            // needed because initial animation of ScreenState HOME_SCREEN behaves differently when detailedViewController
            // lives inside detailedViewController
            //TODO-goelzda: Why do we have to do it like this?
            return new GUI.AudioZoneControlDetailedViewController({
                showStateArgs: [ScreenState.AudioZoneDetailedVC, null, {
                    showStateArgs: [AudioZoneControlEnums.ScreenState.HOME_SCREEN, null, {
                        control: this.control
                    }],
                    control: this.control
                }, AnimationType.FADE]
            }); // This is just a container ViewController to help us with the ViewManagement
        }

        handleMasterShowState(identifier, view, details, animationType, forceParent) {
            var handleInParent = false;

            switch (identifier) {
                case AudioZoneControlEnums.ScreenState.SETTINGS:
                case AudioZoneControlEnums.ScreenState.SETTINGS_SHORTCUT_EDIT:
                case AudioZoneControlEnums.ScreenState.FAVORITE_EDIT:
                case AudioZoneControlEnums.ScreenState.GLOBAL_FAVORITE_EDIT:
                case AudioZoneControlEnums.ScreenState.CONTEXT_MENU_BASE:
                case AudioZoneControlEnums.ScreenState.ADD_MEDIA_VC_BASE:
                case AudioZoneControlEnums.ScreenState.ADD_MEDIA_VC_PLAYLIST:
                case AudioZoneControlEnums.ScreenState.ADD_MEDIA_VC_PLAYLIST_PICKER:
                case AudioZoneControlEnums.ScreenState.ADD_MEDIA_VC_PLAYLIST_SPOTIFY:
                case AudioZoneControlEnums.ScreenState.ADD_MEDIA_VC_FAVS:
                case AudioZoneControlEnums.ScreenState.ADD_ZONE_FAV:
                case AudioZoneControlEnums.ScreenState.EDIT_MEDIA:
                case ScreenState.ControlLocking:
                    handleInParent = true;
                    break;
            }

            if (handleInParent || forceParent) {
                this._ogMasterShowState.apply(this.masterScreen.ViewController, arguments);
            } else {
                // always use a new detailed view controller to avoid having a history.
                this.detailedScreen.showState.apply(this.detailedScreen, [ScreenState.AudioZoneDetailedVC, null, {
                    showStateArgs: arguments,
                    control: this.control
                }, AnimationType.FADE]);
            }
        }

    };
});
