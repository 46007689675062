'use strict';

define(['FroniusControlEnums'], function (FroniusControlEnums) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var VALUE_FORMAT = "%.2f"; // all the computation factors are depending on the height

    var height = 300;
    var HEIGHT_FACTOR = {
        outerDiameter: 67 / height,
        innerDiameter: 55 / height,
        spacing: 25 / height,
        width: 320 / height,
        textHeight: 40 / height,
        text: {
            descrHeight: 15 / height,
            valueHeight: 16 / height
        }
    };
    HEIGHT_FACTOR.text.spacing = HEIGHT_FACTOR.textHeight - (HEIGHT_FACTOR.text.descrHeight + HEIGHT_FACTOR.text.valueHeight);
    return class FroniusAnimationView extends GUI.View {
        //region Static
        static Template = function () {
            var IconSet = {
                production: Icon.EnergyMonitor.PRODUCTION,
                consumption: Icon.EnergyMonitor.CONSUMPTION,
                external: Icon.EnergyMonitor.GRID,
                flowArrows: Icon.EnergyMonitor.FLOW_ARROWS
            };
            var ICON_VIEWBOX = 25,
                // the default viewbox size of icons
                ICON_SIZE_FACTOR = 0.7; // make the icons a bit smaller to have some space around it

            var getContainer = function getContainer() {
                return $('<div class="fronius-animation-view">' + //'<div class="fronius__visualisation__spacing"/>'+ // for what?
                    '<div class="fronius__visualisation">' + '<svg class="fronius__visu" viewBox="0 0 320 300"></svg>' + // the viewbox sets the ratio
                    '</div>' + //'<div class="fronius__visualisation__spacing"/>'+ // for what?
                    '</div>');
            };

            var getEmbeddedFlowArrows = function getEmbeddedFlowArrows(s, center, partyType) {
                var arrowGroup = s.g().addClass("visu__flow-arrows flow-arrows--" + partyType); // load, position and add the icon

                var imageString = ImageBox.getResourceImage(IconSet.flowArrows);
                var imgBlob = new Blob([imageString], {
                    type: "image/svg+xml;charset=utf-8"
                });
                Snap.load(URL.createObjectURL(imgBlob), function (f) {
                    var selected = f.select("g");
                    var centerArrow = selected.select(".arrow-2");
                    var rcBox = centerArrow.getBBox(); // center the icon in it's parent

                    var mvX = rcBox.cx * -1;
                    var mvY = rcBox.cy * -1;
                    var matrix = new Snap.Matrix();
                    matrix.translate(mvX, mvY);
                    selected.transform(matrix);
                    selected.appendTo(arrowGroup);
                });
                arrowGroup.transform("translate(" + center.x + "," + center.y + ")");
            };

            var getProductionNode = function getProductionNode(s, startPt1, startPt2, endPt1, endPt2, center, drawSettings) {
                var path = s.path(''.concat('M' + (startPt1.x + drawSettings.strokeCorrection) + ',' + startPt1.y, // buffer will be 'countered' in the next line
                    'h-' + drawSettings.strokeCorrection, // add extra sBuff, so the line won't stop in the middle of the stroke width
                    arcTo(startPt2, drawSettings.outerRadius, true), 'H' + endPt1.x, 'V' + endPt1.y, arcTo(endPt2, drawSettings.outerRadius, true), 'h-' + drawSettings.strokeCorrection));
                createNodeIcon(s, FroniusControlEnums.NodeType.Production, center, drawSettings.innerRadius);
                return path;
            };

            var getExternalNode = function getExternalNode(s, startPt1, startPt2, endPt1, endPt2, center, drawSettings) {
                var cExternal = s.path(''.concat('M' + (startPt1.x + drawSettings.strokeCorrection) + ',' + startPt1.y, // sBuff will be 'countered' in the next line
                    'h-' + drawSettings.strokeCorrection, // add extra sBuff, so the line won't stop in the middle of the stroke width
                    arcTo(startPt2, drawSettings.outerRadius, true), 'v' + drawSettings.strokeCorrection));
                startPt2.y += 1.3; // otherwise it'd overlay.

                var importPath = s.path(''.concat('M' + startPt2.x + ',' + startPt2.y, 'V' + endPt1.y, 'H' + endPt1.x, arcTo(endPt2, drawSettings.outerRadius, true), 'h-' + drawSettings.strokeCorrection));
                createNodeIcon(s, FroniusControlEnums.NodeType.External, center, drawSettings.innerRadius);
                return {
                    circle: cExternal,
                    path: importPath
                };
            };

            var getConsumptionNode = function getConsumptionNode(s, startPt1, startPt2, endPt1, endPt2, center, drawSettings) {
                var path = s.path(''.concat('M' + (startPt2.x + drawSettings.strokeCorrection) + ',' + startPt2.y, // sBuff will be 'countered' in the next line
                    'h-' + drawSettings.strokeCorrection, // add extra sBuff, so the line won't stop in the middle of the stroke width
                    arcTo(startPt1, drawSettings.outerRadius, false), 'H' + endPt2.x, arcTo(endPt1, drawSettings.outerRadius, false), 'h-' + drawSettings.strokeCorrection));
                createNodeIcon(s, FroniusControlEnums.NodeType.Consumption, center, drawSettings.innerRadius);
                return path;
            };

            var getNodeLabels = function getNodeLabels(s, nodeType, valueLocation, descrLocation, title, anchor) {
                var lblValAndText = s.text(valueLocation.x, valueLocation.y, ["0", "kW"]).addClass('visu__node__valUnitCntr').attr({
                    textAnchor: anchor
                });
                var lblValAndTextNode = lblValAndText.node;
                var result = $(lblValAndTextNode).find('tspan');
                var lblValue = $(result[0]).addClass('visu__node__value node__value--' + nodeType)[0];
                var lblUnit = $(result[1]).addClass('visu__node__unit node__unit--' + nodeType)[0];
                var lblDescr = s.text(descrLocation.x, descrLocation.y, title).addClass('visu__node__descr node__descr--' + nodeType).attr({
                    textAnchor: anchor
                });
                return {
                    lblValue: lblValue,
                    lblUnit: lblUnit,
                    lblDescr: lblDescr
                };
            };

            var createNodeIcon = function createNodeIcon(s, nodeType, center, radius) {
                var iconSrc = IconSet[nodeType];
                var iconGroup = s.g().addClass('visu__node__icon node__icon--' + nodeType);
                iconGroup.circle(0, 0, radius).addClass('bg'); // load, position and add the icon

                var addToTarget = iconGroup; // Creating blob to pass to Snap

                var imageString = ImageBox.getResourceImage(iconSrc);
                var imgBlob = new Blob([imageString], {
                    type: "image/svg+xml;charset=utf-8"
                });
                Snap.load(URL.createObjectURL(imgBlob), function (f) {
                    var selected = f.select("path"); // get path from the loaded svg f

                    var selBBox = selected.getBBox();

                    var scaleX = ((radius * 2) * ICON_SIZE_FACTOR) / ICON_VIEWBOX;
                    var scaleY = ((radius * 2) * ICON_SIZE_FACTOR) / ICON_VIEWBOX; // center the icon in it's parent

                    var mvX = selBBox.cx * -1;
                    var mvY = selBBox.cy * -1;
                    var matrix = new Snap.Matrix();
                    matrix.scale(scaleX, scaleY);
                    matrix.translate(mvX, mvY);
                    selected.transform(matrix);
                    selected.addClass('content');
                    selected.appendTo(addToTarget);
                });
                iconGroup.transform("translate(" + center.x + "," + center.y + ")");
            };

            var arcTo = function arcTo(pt, radius, drawClockwise) {
                var sweepFlag = drawClockwise ? "1" : "0";
                return 'A'.concat(radius, ',', radius, ' 0 1,', sweepFlag, ' ', pt.x, ',', pt.y);
            };

            return {
                getContainer: getContainer,
                getProductionNode: getProductionNode,
                getExternalNode: getExternalNode,
                getConsumptionNode: getConsumptionNode,
                getNodeLabels: getNodeLabels,
                getEmbeddedArrows: getEmbeddedFlowArrows
            };
        }(); //endregion Static

        constructor(comfortMode) {
            super(FroniusAnimationView.Template.getContainer());
            this.comfortMode = !!comfortMode;
            this.producing = 0;
            this.consuming = 0;
            this.external = 0;
            this.isExporting = false;
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(() => {
                this.element.toggleClass("fronius-animation-view--comfort-mode", this.comfortMode);
                this._setUpUI();
            });
        }

        viewWillAppear() {
            return Q(super.viewWillAppear(...arguments)).then(() => {
                Object.keys(this.buttons).forEach(function (btnKey) {
                    this.buttons[btnKey].onButtonReleased = this._handleButtonTap.bind(this, this.buttons[btnKey]);
                }.bind(this));
            });
        }

        viewWillDisappear() {
            // reset button handlers.
            Object.keys(this.buttons).forEach(function (btnKey) {
                this.buttons[btnKey].onButtonReleased = null;
            }.bind(this));
            return super.viewWillDisappear(...arguments);
        }

        update(currProduction, currConsumption, isOffline, inStandby, currGrid) {
            this.isOffline = !!isOffline;
            this.producing = currProduction;
            this.consuming = currConsumption;
            this.inStandby = inStandby; // when the inverter is offline we might not know the consumption/production, but we do know what the
            // system uses from/provides to the grid

            if (isOffline && !inStandby && currProduction === 0 && currConsumption === 0 && currGrid !== 0) {
                this.external = Math.abs(currGrid);
                this.isExporting = currGrid < 0;
            } else {
                this.external = Math.abs(currProduction - currConsumption);
                this.isExporting = currProduction > currConsumption;
            }

            this._updateUI();
        }

        _setUpUI() {
            var container = this.element.find('.fronius__visu')[0];
            var s = Snap(container),
                height = container.viewBox.baseVal.height,
                valOfx,
                descrOfx;
            var spacing = HEIGHT_FACTOR.spacing * height;
            var upperAndLowerPadding = spacing + HEIGHT_FACTOR.textHeight * height;
            var width = HEIGHT_FACTOR.width * height;
            var textHeight = height * HEIGHT_FACTOR.textHeight;
            var descrHeight = height * HEIGHT_FACTOR.text.descrHeight;
            var valueHeight = height * HEIGHT_FACTOR.text.valueHeight;
            var drawSettings = {
                strokeThickness: 2.5,
                gapThickness: 3,
                innerRadius: HEIGHT_FACTOR.innerDiameter * height / 2
            };
            drawSettings.strokeCorrection = drawSettings.strokeThickness / 2;
            drawSettings.strokeAxisDiff = (drawSettings.strokeThickness + drawSettings.gapThickness) / 2;
            drawSettings.outerRadius = drawSettings.innerRadius + drawSettings.gapThickness + drawSettings.strokeThickness / 2;
            var nodeSize = HEIGHT_FACTOR.innerDiameter * height + drawSettings.gapThickness * 2 + drawSettings.strokeThickness; // --------- Production

            var prodCenter = {
                x: spacing + nodeSize / 2,
                y: height - (upperAndLowerPadding + nodeSize / 2)
            };
            var prodPt1 = {
                x: spacing + nodeSize,
                y: height - (upperAndLowerPadding + nodeSize / 2) + drawSettings.strokeAxisDiff
            };
            var prodPt2 = {
                x: prodPt1.x,
                y: prodPt1.y - drawSettings.strokeAxisDiff * 2
            }; // --------- export

            var externalCenter = {
                x: width / 2,
                y: upperAndLowerPadding + nodeSize / 2
            };
            var externalPt1 = {
                // external consumption left line start
                x: width / 2 - drawSettings.strokeAxisDiff,
                y: upperAndLowerPadding + nodeSize
            };
            var externalPt2 = {
                // external consumption right line start
                x: externalPt1.x + drawSettings.strokeAxisDiff * 2,
                y: externalPt1.y
            }; // --------- Consumer

            var consumerCenter = {
                x: width - (spacing + nodeSize / 2),
                y: prodCenter.y
            };
            var consumerPt1 = {
                x: width - prodPt1.x,
                // mirror the production point
                y: prodPt2.y
            };
            var consumerPt2 = {
                x: consumerPt1.x,
                y: prodPt1.y
            }; // --------- Arrows

            var arrowHeight = 15;
            var arrYOffset = drawSettings.gapThickness + arrowHeight / 2;
            var arrImportCenter = {
                x: externalPt2.x + (consumerPt1.x - externalPt2.x) / 2,
                y: consumerPt1.y - arrYOffset
            };
            var arrExportCenter = {
                x: (externalPt1.x - prodPt1.x) / 2 + prodPt1.x,
                y: prodPt2.y - arrYOffset
            };
            var arrConsumptionCenter = {
                x: (consumerPt1.x - prodPt1.x) / 2 + prodPt1.x,
                y: prodPt1.y + arrYOffset
            };
            /* -------------------------------- */

            /*       Production node            */

            /* -------------------------------- */

            this.pProduction = FroniusAnimationView.Template.getProductionNode(s, prodPt1, prodPt2, externalPt1, externalPt2, prodCenter, drawSettings);
            valOfx = {
                x: spacing,
                y: height - valueHeight
            };
            descrOfx = {
                x: valOfx.x,
                y: height - textHeight
            };
            var prodLabels = FroniusAnimationView.Template.getNodeLabels(s, FroniusControlEnums.NodeType.Production, valOfx, descrOfx, _("controls.fronius.production"), 'start');
            this.lblProductionValue = prodLabels.lblValue;
            this.lblProductionUnit = prodLabels.lblUnit;
            /* -------------------------------- */

            /*           Export node            */

            /* -------------------------------- */

            /* import node (group consisting of initial circle and line)
             * Split circle into external circle and line, so we can retrieve the width for all circles.
             * This is needed so we can calculate variable circle length
             * */

            var externalFrags = FroniusAnimationView.Template.getExternalNode(s, externalPt1, externalPt2, consumerPt1, consumerPt2, externalCenter, drawSettings);
            this.pImport = externalFrags.path;
            this.gImport = s.g(externalFrags.circle, externalFrags.path);
            valOfx = {
                x: width / 2,
                y: textHeight
            };
            descrOfx = {
                x: valOfx.x,
                y: descrHeight
            };
            var extLabels = FroniusAnimationView.Template.getNodeLabels(s, FroniusControlEnums.NodeType.External, valOfx, descrOfx, _("controls.fronius.import"), 'middle');
            this.lblExternalValue = extLabels.lblValue;
            this.lblExternalDescr = extLabels.lblDescr;
            this.lblExternalUnit = extLabels.lblUnit;
            /* -------------------------------- */

            /*         Consumer node            */

            /* -------------------------------- */

            this.pConsumption = FroniusAnimationView.Template.getConsumptionNode(s, prodPt1, prodPt2, consumerPt1, consumerPt2, consumerCenter, drawSettings);
            valOfx = {
                x: width - spacing,
                y: height - valueHeight
            };
            descrOfx = {
                x: valOfx.x,
                y: height - textHeight
            };
            var consumptionLabels = FroniusAnimationView.Template.getNodeLabels(s, FroniusControlEnums.NodeType.Consumption, valOfx, descrOfx, _("controls.fronius.consumption"), 'end');
            this.lblConsumptionValue = consumptionLabels.lblValue;
            this.lblConsumptionUnit = consumptionLabels.lblUnit;
            this.circleLen = externalFrags.circle.getTotalLength();
            s.g(this.gImport, this.pConsumption, this.pProduction).attr({
                fill: 'none',
                strokeWidth: drawSettings.strokeThickness
            }); // ---------------- Draw arrows

            FroniusAnimationView.Template.getEmbeddedArrows(s, arrExportCenter, 'export');
            FroniusAnimationView.Template.getEmbeddedArrows(s, arrConsumptionCenter, 'consumption');
            FroniusAnimationView.Template.getEmbeddedArrows(s, arrImportCenter, 'import');
            this.pProduction.addClass('fronius__flow flow--export');
            this.gImport.addClass('fronius__flow flow--import');
            this.pConsumption.addClass('fronius__flow flow--consumption'); // ---------------- Prepare for Buttons

            this._prepareButtons();
        }

        /**
         * Will use the nodes as elements for lxButtons that will call registered delegates when nodes are tapped.
         * @private
         */
        _prepareButtons() {
            var btnClass = ".node__icon--",
                transpColor = Color.BUTTON_GLOW;
            this.elements = {};
            this.elements.gridButton = this.element.find(btnClass + "external");
            this.elements.prodButton = this.element.find(btnClass + "production");
            this.elements.consButton = this.element.find(btnClass + "consumption");
            this.buttons = {};
            this.buttons.grid = new GUI.LxButton(this, this.elements.gridButton[0], transpColor, null, false);
            this.buttons.prod = new GUI.LxButton(this, this.elements.prodButton[0], transpColor, null, false);
            this.buttons.cons = new GUI.LxButton(this, this.elements.consButton[0], transpColor, null, false);
            this.addToHandledSubviews(this.buttons.grid);
            this.addToHandledSubviews(this.buttons.cons);
            this.addToHandledSubviews(this.buttons.prod);
        }

        _updateUI() {
            var exporting = this.isExporting ? this.external / this.producing : 0;
            var importing = this.isExporting ? 0 : this.external / this.consuming;
            var flowMargin = this.circleLen * 0.01;
            /* export line */

            var exportTotalLen = this.pProduction.getTotalLength(),
                exportOffset = parseInt((1 - exporting) * this.circleLen),
                exportLen = exportTotalLen;

            if (!this.isExporting) {
                // shared production node
                exportLen -= 2 * this.circleLen;
            }

            this.pProduction.attr({
                strokeDasharray: '0 ' + exportOffset + ' ' + exportLen + ' ' + exportTotalLen
            });
            /* consumption line */

            var consTotalLen = this.pConsumption.getTotalLength(),
                consumptionOffset = 0,
                consumptionLen = consTotalLen;

            if (exporting > 0) {
                // shared production node
                consumptionOffset = exporting * this.circleLen + flowMargin;
                consumptionLen -= exporting * this.circleLen;
            } else if (importing > 0) {
                // shared consumption node
                consumptionLen -= this.circleLen * importing + flowMargin;
            }

            this.pConsumption.attr({
                strokeDasharray: '0 ' + consumptionOffset + ' ' + consumptionLen + ' ' + consTotalLen
            });
            /* import line */

            var importTotalLen = this.pImport.getTotalLength();
            var importLen = importTotalLen - (1 - importing) * this.circleLen;

            if (!this.isExporting) {
                // shared consumption node
                importLen -= flowMargin;
            }

            this.pImport.attr({
                strokeDasharray: importLen + ' ' + importTotalLen
            });

            this._updateLabels();

            this.element.children('.fronius__visualisation').toggleClass('producing', this.producing > 0);
            this.element.children('.fronius__visualisation').toggleClass('exporting', exporting > 0);
            this.element.children('.fronius__visualisation').toggleClass('importing', importing > 0);
            this.element.children('.fronius__visualisation').toggleClass('consuming', true);
        }

        _updateLabels() {
            var inputFormat = VALUE_FORMAT + " kW";
            var splitTexts = lxUnitConverter.convertSplitAndApply(inputFormat, this.producing);

            if (this.isOffline && !this.inStandby) {
                this.lblProductionValue.textContent = "";
                this.lblProductionUnit.textContent = _("offline"); // if we don't have a production info as the inverter is offline, we don't know what consumption we have.

                this.lblConsumptionValue.textContent = "";
                this.lblConsumptionUnit.textContent = _("unknown");
            } else {
                this.lblProductionValue.textContent = splitTexts.valueTxt;
                this.lblProductionUnit.textContent = splitTexts.succTxt;
                splitTexts = lxUnitConverter.convertSplitAndApply(inputFormat, this.consuming);
                this.lblConsumptionValue.textContent = splitTexts.valueTxt;
                this.lblConsumptionUnit.textContent = splitTexts.succTxt;
            }

            splitTexts = lxUnitConverter.convertSplitAndApply(inputFormat, this.external);
            this.lblExternalValue.textContent = splitTexts.valueTxt;
            this.lblExternalUnit.textContent = splitTexts.succTxt;
            this.lblExternalDescr.node.textContent = this.isExporting ? _("controls.fronius.export") : _("controls.fronius.import");
        }

        /**
         * Responds to the button taps from the different buttons & calls registered delegate methods
         * if there are any.
         * @param src   what button was tapped
         * @private
         */
        _handleButtonTap(src) {
            switch (src) {
                case this.buttons.grid:
                    this.onGridTapped && this.onGridTapped();
                    break;

                case this.buttons.cons:
                    this.onConsumptionTapped && this.onConsumptionTapped();
                    break;

                case this.buttons.prod:
                    this.onProductionTapped && this.onProductionTapped();
                    break;
            }
        }

    };
});
