'use strict';

/**
 * This screen can allow you to ask the user an important question eg. enable a setting that he can accept or decline
 * You can set an icon, title, subtitle, accept- decline button and a decline description (tells the user how to find the setting he declined"
 * It wil always stay on top (static screen)
 *
 * The screen can be described via the overwritten getWelcomeScreenConfig() method
 */

class WelcomeScreenBase extends GUI.StaticScreenBase {
    //region Static
    static Template = function template() {
        var getTemplate = function getTemplate() {
            return $('' + '<div class="welcome-screen-base__container">' + '   <div class="container__accept-button container-button">' + '       <div class="container-button__title"></div>' + '   </div>' + '   <div class="container__decline-button container-button">' + '       <div class="container-button__title"></div>' + '   </div>' + '   <div class="container__decline-disc"></div> ' + '</div>');
        };

        return {
            getTemplate: getTemplate
        };
    }(); //endregion Static

    constructor(config) {
        super($('<div></div>'));
    }

    viewDidLoad() {
        this.element.append(WelcomeScreenBase.Template.getTemplate());
        return super.viewDidLoad(...arguments).then(() => {
            return this.setUpComfortUI(true).then(() => {
                this.welcomeScreenConfig = this.getWelcomeScreenConfig();
                this._isConfigSet("title") && this.setTitle(this.welcomeScreenConfig.title);
                this._isConfigSet("subtitle") && this.setSubtitle(this.welcomeScreenConfig.subtitle);
                this._isConfigSet("icon") && this.setIcon(this.welcomeScreenConfig.icon);
                this.elements = {
                    acceptButton: this.element.find(".container__accept-button"),
                    declineButton: this.element.find(".container__decline-button"),
                    declineDisc: this.element.find(".container__decline-disc")
                };
                this.buttons = {
                    acceptButton: new GUI.LxButton(this, this.elements.acceptButton[0]),
                    declineButton: new GUI.LxButton(this, this.elements.declineButton[0])
                };
                this.addToHandledSubviews(this.buttons.acceptButton);
                this.addToHandledSubviews(this.buttons.declineButton);

                if (this._isConfigSet("acceptButton")) {
                    this.elements.acceptButton.find('.container-button__title').text(this.welcomeScreenConfig.acceptButton);
                    this.buttons.acceptButton.onButtonTapped = this._acceptAction.bind(this);
                } else {
                    this.elements.acceptButton.hide();
                }

                if (this._isConfigSet("declineButton")) {
                    this.elements.declineButton.find('.container-button__title').text(this.welcomeScreenConfig.declineButton);
                    this.buttons.declineButton.onButtonTapped = this._declineAction.bind(this);
                } else {
                    this.elements.declineButton.hide();
                }

                if (this._isConfigSet("declineDisc")) {
                    this.elements.declineDisc.text(this.welcomeScreenConfig.declineDisc);
                } else {
                    this.elements.declineDisc.hide();
                }
            });
        });
    }

    getAnimation() {
        return AnimationType.FADE;
    }

    getLeftIcon() {
        return null; // return null to prevent us from showing a back button
    }

    /**
     * Set any viewed flags here!
     */
    remove() {
        return super.remove(...arguments);
    }

    /**
     * Returns the config object that describes this screen, possible properties are
     * title -> title of the screen
     * subtitle -> subtitle fo the screen
     * icon -> icon src of the screens icon
     * acceptButton -> text of the accept button
     * declineButton -> text of the decline button
     * declineDisc -> description that helps the user enable the declined option in the future. Like a description where he can find the option
     * @returns {{}}
     */
    getWelcomeScreenConfig() {
        console.info("Please implement getWelcomeScreenConfig in your subclass!");
        return {};
    }

    _isConfigSet(prop) {
        return this.welcomeScreenConfig && this.welcomeScreenConfig.hasOwnProperty(prop);
    }

    /**
     * This function needs to be overwritten by the subclass
     * It handles any accept actions
     * @private
     */
    _acceptAction() {
        console.info("No accept action is implemented in your subclass, please implement _acceptAction");
    }

    /**
     * This function needs to be overwritten by the subclass
     * It handles any decline actions
     * @private
     */
    _declineAction() {
        console.info("No decline action is implemented in your subclass, please implement _declineAction");
    }

}

GUI.WelcomeScreenBase = WelcomeScreenBase;
