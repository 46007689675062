'use strict';

define(['DaytimerControlContentCalendar', 'IRCDaytimerCalendarEntries'], function (DaytimerControlContentCalendar, IRCDaytimerCalendarEntries) {
    return class IRCDaytimerControlContentCalendar extends DaytimerControlContentCalendar {
        constructor(details) {
            super(details);
        }

        /**
         * Can be overwritten by subclasses (IRC, Pool) to return their own entries subclass.
         * @return {*}
         */
        getCalendarEntries() {
            return new IRCDaytimerCalendarEntries(this.control);
        }

    };
});
