'use strict';

define("LightControlContentOld", ['LightControlManualList', 'LightControlSceneList', 'LightControlEditSceneScreen', 'LightControlSaveSceneScreen', 'ColorPickerColorSelection'], function (LightControlManualList, LightControlSceneList, LightControlEditSceneScreen, LightControlSaveSceneScreen, ColorPickerColorSelection) {
    return class LightControlContentOld extends Controls.ControlContent {

        get reactHeaderShown() {
            return false
        }

        constructor(details) {
            super(details);
            this.isLightControl = this.control.type === 'LightController';
        }

        viewDidLoad() {
            var pmrsArr = [true];
            return Q(super.viewDidLoad(...arguments)).then(function () {
                this.sceneList = this.getSceneList();
                this.manualList = this.getManualList();
                this.manualList.sendCommand = this.sendCommand;
                var manualListElem = $('<div class="manual-list-page-placeholder"></div>');
                pmrsArr.push(this.appendSubview(this.manualList, manualListElem));
                this.sceneList.sendCommand = this.sendCommand;
                var sceneListElem = $('<div class="scene-list-page-placeholder"></div>');
                pmrsArr.push(this.appendSubview(this.sceneList, sceneListElem));
                this.pageView = new GUI.LxPageView([manualListElem, sceneListElem], null, this._doneAnimating.bind(this), {
                    hammerDisabled: true,
                    overscroll: true
                });
                this.pageView.disableUserInteraction(true);
                pmrsArr.push(this.appendSubview(this.pageView));
                this.pageView.setActivePage(0);
                return Q.all(pmrsArr);
            }.bind(this));
        }

        titleBarText() {
            return this.control.getName();
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK,
                subTitleBar: 'segmented',
                segments: [{
                    type: 'label',
                    text: this.isLightControl ? _("controls.lightcontroller.manual") : _("controls.lightcontroller.color-selector")
                }, {
                    type: 'label',
                    text: _("controls.lightcontroller.scenes")
                }]
            };
        }

        viewDidAppear() {
            super.viewDidAppear();

            this._titleBar.onSegmentSelected = function (number) {
                this.pageView.setActivePage(number);
            }.bind(this);
        }

        viewDidDisappear() {
            this._titleBar.onSegmentSelected = null;
            super.viewDidDisappear(...arguments);
        }

        receivedStates(states) {
            this.sceneList.update(states);
            this.manualList.update(states); // presence simulation

            if (this.control.presence) {
                this._titleBar.togglePresenceSimulation(states.presenceSimulation.active);
            }
        }

        getManualList() {
            return new LightControlManualList(this.control);
        }

        getSceneList() {
            return new LightControlSceneList(this.control);
        }

        // Event listener
        _doneAnimating(pageIndex) {
            this._titleBar.setSegmentActive(pageIndex);
        }

    };
});
