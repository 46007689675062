'use strict';
/*
 title
 */

window.GUI = function (GUI) {
    class RoundComfortActionCell extends GUI.TableViewV2.Cells.ComfortActionCell {
        constructor() {
            super(...arguments);
            this.element.addClass("round-action-cell");
        }

    }

    GUI.TableViewV2.Cells.RoundComfortActionCell = RoundComfortActionCell;
    GUI.TableViewV2.CellType.ROUND_COMFORT_ACTION = "RoundComfortActionCell";
    return GUI;
}(window.GUI || {});
