'use strict';

define(["ControlActionCellsScreen", "LightControlEnums"], function (ControlActionCellsScreen, LightControlEnums) {
    return class LightControlContent extends ControlActionCellsScreen {
        constructor() {
            super(...arguments);
        }

        receivedStates(states) {
            super.receivedStates(...arguments); // We only have a color if the LightControl is on
            // Set white as the fill color to have a better contrast

            if (!states.stateColorForContent) {
                this.setStateIconFill(Color.WHITE);
            }
        }

        shouldShowDetailsButton() {
            return !this.control.isGrouped() && !this.control.hasSingleSwitch() && !this.control.getStates().universalIsLocked;
        }

        handleDetailsBtn() {
            this.ViewController.showState(LightControlEnums.ScreenState.CONTROL_CONTENT_OLD);
        }

    };
});
