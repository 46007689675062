'use strict';

define(['AudioZoneControlEnums', 'AudioZoneDetailedContentBase', "IntercomControlEnums"], function (AudioZoneControlEnums, AudioZoneDetailedContentBase, IntercomControlEnums) {
    class VoiceRecorder extends AudioZoneDetailedContentBase {
        //region Static
        static requiredPlatforms() {
            return [PlatformType.IOS, PlatformType.Android, PlatformType.DeveloperInterface];
        }

        static requiredLMSFeatures() {
            return ["VOICE_RECORDING"];
        }

        static requiresInternalConnection = true;
        static Template = function template() {
            var getButtonElm = function getButtonElm(buttonTitle) {
                return $('<div class="submit-container">' + '   <button type="submit" class="submit-container__button">' + buttonTitle + '</button>' + '</div>');
            };

            return {
                getButtonElm: getButtonElm
            };
        }(); //endregion Static

        constructor(details) {
            super(...arguments);
            this.details = details;
            this.allZones = MediaServerComp.getAvailableZones(this.control.details.server);
            this.selectedZones = [MediaServerComp.getActiveZoneControl().details.playerid];
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments) || true).then(this._createSubmitButton.bind(this));
        }

        viewWillDisappear() {
            if (this.popupPromise) {
                NavigationComp.removePopup(this.popupPromise);
            }

            super.viewWillDisappear(...arguments);
        }

        titleBarText() {
            return _('media.menu.announcement');
        }

        reloadTable() {
            this.tableContent = [{
                headerTitle: "",
                headerDescription: _('media.announcement.headerTitle')
            }];

            this._createSelectableZoneCells();

            return super.reloadTable(...arguments);
        }

        getURL() {
            return "VoiceRecorder";
        }

        _createSelectableZoneCells() {
            this.tableContent.push({
                rows: Object.values(this.allZones).map(function (zone) {
                    return {
                        type: GUI.TableViewV2.CellType.CHECKABLE,
                        content: {
                            title: zone.name,
                            radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE,
                            selected: this.selectedZones.indexOf(zone.playerid) !== -1
                        },
                        didCheckCell: this._onCellSelected.bind(this, zone)
                    };
                }.bind(this))
            });
        }

        _createSubmitButton() {
            if (!this.submitButton) {
                this.submitButton = new GUI.LxButton(this, VoiceRecorder.Template.getButtonElm(_('media.announcement.start-recording')), Color.BUTTON_GLOW, null, true);
                this.appendSubview(this.submitButton);
                this.submitButton.onButtonTapped = this._onButtonTapped.bind(this);
            }
        }

        _onCellSelected(zone) {
            var index = this.selectedZones.indexOf(zone.playerid);

            if (index === -1) {
                this.selectedZones.push(zone.playerid);
            } else {
                this.selectedZones.splice(index, 1);
            }

            this.reloadTable();
        }

        _onButtonTapped() {
            if (this.selectedZones.length) {
                this._startRecording();
            } else {
                this._showErrorPopup();
            }
        }

        _showErrorPopup() {
            return NavigationComp.showPopup({
                title: _('media.announcement.no-zones-selected.title'),
                message: _('media.announcement.no-zones-selected.message'),
                buttonOk: true,
                buttonCancel: false,
                icon: Icon.WARNING,
                color: window.Styles.colors.orange
            }, PopupType.GENERAL);
        }

        _startRecording() {
            this.popupPromise = NavigationComp.showPopup({
                title: _('media.menu.announcement'),
                subTitle: _('media.announcement.selected-zones', {
                    count: this.selectedZones.length
                }),
                messageColor: window.Styles.colors.red,
                buttons: [{
                    title: _('media.announcement.send-recording'),
                    color: Color.STATE_INACTIVE
                }],
                buttonCancel: true,
                icon: Icon.AudioZone.MIC_COVER_ICON,
                color: Color.STATE_INACTIVE
            }, PopupType.AUDIO_RECORD_POPUP);
            this.startScreenSaverActivityTick();
            this.popupPromise.then(function (audioResult) {
                return MediaServerComp.uploadAudioFile(audioResult).then(function () {
                    MediaServerComp.playRecordedFile(audioResult, this.selectedZones);
                    MediaServerComp.deleteFile(audioResult);
                }.bind(this));
            }.bind(this)).finally(function () {
                this.stopScreenSaverActivityTick();
            }.bind(this));
        }

        startScreenSaverActivityTick() {
            if (!this._screenSaverActivityTick) {
                this._screenSaverActivityTick = setInterval(function () {
                    SandboxComponent.activityTick()
                }, IntercomControlEnums.Timeouts.SCREENSAVER_TICK);
                SandboxComponent.activityTick()
            }
        }

        stopScreenSaverActivityTick() {
            this._screenSaverActivityTick && clearInterval(this._screenSaverActivityTick);
            this._screenSaverActivityTick = null;
        }

    }

    Controls.AudioZoneControl.VoiceRecorder = VoiceRecorder;
    return Controls.AudioZoneControl.VoiceRecorder;
});
