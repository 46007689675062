import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

function AcTimer(props) {
    return (
        <Svg
            width={14}
            height={14}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 14A7 7 0 107 0a7 7 0 000 14zm.25-10.25a.75.75 0 00-1.5 0V7.5c0 .414.336.75.75.75h3.75a.75.75 0 000-1.5h-3v-3z"
                fill="#000"
            />
        </Svg>
    );
}

export default AcTimer;
