'use strict';

define(['AudioZoneV2ControlEnums'], function (AudioZoneV2ControlEnums) {
    Controls.AudioZoneV2Control.SingleTones = Controls.AudioZoneV2Control.SingleTones || {};
    /**
     * Singletone for Spotify account management
     * This Singletone can also be required!
     */

    if (Controls.AudioZoneV2Control.SingleTones.SpotifyAccountManager) {
        return Controls.AudioZoneV2Control.SingleTones.SpotifyAccountManager;
    } else {
        class SpotifyAccountManager {
            //region Static
            static shared(control) {
                if (!control) {
                    console.warn("No control provided, try to deduce one!");

                    if (window.hasOwnProperty("AudioserverComp") && AudioserverComp.getActiveZoneControl()) {
                        control = AudioserverComp.getActiveZoneControl();
                    } else {
                        throw "No control available!";
                    }
                }

                this.__instances = this.__instances || {};

                if (!this.__instances.hasOwnProperty(control.uuidAction)) {
                    this.__instances[control.uuidAction] = new this(this, control);

                    this.__instances[control.uuidAction].prepare();
                }

                return this.__instances[control.uuidAction];
            }

            static destroy(control) {
                if (control && this.__instances && this.__instances.hasOwnProperty(control.uuidAction)) {
                    this.__instances[control.uuidAction].destroy();

                    delete this.__instances;
                }
            }

            static getUserUnique(user) {
                try {
                    return user.id;
                } catch (e) {
                    return null;
                }
            } //endregion Static


            //region Getter

            /**
             * Returns all available users
             * @returns {[]}
             */
            get availableUsers() {
                return this._availableUsers;
            }

            /**
             * Returns the current active user
             * @returns {null}
             */
            get activeUser() {
                return this._activeUser;
            }

            /**
             * Returns the name of the current active user, if available
             * @returns {null}
             */
            get activeUserName() {
                var user = this._activeUser;
                return user ? user.user : null;
            }

            get previousUser() {
                return this.__previousUser;
            }

            /**
             * Returns the current active user
             * @returns {null}
             */
            get activeUserId() {
                return this._activeUser ? this._activeUser.id : null;
            }

            get _activeUser() {
                return this.__activeUser;
            } //endregion Getter


            //region Setter
            set _activeUser(user) {
                Debug.Control.AudioZone.SpotifyAccount && console.log(this.name, "setActiveUser: '" + JSON.stringify(user) + "'");
                var prevActiveUser = cloneObject(this.__activeUser);
                this.__activeUser = user;

                try {
                    Debug.Control.AudioZone.SpotifyAccount && console.log(this.name, "   PreviousActiveUser: '" + JSON.stringify(prevActiveUser) + "'");
                    Debug.Control.AudioZone.SpotifyAccount && console.log(this.name, "        NewActiveUser: '" + JSON.stringify(user) + "'");
                } catch (e) {
                    console.error(e);
                }

                if (this._useFixedZoneAccounts()) {
                    if ((prevActiveUser || user) && JSON.stringify(prevActiveUser) !== JSON.stringify(user)) {
                        this.__previousUser = prevActiveUser;
                        // the assignment of the user on the AS happens beforehand, no need to get into this here.

                        this._control.audioserverComp.invalidateContentCachesOf(MusicServerEnum.MediaContentType.SERVICE, MusicServerEnum.ControlContentIdentifiers.SPOTIFY, MediaEnum.ReloadCause.USER_CHANGED);
                    }

                } else {
                    if (user && // ATTENTION: FastClass initializes every setter with "undefined"!
                        this._sharedSettingsObj[this._control.uuidAction] !== this.constructor.getUserUnique(user) // Prevent setting the same user over and over again!
                    ) {
                        this._sharedSettingsObj[this._control.uuidAction] = this.constructor.getUserUnique(user);
                    }

                    if ((prevActiveUser || user) && JSON.stringify(prevActiveUser) !== JSON.stringify(user)) {
                        this.__previousUser = prevActiveUser;
                        PersistenceComponent.setShared(MusicServerEnum.CUSTOMIZATION_KEYS.CURRENT_SPOTIFY_ACC_ID, this._sharedSettingsObj);

                        this._control.audioserverComp.invalidateContentCachesOf(MusicServerEnum.MediaContentType.SERVICE, MusicServerEnum.ControlContentIdentifiers.SPOTIFY, MediaEnum.ReloadCause.USER_CHANGED);
                    }
                }

            } //endregion Setter


            /**
             * Constructs the SpotifyAccountManager
             * @param initiator This value must only be set by the shared initializer
             */
            constructor(initiator, control) {
                if (!(initiator instanceof Function) && !Controls.AudioZoneV2Control.SingleTones.SpotifyAccountManager) {
                    throw "The Spotify Account store is a Singletone, use it like that! -> SpotifyAccountManager.shared(this.control)";
                }

                this._control = control;
                this.name = "SpotifyAccountManager@" + this._control.getName() + " #" + getRandomIntInclusive(0, 100);
                Debug.Control.AudioZone.SpotifyAccount && console.log(this.name, "+CTOR", getStackObj());
                this._sharedSettingsObj = {};
                this._availableUsers = [];
                this._activeUser = null;
                this._service = null;

                this.__boundServiceChangeFn = function (event, services, service, user, action) {
                    this._control.audioserverComp.getCurrentServices().promise.then(function (users) {
                        this._onServiceChange(users);

                        this._reNegotiateActiveUser();
                    }.bind(this));
                }.bind(this);

                this.__boundSharedUserSettingsChange = function (key, value) {
                    var currentActiveUserUnique = this.constructor.getUserUnique(this._activeUser);

                    this._onSharedUserSettingsChange(value); // Only invalidate the content if the users did actually change


                    if (currentActiveUserUnique !== this.constructor.getUserUnique(this._activeUser)) {
                        this._control.audioserverComp.invalidateContentCachesOf(MusicServerEnum.MediaContentType.SERVICE, MusicServerEnum.ControlContentIdentifiers.SPOTIFY, MediaEnum.ReloadCause.USER_CHANGED);
                    }
                }.bind(this);

                this._unregisterZoneChange = this._control.audioserverComp.on(this._control.audioserverComp.ECEvent.ZoneChanged, this.prepare.bind(this));
            }

            destroy() {
                Debug.Control.AudioZone.SpotifyAccount && console.log(this.name, "DESTROY", getStackObj()); // Unregister any listeners here!

                this._unregisterZoneChange();
            }

            prepare() {
                if (!this._prepareDef) {
                    Debug.Control.AudioZone.SpotifyAccount && console.log(this.name, "prepare");
                    this._prepareDef = Q.defer();

                    this._control.audioserverComp.getCurrentServices().promise.done(function (users) {
                        this._onServiceChange(users);

                        return PersistenceComponent.getShared(MusicServerEnum.CUSTOMIZATION_KEYS.CURRENT_SPOTIFY_ACC_ID).then(function (settings) {
                            this._sharedSettingsObj = settings || {};

                            this._reNegotiateActiveUser();

                            PersistenceComponent.registerForSharedKeyChange(MusicServerEnum.CUSTOMIZATION_KEYS.CURRENT_SPOTIFY_ACC_ID, this.__boundSharedUserSettingsChange, true);
                            this.__serviceChangeDeReg = this._control.audioserverComp.registerForServiceChanges(this.__boundServiceChangeFn);

                            this._prepareDef.resolve();
                        }.bind(this));
                    }.bind(this));
                } else {
                    Debug.Control.AudioZone.SpotifyAccount && console.log(this.name, "prepare > already preparing!", getStackObj());
                }

                return this._prepareDef.promise;
            }

            isPrepared() {
                return this._prepareDef && !this._prepareDef.promise.isPending();
            }

            showAccountSelector(ctxMenuHandler) {
                var contextMenuContent = this.availableUsers.map(function (user) {
                    return {
                        selected: this.constructor.getUserUnique(user) === this.constructor.getUserUnique(this._activeUser),
                        title: user.user,
                        action: function onUserSelected() {
                            this._changeActiveUser(user, true);
                        }.bind(this)
                    };
                }.bind(this));
                contextMenuContent.push({
                    title: _("audio-server.spotify.add-another"),
                    titleColor: window.Styles.colors.green,
                    action: this.showAddSpotifyScreen.bind(this)
                });
                return ctxMenuHandler._showContextMenu(contextMenuContent, _('media.spotify.account-selector.title'));
            }

            setUserWithIdActive(uid) {
                try {
                    var wantedUser = this._availableUsers.find(function (user) {
                        return this.constructor.getUserUnique(user) === uid;
                    }.bind(this));

                    if (wantedUser) {
                        this._changeActiveUser(wantedUser, true);
                    }
                } catch (e) {
                }
            }

            /**
             * Checks if the item is owned by either the user (object) provided, or if no user (object) is provided, it
             * will check based on the currently selected user.
             * @param item
             * @param [spotifyUser] the user object, if null or undefined, the currently selected user is used.
             * @returns {boolean}
             */
            isOwnerOfItem(item, spotifyUser) {
                var checkUser = spotifyUser || this.__activeUser;

                if (!checkUser) {
                    console.error("SpotifyAccountManager: isOwnerOfItem will default to false, no user provided, no active user knwon!");
                    return false;
                }

                var currUserId = checkUser.id,
                    currUserName = checkUser.user,
                    itemOwnerId = item.ownerId,
                    itemOwnerName = item.owner; // as of 2021.11.09 the AS provides an ownerId property aside the owner-Property containing the userfriendly username.
                // make use of the id whenever possible.

                if (currUserId && itemOwnerId) {
                    return itemOwnerId === currUserId;
                } else {
                    return currUserName === itemOwnerName;
                }
            }

            getAddSpotifyLink() {
                var prms;

                if (!this._service) {
                    prms = this._control.audioserverComp.getAvailableServices().promise.then(function (services) {
                        this._service = (services || []).find(function (service) {
                            return service.cmd === MusicServerEnum.Service.SPOTIFY;
                        }.bind(this));
                    }.bind(this));
                } else {
                    prms = Q(this._service);
                }

                return prms.then(function () {
                    return this._control.audioserverComp.sendMediaServerCommand(MusicServerEnum.Commands.SERVICES.CFG.GET_LINK + this._service.cmd).then(function (result) {
                        return result.data;
                    });
                }.bind(this));
            }

            showAddSpotifyScreen() {
                return this.getAddSpotifyLink().then(function (resData) {
                    NavigationComp.openWebsite(resData.link);
                });
            }

            showOnboardingForUser(user) {
                this._control.audioserverComp.AudioViewController.showState("NewSpotifyAccountScreen", null, {
                    user: user,
                    control: this._control
                });
            }

            _onServiceChange(users) {
                this._availableUsers = users.filter(function (user) {
                    return user.cmd === MusicServerEnum.Service.SPOTIFY;
                });
            }

            _onSharedUserSettingsChange(settings) {
                this._sharedSettingsObj = settings;

                this._reNegotiateActiveUser();
            }


            _useFixedZoneAccounts() {
                return this._control.audioserverComp.supportsAccountZoneAssignment();
            }

            _identifyAccountZoneAssignment() {
                if (!this._availableUsers) {
                    return undefined;
                }
                return this._availableUsers.find(userObj => {
                    return Array.isArray(userObj.asdefault) && userObj.asdefault.find(playerid => {
                        return playerid === this._control.details.playerid;
                    }) !== undefined;
                });
            }

            _updateAccountZoneAssignment(uniqueUserId) {
                this._control.audioserverComp.assignSpotifyAccountToZone(uniqueUserId, this._control.details.playerid);
            }

            _reNegotiateActiveUser() {
                Debug.Control.AudioZone.SpotifyAccount && console.log(this.name, "_reNegotiateActiveUser: ", cloneObject(this.__activeUser));
                Debug.Control.AudioZone.SpotifyAccount && console.log(this.name, "             available: ", cloneObject(this._availableUsers));
                Debug.Control.AudioZone.SpotifyAccount && console.log(this.name, "              assigned: ", this._identifyAccountZoneAssignment());
                let assignedOnAs = this._identifyAccountZoneAssignment();

                if (assignedOnAs) {
                    this._changeActiveUser(assignedOnAs, false);

                } else if (this._sharedSettingsObj && this._sharedSettingsObj.hasOwnProperty(this._control.uuidAction)) {
                    var activeUser = this._availableUsers.find(function (user) {
                        return this._sharedSettingsObj[this._control.uuidAction] === this.constructor.getUserUnique(user);
                    }.bind(this));

                    if (this.__activeUser && !activeUser) {
                        Debug.Control.AudioZone.SpotifyAccount && console.log(this.name, "  active user no longer exists!"); // the active user is no longer available!

                        if (this._availableUsers.length) {
                            Debug.Control.AudioZone.SpotifyAccount && console.log(this.name, "  retrieving fromm available users!");
                            this._changeActiveUser(this._availableUsers[0], false);
                        } else {
                            Debug.Control.AudioZone.SpotifyAccount && console.log(this.name, "  setting to null, no available users!");
                            this._changeActiveUser(null, false);
                        }
                    } else {
                        if (!this.__activeUser && activeUser) {
                            if (!this.isPrepared()) {
                                // set private initially to avoid reload calls from setter while not prepared!
                                Debug.Control.AudioZone.SpotifyAccount && console.log(this.name, "  initializing active user privately");
                                this.__activeUser = cloneObject(activeUser);
                            } else {
                                Debug.Control.AudioZone.SpotifyAccount && console.error(this.name, "  initialize active user publicly!");
                                this._changeActiveUser(activeUser, false);
                            }
                        }
                        this._changeActiveUser(this.__activeUser, false);
                    }
                } else {
                    Debug.Control.AudioZone.SpotifyAccount && console.log(this.name, "  NO sharedUserSettings");
                } // Assign the first user as the current active user when no active is set and at least one is available


                if (!this._activeUser) {
                    Debug.Control.AudioZone.SpotifyAccount && console.log(this.name, "    no active user from sharedUserSettings");

                    if (this._availableUsers.length) {
                        Debug.Control.AudioZone.SpotifyAccount && console.log(this.name, "    initialize with first one from available");
                        this._changeActiveUser(this._availableUsers[0], false);
                    } else {
                        Debug.Control.AudioZone.SpotifyAccount && console.log(this.name, "    also none available!");
                        this._changeActiveUser(null, false);
                    }
                }
            }

            /**
             * Actracted from setter to separate function to allow differentiation between "automatic" and "manual"
             * account switches. If this isn't done, the app would always send commands with account assignments to
             * the audioserver.
             * @param newUser   the new user object to be assigned onto a zone.
             * @param isManual  if true, it means the user was changed manually, hence should be sent to AS for syncing.
             * @private
             */
            _changeActiveUser(newUser, isManual = false) {
                if (this._useFixedZoneAccounts() && isManual && newUser) {
                    this._updateAccountZoneAssignment(newUser ? newUser.id : null);
                }
                this._activeUser = newUser;
            }

        }

        Controls.AudioZoneV2Control.SingleTones.SpotifyAccountManager = SpotifyAccountManager;
        return Controls.AudioZoneV2Control.SingleTones.SpotifyAccountManager;
    }
});
