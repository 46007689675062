'use strict';

define(["AudioZoneControlEnums"], function (AudioZoneControlEnums) {
    class AudioZoneControlSettingsLineInList extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div class="settings-input-list" />'));
            Object.assign(this, MediaStateHandler.Mixin);
            this.details = details;
            this.control = details.control;
            this.inputs = [];
        }

        viewWillAppear() {
            this._registerForMediaServerEvents(this.EVENT_TYPES.LINEIN);

            return super.viewWillAppear(...arguments);
        }

        viewWillDisappear() {
            this._unregisterForMediaServerEvents(this.EVENT_TYPES.LINEIN);

            super.viewWillDisappear(...arguments);
        }

        titleBarText() {
            return _("media.section.line-in");
        }

        receivedLineInAllDataEvent(res) {
            this.inputs = res.items;
            this.reloadTable();
        }

        reloadTable() {
            this.tableContent = [{
                rows: this.inputs.map(this._getLineInCell.bind(this))
            }];
            return super.reloadTable(...arguments);
        }

        // Private methods
        _getLineInCell(input) {
            return {
                type: GUI.TableViewV2.CellType.GENERAL,
                content: {
                    title: input.name,
                    subtitle: input.description,
                    leftIconSrc: MediaEnum.LineInMap[input.icontype],
                    leftIconColor: input.enabled ? window.Styles.colors.green : Color.TEXT_SECONDARY_B,
                    leftIconBgEnabled: true,
                    clickable: true,
                    class: "base-cell--with-chevron"
                },
                action: this._showInputSetting.bind(this, input)
            };
        }

        _showInputSetting(input) {
            this.ViewController.showState(AudioZoneControlEnums.ScreenState.SETTINGS_INPUT, null, {
                control: this.control,
                input: input
            });
        }

    }

    Controls.AudioZoneControl.AudioZoneControlSettingsLineInList = AudioZoneControlSettingsLineInList;
});
