



export default class ScreenBrightness {

    constructor() {
        this.name = "ScreenBrightness";
        this._brightnessListeners = {};
        const platform = PlatformComponent.getPlatformInfoObj().platform;
        this._isDevIF = platform === PlatformType.DeveloperInterface;
        this._canAdoptBrightness = platform === PlatformType.IOS
            || platform === PlatformType.Android;

        this._targetBrightness = -1;
        this._initialGet = this.get().then(res => {
            this._targetBrightness = res;
            this._notifyListeners(res);
        })
    }

    static instance() {
        if (!window.screenBrightnessHandle) {
            window.screenBrightnessHandle = new ScreenBrightness();
        }
        return window.screenBrightnessHandle;
    }

    static get() {
        return ScreenBrightness.instance().get();
    }

    static set(newVal) {
        return ScreenBrightness.instance().set(newVal);
    }

    static register(cb) {
        return ScreenBrightness.instance().register(cb);
    }

    register(cb) {
        let rndId;
        do {
            rndId = getRandomIntInclusive(0, 10000) + "";
        } while (this._brightnessListeners.hasOwnProperty(rndId));
        if (Object.values(this._brightnessListeners).length === 0) {
            this._startMonitoring();
        }
        this._brightnessListeners[rndId] = cb;

        return () => {
            delete this._brightnessListeners[rndId];
            if (Object.values(this._brightnessListeners).length === 0) {
                this._stopMonitoring();
            }
        }
    }

    _notifyListeners(brightness) {
        Object.values(this._brightnessListeners).forEach(cb => cb(brightness));
    }

    get() {
        let def = Q.defer();
        if (this._canAdoptBrightness) {
            screen.getBrightness((value) => {
                def.resolve(Math.round(parseFloat(value) * 100));
            }, (err) => {
                def.reject(err);
            });
        } else {
            def.resolve(this._targetBrightness < 0 ? 100 : this._targetBrightness);
        }

        return def.promise;
    }

    set(newValue) {
        let def = Q.defer();

        if (this._canAdoptBrightness) {
            screen.setBrightness(() => {
                def.resolve();
            }, (err) => {
                def.resolve();
            }, (newValue / 100));
        } else {
            def.resolve();
        }

        def.promise.then(() => {
            this._targetBrightness = newValue;
            this._notifyListeners(newValue);
        })

        return def.promise;
    }

    _startMonitoring() {
        if (this._recheckTimeout) {
            return;
        }

        this._recheckTimeout = setTimeout(() => {
            this._recheckTimeout = null;
            this.get().then(actualBrightness => {
               if (actualBrightness !== this._targetBrightness) {
                   this._notifyListeners(actualBrightness);
               }
               this._startMonitoring();
            })
        }, 5000);
    }

    _stopMonitoring() {
        this._recheckTimeout && clearTimeout(this._recheckTimeout);
        this._recheckTimeout = null;
    }
    
}