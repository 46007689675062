'use strict';

class SelectableTableView extends GUI.EditableTableView {
    /**
     * @see GUI.TableViewV2
     */
    constructor() {
        super(...arguments);
        this.element.addClass("selectable-table-view");
    }

    viewDidAppear() {
        var baseCall = super.viewDidAppear(...arguments);

        if (this._shouldUpdateScrollTop) {
            delete this._shouldUpdateScrollTop;

            this._updateScrollTop();
        }

        return baseCall;
    }

    selectCell(sectionIdx, rowIdx, selected) {
        var update = this.setSelectedCell(this.cellForSectionAndRow(sectionIdx, rowIdx, selected));

        if (update) {
            this._currentSelectedCell.setSelected(selected);

            this._shouldUpdateScrollTop = true; // set flag, can be that the view isn't visible right now

            this._updateScrollTop();
        }
    }

    setSelectedCell(cell) {
        if (!cell) {
            console.error(this.name + ":Cell to select not found!");
        } else if (this._currentSelectedCell && this._currentSelectedCell !== cell) {
            this._currentSelectedCell.setSelected(false);

            this._currentSelectedCell = cell;
            return true;
        } else if (!this._currentSelectedCell) {
            this._currentSelectedCell = cell;
            return true;
        }

        return false;
    }

    deselectAll() {
        if (this._currentSelectedCell) {
            this._currentSelectedCell.setSelected(false);

            delete this._currentSelectedCell;
        }
    }

    removeSubview(view) {
        if (view === this._currentSelectedCell) {
            delete this._currentSelectedCell;
        }

        return super.removeSubview(...arguments);
    }

    _updateScrollTop() {
        if (this._currentSelectedCell) {
            this.element.scrollTop(this._currentSelectedCell.getElement()[0].offsetTop);
        }
    }

}

GUI.SelectableTableView = SelectableTableView;
