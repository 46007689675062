"use strict";

define("PoolControlContentValvePositionScreen", ["PoolControlEnums"], function (PoolControlEnums) {
    return class PoolControlContentValvePositionScreen extends Controls.ControlContent {
        constructor(details) {
            super(details);
            this.details = details;
            this.previousValvePosition = PoolControlEnums.PoolValvePositions.VALVE_MOVES;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.tableView = new GUI.TableViewV2(this, this);
            this.appendSubview(this.tableView);
        }

        titleBarText() {
            return _("pool.control.valve-pos");
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK,
                style: "transparent"
            };
        }

        getURL() {
            return "ValvePosition";
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        receivedStates(states) {
            this._getTableViewContent(states);

            this.tableView.reload();
        }

        // TableViewDataSource Methods
        titleForHeaderInSection(section, tableView) {
            return this.tableViewContent[section].sectionTitle;
        }

        styleForTableView(tableView) {
            return TableViewStyle.GROUPED;
        }

        numberOfSections(tableView) {
            return this.tableViewContent.length;
        }

        numberOfRowsInSection(section, tableView) {
            return this.tableViewContent[section].cells.length;
        }

        cellTypeForCellAtIndex(section, row, tableView) {
            return this.tableViewContent[section].cells[row].cellType;
        }

        contentForCell(cell, section, row, tableView) {
            return this.tableViewContent[section].cells[row];
        }

        // TableViewDelegate methods
        didCheckCell(cell, section, row, tableView, selected) {
            this.newValvePosition = cell.content.valvePosID;
            this.sendCommand(Commands.format(Commands.POOL.SET_VALVE_POSITION, cell.content.valvePosID));
        }

        // Private stuff
        _getTableViewContent(states) {
            var valveMoves = states.valvePosition === PoolControlEnums.PoolValvePositions.VALVE_MOVES;
            var realValvePosition = valveMoves ? this.newValvePosition : states.valvePosition;
            this.tableViewContent = [{
                sectionTitle: "",
                cells: []
            }];

            for (var key in PoolControlEnums.PoolValvePositions) {
                if (PoolControlEnums.PoolValvePositions.hasOwnProperty(key)) {
                    var index = PoolControlEnums.PoolValvePositions[key];

                    if (index >= 0 && index < 6) {
                        this.tableViewContent[0].cells.push({
                            cellType: GUI.TableViewV2.CellType.CHECKABLE,
                            title: this.control.getNameOfPoolValvePositions(index),
                            selected: realValvePosition == index,
                            radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE,
                            valvePosID: index
                        });
                    }
                }
            }
        }

    };
});
