'use strict';

define(['AudioZoneV2ControlEnums'], function (AudioZoneV2ControlEnums) {
    Controls.AudioZoneV2Control.SingleTones = Controls.AudioZoneV2Control.SingleTones || {};
    /**
     * Singletone for Soundsuit account management
     * This Singletone can also be required!
     */

    if (Controls.AudioZoneV2Control.SingleTones.SoundsuitAccountManager) {
        return Controls.AudioZoneV2Control.SingleTones.SoundsuitAccountManager;
    } else {
        class SoundsuitAccountManager {
            //region Static
            static shared(control) {
                if (!control) {
                    console.warn("No control provided, try to deduce one!");

                    if (window.hasOwnProperty("AudioserverComp") && AudioserverComp.getActiveZoneControl()) {
                        control = AudioserverComp.getActiveZoneControl();
                    } else {
                        throw "No control available!";
                    }
                }

                this.__instances = this.__instances || {};

                if (!this.__instances.hasOwnProperty(control.uuidAction)) {
                    this.__instances[control.uuidAction] = new this(this, control);

                    this.__instances[control.uuidAction].prepare();
                }

                return this.__instances[control.uuidAction];
            }

            static destroy(control) {
                if (control && this.__instances && this.__instances.hasOwnProperty(control.uuidAction)) {
                    this.__instances[control.uuidAction].destroy();

                    delete this.__instances;
                }
            }

            static getUserUnique(user) {
                try {
                    return user.id;
                } catch (e) {
                    return null;
                }
            } //endregion Static


            //region Getter

            /**
             * Returns all available users
             * @returns {[]}
             */
            get availableUsers() {
                return this._availableUsers;
            }

            /**
             * Returns the current active user
             * @returns {null}
             */
            get activeUser() {
                return this._activeUser;
            }

            /**
             * Returns the name of the current active user, if available
             * @returns {null}
             */
            get activeUserName() {
                var user = this._activeUser;
                return user ? (user.name + SEPARATOR_SYMBOL + user.user) : null;
            }

            /**
             * Returns the name of the current active user, if available
             * @returns {null}
             */
            get activeZoneName() {
                var user = this._activeUser;
                return user ? user.name : null;
            }

            get previousUser() {
                return this.__previousUser;
            }

            /**
             * Returns the current active user
             * @returns {null}
             */
            get activeUserId() {
                return this._activeUser ? this._activeUser.id : null;
            }

            get _activeUser() {
                return this.__activeUser;
            } //endregion Getter


            //region Setter
            set _activeUser(user) {
                Debug.Control.AudioZone.SoundsuitAccount && console.log(this.name, "setActiveUser: '" + JSON.stringify(user) + "'");
                var prevActiveUser = cloneObject(this.__activeUser);
                this.__activeUser = user;

                try {
                    Debug.Control.AudioZone.SoundsuitAccount && console.log(this.name, "   PreviousActiveUser: '" + JSON.stringify(prevActiveUser) + "'");
                    Debug.Control.AudioZone.SoundsuitAccount && console.log(this.name, "        NewActiveUser: '" + JSON.stringify(user) + "'");
                } catch (e) {
                    console.error(e);
                }

                if ((prevActiveUser || user) && JSON.stringify(prevActiveUser) !== JSON.stringify(user)) {
                    this.__previousUser = prevActiveUser;

                    this._control.audioserverComp.invalidateContentCachesOf(MusicServerEnum.MediaContentType.SOUNDSUIT, MusicServerEnum.Soundsuit.TYPES.MOODS, MediaEnum.ReloadCause.USER_CHANGED);
                    this._control.audioserverComp.invalidateContentCachesOf(MusicServerEnum.MediaContentType.SOUNDSUIT, MusicServerEnum.Soundsuit.TYPES.SCHEDULES, MediaEnum.ReloadCause.USER_CHANGED);
                }
            } //endregion Setter


            /**
             * Constructs the SoundsuitAccountManager
             * @param initiator This value must only be set by the shared initializer
             */
            constructor(initiator, control) {
                if (!(initiator instanceof Function) && !Controls.AudioZoneV2Control.SingleTones.SoundsuitAccountManager) {
                    throw "The Soundsuit Account store is a Singletone, use it like that! -> SoundsuitAccountManager.shared(this.control)";
                }

                this._control = control;
                this.name = "SoundsuitAccountManager@" + this._control.getName() + " #" + getRandomIntInclusive(0, 100);
                Debug.Control.AudioZone.SoundsuitAccount && console.log(this.name, "+CTOR", getStackObj());
                this._availableUsers = [];
                this._activeUser = null;
                this._service = null;

                this.__boundServiceChangeFn = function (event, services, service, user, action) {
                    this._control.audioserverComp.getCurrentServices().promise.then(function (users) {
                        this._onServiceChange(users);

                        this._reNegotiateActiveUser();
                    }.bind(this));
                }.bind(this);

                this._unregisterZoneChange = this._control.audioserverComp.on(this._control.audioserverComp.ECEvent.ZoneChanged, this.prepare.bind(this));
            }

            destroy() {
                Debug.Control.AudioZone.SoundsuitAccount && console.log(this.name, "DESTROY", getStackObj()); // Unregister any listeners here!

                this.__serviceChangeDeReg && this.__serviceChangeDeReg();
                this._unregisterZoneChange();
            }

            prepare() {
                if (!this._prepareDef) {
                    Debug.Control.AudioZone.SoundsuitAccount && console.log(this.name, "prepare");
                    this._prepareDef = Q.defer();

                    this._control.audioserverComp.getCurrentServices().promise.done(function (users) {
                        this._onServiceChange(users);
                        this._reNegotiateActiveUser();

                        this.__serviceChangeDeReg && this.__serviceChangeDeReg();
                        this.__serviceChangeDeReg = this._control.audioserverComp.registerForServiceChanges(this.__boundServiceChangeFn);
                        this._prepareDef.resolve();
                    }.bind(this));
                } else {
                    Debug.Control.AudioZone.SoundsuitAccount && console.log(this.name, "prepare > already preparing!", getStackObj());
                }

                return this._prepareDef.promise;
            }

            isPrepared() {
                return this._prepareDef && !this._prepareDef.promise.isPending();
            }

            showAccountSelector(ctxMenuHandler) {
                var contextMenuContent = this.availableUsers.map(function (user) {
                    return {
                        selected: this.constructor.getUserUnique(user) === this.constructor.getUserUnique(this._activeUser),
                        title: user.name + SEPARATOR_SYMBOL + user.user, //TODO-woessto: maybe support a subtitle?
                        action: function onUserSelected() {
                            this._changeActiveUser(user, true);
                        }.bind(this)
                    };
                }.bind(this));
                contextMenuContent.push({
                    title: _("audio-server.spotify.add-another"), // says "add another account" - so not spotify related --> no separate text needed.
                    titleColor: window.Styles.colors.green,
                    action: this.showAddSoundsuitScreen.bind(this)
                });
                return ctxMenuHandler._showContextMenu(contextMenuContent, _("audio-server.soundsuit.switch-account"));
            }

            setUserWithIdActive(uid) {
                try {
                    var wantedUser = this._availableUsers.find(function (user) {
                        return this.constructor.getUserUnique(user) === uid;
                    }.bind(this));

                    if (wantedUser) {
                        this._changeActiveUser(wantedUser, true);
                    }
                } catch (e) {
                }
            }

            /**
             * Checks if the item is owned by either the user (object) provided, or if no user (object) is provided, it
             * will check based on the currently selected user.
             * @param item
             * @param [soundsuitUser] the user object, if null or undefined, the currently selected user is used.
             * @returns {boolean}
             */
            isOwnerOfItem(item, soundsuitUser) {
                var checkUser = soundsuitUser || this.__activeUser;

                if (!checkUser) {
                    console.error("SoundsuitAccountManager: isOwnerOfItem will default to false, no user provided, no active user knwon!");
                    return false;
                }

                var currUserId = checkUser.id,
                    currUserName = checkUser.user,
                    itemOwnerId = item.ownerId,
                    itemOwnerName = item.owner; // as of 2021.11.09 the AS provides an ownerId property aside the owner-Property containing the userfriendly username.
                // make use of the id whenever possible.

                if (currUserId && itemOwnerId) {
                    return itemOwnerId === currUserId;
                } else {
                    return currUserName === itemOwnerName;
                }
            }

            getAddSoundsuitLink() {
                var prms;

                if (!this._service) {
                    prms = this._control.audioserverComp.getAvailableServices().promise.then(function (services) {
                        this._service = (services || []).find(function (service) {
                            return service.cmd === MusicServerEnum.Service.SOUNDSUIT;
                        }.bind(this));
                    }.bind(this));
                } else {
                    prms = Q(this._service);
                }

                return prms.then(function () {
                    return this._control.audioserverComp.sendMediaServerCommand(
                        MusicServerEnum.Commands.SERVICES.CFG.GET_LINK + this._service.cmd
                    ).then(function (result) {
                        return result.data;
                    });
                }.bind(this));
            }

            /**
             * Either uses the service provided, or looks up the service-obj from the component.
             * @param service
             */
            showAddSoundsuitScreen(service) {
                return this.getAddSoundsuitLink().then(res => {
                    NavigationComp.openWebsite(res.link);
                });


                let servicePrms;
                if (service) {
                    servicePrms = Q.resolve(service);
                } else {
                    servicePrms = this._control.audioserverComp.getAvailableServices().promise.then(function (availableServices) {
                        return availableServices.find(function (service) {
                            return service.cmd === MusicServerEnum.Service.SOUNDSUIT;
                        });
                    }.bind(this));
                }

                servicePrms.then(baseService => {
                    this._control.audioserverComp.AudioViewController.showState(AudioZoneV2ControlEnums.ScreenState.SETTINGS_SERVICE_LOGIN, null, {
                        service: baseService,
                        control: this._control
                    });
                });
            }

            showOnboardingForUser(user) {
                this._control.audioserverComp.AudioViewController.showState("NewSoundsuitAccountScreen", null, {
                    user: user,
                    control: this._control
                });
            }

            _onServiceChange(users) {
                this._availableUsers = users.filter(function (user) {
                    return user.cmd === MusicServerEnum.Service.SOUNDSUIT;
                });
            }

            _onSharedUserSettingsChange(settings) {
                this._reNegotiateActiveUser();
            }


            _useFixedZoneAccounts() {
                return true; // soundsuit always has fixed zone accounts!
            }

            _identifyAccountZoneAssignment() {
                if (!this._availableUsers) {
                    return undefined;
                }
                return this._availableUsers.find(userObj => {
                    return Array.isArray(userObj.asdefault) && userObj.asdefault.find(playerid => {
                        return playerid === this._control.details.playerid;
                    }) !== undefined;
                });
            }

            _updateAccountZoneAssignment(uniqueUserId) {
                this._control.audioserverComp.assignSoundsuitAccountToZone(uniqueUserId, this._control.details.playerid);
            }

            _reNegotiateActiveUser() {
                Debug.Control.AudioZone.SoundsuitAccount && console.log(this.name, "_reNegotiateActiveUser: ", cloneObject(this.__activeUser));
                Debug.Control.AudioZone.SoundsuitAccount && console.log(this.name, "             available: ", cloneObject(this._availableUsers));
                Debug.Control.AudioZone.SoundsuitAccount && console.log(this.name, "              assigned: ", this._identifyAccountZoneAssignment());
                this._changeActiveUser(this._identifyAccountZoneAssignment(), false);

                if (!this._activeUser) {
                    Debug.Control.AudioZone.SoundsuitAccount && console.log(this.name, "    no active user from sharedUserSettings");

                    if (this._availableUsers.length) {
                        Debug.Control.AudioZone.SoundsuitAccount && console.log(this.name, "    initialize with first one from available");
                        this._changeActiveUser(this._availableUsers[0], false);
                    } else {
                        Debug.Control.AudioZone.SoundsuitAccount && console.log(this.name, "    also none available!");
                        this._changeActiveUser(null, false);
                    }
                }
            }

            _changeActiveUser(newUser, manual = false) {
                if (manual && newUser) {
                    this._updateAccountZoneAssignment(newUser ? newUser.id : null);
                }
                this._activeUser = newUser;
            }

        }

        Controls.AudioZoneV2Control.SingleTones.SoundsuitAccountManager = SoundsuitAccountManager;
        return Controls.AudioZoneV2Control.SingleTones.SoundsuitAccountManager;
    }
});
