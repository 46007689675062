import PropTypes from "prop-types";
import React from 'react';
import LxReactBadge, {LxReactBadgeType} from "./LxReactBadge/LxReactBadge";
import globalStyles from "GlobalStyles";
import useSystemStatusInfo from "./customHooks/systemStatusInfoHook";
import {View} from "react-native-web";

/**
 * @param {Array} props.sourceUuids The uuid that should be used to determine if system status messages are to be counted
 * @param {String} props.badgeType Round or Square - defaults to square
 * @returns {JSX.Element} A text with a background color, padding and a borderRadius
 */
function LxReactSystemStatusBadge(props) {
    const sysStatusInfo = useSystemStatusInfo(props.sourceUuids);

    if (sysStatusInfo.count === 0) {
        return <View/>;
    } else {
        return (<LxReactBadge
            text={"" + sysStatusInfo.count}
            backgroundColor={sysStatusInfo.color || globalStyles.colors.grey["300a36"]}
            textColor={globalStyles.colors.white}
            badgeType={props.badgeType || LxReactBadgeType.SQUARE}
        />);
    }
}


export default LxReactSystemStatusBadge;

LxReactSystemStatusBadge.propTypes = {
    sourceUuids: PropTypes.arrayOf(PropTypes.string).isRequired,
    badgeType: LxReactBadge.propTypes.badgeType
}
