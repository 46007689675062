'use strict';

window.GUI = function (GUI) {
    class LightTableView extends GUI.TableViewV2 {
        /**
         * ctor of LightTableView
         * @constructor
         */
        constructor() {
            super(...arguments);
            this.getElement().addClass("light-table-view");
        }

    }

    GUI.LightTableView = LightTableView;
    return GUI;
}(window.GUI || {});
