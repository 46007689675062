'use strict';

define(["IRoomControllerV2021ControlEnums", "IrcTempSliders"], function (CtrlEnums, IrcTempSliders) {
    return class IrcTempSettingsScreen extends GUI.Screen {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(thermostatSrc) {
                var content = '' + '<div class="irc-temp-settings-screen__content">' + '<div class="content__left">' + '</div>' + '<div class="content__center">' + ImageBox.getResourceImageWithClasses(thermostatSrc, "center__thermostat") + '</div>' + '<div class="content__right">' + '</div>' + '</div>';
                return content;
            };

            var getLeftItem = function getLeftItem(item) {
                var button = '',
                    content = '',
                    description = '',
                    icon = '',
                    icon2 = '',
                    separator,
                    itemClasses = [],
                    buttonIcon,
                    spacing = '<div class="item__spacing"></div>';

                if (item.leftText) {
                    description = '<div class="item__description">' + item.leftText + '</div>';
                }

                if (item.iconSrc) {
                    icon = '<div class="value-cntr__icon-cntr value-cntr__icon-cntr-1">' + ImageBox.getResourceImageWithClasses(item.iconSrc, "icon-cntr__icon icon-cntr__icon-1") + '</div>';
                }

                if (item.icon2Src) {
                    icon2 = '<div class="value-cntr__icon-cntr value-cntr__icon-cntr-2">' + ImageBox.getResourceImageWithClasses(item.icon2Src, "icon-cntr__icon icon-cntr__icon-2") + '</div>';
                }

                if (item.editFn && !item.setByLogic) {
                    button = '<div class="value-cntr__button">' + ImageBox.getResourceImageWithClasses(Icon.ClimateUs.EDIT, "button__icon") + '</div>';
                }

                separator = '<div class="item__separator"><div class="separator__line"></div></div>'; //if (item.leftText) { // only used for floating

                content = '' + (item.isLower ? separator : '') + (item.isLower ? '' : description) + '<div class="item__value-cntr">' + icon + icon2 + '<div class="value-cntr__value"></div>' + button + '</div>' + (item.isLower ? description : '') + (item.isLower ? '' : separator); //}

                itemClasses.push("left__item");
                itemClasses.push("left__item--" + item.cssClass);
                item.isLower && itemClasses.push("left__item--lower");
                button !== "" && itemClasses.push("left__item--with-button");
                return '' + '<div class="' + itemClasses.join(" ") + '">' + (item.isLower ? '' : spacing) + content + (item.isLower ? spacing : '') + '</div>';
            };

            var getRightItem = function getRightItem(item) {
                return '<div class="right__item right__item--' + item.cssClass + '">' + '</div>';
            };

            var getShadingItem = function getShadingItem(shadingType) {
                return '' + '<div class="item__shading item__shading--' + shadingType + '">' + '<div class="shading__icon-cntr">' + ImageBox.getResourceImageWithClasses(Icon.Jalousie.JALOUSIE, "icon-cntr__icon") + '</div>' + '<div class="shading__value"></div>' + '</div>';
            };

            return {
                getTemplate: getTemplate,
                getLeftItem: getLeftItem,
                getRightItem: getRightItem,
                getShadingItem: getShadingItem
            };
        }(); //endregion Static

        constructor(details) {
            super($('<div/>'));
            Object.assign(this, StateHandler.Mixin, ContextMenuHandler.Mixin);
            this.control = details.control;
            this.absMinTemp = details.absMin || 0;
            this.absMaxTemp = details.absMax || 120;
            var states = this.control.getStates();
            this.isManual = states.isManual; // regardless of whether or not heating/cooling is currently allowed, use the capabilites according to inputs.

            this.canHeat = this.control.hasHeatingCapability();
            this.canCool = this.control.hasCoolingCapability();
            this.hasShades = states.hasShades;
        }

        viewDidLoad() {
            var promises = [super.viewDidLoad(...arguments)];
            this.items = this._getItems();
            this.element.append(IrcTempSettingsScreen.Template.getTemplate(this._getThermostatIcon()));
            this.element.toggleClass("irc-temp-settings-screen--manual", this.isManual);
            this.element.toggleClass("irc-temp-settings-screen--heating-only", this.canHeat && !this.canCool);
            this.element.toggleClass("irc-temp-settings-screen--cooling-only", this.canCool && !this.canHeat);
            this.elements = this.elements || {};
            this.elements.left = this.element.find(".content__left");
            this.elements.center = this.element.find(".content__center");
            this.elements.right = this.element.find(".content__right");
            this.items.forEach(this._prepareItemView.bind(this));

            if (this.hasShades && !this.isManual) {
                this._prepareShadingItems();
            }

            return Q.all(promises);
        }

        viewWillAppear() {
            var promise = super.viewWillAppear(...arguments);

            this._registerForStates();

            return promise;
        }

        viewWillDisappear() {
            this._unregisterStates();

            return super.viewWillDisappear(...arguments);
        }

        getAnimation() {
            return AnimationType.HD_OVERLAY;
        }

        destroy() {
            super.destroy(...arguments);
        }

        receivedStates(states) {
            this.states = states;
            var prevTemp = 1000;
            this.items.forEach(function (item) {
                this._updateItemTemp(item, states);
            }.bind(this));

            if (this.hasShades) {
                this._updateItemTempRanges();

                this._repositionShading(states);

                this._updateShadingTemp(states);
            }
        }

        titleBarText() {
            return _("controls.ircv2021.temperatures");
        }

        getURL() {
            return "ircv2021Temperatures";
        }

        _getThermostatIcon() {
            var iconSrc = null;

            if (this.isManual) {
                iconSrc = Icon.IRCV2021.THERMOMETER_MANUAL;
            } else if (!this.canHeat && this.canCool) {
                iconSrc = Icon.IRCV2021.THERMOMETER_COOLING;
            } else if (this.canHeat && !this.canCool) {
                iconSrc = Icon.IRCV2021.THERMOMETER_HEATING;
            } else {
                iconSrc = Icon.IRCV2021.THERMOMETER_LARGE;
            }

            return iconSrc;
        }

        _prepareItemView(item) {
            var button, jqElem;
            this.elements.left.append(IrcTempSettingsScreen.Template.getLeftItem(item));
            this.elements[item.elemName] = this.elements.left.find(".left__item--" + item.cssClass);
            this.elements[item.elemName + "Value"] = this.elements[item.elemName].find(".value-cntr__value");

            if (item.editFn) {
                button = new GUI.LxButton(this, this.elements[item.elemName]);
                this.addToHandledSubviews(button);

                if (item.setByLogic) {
                    this.elements[item.elemName].addClass("left__item--set-by-logic");
                    button.onButtonTapped = this._handleShowSetByLogic.bind(this);
                } else {
                    button.onButtonTapped = item.editFn;
                }
            }

            if (item.elemName === "singleComfort") {
                // one item, but has two right sections for the shading temps!
                let upperItem = {...item, cssClass: item.cssClass + "-upper"};
                let lowerItem = {...item, cssClass: item.cssClass + "-lower"};
                this.elements.right.append(IrcTempSettingsScreen.Template.getRightItem(upperItem));
                this.elements.right.append(IrcTempSettingsScreen.Template.getRightItem(lowerItem));
                this.elements[item.elemName + "RightUpper"] = this.elements.right.find(".right__item--" + upperItem.cssClass);
                this.elements[item.elemName + "RightLower"] = this.elements.right.find(".right__item--" + lowerItem.cssClass);
            } else {
                this.elements.right.append(IrcTempSettingsScreen.Template.getRightItem(item));
                this.elements[item.elemName + "Right"] = this.elements.right.find(".right__item--" + item.cssClass);
            }
        }

        _prepareShadingItems() {
            if (this.canHeat) {
                this._prepareShadingItem(true, this._hasInputConn(CtrlEnums.ConnectedInputs.SHADING_HEATING), this.elements.frostProtectionRight);
            }

            if (this.canCool) {
                this._prepareShadingItem(false, this._hasInputConn(CtrlEnums.ConnectedInputs.SHADING_COOLING), this.elements.heatProtectionRight);
            }
        }

        _prepareShadingItem(isHeating, setByLogic, targetElem) {
            var elem,
                button,
                shadingId = isHeating ? "shadingheat" : "shadingcool";
            targetElem.append(IrcTempSettingsScreen.Template.getShadingItem(shadingId));
            elem = targetElem.find(".item__shading--" + shadingId);
            this.elements[shadingId] = elem;
            this.elements[shadingId + "Value"] = elem.find(".shading__value");
            button = new GUI.LxButton(this, elem);
            this.addToHandledSubviews(button);

            if (setByLogic) {
                button.onButtonTapped = this._handleShowSetByLogic.bind(this);
            } else if (isHeating) {
                button.onButtonTapped = this._editShadingHeatTemp.bind(this);
            } else {
                button.onButtonTapped = this._editShadingCoolTemp.bind(this);
            }
        }

        _getItems() {
            var items = [];

            if (this.isManual) {
                items = this._getManualItems();
            } else if (this.canHeat && !this.canCool) {
                items = this._getOnlyHeatingItems();
            } else if (this.canCool && !this.canHeat) {
                items = this._getOnlyCoolingItems();
            } else {
                items = this._getFullAutoItems();
            }

            return items;
        }

        _getOnlyCoolingItems() {
            var items = [];
            items.push(this._getHeatProtectionItem());
            items.push(this._getEcoMaxItem());
            items.push(this._getComfortMaxItem());
            items.push(this._getFloatingItem(Icon.ClimateUs.NO_HEATING));
            items.push(this._getFrostProtectionItem());
            return items;
        }

        _getOnlyHeatingItems() {
            var items = [];
            items.push(this._getHeatProtectionItem());
            items.push(this._getFloatingItem(Icon.ClimateUs.NO_COOLING));
            items.push(this._getComfortMinItem());
            items.push(this._getEcoMinItem());
            items.push(this._getFrostProtectionItem());
            return items;
        }

        _getManualItems() {
            var items = [];
            items.push(this._getHeatProtectionItem());
            this.canHeat && !this.canCool && items.push(this._getFloatingItem(Icon.ClimateUs.NO_COOLING));
            items.push(this._getManualItem(this.canHeat && !this.canCool));
            this.canCool && !this.canHeat && items.push(this._getFloatingItem(Icon.ClimateUs.NO_HEATING));
            items.push(this._getFrostProtectionItem());
            return items;
        }

        _getManualItem(isLower) {
            var item = this._createItem(_("controls.irc.manual.target-temp"), "tempTarget", Icon.ClimateUs.COOLING, "target-temp", "targetTemp", isLower, this._editManualTarget.bind(this));

            item.icon2Src = Icon.ClimateUs.HEATING;
            return item;
        }

        _getFullAutoItems() {
            var items = [];
            items.push(this._getHeatProtectionItem());
            items.push(this._getEcoMaxItem());

            if (this.control.singleComfortTemp) {
                items.push(this._getSingleComfortItem());
            } else {
                items.push(this._getComfortMaxItem());
                items.push(this._getFloatingItem());
                items.push(this._getComfortMinItem());
            }
            items.push(this._getEcoMinItem());
            items.push(this._getFrostProtectionItem());
            return items;
        }

        _getHeatProtectionItem() {
            return this._createItem(_("controls.ircv2.temperatures.heatprotection"), "heatProtectTemperature", null, "heat-protection", "heatProtection", false);
        }

        _getEcoMaxItem() {
            return this._createItem(
                _("controls.ircv2021.mode-eco"),
                "ecoTempMax",
                Icon.ClimateUs.COOLING,
                "eco-max", "ecoMax",
                false, this._editEcoMax.bind(this),
                this._hasInputConn(CtrlEnums.ConnectedInputs.ECO_MAX_TEMP));
        }

        _getComfortMaxItem() {
            return this._createItem(_("controls.irc.temp.comfort.short"), "comfortTempMax", Icon.ClimateUs.COOLING, "comfort-max", "comfortMax", false, this._editComfortMax.bind(this), this._hasInputConn(CtrlEnums.ConnectedInputs.COMFORT_MAX_TEMP));
        }

        _getSingleComfortItem() {
            var item = this._createItem(
                _("controls.irc.temp.comfort.short"),
                "comfortTempMin",
                this.canCool ? Icon.ClimateUs.COOLING : null,
                "single-comfort-temp",
                "singleComfort",
                false,
                this._editSingleComfort.bind(this),
                this._hasInputConn(CtrlEnums.ConnectedInputs.COMFORT_MIN_TEMP)
            );
            item.icon2Src = this.canHeat ? Icon.ClimateUs.HEATING : null;
            return item;
        }

        _getFloatingItem(iconSrc) {
            return this._createItem(null, null, iconSrc, "floating", "floating", false);
        }

        _getComfortMinItem() {
            return this._createItem(_("controls.irc.temp.comfort.short"), "comfortTempMin", Icon.ClimateUs.HEATING, "comfort-min", "comfortMin", true, this._editComfortMin.bind(this), this._hasInputConn(CtrlEnums.ConnectedInputs.COMFORT_MIN_TEMP));
        }

        _getEcoMinItem() {
            return this._createItem(
                 _("controls.ircv2021.mode-eco"),
                "ecoTempMin",
                Icon.ClimateUs.HEATING,
                "eco-min",
                "ecoMin", true,
                this._editEcoMin.bind(this),
                this._hasInputConn(CtrlEnums.ConnectedInputs.ECO_MIN_TEMP)
            );
        }

        _getFrostProtectionItem() {
            return this._createItem(_("controls.ircv2.temperatures.antifreeze"), "frostProtectTemperature", null, "frost-protection", "frostProtection", true);
        }

        _hasInputConn(inputBit) {
            return !!hasBit(this.control.details.connectedInputs, inputBit);
        }

        _createItem(leftText, stateId, iconSrc, cssClass, elemName, isLower, editFn, setByLogic) {
            return {
                leftText: leftText,
                stateId: stateId,
                iconSrc: iconSrc,
                cssClass: cssClass,
                editFn: editFn,
                isLower: isLower,
                elemName: elemName,
                setByLogic: setByLogic
            };
        }

        _editComfortMax(button, tapEvent) {
            if (this.control.singleComfortTemp) {
                this._editSingleComfort(button, tapEvent);
            } else {
                this._showNewEditMenu(_("controls.ircv2021.comfort-cooling"), tapEvent, CtrlEnums.TmpSliderType.COMFORT_COOL);
            }
        }

        _editSingleComfort(button, tapEvent) {
            this._showNewEditMenu(_("controls.irc.temp.comfort.short"), tapEvent, CtrlEnums.TmpSliderType.SINGLE_COMFORT);
        }

        _editComfortMin(button, tapEvent) {
            if (this.control.singleComfortTemp) {
                this._editSingleComfort(button, tapEvent);
            } else {
                this._showNewEditMenu(_("controls.ircv2021.comfort-heating"), tapEvent, CtrlEnums.TmpSliderType.COMFORT_HEAT);
            }
        }

        _editEcoMax(button, tapEvent) {
            if (this.control.singleComfortTemp) {
                this._showNewEditMenu(_("controls.ircv2021.mode-eco"), tapEvent, CtrlEnums.TmpSliderType.ECO_SINGLE_COMFORT);
            } else {
                this._showNewEditMenu(_("controls.ircv2021.eco-cooling"), tapEvent, CtrlEnums.TmpSliderType.ECO_MAX);
            }
        }

        _editEcoMin(button, tapEvent) {
            if (this.control.singleComfortTemp) {
                this._showNewEditMenu(_("controls.ircv2021.mode-eco"), tapEvent, CtrlEnums.TmpSliderType.ECO_SINGLE_COMFORT);
            } else {
                this._showNewEditMenu(_("controls.ircv2021.eco-heating"), tapEvent, CtrlEnums.TmpSliderType.ECO_MIN);
            }
        }

        _editManualTarget(button, tapEvent) {
            this._handleShowLimitEdit(_("controls.irc.manual.target-temp"), this.elements["targetTemp"], this.states.tempTarget, this._sendTargetTemp.bind(this), this.states.frostProtectTemperature, this.states.heatProtectTemperature, button, tapEvent);
        }

        _sendTargetTemp(value) {
            this._sendCommand(Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.MANUAL, value);
        }

        _editShadingHeatTemp(button, tapEvent) {
            this._showNewEditMenu(_("controls.ircv2021.shading-temperature-heating"), tapEvent, CtrlEnums.TmpSliderType.SHADING_HEAT);
        }

        _editShadingCoolTemp(button, tapEvent) {
            this._showNewEditMenu(_("controls.ircv2021.shading-temperature-cooling"), tapEvent, CtrlEnums.TmpSliderType.SHADING_COOL);
        }

        _setElementVisible(jqElem, visible) {
            jqElem.css("visibility", visible ? "visible" : "hidden");
        }

        _updateActiveZone(jqElem, temp) {
            var tempElem = jqElem.find(".zone__temperature"),
                text = _("controls.climate.us.outside-temp.currently", {
                    temp: IrcTempSettingsScreen.Template.getCurrentTemperatureSpan(this.control.formatTemp(temp))
                });

            tempElem.html(text);
        }

        _showNewEditMenu(title, tapEvent, type) {
            let slidersView = new IrcTempSliders(this.control, type);
            NavigationComp.showContextMenu(this, {
                contentView: slidersView
            }, title, tapEvent.srcElement, GUI.LxGenericContextMenu);
        }

        // TableViewDataSource Methods
        styleForTableView() {
            return TableViewStyle.MENU;
        }

        _handleShowLimitEdit(title, sourceElem, value, changedFn, min, max, button, tapEvent) {
            NavigationComp.showContextMenu(this, {
                value: value,
                format: this.control.getFormatForTemp(),
                minValue: min === undefined ? this.absMinTemp : min,
                maxValue: max === undefined ? this.absMaxTemp : max,
                step: this.control.getTempStep(),
                // depending on C or F its 0.5 or 1
                onSliderDisappear: function (value) {
                    changedFn(value);
                }
            }, title, tapEvent.srcElement, GUI.LxSliderContextMenu);
        }

        _handleShowSetByLogic() {
            NavigationComp.showPopup({
                title: _("controls.ircv2021.temp-set-by-logic"),
                buttonCancel: _("okay")
            });
        }

        _sendCommand(cmd, value) {
            return this.control.sendCommand(Commands.format(cmd, value));
        }

        _updateItemTemp(item, states) {
            var temp, valueElem;

            if (item.stateId && states[item.stateId]) {
                temp = states[item.stateId];
                valueElem = this.elements[item.elemName + "Value"];
                valueElem && valueElem.text(this.control.formatTemp(temp));
                item.temp = temp;
            }
        }

        _updateItemTempRanges() {
            var prevTemp = 1000,
                item;

            for (var i = 0; i < this.items.length; i++) {
                item = this.items[i];

                if (item.hasOwnProperty("temp")) {
                    if (item.elemName === "singleComfort") {
                        // range is to be calculated differently than for the others
                        item.upperLimit = prevTemp;
                        item.lowerLimit = item.temp - this.control.getStates().absentMinOffset;
                    } else if (!item.isLower) {
                        item.upperLimit = prevTemp;
                        item.lowerLimit = item.temp;
                    } else {
                        item.upperLimit = item.temp;

                        if (i + 1 < this.items.length) {
                            item.lowerLimit = this.items[i + 1].temp;
                        } else {
                            item.lowerLimit = -1000;
                        }
                    }
                } else {
                    // must be floating
                    item.upperLimit = this.items[i - 1].temp;
                    item.lowerLimit = this.items[i + 1].temp;
                }

                prevTemp = item.lowerLimit;
            }
        }

        _identifySingleComfortShadingZone(item, shadingTemp) {
            if (item.elemName !== "singleComfort") {
                return "";
            }
             let result = "";

            // singleComfort temp has two areas on the right side, upper & lower
            if (shadingTemp > item.temp) {
                result = "Upper";
            } else if (shadingTemp < item.temp) {
                result = "Lower";
            }

            return result;
        }

        _repositionShading(states) {
            Debug.Control.IRC.TempSettings && console.log(this.name, "_repositionShading");
            var heatShadingZone,
                coolShadingZone,
                sHeatTemp = states.shadingTempHeat,
                sCoolTemp = states.shadingTempCool;
            Debug.Control.IRC.TempSettings && console.log(this.name, "  Heating Shading: " + this.control.formatTemp(sHeatTemp));
            console.log(this.name, "  Cooling Shading: " + this.control.formatTemp(sCoolTemp));
            this.items.forEach(function (item) {
                Debug.Control.IRC.TempSettings && console.log(this.name, lxFormat("  - %.1f ", item.upperLimit) + lxFormat("-> %.1f : ", item.lowerLimit) + item.elemName);

                if (item.upperLimit > sHeatTemp && sHeatTemp >= item.lowerLimit && !heatShadingZone) {
                    Debug.Control.IRC.TempSettings && console.log(this.name, " --> heating goes in " + item.elemName + " --> " + lxFormat("%.1f", sHeatTemp));
                    let singleZone = this._identifySingleComfortShadingZone(item, sHeatTemp);
                    heatShadingZone = this.elements[item.elemName + "Right" + singleZone];
                }

                if (item.upperLimit > sCoolTemp && sCoolTemp >= item.lowerLimit && !coolShadingZone) {
                    Debug.Control.IRC.TempSettings && console.log(this.name, " --> cooling goes in " + item.elemName + " --> " + lxFormat("%.1f", sCoolTemp));

                    let singleZone = this._identifySingleComfortShadingZone(item, sCoolTemp);
                    coolShadingZone = this.elements[item.elemName + "Right" + singleZone];
                }
            }.bind(this));

            if (this.elements.shadingheat && heatShadingZone) {
                this.elements.shadingheat.remove();
                heatShadingZone.append(this.elements.shadingheat);
            } else if (this.elements.shadingheat) {
                console.error(this.name, "No zone has been identified for the heat shading!");
            }

            if (this.elements.shadingcool && coolShadingZone) {
                this.elements.shadingcool.remove();
                coolShadingZone.append(this.elements.shadingcool);
            } else if (this.elements.shadingcool) {
                console.error(this.name, "No zone has been identified for the cool shading!");
            }
        }

        _updateShadingTemp(states) {
            this.elements.shadingcoolValue && this.elements.shadingcoolValue.text(this.control.formatTemp(states.shadingTempCool));
            this.elements.shadingheatValue && this.elements.shadingheatValue.text(this.control.formatTemp(states.shadingTempHeat));
        }

    };
});
