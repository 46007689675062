'use strict';

define("PushbuttonControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class PushbuttonControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            if (control.isAutomaticScene) {
                return [
                    this.createCommand(_("scenes.exec"), this.createCmdObj(Commands.PUSHBUTTON.ON, Commands.PUSHBUTTON.OFF, Commands.PUSHBUTTON.PULSE)),
                    ...(control.hasSceneEditPermission ? [this.createCommand(_("scenes.edit"), control.editScene.bind(control))] : [])
                ];
            } else {
                return [
                    this.createCommand(
                        null,
                        this.createCmdObj(Commands.PUSHBUTTON.ON, Commands.PUSHBUTTON.OFF, Commands.PUSHBUTTON.PULSE),
                        Icon.Pushbutton.PUSHBUTTON,
                        {
                            type: GUI.TableViewV2.CellType.ROUND_COMFORT_ACTION
                        }
                    )
                ];
            }
        }

    };
});
