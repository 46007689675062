'use strict';

define([
    'LxComponents',
    'GlobalStyles',
    'MediaBrowserV2Base',
    'MediaBrowserV2Service',
    'MediaBrowserV2Service7',
    'MediaBrowserV2Service11',
    'MediaBrowserV2Service12',
    'MediaBrowserV2Favorites',
    'MediaBrowserV2LineIn',
    'MediaBrowserV2Library',
    'MediaBrowserV2Playlists',
    "CoverViewV2",
    "LightV2ControlEnums"
], function (
    {
        ReactWrapper,
        LxReactQuickSelect
    },
    {
        default: globalStyles
    }
) {
    class TableViewShowCase extends GUI.TableViewScreenV2 {
        //region Static
        static CellTypeBlackList = [// region Not in use!
            GUI.TableViewV2.CellType.MS_MENU_CELL, GUI.TableViewV2.CellType.Special.MEDIA_EULA_INPUT_CELL, GUI.TableViewV2.CellType.CHECKABLE_RATING, GUI.TableViewV2.CellType.Special.TIME_COUNTER_CELL, GUI.TableViewV2.CellType.Special.EMPTY_SLOT_CELL, GUI.TableViewV2.CellType.Special.GRADIENT_SLIDER, GUI.TableViewV2.CellType.Special.DEVICE_LEARNING_CHECKABLE_CELL, GUI.TableViewV2.CellType.Special.TASK_COMMAND, GUI.TableViewV2.CellType.Special.SAUNA_VALUE, GUI.TableViewV2.CellType.Special.DELAYED_ALARM_CELL, GUI.TableViewV2.CellType.Special.MasterVolumeCell, GUI.TableViewV2.CellType.Special.VOLUME_OVERLAY_CELL, GUI.TableViewV2.CellType.Special.AUTOMATIC_DESIGNER_CONTAINER_TASK_DETAILS_CELL, GUI.TableViewV2.CellType.Special.COLLECTION_BUTTON, GUI.TableViewV2.CellType.Special.AUTOPILOT_EVENT_CELL, GUI.TableViewV2.CellType.Special.GROUP_CELL, GUI.TableViewV2.CellType.Special.INTERCOM_STATE, GUI.TableViewV2.CellType.Special.QUEUE, GUI.TableViewV2.CellType.Special.MEDIA_ADD, GUI.TableViewV2.CellType.Special.MEDIA_CONTROL, GUI.TableViewV2.CellType.Special.ZONE_MEDIA, // endregion
            // region Abstract
            /BaseCell/, // endregion
            // region Control dependent
            /ControlStateCell/, GUI.TableViewV2.CellType.Special.ZONE_GROUPING_V2, GUI.TableViewV2.CellType.Special.AudioVolCell, GUI.TableViewV2.CellType.Special.ZONE_GROUPING, GUI.TableViewV2.CellType.Special.SPOTIFY_CELL, GUI.TableViewV2.CellType.Special.INPUT_CELL, GUI.TableViewV2.CellType.Special.MENU_SYSTEM_STATE_CELL, GUI.TableViewV2.CellType.Special.VOLUME_CONTROL // endregion
        ];
        static MockData = {
            [GUI.TableViewV2.CellType.DELETE]: {
                variants: [{
                    content: {
                        title: "DeleteCell"
                    }
                }, {
                    content: {
                        title: "DeleteCell with iconSrc",
                        iconSrc: Icon.AlarmClock.ALARM_CLOCK
                    }
                }]
            },
            [GUI.TableViewV2.CellType.GENERAL]: {
                variants: [{
                    content: {
                        title: "GeneralCell Orange",
                        subtitle: "This is the generalCellV2 with discText",
                        disclosureText: "Click here...",
                        disclosureColor: globalStyles.colors.orange
                    }
                }, {
                    content: {
                        leftIconSrc: Icon.HEART,
                        title: "GeneralCell Orange",
                        subtitle: "This is the generalCellV2 with discText",
                        disclosureText: "Click here...",
                        disclosureColor: globalStyles.colors.orange
                    }
                }, {
                    content: {
                        leftIconSrc: Icon.HEART,
                        leftIconLarger: true,
                        title: "GeneralCell Orange",
                        subtitle: "This is the generalCellV2 with discText",
                        disclosureText: "Click here...",
                        disclosureColor: globalStyles.colors.orange
                    }
                }, {
                    content: {
                        leftIconSrc: Icon.HEART,
                        leftIconBgEnabled: true,
                        title: "GeneralCell Orange",
                        subtitle: "This is the generalCellV2 with discText",
                        disclosureText: "Click here...",
                        disclosureColor: globalStyles.colors.orange
                    }
                }, {
                    content: {
                        title: "GeneralCell",
                        subtitle: "with multiple disc texts",
                        disclosureText: "DiscText",
                        disclosureText1: "DiscText1",
                        disclosureColor: globalStyles.colors.orange,
                        disclosureColor1: globalStyles.colors.purple
                    }
                }]
            },
            [GUI.TableViewV2.CellType.INPUT]: {
                variants: [{
                    content: {
                        title: "InputCell without value",
                        placeholder: "This is a placeholder",
                        autoFocus: false
                    }
                }, {
                    content: {
                        title: "InputCell with value",
                        value: "This is a value!",
                        placeholder: "This is a placeholder",
                        autoFocus: false,
                        validationRegex: Regex.TEXT
                    }
                }, {
                    content: {
                        value: "Only name characters allowed",
                        placeholder: "Here the NAME regx is used!",
                        autoFocus: false,
                        validationRegex: Regex.NAME
                    }
                }]
            },
            [GUI.TableViewV2.CellType.PASS_INPUT]: {
                variants: [{
                    content: {
                        autoFocus: false,
                        placeholder: "PLACEHOLDER",
                        rightIconSrc: Icon.USER_MANAGEMENT.PASSWORDSTRENGTH
                    }
                }]
            },
            [GUI.TableViewV2.CellType.DURATION_PICKER]: {
                variants: [{
                    content: {
                        title: "PickerCell without icon",
                        value: [12, 3, 24]
                    }
                }, {
                    content: {
                        iconSrc: Icon.AlarmClock.ALARM_CLOCK,
                        title: "PickerCell with icon",
                        value: [12, 3, 24]
                    }
                }]
            },
            [GUI.TableViewV2.CellType.SLIDER]: {
                variants: [{
                    content: {
                        title: GUI.TableViewV2.CellType.SLIDER,
                        step: 0.5,
                        value: 69,
                        format: "%.2f"
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.SLIDER,
                        step: 1,
                        value: 69,
                        format: "%.2f"
                    },
                    userfriendlyValueForSlider: (cell, section, row, tableView, value) => {
                        let alternativeChars = [
                            "0️⃣",
                            "1️⃣",
                            "2️⃣",
                            "3️⃣",
                            "4️⃣",
                            "5️⃣",
                            "6️⃣",
                            "7️⃣",
                            "8️⃣",
                            "9️⃣"
                        ];
                        return `${value}`.replace(/(\d)/g, (fullMatch, digit) => {
                            return alternativeChars[parseInt(digit)];
                        })
                    }
                }]
            },
            [GUI.TableViewV2.CellType.SWITCH]: {
                variants: [{
                    content: {
                        title: "SwitchCell ORANGE",
                        active: true,
                        activeColor: globalStyles.colors.orange
                    }
                }, {
                    content: {
                        title: "SwitchCell RED",
                        active: true,
                        activeColor: globalStyles.colors.red
                    }
                }, {
                    content: {
                        title: "SwitchCell BRAND",
                        active: true,
                        activeColor: globalStyles.colors.brand
                    }
                }]
            },
            [GUI.TableViewV2.CellType.TINY]: {
                variants: [{
                    content: {
                        title: "TinyCell without icon"
                    }
                }, {
                    content: {
                        title: "TinyCell with icon",
                        iconSrc: Icon.AlarmClock.ALARM_CLOCK
                    }
                }]
            },
            [GUI.TableViewV2.CellType.TINY_SEPARATOR]: {
                variants: [{
                    content: {}
                }]
            },
            [GUI.TableViewV2.CellType.Special.ALARM_CLOCK_ENTRY]: {
                variants: [{
                    content: {
                        name: "Normal",
                        modes: [0, 5, 2, 6, 7],
                        time: "12:50",
                        isActive: true
                    }
                },{
                    content: {
                        name: "Normal with Icon",
                        modes: [0, 5, 2, 6, 7],
                        time: "12:50",
                        isActive: true,
                        iconSrc: Icon.AlarmClock.RECURRING,
                    }
                },{
                    content: {
                        name: "Control is disabled",
                        modes: [0, 5, 2, 6, 7],
                        time: "12:50",
                        isActive: true,
                        isControlDisabled: true
                    }
                },{
                    content: {
                        name: "With Hidden Switch",
                        modes: [0, 5, 2, 6, 7],
                        time: "12:50",
                        isActive: true,
                        hideSwitch: true
                    }
                },{
                    content: {
                        name: "NightLight",
                        modes: [0, 5, 2, 6, 7],
                        time: "12:50",
                        isActive: true,
                        nightLight: true
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.MEDIA_EULA_INPUT_CELL]: {
                variants: [{
                    content: {
                        link: "www.loxone.com"
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.MEDIA]: {
                variants: [{
                    content: {
                        title: "Special MediaCell",
                        disclosureIcon: false,
                        subtitle: "Subtitle"
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.MEDIA_COVER]: {
                variants: [],
                depCheck: () => {
                    return false;
                },
                depNote: "Please view this cell in the Music server zone, its the big square cell with the cover"
            },
            [GUI.TableViewV2.CellType.Special.MEDIA_V2_COVER]: {
                variants: [],
                depCheck: () => {
                    return false;
                },
                depNote: "Please view this cell in the AudioPlayer, its the big square cell with the cover"
            },
            [GUI.TableViewV2.CellType.Special.ZONE_FAVORITE_SAVE]: {
                variants: [{
                    content: {
                        title: "Special ZoneFavoriteSave",
                        doesOverwrite: true
                    }
                }, {
                    content: {
                        title: "Special ZoneFavoriteSave",
                        doesOverwrite: false
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.ZONE_SETTING]: {
                variants: [{
                    content: {
                        title: "Special ZoneSetting",
                        value: 20,
                        minValue: 0,
                        maxValue: 50,
                        step: 0.5,
                        format: "%.2f"
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.MINISERVER_CELL]: {
                variants: [{
                    content: {
                        title: "Special MiniseverCell",
                        subtitle: "Subtitle"
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.MINISERVER_IMAGE]: {
                variants: [{
                    content: {
                        title: "Special MiniseverImageCell",
                        ts: "now",
                        uuid: "this is a uuid!!"
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.REMOTE]: {
                variants: [{
                    content: {
                        title: "Special RemoteCell selected",
                        selected: true
                    }
                }, {
                    content: {
                        title: "Special RemoteCell unselected",
                        selected: false
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.TRACKER]: {
                variants: [{
                    content: {
                        entries: Array(5).fill("").map((_, idx) => {
                            return {
                                timestamp: moment(),
                                text: `Entry ${idx}`
                            };
                        })
                    }
                }, {
                    content: {
                        entries: Array(5).fill("").map((_, idx) => {
                            return {
                                timestamp: moment(),
                                text: `Entry ${idx}${GUI.TableViewV2.Cells.TrackerCell.SEPARATOR_SYMBOL}${Array(5).fill("").map((_, idx) => {
                                    return `Line ${idx}`;
                                }).join(GUI.TableViewV2.Cells.TrackerCell.SEPARATOR_SYMBOL)}`
                            };
                        })
                    }
                }]
            },
            [GUI.TableViewV2.CellType.BATTERY_MONITOR]: {
                variants: [{
                    content: {
                        leftIconSrc: "https://app-content.loxone.com/deviceImages/SteakThermo.png",
                        leftFallbackIconSrc: Icon.AIR_LOGO,
                        title: "Some Device",
                        subtitle: "That don't exists",
                        disclosureIcon: true,
                        disclosureText: "Just a mock",
                        disclosureColor: globalStyles.colors.brand
                    }
                }]
            },
            [GUI.TableViewV2.CellType.RATING]: {
                variants: [{
                    content: {
                        rating: 5
                    }
                }]
            },
            [GUI.TableViewV2.CellType.USER_CELL]: {
                variants: [{
                    content: {
                        user: ActiveMSComponent.getCurrentUser(),
                        disclosureIcon: true,
                        trustPeers: [],
                        disclosureText: ActiveMSComponent.getCurrentUser().isAdmin ? _('admin') : null,
                        disclosureColor: globalStyles.colors.green,
                        clickable: true,
                        subtitle: "subtitle",
                        subtitleColor: globalStyles.colors.red
                    }
                }]
            },
            [GUI.TableViewV2.CellType.ValueSelectionCell]: {
                variants: [{
                    content: {
                        title: _("controls.ircv2021.temperature-adjustment"),
                        valueSelection: {
                            value: 20,
                            minValue: 4,
                            maxValue: 30,
                            stepWidth: 0.5,
                            valueFormat: "%.1f°",
                            finishedDelay: 0
                        },
                        actionButton: {
                            title: _("controls.ircv2021.temperature-adjustment-more"),
                            color: globalStyles.colors.green
                        }
                    }
                }]
            },
            [GUI.TableViewV2.CellType.HALO_ICON]: {
                variants: [{
                    content: {
                        title: "Just a Cell",
                        clickable: false
                    }
                }, {
                    content: {
                        title: "Cell with specific icon",
                        clickable: false,
                        leftIconSrc: Icon.USER_MANAGEMENT.CHECKED
                    }
                }, {
                    content: {
                        title: "cell with specific icon + color",
                        clickable: false,
                        leftIconSrc: Icon.USER_MANAGEMENT.CHECKED,
                        leftIconColor: globalStyles.colors.brand
                    }
                }]
            },
            [GUI.TableViewV2.CellType.LIGHT_TEXT]: {
                variants: [{
                    content: {
                        title: "Title"
                    }
                }, {
                    content: {
                        title: "Title",
                        rightIconSrc: Icon.BATTERY,
                        rightIconColor: globalStyles.colors.blue,
                        rightText: "Text",
                        rightTextColor: globalStyles.colors.yellow_fixed
                    }
                }, {
                    content: {
                        title: "Title",
                        rightIconSrc: Icon.BATTERY,
                        rightIconColor: globalStyles.colors.blue,
                        rightText: "Text",
                        rightTextColor: globalStyles.colors.yellow_fixed,
                        rightTextAlign: "center"
                    }
                }, {
                    content: {
                        title: "Title",
                        rightIconSrc: Icon.BATTERY,
                        rightIconColor: globalStyles.colors.blue,
                        rightText: "Text",
                        rightTextColor: globalStyles.colors.yellow_fixed,
                        rightTextAlign: "right"
                    }
                }]
            },
            [GUI.TableViewV2.CellType.History]: {
                variants: [{
                    content: {
                        time: moment().format(DateType.TimeFormat),
                        title: "Title",
                        subtitle: "subtitle"
                    }
                }]
            },
            [GUI.TableViewV2.CellType.BUTTON_CELL_CUSTOM]: {
                variants: [{
                    content: {
                        title: "Title"
                    }
                }, {
                    content: {
                        title: "Title",
                        iconSrc: Icon.BATTERY
                    }
                }]
            },
            [GUI.TableViewV2.CellType.SWITCH_SUBTITLE]: {
                variants: [{
                    content: {
                        title: "Title",
                        active: true
                    }
                }, {
                    content: {
                        title: "Title",
                        subtitle: "Subtitle",
                        active: true
                    }
                }, {
                    content: {
                        title: "Title",
                        subtitle: "Subtitle",
                        active: true,
                        activeColor: globalStyles.colors.blue
                    }
                }, {
                    content: {
                        title: "Title",
                        subtitle: "Subtitle",
                        active: true,
                        rightIconSrc: Icon.BATTERY
                    }
                }]
            },
            [GUI.TableViewV2.CellType.NOTIFICATION]: {
                variants: [{
                    content: {
                        time: moment.now(),
                        title: "Title",
                        message: "Message",
                        iconSrc: Icon.WARNING,
                        iconColor: Color.WHITE
                    }
                }]
            },
            [GUI.TableViewV2.CellType.SLIDER_SWITCH]: {
                variants: [{
                    content: {
                        title: "Title",
                        value: 69,
                        step: 1,
                        color: globalStyles.colors.brand
                    }
                }]
            },
            [GUI.TableViewV2.CellType.STATUS_BUTTON_CELL]: {
                variants: [{
                    content: {
                        text: "Text",
                        buttonTitle: "buttonTitle",
                        buttonAction: () => {
                            NavigationComp.showPopup({
                                title: "💩",
                                buttonOk: true
                            });
                        }
                    }
                }, {
                    content: {
                        texts: ["Text 1", "Text 2", "Text 3"],
                        buttonTitle: "buttonTitle",
                        buttonAction: () => {
                            NavigationComp.showPopup({
                                title: "💩",
                                buttonOk: true
                            });
                        }
                    }
                }]
            },
            [GUI.TableViewV2.CellType.DURATION_SUBTITLE]: {
                variants: [{
                    content: {
                        title: "Title",
                        selected: true,
                        disclosureIcon: true,
                        duration: moment().format('L LT')
                    }
                }, {
                    content: {
                        title: "Title",
                        subtitle: "subtitle",
                        selected: false,
                        disclosureIcon: true,
                        duration: moment().format('L LT')
                    }
                }, {
                    content: {
                        title: "Title",
                        selected: true,
                        disclosureIcon: true,
                        duration: moment().format('L LT'),
                        checkable: true
                    }
                }, {
                    content: {
                        title: "Title",
                        subtitle: "subtitle",
                        selected: false,
                        disclosureIcon: true,
                        duration: moment().format('L LT'),
                        checkable: true
                    }
                }]
            },
            [GUI.TableViewV2.CellType.BUTTON]: {
                variants: [{
                    content: {
                        iconSrc: Icon.BATTERY,
                        iconColor: globalStyles.colors.yellow_fixed,
                        title: "Title",
                        subtitle: "subtitle",
                        buttonSrc: Icon.SUCCESS,
                        color: globalStyles.colors.red,
                        disclosureText: "DiscTxt"
                    }
                }, {
                    content: {
                        iconSrc: Icon.BATTERY,
                        leftIconBgEnabled: true,
                        iconColor: globalStyles.colors.yellow_fixed,
                        title: "Title",
                        subtitle: "subtitle",
                        buttonSrc: Icon.SUCCESS,
                        color: globalStyles.colors.red,
                        disclosureText: "DiscTxt"
                    }
                }, {
                    content: {
                        iconSrc: Icon.BATTERY,
                        leftIconBgEnabled: false,
                        iconColor: globalStyles.colors.yellow_fixed,
                        title: "Title",
                        subtitle: "subtitle",
                        buttonSrc: Icon.SUCCESS,
                        button2Src: Icon.HEART,
                        color: globalStyles.colors.red,
                        color2: globalStyles.colors.purple,
                        disclosureText: "DiscTxt"
                    }
                }, {
                    content: {
                        iconSrc: Icon.BATTERY,
                        leftIconBgEnabled: false,
                        iconColor: globalStyles.colors.yellow_fixed,
                        title: "Title",
                        subtitle: "subtitle",
                        buttonSrc: Icon.SUCCESS,
                        button2Src: Icon.HEART,
                        stateIconSrc: Icon.WARNING,
                        color: globalStyles.colors.red,
                        color2: globalStyles.colors.purple
                    }
                }]
            },
            [GUI.TableViewV2.CellType.HOME_KIT_PROGRESS]: {
                variants: [{
                    content: {
                        max: 69,
                        visible: 3
                    }
                }]
            },
            [GUI.TableViewV2.CellType.CHECKABLE_BUTTON]: {
                variants: [{
                    content: {
                        title: "Title",
                        selected: true,
                        buttonSrc: Icon.SUCCESS,
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED
                    }
                }, {
                    content: {
                        title: "Title",
                        selected: false,
                        buttonSrc: Icon.WARNING,
                        button2Src: Icon.SUCCESS,
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED
                    }
                }, {
                    content: {
                        title: "Title",
                        selected: false,
                        buttonSrc: Icon.WARNING,
                        button2Src: Icon.SUCCESS,
                        buttonFill: globalStyles.colors.blue,
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED
                    }
                }]
            },
            [GUI.TableViewV2.CellType.CHECKABLE_RATING]: {
                variants: [{
                    content: {
                        rating: 5,
                        title: "Title",
                        selected: true
                    }
                }]
            },
            [GUI.TableViewV2.CellType.CHECKABLE_DESCRIPTION]: {
                variants: [{
                    content: {
                        title: "Title",
                        selected: true,
                        description: "This is a long description"
                    }
                }]
            },
            [GUI.TableViewV2.CellType.CHECKABLE]: {
                variants: [{
                    content: {
                        title: "Title",
                        selected: true
                    }
                }]
            },
            [GUI.TableViewV2.CellType.CHECKABLE_VALUE_SELECTION]: {
                variants: [{
                    content: {
                        title: GUI.TableViewV2.CellType.CHECKABLE_VALUE_SELECTION,
                        valueSelection: {
                            value: 69,
                            minValue: 4,
                            maxValue: 100,
                            stepWidth: 0.5,
                            valueFormat: "%.1f°",

                            valueChangedFn() {
                            }

                        },
                        selected: true
                    }
                }]
            },
            [GUI.TableViewV2.CellType.DetailedContentBaseCell]: {
                variants: [{
                    content: {
                        icon: Icon.HEART,
                        color: globalStyles.colors.red,
                        title: "Title",
                        detail: {
                            icon: Icon.WARNING,
                            text: "Warning"
                        },
                        info: {
                            text: "Info Text"
                        }
                    }
                }]
            },
            [GUI.TableViewV2.CellType.DATE_TIME_PICKER]: {
                variants: [{
                    content: {
                        title: `${GUI.TableViewV2.CellType.DATE_TIME_PICKER}`,
                        value: moment()
                    }
                }, {
                    content: {
                        iconSrc: Icon.Daytimer.CALENDAR,
                        title: `${GUI.TableViewV2.CellType.DATE_TIME_PICKER} with icon`,
                        value: moment()
                    }
                }, {
                    content: {
                        iconSrc: Icon.Daytimer.CALENDAR,
                        title: `${GUI.TableViewV2.CellType.DATE_TIME_PICKER} with hidden year`,
                        value: moment(),
                        hideTimeAndYear: true
                    }
                }]
            },
            [GUI.TableViewV2.CellType.DATE_PICKER]: {
                variants: [{
                    content: {
                        title: `${GUI.TableViewV2.CellType.DATE_PICKER}`,
                        value: moment()
                    }
                }, {
                    content: {
                        iconSrc: Icon.Daytimer.CALENDAR,
                        title: `${GUI.TableViewV2.CellType.DATE_PICKER} with icon`,
                        value: moment()
                    }
                }, {
                    content: {
                        title: `${GUI.TableViewV2.CellType.DATE_PICKER} with markes`,
                        value: moment(),
                        marked: [{
                            d: moment().add(1, "day").toDate(),
                            text: "Tomorrow",
                            color: globalStyles.colors.brand
                        }, {
                            d: moment().subtract(1, "day").toDate(),
                            text: "Yesterday",
                            color: globalStyles.colors.purple
                        }]
                    }
                }]
            },
            [GUI.TableViewV2.CellType.TIME_PICKER]: {
                variants: [{
                    content: {
                        title: `${GUI.TableViewV2.CellType.TIME_PICKER}`,
                        value: [12, 43]
                    }
                }, {
                    content: {
                        iconSrc: Icon.Daytimer.CALENDAR,
                        title: `${GUI.TableViewV2.CellType.TIME_PICKER} with icon`,
                        value: [12, 43]
                    }
                }]
            },
            [GUI.TableViewV2.CellType.SEARCH]: {
                variants: [{
                    content: {
                        title: `${GUI.TableViewV2.CellType.SEARCH}`,
                        value: "Value",
                        placeholder: "🔍",
                        autoFocus: false
                    }
                }, {
                    content: {
                        title: `${GUI.TableViewV2.CellType.SEARCH} emails only`,
                        value: "you@me.lol",
                        placeholder: "🔍",
                        autoFocus: false,
                        validationRegex: Regex.EMAIL
                    }
                }]
            },
            [GUI.TableViewV2.CellType.TEXT]: {
                variants: [{
                    content: {
                        text: GUI.TableViewV2.CellType.TEXT
                    }
                }, {
                    content: {
                        text: GUI.TableViewV2.CellType.TEXT,
                        color: globalStyles.colors.green
                    }
                }, {
                    content: {
                        text: GUI.TableViewV2.CellType.TEXT,
                        textColor: globalStyles.colors.green
                    }
                }, {
                    content: {
                        text: GUI.TableViewV2.CellType.TEXT,
                        centered: true
                    }
                }]
            },
            [GUI.TableViewV2.CellType.LIGHT]: {
                variants: [{
                    content: {
                        title: GUI.TableViewV2.CellType.LIGHT
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.LIGHT,
                        iconSrc: Icon.SUCCESS
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.LIGHT,
                        color: Color.ALEXA_BLUE
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.MESSAGE_CENTER_ENTRY_CELL]: {
                variants: [{
                    content: {
                        entry: {
                            "entryUuid": "188d7608-00dc-41bd-ffff504f94a00201",
                            "eventId": 1045,
                            "sourceUuid": "0c5ba600-0053-23bd-ffff403fb0c34b9e",
                            "affectedUuids": ["0b734139-0031-050e-ffff403fb0c34b9e"],
                            "severity": 4,
                            "affectedName": "CRITICAL",
                            "title": "CRITICAL",
                            "desc": "CRITICAL",
                            "roomUuid": "0b734138-03ac-03dc-ffff403fb0c34b9e",
                            "installationPlace": null,
                            "helpLink": null,
                            "isHistoric": false,
                            "setHistoricAt": null,
                            "confirmedAt": null,
                            "timestamps": [1643252608],
                            "readAt": null,
                            "isVisuLocked": false,
                            "actions": [{
                                "actionId": 1005,
                                "title": "Acknowledge",
                                "location": null,
                                "link": null,
                                "isSecured": false,
                                "requiredPermissions": 0
                            }, {
                                "actionId": 1001,
                                "title": "Contact Loxone Partner",
                                "location": "loxone://ms?mac=504F94A00202&loc=partnerInfo",
                                "link": null,
                                "isSecured": false,
                                "requiredPermissions": 0
                            }]
                        },
                        checkConfirmed: true
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.ACTIVITY]: {
                variants: [{
                    content: {
                        activity: {
                            getThumb() {
                                let thumb = Q(INTERCOM_MOCK_IMG);

                                thumb.abort = () => {
                                };

                                return thumb;
                            },

                            getDate() {
                                return moment();
                            }

                        }
                    }
                },
                    /*
                    {
                      content: {
                          activity: {
                              getThumb() {
                                  let thumb = Q(INTERCOM_MOCK_IMG);
                                  thumb.abort = () => {}
                                  return thumb;
                              },
                              getDate() {
                                  return moment();
                              }
                          },
                          inContent: true
                      }
                    },*/
                    {
                        content: {}
                    }]
            },
            [GUI.TableViewV2.CellType.Special.NEWS_HUB]: {
                variants: [{
                    content: {
                        entry: {
                            timestamp: moment.now() / 1000,
                            title: "Test News without image"
                        }
                    }
                }, {
                    content: {
                        entry: {
                            timestamp: moment.now() / 1000,
                            title: "Test News with image",
                            image: "https://i.giphy.com/media/xUPGcAMxlZ1F7cyqNG/giphy.webp"
                        }
                    }
                }, {
                    content: {
                        entry: {
                            timestamp: moment.now() / 1000,
                            title: "New Test News with image",
                            image: "https://i.giphy.com/media/xUPGcAMxlZ1F7cyqNG/giphy.webp"
                        },
                        isNew: true
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.COVER]: {
                variants: [{
                    content: {
                        title: GUI.TableViewV2.CellType.Special.COVER,
                        progress: 0.69,
                        direction: Direction.UP
                    }
                }, {
                    content: {
                        title: `${GUI.TableViewV2.CellType.Special.COVER} with cover position`,
                        progress: 0.69,
                        direction: Direction.Down,
                        hasCoverPosition: true
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.LOADING]: {
                variants: [{
                    content: {}
                }]
            },
            [GUI.TableViewV2.CellType.Special.DEVICE_LEARNING_CELL]: {
                variants: [{
                    content: {
                        leftIconSrc: Icon.AIR_LOGO,
                        title: GUI.TableViewV2.CellType.Special.DEVICE_LEARNING_CELL
                    }
                }, {
                    content: {
                        leftIconSrc: Icon.AIR_LOGO,
                        title: `${GUI.TableViewV2.CellType.Special.DEVICE_LEARNING_CELL} online`,
                        isOnline: true
                    }
                }]
            },
            [GUI.TableViewV2.CellType.CENTERED_BUTTON]: {
                variants: [{
                    content: {
                        title: GUI.TableViewV2.CellType.CENTERED_BUTTON
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.CENTERED_BUTTON,
                        color: globalStyles.colors.brand
                    }
                }]
            },
            [GUI.TableViewV2.CellType.CENTER]: {
                variants: [{
                    content: {
                        title: GUI.TableViewV2.CellType.CENTER
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.CENTER,
                        textColor: globalStyles.colors.brand
                    }
                }, {
                    content: {
                        icon: Icon.HEART
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.MediaBrowserPlayableCell]: {
                variants: Object.keys(MusicServerEnum.LineInMap).map((lineInType, idx) => {
                    let enabled = !!(idx % 2);
                    return {
                        content: {
                            title: `Type ${lineInType} - ${enabled ? "Playing" : "Paused"}`,
                            item: {
                                contentType: MusicServerEnum.MediaContentType.LINEIN,
                                enabled: enabled,
                                icontype: lineInType
                            },
                            details: {}
                        }
                    };
                })
            },
            [GUI.TableViewV2.CellType.Special.MediaBrowserBrowsableCell]: {
                variants: [{
                    content: {
                        title: GUI.TableViewV2.CellType.Special.MediaBrowserBrowsableCell,
                        subtitle: "subtitle",
                        item: {
                            album: "Metal & Blood",
                            artist: "Luminite",
                            audiopath: "spotify:track:5wnjmEpnmDzoABVypugr8g",
                            audiotype: 5,
                            coverurl: "https://i.scdn.co/image/ab67616d0000b27345e39b058aa75851aa9b69fd",
                            duration: 175.5,
                            title: "Metal & Blood",
                            unique_id: "cfb5174d3aca31f996bd311c11ead1d06fbf99ae",
                            user: "ipaddoodler",
                            type: 2
                        },
                        details: {},
                        clickable: true
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.INTERCOM_ACTION_CELL]: {
                variants: [{
                    content: {
                        //title: GUI.TableViewV2.CellType.Special.INTERCOM_ACTION_CELL,
                        icon: Icon.Intercom.CONNECT
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.Special.INTERCOM_ACTION_CELL,
                        icon: Icon.Intercom.CONNECT
                    }
                }, {
                    content: {
                        isPlaceholder: true
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.COMFORT_ACTION]: {
                variants: [{
                    content: {
                        title: GUI.TableViewV2.CellType.Special.COMFORT_ACTION
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.Special.COMFORT_ACTION,
                        textColor: globalStyles.colors.brand
                    }
                }, {
                    content: {
                        icon: Icon.HEART
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.Special.COMFORT_ACTION,
                        tintColor: globalStyles.colors.purple
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.Special.COMFORT_ACTION,
                        textColor: globalStyles.colors.brand,
                        tintColor: globalStyles.colors.purple
                    }
                }, {
                    content: {
                        icon: Icon.HEART,
                        tintColor: globalStyles.colors.purple
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.Special.COMFORT_ACTION,
                        class: "thinking"
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.Special.COMFORT_ACTION,
                        tintColor: globalStyles.colors.purple,
                        class: "thinking"
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.COMFORT_STATE]: {
                variants: [{
                    content: {
                        title: GUI.TableViewV2.CellType.Special.COMFORT_STATE,
                        subtitle: "subtitle",
                        stateText: "stateText"
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.Special.COMFORT_STATE,
                        subtitle: "subtitle",
                        stateText: "stateText",
                        color: globalStyles.colors.purple
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.Special.COMFORT_STATE,
                        subtitle: "subtitle",
                        stateText: "stateText",
                        subtitleColor: globalStyles.colors.yellow_fixed
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.Special.COMFORT_STATE,
                        subtitle: "subtitle",
                        stateText: "stateText",
                        subtitleColor: globalStyles.colors.yellow_fixed,
                        stateTextColor: globalStyles.colors.blue
                    }
                }, {
                    content: {
                        title: `${GUI.TableViewV2.CellType.Special.COMFORT_STATE} with popup`,
                        subtitle: "subtitle",
                        stateText: "The stateText is very long, so there will be a popup",
                        subtitleColor: globalStyles.colors.yellow_fixed,
                        stateTextColor: globalStyles.colors.blue,
                        stateTextPopup: true
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.GROUP_CARD]: {
                variants: [{
                    content: {
                        group: ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.ROOM)[0],
                        displayAsCell: true
                    }
                }, {
                    content: {
                        group: ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.ROOM)[0],
                        displayAsCell: true,
                        useSimpleDesign: true
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.COLLECTION]: {
                variants: [{
                    content: {
                        title: GUI.TableViewV2.CellType.Special.COLLECTION
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.Special.COLLECTION,
                        iconSrc: Icon.SUCCESS
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.Special.COLLECTION,
                        iconSrc: Icon.SUCCESS,
                        iconColor: globalStyles.colors.purple
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.Special.COLLECTION,
                        textColor: globalStyles.colors.brand,
                        iconSrc: Icon.SUCCESS,
                        iconColor: globalStyles.colors.purple
                    }
                }, {
                    content: {
                        title: `${GUI.TableViewV2.CellType.Special.COLLECTION} with MS Icon`,
                        textColor: globalStyles.colors.brand,
                        iconSrc: ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.ROOM)[0].image,
                        msIcon: true,
                        iconColor: globalStyles.colors.purple
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.AUTOPILOT_HISTORY_CELL]: {
                variants: [{
                    content: {
                        title: GUI.TableViewV2.CellType.Special.AUTOPILOT_HISTORY_CELL,
                        subtitle: "subtitle",
                        time: moment().format("LT")
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.MOOD_CELLV2]: {
                variants: [{
                    content: {
                        title: GUI.TableViewV2.CellType.Special.MOOD_CELLV2,
                        id: 999,
                        showButton: false,
                        buttonSrc: Icon.TRANSLUCENT_ADD,
                        selectable: true
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.Special.MOOD_CELLV2,
                        id: 999,
                        showButton: true,
                        buttonSrc: Icon.TRANSLUCENT_ADD,
                        selectable: true,
                        iconSrc: Icon.IRCV2.REASON.PRESENCE
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.Special.MOOD_CELLV2,
                        id: 999,
                        showButton: true,
                        buttonSrc: Icon.TRANSLUCENT_ADD,
                        selectable: true,
                        iconSrc: Icon.Light.ALARM_CLOCK
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.MOOD_CELL]: {
                variants: [{
                    content: {
                        title: GUI.TableViewV2.CellType.Special.MOOD_CELL,
                        id: 99,
                        showButton: true,
                        isMulti: false,
                        buttonSrc: Icon.SETTINGS,
                        selectable: true,
                        isFav: true,
                        iconSrc: Icon.Light.ALARM_CLOCK
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.Special.MOOD_CELL,
                        id: 99,
                        showButton: true,
                        isMulti: false,
                        buttonSrc: Icon.SETTINGS,
                        selectable: true,
                        isFav: false,
                        iconSrc: Icon.Light.ALARM_CLOCK
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.SAVE_SCENE_CELL]: {
                variants: [{
                    content: {
                        placeholder: _("controls.lightcontroller.scene.not-assigned"),
                        selected: false,
                        showButton: false,
                        selectable: true,
                        autoFocus: false,
                        [GUI.LxInputEnum.REGEX]: Regex.NAME,
                        disableInput: false
                    }
                }, {
                    content: {
                        placeholder: _("controls.lightcontroller.scene.not-assigned"),
                        selected: false,
                        showButton: false,
                        selectable: true,
                        autoFocus: false,
                        [GUI.LxInputEnum.REGEX]: Regex.NAME,
                        disableInput: true
                    }
                }, {
                    content: {
                        override: true,
                        value: "Value",
                        selected: false,
                        showButton: false,
                        selectable: true,
                        autoFocus: false,
                        [GUI.LxInputEnum.REGEX]: Regex.NAME,
                        disableInput: true
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.SCENE_CELL]: {
                variants: [{
                    content: {
                        title: GUI.TableViewV2.CellType.Special.SCENE_CELL,
                        selected: false,
                        showButton: false
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.Special.SCENE_CELL,
                        selected: false,
                        showButton: false
                    }
                }, {
                    content: {
                        title: GUI.TableViewV2.CellType.Special.SCENE_CELL,
                        selected: true,
                        showButton: true,
                        buttonSrc: Icon.SUCCESS
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.COLOR_PICKER_FAVORITES]: {
                variants: [{
                    content: {
                        changingAllowed: true,
                        activeColor: LxColorUtils.createColorObjectFromColorString(Color.RED),
                        colorsArray: [Color.BRAND_COLOR, globalStyles.colors.red, globalStyles.colors.blue, globalStyles.colors.purple].map(LxColorUtils.createColorObjectFromColorString)
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.COLOR_PICKER_BRIGHTNESS]: {
                variants: [{
                    content: {
                        title: GUI.TableViewV2.CellType.Special.COLOR_PICKER_BRIGHTNESS,
                        previewColor: LxColorUtils.createColorObjectFromColorString(Color.RED)
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.AUTOMATIC_DESIGNER_CELL]: {
                variants: [],
                depCheck: () => {
                    return false;
                },
                depNote: "Please view the cells in the Automatic Designer, mocking the data is too complicated here 😓"
            },
            [GUI.TableViewV2.CellType.Special.AUTOMATIC_DESIGNER_CONTAINER_CELL]: {
                variants: [],
                depCheck: () => {
                    return false;
                },
                depNote: "Please view the cells in the Automatic Designer, mocking the data is too complicated here 😓"
            },
            [GUI.TableViewV2.CellType.Special.CARD]: {
                variants: [],
                depCheck: () => {
                    return false;
                },
                depNote: "This is a card, not a cell"
            },
            [GUI.TableViewV2.CellType.ROUND_COMFORT_ACTION]: {
                variants: [],
                depCheck: () => {
                    return false;
                },
                depNote: "This is a card, not a cell"
            },
            [GUI.TableViewV2.CellType.CHECKABLE_BUTTON_MEDIA]: {
                variants: [],
                depCheck: () => {
                    return false;
                },
                depNote: "This is a card, not a cell"
            },
            [GUI.TableViewV2.CellType.ROUND_COMFORT_ACTION]: {
                variants: [],
                depCheck: () => {
                    return false;
                },
                depNote: "This is a card, not a cell"
            },
            [GUI.TableViewV2.CellType.Special.STEAK_TIMER_CELL]: {
                variants: [{
                    content: {
                        timerInfo: {
                            duration: 64,
                            active: true
                        },
                        timerAlarm: {
                            time: 69,
                            ringing: true,
                            text: "Ringing..."
                        },
                        remainingTime: 69,
                        uuidAction: "Mock UUID"
                    }
                }, {
                    content: {
                        timerInfo: {
                            duration: 64,
                            active: false
                        },
                        timerAlarm: {
                            time: 69,
                            ringing: false,
                            text: "Ringing..."
                        },
                        remainingTime: 69,
                        uuidAction: "Mock UUID"
                    }
                }]
            },
            [GUI.TableViewV2.CellType.Special.STEAK_SENSOR_CELL]: {
                variants: [{
                    content: {
                        currentTemp: 42,
                        sensorColor: globalStyles.colors.green,
                        sensor: {
                            name: "Green",
                            targetTemperature: 69
                        },
                        sensorAlarmText: {
                            text: null,
                            ringing: false
                        }
                    }
                }, {
                    content: {
                        currentTemp: 42,
                        sensorColor: globalStyles.colors.yellow_fixed,
                        sensor: {
                            name: "Yellow",
                            targetTemperature: 69
                        },
                        sensorAlarmText: {
                            text: "ALARM!!!",
                            ringing: false
                        }
                    }
                }, {
                    content: {
                        currentTemp: 42,
                        sensorColor: globalStyles.colors.yellow_fixed,
                        sensor: {
                            name: "Yellow",
                            targetTemperature: 69
                        },
                        sensorAlarmText: {
                            text: "ALARM!!!",
                            ringing: true
                        }
                    }
                }]
            },
            [GUI.TableViewV2.CellType.IRCV2021TimeLineCell]: (() => {
                let ircV2021s = ActiveMSComponent.getStructureManager().getControlsByType(ControlType.I_Room_V2021),
                    obj = {
                        variants: [],
                        depCheck: () => {
                            return !!ircV2021s.length;
                        },
                        depNote: `Required Control of type ${ControlType.I_Room_V2021} is missing`
                    };

                if (obj.depCheck()) {
                    obj.variants.push({
                        content: {
                            control: ircV2021s[0],
                            parentControl: ircV2021s[0].subControls[Object.keys(ircV2021s[0].subControls)[0]]
                        }
                    });
                }

                return obj;
            })(),
            [GUI.TableViewV2.CellType.IRCV2TimeLineCell]: (() => {
                let ircVXs = [...ActiveMSComponent.getStructureManager().getControlsByType(ControlType.I_Room_V2), ...ActiveMSComponent.getStructureManager().getControlsByType(ControlType.I_Room_V2021)],
                    obj = {
                        variants: [],
                        depCheck: () => {
                            return !!ircVXs.length;
                        },
                        depNote: `Required Control of type ${ControlType.I_Room_V2} is missing`
                    };

                if (obj.depCheck()) {
                    obj.variants.push({
                        content: {
                            control: ircVXs[0],
                            parentControl: ircVXs[0].subControls[Object.keys(ircVXs[0].subControls)[0]]
                        }
                    });
                }

                return obj;
            })(),
            [GUI.TableViewV2.CellType.IRCV2TemperatureCell]: {
                variants: [{
                    content: {
                        color: globalStyles.colors.green,
                        title: GUI.TableViewV2.CellType.IRCV2TemperatureCell,
                        detail: {
                            text: "69 °C"
                        },
                        prominent: {
                            title: "27 °C",
                            actions: [{
                                icon: Icon.PLUS,
                                color: Color.STATE_INACTIVE,

                                action() {
                                }

                            }, {
                                icon: Icon.MINUS,
                                color: Color.STATE_INACTIVE,

                                action() {
                                }

                            }],
                            state: {
                                iconSrc: Icon.IRCV2.HEAT_COOL_STATE,

                                actionFn() {
                                },

                                color: globalStyles.colors.stateActive
                            }
                        },
                        info: {
                            text: _("controls.ircv2.comfort-active")
                        }
                    }
                }]
            }
        }; //endregion Static

        constructor() {
            super();
            this.cellsDisabled = false;
            this._cellTypes = Object.values(GUI.TableViewV2.CellType).map(type => {
                if (type instanceof Object) {
                    return Object.values(type);
                } else {
                    return type;
                }
            }).flat();
        }

        getTableView() {
            return new GUI.EditableTableView(this.tableViewDataSource, this.tableViewDelegate);
        }

        titleBarText() {
            return "TableViewShowCase";
        }

        #displayOptions = [
            "Normal",
            "Disabled",
            "Sorting",
            "Removable",
            "Sortable • Removable",
            "Disabled • Sortable • Removable"
        ]

        #currentDisplayOption = this.#displayOptions[0];

        set setCurrentDisplayOption(value) {
            this.#currentDisplayOption = value;
            let idx = this.#displayOptions.indexOf(value);
            switch (idx) {
                case 0:
                case 1:
                    this.cellsDisabled = !!idx;
                    break;

                case 5:
                    this.cellsDisabled = true;
                    break;

                default:
                    this.cellsDisabled = false;
            }

            this.reloadTable().then(() => {
                switch (idx) {
                    case 2:
                        this.tableView.setEditMode(...this.#duplicateEditMapForAllSections({
                            sortable: true
                        }));
                        break;

                    case 3:
                        this.tableView.setEditMode(...this.#duplicateEditMapForAllSections({
                            removable: true
                        }));
                        break;

                    case 4:
                    case 5:
                        this.tableView.setEditMode(...this.#duplicateEditMapForAllSections({
                            editable: true,
                            removable: true
                        }));
                        break;

                    default:
                        this.tableView.setEditMode(...this.#duplicateEditMapForAllSections({
                            editable: false,
                            removable: false
                        }));
                }
            });
        }

        setTableContent() {
            let blacklistedCnt = 0,
                missingMockCnt = 0,
                renderedTypeCnt = 0,
                renderedCellCnt = 0;
            this.tableContent = [
                {
                    headerElement: ReactWrapper.React.createElement(LxReactQuickSelect, {
                        options: this.#displayOptions,
                        selectedValue: this.#currentDisplayOption,
                        onOptionSelected: value => {
                            this.setCurrentDisplayOption = value;
                        }
                    })
                },
                ...this._cellTypes.filter(cellType => {
                    if (TableViewShowCase.CellTypeBlackList.some(compare => {
                        let blacklisted;

                        if (typeof compare === "string") {
                            blacklisted = compare === cellType;
                        } else {
                            blacklisted = compare.test(cellType);
                        }

                        if (blacklisted) {
                            blacklistedCnt++;
                        }

                        return blacklisted;
                    })) {
                        return false;
                    } else if (cellType in TableViewShowCase.MockData) {
                        renderedTypeCnt++;
                        return true;
                    } else {
                        developerAttention(`Found cell of type ${cellType} without mock data, won't render it!`);
                        missingMockCnt++;
                        return false;
                    }
                }).sort().map(cellType => {
                    let {
                        variants,
                        depCheck,
                        depNote
                    } = TableViewShowCase.MockData[cellType] || {
                        variants: []
                    };

                    if (typeof depCheck === "function" && depCheck() || typeof depCheck === "undefined") {
                        return {
                            headerTitle: cellType,
                            rows: variants.map((mock = {
                                content: {}
                            }) => {
                                renderedCellCnt++;
                                mock.type = cellType;
                                mock.content.disabled = this.cellsDisabled;
                                return mock;
                            })
                        };
                    } else {
                        return {
                            headerTitle: cellType,
                            footer: {
                                iconSrc: Icon.WARNING,
                                message: depNote || "Dependencies not fulfilled!"
                            }
                        };
                    }
                })
            ];
            developerAttention(`${renderedTypeCnt} types rendered using ${renderedCellCnt} cells, ${blacklistedCnt} blacklisted, ${missingMockCnt} still missing`, globalStyles.colors.purple);
            return super.setTableContent(...arguments);
        }

        didRequestRemovingCell() {
            GUI.Notification.createGeneralNotification({
                title: "Imagine the cell is gone now...",
                removeAfter: 5
            }, NotificationType.INFO);
            return false;
        }

        didRequestCheckingCell() {
            return NavigationComp.showPopup({
                title: "Allow change?",
                message: "This is a showcase of the \"didRequestCheckingCell\" functionality",
                buttonOk: "👍",
                buttonCancel: "👎"
            }).then(() => true, () => Q.resolve(false))
        }

        #duplicateEditMapForAllSections(editMap) {
            return this.tableContent.map((_, sectionIdx) => {
                return {
                    sectionIdx: sectionIdx,
                    ...editMap
                }
            })
        }

    }

    return TableViewShowCase;
});
