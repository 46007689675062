import { useState, useMemo, useCallback, useEffect, useRef, useContext } from "react";
import { TouchableOpacity, View, Dimensions, Animated } from "react-native";

import globalStyles from "GlobalStyles";
import {
    LxReactText,
    StatisticDataPointUnit,
    useIsInForeground,
    Calendar,
    WheelPicker,
    DraggableScrollView,
    useBorderColor,
    AmbientContext
} from "LxComponents"

import { Svg, Path } from "react-native-svg";
import { StyleSheet } from "react-native-web"
import { EfmViewType } from "../../Controls/energyFlowMonitorControl/efmUtilities";

import { useRequest } from "ahooks";

export const DateViewType = {
    Live: "live",
    Day: "day",
    Week: "week",
    Month: "month",
    Year: "year",
    Lifetime: "lifetime"
}

export class DateViewTypeUtils {
    static initializeViewInfo = (vt = DateViewType.Live, ts = ActiveMSComponent.getMiniserverUnixUtcTimestamp(), forceCustomLiveTs = false) => {
        let newViewInfo = {
            vt: vt,
            ts: ts
        }
        if ((vt === DateViewType.Live || vt === DateViewType.Lifetime) && !forceCustomLiveTs) {
            newViewInfo.ts = ActiveMSComponent.getMiniserverUnixUtcTimestamp()
        }
        return { ...newViewInfo, ...DateViewTypeUtils.getRange(vt, newViewInfo.ts) };
    }

    static getRange = (vt, ts) => {
        let rangeId,
            range;

        switch (vt) {
            case DateViewType.Lifetime:
            case DateViewType.Year:
                rangeId = "year";
                break;
            case DateViewType.Month:
                rangeId = "month";
                break;
            case DateViewType.Week:
                rangeId = "week";
                break;
            case DateViewType.Live:
            case DateViewType.Day:
            default:
                rangeId = "day";
                break;
        }
        range = ActiveMSComponent.getStartAndEndOfAsUnixUtcTimestamp(ts, rangeId);

        if (vt === DateViewType.Lifetime) {
            // 1230768000 ==> 1.1.2009
            range.start = 1230768000;
            range.end = ActiveMSComponent.getMiniserverUnixUtcTimestamp();
        }

        return range;
    }

    static getStatisticDiffDataPointUnit(vt) {
        let dataPointUnit;
        switch (vt) {
            case DateViewType.Lifetime:
                dataPointUnit = StatisticDataPointUnit.YEAR;
                break;
            case DateViewType.Year:
                dataPointUnit = StatisticDataPointUnit.MONTH;
                break;
            case DateViewType.Month:
            case DateViewType.Week:
                dataPointUnit = StatisticDataPointUnit.DAY;
                break;
            case DateViewType.Live:
            case DateViewType.Day:
            default:
                dataPointUnit = StatisticDataPointUnit.HOUR;
                break;
        }
        return dataPointUnit;
    }
}

export default function DateViewTypeSelector({
    onUpdateVt: __immediate__onUpdateVt = () => { },
    value,
    initialViewType,
    initialTs,
    autoUpdateTs = false,
    availableViewTypes = Object.values(DateViewType),
    allowPastLive = false,
    liveTypeName,
    borderBottomColor,
    useFullWidth = false,
    minUnix,
    maxUnix = ActiveMSComponent.getMiniserverUnixUtcTimestamp()
}) {
    const ensureVtAvailability = (input = DateViewType.Live) => {
        if (availableViewTypes.some(avVt => input === avVt)) {
            return input;
        } else {
            return availableViewTypes[0];
        }
    }
    const isInForeground = useIsInForeground();

    const [selectedVt, setSelectedVt] = useState(ensureVtAvailability(initialViewType || DateViewType.Live));
    
    const [selectedTs, setSelectedTs] = useState(initialTs || ActiveMSComponent.getMiniserverUnixUtcTimestamp());
    // Used to show using components whether a change has been triggered by manual selection or by the automatic update
    const [manuallySelectedTs, setManuallySelectedTs] = useState(initialTs || ActiveMSComponent.getMiniserverUnixUtcTimestamp());
    const [canMoveForward, setCanMoveForward] = useState(false);
    const [canMoveBackwards, setCanMoveBackwards] = useState(true);
    const [calendarVisible, setCalendarVisible] = useState(false);

    const vtForFeatureCheck = (vt) => {
        if (allowPastLive && vt === DateViewType.Live) {
            return DateViewType.Day;
        }
        return vt;
    }

    /**
     * Helper FN to format TS (as number or as moment obj) for logging purposes
     * @param ts either a unix-ts or a moment obj.
     * @returns {string}
     */
    const formatTs = (ts) => {
        let momObj;
        if (typeof ts === "number") {
            momObj = moment.unix(ts);
        } else {
            momObj = ts;
        }
        return momObj.format(DateType.CSV);
    }

    /**
     * Helper to keep intTs.current & selectedTs in sync.
     * @param newVal
     */
    const intUpdateTs = (newVal) => {
        Debug.DateViewTypeSelector && console.log(DateViewTypeSelector.name, "intUpdateTs: " + formatTs(newVal));
        setSelectedTs(newVal);
        intTs.current = newVal;
    }

    /**
     * Andi: Prevents the possibility of setting a time in the future
     */
    useEffect(() => {
        const maxMoment = moment.unix(maxUnix);
        const selected = moment.unix(selectedTs);
        if (selected.isAfter(maxMoment, "second")) {
            Debug.DateViewTypeSelector && console.log(DateViewTypeSelector.name, `useEffect[selectedTs] - Timestamp set to future, resetting to maxMoment. selectedTs: ${formatTs(selected)}, maxMoment: ${formatTs(maxMoment)} `);
            intUpdateTs(maxMoment.unix());
        }
    }, [selectedTs]);

    const { run: onUpdateVt } = useRequest(infoObj => {
        __immediate__onUpdateVt({ ...infoObj });
    }, {
        debounceLeading: true,
        debounceTrailing: true,
        debounceWait: 250,
        manual: true
    });

    useEffect(() => {
        const maxMoment = moment.unix(maxUnix);
        let range = DateViewTypeUtils.getRange(selectedVt, selectedTs);
        let moEnd = moment.unix(range.end);
        let moStart = moment.unix(range.start);
        Debug.DateViewTypeSelector && console.log(DateViewTypeSelector.name, `useEffect[selectedVt,selectedTs] - vt (${selectedVt}) or timestamp (${formatTs(selectedTs)}) changed! inform onUpdateVt `);

        onUpdateVt({ ts: selectedTs, vt: selectedVt, manuallySelectedTs, ...range });

        setCanMoveForward(moEnd.isBefore(maxMoment));

        if (minUnix) {
            setCanMoveBackwards(moment.unix(minUnix).isBefore(moStart));
        } else {
            setCanMoveBackwards(true);
        }
    }, [selectedVt, selectedTs, minUnix]);

    useEffect(() => {
        Debug.DateViewTypeSelector && value ? console.log(`useEffect[value] - value variable exists, using set values ${JSON.stringify({...value, computedTS: formatTs(value.ts)})}`) : console.log(`useEffect[value] - value variable is undefined, using placeholder values value.ts: 1230768000 and value.vt: DateViewType.Live`);
        
        Debug.DateViewTypeSelector && console.log(`useEffect[value] - value.ts = ${formatTs((value && value.ts) ?? 1230768000)}, value.vt: ${(value && value.vt) ?? DateViewType.Live} `);
        if (value && selectedVt !== value.vt) {
            console.log(DateViewTypeSelector.name, `   updating internal vt! ${selectedVt}`);
            changeToViewType(value.vt, true);
        }
        if (value && selectedTs !== value.ts) {
            // a second may differ, this would cause an endless loop!
            if (Math.abs(selectedTs - value.ts) > 20) {
                Debug.DateViewTypeSelector && console.log(DateViewTypeSelector.name, `   updating internal ts! ${formatTs(selectedTs)}`);
                intUpdateTs(value.ts);
            } else {
                Debug.DateViewTypeSelector && console.log(DateViewTypeSelector.name, `   NOT updating internal ts! ${formatTs(selectedTs)}`);
            }
        }
    }, [value])

    const timer = useRef(null);
    const intTs = useRef(selectedTs); // otherwise the timer would use the initial selectedTs value (cannot make useEffect dependent on selectedTs)
    const isToday = useMemo(() => {
        return moment.unix(intTs.current).diff(moment.unix(ActiveMSComponent.getMiniserverUnixUtcTimestamp()), "day") === 0;
    }, [intTs.current]);

    useEffect(() => {
        if (selectedVt === DateViewType.Live && autoUpdateTs && isInForeground && isToday) {
            if (!timer.current) {
                timer.current = setInterval(() => {
                    let now = moment.unix(ActiveMSComponent.getMiniserverUnixUtcTimestamp());
                    let last = moment.unix(intTs.current);
                    if (now.isAfter(last, "minute")) {
                        Debug.DateViewTypeSelector && console.log(DateViewTypeSelector.name, `useEffect[selectedVt] - timer, minute has passed! selectedTs: ${formatTs(last)}, now: ${formatTs(now)}`);
                        intUpdateTs(now.unix());
                    }
                }, 5000);
            }
        } else if (timer.current) {
            clearInterval(timer.current);
            timer.current = null;
        }

        return () => {
            clearInterval(timer.current);
            timer.current = null;
        }
    }, [selectedVt, isInForeground, isToday]);

    const moveTimestamp = useCallback((factor) => {
        let selectedMoment = moment(selectedTs * 1000);
        Debug.DateViewTypeSelector && console.log(DateViewTypeSelector.name, "moveTimestamp: " + factor + " " + selectedVt);
        Debug.DateViewTypeSelector && console.log(DateViewTypeSelector.name, "      from: " + formatTs(selectedMoment));
        let checkViewType = vtForFeatureCheck(selectedVt);
        switch (checkViewType) {
            case DateViewType.Day:
            case DateViewType.Week:
            case DateViewType.Month:
            case DateViewType.Year:
                selectedMoment.add(factor, checkViewType);

                // create separate moment obj, don't modify the original one passed to setSelectedTs!
                let verifyMoment = moment(selectedMoment).add(factor, checkViewType);
                setCanMoveForward(!verifyMoment.isAfter(moment(ActiveMSComponent.getMiniserverUnixUtcTimestamp() * 1000), checkViewType));

                setManuallySelectedTs(selectedMoment.unix())
                intUpdateTs(selectedMoment.unix())
                Debug.DateViewTypeSelector && console.log(DateViewTypeSelector.name, "      to: " + formatTs(selectedMoment));
                break;
            case DateViewType.Lifetime:
            case DateViewType.Live:
            default:
                break;
        }
    }, [selectedVt, selectedTs]);

    const decrementTimestamp = useCallback(() => {
        return moveTimestamp(-1);
    }, [moveTimestamp]);

    const incrementTimestamp = useCallback(() => {
        return moveTimestamp(1);
    }, [moveTimestamp]);

    const changeToViewType = (newVt, ignoreTs) => {
        if (ensureVtAvailability(newVt) !== newVt) {
            console.warn(DateViewTypeSelector.name, "Cannot change to VT " + newVt + " - not part of available list! " + availableViewTypes.join(","));
            return;
        }
        if (vtForFeatureCheck(newVt) === DateViewType.Live && !ignoreTs) {
            intUpdateTs(ActiveMSComponent.getMiniserverUnixUtcTimestamp());
        }
        setSelectedVt(newVt);
    }

    const textForViewType = (vt) => {
        let text;
        switch (vt) {
            case DateViewType.Live:
                if (allowPastLive && liveTypeName) {
                    text = liveTypeName;
                } else {
                    text = _("live");
                }
                break;
            case DateViewType.Day:
                text = _("dateTime.day");
                break;
            case DateViewType.Week:
                text = _("dateTime.week");
                break;
            case DateViewType.Month:
                text = _("dateTime.month");
                break;
            case DateViewType.Year:
                text = _("dateTime.year");
                break;
            case DateViewType.Lifetime:
                text = _("lifetime");
                break;
            default:
                console.warn("No handling for viewType '" + vt + "'");
                break;
        }

        return text;
    }

    const viewTypeButtons = useMemo(() => {
        return availableViewTypes.map((vt, idx) => {
            const onPress = () => changeToViewType(vt),
                vtStyle = {
                    ...styles.ViewTypeButton,
                    backgroundColor: vt === selectedVt ? 'rgba(120, 120, 128, 0.36)' : 'transparent'
                }
            return (
                <TouchableOpacity
                    key={vt}
                    onPress={onPress}
                    style={[vtStyle, idx === availableViewTypes.length - 1 ? { marginRight: 0 } : null]}
                >
                    <LxReactText
                        style={styles.ViewTypeText}
                    >
                        {textForViewType(vt) || vt}
                    </LxReactText>
                </TouchableOpacity>
            )
        });
    }, [selectedVt, liveTypeName]);

    const dateString = useMemo(() => {
        let checkViewType = vtForFeatureCheck(selectedVt);
        if (checkViewType === DateViewType.Live) {
            return ActiveMSComponent.getMomentFromUtcTimestamp(selectedTs).format(LxDate.getDateFormat(DateType.DateAndTimeShort));
        }
        if (checkViewType === DateViewType.Day) {
            return ActiveMSComponent.getMomentFromUtcTimestamp(selectedTs).format(LxDate.getDateFormat(DateType.ShortWeekdayAndDate));
        }
        if (checkViewType === DateViewType.Week) {
            const startOfWeek = ActiveMSComponent.getMomentFromUtcTimestamp(selectedTs).startOf('isoWeek');
            const endOfWeek = ActiveMSComponent.getMomentFromUtcTimestamp(selectedTs).endOf('isoWeek');
            const dateFormat = LxDate.getDateFormat(DateType.Date);
            return `${startOfWeek.format(dateFormat)} - ${endOfWeek.format(dateFormat)}`;
        }
        if (checkViewType === DateViewType.Month) {
            return ActiveMSComponent.getMomentFromUtcTimestamp(selectedTs).format('MMMM YYYY');
        }
        if (checkViewType === DateViewType.Year) {
            return ActiveMSComponent.getMomentFromUtcTimestamp(selectedTs).format('YYYY');
        }
        // TODO: Handle Lifetime
        return '';

    }, [selectedVt, selectedTs]);

    const renderUpperContainer = () => {
        const windowWidth = Dimensions.get('window').width;
        const { isAmbientMode } = useContext(AmbientContext);
        const [scrollable, setScrollable] = useState();
        const leftBorderRadius = new Animated.Value(0);
        const rightBorderRadius = new Animated.Value(0);

        const animateLeftBorderRadius = (value, callback) => {
            Animated.timing(leftBorderRadius, {
                toValue: value,
                useNativeDriver: true,
                duration: 40,
            }).start(callback);
        };

        const animateRightBorderRadius = (value, callback) => {
            Animated.timing(rightBorderRadius, {
                toValue: value,
                useNativeDriver: true,
                duration: 40,
            }).start(callback);
        };

        useEffect(() => {
            if (scrollable) {
                leftBorderRadius.setValue(1);
                rightBorderRadius.setValue(0);
            } else {
                leftBorderRadius.setValue(1);
                rightBorderRadius.setValue(1);
            }
        }, [scrollable]);

        const viewStyle = {
            ...styles.UpperContainer,
            borderTopLeftRadius: leftBorderRadius.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 22],
            }),
            borderBottomLeftRadius: leftBorderRadius.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 22],
            }),
            borderTopRightRadius: rightBorderRadius.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 22],
            }),
            borderBottomRightRadius: rightBorderRadius.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 22],
            }),
        };

        const setBorderRadius = (event) => {
            const { contentOffset, contentSize, layoutMeasurement } = event.nativeEvent;

            if (contentOffset.x <= 0) {
                animateLeftBorderRadius(1);
                animateRightBorderRadius(0);
            } else if (Math.ceil(contentOffset.x) >= contentSize.width - layoutMeasurement.width) {
                animateLeftBorderRadius(0);
                animateRightBorderRadius(1);
            } else {
                animateLeftBorderRadius(0);
                animateRightBorderRadius(0);
            }
        }

        const handleOnContentSizeChange = (width) => {
            if (width > windowWidth || isAmbientMode) {
                setScrollable(true);
            } else {
                setScrollable(false);
            }
        }

        const draggableStyle = {
            flexDirection: 'row',
            justifySelf: "center",
            alignItems: "center",
            height: '100%'
        };
        if (availableViewTypes.length > 1) {
            return <Animated.View
                style={viewStyle}
            >
                <DraggableScrollView
                    horizontal={true}
                    onContentSizeChange={handleOnContentSizeChange}
                    onScroll={setBorderRadius}
                    scrollEventThrottle={16}
                    contentContainerStyle={draggableStyle}
                >
                    {viewTypeButtons}
                </DraggableScrollView>
            </Animated.View>
        } else {
            return null;
        }
    }

    const calendarInfo = useMemo(() => {
        const maxMoment = moment.unix(maxUnix);
        const maximumDate = {
            day: maxMoment.date(),
            month: maxMoment.month() + 1,
            year: maxMoment.year(),
        }
        const minMoment = minUnix ? moment.unix(minUnix) : moment.utc.call(this, [2009, 0, 1, 0, 0, 0]);
        const minimumDate = {
            day: minMoment.date(),
            month: minMoment.month() + 1,
            year: minMoment.year(),
        }
        let customDaysClassName = [];
        if (selectedVt === EfmViewType.Week) {
            const week = ActiveMSComponent.getMomentFromUtcTimestamp(selectedTs).startOf('isoweek'); // Why isoweek? Cause the start of the isoweek is Monday!
            for (var i = 0; i < 7; i++) {
                customDaysClassName.push({
                    day: week.date(),
                    month: week.month() + 1,
                    year: week.year(),
                    className: 'selectedWeekday' + (i === 0 ? ' firstWeekday' : i === 6 ? ' lastWeekday' : ''),
                });
                week.add(1, 'day')
            }
        }
        return {
            minimumDate,
            maximumDate,
            customDaysClassName,
        }
    }, [selectedTs, selectedVt])



    const monthYearPickerInfo = useMemo(() => {
        const maxMoment = moment.unix(maxUnix);
        const selectedMom = ActiveMSComponent.getMomentFromUtcTimestamp(selectedTs);

        const years = [];
        const months = [];

        const countMom = moment(maxMoment);

        const countMonthMom = moment(countMom).startOf('year');
        while (countMonthMom.isSame(countMom, 'year')) {
            months.push({ label: countMonthMom.format('MMMM') });
            countMonthMom.add(1, 'month');
        }

        years.push({ label: countMom.year().toString(), excludes: [] })
        countMom.subtract(1, 'year');

        const countExcludedMonthMom = moment(maxMoment);
        countExcludedMonthMom.add(1, 'month');
        while (countExcludedMonthMom.isSame(maxMoment, 'year')) {
            years[0].excludes.push([0, countExcludedMonthMom.month()]);
            countExcludedMonthMom.add(1, 'month');
        }

        while (countMom.year() >= 2009) {
            years.push({ label: countMom.year().toString() });
            countMom.subtract(1, 'year');
        }

        return {
            years: { dataset: years.reverse(), selectedIdx: years.findIndex(({ label }) => label === selectedMom.year().toString()) },
            months: { dataset: months, selectedIdx: selectedMom.month() },
        }
    }, [selectedTs, selectedVt])

    const toggleCalendar = useCallback(() => {
        return setCalendarVisible(!calendarVisible)
    }, [calendarVisible]);

    const showCalendar = useCallback(() => {
        return setCalendarVisible(true)
    }, []);

    const hideCalendar = useCallback(() => {
        return setCalendarVisible(false)
    }, []);

    const onCalendarChange = useCallback(date => {
        const mom = ActiveMSComponent.getMomentFromUtcTimestamp(selectedTs);
        mom.date(date.day).month(date.month - 1).year(date.year);

        let verifyMoment = moment(mom).add(1, selectedVt);
        setCanMoveForward(!verifyMoment.isAfter(moment(ActiveMSComponent.getMiniserverUnixUtcTimestamp() * 1000), selectedVt));

        if (mom.unix() === selectedTs) {
            // the current day was selected, so datepicker has to be closed manually, since automatic closing is based on a value change
           hideCalendar();
           return
        }
        setManuallySelectedTs(mom.unix());
        setSelectedTs(mom.unix());
    }, [selectedTs, selectedVt]);


    const calendarLocaleAdjustment = useMemo(() => {
        return {
            getToday: () => {
                const mom = ActiveMSComponent.getMomentFromUtcTimestamp(selectedTs)
                return {
                    day: mom.date(),
                    month: mom.month() + 1,
                    year: mom.year(),
                }
            }
        }
    }, [selectedTs]);

    const onWheelPickerSelect = useCallback(data => {
        const mom = ActiveMSComponent.getMomentFromUtcTimestamp(selectedTs);

        mom.year(Number.parseInt(data[selectedVt === EfmViewType.Month ? 1 : 0].value.label))
        if (selectedVt === EfmViewType.Month) {
            mom.month(data[0].idx);
        }

        let verifyMoment = moment(mom).add(1, selectedVt);
        setCanMoveForward(!verifyMoment.isAfter(moment(ActiveMSComponent.getMiniserverUnixUtcTimestamp() * 1000), selectedVt));

        setManuallySelectedTs(mom.unix());
        setSelectedTs(mom.unix());
        return hideCalendar();
    }, [selectedTs, selectedVt]);

    const borderColor = borderBottomColor ?? useBorderColor();

    const moveTimestampButtonFutureStyle = useMemo(() => {
        return {
            ...styles.MoveTimestampButtonFuture,
            opacity: canMoveForward ? 1 : 0.25
        }
    }, [canMoveForward])

    const moveTimestampButtonPastStyle = useMemo(() => {
        return {
            ...styles.MoveTimestampButtonPast,
            opacity: canMoveBackwards ? 1 : 0.25
        }
    }, [canMoveBackwards])

    useEffect(hideCalendar, [selectedVt, selectedTs]);

    return (
        <View style={StyleSheet.flatten([styles.RootCntr, { borderBottomColor: borderColor }])}>
            {renderUpperContainer()}
            <View
                style={{
                    ...styles.LowerContainer,
                    ...(!useFullWidth ? {
                        paddingLeft: globalStyles.fontSettings.sizes.regular,
                        paddingRight: globalStyles.fontSettings.sizes.regular
                    } : {}),
                    justifyContent: vtForFeatureCheck(selectedVt) === DateViewType.Live ? 'center' : 'space-between'
                }}
            >
                {
                    vtForFeatureCheck(selectedVt) !== DateViewType.Live && selectedVt !== DateViewType.Lifetime ? (
                        <TouchableOpacity
                            onPress={decrementTimestamp}
                            style={moveTimestampButtonPastStyle}
                            disabled={!canMoveBackwards}
                        >
                            <Svg viewBox="0 0 7 15" style={styles.MoveIcons}>
                                <Path d="M6.83161 7.05726C7.05613 7.30985 7.05613 7.69049 6.83161 7.94308L1.49827 13.9431C1.25366 14.2183 0.832279 14.2431 0.557091 13.9984C0.281902 13.7538 0.257115 13.3324 0.501727 13.0573L5.44136 7.50017L0.501727 1.94308C0.257115 1.66789 0.281902 1.24651 0.557091 1.0019C0.832279 0.757285 1.25366 0.782072 1.49827 1.05726L6.83161 7.05726Z" fill="#EBEBF5" />
                            </Svg>
                        </TouchableOpacity>
                    ) : null
                }
                {
                    dateString !== '' ? (
                        <TouchableOpacity
                            disabled={
                                [EfmViewType.Day, EfmViewType.Week, EfmViewType.Month, EfmViewType.Year].indexOf(selectedVt) === -1 &&
                                !(EfmViewType.Actual === selectedVt && allowPastLive)
                            }
                            onPress={toggleCalendar}
                            style={styles.DateSelectorButton}
                        >
                            <LxReactText style={styles.CurrDateText}>
                                {dateString}
                            </LxReactText>
                        </TouchableOpacity>
                    ) : null
                }
                {
                    vtForFeatureCheck(selectedVt) !== DateViewType.Live && selectedVt !== DateViewType.Lifetime ? (
                        <TouchableOpacity
                            onPress={incrementTimestamp}
                            style={moveTimestampButtonFutureStyle}
                            disabled={!canMoveForward}
                        >
                            <Svg viewBox="0 0 7 15" style={styles.MoveIcons}>
                                <Path d="M6.83161 7.05726C7.05613 7.30985 7.05613 7.69049 6.83161 7.94308L1.49827 13.9431C1.25366 14.2183 0.832279 14.2431 0.557091 13.9984C0.281902 13.7538 0.257115 13.3324 0.501727 13.0573L5.44136 7.50017L0.501727 1.94308C0.257115 1.66789 0.281902 1.24651 0.557091 1.0019C0.832279 0.757285 1.25366 0.782072 1.49827 1.05726L6.83161 7.05726Z" fill="#EBEBF5" />
                            </Svg>
                        </TouchableOpacity>
                    ) : null
                }
            </View>
            {
                calendarVisible && (
                    [EfmViewType.Day, EfmViewType.Week].indexOf(selectedVt) > -1 ||
                    selectedVt === EfmViewType.Actual && allowPastLive
                ) ? (
                    <View style={styles.Calendar}>
                        <Calendar
                            localeAdjustment={calendarLocaleAdjustment}
                            onChange={onCalendarChange}
                            onCanceled={hideCalendar}
                            {...calendarInfo}
                        />
                    </View>
                ) : null
            }
            {
                calendarVisible && [EfmViewType.Month, EfmViewType.Year].indexOf(selectedVt) > -1 ? (
                    <View style={styles.Calendar}>
                        <WheelPicker
                            key={`wheely-${selectedVt}`}
                            data={
                                selectedVt === EfmViewType.Month ?
                                    [monthYearPickerInfo.months, monthYearPickerInfo.years] :
                                    [monthYearPickerInfo.years] // don't include excludes info
                            }
                            onSelect={onWheelPickerSelect}
                        />
                    </View>
                ) : null
            }
        </View>
    );
}

const styles = {
    RootCntr: {
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        ...globalStyles.borders.bottom,
        backgroundColor: 'transparent',
        marginBottom: 1, // ensures the bottom line is properly shown
        zIndex: 1,
    },
    ViewTypeText: {
        fontFamily: globalStyles.fontSettings.families.regular,
        color: 'white',
        fontSize: globalStyles.fontSettings.sizes.regular,
        lineHeight: 1,
    },
    ViewTypeButton: {
        height: 32,
        paddingTop: globalStyles.fontSettings.sizes.regular,
        paddingBottom: globalStyles.fontSettings.sizes.regular,
        paddingLeft: globalStyles.spacings.gaps.big,
        paddingRight: globalStyles.spacings.gaps.big,
        borderRadius: 20,
        marginRight: globalStyles.spacings.gaps.small,
    },
    UpperContainer: {
        backgroundColor: 'rgba(116, 116, 128, 0.18)',
        height: 40,
        paddingLeft: 4,
        paddingRight: 4,
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '100%',
    },
    LowerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: 65,
        maxWidth: 480,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    MoveTimestampButtonPast: {
        backgroundColor: 'rgba(120, 120, 128, 0.36)',
        width: 32, height: 32,
        borderRadius: 16,
        alignItems: 'center',
        justifyContent: 'center',
        transform: [{ scaleX: '-1' }],
    },
    DateSelectorButton: {
        paddingTop: globalStyles.spacings.gaps.verySmall,
        paddingBottom: globalStyles.spacings.gaps.verySmall,
        paddingLeft: globalStyles.spacings.gaps.big,
        paddingRight: globalStyles.spacings.gaps.big,
        backgroundColor: 'rgba(120, 120, 128, 0.36)',
        borderRadius: 20,
        height: 33,
    },
    CurrDateText: {
        color: 'white',
        fontFamily: globalStyles.fontSettings.families.regular,
        fontSize: globalStyles.fontSettings.sizes.medium
    },
    MoveTimestampButtonFuture: {
        backgroundColor: 'rgba(120, 120, 128, 0.36)',
        width: 32, height: 32,
        borderRadius: 16,
        alignItems: 'center',
        justifyContent: 'center'
    },
    MoveIcons: { width: 7, height: 15 },
    Calendar: {
        width: '100%',
        position: 'absolute',
        top: '95%',
        alignItems: 'center',
    }
}
