"use strict";

define(["ControlInfoView", "AtheneMediaHandler", "AtheneControlEnums", "ActivityImage"], function (ControlInfoView, MediaHandler, AtheneEnums, ActivityImage) {
    return class AtheneVideoView extends ControlInfoView {
        //region Static
        static Template = function () {
            var getVideoContainer = function getVideoContainer() {
                return $('' + '<div class="athene-video-view__container">' + '   <div class="container__video-container">' + '       <div class="video-container__video-placeholder" data-loading="false" data-stopped="false">' + '           <video autoplay muted playsinline poster="' + TRANSPARENT_16X9 + '" preload="metadata" class="video-container__video" />' + '           <div class="video-placeholder__play-button">' + '               ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PLAY, "play-button__icon") + '           </div>' + '       </div>' + '   </div>' + '   <div class="container__pip-container">' + '       <img class="pip-container__image" src="' + TRANSPARENT_16X9 + '">' + '   </div>' + '   <div class="container__text-overlay-container">' + '       <div class="text-overlay-container-element text-overlay-container__time"></div>' + '       <div class="text-overlay-container-element text-overlay-container__blocking"></div>' + '   </div>' + '</div>');
            };

            var getBlockingElement = function getBlockingElement(iconSrc, blockingTxt) {
                var elms = [];

                if (iconSrc) {
                    elms.push($("<div class='blocking__icon-placeholder'>" + "   " + ImageBox.getResourceImageWithClasses(iconSrc, "icon-placeholder__icon") + "</div>"));
                }

                if (blockingTxt) {
                    elms.push($("<div class='blocking__text'>" + blockingTxt + "</div>"));
                }

                return elms;
            };

            return {
                getVideoContainer: getVideoContainer,
                getBlockingElement: getBlockingElement
            };
        }(); //endregion Static

        //region Private
        //fast-class-es6-converter: extracted from defineStatic({}) content
        SOMEONE_THERE_TIME = 10 * 60 * 1000; //endregion Private

        //region Getter
        get isVideoPlaying() {
            return this.elements && this.elements.video && !this.elements.video[0].paused && !this.elements.video[0].ended;
        } //endregion Getter


        constructor(control, details) {
            super(control, null);
            this.details = details;
            this.doesSupportsH264 = details.deviceSupportsH264;
        }

        viewDidLoad() {
            this._videoIsPlaying = false;
            return super.viewDidLoad(...arguments).then(function () {
                this.mediaHandler = MediaHandler.shared(this.control);
                this.mediaHandler.delegate = this;
                this.element.toggleClass("athene-video-view--no-h264", !this.doesSupportsH264);
                this.audioActiveFor = 0;
                this.element.append(AtheneVideoView.Template.getVideoContainer());
                this.elements = {};
                this.elements.videoPlaceholder = this.element.find(".video-container__video-placeholder");
                this.elements.video = this.elements.videoPlaceholder.find(".video-container__video");
                this.elements.videoContainer = this.element.find(".container__video-container");
                this.elements.overlayContainer = this.element.find(".container__text-overlay-container");
                this.elements.overlayTexts = {
                    info: this.elements.overlayContainer.find(".text-overlay-container__info"),
                    time: this.elements.overlayContainer.find(".text-overlay-container__time"),
                    blocking: this.elements.overlayContainer.find(".text-overlay-container__blocking")
                };
                this.elements.videoPlayButton = this.element.find(".video-placeholder__play-button");
                this.elements.message = this.element.find(".items__top-message");
                this.elements.messageText = this.element.find(".top-message__text");
                this.elements.messageIcon = this.element.find('.top-message__image');
                this.elements.pipContainer = this.element.find(".container__pip-container");
                this.elements.picture = this.element.find('.pip-container__image');
                this.pictureButton = new GUI.LxButton(this, this.elements.pipContainer);

                this.pictureButton.onButtonReleased = function () {
                    if (this.elements.videoPlaceholder.attr("data-stopped") !== "true") {
                        this.switchMode();
                    }
                }.bind(this);

                this.addToHandledSubviews(this.pictureButton);
                this.videoPlayButton = new GUI.LxButton(this, this.elements.videoPlayButton);

                this.videoPlayButton.onButtonReleased = function () {
                    if (this.elements.videoPlaceholder.attr("data-stopped") === "true") {
                        this.playVideo(true, true);
                    }
                }.bind(this);

                this.addToHandledSubviews(this.videoPlayButton);
                this.overlayButton = new GUI.LxButton(this, this.elements.overlayContainer);

                this.overlayButton.onButtonReleased = function () {
                    if (typeof this.overlayAction === "function") {
                        this.overlayAction();
                    } else if (this.elements.pipContainer.find('img').length === 0 && this.elements.videoPlaceholder.attr("data-stopped") !== "true") {
                        this.switchMode();
                    }
                }.bind(this);

                this.addToHandledSubviews(this.overlayButton);
                this._boundSetVideoStartLoading = this._setVideoLoading.bind(this, true);
                this._boundSetVideoFinishedLoading = this._setVideoLoading.bind(this, false);

                this._boundOnSuspend = function () {
                    console.log("Video is suspended (Low Power Mode?");
                    this.elements && this.elements.video.trigger('play');
                };

                this._boundPlayFunc = function () {
                    console.log("Video is Playing");
                };

                this.elements.video.on("loadstart", this._boundSetVideoStartLoading);
                this.elements.video.on("loadeddata", this._boundSetVideoFinishedLoading);
                this.elements.video.on("suspend", this._boundOnSuspend);
                this.elements.video.on("play", this._boundPlayFunc);

                if (!this.doesSupportsH264) {
                    AtheneVideoView.Template.getBlockingElement(Icon.Athene.NO_VIDEO, _("athene.incompatibility-note.no-h264")).forEach(function (elm) {
                        this.elements.overlayTexts.blocking.append(elm);
                    }.bind(this));
                    this.elements.overlayTexts.blocking.addClass("text-overlay-container-element--no-bubble");
                }
            }.bind(this));
        }

        viewWillAppear() {
            this.updateVideoSource(this.elements.video[0].srcObject);
            return super.viewWillAppear(...arguments);
        }

        viewDidAppear() {
            this._videoIsPlaying && this.playVideo(this._videoIsPlaying);
            return super.viewDidAppear(...arguments);
        }

        viewWillDisappear() {
            return super.viewWillDisappear(...arguments).then(() => {
                this.showSnapshot(true);
            });
        }

        destroy() {
            return this.mediaHandler.stopRemoteVideoStream().then(() => {
                this.elements.video.off("loadstart", this._boundSetVideoStartLoading);
                this.elements.video.off("loadeddata", this._boundSetVideoFinishedLoading);
                this.elements.video.off("suspend", this._boundOnSuspend);
                this.elements.video.off("play", this._boundPlayFunc);
                
                return super.destroy(...arguments);
            });
        }

        userInteractionTick() {
            if (this.elements.video[0].srcObject) {
                this._stopSomeoneThereTimer();

                this._startSomeoneThereTimer();
            }
        }

        toggleScreenSaver(active) {
            if (active) {
                return this.mediaHandler.stopRemoteVideoStream();
            } else {
                return this.playVideo(true);
            }
        }

        updateVideoSource(videoSource) {
            this.processWhenVisible(() => {
                this.elements.video[0].srcObject = videoSource;

                if (videoSource) {
                    this._startSomeoneThereTimer();

                    this.elements.videoPlaceholder.attr("data-loading", "false");
                } else {
                    this._stopSomeoneThereTimer();
                }
            });
        }

        receivedStates(states) {
            var prevDeviceState = this._deviceState,
                prevBellState = this._bellState;
            this._deviceState = states.deviceState;
            this._bellState = states.bell;

            if (prevDeviceState !== states.deviceState) {
                if (states.deviceState === AtheneEnums.DEVICE_STATE.OK) {
                    if (!(this.ViewController.alert)) {
                        this.playVideo(true);
                    } else {
                        this.elements.videoPlaceholder.attr("data-stopped", "true");
                        this.mediaHandler.getAuthToken().then(authToken => {
                            new ActivityImage(this.control, {
                                date: moment(),
                                imagePath: MediaHandler.LAST_ACTIVITY_PATH + "?auth=" + authToken + "&cacheBuster=" + Date.now().toString()
                            }).getHighRes().then(function (img) {
                                this.elements.video[0].poster = img;
                            }.bind(this));
                        })
                    }
                }
            }

            if (prevBellState !== this._bellState && this._bellState) {
                this.showLastActivity();
            }
        }

        /**
         * Sets the play state
         * @param play
         * @param fromUserInteraction
         * @return {Q.Promise<unknown>}
         */
        playVideo(play = true, fromUserInteraction = false) {
            console.log(this.name, "playVideo(" + play.toString() + ")");
            let def = Q.defer(),
                isFromAlert = this.ViewController.alert,
                force = isFromAlert && this._bellState; // Pause the Video before we actually stop the stream, as we still need to make a snapshot of the video

            if (!play) {
                this.elements.video.trigger('pause');
                this.showBlocking(null, _("athene.video-deactivated"));
            }

            if (this.elements.video[0].srcObject && this.elements.video[0].srcObject.active) {
                this.mediaHandler.pauseRemoteVideo(!play);
                def.resolve();
            } else {
                this.mediaHandler.startRemoteVideoStream(false, force).done(function (videoSrc) {
                    this.updateVideoSource(videoSrc); // Directly switch mode if the video has been started on an alertViewController and
                    // the video is in stopped state (not manually started by the user)

                    if ((isFromAlert || fromUserInteraction) && this.elements.videoPlaceholder.attr("data-stopped") === "true") {
                        this.switchMode();
                    }

                    this.elements.videoPlaceholder.attr("data-stopped", "false");
                    def.resolve();
                }.bind(this), def.reject, function (videoSrc) {
                    this.updateVideoSource(videoSrc);
                });
            }

            this._videoIsPlaying = play;
            return def.promise.then(function () {
                if (play) {
                    return Q(this.elements.video[0].play()).finally(function () {
                        this.hideBlocking();
                    }.bind(this));
                }
            }.bind(this));
        }

        switchMode() {
            var pip, full;
            this.elements.pipContainer.addClass("container__pip-container--shown");

            if (this.elements.pipContainer.find('img').length > 0) {
                // full screen is video - video will be switched to pip
                full = this.elements.videoPlaceholder;
                pip = this.elements.picture;
                !this._deviceSupportsH264 && this.element.addClass("athene-video-view--no-h264-with-image");
            } else {
                pip = this.elements.videoPlaceholder;
                full = this.elements.picture;
                !this._deviceSupportsH264 && this.element.removeClass("athene-video-view--no-h264-with-image");
            }

            pip.detach().insertAfter(full);
            this.elements.pipContainer.append(full.detach());
        }

        hidePicture() {
            if (this.elements.pipContainer.find('img').length === 0) {
                // switch the video back to full screen if currently in pip container
                this.switchMode();
            }

            this.elements.pipContainer.removeClass("container__pip-container--shown");
            this._lastActivitiyShown = false;
        }

        showLastActivity() {
            if (!this._lastActivitiyShown) {
                return PersistenceComponent.getShared(AtheneEnums.SETTINGS.PIP_SETTING + "_" + this.control.uuidAction).then(function (result) {
                    var pipPosition;

                    if (!result) {
                        pipPosition = AtheneEnums.SETTINGS.PIP_POSITION.RIGHT;
                    } else {
                        pipPosition = result;
                    }

                    return this.mediaHandler.readyPrms.then(function () {
                        // Use the ActivityImage class to handle Download Queue and Caching, we will reset the cache in the stateContainer
                        // When a new Bell event is received to always show the most up-to-date image for every bell
                        this.mediaHandler.getAuthToken().then(authToken => {
                            new ActivityImage(this.control, {
                                date: moment(),
                                imagePath: MediaHandler.LAST_ACTIVITY_PATH + "?auth=" + authToken + "&cacheBuster=" + Date.now().toString()
                            }).getHighRes().then(function (img) {
                                this.elements.picture.attr("src", img);
                            }.bind(this));
                        });
                        this.elements.pipContainer.toggleClass("left", pipPosition === AtheneEnums.SETTINGS.PIP_POSITION.LEFT);
                        this.elements.pipContainer.addClass("container__pip-container--shown");
                        this._lastActivitiyShown = true;
                        this.switchMode();
                    }.bind(this));
                }.bind(this));
            } else {
                return Q.resolve();
            }
        }

        showTime(establishing) {
            if (establishing) {
                this.elements.overlayTexts.time.text("--:--");
            } else if (!this.timer) {
                this.elements.overlayTexts.time.text("00:00");
                this.timer = setInterval(function () {
                    var timeString = LxDate.formatSecondsIntoDigits(++this.audioActiveFor, false, true);
                    this.elements.overlayTexts.time.text(timeString);
                }.bind(this), 1000);
            }
        }

        hideTime() {
            this.elements.overlayTexts.time.text(null);
            this.timer && clearTimeout(this.timer);
            this.timer = null;
            this.audioActiveFor = 0;
        }

        showBlocking(iconSrc, text, snapshot, callback) {
            if (this.doesSupportsH264) {
                this.hideBlocking();
                AtheneVideoView.Template.getBlockingElement(iconSrc, text).forEach(function (elm) {
                    this.elements.overlayTexts.blocking.append(elm);
                }.bind(this));
                this.elements.overlayTexts.blocking.addClass("text-overlay-container-element--no-bubble");
                this.elements.videoPlaceholder.addClass("blur");
                this.showSnapshot(snapshot);

                if (callback && typeof callback === "function") {
                    this.overlayAction = callback;
                }
            }
        }

        hideBlocking() {
            if (this.doesSupportsH264) {
                this.elements.overlayTexts.blocking.empty();
                this.elements.videoPlaceholder.removeClass("blur");
                delete this.overlayAction;
            }
        }

        showSnapshot(snapshot) {
            if (this.elements.video[0].srcObject && this.elements.video[0].srcObject.active) {
                var bgURL = TRANSPARENT_16X9;

                if (snapshot) {
                    console.log("Creating snapshot");
                    var canvas = document.createElement("canvas");
                    canvas.width = this.elements.video.width();
                    canvas.height = this.elements.video.height();
                    canvas.getContext('2d').drawImage(this.elements.video[0], 0, 0, canvas.width, canvas.height);
                    bgURL = canvas.toDataURL();
                } else {
                    console.log("Setting clear snapshot");
                }

                this.elements.video.css("background", "transparent url('" + bgURL + "') no-repeat 0 0");
            } else {
                console.warn("Won't snapshot, no active Video Stream!");
            }
        }

        getStateIDs() {
            return ["bell", "deviceState"];
        }

        onRemoteStreamStateChanged() {
            var streamState = this.mediaHandler.remoteStreamState,
                error = this.mediaHandler.remoteStreamError;

            if (streamState === MediaHandler.REMOTE_STREAM_STATE.ERROR && error && error.id === MediaHandler.REMOTE_STREAM_ERROR.KICKED && error.info.type === MediaHandler.KICK_TYPE.STREAM_ERROR) {
                if (error.info.info <= 2) {
                    this.playVideo(false).then(function () {
                        return this.playVideo();
                    }.bind(this));
                } else {
                    this.showBlocking(null, _("athene.video-error"));
                }
            }
        }

        _setVideoLoading(isLoading) {
            this.elements.videoPlaceholder.attr("data-stopped", "false");
            this.elements.videoPlaceholder.attr("data-loading", JSON.stringify(isLoading));
        }

        _startSomeoneThereTimer() {
            // Removed until further notice
            return;
            this._someoneThereTimeout = setTimeout(function () {
                this.showBlocking(null, "Noch jemand hier?".debugify(), true, this.playVideo.bind(this, true));
                this.mediaHandler.startRemoteVideoStream(false);
                this.updateVideoSource(null);
            }.bind(this), this.SOMEONE_THERE_TIME);
        }

        _stopSomeoneThereTimer() {
            clearTimeout(this._someoneThereTimeout);
            this._someoneThereTimeout = null;
        }

    };
});
