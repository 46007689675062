'use strict';

define(["AudioZoneControlEnums"], function (AudioZoneControlEnums) {
    class AudioZoneControlSettingsLineIn extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div class="audiozone-settings-input" />'));
            this.details = details;
            this.control = details.control;
            this.currentPlayerId = details.control.details.playerid;

            if (!this.details.hasOwnProperty("input")) {
                throw "Input is a mandatory input attribute for this screen!";
            }

            this.input = cloneObjectDeep(details.input);
            this.validName = true;
        }

        reloadTable() {
            this.tableContent = [{
                rows: [{
                    type: GUI.TableViewV2.CellType.INPUT,
                    content: {
                        value: this.input.name,
                        placeholder: this.input.description,
                        autofocus: false,
                        validationRegex: Regex.NAME
                    }
                }]
            }];

            if (MediaServerComp.Feature.V2_FIRMWARE) {
                this.tableContent[0].rows.push({
                    content: {
                        title: _("icon"),
                        clickable: true,
                        rightIconSrc: MediaEnum.LineInMap[this.input.icontype],
                        class: "base-cell--with-chevron"
                    },
                    action: this._showSymbols.bind(this)
                });
            }

            this.tableContent.push({
                rows: [{
                    type: GUI.TableViewV2.CellType.SLIDER,
                    content: {
                        title: _("media.input.volume"),
                        value: this.input.hasOwnProperty('inputvolume') ? this.input.inputvolume : 70,
                        format: "%.0f%%",
                        minVal: 0,
                        maxVal: 100,
                        step: 1,
                        minIconSrc: Icon.MINUS,
                        maxIconSrc: Icon.PLUS
                    },
                    sliderDragged: this.onVolumeChange.bind(this),
                    sliderClicked: this.onVolumeChange.bind(this)
                }]
            });
            this.tableContent.push({
                rows: [{
                    type: GUI.TableViewV2.CellType.SWITCH,
                    content: {
                        title: _("media.preferences.line-ins.show-audio-src"),
                        active: this.input.enabled
                    }
                }]
            });
            return super.reloadTable(...arguments);
        }

        viewWillDisappear() {
            this._updateSettings();

            return super.viewWillDisappear(...arguments);
        }

        titleBarText() {
            return this.input.name;
        }

        textChanged(section, row, tableView, text, isValid) {
            this.input.name = text;
            this.validName = isValid;

            if (text === "") {
                this.input.name = this.input.description;
                this.validName = true;
            }
        }

        onSwitchChanged(value, section, row, tableView) {
            this.input.enabled = value;
        }

        onVolumeChange(cell, section, row, tableView, value) {
            Debug.Control.AudioZone.Settings.Zone && console.log(this.name, "volume changed to " + value);
            this.input.inputvolume = value;
            MediaServerComp.updateInputVolume(this.input, true);
        }

        // Private methods

        /**
         * Will update all the settings of the input. Until this is done, a popup is shown.
         * @private
         */
        _updateSettings() {
            Debug.Control.AudioZone.Settings.Zone && console.log(this.name, "_updateSettings");

            if (this.updatePromise) {
                return; // nothing to do.
            }

            var promises = [];

            if (this.validName) {
                Debug.Control.AudioZone.Settings.Zone && console.log("       rename to: " + this.input.name);
                promises.push(MediaServerComp.updateInputName(this.input));
            }

            promises.push(MediaServerComp.updateInputEnabled(this.input));
            promises.push(MediaServerComp.updateInputType(this.input));
            this.updatePromise = NavigationComp.showWaitingFor(Q.all(promises), null, _("media.input.updating", {
                name: this.input.name
            }));
            this.updatePromise.finally(function () {
                this.updatePromise = null;
            }.bind(this));
        }

        _showSymbols() {
            var def = Q.defer(),
                details = {
                    options: Object.keys(MediaEnum.LineInMap).map(function (symbolType) {
                        symbolType = parseInt(symbolType); // symbolType may be a string!

                        return {
                            icontype: symbolType,
                            rightIconSrc: MediaEnum.LineInMap[symbolType],
                            selected: this.input.icontype === symbolType
                        };
                    }.bind(this)),
                    title: _("icon"),
                    deferred: def
                };
            this.ViewController.showState(ScreenState.SelectorScreen, null, details);
            def.promise.then(function (result) {
                this.input.icontype = result[0].option.icontype;
                this.reloadTable();
            }.bind(this));
        }

    }

    Controls.AudioZoneControl.AudioZoneControlSettingsLineIn = AudioZoneControlSettingsLineIn;
});
