'use strict';
/*
 Sauna:
 min: 86°F       -> 30°C
 max: 248°F      -> 120°C

 Sauna Vapor:
 manual mode:
 min: 86°F       -> 30°C
 max: 158°F      -> 70°C

 finnish manual:
 min: 86°F       -> 30°C
 max: 230°F      -> 110°C

 humidity manual:
 fix: 122°F      -> 50°C

 warm air bath:
 fix: 113°F      -> 45°C

 soft vapor bath:
 fix: 122°F      -> 50°C

 herbal:
 fix: 113°F      -> 45°C

 finnish:
 fix: 176°F      -> 80°C
 */

define([], function () {
    return {
        SaunaModes: {
            NO_VAPOR: {
                nr: -1,
                minTemp: 30,
                // was zero, did cause issues
                maxTemp: 120
            },
            FINNISH: {
                nr: 3,
                name: _("controls.sauna.mode.finnish"),
                temp: 80,
                minTemp: 30,
                // specify as modifying the temp only will automatically activate finnish manual mode.
                maxTemp: 110
            },
            HERBAL: {
                nr: 4,
                name: _("controls.sauna.mode.herbal"),
                temp: 45,
                humidity: 50
            },
            SOFT_VAPOR_BATH: {
                nr: 5,
                name: _("controls.sauna.mode.soft-steam"),
                temp: 50,
                humidity: 50
            },
            WARM_AIR_BATH: {
                nr: 6,
                name: _("controls.sauna.mode.warm-air"),
                temp: 45,
                humidity: 45
            },
            HUMIDITY_MANUAL: {
                nr: 2,
                name: _("controls.sauna.mode.manual-humidity"),
                temp: 50,
                humidity: "manually",
                minHumidity: 15,
                maxHumidity: 65
            },
            FINNISH_MANUAL: {
                nr: 1,
                name: _("controls.sauna.mode.finnish-manual"),
                temp: "manually",
                minTemp: 30,
                maxTemp: 110
            },
            SAUNA_MANUAL: {
                nr: 0,
                name: _("controls.sauna.mode.manual"),
                temp: "manually",
                minTemp: 30,
                maxTemp: 70,
                humidity: "manually",
                minHumidity: 15,
                maxHumidity: 65
            }
        },
        ScreenState: {
            MAN_TEMP: 'TargetTempSelectorScreen',
            MAN_HUMID: 'TargetHumiditySelectorScreen'
        }
    };
});
