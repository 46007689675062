'use strict';

class HomeKitSettingsScreen extends GUI.TableViewScreenV2 {
    constructor(details) {
        super($('<div />'));
        this._accessories = details.accessories || [];
        this._visibleAccessories = [];
        this._homeName = details.homeName || "";
        this._apiUserUuid = details.apiUserUuid;
    }

    viewDidLoad() {
        var args = arguments;
        this.ViewController._preventReset = true;
        this._viewer = ActiveMSComponent.getCurrentHomeKitUserType();
        this._owner = ActiveMSComponent.getHomeKitAddonOwner();

        this._handleAccessoriesList();

        return super.viewDidLoad(...args);
    }

    viewDidAppear() {
        return super.viewDidAppear(...arguments).then(function () {
            var descriptionSection = this.element.find('#section-1');

            if (this._viewer === HomeKit.VIEWER.OWNER) {
                descriptionSection.addClass("lx-table-view__section--hide-description");
            } else {
                descriptionSection.removeClass("lx-table-view__section--hide-description");
            }

            if (!this._titleBar.onRightButtonTapped) {
                this._titleBar.onRightButtonTapped = function () {
                    this.ViewController.showPluginVersionPopup();
                }.bind(this);
            }
        }.bind(this));
    }

    _handleAccessoriesList() {
        if (ActiveMSComponent.getCurrentHomeKitUserType() !== HomeKit.VIEWER.USER) {
            this._visibleAccessories = this._accessories.filter(function (accessory) {
                return accessory.visible;
            }.bind(this));
        }
    }

    updateView(details) {
        var args = arguments;
        this._accessories = details.accessories;

        this._handleAccessoriesList();

        return this.reloadTable().then(() => {
            return super.updateView(...args);
        });
    }

    getAnimation() {
        return AnimationType.NONE;
    }

    titleBarText() {
        return _("home-kit.menu");
    }

    styleForTableView() {
        return TableViewStyle.COMFORT_MODE_2020;
    }

    titleBarConfig() {
        if (!this._comeFromSettings) {
            return {
                rightSide: TitleBarCfg.CUSTOM_ICON,
                rightIconSrc: Icon.Menu.INFO
            };
        }

        return {
            leftSide: TitleBarCfg.Button.BACK
        };
    }

    reloadTable() {
        var firstSection, functionSection, resetSection, accessSection, removeSection;

        if (ActiveMSComponent.getCurrentHomeKitUserType() === HomeKit.VIEWER.OWNER) {
            var reachMode = CommunicationComponent.getCurrentReachMode(),
                showExternalConnectionPopup;

            if (reachMode === ReachMode.REMOTE) {
                showExternalConnectionPopup = true;
            }

            firstSection = {
                headerTitle: NBR_SPACE,
                headerDescription: _("home-kit.settings.owner.message", {
                    home: this._homeName
                })
            };
            functionSection = {
                headerTitle: _("home-kit.settings.owner.functions.header"),
                footerTitle: _("home-kit.settings.owner.functions.home-app"),
                headerDescription: NBR_SPACE,
                rows: [this._createProgressCell(), this._createButtonCell(_("home-kit.settings.owner.functions.edit"), this._goToFunctions.bind(this), GUI.TableViewV2.CellType.GENERAL, null, null, null, showExternalConnectionPopup)]
            };
            resetSection = {
                rows: [this._createButtonCell(_("home-kit.settings.owner.functions.reset.button"), this._resetFunctions.bind(this), GUI.TableViewV2.CellType.GENERAL, this._disabledReset, window.Styles.colors.red, null, showExternalConnectionPopup)],
                footerTitle: _("home-kit.settings.owner.functions.reset.text")
            };
            accessSection = {
                headerTitle: _("home-kit.settings.owner.share.header"),
                headerDescription: _("home-kit.settings.owner.share.message"),
                rows: [this._createButtonCell(_("home-kit.settings.owner.share.go-to"), this._goToHomeApp.bind(this), GUI.TableViewV2.CellType.GENERAL, null, null, Icon.HOME_KIT.HOME_APP)]
            };
            removeSection = {
                rows: [this._createButtonCell(_("home-kit.settings.owner.remove.button"), this._removeBridge.bind(this), GUI.TableViewV2.CellType.DELETE)]
            };
            this.tableContent = [firstSection, functionSection, resetSection, accessSection, removeSection];
        } else if (ActiveMSComponent.getCurrentHomeKitUserType() === HomeKit.VIEWER.ADMIN) {
            firstSection = {
                headerTitle: NBR_SPACE,
                headerDescription: _("home-kit.settings.user.message", {
                    user: this._owner.owner
                })
            };
            functionSection = {
                headerTitle: _("home-kit.settings.user.share.header"),
                headerDescription: _("home-kit.settings.different-user", {
                    user: this._owner.owner
                })
            };
            this.tableContent = [firstSection, functionSection, accessSection];
        } else if (ActiveMSComponent.getCurrentHomeKitUserType() === HomeKit.VIEWER.USER) {
            firstSection = {
                headerTitle: NBR_SPACE,
                headerDescription: _("home-kit.settings.user.message", {
                    user: this._owner.owner
                })
            };
            functionSection = {
                headerTitle: _("home-kit.settings.user.share.header"),
                headerDescription: _("home-kit.settings.different-user", {
                    user: this._owner.owner
                })
            };
            this.tableContent = [firstSection, functionSection];
        }

        return super.reloadTable(...arguments);
    }

    _createButtonCell(text, action, type, disabled, color, leftIconSrc, showExternalConnectionPopup) {
        var conf = {
            content: {
                title: text,
                clickable: true,
                disabled: disabled,
                titleColor: color
            },
            type: type,
            action: action
        };

        if (disabled !== undefined) {
            conf.content.disabled = disabled;
        }

        if (color) {
            conf.content.titleColor = color;
        }

        if (leftIconSrc) {
            conf.content.leftIconSrc = leftIconSrc;
        }

        if (showExternalConnectionPopup) {
            conf.content.class = "disabled--user-interaction";
            conf.action = this._showExternalConnectionPopup.bind(this);
        }

        return conf;
    }

    _showExternalConnectionPopup() {
        NavigationComp.showPopup({
            message: _("home-kit.settings.internal-error"),
            buttonOk: true
        });
    }

    _createProgressCell() {
        var conf = {
            content: {
                visible: this._visibleAccessories.length,
                total: this._accessories.length,
                max: ActiveMSComponent.getMaxHomeKitAccessories()
            },
            type: GUI.TableViewV2.CellType.HOME_KIT_PROGRESS
        };
        return conf;
    }

    _goToFunctions() {
        this.ViewController.showState(ScreenState.HomeKitAccessories, null, {
            accessories: this._accessories,
            comeFromSettings: true,
            homeName: this._homeName,
            apiUserUuid: this._apiUserUuid
        });
    }

    _resetFunctions() {
        this._accessories.filter(function (accessory) {
            return accessory.visible;
        }.bind(this));

        ActiveMSComponent.assignHomeKitAccessories(this._accessories).then(function () {
            NavigationComp.showPopup({
                message: _("home-kit.functions.assign.popup.reset.message"),
                buttonOk: true
            });
        }.bind(this), function (error) {
            this.ViewController.handleError(error, _("home-kit.error.assign-rooms"));
        }.bind(this));
    }

    _goToHomeApp() {
        NavigationComp.openWebsite("com.apple.Home://");
    }

    _removeBridge() {
        this.ViewController._preventReset = false;
        this.ViewController._preventPopup = true;
        this.ViewController.dismiss();
    }

}

GUI.HomeKitSettingsScreen = HomeKitSettingsScreen;
