'use strict';

define("SteakControlContentSettings", [], function () {
    return class SteakControlContentSettings extends Controls.ControlContent {
        //region Static
        static Template = function () {
            var getInfoSectionHeader = function getInfoSectionHeader(infoText) {
                return $('<div class="section__header-view">' + '   <div class="header-view__info-text">' + infoText + '</div>' + '</div>');
            };

            return {
                getInfoSectionHeader: getInfoSectionHeader
            };
        }(); //endregion Static

        constructor(details) {
            super(details);
            this.displayAlwaysOnBat = false;
            this.displayAlwaysOnDc = false;
            this.displayBrightness = 0;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.tableView = new GUI.TableViewV2(this, this);
            this.appendSubview(this.tableView);
        }

        titleBarText() {
            return _('settings');
        }

        viewWillAppear() {
            this._reload();

            super.viewWillAppear(...arguments);
        }

        getAnimation() {
            return AnimationType.HD_OVERLAY;
        }

        receivedStates(states) {
            var _stateHasChanged = true;

            if (this.displayAlwaysOnBat === states.displayAlwaysOnBat && this.displayAlwaysOnDc === states.displayAlwaysOnDc && this.touchProtection === states.touchProtection) {
                _stateHasChanged = false;
            }

            this.displayAlwaysOnBat = states.displayAlwaysOnBat;
            this.displayAlwaysOnDc = states.displayAlwaysOnDc;
            this.touchProtection = states.touchProtection;
            this.displayBrightness = states.displayBrightness; // Don't reload to often, first check, if some used state has changed

            if (_stateHasChanged) {
                this._reload();
            }
        }

        // TableViewDataSource Methods
        styleForTableView(tableView) {
            return TableViewStyle.GROUPED;
        }

        numberOfSections(tableView) {
            return this.tableContent.length;
        }

        numberOfRowsInSection(section, tableView) {
            return this.tableContent[section].rows.length;
        }

        titleForHeaderInSection(section, tableView) {
            return this.tableContent[section].header;
        }

        sectionHeaderElement(section, tableView) {
            return this.tableContent[section].headerElement;
        }

        titleForFooterInSection(section, tableView) {
            return this.tableContent[section].footer;
        }

        cellTypeForCellAtIndex(section, row, tableView) {
            return this.tableContent[section].rows[row].type;
        }

        contentForCell(cell, section, row, tableView) {
            return this.tableContent[section].rows[row].content;
        }

        // TableViewDelegate methods
        onSwitchChanged(value, section, row, tableView, cell) {
            this.tableContent[section].rows[row].onSwitchChanged && this.tableContent[section].rows[row].onSwitchChanged.apply(this, arguments);
        }

        sliderClicked(cell, section, row, tableView, value) {
            this.tableContent[section].rows[row].onBrightnessChanged && this.tableContent[section].rows[row].onBrightnessChanged.apply(this, arguments);
        }

        sliderDragged(cell, section, row, tableView, value) {
            this.tableContent[section].rows[row].onBrightnessChanged && this.tableContent[section].rows[row].onBrightnessChanged.apply(this, arguments);
        }

        _reload() {
            this._getTableContent();

            this.tableView.reload();
        }

        _getTableContent() {
            var infoSection = this._getInfoSection(),
                brightnessSection = this._getBrightnessSection(),
                displayAlwaysOnSection = this._getAlwaysOnSection(),
                touchEnableSection = this._getTouchEnableSection();

            this.tableContent = [infoSection];
            brightnessSection && this.tableContent.push(brightnessSection);
            this.tableContent.push(displayAlwaysOnSection);
            this.tableContent.push(touchEnableSection);
        }

        _getInfoSection() {
            return {
                headerElement: SteakControlContentSettings.Template.getInfoSectionHeader(_('steak.settings.sync-notice', {
                    productName: _('steak.product-name')
                })),
                rows: []
            };
        }

        _getAlwaysOnSection() {
            return {
                header: _('steak.settings.display-always-on'),
                rows: [{
                    type: GUI.TableViewV2.CellType.SWITCH,
                    content: {
                        title: _('steak.settings.battery-powered'),
                        active: this.displayAlwaysOnBat,
                        useAntiGhostTimer: true
                    },
                    onSwitchChanged: function (value, section, row, tableView, cell) {
                        this.control.sendCommand(Commands.format(Commands.Steak.SET_DISPLAY_ALWAYS_ON_BAT, value));
                    }.bind(this)
                }, {
                    type: GUI.TableViewV2.CellType.SWITCH,
                    content: {
                        title: _('steak.settings.dc-powered'),
                        active: this.displayAlwaysOnDc,
                        useAntiGhostTimer: true
                    },
                    onSwitchChanged: function (value, section, row, tableView, cell) {
                        this.control.sendCommand(Commands.format(Commands.Steak.SET_DISPLAY_ALWAYS_ON_DC, value));
                    }.bind(this)
                }]
            };
        }

        _getTouchEnableSection() {
            var cell;

            if (Feature.STEAK_NEW_DETAILS && this.control.details.isTouchProtectConnected) {
                cell = {
                    type: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        title: _("steak.settings.touch-enabled"),
                        subtitle: _("controls.leaf.settings.locked-desc"),
                        disclosureText1: this.touchProtection ? _("active") : _("inactive"),
                        disclosureColor: Color.STATE_INACTIVE
                    }
                };
            } else {
                cell = {
                    type: GUI.TableViewV2.CellType.SWITCH,
                    content: {
                        title: _("steak.settings.touch-enabled"),
                        active: this.touchProtection,
                        useAntiGhostTimer: true
                    },
                    onSwitchChanged: function (value, section, row, tableView, cell) {
                        this.control.sendCommand(Commands.format(Commands.Steak.SET_TOUCH_PROTECTION, value));
                    }.bind(this)
                };
            }

            return {
                rows: [cell],
                footer: _("steak.settings.touch-enabled-notice")
            };
        }

        /**
         * Returns the brightness section with one sliderCell if the Feature.STEAK_NEW_DETAILS feature is available
         * @return {*}
         * @private
         */
        _getBrightnessSection() {
            var section = null;

            if (Feature.STEAK_NEW_DETAILS) {
                if (this.control.details.isBrightnessConnected) {
                    section = {
                        rows: {
                            type: GUI.TableViewV2.CellType.GENERAL,
                            content: {
                                title: _("steak.displayBrightness"),
                                subtitle: _("controls.leaf.settings.locked-desc"),
                                disclosureText1: this.displayBrightness + "%",
                                disclosureColor: Color.STATE_INACTIVE
                            }
                        }
                    };
                } else {
                    section = {
                        rows: [{
                            type: GUI.TableViewV2.CellType.SLIDER,
                            content: {
                                title: _("steak.displayBrightness"),
                                value: this.displayBrightness,
                                valueColor: window.Styles.colors.stateActive,
                                format: "%i%%",
                                minIconSrc: Icon.ColorPickerV2.Brightness.DOWN,
                                maxIconSrc: Icon.ColorPickerV2.Brightness.UP
                            },
                            onBrightnessChanged: function (cell, section, row, tableView, value) {
                                this.control.sendCommand(Commands.format(Commands.Steak.SET_DISPLAY_BRIGHTNESS, value), Commands.Type.OVERRIDE);
                            }.bind(this)
                        }]
                    };
                }
            }

            return section;
        }

    };
});
