import React, {useCallback, useEffect, useState} from 'react'
import {LxReactFlexibleCell, LxReactImageView, useBackNavigation} from "../Components";
import { getPlatformSettings, performActionForType, getHeaderTitleForSection, Sections, ActionType, getSavedSection } from './QuickActionsUtils';
import { View, Text, StyleSheet, Pressable } from "react-native";
import globalStyles from '../../../styles/react/styles';
import DndSortableList from '../LxReactTableView/SortableList';
import {useFocusEffect} from "@react-navigation/native";

const SectionHeader = ({title, separator = true}) => {
    return <View style={[styles.sectionHeaderContainer, separator && styles.separator]}>
        <Text style={styles.sectionHeaderText}>{title}</Text>
    </View>
}

const StaticSectionItem = ({data, itemSeparator = true}) => {
    return <Pressable onPress={data?.action} disabled={!data?.action} >
        <View style={[styles.actionItemContainer, itemSeparator && styles.separator]}>
            <LxReactImageView source={data.content.leftIconSrc} containerStyle={[{fill: data.content.leftIconColor, flexShrink: 0}, styles.actionSectionItemImage]} />
            <View style={{flexShrink: 1}}>
                <Text style={styles.actionSectionItemText}>{data.content.title}</Text>
                <Text style={styles.actionSectionItemSubtitle}>{data.content.subtitle}</Text>
            </View>
        </View>
    </Pressable>
}

const SingleSection = ({content, terminateLastItem = true}) => {
    const rowCount = content.rows.length;
    return (
        <View style={styles.actionsSectionContainer}>
            <SectionHeader title={content.headerTitle} separator={rowCount > 0 ? true : false} />
            {content.rows.map((row, index) => (index + 1 === rowCount) ?
                <StaticSectionItem data={row} itemSeparator={terminateLastItem} /> :
                <StaticSectionItem data={row} />)
            }
        </View>
    )
}

const SavedSection = ({content, setContent, isEditMode, setIsEditMode}) => {
    if(!content) return null;

    const {isLoxoneControl} = getPlatformSettings();
    let dndTable = cloneObjectDeep(content);
    let actions = QuickActionUtility.getItems(isLoxoneControl);
    dndTable = dndTable.rows.map((cell, rowId) => {
        cell.type = GUI.TableViewV2.CellType.EDITABLE_CELL;
        cell.content.leftIconSrc = null;

        return {
            id: rowId+1,
            ...window.LxCellReactMap[cell.type]({
                cellObj: cell,
                sectionIdx: dndTable,
                rowId
            })
        }
    });

    const onCellMove = (section, from, to) => {
        let actions = QuickActionUtility.getItems(isLoxoneControl);
        actions = actions.move(from, to);
        QuickActionUtility.setActions(actions);
        setContent(prev => {
            let newState = cloneObjectDeep(prev);
            newState.rows.move(from, to);
            return newState;
        });
    }

    const onCellRemove = (section, rowId) => {
        let actions = QuickActionUtility.getItems(isLoxoneControl);
        let action = actions[rowId];
        QuickActionUtility.removeAction(action.uuid).done(() => {
            setContent(prev => {
                let newState = cloneObjectDeep(prev);
                newState.rows.splice(rowId, 1);
                return newState;
            });
            NavigationComp.showPopup({
                message: _('quick-action.delete', {
                    actionTitle: action.title
                }),
                buttonOk: true,
                icon: Icon.SUCCESS
            });
        });
    };

    return (
        content && actions.length > 0 && <View style={styles.savedSectionContainer}>
            {isEditMode ?
                <>
                    <Pressable
                        onPress={() => {setIsEditMode(prev => !prev)}}
                        style={styles.editButton}
                    >
                        <Text
                            style={styles.editButtonText}
                        >
                            {isEditMode ? _('done') : _('edit')}
                        </Text>
                    </Pressable>
                    <SectionHeader
                        title={content.headerTitle}
                    />
                </>
                :
                <Pressable
                    onPress={() => {setIsEditMode(prev => !prev)}}
                    style={styles.editButton}
                >
                    <Text
                        style={styles.editButtonText}>{isEditMode ? _('done') : _('edit')}
                    </Text>
                </Pressable>
            }
            {isEditMode ?
                <View style={styles.savedContainer}>
                    <DndSortableList
                        content={dndTable}
                        onCellMove={onCellMove}
                        onCellRemove={onCellRemove}
                        listStyle={styles.sortableListContainer}
                        cellTitleStyle={styles.actionSectionItemText}
                        cellSubTitleStyle={styles.actionSectionItemSubtitle}
                        cellContainerStyle={[styles.actionSectionItemCell, styles.separator]}
                    />
                </View> : <SingleSection content={content} terminateLastItem={false} />
            }
        </View>
    )
}

const QuickActionItem = ({title, iconSrc, onPress, row}) => {
    return <LxReactFlexibleCell section={0} row={row} onPress={onPress} title={title} titleStyle={styles.cellTitleStyle} mainLeftContent={{
        comp: LxReactImageView,
        props: {
            source: iconSrc,
            containerStyle: {fill: globalStyles.colors.brand, flexShrink: 0}
        }
    }}
        mainLeftStyle={{width: 24}}
        containerStyle={[{width: '100%'}, styles.separator]}
    />
}

function QuickActionsScreen(props) {
    const [isEditMode, setIsEditMode] = useState(false);
    const [savedSection, setSavedSection] = useState(getSavedSection(isEditMode));

    useBackNavigation(() => {
        props.navigation.goBack();
    }, [])

    //updating the screen after coming back from adding a scene
    useFocusEffect(useCallback(() => {
        setSavedSection(getSavedSection(isEditMode));
        setIsEditMode(false);
    }, []));
    const shouldRenderScenes = (SandboxComponent.isAutopilotAvailable() && Feature.AUTOMATIC_DESIGNER_SCENES);
    return <View style={styles.container}>
        <SectionHeader title={getHeaderTitleForSection(Sections.HEADER)} />
        <QuickActionItem
            title={_('app-position')}
            iconSrc={"resources/Images/ActiveMiniserver/NFC/icon-save-position.svg"}
            onPress={() => performActionForType(ActionType.Position, props.navigation)}
            row={0}
            key={"app-position-item"}
        />
        <QuickActionItem
            title={_('command')}
            iconSrc={"resources/Images/ActiveMiniserver/NFC/icon-save-task.svg"}
            onPress={() => performActionForType(ActionType.Command, props.navigation)}
            row={1}
            key={"app-command-item"}
        />
        {shouldRenderScenes && <QuickActionItem
            title={_("scenes.name")}
            iconSrc={Icon.AutomaticDesigner.SCENE}
            onPress={() => performActionForType(ActionType.Scene, props.navigation)}
            row={2}
            key={"app-scene-item"}
        />}
        <SavedSection content={savedSection} setContent={setSavedSection} isEditMode={isEditMode} setIsEditMode={setIsEditMode} />
        <SectionHeader title={getHeaderTitleForSection(Sections.FOOTER)} separator={false} />
    </View>
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        paddingHorizontal: globalStyles.spacings.gaps.bigger,
        paddingTop: globalStyles.spacings.gaps.small,
        alignItems: 'flex-start'
    },

    sectionHeaderContainer: {
        width: '100%'
    },

    sectionHeaderText: {
        ...globalStyles.textStyles.footNote.default,
        color: globalStyles.colors.text.secondary
    },

    actionsSectionContainer: {
        width: '100%',
        marginBottom: globalStyles.spacings.gaps.small
    },

    actionItemContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingVertical: globalStyles.spacings.gaps.regular,
        width: '100%',
        paddingHorizontal: globalStyles.spacings.gaps.verySmall - 1
    },

    separator: {
        borderBottomColor: globalStyles.colors.borderColor.default,
        borderBottomWidth: 1,
    },

    actionSectionItemImage: {
        width: 24,
        height: 24,
        flex: 0,
        flexBasis: 24,
        marginEnd: globalStyles.spacings.gaps.small
    },

    actionSectionItemText: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.white
    },

    savedContainer: {
        marginBottom: globalStyles.spacings.gaps.small,
        width: '100%'
    },

    actionSectionItemSubtitle: {
        ...globalStyles.textStyles.footNote.default,
        color: globalStyles.colors.text.secondary
    },

    actionSectionItemCell: {
        marginVertical: 6
    },

    sortableListContainer: {
        width: '100%',
        overflow: 'hidden',
        padding: 0
    },

    editButton: {
        alignItems: 'flex-end',
        width: '100%'
    },

    editButtonText: {
        ...globalStyles.textStyles.footNote.default,
        color: globalStyles.colors.brand
    },

    savedSectionContainer: {
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%'
    },

    cellTitleStyle: {
        flexGrow: 0,
        flexWrap: 1
    }
});

export default QuickActionsScreen
