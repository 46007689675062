import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Weather12(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            <Path
                d="M3 16.2a4.79 4.79 0 012.44-4.158A7.26 7.26 0 0112.75 5a7.333 7.333 0 016.22 3.412A5.36 5.36 0 0120.47 8.2a5.28 5.28 0 014.944 3.37A4.793 4.793 0 0124.125 21H7.875A4.838 4.838 0 013 16.2z"
                fill="#d5e2e9"
                fillRule="evenodd"
            />
            <Path
                transform="rotate(15 8.112 25.449)"
                fill="#51a9df"
                d="M7.61177 23.94889H8.61177V26.94889H7.61177z"
            />
            <Path
                transform="rotate(15 11.241 23.966)"
                fill="#51a9df"
                d="M10.74118 22.96593H11.74118V24.96593H10.74118z"
            />
            <Path
                transform="rotate(15 14.112 25.449)"
                fill="#51a9df"
                d="M13.61177 23.94889H14.61177V26.94889H13.61177z"
            />
            <Path
                transform="rotate(15 17.241 23.966)"
                fill="#51a9df"
                d="M16.74118 22.96593H17.74118V24.96593H16.74118z"
            />
            <Path
                transform="rotate(15 20.112 25.449)"
                fill="#51a9df"
                d="M19.61177 23.94889H20.61177V26.94889H19.61177z"
            />
            <Path
                transform="rotate(15 23.241 23.966)"
                fill="#51a9df"
                d="M22.74118 22.96593H23.74118V24.96593H22.74118z"
            />
        </Svg>
    )
}

export default Weather12
