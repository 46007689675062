'use strict';

define([], function () {
    return {
        PickerMode: {
            NONE: 0,
            LUMITECH: 1,
            HSV: 2,
            TEMPERATURE: 3,
            SEQUENCE: 4,
            DAYLIGHT: 5
        },
        Picker_Type: {
            RGB: "Rgb",
            LUMITECH: "Lumitech",
            TUNEABLE_WHITE: "TunableWhite"
        },
        MS_DAYLIGHT_MODE: 2,
        DayLightModes: {
            DIRECT: 4,
            INDIRECT: 5
        },
        MAX_SEQUENCE_COLORS: 6
    };
});
