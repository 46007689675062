"use strict";

define("AalSmartAlarmControlCommandSrc", ["ControlCommandSrc", "AalSmartAlarmControlEnums"], function (ControlCommandSrc, AalSmartAlarmControlEnums) {
    return class AalSmartAlarmControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [];

            if (states.alarmLevel > AalSmartAlarmControlEnums.ALARM_LEVEL.NO_ALARM) {
                cmds.push(this.createCommand(_("controls.alarm.acknowledge"), Commands.ALARM.AAL_SMART_ALARM.QUIT, null));
            }

            if (states.disabledTemp) {
                cmds.push(this.createCommand(_("controls.alarm.reactivate"), Commands.format(Commands.ALARM.AAL_SMART_ALARM.DISABLE, 0), null));
            }

            if (states.alarmLevel === AalSmartAlarmControlEnums.ALARM_LEVEL.NO_ALARM && !states.disabledTemp) {
                cmds.push(this.createCommand(_("controls.alarm.deactivate"), this._showDisableTimerScreen.bind(this, control), null))
            }

            return cmds;
        }

        _showDisableTimerScreen(control) {
            let details = {
                title: _("controls.alarm.deactivate"),
                callbackFn: (deactivateUntil) => {
                    control.sendCommand(Commands.format(Commands.ALARM.AAL_SMART_ALARM.DISABLE, deactivateUntil));
                }
            };

            NavigationComp.showState(ScreenState.TIMER_UNTIL_SCREEN, details);
        }

    };
});
