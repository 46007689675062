'use strict';

define("AalEmergencyControlCommandSrc", ["ControlCommandSrc", "AalEmergencyControlEnums"], function (ControlCommandSrc, AalEmergencyControlEnums) {
    return class AalEmergencyControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [];

            if (states.status === AalEmergencyControlEnums.Status.NORMAL) {
                cmds.push(this.createCommand(_("controls.aalemergency.call-help"), Commands.AalEmergency.TRIGGER, null));
                cmds.push(this.createCommand((_("controls.aalemergency.disable-function.button")), this._showDisableTimerScreen.bind(this, control), null));
            } else if (states.status === AalEmergencyControlEnums.Status.TRIGGERED) {
                cmds.push(this.createCommand(_("controls.aalemergency.quit-alarm"), Commands.AalEmergency.QUIT, null));
            } else if (states.status === AalEmergencyControlEnums.Status.DISABLED) {
                cmds.push(this.createCommand(_("controls.aalemergency.reactivate"), Commands.AalEmergency.REACTIVATE, null));
            }

            return cmds;
        }

        _showDisableTimerScreen(control) {
            let details = {
                title: _('controls.aalemergency.disable-function.heading'),
                pickerText: _('controls.aalemergency.disable-function.label'),
                callbackFn: (deactivateUntil) => {
                    return control.sendCommand(Commands.format(Commands.AalEmergency.DISABLE, deactivateUntil));
                }
            };

            NavigationComp.showState(ScreenState.TIMER_UNTIL_SCREEN, details);
        }

    };
});
