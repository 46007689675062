'use strict';

/**
 * Screen with:
 *
 *  details:
 * iconSrc  (must be an url, default)
 * title
 * message
 * btnTitle (if empty, no button will be shown)
 * link     (opens when tapping the button)
 */

class ServiceMessageScreen extends GUI.StaticScreenBase {
    //region Static
    static Template = function () {
        var getTemplate = function getTemplate(btnTitle) {
            return $('   <div class="service-message-screen__wrapper">' + '       <div class="wrapper__content">' + '           <div class="content__text-placeholder">' + '               <div class="text-placeholder__btn">' + (typeof btnTitle === "string" && btnTitle.toUpperCase()) + '</div>' + '           <div>' + '       </div>' + '   </div>');
        };

        return {
            getTemplate: getTemplate
        };
    }(); //endregion Static

    constructor(details) {
        super();
        this._iconSrc = details.iconSrc || Icon.INFO2;
        this._title = details.title;
        this._message = details.message;
        this._btnTitle = details.btnTitle;
        this._link = details.link;
    }

    viewDidLoad() {
        super.viewDidLoad(...arguments);
        this.element.addClass("service-message-screen");
        this.element.append(ServiceMessageScreen.Template.getTemplate(this._btnTitle));
        this.elements = {
            button: this.element.find(".content__text-placeholder .text-placeholder__btn")
        };

        if (!this._btnTitle) {
            this.elements.button.hide();
        }

        this.setUpComfortUI(true);
        this.setIcon(this._iconSrc);
        this.setTitle(this._title); // Don't use the function setSubtitle, as we use HTML here!

        this.elements.subtitleLbl.html(this._message.insertHrefs());
    }

    viewDidAppear() {
        super.viewDidAppear(...arguments);
        this._boundRemove = this.remove.bind(this);
        this.elements.button.on("click", function () {
            this._boundRemove();

            if (this._link) {
                NavigationComp.openWebsite(this._link);
            }
        }.bind(this));
        this.element.find("a[href]").click(this._boundRemove);
    }

    viewDidDisappear() {
        this.elements.button.off("click");
        this._boundRemove = null;
        super.viewDidDisappear(...arguments);
    }

    getAnimation() {
        return AnimationType.MODAL;
    }

    getURL() {
        return "ServiceMessage";
    }

    hasHistory() {
        return false;
    }

    closeAction() {
        this.remove();
    }

    getLeftIcon() {
        return Icon.Buttons.CLOSE;
    }

}

GUI.ServiceMessageScreen = ServiceMessageScreen;
