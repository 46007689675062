'use strict';
/*
 entries = [{time: "12:43:54", text: "Some Text"}]
 */

GUI.TableViewV2.CellType.Special.AUTOPILOT_EVENT_CELL = "AutopilotEventCell";

class AutopilotEventCell extends GUI.TableViewV2.Cells.GeneralCell {
    constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
        super(...arguments);
        this.element.addClass("autopilot-event-cell");
    }

    viewWillAppear() {
        super.viewWillAppear(...arguments);
        this.andHeader = $('<div class="autopilot-event-cell-header">' + _("and") + '</div>');
        this.element.before(this.andHeader);
    }

    viewDidDisappear() {
        super.viewDidDisappear(...arguments);
        this.andHeader.remove();
    }

}

GUI.TableViewV2.Cells.AutopilotEventCell = AutopilotEventCell;
