'use strict';
/*
 icon
 iconColor
 title
 active (boolean)
 activeColor
 onText
 offText
 useAntiGhostTimer
 disabled           If true, the baseclass handles the darkening of the cell, and this class disables the switch.

 onSwitchChanged(value, section, row, tableView, cell)
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.SWITCH = "SwitchCell";

    class SwitchCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(icon, title, subtitle, hasStateText) {
                return $((icon ? ImageBox.getResourceImageWithClasses(icon, 'content__icon') : '') + '<div class="content__texts-placeholder">' + '<div class="texts-placeholder__title">' + '<div class="title__text">' + title + '</div>' + '</div>' + '<div class="texts-placeholder__sub-title">' + '<div class="sub-title__text">' + (subtitle ? subtitle : "") + '</div>' + '</div>' + '</div>' + (hasStateText ? '<div class="content__state-text"></div>' : '') + '<div class="content__switch-placeholder" />');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("switch-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.contentPlaceholder.append(SwitchCell.Template.getTemplate(this.content.icon, this.content.title, this.content.subtitle, this.content.onText && this.content.offText));

            if (this.content.subtitleColor) {
                this.elements.subtitle = this.element.find('.sub-title__text');
                this.elements.subtitle.css('color', this.content.subtitleColor);
            }

            if (this.content.hasOwnProperty("iconColor")) {
                this.contentPlaceholder.find(".content__icon").css("fill", this.content.iconColor);
            }

            if (this.content.onText && this.content.offText) {
                this.stateLabel = this.contentPlaceholder.find(".content__state-text");
            }

            this.switch = new GUI.LxSwitch(
                this,
                this.contentPlaceholder.find(".content__switch-placeholder")[0],
                this.content.activeColor || window.Styles.colors.green,
                this.content.useAntiGhostTimer, // optionally the switch should make use of an anti ghost timer with 2s duration.
                null,
                this.content.active
            );
            this.switch.onStateChanged = this._onSwitchChanged.bind(this);

            this._updateStateLabel(this.content.active);

            this._updateStateLabel(this.content.active);
            this.switch.setEnabled(!this.content.disabled);

            if (this.content.button2Src) {
                this.elements.button = $('<div class="content__button">' + ImageBox.getResourceImageWithClasses(this.content.button2Src, "button__icon") + '</div>');
                this.elements.button.insertBefore(this.switch.getElement());

                this._addButton(this.elements.button, 1);
            }
        }

        destroy() {
            this.switch.destroy();
            this.switch = null;
            super.destroy();
        }

        // Public Methods
        // Overriding the base method, check if the switch exists, it doesn't exist if the base calls this function
        setActive(a) {
            if (this.switch) {
                this.switch.setActive(a);

                this._updateStateLabel(a);
            }
        }

        setEnabled(enabled) {
            super.setEnabled(...arguments);

            if (this.switch) {
                this.switch.setEnabled(enabled);
            }
        }

        // Private Methods
        _onSwitchChanged(a) {
            this._updateStateLabel(a);

            this.delegate.onSwitchChanged.call(this.delegate, a, this.sectionIdx, this.rowIdx, this.tableView, this);
        }

        _updateStateLabel(active) {
            if (this.content.onText && this.content.offText) {
                if (active) {
                    this.stateLabel.text(this.content.onText);
                } else {
                    this.stateLabel.text(this.content.offText);
                }
            }
        }

        _addButton(element) {
            var button = new GUI.LxButton(this, element, Color.BUTTON_GLOW);
            button.useChildsAsActiveParts("fill");
            this.addToHandledSubviews(button);

            button.onButtonTapped = function onButtonTapped() {
                this.delegate.buttonTapped && this.delegate.buttonTapped.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
            };

            button.onButtonHit = function onButtonHit() {
                this.delegate.buttonHit && this.delegate.buttonHit.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
            };

            button.onButtonReleased = function onButtonReleased() {
                this.delegate.buttonReleased && this.delegate.buttonReleased.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
            };

            button.onButtonDoubleTapped = function onButtonDoubleTapped() {
                this.delegate.buttonDoubleTapped && this.delegate.buttonDoubleTapped.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
            };
        }

    }

    GUI.TableViewV2.Cells.SwitchCell = SwitchCell;
    return GUI;
}(window.GUI || {});
