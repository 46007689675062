'use strict';

define(["ContentOverviewScreenBase", "SpotifyAccountManager", "MediaBrowserV2Base", "OverviewContentLoader"], function (ContentOverviewScreenBase, SpotifyAccountManager, MediaBrowserV2Base, OverviewContentLoader) {
    return class SpotifyContentOverviewScreen extends ContentOverviewScreenBase {
        //region Getter
        get spotifyId() {
            var currId = SpotifyAccountManager.getUserUnique(SpotifyAccountManager.shared(this.control).activeUser);

            if (currId) {
                this._spotifyId = currId;
            } else {
                console.warn(this.viewId, "spotifyId from manager is null or undefined, use previous! " + this._spotifyId);
                currId = this._spotifyId;
            }

            return currId;
        }

        get spotifyUsername() {
            return SpotifyAccountManager.shared(this.control).activeUserName;
        } //endregion Getter


        constructor(details) {
            super(...arguments);
            this._spotifyLoaders = {};
            this._loaderResults = {};
            Object.values(MusicServerEnum.Spotify.TYPES).forEach(function (type) {
                this._loaderResults[type] = [];
            }.bind(this));
            this.reloadContentTypes.push(this.EVENT_TYPES.SERVICE);
        }

        viewDidLoad() {
            this.isSpotifyOnboarding = this.ViewController instanceof GUI.AddMediaViewControllerSpotifyOnboarding;
            return super.viewDidLoad(...arguments);
        }

        setViewController() {
            super.setViewController(...arguments);
            this.setViewControllerHtmlAttr(MusicServerEnum.HtmlProp.CONTENT_ID, MusicServerEnum.ControlContentIdentifiers.SPOTIFY);
            this.setViewControllerHtmlAttr(MusicServerEnum.HtmlProp.ACC_DEP_CONTENT, true);

            if (!this.ViewController.handleOnSubtitleTapped) {
                this.ViewController.handleOnSubtitleTapped = function () {
                    SpotifyAccountManager.shared(this.control).showAccountSelector(this.ViewController);
                }.bind(this);
            }
        }

        receivedServiceReloadEvent(reason) {
            this._loaderResults = {};
            Object.values(MusicServerEnum.Spotify.TYPES).forEach(function (type) {
                this._loaderResults[type] = [];
            }.bind(this));
            this._spotifyLoaders = {};

            this.ViewController._checkTitlebar(); // this updates the spotify account in the titlebar


            if (reason !== MusicServerEnum.ReloadCause.STOPPED) {
                this._loadedInitialContent = false;
                return this.setup();
            } else {
                return Q(true);
            }
        }

        loadData() {
            var promises = [super.loadData(...arguments), SpotifyAccountManager.shared(this.control).prepare() // important when bg/foreground changes´
            ];
            return Q.all(promises).then(function () {
                if (SpotifyAccountManager.shared(this.control).availableUsers.length) {
                    Object.values(MusicServerEnum.Spotify.TYPES).filter(function (id) {
                        var filterIds = [MusicServerEnum.Spotify.TYPES.LIBRARY];

                        if (this.isSpotifyOnboarding) {
                            filterIds.push(MusicServerEnum.Spotify.TYPES.FEATURES);
                            filterIds.push(MusicServerEnum.Spotify.TYPES.NEW_RELEASE);
                            filterIds.push(MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS);
                            filterIds.push(MusicServerEnum.Spotify.TYPES.TRACKS);
                            filterIds.push(MusicServerEnum.Spotify.TYPES.ALBUMS);
                            filterIds.push(MusicServerEnum.Spotify.TYPES.ARTISTS);
                            filterIds.push(MusicServerEnum.Spotify.TYPES.PODCASTS);
                        }

                        return filterIds.indexOf(id) === -1;
                    }.bind(this)).map(function (id) {
                        return {
                            id: id,
                            contentType: this.EVENT_TYPES.SERVICE
                        };
                    }.bind(this)).forEach(function (item) {
                        switch (item.id) {
                            case MusicServerEnum.Spotify.TYPES.FEATURES:
                            case MusicServerEnum.Spotify.TYPES.NEW_RELEASE:
                            case MusicServerEnum.Spotify.TYPES.CATEGORIES:
                            case MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS:
                                this._loaderResults[item.id] = [];
                                this._spotifyLoaders[item.id] = new OverviewContentLoader(this.control, item, this.MAX_OVERVIEW_ITEM_CNT, this.getMediaInfo(item.contentType));
                                break;

                            default:
                                if (item.id === MusicServerEnum.Spotify.TYPES.TRACKS) {
                                    item.name = _("media.title");
                                    var userObj = SpotifyAccountManager.shared(this.control).activeUser;
                                    updateObject(item, this.control.audioserverComp.getSpotifyLikedSongsItem(userObj.id, userObj.user));
                                } else if (item.id === MusicServerEnum.Spotify.TYPES.ALBUMS) {
                                    item.name = _("media.album");
                                } else if (item.id === MusicServerEnum.Spotify.TYPES.ARTISTS) {
                                    item.name = _("media.artist");
                                } else if (item.id === MusicServerEnum.Spotify.TYPES.PODCASTS) {
                                    item.name = "Podcasts";
                                }

                                if (this._loaderResults[MusicServerEnum.Spotify.TYPES.LIBRARY]) {
                                    this._loaderResults[MusicServerEnum.Spotify.TYPES.LIBRARY].push(item);
                                } else {
                                    this._loaderResults[MusicServerEnum.Spotify.TYPES.LIBRARY] = [item];
                                }

                        }
                    }.bind(this));
                }

                return this._handleLoaderResponses();
            }.bind(this));
        }

        /**
         * Will return a promise that resolves once all loaders did at least notify with a result.
         * @returns {Q.Promise<unknown>}
         * @private
         */
        _handleLoaderResponses() {
            var contentReadyChecklist = {};
            var contentReadyDeferred = Q.defer();
            var postResolveReloadRequired = false;
            var allLoaderPromises = [];
            Object.values(this._spotifyLoaders).forEach(function (loader) {
                contentReadyChecklist[loader.item.id] = false;
                allLoaderPromises.push(loader.fetch().then(function (res) {
                    // loader finished downloading data!
                    contentReadyChecklist[loader.item.id] = true;
                    this._loaderResults[loader.item.id] = res;

                    if (loader.wasCacheUpdated() && !contentReadyDeferred.promise.isPending()) {
                        postResolveReloadRequired = true;
                    } else if (!loader.gotFromCache()) {
                        this._checkSpotifyContentReady(contentReadyChecklist, contentReadyDeferred);
                    }
                }.bind(this), function (error) {
                    // loader encountered an error while downloading data!
                    // ignore that no data was acquired, there's still some cached data.
                    if (!loader.gotFromCache()) {
                        console.error(this.viewId, "Loader failed & no cached content has been returned!");
                        contentReadyChecklist[loader.item.id] = true;
                        this._loaderResults[loader.item.id] = [];

                        this._checkSpotifyContentReady(contentReadyChecklist, contentReadyDeferred);
                    } else {
                        console.error(this.viewId, "Loader failed, but a cached content has been returned!");
                    }
                }.bind(this), function (notifRes) {
                    // loader got something from the cache!
                    contentReadyChecklist[loader.item.id] = true;
                    this._loaderResults[loader.item.id] = notifRes;

                    this._checkSpotifyContentReady(contentReadyChecklist, contentReadyDeferred);
                }.bind(this)));
            }.bind(this));

            this._checkSpotifyContentReady(contentReadyChecklist, contentReadyDeferred); // when all loaders are done, check if the table needs to be reloaded (e.g. cache was updated)


            Q.all(allLoaderPromises).finally(function () {
                postResolveReloadRequired && this.reloadTable();
            }.bind(this));
            return contentReadyDeferred.promise;
        }

        /**
         * Will check if all loaders did return content, if so, it will resolve the deferred.
         * @param checklist
         * @param deferred
         * @private
         */
        _checkSpotifyContentReady(checklist, deferred) {
            var allContentReady = Object.values(checklist).every(function (loaderFinished) {
                return loaderFinished;
            });

            if (allContentReady && deferred.promise.isPending()) {
                deferred.resolve();
            }
        }

        updateBackgroundView() {
            if (this.tableContent && this.tableContent.length) {
                this.tableView.setBackgroundView(null);
            } else if (this._loadedInitialContent && SpotifyAccountManager.shared(this.control).availableUsers.length === 0) {
                this.tableView.setBackgroundView(new GUI.NoSpotifyAccountView(this.control));
            } else {
                super.updateBackgroundView(...arguments);
            }
        }

        titleBarText() {
            return _("media.popup.spotify.title");
        }

        viewControllerSubtitleText() {
            if (SpotifyAccountManager.shared(this.control).activeUser) {
                return SpotifyAccountManager.shared(this.control).activeUserName;
            }

            return null;
        }

        getRightVCButtonIcon() {
            return null;
        }

        getRightVCButtonIconFn() {
            return null;
        }

        _shouldShowFavorites() {
            return false;
        }

        getTableContent() {
            return super.getTableContent(...arguments).then(function (tableContent) {
                if (!this._hasError) {
                    tableContent.pushObject(this._getSpotifySection(MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS));
                    tableContent.pushObject(this._getSpotifySection(MusicServerEnum.Spotify.TYPES.FEATURES));
                    tableContent.pushObject(this._getSpotifySection(MusicServerEnum.Spotify.TYPES.NEW_RELEASE));
                    tableContent.pushObject(this._getSpotifySection(MusicServerEnum.Spotify.TYPES.CATEGORIES));
                    tableContent.pushObject(this._getSpotifySection(MusicServerEnum.Spotify.TYPES.PODCASTS));
                    tableContent.pushObject(this._getLibSection());
                }

                return Q.all(tableContent);
            }.bind(this));
        }

        getMediaInfo(contentType) {
            if (contentType === this.EVENT_TYPES.SERVICE) {
                return {
                    service: {
                        uid: MusicServerEnum.Service.SPOTIFY + "/" + this.spotifyId,
                        cmd: MusicServerEnum.Service.SPOTIFY,
                        user: this.spotifyUsername
                    }
                };
            } else {
                return super.getMediaInfo(...arguments);
            }
        }

        getMediaId(contentType) {
            if (contentType === this.EVENT_TYPES.SERVICE) {
                return MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS;
            } else {
                return super.getMediaId(...arguments);
            }
        }

        handleOnItemCellClicked(item, contentType, section, row) {
            if (contentType === MusicServerEnum.MediaContentType.SERVICE) {
                var details = {
                    username: this.spotifyId,
                    lastSelectedItem: item,
                    contentTypes: [contentType],
                    identifier: MusicServerEnum.Service.SPOTIFY,
                    mediaType: MusicServerEnum.MediaType.SERVICE,
                    control: this.control
                };
                return this.ViewController.showState(Controls.AudioZoneV2Control.MediaBrowserV2Base.getScreenStateForItem(item), null, details);
            }

            return super.handleOnItemCellClicked(...arguments);
        }

        _getSpotifySection(type) {
            var loader = this._spotifyLoaders[type];

            if (loader) {
                return this._getSection(this._spotifyLoaders[type].title, this._loaderResults[type].map(function (item) {
                    return this._getSpotifyRow(item, type);
                }.bind(this)), this._openDetailedSpotifyList.bind(this, type));
            }
        }

        _getLibSection() {
            return this._getSection(_("media.library"), this._loaderResults[MusicServerEnum.Spotify.TYPES.LIBRARY].map(function (item) {
                return this._getSpotifyRow(item, MusicServerEnum.Spotify.TYPES.LIBRARY);
            }.bind(this)));
        }

        _openDetailedSpotifyList(id) {
            var item = this._spotifyLoaders[id].item;
            this.ViewController.showState(MediaBrowserV2Base.getScreenStateForItem(item), null, {
                username: this.spotifyId,
                lastSelectedItem: item,
                contentTypes: [item.contentType],
                mediaType: MusicServerEnum.MediaType.SERVICE,
                identifier: MusicServerEnum.Service.SPOTIFY,
                control: this.control
            });
        }

        _getSpotifyRow(item, spotifyType) {
            item.contentType = this.EVENT_TYPES.SERVICE;
            var details,
                ignoreContextMenu = false,
                cell,
                isLikedSongsItem = false;

            switch (spotifyType) {
                case MusicServerEnum.Spotify.TYPES.CATEGORIES:
                case MusicServerEnum.Spotify.TYPES.LIBRARY:
                    if (item.id === MusicServerEnum.Spotify.TYPES.TRACKS) {
                        isLikedSongsItem = true;
                        ignoreContextMenu = false;
                    } else {
                        ignoreContextMenu = true;
                    }

                    break;
            }

            if (spotifyType === MusicServerEnum.Spotify.TYPES.LIBRARY) {
                spotifyType = null;
            }

            details = {
                username: this.spotifyId,
                identifier: MusicServerEnum.Service.SPOTIFY,
                service: this.getMediaInfo(item.contentType).service,
                mediaType: MusicServerEnum.MediaType.SERVICE,
                lastSelectedItem: this._spotifyLoaders[spotifyType] ? this._spotifyLoaders[spotifyType].item : item,
                control: this.control,
                ignoreCtxMenu: ignoreContextMenu
            };
            cell = MediaBrowserV2Base.getConstructorForItem(item).getCellFromContentTypeItem(item, details, item.contentType, this.control.audioserverComp.isFileTypePlaylist(item.type) && this.ViewController instanceof GUI.AudioZoneV2ControlDetailedContentViewController || this.isSpotifyOnboarding, this.control.audioserverComp.isFileTypeBrowsable(item.type) && !(this.ViewController instanceof GUI.AddMediaViewControllerV2PlaylistPicker), this.handleOnItemCellClicked.bind(this), this.handleOnCellButtonClicked.bind(this), this.ViewController);

            if (!this.isInAddMode() || this.isSpotifyOnboarding) {
                cell.type = GUI.TableViewV2.CellType.Special.MEDIA_V2_COVER;
                cell.content.hidePlayButton = !spotifyType && !isLikedSongsItem || spotifyType === MusicServerEnum.Spotify.TYPES.CATEGORIES;
                delete cell.content.class; // Removes the chevron class
            }

            return cell;
        }

    };
});
