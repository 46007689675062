import React, {useCallback, useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";
import globalStyles from "GlobalStyles"
import {
    LxReactTableView,
    navigatorConfig
} from "LxComponents"
import {useBackNavigation} from "../../../../../../../react-comps/Components";

function NfcEditDialogScreen({navigation, route}) {
    const details = route.params;
    let nfcTag = details.nfcTag;
    let user = details.currentUser;

    const [nfcTagName, setNfcTagName] = useState(nfcTag.name);
    const originalNameRef = useRef(nfcTag.name)

    const onBackNavigation =  () => {
        let def = Q.defer();

        if (originalNameRef.current !== nfcTagName) {
            def.promise = ActiveMSComponent.addNfcTag(user.uuid, nfcTag.id, nfcTagName);
        } else {
            def.resolve();
        }

        def.promise.then(() => {
            navigation.navigate(ScreenState.UserDetailsScreen, {
                needsReload: "nfcTags",
                ts: moment().unix()
            })
        })
    }

    useBackNavigation(() => {
        onBackNavigation();
    }, []);

    useEffect(() => {
        navigation.setOptions(
            navigatorConfig({
                animationType: AnimationType.PUSH_OVERLAP_LEFT,
                title: _('details'),
                onLeftAction: onBackNavigation
            })
        );

    }, [nfcTagName])

    const _getContent = useCallback(() => {
        return [{
            rows: [
                {
                    type: GUI.TableViewV2.CellType.INPUT,
                    content: {
                        title: _('miniserver.name'),
                        placeholder: nfcTagName,
                        value: nfcTagName,
                        autoFocus: true
                    },
                    textChanged: function textChanged(section, row, tableView, value, valid, valueDidChange) {
                        setNfcTagName(validateNfcTagName(value));
                    }.bind(this),
                    submitText: function submitText(section, row, tableView, value, valid, valueDidChange) {
                        setNfcTagName(validateNfcTagName(value));
                    }.bind(this)
                },
                {
                    content: {
                        title: _("nfc.learning.deletetag"),
                        clickable: true,
                        titleStyle: {
                            color: globalStyles.colors.red
                        }
                    },
                    action: deleteNfcTag
                }
            ]
        }]
    }, []);

    const validateNfcTagName = (name) => {
        if (name === "" || name === null) {
            return nfcTagName;
        } else {
            return name;
        }
    }

    const deleteNfcTag = () => {
        return NavigationComp.showPopup({
            title: _("nfc.learning.deletetag"),
            message: _("nfc.learning.deletetag.message", {
                currentTag: nfcTag.name,
                username: user.name
            }),
            buttonOk: _("nfc.learning.yesdeletetag"),
            buttonCancel: true,
            icon: Icon.Notification.CAUTION,
            color: globalStyles.colors.red
        }, PopupType.GENERAL).then(() => {
            return ActiveMSComponent.removeNfcTag(user.uuid, nfcTag.id).then(() => {
                navigation.navigate(ScreenState.UserDetailsScreen, {
                    needsReload: "nfcTags",
                    ts: moment().unix()
                })
            });
        });
    }

    return (
        <LxReactTableView
            tableContent={_getContent()}
            showSectionSeparator={true}
            showItemSeparatorOnFirstItem={true}
        />
    )
}

NfcEditDialogScreen.propTypes = {
    nfcTag: PropTypes.object,
    user: PropTypes.object
}

export default NfcEditDialogScreen;
