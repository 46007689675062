'use strict';

window.GUI = function (GUI) {
    class AudioZoneControlContentViewControllerReactAbstractor extends GUI.ViewController {
        constructor(details) {
            super($('<div/>'));
        }

        getAnimation() {
            return AnimationType.FADE;
        }
    }

    GUI.AudioZoneControlContentViewControllerReactAbstractor = AudioZoneControlContentViewControllerReactAbstractor;

    class AudioZoneControlContentViewController extends GUI.ControlContentViewController {
        constructor(details) {
            super(details);
        }

        updateURL() {
            NavigationComp.updateURL(window.CURRENT_URL.split("/")[0] + "/" + this.getURL())
        }

        setReady(ready) {
            var notReadyIsVisible = this.history.stack.indexOf("AudioZoneNotReadyScreen") !== -1;

            this.updateURL()

            if (!ready) {
                if (!notReadyIsVisible) {
                    this.navigateBackToRoot().then(() => {
                        // Only do this if we are still have a control, we might have navigated back due to reachability change!
                        if (this.control) {
                            return super.showState("AudioZoneNotReadyScreen", null, {
                                control: this.control
                            });
                        }
                    });
                }
            } else if (notReadyIsVisible) {
                this.navigateBackToRoot();
            }

            this.element && this.element.toggleClass("audio-zone-control-content-view-controller--with-tiny-player", ready);
        }

        _presentInitialView() {
            return super._presentInitialView(...arguments).then(function () {
                var def = Q.defer(),
                    tinyPlayerCtor;

                if (!this.tinyPlayer && !this.control.isGrouped()) {
                    if (HD_APP) {
                        tinyPlayerCtor = require("TinyPlayerHD");
                    } else {
                        tinyPlayerCtor = require("TinyPlayer");
                    }
                    this.tinyPlayer = new tinyPlayerCtor(this.control);
                    this.tinyPlayer.setViewController(this);
                    def.resolve(this.appendSubview(this.tinyPlayer));
                } else {
                    def.resolve();
                }

                return def.promise;
            }.bind(this));
        }

    }

    GUI.AudioZoneControlContentViewController = AudioZoneControlContentViewController;
    return GUI;
}(window.GUI || {});
