import PropTypes from "prop-types";
import SearchBar from "./SearchBar";
import {LxReactTableView} from "LxComponents";
import globalStyles from "GlobalStyles";
import {useState, useCallback, useEffect, useRef, useMemo} from "react";
import {View} from "react-native";

export default function SearchableTable(props) {
    const  {
        showSearchBar = true,
        useTitle = true,
        useSubTitle = true,
        useTags = true
    } = props;
    const [internalContent, setInternalContent] = useState(props.tableContent);
    const filterText = useRef(null);
    const initialContent = useRef(props.tableContent);

    const meetsFilter = (text) => {
        return text && text.toLowerCase().includes(filterText.current.toLowerCase());
    }

    const updateContent = () => {
        if (!filterText.current || filterText.current === "" || !initialContent.current) {
            setInternalContent(initialContent.current);
            return;
        }
        let filteredContent = [],
            filteredRows;

        // provide a useCallback() function that takes the search term as a param and returns a tablecontent
        if (props.getCustomFilteredContent) {
            filteredContent = props.getCustomFilteredContent(filterText.current);
        } else {
            initialContent.current.forEach(section => {
                // rows are filtered by title, subtitle and searchtags, add the "excludeFromSearch" property to the row object to always show it
                filteredRows = section.rows.filter(row => {
                    if (row.excludeFromSearch) {
                        return true;
                    }
                    const {title, subTitle, subtitle, searchTags} = {...row, ...row.content};
                    let isValidRow = false;
                    if (useTitle && title) {
                        isValidRow = meetsFilter(title);
                    }
                    if (useSubTitle && (subtitle || subTitle)) {
                        isValidRow = isValidRow || meetsFilter(subTitle) || meetsFilter(subtitle);
                    }
                    if (useTags && searchTags) {
                        isValidRow = isValidRow || meetsFilter(searchTags);
                    }
                    return isValidRow;
                });
                if (filteredRows.length > 0) {
                    filteredContent.push({...section, rows: filteredRows });
                }
            });
        }

        setInternalContent(filteredContent);
    }

    const updateFilter = useCallback((newFilter) => {
        filterText.current = newFilter;
        props.handleFilterActive && props.handleFilterActive(newFilter);
        updateContent();
    }, [props.getCustomFilteredContent]);

    useEffect(() => {
        initialContent.current = props.tableContent;
        updateContent();
    }, [props.tableContent])


    const Styles = useMemo(() => {
        let style = {
                filterBar: { marginHorizontal: globalStyles.spacings.contentHorizontal, marginBottom: 15 },
                rootCntr: { flexDirection: "column", flex: 1 },
                tableView: {}
            };

        if (props.withoutHorizontalSpaces) {
            style.filterBar.marginHorizontal = 0;
            style.tableView = {
                List:{
                    paddingHorizontal: 0
                }
            };
        }

        return style;
    }, [props.withoutHorizontalSpaces])


    return <View style={[Styles.rootCntr, props.style]}>
        {showSearchBar ? <SearchBar
            style={Styles.filterBar}
            title={props.filterTitle}
            textChanged={updateFilter}
            onSubmitEditing={updateFilter}/> : null}
        <LxReactTableView
            styles={Styles.tableView}
            tableContent={internalContent} />
    </View>
}


SearchableTable.propTypes = {
    ...LxReactTableView.propTypes,
    handleFilterActive: PropTypes.func,
    filterTitle: PropTypes.string,
    hideFilter: PropTypes.bool,
    getCustomFilteredContent: PropTypes.func,
    withoutHorizontalSpaces: PropTypes.bool,
    useTitle: PropTypes.bool,
    useSubTitle: PropTypes.bool,
    useTags: PropTypes.bool
}
