'use strict';
/*
 type               --> FIXED: "search"
 leftIconSrc        --> FIXED: Icon.SEARCH
 selectable         --> FIXED: false
 title
 value
 placeholder
 autoFocus (boolean)
 validationRegex

 // SearchCell methods
 focus()    // focuses the input

 // SearchCell delegate
 textChanged(section, row, tableView, value, valid, valueDidChange) // TODO adopt to our global syntax! (+cell, +tableView!), name "SearchCellValueChanged"
 submitText(section, row, tableView, value, valid) // TODO adopt to our global syntax! (+cell, +tableView!), name "SearchCellValueChanged"
 // TODO add onSubmit function (onkeyup -> enter?)
 */

window.GUI = function (GUI) {
    class SearchCell extends GUI.TableViewV2.Cells.InputCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("search-cell");
        }

        // Public Methods
        setContent(content) {
            content.type = GUI.LxInputEnum.Type.SEARCH;
            content.selectable = false;
            super.setContent(...arguments);
        }

    }

    GUI.TableViewV2.Cells.SearchCell = SearchCell;
    GUI.TableViewV2.CellType.SEARCH = "SearchCell";
    return GUI;
}(window.GUI || {});
