'use strict';

define(['MediaSearchV2Base', 'AudioZoneV2ControlEnums', 'SpotifyAccountManager'], function (MediaSearchV2Base, AudioZoneV2ControlEnums, SpotifyAccountManager) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var DEFAULT_LIGHT_CELLS = GUI.TableViewV2.CellType.LIGHT,
        GLOBAL_IDENTIFIER = 'global',
        LAST_SELECTED_INDEX = 'lastSelectedIndex',
        SAVE_KEYWORD_THRESHOLD = 2000;
    return class GlobalSearchScreen extends MediaSearchV2Base {
        //region Getter
        get inputPlaceholder() {
            return _('media.global-search-button.title');
        }

        get contentType() {
            return this._getContentTypeForTag(this.tag);
        }

        get identifier() {
            return this._getIdentifierForTag(this.tag);
        }

        get mediaType() {
            return this._getMediaTypeForTag(this.tag);
        }

        get username() {
            if (this.tag === MusicServerEnum.Search_Result.SPOTIFY) {
                if (SpotifyAccountManager.shared(this.control).activeUser) {return SpotifyAccountManager.shared(this.control).activeUser.id;
            } else {
                console.error(this.name, "failed to get username, active user not set yet! prepared=" + SpotifyAccountManager.shared(this.control).isPrepared());
                    return null;
                }
            } else {return MusicServerEnum.NOUSER;
            }
        } //endregion Getter


        constructor(details) {
            super(...arguments);
            this.searchResult = {};
            this.uniqueIdentifier = "";
            this.searchResultSections = [];
        }

        /**
         * Not all views should adopt to ambient mode, hence provide an option to intercept.
         * @returns {*}
         */
        isInAmbientMode() {
            return AMBIENT_MODE; // the global search screen should adopt!
        }

        viewDidLoad() {
            var args = arguments,
                loadServicesPromise,
                keyWordsPromise,
                loadFilePromise;
            loadServicesPromise = this.control.audioserverComp.getCurrentServices().promise.then(function (services) {
                if (this.ViewController instanceof GUI.AddMediaViewControllerV2PlaylistSpotify || this.ViewController instanceof GUI.AddMediaViewControllerSpotifyOnboarding) {
                    this.tags.push(MusicServerEnum.Search_Result.SPOTIFY);
                } else if (this.ViewController instanceof GUI.AddMediaViewControllerV2Playlist) {
                    this.tags.push(MusicServerEnum.Search_Result.LOCAL);
                } else {
                    this.hasServices = !!services.length;
                    this.services = services;
                    this.tags.push(MusicServerEnum.Search_Result.LOCAL);

                    if (this.hasServices) {
                        this.tags.push(MusicServerEnum.Search_Result.SPOTIFY);
                    }

                    this.tags.push(MusicServerEnum.Search_Result.TUNE_IN);
                }
            }.bind(this));
            keyWordsPromise = this._getSavedKeywords();
            loadFilePromise = this._loadLastSelectedSegment();
            return loadServicesPromise.then(() => {
                return Q.all([keyWordsPromise, loadFilePromise, Q(super.viewDidLoad(...args) || true)]).finally(function () {
                    return this._updateAllTableViews([this._createLastSearchesSection()]);
                }.bind(this));
            });
        }

        viewWillAppear() {
            if (this.lastSelectedSegmentIndex > this.tags.length - 1) {
                this.lastSelectedSegmentIndex = this.tags.length - 1;
            }

            if (this.ViewController instanceof GUI.AddMediaViewControllerV2PlaylistSpotify || this.ViewController instanceof GUI.AddMediaViewControllerSpotifyOnboarding) {
                this.lastSelectedSegmentIndex = 1;
            }

            this.segmentedControl && this.segmentedControl.setActiveSegment(this.lastSelectedSegmentIndex, true);

            if (this.ViewController instanceof GUI.AddMediaViewControllerV2PlaylistSpotify || this.ViewController instanceof GUI.AddMediaViewControllerSpotifyOnboarding) {
                this.segmentedControl && this.segmentedControl.setDisabled(true);
                this.pageView.disableUserInteraction(true);
                this.lastSelectedSegmentIndex = 1;
            }

            this.element.toggleClass("ambient-mode-search", this.isInAmbientMode());

            this.pageView.setActivePage(this.lastSelectedSegmentIndex);
            return super.viewWillAppear(...arguments);
        }

        viewWillDisappear() {
            if (this._keywordSaveTimeout) {
                this.keyword && this.control.audioserverComp.saveKeyword(GLOBAL_IDENTIFIER, this.keyword, this.control.uuidAction);
                clearTimeout(this._keywordSaveTimeout);
                this._keywordSaveTimeout = null;
            }

            PersistenceComponent.saveFile(LAST_SELECTED_INDEX, this.segmentIndex.toString(), DataType.STRING);
            this.lastSelectedSegmentIndex = this.segmentIndex;
            super.viewWillDisappear(...arguments);
        }

        rightSectionButtonTapped(section, tableView) {
            var lastSelectedItem, showStateArgs;

            if (section === 0 && !this.isSearching) {
                this._lastUsedKeywords = [];
                this.control.audioserverComp.deleteKeywords(GLOBAL_IDENTIFIER, this.control.uuidAction).done(this._updateAllTableViews.bind(this, [this._createLastSearchesSection()]));
            } else if (this.isSearching) {
                lastSelectedItem = tableView.dataSource.tableContent[section]; // ensures e.g. "more" of local playlists are shown using the playlists-screen

                var contentTypeForResultBrowsing = this.contentType;

                if (contentTypeForResultBrowsing === MusicServerEnum.MediaContentType.LIBRARY && lastSelectedItem.type === MusicServerEnum.SearchResultType.PLAYLISTS) {
                    contentTypeForResultBrowsing = MusicServerEnum.MediaContentType.PLAYLISTS;
                }

                lastSelectedItem.fromSearch = true; // e.g. to hide the create playlist button when showing the list of playlists found

                lastSelectedItem.contentType = contentTypeForResultBrowsing;
                lastSelectedItem.name = lastSelectedItem.headerTitle + " - " + this.keyword;
                showStateArgs = [Controls.AudioZoneV2Control.MediaBrowserV2Base.getScreenStateForItem(lastSelectedItem), null, {
                    username: this.username,
                    lastSelectedItem: lastSelectedItem,
                    identifier: this.identifier,
                    contentTypes: [contentTypeForResultBrowsing],
                    mediaType: this.mediaType,
                    srcCmd: lastSelectedItem.link,
                    control: this.control
                }];
                this.ViewController.showState.apply(this.ViewController, showStateArgs);
            }
        }

        getMediaInfo() {
            var mediaInfo;

            switch (this.tag) {
                case MusicServerEnum.Search_Result.LOCAL:
                case MusicServerEnum.Search_Result.TUNE_IN:
                    mediaInfo = {
                        service: {
                            uid: this.identifier + "/" + MusicServerEnum.NOUSER
                        }
                    };
                    break;

                case MusicServerEnum.Search_Result.SPOTIFY:
                    mediaInfo = {
                        service: {
                            uid: this.identifier + "/" + this.username
                        }
                    };
                    break;
            }

            return mediaInfo;
        }

        /**
         * The user did hit enter - this is when the search must be triggered immediately.
         * @param value
         * @private
         */
        _onSubmit(value) {
            super._onSubmit(...arguments);

            if (value || value !== "") {
                this._keywordSaveTimeout && clearTimeout(this._keywordSaveTimeout);
                this._keywordSaveTimeout = setTimeout(function () {
                    value && this.control.audioserverComp.saveKeyword(GLOBAL_IDENTIFIER, value, this.control.uuidAction);
                    clearTimeout(this._keywordSaveTimeout);
                    this._keywordSaveTimeout = null;
                }.bind(this), SAVE_KEYWORD_THRESHOLD);
            }
        }

        _clearSearch() {
            super._clearSearch(...arguments);

            this._getSavedKeywords(GLOBAL_IDENTIFIER, this.control.uuidAction).then(function () {
                this.searchResultSections = [];
                this.tags.forEach(function (tag) {
                    this._updateTableContentAndBackgroundViewForTag(tag, this.EMPTY_VIEW);
                }.bind(this));

                this._updateAllTableViews([this._createLastSearchesSection()]);
            }.bind(this));
        }

        _onSearchError(response) {
            super._onSearchError(...arguments);

            this.uniqueIdentifier = "";
        }

        _createLastSearchesSection() {
            var section = {
                rows: []
            };

            if (this._lastUsedKeywords) {
                section.headerTitle = _('media.global-search.keywords');
                section.sectionRightButtonTitle = _("delete");

                this._lastUsedKeywords.forEach(function (keyword) {
                    section.rows.pushObject({
                        content: {
                            title: keyword,
                            color: window.Styles.colors.green
                        },
                        action: function () {
                            this._controlDefer && this._controlDefer.notify({
                                historyTerm: keyword
                            });

                            this._launchSearchFor(keyword);

                            this.searchInput && this.searchInput.removeFocus();
                        }.bind(this),
                        type: DEFAULT_LIGHT_CELLS
                    });
                }.bind(this));
            }

            return section;
        }

        _launchSearchFor(keyword) {
            Debug.Control.AudioZone.GlobalSearch && console.log(this.name, "_launchSearchFor: " + keyword, getStackObj());
            return super._launchSearchFor(...arguments);
        }

        _getSavedKeywords() {
            return this.control.audioserverComp.loadLastUsedKeywords(GLOBAL_IDENTIFIER, this.control.uuidAction).then(function (res) {
                if (res) {
                    this._lastUsedKeywords = res.reverse();
                }
            }.bind(this), function () {
                this._lastUsedKeywords = [];
            }.bind(this));
        }

        _loadLastSelectedSegment() {
            return PersistenceComponent.loadFile(LAST_SELECTED_INDEX, DataType.STRING).then(function (idx) {
                this.lastSelectedSegmentIndex = parseInt(idx) || 0;
            }.bind(this), function (err) {
                this.lastSelectedSegmentIndex = 0; // There is no problem, if the file doesn't exists
                // We don't have to throw an error
            }.bind(this));
        }

        _setSegmentSelectedType(idx) {
            var segmentEntry = this.segmentEntries[idx],
                mediaType = segmentEntry.mediaType,
                contentType = segmentEntry.contentType;
            this.control.audioserverComp.setSelectedSegmentType(mediaType, contentType);
            this._selectedSegmentedEntry = segmentEntry;
            this._selectedContentType = contentType;
        }

        _getNameForTag(tag) {
            var name;

            switch (tag) {
                case MusicServerEnum.Search_Result.LOCAL:
                    name = _("media.library");
                    break;

                case MusicServerEnum.Search_Result.SPOTIFY:
                    name = _("media.popup.spotify.title");
                    break;

                case MusicServerEnum.Search_Result.TUNE_IN:
                    name = _("media.service.webradio");
                    break;

                default:
                    name = tag;
            }

            return name;
        }

        _getContentTypeForTag(tag) {
            var contentType;

            switch (tag) {
                case MusicServerEnum.Search_Result.LOCAL:
                    contentType = MusicServerEnum.MediaContentType.LIBRARY;
                    break;

                case MusicServerEnum.Search_Result.SPOTIFY:
                case MusicServerEnum.Search_Result.TUNE_IN:
                    contentType = MusicServerEnum.MediaContentType.SERVICE;
                    break;

                default:
                    contentType = null;
            }

            return contentType;
        }

        _getIdentifierForTag(tag) {
            var identifier;

            switch (tag) {
                case MusicServerEnum.Search_Result.LOCAL:
                    identifier = MusicServerEnum.Service.LOCAL;
                    break;

                case MusicServerEnum.Search_Result.SPOTIFY:
                    identifier = MusicServerEnum.Service.SPOTIFY;
                    break;

                default:
                    identifier = null;
            }

            return identifier;
        }

        _getMediaTypeForTag(tag) {
            var mediaType;

            switch (tag) {
                case MusicServerEnum.Search_Result.LOCAL:
                    mediaType = MusicServerEnum.MediaType.LIBRARY;
                    break;

                case MusicServerEnum.Search_Result.SPOTIFY:
                case MusicServerEnum.Search_Result.TUNE_IN:
                    mediaType = MusicServerEnum.MediaType.SERVICE;
                    break;

                default:
                    mediaType = null;
            }

            return mediaType;
        }

    };
});
