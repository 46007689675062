'use strict';

define("SelectExtensionScreen", function () {
    return class SelectExtensionScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div/>'));
            this.searchType = details.searchType;
        }

        viewDidLoad() {
            var args = arguments;
            return ActiveMSComponent.getDevicesJSON().then(devicesObj => {
                ActiveMSComponent.setDeviceLearningImageObj(devicesObj);
                return ActiveMSComponent.getAvailableExtensions().then(extensions => {
                    return this._checkExtensionForSearch(extensions).then(() => {
                        return super.viewDidLoad(...args) || true;
                    });
                });
            });
        }

        // TableView DataSource Methods
        styleForTableView() {
            return TableViewStyle.COMFORT_MODE_2020;
        }

        titleBarText() {
            // tree.device-learning.title
            // air.device-learning.title
            return _(this.searchType + '.device-learning.title');
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.CLOSE
            };
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        reloadTable() {
            this.tableContent = [];
            this.tableContent.push(this._getHeaderSection()); // this function has no return value --> the tableContent will be manipulated in the function

            this._createSectionsForMiniservers();

            if (ActiveMSComponent.canSearchOverAllExtensions() && Feature.LEARN_DEVICE_OVER_APP_REMAKE) {
                if (this.availableExtensions.length > 1) {
                    this.tableContent.push(this._createSearchAllSection());
                }
            }

            return super.reloadTable(...arguments);
        }

        _getHeaderSection() {
            var description; // tree.device-learning.select-extension.title
            // air.device-learning.select-extension.title

            description = _(this.searchType + '.device-learning.select-extension.title');
            return {
                headerTitle: '',
                headerDescription: description,
                rows: []
            };
        }

        _createSectionsForMiniservers() {
            var miniserverSection, miniserverSectionIdx, sectionTemplate;
            this.availableExtensions.forEach(function (extension, idx) {
                // Reset the sectionTemplate every extension
                sectionTemplate = {
                    rows: []
                };
                miniserverSection = this.tableContent.find(function (section) {
                    return section.headerBoldTitle && section.headerBoldTitle.includes(extension.msName);
                }.bind(this));

                if (miniserverSection) {
                    miniserverSectionIdx = this.tableContent.indexOf(miniserverSection);
                    this.tableContent[miniserverSectionIdx].rows.push(this._createCellForExtension(extension));
                } else {
                    if (Feature.LEARN_DEVICE_OVER_APP_REMAKE) {
                        if (extension.msName) {
                            sectionTemplate.headerBoldTitle = _('device-learning.miniserver.name', {
                                msName: extension.msName
                            });
                        }

                        sectionTemplate.rows.push(this._createCellForExtension(extension));
                        this.tableContent.push(sectionTemplate);
                    } else {
                        if (this.tableContent.length > 1) {
                            this.tableContent[1].rows.push(this._createCellForExtension(extension));
                        } else {
                            sectionTemplate.rows.push(this._createCellForExtension(extension));
                            this.tableContent.push(sectionTemplate);
                        }
                    }
                }
            }.bind(this));
        }

        _createSearchAllSection() {
            return {
                rows: [{
                    content: {
                        clickable: true,
                        title: _('device-learning.search.all-extensions')
                    },
                    action: function () {
                        this.ViewController.startSearchOnExtension(DeviceManagement.TYPE.TREE);
                        this.ViewController.showState(ScreenState.DeviceSearch, null, {
                            searchType: this.searchType
                        });
                    }.bind(this)
                }]
            };
        }

        _createCellForExtension(extension) {
            return {
                type: GUI.TableViewV2.CellType.Special.DEVICE_LEARNING_CELL,
                content: {
                    clickable: extension.deviceState,
                    disclosureIcon: true,
                    title: extension.name,
                    subtitle: ActiveMSComponent.getDeviceMountingLocation(extension),
                    leftIconSrc: extension.imageUrl,
                    isOnline: extension.deviceState,
                    rightIconSrc: Icon.INFO2
                },
                buttonTapped: function () {
                    this.ViewController.showState(ScreenState.AboutDevice, null, extension);
                }.bind(this),
                action: function () {
                    this.ViewController.startSearchOnExtension(extension.serialNr);
                    this.ViewController.showState(ScreenState.DeviceSearch, null, {
                        selectedExtension: extension,
                        searchType: this.searchType
                    });
                }.bind(this)
            };
        }

        _checkExtensionForSearch(extensions) {
            var dummyArr = [],
                physicalArr = [],
                defer = Q.defer();
            extensions.forEach(function (extension) {
                if (extension.serialNr === DeviceManagement.DUMMY_AIRBASE || extension.serialNr === DeviceManagement.DUMMY_TREE_TO_AIR || extension.serialNr === DeviceManagement.DUMMY_TREE) {
                    dummyArr.push(extension);
                } else {
                    physicalArr.push(extension);
                }
            }.bind(this));

            if (!physicalArr.length && dummyArr.length) {
                this._showWarningPopup(_('device-learning.warning.dummy-extension.title'), _('device-learning.warning.no-extension.message'));

                defer.reject();
            } else if (!physicalArr.length && !dummyArr.length) {
                this._showWarningPopup(_('device-learning.warning.no-extension.title'), _('device-learning.warning.no-extension.message'));

                defer.reject();
            } else {
                this.availableExtensions = physicalArr;
                defer.resolve();
            }

            return defer.promise;
        }

        _showWarningPopup(title, message) {
            return NavigationComp.showPopup({
                title: title,
                message: message,
                buttonOk: _('ok.short'),
                buttonCancel: false,
                icon: Icon.CAUTION,
                color: window.Styles.colors.orange
            }, PopupType.GENERAL).then(function () {
                throw "cancel"; // If their is an error case, the view will not appear
            }.bind(this));
        }

    };
});
