'use strict';
/**
 * zone
 * rightButtonText: some text for the rightSide Button,
 * rightButtonColor: Default window.Styles.colors.green
 */

window.GUI = function (GUI) {
    class ZoneMediaCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getZoneMediaCellContent = function getZoneMediaCellContent(content) {
                var rightButtonTxt = content.rightButtonText ? content.rightButtonText : " ";
                return $('<div class="content__text-cntr">' + '<div class="text-cntr__main"></div>' + '<div class="text-cntr__sub"></div>' + '</div>' + '<div class="content__right-button">' + '<div class="right-button__text">' + rightButtonTxt + '</div>' + '</div>');
            };

            return {
                getZoneMediaCellContent: getZoneMediaCellContent
            };
        }(); //endregion Static

        constructor() {
            super(...arguments);
            Object.assign(this, StateHandler.Mixin);
            this.element.addClass("zone-media-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.control = this.content.zone;
            this.coverView = new Controls.AudioZoneControl.CoverView(this.content);
            this.coverView.element.addClass("content__cover");
            this.appendSubview(this.coverView, this.contentPlaceholder);
            this.contentPlaceholder.append(ZoneMediaCell.Template.getZoneMediaCellContent(this.content));
            this.elements.mainText = this.contentPlaceholder.find(".text-cntr__main");
            this.elements.subText = this.contentPlaceholder.find(".text-cntr__sub");
            this.elements.rightButton = this.contentPlaceholder.find(".content__right-button");
            this.elements.rightTextArea = this.contentPlaceholder.find(".right-button__text");
            this.elements.rightTextArea.css('background-color', this.content.rightButtonColor);
            this.buttons = {
                rightButton: new GUI.LxButton(this, this.elements.rightButton[0], Color.BUTTON_GLOW)
            };
            this.addToHandledSubviews(this.buttons.rightButton);
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);
            this.buttons.rightButton.onButtonTapped = this._handleRightButton.bind(this);

            this._registerForStates();
        }

        viewWillDisappear() {
            this.buttons.rightButton.onButtonTapped = null;

            this._unregisterStates();

            super.viewWillDisappear(...arguments);
        }

        clickableAllowed() {
            return false;
        }

        receivedStates(states) {
            this.coverView.setConfig(states);
            var newStates = cloneSelective(states, 'texts');

            if (JSON.stringify(this.states) !== JSON.stringify(newStates)) {
                this.states = newStates;
                this.elements.mainText.text(states.texts.mainText);
                this.elements.subText.text(states.texts.subText);
            }
        }

        _handleRightButton() {
            this.delegate.onZoneMediaRightButtonTapped.call(this.delegate, this, this.sectionIdx, this.rowIdx, this.tableView);
        }

    }

    GUI.TableViewV2.Cells.ZoneMediaCell = ZoneMediaCell;
    GUI.TableViewV2.CellType.Special.ZONE_MEDIA = "ZoneMediaCell";
    return GUI;
}(window.GUI || {});
