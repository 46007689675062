'use strict';

define(['AutopilotUtility', 'AutopilotEnums'], function (AutopilotUtility, AutopilotEnums) {
    return class AutopilotOperatingModeScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div class="autopilot-operatingmode-action-screen">'));
            this.values = AutopilotEnums.ActiveInactiveOptions;
            this.operatingMode = details.operatingMode;
            this.title = this.operatingMode.value;

            if (details.event) {
                this.originalEvent = details.event;
                this.event = cloneObject(details.event);
                this.event.type = AutopilotEnums.EventTypes.OperatingMode;
                this.event.id = this.operatingMode.id;
            } else if (details.action) {
                this.originalAction = details.action;
                this.action = cloneObject(details.action);
                this.action.type = AutopilotEnums.ActionTypes.OperatingMode;
                this.action.id = this.operatingMode.id;
            }

            this.editMode = typeof details.action !== 'undefined' && Object.keys(details.action).length > 0 || typeof details.event !== 'undefined' && Object.keys(details.event).length > 0;
        }

        getURL() {
            return "Autopilot/OperatingMode";
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarText() {
            return this.title;
        }

        numberOfRowsInSection(section) {
            return this.values.length;
        }

        cellTypeForCellAtIndex(section, row) {
            return GUI.TableViewV2.CellType.GENERAL;
        }

        contentForCell(cell, section, row) {
            var option = this.values[row];
            return {
                title: option.title,
                rightIconSrc: Icon.TRANSLUCENT_ADD,
                rightIconColor: window.Styles.colors.green,
                clickable: true
            };
        }

        didSelectCell(cell, sectionIdx, row) {
            if (this.action) {
                this.action.value = this.values[row].value;

                if (this.editMode) {
                    AutopilotUtility.copyValues(this.action, this.originalAction);
                    NavigationComp.dispatchEventToUI(AutopilotEnums.CommunicationEvents.UPDATE_ACTION, this.originalAction);
                } else {
                    NavigationComp.dispatchEventToUI(AutopilotEnums.CommunicationEvents.ADD_ACTION, this.action);
                    NavigationComp.disableAnimationsTemp();
                }
            } else if (this.event) {
                this.event.value = this.values[row].value;

                if (this.editMode) {
                    AutopilotUtility.copyValues(this.event, this.originalEvent);
                    NavigationComp.dispatchEventToUI(AutopilotEnums.CommunicationEvents.UPDATE_EVENT, this.originalEvent);
                } else {
                    NavigationComp.dispatchEventToUI(AutopilotEnums.CommunicationEvents.ADD_EVENT, this.event);
                    NavigationComp.disableAnimationsTemp();
                }
            }

            this.ViewController.navigateBackTo(AutopilotEnums.ScreenStates.AutopilotRuleScreen);
        }

    };
});
