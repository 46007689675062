"use strict";

define(['JalousieControlContentConfigInfoScreen', 'JalousieControlContentConfigWaitingScreen', 'JalousieControlContentConfigAdjustScreen', 'JalousieControlContentConfigSettingsScreen', 'JalousieControlContentConfigHelpScreen', 'JalousieControlEnums'], function (JalousieControlContentConfigInfoScreen, JalousieControlContentConfigWaitingScreen, JalousieControlContentConfigAdjustScreen, JalousieControlContentConfigSettingsScreen, JalousieControlContentConfigHelpScreen, JalousieControlEnums) {
    return class JalousieControlContentConfigDialog extends Controls.ControlContent {
        constructor(details) {
            super(details);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.elements = {
                viewControllerPlaceholder: $('<div class="jalousie-control-content-config-dialog__view-controller-placeholder" />')
            };
            this.element.append(this.elements.viewControllerPlaceholder);
            this.configViewController = new GUI.ViewController();
            this.configViewController.addMultipleView(JalousieControlEnums.ScreenState.ADJUST);
            this.configViewController.addMultipleView(JalousieControlEnums.ScreenState.INFO);
            this.configViewController.addMultipleView(JalousieControlEnums.ScreenState.WAITING);
            this.appendSubview(this.configViewController, this.elements.viewControllerPlaceholder);
        }

        titleBarAction() {
            if (this._isInLearningMode()) {
                this._userAbortPrompt();
            } else {
                this._handleUserAbort();
            }
        }

        titleBarText() {
            return NBR_SPACE;
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.CLOSE,
                style: 'transparent'
            };
        }

        getURL() {
            var url = super.getURL() + "/Config/";

            if (this.configViewController) {
                url += this.configViewController.getURL();
            }

            return url;
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        destroy() {
            if (this._isInLearningMode()) {
                this.sendCommand(Commands.format(Commands.JALOUSIE.CONFIG.LEARN, Commands.JALOUSIE.CONFIG.ABORT));
            }

            super.destroy();
        }

        receivedStates(states) {
            var statesStr = JSON.stringify(states);

            if (this._cachedStates === statesStr) {
                // we need this fix, because the Miniserver sends updates after activating the config mode
                // an position update arrives after a short time and we would navigate to the settings...
                // to reproduce: start config mode and quickly start slats adjustment mode.. the settings will be shown after a second...
                // ignoring all unnecessary updates helps!
                return;
            }

            this._cachedStates = statesStr;

            if (this.currentMode === states.currentMode && this.currentStep === states.currentStep) {
                //console.info("bailing out due to duplicate status update!");
                return;
            } // handle timeouts


            if (states.currentStep === JalousieControlEnums.ConfigSteps.ERROR.LOCK_TIMEOUT) {
                this._handleLockTimeout();
            } else if (this._isInLearningMode() && states.currentStep === JalousieControlEnums.ConfigSteps.ERROR.LEARN_TIMEOUT) {
                this._handleLearnTimeout();

                this.currentMode = states.currentMode; // also set the modes to ignore updates afterwards

                this.currentStep = states.currentStep; // when mode and step goes back to 0

                return; // return, don't change the current view
            } else if (states.currentMode === JalousieControlEnums.ConfigModes.READY) {
                this._showSettings();

                return;
            }

            if (states.currentMode === JalousieControlEnums.ConfigModes.FINISHED && // if finish mode
                this.currentMode !== states.currentMode && // & if mode changed
                !states.configurationNeeded) {
                // & if no configuration is needed
                if (typeof this.currentMode === "undefined") {
                    // if it's undefined, we just opened the dialog, show the settings
                    this._showSettings();
                } else {
                    if (this.currentMode === JalousieControlEnums.ConfigModes.SET_END_POSITION && this.currentStep !== JalousieControlEnums.ConfigSteps.ERROR.LEARN_TIMEOUT && // don't show success when we receive an error
                        this.currentStep !== JalousieControlEnums.ConfigSteps.ERROR.LOCK_TIMEOUT) {
                        // we just did the initial configuration (or reconfiguration), show the success screen
                        this._showInfo(JalousieControlEnums.InfoScreenTypes.SUCCESS);
                    } else {
                        // we just corrected something, don't show the info screen
                        this._showSettings();
                    }
                }
            } else if (states.currentMode === JalousieControlEnums.ConfigModes.FINISHED && states.configurationNeeded) {
                this._showInfo(JalousieControlEnums.InfoScreenTypes.INFO);
            } else if (states.currentMode !== JalousieControlEnums.ConfigModes.FINISHED && (this.currentMode !== states.currentMode || this.currentStep !== states.currentStep)) {
                // the mode/step changed, show next screen (waiting or adjust)
                if (this._isWaitingStep(states.currentMode, states.currentStep)) {
                    this._showWaitingScreen(states.currentMode, states.currentStep);
                } else {
                    this._showAdjustScreen(states.currentMode, states.currentStep);
                }
            }

            this.currentMode = states.currentMode;
            this.currentStep = states.currentStep;
        }

        _showInfo(type) {
            if (type === JalousieControlEnums.InfoScreenTypes.SUCCESS) {
                this._updateTitleBarText(_('controls.jalousie.correct.servicemode.title'));
            } else if (type === JalousieControlEnums.InfoScreenTypes.START_SLAT_ADJUSTMENT) {
                this._updateTitleBarText(_('controls.jalousie.config.settings.correct-slats-position'));
            } else {
                this._updateTitleBarText(this.control.name);
            }

            this.configViewController.showState(JalousieControlEnums.ScreenState.INFO, null, {
                control: this.control,
                type: type
            });
        }

        _showSettings() {
            this._updateTitleBarText(_('controls.jalousie.correct.servicemode.title'));

            this.configViewController.showState(JalousieControlEnums.ScreenState.SETTINGS, null, {
                control: this.control,
                onSlatsAdjustmentStarted: function () {
                    this._showInfo(JalousieControlEnums.InfoScreenTypes.START_SLAT_ADJUSTMENT);
                }.bind(this)
            });
        }

        _showWaitingScreen(mode, step) {
            this._updateTitleBarText(this._getTextForMode(mode));

            this.configViewController.showState(JalousieControlEnums.ScreenState.WAITING, null, {
                mode: mode,
                step: step
            });
        }

        _showAdjustScreen(mode, step) {
            this._updateTitleBarText(this._getTextForMode(mode));

            this.configViewController.showState(JalousieControlEnums.ScreenState.ADJUST, null, {
                control: this.control,
                mode: mode,
                step: step
            });
        }

        _handleLockTimeout() {
            if (this._lockTimeoutPopup) return;
            this._lockTimeoutPopup = this._handleTimeout(_("controls.jalousie.config.error.learning-lock")); // remove reference if popup is removed

            this._lockTimeoutPopup.done(function () {
                this._lockTimeoutPopup = null;
            }.bind(this), function () {
                this._lockTimeoutPopup = null;
            }.bind(this));
        }

        _handleLearnTimeout() {
            if (this._learnTimeoutPopup) return;
            this._learnTimeoutPopup = this._handleTimeout(_("controls.jalousie.config.error.learn-timeout")); // remove reference if popup is removed

            this._learnTimeoutPopup.done(function () {
                this._learnTimeoutPopup = null;
            }.bind(this), function () {
                this._learnTimeoutPopup = null;
            }.bind(this));
        }

        _handleTimeout(message) {
            var popupContent = {
                message: message,
                buttonOk: _("finish"),
                icon: Icon.WRENCH,
                color: window.Styles.colors.red
            };
            var popup = NavigationComp.showPopup(popupContent);
            popup.then(function () {
                this.sendCommand(Commands.format(Commands.JALOUSIE.CONFIG.LEARN, Commands.JALOUSIE.CONFIG.ABORT));

                this._handleUserAbort();
            }.bind(this));
            return popup;
        }

        _userAbortPrompt() {
            var popupContent = {
                message: _('controls.jalousie.config.user-abort-prompt'),
                buttonOk: _("yes"),
                buttonCancel: _("no"),
                icon: Icon.WRENCH,
                color: window.Styles.colors.red
            };
            return NavigationComp.showPopup(popupContent).done(function () {
                this._handleUserAbort();
            }.bind(this));
        }

        _handleUserAbort() {
            this.ViewController.dismissModal();
        }

        _updateTitleBarText(text) {
            this._titleBar.setTitleBarSideTexts(text);
        }

        _getTextForMode(mode) {
            switch (mode) {
                case JalousieControlEnums.ConfigModes.FINISHED:
                    return _('controls.jalousie.correct.servicemode.title');

                case JalousieControlEnums.ConfigModes.SET_END_POSITION:
                    return _('controls.jalousie.correct.servicemode.title');

                case JalousieControlEnums.ConfigModes.CORRECT_END_POSITION:
                    return _('controls.jalousie.config.settings.correct-endposition');

                case JalousieControlEnums.ConfigModes.MOVE_END_POSITION:
                    break;
                // not needed atm

                case JalousieControlEnums.ConfigModes.ADJUST_SLATS:
                    return _('controls.jalousie.config.settings.correct-slats-position');
            }

            return "";
        }

        _isWaitingStep(mode, step) {
            if (mode === JalousieControlEnums.ConfigModes.ADJUST_SLATS) {
                return step === JalousieControlEnums.ConfigSteps.SLAT_ADJUSTMENT.MOVING_DOWN || step === JalousieControlEnums.ConfigSteps.SLAT_ADJUSTMENT.MOVING_UP;
            } else {
                return false;
            }
        }

        /**
         * returns true if the currentMode is one of the learning modes
         * @returns {boolean}
         * @private
         */
        _isInLearningMode() {
            return this.currentMode === JalousieControlEnums.ConfigModes.SET_END_POSITION || this.currentMode === JalousieControlEnums.ConfigModes.CORRECT_END_POSITION || this.currentMode === JalousieControlEnums.ConfigModes.MOVE_END_POSITION || this.currentMode === JalousieControlEnums.ConfigModes.ADJUST_SLATS;
        }

    };
});
