'use strict';

define([], function () {
    return class StatisticHeader extends GUI.View {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(showImage) {
                var valueHTMLString = '';

                if (showImage) {
                    valueHTMLString += '<div class="value-label__icon-bg image--on"></div>' + '<div class="value-label__icon-bg image--off"></div>';
                }

                valueHTMLString += '<div class="label-placeholder__value-label">' + '   <span class="value-label__value"></span>' + '</div>';
                return $('<div class="statistic-header">' + '   <div class="header__button-placeholder placeholder--left">' + '       ' + ImageBox.getResourceImageWithClasses('resources/Images/Controls/Statistic/left.svg', 'button-icon button-icon--left') + '   </div>' + '   <div class="header__label-placeholder">' + '       ' + valueHTMLString + '       <div class="label-placeholder__time-label"></div>' + '       ' + getLoadingIndicator() + '   </div>' + '   <div class="header__button-placeholder placeholder--right">' + '       ' + ImageBox.getResourceImageWithClasses('resources/Images/Controls/Statistic/right.svg', 'button-icon button-icon--right') + '   </div>' + '</div>');
            };

            var getLoadingIndicator = function getLoadingIndicator() {
                return '<div class="label-placeholder__loading-indicator css-spinner-a"></div>';
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(controlUUID, output) {
            super();
            this.control = ActiveMSComponent.getStructureManager().getControlByUUID(controlUUID);
            this.noDataDefinedValue = this.control.getStatisticNoDataValue();
            this.noDataDefinedText = this.control.getStatisticNoDataEntryLabelText();

            if (output && output.visuType === Statistic.Type.DIGITAL) {
                if (this.control.details) {
                    if (this.control.details.text) {
                        this.onText = this.control.details.text.on;
                        this.offText = this.control.details.text.off;
                    } else if (this.control.details.image) {
                        this.onImage = this.control.details.image.on;
                        this.offImage = this.control.details.image.off;
                    }
                } else {
                    this.onText = _('on');
                    this.offText = _('off');
                }
            }

            this.format = output.format ? output.format : "%.1f";
            this.visuType = output.visuType;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.element = StatisticHeader.Template.getTemplate(this.visuType === Statistic.Type.DIGITAL && this.control.details && this.control.details.image);
            this.elements = {
                backButton: this.element.children('.placeholder--left'),
                forwardButton: this.element.children('.placeholder--right'),
                timestampLabel: this.element.find('.label-placeholder__time-label'),
                labelPlaceholder: this.element.find('.label-placeholder__value-label'),
                valueLabel: this.element.find('.value-label__value'),
                indicator: this.element.find('.label-placeholder__loading-indicator')
            };

            if (this.visuType === Statistic.Type.DIGITAL && this.control.details && this.control.details.image) {
                this.elements.onImage = this.element.find('.image--on').hide();
                this.elements.offImage = this.element.find('.image--off').hide();

                this._setIcon(this.elements.onImage[0], this.onImage);

                this._setIcon(this.elements.offImage[0], this.offImage);
            }

            this.buttons = {
                backButton: new GUI.LxButton(this, this.elements.backButton[0], Color.BUTTON_GLOW, null, true, 0),
                forwardButton: new GUI.LxButton(this, this.elements.forwardButton[0], Color.BUTTON_GLOW, null, true, 0)
            };
            this.buttons.backButton.useChildsAsActiveParts('fill');
            this.buttons.forwardButton.useChildsAsActiveParts('fill');
            this.buttons.backButton.onButtonReleased = this.emit.bind(this, "back");
            this.buttons.backButton.onButtonTicked = this.emit.bind(this, "back");
            this.buttons.forwardButton.onButtonReleased = this.emit.bind(this, "forward");
            this.buttons.forwardButton.onButtonTicked = this.emit.bind(this, "forward");
            this.addToHandledSubviews(this.buttons.backButton);
            this.addToHandledSubviews(this.buttons.forwardButton);
        }

        updateStatisticHeader(value, time) {
            this.elements.indicator.remove();

            this.elements.labelPlaceholder.css("color", window.Styles.colors.green)

            if (value === this.noDataDefinedValue) {
                this.elements.valueLabel.text(this.noDataDefinedText);
            } else if (isNaN(value) || value === Infinity) {
                // Infinity means loading!
                if (this.visuType === Statistic.Type.DIGITAL && this.control.details && this.control.details.image) {
                    this.elements.onImage.hide();
                    this.elements.offImage.hide();
                    this.elements.labelPlaceholder.show();
                }

                this.elements.valueLabel.text("");

                if (isNaN(value)) {
                    this.elements.valueLabel.text(_('statistics.no-data'));
                } else if (value === Infinity) {
                    this.elements.valueLabel.text(_('loading.data') + " ...");
                }
            } else {
                if (this.visuType === Statistic.Type.DIGITAL) {
                    if (typeof this.onText === "string" && typeof this.offText === "string") {
                        if (value === 1) {
                            this.elements.valueLabel.text(this.onText);
                        } else {
                            this.elements.valueLabel.text(this.offText);
                        }
                    } else if (this.elements.onImage && this.elements.offImage) {
                        this.elements.labelPlaceholder.hide();

                        if (value === 1) {
                            this.elements.onImage.show();
                            this.elements.offImage.hide();
                        } else {
                            this.elements.onImage.hide();
                            this.elements.offImage.show();
                        }
                    }
                } else {
                    var txt;

                    if (lxUnitConverter.isConvertable(this.format)) {
                        txt = lxUnitConverter.convertAndApply(this.format, value);
                    } else {
                        txt = lxFormat(this.format, value);
                    }

                    this.elements.valueLabel.html(txt);
                }
            }

            this.elements.timestampLabel.text(time);
        }

        _setIcon(imgBg, iconSrc) {
            ImageBox.getImage(iconSrc).then(function (image) {
                if (image.mimeType === "svg") {
                    imgBg.innerHTML = image.image;
                    imgBg.style.backgroundColor = "transparent";
                } else {
                    imgBg.innerHTML = '<img class="icon-bg__icon" src="' + image.image + '">';
                    imgBg.style.backgroundColor = "";
                }
            });
        }

    };
});
