'use strict';
/**
 * Prevents the TableContent for the AudioZoneControlContent
 */

define('AudioZoneSpotifyHelper', ["ControlActionCellsScreen", "AudioZoneControlEnums", "SpotifyAccountHelper"], function (ControlActionCellsScreen, AudioZoneControlEnums, SpotifyAccountHelper) {
    return class AudioZoneSpotifyHelper {
        constructor(controlContent, delegate) {
            this.controlContent = controlContent;
            this.currentControl = cloneObject(this.controlContent.control);
            this.delegate = delegate;
            this.spotifyId = null;
        }

        getLoadingSection() {
            return {
                identifier: MediaEnum.Service.SPOTIFY,
                headerTitle: _('media.popup.spotify.title'),
                rows: [{
                    content: {
                        title: _("loading"),
                        leftIconSrc: Icon.INDICATOR
                    }
                }]
            };
        }

        prepareSpotifySection(services, spotifyId) {
            this.services = services;
            this.spotifyId = spotifyId;
            this.spotifyUser = this.getCurrentSpotifyUserObj().user;
            var btnColor = window.Styles.colors.green,
                onBtnTapped = this.showSpotifySelectorContextMenu.bind(this, null);

            if (this.controlContent.ViewController instanceof GUI.AddMediaViewControllerBase) {
                btnColor = Color.TEXT_SECONDARY_B;
                onBtnTapped = null;
            }

            var section = {
                identifier: MediaEnum.Service.SPOTIFY,
                headerTitle: _('media.popup.spotify.title'),
                sectionRightButtonColor: btnColor,
                rightSectionButtonTapped: onBtnTapped,
                rows: []
            };
            section.sectionRightButtonTitle = this.spotifyUser;

            if (this.services.length > 1) {
                section.sectionRightButtonTitle += " ▾";
            }

            return section;
        }

        showSpotifySelectorContextMenu(ctxMenuHandler) {
            var servicesPrms,
                control = MediaServerComp.getActiveZoneControl();

            if (!this.services) {
                servicesPrms = MediaServerComp.getCurrentServices().promise;
            } else {
                servicesPrms = Q(this.services);
            }

            servicesPrms.then(function (services) {
                if (services.length > 1) {
                    return SpotifyAccountHelper.getCurrentAccountId(control.uuidAction).then(function (currentSpotId) {
                        this.services = services;
                        ctxMenuHandler = ctxMenuHandler || this.controlContent;
                        var contextMenuContent;

                        if (this.services.length) {
                            if (!this.services.find(function (service) {
                                return SpotifyAccountHelper.getUniqueAccountId(service) === currentSpotId;
                            }) || !currentSpotId) {
                                currentSpotId = SpotifyAccountHelper.getUniqueAccountId(this.services[0]);
                            }
                        }

                        contextMenuContent = this.services.map(function (service) {
                            var spotifyId = SpotifyAccountHelper.getUniqueAccountId(service);
                            return {
                                selected: currentSpotId === spotifyId,
                                title: service.user,
                                action: function () {
                                    if (currentSpotId !== spotifyId) {
                                        SpotifyAccountHelper.saveSpotifyAccountId(spotifyId);
                                    }
                                }.bind(this)
                            };
                        }.bind(this));

                        ctxMenuHandler._showContextMenu(contextMenuContent, _('media.spotify.account-selector.title'));
                    }.bind(this));
                } else {
                    console.info("Only one account available, don't show the selector!");
                }
            }.bind(this));
        }

        getSpotifyContent() {
            var cmd = Commands.format(MediaEnum.Commands.PLAYLIST.GET_SERVICE_FOLDER, MediaEnum.Service.SPOTIFY, this.spotifyUser, 'start', 0, 50);
            return MediaServerComp.sendMediaServerCommand(cmd).then(function (res) {
                return this._createSpotifyRows(cloneObject(res.data[0].items));
            }.bind(this));
        }

        getCurrentSpotifyUserObj() {
            var spotifyUserObj = null;

            if (this.spotifyId && this.services) {
                spotifyUserObj = this.services.find(function (service) {
                    return SpotifyAccountHelper.getUniqueAccountId(service) === this.spotifyId;
                }.bind(this));
            }

            if (!spotifyUserObj) {
                spotifyUserObj = this.services[0];
            }

            return spotifyUserObj;
        }

        // ++++++++++++++++++++++++++++++++++++++++++++++++
        // ++++++++++++++++++++++++++++++++++++++++++++++++
        // ------------------------------------------------
        //     Private Functions
        // ------------------------------------------------
        // ++++++++++++++++++++++++++++++++++++++++++++++++
        // ++++++++++++++++++++++++++++++++++++++++++++++++
        _createSpotifyRows(options) {
            var libObj = {
                name: _("media.library"),
                id: MediaEnum.Spotify.TYPES.LIBRARY,
                contentType: MediaEnum.MediaContentType.SERVICE,
                items: options.slice(4, 7),
                myPlaylist: options[3]
            }; // Add the contentType to every spotify object

            options.forEach(function (option) {
                if (option.id === MediaEnum.Spotify.TYPES.MY_PLAYLISTS) {
                    option.name = _("media.playlists");
                }

                option.contentType = MediaEnum.MediaContentType.SERVICE;
            }); // Modify the options to group Tracks, Albums and Artists in a Library "folder"

            options.splice(4, 3, libObj);
            return options.map(function (option) {
                return this._getCellForItem(option);
            }.bind(this));
        }

        _getCellForItem(item, hasDisclosureIcon) {
            var iconObj = Controls.AudioZoneControl.MediaBrowserBase.getConstructorForItem(item).getIconObjForItem(item, MediaEnum.Service.SPOTIFY);
            return {
                content: {
                    clickable: true,
                    title: item.name,
                    leftIconSrc: iconObj.highRes,
                    leftIconColor: iconObj.color,
                    disclosureIcon: false,
                    class: !HD_APP || hasDisclosureIcon ? "base-cell--with-chevron" : null
                },
                action: function () {
                    this.delegate && this.delegate.onCellSelected && this.delegate.onCellSelected.apply(this.delegate, arguments);

                    if (item.id !== MediaEnum.Spotify.TYPES.LIBRARY) {
                        this._onSpotifyItemClicked(item, iconObj);
                    } else {
                        this.delegate && this.delegate.showDetailed && this.delegate.showDetailed(AudioZoneControlEnums.ScreenState.SPOTIFY_LIBRARY, null, {
                            items: item.items,
                            myPlaylist: item.myPlaylist,
                            delegate: this,
                            control: this.controlContent.control,
                            icon: iconObj.highRes,
                            username: this.spotifyUser
                        });
                    }
                }.bind(this)
            };
        }

        _getScreenStateDetailsForItem(item) {
            var iconObj = Controls.AudioZoneControl.MediaBrowserBase.getConstructorForItem(item).getIconObjForItem(item, MediaEnum.Service.SPOTIFY);
            return {
                username: this.spotifyUser,
                lastSelectedItem: item,
                contentTypes: [MediaEnum.MediaContentType.SERVICE],
                mediaType: MediaEnum.MediaType.SERVICE,
                identifier: MediaEnum.Service.SPOTIFY,
                icon: iconObj.highRes
            };
        }

        _onSpotifyItemClicked(item) {
            this.delegate && this.delegate.showDetailed(Controls.AudioZoneControl.MediaBrowserBase.getScreenStateForItem(item), null, this._getScreenStateDetailsForItem(item));
        }

    };
});
