import React, { useState, useEffect } from "react";

export default () => {
    const [HD, setHD] = useState(HD_APP);
    useEffect(() => {
        setHD(HD_APP);
    }, [HD_APP])

    return HD;
}
