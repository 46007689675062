import PropTypes from "prop-types";
import {
    navigatorConfig,
    LxReactTableView,
    useBackNavigation
} from "LxComponents";
import { useRef, useState } from "react";


function SortingScreen({navigation, route}) {
    const details = route.params;

    navigation.setOptions(
        navigatorConfig({
            animationType: AnimationType.MODAL,
            navigation,
            title: _('new-sorting.title'),
            onLeftAction: () => {_handleBackNavigation()}
        })
    );
    const [sortingTableContent, setSortingTableContent] = useState(details.tableContent);
    const msSortingChanged = useRef(false);

    useBackNavigation(() => {
        _handleBackNavigation();
    }, [sortingTableContent]); // Handling for hardware back button

    const _getTableContent = () => {
        return cloneObjectDeep(sortingTableContent).map(section => {
            return {
                ...section,
                rows: section.rows.map((row => {
                    if (row.type.includes("GroupCard")) {
                        return {
                            ...row,
                            type: GUI.TableViewV2.CellType.GENERAL,
                            content: {
                                title: row.content.title2,
                                leftIconSrc: row.content.iconSrc,
                                clickable: false
                            }
                        }
                    } else if (row.type.includes("Card")) {
                        return {
                            ...row,
                            type: GUI.TableViewV2.CellType.GENERAL,
                            content: {
                                title: row.content.control.name,
                                subtitle: row.content.control.groupDetail,
                                leftIconSrc: row.content.control.getIcon(),
                                clickable: false
                            }
                        }
                    }
                }))
            }
        });
    }

    const handleMsMoved = (section, oldIdx, newIdx) => {
        if(!msSortingChanged.current) {
            msSortingChanged.current = true;
        } // If sorting has changed, set the flag to true
        setSortingTableContent(tableContent => {
            let tmpTableContent = cloneObjectDeep(tableContent);
            tmpTableContent[section].rows.move(oldIdx, newIdx);
            return tmpTableContent;
        });
    }

    const _handleBackNavigation = () => {
        if(msSortingChanged.current) { // If sorting has changed, update the shared data
            ActiveMSComponent.createSortingStructureForTab(sortingTableContent, details.getFavForIndex, details.getSortingLocation);
            ActiveMSComponent.updateSharedData();
        }
        navigation.goBack();
    }

    return (
        <LxReactTableView
            tableContent={_getTableContent()}
            performanceStyle={LxReactTableView.PerformanceStyle.Editable}
            editMap={{removable: false, sortable: true}}
            onCellMove={handleMsMoved}
            insets={["bottom"]}
        />
    )
}

export default SortingScreen;

SortingScreen.propTypes = {
    tableContent: PropTypes.object,
    getFavForIndex: PropTypes.func,
    getSortingLocation: PropTypes.func
};
