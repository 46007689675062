'use strict';

define([], function () {
    return {
        DeviceTypes: {
            NO_DEVICE: 0,
            STEAK_AIR: 1
        },
        DeviceStates: {
            IN_USE: 0,
            OFFLINE: 1,
            SLEEPING: 2
        },
        PowerMode: {
            Undefined: 0,
            DC: 1,
            BATTERY: 2
        },
        ScreenStates: {
            RenameScreen: "SteakRenameScreen",
            TargetTempSelectorScreen: "SteakTargetTempSelectorScreen",
            SettingsScreen: "SteakControlContentSettings",
            TimeSelectorScreen: "SteakTimeSelectorScreen"
        },
        TARGET_TEMP: {
            MIN: 10,
            // The lowest temperature is cold smoking with about 15°C
            MAX: 350 // 350°C Is the maximal operating temperature of the sensors

        },
        TIMER_BOUNDRY: {
            // In milliseconds
            MIN: 1000,
            MAX: (99 * 60 + 59) * 1000 // Must be in milliseconds
            //Minutes -^ Seconds -^ limit of the 7 Segment Display of the Steak thermometer

        },
        LOW_BAT_WARNING_PERCENTAGE: 10,
        // %. this information has been requested from Manuel Rohrauer
        STATS_NO_SENSOR_CONNECTED_VALUE: -255
    };
});
