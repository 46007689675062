'use strict';

define(["ControlActionCellsScreen", "IntercomControlInfoView", "IntercomControlContentSettings", "IntercomControlAboutIntercomScreen", "IntercomControlContentMissedList", "IntercomControlEnums", "IntercomVideoView"], function (ControlActionCellsScreen, IntercomControlInfoView, IntercomControlContentSettings, IntercomControlAboutIntercomScreen, IntercomControlContentMissedList, IntercomControlEnums, IntercomVideoView) {
    return class IntercomControlContent extends ControlActionCellsScreen {
        constructor() {
            super(...arguments);
            this.hasRequiredMediaDevices = false;
        }

        viewDidLoad() {
            // Checking the Media devices is an async task, that should only be done once per navigation to the control
            // because the user may connect an audio device without having to restart the app
            return this._hasRequiredMediaDevice().then(hasRequiredMediaDevices => {
                this.hasRequiredMediaDevices = hasRequiredMediaDevices;
                Debug.Control.Intercom.General && console.log(this.name, "hasRequiredMediaDevices: " + this.hasRequiredMediaDevices);
                return Q.all([super.viewDidLoad(...arguments) || true]).then(function () {
                    if (HD_APP && this.control.hasVideoInfo()) {
                        this.element.addClass("intercom-control-content--video");
                    }
                }.bind(this));
            });
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments); // This will be an AlertControlContent if someone rings
            // We want to hide the IntercomControlContent after the maximal ringing duration + this.getDismissTimeoutDuration() (default: 60 seconds)
            // so we don't update the antiScreenSaver timeout

            if (!this.ViewController.alert) {
                this._updateAntiScreenSaver();
            }
        }

        viewWillDisappear() {
            this._updateAntiScreenSaver(true);

            super.viewWillDisappear(...arguments);
        }

        destroy() {
            if (this.control.hasAudio() && this.hasRequiredMediaDevices) {
                this.endCall();
                SipAdapter.resetSIPCallStates(this.control.details.audioInfo.user, this.control.details.audioInfo.host);
            }

            super.destroy(...arguments);
        }

        getTableContent(states) {
            var content = super.getTableContent(...arguments),
                hasVideo = this.control.hasVideoInfo();

            if (!content || !content.length) {
                content = [{
                    rows: []
                }];
            }

            if (this.control.hasAudio() && this.hasRequiredMediaDevices) {
                if (states.isTalking) {
                    content[0].rows.unshift({
                        content: {
                            title: hasVideo ? _("terminate") : _("controls.intercom.voice.terminate-connection"),
                            icon: hasVideo ? Icon.Intercom.DISCONNECT : null,
                            color: window.Styles.colors.red,
                            fillColor: Color.WHITE
                        },
                        action: this.endCall.bind(this)
                    });
                } else {
                    content[0].rows.unshift({
                        content: {
                            title: hasVideo ? _("establish") : _("controls.intercom.voice.establish-connection"),
                            icon: hasVideo ? Icon.Intercom.CONNECT : null,
                            color: states.isCalling ? Color.STATE_INACTIVE : window.Styles.colors.stateActive,
                            fillColor: Color.WHITE
                        },
                        action: this.makeCall.bind(this)
                    });
                }
            } // Cells will be added to the state rows if the intercom only has SIP


            if (hasVideo) {
                if (states.hasLastActivities) {
                    content[0].rows.push({
                        content: {
                            title: _("controls.intercom.activity"),
                            icon: Icon.Intercom.BELL
                        },
                        action: this.presentLastActivities.bind(this)
                    });
                }

                content[0].rows.push({
                    content: {
                        title: _("settings"),
                        icon: Icon.SETTINGS
                    },
                    action: this.presentSettingsWindow.bind(this)
                });
            }

            return content;
        }

        /*getStateRows(states) {
            // We won't return any rows if the Intercom has VideoInfos,
            // All the cells are added in getTableContent();
            if (this.control.hasVideoInfo()) {
                return null;
            }

            var rows = [];

            if (states.hasLastActivities) {
                rows.push({
                    content: {
                        title: _("controls.intercom.last-activity"),
                        disclosureIcon: true
                    },
                    type: GUI.TableViewV2.CellType.GENERAL,
                    action: this.presentLastActivities.bind(this)
                });
            }

            rows.push({
                content: {
                    title: _("settings"),
                    disclosureIcon: true
                },
                type: GUI.TableViewV2.CellType.GENERAL,
                action: this.presentSettingsWindow.bind(this)
            });
            return rows;
        } */

        getActionTableView() {
            if (this.control.hasVideoInfo()) {
                if (HD_APP) {
                    return new GUI.CenteredCardView(this.tableViewDataSource, this.tableViewDelegate);
                } else {
                    return new GUI.CardView(this.tableViewDataSource, this.tableViewDelegate);
                }
            } else {
                return super.getActionTableView(...arguments);
            }
        }

        getActionTableViewCellType() {
            if (this.control.hasVideoInfo()) {
                return GUI.TableViewV2.CellType.Special.INTERCOM_ACTION_CELL;
            } else {
                return GUI.TableViewV2.CellType.Special.COMFORT_ACTION;
            }
        }

        _createCellObjectForCmd(cmdObj) {
            // The icon will be prioritized, that's why we need to delete it before the base call if the control has no video info
            if (!this.control.hasVideoInfo()) {
                delete cmdObj.icon;
            }

            var cellObj = super._createCellObjectForCmd(cmdObj);

            if (this.control.hasVideoInfo()) {
                cellObj.content.title = cmdObj.name;
                cellObj.type = this.getActionTableViewCellType();
                return cellObj;
            }

            return cellObj;
        }

        receivedStates(states) {
            super.receivedStates(...arguments);
            var wasTalking = !!this.isTalking;
            this.isTalking = states.isTalking; // Either the user is talking or he hung up

            if (wasTalking !== this.isTalking) {
                this._updateAntiScreenSaver();
            }

            if (this.hadSipError !== states.hasError && states.hasError) {
                this.presentCurrentSipError(states.error);
            }

            this.hadSipError = states.hasError;
        }

        closeAction() {
            this.acknowledgeBell(true);
            super.closeAction(...arguments);
        }

        getCustomInfoView() {
            if (this.control.hasVideoInfo()) {
                return new IntercomControlInfoView(this.control);
            } else {
                return null;
            }
        }

        /**
         * If we are on HD and the control has videoInfo we prepend the customInfoView
         * to be able to display it fullscreen
         * @param view
         */
        setCustomInfoView(view) {
            if (HD_APP && this.control.hasVideoInfo()) {
                this.customInfoView && this.removeSubview(this.customInfoView);
                this.elements.customInfoBox.empty();
                this.customInfoView = view;

                if (view) {
                    this.elements.customInfoBox.css("display", "");
                    this.prependSubview(this.customInfoView);
                } else {
                    this.elements.customInfoBox.hide();
                }
            } else {
                super.setCustomInfoView(...arguments);
            }
        }

        shouldDisplayStateIcons() {
            return !this.control.hasVideoInfo();
        }

        /**
         * Will start an audio call to the intercom. If the bell is active it'll be acknowledged.
         */
        makeCall() {
            var platformInfo = PlatformComponent.getPlatformInfoObj(),
                majorPlatformVersion = parseInt(platformInfo.version); // Reset the error flag every time we make a new call

            this.hadSipError = false;
            SipAdapter.makeCallToControl(this.control);
            this.acknowledgeBell(true);
        }

        endCall() {
            SipAdapter.hangUp();
        }

        /**
         * Will acknowledge the bell. If this is being done when the alert closes, ensure the dontRecord flag is set
         * to exclude the task from being recorded.
         * @param [dontRecord]  set if the command is sent without the user tapping a specific button.
         */
        acknowledgeBell(dontRecord) {
            // set "dontRecord" flag, because we don't want this cmd to be recorded in NFC/QR/Task/..
            this.control.getStates().isBellRinging && this.sendCommand(Commands.INTERCOM.ANSWER, null, null, !!dontRecord);
        }

        presentLastActivities() {
            Debug.Control.Intercom.General && console.log(this.name, "presentLastActivities");
            this.ViewController.showState(IntercomControlEnums.ScreenState.MISSED_LIST, null, {
                control: this.control
            });
        }

        presentSettingsWindow() {
            var screenState = IntercomControlEnums.ScreenState.ABOUT;

            if (this.control.hasAudio() && this.hasRequiredMediaDevices) {
                screenState = IntercomControlEnums.ScreenState.SETTINGS;
            }

            this.ViewController.showState(screenState, null, {
                control: this.control
            });
        }

        videoActive() {
            return this.control.hasVideoInfo();
        }

        /**
         * Checks if the bell is active
         * @param [states] State is optional, this function will get the controls states automatically if not defined
         * @returns {*}
         */
        bellActive(states) {
            if (!states) {
                states = this.control.getStates();
            }

            return states && states.isBellRinging;
        }

        /**
         * If this content should be dismissed automatically after a given timeout
         * This only applies to alerts, this.ViewController.alert must be true for this function to have any effect
         * @param states
         * @returns {boolean}
         */
        shouldDismissAfterTimeout(states) {
            return !this.bellActive(states) && !states.isTalking;
        }

        // ----------------------------------------------------------------------------------------------------
        //   ScreenSaver & Automatic Dismiss handling.
        // ----------------------------------------------------------------------------------------------------

        /**
         * Will start or stop the a timer that triggers ticks to prevent the screensaver to become active while
         * either audio or video is active.
         * During an alert this will be done by the controlContentAlertViewController itself, but the screensaver
         * also mustn't open up while the intercom is in use while no alert is active - that is the reason why
         * the handling is implemented here too.
         * @param forceStop
         * @private
         */
        _updateAntiScreenSaver(forceStop) {
            var videoActive = this.videoActive();
            Debug.Control.Intercom.General && console.log(this.name, "_updateAntiScreenSaver: a=" + !!this.isTalking + ", v=" + !!videoActive + " - force stop: " + !!forceStop);

            if ((videoActive || this.isTalking) && !forceStop) {
                // either audio or video is active, so if we don't have to stop, ensure the antiScreenSaverTick is
                // up and running.
                if (!this.antiScreenSaverTicker) {
                    this.antiScreenSaverTicker = setInterval(function () {
                        SandboxComponent.activityTick()
                    }.bind(this), IntercomControlEnums.Timeouts.SCREENSAVER_TICK);
                }
            } else {
                // stop the antiScreenSaverTick!
                this.antiScreenSaverTicker && clearInterval(this.antiScreenSaverTicker);
                this.antiScreenSaverTicker = null;
            }
        }

        presentCurrentSipError(error) {
            NavigationComp.showPopup(SipAdapter.getErrorInfoContent(this.control, error));
        }

        /**
         * Asynchronously checks if the device has at least one audio input and output device
         * @note Only the Electron platforms must be checked (for now) because some pcs may not have speakers or microphones (mainly Windows and Linux).
         * @returns {*}
         * @private
         */
        _hasRequiredMediaDevice() {
            var platform = PlatformComponent.getPlatformInfoObj().platform,
                def = Q.defer(),
                deviceTypes;

            if (platform === PlatformType.Mac || platform === PlatformType.Linux || platform === PlatformType.Windows) {
                def.resolve(navigator.mediaDevices.enumerateDevices().then(function (devices) {
                    deviceTypes = devices.map(function (device) {
                        return device.kind;
                    });
                    return deviceTypes.indexOf("audioinput") !== -1 && deviceTypes.indexOf("audiooutput") !== -1;
                }));
            } else {
                def.resolve(platform === PlatformType.IOS || platform === PlatformType.Android);
            }

            return def.promise;
        }

    };
});
