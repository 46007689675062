'use strict';

define(['IRoomControllerV2ControlEnums'], function (IRCV2Enums) {
    return class IRoomControllerV2OperatingModesScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($("<div/>"));
            this.control = details.control;
            this.titleText = details.titleText;
            this.states = this.control.getStates();
            this.currentOpModeNr = this.states.operatingMode;
            this.newOpModeNr = this.currentOpModeNr;
            this.currentOpModeGroup = this.control.convertModeToGroup(this.currentOpModeNr);
        }

        getAnimation() {
            return AnimationType.HD_OVERLAY;
        }

        // Private methods
        titleBarText() {
            return this.titleText || _('pool.control.settings.operating-mode');
        }

        reloadTable() {
            var isAutomaticMode = this.control.isAutomaticMode(this.newOpModeNr),
                //automaticTitle = Feature.IRC_V2021 ? _("controls.ircv2021.mode-auto-based-on-schedule") : _('controls.ircv2.op-mode.automatic'),
                automaticTitle = Feature.IRC_V2021 ? _("controls.ircv2021.setpoint-by-schedule") : _('controls.ircv2.op-mode.automatic'),
                manualTitle = Feature.IRC_V2021 ? _("controls.ircv2021.mode-fixed-setpoint") : _('controls.ircv2.op-mode.manual');
            this.tableContent = [{
                rows: [{
                    content: {
                        title: automaticTitle,
                        selected: isAutomaticMode,
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                        description: _('controls.ircv2.op-mode.automatic.desc')
                    },
                    type: GUI.TableViewV2.CellType.CHECKABLE_DESCRIPTION,
                    didCheckCell: this._setNewOpMode.bind(this, this.control.convertModeToGroup(IRCV2Enums.OperatingMode.AUTOMATIC.HEATING_AND_COOLING), true)
                }, {
                    content: {
                        title: manualTitle,
                        selected: this.control.isFixedSetpointMode(this.newOpModeNr),
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                        description: _('controls.ircv2.op-mode.manual.desc')
                    },
                    type: GUI.TableViewV2.CellType.CHECKABLE_DESCRIPTION,
                    didCheckCell: this._setNewOpMode.bind(this, this.control.convertModeToGroup(IRCV2Enums.OperatingMode.MANUAL.HEATING_AND_COOLING), false)
                }]
            }, this._getHeatCoolSettingsSection()]; // Temperature limit settings - depending on whether or not temperature limits are to be minded

            if (Feature.IRC_OFF) {
                var isOff = this.control.isInOffMode(this.newOpModeNr);
                this.tableContent[0].rows.push({
                    content: {
                        title: _("off"),
                        selected: isOff,
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED
                    },
                    type: GUI.TableViewV2.CellType.CHECKABLE,
                    didCheckCell: this._setNewOpMode.bind(this, this.control.convertModeToGroup(IRCV2Enums.OperatingMode.OFF), false)
                })
            }

            if (this._supportsTemperatureLimits()) {
                this.tableContent.push(this._getTemperatureLimitsSection());
            }

            return super.reloadTable(...arguments);
        }

        _getHeatCoolSettingsSection() {
            var canHeatCool = Feature.IRC_V2021 ? this.control.hasHeatCoolCapability() : true,
                disabled = this.newOpModeNr === IRCV2Enums.OperatingMode.OFF;

            if (canHeatCool) {
                return {
                    headerTitle: _('settings'),
                    headerDescription: _('controls.ircv2.op-mode.temperature-limit-description'),
                    rows: [{
                        content: {
                            title: _('controls.ircv2.op-mode.settings.heating-and-cooling'),
                            selected: this.control.convertModeToGroup(this.newOpModeNr) === IRCV2Enums.OperatingModeGroup.HEATING_AND_COOLING,
                            radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                            disabled
                        },
                        type: GUI.TableViewV2.CellType.CHECKABLE,
                        didCheckCell: function () {
                            this._setNewOpMode(IRCV2Enums.OperatingModeGroup.HEATING_AND_COOLING);
                        }.bind(this)
                    }, {
                        content: {
                            title: _('controls.ircv2.op-mode.settings.heating-only'),
                            selected: this.control.convertModeToGroup(this.newOpModeNr) === IRCV2Enums.OperatingModeGroup.ONLY_HEATING,
                            radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                            disabled
                        },
                        type: GUI.TableViewV2.CellType.CHECKABLE,
                        didCheckCell: function () {
                            this._setNewOpMode(IRCV2Enums.OperatingModeGroup.ONLY_HEATING);
                        }.bind(this)
                    }, {
                        content: {
                            title: _('controls.ircv2.op-mode.settings.cooling-only'),
                            selected: this.control.convertModeToGroup(this.newOpModeNr) === IRCV2Enums.OperatingModeGroup.ONLY_COOLING,
                            radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                            disabled
                        },
                        type: GUI.TableViewV2.CellType.CHECKABLE,
                        didCheckCell: function () {
                            this._setNewOpMode(IRCV2Enums.OperatingModeGroup.ONLY_COOLING);
                        }.bind(this)
                    }]
                };
            } else {
                return null;
            }
        }

        _setNewOpMode(opModeGroup, isAutomatic) {
            if (isAutomatic === undefined) {
                isAutomatic = this.control.isAutomaticMode(this.newOpModeNr);
            }

            this.newOpModeNr = this.control.convertGroupToMode(opModeGroup, isAutomatic);
            this.control.sendCommand(Commands.format(Commands.I_ROOM_V2_CONTROLLER.SET_OPERATING_MODE, this.newOpModeNr));
            this.reloadTable();
        }

        _supportsTemperatureLimits() {
            // whether or not ouside temperatures are to be minded is configured via expert mode, but received as state.
            // versions not supporting this feature, won't have a currentAutomatic-State.
            return this.states.hasOwnProperty("currentAutomatic") && this.states.currentAutomatic === IRCV2Enums.CurrentAutomatic.OUTSIDE_TEMP_DEPENDANT && this._currOpModeSupportsTempLimits();
        }

        _currOpModeSupportsTempLimits() {
            var supported = false; // only when both heating and cooling are supported, the temp limits are available

            switch (this.newOpModeNr) {
                case IRCV2Enums.OperatingMode.AUTOMATIC.HEATING_AND_COOLING:
                case IRCV2Enums.OperatingMode.MANUAL.HEATING_AND_COOLING:
                    supported = true;
                    break;
            }

            return supported;
        }

        _getTemperatureLimitsSection() {
            var section = {
                    rows: []
                },
                description = _("controls.climate.temp-boundary.desc.mixed"),
                btnTitle = _("controls.climate.set.temp-boundary", {
                    count: 2
                }),
                // there are two limits
                title = _("controls.climate.temp-boundary", {
                    count: 2
                });

            section.headerTitle = title;
            section.headerDescription = description;
            section.rows.push({
                content: {
                    title: btnTitle,
                    disclosureIcon: true,
                    clickable: true
                },
                action: this._showTemperatureLimitsScreen.bind(this)
            });
            return section;
        }

        _showTemperatureLimitsScreen() {
            return this.ViewController.showState(IRCV2Enums.ScreenState.AVG_TEMP_LIMITS, null, {
                control: this.control,
                operatingMode: this.newOpModeNr
            });
        }

    };
});
