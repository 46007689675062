'use strict';

window.GUI = function (GUI) {
    class RenameLightCircuitsScreen extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            this.control = details.control;
            this.circuitNames = cloneObjectDeep(this.control.getStates().circuitNames);
        }

        viewWillDisappear() {
            return super.viewWillDisappear(...arguments).then(function () {
                if (JSON.stringify(this.circuitNames) !== JSON.stringify(this.control.getStates().circuitNames)) {
                    this.control.sendCommand(Commands.format(Commands.LIGHTCONTROL_V2.RENAME_CIRCUITS, JSON.stringify(this.circuitNames)));
                }
            }.bind(this));
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            return _("controls.lightV2controller.lightCircuitsRename");
        }

        styleForTableView() {
            return TableViewStyle.COMFORT_MODE_2020;
        }

        reloadTable() {
            var section = {
                rows: []
            };
            Object.keys(this.circuitNames).forEach(function (key, index) {
                section.rows.push({
                    type: GUI.TableViewV2.CellType.INPUT,
                    content: {
                        type: "text",
                        value: this.circuitNames[key],
                        validationRegex: Regex.NAME,
                        title: _("controls.lightV2controller.lightCircuitsNumber", {
                            number: index + 1
                        }),
                        showGreen: false
                    },
                    textChanged: function (section, row, tableView, value) {
                        this.circuitNames[key] = value;
                    }.bind(this)
                });
            }.bind(this));
            this.tableContent = [section];
            if (Feature.DAYLIGHT) {
                this.tableContent.push(this._getDayLightSection());
            }
            return super.reloadTable(...arguments);
        }


        _getDayLightSection() {
            var section = {
                headerTitle: _('settings'),
                rows: []
            };
            section.rows.push({
                content: {
                    title: _('controls.daylight.full-name'),
                    disclosureIcon: true
                },
                action: function () {
                    this.ViewController.showState(ScreenState.DayLightSettingsScreen, null, {
                        control: this.control
                    });
                }.bind(this)
            });
            return section;
        }
    }

    GUI.RenameLightCircuitsScreen = RenameLightCircuitsScreen;

    return GUI;
}(window.GUI || {});
