'use strict';

define(["./controlSettingsEditIconScreenComp.jsx"], function (EditIconScreenComp) {
    return class ControlSettingsEditIconScreen extends GUI.Screen {
        static get ReactComp() {
            return EditIconScreenComp.default;
        }

        constructor() {
            super($("<div />"));
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
        }

        getAnimation() {
            return AnimationType.MODAL;
        }
    };
});
