'use strict';

define(['ControlActionSliderScreen', 'SaunaControlEnums'], function (ControlActionSliderScreen, SaunaControlEnums) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var SLIDER_RATE = 1.0; // seconds

    return class TargetValueSelectorScreenBase extends ControlActionSliderScreen {
        constructor(control) {
            super(...arguments);
            this.antiGhostTimer = new AntiGhostTimer(this._updateSubtitle.bind(this));
            this.initialModification = true;
        }

        /**
         * These views always have a custom title.
         */
        getTitle() {
            throw new Error("getTitle method has to be overwritten!");
        }

        /**
         * Such views only need to have one state ID.
         */
        getStateID() {
            throw new Error("getStateID method has to be overwritten!");
        }

        /**
         * To send the value to the miniserver.
         * @param value
         */
        formatCommand(value) {
            throw new Error("formatCommand method has to be overwritten!");
        }

        /**
         * To display the current value.
         */
        formatValue(value) {
            throw new Error("formatValue method has to be overwritten!");
        }

        /**
         * Returns an array of mode ids that are okay for this view. If the current mode is not contained at the
         * time when a value is sent, the mode will be changed to manual.
         */
        getAcceptibleModes() {
            return [SaunaControlEnums.SaunaModes.SAUNA_MANUAL.nr];
        }

        /**
         * This function returns the config of the slider, it is crucial to implement it!
         * @param states The control states
         * @returns {{min, max, step, antiGhostTimer: boolean}}
         */
        getSliderConfig(states) {
            return {
                min: 0,
                max: 100,
                step: 0.5,
                antiGhostTimer: true,
                customRate: SLIDER_RATE
            };
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.setTitle(this.getTitle());
        }

        destroy() {
            this.antiGhostTimer && this.antiGhostTimer.resetAntiGhostTimer();
            super.destroy(...arguments);
        }

        shouldDisplayStateIcons() {
            return false;
        }

        getRightIcon() {
            return null; // We don't want to show the rightButton in the targetValueSelectorScreen
        }

        getStateIDs() {
            return [this.getStateID(), "mode"];
        }

        receivedStates(states) {
            // do NOT pass the states on to the subclass, otherwise it would adopt the state texts, icons and
            // so on based on the stateContainer of the pool, which is not what we want.
            var stateId = this.getStateID();
            this.sliderActionView.setPosition(states[stateId]);
            this.currentMode = states.mode;
            this.updateProperties(this.getSliderConfig());
            this._canUpdateUI() && this._updateSubtitle(states[stateId]);
        }

        getAdditionalSection() {
            return null;
        }

        /**
         * Used to determine which mode to switch to. Usually the manual mode is the goal. But it finnish manual
         * might also be an option.
         * @private
         */
        getManualTargetMode() {
            return SaunaControlEnums.SaunaModes.SAUNA_MANUAL.nr;
        }

        // Private
        _sendValue(pos, isOngoing) {
            this._updateSubtitle(pos);

            this.antiGhostTimer.fire();

            if (this.initialModification) {
                this._prepareForModification();

                this.initialModification = false;
            } // there is no need to send the commands "live", as the target temp & humidtiy won't change respond that fast.


            !isOngoing && this.sendCommand(this.formatCommand(pos));
        }

        _canUpdateUI() {
            return this.antiGhostTimer && !this.antiGhostTimer.isAntiGhostTimerActive();
        }

        _updateSubtitle(state) {
            var states, stateId;

            if (!state && state !== 0) {
                states = this.control.getStates();
                stateId = this.getStateID();
                state = states[stateId];
            }

            this.sliderActionView.setPosition(state);
            this.setSubtitle(this.formatValue(state));
        }

        /**
         * May be used to respond after a value has been sent.
         * @param initial
         */
        _prepareForModification() {
            if (!this.control.details.hasVaporizer) {
                return; // Nothing to worry about.
            }

            if (this.currentMode === SaunaControlEnums.SaunaModes.SAUNA_MANUAL) {
                return; // already in manual mode
            } // change the mode to "manual" if we change a value which is not allowed to be changed in the current mode


            var idx = this.getAcceptibleModes().indexOf(this.currentMode.nr),
                states,
                temp,
                humidity;

            if (idx < 0) {
                states = this.control.getStates();
                temp = states.tempTargetVal;
                humidity = states.humidTargetVal; // current mode not in acceptible modes, change mode! (an re-set the current values again! they will be reset by the manual mode!)

                this.sendCommand(Commands.format(Commands.SAUNA.MODE, this.getManualTargetMode())); // check which value we should send to avoid sending duplicate commands..

                if (this.getStateID() === "humidTargetVal") {
                    this.sendCommand(Commands.format(Commands.SAUNA.TEMP, temp));
                } else {
                    this.sendCommand(Commands.format(Commands.SAUNA.HUMIDITY, humidity));
                }
            }
        }

    };
});
