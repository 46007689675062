'use strict';
/**
 * Prevents the TableContent for the AudioZoneControlContent
 */

define('SpotifyAccountHelper', [], function () {
    class SpotifyAccountHelper {
        //region Static
        static init() {
            if (!this.__isInitialized) {
                this.__boundChangeFn = function () {
                    MediaServerComp.invalidateContentCachesOf(MediaEnum.MediaContentType.SERVICE, MediaEnum.ControlContentIdentifiers.SPOTIFY, null, MediaEnum.ReloadCause.USER_CHANGED);
                }.bind(this);

                this.__boundServiceChangeFn = function (id, services) {
                    this.__receivedServices(services);
                }.bind(this);

                this.__serviceChangeDeReg = MediaServerComp.registerForServiceChanges(this.__boundServiceChangeFn.bind(this));
                MediaServerComp.getCurrentServices().promise.then(this.__receivedServices.bind(this));
                this.registerCustomizationChanges(this.__boundChangeFn);
                this.__isInitialized = true;
            }
        }

        static deinit() {
            if (this.__isInitialized) {
                this.unregisterCustomizationChanges(this.__boundChangeFn);
                this.__serviceChangeDeReg && this.__serviceChangeDeReg();
                this.__isInitialized = false;
            }
        }

        static registerCustomizationChanges(clbk) {
            PersistenceComponent.registerForSharedKeyChange(MediaEnum.CUSTOMIZATION_KEYS.CURRENT_SPOTIFY_ACC_ID, clbk, true);
        }

        static unregisterCustomizationChanges(clbk) {
            PersistenceComponent.unregisterFromSharedKeyChange(MediaEnum.CUSTOMIZATION_KEYS.CURRENT_SPOTIFY_ACC_ID, clbk);
        }

        static getUniqueAccountId(account) {
            if (MediaServerComp.Feature.V2_FIRMWARE) {
                return account.config[0].id;
            } else {
                return account.user;
            }
        }

        static saveSpotifyAccountId(accId) {
            return this.getCurrentAccountId().then(function (settings) {
                settings[MediaServerComp.getActiveZoneControl().uuidAction] = accId;
                PersistenceComponent.setShared(MediaEnum.CUSTOMIZATION_KEYS.CURRENT_SPOTIFY_ACC_ID, settings);
                return PersistenceComponent.synchronizeShared(); // Directly send the change to the Miniserver
            }.bind(this));
        }

        static getCurrentAccountId(uuidAction) {
            return PersistenceComponent.getShared(MediaEnum.CUSTOMIZATION_KEYS.CURRENT_SPOTIFY_ACC_ID).then(function (res) {
                return res;
            }, function () {
                return {};
            }).then(function (res) {
                res = res || {};

                if (uuidAction) {
                    if (!res.hasOwnProperty(uuidAction)) {
                        res[uuidAction] = null;
                    }

                    return res[uuidAction];
                } else {
                    return res;
                }
            }.bind(this));
        }

        static getSpotifyUserForId(userId) {
            var user = null;

            if (this.__accounts) {
                user = this.__accounts.find(function (service) {
                    return this.getUniqueAccountId(service) === userId;
                }.bind(this));
            }

            return user;
        }

        static __receivedServices(services) {
            if (services) {
                this.__accounts = services.filter(function (service) {
                    return service.cmd === MediaEnum.Service.SPOTIFY;
                });
            }
        } //endregion Static


        constructor() {
        }

    }

    Controls.AudioZoneControl.SpotifyAccountHelper = SpotifyAccountHelper;
    return Controls.AudioZoneControl.SpotifyAccountHelper;
});
