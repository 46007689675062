import * as React from "react"
import Svg, {Path, Defs, LinearGradient, Stop} from "react-native-svg"

function PlaceGradient(props) {
    return (
        <Svg isreact="true"
            width={42}
            height={50}
            viewBox="0 0 42 50"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            fill="none"
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.917.012C9.182.012.333 8.952.333 20.812c0 14.92 19.134 28.36 19.949 28.923.188.133.414.197.635.197.22 0 .444-.064.634-.197.813-.564 19.949-14.003 19.949-28.922 0-11.859-8.849-20.8-20.583-20.8zm0 31.2C14.345 31.212 9 26.08 9 19.773c0-6.308 5.345-11.44 11.917-11.44 6.569 0 11.916 5.132 11.916 11.44 0 6.31-5.347 11.44-11.916 11.44z"
                fill="url(#paint0_linear_3091_12909)"
            />
            <Defs>
                <LinearGradient
                    id="paint0_linear_3091_12909"
                    x1={0.465318}
                    y1={0.172486}
                    x2={51.0592}
                    y2={39.969}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#69C350"/>
                    <Stop offset={1} stopColor="#69C350" stopOpacity={0.24}/>
                </LinearGradient>
            </Defs>
        </Svg>
    )
}

export default PlaceGradient
