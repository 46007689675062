import globalStyles from 'GlobalStyles';
import { LxReactButton, ControlContext } from 'LxComponents';
import { StyleSheet, View, Text } from 'react-native';
import { useContext, useMemo } from 'react';

const PauseTimer = ({ control, states }) => {
    const endSleepMode = () => {
        control.sendCommand(Commands.AC.CANCEL_SLEEP, Commands.Type.OVERRIDE);
    };

    const sleepUntilTime = useMemo(() => {
        const sleepUntilMoment = moment.unix(states?.pauseUntil);
        return sleepUntilMoment.format(DateType.DateTextAndTime);
    }, [states?.pauseUntil]);

    let text = '';

    if (Feature.AC_CONTROL_UPDATES_V2) {
        text = states?.pauseUntil === -1
            ? _('controls.ac-control.sleep-active')
            : _('controls.ac-control.override-off-until', {
                time: sleepUntilTime,
            });
    } else {
        text = states?.pauseUntil === -1
            ? _('controls.ac-control.sleep-active')
            : _('controls.ac-control.sleep-active-until', {
                time: sleepUntilTime,
            });
    }

    return states?.pauseUntil !== 0 ? (
        <>
            <Text style={styles.text}>
                {text}
            </Text>
            <LxReactButton
                containerStyle={styles.endOverrideButtonContainer}
                textStyle={styles.text}
                text={Feature.AC_CONTROL_UPDATES_V2 ? _('controls.ac-control.end-override') : _('controls.ac-control.end-sleep')}
                onPress={endSleepMode}
            />
        </>
    ) : null;
};

const OverrideTimer = ({ control, states }) => {
    const endOverrideMode = () => {
        control.sendCommand(Commands.AC.UNSET_OVERRIDE, Commands.Type.OVERRIDE);
    };

    const overrideUntilTime = useMemo(() => {
        const overrideUntilMoment = moment.unix(states?.overrideTimer.until);
        return overrideUntilMoment.format(DateType.DateTextAndTime);
    }, [states?.overrideTimer.until]);
    
    return states?.overrideTimer.until !== 0 ? (
        <>
            <Text style={styles.text}>
                {`${_('controls.climate.us.fan.on-until')} ${overrideUntilTime}`}
            </Text>
            <LxReactButton
                containerStyle={styles.endOverrideButtonContainer}
                textStyle={styles.text}
                text={_('controls.ac-control.end-override')}
                onPress={endOverrideMode}
            />
        </>
    ) : null;
};

export default function TimerWidget() {
    const { control, states } = useContext(ControlContext);

    if (!states.hasOwnProperty('overrideTimer')) {
        return null;
    }

    return (
        <>
            {states?.pauseUntil !== 0 || states.overrideTimer.until !== 0 ? (
                <View style={styles.overview}>
                    <PauseTimer control={control} states={states} />
                    <OverrideTimer control={control} states={states} />
                </View>
            ) : null}
        </>
    );
}

const styles = StyleSheet.create({
    overview: {
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        textAlignVertical: 'center',
        paddingVertical: 15,
        marginHorizontal: 15,
        paddingHorizontal: 15,
        marginBottom: 15,
        borderRadius: 3,
        backgroundColor: globalStyles.colors.fill.tertiary,
        marginTop: -35,
    },
    text: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.primary,
        marginBottom: 15,
    },
    endOverrideButtonContainer: {
        width: '100%',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(118, 118, 128, 0.24)',
        marginHorizontal: 15,
        paddingHorizontal: 15,
    },
});
