import {
    LxReactButton,
    LxReactHeader, LxReactHeaderIconSize, LxReactText, LxReactTextView,
    navigatorConfig,
    useBackNavigation,
} from "../Components";
import PairingConnectScreen from "./PairingConnectScreen";
import {useCallback, useState} from "react";
import {View} from "react-native";
import Icons from "IconLib";
import globalStyles from "GlobalStyles";

function ManualLoginScreen({navigation}) {


    navigation.setOptions({
        ...navigatorConfig({
            title: ""
        })
    })

    useBackNavigation(() => {
        navigation.goBack();
    }, [])

    const onConnect = (urlOrIp) => {
        const ms = prepareConnAddress(urlOrIp);

        PairedAppComponent.startPairingWith(ms);
        navigation.navigate(PairingConnectScreen.name);
    }

    let [enteredIpOrUrl, setEnteredIpOrUrl] = useState("");

    const textChanged = useCallback((text) => {
        setEnteredIpOrUrl(text);
    }, [])

    const onSubmitEditing = (text) => {
        setEnteredIpOrUrl(text);
        onButtonPressed();
    }

    const onButtonPressed = () => {
        !!enteredIpOrUrl && onConnect && onConnect(removeProtocol(enteredIpOrUrl));
    }

    return <View style={styles.rootCntr} >
        <LxReactHeader
            icon={Icons.Search}
            style={styles.hdr}
            iconSize={LxReactHeaderIconSize.Normal}
            iconContainerStyle={styles.headerIconContainer}
            title={_("managed-tablet.connect-manually")}
        />
        <LxReactTextView
            style={[styles.contentItem, styles.input]}
            textStyle={styles.inputText}
            pkey={"url-input"}
            value={enteredIpOrUrl}
            placeholder={_("miniserverlist.add.manual.placeholder")}
            leftIcon={Icons.Location}
            leftIconStyle={{width: 19}}
            autoFocus={true}
            alignment={"left"}
            textChanged={textChanged}
            onSubmitEditing={onSubmitEditing}
        />
        <View style={[globalStyles.customStyles.separator, styles.separator]} />
        <LxReactButton
            text={_('connect')}
            pkey={"url-connect-button"}
            containerStyle={[styles.contentItem, styles.submitButton]}
            disabled={!enteredIpOrUrl}
            onPress={onButtonPressed}
        />
        <View style={styles.footerContainer}>
            <LxReactText style={styles.footerText}>
                {_("miniserverlist.add.manual.description")}
            </LxReactText>
        </View>
    </View>
}

const styles = {
    rootCntr: {
        ...globalStyles.customStyles.screenContent
    },
    contentItem: {
    },
    hdr: {
        minHeight: 240
    },
    input: {
        marginTop: globalStyles.spacings.gaps.medium,
        paddingHorizontal: globalStyles.sizes.bigger
    },
    inputText: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.primary
    },
    inputPlaceholder: {
        fontSize: globalStyles.fontSettings.sizes.medium
    },
    separator: {
        width: "auto",
        marginLeft: globalStyles.sizes.bigger,
        marginRight: globalStyles.sizes.bigger,
    },
    submitButton: {
        marginHorizontal: globalStyles.sizes.bigger,
        marginTop: globalStyles.sizes.medium,
        fill: globalStyles.colors.brand,
        textAlign: "center"
    },
    footerContainer: {
        marginTop: globalStyles.sizes.small,
        marginLeft: globalStyles.sizes.bigger,
        marginRight: globalStyles.sizes.bigger
    },
    footerText: {
        ...globalStyles.textStyles.footNote.default,
        color: globalStyles.colors.text.secondary,
    },
    headerIconContainer: {
        marginTop: globalStyles.spacings.gaps.veryBig
    }
}
export default ManualLoginScreen;