'use strict';

define("IrrigationControl", [
    "Control",
    "IrrigationControlEnums",
    "IconLib"
], function (
    Control,
    IrrigationControlEnums,
    {
        default: Icons
    }
) {
    return class IrrigationControl extends Control {
        constructor() {
            super(...arguments);
        }

        getLegacyScreens() {
            return [IrrigationControlEnums.ScreenState.DURATION]
        }

        getButton0(states) {
            var btn = null;

            if (states && states.hasZones) {
                btn = {
                    iconSrc: Icon.Pushbutton.PUSHBUTTON2,
                    reactIcon: Icons.Circle,
                    command: states && states.active ? Commands.IRRIGATION.STOP : Commands.IRRIGATION.START_FORCE
                };
            }

            return btn;
        }

        getIcon() {
            return Icon.Irrigation.CONTROL;
        }

        getTracker() {
            var trackerControl,
                subControls = this.subControls,
                trackerUUID = Object.keys(subControls)[0];
            trackerControl = ActiveMSComponent.getControlByUUID(trackerUUID);
            trackerControl.name = _("controls.irrigation.activities");
            trackerControl.details = trackerControl.details || {};
            trackerControl.showLatestDate = false;
            return trackerControl;
        }

    };
});
