import * as React from "react"
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg"

function TrustUser(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <G clipPath="url(#clip0_857_576)">
                <Path
                    d="M6.875 6c1.308 0 2.861-.776 3.322-1.661a.501.501 0 01.878-.017c.853 1.494 1.755 1.967 3.206 1.687l.608-.15.505-.125C14.894 2.754 12.666.5 10 .5 7.46.5 5.322 2.549 4.693 5.319 5.596 5.875 6.239 6 6.875 6z"
                    fill="#69C350"
                />
                <Path
                    d="M11.998 19.999c0-1.654 1.346-3 3-3 .588 0 1.159.177 1.645.5l1.699-.849c-.402-.252-.493-.284-2.425-.967-.698-.247-1.637-.578-2.917-1.036v-2.206c1.503-1.162 2.594-3.348 2.489-5.704a6.453 6.453 0 01-1.948.348c-1.194 0-2.134-.525-2.931-1.635C9.756 6.347 8.249 7 6.875 7c-.783 0-1.434-.141-2.348-.628-.183 2.784.97 4.907 2.473 6.069v2.206l-4.678 1.671A3.51 3.51 0 000 19.614V22a.5.5 0 00.5.5h12.848a2.997 2.997 0 01-1.35-2.501z"
                    fill="#69C350"
                />
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.354 21.381c.502-.718 1.463-1.075 2.317-.766a1.997 1.997 0 011.209 2.556 2.004 2.004 0 01-2.557 1.21 1.98 1.98 0 01-1.311-2.062l-3.36-1.2c-.36.53-.966.879-1.654.879-1.102 0-2-.897-2-2s.898-2 2-2c.599 0 1.133.272 1.499.691l3.552-1.775a1.983 1.983 0 011.056-2.204 1.996 1.996 0 012.683.894 1.996 1.996 0 01-.896 2.684c-.8.401-1.784.228-2.396-.478l-3.543 1.77c.06.278.05.376.036.515l-.008.081 3.373 1.205zm2.539-5.33a1 1 0 00-1.79.892l.002.003.001.001c.24.478.858.689 1.339.446a1 1 0 00.448-1.342zm-8.895 3.948a1 1 0 102 0 1 1 0 00-2 0zm7.664 3.441c.513.183 1.097-.1 1.278-.604a1 1 0 00-1.878-.682l-.001.009-.002.003-.002.002a.997.997 0 00.605 1.272z"
                    fill="#EBEBF5"
                    fillOpacity={0.6}
                />
            </G>
            <Defs>
                <ClipPath id="clip0_857_576">
                    <Path fill="#fff" transform="translate(0 .5)" d="M0 0H24V24H0z" />
                </ClipPath>
            </Defs>
        </Svg>
    )
}

export default TrustUser
