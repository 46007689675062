'use strict';
/**

 */

window.GUI = function (GUI) {
    class PickerViewTime extends GUI.LxPickerViewBase {
        constructor() {
            super(...arguments);
            Debug.GUI.PickerView && console.log(this.name, "constructor");
        }

        /**
         * This function initializes the Picker
         */
        preparePicker() {
            var hideSeconds = !this.details.showSeconds;
            var timeFormat = LxDate.getMobiscrollTimeFormat(hideSeconds).replace("mm", "ii");
            var timeWheels = LxDate.getMobiscrollTimeFormat(hideSeconds, false, this.force24hours).replace("mm", "ii");
            this.picker.time(this.mergePickerConfig({
                minTime: this.details.minTime ? this.details.minTime : 0,
                maxTime: this.details.maxTime ? this.details.maxTime : 24 * 60 * 60 * 1000,
                timeWheels: timeWheels.replace(":", "").replace(" ", ""),
                timeFormat: timeFormat
            }));
            this.setPickerValue(this.details.value);
        }

        /**
         * This function will be called once the pickerValue has changed
         * For example if the user turns one of the pickerWheels
         * @param v The value returned by mobiscroll in the given format
         */
        onChange(v) {
            this.onPickerChangedFn && this.onPickerChangedFn(this.getPickerValue(v));
        }

        /**
         * This sets the given value to the mobiscroll picker
         * @param value This must be in the right format you specified in the picker config.
         */
        setPickerValue(value) {
            var date = this._valuesToDate(value);

            this.picker.mobiscroll('setVal', date);
        }

        getPickerValue() {
            var val = this.picker.mobiscroll('getVal');
            return [val.getHours(), val.getMinutes(), val.getSeconds()];
        }

        _valuesToDate(values) {
            var date = new Date(2015, 1, 1, 0, 0, 0, 0);

            if (values[0]) {
                date.setHours(values[0]);
            }

            if (values[1]) {
                date.setMinutes(values[1]);
            }

            if (values[2]) {
                date.setSeconds(values[2]);
            }

            return date;
        }

    }

    GUI.LxPickerViewTime = PickerViewTime;
    return GUI;
}(window.GUI || {});
