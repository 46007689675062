import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"

function SystemStateErrorCircled(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Circle cx={12} cy={12} r={12} />
            <Path
                fill="#FFF"
                d="M17.924 11.817a.26.26 0 010 .369l-5.742 5.74a.259.259 0 01-.363 0l-5.742-5.74a.26.26 0 010-.369l5.739-5.74a.26.26 0 01.369 0l5.739 5.74zM11.75 9.249v4.001a.25.25 0 10.499 0V9.249a.25.25 0 10-.499 0zm.245 5.851a.375.375 0 100-.75.375.375 0 000 .75z"
            />
        </Svg>
    )
}

export default SystemStateErrorCircled
