import {LxReactFlexibleCell, ControlContext} from "LxComponents";
import {useContext} from "react";
import globalStyles from "GlobalStyles";
import {useNavigation} from "@react-navigation/native";


function StatisticCell(props) {
    const {control} = useContext(ControlContext)
    const navigation = useNavigation();

    const showStatistic = () => {
        let nr = 0;
        if (control.statistic && control.statistic.outputs && control.statistic.outputs[nr]) {
            nr = nr ? nr : 0;
            navigation.navigate(ScreenState.Statistic, {
                controlUUID: control.uuidAction,
                statisticOutputUUID: control.statistic.outputs[nr].uuid
            })
        }
    }

    return <>
        {!!control.statistic && <LxReactFlexibleCell section={0} row={0}
                                                     title={_("statistics")}
                                                     disclosureIcon={true}
                                                     titleStyle={globalStyles.textStyles.body.default}
                                                     cellElementStyle={styles.cell}
                                                     containerStyle={styles.container}
                                                     onPress={showStatistic}
        />}
    </>
}

const styles = {
    container: {
        width: "100%",
        marginVertical: globalStyles.spacings.gaps.medium
    },
    cell: {
        backgroundColor: globalStyles.colors.transparent,
        borderRadius: 4,
        paddingVertical: globalStyles.spacings.gaps.smaller
    }
}

export default StatisticCell
