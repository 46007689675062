import {useContext, useEffect} from "react";
import {UrlContext} from "../App";
import {useNavigation} from "@react-navigation/native";
import {AmbientContext} from "LxComponents";

function useSetURLHook({urlPart,replace, disablePop, appendIndex, nPop = 1}) {
    const navigation = useNavigation();
    const {setUrl} = useContext(UrlContext);
    const {isAmbientMode} = useContext(AmbientContext)

    useEffect(() => {
        let unsubscribeFocus, unsubscribeBlur;

        if (isAmbientMode) {
            return;
        }

        unsubscribeFocus = navigation.addListener("focus", (e) => {
            setUrl({
                urlPart,
                replace,
                appendIndex
            })
        })
        unsubscribeBlur = navigation.addListener("blur", (e) => {
            setUrl({
                pop: !disablePop,
                appendIndex,
                urlPart,
                nPop
            });
        });

        return () => {
            unsubscribeBlur && unsubscribeBlur();
            unsubscribeFocus && unsubscribeFocus();
        }
    }, [isAmbientMode]);
}

export default useSetURLHook;
