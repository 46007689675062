'use strict';

define(["ControlActionCellsScreen", "UniversalControlValueScreen", "UniversalControlSendCommandInfoView"], function (ControlActionCellsScreen, UniversalControlValueScreen, UniversalControlSendCommandInfoView) {
    return class UniversalControlContent extends ControlActionCellsScreen {
        constructor() {
            super(...arguments);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments); // This is the original type defined by the config (control.type)

            this.setSubtitle("Type: " + this.control.originalType);
        }

        // Don't waste any space by showing an icon
        shouldDisplayStateIcons() {
            return false;
        }

        getCustomInfoView() {
            return new UniversalControlSendCommandInfoView(this.control);
        }

        /*getStateSections(states) {
            var sections = [],
                stateRows = [],
                detailsRow = [],
                subCtrlRows = [],
                // Only show the states represented in the structure file, we don't need the getters of the stateContainer
                // E.g: stateText, stateIcon, stateColor, stateInfo, ...
                controlStatesKeys = Object.keys(states).filter(function (stateKey) {
                    return Object.keys(this.control.states).indexOf(stateKey) > -1;
                }.bind(this)); // Iterate over every structure defined state and display
            // Newly received states will be displayed green
            // Empty TextEvents will be replaced with "EMPTY STRING" and displayed in gray

            controlStatesKeys.forEach(function (stateKey) {
                var isNewState = states.newStates.indexOf(stateKey) > -1,
                    titleColor = isNewState ? window.Styles.colors.stateActive : null,
                    stateValue = states[stateKey].value,
                    isValueEvent = states[stateKey].isValueEvent,
                    valueColor = null;

                if (stateValue === "") {
                    stateValue = 'EMPTY STRING';
                    valueColor = Color.STATE_INACTIVE_B;
                } else if (isNewState) {
                    valueColor = window.Styles.colors.stateActive;
                }

                stateRows.push(this._getValueCell(stateKey, stateValue, valueColor, titleColor, isValueEvent));
            }.bind(this));

            if (stateRows.length > 0) {
                sections.push({
                    headerTitle: "States",
                    rows: stateRows
                });
            } // Display the controls states if available


            if (this.control.details) {
                var detailsKeys = Object.keys(this.control.details);
                detailsKeys.forEach(function (detailsKey) {
                    var detailsValue = this.control.details[detailsKey];

                    if (typeof detailsValue === "object") {
                        detailsValue = JSON.stringify(detailsValue);
                    }

                    detailsRow.push(this._getValueCell(detailsKey, detailsValue));
                }.bind(this));

                if (detailsRow.length > 0) {
                    sections.push({
                        headerTitle: "Details",
                        rows: detailsRow
                    });
                }
            } // Display subControls if available, clicking them will result in navigation to the specific subControl


            if (this.control.subControls) {
                var subCtrlUuids = Object.keys(this.control.subControls);
                subCtrlUuids.forEach(function (ctrlUuid) {
                    var subControl = this.control.subControls[ctrlUuid];
                    subCtrlRows.push(this.getSubmenuCell(subControl.name, this._openSubControl.bind(this, subControl)));
                }.bind(this));

                if (subCtrlRows.length > 0) {
                    sections.push({
                        headerTitle: "SubControls",
                        rows: subCtrlRows
                    });
                }
            }

            return sections;
        }

        _getValueCell(name, value, valueColor, titleColor, isValueEvent) {
            // We need to use .toString() the value, if we pass 0 or false as the disclosureText nothing will show up
            try {
                if (value.toString) {
                    value = value.toString();
                } else {
                    value = JSON.stringify(value);
                }
            } catch (e) {
            } // undefined will stay undefined, we can't set this as the disclosureText, so change it to the string "undefined"


            if (value === undefined) {
                value = "undefined";
            } else if (value === null) {
                value = "null";
            }

            var cell = {
                content: {
                    title: name,
                    titleColor: titleColor,
                    disclosureText: value,
                    disclosureColor: valueColor,
                    clickable: true,
                    rightIconSrc: Icon.DISCLOSURE
                },
                action: this._openValueScreen.bind(this, name, value)
            };

            if (isValueEvent !== undefined && isValueEvent !== null) {
                cell.content.subtitle = isValueEvent ? "ValueEvent" : "TextEvent";
            }

            return cell;
        }

        _openSubControl(subControl) {
            NavigationComp.showControlContent(subControl, null, AnimationType.PUSH_OVERLAP_LEFT);
        }

        _openValueScreen(stateKey, stateValue) {
            this.ViewController.showState("UniversalControlValueScreen", null, {
                stateKey: stateKey,
                stateValue: stateValue
            });
        } */

    };
});
