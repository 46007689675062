'use strict';

define(["DaytimerControlEnums", "IRoomControlEnums"], function (DaytimerControlEnums, IRoomControlEnums) {
    var INDICATOR_UPDATE_INTERVAL = 1000 * 60;
    return class DaytimerCalendarEntries extends GUI.View {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(ctrl, showModeBar) {
                var modeBar = '';

                if (showModeBar) {
                    modeBar = '<div class="mode-list__header">' + '<div class="header__edit-btn">' + ImageBox.getResourceImageWithClasses(Icon.SETTINGS, "edit-btn__image") + '</div>' + '<div class="header__mode-list scroll-view-wrapper">' + '<div class="mode-list__content scroll-view-content"></div>' + '</div>' + '</div>';
                }

                return modeBar + '<div class="calendar__placeholder">' + '<div class="calender__time-line scroll-view-wrapper">' + '<div class="time-line__content scroll-view-content">' + createTimeLineLabels() + '<div class="calendar__time-indicator"></div>' + '</div>' + '</div>' + '<div class="calendar__entry-list scroll-view-wrapper">' + '<div class="entry-list__content scroll-view-content">' + '<div class="calendar__time-indicator"></div>' + '</div>' + '</div>' + '</div>';
            };

            var createTimeLineLabels = function createTimeLineLabels() {
                var result = "";

                for (var i = 0; i < 25; i++) {
                    var hour = i.toString();

                    if (hour.length === 1) {
                        hour = "0" + i;
                    }

                    result += '<div class="time-line__entry">' + hour + ':00</div>';
                }

                return result;
            };

            var getModeColumnTemplate = function getModeColumnTemplate(modeNr, modeName) {
                var headerClass = 'column-header-' + modeNr,
                    columnClass = 'column-' + modeNr;
                var header = '<div id="column-header-' + modeNr + '" class="mode-list__entry text-overflow-ellipsis ' + headerClass + ' ">' + modeName + '</div>';
                var column = '<div id="column-' + modeNr + '" class="list__column ' + columnClass + '">' + '<div class="column__entry-container"></div>' + '</div>';
                return {
                    header: header,
                    column: column
                };
            };

            return {
                getTemplate: getTemplate,
                getModeColumnTemplate: getModeColumnTemplate
            };
        }(); //endregion Static

        constructor(control, onScroll) {
            super($('<div class="calendar-entries"></div>'));
            Object.assign(this, StateHandler.Mixin);
            this.control = control;

            if (onScroll) {
                this.showModeBar = false;
                this.dispatchExternalScroll = onScroll;
            } else {
                this.showModeBar = true;
                this.dispatchExternalScroll = null;
            }

            this.scrollViews = {};
            this.registeredScrollViews = false;
            this.name = "CalendarEntries";
            this.restrictedToMode = false;
        }

        viewDidLoad() {
            return Q(super.viewDidLoad()).then(() => {
                var colorObj;
                Debug.Control.Daytimer.CalendarEntries && console.log(this.name + ": viewDidLoad");
                this._minutesSinceMidnight = -1;
                var calendarElements = $(DaytimerCalendarEntries.Template.getTemplate(this.control, this.showModeBar));
                this.buttons = {};
                var idx = 0;

                if (this.showModeBar) {
                    this.buttons.openModeListBtn = new GUI.LxButton(this, calendarElements.children('.header__edit-btn')[0], Color.BUTTON_GLOW, null, true);
                    this.buttons.openModeListBtn.useChildsAsActiveParts('fill');
                    this.addToHandledSubviews(this.buttons.openModeListBtn); // scroll views{

                    var modeListElement = calendarElements[0].querySelector(".header__mode-list");
                    this.scrollViews.modeList = new IScroll(modeListElement, {
                        probeType: 3,
                        mouseWheel: true,
                        scrollX: true,
                        scrollY: false
                    });
                    idx++;
                }

                var timeLineListElement = calendarElements[idx].querySelector(".calender__time-line");
                this.scrollViews.timeLineList = new IScroll(timeLineListElement, {
                    probeType: 3,
                    mouseWheel: true,
                    scrollX: false,
                    scrollY: true
                });
                var calendarListElement = calendarElements[idx].querySelector(".calendar__entry-list");
                this.scrollViews.calendarList = new IScroll(calendarListElement, {
                    probeType: 3,
                    mouseWheel: true,
                    scrollX: true,
                    scrollY: true,
                    scrollbars: true,
                    fadeScrollbars: true,
                    shrinkScrollbars: 'scale'
                });
                this.elements = {
                    modeList: $(modeListElement).children('.mode-list__content'),
                    calendarList: $(calendarListElement).children('.entry-list__content'),
                    indicators: $(calendarElements.find('.calendar__time-indicator')),
                    timeLine: calendarElements.find('.time-line__content')
                };
                this.element.append(calendarElements);

                if (this.hasLegend()) {
                    this.elements.calPlaceholder = this.element.find('.calendar__placeholder');
                    this.elements.calPlaceholder.append(this.getLegendTemplate());
                    this.elements.legendButton = this.element.find('.legend-button');
                    this.buttons.legendButton = new GUI.LxButton(this, this.elements.legendButton[0], Color.BUTTON_GLOW, null, true);
                    this.addToHandledSubviews(this.buttons.legendButton);
                    this.buttons.legendButton.useChildsAsActiveParts('fill');
                    this.elements.legendList = this.element.find('.legend-list');
                    var circles = this.elements.legendList.find('.entry__circle'),
                        circle,
                        modeNr;

                    for (var i = 0; i < circles.length; i++) {
                        circle = $(circles[i]);
                        modeNr = parseInt(circle.parent()[0].id.replace('mode-nr-', ''));
                        colorObj = this.getCircleColorForMode(modeNr);

                        if (colorObj) {
                            circle.css(colorObj.cssAttr, colorObj.color);
                        }
                    }
                }

                this._boundHandleModesScroll = this._handleModesScroll.bind(this);
                this._boundHandleTimeScroll = this._handleTimeScroll.bind(this);
                this._boundHandleCalendarScroll = this._handleCalendarScroll.bind(this);
            })
        }

        viewWillAppear() {
            return Q(super.viewWillAppear(...arguments)).then(() => {
                this.registerForResize();
            });
        }

        viewDidAppear() {
            return Q(super.viewDidAppear()).then(() => {
                this._registerForStates();

                var newEntryContainer = this.element.find('.new-entry__container');

                if (newEntryContainer.length > 0) {
                    newEntryContainer.remove();
                }

                this.timeIdentifier = SandboxComponent.getMiniserverTimeInfo(this, this._updateIndicatorPosition.bind(this), TimeValueFormat.MINUTES_SINCE_MIDNIGHT, INDICATOR_UPDATE_INTERVAL);

                if (this.showModeBar) {
                    this.buttons.openModeListBtn.onButtonReleased = function onButtonReleased() {
                        this.ViewController.showState(DaytimerControlEnums.ScreenState.OPERATING_MODES, null, {
                            control: this.control,
                            isEditMode: true
                        });
                    }.bind(this);
                } // register scroll handling


                this.showModeBar && this.scrollViews.modeList.on("scroll", this._boundHandleModesScroll);
                this.scrollViews.timeLineList.on("scroll", this._boundHandleTimeScroll);
                this.scrollViews.calendarList.on("scroll", this._boundHandleCalendarScroll);
                this.registeredScrollViews = true;

                if (this.hasLegend()) {
                    this.buttons.legendButton.onButtonTapped = function onButtonTapped() {
                        this.elements.legendList.toggleClass('legend-list--visible');
                    }.bind(this);
                }

                this.element.toggleClass("calendar-entries--hd", HD_APP);

                this._refreshScrollViews(); // workaround for: https://www.wrike.com/open.htm?id=101075675


                triggerEvent(window, "resize");
            });
        }

        viewWillDisappear() {
            this._unregisterStates();

            SandboxComponent.removeFromTimeInfo(this.timeIdentifier); // unregister scroll handlers

            this.showModeBar && this.scrollViews.modeList.off("scroll", this._boundHandleModesScroll);
            this.scrollViews.timeLineList.off("scroll", this._boundHandleTimeScroll);
            this.scrollViews.calendarList.off("scroll", this._boundHandleCalendarScroll);
            this.registeredScrollViews = false;

            if (this.buttons.openModeListBtn && this.buttons.openModeListBtn.onButtonReleased) {
                this.buttons.openModeListBtn.onButtonReleased = null;
            }

            if (this.buttons.legendButton && this.buttons.legendButton.onButtonTapped) {
                this.buttons.legendButton.onButtonTapped = null;
            }

            clearTimeout(this._indicatorRedrawTimeout);
            super.viewWillDisappear(...arguments);
        }

        destroy() {
            // remove old stuff
            if (this.usedModes) {
                for (var i = 0; i < this.eventHandlers.length; i++) {
                    this.eventHandlers[i].dispose();
                }
            } // Destroy scroll handlers


            this.showModeBar && this.scrollViews.modeList.destroy();
            this.scrollViews.timeLineList.destroy();
            this.scrollViews.calendarList.destroy();
            super.destroy();
        }

        /**
         * Used in HD split view, only one mode is shown at a time there.
         * @param modeNr
         */
        restrictToMode(modeNr) {
            if (this.restrictedToMode !== modeNr) {
                this.restrictedToMode = modeNr;
                this.states && this._redrawContent();
            }
        }

        scrollTo(x, y) {
            Debug.Control.Daytimer.CalendarEntries && console.log(this.name + ": scrollTo");
            this.scrollViews.calendarList.scrollTo(x, this.scrollViews.calendarList.y);
        }

        onResize() {
            Debug.Control.Daytimer.CalendarEntries && console.log(this.name + ": onResize");

            this._refreshScrollViews();

            this._updateIndicatorPosition(this._minutesSinceMidnight, true);
        }

        receivedStates(states) {
            Debug.Control.Daytimer.CalendarEntries && console.log(this.name + ": receivedStates");
            var hasChanged = !this.states; // initial receivedStates
            // initial states are already here, has anything of importance changed?

            if (!hasChanged) {
                hasChanged |= JSON.stringify(this.entries) !== JSON.stringify(states.entries);

                if (this.restrictedToMode && !hasChanged) {// when the restriction changes, redraw is being called right away.
                } else {
                    hasChanged |= JSON.stringify(this.usedModes) !== JSON.stringify(states.usedModes);
                    hasChanged |= this.states.activeMode !== states.activeMode;
                    hasChanged |= this.states.daytimerState !== states.daytimerState;

                    if (this.control.type === DaytimerControlEnums.ControlType.IRC_DAYTIMER && !hasChanged) {
                        hasChanged |= JSON.stringify(this.states.activeTemperatures) !== JSON.stringify(states.activeTemperatures);
                    }
                }
            }

            if (hasChanged) {
                this.states = cloneObject(states);

                this._redrawContent(this.states);
            } else {
                Debug.Control.Daytimer.CalendarEntries && console.log("   NO changes!");
            }
        }

        // -----------------------------------------------------------------------------------------
        // -----------------------------------------------------------------------------------------
        getCircleColorForMode(modeNr) {
            return null;
        }

        getImageElementForEntry(entryInfo) {
            return null;
        }

        getEntryBackgroundColor(entry) {
            return entry.needActivate ? window.Styles.colors.orange : window.Styles.colors.stateActive;
        }

        hasLegend() {
            return false;
        }

        /**
         * Returns a jquery element that contains the legend for these calendar entries
         * @return {jQuery|HTMLElement}
         */
        getLegendTemplate() {
            return null;
        }

        updateLegend(states) {// nothing to do. the regular daytimer has no legend.
        }

        // -----------------------------------------------------------------------------------------
        _redrawContent() {
            Debug.Control.Daytimer.CalendarEntries && console.log(this.name + ": _redrawContent");
            var i,
                states = this.states; // remove old stuff

            if (this.usedModes) {
                for (i = 0; i < this.eventHandlers.length; i++) {
                    this.eventHandlers[i].dispose();
                }

                for (i = 0; i < this.usedModes.length; i++) {
                    this.elements.modeList.find('#column-header-' + this.usedModes[i]).remove();
                    this.elements.calendarList.find('#column-' + this.usedModes[i]).remove();
                }
            }

            if (this.restrictedToMode !== false) {
                this.usedModes = [this.restrictedToMode];
            } else {
                this.usedModes = states.usedModes;
            }

            this.entries = states.entries;
            this.eventHandlers = [];

            for (i = 0; i < this.usedModes.length; i++) {
                this._createModeColumn(this.usedModes[i], states);
            }

            this.eventHandlers = this.eventHandlers.concat(this._registerNewEntryHandling(this.scrollViews.calendarList, this.scrollViews.timeLineList));

            this._refreshScrollViews();

            this.element.find('.mode-list__entry--active').removeClass('mode-list__entry--active');
            this.element.find('.list__column--active').removeClass('list__column--active');

            if (states.activeMode) {
                var selectedHeader = this.element.find('#column-header-' + states.activeMode).addClass('mode-list__entry--active');
                var selectedColumn = this.element.find('#column-' + states.activeMode).addClass('list__column--active');

                if (!this.scrolledToMode && this.usedModes.length && states.daytimerState !== DaytimerControlEnums.DaytimerState.TIMER) {
                    this.scrolledToMode = true; // animation

                    this.scrollViews.calendarList.scrollToElement(selectedColumn[0], 0, 0, -8);
                    this.showModeBar && this.scrollViews.modeList.scrollToElement(selectedHeader[0], 0, 0, 0);

                    if (!this.registeredScrollViews) {
                        // scroll handlers are registered in viewDidAppear. the states arrive once in viewWillAppear
                        this.dispatchExternalScroll && this.dispatchExternalScroll(this.scrollViews.calendarList.x, this.scrollViews.calendarList.y);
                    }
                }
            }

            this.updateLegend(states);
        }

        _refreshScrollViews() {
            Debug.Control.Daytimer.CalendarEntries && console.log(this.name, "_refreshScrollViews");
            this.scrollViews.timeLineList.refresh();
            this.scrollViews.calendarList.refresh();
            this.scrollViews.modeList && this.scrollViews.modeList.refresh();
        }

        _updateIndicatorPosition(minutesSinceMidnight, forceUpdate) {
            Debug.Control.Daytimer.CalendarEntries && console.log(this.name + ": _updateIndicatorPosition " + minutesSinceMidnight);

            if (this.elements.timeLine[0].clientHeight === 0 || this.scrollViews.calendarList.wrapperHeight === 0) {
                // view not loaded yet. Wait for the next tick
                Debug.Control.Daytimer.CalendarEntries && console.log("        not loaded yet, wait for next tick");

                if (minutesSinceMidnight > 0) {
                    // we must update the indicator once we have the size of the elements.. use recursive timeout to do this asap.
                    clearTimeout(this._indicatorRedrawTimeout);
                    this._indicatorRedrawTimeout = setTimeout(this._updateIndicatorPosition.bind(this, minutesSinceMidnight, forceUpdate), 50);
                }

                return;
            }

            clearTimeout(this._indicatorRedrawTimeout);
            this._minutesSinceMidnight = minutesSinceMidnight;
            var percent = minutesSinceMidnight / LxTimeDef.Minutes.MIDNIGHT;
            var timeLineHeight = this.elements.timeLine[0].clientHeight - 24; // 24 = top & bottom margin

            var linePosition = timeLineHeight * percent;
            this.elements.indicators.css("top", linePosition + "px");

            if (!this.navigatedToIndicator || !!forceUpdate) {
                var viewPortHalve = this.scrollViews.calendarList.wrapperHeight / 2;
                this.navigatedToIndicator = true;
                var position = (linePosition - viewPortHalve) * -1;

                if (position > 0) {
                    position = 0;
                } else if (position < this.scrollViews.calendarList.maxScrollY) {
                    position = this.scrollViews.calendarList.maxScrollY;
                }

                this.scrollViews.calendarList.scrollTo(this.scrollViews.calendarList.x, position);
                this.scrollViews.timeLineList.scrollTo(this.scrollViews.timeLineList.x, this.scrollViews.calendarList.y);
            }
        }

        _createModeColumn(modeNr, states) {
            Debug.Control.Daytimer.CalendarEntries && console.log(this.name, "_createModeColumn: " + modeNr);
            var template = DaytimerCalendarEntries.Template.getModeColumnTemplate(modeNr, SandboxComponent.getStructureManager().getOperatingModes(modeNr));
            var headerElem = $(template.header);
            this.elements.modeList.append(headerElem); //this.eventHandlers.push(Hammer(headerElem[0]).on('tap', function (event) {
            // TODO-sulzean
            //this.ViewController.showState(DaytimerControlEnums.ScreenState.LIST_ENTRIES, null, {control: this.control, selectedMode: event.currentTarget.id.replace('column-header-', '')});
            //}.bind(this)));

            var columnElem = $(template.column);
            columnElem.insertBefore(this.elements.calendarList.children('.calendar__time-indicator'));

            if (this.entries[modeNr]) {
                this._createCalendarEntriesForMode(this.entries[modeNr], modeNr, {
                    state: states.daytimerState,
                    activeMode: states.activeMode
                });

                this.eventHandlers = this.eventHandlers.concat(this._registerCalendarEntriesForMode(modeNr));
            }
        }

        _createCalendarEntriesForMode(entries, mode, powerState) {
            Debug.Control.Daytimer.CalendarEntries && console.log(this.name, "_createCalendarEntriesForMode: " + mode);
            var i,
                wantedColumnClass = '.column-' + mode,
                wantedColumn = this.element.find(wantedColumnClass);
            var entriesContainer = wantedColumn.children('.column__entry-container');
            var height = entriesContainer[0].clientHeight,
                width = entriesContainer[0].clientWidth; // calculate sizes for entries

            var rawInfos = [];

            for (i = 0; i < entries.length; i++) {
                var sizeInfo = this._getTopOffsetAndHeightFromEntry(entries[i]);

                rawInfos.push({
                    entry: entries[i],
                    size: sizeInfo,
                    id: mode + ';' + i
                });
            } // check space for every entry


            var finalInfos = [];

            for (i = 0; i < rawInfos.length; i++) {
                var isLastInfo = i === rawInfos.length - 1;

                if (!isLastInfo && rawInfos[i].entry.to > rawInfos[i + 1].entry.from) {
                    // start new entry collection
                    var newColl = [rawInfos[i]];

                    for (var j = i + 1; j < rawInfos.length; j++) {
                        isLastInfo = j === rawInfos.length - 1;

                        if (newColl[newColl.length - 1].entry.to > rawInfos[j].entry.from) {
                            // entry found which effects on this collection, add it to the collection
                            newColl.push(rawInfos[j]);
                            i = j; // also update variable of first loop - force a bail out if last entry was reached
                        } else {
                            // first entry found who doesn't effect on this collection, go on with this entry in the first loop
                            i = j - 1;
                            break;
                        }
                    } // finished collection add it to the entries array


                    finalInfos.push(newColl);
                } else {
                    // if there is enough place for the entry, add it to the array
                    finalInfos.push(rawInfos[i]);
                }
            }

            var elements = this._generateElementsForEntries(finalInfos, width, powerState);

            Debug.Control.Daytimer.CalendarEntries && console.log(" elements: " + elements.length);

            for (i = 0; i < elements.length; i++) {
                entriesContainer.append(elements[i]);
            }
        }

        _getTopOffsetAndHeightFromEntry(entry) {
            var offset = (entry.from / (24 * 60)) * 100;
            var height = ((entry.to - entry.from) / (24 * 60)) * 100;

            return {
                top: offset,
                height: height
            };
        }

        _generateElementsForEntries(entries, totalWidth, powerState) {
            Debug.Control.Daytimer.CalendarEntries && console.log(this.name, "_generateElementsForEntries");
            var result = [],
                element,
                iconElem;

            for (var i = 0; i < entries.length; i++) {
                var value = entries[i];

                if (value instanceof Array) {
                    var width = (totalWidth - 3) / value.length; // per default there is an offset of 5 px, offset between collection entries is 2 px, subtract it from total width

                    for (var j = 0; j < value.length; j++) {
                        element = this._prepareBaseEntryElement(value[j]);
                        element.css('width', width - 2 + "px");
                        element.css('left', width * j + "px");

                        this._appendDetailToElement(element, value[j], true);

                        result.push(element);
                    }
                } else {
                    element = this._prepareBaseEntryElement(value);

                    this._appendDetailToElement(element, value, false);

                    iconElem = this.getImageElementForEntry(value);
                    iconElem && element.append(iconElem);

                    if (value.entry.needActivate) {
                        this._appendActivationFlagToElement(element, value, powerState);
                    }

                    result.push(element);
                }
            }

            return result;
        }

        _prepareBaseEntryElement(infoObj) {
            Debug.Control.Daytimer.CalendarEntries && console.log(this.name, "prepareBaseEntryElement: " + JSON.stringify(infoObj));
            var entryElem = $('<div id="' + infoObj.id + '" class="column__entry"></div>'),
                bgColor = this.getEntryBackgroundColor(infoObj.entry);
            bgColor && entryElem.css('background-color', bgColor);
            entryElem.css('top', infoObj.size.top + "%");
            entryElem.css('height', infoObj.size.height + "%");
            entryElem.css("min-height", "21px")

            let duration = infoObj.entry.to - infoObj.entry.from;

            if (duration < 84) { // before was absolute height 50 from a column of 864px (5,78% => 1440 * 0,578 = 83,3)
                entryElem.addClass("column__entry--tiny");
            } else if (duration < 184) { // before was absolute height 50 from a column of 864px (12,73% => 1440 * 0,1273 = 183,3)
                entryElem.addClass("column__entry--small");
            }

            return entryElem;
        }

        _appendDetailToElement(elem, entry) {
            // value label
            if (this.control.details.analog) {
                var text = '<br>';

                if (this.control.type === DaytimerControlEnums.ControlType.DAYTIMER) {
                    text = lxFormat(this.control.details.format, entry.entry.value);
                } else if (this.control.type === DaytimerControlEnums.ControlType.IRC_DAYTIMER) {
                    text = lxFormat(this.control.details.format, entry.entry.targetTemp);
                } else if (this.control.type === DaytimerControlEnums.ControlType.IRCV2_DAYTIMER) {
                    text = entry.entry.tempModeName;
                }

                var valueEl = $('<div class="value-label">' + text + '</div>');
                elem.append(valueEl);
            }

            var fromDate = moment(0, "HH").minutes(entry.entry.from);
            var toDate = moment(0, "HH").minutes(entry.entry.to); // the daytimer cannot support AM/PM since it uses 24:00 as a special time that we don't have an equivalent to in AM/PM

            var from = fromDate.format("HH:mm");
            var to = toDate.format("HH:mm");

            if (to === "00:00" && fromDate.date() !== toDate.date()) {
                // it is possible to receive an entry from 0:00 - 0:00 on the same day
                to = "24:00";
            }

            var timeSpanText = from + " - " + to;
            var timeSpanEl = $('<div class="time-span-label">' + timeSpanText + '</div>');
            elem.append(timeSpanEl);
            var durationDate = moment(0, "HH").minutes(toDate.diff(fromDate, 'minutes'));
            var hours = durationDate.hours(),
                minutes = durationDate.minutes();

            if (hours === 0 && minutes === 0) {
                hours = 24;
            }

            var durationText = hours + _("dateTime.hour.oneLetter") + ' ' + minutes + _("dateTime.minute.short");

            var durationEl = $('<div class="time-duration-label">' + durationText + '</div>');
            elem.append(durationEl);
        }

        _appendActivationFlagToElement(elem, entryInfo, powerState) {
            var minutesSinceMidnight = SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINUTES_SINCE_MIDNIGHT),
                imageName;

            if (powerState.state === 2 && powerState.activeMode === entryInfo.entry.mode && entryInfo.entry.from <= minutesSinceMidnight && entryInfo.entry.to >= minutesSinceMidnight) {
                imageName = Icon.Daytimer.LOCK.OPENED;
            } else {
                imageName = Icon.Daytimer.LOCK.CLOSED;
            }

            elem.append($(ImageBox.getResourceImageWithClasses(imageName, 'entry__icon flag-lock-image')));
        }

        _registerCalendarEntriesForMode(mode) {
            var wantedColumnClass = '.column-' + mode,
                wantedColumn = this.element.find(wantedColumnClass);
            var entriesElements = wantedColumn.children('.column__entry-container').children();
            var handlers = [];

            for (var i = 0; i < entriesElements.length; i++) {
                handlers.push(Hammer(entriesElements[i]).on(tapEvent(), this._handleEntryTap.bind(this)));
            }

            return handlers;
        }

        _getMinEntryMinutes() {
            return this.control.type === DaytimerControlEnums.ControlType.IRC_DAYTIMER ? 180 : 60;
        }

        _registerNewEntryHandling(calendarList, timeLineList) {
            // new entry variables
            var y,
                entryPlaceholder,
                entryElem,
                newEntry,
                placeholderRect,
                startTimeLabel,
                // wrapper info
                wrapper = this.element.find('.calendar__entry-list'),
                wrapperOffsetTop = this._getWindowOffsetOfElem(wrapper[0]).y,
                wrapperHeight = wrapper[0].clientHeight,
                // scroll variables
                counter = 0,
                interval,
                columns = this.element.find('.list__column'),
                handler,
                handlers = [],
                modeNr,
                pos,
                newPos,
                limit,
                diff,
                entryHeight;

            for (var i = 0; i < columns.length; i++) {
                handler = Hammer($(columns[i]).find(".column__entry-container")[0], {
                    holdTimeout: 200,
                    dragMinDistance: 10 // important, otherwise android would never recognize a "hold"

                }).on('hold drag dragend release', function (event) {
                    entryHeight = event.currentTarget.clientHeight / 24 * (this._getMinEntryMinutes() / 60);

                    if (event.type === 'hold') {
                        placeholderRect = event.currentTarget.getBoundingClientRect();
                    } else if (!entryPlaceholder) {
                        // event from other element
                        return;
                    }

                    y = this._getPointInDiv(placeholderRect, event).y; // also include last scroll updates

                    y += counter;

                    switch (event.type) {
                        case 'hold':
                            calendarList.disable();
                            entryPlaceholder = $(event.currentTarget);
                            entryElem = $('<div class="new-entry__container"><div class="entry__field">' + _("controls.daytimer.entry.new") + '</div></div>');
                            entryElem.children('.entry__field').css('height', entryHeight + 'px');
                            startTimeLabel = $('<div class="start-time"></div>');
                            modeNr = entryPlaceholder.parent()[0].id.replace('column-', '');
                            newEntry = {
                                from: 0,
                                to: 60,
                                needActivate: false,
                                mode: modeNr,
                                equalModes: [modeNr]
                            }; // default values are configured inside entryDetails.

                            entryElem.prepend(startTimeLabel);

                            this._updateNewEntryInfo(entryElem, entryPlaceholder[0], y, newEntry, entryHeight);

                            entryPlaceholder.append(entryElem);
                            break;

                        case 'drag':
                            if (event.currentTarget === entryPlaceholder[0]) {
                                this._updateNewEntryInfo(entryElem, entryPlaceholder[0], y, newEntry, entryHeight);
                            }

                            pos = getEventPosition(event).y - wrapperOffsetTop;
                            limit = 50;

                            if (pos <= limit) {
                                if (!interval) {
                                    interval = setInterval(function () {
                                        if (calendarList.y < 0) {
                                            diff = 5;

                                            if (calendarList.y + diff > 0) {
                                                diff = -calendarList.y;
                                            }

                                            newPos = calendarList.y + diff;
                                            calendarList.scrollTo(calendarList.x, newPos);
                                            timeLineList.scrollTo(timeLineList.x, newPos);
                                            y -= diff;

                                            this._updateNewEntryInfo(entryElem, entryPlaceholder[0], y, newEntry, entryHeight);

                                            counter -= diff;
                                        } else {
                                            this._updateNewEntryInfo(entryElem, entryPlaceholder[0], y, newEntry, entryHeight);

                                            clearInterval(interval);
                                            interval = null;
                                        }
                                    }.bind(this), 15);
                                }
                            } else if (pos >= wrapperHeight - limit) {
                                if (!interval) {
                                    interval = setInterval(function () {
                                        if (calendarList.y > calendarList.maxScrollY) {
                                            diff = 5;

                                            if (calendarList.y - diff < calendarList.maxScrollY) {
                                                diff = -(calendarList.maxScrollY - calendarList.y);
                                            }

                                            newPos = calendarList.y - diff;
                                            calendarList.scrollTo(calendarList.x, newPos);
                                            timeLineList.scrollTo(timeLineList.x, newPos);
                                            y += diff;

                                            this._updateNewEntryInfo(entryElem, entryPlaceholder[0], y, newEntry, entryElem.clientHeight);

                                            counter += diff;
                                        } else {
                                            this._updateNewEntryInfo(entryElem, entryPlaceholder[0], y, newEntry, entryHeight);

                                            clearInterval(interval);
                                            interval = null;
                                        }
                                    }.bind(this), 15);
                                }
                            } else if (interval) {
                                this._updateNewEntryInfo(entryElem, entryPlaceholder[0], y, newEntry, entryHeight);

                                clearInterval(interval);
                                interval = null;
                            }

                            break;

                        case 'dragend':
                        case 'release':
                            entryPlaceholder = null;
                            calendarList.enable();
                            clearInterval(interval);
                            interval = null;
                            counter = 0;
                            this.ViewController.showState(DaytimerControlEnums.ScreenState.ENTRY_DETAIL, null, {
                                control: this.control,
                                entry: newEntry,
                                isEditMode: false
                            });
                            break;
                    }
                }.bind(this));
                handlers.push(handler);
            }

            return handlers;
        }

        _getWindowOffsetOfElem(element) {
            var el = element.offsetParent;
            var lx = element.offsetLeft,
                ly = element.offsetTop;

            while (el) {
                ly += el.offsetTop;
                el = el.offsetParent;
            }

            return {
                x: lx,
                y: ly
            };
        }

        _getPointInDiv(div, event) {
            var evPos = getEventPosition(event),
                xPos = evPos.x - div.left,
                yPos = evPos.y - div.top;
            var x = xPos,
                y = yPos;

            if (xPos < 0) {
                x = 0;
            }

            if (xPos > event.currentTarget.clientWidth) {
                x = event.currentTarget.clientWidth;
            }

            if (yPos < 0) {
                y = 0;
            }

            if (yPos > event.currentTarget.clientHeight) {
                y = event.currentTarget.clientHeight;
            }

            return {
                x: x,
                y: y
            };
        }

        /**
         * Checks position of entry
         * @param pos position of entry
         * @param wrapper parent of entry
         * @param entryHeight height of entry
         * @returns {number} new position of entry
         */
        _checkEntryPos(pos, wrapper, entryHeight) {
            var newPos,
                wrapperHeight = wrapper.clientHeight,
                maxPos = wrapperHeight - entryHeight;

            if (pos < 0) {
                newPos = 0;
            } else if (pos > maxPos) {
                newPos = maxPos;
            } else {
                newPos = pos;
            }

            return newPos;
        }

        _updateNewEntryInfo(container, parent, offset, entryObj, entryHeight) {
            var startTimeLabel = container.children('.start-time'),
                parentHeight = parent.clientHeight,
                from,
                fromDate;
            offset = this._checkEntryPos(offset, parent, entryHeight);
            from = Math.round(offset / parentHeight * LxTimeDef.Minutes.MIDNIGHT);
            fromDate = moment(0, "HH").minutes(from);

            if (from >= LxTimeDef.Minutes.MIDNIGHT) {
                from = LxTimeDef.Minutes.MIDNIGHT - 60;
            }

            container.css('top', offset - startTimeLabel.height() + 'px');
            startTimeLabel.text(fromDate.format(LxDate.getDateFormat(DateType.TimeFormat)));
            entryObj.from = from;
            entryObj.to = Math.min(LxTimeDef.Minutes.MIDNIGHT, from + this._getMinEntryMinutes());
        }

        _handleEntryTap(event) {
            var indexInfo = event.currentTarget.id.split(';');
            var entry = this.entries[indexInfo[0]][indexInfo[1]];
            this.ViewController.showState(DaytimerControlEnums.ScreenState.ENTRY_DETAIL, null, {
                control: this.control,
                entry: entry,
                isEditMode: true
            });
        }

        // handling scrolling
        _handleModesScroll() {
            this.scrollViews.calendarList.scrollTo(this.scrollViews.modeList.x, this.scrollViews.calendarList.y);
        }

        _handleTimeScroll() {
            this.scrollViews.calendarList.scrollTo(this.scrollViews.calendarList.x, this.scrollViews.timeLineList.y);
        }

        _handleCalendarScroll() {
            this.showModeBar && this.scrollViews.modeList.scrollTo(this.scrollViews.calendarList.x, this.scrollViews.modeList.y);
            this.scrollViews.timeLineList.scrollTo(this.scrollViews.timeLineList.x, this.scrollViews.calendarList.y);
            this.dispatchExternalScroll && this.dispatchExternalScroll(this.scrollViews.calendarList.x, this.scrollViews.calendarList.y);
        }

    };
});
