'use strict';

define([], function () {
    return function StepChartView(data, ui, ctx, Helper) {
        var middleDP, middleX, middleY, lastX, lastY, drawToY;

        var draw = function drawStepChart() {
            drawToY = data.min < 0 ? Helper.getYForValue(0) : ui.bottomOffset;
            middleDP = data.source[data.source.middleIndex];
            middleX = Math.round(Helper.getXForTimestamp(middleDP.ts));
            middleY = Helper.getYForValue(middleDP.value); // We can't show the "noEntryDefine" value, thus we just go down to the minimal value to not show any value

            if (middleDP.isNoDataEntry) {
                middleY = Helper.getYForValue(data.min > 0 ? data.min : 0);
            }

            ctx.moveTo(middleX, middleY);
            lastX = middleX; // to the left

            Helper.forEachVisibleDataPoint(Direction.LEFT, data.source.middleIndex - 1, function (dp) {
                lastY = Helper.getYForValue(dp.value);
                ctx.lineTo(lastX, lastY);
                lastX = Helper.getXForTimestamp(dp.ts);
                ctx.lineTo(lastX, lastY);
            });
            ctx.lineTo(lastX, drawToY);
            ctx.lineTo(middleX, drawToY);
            ctx.moveTo(middleX, middleY);
            lastX = middleX;
            lastY = middleY; // to the right

            Helper.forEachVisibleDataPoint(Direction.RIGHT, data.source.middleIndex + 1, function (dp) {
                lastX = Helper.getXForTimestamp(dp.ts);
                ctx.lineTo(lastX, lastY);
                lastY = Helper.getYForValue(dp.value);
                ctx.lineTo(lastX, lastY);
            });

            if (data.upToDate) {
                lastX = Helper.getXForTimestamp(data.date);
                ctx.lineTo(lastX, lastY);
            }

            ctx.lineTo(lastX, drawToY);
            ctx.lineTo(middleX, drawToY);
            data.check();
        };

        return {
            draw: draw
        };
    };
});
