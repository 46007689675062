'use strict';

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.ACTIVITY = "ActivityCell";

    class ActivityCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(activity) {
                return $('<div class="content__container" data-loading="true" data-error="false" data-show-activity="false">' + '   <img class="container__image" src="' + TRANSPARENT_16X9 + '">' + '   ' + ImageBox.getResourceImageWithClasses(Icon.Athene.NO_IMAGE, "container__error-image") + '   ' + ImageBox.getResourceImageWithClasses(Icon.Athene.CLOCK, "container__activity-image") + '   <div class="container__text-container">' + '       <div class="text-container__title"></div>' + '       <div class="text-container__sub-title"></div>' + '   <div>' + '</div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.thumb = null;
        }

        viewDidLoad() {
            return Q.resolve(super.viewDidLoad(...arguments)).then(function () {
                return GUI.animationHandler.append(ActivityCell.Template.getTemplate(this.content.activity), this.contentPlaceholder).then(function () {
                    this.elements = {
                        container: this.contentPlaceholder.find(".content__container"),
                        title: this.contentPlaceholder.find(".text-container__title"),
                        subtitle: this.contentPlaceholder.find(".text-container__sub-title"),
                        image: this.contentPlaceholder.find(".container__image")
                    };
                    this.updateContent(this.content);
                }.bind(this));
            }.bind(this));
        }

        // ---------------------------------------------------------------------------------------------------------
        //   Methods for super fast tableContent updates
        // ---------------------------------------------------------------------------------------------------------
        cellTypeForReuse() {
            return GUI.TableViewV2.CellType.Special.ACTIVITY;
        }

        updateContent() {
            return super._updateContent(...arguments);
        }

        _applyNewContent(newContent) {
            var basePrms = super._applyNewContent(...arguments);

            this.element.toggleClass("activity-cell--in-content", !!this.content.inContent); // Reset the Cell

            this.thumb && this.thumb.abort();
            GUI.animationHandler.setAttr(this.elements.image, "src", TRANSPARENT_16X9).then(function () {
                // Handle the new content
                let thumbPrms;

                if ("activity" in this.content) {
                    this.thumb = this.content.activity.getThumb();
                    thumbPrms = this.thumb;
                } else {
                    thumbPrms = Q.resolve();
                }

                thumbPrms.then(function (dataURI) {
                    return this.processWhenVisible(function () {
                        return GUI.animationHandler.schedule(function () {
                            this.elements.container.attr("data-error", "false");

                            if (dataURI) {
                                this.elements.image.attr("src", dataURI);
                                this.elements.container.attr("data-show-activity", "false");
                            } else {
                                this.elements.container.attr("data-show-activity", "true");
                            }
                        }.bind(this));
                    }.bind(this));
                }.bind(this), function (e) {
                    return GUI.animationHandler.setAttr(this.elements.container, "data-error", "true");
                }.bind(this)).finally(function () {
                    return GUI.animationHandler.setAttr(this.elements.container, "data-loading", "false");
                }.bind(this));
                GUI.animationHandler.schedule(function () {
                    if ("activity" in this.content) {
                        if (this.content.inContent) {
                            this.elements.title.text(this.content.activity.getDate().format(LxDate.getDateFormat(DateType.DateText)));
                            this.elements.subtitle.text(this.content.activity.getDate().format(LxDate.getDateFormat(`${DateType.TimeFormat}:ss`)));
                        } else {
                            this.elements.title.text(this.content.activity.getDate().format(LxDate.getDateFormat(DateType.DateTextAndTime)));
                        }
                    } else {
                        this.elements.title.text(_("athene.all-activities.title"));
                        this.elements.subtitle.empty();
                        this.elements.image.attr("src", TRANSPARENT_16X9);
                    }
                }.bind(this));
            }.bind(this));
            return basePrms;
        }

        clickableAllowed() {
            return true;
        }

        destroy() {
            this.thumb && this.thumb.abort();
            return super.destroy(...arguments);
        }

        _hammer() {
            var hammerObj = super._hammer(...arguments);

            this.clickableContainer.css("touch-action", "auto");
            return hammerObj;
        }

    }

    GUI.TableViewV2.Cells.ActivityCell = ActivityCell;
    return GUI;
}(window.GUI || {});
