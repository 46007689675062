import {useCallback, useEffect, useState} from "react";
import {useCCEvent} from "LxComponents";


export default function useEcoScreenSettings() {
    const [settings, setSettings] = useState(PersistenceComponent.getEcoScreenSettings());

    useCCEvent([CCEvent.EcoScreenSettingChanged], useCallback((event, args) => {
        let newSettings = PersistenceComponent.getEcoScreenSettings();
        Debug.EcoScreen && console.log("useEcoScreenSettings", "EcoScreenSettingChanged: " + JSON.stringify(newSettings));
        setSettings(newSettings);
    }, []));

    useEffect(() => {
        let newSettings = PersistenceComponent.getEcoScreenSettings();
        Debug.EcoScreen && console.log("useEcoScreenSettings", "useEffect - MS serial: " + JSON.stringify(newSettings));
        setSettings(newSettings);
    }, [ActiveMSComponent.getActiveMiniserverSerial()]);

    return settings;
}