import { View } from "react-native-web";
import AmbientHeaderInfoView from "../AmbientMode/views/AmbientHeaderInfoView";
import { useFocusEffect } from "@react-navigation/native";
import React, { useCallback, useEffect, useRef } from "react";
import { Animated, Easing, Pressable } from "react-native";
import globalStyles from "GlobalStyles";
import { navigatorConfig, useCCEvent } from "LxComponents";
import AmbientHealthIcon from "../AmbientMode/screens/AmbientHealthIcon";
import MessageOverviewScreen from "../LxReactMessageCenter/MessageOverviewScreen";

const outerLimit = 64; // is also the navigator height, but it's good to avoid the edges.
const fadeDuration = 300; // info fades out in this duration
const transDuration = 100; // screen remains empty
const moveInterval = 60 * 5 * 1000;

function EcoScreen(props) {
    
    let visibleInfoOpacity
    if (PlatformComponent.isCordova) {
        visibleInfoOpacity = 0.75;
    } else {
        visibleInfoOpacity = 0.5;
    }

    const ecoContainerOpacity = useRef(new Animated.Value(0)).current
    const infoVerticalTranslation = useRef(new Animated.Value(0)).current;
    const infoHorizontalTranslation = useRef(new Animated.Value(0)).current;
    const infoOpacity = useRef(new Animated.Value(visibleInfoOpacity)).current;
    const infoViewDimRef = useRef(null);
    const containerDimRef = useRef(null);

    const didNavBack = useRef(false);
    useEffect(() => {
        props.navigation.setOptions(navigatorConfig({
            title: "",
            rightActions: [{
                action: ({ dimensions, props, key }) => {
                    return <AmbientHealthIcon ecoMode={true} />
                }
            }],
            onRightAction: () => {
                closeScreen()
                props.navigation.navigate(MessageOverviewScreen.name)
            },
            headerLeft: () => null,
            headerShown: false
        }))
    }, [])

    useFocusEffect(useCallback(() => {
        Animated.timing(
            ecoContainerOpacity,
            {
                toValue: 1,
                duration: 300,
            }
        ).start();
        $(document).off("tap touchend");

        didNavBack.current = false;
        const timeout = setInterval(moveInfoView, moveInterval);

        if ("HomeIndicator" in window) {
            HomeIndicator.hide();
        }

        return () => {
            didNavBack.current = true;
            clearInterval(timeout);
            $(document).on("tap touchend", SandboxComponent.activityTick)
            Animated.timing(
                ecoContainerOpacity,
                {
                    toValue: 0,
                    duration: 300,
                }
            ).start();
            if ("HomeIndicator" in window) {
                HomeIndicator.hide(false);
            }
        }
    }, []))

    const initializedInfoDimRef = useRef(false);
    const onInfoViewLayout = useCallback((event) => {
        let layout = event.nativeEvent.layout;
        Debug.EcoScreen && console.log(EcoScreen.name, "onInfoViewLayout: " + JSON.stringify(layout));
        if (initializedInfoDimRef.current) {
            // only update with & height once initialized, otherwise it'd move the infoView outside the screen
            // as the left/top have been altered already by moving it around.
            infoViewDimRef.current = {
                ...infoViewDimRef.current,
                width: layout.width,
                height: layout.height
            };
        } else {
            infoViewDimRef.current = {
                width: layout.width,
                height: layout.height,
                left: layout.left,
                top: layout.top
            };
            initializedInfoDimRef.current = true;
        }
    }, []);

    const onContainerLayout = useCallback((event) => {
        let layout = event.nativeEvent.layout;
        Debug.EcoScreen && console.log(EcoScreen.name, "onContainerLayout: " + JSON.stringify(layout));
        containerDimRef.current = {
            width: layout.width,
            height: layout.height,
            left: layout.left,
            top: layout.top
        };
    })

    const getMoveLimits = () => {
        let vMax = 250,
            hMax = 450,
            vMin = 0,
            hMin = 0;
        const container = containerDimRef.current,
            info = infoViewDimRef.current;
        if (container && info) {
            hMax = container.width - info.width;
            vMax = container.height - info.height;
            hMin = info.left * -1;
            vMin = info.top * -1;
        }
        let result = {
            maxVertical: vMax + vMin - outerLimit,
            maxHorizontal: hMax + hMin - outerLimit,
            minVertical: vMin + outerLimit, // 64px is the navigator height.
            minHorizontal: hMin + outerLimit,
        }
        return result;
    }

    const moveInfoView = () => {
        let limits = getMoveLimits();
        const vTrans = getRandomIntInclusive(limits.minVertical, limits.maxVertical);
        const hTrans = getRandomIntInclusive(limits.minHorizontal, limits.maxHorizontal);
        console.log(EcoScreen.name, "moveInfoView");
        console.log(EcoScreen.name, "           limits: " + JSON.stringify(limits));
        console.log(EcoScreen.name, "    vTrans/hTrans: " + vTrans + "/" + hTrans);

        Animated.sequence([
            Animated.timing(infoOpacity,
                {
                    toValue: 0,
                    easing: Easing.in(Easing.ease),
                    duration: fadeDuration,
                    useNativeDriver: true
                }),
            Animated.parallel([
                Animated.timing(infoVerticalTranslation,
                    {
                        toValue: vTrans,
                        duration: transDuration,
                        useNativeDriver: true
                    }),
                Animated.timing(infoHorizontalTranslation,
                    {
                        toValue: hTrans,
                        duration: transDuration,
                        useNativeDriver: true
                    }),
            ]),
            Animated.timing(infoOpacity,
                {
                    easing: Easing.in(Easing.ease),
                    toValue: visibleInfoOpacity,
                    duration: fadeDuration * 2,// slower, looks nicer
                    useNativeDriver: true
                }),

        ]).start(() => {
        })
    }

    useCCEvent([CCEvent.EcoScreenDarkenerActive], useCallback((event, { dark, src }) => {
        // ecoScreenExt also dispatches an event when the screen was tapped, don't respond to that.

        // since for some reason an ecoScreenDarkener dark event with src logic is received after
        // manually navigating back, it is crucial to avoid this from happening twice.
        if (!dark && src !== "manual" && !didNavBack.current) {
            didNavBack.current = true;
            props.navigation.goBack();
        }
    }))

    const closeScreen = useCallback(() => {
        didNavBack.current = true;
        props.navigation.goBack();
        SandboxComponent.toggleEcoModeShown(false, true);
    }, [props.navigation])

    return <Animated.View style={[styles.container, {
        opacity: ecoContainerOpacity
    }]}>
        <Pressable style={styles.pressableContainer} onPress={closeScreen}>
            <View style={styles.contentContainer} onLayout={onContainerLayout}>
                <Animated.View style={{
                    transform: [
                        { translateY: infoVerticalTranslation },
                        { translateX: infoHorizontalTranslation }
                    ],
                    opacity: infoOpacity,
                }} >
                    <View onLayout={onInfoViewLayout} style={styles.infoViewCntr}>
                        <AmbientHeaderInfoView ecoModeActive={true} />
                    </View>
                </Animated.View>
            </View>
        </Pressable>
    </Animated.View>
}

const styles = {
    container: {
        flex: 1,
        backgroundColor: globalStyles.colors.black
    },
    pressableContainer: {
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center"
    },
    contentContainer: {
        width: "100%",
        height: "100%",
        maxWidth: 1700,
        maxHeight: 900,
        paddingHorizontal: 92,
        paddingBottom: 56,
        paddingTop: 92,
        justifySelf: "center",
        alignSelf: "center"
    },
    infoViewCntr: {
        width: "fit-content",
        height: "fit-content"
    }
}

export default EcoScreen;
