'use strict';

define(["AutomaticDesignerTableContentHelper", "AutomaticDesignerFilterView"], function (AutomaticDesignerTableContentHelper, AutomaticDesignerFilterView) {
    return class AutomaticDesignerRuleMenuScreen extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div/>'));
            Object.assign(this, ContextMenuHandler.Mixin);
            this.details = details;
            this.navigationPath = details.navigationPath;
            this.lastSelectedEntry = details.entry;
            this.tableContentHelper = new AutomaticDesignerTableContentHelper(this, this.navigationPath, this.details.screenType);
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.filterView = new AutomaticDesignerFilterView(this.ViewController.filterControls);
                this.unregisterFilterResetFn = this.filterView.on(AutomaticDesignerFilterView.EVENTS.RESET, this._resetFilter.bind(this));
                this.prependSubview(this.filterView);
            }.bind(this));
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function () {
                this.filterView.updateView(this.ViewController.filterControls);
            }.bind(this));
        }

        setViewController() {
            super.setViewController(...arguments);
            this.ViewController.addMultipleView("GUI." + this.name);
        }

        titleBarText() {
            if (this.lastSelectedEntry && this.lastSelectedEntry.hasOwnProperty("name") && (!this.ViewController.filterControls || this.ViewController.filterControls.length === 0)) {
                if (this.lastSelectedEntry.hasOwnProperty("name") && this.lastSelectedEntry.hasOwnProperty("subtitle")) {
                    return this.lastSelectedEntry.name + " " + this.lastSelectedEntry.subtitle;
                }

                return this.lastSelectedEntry.name || " ";
            }
        }

        titleBarConfig() {
            return {
                style: TitleBarCfg.STYLE.MINOR
            };
        }

        titleBarActionRight() {
            this.ViewController.showGlobalSearch();
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        setTableContent() {
            this.tableContent = this.tableContentHelper.getContentForIndex(this.lastSelectedEntry);

            if (this.ViewController.showSearchCell && this.ViewController.showSearchCell()) {
                this.tableContent = [this.ViewController.getGlobalSearchSection()].concat(this.tableContent);
            }

            return super.setTableContent(...arguments);
        }

        emptyViewConfig() {
            var emptyMsg;

            if (this.details.screenType === AutomaticDesignerEnums.SCREEN_TYPES.EVENTS) {
                emptyMsg = _("automatic-designer.rule.no.event");
            } else {
                emptyMsg = _("automatic-designer.rule.no.action");
            }

            return {
                message: emptyMsg,
                noCircle: true,
                translucent: true,
                elementIsNotClickable: true
            };
        }

        destroy() {
            this.unregisterFilterResetFn();
            return super.destroy(...arguments);
        }

        _resetFilter() {
            delete this.ViewController.filterControls;
            this.filterView.updateView(this.ViewController.filterControls);
            this.ViewController.navigateBack();
        }

    };
});
