'use strict';

class AlexaFinishScreen extends GUI.AlexaOnboardingScreen {
    //region Static
    static Template = function () {
        var getButtonTemplate = function getButtonTemplate() {
            return $("<div class='bottom-text-container'>" + _('alexa.finish.bottom.text1', {
                bottomTextElem: '<span class="container__open-alexa-button">' + _('alexa.finish.bottom.text2') + '</span>'
            }) + "</div>");
        };

        return {
            getButtonTemplate: getButtonTemplate
        };
    }(); //endregion Static

    //endregion Static

    constructor(details) {
        super($("<div />"));
        this.id = details.id;
        this.iconSrc = details.iconSrc;
        this.iconColor = details.iconColor;
        this.title = details.title;
        this.message = details.message;
        this.message2 = details.message2;
        this.continueBtnText = details.continueBtnText;
        this.continueDef = details.continueDef;
        this.bottomIcon = details.bottomIcon;
        this.bottomText = details.bottomText;
        this.shareButtonText = details.shareButtonText;
        this.textButtonText = details.textButtonText;
        this.rightButtonSrc = details.rightButtonSrc;
        this.rightButtonAction = details.rightButtonAction;
    }

    viewDidLoad() {
        var args = arguments;
        return ActiveMSComponent.sendConfigurationComplete().then(() => {
            return super.viewDidLoad(...args);
        });
    }

    viewWillAppear() {
        var baseCall = super.viewWillAppear(...arguments);

        if (!this._openAlexaButton) {
            AlexaFinishScreen.Template.getButtonTemplate().insertBefore(this.element.find('.button-container')[0]);
            var buttonElem = this.element.find('.bottom-text-container')[0];
            this._openAlexaButton = new GUI.LxButton(this, buttonElem, Color.BUTTON_GLOW, null, true);
            this.addToHandledSubviews(this._openAlexaButton);
        }

        this._openAlexaButton.onButtonTapped = this._handleAlexaButtonTapped.bind(this);
        return baseCall;
    }

    viewWillDisappear() {
        this._openAlexaButton.onButtonTapped = null;
        return super.viewWillDisappear(...arguments);
    }

    closeAction() {
        this.ViewController.dismiss();
    }

    continueBtnAction() {
        this.ViewController.dismiss();
    }

    setSubtitle2(subtitle2) {
        if (this.elements.subtitleLbl2) {
            if (subtitle2) {
                this.elements.subtitleLbl2.html(subtitle2);
            } else {
                this.elements.subtitleLbl2.empty();
            }
        }
    }

    _handleAlexaButtonTapped() {
        this.ViewController.dismiss();

        if (PlatformComponent.isAndroid()) {
            NavigationComp.openWebsite("market://details?id=com.amazon.dee.app");
        }

        if (PlatformComponent.isIOS()) {
            NavigationComp.openWebsite("alexa://alexa?fragment=smart-home");
        }
    }

}

GUI.AlexaFinishScreen = AlexaFinishScreen;
