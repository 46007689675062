'use strict';

define("AtheneNotReadyScreen", ["ControlActionCellsScreen", "AtheneControlEnums"], function (ControlActionCellsScreen, AtheneControlEnums) {
    return class AtheneNotReadyScreen extends ControlActionCellsScreen {
        constructor(details) {
            super(...arguments);
            this.control = details.control;
        }

        closeAction() {
            this.ViewController.dismiss();
        }

        shouldDisplayStateIcons() {
            var shouldShow = super.shouldDisplayStateIcons(...arguments);
            shouldShow &= this.control.getStates().universalIsLocked;
            return shouldShow;
        }

        getActionSections() {
            return [];
        }

        getAnimation() {
            return AnimationType.FADE;
        }

        setSubtitle(subtitle) {
            if (this.control.getStates().universalIsLocked) {
                super.setSubtitle(...arguments);
            } else {
                // no subtitle needs to be set if not locked.
                super.setSubtitle(null);
            }
        }

        receivedStates(states) {
            super.receivedStates(...arguments);
            this.element.toggleClass("athene-not-ready-screen--locked", states.universalIsLocked);
            this.bgView && this.toggleSubview(this.bgView, !states.universalIsLocked);

            if (states.universalIsLocked) {// nothing to be done
            } else if (this.currentDeviceState !== states.deviceState) {
                this.setBackgroundView(states);
            }

            if (this.currentComReady !== states.comReady && states.comReady) {
                this.ViewController.navigateBack();
            }

            this.currentDeviceState = states.deviceState;
            this.currentComReady = states.comReady;
        }

        setBackgroundView(states) {
            var icon, title, message;

            if (states.isLocked) {
                icon = Icon.LOCK;
                title = _("operation-blocked");
                message = _("audio-server.operation-blocked");
            } else {
                icon = Icon.INDICATOR;
                title = "";
                message = states.stateText;
            }

            if (this.bgView) {
                this.removeSubview(this.bgView);
            }

            this.bgView = new GUI.BackgroundView(icon, title, message, null, null, true, true);
            this.insertSubviewBefore(this.bgView, this.actionTableView.getElement());
            this.bgView.getElement().addClass("action-view__background-view");
        }

    };
});
