import {LxReactLoginForm, useCountDown, LxReactHeader, LxReactHeaderIconSize} from "LxComponents";
import {useState, useEffect, useMemo} from 'react';
import globalStyles from "GlobalStyles";
import {View} from "react-native";
import Icons from "IconLib";


export default function MsLogin(
    {
        remainingBlockedTime,
        miniserver,
        lastPwdChange,
        loginRetryCount,
        affectedToken,
        loginState,
        message = null,
        headerIcon = null,
        customErrorMessage = null,
        onSubmit
    }
    ) {

    const currRemainingBlockedTime = useCountDown(remainingBlockedTime);

    const getRemainingBlockedTimeText = () => {
        var text;

        if (loginState === LoginState.TEMP_BLOCKED) {
            text = _('pre-load-login.too-many-wrong-logins');
            if (currRemainingBlockedTime > 0) {
                text += " (" + LxDate.formatSeconds(currRemainingBlockedTime).replace(/&nbsp;/g, " ") + ")";
            }
        }

        return text;
    }

    const [enteredPassword, setEnteredPassword] = useState(miniserver.password ? VendorHub.Crypto.decrypt(miniserver.password) : "");
    const [enteredUser, setEnteredUser] = useState(miniserver.activeUser || "");

    useEffect(() => {
        setEnteredUser(miniserver.activeUser || "");
    }, [miniserver.activeUser]);

    const handleSubmit = (user, pass) => {
        let verifiedUser = verifyTrustUsername(user);
        setEnteredPassword(pass);
        setEnteredUser(verifiedUser);

        onSubmit(verifiedUser, pass);
    }

    const getMsTitle = () => {
        return (miniserver.msName || miniserver.localUrl || miniserver.remoteUrl).addLineBreakPossibilities();
    }

    /**
     * If its a trust username, this will ensure that the serial number will be uppercased!
     * @param user
     * @returns {*}
     */
    const verifyTrustUsername = (user) => {
        let parts = user.split("@");
        if (parts.length === 2 && Regex.MAC_ADDRESS.test(parts[1])) {
            parts[1] = parts[1].toUpperCase();
        }
        return parts.join("@");
    }

    //region ErrorInfo

    const getInvalidTokenInformation = () => {
        Debug.Tokens && console.log(MsLogin.name, "_updateInvalidTokenInformation");
        var jwt, invalidTokenText, additionalInfo; // only for logging.

        try {
            jwt = typeof affectedToken === "string" ? parseJwt(affectedToken) : null;
        } catch (ex) {
            jwt = null;
        }

        if (!jwt || !jwt.hasOwnProperty("payload")) {
            console.error(MsLogin.name, "Failed to parse JWT token, cannot provide insight into causes!", affectedToken, jwt);
            invalidTokenText = _("miniserver.credentials.invalid-token");
        } else {
            var expiresAtUnix = jwt.payload.exp;
            var createdAtUnix = jwt.payload.iat;
            var now = moment().unix();

            if (expiresAtUnix < now) {
                invalidTokenText = _("token.expired-message");
                additionalInfo = "Expired since: " + new LxDate(moment.unix(expiresAtUnix), false).format(DateType.DateTextAndTime);
            } else if (createdAtUnix < lastPwdChange) {
                invalidTokenText = _("token.user-perm-or-pass-changed");
                additionalInfo = "Token created at " + new LxDate(moment.unix(createdAtUnix), false).format(DateType.DateTextAndTime);
                additionalInfo += ". Password changed at " + new LxDate(moment.unix(lastPwdChange), false).format(DateType.DateTextAndTime);
            } else {
                // not expired, but declined! Maybe due to an issue in the token refreshing
                invalidTokenText = _("token.login-fail");
                additionalInfo = "Token created at " + new LxDate(moment.unix(createdAtUnix), false).format(DateType.DateTextAndTime);
                additionalInfo += ". Token should've been valid until " + new LxDate(moment.unix(expiresAtUnix), false).format(DateType.DateTextAndTime);
                additionalInfo += ". Password changed at " + new LxDate(moment.unix(lastPwdChange), false).format(DateType.DateTextAndTime);
            }
        }

        console.error(MsLogin.name, "_updateInvalidTokenInformation: " + invalidTokenText);
        additionalInfo && console.error(MsLogin.name, "  Additional Info: " + additionalInfo);
        jwt && console.error(MsLogin.name, "       JWT: " + JSON.stringify(jwt));

        return invalidTokenText;
    }

    const getLastChangedTime = () => {
        var unixChanged = moment.unix(lastPwdChange),
            unixNow = moment().utc(),
            duration = unixChanged.from(unixNow),
            diff = unixNow - unixChanged,
            showLastChanged = diff >= 0 && diff <= 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

        if (showLastChanged) {
            return _('password.last-changed.duration', {
                duration: duration
            })
        }
    }

    const errorInfo = useMemo(() => {
        var infos = [];
        switch (loginState) {
            case LoginState.INVALID_CREDS:
                infos.push(_("miniserver.credentials.failed"));
                if (typeof lastPwdChange === "number" && lastPwdChange > 0) {
                    infos.push(getLastChangedTime());
                }
                break;
            case LoginState.INVALID_TOKEN:
                infos.push(getInvalidTokenInformation());
                break;
            case LoginState.TEMP_BLOCKED:
                infos.push(getRemainingBlockedTimeText());
                break;
            default:
                break;
        }
        if (loginRetryCount > 2) {
            infos.push(_("miniserver.credentials.lock-warning"));
        }


        return infos;
    }, [loginState,lastPwdChange,loginRetryCount, affectedToken, currRemainingBlockedTime])


    const getUserPlaceholder = () => {
        let placeholder = _('username');
        if (miniserver.isInTrust) {
            placeholder += "@" + _("controls.intercom.about.audio.host");
        }
        return placeholder;
    }

    //endregion ErrorInfo
    
    return <View style={globalStyles.customStyles.screenContent}>
        <LxReactHeader
            style={styles.header}
            title={getMsTitle()}
            subtitle={message}
            icon={ headerIcon || Icons.PlaceGradient }
            iconContainerStyle={styles.headerIconContainer}
            iconSize={LxReactHeaderIconSize.Normal}
        />
        <View style={styles.loginForm}>
            <LxReactLoginForm
                onSubmit={handleSubmit}
                errorInfo={(customErrorMessage ? [customErrorMessage] : errorInfo)}
                containerStyle={styles.loginForm}
                user={enteredUser}
                userIsValid={enteredUser !== ""}
                userInputProps={
                    {
                        value: enteredUser,
                        placeholder: getUserPlaceholder()
                    }
                }
                passwordInputProps={
                    {
                        value: enteredPassword,
                        biometricSecretType: BiometricHelper.SecretType.UserPw,
                        biometricSecretTargetMs: {
                            serialNo: miniserver.serialNo,
                            activeUser: typeof enteredUser === "string" ? enteredUser : miniserver.activeUser // We may not have "enteredUser" yet...
                        }
                    }
                }
            />
        </View>

    </View>
};

const styles = {
    header: {
        minHeight: 240,
        marginBottom: globalStyles.sizes.medium,
        textAlign: "center"
    },
    headerIconContainer: {
        marginTop: globalStyles.spacings.gaps.veryBig
    },
    loginForm: {
        flex: 1,
        flexDirection: "column",
        marginTop: globalStyles.spacings.gaps.medium,
        marginHorizontal: globalStyles.spacings.gaps.veryBig
    }
}
