'use strict';

import {
    LxReactTableView,
    LxReactTableViewLxViewAdapter
} from "LxComponents";

window.GUI = function (GUI) {
    class TableViewScreen extends GUI.Screen {

        tableContent = []

        get useNewTableView() {
            return Debug.React.NewTableView;
        }

        constructor() {
            super(...arguments);
            this.element.addClass("screen table-view-screen");
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(() => {
                let prms = [];

                if (this.emptyViewConfig) {
                    this.updateEmptyViewConfig();
                }

                this.tableViewDataSource = tableViewDataSource(null, null, this);
                this.tableViewDelegate = tableViewDelegate(null, this);
                this.tableViewDataSource.name = this.viewId + "-DataSource";
                this.tableViewDelegate.name = this.viewId + "-Delegate";

                let tableView = this.getTableView(),
                    performanceStyle = LxReactTableView.PerformanceStyle.Normal;

                if (this.useNewTableView) {
                    if (tableView instanceof GUI.EditableTableView) {
                        performanceStyle = LxReactTableView.PerformanceStyle.Editable;
                    }

                    prms.push(this.appendReactComp({
                        reactComp: LxReactTableViewLxViewAdapter,
                        compProps: {
                            dataSource: this.tableViewDataSource,
                            delegate: this.tableViewDelegate,
                            performanceStyle: performanceStyle,
                            insets: ["bottom"],
                            styles: this.styles || {}
                        },
                        target: this.getTableViewPlaceholder()
                    }).then(tableView => this.tableView = tableView));
                } else {
                    this.tableView = tableView;
                    prms.push(this.appendSubview(this.tableView, this.getTableViewPlaceholder()));
                    this.tableView.getElement().addClass("table-view-screen__content");
                    this.tableView.getElement().addClass("table-view-screen__table-view"); // Don't do it like this, it will pass along the arguments from the "then" function which may result in issues
                    // if the reloadTable function expects parameters!
                    // return Q(vdlPrms).then(this.reloadTable.bind(this));
                }

                return Q.all(prms).then(function () {
                    if (this.tableView instanceof GUI.ReusingListView) {
                        this.reloadTable();
                        return Q(true);
                    } else {
                        return Q(this.reloadTable() || true);
                    }
                }.bind(this));
            });
        }

        getTableView() {
            return new GUI.TableViewV2(this.tableViewDataSource, this.tableViewDelegate);
        }

        getTableViewPlaceholder() {// overwrite if needed
        }

        destroy() {
            return super.destroy(...arguments);
        }

        setTableContent() {
            var prms = [true];
            var dataSourceModified = false;
            var delegateModified = false;

            if (this.emptyView) {
                var isEmpty = !this.tableContent || !this.tableContent.length;

                prms.push(this.toggleSubview(this.emptyView, isEmpty));
                if (Debug.React.NewTableView) {
                    isEmpty && console.warn(this.viewId, "trying to hide reactTableView --> won't work");
                    //TODO-woessto: change this, as the reactTableView cannot be toggled as of now.
                } else {
                    prms.push(this.toggleSubview(this.tableView, !isEmpty));
                }
            } // there are views, like the qrCodeView that provide sections as array instead of section objects.


            var validatedContent = this._validateContent(this.tableContent);

            dataSourceModified = this.tableViewDataSource.update(validatedContent);
            delegateModified = this.tableViewDelegate.update(validatedContent);
            return Q.all(prms).then(function () {
                return delegateModified || dataSourceModified;
            });
        }

        /**
         * reloads the tableView
         * @returns Q.Promise<any>
         */
        reloadTable() {
            return Q(this.setTableContent() || true).then(function reloadTableAfterSettingContent(needsReload) {
                return needsReload ? this.tableView.reload() : Q.resolve();
            }.bind(this));
        }

        /**
         * reloads the tableView
         * @returns {*}
         */
        reloadRowsSelective(sectionIdx, startIndex, nCells) {
            return this.tableView.reloadRowsSelective.apply(this.tableView, arguments);
        }

        /**
         * reloads the tableView
         * @returns {*}
         */
        removeRow(sectionIdx, startIndex, animate) {
            return this.tableView.removeRow.call(this.tableView, arguments);
        }

        updateEmptyViewConfig() {
            if (Debug.React.NewTableView) {

            }
            if (this.emptyView) {
                this.emptyView.update(this.emptyViewConfig());
            } else {
                this.emptyView = this._createEmptyView();
                this.appendSubview(this.emptyView);
            }
        }

        /**
         * Will ask for the emptyView's config and then instantiate and return a new background view.
         * @returns {*}
         * @private
         */
        _createEmptyView() {
            var cfg = this.emptyViewConfig(),
                view = new GUI.BackgroundView(cfg.iconSrc, cfg.title, cfg.message, cfg.buttonText, cfg.buttonAction, cfg.noCircle, cfg.elementIsNotClickable);
            view.getElement().addClass("table-view-screen__content");
            view.getElement().addClass("table-view-screen__empty-view");

            if (cfg.translucent) {
                view.setTranslucent();
            }

            return view;
        }

        /**
         * Ensures the tableContent is passed in the proper format. E.g. in the QRCodeResultScreen the sections are
         * not passed as objects, but as array where the first item is a string that stands for the title.
         * @param content
         * @returns {[]|*}
         * @private
         */
        _validateContent(content) {
            if (!content) {
                return content;
            }

            var newContent = [];

            if (Array.isArray(content)) {
                content.forEach(function (section, idx) {
                    if (Array.isArray(section)) {
                        newContent[idx] = this._convertSectionArrToObject(section);
                    } else {
                        newContent[idx] = section;
                    }
                }.bind(this));
            } else {
                newContent = content;
            }

            return newContent;
        }

        /**
         * Will convert a section passed as array into a section object that contains a rows attribute and - if
         * present - a title attribute.
         * @param sectionArr
         * @returns {{rows: []}}
         * @private
         */
        _convertSectionArrToObject(sectionArr) {
            var sectionObject = {
                rows: []
            };
            sectionArr.forEach(function (secItem, idx) {
                if (typeof secItem === "string" && idx === 0) {
                    sectionObject.title = secItem;
                } else if (secItem !== null) {
                    sectionObject.rows.push(this._validateRowItem(secItem));
                } else {// old screens like the QrCodeResultScreen did provide null items, this is no longer supported.
                }
            }.bind(this));
            console.log(this.viewId, "_convertSectionArrToObject: ", sectionArr, sectionObject);
            return sectionObject;
        }

        _validateRowItem(item) {
            // Old screens did pass the type of the cell as separate "cellType" attribute, now it's used as "type"
            if (item.hasOwnProperty("cellType")) {
                item.type = item.cellType;
            }

            return item;
        }

    }

    GUI.TableViewScreen = TableViewScreen;
    return GUI;
}(window.GUI || {});
