import {NewSelectorScreen} from "LxComponents";
import {useEffect, useMemo, useRef} from "react";
import globalStyles from "GlobalStyles";

export default function SceneSelectorScreen({navigation, route}) {
    const selectedCmds = route.params?.selectedCmds;
    const sceneControls = useRef(SandboxComponent.getStructureManager().getAllSceneControls()).current;

    const sceneOptions = useMemo(() => {
        return sceneControls.map(sceneControl => {
            const execCmd = getURIParameters(sceneControl.getSceneExecUrlScheme()).cmd;
            const alreadySelected = !!selectedCmds?.includes(execCmd);
            return {
                id: sceneControl.uuidAction,
                title: sceneControl.getName(),
                disabled: !sceneControl.isSecured && alreadySelected
            };
        });
    }, [selectedCmds]);

    useEffect(() => {
        if (sceneOptions?.length === 0) {
            NavigationComp.showPopup({
                message: _("loxone-control.quick-action.no-scenes"),
                buttons: [{
                    title: _("scenes.create")
                }],
                buttonOk: true,
                icon: Icon.CAUTION,
                color: globalStyles.colors.orange
            }).then((buttonId) => {
                navigation.goBack();
                if (buttonId === 0) {
                    navigation.navigate(ScreenState.AutomaticDesigner.ScenesOverviewScreen);
                }
            })
        }
    }, [sceneOptions])

    const sceneSelected = (optionId, selected) => {
        const selectedSceneControl = sceneControls.find(sceneControl => sceneControl.uuidAction === optionId);
        if (selectedSceneControl?.isSecured) {
            NavigationComp.showPopup({
                message: _("quick-action.scene-password.error-message"),
                buttonOk: true,
                icon: Icon.CAUTION,
                color: globalStyles.colors.orange
            });
        } else if (selectedSceneControl && selected) {
            QuickActionUtility.addCommand(selectedSceneControl.getSceneExecUrlScheme(), selectedSceneControl.getName(), selectedSceneControl.groupDetail);
        }
    }

    return <NewSelectorScreen onSelected={sceneSelected} autoClose={true} options={sceneOptions} title={_("search.controltype.automaticscene")}
                              animationType={AnimationType.FADE}/>
}
