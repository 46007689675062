'use strict';
/**
 * Created by loxone on 08.03.17
 *
 *
 * callbacks:
 * onFavoriteChanged(isFav)      called whenever a the favorite button changes the isFavorite flag.
 */

define([], function () {
    return class ColorPreviewBar extends GUI.View {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return $('<div class="color-preview-bar__button">' + ImageBox.getResourceImageWithClasses(Icon.Light.STAR, "button__icon") + '</div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor() {
            super($('<div class="color-preview-bar" />'));
            this.isFavActive = false;
        }

        viewDidLoad() {
            if (this.elements) {
                console.error(this.name + " received viewDidLoad twice!");
                return;
            }

            super.viewDidLoad(...arguments);
            this.colorView = new GUI.ColorView(GUI.ColorViewType.BAR);
            this.appendSubview(this.colorView);
            this.colorView.getElement().addClass("color-preview-bar__canvas");
            this.element.append(ColorPreviewBar.Template.getTemplate());
            this.elements = {
                button: this.element.find('.color-preview-bar__button')
            };
            this.button = new GUI.LxRippleButton(this.element);
            this.button.on(GUI.LxRippleButton.CLICK_EVENT, this.handleButtonTap.bind(this));
            this.addToHandledSubviews(this.button);
        }

        setFavoriteActive(favActive) {
            this.elements.button.toggleClass("color-preview-bar__button--active", favActive);
            this.isFavActive = favActive;
        }

        /**
         * Will update the colors shown in the preview bar. The background will be a gradient
         * @param colors        Array of colors to show in the preview.
         */
        setColors(colors) {
            var hasColors = colors && colors.length > 0;
            this.toggleSubview(this.colorView, hasColors);
            this.elements.button.toggle(hasColors);
            this.button.setEnabled(hasColors);

            if (hasColors) {
                this.colorView.setColors(colors);
            }
        }

        dispatchFavoriteSelected(fav) {
            this.onFavoriteSelected && this.onFavoriteSelected(fav);
        }

        handleButtonTap() {
            this.setFavoriteActive(!this.isFavActive);
            this.onFavoriteChanged && this.onFavoriteChanged(this.isFavActive);
        }

    };
});
