'use strict';

define([], function () {
    return class IntercomControlAboutIntercomScreen extends GUI.Screen {
        constructor(details) {
            super($('' + '<div class="about-intercom-screen">' + '<div class="about-intercom-screen__error"></div>' + '<div class="about-intercom-screen__content"></div>' + '</div>' + ''));
            Object.assign(this, StateHandler.Mixin);
            this.control = details.control;
            this.error = details.error;
            this.hasVideo = this.control.hasVideoInfo();
            this.hasAudio = this.control.hasAudioInfo();
            this.content = [];
            this.version = false;

            if (this.hasVideo) {
                this.vInfo = this.control.details.videoInfo;
            }

            if (this.hasAudio) {
                this.aInfo = this.control.details.audioInfo;
            }
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.elements = {
                error: this.element.find('.about-intercom-screen__error'),
                content: this.element.find('.about-intercom-screen__content')
            };

            if (!this.error) {
                this.elements.error.hide();
            } else {
                var errorText = _('controls.intercom.about.video.error.title');

                errorText += "<br>(" + this.error.title + ")<br>";
                errorText += _('controls.intercom.about.video.error.message');
                this.elements.error.html(errorText);
            }

            this.tableView = new GUI.TableViewV2(this, this);
            this.appendSubview(this.tableView, this.elements.content);
            this._createContent() && this._reloadTable();
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments);

            this._requestStates();
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);

            this._registerForStates();
        }

        viewDidDisappear(viewRemainsVisible) {

            this._unregisterStates();

            super.viewDidDisappear(viewRemainsVisible);
        }

        getURL() {
            return "AboutIntercom";
        }

        getAnimation() {
            return HD_APP ? AnimationType.MODAL : AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarText() {
            return _("controls.intercom.about.title");
        }

        titleBarConfig() {
            return {
                leftSide: HD_APP ? 'closeButton' : 'backButton'
            };
        }

        receivedStates(states) {
            this.version = states.version;
            this._createContent() && this._reloadTable();
        }

        // TableViewDataSource Methods
        styleForTableView() {
            return HD_APP ? TableViewStyle.GROUPED : null;
        }

        numberOfRowsInSection(section) {
            return this.content[section].sectionContent.length;
        }

        numberOfSections() {
            return this.content.length;
        }

        titleForHeaderInSection(section, tableView) {
            return this.content[section].sectionTitle;
        }

        cellTypeForCellAtIndex(section, row, tableView) {
            return GUI.TableViewV2.CellType.GENERAL;
        }

        // TableViewCellDataSource
        contentForCell(cell, section, row, tableView) {
            return this.content[section].sectionContent[row];
        }

        // TableViewDelegate Methods
        didSelectCell(cell, section, row, tableView) {
            var title, value;

            if (this.content[section].sectionContent[row].copyable) {
                title = this.content[section].sectionContent[row].subtitle;
                value = this.content[section].sectionContent[row].title;
                NavigationComp.showCopyPopup(title, value);
            }
        }

        _reloadTable() {
            return this.tableView.reload();
        }

        _createContent() {
            var sectionContent,
                devType = this.control.getDeviceTypeName(),
                prevContent = JSON.stringify(this.content);
            this.content = [];
            this.videoSection = -1;
            this.audioSection = -1; // general device info

            sectionContent = [];
            sectionContent.push(this._createCell(_("controls.intercom.about.type"), devType));

            if (this.control.isIntercom()) {
                sectionContent.push(this._createCell(_("controls.intercom.about.firmware"), this._getFirmware(), true));
            }

            this.content.push({
                sectionContent: sectionContent
            }); // Video Info

            if (this.hasVideo) {
                this.videoSection = this.content.length;
                sectionContent = [];
                sectionContent.push(this._createCell(_("controls.intercom.about.video.path"), this.vInfo.streamUrl, true));
                sectionContent.push(this._createCell(_("controls.intercom.about.video.image"), this.vInfo.alertImage, true));
                sectionContent.push(this._createCell(_("user"), this.vInfo.user, true));
                sectionContent.push(this._createCell(_("password"), this._getPassText(this.vInfo.pass)));
                this.content.push({
                    sectionTitle: _("controls.intercom.about.video"),
                    sectionContent: sectionContent
                });
            } // Video Info


            if (this.hasAudio) {
                this.audioSection = this.content.length;
                sectionContent = [];
                sectionContent.push(this._createCell(_("controls.intercom.about.audio.host"), this.aInfo.host, true));
                sectionContent.push(this._createCell(_("user"), this.aInfo.user, true));

                if (this.control.hasSecuredSIPModule()) {
                    var cntnt = this._createCell(_("password"), this._getPassText(this.aInfo.pass)); // a password is mandatory for secured intercoms with SIP.


                    if (!this.aInfo.pass || this.aInfo.pass === "") {
                        cntnt.titleColor = window.Styles.colors.red;
                        cntnt.subtitleColor = window.Styles.colors.red;
                    }

                    sectionContent.push(cntnt);
                }

                this.content.push({
                    sectionTitle: _("controls.intercom.about.audio"),
                    sectionContent: sectionContent
                });
            }

            return prevContent !== JSON.stringify(this.content);
        }

        /**
         * the identifier will be shown a subtitle above the value. title & subtitle will swap places (subtitle
         * shown above the title)
         * @param identifier
         * @param value
         * @param copyable
         * @returns {{title: *, subtitle: *, changeTitleSubtitle: boolean, clickable: boolean}}
         * @private
         */
        _createCell(identifier, value, copyable) {
            var content = {
                title: value,
                subtitle: identifier,
                changeTitleSubtitle: true,
                clickable: copyable,
                copyable: copyable,
                showFullTitle: true
            };

            if (!value || value === "") {
                content.titleColor = Color.STATE_INACTIVE;
                content.title = _("unknown");
            }

            return content;
        }

        /**
         * Since the password should not be compromised, it will be converted to a userfriendly string.
         * @param pass  the password to covert
         * @private
         */
        _getPassText(pass) {
            var result;

            if (!pass || pass === "") {
                result = _("unknown");
            } else {
                result = _("controls.intercom.about.hidden-password", {
                    length: pass.length
                });
            }

            return result;
        }

        _getFirmware() {
            return this.version ? this.version : _("unknown");
        }

    };
});
