'use strict';

define(["./presenceDetectorScreen.jsx"], function (PresenceDetectorScreenComp) {
    return class PresenceDetectorScreen extends GUI.Screen {

        static ReactComp = PresenceDetectorScreenComp.default;

        constructor(details) {
            super($('<div>'));
        }
    };
});
