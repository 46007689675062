import globalStyles from "GlobalStyles";
import { StyleSheet, View, Text } from "react-native";
import PropTypes from "prop-types";
import Icons from "IconLib"
import {
    useControl
} from "LxComponents";
import ViewTypeInfoText from "./ViewTypeInfoText";
import useSpotPriceData from "../hooks/useSpotPriceData";
import {
    Categorization,
    getColorFor
} from "../utils";

function Price({ price, unit, color }) {
    return (
        <Text style={StyleSheet.compose(styles.priceLabel, {color})} numberOfLines={2}>
            {price}
            <Text style={styles.priceUnit}>
                {unit}
            </Text>
        </Text>
    )
}

Price.propTypes = {
    price: PropTypes.string,
    unit: PropTypes.string,
    color: PropTypes.string
}

export default function PriceSummary({uuidAction, viewInfo}) {

    const {details} = useControl(uuidAction),
        {max, min, average} = useSpotPriceData({
            uuidAction,
            start: viewInfo.start,
            end: viewInfo.end
        }),
        {valueTxt: minText, succTxt: minUnit} = lxUnitConverter.convertSplitAndApply(details?.format ?? "", min),
        {valueTxt: avgText, succTxt: avgUnit} = lxUnitConverter.convertSplitAndApply(details?.format ?? "", average),
        {valueTxt: maxText, succTxt: maxUnit} = lxUnitConverter.convertSplitAndApply(details?.format ?? "", max);

    return (
        <View style={styles.root}>
            <View style={styles.overview}>
                <View style={styles.tile}>
                    <Icons.LowestPrice style={styles.icon} />
                    <Price price={minText} unit={minUnit} color={getColorFor({category: Categorization.LOWEST})} />
                    <Text style={styles.label} numberOfLines={1}>{_("controls.spotpriceoptimizer.lowest-abr")}</Text>
                </View>
                <View style={styles.tile}>
                    <Icons.HighestPrice style={styles.icon} />
                    <Price price={maxText} unit={maxUnit} color={getColorFor({category: Categorization.HIGHEST})} />
                    <Text style={styles.label} numberOfLines={1}>{_("controls.spotpriceoptimizer.highest-abr")}</Text>
                </View>
                <View style={styles.tile}>
                    <Icons.AveragePrice style={styles.icon} />
                    <Price price={avgText} unit={avgUnit} color={globalStyles.colors.text.primary} />
                    <Text style={styles.label} numberOfLines={1}>{_("controls.spotpriceoptimizer.average-abr")}</Text>
                </View>
            </View>
            <ViewTypeInfoText viewInfo={viewInfo} />
        </View>
    )
}

const styles = StyleSheet.create({
    root: {
        flexDirection: "column",
        paddingVertical: 16,
        width: "100%"
    },
    overview: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between"
    },
    label: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.primary,
    },
    value: {
    },
    tile: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: globalStyles.spacings.gaps.smallest,
        flex: 1
    },
    icon: {
        marginBottom: 4,
        height: 24,
        width: 24,
    },
    priceLabel: {
        paddingVertical: 6,
        textAlign: "center",
        ...globalStyles.textStyles.title3.bold
    },
    priceUnit: {
        ...globalStyles.textStyles.footNote.bold,
        whiteSpace: "nowrap",
        alignSelf: "flex-end",
        marginLeft: 4,
    },
    spacer: {
        width: globalStyles.spacings.gaps.small,
        height: "100%"
    }
})

PriceSummary.propTypes = {
    uuidAction: PropTypes.string,
    viewInfo: PropTypes.object
}
