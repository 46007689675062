import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Rooms(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.5 9.447c0 1.348-.284 2.63-.795 3.79-1.708 4.2-5.764 7.527-7.677 8.923a1.737 1.737 0 01-2.066 0C8.85 20.617 4.12 16.719 2.83 11.89a9.38 9.38 0 01-.33-2.443c0-.134.006-.265.018-.394C2.726 4.018 6.894 0 12.005 0 17.249 0 21.5 4.23 21.5 9.447zM12 13a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
            />
        </Svg>
    )
}

export default Rooms
