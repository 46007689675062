import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Snooze(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path d="M2.5 2a1 1 0 011-1h7a1 1 0 01.753 1.659L5.703 9H10.5a1 1 0 110 2h-7a1 1 0 01-.753-1.659L8.297 3H3.5a1 1 0 01-1-1zm11 5a1 1 0 011-1H21a1 1 0 01.776 1.63L16.601 14H21a1 1 0 110 2h-6.5a1 1 0 01-.776-1.63L18.899 8H14.5a1 1 0 01-1-1zm-9 8a1 1 0 100 2h2.865l-3.633 4.36A1 1 0 004.5 23h5a1 1 0 100-2H6.635l3.633-4.36A1 1 0 009.5 15h-5z" />
        </Svg>
    )
}

export default Snooze;
