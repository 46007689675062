import {LxReactBadge, LxReactBadgeType, useControl} from "../Components";
import globalStyles from "GlobalStyles";

export default function MsShortcutBadge({controlUuid, containerStyle, forCell = false, forContent = false}) {
    const control = useControl(controlUuid);

    const msBadgeLetters = (ctrl) => {
        let badgeId;
        if (ctrl && nullEmptyString(ctrl.name)) {
            badgeId = ctrl.name.substr(0, Math.min(2, ctrl.name.length));
        } else if (ctrl && nullEmptyString(ctrl.serialNo) && ctrl.serialNo.length >= 2) {
            const length = ctrl.serialNo.length;
            badgeId = ctrl.serialNo.substr(length - 2, 2);
        } else {
            badgeId = "MS";
        }
        return badgeId.toUpperCase();
    }

    const textStyle = () => {
        const style = {...globalStyles.textStyles.body.bold};

        if (forContent) {
            style.fontSize = globalStyles.fontSettings.sizes.title1;
        } else if (!forCell) { // = card
            //style.fontSize = globalStyles.fontSettings.sizes.large;
        }

        return style;
    }

    const badgeSize = () => {
        if (forCell) {
            return 40;
        } else if (forContent) {
            return 80;
        } else {
            // card
            return 48;
        }
    }

    const getContainerStyle = () => {
        return {
            width: badgeSize(),
            height: badgeSize(),
            margin: "auto",
            ...containerStyle
        }
    }

    return <LxReactBadge text={msBadgeLetters(control)}
                         textStyle={textStyle()}
                         containerStyle={getContainerStyle()}
                         backgroundColor={globalStyles.colors.stateActive}
                         size={badgeSize()}
                         badgeType={LxReactBadgeType.ROUND} />
}