import {
    useHD,
    LxReactPressable,
    LxReactContextMenuHandler,
    LxBackgroundComp,
    LxReactText,
    LxReactRenderErrorView,
    LxReactControl,
    LxReactCardCell,
    useSystemStatusInfo,
    useBackNavigation,
    AmbientContext,
    LxReactWaitingView,
    navigatorConfig,
    LxReactImageView,
    useLxWindowDimensions
} from "LxComponents";
import React, {useImperativeHandle, forwardRef, useState, useRef, useEffect, useMemo, useContext} from "react";
import Icons from "IconLib";
import {View} from "react-native";
import PropTypes from "prop-types";
import { useNavigation } from "@react-navigation/native";
import { FlatGrid } from 'react-native-super-grid';
import globalStyles from "GlobalStyles";
import {getCurrentScreenState} from "../../../react-comps/navigation/LxReactNavigationStateAnalyzer";

const CardScreen = forwardRef((
    {
        tableContentFromParent,
        emptyScreenConfig,
        showBackNavigationButton,
        getTableContent,
        title,
        tabBarLabel,
        contextMenuOptions,
        sorting,
        hasContextMenu,
        getFavForIndex,
        getSortingLocation,
        getSortingContextMenuOptions
    }, forwardRef) => {

    const {isAmbientMode} = useContext(AmbientContext)
    const navigation = useNavigation();
    const [tableContent, setTableContent] = useState(tableContentFromParent);
    const isHD = useHD();
    const structureReadyRef = useRef(false);
    const containerRef = useRef(null)
    const {width} = useLxWindowDimensions(isAmbientMode);

    const _getCellsInRow = (initial) => {
        let isSafeHD = isHD || !isAmbientMode; // When Ambient Mode is Entrypoint, Favorite Screen in app is loading after Ambient Mode, therefore isHD is false, which is wrong
        const cellCount = {
            sd: 2,
            m: 3,
            l: 4,
            xl: 5
        };

        let tmpCellsInRow = 0,
            isSd = !isSafeHD || width <= 640,
            isHdM = isSafeHD && (width > 640 && width <= 844),
            isHdL = isSafeHD && (width > 844 && width <= 1055),
            isHdXl = isSafeHD && width > 1055;

        if (isSd) {
            tmpCellsInRow = cellCount.sd;
        } else if (isHdM) {
            tmpCellsInRow = cellCount.m;
        } else if (isHdL) {
            tmpCellsInRow = cellCount.l;
        } else if (isHdXl) {
            tmpCellsInRow = cellCount.xl;
        }

        return tmpCellsInRow;
    }

    const cellsInRow = useMemo(() => {
        return _getCellsInRow();
    }, [width])

    useImperativeHandle(forwardRef, () => ({
        getSortingOption () {
            return _getSortingContextMenuOption();
        },
        getSortingContextMenuOptions(cell) {
            let contextMenuContent = [],
                type = "control";

            if (!cell.content.control) {
                type = "group";
            }

            contextMenuContent.pushObject(_createExpertModeLite(cell.content[type]));
            contextMenuContent.pushObject(_createExpertMode(cell.content[type]));
            contextMenuContent.pushObject(_getSortingContextMenuOption());

            return contextMenuContent;
        },
        addAsFavorite (control, location, groupUuid, group) {
            if (ActiveMSComponent.isExpertModeLightEnabled() && !NavigationComp.getCurrentActivityType()) {
                ActiveMSComponent.addFavorite(control, location, groupUuid, group);
            }
        },

        removeAsFavorite (control, location, groupUuid, group) {
            if (ActiveMSComponent.isExpertModeLightEnabled() && !NavigationComp.getCurrentActivityType()) {
                ActiveMSComponent.removeFavorite(control, location, groupUuid, group);
            }
        }
    }));

    const getRoute = () => {
        return getCurrentScreenState(navigation.getState());
    }

    useBackNavigation(() => {
        if (showBackNavigationButton || getRoute() !== ScreenState.FavoritesV2) {
            navigation.goBack()
        } else {
            if (PlatformComponent.isAndroid()) {
                navigator.app.exitApp();
            } else if (PlatformComponent.isDeveloperInterface()) {
                developerAttention("On an Android device the App would have been closed now!");
            }
        }
    });

    // region handle Listeners
    useEffect(() => {
        let eventListeners = [];
        ActiveMSComponent.comReadyPrms.depActiveMsDone(() => {
            ActiveMSComponent.getSortingReadyPromise().then(() => {
                eventListeners = [
                    NavigationComp.registerForUIEvent(NavigationComp.UiEvents.StructureChanged, handleStructureChanged),
                    NavigationComp.registerForUIEvent(NavigationComp.UiEvents.FavoritesChanged, handleStructureChanged),
                    NavigationComp.registerForUIEvent(NavigationComp.UiEvents.SortingStructureChanged, handleStructureChanged),
                    NavigationComp.registerForUIEvent(NavigationComp.UiEvents.TilePresentationChanged, handleStructureChanged),
                ];

                eventListeners.push(CompChannel.on(CCEvent.Pause, () => {
                    structureReadyRef.current = false;
                }));

                eventListeners.push(CompChannel.on(CCEvent.TaskRecorderStart, () => {
                    //updateTitleBar()
                }));

                eventListeners.push(CompChannel.on(CCEvent.TaskRecorderEnd, () => {
                    //updateTitleBar()
                }));

                structureReadyRef.current = true;
                handleStructureChanged();
            });
        })

        return () => {
            eventListeners.forEach(listener => {
                listener();
            })
        }
    }, [])

    useEffect(() => {
        if (isAmbientMode) {
            navigation.setOptions({
                ...navigatorConfig({
                    title,
                    tabBarLabel,
                    isAmbientMode,
                    rightActions: [{
                        action: ({dimensions, props, key}) => {
                            return <ContextMenuButton forTitleBar={true}/>
                        }
                    }],
                    onRightAction: _handleMoreButtonTapped
                })
            })
        }
    }, [isAmbientMode, title, tabBarLabel])
    // endregion

    // region tableView handling
    /*useEffect(() => {
        let handleResize;

        ActiveMSComponent.getSortingReadyPromise().then(() => {

            handleResize = () => {

            }
            window.addEventListener("resize", handleResize);
        });

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [])*/
    // endregion

    useEffect(() => {
        setTableContent(tableContentFromParent)
    }, [tableContentFromParent])

    const _updateTableContent = (tmpTableContent) => {
        if (structureReadyRef.current) {
            setTableContent(tmpTableContent ||tableContentFromParent)
        }
    }

    const _getShowExpertModeLight = (control) => {
        return ActiveMSComponent.isExpertModeLightEnabled() && (!control.uuidParent || !!control.parentControl);
    }

    const _createExpertModeLite = (groupOrControl) => {
        if (_getShowExpertModeLight(groupOrControl)) {
            return {
                title: _("context-menu.visualization"),
                action: () => {
                    if (groupOrControl.uuidAction) {
                        NavigationComp.showControlSettings(groupOrControl, true);
                    } else {
                        NavigationComp.showGroupSettings(groupOrControl, true);
                    }
                }
            };
        }
    }

    const _getShowExpertMode = (control) => {
        return ActiveMSComponent.isExpertModeEnabled() && (!control.uuidParent || !!control.parentControl);
    }


    const _createExpertMode = (groupOrControl) => {
        if (_getShowExpertMode(groupOrControl)) {
            if (groupOrControl.uuidAction) {
                return {
                    title: _("context-menu.expert-mode"),
                    action: () => {
                        NavigationComp.showControlSettings(groupOrControl, false);
                    }
                };
            } else {
                return {
                    title: _('expert-settings'),
                    action: () => {
                        NavigationComp.showGroupSettings(groupOrControl);
                    }
                }
            }

        }
    }

    const handleStructureChanged = () => {
        _updateTableContent(getTableContent());
    }

    const _handleMoreButtonTapped = (event) => {
        LxReactContextMenuHandler.shared.showContextMenu(contextMenuOptions(), title, event.currentTarget)
    }

    const _getSortingContextMenuOption = () => {
        if (ActiveMSComponent.isExpertModeLightEnabled() && !NavigationComp.getCurrentActivityType()) {
            return {
                title: _("context-menu.change-sorting"),
                action: () => {
                    navigation.navigate(ScreenState.SortingScreen, {
                        tableContent,
                        getFavForIndex,
                        getSortingLocation,
                    });
                }
            };
        } else {
            return null;
        }
    }



    const _getEmptyScreenConfig = () => {
        if (emptyScreenConfig) {
            return emptyScreenConfig;
        }
    }


    const _getBorderStyle = (displayAsCell, index, rowIndex, totalItemsCount) => {
        let borderStyle = {},
            borderWidth = 1;

        if (isAmbientMode) {
            borderStyle.borderColor = globalStyles.colors.borderColor.ambient;
        }

        if (displayAsCell) {
            if (rowIndex === 0) {
                borderStyle.borderTopWidth = borderWidth;
            }

            borderStyle.borderBottomWidth = borderWidth;
        } else {
            if ((index + 1) < (cellsInRow * (rowIndex + 1))) {
                borderStyle.borderRightWidth = borderWidth;
            }

            if (index < (totalItemsCount - (totalItemsCount % cellsInRow || cellsInRow))) {
                borderStyle.borderBottomWidth = borderWidth;
            }
        }

        return borderStyle;
    }

    const extractKey = (item, idx) => {
        let key;
        if (item.content.control) {
            key = item.content.control.uuidAction;
        } else if (item.content.group) {
            key = item.content.group.uuid;
        }
        if (!key) {
            developerAttention("Nothing to extract a key from!");
            key = idx;
        } else {
            key += "-" + idx;
        }
        return key;
    }

    // region Inner Components

    const ContextMenuButton = (props) => {
        let tmpContextMenuOptions = [_getSortingContextMenuOption()];

        refs.current.savedContextMenuOptions = tmpContextMenuOptions.concat(contextMenuOptions()).filter(option => option);

        if (refs.current.savedContextMenuOptions.length) {
            if (props.forTitleBar) {
                return  <LxReactImageView source={Icon.Buttons.MORE2}
                                         imageStyle={globalStyles.customStyles.titleBarIcon}/>
            } else {
                return (
                    <LxReactPressable
                        onPressIn={_handleMoreButtonTapped}
                        childComp={{
                            comp: Icons.MoreCircled,
                            props: {
                                height: 22,
                                width: 22,
                                fill: globalStyles.colors.brand
                            }
                        }}
                    />)
            }
        } else {
            return null;
        }
    }

    const CardContent = (props) => {
        let tmpContent = [];
        const HeaderChevron = ({onPressCallback}) => {
            if (onPressCallback) {
                return <Icons.Chevron height={globalStyles.sizes.icons.small} width={globalStyles.sizes.icons.tiny} fill={globalStyles.colors.text.primary} style={styles.chevron}/>
            }
            return null;
        }

        tableContent.forEach((section, idx) => {
            if (section.headerTitle) {
                tmpContent.push(
                    <LxReactPressable key={"sectionTitle:" + section.headerTitle} onPress={section.didSelectHeader ? section.didSelectHeader : null}
                                      style={[styles.sectionHeader.container, {
                                          marginHorizontal: refs.current.isSD ? 16 : 24,
                                          marginTop: idx > 0 ? 40 : 0,
                                      }]} pressableStyle={styles.sectionHeader.pressableContainer}>
                        <LxReactText
                            style={styles.sectionHeader.title}>
                            {section.headerTitle}
                        </LxReactText>
                        <HeaderChevron onPressCallback={section.didSelectHeader}/>
                    </LxReactPressable>
                )
            }
            let GridItem;
            if (section.rows[0].content.hasOwnProperty("control")) {
                GridItem = ({item, index, rowIndex}) => {
                    return <LxReactControl
                        key={item.content.control.uuidAction}
                        controlUuid={item.content.control.uuidAction}
                        displayAsCell={item.content.displayAsCell}
                        showGroupDetails={item.content.showGroupDetails}
                        displayRoomName={item.content.displayRoomName}
                        iconClass={item.content.iconClass}
                        getSortingContextMenuOptions={getSortingContextMenuOptions}
                        borderStyle={JSON.stringify(_getBorderStyle(item.content.displayAsCell, index, rowIndex, section.rows.length))}
                    />
                }
            } else {
                GridItem = ({item, index, rowIndex}) => {
                    let group = item.content.group;
                    item.content.title2 = group.name;

                    item.content.iconSrc = group.image;

                    if (!item.content.useSimpleDesign) {
                        item.content.iconColor = group.color;
                    }

                    item.content.msIcon = true;
                    item.content.clickable = true;
                    return <LxReactCardCell
                        key={item.content.group.uuid}
                        content={item.content}
                        getSortingContextMenuOptions={getSortingContextMenuOptions}
                        borderStyle={JSON.stringify(_getBorderStyle(item.content.displayAsCell, index, rowIndex, section.rows.length))}
                        removeLeftPadding={true}
                    />
                }
            }
            tmpContent.push(<Grid key={"section:" + idx} data={section.rows} displayAsCell={section.rows[0].content.displayAsCell}
                                  renderItemFn={GridItem}/>)
        })
        return tmpContent;
    }

    const Grid = ({data, displayAsCell, renderItemFn, rowIdx, columnIdx}) => {
        let tmpCellsInRow = 1,
            horizontalPadding = 0,
            itemContainerStyle = {};

        if (displayAsCell) {
            if (cellsInRow > 2) {
                tmpCellsInRow = 2;
                itemContainerStyle.paddingHorizontal = 24;
            } else {
                horizontalPadding = 16;
                // IMPORTAMT!!!
                // don't use flex 1
                itemContainerStyle.width = "100%";
            }
        } else {
            tmpCellsInRow = cellsInRow;
        }

        return (
            <FlatGrid
                data={data}
                maxItemsPerRow={tmpCellsInRow}
                keyExtractor={extractKey}
                staticDimension={Math.min(width, 1280)}
                itemContainerStyle={itemContainerStyle} // needed to override the cell width
                style={[styles.grid, {
                    paddingHorizontal: horizontalPadding
                }]}
                spacing={0}
                renderItem={renderItemFn}
            />
        )
    }

    const ViewContent = (props) => {
        if (tableContent && tableContent.length) {
            return (
                <View style={styles.card.container}>
                    <View style={styles.card.innerContainer}>
                        {!isAmbientMode && <View style={styles.card.topContainer}>
                            <LxReactText style={styles.card.miniserverTitle}>
                                {ActiveMSComponent.getMiniserverName().toUpperCase()}
                            </LxReactText>
                            <View style={styles.card.headerContainer}>
                                <LxReactText
                                    numberOfLines={1}
                                    style={styles.card.title}>
                                    {title.toUpperCase()}
                                </LxReactText>
                                <ContextMenuButton/>
                            </View>
                        </View>}
                        <View>
                            <CardContent/>
                        </View>
                    </View>
                </View>
            )
        } else {
            let currEmptyCfg = _getEmptyScreenConfig();
            if (currEmptyCfg && Object.keys(currEmptyCfg).length !== 0) {
                return (<LxBackgroundComp
                    icon={<currEmptyCfg.iconSrc width={45} height={45} style={styles.emptyIcon} fill={globalStyles.colors.brand}/>}
                    title={currEmptyCfg.title}
                    subTitle={currEmptyCfg.subTitle}
                    message={currEmptyCfg.message}
                />)
            } else {
                return <LxReactWaitingView />
            }
        }
    }

    const TopBarMessageCenterEntries = () => {
        const sysStatusInfo = useSystemStatusInfo();

        if (sysStatusInfo.count > 0) {
            return (
                <LxReactPressable
                    style={styles.container}
                    onPress={() => {
                        navigation.navigate(ScreenState.MessageCenterMessagesScreen);
                    }}>
                    <LxReactText
                        style={styles.topBar.entryText}>
                        {_('message-center.x-unread-entries', {
                            count: sysStatusInfo.count
                        })}
                    </LxReactText>
                </LxReactPressable>
            )
        } else {
            return null;
        }
    }

    const TopBar = () => {
        return (
            <View style={styles.topBar.container}>
                <TopBarMessageCenterEntries/>
            </View>
        )
    }

    // endregion

    const refs = useRef({
        timeIdentifier: null,
        savedContextMenuOptions: null,
        sortingChanged: false,
        windowWith: window.innerWidth,
        lastScreenCards: 0,
        isSD: cellsInRow === 2
    })

    try {
        return (
            <View style={styles.container} ref={containerRef}>
                <ViewContent/>
                {!isAmbientMode && <TopBar/>}
            </View>
        )
    } catch(error) {
        return (
            <LxReactRenderErrorView error={error}/>
        )
    }
})

const styles = {
    container: {
        flex: 1
    },
    topBar: {
        container: {
            position: "absolute",
            justifyContent: "center",
            height: 36,
            width: "100%",
            background: globalStyles.colors.black
        },
        entryText: {
            ...globalStyles.textStyles.footNote.default,
            paddingVertical: 10,
            alignSelf: "center",
            textAlign: "center",
            color: globalStyles.colors.text.secondary,
            width: "100%"
        }
    },
    card: {
        container: {
            flex: 1,
            overflow: "scroll",
            alignSelf: "center",
            maxWidth: 1280,
            width: "100%",
        },
        innerContainer: {
            flexDirection: "column"
        },
        topContainer: {
            flexDirection: "column",
            width: "auto",
            paddingTop: 36,
            paddingBottom: 24,
            paddingHorizontal: 24
        },
        miniserverTitle: {
            color: globalStyles.colors.text.secondary,
            ...globalStyles.textStyles.footNote.bold,
            letterSpacing: 1
        },
        headerContainer: {
            flexDirection: "row",
            alignItems: "center"
        },
        title: {
            color: globalStyles.colors.white,
            ...globalStyles.textStyles.largeTitle.bold,
            flex: 1,
            textOverflow: "ellipsis",
        }
    },
    chevron: {
        marginLeft: globalStyles.spacings.gaps.verySmall
    },
    sectionHeader: {
        container: {
            paddingVertical: 5,
            marginBottom: 16,
        },
        pressableContainer: {
            flexDirection: "row",
            alignItems: "center"
        },
        title: {
            ...globalStyles.textStyles.title3.bold,
            color: globalStyles.colors.text.primary
        }
    },
    emptyIcon: {
        marginBottom: globalStyles.sizes.mediumBig
    },
    grid: {
        height: "auto"
    }
}

CardScreen.propTypes = {
    tableContentFromParent: PropTypes.arrayOf(PropTypes.object),
    emptyScreenConfig: PropTypes.object,
    showBackNavigationButton: PropTypes.bool,
    getTableContent: PropTypes.func,
    title: PropTypes.string,
    tabBarLabel: PropTypes.string,
    contextMenuOptions: PropTypes.func,
    sorting: PropTypes.bool,
    hasContextMenu: PropTypes.bool,
    getFavForIndex: PropTypes.func,
    getSortingLocation: PropTypes.func,
    getSortingContextMenuOptions: PropTypes.func
}

export default CardScreen;
