'use strict';

class BackupAndSyncRestoreScreen extends GUI.OnboardingScreen {
    //region Static
    static getShownIds() {
        return [];
    }

    static shouldShow(id) {
        return true;
    } //endregion Static


    constructor(details) {
        super($("<div />"));
        this.continueDef = details.continueDef;
        this.iconSrc = details.iconSrc;
        this.iconColor = details.iconColor;
        this.title = details.title;
        this.message = details.message;
        this.continueBtnText = details.continueBtnText;
    }

    getRightIcon() {
        if (this.rightButtonSrc) {
            return this.rightButtonSrc;
        }

        return super.getRightIcon(...arguments);
    }

    rightAction() {
        if (this.rightButtonAction) {
            return this.rightButtonAction();
        }

        return super.rightAction(...arguments);
    }

    getAnimation() {
        return AnimationType.NONE;
    }

    continueBtnAction() {
        var callbackDefer = Q.defer(),
            waitingPromise = PersistenceComponent.backupAndSyncGetBackup();
        this.ViewController.showState(ScreenState.WaitingScreen, null, {
            cancelButtonText: _("cancel"),
            message: _("backup-and-sync.waiting.search.message"),
            callbackDefer: callbackDefer,
            waitingPromise: waitingPromise
        });
        callbackDefer.promise.then(function (res) {
            var details = {
                miniservers: [],
                modificationDate: ""
            };

            if (res.miniservers && res.modificationDate) {
                details.miniservers = res.miniservers;
                details.modificationDate = res.modificationDate;
            }

            this.ViewController.showState(ScreenState.BackupAndSyncBackup, null, details);
        }.bind(this), function (error) {
            this.ViewController.showErrorPopup(_("error"), error);
        }.bind(this));
    }

    onTopLeftButtonTapped() {
        this.ViewController.dismiss();
    }

}

GUI.BackupAndSyncRestoreScreen = BackupAndSyncRestoreScreen;
