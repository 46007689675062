'use strict';

define("ColorPickerV2Control", ["Control", "ColorPickerV2ControlEnums"], function (Control, ColorPickerV2ControlEnums) {
    var ACTION_IDENTIFIER = {
        SEND_COMMAND: 'sendCommand'
    };
    return class ColorPickerV2Control extends Control {
        constructor() {
            super(...arguments);
            applyMixins(this, CustomRateHandler.Mixin);
        }

        specificCreateCmdText(cmdParts) {
            var text = null,
                cfg = {
                    name: this.name
                };

            switch (cmdParts[1].toLowerCase()) {
                case "setfav":
                    text = _("cmdtext.colorpicker.setfav", cfg);
                    break;

                case "setfavsequence":
                    text = _("cmdtext.colorpickerv2.setfavsequence", cfg);
                    break;

                case "setsequence":
                    text = _("cmdtext.colorpickerv2.setsequence", cfg);
                    break;

                case "setbrightness":
                    text = _("cmdtext.colorpickerv2.setbrightness", cfg);
                    break;

                default:
                    text = _("cmdtext.colorpicker.color", cfg);
                    break;
            }

            return text;
        }

        sendCommand() {
            return this._requestPerformAction(ACTION_IDENTIFIER.SEND_COMMAND, arguments);
        }

        isInDayLightMode() {
            var states = this.getStates();

            if (states) {
                return states.sequence && states.sequence.type === ColorPickerV2ControlEnums.PickerMode.DAYLIGHT;
            } else {
                return false;
            }
        }

        tuneAbleWhiteOnly() {
            return this.details.pickerType === ColorPickerV2ControlEnums.Picker_Type.TUNEABLE_WHITE;
        }

        /**
         * Execute the requested method with the given arguments (values)
         * This function is required by the CustomRateHandler
         * @param action
         * @param values
         * @private
         */
        _performAction(action, values) {
            var promise;

            switch (action) {
                case ACTION_IDENTIFIER.SEND_COMMAND:
                    promise = this._sendCommand.apply(this, values);
                    break;

                default:
                    promise = Q.reject("Action " + action + " is not known to ColorPickerV2Control");
            }

            return promise;
        }

    };
});
