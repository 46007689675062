'use strict';

define(["AudioZoneControlEnums"], function (AudioZoneControlEnums) {
    class AddZoneFavoritesScreen extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            Object.assign(this, MediaStateHandler.Mixin);
            this.delegate = details.delegate;
            this.control = details.control;
            this.itemToAdd = details.itemToAdd;
            this.zoneFavorites = [];
            this._selectedCell = -1;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this._requestEventContent(this.EVENT_TYPES.ZONE_FAVORITES);
            }.bind(this));
        }

        getTableView() {
            //this.tableViewDelegate.didRequestRemovingCell = this.didRequestRemovingCell.bind(this);
            return new GUI.EditableTableView(this.tableViewDataSource, this.tableViewDelegate);
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarConfig() {
            return {
                rightSide: TitleBarCfg.TEXT,
                rightText: _("apply")
            };
        }

        titleBarText() {
            return _("save");
        }

        titleBarActionRight() {
            var cmd = MediaServerComp.getZoneFavoriteSaveCommand(this._selectedCell + 1, // The slots start from 1, not from 0
                this.control.details.playerid, this.itemToAdd, MediaServerComp.getTitleSubtitleForItem(this.itemToAdd).title, this.itemToAdd.contentType, this.delegate.getMediaInfo(this.itemToAdd.contentType));
            MediaServerComp.sendMediaServerCommand(cmd);
            this.closeAction();
        }

        receivedZoneFavoritesAllDataEvent(res) {
            this.zoneFavorites = Array.apply(Array, Array(MediaEnum.MAX_ZONE_FAVS)).map(function () {
                return null;
            }); // Create an empty array with 8 entries

            res.items.forEach(function (zoneFav) {
                this.zoneFavorites[zoneFav.slot - 1] = zoneFav;
            }.bind(this)); // Get the first empty favorite slot or the frst one

            this._selectedCell = Math.max(this.zoneFavorites.indexOf(null), 0);
            this.reloadTable().then(function () {
                this.tableView.setEditingModeForSection(0, true);
            }.bind(this));
        }

        setTableContent() {
            this.tableContent = [{
                headerTitle: this.control.getName(),
                rows: this.zoneFavorites.map(function (zoneFav, idx) {
                    var baseCell = Controls.AudioZoneControl.MediaBrowserFavorites.getZoneFavoriteEditCellForFavAtIdx(zoneFav, idx);
                    baseCell.type = GUI.TableViewV2.CellType.CHECKABLE;
                    baseCell.content.selected = idx === this._selectedCell;
                    baseCell.content.rightIconColor = window.Styles.colors.red;
                    baseCell.content.radioMode = GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE;
                    return baseCell;
                }.bind(this)),
                didMoveCell: function didMoveCell(section, row, tableView, cell, oldIdx, newIdx) {
                    this.zoneFavorites.move(oldIdx, newIdx);
                }.bind(this)
            }];
            return super.setTableContent(...arguments);
        }

        didCheckCell(cell, section, row, tableView, selected) {
            this._setFavAtRowIdxReplacing(row, !!this.zoneFavorites[row]);

            if (this._selectedCell !== row) {
                this._setFavAtRowIdxReplacing(this._selectedCell, false);
            }

            this._selectedCell = row;
        }

        _setFavAtRowIdxReplacing(row, replacing) {
            var cell = this.tableView.cellForSectionAndRow(0, row);

            if (cell) {
                if (replacing) {
                    cell.content.rightIconSrc = Icon.AudioZone.NEW.CHANGE;
                } else {
                    delete cell.content.rightIconSrc;
                }

                cell.setRightIcon(cell.content.rightIconSrc);
            }
        }

    }

    Controls.AudioZoneControl.AddZoneFavoritesScreen = AddZoneFavoritesScreen;
});
