'use strict';

define(["LightV2ControlEditMoodList"], function (LightV2ControlEditMoodList) {
    return class LightV2ControlEditMoodsScreen extends Controls.ControlContent {
        constructor(details) {
            super(details);
            this.isLightControl = true;
            this.editMoodList = new LightV2ControlEditMoodList(this.control, true);
            this.editMoodList.sendCommand = this.sendCommand;
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                return this.appendSubview(this.editMoodList).then(function () {
                    this.editMoodList.getElement().addClass("light-v2-control-edit-moods-screen__moods");
                }.bind(this));
            }.bind(this));
        }

        getURL() {
            return "EditMoods";
        }

        getTitlebar() {
            return null;
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        receivedStates(states) {
            this.editMoodList.update(states);
        }

    };
});
