import { Graph, LoadingGraph, useControl } from "LxComponents"
import PropTypes from "prop-types";
import { EfmUtilities, EfmViewType } from "../../efmUtilities";
import { useMemo } from "react";

import globalStyles from "GlobalStyles";
import useExtendedStats from "../hooks/useExtendedStats.jsx";

import {forceDataIntoMode} from "../util/averageValues.js";
import generateLabels from "../util/generateLabels.js";

import { getTotalWhileIgnoringTinyNumbers } from "../util/getSpecificValue.js";

export default function StorageGraph({ timeRange, storStats, viewType, loading = false, controlUuid, currentValue, fullStretch, onZoom, isLivePast }) {
    const extendedNodeStatsData = useExtendedStats({
        stats: storStats,
        viewType,
        startTs: timeRange.start,
        endTs: timeRange.end,
        newExtend: true
    })
    const control = useControl(controlUuid);
    const {graphData, horizontalLabels, titleRight, highlightDateFormat, bottomLabelsCentered} = useMemo(() => {
        if(!storStats || !storStats.data || storStats.data.length === 0) {
            return {};
        }

        const graphData = [];
        let usedData = extendedNodeStatsData;
        if(viewType === EfmViewType.Actual) {
            usedData = forceDataIntoMode(extendedNodeStatsData, timeRange.start, timeRange.end, storStats.mode);
        }
        usedData.forEach(stat => {
                const arr = [];
                stat.values.forEach((value, idx) => {
                    arr.push({
                        color: globalStyles.colors.blue,
                        timestamp: stat.ts,
                        value: (/[N|n]eg/g.test(storStats.header[idx].output) && value > 0 ? -value : value),
                    });
                });
                graphData.push(arr);
            }
        );

        const {horizontalLabels, highlightDateFormat, bottomLabelsCentered} = generateLabels({viewType, data: graphData});


        let titleRight;
        if(storStats && currentValue && (typeof currentValue.total === 'number' || typeof currentValue.actual === 'number')){
            let num = 0;
            if(typeof currentValue.actual === 'number') {
                num = currentValue.actual;
            } else {
                num = currentValue.total - currentValue.totalNeg;
            }
            let titleRightFormat = storStats.header[0].format;
            if(isLivePast && storStats.dayStats) {
                titleRightFormat = storStats.dayStats.header[0].format;
            }
            const formattedTotal = lxUnitConverter.convertSplitAndApply(titleRightFormat, getTotalWhileIgnoringTinyNumbers({total: num}));
            titleRight = `${formattedTotal.valueTxt} ${formattedTotal.succTxt}`;
        }

        return {
            graphData,
            horizontalLabels,
            highlightDateFormat,
            bottomLabelsCentered: bottomLabelsCentered,
            titleRight,
        }
    }, [storStats, currentValue]);

    return (
        <Graph
            titleLeft={!fullStretch ? _("efm.storage-title") : undefined}
            titleRight={!fullStretch ? titleRight : undefined}
            fullStretch={fullStretch}
            type={viewType === EfmViewType.Actual ? 'line' : 'bar'}
            horizontalLabels={horizontalLabels}
            datapoints={graphData}
            format={control.actualFormat}
            label={viewType === EfmViewType.Actual ? control.actualUnit : control.totalUnit}
            highlightDateFormat={highlightDateFormat}
            bottomLabelsCentered={bottomLabelsCentered}
            onZoom={onZoom}
            forcedTimeRange={timeRange}
            upperGraphMin={0.5}
            lowerGraphMax={-0.5}
            legend={[
                {
                    color: globalStyles.colors.blue,
                    label: `${_("meter2.storage.discharge-energy")} (+) / ${_("meter2.storage.charge-energy")} (-)`,
                }
            ]}
        />
    )
}

StorageGraph.propTypes = {
    gridStats: PropTypes.object,
    timeRange: PropTypes.object,
    viewType: PropTypes.string,
}
