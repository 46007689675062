/**
 * Created by loxone on 20.06.16.
 */
'use strict';

define(['AddMediaViewControllerV2Base', 'FavoritesManager'], function (AddMediaViewControllerV2Base, FavoritesManager) {
    class AddMediaViewControllerV2ZoneFavorite extends AddMediaViewControllerV2Base {
        constructor(details) {
            super(...arguments);
        }

        viewWillAppear() {
            var args = arguments;
            return FavoritesManager.shared(this.control).favorites().then(favs => {
                this.details.blockedIds = favs.map(function (fav) {
                    return fav.audiopath;
                });
                return super.viewWillAppear(...args);
            });
        }

        addItem(item, mediaInfo) {
            return Q(this.details.addFn({
                item: item,
                contentType: item.contentType,
                mediaTypeDetails: mediaInfo
            })).then(function (res) {
                if (this.shouldBlockItemAfterAdd(item)) {
                    this.addToBlockedItems(item);
                }

                return res;
            }.bind(this));
        }

        shouldShowAddCounter() {
            return false;
        }

        shouldBlockItemAfterAdd() {
            return true;
        }

        removeItem(idx) {// Just do nothing...
        }

        moveItem(oldIdx, newIdx) {// Just do nothing...
        }

    }

    GUI.AddMediaViewControllerV2ZoneFavorite = AddMediaViewControllerV2ZoneFavorite;
    return GUI.AddMediaViewControllerV2ZoneFavorite;
});
