'use strict';

window.GUI = function (GUI) {
    class WeatherWidget extends GUI.View {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return $('<div class="widget--no-valid-data cell__top-border-effect clickable">' + '<div class="weather__symbol"></div>' + '<div class="weather__texts">' + '<div class="texts__actual-placeholder">' + '<div class="actual__text">' + _("weather.actual") + '</div>' + ImageBox.getResourceImageWithClasses('resources/Images/General/disclosure-icon.svg', 'actual__disclosure-icon') + '</div>' + '<div class="texts__weather-type"></div>' + '</div>' + '<div class="weather__temp-value-placeholder">' + '<span class="value-label__integer"></span>' + '<span class="value-label__float"></span>' + '<span class="value-label__unit"></span>' + '</div>' + '</div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor() {
            super(WeatherWidget.Template.getTemplate());
            this.format = "%.1f°";
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.weatherServer = ActiveMSComponent.getStructureManager().getWeatherServer();
            this.elements = {
                weatherText: this.element.find('.texts__weather-type'),
                weatherSymbol: this.element.find('.weather__symbol')
            }; // temperature actual value

            var tempValuePlaceholder = this.element.find('.weather__temp-value-placeholder');
            this.elements.tempValue = {
                integer: $(tempValuePlaceholder.children()[0]),
                float: $(tempValuePlaceholder.children()[1]),
                unit: $(tempValuePlaceholder.children()[2])
            };
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);
            this.openWeatherHandler = Hammer(this.element[0]).on(tapEvent(), this.showWeather.bind(this));

            if (this.weatherServer) {
                SandboxComponent.registerForStateChangesForUUID(WEATHER_UUID, this, this.onStateChange.bind(this));
            }
        }

        viewDidDisappear() {
            super.viewDidDisappear(...arguments);

            if (this.weatherServer) {
                SandboxComponent.unregisterForStateChangesForUUID(WEATHER_UUID, this);
            }
        }

        destroy() {
            this.openWeatherHandler.dispose();
            super.destroy();
        }

        onStateChange(states) {
            Debug.Weather && console.log("WeatherWidget received states");

            if (states.hasValidData) {
                var actual = states.forecasts.today[0];
                var iconUrl = actual.weatherIcon,
                    temperature = actual.temperature,
                    weatherText = actual.weatherText; // update icon

                this.elements.weatherIcon && this.elements.weatherIcon.remove();
                this.elements.weatherIcon = $(ImageBox.getResourceImageWithClasses(iconUrl, 'symbol__icon'));
                this.elements.weatherSymbol.prepend(this.elements.weatherIcon);
                this.elements.weatherText.text(weatherText);
                var actualValueDetails = getDetailValuesFromTemperatureFormat(lxFormat(this.format, temperature));
                setDetailValuesFromTemperatureToLabels(actualValueDetails, this.elements.tempValue.integer, this.elements.tempValue.float, this.elements.tempValue.unit);
                this.element.removeClass('widget--no-valid-data');
            } else {
                this.element.addClass('widget--no-valid-data');
            }
        }

        showWeather() {
            NavigationComp.showState(ScreenState.Weather);
        }

    }

    GUI.WeatherWidget = WeatherWidget;
    return GUI;
}(window.GUI || {});
