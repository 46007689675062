'use strict';

define(['AudioZoneV2ControlEnums', 'AudioZoneV2DetailedContentBase', "SpotifyAccountManager"], function (AudioZoneV2ControlEnums, AudioZoneV2DetailedContentBase, SpotifyAccountManager) {
    class SpotifyLibraryScreen extends AudioZoneV2DetailedContentBase {
        //region Getter
        get desc() {
            return _("media.popup.spotify.title");
        } //endregion Getter


        constructor(details) {
            super(...arguments);
            applyMixins(this, MediaStateHandlerV2.Mixin);
            this.myPlaylist = details.myPlaylist;
            this.items = details.items;
            this.delegate = details.delegate;
            this.contentTypes = [MusicServerEnum.MediaContentType.SERVICE];
            this.username = details.username;
        }

        setViewController() {
            super.setViewController(...arguments);
            this.setViewControllerHtmlAttr(MusicServerEnum.HtmlProp.CONTENT_ID, MusicServerEnum.ControlContentIdentifiers.SPOTIFY);
            this.setViewControllerHtmlAttr(MusicServerEnum.HtmlProp.ACC_DEP_CONTENT, true);

            if (!this.ViewController.handleOnSubtitleTapped) {
                this.ViewController.handleOnSubtitleTapped = function () {
                    SpotifyAccountManager.shared(this.control).showAccountSelector(this.ViewController);
                }.bind(this);
            }
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                this._registerForMediaServerEvents(this.EVENT_TYPES.SERVICE);
            }.bind(this));
        }

        destroy() {
            this._unregisterForMediaServerEvents(this.EVENT_TYPES.SERVICE);

            return super.destroy(...arguments);
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarText() {
            return _("media.library");
        }

        viewControllerSubtitleText() {
            return this.username;
        }

        receivedServiceReloadEvent(reason) {
            if (reason === MusicServerEnum.ReloadCause.USER_CHANGED) {
                var user = SpotifyAccountManager.shared(this.control).activeUser;

                if (user) {
                    this.username = user.user;

                    this.ViewController._checkTitlebar();
                }
            }
        }

        setTableContent() {
            this.tableContent = [];

            if (this.myPlaylist) {
                this.tableContent.push({
                    rows: [this._getCellForItem(this.myPlaylist)]
                });
            }

            if (this.items) {
                this.tableContent.push({
                    rows: this.items.map(this._getCellForItem.bind(this))
                });
            }

            return super.setTableContent(...arguments);
        }

        getMediaInfo() {
            return {
                service: {
                    uid: MusicServerEnum.ControlContentIdentifiers.SPOTIFY + "/" + this.username,
                    cmd: MusicServerEnum.Service.SPOTIFY
                }
            };
        }

        _getCellForItem(item) {
            var cell = this.delegate._getCellForItem.call(this.delegate, item, true);

            cell.action = function () {
                return this.ViewController.showState(Controls.AudioZoneV2Control.MediaBrowserV2Base.getScreenStateForItem(item), null, this.delegate._getScreenStateDetailsForItem(item));
            }.bind(this);

            return cell;
        }

        _getRightSideForTitleBar() {
            return null;
        }

    }

    Controls.AudioZoneV2Control.SpotifyLibraryScreen = SpotifyLibraryScreen;
    return Controls.AudioZoneV2Control.SpotifyLibraryScreen;
});
