'use strict';

define("ClimateControlCommandSrc", ["ControlCommandSrc", "ClimateControlEnums"], function (ControlCommandSrc, ClimateControlEnums) {
    return class ClimateControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [],
                settingsModeSettings = Object.values(ClimateControlEnums.SERVICE_MOOD_SETTING).filter(function (id) {
                    return id !== ClimateControlEnums.SERVICE_MOOD_SETTING.OFF; // The off command will be displayed separately, filter it out
                }); // Only show commands in the service mode

            if (states.currentMode === ClimateControlEnums.MODE.SERVICE_MODE) {
                cmds.push(this.createCommand(_("pool.control.quit.service-mode"), Commands.format(Commands.CLIMATE_CONTROLLER.SET_SERVICE_MODE, ClimateControlEnums.SERVICE_MOOD_SETTING.OFF))); // Merge the existing commands with the service commands

                /*cmds = cmds.concat(settingsModeSettings.map(function (id) {
                    return this._serviceCmd(id, states);
                }.bind(this))); */
            }

            return cmds;
        }

        /**
         * Creates a service command. Only one serviceMode can be active at a time (like radio buttons)
         * @param settingsId
         * @param states
         * @return {*|{name: *, cmd: *, icon: *, isActive: *, details: *}|{name, cmd, icon, isActive, details}}
         * @private
         */
        _serviceCmd(settingsId, states) {
            var cmd = Commands.format(Commands.CLIMATE_CONTROLLER.SET_SERVICE_MODE, settingsId);
            return this.createSelectable(this._getServiceName(settingsId), cmd, null, states.serviceMode === settingsId, {
                type: GUI.TableViewV2.CellType.CHECKABLE
            });
        }

        /**
         * Returns the correct name of the given settingsId
         * @param settingsId
         * @return {*}
         * @private
         */
        _getServiceName(settingsId) {
            var name;

            switch (settingsId) {
                case ClimateControlEnums.SERVICE_MOOD_SETTING.STANDBY:
                    name = _("controls.climate.servicemode.inactive");
                    break;

                case ClimateControlEnums.SERVICE_MOOD_SETTING.HEATING_ON:
                    name = _("controls.climate.servicemode.heating");
                    break;

                case ClimateControlEnums.SERVICE_MOOD_SETTING.COOLING_ON:
                    name = _("controls.climate.servicemode.cooling");
                    break;

                case ClimateControlEnums.SERVICE_MOOD_SETTING.VENT_ON:
                    name = _("controls.climate.servicemode.ventilation");
                    break;

                case ClimateControlEnums.SERVICE_MOOD_SETTING.OFF:
                    name = _("off");
                    break;
            }

            return name;
        }

    };
});
