import { useState, useEffect, useRef, useMemo } from 'react';
import { useControl, useConnectionReady } from 'LxComponents';
import {useIsFocused} from "@react-navigation/native";
import CancelablePromise from 'cancelable-promise';

export const StatisticDataPointUnit = {
    ALL: "all",
    HOUR: "hour",
    DAY: "day",
    MONTH: "month",
    YEAR: "year",
}

/**
 * Used to receive the statisticV2 of a control
 * @param controlUuid:string
 * @param fromUnixUtcTs:number
 * @param toUnixUtcTs:number
 * @param outputNames:string[]  list of outputNames to return, must be in the same group!
 * @param dataPointUnit:StatisticDataPointUnit
 * @param [preProcessFn:func] optionally provided to enable processing the datapoints right before displaying them
 * @returns {{ data:{ ts:number, values:number[]}[], header:{title:string,format:string,output:string}[]}}
 */
function useStatisticV2(controlUuid, fromUnixUtcTs, toUnixUtcTs, outputNames, dataPointUnit = StatisticDataPointUnit.ALL, preProcessFn = null) {
    const [nodeStats, setNodeStats] = useState({});
    let currentRequestId = useRef(0);
    let filteredOutputs = useRef(false);

    const control = useControl(controlUuid);
    const isConnReady = useConnectionReady();
    const isFocused = useIsFocused();

    const dbgName = useMemo(() => {
        return control.getName() + "[" + outputNames.join(",") + "]"
    }, [controlUuid, outputNames])


    // region data update handling
    const timeoutRef = useRef(null);

    const clearTimeoutRef = () => {
        let {timeout, prms} = timeoutRef.current ?? {};
        timeout && clearInterval(timeout);
        prms?.cancel();
    }

    useEffect(() => {
        clearTimeoutRef();

        let interval = -1;
        let isShowingActualData = toUnixUtcTs >= ActiveMSComponent.getMiniserverUnixUtcTimestamp();
        if (isShowingActualData && isConnReady && isFocused) {
            switch (dataPointUnit) {
                case StatisticDataPointUnit.MONTH:
                case StatisticDataPointUnit.YEAR:
                case StatisticDataPointUnit.DAY:
                    interval = getRandomIntInclusive(290, 310); // 4:50 - 5:10
                    break;
                case StatisticDataPointUnit.ALL:
                case StatisticDataPointUnit.HOUR:
                default:
                    interval = getRandomIntInclusive(55, 65); // 0:55 - 1:05
                    break;
            }
            Debug.StatisticV2_Hook && console.log(useStatisticV2.name, dbgName + " useEffect[INTERVAL] --> refetch in " + interval + " seconds");
            timeoutRef.current = {
                timeout: setInterval(() => {
                    timeoutRef.current.prms = requestNewStats();
                }, interval * 1000)
            };
        } else {
            Debug.StatisticV2_Hook && console.log(useStatisticV2.name, dbgName + " useEffect[INTERVAL] --> NO cyclic update! connected=" + isConnReady + ", focused=" + isFocused);
        }

        return () => {
            Debug.StatisticV2_Hook && console.log(useStatisticV2.name, dbgName + " useEffect[INTERVAL] CLEANUP!");
            clearTimeoutRef()
        }
    }, [toUnixUtcTs, dataPointUnit, isConnReady, isFocused]);


    // endregion

    const getStatGroupId = (err) => {
        let groupId = -1;
        let groupIdMatch = false;
        let currGroup;

        // check if all have the same:
        groupIdMatch = outputNames.every((outputName) => {
            currGroup = control.getStatisticGroupForOutput(outputName);
            if (!currGroup) {
                err.reason = "No statistics exist for output '" + outputName + "'";
                console.error(useStatisticV2.name, err.reason);
                return false; // group IDs of outputs differ!

            } else if (groupId < 0) {
                groupId = currGroup.id;

            } else if (currGroup.id !== groupId) {
                err.reason = "Cannot acquire statistics from different groups! outputs=" + JSON.stringify(outputNames);
                console.error(useStatisticV2.name, err.reason);
                return false; // group IDs of outputs differ!
            }
            return true;
        });

        if (groupIdMatch) {
            filteredOutputs.current = currGroup.dataPoints.length !== outputNames.length;
        }

        return groupIdMatch ? groupId : -1;
    }

    const requestNewStats = () => {
        let rqId = currentRequestId.current = getRandomIntInclusive(0, 999999);
        let err = {};
        let statGroupId = getStatGroupId(err);

        if (statGroupId === -1) {
            failedToLoadStats(err.reason, rqId);
            return CancelablePromise.reject(err);
        } else {
            Debug.StatisticV2_Hook && console.log(useStatisticV2.name, dbgName + " requestNewStats [" + rqId + "]");
            return requestStatistic(statGroupId, outputNames.length).then((statData) => {
                return newStatsReceived(statData, rqId);
            }, (err) => {
                failedToLoadStats(err, rqId);
                return CancelablePromise.reject(err);
            });
        }
    }

    const requestStatistic = (groupId, numValues) => {
        Debug.StatisticV2_Hook && console.log(useStatisticV2.name, dbgName + " requestStatistic group = " + groupId + ", numValues = " + numValues + ", dpUnit=" + dataPointUnit);
        let outputFilter = filteredOutputs.current ? outputNames.join(",") : "";
        if (dataPointUnit === StatisticDataPointUnit.ALL) {
            return SandboxComponent.getStatisticRaw({
                controlUUID: controlUuid,
                fromUnixUtc: fromUnixUtcTs,
                toUnixUtc: toUnixUtcTs,
                groupId,
                nValues: numValues,
                outputName: outputFilter
            });
        } else {
            return SandboxComponent.getStatisticDiff({
                controlUUID: controlUuid,
                fromUnixUtc: fromUnixUtcTs,
                toUnixUtc: toUnixUtcTs,
                dataPointUnit,
                groupId,
                nValues: numValues,
                outputName: outputFilter
            });
        }
    }

    const getHeaders = () => {
        return outputNames.map((outputName) => {
            return control.getStatisticDataPointForOutput(outputName);
        });
    }

    const getMode = () => {
        let group = control.getStatisticGroupForOutput(outputNames[0]);
        if (group) {
            return group.mode;
        } else {
            console.error(useStatisticV2.name, dbgName + " has no group for output with name " + outputNames[0]);
            return 0;
        }
    }

    const isCurrentRq = (rqId) => {
        return rqId === currentRequestId.current;
    }

    const newStatsReceived = (newStatData, rqId) => {
        if (!isCurrentRq(rqId)) {
            console.warn(useStatisticV2.name, dbgName + " Outdated request responded!");
            return;
        }

        let newStatPackage = {data: newStatData, header: getHeaders(), mode: getMode()};
        if (preProcessFn) {
            console.warn(useStatisticV2.name, dbgName + " Preprocessing request: " + rqId);
            newStatPackage = preProcessFn(newStatPackage);
        }
        setNodeStats(newStatPackage);
    }


    const failedToLoadStats = (err, rqId) => {
        if (!isCurrentRq(rqId)) {
            console.warn(useStatisticV2.name, dbgName + " Outdated request failed " + rqId);
            return;
        }
        console.error(useStatisticV2.name, dbgName + " Failed to load stats - " + JSON.stringify(err), err);
        setNodeStats({err: err});
    }

    useEffect(() => {
        let prms;
        // initial request
        if (isConnReady && isFocused) {
            Debug.StatisticV2_Hook && console.log(useStatisticV2.name, dbgName + " useEffect[RQ_STATS], requesting, " + moment.unix(fromUnixUtcTs).format(DateType.CSV) + "-" + moment.unix(toUnixUtcTs).format(DateType.CSV));
            prms = requestNewStats();
        }

        return () => {
            Debug.StatisticV2_Hook && console.log(useStatisticV2.name, dbgName + " useEffect[RQ_STATS] CLEANUP ");
            // modify request id to avoid dispatching outdated responses.
            currentRequestId.current = -1;
            prms?.cancel();
        };
    }, [controlUuid, fromUnixUtcTs, toUnixUtcTs, dataPointUnit, outputNames.join(",").hashCode(), isConnReady, isFocused]);

    return nodeStats;
}

export default useStatisticV2;
