'use strict';

define(['DaytimerCalendarEntries', 'DaytimerControlEnums', 'PoolDaytimerCalendarEntries', 'DaytimerListEntries', 'DaytimerControlContentEntryDetail', 'DaytimerControlContentOperatingModes', 'DaytimerControlContentDefaultValue'], function (DaytimerCalendarEntries, DaytimerControlEnums, PoolDaytimerCalendarEntries, DaytimerListEntries, DaytimerControlContentEntryDetail, DaytimerControlContentOperatingModes, DaytimerControlContentDefaultValue) {
    return class DaytimerControlContentCalendar extends Controls.ControlContent {
        get reactHeaderShown() {
            return false
        }

        constructor(details) {
            super(details);
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.calendarEntries = this.getCalendarEntries();
            this.listEntries = new DaytimerListEntries(this.control);
            this.listEntries.sendCommand = this.sendCommand.bind(this);
            this.loadingScreen = new GUI.LxWaitingView();
            this.loadingScreen.getElement().addClass("daytimer-control-content-calendar__loading");
            this.calendarVisible = true;
            this.appendSubview(this.calendarEntries);
            this.appendSubview(this.listEntries);
            this.appendSubview(this.loadingScreen);
            this.hideSubview(this.listEntries);
        }

        viewDidAppear() {
            super.viewDidAppear();

            this._titleBar.onLeftButtonTapped = function () {
                this.ViewController.navigateBack();
                this._titleBar.onLeftButtonTapped = null; // workaround for twice calls
            }.bind(this); // add new entry button


            this._titleBar.onRightButtonTapped = function () {
                if (!this.calendarVisible && this.editModeActive) {
                    this.listEntries.removeDeletedEntries();
                } else {
                    this.ViewController.showState(DaytimerControlEnums.ScreenState.ENTRY_DETAIL, null, {
                        control: this.control,
                        isEditMode: false
                    });
                }
            }.bind(this); // list button


            this._titleBar.listButtonTapped = function (showCalendar) {
                this.calendarVisible = showCalendar;

                if (showCalendar) {
                    this.hideSubview(this.listEntries);
                    this.showSubview(this.calendarEntries);
                } else {
                    this.hideSubview(this.calendarEntries);
                    this.showSubview(this.listEntries);
                }
            }.bind(this); // delete button


            this._titleBar.deleteButtonTapped = function (isEditModeActive) {
                this.editModeActive = isEditModeActive;
                this.listEntries.setEditMode(isEditModeActive);
            }.bind(this);

            this.hideSubview(this.loadingScreen);
        }

        viewDidDisappear(viewRemainsVisible) {
            super.viewDidDisappear(viewRemainsVisible);
            this._titleBar.onLeftButtonTapped = null;
            this._titleBar.onRightButtonTapped = null;
            this._titleBar.listButtonTapped = null;
            this._titleBar.deleteButtonTapped = null;
        }

        getURL() {
            return "Calendar";
        }

        getAnimation() {
            return HD_APP ? AnimationType.HD_OVERLAY_FULLSCREEN : AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarText() {
            if (this.control.details.isHeating) {
                return _("controls.irc.daytimer.heating.entries");
            } else if (this.control.details.isCooling) {
                return _("controls.irc.daytimer.cooling.entries");
            } else {
                return _("controls.daytimer.entries");
            }
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK,
                rightSide: 'daytimerCalendar',
                style: 'transparent'
            }
        }

        /**
         * Can be overwritten by subclasses (IRC, Pool) to return their own entries subclass.
         * @return {*}
         */
        getCalendarEntries() {
            return new DaytimerCalendarEntries(this.control);
        }

    };
});
