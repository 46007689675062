import App from "./App";
import LxReactComp from "./LxReactComp";
import LxReactSeparator from "./LxReactSeparator";
import LxReactWaitingIndicator from "./LxReactWaitingIndicator";
import LxReactWaitingView from "./LxReactWaitingView";
import LxReactSwitch from "./LxReactSwitch/LxReactSwitch";
import LxReactSwitchAdapter from "./LxReactSwitch/LxReactSwitchAdapter";
import LxReactVerticalSlider from "./LxReactSlider/LxReactVerticalSlider";
import LxReactHorizontalSlider from "./LxReactSlider/LxReactHorizontalSlider";
import LxSlider from "../GUI/views/lxSlider/lxSlider";
import LxVerticalSlider from "../GUI/views/lxVerticalSlider/lxVerticalSlider";
import LxSliderDefaultSettings from "./LxReactSlider/LxReactSliderDefaultSettings";
import LxReactVerticalSliderButtonView from "./LxReactSlider/LxReactVerticalSliderButtonView";
import LxReactButton from "./LxReactButton/LxReactButton";
import {ButtonType} from "./LxReactButton/LxReactButtonDefaultSettings";
import LxReactIconButton from "./LxReactButton/LxReactIconButton";
import LxWrapperButton from "./LxReactButton/LxWrapperButton";
import LxReactTableView from "./LxReactTableView/LxReactTableView";
import LxReactTableViewLxViewAdapter from "./LxReactTableView/LxReactTableViewLxViewAdapter"
import SearchableTable from "../shared/react/SearchableTable"
import LxIndicator from "./LxIndicator";
import LxReactText from "./LxReactText";
import LxReactUnreadText from "./LxReactUnreadText";
import LxReactImage from "./LxReactImage";
import LxReactAlarmClockBottomContainer from "./LxReactAlarmClockBottomContainer/LxReactAlarmClockBottomContainer";
import LxReactView from "./LxReactView";
import LxReactPressable from "./LxReactPressable";
import LxReactNotification from "./LxReactNotification";
import LxReactBadge, {LxReactBadgeType} from "./LxReactBadge/LxReactBadge";
import LxReactSystemStatusBadge from "./LxReactSystemStatusBadge";
import LxReactFlexibleCell from "./LxReactFlexibleCell/LxReactFlexibleCell";
import LxReactVerticalContainer from "./LxReactVerticalContainer";
import LxReactOnlineView from "./LxReactOnlineView";
import LxReactTextView from "./LxReactTextView";
import LxReactNumberInput from "./LxReactNumberInput";
import LxReactTableViewWrapper from "./LxReactTableView/LxReactTableViewWrapper";
import LxReactCircularProgressbar from "./LxReactCircularProgressbar";
import LxReactLinkText from "./LxReactLinkText";
import LxReactDateTimePicker from "./LxReactDateTimePicker";
import LxReactDurationPicker from "./LxReactDurationPicker";
import LxReactQuickSelect from "./LxReactQuickSelect";
import LxReactSelectorScreen from "./LxReactSelectorScreen";
import LxReactMenuController from "./LxReactMenuController";
import LxReactCompPropTypes from "./LxReactCompPropTypes";
import {LxPropTypes} from "./LxPropTypes";
import LxReactHeader from "./LxReactHeader";
import {LxReactHeaderIconSize} from "./LxReactHeader";
import LxReactLoginForm from "./LxReactLoginForm";
import LxReactScreenAdapter from "./LxReactScreenAdapter";
import navigatorConfig, * as TopNavigator from "./navigation/TopNavigator";
import LxTabBar from "./navigation/TabBar"
import LxReactMiniserverList from "./LxReactMiniserverList";
import LxReactControl from "./LxReactControl";
import LxLegacyReactControlContent from "./LxReactControlContent/LxLegacyReactControlContent";
import LxReactLinkedControlsList from "./LxReactLinkedControlsList";
import LxReactControlStatsGraph from "./LxReactControlStatsGraph";
import LxReactControlStatsScreen from "./LxReactControlStatsScreen";
import LxLegacyReactLockOverlay from "./LxReactControlContent/LxLegacyReactLockOverlay";
import LxControlNoteView from "./LxReactControlContent/LxControlNoteView";
import LxReactCardCell from "./lxReactCardCell";
import LxShimmerPlaceHolder from "./LxShimmerPlaceHolder";
import LxPermissionHandler from "./LxPermissionHandlerComp/LxPermissionHandler"
import LxBackgroundComp from "./LxBackgroundComp";
import LxReactImageView from "./LxReactImageView";
import LxReactRenderErrorView from "./LxReactRenderErrorView";
import LxReactContextMenuHandler from "./../GUI/views/lxContextMenu/lxReactContextMenuHandler";
import useSystemStatusInfo from "./customHooks/systemStatusInfoHook"
import useCountDown from "./customHooks/countDownHook"
import useLiveState from "./customHooks/liveStateHook"
import useCCEvent from "./customHooks/useCCEvent"
import useConnectionReady from "./customHooks/useConnectionReady"
import useIsInForeground from "./customHooks/useIsInForeground"
import useActiveMSHook from "./customHooks/useActiveMSHook"
import useBottomSheet from "./customHooks/useBottomSheet";
import useHD from "./customHooks/useHD";
import useNodeValuesTyped from "./customHooks/useNodeValuesTyped";
import useControl from "./customHooks/useControl";
import useBackNavigation from "./customHooks/useBackNavigation";
import useSetURLHook from "./customHooks/useSetURLHook";
import useSetLegacyURLHook from "./customHooks/useSetLegacyURLHook";
import useControlCtxtMenuOptions from "./customHooks/useControlCtxtMenuOptions";
import useDeviceOrientation from "./customHooks/useDeviceOrientation";
import useCanShowAmbientMode from "./AmbientMode/hooks/useCanShowAmbientMode";
import useOutsideClick from "./customHooks/useOutsideClick";
import useAvailableNodeTypes from "./customHooks/useAvailableNodeTypes";
import useStatisticV2, {StatisticDataPointUnit} from "./customHooks/statisticV2Hook";
import useGroups from "./customHooks/useGroups";
import useNopeAreaForRef from "./customHooks/useNopeAreaForRef";
import useLxWindowDimensions from "./customHooks/useLxWindowDimensions";
import useBorderColor from "./customHooks/useBorderColor";
import useEntryPoint from "./customHooks/useEntryPoint"
import LxReactGraphDummy from "./LxReactGraphDummy";
import DateViewTypeSelector, {DateViewType, DateViewTypeUtils} from "./dateViewTypeSelector/dateViewTypeSelector";
import WeatherComp from '../GUI/ViewController/cardScreen/weatherComp';
import DynamicRightButton from '../GUI/ViewController/cardScreen/dynamicButtonComp';
import TimerComp from '../GUI/ViewController/cardScreen/timerComp';
import LxReactCardScreenTopNavigator from "./navigation/LxReactCardScreenTopNavigator";
import LxReactDeviceSearchNavigator from "./deviceSearch/LxReactDeviceSearchNavigator";
import useDeviceSearchExtensions from "./deviceSearch/useDeviceSearchExtensions";
import useDeviceSearchMiniservers from "./deviceSearch/useDeviceSearchMiniservers";
import LxReactDeviceSearchMiniserverSelection from "./deviceSearch/LxReactDeviceSearchMiniserverSelection";
import LxReactDeviceSearchExtensionSelection from "./deviceSearch/LxReactDeviceSearchExtensionSelection";
import LxReactDeviceSearchTechTagSelection from "./deviceSearch/LxReactDeviceSearchTechTagSelection";
import useDeviceSearchResults from "./deviceSearch/useDeviceSearchResults";
import LxReactDeviceSearchResults from "./deviceSearch/LxReactDeviceSearchResults";
import LxReactDeviceSearchAssistant from "./deviceSearch/LxReactDeviceSearchAssistant";
import LxReactDeviceSearchContext from "./deviceSearch/LxReactDeviceSearchContext";
import LxReactDeviceSearchEnums from "./deviceSearch/LxReactDeviceSearchEnums";
import LxReactControlContent, {CCScreenStates} from "./LxReactControlContent/LxReactControlContent";
import LxBaseControlContent, {CCLocation} from "./LxReactControlContent/LxBaseControlContent";
import LxControlHistoryScreen from "./controlHistory/LxControlHistoryScreen";
import LxControlContentContainer from "./LxReactControlContent/LxControlContentContainer";
import LxControlContextProvider, {ControlContext} from "./LxReactControlContent/LxControlContextProvider";
import LxControlStatisticCell from "./LxReactControlContent/LxControlStatisticCell";
import MessageDetailScreen from "./LxReactMessageCenter/MessageDetailScreen";
import LxReactBackButton from "./LxReactBackButton";
import NewSelectorScreen from "../projectSpecific/react/selector/NewSelectorScreen";

import IrcTempDisplay from "./controls/ircTempDisplay"
import SaunaTempDisplay from "./controls/saunaTempDisplay"

import MsShortcutBadge from "./controls/msShortcutBadge"

import AspectRatioSvg from "./AspectRatioSvg";
import DraggableScrollView from "./DraggableScrollView";
import Graph, {LoadingGraph} from "./Graph";
import CircleGraph, {LoadingCircleGraph} from "./CircleGraph";
import ScrollingCards from "./ScrollingCards";
import Calendar from './Calendar'
import WheelPicker from './WheelPicker'

import TransparentTheme from "../../styles/react/transparentTheme";
import {AmbientContext} from "./AmbientMode/AmbientTabNavigator";

import VirginAppScreen from "./VirginAppScreen";

import React from "react";
import ReactDOM from "react-dom";
import * as ReactNative from "react-native";

class ReactWrapper {
    static reactNodes = []
    static React = React
    static ReactNative = ReactNative
    static render(component, props, targetNode, callback = (e) => {}) {
        let reactElm,
            compInstance;
        if (targetNode instanceof $) {
            targetNode = targetNode[0];
        }
        try {
            reactElm = React.createElement(component, props, null);
            compInstance = ReactDOM.render(reactElm, targetNode, callback);
        } catch (e) {
            callback(e);
        }
        ReactWrapper.reactNodes.push(targetNode);
        return compInstance;
    }

    static unmountReact(node) {
        if (node instanceof $) {
            node = node[0];
        }
        ReactDOM.unmountComponentAtNode(node);
    }
}

export {
    App,

    LxReactScreenAdapter,
    //region Tools
    LxReactComp,
    LxPropTypes,
    LxReactSeparator,
    LxReactWaitingIndicator,
    LxReactWaitingView,
    ReactWrapper,
    LxReactCompPropTypes,
    //endregion

    //region Headers
    LxReactHeader,
    LxReactHeaderIconSize,
    //endregion

    //region Switch
    LxReactSwitch,
    LxReactSwitchAdapter,
    //endregion

    //region Slider
    LxReactVerticalSlider,
    LxReactHorizontalSlider,
    LxSlider,
    LxVerticalSlider,
    LxSliderDefaultSettings,
    LxReactVerticalSliderButtonView,

    //endregion

    //region Button
    LxReactButton,
    ButtonType,
    LxReactIconButton,
    LxWrapperButton,
    //endregion

    LxIndicator,
    LxReactAlarmClockBottomContainer,
    LxReactBadge,
    LxReactBadgeType,
    LxReactVerticalContainer,
    LxReactSystemStatusBadge,
    LxReactDateTimePicker,
    LxReactDurationPicker,
    LxReactQuickSelect,
    LxReactSelectorScreen,
    NewSelectorScreen,
    LxReactMenuController,
    LxReactLoginForm,

    // region CustomHooks
    useSystemStatusInfo,
    useCountDown,
    useLiveState,
    useCCEvent,
    useConnectionReady,
    useIsInForeground,
    useActiveMSHook,
    useBottomSheet,
    useHD,
    useControl,
    useControlCtxtMenuOptions,
    useBackNavigation,
    useSetURLHook,
    useSetLegacyURLHook,
    useDeviceOrientation,
    useOutsideClick,
    useNodeValuesTyped,
    useAvailableNodeTypes,
    useGroups,
    useNopeAreaForRef,
    useLxWindowDimensions,
    useBorderColor,
    useEntryPoint,
    // endregion

    // region Statistic
    useStatisticV2,
    StatisticDataPointUnit,
    LxReactGraphDummy,
    // endregion

    // region DeviceSearch
    useDeviceSearchExtensions,
    useDeviceSearchMiniservers,
    LxReactDeviceSearchMiniserverSelection,
    LxReactDeviceSearchExtensionSelection,
    LxReactDeviceSearchTechTagSelection,
    useDeviceSearchResults,
    LxReactDeviceSearchResults,
    LxReactDeviceSearchNavigator,
    LxReactDeviceSearchContext,
    LxReactDeviceSearchEnums,
    LxReactDeviceSearchAssistant,
    // endregion

    // region TableView
    LxReactTableViewLxViewAdapter,
    LxReactTableView,
    LxReactTableViewWrapper,
    SearchableTable,
    //endregion

    // region Cells
    LxReactFlexibleCell,
    // endregion

    //region Function Components Wrapper
    LxReactText,
    LxReactUnreadText,
    LxReactPressable,
    LxReactView,
    LxReactImage,
    LxReactOnlineView,
    LxReactTextView,
    LxReactNumberInput,
    LxReactCircularProgressbar,
    LxReactLinkText,
    LxShimmerPlaceHolder,
    LxReactNotification,
    //endregion

    // region lists
    LxReactMiniserverList,
    //endregion


    // region Control Stuff
    LxReactControl,
    LxLegacyReactControlContent,
    LxReactLinkedControlsList,
    LxLegacyReactLockOverlay,
    LxReactControlStatsGraph,
    LxReactControlStatsScreen,

    IrcTempDisplay,
    SaunaTempDisplay,
    MsShortcutBadge,

    // endregion

    // region Cards
    LxReactCardCell,
    // endregion

    // region Navigation
    navigatorConfig,
    TopNavigator,
    LxTabBar,
    LxReactBackButton,
    // endregion

    // region Permissions
    LxPermissionHandler,
    // endregion

    // region Graphics
    AspectRatioSvg,
    DraggableScrollView,
    Graph,
    LoadingGraph,
    CircleGraph,
    LoadingCircleGraph,
    // endregion
    LxReactContextMenuHandler,
    LxBackgroundComp,

    //region SVG Utils
    LxReactImageView,
    //endregion

    LxReactRenderErrorView,

    DateViewTypeSelector,
    DateViewType,
    DateViewTypeUtils,

    ScrollingCards,
    Calendar,
    WheelPicker,


    // card screen comps
    WeatherComp,
    DynamicRightButton,
    TimerComp,
    LxReactCardScreenTopNavigator,

    // ControlContents
    LxReactControlContent,
    CCScreenStates,
    LxBaseControlContent,
    LxControlHistoryScreen,
    CCLocation,
    LxControlContentContainer,
    LxControlContextProvider,
    ControlContext,
    LxControlStatisticCell,
    LxControlNoteView,

    //MessageCenter
    MessageDetailScreen,
    useCanShowAmbientMode,
    TransparentTheme,
    AmbientContext,

    VirginAppScreen
}

