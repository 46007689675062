import Svg, { Path } from "react-native-svg"

function PlayStripeCircled(props) {
    return (
        <Svg isreact="true"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path d="M22.485 16.75a.5.5 0 01.22.672 11.95 11.95 0 01-3.654 4.287 11.95 11.95 0 01-5.203 2.15.5.5 0 01-.154-.987 10.95 10.95 0 004.77-1.972 10.95 10.95 0 003.35-3.929.5.5 0 01.671-.22zm-20.302.222a10.95 10.95 0 003.349 3.926 10.95 10.95 0 004.772 1.973.5.5 0 11-.153.988 11.95 11.95 0 01-5.207-2.151 11.95 11.95 0 01-3.653-4.285.5.5 0 01.892-.45zM9 7.5a.5.5 0 01.77-.42l7 4.5a.5.5 0 010 .84l-7 4.5A.5.5 0 019 16.5zm1 .916v7.167L15.575 12 10 8.416zM4.239 3.494a.5.5 0 01-.002.707 10.95 10.95 0 00-2.7 4.399 10.95 10.95 0 00-.4 5.147.5.5 0 11-.988.16 11.95 11.95 0 01.437-5.616 11.95 11.95 0 012.946-4.799.5.5 0 01.707.002zm16.225-.002a11.95 11.95 0 012.948 4.801 11.95 11.95 0 01.437 5.614.5.5 0 11-.987-.16 10.95 10.95 0 00-.401-5.145 10.95 10.95 0 00-2.702-4.4.5.5 0 01.705-.71zM12 0c1.93 0 3.794.456 5.474 1.318a.5.5 0 01-.457.89A10.95 10.95 0 0012 1c-1.77 0-3.48.418-5.02 1.21a.5.5 0 11-.457-.89A11.95 11.95 0 0112 0z" />
        </Svg>
    )
}

export default PlayStripeCircled
