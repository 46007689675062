import {LxReactPressable, AmbientContext} from "LxComponents";
import PropTypes from "prop-types";
import globalStyles from "GlobalStyles";
import Icons from "IconLib";
import {useContext} from "react";

/**
 * @param {Boolean} props.checked Whether the radio button is checked or not
 * @param {Number} props.radius The radius of the radio button
 * @param {Function} props.onToggled Function, that is called when the radio button gets hit
 * @returns {JSX.Element} A radio button that switches between to Images depending on state
 */
function LxReactRadioButton({
                                checked,
                                radius,
                                onToggled,
                                checkedStyle,
                                uncheckedStyle,
                                style = {},
                                disabled = false
                            }) {
    const adoptedRadius = radius || styles.defaultRadius;
    const {isAmbientMode} = useContext(AmbientContext);
    const renderIconElement = () => {
        if (checked) {
            return (
                <Icons.CheckMarkChecked
                    style={{fill: globalStyles.colors.brand, ...checkedStyle}}
                    height={adoptedRadius}
                    width={adoptedRadius}
                />
            )
        } else {
            return (
                <Icons.CheckMarkUnchecked
                    style={{...(isAmbientMode ? styles.uncheckedAmbient : styles.unchecked), ...uncheckedStyle}}
                    height={adoptedRadius}
                    width={adoptedRadius}
                />
            )
        }
    }

    const onToggledFunc = () => {
        return Q.resolve(onToggled(!checked)).then(shouldCheck => {
            if (shouldCheck) {
                HapticFeedback(HapticFeedback.STYLE.SELECT);
            } else {
                HapticFeedback(HapticFeedback.STYLE.ERROR);
            }
        })
    }

    // onMouseDown+onMouseUp required if the radio is shown in a LxReactPressible cell that has an onPress on its own.
    // In such situations the onMouseDown/Up events would bubble on to the cells pressable, leading to the onPress event
    // and the following onToggle of the radioButton to not be called --> if on a device that is using a mouse.
    return (
        <LxReactPressable style={{marginRight: 5, ...style}}
                          onMouseDown={(ev) => {ev.stopPropagation();ev.preventDefault();}}
                          onMouseUp={(ev) => {ev.stopPropagation();ev.preventDefault();}}
                          onPress={onToggledFunc}
                            disabled={disabled}>
            {renderIconElement()}
        </LxReactPressable>
    )
}

const styles = {
    unchecked: {fill: globalStyles.colors.grey["900"], stroke: globalStyles.colors.grey["800"]},
    uncheckedAmbient: {fill: globalStyles.colors.grey["300a36"], stroke: globalStyles.colors.grey["300a36"]},
    defaultRadius: 24
}

LxReactRadioButton.propTypes = {
    checked: PropTypes.bool,
    radius: PropTypes.number,
    onToggled: PropTypes.func.isRequired,
    checkedStyle: PropTypes.object,
    uncheckedStyle: PropTypes.object,
    disabled: PropTypes.bool
}

export default LxReactRadioButton;
