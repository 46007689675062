import {LxReactBadge} from "LxComponents";
import {useMemo} from "react";
import globalStyles from "GlobalStyles";
import {LxReactBadgeType} from "../LxReactBadge/LxReactBadge";

export default function LxReactDeviceSearchBadge({deviceCount, loading}) {

    const {textColor, backgroundColor, text} = useMemo(() => {
        let textColor, backgroundColor, text;

        if (deviceCount && deviceCount > 0) {
            textColor = globalStyles.colors.text.primary;
            backgroundColor = globalStyles.colors.green;
            text = deviceCount;
        } else {
            textColor = globalStyles.colors.grey["300"];
            backgroundColor = globalStyles.colors.grey["300a36"];
            text = "--";
        }

        return {textColor, backgroundColor, text};
    }, [deviceCount]);

    return <LxReactBadge text={text}
                         textStyle={globalStyles.textStyles.body.bold}
                         textColor={textColor}
                         loading={loading}
                         backgroundColor={backgroundColor}
                         badgeType={LxReactBadgeType.SQUARE} />
}
