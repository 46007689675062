import {
    useControl,
    useLiveState,
    LxReactText,
    LxReactNotification,
    LxReactControlStatsGraph,
    LxReactLinkedControlsList,
    DateViewTypeUtils,
    LxReactTableView,
    useBorderColor,
    LxControlNoteView,
    LxControlContextProvider,
} from "LxComponents"
import { View, ScrollView } from "react-native";
import { useMemo, useEffect, useState } from "react";
import globalStyles from "GlobalStyles";
import EmOverview from "./components/emOverview";
import EmLoadsList from "./components/emLoadsList";
import EmStorageSettingsScreen from "./components/emStorageSettingsScreen";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { StyleSheet } from "react-native-web"


export default function EnergyManger2ControlContent({ navigation, route, updateNavigation }) {
    // state handling to show/hide the zoomed graph
    const [storageStatsZoomed, setStorageStatsZoomed] = useState(false)
    useEffect(() => {
        return navigation.addListener('beforeRemove', (e) => {
            if (storageStatsZoomed) {
                setStorageStatsZoomed(false);
                e.preventDefault();
            }
        });
    }, [navigation, storageStatsZoomed]);

    // mind the safe area when zoomed.lx
    const insets = useSafeAreaInsets();

    const details = route.params;
    const control = useControl(details.controlUUID);
    const liveState = useLiveState(details.controlUUID, ["notificationShown", "notificationText", "notificationColor", "stateOfCharge", "storagePower"]);

    /**
     * This takes care of intervening with the back-navigation, in order to update the timestamp/viewType of the parent
     * efm - if there is one.
     */
    useEffect(() => {
        let updateProps = {};
        if (storageStatsZoomed) {
            updateProps.title = control.getStatisticDataPointForOutput("storageLevel").title
        }
        updateNavigation(updateProps);

    }, [storageStatsZoomed]); // important to update when one of these changes, to avoid passing back initial values.

    const notificationHeight = 43;
    const styles = {
        rootCtnr: {
            flexDirection: "column",
            maxHeight: "100%",
            flex: 1,
            overflow: "hidden",
        },
        notificationCntr: {
            position: "absolute",
            top: 0, left: 0, right: 0,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            height: notificationHeight,
            backgroundColor: globalStyles.colors.ambientBg,
            backdropFilter: "blur(28px)",
        },
        notificationText: {
            ...globalStyles.fontSettings.default,
            color: globalStyles.colors.text.primary
        },
        emOverviewCntr: {
            marginTop: globalStyles.sizes.medium,
            marginBottom: globalStyles.sizes.medium,
            paddingLeft: globalStyles.sizes.small,
            paddingRight: globalStyles.sizes.small,
            justifyContent: "center"
        },
        scrollCntr: {
            ...globalStyles.customStyles.screenContent,
            flexDirection: "column",
            flex: 1
        },
        emLoadsListCntr: {
            ...globalStyles.borders.top,
            ...globalStyles.borders.bottom,
            paddingTop: globalStyles.sizes.small,
            paddingLeft: globalStyles.sizes.small,
            paddingRight: globalStyles.sizes.small,
            paddingBottom: globalStyles.sizes.small,
        },
        storageStatsCntr: {
            flexDirection: "column",
            paddingLeft: globalStyles.sizes.small,
            paddingRight: globalStyles.sizes.small,
        },
        zoomedStorageStatsCntr: {
            backgroundColor: globalStyles.colors.black,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            paddingTop: 65, // makes room for the scrubbing labels
            paddingLeft: globalStyles.spacings.contentHorizontal,
            paddingRight: globalStyles.spacings.contentHorizontal,
            paddingBottom: insets.bottom + globalStyles.spacings.gaps.small,
        },
        storageGraph: {
            marginTop: globalStyles.sizes.smallest,
            marginBottom: globalStyles.sizes.smallest
        }
    }


    /**
     * off until midingt / values updated once per minute, ...
     * @returns {null}
     */
    const renderNotification = () => {
        let shouldShow = (liveState.states && liveState.states.notificationShown);
        return <LxReactNotification style={styles.notificationCntr} show={shouldShow} height={notificationHeight}>
            <LxReactText style={styles.notificationText}>{liveState.states.notificationText}</LxReactText>
        </LxReactNotification>;
    }

    const renderOverview = () => {
        return <View style={styles.emOverviewCntr}>
            <EmOverview controlUuid={details.controlUUID} />
        </View>
    }

    const borderColor = useBorderColor();
    const renderLoads = () => {
        return <View style={StyleSheet.flatten([styles.emLoadsListCntr, { borderTopColor: borderColor, borderBottomColor: borderColor }])}>
            <EmLoadsList controlUuid={details.controlUUID} />
        </View>
    }

    const getStorageGraphProps = () => {
        if (control.hasStorageStateOfChargeStatistics) {
            const ouputName = "storageLevel";
            const statDp = control.getStatisticDataPointForOutput(ouputName);
            const viewInfo = DateViewTypeUtils.initializeViewInfo(); //vt+ts
            const range = DateViewTypeUtils.getRange(viewInfo.vt, viewInfo.ts);
            let storageText;
            if (control.hasStorageChargePowerInfo) {
                storageText = lxUnitConverter.convertAndApply(control.actualFormat, liveState.states.storagePower);
            } else {
                storageText = lxFormat(statDp.format, liveState.states.stateOfCharge);
            }

            return {
                controlUuid: details.controlUUID,
                titleRight: storageText,
                fromUnixUtcTs: range.start,
                toUnixUtcTs: range.end,
                viewType: viewInfo.vt,
                titleLeft: statDp.title,
                outputNames: [ouputName],
                ignoreNegative: true,
                colorInfo: globalStyles.colors.blue,
                dataPointUnit: "all",
                // fixedUpperLimit hardcoded to 100 because it is always a percentage
                fixedUpperLimit: 100,
                format: statDp.format,
                style: styles.storageGraph,
                legend: [
                    {
                        color: globalStyles.colors.blue,
                        label: statDp.title
                    }
                ]
            }
        } else {
            return null;
        }
    }

    const renderStorageStatsSmall = () => {
        if (control.hasStorageStateOfChargeStatistics) {
            return <View style={styles.storageStatsCntr} key={"storage-container"}>
                <LxReactControlStatsGraph
                    key={"em-storage-level"}
                    {...getStorageGraphProps()}
                    onZoom={() => { setStorageStatsZoomed(true) }}
                />
            </View>;
        }
    }

    const renderStorageStatsZoomed = () => {
        if (control.hasStorageStateOfChargeStatistics) {
            return <View style={styles.zoomedStorageStatsCntr} key={"storage-container"}>
                <LxReactControlStatsGraph
                    key={"em-storage-level-zoom"}
                    {...getStorageGraphProps()}
                    fullStretch={true}
                />
            </View>;
        }
    }


    const handleStorageSettings = () => {
        navigation.navigate(EmStorageSettingsScreen.name, { controlUUID: details.controlUUID });
    }

    const tableContent = useMemo(() => {
        let section = {};

        section.rows = [];
        if (!control.hasStorageStateOfChargeStatistics && control.storageConnected) {
            section.headerStrongTitle = _("energymanager2.storage.title");

            if (control.hasStorageChargePowerInfo) {
                section.rows.push({
                    title: _("controls.wallbox2.power"),
                    titleStyle: globalStyles.customStyles.cellTitle,
                    cellElementStyle: globalStyles.customStyles.cellElement,
                    mainRightContent: {
                        comp: LxReactText,
                        props: {
                            children: lxUnitConverter.convertAndApply(control.actualFormat, liveState.states.storagePower),
                            isLoading: false,
                            style: globalStyles.customStyles.rightCellText
                        }
                    }
                });
            }
            if (control.hasStorageStateOfChargeInfo) {
                section.rows.push({
                    title: _("energymanager2.storage.state-of-charge"),
                    titleStyle: globalStyles.customStyles.cellTitle,
                    cellElementStyle: globalStyles.customStyles.cellElement,
                    mainRightContent: {
                        comp: LxReactText,
                        props: {
                            children: lxFormat(control.storageFormat, liveState.states.stateOfCharge),
                            isLoading: false,
                            style: globalStyles.customStyles.rightCellText
                        }
                    }
                });
            }
        }

        if (ActiveMSComponent.isAdminUser()) {
            section.rows.push({
                title: _("energymanager2.storage-settings.title"),
                titleStyle: globalStyles.customStyles.cellTitle,
                cellElementStyle: globalStyles.customStyles.cellElement,
                onPress: handleStorageSettings,
                disclosureIcon: true
            });
        }

        return [section];
    }, [
        liveState.states.stateOfCharge,
        liveState.states.storagePower,
        control.supportsStatisticV2,
        control.hasStorageStateOfChargeStatistics,
        control.storageConnected
    ])

    const renderTable = () => {
        return <LxReactTableView tableContent={tableContent} />
    }

    const controlNotes = useMemo(() => {
        return control && control.hasControlNotes ?
            <LxControlContextProvider controlUuid={details.controlUUID} isAlert={false}>
                <LxControlNoteView />
            </LxControlContextProvider>
        : null;
    }, [control && control.hasControlNotes, details.controlUUID])

    return <View style={styles.rootCtnr}>
        {renderNotification()}
        <ScrollView style={styles.scrollCntr}>
            {controlNotes}
            {renderOverview()}      
            {renderLoads()}
            {
                control.storageConnected ?
                    <>
                        {renderStorageStatsSmall()}
                        {renderTable()}
                    </>
                    : null
            }
            <LxReactLinkedControlsList controlUuid={control.uuidAction} />
        </ScrollView>
        {storageStatsZoomed ? renderStorageStatsZoomed() : null}
    </View>
}
