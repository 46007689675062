'use strict';
/*
 entry The news entry from the NewsHub
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.NEWS_HUB = "NewsHubCell";

    class NewsHubCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(entry) {
                return $('<div class="content__container">' + '   <img class="container__image" src="' + Icon.NEWS_HUB_PLACEHOLDER + '">' + '   <div class="container__text-container">' + '       <div class="text-container__title">' + entry.title + '</div>' + // entry.timestamp is the unix timestamp in seconds, moment.js uses the unix timestamp in milliseconds
                    '       <div class="text-container__date">' + moment(entry.timestamp * 1000).format(DateType.DateText).toUpperCase() + '</div>' + '   <div>' + '</div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.contentPlaceholder.append(NewsHubCell.Template.getTemplate(this.content.entry)); // look for the labels

            this.elements = {
                image: this.contentPlaceholder.find(".container__image")
            };

            if (!this.content.isNew) {
                this.element.addClass("news-hub-cell--read");
            } // Dynamically load the image to be able to display a blank placeholder image in the meantime
            // image is an optional property due to Wordpress limitations, it is possible for the image property to be null


            if (this.content.entry.image) {
                var imageElement = this.elements.image;
                var downloadingImage = new Image();

                downloadingImage.onload = function () {
                    imageElement.attr("src", this.src);
                };

                downloadingImage.src = this.content.entry.image;
            }
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments);
        }

        viewWillDisappear() {
            super.viewWillDisappear(...arguments);
        }

        destroy() {
            super.destroy();
        }

        clickableAllowed() {
            return true;
        }

    }

    GUI.TableViewV2.Cells.NewsHubCell = NewsHubCell;
    return GUI;
}(window.GUI || {});
