'use strict';

define("JalousieCentralControl", ["JalousieControl", "JalousieControlEnums"], function (JalousieControl, JalousieControlEnums) {
    return class JalousieCentralControl extends JalousieControl {
        constructor() {
            super(...arguments);
        }

        getReactControlContentFlags() {
            return {
                showStateIcon: false
            }
        }

        getCentralIcon() {
            return Icon.Jalousie.JALOUSIE;
        }

        // -- Override these methods to set our own potentially combined animationType
        isJalousie() {
            this.details.animation = this._animationType();
            return super.isJalousie(...arguments);
        }

        isCurtain() {
            this.details.animation = this._animationType();
            return super.isCurtain(...arguments);
        }

        isAwning() {
            this.details.animation = this._animationType();
            return super.isAwning(...arguments);
        }

        // -- Override these methods to set our own potentially combined animationType

        /**
         * Returns the animationType of the centralControl. This type depends on its subControls AnimationTypes
         * @returns {*}
         * @private
         */
        _animationType() {
            var animationTypes = [],
                resultType;
            this.forEachSubControl(ctrl => {
                animationTypes.push(ctrl.details.animation);
            });
            Debug.Control.JalousieCentral && console.log(this.name, "_animationType: has " + animationTypes.map(at => {
                return this._translateAnimationType(at);
            }).join(", ")); // Reduce eventually returns one value

            resultType = animationTypes.reduce(function (left, right, idx) {
                // Yes, both animationTypes are equal, we don't need to get a combined one!
                if (idx === 0) {
                    Debug.Control.JalousieCentral && console.log(this.name, "    " + idx + ", " + this._translateAnimationType(left) + " & " + this._translateAnimationType(right) + ", first, use right => " + this._translateAnimationType(right)); // initially the left value (= previous value) doesn't exist, so return right.

                    return right;
                } else if (left === right) {
                    Debug.Control.JalousieCentral && console.log(this.name, "    " + idx + ", " + this._translateAnimationType(left) + " & " + this._translateAnimationType(right) + ", equal => " + this._translateAnimationType(left));
                    return left;
                } else {
                    Debug.Control.JalousieCentral && console.log(this.name, "    " + idx + ", " + this._translateAnimationType(left) + " & " + this._translateAnimationType(right) + ", different, detect!"); // We have different animationTypes, get the combined one if possible!

                    return this._getCombinedAnimationType(left, right);
                }
            }.bind(this), JalousieControlEnums.AnimationType.NOT_SUPPORTED); // Second param is the default value

            Debug.Control.JalousieCentral && console.log(this.name, "result = " + this._translateAnimationType(resultType));
            return resultType;
        }

        /**
         * Combines the animationTypes to a general animation type within the animation Range
         * @param left
         * @param right
         * @returns {*}
         * @private
         */
        _getCombinedAnimationType(left, right) {
            Debug.Control.JalousieCentral && console.log(this.name, "      _getCombinedAnimationType: " + this._translateAnimationType(left) + " & " + this._translateAnimationType(right));

            var combinableAnimationTypes = this._getCombinableAnimationTypes(left);

            var combined = JalousieControlEnums.AnimationType.NOT_SUPPORTED;

            if (combinableAnimationTypes.indexOf(right) !== -1) {
                combined = combinableAnimationTypes[0];
            }

            Debug.Control.JalousieCentral && console.log(this.name, "      _getCombinedAnimationType: " + this._translateAnimationType(left) + " & " + this._translateAnimationType(right) + " => " + this._translateAnimationType(combined));
            return combined;
        }

        /**
         * Returns an array of matching combinable AnimationTypes
         * @param anType
         * @returns {Array}
         * @private
         */
        _getCombinableAnimationTypes(anType) {
            var matchingAnimationType = [];

            switch (anType) {
                case JalousieControlEnums.AnimationType.CURTAIN_BOTH_SIDES:
                case JalousieControlEnums.AnimationType.CURTAIN_LEFT:
                case JalousieControlEnums.AnimationType.CURTAIN_RIGHT:
                    matchingAnimationType = [JalousieControlEnums.AnimationType.CURTAIN_BOTH_SIDES, JalousieControlEnums.AnimationType.CURTAIN_LEFT, JalousieControlEnums.AnimationType.CURTAIN_RIGHT];
                    break;

                case JalousieControlEnums.AnimationType.BLINDS:
                case JalousieControlEnums.AnimationType.SHUTTERS:
                    matchingAnimationType = [JalousieControlEnums.AnimationType.BLINDS, JalousieControlEnums.AnimationType.SHUTTERS, JalousieControlEnums.AnimationType.NOT_SUPPORTED];
                    break;

                case JalousieControlEnums.AnimationType.AWNING:
                    matchingAnimationType = [JalousieControlEnums.AnimationType.AWNING];
                    break;

                default:
                    matchingAnimationType = [JalousieControlEnums.AnimationType.NOT_SUPPORTED];
            }

            Debug.Control.JalousieCentral && console.log(this.name, "        _getCombinableAnimationTypes: " + this._translateAnimationType(anType) + " = " + matchingAnimationType.map(at => {
                return this._translateAnimationType(at);
            }).join(", "));
            return matchingAnimationType;
        }

        _translateAnimationType(type) {
            var result = "-UNKNOWN-" + JSON.stringify(type) + "-";
            Object.keys(JalousieControlEnums.AnimationType).forEach(atKey => {
                if (JalousieControlEnums.AnimationType[atKey] === type) {
                    result = atKey;
                }
            });
            return result;
        }

    };
});
