'use strict';

define(['AudioZoneV2ControlEnums', 'AudioZoneV2DetailedContentBase'], function (AudioZoneV2ControlEnums, AudioZoneV2DetailedContentBase) {
    class ZoneGroupOverviewV2 extends AudioZoneV2DetailedContentBase {
        constructor(details) {
            super(...arguments);
            this.details = details;
            this.control = this.control.audioserverComp.getActiveZoneControl();
            this.stateSet = {};
            this.tableContent = [];
            var zoneControls = ActiveMSComponent.getStructureManager().getControlsByType(ControlType.AUDIO_ZONE_V2),
                availableZoneControls = [];
            zoneControls.forEach(function (zoneControl) {
                if (zoneControl.isFixedGroup) {
                    if (!availableZoneControls.find(function (zone) {
                        return zone.isFixedGroup && zone.isControlPartOfFixedGroup(zoneControl) && zone.details.server === this.control.details.server;
                    }.bind(this)) && (!zoneControl.isStacked || zoneControl.uuidAction === this.control.uuidAction)) {
                        // Don't allow stacking except the current player is stacked
                        availableZoneControls.push(zoneControl);
                    }
                } else {
                    availableZoneControls.push(zoneControl);
                }
            }.bind(this));
            this.availableZones = availableZoneControls.filter(function (zone) {
                return zone.details.server === this.control.details.server;
            }.bind(this));
        }

        viewWillAppear() {
            this._registerForZoneStates();

            return super.viewWillAppear(...arguments);
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        destroy() {
            this._unregisterFromZoneStates();

            super.destroy(...arguments);
        }

        titleBarText() {
            return this.control.getName();
        }

        getURL() {
            return "ZoneGroupOverviewV2";
        }

        setTableContent() {
            if (Object.keys(this.stateSet).length && Object.keys(this.stateSet).length === this.availableZones.length) {
                this.used = {};
                this.usedGrouping = {};
                var groupings = [];
                this.availableZones.forEach(function (zone) {
                    if (!this.usedGrouping[zone.details.playerid]) {
                        if (parseInt(zone.details.playerid) !== this.control.details.playerid && !(this.stateSet[zone.details.playerid] && this._includesSelectedZone(this.control, zone))) {
                            groupings.push(this._getGroupedZonesFor(zone.details.playerid));
                        } else {
                            groupings.unshift(this._getGroupedZonesFor(zone.details.playerid));
                        }
                    }
                }.bind(this));
                this.tableContent = groupings.map(function (group) {
                    return {
                        rows: [{
                            type: GUI.TableViewV2.CellType.Special.ZONE_GROUPING_V2,
                            content: {
                                grouping: group,
                                clickable: true
                            },
                            action: this._showGroupScreen.bind(this, group)
                        }]
                    };
                }.bind(this));
            }

            return super.setTableContent(...arguments);
        }

        _registerForZoneStates() {
            this._unregisterFromZoneStates();

            this.availableZones.forEach(function (zone) {
                this.registrations.push(zone.uuidAction);
                SandboxComponent.registerForStateChangesForUUID(zone.uuidAction, this, function (states) {
                    this._zoneStatesReceived(states, zone.details.playerid);
                }.bind(this));
            }.bind(this));
        }

        _unregisterFromZoneStates() {
            if (this.registrations) {
                // Unregister
                this.registrations.forEach(function (regUuid) {
                    SandboxComponent.unregisterForStateChangesForUUID(regUuid, this);
                }.bind(this));
            }

            this.registrations = [];
        }

        /**
         * Will update the stateSet for this player and also trigger an UI update if critical data has changed.
         * @param states
         * @param playerId
         * @private
         */
        _zoneStatesReceived(states, playerId) {
            var groupingChanged = true; //Detect if these states really did change

            if (this.stateSet.hasOwnProperty(playerId)) {
                groupingChanged = JSON.stringify(this.stateSet[playerId][MusicServerEnum.Event.SYNCED_ZONES]) !== JSON.stringify(states[MusicServerEnum.Event.SYNCED_ZONES]);
            }

            if (groupingChanged) {
                Debug.Control.AudioZone.Group && console.log(this.name, "_zoneStatesReceived: " + playerId);
                this.stateSet[playerId] = cloneObject(states);
                this.reloadTable();
            }
        }

        _includesSelectedZone(srcZone, zone) {
            var includes = false;
            includes |= this.stateSet[zone.details.playerid].syncedzones.find(function (playerObj) {
                return playerObj.playerid === srcZone.details.playerid;
            }.bind(this));
            return !!includes;
        }

        _getGroupedZonesFor(playerId) {
            var grouped;

            if (this.stateSet[playerId].isSynced) {
                grouped = this.stateSet[playerId].syncedzones.map(function (playerIdObj) {
                    this.usedGrouping[playerIdObj.playerid] = playerIdObj;
                    return this.availableZones.find(function (zone) {
                        return zone.details.playerid === playerIdObj.playerid;
                    });
                }.bind(this));
            } else {
                grouped = [this.availableZones.find(function (zone) {
                    return zone.details.playerid === playerId;
                })];
            }

            return grouped.filter(function (group) {
                return !!group;
            });
        }

        _showGroupScreen(group) {
            var def = Q.defer(),
                details = {
                    options: this.availableZones.map(function (zone) {
                        return {
                            title: zone.getName(true),
                            subtitle: zone.groupDetail,
                            clickable: true,
                            zone: zone,
                            selected: group.find(function (control) {
                                return control.uuidAction === zone.uuidAction;
                            }.bind(this))
                        };
                    }.bind(this)),
                    title: group.length > 1 ? _("audio-server.group.edit") : _("media.group.set-up"),
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE,
                    mode: GUI.SelectorScreenMode.CONFIRMED,
                    deferred: def
                };
            NavigationComp.showSelector(details).done(function (result) {
                var currentGroupedIds = this._getGroupedZonesFor(this.control.details.playerid).map(function (zone) {
                        return zone.details.playerid;
                    }),
                    activeGroupIds = currentGroupedIds.concat(group.map(function (zone) {
                        return zone.details.playerid;
                    }).filter(function (playerId) {
                        return currentGroupedIds.indexOf(playerId) < 0;
                    })),
                    selectedPlayerIds = result.map(function (res) {
                        return res.option.zone.details.playerid;
                    }).filter(function (playerId) {
                        return playerId !== this.control.details.playerid;
                    }.bind(this)),
                    playersIdsToUnsync = activeGroupIds.filter(function (zoneId) {
                        return selectedPlayerIds.indexOf(zoneId) === -1;
                    }).filter(function (playerId) {
                        return playerId !== this.control.details.playerid;
                    }.bind(this));

                if (playersIdsToUnsync.length) {
                    this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                        cmd: MusicServerEnum.AudioCommands.UNSYNC + playersIdsToUnsync.join("/")
                    });
                }

                if (selectedPlayerIds.length) {
                    this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                        cmd: MusicServerEnum.AudioCommands.SYNC + selectedPlayerIds.join("/")
                    });
                }
            }.bind(this));
        }

        _getRightSideTitleBarButtons() {
            // Just return an empty Array we don't wat a right button
            return [];
        }

    }

    Controls.AudioZoneV2Control.ZoneGroupOverviewV2 = ZoneGroupOverviewV2;
});
