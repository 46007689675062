'use strict';

define(["./credentialsScreen.jsx"], function (CredentialsComp) {
    return class CredentialsScreen extends GUI.Screen {
        //region Static
        static ReactComp = CredentialsComp.default;
        //endregion Static

        constructor(details) {
            super($("<div />"));
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.setUpComfortUI(true);
        }

        getAnimation() {
            return AnimationType.FADE;
        }

        getURL() {
            return "Credentials";
        }
    };
});
