'use strict';
/**
 * This view is shown when there is no connection to a miniserver. Don't confuse it for the miniserverSelectionScreen,
 * which is shown while the connection to an active miniserver is still alive.
 */

define([ "IconLib", "./archiveScreen.jsx" ], function (Icons, ArchiveComp) {
    return class ArchiveScreen extends GUI.Screen {

        static ReactComp = ArchiveComp.default;

        constructor() {
            super($('<div />'));
        }

        getAnimation() {
            return AnimationType.NONE;
        }

        getLeftIcon() {
            return Icon.Buttons.PLACE
        }

        titleBarAction() {
           this.ViewController.showState(ScreenState.Menu)
        }
    };
});
