'use strict';

define([], function () {
    return {
        JalousieMode: {
            LOCKED: -3,
            SAFETY: -2,
            AUTO_NOT_ALLOWED: -1,
            AUTO_INACTIVE: 0,
            AUTO_ACTIVE: 1
        },
        AnimationType: {
            BLINDS: 0,
            SHUTTERS: 1,
            CURTAIN_BOTH_SIDES: 2,
            NOT_SUPPORTED: 3,
            // Schlotterer Retrolux in automatic jalousie
            CURTAIN_LEFT: 4,
            CURTAIN_RIGHT: 5,
            AWNING: 6 // Markise

        },
        SlatsOrientation: {
            HORIZONTAL: 0,
            VERTICAL: 1,
            BETWEEN: 2 // somewhere inbetween.

        },
        ScreenState: {
            CONTROL_CONTENT: 'Controls.JalousieControl.JalousieControlContent',
            // Config stuff
            CONFIG_DIALOG: 'JalousieControlContentConfigDialog',
            INFO: 'JalousieControlContentConfigInfoScreen',
            WAITING: 'JalousieControlContentConfigWaitingScreen',
            ADJUST: 'JalousieControlContentConfigAdjustScreen',
            SETTINGS: 'JalousieControlContentConfigSettingsScreen',
            // End Position Adjustment
            ADJUST_END_POS: "JalousieEndPositionAdjustmentScreen"
        },
        InfoScreenTypes: {
            INFO: 0,
            SUCCESS: 1,
            START_SLAT_ADJUSTMENT: 2
        },
        ConfigModes: {
            FINISHED: 0,
            // finished, ready to learn again!
            READY: 1,
            // service mode is started/ready
            SET_END_POSITION: 19,
            // set end position
            CORRECT_END_POSITION: 21,
            // adjust end position
            MOVE_END_POSITION: 22,
            // move end position (not used right now)
            ADJUST_SLATS: 23 // adjust slats (only jalousie!)

        },
        DeviceState: {
            // available since 11.3
            UNKNOWN: -1,
            // internal state for MS prior to 11.3
            NO_DEVICE: 0,
            // means that an there is no integrated device in use
            OFFLINE: 1,
            // either not configured or offline
            ONLINE: 2 // online and ready to go.

        },
        ConfigSteps: {
            END_POSITION: {
                BOTTOM: 0,
                TOP: 1
            },
            SLAT_ADJUSTMENT: {
                MOVING_DOWN: 0,
                // Automatic
                USER_UP: 1,
                MOVING_UP: 2,
                // Automatic
                USER_DOWN: 3
            },
            ERROR: {
                LOCK_TIMEOUT: 12,
                LEARN_TIMEOUT: 13 // hasEndposition == true ? navigate to settings screen : info screen (type info)

            }
        },
        SAFETY_STATES: {
            NONE: 0,
            STOP: 1,
            DOOR_OPENED: 2
        },
        EndPosAdjustBit: {
            OFF: 0,
            DOWN: 1,
            UP: 2
        },
        StateId: {
            ADJUSTING_END_POS: "adjustingEndPos"
        }
    };
});
