import {useEffect, useMemo} from "react";
import {
    LxReactDeviceSearchAssistant,
    LxReactDeviceSearchEnums,
    useDeviceSearchResults,
    LxReactWaitingView,
    SearchableTable,
    useBackNavigation
} from "LxComponents";
import {View} from "react-native";
import globalStyles from "GlobalStyles";
import useTrackRouteInSearchContext from "./useTrackRouteInSearchContext";
import LxReactDeviceSearchBadge from "./LxReactDeviceSearchBadge";
import LxReactDeviceSearchIcon from "./LxReactDeviceSearchIcon";
import useDeviceSearchTitleBar from "./useDeviceSearchTitleBar";
import LxReactDeviceSearchStoppedView from "./LxReactDeviceSearchStoppedView";
import LxReactDeviceSearchOngoingSearchView from "./LxReactDeviceSearchOngoingSearchView";
import LxReactDeviceSearchRefresh from "./LxReactDeviceSearchRefresh";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {useConnectionReady} from "../Components";

export default function LxReactDeviceSearchResults({route, navigation}) {
    const extension = route.params.extension;
    const branch = route.params.branch;
    const miniserver = route.params.miniserver;
    const searchType = route.params.searchType;
    const searchId = route.params.searchId;

    useTrackRouteInSearchContext(LxReactDeviceSearchResults.name, route);
    useDeviceSearchTitleBar(navigation, route);
    const connectionReady = useConnectionReady(true);

    useBackNavigation(() => {
        navigation.goBack();
    })

    const { searchResults, isSearching, wasKicked, isRestarting } = useDeviceSearchResults(searchType, extension, searchId, branch, miniserver);

    const badgeForCount = (count) => {
        return {
            comp: LxReactDeviceSearchBadge,
            props: { deviceCount: count }
        }
    }

    const renderOnlineView = (icon, hasOnlineState = false, isOnline = false, isTotal = false) => {
        return {
            comp: LxReactDeviceSearchIcon,
            props: {
                icon,
                hasOnlineState,
                isOnline,
                isTotal
            }
        }
    }

    const createDeviceTypeRow = (deviceType, deviceList) => {
        if (!deviceList || deviceList.length === 0) {
            return;
        }
        let firstDevice = deviceList[0];
        return {
            title: firstDevice.name || (searchType + " device").debugify(),
            disabled: !connectionReady,
            onPress: () => openPairingAssistant(deviceType, firstDevice.name),
            mainLeftContent: renderOnlineView(ActiveMSComponent.getImageUrlWithDeviceType(firstDevice.type) ?? ' ', firstDevice.hasOwnProperty("deviceState"), firstDevice.deviceState),
            mainRightContent: badgeForCount(deviceList.length)
        }
    }

    const searchingView = useMemo(() => {
        return <LxReactWaitingView title={_("device-learning.searching.title")} message={_("device-learning.searching.message")}/>
    }, [searchResults.length > 0]);

    const openPairingAssistant = (deviceType, friendlyName) => {
        Debug.DeviceSearch.Results && console.log(LxReactDeviceSearchResults.name, "openPairingAssistant: deviceTypeFilter=" + deviceType);
        navigation.push(LxReactDeviceSearchAssistant.name, {
            ...route.params,
            deviceTypeFilter: deviceType,
            deviceTypeName: friendlyName,
            searchState: LxReactDeviceSearchEnums.SearchState.ASSISTANT
        });
    }

    const renderHeader = () => {
        return <View style={globalStyles.customStyles.sectionHeader} />
    }

    const tableContent = useMemo(() => {
        let content = [];
        let deviceTypeMap = {};
        searchResults.forEach((device) => {
            deviceTypeMap[device.type] = deviceTypeMap[device.type] || [];
            deviceTypeMap[device.type].push(device);
        })
        let deviceRows = Object.keys(deviceTypeMap).map((devType) => {
            return createDeviceTypeRow(devType, deviceTypeMap[devType]);
        });

        content.push({
            rows: [{
                title: _("device-learning.total-found-devices"),
                onPress: () => openPairingAssistant(),
                disabled: !connectionReady,
                mainLeftContent: renderOnlineView(null, false, false, true),
                mainRightContent: badgeForCount(searchResults.length)
            }]
        })
        deviceRows.length > 0 && content.push({ headerElement: renderHeader(), rows: deviceRows});

        Debug.DeviceSearch.Results && console.log(LxReactDeviceSearchResults.name, "useMemo=>tableContent = ", content);


        return content;
    }, [JSON.stringify(searchResults).hashCode(), connectionReady]);

    const insets = useSafeAreaInsets();

    const renderedContent = () => {
        if (isSearching) {
            if (searchResults && searchResults.length > 0) {
                return <View style={Styles.resultCntr}>
                    <SearchableTable containerStyle={Styles.resultTable} tableContent={tableContent} />
                    <LxReactDeviceSearchRefresh style={Styles.refreshArea} searchType={searchType} resultsShown={true} />
                </View>;
            } else {
                return <View style={Styles.resultCntr}>
                    <LxReactDeviceSearchOngoingSearchView style={Styles.searchingView} searchType={searchType} />
                    <LxReactDeviceSearchRefresh style={Styles.refreshArea} searchType={searchType} resultsShown={true} />
                </View>;
            }
        } else {
            return <LxReactDeviceSearchStoppedView searchType={searchType} wasKicked={wasKicked} isRestarting={isRestarting}/>
        }
    }
    return <View style={[Styles.rootCntr, { marginBottom: insets.bottom }]}>
        {renderedContent()}
    </View>

}

const Styles = {
    rootCntr: {
        flex: 1,
        height: "100%",
    },
    resultCntr: {
        flex: 1,
        flexDirection: "column"
    },
    resultTable: {
        flex: 1
    },
    searchingView: {
        flex: 1
    },
    refreshArea: {
        alignSelf: "flex-end"
    }
}
