import {DeviceStates, ScreenStates} from "./steakControlEnums";
import {getSubmenuCell} from "../../UtilityComp/utilities/helper";

export default class SteakControlStatesSrc {
    static getStateSections(control, states) {
        let sections = [];
        if (!states.isActive || states.deviceState === DeviceStates.OFFLINE || states.deviceState === DeviceStates.SLEEPING) {
            return [];
        }

        let rows = [],
            orderedSensors = this._getOrderedSensors(states); // Connected sensors


        orderedSensors.connected.forEach((sensor) => {
            rows.push(this._getSensorCell(control, states, sensor));
        }); // Timer

        rows.push(this._getTimerCell(control, states)); // Disconnected sensors

        orderedSensors.disconnected.forEach((sensor) => {
            rows.push(this._getSensorCell(control, states, sensor));
        }); // Every SteakControl has a Tracker, show it!

        rows.push(this.getSubmenuCell(_('steak.tracker.last'), this._showTracker.bind(this, control))); // STATISTIC

        if (!!control.statistic) {
            this.getStatisticCells(control, states).forEach((statCell) => {
                rows.push(statCell);
            });
        }

        sections.push({ rows })

        return sections;
    }

    static shouldDisplayStateIcons(states) {
        return !states.isActive || states.deviceState === DeviceStates.OFFLINE || states.deviceState === DeviceStates.SLEEPING;
    }

    static getSubmenuCell(title, action, iconSrc, disclosureText) {
        let cell = getSubmenuCell(...arguments);

        if (disclosureText !== null) {
            cell.content.disclosureText = disclosureText;
        }

        return cell;
    }

    static getStatisticCells(control, states) {
        let orderedSensors = this._getOrderedSensors(states),
            statisticIdx = 0;

        if (orderedSensors.connected.length > 0) {
            statisticIdx = orderedSensors.connected[0].idx;
        }

        return [this.getSubmenuCell(_("statistics"), this.showStatisticForOutput.bind(this, control, statisticIdx))];
    }

    // region Private Methods
    /**
     * Returns an object with the sensors ordered in the correct category (connected and disconnected)
     * @param states
     * @return {{connected: Array, disconnected: Array}}
     * @private
     */
    static _getOrderedSensors(states) {
        let orderedSensors = {
            connected: [],
            disconnected: []
        };
        states.sensors.forEach(function (sensor, idx) {
            if (sensor.connected) {
                orderedSensors.connected.push(sensor);
            } else {
                orderedSensors.disconnected.push(sensor);
            }
        });
        return orderedSensors;
    }

    static _getSensorCell(control, states, sensor) {
        if (sensor.connected) {
            return {
                content: {
                    sensor: sensor,
                    sensorAlarmText: states.sensorAlarmTexts[sensor.idx],
                    sensorColor: control.getColorForSensorAtIndex(sensor.idx, states),
                    currentTemp: states.currentTemperatures[sensor.idx],
                    clickable: true
                },
                type: GUI.TableViewV2.CellType.Special.STEAK_SENSOR_CELL,
                action: this._didSelectSensorCell.bind(this, control, states, sensor)
            };
        } else {
            return {
                content: {
                    title: _("steak.sensor.not-connected", {
                        sensorName: sensor.name
                    }),
                    leftIconSrc: Icon.CIRCLE,
                    leftIconColor: control.getColorForSensorAtIndex(sensor.idx, states),
                    clickable: true
                },
                action: this._didSelectSensorCell.bind(this, control, states, sensor)
            };
        }
    }

    static _getTimerCell(control, states) {
        return {
            content: {
                timerInfo: states.timerInfo,
                timerAlarm: states.timerAlarm,
                remainingTime: states.timerRemaining,
                uuidAction: control.uuidAction
            },
            type: GUI.TableViewV2.CellType.Special.STEAK_TIMER_CELL,
            action: this._didSelectTimerCell.bind(this, control, states)
        };
    }

    /**
     * Shows the tracker of the control
     * @private
     */
    static _showTracker(control) {
        let subControls = control.subControls,
            trackerUUID = Object.keys(subControls)[0],
            trackerControl = control.subControls[trackerUUID];
        NavigationComp.showControlContent(trackerControl);
    }

    /**
     * Handles the selection of a sensorCell
     * Default behaviour: Open the statistics of the selected sensor
     * If an alarm is active for the specific sensor the alarm will be acknowledged
     * @param sensor
     * @param control
     * @param states
     * @private
     */
    static _didSelectSensorCell(control, states, sensor) {
        if (states.sensorAlarmTexts[sensor.idx].ringing) {
            control.sendCommand(Commands.Steak.QUIT_ALARM);
        } else {
            this.showStatisticForOutput(control, sensor.idx);
        }
    }

    /**
     * Handles the selection of the timerCell
     * Default behaviour: Open the time selector screen
     * If an timer is active and its remaining time is 0 the timer will be stopped
     * @private
     */
    static _didSelectTimerCell(control, states) {
        if (states && states.timerRemaining === 0 && states.timerInfo.active) {
            this.onTimerStop(control, states);
        } else {
            this.onTimerSetSelected(states);
        }
    }

    static onTimerStop(control, states) {
        if (states.timerRemaining === 0 && states && states.timerInfo.active) {
            control.sendCommand(Commands.Steak.QUIT_ALARM);
        } else {
            control.sendCommand(Commands.Steak.STOP_TIMER);
        }
    }

    // We can only allow the user to set the time if no timer is active
    static onTimerSetSelected(states) {
        if (!states.timerInfo.active) {
            NavigationComp.showState(ScreenStates.TimeSelectorScreen, {
                duration: states.timerInfo.duration
            });
        }
    }

    /**
     * Will present the statistic for the output provided.
     * @param nr    the output number, 0 if not provided.
     * @param control
     */
    static showStatisticForOutput(control, nr) {
        if (control.statistic && control.statistic.outputs && control.statistic.outputs[nr]) {
            nr = nr ? nr : 0;
            NavigationComp.showStatistic(control.uuidAction, control.statistic.outputs[nr].uuid);
        }
    }


    // endregion
}