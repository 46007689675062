'use strict';

define([], function () {
    return class LicensesScreen extends GUI.TableViewScreenV2 {

        get useNewTableView() {
            return true;
        }

        constructor() {
            super($("<div/>"));
        }
        viewDidLoad() {
            this.tableContent = [
                {
                    rows: Licences.map(pkg => {
                        return {
                            content: {
                                title: pkg.name,
                                subtitle: pkg.author,
                                disclosureText: pkg.license,
                                disclosureIcon: true,
                                clickable: true
                            },
                            action: () => {
                                this.ViewController.showState(ScreenState.LicenseDetail, null, pkg)
                            }
                        }
                    })
                }
            ]
            return super.viewDidLoad(...arguments);
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarText() {
            return _("app.open-source-licences");
        }

    };
});
