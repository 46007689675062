import React, { useEffect, useState, useRef } from 'react';

import { View, TouchableOpacity, TouchableWithoutFeedback } from 'react-native';
import LxReactText from '../LxReactText';
import { useHeaderHeight } from "@react-navigation/elements";
import { Portal } from 'react-native-portalize';
import globalStyles from "GlobalStyles";
import { useCallback } from 'react';
import DraggableScrollView from '../DraggableScrollView'

let scrollTimeout = null;

const singleItemHeight = 34;

const isDisabled = (data, selectedValues, datasetIdx, idx) => {
    const mappedSelectedValues = selectedValues.map((selectedIdx, selectedDatasetIdx) => {
        return data[selectedDatasetIdx].dataset[selectedIdx];
    })
    return mappedSelectedValues.some((value, mappedDatasetIdx) => {
        if (mappedDatasetIdx === datasetIdx) { return false }
        if (!Array.isArray(value?.excludes)) { return false }
        return value?.excludes.some(excludeSet => {
            return excludeSet[0] === datasetIdx && excludeSet[1] === idx;
        })
    })
}

/* #region  SingleScroller */
const SingleScroller = ({ data, dataset, selectedIdx, datasetIdx, selectedValues, setSelectedValues }) => {
    const scrollRef = useRef(null);
    const [hasSetInitialPosition, setHasSetInitialPosition] = useState(false);

    useEffect(() => {
        setHasSetInitialPosition(false);
    }, [selectedIdx])

    useEffect(() => {
        if (scrollRef && scrollRef.current && !hasSetInitialPosition) {
            scrollRef.current.scrollTop = selectedIdx * singleItemHeight;
            setHasSetInitialPosition(true);
        }
    }, [scrollRef, selectedIdx]);

    const isRightScroller = datasetIdx === (data.length - 1) && data.length > 1
    const isLeftScroller = datasetIdx === 0 && data.length > 1;


    const getTextAlign = () => {
        if (isLeftScroller) {
            return "left";
        } else if (isRightScroller) {
            return "right"
        } else {
            return "center"
        }
    };

    return (
        <DraggableScrollView
            style={{flexGrow: 1, flexShrink: isLeftScroller ? 1 : 0, overflowY: 'scroll', scrollSnapType: 'y mandatory'}}
            contentContainerStyle={{width: "100%", paddingTop: 88, paddingBottom: 88, paddingLeft: isLeftScroller ? 40 : 0, paddingRight: isRightScroller ? 40 : 8 }}
            ref={scrollRef}
            onScroll={(args) => {
                let scrollTop = scrollRef.current?.scrollTop;
                clearTimeout(scrollTimeout);
                scrollTimeout = setTimeout(() => {
                    const selectedIdx = Math.round((scrollTop - 17) / singleItemHeight);

                    const newValues = [].concat(selectedValues);
                    newValues[datasetIdx] = selectedIdx;

                    if (newValues.some((selectedIdx, idx) => {
                        return selectedIdx !== selectedValues[idx];
                    })) {
                        setSelectedValues([].concat(newValues))
                    }
                }, 150)
            }}
        >
            {
                dataset.map((selectable, selectableIdx) => (
                    !isDisabled(data, selectedValues, datasetIdx, selectableIdx) &&
                    <TouchableWithoutFeedback onPress={() => {if(scrollRef && scrollRef.current){scrollRef.current.scrollTop = selectableIdx * singleItemHeight}}} style={{ opacity: isDisabled(data, selectedValues, datasetIdx, selectableIdx) ? 0.25 : 1, height: singleItemHeight, width: 'auto', scrollSnapAlign: 'center', alignItems: 'flex-start', justifyContent: 'center', display: 'inline-block', minWidth: 150 }}>
                        <LxReactText style={{
                            color: 'rgba(235, 235, 245, 0.6)',
                            fontSize: 23,
                            fontFamily: globalStyles.fontSettings.families[selectedValues[datasetIdx] === selectableIdx ? 'bold' : 'regular'],
                            letterSpacing: 2,
                            lineHeight: 30,
                            minHeight: singleItemHeight,
                            maxHeight: singleItemHeight,
                            scrollSnapAlign: 'center',
                            maxWidth: "100%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textAlign: getTextAlign() }}>{selectable.label}</LxReactText>
                    </TouchableWithoutFeedback>
                ))
            }
        </DraggableScrollView>
    )
}
/* #endregion */

export default function WheelPicker({ onSelect, data }) {
    const headerHeight = useHeaderHeight();

    const [selectedValues, setSelectedValues] = useState([]);

    const onFinished = useCallback(() => {
        const selectedData = data.map(({ dataset }, datasetIdx) =>
            ({ value: dataset[selectedValues[datasetIdx]], idx: selectedValues[datasetIdx] })
        )

        if (selectedData.every((obj, datasetIdx) => {
            if (!obj || !obj.value) { return false }
            return !isDisabled(data, selectedValues, datasetIdx, obj.idx);
        })) {
            typeof onSelect === 'function' && onSelect(selectedData);
        }
    }, [data, selectedValues, onSelect]);

    useEffect(() => {
        if(data.every((value) => typeof value.selectedIdx === 'number')) {
            setSelectedValues(data.map((value) => value.selectedIdx))
        } else {
            setSelectedValues(data.map(() => 0))
        }
    }, [data.map((value) => value.selectedIdx).join('-')]);

    return (
        <Portal>
            <TouchableOpacity
                style={{ position: 'absolute', left: 0, top: 0, height: '100%', width: '100%', background: 'rgba(0,0,0,0.6)' }}
                onPress={onFinished}
            />
            <View
                style={{
                    width: 'calc(100% - 15px)',
                    maxWidth: 360,
                    marginLeft: 'auto', marginRight: 'auto',
                    paddingTop: 42,
                    backgroundColor: '#1c1c1e',
                    borderRadius: 16,
                    position: 'relative',
                    top: headerHeight + 120,
                }}
            >
                <View
                    style={{
                        height: 240,
                        padding: 16,
                        position: 'relative',
                    }}
                >
                    <View style={{
                        width: 'calc(100% - 32px)',
                        height: singleItemHeight,
                        backgroundColor: 'rgba(116, 116, 128, 0.18)',
                        position: 'absolute', left: 16, top: 'calc(50% - 17px)',
                        borderRadius: 8,
                    }} />
                    <View style={{
                        flex: 1,
                        justifyContent: "space-between",
                        flexDirection: 'row',
                    }}>
                        {
                            data.map(({ dataset, selectedIdx }, datasetIdx) => {
                                return (
                                    <SingleScroller
                                        key={`${datasetIdx}-wheely`}
                                        data={data}
                                        dataset={dataset}
                                        selectedIdx={selectedIdx}
                                        datasetIdx={datasetIdx}
                                        selectedValues={selectedValues}
                                        setSelectedValues={setSelectedValues}
                                    />
                                )
                            })
                        }
                    </View>
                    <View style={{
                        width: 'calc(100% - 32px)',
                        height: singleItemHeight,
                        background: 'linear-gradient(180deg, rgba(28,28,30,1) 0%, rgba(28,28,30,0) 100%)',
                        position: 'absolute', left: 16, top: 16,
                    }} />
                    <View style={{
                        width: 'calc(100% - 32px)',
                        height: singleItemHeight,
                        background: 'linear-gradient(0deg, rgba(28,28,30,1) 0%, rgba(28,28,30,0) 100%)',
                        position: 'absolute', left: 16, bottom: 16,
                    }} />
                </View>
                <TouchableOpacity
                    style={{
                        position: 'absolute',
                        right: 16,
                        top: 24,
                    }}
                    onPress={onFinished}
                >
                    <LxReactText style={{ fontSize: 20, color: globalStyles.colors.green, fontFamily: globalStyles.fontSettings.families.bold }}>{_('done')}</LxReactText>
                </TouchableOpacity>
            </View>
        </Portal>
    )
}
