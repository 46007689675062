'use strict';


define(["LxComponents"], function ({ App }) {
    return class HomeKitUsersScreen extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            this._users = details.users;
        }

        viewDidLoad() {
            this._currentSelection = ActiveMSComponent.getCurrentUser().uuid;

            this._reorderUsers();

            return super.viewDidLoad(...arguments).then(function () {
                this.continueButtonElem = $("<div class='continue-button'>" + _('home-kit.continue') + "</div>");
                this.continueButton = new GUI.LxButton(this, this.continueButtonElem);
                this.insertSubviewAfter(this.continueButton, this.tableView.getElement());

                this.continueButton.onButtonTapped = function () {
                    this._setHomeKitUser(this._currentSelection);
                }.bind(this);
            }.bind(this));
        }

        getAnimation() {
            return AnimationType.NONE;
        }

        closeAction() {
            this.ViewController.dismiss();
        }

        titleBarText() {
            return _("home-kit.users.title");
        }

        styleForTableView() {
            return TableViewStyle.COMFORT_MODE_2020;
        }

        reloadTable() {
            var args = arguments;

            var descriptionSection = {
                    footerTitle: _("home-kit.users.hint-1") + "<br><br>" + _("home-kit.users.hint-2"),
                    rows: []
                },
                usersSection = this._createUsersSection(),
                addUserSection = this._createAddUserSection();

            this.tableContent = [descriptionSection, usersSection, addUserSection];
            return super.reloadTable(...args);
        }

        _reorderUsers() {
            var currentUserIndex;

            this._users.forEach(function (user, index) {
                if (user.uuid === this._currentSelection) {
                    currentUserIndex = index;
                    return;
                }
            }.bind(this));

            if (currentUserIndex) {
                var currentUserObject = this._users.splice(currentUserIndex, 1);

                this._users = currentUserObject.concat(this._users);
            }
        }

        _createUsersSection() {
            var usersSection;

            if (this._users && this._users.length > 0) {
                usersSection = {
                    headerTitle: _("user"),
                    rows: []
                };

                this._users.forEach(function (user) {
                    usersSection.rows.push({
                        content: {
                            title: user.name,
                            userObject: user,
                            radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE,
                            selected: user.uuid === this._currentSelection
                        },
                        type: GUI.TableViewV2.CellType.CHECKABLE,
                        didCheckCell: function (value) {
                            this._currentSelection = value.content.userObject.uuid;
                        }.bind(this)
                    });
                }.bind(this));
            }

            return usersSection;
        }

        _createAddUserPopupContent(retry, user) {
            var details = {
                title: _("user.new.title"),
                input: {
                    id: "userHomeName",
                    required: true,
                    placeholder: _("user"),
                    type: GUI.LxInputEnum.TEXT,
                    validationRegex: Regex.USERNAME,
                    filterRegex: Regex.USERNAME_FILTER
                },
                message: retry ? _('user.exists', {
                    username: user
                }) : _("user.new.message"),
                buttonOk: _("home-kit.home.popup.create"),
                buttonCancel: _("home-kit.home.popup.cancel")
            };
            retry && (details.messageColor = window.Styles.colors.red);
            return details;
        }

        _showAddUserPopupAction() {
            this._createUserPopup = NavigationComp.showPopup(this._createAddUserPopupContent(), PopupType.INPUT);

            this._createUserPopup.then(this._createUserAndShowDetails.bind(this));
        }

        _createAddUserSection() {
            var addUserSection = {
                rows: []
            };
            addUserSection.rows.push({
                content: {
                    title: _("home-kit.users.add"),
                    clickable: true
                },
                type: GUI.TableViewV2.CellType.GENERAL,
                action: this._showAddUserPopupAction.bind(this)
            });
            return addUserSection;
        }

        _createUserAndShowDetails(popupResult) {
            var user = {
                    name: popupResult.result
                },
                addUser;
            addUser = this._users.find(function (user) {
                return user.name === popupResult.result;
            });

            if (!addUser) {
                ActiveMSComponent.addOrEditUser(user).then(function (user) {
                    var userDetailDefer = Q.defer();
                    this._addedUser = JSON.parse(cloneObjectDeep(user.LL.value));
                    this.ViewController.setPreventReset(true);
                    App.navigationRef.navigate(ScreenState.UserDetailsScreen, {
                        user: this._addedUser,
                        isNewUser: true,
                        homeKitDefer: userDetailDefer
                    });
                    userDetailDefer.promise.then(function () {
                        this._handleUserDetail(true);
                    }.bind(this), function () {
                        this._handleUserDetail(false);
                    }.bind(this));
                }.bind(this));
            } else {
                this._showRetryPopup(addUser.name);
            }
        }

        _handleUserDetail(navigateOn) {
            this.ViewController.setPreventReset(false);
            if (this._addedUser) {
                return this.ViewController.getHomeKitUsers(true).then(function (users) {
                    var wasAdded = false;
                    this._users = users;
                    users.forEach(function (user) {
                        if (user.uuid === this._addedUser.uuid) {
                            wasAdded = true;
                            this._currentSelection = this._addedUser.uuid;

                            this._reorderUsers();

                            return this.reloadTable();
                        }
                    }.bind(this));

                    if (wasAdded && navigateOn) {
                        this._setHomeKitUser(this._addedUser.uuid);

                        this._addedUser = undefined;
                    }
                }.bind(this));
            }
        }

        _showRetryPopup(user) {
            return NavigationComp.showPopup(this._createAddUserPopupContent(true, user), PopupType.INPUT).then(function (popupResult) {
                this._createUserAndShowDetails(popupResult);
            }.bind(this));
        }

        _setHomeKitUser(selected) {
            Debug.HomeKit && console.log(this.viewId, "_setHomeKitUser: " + selected);
            var callbackDefer = Q.defer(),
                waitingPromise = ActiveMSComponent.setHomeKitUser(selected).then(function () {
                    // ensure the addon is up and running again, otherwise getMiniserverHomeName may fail, as the MS isn't
                    // reachable via HomeKit
                    return ActiveMSComponent.getHomeKitAddonStatus().then(function () {
                        Debug.HomeKit && console.log(this.viewId, "_setHomeKitUser: homekit status is up and running!");
                        return ActiveMSComponent.getMiniserverHomeName().then(function (homeName) {
                            Debug.HomeKit && console.log(this.viewId, "_setHomeKitUser: home name received! " + homeName);
                            return ActiveMSComponent.getHomeKitAccessoriesList(homeName, !!selected).then(function (accessories) {
                                return Q({
                                    accessories: accessories,
                                    homeName: homeName,
                                    apiUserUuid: selected
                                });
                            }.bind(this));
                        }.bind(this));
                    }.bind(this));
                }.bind(this));
            this.ViewController.showState(ScreenState.WaitingScreen, null, {
                message: _("home-kit.loading.message.functions"),
                cancelButtonText: _("home-kit.loading.cancel"),
                callbackDefer: callbackDefer,
                waitingPromise: waitingPromise
            });
            callbackDefer.promise.then(function (details) {
                Debug.HomeKit && console.log(this.viewId, "_setHomeKitUser > success " + details);
                this.ViewController.showState(ScreenState.HomeKitAccessories, null, details);
            }.bind(this), function (error) {
                console.error(this.viewId, "_setHomeKitUser > failed " + JSON.stringify(error));
                this.ViewController.handleError(error, _("home-kit.error.get-visible-accessories"));
            }.bind(this));
        }

    }

})
