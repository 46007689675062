import * as React from "react"
import Svg, { Path } from "react-native-svg"

function CircuitBreaker(props) {
    return (
        <Svg
            isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M13 1a1 1 0 10-2 0v3.17A3.001 3.001 0 009 7v5c0 1.306.835 2.418 2 2.83V19H5a1 1 0 00-1 1v3a1 1 0 102 0v-2h12v2a1 1 0 102 0v-3a1 1 0 00-1-1h-6v-4.17A3.001 3.001 0 0015 12V7a3.001 3.001 0 00-2-2.83V1zm-1 5a1 1 0 011 1v5a1 1 0 11-2 0V7a1 1 0 011-1z"
            />
        </Svg>
    )
}

export default CircuitBreaker
