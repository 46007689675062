'use strict';
/*
 valueSelection     this object is being passed on to the lxValueSelectionView used in this cell.
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.CHECKABLE_VALUE_SELECTION = "CheckableValueSelectionCell";

    class CheckableValueSelectionCell extends GUI.TableViewV2.Cells.CheckableCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.valueSelection = new GUI.LxValueSelectionView(this.content.valueSelection);
                this.valueSelection.getElement().addClass("cell__value-selection");
                this.appendSubview(this.valueSelection);
            }.bind(this));
        }

        setContent(content) {
            super.setContent(...arguments);
            this.valueSelection && this.valueSelection.setDetails(this.content.valueSelection);
        }

    }

    GUI.TableViewV2.Cells.CheckableValueSelectionCell = CheckableValueSelectionCell;
    return GUI;
}(window.GUI || {});
