import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Alexa(props) {
    return (
        <Svg isreact="true"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                clipRule="evenodd"
                d="M12 0C5.373 0 0 5.373 0 12c0 6.085 4.53 11.111 10.4 11.894v-2.418a1.59 1.59 0 00-1.076-1.512 8.403 8.403 0 01-5.722-8.166A8.37 8.37 0 0112.087 3.6a8.4 8.4 0 018.307 8.714l-.003.07c-.004.096-.01.193-.018.289l-.008.091a7.622 7.622 0 01-.05.425c-.004.034-.008.068-.014.102-1.028 6.774-9.851 10.582-9.9 10.603.523.07 1.057.106 1.599.106 6.627 0 12-5.372 12-12 0-6.627-5.373-12-12-12z"
            />
        </Svg>
    )
}

export default Alexa
