import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Weather00n(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            <Path
                d="M16.056 5a12.172 12.172 0 00-2.904.335A11.028 11.028 0 1027 17.395a7.119 7.119 0 01-2.68.448 8.245 8.245 0 01-8.264-8.264 7.546 7.546 0 011.34-4.467A5.437 5.437 0 0016.056 5z"
                fill="#9cb2bc"
            />
            <Path
                d="M27.13 8.714L28 9l-.87.286-.463 1.214-.463-1.214-.87-.286.87-.286.463-1.214zM21.866 6l.647 1.619 1.22.381-1.22.381L21.867 10l-.648-1.619L20 8l1.219-.381zM24 10l.648 1.619 1.219.381-1.22.381L24 14l-.648-1.619L22.133 12l1.22-.381z"
                fill="#f4cd27"
                fillRule="evenodd"
            />
            <Path fill="none" d="M0 0H32V32H0z" />
        </Svg>
    )
}

export default Weather00n
