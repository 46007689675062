'use strict';

define(["AudioZoneV2ControlEnums"], function (AudioZoneV2ControlEnums) {
    class EditMediaScreen extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            applyMixins(this, MediaStateHandlerV2.Mixin);
            this.delegate = details.delegate;
            this.control = this.delegate.control;
            this.contentType = details.contentType;
            this.items = [];

            this[this.__getFunctionNameForEventTypeWithTemplate(this.contentType, this.DYNAMIC_FUNCTION_TPL.ALL_DATA_RECEIVED)] = function (data) {
                return this.onAllContentTypeData(data);
            }.bind(this);

            this[this.__getFunctionNameForEventTypeWithTemplate(this.contentType, this.DYNAMIC_FUNCTION_TPL.CHUNKED_DATA_DATA)] = function (data) {
                return this.onContentTypeDataChunk(data);
            }.bind(this);

            this[this.__getFunctionNameForEventTypeWithTemplate(this.contentType, this.DYNAMIC_FUNCTION_TPL.ERROR)] = function () {
                return this.onContentTypeError();
            }.bind(this);

            this.receivedContent = false;
            this.hasError = false;
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                this._requestEventContent(this.contentType);
            }.bind(this));
        }

        getTableView() {
            return new GUI.EditableTableView(this.tableViewDataSource, this.tableViewDelegate);
        }

        emptyViewConfig() {
            return {
                iconSrc: this.getEmptyViewIconSrc(),
                title: this.getEmptyViewTitle(),
                message: this.getEmptyViewMessage(),
                buttonText: this.getEmptyViewButtonText(),
                buttonAction: this.emptyViewButtonAction.bind(this),
                noCircle: true
            };
        }

        getEmptyViewIconSrc() {
            if (this.hasError) {
                return Icon.ERROR;
            } else {
                return this.receivedContent ? Icon.ERROR : Icon.INDICATOR;
            }
        }

        getEmptyViewTitle() {
            if (this.hasError) {
                return _("error");
            } else {
                return this.receivedContent ? _('controls.tracker.no-entries') : _("loading");
            }
        }

        getEmptyViewMessage() {
            if (this.hasError) {
                return _("error.occured");
            } else {
                return null;
            }
        }

        getEmptyViewButtonText() {
            if (this.hasError) {
                return _("try-again");
            }

            return null;
        }

        emptyViewButtonAction() {
            if (this.hasError) {
                this._requestEventContent(this.contentType);
            } // Do nothing...

        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        onAllContentTypeData(data) {
            this.ViewController.startEditMode();
        }

        onContentTypeDataChunk(data) {
            this.receivedContent = true;
            this.items = this.items.concat(data.items);
            this.updateEmptyViewConfig();
            return this.reloadTable();
        }

        onContentTypeError() {
            this.hasError = true;
            this.updateEmptyViewConfig();
        }

        setTableContent() {
            this.tableContent = this.getTableContent();
            return super.setTableContent(...arguments);
        }

        getTableContent() {
            if (this.items.length) {
                return [{
                    rows: this.items.map(this._getCellForItem.bind(this)),
                    didMoveCell: function didMoveCell(section, row, tableView, cell, oldIdx, newIdx) {
                        this.ViewController.moveItem(oldIdx, newIdx);
                    }.bind(this)
                }];
            } else {
                return []; // Return an empty array to show the EmptyView
            }
        }

        reloadTable() {
            return super.reloadTable(...arguments).then(function () {
                return this.tableView.setEditingModeForSection(0, true, true, true);
            }.bind(this));
        }

        getMediaId() {
            return this.delegate.getMediaId.apply(this.delegate, arguments);
        }

        getMediaInfo() {
            return this.delegate.getMediaInfo.apply(this.delegate, arguments);
        }

        _getCellForItem(item) {
            var texts = this.control.audioserverComp.getTitleSubtitleForItem(item);
            return {
                content: {
                    title: texts.title
                },
                didRemoveCell: function didRemoveCell(section, row, tableView, cell) {
                    this.ViewController.removeItem(row);
                }.bind(this)
            };
        }

    }

    Controls.AudioZoneV2Control.EditMediaScreen = EditMediaScreen;
    return Controls.AudioZoneV2Control.EditMediaScreen;
});
