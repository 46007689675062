import PropTypes from "prop-types";
import {SearchableTable, navigatorConfig, LxPermissionHandler, LxReactPressable} from "LxComponents"
import React, {useState, useEffect, useMemo, useCallback, useRef} from "react";
import globalStyles from "GlobalStyles";
import Icons from "IconLib";
import TrustTableViewHelper from "../../trustUsers/TrustTableViewHelper";

function UserGroupsScreen({navigation, route}) {
    const details = route.params;
    let selectedGroupsRef = useRef(details.userGroups),
        isMainAdmin = details.isMainAdmin,
        trustPeers = details.trustPeers,
        userIsLastPermanentActiveAdmin = details.userIsLastPermanentAdmin;

    const [userGroupsFromMs, setUserGroupsFromMs] = useState([]);

    useEffect(() => {
        navigation.setOptions(
            navigatorConfig({
                animationType: AnimationType.PUSH_OVERLAP_LEFT,
                title: _('usergroups.title'),
                onLeftAction: () => {
                    _handleBackNavigation();
                }
            })
        )
    }, [])


    useEffect(() => {
        ActiveMSComponent.getGroupList().then((res) => {
            let arr = selectedGroupsRef.current,
                localUserGroups;

            selectedGroupsRef.current = arr.map((selectedGroup) => {
                return res.find((userGroup) => {
                    return selectedGroup.uuid === userGroup.uuid;
                });
            });

            // as designed --> It's not possible to add a user to an usergroup from another miniserver. --> BG-I18378
            localUserGroups = res.filter((userGroup) => {
                return !userGroup.trustMember;
            })

            setUserGroupsFromMs(localUserGroups);
        });
    }, [])

    const _handleBackNavigation = useCallback(() => {
        navigation.navigate(ScreenState.UserDetailsScreen, {
            updateUser: {
                key: UserManagement.USER_GROUPS,
                value: selectedGroupsRef.current
            }
        })
    }, []);

    const _getSectionForUserGroups = () => {
        return {
            rows: userGroupsFromMs.map(function (userGroup) {
                var subtitle,
                    //isSelected = this._isSelected(userGroup.uuid),
                    isSelected = !!selectedGroupsRef.current.find((selectedUserGroup) => {
                        return userGroup.uuid === selectedUserGroup.uuid;
                    }),
                    isGroupAll = userGroup.type === UserManagement.USER_GROUP_TYPE.ALL,
                    // We have to disable the usergroups with admin permission,
                    // if the current logged in user only has usermanagement permission
                    disable = isGroupAll || _disableAdminGroup(userGroup) || _disableGroupsWithAdminPermissions(userGroup) || _disableLastAdminGroup(userGroup, isSelected),
                    isInherentActiveTrustGroup = false;

                if (userGroup.trustMember) {
                    subtitle = "@" + _getTrustPeerName(userGroup.trustMember);
                    isInherentActiveTrustGroup = userGroup.trustMember === ActiveMSComponent.getCurrentUser().trustMember && isSelected;
                }

                const cellProps = {
                    type: isInherentActiveTrustGroup ? GUI.TableViewV2.CellType.CHECKABLE_BUTTON : GUI.TableViewV2.CellType.CHECKABLE,
                    content: {
                        title: userGroup.name,
                        subtitle: subtitle,
                        leftIconSrc: userGroup.trustMember ? Icons.TrustUserGroup : Icons.UserGroup,
                        leftIconColor: userGroup.type === UserManagement.USER_GROUP_TYPE.FULL_ACCESS ? globalStyles.colors.orange : globalStyles.colors.stateActive,
                        clickable: !isInherentActiveTrustGroup,
                        selected: isSelected,
                        disabled: disable,
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE,
                    },
                    didCheckCell: () => {
                        _onCellSelected(userGroup)
                    },
                    didRequestCheckingCell: function didRequestCheckingCell() {
                        return Q(!isInherentActiveTrustGroup);
                    }
                };

                if (isInherentActiveTrustGroup) {
                    cellProps.content.mainRightContent = [{
                        comp: LxReactPressable,
                        props: {
                            key: userGroup.name + "info",
                            onPress: () => {
                                NavigationComp.showPopup({
                                    message: _("usermanagement.trust.user-current.inherent"),
                                    buttonOk: true
                                });
                            },
                            children: <Icons.InfoCircled width={30} height={30} fill={globalStyles.colors.text.primary}
                                                         key={userGroup.name + "-info-icon"}/>
                        }
                    }]
                }

                return cellProps
            }.bind(this))
        };
    }


    /**
     * Prevents the user from elevating its user or another user with admin privileges
     * Returns true --> if the current logged in user only has userManagement permission and the userGroup has admin permission
     * @param userGroup
     * @returns {*|boolean}
     * @private
     */
    const _disableGroupsWithAdminPermissions = (userGroup) => {
        var userHasUserManagementRight = SandboxComponent.checkPermission(MsPermission.USER_MANAGEMENT) && !SandboxComponent.checkPermission(MsPermission.ADMIN);
        return userHasUserManagementRight && hasBit(userGroup.userRights, UserManagement.USER_GROUP_PERMISSIONS.LOXONE_CONFIG);
    }


    /**
     * Prevents the main admin user to remove its own admin permission
     * Returns true --> if the current selected user is the main admin user
     * @param userGroup
     * @returns {*|boolean}
     * @private
     */
    const _disableAdminGroup = (userGroup) => {
        return isMainAdmin && userGroup.type === ActiveMSComponent.getAdminUserGroupType();
    }


    /**
     * Returns true --> if the current selected user is the last user with permanent admin permissions
     * @param userGroup
     * @param isSelected
     * @returns {*|boolean}
     * @private
     */
    const _disableLastAdminGroup = (userGroup, isSelected) => {
        var oneSelectedAdminGroupLeft = selectedGroupsRef.current.filter(function (userGroup) {
            return hasBit(userGroup.userRights, UserManagement.USER_GROUP_PERMISSIONS.LOXONE_CONFIG);
        }.bind(this)).length === 1;
        return userIsLastPermanentActiveAdmin && oneSelectedAdminGroupLeft && isSelected && hasBit(userGroup.userRights, UserManagement.USER_GROUP_PERMISSIONS.LOXONE_CONFIG);
    }


    const _onCellSelected = (selectedGroup) => {
        let groupIdx = -1;

        selectedGroupsRef.current.forEach(function (group, idx) {
            if (group.uuid === selectedGroup.uuid) {
                groupIdx = idx;
            }
        }.bind(this));

        if (groupIdx !== -1) {
            selectedGroupsRef.current.splice(groupIdx, 1);
        } else {
            selectedGroupsRef.current.push(selectedGroup);
        }
    }


    const _getTrustPeerName = (peerSerial) => {
        return (trustPeers.find(function (peer) {
            return peer.serial === peerSerial;
        }) || {}).name || peerSerial;
    }

    const userGroupContent = useMemo(() => {
        let tableContent = []

        if (userGroupsFromMs && userGroupsFromMs.length) {
            tableContent.push(_getSectionForUserGroups());
        } else {
            tableContent.push(TrustTableViewHelper.getDummyContent(5, {showSubTitle: false}));
        }

        return tableContent;
    }, [userGroupsFromMs, trustPeers, selectedGroupsRef, userIsLastPermanentActiveAdmin]);

    return <SearchableTable
        tableContent={userGroupContent}
        filterTitle={_("search.title")}
        hideItemSeparator={true}
        useSubTitle={false}
    />
}


UserGroupsScreen.propTypes = {
    userGroups: PropTypes.arrayOf(PropTypes.object),
    isMainAdmin: PropTypes.bool,
    userIsLastPermanentAdmin: PropTypes.bool,
    trustPeers: PropTypes.arrayOf(PropTypes.object)
}

export default UserGroupsScreen;
