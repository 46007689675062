'use strict';

define([], function () {
    return {
        Timeouts: {
            DISMISS_ALERT: 60 * 1000,
            SCREENSAVER_TICK: 1000 * 20
        },
        DeviceTypes: {
            CUSTOM: 0,
            LX_INTERCOM: 1,
            LX_INTERCOM_XL: 2
        },
        ScreenState: {
            MISSED_LIST: 'IntercomControlContentMissedList',
            MISSED_VIEW: 'IntercomControlContentMissedView',
            SETTINGS: 'IntercomControlContentSettings',
            ABOUT: 'IntercomControlAboutIntercomScreen'
        },
        Feature: {
            // SIP module
            SIP: {
                SECURED_INTERCOM: "8.6"
            },
            // Video module
            VIDEO: {
                SECURED_INTERCOM: "49"
            }
        }
    };
});
