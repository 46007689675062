'use strict';
/**
 * iconSrc    The source of the left icon
 * title     The title of the cell
 * value     moment.js object
 * minDate   set the minimum date of the date picker
 * marked    Array of marked days in the calender view -> http://docs.mobiscroll.com/2-17-0/jquery/calendar#!opt-marked
 *
 * onPickerChanged(section, row, tableView, value)
 *
 * Examples:
 * Label:                 26.01.2016
 * -- --- ----  -- --
 * Display a calendar to pick the date (day, month, year)
 * -- --- ----  -- --
 *
 *
 * Based on:
 * https://docs.mobiscroll.com/2-17-0/jquery/calendar
 * */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.DATE_PICKER = "DatePickerCell";

    class DatePickerCell extends GUI.TableViewV2.Cells.PickerBaseCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("date-picker-cell");
        }

        initDetail(detailElement) {
            super.initDetail(detailElement);
            detailElement.addClass("date-picker-cell__detail");
        }

        // --------------------------------------------------------------------------------------------------------
        //          Overwritten Baseclass Methods
        // --------------------------------------------------------------------------------------------------------

        /**
         * Returns a default picker configuration. Mode is used to differ what type of picker is to be used (scroller
         * is our default, but calendar is also available).
         * @returns {{theme: string, mode: string, display: string, lang: string, onChange}}
         */
        getPickerConfig() {
            var cfg = super.getPickerConfig(...arguments);
            cfg.controls = ['calendar'];
            cfg.layout = 'liquid'; // ensures the whole divs width & height is used.

            if (this.content) {
                cfg.defaultValue = this.content.value.toDate();
                cfg.selectedValues = [cfg.defaultValue];

                if (this.content.marked) {
                    cfg.marked = this.content.marked;
                }

                if (this.content.minDate) {
                    cfg.minDate = this.content.minDate.toDate();
                } else {
                    cfg.minDate = moment().startOf('year').toDate();
                }
            }

            cfg.hightlightNow = true;
            cfg.maxDate = moment().add(2, 'year').endOf('year').toDate();
            cfg.selectType = 'day';
            cfg.onMonthChange = this._handleMonthChange.bind(this);
            cfg.onMonthLoaded = this._handleMonthLoaded.bind(this);
            cfg.onDayChange = this.onChange.bind(this);

            if (isUSDateOrder()) {
                // other countries (North American ones) have Sunday as the first day of the week.
                cfg.firstDay = 0;
            } else {
                // european countries have monday as the first day of the week
                cfg.firstDay = 1;
            }

            return cfg;
        }

        /**
         * This function initializes the Picker
         */
        preparePicker() {
            Debug.GUI.Picker.Date && console.log(this.name, "preparePicker");
            this.picker.calendar(this.getPickerConfig());
            this.setPickerValue(this.content.value);
        }

        /**
         * This function will be called once the pickerValue has changed
         * For example if the user turns one of the pickerWheels
         * @param v The value returned by mobiscroll
         */
        onChange(v) {
            Debug.GUI.Picker.Date && console.log(this.name, "onChange");
            /**
             * v is in format DD.MM.YYYY
             */

            this.antiGhostTimer.fire();
            var momentDate = moment(v.date);
            this.updateLabel(this.getValueAsString(momentDate));
            this.delegate.onPickerChanged.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView, momentDate);
        }

        /**
         * This function returns the string for the timeLabel
         * @param val   expects a moment.js object
         */
        getValueAsString(val) {
            Debug.GUI.Picker.Date && console.log(this.name, "getValueAsString");
            return val.format(DateType.DateText);
        }

        /**
         * This sets the given value to the mobiscroll picker
         * @param value expects a moment.js date object.
         */
        setPickerValue(value) {
            Debug.GUI.Picker.Date && console.log(this.name, "setPickerValue: " + value); // adds the date to the LIST of selected days

            var date = value.toDate();
            this.picker.mobiscroll('setVal', date);
        }

        // --------------------------------------------------------------------------------------------------------
        //          Private Methods
        // --------------------------------------------------------------------------------------------------------
        _handleMonthLoaded(year, month, inst) {
            Debug.GUI.Picker.Date && console.log(this.name, "_handleMonth Loaded: " + year + "-" + (month + 1));
        }

        _handleMonthChange(year, month, inst) {
            Debug.GUI.Picker.Date && console.log(this.name, "_handleMonth Change: " + year + "-" + (month + 1));
        }

    }

    GUI.TableViewV2.Cells.DatePickerCell = DatePickerCell;
    return GUI;
}(window.GUI || {});
