'use strict';

define(['AutopilotCommunicator', 'AutopilotEnums', 'AutopilotUtility'], function (AutopilotCommunicator, AutopilotEnums, AutopilotUtility) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var AUTOPILOT_GENERATOR_STATE_UUID = 'autopilotGenerator';
    return class AutopilotRulesScreen extends GUI.Screen {
        constructor(details) {
            super($('<div />'));
        }

        viewDidLoad() {
            return Q.all([this._loadRules(), // <-- Will show "Waiting for" loading popup when it takes longer then 800ms to execute
                super.viewDidLoad(...arguments) || true]);
        }

        viewWillAppear() {
            return Q.all([this._updateTableView(), super.viewWillAppear(...arguments) || true]).then(function () {
                SandboxComponent.registerForStateChangesForUUID(AUTOPILOT_GENERATOR_STATE_UUID, this, this._receivedStates.bind(this));
            }.bind(this));
        }

        viewWillDisappear() {
            SandboxComponent.unregisterForStateChangesForUUID(AUTOPILOT_GENERATOR_STATE_UUID, this);
            super.viewWillDisappear(...arguments);
        }

        getURL() {
            return "Rules";
        }

        // TABLE VIEW DELEGATES
        styleForTableView(tableView) {
            return TableViewStyle.GROUPED;
        }

        numberOfSections() {
            return 1;
        }

        numberOfRowsInSection() {
            return this.rules ? this.rules.length : 0;
        }

        cellTypeForCellAtIndex(section, row) {
            return GUI.TableViewV2.CellType.SWITCH_SUBTITLE;
        }

        contentForCell(cell, section, row) {
            var rule = this.rules[row];
            var content = {
                title: rule.name || AutopilotUtility.createTitleForRule(rule),
                subtitle: AutopilotUtility.createDescriptionArrayForRule(rule),
                disclosureIcon: true,
                clickable: true,
                active: rule.active
            };

            if (rule.invalid) {
                content.rightIconSrc = Icon.CAUTION;
            }

            return content;
        }

        didSelectCell(cell, section, row) {
            // call edit rule screen
            NavigationComp.showState(AutopilotEnums.ScreenStates.AutopilotRuleScreen, {
                rule: this.rules[row],
                editMode: true
            });
        }

        onSwitchChanged(value, section, row) {
            var rule = this.rules[row];
            AutopilotCommunicator.setRuleActive(rule, value);
        }

        _loadRules() {
            return AutopilotCommunicator.getRules().then(function (rules) {
                this.rules = rules;
                return this.rules;
            }.bind(this), function () {
                // show error popup
                var content = {
                    title: _("error"),
                    message: _('error.occured'),
                    buttonOk: true,
                    icon: Icon.CAUTION,
                    color: window.Styles.colors.red
                };
                NavigationComp.showPopup(content).done(function () {
                    this.ViewController.navigateBack();
                }.bind(this));
            }.bind(this));
        }

        /**
         * Loads rules if not already and populates the TableView and Background view as required
         * @param [forceReload] Will force to fetch the rules
         * @return {Promise}
         * @private
         */
        _updateTableView(forceReload) {
            var prms = [true];

            if (!this.rules || forceReload) {
                prms.push(this._loadRules());
            }

            return Q.all(prms).then(function () {
                if (!this.tableView) {
                    this.tableView = new GUI.TableViewV2(this, this);
                    this.appendSubview(this.tableView);
                }

                if (this.rules.length === 0) {
                    this.tableView.setBackgroundView(new GUI.BackgroundView(Icon.Autopilot.COMMON, _('autopilot.no-rule'), _('autopilot.no-rule.description')));
                } else {
                    this.tableView.setBackgroundView(null);
                }

                if (this.wasAlreadyShown()) {
                    // to prevent error logs!
                    this.processWhenVisible(this.tableView.reload.bind(this.tableView));
                } else {
                    this.tableView.reload();
                }
            }.bind(this));
        }

        _receivedStates(states) {
            Debug.Autopilot.StateContainer && console.log("AutopilotRulesScreen received states"); // The states have change, force a reload

            this._updateTableView(true);
        }

    };
});
