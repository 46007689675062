import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"

function MinusFilledCircle(props) {
    return (
        <Svg isreact="true"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 30 30"
            xmlSpace="preserve"
            enableBackground="new 0 0 30 30"
            {...props}
        >
            <Circle cx={15} cy={15} r={15} />
            <Path
                d="M10 14h10a1 1 0 010 2H10a1 1 0 010-2z"
                fill="#fff"
                fillRule="evenodd"
                clipRule="evenodd"
            />
        </Svg>
    )
}

export default MinusFilledCircle
