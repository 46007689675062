import {CCLocation, ControlContext, LxBaseControlContent} from "LxComponents";
import {useCallback, useContext} from "react";
import {View} from "react-native";
import LxSaunaInfoView from "./LxSaunaInfoView";

function SaunaControlContent(props) {
    const {control, states} = useContext(ControlContext);

    const showSaunaInfoViews = () => states.isActive && !states.fanActive && !states.drying;
    const showHumidityInfoView = () => !!control.details.hasVaporizer;

    const showStateIcons = useCallback((control, states) => {
        return !(states.isActive && !states.fanActive && !states.drying);
    }, [])

    return <LxBaseControlContent showStateIcon={showStateIcons}>
        {showSaunaInfoViews() && <View style={styles.saunaInfoViewContainer} location={CCLocation.START}>
            <LxSaunaInfoView actualValue={states.tempActualVal}
                             targetValue={states.tempTargetVal}
                             label={states.hasBenchTemp ? _("controls.sauna.bench-temp.name") : _("temperature")}
                             isHumidity={false}/>
            {showHumidityInfoView() && <LxSaunaInfoView actualValue={states.humidActualVal}
                                                        targetValue={states.humidTargetVal}
                                                        label={_("humidity")}
                                                        isHumidity={true}
            />}
        </View>}
    </LxBaseControlContent>
}

const styles = {
    saunaInfoViewContainer: {
        flexDirection: "row",
        width: "100%"
    }
}

export default SaunaControlContent
