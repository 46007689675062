'use strict';
/*
title
selected
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.REMOTE = "RemoteCell";

    class RemoteCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(title, selected) {
                return $('<div class="content__icon ' + (selected ? 'selected' : '') + '"></div>' + '<div class="content__title">' + title + '</div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("remote-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.contentPlaceholder.append(RemoteCell.Template.getTemplate(this.content.title, this.content.selected));
        }

        clickableAllowed() {
            return true;
        }

    }

    GUI.TableViewV2.Cells.RemoteCell = RemoteCell;
    return GUI;
}(window.GUI || {});
