import globalStyles from "GlobalStyles";
import useLimitedWallboxes, {WallboxEmRestrictionCauses} from "./useLimitedWallboxes";
import {LxReactTableView, navigatorConfig} from "LxComponents";
import {useContext, useEffect} from "react";
import {ControlContext, useBackNavigation} from "../../../react-comps/Components";
import WallboxManagerNode from "./wallboxManagerNodes";
import {WallboxManagerLimitedByCell} from "./wallboxManagerLimitedBy";


export default function WallboxManagerLimitedByScreen({navigation, route}) {
    const {limitedBit = WallboxEmRestrictionCauses.ALL, nodeUuid} = route.params;
    const {control, states} = useContext(ControlContext);
    const restrictedWallboxNodes = useLimitedWallboxes(nodeUuid);

    useBackNavigation(() => {
        navigation.goBack();
    }, []);

    useEffect(() => {
        navigation.setOptions({
            ...navigatorConfig({
                title: _("controls.wallboxmanager.affected-wallboxes")
            })
        })
    }, [navigation]);

    const affectedNodes = () => {
        const nodeUuidMap = {},
            wantedBit = parseInt(limitedBit);

        Object.keys(restrictedWallboxNodes).forEach(bit => {
            if (hasBit(wantedBit, parseInt(bit))) {
                restrictedWallboxNodes[bit].forEach(uuid => {
                    nodeUuidMap[uuid] = true;
                })
            }
        });

        return Object.keys(nodeUuidMap).map(nodeUuid => {
            return control?.getNode(nodeUuid);
        });
    }

    const getTableContent = () => {
        let rows = [];

        if (limitedBit !== WallboxEmRestrictionCauses.ALL) {
            rows.push({
                key: "disclaimer",
                mainCenterContent: {
                    comp: WallboxManagerLimitedByCell,
                    props: {
                        nodeUuid: nodeUuid,
                        limitBit: limitedBit
                    }
                },
                mainCenterStyle: {
                    width: "100%",
                    alignItems: "stretch",
                    justifyContent: "stretch"
                }
            });
        }

        rows = [
            ...rows,
            ...affectedNodes().map(node => {
                    return {
                        key: node.uuid,
                        mainCenterContent: {
                            comp: WallboxManagerNode,
                            props: {
                                nodeUuid: node.uuid,
                                managerUuid: control.uuidAction,
                                showPath: true
                            }
                        },
                        mainCenterStyle: {
                            width: "100%",
                            alignItems: "stretch",
                            justifyContent: "stretch"
                        }
                    };
                }
            )
        ]

        return [{rows}];
    }

    return <LxReactTableView tableContent={getTableContent()} containerStyle={globalStyles.customStyles.screenContent} />
}