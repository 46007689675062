/**
 * Created by loxone on 20.06.16.
 */
'use strict';

define(['AddMediaViewControllerV2Base'], function (AddMediaViewControllerV2Base) {
    class AddMediaViewControllerSpotifyOnboarding extends AddMediaViewControllerV2Base {
        //region Private
        // We can't add any of these items to a Spotify Playlist!
        BANNED_CUSTOMIZATION_KEYS = [MusicServerEnum.ControlContentMenuId.PLAYLISTS, MusicServerEnum.ControlContentMenuId.RADIO, MusicServerEnum.ControlContentMenuId.LIB, MusicServerEnum.ControlContentMenuId.LINE_IN]; //endregion Private

        constructor(details) {
            super(...arguments);
            this.details.blockedIds = [];
            this.control.audioserverComp.fillWithInputCustomizationKeys(this.BANNED_CUSTOMIZATION_KEYS);
        }

        addItem(item, mediaInfo) {
            this.addItemCnt++;

            this._updateAddCounter();

            this.details.blockedIds.pushIfNoDuplicate(item.id);
            return this.control.audioserverComp.sendMediaServerCommand(Commands.format(MusicServerEnum.Commands.SERVICE_MODIFY.FOLLOW, mediaInfo.service.uid, item.id));
        }

        isBlockedItem(item) {
            return false;
        }

        removeItem(idx) {// Just do nothing...
        }

        moveItem(oldIdx, newIdx) {// Just do nothing...
        }

    }

    GUI.AddMediaViewControllerSpotifyOnboarding = AddMediaViewControllerSpotifyOnboarding;
    return GUI.AddMediaViewControllerSpotifyOnboarding;
});
