

const DeviceSearchEnums = {
    SearchState: {
        SELECT_MINISERVER: "selectMiniserver",
        SELECT_EXTENSION: "selectExtension",
        BROWSE_RESULTS: "browseResults",
        ASSISTANT: "assistant"
    },
    NO_SWITCHBOARD_ID: "-no-switchboard-selected-"

}

export default DeviceSearchEnums