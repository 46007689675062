import {useState} from "react";
import {View, Picker} from 'react-native';
import PropTypes from "prop-types";
import globalStyles from "GlobalStyles";
import {LxReactText} from "LxComponents";

export default function LxReactDurationPicker(props) {

    developerAttention("The LxReactDurationPicker is very much in progress!");

    let { value, wheelOrder = "hhii", onChange } = props,
        [inputValue, setInputValue] = useState(value),
        [isPickerOpen, setIsPickerOpen] = useState(false),
        fractions = wheelOrder.match(/(..?)/g);

    const onChangeWrapper = (fractionIdx, value, index) => {
        let newValue = Array.from(inputValue);
        newValue[fractionIdx] = value;
        onChange(newValue);
        setInputValue(newValue);
    }

    const renderFraction = (fraction) => {
        let min = 0,
            max;
        switch (fraction) {
            case LxReactDurationPicker.WheelOrderFractions.Hours:
                max = 24;
                break;
            case LxReactDurationPicker.WheelOrderFractions.Minutes:
            case LxReactDurationPicker.WheelOrderFractions.Seconds:
                max = 59
                break;
        }

        let pickerItems = [];
        for (let i = min; i <= max; i++) {
            pickerItems.push((
                <Picker.Item
                    label={i}
                    value={i}
                />
            ))
        }

        return pickerItems;
    }

    const renderFractions = () => {
        return fractions.map((fraction, fractionIdx) => {
            return (
                <View style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <Picker
                        style={{
                            background: "transparent",
                            color: isPickerOpen ? globalStyles.colors.brand : globalStyles.colors.text.secondary,
                        }}
                        selectedValue={inputValue[fractionIdx]}
                        onValueChange={(value, index) => {
                            onChangeWrapper(fractionIdx, value, index)
                        }}
                    >
                        {
                            renderFraction(fraction)
                        }
                    </Picker>
                    <LxReactText style={{
                        color: isPickerOpen ? globalStyles.colors.brand : globalStyles.colors.text.secondary,
                        alignSelf: "center"
                    }}> {LxReactDurationPicker.WheelOrderMap[fraction]} </LxReactText>
                </View>
            )
        })
    }

    return (
        <View style={{
            backgroundColor: globalStyles.colors.fill.tertiary,
            borderRadius: 2,
            padding: 5,
            display: "flex",
            flexDirection: "row"
        }}>
            {
                renderFractions()
            }
        </View>
    );
}

LxReactDurationPicker.WheelOrderFractions = {
    Hours: "hh",
    Minutes: "ii",
    Seconds: "ss"
}

LxReactDurationPicker.WheelOrderMap = {
    [LxReactDurationPicker.WheelOrderFractions.Hours]: "h",
    [LxReactDurationPicker.WheelOrderFractions.Minutes]: "m",
    [LxReactDurationPicker.WheelOrderFractions.Seconds]: "s"
}

LxReactDurationPicker.propTypes = {
    value: PropTypes.arrayOf(PropTypes.number),
    wheelOrder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    min: PropTypes.number,
    max: PropTypes.number
}
