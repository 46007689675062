import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {ControlContext, LxReactTableView, navigatorConfig, LxReactText, useSetURLHook} from "LxComponents";
import {View} from "react-native";
import {useFocusEffect, useNavigation} from "@react-navigation/native";
import {AUTO_MODE, BOUNDARY_INFO, TEMP_BOUNDRIES} from "../../../climateControlEnums";
import globalStyles from "GlobalStyles";

function LxTemperatureScreen(props) {
    const {control, states} = useContext(ControlContext)
    const navigation = useNavigation();
    const valueRef = useRef({
        cooling: states.onlyCoolingBoundary,
        heating: states.onlyHeatingBoundary
    })
    const [updateTrigger, setUpdateTrigger] = useState(false);

    const getHeatingValue = () => valueRef.current.heating;
    const getCoolingValue = () => valueRef.current.cooling;

    useSetURLHook({
        urlPart: "climatecontroltemperatures"
    })

    useEffect(() => {
        navigation.setOptions({
            ...navigatorConfig({
                title: _("controls.climate.temp-boundary"),
                animationType: AnimationType.HD_OVERLAY
            })
        })
    }, [])

    useFocusEffect(useCallback(() => {
        return () => {
            let coolingValue = getCoolingValue();
            let heatingValue = getHeatingValue();
            // This is the "automatic" flag, enable it to prevent a "bad connection" popup
            if (coolingValue !== states.onlyCoolingBoundary) {
                SandboxComponent.sendCommand(this, control.uuidAction, Commands.format(Commands.CLIMATE_CONTROLLER.SET_BOUNDARY.COOLING, coolingValue), null, control.isSecured, true);
            }
            if (heatingValue !== states.onlyHeatingBoundary) {
                SandboxComponent.sendCommand(this, control.uuidAction, Commands.format(Commands.CLIMATE_CONTROLLER.SET_BOUNDARY.HEATING, heatingValue), null, control.isSecured, true);
            }
        }
    }, []))

    const onCoolingBoundaryChange = (cell, rowIdx, sectionIdx, tableView, value) => {
        valueRef.current.cooling = value;
        setUpdateTrigger(!updateTrigger)
        //this._updateHeaderText(true, value);
    };

    const onHeatingBoundaryChange = (cell, rowIdx, sectionIdx, tableView, value) => {
        valueRef.current.heating = value;
        //this._updateHeaderText(false, value);
        setUpdateTrigger(!updateTrigger)
    }

    /**
     * creates a section from an object. so that the object could be identified the
     * "isHeatingObject" variable either be set to true or false
     * object includes:
     * - sectionTitle
     * - sectionDescription
     * - sectionValue
     * - avgTempStr
     * - onSliderChanged
     * - operator
     * @param object
     * @param setByLogic -> if true the value shouldn't be modifyable from app.
     * @returns {Object}
     * @private
     */
    const createSection = (object, setByLogic = false) => {
        let valueCell,
            format = " %.1f°";
        if (setByLogic) {
            valueCell = {
                content: {
                    title: object.title,
                    subtitle: _("controlled-via-logic"),
                    disclosureText: lxFormat(format, object.sectionValue),
                }
            }
        } else {
            valueCell = {
                content: {
                    title: object.title,
                    minValue: TEMP_BOUNDRIES.MIN,
                    value: object.sectionValue,
                    maxValue: TEMP_BOUNDRIES.MAX,
                    step: TEMP_STEP,
                    format: format,
                    minIconSrc: Icon.MINUS,
                    maxIconSrc: Icon.PLUS,
                    valueColor: globalStyles.colors.stateActive,
                    antiGhostTimer: false
                },
                type: GUI.TableViewV2.CellType.SLIDER,
                sliderDragged: object.onSliderChanged,
                sliderClicked: object.onSliderChanged
            };
        }
        return {
            headerStrongTitle: object.sectionTitle,
            rows: [valueCell],
            footerTitle: object.sectionDescription
        };
    }

    const tableContent = useMemo(() => {
        let content = [],
            onlyCoolingObject = {
                title: _("controls.climate.temp.no.heating"),
                sectionTitle: _("controls.climate.allow.cooling.not-tempeature"),
                sectionDescription: _("controls.climate.allow.cooling.short.desc"),
                sectionValue: getCoolingValue(),
                onSliderChanged: onCoolingBoundaryChange.bind(this)
            },
            onlyHeatingObject = {
                title: _("controls.climate.temp.no.cooling"),
                sectionTitle: _("controls.climate.allow.heating.not-tempeature"),
                sectionDescription: _("controls.climate.allow.heating.short.desc"),
                sectionValue: getHeatingValue(),
                onSliderChanged: onHeatingBoundaryChange.bind(this)
            }; // Add the needed sections to the content dependig on the settings

        if (states.temperatureBoundaryInfo !== BOUNDARY_INFO.NO_DATA) {
            switch (states.autoMode) {
                case AUTO_MODE.HEATING_AND_COOLING:
                case AUTO_MODE.OFF:
                    control.hasHeatingCapability() && content.push(createSection(onlyCoolingObject, control.tlimhLogic));
                    control.hasCoolingCapability() && content.push(createSection(onlyHeatingObject, control.tlimcLogic));
                    break;

                case AUTO_MODE.HEATING_ONLY:
                    content.push(createSection(onlyCoolingObject, control.tlimhLogic));
                    break;

                case AUTO_MODE.COOLING_ONLY:
                    content.push(createSection(onlyHeatingObject, control.tlimcLogic));
                    break;
            }
        }

        return content;
    }, [updateTrigger, states.autoMode])

    return <View>
        <LxTemperatureModeInfoView heatingValue={valueRef.current.heating} coolingValue={valueRef.current.cooling} />
        <LxReactTableView tableContent={tableContent}/>
    </View>
}

function LxTemperatureModeInfoView({heatingValue, coolingValue}) {
    const {control, states} = useContext(ControlContext);
    const hasData = states.temperatureBoundaryInfo === BOUNDARY_INFO.OK;

    const infoText = useMemo(() => {
        const avgTempStr = Feature.CLIMATE_AVG_OUTDOOR_TEMP ? states.avgOutdoorTemp : states.actualOutdoorTemp;
        if (Feature.CLIMATE_AVG_OUTDOOR_TEMP) {
            switch (states.temperatureBoundaryInfo) {
                case BOUNDARY_INFO.NOT_ENOUGH_DATA:
                    return _("controls.climate.temp-boundary.info.not-enough-data"); // No avg temperature is available, set it to the current outdoor temp to prevent showing -1000 in the UI
                case BOUNDARY_INFO.OK:
                    return _("controls.climate.temp-boundary.info.ok", {
                        avgTemp: lxFormat("%.1f°", avgTempStr)
                    });
                case BOUNDARY_INFO.NO_DATA:
                    return _("controls.climate.temp-boundary.info.no-data");
            }
        }
    }, [states.temperatureBoundaryInfo, states.avgOutdoorTemp, states.actualOutdoorTemp]);

    const tempTexts = useMemo(() => {
        let coolingText,
            heatingText;
        if (states.avgOutdoorTemp > heatingValue && states.autoMode !== AUTO_MODE.HEATING_ONLY) {
            coolingText = _("controls.climate.allow.cooling.state");
        } else {
            coolingText = _("controls.climate.disallow.cooling.state");
        }

        if (states.avgOutdoorTemp < coolingValue && states.autoMode !== AUTO_MODE.COOLING_ONLY) {
            heatingText =  _("controls.climate.allow.heating.state")
        } else {
            heatingText =  _("controls.climate.disallow.heating.state");
        }

        return {
            cooling: coolingText,
            heating: heatingText
        }
    }, [heatingValue, coolingValue, states.avgOutdoorTemp, states.autoMode])

    return <View style={styles.infoView.container}>
        <LxReactText style={styles.infoView.label}>{infoText}</LxReactText>
        {states.isOff && <LxReactText style={styles.infoView.offLabel}>{ _("controls.climate.turned-off") }</LxReactText>}
        {hasData && control.hasHeatingCapability() && <LxReactText style={styles.infoView.label}>{tempTexts.heating}</LxReactText>}
        {hasData && control.hasCoolingCapability() && <LxReactText style={styles.infoView.label}>{tempTexts.cooling}</LxReactText>}
    </View>
}

const styles = {
    infoView: {
        container: {
            //backgroundColor: globalStyles.colors.grey["600"],
            padding: globalStyles.spacings.gaps.small,
            marginTop: globalStyles.spacings.gaps.small,
            marginHorizontal: globalStyles.spacings.contentHorizontal
        },
        label: {
            ...globalStyles.textStyles.footNote.default,
            color: globalStyles.colors.text.secondary,
            marginTop: globalStyles.spacings.gaps.verySmall
        },
        offLabel: {
            ...globalStyles.textStyles.footNote.default,
            color: globalStyles.colors.red,
            marginTop: globalStyles.spacings.gaps.verySmall
        }
    }
}

const TEMP_STEP = 0.5

export default LxTemperatureScreen