'use strict';

define([], function () {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var ENERGY_FORMAT = "%.1f kWh";
    var CO2_FORMAT = "%.1f";
    var YIELD_FORMAT = "%.2f";

    var findSectionElements = function (container, sectionType, getUnitElements) {
        var elements = {};
        var section = container.find('.info--' + sectionType)[0]; // formally closest

        elements.totalValue = section.getElementsByClassName('fronius__info__value')[0];

        if (getUnitElements) {
            elements.totalUnit = section.getElementsByClassName('fronius__info__unit')[0];
        }

        var entryTypes = ['day', 'month', 'year'];
        var entry, entryType;

        for (var i = 0; i < entryTypes.length; i++) {
            entryType = entryTypes[i];
            entry = section.getElementsByClassName('entry--' + entryType)[0];
            elements[entryType + 'Value'] = entry.getElementsByClassName('fronius__info__entry__value')[0];

            if (getUnitElements) {
                elements[entryType + 'Unit'] = entry.getElementsByClassName('fronius__info__entry__unit')[0];
            }
        }

        return elements;
    };

    var updateEnergyEntry = function updateEnergyEntry(lblValue, lblUnit, value) {
        var splitTexts = lxUnitConverter.convertSplitAndApply(ENERGY_FORMAT, value);
        lblUnit.innerHTML = splitTexts.succTxt;
        lblValue.innerHTML = splitTexts.valueTxt;
    };

    var updateCo2Entry = function updateCo2Entry(lblValue, lblUnit, value) {
        if (value > 1000) {
            value /= 1000;
            lblUnit.innerHTML = _("controls.fronius.unit.ton");
        }

        lblValue.innerHTML = lxFormat(CO2_FORMAT, value);
    };

    return class FroniusControlContentOverview extends Controls.ControlContent {
        //region Static
        static Template = function () {
            var TIME_RANGE = {
                Day: 0,
                Month: 1,
                Year: 2
            };
            var ENERGY_UNIT = "KWh",
                CO2_UNIT = "kg";
            var energySrc = "resources/Images/Controls/Fronius/leaf_green.svg",
                co2Src = "resources/Images/Controls/Fronius/trees.svg",
                yieldSrc = "resources/Images/Controls/Fronius/piggy-bank.svg";

            var getTemplate = function getTemplate() {
                return $('<div class="fronius__info-container">' + createInfoSection('energy', _("controls.fronius.production.energy"), ENERGY_UNIT, false, energySrc) + '<div class="fronius__info-section-seperator" />' + createInfoSection('co2', _("controls.fronius.savings.co2"), CO2_UNIT, false, co2Src, true) + '<div class="fronius__info-section-seperator" />' + createInfoSection('yield', _("controls.fronius.yield"), ActiveMSComponent.getCurrencyString(), true, yieldSrc) + '</div>');
            };

            var createInfoSection = function createInfoSection(infoType, sectionLabel, unit, isPrefixUnit, iconSrc, isLeftAligned) {
                var time = new LxDate();
                var prefixClass = isPrefixUnit ? " prefixed-unit" : "",
                    alignmentClass = isLeftAligned ? " left-aligned" : "";
                var unitLabel = '<span class="fronius__info__unit">' + unit + '</span>';
                return '<div class="fronius__info-section info--' + infoType + prefixClass + alignmentClass + '">' + '<div class="fronius__info__content">' + (isPrefixUnit ? unitLabel : "") + '<div class="fronius__info__value"> - </div>' + (isPrefixUnit ? "" : unitLabel) + '<div class="fronius__info__label">' + sectionLabel + '</div>' + '<ul class="fronius__info__entries">' + createInfoEntry(infoType, time, TIME_RANGE.Day, unit, isPrefixUnit) + createInfoEntry(infoType, time, TIME_RANGE.Month, unit, isPrefixUnit) + createInfoEntry(infoType, time, TIME_RANGE.Year, unit, isPrefixUnit) + '</ul>' + '</div>' + ImageBox.getResourceImageWithClasses(iconSrc, 'fronius__info__icon') + '</div>';
            };

            var createInfoEntry = function infoEntry(infoType, time, timeRange, unit, isPrefixUnit) {
                var title = "",
                    classTag = "";

                if (timeRange === TIME_RANGE.Day) {
                    title = _("dateTime.today");
                    classTag = "day";
                } else {
                    if (timeRange === TIME_RANGE.Month) {
                        title = time.format(LxDate.getDateFormat(DateType.MonthText));
                        classTag = "month";
                    } else {
                        title = time.format(LxDate.getDateFormat(DateType.Year));
                        classTag = "year";
                    }
                }

                var unitLabel = '<span class="fronius__info__entry__unit">' + unit + '</span>';
                return '<li class="fronius__info__entry entry--' + classTag + '">' + '<span class="fronius__info__entry__time">' + title + '</span>' + '<div class="fronius__info__entry__dots">...................................................................................................</div>' + // should be enough :)
                    '<div class="fronius__info__entry__data">' + (isPrefixUnit ? unitLabel : "") + '<span class="fronius__info__entry__value"></span>' + (isPrefixUnit ? "" : unitLabel) + '</div>' + '</li>';
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(details) {
            Debug.Control.Fronius && console.log("FroniusControlContentOverview ctor");
            super(details);
            this.elements = {};
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.elements.overview = FroniusControlContentOverview.Template.getTemplate();
            this.element.append(this.elements.overview);
            this.elements.energy = findSectionElements(this.elements.overview, 'energy', true);
            this.elements.co2 = findSectionElements(this.elements.overview, 'co2', true);
            this.elements.yield = findSectionElements(this.elements.overview, 'yield', false); // TODO-woessto: Remove this once the class names are assigned properly

            this.element.toggleClass('fronius_control_content_overview', true);
            this.element.toggleClass('fronius_control_content', false);
        }

        titleBarText() {
            return _("controls.fronius.overview");
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK
            };
        }

        viewDidDisappear(viewRemainsVisible) {
            Debug.Control.Fronius && console.log("FroniusControlContentOverview viewDidDisappear");
            super.viewDidDisappear(viewRemainsVisible);
        }

        receivedStates(states) {
            Debug.Control.Fronius && console.log("FroniusControlContentOverview receivedStates");
            var energyEls = this.elements.energy;
            updateEnergyEntry(energyEls.totalValue, energyEls.totalUnit, states.productionTotal);
            updateEnergyEntry(energyEls.dayValue, energyEls.dayUnit, states.productionDay);
            updateEnergyEntry(energyEls.monthValue, energyEls.monthUnit, states.productionMonth);
            updateEnergyEntry(energyEls.yearValue, energyEls.yearUnit, states.productionYear);
            var co2Els = this.elements.co2;
            updateCo2Entry(co2Els.totalValue, co2Els.totalUnit, states.productionTotal * states.co2Factor);
            updateCo2Entry(co2Els.dayValue, co2Els.dayUnit, states.productionDay * states.co2Factor);
            updateCo2Entry(co2Els.monthValue, co2Els.monthUnit, states.productionMonth * states.co2Factor);
            updateCo2Entry(co2Els.yearValue, co2Els.yearUnit, states.productionYear * states.co2Factor);
            var yieldEls = this.elements.yield;
            yieldEls.totalValue.innerHTML = lxFormat(YIELD_FORMAT, states.earningsTotal);
            yieldEls.dayValue.innerHTML = lxFormat(YIELD_FORMAT, states.earningsDay);
            yieldEls.monthValue.innerHTML = lxFormat(YIELD_FORMAT, states.earningsMonth);
            yieldEls.yearValue.innerHTML = lxFormat(YIELD_FORMAT, states.earningsYear);
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        getURL() {
            return "Overview";
        }

        destroy() {
            Debug.Control.Fronius && console.log("FroniusControlContentOverview destroy");
            super.destroy();
        }

    };
});
