/**
 * Created by loxone on 27/07/15.
 */
window.GUI = function (GUI) {
    class CollectionViewV2 extends GUI.EditableTableView {
        constructor(dataSource, delegate, elem) {
            super(...arguments);
            this.element.addClass('lx-collection-view-v2');
            this.hasDummyCell = [];
        }

        // --------------------------------------,
        _getTableViewSpecificCellClass() {
            return "specific-cell-class--cells-container__collection-cell";
        }

        // -------------------------------------

        /**
         * To avoid having to add a dummy cell in the dataSource, simply add one here.
         * @param sectionIdx
         * @returns {*}
         * @private
         */
        _getNumberOfRowsInSection(sectionIdx) {
            Debug.GUI.CollectionView && console.log(this.name, "_getNumberOfRowsInSection: " + sectionIdx);

            var numberOfCells = super._getNumberOfRowsInSection(sectionIdx);

            this.hasDummyCell[sectionIdx] = false;

            if (numberOfCells % 2 !== 0) {
                // store the idx of the dummyCell always > 0. numberOfCells will be the idx of the dummy
                this.hasDummyCell[sectionIdx] = numberOfCells; // uneven number of cells, even it out

                numberOfCells++;
            }

            return numberOfCells;
        }

        /**
         * Creates a cell for the specified section/row and returns it. This is put into a separate implementation
         * to enable interfering with it in subclasses such as the collectionView if needed.
         * @param sectionIdx
         * @param rowIdx
         * @returns {*}
         * @private
         */
        _createRowAtIndex(sectionIdx, rowIdx) {
            Debug.GUI.CollectionView && console.log(this.name, "_createRowAtIndex: " + sectionIdx + " - row: " + rowIdx);

            if (this.hasDummyCell[sectionIdx] !== false && rowIdx === this.hasDummyCell[sectionIdx]) {
                Debug.GUI.CollectionView && console.log("      creating a dummy cell!");
                var cellType = GUI.TableViewV2.CellType.Special.COLLECTION;
                var constructor = GUI.TableViewV2.Cells[cellType];
                return Q.resolve(new constructor(this, this, cellType, sectionIdx, rowIdx, this));
            } else {
                return super._createRowAtIndex(...arguments);
            }
        }

        // ----------------------------------------
        // DataSource Methods for the Dummy-Cell
        contentForCell(cell, section, row, view) {
            Debug.GUI.CollectionView && console.log(this.name, "contentForCell: " + section + " - row: " + row);

            if (this.hasDummyCell[section] !== false && this.hasDummyCell[section] === row) {
                return {
                    clickable: false
                };
            } else {
                console.error("CollectionView did receive a contentForCell it cannot handle! It only handles dummy cells!");
                return null;
            }
        }

    }

    GUI.CollectionViewV2 = CollectionViewV2;
    return GUI;
}(window.GUI || {});
