import {createStackNavigator} from "@react-navigation/stack";
import EcoScreenSettings from "./EcoScreenSettings";
import {LxReactSelectorScreen, navigatorConfig, AmbientContext} from "LxComponents";
import EcoScreenPresenceControlSelection from "./EcoScreenPresenceControlSelection";
import {useContext, useEffect} from "react";

const EcoScreenSettingsStack = createStackNavigator();

function EcoScreenSettingsNavigator(props) {
    const {isAmbientMode} = useContext(AmbientContext)

    useEffect(() => {
        props.navigation.setOptions({
            ...navigatorConfig({
                animationType: isAmbientMode ? AnimationType.NONE : AnimationType.MODAL,
                headerShown: false,
                isAmbientMode: isAmbientMode
            })
        })
    }, [])

    return <EcoScreenSettingsStack.Navigator initialRouteName={EcoScreenSettings.name}>
        <EcoScreenSettingsStack.Screen name={EcoScreenSettings.name} component={EcoScreenSettings} />
        <EcoScreenSettingsStack.Screen name={EcoScreenPresenceControlSelection.name} component={EcoScreenPresenceControlSelection} />
        <EcoScreenSettingsStack.Screen name={LxReactSelectorScreen.name} component={LxReactSelectorScreen} />
    </EcoScreenSettingsStack.Navigator>
}


export default EcoScreenSettingsNavigator
