'use strict';

define(["AudioZoneControlEnums", "MediaBrowserService"], function (AudioZoneControlEnums, MediaBrowserService) {
    class MediaBrowserService11 extends MediaBrowserService {
        //region Static
        static isRadio() {
            return Controls.AudioZoneControl.MediaBrowserService.isRadio.apply(this, arguments);
        }

        static getCellFromContentTypeItem() {
            return Controls.AudioZoneControl.MediaBrowserService.getCellFromContentTypeItem.apply(this, arguments);
        }

        static getCellButtonIconSrc() {
            return Controls.AudioZoneControl.MediaBrowserService.getCellButtonIconSrc.apply(this, arguments);
        }

        static getCellTypeForItemCellAtIndex() {
            return Controls.AudioZoneControl.MediaBrowserService.getCellTypeForItemCellAtIndex.apply(this, arguments);
        }

        static getIconObjForItem() {
            return Controls.AudioZoneControl.MediaBrowserService.getIconObjForItem.apply(this, arguments);
        } //endregion Static


        constructor(details) {
            super(...arguments);
            Object.assign(this, MediaBrowserEditablePlaylistMixin.Mixin);
        }

        getEmptyViewIconSrc() {
            if (this.hasError) {
                return super.getEmptyViewIconSrc(...arguments);
            }

            return this.receivedContent ? null : super.getEmptyViewIconSrc(...arguments);
        }

        getEmptyViewTitle() {
            if (this.hasError) {
                return super.getEmptyViewTitle(...arguments);
            }

            return this.receivedContent ? _("media.playlist.empty.title") : super.getEmptyViewTitle(...arguments);
        }

        getEmptyViewButtonText() {
            if (this.hasError) {
                return super.getEmptyViewButtonText(...arguments);
            }

            return this.receivedContent ? _("media.library.upload.title") : super.getEmptyViewButtonText(...arguments);
        }

        emptyViewButtonAction() {
            if (!this.hasError && this.receivedContent) {
                this._addNewMusicToItem(this.lastSelectedItem);
            } else {
                return super.emptyViewButtonAction(...arguments);
            }
        }

        getActionsForCtxAndItem(ctx, item) {
            var actions = super.getActionsForCtxAndItem(...arguments);
            actions = actions.concat(this._getEditableActionsForCtxAndItem.apply(this, arguments));
            return actions;
        }

    }

    // FileType = 11 = PLAYLIST_EDITABLE
    Controls.AudioZoneControl.MediaBrowserService11 = MediaBrowserService11;
    return Controls.AudioZoneControl.MediaBrowserService11;
});
