import {useCallback} from "react";
import LxTrackerView from "../../trackerControl/trackerView/LxTrackerView";

function LxAlarmTrackerView ({trackerControl}) {
    const transformEntries = useCallback((entries) => {
        if (!entries || !entries.length) {
            return [];
        }
        return [{
            // Reduce them to only one cell with 4 entries
            entries: entries[0].entries.slice(0, MAX_TRACKER_ENTRIES)
        }];
    }, [])

    return <LxTrackerView trackerControl={trackerControl} transformEntries={transformEntries}/>
}

const MAX_TRACKER_ENTRIES = 4;

export default LxAlarmTrackerView;