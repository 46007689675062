/**
 * Created by loxone on 20.06.16.
 */
'use strict';

define(['AddMediaViewControllerPlaylist'], function (AddMediaViewControllerPlaylist) {
    class AddMediaViewControllerPlaylistSpotify extends AddMediaViewControllerPlaylist {
        constructor(details) {
            super(...arguments);
        }

    }

    GUI.AddMediaViewControllerPlaylistSpotify = AddMediaViewControllerPlaylistSpotify;
    return GUI.AddMediaViewControllerPlaylistSpotify;
});
