'use strict';

define("CentralWindowControl", ["WindowControl"], function (WindowControl) {
    return class CentralWindowControl extends WindowControl {
        constructor() {
            super(...arguments);
        }

        getReactControlContentFlags() {
            return {
                showStateIcon: false
            }
        }

        getCentralIcon() {
            return Icon.AudioZone.AUDIO_ZONE;
        }

        /**
         * We need to override the base
         * @returns {string}
         */
        getControlContentIdentifier() {
            return this.controlType + "ControlContent";
        }

    };
});
