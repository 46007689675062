'use strict';

define([], function () {
    return class LightControlEditSceneScreen extends Controls.ControlContent {
        constructor(details) {
            super(details);
            this.controls = mapToArrayOld(this.control.subControls);
        }

        viewDidLoad() {
            var promise = super.viewDidLoad(...arguments);
            this.elements.tableViewWrapper = $('<div class="edit-scene__table-view-wrapper"></div>');
            this.element.append(this.elements.tableViewWrapper); // sceneName

            this.sceneNameTableView = new GUI.TableViewV2(this, this);
            this.sceneNameTableView.getElement().addClass('table-view-wrapper__table-view');
            this.appendSubview(this.sceneNameTableView, this.elements.tableViewWrapper).then(function () {
                return this.sceneNameTableView.reload();
            }.bind(this)); // controlList

            this.controlList = new GUI.ControlListTableView(this, this);
            this.controlList.getElement().addClass('table-view-wrapper__table-view');
            this.appendSubview(this.controlList, this.elements.tableViewWrapper).then(function () {
                return this.controlList.reload();
            }.bind(this)); // deleteScene

            this.deleteSceneTableView = new GUI.TableViewV2(this, this);
            this.deleteSceneTableView.getElement().addClass('table-view-wrapper__table-view');
            this.appendSubview(this.deleteSceneTableView, this.elements.tableViewWrapper).then(function () {
                return this.deleteSceneTableView.reload();
            }.bind(this));
            return promise;
        }

        titleBarActionRight() {
            this._sendChanges();
        }

        getURL() {
            return "EditScene";
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            return _("controls.lightcontroller.scene.edit");
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.CLOSE,
                rightSide: TitleBarCfg.Button.TICK
            };
        }

        receivedStates(states) {
            if (states.activeSceneNr > 0 && states.activeSceneNr < 9) {
                this.activeSceneNr = states.activeSceneNr;
                this.sceneName = states.scenes[this.activeSceneNr].regexFilter(Regex.NAME_FILTER);
                this.sceneNameTableView.reload();
            }
        }

        // TableViewDataSource methods
        titleForHeaderInSection(section, tableView) {
            if (tableView === this.sceneNameTableView && section === 0) {
                return _("controls.lightcontroller.scene.scene-name").toUpperCase();
            }
        }

        groupForHeaderInSection(section) {
            return {
                name: _("controls.lightcontroller.scene.light-source").toUpperCase()
            };
        }

        styleForTableView(tableView) {
            return TableViewStyle.GROUPED;
        }

        numberOfSections() {
            return 1;
        }

        numberOfRowsInSection(section, tableView) {
            if (tableView === this.controlList) {
                return this.controls.length;
            } else {
                return 1;
            }
        }

        cellTypeForCellAtIndex(section, row, tableView) {
            if (tableView === this.sceneNameTableView) {
                return GUI.TableViewV2.CellType.INPUT;
            } else if (tableView === this.deleteSceneTableView) {
                return GUI.TableViewV2.CellType.DELETE;
            }
        }

        controlForCellAtIndex(section, row) {
            return this.controls[row];
        }

        contentForCell(cell, section, row, tableView) {
            if (tableView === this.sceneNameTableView) {
                return {
                    value: this.sceneName,
                    placeholder: _("controls.lightcontroller.scene.save.label"),
                    validationRegex: Regex.NAME,
                    // disallow , and =
                    filterRegex: Regex.NAME_FILTER,
                    showGreen: false
                };
            } else if (tableView === this.deleteSceneTableView) {
                return {
                    title: _("controls.lightcontroller.scene.delete")
                };
            }
        }

        // TableViewDelegate methods
        didSelectCell(cell, section, row, tableView) {
            if (tableView === this.deleteSceneTableView) {
                this.sendCommand(Commands.format(Commands.LIGHTCONTROL.DELETE_SCENE, this.activeSceneNr));
                this.ViewController.navigateBack();
            }
        }

        textChanged(section, row, tableView, value, valid) {
            if (valid) {
                this.sceneName = value;
            } else {
                this.sceneName = null;
            }
        }

        // Private methods
        _sendChanges() {
            if (this.sceneName) {
                this.sendCommand(Commands.format(Commands.LIGHTCONTROL.ADD_AND_RENAME_SCENE, this.activeSceneNr, this.sceneName));
                this.ViewController.navigateBack();
            }
        }

    };
});
