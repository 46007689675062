'use strict';

window.GUI = function (GUI) {
    class LxVirtualKeyboard {
        constructor() {
            this.name = "LxVirtualKeyboard ";
            this.isEnabled = false;
            this._isShifted = false;
            this._isVisible = false;
            this._activeInput = null;
            this._inputElements = [];
            this._inputSelectors = [];
            this._mutationObserver = new MutationObserver(this._onDOMMutation.bind(this)); // Automatically enable the virtual keyboard if the process argument --virtKbrd is set on electron

            if (!!window.electron) {
                this.setEnabled(window.electron.remote.argv.indexOf("--virtKbrd") !== -1);
            }
        }

        setEnabled(enabled) {
            if (enabled) {
                this._mutationObserver.observe(document.querySelector("body"), {
                    childList: true,
                    subtree: true
                });

                this._onDOMMutation();
            } else {
                this._mutationObserver.disconnect();

                this.hide();
            }

            this.isEnabled = enabled;
        }

        show() {
            if (!this._isVisible) {
                if (this.hideAnimation) {
                    clearTimeout(this.hideAnimation);
                    this.hideAnimation = null;
                }

                this._virtualKeyboardElm = $("<div class='simple-keyboard' />");
                $(document.body).append(this._virtualKeyboardElm);
                this._virtualKeyboard = new window.SimpleKeyboard.default(this._getVirtualKeyboardConfig());
                $(document.body).addClass("show-virtual-keyboard");
                this._isVisible = !this._isVisible;
            }
        }

        hide() {
            if (this._isVisible) {
                $(document.body).removeClass("show-virtual-keyboard");
                this._isVisible = !this._isVisible;
                this.hideAnimation = setTimeout(function () {
                    this._virtualKeyboardElm.remove();

                    this._virtualKeyboardElm = null;
                    this._virtualKeyboard = null;
                    this.hideAnimation = null;
                }.bind(this), 150); // Wait until the CSS animation has been finished!
            }
        }

        _onDOMMutation(mutations) {
            var inputElements = $.find("input"),
                inputSelectors = inputElements.map(this._getSelector);

            if (JSON.stringify(inputSelectors) !== JSON.stringify(this._inputSelectors)) {
                // Input elements have been changed! Check which have been added and removed
                var removedInputs = this._inputElements.filter(function (input) {
                    return !inputElements.includes(input);
                });

                var hasActiveInputBeenRemoved = removedInputs.find(function (input) {
                    return this._activeInput && input === this._activeInput[0];
                }.bind(this)) || false;

                if (hasActiveInputBeenRemoved) {
                    this._activeInput = null;
                    this.hide();
                }

                inputElements.forEach(function (input) {
                    var jqIp = $(input);
                    jqIp.on("focusin focus", function (e) {
                        this._activeInput = $(e.target);
                        this.show();
                    }.bind(this));
                }.bind(this));
                this._inputElements = inputElements;
                this._inputSelectors = inputSelectors;
            }
        }

        /**
         * Returns a keyboard configuration according to this documentation: https://franciscohodge.com/projects/simple-keyboard/documentation/
         * @returns {{onKeyPress: _handleVirtualKeyboardPress}}
         * @private
         */
        _getVirtualKeyboardConfig() {
            return {
                onKeyPress: this._handleVirtualKeyboardPress.bind(this),
                physicalKeyboardHighlight: true,
                layout: this._getVirtualKeyboardLayoutForCurrentLanguage(),
                mergeDisplay: true,
                display: {
                    "{bksp}": "⟵",
                    "{enter}": "enter ↵",
                    "{shift}": "shift ⇧",
                    "{lock}": "caps ⇪",
                    "{tab}": "tab ⇥"
                }
            };
        }

        /**
         * Handles every key press event of the virtual keyboard
         * @param key
         * @private
         */
        _handleVirtualKeyboardPress(key) {
            switch (key) {
                case "{enter}":
                    this._activeInput.trigger(this._getKeyupEventForChar(13));

                    break;

                case "{bksp}":
                    this._activeInput.val(this._activeInput.val().slice(0, -1));

                    this._activeInput.trigger(this._getKeyupEventForChar(46));

                    break;

                case "{space}":
                    this._activeInput.val(this._activeInput.val() + " ");

                    this._activeInput.trigger(this._getKeyupEventForChar(" ".charCodeAt(0)));

                    break;

                case "{shift}":
                case "{lock}":
                    if (key === "{shift}") {
                        this._isShifted = !this._isShifted;
                    }

                    var currentLayout = this._virtualKeyboard.options.layoutName,
                        shiftToggle = currentLayout === "default" ? "shift" : "default";

                    this._virtualKeyboard.setOptions({
                        layoutName: shiftToggle
                    });

                    break;

                default:
                    // match any non special characters
                    if (!/{.*}/.test(key)) {
                        this._activeInput.val(this._activeInput.val() + key);

                        this._activeInput.trigger(this._getKeyupEventForChar(key.charCodeAt(0))); // Automatically Reset the Shift status after the first key press after a shift


                        if (this._isShifted) {
                            this._handleVirtualKeyboardPress("{shift}");
                        }
                    }

            }

            this._activeInput.trigger("input");
        }

        /**
         * Returns the keyboard layout for the current language or english if there is no specific layout available
         * @returns {*|layouts.en|{default, shift}}
         * @private
         */
        _getVirtualKeyboardLayoutForCurrentLanguage() {
            var currentLanguage = PlatformComponent.getLanguage().split("-")[0],
                // Only use the country specific part of the language
                layouts = {
                    de: {
                        'default': ["^ 1 2 3 4 5 6 7 8 9 0 \u00DF \u00B4 {bksp}", "{tab} q w e r t z u i o p \u00FC +", "{lock} a s d f g h j k l \u00F6 \u00E4 # {enter}", "{shift} < y x c v b n m , . - {shift}", '.com @ {space}'],
                        'shift': ["\u00B0 ! \" \u00A7 $ % & / ( ) = ? ` {bksp}", "{tab} Q W E R T Z U I O P \u00DC *", "{lock} A S D F G H J K L \u00D6 \u00C4 ' {enter}", "{shift} > Y X C V B N M ; : _ {shift}", '.com @ {space}']
                    },
                    en: {
                        'default': ['` 1 2 3 4 5 6 7 8 9 0 - = {bksp}', '{tab} q w e r t y u i o p [ ] \\', '{lock} a s d f g h j k l ; \' {enter}', '{shift} z x c v b n m , . / {shift}', '.com @ {space}'],
                        'shift': ['~ ! @ # $ % ^ & * ( ) _ + {bksp}', '{tab} Q W E R T Y U I O P { } |', '{lock} A S D F G H J K L : " {enter}', '{shift} Z X C V B N M < > ? {shift}', '.com @ {space}']
                    }
                };
            return layouts[currentLanguage] || layouts.en;
        }

        _getKeyupEventForChar(keyCode) {
            return $.Event('keyup', {
                keyCode: keyCode,
                which: keyCode
            });
        }

        _getSelector(node) {
            var selector = "";

            while (node.parentElement) {
                var siblings = Array.from(node.parentElement.children).filter(function (e) {
                    return e.tagName === node.tagName;
                });
                selector = siblings.indexOf(node) ? node.tagName + ":nth-of-type(" + siblings.indexOf(node) + 1 + ")" : node.tagName + (selector ? " > " : "") + selector;
                node = node.parentElement;
            }

            return "html > " + selector.toLowerCase();
        }

    }

    GUI.LxVirtualKeyboard = LxVirtualKeyboard;
    return GUI;
}(window.GUI || {});
