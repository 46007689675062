import {LxReactButton, LxReactComp} from "LxComponents";

export default class LxWrapperButton extends LxReactComp {

    /**
     * Returns a TouchableWithOpacity Component, that contains a text and icons left and right
     * @param {Function} props.onPress Handler for tap event
     * @param {String} props.text Text that should be displayed in the button
     * @param {Object} [props.containerStyle] For overriding the Touchable Component Style
     * @param {Boolean} [props.disabled = false] defines if the button is clickable or not
     */
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <LxReactButton text={this.props.text}
                           containerStyle={this.props.containerStyle}
                           onPress={this.props.onButtonTapped}
                           onPressOut={this.props.onButtonReleased}
                           onPressIn={this.props.onButtonHit}
            />
        )
    }
}
