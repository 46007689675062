'use strict';

define("AudioZoneV2NotReadyScreen", ["ControlActionCellsScreen", "AudioZoneV2ControlEnums"], function (ControlActionCellsScreen, AudioZoneV2ControlEnums) {
    return class AudioZoneV2NotReadyScreen extends ControlActionCellsScreen {
        //region Static
        static Template = function () {
            return {
                getAboutButton: function getAboutButton() {
                    return $('<div class="audio-zonev2-not-ready-screen__about-button">' + ImageBox.getResourceImageWithClasses(Icon.Buttons.INFO, "about-button__icon") + '</div>');
                }
            };
        }(); //endregion Static

        constructor(details) {
            super(...arguments);
            this.control = details.control;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(() => {
                this.buttons = this.buttons || {};
                this.buttons.about = new GUI.LxButton(this, AudioZoneV2NotReadyScreen.Template.getAboutButton(), Color.BUTTON_GLOW, null, true);
                return this.appendSubview(this.buttons.about);
            });
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(() => {
                this.buttons.about.onButtonTapped = this._showMoreInfo.bind(this);
            });
        }

        viewDidDisappear() {
            this.buttons.about.onButtonTapped = null;
            return super.viewDidDisappear(...arguments);
        }

        closeAction() {
            this.ViewController.dismiss();
        }

        shouldDisplayStateIcons() {
            var shouldShow = super.shouldDisplayStateIcons(...arguments);
            shouldShow &= this.control.getStates().universalIsLocked;
            return shouldShow;
        }

        handleModalBackgroundTapped() {
            return this.closeAction();
        }

        getAnimation() {
            return AnimationType.HD_MODAL_FULLSCREEN;
        }

        setSubtitle(subtitle) {
            if (this.control.getStates().universalIsLocked) {
                super.setSubtitle(...arguments);
            } else {
                // no subtitle needs to be set if not locked.
                super.setSubtitle(null);
            }
        }

        _shouldShowLinkedControls() {
            return false;
        }
        
        receivedStates(states) {
            // don't forward to base, no need for any other actions. ctxt menu and messagecenter handled in here
            this.element.toggleClass("audio-zonev2-not-ready-screen--locked", states.universalIsLocked);
            this.bgView && this.toggleSubview(this.bgView, !states.universalIsLocked);

            if (states.universalIsLocked) {// nothing to be done
            } else if (!this.currentCanPlay || this.currentCanPlay !== states.canPlayMusic) {
                this.currentCanPlay = states.canPlayMusic;
                this.setBackgroundView(states);
            }

            this.updateContextMenu(states); // Only handle the MessageCenter if the control is able to send messages to the MessageCenter hence states exists

            if (this.control.states && this.shouldShowMessageCenterButton()) {
                this._updateMessageCenterButton(states);
            }
        }

        setBackgroundView(states) {
            var bgContent = {
                icon: null, title: null, message: null, buttonText: null, buttonAction: null
            };

            if (states.isLocked) {
                bgContent.iconSrc = Icon.LOCK;
                bgContent.title = _("operation-blocked");
                bgContent.message = _("audio-server.operation-blocked");
            } else {
                bgContent.iconSrc = Icon.INDICATOR;
                bgContent.title = states.texts.connectivityTextShort || "";
                bgContent.message = states.texts.connectivityText || "";

                if (this._shouldShowStartMusicServer(states)) {
                    bgContent.buttonText = _("luring.start.now");
                    bgContent.buttonAction = this._startMusicServer.bind(this);
                }

                if (states.messageCenterEntries && states.messageCenterEntries.length > 0) {
                    this._adoptBgContentForMessageCenter(bgContent, states.messageCenterEntries);
                }
            }

            if (this.bgView) {
                this.bgView.update(bgContent);
            } else {
                this.bgView = new GUI.BackgroundView(bgContent.iconSrc, bgContent.title, bgContent.message, bgContent.buttonText, bgContent.buttonAction, true, false, bgContent.iconFillColor);
                this.insertSubviewBefore(this.bgView, this.actionTableView.getElement());
                this.bgView.getElement().addClass("action-view__background-view");
            }
        }

        _adoptBgContentForMessageCenter(bgContent, entries) {
            var prominentEntry = entries[0];

            if (prominentEntry.severity >= MessageCenterHelper.SEVERITY_LEVEL.IMPORTANT) {
                bgContent.iconSrc = MessageCenterHelper.getIconForSeverityEntry(prominentEntry, true, true);
                bgContent.iconFillColor = MessageCenterHelper.getColorForSeverityEntry(prominentEntry);
                bgContent.noCircle = true;
                bgContent.buttonText = _("show-more-of-text");
                bgContent.buttonAction = this.openMessageCenterEntry.bind(this);
            }
        }

        _startMusicServer() {
            this.control.sendCommand(Commands.AudioZone.SERVER.ON);
        }

        _shouldShowStartMusicServer(states) {
            return states.serverState === MusicServerEnum.ServerState.OFFLINE;
        }

        _showMoreInfo() {
            var target = AudioZoneV2ControlEnums.ScreenState.SETTINGS.ABOUT,
                detail = {
                    control: this.control
                };
            this.ViewController.showState(target, null, detail, AnimationType.MODAL);
        }

    }
});

