'use strict';

class AlexaOnboardingScreen extends GUI.OnboardingScreen {
    constructor(details) {
        super($("<div />"));
        this.id = details.id;
        this.iconSrc = details.iconSrc;
        this.iconColor = details.iconColor;
        this.title = details.title;
        this.message = details.message;
        this.message2 = details.message2;
        this.continueBtnText = details.continueBtnText;
        this.continueDef = details.continueDef;
        this.bottomIcon = details.bottomIcon;
        this.bottomText = details.bottomText;
        this.shareButtonText = details.shareButtonText;
        this.textButtonText = details.textButtonText;
        this.rightButtonSrc = details.rightButtonSrc;
        this.rightButtonAction = details.rightButtonAction;
        this._hasErrorText = details.hasErrorText;
    }

    viewDidLoad() {
        return Q(super.viewDidLoad(...arguments)).then(function () {
            if (this.textButtonText) {
                this.textButton = new GUI.LxButton(this, '<div class="screen__text-button">' + this.textButtonText + '</div>');
                this.insertSubviewAfter(this.textButton, this.elements.subtitleLbl2);

                this.textButton.onButtonTapped = function () {
                    var link = "https://www.loxone.com";
                    NavigationComp.openWebsite(link);
                }.bind(this);
            }

            if (this.bottomText) {
                this.bottomText = $("<div class='screen__bottom-text" + (this._hasErrorText ? " invalid'" : "'") + ">" + this.bottomText + "</div>");
                GUI.animationHandler.insertAfter(this.bottomText, this.elements.subtitleLbl2);
            }

            if (this.bottomIcon) {
                var bottomIcon = $('<div class="screen__bottom-icon">' + ImageBox.getResourceImageWithClasses(this.bottomIcon, "state-icons__bg") + '</div>');

                if (this.bottomIcon.endsWith(".png")) {
                    bottomIcon = $("<img class='screen__bottom-png' src=" + this.bottomIcon + ">");
                }

                GUI.animationHandler.insertAfter(bottomIcon, this.elements.subtitleLbl2);
            }

            if (this.shareButtonText) {
                this.shareBtn = new GUI.LxButton(this, $("<div class='screen__share-button'>" + this.shareButtonText + "</div>"));
                this.insertSubviewAfter(this.shareBtn, this.elements.subtitleLbl2);

                this.shareBtn.onButtonTapped = function () {
                    console.log("not implemented");
                }.bind(this);
            }
        }.bind(this));
    }

    closeAction() {
        this.ViewController.closeAction();
    }

    getAnimation() {
        return AnimationType.NONE;
    }

    continueBtnAction() {
        var callbackDefer = Q.defer(),
            waitingPromise;
        waitingPromise = ActiveMSComponent.isAlexaAddonCreated().then(function (response) {
            if (response.created) {
                return ActiveMSComponent.logoutAlexaUser();
            } else {
                return ActiveMSComponent.createAlexaPlugin();
            }
        }.bind(this));
        this.ViewController.showState(ScreenState.WaitingScreen, null, {
            cancelButtonText: _('cancel'),
            message: _('home-kit.loading.message.setup'),
            callbackDefer: callbackDefer,
            waitingPromise: waitingPromise,
            noBackNavigationOnPromiseRejection: true
        });
        callbackDefer.promise.then(function (screenState) {
            return ActiveMSComponent.startAuthFlow().then(function (res) {
                return this.ViewController.showState(ScreenState.AlexaSetupScreen, null, {
                    iconSrc: Icon.ALEXA.APP,
                    iconColor: Color.ALEXA_BLUE,
                    title: _('alexa.setup.title'),
                    message: _('alexa.setup.message'),
                    awsCodeChallenge: res.codeChallenge,
                    continueBtnText: _('continue')
                });
            }.bind(this), function (err) {
                console.warn("Alexa onboarding screen, error while creating the plugin --> " + JSON.stringify(err));
                /*if (getLxResponseCode(err) === 408) {
                    NavigationComp.showPopup()
                }*/
            }.bind(this));
        }.bind(this), function (error) {
            this.closeAction(error);
        }.bind(this));
    }

}

GUI.AlexaOnboardingScreen = AlexaOnboardingScreen;
