import {useFocusEffect} from "@react-navigation/native";
import {useCallback} from "react";

//Purpose: executes special functionalities for central controls like example resetting the selection of sub controls
function useCentralControlFeatures(control) {
    useFocusEffect(useCallback(() => {
        return () => {
            if (control.isGrouped()) {
                // reset selection of subcontrols when leaving screen
                control.updateGroupSelection();
            }
        }
    }, []));

    return control && control.isGrouped();
}

export default useCentralControlFeatures;
