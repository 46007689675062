import {LxReactTextView} from "LxComponents";
import {useMemo} from "react";
import {useRequest} from "ahooks";
import Icons from "IconLib";
import globalStyles from "GlobalStyles";

export default function SearchBar({ title = _("device-learning.filter.title"), style, onSubmitEditing, textChanged, debounceWait = 250 }) {

    const { leftIcon, leftIconStyle, placeholder } = useMemo(() => {
        return {
            leftIcon: Icons.Search,
            leftIconStyle: Styles.leftSearchIcon,
            placeholder: title
        }
    }, []);
    const {run} = useRequest(textChanged, {debounceWait, manual: true})

    return <LxReactTextView
        textChanged={run}
        onSubmitEditing={onSubmitEditing}
        placeholder={placeholder}
        leftIcon={leftIcon}
        leftIconStyle={leftIconStyle}
        alignment={"left"}
        showClearIcon={true}
        containerStyle={{
            height: 35 - 16
        }}
        style={[Styles.searchContainer, style]}
    />
}


const Styles = {
    searchContainer: {
        backgroundColor: globalStyles.colors.grey["300a36"],
        borderRadius: 6,
        marginHorizontal: 16,
        height: 35,
        width: "auto",
        padding: 8
    },
    leftSearchIcon: {
        height: 24,
        width: 24,
        fill: globalStyles.colors.placeholder
    }
}
