import * as React from "react"
import Svg, { Path } from "react-native-svg"

function BrightnessChange(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M12 2a1 1 0 100-2 1 1 0 000 2zM19.071 4.929a1 1 0 101.414-1.414 1 1 0 00-1.414 1.414zM23 13a1 1 0 110-2 1 1 0 010 2zM19.071 19.071a1 1 0 101.414 1.414 1 1 0 00-1.414-1.414zM11 23a1 1 0 112 0 1 1 0 01-2 0zM4.929 19.071a1 1 0 10-1.414 1.414 1 1 0 001.414-1.414zM1 11a1 1 0 110 2 1 1 0 010-2zM4.929 4.929a1 1 0 10-1.414-1.414 1 1 0 001.414 1.414z"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 12a8 8 0 11-16 0 8 8 0 0116 0zm-3.363 3.807a7 7 0 01-8.445-8.445 6 6 0 108.445 8.445z"
            />
        </Svg>
    )
}

export default BrightnessChange
