'use strict';

define(['AudioZoneControlEnums', 'AudioZoneTableViewHelper', 'CustomizationHelper'], function (AudioZoneControlEnums, AudioZoneTableViewHelper, CustomizationHelper) {
    class AudioZoneControlAppearanceEditScreen extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            this.details = details;
            this.control = details.control;
            this.currentTableContent = details.currentTableContent;
        }

        getTableView() {
            return new GUI.EditableTableView(this.tableViewDataSource, this.tableViewDelegate);
        }

        viewDidLoad() {
            var args = arguments;
            this.customizationHelper = new CustomizationHelper();
            return this.customizationHelper.getSavedSettings(this.control.uuidAction).then(res => {
                this.currentRating = res;
                return super.viewDidLoad(...args);
            });
        }

        viewWillAppear() {
            super.viewWillAppear(arguments);
            this.tableView.setEditingModeForSection(1, true, false, true);
            this.tableView.setEditingModeForSection(2, true, false, true);
        }

        destroy() {
            this.customizationHelper.saveRating(this.currentRating);
            super.destroy(...arguments);
        }

        titleBarText() {
            return _('media.display-settings.title');
        }

        reloadTable() {
            this.tableContent = [{
                headerTitle: '',
                headerDescription: _("media.display-settings.desc")
            }, this._getFirstSortAbleSection(), this._getSecondSortAbleSection()];
            return super.reloadTable(...arguments);
        }

        didMoveCell(section, row, tableView, cell, oldIdx, newIdx) {
            if (section === 1) {
                this.tableContent[section].rows[oldIdx].content.rating = newIdx;
                this.tableContent[section].rows[newIdx].content.rating = oldIdx;
            } else if (section === 2) {
                this.tableContent[section].rows[oldIdx].content.rating = newIdx + 2;
                this.tableContent[section].rows[newIdx].content.rating = oldIdx + 2;
            }

            this.currentRating[this.tableContent[section].rows[oldIdx].content.identifier] = this.tableContent[section].rows[oldIdx].content.rating;
            this.currentRating[this.tableContent[section].rows[newIdx].content.identifier] = this.tableContent[section].rows[newIdx].content.rating;
            this.tableContent[section].rows.move(oldIdx, newIdx);
        }

        _getFirstSortAbleSection() {
            var section = {
                rows: []
            };
            this.currentTableContent.forEach(function (savedSection) {
                switch (savedSection.identifier) {
                    case MediaEnum.ControlContentIdentifiers.ZONE_FAVORITES:
                    case MediaEnum.ControlContentIdentifiers.SHORTCUTS:
                        section.rows.push({
                            type: GUI.TableViewV2.CellType.CHECKABLE,
                            content: {
                                title: savedSection.headerTitle,
                                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE,
                                selected: savedSection.rating >= MediaEnum.RATING.DEFAULT,
                                identifier: savedSection.identifier,
                                rating: savedSection.rating
                            },
                            didCheckCell: function (cell, section, row, tableView, selected) {
                                this.tableContent[section].rows[row].content.rating = selected ? MediaEnum.RATING.DEFAULT : MediaEnum.RATING.HIDDEN;
                                this.currentRating[this.tableContent[section].rows[row].content.identifier] = this.tableContent[section].rows[row].content.rating;
                            }.bind(this)
                        });
                }
            }.bind(this));
            return section;
        }

        _getSecondSortAbleSection() {
            var section = {
                rows: []
            };
            this.currentTableContent.forEach(function (savedSection) {
                switch (savedSection.identifier) {
                    case MediaEnum.ControlContentIdentifiers.LOXONE:
                        section.rows.push({
                            content: {
                                title: savedSection.headerTitle,
                                rightIconSrc: Icon.SETTINGS_SMALL,
                                clickable: true,
                                forceSelection: true,
                                identifier: savedSection.identifier,
                                rating: savedSection.rating
                            },
                            action: function (cell, sectionIdx, rowIdx, tableView) {
                                var selectedSection = this.currentTableContent.find(function (sec) {
                                    return sec.identifier === this.tableContent[sectionIdx].rows[rowIdx].content.identifier;
                                }.bind(this));

                                this._handleOnSectionSettings(selectedSection);
                            }.bind(this)
                        });
                        break;

                    case MediaEnum.ControlContentIdentifiers.SPOTIFY:
                    case MediaEnum.ControlContentIdentifiers.TUNE_IN:
                        section.rows.push({
                            content: {
                                title: savedSection.headerTitle,
                                identifier: savedSection.identifier,
                                rating: savedSection.rating
                            }
                        });
                }
            }.bind(this));
            return section;
        }

        _handleOnSectionSettings(section) {
            var def = Q.defer(),
                details = {
                    options: section.rows.map(function (row) {
                        return {
                            title: row.content.title,
                            clickable: true,
                            identifier: row.content.identifier,
                            selected: this.currentRating[row.content.identifier]
                        };
                    }.bind(this)),
                    title: section.headerTitle,
                    headerTitle: "",
                    headerDescription: _("media.display-settings.loxone.help"),
                    mode: GUI.SelectorScreenMode.NORMAL,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE,
                    deferred: def
                }; // First set every item in the section to false (hidden)

            section.rows.forEach(function (row) {
                this.currentRating[row.content.identifier] = false;
            }.bind(this));
            def.promise.then(function (results) {
                // Then pick out the enabled once and set them to true (shown)
                results.forEach(function (result) {
                    this.currentRating[result.option.identifier] = true;
                }.bind(this));
            }.bind(this));
            this.ViewController.showState(ScreenState.SelectorScreen, null, details);
        }

    }

    Controls.AudioZoneControl.AudioZoneControlAppearanceEditScreen = AudioZoneControlAppearanceEditScreen;
});
