'use strict';

define([], function () {
    return class DaytimerControlContentDefaultValue extends Controls.ControlContent {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return '<div class="default-value__invalid-value-info">' + _("controls.daytimer.entry.value.invalid") + '</div>';
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(details) {
            super(details);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.tableView = new GUI.TableViewV2(this, this);
            this.appendSubview(this.tableView);
            this.elements = {
                invalidValueInfo: $(DaytimerControlContentDefaultValue.Template.getTemplate()).hide()
            };
            this.element.append(this.elements.invalidValueInfo);
        }

        getURL() {
            return "DefaultValue";
        }

        getAnimation() {
            return AnimationType.HD_OVERLAY
        }

        titleBarText() {
            return _("controls.daytimer.default-value");
        }

        titleBarAction() {
            this.ViewController.navigateBack();
        }

        titleBarActionRight() {
            this._sendDefaultValue();
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK,
                rightSide: TitleBarCfg.Button.TICK,
                style: 'transparent'
            };
        }

        receivedStates(states) {
            // Attention: We can receive states with the same value eg. when a timer is active
            // So only update if the value changed
            var tmpValue = this.currentValue;
            this.currentValue = states.defaultValue;

            if (this.currentValue !== tmpValue) {
                this.currentValue = states.defaultValue;
                this.tableView.reload();
            }
        }

        // Private functions
        _sendDefaultValue() {
            this.sendCommand(Commands.format(Commands.DAYTIMER.DEFAULT, this.currentValue));
            this.ViewController.navigateBack();
        }

        // TableViewDataSource methods
        numberOfSections() {
            return 1;
        }

        styleForTableView() {
            return TableViewStyle.GROUPED;
        }

        numberOfRowsInSection() {
            return 1;
        }

        cellTypeForCellAtIndex() {
            return GUI.TableViewV2.CellType.INPUT;
        }

        contentForCell() {
            return {
                leftIconSrc: 'resources/Images/Controls/Daytimer/target.svg',
                title: _("controls.daytimer.entry.value"),
                value: this.currentValue,
                autoFocus: true,
                validationRegex: Regex.DECIMAL_VALUE
            };
        }

        // TableViewDelegate Methods
        textChanged(section, row, tableView, value, valid) {
            if (valid) {
                this.elements.invalidValueInfo.hide();
                value = value.replace(/,/, ".");
                this.currentValue = parseFloat(value);
            } else {
                this.elements.invalidValueInfo.show();
            }
        }

    };
});
