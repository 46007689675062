const graphWhiteSpace = 0.05; // added padding around the graph values, so the bars or lines don't touch the border
const barWidth = 0.5547814207;
const barMaxWidthMultiplier = 0.04371584699;

const labelWidthPercent = 0.0520675917; // empty space on the right of the graph for label placement (usually kWh)
const rightScaleWidthPercent = 0.1; // width of the area for the labels on the right
const drawableAreaWidthPercent = 1 - labelWidthPercent - rightScaleWidthPercent; // available width for actual graph

const bottomScaleHeightPercent = 0.09; // height of are below the graph for bottom labels (usually times)
const drawableAreaHeightPercent = 1 - bottomScaleHeightPercent; // available height for actual graph

const numVerticalSeparations = 4;

const bgLineStroke = { strokeWidth: 1, stroke: 'rgba(44, 44, 46, 1)' };
const bgLineStrokeThick = { strokeWidth: 2, stroke: 'rgba(44, 44, 46, 1)' };

export {
    graphWhiteSpace,
    barWidth,
    barMaxWidthMultiplier,
    labelWidthPercent,
    rightScaleWidthPercent,
    drawableAreaWidthPercent,
    bottomScaleHeightPercent,
    drawableAreaHeightPercent,
    numVerticalSeparations,
    bgLineStroke,
    bgLineStrokeThick,
}
