import {View} from "react-native";
import {useCallback, useEffect, useRef} from "react";
import {useFocusEffect} from "@react-navigation/native";
import { Animated, Easing } from "react-native";
import {LxReactText} from "../Components";
import globalStyles from "GlobalStyles";
import usePairedIdentify from "./usePairedAppIdentify";
import ScreenBrightness from "../../PairedAppComp/helper/ScreenBrightness";


export default function PairedAppIdentify({navigation, route}) {
    const pairedAppInfos = usePairedIdentify(route.params);

    const initial = 0,
        duration= 750;
    const whiteOpacity = useRef(new Animated.Value(initial)).current;

    useFocusEffect(useCallback(() => {
        Animated.loop(
            Animated.sequence([
                Animated.timing(whiteOpacity, {
                    delay: 250,
                    toValue: 0.95,
                    easing: Easing.inOut(Easing.ease),
                    useNativeDriver: true,
                    duration: duration
                }),
                Animated.timing(whiteOpacity, {
                    delay: 250,
                    toValue: initial,
                    easing: Easing.inOut(Easing.ease),
                    useNativeDriver: true,
                    duration: duration
                })
            ]),
            {
                useNativeDriver: true
            }
        ).start()
    }, []))

    // set to -100 to indicate it hasn't been requested et.
    const initialBrightnessRef = useRef(BrightnessIndicators.NOT_REQUESTED);

    useEffect(() => {
        if (!pairedAppInfos.active || !pairedAppInfos.visual) {
            initialBrightnessRef.current = BrightnessIndicators.NOT_REQUESTED; // reset to ensure it's grabbed again
            navigation.goBack();
        }
    }, [pairedAppInfos]);

    useEffect(() => {
        let isRendered = true;
        if (initialBrightnessRef.current === BrightnessIndicators.NOT_REQUESTED) {
            initialBrightnessRef.current = BrightnessIndicators.REQUESTED; // requesting
            ScreenBrightness.get().then((currBrightness) => {
                if (isRendered) {
                    initialBrightnessRef.current = currBrightness;
                    ScreenBrightness.set(BrightnessIndicators.IDENTIFY_BRIGHTNESS);
                }
            });
        }


        return () => {
            isRendered = false;
            if (initialBrightnessRef.current > BrightnessIndicators.NOT_REQUESTED) {
                ScreenBrightness.set(initialBrightnessRef.current);
            }
        }
    })

    return <View style={styles.rootCntr}>
        <Animated.View style={{
            ...styles.flashingOverlay,
            opacity: whiteOpacity
        }} />
        <LxReactText style={styles.identifyText}>{"IDENTIFY".toUpperCase()}</LxReactText>
    </View>
}

const BrightnessIndicators = {
    NOT_REQUESTED: -100,
    REQUESTED: -50,
    IDENTIFY_BRIGHTNESS: 100
}

const styles = {
    rootCntr: {
        flex: 1,
        width: "100%",
        height: "100%"
    },
    identifyText: {
        ...globalStyles.textStyles.pageHeading.bold,
        color: globalStyles.colors.text.primary,
        textAlign: "center",
        margin: "auto",
        fontSize: 131,
        fontWeight: 800,
        lineHeight: 202
    },
    flashingOverlay: {
        opacity: 0,
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "#E6E6E6"
    }
}