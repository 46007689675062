'use strict';

define(["ControlInfoView"], function (ControlInfoView) {
    return class UniversalControlInfoView extends ControlInfoView {
        constructor(control, stateKey, stateValue) {
            super(control);
            this.stateKey = stateKey;
            this.stateValue = stateValue;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);

            this._setValue();
        }

        receivedStates(states) {
            // Don't set the color and value of the states if we want to display states, hence we have a stateKey
            // E.g: this.stateKey won't exist in states if the user is looking at a detail value, its a detail key
            if (states.hasOwnProperty(this.stateKey)) {
                this.stateValue = states[this.stateKey].value;

                if (this.stateValue !== undefined) {
                    // New states will flash green
                    if (states.newStates.indexOf(this.stateKey) > -1) {
                        this.element.css("background-color", window.Styles.colors.stateActive);
                    } else {
                        this.element.css("background-color", "rgba(255,255,255,0.2)");
                    }

                    this._setValue();
                }
            }
        }

        _setValue() {
            var formatedNode = '';
            this.element.html(""); // Try to format the JSON, display the unformated value if we can't parse the value

            try {
                formatedNode = '<div class="universal-control-info-view__formated-container formated-container--json">' + JSON.stringify(JSON.parse(this.stateValue), null, 4) + '</div>';
            } catch (e) {
                formatedNode = '<div class="universal-control-info-view__formated-container">' + this.stateValue + '</div>';
            }

            this.element.append(formatedNode);
        }

    };
});
