'use strict';
/*
 title
 selected
 disclosureIcon
 [activeTextColor]
 [activeIconBGColor]
 [radioMode]
 [checkedIcon]          Icon that is shown when the cell is selected. Filled using activeIconBGColor
 [uncheckedIcon]        Icon that is shown when the cell is not selected.

 didRequestCheckingCell(cell, section, row, tableView)      -> return a Promise or true!
 didCheckCell(cell, section, row, tableView, selected)
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.CHECKABLE = "CheckableCell";
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var PLACEHOLDER_CLASS = "check-icon-placeholder__icon";
        var ACTIVE_ICON_CLASS = "check-icon-placeholder__icon--active";
        var PASSIVE_ICON_CLASS = "check-icon-placeholder__icon--passive";
        var CUSTOM_ICON_CLASS = "check-icon-placeholder__icon--custom";

        class CheckableCell extends GUI.TableViewV2.Cells.GeneralCell {
            //region Static
            static Template = function () {
                var getLeftIcon = function getLeftIcon(content) {
                    return '<div class="content__check-icon-placeholder"></div>';
                };

                return {
                    getLeftIcon: getLeftIcon
                };
            }(); //endregion Static

            constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
                super(...arguments);
                this.element.addClass("checkable-cell");
            }

            viewDidLoad() {
                var promises = [super.viewDidLoad(...arguments)];

                this._verifyCheckIcons(this.content);

                var leftIcon = CheckableCell.Template.getLeftIcon(this.content);
                promises.push(GUI.animationHandler.prepend(leftIcon, this.element.find('.cell__content')));
                return Q.all(promises).then(function () {
                    this.elements.checkIconCntr = this.element.find('.content__check-icon-placeholder');
                    this.elements.checkIcon = this.element.find('.check-icon-placeholder__icon');
                    this.elements.checked = this.element.find('.check-icon-placeholder__icon--active');
                    this.elements.unchecked = this.element.find('.check-icon-placeholder__icon--passive');
                    this.updateContent(this.content);
                }.bind(this));
            }

            viewDidAppear() {
                var baseCall = super.viewDidAppear(...arguments);

                if (this.content.disclosureIcon) {
                    this.checkHandler = Hammer(this.elements.checkIconCntr[0]).on(tapEvent(), function (e) {
                        stopEventPropagation(e);
                        this.onSelected(null, true);
                    }.bind(this));
                }

                return baseCall;
            }

            viewWillDisappear() {
                if (this.content.disclosureIcon) {
                    this.checkHandler.dispose();
                }

                return super.viewWillDisappear(...arguments);
            }

            // ---------------------------------------------------------------------------------------------------------
            //   Methods for super fast tableContent updates
            // ---------------------------------------------------------------------------------------------------------
            cellTypeForReuse() {
                return GUI.TableViewV2.CellType.CHECKABLE;
            }

            updateContent(newContent) {
                return super._updateContent(...arguments);
            }

            /**
             * This Method is used to apply a new content object onto the cell instance. e.g. update texts and so on.
             * @param newContent
             * @private
             */
            _applyNewContent(newContent) {
                Debug.GUI.CheckableCell && console.log(this.viewId, "_applyNewContent: " + JSON.stringify(newContent)); // call verify first, as it'll ensure the proper icons are set.

                var promises = [super._applyNewContent(...arguments)];
                GUI.animationHandler.toggleCssClass(this.element, "checkable-cell--removable", !!this.removable);

                this._verifyCheckIcons(newContent);

                promises.push(this._updateCheckIcons(newContent));
                return Q.all(promises);
            }

            // ---------------------------------------------------------------------------------------------------------
            // ---------------------------------------------------------------------------------------------------------
            onSelected(event, forceSelection, forceBase) {
                if (!forceSelection && this.content.disclosureIcon || forceBase) {
                    // when the cell is clicked and there is a disclosureIcon, it's not treated as a checkChange.
                    super.onSelected(...arguments);
                } else if ((forceSelection || !this.content.disclosureIcon) && !this.content.disabled) {
                    // if the icon was selected directly - or if the cell has no disclosure icon and the cell was hit,
                    // handle it as regular checkChange
                    var setCellSelected = !this.content.selected;
                    var result = true;

                    if (this.delegate.didRequestCheckingCell) {
                        result = this.delegate.didRequestCheckingCell.call(this.delegate, this, this.sectionIdx, this.rowIdx, this.tableView);
                    } // Result will be undefined due to tableViewDelegate, it is defined, but does not return any promise
                    // undefined will lead to executed action


                    if (result === undefined) {
                        result = true;
                    }

                    Q.when(result).done(function (res) {
                        if (res) {
                            if (this.content.radioMode !== GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE) {
                                setCellSelected = true; // if radio mode is active, don't allow to deselect a cell

                                if (this.content.radioMode === GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED) {
                                    this._deselectCellsInSection(this.sectionIdx);
                                } else if (this.content.radioMode === GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE) {
                                    for (var sec = 0; sec < this.tableView.table.length; sec++) {
                                        this._deselectCellsInSection(sec);
                                    }
                                }
                            }

                            this.setChecked(setCellSelected, true, false);
                        }
                    }.bind(this), function () {
                        console.info("Promise said no to check this cell!");
                    });
                }
            }

            rippleAllowed() {
                return false; // no ripple in checkable cells.
            }

            clickableAllowed() {
                return true;
            }

            getDefaultSelectedIcon() {
                return Icon.CHECKED;
            }

            getDefaultDeselectedIcon() {
                return Icon.UNCHECKED;
            }

            // Public methods
            startEditMode(removable, sortable) {
                super.startEditMode(...arguments);

                if (removable) {
                    GUI.animationHandler.toggleCssClass(this.element, "checkable-cell--removable", !!removable);
                }
            }

            // Private methods
            setChecked(selected, wasTouchedCell, noHaptic) {
                var prevSelectedState = this.content.selected;
                this.content.selected = selected;

                this._adjustColors(selected); // easier to identify checkable cells, mostly for testflight


                this.element.toggleClass("checkable-cell--checked", !!this.content.selected);

                if (wasTouchedCell && this.delegate.didCheckCell) {
                    if (prevSelectedState !== selected && !noHaptic) {
                        HapticFeedback(HapticFeedback.STYLE.SELECT);
                    }

                    this.delegate.didCheckCell.call(this.delegate, this, this.sectionIdx, this.rowIdx, this.tableView, selected);
                }
            }

            _deselectCellsInSection(sectionIdx) {
                for (var row = 0; row < this.tableView.table[sectionIdx].length; row++) {
                    var cell = this.tableView.table[sectionIdx][row];

                    if (cell instanceof GUI.TableViewV2.Cells.CheckableCell) {
                        if (cell !== this) {
                            cell.setChecked.call(cell, false, false);
                        }
                    }
                }
            }

            _adjustColors(selected) {
                return GUI.animationHandler.schedule(function afAdjustColors() {
                    if (selected) {
                        this.content.activeTextColor && this.elements.titleLbl && this.elements.titleLbl.css("color", this.content.activeTextColor);
                        this.content.activeIconBGColor && this.elements.checkIcon && this.elements.checkIcon.css("fill", this.content.activeIconBGColor);
                        this.elements.unchecked.hide();
                        this.elements.checked.show();
                    } else {
                        this.content.activeTextColor && this.elements.titleLbl && this.elements.titleLbl.css("color", "");
                        this.content.activeIconBGColor && this.elements.checkIcon && this.elements.checkIcon.css("fill", "");
                        this.elements.checked.hide();
                        this.elements.unchecked.show();
                    }
                }.bind(this));
            }

            _verifyCheckIcons(content) {
                // ensure that both a checked and unchecked icon are configured. Don't modify the content!
                if (!content.hasOwnProperty("checkedIcon")) {
                    this._checkedIconSrc = this.getDefaultSelectedIcon();
                    this._customCheckedIcon = false;
                } else {
                    this._checkedIconSrc = content.checkedIcon;
                    this._customCheckedIcon = true;
                }

                if (!content.hasOwnProperty("uncheckedIcon")) {
                    this._uncheckedIconSrc = this.getDefaultDeselectedIcon();
                    this._customUncheckedIcon = false;
                } else {
                    this._uncheckedIconSrc = content.uncheckedIcon;
                    this._customUncheckedIcon = true;
                }
            }

            _updateCheckIcons(content) {
                var activeIcon = ImageBox.getResourceImageWithClasses(this._checkedIconSrc, PLACEHOLDER_CLASS + " " + ACTIVE_ICON_CLASS + " " + (!!this._customCheckedIcon ? CUSTOM_ICON_CLASS : ""));
                var passiveIcon = "";

                if (this._uncheckedIconSrc) {
                    passiveIcon = ImageBox.getResourceImageWithClasses(this._uncheckedIconSrc, PLACEHOLDER_CLASS + " " + PASSIVE_ICON_CLASS + " " + (!!this._customUncheckedIcon ? CUSTOM_ICON_CLASS : ""));
                }

                return GUI.animationHandler.schedule(function () {
                    this.elements.checkIconCntr.empty();
                    this.elements.checkIconCntr.append(activeIcon);
                    this.elements.checkIconCntr.append(passiveIcon);
                    this.elements.checked = this.elements.checkIconCntr.find('.check-icon-placeholder__icon--active');
                    this.elements.unchecked = this.elements.checkIconCntr.find('.check-icon-placeholder__icon--passive'); // adjust colors too

                    if (content.selected) {
                        this.content.activeTextColor && this.elements.titleLbl && this.elements.titleLbl.css("color", content.activeTextColor);
                        this.content.activeIconBGColor && this.elements.checkIcon && this.elements.checkIcon.css("fill", content.activeIconBGColor);
                        this.elements.unchecked.hide();
                        this.elements.checked.show();
                        this.element.addClass("checkable-cell--checked");
                    } else {
                        this.content.activeTextColor && this.elements.titleLbl && this.elements.titleLbl.css("color", "");
                        this.content.activeIconBGColor && this.elements.checkIcon && this.elements.checkIcon.css("fill", "");
                        this.elements.checked.hide();
                        this.elements.unchecked.show();
                        this.element.removeClass("checkable-cell--checked");
                    }
                }.bind(this));
            }

        }

        GUI.TableViewV2.Cells.CheckableCell = CheckableCell;
    }
    return GUI;
}(window.GUI || {});

GUI.TableViewV2.Cells.CheckableCell.RadioMode = {
    INACTIVE: "inactive",
    SECTIONED: "sectioned",
    TABLE: "table"
};
