// maxEntries is set to multiple of 31, because the maximum of vertical separations in the graph is 31
// this way, we reduce to a number that causes entries to better align with the grid

const modesArray = [
    StatisticV2Mode.EveryChangeMin,
    StatisticV2Mode.EveryChange,
    StatisticV2Mode.IntervalMin5,
    StatisticV2Mode.IntervalMin10,
    StatisticV2Mode.IntervalMin30,
    StatisticV2Mode.IntervalMin60,
    StatisticV2Mode.IntervalMin15
];
const timesArray = [
    5, // Every Minute Data is also averaged down to max 288, else the graph gets very fuzzy
    5,
    5,
    10,
    30,
    60,
    15
];

function removeDuplicates(dataToProcess) {
    const existingTs = [];
    dataToProcess = dataToProcess.filter(d => {
        if(existingTs.indexOf(d.ts) > -1) {
            return false;
        }
        existingTs.push(d.ts);
        return true;
    });
    return dataToProcess;
}

function forceDataIntoMode(dataToProcess, firstTs, lastTs, mode) {
    if(!Array.isArray(dataToProcess) || dataToProcess.length < 2) {
        return dataToProcess;
    }

    if(firstTs >= lastTs) {
        return dataToProcess;
    }

    dataToProcess = removeDuplicates(dataToProcess);

    const dataDepth = dataToProcess[0].values.length;
    let movingTs = firstTs;
    const timeJump = timesArray[modesArray.indexOf(mode)] * 60;

    const inputData = [].concat(dataToProcess);
    const reshaped = [];

    let lastValues = [];
    for(let i = 0; i < dataDepth; i++) {
        lastValues.push(0);
    }

    /**
     * Make sure the whole range from 00:00 -> 00:00
     * Time ranges always end at 23:59, without adding 1,
     * all data from 23:55 until 23:59 would be lost
     */
    while (movingTs <= (lastTs + 1)) {
        const datapointsInTimeRange = [];

        while(inputData.length > 0 && inputData[0].ts <= movingTs) {
            datapointsInTimeRange.push(inputData.shift());
        }

        const averaged = {
            ts: movingTs,
            values: [],
        }
        datapointsInTimeRange.forEach(datapoint => {
            datapoint.values.forEach((v, vIdx) => {
                if(!averaged.values[vIdx]) {averaged.values[vIdx] = 0}
                averaged.values[vIdx] += v;
            })
        })
        if(averaged.values.length === 0) {
            averaged.values = [].concat(lastValues);
        } else {
            averaged.values = averaged.values.map(v => v / datapointsInTimeRange.length);
            lastValues = [].concat(averaged.values);
        }
        reshaped.push(averaged)

        movingTs += timeJump;
    }
    
    return reshaped;
}

export {forceDataIntoMode};

export default (dataToProcess, maxEntries = 248) => {
    if(!Array.isArray(dataToProcess) || dataToProcess.length < 1) {
        return dataToProcess;
    }

    /**
     * Amha Andi
     * Before processing, make sure there are no duplicate entries for timestamps in the data.
     * Introduced, because Tobi Home Server sent a duplicate entry which skewed the Graph by
     * one and caused a spike at the very end of the graph.
     */
    const existingTs = [];
    dataToProcess = removeDuplicates(dataToProcess);

    if(dataToProcess.length > maxEntries) {
        const groupSize = Math.round(dataToProcess.length / maxEntries);
        const copy = [].concat(dataToProcess);
        dataToProcess = [];
        while(copy.length > 0) {
            const current = copy.splice(0, groupSize);
            const averaged = {
                ts: 0,
                values: [],
            }
            current.forEach(datapoint => {
                averaged.ts += datapoint.ts;
                datapoint.values.forEach((v, vIdx) => {
                    if(!averaged.values[vIdx]) {averaged.values[vIdx] = 0}
                    averaged.values[vIdx] += v;
                })
            })
            averaged.ts /= current.length
            averaged.values = averaged.values.map(v => v / current.length)
            dataToProcess.push(averaged)
        }
    }
    return dataToProcess;
}