import SwitchToggle from "react-native-switch-toggle";
import globalStyles from "GlobalStyles";
import {View} from "react-native";
import React, {useState, useRef, useEffect, useCallback, useContext} from "react";
import PropTypes from "prop-types";
import {useCCEvent, AmbientContext} from "LxComponents";

export default function LxReactSwitch(props) {
    const {isAmbientMode} = useContext(AmbientContext)
    const [value, setValue] = useState(!!props.value),
        ref = useRef();

    // will ensure that the switch returns to the value provided by the props. E.g. after AD/Scene recording.
    const handleCCEvent = useCallback((event, arg) => {
        props.stateDependent && setValue(!!props.value);
    }, []);
    useCCEvent([CCEvent.ForceStateUpdate], handleCCEvent);

    useEffect(() => {
        setValue(!!props.value);

        return () => {
        }
    }, [props.value])


    // To prevent the event from bubbling through we simply catch it here and hand it over to the Switch
    useEffect(() => {
        let hammerObj,
            handlers = [];
        if (ref.current) {
            hammerObj = Hammer(ref.current, GUI.TableViewV2.Cells.BaseCell.prototype._getHammerOptions());
            handlers.push(hammerObj.on(tapEvent(), e => {
                [
                    "preventDefault",
                    "stopPropagation",
                    "stopImmediatePropagation"
                ].forEach(prop => {
                    if (prop in e && typeof e[prop] === "function") {
                        e[prop]();
                    }
                })
            }));
        }

        return () => {
            handlers && handlers.forEach(handler => handler.dispose());
            hammerObj && hammerObj.dispose();
        }
    }, [ref.current])

    let trackColor = props.activeTrackColor || globalStyles.colors.stateActive,
        circleColor = globalStyles.colors.grey["200"];

    if (props.disabled) {
        trackColor = globalStyles.colors.grey["900"];
        circleColor = globalStyles.colors.grey["800"];
    }

    const onPress = useCallback(() => {
        if (props.disabled) {
            return;
        }
        HapticFeedback(HapticFeedback.STYLE.SELECT);
        let prms = Q();
        if (props.onValueChanged) {
            prms = Q(props.onValueChanged(!value));

        }
        prms.done(() => {
            setValue(currentValue => {
                return !currentValue;
            });
        }, e => {
            HapticFeedback(HapticFeedback.STYLE.ERROR);
            // Just do nothing
        });
    }, [value, props.disabled]);

    return (
        <View style={styles.container} ref={ref}>
            <SwitchToggle
                switchOn={value}
                onPress={onPress}
                duration={TOGGLE_DURATION}
                containerStyle={styles.track}
                circleStyle={styles.circle}
                backgroundColorOn={trackColor}
                backgroundColorOff={isAmbientMode || AMBIENT_MODE ? globalStyles.colors.grey["300a36"] : props.inactiveBackgroundColor || globalStyles.colors.grey["900"]}
                circleColorOn={circleColor}
                circleColorOff={circleColor}
                type={0}
            />
        </View>
    );
}

const styles = {
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%"
    },
    track: {
        width: 40,
        height: 40,
        borderRadius: 3,
        padding: 4,
    },
    circle: {
        width: 16,
        height: 30,
        borderRadius: 1,
        shadowRadius: 4,
        shadowColor: globalStyles.colors.black,
        shadowOpacity: 0.5,
        shadowOffset: {
            width: 0,
            height: 2
        }
    }
}

const TOGGLE_DURATION = 200;


LxReactSwitch.propTypes = {
    activeTrackColor: PropTypes.string,
    inactiveBackgroundColor: PropTypes.string,
    value: PropTypes.bool,
    onValueChanged: PropTypes.func,
    disabled: PropTypes.bool,
    isLegacy: PropTypes.bool
}
