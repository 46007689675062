'use strict';

class CardView extends GUI.CollectionViewV2 {
    constructor(dataSource, delegate, elem) {
        super(...arguments);
    }

    _getNumberOfRowsInSection(sectionIdx) {
        Debug.GUI.CardView && console.log(this.name, "_getNumberOfRowsInSection: " + sectionIdx);

        var numberOfCells = super._getNumberOfRowsInSection(sectionIdx); // a card view has no dummy cells!


        if (this.hasDummyCell[sectionIdx] !== false) {
            numberOfCells--;
            this.hasDummyCell[sectionIdx] = false;
        }

        return numberOfCells;
    }

}

GUI.CardView = CardView;
