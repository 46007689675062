'use strict';
/*
 This cell just adds the trust host with a different color to the title of the general cell
 user
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.USER_CELL = "UserCell";

    class UserCell extends GUI.TableViewV2.Cells.GeneralCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("user-cell");
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.setTitle(this.content.title);
            }.bind(this));
        }

        setContent(content) {
            content.title = content.user.name;
            return super.setContent(...arguments);
        }

        setTitle(value) {
            var titleHTML = value.sanitizeHTML();

            if (this.content.user.trustMember) {
                // Add the trust member as a span, to ensure the same behaviour as if it is a flow text
                titleHTML += "<span class='peer' style='color: " + Color.TEXT_SECONDARY_B + "'>@" + this._getTrustPeerName(this.content.user.trustMember) + "</span>";
            }

            GUI.animationHandler.schedule(function () {
                this.elements.title.html(titleHTML);
                this.elements.title.prop("title", value);
            }.bind(this));
        }

        // ---------------------------------------------------------------------------------------------------------
        //   Methods for super fast tableContent updates
        // ---------------------------------------------------------------------------------------------------------
        cellTypeForReuse() {
            return GUI.TableViewV2.CellType.USER_CELL;
        }

        updateContent(newContent) {
            return super._updateContent(...arguments);
        }

        /**
         * This Method is used to apply a new content object onto the cell instance. e.g. update texts and so on.
         * @param newContent
         * @private
         */
        _applyNewContent(newContent) {
            newContent.title = newContent.user.name;
            return super._applyNewContent(...arguments).then(function () {
                this.setTitle(newContent.title);
            }.bind(this));
        }

        // ---------------------------------------------------------------------------------------------------------
        // ---------------------------------------------------------------------------------------------------------
        _getTrustPeerName(peerSerial) {
            return (this.content.trustPeers.find(function (peer) {
                return peer.serial === peerSerial;
            }) || {}).name || peerSerial;
        }

    }

    GUI.TableViewV2.Cells.UserCell = UserCell;
    return GUI;
}(window.GUI || {});
