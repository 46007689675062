"use strict";

define(['JalousieControlEnums'], function (JalousieControlEnums) {
    return class JalousieControlContentConfigSettingsScreen extends Controls.ControlContent {
        constructor(details) {
            super(details);
            this._availableModes = this.control.details.availableModes.split(",");
            this._onSlatsAdjustmentStarted = details.onSlatsAdjustmentStarted;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.tableView = new GUI.TableViewV2(this, this);
            this.tableView.getElement().addClass("jalousie-control-content-config-settings-screen__table-view");
            this.appendSubview(this.tableView);

            this._generateTableContent();

            this.tableView.reload();
        }

        getURL() {
            return "";
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        // TableViewDataSource methods
        styleForTableView() {
            return HD_APP ? TableViewStyle.GROUPED : null;
        }

        numberOfSections() {
            return this.tableContent.length;
        }

        numberOfRowsInSection(section, tableView) {
            return this.tableContent[section].length;
        }

        cellTypeForCellAtIndex(section, row, tableView) {
            return GUI.TableViewV2.CellType.GENERAL;
        }

        contentForCell(cell, section, row, tableView) {
            return this.tableContent[section][row].content;
        }

        // TableViewDelegate methods
        didSelectCell(cell, section, row, tableView) {
            return this.tableContent[section][row].action();
        }

        _generateTableContent() {
            var section0 = [],
                section1 = [];
            this.tableContent = [section0, section1]; // section 0 = modes

            if (this._supportsMode(JalousieControlEnums.ConfigModes.CORRECT_END_POSITION)) {
                section0.push({
                    content: {
                        title: _("controls.jalousie.config.settings.correct-endposition"),
                        leftIconSrc: "resources/Images/Controls/Jalousie/jalousie/config/icon-endposition_small.svg",
                        leftIconColor: window.Styles.colors.red,
                        clickable: true
                    },
                    action: function () {
                        this.sendCommand(Commands.format(Commands.JALOUSIE.CONFIG.LEARN, JalousieControlEnums.ConfigModes.CORRECT_END_POSITION));
                    }.bind(this)
                });
            }

            if (this._supportsMode(JalousieControlEnums.ConfigModes.ADJUST_SLATS)) {
                section0.push({
                    content: {
                        title: _("controls.jalousie.config.settings.correct-slats-position"),
                        leftIconSrc: "resources/Images/Controls/Jalousie/jalousie/config/icon-lamellen_small.svg",
                        leftIconClass: "correct-slats",
                        clickable: true
                    },
                    action: function () {
                        this._onSlatsAdjustmentStarted();
                    }.bind(this)
                });
            } // section 1 = reset, learn new..


            section1.push({
                content: {
                    title: _("controls.jalousie.config.settings.re-learn-type"),
                    leftIconSrc: "resources/Images/Controls/Jalousie/jalousie/config/icon-rotate-right.svg",
                    leftIconColor: window.Styles.colors.red,
                    clickable: true
                },
                action: function () {
                    this.sendCommand(Commands.format(Commands.JALOUSIE.CONFIG.LEARN, JalousieControlEnums.ConfigModes.SET_END_POSITION));
                }.bind(this)
            });
        }

        _supportsMode(mode) {
            return this._availableModes.indexOf(mode + "") !== -1;
        }

    };
});
