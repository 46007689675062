import globalStyles from "GlobalStyles"

const Wb2Enums = {
    styles: {
        rootCntr: {
            flexDirection: "column",
            maxHeight: "100%"
        },
        contentCntr: {
            ...globalStyles.customStyles.screenContent,
            flexDirection: "column",
            width: "100%",
            padding: globalStyles.sizes.small,
        },
        cellElement: {
            ...globalStyles.customStyles.cellElement,
        },
        stateValue: {
            ...globalStyles.customStyles.rightCellText,
            fontSize: globalStyles.fontSettings.sizes.medium
        },
        sectionHeader: {
           ...globalStyles.customStyles.sectionHeader,
            ...globalStyles.textStyles.title3.bold
        },
        sectionHeaderTitleStyle: {
           marginTop: globalStyles.spacings.gaps.big
        },
        cellTitle: {
            ...globalStyles.customStyles.cellTitle,
            fontSize: globalStyles.fontSettings.sizes.medium
        },
        cellFooter: {
            ...globalStyles.textStyles.footNote.default,
            marginTop: 13
        },
        notificationCntr: {
            width: "100%",
            height: globalStyles.sizes.big,
            paddingLeft: globalStyles.sizes.small,
            paddingRight: globalStyles.sizes.small,
            justifyContent: "center",
            alignItems: "center"
        },
        notificationText: {
            ...globalStyles.textStyles.default,
            fontSize: globalStyles.fontSettings.sizes.medium,
            textAlign: "center",
            width: "100%",
            color: globalStyles.colors.text.secondary
        },
    }
}

export default Wb2Enums;
