import {
    LxReactTableView,
    LxReactText,
    LxReactTextView,
    useDeviceSearchExtensions,
    LxReactSelectorScreen,
    useGroups,
    LxReactNumberInput,
    LxReactPressable
} from "LxComponents";
import {useMemo, useRef, useState, useCallback, useContext} from "react"
import {View} from "react-native"
import globalStyles from "GlobalStyles"
import {useNavigation} from "@react-navigation/native";
import useDeviceSearchLightGroups from "./useDeviceSearchLightGroups";
import useDeviceSearchSwitchboards from "./useDeviceSearchSwitchboards";
import LxReactDeviceSearchAssistantContext from "./LxReactDeviceSearchAssistantContext";
import Icons from "IconLib"
import {LxReactFlexibleCell, LxReactImageView, useConnectionReady} from "../Components";
import DeviceSearchEnums from "./LxReactDeviceSearchEnums";

export default function DeviceSearchDeviceInfoTable({device, rerenderProp, isDeviceLearnedInElsewhere = false}) {

    const navigation = useNavigation();

    const {filteredExtension, filteredBranch, searchType, updateData, getData, lightGroupIdentify} = useContext(LxReactDeviceSearchAssistantContext)

    const [selectedRoom, setSelectedRoom] = useState(getData().room);
    const [selectedGroup, setSelectedGroup] = useState(getData().groupID);
    const [selectedSwitchboard, setSelectedSwitchboard] = useState(getData().switchboard);
    const [selectedHeatSwitchboard, setSelectedHeatSwitchboard] = useState(getData().heatSwitchboard);
    const [selectedWallboxSwitchboard, setSelectedWallboxSwitchboard] = useState(getData().wallboxSwitchboard);
    const connectionReady = useConnectionReady(true);

    const rooms = useGroups(GroupTypes.ROOM);

    const createLearnedInElsewhereRow = () => {
        return {
            type: 'CustomCell',
            comp: LxReactFlexibleCell,
            key: "learnedInElsewhere-warning",
            props: {
                mainLeftContent: {
                    comp: LxReactImageView,
                    props: {
                        source: Icon.INFO2,
                        containerStyle: {
                            fill: globalStyles.colors.red_fixed,
                            height: globalStyles.sizes.icons.regular,
                            width: globalStyles.sizes.icons.regular,
                        },
                        imageStyle: {
                            height: globalStyles.sizes.icons.regular,
                            width: globalStyles.sizes.icons.regular,
                        },
                    },
                },
                mainCenterStyle: {
                    flexShrink: 0,
                },
                mainCenterContent: {
                    comp: LxReactText,
                    props: {
                        style: {
                            ...globalStyles.textStyles.footNote.default,
                            color: globalStyles.colors.red_fixed,
                        },
                        children: _('device-learning.blocked.add-replace-gateway'),
                    },
                },
            }
        };
    };

    const createInfoRow = (title, info) => {
        return {
            title: title,
            mainRightContent: {
                comp: LxReactText,
                props: {
                    style: Styles.infoText,
                    ellipsizeMode: "head",
                    numberOfLines: 2,
                    children: info
                }
            },
            mainRightStyle: {
                maxWidth: "50%"
            }
        }
    }

    const createNumberInput = (title, value, valueModified, min = 1, max = 99, disabled = false) => {
        return {
            title: title,
            disabled: disabled,
            mainRightContent: {
                comp: LxReactNumberInput,
                props: {
                    disabled: disabled,
                    min: min,
                    max: max,
                    value: value,
                    numberChanged: (newNr) => {
                        valueModified("" + newNr);
                    }
                }
            }
        }
    }

    const createSwitchboardLocInput = (title, value, valueModified, disabled = false) => {
        return createNumberInput(title, value, valueModified, DeviceManagement.MIN_SB_POS, DeviceManagement.MAX_SB_POS, disabled);
    }

    const createInputRow = (title, value, placeholder, valueModified) => {
        return {
            title: title,
            mainRightContent: {
                comp: LxReactTextView,
                props: {
                    value: value,
                    textStyle: Styles.inputStyle,
                    placeholderTextColor: globalStyles.colors.text.tertiary,
                    selectTextOnFocus: false,
                    hideRightIcon: true,
                    placeholder: placeholder,
                    validationRegex: Regex.DEV_NAME,
                    textChanged: (newValue, isValid) => {
                        if (isValid || newValue === "") {
                            valueModified(newValue);
                        }
                    },
                    onBlur: (newValue, valid, modified) => {
                        if (newValue && (valid || newValue === "") && modified) {
                            valueModified && valueModified(newValue);
                        }
                        Debug.DeviceSearch.Assistant && console.log(DeviceSearchDeviceInfoTable.name, "Input-Blur - value now is: '" + newValue + "', valid=" + !!valid + ", modified="+ !!modified);
                    },
                    filterRegex: Regex.DEV_NAME_FILTER
                }
            },
        }
    }

    const createSelectionRow = (title, selectedId, options, onSelected, createFn) => {
        let selectedOption = options.find(option => option.id === selectedId);
        let row = createInfoRow(title, selectedOption ? selectedOption.title : title);
        row.disclosureIcon = false;
        row.mainRightContent.props.style = selectedOption ? Styles.adoptedInfoText : Styles.placeholderText;
        row.onPress = () => {
            showSelector(title, selectedId, options, onSelected, createFn);
        }
        return row;
    }

    const showSelector = (title, selectedId, options, onSelected, createFn, autoClose = true) => {
        navigation.push(LxReactSelectorScreen.name, {
            title: title,
            options: options,
            selectedId: selectedId,
            onSelected: onSelected,
            createFn: createFn,
            autoClose: autoClose
        });
    }

    const extensions = useDeviceSearchExtensions(searchType);
    const lightGroups = useDeviceSearchLightGroups(device)
    const switchboards = useDeviceSearchSwitchboards(device);

    const isClientDevice = useMemo(() => {
        let extension = device.extension ? ActiveMSComponent.getExtension(device.extension) : null;
        return extension?.isOnClient ?? false;
    }, [device?.extension ?? 0, extensions]);

    const roomOptions = useMemo(() => {
       return rooms.map((room) => {
           return {
               id: room.uuid,
               title: room.name
           }
       })
    }, [rooms]);
    const handleRoomSelected = (roomUuid) => {
        Debug.DeviceSearch.Assistant && console.log(DeviceSearchDeviceInfoTable.name, "handleRoomSelected: '" + roomUuid + "'");
        updateData({room: roomUuid});
        setSelectedRoom(roomUuid);
    }
    const canCreateRoom = () => {
        return SandboxComponent.checkPermission(MsPermission.EXPERT_MODE) && Feature.CREATE_ROOMS;
    }
    const handleCreateRoom = useCallback((newRoomName) => {
        Debug.DeviceSearch.Assistant && console.log(DeviceSearchDeviceInfoTable.name, "handleCreateRoom: '" + newRoomName + "'");
        return ActiveMSComponent.createRoom(newRoomName).then((uuid) => {
            // convert to a selection option entry before returning it to the selectorScreen.
            return { id: uuid, title: newRoomName };
        });
    }, []);
    const handleNameChanged = (newName) => {
        Debug.DeviceSearch.Assistant && console.log(DeviceSearchDeviceInfoTable.name, "handleNameChanged: '" + newName + "'");
        updateData({name: newName});
    }
    const handleNameRowChanged = (newRow) => {
        Debug.DeviceSearch.Assistant && console.log(DeviceSearchDeviceInfoTable.name, "handleNameRowChanged: '" + newRow + "'");
        updateData({nameRow: newRow});
    }
    const handleNameColumnChanged = (newCol) => {
        Debug.DeviceSearch.Assistant && console.log(DeviceSearchDeviceInfoTable.name, "handleNameColumnChanged: '" + newCol + "'");
        updateData({nameColumn: newCol});
    }
    const handleDescriptionChanged = (newDescription) => {
        Debug.DeviceSearch.Assistant && console.log(DeviceSearchDeviceInfoTable.name, "handleDescriptionChanged: '" + newDescription + "'");
        updateData({description: newDescription});
    }
    const handlePlaceChanged = (newPlace) => {
        Debug.DeviceSearch.Assistant && console.log(DeviceSearchDeviceInfoTable.name, "handlePlaceChanged: '" + newPlace + "'");
        updateData({place: newPlace});
    }
    const handleSwitchboardPosChange = (newPos) => {
        Debug.DeviceSearch.Assistant && console.log(DeviceSearchDeviceInfoTable.name, "handleSwitchboardPosChange: '" + newPos + "'");
        updateData({column: newPos});
    }
    const handleSwitchboardRowChange = (newRow) => {
        Debug.DeviceSearch.Assistant && console.log(DeviceSearchDeviceInfoTable.name, "handleSwitchboardRowChange: '" + newRow + "'");
        updateData({row: newRow});
    }

    // region switchboard handling

    const handleHeatSwitchboardSelected = useCallback((sbId) => {
        Debug.DeviceSearch.Assistant && console.log(DeviceSearchDeviceInfoTable.name, "handleHeatSwitchboardSelected: '" + sbId + "'");
        updateData({heatSwitchboard: sbId});
        setSelectedHeatSwitchboard(sbId);
    }, []);
    const handleSwitchboardSelected = useCallback((sbId) => {
        Debug.DeviceSearch.Assistant && console.log(DeviceSearchDeviceInfoTable.name, "handleSwitchboardSelected: '" + sbId + "'");
        updateData({switchboard: sbId});
        setSelectedSwitchboard(sbId);
    }, []);
    const handleWallboxSwitchboardSelected = useCallback((sbId) => {
        Debug.DeviceSearch.Assistant && console.log(DeviceSearchDeviceInfoTable.name, "handleWallboxSwitchboardSelected: '" + sbId + "'");
        updateData({wallboxSwitchboard: sbId});
        setSelectedWallboxSwitchboard(sbId);
    }, []);

    const handleCreateSwitchboard = useCallback((newBoardName) => {
        Debug.DeviceSearch.Assistant && console.log(DeviceSearchDeviceInfoTable.name, "handleCreateSwitchboard: '" + newBoardName + "'");
        return ActiveMSComponent.createSwitchboard(newBoardName, DeviceManagement.SwitchboardTypes.REGULAR).then((newSb) => {
            return { id: newSb.id, title: newSb.name };
        });
    }, []);
    const handleCreateHeatSwitchboard = useCallback((newBoardName) => {
        Debug.DeviceSearch.Assistant && console.log(DeviceSearchDeviceInfoTable.name, "handleCreateHeatSwitchboard: '" + newBoardName + "'");
        return ActiveMSComponent.createSwitchboard(newBoardName, DeviceManagement.SwitchboardTypes.HEAT).then((newSb) => {
            return { id: newSb.id, title: newSb.name };
        });
    }, []);
    const handleCreateWallboxSwitchboard = useCallback((newBoardName) => {
        Debug.DeviceSearch.Assistant && console.log(DeviceSearchDeviceInfoTable.name, "handleCreateWallboxSwitchboard: '" + newBoardName + "'");
        return ActiveMSComponent.createSwitchboard(newBoardName, DeviceManagement.SwitchboardTypes.WALLBOX).then((newSb) => {
            return { id: newSb.id, title: newSb.name };
        });
    }, []);

    // endregion

    // region lightGroup handling
    const handleGroupSelected = (groupId) => {
        Debug.DeviceSearch.Assistant && console.log(DeviceSearchDeviceInfoTable.name, "handleGroupSelected " + groupId);
        updateData({groupID: groupId});
        setSelectedGroup(groupId);
    }
    const lgRoomDefRef = useRef(null);
    const lgRoomSelected = useCallback((lgRoomUuid) => {
        if (!lgRoomDefRef.current) {
            // nothing to do.
            return;
        } else {
            lgRoomDefRef.current.resolve(lgRoomUuid);
        }
    }, [lgRoomDefRef.current]);
    const handleCreateLightGroup = (newGroupName) => {
        Debug.DeviceSearch.Assistant && console.log(DeviceSearchDeviceInfoTable.name, "handleCreateLightGroup " + newGroupName);
        lgRoomDefRef.current = Q.defer();
        // use the show room selector property to select a room.
        showSelector(_("device-learning.room-for-group"), null, roomOptions, lgRoomSelected, handleCreateRoom, false);
        return lgRoomDefRef.current.promise.then((roomUuid) => {
            Debug.DeviceSearch.Assistant && console.log(DeviceSearchDeviceInfoTable.name, "handleCreateLightGroup > room for LG " + newGroupName +" selected: " + roomUuid);
            return ActiveMSComponent.createLightGroup(newGroupName, roomUuid, device).then((lgObject) => {
                Debug.DeviceSearch.Assistant && console.log(DeviceSearchDeviceInfoTable.name, "lightGroup created! " + JSON.stringify(lgObject));
                return { id: lgObject.uuid, title: lgObject.name }
            });
        }).finally(() => {
            lgRoomDefRef.current = null;
        });
    }
    // endregion

    const extensionRow = useMemo(() => {
        if (searchType === DeviceManagement.TYPE.AIR) {
            return null; // air doesn't provide any extension info.
        } else if ((filteredExtension && filteredExtension !== searchType) || filteredBranch) {
            return null; // no need to show the extension, when filtering for it.
        }
        let extension = device.extension ? ActiveMSComponent.getExtension(device.extension) : null;
        let extName = extension ? extension.name : "--";
        let branchName = null;
        if (extension && device.subCaptionId) {
            let branches = (extension.subCaptions || []).filter(subCap => {
                return subCap.id === device.subCaptionId;
            });
            if (branches.length > 0) {
                branchName = branches[0].name;
            }
            return createInfoRow(_("device-learning.extension"), extName + (branchName ? ", " + branchName : ""));
        } else {
            return createInfoRow(_("device-learning.extension"), "--");
        }
    }, [device.extension, device.subCaptionId, extensions])

    const renderHeader = (title) => {
        if (nullEmptyString(title)) {
            return <LxReactText style={globalStyles.customStyles.sectionHeader}>{title}</LxReactText>;
        } else {
            return <View style={globalStyles.customStyles.emptyHeader} />;
        }
    }

    const identifyComponentForGroup = (group) => {
        if (!Feature.LIGHT_GROUP_IDENTIFY) {
            return null;
        }
        const groupId = group.uuid;
        return {
            comp: LxReactPressable,
            props: {
                onPress: () => {
                    lightGroupIdentify(groupId);
                },
                children: [ <Icons.Location style={Styles.identifyIcon} /> ]
            }
        }
    }

    const lightgroupRows = useMemo(() => {
        let lgRows = [];
        if (Feature.LEARN_DEVICE_OVER_APP_REMAKE && hasBit(device.capabilities, DeviceManagement.CAPABILITIES.LIGHT_GROUP)) {
            let lgRow,
                groupOptions;
            if (lightGroups) {
                groupOptions = lightGroups.map(lg => {
                    return {
                        id: lg.uuid,
                        title: lg.name,
                        subTitle: lg.room ? lg.room.name : null,
                        mainRightContent: lg.uuid === "" ? null : identifyComponentForGroup(lg)
                    };
                });
                lgRow = createSelectionRow(_('device-learning.light-group'), getData().groupID, groupOptions, handleGroupSelected, handleCreateLightGroup)
            } else {
                lgRow = createInfoRow(_('device-learning.light-group'), "--")
            }
            lgRows.push(lgRow);
        }

        return lgRows
    }, [device.capabilities, device.groupID, selectedGroup, lightGroups, Feature.LEARN_DEVICE_OVER_APP_REMAKE]);

    // region switchboard

    const getSwitchboardOptions = (sbType) => {
        if (!switchboards) {
            return [];
        }
        return switchboards.filter(sb => sb.sbType === sbType)
            .map(sb => { return { id: sb.id, title: sb.name }});
    }

    const getSwitchboardProps = (sbType) => {
        let props = {
                options: getSwitchboardOptions(sbType)
            },
            shouldSelectFirst = props.options.length > 0; // if options have been loaded, preselect the first one.

        // ensure that there's a "none" option for no switchboard.
        props.options.push({ id: DeviceSearchEnums.NO_SWITCHBOARD_ID, title: _("not-used") });

        switch (sbType) {
            case DeviceManagement.SwitchboardTypes.WALLBOX:
                props.title = _("device-learning.wallbox-switchboard");
                props.lastUsed = getData().wallboxSwitchboard;
                props.createFn = handleCreateWallboxSwitchboard;
                props.selectFn = handleWallboxSwitchboardSelected;
                break;
            case DeviceManagement.SwitchboardTypes.HEAT:
                props.title = _('device-learning.heat-switchboard');
                props.lastUsed = getData().heatSwitchboard
                props.createFn = handleCreateHeatSwitchboard;
                props.selectFn = handleHeatSwitchboardSelected;
                break;
            default:
                props.title = _('device-learning.switchboard');
                props.lastUsed = getData().switchboard;
                props.createFn = handleCreateSwitchboard;
                props.selectFn = handleSwitchboardSelected;
                break;
        }

        // if nothing has been preselected, select the first switchboard by default.
        if (!props.lastUsed && shouldSelectFirst) {
            props.lastUsed = props.options[0].id;
            handleSwitchboardSelected(props.lastUsed);
        }

        return props;
    }

    const switchboardRows = useMemo(() => {
        if (!Feature.LEARN_DEVICE_OVER_APP_REMAKE || !hasBit(device.capabilities, DeviceManagement.CAPABILITIES.SWITCHBOARD)) {
            return [];
        }
        let sbType = ActiveMSComponent.identifySwitchboardType(device),
            props = getSwitchboardProps(sbType),
            rows = [],
            notSelected = !props.lastUsed || props.lastUsed === DeviceSearchEnums.NO_SWITCHBOARD_ID;

        if (!notSelected) {
            // even though the lastUsed may be available from persistence, it may not be available in the options (yet
            // or anymore) --> hence the loc/row needs to be invalidated.
            notSelected = !props.options.find(option => option.id === props.lastUsed);
        }

        if (props.options) {
            rows.push(createSelectionRow(props.title, props.lastUsed, props.options, props.selectFn, props.createFn));
        } else {
            rows.push(createInfoRow(props.title, props.lastUsed));
        }

        if (hasBit(device.capabilities, DeviceManagement.CAPABILITIES.SB_ROW)) {
            // ensure the dataset has got a value initially
            !getData().row && handleSwitchboardRowChange(getData().row || DeviceManagement.MIN_SB_POS);
            rows.push(createSwitchboardLocInput(_('device-learning.row'), getData().row, handleSwitchboardRowChange, notSelected));
        }
        if (hasBit(device.capabilities, DeviceManagement.CAPABILITIES.SB_POS)) {
            // ensure the dataset has got a value initially
            !getData().column && handleSwitchboardPosChange(getData().column || DeviceManagement.MIN_SB_POS);
            rows.push(createSwitchboardLocInput(_('device-learning.position'), getData().column, handleSwitchboardPosChange, notSelected));
        }

        return rows;
    }, [
        switchboards && switchboards.length,
        getData().row,
        getData().column,
        device.type,
        device.capabilities,
        selectedSwitchboard,
        selectedHeatSwitchboard,
        selectedWallboxSwitchboard
    ]);

    // endregion switchboard

    const numberedNameRows = useMemo(() => {
        let nrNameRows = [];

        if (ActiveMSComponent.deviceTypeSupportsNumberedName(device.type)) {
            !getData().nameRow && handleNameRowChanged(getData().nameRow || 1);
            nrNameRows.push(createNumberInput(_('device-learning.row'), getData().nameRow || 1, handleNameRowChanged));
            !getData().nameColumn && handleNameColumnChanged(getData().nameColumn || 1);
            nrNameRows.push(createNumberInput(_('device-learning.position'), getData().nameColumn || 1, handleNameColumnChanged));
        }

        return nrNameRows;
    }, [device.type, getData().nameRow, getData().nameColumn]);

    const tableContent = useMemo(() => {
        let content = [],
            firstSection = {},
            secondSection,
            snrRow = createInfoRow(_("miniserver.serial-number"), device.serialNr.toLocaleUpperCase()),
            devTypeRow = createInfoRow(_("battery-monitor.device.type"), device.name);

        if(isDeviceLearnedInElsewhere) {
            firstSection.rows = [
                snrRow,
                devTypeRow,
                createLearnedInElsewhereRow()
            ];
        } else {
            firstSection.rows = [
                createInputRow(_('device-learning.designation'), getData().name, device.name, handleNameChanged),
                createSelectionRow(_("room"), getData().room, roomOptions, handleRoomSelected, canCreateRoom() ? handleCreateRoom : null),
                snrRow,
                devTypeRow,
            ];

            secondSection = {
                headerElement: renderHeader(""),
                rows: [...switchboardRows, ...lightgroupRows]
            };
            
            if (isClientDevice && !Feature.GW_DEVICESEARCH_OPTIONS) {
                // cannot set lightgroups and/or switchboards for devices that are to be paired on a client.
                secondSection.rows = [];
            }

            if (hasBit(device.capabilities, DeviceManagement.CAPABILITIES.PLACE)) {
                secondSection.rows.push(createInputRow(_('battery-monitor.device.assembly-place'), getData().place, _('battery-monitor.device.assembly-place'), handlePlaceChanged))
            }

            if (Feature.LEARN_DEVICE_OVER_APP_REMAKE) {
                secondSection.rows.push(createInputRow(_('description'), getData().description, _('description'), handleDescriptionChanged));
            }
        }

        firstSection.rows.splice(1,0, ...numberedNameRows);
        firstSection.rows.pushObject(extensionRow);
        content.pushObject(firstSection);
        secondSection !== undefined && content.pushObject(secondSection);

        if (!connectionReady) {
            content.forEach(section => {
                section.rows.forEach(row => {
                    row.disabled = true;
                })
            })
        }
        
        return content;
    }, [device, isClientDevice, extensions, selectedRoom, lightgroupRows, rooms, switchboards, extensionRow, JSON.stringify(switchboardRows), isDeviceLearnedInElsewhere]);


    return <LxReactTableView tableContent={tableContent} />
}

const Styles = {
    infoText: {
        ...globalStyles.customStyles.rightCellText,
        textOverflow: "ellipsis"
    },
    adoptedInfoText: {
        ...globalStyles.customStyles.rightCellText,
        color: globalStyles.colors.stateActive,
        lineHeight: 20
    },
    placeholderText: {
        ...globalStyles.customStyles.rightCellText,
        color: globalStyles.colors.text.tertiary,
        lineHeight: 20
    },
    inputStyle: {
        fontSize: globalStyles.fontSettings.sizes.medium,
        color: globalStyles.colors.stateActive,
        marginRight: 0
    },
    identifyIcon: {
        fill: globalStyles.colors.text.primary,
        width: globalStyles.sizes.icons.button,
        height: globalStyles.sizes.icons.button,
    }
}