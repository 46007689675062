'use strict';
/*
 title

 leftIconSrc
 leftIconColor
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.HALO_ICON = "HaloIconCell";

    class HaloIconCell extends GUI.TableViewV2.Cells.EditableBaseCell {
        //region Static
        static Template = function () {
            var getCellContent = function getCellContent(content) {
                return $(getIcon(content.leftIconSrc || Icon.DEFAULT) + '<div class="content__texts-placeholder">' + (content.title ? '<div class="texts-placeholder__title">' + '<div class="title__text text-overflow-ellipsis">' + content.title + '</div>' + '</div>' : '') + '</div>');
            };

            var getIcon = function getIcon(src, iconClass) {
                return '<div class="content__icon-placeholder">' + getImage(src, "icon-placeholder__icon" + (iconClass ? " " + iconClass : "")) + '</div>';
            };
            /**
             * Returns an element containing the image that can be loaded from the source provided. If the source starts
             * with "resources/" and ends with ".svg" it is assumed that it is included in our ImageBox and is fetched
             * from there. All other images will be loaded from  the path provided using an image tag.
             * @param src
             * @param classes
             * @returns {*}
             */


            var getImage = function getImage(src, classes) {
                var img;

                if (src.startsWith("resources/") && src.indexOf(".svg") !== -1) {
                    img = ImageBox.getResourceImageWithClasses(src, classes);
                } else {
                    img = '<img class="' + classes + '" src="' + src + '">';
                }

                return img;
            };

            return {
                getCellContent: getCellContent
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.contentPlaceholder.append(HaloIconCell.Template.getCellContent(this.content));

            if (this.content.title) {
                this.elements.title = this.element.find('.texts-placeholder__title .title__text');
            }

            this.elements.leftIconCntr = this.element.find('.content__icon-placeholder');
            this.elements.leftIcon = this.element.find('.icon-placeholder__icon');

            if (this.content.leftIconColor) {
                this.elements.leftIcon.css('fill', this.content.leftIconColor);
            }
        }

        // Public methods

        /**
         * updates the left icon (only works with resource svg's and if there was a left icon used before - eg. png which failed to load!)
         * @param iconSrc
         */
        setLeftIcon(iconSrc) {
            var classAttr = this.elements.leftIcon.attr("class"),
                styleAttr = this.elements.leftIcon.attr("style"),
                newIcon = $(ImageBox.getResourceImageWithClasses(iconSrc, classAttr)).attr("style", styleAttr);
            this.elements.leftIcon.replaceWith(newIcon);
            this.elements.leftIcon = newIcon;
        }

        setTitle(value) {
            this.elements.title.text(value);
        }

        clickableAllowed() {
            return true;
        }

    }

    GUI.TableViewV2.Cells.HaloIconCell = HaloIconCell;
    return GUI;
}(window.GUI || {});
