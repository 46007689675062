'use strict';

define(["LxComponents"], function ({ App }) {
    return class AlexaSelectUserViewController extends GUI.ModalViewController {
        constructor(details) {
            super();

            if (details) {
                this._screenToShow = details.screenToShow;
                this._detailsFromPrevScreen = details.details;
            }
        }

        closeAction() {
            this.ViewController.closeAction();
        }

        getAnimation() {
            return AnimationType.NONE;
        }

        getShouldBlockScreenSaver() {
            return true;
        }

        handleNextScreen(details) {
            let vc = this.ViewController;
            return this.dismiss().then(() => {
                return vc.handleNextScreen(details);
            });
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                if (this._screenToShow === ScreenState.UserDetailsScreen) {
                    App.navigationRef.navigate(ScreenState.UserDetailsScreen, this._detailsFromPrevScreen);
                } else {
                    this.showState(this._screenToShow, null, this._detailsFromPrevScreen, AnimationType.NONE);
                }
            }.bind(this));
        }

    };
});
