export default class AlarmControlBaseStatesSrc {
    static getMoreSection(control, states, navigation) {
        let section = {
            rows: []
        };
        const trackerUUID = Object.keys(control.subControls)[0];
        const trackerControl = control.subControls[trackerUUID];

        section.rows.push({
            content: {
                title: trackerControl.name,
                disclosureIcon: true,
            },
            action: this.showTrackerControl.bind(this, trackerControl)
        })
        return section;
    }

    static showTrackerControl(trackerControl) {
        NavigationComp.showControlContent(trackerControl)
    }
}
