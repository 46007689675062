import {useNavigation, useFocusEffect} from "@react-navigation/native";
import React, {useState, useCallback, useRef, useEffect, useMemo} from "react";
import {
    LxReactTableView,
    LxReactButton,
    navigatorConfig,
    LxReactContextMenuHandler,
    TopNavigator,
    LxReactText,
    LxReactPressable,
    LxReactImageView,
    useBackNavigation,
    useCCEvent
} from "LxComponents";
import PropTypes from "prop-types";
import Icons from "IconLib";
import globalStyles from "GlobalStyles";
import {View, ScrollView} from "react-native";
import DndSortableList from "../react-comps/LxReactTableView/SortableList"
import {ButtonType, LxReactFlexibleCell} from "./Components";
import AppSetupOptions from "./AppSetupOptions";
/**
 * Used in miniserverSelectionScreen & ArchiveScreen.
 * @param props
 * @property [showAddMsInitially] if true, it'll trigger showing the add miniserver comp right away.
 * @returns {JSX.Element}
 * @constructor
 */

export default function LxReactMiniserverList(props) {

    const navigation = useNavigation();
    const shouldShowMSInitiallyRef = useRef(!!props.showAddMsInitially && !HD_APP)
    const [allMiniservers, setAllMiniservers] = useState(PersistenceComponent.getAllMiniserver(true));
    const [editModeActive, setEditModeActive] = useState(false);
    const [networkStatus, setNetworkStatus] = useState(PlatformComponent.getNetworkStatus().status);

    const refreshData = () => {
        setAllMiniservers(PersistenceComponent.getAllMiniserver(true));
    }

    const hasDevices = () => {
        var msList = allMiniservers;
        return Array.isArray(msList) && msList.length > 0
    }

    useCCEvent([CCEvent.NetworkChanged], (ev, status) => {
        setNetworkStatus(status);
    });

    useFocusEffect(
        useCallback(() => {
            refreshData();
            if (shouldShowMSInitiallyRef.current) {
                shouldShowMSInitiallyRef.current = false;
                //showAddMiniserverContextMenu(); don't do it, appsetupoptions will be shown!
            }
            return () => {
                refreshData();
            }
        }, [networkStatus])
    )

    useBackNavigation(() => {
        if (!props.isQuickSelection) {
            if (PlatformComponent.isAndroid()) {
                navigator.app.exitApp();
            } else if (PlatformComponent.isDeveloperInterface()) {
                developerAttention("On an Android device the App would have been closed now!");
            }
        } else {
            navigation.goBack();
        }
    })

    useEffect(() => {
        let options = {
            title: editModeActive ? _("edit") : _('miniserverlist.title'),
            animationType: props.isQuickSelection ? AnimationType.MODAL : AnimationType.NONE,
            onLeftAction: () => {
                if (props.isQuickSelection) {
                    navigation.goBack();
                } else {
                    navigation.navigate(ScreenState.Menu);
                }
            }
        }

        if (hasDevices()) {
            options = {
                ...options,
                rightActions: [{
                    action: editModeActive ? _("done") : _("edit")
                }],
                onRightAction: () => {
                    toggleEditMode()
                }
            }
        }
        navigation.setOptions({
            ...navigatorConfig(options),
            get headerBackImage() {
                if (props.isQuickSelection) {
                    return TopNavigator.forModal().headerBackImage
                } else {
                    return () => <LxReactImageView source={Icon.Buttons.PLACE} containerStyle={globalStyles.customStyles.titleBarIcon} partFillColors={{
                        path: globalStyles.colors.white
                    }} />
                }
            }
        });
    }, [editModeActive, allMiniservers])

    const AddMSButton = () => {
        return <LxReactButton onPress={() => showAddMiniserverContextMenu()}
                              containerStyle={styles.addMiniserverButton}
                              text={_("miniserverlist.add.to")}
                              pkey={"miniserver_add"}
                              key={"miniserver_add"}
        />
    }

    const PairTabletButton = () => {
        if (!PairedAppComponent.isPairableDevice()) {
            return null;
        }
        return <LxReactButton
            onPress={() => {
                (ActiveMSComponent.getActiveMiniserver() ? NavigationComp.showArchive() : Q.resolve()).then(() => {
                    navigation.navigate("AppPairingScreen");
                })

            }}
            buttonType={ButtonType.SECONDARY}
            containerStyle={styles.pairTabletButton}
            text={_("managed-tablet.set-up-managed-tab")}
            key={"pair-tab"}
            pkey={"pair-tab"}
        />
    }

    // region table content creation
    const isActiveMs = (ms) => {
        var activeMs = ActiveMSComponent.getActiveMiniserver(),
            activeMsSerial = activeMs ? activeMs.serialNo : false;

        return ms && ms.serialNo === activeMsSerial;
    }

    /**
     * Creates a cell object for a given miniserver
     * @param ms
     * @param isActiveMs
     * @returns {{action: *, type: (string|*), content: {subtitle, title: string}}}
     * @private
     */
    const getCellForMiniserver = (ms, isActiveMs) => {
        var title = getTitleForMiniserver(ms),
            content = {
                title: title,
                subtitle: ms.location
            };

        content.key = "ms-" + title;

        content.subTitleStyle = {
            ...globalStyles.textStyles.footNote.default,
            maxWidth: "100%"
        }

        content.titleStyle = {
            ...globalStyles.textStyles.body.bold,
            maxWidth: "100%"
        }

        content.mainRightContent = {
            comp: LxReactPressable,
            props: {
                key: ms.serialNo + "info",
                pkey: ms.serialNo + "-info",
                onPress: () => handleAboutMsTapped(ms),
                children: <Icons.InfoCircled width={30} height={30} fill={globalStyles.colors.text.primary} key={title + "-info"}/>
            }
        }

        content.mainRightStyle = {
            flexShrink: 0
        }

        content.mainLeftStyle = {
            flexShrink: 0
        }

        content.mainCenterStyle = {
            flexShrink: 1
        }

        if (!editModeActive) {
            content.mainLeftContent = {
                comp: LxReactText,
                props: {
                    style: {
                        ...styles.miniserverTouchable,
                        backgroundColor: (!!isActiveMs ? globalStyles.colors.brand : globalStyles.colors.grey["300a36"])
                    },
                    children: title.substring(0, 2)
                }
            }
        }

        return {
            type: GUI.TableViewV2.CellType.GENERAL,
            content: content,
            action: editModeActive ? null : () => {
                return handleMsTap(ms, isActiveMs)
            },
        };
    }

    /**
     * Returns the name with the fav star if applicable
     * @param ms
     * @returns {string}
     * @private
     */
    const getTitleForMiniserver = (ms) => {
        return ms.msName || ms.serialNo || ms.localUrl || ms.remoteUrl;
    }

    const tableContent = useMemo(() => {
        var msSection = {
            footerElement: !editModeActive ? (<><AddMSButton key={"add-ms-btn"}/><PairTabletButton /></>) : null
        };

        if (!hasDevices()) {
            return [];
        }

        msSection.rows = allMiniservers.map((msObj, idx) => {
            return getCellForMiniserver(msObj, isActiveMs(msObj));
        });

        return [msSection];
    }, [allMiniservers, editModeActive]);

    //endregion

    // region interaction

    const handleMsMoved = (section, oldIdx, newIdx) => {
        // Reorder by just moving it to its new index --> will be stored later (maybe more changes will follow)
        setAllMiniservers(allMs => {
            let tmpAllMs = cloneObjectDeep(allMs);
            tmpAllMs.move(oldIdx, newIdx);
            return tmpAllMs;
        });
    }

    const handleMsRemoved = (section, idx) => {
        persistChanges();
        let ms = allMiniservers[idx];
        handleDeleteMsTapped(ms, isActiveMs(ms));
    }

    const handleMsTap = (ms, isActiveMs) => {
        if (isActiveMs) {
            if (navigation.canGoBack()) {
                navigation.goBack();
            } else {
                navigation.navigate(ScreenState.ActiveMiniserver)
            }
             //navigateBack();
        } else {
            connectTo(ms);
        }
    }

    const handleAboutMsTapped = (ms) => {
        showState(ScreenState.AboutMiniserver, {
            serialNo: ms.serialNo
        });
    }

    const handleDeleteMsTapped = (ms, isActiveMs) => {
        let content = {
            title: _('miniserverlist.delete'),
            message: _('miniserverlist.delete.confirmation'),
            buttonOk: _('delete'),
            buttonCancel: true,
            icon: Icon.DELETE,
            color: globalStyles.colors.red
        };
        showPopup(content).then(() => {

            PersistenceComponent.removeMiniserver(ms.serialNo);

            if (isActiveMs) {
                disconnect();
            }

            refreshData();
        }, function () {
        });
    }

    const toggleEditMode = () => {
        editModeActive && persistChanges();
        setEditModeActive(!editModeActive);
    }

    const persistChanges = () => {
        PersistenceComponent.updateMiniserverList(allMiniservers);
    }

    const showAddMiniserverContextMenu = () => {
        var options = [],
            canSearch = networkStatus === NetworkStatus.LAN && MiniserverFinder.hasMSFinder(),
            title = _("miniserverlist.add");

        canSearch && options.push({
            title: _("miniserverlist.add.search"),
            action: () => {
                return showMiniserverSearchScreen();
            }
        });
        options.push({
            title: _("miniserverlist.add.manual"),
            action: () => {
                return showEnterUrlScreen();
            }
        });
        options.push({
            title: _("miniserverlist.add.test"),
            action: () => {
                return connectToDemoMiniserver();
            }
        });
        return showContextMenu(options, title);
    }

// endregion

// region navigation stuff
    const showPopup = (content) => {
        return NavigationComp.showPopup(content);
    }
    const showContextMenu = (options, title) => {
        LxReactContextMenuHandler.shared.showContextMenu(options, "");
    }
    const disconnect = () => {
        return NavigationComp.disconnect();
    }
    const showArchive = () => {
        return NavigationComp.showArchive();
    }
    const connectTo = (ms) => {
        return NavigationComp.connectTo(ms);
    }
    const showState = (screenState, screenProps) => {
        //return navigation.navigate(screenState, screenProps);
        return NavigationComp.showState(screenState, screenProps);
    }
    const registerForBackNavigation = (callback) => {
        return NavigationComp.registerForBackNavigation(callback);
    }
    const connectToDemoMiniserver = () => {
        return NavigationComp.connectToDemoMiniserver();
    }
    const showEnterUrlScreen = () => {
        return NavigationComp.showEnterUrlScreen();
    }
    const showMiniserverSearchScreen = () => {
        return NavigationComp.showMiniserverSearchScreen();
    }
// endregion

    const StaticMiniserverList = () => {
        return <>
            {tableContent[0].rows.map((msObject, index) =>
                <LxReactFlexibleCell
                    section={0}
                    row={index}
                    key={`${index}-${msObject.content.title}`}
                    {...msObject.content}
                    subTitle={msObject.content.subtitle}
                    onPress={msObject.action}
                />
            )}
            <AddMSButton key={"add-ms-button"} />
            <PairTabletButton />
        </>
    }

    const DndListWithPrepStep = () => {
        let tempContent = [];
        tempContent = tableContent[0].rows.map((msObject, index) => {
            return {
                id: index+1,
                ...window.LxCellReactMap[msObject.type]({
                    cellObj: msObject,
                    sectionIdx: tableContent[0],
                    rowId: index
                })
            }
        });

        return <DndSortableList content={tempContent} onCellRemove={handleMsRemoved} onCellMove={handleMsMoved} listStyle={styles.dndList} />
    }

    if (hasDevices() && !shouldShowMSInitiallyRef.current) {
        return (
            <ScrollView style={styles.miniserverList} contentContainerStyle={props.style}>
                {editModeActive ? <DndListWithPrepStep /> : <StaticMiniserverList />}
            </ScrollView>
        )
    } else {
        return <AppSetupOptions />
    }
}

const styles = {
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
    },
    addMiniserverButton: {
        marginTop: globalStyles.spacings.gaps.big,
        textAlign: "center"
    },
    pairTabletButton: {
        marginTop: globalStyles.spacings.gaps.smallRegular,
        textAlign: "center"
    },
    miniserverTouchable: {
        ...globalStyles.textStyles.miniserver,
        color: globalStyles.colors.text.primary,
        textAlign: "center",
        paddingVertical: "auto",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        lineHeight: "40px"
    },
    miniserverList: {
        paddingHorizontal: globalStyles.spacings.gaps.regular
    },
    dndList: {
        width: '100%'
    },
}

LxReactMiniserverList.propTypes = {
    style: PropTypes.object,
    showAddMsInitially: PropTypes.bool,
    isQuickSelection: PropTypes.bool
}