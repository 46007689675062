import * as React from "react"
import Svg, {
    Path,
    G,
    Defs,
    LinearGradient,
    ClipPath,
    Rect,
    Stop,
} from "react-native-svg"
import {useRef} from "react";


export default function EFMCardBg2(props) {
    const cardkey = useRef(getRandomIntInclusive(0, 99999));
    const makeUnique = (id) => {
        return id + "-" + cardkey.current;
    }
    return (
        <Svg isreact="true" width="342" height="148" viewBox="0 0 342 148" fill="none" {...props}>
            <G opacity="1">
                <Rect width="342" height="148" fill={"url(#" + makeUnique("paint0_linear_1726_19010_2") + ")"} />
                <G style={{mixBlendMode:'overlay'}}>
                    <G clipPath={"url(#" + makeUnique("clip0_1726_19010") + ")"}>
                        <G style={{mixBlendMode:'overlay'}}>
                            <Path d="M6.33333 40.6667H-6.33333V30.7573C-4.82 32.232 -2.59467 33.852 -0.0266666 33.9533C0.0586662 33.9573 0.144001 33.9587 0.229336 33.9587C2.104 33.9587 3.86667 33.1387 5.47067 31.5187C5.91734 31.0667 6.104 30.6187 6.02267 30.1853C5.98267 29.9733 5.836 29.5813 5.308 29.3467L-6.228 24.0333C-4.83867 21.3347 -3.54 17.6187 -4.74 14.5213C-5.512 12.5293 -7.204 11.0573 -9.76667 10.1493C-10.3667 9.93866 -10.852 9.956 -11.2093 10.2107C-11.3813 10.3347 -11.6707 10.6307 -11.6667 11.204V23.82C-14.8613 23.0747 -18.904 23.048 -21.5747 25.0613C-23.38 26.424 -24.2973 28.5053 -24.2973 31.248C-24.2973 31.884 -24.1133 32.3333 -23.752 32.584C-23.6333 32.6667 -23.424 32.772 -23.1373 32.772C-22.9947 32.772 -22.832 32.7453 -22.652 32.6787L-11.6667 28.648V40.6667H-24.3333C-24.7013 40.6667 -25 40.9653 -25 41.3333C-25 41.7013 -24.7013 42 -24.3333 42H-11H-7H6.33333C6.70133 42 7 41.7013 7 41.3333C7 40.9653 6.70133 40.6667 6.33333 40.6667ZM4.52533 30.5813C3.12267 31.9973 1.62 32.68 0.0266685 32.6227C-2.704 32.5147 -5.07467 30.268 -6.33333 28.816V25.4533L4.60667 30.4933C4.584 30.52 4.55733 30.548 4.52533 30.5813ZM-10.2107 11.408C-8.03067 12.18 -6.608 13.3893 -5.98267 15.004C-5.09333 17.3013 -5.88133 20.1187 -6.93067 22.428C-7.41867 21.82 -8.16 21.4213 -9 21.4213C-9.488 21.4213 -9.94 21.5613 -10.3333 21.792L-10.332 11.368C-10.296 11.3773 -10.256 11.3907 -10.2107 11.408ZM-22.96 31.3667C-22.9627 31.3333 -22.964 31.2933 -22.964 31.248C-22.964 28.9467 -22.2267 27.2227 -20.772 26.1267C-18.3827 24.3253 -14.492 24.4827 -11.6667 25.192V27.2267L-22.96 31.3667ZM-10.3333 40.6667V24.088C-10.3333 23.352 -9.73467 22.7547 -9 22.7547C-8.26533 22.7547 -7.66667 23.352 -7.66667 24.088V40.6667H-10.3333Z" fill="black" fillOpacity="0.5" />
                        </G>
                    </G>
                    <G clipPath={"url(#" + makeUnique("clip1_1726_19010") + ")"}>
                        <G style={{mixBlendMode:'overlay'}}>
                            <Path d="M60.3333 12C60.3333 12.368 60.0347 12.6667 59.6667 12.6667C59.2987 12.6667 59 12.368 59 12V10.6667C59 10.2987 59.2987 10 59.6667 10C60.0347 10 60.3333 10.2987 60.3333 10.6667V12Z" fill="black" fillOpacity="0.5" />
                            <Path d="M51.6667 15.336C51.496 15.336 51.3253 15.2707 51.1947 15.14L49.8627 13.808C49.6027 13.548 49.6027 13.1253 49.8627 12.8653C50.1227 12.6053 50.5453 12.6053 50.8053 12.8653L52.1387 14.1987C52.3987 14.4587 52.3987 14.8813 52.1387 15.1413C52.008 15.2707 51.8373 15.336 51.6667 15.336Z" fill="black" fillOpacity="0.5" />
                            <Path d="M67.6667 15.3333C67.8373 15.3333 68.008 15.268 68.1387 15.1387L69.472 13.8053C69.732 13.5453 69.732 13.1227 69.472 12.8627C69.212 12.6027 68.7893 12.6027 68.5293 12.8627L67.196 14.196C66.936 14.456 66.936 14.8787 67.196 15.1387C67.3253 15.268 67.496 15.3333 67.6667 15.3333Z" fill="black" fillOpacity="0.5" />
                            <Path d="M47.6667 22H49C49.368 22 49.6667 22.2987 49.6667 22.6667C49.6667 23.0347 49.368 23.3333 49 23.3333H47.6667C47.2987 23.3333 47 23.0347 47 22.6667C47 22.2987 47.2987 22 47.6667 22Z" fill="black" fillOpacity="0.5" />
                            <Path d="M51.484 25.5C51.5813 25.7747 51.8387 25.9453 52.1133 25.9453C52.1867 25.9453 52.2613 25.9333 52.336 25.908C52.6827 25.7853 52.8653 25.404 52.7427 25.0573C52.4707 24.2907 52.3333 23.4853 52.3333 22.6667C52.3333 18.624 55.6227 15.3333 59.6667 15.3333C61.756 15.3333 63.7547 16.2347 65.1493 17.8067C65.3933 18.0827 65.816 18.1053 66.0907 17.8627C66.3667 17.6173 66.3907 17.1973 66.1467 16.9213C64.5 15.0653 62.1373 14 59.6667 14C54.888 14 51 17.888 51 22.6667C51 23.6373 51.1627 24.5907 51.484 25.5Z" fill="black" fillOpacity="0.5" />
                            <Path fillRule="evenodd" clipRule="evenodd" d="M72.2493 22.3413L78.8373 34.2013C78.9547 34.3213 79.0267 34.4853 79.0267 34.6667C79.0267 35.0347 78.728 35.3333 78.36 35.3333H67V41.3333C67 41.7013 66.7013 42 66.3333 42H62.3333C61.9653 42 61.6667 41.7013 61.6667 41.3333V35.3333H50.3333C50.0973 35.3333 49.8787 35.208 49.7587 35.0053C49.6387 34.8013 49.636 34.5493 49.7507 34.3427L56.4173 22.3427C56.5347 22.132 56.7573 22 57 22H71.6667C71.9093 22 72.132 22.132 72.2493 22.3413ZM68.8347 34L68.2427 31.3333H60.424L59.832 34H68.8347ZM61.312 27.3333L60.72 30H67.9453L67.3533 27.3333H61.312ZM53.6893 30H59.3547L59.9467 27.3333H55.1707L53.6893 30ZM67.0573 26L66.4653 23.3333H62.2013L61.6093 26H67.0573ZM73.496 27.3333H68.72L69.312 30H74.9773L73.496 27.3333ZM68.424 26H72.756L71.2747 23.3333H67.832L68.424 26ZM60.8347 23.3333H57.392L55.9107 26H60.2427L60.8347 23.3333ZM59.0573 31.3333H52.948L51.4667 34H58.4653L59.0573 31.3333ZM63 40.6667H65.6667V35.3333H63V40.6667ZM69.6093 31.3333L70.2013 34H77.2L75.7187 31.3333H69.6093Z" fill="black" fillOpacity="0.5" />
                        </G>
                    </G>
                    <G style={{mixBlendMode:'overlay'}}>
                        <Path d="M135 10.6667L135.556 10.2993C135.433 10.1124 135.224 10 135 10C134.776 10 134.567 10.1124 134.444 10.2993L135 10.6667Z" fill="black" fillOpacity="0.5" />
                        <Path d="M132.983 22.9768C133.155 22.6509 133.029 22.2478 132.703 22.0765C132.378 21.9052 131.974 22.0306 131.803 22.3565C130.99 23.9045 130.131 25.6409 129.474 27.2239C128.831 28.7723 128.333 30.2832 128.333 31.3333C128.333 33.311 129.194 35.0853 130.558 36.3051C130.833 36.5504 131.254 36.5268 131.5 36.2522C131.745 35.9777 131.721 35.5563 131.447 35.3109C130.353 34.3334 129.667 32.9157 129.667 31.3333C129.667 30.5821 130.056 29.2997 130.705 27.7351C131.34 26.2051 132.178 24.5088 132.983 22.9768Z" fill="black" fillOpacity="0.5" />
                        <Path fillRule="evenodd" clipRule="evenodd" d="M135 10.6667C135.556 10.2993 135.556 10.2991 135.556 10.2993L135.564 10.311L135.586 10.3444L135.671 10.4738C135.745 10.5871 135.853 10.7537 135.99 10.9673C136.265 11.3946 136.658 12.0105 137.128 12.7667C138.07 14.2785 139.325 16.3534 140.582 18.6034C141.837 20.8513 143.1 23.285 144.051 25.512C144.989 27.7108 145.667 29.8037 145.667 31.3333C145.667 37.2256 140.891 42 135 42C129.106 42 124.333 37.2255 124.333 31.3333C124.333 29.8037 125.011 27.7108 125.949 25.512C126.9 23.285 128.162 20.8513 129.418 18.6034C130.675 16.3534 131.93 14.2785 132.872 12.7667C133.342 12.0105 133.735 11.3946 134.01 10.9673C134.147 10.7537 134.255 10.5871 134.329 10.4738L134.414 10.3444L134.436 10.311L134.444 10.2993C134.444 10.2991 134.444 10.2993 135 10.6667ZM134.003 13.4714C134.4 12.8347 134.74 12.2986 135 11.8928C135.26 12.2986 135.6 12.8347 135.997 13.4714C136.93 14.9715 138.175 17.0274 139.418 19.2536C140.662 21.4819 141.9 23.8696 142.824 26.0354C143.761 28.2296 144.333 30.1009 144.333 31.3333C144.333 36.4891 140.155 40.6667 135 40.6667C129.843 40.6667 125.667 36.4892 125.667 31.3333C125.667 30.1009 126.239 28.2296 127.176 26.0354C128.1 23.8696 129.337 21.4819 130.582 19.2536C131.825 17.0274 133.07 14.9715 134.003 13.4714Z" fill="black" fillOpacity="0.5" />
                    </G>
                    <G clipPath={"url(#" + makeUnique("clip2_1726_19010") + ")"}>
                        <G style={{mixBlendMode:'overlay'}}>
                            <Path d="M222.333 40.6667H209.667V30.7573C211.18 32.232 213.405 33.852 215.973 33.9533C216.059 33.9573 216.144 33.9587 216.229 33.9587C218.104 33.9587 219.867 33.1387 221.471 31.5187C221.917 31.0667 222.104 30.6187 222.023 30.1853C221.983 29.9733 221.836 29.5813 221.308 29.3467L209.772 24.0333C211.161 21.3347 212.46 17.6187 211.26 14.5213C210.488 12.5293 208.796 11.0573 206.233 10.1493C205.633 9.93866 205.148 9.956 204.791 10.2107C204.619 10.3347 204.329 10.6307 204.333 11.204V23.82C201.139 23.0747 197.096 23.048 194.425 25.0613C192.62 26.424 191.703 28.5053 191.703 31.248C191.703 31.884 191.887 32.3333 192.248 32.584C192.367 32.6667 192.576 32.772 192.863 32.772C193.005 32.772 193.168 32.7453 193.348 32.6787L204.333 28.648V40.6667H191.667C191.299 40.6667 191 40.9653 191 41.3333C191 41.7013 191.299 42 191.667 42H205H209H222.333C222.701 42 223 41.7013 223 41.3333C223 40.9653 222.701 40.6667 222.333 40.6667ZM220.525 30.5813C219.123 31.9973 217.62 32.68 216.027 32.6227C213.296 32.5147 210.925 30.268 209.667 28.816V25.4533L220.607 30.4933C220.584 30.52 220.557 30.548 220.525 30.5813ZM205.789 11.408C207.969 12.18 209.392 13.3893 210.017 15.004C210.907 17.3013 210.119 20.1187 209.069 22.428C208.581 21.82 207.84 21.4213 207 21.4213C206.512 21.4213 206.06 21.5613 205.667 21.792L205.668 11.368C205.704 11.3773 205.744 11.3907 205.789 11.408ZM193.04 31.3667C193.037 31.3333 193.036 31.2933 193.036 31.248C193.036 28.9467 193.773 27.2227 195.228 26.1267C197.617 24.3253 201.508 24.4827 204.333 25.192V27.2267L193.04 31.3667ZM205.667 40.6667V24.088C205.667 23.352 206.265 22.7547 207 22.7547C207.735 22.7547 208.333 23.352 208.333 24.088V40.6667H205.667Z" fill="black" fillOpacity="0.5" />
                        </G>
                    </G>
                    <G clipPath={"url(#" + makeUnique("clip3_1726_19010") + ")"}>
                        <G style={{mixBlendMode:'overlay'}}>
                            <Path d="M276.333 12C276.333 12.368 276.035 12.6667 275.667 12.6667C275.299 12.6667 275 12.368 275 12V10.6667C275 10.2987 275.299 10 275.667 10C276.035 10 276.333 10.2987 276.333 10.6667V12Z" fill="black" fillOpacity="0.5" />
                            <Path d="M267.667 15.336C267.496 15.336 267.325 15.2707 267.195 15.14L265.863 13.808C265.603 13.548 265.603 13.1253 265.863 12.8653C266.123 12.6053 266.545 12.6053 266.805 12.8653L268.139 14.1987C268.399 14.4587 268.399 14.8813 268.139 15.1413C268.008 15.2707 267.837 15.336 267.667 15.336Z" fill="black" fillOpacity="0.5" />
                            <Path d="M283.667 15.3333C283.837 15.3333 284.008 15.268 284.139 15.1387L285.472 13.8053C285.732 13.5453 285.732 13.1227 285.472 12.8627C285.212 12.6027 284.789 12.6027 284.529 12.8627L283.196 14.196C282.936 14.456 282.936 14.8787 283.196 15.1387C283.325 15.268 283.496 15.3333 283.667 15.3333Z" fill="black" fillOpacity="0.5" />
                            <Path d="M263.667 22H265C265.368 22 265.667 22.2987 265.667 22.6667C265.667 23.0347 265.368 23.3333 265 23.3333H263.667C263.299 23.3333 263 23.0347 263 22.6667C263 22.2987 263.299 22 263.667 22Z" fill="black" fillOpacity="0.5" />
                            <Path d="M267.484 25.5C267.581 25.7747 267.839 25.9453 268.113 25.9453C268.187 25.9453 268.261 25.9333 268.336 25.908C268.683 25.7853 268.865 25.404 268.743 25.0573C268.471 24.2907 268.333 23.4853 268.333 22.6667C268.333 18.624 271.623 15.3333 275.667 15.3333C277.756 15.3333 279.755 16.2347 281.149 17.8067C281.393 18.0827 281.816 18.1053 282.091 17.8627C282.367 17.6173 282.391 17.1973 282.147 16.9213C280.5 15.0653 278.137 14 275.667 14C270.888 14 267 17.888 267 22.6667C267 23.6373 267.163 24.5907 267.484 25.5Z" fill="black" fillOpacity="0.5" />
                            <Path fillRule="evenodd" clipRule="evenodd" d="M288.249 22.3413L294.837 34.2013C294.955 34.3213 295.027 34.4853 295.027 34.6667C295.027 35.0347 294.728 35.3333 294.36 35.3333H283V41.3333C283 41.7013 282.701 42 282.333 42H278.333C277.965 42 277.667 41.7013 277.667 41.3333V35.3333H266.333C266.097 35.3333 265.879 35.208 265.759 35.0053C265.639 34.8013 265.636 34.5493 265.751 34.3427L272.417 22.3427C272.535 22.132 272.757 22 273 22H287.667C287.909 22 288.132 22.132 288.249 22.3413ZM284.835 34L284.243 31.3333H276.424L275.832 34H284.835ZM277.312 27.3333L276.72 30H283.945L283.353 27.3333H277.312ZM269.689 30H275.355L275.947 27.3333H271.171L269.689 30ZM283.057 26L282.465 23.3333H278.201L277.609 26H283.057ZM289.496 27.3333H284.72L285.312 30H290.977L289.496 27.3333ZM284.424 26H288.756L287.275 23.3333H283.832L284.424 26ZM276.835 23.3333H273.392L271.911 26H276.243L276.835 23.3333ZM275.057 31.3333H268.948L267.467 34H274.465L275.057 31.3333ZM279 40.6667H281.667V35.3333H279V40.6667ZM285.609 31.3333L286.201 34H293.2L291.719 31.3333H285.609Z" fill="black" fillOpacity="0.5" />
                        </G>
                    </G>
                    <G style={{mixBlendMode:'overlay'}}>
                        <Path d="M351 10.6667L351.556 10.2993C351.433 10.1124 351.224 10 351 10C350.776 10 350.567 10.1124 350.444 10.2993L351 10.6667Z" fill="black" fillOpacity="0.5" />
                        <Path d="M348.983 22.9768C349.155 22.6509 349.029 22.2478 348.703 22.0765C348.378 21.9052 347.974 22.0306 347.803 22.3565C346.99 23.9045 346.131 25.6409 345.474 27.2239C344.831 28.7723 344.333 30.2832 344.333 31.3333C344.333 33.311 345.194 35.0853 346.558 36.3051C346.833 36.5504 347.254 36.5268 347.5 36.2522C347.745 35.9777 347.721 35.5563 347.447 35.3109C346.353 34.3334 345.667 32.9157 345.667 31.3333C345.667 30.5821 346.056 29.2997 346.705 27.7351C347.34 26.2051 348.178 24.5088 348.983 22.9768Z" fill="black" fillOpacity="0.5" />
                        <Path fillRule="evenodd" clipRule="evenodd" d="M351 10.6667C351.556 10.2993 351.556 10.2991 351.556 10.2993L351.564 10.311L351.586 10.3444L351.671 10.4738C351.745 10.5871 351.853 10.7537 351.99 10.9673C352.265 11.3946 352.658 12.0105 353.128 12.7667C354.07 14.2785 355.325 16.3534 356.582 18.6034C357.837 20.8513 359.1 23.285 360.051 25.512C360.989 27.7108 361.667 29.8037 361.667 31.3333C361.667 37.2256 356.891 42 351 42C345.106 42 340.333 37.2255 340.333 31.3333C340.333 29.8037 341.011 27.7108 341.949 25.512C342.9 23.285 344.162 20.8513 345.418 18.6034C346.675 16.3534 347.93 14.2785 348.872 12.7667C349.342 12.0105 349.735 11.3946 350.01 10.9673C350.147 10.7537 350.255 10.5871 350.329 10.4738L350.414 10.3444L350.436 10.311L350.444 10.2993C350.444 10.2991 350.444 10.2993 351 10.6667ZM350.003 13.4714C350.4 12.8347 350.74 12.2986 351 11.8928C351.26 12.2986 351.6 12.8347 351.997 13.4714C352.93 14.9715 354.175 17.0274 355.418 19.2536C356.662 21.4819 357.9 23.8696 358.824 26.0354C359.761 28.2296 360.333 30.1009 360.333 31.3333C360.333 36.4891 356.155 40.6667 351 40.6667C345.843 40.6667 341.667 36.4892 341.667 31.3333C341.667 30.1009 342.239 28.2296 343.176 26.0354C344.1 23.8696 345.337 21.4819 346.582 19.2536C347.825 17.0274 349.07 14.9715 350.003 13.4714Z" fill="black" fillOpacity="0.5" />
                    </G>
                </G>
                <G style={{mixBlendMode:'overlay'}}>
                    <G clipPath={"url(#" + makeUnique("clip4_1726_19010") + ")"}>
                        <G style={{mixBlendMode:'overlay'}}>
                            <Path d="M24.3333 60C24.3333 60.368 24.0347 60.6667 23.6667 60.6667C23.2987 60.6667 23 60.368 23 60V58.6667C23 58.2987 23.2987 58 23.6667 58C24.0347 58 24.3333 58.2987 24.3333 58.6667V60Z" fill="black" fillOpacity="0.5" />
                            <Path d="M15.6667 63.336C15.496 63.336 15.3253 63.2707 15.1947 63.14L13.8627 61.808C13.6027 61.548 13.6027 61.1253 13.8627 60.8653C14.1227 60.6053 14.5453 60.6053 14.8053 60.8653L16.1387 62.1987C16.3987 62.4587 16.3987 62.8813 16.1387 63.1413C16.008 63.2707 15.8373 63.336 15.6667 63.336Z" fill="black" fillOpacity="0.5" />
                            <Path d="M31.6667 63.3333C31.8373 63.3333 32.008 63.268 32.1387 63.1387L33.472 61.8053C33.732 61.5453 33.732 61.1227 33.472 60.8627C33.212 60.6027 32.7893 60.6027 32.5293 60.8627L31.196 62.196C30.936 62.456 30.936 62.8787 31.196 63.1387C31.3253 63.268 31.496 63.3333 31.6667 63.3333Z" fill="black" fillOpacity="0.5" />
                            <Path d="M11.6667 70H13C13.368 70 13.6667 70.2987 13.6667 70.6667C13.6667 71.0347 13.368 71.3333 13 71.3333H11.6667C11.2987 71.3333 11 71.0347 11 70.6667C11 70.2987 11.2987 70 11.6667 70Z" fill="black" fillOpacity="0.5" />
                            <Path d="M15.484 73.5C15.5813 73.7747 15.8387 73.9453 16.1133 73.9453C16.1867 73.9453 16.2613 73.9333 16.336 73.908C16.6827 73.7853 16.8653 73.404 16.7427 73.0573C16.4707 72.2907 16.3333 71.4853 16.3333 70.6667C16.3333 66.624 19.6227 63.3333 23.6667 63.3333C25.756 63.3333 27.7547 64.2347 29.1493 65.8067C29.3933 66.0827 29.816 66.1053 30.0907 65.8627C30.3667 65.6173 30.3907 65.1973 30.1467 64.9213C28.5 63.0653 26.1373 62 23.6667 62C18.888 62 15 65.888 15 70.6667C15 71.6373 15.1627 72.5907 15.484 73.5Z" fill="black" fillOpacity="0.5" />
                            <Path fillRule="evenodd" clipRule="evenodd" d="M36.2493 70.3413L42.8373 82.2013C42.9547 82.3213 43.0267 82.4853 43.0267 82.6667C43.0267 83.0347 42.728 83.3333 42.36 83.3333H31V89.3333C31 89.7013 30.7013 90 30.3333 90H26.3333C25.9653 90 25.6667 89.7013 25.6667 89.3333V83.3333H14.3333C14.0973 83.3333 13.8787 83.208 13.7587 83.0053C13.6387 82.8013 13.636 82.5493 13.7507 82.3427L20.4173 70.3427C20.5347 70.132 20.7573 70 21 70H35.6667C35.9093 70 36.132 70.132 36.2493 70.3413ZM32.8347 82L32.2427 79.3333H24.424L23.832 82H32.8347ZM25.312 75.3333L24.72 78H31.9453L31.3533 75.3333H25.312ZM17.6893 78H23.3547L23.9467 75.3333H19.1707L17.6893 78ZM31.0573 74L30.4653 71.3333H26.2013L25.6093 74H31.0573ZM37.496 75.3333H32.72L33.312 78H38.9773L37.496 75.3333ZM32.424 74H36.756L35.2747 71.3333H31.832L32.424 74ZM24.8347 71.3333H21.392L19.9107 74H24.2427L24.8347 71.3333ZM23.0573 79.3333H16.948L15.4667 82H22.4653L23.0573 79.3333ZM27 88.6667H29.6667V83.3333H27V88.6667ZM33.6093 79.3333L34.2013 82H41.2L39.7187 79.3333H33.6093Z" fill="black" fillOpacity="0.5" />
                        </G>
                    </G>
                    <G clipPath={"url(#" + makeUnique("clip5_1726_19010") + ")"}>
                        <G style={{mixBlendMode:'overlay'}}>
                            <Path d="M114.333 88.6667H101.667V78.7573C103.18 80.232 105.405 81.852 107.973 81.9533C108.059 81.9573 108.144 81.9587 108.229 81.9587C110.104 81.9587 111.867 81.1387 113.471 79.5187C113.917 79.0667 114.104 78.6187 114.023 78.1853C113.983 77.9733 113.836 77.5813 113.308 77.3467L101.772 72.0333C103.161 69.3347 104.46 65.6187 103.26 62.5213C102.488 60.5293 100.796 59.0573 98.2333 58.1493C97.6333 57.9387 97.148 57.956 96.7907 58.2107C96.6187 58.3347 96.3293 58.6307 96.3333 59.204V71.82C93.1387 71.0747 89.096 71.048 86.4253 73.0613C84.62 74.424 83.7027 76.5053 83.7027 79.248C83.7027 79.884 83.8867 80.3333 84.248 80.584C84.3667 80.6667 84.576 80.772 84.8627 80.772C85.0053 80.772 85.168 80.7453 85.348 80.6787L96.3333 76.648V88.6667H83.6667C83.2987 88.6667 83 88.9653 83 89.3333C83 89.7013 83.2987 90 83.6667 90H97H101H114.333C114.701 90 115 89.7013 115 89.3333C115 88.9653 114.701 88.6667 114.333 88.6667ZM112.525 78.5813C111.123 79.9973 109.62 80.68 108.027 80.6227C105.296 80.5147 102.925 78.268 101.667 76.816V73.4533L112.607 78.4933C112.584 78.52 112.557 78.548 112.525 78.5813ZM97.7893 59.408C99.9693 60.18 101.392 61.3893 102.017 63.004C102.907 65.3013 102.119 68.1187 101.069 70.428C100.581 69.82 99.84 69.4213 99 69.4213C98.512 69.4213 98.06 69.5613 97.6667 69.792L97.668 59.368C97.704 59.3773 97.744 59.3907 97.7893 59.408ZM85.04 79.3667C85.0373 79.3333 85.036 79.2933 85.036 79.248C85.036 76.9467 85.7733 75.2227 87.228 74.1267C89.6173 72.3253 93.508 72.4827 96.3333 73.192V75.2267L85.04 79.3667ZM97.6667 88.6667V72.088C97.6667 71.352 98.2653 70.7547 99 70.7547C99.7347 70.7547 100.333 71.352 100.333 72.088V88.6667H97.6667Z" fill="black" fillOpacity="0.5" />
                        </G>
                    </G>
                    <G style={{mixBlendMode:'overlay'}}>
                        <Path d="M171 58.6667L171.556 58.2993C171.433 58.1124 171.224 58 171 58C170.776 58 170.567 58.1124 170.444 58.2993L171 58.6667Z" fill="black" fillOpacity="0.5" />
                        <Path d="M168.983 70.9768C169.155 70.6509 169.029 70.2478 168.703 70.0765C168.378 69.9052 167.974 70.0306 167.803 70.3565C166.99 71.9045 166.131 73.6409 165.474 75.2239C164.831 76.7723 164.333 78.2832 164.333 79.3333C164.333 81.311 165.194 83.0853 166.558 84.3051C166.833 84.5504 167.254 84.5268 167.5 84.2522C167.745 83.9777 167.721 83.5563 167.447 83.3109C166.353 82.3334 165.667 80.9157 165.667 79.3333C165.667 78.5821 166.056 77.2997 166.705 75.7351C167.34 74.2051 168.178 72.5088 168.983 70.9768Z" fill="black" fillOpacity="0.5" />
                        <Path fillRule="evenodd" clipRule="evenodd" d="M171 58.6667C171.556 58.2993 171.556 58.2991 171.556 58.2993L171.564 58.311L171.586 58.3444L171.671 58.4738C171.745 58.5871 171.853 58.7537 171.99 58.9673C172.265 59.3946 172.658 60.0105 173.128 60.7667C174.07 62.2785 175.325 64.3534 176.582 66.6034C177.837 68.8513 179.1 71.285 180.051 73.512C180.989 75.7108 181.667 77.8037 181.667 79.3333C181.667 85.2256 176.891 90 171 90C165.106 90 160.333 85.2255 160.333 79.3333C160.333 77.8037 161.011 75.7108 161.949 73.512C162.9 71.285 164.162 68.8513 165.418 66.6034C166.675 64.3534 167.93 62.2785 168.872 60.7667C169.342 60.0105 169.735 59.3946 170.01 58.9673C170.147 58.7537 170.255 58.5871 170.329 58.4738L170.414 58.3444L170.436 58.311L170.444 58.2993C170.444 58.2991 170.444 58.2993 171 58.6667ZM170.003 61.4714C170.4 60.8347 170.74 60.2986 171 59.8928C171.26 60.2986 171.6 60.8347 171.997 61.4714C172.93 62.9715 174.175 65.0274 175.418 67.2536C176.662 69.4819 177.9 71.8696 178.824 74.0354C179.761 76.2296 180.333 78.1009 180.333 79.3333C180.333 84.4891 176.155 88.6667 171 88.6667C165.843 88.6667 161.667 84.4892 161.667 79.3333C161.667 78.1009 162.239 76.2296 163.176 74.0354C164.1 71.8696 165.337 69.4819 166.582 67.2536C167.825 65.0274 169.07 62.9715 170.003 61.4714Z" fill="black" fillOpacity="0.5" />
                    </G>
                    <G clipPath={"url(#" + makeUnique("clip6_1726_19010") + ")"}>
                        <G style={{mixBlendMode:'overlay'}}>
                            <Path d="M240.333 60C240.333 60.368 240.035 60.6667 239.667 60.6667C239.299 60.6667 239 60.368 239 60V58.6667C239 58.2987 239.299 58 239.667 58C240.035 58 240.333 58.2987 240.333 58.6667V60Z" fill="black" fillOpacity="0.5" />
                            <Path d="M231.667 63.336C231.496 63.336 231.325 63.2707 231.195 63.14L229.863 61.808C229.603 61.548 229.603 61.1253 229.863 60.8653C230.123 60.6053 230.545 60.6053 230.805 60.8653L232.139 62.1987C232.399 62.4587 232.399 62.8813 232.139 63.1413C232.008 63.2707 231.837 63.336 231.667 63.336Z" fill="black" fillOpacity="0.5" />
                            <Path d="M247.667 63.3333C247.837 63.3333 248.008 63.268 248.139 63.1387L249.472 61.8053C249.732 61.5453 249.732 61.1227 249.472 60.8627C249.212 60.6027 248.789 60.6027 248.529 60.8627L247.196 62.196C246.936 62.456 246.936 62.8787 247.196 63.1387C247.325 63.268 247.496 63.3333 247.667 63.3333Z" fill="black" fillOpacity="0.5" />
                            <Path d="M227.667 70H229C229.368 70 229.667 70.2987 229.667 70.6667C229.667 71.0347 229.368 71.3333 229 71.3333H227.667C227.299 71.3333 227 71.0347 227 70.6667C227 70.2987 227.299 70 227.667 70Z" fill="black" fillOpacity="0.5" />
                            <Path d="M231.484 73.5C231.581 73.7747 231.839 73.9453 232.113 73.9453C232.187 73.9453 232.261 73.9333 232.336 73.908C232.683 73.7853 232.865 73.404 232.743 73.0573C232.471 72.2907 232.333 71.4853 232.333 70.6667C232.333 66.624 235.623 63.3333 239.667 63.3333C241.756 63.3333 243.755 64.2347 245.149 65.8067C245.393 66.0827 245.816 66.1053 246.091 65.8627C246.367 65.6173 246.391 65.1973 246.147 64.9213C244.5 63.0653 242.137 62 239.667 62C234.888 62 231 65.888 231 70.6667C231 71.6373 231.163 72.5907 231.484 73.5Z" fill="black" fillOpacity="0.5" />
                            <Path fillRule="evenodd" clipRule="evenodd" d="M252.249 70.3413L258.837 82.2013C258.955 82.3213 259.027 82.4853 259.027 82.6667C259.027 83.0347 258.728 83.3333 258.36 83.3333H247V89.3333C247 89.7013 246.701 90 246.333 90H242.333C241.965 90 241.667 89.7013 241.667 89.3333V83.3333H230.333C230.097 83.3333 229.879 83.208 229.759 83.0053C229.639 82.8013 229.636 82.5493 229.751 82.3427L236.417 70.3427C236.535 70.132 236.757 70 237 70H251.667C251.909 70 252.132 70.132 252.249 70.3413ZM248.835 82L248.243 79.3333H240.424L239.832 82H248.835ZM241.312 75.3333L240.72 78H247.945L247.353 75.3333H241.312ZM233.689 78H239.355L239.947 75.3333H235.171L233.689 78ZM247.057 74L246.465 71.3333H242.201L241.609 74H247.057ZM253.496 75.3333H248.72L249.312 78H254.977L253.496 75.3333ZM248.424 74H252.756L251.275 71.3333H247.832L248.424 74ZM240.835 71.3333H237.392L235.911 74H240.243L240.835 71.3333ZM239.057 79.3333H232.948L231.467 82H238.465L239.057 79.3333ZM243 88.6667H245.667V83.3333H243V88.6667ZM249.609 79.3333L250.201 82H257.2L255.719 79.3333H249.609Z" fill="black" fillOpacity="0.5" />
                        </G>
                    </G>
                    <G clipPath={"url(#" + makeUnique("clip7_1726_19010") + ")"}>
                        <G style={{mixBlendMode:'overlay'}}>
                            <Path d="M330.333 88.6667H317.667V78.7573C319.18 80.232 321.405 81.852 323.973 81.9533C324.059 81.9573 324.144 81.9587 324.229 81.9587C326.104 81.9587 327.867 81.1387 329.471 79.5187C329.917 79.0667 330.104 78.6187 330.023 78.1853C329.983 77.9733 329.836 77.5813 329.308 77.3467L317.772 72.0333C319.161 69.3347 320.46 65.6187 319.26 62.5213C318.488 60.5293 316.796 59.0573 314.233 58.1493C313.633 57.9387 313.148 57.956 312.791 58.2107C312.619 58.3347 312.329 58.6307 312.333 59.204V71.82C309.139 71.0747 305.096 71.048 302.425 73.0613C300.62 74.424 299.703 76.5053 299.703 79.248C299.703 79.884 299.887 80.3333 300.248 80.584C300.367 80.6667 300.576 80.772 300.863 80.772C301.005 80.772 301.168 80.7453 301.348 80.6787L312.333 76.648V88.6667H299.667C299.299 88.6667 299 88.9653 299 89.3333C299 89.7013 299.299 90 299.667 90H313H317H330.333C330.701 90 331 89.7013 331 89.3333C331 88.9653 330.701 88.6667 330.333 88.6667ZM328.525 78.5813C327.123 79.9973 325.62 80.68 324.027 80.6227C321.296 80.5147 318.925 78.268 317.667 76.816V73.4533L328.607 78.4933C328.584 78.52 328.557 78.548 328.525 78.5813ZM313.789 59.408C315.969 60.18 317.392 61.3893 318.017 63.004C318.907 65.3013 318.119 68.1187 317.069 70.428C316.581 69.82 315.84 69.4213 315 69.4213C314.512 69.4213 314.06 69.5613 313.667 69.792L313.668 59.368C313.704 59.3773 313.744 59.3907 313.789 59.408ZM301.04 79.3667C301.037 79.3333 301.036 79.2933 301.036 79.248C301.036 76.9467 301.773 75.2227 303.228 74.1267C305.617 72.3253 309.508 72.4827 312.333 73.192V75.2267L301.04 79.3667ZM313.667 88.6667V72.088C313.667 71.352 314.265 70.7547 315 70.7547C315.735 70.7547 316.333 71.352 316.333 72.088V88.6667H313.667Z" fill="black" fillOpacity="0.5" />
                        </G>
                    </G>
                </G>
                <G style={{mixBlendMode:'overlay'}}>
                    <G clipPath={"url(#" + makeUnique("clip8_1726_19010") + ")"}>
                        <G style={{mixBlendMode:'overlay'}}>
                            <Path d="M6.33333 136.667H-6.33333V126.757C-4.82 128.232 -2.59467 129.852 -0.0266666 129.953C0.0586662 129.957 0.144001 129.959 0.229336 129.959C2.104 129.959 3.86667 129.139 5.47067 127.519C5.91734 127.067 6.104 126.619 6.02267 126.185C5.98267 125.973 5.836 125.581 5.308 125.347L-6.228 120.033C-4.83867 117.335 -3.54 113.619 -4.74 110.521C-5.512 108.529 -7.204 107.057 -9.76667 106.149C-10.3667 105.939 -10.852 105.956 -11.2093 106.211C-11.3813 106.335 -11.6707 106.631 -11.6667 107.204V119.82C-14.8613 119.075 -18.904 119.048 -21.5747 121.061C-23.38 122.424 -24.2973 124.505 -24.2973 127.248C-24.2973 127.884 -24.1133 128.333 -23.752 128.584C-23.6333 128.667 -23.424 128.772 -23.1373 128.772C-22.9947 128.772 -22.832 128.745 -22.652 128.679L-11.6667 124.648V136.667H-24.3333C-24.7013 136.667 -25 136.965 -25 137.333C-25 137.701 -24.7013 138 -24.3333 138H-11H-7H6.33333C6.70133 138 7 137.701 7 137.333C7 136.965 6.70133 136.667 6.33333 136.667ZM4.52533 126.581C3.12267 127.997 1.62 128.68 0.0266685 128.623C-2.704 128.515 -5.07467 126.268 -6.33333 124.816V121.453L4.60667 126.493C4.584 126.52 4.55733 126.548 4.52533 126.581ZM-10.2107 107.408C-8.03067 108.18 -6.608 109.389 -5.98267 111.004C-5.09333 113.301 -5.88133 116.119 -6.93067 118.428C-7.41867 117.82 -8.16 117.421 -9 117.421C-9.488 117.421 -9.94 117.561 -10.3333 117.792L-10.332 107.368C-10.296 107.377 -10.256 107.391 -10.2107 107.408ZM-22.96 127.367C-22.9627 127.333 -22.964 127.293 -22.964 127.248C-22.964 124.947 -22.2267 123.223 -20.772 122.127C-18.3827 120.325 -14.492 120.483 -11.6667 121.192V123.227L-22.96 127.367ZM-10.3333 136.667V120.088C-10.3333 119.352 -9.73467 118.755 -9 118.755C-8.26533 118.755 -7.66667 119.352 -7.66667 120.088V136.667H-10.3333Z" fill="black" fillOpacity="0.5" />
                        </G>
                    </G>
                    <G clipPath={"url(#" + makeUnique("clip9_1726_19010") + ")"}>
                        <G style={{mixBlendMode:'overlay'}}>
                            <Path d="M60.3333 108C60.3333 108.368 60.0347 108.667 59.6667 108.667C59.2987 108.667 59 108.368 59 108V106.667C59 106.299 59.2987 106 59.6667 106C60.0347 106 60.3333 106.299 60.3333 106.667V108Z" fill="black" fillOpacity="0.5" />
                            <Path d="M51.6667 111.336C51.496 111.336 51.3253 111.271 51.1947 111.14L49.8627 109.808C49.6027 109.548 49.6027 109.125 49.8627 108.865C50.1227 108.605 50.5453 108.605 50.8053 108.865L52.1387 110.199C52.3987 110.459 52.3987 110.881 52.1387 111.141C52.008 111.271 51.8373 111.336 51.6667 111.336Z" fill="black" fillOpacity="0.5" />
                            <Path d="M67.6667 111.333C67.8373 111.333 68.008 111.268 68.1387 111.139L69.472 109.805C69.732 109.545 69.732 109.123 69.472 108.863C69.212 108.603 68.7893 108.603 68.5293 108.863L67.196 110.196C66.936 110.456 66.936 110.879 67.196 111.139C67.3253 111.268 67.496 111.333 67.6667 111.333Z" fill="black" fillOpacity="0.5" />
                            <Path d="M47.6667 118H49C49.368 118 49.6667 118.299 49.6667 118.667C49.6667 119.035 49.368 119.333 49 119.333H47.6667C47.2987 119.333 47 119.035 47 118.667C47 118.299 47.2987 118 47.6667 118Z" fill="black" fillOpacity="0.5" />
                            <Path d="M51.484 121.5C51.5813 121.775 51.8387 121.945 52.1133 121.945C52.1867 121.945 52.2613 121.933 52.336 121.908C52.6827 121.785 52.8653 121.404 52.7427 121.057C52.4707 120.291 52.3333 119.485 52.3333 118.667C52.3333 114.624 55.6227 111.333 59.6667 111.333C61.756 111.333 63.7547 112.235 65.1493 113.807C65.3933 114.083 65.816 114.105 66.0907 113.863C66.3667 113.617 66.3907 113.197 66.1467 112.921C64.5 111.065 62.1373 110 59.6667 110C54.888 110 51 113.888 51 118.667C51 119.637 51.1627 120.591 51.484 121.5Z" fill="black" fillOpacity="0.5" />
                            <Path fillRule="evenodd" clipRule="evenodd" d="M72.2493 118.341L78.8373 130.201C78.9547 130.321 79.0267 130.485 79.0267 130.667C79.0267 131.035 78.728 131.333 78.36 131.333H67V137.333C67 137.701 66.7013 138 66.3333 138H62.3333C61.9653 138 61.6667 137.701 61.6667 137.333V131.333H50.3333C50.0973 131.333 49.8787 131.208 49.7587 131.005C49.6387 130.801 49.636 130.549 49.7507 130.343L56.4173 118.343C56.5347 118.132 56.7573 118 57 118H71.6667C71.9093 118 72.132 118.132 72.2493 118.341ZM68.8347 130L68.2427 127.333H60.424L59.832 130H68.8347ZM61.312 123.333L60.72 126H67.9453L67.3533 123.333H61.312ZM53.6893 126H59.3547L59.9467 123.333H55.1707L53.6893 126ZM67.0573 122L66.4653 119.333H62.2013L61.6093 122H67.0573ZM73.496 123.333H68.72L69.312 126H74.9773L73.496 123.333ZM68.424 122H72.756L71.2747 119.333H67.832L68.424 122ZM60.8347 119.333H57.392L55.9107 122H60.2427L60.8347 119.333ZM59.0573 127.333H52.948L51.4667 130H58.4653L59.0573 127.333ZM63 136.667H65.6667V131.333H63V136.667ZM69.6093 127.333L70.2013 130H77.2L75.7187 127.333H69.6093Z" fill="black" fillOpacity="0.5" />
                        </G>
                    </G>
                    <G style={{mixBlendMode:'overlay'}}>
                        <Path d="M135 106.667L135.556 106.299C135.433 106.112 135.224 106 135 106C134.776 106 134.567 106.112 134.444 106.299L135 106.667Z" fill="black" fillOpacity="0.5" />
                        <Path d="M132.983 118.977C133.155 118.651 133.029 118.248 132.703 118.077C132.378 117.905 131.974 118.031 131.803 118.357C130.99 119.904 130.131 121.641 129.474 123.224C128.831 124.772 128.333 126.283 128.333 127.333C128.333 129.311 129.194 131.085 130.558 132.305C130.833 132.55 131.254 132.527 131.5 132.252C131.745 131.978 131.721 131.556 131.447 131.311C130.353 130.333 129.667 128.916 129.667 127.333C129.667 126.582 130.056 125.3 130.705 123.735C131.34 122.205 132.178 120.509 132.983 118.977Z" fill="black" fillOpacity="0.5" />
                        <Path fillRule="evenodd" clipRule="evenodd" d="M135 106.667C135.556 106.299 135.556 106.299 135.556 106.299L135.564 106.311L135.586 106.344L135.671 106.474C135.745 106.587 135.853 106.754 135.99 106.967C136.265 107.395 136.658 108.011 137.128 108.767C138.07 110.278 139.325 112.353 140.582 114.603C141.837 116.851 143.1 119.285 144.051 121.512C144.989 123.711 145.667 125.804 145.667 127.333C145.667 133.226 140.891 138 135 138C129.106 138 124.333 133.225 124.333 127.333C124.333 125.804 125.011 123.711 125.949 121.512C126.9 119.285 128.162 116.851 129.418 114.603C130.675 112.353 131.93 110.278 132.872 108.767C133.342 108.011 133.735 107.395 134.01 106.967C134.147 106.754 134.255 106.587 134.329 106.474L134.414 106.344L134.436 106.311L134.444 106.299C134.444 106.299 134.444 106.299 135 106.667ZM134.003 109.471C134.4 108.835 134.74 108.299 135 107.893C135.26 108.299 135.6 108.835 135.997 109.471C136.93 110.971 138.175 113.027 139.418 115.254C140.662 117.482 141.9 119.87 142.824 122.035C143.761 124.23 144.333 126.101 144.333 127.333C144.333 132.489 140.155 136.667 135 136.667C129.843 136.667 125.667 132.489 125.667 127.333C125.667 126.101 126.239 124.23 127.176 122.035C128.1 119.87 129.337 117.482 130.582 115.254C131.825 113.027 133.07 110.971 134.003 109.471Z" fill="black" fillOpacity="0.5" />
                    </G>
                    <G clipPath={"url(#" + makeUnique("clip10_1726_19010") + ")"}>
                        <G style={{mixBlendMode:'overlay'}}>
                            <Path d="M222.333 136.667H209.667V126.757C211.18 128.232 213.405 129.852 215.973 129.953C216.059 129.957 216.144 129.959 216.229 129.959C218.104 129.959 219.867 129.139 221.471 127.519C221.917 127.067 222.104 126.619 222.023 126.185C221.983 125.973 221.836 125.581 221.308 125.347L209.772 120.033C211.161 117.335 212.46 113.619 211.26 110.521C210.488 108.529 208.796 107.057 206.233 106.149C205.633 105.939 205.148 105.956 204.791 106.211C204.619 106.335 204.329 106.631 204.333 107.204V119.82C201.139 119.075 197.096 119.048 194.425 121.061C192.62 122.424 191.703 124.505 191.703 127.248C191.703 127.884 191.887 128.333 192.248 128.584C192.367 128.667 192.576 128.772 192.863 128.772C193.005 128.772 193.168 128.745 193.348 128.679L204.333 124.648V136.667H191.667C191.299 136.667 191 136.965 191 137.333C191 137.701 191.299 138 191.667 138H205H209H222.333C222.701 138 223 137.701 223 137.333C223 136.965 222.701 136.667 222.333 136.667ZM220.525 126.581C219.123 127.997 217.62 128.68 216.027 128.623C213.296 128.515 210.925 126.268 209.667 124.816V121.453L220.607 126.493C220.584 126.52 220.557 126.548 220.525 126.581ZM205.789 107.408C207.969 108.18 209.392 109.389 210.017 111.004C210.907 113.301 210.119 116.119 209.069 118.428C208.581 117.82 207.84 117.421 207 117.421C206.512 117.421 206.06 117.561 205.667 117.792L205.668 107.368C205.704 107.377 205.744 107.391 205.789 107.408ZM193.04 127.367C193.037 127.333 193.036 127.293 193.036 127.248C193.036 124.947 193.773 123.223 195.228 122.127C197.617 120.325 201.508 120.483 204.333 121.192V123.227L193.04 127.367ZM205.667 136.667V120.088C205.667 119.352 206.265 118.755 207 118.755C207.735 118.755 208.333 119.352 208.333 120.088V136.667H205.667Z" fill="black" fillOpacity="0.5" />
                        </G>
                    </G>
                    <G clipPath={"url(#" + makeUnique("clip11_1726_19010") + ")"}>
                        <G style={{mixBlendMode:'overlay'}}>
                            <Path d="M276.333 108C276.333 108.368 276.035 108.667 275.667 108.667C275.299 108.667 275 108.368 275 108V106.667C275 106.299 275.299 106 275.667 106C276.035 106 276.333 106.299 276.333 106.667V108Z" fill="black" fillOpacity="0.5" />
                            <Path d="M267.667 111.336C267.496 111.336 267.325 111.271 267.195 111.14L265.863 109.808C265.603 109.548 265.603 109.125 265.863 108.865C266.123 108.605 266.545 108.605 266.805 108.865L268.139 110.199C268.399 110.459 268.399 110.881 268.139 111.141C268.008 111.271 267.837 111.336 267.667 111.336Z" fill="black" fillOpacity="0.5" />
                            <Path d="M283.667 111.333C283.837 111.333 284.008 111.268 284.139 111.139L285.472 109.805C285.732 109.545 285.732 109.123 285.472 108.863C285.212 108.603 284.789 108.603 284.529 108.863L283.196 110.196C282.936 110.456 282.936 110.879 283.196 111.139C283.325 111.268 283.496 111.333 283.667 111.333Z" fill="black" fillOpacity="0.5" />
                            <Path d="M263.667 118H265C265.368 118 265.667 118.299 265.667 118.667C265.667 119.035 265.368 119.333 265 119.333H263.667C263.299 119.333 263 119.035 263 118.667C263 118.299 263.299 118 263.667 118Z" fill="black" fillOpacity="0.5" />
                            <Path d="M267.484 121.5C267.581 121.775 267.839 121.945 268.113 121.945C268.187 121.945 268.261 121.933 268.336 121.908C268.683 121.785 268.865 121.404 268.743 121.057C268.471 120.291 268.333 119.485 268.333 118.667C268.333 114.624 271.623 111.333 275.667 111.333C277.756 111.333 279.755 112.235 281.149 113.807C281.393 114.083 281.816 114.105 282.091 113.863C282.367 113.617 282.391 113.197 282.147 112.921C280.5 111.065 278.137 110 275.667 110C270.888 110 267 113.888 267 118.667C267 119.637 267.163 120.591 267.484 121.5Z" fill="black" fillOpacity="0.5" />
                            <Path fillRule="evenodd" clipRule="evenodd" d="M288.249 118.341L294.837 130.201C294.955 130.321 295.027 130.485 295.027 130.667C295.027 131.035 294.728 131.333 294.36 131.333H283V137.333C283 137.701 282.701 138 282.333 138H278.333C277.965 138 277.667 137.701 277.667 137.333V131.333H266.333C266.097 131.333 265.879 131.208 265.759 131.005C265.639 130.801 265.636 130.549 265.751 130.343L272.417 118.343C272.535 118.132 272.757 118 273 118H287.667C287.909 118 288.132 118.132 288.249 118.341ZM284.835 130L284.243 127.333H276.424L275.832 130H284.835ZM277.312 123.333L276.72 126H283.945L283.353 123.333H277.312ZM269.689 126H275.355L275.947 123.333H271.171L269.689 126ZM283.057 122L282.465 119.333H278.201L277.609 122H283.057ZM289.496 123.333H284.72L285.312 126H290.977L289.496 123.333ZM284.424 122H288.756L287.275 119.333H283.832L284.424 122ZM276.835 119.333H273.392L271.911 122H276.243L276.835 119.333ZM275.057 127.333H268.948L267.467 130H274.465L275.057 127.333ZM279 136.667H281.667V131.333H279V136.667ZM285.609 127.333L286.201 130H293.2L291.719 127.333H285.609Z" fill="black" fillOpacity="0.5" />
                        </G>
                    </G>
                    <G style={{mixBlendMode:'overlay'}}>
                        <Path d="M351 106.667L351.556 106.299C351.433 106.112 351.224 106 351 106C350.776 106 350.567 106.112 350.444 106.299L351 106.667Z" fill="black" fillOpacity="0.5" />
                        <Path d="M348.983 118.977C349.155 118.651 349.029 118.248 348.703 118.077C348.378 117.905 347.974 118.031 347.803 118.357C346.99 119.904 346.131 121.641 345.474 123.224C344.831 124.772 344.333 126.283 344.333 127.333C344.333 129.311 345.194 131.085 346.558 132.305C346.833 132.55 347.254 132.527 347.5 132.252C347.745 131.978 347.721 131.556 347.447 131.311C346.353 130.333 345.667 128.916 345.667 127.333C345.667 126.582 346.056 125.3 346.705 123.735C347.34 122.205 348.178 120.509 348.983 118.977Z" fill="black" fillOpacity="0.5" />
                        <Path fillRule="evenodd" clipRule="evenodd" d="M351 106.667C351.556 106.299 351.556 106.299 351.556 106.299L351.564 106.311L351.586 106.344L351.671 106.474C351.745 106.587 351.853 106.754 351.99 106.967C352.265 107.395 352.658 108.011 353.128 108.767C354.07 110.278 355.325 112.353 356.582 114.603C357.837 116.851 359.1 119.285 360.051 121.512C360.989 123.711 361.667 125.804 361.667 127.333C361.667 133.226 356.891 138 351 138C345.106 138 340.333 133.225 340.333 127.333C340.333 125.804 341.011 123.711 341.949 121.512C342.9 119.285 344.162 116.851 345.418 114.603C346.675 112.353 347.93 110.278 348.872 108.767C349.342 108.011 349.735 107.395 350.01 106.967C350.147 106.754 350.255 106.587 350.329 106.474L350.414 106.344L350.436 106.311L350.444 106.299C350.444 106.299 350.444 106.299 351 106.667ZM350.003 109.471C350.4 108.835 350.74 108.299 351 107.893C351.26 108.299 351.6 108.835 351.997 109.471C352.93 110.971 354.175 113.027 355.418 115.254C356.662 117.482 357.9 119.87 358.824 122.035C359.761 124.23 360.333 126.101 360.333 127.333C360.333 132.489 356.155 136.667 351 136.667C345.843 136.667 341.667 132.489 341.667 127.333C341.667 126.101 342.239 124.23 343.176 122.035C344.1 119.87 345.337 117.482 346.582 115.254C347.825 113.027 349.07 110.971 350.003 109.471Z" fill="black" fillOpacity="0.5" />
                    </G>
                </G>
            </G>
            <Defs>
                <LinearGradient id={makeUnique("paint0_linear_1726_19010_2")} x1="287.579" y1="74" x2="43.766" y2="74" gradientUnits="userSpaceOnUse">
                    <Stop stopColor="#FFCC00" />
                    <Stop offset="1" stopColor="#00B0DB" />
                </LinearGradient>
                <ClipPath id={makeUnique("clip0_1726_19010")}>
                    <Rect width="32" height="32" fill="white" transform="translate(-25 10)" />
                </ClipPath>
                <ClipPath id={makeUnique("clip1_1726_19010")}>
                    <Rect width="32" height="32" fill="white" transform="translate(47 10)" />
                </ClipPath>
                <ClipPath id={makeUnique("clip2_1726_19010")}>
                    <Rect width="32" height="32" fill="white" transform="translate(191 10)" />
                </ClipPath>
                <ClipPath id={makeUnique("clip3_1726_19010")}>
                    <Rect width="32" height="32" fill="white" transform="translate(263 10)" />
                </ClipPath>
                <ClipPath id={makeUnique("clip4_1726_19010")}>
                    <Rect width="32" height="32" fill="white" transform="translate(11 58)" />
                </ClipPath>
                <ClipPath id={makeUnique("clip5_1726_19010")}>
                    <Rect width="32" height="32" fill="white" transform="translate(83 58)" />
                </ClipPath>
                <ClipPath id={makeUnique("clip6_1726_19010")}>
                    <Rect width="32" height="32" fill="white" transform="translate(227 58)" />
                </ClipPath>
                <ClipPath id={makeUnique("clip7_1726_19010")}>
                    <Rect width="32" height="32" fill="white" transform="translate(299 58)" />
                </ClipPath>
                <ClipPath id={makeUnique("clip8_1726_19010")}>
                    <Rect width="32" height="32" fill="white" transform="translate(-25 106)" />
                </ClipPath>
                <ClipPath id={makeUnique("clip9_1726_19010")}>
                    <Rect width="32" height="32" fill="white" transform="translate(47 106)" />
                </ClipPath>
                <ClipPath id={makeUnique("clip10_1726_19010")}>
                    <Rect width="32" height="32" fill="white" transform="translate(191 106)" />
                </ClipPath>
                <ClipPath id={makeUnique("clip11_1726_19010")}>
                    <Rect width="32" height="32" fill="white" transform="translate(263 106)" />
                </ClipPath>
            </Defs>
        </Svg>
    )
}
