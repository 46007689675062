'use strict';
/*
 time
 title
 subtitle        string or array of strings (each item will be in a row)
 clickable
 */

GUI.TableViewV2.CellType.Special.AUTOPILOT_HISTORY_CELL = "AutopilotHistoryCell";

class AutopilotHistoryCell extends GUI.TableViewV2.Cells.BaseCell {
    //region Static
    static Template = function () {
        var getTemplate = function getTemplate(content) {
            return $('<div class="sub-cell-container">' + '<div class="sub-cell-container__time">' + content.time + '</div>' + '<div class="right-container">' + '   <div class="sub-cell-container__title">' + content.title + '</div>' + '   <div class="sub-cell-container__text">' + '       ' + (typeof content.subtitle === "string" ? content.subtitle : content.subtitle.join('</div><div class="sub-cell-container__text">')) + '   </div>' + '</div>' + '</div>');
        };

        return {
            getTemplate: getTemplate
        };
    }(); //endregion Static

    constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
        super(...arguments);
        this.element.addClass("autopilot-history-cell");
    }

    viewDidLoad() {
        super.viewDidLoad();
        this.contentPlaceholder.append(AutopilotHistoryCell.Template.getTemplate(this.content));
    }

    clickableAllowed() {
        return this.content.clickable;
    }

}

GUI.TableViewV2.Cells.AutopilotHistoryCell = AutopilotHistoryCell;
