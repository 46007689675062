'use strict';

define(["AudioZoneV2ControlEnums"], function (AudioZoneV2ControlEnums) {
    class MediaContextMenuV2Base extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            this.details = details;
            this.control = details.control;
            this.delegate = details.delegate;
            this.title = this.details.title || NBR_SPACE;
        }

        viewDidLoad() {
            HapticFeedback();
            return super.viewDidLoad(...arguments);
        }

        emptyViewConfig() {
            return {
                iconSrc: Icon.ERROR,
                title: _("controls.ircv2.no-action-phase"),
                message: NBR_SPACE
            };
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            return this.title;
        }

        setTableContent() {
            var args = arguments,
                boundNavBack = this.ViewController.dismiss.bind(this.ViewController);

            if (this.ViewController.history.stack.length >= 1) {
                boundNavBack = this.ViewController.navigateBack.bind(this.ViewController);
            }

            return Q(this.delegate.__getCtxMenuTableContent(boundNavBack)).then(content => {
                this.tableContent = content;
                return super.setTableContent(...args);
            });
        }

    }

    Controls.AudioZoneV2Control.MediaContextMenuV2Base = MediaContextMenuV2Base;
});
