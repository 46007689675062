'use strict';

define(["NfcCodeTouchControlEnums"], function (NfcCodeTouchControlEnums) {
    return class NfcCodeTouchControlContentCodeValidity extends GUI.TableViewScreenV2 {
        constructor(details) {
            var msTime = SandboxComponent.getMiniserverTimeInfo(null, null, TimeValueFormat.MINISERVER_DATE_TIME),
                now;
            super($('<div />'));
            Object.assign(this, ContextMenuHandler.Mixin);
            this.control = details.control;
            this.code = details.code;
            this._isReactivating = details.reactivating;

            if (this._isReactivating) {
                // set proper times..
                now = msTime.add(1, "hour").startOf("hour");
                this.code.timeFrom = now.unix();
                this.code.timeTo = now.add(1, "day").unix();
            }
        }

        titleBarText() {
            return _('validity');
        }

        titleBarConfig() {
            if (this._isReactivating) {
                return {
                    rightSide: TitleBarCfg.CUSTOM_ICON,
                    rightIconSrc: 'resources/Images/General/TitleBar/titlebar_tickmark.svg'
                };
            }
        }

        titleBarActionRight() {
            this._sendReactivationCmd();
        }

        closeAction() {
            var def = Q.defer();

            if (this.invalidToTime) {
                HapticFeedback(HapticFeedback.STYLE.WARNING);
                var content = {
                    title: _("error"),
                    message: _("controls.daytimer.time.mismatch.popup.message"),
                    buttonOk: true,
                    icon: Icon.ERROR,
                    color: window.Styles.colors.red
                };
                NavigationComp.showPopup(content);
                def.reject("Time for beginning was after the end time");
            } else {
                def.resolve(super.closeAction(...arguments));
            }

            return def.promise;
        }

        getURL() {
            if (this._isReactivating) {
                return this.code.uuid + "/Reactivate";
            }

            return "Validity";
        }

        getAnimation() {
            return HD_APP ? AnimationType.HD_OVERLAY : AnimationType.PUSH_OVERLAP_LEFT;
        }

        handleModalBackgroundTapped() {
            return false;
        }

        // TableViewDataSource Methods
        styleForTableView(tableView) {
            return TableViewStyle.GROUPED;
        }

        setTableContent() {
            this.tableContent = [];
            this.tableContent.push(this._getValiditySelectorSection());

            if (this.code.type === NfcCodeTouchControlEnums.CodeType.TIME_DEPENDENT) {
                this.tableContent.push(this._getTimeSelectorSection());
            }

            return super.setTableContent(...arguments);
        }

        // Private methods
        _getValiditySelectorSection() {
            var section = {
                rows: []
            };
            section.rows.push({
                type: GUI.TableViewV2.CellType.CHECKABLE,
                content: {
                    title: _('code-touch.unlimited'),
                    selected: this.code.type === NfcCodeTouchControlEnums.CodeType.PERMANENT,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED
                },
                didCheckCell: this._handleDidCheckCell.bind(this, NfcCodeTouchControlEnums.CodeType.PERMANENT)
            });
            section.rows.push({
                type: GUI.TableViewV2.CellType.CHECKABLE,
                content: {
                    title: _('code-touch.single-use'),
                    selected: this.code.type === NfcCodeTouchControlEnums.CodeType.ONE_TIME,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                    rightIconSrc: Icon.NfcCodeTouch.CODE_ONE_TIME
                },
                didCheckCell: this._handleDidCheckCell.bind(this, NfcCodeTouchControlEnums.CodeType.ONE_TIME)
            });
            section.rows.push({
                type: GUI.TableViewV2.CellType.CHECKABLE,
                content: {
                    title: _('code-touch.time-restricted'),
                    selected: this.code.type === NfcCodeTouchControlEnums.CodeType.TIME_DEPENDENT,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                    rightIconSrc: Icon.NfcCodeTouch.CODE_TIME_DEPENDANT
                },
                didCheckCell: this._handleDidCheckCell.bind(this, NfcCodeTouchControlEnums.CodeType.TIME_DEPENDENT)
            });
            return section;
        }

        _handleDidCheckCell(codeType) {
            var needsReload = false;

            if (this.code.type === NfcCodeTouchControlEnums.CodeType.TIME_DEPENDENT || codeType === NfcCodeTouchControlEnums.CodeType.TIME_DEPENDENT) {
                needsReload = true;
            }

            this.code.type = codeType;
            needsReload && this.reloadTable();
        }

        _getTimeSelectorSection() {
            var section = {
                    headerTitle: _('timespan'),
                    rows: []
                },
                msTime = SandboxComponent.getMiniserverTimeInfo(null, null, TimeValueFormat.MINISERVER_DATE_TIME);

            if (!this.code.timeFrom) {
                this.code.timeFrom = msTime.unix();
            }

            if (!this.code.timeTo) {
                this.code.timeTo = msTime.add(1, "day").unix();
            }

            section.rows.push({
                content: {
                    title: _('time.from'),
                    disclosureText: this._formatReadableTime(this.code.timeFrom),
                    clickable: true
                },
                action: function () {
                    this._showContextMenu({
                        type: GUI.LxTimePickerContextMenu.TYPE.DATE_TIME,
                        value: moment.unix(this.code.timeFrom),
                        onPickerDisappear: function (value) {
                            this.code.timeFrom = value.unix();

                            this._validateTimeAndReloadTable(true);
                        }.bind(this)
                    }, _('time.from'), null, GUI.LxTimePickerContextMenu);
                }.bind(this)
            });
            section.rows.push({
                content: {
                    title: _('time.until'),
                    disclosureText: this._formatReadableTime(this.code.timeTo),
                    disclosureColor: this.invalidToTime ? window.Styles.colors.red : null,
                    clickable: true
                },
                action: function (cell) {
                    this._showContextMenu({
                        type: GUI.LxTimePickerContextMenu.TYPE.DATE_TIME,
                        value: moment.unix(this.code.timeTo),
                        onPickerDisappear: function (value) {
                            this.code.timeTo = value.unix();

                            this._validateTimeAndReloadTable(false);
                        }.bind(this)
                    }, _('time.until'), null, GUI.LxTimePickerContextMenu);
                }.bind(this)
            });
            return section;
        }

        _formatReadableTime(timestamp) {
            return moment.unix(timestamp).format(LxDate.getDateTimeFormat(DateType.Date, true)) + " " + _('timeSuffix');
        }

        _validateTimeAndReloadTable(didChangeStart) {
            if (this.code.timeFrom >= this.code.timeTo) {
                this.invalidToTime = false;

                if (didChangeStart) {
                    this.code.timeTo = moment.unix(this.code.timeFrom).add(1, "DAY").unix();
                } else {
                    this.invalidToTime = true;
                }
            } else if (this.invalidToTime) {
                this.invalidToTime = false;
            }

            this.setTableContent();
            this.tableView.reloadSection(1);
        }

        _sendReactivationCmd() {
            var cmd;

            if (!this.code.hasOwnProperty("code")) {
                this.code.code = -1; // -1 = don't change the code
            }

            if (this.code.type === NfcCodeTouchControlEnums.CodeType.TIME_DEPENDENT) {
                cmd = Commands.format(Commands.NFC_CODE_TOUCH.UPDATE_CODE_WITH_TIME, this.code.uuid, true, encodeURIComponent(this.code.name), this.code.code, this.code.type, this.code.outputs, this.code.standardOutput, this.code.timeFrom, this.code.timeTo);
            } else {
                cmd = Commands.format(Commands.NFC_CODE_TOUCH.UPDATE_CODE, this.code.uuid, true, encodeURIComponent(this.code.name), this.code.code, this.code.type, this.code.outputs, this.code.standardOutput);
            }

            return this.control.sendCommandWithErrorHandling(cmd, this.ViewController);
        }

    };
});
