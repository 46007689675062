import {useState, useRef, useEffect, useCallback, useMemo} from "react";
import {useConnectionReady, useLiveState} from "LxComponents";
import DeviceSearchUtils from "./deviceSearchUtils";

export default function useDeviceSearchExtensions(searchType, miniserver) {

    const [extensionList, setExtensionList] = useState(null);
    const connReady = useConnectionReady(true);
    const liveState = useLiveState(GLOBAL_UUID, ["liveSearch"]);
    const isMounted = useRef(false);

    const randomId = useRef(getRandomIntInclusive(100,999));
    const dbgName = useMemo(() => {
        return "useDeviceSearchExtensions#" + randomId.current;
    }, []);

    /**
     * Filters dummies and publishes the result.
     * @param extensions
     */
    const updateExtensions = useCallback((extensions) => {
        if (!isMounted.current) {
            Debug.DeviceSearch.Extensions && console.log(dbgName, "updateExtensions --> NOT mounted!");
            return;
        }
        let filtered = DeviceSearchUtils.getSearchableExtensions(extensions, miniserver);
        Debug.DeviceSearch.Extensions && console.log(dbgName, "updateExtensions");
        setExtensionList(filtered);
    }, [miniserver, searchType]);

    const reloadExtensions = () => {
        if (connReady) {
            Debug.DeviceSearch.Extensions && console.log(dbgName, "reloadExtensions");
            ActiveMSComponent.getAvailableExtensions(null, miniserver).then(updateExtensions);
        } else {
            Debug.DeviceSearch.Extensions && console.log(dbgName, "reloadExtensions -> not connected, don't load");
        }
    }

    useEffect(() => {
        isMounted.current = true;
        reloadExtensions();
        let unregFn = ActiveMSComponent.registerForExtensionChanges(updateExtensions, searchType);
        return () => {
            isMounted.current = false;
            unregFn();
        };
    }, []);

    useEffect(() => {
        Debug.DeviceSearch.Extensions && console.log(dbgName, "useEffect@connReady/searchType -> connReady=" + connReady + ", searchType=" + searchType);
        reloadExtensions();
    }, [connReady, searchType, miniserver])

    const deviceCountRef = useRef(-1);
    useEffect(() => {
        if (liveState.states && liveState.states.liveSearch && deviceCountRef.current !== liveState.states.liveSearch.deviceCounter) {
            Debug.DeviceSearch.Extensions && console.log(dbgName, "useEffect - deviceCountStateChanged - now @ " + liveState.states.liveSearch.deviceCounter);
            deviceCountRef.current = liveState.states.liveSearch.deviceCounter;
            reloadExtensions();
        }
    },[(liveState.states && liveState.states.liveSearch) ? liveState.states.liveSearch.deviceCounter : null] );

    return extensionList;
}