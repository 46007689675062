'use strict';

define("PushbuttonControl", [
    "Control",
    "IconLib"
], function (
    Control,
    {
        default: Icons
    }
) {
    return class PushbuttonControl extends Control {
        //region Getter
        get isAutomaticScene() {
            var isAutomaticScene = false;

            if (this.details && this.details.hasOwnProperty("type")) {
                if (this.details.type === ControlSubTypes.AUTOMATIC_SCENE && Feature.AUTOMATIC_DESIGNER_SCENES) {
                    isAutomaticScene = true;
                }
            }

            return isAutomaticScene;
        }

        get hasSceneEditPermission() {
            const currentPermissions = ActiveMSComponent.getAvailablePermissions();            
            return  hasBit(currentPermissions, MsPermission.AUTOPILOT); // Scene is basically an Automatic Rule without triggers, hence the permission is the same
        }

        get isVirtualInput() {
            var isVI = false;

            if (this.details && this.details.hasOwnProperty("type")) {
                isVI = this.details.type === ControlSubTypes.VIRTUAL_INPUT;
            }

            return isVI;
        } //endregion Getter


        constructor() {
            super(...arguments);
        }

        isConfigured() {
            var baseIsConfigured = super.isConfigured(...arguments),
                isValid = true;

            if (this.isAutomaticScene) {
                var availableScenes = AutomaticDesignerComponent.getScenesSync(),
                    associatedScene;

                if (availableScenes && availableScenes.length) {
                    associatedScene = availableScenes.find(function (scene) {
                        return scene.id === this.uuidAction;
                    }.bind(this));

                    if (associatedScene) {
                        isValid = !associatedScene.invalid;
                    }
                }
            }

            return baseIsConfigured && isValid;
        }

        /**
         * Returns an icon for this control type, that Icon will be used if no defaultIcon is set by loxone config.
         * @returns {null}
         */
        getControlIcon() {
            if (this.isAutomaticScene) {
                return Icon.AutomaticDesigner.SCENE;
            } else {
                return super.getControlIcon(...arguments);
            }
        }

        getControlTypeName() {
            var type = this.type; // The config may have additional subtypes!

            if (this.isAutomaticScene) {
                type = "automaticScene";
            }

            return this._getControlTypeNameForType(type);
        }

        getButton0() {
            return {
                iconSrc: Icon.Pushbutton.PUSHBUTTON2,
                reactIcon: Icons.Circle,
                command: {
                    tap: Commands.PUSHBUTTON.PULSE,
                    hit: Commands.PUSHBUTTON.ON,
                    release: Commands.PUSHBUTTON.OFF
                }
            };
        }

        getFriendlyValueName(event, operator) {
            switch (event.data.stateName) {
                case "active":
                    return this.name + " " + (event.value ? _("on") : _("off")).toLowerCase();

                default:
                    return "";
            }
        }

        /**
         * returns eg. AlarmControlContent or AlarmCentralControlContent for this control
         * It will use ControlActionCellsScreen as a fallback if the controls content does not exist
         * @returns {string}
         */
        getControlContentIdentifier() {
            var id = super.getControlContentIdentifier(...arguments);

            if (this.isAutomaticScene) {
                id = "ControlActionCellsScreen";
            }

            return id;
        }

        editScene() {
            if (this.isAutomaticScene) {
                AutomaticDesignerComponent.getScenes().done(function (scenes) {
                    var wantedScene = scenes.find(function (scene) {
                        return scene.id === this.uuidAction;
                    }.bind(this));

                    if (wantedScene) {
                        if (wantedScene.locked) {
                            NavigationComp.showPopup({
                                title: _("automatic-designer.rule.missing-permission.1"),
                                message: _("automatic-designer.rule.missing-permission.2")
                            });
                        } else {
                            NavigationComp.showState(ScreenState.AutomaticDesigner.RuleViewController, {
                                rule: wantedScene,
                                extension: AutomaticDesignerEnums.RULE_EXTENSION.SCENE,
                                isInEditMode: true
                            });
                        }
                    }
                }.bind(this));
            } else {
                developerAttention("This is not an AutomaticDesigner Scene!");
            }
        }

        getSceneExecUrlScheme() {
            if (this.isAutomaticScene) {
                var cmd;

                if (this.isSecured) {
                    cmd = Commands.format(Commands.CONTROL.SECURED_COMMAND, this.uuidAction, Commands.PUSHBUTTON.PULSE);
                } else {
                    cmd = Commands.format(Commands.CONTROL.COMMAND, this.uuidAction, Commands.PUSHBUTTON.PULSE);
                }

                return UrlHelper.createCommandURLStart({
                    mac: ActiveMSComponent.getActiveMiniserver().serialNo
                }, cmd);
            } else {
                return null;
            }
        }

    };
});
