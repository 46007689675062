'use strict';

import {AmbientOnboardingNavigator} from "../../../react-comps/AmbientMode/onboarding/AmbientOnboardingNavigator";
import {ReactWrapper} from "../../../react-comps/Components";

class EntryPointWelcomeScreen extends GUI.StaticScreenBase {

    constructor() {
        super($('<div></div>'));
    }

    show() {
        super.show(...arguments).then(() => {
            let def = Q.defer();

            ReactWrapper.render(AmbientOnboardingNavigator, {
                hideNavigator: this.remove.bind(this)
            }, this.element, () => {
                def.resolve()
            });

            return def.promise;
        });
    }

    remove() {
        ReactWrapper.unmountReact(this.element);
        super.remove(...arguments);
    }

    /*getWelcomeScreenConfig() {
        return {
            title: _("entry-point.location-picker-screen.title"),
            subtitle: _("entry-point.initial-setup-screen.subtitle"),
            icon: Icon.Menu.ENTRY_POINT,
            acceptButton: _('entry-point.initial-setup-screen.button.accept'),
            declineButton: _('entry-point.initial-setup-screen.button.decline'),
            declineDisc: _('entry-point.initial-setup-screen.button.how-to-settings')
        };
    }

    remove() {
        EntryPointHelper.setWelcomeScreenViewed(true);
        return super.remove(...arguments);
    }

    _acceptAction() {
        NavigationComp.showState(ScreenState.EntryPointLocationSelection, {
            initSetup: true // This will show a cancel button and directly switch to the selected location

        }).then(function () {
            this.remove();
        }.bind(this));
    }

    _declineAction() {
        return this.remove();
    }*/

}

GUI.EntryPointWelcomeScreen = EntryPointWelcomeScreen;
