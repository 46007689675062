'use strict';

define(["LxComponents"], function ({ App }) {

    return class AlexaControlSelectorScreen extends GUI.TableViewScreenV2 {
        //region Static
        static Template = function () {
            var nextButtonTemplate = function nextButtonTemplate(shouldHideNextButton) {
                var template = '<div class="bottom-container ' + (shouldHideNextButton ? " only-element" : "") + '">' + '' + '<div class="bottom-container__count"></div>';

                if (!shouldHideNextButton) {
                    template += '' + '<div class="bottom-container__button-container">' + '   ' + '<div class="button-container__button">' + _('home-kit.continue') + '</div>' + '' + '</div>';
                }

                template += '</div>';
                return $(template);
            };

            return {
                nextButtonTemplate: nextButtonTemplate
            };
        }(); //endregion Static

        constructor(details) {
            super($("<div />"));
            this._isInitalCall = true;

            if (details) {
                this._availableEndpoints = details.endpoints;
                this._isInEditMode = details.isInEditMode;
                this._selectedUserObj = details.createdUser;
                this._selectedUserUuid = details.userUuid;
            }

            this._endpointsLoadingPrms = Q.defer().promise;

            if (!this._availableEndpoints) {
                this._availableEndpoints = [];
            }
        }

        viewDidLoad() {
            var nextButtonElem;
            return super.viewDidLoad(...arguments).then(function () {
                if (this._isInEditMode) {
                    this.element.append(AlexaControlSelectorScreen.Template.nextButtonTemplate(this._isInEditMode));
                    this._counterTextElem = this.element.find('.bottom-container__count');
                    return Q.resolve();
                } else {
                    this.element.append(AlexaControlSelectorScreen.Template.nextButtonTemplate(this._isInEditMode));
                    nextButtonElem = this.element.find('.bottom-container__button-container');
                    this._counterTextElem = this.element.find('.bottom-container__count');
                    this._nextButton = new GUI.LxButton(this, nextButtonElem[0], Color.BUTTON_GLOW, null, true, 250);
                    this.addToHandledSubviews(this._nextButton, this.element);
                    return Q.resolve();
                }
            }.bind(this));
        }

        viewWillAppear() {
            var baseCall = super.viewWillAppear(...arguments); // toggle the emptyView manually to prevent screen flickering

            this.showSubview(this.emptyView);
            this.hideSubview(this.tableView);
            this._availableEndpoints = [];
            this._endpointsLoadingPrms = ActiveMSComponent.getEndpoints(true).then(function (endpoints) {
                this._allEndpoints = endpoints;

                if (this._isInitalCall && !this._isInEditMode) {
                    this._isInitalCall = false;

                    this._allEndpoints.forEach(endpoint => {
                        endpoint.visible = true;
                    });

                    ActiveMSComponent.updateAllEndpoints(this._allEndpoints);
                }

                this._availableEndpoints = this._allEndpoints.filter(endpoint => {
                    return endpoint.lxControlUuid;
                });

                this._updateBottomCounter();

                if (!this._availableEndpoints.length && !this._selectedUserObj) {
                    this._userPrms = ActiveMSComponent.getUser(this._selectedUserUuid).then(function (user) {
                        this._selectedUserObj = getLxResponseValue(user);
                        this.reloadTable();
                    }.bind(this));
                } else {
                    this._userPrms = Q.resolve();
                    this.reloadTable();
                }
            }.bind(this));

            if (this._nextButton) {
                this._nextButton.onButtonTapped = this._onNextButtonClicked.bind(this);
            }

            return baseCall;
        }

        emptyViewConfig() {
            return {
                iconSrc: Icon.INDICATOR,
                message: _('home-kit.loading.message.functions'),
                noCircle: true,
                translucent: true,
                elementIsNotClickable: true
            };
        }

        viewWillDisappear() {
            if (this._nextButton) {
                this._nextButton.onButtonTapped = null;
            }

            return super.viewWillDisappear(...arguments);
        }

        closeAction() {
            if (this._isInEditMode) {
                if (this._valindNumbersOfEndpoints) {
                    return ActiveMSComponent.sendUpdateEndpoints().finally(function () {
                        // force update view call in previous screen
                        return this.ViewController.navigateBack(false, {});
                    }.bind(this));
                } else {
                    return Q.reject();
                }
            } else {
                this.ViewController.closeAction();
            }
        }

        titleBarConfig() {
            if (this._isInEditMode) {
                return {
                    leftSide: TitleBarCfg.Button.BACK
                };
            } else {
                return {
                    leftSide: TitleBarCfg.Button.CLOSE
                };
            }
        }

        titleBarText() {
            return _('functions');
        }

        reloadTable() {
            this.tableContent = [];

            if (this._availableEndpoints.length) {
                this._allEndpoints = ActiveMSComponent.getLocalEndpoints();
                this._availableEndpoints = this._allEndpoints.filter(endpoint => {
                    return endpoint.lxControlUuid;
                });

                this._sortEndpointsByRoom();

                this.tableContent.push(this._getHelpTextSection());
                Object.keys(this._sortedEnpoints).forEach(function (key) {
                    this.tableContent.push(this._getGroupedRoomSection(key));
                }.bind(this));
            } else {
                if (this._endpointsLoadingPrms.inspect().state === "fulfilled") {
                    this.tableContent.push(this._getNoEndpointsSection());
                }
            }

            return super.reloadTable(...arguments);
        }

        _getHelpTextSection() {
            return {
                footerTitle: _('alexa.functions.hint-text')
            };
        }

        _getNoEndpointsSection() {
            var section = {
                rows: []
            };
            section.rows.push({
                content: {
                    title: _('home-kit.functions.button.settings'),
                    titleColor: window.Styles.colors.brand,
                    clickable: true
                },
                action: function () {
                    NavigationComp.showWaitingFor(this._userPrms).then(function () {
                        App.navigationRef.navigate(ScreenState.UserDetailsScreen,  {
                            user: this._selectedUserObj,
                            isNewUser: false,
                            homeKitDefer: Q.defer()
                        })
                    }.bind(this));
                }.bind(this)
            });
            section.rows.push({
                content: {
                    title: _('home-kit.functions.button.change-user'),
                    titleColor: window.Styles.colors.brand,
                    clickable: true
                },
                action: function () {
                    return this.ViewController.showState(ScreenState.AlexaSelectUserViewController, null, {
                        screenToShow: ScreenState.AlexaSelectUserScreen,
                        details: {}
                    });
                }.bind(this)
            });
            return section;
        }

        /**
         * Looks for the room object specified by the key.
         * @param key
         * @returns {*}
         * @private
         */
        _getRoomNameForKey(key) {
            let roomName  = _('home-kit.settings.owner.functions.progress.unused');
            if (key && key.length > 0) {
                let roomObj = ActiveMSComponent.getStructureManager().getGroupByUUID(key, GroupTypes.ROOM);

                // eventually the room is provided, but not available in the structure!
                if (roomObj && roomObj.name) {
                    roomName = roomObj.name;
                }
            }
            return roomName;
        }

        _getGroupedRoomSection(key) {
            var headerTitle = this._getRoomNameForKey(key);
            var section = {
                    headerTitle: headerTitle,
                    sectionRightButtonTitle: _("home-kit.functions.select-all"),
                    rightSectionButtonTapped: this._sectionButtonAction.bind(this, key),
                    rows: this._sortedEnpoints[key].map(function (endpoint) {
                        return this._getCellForEndpoint(endpoint);
                    }.bind(this))
                },
                roomObj = this._allEndpoints.find(function (endpoint) {
                    return endpoint.serial === key;
                });

            if (roomObj && key && key.length > 0) {
                section.rows.push({
                    type: GUI.TableViewV2.CellType.CHECKABLE,
                    content: {
                        title: _('alexa.functions.room'),
                        disabled: true,
                        selected: roomObj.visible
                    }
                });
            }

            return section;
        }

        _sortEndpointsByRoom() {
            this._sortedEnpoints = {};

            this._availableEndpoints.forEach(function (endpoint) {
                if (endpoint.lxControlType !== 0) {
                    if (!this._sortedEnpoints[endpoint.lxRoomUuid]) {
                        this._sortedEnpoints[endpoint.lxRoomUuid] = [];
                    }

                    this._sortedEnpoints[endpoint.lxRoomUuid].push(endpoint);
                }
            }.bind(this));
        }

        _sectionButtonAction(roomUuid, section) {
            var allSelected = this.tableContent[section].rows.map(function (row) {
                    return row.content.selected;
                }).reduce(function (a, b) {
                    return a && b;
                }, true),
                roomObj = this._allEndpoints.find(({
                                                       serial
                                                   }) => {
                    return serial === roomUuid;
                });

            this._allEndpoints.forEach(function (endpoint) {
                if (endpoint.lxControlType > 0 && endpoint.lxRoomUuid === roomUuid) {
                    endpoint.visible = !allSelected;
                }
            });

            if (roomObj) {
                roomObj.visible = !allSelected;
            }

            ActiveMSComponent.updateAllEndpoints(this._allEndpoints);

            this._updateBottomCounter();

            this.reloadTable();
        }

        _getCellForEndpoint(endpoint) {
            return {
                type: GUI.TableViewV2.CellType.CHECKABLE,
                content: {
                    title: endpoint.name,
                    selected: endpoint.visible,
                    subtitle: ActiveMSComponent.alexaGetControlNameForType(endpoint.lxType)
                },
                didCheckCell: function didCheckCell(cell, section, row, tableView) {
                    endpoint.visible = !endpoint.visible;
                    ActiveMSComponent.updateEndpoint(endpoint);

                    var lastCell = this.tableView.cellForSectionAndRow(section, this.tableContent[section].rows.length - 1),
                        roomObj = this._allEndpoints.find(({
                                                               serial
                                                           }) => {
                            return serial === endpoint.lxRoomUuid;
                        }),
                        isLastCellSelected = !!this._sortedEnpoints[endpoint.lxRoomUuid].map(({
                                                                                                  visible
                                                                                              }) => {
                            return visible;
                        }).reduce(function (a, b) {
                            return a | b;
                        }, false);

                    if (roomObj) {
                        lastCell.setChecked(isLastCellSelected, false, true);
                        roomObj.visible = isLastCellSelected;
                        ActiveMSComponent.updateEndpoint(roomObj);
                    }

                    this._updateBottomCounter();

                    this.reloadTable();
                }.bind(this)
            };
        }

        _updateBottomCounter() {
            var usedEndpoints = ActiveMSComponent.getLocalEndpoints().filter(({
                                                                                  visible
                                                                              }) => {
                    return visible;
                }).length,
                validClass;
            this._valindNumbersOfEndpoints = usedEndpoints <= Alexa.ENDPOINTS_LIMIT;this._nextButton && this._nextButton.setEnabled(this._valindNumbersOfEndpoints);
            validClass = this._valindNumbersOfEndpoints ? "selected-count-valid" : "selected-count-invalid";

            this._counterTextElem.html(_("home-kit.functions.selected", {
                selected: "<span class=" + validClass + ">" + usedEndpoints + "</span>",
                maximum: Alexa.ENDPOINTS_LIMIT
            }));
        }

        _onNextButtonClicked() {
            ActiveMSComponent.sendUpdateEndpoints().then(function () {
                this.ViewController.showState(ScreenState.AlexaFinishScreen, null, {
                    iconSrc: Icon.ALEXA.APP,
                    title: _('home-kit.setup-done.title'),
                    message: _('alexa.finish.bottom-text'),
                    message2: _('alexa.finish.hint-text1') + '<br>' + _('alexa.finish.hint-text2') + '<br>' + _('alexa.finish.hint-text3'),
                    iconColor: Color.ALEXA_BLUE,
                    continueBtnText: _('done')
                });
            }.bind(this), function (err) {
                console.error("LOG!!!!! UpdateEndpoints --> failed with error: " + JSON.stringify(err));
            }.bind(this));
        }

    }
})

