import {LxReactComp} from "LxComponents"

import {View, Text, StyleSheet} from "react-native";
import React from "react";

import LinearGradient from 'react-native-linear-gradient';
import { createShimmerPlaceholder } from 'react-native-shimmer-placeholder'

const ShimmerPlaceHolder = createShimmerPlaceholder(LinearGradient)

class ComplexReactComp extends LxReactComp {
    // region Private
    #options = [
        "Everyone",
        "Andi",
        "Tobi",
        "Lukas",
        "Max",
        "David"
    ]

    styles = StyleSheet.create({
        reactView: {
            padding: 10,
            borderWidth: 2,
            flex: 1,
            alignItems: "center"
        }
    })

    // endregion

    constructor(...params) {
        super(...params)
        this._boundLegacyCellMapperReloadFn = this.legacyCellMapperReloadFn.bind(this);
        this.state = {
            /*tableContent: [
                {
                    headerTitle: "Mapped Cells",
                    rows: [
                        {
                            content: {
                                title: window.GUI.TableViewV2.CellType.GENERAL,
                                subtitle: "Test^^2344444444",
                                subtitleColor: "deeppink",
                                disclosureIcon: true,
                                disclosureText: "HUHUHHUU",
                                disclosureColor: Color.BRAND_COLOR
                            },
                            action: () => this.#notify("Hi")
                        },
                        {
                            type: window.GUI.TableViewV2.CellType.SWITCH,
                            content: {
                                title: window.GUI.TableViewV2.CellType.SWITCH,
                                active: true,
                                subtitle: "Active",
                                subtitleColor: Color.STATE_ACTIVE
                            },
                            action: () => this.#notify("Hi"),
                            onSwitchChanged: value => {
                                let wantedCellIdx = this.state.tableContent[0].rows.findIndex(cell => cell.type === window.GUI.TableViewV2.CellType.SWITCH);
                                this.state.tableContent[0].rows[wantedCellIdx].content.active = value;
                                this.state.tableContent[0].rows[wantedCellIdx].content.subtitle = value ? "Active" : "Inactive";
                                this.state.tableContent[0].rows[wantedCellIdx].content.subtitleColor = value ? Color.TEXT_ACTIVE : Color.TEXT_INACTIVE;
                                this.setState(this.state);
                            }
                        },
                        {
                            type: window.GUI.TableViewV2.CellType.USER_CELL,
                            content: {
                                user: {
                                    name: window.GUI.TableViewV2.CellType.USER_CELL,
                                    trustMember: "abcd"
                                },
                                disclosureIcon: true,
                                trustPeers: [{serial: "abcd", name: "Trust-MS"}],
                                disclosureText: _('admin'),
                                disclosureColor: Color.GREEN,
                                clickable: true,
                                subtitle: "Inactive",
                                subtitleColor: Color.RED,
                                leftSwipeButtons: [
                                    {
                                        icon: Icons.React.Gear,
                                        containerStyle: {
                                            backgroundColor: Color.RED
                                        },
                                        onPress: () => {
                                            console.error("Hi, Delete!");
                                        }
                                    },
                                    {
                                        containerStyle: {
                                            backgroundColor: Color.BLUE
                                        },
                                        textStyle: {
                                            color: globalStyles.colors.text.primary
                                        },
                                        text: "Info",
                                        onPress: () => {
                                            console.info("Hi, Info!");
                                        }
                                    }
                                ],

                                rightSwipeButtons: [
                                    {
                                        text: "Fav",
                                        onPress: () => {
                                            console.log("Hi, Fav!");
                                        }
                                    }
                                ]
                            },
                            action: () => this.#notify("Hi")
                        },
                        {
                            type: window.GUI.TableViewV2.CellType.USER_CELL,
                            content: {
                                user: {name: window.GUI.TableViewV2.CellType.USER_CELL},
                                disclosureIcon: true,
                                trustPeers: [],
                                disclosureColor: Color.GREEN,
                                clickable: true,
                                subtitle: "Active",
                                subtitleColor: Color.STATE_ACTIVE
                            },
                            action: () => this.#notify("Hi")
                        },
                        {
                            type: window.GUI.TableViewV2.CellType.CHECKABLE,
                            content: {
                                title: window.GUI.TableViewV2.CellType.CHECKABLE,
                                selected: true,
                                subtitle: "Active"
                            },
                            action: () => this.#notify("Hi"),
                            didCheckCell: value => {
                                let wantedCellIdx = this.state.tableContent[0].rows.findIndex(cell => cell.type === window.GUI.TableViewV2.CellType.CHECKABLE);
                                this.state.tableContent[0].rows[wantedCellIdx].content.selected = value;
                                this.state.tableContent[0].rows[wantedCellIdx].content.subtitle = value ? "Active" : "Inactive";
                                this.state.tableContent[0].rows[wantedCellIdx].content.subtitleColor = value ? Color.TEXT_ACTIVE : Color.TEXT_INACTIVE;
                                this.setState(this.state);
                            }
                        },
                        {
                            type: window.GUI.TableViewV2.CellType.BUTTON,
                            content: {
                                title: window.GUI.TableViewV2.CellType.BUTTON
                            },
                            buttonTapped: () => {
                                let wantedCellIdx = this.state.tableContent[0].rows.findIndex(cell => cell.type === window.GUI.TableViewV2.CellType.BUTTON);
                                this.state.tableContent[0].rows[wantedCellIdx].content.subtitle = "🙏";
                                this.setState(this.state);
                            },
                            button2Tapped: () => {
                                let wantedCellIdx = this.state.tableContent[0].rows.findIndex(cell => cell.type === window.GUI.TableViewV2.CellType.BUTTON);
                                this.state.tableContent[0].rows[wantedCellIdx].content.subtitle = "😡";
                                this.setState(this.state);
                            }
                        },
                        {
                            type: window.GUI.TableViewV2.CellType.DELETE,
                            content: {
                                title: window.GUI.TableViewV2.CellType.DELETE,
                            },
                            action: (value) => {
                                console.warn("HI!!!!")
                            }
                        },
                        {
                            type: window.GUI.TableViewV2.CellType.SLIDER,
                            content: {
                                title: window.GUI.TableViewV2.CellType.SLIDER,
                                disclosureText: "0",
                                disclosureColor: Color.TEXT_INACTIVE,
                                value: 0,
                                minIconSrc: true,
                                maxIconSrc: true
                            }
                        },
                        {
                            type: window.GUI.TableViewV2.CellType.BATTERY_MONITOR,
                            content: {
                                title: window.GUI.TableViewV2.CellType.BATTERY_MONITOR,
                                disclosureIcon: true,
                                disclosureText: "Online State",
                                disclosureColor: Color.GREEN,
                                clickable: true,
                                subtitle: "Active",
                                subtitleColor: Color.STATE_INACTIVE
                            },
                            action: () => this.#notify("Hi")
                        },
                        {
                            type: GUI.TableViewV2.CellType.Special.LOADING
                        },
                        {
                            type: GUI.TableViewV2.CellType.TEXT,
                            content: {
                                text: GUI.TableViewV2.CellType.TEXT
                            }
                        },
                        {
                            type: GUI.TableViewV2.CellType.TEXT,
                            content: {
                                text: GUI.TableViewV2.CellType.TEXT,
                                color: Color.RED
                            }
                        },
                        {
                            type: GUI.TableViewV2.CellType.TEXT,
                            content: {
                                text: GUI.TableViewV2.CellType.TEXT,
                                textColor: Color.RED
                            }
                        },
                        {
                            type: GUI.TableViewV2.CellType.TEXT,
                            content: {
                                text: GUI.TableViewV2.CellType.TEXT,
                                centered: true
                            }
                        },
                        {
                            type: GUI.TableViewV2.CellType.LIGHT,
                            content: {
                                title: GUI.TableViewV2.CellType.LIGHT
                            }
                        },
                        {
                            type: GUI.TableViewV2.CellType.LIGHT,
                            content: {
                                title: GUI.TableViewV2.CellType.LIGHT,
                                color: Color.ALEXA_BLUE
                            }
                        },
                        {
                            type: GUI.TableViewV2.CellType.History,
                            content: {
                                time: "12:00:99",
                                title: "Title",
                                subtitle: "subtitle"
                            }
                        },
                        {
                            type: GUI.TableViewV2.CellType.Special.MediaBrowserBrowsableCell,
                            content: {
                                title: window.GUI.TableViewV2.CellType.Special.MediaBrowserBrowsableCell,
                                subtitle: "Test^^2344444444",
                                subtitleColor: "deeppink",
                            },
                            action: () => this.#notify("Hi")
                        },
                        {
                            type: GUI.TableViewV2.CellType.Special.MediaBrowserPlayableCell,
                            content: {
                                title: window.GUI.TableViewV2.CellType.Special.MediaBrowserPlayableCell,
                                subtitle: "Test^^2344444444",
                                subtitleColor: "deeppink",
                            },
                            action: () => this.#notify("Hi")
                        },
                        {
                            type: GUI.TableViewV2.CellType.Special.MEDIA,
                            content: {
                                title: "Special MediaCell",
                                subtitle: "Subtitle"
                            },
                            action: () => this.#notify("Hi")
                        },
                        {
                            type: GUI.TableViewV2.CellType.Special.MESSAGE_CENTER_ENTRY_CELL,
                            content: {
                                entry: {
                                    "entryUuid": "188d7608-00dc-41bd-ffff504f94a00201",
                                    "eventId": 1045,
                                    "sourceUuid": "0c5ba600-0053-23bd-ffff403fb0c34b9e",
                                    "affectedUuids": ["0b734139-0031-050e-ffff403fb0c34b9e"],
                                    "severity": 4,
                                    "affectedName": "CRITICAL",
                                    "title": "CRITICAL",
                                    "desc": "CRITICAL",
                                    "roomUuid": "0b734138-03ac-03dc-ffff403fb0c34b9e",
                                    "installationPlace": null,
                                    "helpLink": null,
                                    "isHistoric": false,
                                    "setHistoricAt": null,
                                    "confirmedAt": null,
                                    "timestamps": [1643252608],
                                    "readAt": null,
                                    "isVisuLocked": false,
                                    "actions": [{
                                        "actionId": 1005,
                                        "title": "Acknowledge",
                                        "location": null,
                                        "link": null,
                                        "isSecured": false,
                                        "requiredPermissions": 0
                                    }, {
                                        "actionId": 1001,
                                        "title": "Contact Loxone Partner",
                                        "location": "loxone://ms?mac=504F94A00202&loc=partnerInfo",
                                        "link": null,
                                        "isSecured": false,
                                        "requiredPermissions": 0
                                    }]
                                },
                                checkConfirmed: true
                            },
                            action: () => this.#notify("Hi")
                        },
                        {
                            type: GUI.TableViewV2.CellType.Special.MINISERVER_CELL,
                            content: {
                                title: "Special MiniseverCell",
                                subtitle: "Subtitle"
                            },
                            action: () => this.#notify("Hi")
                        },
                        {
                            type: GUI.TableViewV2.CellType.CENTER,
                            content: {
                                title: GUI.TableViewV2.CellType.CENTER,
                                textColor: Color.TEXT_ACTIVE
                            },
                            action: () => this.#notify("Hi")
                        },
                        {
                            type: GUI.TableViewV2.CellType.CENTER,
                            content: {
                                icon: true
                            },
                            action: () => this.#notify("Hi")
                        },/*,
                {
                    type: window.GUI.TableViewV2.CellType.Special.MOOD_CELL,
                    content: {
                        title: GUI.TableViewV2.CellType.Special.MOOD_CELL,
                        id: 99,
                        showButton: true,
                        isMulti: false,
                        buttonSrc: Icon.SETTINGS,
                        selectable: true,
                        isFav: true,
                        iconSrc: Icon.Light.ALARM_CLOCK
                    },

                    action: () => this.#notify("Hi")
                }
                    ]
                }
            ]*/
            hideShimmer: false
        };

        this.firstLineRef = React.createRef();
        this.secondLineRef = React.createRef();
        this.thirdLineRef = React.createRef();



        /*setTimeout(() => {
            this.setState({hideShimmer: true})
        }, 5000)*/
    }

    legacyCellMapperReloadFn(sectionIdx, rowIdx, newContent) {
        if (this.state.tableContent[sectionIdx] &&
            this.state.tableContent[sectionIdx].rows &&
            this.state.tableContent[sectionIdx].rows[rowIdx]
        ) {
            this.state.tableContent[sectionIdx].rows[rowIdx].content = newContent;
            this.setState({
                tableContent: this.state.tableContent
            });
        }
    }


    /*render() {
        let props = {
                title: "test1",
                placeholder: "placeholderTest",
                value: "taaaa"
            },
            props1 = {
                showClearIcon: true,
                ...props
            },
            props2 = {
                placeholder: "test1456",
                rightDefaultIcon: Icon.React.FaceID,
                leftIcon: Icon.React.User
            },
            props3 = {
                multiline: true,
                placeholder: "placeholderTest",
                value: "taaaa"
            },
            props6 = {
                multiline: true,
                ...props
            },
            props4 = {
                alignment: "right",
                ...props

            },
            props5 = {
                alignment: "left",
                ...props
            }
        return (
            <View style={{
                ...this.styles.reactView,
                flex: 1,
                flexDirection: "column",
                width: "100%",
            }}>
                <LxReactQuickSelect title={"Choose"} options={this.#options} onOptionSelected={(value) => {
                    debugger;
                }}/>
                <LxReactQuickSelect options={this.#options} onOptionSelected={(value) => {
                    debugger;
                }}/>
                <LxReactTextView {...props}/>
                <LxReactTextView {...props1}/>
                <LxReactTextView {...props2}/>
                <LxReactTextView {...props3}/>
                <LxReactTextView {...props6}/>
                <LxReactTextView {...props4}/>
                <LxReactTextView {...props5}/>
            </View>
        )
    }*/

    render() {
        debugger;

        /*const facebookAnimated = Animated.stagger(
            400,
            [
                this.firstLineRef.current.getAnimated(),
                Animated.parallel([
                    this.secondLineRef.current.getAnimated(),
                    this.thirdLineRef.current.getAnimated()
                ])
            ]
        );
        Animated.loop(facebookAnimated).start();*/


        return (
            <View style={{height: "100%", width: "100%",flexDirection: "column"}}>
                <View style={{
                    height: 100,
                    width: 600,
                    flexDirection: "row"
                }}>
                    <ShimmerPlaceHolder
                        shimmerColors={['#EBEBF599', '#c5c5c5', '#EBEBF599']}
                        style={{
                            width: 40,
                            height: 40,
                            borderRadius: 50
                        }}
                        visible={this.state.hideShimmer}>
                    </ShimmerPlaceHolder>
                    <View style={{
                        flexDirection: "column",
                        marginLeft: 20
                    }}>

                        <ShimmerPlaceHolder
                            shimmerColors={['#EBEBF599', '#c5c5c5', '#EBEBF599']}
                            style={{
                                borderRadius: 17,
                                height: 20,
                                width: 400
                            }}
                            visible={this.state.hideShimmer}>
                            <Text style={{
                                color: "white"
                            }}>
                                Wow, awesome here.
                            </Text>
                        </ShimmerPlaceHolder>
                        <ShimmerPlaceHolder
                            shimmerColors={['#EBEBF599', '#c5c5c5', '#EBEBF599']}
                            style={{
                                borderRadius: 17,
                                marginTop: 10,
                                height: 20,
                                width: 200
                            }}
                            visible={this.state.hideShimmer}>
                            <Text style={{
                                color: "white"
                            }}>
                                I know ;D
                            </Text>
                        </ShimmerPlaceHolder>
                    </View>

                </View>
                <View style={{
                    height: 100,
                    width: 600,
                    flexDirection: "row"
                }}>
                    <ShimmerPlaceHolder
                        shimmerColors={['#EBEBF599', '#c5c5c5', '#EBEBF599']}
                        style={{
                            width: 40,
                            height: 40,
                            borderRadius: 50
                        }}
                        visible={this.state.hideShimmer}>
                    </ShimmerPlaceHolder>
                    <View style={{
                        flexDirection: "column",
                        marginLeft: 20
                    }}>

                        <ShimmerPlaceHolder
                            shimmerColors={['#EBEBF599', '#c5c5c5', '#EBEBF599']}
                            style={{
                                borderRadius: 17,
                                height: 20,
                                width: 400
                            }}
                            visible={this.state.hideShimmer}>
                            <Text style={{
                                color: "white"
                            }}>
                                Wow, awesome here.
                            </Text>
                        </ShimmerPlaceHolder>
                        <ShimmerPlaceHolder
                            shimmerColors={['#EBEBF599', '#c5c5c5', '#EBEBF599']}
                            style={{
                                borderRadius: 17,
                                marginTop: 10,
                                height: 20,
                                width: 200
                            }}
                            visible={this.state.hideShimmer}>
                            <Text style={{
                                color: "white"
                            }}>
                                I know ;D
                            </Text>
                        </ShimmerPlaceHolder>
                    </View>

                </View>
                <View style={{
                    height: 100,
                    width: 600,
                    flexDirection: "row"
                }}>
                    <ShimmerPlaceHolder
                        shimmerColors={['#EBEBF599', '#c5c5c5', '#EBEBF599']}
                        style={{
                            width: 40,
                            height: 40,
                            borderRadius: 50
                        }}
                        visible={this.state.hideShimmer}>
                    </ShimmerPlaceHolder>
                    <View style={{
                        flexDirection: "column",
                        marginLeft: 20
                    }}>

                        <ShimmerPlaceHolder
                            shimmerColors={['#EBEBF599', '#c5c5c5', '#EBEBF599']}
                            style={{
                                borderRadius: 17,
                                height: 20,
                                width: 400
                            }}
                            visible={this.state.hideShimmer}>
                            <Text style={{
                                color: "white"
                            }}>
                                Wow, awesome here.
                            </Text>
                        </ShimmerPlaceHolder>
                        <ShimmerPlaceHolder
                            shimmerColors={['#EBEBF599', '#c5c5c5', '#EBEBF599']}
                            style={{
                                borderRadius: 17,
                                marginTop: 10,
                                height: 20,
                                width: 200
                            }}
                            visible={this.state.hideShimmer}>
                            <Text style={{
                                color: "white"
                            }}>
                                I know ;D
                            </Text>
                        </ShimmerPlaceHolder>
                    </View>

                </View>
            </View>
        )


    }

    #notify(title, msg) {
        NavigationComp.showPopup({
            title: title,
            message: msg,
            buttonOk: true
        });
    }
}

export default ComplexReactComp
