'use strict';

define(["AtheneControlContentBase"], function (AtheneControlContentBase) {
    return class AtheneControlContentUnsupported extends AtheneControlContentBase {
        constructor(details) {
            super(...arguments);
        }

        getActionSections() {
            var sections = super.getActionSections(...arguments),
                activitiesRow = this._getActivitiesRow(),
                ttsRow = this._getTtsRow(),
                section = {
                    rows: []
                };

            if (activitiesRow || ttsRow) {
                section.rows.pushObject(activitiesRow);
                section.rows.pushObject(ttsRow);
                sections.splice(0, 0, section);
            }

            return sections;
        }

    };
});
