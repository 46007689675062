'use strict';

define(["NfcCodeTouchControlContentCode", "NfcCodeTouchControlContentCodeValidity", "NfcCodeTouchControlEnums"], function (NfcCodeTouchControlContentCode, NfcCodeTouchControlContentCodeValidity, NfcCodeTouchControlEnums) {
    return class NfcCodeTouchControlContentCodes extends Controls.ControlContent {
        constructor(details) {
            super(details);
            this._codeDate = details.codeDate;
            this._activeCodes = details.activeCodes;
            this._inactiveCodes = details.inactiveCodes;
        }

        viewDidLoad() {
            var baseCall = super.viewDidLoad(...arguments);
            this.tableViewDataSource = tableViewDataSource(null, null, this);
            this.tableViewDelegate = tableViewDelegate(null, this);
            this.tableView = new GUI.TableViewV2(this.tableViewDataSource, this.tableViewDelegate);
            this.appendSubview(this.tableView);

            this._checkEmptyScreen();

            return baseCall;
        }

        titleBarText() {
            return _('user.access-codes');
        }

        titleBarConfig() {
            return {
                rightSide: TitleBarCfg.CUSTOM_ICON,
                rightIconSrc: 'resources/Images/General/TitleBar/titlebar_plus.svg'
            };
        }

        titleBarActionRight() {
            this.ViewController.showState(NfcCodeTouchControlEnums.ScreenState.CODE, null, {
                control: this.control,
                createNewCode: true
            });
        }

        viewWillAppear() {
            this.tableView.reload();
            return super.viewWillAppear(...arguments);
        }

        getURL() {
            return "Codes";
        }

        getAnimation() {
            return AnimationType.HD_OVERLAY;
        }

        receivedStates(states) {
            if (states.codeDate > this._codeDate) {
                this._loadCodes(true);
            }

            this._codeDate = states.codeDate;
        }

        _createAndReloadTableContent() {
            this.tableContent = [];
            this.tableContent.push(this._getCodesSection(true));
            this.tableContent.push(this._getCodesSection());
            this.tableViewDataSource.update(this.tableContent);
            this.tableViewDelegate.update(this.tableContent);
            this.tableView.reload();
        }

        _getCodesSection(isActiveCodes) {
            var section = {
                    headerTitle: isActiveCodes ? _('user.access-codes.active') : _('user.access-codes.inactive'),
                    rows: []
                },
                cellDataSet = isActiveCodes ? this._activeCodes : this._inactiveCodes,
                cell;
            section.rows = cellDataSet.map(function (codeEntry) {
                return this._createCell(codeEntry, isActiveCodes);
            }.bind(this));
            return section;
        }

        _createCell(codeEntry, isActiveCodes) {
            var icon,
                cell,
                checkCmd = isActiveCodes ? Commands.NFC_CODE_TOUCH.DEACTIVATE_CODE : Commands.NFC_CODE_TOUCH.ACTIVATE_CODE;

            switch (codeEntry.type) {
                case NfcCodeTouchControlEnums.CodeType.ONE_TIME:
                    icon = Icon.NfcCodeTouch.CODE_ONE_TIME;
                    break;

                case NfcCodeTouchControlEnums.CodeType.TIME_DEPENDENT:
                    icon = Icon.NfcCodeTouch.CODE_TIME_DEPENDANT;
                    break;

                default:
                    icon = null;
                    break;
            }

            cell = {
                type: GUI.TableViewV2.CellType.CHECKABLE,
                content: {
                    title: codeEntry.name,
                    rightIconSrc: icon,
                    disclosureIcon: true,
                    selected: codeEntry.isActive,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE
                },
                action: function () {
                    return this.ViewController.showState(NfcCodeTouchControlEnums.ScreenState.CODE, null, {
                        control: this.control,
                        code: codeEntry
                    });
                }.bind(this),
                didRequestCheckingCell: function () {
                    if (codeEntry.isEmpty) {
                        NavigationComp.showPopup({
                            message: _('user.access-codes.empty'),
                            buttonOk: _('ok'),
                            icon: Icon.WARNING,
                            color: window.Styles.colors.orange
                        }).then(function () {
                            return this.ViewController.showState(NfcCodeTouchControlEnums.ScreenState.CODE, null, {
                                control: this.control,
                                code: codeEntry
                            });
                        }.bind(this));
                        return false;
                    } else {
                        return true;
                    }
                }.bind(this),
                didCheckCell: function () {
                    return this.sendCommand(Commands.format(checkCmd, codeEntry.uuid));
                }.bind(this)
            };

            if (codeEntry.type === NfcCodeTouchControlEnums.CodeType.TIME_DEPENDENT && !isActiveCodes) {
                cell.didRequestCheckingCell = function () {
                    // resolve the promise immediately to prevent cell is showing as checked
                    var def = Q.defer(true);
                    this.ViewController.showState(NfcCodeTouchControlEnums.ScreenState.CODE_VALIDITY, null, {
                        control: this.control,
                        code: cloneObject(codeEntry),
                        reactivating: true
                    }, AnimationType.MODAL);
                    return def.promise;
                }.bind(this);
            }

            return cell;
        }

        _loadCodes(force) {
            return this.control.getCodes(force).then(function (result) {
                this._activeCodes = result.activeCodes;
                this._inactiveCodes = result.inactiveCodes;

                this._createAndReloadTableContent();

                this._checkEmptyScreen();
            }.bind(this));
        }

        /**
         * Checks the current dataset & shows or hides the empty view accordingly.
         * @private
         */
        _checkEmptyScreen() {
            if (this._inactiveCodes.length === 0 && this._activeCodes.length === 0) {
                this._presentEmtpyScreen();
            } else {
                this._emptyView && this.hideSubview(this._emptyView);
                this.showSubview(this.tableView); // initial table view reload

                this._createAndReloadTableContent();
            }
        }

        /**
         * Will prepare and show the empty screen.
         * @private
         */
        _presentEmtpyScreen() {
            if (!this._emptyView) {
                this._emptyView = new GUI.BackgroundView(Icon.NfcCodeTouch.CODES, _("user.access-code.no-codes"), _("user.access-code.create-new-code", {
                    plus: ImageBox.getResourceImageWithClasses(Icon.PLUS, "empty-view__icon")
                }), null, this.titleBarActionRight.bind(this));

                this._emptyView.getElement().addClass("nfc-code-touch-control-content-codes__empty-view");

                this.appendSubview(this._emptyView);
            }

            this.showSubview(this._emptyView);
            this.hideSubview(this.tableView);
        }

    };
});
