'use strict';

define([], function () {
    return {
        ScreenState: {
            CONTROL_CONTENT: 'RemoteControlContent',
            MODE_LIST: 'RemoteModeListControlContent'
        },
        Modes: {
            AllOff: 0
        },
        Pads: {
            DPAD: 0,
            NUMPAD: 1
        }
    };
});
