'use strict';

define(['MediaSearchBase', 'AudioZoneControlEnums', 'SpotifyAccountHelper'], function (MediaSearchBase, AudioZoneControlEnums, SpotifyAccountHelper) {
    class LegacySearchScreen extends MediaSearchBase {
        //region Getter
        get inputPlaceholder() {
            return _('media.search.specific', {
                where: this.delegate.desc
            });
        }

        get contentType() {
            return this.delegate.contentTypes[0];
        }

        get mediaType() {
            return this.delegate.mediaType;
        }

        get searchLocation() {
            return this.delegate.legacySearchIdentifier;
        } //endregion Getter


        constructor() {
            super(...arguments);
            this.start = 0;
            this.nitems = this.delegate.__getRequestPacketSizeForEventType(this.delegate.contentTypes[0]);

            if (this.searchLocation !== MediaEnum.SearchLoc.RADIO) {
                if (this.delegate instanceof Controls.AudioZoneControl.MediaBrowserPlaylists) {
                    this.tags.push(MediaEnum.Tag.PLAYLIST);
                }

                if (this.searchLocation && MediaServerComp.Feature.SEARCH_LIB_IMPROVED || (this.searchLocation === MediaEnum.SearchLoc.SPOTIFY || this.searchLocation === MediaEnum.SearchLoc.LMS) && MediaServerComp.Feature.SEARCH_SERVICES_IMPROVED) {
                    this.tags.push(MediaEnum.Tag.TRACK, MediaEnum.Tag.ARTIST, MediaEnum.Tag.ALBUM); // Searching playlists is only supported in spotify.

                    if (MediaServerComp.Feature.SEARCHABLE_PLAYLISTS && (this.searchLocation === MediaEnum.Service.SPOTIFY || this.searchLocation === MediaEnum.SearchLoc.LIBRARY || this.searchLocation === MediaEnum.SearchLoc.LMS)) {
                        this.tags.push(MediaEnum.Tag.PLAYLIST);
                    }

                    if (MediaServerComp.Feature.SEARCHABLE_GENRES && this.searchLocation !== MediaEnum.SearchLoc.LIBRARY && this.searchLocation !== MediaEnum.Service.SPOTIFY) {
                        this.tags.push(MediaEnum.Tag.GENRE);
                    }
                }
            }
        }

        showCancelButton() {
            return true;
        }

        rightSectionButtonTapped(section, tableView) {
            var lastSelectedItem = {
                    contentType: this.delegate.contentTypes[0],
                    name: this._getNameForTag(this.tag) + " - " + this.keyword
                },
                showStateArgs;

            if (this._gotResults) {
                showStateArgs = [Controls.AudioZoneControl.MediaBrowserBase.getScreenStateForItem(lastSelectedItem), null, {
                    username: this.username,
                    lastSelectedItem: lastSelectedItem,
                    identifier: this.searchLocation,
                    contentTypes: [lastSelectedItem.contentType],
                    mediaType: this.delegate.mediaType,
                    isFromSearch: true,
                    srcCmd: [MediaEnum.Commands.SEARCH.SELECTIVE, this.searchLocation, this.username, this.tag, encodeURIComponent(this.keyword), this.start, this.nitems].join("/")
                }];
                this.ViewController.showState.apply(this.ViewController, showStateArgs);
            }
        }

        _getNameForTag(tag) {
            var name;

            switch (tag) {
                case MediaEnum.Tag.ARTIST:
                    name = _("media.artist");
                    break;

                case MediaEnum.Tag.ALBUM:
                    name = _("media.album");
                    break;

                case MediaEnum.Tag.TRACK:
                    name = _("media.title");
                    break;

                case MediaEnum.Tag.PLAYLIST:
                    name = _("media.playlist");
                    break;

                case MediaEnum.Tag.GENRE:
                    name = _("media.genre");
                    break;

                default:
                    name = tag;
            }

            return name;
        }

    }

    Controls.AudioZoneControl.LegacySearchScreen = LegacySearchScreen;
    return Controls.AudioZoneControl.LegacySearchScreen;
});
