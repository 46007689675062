'use strict';

define(["IRoomControlEnums"], function (IRoomControlEnums) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var SERVICE_MODE_IDENTIFIER = -1;
    return class IRoomControlContentControllerMode extends Controls.ControlContent {
        constructor(details) {
            super(details);
            this.currentVisuType = details.control.details.restrictedToMode;
            this.isLessInformation = this.currentVisuType === IRoomControlEnums.VisuType.HEATING || this.currentVisuType === IRoomControlEnums.VisuType.COOLING;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.tableView = new GUI.TableViewV2(this, this);
            this.tableView.getElement().addClass("controller-mode__table-view");
            this.appendSubview(this.tableView);
            this.tableView.reload();
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        getURL() {
            return "ModeList";
        }

        titleBarText() {
            return _("controls.irc.mode");
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK,
                style: 'transparent'
            };
        }

        receivedStates(states) {
            var serviceMode = states.serviceMode !== IRoomControlEnums.ServiceMode.OFF;
            var visuType;

            if (this.control.details.restrictedToMode === IRoomControlEnums.VisuType.HEATING && this.control.isHeating(states.controllerMode)) {
                visuType = IRoomControlEnums.VisuType.HEATING;
            } else if (this.control.details.restrictedToMode === IRoomControlEnums.VisuType.COOLING && this.control.isCooling(states.controllerMode)) {
                visuType = IRoomControlEnums.VisuType.COOLING;
            } else if (serviceMode) {
                visuType = SERVICE_MODE_IDENTIFIER;
            } else {
                visuType = IRoomControlEnums.VisuType.HEATING_AND_COOLING;
            }

            this.currentVisuType = visuType;
            this.serviceModeActive = serviceMode; // full auto is maybe not visible

            this.controllerMode = states.controllerMode;

            if (this.controllerMode === IRoomControlEnums.IRCMode.FULL_AUTO_HEATING && this.currentVisuType === IRoomControlEnums.VisuType.HEATING) {
                this.controllerMode = IRoomControlEnums.IRCMode.AUTO_HEATING;
            } else if (this.controllerMode === IRoomControlEnums.IRCMode.FULL_AUTO_COOLING && this.currentVisuType === IRoomControlEnums.VisuType.COOLING) {
                this.controllerMode = IRoomControlEnums.IRCMode.AUTO_COOLING;
            }

            this.tableView.reload();
        }

        // TableViewDataSource methods
        styleForTableView() {
            return TableViewStyle.GROUPED;
        }

        numberOfSections() {
            if (this.currentVisuType === IRoomControlEnums.VisuType.HEATING || this.currentVisuType === IRoomControlEnums.VisuType.COOLING) {
                return 2;
            }

            return 3;
        }

        numberOfRowsInSection(section) {
            if (this.currentVisuType === IRoomControlEnums.VisuType.HEATING || this.currentVisuType === IRoomControlEnums.VisuType.COOLING) {
                if (section === 0) {
                    return 2; // auto and manual heating OR auto and manual cooling
                } else {
                    return 1; // service
                }
            }

            if (section === 1) {
                return 4; // auto and manual heating AND auto and manual cooling
            } else {
                return 1; // full auto or service
            }
        }

        cellTypeForCellAtIndex() {
            return GUI.TableViewV2.CellType.CHECKABLE;
        }

        contentForCell(cell, section, row) {
            var ircMode = this._ircModeForIndexPath({
                section: section,
                row: row
            });

            var result = {
                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE
            };

            if (ircMode === SERVICE_MODE_IDENTIFIER) {
                result.title = _("controls.irc.mode.service");
            } else {
                var tmpIRCMode = ircMode;

                if (ircMode <= IRoomControlEnums.IRCMode.FULL_AUTO_COOLING) {
                    tmpIRCMode = IRoomControlEnums.IRCMode.FULL_AUTO;
                }

                result.title = this.control.getNameOfIRCMode(tmpIRCMode);
            }

            switch (ircMode) {
                case IRoomControlEnums.IRCMode.FULL_AUTO:
                case IRoomControlEnums.IRCMode.FULL_AUTO_HEATING:
                case IRoomControlEnums.IRCMode.FULL_AUTO_COOLING:
                    result.subtitle = '(' + _("controls.irc.mode.full-auto.info") + ')';
                    break;

                case IRoomControlEnums.IRCMode.AUTO_HEATING:
                    result.subtitle = '(' + _("controls.irc.mode.auto.heat.info") + ')';
                    break;

                case IRoomControlEnums.IRCMode.AUTO_COOLING:
                    result.subtitle = '(' + _("controls.irc.mode.auto.cool.info") + ')';
                    break;

                case SERVICE_MODE_IDENTIFIER:
                    result.subtitle = _("controls.irc.mode.service.info");
                    break;
            }

            if (ircMode === this.controllerMode && !this.serviceModeActive || ircMode === SERVICE_MODE_IDENTIFIER && this.serviceModeActive) {
                result.selected = true;
            }

            return result;
        }

        // TableViewDelegate methods
        didCheckCell(cell, section, row, tableView) {
            // don't set direct to this.controllerMode - can be SERVICE_MODE_IDENTIFIER
            var ircMode = this._ircModeForIndexPath({
                section: section,
                row: row
            });

            if (ircMode === SERVICE_MODE_IDENTIFIER) {
                this.sendCommand(Commands.format(Commands.I_ROOM_CONTROLLER.SERVICE, this.serviceModeActive ? 0 : 1));
            } else {
                this.controllerMode = ircMode;
                this.sendCommand(Commands.format(Commands.I_ROOM_CONTROLLER.MODE, this.controllerMode)); // disable service mode if enabled!

                this.serviceModeActive && this.sendCommand(Commands.format(Commands.I_ROOM_CONTROLLER.SERVICE, 0));
            }
        }

        // Private methods
        _ircModeForIndexPath(indexPath) {
            var section = indexPath.section,
                row = indexPath.row;
            var ircMode;

            if (this.isLessInformation) {
                if (section === 0) {
                    if (this.currentVisuType === IRoomControlEnums.VisuType.HEATING) {
                        if (row === 0) {
                            ircMode = IRoomControlEnums.IRCMode.AUTO_HEATING;
                        } else {
                            ircMode = IRoomControlEnums.IRCMode.MANUAL_HEATING;
                        }
                    } else if (row === 0) {
                        ircMode = IRoomControlEnums.IRCMode.AUTO_COOLING;
                    } else {
                        ircMode = IRoomControlEnums.IRCMode.MANUAL_COOLING;
                    }
                } else {
                    ircMode = SERVICE_MODE_IDENTIFIER;
                }
            } else if (section === 0) {
                // full auto
                if (this.control.isHeating(this.controllerMode)) {
                    ircMode = IRoomControlEnums.IRCMode.FULL_AUTO_HEATING;
                } else if (this.control.isCooling(this.controllerMode)) {
                    ircMode = IRoomControlEnums.IRCMode.FULL_AUTO_COOLING;
                } else {
                    ircMode = IRoomControlEnums.IRCMode.FULL_AUTO;
                }
            } else if (section === 1) {
                if (row === 0) {
                    ircMode = IRoomControlEnums.IRCMode.AUTO_HEATING;
                } else if (row === 1) {
                    ircMode = IRoomControlEnums.IRCMode.AUTO_COOLING;
                } else if (row === 2) {
                    ircMode = IRoomControlEnums.IRCMode.MANUAL_HEATING;
                } else {
                    ircMode = IRoomControlEnums.IRCMode.MANUAL_COOLING;
                }
            } else {
                ircMode = SERVICE_MODE_IDENTIFIER;
            }

            return ircMode;
        }

    };
});
