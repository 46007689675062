'use strict';

define([], function () {
    return class ArrangementSettingsScreen extends GUI.TableViewScreen {
        constructor() {
            super($('<div class="arrangement-settings-screen" />'));
            this.sortByRating = PersistenceComponent.getMiniserverSettings().sortByRating;
        }

        reloadTable() {
            this.tableContent = [{
                rows: [this._generateRow(_('menu.properties.sort-lists-alphabetically'), !this.sortByRating), this._generateRow(_('menu.properties.sort-lists-by-rating'), this.sortByRating)]
            }];
            return super.reloadTable(...arguments);
        }

        titleBarText() {
            return _('menu.settings.arrangement');
        }

        _generateRow(title, selected) {
            return {
                type: GUI.TableViewV2.CellType.CHECKABLE,
                content: {
                    title: title,
                    selected: selected
                },
                didCheckCell: function didCheckCell(cell, section, row) {
                    this._setSortByRating(row === 1);
                }.bind(this)
            };
        }

        _setSortByRating(sbr) {
            this.sortByRating = sbr;
            PersistenceComponent.activateSortByRating(this.sortByRating);
            this.reloadTable();
            NavigationComp.dispatchEventToUI(NavigationComp.UiEvents.SortByRatingChanged);
        }

    };
});
