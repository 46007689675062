import {useState, useEffect} from "react";
import {useDeviceSearchExtensions} from "LxComponents";
import DeviceSearchUtils from "./deviceSearchUtils";

export default function useDeviceSearchMiniservers(searchType) {
    const [msList, setMsList] = useState(null);
    const extensionList = useDeviceSearchExtensions(searchType);

    useEffect(() => {
        if(Feature.GENERIC_DEVICE_SEARCH) {
            if(!extensionList) {
                return;
            }
            ActiveMSComponent.getAvailableMiniservers().then((res) => {
                if(!res) {
                    return;
                }
                const extendedMsList = DeviceSearchUtils.populateMsListWithExtensionInfo(res, extensionList);
                setMsList(extendedMsList);
            });
        } else {
            let msList = DeviceSearchUtils.createMiniserverListFromExtensionList(extensionList);
            setMsList(msList);
        }
    }, [extensionList]);

    return {
        msList: msList,
        totalFoundDevices: DeviceSearchUtils.getTotalFoundDevicesFromMsList(msList),
        totalLoadingFoundDevices: DeviceSearchUtils.getTotalLoadingFoundDevices(msList)
    };
}