import {useCallback, useEffect, useMemo} from "react";
import EcoScreenUtils from "../../EcoScreen/EcoScreenUtils";
import useAmbientShortcuts from "../hooks/useAmbientShortcuts";
import {NewSelectorScreen, LxReactFlexibleCell, navigatorConfig} from "LxComponents";
import AmbientUtils from "../AmbientUtils";

function AmbientShortcutSelection(props) {
    const selectedControlUuids = useAmbientShortcuts(true);

    useEffect(() => {
        props.navigation.setOptions(navigatorConfig({
            title: _("ambient.settings.shortcuts.add-multiple"),
            animationType: AnimationType.FADE
        }))
    }, [])

    const options = useMemo(() => {
        return EcoScreenUtils.createRoomOptions();
    }, [])

    const onOptionSelected = useCallback((controlUuid, selected) => {
        if (selected && selectedControlUuids.length === AmbientUtils.MAX_SHORTCUTS) {
            return;
        }
        let newSelectedControlUuids = [...selectedControlUuids]
        if (selected && !newSelectedControlUuids.includes(controlUuid)) {
            let safeControlUuids = Array.isArray(controlUuid) ? controlUuid : [controlUuid]
            let reallyNewControlUuids = safeControlUuids.filter(ctrlUuid => !newSelectedControlUuids.includes(ctrlUuid));
            reallyNewControlUuids.length > 0 && newSelectedControlUuids.push(...reallyNewControlUuids)
        } else {
            const controlIndex = newSelectedControlUuids.indexOf(controlUuid);
            if (controlIndex !== -1) {
                newSelectedControlUuids.splice(controlIndex, 1)
            }
        }

        PersistenceComponent.updateAmbientModeSetting({
            quickAccessControls: newSelectedControlUuids
        });
    }, [selectedControlUuids])

    const meetsFilter = (text, filterText) => {
        return text && text.toLowerCase().includes(filterText.toLowerCase());
    }

    const getFilteredContent = useCallback((filterText) => {
        const controlOptions = options.flatMap(roomOption => roomOption.options);
        const filteredControlOptions = controlOptions.filter(ctrlOption => meetsFilter(ctrlOption.title, filterText) || meetsFilter(ctrlOption.subTitle, filterText))
        const rows = filteredControlOptions.map(option => {
            return {
                controlUuid: option.id,
                title: option.title,
                subTitle: option.subTitle,
                checked: selectedControlUuids.includes(option.id),
                radioMode: LxReactFlexibleCell.RadioMode.Inactive,
                onCheckedToggled: checked => {
                    if (checked && selectedControlUuids.length === AmbientUtils.MAX_SHORTCUTS) {
                        AmbientUtils.showMaxShorcutReachedPopup()
                        return Q.reject()
                    }
                    return Q.resolve(true)
                },
                onCheckedChange: (checked, dueToRadioMode = false) => {
                    onOptionSelected(option.id, checked)
                }
            }
        });
        return [{rows}]
    }, [options, selectedControlUuids, onOptionSelected])

    const showMaxShortcutsReached = useCallback(() => AmbientUtils.showMaxShorcutReachedPopup(), []);

    return <NewSelectorScreen title={_("ambient.settings.shortcuts.add-multiple")}
                           allowMultiple={true}
                           dispatchSelectionChange={true}
                           options={options}
                           animationType={AnimationType.FADE}
                           selectedId={selectedControlUuids}
                           onSelected={onOptionSelected}
                           getCustomFilteredContent={getFilteredContent}
                           filterTitle={_("search.title")}
                           maxItemCount={AmbientUtils.MAX_SHORTCUTS}
                           onMaxItemsReached={showMaxShortcutsReached}
    />
}

export default AmbientShortcutSelection
