'use strict';

define([], function () {
    return {
        TYPE: {
            TIME: 0,
            YEAR: 1,
            MONTH: 2,
            DAY: 3,
            HOUR: 4,
            MINUTE: 5,
            SECOND: 6,
            START: 7,
            NIGHTFALL: 8,
            DAYBREAK: 9,
            SUNSET: 10,
            SUNRISE: 11
        }
    };
});
