import {
    SearchableTable,
    navigatorConfig
} from "LxComponents"
import PropTypes from "prop-types";
import {useState, useEffect, useMemo} from "react";
import globalStyles from "GlobalStyles";
import Icons from "IconLib";
import {userStyles} from "./UserStyles";
import useTrustManager from "./trustUsers/useTrustManager";
import TrustTableViewHelper from "./trustUsers/TrustTableViewHelper";
import {useBackNavigation} from "../../../../../react-comps/Components";

function UserManagementScreen({navigation, route}) {
    const [users, setUsers] = useState(null);
    const [trustPeers, setTrustPeers] = useState(null);
    const {trustManager} = useTrustManager();

    let _userHasUserManagementRight = SandboxComponent.checkPermission(MsPermission.USER_MANAGEMENT) && !SandboxComponent.checkPermission(MsPermission.ADMIN);

    useBackNavigation(() => {
        navigation.goBack();
    }, []);

    useEffect(() => {
        navigation.setOptions(navigatorConfig({
            animationType: AnimationType.HD_MODAL_FULLSCREEN,
            title: _('user.management')
        }));
        trustManager.onTrustUsersChanged(() => {
            _loadDataFromMS();
        });

        return () => {
            trustManager.offTrustUsersChanged();
        }
    }, [])

    useEffect(() => {
        if (!route.params.hasOwnProperty('needsReload') || route.params.needsReload) {
            _loadDataFromMS();
        }
    }, [route.params])

    //private Section
    const _getUsersSection = (key, users = []) => {
        if (users.length) {
            return {
                headerTitle: key,
                headerTitleStyle: userStyles.letterHeading,
                rows: users.map(_createCellForUser.bind(this))
            };
        }
    }

    const _createCellForUser = (user) => {
        let disableAdmins = _userHasUserManagementRight && user.isAdmin,
            subTitleProperties,
            subTitle,
            subTitleColor;

        if (Feature.USER_CAN_BE_DISABLED) {
            subTitleProperties = ActiveMSComponent.getUserStateDescription(user);
            subTitle = subTitleProperties.stateDescription;
            subTitleColor = subTitleProperties.descriptionColor;
        } else {
            subTitle = null;
            subTitleColor = null;
        }

        return {
            type: GUI.TableViewV2.CellType.USER_CELL,
            action: () => {
                navigation.navigate(ScreenState.UserDetailsScreen, {
                    user: user,
                    allUsers: users,
                    userUuid: user.uuid,
                    trustPeers: trustPeers //usergroups: this.usergroups

                });
            },
            content: {
                user: user,
                trustPeers: trustPeers,
                disabled: disableAdmins,
                disclosureIcon: !disableAdmins,
                disclosureText: user.isAdmin ? _('admin') : null,
                disclosureColor: globalStyles.colors.stateActive,
                clickable: true,
                subtitle: subTitle,
                subtitleColor: subTitleColor
            },
            searchTags: user.name
        };
    }

    const _createAddUserSection = () => {
        let section = {
            rows: []
        };
        section.rows.push({
            action: _showInformationPopup.bind(this, false),
            content: {
                title: _('user.add'),
                leftIconSrc: Icons.PlusFilledCircle,
                leftIconColor: globalStyles.colors.stateActive,
                clickable: true
            },
            excludeFromSearch: true
        });

        if (trustPeers) {
            if (trustPeers.length) {
                section.rows.push({
                    action: function () {
                        navigation.navigate(ScreenState.TrustPeerSelectScreen, {
                            peers: trustPeers
                        })
                    }.bind(this),
                    content: {
                        title: _("usermanagement.trust.managed-user"),
                        leftIconSrc: Icons.TrustUser,
                        leftIconColor: globalStyles.colors.stateActive,
                        clickable: true,
                        disclosureIcon: true
                    },
                    excludeFromSearch: true
                });
            } else {
                // nothing to do, only remove the dummy cell
            }
        } else {
            section.rows.push(TrustTableViewHelper.getDummyCell())
        }

        return section;
    }

    /**
     * shows a popup with an input field
     * @param retry if true --> popup will shown with red message color and other message
     * @param name
     * @returns {*}
     * */

    const _showInformationPopup = (retry, name) => {
        let message, messageColor;

        if (retry) {
            message = _('user.exists', {
                username: name
            });
            messageColor = globalStyles.colors.red;
        } else {
            message = _('user.new.message');
        }

        return NavigationComp.showPopup({
            title: _('user.new.title'),
            message: message,
            messageColor: messageColor,
            buttonOk: _('user.add.short'),
            buttonCancel: true,
            icon: Icon.INFO,
            color: globalStyles.colors.stateActive,
            input: {
                id: "username",
                required: true,
                validationRegex: Regex.USERNAME,
                filterRegex: Regex.USERNAME_FILTER
            }
        }, PopupType.INPUT).then((res) => {
            let user = {
                    name: res.result,
                    changePassword: true,
                },
                addUser; // checks if the username already exists

            addUser = users.find(function (user) {
                return user.name === res.result;
            });

            if (!addUser) {
                ActiveMSComponent.addOrEditUser(user).then(function (user) {
                    navigation.navigate(ScreenState.UserDetailsScreen, {
                        user: JSON.parse(cloneObjectDeep(user.LL.value)),
                        allUsers: users,
                        isNewUser: true
                    })
                }.bind(this));
            } else {
                _showInformationPopup(true, res.result);
            }
        });
    }

    const _loadDataFromMS = () => {
        let tmpTrustPeers = [];

        ActiveMSComponent.getUsers(true).then((users) => {
            setUsers(users);
        })
        if (Feature.TRUST) {
            ActiveMSComponent.getTrustPeers().then((response) => {
                let thisSerial = ActiveMSComponent.getActiveMiniserver().serialNo,
                    tmpTrustPeers = response.peers.filter((peers) => {
                        return peers.serial !== thisSerial;
                    });

                setTrustPeers(tmpTrustPeers);
            }, () => {
                setTrustPeers(tmpTrustPeers);
            });
        } else {
            setTrustPeers(tmpTrustPeers); // set the value incase of older miniserver versions
        }
    }

    const userManagementContent = useMemo(() => {
        let tableContent = [],
            _sortedUsers;

        if (Feature.USER_MANAGEMENT_REWORK) {
            tableContent.push(_createAddUserSection());
        }


        if (users && trustPeers) {
            _sortedUsers = ActiveMSComponent.sortUsers(users);
            Object.keys(_sortedUsers).forEach((key) => {
                let users = _sortedUsers[key];
                tableContent.pushObject(_getUsersSection(key, users));
            })
        } else {
            tableContent.push(TrustTableViewHelper.getDummyContent());
        }

        return tableContent;
    }, [users, trustPeers]);

    return (
        <SearchableTable
            tableContent={userManagementContent}
            filterTitle={_("search.title")}
            showItemSeparatorOnFirstItem={true}
            insets={["bottom"]}
            useSubTitle={false}
        />
    )
}

UserManagementScreen.propTypes = {
    user: PropTypes.object,
    type: PropTypes.string,
    initSecretScore: PropTypes.number,
    setTitleBarButtonValid: PropTypes.func,
    setOptions: PropTypes.func
}

export default UserManagementScreen;
