import Svg, { Path } from "react-native-svg"

function Sort(props) {
    return (
        <Svg isreact="true"
            x="0px"
            y="0px"
            viewBox="0 0 30 30"
            xmlSpace="preserve"
            enableBackground="new 0 0 30 30"
            {...props}
        >
            <Path
                d="M6 14h18v2H6v-2zm0-5h18v2H6V9zm0 10h18v2H6v-2z"
                fillRule="evenodd"
                clipRule="evenodd"
            />
        </Svg>
    )
}

export default Sort
