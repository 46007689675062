'use strict';

window.GUI = function (GUI) {
    class MiniserverDetailsScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div />'));
            this.setDetails(details);
        }

        setDetails(details) {
            var serialNo = details && details.serialNo;

            if (!serialNo) {
                serialNo = ActiveMSComponent.getMiniserverSerialNo();
            }

            this.isWebinterface = PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface;
            this.miniserver = PersistenceComponent.getAllMiniserver()[serialNo];
            this.isActiveMiniserver = ActiveMSComponent.getMiniserverSerialNo() === serialNo;
            this.reachMode = this.isActiveMiniserver ? CommunicationComponent.getCurrentReachMode() : ReachMode.NONE;
            this.noActions = details ? details.noActions : false;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
        }

        titleBarText() {
            return _("details");
        }

        getAnimation() {
            return HD_APP ? AnimationType.PUSH_OVERLAP_LEFT : AnimationType.MODAL;
        }

        styleForTableView() {
            return TableViewStyle.COMFORT_MODE_2020;
        }

        reloadTable() {
            var sectionOne = {
                    rows: []
                },
                sectionTwo = {
                    rows: []
                },
                sectionThree = {
                    rows: []
                },
                sectionFour = {
                    rows: []
                },
                sectionFive = {
                    rows: []
                },
                sectionSix = {
                    rows: []
                },
                sectionSeven = {
                    rows: []
                },
                sectionEight = {
                    rows: []
                }; // Section one

            sectionOne.rows.push({
                content: {
                    title: _("miniserver.label"),
                    disclosureText: this.miniserver.msName
                }
            }); // Section two

            sectionTwo.rows.push({
                content: {
                    title: _('conn'),
                    disclosureText: this.miniserver.msName
                }
            });
            sectionTwo.rows.push({
                content: {
                    title: _('user'),
                    disclosureText: this.miniserver.activeUser
                }
            }); // Section three

            if (this.miniserver.localUrl && this.miniserver.localUrl !== "") {
                sectionThree.rows.push({
                    content: {
                        title: _('miniserver.url.local'),
                        disclosureText: this.miniserver.localUrl,
                        clickable: true
                    }
                });
            }

            if (this.miniserver.remoteUrl && this.miniserver.remoteUrl !== "") {
                sectionThree.rows.push({
                    content: {
                        title: _('miniserver.url.remote'),
                        disclosureText: this.miniserver.remoteUrl,
                        clickable: true
                    }
                });
            } // Section four


            sectionFour.rows.push({
                content: {
                    title: _('miniserver.serial-number'),
                    disclosureText: this.miniserver.serialNo,
                    clickable: true
                }
            });
            sectionFour.rows.push({
                content: {
                    title: _('miniserver.firmware'),
                    disclosureText: ActiveMSComponent.getConfigVersion(),
                    clickable: true
                }
            }); // Section five

            sectionFive.rows.push({
                type: GUI.TableViewV2.CellType.SWITCH,
                content: {
                    title: _("favorites.show-as")
                }
            }); // Section six

            sectionSix.rows.push({
                type: GUI.TableViewV2.CellType.LEFT,
                content: {
                    title: _("miniserver.trigger-reboot")
                },
                action: this._handleRestart.bind(this)
            }); // Section seven

            sectionSeven.rows.push({
                type: GUI.TableViewV2.CellType.LEFT,
                content: {
                    title: this.reachMode === ReachMode.NONE ? _('connect') : _('disconnect.connection'),
                    active: true
                },
                action: this._handleConnectOrDisconnect.bind(this)
            }); // Section eight

            sectionEight.rows.push({
                type: GUI.TableViewV2.CellType.LEFT,
                content: {
                    title: _('miniserverlist.delete')
                },
                action: this._handleDelete.bind(this)
            });
            this.tableContent = [sectionOne, sectionTwo, sectionThree, sectionFour, sectionFive, sectionSix, sectionSeven, sectionEight];
            return super.reloadTable(...arguments);
        }

        _handleConnectOrDisconnect() {
            if (this.isWebinterface) {
                location.reload();
                return;
            }

            if (this.reachMode === ReachMode.NONE) {
                NavigationComp.connectTo(this.miniserver);
            } else {
                NavigationComp.disconnect();
            }
        }

        _handleRestart() {
            console.log("Miniserver is restarting");
        }

        _handleDelete() {
            var content = {
                title: _('miniserverlist.delete'),
                message: _('miniserverlist.delete.confirmation'),
                buttonOk: _('delete'),
                buttonCancel: true,
                icon: Icon.DELETE,
                color: window.Styles.colors.red
            };
            NavigationComp.showPopup(content).then(function () {
                PersistenceComponent.removeMiniserver(this.miniserver.serialNo);
                NavigationComp.disconnect();
            }.bind(this), function () {
            });
        }

    }

    GUI.MiniserverDetailsScreen = MiniserverDetailsScreen;
    return GUI;
}(window.GUI || {});
