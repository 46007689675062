'use strict';

define([], function () {
    return {
        HistoryType: {
            CODE_USER: 0,
            // code (can be associated with one user)
            CODE: 1,
            // code (with name)
            CODE_AMBIGUOUS: 2,
            // code (ambiguous)
            NFC_USER: 3,
            // nfc (can be associated with one user)
            NFC: 4,
            // nfc (with name)
            NFC_AMBIGUOUS: 5,
            // nfc (ambiguous)
            // authentication steps will be displayed up to VISU --> see history.js
            VISU: 6,
            // user via visu
            CODE_DENIED: 7,
            // code (denied)
            NFC_DENIED: 8,
            // nfc (denied)
            VISU_DENIED: 9, // visu (denied)
            EXT_ACCEPTED: 10,
            EXT_DECLINED: 11

        },
        CodeType: {
            PERMANENT: 0,
            ONE_TIME: 1,
            TIME_DEPENDENT: 2
        },
        OUTPUT_UNAUTHORIZED: -1,
        OUTPUT_TWOFACTOR_STEP1: 0,
        OutputBitmask: {
            UNAUTHORIZED: 0,
            // 00000000
            ONE: 1,
            // 00000001
            TWO: 2,
            // 00000010
            THREE: 4,
            // 00000100
            FOUR: 8,
            // 00001000
            FIVE: 16,
            // 00010000
            SIX: 32 // 00100000

        },
        DeviceStates: {
            READY_TO_LEARN_TAG: 0,
            OFFLINE: 1,
            DUMMY_TREE: 3,
            CAN_NOT_READ_TAG: 4,
            DUMMY_AIR: 7
        },
        CodeTouchSortKey: {
            READY_TO_LEARN_TAG: "readyToLearnTag",
            OFFLINE: "offline",
            DUMMY: "dummy",
            CAN_NOT_READ_TAG: "canNotReadNfc"
        },
        ScreenState: {
            HISTORY: 'NfcCodeTouchControlContentHistory',
            CODES: 'NfcCodeTouchControlContentCodes',
            CODE: 'NfcCodeTouchControlContentCode',
            CODE_VALIDITY: 'NfcCodeTouchControlContentCodeValidity',
            CODE_PERMISSIONS: 'NfcCodeTouchControlContentCodePermissions',
            LEARN_NFCTAG: 'NfcCodeTouchControlContentLearnNfcTag'
        },
        ErrorResponse: {
            BRUTE_FORCE: "brute force protection",
            // also occurs when adding new codes, as the already in use check also allows brute forcing exisitng codes.
            ALREADY_IN_USE: "code exists already!"
        },
        AuthType: {
            TwoFA: 0,
            CodeOrNFc: 1,
            Nfc: 2,
            Code: 3
        }
    };
});
