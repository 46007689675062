'use strict';

define(["ControlActionScreen", "UniversalControlInfoView"], function (ControlActionScreen, UniversalControlInfoView) {
    return class UniversalControlValueScreen extends ControlActionScreen {
        constructor() {
            super(...arguments);
            this.stateKey = this.details.stateKey;
            this.stateValue = this.details.stateValue;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.setSubtitle(this.stateKey);
        }

        // Don't show the top right context menu button
        getRightIcon() {
            return null;
        }

        // Don't waste any space by showing an icon
        shouldDisplayStateIcons() {
            return false;
        }

        getCustomInfoView() {
            return new UniversalControlInfoView(this.control, this.stateKey, this.stateValue);
        }

    };
});
