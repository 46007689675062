'use strict';

class StaticScreenBase extends GUI.Screen {
    //region Static
    static Template = function () {
        var getTemplate = function getTemplate() {
            return $('<div class="static-screen-base"></div>');
        };

        return {
            getTemplate: getTemplate
        };
    }(); //endregion Static

    constructor() {
        super(StaticScreenBase.Template.getTemplate());
    }

    getAnimation() {
        return AnimationType.HD_OVERLAY_FULLSCREEN;
    }

    toggle() {
        if (!this._viewDidAppearPassed) {
            this.show();
        } else {
            this.remove();
        }

        return this;
    }

    show() {
        var prms = [true];

        if (this._viewDidAppearPassed) {
            Debug.GUI.StaticScreen && console.info(this.name + " is already shown");
        } else {
            this.setViewController({});

            if (!this._viewDidLoadPassed) {
                prms.push(this.viewDidLoad());
            }

            prms.push(Q(this.viewWillAppear()).then(function () {
                $("root-view").append(this.getElement());
                return this.viewDidAppear();
            }.bind(this)));
        }

        return Q.all(prms);
    }

    remove() {
        var returnPrms;

        if (this._screenWillBeAlreadyRemoved) {
            returnPrms = this._removePromise;
        } else {
            if (this._viewDidAppearPassed) {
                this._screenWillBeAlreadyRemoved = true;
                this._removePromise = Q(this.viewWillDisappear()).then(function () {
                    this.getElement().remove();
                    return this.viewDidDisappear();
                }.bind(this));
            } else {
                Debug.GUI.StaticScreen && console.info(this.name + " is already hidden");
                this._removePromise = Q.resolve();
            }

            returnPrms = this._removePromise;
        }

        this._removePromise.finally(function () {
            this._screenWillBeAlreadyRemoved = false;
        }.bind(this));

        return returnPrms;
    }

}

GUI.StaticScreenBase = StaticScreenBase;
