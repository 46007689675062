import {useEffect, useState} from "react";


export default function useAppPairingSetup() {
    const [state, setState] = useState({});

    useEffect(() => {
        const unregFn = PairedAppComponent.registerForPairingSetup((props) => {
            Debug.PairedApp && console.log(useAppPairingSetup.name, "pairingSetup changed: " + props.state + ": ", props);
            setState({...props});
        });

        return unregFn;
    }, [])

    return state;
}