import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Weather23(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            <Path
                d="M3 16.2a4.79 4.79 0 012.44-4.158A7.26 7.26 0 0112.75 5a7.333 7.333 0 016.22 3.412A5.36 5.36 0 0120.47 8.2a5.28 5.28 0 014.944 3.37A4.793 4.793 0 0124.125 21H7.875A4.838 4.838 0 013 16.2zM24.602 24.6h-.636l.166-.167a.4.4 0 00-.565-.565l-.167.166v-.636a.4.4 0 00-.8 0v.636l-.167-.166a.4.4 0 00-.565.565l.166.167h-.636a.4.4 0 000 .8h.636l-.166.167a.4.4 0 00.565.565l.167-.166v.636a.4.4 0 00.8 0v-.636l.167.166a.4.4 0 00.565-.566l-.166-.166h.636a.4.4 0 000-.8zM10.602 24.6h-.636l.166-.167a.4.4 0 00-.565-.565l-.167.166v-.636a.4.4 0 00-.8 0v.636l-.167-.166a.4.4 0 00-.565.565l.166.167h-.636a.4.4 0 000 .8h.636l-.166.167a.4.4 0 00.565.565l.167-.166v.636a.4.4 0 00.8 0v-.636l.167.166a.4.4 0 00.565-.566l-.166-.166h.636a.4.4 0 000-.8zM18.403 25.4h-.954l.25-.25a.6.6 0 00-.85-.848l-.249.25v-.955a.6.6 0 00-1.2 0v.954l-.25-.25a.6.6 0 00-.848.85l.25.249h-.955a.6.6 0 000 1.2h.954l-.25.25a.6.6 0 00.85.848l.249-.25v.955a.6.6 0 001.2 0v-.954l.25.25a.6.6 0 00.848-.85l-.25-.249h.955a.6.6 0 000-1.2z"
                fill="#d5e2e9"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default Weather23
