'use strict';

define(["IRoomControlEnums", "IRoomControlContentTemperatureList", "DaytimerControlEnums", "OperatingModeEnums"], function (IRoomControlEnums, IRoomControlContentTemperatureList, DaytimerControlEnums, OperatingModeEnums) {
    return class IRoomControlContentSettingsList extends Controls.ControlContent {
        constructor(details) {
            super(details);
            this.currentVisuType = details.control.details.restrictedToMode;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments); // ensure the data is ready for the UI

            this._preparePeriods();

            this.tableView = new GUI.TableViewV2(this, this);
            this.tableView.getElement().addClass("settings-list__table-view");
            this.appendSubview(this.tableView);
            this.tableView.reload();
        }

        getAnimation() {
            return AnimationType.HD_OVERLAY;
        }

        getURL() {
            return "Settings";
        }

        titleBarText() {
            return _("settings");
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK,
                style: 'transparent'
            };
        }

        receivedStates(states) {
            var visuType;

            if (this.control.details.restrictedToMode === IRoomControlEnums.VisuType.HEATING && this.control.isHeating(states.controllerMode)) {
                visuType = IRoomControlEnums.VisuType.HEATING;
            } else if (this.control.details.restrictedToMode === IRoomControlEnums.VisuType.COOLING && this.control.isCooling(states.controllerMode)) {
                visuType = IRoomControlEnums.VisuType.COOLING;
            } else {
                visuType = IRoomControlEnums.VisuType.HEATING_AND_COOLING;
            }

            if (this.currentVisuType !== visuType) {
                this.currentVisuType = visuType;
                this.controllerMode = states.controllerMode; // also update controller mode, wouldn't be done after that

                this.tableView.reload();
            } else if (this.controllerMode !== states.controllerMode) {
                this.controllerMode = states.controllerMode;
                this.tableView.reloadRowsSelective(0, 0, 1);
            }

            if (this.serviceMode !== states.serviceMode) {
                this.serviceMode = states.serviceMode;
                this.tableView.reloadRowsSelective(0, 0, 1);
            }
        }

        // TableViewDataSource methods
        styleForTableView() {
            return TableViewStyle.GROUPED;
        }

        numberOfSections() {
            if (this.currentVisuType === IRoomControlEnums.VisuType.HEATING || this.currentVisuType === IRoomControlEnums.VisuType.COOLING) {
                return 3;
            }

            return 4;
        }

        titleForHeaderInSection(section) {
            if (section === 0) {
                return _("controls.irc.mode.current");
            } else if (section === 1 && this.currentVisuType !== IRoomControlEnums.VisuType.COOLING) {
                return _("controls.irc.heating");
            } else if (section === 1 && this.currentVisuType === IRoomControlEnums.VisuType.COOLING || section === 2 && this.currentVisuType === IRoomControlEnums.VisuType.HEATING_AND_COOLING) {
                return _("controls.irc.cooling");
            } else {
                return _("controls.irc.temperatures");
            }
        }

        numberOfRowsInSection(section) {
            if (this.currentVisuType === IRoomControlEnums.VisuType.HEATING || this.currentVisuType === IRoomControlEnums.VisuType.COOLING) {
                if (section === 1) {
                    return 2;
                } else {
                    return 1;
                }
            }

            if (section === 1 || section === 2) {
                return 2;
            } else {
                return 1;
            }
        }

        cellTypeForCellAtIndex() {
            return GUI.TableViewV2.CellType.GENERAL;
        }

        contentForCell(cell, section, row) {
            if (section === 0) {
                var disclosureText, disclosureColor;

                if (this.serviceMode) {
                    disclosureText = _("controls.irc.mode.service");
                    disclosureColor = window.Styles.colors.red;
                } else {
                    var mode = this.controllerMode;

                    if (mode === IRoomControlEnums.IRCMode.FULL_AUTO_HEATING && this.currentVisuType === IRoomControlEnums.VisuType.HEATING) {
                        mode = IRoomControlEnums.IRCMode.AUTO_HEATING;
                    } else if (mode === IRoomControlEnums.IRCMode.FULL_AUTO_COOLING && this.currentVisuType === IRoomControlEnums.VisuType.COOLING) {
                        mode = IRoomControlEnums.IRCMode.AUTO_COOLING;
                    }

                    disclosureText = this.control.getNameOfIRCMode(mode);
                }

                return {
                    leftIconSrc: 'resources/Images/Controls/IRoom/calendar.svg',
                    title: _("controls.irc.mode"),
                    disclosureIcon: true,
                    disclosureText: disclosureText,
                    disclosureColor: disclosureColor
                };
            } else if (section === 1 && this.currentVisuType !== IRoomControlEnums.VisuType.COOLING) {
                if (row === 0) {
                    // return the heating period cell (use the operating mode to differ between heating and cooling)
                    return this._getHeatCoolPeriodCell(OperatingModeEnums.OperatingMode.HEATING);
                } else {
                    return {
                        leftIconSrc: 'resources/Images/Controls/IRoom/clock.svg',
                        title: _("controls.irc.daytimer.heating.entries"),
                        disclosureIcon: true
                    };
                }
            } else if (section === 1 && this.currentVisuType === IRoomControlEnums.VisuType.COOLING || section === 2 && this.currentVisuType === IRoomControlEnums.VisuType.HEATING_AND_COOLING) {
                if (row === 0) {
                    // return the cooling period cell (use the operating mode to differ between heating and cooling)
                    return this._getHeatCoolPeriodCell(OperatingModeEnums.OperatingMode.COOLING);
                } else {
                    return {
                        leftIconSrc: 'resources/Images/Controls/IRoom/clock.svg',
                        title: _("controls.irc.daytimer.cooling.entries"),
                        disclosureIcon: true
                    };
                }
            } else {
                return {
                    leftIconSrc: 'resources/Images/Controls/IRoom/temperature.svg',
                    title: _("controls.irc.temperatures"),
                    disclosureIcon: true
                };
            }
        }

        // TableViewDelegate Methods
        didSelectCell(cell, section, row) {
            if (section === 0) {
                this.ViewController.showState(IRoomControlEnums.ScreenState.CONTROLLER_MODE_LIST, null, {
                    control: this.control
                });
            } else if (section === 1 && this.currentVisuType !== IRoomControlEnums.VisuType.COOLING) {
                if (row === 0) {// nothing to do. the heating period isn't clickable.
                } else {
                    var heatingCtrl = ActiveMSComponent.getStructureManager().getControlByUUID(this.control.getDaytimerUuidFromIRoomControl(true));
                    this.ViewController.showState(IRoomControlEnums.ScreenState.CALENDAR, null, {
                        control: heatingCtrl
                    });
                }
            } else if (section === 1 && this.currentVisuType === IRoomControlEnums.VisuType.COOLING || section === 2 && this.currentVisuType === IRoomControlEnums.VisuType.HEATING_AND_COOLING) {
                if (row === 0) {// nothing to do. the cooling period isn't clickable.
                } else {
                    var coolingCtrl = ActiveMSComponent.getStructureManager().getControlByUUID(this.control.getDaytimerUuidFromIRoomControl(false));
                    this.ViewController.showState(IRoomControlEnums.ScreenState.CALENDAR, null, {
                        control: coolingCtrl
                    });
                }
            } else {
                this.ViewController.showState(IRoomControlEnums.ScreenState.TEMPERATURE_LIST, null, {
                    control: this.control
                });
            }
        }

        // Private methods
        _getTimeStringFromDate(date) {
            return moment(date, 'MM-DD').format(LxDate.getDateFormat(DateType.DayAndMonthShort));
        }

        // Event listeners
        _handleTapEvent(event) {
            if (event.currentTarget === this.cellElements[0]) {
            } else if (event.currentTarget === this.cellElements[1]) {//this.ViewController.showState(IRoomControlEnums.ScreenState.PERIOD_VIEW, null, {control: this.control});
            } else if (event.currentTarget === this.cellElements[2] || event.currentTarget === this.cellElements[4]) {
                // cell elements on index 2 = heating so result is "true", otherwise "false" when it was 4 = cooling
                var uuid = this.control.getDaytimerUuidFromIRoomControl(event.currentTarget === this.cellElements[2]);
                var daytimerControl = ActiveMSComponent.getStructureManager().getControlByUUID(uuid);
                this.ViewController.showState(DaytimerControlEnums.ScreenState.CALENDAR, null, {
                    control: daytimerControl
                });
            } else if (event.currentTarget === this.cellElements[3]) {//this.ViewController.showState(IRoomControlEnums.ScreenState.PERIOD_VIEW, null, {control: this.control});
            } else if (event.currentTarget === this.cellElements[5]) {
            }
        }

        /**
         * Will ensure that the periods information is ready and in a proper format for using it in this screen.
         * @private
         */
        _preparePeriods() {
            this.periods = {};

            if (this._hasCustomPeriods()) {
                this.periods[OperatingModeEnums.OperatingMode.HEATING] = {
                    start: this.control.details.heatPeriodStart,
                    end: this.control.details.heatPeriodEnd
                };
                this.periods[OperatingModeEnums.OperatingMode.COOLING] = {
                    start: this.control.details.coolPeriodStart,
                    end: this.control.details.coolPeriodEnd
                };
            } else {
                this._loadHeatCoolPeriods();
            }
        }

        /**
         * Detects whether or not an IRC has custom heating/cooling periods or not.
         * The version check is not needed - as old miniservers will always return the heating or cooling period
         * using these attributes.
         * @returns {*}
         * @private
         */
        _hasCustomPeriods() {
            var details = this.control.details;
            return details.coolPeriodStart || details.heatPeriodStart;
        }

        /**
         * Will acquire the heating and cooling periods, as soon as they are here, it reloads the table.
         * @private
         */
        _loadHeatCoolPeriods() {
            ActiveMSComponent.getHeatCoolPeriodSpans().then(function (res) {
                this.periods = res;
                this.tableView.reload();
            }.bind(this));
        }

        /**
         *
         * @param opMode      uses the operating mode enum to differ between heating and cooling
         * @returns {*}
         * @private
         */
        _getHeatCoolPeriodCell(opMode) {
            var content = {},
                period = this.periods[opMode]; // undefined if not yet loaded.

            if (opMode === OperatingModeEnums.OperatingMode.COOLING) {
                content.title = _("controls.irc.cooling.period");
                content.leftIconSrc = Icon.COOL_PERIOD;
            } else if (opMode === OperatingModeEnums.OperatingMode.HEATING) {
                content.title = _("controls.irc.heating.period");
                content.leftIconSrc = Icon.HEAT_PERIOD;
            }

            if (period) {
                content.disclosureText = this._getTimeStringFromDate(period.start) + ' - ' + this._getTimeStringFromDate(period.end);
            } else {
                content.disclosureText = _("loading"); // still loading
            }

            return content;
        }

    };
});
