import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Timer(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path d="M11 2a1 1 0 011-1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12c0-2.65.939-5.084 2.5-6.983a1 1 0 011.545 1.27A8.959 8.959 0 003 12a9 9 0 1010-8.945V4.5a1 1 0 11-2 0V2z" />
            <Path d="M12 14a2 2 0 10-1.016-3.723L8.05 7.343a.5.5 0 00-.707.707l2.934 2.934A2 2 0 0012 14z" />
        </Svg>
    )
}

export default Timer
