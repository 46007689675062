import {
    LxReactComp,
    LxReactTableView,
    LxReactRenderErrorView
} from "LxComponents"
import PropTypes from "prop-types"

class LxReactTableViewLxViewAdapter extends LxReactComp {

    // region static variables
    static propTypes = {
        dataSource: PropTypes.object.isRequired,
        delegate: PropTypes.object.isRequired,
        itemSeparatorStyle: PropTypes.object,
        hideItemSeparator: PropTypes.bool,
        showSectionSeparator: PropTypes.bool,
        styles: PropTypes.object,
        insets: LxReactTableView.propTypes.insets
    }
    // endregion

    // region private variables
    #collector
    // endregion

    // region Deprecated props
    get table() {
        console.warn(this.constructor.name, '"table" has been deprecated and should not be used anymore!');
        return cloneObjectDeep(this.state.collection.sections).map(section => {
            return section.rows || [];
        });
    }
    // endregion

    constructor() {
        super(...arguments);
        this.#collector = new GUI.TableViewContentCollector(this, this.props.dataSource, this.props.delegate, false);
        let collection = this.#collector.collectContent();
        this.state = {
            performanceStyle: this.props.performanceStyle || LxReactTableView.PerformanceStyle.Normal,
            collection: collection,
            editMap: collection.sections.map(section => {
                return {
                    sortable: false,
                    removable: false
                }
            })
        };
    }

    legacyCellMapperReloadFn(sectionIdx, rowIdx, newContent) {
        if (this.state.collection.sections[sectionIdx] &&
            this.state.collection.sections[sectionIdx].rows &&
            this.state.collection.sections[sectionIdx].rows[rowIdx]
        ) {
            this.state.collection.sections[sectionIdx].rows[rowIdx].content = newContent;
            this.setState({
                collection: this.state.collection
            });
        }
    }

    onCellMove(sectionIdx, from, to) {
        this.state.collection.sections[sectionIdx].rows.move(from, to);
        this.setState({
            collection: this.state.collection
        });
        if (this.state.collection.sections[sectionIdx].didMoveCell) {
            this.state.collection.sections[sectionIdx].didMoveCell(
                sectionIdx,
                from,
                this,
                this.cellForSectionAndRow(sectionIdx, to),
                from,
                to
            );
        }
    }

    onCellRemove(sectionIdx, rowIdx) {
        let prms;
        if (this.state.collection.sections[sectionIdx].didRequestRemovingCell) {
            prms = Q(this.state.collection.sections[sectionIdx].didRequestRemovingCell(
                sectionIdx,
                rowIdx,
                this,
                this.cellForSectionAndRow(sectionIdx, rowIdx)
            ))
        } else {
            prms = Q.resolve(true);
        }
        return prms.then((shouldDelete) => {
            if (shouldDelete) {
                this.state.collection.sections[sectionIdx].rows.splice(rowIdx, 1);
                this.setState({
                    collection: this.state.collection
                });
                this.state.collection.sections[sectionIdx].didRemoveCell(sectionIdx, rowIdx, this);
            }
        });
    }

    render() {
        return (
            <LxReactTableView
                tableContent={this.state.collection.sections}
                emptyComp={this.state.emptyComp}
                editMap={this.state.editMap}
                onCellMove={this.onCellMove.bind(this)}
                onCellRemove={this.onCellRemove.bind(this)}
                tableViewStyle={this.state.collection.styleForTableView}
                performanceStyle={this.state.performanceStyle}
                legacyCellMapperReloadFn={this.legacyCellMapperReloadFn.bind(this)}
                itemSeparatorStyle={this.props.itemSeparatorStyle}
                hideItemSeparator={this.props.hideItemSeparator}
                showSectionSeparator={this.props.showSectionSeparator}
                insets={this.props.insets}
                styles={this.props.styles}
            />
        )
    }

    // region LxTableView methods
    setBackgroundView(bgView) {
        console.warn(this.constructor.name, '"setBackgroundView" has been deprecated in favour of "setEmptyComponent"');
        if (ReactWrapper.React.isValidElement(bgView)) {
            this.setEmptyComponent(bgView);
        }
    }

    setEmptyComponent(comp) {
        this.setState({
            emptyComp: comp
        });
    }

    reload() {
        this.setState({
            collection: this.#collector.collectContent()
        });
        return Q.resolve();
    }

    reloadRowsSelective() {
        console.warn(this.constructor.name, '"reloadRowsSelective" has been deprecated, use "reload" instead!');
        return this.reload();
    }

    removeRow(sectionIdx, startIndex, animate) {
        debugger;
    }

    cellForSectionAndRow(section, row) {
        let warning = '"cellForSectionAndRow" has been deprecated, update the tableContent instead!',
            cell = this.state.collection.sections[section].rows[row] || {};
        console.warn(this.constructor.name, warning);
        return {
            ["⚠️"]: warning,
            ...cell
        }
    }
    // endregion

    // region LxEditableTableView methods

    /**
     * Allows to set multiple sections editable
     * @param sectionMaps
     */
    setEditMode(...sectionMaps) {
        if ([
            LxReactTableView.PerformanceStyle.Editable,
            LxReactTableView.PerformanceStyle.EditableCrossSectional
        ].includes(this.state.performanceStyle)) {
            let editMap = this.state.editMap;
            sectionMaps.forEach(({
                sectionIdx = 0,
                sortable = false,
                removable = false,
                startIdx = 0,
                endIdx = (this.state.collection.sections[sectionIdx].rows || []).length
            }) => {
                editMap[sectionIdx] = {
                    sortable: sortable,
                    removable: removable,
                    startIdx: startIdx || 0,
                    endIdx: endIdx
                }
            });

            this.setState({
                editMap: editMap
            })
        } else {
            console.warn(this.constructor.name, "is not editable right now!");
        }
    }

    setEditingModeForSection(
        sectionIdx = 0,
        editing,
        removeEnabled,
        sortEnabled,
        startIdx,
        numberOfCells
    ) {
        console.warn(this.constructor.name, '"setEditingModeForSection" has been deprecated, use "setEditMode" instead!');
        this.setEditMode({
            sectionIdx: sectionIdx,
            sortable: sortEnabled,
            removable: removeEnabled,
            startIdx: startIdx,
            endIdx: numberOfCells
        });
        return Q.resolve();
    }
    // endregion
}

export default LxReactTableViewLxViewAdapter;
