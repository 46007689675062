'use strict';

define(["AudioZoneControlEnums", "MediaBrowserService"], function (AudioZoneControlEnums, MediaBrowserService) {
    class MediaBrowserService7 extends MediaBrowserService {
        //region Static
        static isRadio() {
            return Controls.AudioZoneControl.MediaBrowserService.isRadio.apply(this, arguments);
        }

        static getCellFromContentTypeItem() {
            return Controls.AudioZoneControl.MediaBrowserService.getCellFromContentTypeItem.apply(this, arguments);
        }

        static getCellButtonIconSrc() {
            return Controls.AudioZoneControl.MediaBrowserService.getCellButtonIconSrc.apply(this, arguments);
        }

        static getCellTypeForItemCellAtIndex() {
            return Controls.AudioZoneControl.MediaBrowserService.getCellTypeForItemCellAtIndex.apply(this, arguments);
        }

        static getIconObjForItem() {
            return Controls.AudioZoneControl.MediaBrowserService.getIconObjForItem.apply(this, arguments);
        } //endregion Static


        constructor(details) {
            super(...arguments);
        }

        getActionsForCtxAndItem(ctx, item) {
            var actions = super.getActionsForCtxAndItem(...arguments);

            switch (ctx) {
                case this.CONTEXT.SPOTIFY:
                    if (item === this) {
                        if (MediaServerComp.Feature.SPOTIFY_FOLLOWABLE) {
                            actions.pushObject(this._getActionWithTmplForCtx({
                                content: {
                                    title: _("media.playlist.follow"),
                                    leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_FOLLOW
                                },
                                action: this._followPlaylist.bind(this, item.lastSelectedItem)
                            }, ctx));
                        }
                    }

                    break;
            }

            return actions;
        }

        _followPlaylist(playlist) {
            return MediaServerComp.sendMediaServerCommand(MediaEnum.Commands.PLAYLIST.FOLLOW + "/" + this.getMediaInfo(playlist.contentType).service.uid + "/" + playlist.id);
        }

    }

    // FileType = 7 = PLAYLIST_BROWSABLE
    Controls.AudioZoneControl.MediaBrowserService7 = MediaBrowserService7;
    return Controls.AudioZoneControl.MediaBrowserService7;
});
