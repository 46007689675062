'use strict';

define(["TaskDetailsScreen", "TaskRecordingCommandsScreen"], function (TaskDetailsScreen, TaskRecordingCommandsScreen) {
    return class TaskRecorderScreen extends GUI.Screen {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return $('<div class="task-recorder-screen">' + '<div class="task-recorder-screen__new-record-view">' + '<div class="new-record-view__touch-area">' + ImageBox.getResourceImageWithClasses("resources/Images/ActiveMiniserver/TaskRecorder/record.svg", "touch-area__icon clickable") + '</div>' + '</div>' + '<div class="task-recorder-screen__bottom-placeholder">' + getNoTaskView() + '</div>' + '</div>');
            };

            var getNoTaskView = function getNoTaskView() {
                return '<div class="bottom-placeholder__no-tasks-view">' + '<div class="no-tasks-view__title">' + _('taskrecorder.no-tasks.title') + '</div>' + '<div class="no-tasks-view__message">' + _('taskrecorder.no-tasks.message', {
                    icon: ImageBox.getResourceImageWithClasses("resources/Images/ActiveMiniserver/TaskRecorder/record.svg", "message__icon")
                }) + '</div>' + '</div>';
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor() {
            super(TaskRecorderScreen.Template.getTemplate());
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.titleBar = this._getTitlebar();
            this.prependSubview(this.titleBar);
            this.elements = {
                bottomPlaceholder: this.element.find(".task-recorder-screen__bottom-placeholder"),
                newTaskButton: this.element.find(".new-record-view__touch-area"),
                noTasksView: this.element.find(".bottom-placeholder__no-tasks-view")
            };
            this.newTaskButton = new GUI.LxButton(this, this.elements.newTaskButton[0], window.Styles.colors.red);
            this.addToHandledSubviews(this.newTaskButton);
            this.tableView = new GUI.EditableTableView(this, this, null, 0, false, true, false);
            this.tableView.getElement().addClass("bottom-placeholder__table-view");
            this.appendSubview(this.tableView, this.elements.bottomPlaceholder);
            this.hideSubview(this.tableView);
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);

            this.titleBar.onLeftButtonTapped = function () {
                this.ViewController.navigateBack();
                this.titleBar.onLeftButtonTapped = null;
            }.bind(this);

            this.newTaskButton.onButtonTapped = this._startRecordingTask.bind(this);
            this.listenerID = SandboxComponent.registerForTasks(this._tasksReceived.bind(this));
        }

        viewWillDisappear() {
            SandboxComponent.unregisterForTasks(this.listenerID);
            super.viewWillDisappear(...arguments);
        }

        viewDidDisappear() {
            this.newTaskButton.onButtonTapped = null;
            this.titleBar.onLeftButtonTapped = null;
            super.viewDidDisappear(...arguments);
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        // Private methods
        _getTitlebar() {
            var titleBarConfig = {
                leftSide: TitleBarCfg.Button.CLOSE,
                style: 'transparent'
            };
            var titleBar = new GUI.LxTitleBar(titleBarConfig);
            titleBar.setTitleBarSideTexts(_('taskrecorder'));
            return titleBar;
        }

        _startRecordingTask() {
            NavigationComp.showState(ScreenState.ActiveMiniserverScreen);
            NavigationComp.startActivity(GUI.ActiveMiniserverViewController.Activity.TaskRecorder);
        }

        _tasksReceived(upcomingTasks, pastTasks) {
            this.upcomingTasks = upcomingTasks;
            this.pastTasks = pastTasks;
            var tasksAvailable = this.upcomingTasks.length > 0 || this.pastTasks.length > 0;
            this.elements.noTasksView.toggle(!tasksAvailable);

            if (tasksAvailable) {
                this.showSubview(this.tableView);
            } else {
                this.hideSubview(this.tableView);
            }

            this.tableView.reload();
        }

        // TableViewDataSource Methods
        styleForTableView() {
            return HD_APP ? TableViewStyle.GROUPED : null;
        }

        numberOfSections(tableView) {
            return this.upcomingTasks.length > 0 && this.pastTasks.length > 0 ? 2 : 1;
        }

        titleForHeaderInSection(section, tableView) {
            if (section === 0) {
                if (this.upcomingTasks.length > 0) {
                    return _('taskrecorder.upcoming-tasks');
                } else if (this.pastTasks.length > 0) {
                    return _('taskrecorder.past-tasks');
                }
            } else if (section === 1 && this.pastTasks.length > 0) {
                return _('taskrecorder.past-tasks');
            }
        }

        rightButtonTitleForHeaderInSection(section, tableView) {
            return _('edit');
        }

        numberOfRowsInSection(section, tableView) {
            if (section === 0) {
                if (this.upcomingTasks.length > 0) {
                    return this.upcomingTasks.length;
                } else if (this.pastTasks.length > 0) {
                    return this.pastTasks.length;
                }
            } else if (section === 1) {
                return this.pastTasks.length;
            }
        }

        cellTypeForCellAtIndex(section, row, tableView) {
            return GUI.TableViewV2.CellType.DURATION_SUBTITLE;
        }

        contentForCell(cell, section, row) {
            var task = this._getTask(section, row);

            return {
                title: task.name,
                duration: task.startDate.format('L LT'),
                disclosureIcon: true,
                forceSelection: true
            };
        }

        // TableViewDelegate Methods
        didSelectCell(cell, section, row) {
            var task = this._getTask(section, row);

            NavigationComp.showState(ScreenState.TaskDetails, {
                task: task,
                editing: true
            });
        }

        didRequestRemovingCell(section, row) {
            var task = this._getTask(section, row);

            var content = {
                title: _("taskrecorder.delete-task"),
                message: _("taskrecorder.delete-task-message", {
                    taskName: task.name
                }),
                buttonOk: _("delete"),
                buttonCancel: true,
                icon: Icon.ERROR,
                color: window.Styles.colors.red
            };
            return NavigationComp.showPopup(content);
        }

        didRemoveCell(section, row) {
            var task = this._getTask(section, row);

            SandboxComponent.deleteTask(task);
        }

        _getTask(section, row) {
            if (section === 0) {
                if (this.upcomingTasks.length > 0) {
                    return this.upcomingTasks[row];
                } else if (this.pastTasks.length > 0) {
                    return this.pastTasks[row];
                }
            } else if (section === 1) {
                return this.pastTasks[row];
            }
        }

    };
});
