'use strict';

window.GUI = function (GUI) {
    class HorizontalSelector extends GUI.View {
        constructor(dataSource, delegate, lowerCase) {
            super($('<div class="scroll-view-wrapper">' + '   <div class="horizontal-selector__scroll-container scroll-view-content"></div>' + '</div>'));
            this.dataSource = dataSource;
            this.delegate = delegate;

            if (!lowerCase) {
                this.element.addClass('horizontal-selector--capitalized');
            }

            this.table = [];
            this.eventHandlers = [];
            this.buttons = {};
            this.selectedIndex = 0;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.elements = {
                scrollContainer: this.element.find('.horizontal-selector__scroll-container')
            };
            this.scrollView = new IScroll(this.element[0], {
                probeType: 3,
                tap: true,
                click: true,
                mouseWheel: true,
                scrollX: true,
                scrollY: false
            });
            this.registerForResize();
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);

            if (this.onScroll) {
                this._boundOnScroll = function () {
                    this.onScroll && this.onScroll(this.scrollView.x, this.scrollView.y);
                }.bind(this);

                this.scrollView.on("scroll", this._boundOnScroll);
            }

            this._boundRepsondToTap = function (event) {
                if (this._supportsSelection()) {
                    this._respondToTap(event);
                }
            }.bind(this);

            this.element.on(tapEvent(), this._boundRepsondToTap);
            this.reload();
        }

        viewDidDisappear() {
            if (this.onScroll) {
                this.scrollView.off("scroll", this._boundOnScroll);
                this._boundOnScroll = null;
            }

            this.element.off(tapEvent(), this._boundRepsondToTap);
            super.viewDidDisappear(...arguments);
        }

        onResize() {
            this.reload();
        }

        destroy() {
            this._destroyContent();

            super.destroy();
        }

        // -----------------------
        // Public methods
        // -----------------------
        setTranslucent() {
            this.element.addClass("horizontal-selector--translucent");
        }

        reload() {
            this._destroyContent();

            if (!this.element) {
                console.error("HorizontalSelector has no element!");
                return;
            }

            var selectable = this._supportsSelection();

            var list = this.elements.scrollContainer.empty();
            var optionStrings = this.dataSource.getSelectorStrings();
            var option, i;
            var totalWidth = 50; // 50 is the initial offset on the left

            for (i = 0; i < optionStrings.length; i++) {
                option = $('<div class="scroll-container__entry"><div class="entry__text">' + optionStrings[i] + "</div></div>");
                option.addClass('scroll-container__entry-' + i);
                option.attr('id', i);
                this.table[i] = option;

                if (selectable) {
                    if (i === this.selectedIndex) {
                        option.addClass('selected');
                    }

                    option.addClass('clickable');
                    /* Hammer.js won't work with iscroll-probe!
                    var btn = new GUI.LxButton(this, option[0], null);
                    btn.useChildsAsActiveParts("color");
                    btn.onButtonTapped = this._handleButtonTap.bind(this);
                    this.buttons["" + i] = btn;
                    */
                }

                list.append(option);
                totalWidth += option[0].offsetWidth + 20; // 20 is the offset
            }

            this.elements.scrollContainer[0].style.width = totalWidth + "px";
            this.scrollView.refresh();
        }

        setSelectedIndex(index) {
            this._updateSelectedIdx(index);
        }

        scrollTo(x, y) {
            this.scrollView.scrollTo(x, this.scrollView.y);
        }

        // -----------------------
        // Private methods
        // -----------------------
        _supportsSelection() {
            var result = true;

            if (this.delegate.isSelectionAllowed) {
                result = this.delegate.isSelectionAllowed(this);
            }

            Debug.GUI.LxHorizontalSelector && console.log(this.name, "_supportsSelection: " + result);
            return result;
        }

        _destroyContent() {
            while (this.eventHandlers.length) {
                this.eventHandlers.pop().dispose();
            }

            var btnKeys = Object.keys(this.buttons);

            for (var i = 0; i < btnKeys.length; i++) {
                this.buttons[btnKeys[i]].destroy();
            }

            this.buttons = {};
        }

        _handleButtonTap(src) {
            if (this._updateSelectedIdx(parseInt(src.element.id))) {
                this.delegate.didSelectEntry(this.selectedIndex);
            }
        }

        _updateSelectedIdx(index) {
            if (index === this.selectedIndex) {
                return false;
            }

            var previousSelection = this.selectedIndex;
            this.selectedIndex = index;
            Debug.GUI.LxHorizontalSelector && console.log(this.name, "_handleButtonTap " + this.selectedIndex);
            this.table[previousSelection].removeClass('selected');
            this.table[this.selectedIndex].addClass('selected');
            return true;
        }

        _respondToTap(event) {
            // handle both, tap on text and on parent element
            if (event.target.className.indexOf("scroll-container__entry") !== -1) {
                this._handleButtonTap({
                    element: event.target
                });
            } else if (event.target.className === "entry__text") {
                this._handleButtonTap({
                    element: event.target.parentElement
                });
            }
        }

    }

    GUI.HorizontalSelector = HorizontalSelector;
    return GUI;
}(window.GUI || {});
