'use strict';

define([], function () {
    return class NfcInstructionView extends GUI.View {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return $('<div class="nfc-instruction-view">' + '   <div class="nfc-instruction-view__icon-placeholder" />' + '   <div class="nfc-instruction-view__title" />' + '   <div class="nfc-instruction-view__message" />' + '       <div class="nfc-instruction-view__submessage" />' + '   <div class="nfc-instruction-view__button" />' + '</div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor() {
            super(NfcInstructionView.Template.getTemplate());
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.elements = {
                iconPlaceholder: this.element.find(".nfc-instruction-view__icon-placeholder"),
                titleLbl: this.element.find(".nfc-instruction-view__title"),
                messageLbl: this.element.find(".nfc-instruction-view__message"),
                subMessage: this.element.find(".nfc-instruction-view__submessage"),
                button: this.element.find(".nfc-instruction-view__button").hide()
            };
            this.elements.button.on("click", function () {
                if (this.retryAllowed) {
                    this._iconSrc && this.setIcon(this._iconSrc);
                    this._title && this.setTitle(this._title);
                    this._msg && this.setMessage(this._msg);
                    this._submsg && this.setSubMessage(this._submsg);
                    this.startTouchAnimation();
                    this.retryAllowed = false;
                } else {
                    this.emit("button-click");
                }
            }.bind(this));
        }

        setIcon(iconSrc, iconClass) {
            if (!this._iconSrc) {
                this._iconSrc = iconSrc; // save to restore on retry
            }

            this.elements.iconPlaceholder.empty();
            this.elements.iconPlaceholder.append(ImageBox.getResourceImageWithClasses(iconSrc, "icon-placeholder__icon " + (iconClass || "")));
        }

        setTitle(title) {
            if (!this._title) {
                this._title = title; // save to restore on retry
            }

            this.elements.titleLbl.text(title);
        }

        setMessage(msg) {
            if (!this._msg) {
                this._msg = msg; // save to restore on retry
            }

            this.elements.messageLbl.text(msg);
        }

        setSubMessage(msg) {
            if (!this._submsg) {
                this._submsg = msg; // save to restore on retry
            }

            this.elements.subMessage.text(msg);
        }

        setSubMessageColor(color) {
            this.elements.subMessage.css('color', color);
        }

        startTouchAnimation() {
            this.element.removeClass("success-animation");
            this.element.removeClass("fail-animation");
            this.element.addClass("touch-animation");
            this.elements.button.hide();
        }

        startSuccessAnimation(hideFinish) {
            this.element.removeClass("touch-animation");
            this.element.removeClass("fail-animation");
            this.element.addClass("success-animation");
            this.elements.button.toggle(!hideFinish);
            this.elements.button.text(_('finish'));
            this.retryAllowed = false;
        }

        startFailAnimation(retryAllowed, showClose) {
            this.element.removeClass("touch-animation");
            this.element.removeClass("success-animation");
            this.element.addClass("fail-animation");
            this.retryAllowed = retryAllowed;

            if (this.retryAllowed) {
                this.elements.button.text(_('miniserver.conn-failed.retry'));
                this.elements.button.show();
            } else if (showClose) {
                this.elements.button.text(_('close'));
                this.elements.button.show();
            }
        }

    };
});
