'use strict';

class AutopilotBar extends GUI.InstructionBar {
    constructor(rule) {
        super();
        this.element.addClass("autopilot-bar");
        this.commands = [];
        this.rule = rule;
        SandboxComponent.startTaskRecording();
    }

    viewDidLoad() {
        super.viewDidLoad(...arguments); // add arrow

        this.elements.arrow = $(ImageBox.getResourceImageWithClasses("resources/Images/General/disclosure-icon.svg", "task-recording-bar__arrow")).hide();
        this.elements.arrow.insertAfter(this.elements.textLbl);
        this.elements.stopBtn = this.addButton("resources/Images/ActiveMiniserver/TaskRecorder/recording-stop.svg", "stop-btn");

        this._updateCommands();
    }

    viewDidAppear() {
        super.viewDidAppear(...arguments);
        SandboxComponent.registerForNewTaskCommands(this._updateCommands.bind(this));
        this.elements.stopBtn.on("click", this._finishRecording.bind(this));
    }

    viewDidDisappear(viewRemainsVisible) {
        SandboxComponent.unregisterForNewTaskCommands();
        this.elements.stopBtn.off("click");
        super.viewDidDisappear(viewRemainsVisible);
    }

    destroy() {
        SandboxComponent.stopTaskRecording();
        super.destroy();
    }

    onBarClicked() {
    }

    // Private methods
    _updateCommands(commands) {
        if (commands) {
            this.commands = commands;
            this.setText(_('taskrecorder.commands-recorded', {
                count: this.commands.length
            }));
            this.elements.arrow.toggle(this.commands.length > 0);
        } else {
            this.setText(_("taskrecorder.choose-commands"));
        }
    }

    _finishRecording() {
        NavigationComp.stopActivity(GUI.ActiveMiniserverViewController.Activity.Autopilot);
        let AutopilotEnums = require("AutopilotEnums");
        var commandsClean = {};
        this.commands.forEach(function (command) {
            if (!commandsClean.hasOwnProperty(command.cmd)) {
                commandsClean[command.cmd] = command;
            }
        }.bind(this));
        this.commands = [];

        for (var prop in commandsClean) {
            this.commands.push(commandsClean[prop]);
        }

        for (var i = 0; i < this.commands.length; i++) {
            var cmd = this.commands[i].cmd;
            var idxFirstSlash = cmd.indexOf("/");
            var id = cmd.substring(0, idxFirstSlash);
            var command = cmd.substring(idxFirstSlash + 1, cmd.length);
            this.rule.actions.push({
                id: id,
                command: command,
                type: AutopilotEnums.ActionTypes.Command
            });
        }

        var details = {
            rule: this.rule
        };
        NavigationComp.disableAnimationsTemp();
        NavigationComp.showState(AutopilotEnums.ScreenStates.AutopilotScreen, details, AnimationType.MODAL);
    }

}

GUI.AutopilotBar = AutopilotBar;
