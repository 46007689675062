import * as React from "react"
import Svg, { Path } from "react-native-svg"

function PaintUtils(props) {
    return (
        <Svg isreact="true"
            height={24}
            viewBox="0 0 24 24"
            width={24}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M13.5 0a.5.5 0 01.5.5V10h.205l2.261-4.061c-.55-.548-.736-1.337-.456-2.138l.057-.15c.069-.162.165-.32.297-.502l.05-.068.396-.508c.51-.688.573-1.093.153-1.735a.5.5 0 01.536-.76c1.965.475 3.046 2.187 2.908 4.031-.102 1.353-.849 2.352-1.906 2.514L17.223 10h.277a.5.5 0 01.492.41l.008.09V12h.5a3.5 3.5 0 01.192 6.995L18.5 19H18v2.5a2.501 2.501 0 01-2.336 2.495L15.5 24h-9A2.5 2.5 0 014 21.5v-11a.5.5 0 01.5-.5h.19L3.558 7.731a.508.508 0 01-.049-.14l-.001-.009-.5-3a.5.5 0 01.75-.51l2.5 1.5a.5.5 0 01.18.185l-.042-.063A.51.51 0 016.4 5.7l.009.015.028.042L8.794 10H9V.5a.5.5 0 01.41-.492L9.5 0zM17 11H5v10.5c0 .78.595 1.42 1.356 1.493L6.5 23h9a1.5 1.5 0 001.5-1.5zm1.5 2H18v5h.5a2.5 2.5 0 00.164-4.995zm-1.186-6.528L15.35 10h.804l1.313-3.472a2.845 2.845 0 01-.085-.029zM13 1h-3v9h3V9h-.5a.5.5 0 01-.09-.992L12.5 8h.5V7h-1.5a.5.5 0 01-.09-.992L11.5 6H13V5h-.5a.5.5 0 01-.09-.992L12.5 4h.5V3h-1.5a.5.5 0 01-.09-.992L11.5 2H13zM5.84 6.744l-1.206.905L5.809 10H7.65zM4.172 5.485l.187 1.121.748-.561zm14.076-4.002l.004.054c.02.588-.257 1.149-.762 1.794-.418.533-.437.56-.5.71l-.047.125c-.156.486-.01.923.395 1.202.006 0 .011.002.016.005l.072.05c.056.034.117.064.182.091.975.411 1.71-.235 1.802-1.48.077-1.02-.334-1.97-1.144-2.538z"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default PaintUtils
