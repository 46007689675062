'use strict';

define([], function () {
    /**
     * This view displays the tracker entries in a TableView
     */
    return class TrackerView extends GUI.View {
        constructor(control, isNewStyle) {
            super($('<div />'));
            this.control = control;
            this.isNewStyle = isNewStyle;
            this.tableContent = [];
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.tableViewDataSource = tableViewDataSource(this.tableContent, null, this);
            this.tableViewDelegate = tableViewDelegate(this.tableContent, this);
            this.tableView = new GUI.TableViewV2(this.tableViewDataSource, this.tableViewDelegate);
            this.appendSubview(this.tableView);
        }

        /**
         * Updates the tracker view
         * @param states
         */
        update(states) {
            if (states.trackerEntries.length) {
                this._prepareTableContent(states.trackerEntries);

                this.tableViewDataSource.update(this.tableContent);
                this.tableViewDelegate.update(this.tableContent);
                this.tableView.reload();
            }
        }

        // TableViewDelegate Methods
        styleForTableView() {
            // isNewStyle === HouseMode
            if (this.isNewStyle) {
                return TableViewStyle.TRANSLUCENT;
            } else {
                return TableViewStyle.GROUPED;
            }
        }

        // Private
        _prepareTableContent(trackerEntries) {
            this.tableContent = [];

            var entries = this._filterEntries(trackerEntries);

            entries.forEach(function (entry) {
                var day = {
                    headerTitle: entry.formatedDate,
                    rows: []
                };
                day.rows.push({
                    type: GUI.TableViewV2.CellType.Special.TRACKER,
                    content: entry
                });
                this.tableContent.push(day);
            }.bind(this));
        }

        _filterEntries(entries) {
            if (!this.control.details.hasOwnProperty("startTime")) {
                return entries;
            }

            var startTime = this.control.details.startTime; // just return entries since the given startTime (details)

            var result = [];

            for (var i = 0; i < entries.length; i++) {
                var entriesPerDay = entries[i];
                var alarmEntries = [];

                for (var j = 0; j < entriesPerDay.entries.length; j++) {
                    var entry = entriesPerDay.entries[j];

                    if (entry.timestamp.unix() < startTime.unix()) {
                        continue;
                    }

                    alarmEntries.push(entry);
                }

                if (alarmEntries.length > 0) {
                    var dayCopy = JSON.parse(JSON.stringify(entriesPerDay));
                    dayCopy.entries = alarmEntries;
                    result.push(dayCopy);
                }
            }

            return result;
        }

    };
});
