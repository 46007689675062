import {LxReactHeader, navigatorConfig} from "LxComponents";
import globalStyles from "GlobalStyles";
import {View} from "react-native";
import {Icons} from "IconLib";
import LxReactPressable from "../LxReactPressable";
import {LxReactText, useBackNavigation} from "../Components";
import {useCallback, useRef} from "react";
import ConnectToMsScreen from "./ConnectToMsScreen";
import {useNavigation} from "@react-navigation/native";
import PairingConnectScreen from "./PairingConnectScreen";
import ScanQrSetupScreen from "./ScanQrSetupScreen";
import PairedAppEnums from "../../PairedAppComp/pairedAppEnums";


export default function SelectPairingMethodScreen({route}) {

    const navigation = useNavigation();
    navigation.setOptions({
        ...navigatorConfig({
            title: ""
        })
    })

    useBackNavigation(() => {
        navigation.goBack();
    }, [])


    const inputFileRef = useRef(null);
    const onUploadTapped = useCallback((ev) => {
        inputFileRef.current?.click(ev);
    }, [inputFileRef.current]);

    const handleInvalidFile = () => {
        NavigationComp.showPopup({
            title: _("managed-tablet.invalid-file-title"),
            message: _("managed-tablet.invalid-file-message"),
            buttonOk: _('okay'),
            icon: Icon.CAUTION,
            color: globalStyles.colors.red,
            type: PopupType.GENERAL
        });
    }
    const onFileChange = useCallback((event) => {
        try {
            let file = event.target.files[0];
            let reader = new FileReader();
            reader.readAsText(file);
            reader.onload = function () {
                let isValid = false;
                try {
                    const parsed = JSON.parse(reader.result);
                    if (PairedAppComponent.validatePairingFile(parsed, PairedAppEnums.SetupSource.FILE)) {
                        isValid = true;
                        PairedAppComponent.startPairingWithFile(parsed, PairedAppEnums.SetupSource.FILE);
                        navigation.navigate(PairingConnectScreen.name);
                    }
                } catch (ex) {
                    console.error(SelectPairingMethodScreen.name, "fileSelectedHandler - process result failed = " + ex?.message);
                }
                !isValid && handleInvalidFile();
            };
            reader.onerror = function () {
                console.error(SelectPairingMethodScreen.name, "fileSelectedHandler - failed = " + reader?.error);
                handleInvalidFile();
            };
        } catch (ex) {
            handleInvalidFile();
        }

    }, []);
    const Option = ({title, OpIcon, action, type}) => {
        const isUpload = type === "upload";

        return (<LxReactPressable key={title} onPress={action} pressableStyle={styles.optionCntr}>
            <OpIcon style={styles.optionIcon} />
            <LxReactText style={styles.optionText}>{title}</LxReactText>
            {
                isUpload ?
                    <input
                        type={"file"}
                        ref={inputFileRef}
                        onChange={onFileChange}
                        accept={"application/json"}
                        style={styles.invisibleInput} />
                    : null
            }
        </LxReactPressable>)
    }

    const checkPermission = () => {
        if (!PlatformComponent.isAndroid()) {
            return Q.resolve();
        }
        var def = Q.defer();
        cordova.plugins.permissions.checkPermission(cordova.plugins.permissions.CAMERA, (result) => {
            result.hasPermission ? def.resolve(result) : def.reject(result);
        }, (permissionError) => {
            def.reject(permissionError);
        });
        return def.promise;
    }

    const requestPermission = () => {
        if (!PlatformComponent.isAndroid()) {
            return Q.resolve();
        }
        var def = Q.defer();
        cordova.plugins.permissions.requestPermission(cordova.plugins.permissions.CAMERA, (result) => {
            result.hasPermission ? def.resolve(result) : def.reject(result);
        }, (permissionError) => {
            def.reject(permissionError);
        });

        return def.promise;
    }

    const ensureCameraPermission = () => {
        Debug.PairedApp && console.log(SelectPairingMethodScreen.name, "ensureCameraPermission");
        return checkPermission().then(null, err => {
            Debug.PairedApp && console.log(SelectPairingMethodScreen.name, "ensureCameraPermission > not provided yet, request!");
            return requestPermission();
        });
    }

    const getOptions = () => {
        const options = [];

        options.push({
            title: _("managed-tablet.scan-setup-code"),
            type: "scan",
            OpIcon: Icons.QrScan,
            action: () => {
                Debug.PairedApp && console.log(SelectPairingMethodScreen.name, "QR Option selected");
                ensureCameraPermission().then(() => {
                    Debug.PairedApp && console.log(SelectPairingMethodScreen.name, "Permissions exist, navigate to screen!");
                    navigation.navigate(ScanQrSetupScreen.name);
                }, (err) => {
                    console.error(SelectPairingMethodScreen.name, "failed to acquire permission for Camera: " + (err.message || JSON.stringify(err)), err);
                    NavigationComp.showErrorPopup(
                        false,
                        _("qr-scan.cam-permission-missing.title"),
                        _("qr-scan.cam-permission-missing.message")
                    );
                });
            }
        })
        options.push({
            title: _("managed-tablet.import-setup-file"),
            type: "upload",
            OpIcon: Icons.File,
            action: onUploadTapped
        })
        options.push({
            title: _("managed-tablet.pair-manually"),
            OpIcon: Icons.ArrowLeftRight,
            action: () => {navigation.navigate(ConnectToMsScreen.name)}
        })

        return options;
    }

    return <View style={styles.rootCntr}>
        <LxReactHeader
            title={_("managed-tablet.set-up-managed-tab")}
            subtitle={_("managed-tablet.set-up-managed-tab-descr")}
            icon={Icons.Tablet}
            iconStyle={styles.hdrIcon}
            />
        <form>{
            getOptions().map(op => <Option {...op} />)
        }</form>
    </View>
}



const styles = {
    text: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.secondary
    },
    rootCntr: {
      maxWidth: globalStyles.sizes.contentMaxWidth,
        marginHorizontal: "auto"
    },
    hdrIcon: {
        width: 48,
        height: 48,
        fill: globalStyles.colors.stateActive
    },
    optionCntr: {
        flexDirection: "row",
        minHeight: 42,
        marginVertical: globalStyles.spacings.gaps.small
    },
    optionIcon: {
        fill: globalStyles.colors.stateActive,
        width: 32,
        height: 32,
        marginHorizontal: globalStyles.spacings.gaps.small,
        justifySelf: "center",
        alignSelf: "center"
    },
    optionText: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.primary,
        marginVertical: "auto"
    },
    invisibleInput: {
        display: "none",
        opacitiy: 0,
        zIndex: -1,
        position: "absolute"
    }
}
