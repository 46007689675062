import {useState, useEffect, useCallback} from "react";
import {useCCEvent} from "../../Components";

export default function useAmbientShortcuts(uuidsOnly = false) {
    const [controls, setControls] = useState([]);

    useCCEvent([CCEvent.AmbientModeSettingChanged], useCallback((event, args) => {
        let newSettings = PersistenceComponent.getAmbientModeSettings();
        updateControls(newSettings)
    }, [controls]));

    const updateControls = (settings) => {
        setControls(settings ? settings.quickAccessControls.map((ctrlUuid) => {
            return uuidsOnly ? ctrlUuid : ActiveMSComponent.getStructureManager().getControlByUUID(ctrlUuid);
        }).filter(control => control) : [])
    }
    useEffect(() => {
        updateControls(PersistenceComponent.getAmbientModeSettings());
    }, []);

    return controls;
}
