import {useCCEvent} from "LxComponents";
import {useCallback, useState} from "react";


export default function useEntryPoint() {

    const loadEntryPointInfo = () => {
        let location = EntryPointHelper.getLocation();
        return cloneObjectDeep(
            EntryPointHelper.getLocationSourceEntry(location)
        );
    }

    const [entryPointInfo, setEntryPointInfo] = useState(loadEntryPointInfo)
    useCCEvent([CCEvent.EntryPointSettingChanged], useCallback(() => {
        const newInfo = loadEntryPointInfo();
        if (hashForObject(newInfo) !== hashForObject(entryPointInfo)) {
            setEntryPointInfo(newInfo);
        }
    }, [entryPointInfo]))

    return entryPointInfo;
}
