'use strict';

define(["AudioZoneControlEnums", "MediaBrowserBase"], function (AudioZoneControlEnums, MediaBrowserBase) {
    class MediaBrowserPlaylists extends MediaBrowserBase {
        //region Static
        static getCellFromContentTypeItem(item, details, contentType, isPlaylist, isBrowsable, actionClb, buttonTappedClb, vc, disabled) {
            // Pretend it's not a playlist to get the correct TableViewCell type
            return Controls.AudioZoneControl.MediaBrowserBase.getCellFromContentTypeItem.call(this, item, details, contentType, false, isBrowsable, actionClb, buttonTappedClb, vc, disabled);
        }

        static getCellButtonIconSrc(item, contentType, vc) {
            return Controls.AudioZoneControl.MediaBrowserBase.getCellButtonIconSrc.apply(this, arguments);
        }

        static getCellTypeForItemCellAtIndex(item, contentType) {
            return Controls.AudioZoneControl.MediaBrowserBase.getCellTypeForItemCellAtIndex.apply(this, arguments);
        }

        static getIconObjForItem(item, identifier) {
            var baseIconObj = Controls.AudioZoneControl.MediaBrowserBase.getIconObjForItem.apply(this, arguments);

            if (MediaServerComp.isFileTypePlaylist(item.type) && !baseIconObj.hasProvidedCover) {
                baseIconObj.highRes = Icon.AudioZone.NEW.PLAYLIST;
                baseIconObj.lowRes = Icon.AudioZone.NEW.PLAYLIST;
                baseIconObj.color = Color.TEXT_SECONDARY_B;
            }

            return baseIconObj;
        } //endregion Static


        //region Getter
        get desc() {
            return _("media.playlists");
        } //endregion Getter


        constructor(details) {
            super(...arguments);
            Object.assign(this, MediaBrowserEditablePlaylistMixin.Mixin);
        }

        getTableContent() {
            var baseTableContent = super.getTableContent(...arguments),
                customSectionClass = this.contentTypes.join(" ") + " ";

            if (!this.receivedContent) {
                customSectionClass += this.LOADING_SECTION_CLASS;
            } // Only show the "add" cell if we don't display a Playlist


            if (this._shouldOfferNewPlaylist()) {
                baseTableContent = [{
                    customClass: customSectionClass,
                    rows: [{
                        content: {
                            clickable: true,
                            title: _("media.playlist.create"),
                            leftIconSrc: Icon.TitleBar.PLUS,
                            leftIconColor: window.Styles.colors.green,
                            leftIconBgEnabled: true,
                            disclosureIcon: false
                        },
                        action: this.createPlaylist.bind(this)
                    }]
                }].concat(baseTableContent);
            }

            return baseTableContent;
        }

        getSectionIdxOffset() {
            return +this._shouldOfferNewPlaylist();
        }

        getEmptyViewIconSrc() {
            if (this.hasError) {
                return super.getEmptyViewIconSrc(...arguments);
            }

            return this.receivedContent ? null : super.getEmptyViewIconSrc(...arguments);
        }

        getEmptyViewTitle() {
            if (this.hasError) {
                return super.getEmptyViewTitle(...arguments);
            }

            return this.receivedContent ? _("media.playlist.empty.title") : super.getEmptyViewTitle(...arguments);
        }

        getEmptyViewButtonText() {
            if (this.hasError) {
                return super.getEmptyViewButtonText(...arguments);
            }

            return this.receivedContent ? _("media.library.upload.title") : super.getEmptyViewButtonText(...arguments);
        }

        emptyViewButtonAction() {
            this._addNewMusicToItem(this.lastSelectedItem);
        }

        createPlaylist() {
            var playlistCnt = this.contentTypeItems[MediaEnum.MediaContentType.PLAYLISTS].all.length,
                playlistPlaceholder = _("media.playlist.save.default-name", {
                    number: playlistCnt + 1
                }),
                id = this.getMediaInfo(this.lastSelectedItem.contentType);

            return MediaServerComp.showNamePopup(_("media.playlist.create"), _("create"), playlistPlaceholder).then(function (plName) {
                return MediaServerComp.createContainer(this.lastSelectedItem.contentType, id, plName).then(function (newPlaylistItem) {
                    // Don't navigate into the newly created playlist if we are in the Playlist picker
                    if (this.ViewController instanceof GUI.AddMediaViewControllerPlaylistPicker) {
                        return Q(true);
                    } else {
                        return this.handleOnItemCellClicked(newPlaylistItem, newPlaylistItem.contentType);
                    }
                }.bind(this));
            }.bind(this));
        }

        getActionsForCtxAndItem(ctx, item) {
            var actions = [];

            switch (ctx) {
                case this.CONTEXT.LMS:
                    if (item === this) {
                        if (this._shouldOfferNewPlaylist()) {
                            actions.pushObject(this._getActionWithTmplForCtx({
                                content: {
                                    title: _("media.playlist.create"),
                                    leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_NEW
                                },
                                action: this.createPlaylist.bind(this)
                            }, ctx));
                        }
                    }

                    break;
            }

            actions = actions.concat(this._getEditableActionsForCtxAndItem.apply(this, arguments));
            return actions;
        }

        _shouldOfferNewPlaylist() {
            if (this.USE_REUSING) {
                return false;
            } else {
                return !MediaServerComp.isFileTypePlayable(this.lastSelectedItem.type, this.lastSelectedItem.contentType);
            }
        }

    }

    Controls.AudioZoneControl.MediaBrowserPlaylists = MediaBrowserPlaylists;
    return Controls.AudioZoneControl.MediaBrowserPlaylists;
});
