'use strict';

class TaskRecordingV2Bar extends GUI.InstructionBar {
    constructor(details) {
        super();
        this.element.addClass("task-recording-v2-bar");
        this.element.removeClass("instruction-bar--animated");
        SandboxComponent.startTaskRecording(true);
        this.task = SandboxComponent.getRecordedTask();
        this.rule = details.rule;
        this.extension = details.extension || AutomaticDesignerEnums.RULE_EXTENSION.NONE;
    }

    viewDidLoad() {
        super.viewDidLoad(...arguments);
        this.elements.stopBtn = this.addButton(Icon.TaskRecorderV2.STOP_RECORDING, "stop-btn");

        this._updateCommands();
    }

    viewDidAppear() {
        super.viewDidAppear(...arguments);
        SandboxComponent.registerForNewTaskCommands(this._updateCommands.bind(this));
        this.elements.stopBtn.on("click", this._finishRecording.bind(this));
    }

    viewDidDisappear(viewRemainsVisible) {
        SandboxComponent.unregisterForNewTaskCommands();
        this.elements.stopBtn.off("click");
        super.viewDidDisappear(viewRemainsVisible);
    }

    destroy() {
        this.task = null;
        SandboxComponent.stopTaskRecording();
        super.destroy();
    }

    setText(t) {
        this.elements.textLbl.html(t);
    }

    // Private methods
    _updateCommands() {
        this.setText(_("automatic-designer.task-recorder.record-action.record-count", {
            actions: "<span>" + _("action-x", {
                count: this.task.commands.length
            }) + "</span>"
        }));
    }

    _finishRecording() {
        var task = this.task; // save a reference to the task because of destroy fn!

        NavigationComp.stopActivity(GUI.ActiveMiniserverViewController.Activity.TaskRecorder);

        if (task.commands.length > 0) {
            AutomaticDesignerComponent.convertTaskToRule(task).then(function (ruleFragment) {
                var alteredRule = cloneObject(this.rule),
                    extension = AutomaticDesignerEnums.RULE_EXTENSION.TASK_RECORDER | this.extension;
                alteredRule.actions = alteredRule.actions.concat(ruleFragment.actions.map(function (action) {
                    action.recorded = true;
                    return action;
                }));
                NavigationComp.showState(ScreenState.AutomaticDesigner.RuleViewController, {
                    rule: alteredRule,
                    ogRule: this.rule,
                    extension: extension,
                    isInEditMode: true
                });
            }.bind(this));
        } else {
            if (hasBit(this.extension, AutomaticDesignerEnums.RULE_EXTENSION.SCENE)) {
                AutomaticDesignerComponent.getScenes().then(function (scenes) {
                    NavigationComp.showState(ScreenState.AutomaticDesigner.ScenesOverviewScreen, {
                        taskRecorderScene: this.rule,
                        isInEditMode: !!scenes.find(function (scene) {
                            return scene.id === this.rule.id;
                        }.bind(this))
                    });
                }.bind(this));
            } else {
                return AutomaticDesignerComponent.getRules().then(function (rules) {
                    NavigationComp.showState(ScreenState.AutomaticDesigner.OverviewScreen, {
                        taskRecorderRule: this.rule,
                        isInEditMode: !!rules.find(function (rule) {
                            return rule.id === this.rule.id;
                        }.bind(this))
                    });
                }.bind(this));
            }
        }
    }

}

GUI.TaskRecordingV2Bar = TaskRecordingV2Bar;
