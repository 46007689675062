'use strict';

define("HourcounterControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class HourcounterControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            if (states.hasMaintenanceInterval) {
                return [this.createCommand(_('controls.hourcounter.maintenance.reset-interval'), Commands.HOURCOUNTER.RESET)];
            }
        }

    };
});
