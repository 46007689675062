'use strict';

import WallboxManagerGroupScreen from "./content/wallboxManagerGroupScreen";
import NodeValueRequestHandler from "./content/nodeValueRequestHandler";
import WallboxManagerLimitedByScreen from "./content/wallboxManagerLimitedByScreen";

define("WallboxManagerControl",
    [
        "Control",
        "./wallboxManagerControlEnums.js"
    ],
    function (
        Control,
        WbManagerEnums
    ) {

        return class WallboxManagerControl extends Control {
            constructor() {
                super(...arguments);
                this.nodes.forEach(rootNode => {rootNode.isRoot = true;});
                this._nodeMap = this._createNodeMap();
                this._nodeValRequest = new NodeValueRequestHandler(this);
            }

            // region getters

            get powerFormat() {
                // a wallbox must always provide kW - this is fixed on the block inputs!
                // as discussed with markus, we decide that one decimal place is enough
                return "%.1f kW";
            }

            get nodes() {
                return Array.isArray(this.details.nodes) ? this.details.nodes : [];
            }

            get wallboxNodes() {
                return this.getWallboxNodes();
            }

            // endregion

            getReactControlContentFlags() {
                return {
                    showStateIcon: false,
                    showStateText: false
                }
            }

            /**
             * Retuns an array of react-screens that are used by this control
             * @returns {*[]}
             */
            getReactScreens() {
                return [
                    ...super.getReactScreens(...arguments),
                    {
                        component: WallboxManagerGroupScreen,
                        name: WallboxManagerGroupScreen.name
                    }, {
                        component: WallboxManagerLimitedByScreen,
                        name: WallboxManagerLimitedByScreen.name
                    }
                ];
            }

            getIcon() {
                return Icon.WallboxManager.Icon;
            }

            /**
             * Returns the childNodes of a uuid provided
             * @param [nodeUuid] uuid of the node, which we want the childNodes from. if omitted, returns the root nodes
             * @returns {[]}
             */
            getChildNodes(nodeUuid = null) {
                if (nodeUuid === null || nodeUuid === this.uuidAction) {
                    return this.nodes;
                } else {
                    let node = this.getNode(nodeUuid);
                    return node && Array.isArray(node.nodes) ? node.nodes : [];
                }
            }

            /**
             * Returns a list of all the wallbox nodes underneath a group node identified by the uuid.
             * @param nodeUuid if omitted, the whole controls nodes are used.
             * @returns {*[]}
             */
            getWallboxNodes(nodeUuid = null) {
                let wallboxes = [];
                this.getChildNodes(nodeUuid).forEach((childNode) => {
                    if (this._isWallboxNode(childNode)) {
                        wallboxes.push(childNode);
                    } else if (this._isGroupNode(childNode)) {
                        wallboxes = [...wallboxes, ...this.getWallboxNodes(childNode.uuid)];
                    }
                })
                return wallboxes;
            }

            getLimitedWallboxNodes(nodeUuid = null) {
                return this.getWallboxNodes(nodeUuid).filter(wbNode => {
                    return this.isWallboxNodeLimited(wbNode);
                })
            }

            isWallboxNodeLimited(node) {
                if (this._isWallboxNode(node)) {
                    // a limit flag is not enough, it also needs to have a limit 0 to be considered limited.
                    const {states} = SandboxComponent.getStatesForUUID(node.ctrlUuid);
                    return states ? (states.limitedBy > 0 && states.limit === 0) : false;
                } else {
                    return false;
                }
            }

            getNode(nodeUuid) {
                if (this._nodeMap.hasOwnProperty(nodeUuid)) {
                    return this._nodeMap[nodeUuid];
                } else {
                    return null;
                }
            }

            /**
             * Returns the important values for the node, limit power and current power.
             * @param nodeUuid
             * @returns {{current: number, limit: number}}
             */
            getNodeValue(nodeUuid) {
                let node = this.getNode(nodeUuid),
                    ctrl = ActiveMSComponent.getStructureManager().getControlByUUID(node.ctrlUuid),
                    limit = !!ctrl ? false : node.fuse,
                    priority = ctrl && ctrl.isPrioCharging,
                    power = this._getPowerForNode(node, ctrl),
                    limitedBy = this._getLimitedByForNode(node, ctrl);

                Debug.Control.WallboxManager && console.log(this.name, "getNodeValue " + node.title + " = " + power + " / " + limit + ", limitedBy=" + limitedBy);

                return { power, limit, priority, limitedBy }
            }

            /**
             * Async request for a node value
             * @param nodeUuid
             * @returns {Q.Promise<{current: number, limit: number}>|*}
             */
            requestNodeValue(nodeUuid) {
                let node = this.getNode(nodeUuid),
                    ctrl = ActiveMSComponent.getStructureManager().getControlByUUID(node.ctrlUuid);
                if (!node.isRoot && !ctrl) {
                    return this._nodeValRequest.requestValue(nodeUuid).then(res => {
                      return {
                          fuse: res.fuse,
                          assigned: res.assigned,
                          limitedBy: res.limitedBy,
                          used: res.used
                      }
                    });
                } else {
                    return Q.resolve(this.getNodeValue(nodeUuid));
                }
            }

            _getPowerForNode(node, ctrl) {
                let value = 0,
                    stateName = this.getStateNameForUuid(node.actualState),
                    states = this.getStates();

                if (ctrl) {
                    value = ctrl.currentPower;
                    Debug.Control.WallboxManager && console.log(this.name, "_getPowerForNode " + node.title + " = (ctrl): " + value);

                } else if (stateName && states.hasOwnProperty(stateName)) {
                    value = states[stateName];
                    Debug.Control.WallboxManager && console.log(this.name, "_getPowerForNode " + node.title + " = (WBEM): " + value);
                }

                return value;
            }

            _getLimitedByForNode(node, ctrl) {
                let value = 0,
                    stateName = this.getStateNameForUuid(node.limitedByState),
                    states = this.getStates();

                if (ctrl) {
                    value = ctrl.limitedBy;
                    Debug.Control.WallboxManager && console.log(this.name, "_getLimitedByForNode " + node.title + " = (ctrl): " + value);

                } else if (stateName && states.hasOwnProperty(stateName)) {
                    value = states[stateName];
                    Debug.Control.WallboxManager && console.log(this.name, "_getLimitedByForNode " + node.title + " = (WBEM): " + value);
                }

                return value;
            }

            getStateNameForUuid(stateUuid) {
                let name = null;
                Object.keys(this.states).some(stateName => {
                    if (this.states[stateName] === stateUuid) {
                        name = stateName;
                        return true;
                    }
                    return false;
                })

                return name;
            }

            /**
             * recursively goes through the nodes of the array and returns a map, where the uuid is the key and the
             * node object itself is the value.
             * @returns {{}}
             * @private
             */
            _createNodeMap() {
                let map = {};
                const _recMapNode = (node, path = []) => {
                    map[node.uuid] = node;
                    node.path = [...path];
                    let childPath = [...path, node.title];
                    if (Array.isArray(node.nodes)) {
                        node.nodes.forEach(childNode => _recMapNode(childNode, childPath));
                    }
                };
                this.nodes.forEach(rootNode => _recMapNode(rootNode));
                return map;
            }

            _isWallboxNode(node) {
                return node && node.hasOwnProperty("ctrlUuid");
            }

            _isGroupNode(node) {
                return node && node.hasOwnProperty("nodes") && Array.isArray(node.nodes);
            }
        };
    });
