const ControlRequire = require.context("./", true, /.*control\.js/i),
    EnumsRequire = require.context("./", true, /^Controls\/.*\/.*Enums\.js$/);

export function getControlForType(type = "") {
    try {
        return ControlRequire(getRequirePathForModule(`${type}Control`));
    } catch (e) {
        return ControlRequire(getRequirePathForModule("Control"));
    }
}

export function getEnumsForType(type) {
    try {
        return EnumsRequire(getRequirePathForModule(`${type}ControlEnums`));
    } catch (e) {
        return {}
    }
}
