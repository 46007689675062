'use strict';

define('TimeLineSlider', [], function () {
    return class TimeLineSlider extends GUI.LxSliderWithCustomRate {
        //region Static
        static Template = function () {
            var getShadowBox = function getShadowBox() {
                return '' + '<div class="handle__shadow-box">' + '<div class="shadow-box__shadow"></div>' + '</div>';
            };

            return {
                getShadowBox: getShadowBox
            };
        }(); //endregion Static

        constructor() {
            super({
                handleType: 'line',
                activeColor: Color.AUDIO_ZONE_SLIDER_COLOR,
                showProgress: true,
                updateRate: 0.5
            });
            this.isPlaying = true;
            this.element.removeClass('slider-v2');
            this.element.addClass('time-line-slider');
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                var handle = this.element.find('.slider__notch');
                handle.prepend(TimeLineSlider.Template.getShadowBox());
                this.shadowBox = handle.find('.handle__shadow-box');
            }.bind(this));
        }

        updateGUIPosition() {
            var percentage = super.updateGUIPosition(...arguments);
            GUI.animationHandler.schedule(function () {
                this.shadowBox.css("left", percentage * 100 + "%");
            }.bind(this));
            return percentage;
        }

        setIsPlaying(isPlaying) {
            if (isPlaying !== this.isPlaying) {
                GUI.animationHandler.schedule(function () {
                    this.shadowBox.toggleClass('stopped', !isPlaying);
                    this.shadowBox.toggleClass('playing', isPlaying);
                }.bind(this));
                this.isPlaying = isPlaying;
            }
        }

    };
});
