import * as React from "react"
import Svg, { Path, Rect } from "react-native-svg"

function Weather10(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            <Path
                d="M3 16.2a4.79 4.79 0 012.44-4.158A7.26 7.26 0 0112.75 5a7.333 7.333 0 016.22 3.412A5.36 5.36 0 0120.47 8.2a5.28 5.28 0 014.944 3.37A4.793 4.793 0 0124.125 21H7.875A4.838 4.838 0 013 16.2z"
                fill="#d5e2e9"
                fillRule="evenodd"
            />
            <Rect
                x={10.49001}
                y={22.79798}
                width={2}
                height={4}
                rx={1}
                ry={1}
                transform="rotate(15 11.49 24.798)"
                fill="#51a9df"
            />
            <Rect
                x={14.22059}
                y={22.67619}
                width={2}
                height={6}
                rx={1}
                ry={1}
                transform="rotate(15 15.22 25.676)"
                fill="#51a9df"
            />
            <Rect
                x={18.48583}
                y={22.78365}
                width={2}
                height={4}
                rx={1}
                ry={1}
                transform="rotate(15 19.486 24.784)"
                fill="#51a9df"
            />
            <Path fill="none" d="M0 0H32V32H0z" />
        </Svg>
    )
}

export default Weather10
