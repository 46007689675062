import globalStyles from "GlobalStyles"

import React, { useState, useMemo, useRef, useCallback, useEffect, Fragment } from 'react'
import { PanResponder, View, Text as RNText, TouchableOpacity } from 'react-native';

import {
    Rect,
    Line,
    Text,
    Circle,
    Path,
    LinearGradient,
    Stop,
    Defs,
    G,
    Mask,
} from 'react-native-svg';

import { LxReactText, useBorderColor } from "LxComponents"

import PropTypes from 'prop-types';
import AspectRatioSvg from '../AspectRatioSvg';
import Pills from './Pills';

import debounce from 'lodash/debounce';
import Icons from "IconLib"

const styles = {
    headingBar: {
        marginBottom: 7,
        marginTop: 7,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    headingBarOverlay: {

    },
    heading: {
        ...globalStyles.textStyles.title1.bold,
        color: globalStyles.colors.text.primary,
        fontFamily: globalStyles.fontSettings.families.extraBold,
        fontSize: 20,
    },
}

import {
    graphWhiteSpace,
    barWidth,
    barMaxWidthMultiplier,
    rightScaleWidthPercent,
    drawableAreaWidthPercent,
    drawableAreaHeightPercent,
    numVerticalSeparations,
    bgLineStroke, bgLineStrokeThick,
    labelWidthPercent,
} from './config'

const mapGraphValueToPixelSpace = (inputRangeStart, inputRangeEnd, outputRangeStart, outputRangeEnd, value) => {
    const val = (value - inputRangeStart) * ((outputRangeEnd - outputRangeStart) / (inputRangeEnd - inputRangeStart)) + outputRangeStart
    return isNaN(val) ? 0 : val;
}

const addHorizontalLine = ({ arr, idx, color, noText = false, value, isZeroLine, lineY, rightInnerGraphEnd, rightOutterGraphEnd, width }) => {
    arr.push(<Line key={`hor-line-${idx}`} {...(isZeroLine ? (bgLineStrokeThick) : (bgLineStroke))} stroke={color} x1={0} y1={lineY} x2={rightOutterGraphEnd} y2={lineY} />)
    if (!noText) {
        const letterSpacing = -width * 0.002 > -1 ? -width * 0.002 : -1;
        arr.push(<Text
            key={`hor-label-${idx}`}
            stroke="none"
            fill={color}
            x={rightInnerGraphEnd + width * 0.01}
            y={lineY + width * 0.034}
            fontSize={value.length > 6 ? globalStyles.fontSettings.sizes.micro : globalStyles.fontSettings.sizes.smaller}
            letterSpacing={letterSpacing}
            textAnchor="start"
            fontFamily={globalStyles.fontSettings.families.bold} // Only set weight via family
        >{value}</Text>)
    }
}

const rangeAwareRound = (number, direction) => { // direction; true -> up; false -> down
    if (number < 0.5 && number > -0.5) { return rangeAwareRound(number * 100, number > 0 ? true : false) / 100 }

    const mathFunc = direction ? Math.ceil : Math.floor;
    const roundNumber = mathFunc(number);
    const digitCount = roundNumber.toString().replace('-', '').length;
    if (digitCount === 0) { return roundNumber }
    let divider;
    switch (digitCount) {
        case 1: divider = 1; break;
        case 2: divider = 5; break;
        case 3: divider = 10; break;
        case 4: divider = 50; break;
        default: divider = Math.pow(10, digitCount - 1);
    }

    return mathFunc(number / divider) * divider;
}

const roundOutTinyErrors = number => {
    const rounded = Math.round(number);
    if (Math.abs(rounded - number) < 0.00001) {
        return rounded;
    } else {
        const oneDecimalWattRounded = Math.round(number * 10000) / 10000;
        if (Math.abs(oneDecimalWattRounded - number) < 0.0001) {
            return oneDecimalWattRounded;
        }
        return number;
    }
}

/* #region  createBars */
const createBars = ({ datapoints, rightInnerGraphEnd, bottomInnerGraphEnd, graphRange, arr, lowerGraphLimit, upperGraphLimit, width }) => {
    const numDataPoints = datapoints.length;
    const dataPointWidth = rightInnerGraphEnd / numDataPoints;
    datapoints.forEach((datapoint, idx) => {
        const barsToDraw = [];
        if (Array.isArray(datapoint)) {
            datapoint.forEach(innerDataPoint => {
                barsToDraw.push(innerDataPoint);
            });
        } else {
            barsToDraw.push(datapoint);
        }

        const totalBarWidth = Math.min(dataPointWidth * barWidth, width * barMaxWidthMultiplier);

        barsToDraw.forEach((barData, innerIdx) => {
            let barHeight = Math.abs(barData.value) / graphRange * bottomInnerGraphEnd;
            const dataPointTopValue = barData.value < 0 ? 0 : barData.value;
            let y = mapGraphValueToPixelSpace(lowerGraphLimit, upperGraphLimit, bottomInnerGraphEnd, 0, dataPointTopValue);
            if (y > bottomInnerGraphEnd) {
                y = bottomInnerGraphEnd;
            }
            if (y + barHeight > bottomInnerGraphEnd) {
                barHeight = bottomInnerGraphEnd - y;
            }
            arr.push(
                <Rect
                    key={`bar-${idx}-${innerIdx}`}
                    fill={barData.color}
                    x={dataPointWidth * idx + (dataPointWidth - totalBarWidth) / 2}
                    y={y}
                    width={totalBarWidth}
                    height={barHeight}
                />
            )
        });
    });
}
/* #endregion */

/* #region  createSideBySideBars */
const createSideBySideBars = ({
    datapoints,
    rightInnerGraphEnd,
    bottomInnerGraphEnd,
    graphRange,
    arr,
    lowerGraphLimit,
    upperGraphLimit,
    width
}) => {
    if (!Array.isArray(datapoints[0])) {
        return createBars({ datapoints, rightInnerGraphEnd, bottomInnerGraphEnd, graphRange, arr, lowerGraphLimit, upperGraphLimit, width });
    }

    const numDataPoints = datapoints.length;
    const dataPointWidth = rightInnerGraphEnd / numDataPoints;
    const totalBarWidth = Math.min(dataPointWidth * barWidth, width * barMaxWidthMultiplier);

    datapoints.forEach((subDataPoints, idx) => {
        const numDivisions = subDataPoints.length;
        const singleBarWidth = totalBarWidth / numDivisions;

        subDataPoints.forEach((datapoint, innerIdx) => {
            let barHeight = Math.abs(datapoint.value) / graphRange * bottomInnerGraphEnd;
            const dataPointTopValue = datapoint.value < 0 ? 0 : datapoint.value;
            let y = mapGraphValueToPixelSpace(lowerGraphLimit, upperGraphLimit, bottomInnerGraphEnd, 0, dataPointTopValue);
            if (y > bottomInnerGraphEnd) {
                y = bottomInnerGraphEnd;
            }
            const x = dataPointWidth * idx + ((dataPointWidth - totalBarWidth) / 2) + singleBarWidth * innerIdx;
            if (y + barHeight > bottomInnerGraphEnd) {
                barHeight = bottomInnerGraphEnd - y;
            }
            arr.push(
                <Rect
                    key={`bar-${idx}-${innerIdx}`}
                    fill={datapoint.color}
                    x={x}
                    y={y}
                    width={singleBarWidth}
                    height={barHeight}
                />
            )
        });
    });
}
/* #endregion */

let cutoffCounter = 0;

/* #region  createLines */
const createLines = ({
    datapoints,
    rightInnerGraphEnd,
    bottomInnerGraphEnd,
    arr,
    lowerGraphLimit,
    upperGraphLimit,
    forcedColors,
    forcedTimeRange
}) => {
    if (datapoints.length === 0) { return; }
    const innerArr = [];
    const mTag = cutoffCounter.toString();

    const numDataPoints = datapoints.length;
    const dataPointWidth = rightInnerGraphEnd / numDataPoints;

    /* #region  Reshape data for Lines */
    const reshapedData = [{ data: [] }];

    let lastFullyRenderedIdx = 0;
    let lastFullyRenderedTs = 0;
    /**
     * Andi BG-I22096 -> graph line cropped when Power drops to 0 (kWh)
     * The following lines used to determine the last fully rendered idx/ts by looking at where the
     * datapoints start to only show zero. This of course doesn't work well if the stats drop to
     * zero earlier than the time until which the graph should display.
     * So now we determine the last fully rendered idx by using the last datapoint that is now or in the past.
     * Since we never show data in the future, this should be a stable way to get the rendering range.
     */
    datapoints.forEach((datapoint, idx) => {
        if (Array.isArray(datapoint)) {
            datapoint.forEach((dP, innerIdx) => {
                if (!reshapedData[innerIdx]) { reshapedData[innerIdx] = { data: [] }; }
                reshapedData[innerIdx].data.push(dP)
                if (moment(dP.timestamp * 1000).isSameOrBefore(ActiveMSComponent.getMiniserverUnixUtcTimestamp() * 1000)) {
                    lastFullyRenderedIdx = idx;
                    lastFullyRenderedTs = dP.timestamp;
                }
            })
        } else {
            reshapedData[0].data.push(datapoint)
            if (moment(datapoint.timestamp * 1000).isSameOrBefore(ActiveMSComponent.getMiniserverUnixUtcTimestamp() * 1000)) {
                lastFullyRenderedIdx = idx;
                lastFullyRenderedTs = datapoint.timestamp;
            }
        }
    });
    /* #endregion */

    let highestPoint = -Infinity;
    let lowestPoint = Infinity;

    reshapedData.forEach(({ data: dataset }, datasetIdx) => {
        const startPixelY = mapGraphValueToPixelSpace(lowerGraphLimit, upperGraphLimit, bottomInnerGraphEnd, 0, dataset[0].value);
        const pathArr = [`M 0 ${startPixelY}`];
        let lowestValue = Infinity; let highestValue = -Infinity;
        dataset.forEach((datapoint, idx) => {
            const datapointPixelY = mapGraphValueToPixelSpace(lowerGraphLimit, upperGraphLimit, bottomInnerGraphEnd, 0, datapoint.value);
            const ts = Array.isArray(datapoint) ? datapoint[0].timestamp : datapoint.timestamp;
            if (datapoint.value < lowestValue) { lowestValue = datapoint.value; }
            if (datapoint.value > highestValue) { highestValue = datapoint.value; }
            const dataPointPixelX = !forcedTimeRange ?
                ((dataPointWidth * idx) + (dataPointWidth / 2)) :
                mapGraphValueToPixelSpace(forcedTimeRange.start, forcedTimeRange.end, 0, rightInnerGraphEnd, ts);
            pathArr.push(`L ${dataPointPixelX} ${datapointPixelY}`);
            if (datapointPixelY < highestPoint) { highestPoint = datapointPixelY }
            if (datapointPixelY > lowestPoint) { lowestPoint = datapointPixelY }
        })
        const endPixelY = mapGraphValueToPixelSpace(lowerGraphLimit, upperGraphLimit, bottomInnerGraphEnd, 0, dataset.slice(-1)[0].value);
        pathArr.push(`L ${rightInnerGraphEnd} ${endPixelY}`);
        const linePath = pathArr.join('\n');

        if (lowerGraphLimit < 0) {
            pathArr.push(`L ${rightInnerGraphEnd} ${mapGraphValueToPixelSpace(lowerGraphLimit, upperGraphLimit, bottomInnerGraphEnd, 0, 0)}`)
            pathArr.push(`L 0 ${mapGraphValueToPixelSpace(lowerGraphLimit, upperGraphLimit, bottomInnerGraphEnd, 0, 0)}`)
            pathArr.push(`L 0 ${startPixelY}`)
            const upperPercent = highestValue / (highestValue - lowestValue);
            innerArr.push(
                <Defs key={`line-${datasetIdx}-${mTag}-def`}>
                    <LinearGradient id={`line-${datasetIdx}-gradient-${mTag}`} x1="0%" y1="0%" x2="0%" y2="100%">
                        <Stop offset="0%" stopColor="black" stopOpacity="0.3" />
                        <Stop offset={`${upperPercent * 100}%`} stopColor="black" stopOpacity="0.8" />
                        <Stop offset="100%" stopColor="black" stopOpacity="0.3" />
                    </LinearGradient>
                    {
                        forcedColors ? (
                            <LinearGradient id={`line-${datasetIdx}-color-gradient-${mTag}`} x1="0%" y1="0%" x2="0%" y2="100%">
                                <Stop offset="0%" stopColor={forcedColors.top} stopOpacity="1" />
                                <Stop offset={`${upperPercent * 100}%`} stopColor={forcedColors.top} stopOpacity="1" />
                                <Stop offset={`${upperPercent * 100}%`} stopColor={forcedColors.bottom} stopOpacity="1" />
                                <Stop offset="100%" stopColor={forcedColors.bottom} stopOpacity="1" />
                            </LinearGradient>
                        ) : null
                    }
                </Defs>
            )
        } else {
            pathArr.push(`L ${rightInnerGraphEnd} ${bottomInnerGraphEnd}`)
            pathArr.push(`L 0 ${bottomInnerGraphEnd}`)
            pathArr.push(`L 0 ${startPixelY}`)
            innerArr.push(
                <Defs key={`line-${datasetIdx}-def`}>
                    <LinearGradient id={`line-${datasetIdx}-gradient-${mTag}`} x1="0%" y1="0%" x2="0%" y2="100%">
                        <Stop offset="0%" stopColor="black" stopOpacity="0.3" />
                        <Stop offset="100%" stopColor="black" stopOpacity="0.8" />
                    </LinearGradient>
                </Defs>
            )
        }

        const gradientPath = pathArr.join('\n');
        innerArr.push(
            <Path
                key={`lines-${datasetIdx}-gradient`}
                fill={forcedColors && lowerGraphLimit < 0 ? `url(#line-${datasetIdx}-color-gradient-${mTag})` : forcedColors ? forcedColors.top : dataset[0].color}
                d={gradientPath}
            />
        )
        innerArr.push(
            <Path
                key={`lines-${datasetIdx}-gradient-overlay`}
                fill={`url(#line-${datasetIdx}-gradient-${mTag})`}
                d={gradientPath}
            />
        )
        innerArr.push(
            <Path
                key={`lines-${datasetIdx}-line`}
                stroke={forcedColors && lowerGraphLimit < 0 ? `url(#line-${datasetIdx}-color-gradient-${mTag})` : forcedColors ? forcedColors.top : dataset[0].color}
                strokeWidth={1}
                d={linePath}
                fill="none"
            />
        )

        cutoffCounter++;
        let maskWidth;
        if (!forcedTimeRange) {
            maskWidth = dataPointWidth * (lastFullyRenderedIdx + 0.5);
        } else {
            maskWidth = mapGraphValueToPixelSpace(forcedTimeRange.start, forcedTimeRange.end, 0, rightInnerGraphEnd, lastFullyRenderedTs);
        }
        if (maskWidth < 0) maskWidth = 0;
        arr.push((
            <Fragment key={`cutoff-${datasetIdx}-${mTag}`}>
                {
                    !forcedTimeRange ? (
                        <Mask id={`cutoff-${datasetIdx}-${mTag}`}>
                            <Rect
                                x={0}
                                y={0}
                                width={maskWidth}
                                height={bottomInnerGraphEnd}
                                fill="white"
                            />
                        </Mask>
                    ) : (
                        <Mask id={`cutoff-${datasetIdx}-${mTag}`}>
                            <Rect
                                x={0}
                                y={0}
                                width={maskWidth}
                                height={bottomInnerGraphEnd}
                                fill="white"
                            />
                        </Mask>
                    )
                }
                <G mask={`url(#cutoff-${datasetIdx}-${mTag})`}>
                    {innerArr}
                </G>
            </Fragment>
        ));
    })
}
/* #endregion */

/* #region  createHighlight */
const createHighlight = ({
    datapoints,
    arr,
    markedIndex,
    rightInnerGraphEnd,
    bottomOutterGraphEnd,
    lowerGraphLimit,
    upperGraphLimit,
    bottomInnerGraphEnd,
    graphRange,
    width,
    forcedTimeRange
}) => {
    if (!Array.isArray(datapoints) || !datapoints[markedIndex]) {
        return;
    }

    const dataPointWidth = rightInnerGraphEnd / datapoints.length;
    const ts = Array.isArray(datapoints[markedIndex]) ? datapoints[markedIndex][0].timestamp : datapoints[markedIndex].timestamp;
    const dataX = !forcedTimeRange ?
        dataPointWidth * (markedIndex + 0.5) :
        mapGraphValueToPixelSpace(forcedTimeRange.start, forcedTimeRange.end, 0, rightInnerGraphEnd, ts);

    arr.push(
        <Rect
            key={`marked-index-bar`}
            fill="white"
            x={dataX}
            y={0}
            width={1}
            height={bottomInnerGraphEnd}
        />
    )
    const dataPointForHighlight = datapoints[markedIndex];
    let flattendDataPoint = [dataPointForHighlight];
    if (Array.isArray(dataPointForHighlight)) {
        flattendDataPoint = dataPointForHighlight;
    }
    flattendDataPoint.forEach((barData, innerIdx) => {
        const dataPointTopValue = barData.value < 0 ? 0 : barData.value;
        let y = mapGraphValueToPixelSpace(lowerGraphLimit, upperGraphLimit, bottomInnerGraphEnd, 0, dataPointTopValue);
        if (barData.value < 0) {
            const barHeight = Math.abs(barData.value) / graphRange * bottomInnerGraphEnd;
            y += barHeight;
        }
        const radius = width * 0.012 < 10 ? width * 0.012 : 6;
        arr.push(
            <Circle
                key={`bar-${markedIndex}-marker-${innerIdx}-white`}
                cy={y}
                cx={dataX - (!forcedTimeRange ? 0.5 : 0)}
                r={radius}
                fill="white"
            />
        )
        arr.push(
            <Circle
                key={`bar-${markedIndex}-marker-${innerIdx}-black`}
                cy={y}
                cx={dataX}
                r={radius * 0.58}
                fill="black"
            />
        )
    });
}
/* #endregion */

function Graph({
    type = 'bar',
    horizontalLabels = [],
    bottomLabelsCentered,
    datapoints = [],
    label = 'kWh',
    format = "%.2f kW",
    style,
    fullStretch = false,
    titleLeft,
    titleRight,
    highlightDateFormat = LxDate.getDateFormat(DateType.DateAndTimeShort),
    fixedLowerLimit,
    fixedUpperLimit,
    legend,
    onZoom,
    forcedColors,
    forcedTimeRange,
    upperGraphMin,
    lowerGraphMax,
    highlightFullGraph = false,
    customPillTitle
}) {
    const [{ width, height }, setSize] = useState({ width: 0, height: 0 })
    const [markedIndex, setMarkedIndex] = useState(false);
    const [lastTouch, setLastTouch] = useState(false);
    const lineColor = useBorderColor();
    const markedIndexRef = useRef(false);

    const viewRef = useRef(null);

    /* #region Constants calculations */
    const {
        numHorizontalSeparations,
        numHorizontalLabels,
        rightInnerGraphEnd,
        bottomInnerGraphEnd,
        rightOutterGraphEnd,
        bottomOutterGraphEnd,
    } = useMemo(() => {
        let numHorizontalSeparations = datapoints.length;
        if (numHorizontalSeparations > 31) { numHorizontalSeparations = 24; }
        if (numHorizontalSeparations === 0) { numHorizontalSeparations = 24; }

        let numHorizontalLabels = horizontalLabels.length;
        if (datapoints.length === 0) { numHorizontalLabels = 4; }
        if (horizontalLabels.length > 0) {
            while (numHorizontalSeparations % numHorizontalLabels !== 0 && numHorizontalSeparations > 0) {
                numHorizontalSeparations--;
            }
        }

        return {
            numHorizontalSeparations,
            numHorizontalLabels,
            rightInnerGraphEnd: width * drawableAreaWidthPercent,
            rightOutterGraphEnd: width * (drawableAreaWidthPercent + rightScaleWidthPercent),
            bottomInnerGraphEnd: height * drawableAreaHeightPercent,
            bottomOutterGraphEnd: height,
        }
    }, [datapoints, width, height, fullStretch]);

    let lastFullyRenderedIdx = useMemo(() => {
        let last = 0;
        datapoints.forEach((datapoint, idx) => {
            if (Array.isArray(datapoint)) {
                datapoint.forEach(dP => {
                    if (moment(dP.timestamp * 1000).isSameOrBefore(ActiveMSComponent.getMiniserverUnixUtcTimestamp() * 1000)) {
                        last = idx;
                    }
                })
            } else {
                if (moment(datapoint.timestamp * 1000).isSameOrBefore(ActiveMSComponent.getMiniserverUnixUtcTimestamp() * 1000)) {
                    last = idx;
                }
            }
        });
        return last;
    }, [datapoints]);
    /* #endregion */

    /* #region  Interaction listeners (for highlight) */
    const touchState = useRef(false);
    const touchResponderStart = (evt, gestureState) => {
        if(touchState.current === false) {
            touchState.current = true;
        }
    }
    const touchResponderMove = (evt, gestureState) => {
        if (viewRef.current && touchState.current) {
            const trigger = evt.type;
            if(trigger === 'mousemove') {
                touchState.current = { touchX: evt.nativeEvent.x, touchY: evt.nativeEvent.y }
                setLastTouch({ touchX: evt.nativeEvent.x, touchY: evt.nativeEvent.y })
            } else {
                touchState.current = { touchX: gestureState.x0 + gestureState.dx, touchY: gestureState.y0 + gestureState.dy }
                setLastTouch({ touchX: gestureState.x0 + gestureState.dx, touchY: gestureState.y0 + gestureState.dy })
            }
        }
    };

    const touchResponderAllowed = () => {
        return touchState.current === false;
    }

    const touchEndResponder = () => {
        if(!!touchState.current) {
            touchState.current = false;
            markedIndexRef.current = false;
            setLastTouch(false);
        }
    };

    const checkIfDatapointHasValue = (datapoint) => {
        if (Array.isArray(datapoint)) {
            return datapoint.filter((a) => {a?.value !== Graph.NO_DATA_VALUE}).length === 0
        } else {
            return datapoint?.value !== Graph.NO_DATA_VALUE
        }
    }

    useEffect(() => {
        if (touchState.current === false) {
            markedIndexRef.current = false;
            return;
        }
        if (viewRef.current) {
            viewRef.current.measure((fx, fy, width, height, px, py) => {
                const { touchX, touchY } = touchState.current;
                const xInGraph = touchX - px;
                const yInGraph = touchY - py;
                const rightInnerGraphEnd = width * drawableAreaWidthPercent;
                const bottomInnerGraphEnd = height * drawableAreaHeightPercent;

                if (xInGraph >= 0 && xInGraph <= rightInnerGraphEnd && yInGraph >= 0 && yInGraph <= bottomInnerGraphEnd) {
                    const dataPointWidth = rightInnerGraphEnd / datapoints.length;

                    let idx = Math.round((xInGraph / dataPointWidth) + 0.5) - 1;

                    if (forcedTimeRange && type === "line") {
                        let computedTs = Math.round(mapGraphValueToPixelSpace(0, rightInnerGraphEnd, forcedTimeRange.start, forcedTimeRange.end, xInGraph + 2));
                        let limit = ActiveMSComponent.getMiniserverUnixUtcTimestamp() + 60;
                        // look up datapoint
                        let newMark = false;
                        datapoints.some((datapoint, idx) => {
                            if (Array.isArray(datapoint)) {
                                datapoint.forEach(dP => {
                                    if (dP.timestamp <= computedTs && dP.timestamp <= limit) {
                                        newMark = idx;
                                        return false;
                                    } else {
                                        return true;
                                    }
                                })
                            } else {
                                if (datapoint.timestamp <= computedTs && datapoint.timestamp <= limit) {
                                    newMark = idx;
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        });
                        markedIndexRef.current = newMark;

                    } else if (idx <= lastFullyRenderedIdx || highlightFullGraph) {
                        if (checkIfDatapointHasValue(datapoints[idx])) {
                            markedIndexRef.current = idx;
                        } else {
                            setMarkedIndex(false);
                        }
                    } else {
                        markedIndexRef.current = false;
                    }
                } else {
                    markedIndexRef.current = false;
                }
            })
        }
    }, [lastTouch, lastFullyRenderedIdx, JSON.stringify(datapoints)]);

    const panResponder = useRef(
        PanResponder.create({
            onPanResponderMove: touchResponderMove,
            onStartShouldSetPanResponder: touchResponderAllowed,
            onPanResponderStart: touchResponderStart,
            onPanResponderEnd: touchEndResponder,
            onPanResponderTerminate: touchEndResponder,
            onPanResponderTerminationRequest: () => false,
        })
    ).current;
    /* #endregion */

    const svgChildren = useMemo(() => {
        if (width <= 0 || height <= 0) {
            return [];
        }

        let arr = [];

        /* #region  Calculate Graph Limits */
        // Array can be one deep (multiple values per datapoint)
        let maxDatapointValue = datapoints.length > 0 ? -Infinity : 4;
        let minDatapointValue = datapoints.length > 0 ? Infinity : 0;
        datapoints.forEach(datapoint => {
            if (Array.isArray(datapoint)) {
                datapoint.forEach(innerDataPoint => {
                    if (innerDataPoint.value > maxDatapointValue) { maxDatapointValue = innerDataPoint.value }
                    if (innerDataPoint.value < minDatapointValue) { minDatapointValue = innerDataPoint.value }
                })
            } else {
                if (datapoint.value > maxDatapointValue) { maxDatapointValue = datapoint.value }
                if (datapoint.value < minDatapointValue) { minDatapointValue = datapoint.value }
            }
        });

        const upperPad = Math.max(0.01, maxDatapointValue * (1 + graphWhiteSpace) - maxDatapointValue)
        const lowerPad = Math.max(0.01, Math.abs(minDatapointValue * (1 + graphWhiteSpace) - minDatapointValue))

        let upperGraphLimit = rangeAwareRound(maxDatapointValue + upperPad, true);
        let lowerGraphLimit = 0;
        if (minDatapointValue < 0) {
            lowerGraphLimit = rangeAwareRound(minDatapointValue - lowerPad, false);
        }

        /**
         * Check if minimum graph range is needed
         */
        if (maxDatapointValue > 100 || minDatapointValue < -100) {
            upperGraphMin *= 1000
            lowerGraphMax *= 1000
        }
        if (upperGraphMin > upperGraphLimit) {
            upperGraphLimit = upperGraphMin;
        }
        if (lowerGraphMax < lowerGraphLimit) {
            lowerGraphLimit = lowerGraphMax;
        }

        if (typeof fixedLowerLimit === 'number') {
            lowerGraphLimit = fixedLowerLimit;
        }
        if (typeof fixedUpperLimit === 'number') {
            upperGraphLimit = fixedUpperLimit;
        }

        const zeroLineY = mapGraphValueToPixelSpace(lowerGraphLimit, upperGraphLimit, bottomInnerGraphEnd, 0, 0);
        const sectionHeight = bottomInnerGraphEnd / numVerticalSeparations;
        let closestLineY;
        let closestLineYDistance = Infinity;
        for (let i = 1; i < numVerticalSeparations + 1; i++) {
            const currentY = (i * sectionHeight);
            const dist = Math.abs(currentY - zeroLineY);
            if (dist < closestLineYDistance) {
                closestLineY = currentY;
                closestLineYDistance = dist;
            }
        }
        const innerLineOffset = zeroLineY - closestLineY;

        const graphRange = upperGraphLimit - lowerGraphLimit;
        /* #endregion */

        /* #region  Outter right label (e.g. kWh, rotated 90deg) */
        const graphUnitInformation = lxUnitConverter.getUnitInformation(`%.2f ${label}`, Math.abs(lowerGraphLimit) > upperGraphLimit ? Math.abs(lowerGraphLimit) : upperGraphLimit);

        arr.push(<Text
            key={'outter-right-label'}
            stroke="none"
            fill="rgba(235, 235, 245, 0.6)"
            x={width - 15}
            y={bottomInnerGraphEnd / 2 - 3} // The three pixel offset is not to collide with the graph itself
            fontSize={globalStyles.fontSettings.sizes.smaller}
            textAnchor="middle"
            fontFamily={globalStyles.fontSettings.families.bold} // Only set weight via family
            rotation={90}
            originX={width - 15} originY={bottomInnerGraphEnd / 2}
        >{graphUnitInformation.unitStr}</Text>)
        /* #endregion */

        arr.push(<Path key="outer-rect" d={`M 0 ${bottomInnerGraphEnd} L 0 0 L ${rightInnerGraphEnd} 0 L ${rightInnerGraphEnd} ${bottomOutterGraphEnd}`} {...bgLineStroke} stroke={lineColor} fill="none" />)

        /* #region  Vertical Lines */
        const sectionWidth = rightInnerGraphEnd / numHorizontalSeparations;
        let numSectionsBetweenHorizontalLabels = Math.round(numHorizontalSeparations / numHorizontalLabels);
        for (let i = 0; i < numHorizontalSeparations; i++) {
            if (numHorizontalLabels === numHorizontalSeparations) {
                arr.push(<Line key={`ver-line-${i}`} {...bgLineStroke} stroke={lineColor} x1={i * sectionWidth} y1={0} x2={i * sectionWidth} y2={bottomInnerGraphEnd} />)
            } else if (numHorizontalLabels > 0 && i % numSectionsBetweenHorizontalLabels === 0) {
                arr.push(<Line key={`ver-line-${i}`} {...bgLineStroke} stroke={lineColor} x1={i * sectionWidth} y1={0} x2={i * sectionWidth} y2={bottomOutterGraphEnd} />)
            } else {
                arr.push(<Line key={`ver-line-${i}`} {...bgLineStroke} stroke={lineColor} x1={i * sectionWidth} y1={0} x2={i * sectionWidth} y2={bottomInnerGraphEnd} />)
            }
        }
        /* #endregion */

        /* #region  Horizontal Lines */
        let vValFormat = ".2f";
        if (graphUnitInformation.unitStr === "%") {
            vValFormat = ".0f";
        } else { // check if fractions are required for the numbers here.
            let needsFractions = false;
            let vLineY, vValTemp;
            for (let i = -1; i < numVerticalSeparations + 1; i++) {
                vLineY = i * sectionHeight + innerLineOffset;
                vValTemp = roundOutTinyErrors(mapGraphValueToPixelSpace(bottomInnerGraphEnd, 0, lowerGraphLimit, upperGraphLimit, vLineY));
                needsFractions = needsFractions || Math.round((vValTemp / graphUnitInformation.divisor % 1) * 100) > 0;
            }
            if (!needsFractions) {
                vValFormat = ".0f";
            }
        }
        let lowestLineY = Infinity;
        for (let i = -1; i < numVerticalSeparations + 1; i++) {
            const lineY = i * sectionHeight + innerLineOffset;
            if (lineY >= 0 && lineY < lowestLineY) { lowestLineY = lineY }
            const value = roundOutTinyErrors(mapGraphValueToPixelSpace(bottomInnerGraphEnd, 0, lowerGraphLimit, upperGraphLimit, lineY));
            const formattedValue = lxFormat(graphUnitInformation.valFormat.replace(/\.\df/, vValFormat), value / graphUnitInformation.divisor, true);

            if (bottomInnerGraphEnd - lineY > 10 && lineY >= 0) {
                addHorizontalLine({ arr, idx: i, noText: bottomInnerGraphEnd - lineY < 20, color: datapoints.length > 0 ? 'rgba(235, 235, 245, 0.6)' : lineColor, value: formattedValue, isZeroLine: value === 0, lineY, rightInnerGraphEnd, rightOutterGraphEnd, width });
            }
        }
        addHorizontalLine({ arr, idx: 'topline-Y0', noText: lowestLineY < 20, color: datapoints.length > 0 ? 'rgba(235, 235, 245, 0.6)' : lineColor, value: lxFormat(graphUnitInformation.valFormat.replace(/\.\df/, vValFormat), upperGraphLimit / graphUnitInformation.divisor, true), isZeroLine: upperGraphLimit === 0, lineY: 0, rightInnerGraphEnd, rightOutterGraphEnd, width });
        addHorizontalLine({ arr, idx: 'topline', color: datapoints.length > 0 ? 'rgba(235, 235, 245, 0.6)' : lineColor, value: lxFormat(graphUnitInformation.valFormat.replace(/\.\df/, vValFormat), lowerGraphLimit / graphUnitInformation.divisor, true), isZeroLine: lowerGraphLimit === 0, lineY: bottomInnerGraphEnd, rightInnerGraphEnd, rightOutterGraphEnd, width });
        /* #endregion */

        /* #region  Graphs Drawing */
        if (type === 'bar') {
            createBars({
                arr, datapoints, graphRange,
                rightInnerGraphEnd, bottomInnerGraphEnd,
                lowerGraphLimit, upperGraphLimit,
                width,
            });
        } else if (type === 'bar-side-by-side') {
            createSideBySideBars({
                arr, datapoints, graphRange,
                rightInnerGraphEnd, bottomInnerGraphEnd,
                lowerGraphLimit, upperGraphLimit,
                width,
            });
        } else if (type === 'line') {
            createLines({
                arr, datapoints, graphRange,
                rightInnerGraphEnd, bottomInnerGraphEnd,
                lowerGraphLimit, upperGraphLimit,
                width, forcedColors, forcedTimeRange,
            });
        }
        /* #endregion */

        arr.push(<Line key={`hor-line-zero-thick`} {...bgLineStrokeThick} stroke={lineColor} x1={0} y1={zeroLineY} x2={rightOutterGraphEnd} y2={zeroLineY} />);

        if (markedIndexRef.current !== false) {
            createHighlight({ datapoints, arr, markedIndex: markedIndexRef.current, rightInnerGraphEnd, bottomOutterGraphEnd, lowerGraphLimit, upperGraphLimit, bottomInnerGraphEnd, graphRange, width, forcedTimeRange: type !== 'line' ? false : forcedTimeRange })
        }

        /* #region  Bottom Labels */
        const labelAreaWidth = sectionWidth * numSectionsBetweenHorizontalLabels;
        for (let i = 0; i < numHorizontalSeparations; i++) {
            if (i % numSectionsBetweenHorizontalLabels === 0) {
                const labelIdx = i / numSectionsBetweenHorizontalLabels
                arr.push(<Text
                    key={`ver-label-${labelIdx}`}
                    stroke="none"
                    fill="#828288"
                    x={labelAreaWidth * (labelIdx + (bottomLabelsCentered ? 0.5 : 0.06))}
                    textAnchor={bottomLabelsCentered ? 'middle' : 'start'}
                    y={bottomInnerGraphEnd + 11}
                    fontSize={globalStyles.fontSettings.sizes.tiny}
                    dominantBaseline="middle"
                    fontFamily={globalStyles.fontSettings.families.bold} // Only set weight via family
                >{horizontalLabels[labelIdx]}</Text>)
            }
        }
        /* #endregion */

        return arr;
    }, [JSON.stringify(datapoints), markedIndexRef.current, width, height, horizontalLabels, type, fullStretch, label, lineColor])

    return (
        <View style={[style, fullStretch ? { flex: 1 } : {}]}>
            {
                titleLeft || titleRight || onZoom ? (
                    <View key={"headerCntr"} style={[styles.headingBar, { width: (100 * (1 - labelWidthPercent)).toFixed(4) + '%', opacity: (markedIndexRef.current !== false) ? 0 : 1 }]}>
                        <RNText style={styles.heading} key={"titleLeft"}>{titleLeft}</RNText>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <RNText style={styles.heading} key={"titleRight"}>{titleRight}</RNText>
                            {
                                typeof onZoom === 'function' ? (
                                    <TouchableOpacity style={{ width: 20, height: 20, marginLeft: 18 }} onPress={onZoom}>
                                        <Icons.ExpandArrows />
                                    </TouchableOpacity>
                                ) : null
                            }
                        </View>
                    </View>
                ) : null
            }
            <Pills
                datapoints={datapoints}
                markedIndex={markedIndexRef.current}
                forcedTimeRange={type !== 'line' ? false : forcedTimeRange}
                rightInnerGraphEnd={rightInnerGraphEnd}
                bottomInnerGraphEnd={bottomInnerGraphEnd}
                dateFormat={highlightDateFormat}
                valueFormat={`%.2f ${label}`}
                title={customPillTitle}
            />
            <View
                    style={fullStretch ? { flex: 1 } : {}}
                    key={'aspectRatioSvgCnt'}
                    {...panResponder.panHandlers}
                    onMouseEnter={touchResponderStart}
                    onMouseMove={touchResponderMove}
                    onMouseLeave={touchEndResponder}
                    ref={viewRef}
                >
                    <AspectRatioSvg
                        onSize={setSize}
                        stretchInstead={fullStretch}
                        fixedHeight={200}
                    >
                        {svgChildren}
                    </AspectRatioSvg>
                </View>
            {
                Array.isArray(legend) && legend.length > 0 ? (
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 8 }}>
                        {legend.map((l, idx) => (
                            <View key={`legend_${idx}`} style={{
                                marginRight: idx !== legend.length - 1 ? 16 : 0,
                                alignItems: 'center',
                                flexDirection: 'row',
                            }}>
                                <View style={{
                                    width: 8,
                                    height: 8,
                                    borderRadius: 4,
                                    backgroundColor: l.color,
                                    marginRight: 8,
                                    marginTop: 1,
                                }} />
                                <LxReactText style={{
                                    color: 'white',
                                    fontSize: globalStyles.fontSettings.sizes.small,
                                    lineHeight: 17.3,
                                }}>{l.label}</LxReactText>
                            </View>
                        ))}
                    </View>
                ) : null
            }
        </View>
    )
}

Graph.NO_DATA_VALUE = undefined;

Graph.propTypes = {
    type: PropTypes.oneOf(['bar', 'bar-side-by-side', 'line']).isRequired,
    horizontalLabels: PropTypes.array.isRequired,
    datapoints: PropTypes.array.isRequired,
    label: PropTypes.string,
    fixedLowerLimit: PropTypes.number,
    fixedUpperLimit: PropTypes.number,
    highlightFullGraph: PropTypes.bool,
};

export const LoadingGraph = ({ titleLeft, fullStretch, ...props }) => {
    return (
        <Graph
            type="bar"
            horizontalLabels={[]}
            datapoints={[]}
            label=""
            titleLeft={titleLeft}
            fullStretch={fullStretch}
            {...props}
        />
    )
}

const ThrottledGraph = (param) => {
    const [tick, setTick] = useState(false);

    const debouncedTicker = debounce(
        () => setTick(!tick),
        30000,
        {
            maxWait: 30000,
            leading: true,
            trailing: true
        }
    );

    useMemo(() => debouncedTicker(), [param])

    const ThrottledGraph = useMemo(() => <Graph {...param} />,
        [tick]
    )

    return (
        <>
            {ThrottledGraph}
        </>
    );
}

export { mapGraphValueToPixelSpace };

export default ThrottledGraph
