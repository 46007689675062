import React, { Component, useMemo } from "react";
import {
    View,
    StyleSheet,
    TouchableOpacity,
} from "react-native";
import Svg, { Circle } from "react-native-svg"

import globalStyles from "GlobalStyles";

import LxReactImageView from "../LxReactImageView";
import Icons from "IconLib";

const circleSize = 72;
const circleRadius = circleSize / 2;

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        backgroundColor: "transparent"
    },
    Icon: {
        fill: globalStyles.colors.text.primary,
        width: globalStyles.sizes.icons.regular,
        height: globalStyles.sizes.icons.regular,
        backgroundColor: "transparent",
        position: 'absolute',
        left: circleRadius - (globalStyles.sizes.icons.regular / 2),
        top: circleRadius - (globalStyles.sizes.icons.regular / 2)
    }
});

export default ({icon, onClick, onLongPress, hasChildren, positivity = 0, style, groupHeading, useCircuitBreaker = false}) => {
    const iconComp = useMemo(() => {
        if (typeof icon !== 'string' || icon.length === 0) {
            if (useCircuitBreaker) {
                return <Icons.CircuitBreaker style={styles.Icon} />
            } else {
                return <Icons.Flash style={styles.Icon} />
            }
        } else {
            return <LxReactImageView
                source={icon}
                containerStyle={styles.Icon}
            />
        }
    }, [icon])

    // ClipPaths must have unique IDs otherwise when the initial loaded clippath is unmounted, it isn't available to
    // the others either.
    const clipPathId = useMemo(() => {
        return "energyIconClippy-" + getRandomIntInclusive(0, 99999);
    }, []);

    const clipPath = useMemo(() => {
        const iconWidth = 80;
        let clipPathParts = [];

        // rect covering the whole icon
        clipPathParts.push(`M0 0 h${iconWidth} v${circleSize} h-${iconWidth}`);

        // circle that will actually do the clipping.
        clipPathParts.push(`M 0, ${circleRadius}`);
        clipPathParts.push(`a${circleRadius},${circleRadius} 0 1,0 ${circleSize},0`);
        clipPathParts.push(`a${circleRadius},${circleRadius} 0 1,0 -${circleSize},0`);

        return clipPathParts.join(" ");
    }, []);

    return (
        <View style={StyleSheet.flatten([styles.container, style, {}])}>
            <TouchableOpacity onPress={() => {onClick && onClick()}} disabled={!onClick && !onLongPress} onLongPress={onLongPress}>
                <Svg width={80} height={72} style={{width: 80, height: 72}}>
                    {hasChildren && !groupHeading ?
                        <Circle cx={circleRadius + 11}
                                cy={circleRadius}
                                r={circleRadius * 0.8888888889}
                                stroke="rgba(151, 151, 151, 1)"
                                strokeWidth="2"
                                clipPath={"url(#" + clipPathId + ")"}
                                fill={globalStyles.colors.ambientBg}/>
                        : null }
                    <Circle
                        cx={circleRadius} cy={circleRadius}
                        r={circleRadius - 1.5}
                        fill={globalStyles.colors.ambientBg}
                        strokeWidth={3}
                        stroke={positivity == 0 ? 'rgba(235, 235, 245, 0.6)' : positivity < 0 ? globalStyles.colors.orange : globalStyles.colors.green}
                    />
                    <clipPath id={clipPathId}>
                        <path d={clipPath}/>
                    </clipPath>
                </Svg>
                {
                   iconComp
                }
            </TouchableOpacity>
        </View>
    );
}
