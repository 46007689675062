'use strict';

define(["ScrollView", "IndicatorView", "BarChartView", "DigitalChartView", "LineChartView", "StepChartView"], function (ScrollView, IndicatorView, BarChartView, DigitalChartView, LineChartView, StepChartView) {
    /**
     * ChartView
     * @param delegate
     * @param dataSource
     * @param chartType 0 = LineChart, 1 = Digital, 2 = BarChart
     * @param frequency statistic frequency
     * @param valueFormat format or info object for digital!
     * @returns {{}} API
     * @constructor
     */
    return function ChartView(delegate, dataSource, chartType, frequency, valueFormat, noEntryDefine) {
        let weakThis = this;
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");
        var scrollView = new ScrollView(canvas),
            indicatorView,
            subChartView;
        /*
         Constants go here!
         */
        // Time

        var sPerDay = 86400,
            sPerHour = 3600,
            sPerMinute = 60,
            REQUEST_MONTH_FORMAT = "YYYYMM",
            REQUEST_YEAR_FORMAT = "YYYY";
        var timeObject, minimumTime, miniserverTime; // Bubble

        var bubbleBGColor = Color.STATISTIC_BUBBLE_BG,
            bubbleFontSize = 14,
            bubbleFont = bubbleFontSize + "px" + FONT.Regular,
            bubbleFontColor = Color.TEXT_A,
            bubbleTextWidthPadding = 6,
            bubbleTextHeightPadding = 3,
            bubbleCornerRadius = 3; // Timeline

        var timeLineHeight = 40,
            timeLineFontSize = 14,
            timeLineFont = timeLineFontSize + "px" + FONT.Medium,
            timeLineColor = Color.TIME_LINE_VALUE_INDICATORS,
            timeLineFontColor = Color.TEXT_A,
            timeLineDashHeightSmall = 3,
            timeLineDashHeightBig = 5; // Y-Axis

        var yAxisFontSize = 14,
            yAxisFont = yAxisFontSize + "px" + FONT.Medium,
            yAxisFontColor = Color.TEXT_SECONDARY_A;
        var userInteraction = false; // if user did interact (scroll)

        var reloadRangeFaktor = 2; // 2 "viewports" to left, 2 to right!

        /*
         Variables go here!
         */
        // all info about the data

        var data = {
            range: Statistic.DataRange.DAY,
            upToDate: false,
            source: [],
            requests: {},
            min: NaN,
            max: NaN,
            steps: []
        }; // Pixel Variables

        var ui = {
            canvasWidth: NaN,
            canvasHeight: NaN,
            pixelPerSecond: NaN,
            secondsPerPixel: NaN,
            yPixelPerUnit: NaN,
            topOffset: 20,
            bottomOffset: NaN
        }; // Time Variables

        var time = {
            middle: 0,
            left: 0,
            right: 0
        };
        /*
         Helpers go here!
         */

        var Helper = {
            /**
             * searches the index of the next data point for a given timestamp, starting at given index searching up or downwards
             * @param ts
             * @param startIdx
             * @param searchUpward
             * @returns {number}
             */
            findIndexOfDataPointForTimeStamp: function findIndexOfDataPointForTimeStamp(ts, startIdx, searchUpward) {
                if (isNaN(startIdx)) {
                    startIdx = data.source.length - 1;
                    searchUpward = false;
                }

                var otherIdx;

                if (searchUpward === true) {
                    while (data.source[startIdx]) {
                        if (data.source[startIdx].ts >= ts) {
                            // check if previous ts is narrower

                            /*if (data.source[startIdx - 1]) {
                             if ((ts - data.source[startIdx - 1].ts) <= (data.source[startIdx].ts - ts)) {
                             return startIdx - 1;
                             }
                             }*/
                            otherIdx = startIdx - 1;

                            while (data.source[otherIdx]) {
                                if (data.source[otherIdx].ts <= ts) {
                                    if (ts - data.source[otherIdx].ts < data.source[startIdx].ts - ts) {
                                        return otherIdx;
                                    } else {
                                        return startIdx;
                                    }
                                }

                                otherIdx--;
                            }

                            return startIdx;
                        }

                        startIdx++;
                    } // last datapoint is left from ts, take last one!


                    return data.source.length - 1;
                } else if (searchUpward === false) {
                    while (data.source[startIdx]) {
                        if (data.source[startIdx].ts <= ts) {
                            // check if previous ts is narrower

                            /*if (data.source[startIdx + 1]) {
                             if ((data.source[startIdx + 1].ts - ts) <= (ts - data.source[startIdx].ts)) {
                             return startIdx + 1;
                             }
                             }*/
                            otherIdx = startIdx + 1;

                            while (data.source[otherIdx]) {
                                if (data.source[otherIdx].ts >= ts) {
                                    if (data.source[otherIdx].ts - ts < ts - data.source[startIdx].ts) {
                                        return otherIdx;
                                    } else {
                                        return startIdx;
                                    }
                                }

                                otherIdx++;
                            }

                            return startIdx;
                        }

                        startIdx--;
                    } // first datapoint is right from ts, take first one!


                    return 0;
                }

                return NaN;
            },

            /**
             * calculates the y axis pixel for a value
             * @param v value
             * @returns {number}
             */
            getYForValue: function getYForValue(v) {
                return Math.floor((data.max - v) * ui.yPixelPerUnit + ui.topOffset); // floor not necessary here!
            },

            /**
             * calculates the x axis pixel for a timestamp
             * @param ts timestamp in seconds
             * @returns {number}
             */
            getXForTimestamp: function getXForTimestamp(ts) {
                return Math.floor((ts - time.left) * ui.pixelPerSecond); // floor seems to be neccessary since pixel bug on iOS (retina?)
            },

            /**
             * returns seconds for deltaPixel range
             * @param deltaPixels
             * @returns {number}
             */
            getSecondsFromDelta: function getSecondsFromDelta(deltaPixels) {
                return Math.floor(deltaPixels * ui.secondsPerPixel);
            },

            /**
             * returns the given days in seconds
             * @param d
             * @returns {number}
             */
            getSecondsForDays: function getSecondsForDays(d) {
                return d * sPerDay;
            },

            /**
             * returns the given seconds in days
             * @param s
             * @returns {number}
             */
            getDaysForSeconds: function getDaysForSeconds(s) {
                return s / sPerDay;
            },

            /**
             * returns the date for a timestamp with format DD.MM.YYYY
             * @param ts
             * @returns {*}
             */
            getTextForTimeStamp: function getTextForTimeStamp(ts) {
                var diff = time.middle - ts;
                timeObject.subtract(diff, "seconds");
                var string = timeObject.format("DD.MM.YYYY"); // needs to be in this format

                timeObject.add(diff, "seconds");
                return string;
            },

            /**
             * measures the text and returns it's size
             * @param t text to measure
             * @returns {{width: number}}
             */
            getBubbleSizeWithText: function getBubbleSizeWithText(t) {
                var size = ctx.measureText(t);
                return {
                    width: size.width + 2 * bubbleTextWidthPadding,
                    height: bubbleFontSize + 2 * bubbleTextHeightPadding
                };
            },

            /**
             * returns hour for the timestamp
             * @param ts % 3600 must be 0!
             * @returns {number}
             */
            getHourForTimeStamp: function getHourForTimeStamp(ts) {
                var hour = ts % 86400 / 3600; // first get rest of day modulo, then calc hour

                if (hour > 0) {
                    return hour;
                }

                return 24;
            },

            /**
             * returns second for the timestamp
             * @param ts % 1 must be 0!
             * @returns {number}
             */
            getSecondForTimeStamp: function getSecondForTimeStamp(ts) {
                var hour = ts % 86400 % 3600 / 60; // first get rest of day modulo, then rest of hour modulo, then calc minute

                if (hour > 0) {
                    return hour;
                }

                return 60;
            },

            /**
             * formats the timestamp with the format
             * @param ts
             * @param format
             * @returns {string}
             */
            formatTimeStamp: function formatTimeStamp(ts, format) {
                // we use the timeObject (with time.middle) to get the formatted string!
                var d = ts - timeObject.unix();

                if (d !== 0) {
                    timeObject.add(d, "seconds");
                }

                var t = timeObject.format(format);

                if (d !== 0) {
                    timeObject.add(-d, "seconds");
                }

                return t;
            },

            /**
             * rounds the number up to the given value
             * (17, 100) -> 100
             * (27234, 1000) -> 28000
             * @param nr number to round
             * @param to round to this number
             * @returns {number} rounded number
             */
            roundUpTo: function roundUpTo(nr, to) {
                return Math.ceil(nr / to) * to;
            },

            /**
             * rounds the number down to the given value
             * (17, 100) -> 0
             * (27234, 1000) -> 27000
             * @param nr number to round
             * @param to round to this number
             * @returns {number} rounded number
             */
            roundDownTo: function roundDownTo(nr, to) {
                return Math.floor(nr / to) * to;
            },

            /**
             * Iterates over each visible data point from a given start index in a given direction, the current data point
             * and index are available in the callback function
             * @param dir Direction.LEFT is the fallback value if an invalid direction is provided
             * @param startIdx The index to start the iteration from
             * @param clbFn Callback function
             *                  Arguments: dataPoint, idx
             *                  Note: The iteration will be aborted if the callback function returns "false"
             * @returns {Number} The last index
             */
            forEachVisibleDataPoint: function forEachVisibleDataPoint(dir, startIdx, clbFn) {
                var clbRes,
                    idx,
                    idxModifier,
                    dpIsOutOfBoundary = false;
                startIdx = startIdx != null ? startIdx : data.source.middleIndex;

                if (dir !== Direction.LEFT && dir !== Direction.RIGHT) {
                    console.warn("Invalid direction, only 'Direction.LEFT' or 'Direction.RIGHT' are allowed! Defaulting to 'Direction.LEFT'");
                    dir = Direction.LEFT;
                }

                if (dir === Direction.LEFT) {
                    idxModifier = -1;
                } else {
                    idxModifier = 1;
                }

                idx = startIdx;

                while (idx >= 0 && idx < data.source.length && // Stay inside the boundary of the data source
                !dpIsOutOfBoundary && // Don't iterate past the screen boundary
                clbRes !== false // Abort the iteration if the last clbResult is false
                    ) {
                    if (!data.source[idx].isNoDataEntry) {
                        clbRes = clbFn(data.source[idx], idx);
                    } else {
                        clbRes = clbFn({
                            ts: data.source[idx].ts,
                            // Why data.min or 0?
                            //      NoDataEntries should not be visible to the user, this can be achieved by setting the value
                            //      to either 0 if the minimal value is less then 0, or to minimal value if not.
                            value: data.min > 0 ? data.min : 0,
                            isNoDataEntry: data.source[idx].isNoDataEntry
                        }, idx);
                    }

                    if (dir === Direction.LEFT) {
                        dpIsOutOfBoundary = data.source[idx].ts <= time.left;
                    } else {
                        dpIsOutOfBoundary = data.source[idx].ts >= time.right;
                    }

                    if (!dpIsOutOfBoundary) {
                        idx += idxModifier;
                    }
                }

                return idx;
            },

            /**
             * Draws a small triangle that points towards the point in a given color with an optional label
             * This function is used for debugging
             * @param x
             * @param y
             * @param color
             * @param [label]
             */
            markPointWithColorAndLabel: function markPointWithColorAndLabel(x, y, color, label) {
                ctx.fill();
                ctx.fillStyle = color;
                ctx.beginPath();
                ctx.moveTo(x, y);
                ctx.lineTo(x - 5, y - 10);
                ctx.lineTo(x + 5, y - 10);
                ctx.closePath();

                if (label) {
                    ctx.font = "15px Averta-Regular";
                    ctx.fillStyle = color;
                    ctx.textAlign = "center";
                    ctx.fillText(label, x, y - 12);
                }
            }
        };
        /*
         Enums go here!
         */

        var ResolutionType = {
            RT1MIN: {
                type: "RT1MIN",
                dateType: DateType.WeekdayAndDate,
                hideSeconds: false,
                timeline: {
                    steps: [0, 0, 1, 0, 0, 2],
                    first: function (t) {
                        t.startOf("minute");
                        if (chartType === Statistic.Type.BAR_CHART) t.add(500, "millisecond");
                    },
                    step0: function (t) {
                        t.add(10, "second");
                    },
                    step1: function (t) {
                        t.add(10, "second");
                    },
                    step2: function (t) {
                        t.add(10, "second");
                        return t.format(LxDate.getTimeFormat(true));
                    },
                    last: function (t) {
                        t.endOf("minute");
                    }
                },
                bubbles: {
                    type: "day",
                    dateType: DateType.ShortWeekdayAndDate,
                    first: function (ts) {
                        return Helper.roundDownTo(ts, sPerDay);
                    },
                    nextOffset: function () {
                        return sPerDay;
                    }
                }
            },
            RT5MIN: {
                type: "RT5MIN",
                dateType: DateType.WeekdayAndDate,
                hideSeconds: true,
                timeline: {
                    steps: [0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
                    first: function (t) {
                        t.startOf("hour");
                        if (chartType === Statistic.Type.BAR_CHART) t.add(30, "second");
                    },
                    step0: function (t) {
                        t.add(1, "minute");
                    },
                    step1: function (t) {
                        t.add(1, "minute");
                        return t.format(LxDate.getTimeFormat(true));
                    },
                    last: function (t) {
                        t.endOf("hour");
                    }
                },
                bubbles: {
                    type: "day",
                    dateType: DateType.ShortWeekdayAndDate,
                    first: function (ts) {
                        return Helper.roundDownTo(ts, sPerDay);
                    },
                    nextOffset: function () {
                        return sPerDay;
                    }
                }
            },
            RT10MIN: {
                type: "RT10MIN",
                dateType: DateType.WeekdayAndDate,
                hideSeconds: true,
                timeline: {
                    steps: [0, 0, 0, 0, 1, 0, 0, 0, 0, 2],
                    first: function (t) {
                        t.startOf("hour");
                        if (chartType === Statistic.Type.BAR_CHART) t.add(30, "second");
                    },
                    step0: function (t) {
                        t.add(1, "minute");
                    },
                    step1: function (t) {
                        t.add(1, "minute");
                    },
                    step2: function (t) {
                        t.add(1, "minute");
                        return t.format(LxDate.getTimeFormat(true));
                    },
                    last: function (t) {
                        t.endOf("hour");
                    }
                },
                bubbles: {
                    type: "day",
                    dateType: DateType.ShortWeekdayAndDate,
                    first: function (ts) {
                        return Helper.roundDownTo(ts, sPerDay);
                    },
                    nextOffset: function () {
                        return sPerDay;
                    }
                }
            },
            RT1H: {
                type: "RT1H",
                dateType: DateType.WeekdayAndDate,
                hideSeconds: true,
                timeline: {
                    steps: chartType === Statistic.Type.BAR_CHART ? [3] : [0, 0, 1, 0, 0, 2],
                    first: function (t) {
                        t.startOf("hour");
                        if (chartType === Statistic.Type.BAR_CHART) t.subtract(30, "minute");
                    },
                    step0: function (t) {
                        t.add(10, "minute");
                    },
                    step1: function (t) {
                        t.add(10, "minute");
                    },
                    step2: function (t) {
                        t.add(10, "minute");
                        return t.format(LxDate.getTimeFormat(true, true));
                    },
                    step3: function (t) {
                        t.add(1, "hour");
                        return t.format(LxDate.getTimeFormat(true, true));
                    },
                    last: function (t) {
                        t.endOf("hour");
                    }
                },
                bubbles: {
                    type: "day",
                    dateType: DateType.ShortWeekdayAndDate,
                    first: function (ts) {
                        return Helper.roundDownTo(ts, sPerDay);
                    },
                    nextOffset: function () {
                        return sPerDay;
                    }
                }
            },
            RT3H: {
                type: "RT3H",
                dateType: DateType.WeekdayAndDate,
                hideSeconds: true,
                timeline: {
                    steps: chartType === Statistic.Type.BAR_CHART ? [3, 3, 4] : [0, 1, 0, 1, 0, 2],
                    first: function (t) {
                        t.startOf("day");
                        if (chartType === Statistic.Type.BAR_CHART) t.add(30, "minute");
                    },
                    step0: function (t) {
                        t.add(30, "minute");
                    },
                    step1: function (t) {
                        t.add(30, "minute");
                    },
                    step2: function (t) {
                        t.add(30, "minute");
                        return t.format(LxDate.getTimeFormat(true, true));
                    },
                    step3: function (t) {
                        t.add(1, "hour");
                    },
                    step4: function (t) {
                        t.add(1, "hour");
                        return t.format(LxDate.getTimeFormat(true, true));
                    },
                    last: function (t) {
                        t.endOf("hour");
                    }
                },
                bubbles: {
                    type: "day",
                    dateType: DateType.ShortWeekdayAndDate,
                    first: function (ts) {
                        return Helper.roundDownTo(ts, sPerDay);
                    },
                    nextOffset: function () {
                        return sPerDay;
                    }
                }
            },
            RT6H: {
                type: "RT6H",
                dateType: DateType.WeekdayAndDate,
                hideSeconds: true,
                timeline: {
                    steps: [0, 0, 1, 0, 0, 2],
                    first: function (t) {
                        t.startOf("day");
                        if (chartType === Statistic.Type.BAR_CHART) t.add(30, "minute");
                    },
                    step0: function (t) {
                        t.add(1, "hour");
                    },
                    step1: function (t) {
                        t.add(1, "hour");
                    },
                    step2: function (t) {
                        t.add(1, "hour");
                        return t.hour();
                    },
                    last: function (t) {
                        t.endOf("hour");
                    }
                },
                bubbles: {
                    type: "day",
                    dateType: DateType.ShortWeekdayAndDate,
                    first: function (ts) {
                        return Helper.roundDownTo(ts, sPerDay);
                    },
                    nextOffset: function () {
                        return sPerDay;
                    }
                }
            },
            RT12H: {
                type: "RT12H",
                dateType: DateType.WeekdayAndDate,
                hideSeconds: true,
                ignoreMinutes: true,
                timeline: {
                    steps: [0, 0, 1, 0, 0, 2],
                    first: function (t) {
                        t.startOf("day");
                    },
                    step0: function (t) {
                        t.add(2, "hour");
                    },
                    step1: function (t) {
                        t.add(2, "hour");
                    },
                    step2: function (t) {
                        t.add(2, "hour");
                        return t.hour();
                    },
                    last: function (t) {
                        t.endOf("hour");
                    }
                },
                bubbles: {
                    type: "day",
                    dateType: DateType.Date,
                    first: function (ts) {
                        return Helper.roundDownTo(ts, sPerDay);
                    },
                    nextOffset: function () {
                        return sPerDay;
                    }
                }
            },
            RT1D: {
                type: "RT1D",
                dateType: DateType.WeekdayAndDate,
                hideSeconds: true,
                ignoreMinutes: true,
                timeline: {
                    steps: [1],
                    first: function (t) {
                        t.startOf("day");
                        t.subtract(1, "day");
                        if (chartType === Statistic.Type.BAR_CHART) t.add(12, "hour");
                    },
                    step1: function (t) {
                        t.add(1, "day");
                        return t.format(LxDate.getDateFormat(DateType.ShortWeekday)); // DD.
                    },
                    last: function (t) {
                        t.endOf("day");
                    }
                },
                bubbles: {
                    type: "month",
                    dateType: DateType.MonthText,
                    first: function (ts) {
                        return moment.utc(ts * 1000).startOf("month").unix();
                    },
                    nextOffset: function (ts) {
                        var offset = moment.utc(ts * 1000);
                        offset.add(1, "month");
                        return offset.unix() - ts;
                    }
                }
            },
            RT5D: {
                type: "RT5D",
                dateType: DateType.WeekdayAndDate,
                noTime: true,
                timeline: {
                    steps: [1, 2, 2, 2, 2, 3],
                    first: function (t) {
                        t.startOf("month");
                        if (chartType === Statistic.Type.BAR_CHART) t.add(12, "hour");
                    },
                    step0: function (t) {
                        return t.format(LxDate.getDateFormat(DateType.Day));
                    },
                    step1: function (t) {
                        t.add(4, "day");
                        return t.format(LxDate.getDateFormat(DateType.Day));
                    },
                    step2: function (t) {
                        t.add(5, "day");
                        return t.format(LxDate.getDateFormat(DateType.Day));
                    },
                    step3: function (t) {
                        t.add(1, "month");
                        t.startOf("month");
                        if (chartType === Statistic.Type.BAR_CHART) t.add(12, "hour");
                        return t.format(LxDate.getDateFormat(DateType.Day));
                    },
                    last: function (t) {
                        t.endOf("day");
                    }
                },
                bubbles: {
                    type: "month",
                    dateType: DateType.MonthText,
                    first: function (ts) {
                        return moment.utc(ts * 1000).startOf("month").unix();
                    },
                    nextOffset: function (ts) {
                        var offset = moment.utc(ts * 1000);
                        offset.add(1, "month");
                        return offset.unix() - ts;
                    }
                }
            },
            RT1M: {
                type: "RT1M",
                dateType: DateType.DateText,
                noTime: true,
                timeline: {
                    steps: [1],
                    first: function (t) {
                        t.startOf("month");
                        t.subtract(1, "month");
                        if (chartType === Statistic.Type.BAR_CHART) t.add(15, "day"); //t.add(15, "day");
                    },
                    step1: function (t) {
                        t.add(1, "month");
                        return t.format(LxDate.getDateFormat(DateType.MonthText));
                    },
                    step2: function (t) {
                        t.add(14, "day");
                        return t.format(LxDate.getDateFormat(DateType.Day));
                    },
                    step3: function (t) {
                        t.subtract(14, "day");
                        t.add(1, "month");
                    },
                    last: function (t) {
                        t.endOf("month");
                    }
                },
                bubbles: {
                    type: "year",
                    dateType: DateType.Year,
                    first: function (ts) {
                        return moment.utc(ts * 1000).startOf("year").unix();
                    },
                    nextOffset: function (ts) {
                        var offset = moment.utc(ts * 1000);
                        offset.add(1, "year");
                        return offset.unix() - ts;
                    }
                }
            },
            RT1Ms: {
                type: "RT1Ms",
                // short month string
                dateType: DateType.DateText,
                noTime: true,
                timeline: {
                    steps: [1],
                    first: function (t) {
                        t.startOf("month");
                        t.subtract(1, "month");
                        if (chartType === Statistic.Type.BAR_CHART) t.add(15, "day"); //t.add(15, "day");
                    },
                    step1: function (t) {
                        t.add(1, "month");
                        return t.format(LxDate.getDateFormat(DateType.MonthShortText));
                    },
                    step2: function (t) {
                        t.add(14, "day");
                        return t.format(LxDate.getDateFormat(DateType.Day));
                    },
                    step3: function (t) {
                        t.subtract(14, "day");
                        t.add(1, "month");
                    },
                    last: function (t) {
                        t.endOf("month");
                    }
                },
                bubbles: {
                    type: "year",
                    dateType: DateType.Year,
                    first: function (ts) {
                        return moment.utc(ts * 1000).startOf("year").unix();
                    },
                    nextOffset: function (ts) {
                        var offset = moment.utc(ts * 1000);
                        offset.add(1, "year");
                        return offset.unix() - ts;
                    }
                }
            },
            RTY: {
                type: "RTY",
                dateType: DateType.DateText,
                noTime: true,
                timeline: {
                    steps: [1],
                    first: function (t) {
                        t.startOf("month");
                        t.subtract(1, "month");
                        if (chartType === Statistic.Type.BAR_CHART) t.add(15, "day"); //t.add(15, "day");
                    },
                    step1: function (t) {
                        t.add(1, "month");
                    },
                    last: function (t) {
                        t.endOf("month");
                    }
                },
                bubbles: {
                    type: "year",
                    dateType: DateType.Year,
                    first: function (ts) {
                        return moment.utc(ts * 1000).startOf("year").unix();
                    },
                    nextOffset: function (ts) {
                        var offset = moment.utc(ts * 1000);
                        offset.add(1, "year");
                        return offset.unix() - ts;
                    }
                }
            }
        };
        /*
         GUI calculated stuff
         */

        /**
         * returns number of seconds for the x-axis range based on the display width
         * we always calc from 320px width!
         * @returns {number}
         */

        var getRangeForDisplay = function getRangeForDisplay() {
            if (chartType === Statistic.Type.BAR_CHART) {
                return 60 * 60 * 24 * 10 / 320 * canvas.clientWidth; // 10 days for 320 pixels!
                // canvas.clientWidth needed!
            } else {
                return 60 * 60 * 24 * 1.5 / 320 * canvas.clientWidth; // 1,5 days for 320 pixels!
                // canvas.clientWidth needed!
            }
        };
        /**
         * adopts the range to the display width
         * @param r range in seconds
         * @returns {number}
         */


        var adoptRangeForDisplay = function adoptRangeForDisplay(r) {
            return canvas.clientWidth / 320 * r; // canvas.clientWidth needed!
        }; // Range


        var range = {};
        /**
         * calculates the range in every unit based on seconds
         * @param seconds
         */

        var calcRange = function calcRange(seconds) {
            range.seconds = seconds;
            range.hours = seconds / 3600;
            range.days = range.hours / 24;
            calcDataRange();
        };

        var calcDataRange = function () {
            var newDataRange;

            if (range.seconds < adoptRangeForDisplay(sPerMinute * 30)) {
                newDataRange = Statistic.DataRange.MINUTE;
            } else if (range.seconds < adoptRangeForDisplay(sPerDay)) {
                newDataRange = Statistic.DataRange.HOUR;
            } else if (range.seconds < adoptRangeForDisplay(sPerDay * 31)) {
                newDataRange = Statistic.DataRange.DAY;
            } else if (range.seconds < adoptRangeForDisplay(sPerDay * 365 * 2)) {
                newDataRange = Statistic.DataRange.MONTH;
            } else {
                newDataRange = Statistic.DataRange.YEAR;
            } // limit the data range!


            if (frequency > Statistic.Frequency.AVERAGE_1 && newDataRange === Statistic.DataRange.MINUTE) {
                newDataRange = Statistic.DataRange.HOUR;
            }

            if (data.range !== newDataRange) {
                console.info("flipping to range:", newDataRange);
                data.range = newDataRange;

                if (chartType === Statistic.Type.BAR_CHART) {
                    reloadData();
                }
            }
        }; // ResolutionType


        var resolutionType = null;
        /**
         * returns the resolution which is best for the given range
         * @param range in seconds
         */

        var calcResolutionType = function calcResolutionType(range) {
            var isNotBarChart = chartType !== Statistic.Type.BAR_CHART; // BarChart exception: don't use a range which makes no sense (eg. if we have data range hour, don't show minutes -> confuses users)
            // https://www.wrike.com/open.htm?id=56890794

            if (range < adoptRangeForDisplay(sPerMinute * 5) && (isNotBarChart || data.range === Statistic.DataRange.MINUTE)) {
                // from x to 5min
                resolutionType = ResolutionType.RT1MIN;
            } else if (range < adoptRangeForDisplay(sPerMinute * 20) && (isNotBarChart || data.range === Statistic.DataRange.MINUTE)) {
                // from 5min to 20min
                resolutionType = ResolutionType.RT5MIN;
            } else if (range < adoptRangeForDisplay(sPerHour) && (isNotBarChart || data.range === Statistic.DataRange.MINUTE)) {
                // from 20min to 1h
                resolutionType = ResolutionType.RT10MIN;
            } else if (range < adoptRangeForDisplay(sPerHour * 4) && (isNotBarChart || data.range === Statistic.DataRange.HOUR)) {
                // from 1h to 4h
                resolutionType = ResolutionType.RT1H;
            } else if (range < adoptRangeForDisplay(sPerHour * 12) && (isNotBarChart || data.range === Statistic.DataRange.HOUR)) {
                // from 4h to 12h
                resolutionType = ResolutionType.RT3H;
            } else if (range < adoptRangeForDisplay(sPerDay * 2) && (isNotBarChart || data.range === Statistic.DataRange.HOUR)) {
                // from 12h to 2 days
                resolutionType = ResolutionType.RT6H;
            } else if (range < adoptRangeForDisplay(sPerDay * 4) && (isNotBarChart || data.range === Statistic.DataRange.HOUR)) {
                // from 2 days to 4 days
                resolutionType = ResolutionType.RT12H;
            } else if (range < adoptRangeForDisplay(sPerDay * 13) && (isNotBarChart || data.range === Statistic.DataRange.DAY)) {
                // from 4 days to 13 days
                resolutionType = ResolutionType.RT1D;
            } else if (range < adoptRangeForDisplay(sPerDay * 70) && (isNotBarChart || data.range === Statistic.DataRange.DAY)) {
                // from 13 days to 70 days
                resolutionType = ResolutionType.RT5D;
            } else if (range < adoptRangeForDisplay(sPerDay * 150) && (isNotBarChart || data.range === Statistic.DataRange.MONTH)) {
                // from 70 days to 150 days
                resolutionType = ResolutionType.RT1M;
            } else if (range < adoptRangeForDisplay(sPerDay * 365) && (isNotBarChart || data.range === Statistic.DataRange.MONTH)) {
                // from 150 days to 365 days
                resolutionType = ResolutionType.RT1Ms;
            } else {
                // from 365 days to x
                resolutionType = ResolutionType.RTY;
            } //console.log("dataRange:", data.range);
            //console.log("resolutionType:", resolutionType.type);

        };
        /**
         * focuses the given middle timestamp and calculates left and right timestamp and adds the offset to the timeObject!
         * @param timestamp new middle timestamp
         * @param offset
         * @returns {boolean} if timestamp is in the range of datapoints and miniserver time
         */


        var adoptTimeStamps = function adoptTimeStamps(timestamp, offset) {
            var timestampInRange = true;

            if (miniserverTime.unix() < timestamp) {
                var newTimestamp = miniserverTime.unix();
                offset = offset - (timestamp - newTimestamp); // calc the offset until last datapoint!

                timestamp = newTimestamp;
                timestampInRange = false;
            } else if (timestamp < minimumTime) {
                offset = offset - (timestamp - minimumTime); // calc the offset to minimumTime!

                timestamp = minimumTime;
                timestampInRange = false;
            }

            time.middle = Math.floor(timestamp);
            time.left = Math.floor(timestamp - range.seconds / 2);
            time.right = Math.floor(timestamp + range.seconds / 2);
            timeObject.add(offset, "seconds");
            return timestampInRange;
        };
        /**
         * calcs steps between min and max
         * @param {Number} min old min
         * @param {Number} minRaw
         * @param {Number} max old max
         * @param {Number} maxRaw
         * @param {Number} nrOfSteps
         * @returns {{min: Number, max: Number, steps: Number[]}}
         */


        var getStepsBetweenMinAndMax = function getStepsBetweenMinAndMax(min, minRaw, max, maxRaw, nrOfSteps) {
            Debug.Statistic.GUI && console.log("calcing steps for min:", min, "(", minRaw, ") max:", max, "(", maxRaw, "), steps:", nrOfSteps); // TODO-thallth min max doesn't update if changed!

            if (isNaN(minRaw) || isNaN(maxRaw)) return;

            if (minRaw === 0 && maxRaw === 0) {
                minRaw = chartType === Statistic.Type.BAR_CHART ? 0 : -1;
                maxRaw = 1;
            } else if (minRaw === maxRaw) {
                minRaw = 0;
            }

            var currentMin = !isNaN(min) ? min : minRaw;
            var newMin = currentMin,
                newMax; // calculate the steps

            var diff = maxRaw - minRaw;
            var rawStep = diff / (nrOfSteps - 1);
            var friendlyStep = roundStep(rawStep); // this handles the case when eg. min = 0, max = 2, format = %.0f (int) -> we must display proper values: 0,1,2,3,4
            // otherwise the step would be a decimal value, the axis description would be something like 0,0,0,1,1
            // Wrike 99291035

            if (diff < nrOfSteps && valueFormat.indexOf("%.0f") !== -1) {
                friendlyStep = 1;
            }

            var digitsOfFriendlyStep = getDigitsOfValue(friendlyStep);
            var minRemainder = 0;

            if (friendlyStep < 1) {
                minRemainder = Math.roundTowardsZero(currentMin * Math.pow(10, digitsOfFriendlyStep));
                newMin = minRemainder / Math.pow(10, digitsOfFriendlyStep);
            } else {
                minRemainder = Math.roundTowardsZero(currentMin / Math.pow(10, digitsOfFriendlyStep - 1));
                newMin = minRemainder * Math.pow(10, digitsOfFriendlyStep - 1);
            }

            var values = [newMin];

            while (values.length < nrOfSteps) {
                values.push(values[values.length - 1] + friendlyStep);
            }

            newMin = Math.min(newMin, minRaw);
            newMax = Math.max(values[values.length - 1], maxRaw);

            if (newMin !== min || newMax !== max) {
                return {
                    min: newMin,
                    max: newMax,
                    steps: values
                };
            }
        }; // 344 becomes 350, 364 becomes 400, 22 becomes 25, 26 becomes 30


        var roundStep = function roundStep(_step) {
            // say _step is 552
            // get the digits of step, 3 in this case
            var digitsOfStep = getDigitsOfValue(_step);
            var remainderOfStep = 0;
            var result = 0;

            if (_step < 1) {
                remainderOfStep = _step * Math.pow(10, digitsOfStep);

                if (Math.round(remainderOfStep) >= _step * Math.pow(10, digitsOfStep)) {
                    result = Math.round(remainderOfStep) / Math.pow(10, digitsOfStep);
                } else {
                    result = (Math.floor(remainderOfStep) + 0.5) / Math.pow(10, digitsOfStep);
                }
            } else {
                // now get the digits we want to take into consideration for rounding this value  - 52 in this case (the remainder of 552 / 100)
                remainderOfStep = Math.fmod(_step, Math.pow(10, digitsOfStep - 1)); // now let's decide wether we round up to the next decade or up to the mid of the decade (5, 50, 500...)
                // the remainder has 2 digits in this case

                var digitsOfRemainder = getDigitsOfValue(remainderOfStep); // compute the float where only the first digit of the remainder is present for rounding (5,2 in this case)

                var remainder = remainderOfStep / Math.pow(10, digitsOfRemainder - 1); // so now we can decide wether or not to round up or down, depending on the remainder computed before (5,2)
                // in both cases we need to adjust the step so it can be rounded (552 becomes 5,52)

                var tempStep = _step / Math.pow(10, digitsOfStep - 1);

                if (remainder >= 5) {
                    // round up to the next decade -> 5,52 becomes 6,00
                    tempStep = Math.ceil(tempStep);
                } else {
                    // round up to the mid of the "decade" (say the step'd be 5,45 - which it isn't in our example, it'd become 5,5)
                    tempStep = Math.floor(tempStep) + 0.5;
                } // now we need to recompute the step so it has all its digits = 6,00 * 100 = 600


                result = tempStep * Math.pow(10, digitsOfStep - 1);
            }

            return result;
        };

        var getDigitsOfValue = function getDigitsOfValue(val) {
            return (parseFloat(val) + "").split(".")[0].length;
        };

        var updatePixelVariables = function updatePixelVariables() {
            ui.pixelPerSecond = ui.canvasWidth / range.seconds;
            ui.secondsPerPixel = Math.round(range.seconds / ui.canvasWidth);
        };

        var recalculateGUIVariables = function recalculateGUIVariables() {
            // order matters!
            ui.canvasWidth = canvas.clientWidth;
            ui.canvasHeight = canvas.clientHeight;
            ui.bottomOffset = ui.canvasHeight - timeLineHeight;
            adoptTimeStamps(timeObject.unix(), 0);
            calcResolutionType(time.right - time.left);
            updatePixelVariables(); // don't do this here! (not neccessary on zooming!)

            if (chartType === Statistic.Type.DIGITAL) {
                data.min = data.minRaw;
                data.max = data.maxRaw;
                data.steps = [data.min, data.max];
            } else {
                var res = getStepsBetweenMinAndMax(data.min, data.minRaw, data.max, data.maxRaw, 5);

                if (res) {
                    // we have new steps!
                    data.min = res.min;
                    data.max = res.max;
                    data.steps = res.steps;
                }
            }

            ui.yPixelPerUnit = (ui.canvasHeight - ui.topOffset - timeLineHeight) / (data.max - data.min); // actual grap draw height / (max - min)!
        };

        var scrollingLeft = false,
            scrollingRight = false;
        /**
         * searches the middle index
         * @param dir searchs in this direction if given (speeds up!)
         */

        var findMiddleIndex = function findMiddleIndex(dir) {
            Debug.Statistic.DataTimes && console.time("searchMiddle to " + dir);

            if (isNaN(data.source.middleIndex)) {
                data.source.middleIndex = Helper.findIndexOfDataPointForTimeStamp(time.middle, data.source.length - 1, false); // search and begin at the end moving down
            } else {
                if (dir === "left") {
                    data.source.middleIndex = Helper.findIndexOfDataPointForTimeStamp(time.middle, data.source.middleIndex, false); // search and begin at last index moving down
                } else if (dir === "right") {
                    data.source.middleIndex = Helper.findIndexOfDataPointForTimeStamp(time.middle, data.source.middleIndex, true); // search and begin at last index moving up
                }
            }

            if (typeof data.source[data.source.middleIndex] === "undefined") {
                // if no data is available at this index, set to NaN -> displays: no data
                data.source.middleIndex = NaN;
            }

            Debug.Statistic.GUIDetailed && console.log("middleIndex ", data.source.middleIndex);
            Debug.Statistic.DataTimes && console.timeEnd("searchMiddle to " + dir);
        };

        var scroll = function scroll(offsetX, deltaX) {
            userInteraction = true;
            scrollingLeft = deltaX > 0;
            scrollingRight = deltaX < 0;
            Debug.Statistic.GUIDetailed && console.log("scrolling " + (scrollingLeft ? "left" : "right") + " (" + deltaX + "px)");
            var offset = Helper.getSecondsFromDelta(deltaX);
            adoptTimeStamps(time.middle - offset, offset * -1);
            findMiddleIndex(scrollingLeft ? "left" : scrollingRight ? "right" : null);
            draw();
        };

        var scrollTimeout;

        var focusTimestamp = function focusTimestamp(timestamp, doNotAnimate) {
            var centerX = Helper.getXForTimestamp(time.middle);
            var diff = centerX - Helper.getXForTimestamp(timestamp);

            if (diff !== 0 && !doNotAnimate) {
                var scrollTime = 200; // 200 ms

                var fps = 120,
                    fpMs = fps / 1000;
                var frames = scrollTime * fpMs,
                    renderRate = scrollTime / frames;
                var pixelSteps = diff / frames;
                clearTimeout(scrollTimeout);

                var render = function render() {
                    scroll(0, pixelSteps);
                    frames--;

                    if (frames > 0) {
                        scrollTimeout = setTimeout(render, renderRate);
                    } else {
                        scrollTimeout = null; // focus timestamp exactly!

                        var offset = timestamp - time.middle;
                        adoptTimeStamps(timestamp, offset);
                        draw();
                    }
                };

                render();
            } else {
                // focus timestamp exactly!
                var offset = timestamp - time.middle;

                if (offset !== 0) {
                    adoptTimeStamps(timestamp, offset);
                    draw();
                }
            }
        };

        var draw = function draw() {
            return GUI.animationHandler.schedule(function () {
                Debug.Statistic.DrawTimes && console.time("draw");
                ctx.clearRect(0, 0, ui.canvasWidth, ui.canvasHeight);
                drawTimeLine();

                if (data.source.length) {
                    ctx.fillStyle = window.Styles.colors.green;
                    ctx.lineWidth = 1;
                    ctx.strokeWidth = 1;
                    Debug.Statistic.DrawTimes && console.time("draw SubChartView");
                    ctx.beginPath();
                    subChartView.draw();
                    ctx.closePath();
                    ctx.fill(); //ctx.stroke();

                    Debug.Statistic.DrawTimes && console.timeEnd("draw SubChartView");
                    setIndicatorValue(indicatorView.draw());
                    valueFormat && drawYAxisValues();
                } else {
                    data.check();
                }

                chartType !== Statistic.Type.DIGITAL && drawGrid();
                drawBubbles();
                Debug.Statistic.DrawTimes && console.timeEnd("draw");
            });
        };

        var drawYAxisValues = function () {
            Debug.Statistic.DrawTimes && console.time("drawYAxisValues");
            ctx.save();
            ctx.textAlign = "right";
            ctx.textBaseline = "bottom";
            ctx.font = yAxisFont;
            ctx.fillStyle = yAxisFontColor;

            for (var i = 0; i < data.steps.length; i++) {
                var text;
                /*if (typeof valueFormat === "object") {
                 text = valueFormat[data.steps[i]]; // digital! -> use text at index 0,1
                 } else {*/

                if (lxUnitConverter.isConvertable(valueFormat)) {
                    var splitTexts = lxUnitConverter.convertSplitAndApply(valueFormat, data.steps[i]);
                    text = splitTexts.valueTxt + " " + splitTexts.succTxt;
                } else {
                    text = lxFormat(valueFormat, data.steps[i], true);
                } //}


                text = text.split("&nbsp;").join(" "); // replace the &nbsp; of some formats with a blank. canvas doesn't support it.

                ctx.fillText(text, ui.canvasWidth - 10, Helper.getYForValue(data.steps[i]) - 1); // move up 1px to stay on top of line!
            }

            ctx.restore();
            Debug.Statistic.DrawTimes && console.timeEnd("drawYAxisValues");
        };

        var drawBubbles = function drawBubbles() {
            Debug.Statistic.DrawTimes && console.time("drawBubbles");
            ctx.save();
            ctx.font = bubbleFont;
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            var startTS = resolutionType.bubbles.first(time.left);
            var bubbles = [],
                bubble;

            while (startTS < time.right) {
                bubble = {};
                bubble.x = Helper.getXForTimestamp(startTS);
                bubble.text = Helper.formatTimeStamp(startTS, LxDate.getDateFormat(resolutionType.bubbles.dateType));
                bubble.size = Helper.getBubbleSizeWithText(bubble.text);
                bubbles.push(bubble);
                startTS += resolutionType.bubbles.nextOffset(startTS);
            }

            if (bubbles[0].x < 10) {
                // bubble should be fixed!
                bubbles[0].x = 10;
            }

            if (bubbles.length >= 2) {
                var distance = bubbles[1].x - (bubbles[0].x + bubbles[0].size.width);

                if (distance < 10) {
                    bubbles[0].x += distance - 10;
                }

                if (bubbles[0].x + bubbles[0].size.width <= 0) {
                    bubbles.shift(); // bubble not visible!

                    if (bubbles[0].x < 10) {
                        bubbles[0].x = 10;
                    }
                }
            }

            Debug.Statistic.GUI && console.log("drawing", bubbles.length, "bubbles");

            while (bubbles.length) {
                bubble = bubbles.pop();
                drawBubble(bubble.x, bubble.text, bubble.size);
            }

            ctx.restore();
            Debug.Statistic.DrawTimes && console.timeEnd("drawBubbles");
        };

        var drawBubble = function drawBubble(x, text, size) {
            ctx.fillStyle = bubbleBGColor;
            var rectX = x,
                rectY = ui.bottomOffset - 8 - size.height,
                rectWidth = size.width,
                rectHeight = size.height,
                rectXMax = rectX + rectWidth,
                rectYMax = rectY + rectHeight,
                rectXMiddle = rectX + rectWidth / 2,
                rectYMiddle = rectY + rectHeight / 2;
            ctx.beginPath();
            ctx.moveTo(rectXMiddle, rectY);
            ctx.arcTo(rectXMax, rectY, rectXMax, rectYMiddle, bubbleCornerRadius);
            ctx.arcTo(rectXMax, rectYMax, rectXMiddle, rectYMax, bubbleCornerRadius);
            ctx.arcTo(rectX, rectYMax, rectX, rectYMiddle, bubbleCornerRadius);
            ctx.arcTo(rectX, rectY, rectXMiddle, rectY, bubbleCornerRadius);
            ctx.closePath();
            ctx.fill();
            ctx.fillStyle = bubbleFontColor;
            ctx.fillText(text, rectXMiddle, rectYMiddle);
        };

        var drawGrid = function drawGrid() {
            Debug.Statistic.DrawTimes && console.time("drawGrid");
            ctx.save();
            ctx.strokeStyle = Color.GRID_LINES;
            ctx.lineHeight = 1;

            if (!ctx.setLineDash) {
                ctx.setLineDash = function () {
                };
            }

            if (data.min !== data.steps[0]) {
                // always draw line at bottom!
                ctx.beginPath();
                ctx.moveTo(0, ui.bottomOffset);
                ctx.lineTo(ui.canvasWidth, ui.bottomOffset);
                ctx.stroke();
                ctx.closePath();
            }

            var y;

            for (var i = 0; i < data.steps.length; i++) {
                y = Helper.getYForValue(data.steps[i]);
                ctx.beginPath();
                ctx.setLineDash([]);
                ctx.moveTo(0, y);
                ctx.lineTo(ui.canvasWidth, y);
                ctx.stroke();
                ctx.closePath();

                if (data.steps[i + 1] !== undefined) {
                    y = Helper.getYForValue(data.steps[i] + (data.steps[i + 1] - data.steps[i]) / 2);
                    ctx.beginPath();
                    ctx.setLineDash([2, 2]);
                    ctx.moveTo(0, y);
                    ctx.lineTo(ui.canvasWidth, y);
                    ctx.stroke();
                    ctx.closePath();
                }
            }

            ctx.restore();
            Debug.Statistic.DrawTimes && console.timeEnd("drawGrid");
        };

        var drawTimeLine = function drawTimeLine() {
            Debug.Statistic.DrawTimes && console.time("drawTimeLine");
            var top = ui.bottomOffset + 8; // save old settings

            ctx.save(); // new settings

            ctx.lineWidth = 1;
            ctx.font = timeLineFont;
            ctx.textAlign = "center";
            ctx.textBaseline = "top";
            ctx.strokeStyle = timeLineColor;
            ctx.fillStyle = timeLineFontColor;
            ctx.beginPath();
            var x,
                text = null,
                currentTime = moment.utc(time.left * 1000),
                lastTimeStamp = moment.utc(time.right * 1000);
            resolutionType.timeline.first(currentTime); // round down -> first left indicator scrolls in/out!

            resolutionType.timeline.last(lastTimeStamp); // round up -> last indicator scrolls in/out

            while (currentTime <= lastTimeStamp) {
                for (var i = 0; i < resolutionType.timeline.steps.length; i++) {
                    text = resolutionType.timeline["step" + resolutionType.timeline.steps[i]](currentTime);
                    x = Helper.getXForTimestamp(currentTime.unix());
                    ctx.moveTo(x, top);

                    if (resolutionType.timeline.steps[i] === 0) {
                        // step0 = small line!
                        ctx.lineTo(x, top + timeLineDashHeightSmall);
                    } else {
                        ctx.lineTo(x, top + timeLineDashHeightBig);
                    }

                    if (typeof text === "string" || typeof text === "number") {
                        ctx.fillText(text, x, top + 9);
                        text = null;
                    }
                }
            }

            ctx.closePath();
            ctx.stroke();
            ctx.restore();
            Debug.Statistic.DrawTimes && console.timeEnd("drawTimeLine");
        };

        data.check = function checkData() {
            // to left
            timeObject.subtract(range.seconds, "second");
            requestDataForDate(timeObject.format(REQUEST_MONTH_FORMAT));
            timeObject.add(range.seconds, "second"); // to right

            timeObject.add(range.seconds, "second");
            requestDataForDate(timeObject.format(REQUEST_MONTH_FORMAT));
            timeObject.subtract(range.seconds, "second");
        };

        var requestDataForDate = function requestDataForDate(dateToRequest) {
            if (dateToRequest) {
                var dateFormat, monthOrYear;

                if (data.range === Statistic.DataRange.YEAR) {
                    dateToRequest = dateToRequest.slice(0, 4); // for year only use YYYY!

                    dateFormat = REQUEST_YEAR_FORMAT;
                    monthOrYear = "year";
                } else {
                    dateFormat = REQUEST_MONTH_FORMAT;
                    monthOrYear = "month";
                }

                if (data.requests.hasOwnProperty(dateToRequest)) {// already requested!
                } else {
                    Debug.Statistic.GUI && console.log("requesting data for", dateToRequest); // check if data until then was requested!
                    // searching last month/year of all requested

                    var from = 0;

                    for (var date in data.requests) {
                        if (data.requests.hasOwnProperty(date)) {
                            from = from > moment.utc(date, dateFormat) ? from : moment.utc(date, dateFormat);
                        }
                    }

                    if (from) {
                        // and then go through all months/years until the currently requested
                        var to = moment.utc(dateToRequest, dateFormat),
                            missingDate;
                        from.subtract(1, monthOrYear);

                        while (from > to) {
                            missingDate = from.format(dateFormat);

                            if (!data.requests[missingDate]) {
                                console.info(" - requesting previously skipped", monthOrYear, ":", missingDate);
                                data.requests[missingDate] = "requested";
                                dataSource.getDataForDate(missingDate, data.range);
                            }

                            from.subtract(1, monthOrYear);
                        }
                    } // request data for month!


                    data.requests[dateToRequest] = "requested";
                    dataSource.getDataForDate(dateToRequest, data.range);
                }
            }
        };

        var resetData = function resetData() {
            Debug.Statistic.GUI && console.info("resetData");
            dataSource.resetRequests();
            data.upToDate = false;
            data.source = [];
            data.source.middleIndex = NaN;
            data.requests = {};
            data.steps = [];
            data.min = NaN;
            data.minRaw = NaN;
            data.max = NaN;
            data.maxRaw = NaN;
        };
        /**
         * deletes cached data and requests the data for the current visible range!
         */


        var reloadData = function reloadData() {
            resetData();
            var from = moment.utc((time.left - range.seconds * reloadRangeFaktor) * 1000).startOf("month"),
                to = moment.utc((time.right + range.seconds * reloadRangeFaktor) * 1000).startOf("month"),
                toRight = moment.utc(time.middle * 1000).startOf("month"),
                toLeft = toRight.clone().add(-1, "month");
            requestDataForDate(toRight.format(REQUEST_MONTH_FORMAT));
            toRight.add(1, "month");

            while (toLeft.unix() >= from.unix() || toRight.unix() <= to.unix()) {
                requestDataForDate(toLeft.format(REQUEST_MONTH_FORMAT));
                requestDataForDate(toRight.format(REQUEST_MONTH_FORMAT));
                toLeft.add(-1, "month");
                toRight.add(1, "month");
            } // do this after requesting new month, to display correct text!


            setIndicatorValue(NaN);
        };

        var resizeID = NaN;

        var resizeCanvas = function resizeCanvas() {
            resizeID = NaN;
            checkAndPrepareCanvasContextForRetina(canvas);
            var oldWidth = range.seconds * ui.pixelPerSecond,
                newWidth = canvas.clientWidth,
                diff = newWidth - oldWidth;
            var secs = Helper.getSecondsFromDelta(diff); // recalculate range

            calcRange(range.seconds + secs);
            recalculateGUIVariables();
            draw();
        };

        var onResize = function onResize() {
            if (!isNaN(resizeID)) {
                cancelAnimationFrame(resizeID);
            }

            resizeID = lxRequestAnimationFrame(resizeCanvas, canvas);
        };

        var setIndicatorValue = function setIndicatorValue(v) {
            if (isNaN(v)) {
                // look if a request is pending
                if (data.requests[parseInt(Helper.formatTimeStamp(time.middle, REQUEST_MONTH_FORMAT))] === "requested") {
                    v = Infinity; // Infinity means loading!
                }
            }

            var dateFormat = LxDate.getDateFormat(resolutionType.dateType);

            if (!resolutionType.noTime) {
                dateFormat += " " + LxDate.getTimeFormat(resolutionType.hideSeconds, resolutionType.ignoreMinutes);
            }

            delegate.setCurrentDataPoint(v, Helper.formatTimeStamp(time.middle, dateFormat));
        };

        weakThis.GUIReady = function GUIReady() {
            // register scroll handler
            scrollView.onScroll = scroll;
            addEvent('resize', window, onResize);
            addEvent('orientationchange', window, onResize);

            scrollView.onZoom = function onZoom(leftDiff, rightDiff) {
                userInteraction = true;
                var leftSecs = Helper.getSecondsFromDelta(leftDiff),
                    rightSecs = Helper.getSecondsFromDelta(rightDiff),
                    wholeSecs = leftSecs + rightSecs;

                if (range.seconds + wholeSecs < ui.canvasWidth) {
                    return;
                }

                if (range.seconds + wholeSecs > adoptRangeForDisplay(sPerDay * 365 * 2.5)) {
                    return;
                } // recalculate range


                calcRange(range.seconds + wholeSecs); // adopt middle ts

                if (leftDiff !== 0 && rightDiff !== 0) {// pinching with 2 fingers, stay in the middle
                } else {
                    // only moving 1 finger, adopt middle to pinch in one side!
                    var addition = (rightSecs - leftSecs) / 2;
                    Debug.Statistic.GUIDetailed && console.log("addition to middle = " + addition);
                    adoptTimeStamps(time.middle + addition, addition);
                }

                //checkAndPrepareCanvasContextForRetina(canvas);
                recalculateGUIVariables(); // adopt middleIndex

                var searchTo = addition > 0 ? "right" : addition < 0 ? "left" : null;
                Debug.Statistic.GUIDetailed && console.log("searching to " + searchTo);
                findMiddleIndex(searchTo);
                draw();
            };

            dataSource.getMinimumDate().then(function (ts) {
                minimumTime = ts;
            });
            checkAndPrepareCanvasContextForRetina(canvas);
            calcRange(getRangeForDisplay());
            miniserverTime = dataSource.getMiniserverTime();
            timeObject = miniserverTime.clone();
            adoptTimeStamps(timeObject.unix(), 0);
            recalculateGUIVariables();
            drawTimeLine();
            requestDataForDate(timeObject.format(REQUEST_MONTH_FORMAT));
        };

        weakThis.getElement = function getElement() {
            return canvas;
        };

        weakThis.getVisibleDatapoints = function getVisibleData() {
            var dps = data.source;
            var middleTimeTs = time.middle;
            var secondsOnScreen = range.seconds;
            var startTs = middleTimeTs - secondsOnScreen / 2;
            var endTs = middleTimeTs + secondsOnScreen / 2;

            return dps.filter(dp => {
                return dp.ts > startTs && dp.ts < endTs;
            });
        }

        /**
         *
         * @param dp dataPackage
         * @param final if the dataPackage is the final one
         */


        weakThis.receivedDataPackage = function receivedDataPackage(dp, final) {
            // atm we ignore intermediate packages in earlier months!
            if (!final && miniserverTime.format(REQUEST_MONTH_FORMAT) !== dp.date) {
                return;
            }

            Debug.Statistic.GUI && console.log("receivedDataPackage for date:", dp.date, "dataPoints:", dp.dataPoints.length, "final:", final); // on bar charts there is an outlier limit, those bars exceeding it will be shown orange

            if (chartType === Statistic.Type.BAR_CHART && dp.outlierLimit) {
                if (!data.hasOwnProperty("outlierLimit")) {
                    Debug.Statistic.GUI && console.log("   Initializing outlier limit to " + dp.outlierLimit);
                    data.outlierLimit = dp.outlierLimit;
                } else if (dp.outlierLimit > data.outlierLimit) {
                    Debug.Statistic.GUI && console.log("   OutlierLimit was " + data.outlierLimit + ", now " + dp.outlierLimit);
                    data.outlierLimit = dp.outlierLimit;
                }
            }

            if (!data.upToDate && final) {
                if (chartType !== Statistic.Type.DIGITAL && chartType !== Statistic.Type.BAR_CHART && !(chartType === Statistic.Type.LINE_CHART && frequency === Statistic.Frequency.EVERY_CHANGE)) {
                    // only if not digital, bar chart or every change, use the last dp's ts as latest time!
                    miniserverTime = moment.utc(dp.dataPoints[dp.dataPoints.length - 1].ts * 1000);
                }

                data.upToDate = true;
                data.date = miniserverTime.unix();

                if (chartType === Statistic.Type.DIGITAL) {
                    draw();
                }
            }

            if (final && data.requests[parseInt(dp.date)] === "intermediate") {
                // we received intermediate packages before; skip this final package!
                return;
            }

            if (data.source.length > 0) {
                var firstDpDataPoint = dp.dataPoints[0],
                    // first dataPoint arrived in the dataPackage
                    lastDpDataPoint = dp.dataPoints[dp.dataPoints.length - 1],
                    // last dataPoint arrived in the dataPackage
                    firstDataPoint = data.source[0],
                    // first dataPoint of the array
                    lastDataPoint = data.source[data.source.length - 1]; // last dataPoint of the array
                // save index!

                var tmpMiddleIdx = data.source.middleIndex;

                if (lastDataPoint.ts <= lastDpDataPoint.ts) {
                    // <= to sum up values!
                    // add to end of data array!
                    if (chartType === Statistic.Type.DIGITAL || chartType === Statistic.Type.LINE_CHART && frequency === Statistic.Frequency.EVERY_CHANGE) {
                        // check duplicate entries at month change!
                        if (lastDataPoint.value === firstDpDataPoint.value) {
                            dp.dataPoints.shift();
                        }
                    } else if (chartType === Statistic.Type.BAR_CHART) {
                        // sum up values
                        if (lastDataPoint.ts === firstDpDataPoint.ts) {
                            lastDataPoint.value += firstDpDataPoint.value;
                            dp.dataPoints.shift();
                        }
                    }

                    data.source = data.source.concat(dp.dataPoints);

                    if (chartType === Statistic.Type.LINE_CHART) {
                        // remove ts duplicates only
                        data.source = Statistic.Processor.removeDuplicateDatapoints(data.source, false, true, false, true);
                    }

                    if (!userInteraction) {
                        // we just added data to the end of the array. if there was no user interaction, update the tmpMiddleIdx to the last index
                        tmpMiddleIdx = data.source.length - 1;
                    }

                    data.source.middleIndex = tmpMiddleIdx;
                } else {
                    // add to beginning of data array!
                    if (chartType === Statistic.Type.DIGITAL || chartType === Statistic.Type.LINE_CHART && frequency === Statistic.Frequency.EVERY_CHANGE) {
                        // check duplicate entries at month change!
                        if (lastDpDataPoint.value === firstDataPoint.value) {
                            data.source.shift();
                            data.source.middleIndex--;
                            tmpMiddleIdx--;
                        }
                    } else if (chartType === Statistic.Type.BAR_CHART) {
                        // sum up values
                        if (lastDpDataPoint.ts === firstDataPoint.ts) {
                            lastDpDataPoint.value += firstDataPoint.value;
                            data.source.shift();
                            data.source.middleIndex--;
                            tmpMiddleIdx--;
                        }
                    }

                    data.source = dp.dataPoints.concat(data.source);

                    if (time.middle < firstDpDataPoint.ts) {
                        // index stays zero
                        data.source.middleIndex = tmpMiddleIdx;
                    } else if (time.middle < lastDpDataPoint.ts) {
                        // must find index!
                        findMiddleIndex("right");
                    } else {
                        // add length to index!
                        data.source.middleIndex = tmpMiddleIdx + dp.dataPoints.length;
                    }
                }
            } else {
                data.source = dp.dataPoints;
            }

            if (!userInteraction) {
                if (chartType === Statistic.Type.DIGITAL || chartType === Statistic.Type.LINE_CHART && frequency === Statistic.Frequency.EVERY_CHANGE) {
                    // focus miniserver time!
                    var ts = miniserverTime.unix();
                    adoptTimeStamps(ts, ts - time.middle);
                } else {
                    // focus last timestamp!
                    var lastTS = data.source[data.source.length - 1].ts;
                    adoptTimeStamps(lastTS, lastTS - time.middle);
                }
            }

            data.requests[parseInt(dp.date)] = final ? "received" : "intermediate";

            if (chartType === Statistic.Type.DIGITAL) {
                data.minRaw = 0;
                data.maxRaw = 1;
            } else if (chartType === Statistic.Type.BAR_CHART) {
                data.minRaw = 0;

                if (isNaN(data.maxRaw)) {
                    data.maxRaw = dp.max;
                } else {
                    data.maxRaw = Math.max(dp.max, data.maxRaw);
                }
            } else {
                if (isNaN(data.minRaw)) {
                    data.minRaw = dp.min;
                } else {
                    data.minRaw = Math.min(dp.min, data.minRaw);
                }

                if (isNaN(data.maxRaw)) {
                    data.maxRaw = dp.max;
                } else {
                    data.maxRaw = Math.max(dp.max, data.maxRaw);
                }
            }
            //checkAndPrepareCanvasContextForRetina(canvas);
            recalculateGUIVariables();

            if (!isNaN(data.source.middleIndex)) {
                setTimeout(draw, 1); // try lxRequestAnimationFrame??
            } else {
                findMiddleIndex();
                setTimeout(draw, 1);
            }
        };

        weakThis.receivedNoDataResponse = function receivedNoDataResponse(date) {
            data.requests[date] = "no-data";

            if (chartType === Statistic.Type.DIGITAL || chartType === Statistic.Type.LINE_CHART && frequency === Statistic.Frequency.EVERY_CHANGE) {
                // try to download some data from the previous month in order to be able to draw the stuff
                // TODO-thallth (low prio) make sure we only try to download data from past! (not future!) - compare with middle ts?
                if (date.length === 4) {
                    // Year
                    var yearBeforeNoDataYear = moment.utc(date, REQUEST_YEAR_FORMAT).subtract(1, "year");

                    if (moment(yearBeforeNoDataYear, REQUEST_YEAR_FORMAT).unix() >= minimumTime) {
                        requestDataForDate(yearBeforeNoDataYear.format(REQUEST_YEAR_FORMAT));
                    } else {
                        Debug.Statistic.GUI && console.log("don't request data for " + yearBeforeNoDataYear.format(REQUEST_YEAR_FORMAT));
                    }

                    return;
                } else if (date.length === 6) {
                    // Month
                    var monthBeforeNoDataYear = moment.utc(date, REQUEST_MONTH_FORMAT).subtract(1, "month");

                    if (moment(monthBeforeNoDataYear, REQUEST_MONTH_FORMAT).unix() >= minimumTime) {
                        requestDataForDate(monthBeforeNoDataYear.format(REQUEST_MONTH_FORMAT));
                    } else {
                        Debug.Statistic.GUI && console.log("don't request data for " + monthBeforeNoDataYear.format(REQUEST_MONTH_FORMAT));
                    }

                    return;
                }
            }

            if (!data.upToDate) {
                setIndicatorValue(NaN); // no data!
            }
        };

        var destroy = function onDestroy() {
            Debug.Statistic.GUI && console.log("destroy chartView");
            removeEvent('resize', window, onResize);
            removeEvent('orientationchange', window, onResize);
            scrollView.destroy();
        };
        /**
         * focuses the previous datapoint from the middle
         * @param doNotAnimate
         * @returns {boolean} whether at least one more datapoint is available
         */


        weakThis.focusPreviousDatapoint = function focusPreviousDatapoint(doNotAnimate) {
            Debug.Statistic.GUI && console.log("focusPreviousDatapoint");
            var currDatapoint = data.source[data.source.middleIndex],
                prevDatapoint = data.source[data.source.middleIndex - 1];
            var centerX = Helper.getXForTimestamp(time.middle),
                currDatapointX = Helper.getXForTimestamp(currDatapoint.ts);

            if (currDatapointX < centerX) {
                // focus currDatapoint
                focusTimestamp(currDatapoint.ts, doNotAnimate);
            } else if (prevDatapoint) {
                // focus prevDatapoint
                data.source.middleIndex--;
                focusTimestamp(prevDatapoint.ts, doNotAnimate);
            }

            return prevDatapoint !== undefined;
        };
        /**
         * focuses the next datapoint from the middle
         * @param doNotAnimate
         * @returns {boolean} whether at least one more datapoint is available
         */


        weakThis.focusNextDatapoint = function focusNextDatapoint(doNotAnimate) {
            Debug.Statistic.GUI && console.log("focusNextDatapoint");
            var currDatapoint = data.source[data.source.middleIndex],
                nextDatapoint = data.source[data.source.middleIndex + 1];
            var centerX = Helper.getXForTimestamp(time.middle),
                currDatapointX = Helper.getXForTimestamp(currDatapoint.ts);

            if (currDatapointX > centerX) {
                // focus currDatapoint
                focusTimestamp(currDatapoint.ts, doNotAnimate);
            } else if (nextDatapoint) {
                // focus nextDatapoint
                data.source.middleIndex++;
                focusTimestamp(nextDatapoint.ts, doNotAnimate);
            }

            return nextDatapoint !== undefined;
        }; // indicatorView


        indicatorView = new IndicatorView(data, time, ui, ctx, Helper, chartType, frequency, noEntryDefine); // chartView

        if (chartType === Statistic.Type.BAR_CHART) {
            subChartView = new BarChartView(data, ui, ctx, Helper);
        } else if (chartType === Statistic.Type.DIGITAL) {
            subChartView = new StepChartView(data, ui, ctx, Helper); //subChartView = new DigitalChartView(data, ui, ctx, Helper);
        } else if (chartType === Statistic.Type.LINE_CHART) {
            if (frequency === Statistic.Frequency.EVERY_CHANGE) {
                subChartView = new StepChartView(data, ui, ctx, Helper);
            } else {
                subChartView = new LineChartView(data, ui, ctx, Helper);
            }
        } // API


        return {
            getElement: weakThis.getElement,
            GUIReady: weakThis.GUIReady,
            receivedDataPackage: weakThis.receivedDataPackage,
            getVisibleDatapoints: weakThis.getVisibleDatapoints,
            receivedNoDataResponse: weakThis.receivedNoDataResponse,
            jumpBack: weakThis.focusPreviousDatapoint,
            jumpForward: weakThis.focusNextDatapoint,
            destroy: destroy
        };
    };
});
