'use strict';

window.GUI = function (GUI) {
    class HomeScreenHD extends GUI.HomeScreen {
        //region Static
        static Template = function () {
            var getTopBorder = function getTopBorder() {
                return $('<div class="home-screen__top-border"></div>');
            };

            return {
                getTopBorder: getTopBorder
            };
        }(); //endregion Static

        constructor() {
            super();
            console.log("HomeScreenHD constructor!");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.element.addClass("home-screen-hd");
            this.element.toggleClass("home-screen-hd--merge-mode", MergeHdAndFavs);
            this.elements.header.removeClass("top__header--sd");

            if (!MergeHdAndFavs) {
                HomeScreenHD.Template.getTopBorder().insertAfter(this.titleBar.getElement());
            }
        }

        weatherStatesReceived(states) {
            super.weatherStatesReceived(states); // remove top border!

            this.weatherWidget && this.weatherWidget.getElement().removeClass("cell__top-border-effect");
            /*if(!states.hasValidData && this.elements.weatherWidgetContainer) {
                this.elements.weatherWidgetContainer.remove();
                this.elements.weatherWidgetContainer = null;
            }*/
        }

        /* no titlBar in HD */
        _initTitleBar(isWebinterface) {
            return MergeHdAndFavs ? null : super._initTitleBar(...arguments);
        }

    }

    GUI.HomeScreenHD = HomeScreenHD;
    return GUI;
}(window.GUI || {});
