'use strict';
/**
 * callbacks:
 *  onColorPicked(color)        called when a single color has been selected.
 *  onPickerReleased()          called when the user released the picker
 */

define(['ColorPickerV2ControlEnums'], function (ColorPickerV2ControlEnums) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var SELECTOR_RADIUS = 16;
    var SELECTOR_ID_PREFIX = "color-selector-";
    return class ColorSelectorV2 extends GUI.View {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return $('<div class="color-selectorv2">' + '   <div class="color-selectorv2__button">' + '       <div class="button__cntr button__canvas-cntr" />' + '       <div class="button__cntr button__icon-cntr">' + ImageBox.getResourceImageWithClasses(Icon.DELETE_2, 'cntr__cntr-content icon-cntr__icon icon-cntr__icon-delete') + ImageBox.getResourceImageWithClasses(Icon.CHECKED, 'cntr__cntr-content icon-cntr__icon icon-cntr__icon-tick') + '       </div>' + '   </div>' + '   <div class="color-selectorv2__canvas-cntr">' + // the canvas needs to be wrapped, otherwise the drawing is messed up.
                    '   </div>' + '</div>');
            };

            var getSelector = function getSelector(idx, multiple, deleting) {
                var content = '<div class="selector__color-preview"></div>';

                if (!!multiple) {
                    if (!!deleting) {
                        content += ImageBox.getResourceImageWithClasses(Icon.DELETE_2, 'selector__icon');
                    } else {
                        content += '<div class="selector__label">' + (idx + 1) + '</div>';
                    }
                }

                return $('<div class="canvas-cntr__selector" id="' + (SELECTOR_ID_PREFIX + idx) + '">' + content + '</div>');
            };

            return {
                getSelector: getSelector,
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(multiSelection) {
            super(ColorSelectorV2.Template.getTemplate());
            this.multiSelection = multiSelection;
            this.selectors = [];
            this.eventHandlers = [];
            this.draggingIdx = -1;
            this.isDeleting = false;
            this.name = this.multiSelection ? "MultiColorSelection" : this.name;
            this.hammerCfg = {
                preventDefault: true,
                dragMinDistance: 5
            };
            this.hammerEvIds = 'tap dragstart drag dragend release';
            this.deleteEvIds = tapEvent();
            this._colorViewType = GUI.ColorViewType.HUE;
            this._shouldRegisterTouchHandlers = true;
        }

        viewDidLoad() {
            Debug.Control.ColorSelectorV2 && console.log(this.name, "viewDidLoad");
            return super.viewDidLoad(...arguments).then(function () {
                var promises = [];
                this.elements = {
                    cntr: this.element.find('.color-selectorv2__canvas-cntr'),
                    button: this.element.find('.color-selectorv2__button'),
                    btnCanvasCntr: this.element.find('.button__canvas-cntr').toggle(!this.multiSelection),
                    btnIconCntr: this.element.find('.button__icon-cntr').toggle(this.multiSelection),
                    deleteIcon: this.element.find('.icon-cntr__delete'),
                    tickIcon: this.element.find('.icon-cntr__tick')
                };
                this.colorView = new GUI.ColorView(this._colorViewType);
                this.colorView.getElement().addClass("canvas-cntr__canvas");
                promises.push(this.appendSubview(this.colorView, this.elements.cntr));
                this.elements.canvas = this.colorView.getElement();
                this.button = new GUI.LxRippleButton(this.elements.button);
                this.button.setRippleEnabled(false);
                promises.push(this.addToHandledSubviews(this.button));

                if (this.multiSelection) {
                    // add a delete button/basket
                    this.button.on(GUI.LxRippleButton.CLICK_EVENT, this._toggleDeleteMode.bind(this));
                } else {
                    // add the ColorWheel switcher btn
                    this.colorWheelSwitcher = new GUI.ColorView(GUI.ColorViewType.WARM_COLD);
                    promises.push(this.appendSubview(this.colorWheelSwitcher, this.elements.btnCanvasCntr));
                    this.colorWheelSwitcher.getElement().addClass("cntr__cntr-content");
                    this.button.on(GUI.LxRippleButton.CLICK_EVENT, this._toggleColorView.bind(this));
                }

                return Q.all(promises);
            }.bind(this));
        }

        viewWillAppear() {
            Debug.Control.ColorSelectorV2 && console.log(this.name, "viewWillAppear");
            var promise = super.viewWillAppear(...arguments);

            this._updateAttributes();

            this._registerEventHandlers();

            return promise;
        }

        viewDidAppear() {
            Debug.Control.ColorSelectorV2 && console.log(this.name, "viewDidAppear");
            var promise = super.viewDidAppear(...arguments);

            this._updateAttributes();

            this._updateUI();

            return promise;
        }

        viewDidDisappear(viewRemainsVisible) {
            Debug.Control.ColorSelectorV2 && console.log(this.name, "viewDidDisappear");

            this._removeEventHandlers();

            return super.viewDidDisappear(viewRemainsVisible);
        }

        /**
         * Called whenever the color array needs to be updated.
         * @param colors
         */
        updateColorSelection(colors) {
            Debug.Control.ColorSelectorV2 && console.log(this.name, "updateColorSelection");
            this.colors = cloneObject(colors);

            this._updateUI();
        }

        /**
         * Sets the colorViewType, the change will be visible after updating the colorSelection to prevent to much UI Updates
         * @param pickerMode
         */
        setActiveMode(pickerMode) {
            if (pickerMode !== ColorPickerV2ControlEnums.PickerMode.NONE) {
                this._colorViewType = this._mapModes(pickerMode);
            }
        }

        togglePickerMode(pickerMode) {
            this.setActiveMode(pickerMode);

            let colorViewType = this._mapModes(pickerMode);

            this._updateUI();

            this.element.toggleClass('color-selectorv2__daylight', colorViewType === GUI.ColorViewType.DAYLIGHT);

            if (colorViewType === GUI.ColorViewType.DAYLIGHT) {
                this._shouldRegisterTouchHandlers = false;

                this._removeEventHandlers();
            } else {
                this._shouldRegisterTouchHandlers = true;

                this._registerEventHandlers();
            }
        }

        setColorViewText(text) {
            this.colorView && this.colorView.setOverlayText(text);
        }

        showTuneAbleWhiteOnly() {
            this.setActiveMode(ColorPickerV2ControlEnums.PickerMode.TEMPERATURE);
            this.element.toggleClass('color-selectorv2__tune-able-white', true);
        }

        // -----------------------------------------------------------------------------------------------
        //                  Private Methods
        // -----------------------------------------------------------------------------------------------
        _mapModes(mode) {
            var mappedMode;

            switch (mode) {
                case ColorPickerV2ControlEnums.PickerMode.HSV:
                case ColorPickerV2ControlEnums.PickerMode.SEQUENCE:
                    mappedMode = GUI.ColorViewType.HUE;
                    break;

                case ColorPickerV2ControlEnums.PickerMode.TEMPERATURE:
                    mappedMode = GUI.ColorViewType.WARM_COLD;
                    break;

                case ColorPickerV2ControlEnums.PickerMode.DAYLIGHT:
                    mappedMode = GUI.ColorViewType.DAYLIGHT;
                    break;
            }

            return mappedMode;
        }

        /**
         * Will update the UI and the selector circles corresponding to the current colors and attributes.
         * @private
         */
        _updateUI() {
            var selector, i, color; // clean up old selectors

            this._removeEventHandlers();

            this.selectors.forEach(function (selector) {
                selector.remove();
            });
            this.selectors = []; // update the type of the colorView

            this.colorView.setType(this._colorViewType);

            if (!this.multiSelection) {
                if (this._colorViewType === GUI.ColorViewType.WARM_COLD) {
                    this.colorWheelSwitcher.setType(GUI.ColorViewType.HUE);
                } else {
                    this.colorWheelSwitcher.setType(GUI.ColorViewType.WARM_COLD);
                }
            } // ensure the delete mode is properly visualized.


            this._checkAndUpdateDeleteMode(); // initialize new selectors


            for (i = 0; i < this.colors.length; i++) {
                color = this.colors[i];

                if (this._colorViewType === GUI.ColorViewType.WARM_COLD && color.type !== ColorPickerV2ControlEnums.PickerMode.TEMPERATURE) {
                    break;
                } else if (this._colorViewType === GUI.ColorViewType.HUE && color.type !== ColorPickerV2ControlEnums.PickerMode.HSV) {
                    break;
                }

                selector = this._prepareAndShowSelector(color, i);
                this.selectors.push(selector);
            }

            if (this._shouldRegisterTouchHandlers) {
                this._registerEventHandlers();
            }
        }

        /**
         * Will remove all eventHandlers.
         * @private
         */
        _removeEventHandlers() {
            var i;

            for (i = 0; i < this.eventHandlers.length; i++) {
                this.eventHandlers[i].dispose();
            }

            this.eventHandlers = [];
        }

        /**
         * Will register all eventhandlers.
         * @private
         */
        _registerEventHandlers() {
            if (this.eventHandlers.length > 0) {
                this._removeEventHandlers();
            }

            if (this.isDeleting) {
                this.selectors.forEach(function (selector) {
                    this.eventHandlers.push(Hammer(selector[0], this.hammerCfg).on(this.deleteEvIds, this._handleDeleteSelector.bind(this)));
                }.bind(this));
            } else {
                this.eventHandlers.push(Hammer(this.elements.canvas[0], this.hammerCfg).on(this.hammerEvIds, this._handleTouchEvent.bind(this)));
                this.selectors.forEach(function (selector) {
                    this.eventHandlers.push(Hammer(selector[0], this.hammerCfg).on(this.hammerEvIds, this._handleTouchEvent.bind(this)));
                }.bind(this));
            }
        }

        _prepareAndShowSelector(colorObj, idx) {
            var elem = ColorSelectorV2.Template.getSelector(idx, this.multiSelection, this.isDeleting),
                preview = elem.find(".selector__color-preview"),
                pos,
                top,
                left;
            this.elements.cntr.append(elem);
            pos = this._getPositionForColor(colorObj, idx);
            top = pos.y - SELECTOR_RADIUS + "px";
            left = pos.x - SELECTOR_RADIUS + "px";
            elem.css("top", top);
            elem.css("left", left);
            preview.css('background-color', LxColorUtils.getHSLATextForDrawing(colorObj));
            Debug.Control.ColorSelectorV2 && console.log(this.name, "_prepareAndShowSelector: " + top + "/" + left);
            return elem;
        }

        _getPositionForColor(colorObj) {
            var coords;

            if (colorObj.type === ColorPickerV2ControlEnums.PickerMode.HSV) {
                coords = hsvToCoordinates({
                    h: colorObj.hue,
                    s: colorObj.sat
                }, this.radius);
            } else if (colorObj.type === ColorPickerV2ControlEnums.PickerMode.TEMPERATURE) {
                coords = tempToCoordinates(colorObj.kelvin, this.radius);
            }

            coords.x += this.margin;
            coords.y += this.margin;
            Debug.Control.ColorSelectorV2 && console.log(this.name, "_getPositionForColor: " + coords.x + "/" + coords.y);
            return coords;
        }

        /**
         * Will update the attributes that are neccesary for all drawing types
         * @private
         */
        _updateAttributes() {
            Debug.Control.ColorSelectorV2 && console.log(this.name, "_updateAttributes");
            this.width = this.elements.canvas[0].clientWidth;
            this.height = this.elements.canvas[0].clientHeight;
            this.radius = Math.min(this.width, this.height) / 2;
            this.margin = this.elements.canvas[0].offsetLeft;
        }

        // Event listeners
        _toggleColorView() {
            if (this._colorViewType === GUI.ColorViewType.HUE) {
                this._colorViewType = GUI.ColorViewType.WARM_COLD;
            } else {
                this._colorViewType = GUI.ColorViewType.HUE;
            }

            this._updateUI();
        }

        _toggleDeleteMode() {
            this.isDeleting = !this.isDeleting; // will update the delete mode attributes too.

            this._updateUI();
        }

        /**
         * Will ensure the UI doesn't allow deleting items while it shouldn't.
         * @private
         */
        _checkAndUpdateDeleteMode() {
            var canDelete = this._canDeleteColors();

            if (!canDelete) {
                this.isDeleting = false;
            }

            this.button.setEnabled(canDelete || !this.multiSelection);
            this.element.toggleClass("color-selectorv2--no-deleting", !canDelete && this.multiSelection);
            this.element.toggleClass("color-selectorv2--deleting", this.isDeleting && this.multiSelection);
            this.element.toggleClass("color-selectorv2--adding", !this.isDeleting && canDelete && this.multiSelection);
        }

        /**
         * Called whenever a color is being changed or added (if multipleSelection is active)
         * @param e
         * @private
         */
        _handleTouchEvent(e) {
            stopEventPropagation(e);

            if (this._ignoreEvent(e)) {
                return; // avoid too many requests.
            }

            var position = this._getXAndYPosFromEvent(e),
                colorObject,
                targetId = e.currentTarget.id,
                selectorId = -1,
                isDragging = e.type === 'drag',
                radius = Math.min(this.width, this.height) / 2,
                isInsideOfCircle = this._pointInCircle(position.x, position.y, radius); // Click the button on the lower left side if the user clicks outside of the lower right section of the circle


            if (!isInsideOfCircle && (position.x >= radius || position.y <= radius) && tapEvent() === e.type && this.button.isEnabled) {
                this.button._handleTap(e);

                return;
            } // try and detect the id ( = if an existing selector is being clicked or dragged


            if (targetId && targetId.startsWith(SELECTOR_ID_PREFIX)) {
                selectorId = parseInt(targetId.split("-")[2]);
            } else {
                selectorId = this._getIndexForSelection(isDragging);
            } // acquire the color object for the new position.


            if (this._colorViewType === GUI.ColorViewType.HUE) {
                colorObject = this._getHsvColorObject(position, this.width, this.height);
            } else {
                colorObject = this._getTempColorObject(position, this.height);
            }

            colorObject.brightness = this._getBrightnessForSelection(selectorId); // ensure no color is being sent, if the new color could not be updated properly..

            if (colorObject.type !== ColorPickerV2ControlEnums.PickerMode.NONE) {
                switch (e.type) {
                    case 'drag':
                        this._dispatchColorSelected(colorObject, selectorId, isDragging);

                        break;

                    case 'click':
                    case 'tap':
                        // A Tap won't trigger the release event, we must handle it our self
                        this._dispatchColorSelected(colorObject, selectorId, isDragging);

                        this.onPickerReleased && this.onPickerReleased();
                        break;

                    case 'dragend':
                        this._dispatchColorSelected(colorObject, selectorId, isDragging);

                        this.onPickerReleased && this.onPickerReleased();
                        break;

                    default:
                        break;
                }
            }
        }

        /**
         * This method helps retrieving a proper brightness for a moved or new color selection.
         * @param selectorId    the id of the selector to get the brightness for
         * @returns {*}
         * @private
         */
        _getBrightnessForSelection(selectorId) {
            var brightness = 0; // recover brightness from previous color

            if (this.colors[selectorId]) {
                // moving an existing selection around, reuse brightness!
                brightness = this.colors[selectorId].brightness;
            } else if (this.colors.length > 0) {
                // other colors exist, use their brightness
                brightness = this.colors[0].brightness;
            }

            if (brightness === 0) {
                // a completely new selection or brightness was at 0 when moving around = full brightness
                brightness = LxColorUtils.DEFAULT_BRIGHTNESS;
            }

            return brightness;
        }

        /**
         * Will both update the current selection to match the new color, update the local dataset and dispatch the
         * new selection to the listening delegate.
         * @param colorObject
         * @param selectorId
         * @param isDragging
         * @private
         */
        _dispatchColorSelected(colorObject, selectorId, isDragging) {
            Debug.Control.ColorSelectorV2 && console.log(this.name, "_dispatchColorSelected"); // ensure the dragged or new selector is being presented properly (don't use _updateUI, it messes with the handlers)

            this._updateSelector(selectorId, colorObject); // ensure to update the local color attribute.


            if (!this.colors) {
                // the colorpicker might have been off == no colors picked!
                this.colors = [colorObject];
            } else if (selectorId < this.colors.length) {
                // an existing color is being modified
                this.colors[selectorId] = colorObject;
            } else {
                // a new color was picked!
                this.colors.push(colorObject);
            }

            this.onColorPicked && this.onColorPicked(colorObject, selectorId, isDragging);
        }

        /**
         * Called when a selector was tapped while the delete mode was active.
         * @param e
         * @private
         */
        _handleDeleteSelector(e) {
            stopEventPropagation(e);
            var targetId = e.currentTarget.id,
                selectorId = -1;

            try {
                if (targetId.startsWith(SELECTOR_ID_PREFIX)) {
                    selectorId = parseInt(targetId.split("-")[2]);
                    this.colors.splice(selectorId, 1);

                    this._updateUI();

                    this.onDeleteColor && this.onDeleteColor(selectorId);
                } else {
                    console.warn("Could not delete the selector - the ID could not be determined!");
                }
            } catch (ex) {
                console.error("Could not delete the selector!");
            }
        }

        /**
         * Returns a proper color index for the current selection
         * @param dragging      whether or not dragging is active
         * @returns {*}
         * @private
         */
        _getIndexForSelection(dragging) {
            var result;

            if (this.draggingIdx >= 0) {
                result = this.draggingIdx;
            } else if (this.multiSelection) {
                result = Math.min(this.colors.length, ColorPickerV2ControlEnums.MAX_SEQUENCE_COLORS - 1);
            } else {
                result = 0;
            } // reset drag index if no longer dragging.


            if (dragging) {
                this.draggingIdx = result;
            } else {
                this.draggingIdx = -1;
            }

            return result;
        }

        /**
         * Will update the current selector without interfering with the eventHandlers (as in updateUI)
         * @param id        the index of the new color in the array
         * @param color     the updated color of the new object.
         * @private
         */
        _updateSelector(id, color) {
            var selector = this.selectors[id],
                colorPreview,
                coords,
                colorObj = cloneObjectDeep(color),
                pos = this._getPositionForColor(color);

            Debug.Control.ColorSelectorV2 && console.log("_updateSelector: " + id + " - " + JSON.stringify(colorObj));

            if (selector) {
                coords = {
                    x: pos.x - SELECTOR_RADIUS,
                    y: pos.y - SELECTOR_RADIUS
                };
                selector.css('top', coords.y + "px");
                selector.css('left', coords.x + "px");
                colorPreview = selector.find('.selector__color-preview');
                colorPreview.css('background-color', LxColorUtils.createHSLATextFromColorObject(colorObj));
            } else {
                // initialize a new one!
                selector = this._prepareAndShowSelector(colorObj, id);
                this.selectors.push(selector);
                this.eventHandlers.push(Hammer(selector[0], this.hammerCfg).on(this.hammerEvIds, this._handleTouchEvent.bind(this)));
            }
        }

        /**
         * on iOS drag events are too fast (or too much, pixels!) save time and skip too fast events!
         * @param e
         * @returns {boolean} true if this event is to be ignored
         * @private
         */
        _ignoreEvent(e) {
            if (e.type === 'drag') {
                if (e.timeStamp - this.lastDragTS < 40) {
                    return true;
                }

                this.lastDragTS = e.timeStamp;
            }

            return false;
        }

        _getXAndYPosFromEvent(event) {
            var srcElement = this.elements.canvas[0],
                canvasRect = srcElement.getBoundingClientRect(),
                evPos = getEventPosition(event),
                xPos = evPos.x - canvasRect.left,
                yPos = evPos.y - canvasRect.top;
            var x = xPos,
                y = yPos;

            if (xPos < 0) {
                x = 0;
            }

            if (xPos > srcElement.clientWidth) {
                x = srcElement.clientWidth;
            }

            if (yPos < 0) {
                y = 0;
            }

            if (yPos > srcElement.clientHeight) {
                y = srcElement.clientHeight;
            }

            return {
                x: x,
                y: y
            };
        }

        _getHsvColorObject(position, width, height) {
            var hsv = coordinatesToHsv(position.x, position.y, Math.min(width, height) / 2);
            return {
                hue: parseInt(hsv.h),
                sat: Math.min(parseInt(hsv.s), 100),
                type: ColorPickerV2ControlEnums.PickerMode.HSV
            };
        }

        _getTempColorObject(position, height) {
            var kelvin = 2700;
            kelvin += (6500 - 2700) * (1 - position.y / height);
            return {
                type: ColorPickerV2ControlEnums.PickerMode.TEMPERATURE,
                brightness: 100,
                kelvin: parseInt(kelvin)
            };
        }

        /**
         * Colors can only be deleted if there are more than one.
         * @returns {boolean|*}
         * @private
         */
        _canDeleteColors() {
            return this.multiSelection && this.colors.length > 2;
        }

        /**
         * If the given point is within a circle with the given radius
         * @param x
         * @param y
         * @param radius
         * @returns {boolean}
         */
        _pointInCircle(x, y, radius) {
            var distancesquared = (x - radius) * (x - radius) + (y - radius) * (y - radius);
            return distancesquared <= radius * radius;
        }

    };
});
