'use strict';

define(["AudioZoneControlEnums", "AudioZoneTableViewHelper"], function (AudioZoneControlEnums, AudioZoneTableViewHelper) {
    class AddMediaScreen extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            Object.assign(this, ContextMenuHandler.Mixin);
            this.details = details;
            this.control = details.control;
            this.tableViewContentHelper = new AudioZoneTableViewHelper(this);
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.ViewController.startEditMode();
            }.bind(this));
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        setTableContent() {
            var args = arguments;
            this.states = this.control.getStates();
            return this.tableViewContentHelper.getTableContent().then(content => {
                this.tableContent = content;
                return super.setTableContent(...args);
            });
        }

    }

    Controls.AudioZoneControl.AddMediaScreen = AddMediaScreen;
});
