'use strict';

define("MailBoxControl", ["Control"], function (Control) {
    return class MailBoxControl extends Control {
        constructor() {
            super(...arguments);
        }

        specificCreateCmdText(cmdParts) {
            var text = null,
                timespan = null;

            if (cmdParts[1] === Commands.MailBox.CONF_MAIL) {
                text = _("controls.mailbox.confirmmail");
            } else if (cmdParts[1] === Commands.MailBox.NOTIF_DIS_ID) {
                timespan = cmdParts[2];

                if (timespan === "0") {
                    text = _("controls.mailbox.reenable-notifications");
                } else {
                    timespan = LxDate.formatSecondsShort(timespan, true, true);
                    text = _("controls.mailbox.ad.disable-notif-for-short", {
                        duration: timespan
                    });
                }
            }

            if (text !== null) {
                text = this.name + ": " + text;
            }

            return text;
        }

        getFriendlyValueName(event, operator) {
            var name = "";

            switch (event.data.stateName) {
                case "packetReceived":
                    name = event.value ? _("controls.mailbox.packet-received") : _("controls.mailbox.confirmpacket");
                    break;

                case "mailReceived":
                    name = event.value ? _("controls.mailbox.mail-received") : _("controls.mailbox.confirmmail");
                    break;

                default:
                    break;
            }

            if (name !== "") {
                name = this.name + ": " + name;
            }

            return name;
        }

    };
});
