import {View} from "react-native-web";
import globalStyles from "GlobalStyles";
import {
    LxReactTableView,
    LxReactText,
    useSetURLHook,
    AmbientContext,
    navigatorConfig,
    ControlContext,
    CCScreenStates,
    useBackNavigation
} from "LxComponents";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {CommonActions, useFocusEffect} from "@react-navigation/native";

function MessageDetailScreen({navigation, route}) {
    const {isAmbientMode} = useContext(AmbientContext);
    const {control} = useContext(ControlContext)
    const [entry, setEntry] = useState(route.params.entry);
    const messageCenterUuidRef = useRef(ActiveMSComponent.getStructureManager().getMessageCenter().uuidAction)
    const fromControlContent = route.params.fromControlContent;

    const updateReciever = useRef(null);

    useSetURLHook({
        urlPart: UrlStartLocation.MESSAGE_CENTER + "/" + entry.entryUuid
    });

    useBackNavigation(() => {
        navigation.goBack();
    });

    useEffect(() => {
        setEntry(route.params.entry)
    }, [route.params.entry])

    useFocusEffect(useCallback(() => {
        Debug.SystemState && console.log("SystemState: MessageDetail useFocusEffect(), focused")
        navigation.setOptions({
            ...navigatorConfig({
                title: "",
                headerStyle: {
                    backgroundColor: (fromControlContent || isAmbientMode) ? globalStyles.colors.transparent : globalStyles.colors.grey[600],
                    borderBottomWidth: 0
                },
                onLeftAction: () => navigation.goBack(),
                animationType: (fromControlContent || isAmbientMode) ? AnimationType.NONE : AnimationType.MODAL
            })
        });
        updateReciever.current = ActiveMSComponent.registerForMessageCenterUpdate(onMessageUpdateRecieved.bind(this));
        let readAt = SandboxComponent.getMiniserverTimeInfo(null, null, TimeValueFormat.MINISERVER_DATE_TIME).unix();
        ActiveMSComponent.updateMessageCenterEntry(entry.entryUuid, { readAt }); // We need to locally adopt the readAt value, because the entry is not updated by the Miniserver anymore - it does not send the update back anymore due to performance reasons
        sendCommand(Commands.MESSAGE_CENTER.READ_ENTRY);

        return () => {
            updateReciever.current && updateReciever.current();
            Debug.SystemState && console.log("SystemState: MessageDetail useFocusEffect(), cleanup")
        }
    }, []))

    /**
     * React to messageCenter updates by either update the tableView,
     * or navigate back, if the message has been removed
     * @param ev
     * @param messageCenterStructure
     * @private
     */
    const onMessageUpdateRecieved = (ev, messageCenterStructure) => {
        let previousEntryString = JSON.stringify(entry);
        let newEntry = messageCenterStructure.entries.find((msgEntry) => msgEntry.entryUuid === entry.entryUuid)

        if (!newEntry) {
            navigation.goBack();
        } else if (previousEntryString !== JSON.stringify(newEntry)) {
            setEntry(newEntry)
            // ControlContentViewController handles this behavior

            /*if (this.entry.setHistoricAt &&
                (this.ViewController instanceof GUI.ControlContentViewController) &&
                this.entry.eventId === MessageCenterHelper.KNOWN_ENTRY_ID.INTERCOM_SABOTAGE) {
                 this.closeAction();
            }*/
        }

        if (control && control.getLockingSystemStatusMessage()) {
            navigation.dispatch(CommonActions.reset({
                index: 0,
                routes: [
                    {
                        name: CCScreenStates.INITIAL_CONTENT
                    }
                ]
            }))
        }
    }

    /**
     * Takes an action and returns its cell configuration
     * @param action
     * @return {{content: {title, clickable: boolean}, type: string, action}}
     * @private
     */
    const getCellForAction = (action) => {
        return {
            content: {
                title: action.title,
                textColor: globalStyles.colors.brand,
                clickable: true
            },
            type: GUI.TableViewV2.CellType.Special.COMFORT_ACTION,
            action: executeEntryAction.bind(this, action)
        };
    }

    /**
     * Executes the defined action by either navigating to the provided location,
     * opening the hyperlink, or executing a remote action on the Miniserver
     * @param action
     * @param cell
     * @private
     */
    const executeEntryAction = (action, cell) => {
        // Some actions require specific actions.
        // Such actions can be defined in MessageCenterHelper.KNOWN_ACTIONS
        if (MessageCenterHelper.isActionKnown(action)) {
            MessageCenterHelper.executeKnownAction(action);
            return;
        }

        if (action.location) {
            window.handleOpenURL(action.location);
        } else if (action.link) {
            openWebsite(action.link);
        } else {
            let promise;
            /*let thinkingTimeout = setTimeout(() => {
                cell.setThinking(true);
            }, 300); */

            if (action.hasOwnProperty("requiredPermissions") && !!action.requiredPermissions) {
                //Debug.SystemState && console.log(this.name, "Command needs higher permissions", "Permissions: " + JSON.stringify(action.requiredPermissions));
                promise = SandboxComponent.getPermission(action.requiredPermissions).then(sendCommand.bind(this, Commands.MESSAGE_CENTER.EXEC_ACTION, [action.actionId], action.isSecured));
            } else {
                promise = sendCommand(Commands.MESSAGE_CENTER.EXEC_ACTION, [action.actionId], action.isSecured);
            }

            promise.then(() => {
                if (action.actionId !== MessageCenterHelper.KNOWN_ACTION_ID.ACKNOWLEDGE) {
                    //clearTimeout(thinkingTimeout);
                    //cell.setThinking(false);
                }
            }, (err) => {
                Debug.SystemState && console.log(MessageDetailScreen.name, "Command failed with error", JSON.stringify(err));

                if (action.actionId === MessageCenterHelper.KNOWN_ACTION_ID.ACKNOWLEDGE) {
                    //clearTimeout(thinkingTimeout);
                    //cell.setThinking(false);
                    NavigationComp.showErrorPopup(false, null, _('wear-os.error-message.command-not-executed'));
                }
            });
        }
    }

    /**
     * Opens the provided link:
     * Webinterface: Opens the link in a new tab
     * Native: Opens the link in the browser or the native in App WebView
     * @param link
     * @private
     */
    const openWebsite = (link) => {
        NavigationComp.openWebsite(link);
    }

    /**
     * Sends a command to the messageCenter
     * Note: the messageCenterUuid and entryUuid will be added automatically, additional parameters can be passed if the cmd requires it
     * @param cmd The command, it must include placeholders for the messageCenterUuid and entryUuid
     * @param [args] List of arguments used in the cmd argument
     * @param [isSecured] Indicates that the command requires a visu password
     * @return {*}
     * @private
     */
    const sendCommand = (cmd, args, isSecured) => {
        let formatArgs, prms; // The entry may already be removed by the Miniserver itself. Thus sending any command would fail because this.entry is null.
        // We don't need any special error handling because the Screen will remove it self if there is no entry anymore

        if (entry) {
            formatArgs = [cmd, entry.entryUuid];
            formatArgs = formatArgs.concat(args || []);
            cmd = Commands.format.apply(this, formatArgs); // Parameters: sender, uuid (used to build the command) cmd itself, type use default, if the command is secured, If the command is sent automatically without the users intervention, dontRecord

            prms = SandboxComponent.sendCommand(this, messageCenterUuidRef.current, cmd, null, isSecured, true, true);
        } else {
            prms = Q(true);
        }

        return prms;
    }

    const getTitleForEntry = () => {
        let date = moment.unix(entry.setHistoricAt).format(DateType.WeekdayAndDateTextAndTimeNoYear);

        if (entry.eventId === MessageCenterHelper.KNOWN_ENTRY_ID.DEVICE_UPDATE) {
            return _("message-center.device-ack-at", {
                date: date
            });
        } else {
            return _("message-center.resolved-at", {
                date: date
            });
        }
    }

    const messageCenterEntryContent = useMemo(() => {
        if (!entry) {
            return [];
        }

        let tableContent = [],
            section = {},
            rows = [],
            isHistoricEntry = !!entry.setHistoricAt,
            isConfirmedAt = !!entry.confirmedAt,
            location = MessageCenterHelper.getLocationForEntry(entry);

        tableContent.push({
            rows: [{
                type: GUI.TableViewV2.CellType.LIGHT,
                content: {
                    title: _("message-center.occurred-on", {date: MessageCenterHelper.getFormatedDate(entry)}),
                    subtitle: location,
                    titleStyle: globalStyles.textStyles.footNote.default,
                    color: globalStyles.colors.text.secondary
                }
            }]
        })

        rows.push({
            content: {
                title: entry.desc.replace(/<br>/gm, "\n"),
            }
        })

        if (entry.helpLink) {
            rows.push({
                content: {
                    title: entry.helpLink,
                    titleColor: globalStyles.colors.brand
                },
                action: openWebsite.bind(this, entry.helpLink)
            })
        }

        if (isHistoricEntry) {
            // Don't show the "Resolved at ..." label for this eventIds
            if (entry.eventId !== MessageCenterHelper.KNOWN_ENTRY_ID.REBOOT && entry.eventId !== MessageCenterHelper.KNOWN_ENTRY_ID.MS_UPDATE && entry.eventId !== MessageCenterHelper.KNOWN_ENTRY_ID.INIT_PRESENTATION && entry.eventId !== MessageCenterHelper.KNOWN_ENTRY_ID.OLD_SYSTEM_MESSAGE) {
                rows.push({
                    type: GUI.TableViewV2.CellType.LIGHT,
                    content: {
                        title: getTitleForEntry(),
                        titleStyle: globalStyles.textStyles.footNote.default,
                        color: globalStyles.colors.text.secondary
                    }
                });
            }
        } else {
            // This screen can be displayed in two different ViewControllers
            // • ControlContentViewController: Opened directly from a ControlContent
            //  • Remove the "Jump to Control" action, the user just has to navigate back to go to the control
            // • ActiveMiniserverViewController: From the MessageCenterMessagesScreen
            //  • Display the "Jump to Control" action as usual

            if (fromControlContent) {
                entry.actions = entry.actions.filter((action) => {
                    return action.actionId !== MessageCenterHelper.KNOWN_ACTION_ID.JUMP_TO_CONTROL;
                });
            }

            rows.push(...entry.actions.map(getCellForAction.bind(this)));


            if (isConfirmedAt) {
                rows.push({
                    content: {
                        title: _("message-center.ack-at", {
                            date: moment.unix(entry.confirmedAt).format(DateType.WeekdayAndDateTextAndTimeNoYear)
                        }),
                        titleStyle: globalStyles.textStyles.footNote.default,
                        clickable: false,
                        color: globalStyles.colors.text.secondary
                    },
                    type: GUI.TableViewV2.CellType.LIGHT
                });
            }
        }

        section.rows = rows;
        tableContent.push(section);

        return tableContent
    }, [entry])

    const Icon = (props) => {
        let HeaderIcon = MessageCenterHelper.getIconForSeverityEntry(entry, false);
        let iconColor = MessageCenterHelper.getColorForSeverityEntry(entry, true);
        return <View style={styles.header.iconContainer}>
            <HeaderIcon {...styles.header.icon} fill={iconColor}/>
        </View>
    }

    return <View style={styles.container}>
        <View style={styles.header.container}>
            <Icon/>
            <LxReactText style={styles.header.title}>{entry.title}</LxReactText>
            { entry.affectedName ? <LxReactText style={styles.header.subtitle}>{entry.affectedName}</LxReactText> : null }
        </View>
        {messageCenterEntryContent && <LxReactTableView hideItemSeparator={true} styles={styles.tableView}
                                                        tableContent={messageCenterEntryContent}/>}
    </View>
}

const styles = {
    container: {
        flex: 1,
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
    },
    header: {
        container: {
            marginBottom: globalStyles.spacings.gaps.medium,
        },
        iconContainer: {
            width: "100%",
            justifyContent: "center",
            alignItems: "center"
        },
        icon: {
            height: globalStyles.sizes.icons.systemState,
            width: globalStyles.sizes.icons.systemState
        },
        title: {
            ...globalStyles.textStyles.title2.default,
            color: globalStyles.colors.text.primary,
            width: "100%",
            textAlign: "center",
            marginTop: globalStyles.spacings.gaps.verySmall,
            paddingHorizontal: globalStyles.spacings.gaps.small
        },
        subtitle: {
            ...globalStyles.textStyles.default,
            color: globalStyles.colors.text.secondary,
            width: "100%",
            textAlign: "center",
            marginTop: globalStyles.spacings.gaps.verySmall,
            paddingHorizontal: globalStyles.spacings.gaps.small
        }
    },
    tableView: {
        List: {
            width: "100%",
            maxWidth: globalStyles.sizes.contentMaxWidth,
            alignSelf: "center"
        }
    }
}

export default MessageDetailScreen
