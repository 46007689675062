import {
    App
} from "LxComponents"

class LxReactContextMenu {
    constructor() {
        this.__contextMenuHandler = {};

        App.navigationRef.addListener("state", e => {
            if (this.__contextMenuHandler.menu) {
                this.hideContextMenu(this.__contextMenuHandler.menu);
            }
        })
    }

    showContextMenu (options, title, srcElem, ctxCtor) {
        ctxCtor = ctxCtor || GUI.LxContextMenu;

        let prms;
        if (this.__contextMenuHandler.menu) {
            prms = this.hideContextMenu(this.__contextMenuHandler.menu);
        } else {
            prms = Q.resolve();
        }

        return prms.then(() => {
            let contextMenu = new ctxCtor({
                title: title,
                options: options,
                origin: srcElem,
                delegate: this
            });
            contextMenu.onHideContextMenu = this.hideContextMenu.bind(this);

            contextMenu.onOptionSelected = function (idx) {
                if (this.onOptionSelected) {
                    this.onOptionSelected.call(this, idx);
                } else {
                    console.warn("onOptionSelected not implemented. Selection of " + this.__contextMenuHandler.options[idx].title + " unhandled! ");
                }
            }.bind(this);

            this.__contextMenuHandler.menu = contextMenu;
            this.__contextMenuHandler.title = title;
            this.__contextMenuHandler.options = options;

            setTimeout(() => {
                Q(contextMenu.viewDidLoad()).done(() => {
                    $("root-view").append(contextMenu.getElement());
                    return Q(contextMenu.viewWillAppear()).then(() => {
                        return Q(contextMenu.viewDidAppear()).then(() => {
                            HapticFeedback();
                        });
                    });
                });
            }, 0);
        });
    };


    hideContextMenu (menu) {
        let def = Q.defer()

        if (!this.__contextMenuHandler.menu || menu && this.__contextMenuHandler.menu !== menu) {
            return; // nothing to do.
        }

        setTimeout(() => {
            Q(this.__contextMenuHandler.menu.viewWillDisappear()).done(() => {
                return Q(this.__contextMenuHandler.menu.viewDidDisappear()).then(() => {
                    this.__contextMenuHandler.menu.getElement().remove();
                    this.__contextMenuHandler.menu.destroy();
                    this.__contextMenuHandler.menu = null;
                    this.__contextMenuHandler.title = null;
                    this.__contextMenuHandler.options = null;
                    def.resolve();
                });
            }, (err) => {
                debugger;
            });
        }, 0);

        return def.promise;
    }
}

export default class {
    static get shared() {
        if (!window.LxReactContextMenu) {
            window.LxReactContextMenu = new LxReactContextMenu();
        }

        return window.LxReactContextMenu;

    }
}
