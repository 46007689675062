import { EfmViewType } from "../../efmUtilities";

export default ({viewType, data}) => {
    let horizontalLabels = [];
    let bottomLabelsCentered = true;
    if([EfmViewType.Day, EfmViewType.Actual].includes(viewType)) {
        horizontalLabels = ['00:00', '06:00', '12:00', '18:00'];
        bottomLabelsCentered = false
    } else if (viewType === EfmViewType.Week) {
        data.forEach((datapoint) => {
            horizontalLabels.push(ActiveMSComponent.getMomentFromUtcTimestamp((Array.isArray(datapoint) ? datapoint[0] : datapoint).timestamp).format(LxDate.getDateFormat(DateType.DayMonth)))
        })
    } else if (viewType === EfmViewType.Month) {
        data.forEach((datapoint, idx) => {
            if(idx % 2 !== 0) {
                horizontalLabels.push('')
            } else {
                horizontalLabels.push(ActiveMSComponent.getMomentFromUtcTimestamp((Array.isArray(datapoint) ? datapoint[0] : datapoint).timestamp).format('DD'))
            }
        })
    } else if (viewType === EfmViewType.Year) {
        data.forEach((datapoint) => {
            horizontalLabels.push(ActiveMSComponent.getMomentFromUtcTimestamp((Array.isArray(datapoint) ? datapoint[0] : datapoint).timestamp).format('MM'))
        })
    } else if (viewType === EfmViewType.Lifetime) {
        data.forEach((datapoint) => {
            horizontalLabels.push(ActiveMSComponent.getMomentFromUtcTimestamp((Array.isArray(datapoint) ? datapoint[0] : datapoint).timestamp).format(LxDate.getDateFormat(DateType.Year)))
        })
    }
    return {
        horizontalLabels,
        bottomLabelsCentered,
        highlightDateFormat: (
            viewType === EfmViewType.Week || viewType === EfmViewType.Month ? 'L' :
            viewType === EfmViewType.Year ? 'MMMM YYYY' :
            viewType === EfmViewType.Lifetime ? 'YYYY' :
            undefined
        ),
    }
}
