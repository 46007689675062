import {SCREEN_STATES} from "./ventilationControlEnums";

export default class VentilationControlStatesSrc {
    static getStateSections(control, states, navigation) {
        let sections = [];

        let stateRows = [];

        if (control.details.hasIndoorHumidity) {
            stateRows.push({
                content: {
                    title: _('controls.leaf.humidity'),
                    disclosureText: control.formatHumidity(states.humidityIndoor)
                }
            });
        }

        if (control.details.hasAirQuality) {
            stateRows.push({
                content: {
                    title: _("controls.ventilation.airquality"),
                    disclosureText: control.getCo2QualityString()
                }
            });
            stateRows.push({
                content: {
                    title: _('controls.leaf.co2'),
                    disclosureText: control.formatCO2(states.airQualityIndoor)
                }
            });
        }

        if (control.details.hasHeatExchanger) {
            stateRows.push({
                content: {
                    title: _('controls.leaf.heat-exchanger'),
                    disclosureText: states.heatExchanger ? _("on") : _("off")
                }
            });
        }

        if (!control.hasError()) {
            stateRows.push({
                content: {
                    title: _('settings'),
                    disclosureIcon: true
                },
                action: () => {
                    navigation.navigate(SCREEN_STATES.VENT_SETTINGS_SCREEN, {control});
                }
            });
        }

        if (stateRows.length > 0) {
            sections.push({
                rows: stateRows
            })
        }

        return sections;
    }
}