const PeerState = {
    New: 0,
    Waiting: 1,
    Pending: 2,
    Done: 3,
    Failed: -1
}

class PeerStateInfo {
    constructor(serial, state = PeerState.New, userCount = 0, timeStamp = Date.now()) {
        this.serial = serial;
        this.state = state;
        this.userCount = userCount;
        this.timeStamp = timeStamp;
    }
}

class PeerData {
    constructor(serial, name, stateInfo) {
        this.name = name;
        this.stateInfo = stateInfo || new PeerStateInfo(serial);
        this.users = [];
    }

    setStateInfo(newStateInfo) {
        this.stateInfo = newStateInfo;
    }

    setUsers(newUsers) {
        this.users = newUsers
    }

    hasState(peerState) {
        return this.stateInfo.state === peerState;
    }
}

export {
    PeerData,
    PeerState,
    PeerStateInfo
}
