'use strict';
/*
 title
 color
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.CENTERED_BUTTON = "CenteredButtonCell";

    class CenteredButtonCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(title) {
                return $('<div class="centered-button-cell__title text-overflow-ellipsis">' + title + '</div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("centered-button-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.contentPlaceholder.append(CenteredButtonCell.Template.getTemplate(this.content && this.content.title));

            if (this.content.color) {
                this.element.css("background-color", this.content.color);
            }
        }

        clickableAllowed() {
            return true;
        }

    }

    GUI.TableViewV2.Cells.CenteredButtonCell = CenteredButtonCell;
    return GUI;
}(window.GUI || {});
