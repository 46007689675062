import Icons from "IconLib";
import globalStyles from "GlobalStyles";
import PropTypes from "prop-types";
import {useEffect, useMemo, useState} from "react";
import {
    ButtonType,
    LxReactButton,
    LxReactHeader,
    LxReactText, useCCEvent,
    useCountDown
} from "../Components";
import {View} from "react-native";

export const PairedTabletWarningOrigin  = {
    ARCHIVE: "archive",
    CREDENTIALS: "credentials"
};

const pairedMsName = () => {
    let ms = PairedAppComponent.getPairedAppMiniserver(),
        name = "";

    name = ms.msName || ms.name;
    if (ms.serialNo) {
        if (name) {
            name += " (" + ms.serialNo + ")"
        } else {
            name = ms.serialNo
        }
    }
    if (!name) {
        name = ms.localUrl || ms.remoteUrl;
    }
    return "Miniserver " + name;
}

function Btn(props) {
    return <LxReactButton
        {...props}
        containerStyle={styles.btn}
        pressableStyle={{...styles.btnCntnt, ...(props.style || {}) }} />
}

function UnpairButton() {
    const handleUnpairRequest = () => {
        PairedAppComponent.handleUnpairTapped();
    }

    return <Btn
        leftIcon={ () => {
            return <Icons.BreakChain style={styles.btnIcon} />
        }}
        text={_("managed-tablet.unpair-tablet")}
        pkey={"unpair"}
        onPress={handleUnpairRequest}
        buttonType={ButtonType.SECONDARY} />
}

function RetryButton() {
    const retry = () => {
        PairedAppComponent.connectToPairedMiniserver();
    }

    return <Btn
        leftIcon={ () => {
            return <Icons.ArrowCircle style={styles.btnIcon} />
        }}
        text={_("try-again")}
        pkey={"retry"}
        onPress={retry}
        buttonType={ButtonType.PRIMARY} />
}

function ResumeToPairedMs({origin}) {
    const calcRemaining = (resTs = 0) => {
        return Math.round(Math.max(resTs - Date.now(), 0) / 1000);
    }
    const initialInfo = PairedAppComponent.getResumeToPairedMsTimeoutInfo();
    const [timerState, setTimerState] = useState({
        active: initialInfo.active,
        resumeTs: initialInfo.resumeTs,
        remaining: calcRemaining(initialInfo.resumeTs)
    });


    useCCEvent([CCEvent.PairingResumeTimer], (ev, arg) => {
        setTimerState({active: arg.active, resumeTs: arg.resumeTs, remaining: calcRemaining(arg.resumeTs) });
    }, []);

    useEffect(() => {
        let ticker;
        if (timerState.active) {
           ticker = setInterval(() => {
               setTimerState(prevState => {
                   return {
                       ...prevState,
                       remaining: calcRemaining(prevState.resumeTs)
                   }
               });
           }, 500);
        }

        return () => {
            clearInterval(ticker);
        }
    }, [timerState.active]);

    const text = () => {
        const duration = LxDate.formatSecondsShort(timerState.remaining, true, true);
        if (origin === PairedTabletWarningOrigin.CREDENTIALS) {
            return _("managed-tablet.auto-auth-retry", {duration});
        } else {
            return _("managed-tablet.auto-resume", {duration});
        }
    }

    if (timerState.active) {
        return <LxReactText style={styles.text}>{text()}</LxReactText>
    } else {
        return null;
    }
}

function LoginFailedWarning({remainingBlockedTime = 0, loginState = -1, lastPwdChange = 0}) {
    const currRemainingBlockedTime = useCountDown(remainingBlockedTime);
    const subTitle = useMemo(() => {
        let subTitle;

        if (loginState === LoginState.INVALID_CREDS || loginState === LoginState.TEMP_BLOCKED) {
            subTitle = _("managed-tablet.login-failed", { miniserver: pairedMsName()})

        }  else {
            subTitle = _("managed-tablet.generic-login-fail", { miniserver: pairedMsName()});
        }

        return subTitle;
    }, [loginState]);

    const BlockedText = ({remainingTime}) => {
        const text = () => {
            return (_("pre-load-login.too-many-wrong-logins") + " (" + LxDate.formatSecondsShort(remainingTime, true, true) + ")")
        }
        return <LxReactText style={styles.blockedText}>{text()}</LxReactText>
    }

    const ActionArea = ({blockedTime}) => {
        if (blockedTime > 0) {
            return <BlockedText remainingTime={blockedTime}/>
        } else {
            return <View style={styles.btnCntr}>
                <UnpairButton />
                <RetryButton />
            </View>;
        }
    }

    return <View style={styles.rootCntr}>
        <LxReactHeader
            style={styles.hdr}
            iconStyle={{ fill: globalStyles.colors.red } }
            icon={Icons.Tablet}
            title={_("managed-tablet.title")}
            subtitle={subTitle} />
        <ActionArea blockedTime={currRemainingBlockedTime}/>
        {currRemainingBlockedTime > 0 ? null : <ResumeToPairedMs origin={PairedTabletWarningOrigin.CREDENTIALS}/>}
    </View>
}


function ArchiveWarning() {
    function ResumeButton() {
        return <Btn
            text={_("back")}
            pkey={"retry"}
            onPress={() => {
                PairedAppComponent.connectToPairedMiniserver();
            }}
            buttonType={ButtonType.PRIMARY} />
    }

    return <View style={styles.rootCntr}>
        <LxReactHeader
            style={styles.hdr}
            icon={Icons.Tablet}
            title={_("managed-tablet.title")}
            subtitle={_("managed-tablet.already-paired-desc", {miniserver: pairedMsName()})}
            />
        <View style={styles.btnCntr}>
            <UnpairButton />
            <ResumeButton />
        </View>
        <ResumeToPairedMs origin={PairedTabletWarningOrigin.ARCHIVE}/>
    </View>
}


export default function PairedTabletWarning({origin, details}) {
    if (origin === PairedTabletWarningOrigin.CREDENTIALS) {
        return <LoginFailedWarning
            lastPwdChange={details?.lastPwdChange}
            loginState={details?.loginState}
            loginRetryCount={details?.loginRetryCount}
            remainingBlockedTime={details?.remainingBlockedTime} />;
    } else {
        return <ArchiveWarning />;
    }
}


PairedTabletWarning.propTypes = {
    origin: PropTypes.oneOf(Object.values(PairedTabletWarningOrigin)).isRequired,
    details: PropTypes.object
}

const styles = {
    rootCntr: {
        ...globalStyles.customStyles.screenContent,
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    text: {
        ...globalStyles.textStyles.subheadline.italic,
        color: globalStyles.colors.text.secondary,
        marginTop: globalStyles.spacings.gaps.small,
        textAlign: "center"
    },
    blockedText: {
        ...globalStyles.textStyles.subheadline.italic,
        color: globalStyles.colors.orange,
        marginTop: globalStyles.spacings.gaps.small,
        textAlign: "center"
    },
    hdr: {
        minHeight: 250,
        flex: 0,
        marginTop: globalStyles.spacings.gaps.small,
        marginBottom: globalStyles.spacings.gaps.small
    },
    btnCntr: {
        flexDirection: "row",
        width: "100%"
    },
    btnIcon: {
        width: globalStyles.sizes.icons.small,
        height: globalStyles.sizes.icons.small,
        fill: globalStyles.colors.text.primary
    },
    btn: {
        width: "50%",
        margin: globalStyles.spacings.gaps.verySmall
    },
    btnCntnt: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    }
}
