'use strict';

import CancelablePromise, {cancelable} from 'cancelable-promise';

export const EfmViewType = {
    Actual: "live",
    Day: "day",
    Week: "week",
    Month: "month",
    Year: "year",
    Lifetime: "lifetime"
}
export const EfmNodeType = {
    Grid: "Grid",
    Storage: "Storage",
    Production: "Production",
    Consumption: "Load",
    Group: "Group"
}

const EfmNodeSubtype = {
    Wallbox: "Wallbox"
}

import {getLeafNodesForStatistics} from "../../react-comps/utils";

export class EfmUtilities {
    /**
     * Returns the time range to display for a specific viewType for a certain timestamp.
     * E.g.: If viewType === week and timestamp is this Tuesday,
     * the range from Monday 00:00 until Sunday 23:59 will be returned.
     * control is necessary to find the earliest timestamp for which data is available.
     * Extracted from the EfmNode class so it does not depend on the availability of a node. (Might not be available, e.g. Spider)
     * @param {Control} control
     * @param {String} viewType
     * @param {Number} unixUtcTs
     * @returns
     */
    static getStatisticRange(control, viewType, unixUtcTs) {
        let rangeId,
            range;

        switch (viewType) {
            case EfmViewType.Lifetime:
            case EfmViewType.Year:
                rangeId = "year";
                break;
            case EfmViewType.Month:
                rangeId = "month";
                break;
            case EfmViewType.Week:
                rangeId = "week";
                break;
            case EfmViewType.Actual:
            case EfmViewType.Day:
            default:
                rangeId = "day";
                break;
        }
        range = ActiveMSComponent.getStartAndEndOfAsUnixUtcTimestamp(unixUtcTs, rangeId);

        if (viewType === EfmViewType.Lifetime) { // start with the beginning of the year the stats where activated.
            let availableSince = false;
            if (control && control.hasOwnProperty("getStatisticGroupForOutput")) {
                availableSince = control.getStatisticGroupForOutput("total")();
            }
            // 1230768000 ==> 1.1.2009
            range.start = availableSince ? ActiveMSComponent.getStartAndEndOfAsUnixUtcTimestamp(availableSince, rangeId).start : 1230768000;
        }

        return range;
    }

    static reduceStatistics({header, data, dayStats}, viewType) {
        let usedHeader = viewType === EfmViewType.Actual && dayStats ? dayStats.header : header;
        let usedData = viewType === EfmViewType.Actual && dayStats ? dayStats.data : data;

        // reduce the datapoints of all timestamps into one flat array, containing a value for each output
        let reducedValues = usedData.reduce((prev, curr) => {
            curr.values.forEach((val, idx) => {
                prev[idx] = (prev[idx] || 0) + val;
            });
            return prev;
        }, []);

        // create a value object, containing a property for each output value in the stats, with the value computed
        let valueObj = {};
        usedHeader.forEach((headerObj, idx) => {
            valueObj[headerObj.output] = reducedValues[idx];
        })

        return valueObj;
    }
}

export class EfmNodeFactory {

    /**
     * Returns an object that contains a promise that contains one statistic with the combined values of each node and
     * childnode representing a certain nodeType.
     * E.g. { Grid: {CancelablePromise.Promise<[{header: number[], ts: number}]>}, Production: {CancelablePromise.Promise<[{values: number[], ts: number}]>} }
     * @param passedNodeList  the list of nodes from which to collect
     * @param viewType  desired viewType (actual, day, ...)
     * @param unixUtcTs desired timestamp (unix utc in seconds)
     * @param [ignoredTypes:Array[string]] optionally provide a list of types that aren't required here.
     * @returns {{}}    object containing the result promises for each of the types.
     */
    static getStatisticsForAllNodeTypes(passedNodeList, viewType, unixUtcTs, ignoredTypes) {
        const nodeList = []
        getLeafNodesForStatistics(nodeList, passedNodeList, ignoredTypes);
        let availableStatPromises = {};
        Debug.Control.Efm.Stat && console.log("EfmStats", "Statistic Acquisition for nodes ", nodeList);
        Object.values(EfmNodeType).forEach(nodeTypeVal => {
            if (nodeTypeVal === EfmNodeType.Group) {
                // ignore group nodes!
            } else {
                let nodeTypePromises = [];
                try { // blackscreen if efm is resumed via last-location in a deeper state. nodes don't have functions anymore.
                    nodeList.forEach((node) => {
                        if (node.hasNodeType(nodeTypeVal) && node.isAvailable) {
                            nodeTypePromises.push(node.getStatisticsTyped(viewType, unixUtcTs, nodeTypeVal));
                        }
                    })
                    if (nodeTypePromises.length > 0) {
                        availableStatPromises[nodeTypeVal] = CancelablePromise.allSettled(nodeTypePromises).then(statResults => {
                            const successfulStats = statResults
                                .filter(({ status }) => status === "fulfilled" )
                                .map(({value}) => value);

                            if(successfulStats.length > 0) {
                                if (successfulStats.length > 1) {
                                    return SandboxComponent.combineStatistics(successfulStats);
                                } else {
                                    return successfulStats[0];
                                }
                            }
                        });
                    }
                } catch (ex) {
                    console.error("Failed during getStatisticsForAllNodeTypes - " + ex.message);
                    console.error(ex);
                }

            }
        });

        if (Debug.Control.Efm.Stat) {
            CancelablePromise.all(Object.values(availableStatPromises)).then(results => {
                console.log("EfmStats", "getStatisticsForAllNodeTypes > resolved: ", results);
            });
        }

        return availableStatPromises;
    }

    /**
     * Requests the node values of all nodes in the list (groups will return the values of their childs) and resolves
     * with a result object, containing the combined value of the production/grid/storage and tracked consumption value
     * of that node & it's subnodes
     * @param nodesToCheck
     * @param viewType
     * @param viewTs
     * @returns {CancelablePromise<{storage: {}, production: {}, grid: {}, trackedConsumption: {}}>}
     */
    static requestTypedValues = (nodesToCheck, viewType, viewTs, isRecursion = false) => {
        let trackedConsumptions = [];
        let productions = [];
        let storages = [];
        let grids = [];
        let promises = [];
        Debug.Control.Efm.TypedNodeValue && !isRecursion &&
            console.log("TypedNodeValue", `requestTypedValues: vt=${viewType}, ts=${moment.unix(viewTs).format(DateType.CSV)}`, nodesToCheck);

        nodesToCheck.forEach((node) => {
            if (!node.isAvailable) {
                // ignore
            } else if (node.nodeType === EfmNodeType.Group) {
                // group may also contain values of various types
                promises.push(EfmNodeFactory.requestTypedValues(node.getChildNodes(), viewType, viewTs, true).then((res) => {
                    Debug.Control.Efm.TypedNodeValue && !isRecursion && console.log("TypedNodeValue", `      *group: "${node.title}" (${node.nodeType}) => ${JSON.stringify(res)}`);
                    trackedConsumptions.push(res.trackedConsumption);
                    productions.push(res.production);
                    storages.push(res.storage);
                    grids.push(res.grid);
                }));

            } else {
                promises.push(node.getNodeValue(viewType, viewTs).then((nodeRes) => {
                    Debug.Control.Efm.TypedNodeValue && console.log("TypedNodeValue", `      node "${node.title}" (${node.nodeType}) => ${JSON.stringify(nodeRes)}`);

                    switch (node.nodeType) {
                        case EfmNodeType.Consumption:
                            trackedConsumptions.push(nodeRes);
                            break;
                        case EfmNodeType.Storage:
                            storages.push(nodeRes);
                            break;
                        case EfmNodeType.Production:
                            productions.push(nodeRes);
                            break;
                        case EfmNodeType.Grid:
                            grids.push(nodeRes);
                            break;
                        default:
                            break;
                    }
                    return true;
                }));
            }
        });

        const reduceNodeValue = (prev, curr) => {
            let combined = {...prev};
            Object.keys(curr).forEach((key) => {
                if (combined[key]) {
                    combined[key] += curr[key];
                } else {
                    combined[key] = curr[key];
                }
            });
            return combined;
        }

        return CancelablePromise.allSettled(promises).then(() => {
            if (Debug.Control.Efm.TypedNodeValue && !isRecursion) {
                console.log("TypedNodeValue", `    trackedConsumptions: ${JSON.stringify(trackedConsumptions)}`);
                console.log("TypedNodeValue", `               storages: ${JSON.stringify(storages)}`);
                console.log("TypedNodeValue", `            productions: ${JSON.stringify(productions)}`);
                console.log("TypedNodeValue", `                 grids: ${JSON.stringify(grids)}`);
            }
            let result = {
                trackedConsumption: trackedConsumptions.reduce(reduceNodeValue, {}),
                storage: storages.reduce(reduceNodeValue, {}),
                production: productions.reduce(reduceNodeValue, {}),
                grid: grids.reduce(reduceNodeValue, {})
            }
            Debug.Control.Efm.TypedNodeValue && !isRecursion && console.log("TypedNodeValue", ` ==> combined: ${JSON.stringify(result)}`);
            return result;
        });
    }


    /**
     * Uses the nodes provided to compute the untracked consumption
     * @param nodesToCheck  array of nodes, tracked nodes only.
     * @param viewType      live/day/month/..
     * @param viewTs        unix utc timestamp.
     * @returns {CancelablePromise<{}>}
     */
    static getOtherValue = (nodesToCheck, viewType, viewTs) => {
        Debug.Control.Efm.OtherNodeValue && console.log("OtherNodeValue", `getOtherNodeValue: vt=${viewType}, ts=${moment.unix(viewTs).format(DateType.CSV)}`, nodesToCheck);

        const _oVal = (obj, output) => {
            return obj.hasOwnProperty(output) ? obj[output] : 0;
        }

        const getActualOther = (cons, grid, prod, storage) => {
            let output = "actual";
            let consVal = _oVal(cons, output);
            let gridVal = _oVal(grid, output);
            let prodVal = _oVal(prod, output);
            let storageVal = _oVal(storage, output);
            return prodVal + gridVal + storageVal - consVal;
        }
        const getTotalOther = (cons, grid, prod, storage) => {
            let output = "total";
            let outputNeg = "totalNeg";
            let consumption = _oVal(cons, output);
            let gridExport = _oVal(grid, outputNeg);
            let gridImport = _oVal(grid, output);
            let production = _oVal(prod, output);
            let storageDelivery = _oVal(storage, output);
            let storageCharge = _oVal(storage, outputNeg);

            return production - gridExport + gridImport - storageCharge + storageDelivery - consumption;
        }

        return EfmNodeFactory.requestTypedValues(nodesToCheck, viewType, viewTs).then((typedResults) => {
            Debug.Control.Efm.OtherNodeValue && console.log("OtherNodeValue", `  typed results:  `);
            Debug.Control.Efm.OtherNodeValue && console.log("OtherNodeValue", `        consumption = ${JSON.stringify(typedResults.trackedConsumption)}`);
            Debug.Control.Efm.OtherNodeValue && console.log("OtherNodeValue", `         production = ${JSON.stringify(typedResults.production)}`);
            Debug.Control.Efm.OtherNodeValue && console.log("OtherNodeValue", `               grid = ${JSON.stringify(typedResults.grid)}`);
            Debug.Control.Efm.OtherNodeValue && console.log("OtherNodeValue", `            storage = ${JSON.stringify(typedResults.storage)}`);
            let combined = {};
            switch (viewType) {
                case EfmViewType.Actual:
                    combined["actual"] = getActualOther(typedResults.trackedConsumption, typedResults.grid, typedResults.production, typedResults.storage);
                    break;
                default:
                    combined["total"] = getTotalOther(typedResults.trackedConsumption, typedResults.grid, typedResults.production, typedResults.storage);
                    break;
            }

            Debug.Control.Efm.OtherNodeValue && console.log("OtherNodeValue", `       -------------------------------------`);
            Debug.Control.Efm.OtherNodeValue && console.log("OtherNodeValue", `             combined = ${JSON.stringify(combined)}`);

            return combined;
        });
    }


    /**
     * Function used to create classes of EfmNodes from the EFMs details nodes property.
     * @param jsonNodeArray  the JSON array from the EFMs details
     * @param efmUuid   the uuid of the EFM itself
     * @returns {EfmNode[]}
     */
    static createNodesForEfm(jsonNodeArray, efmUuid) {
        return jsonNodeArray.map((node) => {
            return EfmNodeFactory.createNode(node, efmUuid);
        });
    }

    /**
     * Used to instantiate an individual node.
     * @param jsonNode JSON object from EFM Details
     * @param efmUuid uuid of the EFM containing this node
     * @param level on what level is this node located?
     * @param [parentNodeUuid] optional, if provided it specifies the uuid of the parent node.
     * @returns {EfmNode}   the EfmNode-Instance created
     */
    static createNode(jsonNode, efmUuid, level = 0, parentNodeUuid = null) {
        let nodeInstance;

        const verifyNodeType = (inputType) => {
            let resultType,
                subType;

            //TODO-woessto: remove once correct on ms
            switch (inputType) {
                case "Production Power":
                case "Produktion":
                    inputType = EfmNodeType.Production;
                    break;
                case "":
                    inputType = EfmNodeType.Consumption;
                    break;
                default:
                    break;
            }

            // ensure first character is uppercased
            inputType = inputType.charAt(0).toUpperCase() + inputType.slice(1);

            switch (inputType) {
                case EfmNodeType.Consumption:
                case EfmNodeType.Storage:
                case EfmNodeType.Grid:
                case EfmNodeType.Production:
                case EfmNodeType.Group:
                    resultType = inputType;
                    break;
                case EfmNodeSubtype.Wallbox: // New node type wallbox supported, but as of now handled like a load
                    subType = EfmNodeSubtype.Wallbox;
                    resultType = EfmNodeType.Consumption;
                    break;
                default:
                    resultType = EfmNodeType.Consumption;
                    break;
            }
            return { type: resultType, subType: subType };
        }

        let verifiedTypes = verifyNodeType(jsonNode.nodeType)
        jsonNode.nodeType = verifiedTypes.type;
        jsonNode.nodeSubType = verifiedTypes.subType;

        switch (jsonNode.nodeType) {
            case EfmNodeType.Grid:
                nodeInstance = new EfmGridNode(jsonNode, efmUuid, level, parentNodeUuid);
                break;
            case EfmNodeType.Production:
                nodeInstance = new EfmProductionNode(jsonNode, efmUuid, level, parentNodeUuid);
                break;
            case EfmNodeType.Storage:
                nodeInstance = new EfmStorageNode(jsonNode, efmUuid, level, parentNodeUuid);
                break;
            case EfmNodeType.Group:
                nodeInstance = new EfmGroupNode(jsonNode, efmUuid, level, parentNodeUuid);
                break;
            case EfmNodeType.Consumption:
            default: // if no node type is provided, it's considered a consumption
                nodeInstance = new EfmConsumptionNode(jsonNode, efmUuid, level, parentNodeUuid);
                break;

        }
        return nodeInstance;
    }
}

// region Nodes

class EfmNode {

    constructor({uuid, actualEfmState, title, nodeType, nodeSubType, nodes, ctrlUuid, icon, rest, restName = null,
                    restIcon = null}, efmUuid, level, parentNodeUuid) {
        this._hasNodes = !!nodes ? (Array.isArray(nodes) && (nodes.length > 0)) : false;
        this._title = title;
        this._uuid = uuid;
        this._nodeType = nodeType;
        this._nodeSubType = nodeSubType;
        this._level = level;
        this._icon = icon;
        this._rest = !!rest;
        this._restName = restName;
        this._restIcon = restIcon;
        this._actualEfmState = actualEfmState;
        this._actualValueListeners = {};

        this._parentEfmUuid = efmUuid;
        this._parentEfm = ActiveMSComponent.getStructureManager().getControlByUUID(efmUuid);

        // the node uuid of the group this node is part of. null if on root level.
        this._parentNodeUuid = parentNodeUuid;

        if (ctrlUuid) {
            this._controlUuid = ctrlUuid;
            this._control = ActiveMSComponent.getStructureManager().getControlByUUID(ctrlUuid);
            if (!this._control) {
                console.error(this.dbgName, "Referenced control with UUID " + ctrlUuid + " is missing in structure file!");
            }
        } else {
            this._controlUuid = null;
        }

        this._hasNodes && this._initNodes(nodes);
    }

    /**
     * Returns true if the node is available to be used. False if it won't work as sth is missing.
     * @returns {*|boolean}
     */
    get isAvailable() {
        if (this._hasNodes) {
            return true;
        } else {
            return !!this._control;
        }
    }

    get nodeType() {
        return this._nodeType;
    }

    get nodeSubType() {
        return this._nodeSubType;
    }

    get control() {
        return this._control;
    }

    get dbgName() {
        return this._parentEfm.getName() + " - " + this._uuid + " - " + this.title;
    }

    get uuid() {
        return this._uuid;
    }

    get title() {
        //if the meter title gets updated, the _title property won't change so prefer the control name over the title prop, use title for fallback
        if (this.control) {
            return this.control.getName();
        } else if (this._title) {
            return this._title;
        } else {
            return "--";
        }
    }
    set title(newVal) {
        // do nothing.
    }
    get hasChildNodes() {
        return this._hasNodes;
    }
    get parentNodeUuid() {
        return this._parentNodeUuid;
    }
    get childNodes() {
        return this._nodes;
    }
    set hasChildNodes(newVal) {
        // do nothing;
    }

    get computeUntracked() {
        return this._rest;
    }

    get untrackedIcon() {
        return this._restIcon;
    }

    get untrackedName() {
        return this._restName || _("efm.other-node-title");
    }

    get icon() {
        // for nodes that have a control assigned, the icon set in the EFM is always synced to the meters icon.
        // In order to avoid having to patch the nodes-structure within the EFM when a structure patch arrives due to
        // changes made in the expert mode, simply use the icon returned by the assigned control - which is being
        // patched by the basic logic already.
        if (this.control) {
            return this.control.getIcon();
        }
        return this._icon;
    }

    /**
     * Returns true if the meter behind this node (or one of the meters inside the group node) is bidirectional.
     * @returns {boolean}
     */
    get isBidirectional() {
        if (this.control) {
            return this.control.isBidirectional();
        } else if (this.hasChildNodes) {
            return this.childNodes.some(child => child.isBidirectional);
        } else {
            return true; // when in doubt, assume it is.
        }
    }

    /**
     * Distribution boards are always loads that have a control and have childNodes.
     * @returns {false|boolean}
     */
    get isDistributionBoard() {
        return this.control && this.nodeType === EfmNodeType.Consumption && this.hasChildNodes;
    }

    // region public
    /**
     * The callback provided will be called with the current nodeValue for viewType="acutal"
     * @param callback
     * @returns {(function(): void)|*}  a function to call once the callback should be unregistered.
     */
    registerForActualNodeValueUpdates(callback) {
        this._actualValueListeners = this._actualValueListeners || {};
        let randomId = this._getRandomIdForListener(this._actualValueListeners);

        // when the first callback is registered, register the node on the state containers
        if (!this._hasListeners(this._actualValueListeners)) {
            this._registerForLiveStates();
        }
        Debug.Control.Efm.Node && console.log(this.dbgName, "registerForActualNodeValueUpdates " + randomId + ", #" + Object.keys(this._actualValueListeners).length);
        this._actualValueListeners[randomId] = callback;

        // return a function that takes care of removing the callback & also unregistering the node form state containers
        // after the last callback has been removed.
        return () => {
            Debug.Control.Efm.Node && console.log(this.dbgName, "unregisterFromActualNodeValueUpdates " + randomId + ", #" + Object.keys(this._actualValueListeners).length);
            delete this._actualValueListeners[randomId];
            if (!this._hasListeners(this._actualValueListeners)) {
                this._removeFromLiveStates();
            }
        };
    }

    registerForTotalNodeValueUpdates(callback, viewType, unixUtcTs) {
        let prms;
        if (!this._isViewingNow(viewType, unixUtcTs)) {
            return () => {};
        }
        this._totalValueListeners = this._totalValueListeners ||{};
        let randomId = this._getRandomIdForListener(this._totalValueListeners);

        // when the first callback is registered, register the node on the state containers
        if (!this._hasListeners(this._totalValueListeners)) {
            prms = this._startTotalValueUpdateTimer(viewType, unixUtcTs);
        }

        this._totalValueListeners[randomId] = callback;

        return () => {
            delete this._totalValueListeners[randomId];
            if (!this._hasListeners(this._totalValueListeners)) {
                this._stopTotalValueUpdateTimer();
            }
            prms?.cancel();
        }
    }

    registerForStatisticUpdates(callback, viewType, unixUtcTs) {
        Debug.Control.Efm.Node && console.log(this.dbgName, "registerForStatisticUpdates " + viewType);
        let viewUnit;
        switch (viewType) {
            case EfmViewType.Actual:
            case EfmViewType.Lifetime:
                viewUnit = EfmViewType.Day;
                break;
            default:
                viewUnit =  viewType;
                break;
        }
        if (!ActiveMSComponent.isUnixUtcTsWithinCurrentRange(unixUtcTs, viewUnit)) {
            return;
        }

        this._statisticListeners = this._statisticListeners ||{};
        let randomId = this._getRandomIdForListener(this._statisticListeners);

        // when the first callback is registered, register the node on the state containers
        if (!this._hasListeners(this._statisticListeners)) {
            this._startStatisticValueUpdateTimer(viewType, unixUtcTs);
        }

        this._statisticListeners[randomId] = callback;

        return () => {
            delete this._statisticListeners[randomId];
            if (!this._hasListeners(this._statisticListeners)) {
                this._stopStatisticValueUpdateTimer();
            }
        }
    }

    getChildNodes() {
        return this._nodes;
    }

    /**
     * Will request the statistic from the control or the childnodes.
     * @param viewType              actual/day/week/month/year/lifetime
     * @param unixUtcTimeStamp      unix utc timestamp of a period.
     * @returns {CancelablePromise<[{values: number[], ts: number}]>}
     */
    getStatistics(viewType, unixUtcTimeStamp) {
        if (!this.isAvailable) {
            console.error(this.dbgName, "getStatistics " + viewType + " --> unavailable!");
            return CancelablePromise.resolve({err: "unavailable"});
        }
        let resultPromise;
        Debug.Control.Efm.Node && console.log(this.dbgName, "getStatistics " + viewType);
        if (this._control) {
            resultPromise = this._requestStatisticsFromControl(viewType, unixUtcTimeStamp);
        } else if (this._hasNodes) {
            resultPromise = this._gatherStatisticsFromChildNodes(viewType, unixUtcTimeStamp);
        } else {
            console.error(this.dbgName, "getStatistics: control not in structure file & no child nodes! " + this._controlUuid);
            resultPromise = CancelablePromise.resolve({ err: "No control & no child nodes" });
        }
        return resultPromise;
    }

    /**
     * Returns the statistics, but only for a certain type of node.
     * @param viewType
     * @param unixUtcTimeStamp
     * @param nodeType
     * @returns {CancelablePromise<{values: number[], ts: number}[]>}
     */
    getStatisticsTyped(viewType, unixUtcTimeStamp, nodeType) {
        if (!this.isAvailable) {
            console.error(this.dbgName, "getStatisticsTyped " + viewType + " - " + nodeType + " --> unavailable!");
            return CancelablePromise.resolve({err: "unavailable"});
        }
        Debug.Control.Efm.Node && console.log(this.dbgName, "getStatisticsTyped " + viewType + " - " + nodeType);
        if (this.hasNodeType(nodeType)) {
            if (this._nodeType === nodeType) {
                return this.getStatistics(viewType, unixUtcTimeStamp);
            } else if (this._hasNodes) {
                return this._gatherStatisticsFromChildNodes(viewType, unixUtcTimeStamp, nodeType);
            }
        } else {
            console.error(this.dbgName, "getStatisticsTyped failed, does not represent the node type " + nodeType);
            return CancelablePromise.reject(new Error(this.dbgName + " does not represent the node type " + nodeType));
        }
    }

    /**
     * Returns true if either the node itself or child nodes of it have that node type.
     * @param nodeType
     * @returns {boolean}
     */
    hasNodeType(nodeType) {
        let result, nodeTypes = this.getNodeTypes();
        result = !!nodeTypes[nodeType];
        Debug.Control.Efm.Node && console.log(this.dbgName, "hasNodeType: " + nodeType + ", nodeTypes" + JSON.stringify(nodeTypes) + " = " + result);
        return result;
    }

    /**
     * Returns an object with a boolean attribute set to true for each nodeType represented by either this node or its
     * child nodes
     * @returns {{}}
     */
    getNodeTypes() {
        let result = {};
        if (!this.hasChildNodes) {
            result[this._nodeType] = true;
        } else {
            this._nodes.forEach(childNode => {
                Object.keys(childNode.getNodeTypes()).forEach((typeKey) => {
                    result[typeKey] = true;
                });
            })
        }
        return result;
    }


    /**
     *
     * @param viewType  actual/day/week/month/year
     * @param [unixUtcTimeStamp] required for requesting values other than the current ones.
     * @returns {CancelablePromise<{total: number, totalNeg: number, actual: number, storage: number}>}
     */
    getNodeValue(viewType = EfmViewType.Actual, unixUtcTimeStamp = 0) {
        if (!this.isAvailable) {
            console.error(this.dbgName, "getNodeValue " + viewType + " => unavailable!");
            return CancelablePromise.resolve({
                actual: 0,
                total: 0,
                totalNeg: 0,
                storage: 0
            });
        }

        let promise;
        if (viewType === EfmViewType.Actual) { // unix timestamp does not need to be minded, cannot change timestamp
            if (this._actualEfmStateAvailable()) {
                Debug.Control.Efm.Node && console.log(this.dbgName, "getNodeValue " + viewType + " - from efmState");
                // get Actual state from EFM state container
                promise = this._getCurrentActualValueFromEfmState();

            } else if (this._control && this._control.statesReady) { //
                Debug.Control.Efm.Node && console.log(this.dbgName, "getNodeValue " + viewType + " - from control state");
                // get from control state container
                promise = this._getCurrentActualValueFromControl();

            } else if (this._hasNodes) {
                Debug.Control.Efm.Node && console.log(this.dbgName, "getNodeValue " + viewType + " - from control child nodes");
                promise = this._gatherNodeValueFromChildNodes(viewType, unixUtcTimeStamp);

            } else {
                Debug.Control.Efm.Node && console.log(this.dbgName, "getNodeValue " + viewType + " - from EFM");
                promise = this._requestCurrentNodeValueFromEfm(viewType);

            }
        } else if (this._isViewingNow(viewType, unixUtcTimeStamp)) {

            if (this._currentNodeValueAvailableInStates(viewType)) {
                Debug.Control.Efm.Node && console.log(this.dbgName, "getNodeValue " + viewType + " - from control");
                promise = this._getCurrentTotalValueFromControl(viewType);
            } else if (this._hasNodes) {
                Debug.Control.Efm.Node && console.log(this.dbgName, "getNodeValue " + viewType + " - from control child nodes");
                promise = this._gatherNodeValueFromChildNodes(viewType, unixUtcTimeStamp);
            } else {
                // day/week/month/year-view - most recent, data can be retrieved from EFM (or control)
                Debug.Control.Efm.Node && console.log(this.dbgName, "getNodeValue " + viewType + " - from efm");
                promise = this._requestCurrentNodeValueFromEfm(viewType);
            }

        } else if (this._control) {
            // request statistic values for the timestamp specified
            Debug.Control.Efm.Node && console.log(this.dbgName, "getNodeValue " + viewType + " - from control statistics");
            promise = this._getNodeValueFromStatistics(viewType, unixUtcTimeStamp);
        } else if (this._hasNodes) {
            Debug.Control.Efm.Node && console.log(this.dbgName, "getNodeValue " + viewType + " - from child nodes");
            promise = this._gatherNodeValueFromChildNodes(viewType, unixUtcTimeStamp);
        } else {
            console.log(this.dbgName, "getNodeValue " + viewType + " -----> UNAVAILABLE");
            promise = this._handleNotAvailable("No control or subNodes to acquire value from!");
        }
        return promise.then(res => {
            return cloneObject(res); // ensure no one messes with it.
        });
    }

    // endregion

    formatNodeValue(nodeValue) {
        let result = {};
        Object.keys(nodeValue).forEach((outputName) => {
           result[outputName] = this._formatOutputValue(outputName, nodeValue[outputName]);
        });
        return result;
    }

    // region private

    _actualEfmStateAvailable() {
        return this._actualEfmState && this._onRootLevel() && false; //TODO-woessto: enable once EFM is ready
    }

    _initNodes(jsonNodes) {
        this._nodes = jsonNodes.map(jsonNode =>
            EfmNodeFactory.createNode(jsonNode, this._parentEfmUuid, this._level + 1, this.uuid)
        );
    }

    _onRootLevel() {
        return this._level === 0;
    }

    /**
     * Iterates over the child nodes and collects their nodeValues, then combines them into one.
     * @param viewType
     * @param unixUtcTimestamp
     * @returns {CancelablePromise<number>}
     * @private
     */
    _gatherNodeValueFromChildNodes(viewType, unixUtcTimestamp) {
        Debug.Control.Efm.Node && console.log(this.dbgName, "_gatherNodeValueFromChildNodes");
        var promises = this._nodes.map(node => node.getNodeValue(viewType, unixUtcTimestamp));
        return CancelablePromise.all(promises).then((results) => {
            Debug.Control.Efm.Node && console.log(this.dbgName, "_gatherNodeValueFromChildNodes: " + JSON.stringify(results));
            // reduce results into one nodeValue-Object.
            return results.reduce((prev, curr) => {
                // iterate over all attributes of the current
                Object.keys(curr).forEach((key) => {
                    if (prev.hasOwnProperty(key)) {
                        prev[key] = prev[key] + curr[key];
                    } else {
                        prev[key] = curr[key];
                    }
                });
                return prev;
            }, {});
        });
    }

    /**
     * uses the nodes efmState property to retrieve the actual value from the EFM-State-Container
     * @returns {CancelablePromise<number>}
     * @private
     */
    _getCurrentActualValueFromEfmState() {
        Debug.Control.Efm.Node && console.log(this.dbgName, "_getCurrentActualValueFromEfmState");
        var states = this._parentEfm.getStates();
        if (states.hasOwnProperty(this._actualEfmState)) {
            return CancelablePromise.resolve({ actual: states[this._actualEfmState] });
        } else {
            return this._handleNotAvailable(this._actualEfmState + "-state of " + this._parentEfm.getName());
        }
    }

    _requestCurrentNodeValueFromEfm(viewType) {
        Debug.Control.Efm.Node && console.log(this.dbgName, "_requestCurrentNodeValueFromEfm " + viewType);
        return cancelable(this._parentEfm.requestCurrentNodeValue(this._uuid, viewType)).then((value) => {
            Debug.Control.Efm.Node && console.log(this.dbgName, "_requestCurrentNodeValueFromEfm " + viewType + " > " + JSON.stringify(value));
            return value;
        });
    }

    // region Node Value Acquisition from Control States

    _currentNodeValueAvailableInStates(viewType) {
        // usually current values (actual, this day, month, year are available, only week isnt)
        let available = false;
        if (this._control && this._control.statesReady) {
            // check if the control has a state for the requested viewType
            switch (viewType) {
                case EfmViewType.Day:
                    available = this._control.hasTotalDay;
                    break;
                case EfmViewType.Week:
                    available = this._control.hasTotalWeek;
                    break;
                case EfmViewType.Month:
                    available = this._control.hasTotalMonth;
                    break;
                case EfmViewType.Year:
                    available = this._control.hasTotalYear;
                    break;
                case EfmViewType.Actual:
                case EfmViewType.Lifetime:
                    available = true;  // always available (total+actual)
                    break;
                default:
                    available = false; // unhandled view type!
                    break;
            }
        }
        return available;
    }

    /**
     * uses the state container of the control assigned to this node to acquire the actual value
     * @returns {CancelablePromise<number>}
     * @private
     */
    _getCurrentActualValueFromControl() {
        Debug.Control.Efm.Node && console.log(this.dbgName, "_getCurrentActualValueFromControl");
        var states = this._control ? this._control.getStates() : false;
        if (states) {
            return CancelablePromise.resolve(this._getActualNodeValueFromControlStates(states));
        } else {
            return this._handleNotAvailable("no states available for " + this._control.getName());
        }
    }

    _getCurrentTotalValueFromControl(viewType) {
        Debug.Control.Efm.Node && console.log(this.dbgName, "_getCurrentTotalValueFromControl");
        var states = this._control ? this._control.getStates() : false;
        if (states) {
            return CancelablePromise.resolve(this._getTotalNodeValueFromControlStates(states, viewType));
        } else {
            return this._handleNotAvailable("no states available for " + this._control.getName());
        }
    }

    _getActualNodeValueFromControlStates(states) {
        Debug.Control.Efm.Node && console.log(this.dbgName, "_getActualNodeValueFromControlStates");
        let result = {};
        if (states.hasOwnProperty("actualValue")) {
            result.actual = states.actualValue;
        }
        return result;
    }

    _getTotalNodeValueFromControlStates(states, viewType) {
        let result = {};
        const storeInResult = (valName, stateName) => {
            if (states.hasOwnProperty(stateName)) {
                result[valName] = states[stateName];
            }
        }
        switch (viewType) {
            case EfmViewType.Day:
                storeInResult("total", "totalDayValue");
                storeInResult("totalNeg", "totalNegDayValue");
                break;
            case EfmViewType.Week:
                storeInResult("total", "totalWeekValue");
                storeInResult("totalNeg", "totalNegWeekValue");
                break;
            case EfmViewType.Month:
                storeInResult("total", "totalMonthValue");
                storeInResult("totalNeg", "totalNegMonthValue");
                break;
            case EfmViewType.Year:
                storeInResult("total", "totalYearValue");
                storeInResult("totalNeg", "totalNegYearValue");
                break;
            case EfmViewType.Lifetime:
                storeInResult("total", "totalValue");
                storeInResult("totalNeg", "totalNegValue");
                break;
            default:
                break;
        }
        Debug.Control.Efm.Node && console.log(this.dbgName, "_getTotalNodeValueFromControlStates " + viewType, result);
        return result;
    }

    // endregion

    /**
     * Returns a promise that rejects with not available
     * @returns {CancelablePromise<unknown>}
     * @private
     */
    _handleNotAvailable(requested) {
        console.error(this.dbgName, "_handleNotAvailable: " + requested);
        return CancelablePromise.reject(requested + " is not Available"); //TODO-woessto
    }


    //region statistics

    _requestStatisticsFromControl(viewType, unixUtcTimeStamp) {
        if (!this._control) {
            console.error(this.dbgName, "_requestStatisticsFromControl failed, no control found!");
            return CancelablePromise.reject({ err: "No control" });
        }
        if (!this._control.supportsStatisticV2) {
            developerAttention(this.dbgName +  ": _requestStatisticsFromControl failed, control doesn't support statV2!", window.Styles.colors.red);
            return CancelablePromise.reject({ err: "Control doesn't support statV2!" });
        }

        Debug.Control.Efm.Node && console.log(this.dbgName, "_requestStatisticsFromControl " + viewType + ", " + unixUtcTimeStamp);
        let range = this._getStatisticRange(viewType, unixUtcTimeStamp),
            dpUnit,
            group = this._getStatisticGroupForViewType(viewType),
            numValues = group ? group.dataPoints.length : 0,
            promise;

        if (!group) {
            return CancelablePromise.reject({err: `Failed to find statistic group for viewType "${viewType}"`});
        }

        if (viewType !== EfmViewType.Actual) {
            dpUnit = this._getDiffDataPointUnit(viewType)
            promise = SandboxComponent.getStatisticDiff({
                controlUUID: this._controlUuid,
                fromUnixUtc: range.start,
                toUnixUtc: range.end,
                dataPointUnit: dpUnit,
                groupId: group.id,
                nValues: numValues
            });
        } else {
            promise = SandboxComponent.getStatisticRaw({
                controlUUID: this._controlUuid,
                fromUnixUtc: range.start,
                toUnixUtc: range.end,
                groupId: group.id,
                nValues: numValues
            });
        }

        return promise.then((statData) => {
            let result = { header: group ? group.dataPoints : [{}, {}], data: statData, mode: group ? group.mode : 0 };
            Debug.Control.Efm.Node && console.log(this.dbgName, "_requestStatisticsFromControl " + viewType
                + ", " + unixUtcTimeStamp + " ==> " + statData.length + " dataPoints, header = " + JSON.stringify(result.header));
            return result;
        }, (err) => {
            console.error(this.dbgName, "_requestStatisticsFromControl failed with: " + JSON.stringify(err));
            return CancelablePromise.reject({err: "Statistic not loaded: " + JSON.stringify(err)});
        })
    }

    /**
     * Acquires the statistics of this node, reduces it down to a single value per output in the recording.
     * @param viewType
     * @param unixUtcTimeStamp
     * @returns {CancelablePromise<{total:number, totalNeg:number, actual:number, storage:number}>}
     * @private
     */
    _getNodeValueFromStatistics(viewType, unixUtcTimeStamp) {
        Debug.Control.Efm.Node && console.log(this.dbgName, "_getNodeValueFromStatistics " + viewType);
        return this._requestStatisticsFromControl(viewType, unixUtcTimeStamp).then((statistic) => {
            if (!statistic.data) { // avoids a reduce error down below this fn
                return {err: "No statistic data!"};
            }

            // reduce the datapoints of all timestamps into one flat array, containing a value for each output
            let reducedValues = statistic.data.reduce((prev, curr) => {
                curr.values.forEach((val, idx) => {
                    prev[idx] = (prev[idx] || 0) + val;
                });
                return prev;
            }, []);

            // create a value object, containing a property for each output value in the stats, with the value computed
            let valueObj = {};
            statistic.header.forEach((headerObj, idx) => {
                valueObj[headerObj.output] = reducedValues[idx];
            })

            return valueObj;
        }).then((res) => {
            Debug.Control.Efm.Node && console.log(this.dbgName, "_getNodeValueFromStatistics " + viewType + "=" + JSON.stringify(res));
            return res;
        }, e => {
            Debug.Control.Efm.Node && console.log(this.dbgName, "_getNodeValueFromStatistics error");
            Debug.Control.Efm.Node && console.error(e);
            throw e;
        });
    }

    /**
     *
     * @param viewType
     * @param unixUtcTimeStamp
     * @param [nodeType] optional filter to only gather statistics from certain nodes, defaults to false
     * @returns {CancelablePromise<{values: number[], ts: number}[]>}
     * @private
     */
    _gatherStatisticsFromChildNodes(viewType, unixUtcTimeStamp, nodeType = false){
        Debug.Control.Efm.Node && console.log(this.dbgName, "_gatherStatisticsFromChildNodes (typed=" + nodeType + ")");
        var promises,
            nodeList = this._nodes;

        // if only statistics of certain node types are to be requested, don't ask nodes for stats that they don't have.
        if (nodeType) {
            nodeList = this._nodes.filter((childNode) => {
                return childNode.hasNodeType(nodeType);
            });
        }

        // the (filtered) list of nodes will only contain nodes that can deliver stats.
        promises = nodeList.map((node) => {
            if (nodeType) {
                return node.getStatisticsTyped(viewType, unixUtcTimeStamp, nodeType);
            } else {
                return node.getStatistics(viewType, unixUtcTimeStamp);
            }
        });

        return CancelablePromise.all(promises).then((results) => {
            Debug.Control.Efm.Node && console.log(this.dbgName, "_gatherStatisticsFromChildNodes > statistics = ", results);
            return SandboxComponent.combineStatistics(results).then((combined) => {
                Debug.Control.Efm.Node && console.log(this.dbgName, "_gatherStatisticsFromChildNodes > combined = ", combined);
                return combined;
            });
        });
    }

    _getDiffDataPointUnit(viewType) {
        var dpUnit;
        switch (viewType) {
            case EfmViewType.Lifetime:
                dpUnit = StatisticV2DiffDataPointUnit.Year;
                break;
            case EfmViewType.Year:
                dpUnit = StatisticV2DiffDataPointUnit.Month;
                break;
            case EfmViewType.Month:
            case EfmViewType.Week:
                dpUnit = StatisticV2DiffDataPointUnit.Day;
                break;
            case EfmViewType.Day:
                dpUnit = StatisticV2DiffDataPointUnit.Hour;
                break;
            default:
                break;
        }
        return dpUnit;
    }

    _getStatisticRange(viewType, unixUtcTs) {
        let rangeId,
            range;

        switch (viewType) {
            case EfmViewType.Lifetime:
            case EfmViewType.Year:
                rangeId = "year";
                break;
            case EfmViewType.Month:
                rangeId = "month";
                break;
            case EfmViewType.Week:
                rangeId = "week";
                break;
            case EfmViewType.Actual:
            case EfmViewType.Day:
            default:
                rangeId = "day";
                break;
        }
        range = ActiveMSComponent.getStartAndEndOfAsUnixUtcTimestamp(unixUtcTs, rangeId);

        if (viewType === EfmViewType.Lifetime) { // start with the beginning of the year the stats where activated.
            var avSince = this._getAccumulatedStatisticGroupObj().activeSince
            // 1230768000 ==> 1.1.2009
            range.start = avSince ? ActiveMSComponent.getStartAndEndOfAsUnixUtcTimestamp(avSince, rangeId).start : 1230768000;
        }

        return range;
    }

    _getStatisticGroupForViewType(viewType) {
        var group;
        switch (viewType) {
            case EfmViewType.Lifetime:
            case EfmViewType.Year:
            case EfmViewType.Month:
            case EfmViewType.Week:
            case EfmViewType.Day:
                group = this._getAccumulatedStatisticGroupObj();
                break;
            case EfmViewType.Actual:
            default:
                group = this._getActualStatisticGroupObj();
                break;
        }
        return group;
    }

    _getAccumulatedStatisticGroupObj() {
        return this._control.getStatisticGroupForOutput("total");
    }

    _getActualStatisticGroupObj() {
        return this._control.getStatisticGroupForOutput("actual");
    }

    // endregion

    _formatOutputValue(outputName, outputValue) {
        let format = "%.2f";
        switch (outputName) {
            case "total":
            case "totalNeg":
                format = this._control ? this._control.totalFormat : "%.1f kWh";
                break;
            case "actual":
                format = this._control ? this._control.actualFormat : "%.1f kW";
                break;
            default:
                break;
        }

        let splitTexts = lxUnitConverter.convertSplitAndApply(format, outputValue);
        return splitTexts.valueTxt + splitTexts.succTxt;
    }

    /**
     * Returns the names of the properties in the stateContainer that are to be used for the actual value view.
     * This is used for the liveStateHooks, that will trigger re-renderings of the UI.
     * @private
     */
    _getActualNodeValueControlStateNames() {
        let result = [];
        result.push("actualValue");
        return result;
    }


    // region general registration handling

    _getRandomIdForListener(collection) {
        let randomId;
        do {
            randomId = "" + getRandomIntInclusive(0, 100000);
        } while (this._actualValueListeners.hasOwnProperty(randomId));
        return randomId;
    }

    _hasListeners(collection) {
        return Object.keys(collection).length > 0;
    }

    // endregion

    // region private live state update handling


    /**
     * Registers this node an all state containers of either its own control, the efm or its child node's controls.
     * This ensures that each time the actual NodeValue needs to be updated, it is.
     * @private
     */
    _registerForLiveStates() {
        Debug.Control.Efm.Node && console.log(this.dbgName, "_registerForLiveStates");
        this._removeFromLiveStates();

        this._getLiveStateRegistrations().forEach((regObj) => {
            let requestPrms = [],
                receivedStates = () => {
                    requestPrms.push(this._dispatchLiveStateChanged())
                },
                deReg = SandboxComponent.registerFunctionForStateChangesForUUID(regObj.uuid, receivedStates);

            if (deReg) {
                this._liveStateUnregFns.push({
                    deReg,
                    requestPrms
                });
            }
        });
    }

    /**
     * Acquires the actual NodeValue and then dispatches it to the registered callbacks.
     * @private
     */
    _dispatchLiveStateChanged() {
        return this.getNodeValue(EfmViewType.Actual).then((nodeVal) => {
            Object.keys(this._actualValueListeners).forEach((key) => {
                this._actualValueListeners[key](cloneObject(nodeVal)); // clone ensures no one messes with the other ones object
            });
        });
    }

    /**
     * Takes care that the node unregisters from all state containers it has registered for.
     * @private
     */
    _removeFromLiveStates() {
        Debug.Control.Efm.Node && console.log(this.dbgName, "_removeFromLiveStates");
        if (this._liveStateUnregFns && this._liveStateUnregFns.length >= 0) {
            this._liveStateUnregFns.forEach(({ deReg = () => {}, requestPrms = [] }) => {
                deReg();
                requestPrms.forEach(prms => {
                    prms?.cancel();
                });
            });
        }
        this._liveStateUnregFns = [];
    }

    /**
     * Used to get all required liveStateHook registrations in order to trigger an update, when the actual node value
     * needs to be re-requested.
     * @returns {[{uuid:number, stateNames:string[]}]} array obj, uuid = used to get the stateContainer, stateNames = for which specifically to register
     */
    _getLiveStateRegistrations() {
        let regs = [];

        if (this._actualEfmStateAvailable()) {
            // needs to be updated once the parent efm changes.
            let efmRegInfo = {};
            efmRegInfo.uuid = this._parentEfmUuid;
            efmRegInfo.stateNames = [this._actualEfmState];
            regs.push(efmRegInfo);

        } else if (this._control) {
            let ctrlRegInfo = {};
            ctrlRegInfo.uuid = this._controlUuid;
            ctrlRegInfo.stateNames = this._getActualNodeValueControlStateNames();
            regs.push(ctrlRegInfo);

        } else if (this._nodes) {
            this._nodes.forEach((childNode) => {
                regs.splice(0,0, ...childNode._getLiveStateRegistrations());
            });
        }
        return regs;
    }

    // endregion

    // region Total Value Update Handling

    _startTotalValueUpdateTimer(viewType, unixUtcTs) {
        return new CancelablePromise((resolve, reject, onCancel) => {
            let prms,
                timeout = setTimeout(() => {
                prms = this.getNodeValue(viewType, unixUtcTs).then(nodeValue => {
                    Object.values(this._totalValueListeners).forEach((cb) => {
                        cb(nodeValue);
                    });
                    resolve();
                });
                if (this._hasListeners(this._totalValueListeners)) {
                    this._startTotalValueUpdateTimer(viewType, unixUtcTs);
                } else {
                    this._stopTotalValueUpdateTimer();
                }
            }, this._getUpdateIntervalFor(viewType, unixUtcTs));

            onCancel(() => {
                prms?.cancel();
                clearTimeout(timeout);
            });
        });
    }

    _stopTotalValueUpdateTimer() {
        clearTimeout(this._totalValueUpdateTimer);
        this._totalValueUpdateTimer = null;
    }


    _startStatisticValueUpdateTimer(viewType, unixUtcTs) {
        this._statisticValueUpdateTimer = setTimeout(() => {
            Debug.Control.Efm.Node && console.log(this.dbgName, "_statisticUpdateTimer - fired!");
            this.getStatistics(viewType, unixUtcTs).then((res) => {
                Debug.Control.Efm.Node && console.log(this.dbgName, "_statisticUpdateTimer - got statistics, dispatch!");
                Object.values(this._statisticListeners).forEach((cb) => { cb(res); });
            }, (err) => {
                console.error(this.dbgName, "_statisticUpdateTimer - failed to get statistics, dispatch empty!");
                Object.values(this._statisticListeners).forEach((cb) => { cb({}); });
            });

            if (this._hasListeners(this._statisticListeners)) {
                this._startStatisticValueUpdateTimer(viewType, unixUtcTs);
            } else {
                this._stopStatisticValueUpdateTimer();
            }
        }, this._getStatisticUpdateIntervalFor(viewType));
    }

    _stopStatisticValueUpdateTimer() {
        clearTimeout(this._statisticValueUpdateTimer);
        this._statisticValueUpdateTimer = null;
    }

    _getStatisticUpdateIntervalFor(viewType) {
        let intervalSecs;
        switch (viewType) {
            case EfmViewType.Month:
            case EfmViewType.Lifetime:
            case EfmViewType.Year:
                intervalSecs = 60 * 10; // update each 10 minutes
                break;
            case EfmViewType.Actual:
            case EfmViewType.Day:
            case EfmViewType.Week:
                intervalSecs = 60; // each minute an update
                break;
        }
        return intervalSecs * 1000;
    }

    /**
     * In what interval should the data for the ui be re-requested?
     * @param viewType
     * @param unixUtcTs
     * @returns {number}
     * @private
     */
    _getUpdateIntervalFor(viewType, unixUtcTs) {
        let interval = 0;

        if (this._isViewingNow(viewType, unixUtcTs)) {
            interval = this._getStatisticUpdateIntervalFor(viewType);
        } else {
            console.warn(this.dbgName, "_getUpdateIntervalFor - not the current " + viewType + "!");
        }

        return Math.max(interval, 10000); // 10 seconds MINIMUM
    }

    // endregion

    /**
     * Returns true if the requested view-window (day/month/week/year/lifetime) with the corresponding
     * timestamp indicates that the current week/month/week/year is being viewed/requested.
     * viewingNow indicates that values need to be re-requested periodically.
     * viewingNow also enables the use of different dataSources as the EFM provides those values via states/cmds
     * @param viewType      efmViewType-value
     * @param unixUtcTs     the unix utc ts in question
     * @returns {boolean}   if true, the unixUtcTs is e.g. within the current week (not past week or such)
     * @private
     */
    _isViewingNow(viewType, unixUtcTs = 0) {
        if (viewType === EfmViewType.Actual || viewType === EfmViewType.Lifetime || unixUtcTs === 0) {
            return true;
        }
        return ActiveMSComponent.isUnixUtcTsWithinCurrentRange(unixUtcTs, viewType);
    }

    isOfType(type) {
        if(this.nodeType === type) {
            return true;
        }
        if(this.hasChildNodes) {
            return this._nodes.every(node => node.isOfType(type));
        }
        return false;
    }

    meetsTypeRequirements(types) {
        if(types.includes(this.nodeType)) {
            return true;
        }
        if(this.hasChildNodes) {
            return this._nodes.every(node => node.meetsTypeRequirements(types));
        }
        return false;
    }

    groupConsistingOf(nodeTypes) {
        if(this.isOfType(EfmNodeType.Group)) {
            return this._nodes.every(node => node.meetsTypeRequirements(nodeTypes));
        }
        return false;
    }
}

export class EfmGridNode extends EfmNode {
}

export class EfmProductionNode extends EfmNode {
    getNodeValue(viewType, unixUtcTimeStamp) {
        return super.getNodeValue(...arguments).then(res => {
            if (this.isBidirectional) {
                // the app considers a positive value as production, while with bidirectional producers, a negative
                // value means production --> hence flip those values
                if (viewType === EfmViewType.Actual) {
                    return {
                        actual: res.actual * -1
                    };
                } else {
                    return {
                        total: res.totalNeg,
                        totalNeg: res.total
                    };
                }
            } else {
                return res;
            }
        })
    }

    getStatistics(viewType, unixUtcTimeStamp) {
        return super.getStatistics(...arguments).then(res => {
            if (this.isBidirectional) {
                // the app considers a positive value as production, while with bidirectional producers, a negative
                // value means production --> hence flip those values
                if (viewType === EfmViewType.Actual) {
                    return {
                        ...res,
                        data: res.data.map(dp => {
                            return {
                                ...dp,
                                values: [dp.values[0] * -1]
                            }
                        })
                    }
                } else {
                    return {
                        ...res,
                        // swapping the headers is not the way to go, somewhere it presumably is fixed which index is
                        // considered total and which totalNeg --> reorder each DataPoint instead.
                        data: [...res.data].map(dp => {
                            return {
                                ...dp,
                                values: [...dp.values].reverse()
                            }
                        })
                    }
                }
            } else {
                return res;
            }
        });
    }
}

export class EfmStorageNode extends EfmNode {
    _formatOutputValue(outputName, outputValue) {
        let formatted;
        switch (outputName) {
            case "storage":
                let format = '%.0f %%';
                if(this._control) {
                    format = this._control.storageFormat;
                }
                formatted = lxFormat(format, outputValue);
                break;
            default:
                formatted = super._formatOutputValue(outputName, outputValue);
                break;
        }
        return formatted;
    }

    _getActualNodeValueFromControlStates(states) {
        Debug.Control.Efm.Node && console.log(this.dbgName, "_getActualNodeValueFromControlStates");
        let result = super._getActualNodeValueFromControlStates(states);

        if (states.hasOwnProperty("storageValue")) {
            result.storage = states.storageValue;
        }
        return result;
    }

    /**
     * Returns the names of the properties in the stateContainer that are to be used for the actual value view.
     * This is used for the liveStateHooks, that will trigger re-renderings of the UI.
     * @private
     */
    _getActualNodeValueControlStateNames() {
        let result = super._getActualNodeValueControlStateNames();
        result.push("storageValue");
        return result;
    }
}

export class EfmConsumptionNode extends EfmNode {

    /**
     * A wallbox is a consumption node, that only has total and actual states.
     * @param viewType
     */
    _currentNodeValueAvailableInStates(viewType) {
        let isAvailable = super._currentNodeValueAvailableInStates(...arguments);
        if (isAvailable && this.nodeSubType === EfmNodeSubtype.Wallbox) {
            switch (viewType) {
                case EfmViewType.Actual:
                case EfmViewType.Lifetime:
                    isAvailable = true;
                    break;
                default:
                    isAvailable = false;
                    break;
            }
        }
        return isAvailable;
    }

    _getActualStatisticGroupObj() {
        let group = super._getActualStatisticGroupObj(...arguments);
        if (!group && this.nodeSubType === EfmNodeSubtype.Wallbox) {
            //TODO-woessto: remove workaround for wallbox (had no actual statistic, but only powerSession/energySession)
            group = this._control.getStatisticGroupForOutput("powerSession");
        }
        return group;
    }

}

export class EfmGroupNode extends EfmNode {
}

// endregion
