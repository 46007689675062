import {
    navigatorConfig,
    useControlCtxtMenuOptions,
    LxReactContextMenuHandler,
    LxLegacyReactLockOverlay,
    LxReactImageView,
    LxReactRenderErrorView,
    useBackNavigation,
    ReactWrapper,
    useSetURLHook,
    AmbientContext,
} from 'LxComponents';
import Icons from 'IconLib';
import globalStyles from 'GlobalStyles';
import React, {
    useMemo,
    useEffect,
    useState,
    useCallback,
    useContext,
    useRef,
} from 'react';
import useControlObject from '../customHooks/useControlObject';
import { useLiveState } from '../Components';
import LxControlContextProvider from "./LxControlContextProvider";

const RightActionIndexes = {
    MESSAGE_CENTER: 0,
    EXPAND: 1,
    MORE: 2,
};

/**
 * This is not exported as the "containing" item wraps it into a controlContext
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
function LxLegacyReactControlContentInternal(props) {
    const ctrlUuid = props.route.params.controlUUID;
    const dynamicControl = useControlObject(ctrlUuid);
    const { control } = dynamicControl;
    const liveState = useLiveState(ctrlUuid, ['messageCenterEntries']);
    const ctxtMenuOptions = useControlCtxtMenuOptions(ctrlUuid, null);
    const details = useRef({});
    const { isAmbientMode } = useContext(AmbientContext);
    const [customNavProps, setCustomNavProps] = useState({});
    const rightActionCodesRef = useRef({});

    const hasMessageCenterEntries = () =>
        liveState?.states?.messageCenterEntries &&
        liveState?.states?.messageCenterEntries?.length;

    useSetURLHook({
        urlPart: 'control/' + ctrlUuid,
    });

    const expandContent = (details) => {
        NavigationComp.showControlContent(control, details, null, {
            isInAmbientMode: false,
        });
    };

    // callback used to update the details for expanding the controlContent.
    const updateDetailsForExpand = useCallback((newProps, extAsapContent) => {
        details.current = { ...newProps };
        if (extAsapContent) {
            // e.g. used in energy flow monitor, to expand the content when zooming a graph.
            expandContent({ ...details.current, ...extAsapContent });
        }
    }, []);

    const MessageCenterIcon = () => {
        let entry = liveState.states.messageCenterEntries[0];
        if(!entry) return null; // after an entry is confirmed - it is removed, then when modal is closed ControlContent is re-rendered, but entry is already removed which causes a crash 
        let Icon = MessageCenterHelper.getIconForSeverityEntry(entry, true);
        let iconColor = MessageCenterHelper.getColorForSeverityEntry(entry);

        return (
            <Icon
                {...globalStyles.customStyles.titleBarIcon}
                fill={iconColor}
            />
        );
    };

    const openMessageCenterEntry = (mscEntry) =>
        NavigationComp.showState(
            ScreenState.MessageCenterMessageScreen,
            { entry: mscEntry },
            AnimationType.NONE,
        );

    const handleTitleRightAction = useCallback(
        (idx) => {
            let actionCode = rightActionCodesRef.current[idx];
            if (actionCode === RightActionIndexes.MESSAGE_CENTER) {
                openMessageCenterEntry(
                    liveState.states.messageCenterEntries[0],
                );
            } else if (actionCode === RightActionIndexes.EXPAND) {
                expandContent(details.current);
            } else if (actionCode === RightActionIndexes.MORE) {
                LxReactContextMenuHandler.shared.showContextMenu(
                    ctxtMenuOptions,
                    control.getName(),
                );
            }
        },
        [dynamicControl, ctxtMenuOptions, isAmbientMode, details.current],
    );

    const titleBarCfg = useMemo(() => {
        let rightActions = [];

        let actionIndex = 0;
        if (hasMessageCenterEntries()) {
            rightActions.push({
                action: ({ dimensions, props, key }) => {
                    return <MessageCenterIcon />;
                },
            });
            rightActionCodesRef.current[actionIndex] =
                RightActionIndexes.MESSAGE_CENTER;
            actionIndex++;
        }

        if (isAmbientMode) {
            rightActions.push({
                action: ({ dimensions, props, key }) => {
                    return (
                        <Icons.ExpandArrowsCircled
                            {...globalStyles.customStyles.titleBarIcon}
                        />
                    );
                },
            });
            rightActionCodesRef.current[actionIndex] =
                RightActionIndexes.EXPAND;
            actionIndex++;
        }

        if (ctxtMenuOptions && ctxtMenuOptions.length) {
            rightActions.push({
                action: ({ dimensions, props, key }) => {
                    return (
                        <LxReactImageView
                            source={Icon.Buttons.MORE2}
                            imageStyle={globalStyles.customStyles.titleBarIcon}
                        />
                    );
                },
            });
            rightActionCodesRef.current[actionIndex] = RightActionIndexes.MORE;
            actionIndex++;
        }

        return {
            title: control.getName(),
            leftActions: [
                {
                    action: ({ dimensions, props, key }) => {
                        //return <Icons.BackArrow key={key} fill={props.tintColor} height={dimensions.height} width={dimensions.width}/>
                        return (
                            <LxReactImageView
                                source={
                                    customNavProps.showBack
                                        ? Icon.Buttons.BACK
                                        : Icon.Buttons.CLOSE
                                }
                                containerStyle={
                                    globalStyles.customStyles.titleBarIcon
                                }
                            />
                        );
                    },
                },
            ],
            rightActions: rightActions,
            onRightAction: handleTitleRightAction,
            navigation: props.navigation,
            ...customNavProps,
        };
    }, [dynamicControl, customNavProps, isAmbientMode, handleTitleRightAction]);

    useBackNavigation(() => {
        if (customNavProps.onLeftAction) {
            customNavProps.onLeftAction();
        } else {
            props.navigation.goBack();
        }
    });

    useEffect(() => {
        props.navigation.setOptions({
            ...topNavigator,
        });
    }, [titleBarCfg]);

    useEffect(() => {
        if (!control) {
            props.navigation.goBack();
        }
    }, [dynamicControl]);

    // custom nav props may be provided by the rendered content using the udpateNavigation-Callback.
    const updateNavigation = useCallback((newProps) => {
        setCustomNavProps(newProps);
    }, []);

    const topNavigator = navigatorConfig({ ...titleBarCfg });

    const specificContent = useMemo(() => {
        let reactContent = control.getLegacyReactControlContent();
        if (reactContent) {
            return ReactWrapper.React.createElement(reactContent, {
                ...props,
                updateNavigation,
                updateDetailsForExpand,
            });
        } else {
            return (
                <LxReactRenderErrorView
                    error={
                        'Provide component to render in content in getLegacyReactControlContent of control'
                    }
                />
            );
        }
    }, [dynamicControl, props]);

    if (control) {
        return (
            <LxLegacyReactLockOverlay {...props}>
                {specificContent}
            </LxLegacyReactLockOverlay>
        );
    } else {
        return null;
    }
}

export default function LxLegacyReactControlContent(props) {
    const ctrlUuid = props.route.params.controlUUID;

    return <LxControlContextProvider controlUuid={ctrlUuid} isAlert={false}>
        <LxLegacyReactControlContentInternal {...props}/>
    </LxControlContextProvider>
}
