'use strict';

import AlarmControlContentBase from "../alarmControlBase/content/controlContent";
import {LxBaseControlContent} from "LxComponents";

define("AlarmControl", ["Control", "AlarmControlEnums"], function (Control, AlarmControlEnums) {
    return class AlarmControl extends Control {
        constructor() {
            super(...arguments);
        }

        getReactControlContent() {
            if (this.isGrouped()) {
                return LxBaseControlContent
            } else {
                return AlarmControlContentBase
            }
        }

        getCellType(forCentral) {
            return forCentral ? "AlarmControlStateCell" : ControlCard.BASE;
        }

        movementEnabled() {
            return this.details.presenceConnected;
        }

        getDescForArmedState(armed) {
            if (armed) {
                return _("controls.alarm.armed-short");
            } else if (!armed) {
                return _("controls.alarm.disarmed-short");
            }
        }

        getDescForLevel(level) {
            switch (level) {
                case AlarmControlEnums.AlarmLevel.ALARM.NONE:
                    return _("controls.alarm.level.not-active");

                case AlarmControlEnums.AlarmLevel.ALARM.SILENT:
                    return _("controls.alarm.level.silent");

                case AlarmControlEnums.AlarmLevel.ALARM.ACUSTIC:
                    return _("controls.alarm.level.acoustic");

                case AlarmControlEnums.AlarmLevel.ALARM.OPTIC:
                    return _("controls.alarm.level.optic");

                case AlarmControlEnums.AlarmLevel.ALARM.INTERNAL:
                    return _("controls.alarm.level.internal");

                case AlarmControlEnums.AlarmLevel.ALARM.EXTERNAL:
                    return _("controls.alarm.level.external");

                case AlarmControlEnums.AlarmLevel.ALARM.REMOTE:
                    return _("controls.alarm.level.remote");

                default:
                    return null;
            }
        }

        specificCreateCmdText(cmdParts) {
            let text,
                cfg = {
                    name: this.name
                };

            switch (cmdParts[1]) {
                case "dismv":
                    if (cmdParts[2] === "0") {
                        // dismv = 0 = movement detectors are active
                        text = _("cmdtext.alarm.movement.activate", cfg);
                    } else {
                        // dismv = 1 = movement detectors are deactivated
                        text = _("cmdtext.alarm.movement.deactivate", cfg);
                    }

                    break;

                case Commands.ALARM.ARM:
                    if (cmdParts.length >= 3) {
                        if (cmdParts[2] === "0") {
                            text = _("cmdtext.alarm.arm-deactivate-movement", cfg);
                        } else {
                            text = _("cmdtext.alarm.arm-activate-movement", cfg);
                        }
                    } else {
                        text = _("cmdtext.alarm.arm", cfg); //this is a work around, because the wrong translation string was used arm instead of on
                    }

                    break;

                case Commands.ALARM.DELAYED_ARM:
                    if (cmdParts.length >= 3) {
                        if (cmdParts[2] === "0") {
                            text = _("cmdtext.alarm.delayedon-deactivate-movement", cfg);
                        } else {
                            text = _("cmdtext.alarm.delayedon-activate-movement", cfg);
                        }
                    } else {
                        text = _("cmdtext.alarm.delayedon", cfg);
                    }

                    break;

                case Commands.ALARM.ACKNOWLEDGE:
                case Commands.ALARM.SMOKE_ALARM.QUIT:
                    text = _("cmdtext.alarm.quit", cfg);
                    break;

                case Commands.ALARM.START_DRILL:
                    text = _("controls.alarm-base.drill.start");
                    break;

                default:
                    let cmdKey = "cmdtext.alarm." + cmdParts[1];
                    text = _(cmdKey, cfg);
                    break;
            }

            return text;
        }

        getFriendlyValueName(event, operator) {
            switch (event.data.stateName) {
                case "level":
                    return this.name + " " + event.data.stateTitle + " " + operator + " " + this.getDescForLevel(event.value);

                case "armed":
                    return this.name + " " + this.getDescForArmedState(event.value);

                default:
                    return "";
            }
        }

        // Private
        _onPresenceChanged(active) {
            // TODO send only to controls with presence (grouped control!)
            this.getCommand(Commands.format(Commands.ALARM.DISABLE_MOVEMENT, !active)).call(this);
        }

    };
});
