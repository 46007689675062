'use strict';

window.GUI = function (GUI) {
    class ZoneSettingCell extends GUI.TableViewV2.Cells.BaseCell {
        constructor() {
            super(...arguments);
            this.element.addClass("zone-setting-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            let prms = [];
            this.currentValue = this.content.hasOwnProperty('value') ? this.content.value : 0;
            var htmlElem = GUI.TableViewV2.Cells.ZoneSettingCell.Template.getZoneSettingCellContent(this.content.title);
            this.contentPlaceholder.append(htmlElem);

            if (!this.hasOwnProperty("elements")) {
                this.elements = {};
            }

            this.elements.leftButton = this.contentPlaceholder.find('.small-container__left');
            this.elements.rightButton = this.contentPlaceholder.find('.small-container__right');
            this.elements.valueLabel = this.contentPlaceholder.find('.top-row__right-label');

            if (!this.hasOwnProperty("buttons")) {
                this.buttons = {};
            }

            this.buttons.leftButton = new GUI.LxButton(this, this.elements.leftButton[0], Color.BUTTON_GLOW);
            this.buttons.rightButton = new GUI.LxButton(this, this.elements.rightButton[0], Color.BUTTON_GLOW);
            this.addToHandledSubviews(this.buttons.rightButton);
            this.addToHandledSubviews(this.buttons.leftButton); // Slider

            //before: prependSubview
            prms.push(this.appendReactComp({
                reactComp: GUI.LxSlider,
                compProps: {
                    hasCustomRate: true,
                    min: this.content.minVal,
                    max: this.content.maxVal,
                    step: this.content.step
                },
                target: this.contentPlaceholder.find('.bottom-row__large-container')
            }).then(instance => this.buttons.slider = instance));
            //this.buttons.slider.getElement().addClass("large-container__slider");
            this.antiGhostTimer = new AntiGhostTimer(this._antiGhostPassed.bind(this));
            return Q.all(prms);
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);
            this.buttons.leftButton.onButtonTapped = this._handleButtonTapped.bind(this);
            this.buttons.rightButton.onButtonTapped = this._handleButtonTapped.bind(this); // onPositionChanged fires far too often.

            this.buttons.slider.onDraggingChanged = this._handleSliderReleased.bind(this);
            this.buttons.slider.onPositionChanged = this._handleSliderChanged.bind(this);
            this.buttons.slider.setPosition(this.currentValue);

            this._updateDisplayedValue();
        }

        viewWillDisappear() {
            this.buttons.leftButton.onButtonTapped = null;
            this.buttons.rightButton.onButtonTapped = null;
            this.buttons.slider.onDraggingChanged = null;
            this.buttons.slider.onPositionChanged = null;
            super.viewWillDisappear(...arguments);
        }

        destroy() {
            this.antiGhostTimer && this.antiGhostTimer.resetAntiGhostTimer();
            super.destroy();
        }

        // Public functions
        setValue(value) {
            if (!this.antiGhostTimer.isAntiGhostTimerActive()) {
                this.currentValue = value;
                this.buttons.slider.setPosition(this.currentValue);

                this._updateDisplayedValue();
            } else {
                this.currentValue = value;
            }
        }

        // Private functions
        _handleButtonTapped(sourceBtn) {
            if (sourceBtn === this.buttons.leftButton) {
                this.currentValue -= this.content.step;
            } else if (sourceBtn === this.buttons.rightButton) {
                this.currentValue += this.content.step;
            } // Validate Range


            this.currentValue = Math.min(this.currentValue, this.content.maxVal);
            this.currentValue = Math.max(this.currentValue, this.content.minVal);
            this.currentValue = this._prettifyCurrentValue(this.currentValue);
            this.buttons.slider.setPosition(this.currentValue);

            this._updateDisplayedValue();

            this.antiGhostTimer && this.antiGhostTimer.fire();
            this.delegate.settingCellValueChanged.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView, this.currentValue);
        }

        _handleSliderChanged(position) {
            this.currentValue = this._prettifyCurrentValue(position);

            this._updateDisplayedValue();

            this.antiGhostTimer && this.antiGhostTimer.fire();
            this.delegate.settingCellValueChanged.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView, this.currentValue, true); // isDragging is set to "true"
        }

        _handleSliderReleased(position) {
            this.currentValue = this._prettifyCurrentValue(position);

            this._updateDisplayedValue();

            this.antiGhostTimer && this.antiGhostTimer.fire();
            this.delegate.settingCellValueChanged.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView, this.currentValue, false); // isDragging is set to "false"
        }

        _updateDisplayedValue() {
            var formattedText = lxUnitConverter.convertAndApply(this.content.format, this.currentValue, true);
            this.elements.valueLabel.html(formattedText);
        }

        _antiGhostPassed() {
            this.buttons.slider.setPosition(this.currentValue);

            this._updateDisplayedValue();
        }

        /**
         * After multiplications floats tend to mess up in javascript. Take care of them here.
         * http://stackoverflow.com/questions/1458633/how-to-deal-with-floating-point-number-precision-in-javascript
         * @param inputValue
         * @returns {Number}
         * @private
         */
        _prettifyCurrentValue(inputValue) {
            return Math.round(inputValue * 1000) / 1000;
        }

    }

    GUI.TableViewV2.Cells.ZoneSettingCell = ZoneSettingCell;

    GUI.TableViewV2.Cells.ZoneSettingCell.Template = function () {
        var getZoneSettingCellContent = function getZoneSettingCellContent(title) {
            return '' + '<div class="zone-setting-cell__top-row">' + '<div class="top-row__left-label">' + title + '</div>' + '<div class="top-row__right-label">200ms</div>' + '</div>' + '<div class="zone-setting-cell__bottom-row">' + '<div class="bottom-row__large-container">' + // SLIDER
                '</div>' + '<div class="bottom-row__small-container">' + '<div class="small-container__left">' + ImageBox.getResourceImageWithClasses("resources/Images/General/button-minus.svg", "left__icon") + '</div>' + '<div class="small-container__right">' + ImageBox.getResourceImageWithClasses("resources/Images/General/button-plus.svg", "right__icon") + '</div>' + '</div>' + '</div>' + '';
        };

        return {
            getZoneSettingCellContent: getZoneSettingCellContent
        };
    }();

    GUI.TableViewV2.CellType.Special.ZONE_SETTING = "ZoneSettingCell";
    return GUI;
}(window.GUI || {});
