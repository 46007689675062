import {
    LxReactTableView, LxReactPressable, LxReactHorizontalSlider, useControl, useLiveState
} from "LxComponents";
import {useMemo} from "react";
import {View} from "react-native";
import Icons from "IconLib";
import globalStyles from "GlobalStyles";
import {LxReactText} from "LxComponents";
import {TmpSliderType} from "../../iRoomV2021ControlEnums"

function ButtonSlider(props) {

    const onValueChanged = (newValue) => {
        props.onValueChanged && props.onValueChanged(newValue, true)
    }

    const onButtonPressed = (shouldIncrease) => {
        let config = props;
        let newValue;
        if (shouldIncrease) {
            newValue = props.value + (config.step || 1);
            if (newValue > config.max) {
                newValue = config.max;
            }
        } else {
            newValue = props.value - (config.step || 1);
            if (newValue < config.min) {
                newValue = config.min;
            }
        }

        onValueChanged(newValue)
    }

    const ActionButton = ({isPlus}) => {
        return <LxReactPressable style={sliderStyles.actionButtonContainer} pressableStyle={sliderStyles.actionButton}
                                 pkey={isPlus ? "plus-button" : "minus-button"} onPress={() => onButtonPressed(isPlus)}>
            {isPlus ? <Icons.Plus {...sliderStyles.actionIcon}/> : <Icons.Minus {...sliderStyles.actionIcon}/>}
        </LxReactPressable>
    }

    return <View style={sliderStyles.container}>
        <View style={sliderStyles.contentContainer}>
            <LxReactHorizontalSlider {...props} containerStyle={sliderStyles.slider} activeColor={globalStyles.colors.brand}/>
            <View style={sliderStyles.actionButtonsContainer}>
                <ActionButton isPlus={false}/>
                <ActionButton isPlus={true}/>
            </View>
            <LxReactText style={[styles.smallText, styles.valueLabel]}>{lxFormat(props.format, props.value, true)}</LxReactText>
        </View>
    </View>
}


const sliderStyles = {
    container: {
        width: "100%",
        flex: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    contentContainer: {
        width: "100%",
        height: "100%",
        maxHeight: 405,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },
    slider: {
        marginLeft: globalStyles.spacings.gaps.smaller
    },
    actionButtonsContainer: {
        marginLeft: globalStyles.spacings.gaps.small,
        flexDirection: "row"
    },
    actionButtonContainer: {
        height: globalStyles.sizes.icons.veryBig,
        width: globalStyles.sizes.icons.veryBig,
        marginHorizontal: globalStyles.spacings.gaps.verySmall,
        justifyContent: "center",
        alignItems: "center",
    },
    actionButton: {
        justifyContent: "center",
        alignItems: "center",
        height: globalStyles.sizes.icons.regular,
        width: globalStyles.sizes.icons.regular,
        padding: globalStyles.spacings.gaps.verySmall,
        backgroundColor: globalStyles.colors.grey["300a36"],
        borderRadius: "50%"
    },
    actionIcon: {
        height: globalStyles.sizes.icons.tiny,
        width: globalStyles.sizes.icons.tiny,
        fill: globalStyles.colors.white
    }
}

export default function Irc2024TempAdoption({controlUuid, tempType}) {
    const control = useControl(controlUuid);
    const  {states = {}} = useLiveState(controlUuid, [
        "comfortTempMin",
        "comfortTempMax",
        "comfortTolerance",
        "heatProtectTemperature",
        "frostProtectTemperature",
        "absentMinOffset",
        "absentMaxOffset",
        "shadingTempHeat",
        "shadingTempCool"
    ]);

    const fTemp = (temp) => {
        if (!control) {
            return null;
        }
        return control?.formatTemp(temp);
    }

    const sendCmd = (cmd, value) => {
        return control?.sendCommand(Commands.format(cmd, value));
    }

    const comfortChanged = (value) => {
        sendCmd(Commands.IRC_V2021.COMFORT_HEAT, value);
    }

    const comfortHeatingChanged = (value) => {
        let prms = Q.resolve();
        if (value > states?.comfortTempMax) {
            // with both heating and cooling available the selector is limited properly, but when
            // only heating is supported, the limit is set to the heat protection. as the miniserver
            // would block setting the comfortMin above the comfortMax, it must be raised first.
            prms = comfortCoolingChanged(value);
        }
        return prms.then(() => {sendCmd(Commands.IRC_V2021.COMFORT_HEAT, value);});
    }
    const comfortCoolingChanged = (value) => {
        let prms = Q.resolve();
        if (value < states?.comfortTempMin) {
            // with both heating and cooling available the selector is limited properly, but when
            // only cooling is supported, the limit is set to the frost protection. as the miniserver
            // would block setting the comfortMax below the comfortMin, it must be lowered first
            prms = comfortHeatingChanged(value);
        }
        return prms.then(() => {sendCmd(Commands.IRC_V2021.COMFORT_COOL, value);});
    }

    const comfortToleranceChanged = (value) => {
        sendCmd(Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.COMFORT_TOLERANCE, value);
    }

    const ecoDeviationChanged = (value) => {
        sendCmd(Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.ECO_MIN, value);
    }
    const ecoMinDeviationChanged = (value) => {
        sendCmd(Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.ECO_MIN, value);
    }
    const ecoMaxDeviationChanged = (value) => {
        sendCmd(Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.ECO_MAX, value);
    }
    const shadingHeatChanged = (value) => {
        sendCmd(Commands.IRC_V2021.SHADING_TEMP_HEAT, value);
    }
    const shadingCoolChanged = (value) => {
        sendCmd(Commands.IRC_V2021.SHADING_TEMP_COOL, value);
    }


    // 2 separate comfort temperatures - this is the deviation from the cooling comfort
    const ecoMinContent = () => {
        let rows = [];
        rows.push(sliderEditCell(
            _("controls.ircv2021.single-comfort.deviation-eco"),
            _("controls.ircv2021.deviation-eco-heat-descr"),
            0.5,
            states?.comfortTempMin - states?.frostProtectTemperature,
            states?.absentMinOffset,
            ecoMinDeviationChanged
        ));

        rows.push(switchToCell(
            _("controls.ircv2021.eco-heating"),
            states?.comfortTempMin - states?.absentMinOffset,
            true, false
        ));
        return [{rows}]
    }

    // 2 separate comfort temperatures - this is the deviation from the heating comfort
    const ecoMaxContent = () => {
        let rows = [];
        rows.push(sliderEditCell(
            _("controls.ircv2021.single-comfort.deviation-eco"),
            _("controls.ircv2021.deviation-eco-cool-descr"),
            0.5,
            states?.heatProtectTemperature - states?.comfortTempMax,
            states?.absentMaxOffset,
            ecoMaxDeviationChanged
        ));

        rows.push(switchToCell(
            _("controls.ircv2021.eco-cooling"),
            states?.comfortTempMax + states?.absentMaxOffset,
            false, true
        ));
        return [{rows}]
    }

    // 1 comfort temperatures - this is the deviation from the target comfort temperature
    const singleComfortEcoContent = (forEcoMin, forEcoMax) => {
        let currentEcoDeviation = states?.absentMinOffset,
            rows = [],
            canHeat = control?.hasHeatingCapability(),
            canCool = control?.hasCoolingCapability(),
            heatTarget = states?.comfortTempMin - currentEcoDeviation,
            coolTarget = (states?.comfortTempMin + currentEcoDeviation),
            description = _("controls.ircv2021.single-comfort.deviation-eco-descr");

        if (canHeat && !canCool) {
            description = _("controls.ircv2021.single-comfort.deviation-eco-descr.heat-only")
        } else if (canCool && !canCool) {
            description = _("controls.ircv2021.single-comfort.deviation-eco-descr.cool-only")
        }

        let maxDeviation; // must not exceed heat or frost protection temperature.
        if (canCool && canHeat) {
            maxDeviation = Math.min(
                states?.heatProtectTemperature - states?.comfortTempMin, // upper range
                states?.comfortTempMin - states?.frostProtectTemperature // lower range
            );
        } else if (canCool) {
            maxDeviation = states?.heatProtectTemperature - states?.comfortTempMin;
        } else if (canHeat) {
            maxDeviation = states?.comfortTempMin - states?.frostProtectTemperature;
        }

        rows.push(sliderEditCell(
            _("controls.ircv2021.single-comfort.deviation-eco"),
            description,
            0.5,
            maxDeviation,
            currentEcoDeviation,
            ecoDeviationChanged
        ));

        canCool && rows.push(switchToCell(
            _("controls.ircv2021.eco-cooling"),
            coolTarget,
            false, true
        ));
        canHeat && rows.push(switchToCell(
            _("controls.ircv2021.eco-heating"),
            heatTarget,
            true,
            false
        ));

        return [{rows}];
    }

    const singleComfortContentHeatCool = () => {
        let rows = [];
        rows.push(sliderEditCell(
            _("controls.irc.temp.comfort"),
            _("controls.ircv2021.single-comfort.comfort-desc"),
            states?.frostProtectTemperature,
            states?.heatProtectTemperature,
            states?.comfortTempMin,
            comfortChanged
        ));
        rows.push(sliderEditCell(
            _("controls.ircv2021.single-comfort.deviation-comfort"),
            _("controls.ircv2021.single-comfort.deviation-comfort-desc"),
            0.5,
            3,
            states?.comfortTolerance,
            comfortToleranceChanged
        ));
        rows.push(switchToCell(
            _("controls.ircv2021.single-comfort.switch-to-cooling"),
            states?.comfortTempMin + states?.comfortTolerance,
            false, true
        ));
        rows.push(switchToCell(
            _("controls.ircv2021.single-comfort.switch-to-heating"),
            states?.comfortTempMin - states?.comfortTolerance,
            true,
            false
        ));
        return [{ rows }]
    }

    const singleComfortContentHeatOrCoolOnly = () => {
        let rows = [];
        rows.push(sliderEditCell(
            null,
            _("controls.ircv2021.single-comfort.comfort-desc"),
            states?.frostProtectTemperature,
            states?.heatProtectTemperature,
            states?.comfortTempMin,
            comfortChanged
        ));
        return [{ rows }]
    }

    const comfortHeatingTempContent = () => {
        let rows = [];
        rows.push(sliderEditCell(
            null,
            _("controls.ircv2021.single-comfort.comfort-desc"),
            states?.frostProtectTemperature,
            states?.heatProtectTemperature,
            states?.comfortTempMin,
            comfortHeatingChanged
        ));
        return [{ rows }]
    }

    const comfortCoolingTempContent = () => {
        let rows = [];
        rows.push(sliderEditCell(
            null,
            _("controls.ircv2021.single-comfort.comfort-desc"),
            states?.frostProtectTemperature,
            states?.heatProtectTemperature,
            states?.comfortTempMax,
            comfortCoolingChanged
        ));
        return [{ rows }]
    }

    const shadingContent = (forHeating) => {
        let rows = [];
        rows.push(sliderEditCell(null,
            forHeating ?
                _("controls.ircv2021.single-comfort.shading-heat-desc") :
                _("controls.ircv2021.single-comfort.shading-cool-desc"),
            states?.frostProtectTemperature,
            states?.heatProtectTemperature,
            forHeating ? states?.shadingTempHeat : states.shadingTempCool,
            forHeating ? shadingHeatChanged : shadingCoolChanged
        ));
        return [{rows}]
    }

    const RightPart = ({value, heating, cooling}) => {
        return <View style={styles.rightPartStyle}>
            <LxReactText style={[styles.smallText, styles.valueLabel]}>{fTemp(value)}</LxReactText>
            {heating ? <Icons.Flame style={[styles.rightIcon, styles.heatingIcon]} /> : null}
            {cooling ? <Icons.Snowflake style={[styles.rightIcon, styles.coolingIcon]} /> : null}
        </View>
    }

    const switchToCell = (title, value, heating = false, cooling = false) => {
        return {
            title,
            mainRightContent:  {
                comp: RightPart,
                props: {
                    value, heating, cooling
                }
            }
        }
    }

    const sliderEditCell = (title, subTitle, min, max, value, onValueChanged) => {
        return {
            title,
            subTitle,
            subTitleStyle: styles.smallText,
            bottomContent:  {
                comp: ButtonSlider,
                props: {
                    min, max,
                    step: control?.getTempStep(),
                    format: control?.getFormatForTemp(),
                    value,
                    onValueChanged
                }
            }
        }
    }

    const tableContent = useMemo(() => {
        let content,
            canHeat = control?.hasHeatingCapability(),
            canCool = control?.hasCoolingCapability();
        switch (tempType) {
            case TmpSliderType.SINGLE_COMFORT:
                if (canHeat && canCool) {
                    content = singleComfortContentHeatCool();
                } else {
                    content = singleComfortContentHeatOrCoolOnly();
                }
                break;
            case TmpSliderType.SHADING_HEAT:
            case TmpSliderType.SHADING_COOL:
                content = shadingContent(tempType === TmpSliderType.SHADING_HEAT)
                break;
            case TmpSliderType.ECO_SINGLE_COMFORT:
                content = singleComfortEcoContent();
                break;
            case TmpSliderType.ECO_MIN: // separate target heating/Cooling comfort temps
                content = ecoMinContent();
                break;
            case TmpSliderType.ECO_MAX: // separate target heating/Cooling comfort temps
                content = ecoMaxContent();
                break;
            case TmpSliderType.COMFORT_HEAT:
                content = comfortHeatingTempContent();
                break;
            case TmpSliderType.COMFORT_COOL:
                content = comfortCoolingTempContent();
                break;
            default:
                content = [];
                break;
        }
        return content;

    }, [
        JSON.stringify(states),
        control,
        control?.coolingCapability,
        control?.heatingCapability,
        tempType
    ]);

    return <LxReactTableView tableContent={tableContent}/>
}


const styles = {
    smallText: {
        color: globalStyles.colors.text.secondary,
        fontSize: globalStyles.fontSettings.sizes.smallReg,
        fontFamily: globalStyles.fontSettings.families.regular,
    },
    valueLabel: {
        height: "auto",
        textAlign: "right",
        maxWidth: "100%",
        minWidth: "35px",
        fontSize: globalStyles.fontSettings.sizes.small,
    },
    rightPartStyle: {
      flexDirection: "row"
    },
    rightIcon: {
        marginLeft: globalStyles.sizes.smallest,
        width: globalStyles.sizes.icons.tiny,
        height: globalStyles.sizes.icons.tiny
    },
    heatingIcon: {
        fill: globalStyles.colors.orange
    },
    coolingIcon: {
        fill: globalStyles.colors.blue
    }
}