'use strict';

define(["BatteryDeviceScreen", "LxComponents"], function (
    BatteryDeviceScreen,
    {
        ReactWrapper,
        LxReactQuickSelect
    }
) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var DeviceTypeEnum = {
        AIR_TYPES: "AirTypes",
        INTERNAL_NAME: "InternalName",
        LOCALIZED_NAME: "Name_Localized"
    };
    var DevicesJsonEnum = {
        IMAGE: "image",
        BATTERY_LINK: "battery-link"
    };

    /**
     * BatteryMonitorScreen displays all (Air + Battery) devices and the state of each device
     * - grouped by state
     * - grouped by room
     */
    return class BatteryMonitorScreen extends GUI.TableViewScreenV2 {

        #sortByState = true;

        get useNewTableView() {
            return true;
        }

        set setSortByState(value) {
            this.#sortByState = value;
            this.reloadTable();
        }

        constructor(details) {
            super($('<div class="battery-monitor-screen" />'));
            if (details && details.deviceUuid) {
                this._desiredDeviceUuid = details.deviceUuid;
            }
        }

        /**
         * gets called when this screen is already opened and the user taps another notification
         * @param details
         */
        updateView(details) {
            var device = this._getDeviceForUuid(details.deviceUuid);

            if (!device) {
                return;
            }

            this.ViewController.showState(ScreenState.BatteryDevice, null, {
                device: device
            });
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                return this._loadInformation();
            }.bind(this));
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments); // Check if we need to navigate to a specific device

            if (this._desiredDeviceUuid) {
                var device = this._getDeviceForUuid(this._desiredDeviceUuid);

                delete this._desiredDeviceUuid;

                if (!device) {
                    return;
                }

                this.ViewController.showState(ScreenState.BatteryDevice, null, {
                    device: device
                });
            }
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            return _('battery-monitor.overview');
        }

        reloadTable() {
            let options = [
                    _("sort.by-state"),
                    _("sort.by-room")
                ],
                devices;

            this.tableContent = [];


            if (this._deviceStates && this._deviceStates.length) {
                devices = this.#sortByState ? this._sortDevicesByState() : this._sortDevicesByRoom();

                this.tableContent = [
                    {
                        headerElement: ReactWrapper.React.createElement(LxReactQuickSelect, {
                            options: options,
                            selectedValue: options[+!this.#sortByState],
                            onOptionSelected: (value, idx) => {
                                this.setSortByState = !idx;
                            }
                        }),
                        rows: devices.map((device) => {
                            return this._generateCellContentForDevice(device);
                        })
                    }
                ]
            }

            return super.reloadTable(...arguments);
        }

        _sortDevicesByRoom() {
            let devicesWithRoom = [],
                devicesWithoutRoom = [];

            this._deviceStates.forEach((device) => {
                if (device.roomName && device.roomName.length) {
                    devicesWithRoom.push(device);
                } else {
                    devicesWithoutRoom.push(device);
                }
            })

            devicesWithRoom.sort((a, b) => a.roomName.localeCompare(b.roomName));
            devicesWithoutRoom.sort((a, b) => a.name.localeCompare(b.name));

            return devicesWithRoom.concat(devicesWithoutRoom);
        }

        _sortDevicesByState() {
            let onlineDevices = [],
                offlineDevices = [];

            this._deviceStates.forEach((device) => {
                if (this._isDeviceOnline(device)) {
                    onlineDevices.push(device);
                } else {
                    offlineDevices.push(device);
                }
            })

            offlineDevices.sort((a, b) => a.name.localeCompare(b.name));
            onlineDevices.sort((a, b) => a.batteryLevel - b.batteryLevel);

            return offlineDevices.concat(onlineDevices);
        }


        /**
         * Loads all infos at the same time and when everything is ready, it processes it.
         * @returns {Q.Promise<unknown>}
         * @private
         */
        _loadAndProcessInfos() {
            var promises = [],
                deviceStates = null; // devices json may be already cached, from the air/tree device search

            promises.push(ActiveMSComponent.getDevicesJSON().then(function (res) {
                this._deviceJson = Object.values(res);
            }.bind(this)));
            promises.push(this._requestDeviceTypes());
            promises.push(this._requestDeviceStates().then(function (devStates) {
                deviceStates = devStates;
            }));
            return Q.all(promises).then(function () {
                return this._processDeviceStates(deviceStates);
            }.bind(this));
        }

        _loadInformation() {
            Debug.BatteryMonitor && console.log(this.name, "_loadInformation (devices.json, device-types, device-states, process-device-states)");
            return this._loadAndProcessInfos().then(function () {

                if (this._deviceStates.length === 0) {
                    Debug.BatteryMonitor && console.log(this.name, "no devices found!");

                    this._showNoDevicesPopup();

                    return;
                }

                Debug.BatteryMonitor && console.log(this.name, " finished loading infos"); // add segmented control

                this.reloadTable();
            }.bind(this));
        }

        /**
         * loads device types from Miniserver (needed for friendly name of types
         * @private
         */
        _requestDeviceTypes() {
            Debug.BatteryMonitor && console.log(this.name, "_requestDeviceTypes");
            return this._sendBattCommand(Commands.BATTERY_MONITOR.GET_DEVICE_TYPES).then(function (result) {
                Debug.BatteryMonitor && console.log(this.name, " devices types loaded");
                this._deviceTypes = JSON.parse(result.LL.value);

                return Q.resolve();
            }.bind(this));
        }

        /**
         * Will send the desired command to the battery monitor UUID of the Miniserver via HTTP request.
         * @param cmd   the battery monitor command to send.
         * @returns {*}
         * @private
         */
        _sendBattCommand(cmd) {
            var fullCmd = Commands.format(Commands.CONTROL.COMMAND, ActiveMSComponent.getDeviceMonitorUuid(), cmd);
            return CommunicationComponent.sendViaHTTP(fullCmd);
        }

        /**
         * loads data from Miniserver and parses it
         * @private
         */
        _requestDeviceStates() {
            Debug.BatteryMonitor && console.log(this.name, "_requestDeviceStates");
            return this._sendBattCommand(Commands.BATTERY_MONITOR.GET_DEVICE_STATES).then(function (result) {
                Debug.BatteryMonitor && console.log(this.name, " devices states loaded");
                var devices = JSON.parse(result.LL.value);

                if (devices instanceof Array) {// normal Miniserver, ok
                } else {
                    // Gateway
                    var allDevices = [],
                        mac,
                        msDevices;

                    for (mac in devices) {
                        if (devices.hasOwnProperty(mac)) {
                            msDevices = devices[mac]; // Sanitize device properties to prevent JS Injection

                            msDevices.forEach(function (device) {
                                // "name" and "place" may be altered by the user to contain HTML entities
                                // which may result in JS injection
                                ["name", "place"].forEach(function (key) {
                                    device[key] = device[key].sanitizeHTML();
                                    if (device.type === "SteakThermo") {
                                        device[key] = device[key].replaceAll("&amp;", "&");
                                    }
                                });
                            });
                            allDevices = allDevices.concat(msDevices);
                        }
                    }

                    devices = allDevices;
                }

                return devices;
            }.bind(this));
        }

        _processDeviceStates(deviceStates) {
            Debug.BatteryMonitor && console.log(this.name, "_processDeviceStates");
            deviceStates.forEach(function (deviceState) {
                this._addAdditionalInfosToDevice(deviceState);
            }.bind(this));

            this._deviceStates = deviceStates; // generate table contents
        }

        _generateCellContentForDevice(device) {
            return {
                type: GUI.TableViewV2.CellType.BATTERY_MONITOR,
                action: function () {
                    this.ViewController.showState(ScreenState.BatteryDevice, null, {
                        device: device
                    });
                }.bind(this),
                content: {
                    leftIconSrc: device.image,
                    leftFallbackIconSrc: Icon.AIR_LOGO,
                    title: this._getTitleForDevice(device),
                    titleStyle: { ...Styles.textStyles.body.bold },
                    subtitle: this._getSubtitleForDevice(device),
                    subTitleStyle: { ...Styles.textStyles.body.bold },
                    disclosureText: this._getDisclosureTextForDevice(device),
                    disclosureColor: this._getDisclosureColorForDevice(device),
                    leftIconSize: Styles.sizes.icons.bigger
                }
            };
        }

        // Helper
        _getTitleForDevice(device) {
            return device.name;
        }

        _getSubtitleForDevice(device) {
            var str = "",
                place = device.name !== device.place ? device.place : null;

            if (device.roomName) {
                str = device.roomName;

                if (place !== null && place !== "" && str !== place) {
                    str += SEPARATOR_SYMBOL + place;
                }
            } else {
                return place;
            }

            return str;
        }

        _getDisclosureTextForDevice(device) {
            if (this._isDeviceOnline(device)) {
                return device.batteryLevel + "%";
            } else {
                return _('offline');
            }
        }

        _getDisclosureColorForDevice(device) {
            var colorBatteryLevel;

            if (device.batteryLevel === 0 || !this._isDeviceOnline(device)) {
                colorBatteryLevel = window.Styles.colors.red;
            } else if (device.batteryLow) {
                colorBatteryLevel = window.Styles.colors.orange;
            } else {
                colorBatteryLevel = window.Styles.colors.green;
            }

            return colorBatteryLevel;
        }

        _isDeviceOnline(device) {
            return device.deviceState & 1; // bit 0 = offline/online
        }

        /**
         * returns the device for the given uuid
         * @param deviceUuid
         * @returns {{}}
         * @private
         */
        _getDeviceForUuid(deviceUuid) {
            for (var i = 0; i < this._deviceStates.length; i++) {
                if (this._deviceStates[i].uuid === deviceUuid) {
                    return this._deviceStates[i];
                }
            }

            console.log("WARNING: BatteryMonitor Device (" + deviceUuid + ") is not supported yet, please make sure it's supported properly (API, App)!");
        }

        /**
         * returns a friendlyName for the given deviceType
         * @param deviceType
         * @returns {String}
         * @private
         */
        _getDeviceTypeString(deviceType) {
            var airTypes = this._deviceTypes[DeviceTypeEnum.AIR_TYPES],
                devType,
                i;

            for (i = 0; i < airTypes.length; i++) {
                devType = airTypes[i];

                if (devType[DeviceTypeEnum.INTERNAL_NAME] === deviceType) {
                    return devType[DeviceTypeEnum.LOCALIZED_NAME];
                }
            }

            return NOT_AVAILABLE;
        }

        /**
         * adds more info from our JSON to the device object (image, links,..)
         * @param device
         * @private
         */
        _addAdditionalInfosToDevice(device) {
            var dev,
                roomForUuid;
            device.friendlyType = this._getDeviceTypeString(device.type); // set default values.

            device.image = Icon.AIR_LOGO;
            device.batteryLink = "https://www.loxone.com/docu"; // Look for the actual values

            dev = this._deviceJson.find(function (_dev) {
                return _dev.type === device.type;
            });

            if (dev) {
                device.image = dev[DevicesJsonEnum.IMAGE] || device.image;
                device.batteryLink = dev[DevicesJsonEnum.BATTERY_LINK] || device.batteryLink; // The Touch & Grills battery isn't removable

                if (device.type === "SteakThermo") {
                    delete device.batteryLink;
                }
            }

            if (device.room) {
                roomForUuid = ActiveMSComponent.getStructureManager().getGroupByUUID(device.room);
            }

            device.roomName = roomForUuid ? roomForUuid.name : null;
        }

        /**
         * displays a popup which tells the user that no battery-powered Air devices are available
         * @private
         */
        _showNoDevicesPopup() {
            NavigationComp.showPopup({
                icon: Icon.INFO,
                title: _('battery-monitor.battery.no-devices'),
                buttonOk: true
            }).done(function () {
                this.ViewController.navigateBack();
            }.bind(this));
        }

    };
});
