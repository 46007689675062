'use strict';

Window.Controls = function (Controls) {
    {
        class SoundsuitAccountScreen extends GUI.TableViewScreenV2 {
            //region Static
            static Template = class {
                static getServiceHeader(title, subtitle, iconSrc) {
                    return $('<div class="soundsuite-header">'
                        + '       <div class="service-icon-placeholder">'
                        + '       ' + ImageBox.getResourceImageWithClasses(iconSrc, "service-icon")
                        + '       </div>' + '       <div class="service-text-placeholder">'
                        + '           <div class="service-text-title-label">' + title + '</div>'
                        + '           <div class="service-text-subtitle-label">' + subtitle + '</div>'
                        + '       </div>'
                        + '   </div>');
                }
            }
            //endregion Static

            constructor(details) {
                super($("<div/>"));
                this.service = details.service;
                this.control = details.control;
            }

            viewDidLoad() {
                Debug.Control.AudioZone.ServiceLogin && console.log(this.name, "viewDidLoad");
                return super.viewDidLoad(...arguments);
            }

            viewWillAppear() {
                Debug.Control.AudioZone.ServiceLogin && console.log(this.name, "viewWillAppear");
                return super.viewWillAppear(...arguments);
            }

            viewDidAppear() {
                return super.viewDidAppear(...arguments).then(() => {
                    this._unregService = this.control.audioserverComp.registerForServiceChanges(() => {
                        Debug.Control.AudioZone.ServiceLogin && console.log(this.name, "services changed - reload!");
                        this.reloadTable()
                    });
                });
            }

            viewWillDisappear() {
                this._unregService && this._unregService();
                this._unregService = null;

                return super.viewWillDisappear(...arguments);
            }

            viewDidDisappear(viewRemainsVisible) {
                Debug.Control.AudioZone.ServiceLogin && console.log(this.name, "viewDidDisappear");
                super.viewDidDisappear(viewRemainsVisible);
            }

            getAnimation() {
                return AnimationType.MODAL;
            }

            titleBarText() {
                return _("media.edit-favorites", {
                    favorite: this.service.user
                });
            }

            getURL() {
                return "SoundsuitAccount";
            }

            reloadTable() {
                this.tableContent = [this._getTopSection(), this._getZoneSection()];
                return super.reloadTable(...arguments);
            }

            _getTopSection() {
                return {
                    headerElement: SoundsuitAccountScreen.Template.getServiceHeader(this.service.name, _("media.preferences.music-service"), this.service.iconSrc),
                    rows: [
                        {
                            type: GUI.TableViewV2.CellType.CENTER,
                            content: {
                                title: _("delete"),
                                textColor: window.Styles.colors.red
                            },
                            action: this._deleteAccount.bind(this)
                        },
                        {
                            type: GUI.TableViewV2.CellType.CENTER,
                            content: {
                                title: _("audio-server.soundsuit.open-ws")
                            },
                            action: () => {
                                NavigationComp.openWebsite(this.service.helplink);
                            }
                        }
                    ]
                }
            }

            _getZoneSection() {
                return {
                    headerTitle: _("audio-server.soundsuit.zones"),
                    //TODO-woessto: do we need to manually reload zones?
                    //sectionRightButtonTitle: _("update-str"),
                    //rightSectionButtonTapped: this._reloadZones.bind(this),
                    //sectionRightButtonColor: window.Styles.colors.stateActive,
                    rows: this.service.zones.map(zone => {
                        return {
                            content: {
                                title: zone.name
                            }
                        }
                    })
                }
            }

            _deleteAccount() {
                var content,
                    message = _("media.preferences.services.editor.remove-account.message", {
                    service: this.service.name,
                    account: this.service[MusicServerEnum.Attr.SERVICE.USER]
                });

                content = {
                    title: _("media.preferences.services.editor.remove-account.title"),
                    message: message,
                    buttonOk: _("remove"),
                    buttonCancel: true,
                    color: window.Styles.colors.red,
                    icon: Icon.DELETE
                };

                NavigationComp.showPopup(content).done(() => {
                    var cmd = MusicServerEnum.Commands.SERVICES.CFG.DELETE + this.service[MusicServerEnum.Attr.SERVICE.UID];
                    NavigationComp.showWaitingFor(AudioserverComp.sendMediaServerCommand(cmd)).then(function () {
                        this.ViewController.navigateBack();
                    }.bind(this));
                });
            }

            _reloadZones() {
                //TODO-woessto: reload the zones!
            }
        }

        Controls.AudioZoneV2Control.SoundsuitAccountScreen = SoundsuitAccountScreen;
    }
    return Controls;
}(window.Controls || {});
