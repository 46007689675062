"use strict";

define("PoolControlContentOperatingModeList", ["PoolControlEnums"], function (PoolControlEnums) {
    return class PoolControlContentOperatingModeList extends Controls.ControlContent {
        constructor(details) {
            super(details);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.tableView = new GUI.TableViewV2(this, this);
            this.tableView.getElement().addClass("operating-mode-list__table-view");
            this.appendSubview(this.tableView);
        }

        getURL() {
            return _("settings");
        }

        /*getAnimation: function getAnimation() {
                     return AnimationType.PUSH_OVERLAP_LEFT;
                     },*/

        titleBarText() {
            return _('pool.control.settings.operating-mode');
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK,
                style: "transparent"
            };
        }

        receivedStates(states) {
            this._prepareTableViewContent(states);

            this.tableView.reload();
        }

        // TableViewDataSource Methods
        styleForTableView(tableView) {
            return TableViewStyle.GROUPED;
        }

        titleForHeaderInSection(section, tableView) {
            return this.tableViewContent[section].sectionTitle;
        }

        numberOfSections(tableView) {
            return this.tableViewContent.length;
        }

        numberOfRowsInSection(section, tableView) {
            return this.tableViewContent[section].cells.length;
        }

        cellTypeForCellAtIndex(section, row, tableView) {
            return this.tableViewContent[section].cells[row].cellType;
        }

        contentForCell(cell, section, row, tableView) {
            return this.tableViewContent[section].cells[row];
        }

        // TableViewDelegate Methods
        didCheckCell(cell, section, row, tableView, selected) {
            var newMode = PoolControlEnums.PoolMode.UNKNOWN;

            if (section === 0) {
                if (row === 0) {
                    newMode = PoolControlEnums.PoolMode.AUTOMATIC;
                } else {
                    newMode = PoolControlEnums.PoolMode.OUT_OF_ORDER;
                }
            } else if (section === 1) {
                newMode = PoolControlEnums.PoolMode.SERVICE_MODE;
            }

            if (newMode !== PoolControlEnums.PoolMode.UNKNOWN) {
                this.sendCommand(Commands.format(Commands.POOL.SET_OPERATING_MODE, newMode));
            }
        }

        didRequestCheckingCell(cell, section, row, tableView) {
            if (section === 1) {
                var content = {
                    message: _("pool.control.service-mode-alert.message"),
                    buttonOk: _("pool.control.service-mode-alert.activate-button").toUpperCase(),
                    buttonCancel: true,
                    icon: Icon.WRENCH,
                    color: window.Styles.colors.red
                };
                return NavigationComp.showPopup(content);
            } else {
                return true;
            }
        }

        // Private stuff
        _prepareTableViewContent(states) {
            var mainSection = {
                    sectionTitle: "",
                    cells: []
                },
                serviceSection = {
                    sectionTitle: "",
                    cells: []
                };
            mainSection.cells.pushObject(this._getAutomaticModeCell(states.currentOpModeNr));
            mainSection.cells.pushObject(this._getOutOfOrderModeCell(states.currentOpModeNr));
            serviceSection.cells.pushObject(this._getServiceModeCell(states.currentOpModeNr));
            this.tableViewContent = [mainSection, serviceSection];
        }

        _getAutomaticModeCell(currentOpModeNr) {
            return {
                cellType: GUI.TableViewV2.CellType.CHECKABLE,
                title: this.control.getNameOfPoolMode(PoolControlEnums.PoolMode.AUTOMATIC),
                subtitle: _("pool.control.automatic.subtitle"),
                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE,
                selected: currentOpModeNr === PoolControlEnums.PoolMode.AUTOMATIC
            };
        }

        /**
         * Will return an option to put the pool out of service via app. Only available in configs >10.1 and if
         * no logic is connected to the reset input on the control.
         * @param currentOpModeNr
         * @return {*}
         * @private
         */
        _getOutOfOrderModeCell(currentOpModeNr) {
            var cell = null; // if logic is connected to the reset input, don't allow manually overriding it.

            if (this.control.supportsOutOfServiceViaApp()) {
                cell = {
                    cellType: GUI.TableViewV2.CellType.CHECKABLE,
                    title: this.control.getNameOfPoolMode(PoolControlEnums.PoolMode.OUT_OF_ORDER),
                    subtitle: _("pool.control.OP-modes.out-of-order.description"),
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE,
                    selected: currentOpModeNr === PoolControlEnums.PoolMode.OUT_OF_ORDER
                };
            }

            return cell;
        }

        _getServiceModeCell(currentOpModeNr) {
            return {
                cellType: GUI.TableViewV2.CellType.CHECKABLE,
                title: this.control.getNameOfPoolMode(PoolControlEnums.PoolMode.SERVICE_MODE),
                subtitle: _("pool.control.servicemode.subtitle"),
                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE,
                inactiveTextColor: window.Styles.colors.red,
                activeTextColor: window.Styles.colors.red,
                selected: currentOpModeNr === PoolControlEnums.PoolMode.SERVICE_MODE
            };
        }

    };
});
