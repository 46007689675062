import {useConnectionReady} from "LxComponents";
import {useEffect, useState} from "react"

/**
 * Returns an object that lists all configured options of certain user-properties.
 * E.g. the companies and departments already configured on a MS.
 * @returns {{}}
 */
export default function useUserPropOptions() {
    const [propOptions, setPropOptions] = useState({});
    const connReady = useConnectionReady();

    useEffect(() => {
        if (connReady) {
            ActiveMSComponent.getUserPropertyOptions().then((res) => {
                setPropOptions(res);
            });
        }
    }, [connReady]);

    return propOptions;
}