'use strict';

define("SliderControl", [
    "Control",
    "IconLib"
], function (
    Control,
    {
        default: Icons
    }
) {
    return class SliderControl extends Control {
        constructor() {
            super(...arguments);
        }

        getButton0() {
            return {
                iconSrc: Icon.PLUS,
                reactIcon: Icons.Plus,
                command: {
                    tap: this._increaseValueCommand.bind(this),
                    tick: this._increaseValueCommand.bind(this)
                }
            };
        }

        getButton1() {
            return {
                iconSrc: Icon.MINUS,
                reactIcon: Icons.Minus,
                command: {
                    tap: this._decreaseValueCommand.bind(this),
                    tick: this._decreaseValueCommand.bind(this)
                }
            };
        }

        specificCreateCmdText(cmdParts, fmdArguments) {
            var text,
                cfg = {
                    name: this.name
                };
            var value = parseFloat(cmdParts[1]);
            cfg.value = lxFormat(this.details.format, value);
            text = _("cmdtext.analog.value", cfg);
            return text;
        }

        getFriendlyValueName(event, operator) {
            switch (event.data.stateName) {
                case "value":
                    return this.name + " " + event.data.stateTitle + " " + operator + " " + event.value.toString().replace(",", ".");

                default:
                    return "";
            }
        }

        /**
         * Decrease the value and checks if the current value is already at its minimum
         * @private
         */
        _decreaseValueCommand() {
            var states = this.getStates(),
                pos = states.pos,
                min = this.details.min,
                step = this.details.step;

            if (pos <= min) {
                // nothing to do if the pos is already at the maximum
                return;
            }

            var newVal = pos - step;

            if (newVal < min) {
                newVal = min;
            } // Don't send the same value again


            if (pos === newVal) {
                return;
            }

            return Commands.format(Commands.SLIDER.VALUE, newVal);
        }

        /**
         * Increases the value and checks if the current value is already at its maximum
         * @private
         */
        _increaseValueCommand() {
            var states = this.getStates(),
                pos = states.pos,
                min = this.details.min,
                max = this.details.max,
                step = this.details.step;

            if (pos >= max) {
                // nothing to do if the pos is already at the maximum
                return;
            } // Ensures to set valid positions
            // We can't set 0.5 if the min value is 10


            pos = Math.max(pos, min);
            var newVal = pos + step;

            if (newVal > max) {
                newVal = max;
            } // Don't send the same value again


            if (pos === newVal) {
                return;
            }

            return Commands.format(Commands.SLIDER.VALUE, newVal);
        }

    };
});
