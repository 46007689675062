'use strict';

define([ "ContentOverviewScreenBase", "MediaBrowserV2Service", "AudioZoneV2ControlEnums" ],
    function (ContentOverviewScreenBase, MediaBrowserV2Service, AudioZoneV2ControlEnums) {
    return class RadioContentOverviewScreen extends ContentOverviewScreenBase {

        get RIGHT_SIDE_BUTTON() {
            let parentBtns = super.RIGHT_SIDE_BUTTON;
            if (this.control.audioserverComp.Feature.CUSTOM_STREAMS) {
                parentBtns.ADD_RADIO = "right-side__touch-area--add-radio"
            }

            return parentBtns;
        }

        constructor(details) {
            super(...arguments);
            this._radios = [];
        }

        titleBarActionRight(buttonId) {
            if (buttonId === this.RIGHT_SIDE_BUTTON.ADD_RADIO) {
                return this._addCustomRadio();
            }
            return super.titleBarActionRight(...arguments);
        }

        _getRightSideTitleBarButtons() {
            let btns = super._getRightSideTitleBarButtons(...arguments);

            if (this.control.audioserverComp.Feature.CUSTOM_STREAMS) {
               btns.push(this._getRightSideTitleBarButtonElm(
                    this.RIGHT_SIDE_BUTTON.ADD_RADIO,
                   Icon.TitleBar.PLUS))
            }

            return btns;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(() => {
                this._radiosUnreg = this.control.audioserverComp.registerForRadioChanges(() => {
                    this.setup();
                });
            });
        }

        destroy() {
            this._radiosUnreg();
            super.destroy(...arguments);
        }

        loadData() {
            return super.loadData(...arguments).then(function () {
                return this.control.audioserverComp.getRadios().promise.then(function (radios) {
                    this._radios = radios;
                }.bind(this));
            }.bind(this));
        }

        titleBarText() {
            return _("media.service.webradio");
        }

        favFilterFunction(item) {
            return item.type === MusicServerEnum.FAVORITE_TYPES.TUNEIN ||
                item.type === MusicServerEnum.FAVORITE_TYPES.CUSTOM_STREAM;
        }

        getTableContent() {
            return super.getTableContent(...arguments).then(function (tableContent) {
                tableContent.pushObject(this._getMoreRadiosSection());
                return Q.all(tableContent);
            }.bind(this));
        }

        handleOnItemCellClicked(item, contentType, section, row) {
            if (contentType === MusicServerEnum.MediaContentType.SERVICE) {
                var details = {
                    username: MusicServerEnum.NOUSER
                };
                details.lastSelectedItem = item;
                details.contentTypes = [contentType];
                details.identifier = item.cmd;
                details.mediaType = MusicServerEnum.MediaType.SERVICE;
                details.control = this.control;
                return this.ViewController.showState(Controls.AudioZoneV2Control.MediaBrowserV2Base.getScreenStateForItem(item), null, details);
            }

            return super.handleOnItemCellClicked(...arguments);
        }


        getRightVCButtonIcon() {
            return this.control.audioserverComp.Feature.CUSTOM_STREAMS ? Icon.PLUS : null;
        }

        getRightVCButtonIconFn() {
            return this._addCustomRadio.bind(this);
        }

        _addCustomRadio() {
            this.ViewController.ViewController.showState(AudioZoneV2ControlEnums.ScreenState.CUSTOM_STREAM_SCREEN, null, {
                control: this.control
            }, AnimationType.MODAL);
        }

        _getMoreRadiosSection() {
            var section = this._getSection(_("explore"), this._radios.map(function (item) {
                var details = {
                    username: MusicServerEnum.NOUSER,
                    control: this.control,
                    identifier: item.cmd,
                    ignoreCtxMenu: true
                };
                var cell = MediaBrowserV2Service.getCellFromContentTypeItem(item, details, this.EVENT_TYPES.SERVICE, false, false, this.handleOnItemCellClicked.bind(this), this.handleOnCellButtonClicked.bind(this), this.ViewController);

                if ((HD_APP) && !this.isInAddMode()) {
                    cell.type = GUI.TableViewV2.CellType.Special.MEDIA_V2_COVER;
                    cell.content.hidePlayButton = true;
                    delete cell.content.class; // Removes the chevron class
                } else {
                    cell.content.class = "base-cell--with-chevron";
                }

                return cell;
            }.bind(this)), null, !this.isInAddMode());

            if (section) {
                delete section.customClass;
            }

            return section;
        }

    };
});
