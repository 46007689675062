import {
    LxReactImageView,
    WeatherComp,
    TimerComp,
    DynamicRightButton
} from "LxComponents"
import Icons from "IconLib";
import React from "react";
import globalStyles from "GlobalStyles";
import {getCurrentScreenState} from "./LxReactNavigationStateAnalyzer";
import TrustListScreen from "../../GUI/ActiveMiniserver/trust/trustListScreen";

const _getHeaderTitle = (navigation, isAmbientMode) => {
    let dimensions = {
        height: 32,
        width: 144
    }
    if (isAmbientMode) {
        let customGroupTitles = ActiveMSComponent.getStructureManager().getCustomGroupTitles();
        let screenState = getCurrentScreenState(navigation.getState());
        switch (screenState) {
            case ScreenState.FavoritesV2:
                return _('favorites.title');
            case ScreenState.House:
                return _('central');
            case ScreenState.Rooms:
                return customGroupTitles[GroupTypes.ROOM]
            case ScreenState.Categories:
                return customGroupTitles[GroupTypes.CATEGORY]
        }
    } else if (AppBranding.brandLogoUrl) {
        return () => <LxReactImageView
            key={"headerTitleLogo"}
            source={AppBranding.brandLogoUrl}
            imageStyle={{
                ...dimensions,
                objectFit: "scale-down"
            }}
            containerStyle={{
                ...dimensions,
                justifyContent: "center",
                alignItems: "center"
            }}
        />
    } else {
        return () => <Icons.LoxoneSlogan key={"headerTitleLogo"} {...dimensions} fill={globalStyles.colors.green}/>
    }
}

const _getLeftAction = (navigation, isAmbientMode) => {
    let leftActions = [],
        weatherControl = ActiveMSComponent.getStructureManager().getWeatherServer();

    leftActions.push({
        action: ({dimensions, props}) => {
            if (_checkIfBackButtonShouldBeShown(navigation)) {
                //return <Icons.BackArrow key={TitleBarCfg.Button.BACK} fill={props.tintColor} height={dimensions.height} width={dimensions.width}/>
                return <LxReactImageView source={Icon.Buttons.BACK} containerStyle={{
                    height: 35,
                    width: 35,
                    fill: globalStyles.colors.buttonDisabledBg
                }}/>
            } else if (isAmbientMode) {
                return null;
            } else {
                return <LxReactImageView source={Icon.Buttons.PLACE} containerStyle={{
                    height: 35,
                    width: 35,
                    fill: globalStyles.colors.buttonDisabledBg
                }} partFillColors={{
                    path: globalStyles.colors.white
                }}/>
            }
        }
    });
    if (!isAmbientMode && weatherControl) {
        leftActions.push({
            action: ({dimensions, props}) => {
                return <WeatherComp
                    uuidAction={weatherControl.uuidAction}
                    tintColor={props.tintColor}
                    dimensions={dimensions}
                />
            }
        })
    }

    return leftActions;
}

const _getLeftActionHandler = (idx, navigation) => {
    if (idx === 0) {
        if (_checkIfBackButtonShouldBeShown(navigation)) {
            let state = navigation.getState();
            if (state && state.routes) {
                let prevRoute = state.routes[state.routes.length - 2],
                    currRoute = state.routes[state.routes.length - 1];

                if (currRoute && currRoute.name === TrustListScreen.name) {
                    navigation.goBack();
                } else if (prevRoute) {
                    navigation.navigate(prevRoute.name);
                } else if (state.routeNames.length === 2) {
                    navigation.navigate(state.routeNames[0]);
                } else if (navigation.canGoBack()) {
                    navigation.goBack();
                }
            }
        } else {
            NavigationComp.showMenu()
        }
    }
}

const _getRightActions = (navigation, isAmbientMode) => {
    if (isAmbientMode) {
        return [];
    }
    return [
        {
            action: ({dimensions, props}) => {
                if (HD_APP) {
                    return <TimerComp/>
                }
            }
        },
        {
            action: ({dimensions, props}) => {
                return <DynamicRightButton key={"dynamicRightButton"} navigation={navigation}/>
            }
        },
        {
            action: ({dimensions, props}) => {
                return <LxReactImageView key={"search"} source={Icon.Buttons.SEARCH} containerStyle={{
                    height: 35,
                    width: 35,
                }} imageStyle={{fill: globalStyles.colors.buttonDisabledBg}}/>
                //return <Icons.Search key={"SearchButton"} fill={props.tintColor} height={dimensions.height} width={dimensions.width}/>
            }
        },
    ]
}

const _getRightActionHandler = (idx, navigation) => {
    if (idx === 2) {
        navigation.navigate(ScreenState.SearchScreen);
    }
}

const _checkIfBackButtonShouldBeShown = (navigation) => {
    const navState = navigation.getState();
    const screenState = getCurrentScreenState(navState);

    if (screenState === ScreenState.TrustListScreen) {
        const latestParams = navState.routes[navState.routes.length - 1].params;
        return latestParams && Array.isArray(latestParams.structurePath) && latestParams.structurePath.length > 0;
    } else {
        return (
            screenState === ScreenState.CategoryDetail ||
            screenState === ScreenState.RoomDetail
        )
    }
}


export default ({navigation}, isAmbientMode) => {
    return {
        isAmbientMode: isAmbientMode,
        headerStyle: {
          backgroundColor:  isAmbientMode || AMBIENT_MODE ?  globalStyles.colors.transparent : globalStyles.colors.black
        },
        headerTitle: _getHeaderTitle(navigation, isAmbientMode),
        leftActions: _getLeftAction(navigation, isAmbientMode),
        onLeftAction: (idx) => {
            return _getLeftActionHandler(idx, navigation, isAmbientMode)
        },
        rightActions: _getRightActions(navigation, isAmbientMode),
        onRightAction: (idx) => {
            return _getRightActionHandler(idx, navigation)
        }
    };
};
