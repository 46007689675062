import TempDisplay from "./tempDisplay";

export default function IrcTempDisplay({controlUuid, containerStyle, forCell = false}) {
    return <TempDisplay
        controlUuid={controlUuid}
        containerStyle={containerStyle}
        forCell={forCell}
        tempState={"tempActual"}
    />
}
