import LinearGradient from 'react-native-linear-gradient';
import { createShimmerPlaceholder } from 'react-native-shimmer-placeholder'
import PropTypes from "prop-types";


const ShimmerPlaceHolder = createShimmerPlaceholder(LinearGradient)

function LxShimmerPlaceHolder(props) {

    let style = {
        borderRadius: 17,
    }

    if (props.type) {
        switch(props.type) {
            case "Primary":
                style.width = "100%";
                break;
            case "Secondary":
                style.width = "60%";
                style.marginTop = Styles.spacings.gaps.small
                break;
        }
    }

    return <ShimmerPlaceHolder
        visible={!props.isLoading}
        style={[style, props.style]}
        shimmerColors={['#EBEBF54C', '#c5c5c5', '#EBEBF54C']}
    />
}


LxShimmerPlaceHolder.propTypes = {
    style: PropTypes.object,
    isLoading: PropTypes.bool,
    type: PropTypes.string
}

export default LxShimmerPlaceHolder;
