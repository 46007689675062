import { useLiveState, useControl, LxReactButton, ButtonType, LxReactText } from "LxComponents";
import globalStyles from "GlobalStyles";
import { View } from "react-native";
import { useMemo } from "react";
import Icons from "IconLib";

export default function ChargingInfo({ controlUUid }) {
    const control = useControl(controlUUid);
    const liveState = useLiveState(controlUUid, ["session", "actualValue", "chargingEnabled", "carConnected", "isCharging", "durationSecs", "limit", "notEnoughPowerAssigned"]);

    const styles = {
        rootCntr: {
            flexDirection: "column",
            marginLeft: globalStyles.sizes.smaller,
            marginRight: globalStyles.sizes.smaller,
        },
        infoCntr: {
            flexDirection: "row",
            flexWrap: "wrap"
        },
        infoItem: {
            width: "50%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 12,
            marginBottom: 12,
        },
        itemValue: {
            ...globalStyles.textStyles.title3.bold,
            textAlign: "center",
            color: globalStyles.colors.text.primary,
            maxWidth: "100%"
        },
        itemNoValue: {
            ...globalStyles.textStyles.default,
            textAlign: "center",
            color: globalStyles.colors.text.secondary
        },
        itemUnit: {
            ...globalStyles.textStyles.footNote.bold,
            textAlign: "left",
            color: globalStyles.colors.text.primary,
        },
        itemTitle: {
            ...globalStyles.textStyles.body.default,
            textAlign: "center",
            width: "98%",
            color: globalStyles.colors.text.secondary,
            marginTop: 4
        },
        actionButtonCntr: {
            backgroundColor: globalStyles.colors.grey["300a36"],
            marginTop: globalStyles.sizes.mediumBig,
            marginBottom: globalStyles.sizes.small,
            width: "100%",
            flexDirection: "row"
        },
        actionButton: {
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
        },
        actionButtonIcon: {
            width: globalStyles.sizes.icons.smaller,
            height: globalStyles.sizes.icons.smaller,
            marginRight: globalStyles.sizes.smaller,
            fill: globalStyles.colors.text.primary,
            alignSelf: "center"
        },
        actionButtonText: {
            ...globalStyles.textStyles.body.bold,
            color: globalStyles.colors.text.primary,
            textAlign: "left",
            alignSelf: "center"
        }
    }

    const noValTxt = "--";

    const renderInfo = (infoObj) => {
        let valueStyle = { ... (infoObj.itemValue === noValTxt ? styles.itemNoValue : styles.itemValue) };
        let unitStyle = { ...styles.itemUnit };

        if (!!infoObj.color && infoObj.itemValue !== noValTxt) {
            valueStyle.color = infoObj.color;
            unitStyle.color = infoObj.color;
        } else if (!infoObj.color) {
            valueStyle.color = globalStyles.colors.text.primary;
            unitStyle.color = globalStyles.colors.text.primary;
        } else {
            valueStyle.color = globalStyles.colors.text.secondary;
            unitStyle.color = globalStyles.colors.text.secondary;
        }

        return (
            <View style={styles.infoItem} key={infoObj.title}>
                <LxReactText style={valueStyle} key={"value"}>{
                    [
                        infoObj.valueTxt,
                        <LxReactText style={unitStyle} key={"valueUnit"}>{infoObj.unitTxt}</LxReactText>,
                        infoObj.value2Txt,
                        infoObj.unit2Txt ? <LxReactText style={unitStyle} key={"valueUnit2"}>{infoObj.unit2Txt}</LxReactText> : null
                    ]
                }
                </LxReactText>
                <LxReactText style={styles.itemTitle} key={"title"}>{infoObj.title}</LxReactText>
            </View>
        );
    }

    const renderInfos = () => {
        //console.log(ChargingInfo.name, "renderInfos " +JSON.stringify(liveState.states.session) + ", durationSecs=" + liveState.states.durationSecs);
        let powerValue = noValTxt,
            powerUnit = "",
            powerColor = null,
            targetPowerValue = noValTxt,
            energyValue = noValTxt,
            energyUnit = "",
            targetPowerUnit = "",
            connSinceText = noValTxt,
            durationText = noValTxt,
            renderedInfos,
            costInfo = null,
            session = liveState.states.session,
            limit = liveState.states.limit;

        if (session) {
            if (session.hasOwnProperty("price") && !control.shouldHidePrice(session.price)) {
                costInfo = {
                    title: _("controls.wallbox2.total-cost"),
                    valueTxt: lxFormat("%.2f", session.price, true),
                    unitTxt: ActiveMSComponent.getCurrencyString()
                }
            }

            let splitTexts = lxUnitConverter.convertSplitAndApply(control.actualFormat, session.power, true);
            powerValue = splitTexts.valueTxt;
            powerUnit = splitTexts.succTxt;
            powerColor = session.power !== 0 ? globalStyles.colors.stateActive : null;
            splitTexts = lxUnitConverter.convertSplitAndApply(control.totalFormat, session.energy, true);
            energyValue = splitTexts.valueTxt;
            energyUnit = splitTexts.succTxt;
            splitTexts = lxUnitConverter.convertSplitAndApply(control.actualFormat, limit, true);
            targetPowerValue = splitTexts.valueTxt;
            targetPowerUnit = splitTexts.succTxt;

            connSinceText = LxDate.formatPastDateDynamic(session.connect, true);
            if (session.connect > 0) {
                durationText = LxDate.formatSecondsShort(liveState.states.durationSecs, true, true, false);
            }
        }

        let infos = []

        control.getCpIsConnected && infos.push(
            { title: _("controls.wallbox2.current-power"), valueTxt: powerValue, unitTxt: powerUnit, color: powerColor }
        )

        let targetPowerInfo = { title: _("controls.wallbox2.target-power"), valueTxt: targetPowerValue, unitTxt: targetPowerUnit }
        if (liveState?.states?.notEnoughPowerAssigned) {
            targetPowerInfo.color = globalStyles.colors.orange;
        }
        infos.push(targetPowerInfo)

        if (control.carConnectedInfoAvailable && session && session.connect > 0) {
            let connSinceParts = connSinceText.split(" ");
            let val1, unit1;
            if (useAmPm()) {
                val1 = connSinceParts.slice(0, -1).join(" ")
                unit1 = " " + connSinceParts[connSinceParts.length - 1]
            } else {
                val1 = connSinceText
            }
            infos.push({ title: _("controls.wallbox2.connected-since"), valueTxt: val1, unitTxt: unit1 });

            let durParts = durationText.split(" ");
            let val2, unit2;
            val1 = durParts[0];
            unit1 = durParts.length > 1 ? " " + durParts[1].toUpperCase() : null;
            val2 = durParts.length > 2 ? " " + durParts[2] : null;
            unit2 = durParts.length > 3 ? " " + durParts[3].toUpperCase() : null;
            infos.push({ title: _("controls.wallbox2.duration"), valueTxt: val1, unitTxt: unit1, value2Txt: val2, unit2Txt: unit2 });
            control.getMeterIsConnected && infos.push({ title: _("controls.wallbox2.charged-energy"), valueTxt: energyValue, unitTxt: energyUnit });

            if (liveState.states.session.user) {
                infos.push({ title: _("controls.wallbox2.user-id"), valueTxt: liveState.states.session.user });
            }
        }

        if (costInfo !== null) {
            infos.push(costInfo);
        }

        if (session?.extAuthority) {
            let authInfo = {
                title: _("controls.wallbox2.ext-approval"),
                valueTxt: session.extAuthority
            }
            infos.push(authInfo);
        }


        renderedInfos = infos.map((info) => { return renderInfo(info); });
        return renderedInfos;
    };

    const handleActionButtonPressed = () => {
        control.toggleCharging();
    }

    const { actionIcon, actionText, actionEnabled, buttonType } = useMemo(() => {
        // start/pause/resume
        let actionIcon, actionText, actionEnabled = true,
            buttonType = ButtonType.SECONDARY;

        if (liveState.states.isPaused) { // resume
            actionIcon = Icons.Flash;
            actionText = _("controls.wallbox2.resume-charging");


        } else if (liveState.states.chargingEnabled) { // pause
            actionIcon = Icons.Pause;
            actionText = _("controls.wallbox2.pause-charging");

        } else { // start
            actionIcon = Icons.Flash;
            actionText = _("controls.car-charger.start-charging");
        }
        actionEnabled = liveState.states.carConnected;

        return {
            actionIcon,
            actionText,
            actionEnabled,
            buttonType
        }
    }, [liveState.states.chargingEnabled, liveState.states.carConnected]);

    const renderButton = () => {
        return control?.isReadyOnly ? null : <LxReactButton onPress={handleActionButtonPressed}
            disabled={!actionEnabled}
            leftIcon={actionIcon}
            text={actionText}
            buttonType={buttonType}
            containerStyle={styles.actionButtonCntr}
            pressableStyle={styles.actionButton} />
    }


    return <View style={styles.rootCntr}>
        <View style={styles.infoCntr}>
            {renderInfos()}
        </View>
        {renderButton()}
    </View>
}
