"use strict";
/*
 title
 color
 minValue           // default 0
 maxValue           // default 100
 minIconSrc
 maxIconSrc
 preventMinIconScale
 step
 value
 [valueColor]
 format
 formatWithHTML     // to include e.g. an icon after the value
 antiGhostTimer     // true per default
 updateRate

 sliderDragged(cell, section, row, tableView, value)
 sliderClicked(cell, section, row, tableView, value)
 userfriendlyValueForSlider(cell, section, row, tableView, value)  // gets called on dragging/after you set the value, you are able to adopt the displayed value (e.g. you have min=10 and max=80 set, but want to display from 0-100%)
 */

GUI.TableViewV2.CellType.SLIDER = "SliderCell";

class SliderCell extends GUI.TableViewV2.Cells.BaseCell {
    //region Static
    static Template = function () {
        var getTemplate = function getTemplate(minIconSrc, maxIconSrc, preventMinIconScale) {
            return $('<div class="label-placeholder">' + '   <div class="label-placeholder__title" />' + '   <div class="label-placeholder__value" />' + '</div>' + '<div class="slider-placeholder">' + (minIconSrc ? getButton(minIconSrc, "min-button", "min-icon" + (preventMinIconScale ? " min-icon--prevent-scale" : "")) : "") + '<div class="slider-placeholder__slider" />' + (maxIconSrc ? getButton(maxIconSrc, "max-button", "max-icon") : "") + '</div>');
        };

        var getButton = function getButton(iconSrc, btnClass, iconClass) {
            return '<div class="slider-placeholder__button ' + btnClass + '">' + ImageBox.getResourceImageWithClasses(iconSrc, "button__icon " + iconClass) + '</div>';
        };

        return {
            getTemplate: getTemplate
        };
    }(); //endregion Static

    constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
        super(...arguments);
        this.element.addClass("slider-cell");
    }

    viewDidLoad() {
        var promises = [super.viewDidLoad(...arguments)]; // verify content settings are configured properly.

        this._defaultEmptyContent();

        var template = SliderCell.Template.getTemplate(this.content.minIconSrc, this.content.maxIconSrc, !!this.content.preventMinIconScale);
        promises.push(GUI.animationHandler.append(template, this.contentPlaceholder));
        return Q.all(promises).then(() => {
            this.elements = {
                titleLbl: this.contentPlaceholder.find(".label-placeholder__title"),
                valueLbl: this.contentPlaceholder.find(".label-placeholder__value"),
                sliderPlaceholder: this.contentPlaceholder.find(".slider-placeholder .slider-placeholder__slider")
            };

            if (this.content.minIconSrc) {
                this.minButton = new GUI.LxButton(this, this.contentPlaceholder.find(".slider-placeholder .slider-placeholder__button.min-button"));
                this.addToHandledSubviews(this.minButton);
            }

            if (this.content.maxIconSrc) {
                this.maxButton = new GUI.LxButton(this, this.contentPlaceholder.find(".slider-placeholder .slider-placeholder__button.max-button"));
                this.addToHandledSubviews(this.maxButton);
            }

            if (this.content.valueColor) {
                this.elements.valueLbl.css("color", this.content.valueColor);
            }

            return this.getSlider(this.elements.sliderPlaceholder, {
                min: this.content.minValue,
                max: this.content.maxValue,
                step: this.content.step,
                value: this.content.value,
                disabled: this.content.disabled
            }).then(instance => {
                this.slider = instance;
                this.elements.titleLbl.text(this.content.title);
            });
        });
    }

    viewWillAppear() {
        var promise = super.viewWillAppear(...arguments);
        this.setPosition(this.content.value, true);
        return promise;
    }

    viewDidAppear() {
        var promise = super.viewDidAppear(...arguments);

        this.slider.onPositionChanged = (value, isOff, isDragging) => {
            this.setPosition(value);

            if (!isDragging) { // react when slider track got clicked
                if (typeof this.delegate.sliderDragEnded === "function") {
                    this.delegate.sliderDragEnded.call(this.delegate, this, this.sectionIdx, this.rowIdx, this.tableView, value);
                }
            }

            if (typeof this.delegate.sliderDragged === "function") {
                this.delegate.sliderDragged.call(this.delegate, this, this.sectionIdx, this.rowIdx, this.tableView, value, isDragging);
            }
        };

        this.slider.onDraggingChanged = (value, isDragging) => { // disable tableview scrolling while dragging

            if (!isDragging) {
                if (typeof this.delegate.sliderDragEnded === "function") {
                    this.delegate.sliderDragEnded.call(this.delegate, this, this.sectionIdx, this.rowIdx, this.tableView, value);
                }
            }

            let possibleTableViewParent = this.element.closest(".lx-table-view-v2");
            if (possibleTableViewParent) {
                let overflowValue = isDragging ? "hidden" : "auto";
                possibleTableViewParent.css("overflow-y", overflowValue);
            }
        }

        if (this.minButton) {
            this.minButton.onButtonTapped = () => {
                if (this.content.disabled) {
                    return;
                }

                var newVal = this.content.value - this.content.step;

                if (newVal >= this.content.minValue) {
                    this.setPosition(newVal, true);
                    this.delegate.sliderClicked.call(this.delegate, this, this.sectionIdx, this.rowIdx, this.tableView, newVal);
                }
            };
        }

        if (this.maxButton) {
            this.maxButton.onButtonTapped = () => {
                if (this.content.disabled) {
                    return;
                }

                var newVal = this.content.value + this.content.step;

                if (newVal <= this.content.maxValue) {
                    this.setPosition(newVal, true);
                    this.delegate.sliderClicked.call(this.delegate, this, this.sectionIdx, this.rowIdx, this.tableView, newVal);
                }
            };
        }

        this.setPosition(this.content.value, true);
        return promise;
    }

    viewWillDisappear() {
        this.slider.onPositionChanged = null;

        if (this.minButton) {
            this.minButton.onButtonTapped = null;
        }

        if (this.maxButton) {
            this.maxButton.onButtonTapped = null;
        }

        return super.viewWillDisappear(...arguments);
    }

    setEnabled(enabled) {
        super.setEnabled(...arguments);

        if (this.slider) {
            this.slider.setEnabled(enabled);
        }
    }

    /**
     * The reason this method exists is to be able to change to another slider implementation in the subclasses.
     * @param targetElement HTMLElement, in which the react component should be rendered
     * @param config Object, that contains slider configuration values such as max, min, step...
     * @returns {*}
     */
    getSlider(targetElement, config) {
        let hasCustomRate = this.content.hasOwnProperty("updateRate");
        console.log(this.viewId, "Appending Slider")
        return this.appendReactComp({
            reactComp: GUI.LxSlider,
            compProps: {
                ...config,
                hasCustomRate: hasCustomRate,
                activeColor: this.content.color,
                antiGhostTimer: !!this.content.antiGhostTimer,
                updateRate: this.content.updateRate,
                containerStyle: {
                    marginLeft: 15,
                    marginRight: 15
                }
            },
            target: targetElement
        });
    }

    // Public methods
    setPosition(value, forceUpdate) {
        value = Math.max(this.content.minValue, Math.min(value, this.content.maxValue)); // make sure that the value is in the range!

        if (Math.abs(value) > 0 && Math.abs(value) < 0.0000000001) { // Temporary Safety Check, because numbers such as 1e-17 are displayed as nAN
            value = 0;
        }

        this.content.value = value;
        this.slider.setPosition(this.content.value, !!forceUpdate);
        var userfrieldyValue = value;

        if (this.delegate.userfriendlyValueForSlider) {
            userfrieldyValue = this.delegate.userfriendlyValueForSlider.call(this.delegate, this, this.sectionIdx, this.rowIdx, this.tableView, value) || value;
        }

        if (this.content.formatWithHTML) {
            GUI.animationHandler.setHtml(this.elements.valueLbl, lxFormat(this.content.formatWithHTML, userfrieldyValue, true));
        } else if (lxUnitConverter.isConvertable(this.content.format)) {
            GUI.animationHandler.setText(this.elements.valueLbl, lxUnitConverter.convertAndApply(this.content.format, userfrieldyValue, true));
        } else {
            GUI.animationHandler.setText(this.elements.valueLbl, lxFormat(this.content.format, userfrieldyValue, true));
        }
    }

    /**
     * Uses SliderCellDefault Enum to fill missing content attributes with default values.
     * @private
     */
    _defaultEmptyContent() {
        var defaultContent = {
            minValue: 0,
            maxValue: 100,
            step: 1,
        };
        Object.keys(defaultContent).forEach((attrId) => {
            if (!this.content.hasOwnProperty(attrId)) {
                this.content[attrId] = defaultContent[attrId];
            }
        });
    }

}

GUI.TableViewV2.Cells.SliderCell = SliderCell;
