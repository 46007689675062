'use strict';
/**
 * This screen is used to list all scheduled operating modes including their dates. They can be edited, renamed
 * deleted or new ones can be added to the schedule.
 */

define([
    "OpModeScheduleDetailScreen",
    "IconLib"
], function (
    OpModeScheduleDetailScreen,
    {
        default: Icons
    }
) {
    return class OperatingModeScreen extends GUI.TableViewScreenV2 {

        get useNewTableView() {
            return true;
        }

        constructor(details) {
            super($('<div class="operating-mode-screen" />'));
            this.schedule = null;
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(() => {
                this.elements = {}; // Returning a promise will show "Waiting for" loading popup when it takes longer then 800ms to execute

                return this._loadData();
            });
        }

        updateView(details) {
            var promises = [super.updateView(...arguments)];

            if (details && details.updated === true) {
                promises.push(this._loadData());
            }

            return promises;
        }

        getPermissions() {
            return [{
                permission: MsPermission.OP_MODES
            }];
        }

        getURL() {
            return "OperatingModeScreen";
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            return _("opmodes.schedule.name");
        }

        titleBarAction() {
            this.ViewController.navigateBack();
        }

        reloadTable() {
            this.tableContent = [];
            this.tableContent.push(this._createActionSection());

            if (this.schedule) {
                this._prepareSection(this.tableContent, this.schedule.active, _("opmodes.active-entries"));

                this._prepareSection(this.tableContent, this.schedule.next, _("opmodes.next-entries"));

                this._prepareSection(this.tableContent, this.schedule.passed, _("opmodes.passed-entries"));
            }

            return super.reloadTable(...arguments);
        }

        _loadData() {
            return ActiveMSComponent.getSchedules().then(function (newSchedule) {
                this.schedule = newSchedule;
                return this.reloadTable();
            }.bind(this));
        }

        /**
         * Creates and returns the content for the action section (creating new schedule entries)
         * @returns {{}}
         * @private
         */
        _createActionSection() {
            return {
                rows: [{
                    type: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        leftIconSrc: Icons.Plus,
                        leftIconColor: window.Styles.colors.green,
                        title: _("opmodes.new-entry")
                    },
                    action: this._showEntryEditor.bind(this, null)
                }]
            };
        }

        /**
         * Will prepare a section and - if there are any cells to display - add it to the trgt.
         * @param trgt
         * @param src
         * @param title
         * @private
         */
        _prepareSection(trgt, src, title) {
            var section = this._createScheduleSection(src, title);

            if (section.rows.length > 0) {
                trgt.push(section);
            }
        }

        /**
         * Creates and returns the content that lists the existing schedules.
         * @returns {{}}
         * @private
         */
        _createScheduleSection(src, title) {
            var scheduleCells = src.map(function (entry) {
                return this._createScheduleCell(entry);
            }.bind(this));
            return {
                headerTitle: title,
                rows: scheduleCells
            };
        }

        /**
         * Will create the content for a schedule entry.
         * @param entry
         * @returns {{type: string, content: {title: *, disclosureText: string, disclosureIcon: boolean}, action: (function(this:_createScheduleCell))}}
         * @private
         */
        _createScheduleCell(entry) {
            let cell = {
                type: GUI.TableViewV2.CellType.GENERAL,
                content: {
                    title: entry.name,
                    subtitle: entry.dateText,
                    disclosureText: entry.operatingMode.name,
                },
                action: this._showEntryEditor.bind(this, entry)
            }; // if there's something wrong with this entry, present it.

            if (!!entry.hasError) {
                cell.content.rightIconSrc = Icon.WARNING;
                cell.content.rightIconColor = window.Styles.colors.red;
            }

            if (!!entry.isActive) {
                cell.content.disclosureColor = window.Styles.colors.stateActive;
                cell.content.subtitleColor = window.Styles.colors.stateActive;

            }

            return cell;
        }

        /**
         *
         * @param [entry]   optional. The entry to edit. if no entry is defined, a new one will be created.
         * @private
         */
        _showEntryEditor(entry) {
            Debug.OperatingMode && console.log(this.name, "_showEntryEditor: " + JSON.stringify(entry));
            this.ViewController.showState(ScreenState.OperatingMode.DetailScreen, null, {
                entry: entry // if not defined, a new one is created in the details.
            });
        }

    };
});
