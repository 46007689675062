import * as React from "react"
import Svg, { Path, Rect } from "react-native-svg"

function Weather26(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            <Path
                d="M3 16.2a4.79 4.79 0 012.44-4.158A7.26 7.26 0 0112.75 5a7.333 7.333 0 016.22 3.412A5.36 5.36 0 0120.47 8.2a5.28 5.28 0 014.944 3.37A4.793 4.793 0 0124.125 21H7.875A4.838 4.838 0 013 16.2zM22.502 25h-.795l.208-.208a.5.5 0 00-.707-.707l-.208.208v-.795a.5.5 0 00-1 0v.795l-.208-.208a.5.5 0 00-.707.707l.208.208h-.795a.5.5 0 000 1h.795l-.208.208a.5.5 0 00.707.707l.208-.208v.795a.5.5 0 001 0v-.795l.208.208a.5.5 0 00.707-.707L21.707 26h.795a.5.5 0 000-1zM26.701 24.2h-.477l.125-.125a.3.3 0 00-.424-.424l-.125.125v-.477a.3.3 0 00-.6 0v.477l-.125-.125a.3.3 0 00-.424.424l.125.125h-.477a.3.3 0 000 .6h.477l-.125.125a.3.3 0 00.424.424l.125-.125v.477a.3.3 0 00.6 0v-.477l.125.125a.3.3 0 00.424-.424l-.125-.125h.477a.3.3 0 000-.6z"
                fill="#d5e2e9"
                fillRule="evenodd"
            />
            <Rect
                x={5.48236}
                y={22.93185}
                width={2}
                height={4}
                rx={1}
                ry={1}
                transform="rotate(15 6.482 24.932)"
                fill="#51a9df"
            />
            <Rect
                x={9.22354}
                y={22.89778}
                width={2}
                height={6}
                rx={1}
                ry={1}
                transform="rotate(15 10.224 25.898)"
                fill="#51a9df"
            />
            <Rect
                x={13.48236}
                y={22.93185}
                width={2}
                height={4}
                rx={1}
                ry={1}
                transform="rotate(15 14.482 24.932)"
                fill="#51a9df"
            />
        </Svg>
    )
}

export default Weather26
