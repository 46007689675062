import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Tick(props) {
    return (
        <Svg isreact="true"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.676.263a1 1 0 01.061 1.413l-11 12a1 1 0 01-1.406.067l-5-4.5a1 1 0 011.338-1.486l4.263 3.837L16.262.324a1 1 0 011.414-.061z"
            />
        </Svg>
    )
}

export default Tick
