'use strict';

define([], function () {
    Controls.AudioZoneV2Control.SingleTones = Controls.AudioZoneV2Control.SingleTones || {};

    if (Controls.AudioZoneV2Control.SingleTones.CustomizationManager) {
        return Controls.AudioZoneV2Control.SingleTones.CustomizationManager;
    } else {
        class CustomizationManager {
            //region Static
            static get default() {
                var menuKeys = Object.keys(MusicServerEnum.ControlContentMenuId),
                    sectionId,
                    menuValue,
                    defaultCustomizationMap = {},
                    enabled;
                defaultCustomizationMap[MusicServerEnum.ControlContentSectionId.START] = {};
                defaultCustomizationMap[MusicServerEnum.ControlContentSectionId.MENU] = {};
                menuKeys.forEach(function (menuKey) {
                    // Groups is not used right now
                    if (menuKey === MusicServerEnum.ControlContentMenuId.GROUPS) {
                        return;
                    }

                    menuValue = MusicServerEnum.ControlContentMenuId[menuKey];
                    sectionId = menuValue.split("-")[0]; // We disable Libs and LineIn per default (as requested by Tom in focus weeks)

                    switch (menuValue) {
                        case MusicServerEnum.ControlContentMenuId.LIB:
                        case MusicServerEnum.ControlContentMenuId.LINE_IN:
                            enabled = false;
                            break;

                        default:
                            enabled = true;
                    }

                    defaultCustomizationMap[sectionId][menuValue] = {
                        rating: this._getDefaultRatingForStartKeyInSectionId(menuValue, sectionId),
                        enabled: enabled,
                        key: menuValue,
                        automatic: true
                    };
                }.bind(this));
                return defaultCustomizationMap;
            }

            static getSingeltonInputSettings(customizationMap) {
                var sectionId = MusicServerEnum.ControlContentMenuId.LINE_IN.split("-")[0];
                Debug.Control.AudioZone.CustomizationManager && console.log("CustomizationManager", "getSingeltonInputSettings " + JSON.stringify(customizationMap[sectionId][MusicServerEnum.ControlContentMenuId.LINE_IN]));
                return customizationMap[sectionId][MusicServerEnum.ControlContentMenuId.LINE_IN];
            }

            static filterUnavailableInputs(customizationMap, inputs) {
                Debug.Control.AudioZone.CustomizationManager && console.log("CustomizationManager", "filterUnavailableInputs");
                var sectionId = MusicServerEnum.ControlContentMenuId.LINE_IN.split("-")[0],
                    inputKeys = inputs.map(input => {
                        return MusicServerEnum.ControlContentMenuId.LINE_IN + "-" + input.audiopath;
                    }),
                    keysToRemove = [];
                Object.keys(customizationMap[sectionId]).forEach(menuKey => {
                    if (this.isInputCustomizationKey(menuKey)) {
                        if (inputKeys.indexOf(menuKey) >= 0) {
                            Debug.Control.AudioZone.CustomizationManager && console.log("CustomizationManager", "filterUnavailableInputs  > " + menuKey + " is OKAY, this input exists");
                        } else {
                            Debug.Control.AudioZone.CustomizationManager && console.warn("CustomizationManager", "filterUnavailableInputs  > " + menuKey + " is NOT OKAY, this input doesnt exist!");
                            keysToRemove.push(menuKey);
                        }
                    }
                });
                keysToRemove.forEach(removeKey => {
                    delete customizationMap[sectionId][removeKey];
                });
                return keysToRemove.length > 0;
            }

            static deleteSingeltonInputSettings(customizationMap) {
                var sectionId = MusicServerEnum.ControlContentMenuId.LINE_IN.split("-")[0];
                Debug.Control.AudioZone.CustomizationManager && console.log("CustomizationManager", "deleteSingeltonInputSettings");
                Debug.Control.AudioZone.CustomizationManager && console.log("CustomizationManager", "    <<delete>> = " + JSON.stringify(customizationMap[sectionId][MusicServerEnum.ControlContentMenuId.LINE_IN]));
                delete customizationMap[sectionId][MusicServerEnum.ControlContentMenuId.LINE_IN];
                Debug.Control.AudioZone.CustomizationManager && console.log("CustomizationManager", "    <<result>> = " + JSON.stringify(customizationMap[sectionId]));
            }

            static isInputCustomizationKey(customizationKey) {
                Debug.Control.AudioZone.CustomizationManager && console.log("CustomizationManager", "isInputCustomizationKey: " + customizationKey);
                return customizationKey.indexOf(MusicServerEnum.ControlContentMenuId.LINE_IN) >= 0;
            }

            static ensureEntryExistsForInput(customizationMap, input, inputDefault) {
                var menuValue = MusicServerEnum.ControlContentMenuId.LINE_IN + "-" + input.audiopath;
                var sectionId = MusicServerEnum.ControlContentSectionId.MENU; // ensure an entry exists for this input.

                if (!customizationMap[sectionId][menuValue]) {
                    customizationMap[sectionId][menuValue] = {
                        rating: !!inputDefault ? inputDefault.rating : this._getDefaultRatingForStartKeyInSectionId(menuValue, sectionId),
                        enabled: !!inputDefault ? inputDefault.enabled : true,
                        key: menuValue,
                        automatic: true
                    };
                    Debug.Control.AudioZone.CustomizationManager && console.log("CustomizationManager", "ensureEntryExistsForInput - input entry created: " + JSON.stringify(customizationMap[sectionId][menuValue]));
                }
            }

            static shared(control) {
                if (!control) {
                    console.warn("No control provided, try to deduce one!");

                    if (window.hasOwnProperty("AudioserverComp") && AudioserverComp.getActiveZoneControl()) {
                        control = AudioserverComp.getActiveZoneControl();
                    } else {
                        throw "No control available!";
                    }
                }

                this.__instances = this.__instances || {};

                if (!this.__instances.hasOwnProperty(control.uuidAction)) {
                    this.__instances[control.uuidAction] = new this(this, control);
                }

                return this.__instances[control.uuidAction];
            }

            static destroy(control) {
                if (control && this.__instances && this.__instances.hasOwnProperty(control.uuidAction)) {
                    this.__instances[control.uuidAction].destroy();

                    delete this.__instances;
                }
            }

            static _getDefaultRatingForStartKeyInSectionId(key, sectionId) {
                var rating = 999;

                if (sectionId === MusicServerEnum.ControlContentSectionId.START) {
                    switch (key) {
                        case MusicServerEnum.ControlContentMenuId.FAVORITES:
                            rating = 0;
                            break;

                        case MusicServerEnum.ControlContentMenuId.HISTORY:
                            rating = 1;
                            break;

                        case MusicServerEnum.ControlContentMenuId.SPOTIFY_PLAYLISTS:
                            rating = 2;
                            break;
                    }
                } else if (sectionId === MusicServerEnum.ControlContentSectionId.MENU) {
                    switch (key) {
                        case MusicServerEnum.ControlContentMenuId.PLAYLISTS:
                            rating = 0;
                            break;

                        case MusicServerEnum.ControlContentMenuId.RADIO:
                            rating = 1;
                            break;

                        case MusicServerEnum.ControlContentMenuId.SPOTIFY:
                            rating = 2;
                            break;

                        case MusicServerEnum.ControlContentMenuId.LIB:
                            rating = 3;
                            break;

                        case MusicServerEnum.ControlContentMenuId.ANNOUNCEMENT:
                            rating = 5;
                            break;

                        case MusicServerEnum.ControlContentMenuId.SOUNDSUIT:
                            rating = 6;
                            break;

                        default:
                            if (key.indexOf(MusicServerEnum.ControlContentMenuId.LINE_IN) >= 0) {
                                // all inputs are rated with 4.
                                rating = 4;
                            }

                            break;
                    }
                }

                return rating;
            } //endregion Static


            constructor(initiator, control) {
                if (!(initiator instanceof Function) && !Controls.AudioZoneV2Control.SingleTones.CustomizationManager) {
                    throw "The CustomizationManager is a Singletone, use it like that! -> CustomizationManager.shared(this.control)";
                }

                this.name = "CustomizationManager";
                this._platform = PlatformComponent.getPlatformInfoObj().platform;
                this._control = control;

                this._boundOnCustomizationSettingsChange = function (key, settings) {
                    this._onCustomizationSettingsChange(settings);
                }.bind(this);

                this.libraryScanRegId = this._control.audioserverComp.registerForScanEvents(this._onLibraryScanEvent.bind(this));
                this._registrations = [];
                PersistenceComponent.registerForSharedKeyChange(MusicServerEnum.CUSTOMIZATION_KEYS.APPEARANCE, this._boundOnCustomizationSettingsChange, true);
            }

            destroy() {
                PersistenceComponent.unregisterFromSharedKeyChange(MusicServerEnum.CUSTOMIZATION_KEYS.APPEARANCE, this._boundOnCustomizationSettingsChange);
                this.libraryScanRegId && this._control.audioserverComp.unregisterFromScanEvents(this.libraryScanRegId);
                this.libraryScanRegId = null;
            }

            registerForChange(boundFn) {
                this._registrations.pushIfNoDuplicate(boundFn);
            }

            unregisterFromChange(boundFn) {
                var idx = this._registrations.indexOf(boundFn);

                if (idx !== -1) {
                    this._registrations.splice(idx, 1);
                }
            }

            notifyListeners() {
                this._registrations.forEach(function (boundFn) {
                    if (typeof boundFn === "function") {
                        if (JSON.stringify(this._customizationSettings[this._control.uuidAction]) !== this._prevSettingsString) {
                            boundFn(this._customizationSettings[this._control.uuidAction]);
                        }
                    }
                }.bind(this));

                this._prevSettingsString = JSON.stringify(this._customizationSettings[this._control.uuidAction]);
            }

            get() {
                if (this._customizationSettings && this._customizationSettings.hasOwnProperty(this._control.uuidAction)) {
                    return Q(cloneObject(this._customizationSettings[this._control.uuidAction])).then(function (customizationMap) {
                        if (this._platform !== PlatformType.IOS && this._platform !== PlatformType.Android && this._platform !== PlatformType.DeveloperInterface) {
                            delete customizationMap[MusicServerEnum.ControlContentSectionId.MENU][MusicServerEnum.ControlContentMenuId.ANNOUNCEMENT];
                        }

                        return this._checkInputsAndFilter(customizationMap);
                    }.bind(this));
                } else {
                    return PersistenceComponent.getShared(MusicServerEnum.CUSTOMIZATION_KEYS.APPEARANCE).then(function (settings) {
                        this._onCustomizationSettingsChange(settings, true); // Only make the request if we are currently connected to the audioserver, or it will result in an unresolvable promise!


                        if (this._control.audioserverComp.connectionReadyDef.promise.inspect().state === "fulfilled") {
                            // Check if the libs are still set automatic, only check if we have library content if the lib is automatically disabled
                            if (this._customizationSettings[this._control.uuidAction][MusicServerEnum.ControlContentSectionId.MENU][MusicServerEnum.ControlContentMenuId.LIB].automatic && this._customizationSettings[this._control.uuidAction][MusicServerEnum.ControlContentSectionId.MENU][MusicServerEnum.ControlContentMenuId.LIB].enabled === false) {
                                // Just probe if at least one mediafolder exists
                                this._control.audioserverComp.sendMediaServerCommand("audio/cfg/getmediafolder/0/0/1").done(function (res) {
                                    if (res.data[0].items.length) {
                                        this.set(MusicServerEnum.ControlContentMenuId.LIB, {
                                            enabled: true,
                                            automatic: false
                                        });
                                    }
                                }.bind(this));
                            }
                        }

                        return Q(cloneObject(this._customizationSettings[this._control.uuidAction])).then(function (customizationMap) {
                            if (this._platform !== PlatformType.IOS && this._platform !== PlatformType.Android && this._platform !== PlatformType.DeveloperInterface) {
                                delete customizationMap[MusicServerEnum.ControlContentSectionId.MENU][MusicServerEnum.ControlContentMenuId.ANNOUNCEMENT];
                            }

                            return this._checkInputsAndFilter(customizationMap);
                        }.bind(this));
                    }.bind(this));
                }
            }

            /**
             * Ensures that the customizationMap doesn't over line-in when there is no line in available.
             * @param customizationMap
             * @returns {*}
             * @private
             */
            _checkInputsAndFilter(customizationMap) {
                Debug.Control.AudioZone.CustomizationManager && console.log("CustomizationManager", "_checkInputsAndFilter: " + JSON.stringify(customizationMap));
                return this._control.audioserverComp.getInputs().promise.then(function (inputs) {
                    // the customizationMap passed into here may have been set up at a time where no inputs have been
                    // loaded yet. Ensure this is being done
                    try {
                        // if exists, retreive the setting for the singelton input that was used before multi line in
                        var inputDefault = this.constructor.getSingeltonInputSettings(customizationMap); // iterate over list of inputs and insert a sepcific customization setting for that input

                        inputs.forEach(function (input) {
                            // pass on the inputDefault that may be present, can be used as blueprint for new inputs.
                            this.constructor.ensureEntryExistsForInput(customizationMap, input, inputDefault);
                        }.bind(this)); // also ensure no "generic" input entry exists (

                        inputDefault && this.constructor.deleteSingeltonInputSettings(customizationMap);
                    } catch (ex) {
                        console.error(this.name, "_checkInputsAndFilter - failed to ensure inputs in settings! " + JSON.stringify(ex));
                    } // ensure inputs that aren't available anymore aren't in this list.


                    this.constructor.filterUnavailableInputs(customizationMap, inputs); // e.g. miniserver compact doesn't have an input, filter out from customizationMap if no inputs are available

                    if (inputs.length === 0) {
                        delete customizationMap[MusicServerEnum.ControlContentSectionId.MENU][MusicServerEnum.ControlContentMenuId.LINE_IN];
                    }

                    Debug.Control.AudioZone.CustomizationManager && console.log("CustomizationManager", "_checkInputsAndFilter >> result = " + JSON.stringify(customizationMap));
                    return customizationMap;
                }.bind(this));
            }

            set(key, deltaConfig) {
                var sectionId = key.split("-")[0],
                    currentSettings = cloneObjectDeep(this._customizationSettings[this._control.uuidAction][sectionId][key]);

                if (!deltaConfig.hasOwnProperty("automatic")) {
                    deltaConfig.automatic = false;
                }

                if (currentSettings) {
                    // The rating did Change, update all following ratings!
                    if (deltaConfig.hasOwnProperty("rating") && deltaConfig.rating !== currentSettings.rating) {
                        var keys = []; // ATTENTION: Object.keys won't return the keys in the definition order!

                        Object.values(this._customizationSettings[this._control.uuidAction][sectionId]).forEach(function (obj) {
                            // since all inputs receive the same rating initially, it must be ensured, that no "overlapping"
                            // ratings are used, otherwise the key of only one input is used here.
                            if (keys[obj.rating]) {
                                do {
                                    obj.rating++;
                                } while (keys[obj.rating]);
                            }

                            keys[obj.rating] = obj.key;
                        }.bind(this));
                        keys.move(currentSettings.rating, deltaConfig.rating).forEach(function (keyToSort, idx) {
                            this._customizationSettings[this._control.uuidAction][sectionId][keyToSort].rating = idx;
                        }.bind(this));
                    }

                    updateFields(currentSettings, deltaConfig);
                    this._customizationSettings[this._control.uuidAction][sectionId][key] = currentSettings;
                    PersistenceComponent.setShared(MusicServerEnum.CUSTOMIZATION_KEYS.APPEARANCE, this._customizationSettings);
                    PersistenceComponent.synchronizeShared();
                    this.notifyListeners();
                }
            }

            _onLibraryScanEvent(source, scanRes) {
                if (!scanRes.isScanning) {
                    // reindexing is finished
                    this._customizationSettings = null; // simply get, this will re-fetch and dispatch change events accordingly

                    this.get();
                }
            }

            _onCustomizationSettingsChange(settings, preventNotify) {
                this._customizationSettings = settings || {};
                var defaults = this.constructor.default;

                if (!this._customizationSettings.hasOwnProperty(this._control.uuidAction)) {
                    this._customizationSettings[this._control.uuidAction] = defaults;
                }

                Debug.Control.AudioZone.CustomizationManager && console.log("CustomizationManager", "_onCustomizationSettingsChange");

                this._control.audioserverComp.getInputs().promise.then(function (inputs) {
                    // if exists, retreive the setting for the singelton input that was used before multi line in
                    var inputDefault = this.constructor.getSingeltonInputSettings(this._customizationSettings[this._control.uuidAction]); // iterate over list of inputs and insert a sepcific customization setting for that input

                    inputs.forEach(function (input) {
                        // pass on the inputDefault that may be present, can be used as blueprint for new inputs.
                        this.constructor.ensureEntryExistsForInput(this._customizationSettings[this._control.uuidAction], input, inputDefault);
                    }.bind(this)); // Add deleted options again, to prevent hiding the Announcement feature after the player has been opened with the Webinterface once

                    Object.keys(this._customizationSettings[this._control.uuidAction]).forEach(function (key) {
                        if (!this._customizationSettings[this._control.uuidAction].hasOwnProperty(key)) {
                            this._customizationSettings[this._control.uuidAction][key] = defaults[key];
                        } else {
                            Object.keys(defaults[key]).forEach(function (keyItemKey) {
                                if (!this._customizationSettings[this._control.uuidAction][key].hasOwnProperty(keyItemKey)) {
                                    this._customizationSettings[this._control.uuidAction][key][keyItemKey] = defaults[key][keyItemKey];
                                }
                            }.bind(this));
                        }
                    }.bind(this)); // also ensure no "generic" input entry exists (

                    inputDefault && this.constructor.deleteSingeltonInputSettings(this._customizationSettings[this._control.uuidAction]); // Groups was part of an alpha and is therefor part of some customization settings
                    // Remove it to not show them in the menu

                    delete this._customizationSettings[this._control.uuidAction][MusicServerEnum.ControlContentMenuId.GROUPS];
                    !preventNotify && this.notifyListeners();
                }.bind(this));
            }

        }

        Controls.AudioZoneV2Control.SingleTones.CustomizationManager = CustomizationManager;
    }

    return Controls.AudioZoneV2Control.SingleTones.CustomizationManager;
});
