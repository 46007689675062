export const sizes = {
    icons: {
        tiny: 16,
        button: 20,
        smaller: 22,
        small: 24,
        medium: 30,
        regular: 32,
        big: 34,
        veryBig: 36,
        bigger: 40,
        weatherIcon: 45,
        veryLarge: 50,
        systemState: 69,
        stateIcon: 80
    },
    smallest: 5,
    smaller: 10,
    small: 15,
    medium: 24,
    mediumBig: 32,
    big: 34,
    bigger: 40,
    biggest: 55,
    cc_more_hd: 60,
    cellH: 76,
    cardH: 180,
    contentMaxWidth: 480,
    textInput: {
        height: 54,
        multiLineHeight: 115,
        leftIconWidth: 24
    },
    button: {
        icon: 20,
        size: 40
    }

}
