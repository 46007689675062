import {LxReactPressable, LxReactTextView} from "LxComponents";
import {useState, useRef, useCallback, useMemo, useEffect} from "react";
import {View} from "react-native";
import globalStyles from "GlobalStyles";
import Icons from "IconLib";

export default function LxReactNumberInput({min= 0, max= 99, value = 1, numberChanged}) {
    const ensureInt = (txtOrInt) => {
        let result;
         if (typeof txtOrInt === "number") {
            result = txtOrInt;
        } else if (typeof txtOrInt === "string") {
                result = parseInt(txtOrInt);
        } else {
             result = min;
         }
        return result;
    }
    const [currentValue, setCurrentValue] = useState(ensureInt(value));
    const intValue = useRef(ensureInt(value));

    useEffect(() => {
        intValue.current = ensureInt(value);
        setCurrentValue(intValue.current);
    }, [value])

    const handleIncrease = useCallback(() => {
        handleValueChanged(intValue.current + 1);
    }, []);
    const handleDecrease = useCallback(() => {
        handleValueChanged(intValue.current - 1);
    }, []);
    const handleValueTextChanged = useCallback((nrText) => {
        if (nrText !== "") {
            let nrValue = parseInt(nrText);
            if (isNaN(nrValue)) {
                HapticFeedback(HapticFeedback.STYLE.ERROR);
            } else {
                handleValueChanged(nrValue, false, true);
            }
        }
    }, []);

    const handleValueChanged = (newVal, hapticFeedback = true, avoidRerender = false) => {
        let hapticStyle;
        const prevValue = intValue.current;
        if (newVal < min) {
            hapticStyle = HapticFeedback.STYLE.ERROR;
            intValue.current = min;
        } else if (newVal > max) {
            hapticStyle = HapticFeedback.STYLE.ERROR;
            intValue.current = max;
        } else {
            hapticStyle = HapticFeedback.STYLE.SELECT;
            intValue.current = Math.max(min, Math.min(newVal, max));
        }
        hapticFeedback && HapticFeedback(hapticStyle);
        !avoidRerender && setCurrentValue(intValue.current);
        if (prevValue !== intValue.current) {
            numberChanged && numberChanged(intValue.current);
        }
    }

    const {minDisabled, maxDisabled} = useMemo(() => {
        const minDisabled = intValue.current <= min,
            maxDisabled = intValue.current >= max;
        return {
            minDisabled,
            maxDisabled
        }
    }, [min, max, intValue.current]);

    const buttonStyles = useMemo(() => {
        return {
            incrButton: {...Styles.squareButton, ...(maxDisabled ? Styles.disabledButton : {})},
            decrButton: {...Styles.squareButton, ...(minDisabled ? Styles.disabledButton : {})},
            decrIcon: {...Styles.buttonIcon, ...(minDisabled ? Styles.disabledIcon : {})},
            incrIcon: {...Styles.buttonIcon, ...(maxDisabled ? Styles.disabledIcon : {})},
        }
    }, [minDisabled, maxDisabled]);

    return <View style={Styles.rootCntr}>
        <LxReactPressable
            pressableStyle={buttonStyles.decrButton}
            pkey={"decr"}
            disabled={intValue.current <= min}
            onPress={handleDecrease}
            onLongPressTick={handleDecrease}
        >
            <Icons.Minus style={buttonStyles.decrIcon}/>
        </LxReactPressable>
        <LxReactTextView
            type={"number"}
            autoComplete={"off"}
            style={Styles.inputBoxStyle}
            containerStyle={Styles.inputStyle}
            textStyle={Styles.inputTextStyle}
            value={"" + intValue.current}
            keepValueUpdated={true}
            textChanged={handleValueTextChanged}
            onSubmitEditing={handleValueTextChanged}
            showClearIcon={false}
            hideRightIcon={true}
            selectTextOnFocus={true}
            alignment={"center"}
            maxLength={2}
            textContentType={"telephoneNumber"}
            validationRegex={Regex.INT_VALUE}
            filterRegex={Regex.INT_VALUE_FILTER}
            filterReplaceChar={""}
        />
        <LxReactPressable
            pressableStyle={buttonStyles.incrButton}
            disabled={intValue.current >= max}
            pkey={"incr"}
            onPress={handleIncrease}
            onLongPressTick={handleIncrease}
        >
            <Icons.Plus style={buttonStyles.incrIcon}/>
        </LxReactPressable>
    </View>
}


const Styles = {
    rootCntr: {
        flexDirection: "row",
        alignContent: "center"
    },
    inputTextStyle: {
        ...globalStyles.textStyles.title3.default,
        color: globalStyles.colors.stateActive,
        marginLeft: "auto",
        marginRight: "auto"
    },
    inputStyle: {
        width: globalStyles.sizes.button.size,
        height: globalStyles.sizes.button.size,
        backgroundColor: globalStyles.colors.fill.tertiary,
        borderRadius: 3
    },
    inputBoxStyle: {
        flex: 1,
        marginLeft: 4,
        marginRight: 4,
    },
    squareButton: {
        width: globalStyles.sizes.button.size,
        height: globalStyles.sizes.button.size,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: globalStyles.colors.fill.tertiary,
        borderRadius: 3
    },
    buttonIcon: {
        fill: globalStyles.colors.text.primary,
        width: globalStyles.sizes.icons.button,
        height: globalStyles.sizes.icons.button,
        margin: "auto"
    },
    disabledButton: {
        backgroundColor: globalStyles.colors.fill.quaternary,
    },
    disabledIcon: {
        fill: globalStyles.colors.text.tertiary
    }
}