'use strict';

define(["ControlInfoView", "AudioZoneV2ControlEnums", "SpotifyAccountManager"], function (ControlInfoView, AudioZoneV2ControlEnums, SpotifyAccountManager) {
    return class SearchInfoView extends ControlInfoView {
        //region Static
        static Template = class {
            //region Static
            static getCustomizationButton() {
                return $("<div class='search-info-view__edit-button-container'>" + "   " + "<div class='edit-button-container__button-placeholder'>" + "       " + ImageBox.getResourceImageWithClasses(Icon.SETTINGS_SMALL, "button-placeholder__icon") + "   " + "</div>" + "</div>");
            } //endregion Static


        }; //endregion Static

        constructor(control, delegate, isInAmbientMode = false) {
            super(control);
            this.delegate = delegate;
            this._isSearching = false;
            this._isInAmbientMode = isInAmbientMode;
            this._setupControlDefer();
        }

        viewDidLoad() {
            var inputCfg = {
                type: GUI.LxInputEnum.Type.SEARCH,
                leftIconSrc: Icon.SEARCH,
                placeholder: _("search.title"),
                style: GUI.LxInputStyle.ROUNDED_SQUARE,
                resetButton: true,
                isDark: true,
                disabled: !HD_APP,
                isInAmbientMode: this._isInAmbientMode
            };
            this.searchInput = new GUI.LxInput(inputCfg, this);
            this.searchInput.onFocus = this.onFocus.bind(this);
            this.searchInput.onBlur = this.onBlur.bind(this);
            this.searchInput.onTextChanged = this.onTextChanged.bind(this);
            this.editButton = new GUI.LxButton(this, SearchInfoView.Template.getCustomizationButton(), Color.BUTTON_GLOW, null, true);

            this.editButton.onButtonTapped = function onButtonTapped() {
                this.delegate.onEditButtonTapped && this.delegate.onEditButtonTapped.call(this.delegate);
            }.bind(this);

            return Q(super.viewDidLoad(...arguments)).then(function () {
                this.appendSubview(this.searchInput);

                if (!(this.ViewController instanceof GUI.AddMediaViewControllerV2Base)) {
                    this.appendSubview(this.editButton);
                } // This button is used to toggle the Search screen on SD Devices


                if (!HD_APP) {
                    this.selfButton = new GUI.LxButton(this, this.searchInput.getElement());
                    this.addToHandledSubviews(this.selfButton);
                    this.selfButton.onButtonTapped = this.onFocus.bind(this);
                }
            }.bind(this));
        }

        onFocus() {
            if (!this._isSearching) {
                Debug.Control.AudioZone.GlobalSearch && console.log(this.name, "onFocus: trigger show search");
                this._isSearching = true;
                this.delegate.ViewController.showState(AudioZoneV2ControlEnums.ScreenState.MEDIA_SEARCH_SCREEN, null, {
                    control: this.control,
                    controlDefer: this._controlDefer
                }).then(function () {
                    Debug.Control.AudioZone.GlobalSearch && console.log(this.name, "onFocus: show search passed - notify again"); // if the text has been entered so fast, that the search screen wasn't ready yet - ensure it's triggered again once shown.

                    this._currentText && this._controlDefer && this._controlDefer.notify({
                        searchTerm: this._currentText
                    });
                }.bind(this));
            }
        }

        onBlur() {
        }

        onTextChanged(value) {
            Debug.Control.AudioZone.GlobalSearch && console.log(this.name, "onTextChanged: " + value);
            this._currentText = value; // store value as it may need to be passed to the searchScreen once it's shown

            this._controlDefer.notify({
                searchTerm: value
            });
        }

        _setupControlDefer() {
            var defer = Q.defer();
            defer.promise.done(function () {
                this._isSearching = false;

                this._setupControlDefer();

                this.searchInput.removeFocus();
            }.bind(this), null, function (data) {
                if (data.hasOwnProperty("historyTerm") && HD_APP) {
                    this.searchInput.setValue(data.historyTerm);
                }
            }.bind(this));
            this._controlDefer = defer;
        }

    };
});
