import globalStyles from "GlobalStyles";

const defaultSettings = {
    MIN: 0,
    MAX: 100,
    STEP: 1,
    VALUE: 0,
}

const defaultColors = {
    SELECTED: globalStyles.colors.white,
    UNSELECTED: globalStyles.colors.greySlider,
    UNSELECTED_VERTICAL: globalStyles.colors.grey["900"],
    GRADIENT_TRACK: globalStyles.colors.transparent,
    SELECTED_DISABLED: globalStyles.colors.grey["800"],
    UNSELECTED_DISABLED: globalStyles.colors.grey["900"],
    THUMB: globalStyles.colors.grey["200"],
    THUMB_DISABLED: globalStyles.colors.grey["800"]
}

const defaultStyles = {
    VERTICAL_WIDTH: 180,
    VERTICAL_HEIGHT: 400
}

const LxSliderDefaultSettings = {
    defaultColors,
    defaultSettings,
    defaultStyles
}

export {
    defaultColors,
    defaultSettings,
    defaultStyles
};

export default LxSliderDefaultSettings;
