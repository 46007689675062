'use strict';

define(["./securitySettingsScreen.jsx"], function (SecuritySettingsScreenReact) {
    return class SecuritySettingsScreen extends GUI.TableViewScreenV2 {
        static ReactComp = SecuritySettingsScreenReact.default;

        constructor() {
            super($('<div class="security-settings-screen" />'));
        }
    };
});
