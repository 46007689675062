'use strict';

define(['CoverViewV2'], function (CoverViewV2) {
    class CurrentCoverViewV2 extends GUI.View {
        constructor(control) {
            super($("<div class='current-cover-view'></div>"));
            applyMixins(this, StateHandler.Mixin);
            this.control = control;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.coverView = new CoverViewV2(this.element, this._getIconObject(cloneObject(this.control.getStates())));
                this.addToHandledSubviews(this.coverView);
            }.bind(this));
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function () {
                this._registerForStates(this.control.uuidAction, ['coverurl']);
            }.bind(this));
        }

        viewWillDisappear() {
            this._unregisterStates(this.control.uuidAction);

            return super.viewWillDisappear(...arguments);
        }

        destroy() {
            this._unregisterStates(this.control.uuidAction);

            return super.destroy(...arguments);
        }

        receivedStates(states) {
            var iconObj = this._getIconObject(cloneObject(states));

            this.coverView.setIconObject(iconObj);
        }

        _getIconObject(item) {
            var ctor = Controls.AudioZoneV2Control.MediaBrowserV2Base.getConstructorForItem(item),
                icon = ctor.getIconObjForItem(item);

            if (item.isPlaying) {
                icon.color = window.Styles.colors.stateActive;
            }

            return icon;
        }

    }

    Controls.AudioZoneV2Control.CurrentCoverViewV2 = CurrentCoverViewV2;
    return Controls.AudioZoneV2Control.CurrentCoverViewV2;
});
