'use strict';
/**
 * Prevents the TableContent for the AudioZoneControlContent
 */

define('AudioZoneTableViewHelper', ["ControlActionCellsScreen", "AudioZoneControlEnums", "AudioZoneSpotifyHelper", "AudioZoneShortcutHelper", "CustomizationHelper"], function (ControlActionCellsScreen, AudioZoneControlEnums, AudioZoneSpotifyHelper, AudioZoneShortcutHelper, CustomizationHelper) {
    return class AudioZoneTableViewHelper {
        constructor(controlContent) {
            Object.assign(this, MediaStateHandler.Mixin);
            this.platform = PlatformComponent.getPlatformInfoObj().platform;
            this.controlContent = controlContent;
            this.spotifyHelper = new AudioZoneSpotifyHelper(this.controlContent, this);

            if (MediaServerComp.isRestricted()) {
                this.__activeCellPath = {
                    section: 0,
                    row: 0
                };
            } else {
                if (MediaServerComp.Feature.V2_FIRMWARE) {
                    this.__activeCellPath = {
                        section: 1,
                        row: 0
                    };
                } else {
                    this.__activeCellPath = {
                        section: 0,
                        row: 0
                    };
                }
            }

            this.customizationHelper = new CustomizationHelper();
        }

        getTableContent() {
            var def = Q.defer(),
                tableContentArr = [],
                states = cloneObject(this.controlContent.states),
                resolveFunction = function resolveFunction(promise) {
                    return Q(promise).then(function (res) {
                        // Why are there two cloneObjectDeeps?
                        // • res is an object so it is call by reference
                        // • The notify just relays the res (section) to the notify function of the promise, there may be some modifications for the specific tableView implementation
                        // • The returned res object is the cached this.currentTableContent which is used to edit the appearance of the AudioZone
                        // • Both objects must be independent from each other, thats why we clone them
                        res && def.notify(cloneObjectDeep(res)); // Don't notify with an empty result

                        return cloneObjectDeep(res);
                    });
                };

            if (MediaServerComp.isRestricted()) {
                if (HD_APP) {
                    tableContentArr.pushObject(resolveFunction(this.getStartSection(this.controlContent.control)));
                } else {
                    tableContentArr.pushObject(resolveFunction(this.getFavoriteSection(this.controlContent.control, states)));

                    if (Feature.SHARED_USER_SETTINGS) {
                        tableContentArr.pushObject(resolveFunction(this.getShortcutSection(this.controlContent.control)));
                    }
                }
            } else {
                if (MediaServerComp.Feature.GLOBAL_SEARCH) {
                    tableContentArr.pushObject(resolveFunction(this._getSearchSection(this.controlContent.control)));
                }

                if (HD_APP) {
                    tableContentArr.pushObject(resolveFunction(this.getStartSection(this.controlContent.control)));
                } else {
                    if (states.zoneFavorites.length) {
                        tableContentArr.pushObject(resolveFunction(this.getFavoriteSection(this.controlContent.control, states)));
                    }

                    if (Feature.SHARED_USER_SETTINGS) {
                        tableContentArr.pushObject(resolveFunction(this.getShortcutSection(this.controlContent.control)));
                    }
                }

                tableContentArr.pushObject(resolveFunction(this._getLoxoneSection(this.controlContent.control)));
                tableContentArr.pushObject(resolveFunction(this._getSpotifySection(def)));
                tableContentArr.pushObject(resolveFunction(this._getTuneInSection(this.controlContent.control)));
                tableContentArr.pushObject(resolveFunction(this._getSettingsSection(this.controlContent.control)));
            }

            Q.all(tableContentArr).then(function (tableContent) {
                if (HD_APP) {
                    try {
                        tableContent[this.__activeCellPath.section].rows[this.__activeCellPath.row].content.active = true;
                    } catch (e) {// do nothing, just catch the issue...
                    }
                }

                tableContent = tableContent.filter(function (section) {
                    // Filter out any non existing or empty sections sections
                    return section && section.rows && section.rows.length;
                });
                tableContent.sort(function (a, b) {
                    return a.rating > b.rating ? 1 : -1;
                });
                this.currentTableContent = tableContent;
                def.resolve(cloneObjectDeep(tableContent));
            }.bind(this));
            return def.promise;
        }

        _getSearchSection(control) {
            return this._getCustomizationSettings().then(function (settings) {
                return {
                    identifier: MediaEnum.ControlContentIdentifiers.SEARCH,
                    rating: settings[MediaEnum.ControlContentIdentifiers.SEARCH],
                    rows: [{
                        content: {
                            clickable: true,
                            title: _('media.global-search-button.title'),
                            leftIconSrc: Icon.SEARCH,
                            class: "general-cell__search-cell"
                        },
                        action: function (cell, section, row, tableView) {
                            this.onCellSelected.apply(this, arguments);
                            this.controlContent.ViewController.showState(AudioZoneControlEnums.ScreenState.GLOBAL_SEARCH_SCREEN, null, {
                                control: control,
                                services: this.services,
                                spotifyHelper: this.spotifyHelper
                            });
                        }.bind(this)
                    }]
                };
            }.bind(this));
        }

        getStartSection(control) {
            if (this.controlContent.ViewController instanceof GUI.AddMediaViewControllerBase) {
                return;
            }

            return this._getCustomizationSettings().then(function (settings) {
                return {
                    identifier: MediaEnum.ControlContentIdentifiers.HOME,
                    rating: settings[MediaEnum.ControlContentIdentifiers.HOME],
                    rows: [{
                        content: {
                            clickable: true,
                            title: _("start"),
                            leftIconSrc: Icon.AudioZone.NEW.NOTE,
                            leftIconColor: window.Styles.colors.green,
                            class: !HD_APP ? "base-cell--with-chevron" : null
                        },
                        action: function (cell, section, row, tableView) {
                            this.onCellSelected.apply(this, arguments);
                            this.showDetailed(AudioZoneControlEnums.ScreenState.HOME_SCREEN, null, {
                                control: control
                            });
                        }.bind(this)
                    }]
                };
            }.bind(this));
        }

        getFavoriteSection(control, states) {
            var row;

            if (this.controlContent.ViewController instanceof GUI.AddMediaViewControllerBase) {
                return;
            }

            return this._getCustomizationSettings().then(function (settings) {
                var section = {
                    identifier: MediaEnum.ControlContentIdentifiers.ZONE_FAVORITES,
                    rating: settings[MediaEnum.ControlContentIdentifiers.ZONE_FAVORITES],
                    headerTitle: _("media.favorites-of-zone", {
                        zone: control.getName()
                    }),
                    sectionRightButtonTitle: MediaServerComp.isRestricted() ? null : _("edit"),
                    rightSectionButtonTapped: function () {
                        this.controlContent.ViewController.showState(AudioZoneControlEnums.ScreenState.FAVORITE_EDIT, null, {
                            control: control
                        }, AnimationType.MODAL);
                    }.bind(this),
                    rows: []
                }; // Fallback for slow slow device (Android 4.4.2 can't handle the card-section)

                if (!HD_APP && !slowDevice) {
                    section.customClass = 'card-section';
                }

                section.rows = states.zoneFavorites.map(function (zoneFav) {
                    row = {
                        type: GUI.TableViewV2.CellType.Special.MEDIA_COVER,
                        content: {
                            iconSrc: Controls.AudioZoneControl.MediaBrowserFavorites.getIconObjForItem(zoneFav).highRes,
                            title: decodeURIComponent(zoneFav.name),
                            subtitle: decodeURIComponent(zoneFav.title || ""),
                            clickable: true,
                            buttonSameFunctionAsBackground: true
                        },
                        action: function () {
                            MediaServerComp.sendCommandToServer(control, Commands.format(Commands.MEDIACLIENT.SOURCE, zoneFav.slot));
                        }.bind(this)
                    }; // Fallback for slow slow device (Android 4.4.2 can't handle the card-section)

                    if (slowDevice) {
                        delete row.type;
                        row.content.leftIconSrc = row.content.iconSrc;
                        delete row.content.iconSrc;
                    }

                    return row;
                }.bind(this));
                return section;
            }.bind(this));
        }

        getShortcutSection(control) {
            if (this.controlContent.ViewController instanceof GUI.AddMediaViewControllerBase) {
                return;
            }

            return this._getCustomizationSettings().then(function (settings) {
                var section = {
                    identifier: MediaEnum.ControlContentIdentifiers.SHORTCUTS,
                    rating: settings[MediaEnum.ControlContentIdentifiers.SHORTCUTS],
                    headerTitle: _("media.shortcuts.title"),
                    sectionRightButtonTitle: MediaServerComp.isRestricted() ? null : _("edit"),
                    rightSectionButtonTapped: function () {
                        this.controlContent.ViewController.showState(AudioZoneControlEnums.ScreenState.SETTINGS_SHORTCUT_EDIT, null, {
                            control: control
                        }, AnimationType.MODAL);
                    }.bind(this),
                    rows: []
                }; // Fallback for slow slow device (Android 4.4.2 can't handle the card-section)

                if (!HD_APP && !slowDevice) {
                    section.customClass = 'card-section';
                }

                return AudioZoneShortcutHelper.getShortcuts().then(function (res) {
                    var row;

                    if (res[this.controlContent.control.uuidAction]) {
                        // Filter out any unsupported shortcuts (LMS feature is not supported or the platform doesn't support the shortcut)
                        res[this.controlContent.control.uuidAction] = res[this.controlContent.control.uuidAction].filter(function (shortcut) {
                            var supportsPlatform = true,
                                lmsSupportsFeature = true,
                                hasRequiredConnection = true;

                            if (shortcut.hasOwnProperty("requiredPlatforms")) {
                                supportsPlatform = shortcut.requiredPlatforms.indexOf(this.platform) !== -1;
                            }

                            if (shortcut.hasOwnProperty("requiredLMSFeatures")) {
                                lmsSupportsFeature = !!shortcut.requiredLMSFeatures.map(function (featureKey) {
                                    return MediaServerComp.Feature[featureKey];
                                }).reduce(function (total, newVal) {
                                    return total && newVal;
                                }, true);
                            }

                            if (shortcut.hasOwnProperty("requiresInternalConnection")) {
                                if (shortcut.requiresInternalConnection) {
                                    hasRequiredConnection = !MediaServerComp.isRestricted();
                                }
                            }

                            return supportsPlatform && lmsSupportsFeature && hasRequiredConnection;
                        }.bind(this));
                        section.rows = res[this.controlContent.control.uuidAction].map(function (shortcut) {
                            row = {
                                type: GUI.TableViewV2.CellType.Special.MEDIA_COVER,
                                content: {
                                    iconSrc: shortcut.coverurl || shortcut.icon || Icon.AudioZone.LIBRARY_ICON,
                                    title: decodeURIComponent(shortcut.name || shortcut.title),
                                    subTitle: decodeURIComponent(shortcut.artist || shortcut.album || ""),
                                    clickable: true,
                                    hidePlayButton: shortcut.type === MediaEnum.FileType.LOCAL_DIR || shortcut.type === MediaEnum.FileType.SCREEN_STATE_SHORTCUT,
                                    buttonSameFunctionAsBackground: !MediaServerComp.isFileTypeBrowsable(shortcut.type),
                                    item: shortcut
                                },
                                action: function () {
                                    if (MediaServerComp.isFileTypeBrowsable(shortcut.type)) {
                                        if (MediaServerComp.Feature.V2_FIRMWARE) {
                                            this.showDetailed(Controls.AudioZoneControl.MediaBrowserBase.getScreenStateForItem(shortcut.lastSelectedItem), null, {
                                                username: shortcut.username,
                                                lastSelectedItem: shortcut.lastSelectedItem,
                                                contentTypes: [shortcut.contentType],
                                                mediaType: shortcut.mediaType,
                                                identifier: shortcut.identifier,
                                                serviceObj: shortcut.serviceObj,
                                                isShortcut: true
                                            });
                                        } else {
                                            this.showDetailed(Controls.AudioZoneControl.MediaBrowserBase.getScreenStateForItem(shortcut.config.lastSelectedItem), null, {
                                                username: shortcut.config.username,
                                                lastSelectedItem: shortcut.config.lastSelectedItem,
                                                contentTypes: [shortcut.contentType],
                                                mediaType: shortcut.config.mediaType,
                                                identifier: shortcut.config.serviceIdentifier,
                                                serviceObj: shortcut.config.serviceObj,
                                                isShortcut: true
                                            });
                                        }
                                    } else if (shortcut.type === MediaEnum.FileType.SCREEN_STATE_SHORTCUT) {
                                        // Simply navigate to the provided screen
                                        this.showDetailed(shortcut.screenState, null, shortcut.details, AnimationType.FADE).fail(function () {
                                            NavigationComp.showPopup({
                                                message: _("x.not-opened", {
                                                    x: shortcut.title
                                                }),
                                                buttonOk: true,
                                                icon: Icon.WARNING,
                                                color: window.Styles.colors.orange
                                            });
                                        }.bind(this));
                                    } else {
                                        MediaServerComp.sendPlayerCommandFromType(shortcut, 0, MediaServerComp.getCellConfigWithServiceItem(shortcut));
                                    }
                                }.bind(this)
                            }; // Fallback for slow slow device (Android 4.4.2 can't handle the card-section)

                            if (slowDevice) {
                                delete row.type;
                                row.content.leftIconSrc = row.content.iconSrc;
                                delete row.content.iconSrc;
                            }

                            return row;
                        }.bind(this));
                    }

                    if (section.rows.length) {
                        return section;
                    } else {
                        // Return an empty section with the identifier to be able to remove the section in the controlContent
                        return {
                            identifier: MediaEnum.ControlContentIdentifiers.SHORTCUTS
                        };
                    }
                }.bind(this));
            }.bind(this));
        }

        _getLoxoneSection(control) {
            if (this.controlContent.ViewController instanceof GUI.AddMediaViewControllerPlaylistSpotify) {
                return;
            }

            return this._getCustomizationSettings().then(function (settings) {
                var section = this._getSectionTemplate(_('loxone'));

                section.identifier = MediaEnum.ControlContentIdentifiers.LOXONE;
                section.rating = settings[MediaEnum.ControlContentIdentifiers.LOXONE];
                section.rows.pushObject(this._createFavoritesCell(control));
                section.rows.pushObject(this._createPlaylistCell(control));
                section.rows.pushObject(this._createLibraryCell(control));
                section.rows.pushObject(this._createLineInSelectorCell(control));
                var hasAllVRFeatures = Controls.AudioZoneControl.VoiceRecorder.requiredLMSFeatures().map(function (featureKey) {
                    return MediaServerComp.Feature[featureKey];
                }).reduce(function (total, newVal) {
                    return total && newVal;
                }, true);

                if (hasAllVRFeatures && Controls.AudioZoneControl.VoiceRecorder.requiredPlatforms().indexOf(this.platform) !== -1) {
                    section.rows.pushObject(this._createVoiceRecorderCell(control));
                }

                if (control.details.clientType !== MediaEnum.ClientType.UPNP) {
                    section.rows.pushObject(this._createZoneGroupCell(control));
                }

                return section;
            }.bind(this));
        }

        _getSettingsSection(control) {
            if (this.controlContent.ViewController instanceof GUI.AddMediaViewControllerBase) {
                return;
            }

            return this._getCustomizationSettings().then(function (settings) {
                return {
                    identifier: MediaEnum.ControlContentIdentifiers.SETTINGS,
                    rating: settings[MediaEnum.ControlContentIdentifiers.SETTINGS],
                    rows: [{
                        content: {
                            clickable: true,
                            title: _("media.preferences"),
                            leftIconSrc: Icon.AudioZone.NEW.GEAR,
                            leftIconColor: window.Styles.colors.green,
                            disclosureIcon: false,
                            class: !HD_APP ? "base-cell--with-chevron" : null
                        },
                        action: function (cell, section, row, tableView) {
                            this.controlContent.ViewController.showState(AudioZoneControlEnums.ScreenState.SETTINGS, null, {
                                control: control,
                                currentTableContent: this.currentTableContent
                            });
                        }.bind(this)
                    }]
                };
            }.bind(this));
        }

        // ---------------------------------
        // Library
        // ---------------------------------
        _createLibraryCell(control) {
            var title = _("media.library"),
                item = {
                    name: title,
                    contentType: MediaEnum.MediaContentType.LIBRARY
                };

            return {
                content: {
                    identifier: MediaEnum.ControlContentIdentifiers.LOXONE_LIB,
                    clickable: true,
                    title: title,
                    leftIconSrc: Icon.AudioZone.NEW.LIBRARY,
                    leftIconColor: window.Styles.colors.green,
                    disclosureIcon: false,
                    class: !HD_APP ? "base-cell--with-chevron" : null
                },
                showLoadingIndicator: this.isLibraryScanning,
                action: function (cell, section, row, tableView) {
                    this.onCellSelected.apply(this, arguments);
                    this.showDetailed(Controls.AudioZoneControl.MediaBrowserBase.getScreenStateForItem(item), null, {
                        username: MediaEnum.NOUSER,
                        lastSelectedItem: item,
                        identifier: MediaEnum.Service.LOCAL,
                        contentTypes: [item.contentType],
                        mediaType: MediaEnum.MediaType.LIBRARY
                    });
                }.bind(this)
            };
        }

        _createFavoritesCell(control) {
            if (this.controlContent.ViewController instanceof GUI.AddMediaViewControllerBase) {
                return null;
            }

            var title = _("media.favorites"),
                icon = Icon.AudioZone.NEW.STAR,
                item = {
                    name: title,
                    contentType: MediaEnum.MediaContentType.FAVORITES
                };

            return {
                content: {
                    identifier: MediaEnum.ControlContentIdentifiers.LOXONE_FAVS,
                    clickable: true,
                    title: title,
                    leftIconSrc: icon,
                    leftIconColor: window.Styles.colors.green,
                    disclosureIcon: false,
                    class: !HD_APP ? "base-cell--with-chevron" : null
                },
                action: function (cell, section, row, tableView) {
                    this.onCellSelected.apply(this, arguments);
                    this.showDetailed(Controls.AudioZoneControl.MediaBrowserBase.getScreenStateForItem(item), null, {
                        username: MediaEnum.NOUSER,
                        lastSelectedItem: {
                            name: title
                        },
                        identifier: MediaEnum.Service.LOCAL,
                        contentTypes: [MediaEnum.MediaContentType.ZONE_FAVORITES, item.contentType],
                        mediaType: MediaEnum.MediaType.FAVORITES,
                        icon: Icon.AudioZone.NEW.STAR
                    });
                }.bind(this)
            };
        }

        _createPlaylistCell(control) {
            if (this.controlContent.ViewController instanceof GUI.AddMediaViewControllerBase) {
                return;
            }

            var title = _("media.playlists"),
                icon = Icon.AudioZone.NEW.PLAYLIST,
                item = {
                    name: title,
                    contentType: MediaEnum.MediaContentType.PLAYLISTS
                };

            return {
                content: {
                    identifier: MediaEnum.ControlContentIdentifiers.LOXONE_PLAYLISTS,
                    clickable: true,
                    title: title,
                    leftIconSrc: icon,
                    leftIconColor: window.Styles.colors.green,
                    disclosureIcon: false,
                    class: !HD_APP ? "base-cell--with-chevron" : null
                },
                action: function (cell, section, row, tableView) {
                    this.onCellSelected.apply(this, arguments);
                    this.showDetailed(Controls.AudioZoneControl.MediaBrowserBase.getScreenStateForItem(item), null, {
                        username: MediaEnum.NOUSER,
                        lastSelectedItem: item,
                        identifier: MediaEnum.Service.LMS,
                        contentTypes: [item.contentType],
                        mediaType: MediaEnum.MediaType.PLAYLIST,
                        icon: icon
                    });
                }.bind(this)
            };
        }

        // ---------------------------------
        // Line In
        // ---------------------------------
        _createLineInSelectorCell(control) {
            if (this.controlContent.ViewController instanceof GUI.AddMediaViewControllerBase) {
                return;
            }

            var icon = Icon.AudioZone.NEW.LINE_IN,
                item = {
                    name: _("media.section.line-in"),
                    contentType: MediaEnum.MediaContentType.LINEIN
                };
            return {
                content: {
                    identifier: MediaEnum.ControlContentIdentifiers.LOXONE_LINE_IN,
                    clickable: true,
                    title: item.name,
                    leftIconSrc: icon,
                    leftIconColor: window.Styles.colors.green,
                    disclosureIcon: false,
                    class: !HD_APP ? "base-cell--with-chevron" : null
                },
                action: function (cell, section, row, tableView) {
                    this.onCellSelected.apply(this, arguments);
                    this.showDetailed(Controls.AudioZoneControl.MediaBrowserBase.getScreenStateForItem(item), null, {
                        lastSelectedItem: item,
                        contentTypes: [item.contentType],
                        mediaType: MediaEnum.MediaType.INPUT,
                        identifier: MediaEnum.MediaContentType.LINEIN,
                        icon: icon
                    });
                }.bind(this)
            };
        }

        // ---------------------------------
        // Voice Recorder
        // ---------------------------------
        _createVoiceRecorderCell(control) {
            if (this.controlContent.ViewController instanceof GUI.AddMediaViewControllerBase) {
                return;
            }

            var icon = Icon.AudioZone.NEW.MIC;
            return {
                content: {
                    identifier: MediaEnum.ControlContentIdentifiers.LOXONE_VOICE_RECORDER,
                    clickable: true,
                    title: _("media.menu.announcement"),
                    leftIconSrc: icon,
                    leftIconColor: window.Styles.colors.green,
                    disclosureIcon: false,
                    class: !HD_APP ? "base-cell--with-chevron" : null
                },
                action: function (cell, section, row, tableView) {
                    this.onCellSelected.apply(this, arguments);
                    this.showDetailed(AudioZoneControlEnums.ScreenState.VOICE_RECORDER, null, {
                        icon: icon
                    });
                }.bind(this)
            };
        }

        // ---------------------------------
        // Zone Grouping
        // ---------------------------------
        _createZoneGroupCell(control) {
            if (this.controlContent.ViewController instanceof GUI.AddMediaViewControllerBase) {
                return;
            } // current zone favorites


            var icon = Icon.AudioZone.GROUPED,
                detail = {
                    control: control,
                    zone: {},
                    icon: icon
                },
                name = _("media.menue.grouped-zones", {
                    zone: control.getName()
                }),
                target = AudioZoneControlEnums.ScreenState.GROUP_OVERVIEW;

            detail.zone[MediaEnum.Event.PLAYER_ID] = control.details.playerid;
            detail.zone[MediaEnum.Event.NAME] = control.getName();
            var groupCell = {
                content: {
                    identifier: MediaEnum.ControlContentIdentifiers.LOXONE_ZONE_GROUPS,
                    clickable: true,
                    title: name,
                    leftIconSrc: icon,
                    leftIconColor: window.Styles.colors.green,
                    disclosureIcon: false,
                    class: !HD_APP ? "base-cell--with-chevron" : null
                },
                action: function (cell, section, row, tableView) {
                    this.onCellSelected.apply(this, arguments);
                    this.showDetailed(target, null, detail);
                }.bind(this)
            };
            groupCell.cellType = GUI.TableViewV2.CellType.GENERAL; // needed, createCell sets wrong tag for color.

            groupCell.leftIconColor = window.Styles.colors.green;
            groupCell.leftIconLarger = true;

            if (this.isSynced) {
                groupCell.rightIconSrc = "resources/Images/Controls/AudioZone/icon-zone-group.svg";
                groupCell.rightIconClass = "group-active-indicator";
                groupCell.rightIconColor = this.syncColor;
            }

            groupCell.selectable = true;
            return groupCell;
        }

        // ++++++++++++++++++++++++++++++++++++++++++++++++
        // ++++++++++++++++++++++++++++++++++++++++++++++++
        // Service Section
        // ++++++++++++++++++++++++++++++++++++++++++++++++
        // ++++++++++++++++++++++++++++++++++++++++++++++++
        _getSpotifySection(defer) {
            return this._getCustomizationSettings().then(function (settings) {
                var section;
                return MediaServerComp.getCurrentServices().promise.then(function (services) {
                    this.services = services;

                    if (services.length) {
                        section = this.spotifyHelper.getLoadingSection();
                        section.rating = settings[MediaEnum.ControlContentIdentifiers.SPOTIFY];
                        defer.notify(cloneObjectDeep(section));
                        return MediaServerComp.getCurrentSpotifyId(true).then(function (spotifyId) {
                            section = this.spotifyHelper.prepareSpotifySection(services, spotifyId);
                            return this.spotifyHelper.getSpotifyContent().then(function (rows) {
                                section.rows = rows;
                                section.rating = settings[MediaEnum.ControlContentIdentifiers.SPOTIFY];
                                return section;
                            }.bind(this));
                        }.bind(this));
                    }
                }.bind(this), function (err) {
                    return Q(null); // resolve with null to not add this section
                });
            }.bind(this));
        }

        // ++++++++++++++++++++++++++++++++++++++++++++++++
        // ++++++++++++++++++++++++++++++++++++++++++++++++
        // Tune In Section
        // ++++++++++++++++++++++++++++++++++++++++++++++++
        // ++++++++++++++++++++++++++++++++++++++++++++++++
        _getTuneInSection(control) {
            if (this.controlContent.ViewController instanceof GUI.AddMediaViewControllerBase && !(this.controlContent.ViewController instanceof GUI.AddMediaViewControllerZoneFavorite)) {
                return;
            }

            return this._getCustomizationSettings().then(function (settings) {
                var section;
                return MediaServerComp.getRadios().promise.then(function (radios) {
                    section = this._getSectionTemplate(_('media.service.webradio'));
                    section.identifier = MediaEnum.ControlContentIdentifiers.TUNE_IN;
                    section.rating = settings[MediaEnum.ControlContentIdentifiers.TUNE_IN];
                    section.rows = radios.map(function (radio) {
                        radio.contentType = MediaEnum.MediaContentType.SERVICE;
                        return this._createTuneInCell(radio, control);
                    }.bind(this));
                    return section;
                }.bind(this));
            }.bind(this));
        }

        onCellSelected(cell, section, row, tableView) {
            var activeCell; // We only want to set cells active on HD (Split Screen)

            if (!HD_APP) {
                return;
            }

            activeCell = tableView.cellForSectionAndRow(this.__activeCellPath.section, this.__activeCellPath.row);
            activeCell.setActive(false);
            this.__activeCellPath.section = section;
            this.__activeCellPath.row = row;
            activeCell = tableView.cellForSectionAndRow(this.__activeCellPath.section, this.__activeCellPath.row);
            activeCell.setActive(true);
        }

        showDetailed(screenState, view, details) {
            if (this.controlContent.ViewController instanceof GUI.AudioZoneControlContentViewController) {
                if (!HD_APP) {
                    return this.controlContent.ViewController.showState(ScreenState.AudioZoneDetailedVC, null, {
                        showStateArgs: arguments
                    }, AnimationType.FADE);
                }
            }

            return this.controlContent.ViewController.showState.apply(this.controlContent.ViewController, arguments);
        }

        // ++++++++++++++++++++++++++++++++++++++++++++++++
        // ++++++++++++++++++++++++++++++++++++++++++++++++
        // ------------------------------------------------
        //     Private Functions
        // ------------------------------------------------
        // ++++++++++++++++++++++++++++++++++++++++++++++++
        // ++++++++++++++++++++++++++++++++++++++++++++++++
        _getSectionTemplate(title, rightBtnTitle) {
            var sectionTemplate = {
                headerTitle: title,
                rows: []
            };

            if (rightBtnTitle) {
                sectionTemplate.sectionRightButtonTitle = rightBtnTitle;
                sectionTemplate.sectionRightButtonColor = window.Styles.colors.brand;
            }

            return sectionTemplate;
        }

        _createTuneInCell(radioObj, control) {
            var iconObj = Controls.AudioZoneControl.MediaBrowserBase.getConstructorForItem(radioObj).getIconObjForItem(radioObj, radioObj.cmd),
                item = {
                    name: radioObj.name,
                    contentType: radioObj.contentType
                };
            return {
                content: {
                    clickable: true,
                    title: radioObj.name,
                    leftIconSrc: iconObj.highRes,
                    leftIconColor: iconObj.color,
                    disclosureIcon: false,
                    class: !HD_APP ? "base-cell--with-chevron" : null
                },
                action: function (cell, section, row, tableView) {
                    this.onCellSelected.apply(this, arguments);
                    this.showDetailed(Controls.AudioZoneControl.MediaBrowserBase.getScreenStateForItem(item), null, {
                        username: MediaEnum.NOUSER,
                        lastSelectedItem: radioObj,
                        identifier: radioObj.cmd,
                        contentTypes: [item.contentType],
                        mediaType: MediaEnum.MediaType.SERVICE,
                        serviceObj: radioObj,
                        icon: iconObj.highRes
                    });
                }.bind(this)
            };
        }

        _getCustomizationSettings() {
            return this.customizationHelper.getSavedSettings(this.controlContent.control.uuidAction);
        }

    };
});
