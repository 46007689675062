import {LxReactHeaderIconSize, LxReactHeader, LxReactTextView, LxReactButton, LxReactText } from "LxComponents";
import {useCallback, useState} from "react";
import globalStyles from "GlobalStyles";
import {View} from "react-native";
import Icons from "IconLib";
/**
 * Used when logging into a miniserver based on an url, ip or serialnumber
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function EnterUrlScreen(props) {

    let [enteredIpOrUrl, setEnteredIpOrUrl] = useState("");

    const textChanged = useCallback((text) => {
        setEnteredIpOrUrl(text);
    }, [])

    const onSubmitEditing = (text) => {
        setEnteredIpOrUrl(text);
        onButtonPressed();
    }

    const onButtonPressed = () => {
        if (!!enteredIpOrUrl) {
            NavigationComp.connectTo({
                url: removeProtocol(enteredIpOrUrl)
            });
        }
    }

    return <View style={styles.rootCntr} >
        <LxReactHeader
            icon={Icons.Search}
            iconSize={LxReactHeaderIconSize.Normal}
            iconContainerStyle={styles.headerIconContainer}
            title={_("miniserverlist.add.manual")}
            />
        <LxReactTextView
            style={[styles.contentItem, styles.input]}
            textStyle={styles.inputText}
            pkey={"url-input"}
            value={enteredIpOrUrl}
            placeholder={_("miniserverlist.add.manual.placeholder")}
            leftIcon={Icons.Location}
            leftIconStyle={{width: 19}}
            autoFocus={true}
            alignment={"left"}
            textChanged={textChanged}
            onSubmitEditing={onSubmitEditing}
            />
        <View style={[globalStyles.customStyles.separator, styles.separator]} />
        <LxReactButton
            text={_('connect')}
            pkey={"url-connect-button"}
            containerStyle={[styles.contentItem, styles.submitButton]}
            disabled={!enteredIpOrUrl}
            onPress={onButtonPressed}
            />
        <View style={styles.footerContainer}>
        <LxReactText style={styles.footerText}>
            {_("miniserverlist.add.manual.description")}
        </LxReactText>
        </View>
    </View>;
};

const styles = {
    rootCntr: {
        ...globalStyles.customStyles.screenContent
    },
    contentItem: {
    },
    input: {
        marginTop: globalStyles.spacings.gaps.medium,
        paddingHorizontal: globalStyles.sizes.bigger
    },
    inputText: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.primary
    },
    inputPlaceholder: {
        fontSize: globalStyles.fontSettings.sizes.medium
    },
    separator: {
        width: "auto",
        marginLeft: globalStyles.sizes.bigger,
        marginRight: globalStyles.sizes.bigger,
    },
    submitButton: {
        marginHorizontal: globalStyles.sizes.bigger,
        marginTop: globalStyles.sizes.medium,
        fill: globalStyles.colors.brand,
        textAlign: "center"
    },
    footerContainer: {
        marginTop: globalStyles.sizes.small,
        marginLeft: globalStyles.sizes.bigger,
        marginRight: globalStyles.sizes.bigger
    },
    footerText: {
        ...globalStyles.textStyles.footNote.default,
        color: globalStyles.colors.text.secondary,
    },
    headerIconContainer: {
        marginTop: globalStyles.spacings.gaps.veryBig
    }
}


