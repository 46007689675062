import * as React from "react"
import Svg, { Path } from "react-native-svg"

function QrScan(props) {
    return (
        <Svg
            isreact="true"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M2 5.25A3.25 3.25 0 015.25 2h2a.75.75 0 010 1.5h-2A1.75 1.75 0 003.5 5.25v2a.75.75 0 01-1.5 0v-2zm14-2.5a.75.75 0 01.75-.75h2A3.25 3.25 0 0122 5.25v2a.75.75 0 01-1.5 0v-2a1.75 1.75 0 00-1.75-1.75h-2a.75.75 0 01-.75-.75zM2.75 16a.75.75 0 01.75.75v2c0 .966.784 1.75 1.75 1.75h2a.75.75 0 010 1.5h-2A3.25 3.25 0 012 18.75v-2a.75.75 0 01.75-.75zm18.5 0a.75.75 0 01.75.75v2A3.25 3.25 0 0118.75 22h-2a.75.75 0 010-1.5h2a1.75 1.75 0 001.75-1.75v-2a.75.75 0 01.75-.75zM10 7H7v3h3v4H7v3h3v-3h4v3h3v-3h-3v-4h3V7h-3v3h-4V7z"
            />
        </Svg>
    )
}

export default QrScan
