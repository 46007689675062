'use strict';

define("IntercomVideoProvider", [], function () {
    Controls.Intercom = Controls.Intercom || {};
    Controls.Intercom.SingleTones = Controls.Intercom.SingleTones || {};

    if (Controls.Intercom.SingleTones.IntercomVideoProvider) {
        return Controls.Intercom.SingleTones.IntercomVideoProvider;
    } else {
        var INVALIDATOR_URL = "";

        class IntercomVideoProvider {
            //region Static
            static shared() {
                if (!this.__instance) {
                    this.__instance = new this(this);
                }

                return this.__instance;
            }

            static destroy() {
                this.__instance && this.__instance.destroy();
                delete this.__instance;
            } //endregion Static


            //region Private
            //fast-class-es6-converter: extracted from defineStatic({}) content
            ERROR = {
                NO_VIDEO: 0,
                INVALIDE_STREAM: 1,
                NETWORK: 2,
                PLUGIN: 3
            }; //endregion Private

            /**
             * This class handles every stream
             * MJPG behaviour:
             *  Check if control and streaming infos are valid
             *  Adoptes stream url
             *  passes adopted stream url as parameter in onStreamUrlReceived function
             * JPG behaviour:
             *  Check if control and streaming infos are valid
             *  Adopts stream url
             *  Increment cachebuster at every image fetch
             *  Fetches image
             *  Passes data url as parameter in onStreamUrlReceived function
             * @param delegate
             * @param control
             */
            constructor() {
                this.name = "IntercomVideoProvider";
                this.hasPlugins = window.hasOwnProperty("videoProxy");
                this._fetchCORSBlockingImgUrlTimeout = null;
                this._fetchImgUrlTimeout = null;
            }

            /**
             * Starts the image fetching
             */
            startRequestStreamUrl(delegate, control, isRetry) {
                Debug.Control.Intercom.Video && console.log(this.name, "startRequestStreamUrl");
                if (this.control && control.uuidAction !== this.control.uuidAction) {
                    this.stopRequestStreamUrl(this.control);

                    this._resetSessionVars(delegate, control);
                } else if (isRetry || this.isRunning) {
                    this.stopRequestStreamUrl(this.control);
                } else {
                    this._resetSessionVars(delegate, control);
                }

                Debug.Control.Intercom.Video && console.log(this.name, "   start requesting stream url! Control --> " + this.control.name);

                if (this.control.hasVideoInfo()) {
                    if (this.streamIsValid) {
                        this.isRunning = true;
                        var videoInfo = cloneObject(this.control.details.videoInfo);
                        videoInfo.streamUrl = this._adoptUrl(videoInfo, videoInfo.streamUrl); // Our plugins do handle authentication challenges on their own, no need to prepend the credentials

                        if (!this.hasPlugins) {
                            videoInfo.streamUrl = this._prependAuthentication(videoInfo.streamUrl, videoInfo);
                        }

                        if (this.hasPlugins) {
                            this._fetchPluginStream(videoInfo);
                        } else if (this.isJpg || this.isMjpg) {
                            this._fetchWebStream(videoInfo);
                        } else {
                            this._evaluateImageType(videoInfo).then(() => {
                                // Only continue if the request has not been stopped yet
                                if (!this.stopped) {
                                    this.startRequestStreamUrl(delegate, control);
                                }
                            },  (errCode) => {
                                // Only continue if the request has not been stopped yet
                                if (!this.stopped) {
                                    if (this.CORS_ERROR) {
                                        // Start the stream url, we just pass back the stream url, everything will be fine
                                        // if the url is added as the src url of an img tag
                                        this.startRequestStreamUrl(delegate, control);
                                    } else {
                                        if (typeof errCode === "number") {
                                            switch (errCode) {
                                                case 0:
                                                    this._dispatchOnStreamUrlError("NoResponse");
                                                    break;
                                                case 401:
                                                    this._dispatchOnStreamUrlError("HttpError", errCode);

                                                    break;
                                                default:
                                                    this._dispatchOnStreamUrlError("StreamError");
                                                    break;
                                            }
                                        }
                                    }
                                }
                            });
                        }
                    } else {
                        this._dispatchOnStreamUrlError("UnknownHost");

                        console.info(this.name + ": Stream URl is not valid!");
                    }
                } else {
                    this._dispatchOnStreamUrlError("Invalid Stream URL");
                }
            }

            /**
             * Pauses the image fetching
             * It will dispatch an additional invalidator URL if the stream is of type MJPG
             * to prevent any further image fetching of the image tag
             */
            pauseRequestStreamUrl(control) {
                if (control.uuidAction === this.control.uuidAction) {
                    this.isRunning = false;
                    Debug.Control.Intercom.Video && console.info(this.name, "pauseRequestStreamUrl: " + this.control.name);

                    if (this.hasPlugins) {
                        videoProxy.stopStream();
                    } // Dispatch an incorrect image url to remove the MJPG stream url from the DOM


                    if (this.isMjpg && !this.hasPlugins) {
                        this._dispatchOnStreamUrlReceived(INVALIDATOR_URL);
                    }
                } else {
                    console.warn(this.name, "other intercom control wanted to pause the video stream");
                }
            }

            /**
             * Stops the image fetching, will force the image tag invalidation
             */
            stopRequestStreamUrl(control) {
                Debug.Control.Intercom.Video && console.log(this.name, "stopRequestStreamUrl: " + control.name);
                this._fetchImgUrlRequest && this._fetchImgUrlRequest.abort();
                this._evaluateImageTypeRequest && this._evaluateImageTypeRequest.abort();
                this._fetchCORSBlockingImgUrlTimeout && clearTimeout(this._fetchCORSBlockingImgUrlTimeout);
                this._fetchImgUrlTimeout && clearTimeout(this._fetchImgUrlTimeout);
                this._fetchCORSBlockingImgUrlTimeout = null;
                this._fetchImgUrlTimeout = null;

                if (control.uuidAction === this.control.uuidAction) {
                    this.isRunning = false;
                    Debug.Control.Intercom.Video && console.log(this.name, "    requesting stream url stopped");
                    this.stopped = true;

                    if (this.hasPlugins) {
                        videoProxy.stopStream();
                    }

                    this._dispatchOnStreamUrlReceived(INVALIDATOR_URL);

                    this._dispatchOnStreamStopped();
                } else {
                    console.warn(this.name, "    other intercom control wanted to stop the video stream");
                }
            }

            /**
             * Dispatches the stream url
             */
            _dispatchOnStreamUrlReceived(streamUrl) {
                Debug.Control.Intercom.Video && console.log(this.name, "_dispatchOnStreamUrlReceived: '" + streamUrl + "', initial=" + !!this.isInitialUrl);
                this.delegate && this.delegate.onStreamUrlReceived.call(this.delegate, streamUrl, this.isInitialUrl);

                if (streamUrl && streamUrl.length) {
                    this.isInitialUrl = false;
                }
            }

            /**
             * Dispatches an error
             * @param errorId Error enum (this.Error)
             * @param errorCode Detailed information about this error
             * @param errorText
             * @private
             */
            _dispatchOnStreamUrlError(errorId, errorCode, errorText) {
                Debug.Control.Intercom.Video && console.log(this.name, "_dispatchOnStreamUrlError ID: " + errorId + " ErrorCode: " + errorCode);

                this.delegate && this.delegate.onStreamUrlError.call(this.delegate, {
                    errorId: errorId,
                    errorCode: errorCode,
                    errorText: errorText
                });
                this.stopped = true;
            }

            /**
             * Dispatches on stream stopped
             * @private
             */
            _dispatchOnStreamStopped() {
                Debug.Control.Intercom.Video && console.log(this.name, "_dispatchOnStreamStopped");
                this.delegate && this.delegate.onStreamStopped.call(this.delegate);
            }

            /**
             * Fetches the stream url with the native videoProxy plugin
             * @param videoInfo
             * @private
             */
            _fetchPluginStream(videoInfo) {
                var pluginUrl = videoInfo.streamUrl,
                    pass = videoInfo.pass,
                    user = videoInfo.user;

                if (this.control.isIntercom()) {
                    pluginUrl = this._appendLxIntercomAuth(videoInfo.streamUrl, user, pass);

                    if (this.control.hasSecuredVideoModule()) {
                        user = ""; // reset credentials, they are now contained in the URL as hash.

                        pass = "";
                    }
                }

                videoProxy.startStream(function (res) {
                    if (typeof this._lastBlobURL === "string") {
                        URL.revokeObjectURL(this._lastBlobURL);
                        this._lastBlobURL = null;
                    }

                    if (typeof res === "string") {
                        var blob = new Blob([base64StringToArrayBuffer(res)], {
                            type: "image/jpeg"
                        });
                        this._lastBlobURL = URL.createObjectURL(blob);

                        this._dispatchOnStreamUrlReceived(this._lastBlobURL);

                        blob = null;
                        videoProxy.imageProcessed();
                    }
                }.bind(this), function (err) {
                    let tmpError = {};

                    Debug.Control.Intercom.Video && console.log(this.name, "PluginError: " + JSON.stringify(err));
                    try {
                        tmpError = JSON.parse(err);
                    } catch (e) {

                    }
                    this._dispatchOnStreamUrlError(tmpError.errorId, tmpError.errorCode, tmpError.errorText);
                }.bind(this), pluginUrl, user, pass, this.isMjpg, this.control.details.videoInfo.streamUrl);
            }

            /**
             * Fetches the stream url for all platforms without the videoProxy plugin
             * @param videoInfo
             * @private
             */
            _fetchWebStream(videoInfo) {
                Debug.Control.Intercom.Video && console.log(this.name, "_fetchWebStream: " + videoInfo?.streamUrl);
                if (this.isMjpg) {
                    this._dispatchOnStreamUrlReceived(videoInfo.streamUrl);
                } else if (this.isJpg) {
                    var refreshUrl = cloneObject(videoInfo.streamUrl); // when reloading JPGs, a cache buster is very useful

                    if (refreshUrl.indexOf("?") > -1) {
                        refreshUrl += "&";
                    } else {
                        refreshUrl += "?";
                    }

                    refreshUrl += "refreshCnt=" + this.refreshCntr++; // CORS forbids us to load the image via XHR, use a workaround and load it via the img tag,
                    // then dispatch the img url

                    if (this.CORS_ERROR) {
                        this._fetchCORSBlockedImgUrl(videoInfo, refreshUrl);
                    } else {
                        this._fetchImgUrl(videoInfo, refreshUrl);
                    }
                } else {
                    this.isJpg = true;

                    this._fetchWebStream(videoInfo);
                }
            }

            _fetchCORSBlockedImgUrl(videoInfo, url) {
                Debug.Control.Intercom.Video && console.log(this.name, "_fetchCORSBlockedImgUrl: " + videoInfo?.streamUrl + ", url=" + url);
                var img = new Image(),
                    originalUuidAction = this.control.uuidAction;

                img.onload = function () {
                    // Check if we opened another control in the meantime the Image has been loaded
                    // We must not dispatch the Image in this case!
                    if (originalUuidAction !== this.control.uuidAction) {
                        return false;
                    }

                    this._dispatchOnStreamUrlReceived(url);

                    this._fetchCORSBlockingImgUrlTimeout = setTimeout(function () {
                        if(!this.isRunning && this.stopped) {
                            clearTimeout(this._fetchCORSBlockingImgUrlTimeout);
                            return this.stopRequestStreamUrl(this.control);
                        }
                        Debug.Control.Intercom.Video && console.log(this.name, "_fetchCORSBlockedImgUrl timeout triggered!");
                        this._fetchWebStream(videoInfo);
                    }.bind(this), 200);
                }.bind(this);

                img.onerror = function () {
                    Debug.Control.Intercom.Video && console.log(this.name, "_fetchCORSBlockedImgUrl failed!");
                    this._dispatchOnStreamUrlError("HttpError");
                }.bind(this);
                img.src = url;
            }

            _fetchImgUrl(videoInfo, url) {
                // Fetch the image in JS to prevent Chrome from blocking the url if the image requires basic Authentication
                this._fetchImgUrlRequest = this._createCORSRequest(url, videoInfo.user, videoInfo.pass);
                this._fetchImgUrlRequest.responseType = "arraybuffer";

                this._fetchImgUrlRequest.onload = function () {
                    var blob = new Blob([this._fetchImgUrlRequest.response], {
                        type: "image/jpeg"
                    });
                    this._lastBlobURL = URL.createObjectURL(blob);

                    this._dispatchOnStreamUrlReceived(this._lastBlobURL);

                    this._fetchImgUrlTimeout = setTimeout(function () {
                        if(!this.isRunning && this.stopped) {
                            clearTimeout(this._fetchImgUrlTimeout);
                            return this.stopRequestStreamUrl(this.control);
                        }
                        this._fetchWebStream(videoInfo);
                    }.bind(this), 200);
                }.bind(this);

                this._fetchImgUrlRequest.onerror = function () {
                    this._dispatchOnStreamUrlError("HttpError", this._fetchImgUrlRequest.statusCode);
                }.bind(this);

                try {
                    this._fetchImgUrlRequest.send();
                } catch (e) {
                    this._dispatchOnStreamUrlError("Refused");
                }
            }

            _adoptUrl(videoInfo, urlToAdopt) {
                var streamUrl = urlToAdopt,
                    sslPort = videoInfo.proxyPort ? parseInt(videoInfo.proxyPort) : 0,
                    // This is the SSL Proxyport, 0 means no SSL proxy is available
                    urlObj = document.createElement('a'),
                    requestProtocol = CommunicationComponent.getRequestProtocol(),
                    isSSL = sslPort && requestProtocol === 'https://',
                    isRemoteConnectUrl = streamUrl.indexOf("remoteConnect") > -1,
                    isCloudDnsUrl = streamUrl.indexOf("clouddns") > -1,
                    currentConnectionType = CommunicationComponent.getCurrentConnectionType(),
                    resolvedUrlComponents,
                    remoteConnectPathNameStart;
                urlObj.href = streamUrl;

                if (isSSL) {
                    urlObj.port = sslPort; // Edge can't handle a protocol with // at the end, so we remove it

                    urlObj.protocol = requestProtocol.replace("//", "");
                }

                Debug.Control.Intercom.Video && console.log(this.name, "_adoptUrl before: " + streamUrl);

                if (isRemoteConnectUrl || currentConnectionType === CONNECTION_TYPE.REMOTE_CONNECT && isCloudDnsUrl || // Also make the adoptions it our current request protocol is HTTPS and the cameras URL is not
                    // as no Browser allows a Security Downgrade, we will fallback to our proxy implementation
                    location.protocol === "https:" && !streamUrl.startsWith("https://")) {
                    resolvedUrlComponents = getUrlComponents(ActiveMSComponent.getMiniserverConnectionUrl());

                    if (resolvedUrlComponents) {
                        // This is the path of the intercom proxy
                        remoteConnectPathNameStart = "/proxy/" + this.control.details.videoInfo.deviceUuid; // NOTE: starts with a "/" per definition

                        urlObj.hostname = resolvedUrlComponents.hostname;
                        urlObj.port = resolvedUrlComponents.port; // Only modify the pathname if it isn't already modified
                        // This may occur if one is connected via the Remote-Connect service but defined the clouddns URL for the intercom
                        // E.g: Miniserver is connected via RemoteConnect service, but Intercom is still set to clouddns
                        // • The streamUrl defined in the secured details looks like
                        //    • "http://clouddns:8891/cgi-bin/image.jpg?size=480x320?quality=60"
                        // • "http://clouddns:8891" is replaced with the remoteConnector URL
                        // • The Miniserver Gen. 2 provides a proxy service for intercom devices
                        //    • This proxy service is available under "/proxy/{intercomDeviceUuid}" as a Webservice
                        // • That's why we need to insert "/proxy/{intercomDeviceUuid}" between "/cgi-bin/image.jpg?size..." to use the proxy for the intercom

                        if (this.control.details.videoInfo.deviceUuid && !urlObj.pathname.startsWith(remoteConnectPathNameStart)) {
                            // By appending the pathname we ensure to not modify and query or hash parameters of the original url
                            urlObj.pathname = remoteConnectPathNameStart + urlObj.pathname;
                        } // When using the Remote-Connect service we have to communicate with HTTPS per default!
                        // Edge can't handle a protocol with // at the end, so we remove it


                        urlObj.protocol = "https";
                    }
                } else if (isCloudDnsUrl) {
                    resolvedUrlComponents = getUrlComponents(CommunicationComponent.getResolvedCloudDnsIp());

                    if (resolvedUrlComponents) {
                        urlObj.hostname = resolvedUrlComponents.hostname;
                    }
                } else if (isSSL) {
                    // Make sure the hostname doesn't include the Port!
                    // It will corrupt the URL on Android by adding a second port to the end of the URL
                    urlObj.hostname = getUrlComponents(ActiveMSComponent.getCurrentUrl()).hostname;
                }

                Debug.Control.Intercom.Video && console.log(this.name, "_adoptUrl after: " + urlObj.href);
                return urlObj.href;
            }

            _prependAuthentication(url, videoInfo) {
                Debug.Control.Intercom.Video && console.log(this.name, "_prependAuthentication: to " + url + ", usr=" + videoInfo.user + ", pw=" + videoInfo.pass);
                var user = videoInfo.user,
                    pw = videoInfo.pass,
                    isIntercom = this.control.isIntercom(),
                    urlObj = document.createElement('a'),
                    protocol;

                if (isIntercom && user && user !== "" && pw && pw !== "") {
                    url = this._appendLxIntercomAuth(url, user, pw);
                }

                urlObj.href = url; // Get the protocol (http: or https:) of the URL
                // the protocol is later used to add the basic authentication to the URL

                protocol = urlObj.protocol + "//";
                user = encodeURIComponent(user);
                pw = encodeURIComponent(pw); // ATTENTION:
                // This will not work in Chromes responsive mode!

                if (!isChrome) {
                    if (nullEmptyString(user) && nullEmptyString(pw) && url.indexOf(user >= 0) && url.indexOf(pw >= 0)) {
                        // already appended!
                    } else if (user && user !== "" && pw && pw !== "") {
                        // Add user and password after the protocol to tell the browser to use basic authentication
                        url = url.replace(protocol, protocol + user + ":" + pw + "@");
                    } else if (user && user !== "") {
                        url = url.replace(protocol, protocol + user + "@");
                    }
                } // Set the new URL with authentication


                urlObj.href = url;
                Debug.Control.Intercom.Video && console.log(this.name, "   >> results in: " + urlObj.href);
                return urlObj.href;
            }

            /**
             * Appends an md5 hash containing the login data as well as the random seed used to genearate it.
             * Modern Intercoms will accept MD5 authentication instead of the old one.
             * @param url           the current URL
             * @param user          the user
             * @param pw            the password
             * @returns {*}
             * @private
             */
            _appendLxIntercomAuth(url, user, pw) {
                var fullUrl = url;

                if (this.control.hasSecuredVideoModule()) {
                    // on secured intercoms, remove the (Basic-Authentication)-Login, only transmit the hash.
                    fullUrl = url.substring(0, url.indexOf('?login='));
                }

                var seed = VendorHub.Crypto.createSeed(31);
                var HA1 = VendorHub.Crypto.md5Hex(user).toUpperCase();
                var HA2 = VendorHub.Crypto.md5Hex(pw).toUpperCase();
                var auth = VendorHub.Crypto.md5Hex(HA1 + ":" + seed + ":" + HA2).toUpperCase();

                if (fullUrl.indexOf("?") > -1) {
                    fullUrl += "&"; // there are already some arguments
                } else {
                    fullUrl += "?"; // start a new argument list.
                }

                fullUrl += "auth=" + encodeURI(auth);
                fullUrl += "&seed=" + encodeURI(seed);
                return fullUrl;
            }

            _evaluateImageType(vi) {
                var def = Q.defer(); // when using cgi or another path it's unclear what kind of stream is used. So try it and test

                Debug.Control.Intercom.Video && console.log(this.name, "_evaluateImageType (jpg or mjpg): " + vi.streamUrl);
                var videoInfo = JSON.parse(JSON.stringify(vi));
                Debug.Control.Intercom.Video && console.log(this.name, "   --> after cloning = " + videoInfo.streamUrl);
                videoInfo.streamUrl = this._adoptUrl(videoInfo, videoInfo.streamUrl);
                this._evaluateImageTypeRequest = this._createCORSRequest(videoInfo.streamUrl, videoInfo.user, videoInfo.pass);

                var rqProcessed = false;
                this._evaluateImageTypeRequest.onreadystatechange = () => {
                    Debug.Control.Intercom.Video && console.log(this.name, "_evaluateImageType > onreadystatechange: " + this._evaluateImageTypeRequest.readyState);
                    if (!rqProcessed &&
                        (this._evaluateImageTypeRequest.readyState === 3 ||
                        this._evaluateImageTypeRequest.readyState === 4)) {
                        // don't force waiting for readyState 4 = done, streams may stay in loading state for quite some
                        // time, where the headers are already present & can be checked.
                        rqProcessed = true;
                        this._handleReadyStateChange(this._evaluateImageTypeRequest, videoInfo).then(() => {
                            Debug.Control.Intercom.Video && console.log(this.name, "_evaluateImageType > resolved: " + (this.isJpg ? "jpg" : (this.isMjpg ? "mjpg" : "-unknown-")));
                            def.resolve();
                        }, (errCode) => {
                            Debug.Control.Intercom.Video && console.log(this.name, "_evaluateImageType > rejected!: " + JSON.stringify(errCode));
                            def.reject(errCode);
                        });
                    }
                };

                try {
                    this._evaluateImageTypeRequest.send();
                } catch (e) {
                    console.error(this.name, "_evaluateImageType: failed (" + JSON.stringify(e) + ")! try detecting it based on the streamUrl");
                    def.resolve(this._evaluateImageTypeByPath(videoInfo.streamUrl));
                }

                return def.promise;
            }

            _handleReadyStateChange(xhr, videoInfo) {
                Debug.Control.Intercom.Video && console.log(this.name, "_handleReadyStateChange: " + xhr.readyState);
                var def = Q.defer();

                if (xhr.status <= 300 && xhr.status > 0) {
                    xhr.onreadystatechange = null;
                    Debug.Control.Intercom.Video && console.log(this.name, "   xhr.status " + xhr.status);
                    Debug.Control.Intercom.Video && console.log(this.name, "   xhr.header: " + xhr.getAllResponseHeaders());
                    var responseHeaders = xhr.getAllResponseHeaders();

                    if (responseHeaders.indexOf("image/jpeg") > -1 || responseHeaders.indexOf("image/jpg") > -1) {
                        Debug.Control.Intercom.Video && console.log(this.name, "      this stream  (" + videoInfo.streamUrl + ") will return jpg, start accordingly");
                        this.isJpg = true;
                        this.isMjpg = false;
                    } else if (responseHeaders.indexOf("multipart/x-mixed-replace") > -1) {
                        Debug.Control.Intercom.Video && console.log(this.name, "      this stream (" + videoInfo.streamUrl + ") will return mjpg");
                        this.isJpg = false;
                        this.isMjpg = true;
                    }

                    xhr.abort();
                    def.resolve();
                } else {
                    // We run into the CORS error!
                    // evaluate the image type with the path and set the CORS error flag for further actions
                    if (xhr.status === 0) {
                        console.error(this.name, "_handleReadyStateChange -> xhr.status is 0, must be a CORS error, just return the url!");
                        this.CORS_ERROR = true;

                        def.resolve(this._evaluateImageTypeByPath(videoInfo.streamUrl));
                    } else {
                        console.error(this.name, "_handleReadyStateChange -> xhr.status is " + xhr.status + ", reject!");
                        // all other responses indicate a problem.
                        def.reject(xhr.status);
                    }
                }

                return def.promise;
            }

            _evaluateImageTypeByPath(streamUrl) {
                // Store whether it's a jpg or mjpeg video-stream
                this.isJpg = streamUrl.indexOf(".jpg") !== -1 || streamUrl.indexOf(".jpeg") !== -1;
                this.isMjpg = streamUrl.indexOf(".mjpg") !== -1 || streamUrl.indexOf(".mjpeg") !== -1;

                if (!this.isMjpg && !this.isJpg && streamUrl.indexOf(".cgi") !== -1) {
                    this.isMjpg = true;
                    console.error(this.name, "_evaluateImageTypeByPath: neither .jpg nor mjpg, but .cgi, assume it's mjpg!");
                }
                if (!this.isMjpg && !this.isJpg) {
                    this.isJpg = true;
                    console.error(this.name, "_evaluateImageTypeByPath: Stream Type could not be determined, assume it's JPG");
                }

                Debug.Control.Intercom.Video && console.log(this.name, "_evaluateImageTypeByPath: " + streamUrl + " = " + (this.isMjpg ? "MJPG" : "JPG"));
                return true;
            }

            /**
             * Creates a CORS-Request
             * @param url   the video path
             * @param user  the user (if any)
             * @param pass  the pass (if any)
             * @returns {XMLHttpRequest}
             * @private
             */
            _createCORSRequest(url, user, pass) {
                var hasCreds = user !== null && user !== "";
                var method = 'GET';
                var xhr = new XMLHttpRequest();

                if (hasCreds && !this.control.isIntercom()) {
                    xhr.open(method, url, true, user, pass);
                } else {
                    xhr.open(method, url, true);
                }

                xhr.withCredentials = true;
                return xhr;
            }

            _resetSessionVars(delegate, control) {
                this.isRunning = true;
                this.delegate = delegate;
                this.control = control;
                this.streamIsValid = control.checkStreamUrlValidity();
                this.isJpg = false;
                this.isMjpg = !!control.isIntercom();
                this.refreshCntr = 0;
                this.stopped = false;
                this.isInitialUrl = true;
                this.CORS_ERROR = false;
            }

        }

        Controls.Intercom.SingleTones.IntercomVideoProvider = IntercomVideoProvider;
        return Controls.Intercom.SingleTones.IntercomVideoProvider;
    }
});
