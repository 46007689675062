import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {navigatorConfig, SearchableTable} from "LxComponents";
import useTrustManager from "./useTrustManager";
import TrustTableViewHelper from "./TrustTableViewHelper";
import {useBackNavigation} from "../../../../../../react-comps/Components";

function TrustPeerSelectScreen({navigation, route}) {
    const peers = route.params && route.params.peers;
    const {peerInfos, trustManager} = useTrustManager(peers)
    const changedUsers = useRef([]).current;


    const onBackNavigation = () => {
        let prms = Q();

        if (changedUsers.length) {
            prms = NavigationComp.showWaitingFor(ActiveMSComponent.editPeerUsers(prepareEditUsersObject(changedUsers)));
            prms.then(() => trustManager.trustUsersChanged());
        }

        prms.then((res) => {
            navigation.goBack();
        })
    }

    useBackNavigation(() => {
        onBackNavigation();
    }, []);

    useEffect(() => {
        navigation.setOptions(navigatorConfig({
            animationType: AnimationType.PUSH_OVERLAP_LEFT,
            title: _("usermanagement.trust.managed-user"),
            onLeftAction: onBackNavigation
        }))
    }, [])

    const prepareEditUsersObject = (users) => {
        const transformedUsers = {};
        users.forEach(user => {
            if (transformedUsers[user.peerSerial]) {
                transformedUsers[user.peerSerial].push(user);
            } else {
                transformedUsers[user.peerSerial] = [user];
            }
        });
        return transformedUsers;
    }

    const getPeerName = (serial) => {
        const foundPeer = peers.find(localPeer => serial === localPeer.serial);
        if (!foundPeer) {
            return null;
        } else {
            return foundPeer.name;
        }
    }

    const getAllFoundUsers = () => {
        return peerInfos.flatMap(peerInfo => peerInfo.users);
    }

    const meetsFilter = (text, filter) => text.toLowerCase().includes(filter.toLowerCase());

    const getFilteredContent = useCallback((query) => {
        const content = [{
            rows: []
        }];

        if (nullEmptyString(query)) {
            const foundUsers = getAllFoundUsers().filter(user => {
                let isValidUser = meetsFilter(user.name, query);
                if (user.peerSerial) {
                    isValidUser = isValidUser || meetsFilter(user.peerSerial.toString(), query);
                    const peerName = getPeerName(user.peerSerial)
                    if (peerName) {
                        isValidUser = isValidUser || meetsFilter(getPeerName(user.peerSerial), query)
                    }
                }
                return isValidUser;
            });

            content[0].rows = foundUsers.map(user => TrustTableViewHelper.getCellForUser(user, user.peerSerial, (selected) => {
                const existingChangedUser = changedUsers.find(existingUser => existingUser.uuid === user.uuid);
                if (existingChangedUser) {
                    const userIndex = changedUsers.indexOf(existingChangedUser);
                    changedUsers.splice(userIndex, 1);
                } else {
                    changedUsers.push(user);
                }
                user.used = selected;
            }, getPeerName(user.peerSerial)));

            if (trustManager.isLoadingPeers()) {
                content[0].rows.push(TrustTableViewHelper.getDummyCell());
            } else if (!foundUsers.length) {
                content[0].rows.push({
                    content: {
                        title: _("device-learning.empty-screen.filter-active.no-results-for", {
                            input: query
                        })
                    }
                });
            }
        }

        return content;
    }, [peerInfos])

    const peerContent = useMemo(() => {
        let content = [{
            rows: []
        }];

        peerInfos.sort((peerA, peerB) => peerA.name.localeCompare(peerB.name));

        peerInfos.forEach(peer => {
            const peerInfo = peer.stateInfo;
            content[0].rows.push(TrustTableViewHelper.getCellForPeer(peerInfo, peer.name || getPeerName(peerInfo.serial), () => {
                navigation.navigate(ScreenState.TrustPeerUserSelectScreen, {serial: peerInfo.serial})
            }))
        });

        return content;
    }, [peerInfos])

    return <SearchableTable filterTitle={_("usermanagement.trust.search-user")} tableContent={peerContent}
                            getCustomFilteredContent={getFilteredContent}/>
}

export default TrustPeerSelectScreen;

