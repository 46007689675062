import {useWindowDimensions} from "react-native";
import {useMemo} from "react";

const useCanShowAmbientMode = () => {
    const { height, width } = useWindowDimensions();

    const requiredSize = useMemo(() => {
        return SandboxComponent.requiredAmbientModeWindowSize();
    }, [])

    const shouldRotate = useMemo(() => {
        return width < height && PlatformComponent.isMobileDevice();
    }, [width, height])

    const shouldResize = useMemo(() => {
        return (height < requiredSize.height || width < requiredSize.width) && (PlatformComponent.isElectron || PlatformComponent.isWebInterface() || PlatformComponent.isDeveloperInterface());
    }, [width, height])

    const notSupported = useMemo(() => {
        let notSupported = (height < requiredSize.height || width < requiredSize.width) && !(PlatformComponent.isElectron || PlatformComponent.isWebInterface() || PlatformComponent.isDeveloperInterface());

        if (notSupported && PlatformComponent.isMobileDevice()) {
            notSupported = width < requiredSize.height && height < requiredSize.width;
        }

        return notSupported;
    }, [width, height])

    return {
        shouldRotate,
        shouldResize,
        notSupported
    }
}

export default useCanShowAmbientMode;
