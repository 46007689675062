'use strict';

define([], function () {
    return function ScrollView() {
        var timeConstant = 325; // 325 ms!
        //
        //	FEATURE DETECTION
        //

        /* Determine touch events support */

        var hasTouchSupport = 'ontouchstart' in window || window.DocumentTouch && document instanceof DocumentTouch || PlatformComponent.isElectron; // Why check for Electron? Chrome per default activated touch events, but didn't add the above objects!

        /* Define event names */

        var events = {
            start: "mousedown",
            move: "mousewheel,mousemove,wheel",
            end: "mouseup,mouseleave",
            cancel: "mouseleave"
        };

        if (hasTouchSupport) {
            events.start += "," + "touchstart";
            events.move += "," + "touchmove";
            events.end += "," + "touchend,touchcancel";
            events.cancel += "," + "touchcancel"; // touchenter, touchleave not used!
        }

        function ScrollView(elem) {
            addEventListeners(elem, events.start, onEventStart, this);
            addEventListeners(elem, events.move, onEventMove, this);
            addEventListeners(elem, events.end, onEventEnd, this); //addEventListeners(elem, events.cancel, onEventCancel, this);

            this.element = elem;
            this.offset = 0;
        }

        var addEventListeners = function addEventListeners(element, events, fn, ctx) {
            events.split(",").forEach(function (e) {
                addEvent(e, element, fn.bind(ctx));
            }.bind(this));
        };

        var removeEventListeners = function removeEventListeners(element, events, fn, ctx) {
            events.split(",").forEach(function (e) {
                removeEvent(e, element, fn.bind(ctx));
            }.bind(this));
        };

        function getClass(obj) {
            if (typeof obj === "undefined") return "undefined";
            if (obj === null) return "null";
            return Object.prototype.toString.call(obj).match(/^\[object\s(.*)\]$/)[1];
        }

        var onEventStart = function (e) {
            //console.log("onEventStart " + getClass(e));
            // detect if we touch with 2 fingers!
            if (hasTouchSupport && e.touches && e.touches.length === 2) {
                this.zoom = {
                    touches: getTouches(e.touches)
                };
            } else {
                this.zoom = null;
            }

            if (this.zoom) {//this.zoom.lastPinchDistance = NaN;
            } else {
                this.pressed = true;
                this.calcDelta(e);
                this.velocity = 0;
                this.amplitude = 0;
                this.frame = this.offset;
                this.timestamp = Date.now();
                clearInterval(this.ticker);
                this.ticker = setInterval(this.track.bind(this), 50); // 200ms
            }

            e.cancelBubble = true;
            e.preventDefault();
            e.stopPropagation();
            return false;
        };

        var onEventMove = function (e) {
            //console.log("onEventMove " + getClass(e));

            /*if ((e.timeStamp - this.lastDragTS) < 10) {
             console.log("skipping drag event, too fast!");
             return false;
             }
             this.lastDragTS = e.timeStamp;
             //console.log(this.lastDragTS);*/
            if (this.zoom) {
                // is still a touch!
                if (e.touches && e.touches.length === 2) {
                    /*
                     var a = e.touches[0].screenX - e.touches[1].screenX;
                     var b = e.touches[0].screenY - e.touches[1].screenY;
                     var distance = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
                      if (!isNaN(this.zoom.lastPinchDistance)) {
                     var diff = this.zoom.lastPinchDistance - distance;
                     //console.log("zooming " + diff + "px");
                     this.onZoom && this.onZoom(diff);
                     }
                      this.zoom.lastPinchDistance = distance;
                     */
                    var newTouches = getTouches(e.touches);
                    var leftDiff = newTouches.leftTouch.x - this.zoom.touches.leftTouch.x;
                    var rightDiff = this.zoom.touches.rightTouch.x - newTouches.rightTouch.x; //console.log("leftDiff", leftDiff);
                    //console.log("rightDiff", rightDiff);

                    this.zoom.touches = newTouches;

                    if (leftDiff !== 0 || rightDiff !== 0) {
                        this.onZoom && this.onZoom(leftDiff, rightDiff);
                    }
                }
            } else {
                var isWheelEvent = getClass(e) === "WheelEvent";

                if (this.pressed || isWheelEvent) {
                    // horizontal -> scroll
                    // vertical -> zoom
                    this.calcDelta(e);

                    if (!isWheelEvent) {
                        this.track();
                    }

                    var horizontal = Math.abs(this.deltaX) >= Math.abs(this.deltaY);

                    if (horizontal || !isWheelEvent) {
                        if (this.deltaX > 0 || this.deltaX < 0) {
                            this.scroll(this.offset + this.deltaX);
                        }
                    } else if (isWheelEvent) {
                        if (this.deltaY > 0 || this.deltaY < 0) {
                            this.deltaY = Math.min(this.deltaY, 50);
                            this.deltaY = Math.max(this.deltaY, -50);
                            this.onZoom && this.onZoom(-this.deltaY / 2, -this.deltaY / 2); //var leftPct = e.clientX / this.element.clientWidth;
                            //this.onZoom && this.onZoom(this.deltaY * leftPct, this.deltaY * (1 - leftPct));
                        }
                    }
                }
            }

            e.cancelBubble = true;
            e.preventDefault();
            e.stopPropagation();
            return false;
        };

        var onEventEnd = function (e) {
            //console.log("onEventEnd");
            if (this.pressed) {
                this.pressed = false;
                this.deltaX = null;
                this.deltaY = null;
                this.lastX = null;
                this.lastY = null;
                clearInterval(this.ticker);

                if (this.velocity > 10 || this.velocity < -10) {
                    this.amplitude = 0.8 * this.velocity;
                    this.target = Math.round(this.offset + this.amplitude);
                    this.timestamp = Date.now();
                    cancelAnimationFrame(this.animationID);
                    this.animationID = lxRequestAnimationFrame(this.autoScroll.bind(this), this.element); // whats better??
                    //this.autoScroll.call(this)
                }
            }

            e.cancelBubble = true;
            e.preventDefault();
            e.stopPropagation();
            return false;
        };

        var onEventCancel = function (e) {
            //console.log("onEventCancel");
            this.pressed = false;
            this.deltaX = null;
            this.deltaY = null;
            this.lastX = null;
            this.lastY = null;
            clearInterval(this.ticker);
            e.cancelBubble = true;
            e.preventDefault();
            e.stopPropagation();
            return false;
        };

        var getTouches = function (touches) {
            var leftIdx = touches[0].screenX < touches[1].screenX ? 0 : 1;
            var rightIdx = leftIdx === 0 ? 1 : 0;
            var upperIdx = touches[0].screenY < touches[1].screenY ? 0 : 1;
            var lowerIdx = upperIdx === 0 ? 1 : 0;
            return {
                leftTouch: {
                    x: touches[leftIdx].screenX,
                    y: touches[leftIdx].screenY
                },
                rightTouch: {
                    x: touches[rightIdx].screenX,
                    y: touches[rightIdx].screenY
                },
                upperTouch: {
                    x: touches[upperIdx].screenX,
                    y: touches[upperIdx].screenY
                },
                lowerTouch: {
                    x: touches[lowerIdx].screenX,
                    y: touches[lowerIdx].screenY
                }
            };
        };

        ScrollView.prototype.calcDelta = function calcDelta(e) {
            // scroll event
            if (typeof e.wheelDeltaX !== "undefined") {
                this.deltaX = e.wheelDeltaX;
                this.deltaY = e.wheelDeltaY;
                this.lastX = null; // reset lastX, not needed with scrollwheel!

                this.lastY = null; // reset lastY, not needed with scrollwheel!

                return;
            } else if (typeof e.deltaX !== "undefined") {
                var multiplier = e.deltaMode === e.DOM_DELTA_LINE ? -12 : 1;
                this.deltaX = e.deltaX * multiplier;
                this.deltaY = e.deltaY * multiplier;
                this.lastX = null; // reset lastX, not needed with scrollwheel!

                this.lastY = null; // reset lastY, not needed with scrollwheel!

                return;
            }

            var x, y;

            if (e.targetTouches != undefined && e.targetTouches.length >= 1) {
                // touch event
                x = e.targetTouches[0].clientX;
                y = e.targetTouches[0].clientY;
            } else {
                // mouse event
                x = e.clientX;
                y = e.clientY;
            } // we have a lastX -> calc delta!


            if (this.lastX != null && this.lastY != null) {
                this.deltaX = x - this.lastX;
                this.deltaY = y - this.lastY;
            }

            this.lastX = x;
            this.lastY = y;
        };

        ScrollView.prototype.track = function track() {
            var now, elapsed, delta, v;
            now = Date.now();
            elapsed = now - this.timestamp;
            this.timestamp = now;
            delta = this.offset - this.frame;
            this.frame = this.offset;
            v = 1000 * delta / (1 + elapsed);
            this.velocity = 0.8 * v + 0.2 * this.velocity;
        };

        ScrollView.prototype.autoScroll = function autoScroll() {
            var elapsed, delta;

            if (this.amplitude) {
                elapsed = Date.now() - this.timestamp;
                delta = Math.round(this.amplitude * Math.exp(-elapsed / timeConstant));

                if (delta != 0) {
                    if (delta > 0.5 || delta < -0.5) {
                        this.scroll(this.target - delta);
                        cancelAnimationFrame(this.animationID);
                        this.animationID = lxRequestAnimationFrame(this.autoScroll.bind(this), this.element); // whats better??
                        //setTimeout(this.autoScroll.bind(this), 1);
                    } else {
                        this.scroll(this.target);
                    }
                }
            }
        };

        ScrollView.prototype.scroll = function scroll(xPos) {
            if (this.min != null && this.max != null) {
                xPos = xPos > this.max ? this.max : xPos < this.min ? this.min : xPos;
            }

            var deltaX = xPos - this.offset;

            if (deltaX !== 0) {
                this.offset = xPos; //console.log("scroll to offset " + this.offset + " x:" + deltaX);

                this.onScroll && this.onScroll(this.offset, deltaX);
            }
        };

        ScrollView.prototype.destroy = function onDestroy() {
            cancelAnimationFrame(this.animationID);
            removeEventListeners(this.element, events.start, onEventStart, this);
            removeEventListeners(this.element, events.move, onEventMove, this);
            removeEventListeners(this.element, events.end, onEventEnd, this); //removeEventListeners(this.element, events.cancel, onEventCancel, this);
        };

        return ScrollView;
    }();
});
