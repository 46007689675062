'use strict';

define(['AudioZoneControlEnums', "AudioZoneDetailedContentBase"], function (AudioZoneControlEnums, AudioZoneDetailedContentBase) {
    class ZoneGroupScreen extends AudioZoneDetailedContentBase {
        //region Static
        static Template = function () {
            var getGroupButton = function getGroupButton(buttonTitle) {
                return '<div class="create-edit-zone-group-container">' + '   <button class="create-edit-zone-group-container__button">' + buttonTitle + '   </button>' + '</div>';
            };

            var getFooter = function getFooter() {
                return '<div class="zone-group-footer">' + '   <div class="zone-group-footer-content">' + '   </div>' + '</div>';
            };

            var getEditButton = function getEditButton() {
                return '<div class="zone-group-edit-container">' + '   <button class="zone-group-edit__button">' + _("edit") + '   </button>' + '</div>';
            };

            var getUnsyncButton = function getUnsyncButton() {
                return '<div class="zone-group-unsync-container">' + '   <button class="zone-group-unsync__button">' + _("media.sync.unsync-group") + '   </button>' + '</div>';
            };

            return {
                getGroupButton: getGroupButton,
                getFooter: getFooter,
                getEditButton: getEditButton,
                getUnsyncButton: getUnsyncButton
            };
        }(); //endregion Static

        constructor(details) {
            super(...arguments);

            if (!details || !details.control) {
                throw new Error("Cannot create ZoneGroupScreen if no control is passed in within the details!");
            }

            this.details = details;
            this.control = this.details.control; // Override the base control with the provided one!

            this.stateSet = {};
            this.allZoneControls = {};
            var allZoneSets = MediaServerComp.getAvailableZones();
            Object.keys(allZoneSets).forEach(function (playerid) {
                this.allZoneControls[playerid] = ActiveMSComponent.getStructureManager().getControlByUUID(allZoneSets[playerid].uuidAction);
            }.bind(this));
        }

        viewWillAppear() {
            var baseCall = super.viewWillAppear(...arguments);

            this._registerForZoneStates();

            return baseCall;
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        viewWillDisappear() {
            this._unregisterFromZoneStates();

            return super.viewWillDisappear(...arguments);
        }

        getURL() {
            return "ZoneGroupScreen";
        }

        titleBarText() {
            return _("media.sync");
        }

        _createFooter() {
            if (this.groupButton) {
                this.removeSubview(this.groupButton);
                this.groupButton = null;
            }

            if (this._footerBar) {
                this.removeSubview(this.editButton);
                this.removeSubview(this.unsyncButton);
                this.editButton = null;
                this.unsyncButton = null;

                this._footerBar.remove();

                delete this._footerBar;
            }

            if (!this.stateSet[this.control.details.playerid].isSynced) {
                this.groupButton = new GUI.LxButton(this, ZoneGroupScreen.Template.getGroupButton(_("media.group.set-up")));
                this.appendSubview(this.groupButton);
                this.groupButton.onButtonTapped = this._onGroupButtonTapped.bind(this);
            } else {
                this.element.append(ZoneGroupScreen.Template.getFooter());
                this.editButton = new GUI.LxButton(this, ZoneGroupScreen.Template.getEditButton());
                this.unsyncButton = new GUI.LxButton(this, ZoneGroupScreen.Template.getUnsyncButton());
                this._footerBar = this.element.find(".zone-group-footer");
                this.appendSubview(this.editButton, this._footerBar.find(".zone-group-footer-content"));
                this.appendSubview(this.unsyncButton, this._footerBar.find(".zone-group-footer-content"));
                this.editButton.onButtonTapped = this._onGroupButtonTapped.bind(this);
                this.unsyncButton.onButtonTapped = this._onUnsyncButtonTapped.bind(this);
            }
        }

        _onGroupButtonTapped() {
            var detail = {
                control: this.control
            };
            this.ViewController.showState(AudioZoneControlEnums.ScreenState.GROUP_CREATE_EDIT, null, detail);
        }

        _onUnsyncButtonTapped() {
            var groupableZonesIds = this.stateSet[this.control.details.playerid].syncedzones.map(function (player) {
                    return player.playerid;
                }),
                cmdObj = {};

            if (MediaServerComp.Feature.DISSOLVE_GROUP) {
                var cmd = MediaEnum.Commands.SYNC.MULTI_UNSYNC;
                cmd += groupableZonesIds.join("/");
                MediaServerComp.sendMediaServerCommand(cmd);
            } else {
                Debug.Control.AudioZone.Group && console.log("      using individual unsync commands");
                groupableZonesIds.forEach(function (zoneId) {
                    cmdObj.cmd = MediaEnum.AudioCommands.UNSYNC;
                    MediaServerComp.sendAudioZoneCommand(zoneId, cmdObj);
                });
            }

            this.ViewController.navigateBackTo(AudioZoneControlEnums.ScreenState.GROUP_OVERVIEW);
        }

        setTableContent() {
            if (Object.keys(this.stateSet).length && Object.keys(this.stateSet).length === Object.keys(this.allZoneControls).length) {
                this.tableContent = [];

                if (this.stateSet[this.control.details.playerid]) {
                    if (this.stateSet[this.control.details.playerid].isSynced) {
                        this._setSyncedSections();
                    } else {
                        this.tableContent.push({
                            rows: [this._getZoneCell(this.control.details.playerid)]
                        });
                    }
                }

                this._createFooter();
            }

            return super.setTableContent(...arguments);
        }

        _setSyncedSections() {
            var states = this.stateSet[this.control.details.playerid];

            if (MediaServerComp.Feature.V2_FIRMWARE && states.hasOwnProperty("masterVolume")) {
                this.tableContent.push({
                    rows: [{
                        content: {
                            zone: this.allZoneControls[this.control.details.playerid],
                            title: _("group"),
                            minIconSrc: Icon.AudioZone.VOL_MINUS,
                            maxIconSrc: Icon.AudioZone.VOL_PLUS,
                            value: states.masterVolume,
                            format: ""
                        },
                        type: GUI.TableViewV2.CellType.SLIDER,
                        sliderDragged: this._onMasterVolumeChange.bind(this),
                        sliderClicked: this._onMasterVolumeChange.bind(this)
                    }]
                });
            }

            this.tableContent.push({
                headerTitle: _("media.preferences.zones"),
                rows: states.syncedzones.map(function (playerIdObj) {
                    return this._getZoneCell(playerIdObj.playerid);
                }.bind(this))
            });
        }

        _getZoneCell(playerId) {
            return {
                content: {
                    zone: this.allZoneControls[playerId],
                    title: this.allZoneControls[playerId].getName(),
                    maxValue: this.stateSet[playerId].maxVolume,
                    minIconSrc: Icon.AudioZone.VOL_MINUS,
                    maxIconSrc: Icon.AudioZone.VOL_PLUS,
                    format: "",
                    updateRate: 0.55
                },
                type: GUI.TableViewV2.CellType.Special.AudioVolCell,
                sliderDragged: this._onVolumeChange.bind(this),
                sliderClicked: this._onVolumeChange.bind(this)
            };
        }

        _registerForZoneStates() {
            this._unregisterFromZoneStates();

            Object.keys(this.allZoneControls).forEach(function (key) {
                var zone = this.allZoneControls[key],
                    uuid = zone.uuidAction;
                this.registrations.push(uuid);
                SandboxComponent.registerForStateChangesForUUID(uuid, this, function (states) {
                    this._zoneStatesReceived(states, key);
                }.bind(this));
            }.bind(this));
        }

        _unregisterFromZoneStates() {
            if (this.registrations) {
                // Unregister
                this.registrations.forEach(function (regUuid) {
                    SandboxComponent.unregisterForStateChangesForUUID(regUuid, this);
                }.bind(this));
            }

            this.registrations = [];
        }

        /**
         * Will update the stateSet for this player and also trigger an UI update if critical data has changed.
         * @param states
         * @param playerId
         * @private
         */
        _zoneStatesReceived(states, playerId) {
            var clientStateChanged = true,
                groupingChanged = true,
                masterVolChanged = true; //Detect if these states really did change

            if (this.stateSet.hasOwnProperty(playerId)) {
                clientStateChanged = this.stateSet[playerId].clientState !== states.clientState;
                groupingChanged = JSON.stringify(this.stateSet[playerId][MediaEnum.Event.SYNCED_ZONES]) !== JSON.stringify(states[MediaEnum.Event.SYNCED_ZONES]);

                if (states.hasOwnProperty("masterVolume")) {
                    masterVolChanged = this.stateSet[playerId].masterVolume !== states.masterVolume;
                } else {
                    masterVolChanged = false; // We ain't have a master volume!
                }
            }

            if (clientStateChanged || groupingChanged) {
                Debug.Control.AudioZone.Group && console.log(this.name, "_zoneStatesReceived: " + playerId);
                this.stateSet[playerId] = cloneObject(states);
                this.reloadTable();
            } else if (masterVolChanged) {
                var masterVolCell = this.tableView.cellForSectionAndRow(0, 0);
                masterVolCell.setPosition(states.masterVolume);
            }
        }

        _getRightSideForTitleBar() {
            return null;
        }

        _onVolumeChange(cell, section, row, tableView, value) {
            MediaServerComp.sendAudioZoneCommand(cell.content.zone.details.playerid, {
                cmd: MediaEnum.AudioCommands.VOLUME.SET_TO + value,
                type: Commands.Type.OVERRIDE
            });
        }

        _onMasterVolumeChange(cell, section, row, tableView, value) {
            MediaServerComp.sendAudioZoneCommand(cell.content.zone.details.playerid, {
                cmd: MediaEnum.AudioCommands.MASTER_VOLUME.SET_TO + value,
                type: Commands.Type.OVERRIDE
            });
        }

    }

    Controls.AudioZoneControl.ZoneGroupScreen = ZoneGroupScreen;
});
