import {ActivityIndicator} from "react-native-web";
import globalStyles from "GlobalStyles";
import PropTypes from "prop-types";


export default function LxReactWaitingIndicator(props) {
    return <ActivityIndicator size={props.size || "large"} color={globalStyles.colors.white} {...props}  />
}

LxReactWaitingIndicator.propTypes = {
    size: PropTypes.number
}

