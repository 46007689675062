'use strict';

define(["ControlActionCellsScreen", "LxComponents" ], function (ControlActionCellsScreen, {
    LxLegacyReactControlContent
}) {
    return class Wallbox2ControlContent extends ControlActionCellsScreen {

        static ReactComp = LxLegacyReactControlContent;

        constructor() {
            super(...arguments);
        }

    };
});
