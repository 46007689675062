'use strict';

define([], function () {
    return class LoxoneControlInfo extends GUI.Screen {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(configMode, infoType) {
                var mainContent;

                if (infoType === LoxoneControlInfoType.INITIAL_INFO) {
                    mainContent = '<div class="loxone-control-info-screen__header">' + _("loxone-control.presence-detector") + ' & ' + _("loxone-control.quick-action") + '</div>' + '<div class="loxone-control-info-screen__page-view-placeholder"></div>';
                } else {
                    mainContent = getPageForInfoType(infoType);
                }

                var finishButton = "",
                    cancelButton = ""; // buttons only in config mode

                if (configMode) {
                    finishButton = '<button class="loxone-control-info-screen__button button--finish">' + getFinishButtonTitleForInfoType(infoType) + '</button>';

                    if (infoType === LoxoneControlInfoType.INITIAL_INFO) {
                        cancelButton = '<button class="loxone-control-info-screen__button button--cancel">' + _("dont-use") + '</button>';
                    }
                }

                return $('<div class="loxone-control-info-screen">' + '   ' + mainContent + '   ' + finishButton + '   ' + cancelButton + '</div>');
            };

            var getPageForInfoType = function getPageForInfoType(infoType, pageIdx) {
                var texts = getLabelsForInfoType(infoType, pageIdx);
                var descriptionLabel = "",
                    pathLabel = "";

                if (texts.description) {
                    descriptionLabel = '<div class="page__description">' + texts.description + '</div>';
                }

                if (texts.path) {
                    pathLabel = '<div class="page__path">' + texts.path + '</div>';
                }

                var additionalClass = infoType === LoxoneControlInfoType.INITIAL_INFO ? " page--with-header" : "";
                var imageSrc = infoType === LoxoneControlInfoType.INITIAL_INFO && pageIdx === 0 ? Icon.Presence.PIC_ACTIVE : Icon.Presence.PIC_INACTIVE;
                return '<div class="loxone-control-info-screen__page' + additionalClass + '">' + '   ' + '<div class="page__image-placeholder">' + '       ' + '<img class="image-placeholder__loupe" src="' + imageSrc + '" />' + '   ' + '</div>' + '   ' + '<div class="page__information-placeholder">' + '       ' + '<div class="page__title">' + texts.title + '</div>' + '       ' + descriptionLabel + '       ' + pathLabel + '   ' + '</div>' + '</div>';
            };

            var getLabelsForInfoType = function getLabelsForInfoType(infoType, pageIdx) {
                var title, description, path;

                var pathText = _("menu") + " > " + _("menu.tools-and-services");

                if (infoType === LoxoneControlInfoType.NO_PRESENCE_CONTROLS || infoType === LoxoneControlInfoType.NO_INTERNAL_STRUCTURE) {
                    pathText += " > " + _("loxone-control.presence-detector");
                }

                var boldPath = '<span class="path__bold">' + pathText + '</span>';

                switch (infoType) {
                    case LoxoneControlInfoType.INITIAL_INFO:
                        if (pageIdx === 0) {
                            title = _("loxone-control.presence-detector");
                            description = _("loxone-control.configuration.initial.presence-description");
                        } else if (pageIdx === 1) {
                            title = _("loxone-control.quick-action");
                            description = _("loxone-control.configuration.initial.quick-actions-description");
                        }

                        break;

                    case LoxoneControlInfoType.DEACTIVATED:
                        title = _("loxone-control.configuration.deactivated.title");
                        path = _("loxone-control.configuration.deactivated.path", {
                            path: boldPath
                        });
                        break;

                    case LoxoneControlInfoType.NO_PRESENCE_CONTROLS:
                        title = _("loxone-control.configuration.no-controls.title");
                        description = _("loxone-control.configuration.no-controls.description", {
                            computer: "PC"
                        });
                        path = _("loxone-control.configuration.no-controls.path", {
                            path: boldPath
                        });
                        break;

                    case LoxoneControlInfoType.NO_INTERNAL_STRUCTURE:
                        title = _("loxone-control.configuration.not-external.title");
                        description = _("loxone-control.configuration.not-external.description");
                        path = _("loxone-control.configuration.not-external.path", {
                            path: boldPath
                        });
                        break;

                    case LoxoneControlInfoType.CONFIGURATION_SUCCESSFUL:
                        title = _("loxone-control.configuration.success.title");
                        description = _("loxone-control.configuration.success.description");
                        break;
                }

                return {
                    title: title,
                    description: description,
                    path: path
                };
            };

            var getFinishButtonTitleForInfoType = function getFinishButtonTitleForInfoType(infoType) {
                switch (infoType) {
                    case LoxoneControlInfoType.INITIAL_INFO:
                        return _("loxone-control.configuration.use-it");

                    case LoxoneControlInfoType.DEACTIVATED:
                    case LoxoneControlInfoType.NO_PRESENCE_CONTROLS:
                    case LoxoneControlInfoType.NO_INTERNAL_STRUCTURE:
                    case LoxoneControlInfoType.CONFIGURATION_SUCCESSFUL:
                        return _("finish");
                }
            };

            return {
                getTemplate: getTemplate,
                getPageForInfoType: getPageForInfoType
            };
        }(); //endregion Static

        constructor(details) {
            let configMode = Boolean(details && details.configMode);
            let infoType = details.infoType;
            super(LoxoneControlInfo.Template.getTemplate(configMode, infoType));
            this.configMode = configMode;
            this.infoType = infoType;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.ViewController.addMultipleView(ScreenState.LoxoneControlInfo);
            this.element.toggleClass("screen--without-title-bar", this.configMode);

            if (this.infoType === LoxoneControlInfoType.INITIAL_INFO) {
                var pages = [LoxoneControlInfo.Template.getPageForInfoType(this.infoType, 0), LoxoneControlInfo.Template.getPageForInfoType(this.infoType, 1)];
                this.pageView = new GUI.LxPageView(pages, null, null, {
                    overscroll: true,
                    pageControl: true
                });
                this.pageView.disableUserInteraction(true);
                this.appendSubview(this.pageView, this.element.find('.loxone-control-info-screen__page-view-placeholder'));
            }

            if (this.configMode) {
                var button = this.element.find('.button--finish');
                this.finishButton = new GUI.LxButton(this, button[0]);
                this.finishButton.onButtonTapped = this._finishButtonTapped.bind(this);
                this.addToHandledSubviews(this.finishButton);

                if (this.infoType === LoxoneControlInfoType.INITIAL_INFO) {
                    button = this.element.find('.button--cancel');
                    this.cancelButton = new GUI.LxButton(this, button[0]);
                    this.cancelButton.onButtonTapped = this._cancelButtonTapped.bind(this);
                    this.addToHandledSubviews(this.cancelButton);
                }
            }
        }

        handleModalBackgroundTapped() {
            return false;
        }

        destroy() {
            if (this.configMode) {
                this.finishButton.destroy();

                if (this.infoType === LoxoneControlInfoType.INITIAL_INFO) {
                    this.cancelButton.destroy();
                }
            }

            super.destroy();
        }

        titleBarText() {
            if (!this.configMode) {
                return _("loxone-control.presence-detector");
            }
        }

        // Private methods
        _finishButtonTapped() {
            switch (this.infoType) {
                case LoxoneControlInfoType.INITIAL_INFO:
                    PersistenceComponent.setMSInstructionFlag(UserInstructionFlag.STATUS_BAR_ACTIVATION);
                    LoxoneControl.setVisibleInStatusBar(true);
                    var state, infoType;

                    if (CommunicationComponent.getCurrentReachMode() === ReachMode.LOCAL) {
                        if (ActiveMSComponent.getStructureManager().getPresenceRooms().length > 0) {
                            state = ScreenState.PresenceDetector;
                        } else {
                            state = ScreenState.LoxoneControlInfo;
                            infoType = LoxoneControlInfoType.NO_PRESENCE_CONTROLS;
                        }
                    } else {
                        state = ScreenState.LoxoneControlInfo;
                        infoType = LoxoneControlInfoType.NO_INTERNAL_STRUCTURE;
                    } // always start in config mode, although method only could be executed in config mode


                    this.ViewController.showState(state, null, {
                        configMode: true,
                        infoType: infoType
                    });
                    break;

                case LoxoneControlInfoType.DEACTIVATED:
                    PersistenceComponent.setMSInstructionFlag(UserInstructionFlag.STATUS_BAR_ACTIVATION);
                    this.ViewController.navigateBackToRoot();
                    break;

                case LoxoneControlInfoType.NO_PRESENCE_CONTROLS:
                case LoxoneControlInfoType.NO_INTERNAL_STRUCTURE:
                case LoxoneControlInfoType.CONFIGURATION_SUCCESSFUL:
                    PersistenceComponent.setMSInstructionFlag(UserInstructionFlag.PRESENCE_DETECTOR_ACTIVATION);
                    this.ViewController.navigateBackToRoot();
                    break;
            }
        }

        _cancelButtonTapped() {
            // always start in config mode, although method only could be executed in config mode
            this.ViewController.showState(ScreenState.LoxoneControlInfo, null, {
                configMode: true,
                infoType: LoxoneControlInfoType.DEACTIVATED
            });
        }

    };
});
