'use strict';

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.MOOD_CELLV2 = "MoodCellV2";

    class MoodCellV2 extends GUI.TableViewV2.Cells.MoodCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            Object.assign(this, StateHandler.Mixin);
        }

        viewDidLoad() {
            var baseCall = super.viewDidLoad(...arguments);
            this.moodId = this.content.id; // initialise in viewDidLoad --> this.content is not ready in the constructor

            this.neverShowRightButton = this.moodId === Controls.LightV2Control.Enums.KNOWN_MOODS.ALL_OFF;
            return baseCall;
        }

        updateUI(selected) {
            // the cell update its self, changes in the cell would be overwritten in base!!!
            return null;
        }

        viewWillAppear() {
            return (super.viewWillAppear(...arguments) || Q.resolve()).then(function () {
                this.content.controlUuid && this._registerForStates(this.content.controlUuid, ["activeMoods"]);
            }.bind(this));
        }

        viewWillDisappear() {
            this.content.controlUuid && this._unregisterStates(this.content.controlUuid);
            return super.viewWillDisappear(...arguments);
        }

        destroy() {
            if (this.leftButton) {
                this.leftButton.onButtonTapped = null;
            }

            return super.destroy(...arguments);
        }

        // Private functions
        receivedStates(states) {
            var moodSelected = states.activeMoods.includes(this.moodId); // this.content.isMulti has to be set for the moodCell

            this.content.isMulti = states.activeMoods.length > 1 && moodSelected;
            this.content.selected = moodSelected; // the mixin button has to be shown if any mood is selected except "All off"

            if (!this.neverShowRightButton && !states.activeMoods.includes(Controls.LightV2Control.Enums.KNOWN_MOODS.ALL_OFF)) {
                this._addRightButton();
            } else {
                this._removeRightButton();
            } // if only one cell is selected, we don't have to show the mixin button for this cell


            if (!states.activeMoods.length || states.activeMoods.length === 1 && states.activeMoods[0] === this.moodId) {
                this._removeRightButton();
            } //prevents unnecessary reloads


            if (JSON.stringify(states.activeMoods) !== JSON.stringify(this._prevActiveMoods)) {
                this._updateContent(this.content);

                this._prevActiveMoods = states.activeMoods;
            }
        }

        _addRightButton() {
            var buttonIcon = this._getTemplate().getRightButtonIcon(this.content.buttonSrc, this.content.isMulti);

            if (this.rightButton) {
                this.showSubview(this.rightButton); // Only replace the icon to minimalize dom manipulations

                this.rightButton.element && this.rightButton.element.find('.button__icon').replaceWith(buttonIcon);
            } else {
                var rightButton = this._getTemplate().getRightButton(this.content.buttonSrc, this.content.isMulti);

                this.rightButton = new GUI.LxButton(this, rightButton[0], Color.BUTTON_GLOW, [{
                    part: rightButton.children()[0],
                    mode: 'fill'
                }], true);
                this.appendSubview(this.rightButton, this.element.find('.cell__content'));
            }
        }

        _removeRightButton() {
            this.rightButton && this.hideSubview(this.rightButton);
        }

    }

    GUI.TableViewV2.Cells.MoodCellV2 = MoodCellV2;
    return GUI;
}(window.GUI || {});
