import {View} from "react-native";
import {useContext, useMemo} from "react";
import {ControlContext, LxReactTableView, useLiveState} from "LxComponents";
import LxTrackerCell from "./LxTrackerCell";
import globalStyles from "GlobalStyles";
import PropTypes from "prop-types";

function LxTrackerView (props) {
    const {control, states} = useContext(ControlContext)
    const trackerStates = useLiveState((props.trackerControl || control).uuidAction);

    const filterEntries = (entries) => {
        let trackerControl = props.trackerControl || control;
        if (!trackerControl.details.hasOwnProperty("startTime")) {
            return entries;
        }

        let startTime = trackerControl.details.startTime; // just return entries since the given startTime (details)

        let result = [];

        for (let i = 0; i < entries.length; i++) {
            let entriesPerDay = entries[i];
            let alarmEntries = [];

            for (let j = 0; j < entriesPerDay.entries.length; j++) {
                let entry = entriesPerDay.entries[j];

                if (entry.timestamp.unix() < startTime.unix()) {
                    continue;
                }

                alarmEntries.push(entry);
            }

            if (alarmEntries.length > 0) {
                let dayCopy = JSON.parse(JSON.stringify(entriesPerDay));
                dayCopy.entries = alarmEntries;
                result.push(dayCopy);
            }
        }

        return result;
    }

    const groupEntriesByDay = (entries) => {
        let sections = [];
        entries.forEach((entry) =>{
            const displayDate = entry.date && moment(entry.date).format(LxDate.getDateFormat(DateType.DateText))
            let daySection = {
                headerStrongTitle: displayDate || null,
                rows: [{
                    type: "CustomCell",
                    comp: LxTrackerCell,
                    props: {
                        entries: entry.entries
                    }
                }]
            };
            sections.push(daySection);
        });
        return sections;
    }

    const tableContent = useMemo(() => {
        let content;
        if (props.transformEntries) {
            content = groupEntriesByDay(props.transformEntries(filterEntries(trackerStates.states.trackerEntries || [])))
        } else {
            content = groupEntriesByDay(filterEntries(trackerStates.states.trackerEntries || []));
        }
        return content;
    }, [props.trackerControl, trackerStates, states.trackerEntries])

    return <View style={globalStyles.customStyles.fullWidth}>
        <LxReactTableView styles={styles.tableView} tableContent={tableContent} />
    </View>
}

const styles = {
    tableView: {
        List: {
            paddingHorizontal: 0
        }
    }
}

LxTrackerView.propTypes = {
    transformEntries: PropTypes.func,
    trackerControl: PropTypes.object
}

export default LxTrackerView
