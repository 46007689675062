'use strict';

define([], function () {
    return class AutomaticDesignerRulesScreen extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            this.ruleUuid = details.ruleUuid; // optional used to directly open a rule via URL-Scheme

            this.details = details || {};
            this._repetitiveRules = [];
            this._oneTimeRules = [];
        }

        viewDidLoad() {
            var args = arguments;
            return this._loadRulesFromComp().then(rules => {
                this.unregisteRuleListenerFn = AutomaticDesignerComponent.registerForRuleChangeNotify(function () {
                    this.updateView(this.details);
                }.bind(this));
                return Q(super.viewDidLoad(...args) || true).then(function () {
                    this.updateView(this.details);
                }.bind(this));
            });
        }

        viewWillAppear() {
            return Q(super.viewWillAppear(...arguments) || true).then(function () {
                return this._loadRulesFromComp();
            }.bind(this));
        }

        updateView(details) {
            this.details = details || {};

            this._loadRulesFromComp().then(function (rules) {
                this.reloadTable();

                if (this.details.ruleUuid) {
                    var wantedRule = rules.find(function (rule) {
                        return rule.id === this.details.ruleUuid;
                    }.bind(this));
                    wantedRule && this._openRule(wantedRule);
                    delete this.details.ruleUuid; // Delete it, we just want to do it once
                } else if (this.details.taskRecorderRule) {
                    this._openRule(cloneObject(this.details.taskRecorderRule));

                    delete this.details.taskRecorderRule;
                }
            }.bind(this));
        }

        destroy() {
            this.unregisteRuleListenerFn();
            super.destroy(...arguments);
        }

        getURL() {
            return "Rules";
        }

        reloadTable() {
            this.tableContent = [{
                headerTitle: "",
                headerDescription: _("automatic-designer.rule.title")
            }];

            if (this._repetitiveRules.length) {
                this.tableContent.push({
                    rows: this._repetitiveRules.map(function (rule) {
                        return this._createCellForRule(rule, false);
                    }.bind(this)).sort(function (a, b) {
                        return a.content.title.localeCompare(
                            b.content.title,
                        );
                    })
                });
            }

            if (this._oneTimeRules.length) {
                this.tableContent.push({
                    headerBoldTitle: _(
                        'automatic-designer.rule.single.title',
                    ).toUpperCase(),
                    headerDescription: _('automatic-designer.rule.single.desc'),
                    rows: this._oneTimeRules
                        .map(
                            function (rule) {
                                return this._createCellForRule(rule, true);
                            }.bind(this),
                        )
                        .sort(function (a, b) {
                            return a.content.title.localeCompare(
                                b.content.title,
                            );
                        }),
                });
            }

            return super.reloadTable(...arguments);
        }

        _loadRulesFromComp() {
            return AutomaticDesignerComponent.getRules(this.details.filterControls).then(function (rules) {
                return this._sortRules(rules);
            }.bind(this));
        }

        _sortRules(rules) {
            this._repetitiveRules = [];
            this._oneTimeRules = [];
            rules.forEach(function (rule) {
                if (rule.eventGroups) {
                    var isOneTime = rule.eventGroups.map(function (group) {
                        if (group.hasOwnProperty("events")) {
                            return group.events.map(function (event) {
                                return event.type;
                            }).includes(AutomaticDesignerEnums.EVENT_TYPES.DATE);
                        } else {
                            return true;
                        }
                    }).reduce(function (left, right) {
                        return left && right;
                    }, true);

                    if (isOneTime) {
                        this._oneTimeRules.push(rule);
                    } else {
                        this._repetitiveRules.push(rule);
                    }
                }
            }.bind(this));
            return rules;
        }

        _isRuleAlreadyExecuted(rule) {
            var msNow = SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINISERVER_DATE_TIME),
                execTimes = [].concat.apply([], rule.eventGroups.map(function (group) {
                    return (group.events || []).filter(function (event) {
                        return event.type === AutomaticDesignerEnums.EVENT_TYPES.DATE;
                    }).map(function (eventWithTime) {
                        return moment.unix(eventWithTime.value);
                    });
                })),
                hasAlreadyBeenExecuted = false;
            execTimes.forEach(function (time) {
                hasAlreadyBeenExecuted |= time.isBefore(msNow, "day");
            });
            return !!hasAlreadyBeenExecuted;
        }

        _openRule(rule) {
            NavigationComp.showState(ScreenState.AutomaticDesigner.RuleViewController, {
                rule: rule
            });
        }

        _createCellForRule(rule, isOneTime) {
            var alreadyExecuted = isOneTime && this._isRuleAlreadyExecuted(rule),
                row = {
                    type: GUI.TableViewV2.CellType.SWITCH,
                    content: {
                        title: rule.name,
                        clickable: true,
                        active: rule.active,
                        icon: Icon.AutomaticDesigner.COMMON,
                        iconColor: rule.active ? window.Styles.colors.green : null
                    },
                    action: this._openRule.bind(this, rule),
                    onSwitchChanged: function (value) {
                        AutomaticDesignerComponent.setRuleActive(rule, value).fail(function () {
                            return this.reloadTable();
                        }.bind(this));
                    }.bind(this)
                };

            if (rule.active) {
                if (rule.description) {
                    row.content.subtitle = rule.description;
                }
            } else {
                row.content.subtitle = _("user.inactive");
            }

            if (rule.invalid) {
                delete row.type; // Use a generalCell instead

                row.content.rightIconSrc = Icon.WARNING;
                row.content.rightIconColor = window.Styles.colors.orange;
                row.content.leftIconSrc = Icon.AutomaticDesigner.COMMON;
                row.content.leftIconColor = Color.TEXT_SECONDARY_B;

                if (rule.active) {
                    row.content.subtitle = _("error");
                } else {
                    row.content.subtitle = _("user.inactive") + "; " + _("error");
                }
            } else if (rule.locked) {
                delete row.type; // Use a generalCell instead
            } else if (alreadyExecuted) {
                row.type = GUI.TableViewV2.CellType.BUTTON;
                row.buttonTapped = row.action;
                row.content.iconSrc = Icon.AutomaticDesigner.COMMON;
                row.content.iconColor = Color.TEXT_SECONDARY_B;
                row.content.buttonSrc = Icon.Buttons.REFRESH;
                row.content.subtitle = _("automatic-designer.rule.historic");
            }

            return row;
        }

    };
});
