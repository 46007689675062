import {useEffect, useRef, useState} from "react";
import {PeerData, PeerState} from "./TrustModels";

function useTrustManager(peers = []) {
    const [peerInfos, setPeerInfos] = useState([]);
    const peerSubscriptions = useRef([]);
    const trustManager = useRef(ActiveMSComponent.getTrustManager()).current;

    useEffect(() => {
        const initialPeers = [];
        peers.forEach(peer => {
            if ("name" in peer && "serial" in peer) {
                initialPeers.push(new PeerData(peer.serial, peer.name));
            }
        })
        setPeerInfos(initialPeers);

        initialPeers.filter(peer => !peerSubscriptions.current[peer.stateInfo.serial]).forEach(peer => {
            const peerInfo = peer.stateInfo;
            if (peerInfo.state === PeerState.New) {
                peerSubscriptions.current[peerInfo.serial] = trustManager.getTrustUsersForPeer(peerInfo.serial, false, peer).subscribe(peerData => {
                    setPeerInfos(prevPeerInfos => {
                        const currentPeerInfos = [...prevPeerInfos];
                        const currentPeerInfo = currentPeerInfos.find(currentPeer => currentPeer.stateInfo.serial === peerData.stateInfo.serial);
                        const index = currentPeerInfos.indexOf(currentPeerInfo);
                        currentPeerInfos[index] = peerData;
                        return currentPeerInfos;
                    })
                })
            }
        })

        return () => {
            peerSubscriptions && peerSubscriptions.current.forEach(subscription => subscription.unsubscribe())
            peerSubscriptions.current = []
        }
    }, [])

    return {
        peerInfos,
        trustManager
    };
}

export default useTrustManager;
