


export default class DeviceSearchUtils {
    static isDummyExtension = (extension) => {
        let isDummy = false;
        
        switch (extension.serialNr) {
            case DeviceManagement.DUMMY_TREE:
            case DeviceManagement.DUMMY_TREE_TO_AIR:
            case DeviceManagement.DUMMY_AIRBASE:
                isDummy = true;
                break;
            default:
                break;
        }

        if(Feature.GENERIC_DEVICE_SEARCH && !extension.serialNr) {
            isDummy = true;
        }
        
        return isDummy;
    }

    static isOnMiniserver = (extension, miniserver) => {
        if (!miniserver) {
            return true;
        } else {
            if (miniserver.serialNr) {
                return extension.msSerial === miniserver.serialNr;
            } else {
                return extension.msName === miniserver.name;
            }
        }
    }

    static getSearchableExtensions = (extensions, miniserver) => {
        var dummyArr = [],
            physicalArr = [],
            list;

        if (!extensions) {
            return null;
        }

        extensions.filter(ext => DeviceSearchUtils.isOnMiniserver(ext, miniserver)).forEach((extension) => {
            if (DeviceSearchUtils.isDummyExtension(extension)) {
                dummyArr.push(extension);
            } else {
                physicalArr.push(extension);
            }
        });

        if (!physicalArr.length && dummyArr.length) {
            list = [];

        } else if (!physicalArr.length && !dummyArr.length) {
            list = [];

        } else {
            list = physicalArr;
        }
        return list.sort((a, b) => {
            let res = (a.name && b.name) ? a.name.localeCompare(b.name) : 0;
            if (res === 0) {
                try {
                    res = a.place.localeCompare(b.place);
                } catch (ex) {
                    // nothing to do.
                }
            }
            return res;
        });
    }

    static extensionHasBranches = (extension) => {
        return extension.subCaptions && extension.subCaptions.length > 1
    }

    static getExtensionBranches = (extension) => {
        if (extension.subCaptions && Array.isArray(extension.subCaptions)) {
            return extension.subCaptions;
        }
        return [];
    }

    static createMiniserverListFromExtensionList = (extensionList) => {
        if (!extensionList) {
            return null;
        }
        let msObj,
            msMap = {};

        extensionList && extensionList.forEach(ex => { // msSerial is also available
            msMap[ex.msSerial || ex.msName] = msMap[ex.msSerial || ex.msName] || {extensions: [], foundDevices: 0};
            msObj = msMap[ex.msSerial || ex.msName];
            msObj.extensions.push(ex);
            msObj.serialNr = msObj.serialNr || ex.msSerial;
            msObj.name = msObj.name || ex.msName;
            msObj.type = msObj.type || ex.miniserverType;
            msObj.typeName = msObj.typeName || getMiniserverTypeName(msObj.type);
            if (msObj.hasOwnProperty("type") && !msObj.imageUrl) {
                msObj.imageUrl = ActiveMSComponent.getImageUrlForMiniserverType(msObj.type);
            } else if (!msObj.imageUrl && ex.internal) {
                msObj.imageUrl = msObj.imageUrl || ex.imageUrl;
            }
            if (ex.foundDevices && ex.foundDevices > 0) {
                msObj.foundDevices += ex.foundDevices;
            }
            if (ex.loadingFoundDevices) {
                msObj.loadingFoundDevices = true;
            }

        });

        return Object.values(msMap).sort((a,b) => {
            return (a.name && b.name) ? a.name.localeCompare(b.name) : 0;
        });
    }

    static populateMsListWithExtensionInfo = (msList, extensionList) => {
        if (!extensionList || !msList) {
            return null;
        }
        let extendedMsList = cloneObjectDeep(msList).map(ms => {
            ms.extensions = extensionList.filter(ext => ext.msSerial === ms.serialNr);
            ms.type = ms.miniserverType; // LoxLIVE is the new normalised type and has to be overwritten here
            ms.typeName = getMiniserverTypeName(ms.miniserverType);
            if("type" in ms && !ms.imageUrl) { // if the miniserver has a type but no image, set the image (e.g. LoxLIVE)
                ms.imageUrl = ActiveMSComponent.getImageUrlForMiniserverType(ms.miniserverType);
            } else if (!ms.imageUrl && ms.extensions.some(ext => ext.internal)) {
                ms.imageUrl = ms.extensions.find(ext => ext.internal).imageUrl;
            }
            ms.foundDevices = ms.extensions.reduce((acc, ext) => acc + (ext.foundDevices || 0), 0);
            return ms;
        });

        return extendedMsList.sort((a,b) => {
            return (a.name && b.name) ? a.name.localeCompare(b.name) : 0;
        });
    }

    static getTotalFoundDevicesFromMsList = (msList) => {
        let sum = 0;
        msList && msList.forEach(ms => {
            if (ms.foundDevices && ms.foundDevices > 0) {
                sum += ms.foundDevices;
            }
        });
        return sum;
    }

    static getTotalLoadingFoundDevices = (msList) => {
        let loading = false;
        msList && msList.forEach(ms => {
            loading = loading || ms.loadingFoundDevices;
        });
        return loading;
    }

    static filterSearchResults = (allResults, miniserver, branch) => {
        let results = allResults;
        if (branch) {
            results = allResults.filter(device => {
                return device.subCaptionId === branch.id;
            });
        } else if (miniserver) {
            results = allResults.filter(device => {
                if (miniserver.serialNr && device.msSerial) {
                    return miniserver.serialNr === device.msSerial;
                } else if (miniserver.name && device.msName) {
                    return device.msName === miniserver.name;
                } else {
                    return true; // e.g. air device search won't provide any info on the ms the device is from.
                }
            });
        }
        return results;
    }

    /**
     * Will iterate over devices and for each device assign the proper room & lightGroup Object (if provided)
     * @param deviceList
     * @param lightGroupList
     * @param roomsMap
     * @returns {*}
     */
    static populateDeviceListWith = (deviceList, lightGroupList, roomsMap) => {
        let lgMap = {};
        if (lightGroupList) {
            lightGroupList.forEach(lg => {
                lgMap[lg.id] = lg;
            });
        }

        return deviceList.map(dev => {
            let result = {...dev};
            if (dev.room && roomsMap && roomsMap[dev.room]) {
                result.roomUuid = dev.room;
                result.room = roomsMap[dev.room];
            }
            if (dev.groupID && lgMap.hasOwnProperty(dev.groupID)) {
                dev.group = lgMap[dev.groupID];
            }
            return result;
        });
    }
}

