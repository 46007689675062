import StorageHandlerBase from "./StorageHandlerBase";


class VolatileStorageHandler extends StorageHandlerBase {
    constructor() {
        super(arguments);
        this._storage = {};
    }

    saveFile(fileName, data, dataType) {
        this._storage[fileName] = {
            data: data,
            dataType: dataType
        };
        return Q.resolve();
    }

    getFile(fileName, dataType) {
        if (this._storage.hasOwnProperty(fileName)) {
            return Q.resolve(this._storage[fileName].data);
        } else {
            return Q.reject();
        }
    }

    deleteFile(fileName) {
        delete this._storage[fileName];
        return Q.resolve();
    }
}

export default VolatileStorageHandler;