'use strict';
/**
 * This is an abstract class, don't use it directly, make subclasses instead...
 */

window.GUI = function (GUI) {
    class PickerBaseCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getPickerCellHeader = function getPickerCellHeader(iconSrc, title) {
                return (iconSrc ? ImageBox.getResourceImageWithClasses(iconSrc, "header__icon") : '') + '<div class="header__title-container">' + '<div class="title-container__title text-overflow-ellipsis">' + title + '</div>' + ImageBox.getResourceImageWithClasses('resources/Images/General/disclosure-icon.svg', 'title__disclosure-icon title-container__disclosure-icon') + '</div>' + '<div class="header__right-text"></div>';
            };

            var getPickerCellDetail = function getPickerCellDetail(id) {
                var idWithType = '-' + id;
                return $('<div id="' + idWithType + '" class="loxone-picker"/>');
            };

            return {
                getPickerCellHeader: getPickerCellHeader,
                getPickerCellDetail: getPickerCellDetail
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("picker-base-cell");
            this.antiGhostTimer = new AntiGhostTimer();
            this.pickerSetup = this.getPickerConfig(); // This is needed to detect if this cell is a pickercell to collaps the other one

            this.isPickerCell = true;
        }

        viewDidLoad() {
            Debug.GUI.PickerCell && console.log(this.viewId, "viewDidLoad");
            var promise = Q(super.viewDidLoad(...arguments));
            this.force24hours = this.content.force24hours || false;
            this.wheelOrder = this.content.wheelOrder || "hhii";
            this.contentPlaceholder.append(PickerBaseCell.Template.getPickerCellHeader(this.content.iconSrc, this.content.title));
            this.valueLabel = this.element.find(".header__right-text");
            return promise;
        }

        viewWillAppear() {
            Debug.GUI.PickerCell && console.log(this.viewId, "viewWillAppear");
            return Q(super.viewWillAppear(...arguments)).then(function () {
                this.updateLabel(this.getValueAsString(this.content.value)); // We need to prepare the picker in case we had it extended and navigated one level deeper
                // So we can scroll it again

                if (this.isExpanded && this.picker) {
                    this.preparePicker();
                }
            }.bind(this));
        }

        expandableAllowed() {
            return true;
        }

        initDetail(detailElement) {
            Debug.GUI.PickerCell && console.log(this.viewId, "initDetail");
            detailElement.addClass("picker-base-cell__detail");
            this.pickerDetail = PickerBaseCell.Template.getPickerCellDetail(this.element[0].id);
            detailElement.append(this.pickerDetail);
        }

        expandStateChanged(expandState) {
            Debug.GUI.PickerCell && console.log(this.viewId, "expandStateChanged", getStackObj());
            return super.expandStateChanged(...arguments).then(function () {
                Debug.GUI.PickerCell && console.log(this.viewId, "expandStateChanged - passed!");

                if (expandState === ExpandState.Expanding) {
                    // element must be in DOM when initializing mobiscroll
                    this.picker = this.pickerDetail.mobiscroll();
                    this.preparePicker();
                }
            }.bind(this));
        }

        destroyDetail() {
            Debug.GUI.PickerCell && console.log(this.viewId, "destroyDetail");
            this.antiGhostTimer.resetAntiGhostTimer();
            this.picker.mobiscroll('destroy');
            this.picker = null;
        }

        // Public function to set the value of the pickerCell
        setValue(val) {
            if (!this.antiGhostTimer.isAntiGhostTimerActive()) {
                this.content.value = val;
                this.updateLabel(this.getValueAsString(this.content.value));
                this.isExpanded && this.setPickerValue(this.content.value);
            }
        }

        setInvalidValue(val) {
            this.element.toggleClass("invalid", val);
        }

        // Internal

        /**
         * Returns a default picker configuration. Mode is used to differ what type of picker is to be used (scroller
         * is our default, but calendar is also available).
         * @returns {{theme: string, mode: string, display: string, lang: string, onChange}}
         */
        getPickerConfig() {
            return {
                theme: 'loxone',
                mode: 'scroller',
                display: 'inline',
                // will place the picker inside the DIV element (does not work on other elements)
                lang: PlatformComponent.getLanguage().split("-")[0].toLowerCase(),
                // only use lang, not region (region may change/be omitted due to navigator.globalization.getPreferredLanguage)
                onChange: this.onChange.bind(this)
            };
        }

        /**
         * This is needed to collapse other open pickerCell in the same tableView
         */
        toggleExpandState() {
            if (!this.isExpanded) {
                // check for other cells
                for (var sec = 0; sec < this.tableView.table.length; sec++) {
                    for (var row = 0; row < this.tableView.table[sec].length; row++) {
                        var cell = this.tableView.table[sec][row]; // Here we check if it is some kind of PickerCell

                        if (!!cell.isPickerCell) {
                            if (cell !== this && cell.isExpanded) {
                                cell.toggleExpandState();
                            }
                        }
                    }
                }
            }

            return super.toggleExpandState(...arguments);
        }

        /**
         * This merges a given config object and the default Picker Config
         * @param config
         * @returns {{theme: string, mode: string, display: string, lang: *, onChange: *}|*}
         */
        mergePickerConfig(config) {
            if (config) {
                return jQuery.extend({}, this.pickerSetup || {}, config);
            } else {
                return this.pickerSetup;
            }
        }

        /**
         * This is our function to set the time to the PickerCell label.
         * We use .html() because some functions return the HTML-Code "&nbsp;" for " "
         * We look for this character and replace it with "&nbsp;";
         * @param timeString
         */
        updateLabel(timeString) {
            timeString = timeString.replace(/ /g, "&nbsp;");
            GUI.animationHandler.setHtml(this.valueLabel, timeString);
        }

        // This functions needs to be implemented in every subclass

        /**
         * This function initializes the Picker
         */
        preparePicker() {
            throw "preparePicker needs to be implemented in your subclass";
        }

        /**
         * This function will be called once the pickerValue has changed
         * For example if the user turns one of the pickerWheels
         * @param v The value returned by mobiscroll in the given format
         */
        onChange(v) {
            throw "onChange needs to be implemented in your subclass";
        }

        /**
         * This function returns the string for the timeLabel
         * @param val
         */
        getValueAsString(val) {
            throw "getValueAsString needs to be implemented in your subclass";
        }

        /**
         * This sets the given value to the mobiscroll picker
         * @param value This must be in the right format you specified in the picker config.
         */
        setPickerValue(value) {
            throw "setPickerValue needs to be implemented in your subclass";
        }

    }

    GUI.TableViewV2.Cells.PickerBaseCell = PickerBaseCell;
    return GUI;
}(window.GUI || {});
