import globalStyles from "GlobalStyles";
import {View} from "react-native-web";
import {useContext} from "react";
import {AmbientContext} from "./Components";

export default function LxReactSeparator(props) {
    const {isAmbientMode} = useContext(AmbientContext)
    const separatorStyle = isAmbientMode ? globalStyles.customStyles.ambientSeparator : globalStyles.customStyles.separator;
    return <View style={[separatorStyle, props.style]}/>
};

LxReactSeparator.propTypes = {
}
