'use strict';

define("LearningAssistantAddDeviceScreen", function () {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    // Not needed now
    //var currentMiniserverInfo = ActiveMSComponent.getActiveMiniserver(),
    //FILENAME = currentMiniserverInfo.serialNo + "_" + currentMiniserverInfo.activeUser + "_lastSelectedRoomUuid",
    var PASS_INTERVAL_TIMEOUT = 900,
        SUCCESS_CONFIRMATION_TIMEOUT = 1200;
    return class LearningAssistantAddDeviceScreen extends GUI.TableViewScreen {
        //region Static
        static Template = function () {
            var getHeaderTemplate = function getHeaderTemplate(device, searchType) {
                var confirmationLogo = "",
                    deviceImage;

                if (device.isConfirmed) {
                    confirmationLogo = '' + '<div class="icon__confirmation-logo">' + ImageBox.getResourceImageWithClasses(Icon.CHECKED, 'confirmation-logo__icon') + '</div>';
                }

                if (device.imageUrl === Icon.AIR_LOGO || device.imageUrl === Icon.TREE_LOGO) {
                    deviceImage = ImageBox.getResourceImageWithClasses(device.imageUrl, "icon__img icon__img--default");
                } else {
                    deviceImage = '<img class="icon__img" src="' + device.imageUrl + '">';
                }

                return $('<div class="header-element-container">' + //'   ' + getTimeIndicator() +
                    '   <div class="header-element-container__icon">' + deviceImage + confirmationLogo + '   </div>' + '   <div class="header-element-container__title">' + device.name + '</div>' + '   <div class="header-element-container__subTitle">' + device.serialNr.formatStringToSerialNumber(searchType) + '</div>' + '</div>');
            };

            var getTimeIndicator = function getTimeIndicator() {
                return '<div class="header-element-container__time-indicator">' + '   <svg class="progress-ring">' + '       <circle class="progress-ring__circle" stroke="white" stroke-width="4" fill="transparent" r="16" cx="24" cy="24"/>' + '   </svg> ' + '</div>';
            };

            var getButtonsTemplate = function getButtonsTemplate() {
                return $('<div class="bottom-container">' + '   <div class="bottom-container__button-container">' + '       <div class="button-container__prev-device-container">' + '           <button type="button" class="prev-device-container__icon-box">' + '           ' + ImageBox.getResourceImageWithClasses(Icon.DeviceLearning.BUTTONS.PREV, "icon-box__icon") + '           </button>' + '           <div type="button" class="prev-device-container__text">' + _('device-learning.action-buttons.back') + '</div>' + '       </div>' + '       <div class="button-container__submit-device-container button-container__add-replace-button-container">' + '           <button type="button" class="submit-device-container__icon-box">' + '           ' + ImageBox.getResourceImageWithClasses(Icon.DeviceLearning.BUTTONS.ADD, "icon-box__icon") + '           </button>' + '           <div type="button" class="submit-device-container__text">' + _('device-learning.action-buttons.create') + '</div>' + '       </div>' + '       <div class="button-container__replace-device-container button-container__add-replace-button-container">' + '           <button type="button" class="replace-device-container__icon-box">' + '           ' + ImageBox.getResourceImageWithClasses(Icon.DeviceLearning.BUTTONS.REPLACE, "icon-box__icon") + '           </button>' + '           <div type="button" class="replace-device-container__text">' + _('device-learning.action-buttons.replace') + '</div>' + '       </div>' + '       <div class="button-container__next-device-container">' + '           <button type="button" class="next-device-container__icon-box">' + '           ' + ImageBox.getResourceImageWithClasses(Icon.DeviceLearning.BUTTONS.NEXT, "icon-box__icon") + '           </button>' + '           <div type="button" class="next-device-container__text">' + _('device-learning.action-buttons.next') + '</div>' + '       </div>' + '</div>');
            };

            var getTitleBarCounterTemplate = function getTitleBarCounterTemplate() {
                return '<div class="right-side-counter">' + '   <div class="right-side-counter__text-container">' + '       <div class="text-container__text"/>' + '   </div>' + '</div>';
            };

            return {
                getHeaderTemplate: getHeaderTemplate,
                getButtonsTemplate: getButtonsTemplate,
                getTitleBarCounterTemplate: getTitleBarCounterTemplate
            };
        }(); //endregion Static

        constructor(details) {
            super($('<div/>'));
            Debug.DeviceSearch.Assistant && console.log(this.name, "contructor", details);
            this.deviceTypeFilter = details.deviceType;
            this.filterText = details.filterText;
            this.deviceData = {};
            this.room = {};
            this.isNameValid = true;
            this.isPlaceValid = true;
            this.currentDataSet = [];
            this.currentDeviceIndex = 0;
            this.searchType = ActiveMSComponent.getSearchType();
        }

        viewDidLoad() {
            var baseCall = super.viewDidLoad(...arguments);
            Debug.DeviceSearch.Assistant && console.log(this.name, "viewDidLoad");
            this._boundOnKeyPress = this._onKeyPress.bind(this);
            this.currentDataSet = ActiveMSComponent.getDeviceSearchCurrentDeviceList();
            this.filteredSearchResults = this._filterDevices(this.currentDataSet);

            if (this.filteredSearchResults.length) {
                this.setCurrentDevice(this.filteredSearchResults[this.currentDeviceIndex]);
            }

            return baseCall.then(function () {
                Debug.DeviceSearch.Assistant && console.log(this.name, "viewDidLoad (continue after basecall)");
                this.element.append(LearningAssistantAddDeviceScreen.Template.getButtonsTemplate());
                this.elements.rightSideCounterDiv = this.element.find('.text-container__text');
                this.elements.bottomContainer = this.element.find('.bottom-container');
                this.elements.prevButton = this.element.find('.button-container__prev-device-container');
                this.elements.submitButton = this.element.find('.button-container__submit-device-container');
                this.elements.replaceButton = this.element.find('.button-container__replace-device-container');
                this.elements.nextButton = this.element.find('.button-container__next-device-container');
                this.buttons = {
                    prev: new GUI.LxButton(this, this.elements.prevButton[0], Color.BUTTON_GLOW, null, true, 250),
                    submit: new GUI.LxButton(this, this.elements.submitButton[0], Color.BUTTON_GLOW, null, true, 250),
                    replace: new GUI.LxButton(this, this.elements.replaceButton[0], Color.BUTTON_GLOW, null, true, 250),
                    next: new GUI.LxButton(this, this.elements.nextButton[0], Color.BUTTON_GLOW, null, true, 250)
                };
                Object.keys(this.buttons).forEach(function (buttonKey) {
                    this.addToHandledSubviews(this.buttons[buttonKey]);
                    this.buttons[buttonKey].useChildsAsActiveParts("fill");
                }.bind(this));
            }.bind(this));
        }

        setCurrentDevice(device) {
            Debug.DeviceSearch.Assistant && console.log(this.name, "setCurrentDevice", device);
            var def = Q.defer();
            this._currentDevice = device;

            if (this._currentDevice) {
                // reset the locally stored infos, those need to be entered again for the next device
                this.deviceData.name = "";
                this.deviceData.description = "";
                this.deviceData.place = "";
                this._currentDevice.imageUrl = ActiveMSComponent.getImageUrlWithDeviceType(this._currentDevice.type);
                this.deviceCapabilities = this._currentDevice.capabilities;
                ActiveMSComponent.stopDeviceIdentify(this._currentDevice.extension).finally(function () {
                    ActiveMSComponent.setDeviceIdentify(this._currentDevice.extension, this._currentDevice.serialNr).finally(function () {
                        def.resolve();
                    });
                }.bind(this));
            } else {
                def.resolve();
            }

            return def.promise.then(this._updateAddReplaceButtons.bind(this));
        }

        viewWillAppear() {
            return Q(super.viewWillAppear(...arguments) || true).then(function () {
                Debug.DeviceSearch.Assistant && console.log(this.name, "viewWillAppear");
                this.unregisteDeviceStateFn = ActiveMSComponent.registerForDeviceLearningCounterStateChange(this._processLiveLearnStateChange.bind(this));
                this.buttons.prev.onButtonTapped = this._showPrevDevice.bind(this);
                this.buttons.prev.onButtonHit = this._startPassInterval.bind(this, this._showPrevDevice.bind(this));
                this.buttons.prev.onButtonReleased = this._stopPassInterval.bind(this);
                this.buttons.submit.onButtonTapped = this._createDevice.bind(this);
                this.buttons.replace.onButtonTapped = this._replaceDevice.bind(this);
                this.buttons.next.onButtonTapped = this._showNextDevice.bind(this);
                this.buttons.next.onButtonHit = this._startPassInterval.bind(this, this._showNextDevice.bind(this));
                this.buttons.next.onButtonReleased = this._stopPassInterval.bind(this);
                window.addEventListener("keydown", this._boundOnKeyPress);

                this._setCounterDivText();

                this._processLiveLearnStateChange(ActiveMSComponent.getDeviceSearchCurrentDeviceList());
            }.bind(this));
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);
            Debug.DeviceSearch.Assistant && console.log(this.name, "viewDidAppear");
            this.swipeHandlers = [];
            var swipeOptions = {
                swipeVelocityX: 0.1
            };
            this.swipeHandlers.push(Hammer(this.element[0], swipeOptions).on('swipeleft', this._showNextDevice.bind(this)));
            this.swipeHandlers.push(Hammer(this.element[0], swipeOptions).on('swiperight', this._showPrevDevice.bind(this)));
        }

        viewWillDisappear() {
            Debug.DeviceSearch.Assistant && console.log(this.name, "viewWillDisappear");

            for (var i = 0; i < this.swipeHandlers.length; i++) {
                this.swipeHandlers[i].dispose();
            }

            this.swipeHandlers = [];
            this.unregisteDeviceStateFn();
            window.removeEventListener("keydown", this._boundOnKeyPress);
            super.viewWillDisappear(...arguments);
        }

        _onKeyPress(event) {
            switch (event.key) {
                case "ArrowLeft":
                    this._showPrevDevice();

                    break;

                case "ArrowRight":
                    this._showNextDevice();

                    break;
            }
        }

        destroy() {
            Debug.DeviceSearch.Assistant && console.log(this.name, "destroy");
            this._currentDevice && ActiveMSComponent.stopDeviceIdentify(this._currentDevice.extension);
            super.destroy(...arguments);
        }

        titleBarText() {
            return _('device-learning.add-device.title');
        }

        titleBarConfig() {
            return {
                rightSide: TitleBarCfg.CUSTOM_CONTENT,
                rightSideDiv: LearningAssistantAddDeviceScreen.Template.getTitleBarCounterTemplate()
            };
        }

        getUrl() {
            return "addDeviceScreen";
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        // TableView DataSource Methods
        styleForTableView() {
            return TableViewStyle.COMFORT_MODE_2020;
        }

        updateView(details) {
            Debug.DeviceSearch.Assistant && console.log(this.name, "updateView", details);

            switch (details.type) {
                case DeviceManagement.SELECTOR_TYPES.ROOM:
                    this.selectedRoomName = details.value.name;
                    this.deviceData.roomUuid = details.value.uuid;
                    break;

                case DeviceManagement.SELECTOR_TYPES.SWITCHBOARD:
                    this.selectedSwitchboardName = details.value.name;
                    this.deviceData.switchboard = details.value.name;
                    this.savedSwitchboardsDataset = details.dataset;
                    break;

                case DeviceManagement.SELECTOR_TYPES.LIGHT_GROUP:
                    this.selectedLightGroupName = details.value.name;
                    this.deviceData.groupID = details.value.uuid;
                    break;

                case DeviceManagement.SELECTOR_TYPES.HEAT_SWITCHBOARD:
                    this.selectedHeatSwitchboardName = details.value.name; // Same handling as switchboard in config

                    this.deviceData.switchboard = details.value.name;
                    this.savedHeatSwitchboardsDataset = details.dataset;
                    break;

                case DeviceManagement.REPLACE_SUCCESSFUL:
                    this._showConfirmationOf(this._currentDevice, true);

                    break;
            }

            this.reloadTable();
        }

        reloadTable() {
            Debug.DeviceSearch.Assistant && console.log(this.name, "reloadTable");
            var args = arguments,
                tempTableContent = [];
            this.elements.rightSideCounterDiv && this._setCounterDivText();

            if (this._shouldShowConfirmation()) {
                tempTableContent = this._getConfirmationContent();
            } else if (this.filteredSearchResults.length) {
                tempTableContent.push(this._getHeaderElement());
                tempTableContent.push(this._getNameSection());
                tempTableContent.push(this._getPlaceSection());
                tempTableContent.push(this._getExtendedPlaceSection());
                tempTableContent.push(this._getSettingsSection());
            }

            return Q.all(tempTableContent).then(res => {
                this.tableContent = res.filter(function (section) {
                    return !!section;
                });
                return Q(super.reloadTable(...args) || true).then(function () {
                    this._setBackgroundView();
                }.bind(this));
            });
        }

        _setBackgroundView() {
            var config, bgView;

            if (this.filteredSearchResults.length || this._shouldShowConfirmation()) {
                this.tableView && this.tableView.setBackgroundView(null);
            } else {
                this.setCurrentDevice(null);

                if (this.currentDataSet.length > 0) {
                    config = {
                        iconSrc: Icon.INDICATOR_BOLD,
                        title: _('device-learning.empty-screen.filter-active.title'),
                        message: _('device-learning.empty-screen.filter-active.message', {
                            count: this.currentDataSet.length
                        }),
                        buttonText: _('device-learning.empty-screen.filter-active.button-title'),
                        buttonAction: function () {
                            this.deviceTypeFilter = DeviceManagement.DEVICE_TYPE_ALL;
                            this.filterText = null;
                            this.filteredSearchResults = this._filterDevices(this.currentDataSet);
                            this.setCurrentDevice(this.filteredSearchResults[0]);
                            this.reloadTable();
                        }.bind(this),
                        noCircle: true
                    };
                    bgView = new GUI.BackgroundView(config.iconSrc, config.title, config.message, config.buttonText, config.buttonAction, config.noCircle);
                    this.tableView && this.tableView.setBackgroundView(bgView);
                } else {
                    config = {
                        iconSrc: Icon.INDICATOR_BOLD,
                        title: _('device-learning.searching.title'),
                        message: _('device-learning.searching.message'),
                        noCircle: true
                    };
                    bgView = new GUI.BackgroundView(config.iconSrc, config.title, config.message, null, null, config.noCircle);
                    this.tableView && this.tableView.setBackgroundView(bgView);
                }
            }
        }

        _processLiveLearnStateChange(allResults) {
            Debug.DeviceSearch.Assistant && console.log(this.name, "_processLiveLearnStateChange", allResults);
            this.currentDataSet = allResults;
            this.filteredSearchResults = this._filterDevices(allResults);

            this._updateScreen();
        }

        /**
         * Updates all of it, not only the table view.
         * @private
         */
        _updateScreen() {
            var previousDevice = this._currentDevice,
                currentDevice,
                deviceOnSameIndex,
                needsReload = true;

            this._updateAddReplaceButtons();

            if (this._shouldShowConfirmation()) {// keep everything as is.
            } else if (this.filteredSearchResults.length) {
                if (this._currentDevice) {
                    // look up the current in the new result list.
                    currentDevice = this.filteredSearchResults.find(function (device) {
                        return device.serialNr === this._currentDevice.serialNr;
                    }.bind(this));
                }

                if (currentDevice) {
                    // still exists - nothing to do.
                    needsReload = false;
                } else {
                    // device has dropped out of search results, check if on the same index a new device exists
                    deviceOnSameIndex = this.filteredSearchResults[this.currentDeviceIndex];
                    HapticFeedback(HapticFeedback.STYLE.SELECT);

                    if (deviceOnSameIndex) {
                        this.setCurrentDevice(deviceOnSameIndex);
                    } else {
                        this.currentDeviceIndex--;
                        this.setCurrentDevice(this.filteredSearchResults[this.currentDeviceIndex]);
                    }
                }
            } // update the counter on the screen


            this.elements.rightSideCounterDiv && this._setCounterDivText();

            if (needsReload) {
                this._handleCurrentDeviceChanged(previousDevice).then(function () {
                    this.reloadTable();
                    this.tableView.resetScrollTop();
                }.bind(this));
            }
        }

        /**
         * Called when the currently shown device is dropped from the list and another device is shown.
         * @param previousDevice        the device that has been shown until now.
         * @private
         */
        _handleCurrentDeviceChanged(previousDevice) {
            Debug.DeviceSearch.Assistant && console.log(this.name, "_handleCurrentDeviceChanged: ", previousDevice);
            var resultPromise = Q.resolve(true),
                lastPairedSnr = ActiveMSComponent.getLastPairedDevicSerial();

            if (previousDevice && previousDevice.serialNr !== lastPairedSnr) {
                resultPromise = NavigationComp.showPopup({
                    title: _("device-learning.not-reachable.title"),
                    message: _("device-learning.not-reachable.message", {
                        device: previousDevice.name,
                        serialNr: previousDevice.serialNr.formatStringToSerialNumber(this.searchType)
                    }),
                    buttonOk: true,
                    buttonCancel: false
                });
            } else {
                // previous device successfully paired, don't show.
                Debug.DeviceSearch.Assistant && console.log("   last successfully paired: " + lastPairedSnr);
                Debug.DeviceSearch.Assistant && console.log("            previous device: ", previousDevice);
            }

            return resultPromise;
        }

        _getHeaderElement(device) {
            var headerElementDevice = device || this._currentDevice;
            return Q({
                headerElement: LearningAssistantAddDeviceScreen.Template.getHeaderTemplate(headerElementDevice, this.searchType)
            });
        }

        _getNameSection() {
            var section = {
                rows: []
            };
            section.rows.push({
                type: GUI.TableViewV2.CellType.INPUT,
                content: {
                    value: this.deviceData.name,
                    title: _('device-learning.designation'),
                    validationRegex: Regex.NAME,
                    filterRegex: Regex.NAME_FILTER
                },
                textChanged: this._handleDesignationTextChange.bind(this),
                submitText: this._handleDesignationTextChange.bind(this)
            });

            if (Feature.LEARN_DEVICE_OVER_APP_REMAKE) {
                section.rows.push({
                    type: GUI.TableViewV2.CellType.INPUT,
                    content: {
                        value: this.deviceData.description,
                        placeholder: this._currentDevice.description || "",
                        title: _('description')
                    },
                    textChanged: this._handleDescriptionTextChange.bind(this),
                    submitText: this._handleDescriptionTextChange.bind(this)
                });
            }

            return Q(section);
        }

        _getPlaceSection() {
            var section = {
                headerBoldTitle: _('battery-monitor.device.assembly-place'),
                rows: []
            };
            section.rows.push({
                content: {
                    title: _('room'),
                    disclosureIcon: true,
                    disclosureText: this.selectedRoomName || _('not-used'),
                    clickable: true
                },
                action: function () {
                    this.ViewController.showState(ScreenState.DeviceLearningSelectorScreen, null, {
                        type: DeviceManagement.SELECTOR_TYPES.ROOM,
                        roomUuid: this.deviceData && this.deviceData.roomUuid
                    });
                }.bind(this)
            });

            if (hasBit(this.deviceCapabilities, DeviceManagement.CAPABILITIES.PLACE)) {
                section.rows.push({
                    type: GUI.TableViewV2.CellType.INPUT,
                    content: {
                        title: _('battery-monitor.device.assembly-place'),
                        value: this.deviceData.place || "",
                        validationRegex: Regex.NAME,
                        filterRegex: Regex.NAME_FILTER
                    },
                    textChanged: this._handlePlaceTextChange.bind(this),
                    submitText: this._handlePlaceTextChange.bind(this)
                });
            }

            return Q(section);
        }

        _getExtendedPlaceSection() {
            var section = {
                    rows: []
                },
                isRegularSwitchboard = false,
                dataDef = Q.defer(),
                sectionDef = Q.defer(),
                cmd;

            if (Feature.LEARN_DEVICE_OVER_APP_REMAKE) {
                if (hasBit(this.deviceCapabilities, DeviceManagement.CAPABILITIES.SWITCHBOARD)) {
                    isRegularSwitchboard = hasBit(this.deviceCapabilities, DeviceManagement.CAPABILITIES.SB_ROW);

                    if (isRegularSwitchboard) {
                        cmd = Commands.DEVICE_MANAGEMENT.GET_SWITCHBOARDS;

                        if (this.savedSwitchboardsDataset) {
                            dataDef.resolve(this.savedSwitchboardsDataset);
                        } else {
                            SandboxComponent.sendWithPermission(cmd, MsPermission.DEVICE_MANAGEMENT).then(function (res) {
                                this.savedSwitchboardsDataset = getLxResponseValue(res);
                                dataDef.resolve(this.savedSwitchboardsDataset);
                            }.bind(this));
                        }
                    } else {
                        cmd = Commands.DEVICE_MANAGEMENT.GET_HEAT_SWITCHBOARDS;

                        if (this.savedHeatSwitchboardsDataset) {
                            dataDef.resolve(this.savedHeatSwitchboardsDataset);
                        } else {
                            SandboxComponent.sendWithPermission(cmd, MsPermission.DEVICE_MANAGEMENT).then(function (res) {
                                this.savedHeatSwitchboardsDataset = getLxResponseValue(res);
                                dataDef.resolve(this.savedHeatSwitchboardsDataset);
                            }.bind(this));
                        }
                    }

                    dataDef.promise.then(function (res) {
                        section.rows.push(this._getSwitchboardRow(isRegularSwitchboard, res));

                        if (hasBit(this.deviceCapabilities, DeviceManagement.CAPABILITIES.SB_ROW)) {
                            section.rows.push({
                                type: GUI.TableViewV2.CellType.INPUT,
                                content: {
                                    title: _('device-learning.row'),
                                    placeholder: DeviceManagement.MIN_SB_POS,
                                    type: GUI.LxInputEnum.Type.TELEPHONE,
                                    validationRegex: Regex.INT_VALUE,
                                    value: this.deviceData.row,
                                    autofocus: false,
                                    numberMin: DeviceManagement.MIN_SB_POS,
                                    numberMax: DeviceManagement.MAX_SB_POS
                                },
                                textChanged: function (section, row, tableView, value, valid) {
                                    if (valid) {
                                        this.deviceData.row = value;
                                    }
                                }.bind(this)
                            });
                        }

                        if (hasBit(this.deviceCapabilities, DeviceManagement.CAPABILITIES.SB_POS)) {
                            section.rows.push({
                                type: GUI.TableViewV2.CellType.INPUT,
                                content: {
                                    title: _('device-learning.position'),
                                    placeholder: DeviceManagement.MIN_SB_POS,
                                    type: GUI.LxInputEnum.Type.TELEPHONE,
                                    validationRegex: Regex.INT_VALUE,
                                    value: this.deviceData.column,
                                    autofocus: false,
                                    numberMin: DeviceManagement.MIN_SB_POS,
                                    numberMax: DeviceManagement.MAX_SB_POS
                                },
                                textChanged: function (section, row, tableView, value, valid) {
                                    if (valid) {
                                        this.deviceData.column = value;
                                    }
                                }.bind(this)
                            });
                        }

                        sectionDef.resolve(section);
                    }.bind(this));
                } else {
                    sectionDef.resolve(null);
                }
            } else {
                sectionDef.resolve(null);
            }

            return sectionDef.promise;
        }

        _getSettingsSection() {
            var section = {
                headerBoldTitle: _('settings'),
                rows: []
            };

            if (this._currentDevice.hasBaseProgramming || hasBit(this.deviceCapabilities, DeviceManagement.CAPABILITIES.AUTO_CONFIG)) {
                section.rows.push({
                    type: GUI.TableViewV2.CellType.SWITCH,
                    content: {
                        title: _('device-learning.auto-config.title'),
                        active: this.deviceData.autoCreate
                    },
                    onSwitchChanged: function onSwitchChanged(value) {
                        this.deviceData.autoCreate = value;
                    }.bind(this)
                });
            }

            if (Feature.LEARN_DEVICE_OVER_APP_REMAKE && hasBit(this.deviceCapabilities, DeviceManagement.CAPABILITIES.LIGHT_GROUP)) {
                section.rows.push(this._getLightGroupRow());
            }

            if (section.rows.length > 0) {
                return Q(section);
            }
        }

        _getSwitchboardRow(isRegularSwitchboard, result) {
            var row, title, disclosureText, details;

            if (isRegularSwitchboard) {
                title = _('device-learning.switchboard');
                disclosureText = this.selectedSwitchboardName || result[0].name;
                details = {
                    type: DeviceManagement.SELECTOR_TYPES.SWITCHBOARD,
                    switchboardName: this.deviceData && this.deviceData.switchboard,
                    switchboards: result
                };
            } else {
                // create a default switchboard if none exists
                if (!result.length) {
                    result.push({
                        name: _('device-learning.heat-switchboard')
                    });
                }

                title = _('device-learning.heat-switchboard');
                disclosureText = this.selectedHeatSwitchboardName || result[0].name;
                details = {
                    type: DeviceManagement.SELECTOR_TYPES.HEAT_SWITCHBOARD,
                    heatSwitchboardName: this.deviceData && this.deviceData.switchboard,
                    heatSwitchboards: result
                };
            }

            row = {
                content: {
                    title: title,
                    disclosureText: disclosureText,
                    disclosureIcon: true,
                    clickable: true
                },
                action: function () {
                    this.ViewController.showState(ScreenState.DeviceLearningSelectorScreen, null, details);
                }.bind(this)
            };
            return row;
        }

        _getLightGroupRow() {
            var lightType;
            /*if (hasBit(this.deviceCapabilities, DeviceManagement.CAPABILITIES.LIGHT_GROUP_WW)) {
                lightType = 1;
            } else if (hasBit(this.deviceCapabilities, DeviceManagement.CAPABILITIES.LIGHT_GROUP_RGBW)) {
                lightType = 2;
            }*/

            return {
                content: {
                    title: _('device-learning.light-group'),
                    disclosureIcon: true,
                    disclosureText: this.selectedLightGroupName || "",
                    clickable: true
                },
                action: function () {
                    this.ViewController.showState(ScreenState.DeviceLearningSelectorScreen, null, {
                        type: DeviceManagement.SELECTOR_TYPES.LIGHT_GROUP,
                        lightgroup: this.deviceData && this.deviceData.groupID,
                        roomUuid: this.deviceData && this.deviceData.roomUuid //lightType: lightType

                    });
                }.bind(this)
            };
        }

        _handleDesignationTextChange(section, row, tableView, value, valid, valueDidChange) {
            this.deviceData.name = value;

            if (!value) {
                valid = true;
            }

            this.isNameValid = valid;
            this.buttons.submit.setEnabled(this.isNameValid && this.isPlaceValid);
        }

        _handleDescriptionTextChange(section, row, tableView, value, valid, valueDidChange) {
            this.deviceData.description = value;
        }

        _handlePlaceTextChange(section, row, tableView, value, valid, valueDidChange) {
            this.deviceData.place = value;

            if (!value) {
                valid = true;
            }

            this.isPlaceValid = valid;
            this.buttons.submit.setEnabled(this.isNameValid && this.isPlaceValid);
        }

        _showPrevDevice() {
            HapticFeedback();

            this._showDevice(this.currentDeviceIndex - 1);
        }

        _showNextDevice() {
            HapticFeedback();

            this._showDevice(this.currentDeviceIndex + 1);
        }

        _showDevice(newPos) {
            Debug.DeviceSearch.Assistant && console.log(this.name, "_showDevice: " + newPos);
            var def = Q.defer(),
                filteredSearchResultsLength = this.filteredSearchResults.length;

            if (filteredSearchResultsLength > 1 && !this._showDeviceDef) {
                this._showDeviceDef = Q.defer();

                if (newPos < 0) {
                    newPos = this.filteredSearchResults.length - 1;
                } else if (newPos > this.filteredSearchResults.length - 1) {
                    newPos = 0;
                }

                this.currentDeviceIndex = Math.max(newPos, 0);
                this.currentDeviceIndex = Math.min(newPos, this.filteredSearchResults.length - 1);
                def.resolve(NavigationComp.showWaitingFor(this.setCurrentDevice(this.filteredSearchResults[this.currentDeviceIndex])).then(function () {
                    return this.reloadTable().then(function () {
                        // ensure the screen is scrolled all the way to the top, so the user knows sth has changed.
                        this.tableView.resetScrollTop();

                        this._showDeviceDef.resolve();

                        this._showDeviceDef = null;
                    }.bind(this));
                }.bind(this)));
            }

            return def.promise;
        }

        _replaceDevice() {
            if (this._currentDevice) {
                if (this._hasDevicesToReplace) {
                    this.ViewController.showState(ScreenState.DeviceReplace, null, {
                        selectedDevice: this._currentDevice
                    });
                } else {
                    NavigationComp.showPopup({
                        title: _('device-learning.replace.no-device.title'),
                        message: _('device-learning.replace.no-device.message'),
                        buttonOk: true
                    });
                }
            }
        }

        _createDevice() {
            if (this._shouldShowConfirmation() || !this._currentDevice) {
                return; // nothing to create or currently disabled.
            }

            if (!this.deviceData.name) {
                // no name was provided by user, use name provided by the search result (= userfriendly device type)
                this.deviceData.name = this._currentDevice.name;
            }

            var promise = ActiveMSComponent.createNewDevice(this._currentDevice.extension, this._currentDevice.serialNr, this.deviceCapabilities, this.deviceData);
            return NavigationComp.showWaitingFor(promise).then(this._handlePromise.bind(this), this._handlePromise.bind(this));
        }

        _handlePromise(res) {
            Debug.DeviceSearch.Assistant && console.log(this.name, "_handlePromise: " + JSON.stringify(res));
            return this._showInformationPopup(res.LL.Code);
        }

        _showInformationPopup(code) {
            switch (parseInt(code)) {
                case ResponseCode.OK:
                    this._showConfirmationOf(cloneObject(this._currentDevice), false);

                    break;

                case DeviceManagement.DEVICE_ALREADY_IN_USE:
                    NavigationComp.showPopup({
                        title: _('device-learning.failed.title'),
                        message: _('device-learning.failed.message.serial-exists'),
                        buttonOk: _('finish'),
                        buttonCancel: false,
                        icon: Icon.CAUTION,
                        color: window.Styles.colors.orange
                    });
                    break;

                default:
                    NavigationComp.showPopup({
                        title: _('device-learning.failed.title'),
                        message: _('device-learning.failed.message.other-error'),
                        buttonOk: _('finish'),
                        buttonCancel: false,
                        icon: Icon.CAUTION,
                        color: window.Styles.colors.orange
                    });
                    break;
            }
        }

        _filterDevices(allDevices) {
            var filteredDevices = [],
                filterDataSet,
                returnArr = [];

            if (this.filterText) {
                filteredDevices = filteredDevices.concat(allDevices.filter(function (device) {
                    if (device.name.toLowerCase().indexOf(this.filterText) !== -1 || device.serialNr.toLowerCase().indexOf(this.filterText) !== -1 || device.serialNr.formatStringToSerialNumber(this.searchType).toLowerCase().indexOf(this.filterText) !== -1) {
                        return device;
                    }
                }.bind(this)));
            }

            filterDataSet = !!this.filterText ? filteredDevices : allDevices;

            if (this.deviceTypeFilter) {
                if (this.deviceTypeFilter.includes(DeviceManagement.DEVICE_TYPE_ALL)) {
                    returnArr = filterDataSet;
                } else {
                    filterDataSet.forEach(function (device) {
                        if (!returnArr.includes(device)) {
                            if (device.type === this.deviceTypeFilter) {
                                returnArr = returnArr.concat(device);
                            }
                        }
                    }.bind(this));
                }
            } else {
                returnArr = filterDataSet;
            }

            return returnArr;
        }

        _startPassInterval(func) {
            if (!this.passInterval) {
                this.passInterval = setInterval(function () {
                    func();
                }.bind(this), PASS_INTERVAL_TIMEOUT);
            }
        }

        _stopPassInterval() {
            clearInterval(this.passInterval);
            this.passInterval = null;
        }

        _setCounterDivText() {
            var text, currentCount;

            if (this._shouldShowConfirmation()) {// leave untouched
            } else if (this._currentDevice) {
                currentCount = this.filteredSearchResults.length ? this.currentDeviceIndex + 1 : this.currentDeviceIndex;
                text = addLeadingZerosToNumber(currentCount, 2) + " / " + addLeadingZerosToNumber(this.filteredSearchResults.length, 2);
            } else {
                text = "00/00";
            }

            this.elements.rightSideCounterDiv.text(text);
        }

        _handleRowClicked(fn, cell, sectionIdx, rowIdx) {
            this.lastSelectedSectionIdx = sectionIdx;
            this.lastSelectedRowIdx = rowIdx;
            fn();
        }

        _updateAddReplaceButtons() {
            Debug.DeviceSearch.General && console.log(this.name, "_updateAddReplaceButtons");
            var prms,
                addButtonAvailable = this._currentDevice && !this._shouldShowConfirmation();

            if (this._currentDevice) {
                prms = ActiveMSComponent.getDevicesOfExtensions(this._currentDevice.type).then(function (result) {
                    Debug.DeviceSearch.General && console.log(this.name, "   num devices of type " + this._currentDevice.type + ": " + result.length);
                    return result.length > 0;
                }.bind(this));
            } else {
                // no devices left - disable.
                prms = Q(false);
            }

            Debug.DeviceSearch.General && console.log(this.name, "   addButton: " + addButtonAvailable);
            this.elements.submitButton.toggleClass('add-replace-button-disabled', !addButtonAvailable);
            prms.then(function (hasDevicesToReplace) {
                this._hasDevicesToReplace = hasDevicesToReplace;
                Debug.DeviceSearch.General && console.log(this.name, "   replaceButton: " + hasDevicesToReplace);
                this.elements.replaceButton.toggleClass('add-replace-button-disabled', !this._hasDevicesToReplace);
            }.bind(this));
        }

        //--------------------------------------------------------------------------
        //-------------------  Confirmation Handling -------------------------------
        //--------------------------------------------------------------------------
        _shouldShowConfirmation() {
            console.log(this.name, "_shouldShowConfirmation " + (this._showConfirmation ? "true" : "false"));
            return this._showConfirmation === true;
        }

        _showConfirmationOf(confirmedDevice, isReplace) {
            console.log(this.name, "_showConfirmationOf " + JSON.stringify(confirmedDevice));
            this._confirmedDevice = cloneObject(confirmedDevice);
            this._confirmedDevice.isConfirmed = true;
            this._showConfirmation = true;
            this._confirmedReplace = isReplace;

            this._updateScreen();

            clearTimeout(this._hideConfirmationTimeout);
            this._hideConfirmationTimeout = setTimeout(this._hideConfirmation.bind(this), SUCCESS_CONFIRMATION_TIMEOUT);
        }

        _hideConfirmation() {
            console.log(this.name, "_hideConfirmation");

            this._stopHideConfirmationTimeout();

            this._showConfirmation = false;

            this._updateScreen();
        }

        _stopHideConfirmationTimeout() {
            clearTimeout(this._hideConfirmationTimeout);
            this._hideConfirmationTimeout = null;
        }

        _getConfirmationContent() {
            console.log(this.name, "_getConfirmationContent");
            var content = [];
            content.push(this._getHeaderElement(this._confirmedDevice));
            content.push(this._getConfirmSection());
            return content;
        }

        _getConfirmSection() {
            var section = {
                rows: []
            };
            var title;

            if (this._confirmedReplace) {
                title = _('device-learning.replace-successful');
            } else {
                title = _('device-learning.successful.title');
            }

            section.rows.push({
                type: GUI.TableViewV2.CellType.CENTER,
                content: {
                    title: title,
                    clickable: true,
                    textColor: window.Styles.colors.green
                },
                action: this._hideConfirmation.bind(this)
            });
            return Q(section);
        }

    };
});
