import * as React from "react"
import Svg, { Path } from "react-native-svg"

function File(props) {
    return (
        <Svg
            isreact="true"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M5 1a2 2 0 00-2 2v18a2 2 0 002 2h14a2 2 0 002-2V8.828a2 2 0 00-.586-1.414l-5.828-5.828A2 2 0 0013.172 1H5zm-.5 2a.5.5 0 01.5-.5h8V7a2 2 0 002 2h4.5v12a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5V3zm13.88 4.5H15a.5.5 0 01-.5-.5V3.62l3.88 3.88z"
            />
        </Svg>
    )
}

export default File
