import {View} from "react-native";
import {LxReactText} from "LxComponents";
import globalStyles from "GlobalStyles";
import {useRef, useState} from "react";

function LxTrackerCell({entries}) {
    const columnWidthInfo = useRef({
        itemsCompared: 0,
        maxWidth: useAmPm() ? 115 : 80
    }).current;

    const TrackerEntry = ({entry, key}) => {
        let date = entry.timestamp.format(LxDate.getTimeFormat());
        let text = entry.text.split(SEPARATOR_SYMBOL).map(line => {
            return <LxReactText>{line}</LxReactText>
        });

        return <View style={styles.outerContainer}>
            <View style={styles.innerContainer}>
                <View style={{minWidth: columnWidthInfo.maxWidth, maxWidth: columnWidthInfo.maxWidth}}>
                    <LxReactText style={styles.dateLabel}>{date}</LxReactText>
                </View>
                <LxReactText style={styles.messageLabel}>{text}</LxReactText>
            </View>
        </View>
    }

    return <View style={styles.container}>
        {entries && entries.map((entry, idx) => <TrackerEntry entry={entry} key={idx}/>)}
    </View>
}

const SEPARATOR_SYMBOL = '\x14';

const styles = {
    outerContainer: {
        justifyContent: "center",
        width: "100%",
        marginVertical: 14
    },
    innerContainer: {
        flexDirection: "row"
    },
    container: {
        marginVertical: globalStyles.spacings.gaps.small,
        width: "100%"
    },
    dateLabel: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.secondary,
        marginRight: globalStyles.spacings.gaps.verySmall,
        display: "flex"
    },
    messageLabel: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.primary,
        paddingHorizontal: globalStyles.spacings.gaps.verySmall,
        maxWidth: "100%",
        textAlign: "left",
        flexDirection: "column",
        display: "flex"
    }
}

export default LxTrackerCell
