'use strict';
/**
 * Prevents the TableContent for the AudioZoneControlContent
 */

define('AudioZoneShortcutHelper', [], function () {
    return class AudioZoneShortcutHelper {
        //region Static
        static MAX_SHORTCUTS = 20;

        static get control() {
            return MediaServerComp.getActiveZoneControl();
        }

        static registerForShortcutChanges(clbk, preventInitial) {
            PersistenceComponent.registerForSharedKeyChange(MediaEnum.CUSTOMIZATION_KEYS.SHORTCUTS, clbk, preventInitial);
        }

        static unregisterForShortcutChanges(clbk) {
            PersistenceComponent.unregisterFromSharedKeyChange(MediaEnum.CUSTOMIZATION_KEYS.SHORTCUTS, clbk);
        }

        static saveShortcuts(item) {
            var def = Q.defer();
            this.getShortcuts().done(function (res) {
                var control = MediaServerComp.getActiveZoneControl();

                if (res[control.uuidAction]) {
                    if (res[control.uuidAction].length < this.MAX_SHORTCUTS) {
                        res[control.uuidAction].push(item);
                        PersistenceComponent.setShared(MediaEnum.CUSTOMIZATION_KEYS.SHORTCUTS, res);
                        def.resolve();
                    } else {
                        def.reject();
                        return NavigationComp.showPopup({
                            title: _("media.item.add-as-shortcut.warning.max.title"),
                            message: _("media.item.add-as-shortcut.warning.max.description"),
                            buttonOk: true,
                            buttonCancel: false,
                            icon: Icon.WARNING,
                            color: window.Styles.colors.orange
                        }, PopupType.GENERAL);
                    }
                } else {
                    res[control.uuidAction] = [item];
                    PersistenceComponent.setShared(MediaEnum.CUSTOMIZATION_KEYS.SHORTCUTS, res);
                    def.resolve();
                }
            }.bind(this));
            def.promise.then(function () {
                PersistenceComponent.synchronizeShared(); // Directly send the change to the Miniserver

                if (item.name || item.title) {
                    MediaServerComp.showConfirmationFeedback(_("media.item.add-to-shortcuts.confirmation.specific", {
                        name: item.name || item.title
                    }));
                } else {
                    MediaServerComp.showConfirmationFeedback(_("media.item.add-to-shortcuts.confirmation.generic"));
                }
            });
        }

        static getShortcuts(uuidAction) {
            return PersistenceComponent.getShared(MediaEnum.CUSTOMIZATION_KEYS.SHORTCUTS).then(function (res) {
                res = res || {};

                if (uuidAction) {
                    return this._sanitizeShortcuts(res[uuidAction] || []);
                } else {
                    Object.values(res).forEach(function (shortcuts) {
                        this._sanitizeShortcuts(shortcuts);
                    }.bind(this));
                    return res;
                }
            }.bind(this), function () {
                return {}; // There is no problem, if the file doesn't exists
                // We don't have to throw an error
            }.bind(this));
        }

        static removeShortcut(shortcut) {
            return this.getShortcuts().then(function (shortcuts) {
                var control = MediaServerComp.getActiveZoneControl();

                if (shortcuts[control.uuidAction]) {
                    var wantedShortcut = shortcuts[control.uuidAction].find(function (searchingShortcut) {
                            return JSON.stringify(searchingShortcut) === JSON.stringify(shortcut);
                        }),
                        shortcutIdx = shortcuts[control.uuidAction].indexOf(wantedShortcut);
                    shortcuts[control.uuidAction].splice(shortcutIdx, 1);
                    PersistenceComponent.setShared(MediaEnum.CUSTOMIZATION_KEYS.SHORTCUTS, shortcuts);
                    return PersistenceComponent.synchronizeShared().then(function () {
                        MediaServerComp.showConfirmationFeedback(_("media.item.add-as-shortcut.removed"));
                    });
                } else {
                    return true;
                }
            }.bind(this));
        }

        static _sanitizeShortcuts(shortcuts) {
            return shortcuts.map(function (shortcut) {
                if (shortcut.contentType === MediaEnum.MediaContentType.LINEIN) {
                    delete shortcut.config;
                }

                return shortcut;
            });
        } //endregion Static


        constructor() {
        }

    };
});
