'use strict';
/*
groupUUID
groupName
imageName
color
regularCellBg     boolean, default is false - if true, the regular cell background is being used.
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.GROUP_CELL = "GroupCell";

    class GroupCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getTemplate = function getCellTemplate(name) {
                return $('<div class="content__icon" />' + '<div class="content__name text-overflow-ellipsis clickable">' + name + '</div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("group-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.contentPlaceholder.append(GroupCell.Template.getTemplate(this.content.groupName));
            this.element.attr("id", "group-cell-" + this.content.groupUUID);
            var imagePlaceholder = this.contentPlaceholder.find(".content__icon");
            ImageBox.getImage(this.content.imageName).then(function (image) {
                if (image.mimeType === "svg") {
                    var imageElem = $(image.image);
                    imageElem.attr('class', 'icon-container__icon');
                    imageElem.css('fill', this.content.color);
                    imagePlaceholder.append(imageElem);
                } else {
                    imagePlaceholder.append('<img class="icon-container__icon" src="' + image.image + '"/>');
                }
            }.bind(this));

            if (this.content.regularCellBg) {
                this.element.addClass("group-cell--regular-cell-bg");
            }
        }

        clickableAllowed() {
            return true;
        }

        onSelected() {
            //console.log("onSelected:", this.content.groupName);
            this.element.addClass("group-cell--selected"); // add another (immediately) class (darker font)

            super.onSelected(...arguments);
        }

        setSelected(selected) {
            //console.log("setSelected:", this.content.groupName, selected);
            this.element.toggleClass("group-cell--selected", selected);
            super.setSelected(...arguments);
        }

    }

    GUI.TableViewV2.Cells.GroupCell = GroupCell;
    return GUI;
}(window.GUI || {});
