'use strict';

define("EnergyFlowMonitorControl", [
    "Control", "./efmUtilities.js", "./content/energyFlowMonitorControlContent.jsx", "IconLib"
], function (Control, EfmUtilities, CtrlContent, { default: Icons }) {
    return class EnergyFlowMonitorControl extends Control {
        constructor() {
            super(...arguments);
        }

        _getUnitFromFormat(format) {
            let splitTexts = lxUnitConverter.convertSplitAndApply(format, 1, true);
            return splitTexts.succTxt.replaceAll(" ", "");
        }

        getStatisticGroupForOutput(outputName) {
            let superValue = super.getStatisticGroupForOutput(outputName),
                formatGetter = `${outputName}Format`;
            if (superValue && formatGetter in this) {
                superValue.dataPoints.forEach(dp => {
                    if (dp.output === outputName) {
                        dp.format = this[formatGetter];
                    }
                })
            }
            return superValue;
        }

        getStatisticDataPointForOutput(outputName) {
            let superValue = super.getStatisticDataPointForOutput(outputName),
                formatGetter = `${outputName}Format`;
            if (superValue && formatGetter in this) {
                superValue.format = this[formatGetter];
            }
            return superValue;
        }

        get actualFormat() {
            if (this.details.actualFormat && this.details.actualFormat.endsWith("kW")) {
                return "%.1f kW";
            }
            return this.details.actualFormat || "%.1f";
        }

        get actualUnit() {
            return this._getUnitFromFormat(this.actualFormat);
        }

        get totalFormat() {
            if (this.details.totalFormat && this.details.totalFormat.endsWith("kWh")) {
                return "%.1f kWh";
            }
            return this.details.totalFormat || "%.1f";
        }

        /**
         * @Note: This is used generically, Don't trust Intellisense
         */
        get totalNegFormat() {
            return this.totalFormat;
        }

        get totalUnit() {
            return this._getUnitFromFormat(this.totalFormat);
        }

        get storageFormat() {
            return this.details && typeof this.details.storageFormat === 'string' && this.details.storageFormat.length > 0 ? this.details.storageFormat : "%.0f %%"
        }

        get storageUnit() {
            return this._getUnitFromFormat(this.storageFormat);
        }

        get computeUntracked() {
            return !!this.details.rest;
        }

        get untrackedIcon() {
            if (this.details && this.details.restIcon) {
                return this.details.restIcon;
            } else {
               return null;
            }
        }

        get untrackedName() {
            if (this.details && this.details.restName) {
                return this.details.restName;
            } else {
                return _("efm.other-node-title");
            }
        }

        getIcon() {
            return this.defaultIcon || Icon.TabBar.CENTRAL;
        }

        getReactIcon() {
            if (this.defaultIcon) {
                return null;
            } else {
                return Icons.EnergyFlowMonitorSymbol;
            }
        }

        // region Node Handling

        _addNodeUuidToLaunch(nodeUuid, viewType) {
            Debug.Control.Efm.NodeValueRequests && console.log(this.name + "-LaunchControl", "_addNodeUuidToLaunch " + nodeUuid + ", viewType="+ viewType);
            this._launchList = this._launchList || {};
            this._launchList[viewType] = this._launchList[viewType] || this._scheduleLaunch(viewType);
            this._launchList[viewType].nodeMap[nodeUuid] = true;
            return this._launchList[viewType].def.promise;
        }

        _scheduleLaunch(viewType) {
            Debug.Control.Efm.NodeValueRequests && console.log(this.name + "-LaunchControl", "_scheduleLaunch viewType="+ viewType);
            setTimeout(this._launchRequest.bind(this, viewType), 50);
            return { def: Q.defer(), nodeMap: {} };
        }

        _launchRequest(viewType) {
            let launchInfos = this._launchList[viewType],
                nodeUuids = Object.keys(launchInfos.nodeMap);

            // reset launchlist Entry
            this._launchList[viewType] = false;

            Debug.Control.Efm.NodeValueRequests && console.log(this.name + "-LaunchControl", "_launchRequest viewType="+ viewType + ", uuids=" +  nodeUuids.join(","));

            // fire
            let cmd = Commands.format(Commands.EnergyFlowMonitor.GET_NODE_VALUE, viewType,  nodeUuids.join(","))
            this._sendCommand(cmd).then((res) => {
                Debug.Control.Efm.Ctrl && console.log(this.name + "-LaunchControl", "_launchRequest " + cmd + " > response: " , res);
               launchInfos.def.resolve(this._parseGetNodeValueResponse(res));
            }, (err) => {
                console.error(this.name + "-LaunchControl", "_launchRequest viewType="+ viewType + " > fail: " , err);
                launchInfos.def.reject(err);
            });
        }

        _parseGetNodeValueResponse(plainRes) {
            let jsonRes,
                txtValue;
            try {
                txtValue = getLxResponseValue(plainRes, true);

                // miniserver may invalidly separate entries with two commas.
                if (txtValue.indexOf(",,") >= 0) {
                    txtValue = txtValue.replaceAll(",,", ",");
                }

                jsonRes = JSON.parse(txtValue);
            } catch (ex) {
                console.error(this.name, "_parseGetNodeValueResponse failed! " + plainRes, ex);
                jsonRes = {};
            }
            return jsonRes;
        }

        requestCurrentNodeValue(nodeUuid, viewType) {
            Debug.Control.Efm.Ctrl && console.log(this.name, "requestCurrentNodeValue of " + nodeUuid + ", viewType="+ viewType);
            return this._addNodeUuidToLaunch(nodeUuid, viewType).then((jsonResponse) => {
                // returns a json object where each requested uuid is the key that maps a JSON object with the requested
                // values to it, eg.: { "nodeUUid1": { "total": 1, "totalNeg": 2}, "nodeUUid2": { "total": 3 } }
                if (jsonResponse.hasOwnProperty(nodeUuid)) {
                    Debug.Control.Efm.Ctrl && console.log(this.name, "requestCurrentNodeValue of " + nodeUuid + ", viewType="+ viewType + " > response: ", jsonResponse[nodeUuid]);
                    return jsonResponse[nodeUuid];
                } else {
                    console.error(this.name, "requestCurrentNodeValue of " + nodeUuid + ", viewType="+ viewType + " > MISSING VALUE in response: " + JSON.stringify(jsonResponse));
                    return {err: "Missing in response"};
                }
            });
        }

        getNodes() {
            Debug.Control.Efm.Ctrl && console.log(this.name, "getNodes");
            return EfmUtilities.EfmNodeFactory.createNodesForEfm(this.details.nodes, this.uuidAction);
        }

        /**
         * Returns a new instance of the node requested by the uuid.
         * @param nodeUuid
         * @returns {null}
         */
        getNode(nodeUuid) {
            const findNodeByUuid = (list) => {
                let result = null;
                list.some(node => {
                    if (node.uuid === nodeUuid) {
                        result = node;
                    } else if (node.hasChildNodes) {
                        result = findNodeByUuid(node.childNodes);
                    }
                    return result !== null;
                });
                return result;
            }

            return findNodeByUuid(this.getNodes());
        }

        getLegacyReactControlContent() {
            return CtrlContent.default;
        }

        getReactScreens() {
            return [...super.getReactScreens(...arguments)];
        }

        // endregion
    };
});
