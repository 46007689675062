'use strict';
/*
 title
 iconSrc
 */

window.GUI = function (GUI) {
    class TinyCell extends GUI.TableViewV2.Cells.BaseCell {
        constructor() {
            super(...arguments);
            this.element.addClass("tiny-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.contentPlaceholder.append(GUI.TableViewV2.Cells.TinyCell.Template.getMediaCellContent(this.content.iconSrc, this.content.title));
        }

        _handleDetailButton() {
            this.delegate.onMediaDetailButtonTapped.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
        }

        // used in music server, here it's always clickable
        clickableAllowed() {
            return true;
        }

    }

    GUI.TableViewV2.Cells.TinyCell = TinyCell;

    GUI.TableViewV2.Cells.TinyCell.Template = function () {
        var getMediaCellContent = function getMediaCellContent(iconSrc, title) {
            return (iconSrc ? '<div class="tiny-cell__icon-placeholder">' + ImageBox.getResourceImageWithClasses(iconSrc, "tiny-cell__icon") + '</div>' : '') + '<div class="tiny-cell__texts-placeholder">' + '<div class="texts-placeholder__name text-overflow-ellipsis">' + title + '</div>' + '</div>';
        };

        return {
            getMediaCellContent: getMediaCellContent
        };
    }();

    GUI.TableViewV2.CellType.TINY = "TinyCell";
    return GUI;
}(window.GUI || {});
