import { Text } from 'react-native';
import PropTypes from 'prop-types';
import globalStyles from 'GlobalStyles';
import { StyleSheet, View } from 'react-native';
import { LxReactText } from 'LxComponents';

function LxReactUnreadText({isRead, ...props}) {
    const text = !isRead ? (
            <View style={styles.unreadContainer}>
                <View
                    style={styles.unreadIndicator}
                />
                <LxReactText {...props} />
            </View>
        ) : (
            <LxReactText {...props} />
        );

    return text;
}

export default LxReactUnreadText;

const styles = StyleSheet.create({
    unreadContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },

    unreadIndicator: {
        width: 10,
        height: 10,
        borderRadius: 5,
        backgroundColor: globalStyles.colors.red,
        marginBottom: 5,
        marginEnd: 10,
    }
});

LxReactUnreadText.propTypes = {
    ...Text.propTypes,
    ...LxReactText.propTypes,
    isRead: PropTypes.bool,
};
