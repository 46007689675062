import globalStyles from "GlobalStyles";

export const userStyles = {
    letterHeading: {
        ...globalStyles.textStyles.body.bold,
        marginLeft: 4,
        marginBottom: 14,
        marginTop: globalStyles.spacings.gaps.bigger
    }
}
