import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Trust(props) {
    return (
        <Svg
            isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.87 4.538h.001C2.007 4.5 9.278 2.511 11.64.146a.534.534 0 01.723 0c2.357 2.364 9.632 4.354 9.766 4.39h.001c.219.06.37.257.37.484 0 8.569-.761 15.406-10.326 18.948a.5.5 0 01-.348 0C2.261 20.427 1.5 13.589 1.5 5.02c0-.227.151-.424.37-.483zM15.5 7a.5.5 0 010 1h-7a.5.5 0 010-1h7zm-8 2a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5h-9zm8 6a.5.5 0 010 1h-7a.5.5 0 010-1h7z"
            />
        </Svg>
    )
}

export default Trust
