import {NewSelectorScreen, useBackNavigation} from "../Components";
import {useCallback, useContext, useMemo, useState} from "react";
import {AppPairingContext, DeviceCreateContext} from "./AppPairingNavigator";
import useBeforeNavBack from "./useBeforeNavBack";
import Icons from "IconLib"
import globalStyles from "GlobalStyles"


export default function UserGroupSelectionScreen({navigation, route}) {
    const {stateArgs} = useContext(AppPairingContext);
    const { updateProps } = useContext(DeviceCreateContext);
    const [selection, setSelection] = useState(route.params?.userGroups || []);

    useBeforeNavBack(() => {
        // use the Pairing Component to pass data back to the context.
        updateProps({userGroups: selection});
    })
    useBackNavigation(() => {
        navigation.goBack();
    })

    const options = useMemo(() => {
        return (Object.values(stateArgs?.availableUserGroupMap ?? {})).map(group => {
            return {
                title: group.description || group.name,
                id: group.uuid,
                mainLeftContent: {
                    comp: group.trustMember ? Icons.TrustUserGroup : Icons.UserGroup,
                    props: {
                        width: globalStyles.sizes.icons.small,
                        height: globalStyles.sizes.icons.small,
                        fill: group.type === UserManagement.USER_GROUP_TYPE.FULL_ACCESS ? globalStyles.colors.orange : globalStyles.colors.stateActive
                    }
                }
            }
        })
    }, [stateArgs?.availableUserGroupMap]);

    const onOptionSelected = useCallback((roomUuid, selected) => {
        setSelection((prevSelection) => {
            if (selected) {
                return [...prevSelection, roomUuid];
            } else {
                return prevSelection.filter(selUuid => selUuid !== roomUuid);
            }
        })
    }, []);

    return <NewSelectorScreen
        title={_('usergroups.headerTitle')}
        allowMultiple={true}
        options={options}
        selectedId={selection}
        onSelected={onOptionSelected}
    />
}