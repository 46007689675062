import {createStateCell} from "../../UtilityComp/utilities/helper";
import WallboxManagerCircleGraph from "./content/wallboxManagerCircleGraph";
import WallboxManagerNode from "./content/wallboxManagerNodes";
import WallboxManagerLimitedByList from "./content/wallboxManagerLimitedBy";


export default class WallboxManagerControlStatesSrc {

    static getNodeList(nodes, control, navigation) {
        return nodes.map(node => {
            return {
                key: node.uuid,
                mainCenterContent: {
                    comp: WallboxManagerNode,
                    props: {
                        nodeUuid: node.uuid,
                        managerUuid: control.uuidAction
                    }
                },
                mainCenterStyle: {
                    width: "100%",
                    alignItems: "stretch",
                    justifyContent: "stretch"
                }
            }
        })
    }

    static getScreenContent(nodeUuid = null, nodes, control, navigation) {
        let sections = [];
        const limitedRows = [];

        if (control.getLimitedWallboxNodes(nodeUuid).length > 0) {
            limitedRows.push({
                key: "restricted-wallboxes",
                mainCenterContent: {
                    comp: WallboxManagerLimitedByList,
                    props: { nodeUuid }
                },
                mainCenterStyle: {
                    width: "100%",
                    alignItems: "stretch",
                    justifyContent: "stretch"
                }
            })
        }

        sections.push({
            rows: [
                {
                    key: "circle-graph",
                    mainCenterContent: {
                        comp: WallboxManagerCircleGraph,
                        props: {
                            nodeUuid: nodeUuid,
                            format: control.powerFormat
                        }
                    },
                    mainCenterStyle: {
                        width: "100%",
                        alignItems: "stretch",
                        justifyContent: "stretch"
                    }
                },
                ...limitedRows,
                ...this.getNodeList(nodes, control, navigation)
            ]
        })

        return sections;
    }

    static getStateSections(control, states, navigation) {
        return this.getScreenContent(null, control.nodes, control, navigation);
    }
}