'use strict';

define(['LightV2ControlSaveMoodScreen'], function (LightV2ControlSaveMoodScreen) {
    return class LightV2ControlNewMoodScreen extends LightV2ControlSaveMoodScreen {
        constructor(details) {
            super(details);
        }

        getURL() {
            return "NewMood";
        }

        titleBarText() {
            return _("controls.lightV2controller.mood.new");
        }

    };
});
