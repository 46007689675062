'use strict';

window.Controls = function (Controls) {
    Controls.LightV2Control = Controls.LightV2Control || {};
    return Controls;
}(window.Controls || {});

define([], function () {
    Controls.LightV2Control.Enums = {
        ScreenState: {
            EDIT_MOOD: 'LightV2ControlEditMoodScreen',
            EDIT_MOODS: 'LightV2ControlEditMoodsScreen',
            NEW_MOOD: 'LightV2ControlNewMoodScreen',
            SAVE_MOOD: 'LightV2ControlSaveMoodScreen',
            REPLACE_MOOD: 'LightV2ControlReplaceMoodScreen',
            CONTROL_CONTENT_OLD: "LightV2ControlContentOld"
        },
        KNOWN_MOODS: {
            MANUAL: -1,
            ALARM_CLOCK: 776,
            ALL_ON: 777,
            ALL_OFF: 778
        },
        USED_BIT: {
            NOT_USED: 0,
            MOVEMENT_MOOD: 1,
            IS_T5_MOOD: 2,
            ALARM_CLOCK_MOOD: 4,
            PRESENCE_MOOD: 8 // since jan.2021

        }
    };
    return Controls.LightV2Control.Enums;
});
