import {useContext, useMemo, useState, useEffect, useCallback, useRef} from "react";
import {
    useDeviceSearchResults,
    LxReactDeviceSearchContext,
    LxReactText,
    LxReactSelectorScreen,
    LxReactOnlineView,
    LxReactImageView,
    LxBackgroundComp,
    LxReactButton,
    ButtonType,
    useNopeAreaForRef,
    useBackNavigation,
    useConnectionReady,
} from "LxComponents";
import globalStyles from "GlobalStyles";
import Icons from "IconLib";
import {View, Image} from "react-native";
import useTrackRouteInSearchContext from "./useTrackRouteInSearchContext";
import DeviceSearchDeviceInfoTable from "./deviceSearchDeviceInfoTable";
import useDeviceSearchExistingDevices from "./useDeviceSearchExistingDevices";
import LxReactDeviceSearchAssistantContext from "./LxReactDeviceSearchAssistantContext";
import useDeviceSearchTitleBar from "./useDeviceSearchTitleBar"
import LxReactDeviceSearchStoppedView from "./LxReactDeviceSearchStoppedView";
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import LxReactDeviceSearchOngoingSearchView from "./LxReactDeviceSearchOngoingSearchView";
import useDeviceSearchLightGroups from "./useDeviceSearchLightGroups";
import { StyleSheet } from "react-native-web"
import DeviceSearchEnums from "./LxReactDeviceSearchEnums";
import LxReactDeviceSearchResults from "./LxReactDeviceSearchResults";


const DEV_SEARCH_STORED_DATA = "-last-device-type-data-";


export default function LxReactDeviceSearchAssistant({route, navigation}) {
    const ref = useRef(null),
        insets = useSafeAreaInsets();

    // ensure no notfications are shown over the buttons below.
    useNopeAreaForRef(ref);


    const extension = route.params.extension;
    const branch = route.params.branch;
    const miniserver = route.params.miniserver;
    const searchType = route.params.searchType;
    const searchId = route.params.searchId;
    const deviceTypeFilter = route.params.deviceTypeFilter;
    const currentDeviceSerial = route.params.currentDeviceSerial;

    const {updateRouteParams} = useContext(LxReactDeviceSearchContext);

    const { searchResults, isSearching, wasKicked, isRestarting } = useDeviceSearchResults(searchType, extension, searchId, branch, miniserver);

    const [rerenderState, setRerenderState] = useState(0);
    const lastPairedSerialNumbersRef = useRef([]);
    useTrackRouteInSearchContext(LxReactDeviceSearchAssistant.name, route);
    useDeviceSearchTitleBar(navigation, route);

    useBackNavigation(() => {
        navigation.goBack();
    })
    const connectionReady = useConnectionReady(true);

    const existingDevices = useDeviceSearchExistingDevices(null, miniserver, extension, searchType);
    const pickerList = useMemo(() => {
        let list;
        if (deviceTypeFilter) {
            list = searchResults.filter(device => device.type === deviceTypeFilter);
        } else {
            list = searchResults;
        }

        Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "useMemo=>pickerList (filter='"+deviceTypeFilter+"') = " + JSON.stringify(list));
        return list;
    }, [JSON.stringify(searchResults).hashCode(), deviceTypeFilter]);

    const selectedDeviceIdx = useRef(-1);
    const getInitialSelection = () => {
        let selection = null;
        if (pickerList.length > 0) {
            if (currentDeviceSerial) {
                pickerList.some((dev, idx) => {
                    if (dev.serialNr === currentDeviceSerial) {
                        selection = { idx: idx, serialNr: dev.serialNr };
                        return true;
                    }
                    return false;
                });
            }
            selection = selection || { idx: 0, serialNr: pickerList[0].serialNr };
        }
        Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "getInitialSelection: " + JSON.stringify(selection), pickerList);
        if (selection) {
            selectedDeviceIdx.current = selection.idx;
            return selection.serialNr;
        }
        return null;
    }
    const [selectedDeviceSerialNr, setSelectedDeviceSerialNr] = useState(getInitialSelection);

    // ensure a device is selected.
    const lastIdentify = useRef(null);
    const startIdentify = (deviceSerial, requestTarget) => {
        Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "startIdentify: ext=" + requestTarget + ", device=" + deviceSerial);
        lastIdentify.current = {ext: requestTarget, device: deviceSerial};
        ActiveMSComponent.setDeviceIdentify(lastIdentify.current.ext, lastIdentify.current.device);
    }
    const stopIdentify = () => {
        if (lastIdentify.current) {
            Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "stopIdentify: ext=" + lastIdentify.current.ext + ", device=" + lastIdentify.current.device);
            ActiveMSComponent.setDeviceIdentify(lastIdentify.current.ext);
            lastIdentify.current = null;
        } else {
            Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "stopIdentify - not active");
        }
    }

    useEffect(() => {
        if (pickerList.length > 0 && !selectedDeviceSerialNr) {
            let didPick = false;
            if (currentDeviceSerial) {
                Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "useEffect@pickerList - pick device by serial " + currentDeviceSerial);
                didPick = pickDeviceWithSerial(currentDeviceSerial);
            }
            if (!didPick) {
                Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "useEffect@pickerList - pick first device " + currentDeviceSerial);
                pickDeviceAtIndex(0);
            }
        } // empty pickerlist will stop the identify via the identify-useEffect further below.
    }, [JSON.stringify(pickerList).hashCode()]);

    const updateSelectedDeviceInfos = (idx, serial) => {
        // use context to dispatch the device currently selected.
        updateRouteParams(LxReactDeviceSearchAssistant.name, { currentDeviceSerial: serial }, route.key)

        selectedDeviceIdx.current = idx;
        Debug.DeviceSearch.Assistant &&console.log(LxReactDeviceSearchAssistant.name, "updateSelectedDeviceInfos: idx=" + idx + ", serial=" + serial);
        setSelectedDeviceSerialNr(serial);
    }

    const pickDeviceAtIndex = (idx) => {
        let pickerIdx;
        if (idx < 0) {
            HapticFeedback(HapticFeedback.STYLE.ERROR);
            pickerIdx = pickerList.length - 1;
        } else if (idx >= pickerList.length) {
            HapticFeedback(HapticFeedback.STYLE.ERROR);
            pickerIdx = 0;
        } else {
            HapticFeedback(HapticFeedback.STYLE.SELECT);
            pickerIdx = idx;
        }

        let device = pickerList[pickerIdx];
        if (device) {
            Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "pickDeviceAtIndex - " + idx + "=" + pickerIdx + " = ", device);
            updateSelectedDeviceInfos(pickerIdx, device.serialNr);
        } else {
            Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "pickDeviceAtIndex - no device found at " + pickerIdx, pickerList);
        }
        return !!device;
    }

    const pickDeviceWithSerial = (serial) => {
        let devIdx = pickerList.findIndex(dev => dev.serialNr.toLowerCase() === serial.toLowerCase());
        if (devIdx >= 0) {
            updateSelectedDeviceInfos(devIdx, serial);
        }
        return devIdx >= 0;
    }

    const pickedDevice = useMemo(() => {
        let pickedDevice = null,
            locatedIdx = -1;
            if (selectedDeviceSerialNr) {
                pickerList.find((dev, idx) => {
                    if (dev.serialNr === selectedDeviceSerialNr) {
                        locatedIdx = idx;
                        return true;
                    }
                    return false;
                });
                pickedDevice = locatedIdx >= 0 ? pickerList[locatedIdx] : null;
                Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name,
                    "useMemo=>pickedDevice (selectedDeviceSerialNr=" + selectedDeviceSerialNr + ") = " + (pickedDevice ? pickedDevice.serialNr : "-"));
            }
            if (!pickedDevice && pickerList.length > selectedDeviceIdx.current) {
                pickedDevice = pickerList[selectedDeviceIdx.current];
                Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name,
                    "useMemo=>pickedDevice (selectedDeviceIdx=" + selectedDeviceIdx.current + ") = " + (pickedDevice ? pickedDevice.serialNr : "-"));
            }
            if (!pickedDevice) {
                Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "useMemo=>pickedDevice = -  --> neither by serial=" + selectedDeviceSerialNr + " nor by idx=" + selectedDeviceIdx.current, pickerList);
                if (pickerList.length > 0) {
                    pickedDevice = pickerList[pickerList.length - 1];
                    selectedDeviceIdx.current = pickerList.length - 1; // important to avoid 4/3-issue
                    Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "useMemo=>pickedDevice = resort to last device in list! " + pickedDevice.serialNr);
                }
            }

            if (pickedDevice && selectedDeviceSerialNr && pickedDevice.serialNr !== selectedDeviceSerialNr) {
                setSelectedDeviceSerialNr(pickedDevice.serialNr);
                pickedDevice = null;
            }

        return pickedDevice;
    }, [selectedDeviceSerialNr, JSON.stringify(pickerList).hashCode()]);

    const isDeviceLearnedInElsewhere = useMemo(() => {
        if (pickedDevice && existingDevices) {
            return existingDevices.some(dev => dev?.serialNr?.toLowerCase().replaceAll(":", "") === pickedDevice?.serialNr && pickedDevice.msSerial !== dev.extension.msSerial);
        } else {
            return false;
        }
    }, [pickedDevice, existingDevices]);
    // this is the only place within the assistant where identify may be started or stopped.
    useEffect(() => {
        // there was a report (BG-I26248), that even though no more devices have been shown, the identify was still
        // ongoing. The useEffect for the empty pickerList did stop it, bit this useEffect did restart it as the snr
        // isn't unset and it was triggered at a later point in time than the useEffect on the pickerList.
        if (selectedDeviceSerialNr && pickerList.length > 0) {
            Debug.DeviceSearch.Assistant &&console.log(LxReactDeviceSearchAssistant.name, "useEffect@selectedDeviceSerial - start identify of " + selectedDeviceSerialNr + " ex-rqTarget=" + extension?.requestTarget + ", dev.rqTarget=" + pickedDevice?.requestTarget);
            startIdentify(selectedDeviceSerialNr, (extension?.requestTarget ?? false) || pickedDevice?.requestTarget);
        } else if (selectedDeviceSerialNr) {
            Debug.DeviceSearch.Assistant &&console.log(LxReactDeviceSearchAssistant.name, "useEffect@selectedDeviceSerial - snr set (" + selectedDeviceSerialNr + "), but pickerlist empty, do not start identify, but stop it!");
            stopIdentify();
        } else {
            Debug.DeviceSearch.Assistant &&console.log(LxReactDeviceSearchAssistant.name, "useEffect@selectedDeviceSerial - STOP identify");
            stopIdentify();
        }
    }, [selectedDeviceSerialNr, pickedDevice?.requestTarget, extension?.requestTarget, pickerList.length])
    useEffect(() => {
        return () => {
            Debug.DeviceSearch.Assistant &&console.log(LxReactDeviceSearchAssistant.name, "useEffect@comp - STOP identify");
            stopIdentify();
        }
    }, [])


    const lightGroups = useDeviceSearchLightGroups(pickedDevice);

    // region modification tracking

    const dataRef = useRef({});
    const storeDataTimeoutRef = useRef(null);
    const updateDataCallback = useCallback(newData => {
        dataRef.current = {...dataRef.current, ...newData};

        storeDataTimeoutRef.current && clearTimeout(storeDataTimeoutRef.current);
        storeDataTimeoutRef.current = setTimeout(() => {
            persistDeviceData();
            storeDataTimeoutRef.current = null;
        }, [2000]);

    }, [])

    const deviceDataPersistencePath = useMemo(() => {
        const activeMsSerial = ActiveMSComponent.getActiveMiniserver().serialNo;
        return searchType + DEV_SEARCH_STORED_DATA + activeMsSerial;
    }, [searchType]);

    const loadPersistedDeviceData = () => {
        let path = deviceDataPersistencePath,
            dataString = PersistenceComponent.getLocalStorageItemWithTtl(path),
            data;
        try {
            data = JSON.parse(dataString);
        } catch (ex) {
            data = {};
        }
        Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "loadPersistedDeviceData = "+ JSON.stringify(data));

        return data;
    }

    /**
     * Initializes the ref.
     */
    const initializedRef = useRef(false);
    useEffect(() => {
        if (!initializedRef.current) {
            let initialDevData = loadPersistedDeviceData();
            updateDataCallback(initialDevData);
        }
        initializedRef.current = true;
    }, [])

    const getDataCallback = useCallback( () => {
        return dataRef.current;
    }, []);

    const persistDeviceData = () => {
        let path = deviceDataPersistencePath,
            data = {...dataRef.current};
        (async () => {
            PersistenceComponent.setLocalStorageItemWithTtl(path, JSON.stringify(data), DeviceManagement.DATA_TTL);
        })();
    }


    // endregion


    const deviceIcon = useMemo(() => {
        if (!pickedDevice) {
            return null;
        }
        const iconSrc = ActiveMSComponent.getImageUrlWithDeviceType(pickedDevice.type);
        let rendered;
        if (iconSrc.startsWith("http") && !iconSrc.endsWith(".svg")) {
            rendered = <Image source={{uri: iconSrc}} style={[Styles.headerIconCntr, Styles.headerIcon]} />;
        } else {
            rendered = <LxReactImageView  source={iconSrc}
                                          containerStyle={Styles.headerIconCntr}
                                          imageStyle={Styles.headerIcon}/>
        }
        return rendered;
    }, [pickedDevice ? pickedDevice.type : null]);

    const findSelDeviceIdxInPickerList = () => {
        let lookForSnr = selectedDeviceSerialNr || (pickedDevice?.serialNr ?? "-no-snr-");
        return pickerList.findIndex(dev => dev.serialNr.toLowerCase() === lookForSnr.toLowerCase());
    }

    const pickedLocationText = useMemo(() => {
        let result;
        if (selectedDeviceIdx.current >= 0 && pickerList && pickerList.length > 0) {
            let total = pickerList.length,
                current = selectedDeviceIdx.current + 1

            // check for 5/1 issue.
            if (current > total) {
                // try to locate it in the pickerList
                current = findSelDeviceIdxInPickerList();
                if (current >= 0) {
                    current = current + 1;
                } else {
                    current = "-"; // not found in results - should update in a second when new results arrive.
                }
            }

            result = current + "/" + total;
        } else {
            result = "-/-";
        }
        Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "useMemo=> pickedLocationText = "+ result + " (selectedDeviceIdx="+ selectedDeviceIdx.current + ", pickerList? " + (pickerList ? pickerList.length : false) + ")");
        return result;
    }, [selectedDeviceIdx.current, pickerList.length]);

    const handleGoBack = () => {
        navigation.goBack();
    }

    const getSearchStoppedWithoutResults = () => {
        let title, message = _("device-learning.search-again"),
        retryCb = () => {
            ActiveMSComponent.restartDeviceSearch();
        };
        if (searchType === DeviceManagement.TYPE.AIR) {
            title = _("air.device-learning.stopped.title");

        } else if (searchType === DeviceManagement.TYPE.TREE) {
            title = _("tree.device-learning.stopped.title");
        }
        return (<View style={Styles.topContainer}>
            <LxBackgroundComp
                icon={<Icons.ArrowCircle fill={globalStyles.colors.stateActive} />}
                title={title}
                message={message}
                onTitleClickedFn={retryCb}
                onMessageClickedFn={retryCb}
            />
        </View>)
    }

    const getNoResultsYet = () => {
        return (<View style={Styles.topContainer}><LxReactDeviceSearchOngoingSearchView searchType={searchType}/></View>);
    }

    const getAllDevicesPaired = () => {
        const numAvailable = searchResults?.length;
        const title = _("device-learning.all-paired");
        const subTitle = numAvailable ? _("device-learning.empty-screen.filter-active.title") : null;
        const message = numAvailable ? _("device-learning.empty-screen.filter-active.message_plural", {count: numAvailable}) : _("back");
        return (<View style={Styles.topContainer}>
            <LxBackgroundComp
                icon={<Icons.Tick fill={globalStyles.colors.stateActive} />}
                title={title}
                subTitle={subTitle}
                message={message}
                onTitleClickedFn={handleGoBack}
                onMessageClickedFn={handleGoBack}
            />
        </View>)
    }

    const currentItem = useMemo(() => {
        if (pickedDevice) {
            Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "useMemo=>currentItem, rendering: " + JSON.stringify(pickedDevice));
            return (<View style={Styles.topContainer}>
                <View style={Styles.header}>
                    <View style={[Styles.headerSpacing, Styles.headerLeft]} />
                    {deviceIcon}
                    <View style={[Styles.headerSpacing, Styles.headerRight]}>
                        <LxReactText style={Styles.locationText}>{pickedLocationText}</LxReactText>
                    </View>
                </View>

                <DeviceSearchDeviceInfoTable
                    device={pickedDevice}
                    searchType={searchType}
                    isDeviceLearnedInElsewhere={isDeviceLearnedInElsewhere}
                />
            </View>);
        } else if (!isSearching) {
            return getSearchStoppedWithoutResults();
        } else if (isSearching && (lastPairedSerialNumbersRef.current.length === 0 || searchType === DeviceManagement.TYPE.AIR)) {
           return getNoResultsYet();
        } else {
            return getAllDevicesPaired();
        }
    }, [pickedDevice ? pickedDevice.serialNr : null, pickedLocationText, rerenderState, lastPairedSerialNumbersRef.current.length, isDeviceLearnedInElsewhere]);

    // region device create

    const incrementDeviceData = (numberedNameSupport) => {
        const incrementProp = (id, dataset) => {
            if (dataset.hasOwnProperty(id)) {
                dataset[id] = increaseNumberSuffix(dataset[id])
            }
        }

        let newData = {...dataRef.current};
        if (numberedNameSupport) {
            incrementProp("nameColumn", newData);
        } else {
            incrementProp("name", newData);
        }
        incrementProp("description", newData);
        incrementProp("column", newData);
        if (JSON.stringify(dataRef.current).hashCode() !== JSON.stringify(newData).hashCode()) {
           Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "incrementDeviceData");
           Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "    from: " + JSON.stringify(dataRef.current));
           Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "      to: " + JSON.stringify(newData));
           updateDataCallback(newData);
           setRerenderState(getRandomIntInclusive(0,99999));
       }
    }

    const getLimitInfos = (data, err) => {
        let max = -1,
            name = "Extension/Branch";
        try {
            let extObject = ActiveMSComponent.getExtension(data.extension || extension);
            if (extObject) {
                max = ActiveMSComponent.getMaxNumDevices(extObject, data.subCaptionId) || max;
                name = ActiveMSComponent.getExtensionName(extObject, data.subCaptionId) || name;
            }
        } catch (ex) {
            // sth went wrong!
        }
        let maxTxt = max > 0 ? (" (max. " + max + ")") : "";
        return {
            name: name,
            maxNum: max,
            max: maxTxt
        }
    }

    const createDevice = () => {
        let devData = {...dataRef.current};
        const numberedNameSupport = ActiveMSComponent.deviceTypeSupportsNumberedName(pickedDevice.type);

        // if the device type supports it, add a suffix with row.column
        if (numberedNameSupport) {
            devData.name = (devData.name && devData.name.length > 0 ? (devData.name + " ") : "") + devData.nameRow + "." + devData.nameColumn;
        }
        delete devData.nameRow;
        delete devData.nameColumn;

        // a name is a must.
        devData.name = devData.name || pickedDevice.name;

        // in the app we differ between heat/wallbox switchboard and regular switchboard, the MS doesn't differ in create dev.
        let sbType = ActiveMSComponent.identifySwitchboardType(pickedDevice);
        switch (sbType) {
            case DeviceManagement.SwitchboardTypes.WALLBOX:
                devData.switchboard = devData.wallboxSwitchboard;
                break;
            case DeviceManagement.SwitchboardTypes.HEAT:
                devData.switchboard = devData.heatSwitchboard;
                break;
            default:
                break;
        }
        delete devData.heatSwitchboard;
        delete devData.wallboxSwitchboard;
        if (devData.switchboard === DeviceSearchEnums.NO_SWITCHBOARD_ID) {
            // the no-switchboard-option means that no switchboard information must be passed on.
            delete devData.switchboard;
        }

        if (devData.room) { // the ms expects the room to be provided via roomUuid, not room
            devData.roomUuid = devData.room;
            delete devData.room;
        }

        devData.autoCreate = false; // use auto config or not
        const totalData = {...pickedDevice, ...devData};

        Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "createdDevice: " + JSON.stringify(devData));

        var promise = ActiveMSComponent.createNewDevice(
            pickedDevice.requestTarget,
            pickedDevice.serialNr,
            pickedDevice.capabilities,
            devData);
        return NavigationComp.showWaitingFor(promise).then((res) => {
            lastPairedSerialNumbersRef.current.push(totalData.serialNr);
            incrementDeviceData(numberedNameSupport);

            ActiveMSComponent.getAvailableTechTags(true).then((tags) => {
                showDevicePairedNotification(totalData, res, false, tags);
            }).catch((err) => {
                showDevicePairedNotification(totalData, res, false, []);
            });
        }, (err) => {
            showErrorPopup(totalData, err, false);
        });
    }

    // endregion

    const handleLightGroupIdentify = useCallback((groupUuid) => {
        let lightGroup = null;
        lightGroups && lightGroups.some(lgObj => {
            if (lgObj.uuid === groupUuid) {
                lightGroup = lgObj;
                return true;
            }
            return false;
        });
        Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "handleLightGroupIdentifyStart: " + groupUuid + " = " + JSON.stringify(lightGroup));
        if (!lightGroup) {
            // something went wrong
            NavigationComp.showErrorPopup(false, _("device-learning.check-lightgroup.title"), "Lighting group wasn't found. " + groupUuid);
            return;
        }

        let name = lightGroup.name;
        if (lightGroup.room) {
            name += " (" + lightGroup.room.name + ")";
        }
        let popupContent = {
            title: _("device-learning.check-lightgroup.title"),
            message: _("device-learning.check-lightgroup.flashing-message", { name: name }),
            buttonOk: _("device-learning.check-lightgroup.stop")
        }
        ActiveMSComponent.startLightGroupIdentify(groupUuid).then(() => {
            NavigationComp.showPopup(popupContent).finally(() => {
                ActiveMSComponent.stopLightGroupIdentify();
            });
        })
    }, [lightGroups]);


    // region device-pairing-feedback

    const notificationRef = useRef(null);

    const offerTechSwitch = (data, res) => {
        const { searchableChildrenTags = [] } = res.LL.value || {};
        ActiveMSComponent.getAvailableTechTags()
            .then((tags) => {
                const content = {};
                content.title = _("device-learning.continue-with", {
                    childrenTitles: ''
                });
                content.buttonOk = false;
                content.buttonCancel = true;
                content.icon = Icon.DeviceLearning.DEVICE_SEARCH;
                content.buttons = searchableChildrenTags.map((tag) => {
                    return {
                        type: GUI.PopupBase.ButtonType.CUSTOM,
                        color: globalStyles.colors.stateActive,
                        title: tags.find((t) => t.name === tag).title,
                    };
                });
                return content;
            })
            .then((content) => {
                return NavigationComp.showPopup(content);
            })
            .then((idx) => {
                if (Number.isNaN(parseInt(idx))) return;

                NavigationComp.showWaitingFor(
                    ActiveMSComponent.switchToSearchableChild(
                        searchableChildrenTags[idx],
                        data,
                    ),
                ).then((searchId) => {
                    ActiveMSComponent.getAvailableExtensions(
                        true,
                        miniserver,
                    ).then((res) => {
                        navigation.push(LxReactDeviceSearchResults.name, {
                            ...route.params,
                            searchType: searchableChildrenTags[idx],
                            searchId,
                            extension: res.find(
                                (ext) => ext.serialNr === data.serialNr,
                            ),
                            miniserver: miniserver,
                            searchState:
                                DeviceSearchEnums.SearchState.BROWSE_RESULTS,
                            isParked: true,
                        });
                    });
                });
            })
            .catch((e) => {
                Debug.DeviceSearch.Assistant &&
                    console.log(
                        LxReactDeviceSearchAssistant.name,
                        'handleNotificationTapped: ',
                        'unable to present a technology switch popup due to',
                        JSON.stringify(e),
                        'using data: ',
                        JSON.stringify(data),
                        JSON.stringify(res),
                    );
            });
    };

    const handleNotificationTapped = (title, data, res, replaced) => {
        Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "handleNotificationTapped: ", data);
        var content = {
            title: title,
            buttonOk: true,
            icon: Icon.SUCCESS,
            color: globalStyles.colors.stateActive
        };

        if (data && data.groupID) {
            content.buttons = [{
                type: GUI.PopupBase.ButtonType.CUSTOM,
                color: globalStyles.colors.stateActive,
                title: _("device-learning.check-lightgroup.title")
            }]
        }
        const { hasSearchableChildren } =
            res.LL.value || {};
        if (hasSearchableChildren) {
            offerTechSwitch(data, res);
        } else {
            NavigationComp.showPopup(content).then((idx) => {
                if (data.groupID && idx === 0) {
                    handleLightGroupIdentify(data.groupID);
                }
            });
        }
            
    }

    const showDevicePairedNotification = (data, res, replaced = false, newTags) => {
        Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "showDevicePairedNotification: ", data);
        const title = replaced ? _("device-learning.replace-successful") : _("device-learning.successful.title");
        
        let newResult = {...res};
        if(res.LL.value.length > 0) {
            newResult.LL.value = JSON.parse(res.LL.value);
        }

        const { hasSearchableChildren, searchableChildrenTags } = newResult.LL.value || {};
        let subtitle;
        if(hasSearchableChildren) {
            subtitle = _("device-learning.continue-with", {
                childrenTitles: searchableChildrenTags.map((tag) => newTags.find((t) => t.name === tag).title).join(", ")
            });
        }
        const notification = GUI.Notification.createGeneralNotification({
            iconSrc: Icon.SUCCESS,
            subtitle,
            iconColor: globalStyles.colors.text.primary,
            title: title,
            closeable: true,
            clickable: Feature.LIGHT_GROUP_IDENTIFY,
            removeAfter: 5
        }, NotificationType.SUCCESS);

        notification.on(GUI.Notification.CLICK_EVENT, () => {
            Feature.LIGHT_GROUP_IDENTIFY && handleNotificationTapped(title, data, newResult, replaced);
            notificationRef.current.remove();
            notificationRef.current = null;
        });
        notification.on(GUI.Notification.MARK_AS_READ_EVENT, () => {
            notificationRef.current.remove();
            notificationRef.current = null;
        });
        if (notificationRef.current) {
            notificationRef.current.remove();
        }
        notificationRef.current = notification;
    }

    const showErrorPopup = (data, err, replaced = false) => {
        Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant?.name, "showErrorPopup: ", err);

        let errorCode, errorValue;
        try {
            errorCode = getLxResponseCode(err);
        } catch (ex) {
            errorCode = -1;
        }
        try {
            errorValue = getLxResponseValue(err,true);
        } catch (ex) {
            errorValue = JSON.stringify(err);
        }
        let title = _("device-learning.failed.title"),
            message;
        switch (errorCode) {
            case 405: // device already paired with this serial number.
                message = _("device-learning.failed.message.serial-exists");
                break;
            case 406: // extension full
                let limitInfos = getLimitInfos(data, err);
                message = _("device-learning.failed.message.ext-full", { name: limitInfos.name }) + limitInfos.max;
                break;
            case 500: // device did not respond to pairing command.
                if (searchType === DeviceManagement.TYPE.AIR) {
                    message = _("device-learning.failed.no-response-air", {name: data?.name || data?.type });
                }
                break;
            default:
                break;
        }
        message = message || _("device-learning.failed.message.other-error") + " " + errorValue + "(" + errorCode + ")";
        const content = {
            title: title,
            message: message,
            buttonOk: true,
            icon: Icon.CAUTION,
            color: globalStyles.colors.orange
        };
        NavigationComp.showPopup(content);
    }

    // endregion

    // region device replacing

    const createDeviceSelectionOption = (dev) => {
        let onlinePropObj = {};
        if (dev.deviceState && dev.serialNr) {
            onlinePropObj.isOnline = dev.deviceState;
        }
        let lgName = dev.group ? dev.group.name : null;
        let roomName = dev.room ? dev.room.name : null;
        let snr = dev.serialNr;
        let subTitleParts = [];
        subTitleParts.pushObject(snr);
        subTitleParts.pushObject(lgName);
        let searchTags = [...subTitleParts];
        searchTags.pushObject(roomName);
        return {
            id: dev.uuid,
            title: dev.description || dev.name,
            subTitle: subTitleParts.join(", "),
            searchTags: searchTags.join(", "),
            mainLeftContent: {
                comp: LxReactOnlineView,
                props: {
                    key: "online-icon",
                    ...onlinePropObj,
                    style: {
                        justifySelf: "center",
                        alignContent: "center",
                    },
                    imageComp: {
                        comp: LxReactImageView,
                        props: {
                            key: "icon",
                            source: ActiveMSComponent.getImageUrlWithDeviceType(dev.type),
                            containerStyle: {
                                fill: globalStyles.colors.white,
                                height: globalStyles.sizes.icons.regular,
                                width: globalStyles.sizes.icons.regular
                            },
                            imageStyle: {
                                height: globalStyles.sizes.icons.regular,
                                width: globalStyles.sizes.icons.regular
                            }
                        }
                    }
                }
            }
        }
    }

    const compatibleDeviceTypes = useMemo(() => {
        let typeMap = {};
        // this enum is an array of arrays, each array contains a list of device types that are compatible (e.g. old keypad with nfc code touch)
        DeviceManagement.GENERATION_DEVICE_TYPES.forEach(genList => {
            genList.forEach(gType => {typeMap[gType] = genList;});
        })
        return typeMap;
    }, [])

    const replaceOptions = useMemo(() => {
        if (pickedDevice && existingDevices) {
            let roomUuidMap = {}; // establish a uuid map to avoid messing up rooms with same names.
            // filter out the devices that are not on the same Miniserver to prevent replacing across Miniservers in Client-GW setups. This covers both physical and dummy devices.
            existingDevices.filter(dev => pickedDevice.msSerial === (dev.extension && dev.extension.msSerial)).forEach(dev => {
                let match = dev.type === pickedDevice.type;
                if (!match && compatibleDeviceTypes[pickedDevice.type]) {
                    // check if the devices type matches one of the other compatible types
                    match = compatibleDeviceTypes[pickedDevice.type].some(compType => { return dev.type === compType; });
                }
                if (match) {
                    roomUuidMap[dev.roomUuid] = roomUuidMap[dev.roomUuid] || { title: (dev.room ? dev.room.name : ""), options: []};
                    roomUuidMap[dev.roomUuid].options.push(createDeviceSelectionOption(dev))
                }
            });
            let result = Object.values(roomUuidMap);
            result.sort((a, b) => {
                return a.title.localeCompare(b.title);
            });

            // not only the rooms should be sorted, but also the devices in them.
            result.forEach(({options}) => options.sort((a,b) => {
                return a.title.localeCompare(b.title);
            }));

            Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "useMemo@replaceOptions: " + result.length + " - pickedType=" + pickedDevice?.type + ", existingDevices: ", existingDevices, result);
            return result;
        } else {
            Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "useMemo@replaceOptions: none - pickedType=" + pickedDevice?.type + ", existingDevices: ", existingDevices);
            return [];
        }
    }, [JSON.stringify(existingDevices).hashCode(), pickedDevice ? pickedDevice.type : null]);
    const openReplaceDeviceScreen = () => {
        let replaceWithSerial = pickedDevice.serialNr; // store to make sure no other device is replaced if sth changes in the meantime!
        navigation.push(LxReactSelectorScreen.name, {
            title: _('device-learning.action-buttons.replace'),
            options: replaceOptions,
            selectedId: null,
            onSelected: (targetUuid) => {
                replaceDevice(targetUuid, replaceWithSerial);
            },
            autoClose: true
        });
    }
    const replaceDevice = (targetUuid, replaceSerial) => {
        console.log(LxReactDeviceSearchAssistant.name, "replaceDevice " + targetUuid + " with " + replaceSerial);
        let devData = {...dataRef.current};
        const totalData = {...pickedDevice, ...devData};

        NavigationComp.showWaitingFor(ActiveMSComponent.replaceDevice(replaceSerial, targetUuid)).then(res => {
            lastPairedSerialNumbersRef.current.push(replaceSerial);
            ActiveMSComponent.getAvailableTechTags(true).then((tags) => {
                showDevicePairedNotification(totalData, res, true, tags);
            }).catch((err) => {
                showDevicePairedNotification(null, res, true, []);
            });
        }, (err) => {
            showErrorPopup(null, err, true);
        });
    }

    // endregion

    const moveBack = () => {
        Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "moveBack: " + selectedDeviceIdx.current + " -> " + (selectedDeviceIdx.current - 1));
        (async => {
            pickDeviceAtIndex(selectedDeviceIdx.current - 1);
        })();
    };
    const moveForward = () => {
        Debug.DeviceSearch.Assistant && console.log(LxReactDeviceSearchAssistant.name, "moveForward: " + selectedDeviceIdx.current + " -> " + (selectedDeviceIdx.current + 1));
        (async => {
            pickDeviceAtIndex(selectedDeviceIdx.current + 1);
        })();
    };

    const createMoveButton = (cb, icon) => {
        return <LxReactButton
            leftIcon={icon}
            buttonType={ButtonType.SECONDARY}
            onPress={cb}
            onLongPressTick={cb}
            disabled={ !pickerList || pickerList.length === 0 || !connectionReady}
            containerStyle={[StyleSheet.flatten(Styles.button)]}
            longPressTickInterval={searchType === DeviceManagement.TYPE.AIR ? LONG_PRESS_TICK_AIR : LONG_PRESS_TICK_TREE}
        />
    }

    const {backButton, forwardButton} = useMemo(() => {
        return {
            backButton: createMoveButton(moveBack, Icons.ArrowLeft),
            forwardButton: createMoveButton(moveForward, Icons.ArrowRight)
        }
    }, [pickerList ? pickerList.length : 0, connectionReady]);

    if (!wasKicked) {
        return <LxReactDeviceSearchAssistantContext.Provider value={{
            currentDevice: pickedDevice,
            searchType: searchType,
            filteredExtension: extension,
            filteredBranch: branch,
            filteredMiniserver: miniserver,
            updateData: updateDataCallback,
            getData: getDataCallback,
            lightGroupIdentify: handleLightGroupIdentify
        }}>
            <View style={[Styles.rootCntr, { marginBottom: insets.bottom }]}>
                {currentItem}
                <View style={Styles.buttonAreaContainer} ref={ref}>
                    <View style={Styles.buttonArea}>
                        <LxReactButton text={_('device-learning.add-device.title')}
                                       buttonType={pickedDevice ? ButtonType.PRIMARY : ButtonType.SECONDARY}
                                       onPress={createDevice}
                                       numberOfLines={1}
                                       disabled={!pickedDevice || !connectionReady || isDeviceLearnedInElsewhere}
                                       containerStyle={[StyleSheet.flatten(Styles.button), StyleSheet.flatten(Styles.createButton)]}
                        />
                        <View style={Styles.lowerButtonArea} >
                            {backButton}
                            <LxReactButton text={_('device-learning.device-replace.title')}
                                           buttonType={ButtonType.SECONDARY}
                                           numberOfLines={1}
                                           onPress={openReplaceDeviceScreen}
                                           disabled={replaceOptions.length === 0 || !connectionReady || isDeviceLearnedInElsewhere}
                                           containerStyle={[StyleSheet.flatten(Styles.button), StyleSheet.flatten(Styles.replaceButton)]}
                            />
                            {forwardButton}
                        </View>
                    </View>
                </View>
            </View>
        </LxReactDeviceSearchAssistantContext.Provider>
    } else {
        return <LxReactDeviceSearchStoppedView searchType={searchType} wasKicked={true} isRestarting={isRestarting}/>
    }
}

const LONG_PRESS_TICK_TREE = 1000;
const LONG_PRESS_TICK_AIR = 2000;

const Styles = {
    rootCntr: {
        flexDirection: "column",
        flex: 1,
    },
    topContainer: {
        ...globalStyles.customStyles.screenContent,
        flex: 1
    },
    header: {
        maxHeight: 112,
        justifyContent: "center",
        alignContent: "center",
        marginBottom: 16,
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20
    },
    headerSpacing: {
        flex: 1,
    },
    headerIconCntr: {
    },
    headerIcon: {
        width: 104,
        height: 104,
        marginLeft: "auto",
        marginRight: "auto"
    },
    headerRight: {
    },
    locationText: {
        ...globalStyles.textStyles.title3.default,
        color: globalStyles.colors.stateActive,
        textAlign: "right",
        marginTop: "auto",
        marginBottom: "auto"
    },
    pickerBar: {
        ...globalStyles.borders.top,
        flexDirection: "row",
        height: 64,
        alignItems: "center",
        justifyContent: "space-between"
    },
    positionText: {
        ...globalStyles.textStyles.footNote.bold,
        color: globalStyles.colors.text.tertiary,
        textAlign: "center",
        flex: 1
    },

    buttonAreaContainer: {
        ...globalStyles.borders.top,
        backgroundColor: globalStyles.colors.grey["700"],
    },
    buttonArea: {
        ...globalStyles.customStyles.screenContent,
        flexDirection: "column",
        margin: 12,
        paddingHorizontal: 20,
        marginHorizontal: 0
    },
    lowerButtonArea: {
        flexDirection: "row"
    },
    button: {
        backgroundColor: globalStyles.colors.fill.primary,
        margin: 4,
    },
    createButton: {
        backgroundColor: globalStyles.colors.stateActive
    },
    replaceButton: {
        flex: 1
    }
}