import VerticalSlider from "rn-vertical-slider";
import {View} from "react-native-web";
import {defaultSettings, defaultColors, defaultStyles} from "./LxReactSliderDefaultSettings";
import {isValidSliderValue} from "./LxReactSliderUtils";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import {AmbientContext} from "LxComponents";
import globalStyles from "GlobalStyles";

/**
 * Represents a Vertical Slider Component based on rn-vertical-slider (https://github.com/sacmii/rn-vertical-slider) which is showing an indicator * @typedef {object} Props
 * @prop {number} [min = 0] Minimum Value of slider
 * @prop {number} [max = 100] Maximum Value of slider
 * @prop {number} [step = 1] Step size of slider
 * @prop {number}  [value = 0] Initial value of the slider, must be updated in onValueChanged, otherwise slider resets to initial value
 * @prop {Function} onValueChanged Callback function that is triggered when slider was moved, returns new value(s) as param
 * @prop {Color} [activeColor = brand] Color of the selected track part and the label texts
 * @prop {Color} [unselectedTrackColor = mantisgreen] Color of the unselected track part
 * @prop {Boolean} [disabled = false] If false, taps on switch are not processed
 * @prop {ViewStyle} [containerStyle = null]:   Style to apply on slider container * @extends {Component<Props>}
 * TODO Tap Support is needed!
 */
export default function LxReactVerticalSlider(props) {
    const {isAmbientMode} = useContext(AmbientContext)

    const styles = {
        container: {
            width: "50%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
        }
    }

    const [containerStyle, setContainerStyle] = useState({
        height: defaultStyles.VERTICAL_HEIGHT,
        width: defaultStyles.VERTICAL_WIDTH
    })

    const getSelectedTrackColor = () => {
        return props.disabled ? defaultColors.SELECTED_DISABLED : (props.activeColor || defaultColors.SELECTED);
    }

    const getUnselectedTrackColor = () => {
        if (props.disabled) {
            return defaultColors.UNSELECTED_DISABLED
        } else if (isAmbientMode) {
            return globalStyles.colors.grey["300a36"]
        } else {
            return props.unselectedTrackColor || defaultColors.UNSELECTED_VERTICAL
        }
    }

    const updateContainerSize = (event) => {
        setContainerStyle({
            width: event.nativeEvent.layout.width,
            height: event.nativeEvent.layout.height
        })
    }

    return (
        <View style={[styles.container, props.containerStyle]} onLayout={updateContainerSize}>
            <VerticalSlider
                height={containerStyle.height}
                width={containerStyle.width}
                borderRadius={32}
                disabled={props.disabled}
                min={isValidSliderValue(props.min) ? props.min : defaultSettings.MIN}
                max={isValidSliderValue(props.max) ? props.max : defaultSettings.MAX}
                step={isValidSliderValue(props.step) ? props.step : defaultSettings.STEP}
                value={isValidSliderValue(props.value) ? props.value : defaultSettings.VALUE}
                onChange={props.onValueChanged}
                onComplete={props.onValueChangeComplete}
                minimumTrackTintColor={getSelectedTrackColor()}
                maximumTrackTintColor={getUnselectedTrackColor()}
            />
        </View>
    )
}

LxReactVerticalSlider.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.number,
    onValueChanged: PropTypes.func,
    onValueChangeComplete: PropTypes.func,
    activeColor: PropTypes.string,
    unselectedTrackColor: PropTypes.string,
    disabled: PropTypes.bool,
    containerStyle: PropTypes.object
}


