import {
    LxReactTableView,
    navigatorConfig,
    TopNavigator,
    useBottomSheet,
    LxReactText
} from "LxComponents";
import Icons from "IconLib";
import React, { useState } from "react";
import {View} from "react-native";
import globalStyles from "GlobalStyles";

export default ({navigation, route}) => {

    const [query, setQuery] = useState(""),
        iconKeys = Object.keys(Icons),
        contextMap = {};

    iconKeys.forEach(iconKey => {
        const [BottomSheet, openContext, closeContext] = useBottomSheet({
            useMinHeight: false
            }),
            icon = Icons[iconKey];
        contextMap[iconKey] = {
            open: openContext,
            close: closeContext,
            menuElem: <BottomSheet>
                {React.createElement(icon, {
                    fill: "white",
                    height: 94,
                    width: 94,
                    style: {
                        alignSelf: "center",
                        margin: "15%"
                    }
                })}
            </BottomSheet>
        }
    });

    navigation.setOptions(
        navigatorConfig({
            animationType: AnimationType.MODAL,
            prompt: 'import Icons from "IconLib"',
            title: "IconLib",
            type: TopNavigator.NavigatorType.Search,
            searchState: useState(false),
            typeProps: {
                textChanged: query => setQuery(query.toLowerCase())
            }
        })
    )

    const getTableContent = query => {
        let filteredKeys = iconKeys.filter(key => {
            return key.toLowerCase().includes(query);
        });
        return [
            {
                rows: filteredKeys.sort().map(iconKey => {
                    return getCellForIcon({ iconKey });
                })
            }
        ]
    }

    const getCellForIcon = ({ iconKey }) => {
        return {
            content: {
                leftIconSrc: Icons[iconKey],
                leftIconColor: globalStyles.colors.red,
                title: iconKey.capitalize()
            },
            action: () => {
                contextMap[iconKey].open()
            }
        }
    }

    const getEmpty = () => {
        return (
            <View style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center"
            }}>
                <LxReactText style={{color: globalStyles.colors.white}}>{`No Icon found for "${query}"...`}</LxReactText>
            </View>
        )
    }

    return (
        <>
            <LxReactTableView
                tableContent={getTableContent(query)}
                emptyComp={getEmpty}
                insets={["bottom"]}
            />
            {
                Object.values(contextMap).map(contextObj => {
                    return contextObj.menuElem;
                })
            }
        </>
    )
}
