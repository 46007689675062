import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {Text, View} from "react-native";

export function SortableItem({id, children}) {
    const {
        setNodeRef,
        transform,
        transition,
    } = useSortable({id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        backgroundColor: "transparent",
        width: "100%",
    };

    return (
        <div style={style} ref={setNodeRef}>
            {children}
        </div>
    );
}
