import {createStackNavigator, TransitionPresets} from '@react-navigation/stack';
import {
    TopNavigator,
    useSetURLHook,
    LxReactCardScreenTopNavigator,
    navigatorConfig,
    AmbientContext
} from "LxComponents";
import {useContext, useEffect} from "react";
import TrustListScreen from "./trustListScreen";
import TrustContextProvider from "./trustContextProvider";

const screenOptions = (...params) => {
    return {
        ...TopNavigator.forDefault(),
        ...TransitionPresets.ModalFadeTransition,
        ...navigatorConfig(LxReactCardScreenTopNavigator(...params))
    }
}


function TrustNavigator({navigation, route}) {
    const { isAmbientMode } = useContext(AmbientContext)
    useSetURLHook({
        urlPart: UrlStartLocation.TRUST,
        appendIndex: 1
    });

    useEffect(() => {
        if (isAmbientMode) {
            navigation.setOptions({
                ...navigatorConfig({
                    headerShown: !isAmbientMode,
                    isAmbientMode
                })
            })
        }
    }, [isAmbientMode])

    const Stack = createStackNavigator();

    return (
        <TrustContextProvider>
            <Stack.Navigator initialRouteName={ScreenState.TrustListScreen}
                             screenOptions={(params) => {
                                 let options = {
                                     ...screenOptions(params, isAmbientMode),
                                     ...(isAmbientMode ? {cardOverlayEnabled: false} : {})
                                 }
                                 return options;
                             }}>
                <Stack.Screen  name={ScreenState.TrustListScreen} component={TrustListScreen} />
            </Stack.Navigator>
        </TrustContextProvider>
    );
}

export default TrustNavigator;
