'use strict';

import {
    LxReactText,
    LxReactTableViewLxViewAdapter,
    LxReactTableView,
    LxReactVerticalContainer,
    LxReactPressable
} from "LxComponents";

window.GUI = function (GUI) {
    class BurgerMenuScreen extends GUI.Screen {
        //region Static
        static Template = class {
            //region Static
            static getMenuListTemplate() {
                return $(
                    "<div class='menu-list-container'>"
                        + BurgerMenuScreen.Template.getMiniserverListTemplate()
                        + "<div class='menu-list-container__right-container'>"
                            + BurgerMenuScreen.Template.getHeaderTemplate()
                            + BurgerMenuScreen.Template.getMenuListTableViewTemplate()
                            + BurgerMenuScreen.Template.getBottomTemplate()
                    + "</div>"
                    + "</div>"
                );
            }

            static getHeaderTemplate() {
                return "<div class='menu-list-container__header'>" +
                        "<div class='header__left-container'>" +
                            "<div class='left-container__title'/>" +
                            "<div class='left-container__subtitle'/>" +
                        "</div>" +
                        "<div class='header__right-icon-container'>" +
                            ImageBox.getResourceImageWithClasses(Icon.Menu.INFO, "right-icon-container__icon") +
                        "</div>" +
                    "</div>";
            }

            static getMenuListTableViewTemplate() {
                return "<div class='right-container__tableView'></div>";
            }

            static getMiniserverListTemplate() {
                return "<div class='menu-left-side-container'>" +
                    "<div class='menu-left-side-container__list' />" +
                    "<div class='miniserver-list-container__help-container' />" +
                    "</div>";
            }

            static getMiniserverTemplateCell(initials, msName) {
                return $(
                    "<div class='miniserver-selector-cell'>" +
                        "<div class='miniserver-selector-cell__background'></div>" +
                        "<div class='miniserver-selector-cell__title' title='" + msName + "'>" +
                            initials +
                        "</div>" +
                        "<div class='miniserver-selector-cell__favorite-container'>" +
                            ImageBox.getResourceImageWithClasses(Icon.Menu.FAVORITES_MINISERVER, "miniserver-selector-cell__favorite-icon") +
                        "</div>" +
                    "</div>"
                );
            }

            static getMoreTemplateCell() {
                return $("<div class='miniserver-more-cell'>" + "•••" + "</div>");
            }

            static getHelpTemplateCell() {
                return $("<div class='miniserver-help-cell'>" + ImageBox.getResourceImageWithClasses(Icon.MESSAGE_CENTER.HELP, "miniserver-list-container__help-button") + "</div>");
            }

            static getBottomTemplate() {
                return "<div class='menu-list-container__bottom' />";
            }
            //endregion Static


        }; //endregion Static

        constructor() {
            super(BurgerMenuScreen.Template.getMenuListTemplate());
            Object.assign(this, StateHandler.Mixin);
            this.needsMoreButton = PlatformComponent.getPlatformInfoObj().platform !== PlatformType.Webinterface;
        }

        reactHeaderShown = false;

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this._firstLoad = true;
                this._previousHasValidData = false;
                this.listContainerElem = this.element.find('.menu-left-side-container__list');
                if (PairedAppComponent.isPaired()) {
                    this.helpContainer = this.element.find('.menu-list-container__bottom');
                } else {
                    this.element.find('.menu-list-container__bottom').hide();
                    this.helpContainer = this.element.find('.miniserver-list-container__help-container');
                }
                this.tableViewContainer = this.element.find('.right-container__tableView');
                this.elements = {
                    headerLeft: this.element.find(".header__left-container"),
                    headerTitle: this.element.find('.left-container__title'),
                    headerSubtitle: this.element.find('.left-container__subtitle'),
                    headerIconContainer: this.element.find('.header__right-icon-container')
                };
                this._allMiniservers = PersistenceComponent.getAllMiniserver(true);

                this._generateHeader();
                this.headerCloseBtn = new GUI.LxButton(this, this.elements.headerLeft);
                this.addToHandledSubviews(this.headerCloseBtn);

                this._addInfoButton();

                this.tableContent = this._getMenuEntriesTableViewContent();
                this.tableViewDataSource = tableViewDataSource(this.tableContent, null, this);
                this.tableViewDelegate = tableViewDelegate(this.tableContent);

                var prmses = [];
                if (Debug.React.NewTableView) {
                    prmses.push(this.appendReactComp({
                        reactComp: LxReactTableViewLxViewAdapter,
                        compProps: {
                            dataSource: this.tableViewDataSource,
                            delegate: this.tableViewDelegate,
                            performanceStyle: LxReactTableView.PerformanceStyle.Normal,
                            key: "menu-table",
                            hideItemSeparator: true,
                            showSectionSeparator: true,
                            styles: { List: {paddingHorizontal: 0}}
                        },
                        target: this.tableViewContainer
                    }).then(tableView => this.tableView = tableView));
                } else {
                    this.tableView = new GUI.TableViewV2(this.tableViewDataSource, this.tableViewDelegate);
                    prmses.push(this.appendSubview(this.tableView, this.tableViewContainer));
                }

                if (PairedAppComponent.isPaired()) {
                    this.element.toggleClass("burger-menu-screen--paired", true);
                } else {
                    prmses.push(this.appendReactComp({
                        reactComp: LxReactVerticalContainer,
                        compProps: {
                            childComps: this._getMiniserverSidebarContent(),
                            childHeight: 46,
                            moreComp: this.needsMoreButton ? this._getMiniserverSidebarShowMoreContent() : null,
                            moreHeight: 46,
                            key: "ms-list"
                        },
                        target: this.listContainerElem
                    }).then(tableView => this.msSideList = tableView));
                }

                this._removeConnEstablishedFn = CompChannel.on(CCEvent.ConnEstablished, function () {
                    this._handleConnectionState();
                }.bind(this));
                this._removeConnClosedFn = CompChannel.on(CCEvent.ConnClosed, function () {
                    this._handleConnectionState();
                }.bind(this));

                return Q.all(prmses).then(() => {
                    this._registerForStates(WEATHER_UUID, ["hasValidData"]);
                    this.reload();
                });
            }.bind(this));
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments);

            if (this._firstLoad) {
                var helpButtonElem, helpButton, moreButtonElem, moreButton, swipeOptions;

                this._appendHelpButton();

                helpButtonElem = this.element.find('.miniserver-help-cell');
                helpButton = new GUI.LxButton(this, helpButtonElem, Color.BUTTON_GLOW);
                this.addToHandledSubviews(helpButton);

                helpButton.onButtonTapped = function () {
                    this._showState(ScreenState.HelpFeedbackScreen);
                }.bind(this);

                this.headerCloseBtn.onButtonTapped = () => {
                    this.ViewController.navigateBack();
                }

                swipeOptions = {
                    swipeVelocityX: 0.1
                };
                this.swipeHandler = Hammer(this.element[0], swipeOptions).on('swipeleft', function (ev) {
                    this.ViewController.navigateBack();
                    ev.stopPropagation();
                    ev.preventDefault();
                }.bind(this));
                this._firstLoad = false;
            }
        }

        viewWillDisappear() {
            if (this.swipeHandler) {
                this.swipeHandler.dispose();
            }

            this._unregisterStates(WEATHER_UUID);

            return super.viewWillDisappear(...arguments);
        }

        destroy() {
            this._removeConnEstablishedFn();

            this._removeConnClosedFn();

            return super.destroy(...arguments);
        }

        receivedStates(states) {
            if (this._previousHasValidData !== states.hasValidData) {
                this.reload();
            }

            this._previousHasValidData = states.hasValidData;
        }

        reload() {
            var shouldReload = false;
            this.tableContent = this._getMenuEntriesTableViewContent();
            shouldReload |= this.tableViewDataSource.update(this.tableContent);
            shouldReload |= this.tableViewDelegate.update(this.tableContent);
            return shouldReload ? this.tableView.reload() : Q.resolve(true);
        }

        styleForTableView() {
            return TableViewStyle.MENU;
        }

        getAnimation() {
            return AnimationType.OVERLAY_RIGHT;
        }

        _showState(state, details) {
            return this.ViewController.navigateBack().then(function () {
                var animation = AnimationType.MODAL;

                if (state === "GUI.WeatherScreenV2") {
                    animation = AnimationType.HD_MODAL_FULLSCREEN;
                }

                return NavigationComp.showState(state, details, animation);
            });
        }

        _handleConnectionState() {
            var reachMode = CommunicationComponent.getCurrentReachMode();

            if (reachMode === ReachMode.LOCAL) {
                reachMode = _("miniserver.connected.internal");
            } else if (reachMode === ReachMode.REMOTE) {
                reachMode = _("miniserver.connected.external");
            } else {
                reachMode = _('miniserver.not-connected');
            }

            console.log("####### " + reachMode);
            this.elements.headerSubtitle.text(reachMode);
        }

        _addInfoButton() {
            var infoButton;
            infoButton = new GUI.LxButton(this, this.elements.headerIconContainer, Color.BUTTON_GLOW);
            this.addToHandledSubviews(infoButton);

            infoButton.onButtonTapped = function () {
                this._showState(ScreenState.AboutMiniserver);
            }.bind(this);
        }

        // ##################################################################
        // ### Miniserver Table
        // ##################################################################

        _getMiniserverSidebarContent() {
            var rows,
                title,
                activeMs = ActiveMSComponent.getActiveMiniserver(),
                activeMsSerial = activeMs ? activeMs.serialNo : null,
                borderStyle;

            rows = this._allMiniservers.map((miniserver, idx) => {
                if (activeMsSerial === miniserver.serialNo) {
                    borderStyle = {
                        borderColor: window.Styles.colors.brand,
                        borderStyle: "solid",
                        borderWidth: 2
                    }
                } else {
                    borderStyle = {};
                }
                title = this._getTitleForMiniserver(miniserver);

                return this._getSideBarCompObj(title.substring(0, 2), title, this._connectToMsPress.bind(this), [miniserver], borderStyle);
            });
            return rows;
        }


        _getSideBarCompObj(text, key, onPressFn, onPressArgs, borderStyle) {
            return {
                comp: LxReactPressable,
                props: {
                    onPress: onPressFn,
                    onPressArgs: onPressArgs,
                    pKey: key,
                    key: key,
                    style: {
                        height: 40,
                        width: 40,
                        backgroundColor: Styles.colors.fill.quaternary,
                        borderRadius: "50%",
                        ...borderStyle
                    },
                    childComp: this._getSideBarTextCompObj(text)
                }
            }
        }

        _getSideBarTextCompObj(text) {
            return {
                comp: LxReactText,
                props: {
                    children: text,
                    numberOfLines: 1,
                    ellipsizeMode: "clip",
                    style: {
                        ...Styles.textStyles.miniserver,
                        color: Styles.colors.text.primary,
                        textOverflow: "clip",
                        paddingVertical: "auto",
                        margin: "auto"
                    }
                }
            }
        }

        _getMiniserverSidebarShowMoreContent() {
            return this._getSideBarCompObj("...", "moreBtn", this._moreButtonOnPress.bind(this));
        }

        _moreButtonOnPress() {
            this._showState(ScreenState.MiniserverSelection);
        }

        _getTitleForMiniserver(ms) {
            return ms.msName || ms.serialNo || ms.localUrl || ms.remoteUrl;
        }

        _connectToMsPress(ev, miniserver) {
            var currentActiveMiniserver = ActiveMSComponent.getActiveMiniserver();

            if (currentActiveMiniserver.serialNo !== miniserver.serialNo) {
                NavigationComp.connectTo(miniserver);
            } else {
                this.ViewController.navigateBack();
            }
        }

        _appendHelpButton() {
            this.helpContainer.css('fill', Color.TEXT_SECONDARY_B);
            this.helpContainer.append(BurgerMenuScreen.Template.getHelpTemplateCell());
        }

        // ##################################################################
        // ### Menu Entries TableView
        // ##################################################################
        _getMenuEntriesTableViewContent() {
            const firstRows = this._generateFirstSection(),
                secondRows = this._generateSecondSection(),
                thirdRows = this._generateThirdSection(),
                sections = [];

            firstRows.length > 0 && sections.push({ rows: firstRows });
            secondRows.length > 0 && sections.push({ rows: secondRows });
            thirdRows.length > 0 && sections.push({ rows: thirdRows });

            return sections;
        }

        _generateHeader() {
            var serialNo = ActiveMSComponent.getMiniserverSerialNo(),
                miniserver = PersistenceComponent.getAllMiniserver()[serialNo],
                reachMode = CommunicationComponent.getCurrentReachMode();

            if (reachMode === ReachMode.LOCAL) {
                reachMode = _("miniserver.connected.internal");
            } else if (reachMode === ReachMode.REMOTE) {
                reachMode = _("miniserver.connected.external");
            } else {
                reachMode = _('miniserver.not-connected');
            }

            if (miniserver === undefined) {
                return null;
            }

            this.elements.headerTitle.text(miniserver.msName);
            this.elements.headerSubtitle.text(reachMode);
        }

        _generateFirstSection() {
            var result = [],
                weatherStates,
                hasWeatherService;
            weatherStates = SandboxComponent.getStatesForUUID(WEATHER_UUID);
            hasWeatherService = weatherStates && weatherStates.states.hasValidData;
            result = SandboxComponent.getBurgerMenuFirstSectionItems(hasWeatherService, this._showState.bind(this));
            return result.map(
                row => this._adoptCellContentForReact(row)
            );
        }

        _generateSecondSection() {
            return SandboxComponent.getBurgerMenuSecondSectionItems(this._showState.bind(this)).map(
                row => this._adoptCellContentForReact(row)
            );
        }

        _adoptCellContentForReact(row) {
            row.content.cellElementStyle = {
                minHeight: 42
            }
            return row;
        }

        _generateThirdSection() {
            var result = [];
            result.pushObject(SandboxComponent.getSwitchUserItem(this._showState.bind(this)));
            return result.map(
                row => this._adoptCellContentForReact(row)
            );
        }

    }

    GUI.BurgerMenuScreen = BurgerMenuScreen;
    return GUI;
}(window.GUI || {});

