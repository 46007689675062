'use strict';

define("IRoomControllerV2ControlCommandSrc", ["ControlCommandSrc", "IRoomControllerV2ControlEnums"], function (ControlCommandSrc, IRoomControllerV2ControlEnums) {
    return class IRoomControllerV2ControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [];

            if (this._shouldShowTimerEndActions(states)) {
                if (states.overrideEntries[0].isTimer) {
                    cmds.push(this.createCommand(_("controls.daytimer.timer.end"), Commands.I_ROOM_V2_CONTROLLER.OVERRIDE.STOP));
                } else if (states.overrideEntries[0].reason === IRoomControllerV2ControlEnums.Reason.COMFORT_OVERRIDE && states.comfortTemperatureOffset !== 0) {
                    cmds.push(this.createCommand(_("controls.ircv2.comfort-adoption.end"), Commands.I_ROOM_V2_CONTROLLER.OVERRIDE.STOP));
                } else if (states.overrideEntries[0].source) {
                    cmds.push(this.createCommand(_("controls.ircv2.timer.end-mode", {
                        mode: '"' + states.overrideEntries[0].source + '"'
                    }), Commands.I_ROOM_V2_CONTROLLER.OVERRIDE.STOP));
                } else {
                    cmds.push(this.createCommand(_("controls.ircv2.timer.end-mode", {
                        mode: control.getNameOfActiveMode()
                    }), Commands.I_ROOM_V2_CONTROLLER.OVERRIDE.STOP));
                }
            } else {
                cmds.push(this.createDeactivatable(_("controls.daytimer.timer.start"), function () {
                    NavigationComp.showState(IRoomControllerV2ControlEnums.ScreenState.OVERRIDE_SCREEN, {
                        control: control,
                        activeMode: states.activeMode
                    });
                }));
            }

            return cmds;
        }

        /**
         * Weather or not the current overrideEntry should add the timer end actions
         * @param states
         * @return {boolean}
         * @private
         */
        _shouldShowTimerEndActions(states) {
            if (states.hasCurrentOverrideEntry) {
                return states.overrideEntries[0].reason === IRoomControllerV2ControlEnums.Reason.COMFORT_OVERRIDE || states.overrideEntries[0].reason === IRoomControllerV2ControlEnums.Reason.ECO_OVERRIDE || states.overrideEntries[0].reason === IRoomControllerV2ControlEnums.Reason.ECO_PLUS_OVERRIDE;
            }

            return false;
        }

    };
});
