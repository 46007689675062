import {View, ScrollView} from "react-native";
import {useMemo} from "react";
import {useLiveState, useControl, LxReactText, LxReactTableView, navigatorConfig} from "LxComponents";
import Wb2Enums from "./wallboxEnums.js"
import globalStyles from "GlobalStyles";
import {useBackNavigation} from "../../../react-comps/Components";

export default function WbHistory({navigation, route}) {
    const details = route.params;
    const controlUuid = details.controlUuid;

    const control = useControl(controlUuid);
    const liveState = useLiveState(controlUuid, ["sessionHistory"])

    navigation.setOptions(
        navigatorConfig({
            title: _('history')
        })
    );
    useBackNavigation(() => {
        navigation.goBack();
    });

    const styles = {
        cellTitle: {
            ...Wb2Enums.styles.cellTitle,
        },
        entryCntr: {
            marginLeft: globalStyles.sizes.small,
            marginRight: globalStyles.sizes.small,
            flexDirection: "column"
        },
        headerRow: {
            flexDirection: "row"
        },
        header: {
            ...Wb2Enums.styles.sectionHeader,
            marginLeft: 0
        },
        itemRow: {
            flexDirection: "row",
            minHeight: 54,
            alignItems: "center"
        },
        title: {
            ...Wb2Enums.styles.cellTitle,
            flex: 1
        },
        value: {
            ...Wb2Enums.styles.stateValue,
        }
    }

    const EntryRow = ({title, value, id}) => {
        return  <View style={styles.itemRow} key={id}>
            <LxReactText key="title" style={styles.title}>{title}</LxReactText>
            <LxReactText key="value" style={styles.value}>{value}</LxReactText>
        </View>
    }

    const renderEntry = ({id, title, connected, disconnected, duration, energy, user, cost, ocpp}) => {
        return <View style={styles.entryCntr} key={"entry-" + id}>
            <View style={styles.headerRow} key={"header"}>
                <LxReactText key="title" style={styles.header}>{title}</LxReactText>
            </View>
            <EntryRow title={_("controls.wallbox2.connected-from")} key={"connected"} value={connected} />
            <EntryRow title={_("controls.wallbox2.connected-until")} key={"disconnected"} value={disconnected} />
            <EntryRow title={_("controls.wallbox2.duration")} key={"duration"} value={duration} />
            {
                control?.getMeterIsConnected ?
                <EntryRow title={_("controls.wallbox2.charged-energy")} key={"energy"} value={energy} /> : null
            }
            { (user && user !== "--") ?
                    <EntryRow title={_("controls.wallbox2.user-id")} key={"user"} value={user} /> : null }
            { nullEmptyString(cost) ?
                    <EntryRow title={_("controls.wallbox2.total-cost")} value={cost} key={"cost"} /> : null }
            { ocpp ?
                <EntryRow title={_("controls.wallbox2.ext-approval")} value={ocpp} key={"ocpp"} /> : null }
        </View>
    }

    const renderEntries = () => {
        let result = [],
            session,
            connectText, disconnectText, durationText, energyText, userIdText, costText,
            entryTitle;

        if (liveState.states.sessionHistory) {
            liveState.states.sessionHistory.forEach((historyDay) => {
                historyDay.entries.forEach((entry) => {
                    session = entry.session;
                    entryTitle = LxDateFromUnix(session.connect).format(DateType.DateText);
                    connectText = LxDateFromUnix(session.connect).format(DateType.DateAndTimeShort);
                    disconnectText = LxDateFromUnix(session.disconnect).format(DateType.DateAndTimeShort);
                    durationText = LxDate.formatSecondsShort(session.disconnect - session.connect, true, true, false);
                    energyText = lxUnitConverter.convertAndApply(control.details.totalFormat, session.energy);
                    userIdText = session.user || "--";
                    costText = (session.price && !control.shouldHidePrice(session.price)) ?
                        lxFormat(ActiveMSComponent.getCurrencyFormat(), session.price)
                        : null;

                    result.pushObject(renderEntry({
                        id: session.disconnect + "",
                        title: entryTitle,
                        connected: connectText,
                        disconnected: disconnectText,
                        duration: durationText,
                        energy: energyText,
                        user: userIdText,
                        cost: costText,
                        ocpp: session.extAuthority
                    }));
                });
            });
        }
        return result;
    };



    return <View style={Wb2Enums.styles.rootCntr}>
        <ScrollView style={Wb2Enums.styles.contentCntr}>
            {renderEntries()}
        </ScrollView>
    </View>
}
