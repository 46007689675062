'use strict';

window.GUI = function (GUI) {

    class TableViewContentCollector {
        /**
         * Creates a new instance responsible for combining the infos of both the dataSource and the delegate into one
         * tableContent-object, just like "newer" screens pass them to dataSource and delegate.
         * Required as some older screens still only make use of the individual methods instead of passing the whole
         * dataset to the table view via a tableContent-object.
         * @param tableView the table reference for which this collector is used
         * @param dataSource the dataSource that provides the data for this table
         * @param delegate the delegate that is used for callbacks/actions
         * @param [isListView] if true, the data provided should be retrieved like a listView
         */
        constructor(tableView, dataSource, delegate, isListView = false) {
            this.tableView = tableView;
            this.ds = dataSource;
            this.del = delegate;
            this.isListView = isListView;
        }

        collectContent() {
            var tableContent = {},
                numberOfSections = this._callDs("numberOfSections", this.tableView),
                sections = [];

            if (this.isListView) {
                this._setAttr(tableContent, "styleForReusingListView", this._callDs("styleForReusingListView", this.tableView));
            } else {
                this._setAttr(tableContent, "styleForTableView", this._callDs("styleForTableView", this.tableView));
            }

            for (var i = 0; i < numberOfSections; i++) {
                sections.push(this.collectSectionContent(i));
            }
            this._setAttr(tableContent, "sections", sections);

            this._addCallable(tableContent, "processAtTheEndOfReload");

            return tableContent;
        }

        collectSectionContent(sectionIdx) {
            var sectionContent = {},
                numberOfRowsInSection = this._callDs("numberOfRowsInSection", sectionIdx, this.tableView),
                rowList = [];

            // header
            this._setAttr(sectionContent, "headerTitle", this._callDs("titleForHeaderInSection", sectionIdx, this.tableView));
            this._setAttr(sectionContent, "headerImage", this._callDs("imageForHeaderInSection", sectionIdx, this.tableView));
            this._setAttr(sectionContent, "headerStrongTitle", this._callDs("strongTitleForHeaderInSection", sectionIdx, this.tableView));
            this._setAttr(sectionContent, "headerBoldTitle", this._callDs("boldTitleForHeaderInSection", sectionIdx, this.tableView));
            this._setAttr(sectionContent, "headerDescription", this._callDs("descriptionForHeaderInSection", sectionIdx, this.tableView));
            this._setAttr(sectionContent, "sectionRightButtonTitle", this._callDs("rightButtonTitleForHeaderInSection", sectionIdx, this.tableView));
            this._setAttr(sectionContent, "sectionRightButtonColor", this._callDs("rightButtonColorForHeaderInSection", sectionIdx, this.tableView));
            this._setAttr(sectionContent, "headerElement", this._callDs("sectionHeaderElement", sectionIdx, this.tableView));
            this._setAttr(sectionContent, "headerContent", this._callDs("sectionHeaderContent", sectionIdx, this.tableView));
            this._setAttr(sectionContent, "hideItemSeparator", this._callDs("hideItemSeparator", sectionIdx, this.tableView));

            this._addCallable(sectionContent, "didSelectHeader", sectionIdx);
            this._addCallable(sectionContent, "rightSectionButtonTapped", sectionIdx);
            this._addCallable(sectionContent, "didMoveCell", sectionIdx);
            this._addCallable(sectionContent, "didRemoveCell", sectionIdx);
            // section itself
            this._setAttr(sectionContent, "customClass", this._callDs("customClassForSection", sectionIdx, this.tableView));
            this._setAttr(sectionContent, "resetScrollLeftOnReload", this._callDs("resetScrollLeftOfSectionOnReload", sectionIdx, this.tableView));

            // rows
            for (var i = 0; i < numberOfRowsInSection; i++) {
                rowList.push(this.collectRowContent(sectionIdx, i));
            }
            this._setAttr(sectionContent, "rows", rowList);

            // footer
            this._setAttr(sectionContent, "footer", this._callDs("contentForFooterInSection", sectionIdx, this.tableView));
            this._setAttr(sectionContent, "footerTitle", this._callDs("titleForFooterInSection", sectionIdx, this.tableView));
            this._setAttr(sectionContent, "footerColor", this._callDs("colorForFooterInSection", sectionIdx, this.tableView));
            this._setAttr(sectionContent, "footerElement", this._callDs("sectionFooterElement", sectionIdx, this.tableView));

            this._addCallable(sectionContent, "didSelectFooter", sectionIdx);

            return sectionContent;
        }

        collectRowContent(sectionIdx, rowIdx) {
            var rowContent = {};

            // DataSource
            this._setAttr(rowContent, "content", this._callDs("contentForCell", null, sectionIdx, rowIdx, this.tableView));
            if (this.isListView) {
                this._setAttr(rowContent, "type", this._callDs("cellTypeForRowInList", rowIdx, this.tableView));
                this._setAttr(rowContent, "height", this._callDs("heightForCellInRow", rowIdx, this.tableView));
            } else {
                this._setAttr(rowContent, "type", this._callDs("cellTypeForCellAtIndex", sectionIdx, rowIdx, this.tableView));
            }
            this._setAttr(rowContent, "cell", this._callDs("cellForIndex", sectionIdx, rowIdx));

            // Delegate
            if (!this._addCallable(rowContent, "didSelectCell", sectionIdx, rowIdx, "action")) {
                this._addCallable(rowContent, "action", sectionIdx, rowIdx);
            }

            this._addCallable(rowContent, "didRequestRemovingCell", sectionIdx, rowIdx);
            this._addCallable(rowContent, "didRemoveCell", sectionIdx, rowIdx);


            this._addCallable(rowContent, "showSortingContextMenu", sectionIdx, rowIdx);
            this._addCallable(rowContent, "hideSortingContextMenu", sectionIdx, rowIdx);
            this._addCallable(rowContent, "startSorting", sectionIdx, rowIdx);
            if (this.isListView) {
                if (!this._addCallable(rowContent, "didSelectCell", sectionIdx, rowIdx, "onListCellTapped")) {
                    this._addCallable(rowContent, "action", sectionIdx, rowIdx, "onListCellTapped");
                }
                this._addCallable(rowContent, "buttonTapped", sectionIdx, rowIdx, "onListCellRightButtonTapped");

                this._addCallable(rowContent, "didMoveListCell", sectionIdx, rowIdx);
                this._addCallable(rowContent, "removeListCell", sectionIdx, rowIdx);
            }

            // ComfortActionCell
            this._addCallable(rowContent, "onCellHit", sectionIdx, rowIdx);
            this._addCallable(rowContent, "onCellTick", sectionIdx, rowIdx);
            this._addCallable(rowContent, "onCellReleased", sectionIdx, rowIdx);
            this._addCallable(rowContent, "onCellDoubleTapped", sectionIdx, rowIdx);

            // Switch
            this._addCallable(rowContent, "onSwitchChanged", sectionIdx, rowIdx);

            // Slider
            this._addCallable(rowContent, "sliderDragged", sectionIdx, rowIdx);
            this._addCallable(rowContent, "sliderDragEnded", sectionIdx, rowIdx);
            this._addCallable(rowContent, "sliderClicked", sectionIdx, rowIdx);
            this._addCallable(rowContent, "userfriendlyValueForSlider", sectionIdx, rowIdx);

            // Automatic Designer Cell
            this._addCallable(rowContent, "didRemoveCapability", sectionIdx, rowIdx);
            this._addCallable(rowContent, "validityDidChange", sectionIdx, rowIdx);
            this._addCallable(rowContent, "onGroupChange", sectionIdx, rowIdx);

            // CHECKABLE
            this._addCallable(rowContent, "didRequestCheckingCell", sectionIdx, rowIdx);
            this._addCallable(rowContent, "didCheckCell", sectionIdx, rowIdx);

            // INPUT
            this._addCallable(rowContent, "textChanged", sectionIdx, rowIdx);
            this._addCallable(rowContent, "submitText", sectionIdx, rowIdx);
            this._addCallable(rowContent, "onFocus", sectionIdx, rowIdx);
            this._addCallable(rowContent, "onBlur", sectionIdx, rowIdx);

            // BUTTON, DEVICE_LEARNING_CELL, DEVICE_LEANING_CHECKABLE_CELL
            this._addCallable(rowContent, "buttonTapped", sectionIdx, rowIdx);
            this._addCallable(rowContent, "buttonDoubleTapped", sectionIdx, rowIdx);

            // BUTTON
            this._addCallable(rowContent, "buttonHit", sectionIdx, rowIdx);
            this._addCallable(rowContent, "buttonReleased", sectionIdx, rowIdx);
            this._addCallable(rowContent, "button2Tapped", sectionIdx, rowIdx);
            this._addCallable(rowContent, "button2Hit", sectionIdx, rowIdx);
            this._addCallable(rowContent, "button2Released", sectionIdx, rowIdx);
            this._addCallable(rowContent, "onButtonTicked", sectionIdx, rowIdx);
            this._addCallable(rowContent, "button2Ticked", sectionIdx, rowIdx);

            // rating
            this._addCallable(rowContent, "didChangeRating", sectionIdx, rowIdx);

            // DATE_PICKER
            this._addCallable(rowContent, "onPickerChanged", sectionIdx, rowIdx);

            // MOOD_CELL
            this._addCallable(rowContent, "favoriteStateChanged", sectionIdx, rowIdx);

            // DetailedContentBaseCell
            this._addCallable(rowContent, "onTopLeftButtonPress", sectionIdx, rowIdx);
            this._addCallable(rowContent, "onTopRightButtonPress", sectionIdx, rowIdx);

            // STEAK_SENSOR_CELL
            this._addCallable(rowContent, "onChangeDescription", sectionIdx, rowIdx);
            this._addCallable(rowContent, "onTargetTempChange", sectionIdx, rowIdx);

            // STEAK_TIMER_CELL
            this._addCallable(rowContent, "onTimerSetSelected", sectionIdx, rowIdx);
            this._addCallable(rowContent, "onTimerStop", sectionIdx, rowIdx);
            this._addCallable(rowContent, "onTimerStart", sectionIdx, rowIdx);

            // IRCV2TemperatureCell
            this._addCallable(rowContent, "onTemperatureScreenOpen", sectionIdx, rowIdx);

            this._addCallable(rowContent, "getSortableOptions", sectionIdx, rowIdx);
            this._addCallable(rowContent, "onViewWillAppear", sectionIdx, rowIdx);


            this._addCallable(rowContent, "markNotificationAsRead", sectionIdx, rowIdx);
            this._addCallable(rowContent, "favoriteSelected", sectionIdx, rowIdx);

            return rowContent;
        }

        _setAttr(target, attributeName, value) {
            if (value) {
                target[attributeName] = value;
            }
        }

        /**
         * Checks the delegate if there is a function with this name, if so stores it either with the functionName or
         * with an alternative storageName (which may be used in the tableContent) - if provided
         * @param target  where to store the callable
         * @param functionName  the function name of the callable
         * @param [section] which section (if applicable)
         * @param [row]   which row (if applicable)
         * @param [storageName]  this name will be used to store the fn, if provided - otherwise uses functionName
         * @returns {boolean}
         * @private
         */
        _addCallable(target, functionName, section, row, storageName) {
            var callable = this._safeGetCallable(functionName, section, row),
                added = false;
            if (callable && typeof callable === "function") {
                target[storageName || functionName] = callable;
                added = true;
            } else {
                //console.warn("Content Collector", "_addCallable did NOT add " + functionName + " on row/section: " + row + "/" + section);
            }
            return added;
        }

        /**
         * Important for old tableView content handling without an instance of tableViewDataSource, here some methods
         * may not be available!
         * @param fn    the functionName to use to acquire the info
         * @returns {null|*}
         * @private
         */
        _callDs(fn) {
            if (this.ds[fn]) {
                let passOnArgs = Array.from(arguments);
                passOnArgs = passOnArgs.splice(1, passOnArgs.length -1);
                return this.ds[fn].apply(this.ds, passOnArgs);
            } else {
                return null
            }
        }

        /**
         * Important for old tableContent Handling where no instance of tableViewDelegate is used, some delegate functions
         * may not be available --> especially the getCallable.
         * @param functionName
         * @param section
         * @param row
         * @returns {*}
         * @private
         */
        _safeGetCallable(functionName, section, row) {
            if (this.del.getCallable) {
                return this.del.getCallable.call(this.del, functionName, section, row)
            } else if (this.del[functionName]) {
                return this.del[functionName].bind(this.del);
            }
        }
    }

    GUI.TableViewContentCollector = TableViewContentCollector;
    return GUI;
}(window.GUI || {});
