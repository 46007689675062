'use strict';

define(["IntercomVideoProvider"], function (IntercomVideoProvider) {
    return class IntercomVideoView extends GUI.View {
        //region Static
        static Template = function () {
            var getTemplate = function Template(isAlphaApp) {
                return $('<div class="intercom-video-view__placeholder intercom-video-view__video-placeholder"' + getPlaceholderAttrs() + '>' + getConnectionContainer() + getErrorContainer(isAlphaApp) + // Important, don't change the order of getVideoContainer and getPlayContainer
                    // in combination with the css the playContainer is on top
                    getVideoContainer() + getPlayContainer() + '</div>');
            };

            var getPlaceholderAttrs = function getPlaceholderAttrs() {
                return 'data-loading="false" data-paused="false" data-error="false" data-video="false"';
            };

            var getErrorContainer = function getErrorContainer(isAlphaApp) {
                var errorContainer = '<div class="video-container__error-container">' + '' + '<div class="error-container__content">' + '       ' + ImageBox.getResourceImageWithClasses(Icon.Intercom.CAMERA, "content__icon");

                if (isAlphaApp) {
                    errorContainer += extendErrorContainerWithDebugInfo();
                }

                errorContainer += '</div>' + '</div>';
                return errorContainer;
            };

            var extendErrorContainerWithDebugInfo = function extendErrorContainerWithDebugInfo() {
                return '<div class="content__error-text"></div>' + '' + '<div class="content__retry-button">' + _("try-again") + '</div>';
            };

            var getPlayContainer = function getPlayContainer() {
                return '<div class="video-placeholder__play-container">' + '' + '<div class="play-container__button">' + '   ' + '<div class="button__icon-container">' + '       ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PLAY, "button__icon") + '   ' + '</div>' + '' + '</div>' + '</div>';
            };

            var getConnectionContainer = function getConnectionContainer() {
                return '<div class="video-placeholder__connecting-container">' + '<div class="connecting-container__content">' + '' + '<div class="content__spinner-container">' + '   ' + '<div class="spinner-container__spinner  css-spinner-b"></div>' + '' + '</div>' + '' + '<div class="content__label">' + _("controls.intercom.video.establish-connection") + '</div>' + '</div>' + '</div>';
            };

            var getVideoContainer = function getVideoContainer() {
                return '<div class="video-placeholder__video-container">' + '   ' + '<img src="" class="video-placeholder__video">' + '</div>';
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        //region Private
        //fast-class-es6-converter: extracted from defineStatic({}) content
        ViewStates = {
            SHOW_VIDEO_CONTENT: 1,
            SHOW_ERROR_CONTENT: 2,
            SHOW_PLAY_BUTTON: 3,
            SHOW_CONNECTING_VIEW: 4
        };
        DOWNLOAD_IMAGE_TIMEOUT = 8 * 1000; //endregion Private

        // 8 seconds equals timeout from the miniserver

        /**
         *
         * @param control the control for which to show the video
         * @constructor
         */
        constructor(control) {
            Debug.Control.Intercom.Video && console.log("IntercomVideoView ctor");
            super($('<div />'));
            Object.assign(this, StateHandler.Mixin);
            this.control = control;
            this.videoProvider = IntercomVideoProvider.shared();
            this.isAlphaApp = window.UPDATE_LEVEL === UpdateComp.UpdateLevel.ALPHA;
            this._streamWasRunning = false;
        }

        viewDidLoad() {
            var prmsArr = [super.viewDidLoad(...arguments)];
            this.element.append(IntercomVideoView.Template.getTemplate(this.isAlphaApp));
            this.boundPauseRequestStreamUrl = this.videoProvider.pauseRequestStreamUrl.bind(this.videoProvider, this.control);
            this.boundStartRequestStreamUrl = this.videoProvider.startRequestStreamUrl.bind(this.videoProvider, this, this.control);
            this.elements = this.elements || {};
            this.elements.videoPlaceholder = this.element.find('.intercom-video-view__video-placeholder');
            this.elements.video = this.element.find('.video-placeholder__video');
            this.elements.playButton = this.element.find('.play-container__button');

            if (this.isAlphaApp) {
                this.elements.errorText = this.element.find('.content__error-text');
                this.elements.retryButton = this.element.find('.content__retry-button'); // retry button handling

                this.retryButton = new GUI.LxButton(this, this.elements.retryButton[0], Color.BUTTON_GLOW, null, true);
                this.retryButton.onButtonTapped = this._handleRetry.bind(this);
                prmsArr.push(this.addToHandledSubviews(this.retryButton));
            } // play button handling


            this.playButton = new GUI.LxButton(this, this.elements.playButton[0], Color.BUTTON_GLOW, null, true);
            this.playButton.onButtonTapped = this._handlePlayButtonTapped.bind(this);
            prmsArr.push(this.addToHandledSubviews(this.playButton));

            this._registerForStates(this.control.uuidAction, ['lastBellTimestamp', 'lastBellEvents']);

            this._exchangeContent(this.ViewStates.SHOW_CONNECTING_VIEW);

            return Q.all(prmsArr);
        }

        viewDidAppear() {
            var baseCall = super.viewDidAppear(...arguments);

            if (this.isAlphaApp) {
                this.retryButton.onButtonTapped = this._handleRetry.bind(this);
            }

            this.playButton.onButtonTapped = this._handlePlayButtonTapped.bind(this);

            if (!this._shouldLoadLastBellImage()) {
                this.videoProvider.startRequestStreamUrl(this, this.control);
            } // discovers when the app is going to the background & foreground again


            document.addEventListener("pause", this.boundPauseRequestStreamUrl);
            document.addEventListener("resume", this.boundStartRequestStreamUrl);
            this.reachabilityUnregFn = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.ReachabilityUpdate, function (event, reachMode) {
                if (reachMode === ReachMode.NONE) {
                    this.boundPauseRequestStreamUrl();
                } else {
                    this.boundStartRequestStreamUrl();
                }
            }.bind(this));
            return baseCall;
        }

        viewWillDisappear() {
            Debug.Control.Intercom.Video && console.log(this.name, "viewWillDisappear");

            if (this.isAlphaApp) {
                this.retryButton.onButtonTapped = null;
            }

            this.playButton.onButtonTapped = null;

            if (this._shouldLoadLastBellImage()) {
                this._unregisterStates();
            } // pause the video as soon as the view disappears


            if (this.videoProvider.isRunning) {
                Debug.Control.Intercom.Video && console.info("   pause the video stream");
                this.videoProvider.pauseRequestStreamUrl(this.control);
            }

            document.removeEventListener("pause", this.boundPauseRequestStreamUrl);
            document.removeEventListener("resume", this.boundStartRequestStreamUrl);
            this.reachabilityUnregFn && this.reachabilityUnregFn();
            return super.viewWillDisappear(...arguments);
        }

        destroy() {
            Debug.Control.Intercom.Video && console.log(this.name, "destroy");
            this.videoProvider.stopRequestStreamUrl(this.control);
            this._streamWasRunning = false;
            return super.destroy(...arguments);
        }

        receivedStates(states) {
            this.states = states;
            Debug.Control.Intercom.Video && console.warn("-------------------------------------------");
            Debug.Control.Intercom.Video && console.warn(JSON.stringify(states.lastActivitiesTimestamps));

            if (states.lastActivitiesTimestamps) {
                Debug.Control.Intercom.Video && console.warn(states.lastActivitiesTimestamps[0].timestamp);
            }

            Debug.Control.Intercom.Video && console.warn(states.lastBellTimestamp);
            Debug.Control.Intercom.Video && console.warn(this._lastBellTs);
            Debug.Control.Intercom.Video && console.warn("-------------------------------------------");

            if (this._shouldLoadLastBellImage() && !this._bellImageDownloadTimeout) {
                if (states.hasLastActivities && states.lastActivitiesTimestamps[0].timestamp === states.lastBellTimestamp && states.lastBellTimestamp !== this._lastBellTs) {
                    this._lastBellTs = states.lastBellTimestamp;

                    this._loadBellImage();
                }
            }
        }

        // -------------------------------------------------------------------------------------------
        //                    Public New
        // -------------------------------------------------------------------------------------------
        startVideo() {
            this.videoProvider.startRequestStreamUrl(this, this.control);
        }

        stopVideo() {
            this.videoProvider.stopRequestStreamUrl(this.control);
        }

        pauseVideo() {
            Debug.Control.Intercom.Video && console.log("pauseVideo");
            this.videoProvider.pauseRequestStreamUrl(this.control);
        }

        // -------------------------------------------------------------------------------------------
        //                    IntercomVideoProvider Delegate calls
        // -------------------------------------------------------------------------------------------
        onStreamUrlReceived(streamUrl, isInitial) {
            Debug.Control.Intercom.Video && console.log(this.name, "onStreamUrlReceived: '" + streamUrl + "', isInitial=" + !!isInitial);

            // Check if the image is portrait
            var videoNode = this.elements.video[0],
                imgHeight = videoNode.naturalHeight,
                imgWidth = videoNode.naturalWidth;

            if (!HD_APP) {
                this._setVideoPortraitMode(imgHeight, imgWidth);
            }

            if (nullEmptyString(streamUrl)) {
                this.elements.video[0].onload = () => {
                    Debug.Control.Intercom.Video && console.log(this.name, "videoElement: onload: " + streamUrl);
                }
                this.elements.video[0].onerror = (ev) => {
                    Debug.Control.Intercom.Video && console.log(this.name, "videoElement: onerror: " + streamUrl);
                    this._exchangeContent(this.ViewStates.SHOW_ERROR_CONTENT);
                    this.onNoVideo && this.onNoVideo.call(this, ev);
                }
            } else {
                this.elements.video[0].onload = null;
                this.elements.video[0].onerror = null;
            }

            this.elements.video[0].src = streamUrl;


            if (!nullEmptyString(streamUrl) && isInitial) {
                // nothing to do, still loading
                Debug.Control.Intercom.Video && console.log(this.name, "  >> still loading!");
                this._exchangeContent(this.ViewStates.SHOW_CONNECTING_VIEW);

            } else if (this.isVisible()) {
                Debug.Control.Intercom.Video && console.log(this.name, "  >> go!");
                this._exchangeContent(this.ViewStates.SHOW_VIDEO_CONTENT);

                this.onVideoStarted && this.onVideoStarted.call(this);
            }
        }

        _setVideoPortraitMode(imgHeight, imgWidth) {
            var isPortrait = false; // a squared image also has to be viewed as portrait
            // the default container has format 4:3

            if (imgWidth !== 0 && imgHeight !== 0 && imgHeight >= imgWidth) {
                isPortrait = true;
            }

            this.element && this.element.toggleClass("intercom-video-view__portrait", isPortrait);
        }

        onStreamUrlError(error) {
            Debug.Control.Intercom.Video && console.log(this.name, "VideoError --> " + JSON.stringify(error));
            this._exchangeContent(this.ViewStates.SHOW_ERROR_CONTENT);
            if (this.isAlphaApp) {
                this.elements.errorText.html(this.control.getVideoFailedTexts(error).message);
            }
            this.onNoVideo && this.onNoVideo.call(this, error);
        }

        onStreamStopped() {
            this.onVideoStopped && this.onVideoStopped.call(this);
        }

        // -------------------------------------------------------------------------------------------
        //                    Private Methods
        // -------------------------------------------------------------------------------------------

        /**
         * Calculates the aspact ratio and returns it as a string eg. 16:9
         * @param imgNode the DOM img element
         * @returns {string}
         */
        _getAspectRatio(imgNode) {
            var imgWidth = imgNode.naturalWidth,
                imgHeight = imgNode.naturalHeight,
                ratio = this._gcd(imgWidth, imgHeight);

            return imgWidth / ratio + ":" + imgHeight / ratio;
        }

        /**
         * Greatest Common Divisor calculator
         * @param a
         * @param b
         * @returns {*}
         * @private
         */
        _gcd(a, b) {
            return b === 0 ? a : this._gcd(b, a % b);
        }

        _shouldLoadLastBellImage() {
            return Feature.INTERCOM_GEN_1_BELL_STATE && this.control.details.showBellImage && this.states.lastBellTimestamp.length && this.control.details.videoInfo.alertImage !== '' && this.ViewController && this.ViewController.alert;
        }

        _loadBellImage() {
            Debug.Control.Intercom.Video && console.warn("-------------------------------------------");
            Debug.Control.Intercom.Video && console.warn("Fetching Bell image");
            Debug.Control.Intercom.Video && console.warn("-------------------------------------------");
            this._bellImageDownloadTimeout = setTimeout(function () {
                // Fallback, if we can't download the image, start the video stream automatically
                Debug.Control.Intercom.Video && console.warn("-------------------------------------------");
                Debug.Control.Intercom.Video && console.warn("Started stream after image loader timeout");
                Debug.Control.Intercom.Video && console.warn("-------------------------------------------");
                this.videoProvider.startRequestStreamUrl(this, this.control);

                this._exchangeContent(this.ViewStates.SHOW_CONNECTING_VIEW);
            }.bind(this), this.DOWNLOAD_IMAGE_TIMEOUT); // 8 seconds
            // needed to fix miniserver issue

            setTimeout(function () {
                this.processAsync(ImageBox.getCamImage(this.control.uuidAction, this._lastBellTs), function (image) {
                    // same behavior as in intercomControlContentMissedView
                    if (image.mimeType === "jpg") {
                        clearTimeout(this._bellImageDownloadTimeout);
                        this._bellImageDownloadTimeout = null;
                        this.elements.video[0].src = image.image;

                        this._exchangeContent(this.ViewStates.SHOW_PLAY_BUTTON);
                    } else {
                        // Log needed --> checks other image types
                        console.warn("-------------------------------------------");
                        console.warn("image.mimeType doesn't match jpg is --> " + image.mimeType);
                        console.warn("-------------------------------------------");
                    }
                }.bind(this));
            }.bind(this), 1000);
        }

        _handlePlayButtonTapped() {
            this._streamWasRunning = true;
            this.videoProvider.startRequestStreamUrl(this, this.control);
        }

        _exchangeContent(viewState) {
            GUI.animationHandler.schedule(function () {
                this.elements.videoPlaceholder.attr("data-video", viewState === this.ViewStates.SHOW_VIDEO_CONTENT);
                this.elements.videoPlaceholder.attr("data-loading", viewState === this.ViewStates.SHOW_CONNECTING_VIEW);
                this.elements.videoPlaceholder.attr("data-error", viewState === this.ViewStates.SHOW_ERROR_CONTENT);
                this.elements.videoPlaceholder.attr("data-paused", viewState === this.ViewStates.SHOW_PLAY_BUTTON);
            }.bind(this));
        }

        // ###########################################################
        // -----------------------------------------------------------
        // Debug Only ------------------------------------------------
        // -----------------------------------------------------------
        // ###########################################################
        _handleRetry() {
            if (GUI.hasOwnProperty("DebugScreen")) {
                this.startVideo();

                this._exchangeContent(this.ViewStates.SHOW_CONNECTING_VIEW);
            }
        }

    };
});
