'use strict';
/*
 text
 centered   if ture, the text will be centered
 color      the background will be "tinted" and the text will have that color.
 textColor  the color to show the text in. if provided, this overrules the color-property for the text.
 */

window.GUI = function (GUI) {
    class TextCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(content) {
                return '<div class="content__text">' + content.text + '</div>';
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor() {
            super(...arguments);
            this.element.addClass("text-cell");
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.contentPlaceholder.append(TextCell.Template.getTemplate(this.content));
                this.elements = this.elements || {};
                this.elements.text = this.contentPlaceholder.find(".content__text");
                return this.updateContent(this.content);
            }.bind(this));
        }

        clickableAllowed() {
            return false;
        }

        // ---------------------------------------------------------------------------------------------------------
        //   Methods for super fast tableContent updates
        // ---------------------------------------------------------------------------------------------------------
        cellTypeForReuse() {
            return GUI.TableViewV2.CellType.TEXT;
        }

        updateContent(newContent) {
            return super._updateContent(...arguments);
        }

        /**
         * This Method is used to apply a new content object onto the cell instance. e.g. update texts and so on.
         * @param newContent
         * @private
         */
        _applyNewContent(newContent) {
            var promises = [super._applyNewContent(...arguments)];
            promises.push(GUI.animationHandler.schedule(function afApplyNewContentTextCell() {
                this.elements.text.html(newContent.text);
                this.element.toggleClass("text-cell--centered", !!newContent.centered);

                if (newContent.color) {
                    this.element.css("background-color", applyAlphaChannelToColorString(newContent.color, 0.24));
                } else {
                    this.element.css("background-color", "");
                }

                if (newContent.color || newContent.textColor) {
                    this.elements.text.css("color", newContent.textColor || newContent.color);
                } else {
                    this.elements.text.css("color", "");
                }
            }.bind(this)));
            return Q.all(promises);
        } // ---------------------------------------------------------------------------------------------------------
        // ---------------------------------------------------------------------------------------------------------


    }

    GUI.TableViewV2.Cells.TextCell = TextCell;
    GUI.TableViewV2.CellType.TEXT = "TextCell";
    return GUI;
}(window.GUI || {});
