'use strict';

define(["ClimateUsControlEnums"], function (CtrlEnums) {
    return class IrcListScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div />'));
            Object.assign(this, StateHandler.Mixin);
            this.control = details.control;
            this.states = {};
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function () {
                this._registerForStates(this.control.uuidAction, this.getStateIDs());
            }.bind(this));
        }

        viewWillDisappear() {
            this._unregisterStates();

            return super.viewWillDisappear(...arguments);
        }

        getAnimation() {
            return AnimationType.HD_OVERLAY;
        }

        titleBarText() {
            return Feature.HVAC_CLIMATE_CONTROLLER_INTEGRATION ? _('controls.climate.us.rooms') : _("controls.climate.us.room-controllers");
        }

        reloadTable() {
            this.tableContent = this._getControlSections(this.control.getStates());
            return super.reloadTable(...arguments);
        }

        // -----------------------------------------------------------------------------------------------
        //                      State Handler
        // -----------------------------------------------------------------------------------------------
        getStateIDs() {
            return ["controls"];
        }

        receivedStates(states) {
            if (JSON.stringify(states.controls) !== JSON.stringify(this.states.controls)) {
                this.reloadTable();
            }

            this.states = cloneObject(states);
        }

        // -----------------------------------------------------------------------------------------------
        //                      Private
        // -----------------------------------------------------------------------------------------------

        /**
         * Sorts all controls into its correct section
         * @param states
         * @return {Array}
         * @private
         */
        _getControlSections(states) {
            var ircV2Control,
                sortedControls = {},
                // Map of the controls, key is the demand, value is the array of controls matching the demand
                sections = [],
                currentDemand;

            if (states.isHeating) {
                currentDemand = CtrlEnums.Demand.HEATING;
            } else if (states.isCooling) {
                currentDemand = CtrlEnums.Demand.COOLING;
            } else {
                currentDemand = CtrlEnums.Demand.NONE;
            } // Sort each IRCv2 control into its correct section


            states.controls.forEach(function (control) {
                ircV2Control = ActiveMSComponent.getControlByUUID(control.uuid); // Controls may not be visualized and not be found in the LoxApp3.json

                if (ircV2Control) {
                    // Check if the demand section already exists, directly add the control if it does, add a new array if it doesn't
                    if (sortedControls[control.demand]) {
                        sortedControls[control.demand].push(ircV2Control);
                    } else {
                        sortedControls[control.demand] = [ircV2Control];
                    }
                }
            }); // =====================================================
            // Keep the order
            // 1. Current demand (if not "none")
            // 2. Heating or Cooling depending on the current demand
            // 2. Cooling or Heating depending on the current demand
            // 3. None, if it isn't the current demand
            // Always move the current demand section to the top

            if (sortedControls[currentDemand] && currentDemand !== CtrlEnums.Demand.NONE) {
                sections.pushObject(this._getSectionForDemand(currentDemand, sortedControls, states));
            }

            if (currentDemand !== CtrlEnums.Demand.HEATING) {
                sections.pushObject(this._getSectionForDemand(CtrlEnums.Demand.HEATING, sortedControls, states));
            }

            if (currentDemand !== CtrlEnums.Demand.COOLING) {
                sections.pushObject(this._getSectionForDemand(CtrlEnums.Demand.COOLING, sortedControls, states));
            } // as the "no demand" section won't be pushed first as current demand, always push last.


            sections.pushObject(this._getSectionForDemand(CtrlEnums.Demand.NONE, sortedControls, states)); // =====================================================

            return sections;
        }

        _getSectionForDemand(demand, sortedControls, states) {
            if (sortedControls[demand]) {
                return {
                    headerTitle: this._demandToReadableString(demand, states),
                    rows: sortedControls[demand].map(this._getIRCv2Cell.bind(this))
                };
            }
        }

        /**
         * Converts the demand ID to a human readable string
         * @param demandId
         * @return {*}
         * @private
         */
        _demandToReadableString(demandId, states) {
            var demandString;

            switch (parseInt(demandId)) {
                // demandId is a string, switch case only works on same types (like ====)
                case CtrlEnums.Demand.COOLING:
                    if (states.isCooling) {
                        demandString = _("controls.climate.us.mode.cooling");
                    } else {
                        demandString = _("controls.climate.demand.cooling");
                    }

                    break;

                case CtrlEnums.Demand.NONE:
                    demandString = _("controls.climate.demand.none");
                    break;

                case CtrlEnums.Demand.HEATING:
                    if (states.isHeating) {
                        demandString = _("controls.climate.us.mode.heating");
                    } else {
                        demandString = _("controls.climate.demand.heating");
                    }

                    break;

                default:
                    break;
            }

            return demandString;
        }

        /**
         * Simply returns a controlCell for the given control
         * @param control
         * @return {{type: (*|string), content: {control: *, displayAsCell: boolean, showGroupDetails: boolean}}}
         * @private
         */
        _getIRCv2Cell(control) {
            return {
                type: control.getCellType(),
                content: {
                    control: control,
                    displayAsCell: true,
                    showGroupDetails: false
                }
            };
        }

        _send(cmdFormat, temp) {
            SandboxComponent.sendCommand(this, this.control.uuidAction, Commands.format(cmdFormat, temp), null, this.control.isSecured, true // This is the "automatic" flag, enable it to prevent a "bad connection" popup
            );
        }

    };
});
