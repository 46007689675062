import {View} from "react-native-web";
import React, {useContext} from "react";
import {ButtonType, LxReactButton, LxReactText, ControlContext, AmbientContext} from "LxComponents";
import globalStyles from "GlobalStyles";

function LxControlLockedContent(props) {
    const {isAmbientMode} = useContext(AmbientContext)
    const {states, control} = useContext(ControlContext)

    const LockedNotice = (props) => {
        if (!states.universalLockReason) {
            return null;
        }

        let lockReason = states.universalLockReason;
        let containerStyle = {...styles.messageContainer};

        if (isAmbientMode) {
            containerStyle.background = globalStyles.colors.transparent;
        }

        return <View style={styles.container}>
            <LxReactText style={styles.title}>{_("control.lock.note")}</LxReactText>
            <View style={containerStyle}>
                <LxReactText style={styles.message}>{lockReason}</LxReactText>
            </View>
        </View>
    };

    const UnlockButton = () => {
        return <LxReactButton
            containerStyle={styles.buttonStyle}
            textStyle={styles.buttonTextStyle}
            buttonType={ButtonType.SECONDARY}
            text={_("control.lock.unlock-title")}
            onPress={() => control.unlockControl()}/>
    }

    if (!states.universalIsLocked) {
        return null;
    } else {
        return <View style={globalStyles.customStyles.fullWidth}>
            {states.universalLockReason && <LockedNotice/>}
            {states.universalIsUnlockable && <UnlockButton/>}
        </View>
    }
}

const styles = {
    container: {
        width: "100%"
    },
    title: {
        ...globalStyles.textStyles.body.bold,
        color: globalStyles.colors.text.primary,
        marginBottom: globalStyles.spacings.gaps.small
    },
    messageContainer: {
        padding: globalStyles.sizes.small,
        width: "100%",
        background: globalStyles.colors.grey["600"],
    },
    message: {
        ...globalStyles.textStyles.footNote.default,
        color: globalStyles.colors.text.secondary,
    },
    buttonStyle: {
        width: "100%",
        marginTop: globalStyles.spacings.gaps.small
    },
    buttonTextStyle: {
        textAlign: "center"
    }
}

export default LxControlLockedContent
