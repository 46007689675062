import { ScreenState } from "./daytimerControlEnums";

export default class DaytimerControlStatesSrc {
    static getStateSections(control, states) {
        let sections = [],
            section = {},
            rows = [];

        if (states.stateText !== "") {

            rows.push({
                content: {
                    title: _("controls.daytimer.entries"),
                    disclosureIcon: true
                },
                type: GUI.TableViewV2.CellType.GENERAL,
                action: this.showScreen.bind(this, control, ScreenState.CALENDAR)
            }); // if analog, add the default value cell

            if (control.details.analog) {
                rows.push({
                    content: {
                        title: _("controls.daytimer.default-value"),
                        disclosureText: lxFormat(control.details.format, states.defaultValue),
                        disclosureIcon: true
                    },
                    type: GUI.TableViewV2.CellType.GENERAL,
                    action: this.showScreen.bind(this, control, ScreenState.DEFAULT_VALUE)
                });
            }
            section.rows = rows;
            sections.push(section);

            return sections;
        }
    }

    static showScreen(control, screenState) {
        NavigationComp.showState(screenState, {control})
    }
}