import { useState, useEffect } from 'react';

/**
 * CountDown-Hook, will start counting if seconds provided > 0. Stops automatically when 0 is reached.
 * @param seconds from where to start the countdown from
 * @returns {number} seconds left in this countdown
 */
export default function useCountDown(seconds) {
    const [remaining, setRemaining] = useState(seconds);

    let timer;
    let targetTime;

    useEffect(() => {
        targetTime = Math.round(new Date().getTime() / 1000) + seconds;
        function handleTimerFired() {
            let _left = targetTime - Math.round(new Date().getTime() / 1000);
            setRemaining(_left);

            if (_left > 0) {
                timer = setTimeout(handleTimerFired, 1000);
            } else {
                clearTimeout(timer);
                timer = null;
            }
        }

        timer && clearTimeout(timer);
        if (remaining > 0) {
            timer = setTimeout(handleTimerFired, 1000);
        }

        return () => {
            timer && clearTimeout(timer);
            timer = null;
        };
    }, []);

    return remaining;
}
