import {createStateCell} from "../../UtilityComp/utilities/helper";
import globalStyles from "GlobalStyles";

export default class EnergyManagerControlStatesSrc {
    static getStateSections(control, states) {
        let sections = [],
            section = {
                rows: [],
            },
            valueColor = globalStyles.colors.red,
            title = _("controls.energyManager.mains"),
            currentValue;

        if (states.overflow) {
            valueColor = globalStyles.colors.green;
            title = _("controls.energyManager.overflow");
        }

        currentValue = lxUnitConverter.convertAndApply("%.1f kW", Math.abs(states.currentValue));
        section.rows.push(createStateCell(title, currentValue, null, null, null, valueColor));
        sections.push(section);

        return sections;
    }
}
