import Svg, { Path } from "react-native-svg"

function Error(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path d="M23.847 11.635a.523.523 0 010 .738L12.362 23.852a.517.517 0 01-.725 0L.152 12.373a.523.523 0 010-.738L11.631.156a.523.523 0 01.738 0l11.479 11.479zM11.499 6.5v8a.5.5 0 101 0v-8a.5.5 0 10-1 0zm.5 12.5a1 1 0 100-2 1 1 0 000 2z" />
        </Svg>
    )
}

export default Error
