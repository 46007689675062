'use strict';

define(["./enterUrlScreen.jsx"], function (EnterUrlComp) {
    return class EnterUrlScreen extends GUI.Screen {

        static ReactComp = EnterUrlComp.default;

        constructor() {
            super($("<div />"));
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.setUpComfortUI(true);
        }

        getAnimation() {
            return AnimationType.FADE;
        }

        getURL() {
            return "EnterUrl";
        }
    };
});
