'use strict';

define([], function () {
    return {
        OperatingMode: {
            HOLIDAY: 1,
            MONDAY: 3,
            SUNDAY: 9,
            HEATING: 10,
            COOLING: 11
        },
        CalendarMode: {
            YEARLY: 0,
            // on that date, every year
            EASTER: 1,
            // depends on the date of easter
            SPECIFIC: 2,
            // at a certain date only (not repeating)
            SPAN_THIS_YEAR: 3,
            // certain timespan this year
            SPAN_EACH_YEAR: 4,
            // certain timespan each year
            WEEKDAY: 5 // at a specific weekday.
        },
        WeekdayRepeatMode: {
            EVERY: 0,
            FIRST: 1,
            SECOND: 2,
            THIRD: 3,
            FOURTH: 4,
            LAST: 5
        },
        // Identifies the month number that indicates it has to be repeated every month.
        WeekdayEveryMonth: 13
    }
})
