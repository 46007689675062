'use strict';
/*
 uuid
 ts
 title
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.MINISERVER_IMAGE = "MiniserverImageCell";

    class MiniserverImageCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getTemplateWithImage = function getTemplateWithImage(tsString) {
                var cell = $('<div class="content__title">' + tsString + '</div>' + ImageBox.getResourceImageWithClasses('resources/Images/General/disclosure-icon.svg', 'content__disclosure-icon') + '<div class="content__flex" />');
                return cell;
            };

            return {
                getTemplateWithImage: getTemplateWithImage
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("miniserver-image-cell");
            this._hasImage = false;
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.contentPlaceholder.append(MiniserverImageCell.Template.getTemplateWithImage.call(this, this.content.title));
            this.elements.disclosureIcon = this.contentPlaceholder.find(".content__disclosure-icon");
            this.elements.disclosureIcon.hide();
            this.processAsync(ImageBox.getCamImage(this.content.uuid, this.content.ts), function (image) {
                var img;

                if (image.mimeType === "jpg") {
                    this._hasImage = true;
                    this.elements.disclosureIcon.show();
                    img = '<img class="content__image" src="' + image.image + '">';
                } else {
                    img = ImageBox.getResourceImageWithClasses("resources/Images/Controls/Intercom/icon-error.svg", "content__no-image");
                }

                this.contentPlaceholder.append(img);
            }.bind(this));
        }

        onSelected() {
            if (this._hasImage) {
                super.onSelected();
            }
        }

        clickableAllowed() {
            return true;
        }

    }

    GUI.TableViewV2.Cells.MiniserverImageCell = MiniserverImageCell;
    return GUI;
}(window.GUI || {});
