"use strict";

window.MediaStateHandlerV2 = {
    get Mixin() {
        return {
            _mediaRegistratons: {},
            __chunkQueueMap: {},
            EVENT_TYPES: MusicServerEnum.MediaContentType,
            DYNAMIC_FUNCTION_TPL: {
                ALL_DATA_RECEIVED: "received{eventType}AllDataEvent",
                CHUNKED_DATA_DATA: "received{eventType}ChunkEvent",
                RELOAD: "received{eventType}ReloadEvent",
                ERROR: "received{eventType}ErrorEvent"
            },

            /**
             * Register for MediaServerEvents
             * @note Takes an argument list of this.EVENT_TYPES
             * @private
             */
            _registerForMediaServerEvents: function _registerForMediaServerEvents() {
                this._registerForMediaServerReloadEvents.apply(this, arguments);

                this._requestEventContent.apply(this, arguments);
            },

            /**
             * Register for MediaServer Reload Events
             * @note Takes an argument list of this.EVENT_TYPES
             * @private
             */
            _registerForMediaServerReloadEvents: function _registerForMediaServerReloadEvents() {
                Debug.Control.AudioZone.MediaStateHandler && console.log(this.viewId || this.name, "_registerForMediaServerReloadEvents");
                var eventTypes = Array.from(arguments);
                this.control.audioserverComp.connectionPromise().done(function () {
                    eventTypes.forEach(function (eventType) {
                        if (!this.__isRegisteredForMediaServerReloadEvent(eventType)) {
                            this._mediaRegistratons[eventType] = this.control.audioserverComp.registerForReloadEvent(eventType, function (reason, remove, id) {
                                this._receivedReloadEvent(eventType, reason, id);
                            }.bind(this));
                        } else {// Don't register multiple times!
                        }
                    }.bind(this));
                }.bind(this));
            },
            _unregisterFromMediaServerReloadEvents: function _unregisterFromMediaServerReloadEvents() {
                var eventTypes = Array.from(arguments);
                eventTypes.forEach(function (eventType) {
                    if (this.__isRegisteredForMediaServerReloadEvent(eventType)) {
                        this.control.audioserverComp.unregisterFromReloadEvent(eventType, this._mediaRegistratons[eventType]);
                        delete this._mediaRegistratons[eventType];
                    }
                }.bind(this));
            },

            /**
             * Unregister from MediaServerEvents
             * @note Takes any argument list of this.EVENT_TYPES. All registered events will be unregistered if no arguments are given
             * @private
             */
            _unregisterForMediaServerEvents: function _unregisterForMediaServerEvents() {
                var eventTypes = Array.from(arguments); // No eventTypes given, assume we should unregister from all events

                if (!eventTypes.length) {
                    eventTypes = Object.keys(this._mediaRegistratons);
                }

                eventTypes.forEach(function (eventType) {
                    this._unregisterFromMediaServerReloadEvents.apply(this, eventTypes);
                }.bind(this));

                this._stopLoadingContent.apply(this, arguments);
            },

            /**
             * A reload event has been received
             * @note This will trigger a reload of the event content
             * @param eventType
             * @param reason
             * @param id
             * @private
             */
            _receivedReloadEvent: function _receivedReloadEvent(eventType, reason, id) {
                Debug.Control.AudioZone.MediaStateHandler && console.log(this.viewId || this.name, "_receivedReloadEvent");
                var reloadPrms; // Don't call the function if the component is not registered for events (anymore)

                if (this.__isRegisteredForMediaServerReloadEvent(eventType, id, reason)) {
                    reloadPrms = Q(this.__callDynamicEventTypeFunctionFromTemplateWithArgs(eventType, this.DYNAMIC_FUNCTION_TPL.RELOAD, [reason, id])); // Special reason handling

                    if ((Object.values(MusicServerEnum.ReloadCause).indexOf(reason) !== -1 || Object.values(MusicServerEnum.Attr.Container.ActionType).indexOf(reason) !== -1) && reason !== MusicServerEnum.ReloadCause.STOPPED) {
                        reloadPrms = reloadPrms.then(function () {
                            // Wait with requesting the content until we are allowed to!
                            Debug.Control.AudioZone.MediaStateHandler && console.log(this.viewId || this.name, "_receivedReloadEvent > requestEventContent");
                            return this._requestEventContent(eventType);
                        }.bind(this));
                    } else {
                        reloadPrms = Q(true);
                    }
                } else {
                    reloadPrms = Q(true);
                }

                return reloadPrms;
            },

            /**
             * Loads the event content of the given events
             * @note Takes any argument list of this.EVENT_TYPES and will request its contents
             * @private
             */
            _requestEventContent: function _requestEventContent() {
                var args = arguments;

                if (!this.control) {
                    console.warn("Can't request event content without a control!");
                    return;
                }

                this.control.audioserverComp.connectionPromise().done(function () {
                    var eventTypes = Array.from(args),
                        mediaEventRequest;
                    this._mediaEventRequests = this._mediaEventRequests || {};
                    eventTypes.forEach(function (eventType) {
                        if (!this._mediaEventRequests.hasOwnProperty(eventType)) {
                            this._mediaEventRequests[eventType] = [];
                        }

                        Debug.Control.AudioZone.MediaStateHandler && console.log(this.viewId || this.name, "_requestEventContent > requestContent");
                        mediaEventRequest = this.control.audioserverComp.requestContent(eventType, this.__getMediaId(eventType), // always start with patch 0!
                            this.__getRequestPacketSizeForEventType(eventType), this.__getMediaInfo(eventType));

                        if (mediaEventRequest) {
                            if (mediaEventRequest.data) {
                                this.__onEventDataReceived(eventType, cloneObject(mediaEventRequest.data));
                            }

                            mediaEventRequest.promise.then(this.__onEventDataReceived.bind(this, eventType), this.__onEventErrorReceived.bind(this, eventType), this.__onEventDataChunkReceived.bind(this, eventType)).finally(function (mediaEventRequest) {
                                if (this._mediaEventRequests.hasOwnProperty(eventType)) {
                                    var prmsIdx = this._mediaEventRequests[eventType].indexOf(mediaEventRequest.promise);

                                    if (prmsIdx !== -1) {
                                        this._mediaEventRequests[eventType].splice(prmsIdx, 1);
                                    }
                                }
                            }.bind(this, mediaEventRequest));

                            this._mediaEventRequests[eventType].push(mediaEventRequest.promise);
                        } else {
                            this.__onEventErrorReceived(eventType);
                        }
                    }.bind(this));
                }.bind(this));
            },

            /**
             * Will stop any pending requests
             * @note Takes any argument list of this.EVENT_TYPES. All registered events will be unregistered if no arguments are given
             * @private
             */
            _stopLoadingContent: function _stopLoadingContent() {
                var eventTypes = Array.from(arguments);
                eventTypes.forEach(function (eventType) {
                    if (this._mediaEventRequests && this._mediaEventRequests.hasOwnProperty(eventType)) {
                        this._mediaEventRequests[eventType].forEach(function (request) {
                            console.info("Stopping an ongoing request for eventType " + eventType + " (id = '" + this.__getMediaId(eventType) + "')");
                            this.control.audioserverComp.stopRequestFor(eventType, request, this.__getMediaInfo(eventType));
                            request = null;
                        }.bind(this));

                        delete this._mediaEventRequests[eventType];
                    }
                }.bind(this));
            },

            /**
             * Returns a function name based on the eventType and template
             * @param eventType The Event Type the function name is for
             * @param template  The template which is used to build the function name
             * @note "{eventType}" will be replaced with the content of the argument eventType
             * @return {*|void|string}
             * @private
             */
            __getFunctionNameForEventTypeWithTemplate: function __getFunctionNameForEventTypeWithTemplate(eventType, template) {
                return template.replace("{eventType}", eventType);
            },

            /**
             * Yey, we got event data! Pass it on to the generic function depending on the event type
             * @note The data may come in chunks (depending on its length and "__getRequestPacketSizeForEventType"
             * @param eventType
             * @private
             */
            __onEventDataReceived: function __onEventDataReceived(eventType) {
                var data = Array.from(arguments).splice(1)[0];
                return Q(this.__onEventDataChunkReceived.apply(this, arguments) || true).then(function () {
                    if (data && data.isFinished) {
                        return this.__callDynamicEventTypeFunctionFromTemplateWithArgs(eventType, this.DYNAMIC_FUNCTION_TPL.ALL_DATA_RECEIVED, [data]);
                    } else {
                        return Q(true);
                    }
                }.bind(this));
            },

            /**
             * Yey, we got event data! Pass it on to the generic function depending on the event type
             * @note The data may come in chunks (depending on its length and "__getRequestPacketSizeForEventType"
             * @param eventType
             * @private
             */
            __onEventDataChunkReceived: function __onEventDataChunkReceived(eventType) {
                var processChunk = function processChunk() {
                    var args = this.__chunkQueueMap[eventType].pop();

                    if (args) {
                        this.__chunkedPrms = this.__callDynamicEventTypeFunctionFromTemplateWithArgs(eventType, this.DYNAMIC_FUNCTION_TPL.CHUNKED_DATA_DATA, Array.from(args).splice(1));
                    } else {
                        this.__chunkedPrms = null;
                    }
                }.bind(this);

                this.__chunkQueueMap[eventType] = this.__chunkQueueMap[eventType] || [];

                this.__chunkQueueMap[eventType].push(arguments);

                if (!this.__chunkedPrms) {
                    processChunk();
                }

                return Q(this.__chunkedPrms || true).then(function () {
                    if (this.__chunkQueueMap[eventType].length) {
                        processChunk();
                        return this.__chunkedPrms;
                    }
                }.bind(this));
            },

            /**
             * No! there was an error, just do what we did previously and just ignore it
             * @param eventType
             * @param [cause]
             * @private
             */
            __onEventErrorReceived: function __onEventErrorReceived(eventType, cause) {
                if (cause === MusicServerEnum.ReloadCause.STOPPED) {
                    console.info("Manually canceled an ongoing request! for eventType " + eventType + " (id = '" + this.__getMediaId(eventType) + "')");
                } else {
                    console.warn("Error loading the event data for '" + eventType + "'", cause);
                    return this.__callDynamicEventTypeFunctionFromTemplateWithArgs(eventType, this.DYNAMIC_FUNCTION_TPL.ERROR, Array.from(arguments).splice(1));
                }
            },

            /**
             * Calls a generic function based on the eventType and the template with the given arguments
             * @note The template may look like this "myAwesome{eventType}Function" which results in the function "myAwesomeQueueFunction" if the eventType equals this.EVENT_TYPE.QUEUE
             * @param eventType
             * @param template
             * @param args
             * @private
             */
            __callDynamicEventTypeFunctionFromTemplateWithArgs: function __callDynamicEventTypeFunctionFromTemplateWithArgs(eventType, template, args) {
                var funcName = this.__getFunctionNameForEventTypeWithTemplate(eventType, template);

                if (typeof this[funcName] === "function") {
                    var def = Q.defer(); // Ensures the receiver is still registered

                    if (!this.__isRegisteredForMediaServerReloadEvent(eventType)) {
                        Debug.Control.AudioZone.MediaStateHandler && console.warn(this.viewId || this.name, "__callDynamicEventTypeFunctionFromTemplateWithArgs " + funcName + " - no longer registered!");
                        def.resolve();
                        return;
                    } // Places the call in an own Task, to get to other calls quicker.


                    setTimeout(function () {
                        // Ensures the receiver is still registered -- e.g. on destroying views being async may just be enough
                        // time that the receiver that was registered isn't registered anymore.
                        if (this.__isRegisteredForMediaServerReloadEvent(eventType)) {
                            def.resolve(this[funcName].apply(this, cloneObject(args)));
                        } else {
                            def.resolve();
                        }
                    }.bind(this), 0);
                    return def.promise;
                } else {
                    return Q(true);
                }
            },

            /**
             * Checks if the component is already registered for a given eventType
             * @param eventType
             * @param id
             * @param reason
             * @return {boolean}
             * @private
             */
            __isRegisteredForMediaServerReloadEvent: function __isRegisteredForMediaServerReloadEvent(eventType, id, reason) {
                var ownId = this.__getMediaId(eventType) + "",
                    // ensure it's a string
                    compareEventId = typeof id !== 'undefined' ? `${id}`.split("_").shift() + "" : undefined,
                    // ensure it's a string if provided
                    registered = this._mediaRegistratons && this._mediaRegistratons.hasOwnProperty(eventType);

                if (registered && typeof compareEventId !== 'undefined' && ownId !== compareEventId && reason !== MediaEnum.ReloadCause.USER_CHANGED) {
                    // reload events may not affect the content that is shown in this very view.
                    Debug.Control.AudioZone.MediaStateHandler && console.warn(this.viewId || this.name, "__isRegisteredForMediaServerReloadEvent: false due to ID Missmatch, " + "evType: " + eventType + ", with id: " + id + ", own ID is " + ownId + ", reason: " + reason);
                    registered = false;
                } else {
                    Debug.Control.AudioZone.MediaStateHandler && console.log(this.viewId || this.name, "__isRegisteredForMediaServerReloadEvent: type: " + eventType + ", id: " + id + ", registered? " + !!registered);
                }

                return registered;
            },

            /**
             * Defines the package size to be fetched from the MusicServer
             * @param eventType
             * @return {number}
             */
            __getRequestPacketSizeForEventType: function __getRequestPacketSizeForEventType(eventType) {
                if (this.getRequestPacketSizeForEventType) {
                    return this.getRequestPacketSizeForEventType(eventType);
                }

                return MusicServerEnum.DEFAULT_RQ_SIZE;
            },

            /**
             * The information which is given used by the specific MediaLoader
             * @return {*}
             */
            __getMediaInfo: function __getMediaInfo(eventType) {
                var mediaInfo = this.control.details;

                if (this.getMediaInfo) {
                    mediaInfo = this.getMediaInfo(eventType);
                }

                return mediaInfo;
            },

            /**
             * Returns the ID to be fetched 0 means start...
             * @returns {number}
             * @private
             */
            __getMediaId: function __getMediaId(eventType) {
                if (this.getMediaId) {
                    return this.getMediaId(eventType);
                } else if (this.control && (eventType === this.EVENT_TYPES.QUEUE || eventType === this.EVENT_TYPES.ZONE_FAVORITES)) {
                    return this.control.details.playerid;
                } else {
                    return 0;
                }
            }
        };
    }

};
