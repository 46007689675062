import {HeaderTitle, HeaderBackButton} from "@react-navigation/elements";
import {LxReactTextView, LxReactText, LxReactImageView, LxReactPressable} from "LxComponents";
import globalStyles from "GlobalStyles";
import PropTypes from "prop-types";
import Icons from "IconLib";
import {useRequest} from "ahooks";
import {CardStyleInterpolators, TransitionPresets, TransitionSpecs} from "@react-navigation/stack";
import {
    View,
    Animated,
    Pressable,
    StyleSheet
} from "react-native";
import { Navigation } from "../App";
import { useDebounceFn } from "ahooks";

const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
        if (!timer) {
            func.apply(this, args);
        }
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = undefined;
        }, timeout);
    };
}

// region navigatorConfig
const navigatorConfig = ({
                             leftActions = [],
                             onLeftAction,
                             prompt,
                             title,
                             headerTitle,
                             type = NavigatorType.Default,
                             typeProps,
                             rightActions = [],
                             onRightAction,
                             headerShown = true,
                             animationType = AnimationType.NONE,
                             navigation,
                             isAmbientMode = false,
                             searchState = [false, () => {
                                 console.warn("No Search handler has been set!")
                             }],
                            disableBackNavigation = false,
                             ...props // Catches any other props and passes them to the returned object
                         }) => {
    let [searchActive, setSearchActive] = searchState;
    let showCloseIcon = false;

    const executeBackNavigation = debounce((idx) => {
        if (onLeftAction) {
            onLeftAction(idx);
        } else {
            if (navigation) {
                navigation.goBack();
            } else {
                console.warn("No 'navigation' prop given!");
                NavigationComp.navigateBack();
            }
        }
    });

    const executeRightAction = debounce((idx) => onRightAction && onRightAction(idx))


    const dimensions = {
            height: 24,
            width: 24
        },
        renderAction = (actionObj, idx, clb, props) => {
            if (typeof actionObj.action === "string") {
                let actionStyle = {
                    fontFamily: globalStyles.fontSettings.families.regular,
                    color: !actionObj.disabled ? props.tintColor : globalStyles.colors.text.tertiary
                }
                return (
                    <LxReactPressable
                        pkey={idx}
                        key={idx}
                        onPress={clb}
                        disabled={!!actionObj.disabled}
                    >
                        <LxReactText style={actionStyle}>{actionObj.action}</LxReactText>
                    </LxReactPressable>
                )
            } else if (typeof actionObj.action === "function") {
                return (
                    <LxReactPressable
                        pkey={idx}
                        key={idx}
                        onPress={clb}
                        disabled={!!actionObj.disabled}
                    >
                        {actionObj.action({
                            key: idx,
                            props,
                            dimensions,
                            disabled: actionObj.disabled
                        })}
                    </LxReactPressable>
                )
            }
        };

    const getTitle = ({
                          typeProps,
                          prompt,
                          titleProps
                      }) => {
        if (searchActive) {
            return <SearchExtra textChanged={query => {
                typeProps && typeProps.textChanged && typeProps.textChanged(query)
            }}/>
        } else {
            if (titleProps.children && !prompt) {
                return <HeaderTitle {...titleProps}/>
            } else if (titleProps.children || prompt) {
                return (
                    <View style={{
                        alignItems: "center"
                    }}>
                        <Prompt>{prompt}</Prompt>
                        <HeaderTitle {...titleProps}/>
                    </View>
                )
            }
        }
    }

    let topNavProps = {};
    if (type === NavigatorType.Search) {
        let providedRightAction = onRightAction;
        if (searchActive) {
            leftActions = [];
            onLeftAction = null;

            rightActions = [{
                action: _("Cancel")
            }];

            topNavProps.headerTitleContainerStyle = {
                flexGrow: 1
            }

        } else {
            rightActions = [{
                action: ({key, props}) => {
                    //return <Icons.Search key={key} fill={props.tintColor} {...dimensions}/>
                    return <LxReactImageView
                        source={Icon.Buttons.SEARCH}
                        imageStyle={{height: 35, width: 35}}
                        partFillColors={{
                            path: globalStyles.colors.text.secondary,
                            circle: globalStyles.colors.stateInactiveB
                        }}
                        key={"search"}/>
                }
            }, ...rightActions];
            topNavProps.headerTitleContainerStyle = {
                flexGrow: 0
            }
        }
        onRightAction = (idx) => {
            if (searchActive || idx === 0) {
                setSearchActive(active => {
                    // Stop the search
                    typeProps && typeProps.textChanged && typeProps.textChanged("")
                    return !active
                });
            } else {
                providedRightAction(idx);
            }
        }
    }


    if (leftActions.length) {
        topNavProps.headerLeft = props => {
            return (
                <View style={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: globalStyles.spacings.gaps.big,
                    paddingLeft: globalStyles.spacings.gaps.small
                }}>
                    {leftActions.map((action, idx) => {
                        return renderAction(action, idx, () => executeBackNavigation(idx), props);
                    })}
                </View>
            )
        }
    } else if (onLeftAction) {
        topNavProps.headerLeft = props => {
            return <HeaderBackButton {...props} {...{disabled: disableBackNavigation}} onPress={() => executeBackNavigation(0)}/>
        }
    }

    if (title || title === "") {
        topNavProps.title = title;
    }

    if (typeof headerTitle === "function") {
        topNavProps.headerTitle = headerTitle;
    } else {
        topNavProps.headerTitle = titleProps => {
            return getTitle({
                typeProps,
                prompt,
                titleProps
            })
        }
    }

    if (rightActions.length) {
        topNavProps.headerRight = props => {
            return (
                <View style={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: globalStyles.spacings.gaps.big,
                    paddingRight: globalStyles.spacings.gaps.small
                }}>
                    {rightActions.map((action, idx) => {
                        return renderAction(action, idx, () => executeRightAction(idx), props);
                    })}
                </View>
            )
        }
    } else {
        topNavProps.headerRight = props => {
            return null;
        }
    }
    if (AMBIENT_MODE || isAmbientMode) {
        if (!animationType || [AnimationType.NONE, AnimationType.FADE, AnimationType.HD_OVERLAY].includes(animationType)) {
            showCloseIcon = true;
        }
        animationType = AnimationType.NONE;
    }
    switch (animationType) {
        case AnimationType.MODAL:
        case AnimationType.HD_MODAL_FULLSCREEN:
            topNavProps = {
                ...topNavProps,
                ...forModal({
                    isFullScreen: animationType === AnimationType.HD_MODAL_FULLSCREEN,
                    isAmbientMode
                })
            };
            break;
        case AnimationType.HD_OVERLAY:
        case AnimationType.OVERLAY_RIGHT:
            if (animationType === AnimationType.HD_OVERLAY && !HD_APP) {
                topNavProps = {
                    ...topNavProps,
                    ...forDefault(isAmbientMode),
                    ...TransitionPresets.SlideFromRightIOS
                };
                break;
            }
            topNavProps = {
                ...topNavProps,
                ...forPushOverlay({
                    animationType,
                    isAmbientMode
                }),
            };
            break;
        case AnimationType.PUSH_OVERLAP_LEFT:
            topNavProps = {
                ...topNavProps,
                ...forDefault(isAmbientMode),
                ...TransitionPresets.SlideFromRightIOS
            };
            break;
        case AnimationType.FADE:
        case AnimationType.NONE:
        default:
            let nonAmbientProps = AMBIENT_MODE || isAmbientMode ? {} : TransitionPresets.ModalFadeTransition
            topNavProps = {
                ...topNavProps,
                ...forDefault(isAmbientMode),
                ...nonAmbientProps
            };
    }

    if (isAmbientMode || AMBIENT_MODE) {
        let transparentStyle = {
            backgroundColor: "transparent",
            borderColor: globalStyles.colors.borderColor.ambient
        }
        if (topNavProps.cardStyle) {
            topNavProps.cardStyle = [topNavProps.cardStyle, transparentStyle]
        }
        if (topNavProps.headerStyle) {
            topNavProps.headerStyle = [topNavProps.headerStyle, transparentStyle]
        }
        let showBackIcon = false;
        if (navigation && navigation.getState) {
            let navState = navigation.getState();
            showBackIcon = navState.type === "stack" && navState.index > 0;
        }
        if (showCloseIcon) {
            topNavProps.headerBackImage = ({tintColor}) => {
                return <LxReactImageView
                    source={showBackIcon ? Icon.Buttons.BACK : Icon.Buttons.CLOSE}
                    imageStyle={styles.headerImage}
                    partFillColors={styles.headerImageColors}
                    key={"back"}/>
            }
        }
    }

    return {
        headerShown,
        ...topNavProps,
        ...props
    }
}
// endregion

// region Prompt
const Prompt = props => {
    if (props.children) {
        return <LxReactText style={Prompt.style} {...props}/>
    }
}

Prompt.style = {
    color: globalStyles.colors.white
}
// endregion

// region SearchExtra
const SearchExtra = props => {
    const {
            value,
            placeholder = _("search.title"),
            autoFocus = true,
            leftIcon = Icons.Search,
            leftIconStyle = styles.searchExtraLeftIcon,
            textChanged,
            onSubmitEditing,
            debounceWait = 250
        } = props,
        {run} = useRequest(textChanged, {
            debounceWait,
            manual: true
        });

    return <LxReactTextView
        value={value}
        placeholder={placeholder}
        autoFocus={autoFocus}
        leftIcon={leftIcon}
        leftIconStyle={leftIconStyle}
        alignment={"left"}
        style={[props.style, styles.searchExtraContainer]}
        containerStyle={{
            height: styles.height - (2 * styles.padding)
        }}
        onSubmitEditing={onSubmitEditing}
        textChanged={run}
    />
}

SearchExtra.propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    leftIcon: PropTypes.node,
    leftIconStyle: PropTypes.object,
    onSubmitEditing: PropTypes.func,
    textChanged: PropTypes.func.isRequired,
    debounceWait: PropTypes.number
}
// endregion

const NavigatorType = {
    Default: "default",
    Search: "search"
};

const ModalDefaultSettings = {
    MAX_WIDTH: 640,
    OVERLAY_HD_WIDTH: 400,
    OVERLAY_OFFSET: 38,
    SLIDE_ANIMATION_DURATION: 500,
}

const forDefault = (isAmbientMode = AMBIENT_MODE) => {
    const maxTitleWidth = isAmbientMode ? 400 - (4 * 45) : window.innerWidth - (4 * 45);
    return {
        animationEnabled: !AMBIENT_MODE,
        gestureEnabled: true,
        cardOverlayEnabled: false,
        headerStyle: styles.headerContainer,
        headerBackgroundContainerStyle: styles.headerBackgroundContainer,
        headerTitleAlign: "center",
        headerTintColor: globalStyles.colors.brand,
        headerTitleStyle: [styles.headerTitle, {maxWidth: maxTitleWidth}],
        headerBackImage: ({tintColor}) => {
            //return <Icons.BackArrow fill={tintColor} height={24} width={24}/>
            return <LxReactImageView
                source={Icon.Buttons.BACK}
                iconClass={"icon-placeholder__arrow-back"}
                imageStyle={styles.headerImage}
                partFillColors={styles.headerImageColors}
                key={"back"}/>
        },
        tabBarActiveTintColor: globalStyles.colors.brand,
        tabBarInactiveTintColor: globalStyles.colors.tabBarGrey,
        cardStyle: styles.cardDefault
    };
}

const forModal = ({isFullScreen = false, isAmbientMode} = {}) => {
    let options = {
        ...forDefault(isAmbientMode),
        presentation: "modal",
        headerBackImage: ({tintColor}) => {
            return <LxReactImageView
                source={Icon.Buttons.CLOSE}
                imageStyle={styles.headerImage}
                partFillColors={styles.headerImageColors}
                key={"back"}/>
        },
        cardStyle: styles.modalBackground
    };

    if (isFullScreen) {
        options = {
            ...options,
            ...TransitionPresets.ModalSlideFromBottomIOS,
            cardStyle: styles.cardDefault
        }
    } else {
        if (HD_APP) {
            options = {
                ...options,
                ...TransitionPresets.ModalSlideFromBottomIOS,
            }
        } else {
            options = {
                ...options,
                ...TransitionPresets.ModalPresentationIOS,
            }
        }
        options.headerStyle = styles.modalBackground;
    }

    if (HD_APP && !isFullScreen) {
        options.headerStyle = [options.headerStyle, styles.headerModal];
        options = {
            ...options,
            presentation: "transparentModal",
            cardOverlayEnabled: true,
            cardStyle: styles.cardModal,
            cardStyleInterpolator: (props) => {
                let defaultInterpolators = CardStyleInterpolators.forModalPresentationIOS(props);
                return {
                    ...defaultInterpolators,
                    containerStyle: {
                        maxWidth: ModalDefaultSettings.MAX_WIDTH,
                        width: "100%",
                        alignSelf: "center",
                        paddingTop: globalStyles.spacings.gaps.big,
                    }
                }
            },
            cardOverlay: cardOverlay
        };

        if (PlatformComponent.isElectron && PlatformComponent.getPlatformInfoObj().platform === PlatformType.Mac) {
            delete options.cardStyle.paddingBottom
        }
    }

    return options;
}

const forPushOverlay = ({animationType, isAmbientMode}) => {
    let isOverlayOnLeftSide = animationType === AnimationType.OVERLAY_RIGHT; // If screen should be overlay on left side like burgerMenu
    return {
        ...forDefault(isAmbientMode),
        cardStyle: styles.cardPushOverlay,
        presentation: AMBIENT_MODE ? "modal" : "transparentModal",
        gestureDirection: isOverlayOnLeftSide ? "horizontal-inverted" : "horizontal",
        transitionSpec: {
            open: TransitionSpecs.TransitionIOSSpec,
            close: TransitionSpecs.TransitionIOSSpec
        },
        cardOverlayEnabled: !AMBIENT_MODE,
        cardStyleInterpolator: (props) => {
            let {current, layouts} = props,
                modalInterpolator = CardStyleInterpolators.forModalPresentationIOS(props),
                maxWidth;

            if (isOverlayOnLeftSide) {
                if (HD_APP) {
                    maxWidth = ModalDefaultSettings.OVERLAY_HD_WIDTH;
                } else {
                    maxWidth = layouts.screen.width - ModalDefaultSettings.OVERLAY_OFFSET
                }
            } else {
                maxWidth = ModalDefaultSettings.MAX_WIDTH;
            }

            return {
                overlayStyle: modalInterpolator.overlayStyle,
                containerStyle: {
                    transform: [
                        {
                            translateX: current.progress.interpolate({
                                inputRange: [0, 1],
                                outputRange: isOverlayOnLeftSide ? [-layouts.screen.width, 0] : [layouts.screen.width, 0],
                            }),
                        }
                    ],
                    maxWidth,
                    width: "100%",
                    alignSelf: isOverlayOnLeftSide ? "flex-start" : "flex-end"
                }
            }
        },
        cardOverlay: cardOverlay
    }
}

const cardOverlay = (...args) => {
    let [current] = args;
    return <CardOverlay current={current}/>
}

const CardOverlay = (props) => {
    const { current } = props,
        { run } = useDebounceFn(() => {
            // Check if we are fully done with animations!
            if (current.style.opacity.__getValue() === current.style.opacity._config.outputRange[1]) {
                Navigation.goBack();
            }
        }, {
            wait: 150
        });
    return <Pressable style={[
        StyleSheet.absoluteFill
    ]} onPress={run}>
        <Animated.View style={{
            ...current.style,
            backgroundColor: "black",
            flex: 1
        }}>
        </Animated.View>
    </Pressable>
}

const styles = {
    headerContainer: {
        backgroundColor: globalStyles.colors.black
    },
    headerBackgroundContainer: {
        WebkitAppRegion: "drag"
    },
    headerImage: {
        height: globalStyles.sizes.icons.big,
        width: globalStyles.sizes.icons.big
    },
    headerImageColors: {
        path: globalStyles.colors.text.secondary,
        circle: globalStyles.colors.stateInactiveB
    },
    headerTitle: {
        ...globalStyles.textStyles.titleBar.title,
        color: globalStyles.colors.text.primary,
        textOverflow: "ellipsis"
    },
    headerModal: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16
    },
    cardDefault: {
        flex: 1,
        WebkitAppRegion: "no-drag",
        backgroundColor: globalStyles.colors.black
    },
    cardModal: {
        flex: 1,
        backgroundColor: globalStyles.colors.grey["600"],
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        overflow: 'hidden',
        border: `solid 1.5px ${globalStyles.colors.borderColor.default}`
    },
    cardPushOverlay: {
        flex: 1,
        WebkitAppRegion: "no-drag",
        backgroundColor: globalStyles.colors.black
    },
    modalBackground: {
        backgroundColor: globalStyles.colors.grey["600"]
    },
    searchExtraContainer: {
        backgroundColor: globalStyles.colors.grey["300a36"],
        borderRadius: 6,
        marginLeft: 16,
        marginRight: 16,
        height: 35,
        width: "auto",
        padding: 8
    },
    searchExtraLeftIcon: {
        height: 24,
        width: 24,
        fill: globalStyles.colors.placeholder
    }
}

export default navigatorConfig;

export {
    navigatorConfig,
    NavigatorType,
    forDefault,
    forModal,
    forPushOverlay,
    Prompt,
    SearchExtra
}
