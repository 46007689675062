'use strict';

define([], function () {
    return class NfcTagContentView extends GUI.View {
        constructor() {
            super($('<div />'));
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.tableView = new GUI.TableViewV2(this, this);
            this.appendSubview(this.tableView);
        }

        updateWithTag(tag) {
            this._tag = tag;

            this._generateTableContent().done(function () {
                this.tableView.reload();
            }.bind(this));
        }

        getTagContentName() {
            if (this._positionTag) {
                return _('saved-position');
            } else if (this._commandTag) {
                return _('saved-command');
            } else if (this._miniserverTag) {
                return _('saved-miniserver');
            } else {
                return null;
            }
        }

        // TableViewDataSource Methods
        styleForTableView(tableView) {
            return HD_APP ? TableViewStyle.GROUPED : null;
        }

        numberOfSections(tableView) {
            return this._tableContent.length;
        }

        numberOfRowsInSection(section, tableView) {
            return this._tableContent[section].length - 1;
        }

        titleForHeaderInSection(section, tableView) {
            return this._tableContent[section][0];
        }

        cellTypeForCellAtIndex(section, row, tableView) {
            return GUI.TableViewV2.CellType.GENERAL;
        }

        contentForCell(cell, section, row, tableView) {
            return this._tableContent[section][row + 1];
        }

        didSelectCell(cell, section, row, tableView) {
            this._tableContent[section][row + 1].action && this._tableContent[section][row + 1].action(cell, section, row, tableView);
        }

        _generateTableContent() {
            var def = Q.defer();
            this._tableContent = [];
            this._commandTag = false;
            this._positionTag = false;
            this._miniserverTag = false; // CONTENT

            var contentSection = [],
                msInfoSection = [];

            this._loadTagContentSection(contentSection, msInfoSection).done(function () {
                if (this._miniserverTag) {
                    msInfoSection.unshift(_('saved-miniserver'));
                } else {
                    msInfoSection.unshift(null);
                    contentSection.unshift(this.getTagContentName());
                } // remove eventually empty sections


                if (contentSection.length === 0) {
                    this._tableContent.splice(this._tableContent.indexOf(contentSection), 1);
                }

                if (msInfoSection.length === 1) {
                    this._tableContent.splice(this._tableContent.indexOf(msInfoSection), 1);
                }

                def.resolve();
            }.bind(this));

            this._tableContent.push(contentSection);

            this._tableContent.push(msInfoSection); // WRITE-PROTECTION


            this._tableContent.push(this._loadWriteProtectionSection()); // ACTIONS


            if (this._tag.isWritable) {
                this._tableContent.push(this._loadActionsSection());
            } // DEBUG


            if (Debug.NFC) {
                this._tableContent.push(this._loadDebugSection());
            }

            return def.promise;
        }

        _loadTagContentSection(contentSection, msInfoSection) {
            var def = Q.defer();
            var messages = this._tag.ndefMessage;

            if (!messages || messages.length === 0) {
                contentSection.push({
                    title: _('nfc.tag.empty')
                });
                def.resolve();
                return def.promise;
            }

            var promises = [];
            this._totalContentSize = 0;
            var message;

            for (var i = 0; i < messages.length; i++) {
                message = messages[i];
                var size = ndef.encodeMessage([message]).length,
                    sizeStr = " (" + size + " Bytes)";
                this._totalContentSize += size;

                if (message.tnf === ndef.TNF_EMPTY) {// don't add anything
                } else if (message.tnf === ndef.TNF_WELL_KNOWN) {
                    if (util.isType(message, ndef.TNF_WELL_KNOWN, ndef.RTD_TEXT)) {
                        /*contentSection.push({
                         title: ndef.textHelper.decodePayload(message.payload),
                         subtitle: "Text",
                         changeTitleSubtitle: true
                         });*/
                    } else if (util.isType(message, ndef.TNF_WELL_KNOWN, ndef.RTD_URI)) {
                        var url = ndef.uriHelper.decodePayload(message.payload),
                            params = getURIParameters(url);
                        this._commandTag = url.hasPrefix(UrlHelper.LOXONE_CMD_SCHEME) && typeof params.cmd === "string";
                        this._positionTag = url.hasPrefix(UrlHelper.LOXONE_SCHEME) && typeof params.loc === "string";
                        this._miniserverTag = url.hasPrefix(UrlHelper.LOXONE_SCHEME) && !this._positionTag;

                        if (this._commandTag || this._positionTag || this._miniserverTag) {
                            promises.push(this._loadMiniserverInfoSection(msInfoSection, params));

                            if (this._commandTag) {
                                promises.push(this._getCommandInfosFromUrlParams(contentSection, params));
                            } else if (this._positionTag) {
                                promises.push(this._getPositionInfosFromUrlParams(contentSection, params));
                            }
                        } else {
                            contentSection.push({
                                title: url,
                                subtitle: "URL",
                                changeTitleSubtitle: true
                            });
                        }
                    } else {
                        /*contentSection.push({
                         title: nfc.bytesToString(message.payload),
                         subtitle: "Unknown RTD",
                         changeTitleSubtitle: true
                         });*/
                    }
                } else {
                    /*contentSection.push({
                     title: nfc.bytesToString(message.payload),
                     subtitle: ndef.tnfToString(message.tnf) + sizeStr,
                     changeTitleSubtitle: true
                     });*/
                }
            }

            if (promises.length > 0) {
                // we have to wait for more info..
                Q.all(promises).done(function () {
                    def.resolve();
                });
            } else if (contentSection.length === 0) {
                contentSection.push({
                    title: _('nfc.tag.empty')
                });
                def.resolve();
            } else {
                def.resolve();
            }

            return def.promise;
        }

        _getCommandInfosFromUrlParams(contentSection, params) {
            var def = Q.defer();
            var activeMS = ActiveMSComponent.getActiveMiniserver(),
                isSameMiniserver = activeMS.serialNo === params.mac;
            var cmd = UrlHelper.getCommandFromCommandString(params.cmd);

            if (isSameMiniserver) {
                var controlUuid = UrlHelper.getControlUUIDFromCommandString(params.cmd);
                var control = ActiveMSComponent.getControlByUUID(controlUuid);

                if (!control) {
                    // might be an audioZone
                    control = MediaServerComp.getZoneFromCommand(controlUuid, cmd);

                    if (!control) {
                        control = {
                            name: _('unknown-object')
                        };
                    }
                }

                contentSection.unshift({
                    title: control.name,
                    subtitle: control.groupDetail,
                    changeTitleSubtitle: true
                });
                def.resolve();
            } else {
                // we have to load infos
                var controlUUID = UrlHelper.getControlUUIDFromCommandString(params.cmd);
                var cmdParts = decodeURIComponent(cmd).split("/");
                var additionalInfo = null;

                if (cmdParts[0] === 'audio') {
                    // we're looking for an audioCmd
                    additionalInfo = {
                        playerid: parseInt(cmdParts[1])
                    };
                }

                PersistenceComponent.getControlOfMiniserver(params.mac, controlUUID, additionalInfo).done(function (result) {
                    var control;

                    if (result === ControlLoadError.MINISERVER_NOT_AVAILABLE || result === ControlLoadError.CONTROL_NOT_AVAILABLE) {
                        control = {
                            name: _('unknown-object')
                        };
                    } else {
                        control = result;
                    }

                    contentSection.unshift({
                        title: control.name,
                        subtitle: control.groupDetail,
                        changeTitleSubtitle: true
                    });
                    def.resolve();
                });
            }

            contentSection.push({
                title: decodeURIComponent(UrlHelper.getCommandFromCommandString(params.cmd)),
                subtitle: _('command'),
                changeTitleSubtitle: true
            });

            if (params.cmd2) {
                contentSection[contentSection.length - 1].subtitle += " 1";
                contentSection.push({
                    title: decodeURIComponent(UrlHelper.getCommandFromCommandString(params.cmd2)),
                    subtitle: _('command') + " 2",
                    changeTitleSubtitle: true
                });
            }

            return def.promise;
        }

        _getPositionInfosFromUrlParams(contentSection, params) {
            var def = Q.defer();
            var locInfo = UrlHelper.getLocationInfoFromLocationString(params.loc);

            if (locInfo.control) {
                PersistenceComponent.getControlOfMiniserver(params.mac, locInfo.control).done(function (result) {
                    var control;

                    if (result === ControlLoadError.MINISERVER_NOT_AVAILABLE || result === ControlLoadError.CONTROL_NOT_AVAILABLE) {
                        control = {
                            name: _('unknown-object')
                        };
                    } else {
                        control = result;
                    }

                    contentSection.push({
                        title: control.name,
                        subtitle: control.groupDetail,
                        changeTitleSubtitle: true
                    });
                    def.resolve();
                });
            } else if (locInfo.room) {
                var room = ActiveMSComponent.getStructureManager().getGroupByUUID(locInfo.room, GroupTypes.ROOM);
                room = room && room.name || locInfo.room;
                contentSection.push({
                    title: room,
                    subtitle: _('room'),
                    changeTitleSubtitle: true
                });
                def.resolve();
            } else if (locInfo.category) {
                var cat = ActiveMSComponent.getStructureManager().getGroupByUUID(locInfo.category, GroupTypes.CATEGORY);
                cat = cat && cat.name || locInfo.category;
                contentSection.push({
                    title: cat,
                    subtitle: _('category'),
                    changeTitleSubtitle: true
                });
                def.resolve();
            } else {
                contentSection.push({
                    title: params.loc,
                    subtitle: _('app-position'),
                    changeTitleSubtitle: true
                });
                def.resolve();
            }

            return def.promise;
        }

        _loadMiniserverInfoSection(miniserverSection, params) {
            var def = Q.defer();
            var activeMS = ActiveMSComponent.getActiveMiniserver(),
                isSameMiniserver = activeMS.serialNo === params.mac;

            if (isSameMiniserver) {
                this._addMiniserverInfo(miniserverSection, activeMS);

                def.resolve();
            } else if (params.mac) {
                var ms = PersistenceComponent.getMiniserver(params.mac);

                if (ms) {
                    this._addMiniserverInfo(miniserverSection, ms);

                    def.resolve();
                } else {
                    miniserverSection.push({
                        title: _('unknown') + " (" + params.mac.toUpperCase() + ")",
                        subtitle: _('miniserver'),
                        changeTitleSubtitle: true
                    });
                    def.resolve();
                }
            } else {
                if (params.host) {
                    miniserverSection.push({
                        title: params.host,
                        subtitle: _('miniserver'),
                        changeTitleSubtitle: true
                    });
                }

                if (params.usr) {
                    miniserverSection.push({
                        title: params.usr,
                        subtitle: _('user'),
                        changeTitleSubtitle: true
                    });
                }
                /*if (params.pwd) {
                 var pwd = "";
                 while (pwd.length < params.pwd.length) {
                 pwd += "•";
                 }
                 var hidden = true;
                 miniserverSection.push({
                 title: pwd,
                 subtitle: _('password'),
                 clickable: true,
                 changeTitleSubtitle: true,
                 action: function(cell, section, row, tableView) {
                 cell.setTitle(hidden ? params.pwd : pwd);
                 hidden = !hidden; // toggle
                 }
                 });
                 }*/


                if (miniserverSection.length === 0) {
                    miniserverSection.push({
                        title: _('unknown'),
                        subtitle: _('miniserver'),
                        changeTitleSubtitle: true
                    });
                }

                def.resolve();
            }

            return def.promise;
        }

        _addMiniserverInfo(miniserverSection, ms) {
            miniserverSection.push({
                title: ms.msName,
                subtitle: _('miniserver'),
                changeTitleSubtitle: true
            });
            miniserverSection.push({
                title: ms.activeUser,
                subtitle: _('user'),
                changeTitleSubtitle: true
            });
        }

        _loadWriteProtectionSection() {
            var wpContent = {
                subtitle: _('write-protection'),
                changeTitleSubtitle: true
            };

            if (typeof this._tag.isWritable !== "boolean" || this._tag.isWritable) {
                if (typeof this._tag.canMakeReadOnly !== "boolean" || this._tag.canMakeReadOnly) {
                    wpContent.title = _('not-active');
                } else {
                    wpContent.title = _('not-available');
                }
            } else {
                wpContent.title = _('active');
                wpContent.titleColor = window.Styles.colors.red;
            }

            return [_('nfc.tag'), wpContent];
        }

        _loadActionsSection() {
            var actionSection = [_('actions')]; // ERASE

            actionSection.push({
                title: _('nfc.tag.erase'),
                leftIconSrc: "resources/Images/General/trash-can-v2.svg",
                clickable: true,
                action: this._onEraseTapped.bind(this)
            }); // WRITE-PROTECT

            if (this._tag.canMakeReadOnly) {
                actionSection.push({
                    title: _('nfc.tag.write-protect'),
                    leftIconSrc: "resources/Images/ActiveMiniserver/NFC/icon-lock.svg",
                    clickable: true,
                    action: this._onWriteProtectTapped.bind(this)
                });
            }

            return actionSection;
        }

        _loadDebugSection() {
            var debugSection = ["Debugging"]; // SIZE

            if (typeof this._tag.maxSize === "number") {
                debugSection.push({
                    title: this._totalContentSize + "/" + this._tag.maxSize + " Bytes",
                    subtitle: "Size",
                    changeTitleSubtitle: true
                });
            } // TYPE


            if (typeof this._tag.type === "string") {
                debugSection.push({
                    title: this._tag.type,
                    subtitle: "Type",
                    changeTitleSubtitle: true
                });
            } // TECH-TYPES


            debugSection.push({
                title: this._tag.techTypes.join(", ").replace(/android.nfc.tech./g, ""),
                subtitle: "TechTypes",
                changeTitleSubtitle: true
            }); // ID

            var id = "unknown id";

            try {
                id = nfc.bytesToHexString(this._tag.id);
            } catch (e) {
                console.error(e.stack);
            }

            debugSection.push({
                title: id,
                subtitle: "ID",
                changeTitleSubtitle: true
            });
            return debugSection;
        }

        _onEraseTapped() {
            this.ViewController.navigateBack();
            NavigationComp.showState(ScreenState.NfcTagInteractionScreen, {
                type: Interaction.NFC.ERASE
            });
        }

        _onWriteProtectTapped() {
            this.ViewController.navigateBack();
            NavigationComp.showState(ScreenState.NfcTagInteractionScreen, {
                type: Interaction.NFC.WRITE_LOCK
            });
        }

    };
});
