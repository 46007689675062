'use strict';

import {
    ReactWrapper,
    LxReactSwitchAdapter
} from "LxComponents";
import globalStyles from "GlobalStyles";

window.GUI = function (GUI) {
    // TODO support viewDidAppear etc.!

    /**
     * ctor of LxSwitch
     * @param {*} callbackContext switch callbacks context
     * @param {HTMLElement} elem
     * @param [activeColor=Color.GREEN] color when button is set to active
     * @param [useAntiGhostTimer=false] if true, an anti ghost timer will avoid frequent changes
     * @param [antiGhostInterval=2000]  if an anti ghost timer is used, this duration will be passed in.
     * @param initialValue Initial value of the switch
     * @returns {LxSwitch}
     * @constructor
     */
    function LxSwitch(callbackContext, elem, activeColor, useAntiGhostTimer, antiGhostInterval, initialValue, inactiveBackgroundColor = undefined) {
        this.callbackContext = callbackContext;
        this.element = elem;
        this.activeColor = activeColor || globalStyles.colors.green;
        this.isActive = false;
        this.disabled = false;
        this.reactComp = ReactWrapper.render(LxReactSwitchAdapter, {
            activeTrackColor: this.activeColor,
            inactiveBackgroundColor: inactiveBackgroundColor,
            disabled: this.disabled,
            onValueChanged: (newVal) => this.onStateChanged(newVal),
            value: initialValue
        }, this.element, () => {
        });
        this.eventHandler = {
            dispose: () => developerAttention("LxReactSwitchAdapter: eventHandler.dispose() is deprecated, no EventHandler anymore")
        };
        this.element.addEventListener("click", e => {
            e.preventDefault();
            e.stopPropagation();
        })
        return this;
    }

    LxSwitch.prototype._handleTap = () => developerAttention("LxReactSwitchAdapter: handleTap() is deprecated, not needed anymore")

    LxSwitch.prototype.getElement = function getElement() {
        return this.element;
    };

    LxSwitch.prototype.setActiveColor = function setActiveColor(color) {
        this.activeColor = color;
        //this.setActive(this.isActive); // if color is set after active state!
    };

    LxSwitch.prototype.setActive = function setActive(active) {
        this.reactComp.setActive(active);
    };
    /**
     * setter for colors of switch
     * @param colors struct: { indicator: { active: <color>, inactive: <color> }, background: { active: <color>, inactive: <color> } }
     */


    LxSwitch.prototype.setColors = () => developerAttention("\"LxReactSwitchAdapter: setColors() is deprecated, use only setActiveColor() to change track color")
    /**
     * destroys lxSwitch event listeners
     */
    LxSwitch.prototype.destroy = () => developerAttention("LxReactSwitchAdapter: destroy() is deprecated, not needed anymore")


    LxSwitch.prototype.setEnabled = function setEnabled(enabled) {
        this.disabled = !enabled;
        this.reactComp.setDisabled(this.disabled);
    };

    GUI.LxSwitch = LxSwitch;
    return GUI;
}
(window.GUI || {});
