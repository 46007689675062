'use strict';

define("PresenceDetectorControl", ["Control"], function (Control) {
    return class PresenceDetectorControl extends Control {
        constructor() {
            super(...arguments);
        }

        getFriendlyValueName(event, operator) {
            var controlName = this.getName();

            switch (event.data.stateName) {
                case "active":
                    return event.value ? _("x.active", {
                        x: controlName
                    }) : _("x.inactive", {
                        x: controlName
                    });

                default:
                    return "";
            }
        }

    };
});
