import {createStackNavigator} from "@react-navigation/stack";
import SelectPairingMethodScreen from "./SelectPairingMethodScreen";
import ConnectToMsScreen from "./ConnectToMsScreen";
import {createContext, useCallback, useState} from "react";
import useAppPairingSetup from "./useAppPairingSetup";
import {navigatorConfig} from "LxComponents";
import PairingConnectScreen from "./PairingConnectScreen";
import ManualLoginScreen from "./ManualLoginScreen";
import UserGroupSelectionScreen from "./UserGroupSelectionScreen";
import ChangeSecretScreen from "../../GUI/ActiveMiniserver/menu/miniserverSettings/changeSecret/changeSecretScreen";
import ScanQrSetupScreen from "./ScanQrSetupScreen";



export const AppPairingContext = createContext({ initial: true });
export const DeviceCreateContext = createContext({});

function AppPairingNavigator({navigation}) {
    navigation.setOptions({
        ...navigatorConfig({
            headerShown: false
        })
    })

    const AppPairingStackNavigator = createStackNavigator();
    const appPairingSetupData = useAppPairingSetup();

    const [deviceCreateInfo, setDeviceCreateInfo] = useState({});
    const updateNewDeviceProps = useCallback((newInfo) => {
        setDeviceCreateInfo(prevValue => {
            return {...prevValue, ...newInfo };
        })
    }, [setDeviceCreateInfo]);

    return <DeviceCreateContext.Provider value={{
        deviceProps: {...deviceCreateInfo},
        updateProps: updateNewDeviceProps
    }}>
        <AppPairingContext.Provider value={{...appPairingSetupData}}>
            <AppPairingStackNavigator.Navigator initialRouteName={SelectPairingMethodScreen.name}>
                <AppPairingStackNavigator.Screen name={SelectPairingMethodScreen.name} component={SelectPairingMethodScreen} initialParams={{}} />
                <AppPairingStackNavigator.Screen name={ConnectToMsScreen.name} component={ConnectToMsScreen} initialParams={{}} />
                <AppPairingStackNavigator.Screen name={PairingConnectScreen.name} component={PairingConnectScreen} initialParams={{}} />
                <AppPairingStackNavigator.Screen name={ManualLoginScreen.name} component={ManualLoginScreen} initialParams={{}} />
                <AppPairingStackNavigator.Screen name={UserGroupSelectionScreen.name} component={UserGroupSelectionScreen} initialParams={{}} />
                <AppPairingStackNavigator.Screen name={ScreenState.ChangeSecretScreen} component={ChangeSecretScreen} initialParams={{}} />
                <AppPairingStackNavigator.Screen name={ScanQrSetupScreen.name} component={ScanQrSetupScreen} initialParams={{}} />
            </AppPairingStackNavigator.Navigator>
        </AppPairingContext.Provider>
    </DeviceCreateContext.Provider>;

}

export default AppPairingNavigator;