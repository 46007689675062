import * as React from "react"
import Svg, { Path } from "react-native-svg"

function AcSilentMode(props) {
  return (
    <Svg isreact="true"
      width={24}
      height={22}
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M14.571 3.272c.04-2.04-2.23-3.277-3.917-2.106a.855.855 0 00-.101.082L4.801 6.704h-2.23C1.161 6.704 0 7.874 0 9.281v3.439c0 1.407 1.162 2.577 2.571 2.577h2.224l5.596 5.448c.034.033.07.063.109.09 1.669 1.158 4.026-.044 4.071-2.078V3.272zM23.623 7.948a1.286 1.286 0 010 1.818L22.39 11l1.233 1.234a1.286 1.286 0 01-1.818 1.818l-1.234-1.234-1.233 1.234a1.286 1.286 0 11-1.819-1.818L18.754 11 17.52 9.766a1.286 1.286 0 111.818-1.818l1.233 1.234 1.234-1.234a1.286 1.286 0 011.818 0z"
      />
    </Svg>
  )
}

export default AcSilentMode
