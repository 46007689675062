'use strict';
/*
 title
 placeholder
 value
 selected
 override   boolean attribute specifying wether or not the override label is to be shown.
 disableInput

 Delegate
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.SAVE_SCENE_CELL = "SaveSceneCell";

    class SaveSceneCell extends GUI.TableViewV2.Cells.SceneBaseCell {
        //region Static
        static Template = function () {
            var getOverrideLabel = function getOverrideLabel() {
                return $('<div class="content__override-label">' + _("controls.lightcontroller.scene.overwrite") + '</div>');
            };

            return {
                getOverrideLabel: getOverrideLabel
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("save-scene-cell");
        }

        viewDidLoad() {
            return Q(super.viewDidLoad()).then(function () {
                var textsPlaceholder = this.getElement().find(".content__texts-placeholder");
                var inputContent = cloneObjectDeep(this.content); // use deep clone, as regexes aren't transferred properly.

                if (!inputContent.hasOwnProperty(GUI.LxInputEnum.SHOW_GREEN)) {
                    inputContent[GUI.LxInputEnum.SHOW_GREEN] = false;
                }

                inputContent.disabled = this.content.disableInput;
                this.input = new GUI.LxInput(inputContent, this);
                this.input.getElement().addClass("texts-placeholder__input");

                if (this.content.override) {
                    this.contentPlaceholder.append(SaveSceneCell.Template.getOverrideLabel());
                }

                if (!this.content.hasOwnProperty(GUI.LxInputEnum.VALUE)) {
                    this.getElement().addClass("save-scene-cell--not-used");
                }

                return this.appendSubview(this.input, textsPlaceholder);
            }.bind(this));
        }

        textChanged(value, valid, valueDidChange) {
            this.delegate.textChanged && this.delegate.textChanged(this.sectionIdx, this.rowIdx, this.tableView, value, valid, valueDidChange);
        }

        submitText(value, valid, valueDidChange) {
            this.delegate.submitText && this.delegate.submitText(this.sectionIdx, this.rowIdx, this.tableView, value, valid, valueDidChange);
        }

        setSelected(selected) {
            super.setSelected(...arguments); // set the focus
            // need for iOS device --> cell click will not automatically focus the input element

            if (!this.content.disableInput) {
                this.input.focus();
            }
        }

        getValue() {
            return this.input.getValue();
        }

        setValue(newValue) {
            return this.input.setValue(newValue);
        }

        /**
         * returns whether or not the value in the input is valid.
         * @returns {boolean}
         */
        isValid() {
            return this.input.isValid();
        }

        rippleAllowed() {
            return false;
        }

    }

    GUI.TableViewV2.Cells.SaveSceneCell = SaveSceneCell;
    return GUI;
}(window.GUI || {});
