'use strict';

define(['ControlSetting'], function (ControlSetting) {
    return class ControlPresetSetting extends ControlSetting {
        constructor(data) {
            super(data);
            this.isModified = data.presetModified;
            this.numAffectedControls = data.presetControls;
            this.affectedSettings = data.presetAffectedSettings;
            this.presetUuid = data.presetUuid;
        }

        isAffectedSetting(settingId) {
            return this.affectedSettings.filter(function (affId) {
                return affId === settingId;
            }).length > 0;
        }

    };
});
