import {View} from "react-native-web";
import React from "react";
import {LxReactText} from "LxComponents";
import Icons from "IconLib"
import globalStyles from "GlobalStyles";
import PropTypes from "prop-types";

function LxReactBackButton({ tintColor }) {
    return <View style={styles.container}>
        <Icons.ChevronBack {...styles.icon} fill={tintColor}/>
        <LxReactText style={[{color: tintColor},globalStyles.textStyles.titleBar.action.default, styles.text]}>{_("back")}</LxReactText>
    </View>
}

const styles = {
    container: {
        flexDirection: "row",
        alignItems: "center",
        marginLeft: globalStyles.spacings.gaps.small
    },
    icon: {
        height: globalStyles.sizes.icons.small
    },
    text: {
        marginLeft: globalStyles.spacings.gaps.smaller
    }
}

LxReactBackButton.propTypes = {
    tintColor: PropTypes.string
}

export default LxReactBackButton