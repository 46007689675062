'use strict';

define([], function () {
    return class FilterView extends GUI.View {
        //region Static
        static EVENTS = {
            RESET: "reset"
        };
        static Template = class {
            //region Static
            static getFilterElement() {
                return $("<div class='filter-view'>" + "   " + "<div class='filter-view__desc'></div>" + "   " + "<div class='filter-view__reset-btn'>" + _("automatic-designer.filter.reset") + "</div>" + "</div>");
            } //endregion Static


        }; //endregion Static

        constructor(filterControls) {
            super(FilterView.Template.getFilterElement());
            this.filterControls = filterControls || [];
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                this.elements = this.elements || {};
                this.elements.filterDesc = this.element.find(".filter-view__desc");
                this.elements.filterResetBtn = this.element.find(".filter-view__reset-btn");
                this.resetButton = new GUI.LxButton(this, this.elements.filterResetBtn);
                this.addToHandledSubviews(this.resetButton);

                this.resetButton.onButtonTapped = function () {
                    this.emit(this.constructor.EVENTS.RESET);
                }.bind(this);

                this.setFilterDescription();
            }.bind(this));
        }

        updateView(filterControls) {
            this.filterControls = filterControls || [];
            this.setFilterDescription();
        }

        setFilterDescription() {
            if (this.filterControls.length) {
                var control = this.filterControls[0],
                    controlRoom = control.getRoom(),
                    transVarObj = {
                        controlTypeName: control.getControlTypeName(),
                        controlName: control.getName()
                    },
                    transString = _("automatic-designer.filter.without-room", transVarObj);

                if (controlRoom) {
                    transVarObj.roomName = controlRoom.name;
                    transString = _("automatic-designer.filter.with-room", transVarObj);
                }

                this.element.show();
                this.elements.filterDesc.text(transString);
            } else {
                this.elements.filterDesc.text(null);
                this.element.hide();
            }
        }

    };
});
