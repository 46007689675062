'use strict';

define("UniversalControl", ["Control"], function (Control) {
    return class UniversalControl extends Control {
        constructor() {
            super(...arguments);
        }

        /**
         * Saves the sent command in this.sentCommands and avoids duplicates
         * This function will eventually call its base function to send the command
         * @returns {*}
         */
        sendCommand() {
            var cmd = arguments[0];

            if (this.sentCommands) {
                if (this.sentCommands.indexOf(cmd) === -1) {
                    this.sentCommands.push(cmd);
                }
            } else {
                this.sentCommands = [cmd];
            }

            return super.sendCommand(...arguments);
        }

        /**
         * Returns a list of all sent commands, if no commands have been sent yet,
         * an empty array will be returned
         * @returns {*}
         */
        getSentCommands() {
            if (!this.sentCommands) {
                return [];
            } else {
                return this.sentCommands;
            }
        }

    };
});
