'use strict';

define(["AudioZoneV2ControlEnums", "SpotifyAccountManager"], function (AudioZoneV2ControlEnums, SpotifyAccountManager) {
    class SpotifyAccountScreen extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            applyMixins(this, StateHandler.Mixin);
            this.details = details;
            this.control = this.details.control;
            this.accounts = [];
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                return this.control.audioserverComp.connectionReadyDef.promise.then(function () {
                    return this.control.audioserverComp.getAvailableServices().promise.then(function (availableServices) {
                        this.service = availableServices.find(function (service) {
                            return service.cmd === MusicServerEnum.Service.SPOTIFY;
                        });
                    }.bind(this));
                }.bind(this));
            }.bind(this));
        }

        viewWillAppear() {
            return Q(super.viewWillAppear(...arguments) || true).then(function () {
                this._registerForStates(this.control.uuidAction, ["enableSpotifyConnect"]);

                this.serviceChangeReg = AudioserverComp.registerForServiceChanges(this._onServicesChanged.bind(this));
                return Q.all([this._onServicesChanged()]).then(this.reloadTable.bind(this));
            }.bind(this));
        }

        viewWillDisappear() {
            this.serviceChangeReg && this.serviceChangeReg(); // unregister from service changes.

            this.serviceChangeReg = null;

            this._unregisterStates();

            super.viewWillDisappear(...arguments);
        }

        titleBarText() {
            return _("media.popup.spotify.title");
        }

        receivedStates(states) {
            this.spotifyConnectEnabled = states.enableSpotifyConnect;
            this.reloadTable();
        }

        setTableContent() {
            this.tableContent = [this._getAddSection()];
            this.tableContent.pushObject(this._getAccountsSection());
            this.tableContent.pushObject(this._getConnectSection());
            return super.setTableContent(...arguments);
        }

        _getAddSection() {
            return {
                rows: [{
                    content: {
                        leftIconSrc: Icon.TRANSLUCENT_ADD,
                        leftIconColor: window.Styles.colors.green,
                        title: _("media.preferences.services.editor.add-spotify-account"),
                        clickable: true
                    },
                    type: GUI.TableViewV2.CellType.GENERAL,
                    action: function action() {
                        SpotifyAccountManager.shared(this.control).showAddSpotifyScreen();
                    }
                }],
                footerTitle: _("audio-server.settings.spotify.info-text", {
                    productName: _("audio-server")
                })
            };
        }

        _getAccountsSection() {
            if (this.accounts.length) {
                return {
                    rows: this.accounts.map(function (account) {
                        return {
                            content: {
                                leftIconSrc: account.iconSrc,
                                title: account.name,
                                subtitle: account.user,
                                rightIconSrc: Icon.SETTINGS_SMALL,
                                clickable: true
                            },
                            type: GUI.TableViewV2.CellType.GENERAL,
                            action: function () {
                                this._showScreen(AudioZoneV2ControlEnums.ScreenState.SETTINGS_SERVICE_LOGIN, {
                                    control: this.control,
                                    service: account
                                });
                            }.bind(this)
                        };
                    }.bind(this))
                };
            }
        }

        _getConnectSection() {
            if (AudioserverComp.Feature.SPOTIFY_CONNECT) {
                return {
                    rows: [{
                        type: GUI.TableViewV2.CellType.SWITCH,
                        content: {
                            title: _("media.settings.spotify.connect"),
                            active: this.spotifyConnectEnabled
                        },
                        onSwitchChanged: function (active, section, row, tableView) {
                            this.control.sendCommand(Commands.format(Commands.AudioZone.SET_SPOTIFY_CONNECT, active));
                        }.bind(this)
                    }]
                };
            }
        }

        _showScreen(target, detail) {
            this.ViewController.ViewController.showState(target, null, detail);
        }

        _onServicesChanged() {
            return AudioserverComp.getCurrentServices().promise.then(function (services) {
                this.accounts = services.filter(function (service) {
                    return service.cmd === MusicServerEnum.Service.SPOTIFY;
                });
                return this.reloadTable();
            }.bind(this));
        }

    }

    Controls.AudioZoneV2Control.SpotifyAccountScreen = SpotifyAccountScreen;
    return Controls.AudioZoneV2Control.SpotifyManagementScreen;
});
