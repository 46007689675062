'use strict';

define("IRCDaytimerCalendarEntries", ["DaytimerCalendarEntries", "IRoomControlEnums"], function (DaytimerCalendarEntries, IRoomControlEnums) {
    return class IRCDaytimerCalendarEntries extends DaytimerCalendarEntries {
        //region Static
        static Template = function () {
            /**
             *
             * @param ctrl          the iRoomController itself
             * @param isHeating     if this schedule is for heating
             * @param isCooling     if this schedule is for cooling
             * @return {string}
             */
            var createLegendView = function createLegendView(ctrl, isHeating, isCooling) {
                return '<div class="legend-view">' + '<div class="legend-list legend-list--irc">' + (isHeating ? createLegendViewEntry(ctrl, IRoomControlEnums.IRCTempMode.COMFORT_HEATING, ctrl.type) : "") + (isCooling ? createLegendViewEntry(ctrl, IRoomControlEnums.IRCTempMode.COMFORT_COOLING, ctrl.type) : "") + createLegendViewEntry(ctrl, IRoomControlEnums.IRCTempMode.ECONOMY, ctrl.type) + createLegendViewEntry(ctrl, IRoomControlEnums.IRCTempMode.PARTY, ctrl.type) + createLegendViewEntry(ctrl, IRoomControlEnums.IRCTempMode.INCREASED_HEAT, ctrl.type) + createLegendViewEntry(ctrl, IRoomControlEnums.IRCTempMode.EMPTY_HOUSE, ctrl.type) + createLegendViewEntry(ctrl, IRoomControlEnums.IRCTempMode.HEAT_PROTECTION, ctrl.type) + '</div>' + '<div class="legend-button">' + ImageBox.getResourceImageWithClasses('resources/Images/Controls/IRoom/info.svg', 'legend-button__icon') + '</div>' + '</div>';
            };

            var createLegendViewEntry = function createLegendViewEntry(control, modeNr, type) {
                var circleClass = "entry__circle  circle--circle-border",
                    name = control.getNameOfIRCTempMode(modeNr);
                return '<div id="mode-nr-' + modeNr + '" class="legend-list__entry">' + '<div class="' + circleClass + '"></div>' + '<div class="entry__name">' + name + '</div>' + '<div class="entry__value"></div>' + '</div>';
            };

            return {
                getLegend: createLegendView
            };
        }(); //endregion Static

        constructor(control, onScroll) {
            super(...arguments); // the irc daytimer is passed in here as this.control - so be sure to have the poolControl itself ready too.

            this.parentControl = ActiveMSComponent.getControlByUUID(this.control.uuidParent);
        }

        // -----------------------------------------------------------------------------------------
        // -----------------------------------------------------------------------------------------
        getCircleColorForMode(modeNr) {
            var colorObj = null;

            if (modeNr === IRoomControlEnums.IRCTempMode.ECONOMY) {
                colorObj = {
                    color: this.parentControl.getColorOfIRCTempMode(modeNr),
                    cssAttr: 'border-color'
                };
            } else {
                colorObj = {
                    color: this.parentControl.getColorOfIRCTempMode(modeNr),
                    cssAttr: 'background-color'
                };
            }

            return colorObj;
        }

        getImageElementForEntry(entryInfo) {
            var imageName = this.parentControl.getIconOfIRCTempMode(entryInfo.entry.tempMode);
            return $(ImageBox.getResourceImageWithClasses(imageName, 'entry__icon temp-mode-image'));
        }

        getEntryBackgroundColor(entry) {
            return this.parentControl.getColorOfIRCTempMode(entry.tempMode);
        }

        hasLegend() {
            return true;
        }

        /**
         * Returns a jquery element that contains the legend for these calendar entries
         * @return {jQuery|HTMLElement}
         */
        getLegendTemplate() {
            // pass on the parent control as the ircDaytimer has no such methods!
            return $(IRCDaytimerCalendarEntries.Template.getLegend(this.parentControl, this.control.details.isHeating, this.control.details.isCooling));
        }

        updateLegend(states) {
            var valueLabels = this.elements.legendList.find('.entry__value'),
                label,
                tempModeNr;

            for (var i = 0; i < valueLabels.length; i++) {
                label = $(valueLabels[i]);
                tempModeNr = label.parent()[0].id.replace('mode-nr-', '');
                label.text(lxFormat(this.control.details.format, states.activeTemperatures[tempModeNr]) + SandboxComponent.getTemperatureUnit());
            }
        }

    };
});
