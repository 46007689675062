'use strict';

define(["IRoomControlEnums"], function (IRoomControlEnums) {
    return class IRoomControlContentTimer extends Controls.ControlContent {
        constructor(details) {
            super(details);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.tableView = new GUI.TableViewV2(this, this);
            this.tableView.getElement().addClass("timer__table-view");
            this.appendSubview(this.tableView);
            SandboxComponent.getMiniserverTimeInfo(this, function (msDateTime) {
                this.targetDateTime = msDateTime; // Mantis 8905

                this.targetDateTime.seconds(0);

                if (this.targetDateTime.minute() === 0) {
                    this.targetDateTime.add(4 * 60, 'minutes');
                } else {
                    this.targetDateTime.add(5 * 60, 'minutes');
                    this.targetDateTime.minute(0);
                }
            }.bind(this), TimeValueFormat.MINISERVER_DATE_TIME);
        }

        titleBarActionRight() {
            this._startTimer();
        }

        updateView(details) {
            super.updateView();

            if (details) {
                this.tempMode = details.modeNr;
                this.tableView.reload(); // reload is every time necessary because otherwise the picker won't work anymore
            }
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        getURL() {
            return "Timer";
        }

        titleBarText() {
            return _("controls.irc.timer");
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.CLOSE,
                rightSide: TitleBarCfg.Button.TICK,
                style: 'transparent'
            };
        }

        receivedStates(states) {
            this.activeTemperatures = states.activeTemperatures;

            if (!this._initialStatesReceived) {
                this.isHeating = this.control.isHeating(states.controllerMode);
                this.isCooling = this.control.isCooling(states.controllerMode);

                if (this.isHeating) {
                    this.tempMode = IRoomControlEnums.IRCTempMode.COMFORT_HEATING;
                } else if (this.isCooling) {
                    this.tempMode = IRoomControlEnums.IRCTempMode.COMFORT_COOLING;
                }

                if (states.activeTempMode === IRoomControlEnums.IRCTempMode.COMFORT_HEATING || states.activeTempMode === IRoomControlEnums.IRCTempMode.COMFORT_COOLING) {
                    this.tempMode = IRoomControlEnums.IRCTempMode.ECONOMY;
                }

                this._initialStatesReceived = true; // many updates are only possible if the timer is active - timer isn't active when he is visible -> nope!
                // the IRC can always send updates (temp)

                this.tableView.reload();
            }
        }

        // Private functions
        _startTimer() {
            var msDateTime = SandboxComponent.getMiniserverTimeInfo(null, null, TimeValueFormat.MINISERVER_DATE_TIME),
                // get the precise difference in minutes e.g. 119.75.
                timeDifference = this.targetDateTime.diff(msDateTime, 'minutes', true); // ceil the minutes value, as otherwise the timer won't end at e.g. 14:00 but always at 13:59

            timeDifference = Math.ceil(timeDifference);
            this.sendCommand(Commands.format(Commands.I_ROOM_CONTROLLER.START_TIMER, this.tempMode, timeDifference));
            this.ViewController.navigateBack();
        }

        // TableViewDataSource methods
        styleForTableView() {
            return TableViewStyle.GROUPED;
        }

        numberOfSections() {
            return 2;
        }

        numberOfRowsInSection() {
            return 1;
        }

        cellTypeForCellAtIndex(section, row) {
            if (section === 0 && row === 0) {
                return GUI.TableViewV2.CellType.GENERAL;
            } else if (section === 1 && row === 0) {
                return GUI.TableViewV2.CellType.DATE_TIME_PICKER;
            }
        }

        titleForHeaderInSection(section) {
            if (section === 0) {
                return _("controls.irc.timer.temperature");
            } else if (section === 1) {
                return _("controls.irc.timer.time");
            }
        }

        contentForCell(cell, section) {
            if (section === 0) {
                // GeneralCell
                return {
                    leftIconSrc: this.control.getIconOfIRCTempMode(this.tempMode),
                    leftIconColor: this.control.getColorOfIRCTempMode(this.tempMode),
                    title: this.control.getNameOfIRCTempMode(this.tempMode),
                    disclosureIcon: true,
                    disclosureText: lxFormat(this.control.details.format, this.activeTemperatures[this.tempMode]) + SandboxComponent.getTemperatureUnit()
                };
            } else if (section === 1) {
                // PickerCell
                return {
                    iconSrc: "resources/Images/Controls/Daytimer/clock-end.svg",
                    title: _("controls.daytimer.entry.end"),
                    value: this.targetDateTime,
                    expanded: true
                };
            }
        }

        // TableViewDelegate methods
        didSelectCell(cell, section) {
            if (section === 0) {
                var uuid = this.control.getDaytimerUuidFromIRoomControl(this.isHeating);

                if (uuid) {
                    var daytimerControl = ActiveMSComponent.getStructureManager().getControlByUUID(uuid);
                    this.ViewController.showState(IRoomControlEnums.ScreenState.TEMPERATURE_SELECTION, null, {
                        control: daytimerControl,
                        entry: {
                            tempMode: this.tempMode
                        }
                    });
                }
            }
        }

        onPickerChanged(section, row, tableView, value) {
            this.targetDateTime = value;
        }

    };
});
