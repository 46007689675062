'use strict';

define("AutomaticDesignerAddOrEditScene", ["AutomaticDesignerAddOrEditRule", "AutomaticDesignerSceneVisuEditScreen", "QrCodeResultScreen"], function (AutomaticDesignerAddOrEditRule, AutomaticDesignerSceneVisuEditScreen, QrCodeResultScreen) {
    return class AutomaticDesignerAddOrEditScene extends AutomaticDesignerAddOrEditRule {
        constructor(details) {
            super(...arguments);
            this.platform = PlatformComponent.getPlatformInfoObj().platform;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.associatedControl = SandboxComponent.getStructureManager().getControlByUUID(this.currentEditRule.id);
            }.bind(this));
        }

        titleBarText() {
            return this.isInEditMode ? _("scenes.edit") : _("scenes.create");
        }

        _getLockTexts() {
            return [_("automatic-designer.rule.missing-permission.1-scene"), _("automatic-designer.rule.missing-permission.2")];
        }

        _getTableContent() {
            var baseTableContent = super._getTableContent(...arguments);

            if (!this.currentEditRule.locked) {
                baseTableContent.pushObject(this._getVisuPwSection());
                baseTableContent.pushObject(this._getVisuSection());

                if (this.isInEditMode) {
                    baseTableContent.pushObject(this._getAddActionSection());
                    baseTableContent.pushObject(this._getDeleteSceneSection());
                }
            }

            return baseTableContent;
        }

        _getRuleSettingsSection() {
            var baseSection = super._getRuleSettingsSection(...arguments);

            baseSection && (baseSection.rows = []);
            return baseSection;
        }

        _getRuleNotifyOnExecSection() {
            return null;
        }

        _getRuleRoomCatSection() {
            return null;
        }

        _getEditRuleEventsSection() {
            return null;
        }

        _getEditRuleActionsSection() {
            return super._getEditRuleActionsSection();
        }

        _getDeleteRuleSection() {
            return null;
        }

        _getVisuPwSection() {
            if (Feature.AUTOMATIC_DESIGNER_SCENES_USE_VISU_PW && ActiveMSComponent.isExpertModeLightEnabled() && this.isSaveScreen || this.isInEditMode) {
                return {
                    rows: [{
                        type: GUI.TableViewV2.CellType.SWITCH,
                        content: {
                            title: _("visu-password"),
                            active: this.currentEditRule.visu.useVisuPwd
                        },
                        onSwitchChanged: function onSwitchChanged(value, section, row, tableView, cell) {
                            AutomaticDesignerComponent.acquireVisuPasswordForObj({
                                isSecured: true,
                                // Force true to be able to verify the entered visu PW
                                uuid: AutomaticDesignerComponent.getAutomaticDesignerUuid()
                            }).then(function () {
                                this.currentEditRule.visu.useVisuPwd = value;
                                this.reloadTable();
                            }.bind(this), function () {
                                cell.setActive(this.currentEditRule.visu.useVisuPwd);
                            }.bind(this));
                        }.bind(this)
                    }],
                    footerTitle: _("scenes.visu.visu-pw")
                };
            } else {
                return null;
            }
        }

        _getVisuSection() {
            if (ActiveMSComponent.isExpertModeLightEnabled() && this.isSaveScreen || this.isInEditMode) {
                return {
                    rows: [{
                        content: {
                            title: _("visu"),
                            disclosureText: this.currentEditRule.visu.useVisu ? _("media.input.in-use") : _("media.input.unused"),
                            clickable: true,
                            class: "base-cell--with-chevron"
                        },
                        action: this._openVisuSettings.bind(this)
                    }]
                };
            } else {
                return null;
            }
        }

        _getAddActionSection() {
            if (this.currentEditRule.visu.useVisu && !this.currentEditRule.visu.useVisuPwd) {
                var section = {
                    headerTitle: _("actions"),
                    rows: []
                };
                section.rows.pushObject(this._getQuickActionAddActionRow());
                section.rows.pushObject(this._getQRCodeAddActionRow());
                section.rows.pushObject(this._getNFCAddActionRow());
                return section;
            } else {
                return null;
            }
        }

        _getQuickActionAddActionRow() {
            if (QuickActionUtility.hasQuickActions()) {
                var title,
                    availableQuickActions = QuickActionUtility.getItems(LoxoneControl.hasLoxoneControl());

                if (this.platform === PlatformType.Android) {
                    title = _("shortcuts.android.create-new");
                } else if (LoxoneControl.hasLoxoneControl()) {
                    title = _('loxone-control.quick-action.create-new');
                } else {
                    title = _('quick-action.create-new');
                }

                return {
                    content: {
                        title: title + "...",
                        titleColor: window.Styles.colors.green,
                        disabled: availableQuickActions.length >= MAX_NUMBER_OF_QUICK_ACTIONS && !LoxoneControl.hasLoxoneControl(),
                        clickable: true
                    },
                    action: function () {
                        var text,
                            cmd = getURIParameters(this.associatedControl.getSceneExecUrlScheme()).cmd,
                            isDuplicate = !!availableQuickActions.find(function (item) {
                                if (item.hasOwnProperty("cmds")) {
                                    return item.cmds.includes(cmd);
                                } else {
                                    return false;
                                }
                            });

                        if (!isDuplicate) {
                            QuickActionUtility.addCommand(this.associatedControl.getSceneExecUrlScheme(), this.associatedControl.getName(), this.associatedControl.groupDetail).done(function () {
                                if (this.platform === PlatformType.Android) {
                                    text = _("shortcuts.android.created");
                                } else if (LoxoneControl.hasLoxoneControl()) {
                                    text = _('loxone-control.quick-action.created');
                                } else {
                                    text = _('quick-action.created');
                                }

                                GUI.Notification.createGeneralNotification({
                                    subtitle: text,
                                    closeable: false,
                                    clickable: true,
                                    removeAfter: 5
                                }, NotificationType.SUCCESS);
                            }.bind(this));
                        } else {
                            if (this.platform === PlatformType.Android) {
                                text = _("shortcuts.android.save-duplicate");
                            } else if (LoxoneControl.hasLoxoneControl()) {
                                text = _('loxone-control.quick-action.save.duplicate');
                            } else {
                                text = _("quick-action.save.duplicate");
                            }

                            GUI.Notification.createGeneralNotification({
                                subtitle: text,
                                closeable: false,
                                clickable: true,
                                removeAfter: 5
                            }, NotificationType.WARNING);
                        }
                    }.bind(this)
                };
            }
        }

        _getQRCodeAddActionRow() {
            return {
                content: {
                    title: _("qr.code-generator") + "...",
                    titleColor: window.Styles.colors.green,
                    clickable: true
                },
                action: function () {
                    NavigationComp.showState(ScreenState.QRCodeResult, {
                        type: Interaction.QR.WRITE_SCENE,
                        text: this.associatedControl.getSceneExecUrlScheme(),
                        mainText: this.associatedControl.getName(),
                        subText: this.associatedControl.groupDetail
                    });
                }.bind(this)
            };
        }

        _getNFCAddActionRow() {
            if ((this.platform === PlatformType.Android || this.platform === PlatformType.DeveloperInterface)
                && nfcService.nfcAvailable()) {
                return {
                    content: {
                        title: _("nfc.smart-tag.create") + "...",
                        titleColor: window.Styles.colors.green,
                        clickable: true
                    },
                    action: function () {
                        NavigationComp.showState(ScreenState.NfcTagInteractionScreen, {
                            type: Interaction.NFC.WRITE_COMMAND,
                            commandUrl: this.associatedControl.getSceneExecUrlScheme()
                        });
                    }.bind(this)
                };
            }
        }

        _getDeleteSceneSection() {
            return {
                rows: [{
                    type: GUI.TableViewV2.CellType.DELETE,
                    content: {
                        iconSrc: Icon.DELETE_2,
                        title: _("scenes.delete")
                    },
                    action: function () {
                        NavigationComp.showWaitingFor(AutomaticDesignerComponent.deleteRule(this.currentEditRule)).then(function () {
                            this.ViewController.navigateBack();
                        }.bind(this));
                    }.bind(this)
                }]
            };
        }

        _openVisuSettings() {
            this.ViewController.showState(ScreenState.AutomaticDesigner.ScenesVisuEditScreen, null, {
                rule: this.currentEditRule
            });
        }

    };
});
