'use strict';

define("ApplicationControl", [
    "Control",
    "IconLib"
], function (
    Control,
    {
        default: Icons
    }
) {
    return class ApplicationControl extends Control {
        constructor() {
            super(...arguments);
        }

        getIcon() {
            if (this.defaultIcon) {
                return this.defaultIcon;
            } else if (this.details.image) {
                return this.details.image;
            } else if (this.type === ControlType.APPLICATION) {
                return Icon.Application.APP_LINK2;
            } else if (this.type === ControlType.WEBPAGE) {
                return Icon.Webpage.WEBPAGE2;
            }
        }

        getStateTextShort() {
            if (this.type === ControlType.APPLICATION) {
                return _("search.controltype.application");
            } else if (this.type === ControlType.WEBPAGE) {
                return _("search.controltype.webpage");
            }
        }

        getButton0() {
            return {
                iconSrc: Icon.Application.APP_LINK,
                reactIcon: Icons.Link,
                command: this.open.bind(this)
            };
        }

        /**
         * Will use tha navigationComp to open the url. It uses the HD_URL if provided and the app is running in HD.
         * Otherwise it'll use the regular url. When only an SD-URL is defined but not an HD one, it'll always use SD.
         * When opening an App-Link it'll inform if opening it failed by providing a message informing that no app is
         * installed that could open that link.
         */
        open() {
            if (this.type === ControlType.WEBPAGE) {
                NavigationComp.openWebsite(this.getUrl());
            } else {
                NavigationComp.openWebsite(this.getUrl(), null, this.displayAppIsNotInstalledWarning.bind(this));
            }
        }

        getUrl() {
            var url;

            if (this.details.urlHd && HD_APP) {
                url = this.details.urlHd;
            } else if (this.details.url) {
                url = this.details.url;
            } else {
                if (this.details.urlHd) {
                    console.error("Fallback to HD Url - no SD given!");
                    url = this.details.urlHd;
                } else {
                    console.error("Could not determine URL to open (" + this.name + ")!");
                    console.error(JSON.stringify(this.details));
                }
            }

            return url;
        }

        displayAppIsNotInstalledWarning() {
            NavigationComp.showPopup({
                title: _("controls.app.not-installed.title"),
                message: _("controls.app.not-installed.text", {
                    appUrl: this.getUrl()
                }),
                buttonCancel: true,
                icon: Icon.CAUTION,
                color: window.Styles.colors.red
            });
        }

    };
});
