'use strict';

define("PoolControl", ["Control", "PoolControlEnums"], function (Control, PoolControlEnums) {
    return class PoolControl extends Control {
        constructor() {
            super(...arguments);
        }

        getLegacyScreens() {
            return Object.values(PoolControlEnums.ScreenState);
        }

        getIcon() {
            return this.getStates().stateIcon;
        }

        specificCreateCmdText(cmdParts, cmdArguments) {
            var text,
                cfg = {
                    name: this.name
                };

            switch (cmdParts[1]) {
                case "swimmingMachine":
                    if (cmdParts[2] === "on") {
                        text = _("cmdtext.pool.swimmingmachine.on", cfg);
                    } else if (cmdParts[2] === "off") {
                        text = _("cmdtext.pool.swimmingmachine.off", cfg);
                    } else {
                        cfg.value = lxFormat("%.0f%%", parseFloat(cmdParts[2]) * 100);
                        text = _("cmdtext.pool.swimmingmachine.set", cfg);
                    }

                    break;

                case "tempMode":
                    var mode = parseInt(cmdParts[2]);
                    cfg.mode = this.getNameOfPoolTempMode(mode);

                    if (mode === 0) {
                        text = _("cmdtext.pool.tempmode.off", cfg);
                    } else if (mode === 1) {
                        text = _("cmdtext.pool.tempmode.auto", cfg);
                    } else {
                        // old translations might expect 'modus' instead of 'mode' as argument
                        cfg.modus = cfg.mode;
                        text = _("cmdtext.pool.tempmode", cfg);
                    }

                    break;

                case "operatingMode":
                    var opMode = parseInt(cmdParts[2]);
                    cfg.mode = this.getNameOfPoolMode(opMode);
                    text = _("cmdtext.pool.operationmode", cfg);
                    break;

                case "targetTemp":
                    var targetTemp = parseFloat(cmdParts[2]);
                    cfg.value = lxFormat("%.0f°", targetTemp);
                    text = _("cmdtext.pool.targettemp", cfg);
                    break;

                case "backwashTime":
                case "rinseTime":
                    var time = parseInt(cmdParts[2]);
                    cfg.time = LxDate.formatSecondsShort(time, true);

                    if (cmdParts[1] === "backwashTime") {
                        text = _("cmdtext.pool.backwashtime", cfg);
                    } else {
                        text = _("cmdtext.pool.rinsetime", cfg);
                    }

                    break;

                case "pump":
                    var active = parseInt(cmdParts[2]) !== 0;

                    if (active) {
                        text = _("cmdtext.pool.pump.activate", cfg);
                    } else {
                        text = _("cmdtext.pool.pump.deactivate", cfg);
                    }

                    break;

                case "drainValve":
                    var open = parseInt(cmdParts[2]) !== 0;

                    if (open) {
                        text = _("cmdtext.pool.drainvalve.open", cfg);
                    } else {
                        text = _("cmdtext.pool.drainvalve.close", cfg);
                    }

                    break;

                case "valvePos":
                    cfg.pos = this.getNameOfPoolValvePositions(parseInt(cmdParts[2]));
                    text = _("cmdtext.pool.valvepos", cfg);
                    break;

                case "startCycle":
                    // START_TIMER: "startCycle/%i/%s", // cycleId, seconds, [seconds rinse]
                    cfg.cycle = this.getNameOfPoolCycleMode(parseInt(cmdParts[2]));
                    cfg.time = LxDate.formatSecondsShort(parseInt(cmdParts[3]), true);

                    if (cmdParts.length > 4) {
                        //TODO-goelzda: How to best display rinsing is also done
                        cfg.rinse = LxDate.formatSecondsShort(parseInt(cmdParts[4]), true);
                        text = _("cmdtext.pool.startcycle.rinse", cfg);
                    } else {
                        text = _("cmdtext.pool.startcycle", cfg);
                    }

                    break;

                case "approveHeating":
                case "approveCooling":
                    var approved = parseInt(cmdParts[2]) !== 0,
                        txtId = "cmdtext.pool.approve";

                    if (cmdParts[1].endsWith("Heating")) {
                        txtId += "heating." + approved;
                    } else {
                        txtId += "cooling." + approved;
                    }

                    text = _(txtId, cfg);
                    break;

                default:
                    // coveropen, coverclose
                    if (cmdArguments) {
                        for (var i = 0; i < cmdArguments.length; i++) {
                            cfg["argument" + i] = cmdArguments[i];
                        }
                    }

                    var key = "cmdtext.pool." + cmdParts[1].toLowerCase();
                    text = _(key, cfg);
                    break;
            }

            return text;
        }

        /**
         * Gets the name of the current pool mode according to the current operating mode
         * @param operatingModeNr
         * @returns {*}
         */
        getNameOfPoolMode(operatingModeNr) {
            switch (operatingModeNr) {
                case PoolControlEnums.PoolMode.OUT_OF_ORDER:
                    return _("pool.control.OP-modes.out-of-order");

                case PoolControlEnums.PoolMode.AUTOMATIC:
                    return _("autopilot");

                case PoolControlEnums.PoolMode.SERVICE_MODE:
                    return _("pool.control.OP-modes.servicemode");
            }
        }

        /**
         * Returns the icon name of the current cycle mode
         * @param cycleModeNr
         * @returns {*}
         */
        getIconNameOfPoolCycleMode(cycleModeNr) {
            switch (cycleModeNr) {
                case PoolControlEnums.PoolCycleMode.FILTER:
                    return 'icon-filtering';

                case PoolControlEnums.PoolCycleMode.FLUSHING:
                    return 'icon-flushing';

                case PoolControlEnums.PoolCycleMode.CIRCULATE:
                    return 'icon-circulating';

                case PoolControlEnums.PoolCycleMode.DRAIN:
                    return 'icon-empty';
            }
        }

        getIconPathOfPoolCycleMode(cycleModeNr) {
            return 'resources/Images/Controls/Pool/' + this.getIconNameOfPoolCycleMode(cycleModeNr) + '.svg';
        }

        /**
         * Returns the name of the current cycle mode
         * @param cycleModeNr
         * @returns {*}
         */
        getNameOfPoolCycleMode(cycleModeNr) {
            switch (cycleModeNr) {
                case PoolControlEnums.PoolCycleMode.FILTER:
                    return _("pool.control.filter");

                case PoolControlEnums.PoolCycleMode.FLUSHING:
                    return _("pool.control.back-wash") + " + " + _("pool.control.clear-wash");

                case PoolControlEnums.PoolCycleMode.CIRCULATE:
                    return _("pool.control.circulate");

                case PoolControlEnums.PoolCycleMode.DRAIN:
                    return _("pool.control.drain");
            }
        }

        /**
         * Retruns the color of the current pool cycle mode
         * @param cycleModeNr
         * @returns {*}
         */
        getColorOfPoolCycleMode(cycleModeNr) {
            switch (cycleModeNr) {
                case PoolControlEnums.PoolCycleMode.NO_CYCLE_ACTIVE:
                    return Color.STATE_INACTIVE;

                case PoolControlEnums.PoolCycleMode.FILTER:
                    return window.Styles.colors.green;

                case PoolControlEnums.PoolCycleMode.FLUSHING:
                    return window.Styles.colors.blue;

                case PoolControlEnums.PoolCycleMode.CIRCULATE:
                    return window.Styles.colors.purple;

                case PoolControlEnums.PoolCycleMode.DRAIN:
                    return window.Styles.colors.orange;
            }
        }

        /**
         * Checks if the PoolControl has an error
         * Available since 7.5.5.27
         * @returns {boolean}
         */
        hasError() {
            if (Feature.POOL_ERROR_HANDLING) {
                var states = this.getStates();

                if (states.error === PoolControlEnums.ErrorTypes.HAD_ERROR || states.error === PoolControlEnums.ErrorTypes.HAS_ERROR) {
                    return true;
                }
            }

            return false;
        }

        /**
         * Checks if the Aquastar Air is offline
         * Available since 8.1.10.24
         * @returns {boolean}
         */
        isOffline() {
            if (Feature.POOL_ERROR_STATE_OFFLINE) {
                var states = this.getStates();
                return states.error === PoolControlEnums.ErrorTypes.IS_OFFLINE;
            } else {
                return false;
            }
        }

        /**
         * Checks if the current temp mode is automatic, so either full or auto heating/ cooling
         * @param tempModeNr
         * @returns {boolean}
         */
        isTempModeAutomatic(tempModeNr) {
            return tempModeNr === PoolControlEnums.PoolTempMode.FULL_AUTO || tempModeNr === PoolControlEnums.PoolTempMode.AUTO_HEATING || tempModeNr === PoolControlEnums.PoolTempMode.AUTO_COOLING;
        }

        /**
         * Returns the name of the current pool temp mode
         * @param tempModeNr
         * @returns {*}
         */
        getNameOfPoolTempMode(tempModeNr) {
            switch (tempModeNr) {
                case PoolControlEnums.PoolTempMode.OFF:
                    return _("pool.control.temp-mode.off");

                case PoolControlEnums.PoolTempMode.FULL_AUTO:
                    return _("autopilot");

                case PoolControlEnums.PoolTempMode.AUTO_HEATING:
                    return _("pool.control.temp-mode.automatic.heating");

                case PoolControlEnums.PoolTempMode.AUTO_COOLING:
                    return _("pool.control.temp-mode.automatic.cooling");

                case PoolControlEnums.PoolTempMode.MAN_HEATING:
                    return _("pool.control.temp-mode.manuel.heating");

                case PoolControlEnums.PoolTempMode.MAN_COOLING:
                    return _("pool.control.temp-mode.manuel.cooling");
            }
        }

        /**
         * Checks if the poolControl has a temperature control
         * @returns {boolean}
         */
        hasTemperatureControl() {
            return !!(this.details.hasHeating || this.details.hasCooling);
        }

        /**
         * Returns the name of the current pool valve cycle
         * @param valveCycleID
         * @returns {*}
         */
        getNameOfPoolValveCycle(valveCycleID) {
            switch (valveCycleID) {
                case PoolControlEnums.PoolValveCycle.NO_CYCLE:
                    return _("pool.control.no-circle");

                case PoolControlEnums.PoolValveCycle.FILTER:
                    return _("pool.control.filter");

                case PoolControlEnums.PoolValveCycle.BACK_WASH:
                    return _("pool.control.back-wash");

                case PoolControlEnums.PoolValveCycle.CLEAR_WASH:
                    return _("pool.control.clear-wash");
            }
        }

        /**
         * Returns the name of the current pool valve position
         * @param valvePosition
         * @returns {*}
         */
        getNameOfPoolValvePositions(valvePosition) {
            switch (valvePosition) {
                case PoolControlEnums.PoolValvePositions.VALVE_MOVES:
                    return _("pool.control.valve-pos.valve-moves");

                case PoolControlEnums.PoolValvePositions.FILTER:
                    return _("pool.control.filter");

                case PoolControlEnums.PoolValvePositions.BACK_WASH:
                    return _("pool.control.back-wash");

                case PoolControlEnums.PoolValvePositions.CLEAR_WASH:
                    return _("pool.control.clear-wash");

                case PoolControlEnums.PoolValvePositions.CIRCULATE:
                    return _("pool.control.circulate");

                case PoolControlEnums.PoolValvePositions.CLOSED:
                    return _("pool.control.closed");

                case PoolControlEnums.PoolValvePositions.DRAIN:
                    return _("pool.control.drain");

                case PoolControlEnums.PoolValvePositions.RELIEVE:
                    return _("pool.control.relieve");
            }
        }

        /**
         * Will return a suitable background color depending on the current states.
         * @return {string}
         */
        detectColorForStates() {
            var color = '',
                states = this.getStates();

            if (states.error) {
                if (states.error === PoolControlEnums.ErrorTypes.IS_OFFLINE) {
                    color = Color.STATE_INACTIVE;
                } else {
                    color = window.Styles.colors.orange;
                }
            } else {
                if (states.currentOpModeNr === PoolControlEnums.PoolMode.SERVICE_MODE) {
                    color = window.Styles.colors.red;
                } else if (states.currentOpModeNr === PoolControlEnums.PoolMode.OUT_OF_ORDER) {
                    color = Color.STATE_INACTIVE;
                } else {
                    color = window.Styles.colors.blue;
                }
            }

            return color;
        }

        /**
         * Since 10.1 apps may set the pool out of service as long as no logic is connected to the reset input.
         * @return {boolean}
         */
        supportsOutOfServiceViaApp() {
            return Feature.POOL_OUT_OF_SERVICE_BY_APP && !this.details.hasResetConnected;
        }

    };
});
