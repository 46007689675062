'use strict';

window.GUI = function (GUI) {
    class EulaInputCell extends GUI.TableViewV2.Cells.BaseCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("eula-input-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.contentPlaceholder.append(EulaInputCellTemplate.getEulaInputCellContent(this.content));
            this.elements.checkbox = this.element.find('.content__checkbox');
            this.elements.link = this.element.find('.text__link');

            if (!this.buttons) {
                this.buttons = {};
            }

            this.buttons.checkbox = new GUI.LxButton(this, this.elements.checkbox[0], window.Styles.colors.green);
            this.buttons.checkbox.useChildsAsActiveParts('fill');
            this.buttons.link = new GUI.LxButton(this, this.elements.link[0], Color.BUTTON_GLOW);
            this.addToHandledSubviews(this.buttons.checkbox);
            this.addToHandledSubviews(this.buttons.link);
        }

        viewDidAppear() {
            super.viewDidAppear();
            this.buttons.link.onButtonTapped = this._linkButtonTapped.bind(this);
            this.buttons.checkbox.onButtonTapped = this._checkboxCheckChange.bind(this);

            this._setState(!!this.content.value);

            this.delegate.eulaCheckboxChanged(this.sectionIdx, this.rowIdx, this.content.value);
        }

        viewWillDisappear(viewRemainsVisible) {
            super.viewWillDisappear(viewRemainsVisible);
            this.buttons.checkbox.onButtonTapped = null;
            this.buttons.link.onButtonTapped = null;
        }

        _linkButtonTapped() {
            NavigationComp.openWebsite(this.content.link);
        }

        _checkboxCheckChange() {
            this._setState(!this.content.value);

            this.delegate.eulaCheckboxChanged(this.sectionIdx, this.rowIdx, this.content.value);
        }

        /**
         * Updates the state attribute and the UI according to the given state.
         * @param checked
         * @private
         */
        _setState(checked) {
            this.content.value = checked;
            this.buttons.checkbox.setActive(this.content.value);
            this.element.toggleClass('checked', this.content.value);
            this.element.toggleClass('invalid', !this.content.value);
        }

    }

    GUI.TableViewV2.Cells.EulaInputCell = EulaInputCell;

    var EulaInputCellTemplate = function () {
        var getEulaInputCellContent = function getEulaInputCellContent(content) {
            var link = '<span class="text__link">' + _("media.service.add.input.eula") + '</span>';

            var text = _("media.service.add.input.eula.read-and-accepted", {
                eulalink: link
            });

            return $('<div class="content__checkbox">' + '   ' + ImageBox.getResourceImageWithClasses(Icon.CHECKED, "checkbox__icon") + '</div>' + '<div class="content__text">' + text + '</div>');
        };

        return {
            getEulaInputCellContent: getEulaInputCellContent
        };
    }();

    GUI.TableViewV2.CellType.Special.MEDIA_EULA_INPUT_CELL = "EulaInputCell";
    return GUI;
}(window.GUI || {});
