import * as React from "react"
import Svg, { Path } from "react-native-svg"

function BreakChain(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path d="M15.974 14.566l-1.414-1.414 5.466-5.466a2 2 0 000-2.828l-.615-.615a2 2 0 00-2.828 0l-5.466 5.466-1.414-1.414 5.466-5.466a4 4 0 015.657 0l.615.615a4 4 0 010 5.657l-5.466 5.466zM14.56 15.98l-5.459 5.459a4 4 0 01-5.657 0l-.615-.615a4 4 0 010-5.657l5.459-5.459 1.414 1.414-5.459 5.459a2 2 0 000 2.828l.615.615a2 2 0 002.828 0l5.459-5.459 1.414 1.414zm3.393 1.01c.236 0 .473.09.653.27l1.846 1.846a.922.922 0 11-1.305 1.305l-1.846-1.846a.922.922 0 01.653-1.575zm-2.833.994c.51 0 .923.413.911.923v2.308a.906.906 0 01-.911.923.942.942 0 01-.935-.923v-2.308a.942.942 0 01.935-.923zm3.779-4.156h2.308c.51.003.923.415.923.926a.92.92 0 01-.923.92h-2.308a.92.92 0 01-.923-.92c0-.51.414-.924.923-.926zM6.307 7.286a.921.921 0 01-.653-.27L3.808 5.17a.922.922 0 111.305-1.305l1.846 1.846a.922.922 0 01-.653 1.575zm2.833-.994a.905.905 0 01-.911-.923V3.061a.906.906 0 01.911-.923c.51 0 .923.414.935.923v2.308a.942.942 0 01-.935.923zm-3.779 4.156H3.053a.927.927 0 01-.923-.926.92.92 0 01.923-.92h2.308a.92.92 0 01.923.92c0 .51-.414.924-.923.926z" />
        </Svg>
    )
}

export default BreakChain
