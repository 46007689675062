import {
    LxReactSwitch,
    LxReactButton,
    LxReactHorizontalSlider,
    LxReactPressable,
    LxIndicator,
    LxReactText,
    LxReactUnreadText,
    LxReactIconButton,
    LxReactImage,
    LxReactView,
    LxReactOnlineView,
    LxReactTextView,
    LxReactDateTimePicker,
    LxReactDurationPicker,
    LxReactSystemStatusBadge,
    LxReactFlexibleCell,
    LxReactImageView,
    LxReactControl,
    LxReactAlarmClockBottomContainer
} from "LxComponents";
import Icons from "IconLib";
import globalStyles from "GlobalStyles";
import {ButtonType} from "../../../react-comps/LxReactButton/LxReactButtonDefaultSettings";

$(function () {
    window.LxCellReactMap = new Proxy({
        [window.GUI.TableViewV2.CellType.GENERAL]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            return {
                props: mapBasicProps(cellObj)
            }
        },
        [window.GUI.TableViewV2.CellType.CHECKABLE]: mapObj => {
            return {
                props: mapCheckableBasicProps(mapObj)
            }
        },
        [window.GUI.TableViewV2.CellType.SWITCH]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            let cellProps = mapBasicProps(cellObj);

            cellProps.mainRightContent = []

            if (cellObj.content.button2Src) {
               cellProps.mainRightContent.push({
                    comp: LxReactButton,
                    props: {
                        onPress: cellObj.buttonTapped,
                        children: <LxReactImageView
                            source={cellObj.content.button2Src}
                            imageStyle={{
                                fill: globalStyles.colors.white,
                                width: globalStyles.sizes.icons.button,
                            }}
                            containerStyle={{backgroundColor: globalStyles.colors.transparent}}
                        />,
                        pkey: `${sectionIdx}.${rowIdx}-button`,
                        buttonType: ButtonType.SECONDARY,
                        containerStyle: {
                            marginRight: globalStyles.spacings.gaps.regular,
                            width: globalStyles.sizes.button.size,
                            height: globalStyles.sizes.button.size,
                        },
                        pressableStyle: {
                            alignItems: "center",
                            justifyContent: "center",
                            paddingVertical: 0,
                            paddingHorizontal: 0,
                        }
                    }
                })
            }

            cellProps.mainRightContent.push({
                comp: LxReactSwitch,
                props: {
                    activeTrackColor: cellObj.content.activeColor,
                    value: cellObj.content.active,
                    onValueChanged: (newValue) => cellObj.onSwitchChanged(newValue, sectionIdx, rowIdx, table, cellObj),
                    disabled: cellObj.content.disabled
                }
            })

            return {
                props: cellProps
            }
        },
        [window.GUI.TableViewV2.CellType.BUTTON]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            let cellProps = mapBasicProps(cellObj);

            if (!Array.isArray(cellProps.mainRightContent)) {
                cellProps.mainRightContent = [];
            }

            if (cellObj.content.stateIconSrc) {
                cellProps.mainRightContent.push(
                    {
                        comp: Icons.LoxoneHeart,
                        props: {
                            height: 20,
                            width: 20
                        }
                    }
                )
            }

            if (cellObj.content.buttonSrc) {
                cellProps.mainRightContent.push({
                    comp: LxReactButton,
                    props: {
                        // todo add icon
                        children: <LxReactImageView source={cellObj.content.buttonSrc} imageStyle={{fill: cellObj.content.activeIconBGColor || globalStyles.colors.text.primary}} {...cellObj.content.buttonIconProps}/>,
                        onPress: cellObj.buttonTapped,
                        pkey: `${sectionIdx}.${rowIdx}-button`
                    }
                })
            }

            if (cellObj.content.button2Src) {

                cellProps.mainRightContent.push({
                    comp: LxReactButton,
                    props: {
                        // todo add icon
                        text: "👎",
                        onPress: cellObj.button2Tapped,
                        pkey: `${sectionIdx}.${rowIdx}-button2`,
                        disabled: cellObj.content.disabled
                    }
                })
            }


            return {
                props: cellProps
            }
        },
        [window.GUI.TableViewV2.CellType.USER_CELL]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            let user = cellObj.content.user,
                {name, trustMember} = user,
                {reactIcon, descriptionColor} = ActiveMSComponent.getUserStateDescription(user);

            cellObj.content.title = name;
            delete cellObj.content.subtitle;
            delete cellObj.content.subtitleColor;
            delete cellObj.content.disclosureIcon;
            cellObj.content.reactLeftIconSrc = reactIcon;
            cellObj.content.leftIconColor = descriptionColor;

            if (trustMember) {
                cellObj.content.subtitle = (cellObj.content.trustPeers.find(function (peer) {
                    return peer.serial === trustMember;
                }) || {}).name || trustMember;
            }

            cellObj.content.mainCenterStyle = {
                flexBasis: "inherit"
            }

            cellObj.content.titleStyle = {
                maxWidth: "100%",
                numberOfLines: 1
            }

            return {
                props: mapBasicProps(cellObj)
            }
        },
        [window.GUI.TableViewV2.CellType.SLIDER]: ({cellObj, sectionIdx, rowIdx, cell, table, reloadFn}) => {
            let defaultContent = {
                    minValue: 0,
                    maxValue: 100,
                    step: 1,
                },
                cellProps,
                value = cellObj.content.value,
                reloadWrapper = (cellContent) => {
                    if (cellContent.value > 0) {
                        cellContent.disclosureColor = Color.TEXT_ACTIVE;
                    } else {
                        cellContent.disclosureColor = Color.TEXT_INACTIVE;
                    }
                    if (cellObj.userfriendlyValueForSlider) {
                        cellContent.disclosureText = cellObj.userfriendlyValueForSlider(cell, sectionIdx, rowIdx, table, cellContent.value);
                    } else {
                        cellContent.disclosureText = cellContent.value.toString();
                    }
                    reloadFn(sectionIdx, rowIdx, cellContent);
                };

            if (cellObj.userfriendlyValueForSlider) {
                value = cellObj.userfriendlyValueForSlider(cell, sectionIdx, rowIdx, table, cellObj.content.value);
            }

            if (cellObj.content.formatWithHTML) {
                cellObj.content.disclosureText = lxFormat(cellObj.content.formatWithHTML, value, true);
            } else if (lxUnitConverter.isConvertable(cellObj.content.format)) {
                cellObj.content.disclosureText = lxUnitConverter.convertAndApply(cellObj.content.format, value, true);
            } else if (typeof value === "string") {
                cellObj.content.disclosureText = value;
            } else {
                cellObj.content.disclosureText = lxFormat(cellObj.content.format, value, true);
            }

            cellProps = mapBasicProps(cellObj)

            cellProps.bottomContent = []

            cellObj.content.min = cellObj.content.min || defaultContent.minValue;
            cellObj.content.max = cellObj.content.maxValue || defaultContent.maxValue;
            cellObj.content.step = cellObj.content.step || defaultContent.step;

            let buttonFill = AMBIENT_MODE ? globalStyles.colors.fill.tertiary : globalStyles.colors.text.secondary;

            if (cellObj.content.minIconSrc) {
                cellProps.bottomContent.push({
                    comp: LxReactPressable,
                    props: {
                        pkey: `${sectionIdx}.${rowIdx}-min`,
                        disabled: cellObj.content.disabled,
                        onPress: () => {
                            if (cellObj.content.disabled) {
                                return;
                            }

                            if (cellObj.content.value - cellObj.content.step < cellObj.content.min) {
                                return;
                            }

                            cellObj.content.value -= cellObj.content.step;
                            cellObj.sliderClicked && cellObj.sliderClicked(cellObj, sectionIdx, rowIdx, table, cellObj.content.value);
                            reloadWrapper(cellObj.content);
                        },
                        childComp: getIcon(cellObj.content.minIconSrc, {
                            fill: buttonFill,
                            height: 30,
                            width: 30
                        }, {
                            marginRight: globalStyles.spacings.gaps.regular
                        }),
                    }
                })
            }

            cellProps.bottomContent.push({
                comp: LxReactHorizontalSlider,
                props: {
                    disabled: !!cellObj.content.disabled,
                    min: cellObj.content.min,
                    max: cellObj.content.max,
                    step: cellObj.content.step,
                    value: cellObj.content.value,
                    activeColor: cellObj.content.valueColor,
                    onValueChanged: (value, isDragging) => {
                        cellObj.content.value = value;
                        if (!isDragging) {
                            cellObj.sliderClicked && cellObj.sliderClicked(cellObj, sectionIdx, rowIdx, table, value);
                        }
                        cellObj.sliderDragged && cellObj.sliderDragged(cellObj, sectionIdx, rowIdx, table, value);
                        reloadWrapper(cellObj.content);
                    },
                    onDraggingChanged: (isDragging, lastValue) => {
                        if (!isDragging) {
                            cellObj.sliderDragEnded && cellObj.sliderDragEnded(cellObj, sectionIdx, rowIdx, table, lastValue);
                        }
                    },
                    flex: 1
                }
            });

            if (cellObj.content.maxIconSrc) {
                cellProps.bottomContent.push({
                    comp: LxReactPressable,
                    props: {
                        pkey: `${sectionIdx}.${rowIdx}-max`,
                        disabled: cellObj.content.disabled,
                        onPress: () => {
                            if (cellObj.content.disabled) {
                                return;
                            }

                            if (cellObj.content.value + cellObj.content.step > cellObj.content.max) {
                                return;
                            }

                            cellObj.content.value += cellObj.content.step;
                            cellObj.sliderClicked && cellObj.sliderClicked(cellObj, sectionIdx, rowIdx, table, cellObj.content.value);
                            reloadWrapper(cellObj.content);
                        },
                        childComp: getIcon(cellObj.content.maxIconSrc, {
                            fill: buttonFill,
                            height: 30,
                            width: 30
                        }, {
                            marginLeft: globalStyles.spacings.gaps.regular
                        })
                    }
                })
            }

            cellProps.bottomStyle = {
                flexDirection: "row",
                marginTop: globalStyles.spacings.gaps.big
            }

            return {
                props: cellProps
            }
        },
        [window.GUI.TableViewV2.CellType.Special.LOADING]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            return {
                props: {
                    mainCenterContent: {
                        comp: LxIndicator,
                        props: {
                            height: globalStyles.sizes.icons.small,
                            width: globalStyles.sizes.icons.small
                        }
                    }
                }
            }
        },
        [window.GUI.TableViewV2.CellType.TEXT]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            let adoptedProps = {
                title: cellObj.content.text,
                mainCenterStyle: {
                    alignItems: cellObj.content.centered ? "center" : "flex-start",
                },
                cellContainerStyle: {
                    backgroundColor: cellObj.content.color ? applyAlphaChannelToColorString(cellObj.content.color, 0.4) : null
                },
                titleStyle: {
                    color: cellObj.content.textColor || globalStyles.colors.stateActive
                }
            };

            return {
                props: adoptedProps
            }
        },
        [window.GUI.TableViewV2.CellType.LIGHT]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            let basicProps = mapBasicProps(cellObj),
                adoptedProps = {
                    mainCenterStyle: {
                        alignItems: cellObj.content.centered ? "center" : "flex-start",
                    },
                    titleStyle: {
                        color: cellObj.content.color || globalStyles.colors.stateActive
                    }
                };

            if (cellObj.content.titleStyle) {
                adoptedProps.titleStyle = {
                    ...adoptedProps.titleStyle,
                    ...cellObj.content.titleStyle
                }

                adoptedProps.subTitleStyle = cellObj.content.titleStyle;
            }

            return {
                props: Object.assign(basicProps, adoptedProps)
            }
        },
        [window.GUI.TableViewV2.CellType.History]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            let basicProps = mapBasicProps(cellObj),
                adoptedProps = {
                    mainLeftContent: {
                        comp: LxReactText,
                        props: {
                            style: {
                                color: Color.TEXT_SECONDARY_A
                            },
                            children: cellObj.content.time
                        }
                    },
                    subTitleStyle: {
                        color: Color.TEXT_SECONDARY_A
                    }
                };

            return {
                props: Object.assign(basicProps, adoptedProps)
            }
        },
        [window.GUI.TableViewV2.CellType.GATE]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            delete cellObj.content.disclosureText;
            const shouldShowMessageCenterDisclosure = isAffectedByMessageCenter(cellObj);
            if (shouldShowMessageCenterDisclosure) {
                replaceWithMessageCenterInfo(cellObj);
            }
            let basicProps = mapBasicProps(cellObj);

            return{
                props: basicProps
            };
        },
        [window.GUI.TableViewV2.CellType.JALOUSIE]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            if (!cellObj.states.universalIsLocked) {
                const shouldShowMessageCenterDisclosure = isAffectedByMessageCenter(cellObj);
                if(shouldShowMessageCenterDisclosure) {
                    replaceWithMessageCenterInfo(cellObj);
                    cellObj.content.disclosureText = null;
                } else if (cellObj.states.locked || cellObj.states.safetyActive) {
                    cellObj.content.disclosureText = "!";
                    cellObj.content.disclosureColor = globalStyles.colors.red;
                } else {
                    cellObj.content.disclosureText = cellObj.control.details.isAutomatic ? "A" : null;
                    cellObj.content.disclosureColor = cellObj.states.autoActive ? globalStyles.colors.stateActive : cellObj.states.autoAllowed ? globalStyles.colors.text.primary : globalStyles.colors.orange
                }
            }

            let basicProps = mapBasicProps(cellObj);

            return {
                props: basicProps
            }
        },
        [window.GUI.TableViewV2.CellType.ALARM]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            delete cellObj.content.disclosureText;
            const isActive = cellObj.states.alarmActive;
            const shouldShowMessageCenterDisclosure = isAffectedByMessageCenter(cellObj);
            if (isActive) {
                cellObj.content.rightIconColor = globalStyles.colors.red
            }
            if (shouldShowMessageCenterDisclosure) {
                replaceWithMessageCenterInfo(cellObj);
            }
            let basicProps = mapBasicProps(cellObj);

            if (!isActive && cellObj.control.movementEnabled() && !shouldShowMessageCenterDisclosure) {
                let iconSrc;

                if (cellObj.states.disableMove) {
                    iconSrc = Icon.Alarm.MOTION_INACTIVE;
                } else {
                    iconSrc = Icon.Alarm.MOTION_ACTIVE;
                }

                basicProps.mainRightContent.unshift({
                    comp: LxReactImageView,
                    props: {
                        source: iconSrc,
                        containerStyle: {
                            fill: cellObj.states.stateColor || globalStyles.colors.text.secondary,
                        },
                        imageStyle: {
                            height: globalStyles.sizes.icons.small,
                            width: globalStyles.sizes.icons.medium
                        }
                    }
                });
            }

            return{
                props: basicProps
            };
        },

        [window.GUI.TableViewV2.CellType.Special.MediaBrowserBrowsableCell]: ({
                                                                                  cellObj,
                                                                                  sectionIdx,
                                                                                  rowIdx,
                                                                                  cell,
                                                                                  table
                                                                              }) => {
            return {
                props: mapBasicProps(cellObj, true)
            }
        },
        [window.GUI.TableViewV2.CellType.Special.MediaBrowserPlayableCell]: ({
                                                                                 cellObj,
                                                                                 sectionIdx,
                                                                                 rowIdx,
                                                                                 cell,
                                                                                 table
                                                                             }) => {
            return {
                props: mapBasicProps(cellObj, true)
            }
        },
        [window.GUI.TableViewV2.CellType.Special.MEDIA]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            return {
                props: mapBasicProps(cellObj)
            }
        },
        [window.GUI.TableViewV2.CellType.Special.MESSAGE_CENTER_ENTRY_CELL]: ({
                                                                                  cellObj,
                                                                                  sectionIdx,
                                                                                  rowIdx,
                                                                                  cell,
                                                                                  table
                                                                              }) => {
            let entry = cellObj.content.entry,
                severityColor = MessageCenterHelper.getColorForSeverityEntry(entry, true),
                severityIcon = MessageCenterHelper.getIconForSeverityEntry(entry),
                props = mapBasicProps(cellObj),
                topText = MessageCenterHelper.getFormatedDate(entry),
                location = MessageCenterHelper.getLocationForEntry(entry);

            if (location) {
                topText += " • " + location;
            }

            props.title = entry.title;
            props.subTitle = entry.affectedName;
            props.titleStyle = {
                ...globalStyles.textStyles.body.bold
            }
            props.subTitleStyle = {
                color: globalStyles.colors.text.primary
            };
            props.topContent = {
                comp: LxReactUnreadText,
                props: {
                    style: {
                        ...globalStyles.textStyles.footNote.default,
                        color: globalStyles.colors.text.secondary,
                        marginBottom: globalStyles.spacings.gaps.verySmall
                    },
                    isRead: !!entry.readAt,
                    children: topText
                }
            };
            props.mainLeftContent = {
                comp: severityIcon,
                props: {
                    fill: severityColor || globalStyles.colors.white,
                    height: globalStyles.sizes.icons.medium,
                    width: globalStyles.sizes.icons.medium
                }
            };

            return {
                props: props
            }
        },
        [window.GUI.TableViewV2.CellType.Special.MINISERVER_CELL]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            return {
                props: mapBasicProps(cellObj)
            }
        },
        [window.GUI.TableViewV2.CellType.CENTER]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            let adoptedProps;

            if (cellObj.content.icon) {
                adoptedProps = {
                    mainCenterContent: {
                        comp: Icons.LoxoneHeart,
                        props: {
                            height: 30,
                            width: 30
                        }
                    },

                }
            } else {
                adoptedProps = {
                    title: cellObj.content.title,
                    titleStyle: {
                        color: cellObj.content.textColor || globalStyles.colors.text.secondary,
                    }
                }
            }

            adoptedProps.mainCenterStyle = {
                alignItems: "center"
            }

            return {
                props: adoptedProps
            }
        },
        [window.GUI.TableViewV2.CellType.TINY_SEPARATOR]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            return {
                props: {
                    cellElementStyle: {
                        height: 20,
                        minHeight: 20
                    }
                }
            }
        },
        [window.GUI.TableViewV2.CellType.TINY]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            let leftContent = null;

            if (cellObj.content.iconSrc) {
                leftContent = {
                    comp: Icons.LoxoneHeart,
                    props: {
                        height: 20,
                        width: 20
                    }
                }
            }

            return {
                props: {
                    title: cellObj.content.title,
                    mainCenterStyle: {
                        alignItems: "center"
                    },
                    cellElementStyle: {
                        height: 40,
                        minHeight: 40
                    },
                    mainLeftContent: leftContent,
                    onPress: cellObj.action,
                }
            }
        },
        [window.GUI.TableViewV2.CellType.CENTERED_BUTTON]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            return {
                props: {
                    title: cellObj.content.title,
                    titleStyle: {
                        color: cellObj.content.textColor
                    },
                    mainCenterStyle: {
                        alignItems: "center"
                    },
                    cellElementStyle: {
                        backgroundColor: cellObj.content.color || Color.STATE_INACTIVE
                    },
                    onPress: () => {
                        cellObj.action && cellObj.action();
                    },
                }
            }
        },
        [window.GUI.TableViewV2.CellType.DELETE]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            let cellTitle = (cellObj.content && cellObj.content.title) ||  _("delete")
            return {
                props: {
                    ...mapBasicProps(cellObj),
                    title: cellTitle,
                    titleStyle: {
                        color: globalStyles.colors.red
                    },
                    onPress: cellObj.action
                }
            }
        },
        [window.GUI.TableViewV2.CellType.Special.COMFORT_ACTION]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            let backgroundColor = null,
                mainCenter = null;

            if (cellObj.content.tintColor) {
                backgroundColor = applyAlphaChannelToColorString(cellObj.content.tintColor, 0.4);
            }

            if (cellObj.content.class === "thinking") {
                cellObj.content.title = null;

                mainCenter = {
                    comp: LxIndicator,
                    props: {
                        height: 30,
                        width: 30
                    }
                };
            } else if (cellObj.content.icon) {
                mainCenter = {
                    comp: Icons.LoxoneHeart,
                    props: {
                        height: 30,
                        width: 30
                    }
                }
            }


            return {
                props: {
                    title: cellObj.content.title,
                    titleStyle: {
                        color: cellObj.content.textColor || Color.TEXT_SECONDARY_B
                    },
                    cellElementStyle: {
                        backgroundColor: backgroundColor
                    },
                    mainCenterContent: mainCenter,
                    onPress: cellObj.action
                }
            }
        },
        [window.GUI.TableViewV2.CellType.Special.ALARM_CLOCK_ENTRY]: ({ cellObj, sectionIdx, rowIdx, cell, table}) => {
            let adoptedProps = mapBasicProps(cellObj),
               weekDayModes = [],
               specialDayModes = [];

            adoptedProps.bottomContent = [];


           if (cellObj.content.nightLight) {
               var mode = cellObj.content.daily ? _("daily") : _("one-time");
               weekDayModes.push(mode);

               adoptedProps.bottomContent.push({
                   comp: LxReactAlarmClockBottomContainer,
                   props: {
                       isActive: cellObj.content.isActive && !cellObj.content.isControlDisabled,
                       weekDays: weekDayModes,
                       nightLight: cellObj.content.nightLight
                   }
               });
           } else {
               cellObj.content.modes.forEach((modeKey) => {
                   //currentMode = modes[modeKey];

                   if (modeKey >= 3 && modeKey <= 9) {
                       weekDayModes.push(shortenOperatingMode(modeKey))
                   } else {
                       specialDayModes.push(shortenOperatingMode(modeKey))
                   }
               })

               adoptedProps.bottomContent.push({
                   comp: LxReactAlarmClockBottomContainer,
                   props: {
                       pkey: `LxReactAlarmClockBottomContainer:${sectionIdx}:${rowIdx}`,
                   isActive: cellObj.content.isActive&& !cellObj.content.isControlDisabled,
                       weekDays: weekDayModes,
                       specialDays: specialDayModes,
                       nightLight: cellObj.content.nightLight
                   }
               });
           }

            adoptedProps.mainRightContent = [];

           let timeTextStyle = {
               color: globalStyles.colors.text.primary,
               marginRight: globalStyles.spacings.gaps.smaller
           }

            if (cellObj.content.alarmTime !== undefined) {
                adoptedProps.mainRightContent.push({
                    comp: LxReactText,
                    props: {
                        children: LxDate.formatTimeFromSecondsToLocal(cellObj.content.alarmTime, true),
                        style: [globalStyles.textStyles.body.default, timeTextStyle]
                    }
                })
            }

           if (!cellObj.content.hideSwitch) {
               adoptedProps.mainRightContent.push({
                   comp: LxReactSwitch,
                   props: {
                       value: cellObj.content.isActive,
                       onValueChanged: (value) => {
                           cellObj.onSwitchChanged && cellObj.onSwitchChanged(value, sectionIdx, rowIdx, table, cell);
                       },
                       activeTrackColor: cellObj.content.isControlDisabled ? globalStyles.colors.red : null // default color is green
                   }
               })
           }


           adoptedProps.mainRightContent.push({
               comp: Icons.Chevron,
               props: {
                   fill: globalStyles.colors.text.secondary,
                   height: globalStyles.sizes.icons.small,
                   width: 12,
                   style: {marginLeft: "5px"}
               }
           })

           adoptedProps.title = cellObj.content.name;


            return {
                props: adoptedProps
            }
        },
        [window.GUI.TableViewV2.CellType.Special.AUTOPILOT_HISTORY_CELL]: ({
                                                                               cellObj,
                                                                               sectionIdx,
                                                                               rowIdx,
                                                                               cell,
                                                                               table
                                                                           }) => {
            return {
                props: {
                    title: cellObj.content.title,
                    titleStyle: {
                        color: Color.WHITE
                    },
                    subTitle: cellObj.content.subtitle,
                    mainLeftContent: {
                        comp: LxReactText,
                        props: {
                            children: cellObj.content.time,
                            style: {
                                marginTop: 2,
                                color: Color.TEXT_SECONDARY_B
                            }
                        }
                    },
                    mainLeftStyle: {
                        height: "100%",
                        alignItems: "flex-start"
                    }
                }
            }
        },
        [window.GUI.TableViewV2.CellType.CHECKABLE_BUTTON]: (obj) => {
            return {
                props: Object.assign(
                    window.LxCellReactMap[window.GUI.TableViewV2.CellType.CHECKABLE](obj).props,
                    window.LxCellReactMap[window.GUI.TableViewV2.CellType.BUTTON](obj).props
                )
            }
        },
        [window.GUI.TableViewV2.CellType.CHECKABLE_DESCRIPTION]: (obj) => {
            return {
                props: Object.assign(
                    window.LxCellReactMap[window.GUI.TableViewV2.CellType.CHECKABLE](obj).props,
                    {
                        bottomContent: {
                            comp: LxReactText,
                            props: {
                                children: obj.cellObj.content.description,
                                style: {
                                    marginTop: globalStyles.spacings.gaps.small,
                                    color: Color.TEXT_SECONDARY_B
                                }
                            }
                        }
                    }
                )
            }
        },
        [window.GUI.TableViewV2.CellType.CHECKABLE_VALUE_SELECTION]: ({
                                                                          cellObj,
                                                                          sectionIdx,
                                                                          rowIdx,
                                                                          cell,
                                                                          table,
                                                                          reloadFn
                                                                      }) => {
            let selectAbleProps = window.LxCellReactMap[window.GUI.TableViewV2.CellType.CHECKABLE]({
                    cellObj,
                    sectionIdx,
                    rowIdx,
                    cell,
                    table
                }).props,
                iconStyle = {
                    height: globalStyles.sizes.icons.big,
                    width: globalStyles.sizes.icons.big,
                    fill: globalStyles.colors.greySlider
                },
                containerStyle = {
                    backgroundColor: globalStyles.colors.transparent,
                    padding: 0
                },
                valueSelection = cellObj.content.valueSelection,
                cellContent = cellObj.content;


            valueSelection.stepWidth = valueSelection.stepWidth || 1;
            valueSelection.minValue = valueSelection.minValue || 0;
            valueSelection.maxValue = valueSelection.maxValue || 100;
            valueSelection.valueFormat = valueSelection.valueFormat || "%.0f";
            valueSelection.value = valueSelection.value || 0;

            selectAbleProps.bottomContent = [
                {
                    comp: LxReactIconButton,
                    props: {
                        icon: Icons.Minus,
                        iconStyle: iconStyle,
                        containerStyle: containerStyle,
                        onPress: () => {
                            valueSelection.value = Math.max(valueSelection.value - valueSelection.stepWidth, valueSelection.minValue);

                            cellObj.content.valueChangedFn(valueSelection.value);
                            cellContent.valueSelection.value = valueSelection.value;
                            reloadFn(sectionIdx, rowIdx, cellContent);

                        }
                    }
                },
                {
                    comp: LxReactText,
                    props: {
                        children: lxFormat(valueSelection.valueFormat, valueSelection.value),
                        style: {
                            textAlignVertical: "center",
                            textAlign: "center",
                            color: globalStyles.colors.text.primary,
                            fontWeight: "bold",
                            fontSize: globalStyles.fontSettings.sizes.veryLarge,
                            flex: 1
                        }
                    }
                },
                {
                    comp: LxReactIconButton,
                    props: {
                        icon: Icons.Plus,
                        iconStyle: iconStyle,
                        containerStyle: containerStyle,
                        onPress: () => {
                            valueSelection.value = Math.min(valueSelection.value + valueSelection.stepWidth, valueSelection.maxValue);

                            valueSelection.valueChangedFn(valueSelection.value);
                            cellContent.valueSelection.value = valueSelection.value;
                            reloadFn(sectionIdx, rowIdx, cellContent);
                        }
                    }
                }
            ]

            selectAbleProps.bottomStyle = {
                flexDirection: "row",
                marginTop: globalStyles.spacings.gaps.small
            };

            selectAbleProps.leftAccessoryStyle = {
                paddingTop: globalStyles.spacings.gaps.small,
                alignItems: "start"
            }

            return {
                props: selectAbleProps
            }
        },
        /*[window.GUI.TableViewV2.CellType.Special.COLLECTION]: ({ cellObj, sectionIdx, rowIdx, cell, table}) => {

            return {
                props: selectAbleProps
            }
        },*/
        [window.GUI.TableViewV2.CellType.Special.NEWS_HUB]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            let props = {};

            props.title = cellObj.content.entry.title;
            props.subTitle = moment(cellObj.content.entry.timestamp * 1000).format(DateType.DateText).toUpperCase();
            props.onPress = cellObj.action;

            if (cellObj.content.entry.image) {
                props.topContent = {
                    comp: LxReactImage,
                    props: {
                        source: {
                            //uri: cellObj.content.entry.image,
                            uri: "https://app-content.loxone.com/deviceImages/CapTouch.png",
                            width: 500,
                            height: 500
                        }
                    }
                }

                if (!cellObj.content.isNew) {
                    props.topContent.props.style = {
                        opacity: 0.3
                        //tintColor: globalStyles.colors.greySlider
                    }
                }
            }

            return {
                props: props
            }
        },
        [window.GUI.TableViewV2.CellType.SLIDER_SWITCH]: ({cellObj, sectionIdx, rowIdx, cell, table, reloadFn}) => {
            let baseSliderProps = window.LxCellReactMap[window.GUI.TableViewV2.CellType.SLIDER]({
                cellObj,
                sectionIdx,
                rowIdx,
                cell,
                table,
                reloadFn
            }).props;

            baseSliderProps.mainRightContent.push(
                {
                    comp: LxReactSwitch,
                    props: {
                        value: cellObj.content.value > 0,
                        onValueChanged: (value) => {
                            cellObj.onSwitchChanged && cellObj.onSwitchChanged(value, sectionIdx, rowIdx, table, cell);
                        }
                    }
                }
            )

            return {
                props: baseSliderProps
            }
        },
        [window.GUI.TableViewV2.CellType.Special.COLOR_PICKER_BRIGHTNESS]: ({
                                                                                cellObj,
                                                                                sectionIdx,
                                                                                rowIdx,
                                                                                cell,
                                                                                table,
                                                                                reloadFn
                                                                            }) => {
            let sliderSwitchProps = window.LxCellReactMap[window.GUI.TableViewV2.CellType.SLIDER_SWITCH]({
                cellObj,
                sectionIdx,
                rowIdx,
                cell,
                table,
                reloadFn
            }).props;

            sliderSwitchProps.mainRightContent.unshift(
                {
                    comp: LxReactView,
                    props: {
                        style: {
                            backgroundColor: LxColorUtils.getCSSRGBPropertyFromColorObject(cellObj.content.previewColor),
                            borderRadius: 50,
                            height: globalStyles.sizes.small,
                            width: globalStyles.sizes.small,
                            marginRight: globalStyles.sizes.smallest
                        }
                    }
                }
            )

            return {
                props: sliderSwitchProps
            }
        },
        [window.GUI.TableViewV2.CellType.Special.COLOR_PICKER_FAVORITES]: ({
                                                                               cellObj,
                                                                               sectionIdx,
                                                                               rowIdx,
                                                                               cell,
                                                                               table
                                                                           }) => {
            let baseProps;

            cellObj.content.title = _("controls.colorpicker.favorites");
            cellObj.content.leftIconSrc = Icons.Star;

            baseProps = mapBasicProps(cellObj);

            baseProps.bottomContent = cellObj.content.colorsArray.map((colorObj, idx) => {
                return {
                    comp: LxReactPressable,
                    props: {
                        onPress: () => {
                            cellObj.favoriteSelected(idx, sectionIdx, rowIdx, table)
                        },
                        style: {
                            borderRadius: 50,
                            backgroundColor: LxColorUtils.getCSSRGBPropertyFromColorObject(colorObj),
                            height: globalStyles.sizes.biggest,
                            width: globalStyles.sizes.biggest,
                            marginLeft: "auto",
                            marginRight: "auto"
                        }
                    }
                }
            })

            baseProps.bottomStyle = {
                marginTop: globalStyles.sizes.small,
                flexDirection: "row",
                flex: "space"
            };

            return {
                props: baseProps
            }
        },
        [GUI.TableViewV2.CellType.Special.DEVICE_LEARNING_CELL]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            let baseProps = mapBasicProps(cellObj),
                imageComp = getLeftIcon(cellObj);

            baseProps.mainLeftContent = {
                comp: LxReactOnlineView,
                props: {
                    imageComp: imageComp
                }
            }

            if (cellObj.content.hasOwnProperty("isOnline")) {
                baseProps.mainLeftContent.props.isOnline = cellObj.content.isOnline;
            }

            baseProps.mainLeftStyle = {
                height: globalStyles.sizes.bigger,
                width: globalStyles.sizes.bigger
            }

            return {
                props: baseProps
            }
        },
        [GUI.TableViewV2.CellType.Special.COMFORT_STATE]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            let baseProps;

            cellObj.content.disclosureText = cellObj.content.stateText;
            if (cellObj.content.stateTextColor) {
                cellObj.content.disclosureColor = cellObj.content.stateTextColor;

            }

            baseProps = mapBasicProps(cellObj);

            if (cellObj.content.color) {
                baseProps.mainRightStyle = {
                    ...baseProps.mainRightStyle,
                    backgroundColor: cellObj.content.color
                }
            }

            if (cellObj.content.stateTextColor) {
                baseProps.mainRightStyle = baseProps.mainRightStyle || {};
                baseProps.mainRightStyle.color = cellObj.content.color;
            }

            if (cellObj.content.subtitleColor) {
                baseProps.subTitleStyle = baseProps.subTitleStyle || {};
                baseProps.subTitleStyle.color = cellObj.content.subtitleColor;
            }


            /*baseProps.mainCenterStyle = {
                flex: 2
            }*/

            baseProps.mainRightStyle = {
                ...baseProps.mainRightStyle,
                flexShrink: 0,
                width: "auto"
            }

            if (cellObj.content.stateTextPopup) {
                baseProps.onPress = () => {
                    NavigationComp.showPopup({
                        title: cellObj.content.title,
                        message: cellObj.content.disclosureText,
                        buttonOk: _('close')
                    })
                }
            } else if (cellObj.onSelected) {
                baseProps.onPress = cellObj.onSelected
            }


            return {
                props: baseProps
            }
        },
        [GUI.TableViewV2.CellType.INPUT]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            let baseProps = {},
                validInput = true,
                leftIcon,
                textViewProps;

            if (cellObj.content.leftIconSrc) {
                leftIcon = getLeftIcon(cellObj);
            }

            let color;
            if (cellObj.content?.disabled) {
                color = globalStyles.colors.placeholder;
            } else if (cellObj.content?.textStyle?.color) {
                color = cellObj.content.textStyle.color;
            } else {
                color = globalStyles.colors.green_fixed;
            }

            textViewProps = {
                title: cellObj.content.title,
                subtitle: cellObj.content.subtitle,
                titleContainerStyle: cellObj.content.titleContainerStyle,
                textStyle: {
                    fontSize: globalStyles.fontSettings.sizes.medium,
                    color,
                    ...cellObj.content.textStyle
                },
                value: cellObj.content.value || "",
                placeholder: cellObj.content.placeholder,
                leftIcon: leftIcon ? leftIcon.comp : leftIcon,
                alignment: cellObj.content.alignment,
                type: cellObj.content.type,
                biometricSecretTargetMs: cellObj.content.biometricSecretTargetMs,
                autoFocus: cellObj.content.autoFocus,
                maxLength: cellObj.content.maxLength,
                secureTextEntry: cellObj.content.type === GUI.LxInputEnum.Type.PASSWORD,
                hideRightIcon: cellObj.content.hideRightIcon,
                validation: cellObj.content.validation,
                asynValidationOnEnd: cellObj.content.asynValidationOnEnd,
                validationErrorText: cellObj.content.validationErrorText,
                disabled: cellObj.content.disabled,
                validationRegex: cellObj.content.validationRegex,
                customErrorMessage: cellObj.content.customErrorMessage,
                keyboardType: cellObj.content.keyboardType,
                displayInCell: true, // handle the height of the cell
                textChanged: (value, isValid) => {
                    cellObj.textChanged && cellObj.textChanged(sectionIdx, rowIdx, table, value, isValid);
                },
                onBlur: (value, valid) => {
                    cellObj.onBlur && cellObj.onBlur(value, valid);
                }
            }

            if (cellObj.content.biometricSecretType) {
                textViewProps.biometricSecretTyp = cellObj.content.biometricSecretType;
            }

            baseProps.mainCenterContent = {
                comp: LxReactTextView,
                props: textViewProps
            }

            return {
                props: baseProps
            }
        },

        [GUI.TableViewV2.CellType.PASS_INPUT]: ({cellObj, sectionIdx, rowIdx, cell, table, reloadFn}) => {
            cellObj.content.alignment = "left";

            let inputProps,
                strengthMap = [
                    {
                        color: globalStyles.colors.red,
                        text: _('change-password.week')
                    },
                    {
                        color: globalStyles.colors.orange,
                        text: _('change-password.medium')
                    },
                    {
                        color: globalStyles.colors.green,
                        text: _('change-password.strong')
                    },
                    {
                        color: globalStyles.colors.green,
                        text: _('change-password.super-strong')
                    }
                ];

            inputProps = window.LxCellReactMap[GUI.TableViewV2.CellType.INPUT]({
                cellObj,
                sectionIdx,
                rowIdx,
                cell,
                table
            }).props;


            if (cellObj.content.hasOwnProperty("newPwScore")) {
                cellObj.content.strengthProps = strengthMap[cellObj.content.newPwScore];
                inputProps.bottomContent = [
                    {
                        comp: LxReactText,
                        props: {
                            children: _('change-password.strength'),
                            style: {
                                color: globalStyles.colors.text.secondary
                            }
                        }
                    }
                ];

                inputProps.bottomContent.push({
                    comp: LxReactText,
                    props: {
                        style: {
                            color: cellObj.content.strengthProps ? cellObj.content.strengthProps.color : null,
                            flex: 1,
                            marginLeft: globalStyles.spacings.gaps.smallest
                        },
                        children: cellObj.content.strengthProps ? cellObj.content.strengthProps.text : ""
                    }
                })

                inputProps.bottomStyle = {
                    marginTop: 13,
                    marginBottom: 3,
                    flexDirection: "row",
                }

            }

            return {
                props: inputProps
            }
        },

        [GUI.TableViewV2.CellType.SEARCH]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            let inputProps = window.LxCellReactMap[GUI.TableViewV2.CellType.INPUT]({
                cellObj,
                sectionIdx,
                rowIdx,
                cell,
                table
            }).props;

            inputProps.mainCenterContent.props.leftIcon = Icons.Search;
            inputProps.mainCenterContent.props.alignment = "right";

            return {
                props: inputProps
            }
        },

        [GUI.TableViewV2.CellType.DATE_PICKER]: ({cellObj, sectionIdx, rowIdx, cell, table, reloadFn}) => {
            let cellProps = mapBasicProps(cellObj);

            cellProps.mainRightContent = {
                comp: LxReactDateTimePicker,
                props: {
                    title: cellObj.content.title,
                    value: cellObj.content.value,
                    type: LxReactDateTimePicker.Types.Date,
                    onChange: value => {
                        cellObj.onPickerChanged && cellObj.onPickerChanged(sectionIdx, rowIdx, table, value)
                    },
                    marked: cellObj.content.marked,
                    hideTimeAndYear: cellObj.content.hideTimeAndYear
                }
            }

            if (cellObj.content.minDate) {
                cellProps.mainRightContent.props.min = moment(cellObj.content.minDate);
            }

            if (cellObj.content.maxDate) {
                cellProps.mainRightContent.props.max = moment(cellObj.content.maxDate);
            }

            return {
                props: cellProps
            }
        },

        [GUI.TableViewV2.CellType.TIME_PICKER]: ({cellObj, sectionIdx, rowIdx, cell, table, reloadFn}) => {
            let cellProps = mapBasicProps(cellObj),
                mockDate = new Date(),
                [hours = 0, minutes = 0, seconds = 0] = cellObj.content.value;

            mockDate.setHours(hours);
            mockDate.setMinutes(minutes);
            mockDate.setSeconds(seconds);
            mockDate.setMilliseconds(0);

            cellProps.mainRightContent = {
                comp: LxReactDateTimePicker,
                props: {
                    title: cellObj.content.title,
                    value: moment(mockDate),
                    type: LxReactDateTimePicker.Types.Time,
                    onChange: value => {
                        cellObj.onPickerChanged && cellObj.onPickerChanged(sectionIdx, rowIdx, table, value);
                    }
                }
            }

            return {
                props: cellProps
            }
        },

        [GUI.TableViewV2.CellType.DATE_TIME_PICKER]: ({cellObj, sectionIdx, rowIdx, cell, table, reloadFn}) => {
            let cellProps = mapBasicProps(cellObj),
                actualType = LxReactDateTimePicker.Types.DateTime,
                previewFormat;

            if (cellObj.content.hideTimeAndYear) {
                actualType = LxReactDateTimePicker.Types.Date;
                previewFormat = DateType.DateTextNoYear;

                if (!cellObj.content.minDate) {
                    cellObj.content.minDate = moment().startOf('year');
                }
                if (!cellObj.content.maxDate) {
                    cellObj.content.maxDate = moment().endOf('year');
                }
            }
            const valueStyle = cellObj.content.disclosureColor ? {
                color: cellObj.content.disclosureColor
            } : {};

            cellProps.mainRightContent = {
                comp: LxReactDateTimePicker,
                props: {
                    title: cellObj.content.title,
                    value: cellObj.content.value,
                    valueStyle,
                    type: actualType,
                    onChange: value => {
                        cellObj.onPickerChanged && cellObj.onPickerChanged(sectionIdx, rowIdx, table, value)
                    },
                    previewFormat: previewFormat,
                    min: cellObj.content.minDate,
                    max: cellObj.content.maxDate,
                    marked: cellObj.content.marked,
                    hideTimeAndYear: cellObj.content.hideTimeAndYear
                }
            };

            if (cellObj.content.minDate) {
                cellProps.mainRightContent.props.min = moment(cellObj.content.minDate);
            }

            if (cellObj.content.maxDate) {
                cellProps.mainRightContent.props.max = moment(cellObj.content.maxDate);
            }

            return {
                props: cellProps
            }
        },

        [GUI.TableViewV2.CellType.DURATION_PICKER]: ({cellObj, sectionIdx, rowIdx, cell, table, reloadFn}) => {
            let cellProps = mapBasicProps(cellObj)

            cellProps.mainRightContent = {
                comp: LxReactDurationPicker,
                props: {
                    title: cellObj.content.title,
                    value: cellObj.content.value,
                    onChange: value => {
                        cellObj.onPickerChanged && cellObj.onPickerChanged(sectionIdx, rowIdx, table, value);
                    }
                }
            }

            return {
                props: cellProps
            }
        },

        [window.GUI.TableViewV2.CellType.Special.MENU_SYSTEM_STATE_CELL]: ({
                                                                               cellObj,
                                                                               sectionIdx,
                                                                               rowIdx,
                                                                               cell,
                                                                               table
                                                                           }) => {
            let cellProps = mapBasicProps(cellObj);

            cellProps.mainRightContent = {
                comp: LxReactSystemStatusBadge,
                props: {
                    sourceUuids: cellObj.content.sourceUuids,
                }
            }

            return {
                props: cellProps
            }
        },
        [window.GUI.TableViewV2.CellType.EDITABLE_CELL]: ({cellObj, sectionIdx, rowIdx}) => {
            let cellProps = mapBasicProps(cellObj);

            return {
                props: cellProps
            }
        },
        ["GroupCard"]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            cellObj.content.title = cellObj.content.title2;
            cellObj.content.leftIconSrc = cellObj.content.iconSrc;

            let cellProps = mapBasicProps(cellObj);


            return {
                props: cellProps
            }
        },
        ["ControlBaseCard"]: ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            return {
                comp: LxReactControl,
                props: cellObj.props
            }
        },
        ["CustomCell"]:  ({cellObj, sectionIdx, rowIdx, cell, table}) => {
            return {
                comp: cellObj.comp,
                props: cellObj.props
            }
        },

    }, {
        get(obj, prop, val) {
            if (prop in obj) {
                return obj[prop];
            } else {
                if (prop.includes("Card")) {

                    return ({cellObj, sectionIdx, rowIdx, cell, table}) => {
                        cellObj.content.title = cellObj.content.control.name;
                        cellObj.content.subtitle = cellObj.content.control.groupDetail;
                        cellObj.content.leftIconSrc = ActiveMSComponent.getStructureManager().getControlByUUID(cellObj.content.control.uuidAction).getIcon();

                        return {
                            props: mapBasicProps(cellObj)
                        }
                    }
                } else {
                    return ({cellObj, sectionIdx, rowIdx, cell, table}) => {
                        return {
                            props: mapBasicProps(cellObj)
                        }
                    }
                }
            }
        }
    })
});

function mapCheckableBasicProps({cellObj, sectionIdx, rowIdx, cell, table, reload, reloadFn}) {
    let basicProps = mapBasicProps(cellObj),
        adoptedProps = {
            checked: !!cellObj.content.selected,
            checkedIconStyle: cellObj.content.checkedIconStyle,
            uncheckedIconStyle: cellObj.content.uncheckedIconStyle,
            radioMode: cellObj.content.radioMode || LxReactFlexibleCell.RadioMode.Inactive,
            onCheckedToggled: checked => {
                let prms;
                if (cellObj.didRequestCheckingCell) {
                    prms = Q.resolve(cellObj.didRequestCheckingCell(cell, sectionIdx, rowIdx, table));
                } else {
                    prms = Q.resolve(true);
                }
                return prms;
            },
            onCheckedChange: (checked, dueToRadioMode = false) => {
                cellObj.content.selected = checked;
                reloadFn(sectionIdx, rowIdx, cellObj.content);
                if (!dueToRadioMode && cellObj.didCheckCell) {
                    cellObj.didCheckCell(cell, sectionIdx, rowIdx, table, checked);
                }
            }
        };
    return Object.assign(basicProps, adoptedProps);
}

function mapBasicProps(props, wip) {
    if (!props.content) {
        //console.warn("mapBasicProps", "props provided do not contain a content, return as is!");
        return props;
    }
    let mainRightContent = [],
        mainLeftContent = props.content.mainLeftContent;

    if (props.content.disclosureText) {
        let disclosureTextProps = props.content.disclosureTextProps || {};
        mainRightContent.push({
            comp: LxReactText,
            props: {
                style: {
                    color: props.content.disclosureColor || globalStyles.colors.text.secondary,
                    fontSize: globalStyles.fontSettings.sizes.medium,
                    textAlign: "right",
                    maxWidth: "100%",
                    fontFamily: globalStyles.fontSettings.families.regular,
                },
                children: props.content.disclosureText,
                copyable: props.content.copyable,
                copyableOnPress: props.content.copyable && !props.action,
                ...disclosureTextProps
            }
        });
    }
    if (props.content.disclosureIcon) {
        mainRightContent.push({
            comp: Icons.Chevron,
            props: {
                fill: globalStyles.colors.text.secondary,
                height: globalStyles.sizes.icons.small,
                width: 12,
                style: {marginLeft: "12px"}
            }
        });
    }

    if (props.content.leftIconSrc && !props.content.reactLeftIconSrc) {
        mainLeftContent = getLeftIcon(props);
    }

    if (props.content.rightIconSrc && !props.content.reactRightIconSrc) {
        let rightIcon = getIcon(props.content.rightIconSrc, {
            height: props.content.rightIconSize || globalStyles.sizes.icons.small,
            width: props.content.rightIconSize || globalStyles.sizes.icons.small,
        }, {
            fill: props.content.rightIconColor,
            marginLeft: 5
        })
        mainRightContent.push(rightIcon)
    }

    if (props.content.reactLeftIconSrc) { // some cells are used in multiple screens, can't replace leftIconSrc with react icons yet.
        mainLeftContent = {
            comp: props.content.reactLeftIconSrc,
            props: {
                fill: props.content.leftIconColor || globalStyles.colors.white,
                height: globalStyles.sizes.icons.small,
                width: globalStyles.sizes.icons.small
            }
        };
    }

    return {
        isLoading: !!props.content.isLoading,
        title: wip ? props.content.title.debugify() : props.content.title,
        titleStyle: {...props.content.titleStyle, ...(props.content.titleColor ? {color: props.content.titleColor} : null)},
        subTitle: props.content.subtitle,
        subTitleStyle: {...props.content.subTitleStyle, ...{color: props.content.subtitleColor || globalStyles.colors.text.secondary}},
        onPress: props.action,
        mainRightContent: props.content.mainRightContent || mainRightContent,
        mainLeftStyle: props.content.mainLeftStyle || null,
        mainRightStyle: props.content.mainRightStyle || null,
        mainCenterContent: props.content.mainCenterContent || null,
        mainCenterStyle: props.content.mainCenterStyle || null,
        mainLeftContent: props.content.mainLeftContent || mainLeftContent,
        disabled: props.content.disabled,
        leftSwipeButtons: props.content.leftSwipeButtons,
        rightSwipeButtons: props.content.rightSwipeButtons,
        cellElementStyle: props.content.cellElementStyle,
        bottomContent: props.content.bottomContent || null,
        topContent: props.content.topContent || null,
        topStyle: props.content.topStyle || null,
        refs: (props.content.rightSwipeButtons || props.content.leftSwipeButtons) ? [] : null
    }
}


let getLeftIcon = (props) => {
    let leftIcon;

    if (typeof props.content.leftIconSrc === "function" && props.content.leftIconSrc.isReactIcon) {
        leftIcon = {
            comp: props.content.leftIconSrc,
            props: {
                fill: props.content.leftIconColor || globalStyles.colors.white,
                height: globalStyles.sizes.icons.small,
                width: globalStyles.sizes.icons.small
            }
        };
    } else if (typeof props.content.leftIconSrc === "string") {
        leftIcon = {
            comp: LxReactImageView,
            props: {
                source: props.content.leftIconSrc,
                containerStyle: {
                    fill: props.content.leftIconColor || globalStyles.colors.white,
                    height: props.content.leftIconSize || globalStyles.sizes.icons.small,
                    width: props.content.leftIconSize || globalStyles.sizes.icons.small
                },
                imageStyle: {
                    height: props.content.leftIconSize || globalStyles.sizes.icons.small,
                    width: props.content.leftIconSize || globalStyles.sizes.icons.small
                }

            }
        };
    } else {
        leftIcon = {
            comp: Icons.Default,
            props: {
                fill: globalStyles.colors.red,
                height: globalStyles.sizes.icons.small,
                width: globalStyles.sizes.icons.small
            }
        };
    }

    return leftIcon;
}

let getIcon = (source, imageStyle, containerStyle) => {
    let icon;

    if (typeof source === "string") {
        icon = {
            comp: LxReactImageView,
            props: {
                imageStyle,
                containerStyle,
                source
            }
        };
    } else if (typeof source === "function" || typeof source === "object") {
        icon = {
            comp: source,
            props: {
                ...imageStyle,
                ...containerStyle
            }
        };
    } else {
        icon = {
            comp: Icons.Sun,
            props: {
                ...imageStyle,
                ...containerStyle
            }
        };
    }

    return icon;
}

const isAffectedByMessageCenter = (cellObj) => {
    if (
        cellObj.states.messageCenterEntries &&
        cellObj.states.messageCenterEntries.length &&
        cellObj.states.messageCenterEntries.some(
            (entry) =>
                entry.severity >
                MessageCenterHelper.SEVERITY_LEVEL.INFO,
        )
    ) {
        return true;
    }
    return false;
}

const replaceWithMessageCenterInfo = (cellObj) => {
    cellObj.content.rightIconColor = MessageCenterHelper.getColorForSeverityEntry(cellObj.states.messageCenterEntries[0], true);
    cellObj.content.rightIconSrc = MessageCenterHelper.getIconForSeverityEntry(cellObj.states.messageCenterEntries[0]);
}