import StorageHandlerBase from "../../../shared/logic/storage/StorageHandlerBase";


class PersistentStorageHandler extends StorageHandlerBase {
    constructor() {
        super(arguments);
    }

    saveFile(fileName, data, dataType) {
        return PersistenceComponent.saveFile(fileName, data, dataType);
    }

    getFile(fileName, dataType) {
        return PersistenceComponent.loadFile(fileName, dataType);
    }

    deleteFile(fileName) {
        return PersistenceComponent.deleteFile(fileName);
    }
}

export default PersistentStorageHandler;