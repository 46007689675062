import {ControlContext, LxBaseControlContent, CCLocation} from "LxComponents";
import {useCallback, useContext, useRef} from "react";
import {Status} from "../aalEmergencyControlEnums";
import LxAlarmTrackerView from "../../alarmControlBase/content/LxAlarmTrackerView";
import {useFocusEffect, useNavigation} from "@react-navigation/native";

function AalEmergencyControlContent (props) {
    const {control, states} = useContext(ControlContext)
    const trackerUUID = Object.keys(control.subControls)[0];
    const trackerControl = control.subControls[trackerUUID];
    const navigation = useNavigation();
    const dismissTimeoutRef = useRef();

    // This content should be dismissed automatically after a given timeout (when dismissed after being triggered)
    useFocusEffect(useCallback(() => {
        if (states.status !== Status.TRIGGERED && !dismissTimeoutRef.current) {
            dismissTimeoutRef.current = setTimeout(() => navigation.goBack(), 60 * 1000)
        } else if (dismissTimeoutRef.current) {
            clearTimeout(dismissTimeoutRef.current)
        }

        return () => {
            if (dismissTimeoutRef.current) {
                clearTimeout(dismissTimeoutRef.current)
            }
        }
    }, [states]))



    return <LxBaseControlContent showStatesFirst={isTriggered}>
        {isTriggered(control, states) && <LxAlarmTrackerView trackerControl={trackerControl} location={CCLocation.START}/>}
    </LxBaseControlContent>
}

const isTriggered = (control, states) => states.status === Status.TRIGGERED;

export default AalEmergencyControlContent