import {useEffect, useState} from "react";
import useAmbientWallpaperOptions from "./useAmbientWallpaperOptions"
import defaultBG from "../../../../resources/Images/EcoScreen/wallpaper.png";
import useAmbientSettings from "./useAmbientSettings";


export default function useAmbientWallpaper() {
    const settings = useAmbientSettings();
    const [backgroundImageObj, setBackgroundImageObj] = useState(settings ? settings.imageObject : null);
    const options = useAmbientWallpaperOptions();

    const updateImageInfo = () => {
        let image = settings ? settings.imageObject : null;
        setBackgroundImageObj(image || (options ? options[0] : null));
    }

    useEffect(() => {
        updateImageInfo();
    }, [settings ? settings.imageObject : null]);

    return backgroundImageObj || defaultImageObj
}

const defaultImageObj = {
    source: {
        uri: defaultBG,
        sizing: {
            scale: 1,
            translateX: 0,
            translateY: 0
        }
    }
}
