'use strict';

define(["AudioZoneControlEnums", "AudioZoneDetailedContentBase"], function (AudioZoneControlEnums, AudioZoneDetailedContentBase) {
    class MediaBrowserBase extends AudioZoneDetailedContentBase {
        //region Static
        static getCellFromContentTypeItem(item, details, contentType, isPlaylist, isBrowsable, actionClb, buttonTappedClb, vc, disabled) {
            var texts = MediaServerComp.getTitleSubtitleForItem(item),
                iconObj = this.getIconObjForItem(item, details.identifier),
                btnSrc = this.getCellButtonIconSrc(item, contentType, vc);

            if (isPlaylist && MediaServerComp.Feature.V2_FIRMWARE) {
                return {
                    type: GUI.TableViewV2.CellType.Special.MEDIA_COVER,
                    content: {
                        clickable: true,
                        title: decodeURIComponent(texts.title),
                        subTitle: decodeURIComponent(texts.subtitle),
                        iconSrc: iconObj.highRes,
                        item: item,
                        username: details.username,
                        details: cloneObjectDeep(details),
                        disabled: disabled
                    },
                    action: actionClb ? actionClb.bind(this, item, contentType) : null
                };
            } else {
                return {
                    type: this.getCellTypeForItemCellAtIndex(item, contentType),
                    content: {
                        clickable: true,
                        title: decodeURIComponent(texts.title),
                        subtitle: decodeURIComponent(texts.subtitle),
                        leftIconSrc: iconObj.lowRes,
                        iconSrc: iconObj.lowRes,
                        iconColor: iconObj.color,
                        leftIconColor: iconObj.color,
                        leftIconBgEnabled: !iconObj.hasProvidedCover,
                        buttonSrc: btnSrc,
                        disclosureIcon: false,
                        leftIconLarger: iconObj.hasProvidedCover,
                        class: isBrowsable ? "base-cell--with-chevron" : null,
                        disabled: disabled
                    },
                    action: function (cell, section, row, tableView) {
                        actionClb && actionClb(item, contentType, section, row);
                    },
                    buttonTapped: function (section, row, tableView) {
                        if (buttonTappedClb) {
                            buttonTappedClb(item, contentType, section, row);
                        } else if (actionClb) {
                            actionClb(item, contentType, section, row);
                        }
                    },
                    onViewWillAppear: function onViewWillAppear(cell, section, row, tableView) {
                        var imgElm;

                        if (cell instanceof GUI.TableViewV2.Cells.ButtonCell) {
                            imgElm = cell.getElement().find(".icon-placeholder__icon");
                        } else if (cell instanceof GUI.TableViewV2.Cells.GeneralCell) {
                            imgElm = cell.elements.leftIcon;
                        }

                        if (imgElm) {
                            imgElm.attr("contentType", contentType);
                        }
                    }
                };
            }
        }

        static getCellButtonIconSrc(item, contentType, vc) {
            if (vc instanceof GUI.AddMediaViewControllerBase) {
                return Icon.CIRCLED_ADD;
            } else {
                return Icon.Buttons.MORE_HORIZONTAL;
            }
        }

        static getCellTypeForItemCellAtIndex(item, contentType) {
            var isPlayable = MediaServerComp.isFileTypePlayable(item.type, contentType);
            return isPlayable ? GUI.TableViewV2.CellType.BUTTON : GUI.TableViewV2.CellType.GENERAL;
        }

        static getIconObjForItem(item, identifier) {
            var providedCover = item.thumbnail || item.coverurl,
                iconObj = {
                    lowRes: Icon.AudioZone.NEW.NOTE,
                    highRes: Icon.AudioZone.NEW.NOTE,
                    color: Color.TEXT_SECONDARY_B,
                    hasProvidedCover: !!providedCover
                },
                qParams; // Some items may fallback to the base call and are actually line in types which must be handled
            // like this to ensure we show the correct image eg. in the TinyPlayer or Favorite views.

            if (item.hasOwnProperty("coverurl")) {
                qParams = new URLSearchParams(item.coverurl);

                if (qParams.get("icontype")) {
                    item.icontype = qParams.get("icontype");
                    item.enabled = !!parseInt(qParams.get("enabled"));
                    return Controls.AudioZoneControl.MediaBrowserLineIn.getIconObjForItem(item, identifier);
                }
            }

            if (MediaServerComp.isFileTypeBrowsable(item.type)) {
                if (item.external) {
                    iconObj.highRes = Icon.AudioZone.NEW.PLAYLIST_NAS;
                    iconObj.lowRes = Icon.AudioZone.NEW.PLAYLIST_NAS;
                } else {
                    iconObj.highRes = Icon.AudioZone.NEW.FOLDER;
                    iconObj.lowRes = Icon.AudioZone.NEW.FOLDER;
                }
            }

            if (iconObj.hasProvidedCover) {
                iconObj.highRes = item.coverurl || item.thumbnail;
                iconObj.lowRes = item.thumbnail || item.coverurl;
            }

            if (item.hasOwnProperty("tag") && !iconObj.hasProvidedCover) {
                switch (item.tag) {
                    case MediaEnum.Tag.ARTIST:
                        iconObj.highRes = Icon.AudioZone.NEW.MIC_2;
                        iconObj.lowRes = Icon.AudioZone.NEW.MIC_2;
                        break;

                    case MediaEnum.Tag.ALBUM:
                        iconObj.highRes = Icon.AudioZone.NEW.CD;
                        iconObj.lowRes = Icon.AudioZone.NEW.CD;
                        break;

                    case MediaEnum.Tag.PLAYLIST:
                        iconObj.highRes = Icon.AudioZone.NEW.PLAYLIST;
                        iconObj.lowRes = Icon.AudioZone.NEW.PLAYLIST;
                        break;

                    case MediaEnum.Tag.GENRE:
                        iconObj.highRes = Icon.AudioZone.NEW.GENRE;
                        iconObj.lowRes = Icon.AudioZone.NEW.GENRE;
                        break;
                }
            }

            return iconObj;
        }

        static applyContentTypeToItem(item, contentType) {
            if (!item.hasOwnProperty("contentType")) {
                if (item.hasOwnProperty("type")) {
                    switch (item.type) {
                        case MediaEnum.FileType.LOCAL_DIR:
                        case MediaEnum.FileType.LOCAL_FILE:
                        case MediaEnum.FileType.LOCAL_PLAYLIST:
                            contentType = MediaEnum.MediaContentType.LIBRARY;
                            break;

                        case MediaEnum.FileType.ROOM_FAVORITE:
                            contentType = MediaEnum.MediaContentType.ZONE_FAVORITES;
                            break;

                        case MediaEnum.FileType.FAVORITE:
                            contentType = MediaEnum.MediaContentType.FAVORITES;
                            break;

                        case MediaEnum.FileType.HW_INPUT:
                            contentType = MediaEnum.MediaContentType.LINEIN;
                            break;

                        case MediaEnum.FileType.PLAYLIST_BROWSABLE:
                        case MediaEnum.FileType.PLAYLIST_EDITABLE:
                        case MediaEnum.FileType.FOLLOWED_PLAYLIST:
                            contentType = MediaEnum.MediaContentType.SERVICE;
                            break;
                    }
                }
            }

            if (contentType) {
                item.contentType = contentType;
            }
        }

        static getScreenStateForItem(item) {
            var baseId = AudioZoneControlEnums.ScreenState.MEDIA_BROWSER_BASE_SCREEN,
                ctor,
                ctorTypeSpecific;

            if (!item) {
                console.warn(this.name, "No item provided, falling back to base -> " + baseId);
                return baseId;
            }

            if (item.contentType === MediaEnum.MediaContentType.ZONE_FAVORITES || item.contentType === MediaEnum.MediaContentType.FAVORITES) {
                ctor = baseId.split(".").pop().replace("Base", "") + MediaEnum.MediaContentType.FAVORITES;
            } else {
                ctor = baseId.split(".").pop().replace("Base", "") + item.contentType;
            }

            ctorTypeSpecific = ctor + item.type;

            if (Controls.AudioZoneControl.hasOwnProperty(ctorTypeSpecific)) {
                return "Controls.AudioZoneControl." + ctorTypeSpecific;
            } else if (Controls.AudioZoneControl.hasOwnProperty(ctor)) {
                console.info(this.name, "Type specific Ctor '" + ctorTypeSpecific + "' not found, using common -> " + ctor);
                return "Controls.AudioZoneControl." + ctor;
            } else {
                console.info(this.name, "Ctor '" + ctor + "' and '" + ctorTypeSpecific + "' not found, falling back to base -> " + baseId);
                return baseId;
            }
        }

        static getConstructorForItem(item) {
            var screenState = this.getScreenStateForItem(item),
                stateParts = screenState.split("."),
                ctor = window;

            try {
                stateParts.forEach(function (part) {
                    ctor = ctor[part];
                });
            } catch (e) {
                ctor = this;
            }

            return ctor;
        }

        static Template = function template() {
            var getHeaderElement = function getTemplate(templateData) {
                return $('<div class="header-section-container">' + '   <div class="header-section-container__main-container">' + '       <div class="main-container__information-container">' + '           <div class="information-container__left-icon">' + '           ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.LIBRARY_ICON, "cover__icon") + '           </div>' + '           <div class="information-container__playlist-information">' + '               <div class="playlist-information__title">' + templateData.title + '</div>' + '               <div class="playlist-information__sub-title">' + templateData.subTitle + '</div>' + '               <div class="playlist-information__icon"></div>' + '           </div>' + '       </div>' + '   </div>' + '   <div class="header-section-container__action-container">' + '       <div class="action-container__button action-container__button--play clickable">' + '           ' + ImageBox.getResourceImageWithClasses(templateData.leftButtonIcon, "button__icon") + '           <div class="button-text">' + templateData.leftButtonText + '</div>' + '       </div>' + '       <div class="action-container__button action-container__button--shuffle clickable">' + '           ' + ImageBox.getResourceImageWithClasses(templateData.rightButtonIcon, "button__icon") + '           <div class="button-text">' + templateData.rightButtonText + '</div>' + '       </div>' + '   </div>' + '</div>');
            };

            return {
                getHeaderElement: getHeaderElement
            };
        }(); //endregion Static

        //region Private
        //fast-class-es6-converter: extracted from defineStatic({}) content
        LOADING_SECTION_CLASS = "lx-table-view__section--loading";
        USE_REUSING = false; //endregion Private

        //region Getter
        get desc() {
            return this.lastSelectedItem.name;
        } //endregion Getter


        constructor(details) {
            super(...arguments);
            Object.assign(this, MediaStateHandler.Mixin);
            this.mediaType = details.mediaType;
            this.contentTypes = details.contentTypes;
            this.identifier = details.identifier;
            this.username = details.username || MediaEnum.NOUSER;
            this.lastSelectedItem = details.lastSelectedItem;
            this.control = MediaServerComp.getActiveZoneControl();
            this.contentTypeItems = {}; // Its also possible to load data directly from an URL (Used for globalSearch)
            // It is preferred over "this.contentType"

            this.srcCmd = details.srcCmd;
            this.contentTypes.forEach(function (contentType) {
                this.contentTypeItems[contentType] = {
                    all: [],
                    browsable: [],
                    playable: [],
                    isFinished: false
                }; // binds the dynamic function (naming is depending on "this.contentType") to a statically named function for a more generic use of this base

                if (!this.srcCmd) {
                    this[this.__getFunctionNameForEventTypeWithTemplate(contentType, this.DYNAMIC_FUNCTION_TPL.ALL_DATA_RECEIVED)] = function (data) {
                        return this.onAllContentTypeData(data, contentType);
                    }.bind(this);

                    this[this.__getFunctionNameForEventTypeWithTemplate(contentType, this.DYNAMIC_FUNCTION_TPL.CHUNKED_DATA_DATA)] = function (data) {
                        return this.onContentTypeDataChunk(data, contentType);
                    }.bind(this);

                    this[this.__getFunctionNameForEventTypeWithTemplate(contentType, this.DYNAMIC_FUNCTION_TPL.RELOAD)] = function (reason) {
                        return this.onContentTypeReload(reason, contentType);
                    }.bind(this);

                    this[this.__getFunctionNameForEventTypeWithTemplate(contentType, this.DYNAMIC_FUNCTION_TPL.ERROR)] = function (reason) {
                        return this.onContentTypeError(contentType);
                    }.bind(this);
                }
            }.bind(this));
            this.hasError = false;
            this.receivedContent = false;
            this._viewShouldRemainVisible = true;
            this.buttons = {};
        }

        setViewController() {
            super.setViewController(...arguments);
            this.ViewController.addMultipleView("Controls.AudioZoneControl." + this.name);
            this.ViewController.getElement().attr("contentIdentifier", this.identifier);
        }

        viewDidLoad() {
            if (this.details.isFromSearch) {
                delete this.legacySearchIdentifier;
            }

            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                if (!this.srcCmd) {
                    this._registerForMediaServerEvents.apply(this, this.contentTypes);
                } else if (this.srcCmd) {
                    this.loadItemsFromMusicServer(this.srcCmd);
                }
            }.bind(this));
        }

        titleBarActionRight(buttonId) {
            if (buttonId === this.RIGHT_SIDE_BUTTON.CONTEXT_MENU) {
                // The first mediaBrowserBase screen in the viewStack ([0] == "GUI.Screen" <- DummyScreen, [1] == this)
                // contains the basic "initialization" properties like contentType and no real items
                // Thus we need to show the context menu for the screen itself because we don't have any real lastSelectedItem
                if (this.ViewController.history.stack.length > 2) {
                    this._showContextMenuForItem(this.lastSelectedItem);
                } else {
                    this._showContextMenuForItem(this);
                }
            } else {
                super.titleBarActionRight(...arguments);
            }
        }

        getURL() {
            var baseUrl = super.getURL(...arguments);

            if (Debug.Control.AudioZone.MediaBrowserBase) {
                return baseUrl + "_{" + this.contentTypes.map(this.getMediaId.bind(this)).join("|") + "}";
            } else {
                return baseUrl;
            }
        }

        loadItemsFromMusicServer(cmd) {
            var data,
                startIdx,
                cmdDetails,
                fullCmd,
                contentType = this.contentTypes[0];
            return MediaServerComp.sendMediaServerCommand(cmd).then(function (res) {
                // Break the loop if we don't have a name set
                // it will be nulled in the base destroy function, it is an indicator that this view has been destroyed
                if (this.name) {
                    data = res.data[0];
                    if (!data.items && data.results && data.results.length > 0) {
                        data = data.results[0]; // fixes bug in spotify searches "more"
                    }

                    data.totalitems = Math.min(data.totalitems, 1000); // limit to 1000, the new Musicserver software does this automatically, the ond one must be limited on the client side

                    startIdx = data.hasOwnProperty('start') ? data.start : data.offset;
                    this.onContentTypeDataChunk(data, contentType);
                    cmdDetails = cmd.split('/');
                    cmdDetails[cmdDetails.length - 2] = startIdx + this.__getRequestPacketSizeForEventType(this.contentTypes[0]);
                    fullCmd = cmdDetails.join('/');

                    if (startIdx < data.totalitems) {
                        this.loadItemsFromMusicServer(fullCmd);
                    } else {
                        this.onAllContentTypeData(this.contentTypeItems[contentType], contentType);
                    }
                }

                return Q(true);
            }.bind(this));
        }

        onAllContentTypeData(data, contentType) {
            console.info(this.name, "(id = '" + this.getMediaId(contentType) + "') Received all data for contentType: " + contentType);
            var tableViewElement = this.tableView.getElement(); // Due to the asynchronous nature of the Tableview we may not have the TableViews element available
            // this happens if the content finishes while we are already reloading the TableView

            if (tableViewElement) {
                // Remove the loading class from the tableView
                var finishedSections = tableViewElement.find("." + contentType),
                    sectionIdx;
                finishedSections.each(function (finishedSection, elm) {
                    sectionIdx = parseInt(elm.id.replace("section-", ""));
                    elm = $(elm);
                    elm.removeClass(this.LOADING_SECTION_CLASS);
                    this.tableContent[sectionIdx].customClass = this.tableContent[sectionIdx].customClass.replace(this.LOADING_SECTION_CLASS, "");
                }.bind(this));
            }
        }

        onContentTypeDataChunk(data, contentType) {
            console.info(this.name, "(id = '" + this.getMediaId(contentType) + "') Got data chunk for contentType: " + contentType);

            if (this.contentTypeItems[contentType].isFinished) {
                console.info(this.name, "(id = '" + this.getMediaId(contentType) + "') Ignoring contentType ('" + contentType + "') data chunk, contentType items have not been reset!");
                return Q(true);
            }

            var prepareObj = this.processContentTypeDataChunk(data, contentType);
            this.contentTypeItems[contentType].isFinished = data.isFinished;
            this.hasError = false;
            this.receivedContent = true;
            this.updateEmptyViewConfig();
            return this.reloadForChunkedDataOfContentType(contentType, prepareObj);
        }

        processContentTypeDataChunk(data, contentType) {
            var __tmpThisContentTypeItems = cloneObject(this.contentTypeItems[contentType]); // Empty the sorted items arrays, we need to reassign them


            this.contentTypeItems[contentType].browsable = [];
            this.contentTypeItems[contentType].playable = [];
            data.items.forEach(function (item) {
                item.contentType = contentType;
            });
            this.contentTypeItems[contentType].all = this.contentTypeItems[contentType].all.concat(data.items);
            this.contentTypeItems[contentType].all.forEach(function (item) {
                if (MediaServerComp.isFileTypeBrowsable(item.type)) {
                    this.contentTypeItems[contentType].browsable.push(item);
                } else {
                    this.contentTypeItems[contentType].playable.push(item);
                }
            }.bind(this));
            return {
                newBrowsableCnt: this.contentTypeItems[contentType].browsable.length - __tmpThisContentTypeItems.browsable.length,
                newPlayableCnt: this.contentTypeItems[contentType].playable.length - __tmpThisContentTypeItems.playable.length
            };
        }

        onContentTypeReload(reason, contentType) {
            console.info(this.name, "(id = '" + this.getMediaId(contentType) + "') Got reload event ('" + reason + "') for contentType: " + contentType); // Just reset the items for the given contentType, it will automatically be re-fetched

            this._resetContentTypeItems(contentType);

            this.hasError = false;
            this.receivedContent = false;
            this.updateEmptyViewConfig(); // Reload the whole table to clear the items, a re-fetch is automatically done

            return this.processWhenVisible(this.reloadTable.bind(this));
        }

        onContentTypeError(contentType) {
            // Just reset the items for the given contentType, it will automatically be re-fetched
            this._resetContentTypeItems(contentType);

            this.hasError = true;
            this.updateEmptyViewConfig(); // Reload the whole table to clear the items

            return this.processWhenVisible(this.reloadTable.bind(this));
        }

        getTableView() {
            return new GUI.CardView(this.tableViewDataSource, this.tableViewDelegate);
        }

        emptyViewConfig() {
            return {
                iconSrc: this.getEmptyViewIconSrc(),
                title: this.getEmptyViewTitle(),
                message: this.getEmptyViewMessage(),
                buttonText: this.getEmptyViewButtonText(),
                buttonAction: this.emptyViewButtonAction.bind(this),
                noCircle: true
            };
        }

        getEmptyViewIconSrc() {
            if (this.hasError) {
                return Icon.ERROR;
            } else {
                return this.receivedContent ? Icon.ERROR : Icon.INDICATOR;
            }
        }

        getEmptyViewTitle() {
            if (this.hasError) {
                return _("error");
            } else {
                return this.receivedContent ? _('controls.tracker.no-entries') : _("loading");
            }
        }

        getEmptyViewMessage() {
            if (this.hasError) {
                return _("error.occured");
            } else {
                return null;
            }
        }

        getEmptyViewButtonText() {
            if (this.hasError) {
                return _("try-again");
            }

            return null;
        }

        emptyViewButtonAction() {
            if (this.hasError) {
                this._requestEventContent.apply(this, this.contentTypes);
            } // Do nothing...

        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarText() {
            var title = _("details");

            if (this.lastSelectedItem && this.lastSelectedItem.name) {
                title = this.lastSelectedItem.name;
            }

            return decodeURIComponent(title);
        }

        closeAction() {
            this._viewShouldRemainVisible = false;
            return super.closeAction(...arguments);
        }

        destroy() {
            if (!this.srcCmd) {
                this._unregisterForMediaServerEvents.apply(this, this.contentTypes);
            }

            return super.destroy(...arguments);
        }

        reloadRowsSelective(section, startIdx, nCells) {
            console.info(this.name, "Reloading '" + nCells + "' rows in section '" + section + "' from index '" + startIdx + "'");
            this.setTableContent();
            return super.reloadRowsSelective(...arguments).then(function () {
                if (this.buttons.headerPlay && this.buttons.headerShuffle) {
                    this.addToHandledSubviews(this.buttons.headerPlay);
                    this.addToHandledSubviews(this.buttons.headerShuffle);
                    this.buttons.headerPlay.on(GUI.LxRippleButton.CLICK_EVENT, function playUnshuffled() {
                        // BG-I22234 according to andre, the shuffle off must be sent prior if shuffle shouldn't be used.
                        MediaServerComp.sendAudioZoneCommand(this.control.details.playerid, {
                            cmd: MediaEnum.AudioCommands.SHUFFLE.OFF
                        }).done(function () {
                            this._playItem(this.lastSelectedItem);
                        }.bind(this))
                    }.bind(this));
                    this.buttons.headerShuffle.on(GUI.LxRippleButton.CLICK_EVENT, function playShuffled() {
                        MediaServerComp.sendAudioZoneCommand(this.control.details.playerid, {
                            cmd: MediaEnum.AudioCommands.SHUFFLE.ON
                        }).done(function () {
                            this._playItem(this.lastSelectedItem);
                        }.bind(this));
                    }.bind(this));
                }
            }.bind(this));
        }

        getTableContent() {
            var tableContent = [];
            this.contentTypes.forEach(function (contentType) {
                if (this.contentTypeItems[contentType].browsable.length) {
                    tableContent.push(this.getSectionForContentTypeAndItems(contentType, this.contentTypeItems[contentType].browsable, true));
                }

                if (this.contentTypeItems[contentType].playable.length) {
                    tableContent.push(this.getSectionForContentTypeAndItems(contentType, this.contentTypeItems[contentType].playable, false));
                }
            }.bind(this));
            return tableContent;
        }

        reloadForChunkedDataOfContentType(contentType, prepareObj) {
            var sectionOffset = this.getSectionIdxOffset() + this.contentTypes.indexOf(contentType),
                newBrowsableItemCnt = prepareObj.newBrowsableCnt,
                browsableItemsCnt = this.contentTypeItems[contentType].browsable.length - newBrowsableItemCnt,
                newPlayableItemCnt = prepareObj.newPlayableCnt,
                playableItemsCnt = this.contentTypeItems[contentType].playable.length - newPlayableItemCnt,
                reloadFunc = function (sectionOffset, newBrowsableItemCnt, newPlayableItemCnt, browsableItemCnt, playableItemCnt) {
                    var prms = [true]; // Check if we have a ViewController, due to the nature of promises we may not show the screen anymore!

                    if (this.ViewController) {
                        if (newBrowsableItemCnt && newPlayableItemCnt) {
                            prms.push(this.reloadRowsSelective(sectionOffset, browsableItemCnt, newBrowsableItemCnt));
                            prms.push(this.reloadRowsSelective(1 + sectionOffset, playableItemCnt, newPlayableItemCnt));
                        } else if (newBrowsableItemCnt) {
                            prms.push(this.reloadRowsSelective(sectionOffset, browsableItemCnt, newBrowsableItemCnt));
                        } else if (newPlayableItemCnt) {
                            prms.push(this.reloadRowsSelective(sectionOffset, playableItemCnt, newPlayableItemCnt));
                        }
                    }

                    return Q.all(prms);
                }.bind(this);

            if (!this._chunkedReloadPrms) {
                this._chunkedReloadPrms = reloadFunc(sectionOffset, newBrowsableItemCnt, newPlayableItemCnt, browsableItemsCnt, playableItemsCnt).then(function () {
                    this._chunkedReloadPrms = null;
                }.bind(this));
                return this._chunkedReloadPrms;
            } else {
                return this._chunkedReloadPrms.then(function () {
                    this._chunkedReloadPrms = reloadFunc(sectionOffset, newBrowsableItemCnt, newPlayableItemCnt, browsableItemsCnt, playableItemsCnt).then(function () {
                        this._chunkedReloadPrms = null;
                    }.bind(this));
                    return this._chunkedReloadPrms;
                }.bind(this));
            }
        }

        getSectionIdxOffset() {
            return 0;
        }

        getSectionForContentTypeAndItems(contentType, items, isBrowsable) {
            var customClass = contentType,
                isMixedContent = this.contentTypeItems[contentType].browsable.length && this.contentTypeItems[contentType].playable.length;

            if (!this.contentTypeItems[contentType].isFinished) {
                customClass += " " + this.LOADING_SECTION_CLASS;
            }

            return {
                headerElement: !isMixedContent ? this.getHeaderElementForItemWithItemsAndContentType(this.lastSelectedItem, items, contentType) : null,
                contentType: contentType,
                customClass: customClass,
                rows: items.map(function (item) {
                    return this.constructor.getCellFromContentTypeItem(item, this, contentType, MediaServerComp.isFileTypePlaylist(item.type) && this.ViewController instanceof GUI.AudioZoneControlDetailedViewController, MediaServerComp.isFileTypeBrowsable(item.type) && !(this.ViewController instanceof GUI.AddMediaViewControllerPlaylistPicker), this.handleOnItemCellClicked.bind(this), this.handleOnCellButtonClicked.bind(this), this.ViewController, typeof this.ViewController.details.blockedIds !== "undefined" && this.ViewController.details.blockedIds.indexOf(item.id) !== -1);
                }.bind(this))
            };
        }

        getHeaderElementForItemWithItemsAndContentType(item, items, contentType) {
            // Don't show a header if we add items
            if (this.ViewController instanceof GUI.AddMediaViewControllerBase) {
                return;
            }

            var isPlaylist = MediaServerComp.isFileTypePlayable(item.type, contentType) && item.type,
                imgObj = this.constructor.getIconObjForItem(item, this.identifier),
                texts = MediaServerComp.getTitleSubtitleForItem(item),
                headerElement = null;

            if (isPlaylist) {
                headerElement = MediaBrowserBase.Template.getHeaderElement({
                    title: decodeURIComponent(texts.title),
                    subTitle: decodeURIComponent(texts.subtitle),
                    leftButtonText: _('cmdtext.audiozone.play'),
                    leftButtonIcon: Icon.AudioZone.NEW.CONTROL.PLAY,
                    rightButtonText: _('media.play.shuffle'),
                    rightButtonIcon: Icon.AudioZone.SHUFFLE
                });
                this.buttons.headerPlay = new GUI.LxRippleButton(headerElement.find('.action-container__button--play'));
                this.buttons.headerShuffle = new GUI.LxRippleButton(headerElement.find('.action-container__button--shuffle'));
                headerElement.find('.information-container__left-icon').addClass(imgObj.rounded ? "information-container__left-icon--rounded" : "");
                ImageBox.getImageElement(imgObj.highRes, 'cover__icon').then(function (res) {
                    this.element && headerElement.find('.information-container__left-icon').toggleClass("information-container__left-icon--app-icon", !imgObj.hasProvidedCover);
                    this.element && headerElement.find('.cover__icon').html(res[0]);
                }.bind(this)); // We won't show the cover icon of the playlist for v1 Musicservers

                if (!MediaServerComp.Feature.V2_FIRMWARE) {
                    headerElement.find('.information-container__left-icon').hide();
                }
            }

            return headerElement;
        }

        setTableContent() {
            this.tableContent = this.getTableContent();
            return super.setTableContent(...arguments);
        }

        handleOnItemCellClicked(item, contentType, section, row) {
            if (MediaServerComp.isFileTypeBrowsable(item.type)) {
                var details = cloneObject(this.details);
                delete details.srcCmd; // Remove the srcCmd to prevent us from showing the exact same screen again

                details.lastSelectedItem = item;
                details.contentTypes = [contentType];
                return this.ViewController.showState(Controls.AudioZoneControl.MediaBrowserBase.getScreenStateForItem(item), null, details);
            } else if (this.ViewController instanceof GUI.AddMediaViewControllerBase) {
                this.handleOnCellButtonClicked.apply(this, arguments);
            } else {
                return this._playItem(item);
            }
        }

        handleOnCellButtonClicked(item, contentType, section, row) {
            if (this.ViewController instanceof GUI.AudioZoneControlDetailedViewController || this.ViewController instanceof GUI.AudioZoneControlContentViewController) {
                // GlobalSearch
                return this._showContextMenuForItem(item);
            } else if (this.ViewController instanceof GUI.AddMediaViewControllerBase) {
                var cell, ogIcon;

                if (typeof section === "number" && typeof row === "number") {
                    cell = this.tableContent[section].rows[row];
                    ogIcon = cell.content.buttonSrc;
                    cell.content.buttonSrc = Icon.INDICATOR_BOLD;
                    this.tableContent[section].rows[row] = cell;
                    super.reloadRowsSelective(section, row, 1);
                }

                return Q(this.ViewController.addItem(item, this.getMediaInfo(contentType))).finally(() => {
                    if (cell) {
                        cell.content.buttonSrc = ogIcon;
                        this.tableContent[section].rows[row] = cell;
                        return super.reloadRowsSelective(section, row, 1);
                    } else {
                        return Q(true);
                    }
                });
            }
        }

        getMediaInfo(controlType) {
            return {
                service: {
                    uid: this.identifier + "/" + this.username
                }
            };
        }

        getMediaId(controlType) {
            if (this.lastSelectedItem && this.lastSelectedItem.id) {
                return this.lastSelectedItem.id;
            } else {
                return 0;
            }
        }

        getActionsForCtxAndItem(ctx, item) {
            return [];
        }

        _getRightSideForTitleBar() {
            if (this.ViewController instanceof GUI.AudioZoneControlDetailedViewController || this.ViewController instanceof GUI.AudioZoneControlContentViewController) {
                return super._getRightSideForTitleBar(...arguments);
            } else {
                return null;
            }
        }

        _playItem(item) {
            if (this.details.isFromSearch && (this.lastSelectedItem.tag === "album" || this.lastSelectedItem.tag === "playlist" || this.lastSelectedItem.tag === "artist")) {
                return MediaServerComp.sendAudioZoneCommand(this.control.details.playerid, {
                    cmd: MediaServerComp.getPlayCommandForItem(item, MediaEnum.PlayType.REPLACE, MediaEnum.MediaContentType.SEARCH, this.getMediaInfo(item.contentType))
                });
            } else {
                return MediaServerComp.sendPlayerCommandFromType(item, this.contentTypeItems[item.contentType].all.indexOf(item), {
                    username: this.username,
                    serviceIdentifier: this.identifier,
                    mediaType: this.mediaType,
                    lastSelectedItem: this.lastSelectedItem,
                    service: this.getMediaInfo(item.controlType).service
                });
            }
        }

        /**
         * Resets all contentTypeItems for every given contentType as function arguments,
         * will reset all if no content types are given
         * @note Add contentTypes as individual arguments, not as an array!
         * @private
         */
        _resetContentTypeItems() {
            var contentTypes = Array.from(arguments);

            if (!contentTypes.length) {
                contentTypes = this.contentTypes;
            }

            contentTypes.forEach(function (contentType) {
                this.contentTypeItems[contentType].all = [];
                this.contentTypeItems[contentType].browsable = [];
                this.contentTypeItems[contentType].playable = [];
                this.contentTypeItems[contentType].isFinished = false;
            }.bind(this));
        }

    }

    Controls.AudioZoneControl.MediaBrowserBase = MediaBrowserBase;
    return Controls.AudioZoneControl.MediaBrowserBase;
});
