'use strict';

define(["ControlInfoView", "UniversalControlValueScreen"], function (ControlInfoView, TrackerView, UniversalControlValueScreen) {
    return class UniversalControlSendCommandInfoView extends ControlInfoView {
        //region Static
        static Template = function () {
            var getHistorySection = function getHistorySection() {
                return $('<div class="history-section">' + '<div class="history-section__title">History: </div>' + '<select class="history-section__select"></select>' + '</div>');
            };

            var getCmdSection = function getCmdSection() {
                return $('<div class="cmd-section">' + '<div class="cmd-section__send-button"><div class="send-button__title">Send</div></div>' + '</div>');
            };

            return {
                getHistorySection: getHistorySection,
                getCmdSection: getCmdSection
            };
        }(); //endregion Static

        constructor(control) {
            super(control);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.cmd = "";
            this.input = new GUI.LxInput({
                autoFocus: false,
                placeholder: "e.g: cmd/param1/param2",
                isDark: true
            }, this);
            this.initialOption = "<option disabled selected >--</option>";
            this.element.append("<div class='cmd-title'>Command</div>");
            this.element.append(UniversalControlSendCommandInfoView.Template.getHistorySection());
            this.element.append(UniversalControlSendCommandInfoView.Template.getCmdSection());
            this.cmdSelect = this.element.find(".history-section__select");
            this.cmdSelect.change(this._didChooseCommand.bind(this));
            this.sendButton = new GUI.LxButton(this, this.element.find(".cmd-section__send-button"));
            this.appendSubview(this.input, this.element.find(".cmd-section"));
            this.addToHandledSubviews(this.sendButton);
            this.sendButton.setEnabled(false);
            this.sendButton.onButtonTapped = this._send.bind(this);

            this._updateSelection();
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments); // This has to be in viewWillAppear, the select element must already be in the DOM!

            this._didChooseCommand();
        }

        viewWillDisappear() {
            // Remove any potentially already shown notification
            this.notification && this.notification.remove();
            super.viewWillDisappear(...arguments);
        }

        // --- LxInput Delegate --
        submitText(value, valid) {
            this.cmd = value;

            var cmdNotEmpty = this._cmdIsNotEmpty();

            this.sendButton.setEnabled(cmdNotEmpty); // Directly send the command if it is not empty

            if (cmdNotEmpty) {
                this._send();
            }
        }

        textChanged(value, valid, valueDidChange) {
            this.cmd = value;
            this.sendButton.setEnabled(this._cmdIsNotEmpty());
        }

        // --- LxInput Delegate --
        _send() {
            // Remove any potentially already shown notification
            this.notification && this.notification.remove();
            this.control.sendCommand(this.cmd).then(function (response) {
                this.notification = GUI.Notification.createGeneralNotification({
                    title: "Command sent >",
                    closeable: true,
                    clickable: true
                }, NotificationType.SUCCESS);
                this.notification.on(GUI.Notification.CLICK_EVENT, this._openValueScreen.bind(this, this.cmd, response));
            }.bind(this), function (response) {
                var code = getLxResponseCode(response),
                    message;

                switch (code) {
                    case ResponseCode.SECURED_CMD_FAILED:
                        message = "Secured command failed! " + code;
                        break;

                    case ResponseCode.FORBIDDEN:
                        message = "Not allowed to perform command! " + code;
                        break;

                    case ResponseCode.NOT_FOUND:
                        message = "Command not found! " + code;
                        break;

                    default:
                        message = "Couldn't send command: " + code;
                        break;
                }

                this.notification = GUI.Notification.createGeneralNotification({
                    title: "Error >",
                    subtitle: message,
                    closeable: true,
                    clickable: true
                }, NotificationType.ERROR);
                this.notification.on(GUI.Notification.CLICK_EVENT, this._openValueScreen.bind(this, this.cmd, response));
            }.bind(this));

            this._updateSelection();
        }

        /**
         * Empties the current select and repopulates it with the new commands
         * @private
         */
        _updateSelection() {
            var sentCommands = this.control.getSentCommands();
            this.cmdSelect.empty();

            if (sentCommands.length > 0) {
                // Set the last command as the current one, to directly be able to send it when relaunching the view
                if (!this._cmdIsNotEmpty()) {
                    this.cmd = sentCommands[sentCommands.length - 1];
                }

                for (var i = 0; i < sentCommands.length; i++) {
                    var cmd = sentCommands[i],
                        selectedAttr = cmd === this.cmd ? "selected" : "";
                    this.cmdSelect.append("<option " + selectedAttr + " >" + cmd + "</option>");
                }
            } else {
                // Add the initial option if no command exists
                this.cmdSelect.append(this.initialOption);
            }
        }

        /**
         * Populates the input field with the selected command
         * @private
         */
        _didChooseCommand() {
            // We don't want the default disabled option as we don't want set its value (--) to the command text field
            var selectedOption = $("select option:selected:not([disabled])"),
                cmd = selectedOption.text();
            this.input.setValue(cmd);
            this.cmd = cmd;
            this.sendButton.setEnabled(this.cmd !== "");
        }

        _openValueScreen(cmdName, response) {
            this.ViewController.showState("UniversalControlValueScreen", null, {
                stateKey: cmdName,
                stateValue: JSON.stringify(response)
            });
        }

        _cmdIsNotEmpty() {
            return this.cmd !== "" && this.cmd !== " ";
        }

    };
});
