'use strict';

define([], function () {
    return class MenuScreen extends GUI.Screen {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return $('' + '<div class="menu-screen">' + '<div class="menu-screen__header">' + '<div class="header__logo-container">' + AppBranding.getBrandLogoElement("logo-container__logo") + '</div>' + '</div>' + '<div class="menu-screen__container"></div>' + '</div>');
            };

            var getTableHeaderElement = function getTableHeaderElement(small, large) {
                return $('<div class="menu-screen__container__menu-header">' + '<div class="menu-header__small-text">' + (small ? small : "") + '</div>' + '<div class="menu-header__large-text">' + (large ? large : "") + '</div>' + '</div>');
            };

            return {
                getTemplate: getTemplate,
                getTableHeaderElement: getTableHeaderElement
            };
        }(); //endregion Static

        constructor() {
            super(MenuScreen.Template.getTemplate());
            this.isWebinterface = PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface;
            this.msInfo = ActiveMSComponent.getActiveMiniserver();
            this.showItemFn = this._showItemState.bind(this);
        }

        viewDidLoad() {
            var weatherStates = SandboxComponent.getStatesForUUID(WEATHER_UUID);
            this.hasWeatherService = this.msInfo && weatherStates && weatherStates.states.hasValidData;
            return super.viewDidLoad(...arguments).then(function () {
                let promise;
                this.setUpComfortUI(false, false);
                this.toggleComfortModeUI(false);

                if (HD_APP) {
                    this.element.addClass("menu-screen--hd");
                }

                this.elements = {
                    container: this.element.find('.menu-screen__container')
                };

                this._createTableViewContent();
                this.tableViewDataSource = tableViewDataSource(this.tableContent, null, this);
                this.tableViewDelegate = tableViewDelegate(this.tableContent);

                promise = this.appendReactComp({
                    reactComp: GUI.LxReactTableViewLxViewAdapter,
                    compProps: {
                        dataSource: this.tableViewDataSource,
                        delegate: this.tableViewDelegate,
                        performanceStyle: GUI.LxReactTableView.PerformanceStyle.Normal,
                        key: "menu-table"
                    },
                    target: this.elements.container
                }).then(tableView => this.tableView = tableView);

                return promise.then(() => this._reloadTable().then( () => {
                    if (this.msInfo && !this.hasWeatherService) {
                        SandboxComponent.registerForStateChangesForUUID(WEATHER_UUID, this, this._weatherStateReceived.bind(this));
                    }
                }));
            }.bind(this));
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments);
            this.elements.container.scrollTop(this.lastScrollPosition);
        }

        viewWillDisappear() {
            this.lastScrollPosition = this.elements.container.scrollTop();
            super.viewWillDisappear(...arguments);
        }

        getRightIcon() {
            return this._isConnected() ? Icon.Buttons.SEARCH : null;
        }

        /**
         * Called when the top right icon is tapped.
         */
        rightAction() {
            NavigationComp.showSearchScreen();
        }

        isReusable() {
            return true;
        }

        destroyOnBackNavigation() {
            return true; // destroy the screen always, to make sure that we don't get an unexpected state, where we show a menu for a Miniserver in the archive (while disconnected)
        }

        destroy() {
            if (this.msInfo) {
                SandboxComponent.unregisterForStateChangesForUUID(WEATHER_UUID, this);
            }

            super.destroy(...arguments);
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        // Private methods
        _reloadTable() {
            this._createTableViewContent();

            this.tableViewDataSource.update(this.tableContent);
            this.tableViewDelegate.update(this.tableContent);

            return this.tableView.reload();
        }

        _createTableViewContent() {
            this.tableContent = [];
            this.tableContent.pushObject(this._createSettingsSection());
            this.tableContent.pushObject(this._createAboutSection());

            this.tableContent.forEach(section => {
                section.rows = section.rows.map(row => {
                    return this._adoptCellContentForReact(row);
                })
            })
        }

        _showState(state, details) {
            //this.ViewController.navigateBack();
            this.ViewController.showState(state, null, details);
        }

        _createSection(rows, title, hdrContent, hdrElem) {
            var section = null;

            if (rows && rows.length > 0) {
                section = {
                    rows: rows,
                    headerElement: hdrElem,
                    headerTitle: title,
                    headerContent: hdrContent
                };
            }

            return section;
        }

        _createSettingsSection() {
            var items = SandboxComponent.getMenuSettingsSectionItems(this.showItemFn);
            return items.length > 0 ? this._createSection(items) : null;
        }

        _createAboutSection() {
            var items = SandboxComponent.getMenuAboutItems(this.showItemFn);
            if (GUI.DebugScreen.enabled) {
                let subtitle;
                if (PlatformComponent.isMobileDevice()) {
                    subtitle = _("debug-screen.info.mobile");
                } else {
                    subtitle = _("debug-screen.info.stationary");
                }

                items.push({
                    content: {
                        title: _("debug-screen.text"),
                        subtitle: subtitle,
                        clickable: true
                    },
                    action: () => {
                        GUI.DebugScreen.toggle();
                    }
                });
            }
            return this._createSection(items, _("menu.about-heading"));
        }

        // Private Methods
        _weatherStateReceived(states) {
            this.hasWeatherService = states.hasValidData;

            this._reloadTable();

            SandboxComponent.unregisterForStateChangesForUUID(WEATHER_UUID, this);
        }

        _showItemState(state, details) {
            if (state === ScreenState.AboutMiniserver) {
                details = details || {};
                details.noActions = true;
            }

            this.ViewController.showState(state, null, details);
        }

        _isConnected() {
            return this.msInfo;
        }

        _adoptCellContentForReact(row) {
            delete row.type;
            return row;
        }
    };
});
