import {StyleSheet, View} from "react-native";
import globalStyles from "GlobalStyles";
import PropTypes from "prop-types";
import {LxReactText, LxReactWaitingIndicator} from "LxComponents";

const badgeDiameter = 30;
const styles = StyleSheet.create({
    badgeContainer: {
        width: "fit-content",
        minWidth: 30,
        minHeight: 30,
        aspectRatio: 1,
        paddingVertical: globalStyles.spacings.gaps.smaller,
        paddingHorizontal: globalStyles.spacings.gaps.smaller,
        margin: 2,
        borderRadius: 16
    },
    squareContainer: {
        borderRadius: 2,
        width: 22,
        height: 22,
        margin: 0,
        alignItems: "center",
        paddingVertical: "initial",
        paddingHorizontal: "initial",
    },
    badgeText: {
        ...globalStyles.textStyles.footNote.default,
        margin: "auto"
    }
})

/**
 * @param {String} props.text The text that should be displayed in the badge
 * @param {String} props.backgroundColor The background color of the badge
 * @param {String} props.textColor The background color of the badge
 * @param {String} props.badgeType Round or Square - defaults to round
 * @returns {JSX.Element} A text with a background color, padding and a borderRadius
 */
function LxReactBadge(props) {

    return (
        <View style={
            [
                styles.badgeContainer,
                (props.badgeType === LxReactBadgeType.SQUARE ? styles.squareContainer : {}),
                {backgroundColor: props.backgroundColor || globalStyles.colors.white},
                (props.size ? {
                    borderRadius: (props.badgeType === LxReactBadgeType.SQUARE) ? 2 : (props.size / 2 + 1),
                    minWidth: props.size,
                    minHeight: props.size
                } : {}),
                props.containerStyle || {}
            ]}>
            {
                props.loading ?
                    <LxReactWaitingIndicator size="small" color={props.textColor} style={{margin: "auto"}} />
                    :
                    <LxReactText style={{color: props.textColor, margin: "auto", ...props.textStyle}}>{props.text}</LxReactText>
            }
        </View>
    )
}

export const LxReactBadgeType = {
    ROUND: "round",
    SQUARE: "square"
}

LxReactBadge.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    textColor: PropTypes.string,
    textStyle: PropTypes.object,
    containerStyle: PropTypes.object,
    loading: PropTypes.bool,
    size: PropTypes.number,
    backgroundColor: PropTypes.string,
    badgeType: PropTypes.oneOf([LxReactBadgeType.ROUND, LxReactBadgeType.SQUARE])
}

export default LxReactBadge;
