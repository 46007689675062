'use strict';
/**
 value   either an array with hours/minutes/seconds or simply seconds
 */

window.GUI = function (GUI) {
    class PickerViewDuration extends GUI.LxPickerViewBase {
        constructor() {
            super(...arguments);
            Debug.GUI.PickerView && console.log(this.name, "constructor");
            this.hasHours = this.details.wheelOrder.indexOf("hh") !== -1;
            this.hasMinutes = this.details.wheelOrder.indexOf("ii") !== -1;
            this.hasSeconds = this.details.wheelOrder.indexOf("ss") !== -1;
        }

        /**
         * This function initializes the Picker
         */
        preparePicker() {
            this.picker.timespan(this.mergePickerConfig({
                minTime: this.details.minTime ? this.details.minTime : 0,
                maxTime: this.details.maxTime ? this.details.maxTime : 24 * 60 * 60 * 1000,
                wheelOrder: this.details.wheelOrder,
                labels: ['', '', '', 'h', 'm', 's'] // ['Years', 'Months', 'Days', 'Hours', 'Minutes', 'Seconds']

            }));
            this.setPickerValue(this.details.value);
        }

        /**
         * This function will be called once the pickerValue has changed
         * For example if the user turns one of the pickerWheels
         * @param v The value returned by mobiscroll in the given format
         */
        onChange(v) {
            this.rawPickerValue = v;
            this.onPickerChangedFn && this.onPickerChangedFn(this.getPickerValue(v));
        }

        getPickerValue(v) {
            /**
             * v is in the following format specified with the labels property in the preparePicker function
             * 12 h 48 m 55 s
             */
            var h = 0,
                m = 0,
                s = 0,
                tmpValues;
            v = v || this.rawPickerValue;

            if (this.hasHours && v.indexOf("h") !== -1) {
                tmpValues = v.split("h");
                h = parseInt(tmpValues[0]);
                v = tmpValues[1];
            }

            if (v.indexOf("m") !== -1) {
                tmpValues = v.split("m");
                m = parseInt(tmpValues[0]);
                v = tmpValues[1];
            }

            if (this.hasSeconds && v.indexOf("s") !== -1) {
                tmpValues = v.split("s");
                s = parseInt(tmpValues[0]);
                v = tmpValues[1];
            }

            return [h, m, s];
        }

        /**
         * This sets the given value to the mobiscroll picker
         * @param value This must be in the right format you specified in the picker config.
         */
        setPickerValue(value) {
            var valueArray = [],
                inputValueArray = [];

            if (Array.isArray(value)) {
                inputValueArray = value;
            } else if (value) {
                inputValueArray = this._getPickerValueArrayFromSeconds(value);
            }

            if (this.hasHours) {
                valueArray.push(inputValueArray[0] || 0);
            }

            if (this.hasMinutes) {
                valueArray.push(inputValueArray[1] || 0);
            }

            if (this.hasSeconds) {
                valueArray.push(inputValueArray[2] || 0);
            }

            this.picker.mobiscroll('setVal', valueArray);
        }

        /**
         * Takes input seconds and converts it into an array with a value for hour/minute/second
         * @param valueSeconds
         * @returns {[number, number, number]}
         * @private
         */
        _getPickerValueArrayFromSeconds(valueSeconds) {
            var calcValue = valueSeconds,
                hours = 0,
                minutes = 0,
                seconds = 0;
            seconds = calcValue % 60;
            calcValue -= seconds;
            minutes = calcValue % 3600;
            calcValue -= minutes;
            minutes = minutes / 60;
            hours = calcValue / 3600;
            return [hours, minutes, seconds];
        }

    }

    GUI.LxPickerViewDuration = PickerViewDuration;
    return GUI;
}(window.GUI || {});
