'use strict';
/*
 previewColor
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.COLOR_PICKER_BRIGHTNESS = "ColorPickerBrightnessCell";
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        // Keep in sync with colorPickerFavoritesCell
        var MIN_BG_BRIGHTNESS = 10;

        class ColorPickerBrightnessCell extends GUI.TableViewV2.Cells.SliderSwitchCell {
            //region Static
            static Template = function () {
                var getPreviewColorCircle = function getPreviewColorCircle() {
                    return '<div class="content__color-circle"></div>';
                };

                return {
                    getPreviewColorCircle: getPreviewColorCircle
                };
            }(); //endregion Static

            constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
                super(...arguments);
                this.element.addClass("color-picker-brightness-cell");
            }

            viewDidLoad() {
                return Q(super.viewDidLoad(...arguments)).then(function () {
                    this.previewColorCircle = $(ColorPickerBrightnessCell.Template.getPreviewColorCircle());
                    this.previewColorCircle.insertAfter(this.elements.titleLbl);

                    if (this.content.previewColor) {
                        this.setPreviewColor(this.content.previewColor);
                    }

                    this.elements.valueLbl.hide(); // We don't need a value label
                }.bind(this));
            }

            // Public methods
            setPreviewColor(colorObject) {
                this.content.previewColor = colorObject; // circle

                var cssColor = LxColorUtils.getCSSRGBPropertyFromColorObject(colorObject);
                this.previewColorCircle.css('background-color', cssColor);
                var darkMode = NavigationComp.getDarkModeState();
                this.previewColorCircle.toggleClass("color-circle--light-bg", !darkMode && colorObject.sat < MIN_BG_BRIGHTNESS);
                this.previewColorCircle.toggleClass("color-circle--dark-bg", darkMode && colorObject.brightness < MIN_BG_BRIGHTNESS); // switch

                this.switch.setActive(colorObject.brightness > 0); // brightness

                this.setPosition(colorObject.brightness);
            }

        }

        GUI.TableViewV2.Cells.ColorPickerBrightnessCell = ColorPickerBrightnessCell;
    }
    return GUI;
}(window.GUI || {});
