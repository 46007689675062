define("SequentialControl", ["Control"], function (Control) {
    return class SequentialControl extends Control {
        constructor() {
            super(...arguments);
        }

        /**
         * Returns an icon for this control type, that Icon will be used if no defaultIcon is set by loxone config.
         * @returns {Icon}
         */
        getControlIcon() {
            return Icon.Sequential.CONTROL;
        }

    };
});
