import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Tablet(props) {
    return (
        <Svg
            isreact="true"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M21.749 3a2.25 2.25 0 012.25 2.25v13.502a2.25 2.25 0 01-2.25 2.25H2.25A2.25 2.25 0 010 18.752V5.25A2.25 2.25 0 012.25 3h19.499zm0 1.5H2.25a.75.75 0 00-.75.75v13.502c0 .414.336.75.75.75h19.499a.75.75 0 00.75-.75V5.25a.75.75 0 00-.75-.75zm-11.499 12h3.5a.75.75 0 01.102 1.493L13.75 18h-3.5a.75.75 0 01-.102-1.493l.102-.007h3.5-3.5z"
            />
        </Svg>
    )
}

export default Tablet
