'use strict';

define(['AudioZoneV2ControlEnums', 'AudioZoneV2DetailedContentBase'], function (AudioZoneV2ControlEnums, AudioZoneV2DetailedContentBase) {
    return class RecordingSelection extends AudioZoneV2DetailedContentBase {
        //region Getter
        get RIGHT_SIDE_BUTTON() {
            let baseVal = super.RIGHT_SIDE_BUTTON;
            baseVal.NEXT = "right-side__touch-area--next";
            return baseVal;
        } //endregion Getter


        constructor() {
            super(...arguments);
            this.availableControls = this.control.audioserverComp.getAvailableZones(true);
            this.selectedControls = [this.control];
        }

        titleBarActionRight(buttonId) {
            if (buttonId === this.RIGHT_SIDE_BUTTON.NEXT) {
                this.ViewController.showState(AudioZoneV2ControlEnums.ScreenState.RECORDING_SCREEN, null, {
                    control: this.control,
                    controls: this.selectedControls
                });
            } else {
                super.titleBarActionRight(...arguments);
            }
        }

        getURL() {
            return "VoiceRecorder";
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            return _('media.menu.announcement');
        }

        getTableContent() {
            var selectedControlUuids = this.selectedControls.map(function (control) {
                return control.uuidAction;
            });
            return [{
                rows: this.availableControls.map(function (control) {
                    var states = control.getStates(),
                        controlIsLocked = states && states.isLocked,
                        controlCanPlay = states && states.canPlayMusic;

                    if (control.isConfigured() && !controlIsLocked && controlCanPlay) {
                        return {
                            type: GUI.TableViewV2.CellType.CHECKABLE,
                            content: {
                                control: control,
                                title: control.getName(),
                                subtitle: control.getSubtitle(),
                                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE,
                                selected: selectedControlUuids.indexOf(control.uuidAction) !== -1,
                                isCurrentPlayer: control.uuidAction === this.control.uuidAction
                            },
                            didCheckCell: this._onCellSelected.bind(this)
                        };
                    } else if (controlIsLocked) {
                        return {
                            type: GUI.TableViewV2.CellType.CHECKABLE,
                            content: {
                                control: control,
                                title: control.getName(),
                                titleColor: control.uuidAction === this.control.uuidAction ? window.Styles.colors.stateActive : null,
                                subtitleColor: window.Styles.colors.orange,
                                subtitle: _("operation-blocked"),
                                rightIconSrc: Icon.MESSAGE_CENTER.WARNING,
                                rightIconColor: window.Styles.colors.orange,
                                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE,
                                selected: selectedControlUuids.indexOf(control.uuidAction) !== -1,
                                isCurrentPlayer: control.uuidAction === this.control.uuidAction,
                                disabled: true
                            }
                        };
                    } else {
                        return {
                            type: GUI.TableViewV2.CellType.CHECKABLE,
                            content: {
                                control: control,
                                title: control.getName(),
                                titleColor: control.uuidAction === this.control.uuidAction ? window.Styles.colors.stateActive : null,
                                subtitleColor: window.Styles.colors.orange,
                                subtitle: _("unconfigured.title"),
                                rightIconSrc: Icon.MESSAGE_CENTER.WARNING,
                                rightIconColor: window.Styles.colors.orange,
                                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE,
                                selected: selectedControlUuids.indexOf(control.uuidAction) !== -1,
                                isCurrentPlayer: control.uuidAction === this.control.uuidAction,
                                disabled: true
                            }
                        };
                    }
                }.bind(this)).sort(function (a, b) {
                    return a.content.isCurrentPlayer * -1; // Moves the current player on top
                }.bind(this))
            }];
        }

        didRequestCheckingCell(cell, section, row, tableView) {
            if (cell.content.selected) {
                // User wants to deselect the cell, check if it is possible
                // At lease one cell (control) must be selected!
                return this.selectedControls.length > 1;
            } else {
                return true;
            }
        }

        setTableContent() {
            return Q(this.getTableContent()).then(tableContent => {
                this.tableContent = tableContent;
                return super.setTableContent(...arguments);
            });
        }

        _getRightSideTitleBarButtons() {
            return [this._getRightSideTitleBarButtonElm(this.RIGHT_SIDE_BUTTON.NEXT, Icon.Buttons.MORE_NO_CIRCLE)];
        }

        _onCellSelected(cell, section, row, tableView, selected) {
            var control = cell.content.control,
                ctrlIdx = this.selectedControls.findIndex(function (control) {
                    return control.uuidAction === cell.content.control.uuidAction;
                });

            if (ctrlIdx === -1) {
                this.selectedControls.push(control);
            } else {
                this.selectedControls.splice(ctrlIdx, 1);
            }
        }

    };
});
