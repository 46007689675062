'use strict';

define(["./miniserverSelectionScreen.jsx"], function (MiniserverSelectionComp) {
    return class MiniserverSelectionScreen extends GUI.Screen {

        static ReactComp = MiniserverSelectionComp.default;

        constructor() {
            super($('<div />'));
        }

        getAnimation() {
            return AnimationType.MODAL;
        }
    };
});
