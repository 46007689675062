'use strict';

define("SteakTimeSelectorScreen", ["SteakControlEnums"], function (SteakControlEnums) {
    return class SteakTimeSelectorScreen extends Controls.ControlContent {
        constructor(details) {
            super(details);
            this.initDuration = details.duration;
            this.duration = details.duration;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.tableView = new GUI.TableViewV2(this, this);
            this.appendSubview(this.tableView);
        }

        titleBarText() {
            return _('controls.daytimer.timer.name');
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments);
            this.tableView.reload();
        }

        getAnimation() {
            return AnimationType.HD_OVERLAY;
        }

        viewWillDisappear() {
            if (this.initDuration !== this.duration) {
                this.sendCommand(Commands.format(Commands.Steak.SET_TIMER_DURATION, this.duration));
            }

            super.viewWillDisappear(...arguments);
        }

        // TableViewDataSource Methods
        styleForTableView(tableView) {
            return TableViewStyle.GROUPED;
        }

        numberOfSections(tableView) {
            return 1;
        }

        numberOfRowsInSection(section, tableView) {
            return 1;
        }

        titleForFooterInSection(section, tableView) {
            return _("steak.timer.picker-description", {
                productName: _('steak.product-name')
            });
        }

        cellTypeForCellAtIndex(section, row, tableView) {
            return GUI.TableViewV2.CellType.DURATION_PICKER;
        }

        contentForCell(cell, section, row, tableView) {
            var hours = Math.floor(this.duration / 3600),
                hourLesDuration = this.duration % 3600,
                minutes = Math.floor(hourLesDuration / 60),
                seconds = Math.floor(hourLesDuration % 60);
            return {
                title: _("pool.control.duration"),
                value: [hours, minutes, seconds],
                maxTime: SteakControlEnums.TIMER_BOUNDRY.MAX,
                minTime: SteakControlEnums.TIMER_BOUNDRY.MIN,
                wheelOrder: "hhiiss",
                expanded: true
            };
        }

        // TableViewDelegate methods
        onPickerChanged(section, row, tableView, value) {
            var hours = value[0],
                minutes = value[1],
                seconds = value[2];
            this.duration = hours * 60 * 60 + minutes * 60 + seconds;
        }

    };
});
