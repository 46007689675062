'use strict';

define(['./presentationSettingsScreen.jsx'], function (PresentationSettings) {
    return class PresentationSettingsScreen extends GUI.Screen {

        static ReactComp = PresentationSettings.default

        constructor() {
            super($('<div />'));
        }

        titleBarText() {
            return _('menu.settings.app.presentation');
        }
    };
});
