import {createStateCell} from "../../UtilityComp/utilities/helper";
import globalStyles from "GlobalStyles";

export default class LoadManagerControlStatesSrc {
    static getStateSections(control, states) {
        let sections = [],
            section = {},
            rows = [];

        let loads = control.details.loads;
        loads.forEach((load) => {
            var value = NBR_SPACE,
                valueColor;

            if (load.hasStatus) {
                value = hasBit(states.statusLoads, 1 << load.id) ? _("on") : _("off");
                valueColor = hasBit(states.statusLoads, 1 << load.id) ? globalStyles.colors.brand : globalStyles.colors.text.secondary;
            }

            if (hasBit(states.lockedLoads, 1 << load.id)) {
                value = _("control.lock.locked-title");
                valueColor = globalStyles.colors.red;
            }

            rows.push(createStateCell(load.name, value, null, _("controls.loadManager.load-max", {
                power: lxFormat("%.2f", load.power)
            }), null, valueColor, globalStyles.colors.grey["300"]));
        });

        section.rows = rows;
        sections.push(section);

        return sections;
    }
}