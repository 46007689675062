import { useState, useEffect, useRef } from 'react';

/**
 * Used to register for states of a stateContainer, optionally only for certain attributes of that state container
 * @param containerUuid what state container to register for
 * @param [stateNames] for which state names should it register specifically
 * @returns {{allStatesReceived: boolean, states: object}}
 */
function useLiveState(containerUuid, stateNames) {

    const filterStates = (allStates) => {
        let filtered;
        if (allStates && Array.isArray(stateNames)) {
            filtered = {};
            stateNames.forEach(name => {
                if (allStates.hasOwnProperty(name)) {
                    filtered[name] = allStates[name];
                }
            })
        } else {
            filtered = allStates;
        }
        return filtered;
    }

    const hashForState = (stateVal) => {
        if (!stateVal) {
            return 0;
        }
        let hashValBase = stateVal;
        if (typeof stateVal === "function") {
            hashValBase = stateVal();
        }
        return JSON.stringify(hashValBase).hashCode();
    }

    const initialHashes = (states) => {
        let result = {};
        (stateNames || Object.keys(states)).forEach((stateName) => {
            try {
                result[stateName] = hashForState(states[stateName]);
            } catch (ex) {
                result[stateName] = 0;
                console.error(useLiveState.name, "Failed to aquire hash for " + stateName, states);
            }
        })
        return result;
    }

    const [stateCntr, setStateCntr] = useState(1);
    const control = useRef(ActiveMSComponent.getControlByUUID(containerUuid));

    const dbgName = () => {
        return useLiveState.name + "@" + (control.current ? control.current.getName() : "-no-controls-");
    }

    let unregFn = useRef(null);
    let internalState = useRef(SandboxComponent.getStatesForUUID(containerUuid) ?  filterStates(SandboxComponent.getStatesForUUID(containerUuid).states) : {});
    let internalHashes = useRef(SandboxComponent.getStatesForUUID(containerUuid) ?  initialHashes(filterStates(SandboxComponent.getStatesForUUID(containerUuid).states)) : {});
    let internalAllStatesReceived = useRef(SandboxComponent.getStatesForUUID(containerUuid) !== false);


    const updateAndCheckInternal = (newState) => {
        let modified = false;
        let internalModified = false,
            stateHash;
        if (newState && Array.isArray(stateNames)) {
            Debug.React.LiveStateHook && console.log(dbgName(), "updateAndCheckInternal");
            stateNames.forEach((stateName) => {
                internalModified = false;
                try {
                    stateHash = hashForState(newState[stateName]);
                } catch (ex) {
                    console.error(useLiveState.name, "Failed to aquire hash for " + stateName, newState);
                    stateHash = 0;
                }
                if (internalHashes.current[stateName] !== stateHash) {
                    internalModified = true;
                    modified = true;
                    Debug.React.LiveStateHook && console.log(dbgName(), "   INT: " + stateName + ": " + JSON.stringify(internalState.current[stateName]) + " -> " + JSON.stringify(newState[stateName]));
                    internalState.current[stateName] = newState[stateName];
                    internalHashes.current[stateName] = stateHash;
                }
            })
        } else if (newState) {
            let newInternalState = {};
            Object.keys(newState).forEach((stateName) => {
                try {
                    newInternalState[stateName] = newState[stateName];
                } catch (ex) {
                    console.error(dbgName(), "failed to store state of '" + stateName + "'");
                }
            })
            internalState.current = newInternalState;
            Debug.React.LiveStateHook && console.log(dbgName(), "updateAndCheckInternal - not monitoring specific states, update");
            modified = true;
            // no state names provided, nothing to do.
        }

        if (modified) {
            changeCntr();
        }

        return modified;
    }

    const changeCntr = () => {
        // use a random, as - oddly - the previous stateCntr cannot be acquired and is always the initial value.
        setStateCntr(getRandomIntInclusive(0, 99999999));
    }

    useEffect(() => {
        function stateContainerReady(isReady) {
            //if (statusInfo.allStatesReceived !== isReady) {
            if (internalAllStatesReceived.current !== isReady) {
                Debug.React.LiveStateHook && console.warn(dbgName(), "stateContainerReady - " + isReady + " before= " + internalAllStatesReceived.current);
                internalAllStatesReceived.current = !!isReady;
                changeCntr();
            } else {
                Debug.React.LiveStateHook && console.log(dbgName(), "stateContainerReady - unchanged, ignore!");
            }

        }

        Debug.React.LiveStateHook && console.log(dbgName(), "useEffect - unregister/reregister");
        // fns stored as states will be called and the return value is stored (stateReducer) --> wrap in anonymous fn
        unregFn.current && unregFn.current();
        unregFn.current = SandboxComponent.registerFunctionForStateChangesForUUID(containerUuid, (newStates) => {
            updateAndCheckInternal(filterStates(newStates));
        }, stateContainerReady)


        let currentStates = SandboxComponent.getStatesForUUID(containerUuid).states || {} // if there is no state container, the states won't update
        // ensure the internal dataset is updated.
        control.current && updateAndCheckInternal(filterStates(currentStates));

        return () => {
            Debug.React.LiveStateHook && console.log(dbgName(), "useEffect - DESTROY");
            unregFn.current && unregFn.current();
        };
    }, [containerUuid, stateNames ? stateNames.join(",").hashCode() : -1]);

    return { states: internalState.current, allStatesReceived: internalAllStatesReceived.current, _stateCntr: stateCntr };
}

export default useLiveState;
