import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

function HighestPrice(props) {
  return (
      <Svg
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          isreact="true"
          {...props}
      >
        <Circle cx={12.3334} cy={12} r={11} stroke="#E73246" strokeWidth={2}/>
        <Path
            d="M12.3334 7V17M12.3334 7L15.8334 10.5M12.3334 7L8.83337 10.5"
            stroke="#E73246"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
      </Svg>
  )
}
export default HighestPrice;
