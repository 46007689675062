import * as React from "react"
import Svg, { Path } from "react-native-svg"

function ListIndicator(props) {
    return (
        <Svg isreact="true"
            width={16}
            height={11}
            viewBox="0 0 16 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M4 .167c.368 0 .667.298.667.666v7.058l1.862-1.862a.667.667 0 11.942.942l-3 3a.667.667 0 01-.942 0l-3-3a.667.667 0 01.942-.942L3.333 7.89V.833c0-.368.299-.666.667-.666zM11.529 1.029c.26-.26.682-.26.942 0l3 3a.667.667 0 11-.942.942L12.667 3.11v7.058a.667.667 0 01-1.334 0V3.109L9.471 4.971a.667.667 0 11-.942-.942l3-3z"
            />
        </Svg>
    )
}

export default ListIndicator
