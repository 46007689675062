'use strict';

define("JalousieCentralControlCommandSrc", ["JalousieControlCommandSrc"], function (JalousieControlCommandSrc) {
    return class JalousieCentralControlCommandSrc extends JalousieControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let details = {
                type: GUI.TableViewV2.CellType.ROUND_COMFORT_ACTION,
                rowIdx: 0
            }
            let cmds = [
                this.createCommand(_('controls.jalousie.curtain.up.full'), Commands.JALOUSIE.FULL_UP, control.getOpenIcon(), details),
                this.createCommand(_('controls.jalousie.curtain.down.full'), Commands.JALOUSIE.FULL_DOWN, control.getCloseIcon(), details),
                this.createCommand(_('controls.jalousie.shade'), Commands.JALOUSIE.SHADE, Icon.Jalousie.ComfortMode.SHADE_BUTTON, details),
                this.createCommand(_('mobiscroll.stop'), Commands.JALOUSIE.STOP, Icon.Jalousie.STOP, details)
            ];

            if (states.autopilotsAvailable_Sel) {
                if (!states.allAutopilotsActive_Sel) {
                    cmds.push(this.createDeactivatable(_('autopilot.activate'), Commands.JALOUSIE.AUTO_ON, Icon.Jalousie.ComfortMode.AUTO_BUTTON, !states.nrOfAutoAllowed, details));
                } else {
                    cmds.push(this.createDeactivatable(_('autopilot.deactivate'), Commands.JALOUSIE.AUTO_OFF, Icon.Jalousie.ComfortMode.AUTO_BUTTON, !states.nrOfAutoAllowed, details));
                }
            }

            return cmds;
        }

    };
});
