import * as React from "react"
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg"

function AlarmShield(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <G clipPath="url(#clip0_1238_869)">
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.871 4.532H1.87a.499.499 0 00-.37.483c0 8.569.761 15.406 10.326 18.948a.5.5 0 00.348 0C21.739 20.421 22.5 13.584 22.5 5.015a.499.499 0 00-.37-.483h-.001c-.134-.037-7.409-2.027-9.766-4.391a.534.534 0 00-.723 0c-2.362 2.365-9.633 4.354-9.769 4.39z"
                    fill="#000"
                />
            </G>
            <Defs>
                <ClipPath id="clip0_1238_869">
                    <Path fill="#fff" d="M0 0H24V24H0z" />
                </ClipPath>
            </Defs>
        </Svg>
    )
}

export default AlarmShield
