import {useState} from "react";
import {
    LxReactTableView,
    navigatorConfig
} from "LxComponents"

const SecuritySettingsScreen = props => {

    props.navigation.setOptions(
        navigatorConfig({
            title: _("menu.settings.app.security.title"),
            animationType: AnimationType.MODAL
        })
    );

    // no need to use a state or alike, the switch will update itself once the promise has passed!
    const biometricType = BiometricHelper.getBiometricTypePhrase(),
            tableContent = [
            {
                rows: [
                    {
                        type: GUI.TableViewV2.CellType.SWITCH,
                        content: {
                            title: biometricType,
                            active: PersistenceComponent.getBiometricState()
                        },
                        onSwitchChanged: function onSwitchChanged(value) {
                            // Force a Biometric authentication. This will show the initial Touch or Face ID
                            // permission dialog if Touch or Face ID hasn't been enabled for the app yet.
                            return BiometricHelper.authenticate().then(function success() {
                                PersistenceComponent.setBiometricState(value);
                            });
                        }
                    }
                ],
                footerTitle: _("menu.settings.app.security.details.biometric.footer.title", {
                    authMethod: biometricType
                })
            }
        ];

    return <LxReactTableView
        tableContent={tableContent}
    />
}

export default SecuritySettingsScreen;
