
import { useMemo } from 'react';
import { EfmNodeType } from '../../Controls/energyFlowMonitorControl/efmUtilities';

const nodesContainType = (nodes, type) => {
    return nodes.some(node => {
        if(node.hasChildNodes) {
            return nodesContainType(node._nodes, type);
        } else {
            return node.nodeType === type;
        }
    })
}

export default nodes => {
    return useMemo(() => {
        const ret = {};
        Object.entries(EfmNodeType).forEach(([key, type]) => {
            ret[key] = nodesContainType(nodes, type);
        });
        return ret;
    }, [nodes])
}