'use strict';

define([], function () {
    return function BarChartView(data, ui, ctx, Helper) {
        var draw = function drawBarChart() {
            var preMiddleDp = data.source[data.source.middleIndex - 1],
                middleDp = data.source[data.source.middleIndex],
                pastMiddleDp = data.source[data.source.middleIndex + 1]; // to the left

            Helper.forEachVisibleDataPoint(Direction.LEFT, data.source.middleIndex, drawBar); // to the right

            Helper.forEachVisibleDataPoint(Direction.RIGHT, data.source.middleIndex + 1, drawBar); // Simply marks the middle, its previous and following datapoint, this is useful for debugging

            if (Debug.Statistic.Draw) {
                preMiddleDp && Helper.markPointWithColorAndLabel(Helper.getXForTimestamp(preMiddleDp.ts), calcYForDp(preMiddleDp), window.Styles.colors.purple, "prevDp");
                Helper.markPointWithColorAndLabel(Helper.getXForTimestamp(middleDp.ts), calcYForDp(middleDp), window.Styles.colors.blue, "middleDp");
                pastMiddleDp && Helper.markPointWithColorAndLabel(Helper.getXForTimestamp(pastMiddleDp.ts), calcYForDp(pastMiddleDp), Color.OAK, "nextDp");
            }

            data.check();
        };

        var x = 0,
            rectWidth = 0,
            rectX = 0,
            rectY = 0,
            rectXMax = 0,
            rectYMax = 0,
            rectXMiddle = 0;
        var dpMoment,
            dpTSx1 = 0,
            dpTSx2 = 0,
            barWidth = 0,
            MIN_BAR_WIDTH = 10,
            barDistance = 4;

        var drawBar = function drawBar(dp) {
            ctx.fill(); // Off limit bars should be colored orange

            ctx.fillStyle = dp.value > data.outlierLimit ? window.Styles.colors.orange : window.Styles.colors.green;
            ctx.beginPath();
            dpMoment = moment.utc(dp.ts * 1000);
            dpTSx1 = Helper.getXForTimestamp(dpMoment.unix());
            dpTSx2 = Helper.getXForTimestamp(dpMoment.add(1, data.range).unix());
            barWidth = Math.max(MIN_BAR_WIDTH, dpTSx2 - dpTSx1 - barDistance); // calcing with x from left, y from top!

            x = dpTSx1 + barDistance / 2;
            rectWidth = barWidth;
            rectX = x;
            rectY = calcYForDp(dp);
            rectXMax = rectX + rectWidth;
            rectYMax = ui.bottomOffset;
            rectXMiddle = rectX + rectWidth / 2;
            ctx.moveTo(rectXMax, rectYMax);
            ctx.lineTo(rectX, rectYMax);
            ctx.arcTo(rectX, rectY, rectXMiddle, rectY, 2);
            ctx.arcTo(rectXMax, rectY, rectXMax, rectYMax, 2);
            ctx.closePath();
        };

        var calcYForDp = function calcYForDp(dp) {
            return ui.bottomOffset - Math.max(ui.bottomOffset - Helper.getYForValue(dp.value), dp.value === 0 ? 0 : 5); // if value != 0 then the min height is 5!
        };

        return {
            draw: draw
        };
    };
});
