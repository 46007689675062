define("GateCentralControlCommandSrc", ["GateControlCommandSrc"], function (GateControlCommandSrc) {
    return class GateCentralControlCommandSrc extends GateControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            var numberOfSelectedCtrls = control.getSelectedControls().length,
                isOnly1ControlSelected = numberOfSelectedCtrls === 1,
                isOpening = states.nrOfOpening_Sel > 0,
                allOpening = isOpening && isOnly1ControlSelected,
                isClosing = states.nrOfClosing_Sel > 0,
                allClosing = isClosing && isOnly1ControlSelected,
                openDisabled = states.nrOfPreventOpen_Sel > 0 && numberOfSelectedCtrls === states.nrOfPreventOpen_Sel,
                closeDisabled = states.nrOfPreventClose_Sel > 0 && numberOfSelectedCtrls === states.nrOfPreventClose_Sel;
            return [
                this.createDeactivatable(_('close'), allClosing ? Commands.GATE.STOP : Commands.GATE.CLOSE, null, closeDisabled, {rowIdx: 0}),
                this.createDeactivatable(_('open'), allOpening ? Commands.GATE.STOP : Commands.GATE.OPEN, null, openDisabled, {rowIdx: 0}),
                ];
        }

    };
});
