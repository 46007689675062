'use strict';

define(["AudioZoneControlEnums"], function (AudioZoneControlEnums) {
    class AudioZoneSettingsServiceRadioSettings extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            this.details = details;
            this.control = details.control;
        }

        viewWillAppear() {
            super.viewWillAppear(arguments);

            this._reloadRadios(true);
        }

        titleBarText() {
            return _("edit");
        }

        onSwitchChanged(active, section, row, table) {
            var cmd;

            if (active) {
                cmd = MediaEnum.Commands.SERVICES.RADIO.ENABLE;
            } else {
                cmd = MediaEnum.Commands.SERVICES.RADIO.DISABLE;
            }

            MediaServerComp.sendMediaServerCommand(cmd);
        }


        reloadTable() {
            this.tableContent = [{
                rows: [{
                    content: {
                        title: _("media.service.webradio.show"),
                        active: this._isSwitchActive()
                    },
                    type: GUI.TableViewV2.CellType.SWITCH
                }]
            }];

            return super.reloadTable(...arguments);
        }


        _isSwitchActive() {
            return this.radios && this.radios.length > 0;
        }

        _updateRadios(radios) {
            if (!radios || JSON.stringify(this.radios) === JSON.stringify(radios)) {
                return;
            }

            this.radios = radios;
            this.tableView.cellForSectionAndRow(0, 0).setActive(this._isSwitchActive());
        }

        _reloadRadios(initial) {
            var result = MediaServerComp.getRadios();
            this.processAsync(result.promise, this._updateRadios.bind(this)); // to avoid "ghosts" only use the direct result if it was the initial response

            if (!!initial) {
                this._updateRadios(result.data);
            }
        }

    }

    Controls.AudioZoneControl.AudioZoneSettingsServiceRadioSettings = AudioZoneSettingsServiceRadioSettings;
});
