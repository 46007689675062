'use strict';
/*
title
iconSrc
iconColor,
activeIconColor,
textColor,
activeTextColor,
msIcon          whether or not to load the image from the Miniserver or the resourceCache
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.COLLECTION = "CollectionCell";

    class CollectionCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getCellContent = function getGeneralCellContent(content) {
                var img = content.hasOwnProperty('iconSrc') ? getTitleIcon(content.iconSrc, content.msIcon) : '';
                var txt = content.hasOwnProperty('title') ? content.title : '';
                return $(img + '<div class="content__title text-overflow-ellipsis">' + txt + '</div>');
            };

            var getTitleIcon = function getTitleIcon(src, msIcon) {
                return '<div class="content__icon-placeholder">' + getImage(!!msIcon ? Icon.DEFAULT : src, "icon-placeholder__icon") + '</div>';
            };

            var getImage = function getImage(src, classes) {
                return src.indexOf(".svg") !== -1 ? ImageBox.getResourceImageWithClasses(src, classes) : '<img class="' + classes + '" src="' + src + '">';
            };

            return {
                getCellContent: getCellContent
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("collection-cell");
            this.enabled = true;
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.contentPlaceholder.append(CollectionCell.Template.getCellContent(this.content));
            var titleIconCntr = this.element.find('.content__icon-placeholder');
            this.elements.titleIcon = this.element.find('.icon-placeholder__icon');
            this.elements.title = this.element.find(".content__title"); // colorize the default icon.

            this._setColor();

            if (this.content.msIcon) {
                ImageBox.getImage(this.content.iconSrc).then(function (image) {
                    if (image.mimeType === "svg") {
                        titleIconCntr.html(ImageBox.addClassesToSVG(image.image, 'icon-placeholder__icon'));
                    } else {
                        titleIconCntr.html('<img class="icon-placeholder__icon" src="' + image.image + '"/>');
                    }

                    this.elements.titleIcon = this.element.find('.icon-placeholder__icon');

                    this._setColor();
                }.bind(this));
            }
        }

        clickableAllowed() {
            return this.enabled;
        }

        rippleAllowed() {
            return this.enabled;
        }

        setEnabled(enabled) {
            this.enabled = enabled;
            this.element.toggleClass("disabled", !enabled);
        }

        setActive(active) {
            this._setColor(active);
        }

        onSelected() {
            super.onSelected();
        }

        // Private methods
        _setColor(active) {
            if (active) {
                if (this.content.activeIconColor) {
                    this.elements.titleIcon.css('fill', this.content.activeIconColor);
                }

                if (this.content.activeTextColor) {
                    this.elements.title.css("color", this.content.activeTextColor);
                }
            } else {
                if (this.content.iconColor) {
                    this.elements.titleIcon.css('fill', this.content.iconColor);
                }

                if (this.content.textColor) {
                    this.elements.title.css("color", this.content.textColor);
                }
            }
        }

    }

    GUI.TableViewV2.Cells.CollectionCell = CollectionCell;
    return GUI;
}(window.GUI || {});
