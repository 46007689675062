import {ControlContext, navigatorConfig, LxReactTableView} from "LxComponents";
import {useContext, useEffect, useMemo} from "react";
import WallboxManagerControlStatesSrc from "../wallboxManagerControlStatesSrc";
import globalStyles from "GlobalStyles";
import {useBackNavigation} from "LxComponents";


export default function WallboxManagerGroupScreen({navigation, route}) {
    const {control} = useContext(ControlContext)
    const {nodeUuid} = route.params;

    const node = useMemo(() => {
        return control.getNode(nodeUuid);
    }, [control, nodeUuid]);

    useBackNavigation(() => {
        navigation.goBack();
    }, []);

    useEffect(() => {
        navigation.setOptions({
            ...navigatorConfig({
                title: node ? node.title : "--"
            })
        })
    }, [node]);

    const getTableContent = () => {
        return control ?
            WallboxManagerControlStatesSrc.getScreenContent(nodeUuid, control.getChildNodes(nodeUuid), control, navigation)
            : [];
    }

    return <LxReactTableView tableContent={getTableContent()} containerStyle={globalStyles.customStyles.screenContent} />
}

