'use strict'; // TODO-thallth create a baseClass for InstructionBar + InstructionNotification!

class InstructionBar extends GUI.View {
    //region Static
    static Template = function () {
        var getTemplate = function getTemplate() {
            return $('<div class="instruction-bar">' + '   <div class="instruction-bar__text-label text-overflow-ellipsis clickable" />' + '   <div class="instruction-bar__flex-space" />' + '</div>');
        };

        return {
            getTemplate: getTemplate
        };
    }(); //endregion Static

    constructor(type) {
        super(InstructionBar.Template.getTemplate());
        this.element.addClass("instruction-bar--animated");
        document.documentElement.style.setProperty("--instruction-bar-height", "50px")
        this.type = type;
    }

    viewDidLoad() {
        super.viewDidLoad(...arguments);
        this.elements = {
            textLbl: this.element.find(".instruction-bar__text-label")
        };
    }

    viewDidAppear() {
        super.viewDidAppear(...arguments);

        if (this.onBarClicked) {
            this._boundClicked = this.onBarClicked.bind(this);
            this.element.on("click", this._boundClicked);
        }
    }

    viewDidDisappear(viewRemainsVisible) {
        if (this._boundClicked) {
            this.element.off("click", this._boundClicked);
            this._boundClicked = null;
        }

        document.documentElement.style.setProperty("--instruction-bar-height", "0px")

        super.viewDidDisappear(viewRemainsVisible);
    }

    addButton(iconSrc, btnClass) {
        var btn = $('<div>' + ImageBox.getResourceImageWithClasses(iconSrc, "button__icon clickable") + '</div>');
        btn.addClass("instruction-bar__button " + btnClass);
        this.element.append(btn);
        return btn;
    }

    setText(t) {
        this.elements.textLbl.text(t);
    }

    // Private methods
    _onCancel() {
        if (this.type === Interaction.NFC.WRITE_POSITION || this.type === Interaction.QR.WRITE_POSITION || this.type === Interaction.QuickAction.WRITE_POSITION) {
            NavigationComp.stopActivity(GUI.ActiveMiniserverViewController.Activity.Position);
        } else if (this.type === Interaction.NFC.WRITE_COMMAND || this.type === Interaction.QR.WRITE_COMMAND || this.type === Interaction.QuickAction.WRITE_COMMAND) {
            NavigationComp.stopActivity(GUI.ActiveMiniserverViewController.Activity.Command);
        }
    }

}

GUI.InstructionBar = InstructionBar;
