'use strict';

define([], function () {
    return class GroupSelectionScreen extends GUI.Screen {
        constructor() {
            super($('<div class="group-selection-screen" />'));
            this.rooms = ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.ROOM);
            this.cats = ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.CATEGORY);
            this.currentGroupType = GroupTypes.ROOM;
            var settings = PersistenceComponent.getMiniserverSettings(ActiveMSComponent.getMiniserverSerialNo());
            this.selectionSource = settings.manualFavorites.favorites;
            this.initialSelection = JSON.stringify(this.selectionSource);
        }

        viewDidLoad() {
            super.viewDidLoad();
            var groupNames = ActiveMSComponent.getStructureManager().getCustomGroupTitles();

            this._titleBar.setSegmentInfo([{
                type: 'label',
                text: groupNames[GroupTypes.ROOM].toUpperCase()
            }, {
                type: 'label',
                text: groupNames[GroupTypes.CATEGORY].toUpperCase()
            }]);

            this._titleBar.onSegmentSelected = function (number) {
                this.pageView.setActivePage(number);
            }.bind(this); // groups


            this.roomList = new GUI.TableViewV2(this, this);
            this.catList = new GUI.TableViewV2(this, this);
            this.addToHandledSubviews(this.roomList);
            this.addToHandledSubviews(this.catList); // pageView

            var pages = [this.roomList.getElement(), this.catList.getElement()];
            this.pageView = new GUI.LxPageView(pages, this._onPageProgress.bind(this), this._onPageAnimationEnd.bind(this), {
                overscroll: false
            });
            this.pageView.disableUserInteraction(true);
            var pageIdx = this.currentGroupType === GroupTypes.CATEGORY ? 1 : 0; // default or none is room

            this._titleBar.setSegmentActive(pageIdx);

            this.pageView.setActivePage(pageIdx);

            if (this.currentGroup) {
                if (this.currentGroupType === GroupTypes.ROOM) {
                    this.roomList.selectGroup(this.currentGroup);
                } else {
                    this.catList.selectGroup(this.currentGroup);
                }
            }

            this.appendSubview(this.pageView);
            this.roomList.reload();
            this.catList.reload();
        }

        getURL() {
            return "GroupSelection";
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarConfig() {
            return {
                subTitleBar: 'segmented'
            };
        }

        titleBarText() {
            return _("manual-favorites.select-favorites");
        }

        titleBarAction() {
            this._storeSelection();

            this.ViewController.navigateBack();
        }

        // Private methods
        _onPageProgress(percent) {//this.pageView.slidePageToPercent(percent);
            //this._titleBar.setSegmentProgress(percentage);
        }

        _onPageAnimationEnd(pageIdx) {
            this._titleBar.setSegmentActive(pageIdx);

            this.currentGroupType = pageIdx === 0 ? GroupTypes.ROOM : GroupTypes.CATEGORY;
        }

        // TableViewDataSource Methods
        styleForTableView() {
            return TableViewStyle.GROUPED;
        }

        numberOfSections() {
            return 1;
        }

        numberOfRowsInSection(section, tableView) {
            if (tableView === this.roomList) {
                return this.rooms.length;
            } else {
                return this.cats.length;
            }
        }

        cellTypeForCellAtIndex() {
            return GUI.TableViewV2.CellType.CHECKABLE_RATING;
        }

        contentForCell(cell, section, row, tableView) {
            var name,
                checked,
                rating = 0;

            if (tableView === this.roomList) {
                var room = this.rooms[row];
                name = room.name;
                checked = typeof this.selectionSource.rooms[room.uuid] === "number";

                if (checked) {
                    rating = this.selectionSource.rooms[room.uuid];
                }
            } else {
                var cat = this.cats[row];
                name = cat.name;
                checked = typeof this.selectionSource.cats[cat.uuid] === "number";

                if (checked) {
                    rating = this.selectionSource.cats[cat.uuid];
                }
            }

            return {
                title: name,
                disclosureIcon: true,
                selected: checked,
                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE,
                rating: rating
            };
        }

        // TableViewDelegate Methods
        didSelectCell(cell, section, row, tableView) {
            var groupUUID;

            if (tableView === this.roomList) {
                groupUUID = this.rooms[row].uuid;
            } else {
                groupUUID = this.cats[row].uuid;
            }

            this.ViewController.showState(ScreenState.ControlSelection, null, {
                groupUUID: groupUUID,
                selectionSource: this.selectionSource
            });
        }

        didCheckCell(cell, section, row, tableView, selected) {
            if (tableView === this.roomList) {
                if (selected) {
                    this.selectionSource.rooms[this.rooms[row].uuid] = 0;
                } else {
                    delete this.selectionSource.rooms[this.rooms[row].uuid];
                }
            } else {
                if (selected) {
                    this.selectionSource.cats[this.cats[row].uuid] = 0;
                } else {
                    delete this.selectionSource.cats[this.cats[row].uuid];
                }
            }
        }

        didChangeRating(rating, section, row, tableView) {
            if (tableView === this.roomList) {
                this.selectionSource.rooms[this.rooms[row].uuid] = rating;
            } else {
                this.selectionSource.cats[this.cats[row].uuid] = rating;
            }
        }

        /**
         * Validates if the selection has changed. If so, stores the new manualFavorites and informs the App.
         * @private
         */
        _storeSelection() {
            if (this.initialSelection !== JSON.stringify(this.selectionSource)) {
                PersistenceComponent.setManualFavorites(this.selectionSource);
                NavigationComp.dispatchEventToUI(NavigationComp.UiEvents.FavoritesChanged);
            }
        }

    };
});
