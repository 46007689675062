'use strict';

/**
 * @param details   an object containing all the important infos for this screen.
 *      title:      the name for the selector screen, shown in the titlebar
 *      subtitle:   Optional subtitle
 *      options:    an array of options to pick from. At most one is selected (boolean flag). title & subtitle are passed along.
 *      cellType:   Type of cell that should be used. Optional, GeneralCell will be used as fallback
 *      deferred:   when an option was picked, it will resolve the deferred with the index of the option.
 *      mode:       how the screen will respond to selection changes. Default: GUI.SelectorScreenMode.Normal
 *      icon:       The src for the main top centered icon to be shown by default. (only shown if noIcon isn't set)
 */

class ComfortModeSelectorScreen extends GUI.SelectorScreen {
    constructor(details) {
        super(...arguments);
        this.element.addClass("comfort-mode-selector-screen");
        this.subtitle = details.subtitle;
        this.cellType = details.cellType;
        this.noIcon = details.noIcon;

        if (!this.noIcon && details.icon) {
            this.icon = details.icon;
        } else {
            this.icon = false;
        }
    }

    viewDidLoad() {
        return super.viewDidLoad(...arguments).then(function () {
            this.setUpComfortUI(!this.noIcon);
            this.setTitle(this.title);
            this.setSubtitle(this.subtitle);

            if (this.icon) {
                this.setIcon(this.icon);
            }
        }.bind(this));
    }

    titleBarText() {
        return null; //hide titlebar title because title label is already set in viewDidLoad()
    }

    styleForTableView() {
        return TableViewStyle.TRANSLUCENT;
    }

    getAnimation() {
        return AnimationType.FADE;
    }

    closeAction() {
        this.titleBarAction.call(this);
    }

    didSelectCell(cell, section, row) {
        return this._selectCell(cell).then(function () {
            if (this.mode === GUI.SelectorScreenMode.QUICK) {
                this._resolveWithSelectedRows();

                this.ViewController.navigateBack();
            }
        }.bind(this));
    }

    _createOptionCell(option) {
        var cell = {};
        cell.content = cloneObject(option);
        cell.type = this.cellType;
        return cell;
    }

    /**
     * Iterates over all cells of the tableView
     * @param selectCell
     * @returns Q.Promise<any>
     * @private
     */
    _selectCell(selectCell) {
        return this.tableView.table.reduce(function (prevSectionPrms, section, sectionIndex) {
            return prevSectionPrms.then(function () {
                return section.reduce(function (prevRowPrms, row, rowIndex) {
                    return prevRowPrms.then(function () {
                        var cell = this.tableView.table[sectionIndex][rowIndex];
                        return cell.setActive.call(cell, cell === selectCell);
                    }.bind(this));
                }.bind(this), Q.resolve());
            }.bind(this));
        }.bind(this), Q.resolve());
    }

    _getSelectedCells() {
        return this.tableView.table[0].filter(function (cell) {
            return cell.getElement().hasClass("active");
        });
    }

}

GUI.ComfortModeSelectorScreen = ComfortModeSelectorScreen;
/**
 * Specifies how the selector will respond.
 * @type {{QUICK: string, NORMAL: string}}
 */

GUI.ComfortModeSelectorScreenMode = {
    QUICK: GUI.SelectorScreenMode.QUICK,
    // the deferred resolves after one item has been selected.
    NORMAL: GUI.SelectorScreenMode.NORMAL // the user can pick an option. The deferred is not resolved until the view is dismissed using the titlebar

};
