'use strict';

define([], function () {
    return class AutomaticDesignerSceneVisuEditScreen extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div/>'));
            this.details = details;
            this.groupList = [];
            this._userManagementPermissionInfo = ActiveMSComponent.getRequiredUserManagementPermissionInfos();
            this._hasUserManagementPermissions = this._userManagementPermissionInfo.hasUserManagementPermissions;
        }

        getPermissions() {
            var perms = [{
                permission: MsPermission.EXPERT_MODE_LIGHT,
                revoke: true
            }];

            if (this._hasUserManagementPermissions) {
                perms = perms.concat(this._userManagementPermissionInfo.requiredPermissions.map(function (permission) {
                    return {
                        permission: permission,
                        revoke: true
                    };
                }));
            }

            return perms;
        }

        viewDidLoad() {
            var args = arguments;
            return CommunicationComponent.sendViaHTTP(Commands.GET_ALL_ICONS).then(symbols => {
                this.symbols = symbols;
                return super.viewDidLoad(...args).then(function () {
                    if (this._hasUserManagementPermissions) {
                        return ActiveMSComponent.getGroupList().then(function (groupList) {
                            this.groupList = groupList;
                            return this.reloadTable();
                        }.bind(this));
                    }

                    return Q.resolve();
                }.bind(this));
            });
        }

        titleBarText() {
            return _("visu");
        }

        setTableContent() {
            this.tableContent = this._getTableContent();
            return super.setTableContent(...arguments);
        }

        _getTableContent() {
            var currentRoom = ActiveMSComponent.getStructureManager().getGroupByUUID(this.details.rule.visu.room),
                currentCat = ActiveMSComponent.getStructureManager().getGroupByUUID(this.details.rule.visu.cat),
                tableContent = [{
                    headerTitle: "",
                    headerDescription: _("scenes.visu.desc"),
                    rows: [{
                        type: GUI.TableViewV2.CellType.SWITCH,
                        content: {
                            title: _("visu.use"),
                            active: this.details.rule.visu.useVisu
                        },
                        onSwitchChanged: function (active) {
                            this.details.rule.visu.useVisu = active;
                        }.bind(this)
                    }]
                }, {
                    headerTitle: _("visu"),
                    rows: [{
                        type: GUI.TableViewV2.CellType.INPUT,
                        content: {
                            title: _("description"),
                            value: this.details.rule.visu.desc,
                            placeholder: nullEmptyString(this.details.rule.visu.desc) || "",
                            autoFocus: false
                        },
                        textChanged: function (section, row, tableView, value) {
                            this.details.rule.visu.desc = value;
                        }.bind(this)
                    }, {
                        content: {
                            title: _("icon"),
                            rightIconSrc: nullEmptyString(this.details.rule.visu.icon) || Icon.AutomaticDesigner.SCENE,
                            clickable: true,
                            class: "base-cell--with-chevron"
                        },
                        action: function () {
                            this._selectFromDataSetWithTitle(this.symbols.map(function (symbolObj) {
                                symbolObj.iconSrc = symbolObj.id;
                                return symbolObj;
                            }).concat([{
                                id: undefined,
                                name: _("scenes.name"),
                                iconSrc: Icon.AutomaticDesigner.SCENE
                            }]).sort(function (a, b) {
                                return compareObjByName(a, b);
                            }), _("icon"), "icon");
                        }.bind(this)
                    }, {
                        type: GUI.TableViewV2.CellType.RATING,
                        content: {
                            rating: this.details.rule.visu.rating
                        },
                        didChangeRating: function (cell, section, row, tableView, rating, dragging) {
                            this.details.rule.visu.rating = rating;
                        }.bind(this)
                    }, {
                        type: GUI.TableViewV2.CellType.SWITCH,
                        content: {
                            title: _("favorites.show-as"),
                            active: this.details.rule.visu.useFav
                        },
                        onSwitchChanged: function (active) {
                            this.details.rule.visu.useFav = active;
                        }.bind(this)
                    }, {
                        content: {
                            title: ActiveMSComponent.getStructureManager().getCustomGroupTitles()[GroupTypes.ROOM],
                            disclosureText: currentRoom ? currentRoom.name : null,
                            clickable: true,
                            class: "base-cell--with-chevron"
                        },
                        action: function () {
                            this._selectFromDataSetWithTitle(Object.values(ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.ROOM, true)).map(function (group) {
                                return {
                                    id: group.uuid,
                                    name: group.name
                                };
                            }), ActiveMSComponent.getStructureManager().getCustomGroupTitles()[GroupTypes.ROOM], "room");
                        }.bind(this)
                    }, {
                        content: {
                            title: ActiveMSComponent.getStructureManager().getCustomGroupTitles()[GroupTypes.CATEGORY],
                            disclosureText: currentCat ? currentCat.name : null,
                            clickable: true,
                            class: "base-cell--with-chevron"
                        },
                        action: function () {
                            this._selectFromDataSetWithTitle(Object.values(ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.CATEGORY, true)).map(function (group) {
                                return {
                                    id: group.uuid,
                                    name: group.name
                                };
                            }), ActiveMSComponent.getStructureManager().getCustomGroupTitles()[GroupTypes.CATEGORY], "cat");
                        }.bind(this)
                    }]
                }];

            if (this._hasUserManagementPermissions && this.groupList.length) {
                tableContent.push({
                    headerTitle: _("visu.allowed-user-groups"),
                    rows: (this.details.rule.permissions.groups || []).map(function (groupUuid, groupIdx) {
                        var group = this.groupList.find(function (group) {
                            return group.uuid === groupUuid;
                        });
                        return {
                            content: {
                                title: group.name,
                                leftIconSrc: Icon.USER_MANAGEMENT.GROUP,
                                leftIconColor: window.Styles.colors.stateActive
                            }
                        };
                    }.bind(this)).concat([{
                        content: {
                            title: _("usergroups.title"),
                            clickable: true,
                            class: "base-cell--with-chevron"
                        },
                        action: function () {
                            var def = Q.defer(),
                                details = {
                                    options: this.groupList.map(function (group) {
                                        return {
                                            title: group.name,
                                            selected: (this.details.rule.permissions.groups || []).includes(group.uuid),
                                            clickable: true,
                                            ogObj: group
                                        };
                                    }.bind(this)),
                                    title: _("usergroups.select"),
                                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE,
                                    mode: GUI.SelectorScreenMode.CONFIRMED,
                                    deferred: def
                                };
                            return NavigationComp.showSelector(details).then(function (results) {
                                this.details.rule.permissions.groups = results.map(function (selectedOption) {
                                    return selectedOption.option.ogObj.uuid;
                                });
                                this.reloadTable();
                            }.bind(this));
                        }.bind(this)
                    }]),
                    footerTitle: _("scenes.visu.permissions.desc")
                });
            }

            return tableContent;
        }

        _selectFromDataSetWithTitle(dataSet, title, visuKey) {
            this.details.rule.visu = this.details.rule.visu || {};
            var currentId = this.details.rule.visu[visuKey],
                def = Q.defer(),
                details = {
                    options: dataSet.map(function (data) {
                        return {
                            title: data.name,
                            selected: data.id === currentId,
                            rightIconSrc: data.iconSrc,
                            clickable: true,
                            ogObj: data
                        };
                    }),
                    title: title,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                    mode: GUI.SelectorScreenMode.QUICK,
                    deferred: def
                };
            return NavigationComp.showSelector(details).then(function (results) {
                this.details.rule.visu[visuKey] = results[0].option.ogObj.id;
                this.reloadTable();
            }.bind(this));
        }

    };
});
