import {colors} from "./colors";
import {shadows} from "./shadows";
import {fontSettings, textStyles} from "./text"
import {spacings} from "./spacings";
import {sizes} from "./sizes";
import {borders} from "./borders";
import {customStyles} from "./customStyles";

const globalStyles = {
    colors,
    shadows,
    fontSettings,
    textStyles,
    spacings,
    sizes,
    borders,
    customStyles
}

export default globalStyles;
