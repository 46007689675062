'use strict';

define("ClimateUsControl", ["Control", "ClimateUsControlEnums"], function (Control, CtrlEnums) {
    return class ClimateUsControl extends Control {
        constructor() {
            super(...arguments);
        }

        // ------------------------------------------------------------------------------
        // Convenience methods for functionality
        // ------------------------------------------------------------------------------
        isEmergencyHeaterControllable() {
            return this.hasEmergencyHeater() && !this.isEmergencyHeaterControlledByLogic();
        }

        canHeat() {
            return hasBit(this.details.capabilities, CtrlEnums.Capabilities.HEATING);
        }

        canCool() {
            return hasBit(this.details.capabilities, CtrlEnums.Capabilities.COOLING);
        }

        canHeatAndCool() {
            return this.canHeat() && this.canCool();
        }

        // ------------------------------------------------------------------------------
        // Checking for connected inputs
        // ------------------------------------------------------------------------------
        isInputConnected(inputBit) {
            return hasBit(this.details.connectedInputs, inputBit);
        }

        areOpModesControlledByLogic() {
            return this.isInputConnected(CtrlEnums.ConnectedInputs.MODE);
        }

        hasOutsideTemperature() {
            return this.getStates().outdoorTempInfo === CtrlEnums.OutdoorTempInfo.OK;
        }

        usesOutsideTemperature() {
            return this.getStates().outdoorTempMode !== CtrlEnums.OutdoorTempMode.NOT_USED;
        }

        // ------------------------------------------------------------------------------
        // Checking for connected parameters
        // ------------------------------------------------------------------------------
        isParamSetByLogic(paramFlag) {
            if (!this.details.hasOwnProperty("connectedParameters")) {
                return false;
            }
            return hasBit(this.details.connectedParameters, paramFlag);
        }

        get isHeatingLimitSetByLogic() {
            return this.isParamSetByLogic(CtrlEnums.ConnectedParameters.HEATING_LIMIT);
        }

        get isCoolingLimitSetByLogic() {
            return this.isParamSetByLogic(CtrlEnums.ConnectedParameters.COOLING_LIMIT);
        }

        getOutsideTempModeName() {
            var temp = "",
                states = this.getStates();
            switch (states.outdoorTempMode) {
                case CtrlEnums.OutdoorTempMode.AVG_FORECAST_48H:
                    temp = _("controls.climate.tempmode.expected48")
                    break;
                case CtrlEnums.OutdoorTempMode.AVG_PAST_48H:
                    temp = _("controls.climate.tempmode.past48")
                    break;
                case CtrlEnums.OutdoorTempMode.CURR_TEMP:
                    temp = _("controls.climate.tempmode.current")
                    break;
                default:
                    break;
            }
            return temp;
        }

        isBoostControlledByLogic() {
            return this.isInputConnected(CtrlEnums.ConnectedInputs.BOOST);
        }

        isEmergencyHeaterControlledByLogic() {
            return this.isInputConnected(CtrlEnums.ConnectedInputs.EMERGENCY_HEATER);
        }

        // ------------------------------------------------------------------------------
        // Checking for connected outputs
        // ------------------------------------------------------------------------------
        isOutputConnected(inputBit) {
            return hasBit(this.details.connectedOutputs, inputBit);
        }

        hasHeatStage1() {
            return this.isOutputConnected(CtrlEnums.ConnectedOutputs.HEAT_STAGE_1);
        }

        hasHeatStage2() {
            return this.isOutputConnected(CtrlEnums.ConnectedOutputs.HEAT_STAGE_2);
        }

        hasCompressor() {
            return this.isOutputConnected(CtrlEnums.ConnectedOutputs.COMPRESSOR);
        }

        hasCoolStage2() {
            return this.isOutputConnected(CtrlEnums.ConnectedOutputs.COOL_STAGE_2);
        }

        hasEmergencyHeater() {
            return this.isOutputConnected(CtrlEnums.ConnectedOutputs.HEAT_EMERGENCY);
        }

        hasReversingValve() {
            return this.isOutputConnected(CtrlEnums.ConnectedOutputs.REVERSING_VALVE);
        }

        hasFan() {
            return this.isOutputConnected(CtrlEnums.ConnectedOutputs.FAN);
        }

        hasHumdifier() {
            return this.isOutputConnected(CtrlEnums.ConnectedOutputs.HUMIDIFIER);
        }
        
        // ------------------------------------------------------------------------------
        // Checking for available capabilities
        // ------------------------------------------------------------------------------
        
        get hasHeatingCapability() {
            return hasBit(this.details.capabilities, CtrlEnums.Capabilities.HEATING) || false;
        }

        get hasCoolingCapability() {
            return hasBit(this.details.capabilities, CtrlEnums.Capabilities.COOLING) || false;
        }

        get hasEmergencyHeatCapability() {
            return hasBit(this.details.capabilities, CtrlEnums.Capabilities.EMERGENCY_HEAT) || false;
        }

        get hasFanCapability() {
            return hasBit(this.details.capabilities, CtrlEnums.Capabilities.FAN) || false;
        }

        get hasSwitchOnThresholdCapability() {
            return hasBit(this.details.capabilities, CtrlEnums.Capabilities.SWITCH_ON_THRESHOLD) || false;
        }

        get hasServiceModeCapability() {
            return hasBit(this.details.capabilities, CtrlEnums.Capabilities.SERVICE_MODE) || false;
        }

    };
});
