'use strict';

define([
    'LxComponents',
    'IconLib'
], function (
    {
        ReactWrapper,
        LxReactSwitch
    },
    {
        default: Icons
    }
) {
    return class TableViewTestScreen extends GUI.TableViewScreenV2 {
        constructor() {
            super($('<div/>'));
        }

        getTableView() {
            return new GUI.EditableTableView(this.tableViewDataSource, this.tableViewDelegate);
        }

        setTableContent() {
            this.tableContent = [
                {
                    headerTitle: "headerTitle",
                    rows: [
                        {
                            content: {
                                title: "1"
                            }
                        },
                        {
                            content: {
                                title: "2"
                            }
                        },
                        {
                            content: {
                                title: "3"
                            }
                        },
                        {
                            content: {
                                title: "4"
                            }
                        },
                        {
                            content: {
                                title: "5"
                            }
                        },
                        {
                            content: {
                                title: "6"
                            }
                        },
                        {
                            type: window.GUI.TableViewV2.CellType.SLIDER,
                            content: {
                                title: window.GUI.TableViewV2.CellType.SLIDER,
                                disclosureText: "0",
                                disclosureColor: Color.TEXT_INACTIVE,
                                value: 0,
                                minIconSrc: true,
                                maxIconSrc: true
                            }
                        }
                    ]
                },
                {
                    headerTitle: "Clickable headerTitle",
                    rows: [
                        {
                            content: {
                                title: "Just a dummy cell"
                            }
                        }
                    ],
                    didSelectHeader() {
                        alert("hi");
                    }
                },
                {
                    headerTitle: "Clickable headerTitle",
                    sectionRightButtonTitle: "Test",
                    rows: [
                        {
                            content: {
                                title: "Just a dummy cell"
                            }
                        }
                    ],
                    didSelectHeader() {
                        alert("hi");
                    },
                    rightSectionButtonTapped() {
                        alert("Pressed on Button")
                    }
                },
                {
                    headerTitle: "headerTitle",
                    sectionRightButtonTitle: "Test",
                    rows: [
                        {
                            content: {
                                title: "Just a dummy cell"
                            }
                        }
                    ],
                    rightSectionButtonTapped() {
                        alert("Pressed on Button")
                    }
                },
                {
                    headerContent: {
                        message: "This is a HeaderContent",
                        align: "center",
                        color: "hotpink"
                    },
                    rows: [
                        {
                            content: {
                                title: "Just a dummy cell"
                            }
                        }
                    ]
                },
                {
                    headerElement: $("<div/>"), // Should not be rendered
                    rows: [
                        {
                            content: {
                                title: "Just a dummy cell"
                            }
                        }
                    ]
                },
                {
                    headerElement: ReactWrapper.React.createElement(LxReactSwitch),
                    rows: [
                        {
                            content: {
                                title: "Just a dummy cell"
                            }
                        }
                    ]
                },
                {
                    headerStrongTitle: "Strong",
                    rows: [
                        {
                            content: {
                                title: "Just a dummy cell"
                            }
                        }
                    ]
                },
                {
                    headerStrongTitle: "Strong",
                    headerDescription: "This is a description",
                    rows: [
                        {
                            content: {
                                title: "Just a dummy cell"
                            }
                        }
                    ]
                },
                {
                    headerStrongTitle: "Strong",
                    headerDescription: "This is a description",
                    sectionRightButtonTitle: "Test",
                    rows: [
                        {
                            content: {
                                title: "Just a dummy cell"
                            }
                        }
                    ],
                    rightSectionButtonTapped() {
                        alert("Pressed on Button")
                    }
                },
                {
                    headerBoldTitle: "Bold",
                    rows: [
                        {
                            content: {
                                title: "Just a dummy cell"
                            }
                        }
                    ]
                },
                {
                    headerBoldTitle: "Bold",
                    headerDescription: "This is a description",
                    rows: [
                        {
                            content: {
                                title: "Just a dummy cell"
                            }
                        }
                    ]
                },
                {
                    rows: [
                        {
                            content: {
                                title: "Just a dummy cell"
                            }
                        }
                    ],
                    footerTitle: "FooterTitle"
                },
                {
                    rows: [
                        {
                            content: {
                                title: "Just a dummy cell"
                            }
                        }
                    ],
                    footerTitle: "Clickable FooterTitle",
                    didSelectFooter() {
                        alert("Hi")
                    }
                },
                {
                    rows: [
                        {
                            content: {
                                title: "Just a dummy cell"
                            }
                        }
                    ],
                    footerElement: $("<div/>"), // Should not be rendered
                },
                {
                    rows: [
                        {
                            content: {
                                title: "Just a dummy cell"
                            }
                        }
                    ],
                    footerElement: ReactWrapper.React.createElement(LxReactSwitch)
                },
                {
                    rows: [
                        {
                            content: {
                                title: "Just a dummy cell"
                            }
                        }
                    ],
                    footer: {
                        title: "FooterTitle",
                        message: "FooterMessage",
                        iconSrc: Icon.WARNING
                    }
                },
                {
                    rows: [
                        {
                            content: {
                                title: "Just a dummy cell"
                            }
                        }
                    ],
                    footer: {
                        title: "FooterTitle",
                        message: "FooterMessage",
                        iconSrc: Icons.LoxoneHeart
                    }
                }
            ]
            return super.setTableContent(...arguments);
        }

        titleBarText() {
            return "TableViewTest";
        }

        viewDidAppear() {
            this.tableView.setEditingModeForSection(0, true, true, true);
            return super.viewDidAppear(...arguments);
        }

    };
});
