'use strict';

define([], function () {
    return class BackupAndSyncViewController extends GUI.ModalViewController {
        constructor(details) {
            super();
            this.type = details.type;
        }

        getAnimation() {
            return AnimationType.NONE;
        }

        getShouldBlockScreenSaver() {
            return true;
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                // Go to settings or backupAndSync welcome screen
                return PersistenceComponent.backupAndSyncICloudStatus().then(function (response) {
                    if (this.type === BackupAndSync.MENU) {
                        // Go here if we come from the menu entry
                        if (!PersistenceComponent.backupAndSyncIsEnabled()) {
                            // Syncing not activated -> Welcome Screen
                            var def = Q.defer(),
                                details = {
                                    iconSrc: Icon.BACKUP_AND_SYNC,
                                    iconColor: window.Styles.colors.brand,
                                    title: _("backup-and-sync.welcome.title.icloud"),
                                    message: _("backup-and-sync.welcome.message.icloud"),
                                    continueBtnText: _("backup-and-sync.welcome.continue-button-text"),
                                    continueDef: def
                                };
                            def.promise.done(function (viaButton) {
                                this.dismiss();
                            }.bind(this));
                            return this.showState(ScreenState.BackupAndSyncWelcome, null, details, AnimationType.NONE);
                        } else {
                            // Syncing already activated -> Settings screen
                            var details = {
                                miniservers: PersistenceComponent.getAllMiniserver()
                            };
                            return this.showState(ScreenState.BackupAndSyncSettings, null, details, AnimationType.NONE);
                        }
                    } else {
                        // Go here if we come from the archive popup screen to restore a backup
                        var platform = PlatformComponent.getPlatformInfoObj().platform,
                            def = Q.defer(),
                            details = {
                                iconSrc: Icon.BACKUP_AND_SYNC,
                                iconColor: window.Styles.colors.brand,
                                title: _("backup-and-sync.restore.title"),
                                continueBtnText: _("continue"),
                                continueDef: def
                            };

                        if (platform === PlatformType.IOS || platform === PlatformType.DeveloperInterface) {
                            details.message = _("backup-and-sync.restore.message.icloud");
                        } else {
                            details.message = _("backup-and-sync.restore.message.google");
                        }

                        def.promise.done(function (viaButton) {
                            this.dismiss();
                        }.bind(this));
                        return this.showState(ScreenState.BackupAndSyncRestore, null, details, AnimationType.NONE);
                    }
                }.bind(this), function (error) {
                    this.showErrorPopup(_("backup-and-sync.permission.title.icloud"), error, window.Styles.colors.green).then(function () {
                        this.ViewController.dismiss();
                    }.bind(this));
                }.bind(this));
            }.bind(this));
        }

        destroyOnBackNavigation() {
            return true;
        }

        showErrorPopup(title, message, color) {
            var error = _("error.ocured"),
                details = {
                    title: title,
                    message: error,
                    buttonOk: _('ok'),
                    buttonCancel: false,
                    color: window.Styles.colors.red
                };

            if (color) {
                details.color = color;
            }

            if (message) {
                details.message = message;
            }

            return NavigationComp.showPopup(details, PopupType.GENERAL);
        }

    };
});
