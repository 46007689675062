import * as React from "react"
import Svg, { Path } from "react-native-svg"

function ArrowLeftRight(props) {
    return (
        <Svg
            isreact="true"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M22.278 9.714L17.783 5.22l-.084-.073a.75.75 0 00-.977.073l-.073.084a.75.75 0 00.073.976l3.218 3.217H2.243l-.102.008a.75.75 0 00-.648.743l.006.101A.751.751 0 002.25 11h19.5a.75.75 0 00.528-1.286zM22.507 13.75l-.007-.1a.75.75 0 00-.75-.65H2.25a.75.75 0 00-.528 1.284l4.5 4.497.084.072a.75.75 0 00.977-.073l.073-.084a.75.75 0 00-.073-.977L4.06 14.5h17.716a.724.724 0 00.047-.004l.035-.002a.75.75 0 00.648-.743z"
            />
        </Svg>
    )
}

export default ArrowLeftRight
