'use strict';
import { useDeviceOrientation } from "LxComponents";

define("SystemSchemeControlContent", ["ControlActionScreen", "SubControlView"], function (ControlActionScreen, SubControlView) {
    return class SystemSchemeControlContent extends ControlActionScreen {
        //region Static
        static Template = function template() {
            var getTemplate = function getTemplate(imageSrc, schemeSize) {
                return $('<div class="system-scheme-control-content__background-wrapper">' + '   <div class="background-wrapper__container">' + '       <img src="' + imageSrc + '" width="' + schemeSize.width + '" height="' + schemeSize.height + '" class="container__image">' + '   </div>' + '</div>' + '<div class="system-scheme-control-content__button-container">' + '    ' + ImageBox.getResourceImageWithClasses(Icon.SystemScheme.Reset, 'button-container__reset-button') + '</div> ');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor() {
            super(...arguments);
        }

        supportedOrientation() {
            return DeviceOrientation.ANY;
        }

        viewDidLoad() {
            var basePromise = super.viewDidLoad(...arguments) || true;
            return Q.all([basePromise]).then(() => {
                return this.control.imagePrms.then(image => {
                    this.image = image;
                    if (image) {
                        this.elements.content.append(SystemSchemeControlContent.Template.getTemplate(image, this.control.details.schemeSize));
                        this.elements.backgroudWrapper = this.element.find('.system-scheme-control-content__background-wrapper');
                        this.elements.container = this.element.find('.background-wrapper__container');
                        this.elements.resetButton = this.element.find('.button-container__reset-button');
                        this.buttons = {
                            reset: new GUI.LxButton(this, this.elements.resetButton[0], Color.BUTTON_GLOW)
                        };
                        this.addToHandledSubviews(this.buttons.reset);
                        this.buttons.reset.onButtonTapped = this._handleResetButtonTapped.bind(this);

                        return this._presentImage();
                    } else {
                        return this.appendSubview(
                            new GUI.BackgroundView(
                                Icon.Buttons.CLOSE,
                                _('system-scheme.error-title.not-loaded'),
                                ""
                            ), this.elements.content
                        );
                    }
                }, () => {
                    return this.appendSubview(
                        new GUI.BackgroundView(
                            Icon.Buttons.CLOSE,
                            _('system-scheme.error-title.no-image.title'),
                            _('system-scheme.error-title.no-image.message')
                        ), this.elements.content
                    );
                })
            });
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(() => {
                if (this.image) {
                    this.viewportWidth = this.elements.backgroudWrapper.width();
                    this.viewportHeight = this.elements.backgroudWrapper.height();
                    this.defaultImageWidth = this.control.details.schemeSize.width;
                    this.defaultImageHeight = this.control.details.schemeSize.height;
                    this.registerForResize();

                    if (!this.zoomHandler) {
                        this.zoomHandler = panzoom(this.elements.container[0], {
                            maxZoom: 3,
                            bounds: true,
                            zoomDoubleClickSpeed: 1, // Disables double click zoom
                            onTouch: function(e) {
                                // `e` - is current touch event.
                            
                                return false; // tells the library to not preventDefault.
                            }
                        });

                        this._setMinZoom();

                        this._calcDefaultPosition();

                        this.zoomHandler.zoomAbs(0, 0, this.minZoom); // Centers the image if the image is smaller than the viewport

                        this.zoomHandler.moveTo(this.defaultXPos, this.defaultYPos);
                    } // resume is not working on iOS and Android
                    // see issue: https://github.com/anvaka/panzoom/issues/43

                    /*if (this.zoomHandler.isPaused()) {
                        this.zoomHandler.resume();
                    }*/


                    if (this.zoomHandler && this.scrollTop && this.scrollLeft && this.savedScale) {
                        this.zoomHandler.smoothZoomAbs(this.scrollLeft, this.scrollTop, this.savedScale);
                    }
                }
            });
        }

        viewDidAppear() {
            var promise = super.viewDidAppear(...arguments);
            this.element.toggleClass("systems-scheme-control-content--visible", true);
            return promise;
        }

        viewWillDisappear() {
            var transform;

            if (this.zoomHandler) {
                transform = this.zoomHandler.getTransform();
                this.scrollTop = transform.y;
                this.scrollLeft = transform.x;
                this.savedScale = transform.scale; //this.zoomHandler.pause();
            }

            this.element.toggleClass("systems-scheme-control-content--visible", false);
            super.viewWillDisappear(...arguments);
        }

        destroy() {
            if (this.zoomHandler) {
                this.zoomHandler.dispose();
            }
            super.destroy(...arguments);

            useDeviceOrientation().set();
        }

        onResize() {
            var transform = this.zoomHandler.getTransform(),
                currentScale = transform.scale,
                isOnInitialZoom = currentScale === this.minZoom;
            this.viewportWidth = this.elements.backgroudWrapper.width();
            this.viewportHeight = this.elements.backgroudWrapper.height();

            this._setMinZoom();

            this._calcDefaultPosition();

            if (isOnInitialZoom) {
                this._resetZoom();
            }
        }

        setSubtitle() {
            return super.setSubtitle();
        }

        shouldDisplayStateIcons() {
            return false;
        }

        _createPageObject(subControl, isTextObject) {
            var view = new SubControlView(subControl, isTextObject);
            view.element.addClass("container__subview");
            return this.appendSubview(view, this.elements.container);
        }

        _presentImage() {
            var schemeSize = this.control.details.schemeSize;
            this.elements.container.css('width', schemeSize.width);
            this.elements.container.css('maxWidth', schemeSize.width);
            this.elements.container.css('height', schemeSize.height);
            this.elements.container.css('maxHeight', schemeSize.height);
            const controlObjects = this.control.details.controlReferences.map(controlObject => this._createPageObject(controlObject)) || [];
            const textObjects = Feature.SYSTEM_SCHEME_EXTENSIONS && this.control.details.extraObjects.map(textObject => this._createPageObject(textObject, true)) || [];
            return [...controlObjects, ...textObjects];
        }

        /**
         * Calculates the minZoom property
         * If the image is smaller than the viewport, we set 1 (default scale)
         * @private
         */
        _setMinZoom() {
            if (this.viewportHeight > this.defaultImageHeight && this.viewportWidth > this.defaultImageWidth) {
                this.minZoom = 1;
            } else {
                // Here we check the difference between the viewport property and the image property,
                // after that we know, how we have to set the minZoom property
                if (this.viewportHeight - this.defaultImageHeight < this.viewportWidth - this.defaultImageWidth) {
                    this.minZoom = this.viewportHeight / this.defaultImageHeight;
                } else {
                    this.minZoom = this.viewportWidth / this.defaultImageWidth;
                }
            }

            this.minZoom = this.minZoom * 0.9;
            this.zoomHandler.setMinZoom(this.minZoom);
        }

        _handleResetButtonTapped() {
            this._resetZoom();
        }

        _calcDefaultPosition() {
            this.defaultXPos = (this.viewportWidth - this.defaultImageWidth * this.minZoom) / 2;
            this.defaultYPos = (this.viewportHeight - this.defaultImageHeight * this.minZoom) / 2;
        }

        _resetZoom() {
            if (this.zoomHandler) {
                this.zoomHandler.pause(); // Cancels animations, zoom and pan

                this.zoomHandler.resume(); // Do not change the order of these commands!

                this.zoomHandler.moveTo(0, 0);
                this.zoomHandler.zoomAbs(0, 0, this.minZoom); // Centers the image if the image is smaller than the viewport

                this.zoomHandler.moveTo(this.defaultXPos, this.defaultYPos);
            }
        }

    };
});
