'use strict';

define([
    "QrCodeResultScreen",
    "IconLib"
], function (
    QRCodeResultScreen,
    {
        default: Icons
    }
) {
    return class QrCodeScreen extends GUI.TableViewScreenV2 {

        get useNewTableView() {
            return true;
        }

        constructor() {
            super($('<div/>'));
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        getURL() {
            return "QRCodes";
        }

        titleBarText() {
            return _('qr.codes');
        }

        reloadTable() {
            this.tableContent = [{
                headerTitle: _('qr.generate'),
                rows: []
            }];
            this.tableContent[0].rows.push({
                content: {
                    title: _('app-position'),
                    leftIconSrc: Icons.PinWithCross,
                    leftIconColor: window.Styles.colors.green,
                    clickable: true
                },
                action: function () {
                    this._startActivityRecording(Interaction.QR.WRITE_POSITION);
                }.bind(this)
            }, {
                content: {
                    title: _('command'),
                    leftIconSrc: Icons.PlayCircled,
                    leftIconColor: window.Styles.colors.green,
                    clickable: true,
                    rightIconSrc: !Feature.NFC_QR_QA_COMMANDS ? Icon.Notification.CAUTION : null,
                    rightIconColor: !Feature.NFC_QR_QA_COMMANDS ? Color.DISCLOSURE_ICON_A : null
                },
                action: function () {
                    if (!Feature.NFC_QR_QA_COMMANDS) {
                        NavigationComp.showCurrentlyNotSupportedPopup(_('command.save.not-supported'));
                    } else {
                        this._startActivityRecording(Interaction.QR.WRITE_COMMAND);
                    }
                }.bind(this)
            });

            if (SandboxComponent.isAutopilotAvailable() && Feature.AUTOMATIC_DESIGNER_SCENES) {
                this.tableContent[0].rows.push({
                    content: {
                        title: _("scenes.name"),
                        leftIconSrc: Icons.PlayStripeCircled,
                        leftIconColor: window.Styles.colors.green,
                        clickable: true
                    },
                    action: function () {
                        var def = Q.defer(),
                            details = {
                                options: SandboxComponent.getStructureManager().getAllSceneControls(true).map(function (sceneControl) {
                                    return {
                                        title: sceneControl.getName(),
                                        clickable: true,
                                        sceneControl: sceneControl
                                    };
                                }),
                                title: _("qr.code-generator"),
                                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE,
                                mode: GUI.SelectorScreenMode.QUICK,
                                deferred: def
                            };
                        NavigationComp.showSelector(details).then(function (results) {
                            var control = results[0].option.sceneControl;
                            NavigationComp.showState(ScreenState.QRCodeResult, {
                                type: Interaction.QR.WRITE_SCENE,
                                text: control.getSceneExecUrlScheme(),
                                mainText: control.getName(),
                                subText: control.groupDetail
                            });
                        });
                    }
                });
            }

            this.tableContent[0].rows.push({
                content: {
                    title: _('miniserver'),
                    showFullSubtitle: true,
                    leftIconSrc: Icons.KeyholeCircled,
                    leftIconColor: window.Styles.colors.green,
                    clickable: true
                },
                action: function () {
                    NavigationComp.showState(ScreenState.QRCodeResult, {
                        type: Interaction.QR.SHARE_MINISERVER
                    });
                }
            });
            return super.reloadTable(...arguments);
        }

        _startActivityRecording(activityType) {
            NavigationComp.disableAnimationsTemp();
            this.ViewController.navigateBack().done(function () {
                if (activityType === Interaction.QR.WRITE_POSITION) {
                    // App-Position
                    var onFinish = function () {
                        NavigationComp.startActivity(GUI.ActiveMiniserverViewController.Activity.Position, Interaction.QR.WRITE_POSITION);
                    }.bind(this);

                    if (PersistenceComponent.getAppInstructionFlag(UserInstructionFlag.QR_POSITION)) {
                        onFinish();
                    } else {
                        PersistenceComponent.setAppInstructionFlag(UserInstructionFlag.QR_POSITION);

                        var msg = _('app-position.help');

                        msg += ImageBox.getResourceImageWithClasses("resources/Images/ActiveMiniserver/QRCode/icon-qr-logo.svg");
                        NavigationComp.showState(ScreenState.QuickGuide, {
                            iconSrc: "resources/Images/ActiveMiniserver/QRCode/qr-logo.svg",
                            title: _('app-position.save'),
                            message: msg,
                            onFinishFn: onFinish
                        });
                    }
                } else if (activityType === Interaction.QR.WRITE_COMMAND) {
                    // Command / Task
                    var onFinish = function () {
                        NavigationComp.startActivity(GUI.ActiveMiniserverViewController.Activity.Command, Interaction.QR.WRITE_COMMAND);
                    }.bind(this);

                    if (PersistenceComponent.getAppInstructionFlag(UserInstructionFlag.QR_COMMAND)) {
                        onFinish();
                    } else {
                        PersistenceComponent.setAppInstructionFlag(UserInstructionFlag.QR_COMMAND);
                        NavigationComp.showState(ScreenState.QuickGuide, {
                            iconSrc: "resources/Images/ActiveMiniserver/QRCode/qr-logo.svg",
                            title: _('command.save'),
                            message: _('command.help'),
                            onFinishFn: onFinish
                        });
                    }
                }
            }.bind(this));
        }

    };
});
