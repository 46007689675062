// Automatically generates and exports an Icon object with all the React Icon components
// Note: This file is available as an alias, so simply do -> import Icons from "IconLib";
import {
    ReactWrapper,
    LxShimmerPlaceHolder
} from "LxComponents";


let IconRequire = require.context("../../resources/Images/React/", false, /\.jsx$/),
    iconMap = {};
IconRequire.keys().forEach(path => {
    // Prevent duplicates and only allow absolute paths
    if (path.indexOf("./") === 0) {
        return;
    }
    let icon = IconRequire(path).default,
        name = icon.name;
    if (name in iconMap) {
        console.error(`Icon with name "${name}" already exists. Duplicate at ${path}`);
    } else {
        iconMap[name] = props => {
            if (props.isLoading) {
                return <LxShimmerPlaceHolder
                    isLoading={true}
                    style={{
                        borderRadius: 50,
                        height: props.height,
                        width: props.width
                    }}
                />
            }
            return ReactWrapper.React.createElement(icon, props);
        };
        iconMap[name].isReactIcon = true;
        iconMap[name].hasIsLoading = true;
    }
});

export default iconMap;

// Dynamically generated exports are not working as of now
// https://github.com/webpack/webpack/issues/13865
export const Icons = iconMap;


