import {LxReactPressable, LxReactText, ControlContext} from "LxComponents";
import {View} from "react-native";
import {useNavigation} from "@react-navigation/native";
import {ScreenState} from "../saunaControlEnums";
import globalStyles from "GlobalStyles";
import {useContext} from "react";

function LxSaunaInfoView({label, targetValue, actualValue, isHumidity}) {
    const {control} = useContext(ControlContext)
    const navigation = useNavigation();
    const openValueSelector = () => {
        let targetScreenState = isHumidity ? ScreenState.MAN_HUMID : ScreenState.MAN_TEMP;
        navigation.navigate(targetScreenState, {control})
    }

    return <LxReactPressable onPress={openValueSelector} style={[styles.container, isHumidity ? styles.humidityContainer: null]}>
        <View style={styles.actualValueContainer}>
            <LxReactText style={styles.actualValueLabel}>{formatActualValue(actualValue, isHumidity)}</LxReactText>
        </View>
        <View style={styles.targetValueContainer}>
            <LxReactText style={styles.nameLabel}>{label}</LxReactText>
            <LxReactText style={styles.targetValueLabel}>{formatTargetValue(targetValue, isHumidity)}</LxReactText>
        </View>
    </LxReactPressable>
}

const formatActualValue = (value, isHumidity) => {
    let format = isHumidity ? HUMID_FORMAT : TEMP_FORMAT;
    return lxFormat(format, value, true)
}

const formatTargetValue = (value, isHumidity) => {
    let format = isHumidity ? HUMID_FORMAT : TEMP_FORMAT;
    return _("target-value", {
        val: lxFormat(format, value, true)
    });
}

const TEMP_FORMAT = "%.0f°";
const HUMID_FORMAT = "%.0f%";

const styles = {
    humidityContainer: {
        marginLeft: globalStyles.spacings.gaps.verySmall
    },
    container: {
        flex: 1,
        alignItems: "center"
    },
    actualValueContainer: {
        width: "100%",
        textAlign: "center",
        backgroundColor: globalStyles.colors.grey["300a18"]
    },
    targetValueContainer: {
        width: "100%",
        textAlign: "center",
        backgroundColor: globalStyles.colors.grey["300a36"],
        paddingVertical: globalStyles.spacings.gaps.small
    },
    actualValueLabel: {
        ...globalStyles.textStyles.title1.default,
        color: globalStyles.colors.text.primary,
        paddingVertical: globalStyles.spacings.gaps.medium
    },
    nameLabel: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.primary,
    },
    targetValueLabel: {
        ...globalStyles.textStyles.footNote.default,
        color: globalStyles.colors.text.secondary,
        marginTop: globalStyles.spacings.gaps.verySmall
    }

}

export default LxSaunaInfoView
