'use strict';
/*

---------------------------------------------
|       Text with optional colored parts    |
|                                           |
|               Button-Title                |
---------------------------------------------


 content:
    [text]              Basic text, not colored. Will be shown first if texts is provided too.
    [texts]             array of either strings or text-objects allowing to show parts of the text in color
                        The contents of this array will be concatenated to one string which will be inserted
                        as HTML (allowing for line-breaks)
    [buttonTitle]       Text shown in the button below the text.
    [buttonAction]      The action to perform when the button is clicked

 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.STATUS_BUTTON_CELL = "StatusButtonCell";

    class StatusButtonCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return '' + '<div class="content__top">' + '<span class="top__text">' + '</span>' + '</div>' + '<div class="content__bottom">' + '<div class="bottom__title"></div>' + '</div>';
            };

            var getTextItem = function getTextItem(itemClass, itemText) {
                return '<span class="' + itemClass + ' text__item">' + itemText + "</span>";
            };

            return {
                getTemplate: getTemplate,
                getTextItem: getTextItem
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            var btmItemNr = 0;
            var leftIconNr = 0;
            this.contentPlaceholder.append(StatusButtonCell.Template.getTemplate());
            this.elements = {
                top: this.contentPlaceholder.find(".content__top"),
                text: this.contentPlaceholder.find(".top__text"),
                bottom: this.contentPlaceholder.find(".content__bottom"),
                buttonTitle: this.contentPlaceholder.find(".bottom__title")
            };
            this.buttons = {};

            if (this.content.buttonTitle) {
                this.buttons.bottom = new GUI.LxButton(this, this.elements.bottom);
                this.addToHandledSubviews(this.buttons.bottom);
                this.buttons.bottom.onButtonTapped = this.content.buttonAction;
                this.setButtonTitle(this.content.buttonTitle);
            }

            var texts = [];
            this.content.text && texts.push(this.content.text);

            if (this.content.texts) {
                texts = texts.concat(this.content.texts);
            }

            this.setTexts(texts);
        }

        clickableAllowed() {
            return false; // The button is clickable, but not the cell itself.
        }

        setTexts(texts) {
            var html = "",
                colorMap = {},
                itemClass;
            texts.forEach(function (textItem, textIdx) {
                itemClass = "text__item-" + textIdx;

                if (typeof textItem === 'object' && textItem !== null) {
                    if (textItem.color) {
                        colorMap[itemClass] = textItem.color;
                    }

                    html += StatusButtonCell.Template.getTextItem(itemClass, textItem.text);
                } else {
                    html += textItem;
                }
            }.bind(this));
            this.elements.text.html(html); // set colors

            Object.keys(colorMap).forEach(function (colorKey) {
                this.elements.text.find("." + colorKey).css("color", colorMap[colorKey]);
            }.bind(this));
        }

        setButtonTitle(title) {
            this.elements.buttonTitle.text(title);
        }

    }

    GUI.TableViewV2.Cells.StatusButtonCell = StatusButtonCell;
    return GUI;
}(window.GUI || {});
