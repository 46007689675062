define("CarChargerControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class CarChargerControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states, navigation) {
            let cmds = [];

            if (states.profiles && states.profiles.length > 0) {
                let cmdName = _("controls.car-charger.select-profile");

                if (states.currentProfile >= 0) {
                    let currentProfile = states.profiles[states.currentProfile];
                    cmdName = _("controls.car-charger.profile", {
                        profile: currentProfile
                    });
                }
                cmds.push(this.createCommand(cmdName, this._showProfileSelector.bind(this, control, states, navigation), null))
            }

            if (!states.isCharging && states.carConnected) {
                cmds.push(this.createCommand(_('controls.car-charger.start-charging'), Commands.CAR_CHARGER.START_CHARGING, null));
            } else if (states.isCharging) {
                cmds.push(this.createCommand(_('controls.car-charger.stop-charging'), Commands.CAR_CHARGER.STOP_CHARGING, null));
            }

            return cmds;
        }

        /**
         * Will show the profile selector
         * @param states
         * @private
         */
        _showProfileSelector(control, states, navigation) {
            let options = [],
                details,
                def = Q.defer(); // prepare the options = profiles

            for (let i = 0; i < states.profiles.length; i++) {
                options.push({
                    title: states.profiles[i],
                    active: i === states.currentProfile,
                    clickable: true
                });
            } // prepare the details for the selector screen


            details = {
                options: options,
                title: control.name,
                subtitle: _("controls.car-charger.profile-selection"),
                mode: GUI.ComfortModeSelectorScreenMode.QUICK,
                selectedCell: states.currentProfile,
                icon: Icon.CarCharger.CAR_CHARGER,
                deferred: def
            }; // show the selector

            navigation.navigate(ScreenState.ComfortModeSelectorScreen, details); // take action after a profile was picked.

            def.promise.then((result) => {
                control.sendCommand(Commands.format(Commands.CAR_CHARGER.SET_PROFILE, result[0].row));
            }, (err) => {
                console.error("Could not pick a profile!");
            });
        }

    };
});
