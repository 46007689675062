import { Graph, LoadingGraph, CircleGraph, LoadingCircleGraph, useControl } from "LxComponents"
import PropTypes from "prop-types";
import { EfmNodeType, EfmUtilities, EfmViewType } from "../../efmUtilities";
import { useMemo } from "react";
import { View } from "react-native";
import globalStyles from "GlobalStyles";

import useExtendedStats from "../hooks/useExtendedStats.jsx";

import {forceDataIntoMode} from "../util/averageValues.js";
import getSpecificValue from "../util/getSpecificValue.js";
import generateLabels from "../util/generateLabels.js";

import { getTotalWhileIgnoringTinyNumbers } from "../util/getSpecificValue.js";
import useLowestMode from "../hooks/useLowestMode";


export default function ConsumptionGraph({ availableNodeTypes, gridStats, storStats: storageStats, prodStats: productionStats, loadStats, viewType, timeRange, loading = false, controlUuid, currentValues, fullStretch, onZoom, isLivePast }) {
    const lowestMode = useLowestMode([gridStats, storageStats, productionStats, loadStats]);

    const rawExtendedStorageNodeStatsData = useExtendedStats({ usedMode: lowestMode, stats: storageStats, viewType, startTs: timeRange.start, endTs: timeRange.end, newExtend: true });
    const rawExtendedGridNodeStatsData = useExtendedStats({ usedMode: lowestMode, stats: gridStats, viewType, startTs: timeRange.start, endTs: timeRange.end, newExtend: true });
    const rawExtendedProductionNodeStatsData = useExtendedStats({ usedMode: lowestMode, stats: productionStats, viewType, startTs: timeRange.start, endTs: timeRange.end, newExtend: true });
    const rawExtendedLoadNodeStatsData = useExtendedStats({ stats: loadStats, viewType, startTs: timeRange.start, endTs: timeRange.end, newExtend: true });

    const control = useControl(controlUuid);

    const onlyConsumptionAvailable = useMemo( () =>
        !availableNodeTypes.Grid &&
        !availableNodeTypes.Production,
        [availableNodeTypes]
    );

    const showDonut = useMemo(()=>
        (availableNodeTypes.Production && availableNodeTypes.Storage) ||
        (availableNodeTypes.Grid && availableNodeTypes.Storage) ||
        (availableNodeTypes.Production && availableNodeTypes.Grid)
    ,[availableNodeTypes])

    const {
        extendedStorageNodeStatsData,
        extendedGridNodeStatsData,
        extendedProductionNodeStatsData,
        extendedLoadNodeStatsData,
    } = useMemo(() => {
        return {
            extendedStorageNodeStatsData: rawExtendedStorageNodeStatsData && viewType === EfmViewType.Actual ? forceDataIntoMode(rawExtendedStorageNodeStatsData, timeRange.start, timeRange.end, lowestMode) : rawExtendedStorageNodeStatsData,
            extendedGridNodeStatsData: rawExtendedGridNodeStatsData && viewType === EfmViewType.Actual ? forceDataIntoMode(rawExtendedGridNodeStatsData, timeRange.start, timeRange.end, lowestMode) : rawExtendedGridNodeStatsData,
            extendedProductionNodeStatsData: rawExtendedProductionNodeStatsData && viewType === EfmViewType.Actual ? forceDataIntoMode(rawExtendedProductionNodeStatsData, timeRange.start, timeRange.end, lowestMode) : rawExtendedProductionNodeStatsData,
            extendedLoadNodeStatsData: rawExtendedLoadNodeStatsData && viewType === EfmViewType.Actual ? forceDataIntoMode(rawExtendedLoadNodeStatsData, timeRange.start, timeRange.end, lowestMode) : rawExtendedLoadNodeStatsData,
        }
    }, [rawExtendedStorageNodeStatsData, rawExtendedGridNodeStatsData, rawExtendedProductionNodeStatsData, rawExtendedLoadNodeStatsData]);

    const { graphData, horizontalLabels, highlightDateFormat, bottomLabelsCentered, legend } = useMemo(() => {
        if(onlyConsumptionAvailable) {
            if(Array.isArray(extendedLoadNodeStatsData)) {
                const graphData = extendedLoadNodeStatsData.map((loadDatapoint, idx) => {
                    return {
                        color: globalStyles.colors.red,
                        timestamp: loadDatapoint.ts,
                        value: getSpecificValue(loadStats.header, loadDatapoint.values, true),
                    }
                });
                const { horizontalLabels, highlightDateFormat, bottomLabelsCentered } = generateLabels({ viewType, data: graphData });
                const legend = [                {
                    color: globalStyles.colors.red,
                    label: _("efm.consumption-title"),
                }];
                return {
                    graphData,
                    horizontalLabels,
                    highlightDateFormat,
                    bottomLabelsCentered,
                    legend
                }
            }
            return {};
        } else {
            const data = [];

            const useProductionData = (Array.isArray(extendedProductionNodeStatsData));
            const useStorageData = (Array.isArray(extendedStorageNodeStatsData));
            const useGridData = (Array.isArray(extendedGridNodeStatsData));

            let usedBaseDatapoints = extendedGridNodeStatsData;
            if(!useGridData) {
                usedBaseDatapoints = extendedProductionNodeStatsData;
            }

            if(!useProductionData && !useGridData) {
                return {};
            }

            let storageLabel = false; let prodLabel = false;

            usedBaseDatapoints.forEach((usedDatapoint, idx) => {
                const arr = [];

                let gridImport;
                let gridExport;
                if(useGridData) {
                    gridImport = getSpecificValue(gridStats.header, (extendedGridNodeStatsData[idx] || {}).values, true);
                    gridExport = -getSpecificValue(gridStats.header, (extendedGridNodeStatsData[idx] || {}).values, false);
                    if(typeof gridExport !== 'number') {
                        gridExport = 0;
                    }
                }

                let storageExport;
                let storageDischarging;
                if(useStorageData) {
                    storageDischarging = getSpecificValue(storageStats.header, (extendedStorageNodeStatsData[idx] || {}).values, true);
                    storageExport = -getSpecificValue(storageStats.header, (extendedStorageNodeStatsData[idx] || {}).values, false);
                }

                let prodValue;
                if(useProductionData) {
                    prodValue = getSpecificValue(productionStats.header, (extendedProductionNodeStatsData[idx] || {}).values, true);
                }



                let consumedFromStorage;
                if(useStorageData) {
                    consumedFromStorage = storageDischarging;
                }

                let consumedFromOwnProduction;
                if(useProductionData) {
                    consumedFromOwnProduction = prodValue - (gridExport || 0) - (storageExport || 0);
                    consumedFromOwnProduction = Math.max(0, consumedFromOwnProduction);
                }

                let consumedFromGrid;
                if(useGridData) {
                    consumedFromGrid = gridImport;
                }


                let totalConsumption = (consumedFromGrid || 0) + (consumedFromOwnProduction || 0) + (consumedFromStorage || 0);

                if(useGridData) {
                    arr.push({
                        color: globalStyles.colors.red, // red point
                        timestamp: usedDatapoint.ts,
                        value: totalConsumption,
                        displayValue: consumedFromGrid,
                    });
                }

                if(useProductionData) {
                    let greenPoint = consumedFromOwnProduction;
                    if(useStorageData) {
                        greenPoint += storageDischarging;
                    }
                    arr.push({
                        color: globalStyles.colors.green,
                        timestamp: usedDatapoint.ts,
                        value: greenPoint,
                        displayValue: consumedFromOwnProduction,
                    });
                    prodLabel = true;
                }

                if(useStorageData) {
                    const bluePoint = storageDischarging;
                    arr.push({
                        color: globalStyles.colors.blue,
                        timestamp: usedDatapoint.ts,
                        value: bluePoint,
                    });
                    storageLabel = true;
                }

                data.push(arr);
            });

            const { horizontalLabels, highlightDateFormat, bottomLabelsCentered } = generateLabels({ viewType, data });

            const legend = [];
            if(prodLabel) {
                legend.push({
                    color: globalStyles.colors.green,
                    label: _("efm.self-production"),
                });
            }
            if(useGridData) {
                legend.push({
                    color: globalStyles.colors.red,
                    label: _("efm.grid-import"),
                });
            }
            if(storageLabel) {
                legend.push({
                    color: globalStyles.colors.blue,
                    label: _("efm.storage-title"),
                });
            }

            return {
                graphData: data,
                horizontalLabels,
                highlightDateFormat,
                bottomLabelsCentered: bottomLabelsCentered,
                legend,
            }
        }

    }, [storageStats, gridStats, productionStats, extendedLoadNodeStatsData, onlyConsumptionAvailable]);

    const customPillTitle = (dataPoints, dateFormat, valueFormat) => {
        const total = dataPoints.reduce((sum, {displayValue, value}) => {
            let valToUse = 0;

            if (typeof displayValue === 'number') {
                valToUse = displayValue;
            } else if (typeof value === 'number') {
                valToUse = value;
            }
            return valToUse + sum;
        }, 0);
        return `${ActiveMSComponent.getMomentFromUtcTimestamp(dataPoints[0].timestamp).format(dateFormat)} (${_('x.total', {
            x: lxUnitConverter.convertAndApply(valueFormat, total, true)
        })})`;
    };

    /* #region  Calculate Circle Graph */
    const circleGraphValues = useMemo(() => {
        if (!gridStats && !(productionStats && storageStats)) {
            return [];
        }

        let prodTotal, storageTotal, gridTotal;
        if (gridStats) {
            try { gridTotal = EfmUtilities.reduceStatistics(gridStats, viewType) } catch (e) { }
            if(gridTotal && typeof gridTotal.totalNeg !== 'number') {
                gridTotal.totalNeg = 0;
            }
        }
        if (storageStats) {
            try { storageTotal = EfmUtilities.reduceStatistics(storageStats, viewType) } catch (e) { }
        }
        if (productionStats) {
            try { prodTotal = EfmUtilities.reduceStatistics(productionStats, viewType) } catch (e) { }
        }

        if (!gridTotal && !(prodTotal && storageTotal)) {
            return  [];
        }

        const arr = [];

        let greenPart;
        if (prodTotal) {
            greenPart = prodTotal.total - (gridTotal || {totalNeg: 0}).totalNeg - (storageTotal ? storageTotal.totalNeg : 0);
            arr.push({
                color: globalStyles.colors.green,
                value: Math.max(0, greenPart),
                label: _("efm.self-production"),
                format: control.totalFormat
            });
        }

        let bluePart;
        if (storageTotal) {
            bluePart = storageTotal.total;
            arr.push({
                color: globalStyles.colors.blue,
                value: Math.max(0, bluePart),
                label: _("efm.storage-title"),
                format: control.totalFormat
            });
        }

        let yellowPart;
        if(gridTotal) {
            yellowPart = gridTotal.total;
            arr.push({
                color: globalStyles.colors.red,
                value: Math.max(0, yellowPart),
                label: _("efm.grid-import"),
                format: control.totalFormat
            });
        }

        return arr;
    }, [storageStats, gridStats, productionStats, currentValues]);

    const titleRight = useMemo(() => {
        let titleRight;
        if(!availableNodeTypes.Grid && !availableNodeTypes.Production) {
            if(loadStats && currentValues && currentValues[EfmNodeType.Consumption]) {
                let viewTypeKey = 'total';
                if(typeof currentValues[EfmNodeType.Consumption].actual === 'number') {
                    viewTypeKey = 'actual';
                }
                const value = getTotalWhileIgnoringTinyNumbers({
                        total: currentValues[EfmNodeType.Consumption][viewTypeKey]
                });

                let format = "%.1f kWh";
                switch (viewTypeKey) {
                    case "total":
                    case "totalNeg":
                        format = control.totalFormat;
                        break;
                    case "actual":
                        format = control.actualFormat;
                        break;
                }
                if(isLivePast) {
                    format = control.totalFormat;
                }
                const formattedTotal = lxUnitConverter.convertSplitAndApply(format,value);
                titleRight = `${formattedTotal.valueTxt} ${formattedTotal.succTxt}`;
            }
        } else if ((gridStats && gridStats.header || productionStats && productionStats.header) && currentValues && (currentValues[EfmNodeType.Grid] || currentValues[EfmNodeType.Production])) {
            let prodTotal, storageTotal, gridTotal;
            if (currentValues[EfmNodeType.Grid]) {
                gridTotal = currentValues[EfmNodeType.Grid];
                if(typeof gridTotal.totalNeg !== 'number') {
                    gridTotal.totalNeg = 0;
                }
            }
            if (currentValues[EfmNodeType.Storage]) {
                storageTotal = currentValues[EfmNodeType.Storage];
            }
            if (currentValues[EfmNodeType.Production]) {
                prodTotal = currentValues[EfmNodeType.Production];
            }

            let num;
            if ((gridTotal && typeof gridTotal.actual === 'number') || (prodTotal && typeof prodTotal.actual === 'number') || (storageTotal && typeof storageTotal.actual === 'number')) {
                num = (gridTotal ? gridTotal.actual : 0) + (prodTotal ? prodTotal.actual : 0) + (storageTotal ? storageTotal.actual : 0);
            } else {
                num = (gridTotal ? gridTotal.total - gridTotal.totalNeg : 0) + (prodTotal ? prodTotal.total : 0) + (storageTotal ? storageTotal.total - storageTotal.totalNeg : 0);
            }
            let titleRightFormat = (gridStats || productionStats).header[0].format;
            if (isLivePast && (gridStats || productionStats).dayStats) {
                titleRightFormat = (gridStats || productionStats).dayStats.header[0].format;
            }
            const formattedTotal = lxUnitConverter.convertSplitAndApply(titleRightFormat, getTotalWhileIgnoringTinyNumbers({total: num}));
            titleRight = `${formattedTotal.valueTxt} ${formattedTotal.succTxt}`;
        }
        return titleRight
    }, [ availableNodeTypes.Grid, availableNodeTypes.Production, loadStats, currentValues, isLivePast, gridStats, gridStats?.header, gridStats?.dayStats, productionStats?.header, productionStats?.dayStats, ]);
    /* #endregion */

    return (
        <>
            <Graph
                titleLeft={!fullStretch ? _("efm.consumption-title") : undefined}
                titleRight={!fullStretch ? titleRight : undefined}
                fullStretch={fullStretch}
                type={viewType === EfmViewType.Actual ? 'line' : 'bar'}
                horizontalLabels={horizontalLabels}
                datapoints={graphData}
                format={control.actualFormat}
                label={viewType === EfmViewType.Actual ? control.actualUnit : control.totalUnit}
                highlightDateFormat={highlightDateFormat}
                bottomLabelsCentered={bottomLabelsCentered}
                fixedLowerLimit={0}
                forcedTimeRange={timeRange}
                legend={Array.isArray(legend) && legend.length > 0 ? legend : [{color: globalStyles.colors.grey['600'], label: '-'}]}
                onZoom={onZoom}
                customPillTitle={customPillTitle}
            />
            {
                !fullStretch && showDonut ? (
                    <>
                        <CircleGraph values={circleGraphValues} />
                        <View style={{height: 11}} />
                    </>
                ) : null
            }
        </>
    )
}

ConsumptionGraph.propTypes = {
    gridStats: PropTypes.object,
    storStats: PropTypes.object,
    prodStats: PropTypes.object,
    viewType: PropTypes.string,
    timeRange: PropTypes.object,
}
