'use strict';
/*
Options:
 overscroll             boolean
 noScroll               boolean, if set to true, it will disable scrolling
 pageControl            boolean
 retainPageControlSize  the space on top and bottom of pages is applied, even if only 1 page is given

 */

window.GUI = function (GUI) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var maxItemLength = 9;

        class LxCoverBrowseView extends GUI.LxPageView {
            constructor(pages, progressCallback, animationEndCallback, options) {
                super($('<div />'));
                Debug.GUI.LxPageView && console.log("LxPageView ctor");
                this.completeContent = pages;
            }

            viewWillAppear() {
                super.viewWillAppear(...arguments);

                if (this.completeContent.length > maxItemLength) {
                }
            }

        }

        GUI.LxCoverBrowseView = LxCoverBrowseView;
    }
    return GUI;
}(window.GUI || {});

GUI.LxPageView.direction = {
    LEFT: "left",
    RIGHT: "right"
};
