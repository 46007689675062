'use strict';

define([], function () {
    return {
        PickerMode: {
            NONE: 0,
            LUMITECH: 1,
            RGB: 2
        }
    };
});
