import {
    useControl,
    LxControlContextProvider,
    LxBaseControlContent,
    MessageDetailScreen,
    LxReactScreenAdapter,
    useBackNavigation,
    useSetURLHook
} from "LxComponents";
import React, {
    useEffect
} from "react";
import {createStackNavigator} from "@react-navigation/stack";

function LxReactControlContent({navigation, route}) {
    const controlUUID = route.params && route.params.controlUUID;
    const isAlert = route.params && route.params.isAlert;
    const control = useControl(controlUUID);
    const activeMCEntries = MessageCenterHelper.findActiveEntriesWithSourceUuid(controlUUID);
    const lockingMessage = control && control.getLockingSystemStatusMessage();
    const specificReactContent = control && control.getReactControlContent();

    const CCStack = createStackNavigator();

    useSetURLHook({
        urlPart: "control/" + controlUUID
    });

    useEffect(() => {
        navigation.setOptions({
                headerShown: false
        })
    } , []);

    useEffect(() => {
        if (!control) {
            EntryPointHelper.resetEntryPointLocation();
            navigation.goBack();
        }
    }, [control]);

    const getReactScreen = (screenInfo) => {
        if (typeof screenInfo === "function") {
            return <CCStack.Screen key={screenInfo.name} name={screenInfo.name} component={screenInfo} />
        } else if (typeof screenInfo === "object" && screenInfo.hasOwnProperty("component")) {
            return <CCStack.Screen key={screenInfo.name} name={screenInfo.name || screenInfo.component.name} component={screenInfo.component} />
        } else {
            console.error(LxReactControlContent + " --> Invalid Screeninfo passed in getReactScreens() of Control " + control.getName(), screenInfo)
            return null;
        }
    }

    useBackNavigation(() => {
        navigation.goBack();
    });

    const initalRouteName = route.params?.initRouteName ?? CCScreenStates.INITIAL_CONTENT;
    if (control) {
        return <LxControlContextProvider controlUuid={controlUUID} isAlert={isAlert}>
            <CCStack.Navigator initialRouteName={lockingMessage ? CCScreenStates.MESSAGE_ENTRY : initalRouteName} screenOptions={{
                cardOverlayEnabled: false
            }}>
                <CCStack.Screen name={CCScreenStates.INITIAL_CONTENT} component={specificReactContent || LxBaseControlContent} initialParams={route.params?.contentDetails}/>
                <CCStack.Screen name={CCScreenStates.MESSAGE_ENTRY} component={MessageDetailScreen} initialParams={{
                    entry: activeMCEntries[0],
                    fromControlContent: true
                }}/>
                <CCStack.Screen name={ScreenState.ControlSettingsController} component={LxReactScreenAdapter} />
                <CCStack.Screen name={ScreenState.ControlHelp} component={LxReactScreenAdapter} />
                <CCStack.Screen name={ScreenState.Statistic} component={LxReactScreenAdapter} />
                <CCStack.Screen name={ScreenState.SelectorScreen} component={LxReactScreenAdapter} />
                <CCStack.Screen name={ScreenState.ComfortModeSelectorScreen} component={LxReactScreenAdapter} />
                <CCStack.Screen name={ScreenState.TIMER_UNTIL_SCREEN} component={LxReactScreenAdapter} />
                {control.getLegacyScreens().map(screenName => <CCStack.Screen key={screenName} name={screenName} component={LxReactScreenAdapter} />)}
                {control.getReactScreens().map(screen => getReactScreen(screen))}
            </CCStack.Navigator>
        </LxControlContextProvider>
    } else {
        return null;
    }
}

export const CCScreenStates = {
    MESSAGE_ENTRY: "ControlMessageScreen",
    INITIAL_CONTENT: "InitialControlContent"
}

export default LxReactControlContent
