'use strict';

define(["./deviceFavoriteSettingsScreen.jsx"], function (DeviceFavoriteSettingsScreenComp) {
    return class DeviceFavoritesSettingsScreen extends GUI.Screen {
        static ReactComp = DeviceFavoriteSettingsScreenComp.default;

        constructor() {
            super($('<div />'));
        }
    };
});
