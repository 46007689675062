import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

function LowestPrice(props) {
  return (
      <Svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          isreact="true"
          {...props}
      >
        <Circle cx={11.9999} cy={12} r={11} stroke="#498737" strokeWidth={2}/>
        <Path
            d="M11.9999 17V7M11.9999 17L15.4999 13.5M11.9999 17L8.49988 13.5"
            stroke="#498737"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
      </Svg>
  )
}
export default LowestPrice;
