'use strict';

define("LightV2ControlCommandSrc", ["ControlCommandSrc", "LightV2ControlEnums"], function (ControlCommandSrc, LightV2ControlEnums) {
    return class LightV2ControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [];

            if (control.hasSingleSwitch()) {
                if (states.isOn) {
                    cmds.push(this.createCommand(_('turn-off'), Commands.format(Commands.LIGHTCONTROL_V2.MOOD.CHANGE_TOO, LightV2ControlEnums.KNOWN_MOODS.ALL_OFF), null));
                } else {
                    cmds.push(this.createCommand(_('turn-on'), Commands.format(Commands.LIGHTCONTROL_V2.MOOD.CHANGE_TOO, LightV2ControlEnums.KNOWN_MOODS.ALL_ON), null));
                }
            } else {
                // Old two list UI

                /*control.moodsOfList(true, function found(mood) {
                 cmds.push(this.createCommand(mood.name, Commands.format(Commands.LIGHTCONTROL_V2.MOOD.CHANGE_TOO, mood.id), null, { mood: mood }));
                 }.bind(this));
                  control.moodsOfList(false, function found(mood) {
                 cmds.push(this.createCommand(mood.name, Commands.format(Commands.LIGHTCONTROL_V2.MOOD.CHANGE_TOO, mood.id), null, { mood: mood }));
                 }.bind(this));*/
                states.moodList.forEach(function (mood) {
                    cmds.push(this.createCommand(mood.name, Commands.format(Commands.LIGHTCONTROL_V2.MOOD.CHANGE_TOO, mood.id), null, {
                        mood: mood
                    }));
                }.bind(this));
            }

            return cmds;
        }

    };
});
