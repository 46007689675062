'use strict';

define([], function () {
    return class ControlInfoView extends GUI.ControlStateView {
        constructor(control, element) {
            if (!element) {
                element = $('<div />');
            }

            super(control, element);
        }

    };
});
