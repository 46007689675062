'use strict';
/**
 * Created by loxone on 20.06.17.
 */

define("PoolControlEnums", [], function () {
    return {
        ScreenState: {
            VALVE_POSITION: "PoolControlContentValvePositionScreen",
            OPERATING_MODES: "PoolControlContentOperatingModeList",
            TIMER: "PoolControlContentTimer",
            TEMPERATURE_MODE_LIST: "PoolControlContentTemperatureModeList",
            SWIM_MACHINE: "AnalogSwimmingMachineScreen",
            SETTINGS: "PoolControlContentSettings",
            SERVICE: "ServiceModeScreen",
            CYCLE_SELECTION: "PoolControlContentCycleSelection",
            CALENDAR: "PoolDaytimerControlContentCalendar",
            COVER: "PoolCoverScreen",
            ENTRY_DETAIL: "DaytimerControlContentEntryDetail",
            OP_MODES: "DaytimerControlContentOperatingModes"
        },
        PoolMode: {
            UNKNOWN: -1,
            OUT_OF_ORDER: 0,
            AUTOMATIC: 1,
            SERVICE_MODE: 2
        },
        PoolCycleMode: {
            NO_CYCLE_ACTIVE: 0,
            FILTER: 1,
            FLUSHING: 2,
            CIRCULATE: 3,
            DRAIN: 4
        },
        ErrorTypes: {
            NO_ERROR: 0,
            HAD_ERROR: 1,
            HAS_ERROR: 2,
            IS_OFFLINE: 3,
            NO_VALVE: 777 // app specific - not supported by the miniserver

        },
        PoolTempMode: {
            OFF: 0,
            FULL_AUTO: 1,
            AUTO_HEATING: 2,
            AUTO_COOLING: 3,
            MAN_HEATING: 4,
            MAN_COOLING: 5
        },
        ValveType: {
            NO_VALVE: 0,
            AQUASTAR: 1
        },
        PoolValveCycle: {
            NO_CYCLE: 0,
            FILTER: 1,
            BACK_WASH: 2,
            CLEAR_WASH: 3
        },
        PoolValvePositions: {
            VALVE_MOVES: -1,
            FILTER: 0,
            BACK_WASH: 1,
            CLEAR_WASH: 2,
            CIRCULATE: 3,
            CLOSED: 4,
            DRAIN: 5,
            RELIEVE: 6
        },
        SwimmingMachineType: {
            NONE: 0,
            DIGITAL: 1,
            ANALOG: 2
        }
    };
});
