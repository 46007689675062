import {
    App
} from "LxComponents";

const createURLFromReactState = (targetRoute) => {
    let urlParts = [];
    getURLPartsFromReactState(App.navigationRef.getState(), targetRoute, urlParts);
    if (urlParts) {
        console.log("LxReactNavigationAnalyzer: joining url parts", urlParts)
        let cleanedUrlParts = urlParts.filter(part => part && part.length > 0).map(part => cleanUrlPart(part));
        console.log("LxReactNavigationAnalyzer: joining cleaned url parts", cleanedUrlParts)
        return cleanedUrlParts.join("/");
    } else {
        return "";
    }
 }

const cleanUrlPart = (urlPart) => {
    let cleanedUrlPart = urlPart;
    if (cleanedUrlPart.startsWith("/")) {
        cleanedUrlPart = cleanedUrlPart.substring(1);
    }
    if (cleanedUrlPart.endsWith("/")) {
        cleanedUrlPart = cleanedUrlPart.slice(0,-1)
    }
    return cleanedUrlPart
}

const getURLPartsFromReactState = (state, targetRoute, urlParts) => {
    if (state && state.routes && state.index >= 0) {
        let nextRoute = state.routes[state.index];
        let routeName = nextRoute.name;

        let legacyScreenName = Object.keys(screenStateMap).find(screenName => screenName === routeName || "GUI." + screenName === routeName);
        if (legacyScreenName) {
            let urlOrScreen = screenStateMap[legacyScreenName];
            if (typeof urlOrScreen === "string") {
                urlParts.push(urlOrScreen)
            } else if (typeof urlOrScreen === "object" && urlOrScreen instanceof GUI.Screen) {
                    urlParts.push(urlOrScreen.getURL());
            }
        }

        if (targetRoute && (routeName === targetRoute || routeName === "GUI." + targetRoute)) {
            return urlParts;
        }

        if (nextRoute.state) {
            return getURLPartsFromReactState(nextRoute.state, targetRoute, urlParts);
        } else {
            return urlParts;
        }
    } else {
        return "";
    }
}

const getScreenStatesFromReactState = (state, screenStates) => {
    if (state && state.routes && state.index >= 0) {
        let nextRoute = state.routes[state.index];
        let routeName = nextRoute.name;

        screenStates.push(routeName);

        if (nextRoute.state) {
            return getScreenStatesFromReactState(nextRoute.state, screenStates)
        } else {
            return screenStates;
        }
    } else {
        return [];
    }
}

const getCurrentScreenState = (state) => {
    let allRoutes = [];
    getScreenStatesFromReactState(state, allRoutes);
    return allRoutes[allRoutes.length - 1]
}

const getActiveScreenState = (state) => {
    return getScreenStatesFromReactState(state, []).slice(-1)[0];
}

const getLastLegacyVC = (state) => {
    if (state && state.routes && state.index >= 0) {
        let nextRoute = state.routes[state.index];
        let routeName = nextRoute.name;

        if (nextRoute.state) {
            return getLastLegacyVC(nextRoute.state)
        } else if (routeName.endsWith("Controller")){
             let legacyScreenName = Object.keys(screenStateMap).find(screenName => screenName === routeName || "GUI." + screenName === routeName);
             if (legacyScreenName) {
                 return screenStateMap[legacyScreenName]
             } else {
                 return null;
             }
        } else {
            return null;
        }
    } else {
        return null;
    }
}

const getNavigationState = (state, routeName) => {
    let route = getRouteFromNavigationState(state, routeName);
    return route ? route.state : null;
}

const getRouteFromNavigationState = (state, routeName) => {
    if (state && state.routes) {
        let targetRoute = null;
        state.routes.some(route => {
            if (route.name === routeName) {
                targetRoute = route;
            } else if (route.state) {
                targetRoute = getRouteFromNavigationState(route.state, routeName)
            }
            return targetRoute !== null;
        })
        return targetRoute;
    } else {
        return null;
    }
}

const reactScreenStateURLMap = {
    [ScreenState.Categories]: UrlStartLocation.CATEGORY,
    [ScreenState.House]: UrlStartLocation.HOUSE,
    [ScreenState.Rooms]: UrlStartLocation.ROOM,
    [ScreenState.FavoritesV2]: UrlStartLocation.FAVORITES
}

export {
    getNavigationState,
    getCurrentScreenState,
    getScreenStatesFromReactState,
    getRouteFromNavigationState
}

