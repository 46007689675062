"use strict";

import {
    LxReactContextMenuHandler
} from "LxComponents";

window.ContextMenuHandler = {
    get Mixin() {
        return {
            //define mixin's prototype. These will be copied to the prototype of the classes that will use this `mixin`
            _showContextMenu: function _showContextMenu(options, title, srcElem, ctxCtor) {
                return LxReactContextMenuHandler.shared.showContextMenu(...arguments);
            },
            _hideContextMenu: function _hideContextMenu(menu) {
                return LxReactContextMenuHandler.shared.hideContextMenu(...arguments);
            }
        };
    }

};
