'use strict';

define("DeviceReplaceScreen", function () {
    return class DeviceReplaceScreen extends GUI.TableViewScreen {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(submitTitle) {
                submitTitle = submitTitle.toUpperCase();
                return $('<button type="button" class="replace-device__button">' + submitTitle + '</button>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(details) {
            super($('<div/>'));
            this.newDevice = cloneObject(details.selectedDevice);
            this.extensionDevices = [];
        }

        viewDidLoad() {
            var args = arguments;
            return ActiveMSComponent.getDevicesOfExtensions(this.newDevice.type).then(res => {
                this.extensionDevices = res;
                return super.viewDidLoad(...args);
            });
        }

        titleBarText() {
            return _('device-learning.device-replace.title');
        }

        getURL() {
            return "deviceReplaceScreen";
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        emptyViewConfig() {
            return {
                iconSrc: Icon.Buttons.CLOSE,
                title: _('device-learning.replace.no-device.title'),
                message: _('device-learning.replace.no-device.message')
            };
        }

        // TableView DataSource Methods
        styleForTableView() {
            return TableViewStyle.COMFORT_MODE_2020;
        }

        reloadTable() {
            var sortedDevices;
            this.tableContent = [];
            this.tableContent.push({
                footerTitle: _('device-learning.replace-with.header-title')
            });
            sortedDevices = this._sortDevicesByRoom();
            Object.keys(sortedDevices).forEach(function (key) {
                this.tableContent.push(this._createSectionForRoom(key, sortedDevices));
            }.bind(this));

            if (!this.oldDevice && this.submitButton) {
                this.hideSubview(this.submitButton);
                this.submitButton = null;
            }

            return super.reloadTable(...arguments);
        }

        _createSectionForRoom(key, sortedDevices) {
            var roomObj = ActiveMSComponent.getStructureManager().getGroupByUUID(key, GroupTypes.ROOM);
            return {
                headerBoldTitle: roomObj ? roomObj.name : null,
                rows: sortedDevices[key].map(function (device) {
                    return this._getRowForDevice(device);
                }.bind(this))
            };
        }

        _onSubmitButtonTapped() {
            var promise = ActiveMSComponent.replaceDevice(this.newDevice.serialNr, this.oldDevice.uuid);
            return NavigationComp.showWaitingFor(promise).then(function (res) {
                if (parseInt(res.LL.Code) === ResponseCode.OK) {
                    this.ViewController.navigateBack(false, {
                        type: DeviceManagement.REPLACE_SUCCESSFUL
                    });
                }
            }.bind(this), function () {
                NavigationComp.showPopup({
                    title: _('device-learning.failed.title'),
                    message: _('device-learning.failed.message.other-error'),
                    buttonOk: _('finish'),
                    buttonCancel: false,
                    icon: Icon.CAUTION,
                    color: window.Styles.colors.orange
                });
            }.bind(this));
        }

        _getRowForDevice(device) {
            var leftImage;
            leftImage = ActiveMSComponent.getImageUrlWithDeviceType(device.type);
            Debug.DeviceSearch.General && console.log(device.name + " onlineState --> " + device.deviceState);
            return {
                type: GUI.TableViewV2.CellType.Special.DEVICE_LEARNING_CHECKABLE_CELL,
                content: {
                    title: device.name,
                    subtitle: ActiveMSComponent.getDeviceMountingLocation(device),
                    leftIconSrc: leftImage,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE,
                    isOnline: device.deviceState,
                    isDummy: !device.serialNr,
                    // a device w/o a serial is a dummy device
                    rightIconSrc: Icon.INFO2
                },
                buttonTapped: function () {
                    this.ViewController.showState(ScreenState.AboutDevice, null, device);
                }.bind(this),
                didCheckCell: this._onRowSelected.bind(this, device)
            };
        }

        _onRowSelected(device) {
            // The device will be unselected if you tap it twice
            if (this.oldDevice === device) {
                this.oldDevice = null;
            } else {
                this.oldDevice = device;
            } // Shows the 'device replace' button if not visible


            if (!this.submitButton) {
                this.submitButton = new GUI.LxButton(this, DeviceReplaceScreen.Template.getTemplate(_('device-learning.device-replace.title')), Color.BUTTON_GLOW, null, true);
                this.appendSubview(this.submitButton);
                this.submitButton.onButtonTapped = this._onSubmitButtonTapped.bind(this);
            }
        }

        _sortDevicesByRoom() {
            var sortedDevices = {};
            this.extensionDevices.forEach(function (device) {
                if (!sortedDevices.hasOwnProperty(device.room)) {
                    sortedDevices[device.room] = [];
                }

                sortedDevices[device.room].push(device);
            }.bind(this));
            return sortedDevices;
        }

    };
});
