'use strict';

window.GUI = function (GUI) {
    class LxButtonArray extends GUI.View {
        /**
         * ctor of LxButton
         * @param {*} callbackContext button callbacks context
         * @param {HTMLElement} elem
         * @param [activeColor=Color.GREEN] color when button is set to active
         * @param {[]} [activeParts] parts of button which should glow: [{ part: <HTMLElement>, mode: "fill" }, ..]
         * @param {bool} [isGlowable=false] if button should be glowalbe
         * @param {NaN | number} [tickInterval=NaN] interval at which the tick-event should fire; NaN= don't use ticks;
         *              0 = default interval; 1-x = interval in ms
         * @returns {LxButton}
         * @constructor
         */
        constructor(buttons) {
            var buttonsElm = $();
            buttons.forEach(function (btn) {
                return buttonsElm.add(btn);
            });
            super(buttonsElm);
            Object.assign(this, RippleContainer.Mixin);
            this.buttons = buttons;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.setRippleEnabled(!!this.rippleEnabled); // "re-set" the attribute, deactivate if not yet activated.

            this.element.addClass("clickable");
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments);
            this.stopRipple();
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments); // register listeners for tap/hold/release

            this._registerListeners(); // TODO-thallth this method doesn't work correctly on Android devices, transitionend isn't called on a short tap

            /*this.eventHandlers.push(hammerObject.on("transitionend webkitTransitionEnd otransitionend", function(ev) {
                this.onGlowEnd && this.onGlowEnd.call(this.callbackContext, this, ev);
            }));*/

        }

        viewWillDisappear() {
            // removes tap/hold/release
            this._removeListeners(); // dispose here, as otherwise events would still be processed after the view has disappeared.
            // this causes issues in existing implementations.


            this.hammerObject && this.hammerObject.dispose();
            this.hammerObject = null;

            if (this.onButtonDoubleTapped) {
                clearTimeout(this._singleTapTimeout);
                this._singleTapTimeout = null;
            }

            this._stopScreenSaverTick();

            this._stopTickInterval();

            super.viewWillDisappear(...arguments);
        }

        destroy() {
            this.startGlowEndTimeout && clearTimeout(this.startGlowEndTimeout);
            this.glowEndTimeout && clearTimeout(this.glowEndTimeout);
            this.removeTransitionTimeout && clearTimeout(this.removeTransitionTimeout);

            this._stopScreenSaverTick();

            this._stopTickInterval();

            super.destroy(...arguments);
        }

        setEnabled(enabled) {
            Debug.GUI.LxButton && console.log(this.name, " setEnabled", enabled);
            this.isEnabled = enabled;

            if (this.isEnabled) {
                this.element[0].classList.remove("disabled");
                this.element[0].classList.add("enabled");
            } else {
                if (this.isBtnDown === true) {
                    this._btnUp(null, true);

                    this._handleButtonUp();
                }

                this._removeTransition(this._activeParts.call(this));

                this.element[0].classList.remove("enabled");
                this.element[0].classList.add("disabled");
            }
        }

        /**
         * Used to activate firing periodically when held.
         * @param tickInterval the initial interval between ticks. will decrease the longer the button is pressed.
         */
        activateTicks(tickInterval) {
            this.firePeriodically = true;
            this.tickInterval = null;
            this.tickDelay = parseInt(tickInterval) || DEFAULT_TICK_INTERVAL;
        }

        /**
         * setter wheater the button should be glowable or not
         * @param isGlowable
         * @param activeParts
         */
        setIsGlowable(isGlowable, activeParts) {
            Debug.GUI.LxButton && console.log(this.name, " setIsGlowable", isGlowable);
            this.isGlowable = isGlowable;

            if (this.isGlowable) {
                this.setActiveParts(activeParts || [{
                    part: this.element[0],
                    mode: "fill"
                }]);
            }
        }

        /**
         * setter for active parts of button
         * if this function is not called, the whole button is the glowable part
         * @param activeParts
         */
        setActiveParts(activeParts) {
            Debug.GUI.LxButton && console.log(this.name, " setActiveParts");

            for (var i = 0; i < activeParts.length; i++) {
                activeParts[i].part.setAttribute("lx-glowable-parts", activeParts[i].mode);
            }
        }

        /**
         * adds all childs to the active parts using the given modes
         * @param {string} modes seperated by ',' or default (fill, color, background-color)
         */
        useChildsAsActiveParts(modes) {
            Debug.GUI.LxButton && console.log(this.name, " useChildsAsActiveParts");

            if (!modes) {
                modes = DEFAULT_MODES;
            }

            if (this.element[0].children) {
                for (var i = 0; i < this.element[0].children.length; i++) {
                    this.element[0].children[i].setAttribute("lx-glowable-parts", modes);
                }
            }
        }

        /**
         * adds all the element to the active parts using the given modes
         * @param {string} modes seperated by ',' or default (fill, color, background-color)
         */
        useElementAsActivePart(modes) {
            Debug.GUI.LxButton && console.log(this.name, " useElementAsActivePart");

            if (!modes) {
                modes = DEFAULT_MODES;
            }

            this.element[0].setAttribute("lx-glowable-parts", modes);
        }

        setActiveColor(color) {
            Debug.GUI.LxButton && console.log(this.name, " setActiveColor", color);
            this.activeColor = color;
            this.setActive(this.isActive); // if color is set after active state!
        }

        setInactiveColor(color) {
            Debug.GUI.LxButton && console.log(this.name, " setInactiveColor", color);
            this.inactiveColor = color;
            this.setActive(this.isActive); // if color is set after active state!
        }

        setActive(active) {
            Debug.GUI.LxButton && console.log(this.name, " setActive", active);
            this.isActive = active;

            if (this.isBtnDown === false) {
                this._setColorToActiveParts(active ? this.activeColor : this.inactiveColor);
            }

            if (this.isActive) {
                this.element[0].classList.add("active");
                this.element[0].classList.remove("inactive");
            } else {
                this.element[0].classList.add("inactive");
                this.element[0].classList.remove("active");
            }
        }

        /**
         * sets the glowtime, if null -> Default
         * @param {Number} t time to glow
         */
        setGlowtime(t) {
            Debug.GUI.LxButton && console.log(this.name, " setGlowtime", t);

            if (t) {
                this.glowTime = t;
            } else {
                this.glowTime = DEFAULT_GLOWABLE_GLOWTIME;
            }
        }

        /**
         * if the current action (button down...) should be canceled when starting to drag
         * no buttonReleased, tap, ... event will be fired!
         * @param {Boolean} cancel=false
         */
        setCancelOnDrag(cancel) {
            Debug.GUI.LxButton && console.log(this.name, " setCancelOnDrag", cancel);
            this.cancelOnDrag = cancel;
        }

        // ---------------------------------------------------------------------------------------------------
        //             hammer event registration / removal
        // ---------------------------------------------------------------------------------------------------

        /**
         * A hammer object is created that will be reused until this view is destroyed.
         * @returns {*}     this buttons hammer object.
         * @private
         */
        _getHammer() {
            if (!this.hammerObject) {
                var options = {
                    tapMaxTime: 169,
                    holdTimeout: 170,
                    holdThreshold: 0 // movement allowed while holding

                };
                this.hammerObject = Hammer(this.element[0], options);
            }

            return this.hammerObject;
        }

        /**
         * Registers the eventlisteners for tap/hold/release
         * @private
         */
        _registerListeners() {
            var hammerObject = this._getHammer();

            hammerObject.on(tapEvent(), this._handleTap.bind(this));
            hammerObject.on("hold", this._handleHold.bind(this));
        }

        /**
         * Removes the eventlisteners from tap/hold/release.
         * @private
         */
        _removeListeners() {
            var hammerObject = this._getHammer();

            hammerObject.off(tapEvent());
            hammerObject.off("hold");
        }

        /**
         * Registers a listener for the mouse- & touchmove events.
         * @private
         */
        _registerMoveListener() {
            this._getHammer().on("touchmove mousmove", this._handleMove.bind(this));
        }

        /**
         * Removes the listeners from the mouse- & touchmove events.
         * @private
         */
        _removeMoveListener() {
            // the result of the "on" method is a reference to the hammer object itself - calling dispose on the
            // hammer object will invalidate all other event listeners of course. Using "off" without passing an
            // eventhandler method will remove all registered handlers. Which is okay right now.
            this._getHammer().off("touchmove mousemove");
        }

        // ---------------------------------------------------------------------------------------------------
        //             handling events
        // ---------------------------------------------------------------------------------------------------
        _handleTap(ev) {
            Debug.GUI.LxButton && console.log(this.name, " _handleTap: enabled = " + this.isEnabled);

            this._stopEvent(ev);

            if (this.isEnabled) {
                this._handleButtonUp(); // this button might no longer be alive after informing the delegage, handle first


                this._btnTap(ev, true);
            }
        }

        _handleHold(ev) {
            Debug.GUI.LxButton && console.log(this.name, " _handleHold: enabled = " + this.isEnabled);

            this._stopEvent(ev);

            if (this.isEnabled) {
                /*
                Listen for the release event on the whole window to also catch releases of the button
                if another view is displayed on top or the button disappears while holding.
                 don't add the events in _registerListeners because the callback would call on every click
                */
                this.hammerObjectWindow = Hammer(window);
                this.hammerObjectWindow.on("release", this._handleRelease.bind(this));
                this.hammerObjectWindow.on("touchend", this._handleRelease.bind(this));

                this._handleButtonDown(); // this button might no longer be alive after informing the delegage, handle first


                this._btnDown(ev, true);
            }
        }

        _handleRelease(ev) {
            Debug.GUI.LxButton && console.log(this.name, " _handleRelease: enabled = " + this.isEnabled);
            ev.preventDefault();

            this._stopEvent(ev);

            this.hammerObjectWindow.off("release");
            this.hammerObjectWindow.off("touchend");
            this.hammerObjectWindow && this.hammerObjectWindow.dispose();
            this.hammerObjectWindow = null;

            if (this.isEnabled) {
                this._handleButtonUp(); // this button might no longer be alive after informing the delegage, handle first


                this._btnUp(ev, true);
            }
        }

        /**
         * Registers for movement while the button is down & updates the isBtnDown attribute.
         * Handling touchmove while the button is down disables scrolling. Also if cancelonDrag is active it'll
         * make sure that a buttonUp is fired once the touch/mouse starts moving.
         * @private
         */
        _handleButtonDown() {
            Debug.GUI.LxButton && console.log(this.name, " _handleButtonDown");
            this.isBtnDown = true;

            this._registerMoveListener();
        }

        /**
         * Remove the movement handlers & update the isBtnDown attribute.
         * @private
         */
        _handleButtonUp() {
            Debug.GUI.LxButton && console.log(this.name, " _handleButtonUp");
            this.isBtnDown = false;

            this._removeMoveListener();
        }

        _handleMove(ev) {
            Debug.GUI.LxButton && console.log(this.name, " _handleMove: enabled = " + this.isEnabled);

            if (this.isEnabled) {
                if (this.isBtnDown && this.cancelOnDrag) {
                    var isGlowable = this.isGlowable; // temp save the value, to quickly color without glow effect!

                    this.isGlowable = false; // set to false

                    this._btnUp(ev, false);

                    this.isGlowable = isGlowable; // set back original value

                    this._handleButtonUp(); // we must call onButtonReleased, to be consistent!


                    if (this.onButtonHit && this.onButtonReleased) {
                        this.onButtonReleased.call(this.callbackContext, this, ev);
                    }
                }
            }
        }

        /**
         * Centralized the implementation to stop an event. This implementation checks if the event can be cancelled
         * before trying to cancel it.
         * @param ev                the event to stop
         * @param [cancelBubble]    if provided, ev.cancelBubble is set to this value
         * @private
         */
        _stopEvent(ev, cancelBubble) {
            if (arguments.length > 1) {
                ev.cancelBubble = cancelBubble;
            }

            stopEventPropagation(ev);
        }

        // --------------------------------------------------------------------------------------
        // --------------------------------------------------------------------------------------
        // HELPER Methods
        _activeParts() {
            var activeParts = [];

            if (this.element) {
                if (this.element[0].hasAttribute("lx-glowable-parts")) {
                    activeParts.push(this.element[0]);
                }

                var glowableParts = this.element[0].querySelectorAll("[lx-glowable-parts]");

                if (glowableParts.length > 0) {
                    for (var i = 0; i < glowableParts.length; i++) {
                        activeParts.push(glowableParts[i]);
                    }
                }

                if (activeParts.length === 0) {
                    // check parent element if nothing found so far..
                    // used to be able to make the parent element an active part, but the touch-area is a smaller child (eg. miniserver cell!)
                    if (this.element[0].parentElement.hasAttribute("lx-glowable-parts")) {
                        activeParts.push(this.element[0].parentElement);
                    }
                }
            } else {
                console.warn(this.name + ": WARN in _activeParts, this.element doesn't exist!");
            }

            return activeParts;
        }

        _setColorToActiveParts(color) {
            Debug.GUI.LxButton && console.log(this.name, " setColorToActiveParts", color);

            this._activeParts().forEach(function (elem) {
                var options = elem.getAttribute("lx-glowable-parts").split(",");
                options.forEach(function (option) {
                    elem.style[option] = color;
                });
            });
        }

        _btnDown(ev, propagate) {
            Debug.GUI.LxButton && console.log(this.name, " btnDown");

            if (this.isGlowable) {
                // cancel glowing
                clearTimeout(this.startGlowEndTimeout);
                clearTimeout(this.glowEndTimeout);
                clearTimeout(this.removeTransitionTimeout);

                this._glowEnd.call(this);
            }

            this._setColorToActiveParts(this.activeColor);

            this._startScreenSaverTick();

            if (this.firePeriodically) {
                this._stopTickInterval();

                this._startTickInterval(this.tickDelay, ev, propagate);
            }

            if (propagate) {
                this.onButtonHit && this.onButtonHit.call(this.callbackContext, this, ev);
            }
        }

        _btnUp(ev, propagate) {
            Debug.GUI.LxButton && console.log(this.name, " btnUp");

            if (!this.element) {
                return; // may be already destroyed..
            }

            if (this.isGlowable) {
                this._addTransition(this._activeParts.call(this));

                if (this.isActive === true) {
                    // remove the transition after 1000ms!
                    // to show the user the "fading" effect even if the miniserver status is still active..
                    this.removeTransitionTimeout = setTimeout(function () {
                        this._removeTransition(this._activeParts.call(this));
                    }.bind(this), 1000);
                } else if (this.isActive === false) {
                    // set color back to inactive!
                    this._setColorToActiveParts(this.inactiveColor); // start timeout to let browser render the transition!


                    this.startGlowEndTimeout = setTimeout(function () {
                        this._startGlowEnd();

                        this.startGlowEndTimeout = null;
                    }.bind(this), this.glowTime);
                }
            } else {
                this._setColorToActiveParts(this.inactiveColor);
            }

            this._stopScreenSaverTick();

            this._stopTickInterval();

            if (propagate) {
                if (this.onButtonReleased) {
                    this.onButtonReleased.call(this.callbackContext, this, ev);
                } else if (this.onButtonTapped) {
                    this.onButtonTapped.call(this.callbackContext, this, ev);
                }
            }
        }

        _btnTap(ev, propagate) {
            Debug.GUI.LxButton && console.log(this.name, " btnTap");

            if (this.isGlowable) {
                this._btnDown(ev, false);

                setTimeout(function () {
                    this._btnUp(ev, false);
                }.bind(this), 1);
            } else {
                // set active and inactive after a while..
                this.element[0].style.fill = this.activeColor;
                setTimeout(function () {
                    if (this.element && this.element.length > 0) {
                        this.element[0].style.fill = this.isActive ? this.activeColor : this.inactiveColor;
                    }
                }.bind(this), 150);
            }

            if (propagate) {
                if (this.onButtonDoubleTapped) {
                    this._checkDoubleTap(ev);
                } else {
                    this._callOnButtonTapped(ev);
                }
            }
        }

        _checkDoubleTap(ev) {
            if (this._singleTapTimeout) {
                // doubletap
                clearTimeout(this._singleTapTimeout);
                this._singleTapTimeout = null;

                this._callOnButtonDoubleTap(ev);
            } else {
                clearTimeout(this._singleTapTimeout);
                this._singleTapTimeout = setTimeout(function () {
                    this._singleTapTimeout = null;

                    this._callOnButtonTapped(ev);
                }.bind(this), DOUBLE_TAP_INTERVAL);
            }
        }

        _callOnButtonTapped(ev) {
            if (this.onButtonTapped) {
                this.onButtonTapped.call(this.callbackContext, this, ev);
            } else if (this.onButtonReleased) {
                this.onButtonReleased.call(this.callbackContext, this, ev);
            } else if (this.onButtonHit) {
                this.onButtonHit.call(this.callbackContext, this, ev);
            }
        }

        _callOnButtonDoubleTap(ev) {
            if (this.onButtonDoubleTapped) {
                this.onButtonDoubleTapped.call(this.callbackContext, this, ev);
            }
        }

        _startTickInterval(timeout, ev, propagate) {
            this._currentTickDelay = Math.max(MIN_TICK_INTERVAL, timeout);
            this.tickInterval = setInterval(this._btnTick.bind(this, ev, propagate), this._currentTickDelay);
        }

        _stopTickInterval() {
            if (this.tickInterval) {
                clearInterval(this.tickInterval);
                this.tickInterval = null;
            }
        }

        _btnTick(ev, propagate) {
            Debug.GUI.LxButton && console.log(this.name, " btnTick");

            if (propagate && this.onButtonTicked) {
                this.onButtonTicked.call(this.callbackContext, this, ev);

                this._stopTickInterval();

                this._startTickInterval(this._currentTickDelay / 1.2, ev, propagate);
            }
        }

        _startGlowEnd() {
            Debug.GUI.LxButton && console.log(this.name, " startGlowEnd");

            if (this.isActive === false) {
                this.glowEndTimeout = setTimeout(function () {
                    this._glowEnd.call(this);

                    this.glowEndTimeout = null;
                }.bind(this), this.glowTime);
            }
        }

        _glowEnd() {
            Debug.GUI.LxButton && console.log(this.name, " glowEnd");

            this._removeTransition(this._activeParts.call(this));

            if (this.isActive === true) {
                // set color back to active
                this._setColorToActiveParts(this.activeColor);
            }
        }

        _addTransition(activeParts) {
            Debug.GUI.LxButton && console.log(this.name, " addTransition");
            activeParts.forEach(function (elem) {
                var option = elem.getAttribute("lx-glowable-parts");
                var transition = option + " " + this.glowTime + "ms ease-in";
                elem.style.transition = transition;
                elem.style.webkitTransition = transition;
                elem.style.mozTransition = transition;
                elem.style.oTransition = transition;
                elem.style.msTransition = transition;
            }.bind(this));
        }

        _removeTransition(activeParts) {
            Debug.GUI.LxButton && console.log(this.name, " removeTransition");
            activeParts.forEach(function (elem) {
                var transition = "none";
                elem.style.transition = transition;
                elem.style.webkitTransition = transition;
                elem.style.mozTransition = transition;
                elem.style.oTransition = transition;
                elem.style.msTransition = transition;
            }.bind(this));
        }

        _startScreenSaverTick(isRestart) {

            this._stopScreenSaverTick();

            this._screenSaverTicker = setTimeout(this._screenSaverTick.bind(this), 2000);
        }

        _stopScreenSaverTick() {
            clearTimeout(this._screenSaverTicker);
            this._screenSaverTicker = null;
        }

        _screenSaverTick() {

            this._startScreenSaverTick(true);
        }

    }

    GUI.LxButtonArray = LxButtonArray;
    return GUI;
}(window.GUI || {});
