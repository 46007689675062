'use strict';

define("ActivityImage", ["ActivityImageQueue", "ActivityImageCache"], function (ActivityImageQueue, ActivityImageCache) {
    return class ActivityImage {
        //region Static
        static ERROR = {
            NONE: 0,
            EXCESSIVE_TEMP: 1
        }; //endregion Static

        constructor(control, activity) {
            this.name = "ActivityImage";
            this.controlUuid = control.uuidAction;
            this.error = activity.error || this.constructor.ERROR.NONE; // Don't save it under "this" as the TableView will have a loop then!

            let mediaHandler = Controls.AtheneControl.SingleTons.MediaHandler.shared(control);
            this._activity = activity;
            this._date = moment.unix(activity.date);
            this._highResUrl = new URL(mediaHandler.host);
            let imgPathComponents = activity.imagePath.split("?");
            this._highResUrl.pathname += imgPathComponents[0].replace(/^\//, ""); // Remove every possible leading slash

            if (imgPathComponents[1]) {
                this._highResUrl.search = imgPathComponents[1];
            }

            this._lowResUrl = new URL(mediaHandler.host);
            var thumbPathComponents;

            if (activity.thumbPath) {
                thumbPathComponents = activity.thumbPath.split("?");
                this._lowResUrl.pathname += thumbPathComponents[0].replace(/^\//, ""); // Remove every possible leading slash

                if (thumbPathComponents[1]) {
                    this._lowResUrl.search = thumbPathComponents[1];
                }
            } else {
                this._lowResUrl.pathname = this._highResUrl.pathname;
                this._lowResUrl.search = this._highResUrl.search;
            }
        }

        /**
         * Returns the date of the activity as a Moment.js object
         * @return {*}
         */
        getDate() {
            return this._date;
        }

        /**
         * Returns the thumbnail as Data URI of the activity or the high-res if no thumb is available
         * A queued request can be aborted using the "abort" function
         * @return {Promise<String>}
         */
        getThumb() {
            var prms,
                cached = Controls.AtheneControl.SingleTons.ActivityImageCache.shared(this.controlUuid).get(this._lowResUrl.pathname + "#thumb");

            if (cached) {
                prms = Q.resolve(cached);

                prms.abort = function () {
                };
            } else {
                prms = Controls.AtheneControl.SingleTons.ActivityImageQueue.shared(this.controlUuid, Controls.AtheneControl.SingleTons.ActivityImageQueue.TYPE.THUMB).requestForUrl(this._lowResUrl.toString());
                prms.then(function (dataURI) {
                    Controls.AtheneControl.SingleTons.ActivityImageCache.shared(this.controlUuid).set(this._lowResUrl.pathname + "#thumb", dataURI, this._activity.date);
                    return dataURI;
                }.bind(this));
            }

            return prms;
        }

        /**
         * Returns the highRes as Data URI of the activity
         * A queued request can be aborted using the "abort" function
         * @return {Promise<String>}
         */
        getHighRes() {
            var prms,
                cached = Controls.AtheneControl.SingleTons.ActivityImageCache.shared(this.controlUuid).get(this._highResUrl.pathname);

            if (cached) {
                prms = Q.resolve(cached);

                prms.abort = function () {
                };
            } else {
                prms = Controls.AtheneControl.SingleTons.ActivityImageQueue.shared(this.controlUuid, Controls.AtheneControl.SingleTons.ActivityImageQueue.TYPE.FULL).requestForUrl(this._highResUrl.toString());
                prms.then(function (dataURI) {
                    Controls.AtheneControl.SingleTons.ActivityImageCache.shared(this.controlUuid).set(this._highResUrl.pathname, dataURI, this._activity.date);
                    return dataURI;
                }.bind(this));
            }

            return prms;
        }

        /**
         * Will Prevent a cyclic dependency violation when serializing an instance of this instance
         * @return {{getThumb: module:ActivityImage.getThumb, activity: module:ActivityImage.activity, getHighRes: module:ActivityImage.getHighRes, getDate: module:ActivityImage.getDate, controlUuid}}
         */
        toJSON() {
            return {
                controlUuid: this.controlUuid,
                activity: this._activity,
                getDate: this.getDate.bind(this),
                getThumb: this.getThumb.bind(this),
                getHighRes: this.getHighRes.bind(this)
            };
        }

    };
});
