'use strict';

define(["InAppNotificationsSettingsScreen", "PushNotificationsSettingsScreen"], function (InAppNotificationsSettingsScreen, PushNotificationsSettingsScreen) {
    return class NotificationsSettingsScreen extends GUI.TableViewScreenV2 {

        get useNewTableView() {
            return true;
        }

        constructor() {
            super($('<div class="notifications-settings-screen" />'));
            var platform = PlatformComponent.getPlatformInfoObj().platform;
            this._isPushNotificationSupported = (platform === PlatformType.Android || platform === PlatformType.IOS) && pushNotificationService.deviceSupportsPushNotifications;
            this._inAppEnabled = false;
            this._pushEnabled = false;
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        viewWillAppear() {
            return this._reloadSettings().then(() => {
                this.reloadTable();
                super.viewWillAppear(...arguments);
            });
        }

        getURL() {
            return UrlStartLocation.NOTIFICATIONS;
        }

        titleBarText() {
            return _('notifications');
        }

        reloadTable() {
            this.tableContent = [{
                rows: [{
                    content: {
                        title: _('notifications.in-app-notifications'),
                        disclosureText: this._inAppEnabled ? _('on') : _('off'),
                        disclosureColor: this._inAppEnabled ? window.Styles.colors.green : null,
                        clickable: true,
                        disclosureIcon: true
                    },
                    action: function action() {
                        this.ViewController.showState(ScreenState.InAppNotificationsSettings);
                    }.bind(this)
                }]
            }];

            if (this._isPushNotificationSupported) {
                this.tableContent[0].rows.push({
                    content: {
                        title: _('notifications.push-notifications'),
                        disclosureText: this._pushEnabled ? _('on') : _('off'),
                        disclosureColor: this._pushEnabled ? window.Styles.colors.green : null,
                        clickable: true,
                        disclosureIcon: true
                    },
                    action: function action() {
                        this.ViewController.showState(ScreenState.PushNotificationsSettings);
                    }.bind(this)
                });
            }

            return super.reloadTable();
        }

        // Private methods
        _reloadSettings() {
            Debug.PushNotification && console.log("PNS (NotificationSettingsScreen)", "_reloadSettings");
            var all = [],
                mac = ActiveMSComponent.getActiveMiniserver().serialNo;
            this._inAppEnabled = false;
            all.push(SandboxComponent.getInAppNotificationSettings().then(function (settings) {
                for (var key in settings) {
                    if (settings.hasOwnProperty(key) && settings[key]) {
                        this._inAppEnabled = true;
                        break;
                    }
                }
            }.bind(this), function () {
                return true;
            }));

            if (this._isPushNotificationSupported) {
                this._pushEnabled = false;
                all.push(pushNotificationService.isPushEnabledForMiniserver(mac).then(function () {
                    Debug.PushNotification && console.log("PNS (NotificationSettingsScreen)", "_reloadSettings - isPushEnabledForMiniserver: checkPushPermissions");
                   return pushNotificationService.checkPushPermissions().then((res) => {
                        Debug.PushNotification && console.log("PNS (NotificationSettingsScreen)", "_reloadSettings - isPushEnabledForMiniserver: true, checked!");
                        this._pushEnabled = true;
                    }, (err) => {
                        Debug.PushNotification && console.log("PNS (NotificationSettingsScreen)", "_reloadSettings - isPushEnabledForMiniserver: false, checked!");
                        this._pushEnabled = false;
                    });
                }.bind(this), function (err) {
                    Debug.PushNotification && console.log("PNS (NotificationSettingsScreen)", "_reloadSettings - isPushEnabledForMiniserver: no. " + JSON.stringify(err));
                    return true;
                }));
            }

            return Q.all(all);
        }

    };
});
