import {useFocusEffect} from "@react-navigation/native";
import {useCallback, useRef, useEffect} from "react";

const useOutsideClick = (ref, callback) => {

    let darknerRef = useRef(null);

    const handleClick = e => {
        if (ref.current) {
            let { left, top, width, height } = ref.current.getBoundingClientRect();

            if (!(e.x >= left && e.x <= left + width && e.y >= top && e.y <= top + height)) {
                callback();
            }
        }
    };

    useEffect(() => {
        if (ref.current) {
            try {
                darknerRef.current = ref.current.parentElement.parentElement.parentElement.parentElement;
            } catch (e) {
                debugger;
            }
        }
    }, [ref]);

    useFocusEffect(
        useCallback(() => {
            darknerRef.current && darknerRef.current.addEventListener("click", handleClick);

            return () => {
                darknerRef.current && darknerRef.current.removeEventListener("click", handleClick);
            };
        }, [darknerRef])
    );
};

export default useOutsideClick
