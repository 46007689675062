import { navigatorConfig, LxIndicator, useCanShowAmbientMode } from "LxComponents";
import { Dimensions, Text, StyleSheet, View, TouchableOpacity, Linking } from "react-native";
import { useState, useEffect, useRef } from "react";
import ImageViewer from "react-native-reanimated-image-viewer";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import globalStyles from "GlobalStyles";
import { OptionType } from "../hooks/useAmbientWallpaperOptions";
import { ImageSlider } from "../views/AmbientBGPicker";
import useAmbientWallpaper from "../hooks/useAmbientWallpaper";
import Icons from "IconLib";
import AmbientStackNavigator from "../AmbientStackNavigator";

export default function AmbientNewWallpaperPreview(props) {
    const {
        imageUri,
    } = { ...props, ...((props.route && props.route.params) || {}) };
    const additionalImages = useRef([]).current;
    const currentWallpaper = useAmbientWallpaper();
    const [selectedImage, setSelectedImage] = useState({ uri: imageUri, author: "", authorLink: "" });
    const [isRepositioning, setIsRepositioning] = useState(false);
    const [didLoad, setDidLoad] = useState(false);
    const ref = useRef();
    const { shouldRotate, shouldResize, notSupported } = useCanShowAmbientMode();

    useEffect(() => {
        // ensures the screensaver won't appear while this is mounted.
        let unregFn = SandboxComponent.blockAmbientAndScreensaver();
        return () => {
            unregFn && unregFn();
        }
    }, [])
   
    useEffect(() => {
        if (shouldRotate || shouldResize || notSupported) {
            props.navigation.goBack();
        }
    }, [shouldRotate, shouldResize, notSupported])

    useEffect(() => {
        if (selectedImage.uri && selectedImage.uri.length) {
            return;
        } else if (imageUri) {
            setSelectedImage({
                uri: imageUri
            })
            additionalImages.push({
                type: OptionType.DEVICE_UPLOAD,
                source: {
                    uri: imageUri
                }
            })
        } else if (currentWallpaper && currentWallpaper.source) {
            // cover for default BG
            if (!currentWallpaper.source.uri) {
                setSelectedImage({
                    uri: currentWallpaper.source
                })
            } else {
                setSelectedImage({
                    uri: currentWallpaper.source.uri,
                    author: currentWallpaper.author,
                    authorLink: currentWallpaper.authorLink
                })
            }

        }
    }, [currentWallpaper])

    const topNavCfg = {
        ...navigatorConfig({
            headerStyle: {
                height: 92
            },
            headerTransparent: true,
            title: "",
            animationType: AnimationType.HD_OVERLAY_FULLSCREEN,
            rightActions: [{
                action: ({ dimensions, props, key }) => {
                    return <Text style={[
                        styles.navButton,
                        !didLoad || isRepositioning ?
                            { color: globalStyles.colors.text.tertiary } :
                            null,
                    ]}>{_("save")}</Text>
                }
            },
            ],
            onRightAction: () => {
                if (!didLoad || isRepositioning) {
                    return;
                }
                const sizingData = ref.current.getImageData()
                PersistenceComponent.saveAmbientWallpaper(selectedImage, sizingData).then(() => {
                    props.navigation.goBack();
                })
            },

            leftActions: [{
                action: ({ dimensions, props, key }) => {
                    return <Text style={styles.navButton}> {_("close")}</Text >
                }
            }],
            onLeftAction: () => {
                props.navigation.goBack();
            },
        }),
    }

    useEffect(() => {
        props.navigation.setOptions({
            ...topNavCfg
        })
    }, [didLoad, isRepositioning]);

    const onImageSelected = (image) => {
        setSelectedImage({
            uri: image.sourceFull.uri,
            author: image.author,
            authorLink: image.authorLink
        })
    }

    const repositionCallback = (reposition) => {
        setIsRepositioning(reposition);  
    }

    const loadCallback = (didLoad) => {
        setDidLoad(didLoad);
    }

    return (
        <View>
            <GestureHandlerRootView style={{ flex: 1 }}>
                {
                    (selectedImage && selectedImage.uri) ?
                        <ImageViewer
                            imageUrl={selectedImage.uri}
                            width={Dimensions.get('window').width}
                            height={Dimensions.get('window').height}
                            loadCallback={loadCallback}
                            onRequestClose={() => { }}
                            repositionCallback={repositionCallback}
                            ref={ref}
                            scale={currentWallpaper.source.sizing.scale}
                            translateY={currentWallpaper.source.sizing.translateY}
                            translateX={currentWallpaper.source.sizing.translateX}
                        />
                    :
                        null
                }
                {
                    !didLoad ?
                        <Delayed>
                            <LoadingImageInfo />
                        </Delayed>
                        : null
                }

            </GestureHandlerRootView>

            <View style={styles.scaleButtonsContainer}>
                <TouchableOpacity onPress={() => ref.current.incScale()} style={styles.scaleButton}>
                    <Icons.Plus style={styles.buttonIcon} />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => ref.current.decScale()} style={styles.scaleButton}>
                    <Icons.Minus style={styles.buttonIcon} />
                </TouchableOpacity>
            </View>

            <View>
                {selectedImage.author ?
                    <View style={styles.sourceContainer}>
                        <Text style={styles.sourceText}>
                            Photo by&nbsp;
                            <Text style={styles.sourceTextUnderlined} onPress={() => { Linking.openURL(selectedImage.authorLink) }}>
                                {selectedImage.author}
                            </Text>
                            <Text>&nbsp;</Text>
                            on&nbsp;
                            <Text style={styles.sourceTextUnderlined} onPress={() => { Linking.openURL("https://unsplash.com/") }}>
                                Unsplash
                            </Text>
                        </Text>
                    </View> : null
                }
                <View style={styles.selectionBar}>
                    <ImageSlider onImageSelected={onImageSelected} additionalImages={additionalImages} />
                </View></View>
        </View>
    )
}

const LoadingImageInfo = () => {
    return (
        <View style={styles.loadingImageContainer}>
            <View style={styles.loadingImageBackground}>
                <LxIndicator size="large" />
                <Text style={styles.loadingImageText}>{_("loading.data")}</Text>
            </View>
        </View>
    )
}

const Delayed = ({ children, waitBeforeShow = 500 }) => {
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsShown(true);
        }, waitBeforeShow);
        return () => clearTimeout(timer);
    }, [waitBeforeShow]);

    return isShown ? children : null;
};

const styles = StyleSheet.create({
    navButton: {
        paddingHorizontal: globalStyles.spacings.gaps.bigger,
        paddingVertical: 11.5,
        borderRadius: 4,
        backgroundColor: "rgba(28, 28, 30, 0.5)",
        alignItems: "center",
        marginVertical: globalStyles.spacings.gaps.big,
        marginHorizontal: globalStyles.spacings.gaps.smallRegular,
        justifyContent: "center",
        color: "white",
        ...globalStyles.textStyles.body.bold,
        ...globalStyles.customStyles.blurredBackground,
    },
    scaleButtonsContainer: {
        position: "absolute",
        bottom: globalStyles.spacings.gaps.bigger,
        right: globalStyles.spacings.gaps.bigger,
        top: globalStyles.spacings.gaps.bigger,
        justifyContent: 'center',
        alignItems: 'center'
    },
    scaleButton: {
        padding: globalStyles.spacings.gaps.smallRegular,
        borderRadius: 4,
        backgroundColor: "rgba(28, 28, 30, 0.5)",
        alignItems: "center",
        marginVertical: globalStyles.spacings.gaps.small,
        justifyContent: "center",
        color: "white",
        ...globalStyles.textStyles.body.bold,
        ...globalStyles.customStyles.blurredBackground,
    },
    buttonIcon: {
        fill: globalStyles.colors.text.primary,
        width: globalStyles.sizes.icons.button,
        height: globalStyles.sizes.icons.button,
    },
    sourceContainer: {
        bottom: globalStyles.spacings.gaps.bigger,
        right: globalStyles.spacings.gaps.bigger,
        marginBottom: 150,
        position: "absolute",
        borderLeftWidth: 4,
        paddingLeft: globalStyles.spacings.gaps.smallRegular,
        borderLeftColor: globalStyles.colors.text.primary,
    },
    sourceText: {
        ...globalStyles.textStyles.body.bold,
        color: globalStyles.colors.text.primary,
        textShadowRadius: 4,
        textShadowColor: "rgba(0, 0, 0, 0.5)"
    },
    sourceTextUnderlined: {
        textDecorationLine: "underline",
    },
    selectionBar: {
        ...globalStyles.customStyles.blurredBackground,
        position: "absolute",
        bottom: globalStyles.spacings.gaps.bigger,
        left: globalStyles.spacings.gaps.bigger,
        right: globalStyles.spacings.gaps.bigger,
        padding: globalStyles.spacings.gaps.regular,
        borderRadius: 4
    },
    loadingImageContainer: {
        position: 'absolute',
        top: 0, left: 0,
        right: 0, bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100,
    },
    loadingImageBackground: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 268,
        height: 105,
        borderRadius: 4,
        backgroundColor: "rgba(28, 28, 30, 0.64)",
        ...globalStyles.customStyles.blurredBackground,
    },
    loadingImageText: {
        ...globalStyles.textStyles.body.bold,
        color: globalStyles.colors.text.primary,
        marginTop: globalStyles.spacings.gaps.regular
    }
})
