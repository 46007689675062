
class UserSharePDF {

    static QR_SIZE = 1024

    static getForUser(details) {
        return NavigationComp.showPopup({
            title: _('user.shared-data.popup.title'),
            message: _('user.shared-data.popup.message'),
            buttonOk: _('user.shared-data.popup.submit-button'),
            buttonCancel: false,
            color: Color.ORANGE,
            icon: Icon.CAUTION
        }, PopupType.GENERAL).then(() => {
            let { name, pdf} = this._generatePDFFor(details);

            if (PlatformComponent.isElectron) {
                loxoneControl.saveFile(null, null, name, arrayBufferToBase64String(pdf));
            } else {
                exportBase64File(name, arrayBufferToBase64String(pdf), "application/pdf");
            }
        });
    }

    static _generatePDFFor(details) {
        let msName = ActiveMSComponent.getActiveMiniserver().msName,
            name = `${msName}-${details.user.name}.pdf`,
            shareScreenInformation = this._getPDFContent(details),
            miniserverContent = shareScreenInformation.miniserverContent,
            userContent = shareScreenInformation.userContent,
            leftOffset = 48,
            leftOffsetBig = 192,
            defaultColor = '#000000',
            fontRegular = "Averta-Regular",
            fontBold = "Averta-Bold",
            fontItalic = "Averta-Italic",
            linkWidth = 0,
            underlineHeight = 2,
            subString,
            maxMiniserverTextLength = 40,
            sharePdf = new jsPDF('p', 'pt', 'a4');

        sharePdf.addFileToVFS(); // Config

        sharePdf.setFont(fontRegular);
        sharePdf.setFontSize(9); // Header Section

        sharePdf.text(_('user.shared-data.title') + SEPARATOR_SYMBOL + userContent.user.message, leftOffset, 43);
        sharePdf.addImage(LOXONE_SLOGAN_NEW_JPEG_DATA_URI, 'JPEG', 466, 40, 80, 18); // Miniserver Name

        sharePdf.setFontSize(21);
        sharePdf.setFont(fontBold);

        if (miniserverContent.msname.message.length >= maxMiniserverTextLength) {
            // 40 max length of the text
            subString = miniserverContent.msname.message.substr(0, maxMiniserverTextLength);
            miniserverContent.msname.message = subString += '...';
        }

        sharePdf.text(miniserverContent.msname.message, leftOffset, 80); // Project Location

        sharePdf.setFontSize(9);
        sharePdf.setFont(fontRegular);
        sharePdf.text(shareScreenInformation.location, leftOffset, 100); // First Header

        sharePdf.setFontSize(14);
        sharePdf.setFont(fontBold);
        sharePdf.text(_('menu.header.miniserver'), leftOffset, 153);
        sharePdf.setFontSize(11);
        sharePdf.setFont(fontRegular); // First Table (Miniserver Information)

        Object.values(miniserverContent).forEach((entry, idx) => {
            let yOffset = 183 + 22 * idx;
            sharePdf.text(entry.title, leftOffset, yOffset);

            if (entry.href) {
                sharePdf.setDrawColor(Color.RED);
                sharePdf.setTextColor(Color.RED);
                linkWidth = sharePdf.textWithLink(entry.message, leftOffsetBig, yOffset, {
                    url: entry.href
                });
                sharePdf.line(leftOffsetBig, yOffset + underlineHeight, leftOffsetBig + linkWidth, yOffset + underlineHeight);
                sharePdf.setDrawColor(defaultColor);
                sharePdf.setTextColor(defaultColor);
            } else {
                sharePdf.text(entry.message, leftOffsetBig, yOffset);
            }
        }); // Second Header

        sharePdf.setFontSize(14);
        sharePdf.setFont(fontBold);
        sharePdf.text(_('user'), leftOffset, 295);
        sharePdf.setFont(fontRegular);
        sharePdf.setFontSize(11); // Second Table (User Information)

        Object.values(userContent).forEach(function (entry, idx) {
            var yOffset = 325 + 22 * idx;
            sharePdf.text(entry.title, leftOffset, yOffset);

            if (entry.isUnknown) {
                sharePdf.setFont(fontItalic);
            } else {
                sharePdf.setFont(fontRegular);
            }

            if (entry.score === -1) {
                sharePdf.setTextColor(Color.ANTHRACITE);
                sharePdf.text(entry.message, leftOffsetBig, yOffset);
                sharePdf.setTextColor(defaultColor);
            } else {
                sharePdf.text(entry.message, leftOffsetBig, yOffset);
            }
        }.bind(this)); // Qr Code Section

        sharePdf.setFontSize(9);
        sharePdf.setTextColor(Color.ANTHRACITE);
        sharePdf.text(_("user.shared-data.scan-qr-code"), 416, 437);
        sharePdf.setTextColor(defaultColor); // Internal QR Code Square

        sharePdf.setDrawColor(Color.ANTHRACITE);

        if (shareScreenInformation.qrCodes.external) {
            sharePdf.rect(leftOffset, 461, 240, 222);
        } else {
            sharePdf.rect(leftOffset, 461, 499, 222);
        }

        sharePdf.setFontSize(14);
        sharePdf.setFont(fontBold);
        sharePdf.text(_('user.shared-data.connection.internal'), leftOffset + 24, 589, {
            maxWidth: 212
        });
        sharePdf.setFont(fontRegular);
        sharePdf.setFontSize(11);
        sharePdf.setTextColor(Color.RED);
        sharePdf.setDrawColor(Color.RED);
        linkWidth = sharePdf.textWithLink(_('user.shared-data.app.open'), leftOffset + 24, 645, {
            url: miniserverContent.internalIp.url
        });
        sharePdf.line(leftOffset + 24, 647, leftOffset + 24 + linkWidth, 647);
        sharePdf.setTextColor(defaultColor);
        sharePdf.setDrawColor(defaultColor);
        sharePdf.addImage(shareScreenInformation.qrCodes.internal, 'JPEG', leftOffset + 24, 485, 80, 80);

        if (shareScreenInformation.qrCodes.external) {
            sharePdf.setDrawColor(Color.ANTHRACITE);
            sharePdf.rect(307, 461, 240, 222);
            sharePdf.setDrawColor(defaultColor);
            sharePdf.addImage(shareScreenInformation.qrCodes.external, 'JPEG', 331, 485, 80, 80);
            sharePdf.setFontSize(14);
            sharePdf.setFont(fontBold);
            sharePdf.text(_('user.shared-data.connection.external'), 331, 589, {
                maxWidth: 212
            });
            sharePdf.setFont(fontRegular);
            sharePdf.setFontSize(11);
            sharePdf.setTextColor(Color.RED);
            sharePdf.setDrawColor(Color.RED);
            linkWidth = sharePdf.textWithLink(_('user.shared-data.app.open'), 331, 645, {
                url: miniserverContent.externalIp.url
            });
            sharePdf.line(331, 647, 331 + linkWidth, 647);
            sharePdf.setTextColor(defaultColor);
            sharePdf.setDrawColor(defaultColor);
        }

        sharePdf.setDrawColor(defaultColor);
        sharePdf.text(_('user.shared-data.download-app'), leftOffset, 699);
        sharePdf.setTextColor(Color.RED);
        sharePdf.setDrawColor(Color.RED);
        linkWidth = sharePdf.textWithLink('www.loxone.com/app', leftOffset, 717, {
            url: 'http://www.loxone.com/app'
        });
        sharePdf.line(leftOffset, 717 + underlineHeight, leftOffset + linkWidth, 717 + underlineHeight);
        sharePdf.setTextColor(defaultColor);
        sharePdf.setDrawColor(defaultColor);

        return {
            name,
            pdf: sharePdf.output("arraybuffer")
        }
    }

    static _getQRCodeDataUrl({
        text,
        logo,
        canvas
    }) {
        canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height); // http://larsjung.de/jquery-qrcode/latest/demo/

        let options = {
            // render method: 'canvas', 'image' or 'div'
            render: 'canvas',
            // version2 range somewhere in 1 .. 40
            minVersion: 5,
            maxVersion: 40,
            // error correction level: 'L', 'M', 'Q' or 'H'
            ecLevel: 'H',
            // offset in pixel if drawn onto existing canvas
            left: 0,
            top: 0,
            // size in pixel
            size: canvas.width,
            // code color or image element
            fill: '#000',
            //'#74A805',
            text: text,
            // corner radius relative to module width: 0.0 .. 0.5
            radius: 0.1,
            // quiet zone in modules
            quiet: 1,
            // modes
            // 0: normal
            // 1: label strip
            // 2: label box
            // 3: image strip
            // 4: image box
            mode: 0,
            mSize: 0.33,
            mPosX: 0.5,
            mPosY: 0.5,
            //label: 'Loxone',
            //fontname: FONT.Regular,
            //fontcolor: '#000',
            // background color or image element, null for transparent background
            background: "#fff"
        };

        if (typeof logo !== "undefined") {
            options.mode = 4;
            options.image = logo;
        }

        return $(canvas).qrcode(options)[0].toDataURL("image/jpeg");
    }

    static _getPDFContent({
        user,
        pwd,
        visuPwd,
        accessCode,
        trustPeers
    }) {
        let canvas = document.createElement('canvas');
        canvas.width = this.QR_SIZE;
        canvas.height = this.QR_SIZE;
        let miniserverContent = {},
            userContent = {},
            currentMiniserver = cloneObjectDeep(ActiveMSComponent.getActiveMiniserver()),
            objectForUrlStart = {},
            qrCodeObj = {};
        miniserverContent.msname = {
            title: _('menu.header.miniserver'),
            message: currentMiniserver.msName
        };
        miniserverContent.serialNumber = {
            title: _('miniserver.serial-number'),
            message: currentMiniserver.serialNo
        };
        objectForUrlStart.host = currentMiniserver.localUrl;
        objectForUrlStart.usr = user.name;

        let originalRemoteUrl = ActiveMSComponent.getOriginalRemoteUrl()
        // don't use this.miniserver.remoteUrl --> it might be adopted to cloud dns url
        if (!originalRemoteUrl) {
            originalRemoteUrl = currentMiniserver.remoteUrl;
        }

        if (user.trustMember) {
            objectForUrlStart.usr += "@" + user.trustMember;
        }

        if (pwd) {
            objectForUrlStart.pwd = pwd;
        }

        miniserverContent.internalIp = {
            title: _('miniserver.url.local'),
            href: CommunicationComponent.getRequestProtocol() + currentMiniserver.localUrl,
            // The href must be the plain, unmodified link without any lineBreakPossibilities added in the next line
            message: currentMiniserver.localUrl,
            url: UrlHelper.createURLStart(objectForUrlStart)
        };
        qrCodeObj.internal = this._getQRCodeDataUrl({
            text: UrlHelper.createURLStart(objectForUrlStart),
            canvas
        });
        // don't use this.miniserver.remoteUrl --> it might be adopted to cloud dns url
        if (originalRemoteUrl) {
            objectForUrlStart.host = originalRemoteUrl;
            miniserverContent.externalIp = {
                title: _('miniserver.url.remote'),
                href: CommunicationComponent.getRequestProtocol() + originalRemoteUrl,
                // The href must be the plain, unmodified link without any lineBreakPossibilities added in the next line
                message: originalRemoteUrl,
                url: UrlHelper.createURLStart(objectForUrlStart)
            };
            qrCodeObj.external = this._getQRCodeDataUrl({
                text: UrlHelper.createURLStart(objectForUrlStart),
                canvas
            });
        }

        delete currentMiniserver.host;

        if (user.trustMember) {
            userContent.trustUser = {
                title: _('media.service.add.input.username'),
                message: `${user.name}@${this._getTrustPeerName(user.trustMember, trustPeers)}`
            };
            userContent.user = {
                title: "",
                message: `${user.name}@${user.trustMember}`
            };
        } else {
            userContent.user = {
                title: _('media.service.add.input.username'),
                message: user.name
            };
        }

        userContent.pass = {
            title: _('password'),
            message: pwd ? pwd : _('user.shared-data.unknown'),
            score: pwd ? 0 : -1,
            isUnknown: true
        };
        currentMiniserver.pwd = pwd;
        userContent.visuPass = {
            title: _('visu-password'),
            message: visuPwd ? visuPwd : _('user.shared-data.unknown'),
            score: visuPwd ? 0 : -1,
            isUnknown: true
        };

        if (!!nullEmptyString(accessCode)) {
            userContent.accessCode = {
                title: _('user.access-code'),
                message: accessCode
            };
        }

        return {
            location: currentMiniserver.location,
            miniserverContent: miniserverContent,
            userContent: userContent,
            qrCodes: qrCodeObj
        };
    }

    static _getTrustPeerName(peerSerial, trustPeers) {
        return (trustPeers.find(function (peer) {
            return peer.serial === peerSerial;
        }) || {}).name || peerSerial;
    }

}

export default UserSharePDF;
