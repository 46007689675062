'use strict';

define(["LightControlEnums"], function (LightControlEnums) {
    return class SceneList extends GUI.Screen {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return $('<div class="container__scene-list"></div>');
            };

            return {
                getTemplate: getTemplate
            };
        }();
        //endregion Static

        constructor(control) {
            super(SceneList.Template.getTemplate());
            this.control = control;
        }

        titleBarText() {
            return HD_APP ? this.control.getName() : null;
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK
            };
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                this._titleBar && this._titleBar.getElement().addClass("menu--title_bar");
                this.isLightControl = this.control.type === 'LightController';

                if (HD_APP) {
                    this.element.addClass("scene-list--hd");
                }

                this.tableViewDataSource = tableViewDataSource(null, null, this);
                this.tableViewDelegate = tableViewDelegate(null, this);
                this.tableView = new GUI.SelectableTableView(this.tableViewDataSource, this.tableViewDelegate);
                this.tableView.getElement().addClass("scene-list__table-view");
                this.tableViewContent = [];
                return this.appendSubview(this.tableView);
            }.bind(this));
        }

        update(states) {
            this.tableViewContent = [];
            var scenes,
                section = {
                    rows: []
                };

            if (this.isLightControl) {
                scenes = mapToArrayOld(states.scenes, true);
                scenes.push({
                    nr: LightControlEnums.SceneNumbers.ALL_ON,
                    name: _("controls.lightcontroller.all-on")
                });
            } else {
                scenes = mapToArrayOld(this.control.details.sceneList, true);
                scenes.push({
                    nr: scenes.length + 1,
                    name: _("controls.lightcontroller.all-on")
                });
            }

            scenes.push({
                nr: 0,
                name: _("controls.lightcontroller.all-off")
            });
            var selectedCellIndex = -1;

            for (var i = 0; i < scenes.length; i++) {
                var sceneNr = parseInt(scenes[i].nr);
                var isSelected = sceneNr === states.activeSceneNr;

                if (sceneNr === 0 && i === scenes.length - 2) {
                    // Scene 0 is everything off, the last cell
                    isSelected = sceneNr === LightControlEnums.SceneNumbers.ALL_ON;
                }

                if (isSelected) {
                    selectedCellIndex = i;
                }

                section.rows.push({
                    type: GUI.TableViewV2.CellType.Special.SCENE_CELL,
                    content: {
                        title: scenes[i].name,
                        selected: isSelected,
                        nr: sceneNr,
                        showButton: i < scenes.length - 2 && this.isLightControl,
                        buttonSrc: "resources/Images/Controls/Light/edit.svg",
                        selectable: true
                    }
                });
            }

            this.tableViewContent.push(section);

            if (states.haveScenesChanged || !this.tableView.table.length) {
                this.tableView.dataSource.update(this.tableViewContent);
                this.tableView.delegate.update(this.tableViewContent);
                this._reloadPrms = this.tableView.reload().then(function () {
                    if (selectedCellIndex >= 0) {
                        this.tableView.selectCell(0, selectedCellIndex, true);
                    }

                    this._reloadPrms = null;
                }.bind(this));
            } else if (!this._reloadPrms) {
                this._reloadPrms = Q(true);
            } // The TableView is async! Await its reload, then process!


            this._reloadPrms.then(function () {
                if (states.activeSceneNr !== this.activeSceneNr) {
                    for (var i = 0; i < section.rows.length; i++) {
                        if (i !== selectedCellIndex) {
                            this.tableView.cellForSectionAndRow(0, i).updateUI(false);
                            this.tableView.selectCell(0, i, false);
                        }
                    }

                    if (selectedCellIndex >= 0) {
                        this.tableView.cellForSectionAndRow(0, selectedCellIndex).updateUI(true);
                        this.tableView.selectCell(0, selectedCellIndex, true);
                    }
                }

                if (states.activeSceneNr === LightControlEnums.SceneNumbers.MANUAL) {
                    this.tableView.deselectAll();
                }
            }.bind(this));

            this.activeSceneNr = states.activeSceneNr;
        }

        // TableViewDataSource Methods
        titleForHeaderInSection(section) {
            return _("controls.lightcontroller.scene-selection");
        }

        styleForTableView() {
            return TableViewStyle.SECTIONED;
        }

        // TableViewDelegate methods
        didCheckCell(cell, section, row, tableView, selected) {
            var sceneNr = parseInt(this.tableViewContent[section].rows[row].content.nr);

            if (this.activeSceneNr !== sceneNr) {
                if (sceneNr === LightControlEnums.SceneNumbers.ALL_OFF) {
                    this.sendCommand(Commands.LIGHTCONTROL.ALL_OFF);
                } else if (this.isLightControl && sceneNr === LightControlEnums.SceneNumbers.ALL_ON || !this.isLightControl && sceneNr === Object.keys(this.control.details.sceneList).length + 1) {
                    this.sendCommand(Commands.LIGHTCONTROL.ALL_ON);
                } else {
                    var cmd = Commands.format(Commands.LIGHTCONTROL.SELECT_SCENE, sceneNr);
                    var text = this.tableViewContent[section].rows[row].content.title;
                    this.sendCommand(cmd, null, null, false, [text]);
                }

                this.tableView.cellForSectionAndRow(section, row).updateUI(true);
                this.tableView.selectCell(section, row, true);
            }
        }

        buttonTapped(section, row, tableView) {
            this._showEditScreen();
        }

        buttonReleased(section, row, tableView) {
            this._showEditScreen();
        }

        _showEditScreen() {
            this.ViewController.showState(LightControlEnums.ScreenState.EDIT_SCENE, null, {
                control: this.control
            });
        }

    };
});
