import {useCallback, useEffect, useMemo, useState} from "react";
import {navigatorConfig, useCanShowAmbientMode} from "LxComponents";
import AmbientRoomSelector from "./AmbientRoomSelector";
import AmbientCardComp from "./AmbientCardComp";
import {Image, Text, View} from "react-native";
import globalStyles from "GlobalStyles";

function AmbientIntroductionScreen({navigation, route}) {
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const {shouldRotate, shouldResize} = useCanShowAmbientMode();

    useEffect(() => {
        setButtonDisabled(shouldRotate || shouldResize);
    }, [shouldRotate, shouldResize])

    useEffect(() => {
        navigation.setOptions({
            ...navigatorConfig({
                headerShown: false,
                animationType: AnimationType.NONE
            })
        })
    }, [])


    const leftButtonText = _('not-now');
    const leftButtonAction = useCallback(() => {
        PersistenceComponent.setAmbientOnboardingViewed(true);
        route.params.hideNavigator();
    }, [])
    const rightButtonText = _('ambient.try-out');
    const rightButtonAction = useCallback(() => {
        navigation.navigate(AmbientRoomSelector.name);
    }, [])

    const warningText = useMemo(() => {
        if (shouldResize) {
            return _('ambient.introduction.warning-1');
        }
        if (shouldRotate) {
            return _('ambient.introduction.warning-2');
        }
    }, [shouldResize, shouldRotate])

    return <AmbientCardComp leftButtonText={leftButtonText} leftButtonAction={leftButtonAction} rightButtonAction={rightButtonAction} rightButtonText={rightButtonText} rightButtonDisabled={buttonDisabled}>
        <Image style={styles.image} source={Icon.Menu.AMBIENT_INTRODUCTION}/>
        <View style={styles.textView}>
            <Text style={styles.titleStyle}>{_('ambient.introduction.title')}</Text>
            <Text style={styles.subTitleStyle}>{_('ambient.introduction.message')}</Text>
            {warningText && <Text style={styles.warningStyle}>{warningText}</Text>}
        </View>
    </AmbientCardComp>
}

const styles = {
    image: {
        width: "100%",
        height: 260,
        borderRadius: 6,
        flexDirection: "column"
    },
    textView: {
        flex: 1
    },
    titleStyle: {
        marginTop: globalStyles.spacings.gaps.regular,
        color: globalStyles.colors.white,
        ...globalStyles.textStyles.title2.bold
    },
    subTitleStyle: {
        marginTop: globalStyles.spacings.gaps.regular,
        color: globalStyles.colors.white,
        ...globalStyles.textStyles.title3.normal
    },
    warningStyle: {
        marginTop: globalStyles.spacings.gaps.regular,
        color: globalStyles.colors.orange,
        ...globalStyles.textStyles.body.default
    }
}

export default AmbientIntroductionScreen
