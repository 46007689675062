'use strict';

define([], function () {
    class DayLightSettingsScreen extends GUI.TableViewScreenV2 {
        //region Static
        static Template = function () {
            var getImageElement = function getSectionDescription() {
                return $('<img class="header__image" src="' + Icon.Light.DAY_LIGHT_PREVIEW + '">');
            };

            return {
                getImageElement: getImageElement
            };
        }(); //endregion Static

        constructor(details) {
            super($('<div />'));
            Object.assign(this, StateHandler.Mixin, ContextMenuHandler.Mixin);
            this.control = details.control;
            this._invalidUntilTime = false;
        }

        viewWillAppear() {
            var promise = super.viewWillAppear(...arguments);

            this._registerForStates(this.control.uuidAction, ['daylightconfig']);

            return promise;
        }

        viewWillDisappear() {
            this._unregisterStates(this.control.uuidAction);

            return super.viewWillDisappear(...arguments);
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            return _('controls.daylight.full-name');
        }

        styleForTableView() {
            return TableViewStyle.COMFORT_MODE_2020;
        }

        receivedStates(states) {
            if (states.daylightconfig && (this._mode !== states.daylightconfig.mode || this._from !== states.daylightconfig.from || this._until !== states.daylightconfig.until)) {
                this._mode = states.daylightconfig.mode;
                this._from = states.daylightconfig.from;
                this._until = states.daylightconfig.until;

                this._checkTimes();

                this.reloadTable();
            }
        }

        reloadTable() {
            this.tableContent = [];
            this.tableContent.push(this._getSettingsSection());
            this.tableContent.push(this._getDescriptionSection());
            return super.reloadTable(...arguments);
        }

        _getSettingsSection() {
            var section = {
                headerElement: DayLightSettingsScreen.Template.getImageElement(),
                rows: []
            };
            section.rows.push({
                type: GUI.TableViewV2.CellType.SWITCH,
                content: {
                    title: _('controls.daylight.description.title'),
                    active: this._mode === 0
                },
                onSwitchChanged: function onSwitchChanged(value) {
                    this._mode = value ? 0 : 1;

                    this._sendDayLightConfig();

                    this.reloadTable();
                }.bind(this)
            });

            if (this._mode === 1) {
                section.rows.push({
                    content: {
                        title: _('start'),
                        disclosureText: LxDate.fromMinutesSinceMidnight(this._from).format(LxDate.getTimeFormat(true)),
                        disclosureColor: window.Styles.colors.brand,
                        clickable: true
                    },
                    action: function () {
                        this._showContextMenu({
                            type: GUI.LxTimePickerContextMenu.TYPE.DURATION,
                            value: this._getTimeForPicker(this._from),
                            wheelOrder: 'hhii',
                            onPickerDisappear: function (value) {
                                this._from = value[0] * 60 + value[1];

                                this._checkTimes(true);

                                if (this._invalidUntilTime) {
                                    this._until = Math.min(this._from + 60, 60 * 24); // minutes of day

                                    if (this._from < this._until) {
                                        this._invalidUntilTime = false;
                                    }
                                }

                                this.reloadTable();
                            }.bind(this)
                        }, _('start'), null, GUI.LxTimePickerContextMenu);
                    }.bind(this)
                });
                section.rows.push({
                    content: {
                        title: _('controls.daytimer.entry.end'),
                        disclosureText: LxDate.fromMinutesSinceMidnight(this._until).format(LxDate.getTimeFormat(true)),
                        disclosureColor: this._invalidUntilTime ? window.Styles.colors.red : window.Styles.colors.brand,
                        clickable: true
                    },
                    action: function () {
                        this._showContextMenu({
                            type: GUI.LxTimePickerContextMenu.TYPE.DURATION,
                            value: this._getTimeForPicker(this._until),
                            wheelOrder: 'hhii',
                            onPickerDisappear: function (value) {
                                this._until = value[0] * 60 + value[1];

                                this._checkTimes(true);

                                this.reloadTable();
                            }.bind(this)
                        }, _('controls.daytimer.entry.end'), null, GUI.LxTimePickerContextMenu);
                    }.bind(this)
                });
            }

            return section;
        }

        _checkTimes(shouldSendConfig) {
            if (this._from >= this._until) {
                // add one hour to from start to end
                this._invalidUntilTime = this._from === 60 * 24;
            }

            if (this._until <= this._from) {
                this._invalidUntilTime = true;
            } else {
                this._invalidUntilTime = false;

                this._sendDayLightConfig();
            }

            if (!this._invalidUntilTime && shouldSendConfig) {
                this._sendDayLightConfig();
            }
        }

        _getDescriptionSection() {
            return {
                footerTitle: _('controls.daylight.description.message')
            };
        }

        _getTimeForPicker(minutes) {
            var hourFrom = parseInt(minutes / 60);
            var minuteFrom = minutes % 60;
            return [hourFrom, minuteFrom];
        }

        _sendDayLightConfig() {
            var obj = {
                    mode: this._mode,
                    from: this._from,
                    until: this._until
                },
                cmd = Commands.format(Commands.LIGHTCONTROL_V2.SET_DAYLIGHT_CONFIG, JSON.stringify(obj));
            this.control.sendCommand(cmd);
        }

    }

    GUI.DayLightSettingsScreen = DayLightSettingsScreen;
    return GUI;
});
