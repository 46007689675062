import {
    View
} from "react-native";
import {
    getAllPossibleLegacyScreens,
    UrlContext
} from "./App";
import React, {useEffect, useRef, useMemo, useCallback, useContext} from "react";
import PropTypes from "prop-types";
import {
    createStackNavigator
} from '@react-navigation/stack';
import {useIsFocused} from '@react-navigation/native';
import {
    navigatorConfig,
    useSetLegacyURLHook,
    LxReactImageView,
    LxReactRenderErrorView,
    useCCEvent
} from "LxComponents";
import {getViewControllerDummy} from "./navigation/LxReactNavigationBarHelper";
import globalStyles from "GlobalStyles";
import {useUpdateEffect} from "ahooks";
import { useFocusEffect } from '@react-navigation/native';
import {getEnumsForType} from "Controls";
import LxReactControlContent from "./LxReactControlContent/LxReactControlContent";
import {AmbientContext} from "./Components";

window.screenStateMap = {}

window.nestedScreens = [
    "LightControlContentOld",
    "LightControlContentOldHD",
    "LightV2ControlContentOld",
    "LightV2ControlContentOldHD",
]

function LxReactScreenAdapter(props) {
    const {isAmbientMode} = useContext(AmbientContext);
    const {setUrl} = useContext(UrlContext)

    const allPossibleScreenStates = getAllPossibleLegacyScreens().filter(screenState => {
        return ![
            ScreenState.RootViewController
        ].includes(screenState)
    }).concat(reactControlContents);

    const getURL = (screen) => {
        let activeMsVC = window.screenStateMap[ScreenState.ActiveMiniserver];
        let newURL;
        if (activeMsVC) {
            newURL = activeMsVC.getURL();
            let isNestedScreen = window.nestedScreens.includes(screen.name);
            if (isNestedScreen) {
                newURL += "/" + screen.getURL();
            }
        } else {
            newURL = screen.getURL();
        }
        return newURL;
    }

    const getElement = () => {
        return $(ref.current);
    }

    const getVCOptions = screen => {
        let vcName = screen.constructor.name,
            details = props.route.params,
            vcOptions;

        switch (vcName) {
            case GUI.ActiveMiniserverViewController.name:
                vcOptions = {
                    initScreenState: ScreenState.ActiveMiniserverScreen,
                    screenStateFilter: [
                        // RootVC
                        ScreenState.ActiveMiniserver,
                        ScreenState.InitialSearch,
                        ScreenState.Welcome,
                        ScreenState.Archive,
                        ScreenState.MiniserverSearch,
                        ScreenState.EnterUrl,
                        ScreenState.Credentials,
                        ScreenState.ConnectingWaiting,
                        ScreenState.Error,
                        // ActiveMSVC
                        ScreenState.FavoritesV2,
                        ScreenState.House,
                        ScreenState.Rooms,
                        ScreenState.RoomsOverview,
                        ScreenState.RoomDetail,
                        ScreenState.Categories,
                        ScreenState.CategoriesOverview,
                        ScreenState.CategoryDetail,
                        ScreenState.TrustListScreen,
                        ScreenState.Trust,
                        ScreenState.GroupCardContent,
                    ],
                    screenOptions: {
                        headerShown: false
                    }
                };
                break;
            case GUI.ControlContentViewController.name:
                //case "AudioZoneV2ControlContentViewController":
                vcOptions = getVcOptionsForCCVC(details)
                vcOptions.allowedScreens = vcOptions.allowedScreens.filter(screenState => !reactControlContents.includes(screenState))
                break;
            case GUI.AudioZoneControlContentViewControllerReactAbstractor.name:
                vcOptions = {
                    paramMap: {
                        [".*"]: details
                    },
                    allowedScreens: [
                        ScreenState.AudioZoneControlContent,
                        "Controls.AudioZoneControl.ZoneGroupScreen"
                    ]
                };
                break;
            case GUI.AudioZoneV2ControlContentViewControllerReactAbstractor.name:
                // make use of the enum to get the allowedScreenStates.
                let audioZoneV2Control = details.control || ActiveMSComponent.getStructureManager().getControlByUUID(details.controlUUID);
                let audioZoneV2ControlEnums = getEnumsForType("AudioZoneV2");
                vcOptions = {
                    paramMap: {
                        [".*"]: {
                            control: audioZoneV2Control,
                            controlUUID: audioZoneV2Control.uuidAction,
                            urlComps: details.urlComps || [],
                            ...details.contentDetails,
                        }
                    },
                    allowedScreens: [
                        ScreenState.AudioZoneV2ControlContent,
                        ...Object.values(audioZoneV2ControlEnums.AllowedScreens),
                        ...audioZoneV2Control.getReactScreens(),
                    ]
                };
                break;
            case "RoomsContentViewController":
                vcOptions = {
                    paramMap: {
                        [".*"]: details
                    },
                    allowedScreens: [
                        ScreenState.RoomsOverview,
                        ScreenState.RoomDetail
                    ]
                };
                break;
            case "CategoriesContentViewController":
                vcOptions = {
                    paramMap: {
                        [".*"]: details
                    },
                    allowedScreens: [
                        ScreenState.CategoriesOverview,
                        ScreenState.CategoryDetail
                    ]
                };
                break;
            /*case "AutomaticDesignerRuleViewController":
                vcOptions = getVcOptionsForADRuleVC(details)
                break;*/

        }

        return vcOptions;
    }

    const getVcOptionsForCCVC = details => {
        let control = details.control || ActiveMSComponent.getStructureManager().getControlByUUID(details.controlUUID),
            cCIdentifier = control.getControlContentIdentifier(),
            subControls = Object.values(control.subControls || {}),
            possibleScreenStates = [
                new RegExp(`^${control.controlType}(Control)*(Content)*(Old)*(HD)*(.*Screen)*$`, "i"),
                /^ControlActionBaseScreen$/,
                /^ControlActionScreen$/,
                /^ControlActionCellsScreen$/,
                /^ControlActionSliderScreen$/,
                /^ControlActionCardsScreen$/,
                /^ControlContentLockedScreen$/,
            ],
            enums = getEnumsForType(control.controlType);

        return {
            initScreenState: details.contentIdentifier || cCIdentifier,
            allowedScreens: [
                details.contentIdentifier || cCIdentifier,
                ...Object.keys(window.LX_REQUIRE_MODULES).filter(screenState => {
                    return possibleScreenStates.map((regex) => {
                        return regex.test(screenState);
                    }).reduce((l, r ) => {
                        return l || r;
                    }, false);
                }),
                ...subControls.map(subControl => {
                    return subControl.getControlContentIdentifier();
                }),
                ...getExtendedScreenStatesForControlIdentifier(details.contentIdentifier || cCIdentifier),
                ...control.getReactScreens(),
                ...Object.values(
                    enums.ScreenState ||
                    enums.ScreenStates ||
                    enums.SCREEN_STATE ||
                    enums.SCREEN_STATES ||
                    {}
                ),
                ...reactControlContents
            ].unique(),
            paramMap: {
                [".*"]: {
                    control: control,
                    controlUUID: control.uuidAction,
                    urlComps: details.urlComps || [],
                    ...details.contentDetails, // provide content details (e.g. used in EFM -> Meter navigation, viewType+TS syncing)
                },
                ["^GUI.ControlSettings"]: {
                    object: control,
                    controlUUID: control.uuidAction
                }
            },
            navigatorProps: {
                screenOptions: {
                    cardOverlayEnabled: false,
                    headerStyle: {
                        backgroundColor: isAmbientMode ? globalStyles.colors.transparent : globalStyles.colors.grey["600"]
                    }
                }
            }
        };
    }

    const getExtendedScreenStatesForControlIdentifier = controlIdentifier => {
        let screenStates = [];

        switch (controlIdentifier) {
            case "IRoomControllerV2021ControlContent":
                screenStates.push("IRCTempSettingsScreen");
                screenStates.push("Irc2021TempAdoptionScreen");
                screenStates.push("IRCV2DaytimerControlContentCalendar");
                screenStates.push("IRoomControllerV2OperatingModesScreen");
                screenStates.push("IRCV2DaytimerControlContentCalendar");
                screenStates.push("DaytimerControlContentEntryDetail");
                screenStates.push("DaytimerControlContentOperatingModes");
                break;
            case "FroniusControlContent":
                screenStates.push("Controls.FroniusControl.FroniusControlContentOverview");
                break;
            case "LightsceneRGBControlContent":
                screenStates.push("LightControlContentOld");
                screenStates.push("LightControlSaveSceneScreen");
                break;
            case "LightV2ControlContent":
                screenStates.push("GUI.DayLightSettingsScreen");
                break;
            case "ClimateControlContent":
                screenStates.push("Irc2021TempAdoptionScreen");
                break;
            case "ClimateUsControlContent":
                screenStates.push("Irc2021TempAdoptionScreen");
                break;
            case "PoolControlContent":
                screenStates.push("DaytimerControlContentEntryDetail");
                screenStates.push("DaytimerControlContentOperatingModes");
                screenStates.push("ServiceModeScreen");
                break;
            case "IRoomControlContent":
                screenStates.push("IRCDaytimerControlContentCalendar");
                screenStates.push("DaytimerControlContentEntryDetail");
                screenStates.push("DaytimerControlContentOperatingModes");
                break;
        }

        return screenStates;
    };

    const getVcOptionsForADRuleVC = details => {
        const {
            extension = AutomaticDesignerEnums.RULE_EXTENSION.NONE
        } = details;
        let initScreenState;

        if (hasBit(extension, AutomaticDesignerEnums.RULE_EXTENSION.TASK_RECORDER)) {
            initScreenState = ScreenState.TaskV2Details;
        } else if (hasBit(extension, AutomaticDesignerEnums.RULE_EXTENSION.SCENE)) {
            initScreenState = ScreenState.AutomaticDesigner.AddOrEditScene;
        } else {
            initScreenState = ScreenState.AutomaticDesigner.AddOrEditRule;
        }

        return {
            paramMap: {
                [initScreenState]: details
            },
            initScreenState,
            allowedScreens: [
                ScreenState.TaskV2Details,
                ScreenState.AutomaticDesigner.AddOrEditScene,
                ScreenState.AutomaticDesigner.AddOrEditRule
            ]
        }
    }

    const getIsVc = () => {
        return (screen instanceof GUI.ViewController) || vcOptions
    }

    const handleDeviceOrientation = (screen) => {
        screen.ViewController.setOrientation(screen.supportedOrientation());
    }

    // region Screen
    const ref = useRef(null),
        screenVdlPrms = useRef(null),
        screenDestroyDef = useRef(Q.defer()),
        screen = useMemo(() => {
            let screen;
            try {
                let routeName = props.route.name;
                switch (routeName) {
                    case ScreenState.AmbientControlContent:
                        routeName = ScreenState.ControlContent;
                        break;
                    case ScreenState.AmbientAudioZoneV2ControlContent:
                        routeName = GUI.AudioZoneV2ControlContentViewControllerReactAbstractor.name;
                        break;
                    case ScreenState.AmbientAudioZoneControlContent:
                        routeName = GUI.AudioZoneControlContentViewControllerReactAbstractor.name;
                        break;
                    default:
                        break;
                }
                if (routeName === ScreenState.AmbientControlContent) {
                    routeName = ScreenState.ControlContent;
                }
                screen = LxReactScreenAdapter.ScreenUtils.getScreen(routeName, props.route.params || {});
            } catch (error) {
                props.navigation.setParams({
                    error
                });
                screen = new LxReactScreenAdapter.ScreenUtils.ErrorScreen(props.route.params || {})
            }
            if ((props.route.params || {}).animationType) {
                screen._inAnimation = props.route.params.animationType;
            }
            try {
                screen._inAnimation = screen._inAnimation || screen.getAnimation();
            } catch (e) { }

            if (screen._inAnimation === AnimationType.MODAL) {
                let view = screen;
                try {
                    screen = LxReactScreenAdapter.ScreenUtils.getScreen(GUI.ModalViewController.name);
                } catch (error) {
                    props.navigation.setParams({
                        error
                    });
                    screen = new LxReactScreenAdapter.ScreenUtils.ErrorScreen(props.route.params || {})
                }
                screen.getElement().addClass("react-navigation-modal");
                screen._inAnimation = AnimationType.MODAL;
                screen._reactTempInitiScreen = view;
            }

            screen.reactNavigationProps = props;
            if ("titleBarConfig" in screen) {
                let config = screen.titleBarConfig();
                if ([
                    "daytimerCalendar"
                    ].includes(config.rightSide) ||
                    "subTitleBar" in config
                ) {
                    delete screen.reactNavigationProps;
                }
            }
            screen.setViewController(getViewControllerDummy(props));

            // What the heck is this!?
            // As we initialize the screen it also registers for backNavigation
            // Tho we might want to manually do smth in the specific implementation of the screen
            // this can be done by using the "useBackNavigation" hook.
            // By setting _boundCloseAction as a getter/setter we have full control and simply ignore all set values
            // as the react component must handle it itself!
            if (screen.constructor.ReactComp) {
                Object.defineProperty(screen, "_boundCloseAction", {
                    get() {},
                    set(boundCloseAction) {
                    }
                })
            }

            handleDeviceOrientation(screen);

            return screen;
        }, [props.route.name]);

    let vcOptions = useMemo(() => {
            return getVCOptions(screen);
        }, [screen]),
        topNavigatorConfig = {
            get headerShown() {
                if ("reactHeaderShown" in screen) {
                    return screen.reactHeaderShown;
                }
                if (getIsVc()) {
                    return false;
                } else {
                    return !!(this.title || this.leftActions || this.prompt || this.rightActions);
                }
            },
            get title() {
                try {
                    return (screen && screen.titleBarText && screen.titleBarText()) || NBR_SPACE;
                } catch {
                    return NBR_SPACE;
                }
            },
            navigation: props.navigation,
            animationType: screen._inAnimation
        },
        navigatorType;

    if (vcOptions) {
        props.navigation.setOptions({
            headerShown: false
        })
    }

    const handleVdlError = error => {
        if (error && error !== GUI.PopupBase.ButtonType.CANCEL) {
            // don't show an error if a popup has been cancelled (e.g. asking for expert mode permission)
            Debug.React.ScreenAdapter && console.error(e.stack);
            if (window.UPDATE_LEVEL !== UpdateComp.UpdateLevel.RELEASE) {
                NavigationComp.showErrorPopup(false, null, error.message || _('error.view-cant-be-loaded'));
            }
        }
        props.navigation.goBack();
    }

    // endregion

    useEffect(() => {
        if (ref.current || screen.constructor.ReactComp) {
            if (!vcOptions) {
                if (!screenVdlPrms.current) {
                    // region TopNavigator
                    let titleBarConfig = screen.titleBarConfig && screen.titleBarConfig() || {};

                    const createActionFromScreenButton = (button) => {
                        return {
                            action: ({key, props}) => {
                                let imageStyle = {
                                    height: 35,
                                    width: 35
                                }
                                let containerStyle = {};

                                if (button.showBackground) {
                                    imageStyle.width = 20;
                                    imageStyle.height = 20;
                                    containerStyle = {
                                        height: 35,
                                        width: 35,
                                        backgroundColor: globalStyles.colors.buttonDisabledBg,
                                        padding: globalStyles.spacings.gaps.smaller,
                                        borderRadius: "50%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }
                                }

                                return <LxReactImageView
                                    source={button.iconSrc}
                                    containerStyle={containerStyle}
                                    imageStyle={imageStyle}
                                    partFillColors={{
                                        path: button.iconColor || globalStyles.colors.text.secondary,
                                        circle: globalStyles.colors.stateInactiveB
                                    }}
                                    key={key}/>
                            }
                        }
                    }

                    screen.screenButtons = screen.getScreenButtons();

                    var leftScreenActions = screen.screenButtons.filter(button => button.showLeft && !button.isHidden).map(button => createActionFromScreenButton(button));
                    var rightScreenActions = screen.screenButtons.filter(button => !button.showLeft && !button.isHidden).map(button => createActionFromScreenButton(button));
                    let ogHideSubView = screen.hideSubview;
                    screen.hideSubview = (...params) => {
                        let result = ogHideSubView.call(screen, ...params);
                        let index = screen.screenButtons && screen.screenButtons.indexOf(params[0]);
                        if (index && index !== -1) {
                            updateScreenButtons(screen.screenButtons);
                        }
                        return result;
                    }

                    let ogShowSubView = screen.showSubview;
                    screen.showSubview = (...params) => {
                        let result = ogShowSubView.call(screen, ...params);
                        let index = screen.screenButtons && screen.screenButtons.indexOf(params[0]);
                        if (index && index !== -1) {
                            updateScreenButtons(screen.screenButtons);
                        }
                        return result;
                    }

                    let ogToggleSubView = screen.toggleSubview;
                    screen.toggleSubview = (...params) => {
                        let result = ogToggleSubView.call(screen, ...params);
                        let index = screen.screenButtons && screen.screenButtons.indexOf(params[0]);
                        if (index && index !== -1) {
                            updateScreenButtons(screen.screenButtons);
                        }
                        return result;
                    }

                    const updateScreenButtons = (screenButtons) => {
                        let leftButtons = screenButtons.filter(button => button.showLeft && !button.isHidden);
                        let rightButtons = screenButtons.filter(button => !button.showLeft && !button.isHidden);
                        let topNavigatorUpdateConfig = {
                            navigation: props.navigation
                        };
                        if (leftButtons) {
                            topNavigatorUpdateConfig.leftActions = leftButtons.map(button => createActionFromScreenButton(button));
                        }
                        if (rightButtons) {
                            topNavigatorUpdateConfig.rightActions = rightButtons.map(button => createActionFromScreenButton(button));
                        }
                        topNavigatorUpdateConfig.onRightAction = onRightScreenButtons;
                        topNavigatorUpdateConfig.onLeftAction = onLeftScreenButton;

                        props.navigation.setOptions(
                            navigatorConfig(topNavigatorUpdateConfig)
                        );
                    }

                    const onRightScreenButtons = (idx) => {
                        let actionButton = screen.screenButtons.filter(button => !button.isHidden)[idx + leftScreenActions.length];
                        actionButton && actionButton.emit("click", {currentTarget: null});
                    }

                    const onLeftScreenButton = () => {
                        if ("titleBarAction" in screen){
                            screen.titleBarAction.call(screen);
                        } else {
                            props.navigation.goBack();
                        }
                    }

                    if (leftScreenActions && leftScreenActions.length) {
                        topNavigatorConfig.leftActions = leftScreenActions;
                    } else if (titleBarConfig.leftSide) {
                        switch (titleBarConfig.leftSide) {
                            case TitleBarCfg.TEXT:
                                topNavigatorConfig.leftActions = [
                                    {
                                        action: "TEXT".debugify()
                                    }
                                ];
                                break;
                            case TitleBarCfg.Button.BACK:
                                topNavigatorConfig.leftActions = [
                                    {
                                        action: ({key, props}) => {
                                            return <LxReactImageView
                                                source={LxTitleBar.getResourceTitlebarButtonIcon(titleBarConfig.leftSide)}
                                                key={key} containerStyle={{ height: 16, width: 24}} imageStyle={{fill: props.tintColor}}/>
                                        }
                                    }
                                ];
                                break;
                            case TitleBarCfg.Button.DROP_DOWN:
                            case TitleBarCfg.Button.DOWN:
                            case TitleBarCfg.Button.CLOSE:
                            case TitleBarCfg.Button.HOME:
                                topNavigatorConfig.leftActions = [
                                    {
                                        action: ({key, props}) => {
                                            return <LxReactImageView
                                                source={LxTitleBar.getResourceTitlebarButtonIcon(titleBarConfig.leftSide)}
                                                key={key} imageStyle={{fill: props.tintColor, height: 24, width: 24}}/>
                                        }
                                    }
                                ];
                                break;
                            case TitleBarCfg.CUSTOM_CONTENT:
                            case TitleBarCfg.CUSTOM_LX_VIEW:
                                topNavigatorConfig.leftActions = [
                                    {
                                        action: "CUSTOM CONTENT".debugify()
                                    }
                                ];
                                break;
                        }
                    }
                    topNavigatorConfig.onLeftAction = onLeftScreenButton;

                    if (rightScreenActions && rightScreenActions.length) {
                        topNavigatorConfig.rightActions = rightScreenActions;
                        topNavigatorConfig.onRightAction = onRightScreenButtons
                    } else if (titleBarConfig.rightSide) {
                        switch (titleBarConfig.rightSide) {
                            case TitleBarCfg.Button.TICK:
                            case TitleBarCfg.Button.EDIT:
                            case TitleBarCfg.Button.CLOSE:
                            case TitleBarCfg.Button.DROP_DOWN:
                            case TitleBarCfg.Button.SEARCH:
                            case TitleBarCfg.Button.MORE:
                            case TitleBarCfg.Button.HOME:
                                topNavigatorConfig.rightActions = [
                                    {
                                        action: ({key, props}) => {
                                            return <LxReactImageView
                                                source={LxTitleBar.getResourceTitlebarButtonIcon(titleBarConfig.rightSide)}
                                                key={key} imageStyle={{fill: props.tintColor, height: 24, width: 24}}/>
                                        }
                                    }
                                ]
                                break;
                            case TitleBarCfg.Button.TEXT:
                                topNavigatorConfig.rightActions = [
                                    {
                                        action: (titleBarConfig.rightText || "").debugify()
                                    }
                                ]
                                break;
                            default:
                                if (titleBarConfig.rightIconSrc) {
                                    topNavigatorConfig.rightActions = [
                                        {
                                            action: ({key, props}) => {
                                                return <LxReactImageView source={titleBarConfig.rightIconSrc} key={key}
                                                                         imageStyle={{
                                                                             fill: props.tintColor,
                                                                             height: 24,
                                                                             width: 24
                                                                         }}/>
                                            }
                                        }
                                    ]
                                } else {
                                    topNavigatorConfig.rightActions = [
                                        {
                                            action: titleBarConfig.rightSide.debugify()
                                        }
                                    ]
                                }
                        }
                        if ("titleBarActionRight" in screen) {
                            topNavigatorConfig.onRightAction = () => {
                                screen.titleBarActionRight.call(screen);
                            }
                        }
                    }
                    props.navigation.setOptions(
                        navigatorConfig(topNavigatorConfig)
                    )
                    // endregion
                    screenVdlPrms.current = Q(screen.viewDidLoad());


                    screenVdlPrms.current.then(() => {
                        if (screen._titleBar) {
                            if ("reactNavigationProps" in screen) {
                                let ogSetTitleBarSideTexts = screen._titleBar.setTitleBarSideTexts;
                                screen._titleBar.setTitleBarSideTexts = (left, right) => {
                                    const finalConfig = {
                                        title: left,
                                        ...(right && Object.keys(right).length >= 3 && right.hasOwnProperty('rightIconSrc') && right.hasOwnProperty('rightAction') && right.hasOwnProperty('rightIconColor') ? {
                                                rightActions: [
                                                    {
                                                        action: ({dimensions, props, key}) => {
                                                            return <LxReactImageView source={right.rightIconSrc}
                                                            imageStyle={{...globalStyles.customStyles.titleBarIcon, ...{fill: right.rightIconColor}}}/>
                                                        }
                                                    }
                                                ],
                                                onRightAction: right.rightAction
                                            }
                                        : {})
                                    }
                                    props.navigation.setOptions(
                                        navigatorConfig(finalConfig)
                                    );
                                    return ogSetTitleBarSideTexts.apply(screen._titleBar, [left, right]);
                                }
                            }
                        }
                        window.screenStateMap[props.route.name] = screen;
                        props.navigation.setOptions(
                            navigatorConfig(topNavigatorConfig)
                        );
                        if (!screen.constructor.ReactComp) {
                            getElement().append(screen.getElement());
                        }
                        if (getIsVc()) {
                            // update url, so a loaded legacy screen in a vc is shown in the url
                            setUrl({
                                urlPart: getURL(screen),
                                replace: true
                            });
                        }

                        return Q(screen.viewWillAppear()).then(() => {
                            let prms = Q.resolve();
                            if (screen._inAnimation === AnimationType.MODAL) {
                                prms = Q(screen.showState(props.route.name, screen._reactTempInitiScreen, null, AnimationType.NONE));
                            }
                            return prms.then(() => Q(screen.viewDidAppear()), e => {
                                handleVdlError(e);
                            });
                        });
                    });
                }
            }
        }
        return () => {

        }
    }, screen.constructor.ReactComp ? [] : [ref.current])

    useEffect(() => {
        return () => {
            let screenName = screen.name;
            screenDestroyDef.current && screenDestroyDef.current.promise.finally(() => {
                try {
                    Debug.React.ScreenAdapter && console.warn("LxReactScreenAdapter --> destroy called");
                    screen.destroy()
                } catch (e) {
                    Debug.React.ScreenAdapter && console.log(`Attempting to destruct "${screenName}" - destroy ❌`);
                    Debug.React.ScreenAdapter && console.error(e);
                }
                Debug.React.ScreenAdapter && console.log(`Attempting to destruct "${screenName}" - ✅`);
            })
        }
    }, [])

    useUpdateEffect(() => {
        // avoid updateView calls when only an animationType is provided, this messes with old views (e.g. homekit setup
        // would try to filter an undefined accessory-list, as previously it only received updateView when a sub-sequent
        // view did modify the acc list.
        let forwardParams = {...props.route.params};
        delete forwardParams.animationType;
        if (Object.keys(forwardParams).length > 0) {
            screen && screen.updateView && screen.updateView(props.route.params);
        }
    }, [props.route.params]);

    const constCalls = useRef(Q.resolve());
    const destCalls = useRef(Q.resolve());

    const vwaCallback = useCallback(() => {
        handleDeviceOrientation(screen);

        if (!screenVdlPrms || !screenVdlPrms.current) {
            return () => {

            }
        }

        constCalls.current = screenVdlPrms.current.then(() => {
            if (screen._viewWillAppearPassed) {
                return Q.resolve();
            } else {
                return Q(screen.viewWillAppear()).finally(() => {
                    if (screen._viewDidAppearPassed) {
                        return Q.resolve();
                    } else {
                        return Q(screen.viewDidAppear());
                    }
                });
            }
        }, e => {
            handleVdlError(e);
        });
    }, [screenVdlPrms.current]);
    const vwdaCallback = useCallback(() => {
        let screenName = screen.name;
        Debug.React.ScreenAdapter && console.log(`Attempting to destruct "${screenName}" - 👷`);
        try {
            if (screen._viewDidAppearPassed) {
                Debug.React.ScreenAdapter && console.warn("LxReactScreenAdapter --> viewWillDisappear called");
                destCalls.current = Q(screen.viewWillDisappear()).then(() => {
                    Debug.React.ScreenAdapter && console.warn("LxReactScreenAdapter --> viewWillDisappear finished");
                    Debug.React.ScreenAdapter && console.warn("LxReactScreenAdapter --> viewDidDisappear called");
                    return Q(screen.viewDidDisappear()).then(() => {
                        Debug.React.ScreenAdapter && console.warn("LxReactScreenAdapter --> viewDidDisappear finished");
                        screenDestroyDef.current && screenDestroyDef.current.resolve();
                    }, e => {
                        debugger;
                        return Q.resolve();
                    });
                }, e => {
                    debugger;
                    return Q.resolve();
                });
            }
        } catch (e) {
            screenDestroyDef.current && screenDestroyDef.current.resolve();
            Debug.React.ScreenAdapter && console.log(`Attempting to destruct "${screenName}" - viewWillDisappear ❌`);
            Debug.React.ScreenAdapter && console.error(e);
        }
    }, [screenVdlPrms.current]);

    useCCEvent([
        CCEvent.Resume,
        CCEvent.Pause
    ], useCallback((event, args) => {
        if (event === CCEvent.Resume) {
            destCalls.current.then(() => {
                vwaCallback();
            })
        } else {
            constCalls.current.then(() => {
                vwdaCallback();
            })
        }
    }, [screenVdlPrms.current]));

    useFocusEffect(
        useCallback(() => {
            vwaCallback();
            return () => {
                vwdaCallback();
            }
        }, [screenVdlPrms.current])
    );

    const isInFocus = useIsFocused();

    if (isInFocus) {
        window.screenStateMap[props.route.name] = screen;

        if (getIsVc() && !screen instanceof GUI.ActiveMiniserverViewController) {
            setUrl({
                urlPart: getURL(screen),
                replace: true
            });
        }

        if (props.route.params && props.route.params.vc) {
            props.route.params.vc.currentView = screen;
        }
    }

    useSetLegacyURLHook({
        screen,
        vc: props.route.params && props.route.params.vc
    })

    if (vcOptions) {
        navigatorType = vcOptions.navigatorType || createStackNavigator()
        vcOptions.allowedScreens = (vcOptions.allowedScreens || allPossibleScreenStates).filter(screenState => {
            if ("screenStateFilter" in vcOptions) {
                let screenStateName = screenState;
                if (typeof screenState === "function") {
                    screenStateName = screenState.name;
                }
                return !vcOptions.screenStateFilter.includes(screenStateName);
            } else {
                return true;
            }
        });

        let navigatorScreens = vcOptions.allowedScreens.map(screenState => {
            let screenOptions = {},
                initialParams,
                screenCtor,
                reactComp;

            const isReactComp = typeof screenState === "function";

            if (vcOptions.paramMap) {
                initialParams = {};
                Object.keys(vcOptions.paramMap).forEach(regexOrString => {
                    let didMatch = (new RegExp(regexOrString)).test(screenState);
                    if (didMatch) {
                        initialParams = {
                            ...initialParams,
                            ...vcOptions.paramMap[regexOrString]
                        }
                    }
                })
            }

            if (vcOptions.optionsMap && vcOptions.optionsMap[screenState]) {
                screenOptions = vcOptions.optionsMap[screenState] || {};
            }

            if (vcOptions.initScreenState === screenState) {
                initialParams = {
                    ...(initialParams || {}),
                    ...vcOptions.details
                };
            }

            const tmpScreenOptions = (...params) => {
                if (typeof vcOptions.screenOptions === "function") {
                    screenOptions = {
                        ...screenOptions,
                        ...vcOptions.screenOptions(...params)
                    }
                } else if (typeof vcOptions.screenOptions === "object") {
                    screenOptions = {
                        ...screenOptions,
                        ...vcOptions.screenOptions
                    }
                }

                return navigatorConfig(screenOptions);
            }

            if (reactControlContents.includes(screenState)) {
                return <navigatorType.Screen
                    key={screenState}
                    name={screenState}
                    component={LxReactControlContent}
                    options={tmpScreenOptions}
                    initialParams={initialParams} />
            }

            if (isReactComp) {
                return <navigatorType.Screen
                    key={screenState.name}
                    name={screenState.name}
                    component={screenState}
                    options={tmpScreenOptions}
                    initialParams={initialParams}
                />
            } else {
                try {
                    screenCtor = LxReactScreenAdapter.ScreenUtils.getScreenCtor(screenState, isAmbientMode);
                    reactComp = screenCtor.ReactComp
                    if (screenCtor && "title" in screenCtor) {
                        screenOptions.title = screenCtor.title;
                    }
                    screenOptions = {
                        animationType: screenCtor.prototype.getAnimation(),
                        ...screenOptions
                    }

                } catch (e) {

                }

                if (!initialParams) {
                    initialParams = {};
                }
                initialParams.vc = screen;

                return <navigatorType.Screen
                    key={screenState}
                    name={screenState}
                    component={reactComp || LxReactScreenAdapter}
                    options={tmpScreenOptions}
                    initialParams={initialParams}
                />
            }
        })

        return (
            <navigatorType.Navigator initialRouteName={vcOptions.initScreenState}
                                     {...(vcOptions.navigatorProps || {})}>
                <navigatorType.Group name={"allowedScreens"}>
                    {navigatorScreens}
                </navigatorType.Group>
            </navigatorType.Navigator>
        )
    } else if (screen.constructor.ReactComp) {
        return React.createElement(screen.constructor.ReactComp, {
            ...props
        });
    } else {
        return (
            <View ref={ref} className={props.route.name} style={{
                flex: 1,
                ...props.style
            }}/>
        )
    }
}

const reactControlContents = [
    "AalEmergencyControlContent",
    "AalSmartAlarmControlContent",
    "AlarmControlContent",
    "AlarmCentralControlContent",
    "AlarmClockControlContent",
    "ApplicationControlContent",
    "CarChargerControlContent",
    "CentralWindowControlContent",
    "ClimateControlContent",
    "DimmerControlContent",
    "AcControlContent",
    "SpotPriceOptimizerControlContent",
    "EnergyManagerControlContent",
    "GateControlContent",
    "GateCentralControlContent",
    "HeatmixerControlContent",
    "JalousieControlContent",
    "JalousieCentralControlContent",
    "HourcounterControlContent",
    "InfoViewControlContent",
    "IrrigationControlContent",
    "MailBoxControlContent",
    "MeterControlContent",
    "MsShortcutControlContent",
    "NfcCodeTouchControlContent",
    //"MediaClientControlContent",
    "PoolControlContent",
    "PowerUnitControlContent",
    "PresenceDetectorControlContent",
    "PulseAtControlContent",
    "PushbuttonControlContent",
    "RadioControlContent",
    "SaunaControlContent",
    "SequentialControlContent",
    "SmokeAlarmControlContent",
    "SolarPumpControlContent",
    "SliderControlContent",
    "SwitchControlContent",
    "TextInputControlContent",
    "TextStateControlContent",
    "TimedSwitchControlContent",
    "TrackerControlContent",
    "UpDownLeftRightDigitalControlContent",
    "UpDownLeftRightAnalogControlContent",
    "ValueSelectorControlContent",
    "VentilationControlContent",
    "WindowControlContent",
    "WindowMonitorControlContent",
    "StatusMonitorControlContent",
    "WallboxManagerControlContent",

    "ControlActionScreen",
    "ControlActionCellsScreen",
    "ControlActionCardsScreen"
]

LxReactScreenAdapter.propTypes = {
    route: PropTypes.object.isRequired,
    navigation: PropTypes.object.isRequired
}

LxReactScreenAdapter.ScreenUtils = {
    name: "ScreenUtils",
    /**
     * https://regex101.com/r/xQVHZK/1
     * @type {*}
     */
    ViewRequire: require.context("../", true, /^(GUI\/(ActiveMiniserver|DeviceManagement|ViewController|views)|Controls\/.*content|Controls\/control.*Screen).*\.js$/),
    get ErrorScreen() {
        return class extends GUI.Screen {

            static ReactComp = props => <LxReactRenderErrorView error={props?.route?.params?.error}/>

            constructor(details) {
                super($("<div/>"));
            }
        }
    },
    getScreen(id, details) {
        let Ctor = null;

        Ctor = LxReactScreenAdapter.ScreenUtils.getScreenCtor(id);

        if (!Ctor) {
            Ctor = this._findConstructor(id);
        }

        if (Ctor) {
            return new Ctor(details);
        } else {
            throw new Error("couldn't load view, no constructor found (" + id + ")")
        }
    },
    getScreenCtor(id) {
        let idParts = id.split("."),
            concreteViewId = idParts[idParts.length - 1];

        if (HD_APP) {
            if (requirePathDefined(concreteViewId + "HD")) {
                concreteViewId += "HD";
            }
        }

        idParts.pop();
        idParts.push(concreteViewId);
        let newViewId = idParts.join(".");

        if (this._findConstructor(newViewId)) {
            return this._findConstructor(id);
        } else {
            let CtorConcreteView,
                modulePath = getRequirePathForModule(concreteViewId);
            try {
                if (modulePath) {
                    CtorConcreteView = LxReactScreenAdapter.ScreenUtils.ViewRequire(modulePath);
                    if (!("name" in CtorConcreteView)) {
                        Debug.React.ScreenAdapter && console.log(this.name, "CtorConcreteView didn't contain a name property! ❌");
                        Debug.React.ScreenAdapter && console.warn(JSON.stringify(CtorConcreteView));
                        CtorConcreteView = null;
                    }
                }

                if (!CtorConcreteView && concreteViewId in GUI) {
                    Debug.React.ScreenAdapter && console.log(this.name, `${concreteViewId} is property of GUI!`);
                    CtorConcreteView = GUI[concreteViewId];
                }
            } catch (e) {
                return null;
                Debug.React.ScreenAdapter && console.error(this.name, "Can't get Ctor! ❌");
                Debug.React.ScreenAdapter && console.error(e);
                throw new Error("can't create view! (" + concreteViewId + ")")
            }
            return CtorConcreteView;
        }
    },
    _findConstructor(id, isAmbientMode) {
        let Ctor = null;

        if (id.indexOf(".") !== -1) {
            // we have an id given
            let parts = id.split("."),
                part;
            Ctor = window[parts[0]];

            for (let i = 1; i < parts.length; i++) {
                part = parts[i];

                if (typeof Ctor === "undefined" || Ctor[part] === "undefined") {
                    return false;
                } else if (HD_APP) {
                    Ctor = Ctor[part + "HD"] || Ctor[part]; // look for HD suffix
                } else {
                    Ctor = Ctor[part];
                }
            }
        } else {
            Ctor = window[id];
        }

        if (typeof Ctor === "function") {
            return Ctor;
        }

        return false;
    }
}

export default LxReactScreenAdapter;
