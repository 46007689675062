import Svg, { Path, Rect } from "react-native-svg"

function CheckMarkChecked(props) {
    return (
        <Svg isreact="true"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            {...props}
        >
                <Rect width="24" height="24" rx="12"/>
                <Path fill-rule="evenodd" clip-rule="evenodd" d="M18.013 7.45285C18.3152 7.73615 18.3305 8.21077 18.0472 8.51296L10.5472 16.513C10.2727 16.8057 9.81656 16.8306 9.51192 16.5694L6.01192 13.5694C5.69743 13.2999 5.661 12.8264 5.93057 12.5119C6.20014 12.1974 6.67361 12.161 6.98811 12.4306L9.94347 14.9637L16.9529 7.48705C17.2362 7.18486 17.7108 7.16955 18.013 7.45285Z" fill="#EBEBF5"/>
        </Svg>
    )
}

export default CheckMarkChecked
