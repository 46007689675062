import {useEffect, useState} from "react";


export default function useOrientationChanged() {
    const [orientation, setOrientation] = useState(Orientation.UNKNOWN);


    const onOrientationChange = (isLandscape) => {
        setOrientation(isLandscape ? Orientation.LANDSCAPE : Orientation.PORTRAIT);
    }

    useEffect(() => {
        let listener = window.matchMedia("(orientation: landscape)");
        const changeFn = (e) => {
                onOrientationChange(e.matches);
            },
            changeEv = "change";
        listener.addEventListener(changeEv, changeFn);

        onOrientationChange(listener.matches);

        return () => {
            listener.removeEventListener(changeEv, changeFn);
            listener = null;
        }
    }, [])


    return orientation;
}

export const Orientation = {
    UNKNOWN: "unkown",
    LANDSCAPE: "landscape",
    PORTRAIT: "portrait"
}