define("ApplicationControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class ApplicationControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [],
                cmdText = _("controls.app.open");

            if (control.type === ControlType.WEBPAGE) {
                cmdText = _("controls.webpage.open");
            }

            let cmd = this.createCommand(cmdText, control.open.bind(control), null, null);
            cmds.push(cmd);
            return cmds;
        }
    };
});