'use strict';

class DayButton extends GUI.View {
    //region Static
    static Template = function () {
        var getTemplate = function getTemplate() {
            return $('<div class="day-button' + (HD_APP ? " day-button--hd" : "") + ' clickable">' + '   <div class="day-button__head">' + '       <div class="head__name"></div>' + '       <div class="head__icon-container">' + '           ' + ImageBox.getResourceImageWithClasses("", 'icon-container__icon') + '       </div>' + '   </div>' + '   <div class="day-button__temp-container">' + '       <div class="temp-container__max-temp"></div>' + '       <div class="temp-container__min-temp"></div>' + '   </div>' + '</div>');
        };

        return {
            getTemplate: getTemplate
        };
    }(); //endregion Static

    constructor(delegate) {
        super(DayButton.Template.getTemplate());
        this.delegate = delegate;
    }

    viewDidLoad() {
        super.viewDidLoad(...arguments);
        this.dayObj = {
            name: "",
            iconSrc: "",
            minTemp: "",
            maxTemp: ""
        };
        this.elements = {
            name: this.element.find(".head__name"),
            iconContainer: this.element.find(".head__icon-container"),
            maxTemp: this.element.find(".temp-container__max-temp"),
            minTemp: this.element.find(".temp-container__min-temp")
        };
        this.button = new GUI.LxButton(this, this.element[0], Color.STATE_INACTIVE_B, null, false);
        this.button.useElementAsActivePart();
        this.addToHandledSubviews(this.button);
    }

    viewDidAppear() {
        super.viewDidAppear(...arguments);

        if (this.delegate.daySelected) {
            this.button.onButtonTapped = this.delegate.daySelected.bind(this.delegate, this);
        }
    }

    updateDay(dayObj) {
        this.dayObj = {
            name: dayObj.name || this.dayObj.name,
            iconSrc: dayObj.iconSrc || this.dayObj.iconSrc,
            minTemp: dayObj.minTemp || this.dayObj.minTemp,
            maxTemp: dayObj.maxTemp || this.dayObj.maxTemp
        };
        this.elements.name.text(this.dayObj.name);

        if (this.dayObj.iconSrc) {
            this.elements.iconContainer.html(ImageBox.getResourceImageWithClasses(this.dayObj.iconSrc, 'icon-container__icon'));
        }

        this.elements.minTemp.text(this.dayObj.minTemp);
        this.elements.maxTemp.text(this.dayObj.maxTemp);
        this.elements.minTemp.toggle(this.dayObj.minTemp !== null && this.dayObj.minTemp !== "");
        this.elements.maxTemp.toggle(this.dayObj.maxTemp !== null && this.dayObj.maxTemp !== "");
    }

    setActive(active) {
        this.element.toggleClass("day-button--active", active);
    }

}

GUI.DayButton = DayButton;
