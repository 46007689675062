'use strict';

define([], function () {
    return class FavColorButton extends GUI.View {
        //region Static
        static Template = '<div class="fav-color-button">' + '   ' + ImageBox.getResourceImageWithClasses(Icon.Light.STAR, "fav-color-button__star") + '</div>'; //endregion Static

        constructor(colors, idx) {
            super($(FavColorButton.Template));
            this.colors = colors;
            this.idx = idx;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.colorView = new GUI.ColorView(GUI.ColorViewType.BALL, this.colors);
                this.prependSubview(this.colorView);
                this.colorView.getElement().addClass("fav-color-button__color-view");
                this.button = new GUI.LxRippleButton(this.element);
                this.button.on(GUI.LxRippleButton.CLICK_EVENT, this._respondToTap.bind(this));
                this.addToHandledSubviews(this.button);
            }.bind(this));
        }

        setEnabled(enabled) {
            this.button.setEnabled(enabled);
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function () {
                this.colorView.setColors(this.colors);
            }.bind(this));
        }

        viewDidAppear() {
            return super.viewDidAppear(...arguments).then(function () {
                this.colorView.setColors(this.colors);
            }.bind(this));
        }

        _respondToTap() {
            this.onFavColorButtonTapped && this.onFavColorButtonTapped(this.idx);
        }

        setColors(colors) {
            this.colors = colors;
            return this.colorView.setColors(colors);
        }

    };
});
