'use strict';
/*
 iconSrc
 iconColor
 time           // milliseconds in utc
 title
 message
 clickable (set to true)

 markNotificationAsRead(uid)
 */

GUI.TableViewV2.CellType.NOTIFICATION = "NotificationCell";

class NotificationCell extends GUI.TableViewV2.Cells.BaseCell {
    //region Static
    static Template = function () {
        var getTemplate = function getTemplate(content) {
            return $('<div class="content__rows">' + '   <div class="rows__row-0">' + prettyPrintTime(content.time) + '</div>' + '   <div class="rows__row-1">' + '       ' + ImageBox.getResourceImageWithClasses(content.iconSrc, "row-1__icon") + '       <div class="row-1__title">' + content.title.encodeSpecialHtmlEntities() + '</div>' + // content.title may contain characters that will invalidate the HTML structure (<, >)
                '   </div>' + '   <div class="rows__row-2">' + content.message.encodeSpecialHtmlEntities() + '</div>' + // content.message may contain characters that will invalidate the HTML structure (<, >)
                '</div>' + '<div class="content__mark-read">' + '   ' + ImageBox.getResourceImageWithClasses("resources/Images/General/Notifications/button-cancel.svg", "mark-read__icon") + '</div> ');
        };

        return {
            getTemplate: getTemplate
        };
    }(); //endregion Static

    constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
        super(...arguments);
        this.element.addClass("notification-cell");
    }

    viewDidLoad() {
        return super.viewDidLoad(...arguments).then(function () {
            this.contentPlaceholder.append(NotificationCell.Template.getTemplate(this.content));
            this.elements = this.elements || {};
            this.elements.row1 = this.contentPlaceholder.find('.content__rows .rows__row-1');
            this.elements.leftIcon = this.contentPlaceholder.find('.content__rows .rows__row-1 .row-1__icon');
            this.elements.markAsReadBtn = this.contentPlaceholder.find('.content__mark-read');
            this.setIconColor(this.content.iconColor);
            this.markAsReadBtn = new GUI.LxRippleButton(this.elements.markAsReadBtn);
            this.addToHandledSubviews(this.markAsReadBtn);
            this.markAsReadBtn.on(GUI.LxRippleButton.CLICK_EVENT, this._handleMarkAsRead.bind(this));
        }.bind(this));
    }

    clickableAllowed() {
        return true;
    }

    setIcon(iconSrc) {
        this.elements.leftIcon.remove();
        this.elements.leftIcon = $(ImageBox.getResourceImageWithClasses(iconSrc, "row-1__icon"));
        this.elements.row1.prepend(this.elements.leftIcon);
    }

    setIconColor(iconColor) {
        this.elements.leftIcon.css('fill', iconColor);
    }

    _handleMarkAsRead() {
        this.delegate.markNotificationAsRead.call(this.delegate, this, this.sectionIdx, this.rowIdx, this.tableView, this.content.uid);
    }

}

GUI.TableViewV2.Cells.NotificationCell = NotificationCell;
