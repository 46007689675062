import { useControl, LxReactImageView, DateViewType } from "LxComponents"
import { View } from "react-native";
import globalStyles from "GlobalStyles"
import PropTypes from "prop-types";
import { useMemo } from "react"
import LiveMeterHeaderValue from "./liveMeterHeaderValue"
import StatisticsMeterHeaderValue from "./statisticsMeterHeaderValue";

export default function MeterV2TotalValues({ controlUUID, viewInfo }) {
    const control = useControl(controlUUID);

    const timestamp = viewInfo.manuallySelectedTs || viewInfo.ts

    const dateString = (type) => {
        const moment = ActiveMSComponent.getMomentFromUtcTimestamp(timestamp)

        if (type === DateViewType.Day) {
            return moment.format(DateType.Date);
        } else if (type === DateViewType.Week) {
            const startOfWeekFull = ActiveMSComponent.getMomentFromUtcTimestamp(timestamp).startOf('isoWeek');
            const endOfWeekFull = ActiveMSComponent.getMomentFromUtcTimestamp(timestamp).endOf('isoWeek');
            const dateFormat = LxDate.getDateFormat(DateType.DayMonth);
            const startOfWeek = startOfWeekFull.format(dateFormat);
            const endOfWeek = endOfWeekFull.format(dateFormat);
            return `${startOfWeek}-${endOfWeek}`;
        } else if (type === DateViewType.Month) {
            return moment.format(DateType.MonthYear);
        } else if (type === DateViewType.Year) {
            const year = moment.format(DateType.Year);
            return `${_("dateTime.year")} ${year}`;
        }
    }

    const getViewInfo = (viewInfo, range) => {
        let newViewInfo = ActiveMSComponent.getStartAndEndOfAsUnixUtcTimestamp(timestamp, range)
        newViewInfo.vt = viewInfo.vt
        return newViewInfo
    }

    const getStateOrViewInfo = (state, viewInfo, dateViewType) => {
        if (ActiveMSComponent.isUnixUtcTsWithinCurrentRange(timestamp, dateViewType)) {
            return { state: state }
        } else {
            return { viewInfo: getViewInfo(viewInfo, dateViewType) }
        }
    }

    const getTotalItems = (viewType) => {
        if (viewType === DateViewType.Live || viewType === DateViewType.Day) {
            return [
                {
                    title: dateString(DateViewType.Day),
                    ...getStateOrViewInfo("totalDayValue", viewInfo, DateViewType.Day)
                },
                {
                    title: dateString(DateViewType.Month),
                    ...getStateOrViewInfo("totalMonthValue", viewInfo, DateViewType.Month)
                },
                {
                    title: dateString(DateViewType.Year),
                    ...getStateOrViewInfo("totalYearValue", viewInfo, DateViewType.Year)
                }
            ]
        } else if (viewType === DateViewType.Week) {
            return [
                {
                    title: dateString(DateViewType.Week),
                    ...getStateOrViewInfo("totalWeekValue", viewInfo, DateViewType.Week)
                },
                {
                    title: dateString(DateViewType.Month),
                    ...getStateOrViewInfo("totalMonthValue", viewInfo, DateViewType.Month)
                },
                {
                    title: dateString(DateViewType.Year),
                    ...getStateOrViewInfo("totalYearValue", viewInfo, DateViewType.Year)
                }
            ]
        } else if (viewType === DateViewType.Month) {
            return [
                {
                    title: dateString(DateViewType.Month),
                    ...getStateOrViewInfo("totalMonthValue", viewInfo, DateViewType.Month)
                },
                {
                    title: dateString(DateViewType.Year),
                    ...getStateOrViewInfo("totalYearValue", viewInfo, DateViewType.Year)
                }
            ]
        } else if (viewType === DateViewType.Year) {
            return [
                {
                    title: dateString(DateViewType.Year),
                    ...getStateOrViewInfo("totalYearValue", viewInfo, DateViewType.Year)
                },
                { title: _("meter.total"), state: "totalValue" }
            ]
        } else if (viewType === DateViewType.Lifetime) {
            return [
                { title: _("meter.total"), state: "totalValue" }
            ]
        }
    }

    const renderTotalItems = () => {
        const items = useMemo(() => getTotalItems(viewInfo.vt), [viewInfo])
        return items.map((item) => {
            if (item.state) {
                return <LiveMeterHeaderValue
                    state={item.state}
                    controlUUID={controlUUID}
                    text={item.title}
                />
            } else {
                return <StatisticsMeterHeaderValue
                    text={item.title}
                    controlUUID={controlUUID}
                    viewInfo={item.viewInfo || null} />
            }
        })
    }

    return <View style={styles.rootCntr}>
        <View style={styles.iconCntr}>
            <LxReactImageView containerStyle={styles.icon} source={control.getIcon()} />
        </View>
        <View style={styles.totalOverview}>
            {renderTotalItems()}
        </View>
    </View>
}

MeterV2TotalValues.propTypes = {
    controlUUID: PropTypes.string.isRequired
}

const styles = {
    rootCntr: {
        flexDirection: "column",
        marginBottom: globalStyles.sizes.small
    },
    iconCntr: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: 138
    },
    icon: {
        justifyContent: "center",
        alignItems: "center",
        width: 70,
        height: 70,
        fill: globalStyles.colors.stateActive
    },
    totalOverview: {
        flexDirection: "row"
    },
    totalItem: {
        flexDirection: "column",
        flex: 1,
        marginLeft: globalStyles.sizes.smallest,
        marginRight: globalStyles.sizes.smallest,
        justifyContent: "center"
    },
    itemValueCntr: {
        flexDirection: "row",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "flex-end"
    },
    itemValue: {
        ...globalStyles.textStyles.footNote.default,
        fontSize: globalStyles.fontSettings.sizes.large,
        color: globalStyles.colors.stateActive
    },
    itemUnit: {
        ...globalStyles.textStyles.footNote.default,
        color: globalStyles.colors.stateActive,
        marginBottom: 2
    },
    itemTitle: {
        ...globalStyles.textStyles.footNote.default,
        color: globalStyles.colors.text.primary,
        fontSize: globalStyles.fontSettings.sizes.medium,
        textAlign: "center"
    }
}