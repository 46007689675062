'use strict';

define(["AutomaticDesignerTableContentHelper"], function (AutomaticDesignerTableContentHelper) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var SEARCH_HISTORY_STORAGE = "AutomaticDesignerHistoryStorage";
    return class AutomaticDesignerSearchScreen extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div/>'));
            this.screenType = details.screenType;
            this.WANTED_TYPES = AutomaticDesignerEnums.KNOWN_TYPES[this.screenType.toUpperCase()]; // prepare for keeping track of search keywords.

            this._loadLastUsedKeywords();
        }

        viewDidLoad() {
            // Initially show the last selected content
            this.tableContent = this._createLastSearchesTableContent();
            return super.viewDidLoad(...arguments);
        }

        titleBarText() {
            return _("search.title");
        }

        titleBarConfig() {
            var cfg = {},
                inputCfg = {
                    type: GUI.LxInputEnum.Type.SEARCH,
                    leftIconSrc: Icon.SEARCH,
                    placeholder: _("search.title"),
                    style: GUI.LxInputStyle.ROUNDED_SQUARE,
                    resetButton: true,
                    autoFocus: true,
                    isDark: true
                };
            this.searchInput = new GUI.LxInput(inputCfg, this);

            if (super.titleBarConfig) {
                cfg = super.titleBarConfig(...arguments);
            }

            cfg.leftSide = TitleBarCfg.CUSTOM_LX_VIEW;
            cfg.leftSideView = this.searchInput;
            cfg.rightSide = TitleBarCfg.TEXT;
            cfg.rightText = _('cancel');
            return cfg;
        }

        titleBarActionRight() {
            this.ViewController.navigateBack();
        }

        getAnimation() {
            return AnimationType.FADE;
        }

        viewDidAppear() {
            if (this.searchInput) {
                this.searchInput.onSubmit = function onSubmit(value) {
                    this.searchInput.removeFocus();

                    this._onSubmit(value, true);
                }.bind(this);

                this.searchInput.onTextChanged = function onSubmit(value) {
                    this._onSubmit(value, false);
                }.bind(this);
            }

            return super.viewDidAppear(...arguments);
        }

        /**
         * The user did hit enter - this is when the search must be triggered immediately.
         * @param value
         * @param force
         * @private
         */
        _onSubmit(value, force) {
            if (value === "") {
                this._clearSearch();
            } else {
                if (value && this._prevSearchTerm !== value && nullEmptyString(value.trim())) {
                    this._searchTimeout && clearTimeout(this._searchTimeout);
                    this._searchTimeout = null;
                    this._searchTimeout = setTimeout(function () {
                        this._searchTimeout = null;
                        this._prevSearchTerm = value;
                        this.inputText = value;

                        this._launchSearchFor(value);
                    }.bind(this), force ? 0 : 250);
                }
            }
        }

        _clearSearch() {
            this._searchTimeout && clearTimeout(this._searchTimeout);
            this._searchTimeout = null;
            this._prevSearchTerm = null;
            this.tableContent = this._createLastSearchesTableContent();
            this.reloadTable();
        }

        _launchSearchFor(value) {
            var result = AutomaticDesignerComponent.searchFor(value, this.screenType);

            if (result.count) {
                this._storeKeyword(value);
            }

            this.tableContent = this._tableContentForResult(result, value);
            this.reloadTable();
        }

        _tableContentForResult(res) {
            if (res.count) {
                var wantedTypes = Object.values(this.WANTED_TYPES),
                    tableContent = []; // We always want to search for these types too

                wantedTypes.push({
                    STRING: "roomsOrCats"
                });
                wantedTypes.push({
                    STRING: GroupTypes.ROOM
                });
                wantedTypes.push({
                    STRING: GroupTypes.CATEGORY
                });
                wantedTypes.push({
                    STRING: "capabilities"
                }); // Scenes should not be shown as its own category, but just as capabilities

                wantedTypes = wantedTypes.filter(function (type) {
                    return type.STRING !== "scenes";
                });
                wantedTypes.forEach(function (wantedType) {
                    var wantedResult = res[wantedType.STRING],
                        section = {
                            headerTitle: AutomaticDesignerComponent.getNameForType(wantedType.STRING, this.screenType),
                            rows: []
                        };

                    if (wantedResult && wantedResult.length) {
                        section.rows = wantedResult.map(function (result, idx) {
                            return this._getCellForItemAndType(result.item, wantedType.STRING, idx);
                        }.bind(this));
                        tableContent.push(section);
                    }
                }.bind(this));
                return tableContent;
            } else {
                return this._createNoResultTableContent();
            }
        }

        /**
         * Creates a section that will indicate that there have not been any matches.
         * @returns {{rows: [*]}}
         * @private
         */
        _createNoResultTableContent() {
            return [{
                footerTitle: _("search.matches", {
                    count: 0
                }),
                rows: []
            }];
        }

        _getCellForItemAndType(item, type, idx) {
            var cell, intType;

            if (type === "capabilities") {
                if (item.capability.hasOwnProperty("intType")) {
                    intType = item.capability.intType;
                } else if (item.sourceItem && item.sourceItem.intType) {
                    intType = item.sourceItem.intType[idx];
                }

                cell = {
                    type: GUI.TableViewV2.CellType.Special.AUTOMATIC_DESIGNER_CELL,
                    content: {
                        descID: item.capability.descID,
                        navigationPath: type + "." + idx,
                        editMode: false,
                        fromSearch: true,
                        lastSelectedEntry: item.sourceItem,
                        screenType: this.screenType,
                        intType: intType
                    }
                };
            } else {
                cell = AutomaticDesignerTableContentHelper.getCellPrototypeForEntry(item, type, this.screenType, true);
                cell.action = this._didSelectSearchResult.bind(this, item, type);
            }

            return cell;
        }

        _didSelectSearchResult(item, type) {
            if (item.hasOwnProperty("showAdFunc") && typeof item.showAdFunc === "function" && item.showAdFunc()) {// Do nothing if showAdFunc returns true we show the Ad
            } else {
                var screenState = ScreenState.AutomaticDesigner.RuleMenuScreen,
                    details = {
                        navigationPath: AutomaticDesignerComponent.getPathForItemAndType(item, this.screenType, type),
                        screenType: this.screenType,
                        entry: item
                    }; // Check if the item is an Automatic designer item or not

                if (Object.values(this.WANTED_TYPES).find(function (typeObj) {
                    return typeObj.STRING === type;
                })) {
                    details.navigationPath = AutomaticDesignerComponent.getPathForItemAndType(item, this.screenType, type);
                } else {
                    // its either room or category, handle it!
                    details.navigationPath = AutomaticDesignerComponent.getPathForItemAndType(item, this.screenType, this.WANTED_TYPES.FUNCTIONS.STRING);
                }

                this.ViewController.showState(screenState, null, details);
            }
        }

        _loadLastUsedKeywords() {
            this._lastUsedKeywords = PersistenceComponent.getForMiniserver(SEARCH_HISTORY_STORAGE) || [];
        }

        _saveLastUsedKeywords() {
            PersistenceComponent.saveForMiniserver(SEARCH_HISTORY_STORAGE, this._lastUsedKeywords);
        }

        _storeKeyword(keyword) {
            var index = this._lastUsedKeywords.indexOf(keyword); // remove the keyword from the list, if it is already on it.


            if (index >= 0) {
                this._lastUsedKeywords.splice(index, 1);
            } // insert the new one at position 0


            this._lastUsedKeywords.splice(0, 0, keyword); // ensure it doesn't exceed 4 keywords;


            if (this._lastUsedKeywords.length > 4) {
                this._lastUsedKeywords.splice(3, this._lastUsedKeywords.length - 4);
            } // update the persisted file.


            this._saveLastUsedKeywords();
        }

        _createLastSearchesTableContent() {
            var section = {
                headerTitle: _("search.last-used"),
                sectionRightButtonTitle: _("delete"),
                sectionRightButtonColor: window.Styles.colors.brand,
                rightSectionButtonTapped: function () {
                    this._lastUsedKeywords = [];

                    this._saveLastUsedKeywords();

                    this.tableContent = this._createLastSearchesTableContent();
                    this.reloadTable();
                }.bind(this),
                rows: []
            };
            this._lastUsedKeywords && this._lastUsedKeywords.forEach(function (keyword) {
                section.rows.pushObject({
                    content: {
                        title: keyword,
                        color: window.Styles.colors.brand
                    },
                    action: function () {
                        this.searchInput.setValue(keyword);
                        this.searchInput.submit();
                    }.bind(this),
                    type: GUI.TableViewV2.CellType.LIGHT
                });
            }.bind(this));
            return [section];
        }

    };
});
