'use strict';

define([], function () {
    return {
        InputFlags: {
            ChargingAllowed: 1, // = bit 0
            CarConnected: 2, // = bit 1
            CurrentPowerConnected: 4, // = bit 2
            MeterConnected: 8, //bit 3
            ChargeActive: 16 //bit 4
        }
    };
});
