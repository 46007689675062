'use strict';

define([], function (base, baseCtor) {
    return class SelectorScreenWrapper extends GUI.SelectorScreen {
        constructor() {
            super(...arguments);
        }

    };
});
