import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import PropTypes from "prop-types";
import globalStyles from "GlobalStyles";
import {View} from 'react-native';

function LxReactCircularProgressbar(props) {

    return (
        <View style={styles.container}>
            <CircularProgressbar
                value={props.value}
                styles={buildStyles({
                    pathColor: props.color,
                    trailColor: globalStyles.colors.text.tertiary,
                    strokeLinecap: "butt"
                })}
            />
        </View>
    )
}

const styles = {
    container: {
        height: globalStyles.sizes.icons.small,
        width: globalStyles.sizes.icons.small
    }
}

export default LxReactCircularProgressbar;


LxReactCircularProgressbar.propTypes = {
    value: PropTypes.number,
    color: PropTypes.string
}
