'use strict';

define(["AudioZoneV2ControlEnums", "MediaBrowserV2Service"], function (AudioZoneV2ControlEnums, MediaBrowserV2Service) {
    class MediaBrowserV2Service7 extends MediaBrowserV2Service {
        //region Static
        static isRadio() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Service.isRadio.apply(this, arguments);
        }

        static getCellFromContentTypeItem() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Service.getCellFromContentTypeItem.apply(this, arguments);
        }

        static getCellButtonIconSrc() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Service.getCellButtonIconSrc.apply(this, arguments);
        }

        static getCellButtonIconColor() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Service.getCellButtonIconColor.apply(this, arguments);
        }

        static getCellTypeForItemCellAtIndex() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Service.getCellTypeForItemCellAtIndex.apply(this, arguments);
        }

        static getIconObjForItem() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Service.getIconObjForItem.apply(this, arguments);
        } //endregion Static


        constructor(details) {
            super(...arguments);
        }

        // whether or not the lastSelectedItem should present an add button when shown in AddMediaViewControllerV2Base
        _showAddPlaylist() {
            var result;

            if (this.lastSelectedItem) {
                // don't present likedsongs-list as addable
                result = !this.control.audioserverComp.isSpotifyLikedSongsItem(this.lastSelectedItem);
            } else {
                result = super._showAddPlaylist(...arguments);
            }

            return result;
        }

    }

    // FileType = 7 = PLAYLIST_BROWSABLE
    Controls.AudioZoneV2Control.MediaBrowserV2Service7 = MediaBrowserV2Service7;
    return Controls.AudioZoneV2Control.MediaBrowserV2Service7;
});
