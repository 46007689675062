'use strict';
/*
    Description of the content: (Base content is excluded here, please check the base implementation for further information)
    content:
        parentControl           -> The parentControl, used to send commands
        control                 -> the control, used to send commands
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.IRCV2021TimeLineCell = "IRCV2021TimeLineCell";

    class IRCV2021TimeLineCell extends GUI.TableViewV2.Cells.IRCV2TimeLineCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        adoptElementForEntry(entryElement, entry) {
            if (entry.needActivate) {
                entryElement.addClass("entry--needs-activation");
            }

            if (entry.isTempActive && entry.isActive) {
                entryElement.addClass("entry--activated");
            }
        }

        getColorForEntry(entry) {
            return this.content.control.getColorOfIRCTempMode(entry.tempMode, !(entry.isActive || entry.reason), entry.needActivate && !entry.isTempActive // only say it needs to be activated, if it isn't already
            );
        }

        _sanitizeDaytimerEntries(entry) {
            var sanitizedEntry = super._sanitizeDaytimerEntries(...arguments),
                states = this.content.control.getStates();

            sanitizedEntry.isTempActive = entry.tempMode === states.activeMode;
            return sanitizedEntry;
        }

    }

    GUI.TableViewV2.Cells.IRCV2021TimeLineCell = IRCV2021TimeLineCell;
    return GUI;
}(window.GUI || {});
