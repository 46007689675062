import {createStackNavigator} from "@react-navigation/stack";
import InternalSelectorScreen from "./InternalSelectorScreen";
import {createContext, useEffect, useMemo, useState} from "react";
import {useNavigation} from "@react-navigation/native";

export const SelectionContext = createContext({})

function NewSelectorScreen(props) {
    const {
        title,
        onSelected,
        autoClose,
        dispatchSelectionChange = false,
        createNewPlaceholder = _("device-learning.create-new"),
        createNewRegex = Regex.NAME,
        createNewFilterRegex = Regex.NAME_FILTER,
        options,
        createFn,
        animationType,
        allowMultiple = false,
        selectedId = [],
        isSubSelection = false,
        getCustomFilteredContent,
        filterTitle,
        maxItemCount = -1,
        onMaxItemsReached,
        headerShown = true,
        withoutHorizontalSpaces = false
    } = {...props, ...((props.route && props.route.params) || {})};

    const navigation = useNavigation();

    const [internalSelectedIds, setInternalSelectedIds] = useState(() => {
        let initialValue = null
        if (selectedId) {
            initialValue = Array.isArray(selectedId) ? selectedId : [selectedId];
        } else {
            initialValue = []
        }
        return initialValue
    });

    const updateSelectedIds = (newId, checked = true, replace = false) => {
        if (newId) {
            if (replace) {
                const newSelectedIds = Array.isArray(newId) ? newId : [newId];
                if (!newSelectedIds.equals(internalSelectedIds)) {
                    setInternalSelectedIds(newSelectedIds)
                }
            } else {
                let newSelectedIds = internalSelectedIds;
                if (checked) {
                    newSelectedIds.push(newId)
                } else {
                    const controlIndex = newSelectedIds.indexOf(newId);
                    if (controlIndex !== -1) {
                        newSelectedIds.splice(controlIndex, 1)
                    }
                }
                setInternalSelectedIds(newSelectedIds)
            }
        }
    }

    const triggerOnSelected = (newOptionId, selected = true) => {
        let selectedIdsOfOriginType = newOptionId || internalSelectedIds;
        if (!allowMultiple && Array.isArray(selectedIdsOfOriginType)) {
            selectedIdsOfOriginType = selectedIdsOfOriginType[0];
        }
        selectedIdsOfOriginType && onSelected && onSelected(selectedIdsOfOriginType, selected);
    }

    const selectionContextValue = useMemo(() => {
        return {
            title,
            autoClose,
            dispatchSelectionChange,
            createNewPlaceholder,
            createNewRegex,
            createNewFilterRegex,
            baseOptions: options,
            createFn,
            animationType,
            allowMultiple: allowMultiple || Array.isArray(selectedId),
            internalSelectedIds,
            isSubSelection,
            getCustomFilteredContent,
            filterTitle,
            maxItemCount,
            onMaxItemsReached,
            headerShown,
            withoutHorizontalSpaces,
            updateSelectedIds,
            onSelected: triggerOnSelected
        }
    }, [internalSelectedIds, options])

    useEffect(() => {
        setInternalSelectedIds(selectedId || [])
    }, [selectedId])

    navigation.setOptions({
        headerShown: false
    })

    const SelectionStack = createStackNavigator();

    return <SelectionContext.Provider value={selectionContextValue}>
        <SelectionStack.Navigator screenOptions={{
            headerShown: false,
            cardOverlayEnabled: false
        }}>
            <SelectionStack.Screen name={InternalSelectorScreen.name} component={InternalSelectorScreen}/>
        </SelectionStack.Navigator>
    </SelectionContext.Provider>
}

export default NewSelectorScreen
