import { ScreenState } from "./intercomControlEnums";

export default class IntercomControlStatesSrc {
    static getStateSections(control, states) {
        let sections = [],
            section = {},
            rows = [];

        if (control.hasVideoInfo()) {
            return sections;
        }

        if (states.hasLastActivities) {
            rows.push({
                content: {
                    title: _("controls.intercom.last-activity"),
                    disclosureIcon: true
                },
                type: GUI.TableViewV2.CellType.GENERAL,
                action: this.presentLastActivities.bind(this, control)
            });
        }

        rows.push({
            content: {
                title: _("settings"),
                disclosureIcon: true
            },
            type: GUI.TableViewV2.CellType.GENERAL,
            action: this.presentSettingsWindow.bind(this, control)
        });

        section.rows = rows;
        sections.push(section);

        return sections;
    }

    static presentLastActivities(control) {
        Debug.Control.Intercom.General && console.log(this.name, "presentLastActivities");
        NavigationComp.showState(ScreenState.MISSED_LIST, { control });
    }

    static presentSettingsWindow(control) {
        let screenState = ScreenState.ABOUT;

        if (control.hasAudio() && this.hasRequiredMediaDevices) {
            screenState = ScreenState.SETTINGS;
        }

        NavigationComp.showState(screenState, {control});
    }
}