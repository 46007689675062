define(["AutopilotEnums", "AutopilotUtility"], function (AutopilotEnums, AutopilotUtility) {
    var instance = null;

    /**
     * The AutopilotCommunicator wraps the whole communication between the app and the miniserver
     * used by the autopilot generator
     * @constructor
     */

    function AutopilotCommunicator() {
        if (instance !== null) {
            throw new Error("Cannot instantiate more than one MySingleton, use MySingleton.getInstance()");
        }
    }

    AutopilotCommunicator.prototype = {
        /**
         * Returns the rules from the buffer. If there are no rules in the buffer already or the buffer is out of date, the rules
         * will be loaded from the miniserver
         * @returns {Promise}
         */
        getRules: function getRules(rejectIfUpToDate) {
            Debug.Autopilot.General && console.log("AutopilotCommunicator: getRules");
            var def = Q.defer(),
                states = SandboxComponent.getStatesForUUID(AUTOMATIC_DESIGNER_STATES),
                hasBufferedRules = this.bufferedRules && this.bufferedRules.length > 0,
                rulesAreUpToDate = hasBufferedRules && states && states.states && typeof this._changeDate === "number" && this._changeDate === states.states.changeDate;

            if (rulesAreUpToDate) {
                Debug.Autopilot.General && console.log(" - from cache");

                if (rejectIfUpToDate) {
                    def.reject(AutopilotEnums.ReloadRulesUpToDate);
                } else {
                    def.resolve(this.bufferedRules);
                }
            } else {
                Debug.Autopilot.General && console.log(" - request");

                this._getRules().then(function (rules) {
                    VendorHub.Usage.autopilot(FeatureUsage.Autopilot.LIST, null, Object.values(rules).length);
                    def.resolve(rules);
                }.bind(this), function (err) {
                    def.reject(err);
                });
            }

            return def.promise;
        },

        /**
         * Loads all rules from the miniserver and stores the rules in a buffer.
         * The buffer will be used for faster access (e.g. on the history screen)
         * @returns {Promise}
         */
        _getRules: function _getRules() {
            Debug.Autopilot.General && console.log("AutopilotCommunicator: _getRules");

            if (this._getRulesDef) {
                Debug.Autopilot.General && console.log(" - use running request");
                return this._getRulesDef.promise;
            }

            this._getRulesDef = Q.defer();

            this._sendCommand(Commands.format(Commands.AUTO_PILOT_GENERATOR.GET_RULES, AutopilotEnums.AutopilotTypes.Autopilot)).done(function (data) {
                try {
                    var parsedData = JSON.parse(data.LL.value);
                } catch (e) {
                    if (data.LL.value.length > 0) {
                        // means that the JSON is invalid
                        this._getRulesDef.reject(e);

                        delete this._getRulesDef;
                        return;
                    } else {
                        // means that we have no autopilot yet
                        parsedData = {};
                    }
                }

                if (!parsedData.rules) {
                    parsedData.rules = [];
                } // populate autopilot invalid flag if there are problems


                for (var i = 0; i < parsedData.rules.length; i++) {
                    var autopilot = parsedData.rules[i],
                        name; // ensure the required attributes exist.

                    autopilot.invalid = !autopilot.events || !autopilot.actions;
                    autopilot.events = autopilot.events || [];
                    autopilot.actions = autopilot.actions || [];
                    name = AutopilotUtility.createTitleForRule(autopilot);
                    autopilot.nameIsGenerated = name === autopilot.name;

                    for (var j = 0; j < autopilot.events.length; j++) {
                        var event = autopilot.events[j];

                        if (event.invalid && event.invalid === true) {
                            autopilot["invalid"] = true;
                            break;
                        }
                    } // just check actions for invalid flag if there are no invalid events, otherwise
                    // we can skip this check


                    if (!event.invalid) {
                        for (var j = 0; j < autopilot.actions.length; j++) {
                            var action = autopilot.actions[j];

                            if (action.invalid && action.invalid === true) {
                                autopilot["invalid"] = true;
                                break;
                            }
                        }
                    }
                } // store autopilot rules to local buffer for faster access
                // (for history screen ...)


                this.bufferedRules = parsedData.rules; // set the correct change date

                var states = SandboxComponent.getStatesForUUID(AUTOMATIC_DESIGNER_STATES);

                if (states && states.states && states.states.changeDate) {
                    this._changeDate = states.states.changeDate;
                    Debug.Autopilot.Communication && console.log("cached autopilots with date " + this._changeDate);
                }

                this._getRulesDef.resolve(parsedData.rules.reverse());

                delete this._getRulesDef;
            }.bind(this), function (err) {
                this._getRulesDef.reject(err);

                delete this._getRulesDef;
            }.bind(this));

            return this._getRulesDef.promise;
        },

        /**
         * Activates or deactivates the given rule on the miniserver
         * @param rule
         * @param active
         * @returns {Promise}
         */
        setRuleActive: function setRuleActive(rule, active) {
            Debug.Autopilot.General && console.log("AutopilotCommunicator: set rule active: " + active);
            VendorHub.Usage.autopilot(FeatureUsage.Autopilot.SET_ACTIVE, active);
            return this._sendCommand(Commands.format(Commands.AUTO_PILOT_GENERATOR.ACTIVATE_RULE, rule.id, active ? 1 : 0));
        },

        /**
         * Executes a given rule on the miniserver (not used at the moment)
         * @param rule
         * @returns {Promise}
         */
        executeRule: function executeRule(rule) {
            return this._sendCommand(Commands.format(Commands.AUTO_PILOT_GENERATOR.EXECUTE_RULE, rule.id));
        },

        /**
         * Removes a given rule from the miniserver
         * @param rule
         * @returns {Promise}
         */
        removeRule: function removeRule(rule) {
            Debug.Autopilot.General && console.log("AutopilotCommunicator: remove rule");
            VendorHub.Usage.autopilot(FeatureUsage.Autopilot.DELETE);
            delete this._changeDate;
            Debug.Autopilot.Communication && console.log(this.name, "removeRule: " + JSON.stringify(rule));
            return this._sendCommand(Commands.format(Commands.AUTO_PILOT_GENERATOR.DELETE_RULE, rule.id));
        },

        /**
         * Saves a new rule or updates an existing one on the miniserver
         * @param rule
         * @param newRule
         * @returns {Promise}
         */
        saveOrUpdateRule: function saveOrUpdateRule(rule, newRule) {
            VendorHub.Usage.autopilot(newRule ? FeatureUsage.Autopilot.CREATE : FeatureUsage.Autopilot.UPDATE);
            var sendRule = JSON.parse(JSON.stringify(rule)); // on some miniservers (e.g. Kikas) description & invalid cause the rule to become invalid when modified, the
            // actions will be dropped by the Miniserver.

            delete sendRule.description;
            delete sendRule.invalid;
            var ruleJSON = encodeURIComponent(JSON.stringify(sendRule));
            Debug.Autopilot.Communication && console.log(this.name, "saveOrUpdateRule: " + JSON.stringify(sendRule));
            delete this._changeDate;
            return this._sendCommand(Commands.format(Commands.AUTO_PILOT_GENERATOR.ADD_OR_UPDATE_RULE, ruleJSON));
        },

        /**
         * Internal method for sending commands to the miniserver
         * All commands will be executed with the autopilot generator uuidAction from the structure file
         * @param cmd
         * @returns {Promise}
         * @private
         */
        _sendCommand: function _sendCommand(cmd) {
            Debug.Autopilot.Communication && console.log("Autopilot sendCommand: " + cmd);
            var cmdPrefix = "jdev/sps/io/%s/";
            var autopilotUUIDAction = ActiveMSComponent.getStructureManager().getAutopilotGenerator().uuidAction;
            cmdPrefix = Commands.format(cmdPrefix, autopilotUUIDAction);
            return SandboxComponent.sendWithPermission(cmdPrefix + cmd, MsPermission.AUTOPILOT, true);
        }
    };

    AutopilotCommunicator.getInstance = function () {
        if (instance === null) {
            instance = new AutopilotCommunicator();
        }

        return instance;
    };

    return AutopilotCommunicator.getInstance();
});
