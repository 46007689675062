
define("SwitchControl", ["Control"], function (Control) {
    return class SwitchControl extends Control {
        constructor() {
            super(...arguments);
        }

        getSwitch(states) {
            return {
                active: states.isActive,
                disabled: states.lockedOn,
                command0: Commands.SWITCH.OFF,
                command1: Commands.SWITCH.ON
            };
        }

        getAdditionalCardStateNames() {
            return ["lockedOn"];
        }

        getFriendlyValueName(event, operator) {
            switch (event.data.stateName) {
                case "active":
                    return this.name + " " + (event.value ? _("on") : _("off")).toLowerCase();

                default:
                    return "";
            }
        }

    };
});
