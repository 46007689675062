import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"

function PlusFilledCircle(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Circle cx={12} cy={12} r={12} />
            <Path
                d="M11.25 17.5a.75.75 0 001.5 0v-4.75h4.75a.75.75 0 000-1.5h-4.75V6.5a.75.75 0 00-1.5 0v4.75H6.5a.75.75 0 000 1.5h4.75v4.75z"
                fill="#fff"
            />
        </Svg>
    )
}

export default PlusFilledCircle
