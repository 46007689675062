'use strict';

define(['AudioZoneControlEnums', 'AudioZoneTableViewHelper', 'CustomizationHelper'], function (AudioZoneControlEnums, AudioZoneTableViewHelper, CustomizationHelper) {
    class AudioZoneControlZoneEQSettings extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            Object.assign(this, StateHandler.Mixin);
            this.details = details;
            this.control = details.control;
            this._blockingTimeoutMap = {};
        }

        titleBarText() {
            return _("media.equalizer");
        }

        reloadTable() {
            var args = arguments;
            return this._getEqSettings().then(eqSettings => {
                this.equalizerSettings = eqSettings;
                this.tableContent = MediaEnum.Equalizer.CHANNELS.map(function (channel, idx) {
                    return {
                        rows: [{
                            type: GUI.TableViewV2.CellType.SLIDER,
                            content: {
                                title: channel,
                                idx: idx,
                                value: this.equalizerSettings[idx],
                                minIconSrc: Icon.ValueSelector.MINUS_PLAIN,
                                minValue: MediaEnum.Equalizer.MIN,
                                maxIconSrc: Icon.ValueSelector.PLUS_PLAIN,
                                maxValue: MediaEnum.Equalizer.MAX,
                                step: MediaServerComp.Feature.V2_FIRMWARE ? MediaEnum.Equalizer.STEP_V2 : MediaEnum.Equalizer.STEP,
                                format: MediaEnum.Equalizer.FORMAT
                            },
                            sliderDragged: this._onEQSettingChange.bind(this),
                            sliderClicked: this._onEQSettingChange.bind(this)
                        }]
                    };
                }.bind(this));
                this.tableContent.push({
                    rows: [{
                        content: {
                            title: _("media.equalizer.send-to"),
                            titleColor: window.Styles.colors.brand,
                            clickable: true
                        },
                        action: this._sendEQSettingsToZone.bind(this)
                    }]
                });
                return super.reloadTable(...args);
            });
        }

        _onEQSettingChange(cell, section, row, tableView, value) {
            this.equalizerSettings[cell.content.idx] = parseFloat(value.toFixed(1)); // https://stackoverflow.com/a/48856974/1766321

            this._saveEQForControl(this.control);
        }

        _sendEQSettingsToZone() {
            var def = Q.defer(),
                availableZones = Object.values(MediaServerComp.getAvailableZones()).filter(function (zone) {
                    return zone.playerid !== this.control.details.playerid && zone.details.clientType !== MediaEnum.ClientType.UPNP;
                }.bind(this)),
                details = {
                    options: availableZones.map(function (zone) {
                        return {
                            title: zone.name,
                            subtitle: zone.groupDetail,
                            clickable: true,
                            zone: zone
                        };
                    }.bind(this)),
                    title: NBR_SPACE,
                    // We don't want a title per design!
                    headerTitle: "",
                    headerDescription: _("media.equalizer.send-to.desc", {
                        zoneName: this.control.getName()
                    }),
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE,
                    mode: GUI.SelectorScreenMode.CONFIRMED,
                    deferred: def
                };
            NavigationComp.showSelector(details).done(function (result) {
                result.forEach(function (select) {
                    this._saveEQForControl(ActiveMSComponent.getStructureManager().getControlByUUID(select.option.zone.uuidAction));
                }.bind(this));
            }.bind(this));
        }

        /**
         * Sending the EQ Settings to a given control, also ensures to send them at max every 50 ms to prevent spamming of the Musicserver
         * @param control
         * @private
         */
        _saveEQForControl(control) {
            if (!this._blockingTimeoutMap.hasOwnProperty(control.uuidAction)) {
                if (MediaServerComp.Feature.V2_FIRMWARE) {
                    MediaServerComp.sendMediaServerCommand(MediaEnum.Commands.SETTINGS.EQ + "/" + control.details.playerid + "/" + this.equalizerSettings.join(","));
                } else {
                    control.sendCommand(Commands.format(Commands.AudioZone.SET_EQ, this.equalizerSettings.join(",")), Commands.Type.OVERRIDE);
                }

                this._blockingTimeoutMap[control.uuidAction] = setTimeout(function () {
                    delete this._blockingTimeoutMap[control.uuidAction];
                }.bind(this), 50);
            } else {
                clearTimeout(this._blockingTimeoutMap[control.uuidAction]);
                this._blockingTimeoutMap[control.uuidAction] = setTimeout(function () {
                    delete this._blockingTimeoutMap[control.uuidAction];

                    this._saveEQForControl(control);
                }.bind(this), 50);
            }
        }

        _getEqSettings() {
            var def = Q.defer(),
                eqSettings = [];

            if (MediaServerComp.Feature.V2_FIRMWARE) {
                MediaServerComp.sendMediaServerCommand(MediaEnum.Commands.SETTINGS.EQ + "/" + this.control.details.playerid).then(function (resp) {
                    if (resp && resp.data && resp.data[0] && resp.data[0].equalizer) {
                        eqSettings = mapToArray(resp.data[0].equalizer);
                        def.resolve(eqSettings);
                    } else {
                        def.reject("Malformed response");
                    }
                }, def.reject);
            } else {
                eqSettings = this.control.getStates().equalizerSettings.split(",");
                def.resolve(eqSettings);
            }

            return def.promise.then(function (eq) {
                return eq.map(function (setting) {
                    return parseFloat(parseFloat(setting).toFixed(1)); // https://stackoverflow.com/a/48856974/1766321
                });
            });
        }

    }

    Controls.AudioZoneControl.AudioZoneControlZoneEQSettings = AudioZoneControlZoneEQSettings;
});
