import globalStyles from "GlobalStyles"
import {LxReactPressable, LxReactText} from "LxComponents"
import {View} from "react-native";
import EnergyIcon from "../../../react-comps/NodeSpider/EnergyIcon";
import WallboxManagerLoadText from "./wbEmLoadText";
import Icons from "IconLib"
import {ControlContext, useControl, useLiveState} from "../../../react-comps/Components";
import {useCallback, useContext, useMemo} from "react";
import WallboxManagerGroupScreen from "./wallboxManagerGroupScreen";
import {useNavigation} from "@react-navigation/native";
import useWallboxEmStatusRequest from "./useWallboxEmStatusRequest";
import useWallboxEmRootNodeValues from "./useWallboxEmRootNodeValues";
import {WallboxEmRestrictionCauses} from "./useLimitedWallboxes";


export default function WallboxManagerNode({nodeUuid, managerUuid, showPath = false}) {
    const {control} = useContext(ControlContext);
    const node = control?.getNode(nodeUuid) ?? null;
    const wbCtrl = useControl(node?.ctrlUuid);
    const navigation = useNavigation();
    const isGroup = Array.isArray(node?.nodes);

    const showGroupCallback = useCallback(() => {
        navigation.push(WallboxManagerGroupScreen.name, { nodeUuid });
    }, [navigation, nodeUuid]);

    if (wbCtrl) {
        return <WallboxManagerNodeWallbox
            format={control.powerFormat}
            ctrlUuid={node?.ctrlUuid}
            uuid={nodeUuid}
            showPath={showPath}
                />
    } else if (node?.isRoot) {
        return <WallboxManagerNodeRootGroup
            {...node}
            isGroup={isGroup}
            onPress={showGroupCallback}
            format={control.powerFormat}
        />
    } else {
        return <WallboxManagerNodeGroup
            {...node}
            isGroup={isGroup}
            onPress={Array.isArray(node.nodes) ? showGroupCallback : null}
            format={control.powerFormat}
        />
    }

}


/**
 * A wallbox itself is always visible, as the user is granted permission to view it automatically.
 * @returns {JSX.Element}
 * @constructor
 */
export function WallboxManagerNodeWallbox({
                                              uuid, // node-uuid
                                              ctrlUuid,
                                              showPath = false,
                                              format = "%.2f"
                                          }) {

    const wbCtrl = useControl(ctrlUuid);
    const {control} = useContext(ControlContext);
    const {states} = useLiveState(ctrlUuid, [
        "session",
        "priority",
        "limit",
        "carConnected",
        "limitedBy",
        "isPaused",
        "isCharging",
        "readyForCharging",
        "chargingEnabled",
        "active",
        "isBelowLimit",
        "phaseSwitching",
        "universalIsLocked",
        "loadSheddingActive",
        "stateTextForContent"
    ])
    const showCtrlCb = useCallback(() => {
        NavigationComp.showControlContent(wbCtrl);
    }, [wbCtrl]);


    const getPower = () => {
        if (wbCtrl.getCpIsConnected) {
            // session power
            return states?.session?.power ?? 0;
        }  else {
            return false;
        }
    }

    const showAsActive = () => {
        return states?.limit > 0 && !states?.isPaused;
    }

    const WbTitle = () => {

        if (states?.loadSheddingActive) {
            return _("controls.wallbox2.load-shedding");
        } else if (states?.phaseSwitching) {
            return _("controls.wallbox2.phase-switching");
        } else if (states?.universalIsLocked) {
            return _("control.lock.locked-title");
        } else if (wbCtrl.carConnectedInfoAvailable && !states?.carConnected) {
            return _("controls.wallbox2.no-vehicle");
        } else if (wbCtrl.carConnectedInfoAvailable && states?.carConnected && !states?.isCharging && !states?.chargingEnabled) {
            return _("controls.wallbox2.vehicle-connected");
        }

        return <WallboxManagerLoadText format={wbCtrl?.actualFormat} power={getPower()} limit={states?.limit} color={globalStyles.colors.text.primary} />;
    }
    
    const WbRightItem = () => {
        const items = [];
        if (states.limitedBy > 0 && states.limit === 0) {
            items.push(<Icons.Error
                fill={globalStyles.colors.orange}
                style={styles.rightIcon} />);
        }
        if (states.priority) {
            items.push(<Icons.ArrowUpCircled
                fill={globalStyles.colors.stateActive}
                style={styles.rightIcon}/>);
        }
        return items;
    }

    const wbSubtitle = () => {
        if(!showPath) {
            return wbCtrl?.getName() + SEPARATOR_SYMBOL + wbCtrl?.getRoom().name;
        } else {
            let node = control.getNode(uuid),
                pathParts = [...node.path].reverse();
            // Array.toReversed() is fairly new and not present e.g. in electron.
            return [wbCtrl.getName(), ...pathParts].join(SEPARATOR_SYMBOL);
        }
    }

    const textForLimit = (limitedBy) => {
        let text = null;
        switch (limitedBy) {
            case WallboxEmRestrictionCauses.PMAX:
                text = _("controls.wallboxmanager.max-power");
                break;
            case WallboxEmRestrictionCauses.ECO:
                text = _("controls.wallboxmanager.eco-charging-mode");
                break;
            case WallboxEmRestrictionCauses.STRAND:
                text = _("controls.wallboxmanager.line-protection");
                break;
            case WallboxEmRestrictionCauses.PARENT:
                text = _("controls.wallboxmanager.parent-line-protection");
                break;
        }
        if (text !== null) {
            text = _("controls.wallboxmanager.limited-by") + " " + text;
        }
        return text;
    }

    const wbSubtitle2 = () => {
        const parts = [];

        states?.priority && parts.push(_("controls.wallboxmanager.priority-charging-mode"))
        parts.pushObject(textForLimit(states?.limitedBy));

        return parts.length > 0 ? parts.join(SEPARATOR_SYMBOL) : null;
    }

    return <WallboxManagerNodeBase
        onPress={showCtrlCb}
        uuid={uuid}
        icon={wbCtrl?.getIcon()}
        isGroup={false}
        title={<WbTitle />}
        subTitle={wbSubtitle()}
        subTitle2={wbSubtitle2()}
        active={showAsActive()}
        rightItem={WbRightItem()}
    />
}

/**
 * On the root level, the nodes will receive their live data straight from the wallbox-managers state-container.
 * @returns {JSX.Element}
 * @constructor
 */
export function WallboxManagerNodeRootGroup({
                                           title,
                                           uuid,
                                           icon = null,
                                           format = "%.2f",
                                           onPress = null,
                                           isGroup = false,
                                            actualState = "",
                                            limitedByState = ""
}) {
    const {assigned, limitedBy, fuse} = useWallboxEmRootNodeValues(uuid);

    return <WallboxManagerNodeGroupBase
        power={assigned}
        limit={fuse}
        limitedBy={limitedBy}
        uuid={uuid}
        format={format}
        onPress={onPress}
        icon={icon}
        isGroup={isGroup}
        title={title} />
}

/**
 * On lower levels, the nodes that don't have a control are periodically updated using a hook that refreshes the data
 * using requests on the MS.
 * @returns {JSX.Element}
 * @constructor
 */
export function WallboxManagerNodeGroup({
                                                 title,
                                                 uuid,
                                                 icon = null,
                                                 format = "%.2f",
                                                 onPress = null,
                                                isGroup = false
}) {
    const nodeValue = useWallboxEmStatusRequest(uuid);

    return <WallboxManagerNodeGroupBase
        power={nodeValue.assigned}
        limit={nodeValue.fuse}
        limitedBy={nodeValue.limitedBy}
        uuid={uuid}
        format={format}
        onPress={onPress}
        icon={icon}
        isGroup={isGroup}
        title={title} />
}



function WallboxManagerNodeGroupBase ({
                                     title,
                                     uuid,
                                     icon = null,
                                     power = 0,
                                     limit = 0,
                                    limitedBy = 0,
                                     format = "%.2f",
                                     isGroup = false,
                                     onPress = null
                                 }) {

    const showAsActive = () => {
        if (power !== false) {
            return power > 0;
        } else {
            return limitedBy > 0;
        }
    }

    return <WallboxManagerNodeBase
        onPress={onPress}
        uuid={uuid}
        icon={icon}
        isGroup={isGroup}
        title={<WallboxManagerLoadText format={format} power={power} limit={limit} color={globalStyles.colors.text.primary} />}
        subTitle={title}
        active={showAsActive() ? -1 : 0}
        />
}


function WallboxManagerNodeBase ({
                                     uuid,
                                     active = false,
                                     icon = null,
                                     isGroup = false,
                                     title = null,
                                    subTitle = null,
                                    subTitle2 = null,
                                     rightItem = null,
                                     onPress = null,

                                 }) {

    return <LxReactPressable onPress={onPress} disabled={!onPress}>
        <View style={styles.loadCntr} key={uuid}>
            <View style={styles.iconCntr} >
                <EnergyIcon icon={icon}
                            useCircuitBreaker={true}
                            onClick={onPress} hasChildren={isGroup} positivity={active ? -1 : 0} />
            </View>
            <View style={styles.loadTextCntr}>
                <LxReactText style={styles.loadState} key={"value"}>
                    {title}
                </LxReactText>
                {
                    subTitle ?
                        (<LxReactText style={styles.loadName} key={"subTitle"}>
                            {subTitle}
                        </LxReactText>) : null
                }
                {
                    subTitle2 ? (
                        <LxReactText style={styles.loadName} key={"subTitle2"}>
                            {subTitle2}
                        </LxReactText>
                    ) : null
                }
            </View>
            {rightItem ? (
                <View style={styles.rightItemCntr}>
                    {rightItem}
                </View>
                ) : null
            }
        </View>
    </LxReactPressable>
}


const styles = {
    loadCntr: {
        flexDirection: "row",
        paddingTop: globalStyles.sizes.smallest,
        paddingBottom: globalStyles.sizes.smallest,
    },
    loadTextCntr: {
        marginLeft: globalStyles.sizes.small,
        marginRight: globalStyles.sizes.smallest,
        flexDirection: "column",
        justifyContent: "center",
        flex: 1
    },
    loadState: {
        ...globalStyles.textStyles.body.bold,
        color: globalStyles.colors.text.primary
    },
    loadName: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.secondary
    },
    iconCntr: {
        width: 72,
        height: 72,
        alignItems: "center",
        justifyContent: "center",
        marginTop: globalStyles.sizes.smallest,
        marginBottom: globalStyles.sizes.smallest,
        marginRight: globalStyles.sizes.smallest,
    },
    rightItemCntr: {
        marginTop: "auto",
        justifyContent: "center",
        flexDirection: "column",
        marginBottom: "auto"
    },
    rightIcon: {
        width: globalStyles.sizes.medium,
        height: globalStyles.sizes.medium,
        margin: globalStyles.sizes.smallest
    }
}
