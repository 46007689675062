"use strict";

define("PoolControlContentTimer", ["PoolControlEnums"], function (PoolControlEnums) {
    return class PoolControlContentTimer extends Controls.ControlContent {
        constructor(details) {
            super(details);
            this.cycleMode = PoolControlEnums.PoolCycleMode.FILTER;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.tableView = new GUI.TableViewV2(this, this);
            this.tableView.getElement().addClass("timer__table-view");
            this.appendSubview(this.tableView);
        }

        titleBarActionRight() {
            this._startTimer();
        }

        updateView(details) {
            super.updateView();

            if (details) {
                this.cycleMode = details.cycleMode;

                this._updateCurrentTime();

                this.tableView.reload(); // reload is every time necessary because otherwise the picker won't work anymore
            }
        }

        getURL() {
            return "Timer";
        }

        getAnimation() {
            return HD_APP ? AnimationType.MODAL : AnimationType.PUSH_LEFT;
        }

        titleBarText() {
            return _("pool.control.manuel-cycle-start");
        }

        titleBarConfig() {
            return {
                leftSide: HD_APP ? TitleBarCfg.Button.CLOSE : TitleBarCfg.Button.BACK,
                rightSide: TitleBarCfg.Button.TICK,
                style: "transparent"
            };
        }

        receivedStates(states) {
            this.filterTime = states.filterTime;
            this.backwashTime = states.backwashTime;
            this.rinseTime = states.rinseTime;
            this.circulateTime = states.circulateTime;

            if (!this.currentTime) {
                this._updateCurrentTime(); // many updates are possible - remaining time of an active cycle


                this.tableView.reload();
            } else if (this.cycleMode === PoolControlEnums.PoolCycleMode.FLUSHING) {
                var timeString = LxDate.formatSecondsShort(this.currentTime);
                this.tableView.cellForSectionAndRow(1, 0).setDisclosureText(timeString);
            }
        }

        // Private functions
        _startTimer() {
            if (this.cycleMode === PoolControlEnums.PoolCycleMode.FLUSHING) {
                this.sendCommand(Commands.format(Commands.POOL.START_TIMER, this.cycleMode, this.backwashTime + '/' + this.rinseTime));
            } else {
                this.sendCommand(Commands.format(Commands.POOL.START_TIMER, this.cycleMode, this.currentTime));
            }

            this.ViewController.navigateBack();
        }

        _updateCurrentTime() {
            if (this.cycleMode === PoolControlEnums.PoolCycleMode.FILTER) {
                this.currentTime = this.filterTime;
            } else if (this.cycleMode === PoolControlEnums.PoolCycleMode.FLUSHING) {
                this.currentTime = this.backwashTime + this.rinseTime;
            } else if (this.cycleMode === PoolControlEnums.PoolCycleMode.CIRCULATE) {
                this.currentTime = this.circulateTime;
            }
        }

        _getTimeArrayOfSeconds(seconds) {
            var value = [];
            value.push(parseInt(seconds / (60 * 60)));
            seconds %= 60 * 60;
            value.push(parseInt(seconds / 60));
            value.push(seconds % 60);
            return value;
        }

        // TableViewDataSource methods
        styleForTableView(tableView) {
            return HD_APP ? TableViewStyle.GROUPED : null;
        }

        numberOfSections() {
            return 2;
        }

        numberOfRowsInSection() {
            return 1;
        }

        cellTypeForCellAtIndex(section, row) {
            if (section === 0 && row === 0) {
                return GUI.TableViewV2.CellType.GENERAL;
            } else if (section === 1 && row === 0) {
                if (this.cycleMode === PoolControlEnums.PoolCycleMode.FLUSHING) {
                    return GUI.TableViewV2.CellType.GENERAL;
                }

                return GUI.TableViewV2.CellType.DURATION_PICKER;
            }
        }

        contentForCell(cell, section) {
            if (section === 0) {
                // GeneralCell
                return {
                    title: _("pool.control.cycle"),
                    disclosureIcon: true,
                    disclosureText: this.control.getNameOfPoolCycleMode(this.cycleMode),
                    disclosureColor: this.control.getColorOfPoolCycleMode(this.cycleMode)
                };
            } else if (section === 1) {
                if (this.cycleMode === PoolControlEnums.PoolCycleMode.FLUSHING) {
                    // GeneralCell
                    return {
                        title: _("pool.control.duration"),
                        disclosureText: this.backwashTime + ' s + ' + this.rinseTime + ' s'
                    };
                } else {
                    // PickerCell
                    return {
                        iconSrc: "resources/Images/Controls/Daytimer/clock-end.svg",
                        title: _("pool.control.duration"),
                        value: this._getTimeArrayOfSeconds(this.currentTime),
                        wheelOrder: "hhiiss"
                    };
                }
            }
        }

        // TableViewDelegate methods
        didSelectCell(cell, section) {
            if (section === 0) {
                this.ViewController.showState(PoolControlEnums.ScreenState.CYCLE_SELECTION, null, {
                    control: this.control.subControls[Object.keys(this.control.subControls)[0]],
                    entry: {
                        cycleMode: this.cycleMode
                    }
                });
            }
        }

        onPickerChanged(section, row, tableView, value) {
            this.currentTime = value[0] * (60 * 60) + value[1] * 60 + value[2];
        }

    };
});
