'use strict';

define(["ControlInfoView", "TrackerView"], function (ControlInfoView, TrackerView) {
    return class TrackerControlInfoView extends ControlInfoView {
        constructor(control, element) {
            super(...arguments);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.trackerView = this._getTrackerView();
            this.appendSubview(this.trackerView);
        }

        receivedStates(states) {
            this.trackerView.update(states);
        }

        _getTrackerView() {
            return new TrackerView(this.control, true);
        }

    };
});
