import {View} from 'react-native';
import globalStyles from "GlobalStyles";
import PropTypes from "prop-types";
import React from 'react';


function LxReactOnlineView(props) {
    const Image = () => {
        let reactElem = React.createElement(props.imageComp.comp, props.imageComp.props)
        return (
            <View style={styles.image}>
                {reactElem}
            </View>
        )
    }

    return (
        <View style={styles.container}
              key={"online-view"}
              pointerEvents={"none"} // strange, but this is required for the touch event to pass through.
        >
            <Image />
            {props.hasOwnProperty("isOnline") && <View style={styles.symbol} />}
        </View>
    )
}

const styles = {
    container: {
        flex: 1,
        justifyContent: "center"
    },
    symbol: {
        height: globalStyles.sizes.smallest,
        width: globalStyles.sizes.smallest,
        position: "absolute",
        bottom: globalStyles.sizes.smallest,
        right: globalStyles.sizes.smallest,
        backgroundColor:globalStyles.colors.greenLed,
        borderRadius: "50%"
    },
    image: {
        justifySelf: "center",
        alignSelf: "center",
        justifyContent: "center",
        alignContent: "center",
        height: globalStyles.sizes.bigger,
        width: globalStyles.sizes.bigger
    }
}

export default LxReactOnlineView;

LxReactOnlineView.propTypes = {
    isOnline: PropTypes.string,
    imageComp: PropTypes.exact({
        comp: PropTypes.func.isRequired, // must be a function component, otherwise it'll be repainted on each render.
        props: PropTypes.object.isRequired
    })
};
