
define("MsShortcutControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class MsShortcutControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [],
                cmdText = _("ms-shortcut.switch");
            let cmd = this.createCommand(cmdText, control.open.bind(control), null, null);
            cmds.push(cmd);
            return cmds;
        }
    };
});