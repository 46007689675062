import React from 'react';

import globalStyles from "GlobalStyles";


export default () => <style>
    {
        `
            .Calendar {
                background: #1C1C1E;
                padding-top: 0;
            }

            .Calendar__sectionWrapper {
                height: 100%;
            }
            
            /* Header */
            
            .Calendar .Calendar__header {
                padding-left: 14px;
                padding-right: 14px;
                padding-bottom: 10px;
            }
            
            .Calendar > :not(.Calendar__footer) button {
                font-family: 'Averta-Bold';
                font-size: 17px;
            }
            
            .Calendar__header .Calendar__monthArrowWrapper {
                width: 21px; height: 35px;
                padding: 5px;
                transform: none;
                order: 2;
            }
            .Calendar__header .Calendar__monthArrowWrapper.-right {
                transform: rotate(180deg);
                order: 1;
            }
            .Calendar__header .Calendar__monthArrowWrapper.-left {
                margin-left: 18px;
            }
            
            .Calendar__header .Calendar__monthArrowWrapper .Calendar__monthArrow {
                width: 10px;
                height: 18px;
                position: relative;
                background-image: none;
                transform: none !important;
            }
            .Calendar__header .Calendar__monthArrowWrapper .Calendar__monthArrow:before,
            .Calendar__header .Calendar__monthArrowWrapper .Calendar__monthArrow:after {
                content: '';
                border-radius: 5.5px;
                position: absolute;
                width: 22.1%;
                height: 73%;
                background: ${globalStyles.colors.green};
            }
            .Calendar__header .Calendar__monthArrowWrapper .Calendar__monthArrow:before {
                top: -8%;
                left: 39.4%;
                transform: rotate(-45deg);
            }
            .Calendar__header .Calendar__monthArrowWrapper .Calendar__monthArrow:after {
                background: ${globalStyles.colors.green};
                top: 35%;
                left: 39.4%;
                transform: rotate(45deg);
            }
            
            .Calendar__monthYear {
                left: 0;
                transform: none !important;
            }
            .Calendar__monthYear.-hiddenNext, .Calendar__monthYear.-hiddenPrevious {
                pointer-events: none;
            }

            .Calendar__monthYear.-shown > *:hover, .Calendar:not(.-noFocusOutline) .Calendar__monthYear.-shown > *:focus, .Calendar__monthYear > *.-activeBackground {
                background: ${globalStyles.colors.green};
            }

            .Calendar__monthText:hover, .Calendar__yearText:hover {
                transform: scale(0.95);
            }
            .Calendar__monthText.-activeBackground, .Calendar__yearText.-activeBackground {
                transform: scale(0.95) !important;
            }

            .Calendar__monthYear .Calendar__yearText.-hidden, 
            .Calendar__monthYear .Calendar__monthText.-hidden {
                transform: scale(0.95) !important;
                opacity: 0.2;
                pointer-events: none;
            }
            
            .Calendar button.Calendar__monthText, .Calendar button.Calendar__yearText {
                text-transform: none;
                padding-right: 0.25em;
                padding-left: 0.25em;
            }
            
            
            /* Week Days */
            
            .Calendar .Calendar__weekDays {
                padding-left: 13px; padding-right: 13px;
            }
            
            .Calendar__weekDay {
                color: rgba(235, 235, 245, 0.3);
                text-transform: uppercase;
                font-size: 13px;
                font-family: 'Averta-Bold';
            }
            
            .Calendar abbr[title] {
                border-bottom: none;
            }
            
            
            /* Days */
            
            .Calendar .Calendar__section {
                padding-left: 16px; padding-right: 16px;
                padding-top: 0;
                opacity: 0;
            }
            .Calendar .Calendar__section.-shown {
                opacity: 1;
            }
            
            .Calendar .Calendar__day {
                color: white;
                font-size: 20px;
                font-family: 'Averta-Regular';
                min-height: 0;
                height: 40px;
            }
            
            .Calendar .Calendar__day.-disabled {
                opacity: 0.3;
            }
            
            /* Current Day */
            
            .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
                color: white;
                text-decoration: none;
                position: relative;
                font-family: 'Averta-Bold';
            }
            .Calendar__day.-today:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 100%;
                background-color: ${globalStyles.colors.green};
                z-index: -1;
            }
            .Calendar__day.-today:after {display: none !important;}
            
            
            /* Marked Week */
            .Calendar__day.selectedWeekday {
                position: relative;
                font-family: 'Averta-Bold';
            }
            .Calendar__day.selectedWeekday:before {
                content: '';
                position: absolute;
                left: 0;
                top: 1px;
                width: 100%;
                height: 100%;
                background-color: ${globalStyles.colors.green};
                z-index: -1;
            }
            .Calendar__day.selectedWeekday.-today:before {
                border-radius: 0;
            }
            .Calendar__day.selectedWeekday.firstWeekday:before {
                border-top-left-radius: 100%;
                border-bottom-left-radius: 100%;
                width: 110%; left: -10%;
            }
            .Calendar__day.selectedWeekday.lastWeekday:before {
                border-top-right-radius: 100%;
                border-bottom-right-radius: 100%;
                width: 110%; right: -10%;
            }
            
            
            /* Month Selector  */
            .Calendar__monthSelectorAnimationWrapper, .Calendar__yearSelectorAnimationWrapper {
                height: 83%;
            }
            .Calendar__monthSelector, .Calendar__yearSelector {
                background-color: #1C1C1E;
            }
            .Calendar__monthSelector .Calendar__monthSelectorItem > button,
            .Calendar__yearSelector .Calendar__yearSelectorItem  > button {
                font-size: 12px;
            }

            .Calendar__monthSelectorItem:not(.-active) .Calendar__monthSelectorItemText:not(:disabled):hover,
            .Calendar__yearSelectorItem:not(.-active) .Calendar__yearSelectorText:not(:disabled):hover {
                background: none;
                border: 1px solid ${globalStyles.colors.green};
                transition: none;
            }
            
            .Calendar__yearSelectorWrapper::after {
                background-image: linear-gradient(to bottom, #1C1C1E, #1C1C1E 10%, rgba(28, 28, 30, 0));
            }
            .Calendar__yearSelectorWrapper::before {
                background-image: linear-gradient(to bottom, rgba(28, 28, 30, 0), #1C1C1E 90%, #1C1C1E);
            }
        `
    }
</style>