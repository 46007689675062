'use strict';
/**
 * slot         the slot number
 * add          whether or not to show an add icon on the left side. - false by default
 */

window.GUI = function (GUI) {
    class EmptySlotCell extends GUI.TableViewV2.Cells.BaseCell {
        constructor() {
            super(...arguments);
            this.element.addClass("empty-slot-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();

            if (!this.elements) {
                this.elements = {};
            }

            this.contentPlaceholder.append(GUI.TableViewV2.Cells.EmptySlotCell.Template.getEmptySlotCellContent(this.content[MediaEnum.Event.SLOT], this.content.add));
            this.elements.iconPlaceholder = this.contentPlaceholder.find(".empty-slot-cell__icon-placeholder");
            this.elements.iconView = new Controls.AudioZoneControl.CoverView({
                iconSrc: "resources/Images/Controls/AudioZone/icon-not-used.svg"
            });
            this.elements.iconView.element.addClass("icon-placeholder__icon");
            this.appendSubview(this.elements.iconView, this.elements.iconPlaceholder);
        }

        clickableAllowed() {
            return this.content.add;
        }

    }

    GUI.TableViewV2.Cells.EmptySlotCell = EmptySlotCell;

    GUI.TableViewV2.Cells.EmptySlotCell.Template = function () {
        var getEmptySlotCellContent = function getEmptySlotCellContent(slotNr, showAdd) {
            var addIcon = "";

            if (showAdd) {
                addIcon = '<div class="empty-slot-cell__add-icon-placeholder">' + ImageBox.getResourceImageWithClasses(Icon.TRANSLUCENT_ADD, "add-icon-placeholder__icon") + '</div>';
            }

            return '' + addIcon + '<div class="empty-slot-cell__icon-placeholder"></div>' + '<div class="empty-slot-cell__texts">' + '<div class="texts__title">' + _("media.item.zone-fav.empty-slot", {
                slotNr: slotNr
            }) + '</div>' + '</div>' + '';
        };

        return {
            getEmptySlotCellContent: getEmptySlotCellContent
        };
    }();

    GUI.TableViewV2.CellType.Special.EMPTY_SLOT_CELL = "EmptySlotCell";
    return GUI;
}(window.GUI || {});
