'use strict';

/*
segments:
[{
  text: '',     // optional
  iconSrc: ''   // optional
},{
  ..
}]

Events:
 on-segment-selected        idx

 */

class LxSegmentedControl extends GUI.View {
    //region Static
    static Template = function () {
        var getTemplate = function getTemplate(segments) {
            var segs = "";

            for (var i = 0; i < segments.length; i++) {
                segs += getSegment(segments[i].text, segments[i].iconSrc);
            }

            return $('<div class="segmented-control">' + '   ' + '<div class="segmented-control__inner">' + '      ' + '<div class="inner__indicator"></div>' + '      ' + segs + '   ' + '</div>' + '</div>');
        };

        var getSegment = function getSegment(text, iconSrc) {
            return '<div class="inner__segment">' + (typeof iconSrc === "string" ? ImageBox.getResourceImageWithClasses(iconSrc, "segment__icon") : '') + (typeof text === "string" ? '<div class="segment__label text-overflow-ellipsis">' + text + '</div>' : '') + '</div>';
        };

        return {
            getTemplate: getTemplate
        };
    }(); //endregion Static

    constructor(segments, isInAmbientMode = false) {
        if (!(segments instanceof Array) || segments.length < 2) {
            throw new Error("LxSegmentedControl needs at least 2 segments to make sense! (segments: " + segments + ")");
        }

        super(LxSegmentedControl.Template.getTemplate(segments));
        this._currentSelectedIdx = 0;
        this._isInAmbientMode = isInAmbientMode;
    }

    isInAmbientMode() {
        return this._isInAmbientMode;
    }

    viewDidLoad() {
        var prms = super.viewDidLoad(...arguments);
        this.elements = {
            segments: this.element.find(".inner__segment"),
            indicator: this.element.find(".inner__indicator")
        };
        this.registerForResize();
        return prms.then(() => {
            this.element.toggleClass("segmented-control--ambient-mode", !!this.isInAmbientMode());
        });
    }

    viewWillAppear() {
        var prms = super.viewWillAppear(...arguments);

        this._updateIndicatorWidth();

        this.setActiveSegment(this._currentSelectedIdx, true);
        return prms;
    }

    viewDidAppear() {
        var prms = super.viewDidAppear(...arguments);

        this._boundOnClick = function (ev) {
            var idx = Math.floor((ev.pageX - this.element.offset().left) / (this.element.width() / this.elements.segments.length));

            this._onSegmentSelected(idx);
        }.bind(this);

        this.element.on("click", this._boundOnClick);
        return prms;
    }

    viewDidDisappear() {
        this.element.off("click", this._boundOnClick);
        return super.viewDidDisappear(...arguments);
    }

    destroy() {
        delete this.elements;
        delete this._currentSelectedSegment;
        return super.destroy();
    }

    onResize() {
        this._updateIndicatorWidth();
    }

    // Public Methods
    setActiveSegment(idx, skipAnimation) {
        if (this.disabled) {
            return;
        }

        if (this._currentSelectedIdx !== idx || this._currentSelectedIdx === 0) {
            this._currentSelectedIdx = idx;
            this._currentSelectedSegment && this._currentSelectedSegment.removeClass("segment--selected");
            this._currentSelectedSegment = $(this.elements.segments[idx]);

            this._currentSelectedSegment.addClass("segment--selected");

            this._moveIndicatorTo(idx, skipAnimation);
        }
    }

    setDisabled(disabled) {
        this.disabled = disabled;
        this.element.toggleClass("disabled", !!disabled);
    }

    // Private Methods
    _onSegmentSelected(idx) {
        if (this.disabled) {
            return;
        }

        if (this._currentSelectedIdx !== idx) {
            this.emit("on-segment-selected", idx);
            this.setActiveSegment(idx);
        }
    }

    /**
     * moves the indicator to it's position
     * @param idx
     * @param skipAnimation
     * @private
     */
    _moveIndicatorTo(idx, skipAnimation) {
        var numberOfSegments = this.elements.segments.length,
            leftPercent = 100 / numberOfSegments * idx * numberOfSegments;
        this.elements.indicator.velocity({
            translateZ: 0,
            // Force HA by animating a 3D property
            translateX: leftPercent + "%"
        }, {
            duration: skipAnimation ? 0 : 100
        });
    }

    _updateIndicatorWidth() {
        this.elements.indicator.css('width', 100 / this.elements.segments.length + '%');
    }

}

GUI.LxSegmentedControl = LxSegmentedControl;
