import {createStackNavigator, TransitionPresets} from "@react-navigation/stack";
import AmbientSettings from "./screens/AmbientSettings";
import AmbientNewWallpaperPreview from "./screens/AmbientNewWallpaperPreview";
import AmbientDefaultLocationSelector from "./screens/AmbientDefaultLocationSelector";
import {
    LxReactSelectorScreen,
    TopNavigator,
    navigatorConfig,
    LxReactScreenAdapter
} from "LxComponents";
import AmbientShortcutSelection from "./screens/AmbientShortcutSelection";
import AmbientShortcutEditScreen from "./screens/AmbientShortcutEditScreen";
import EcoScreenSettingsNavigator from "../EcoScreen/EcoScreenSettingsNavigator";


const screenOptions = (...params) => {
    return {
        ...TopNavigator.forDefault(),
        ...TransitionPresets.ModalFadeTransition,
        ...navigatorConfig({...params, isAmbientMode: true})
    }
}

function AmbientSettingsNavigator(props) {
    const AmbientSettingsStack = createStackNavigator();

    return <AmbientSettingsStack.Navigator
        initialRouteName={AmbientSettings.name}
        screenOptions={
            (params) => {
                return {
                    ...screenOptions(params, true),
                    headerShown: false,
                    isAmbientMode: true,
                    inAmbientTabNav: true
                }
            }}>
        <AmbientSettingsStack.Screen name={AmbientSettings.name} component={AmbientSettings} />
        <AmbientSettingsStack.Screen name={AmbientNewWallpaperPreview.name} component={AmbientNewWallpaperPreview} />
        <AmbientSettingsStack.Screen name={AmbientDefaultLocationSelector.name} component={AmbientDefaultLocationSelector} />
        <AmbientSettingsStack.Screen name={AmbientShortcutSelection.name} component={AmbientShortcutSelection} />
        <AmbientSettingsStack.Screen name={AmbientShortcutEditScreen.name} component={AmbientShortcutEditScreen} />
        <AmbientSettingsStack.Screen name={LxReactSelectorScreen.name} component={LxReactSelectorScreen} />
        <AmbientSettingsStack.Screen name={ScreenState.EcoScreenSettingsScreen} component={EcoScreenSettingsNavigator} />
        <AmbientSettingsStack.Screen name={ScreenState.EntryPointLocationSelection} component={LxReactScreenAdapter} />
    </AmbientSettingsStack.Navigator>
}

export default AmbientSettingsNavigator
