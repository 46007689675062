'use strict';

var TitleBarCfg = {
    Button: {
        DROP_DOWN: "dropDownButton",
        CLOSE: "closeButton",
        EDIT: "editButton",
        SEARCH: "searchButton",
        BACK: "backButton",
        DOWN: "downButton",
        TICK: "tickMarkButton",
        MORE: "moreButton",
        HOME: "home"
    },
    CUSTOM_CONTENT: "customContent",
    CUSTOM_LX_VIEW: "lxView",
    CUSTOM_ICON: "customIcon",
    TEXT: 'text',
    Sub: {
        DROP_DOWN: "dropDownMenu",
        SEGMENTED: "segmented"
    },
    STYLE: {
        MINOR: "title-bar--minor"
    }
};

TitleBarCfg.BUTTON_MAP = {
    [TitleBarCfg.Button.DROP_DOWN]: {
        iconSrc: Icon.TitleBar.DOWN,
        iconClass: "left-side__drop-down-btn"
    },
    [TitleBarCfg.Button.CLOSE]: {
        iconSrc: Icon.TitleBar.CLOSE,
        newNavIconSrc: Icon.Buttons.CLOSE,
        iconClass: "icon-placeholder__close"
    },
    [TitleBarCfg.Button.EDIT]: {
        iconSrc: Icon.TitleBar.EDIT,
        iconClass: ""
    },
    [TitleBarCfg.Button.SEARCH]: {
        iconSrc: Icon.TitleBar.SEARCH,
        iconClass: ""
    },
    [TitleBarCfg.Button.BACK]: {
        iconSrc: Icon.TitleBar.BACK,
        newNavIconSrc: Icon.Buttons.BACK,
        iconClass: "icon-placeholder__arrow-back"
    },
    [TitleBarCfg.Button.DOWN]: {
        iconSrc: Icon.TitleBar.DOWN,
        iconClass: "icon-placeholder__arrow-back"
    },
    [TitleBarCfg.Button.TICK]: {
        iconSrc: Icon.TitleBar.TICK,
        iconClass: ""
    },
    [TitleBarCfg.Button.MORE]: {
        iconSrc: Icon.TitleBar.MORE,
        newNavIconSrc: Icon.Buttons.MORE,
        iconClass: ""
    },
    [TitleBarCfg.Button.HOME]: {
        iconSrc: Icon.TitleBar.HOME,
        iconClass: "icon-placeholder__home"
    }
}

class LxTitleBar extends GUI.View {
    //region Static
    static Template = function () {
        var generateTitleBarForConfig = function generateTitleBarForConfig(config, hasOsTitleBar) {
            var osTitleBar = "",
                rightSideDiv = "",
                leftSideDiv = "",
                classes = ["title-bar"];

            if (config.style) {
                classes.push(config.style);
            }

            if (hasOsTitleBar) {
                classes.push("title-bar--with-os-title-bar")
                osTitleBar = '<div class="operating-system-title-bar"></div>';
            }

            var template = '<div class="' + classes.join(" ") + '">' + osTitleBar + '<div class="title-bar-content">';
            switch (config.leftSide) {
                case TitleBarCfg.TEXT:
                    template += '<div class="left-side-container">' + '<div class="left-side__label text-overflow-ellipsis"></div>' + '</div>';
                    break;

                case TitleBarCfg.Button.BACK:
                case TitleBarCfg.Button.DOWN:
                case TitleBarCfg.Button.CLOSE:
                case TitleBarCfg.Button.HOME:
                    template += '<div class="left-side-container clickable">' + '<div class="left-side__icon-placeholder">' + LxTitleBar.getResourceTitlebarButtonIcon(config.leftSide) + '</div>' + '<div class="left-side__label text-overflow-ellipsis"></div>' + '</div>';
                    break;

                case TitleBarCfg.Button.DROP_DOWN:
                    template += '<div class="left-side-container clickable">' + '<div class="left-side__label text-overflow-ellipsis"></div>' + '<div class="drop-down__arrow-placeholder">' + LxTitleBar.getResourceTitlebarButtonIcon(config.leftSide)  + '</div>' + '</div>';
                    break;

                case TitleBarCfg.CUSTOM_CONTENT:
                    if (!config.hasOwnProperty('leftSideDiv')) {
                        throw "When using customContent 'leftSideDiv' has to be defined!";
                    }

                    template += '<div class="left-side-container">';
                    leftSideDiv = $(config.leftSideDiv);
                    leftSideDiv.addClass('left-side__custom-div');
                    template += leftSideDiv[0].outerHTML;
                    template += '</div>';
                    break;

                case TitleBarCfg.CUSTOM_LX_VIEW:
                    if (!config.hasOwnProperty('leftSideView')) {
                        throw "When using custom lxView 'leftSideView' has to be defined!";
                    }

                    template += '<div class="left-side-container">';
                    template += '</div>';
                    break;

                default:
            }

            switch (config.rightSide) {
                case TitleBarCfg.Button.TICK:
                case TitleBarCfg.Button.EDIT:
                case TitleBarCfg.Button.CLOSE:
                case TitleBarCfg.Button.SEARCH:
                case TitleBarCfg.Button.MORE:
                case TitleBarCfg.Button.HOME:
                    let buttonCfg = TitleBarCfg.BUTTON_MAP[config.rightSide];
                    template += '<div class="right-side-container">';
                    template += '<div class="right-side__touch-area clickable">' + ImageBox.getResourceImageWithClasses(buttonCfg.iconSrc, "right-side__button") + '</div>';
                    template += '</div>';
                    break;

                case TitleBarCfg.Button.DROP_DOWN:
                    template += '<div class="right-side-container">';
                    template += '<div class="right-side__touch-area clickable">' + ImageBox.getResourceImageWithClasses("resources/Images/General/button-menu.svg", "right-side__button") + '</div>';
                    template += '</div>';
                    break;

                case 'groupSwitcher':
                    template += '<div class="titlebar__group-switcher-toucharea clickable">' + '<div class="titlebar__group-switcher">' + ImageBox.getResourceImageWithClasses("resources/Images/General/TitleBar/titlebar_quick-view-selector.svg", "group-switcher__icon") + '</div>' + '</div>';
                    break;

                case 'quickGroupSelector':
                    template += '<div class="titlebar__group-switcher-toucharea toucharea--extended clickable">' + '<div class="titlebar__group-switcher">' + ImageBox.getResourceImageWithClasses("resources/Images/General/TitleBar/titlebar_quick-view-selector.svg", "group-switcher__icon") + '</div>' + '</div>';
                    break;

                case 'daytimerCalendar':
                    template += '<div class="right-side-container">';
                    template += '<div class="right-side__touch-area">' + ImageBox.getResourceImageWithClasses("resources/Images/General/TitleBar/titlebar_plus.svg", "right-side__button plus--icon") + ImageBox.getResourceImageWithClasses("resources/Images/General/TitleBar/titlebar_tickmark.svg", "right-side__button tickmark--icon") + '</div>';
                    template += '<div class="right-side__touch-area">' + ImageBox.getResourceImageWithClasses("resources/Images/General/TitleBar/titlebar_list-h.svg", "right-side__button list--horizontal") + ImageBox.getResourceImageWithClasses("resources/Images/General/TitleBar/titlebar_list-v.svg", "right-side__button list--vertical") + '</div>';
                    template += '<div class="right-side__touch-area">' + ImageBox.getResourceImageWithClasses("resources/Images/General/TitleBar/titlebar_trash-can.svg", "right-side__button") + '</div>';
                    template += '</div>';
                    break;

                case 'remoteControl':
                    template += '<div class="right-side-container">';
                    template += '<div class="right-side__touch-area">' + ImageBox.getResourceImageWithClasses("resources/Images/General/TitleBar/titlebar_on.svg", "right-side__button") + '</div>';
                    template += '<div class="right-side__touch-area">' + ImageBox.getResourceImageWithClasses("resources/Images/General/TitleBar/titlebar_off.svg", "right-side__button") + '</div>';
                    template += '</div>';
                    break;

                case 'taskRecorderIcon':
                    template += '<div class="right-side-container">';
                    template += '<div class="right-side__touch-area clickable">' + ImageBox.getResourceImageWithClasses("resources/Images/ActiveMiniserver/TaskRecorder/recording-stop.svg", "right-side__button") + '</div>';
                    template += '</div>';
                    break;

                case 'customIcon':
                    template += '<div class="right-side-container">';
                    template += '<div class="right-side__touch-area clickable">' + ImageBox.getResourceImageWithClasses(config.rightIconSrc, "right-side__button") + '</div>';
                    template += '</div>';
                    break;

                case TitleBarCfg.CUSTOM_CONTENT:
                    if (!config.hasOwnProperty('rightSideDiv')) {
                        throw "When using customContent 'rightSideDiv' has to be defined!";
                    }

                    template += '<div class="right-side-container">';
                    rightSideDiv = $(config.rightSideDiv);
                    rightSideDiv.addClass('right-side__custom-div');
                    template += rightSideDiv[0].outerHTML;
                    template += '</div>';
                    break;

                case TitleBarCfg.TEXT:
                    template += '<div class="right-side-container clickable right-side__touch-area">' + '<div class="right-side__label text-overflow-ellipsis">' + config.rightText + '</div>' + '</div>';
                    break;

                default:
                    break;
            }

            template += '</div>'; // ends title-bar-content

            template += '</div>'; // ends the title bar itself

            return template;
        };

        var getGreenLineTemplate = function getGreenLineTemplate() {
            return '<div class="title-bar__green-line">';
        };

        var generateSubTitleBarForConfig = function generateSubTitleBarForConfig(config) {
            var extraClasses = config;
            var template = '<div class="sub-title-bar ' + extraClasses + '">';

            switch (config) {
                case TitleBarCfg.TEXT:
                    template += '<div class="current-selection__label text-overflow-ellipsis"></div>';
                    break;

                case 'dropDownMenu':
                    template += '<div class="current-selection__label text-overflow-ellipsis"></div>' + '<div class="drop-down__arrow-placeholder">' + ImageBox.getResourceImageWithClasses("resources/Images/General/TitleBar/titlebar_arrow-down.svg", "button-placeholder__drop-down-btn") + '</div>';
                    break;

                case 'detailIconAndText':
                    template += '' + '<div class="detail__icon-placeholder"></div>' + '<div class="detail__text text-overflow-ellipsis"></div>';
                    break;

                default:
            }

            template += '</div>';
            return template;
        };

        var getDropDownTemplate = function getDropDownTemplate() {
            return '<div class="drop-down-list">' + '<div class="drop-down-list__list-placeholder"></div>' + '</div>';
        };
        /**
         * Creates a default drop-down list with circles according to the content given
         * @param content - an array of objects with at least a title-field. optionally also id and subtitle
         * @param hasCustomIds - an optional boolean that indicates whether or not content contains the IDs to use for
         *                      this dropdown. If this attribute isn't set or is set to false, 0..n indices are used.
         * @returns {string} the drop-down-template
         */


        var getCircleDropDownTemplate = function getCircleDropDownTemplate(content, hasCustomIds) {
            var result = '<div class="container__content-list">';

            for (var i = 0; i < content.length; i++) {
                var obj = content[i]; // validate input.

                if (!obj.hasOwnProperty('title')) {
                    throw "No title given!";
                }

                if (!obj.hasOwnProperty('id') && hasCustomIds) {
                    throw "No id given!";
                }

                var id = hasCustomIds ? obj['id'] : i; // check wehter or not a subtitle is present

                var subtitle = '';

                if (obj.hasOwnProperty('subtitle')) {
                    subtitle = '<div class="label-container__subtitle">' + obj['subtitle'] + '</div>';
                }

                result += '<div id="' + id + '" class="content-list__cell">' + '<div class="cell__circle-container">' + '<div class="circle-container__circle"></div>' + '</div>' + '<div class="cell__label-container">' + '<div class="label-container__title">' + obj['title'] + '</div>' + subtitle + '</div>' + '</div>';
            }

            result += '</div>';
            return result;
        };
        /*var getExpertModeButton = function getExpertModeButton() {
            return $(
                '<div class="right-side__touch-area">' +
                '   ' + ImageBox.getResourceImageWithClasses("resources/Images/General/icon-expert-mode.svg", "right-side__button clickable") +
                '</div>'
            );
        };*/


        return {
            generateTitleBarForConfig: generateTitleBarForConfig,
            generateSubTitleBarForConfig: generateSubTitleBarForConfig,
            getDropDownTemplate: getDropDownTemplate,
            getCircleDropDownTemplate: getCircleDropDownTemplate,
            //getExpertModeButton: getExpertModeButton,
            getGreenLineTemplate: getGreenLineTemplate
        };
    }(); //endregion Static
    /**
     * fetches the icon source and class from the button map and returns an image
     * uses getResourceImageWithClasses
     * @param sideConfig Enum Value from TitleBarCfg.Button
     * @returns {*}
     */
    static getResourceTitlebarButtonIcon(sideConfig) {
        var buttonCfg = TitleBarCfg.BUTTON_MAP[sideConfig];
        return ImageBox.getResourceImageWithClasses(buttonCfg.iconSrc, buttonCfg.iconClass);
    }


    constructor(config) {
        var platform = PlatformComponent.getPlatformInfoObj().platform,
            useOsPlaceholder = platform === PlatformType.IOS || platform === PlatformType.Mac;
        super($(LxTitleBar.Template.generateTitleBarForConfig(config, useOsPlaceholder))); // title bar

        this.titleBar = this.element.closest('.title-bar'); // sub title bar

        if (config.subTitleBar) {
            this.element = this.element.add($(LxTitleBar.Template.generateSubTitleBarForConfig(config.subTitleBar)));
            this.subTitleBar = this.element.closest('.sub-title-bar');
        } else if (config.greenLine) {
            this.element.append($(LxTitleBar.Template.getGreenLineTemplate()));
        } // drop down list


        if (config.leftSide === TitleBarCfg.Button.DROP_DOWN || config.rightSide === TitleBarCfg.Button.DROP_DOWN || config.subTitleBar === TitleBarCfg.Sub.DROP_DOWN) {
            this.element = this.element.add($(LxTitleBar.Template.getDropDownTemplate()));
            this.dropDownList = this.element.closest('.drop-down-list');
            this.dropDownList.hide();
            this.dropDownOpened = false;
            this.dropDownAllowed = true;
            this.onDropDownStateChange && this.onDropDownStateChange(this.dropDownOpened);
        }

        this.config = config; // needs to be done here, otherwise SD won't work. viewDidLoad is called later

        this._initTitleBarElements(this.config);

        if (this.config.subTitleBar) {
            this._initSubTitleBarElements(this.config.subTitleBar);
        }

        if (this.config.leftSide === TitleBarCfg.Button.DROP_DOWN || this.config.rightSide === TitleBarCfg.Button.DROP_DOWN || this.config.subTitleBar === 'dropDownMenu') {
            this._initDropDownListElements();
        } // to register/unregister for back navigation


        this._boundLeftButtonTapped = this._leftButtonTapped.bind(this);

        this.__defineGetter__("onLeftButtonTapped", function () {
            return this._onLeftButtonTapped;
        });

        this.__defineSetter__("onLeftButtonTapped", function (val) {
            if (typeof val === "function" && this.isVisible()) {
                NavigationComp.registerForBackNavigation(val);
            } else if (typeof this._onLeftButtonTapped === "function" && !val) {
                NavigationComp.unregisterFromBackNavigation(this._onLeftButtonTapped);
            }

            this._onLeftButtonTapped = val;
        });
    }

    viewDidLoad() {
        let prms;

        return super.viewDidLoad(...arguments).then(() => {
            if (this.config.hasOwnProperty("leftSideView")) {
                if (this.config.leftSideView.isReactComp) {
                    prms = this.appendReactComp({
                        reactComp: this.config.leftSideView.comp,
                        compProps: this.config.leftSideView.compProps,
                        target: this.element.find(".left-side-container")
                    });
                } else {
                    prms = this.appendSubview(this.config.leftSideView, this.element.find(".left-side-container"));
                }
            } else {
                prms = Q.resolve();
            }

        return prms;
        });
    }

    viewDidAppear() {
        var baseCall = super.viewDidAppear(...arguments);

        if (typeof this._onLeftButtonTapped === "function") {
            NavigationComp.registerForBackNavigation(this._onLeftButtonTapped);
        }

        this.eventHandlers = []; // left

        switch (this.config.leftSide) {
            case TitleBarCfg.Button.BACK:
            case TitleBarCfg.Button.CLOSE:
            case TitleBarCfg.Button.DOWN:
            case TitleBarCfg.Button.HOME:
                this.buttons.leftSideButton.onButtonTapped = this._boundLeftButtonTapped;
                break;

            case TitleBarCfg.Button.DROP_DOWN:
                this.buttons.leftSideButton.onButtonTapped = this._toggleDropDownList.bind(this);
                this.eventHandlers.push(Hammer(this.dropDownListElements.listPlaceholder[0]).on(tapEvent(), this._toggleDropDownList.bind(this)));
                break;

            default:
                break;
        } // right


        switch (this.config.rightSide) {
            case TitleBarCfg.Button.TICK:
            case TitleBarCfg.Button.EDIT:
            case TitleBarCfg.Button.CLOSE:
            case 'groupSwitcher':
            case 'taskRecorderIcon':
            case 'customIcon':
            case TitleBarCfg.Button.SEARCH:
            case TitleBarCfg.Button.MORE:
            case TitleBarCfg.Button.HOME:
            case TitleBarCfg.TEXT:
                this.buttons.rightSideButton.onButtonTapped = this._rightButtonTapped.bind(this);
                break;

            case 'quickGroupSelector':
                this.quickGroupHandler.on('hold', this._showQuickGroupSelector.bind(this));
                this.quickGroupHandler.on(tapEvent(), this._rightButtonTapped.bind(this));
                break;

            case 'daytimerCalendar':
                this.buttons.rightSideButton.onButtonTapped = this._rightButtonTapped.bind(this);
                this.buttons.listButton.onButtonTapped = this._listButtonTapped.bind(this);
                this.buttons.deleteButton.onButtonTapped = this._deleteButtonTapped.bind(this);
                break;

            case TitleBarCfg.Button.DROP_DOWN:
                this.buttons.rightSideButton.onButtonTapped = this._toggleDropDownList.bind(this);
                this.eventHandlers.push(Hammer(this.dropDownListElements.listPlaceholder[0]).on(tapEvent(), this._toggleDropDownList.bind(this)));
                break;

            case 'remoteControl':
                this.buttons.rightSideButton.onButtonTapped = this._rightButtonTapped.bind(this);
                this.buttons.offButton.onButtonTapped = this._powerOffButtonTapped.bind(this);
                break;

            default:
                break;
        } // subTitleBar


        if (this.config.subTitleBar === 'dropDownMenu') {
            this.eventHandlers = [Hammer(this.subTitleBar[0], {
                preventDefault: true
            }).on(tapEvent(), this._toggleDropDownList.bind(this))];
            this.eventHandlers.push(Hammer(this.dropDownListElements.listPlaceholder[0]).on(tapEvent(), this._toggleDropDownList.bind(this)));
        }

        if (this.config.ripple || !this.config.hasOwnProperty('ripple')) {
            var btns = this.buttons;
            Object.getOwnPropertyNames(btns).forEach(function (val) {
                var button = btns[val];

                if (button.setRippleEnabled) {
                    button.setRippleEnabled(true);
                }
            });
        }

        return baseCall;
    }

    viewWillDisappear() {
        if (this.dropDownAllowed && this.dropDownOpened) {
            this._toggleDropDownList();
        }

        if (typeof this._onLeftButtonTapped === "function") {
            NavigationComp.unregisterFromBackNavigation(this._onLeftButtonTapped);
        }

        return super.viewWillDisappear(...arguments);
    }

    viewDidDisappear() {
        // left
        if (this.buttons.leftSideButton) {
            this.buttons.leftSideButton.onButtonTapped = null;
        } // right


        if (this.buttons.rightSideButton) {
            this.buttons.rightSideButton.onButtonTapped = null;
        }

        if (this.config.rightSide === 'daytimerCalendar') {
            this.buttons.listButton.onButtonTapped = null;
            this.buttons.deleteButton.onButtonTapped = null;
        } else if (this.config.rightSide === 'remoteControl') {
            this.buttons.offButton.onButtonTapped = null;
        }

        if (this.eventHandlers) {
            for (var i = 0; i < this.eventHandlers.length; i++) {
                this.eventHandlers[i].dispose();
            }
        }

        return super.viewDidDisappear(...arguments);
    }

    destroy() {
        this.eventHandlers = null; //this._expertModeChangesReg && NavigationComp.removeFromUIEvent(this._expertModeChangesReg);

        return super.destroy(...arguments);
    }

    // Public methods
    setTitleBarSideTexts(left, right, subtitle) {
        if (left && this.titleBarElements && this.titleBarElements.leftSideLabel) {
            this.titleBarElements.leftSideLabel.text(left);
            if (this.ViewController && "reactNavigationProps" in this.ViewController) {
                this.ViewController.reactNavigationProps.navigation.setOptions({
                    title: left
                });
            }
        } else {
            this.leftText = left;
        }

        if (right && this.titleBarElements && this.titleBarElements.rightSideLabel) {
            this.titleBarElements.rightSideLabel.text(right);
        } else {
            this.rightText = right;
        }

        if (subtitle && this.subTitleBarElements && this.subTitleBarElements.label) {
            this.subTitleBarElements.label.text(subtitle);
        } else {
            this.subtitleText = subtitle;
        }
    }

    getLeftTitleBarText() {
        if (this.titleBarElements && this.titleBarElements.leftSideLabel) {
            return this.titleBarElements.leftSideLabel.text();
        } else if (this.leftText) {
            return this.leftText;
        }

        return null;
    }

    // Drop down stuff
    setDropDownListContent(content, viewSubclass) {
        var i;

        if (this.dropDownListContent) {
            if (!this.dropDownEventHandlers) {
                this.removeSubview(this.dropDownListContent);
            } else {
                for (i = 0; i < this.dropDownEventHandlers.length; i++) {
                    this.dropDownEventHandlers[i].dispose();
                }

                this.dropDownListElements.listPlaceholder.empty();
            }
        }

        if (content) {
            if (viewSubclass) {
                this.appendSubview(content, this.dropDownListElements.listPlaceholder);
            } else {
                content.addClass('drop-down-list__container');
                this.dropDownListElements.listPlaceholder.append(content);
                this.dropDownEventHandlers = [];

                for (i = 0; i < content.children().length; i++) {
                    this.dropDownEventHandlers.push(Hammer(content.children()[i]).on(tapEvent(), this._dropDownListCellTapped.bind(this)));
                }
            }
        }

        this.dropDownListContent = content;
    }

    /**
     * Unlike setDropDownListContent, this method only expects the data to be set, not the whole view. the view is
     * being generated based on the data provided
     * @param data - contains an object for each entry. has to have an title attribute, optionally a subtitle and depending
     *                  on 'hasCustomIds' an id-attribute.
     * @param [hasCustomIds] - optional, specifies whether or not the ids are provided in the data. if not, the indices
     *                      are used as ids
     */
    setCircleDropDownListData(data, hasCustomIds) {
        if (!this.config.subTitleBar || this.config.subTitleBar !== 'dropDownMenu') {
            throw "Titlebar not configured for circleDropDownList! subTitleBar needs to be set to dropDownMenu!";
        } // filters data points without name
        // every data point has to have a title


        this.circleDropDownListData = data.filter(function (entry) {
            return !!entry.title;
        });
        var i;

        if (this.dropDownEventHandlers) {
            for (i = 0; i < this.dropDownEventHandlers.length; i++) {
                this.dropDownEventHandlers[i].dispose();
            }

            this.dropDownListElements.listPlaceholder.empty();
        }

        this.circleDropDownList = $(LxTitleBar.Template.getCircleDropDownTemplate(this.circleDropDownListData, hasCustomIds));
        this.circleDropDownList.addClass('drop-down-list__container');
        this.dropDownListElements.listPlaceholder.append(this.circleDropDownList);
        this.dropDownEventHandlers = [];

        for (i = 0; i < this.circleDropDownList.children().length; i++) {
            this.dropDownEventHandlers.push(Hammer(this.circleDropDownList.children()[i]).on(tapEvent(), this._dropDownListCellTapped.bind(this)));
        }
    }

    selectCircleDropDownEntry(id) {
        var i;

        for (i = 0; i < this.circleDropDownList.children().length; i++) {
            var currChild = $(this.circleDropDownList.children()[i]); // ensure both sides are strings, otherwise "===" will fail e.g. 0 and "0"

            var isSelected = currChild[0].id + "" === "" + id;
            currChild.toggleClass('selected', isSelected);
        } // find selected name


        for (i = 0; i < this.circleDropDownListData.length; i++) {
            var obj = this.circleDropDownListData[i];

            if (obj.id === id) {
                this.subTitleBarElements.label.text(obj.title);
                break;
            }
        }
    }

    setDropDownAllowed(isAllowed) {
        if (this.dropDownOpened && !isAllowed) {
            this._toggleDropDownList();
        }

        this.dropDownAllowed = isAllowed;

        if (this.dropDownAllowed) {
            this.titleBarElements.rightSideButton && this.titleBarElements.rightSideButton.show();
            this.subTitleBarElements.dropDownArrow && this.subTitleBarElements.dropDownArrow.show();
        } else {
            this.titleBarElements.rightSideButton && this.titleBarElements.rightSideButton.hide();
            this.subTitleBarElements.dropDownArrow && this.subTitleBarElements.dropDownArrow.hide();
        }
    }

    setSegmentActive(segmentIndex) {
        this.segmentedControl && this.segmentedControl.setActiveSegment(segmentIndex);
    }

    // Other stuff
    setDetailIconAndText(icon, text) {
        this.subTitleBarElements.label.text(text);
        this.subTitleBarElements.iconPlaceholder.empty();

        if (icon) {
            var iconElement = ImageBox.getResourceImageWithClasses(icon, 'detail__icon');
            this.subTitleBarElements.iconPlaceholder.prepend(iconElement);
        }
    }

    togglePresenceSimulation(active) {
        if (active && !this._presenceSimulationIcon) {
            this._presenceSimulationIcon = $(ImageBox.getResourceImageWithClasses("resources/Images/General/icon-presence-simulation.svg", "left-side__presence-simulation-icon"));
            this.titleBarElements.leftSideContainer.append(this._presenceSimulationIcon);
        } else if (!active && this._presenceSimulationIcon) {
            this._presenceSimulationIcon.remove();

            this._presenceSimulationIcon = null;
        }
    }

    // Expert Mode

    /**
     * takes care of whether the expert mode button should be displayed or not
     * also registers on change event and toggles the button
     * @param control
     * @param group
     */

    /*setExpertModeInfo: function setExpertModeInfo(control, group) {
                var handleChangeEvent = function (ev, enabled) {
                    if (enabled) {
                        if (!this.buttons.expertModeButton) {
                            this._addExpertModeButton();
                        }
                        // just update the handler to use the new params (control or group)
                        this.buttons.expertModeButton.onButtonTapped = function() {
                            if (control) {
                                NavigationComp.showControlSettings(control);
                            } else if (group) {
                                NavigationComp.showGroupSettings(group);
                            }
                        };
                    } else {
                        this._removeExpertModeButton();
                    }
                }.bind(this);
                this._expertModeChangesReg = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.ExpertModeChanged, handleChangeEvent);

                handleChangeEvent(null, ActiveMSComponent.isExpertModeEnabled());
            },*/

    /**
     * just adds the button, event handler must be registered separately!
     * @private
     */

    /*_addExpertModeButton: function _addExpertModeButton() {
                this.titleBarElements.expertModeButton = Template.getExpertModeButton();
                if (!this.titleBarElements.rightSideContainer) {
                    this.titleBarElements.rightSideContainer = $('<div class="right-side-container" />');
                    this.element.find('.title-bar-content').append(this.titleBarElements.rightSideContainer);
                    this.titleBarElements.leftSideContainer.addClass("left-side--flex");
                }
                this.titleBarElements.rightSideContainer.append(this.titleBarElements.expertModeButton);
                this.buttons.expertModeButton = new GUI.LxButton(this, this.titleBarElements.expertModeButton[0], Color.BUTTON_GLOW, null, true);
                this.buttons.expertModeButton.setActiveParts([{
                    part: this.titleBarElements.expertModeButton.find("path")[0],
                    mode: "fill"
                }]);
                this.addToHandledSubviews(this.buttons.expertModeButton);
            },

            _removeExpertModeButton: function _removeExpertModeButton() {
                if (this.buttons.expertModeButton) {
                    this.removeSubview(this.buttons.expertModeButton);
                    delete this.titleBarElements.expertModeButton;
                    delete this.buttons.expertModeButton;
                }
            },*/

    // Private methods
    _initTitleBarElements(config) {
        // elements
        this.titleBarElements = {}; // left

        if (config.leftSide) {
            this.titleBarElements.leftSideContainer = this.titleBar.find('.left-side-container');

            if (config.rightSide) {
                this.titleBarElements.leftSideContainer.addClass("left-side--flex");
            }

            this.titleBarElements.leftSideLabel = this.titleBar.find('.left-side__label');
        } // right


        if (config.rightSide) {
            this.titleBarElements.rightSideContainer = this.titleBar.find('.right-side-container');

            switch (config.rightSide) {
                case TitleBarCfg.Button.TICK:
                case TitleBarCfg.Button.CLOSE:
                case TitleBarCfg.Button.EDIT:
                case TitleBarCfg.Button.DROP_DOWN:
                case 'taskRecorderIcon':
                case 'customIcon':
                case TitleBarCfg.Button.SEARCH:
                case TitleBarCfg.Button.MORE:
                case TitleBarCfg.Button.HOME:
                case TitleBarCfg.TEXT:
                    this.titleBarElements.rightSideButton = this.titleBar.find('.right-side__touch-area');
                    break;

                case 'customContent':
                    // nothing to do
                    break;

                case 'groupSwitcher':
                    this.titleBarElements.touchArea = this.titleBar.find('.titlebar__group-switcher-toucharea')[0];
                    break;

                case 'quickGroupSelector':
                    this.titleBarElements.touchArea = this.titleBar.find('.titlebar__group-switcher-toucharea')[0];
                    this.titleBarElements.rightSideLabel = this.titleBar.find('.titlebar__group-initial');
                    break;

                case 'daytimerCalendar':
                case 'daytimerList':
                    this.titleBarElements.addButton = $(this.titleBar.find('.right-side__touch-area')[0]);
                    this.titleBarElements.plusIcon = this.titleBarElements.addButton.children('.plus--icon');

                    this.titleBarElements.listButton = $(this.titleBar.find('.right-side__touch-area')[1]);
                    this.titleBarElements.hListIcon = this.titleBarElements.listButton.children('.list--horizontal');
                    this.titleBarElements.vListIcon = this.titleBarElements.listButton.children('.list--vertical').hide();

                    this.titleBarElements.deleteButton = $(this.titleBar.find('.right-side__touch-area')[2]).hide();

                    this.titleBarElements.tickMarkIcon = this.titleBarElements.addButton.children('.tickmark--icon').hide();
                    break;

                case 'remoteControl':
                    this.titleBarElements.onButton = $(this.titleBar.find('.right-side__touch-area')[0]);
                    this.titleBarElements.offButton = $(this.titleBar.find('.right-side__touch-area')[1]);
                    break;

                default:
                    break;
            }
        } // buttons


        this.buttons = {}; // left

        switch (config.leftSide) {
            case TitleBarCfg.Button.BACK:
            case TitleBarCfg.Button.CLOSE:
            case TitleBarCfg.Button.DOWN:
            case TitleBarCfg.Button.HOME:
                //var partsArray = [{part: this.titleBarElements.leftSideLabel[0], mode: 'color'}]; don't color the text!
                var partsArray = [];

                if (config.leftSide === 'backButton' || config.leftSide === TitleBarCfg.Button.DOWN) {
                    partsArray.push({
                        part: this.titleBarElements.leftSideContainer.find('.icon-placeholder__arrow-back')[0],
                        mode: 'fill'
                    });
                } else if (config.leftSide === TitleBarCfg.Button.CLOSE) {
                    partsArray.push({
                        part: this.titleBarElements.leftSideContainer.find('.icon-placeholder__close')[0],
                        mode: 'fill'
                    });
                }

                if (config.ignoreTitleAsLeftSide) {
                    this.buttons.leftSideButton = new GUI.LxButton(this, this.titleBarElements.leftSideContainer.find(".left-side__icon-placeholder")[0], Color.BUTTON_GLOW, partsArray, true);
                } else {
                    this.buttons.leftSideButton = new GUI.LxButton(this, this.titleBarElements.leftSideContainer[0], Color.BUTTON_GLOW, partsArray, true);
                } //this.buttons.leftSideButton.setGlowtime(200);


                break;

            case TitleBarCfg.Button.DROP_DOWN:
                this.buttons.leftSideButton = new GUI.LxButton(this, this.titleBar.find('.left-side-container')[0], '#000000');
                break;

            default:
                break;
        } // right


        switch (config.rightSide) {
            case TitleBarCfg.Button.TICK:
            case TitleBarCfg.Button.CLOSE:
            case TitleBarCfg.Button.EDIT:
            case TitleBarCfg.Button.MORE:
            case TitleBarCfg.Button.HOME:
            case TitleBarCfg.TEXT:
                this.buttons.rightSideButton = new GUI.LxButton(this, this.titleBarElements.rightSideContainer[0], Color.BUTTON_GLOW, null, false);
                break;

            case 'groupSwitcher':
                this.buttons.rightSideButton = new GUI.LxButton(this, this.titleBarElements.touchArea, '#000000');
                break;

            case 'quickGroupSelector':
                this.quickGroupHandler = Hammer(this.titleBarElements.touchArea, {
                    preventDefault: true,
                    holdTimeout: 200
                });
                break;

            case 'daytimerCalendar':
                this.buttons.rightSideButton = new GUI.LxButton(this, this.titleBarElements.addButton[0], '#000000');
                this.buttons.listButton = new GUI.LxButton(this, this.titleBarElements.listButton[0], '#000000');
                this.buttons.deleteButton = new GUI.LxButton(this, this.titleBarElements.deleteButton[0], '#000000');
                this.calendarVisible = true;
                this.editModeActive = false;
                break;

            case TitleBarCfg.Button.DROP_DOWN:
                this.buttons.rightSideButton = new GUI.LxButton(this, this.titleBarElements.rightSideButton[0], '#000000');
                break;

            case 'remoteControl':
                this.buttons.rightSideButton = new GUI.LxButton(this, this.titleBarElements.onButton[0], Color.BUTTON_GLOW, null, true);
                this.buttons.rightSideButton.useChildsAsActiveParts("fill");
                this.buttons.offButton = new GUI.LxButton(this, this.titleBarElements.offButton[0], Color.BUTTON_GLOW, null, true);
                this.buttons.offButton.useChildsAsActiveParts("fill");
                break;

            case 'taskRecorderIcon':
                this.buttons.rightSideButton = new GUI.LxButton(this, this.titleBarElements.rightSideButton[0], Color.BUTTON_GLOW, null, true);
                break;

            case 'customIcon':
            case TitleBarCfg.Button.SEARCH:
                this.buttons.rightSideButton = new GUI.LxButton(this, this.titleBarElements.rightSideButton[0], null, null, true);
                this.buttons.rightSideButton.useChildsAsActiveParts("fill");
                break;

            default:
                break;
        } // TODO handle setSideTexts in another way


        if (this.leftText) {
            this.titleBarElements.leftSideLabel.text(this.leftText);
        }

        if (this.rightText) {
            this.titleBarElements.rightSideLabel.text(this.rightText);
        }

        this.buttons.leftSideButton && this.addToHandledSubviews(this.buttons.leftSideButton);
        this.buttons.rightSideButton && this.addToHandledSubviews(this.buttons.rightSideButton);
        this.buttons.offButton && this.addToHandledSubviews(this.buttons.offButton);
        this.buttons.listButton && this.addToHandledSubviews(this.buttons.listButton);
        this.buttons.deleteButton && this.addToHandledSubviews(this.buttons.deleteButton);
    }

    _initSubTitleBarElements(subTitleBarConfig) {
        this.subTitleBarElements = {};

        if (subTitleBarConfig === 'segmented') {
            this.segmentedControl = new GUI.LxSegmentedControl(this.config.segments, this.config.isInAmbientMode);
            this.appendSubview(this.segmentedControl, this.subTitleBar);
            this.subTitleBarElements.segmented = this.segmentedControl.getElement();
            this.segmentedControl.on("on-segment-selected", function (ev, idx) {
                this.onSegmentSelected && this.onSegmentSelected(idx);
            }.bind(this));
        } else if (subTitleBarConfig === TitleBarCfg.TEXT) {
            this.subTitleBarElements.label = this.subTitleBar.find('.current-selection__label');
        } else if (subTitleBarConfig === 'dropDownMenu') {
            this.subTitleBarElements.label = this.subTitleBar.find('.current-selection__label');
            this.subTitleBarElements.dropDownArrow = this.subTitleBar.find('.button-placeholder__drop-down-btn');
        } else if (subTitleBarConfig === 'detailIconAndText') {
            this.subTitleBarElements.label = this.subTitleBar.find('.detail__text');
            this.subTitleBarElements.iconPlaceholder = this.subTitleBar.find('.detail__icon-placeholder');
        } // TODO handle setSideTexts in another way


        if (this.subtitleText) {
            this.subTitleBarElements.label.text(this.subtitleText);
        }
    }

    _initDropDownListElements() {
        this.dropDownListElements = {
            listPlaceholder: this.dropDownList.find('.drop-down-list__list-placeholder')
        };
    }

    // Event handler
    _leftButtonTapped() {
        this._onLeftButtonTapped();
    }

    _rightButtonTapped() {
        this.onRightButtonTapped();

        if (this.config.rightSide === 'daytimerCalendar' && this.editModeActive) {
            this._deleteButtonTapped();
        }
    }

    // left and right side, subTitleBar: dropDownButton
    _toggleDropDownList() {
        if (!this.dropDownAllowed) {
            return;
        }

        if (this.dropDownOpened) {
            this.dropDownOpened = false; // reverse won't work with velocity (probably because the element isn't in the DOM anymore)

            this.dropDownListElements.listPlaceholder.velocity({
                height: '0%'
            }, {
                duration: 300,
                complete: function () {
                    // specific
                    if (this.config.rightSide === TitleBarCfg.Button.DROP_DOWN) {
                        this.titleBarElements.rightSideButton.children('.right-side__button').attr('class', 'right-side__button');
                    }
                }.bind(this)
            });
            this.dropDownList.velocity({
                opacity: 0
            }, {
                duration: 300,
                complete: function () {
                    this.dropDownList.hide();
                }.bind(this)
            });
        } else {
            this.dropDownOpened = true; // specific

            if (this.config.rightSide === TitleBarCfg.Button.DROP_DOWN) {
                this.titleBarElements.rightSideButton.children('.right-side__button').attr('class', 'right-side__button list--active');
            }

            this.dropDownListElements.listPlaceholder.velocity({
                height: '100%'
            }, 300);
            this.dropDownList.show();
            this.dropDownList.velocity({
                opacity: 1
            }, 300);
        }

        this.titleBar.toggleClass('drop-down-opened', this.dropDownOpened);

        if (this.config.subTitleBar) {
            this.subTitleBar.toggleClass('drop-down-opened', this.dropDownOpened);
        }

        this.onDropDownStateChange && this.onDropDownStateChange(this.dropDownOpened);
    }

    _dropDownListCellTapped(event) {
        var arg = event.currentTarget.id || event.currentTarget;

        if (this.circleDropDownListData) {
            this.selectCircleDropDownEntry(arg);
        }

        this.onDropDownListSelectionChanged && this.onDropDownListSelectionChanged(arg); //this._toggleDropDownList(); !!! should be handled by the list-placeholder event handler !!!
    }

    // right side: quickGroupSelector
    _showQuickGroupSelector() {
        this.showQuickGroupSelector();
    }

    // right side: daytimerCalendar
    _listButtonTapped() {
        this.calendarVisible = !this.calendarVisible;
        var showPlusIcon = this.calendarVisible || !this.editModeActive || HD_APP;
        var showTickMark = !this.calendarVisible && this.editModeActive && !HD_APP;
        this.titleBarElements.plusIcon.toggle(showPlusIcon);
        this.titleBarElements.tickMarkIcon.toggle(showTickMark);
        var showDelete = !this.calendarVisible && !HD_APP;
        this.titleBarElements.hListIcon.toggle(this.calendarVisible);
        this.titleBarElements.vListIcon.toggle(!this.calendarVisible);
        this.titleBarElements.deleteButton.toggle(showDelete);
        this.titleBarElements.rightSideContainer.toggleClass('right-side-container__daytimer-three-buttons', showDelete);
        this.listButtonTapped(this.calendarVisible);
    }

    _deleteButtonTapped() {
        this.editModeActive = !this.editModeActive;
        this.titleBarElements.plusIcon.toggle(!this.editModeActive);
        this.titleBarElements.tickMarkIcon.toggle(this.editModeActive);

        if (!this.editModeActive) {
            this.titleBarElements.deleteButton.children('.right-side__button').attr('class', 'right-side__button');
        } else {
            this.titleBarElements.deleteButton.children('.right-side__button').attr('class', 'right-side__button trash--active');
        }

        this.deleteButtonTapped(this.editModeActive);
    }

    // right side: remoteControl
    _powerOffButtonTapped() {
        this.powerOffButtonTapped();
    }

}

GUI.LxTitleBar = LxTitleBar;
