'use strict';

define("PoolDaytimerControlStateContainer", ["DaytimerControlStateContainerBase"], function (DaytimerControlStateContainerBase) {
    return class PoolDaytimerControlStateContainer extends DaytimerControlStateContainerBase {
        constructor(control) {
            super(control);
            this.poolControl = SandboxComponent.getStructureManager().getControlByUUID(this.control.uuidParent);
        }

        getUUIDsToRegister() {
            // get all uuid's from IRoomControl
            var uuids = super.getUUIDsToRegister(...arguments);
            uuids.push(this.poolControl.states.currentCycle);
            uuids.push(this.poolControl.states.currentOpMode);
            uuids.push(this.poolControl.states.backwashTime);
            uuids.push(this.poolControl.states.rinseTime);
            return uuids;
        }

        prepareStates(newVals) {
            super.prepareStates(...arguments);
            this.states.activeMode = newVals[this.control.states.mode]; //this.states.value = newVals[this.control.states.value];
            //this.states.defaultValue = newVals[this.control.states.entriesAndDefaultValue].defValue;

            this._prepareEntries(this.states.entries);

            this.states.activeCycleMode = newVals[this.poolControl.states.currentCycle];
            this.states.outOtOrderActive = newVals[this.poolControl.states.currentOpMode] === 0;
            this.states.serviceModeActive = newVals[this.poolControl.states.currentOpMode] === 2;
            this.states.backwashTime = newVals[this.poolControl.states.backwashTime];
            this.states.rinseTime = newVals[this.poolControl.states.rinseTime];
        }

        _prepareEntries(entries) {
            var entry;

            for (var modeNr in entries) {
                if (entries.hasOwnProperty(modeNr)) {
                    for (var i = 0; i < entries[modeNr].length; i++) {
                        entry = entries[modeNr][i];
                        entry.cycleMode = entry.value;
                        delete entry.value;
                        delete entry.needActivate;
                    }
                }
            }
        }

    };
});
