import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Flash(props) {
    return (
        <Svg isreact="true"
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.604.722a.683.683 0 00-.813.213L5.458 18.855a.621.621 0 00-.051.665.673.673 0 00.593.347h9.835l-2.485 10.74c-.07.298.089.601.378.725a.68.68 0 00.814-.212L27.875 13.2a.619.619 0 00.05-.665.67.67 0 00-.593-.348h-9.836l2.487-10.742a.634.634 0 00-.379-.723z"
            />
        </Svg>
    )
}

export default Flash
