'use strict';

define("IntercomControl", ["Control", "IntercomControlEnums"], function (Control, IntercomControlEnums) {
    return class IntercomControl extends Control {
        constructor() {
            super(...arguments);
        }

        destroy() {
            Object.values(Controls.Intercom.SingleTones).forEach(function (singleTone) {
                singleTone.destroy && singleTone.destroy();
            });
        }

        getIcon() {
            return Icon.Intercom.BELL;
        }

        specificCreateCmdText(cmdParts) {
            var text = null,
                cfg = {
                    name: this.name
                };

            if (cmdParts[1] === Commands.INTERCOM.ANSWER) {
                text = _("cmdtext.intercom.answer", cfg);
            } else {
                var scId = cmdParts.slice(0, 2).join("/");
                var subControl = this.subControls[scId];
                cfg.output = createCmdText(subControl, cmdParts.slice(1));
                text = _("cmdtext.intercom.output", cfg);
            }

            return text;
        }

        /**
         * helper method to validate weather the videoInfo has a stream or not
         * @returns {boolean}
         */
        hasVideoInfo() {
            var videoInfo = this.details.videoInfo;
            return videoInfo && videoInfo.hasOwnProperty("streamUrl") && videoInfo.streamUrl !== "";
        }

        /**
         * helper method to validate audioInfo
         * @returns {boolean}
         */
        hasAudioInfo() {
            var audioConfigured = this.details.hasOwnProperty('audioInfo');

            if (audioConfigured) {
                var audioInfo = this.details.audioInfo;
                audioConfigured = audioInfo.host && audioInfo.host !== "";
            }

            return audioConfigured;
        }

        /**
         * Returns if this intercoms has a valid audioInfo and if the platform can make use of it (e.g. a Web-
         * interface cannot make use of audioInfo, so even if a valid config is given, it'll result in false)
         * @returns {*|boolean}
         */
        hasAudio() {
            return SipAdapter.hasSip && this.hasAudioInfo();
        }

        /**
         * helper method to validate a streamUrl
         * @returns {boolean}
         */
        checkStreamUrlValidity() {
            let isValid = false;

            try {
                isValid = new URL(this.details.videoInfo.streamUrl);
            } catch (e) {
                isValid = Regex.URL_WITHOUT_IPV6.test(this.details.videoInfo.streamUrl);
            }

            return !!isValid;
        }

        /**
         * Returns whether or not it's an Loxone Intercom (XL). Intercoms will not transmit their login data
         * using basic auth or alike, but as URL arguments.
         * @returns {boolean}
         * @private
         */
        isIntercom() {
            if (Feature.INTERCOM_TYPE) {
                return this.details.deviceType && (this.details.deviceType === IntercomControlEnums.DeviceTypes.LX_INTERCOM || this.details.deviceType === IntercomControlEnums.DeviceTypes.LX_INTERCOM_XL);
            } else {
                var result = false;

                try {
                    result = this.details.videoInfo.streamUrl.indexOf('?login=') > -1;
                } catch (exc) {// nothing to do
                }

                return result;
            }
        }

        /**
         * Returns true if the intercoms SIP module has the recent firmware that allows secure authentication.
         * @returns {boolean}
         */
        hasSecuredSIPModule() {
            var isSecured = false;

            if (this.isIntercom() && this.features && this.features.SIP.SECURED_INTERCOM) {
                isSecured = true;
            }

            return isSecured;
        }

        /**
         * Returns true if the intercoms Video module has the recent firmware that allows secure authentication.
         * @returns {boolean}
         */
        hasSecuredVideoModule() {
            var isSecured = false;

            if (this.isIntercom() && this.features && this.features.VIDEO.SECURED_INTERCOM) {
                isSecured = true;
            }

            return isSecured;
        }

        /**
         * Returns the deviceType name of the intercom control.
         * @returns {*} string, name of the device type (lx intercom (xl), custom intercom)
         */
        getDeviceTypeName() {
            var name;

            switch (this.details.deviceType) {
                case IntercomControlEnums.DeviceTypes.LX_INTERCOM:
                    name = _("controls.intercom.type.lxintercom");
                    break;

                case IntercomControlEnums.DeviceTypes.LX_INTERCOM_XL:
                    name = _("controls.intercom.type.lxintercomxl");
                    break;

                default:
                    name = _("controls.intercom.type.custom");
                    break;
            }

            return name;
        }

        /**
         * Returns an object with title and message of the specific error that caused the video fail
         * @param cause
         * @returns {{title, message}}
         */
        getVideoFailedTexts(cause) {
            var reachMode = CommunicationComponent.getCurrentReachMode();
            var texts = {
                title: _("controls.intercom.video.error.title"),
                message: _("controls.intercom.video.error.message")
            };

            try {
                let causeObj,
                    host;

                try {
                    causeObj = JSON.parse(cause);
                } catch (e) {
                    causeObj = cause;
                }

                try {
                    host = new URL(this.details.videoInfo.streamUrl).host;
                } catch (e) {
                    host = this.details.videoInfo.streamUrl;
                }

                if (cause && causeObj && causeObj.hasOwnProperty('errorId')) {
                    switch (causeObj.errorId) {
                        case "HttpError":
                            this._getHttpErrorTexts(texts, causeObj);

                            break;

                        case "NoResponse":
                            var msg = _("controls.intercom.video.error.message.noresponse") + " (" + host + ")";
                            texts.message = msg + "<br>" + texts.message;

                            if (reachMode === ReachMode.REMOTE) {
                                // when this error occurs externally, the most probable reason is a missing port forwarding
                                texts.message += "<br>" + _("controls.intercom.video.error.message.external");
                            }

                            texts.title = _("controls.intercom.video.error.title.timeout");
                            break;

                        case "Refused":
                            texts.message = _("controls.intercom.video.error.message.refused", {
                                host: host
                            });

                            if (reachMode === ReachMode.REMOTE) {
                                // when this error occurs externally, the most probable reason is a missing port forwarding
                                texts.message += "<br>" + _("controls.intercom.video.error.message.external");
                            }

                            break;

                        case "UnknownHost":
                            texts.message = _("controls.intercom.video.error.message.unknown-host", {
                                host: host
                            }) + "<br>" + texts.message;
                            texts.title = _("controls.intercom.video.error.title.unknown-host");
                            break;

                        case "LargeImage":
                            var limitMb = causeObj.errorCode / 1024 / 1024 + " MB"; // = errorCode = Bytes

                            texts.message = _("controls.intercom.video.error.message.largeimage", {
                                limit: limitMb
                            }) + "<br>" + texts.message;
                            break;

                        case "StreamError":
                            texts.message = _("controls.intercom.video.error.message.streamerror") + "<br>" + texts.message;
                            break;

                        default:
                            break;
                    }
                }
            } catch (ex) {// Use default texts!
            }

            return texts;
        }

        /**
         * converts HTTP Statuscode to an reasonable error message
         * @param texts
         * @param causeObj
         * @private
         */
        _getHttpErrorTexts(texts, causeObj) {
            switch (causeObj.errorCode) {
                case 401:
                    texts.message = _("controls.intercom.video.error.message.unauthorized") + "<br>" + texts.message;
                    break;

                default:
                    texts.message = texts.message + "<br>(" + causeObj.errorCode + "&nbsp;" + causeObj.errorText + ")";
                    break;
            }
        }

        /**
         * This function updates the features and
         */
        checkFeatures(version) {
            if (Feature.INTERCOM_VERSION) {
                // Version format: [SIP-Version]/[Video-Version]
                var versions = version.split("/"); // Here we have a very strange Version (V8a6), our version check can't handle it
                // so we need to correct it a bit. Baudisch only has
                // 4 chars for versioning.
                // So V8a6 is V8.6.1, V8b6 is V8.6.2, V8.6 is V8.6.1
                // Also the V will be removed, we don't need it
                // Any version without any character is a release version

                var sipVersion = new ConfigVersion(this._convertVersion(versions[0].replace("V", "")));
                var videoVersion = new ConfigVersion(this._convertVersion(versions[1]));
                var features = {
                    SIP: {},
                    VIDEO: {}
                };
                var feature = IntercomControlEnums.Feature;

                for (var sipFeatureKey in feature.SIP) {
                    if (feature.SIP.hasOwnProperty(sipFeatureKey)) {
                        features.SIP[sipFeatureKey] = sipVersion.greaterThanOrEqualTo(this._convertVersion(feature.SIP[sipFeatureKey]));
                    }
                }

                for (var videoFeatureKey in feature.VIDEO) {
                    if (feature.VIDEO.hasOwnProperty(videoFeatureKey)) {
                        features.VIDEO[sipFeatureKey] = videoVersion.greaterThanOrEqualTo(this._convertVersion(feature.VIDEO[videoFeatureKey]));
                    }
                }

                this.features = features;
            } else {
                Debug.Control.Intercom.General && console.warn("FeatureCheck: This miniserver does not support sending the Intercom Version");
            }
        }

        /**
         * The version needs to be converted from the odd Baudisch version code to a normal comparable version code
         * @param baudischVCode
         * @returns {string}
         * @private
         */
        _convertVersion(baudischVCode) {
            var secondCharacter = baudischVCode.charAt(1);

            if (secondCharacter !== ".") {
                // The minor version character can only be lower case characters (specified by Baudisch)
                // so a - 96 = 1
                var asciOffset = 96;
                var minorVersion = secondCharacter.charCodeAt(0) - asciOffset;
                var vArray = baudischVCode.split(secondCharacter);
                return vArray.join(".") + "." + minorVersion;
            } else {
                return baudischVCode + ".0";
            }
        }

    };
});
