import * as React from "react"
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg"

function House(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <G clipPath="url(#clip0_1100_159695)" fill="#EBEBF5">
                <Path d="M20.146 7.354A.5.5 0 0021 7V2.5a.5.5 0 00-.5-.5H16a.5.5 0 00-.354.854l4.5 4.5zM3 12.62V24h7v-7h4v7h7V12.62l-9-9-9 9z" />
                <Path d="M23.863 12.662c-.004-.003-.006-.01-.01-.015l-11.5-11.5a.506.506 0 00-.707 0l-11.5 11.5a.492.492 0 00-.01.69.496.496 0 00.71.019.019.019 0 00.007-.002L12 2.207l11.146 11.148a.014.014 0 00.006.002.501.501 0 00.694 0 .019.019 0 00.007-.002.493.493 0 00.01-.693z" />
            </G>
            <Defs>
                <ClipPath id="clip0_1100_159695">
                    <Path fill="#fff" d="M0 0H24V24H0z" />
                </ClipPath>
            </Defs>
        </Svg>
    )
}

export default House
