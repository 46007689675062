"use strict";

define(['JalousieControlEnums', 'JalousieControlContentConfigHelpScreen'], function (JalousieControlEnums, JalousieControlContentConfigHelpScreen) {
    return class JalousieControlContentConfigAdjustScreen extends Controls.ControlContent {
        //region Static
        static Template = function () {
            var getStateView = function getStateView(mode, step, up, isJalousieMotor) {
                return $('<div class="jalousie-control-content-config-adjust-screen__container-view">' + '   <div class="container-view__info-button">' + '       ' + ImageBox.getResourceImageWithClasses("resources/Images/ActiveMiniserver/Menu/icon-info.svg", "info-button__icon") + '   </div>' + '<div class="container-view__icon-container">' + getTopIconForMode(mode, up) + '</div>' + '<div class="container-view__text-container">' + '<div class="text-container__title">' + getTitleForModeAndDir(mode, up, isJalousieMotor) + '</div>' + '<div class="text-container__sub-title">' + getSubtitleForModeAnDir(mode, step, up, isJalousieMotor) + '</div>' + '       ' + getRetryButton(mode) + '</div>' + '</div>');
            };

            var getTitleForModeAndDir = function getTitleForModeAndDir(mode, up, isJalousieMotor) {
                if (mode === JalousieControlEnums.ConfigModes.SET_END_POSITION) {
                    if (isJalousieMotor && up) {
                        return _('controls.jalousie.config.set-end-position.jalousie.top');
                    }

                    return up ? _('controls.jalousie.config.set-end-position.top') : _('controls.jalousie.config.set-end-position.bottom');
                } else if (mode === JalousieControlEnums.ConfigModes.CORRECT_END_POSITION) {
                    return _("controls.jalousie.correct.set-end-position.title");
                } else if (mode === JalousieControlEnums.ConfigModes.ADJUST_SLATS) {
                    return _("controls.jalousie.learn-slat-adjustment.title");
                }
            };

            var getSubtitleForModeAnDir = function getSubtitleForModeAnDir(mode, step, up, isJalousieMotor) {
                if (mode === JalousieControlEnums.ConfigModes.SET_END_POSITION || mode === JalousieControlEnums.ConfigModes.CORRECT_END_POSITION) {
                    var attrs = {
                        iconOne: getIconForMode(mode, false),
                        iconTwo: getIconForMode(mode, true)
                    };

                    if (mode === JalousieControlEnums.ConfigModes.SET_END_POSITION) {
                        if (isJalousieMotor && up) {
                            return _('controls.jalousie.config.set-end-position.jalousie.top.description', attrs);
                        }

                        return up ? _('controls.jalousie.config.set-end-position.top.description', attrs) : _('controls.jalousie.config.set-end-position.bottom.description', attrs);
                    } else if (mode === JalousieControlEnums.ConfigModes.CORRECT_END_POSITION) {
                        return _('controls.jalousie.config.set-end-position.unknown.description', attrs);
                    }
                } else if (mode === JalousieControlEnums.ConfigModes.ADJUST_SLATS) {
                    if (step === JalousieControlEnums.ConfigSteps.SLAT_ADJUSTMENT.USER_UP) {
                        return _("controls.jalousie.learn-slat-adjustment.subtitle", {
                            icon: getIconForMode(mode, up)
                        });
                    } else if (step === JalousieControlEnums.ConfigSteps.SLAT_ADJUSTMENT.USER_DOWN) {
                        return _("controls.jalousie.learn-slat-adjustment.subtitle-2", {
                            icon: getIconForMode(mode, up)
                        });
                    }
                }
            };

            var getIconForMode = function getIconForMode(mode, up) {
                return ImageBox.getResourceImageWithClasses(getDirectionIconSrcForMode(mode, up), "sub-title-container__icon");
            };

            var getDirectionIconSrcForMode = function getDirectionIconSrcForMode(mode, up) {
                if (mode === JalousieControlEnums.ConfigModes.SET_END_POSITION) {
                    if (up) {
                        return "resources/Images/Controls/Jalousie/jalousie/config/icon-rotate-left.svg";
                    } else {
                        return "resources/Images/Controls/Jalousie/jalousie/config/icon-rotate-right.svg";
                    }
                } else if (mode === JalousieControlEnums.ConfigModes.CORRECT_END_POSITION || mode === JalousieControlEnums.ConfigModes.ADJUST_SLATS) {
                    if (up) {
                        return "resources/Images/Controls/Jalousie/arrows/arrow-up-circled.svg";
                    } else {
                        return "resources/Images/Controls/Jalousie/arrows/arrow-down-circled.svg";
                    }
                }
            };

            var getTopIconForMode = function getTopIconForMode(mode, up) {
                return ImageBox.getResourceImageWithClasses(getIconSrcForMode(mode, up), "icon");
            };

            var getIconSrcForMode = function getIconSrcForMode(mode, up) {
                if (mode === JalousieControlEnums.ConfigModes.SET_END_POSITION) {
                    return "resources/Images/Controls/Jalousie/jalousie/config/icon-end_position.svg";
                } else if (mode === JalousieControlEnums.ConfigModes.CORRECT_END_POSITION) {
                    return "resources/Images/Controls/Jalousie/jalousie/config/icon-move-end_position.svg";
                } else if (mode === JalousieControlEnums.ConfigModes.ADJUST_SLATS) {
                    if (up) {
                        return "resources/Images/Controls/Jalousie/jalousie/config/icon-lamellen_01.svg";
                    } else {
                        return "resources/Images/Controls/Jalousie/jalousie/config/icon-lamellen_02.svg";
                    }
                }
            };

            var getRetryButton = function getRetryButton(mode) {
                if (mode === JalousieControlEnums.ConfigModes.ADJUST_SLATS && Feature.GEIGER_SLAT_ADJUSTMENT_RETRY) {
                    return '<div class="text-container__retry-button">' + '   ' + ImageBox.getResourceImageWithClasses("resources/Images/Controls/Jalousie/jalousie/config/icon-rotate-right.svg", "retry-button__icon") + '   <div class="retry-button__text">' + _('retry.operation') + '</div>' + '</div>';
                }

                return '';
            };

            return {
                getStateView: getStateView,
                getDirectionIconSrcForMode: getDirectionIconSrcForMode,
                getTopIconForMode: getTopIconForMode,
                getTitleForModeAndDir: getTitleForModeAndDir,
                getSubtitleForModeAnDir: getSubtitleForModeAnDir
            };
        }(); //endregion Static

        constructor(details) {
            super(details);
            this.mode = details.mode;
            this.step = details.step;
            this._isJalousieMotor = this._supportsMode(JalousieControlEnums.ConfigModes.ADJUST_SLATS); // if slats adjustment is available, it's a jalousie motor
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(() => {
                var stateView = JalousieControlContentConfigAdjustScreen.Template.getStateView(this.mode, this.step, !this._isDown(this.mode, this.step), this._isJalousieMotor);
                let appendPrms = [];
                this.collectionView = new GUI.CollectionViewV2(this, this);
                this.tableView = new GUI.TableViewV2(this, this);
                this.elements = {
                    stateView: {
                        icon: stateView.find(".container-view__icon-container"),
                        title: stateView.find(".container-view__text-container .text-container__title"),
                        subtitle: stateView.find(".container-view__text-container .text-container__sub-title")
                    }
                };
                this.element.append(stateView);
                appendPrms.push(this.appendSubview(this.collectionView));
                appendPrms.push(this.appendSubview(this.tableView));

                return Q.all(appendPrms).then(() => {
                    let reloadPrms = [true];

                    this.collectionView.getElement().addClass("jalousie-control-content-config-adjust-screen__collection-view");
                    this.tableView.getElement().addClass("jalousie-control-content-config-adjust-screen__table-view");
                    reloadPrms.push(this.collectionView.reload());
                    reloadPrms.push(this.tableView.reload());

                    return Q.all(reloadPrms).then(() => {
                        this.infoButtonElement = this.element.find(".container-view__info-button");

                        if (this.mode === JalousieControlEnums.ConfigModes.ADJUST_SLATS) {
                            // add info button
                            this.infoButton = new GUI.LxButton(this, this.infoButtonElement[0], window.Styles.colors.green, null, true);
                            this.infoButton.setActiveParts([{
                                part: this.infoButtonElement.find(".info-button__icon")[0],
                                mode: "fill"
                            }]);
                            this.addToHandledSubviews(this.infoButton);
                            this.infoButton.onButtonReleased = this._handleInfoButton.bind(this);

                            if (Feature.GEIGER_SLAT_ADJUSTMENT_RETRY) {
                                // add retry button
                                this.retryButton = new GUI.LxButton(this, this.element.find(".text-container__retry-button")[0], window.Styles.colors.green, null, true);

                                this.retryButton.setActiveParts([{
                                    part: this.element.find(".text-container__retry-button .retry-button__icon")[0],
                                    mode: "fill"
                                }, {
                                    part: this.element.find(".text-container__retry-button .retry-button__text")[0],
                                    mode: "color"
                                }]);
                                this.addToHandledSubviews(this.retryButton);
                                this.retryButton.onButtonReleased = this._handleRetryButton.bind(this);
                            }
                        } else {
                            this.infoButtonElement.hide();
                            this.elements.stateView.icon.toggleClass("icon-container--up", !this._isDown(this.mode, this.step)); // rotates icon 180°
                        }
                    })
                })
            });
        }

        viewWillDisappear() {
            clearInterval(this._sendInterval);
            super.viewWillDisappear(...arguments);
        }

        getURL() {
            return "Adjust-" + this.mode + "-" + this.step;
        }

        getAnimation() {
            return AnimationType.FADE;
        }

        receivedStates(states) {
            // we have to toggle between upper and lower end position correction!
            if (this.mode === JalousieControlEnums.ConfigModes.CORRECT_END_POSITION) {
                var isDown = states.positionState >= 0.5;

                if (typeof this._isDownDirection === "undefined" || // also adjust if it's the initial status update
                    isDown !== this._isDownDirection) {
                    //console.error("switching directions..");
                    this.step = isDown ? JalousieControlEnums.ConfigSteps.END_POSITION.BOTTOM : JalousieControlEnums.ConfigSteps.END_POSITION.TOP;
                    this.elements.stateView.title.text(JalousieControlContentConfigAdjustScreen.Template.getTitleForModeAndDir(this.mode, !isDown, this._isJalousieMotor));
                    this.elements.stateView.subtitle.html(JalousieControlContentConfigAdjustScreen.Template.getSubtitleForModeAnDir(this.mode, this.step, !isDown, this._isJalousieMotor));
                    this.elements.stateView.icon.toggleClass("icon-container--up", !this._isDown(this.mode, this.step)); // rotates icon 180°

                    this.tableView.reload(); // only reload tableView, not collectionView (may cancel the user input..!)

                    this._isDownDirection = isDown;
                }
            } else if (this.mode === JalousieControlEnums.ConfigModes.ADJUST_SLATS) {
                this.collectionView.getElement().addClass("single-direction");
            }
        }

        // TableViewDataSource methods
        styleForTableView() {
            return HD_APP ? TableViewStyle.GROUPED : null;
        }

        numberOfSections() {
            return 1;
        }

        numberOfRowsInSection(section, tableView) {
            if (tableView === this.collectionView) {
                if (this.mode === JalousieControlEnums.ConfigModes.ADJUST_SLATS) {
                    return 1; // slats can only be adjusted in one direction per step
                } else {
                    return 2;
                }
            } else {
                return 1;
            }
        }

        cellTypeForCellAtIndex(section, row, tableView) {
            if (tableView === this.collectionView) {
                return GUI.TableViewV2.CellType.Special.COLLECTION_BUTTON;
            } else {
                return GUI.TableViewV2.CellType.GENERAL;
            }
        }

        contentForCell(cell, section, row, tableView) {
            if (tableView === this.collectionView) {
                var down = row === 0;

                if (this.mode === JalousieControlEnums.ConfigModes.ADJUST_SLATS) {
                    down = this._isDown(this.mode, this.step);
                }

                return {
                    title: "",
                    iconSrc: JalousieControlContentConfigAdjustScreen.Template.getDirectionIconSrcForMode(this.mode, !down)
                };
            } else {
                var isDown = this._isDown(this.mode, this.step);

                var title = _("controls.jalousie.config.set-end-position.save", {
                    direction: !isDown ? _("controls.jalousie.config.set-end-position.upper") : _("controls.jalousie.config.set-end-position.lower")
                });

                if (this.mode === JalousieControlEnums.ConfigModes.ADJUST_SLATS) {
                    title = _("controls.jalousie.learn-slat-adjustment.save-slat-position");
                }

                return {
                    title: title,
                    leftIconSrc: "resources/Images/Controls/Jalousie/jalousie/config/icon-save.svg",
                    leftIconClass: "save-icon",
                    clickable: true
                };
            }
        }

        // TableViewDelegate methods
        didSelectCell(cell, section, row, tableView) {
            if (tableView === this.tableView) {
                this.sendCommand(Commands.format(Commands.JALOUSIE.CONFIG.LEARN, Commands.JALOUSIE.CONFIG.ACK));
            }
        }

        buttonHit(section, row) {
            clearInterval(this._sendInterval);
            this._sendInterval = setInterval(function () {
                this.sendCommand(Commands.format(Commands.JALOUSIE.CONFIG.LEARN, this._getCommandForButton(section, row)));
            }.bind(this), 200);
        }

        buttonTapped(section, row) {
            clearInterval(this._sendInterval);
            this.sendCommand(Commands.format(Commands.JALOUSIE.CONFIG.LEARN, this._getCommandForButton(section, row)));
            this.sendCommand(Commands.format(Commands.JALOUSIE.CONFIG.LEARN, Commands.JALOUSIE.CONFIG.CANCEL));
        }

        buttonReleased(section, row) {
            clearInterval(this._sendInterval);
            this.sendCommand(Commands.format(Commands.JALOUSIE.CONFIG.LEARN, Commands.JALOUSIE.CONFIG.CANCEL));
        }

        _isDown(mode, step) {
            if (mode === JalousieControlEnums.ConfigModes.ADJUST_SLATS) {
                return step === JalousieControlEnums.ConfigSteps.SLAT_ADJUSTMENT.USER_DOWN || step === JalousieControlEnums.ConfigSteps.SLAT_ADJUSTMENT.MOVING_DOWN;
            } else {
                return step === JalousieControlEnums.ConfigSteps.END_POSITION.BOTTOM;
            }
        }

        _getCommandForButton(section, row) {
            if (this.mode === JalousieControlEnums.ConfigModes.ADJUST_SLATS) {
                if (this._isDown(this.mode, this.step)) {
                    return Commands.JALOUSIE.CONFIG.DOWN_OR_RIGHT;
                } else {
                    return Commands.JALOUSIE.CONFIG.UP_OR_LEFT;
                }
            } else {
                if (row === 0) {
                    return Commands.JALOUSIE.CONFIG.DOWN_OR_RIGHT;
                } else {
                    return Commands.JALOUSIE.CONFIG.UP_OR_LEFT;
                }
            }
        }

        _handleInfoButton() {
            //if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface) {
            //    NavigationComp.openWebsite(_('controls.jalousie.config.slats-help-link'));
            //} else {
            new JalousieControlContentConfigHelpScreen().show(); //}
        }

        _handleRetryButton() {
            if (this.mode === JalousieControlEnums.ConfigModes.ADJUST_SLATS) {
                if (this.step === JalousieControlEnums.ConfigSteps.SLAT_ADJUSTMENT.USER_UP) {
                    this.sendCommand(Commands.format(Commands.JALOUSIE.CONFIG.LEARN, Commands.JALOUSIE.CONFIG.RETRY_SLATS_ADJ_STEP1));
                } else if (this.step === JalousieControlEnums.ConfigSteps.SLAT_ADJUSTMENT.USER_DOWN) {
                    this.sendCommand(Commands.format(Commands.JALOUSIE.CONFIG.LEARN, Commands.JALOUSIE.CONFIG.RETRY_SLATS_ADJ_STEP2));
                }
            }
        }

        _supportsMode(mode) {
            return this.control.details.availableModes.indexOf(mode + "") !== -1;
        }

    };
});
