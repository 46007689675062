'use strict';

window.GUI = function (GUI) {
    class Screen extends GUI.View {
        //region Static
        static BLOCK_SCREENSAVER_INTERVAL = 20 * 1000;
        static Template = function () {
            var getStateIconsTemplate = function getStateIconsTemplate(stateIconSrc) {
                return '<div class="screen__state-icons">' + '   ' + '<div class="state-icons__pulse"></div>' + '   ' + ImageBox.getResourceImageWithClasses(stateIconSrc, "state-icons__bg") + '</div>';
            };

            var getSmallIcon = function getSmallIcon(iconSrc) {
                var isIconElement = iconSrc.startsWith("<svg"),
                    isMsIcon = !isIconElement && !iconSrc.startsWith("resources/"),
                    el;

                if (isMsIcon) {
                    console.error("not supported!");
                } else if (isIconElement) {
                    el = ImageBox.addClassesToSVG(iconSrc, "small-icon__icon");
                } else {
                    el = ImageBox.getResourceImageWithClasses(iconSrc, "small-icon__icon");
                }

                return $(el);
            };

            return {
                getStateIconsTemplate: getStateIconsTemplate,
                getSmallIcon: getSmallIcon
            };
        }(); //endregion Static

        //region Getter
        get hasOrientationPlugin() {
            return PlatformComponent.isDeveloperInterface() || PlatformComponent.isCordova && window.hasOwnProperty("screen") && window.screen.hasOwnProperty("orientation") && window.screen.orientation && typeof window.screen.orientation.lock === "function";
        } //endregion Getter


        constructor(screenElement) {
            super(screenElement);
            Debug.GUI.Screens && console.log(this.viewId, "ctor");
            this._preventPermissionRegistration = false;
            this._setIconNr = 0; // important async loaded icons to overwrite e.g. icons provided by states
            // Directly create a resolved Defered, as a permissive screen can only be created with an established connection

            this._connectionEstablishedDef = Q.defer();

            this._connectionEstablishedDef.resolve();
        }

        supportedOrientation() {
            if (HD_APP || AMBIENT_MODE) {
                return DeviceOrientation.ANY;
            } else {
                return DeviceOrientation.PORTRAIT_PRIMARY;
            }
        }

        /**
         * @note If the returned promise needs longer then "WAITING_INFO_TIMEOUT" to resolve a waiting popup will be shown
         * @return {*}
         */
        viewDidLoad() {
            Debug.GUI.Screens && console.log(this.viewId, "viewDidLoad");
            var vdlPrms;

            this._checkTitlebar();

            if (!this.elements) {
                this.elements = {};
            }

            vdlPrms = super.viewDidLoad(...arguments) || Q.resolve(); // Register the events to known when the connection is established
            // this is reflected in a promise for easier use

            CompChannel.on(CCEvent.ConnClosed, function (event) {
                this._connectionEstablishedDef = Q.defer();
            }.bind(this));
            CompChannel.on(CCEvent.ConnEstablished, function (event) {
                this._connectionEstablishedDef.resolve();
            }.bind(this));
            return this._grantPermissions().then(function () {
                return vdlPrms;
            }.bind(this));
        }

        viewWillAppear() {
            Debug.GUI.Screens && console.log(this.viewId, "viewWillAppear"); // This function will be called during initialization of the NavigationComp, thus the NavigationComp
            // won't be available for the first screen (RootViewController).

            if (NavigationComp) {
                try {
                    // Save the reference to the closeAction for correct unregistering the back navigation
                    this._boundCloseAction = this.closeAction.bind(this); // ensure proper cancellation even tough a system "back-button" was used (browser/Android)

                    NavigationComp.registerForBackNavigation(this._boundCloseAction);
                } catch (e) {// Exceptions may occur here when quickly show and close a screen
                }
            }

            return super.viewWillAppear(...arguments);
        }

        /**
         * returns the current LxTitleBar
         * @returns {object}
         */
        getTitleBar() {
            return this._titleBar;
        }

        /**
         * Icon path for the state icon
         * @returns {string}
         */
        getStateIconTemplatePath() {
            return Icon.STATE_ICON.COMFORT_MODE_SCREEN;
        }

        /**
         * Title for the waiting for popup.
         * @note It will only appear if the viewDidLoad promise takes longer then "WAITING_INFO_TIMEOUT" to resolve
         * @return {null}
         */
        getWaitingForTitle() {
            return null;
        }

        /**
         * Message for the waiting for popup.
         * @note It will only appear if the viewDidLoad promise takes longer then "WAITING_INFO_TIMEOUT" to resolve
         * @return {null}
         */
        getWaitingForMessage() {
            return null;
        }

        /**
         * If the waiting for popup can be canceled, default is true
         * @note It will only appear if the viewDidLoad promise takes longer then "WAITING_INFO_TIMEOUT" to resolve
         * @return {Boolean}
         */
        isWaitingForCancelable() {
            return true;
        }

        /**
         * The retry function that should be executed when the viewDidLoad promise failed.
         * @return {null|Function}
         */
        getWaitingForRetryFunction() {
            return null;
        }

        /**
         * The Message for the retry dialog
         * @note The retry dialog only appears if getWaitingForRetryFunction is implemented
         * @return {null}
         */
        getWaitingForRetryMessage() {
            return null;
        }

        viewDidAppear() {
            Debug.GUI.Screens && console.log(this.viewId, "viewDidAppear");
            var vdlPrms = super.viewDidAppear(...arguments);

            this._attachTitleBarDelegates();

            if (this.iconButton) {
                this.iconButton.onButtonTapped = this.onIconTapped.bind(this);
            }

            if (this.titleButton) {
                this.titleButton.onButtonTapped = this.onTitleTapped.bind(this);
            }

            if (this.subtitleButton) {
                this.subtitleButton.onButtonTapped = this.onSubtitleTapped.bind(this);
            }

            checkLegacyDrawingBug();

            if (this.getShouldBlockScreenSaver()) {
                this._startBlockingScreenSaver();
            }

            return this._grantPermissions().then(function () {
                this._registerPermissions();

                if (Debug._CYPRESS_TESTS_ACTIVE) {
                    triggerEvent(document, "screenDidAppear", {
                        url: this.getURL()
                    });
                }

                return vdlPrms;
            }.bind(this), this._navigateBackDueToCanceledPermissionElevation.bind(this));
        }

        /**
         * if the view should be updated, or if another view should be initialized and shown
         * @returns {boolean}
         */
        shouldBeUpdated() {
            return true;
        }

        updateView(details) {
            Debug.GUI.Screens && console.log(this.viewId, "updateView");
            return Q.resolve();
        }

        viewWillDisappear() {
            Debug.GUI.Screens && console.log(this.viewId, "viewWillDisappear");

            if (this.iconButton) {
                this.iconButton.onButtonTapped = null;
            }

            if (this.titleButton) {
                this.titleButton.onButtonTapped = null;
            }

            if (this.subtitleButton) {
                this.subtitleButton.onButtonTapped = null;
            }

            if (this._boundCloseAction) {
                NavigationComp.unregisterFromBackNavigation(this._boundCloseAction);
            }

            this._stopBlockingScreenSaver();

            return super.viewWillDisappear(...arguments);
        }

        viewDidDisappear(viewRemainsVisible) {
            Debug.GUI.Screens && console.log(this.viewId, "viewDidDisappear: remainsVisible: " + !!viewRemainsVisible);
            var promise = super.viewDidDisappear(...arguments);

            if (this._titleBar) {
                this._titleBar.onLeftButtonTapped = null;
                this._titleBar.onRightButtonTapped = null;
            }

            return promise;
        }

        getURL() {
            if (this.constructor.name.endsWith("Screen")) {
                // to handle eg. "ScreenSaverSettingsScreen"
                return this.constructor.name.substring(0, this.constructor.name.length - 6);
            }

            return this.constructor.name;
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        handleStatusBarTap() {
            if (this.tableView) {
                this.tableView.resetScrollTop(); // TODO make smooth scrolling to top
            }
        }

        destroy() {
            Debug.GUI.Screens && console.log(this.viewId, "destroy");

            this._unregisterPermissions();

            if (Debug._CYPRESS_TESTS_ACTIVE) {
                triggerEvent(document, "screenDidDisappear", {
                    url: NavigationComp.getURL().split("/").slice(1).join("/")
                });
            }

            super.destroy(...arguments);
        }

        /**
         * Adds: title, subtitle, icon (+small icon), close button
         * Removes: LxTitleBar
         * UI Elements
         * you can use these functions afterwards:
         * .setTitle
         * .setSubtitle
         * .closeAction (default navigateBack)
         * .setTintColor
         *
         * @param displayIcons if the big/small icons should be displayed
         */
        setUpComfortUI(displayIcons) {
            this.element.addClass("screen--comfort");
            this.elements.titleLbl = $('<div class="screen__title-lbl" />');

            if (this.onTitleTapped) {
                this.titleButton = new GUI.LxButton(this, this.elements.titleLbl);
                this.addToHandledSubviews(this.titleButton);
            }

            this.elements.subtitleLbl = $('<div class="screen__subtitle-lbl" />');

            if (this.onSubtitleTapped) {
                this.subtitleButton = new GUI.LxButton(this, this.elements.subtitleLbl);
                this.addToHandledSubviews(this.subtitleButton);
            }

            if (!this.reactNavigationProps) {
                if (this._titleBar) {
                    this.removeSubview(this._titleBar);
                }
                // Why do we do this? We need our the SafeArea here to align everything
                this.element.removeClass("uses-react-safe-area");
            } else {
                if (!AMBIENT_MODE) {
                    this.element.css("padding-top", `${NavigationComp.getStatusBarHeight()}px`);
                }
                this.element.css("padding-bottom", `${NavigationComp.getSafeAreas().bottom}px`);
                this.reactNavigationProps.navigation.setOptions({
                    headerTransparent: true
                });
            }

            this._handleComfortModeScreenButtons(); // Remove the TitleBar


            if (displayIcons) {
                this.elements.iconPlaceholder = $(Screen.Template.getStateIconsTemplate(this.getStateIconTemplatePath()));
                this.elements.iconPulser = this.elements.iconPlaceholder.find(".state-icons__pulse");
                this.elements.iconBackground = this.elements.iconPlaceholder.find(".state-icons__bg");
                this.elements.bigIconCtnr = this.elements.iconPlaceholder.find(".icon__big-icon");
                this.elements.smallIconCtnr = this.elements.iconPlaceholder.find(".icon__small-icon");
            }

            return GUI.animationHandler.schedule(function () {
                this.element.prepend(this.elements.subtitleLbl);
                this.element.prepend(this.elements.titleLbl); // Icons

                if (displayIcons) {
                    this.element.prepend(this.elements.iconPlaceholder);
                }
            }.bind(this)).then(() => {
                if (displayIcons) {
                    if (this.onIconTapped) {
                        this.iconButton = new GUI.LxButton(this, this.elements.iconPlaceholder);
                        this.addToHandledSubviews(this.iconButton);
                    }
                }
            });
        }

        /**
         * Generate buttons which are displayed on top of the screen
         * This can be subclassed to add custom buttons
         * @return {Array}
         */
        getScreenButtons() {
            var leftIcon = this.getLeftIcon(),
                rightIcon = this.getRightIcon(),
                buttons = [];

            if (leftIcon) {
                this.leftButton = this._prepareButton(leftIcon, "screen__button-left", this.leftAction.bind(this));
                buttons.push(this.leftButton);
            }

            if (rightIcon) {
                this.rightButton = this._prepareButton(rightIcon, "screen__button-right screen__button-right--most-right", this.rightAction.bind(this));
                buttons.push(this.rightButton);
            }

            return buttons;
        }

        setTintColor(color) {
            GUI.animationHandler.schedule(function screenSetTintColor() {
                if (color) {
                    this.elements.iconPulser && this.elements.iconPulser.css("background", color);
                    this.elements.iconBackground && this.elements.iconBackground.css("fill", color);
                } else {
                    this.elements.iconPulser && this.elements.iconPulser.css("background", "initial");
                    this.elements.iconBackground && this.elements.iconBackground.css("fill", "");
                }

                this.elements.iconPulser && this.elements.iconPulser.toggleClass("state-icons__pulse--pulsing", !!color);
            }.bind(this));
        }

        setTitle(title) {
            if (this.reactNavigationProps && this.reactNavigationProps.navigation) {
                this.reactNavigationProps.navigation.setOptions({
                    title: ""
                });
            }
            this._scheduleLblUpdate(this.elements.titleLbl, title);
        }

        setSubtitle(subtitle) {
            this._scheduleLblUpdate(this.elements.subtitleLbl, subtitle);
        }

        _scheduleLblUpdate(lbl, text) {
            if (lbl) {
                GUI.animationHandler.schedule(function _scheduleLblUpdate() {
                    if (text) {
                        lbl.text(text);
                    } else {
                        lbl.empty();
                    }
                });
            }
        }

        titleBarAction() {
            return this.closeAction();
        }

        closeAction() {
            return this.ViewController.navigateBack();
        }

        /**
         * return an array of objects with the needed permissions for this Screen
         * { permission: <permission>, revoke: <true/false> }
         * @returns {null}
         */
        getPermissions() {
            return null;
        }

        hasPermissionsGranted() {
            var requiredPermissionObjs = this.getPermissions(),
                requiredPermission,
                hasGrantedPerms = Q(true);

            if (requiredPermissionObjs) {
                requiredPermission = this._getPermissionsMask(requiredPermissionObjs);
                hasGrantedPerms = SandboxComponent.checkGrantedPermission(requiredPermission);
            }

            return hasGrantedPerms;
        }

        /**
         * If this returns true, the screen will ensure that the screensaver won't appear as long as it's visible.
         * @returns {boolean}
         */
        getShouldBlockScreenSaver() {
            return false;
        }

        _handleComfortModeScreenButtons() {
            if (!this.reactNavigationProps) {
                this.screenButtons = this.getScreenButtons();
                return GUI.animationHandler.schedule(() => {
                    this.rightScreenBtnContainer && this.rightScreenBtnContainer.remove();
                    this.element.append('<div class="screen__right-screen-buttons"/>');
                    this.rightScreenBtnContainer = this.element.find('.screen__right-screen-buttons')
                    this.screenButtons.forEach((button) => {
                        if (button.showLeft) {
                            this.element.append(button.getElement());
                        } else {
                            this.rightScreenBtnContainer.append(button.getElement());
                        }
                    });
                });
            }
        }

        _startBlockingScreenSaver() {
            this._stopBlockingScreenSaver();

            SandboxComponent.activityTick()
            this._blockScreenSaverTimer = setTimeout(this._startBlockingScreenSaver.bind(this), Screen.BLOCK_SCREENSAVER_INTERVAL);
        }

        _stopBlockingScreenSaver() {
            this._blockScreenSaverTimer && clearTimeout(this._blockScreenSaverTimer);
            this._blockScreenSaverTimer = null;
        }

        // Titlebar
        _checkTitlebar() {
            // intercept here to use the titleBarV2
            if (this.titleBarV2Config) {
                this._checkTitleBarV2();

                return;
            }

            var text = this.titleBarText && this.titleBarText();

            if (text) {

                var cfg = this.titleBarConfig && this.titleBarConfig() || {};
                if (cfg.leftSide === undefined) {
                    cfg.leftSide = this._getLeftSideForTitleBar();
                }

                this._titleBar = new GUI.LxTitleBar(cfg);

                this._titleBar.setTitleBarSideTexts(text);
                if (this.reactNavigationProps) {
                    if ("subTitleBar" in cfg && cfg.subTitleBar) {
                        this.reactNavigationProps.navigation.setOptions({
                            headerShown: false
                        });
                        this.element.removeClass("uses-react-safe-area");
                        this.prependSubview(this._titleBar);
                    } else {
                        this.reactNavigationProps.navigation.setOptions({
                            headerShown: true
                        });
                    }
                } else {
                    this.prependSubview(this._titleBar);
                }
            }
        }

        _getLeftSideForTitleBar() {
            var leftSide,
                animationType = this._intendedInAnimation || this._inAnimation; // use this refs, the are the correctly used animations

            if (animationType === AnimationType.MODAL || animationType === AnimationType.HD_MODAL_FULLSCREEN || animationType === AnimationType.HD_OVERLAY_FULLSCREEN || animationType === AnimationType.FADE || animationType === AnimationType.NONE) {
                leftSide = TitleBarCfg.Button.CLOSE;
            } else {
                leftSide = TitleBarCfg.Button.BACK;
            }

            return leftSide;
        }

        _checkTitleBarV2() {
            var cfg = this.titleBarV2Config && this.titleBarV2Config();

            if (!cfg) {
                return;
            }

            cfg.title = this.titleBarText && this.titleBarText(); // check if at least the left side is defined, if not, assign sensible default values

            if (cfg.leftIcon === undefined) {
                cfg.leftIcon = this._showCloseIcon() ? Icon.TitleBar.CLOSE : Icon.TitleBar.BACK;
            }

            this._titleBar = new GUI.LxTitleBarV2(cfg);
            this.prependSubview(this._titleBar);
        }

        _grantPermissions() {
            var requiredPermissionObjs = this.getPermissions(),
                requiredPermission,
                retPrms = Q(true);

            if (requiredPermissionObjs && !this._preventPermissionRegistration) {
                requiredPermission = this._getPermissionsMask(requiredPermissionObjs); // Await unlocking of the app to await the promise. This prevents any timing issue with multiple authentication dialogs

                retPrms = BiometricHelper.unlockApp().then(function () {
                    // Wait for an active connection, as we can't get permissions without an active connection
                    return this._connectionEstablishedDef.promise.depActiveMsThen(function () {
                        return SandboxComponent.getPermission(requiredPermission);
                    }.bind(this));
                }.bind(this));
            }

            return retPrms;
        }

        /**
         * Returns all permissions combined into a bitmask.
         * @param [permissions]     optional, if not provided getPermissions is called
         * @returns {*}
         * @private
         */
        _getPermissionsMask(permissions) {
            return (permissions || this.getPermissions()).map(function (perm) {
                return perm.permission;
            }).reduce(function (sum, right) {
                return sum | right; // Add the permissions to one bitmap
            });
        }

        _registerPermissions() {
            var permissions = this.getPermissions();

            if (permissions && permissions.length > 0) {
                this._permissions = [];

                this._permissions.push(SandboxComponent.registerForPermissions(permissions));
            }
        }

        _unregisterPermissions() {
            if (this._permissions) {
                while (this._permissions.length > 0) {
                    this._permissions.pop()();
                }
            }
        }

        _showCloseIcon() {
            var result = false,
                animationType = this._intendedInAnimation || this._inAnimation; // use this refs, the are the correctly used animations

            if (animationType === AnimationType.MODAL || animationType === AnimationType.HD_MODAL_FULLSCREEN || animationType === AnimationType.HD_OVERLAY_FULLSCREEN || animationType === AnimationType.FADE || animationType === AnimationType.NONE) {
                result = true;
            }

            return result;
        }

        /*
                     =============================
                                ICONS
                     =============================
                     */
        setIcon(iconSrc) {
            if (!this.elements.iconPlaceholder) {
                return;
            }

            var currSetIconNr = ++this._setIconNr;

            if (this.elements.stateIcon) {
                GUI.animationHandler.remove(this.elements.stateIcon);
                delete this.elements.stateIcon;
            }

            this.toggleStateIcons(!!iconSrc);

            if (!iconSrc) {
                return;
            }

            var isIconElement = iconSrc.startsWith("<svg"),
                isHttpIcon = iconSrc.startsWith("http"),
                isMsIcon = !isHttpIcon && !isIconElement && !iconSrc.startsWith("resources/");

            if (isMsIcon) {
                this._setMsIcon(iconSrc, currSetIconNr);

                return;
            } else if (isIconElement) {
                this.elements.stateIcon = $(iconSrc);
            } else {
                this._setSvgImage(iconSrc);
            }

            GUI.animationHandler.append(this.elements.stateIcon, this.elements.bigIconCtnr);
        }

        setIconColor(color) {
            if (!this.elements.stateIcon) {
                return;
            }

            this.elements.stateIcon.css('fill', color);
        }

        setSmallIcon(icon) {
            if (!this.elements.iconPlaceholder) {
                return;
            }

            GUI.animationHandler.schedule(function () {
                this.elements.smallIconCtnr.empty();
                this.elements.iconPlaceholder.toggleClass("state-icons--with-small", !!icon);
            }.bind(this));

            if (!icon) {
                return;
            }

            var iconEl = Screen.Template.getSmallIcon(icon.iconSrc);
            iconEl.css('fill', icon.color);
            GUI.animationHandler.append(iconEl, this.elements.smallIconCtnr);
        }

        toggleStateIcons(show) {
            if (!this.elements.iconPlaceholder) {
                return;
            }

            if (!show) {
                GUI.animationHandler.hide(this.elements.iconPlaceholder);
            } else {
                GUI.animationHandler.setCssAttr(this.elements.iconPlaceholder, "display", "");
            }
        }

        toggleComfortModeUI(show) {
            this.toggleStateIcons(show);
            GUI.animationHandler.schedule(function afToggleComfortModeUI() {
                if (show) {
                    this.elements.titleLbl && this.elements.titleLbl.css("display", "");
                    this.elements.subtitleLbl && this.elements.subtitleLbl.css("display", "");
                } else {
                    this.elements.titleLbl && this.elements.titleLbl.hide();
                    this.elements.subtitleLbl && this.elements.subtitleLbl.hide();
                }
            }.bind(this));
        }

        /**
         * If not null, the icon provided under the path will be displayed in the top left corner.
         * leftAction will be called when tapped.
         * @return {null}
         */
        getLeftIcon() {
            return this._showCloseIcon() ? Icon.Buttons.CLOSE : Icon.Buttons.BACK;
        }

        /**
         * If not null, the icon provided under the path will be displayed in the top right corner.
         * rightAction will be called when tapped.
         * @return {null}
         */
        getRightIcon() {
            return null;
        }

        /**
         * Called when the top left icon is tapped.
         */
        leftAction() {
            this.closeAction();
        }

        /**
         * Called when the top right icon is tapped.
         */
        rightAction() {// nothing to do
        }

        _setMsIcon(iconSrc, currSetIconNr) {
            ImageBox.getImage(iconSrc).then(function (image) {
                if (this._setIconNr !== currSetIconNr) {
                    // Icon already changed, bail out.
                    return;
                }

                if (this.elements.stateIcon) {
                    GUI.animationHandler.remove(this.elements.stateIcon);
                }

                if (image.mimeType === "svg") {
                    this.elements.stateIcon = $(image.image);
                } else {
                    this._setSvgImage(image.image);
                }

                GUI.animationHandler.append(this.elements.stateIcon, this.elements.bigIconCtnr);
            }.bind(this));
        }

        _prepareButton(btnIcon, btnClass, action, newIcon, btnIconClasses = "") {
            var btnElem = $('<div class="screen__button ' + btnClass + '">' + ImageBox.getResourceImageWithClasses(btnIcon, "button__icon" + " " + btnIconClasses) + '</div>'); // We need to pass icon classes, otherwise it's not possible to style the icon, trim() is called to remove any leading/trailing spaces if there are no classes
            var button = new GUI.LxRippleButton(btnElem);
            this.addToHandledSubviews(button);
            button.showBackground = !!newIcon;
            button.visible = true;
            button.iconSrc = btnIcon;
            button.showLeft = btnClass.includes("button-left");
            button.on(GUI.LxRippleButton.CLICK_EVENT, action);
            return button;
        }

        _setSvgImage(source) {
            var height = this.elements.bigIconCtnr.attr("height"),
                width = this.elements.bigIconCtnr.attr("width");
            this.elements.stateIcon = getIconElement(source, height, width);
        }

        /**
         * Navigating back to the last screen without permissions
         * @private
         */
        _navigateBackDueToCanceledPermissionElevation() {
            // Navigate back to the last view without permissions if the user canceled the permission elevation
            this.ViewController.navigateBackToScreenWithoutPermission();
        }

        _attachTitleBarDelegates() {
            if (this._titleBar) {
                this._titleBar.onLeftButtonTapped = function () {
                    var prms;

                    if (typeof this.titleBarAction === "function") {
                        prms = this.titleBarAction() || Q(true);
                    } else {
                        prms = this.ViewController.navigateBack();
                    }

                    prms.then(function () {
                        // don't reset if rejected, otherwise the button no longer works on retry.
                        this._titleBar.onLeftButtonTapped = null;
                    }.bind(this));
                }.bind(this);

                if (typeof this.titleBarActionRight === "function") {
                    this._titleBar.onRightButtonTapped = this.titleBarActionRight.bind(this);
                }
            }
        }

        _shouldLogViewLifeCycle() {
            return Debug.GUI.ScreenViewLifeCycle;
        }

    }

    GUI.Screen = Screen;

    return GUI;
}(window.GUI || {});
