import {AUTO_MODE, CAPABILITIES} from "../climateControlEnums";

class ClimateControlUtils {
    static getAutomaticSectionDescription (capabilities, isAvgAutomatic, modeByLogic = false) {
        let texts = [];

        if (modeByLogic) {
            texts.push(_("controlled-via-logic"));
        }

        if (isAvgAutomatic) {
            if (capabilities === CAPABILITIES.HEATING_AND_COOLING) {
                texts.pushObject(_("controls.climate.auto-mode.outside-temp.desc.mixed"));
            } else if (capabilities === CAPABILITIES.HEATING) {
                texts.pushObject(_("controls.climate.auto-mode.outside-temp.desc.heating"));
            } else if (capabilities === CAPABILITIES.COOLING) {
                texts.pushObject(_("controls.climate.auto-mode.outside-temp.desc.cooling"));
            }
        } else {
            if (capabilities === CAPABILITIES.HEATING_AND_COOLING) {
                texts.pushObject(_("controls.climate.auto-mode.like-demand.desc.mixed"));
            } else if (capabilities === CAPABILITIES.HEATING) {
                texts.pushObject(_("controls.climate.auto-mode.like-demand.desc.heating"));
            } else if (capabilities === CAPABILITIES.COOLING) {
                texts.pushObject(_("controls.climate.auto-mode.like-demand.desc.cooling"));
            }
        }

        return texts.join(SEPARATOR_SYMBOL);
    }

    static getTemperatureBoundarySectionDescription (autoMode) {
        if (autoMode === AUTO_MODE.HEATING_AND_COOLING || autoMode === AUTO_MODE.OFF) {
            return _("controls.climate.temp-boundary.desc.mixed");
        } else if (autoMode === AUTO_MODE.HEATING_ONLY) {
            return _("controls.climate.temp-boundary.desc.heating");
        } else if (autoMode === AUTO_MODE.COOLING_ONLY) {
            return _("controls.climate.temp-boundary.desc.cooling");
        }
    }

    static getOffTextForCapability (capabilities) {
        if (capabilities === CAPABILITIES.HEATING_AND_COOLING) {
            return _("controls.climate.off.heatcool");
        } else if (capabilities === CAPABILITIES.HEATING) {
            return _("controls.climate.off.heat");
        } else if (capabilities === CAPABILITIES.COOLING) {
            return _("controls.climate.off.cool");
        } else {
            return _("off");
        }
    }


    static getTemperatureBoundarySectionHeader (capabilities) {
        if (capabilities === CAPABILITIES.HEATING_AND_COOLING) {
            return _("controls.climate.temp-boundary", {
                count: 2
            });
        } else if (capabilities !== CAPABILITIES.NONE) {
            return _("controls.climate.temp-boundary");
        }
    }
}

export default ClimateControlUtils
