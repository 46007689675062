import {useSafeAreaInsets} from "react-native-safe-area-context";
import { useSize } from 'ahooks';
import {useEffect, useRef} from "react";

const NopeAreaProp = "--bottom-nope-area";

/**
 * Will ensure that the height of the element the ref points to is minded in the nope-area.
 * On unmount, this hook will reset to the default nope-area (safe-area-bottom-inset), but
 * only if it is still the hook that did make the latest modification to the nope-area.
 * @param ref
 */
export default function useNopeAreaForRef(ref) {
    const insets = useSafeAreaInsets(),
        size = useSize(ref);

    const setterId = useRef(getRandomIntInclusive(0,99999) + "");

    const applyLatestNopeArea = (unmounted = false) => {
        const latestNopeAreaObj = window.nopeAreaDs[window.nopeAreaDs.length - 1];
        document.documentElement.style.setProperty(NopeAreaProp, `calc(${latestNopeAreaObj.value}px + var(--instruction-bar-height, 0px))`);
    }

    useEffect(() => {
        window.nopeAreaDs = window.nopeAreaDs || [{pid: "-1", value: insets.bottom }];

        if (size && size.height) {
            window.nopeAreaDs.push({pid: setterId.current, value: size.height + insets.bottom});
            applyLatestNopeArea();
        }
        return () => {
            // remove the stored prop for this hook;
            window.nopeAreaDs = window.nopeAreaDs.filter(propObj => propObj.pid !== setterId.current);
            applyLatestNopeArea(true);
        }
    }, [size]);
}