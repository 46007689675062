'use strict';

define([], function () {
    return class DaytimerControlContentTimer extends Controls.ControlContent {
        constructor(details) {
            super(details);
        }

        viewDidLoad() {
            var promise = super.viewDidLoad(...arguments);
            this.tableView = new GUI.TableViewV2(this, this);
            this.tableView.getElement().addClass("timer__table-view");
            this.appendSubview(this.tableView);
            SandboxComponent.getMiniserverTimeInfo(this, function (time) {
                this.currentTime = time.add('minutes', 60);
            }.bind(this), TimeValueFormat.MINISERVER_DATE_TIME);
            return promise;
        }

        titleBarActionRight() {
            this._startTimer();
        }

        getURL() {
            return "Timer";
        }

        titleBarText() {
            return _("controls.daytimer.manual");
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.CLOSE,
                rightSide: TitleBarCfg.Button.TICK
            }
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        receivedStates(states) {
            if (!this._initialStatesReceived) {
                if (this.control.details.analog) {
                    this.currentValue = states.defaultValue;
                } else if (!this.currentValue) {
                    this.currentValue = states.value;
                }

                this._initialStatesReceived = true;
                this.tableView.reload();
            }
        }

        // Private functions
        _startTimer() {
            SandboxComponent.getMiniserverTimeInfo(this, function (time) {
                var timeDifference = this.currentTime.diff(time, 'seconds');
                this.sendCommand(Commands.format(Commands.DAYTIMER.START_TIMER, this.currentValue, timeDifference));
                this.ViewController.navigateBack();
            }.bind(this), TimeValueFormat.MINISERVER_DATE_TIME);
        }

        // TableViewDataSource methods
        numberOfSections() {
            return 2;
        }

        styleForTableView() {
            return TableViewStyle.GROUPED;
        }

        numberOfRowsInSection() {
            return 1;
        }

        cellTypeForCellAtIndex(section, row) {
            if (section === 0 && row === 0) {
                if (this.control.details.analog) {
                    return GUI.TableViewV2.CellType.INPUT;
                } else {
                    return GUI.TableViewV2.CellType.SWITCH;
                }
            } else if (section === 1 && row === 0) {
                return GUI.TableViewV2.CellType.DATE_TIME_PICKER;
            }
        }

        titleForHeaderInSection(section) {
            if (section === 0) {
                return _("controls.daytimer.timer.status");
            } else if (section === 1) {
                return _("controls.irc.timer.time");
            }
        }

        contentForCell(cell, section) {
            if (section === 0) {
                if (this.control.details.analog) {
                    return {
                        leftIconSrc: 'resources/Images/Controls/Daytimer/target.svg',
                        title: _("controls.daytimer.entry.value"),
                        value: this.currentValue,
                        autoFocus: true,
                        validationRegex: Regex.DECIMAL_VALUE
                    };
                } else {
                    return {
                        leftIconSrc: 'resources/Images/Controls/Daytimer/target.svg',
                        title: _("controls.daytimer.timer.status.digital"),
                        active: Boolean(this.currentValue),
                        onText: this.control.on ? this.control.on : _("active"),
                        offText: this.control.off ? this.control.off : _("inactive")
                    };
                }
            } else if (section === 1) {
                // PickerCell
                return {
                    iconSrc: "resources/Images/Controls/Daytimer/clock-end.svg",
                    title: _("controls.daytimer.entry.end"),
                    value: this.currentTime,
                    expanded: true
                };
            }
        }

        // TableViewDelegate methods
        textChanged(section, row, tableView, value, valid) {
            if (valid) {
                value = value.replace(/,/, ".");
                this.currentValue = parseFloat(value);
            }
        }

        onSwitchChanged(value) {
            this.currentValue = value ? 1 : 0;
        }

        onPickerChanged(section, row, tableView, value) {
            this.currentTime = value;
        }

    };
});
