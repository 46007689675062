'use strict';

define(['ControlActionScreen', "RemoteKeyboard"], function (ControlActionScreen, RemoteKeyboard) {
    return class RemoteControlContent extends ControlActionScreen {
        constructor() {
            super(...arguments);
            this.selectingModes = true;
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(() => {
                this.keyboardView = new RemoteKeyboard(this.control);
                this.keyboardView.getElement().addClass("content__keyboard-view");
                return this.insertSubviewAfter(this.keyboardView, this.actionTableView.getElement()).then(() => {
                    return this.hideSubview(this.keyboardView)
                });
            });
        }

        shouldDisplayStateIcons() {
            return false;
        }

        receivedStates(states) {
            this.selectingModes = !states.hasActiveMode && !states.waitingForOff;
            return super.receivedStates(...arguments);
        }

        _updateCommandsTable() {
            this._updateCustomContent();

            return super._updateCommandsTable(...arguments);
        }

        getActionTableView() {
            return new GUI.TableViewV2(this.tableViewDataSource, this.tableViewDelegate);
        }

        getActionSection(states) {
            var section;

            if (states.universalIsLocked) {
                section = [];
            } else if (this.selectingModes) {
                section = super.getActionSection(...arguments);
            } else {
                section = {
                    rows: [this._getSelectedModeCell(states)]
                };
            }

            return section;
        }

        /**
         * Extracted as e.g. on the remote action view, the subtitle isn't only dependant on the state.
         * @param states
         * @return {*}
         */
        getSubtitleText(states) {
            if (this.selectingModes) {
                return _("controls.remote.selecting-modes");
            } else {
                return super.getSubtitleText(...arguments);
            }
        }

        /**
         * No additional section, it'll be put into the bottom table.
         * @return {null}
         */
        getAdditionalSection() {
            return null;
        }

        /**
         * Overwrite - if the user did "open" the mode selection while a mode was active, hitting "x" shouldn't
         * close the view, but close the mode selector.
         */
        closeAction() {
            if (this.selectingModes && this.states.hasActiveMode) {
                this.selectingModes = false;

                this._updateCommandsTable(this.states);
            } else {
                return super.closeAction(...arguments);
            }
        }

        /**
         * This method is called e.g. when the lock state changed, it is used to hide/show customized content added
         * by the subclasses. controlActionScreen also sets "customContentVisible" property.
         * @param visible
         */
        setCustomContentVisible(visible) {
            super.setCustomContentVisible(...arguments);

            if (!visible) {
                this.hideSubview(this.keyboardView);
            } else {
                this.toggleSubview(this.keyboardView, !this.selectingModes);
            }
        }

        _updateCustomContent() {
            var states = this.states || this.control.getStates();
            this.setSubtitle(this.selectingModes ? _("controls.remote.selecting-modes") : states.stateTextForContent);
            this.toggleSubview(this.keyboardView, !this.selectingModes && this.customContentVisible);
        }

        _getSelectedModeCell(states) {
            return {
                content: {
                    title: this.control.getModeName(states.activeMode),
                    clickable: true
                },
                type: GUI.TableViewV2.CellType.Special.COMFORT_ACTION,
                action: function () {
                    this.selectingModes = true;

                    this._updateCommandsTable(this.states);
                }.bind(this)
            };
        }

    };
});
