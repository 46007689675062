import Svg, {Path} from "react-native-svg"

function Delete(props) {
    return (
        <Svg isreact="true"
            x="0px"
            y="0px"
            viewBox="0 0 50 50"
            xmlSpace="preserve"
            {...props}
        >
            <Path
                d="M27.7 25l8.7-8.7c.5-.5.5-1.3 0-1.8l-.9-.9c-.5-.5-1.3-.5-1.8 0L25 22.3l-8.7-8.7c-.5-.5-1.3-.5-1.8 0l-.9.9c-.5.5-.5 1.3 0 1.8l8.7 8.7-8.7 8.7c-.5.5-.5 1.3 0 1.8l.9.9c.5.5 1.3.5 1.8 0l8.7-8.7 8.7 8.7c.5.5 1.3.5 1.8 0l.9-.9c.5-.5.5-1.3 0-1.8L27.7 25z"
                fillRule="evenodd"
                clipRule="evenodd"
            />
        </Svg>
    )
}

export default Delete
