'use strict';

define(["ClimateUsControlEnums", "TempLimitsView"], function (CtrlEnums, TempLimitsView) {
    return class TempLimitsScreen extends GUI.Screen {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return '<div class="temp-limits-screen__content"></div>';
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(details) {
            super($('<div />'));
            this.control = details.control;
        }

        viewDidLoad() {
            var promise = super.viewDidLoad(...arguments);
            this.element.append(TempLimitsScreen.Template.getTemplate());
            this.elements = this.elements || {};
            this.elements.contentContainer = this.element.find(".temp-limits-screen__content");
            this.tempView = new TempLimitsView(this.control, CtrlEnums.OutsideTemp.MIN, CtrlEnums.OutsideTemp.MAX);
            this.tempView.getElement().addClass("content__temp-view");
            this.appendSubview(this.tempView, this.elements.contentContainer);
            return promise;
        }

        getAnimation() {
            return AnimationType.HD_OVERLAY;
        }

        titleBarText() {
            return _("controls.climate.us.outside-temp.title");
        }

    };
});
