import {useControl, useStatisticV2, DateViewTypeUtils, CircleGraph} from "LxComponents"
import {View} from "react-native";
import PropTypes from "prop-types";
import {useState,useEffect} from "react";
import globalStyles from "GlobalStyles";

export default function MeterV2BidirectionalEnergyBalance({controlUUID, fromUnixUtcTs, toUnixUtcTs, viewType, style}) {
    const control = useControl(controlUUID);
    const [circleGraphValues, setCircleGraphValues] = useState([]);

    const reduceStatistics = (statistic) => {
        let newStats = {...statistic, data: null }
        newStats.data = [statistic.data.reduce((prev, curr) => {
            let newDp = { ...prev, values: [...prev.values] }
            curr.values.forEach((val, idx) => {
                newDp.values[idx] = newDp.values[idx] + val;
            });
            return newDp;
        })];

        return newStats;
    }
    const statPackage = useStatisticV2(
        controlUUID,
        fromUnixUtcTs,
        toUnixUtcTs,
        ["total", "totalNeg"],
        DateViewTypeUtils.getStatisticDiffDataPointUnit(viewType),
        reduceStatistics
    );

    useEffect(() => {
        (async () => {
            updateCircleGraphValues();
        })();
    }, [statPackage])


    const styles = {
        rootCntr: {
            flexDirection: "column"
        }
    }

    const getOutputValFromStats = (outputName) => {
        if (Array.isArray(statPackage.data)) {
            let valIdx = -1;
            statPackage.header.some((header, idx) => {
                if (header.output === outputName) {
                    valIdx = idx;
                    return true;
                }
            });
            if (valIdx >= 0) {
                return statPackage.data[0].values[valIdx];
            } else {
                console.error(MeterV2BidirectionalEnergyBalance.name, "getOutputValFromStats: " + outputName + " - NOT FOUND!", statPackage);
                return 0;
            }
        } else {
            console.warn(MeterV2BidirectionalEnergyBalance.name, "getOutputValFromStats: " + outputName + " - no ready yet...", statPackage);
            return 0;
        }
    }

    const getTotalValue = () => {
        return getOutputValFromStats("total");
    }

    const getTotalNegValue = () => {
        return getOutputValFromStats("totalNeg");
    }

    const updateCircleGraphValues =  () => {
        const arr = [];

        let total = getTotalValue(),
            totalNeg = getTotalNegValue(),
            sum = total + totalNeg;

        arr.push({
            color: globalStyles.colors.green,
            value: totalNeg,
            label: control.getNameForOutput("totalNeg"),
            format: control.totalFormat
        });

        arr.push({
            color: globalStyles.colors.orange,
            value: total,
            label: control.getNameForOutput("total"),
            format: control.totalFormat
        })

        setCircleGraphValues(arr);
    }

    return <View style={{ ...styles.rootCntr, ...style }}>
        <CircleGraph values={circleGraphValues} />
    </View>
}


MeterV2BidirectionalEnergyBalance.propTypes = {
    controlUUID: PropTypes.string.isRequired,
    fromUnixUtcTs: PropTypes.number,
    toUnixUtcTs: PropTypes.number,
    viewType: PropTypes.string
}
