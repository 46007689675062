'use strict';

define(['AudioZoneV2ControlEnums', 'IntercomControlEnums'], function (AudioZoneV2ControlEnums, IntercomControlEnums) {
    var Template = function () {
        return {
            getTemplate: function getTemplate() {
                return $('<div class="recording-screen__content">' + '   <div class="content__header"></div>' + '   <div class="content__recording-state">' + '       <div class="recording-state__indicator">' + '           <div class="indicator__icon">' + '               ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.MIC_COVER_ICON, "icon__record-icon") + '           </div>' + '           <div class="indicator__countdown">3</div>' + '       </div>' + '   </div>' + '   <div class="content__recording-description">' + _("audio-server.voice-recorder") + '</div>' + '   <div class="content__recording-send">' + _("media.announcement.send-recording") + '</div>' + '</div>');
            }
        };
    }();

    return class RecordingScreen extends GUI.Screen {
        //region Private
        MAX_DURATION_IN_S = 60;
        COUNT_DOWN_IN_S = 3; //endregion Private

        constructor(details) {
            super($('<div />'));
            this.control = details.control;
            this.selectedControls = details.controls.filter(function (control) {
                return control.isConfigured();
            });
            this.platform = PlatformComponent.getPlatformInfoObj().platform;
            this.audioResult = {};
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.elements = this.elements || {};
                this.elements.content = Template.getTemplate();
                this.elements.header = this.elements.content.find(".content__header");
                this.elements.countDown = this.elements.content.find(".indicator__countdown");
                this.elements.description = this.elements.content.find(".content__recording-description");
                this.elements.sendBtnElm = this.elements.content.find(".content__recording-send");
                this.element.append(this.elements.content); // Stop the Volume interception, we can't have multiple AVAudioSessions at once

                this.control.audioserverComp.stopVolumeButtonInterception();
                this.sendBtn = new GUI.LxButton(this, this.elements.sendBtnElm);
                this.addToHandledSubviews(this.sendBtn);

                this.sendBtn.onButtonTapped = function () {
                    this._stopRecordingAndIntervals(true);
                }.bind(this);

                this.elements.header.text(this.selectedControls.map(function (control) {
                    return control.getName();
                }).join(", "));

                return this._ensurePermissions();
            }.bind(this));
        }

        viewDidAppear() {
            var promise = super.viewDidAppear(...arguments);
            this.startScreenSaverActivityTick();

            this._startCountdown();

            return promise;
        }

        viewWillDisappear() {
            // The user closed the screen (closeAction, hit on darkner)
            this._stopRecordingAndIntervals(false, !!this.audioResult); // Start the Volume interception again, we can't have multiple AVAudioSessions at once


            this.control.audioserverComp.startVolumeButtonInterception();
            return super.viewWillDisappear(...arguments);
        }

        getURL() {
            return "VoiceRecorder/recording";
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK
            };
        }

        titleBarText() {
            return _('media.menu.announcement');
        }

        startScreenSaverActivityTick() {
            if (!this._screenSaverActivityTick) {
                this._screenSaverActivityTick = setInterval(function () {
                    SandboxComponent.activityTick()
                }, IntercomControlEnums.Timeouts.SCREENSAVER_TICK);
                SandboxComponent.activityTick()
            }
        }

        stopScreenSaverActivityTick() {
            this._screenSaverActivityTick && clearInterval(this._screenSaverActivityTick);
            this._screenSaverActivityTick = null;
        }

        _startCountdown() {
            var remainingSeconds = this.COUNT_DOWN_IN_S;
            this.elements.countDown.text(remainingSeconds);
            this.countDownInterval = setInterval(function () {
                --remainingSeconds;
                this.elements.countDown.text(remainingSeconds);

                if (remainingSeconds === 0) {
                    clearInterval(this.countDownInterval);

                    this._startRecordingCountDown();
                }
            }.bind(this), 1000);
        }

        _startRecordingCountDown() {
            var remainingSeconds = this.MAX_DURATION_IN_S;

            this._startRecording(); // set up recording UI


            this.elements.description.text(_("another-x", {
                x: LxDate.formatSecondsIntoDigits(remainingSeconds, false, true)
            }));
            this.timerTimerInterval = setInterval(function () {
                --remainingSeconds;
                this.elements.description.text(_("another-x", {
                    x: LxDate.formatSecondsIntoDigits(remainingSeconds, false, true)
                }));

                if (remainingSeconds === 0) {
                    this._stopRecordingAndIntervals(true);
                }
            }.bind(this), 1000);
        }

        _startRecording() {
            var prefix = "",
                path;
            this.filename = 'audio_recording.wav';

            if (this.platform === PlatformType.IOS) {
                this.pathWithoutName = cordova.file.tempDirectory;
                prefix = 'cdv';
            } else if (this.platform === PlatformType.Android) {
                // since android 13 the external storage may be optional - hence adopt the path here!
                if (this._usesExternalStorage()) {
                    this.pathWithoutName = cordova.file.externalDataDirectory;
                } else {
                    this.pathWithoutName = cordova.file.cacheDirectory;
                }
            } else {
                this.pathWithoutName = "resources/Audio/";
                this.filename = "bell.wav";
            }

            path = this.pathWithoutName + this.filename;
            this.audioResult.path = path;
            this.audioResult.pathWithoutName = this.pathWithoutName;
            this.audioResult.filename = this.filename;

            if (this.platform !== PlatformType.DeveloperInterface) {
                const fullPath = prefix + path;
                Debug.Media.Announcement && console.log(this.viewId, "_startRecording: creating new media object with path: " +fullPath);
                this.mediaObject = new Media(prefix + path, () => {
                    Debug.Media.Announcement && console.log(this.viewId, "_startRecording: successfully created the media object at file path: " +fullPath);
                }, err => {
                    Debug.Media.Announcement && console.error(this.viewId, "_startRecording: failed to create media object at file path: " + fullPath + ", err=" + (err.message || JSON.stringify(err)));
                });
                Debug.Media.Announcement && console.log(this.viewId, "_startRecording: about to start recording!");
                this.elements.content.addClass(" recording-screen__content--recording");
                this.mediaObject.startRecord();
            }
        }

        _stopRecordingAndIntervals(autoHide, dontSend) {
            var result,
                comps = [],
                uploadPrms = [];
            this.elements.content.removeClass(" recording-screen__content--recording");
            this.timerTimerInterval && clearInterval(this.timerTimerInterval);
            this.countDownInterval && clearInterval(this.countDownInterval);
            this.stopScreenSaverActivityTick();

            if (this.mediaObject) {
                this.mediaObject.stopRecord();
                result = cloneObject(this.audioResult);
                delete this.mediaObject;
            } else if (this.platform === PlatformType.DeveloperInterface) {
                result = cloneObject(this.audioResult);
            }

            if (result && !dontSend) {
                delete this.audioResult;
                var serverUuids = [];
                this.selectedControls.forEach(function (control) {
                    if (control.isFixedGroup) {
                        control.resolveFixedGroup().forEach(function (groupCtrl) {
                            serverUuids.pushIfNoDuplicate(groupCtrl.details.server);
                        });
                    } else {
                        serverUuids.pushIfNoDuplicate(control.details.server);
                    }
                });
                serverUuids.map(function (serverUuid) {
                    return window.audioserverComps[serverUuid];
                }).forEach(function (comp) {
                    if (comp) {
                        comps.push(comp);
                        uploadPrms.push(comp.uploadAudioFile(result));
                    } else {
                        console.log(this.name, "No Comp available!");
                    }
                }.bind(this)); // Wait until everything is uploaded, then play the files

                Q.all(uploadPrms).then(function () {
                    return Q.all(comps.map(function (comp) {
                        return comp.playRecordedFile(result, this.selectedControls.map(function (control) {
                            return control.details.playerid;
                        })).then(function () {
                            comp.deleteFile(result);
                        }.bind(this), function (e) {
                            console.error(this.name, e);
                            comp.deleteFile(result);
                        }.bind(this));
                    }.bind(this)));
                }.bind(this), function (e) {
                    console.log(this.name, "An error occured: " + e.message);
                }.bind(this));
            }

            autoHide && this.ViewController.dismiss();
        }



        _ensurePermissions() {
            if (!PlatformComponent.isAndroid()) {
                return Q.resolve();
            }
            const permissions = [
                cordova.plugins.permissions.RECORD_AUDIO,
                cordova.plugins.permissions.MODIFY_AUDIO_SETTINGS
            ];
            if (this._usesExternalStorage()) {
                permissions.push(cordova.plugins.permissions.WRITE_EXTERNAL_STORAGE);
            }
            const missingPermissions = [];
            Debug.Media.Announcement && console.log(this.viewId, "_ensurePermissions: " + permissions.join(", "));

            return Q.all(permissions.map(perm => {
                Debug.Media.Announcement && console.log(this.viewId, "_ensurePermissions > check: " + perm);
                return this._checkPermission(perm).then(null, (err) => {
                    Debug.Media.Announcement && console.log(this.viewId, "_ensurePermissions > " + perm + " - not granted, need to request!");
                    missingPermissions.push(perm);
                });
            })).then(() => {
                if (missingPermissions.length > 0) {
                    Debug.Media.Announcement && console.log(this.viewId, "_ensurePermissions > not all permissions granted already, request some! " + missingPermissions.join(", "));
                    return this._requestPermissions(missingPermissions);
                } else {
                    Debug.Media.Announcement && console.log(this.viewId, "_ensurePermissions > all permissions granted!");
                    return Q.resolve();
                }
            });
        }

        _usesExternalStorage() {
            const versionStr = PlatformComponent.getPlatformInfoObj().version;
            // "version":"12"
            try {
                const versionInt = parseInt(versionStr);
                return versionInt < 13;
            } catch (ex) {
                return false;
            }
        }

        _checkPermission(permission) {
            var def = Q.defer();
            cordova.plugins.permissions.checkPermission(permission, (result) => {
                result.hasPermission ? def.resolve(result) : def.reject(result);
            }, (permissionError) => {
                def.reject(permissionError);
            });
            return def.promise;
        }

        _requestPermissions(permissionArr) {
            var def = Q.defer();
            cordova.plugins.permissions.requestPermissions(permissionArr, (result) => {
                result.hasPermission ? def.resolve(result) : def.reject(result);
            }, (permissionError) => {
                def.reject(permissionError);
            });

            return def.promise;
        }

    };
});
