'use strict';

define(["ContentOverviewScreenBase", "ControlContentMenu", "HistoryManager"], function (ContentOverviewScreenBase, ControlContentMenu, HistoryManager) {
    return class HistoryOverviewScreens extends ContentOverviewScreenBase {
        constructor(details) {
            super(...arguments);
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.boundHistoryChange = this.reloadTable.bind(this);
                HistoryManager.shared(this.control).registerForChange(this.boundHistoryChange);
            }.bind(this));
        }

        destroy() {
            HistoryManager.shared(this.control).unregisterFromChange(this.boundHistoryChange);
            return super.destroy(...arguments);
        }

        titleBarText() {
            return _("media.menu.history");
        }

        getRightVCButtonIcon() {
            return Icon.DELETE;
        }

        getRightVCButtonIconFn() {
            return this.titleBarActionRight.bind(this, this.RIGHT_SIDE_BUTTON.EMPTY_HISTORY);
        }

        titleBarActionRight(buttonId) {
            if (buttonId === this.RIGHT_SIDE_BUTTON.EMPTY_HISTORY) {
                HistoryManager.shared(this.control).clear();
            } else {
                super.titleBarActionRight(...arguments);
            }
        }

        _getRightSideTitleBarButtons() {
            var buttons = [];
            buttons.push(this._getRightSideTitleBarButtonElm(this.RIGHT_SIDE_BUTTON.EMPTY_HISTORY, Icon.AudioZone.CONTEXT.DELETE));
            return buttons;
        }

        getTableContent() {
            return Q.all([this._getHistorySection()]);
        }

        _getHistorySection() {
            var history = HistoryManager.shared(this.control).getHistory();
            return {
                rows: history.map(function (item) {
                    var details = item._historyDetails,
                        onHistoryItemClickedFn = null;
                    details.control = this.control;
                    details.contentTypes = [item.contentType];

                    if (this.control.audioserverComp.isFileTypeBrowsable(item.type)) {
                        onHistoryItemClickedFn = function handleOnHistoryItemClicked(item, contentType, section, row) {
                            this.ViewController.showState(Controls.AudioZoneV2Control.MediaBrowserV2Base.getScreenStateForItem(item), null, details);
                        }.bind(this);
                    }

                    return Controls.AudioZoneV2Control.MediaBrowserV2Base.getConstructorForItem(item).getCellFromContentTypeItem(item, details, item.contentType, true, this.control.audioserverComp.isFileTypeBrowsable(item.type), onHistoryItemClickedFn);
                }.bind(this)),
                footer: history.length > 0 ? null : {
                    iconSrc: Icon.AudioZone.NEW.HISTORY,
                    message: _("audio-server.empty-history"),
                    style: GUI.TableViewV2.FOOTER_STYLE.EMPTY_VIEW
                }
            };
        }

        _shouldShowFavorites() {
            // We have our own fav implementation
            return false;
        }

        _onHistoryChange() {
            return this._reloadSectionWithClass("card-section--history");
        }

    };
});
