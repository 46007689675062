import {
    useLiveState,
    useControl,
    LxReactHeader,
    LxReactHeaderIconSize,
    LxReactButton,
    LxReactText,
    ButtonType,
    LxReactLinkedControlsList
} from "LxComponents";
import {View, ScrollView} from "react-native";
import globalStyles from "GlobalStyles";
import Icons from "IconLib";

export default function LxLegacyReactLockOverlay({navigation, route, children}) {
    const controlUuid = route.params.controlUUID;
    const liveState = useLiveState(controlUuid, ["universalIsLocked", "universalLockReason", "universalIsUnlockable", "stateTextForContent"]);
    const control = useControl(controlUuid);
    const handleUnlock = () => {
        console.log(LxLegacyReactLockOverlay.name, "handleUnlock!");
        control.unlockControl();
    }

    const LockReason = ({lockReason}) => {
        return [
            <LxReactText style={styles.lockNoteTitle}>{"Note"}</LxReactText>
            ,
            <View style={styles.lockReasonCntr}>
                <LxReactText style={styles.lockReason}>{lockReason}</LxReactText>
        </View>
        ];
    }

    const UnlockButton = () => {
        return <LxReactButton
            containerStyle={styles.buttonStyle}
            textStyle={styles.buttonTextStyle}
            buttonType={ButtonType.SECONDARY}
            text={_("control.lock.unlock-title")}
            onPress={handleUnlock} />
    }

    const Locked = () => {
        let lockText = liveState.states.stateTextForContent;
        let lockReason = liveState.states.universalLockReason;
        let lockTitle = _("control.lock.locked-title");

        return <View style={styles.lockCntr}>
            <ScrollView>
            <LxReactHeader
                icon={Icons.Lock}
                title={lockTitle}
                subtitle={lockText}
                style={styles.header}
                iconSize={LxReactHeaderIconSize.Large}
                iconStyle={styles.lockIcon}
            />
            { lockReason && <LockReason lockReason={lockReason} /> }
            { liveState.states.universalIsUnlockable && <UnlockButton /> }
            <LxReactLinkedControlsList style={styles.linkedList} controlUuid={controlUuid} />
            </ScrollView>
        </View>
    }

    return liveState.states.universalIsLocked ? <Locked/> : children;
}

const styles = {
    lockCntr: {
        ...globalStyles.customStyles.screenContent,
        alignSelf: "center",
        justifySelf: "center",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "stretch",
        height: "100%"
    },
    header: {
        marginLeft: globalStyles.sizes.small,
        marginTop: globalStyles.sizes.small,
        marginRight: globalStyles.sizes.small,
        width: "auto"
    },
    lockIcon: {
        fill: globalStyles.colors.red,
    },
    lockNoteTitle: {
        ...globalStyles.textStyles.default,
        color: globalStyles.colors.text.secondary,
        marginLeft: globalStyles.sizes.small * 2,
        marginRight: globalStyles.sizes.small,
        marginTop: globalStyles.sizes.big,
    },
    lockReasonCntr: {
        marginTop: globalStyles.sizes.smallest,
        marginLeft: globalStyles.sizes.small,
        marginRight: globalStyles.sizes.small,
        padding: globalStyles.sizes.small,
        background: globalStyles.colors.grey["300a36"],
    },
    lockReason: {
        ...globalStyles.textStyles.default,
        color: globalStyles.colors.text.secondary,
        fontSize: globalStyles.fontSettings.sizes.smallReg,
    },
    buttonStyle: {
        marginLeft: globalStyles.sizes.small,
        marginRight: globalStyles.sizes.small,
        marginTop: globalStyles.sizes.small
    },
    buttonTextStyle: {
        textAlign: "center"
    },
    linkedList: {
        width: "100%"
    }
}
