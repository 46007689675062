import { Graph, LoadingGraph, DateViewType, useStatisticV2, DateViewTypeUtils, useLiveState } from "LxComponents"
import PropTypes from "prop-types";
import { useMemo } from "react";

import { EfmViewType } from "../../efmUtilities";
import useExtendedStats from "../hooks/useExtendedStats.jsx";
import globalStyles from "GlobalStyles";

import {forceDataIntoMode} from "../util/averageValues.js";
import generateLabels from "../util/generateLabels.js";


export default function StorageLevelGraph({storageNode, fullStretch, onZoom, timeRange, showTitleRight }) {
    const viewType = DateViewType.Live;

    const control = storageNode.control;
    const storStats = useStatisticV2(control.uuidAction, timeRange.start, timeRange.end, ["storageLevel"]);
    const liveState = useLiveState(control.uuidAction, ["storageValue"]);

    const extendedNodeStatsData = useExtendedStats(
        {
            stats: storStats, viewType, startTs: timeRange.start, endTs: timeRange.end,
            newExtend: true // important - doesn't add data that isn't there.
        })

    const {graphData, horizontalLabels, highlightDateFormat, bottomLabelsCentered} = useMemo(() => {
        if(!storStats || !storStats.data || storStats.data.length === 0) {
            return {};
        }

        const graphData = [];
        let usedData = extendedNodeStatsData;
        if(viewType === EfmViewType.Actual) {
            usedData = forceDataIntoMode(extendedNodeStatsData, timeRange.start, timeRange.end, storStats.mode);
        }
        usedData.forEach(stat => {
                const arr = [];
                stat.values.forEach((value, idx) => {
                    arr.push({
                        color: globalStyles.colors.blue,
                        timestamp: stat.ts,
                        value: (/[N|n]eg/g.test(storStats.header[idx].output) && value > 0 ? -value : value),
                    });
                });
                graphData.push(arr);
            }
        );

        const {horizontalLabels, highlightDateFormat, bottomLabelsCentered} = generateLabels({viewType, data: graphData});

        return {
            graphData,
            horizontalLabels,
            highlightDateFormat,
            bottomLabelsCentered: bottomLabelsCentered,
        }
    }, [storStats, timeRange.start, timeRange.end]);

    const {titleRight, label} = useMemo(()=> {
        let formatToUse = '%.0f%%';
        if(control && typeof control.storageFormat === 'string' && control.storageFormat.length > 0) {
            formatToUse = control.storageFormat;
        }
        let retTitleRight = lxFormat(formatToUse, liveState.states.storageValue);
        if(showTitleRight !== true) {
            retTitleRight = '';
        }
        return {
            titleRight: retTitleRight,
            label: lxSplitFormat(formatToUse).succTxt,
        }
    }, [liveState.states.storageValue, showTitleRight]);

    return (
        <Graph
            titleLeft={fullStretch ? undefined : _("efm.storage-level-title")}
            titleRight={fullStretch ? undefined : titleRight}
            fullStretch={fullStretch}
            type={'line'}
            horizontalLabels={horizontalLabels}
            datapoints={graphData}
            label={label}
            fixedUpperLimit={control.details.storageMax}
            highlightDateFormat={highlightDateFormat}
            bottomLabelsCentered={bottomLabelsCentered}
            pillFormat={"%.0f"}
            onZoom={onZoom}
            forcedTimeRange={timeRange}
            legend={
                [
                    {
                        color: globalStyles.colors.blue,
                        label: _("efm.storage-level-title"),
                    }
                ]
            }
        />
    )
}

StorageLevelGraph.propTypes = {
    storageNode: PropTypes.object
}
