import {Pressable, Animated, StyleSheet} from 'react-native';
import PropTypes from "prop-types";
import {useEffect, useRef, forwardRef} from "react";
import {
    ReactWrapper
} from "LxComponents";

/**
 * Wraps the child or children provided in the props in a pressable
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

const LxReactPressable = forwardRef((props, forwardRef) => {
    const doublePressDelay = props.doublePressDelay || 300;
    const longPressTickInterval = props.longPressTickInterval || 200;

    const doublePressTimer = useRef(null);
    const longPressTickTimer = useRef(null);
    const stopTimerRef = (timerRef) => {
        timerRef.current && clearTimeout(timerRef.current);
        timerRef.current = null;
    }
    const startTimerRef = (timerRef, callback, interval) => {
        timerRef.current = setTimeout(callback, interval);
    }


    useEffect(() => {
        return () => {
            stopTimerRef(doublePressTimer);
            stopTimerRef(longPressTickTimer);
        }
    }, [])


    const renderChild = (child, pressableState, childKey) => {
        var rendered = null;
        if (child && child.comp) {
            if (typeof child.comp.render === "function") {
                console.error(LxReactPressable.name, "Rendering a child that is not a function-component will cause excessive repaints");
            } else {
                child.props.key = childKey;
                child.props.pkey = childKey;
                return ReactWrapper.React.createElement(child.comp, child.props);
            }
        } else {
            console.error(LxReactPressable.name, "Rendering child failed, no child or no comp info provided!");
        }
        return rendered;
    }

    const renderChildren = (pressableState) => {
        var rendered = [];

        if (Array.isArray(props.childComps)) {
            rendered.splice(0, 0, ...props.childComps.map((child, idx) => {
                return renderChild(child, pressableState, getKeyProp("-child-" + idx));
            }));
        }
        if (props.childComp) {
            rendered.push(renderChild(props.childComp, pressableState, getKeyProp("-child-" + rendered.length)));
        }
        if (props.children) {
            rendered.push(props.children);
        }
        return rendered.length > 0 ? rendered : null;
    }

    const animatedOpacity = useRef(new Animated.Value(1));
    const animatedScale = useRef(new Animated.Value(1));
    const hitDispatched = useRef(false);
    const pressOutPassed = useRef(false);

    const onPressIn = (event) => {
        Debug.GUI.LxReactPressable && console.log("LxReactPressable", "onPressIn");
        hitDispatched.current = false;
        pressOutPassed.current = false;
        Animated.timing(animatedOpacity.current, {
            toValue: 0.4,
            duration: 50,
            useNativeDriver: true,
        }).start();
        props.onPressIn && props.onPressIn(event);
    };

    const onPressOut = (ev) => {
        Debug.GUI.LxReactPressable && console.log("LxReactPressable", "onPressOut");
        stopTimerRef(longPressTickTimer);

        // fade back in!
        Animated.timing(animatedOpacity.current, {
            toValue: 1,
            duration: 200,
            useNativeDriver: true,
        }).start();
        pressOutPassed.current = true;
        dispatchOnReleased(ev);
        props.onPressOut && props.onPressOut(ev);
    };

    const handleOnPress = (ev) => {
        Debug.GUI.LxReactPressable && console.log("LxReactPressable", "handleOnPress");
        dispatchOnHit(ev, !props.onPress); //when only hit and released is set, a short tap will do nothing because hit is called after release, so we have to force it
        if (props.onDoublePress) {
            // if a timeout is already set, this must be the second tap!
            if (doublePressTimer.current !== null) {
                Debug.GUI.LxReactPressable && console.log("LxReactPressable", "handleOnPress > double tap detected!");
                // double tap detected!
                stopTimerRef(doublePressTimer);
                props.onDoublePress(ev);
            } else {
                Debug.GUI.LxReactPressable && console.log("LxReactPressable", "handleOnPress > wait for potential double tap");
                // no timeout set = first tap, set a timeout, if not cancelled in between, forward the orignal onPress
                startTimerRef(
                    doublePressTimer,
                    () => {
                        Debug.GUI.LxReactPressable && console.log("LxReactPressable", "handleOnPress > not a double tap, dispatch!");
                        stopTimerRef(doublePressTimer);
                        forwardOnPress(ev);
                    },
                    doublePressDelay
                );
            }
        } else {
            // no doubleTap handling!
            forwardOnPress(ev);
        }
        dispatchOnReleased(ev) // will only be dispatched, if onHit has been called before
    };

    const forwardOnPress = (ev) => {
        if (props.onPress) {
            // check if the double-tap interval needs to be minded
            if (props.onPressArgs) {
                props.onPress(ev, ...props.onPressArgs);
            } else {
                props.onPress(ev);
            }
        }
    }

    const onLongPress = (ev) => {
        Debug.GUI.LxReactPressable && console.log("LxReactPressable", "onLongPress");
        let hitDispatched = dispatchOnHit(ev); // remember, don't additionally dispatch a press.
        if (props.onLongPress) {
            props.onLongPress(ev);
        } else if (props.onPress && !props.onLongPressTick && !hitDispatched) {
            forwardOnPress(ev);
        }
        if (props.onLongPressTick) {
            handleLongPressTick(ev);
        }
    }

    const handleLongPressTick = (ev) => {
        Debug.GUI.LxReactPressable && console.log("LxReactPressable", "handleLongPressTick");
        props.onLongPressTick(ev);
        stopTimerRef(longPressTickTimer);
        startTimerRef(longPressTickTimer, handleLongPressTick, longPressTickInterval);
    }

    const getKeyProp = (suffix) => {
        return props.pkey + "-" + suffix;
    }

    /*
        props.onHit will only be dispatched if not already done & if onPressout has not yet passed.
        onPressout will fire before onPress if a very short press is performed, therefore the force flag is used to still trigger onHit
     */
    const dispatchOnHit = (ev, force) => {
        if (props.onHit && (force || (!hitDispatched.current && !pressOutPassed.current))) {
            Debug.GUI.LxReactPressable && console.log("LxReactPressable", "dispatchOnHit");
            hitDispatched.current = true;
            props.onHit(ev);
            return true;
        }
        return false;
    }
    const dispatchOnReleased = (ev) => {
        if (props.onReleased && hitDispatched.current) {
            Debug.GUI.LxReactPressable && console.log("LxReactPressable", "dispatchOnReleased");
            props.onReleased(ev);
        }
    }

    /**
     * Removes the style prop, as this is applied to the animated view.
     * @returns {*}
     */
    const getPressableProps = () => {
        var pProps = {...props};
        pProps.style = [styles.pressable, getSafeStyle(props.pressableStyle)]
        return pProps;
    }

    const getSafeStyle = (styleObject) => {
        return StyleSheet.flatten(styleObject)
    }

    const onToggleHoverAnimation = () => {
        if (!props.showHoverAnimation) {
            return;
        }
        const currentValue = animatedScale.current._value;
        const targetValue = currentValue === 1 ? 1.1 : 1;
        Animated.timing(animatedScale.current, {
            toValue: targetValue,
            duration: 200
        }).start()
    }

    return <Animated.View
        ref={forwardRef}
        style={[{
            opacity: animatedOpacity.current,
            transform: [{
                scale: animatedScale.current
            }]
        }, getSafeStyle(props.style)]}
        key={getKeyProp("anim")}>
        <Pressable {...getPressableProps()}
                   accessible={true}
                   accessibilityRole="button"
                   ref={props.innerRef}
                   onPress={handleOnPress}
                   children={renderChildren}
                   onPressIn={onPressIn}
                   onPressOut={onPressOut}
                   onLongPress={onLongPress}
                   onMouseEnter={onToggleHoverAnimation}
                   onMouseLeave={onToggleHoverAnimation}
                   key={getKeyProp("press")}/>
    </Animated.View>;
})

const styles = {
    pressable: {
        width: "100%",
        height: "100%",
        display: "flex",
    }
}

LxReactPressable.propTypes = {
    ...Pressable.propTypes,
    doublePressDelay: PropTypes.number,
    onDoublePress: PropTypes.func,
    onLongPressTick: PropTypes.func,
    onLongPress: PropTypes.func,
    onHit: PropTypes.func, // make use of this instead of onPressIn --> will be called later, but not if scrolling
    onReleased: PropTypes.func, // make use of this instead of onPressOut --> will be called when onPressou is hit, but not if scrolling
    longPressTickInterval: PropTypes.number,
    pressableStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    showHoverAnimation: PropTypes.bool,
    onPressArgs: PropTypes.array,
    childComp: PropTypes.exact({comp: PropTypes.func.isRequired, props: PropTypes.object}),
    childComps: PropTypes.arrayOf(PropTypes.exact({comp: PropTypes.func.isRequired, props: PropTypes.object})),
    pkey: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired
    ])
};

LxReactPressable.displayName = "LxReactPressable"

export default LxReactPressable;
