'use strict';

define([], function () {
    return class DarkModeSettingsScreen extends GUI.TableViewScreen {
        constructor() {
            super($('<div class="dark-mode-settings-screen" />'));
        }

        titleBarText() {
            return _("dark-mode.appearance");
        }

        reloadTable() {
            this.tableContent = [{
                rows: [this._generateRow(_("dark-mode.light"), !NavigationComp.getDarkModeState()), this._generateRow(_("dark-mode.dark"), NavigationComp.getDarkModeState())]
            }];
            return super.reloadTable(...arguments);
        }

        _generateRow(title, isLightMode) {
            return {
                type: GUI.TableViewV2.CellType.CHECKABLE,
                content: {
                    title: title,
                    selected: isLightMode,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE
                },
                didCheckCell: function didCheckCell(cell, section, row, tableView, selected) {
                    NavigationComp.setDarkModeState(row === 1);
                }
            };
        }

    };
});
