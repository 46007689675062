'use strict';

define([], function () {
    return class ControlHelpScreen extends GUI.Screen {
        //region Static
        static Template = class {
            //region Static
            static getHelpTextContainer(helpText) {
                // Its important to sanitize the HTML to prevent any XSS injections!
                return $("<div class='control-help-screen__text-container'>" + helpText.sanitizeHTML().insertHrefs().replace(Regex.NEW_LINE, "<br>") + "</div>");
            } //endregion Static


        }; //endregion Static

        constructor(details) {
            super($("<div/>"));
            this.control = details.control;
        }

        viewDidLoad() {
            var args = arguments;
            return this._loadHelpText().then(helpText => {
                return super.viewDidLoad(...args).then(function () {
                    this.element.append(ControlHelpScreen.Template.getHelpTextContainer(helpText));
                }.bind(this));
            });
        }

        getURL() {
            return "control/" + this.control.uuidAction + "/help";
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            return _('note');
        }

        _loadHelpText() {
            return CommunicationComponent.sendViaHTTP(Commands.format(Commands.CONTROL.HELP_TEXT, this.control.uuidAction), EncryptionType.REQUEST);
        }

    };
});
