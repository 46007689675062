'use strict';

define("DeviceLearningCreateAttributeScreen", function () {
    return class DeviceLearningCreateAttributeScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div/>'));
            this.selectorType = details.type;
            this.datasetLength = details.datasetLength;
            this.lastSelectedRoomUuid = details.roomUuid;
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.CLOSE,
                rightSide: TitleBarCfg.Button.TICK
            };
        }

        titleBarActionRight() {
            var def = Q.defer();

            switch (this.selectorType) {
                case DeviceManagement.SELECTOR_TYPES.SWITCHBOARD:
                case DeviceManagement.SELECTOR_TYPES.HEAT_SWITCHBOARD:
                    def.resolve(this.ViewController.navigateBack(false, {
                        name: this.inputText || this.placeholder
                    }));
                    break;

                case DeviceManagement.SELECTOR_TYPES.LIGHT_GROUP:
                    def.resolve(this._createLightGroup().then(function (res) {
                        return this.ViewController.navigateBack(false, getLxResponseValue(res));
                    }.bind(this)));
                    break;
            }

            return def.promise;
        }

        titleBarText() {
            return _('media.preferences.services.editor.add');
        }

        // TableView DataSource Methods
        styleForTableView() {
            return TableViewStyle.COMFORT_MODE_2020;
        }

        updateView(details) {
            switch (details.type) {
                case DeviceManagement.SELECTOR_TYPES.ROOM:
                    this.selectedRoomName = details.value.name;
                    this.selectedRoomUuid = details.value.uuid;
                    break;

                case DeviceManagement.SELECTOR_TYPES.LIGHT_GROUP_TYPE:
                    this.selectedGroupTypeName = details.value.name;
                    this.selectedGroupTypeId = details.value.id;
                    break;
            }

            this.reloadTable();
        }

        reloadTable() {
            this.tableContent = [];

            switch (this.selectorType) {
                case DeviceManagement.SELECTOR_TYPES.SWITCHBOARD:
                case DeviceManagement.SELECTOR_TYPES.HEAT_SWITCHBOARD:
                    this.tableContent.push(this._getFirstInputSection());
                    break;

                case DeviceManagement.SELECTOR_TYPES.LIGHT_GROUP:
                    this.tableContent.push(this._getFirstInputSection());
                    this.tableContent.push(this._getRoomTypeSection());
                    break;
            }

            return super.reloadTable(...arguments);
        }

        _getFirstInputSection() {
            var section = {
                    rows: []
                },
                placeholderCounter = this.datasetLength + 1;

            switch (this.selectorType) {
                case DeviceManagement.SELECTOR_TYPES.HEAT_SWITCHBOARD:
                    this.placeholder = _('device-learning.heat-switchboard') + " " + placeholderCounter;
                    break;

                case DeviceManagement.SELECTOR_TYPES.SWITCHBOARD:
                    this.placeholder = _('device-learning.switchboard') + " " + placeholderCounter;
                    break;

                case DeviceManagement.SELECTOR_TYPES.LIGHT_GROUP:
                    this.placeholder = _('device-learning.light-group') + " " + placeholderCounter;
                    break;
            }

            section.rows.push({
                type: GUI.TableViewV2.CellType.INPUT,
                content: {
                    value: this.inputText || "",
                    placeholder: this.placeholder,
                    title: _('device-learning.designation'),
                    validationRegex: Regex.NAME,
                    filterRegex: Regex.NAME_FILTER
                },
                textChanged: this._handleTextChange.bind(this),
                submitText: this._handleTextChange.bind(this)
            });
            return section;
        }

        _getRoomTypeSection() {
            var section = {
                    rows: []
                },
                availableRooms; // if no room has been selected yet, use the last selected room.

            if (this.lastSelectedRoomUuid && !this.selectedRoomUuid) {
                availableRooms = ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.ROOM, true);
                this.selectedRoomUuid = availableRooms[this.lastSelectedRoomUuid].uuid;
                this.selectedRoomName = availableRooms[this.lastSelectedRoomUuid].name;
            }

            section.rows.push({
                content: {
                    title: _('room'),
                    disclosureIcon: true,
                    disclosureText: this.selectedRoomName || _('not-used'),
                    clickable: true
                },
                action: function () {
                    this.ViewController.showState(ScreenState.DeviceLearningSelectorScreen, null, {
                        type: DeviceManagement.SELECTOR_TYPES.ROOM,
                        roomUuid: this.selectedRoomUuid
                    });
                }.bind(this)
            }); // later needed

            /*section.rows.push({
                content: {
                    title: _('device-learning.group-type'),
                    disclosureIcon: true,
                    disclosureText: this.selectedGroupTypeName || "",
                    clickable: true
                },
                action: function () {
                    this.ViewController.showState(ScreenState.DeviceLearningSelectorScreen, null, {
                        type: DeviceManagement.SELECTOR_TYPES.LIGHT_GROUP_TYPE,
                        groupTypeId: this.selectedGroupTypeId
                    });
                }.bind(this)
            });*/

            return section;
        }

        _handleTextChange(section, row, tableView, value, valid, valueDidChange) {
            if (valid) {
                this.inputText = value;
            }
        }

        _createLightGroup() {
            var cmd,
                configJson = {}; // 0 === not in use

            if (this.selectedGroupTypeId > 0) {
                configJson.type = this.selectedGroupTypeId;
            }

            if (this.selectedRoomUuid) {
                configJson.room = this.selectedRoomUuid;
            }

            cmd = Commands.format(Commands.DEVICE_MANAGEMENT.CREATE_LIGHT_GROUP, this.inputText || this.placeholder, JSON.stringify(configJson));
            return SandboxComponent.sendWithPermission(cmd, MsPermission.DEVICE_MANAGEMENT).then(function (res) {
                return res;
            }.bind(this));
        }

    };
});
