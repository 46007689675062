'use strict';

define([], function () {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var QR_SIZE = 1024;
    return class LxQRCodeView extends GUI.View {
        constructor() {
            super($('<div class="lx-qr-code-view">' + '<img class="lx-qr-code-view__img">' + '</div>'));
            this.canvas = document.createElement('canvas');
            this.canvas.width = QR_SIZE;
            this.canvas.height = QR_SIZE;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.image = this.element.find(".lx-qr-code-view__img");
        }

        // Public Methods

        /**
         * Just draws the QR Code
         * @param text
         * @param logoSrc ATTENTION: Due to a Firefox bug (700533), SVG images must have a height and width defined to be visible
         * @return {*}
         */
        draw(text, logoSrc) {
            var def = Q.defer();

            if (logoSrc) {
                var logo = new Image();

                logo.onload = function (logo) {
                    def.resolve(this._getQRCodeDataUrl(text, logo));
                }.bind(this, logo);

                logo.src = logoSrc;
            } else {
                def.resolve(this._getQRCodeDataUrl(text));
            }

            return def.promise.then(function (dataUrl) {
                this.image.attr("title", text);
                this.image.attr("alt", text);
                this.image.attr("src", dataUrl);
                return dataUrl;
            }.bind(this));
        }

        /**
         * Draws the QR Code in a canvas and returns the dataURL of the image with type PNG
         * @param text
         * @param logo
         * @return {string}
         * @private
         */
        _getQRCodeDataUrl(text, logo) {
            this.canvas.getContext("2d").clearRect(0, 0, QR_SIZE, QR_SIZE); // http://larsjung.de/jquery-qrcode/latest/demo/

            var options = {
                // render method: 'canvas', 'image' or 'div'
                render: 'canvas',
                // version range somewhere in 1 .. 40
                minVersion: 5,
                maxVersion: 40,
                // error correction level: 'L', 'M', 'Q' or 'H'
                ecLevel: 'H',
                // offset in pixel if drawn onto existing canvas
                left: 0,
                top: 0,
                // size in pixel
                size: QR_SIZE,
                // code color or image element
                fill: '#000',
                //'#74A805',
                text: text,
                // corner radius relative to module width: 0.0 .. 0.5
                radius: 0.1,
                // quiet zone in modules
                quiet: 1,
                // modes
                // 0: normal
                // 1: label strip
                // 2: label box
                // 3: image strip
                // 4: image box
                mode: 0,
                mSize: 0.33,
                mPosX: 0.5,
                mPosY: 0.5,
                //label: 'Loxone',
                //fontname: FONT.Regular,
                //fontcolor: '#000',
                // background color or image element, null for transparent background
                background: "#fff"
            };

            if (typeof logo !== "undefined") {
                options.mode = 4;
                options.image = logo;
            }

            var canvas = $(this.canvas);
            canvas.qrcode(options);
            return this.canvas.toDataURL("image/png");
        }

    };
});
