'use strict';
/**
 * Prevents the TableContent for the AudioZoneControlContent
 */

define('CustomizationHelper', [], function () {
    return class CustomizationHelper {
        constructor() {
            this.control = MediaServerComp.getActiveZoneControl();
        }

        registerCustomizationChanges(clbk, preventInitial) {
            PersistenceComponent.registerForSharedKeyChange(MediaEnum.CUSTOMIZATION_KEYS.APPEARANCE, clbk, preventInitial);
        }

        unregisterCustomizationChanges(clbk) {
            PersistenceComponent.unregisterFromSharedKeyChange(MediaEnum.CUSTOMIZATION_KEYS.APPEARANCE, clbk);
        }

        saveRating(rating) {
            return this.getSavedSettings().then(function (settings) {
                settings[this.control.uuidAction] = rating;
                PersistenceComponent.setShared(MediaEnum.CUSTOMIZATION_KEYS.APPEARANCE, settings);
                return PersistenceComponent.synchronizeShared(); // Directly send the change to the Miniserver
            }.bind(this));
        }

        getSavedSettings(uuidAction) {
            return PersistenceComponent.getShared(MediaEnum.CUSTOMIZATION_KEYS.APPEARANCE).then(function (res) {
                return res;
            }, function () {
                return {};
            }).then(function (res) {
                res = res || {};

                if (uuidAction) {
                    if (!res.hasOwnProperty(uuidAction)) {
                        res[uuidAction] = {};
                    }

                    return Object.assign(this.getDefaultRating(), res[uuidAction]);
                } else {
                    return res;
                }
            }.bind(this));
        }

        getDefaultRating() {
            var defaultRating = {};
            defaultRating[MediaEnum.ControlContentIdentifiers.SEARCH] = -2;
            defaultRating[MediaEnum.ControlContentIdentifiers.HOME] = -1;
            defaultRating[MediaEnum.ControlContentIdentifiers.ZONE_FAVORITES] = 0;
            defaultRating[MediaEnum.ControlContentIdentifiers.SHORTCUTS] = 1;
            defaultRating[MediaEnum.ControlContentIdentifiers.LOXONE] = 2;
            defaultRating[MediaEnum.ControlContentIdentifiers.SPOTIFY] = 3;
            defaultRating[MediaEnum.ControlContentIdentifiers.TUNE_IN] = 4;
            defaultRating[MediaEnum.ControlContentIdentifiers.SETTINGS] = 999; // Define subtypes

            defaultRating[MediaEnum.ControlContentIdentifiers.LOXONE_FAVS] = true;
            defaultRating[MediaEnum.ControlContentIdentifiers.LOXONE_PLAYLISTS] = true;
            defaultRating[MediaEnum.ControlContentIdentifiers.LOXONE_LIB] = true;
            defaultRating[MediaEnum.ControlContentIdentifiers.LOXONE_LINE_IN] = true;
            defaultRating[MediaEnum.ControlContentIdentifiers.LOXONE_VOICE_RECORDER] = true;
            defaultRating[MediaEnum.ControlContentIdentifiers.LOXONE_ZONE_GROUPS] = true;
            Object.values(MediaEnum.Spotify.TYPES).forEach(function (type) {
                defaultRating[MediaEnum.ControlContentIdentifiers.SPOTIFY + "." + type] = true;
            });
            return defaultRating;
        }

    };
});
