'use strict';

define(['AudioZoneControlEnums'], function (AudioZoneControlEnums) {
    class AudioZoneControlFavoriteEdit extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            Object.assign(this, MediaStateHandler.Mixin);
            this.details = details;
            this.control = details.control;
            this.zoneFavorites = [];
            this.globalFavorites = [];
            this.favSectionIdx = 1;
            this.globalFavSectionIdx = 2;

            if (MediaServerComp.Feature.COPY_ZONE_FAVS) {
                this.globalFavSectionIdx = 3;
            }
        }

        getTableView() {
            this.tableViewDelegate.didRequestRemovingCell = this.didRequestRemovingCell.bind(this);
            return new GUI.EditableTableView(this.tableViewDataSource, this.tableViewDelegate);
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                this._registerForMediaServerEvents(this.EVENT_TYPES.ZONE_FAVORITES, this.EVENT_TYPES.FAVORITES);
            }.bind(this));
        }

        destroy() {
            this._sendFavoriteChanges();

            this._unregisterForMediaServerEvents(this.EVENT_TYPES.ZONE_FAVORITES, this.EVENT_TYPES.FAVORITES);

            super.destroy();
        }

        titleBarText() {
            return this.details.title || _("edit");
        }

        receivedZoneFavoritesAllDataEvent(res) {
            this.zoneFavorites = Array.apply(Array, Array(MediaEnum.MAX_ZONE_FAVS)).map(function () {
                return null;
            }); // Create an empty array with 8 entries

            res.items.forEach(function (zoneFav) {
                this.zoneFavorites[zoneFav.slot - 1] = zoneFav;
            }.bind(this));
            this._ogZoneFavorites = cloneObject(this.zoneFavorites);
            this.reloadRowsSelective(this.favSectionIdx, 0, MediaEnum.MAX_ZONE_FAVS);
        }

        receivedFavoritesAllDataEvent(res) {
            this.globalFavorites = res.items;
            this.globalFavorites.forEach(function (fav) {
                fav.contentType = this.EVENT_TYPES.FAVORITES;
            }.bind(this));
            this._ogGlobalFavorites = cloneObject(this.globalFavorites);
            this.reloadRowsSelective(this.globalFavSectionIdx, 0, this.globalFavorites.length - 1);
        }

        setTableContent() {
            this.tableContent = [{
                footerTitle: _("media.zone.fav.desc")
            }, this._getZoneFavSection()];
            this.tableContent.pushObject(this._getMigrateSection());
            this.tableContent.pushObject(this._getGlobalFavSection());
            return super.setTableContent(...arguments);
        }

        reloadTable() {
            return super.reloadTable(...arguments).then(function () {
                return this._setEditingModeForSections();
            }.bind(this));
        }

        reloadRowsSelective(section, startIdx, nCells) {
            this.setTableContent();
            return super.reloadRowsSelective(...arguments).then(function () {
                return this._setEditingModeForSections();
            }.bind(this));
        }

        _setEditingModeForSections() {
            return Q.all([this.tableView.setEditingModeForSection(this.favSectionIdx, true, true, false), this.tableView.setEditingModeForSection(this.globalFavSectionIdx, true, true, false)]);
        }

        _getZoneFavSection() {
            return {
                rows: this.zoneFavorites.map(Controls.AudioZoneControl.MediaBrowserFavorites.getZoneFavoriteEditCellForFavAtIdx),
                didMoveCell: function didMoveCell(section, row, tableView, cell, oldIdx, newIdx) {
                    this.zoneFavorites.move(oldIdx, newIdx);
                }.bind(this)
            };
        }

        _getMigrateSection() {
            if (MediaServerComp.Feature.COPY_ZONE_FAVS) {
                return {
                    rows: [{
                        content: {
                            title: _("media.zone.fav.get-from"),
                            titleColor: window.Styles.colors.green,
                            clickable: true
                        },
                        action: function () {
                            return MediaServerComp.copyRoomFavsFrom(this.control, this.ViewController);
                        }.bind(this)
                    }, {
                        content: {
                            title: _("media.zone.fav.send-to", {
                                zoneName: this.control.name
                            }),
                            titleColor: window.Styles.colors.green,
                            clickable: true
                        },
                        action: function () {
                            return MediaServerComp.copyRoomFavsTo(this.control, this.ViewController);
                        }.bind(this)
                    }]
                };
            } else {
                return null;
            }
        }

        _getGlobalFavSection() {
            if (this.globalFavorites.length) {
                var zoneFavsFull = !!!this.zoneFavorites.filter(function (fav) {
                    return fav === null;
                }).length;
                return {
                    headerTitle: _("media.favorites.all"),
                    rows: this.globalFavorites.map(function (globalFav) {
                        var iconObj = Controls.AudioZoneControl.MediaBrowserFavorites.getIconObjForItem(globalFav),
                            texts = MediaServerComp.getTitleSubtitleForItem(globalFav),
                            leftIconColor = null;

                        if (!iconObj.hasProvidedCover) {
                            leftIconColor = iconObj.color;
                        }

                        return {
                            content: {
                                title: decodeURIComponent(texts.title),
                                leftIconBgEnabled: !iconObj.hasProvidedCover,
                                leftIconLarger: iconObj.hasProvidedCover,
                                leftIconSrc: iconObj.lowRes,
                                leftIconColor: leftIconColor,
                                buttonSrc: Icon.Buttons.MORE_HORIZONTAL,
                                removeButtonIconSrc: Icon.TRANSLUCENT_ADD,
                                class: "editable-base-cell__add",
                                disabled: zoneFavsFull
                            }
                        };
                    }.bind(this))
                };
            } else {
                return null;
            }
        }

        didRequestRemovingCell(section, row, tableView, cell) {
            if (section === 1) {
                var zoneFav = this.zoneFavorites[row];

                if (zoneFav) {
                    this.zoneFavorites[row] = null;
                    this.reloadRowsSelective(section, row, 1);
                } else {
                    MediaServerComp.selectNewZoneFavorite(row + 1, this.details, this.ViewController.ViewController);
                }
            } else if (section === this.globalFavSectionIdx) {
                var nextSlot = this._getFirstPossibleZoneFavPos(),
                    item = this.globalFavorites[row];

                if (nextSlot) {
                    this.zoneFavorites[nextSlot - 1] = item;
                    this.reloadRowsSelective(this.favSectionIdx, 0, MediaEnum.MAX_ZONE_FAVS);
                }
            }

            this.reloadRowsSelective(this.globalFavSectionIdx, 0, this.globalFavorites.length);
            return false;
        }

        _getFirstPossibleZoneFavPos() {
            return this.zoneFavorites.indexOf(null) + 1;
        }

        _sendFavoriteChanges() {
            if (JSON.stringify(this.zoneFavorites) !== JSON.stringify(this._ogZoneFavorites)) {
                var prms = this.zoneFavorites.map(function (fav, idx) {
                    var ogFav = this._ogZoneFavorites[idx],
                        cmd;

                    if (JSON.stringify(fav) !== JSON.stringify(ogFav)) {
                        if (fav) {
                            cmd = this._createSaveCommand(fav, idx + 1, fav.name);

                            if (cmd !== null) {
                                return MediaServerComp.sendMediaServerCommand(cmd);
                            }
                        } else if (ogFav) {
                            return MediaServerComp.deleteZoneFavorite(ogFav, idx, this.details, this.ViewController.ViewController);
                        }
                    }

                    return Q(true);
                }.bind(this));
                return NavigationComp.showWaitingFor(Q.all(prms));
            } else {
                return Q(true);
            }
        }

        _createSaveCommand(item, slot, name) {
            return MediaServerComp.getZoneFavoriteSaveCommand(slot, this.control.details.playerid, item, name, item.contentType, this.control);
        }

    }

    Controls.AudioZoneControl.AudioZoneControlFavoriteEdit = AudioZoneControlFavoriteEdit;
});
