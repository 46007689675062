'use strict';
/*
 title
 direction

 openCover(section, row, tableView)
 closeCover(section, row, tableView)
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.COVER = "CoverCell";

    class CoverCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(title) {
                return $('<div class="content__title">' + title + '</div>' + '<div class="content__right-container text-overflow-ellipsis">' + '<div class="right-container__button-touch-area button--close">' + ImageBox.getResourceImageWithClasses("resources/Images/Controls/Door/close.svg", "button-touch-area__icon") + '</div>' + '<div class="right-container__cover-placeholder">' + ImageBox.getResourceImageWithClasses("resources/Images/Controls/Pool/pool-cover.svg", "cover-placeholder__cover-icon") + ImageBox.getResourceImageWithClasses("resources/Images/Controls/Pool/pool-frame.svg", "cover-placeholder__frame-icon") + '</div>' + '<div class="right-container__button-touch-area button--open">' + ImageBox.getResourceImageWithClasses("resources/Images/Controls/Door/open.svg", "button-touch-area__icon") + '</div>' + '</div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("cover-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.contentPlaceholder.append(CoverCell.Template.getTemplate(this.content.title));
            this.coverElement = this.contentPlaceholder.find(".cover-placeholder__cover-icon");
            var left = this.contentPlaceholder.find(".button--close");
            var right = this.contentPlaceholder.find(".button--open");
            this.leftButton = new GUI.LxButton(this, left[0], window.Styles.colors.stateActive, null, true);
            this.rightButton = new GUI.LxButton(this, right[0], window.Styles.colors.stateActive, null, true);
            this.leftButton.useChildsAsActiveParts('fill');
            this.rightButton.useChildsAsActiveParts('fill');
            this.addToHandledSubviews(this.rightButton);
            this.addToHandledSubviews(this.leftButton);
            this.leftButton.onButtonHit = this._onLeftButtonHit;
            this.rightButton.onButtonHit = this._onRightButtonHit;

            if (!this.content.hasCoverPosition) {
                this.contentPlaceholder.find(".right-container__cover-placeholder").hide();
            }
        }

        viewDidAppear() {
            super.viewDidAppear();
            this.placeholderWidth = this.contentPlaceholder.find(".right-container__cover-placeholder").width();
            this.leftSpace = (this.placeholderWidth - this.coverElement.children()[0].getBoundingClientRect().width) / 2;
            this.setCoverProgressAndDirection(this.content.progress, this.content.direction);
        }

        // Public methods
        setCoverProgressAndDirection(progress, direction) {
            // We check if the PoolControl has a coverPosition, because we can only show the position, if we get one!
            if (this.content.hasCoverPosition) {
                var right = this.placeholderWidth * (1 - progress);
                var frameWidth = 2;
                right = map(right, [0, right], [0, right - this.leftSpace]);

                if (right < 0) {
                    right = 0;
                }

                this.coverElement.css("right", right + "px");
                this.coverElement.css("clip", "rect(" + 0 + "px, " + this.coverElement.width() + "px, " + this.coverElement.height() + "px, " + (frameWidth + right) + "px)");
            }

            this.leftButton.setActive(direction === Direction.DOWN);
            this.rightButton.setActive(direction === Direction.UP);
            this.content.progress = progress;
            this.content.direction = direction;
        }

        // Private methods
        _onLeftButtonHit() {
            this.delegate.closeCover.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
        }

        _onRightButtonHit() {
            this.delegate.openCover.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
        }

    }

    GUI.TableViewV2.Cells.CoverCell = CoverCell;
    return GUI;
}(window.GUI || {});
