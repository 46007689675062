'use strict';
/*
 title
 modes
 time
 active (boolean)
 [hideSwitch]
 [iconSrc]
 [nightLight]
 [isControlDisabled]            // optional tint color of the switch. Default: Green.

 // AlarmClockEntryCell delegate
 onSwitchChanged(value, section, row, tableView)
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.ALARM_CLOCK_ENTRY = "AlarmClockEntryCell";

    class AlarmClockEntryCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(content) {
                return $('<div class="content__top-container">' + (content.iconSrc ? '<div class="top-container__left-icon-placeholder">' + ImageBox.getResourceImageWithClasses(content.iconSrc, 'left-icon-placeholder__icon') + '</div>' : "") + '<div class="top-container__title">' + '<div class="title__text"></div>' + ImageBox.getResourceImageWithClasses('resources/Images/General/disclosure-icon.svg', 'title__disclosure-icon') + '</div>' + '<div class="content__time"></div>' + '<div class="content__switch-placeholder text-overflow-ellipsis" />' + '</div>' + '<div class="content-container__modes">' + '<div class="modes__entries entries--weekdays"></div>' + '<div class="modes__entries entries--specialDays"></div>' + '</div>' + (content.info ? '<div class="content-container__info-container">' + ImageBox.getResourceImageWithClasses(Icon.INFO, "info-container__info-icon") + '<div class="info-container__info-text">' + content.info + '</div>' + '</div>' : ""));
            };

            var getEntriesTag = function getEntriesTag(mode) {
                return $("<div class='entries-tag'>" + mode + "</div>");
            };

            return {
                getTemplate: getTemplate,
                getEntriesTag: getEntriesTag
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("alarm-clock-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();

            if (this.content.nightLight) {
                this.content.info = _('controls.alarmClock.default-alarm.title');
            } // Don't simply insert untrusted content in an template. set it later by using text/html.


            this.contentPlaceholder.append(AlarmClockEntryCell.Template.getTemplate(this.content)); // look for the labels

            this.elements = {
                name: this.contentPlaceholder.find(".title__text"),
                weekdayEntries: this.contentPlaceholder.find(".entries--weekdays"),
                specialEntries: this.contentPlaceholder.find(".entries--specialDays"),
                time: this.contentPlaceholder.find(".content__time")
            }; // update their texts

            this.elements.name.text(this.content.name);

            if (this.content.nightLight) {
                var mode = this.content.daily ? _("daily") : _("one-time");
                var moodEntry = AlarmClockEntryCell.Template.getEntriesTag(mode);
                moodEntry.toggleClass("active", this.content.isActive && !this.content.isControlDisabled);
                this.elements.weekdayEntries.append(moodEntry);
            } else {
                var modes = SandboxComponent.getStructureManager().getOperatingModes(true);
                this.content.modes.forEach(function (modeKey) {
                    var mode = modes[modeKey];

                    if (modeKey >= 3 && modeKey <= 9) {
                        var weekDayEntry = AlarmClockEntryCell.Template.getEntriesTag(shortenOperatingMode(modeKey));
                        weekDayEntry.toggleClass("active", this.content.isActive && !this.content.isControlDisabled);
                        this.elements.weekdayEntries.append(weekDayEntry);
                    } else {
                        var specialDayEntry = AlarmClockEntryCell.Template.getEntriesTag(mode);
                        specialDayEntry.toggleClass("active", this.content.isActive && !this.content.isControlDisabled);
                        this.elements.specialEntries.append(specialDayEntry);
                    }
                }.bind(this));
            } // AlarmTime can be 0 -> Alarm is set to 00:00. So we need to explicitly check if it is not undefined


            if (this.content.alarmTime !== undefined) {
                this.elements.time.text(LxDate.formatTimeFromSecondsToLocal(this.content.alarmTime, true));
            }

            if (!this.content.hideSwitch) {
                var switchColor = Color.ACTIVE;

                if (this.content.isControlDisabled) {
                    switchColor = window.Styles.colors.red;
                }
                this.switch = new GUI.LxSwitch(this, this.contentPlaceholder.find(".content__switch-placeholder")[0], switchColor, false, null,this.content.isActive);
            }
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments);

            if (this.switch) {
                this.disableHammerReactionOnSwitch();
                this.setActive(this.content.isActive);
                this.switch.onStateChanged = this._onSwitchChanged.bind(this);
            }
        }

        disableHammerReactionOnSwitch() {
            $(this.switch.getElement()).find("*").each((idx, elem) => elem.disableHammer = true);
        }

        viewWillDisappear() {
            this.switch && (this.switch.onStateChanged = null);
            super.viewWillDisappear(...arguments);
        }

        destroy() {
            this.switch && this.switch.destroy();
            super.destroy();
        }

        clickableAllowed() {
            return true;
        }

        // Public Methods
        setActive(a) {
            this.switch && this.switch.setActive(a);
        }

        // Private Methods
        _onSwitchChanged(a) {
            this.delegate.onSwitchChanged.call(this.delegate, a, this.sectionIdx, this.rowIdx, this.tableView);
        }

    }

    GUI.TableViewV2.Cells.AlarmClockEntryCell = AlarmClockEntryCell;
    return GUI;
}(window.GUI || {});
