'use strict';

define("IRCV2DaytimerControl", ["DaytimerControlBase", "IRoomControllerV2ControlEnums"], function (DaytimerControlBase, IRoomControllerV2ControlEnums) {
    return class IRCV2DaytimerControl extends DaytimerControlBase {
        constructor() {
            super(...arguments);
        }

        /**
         * Returns the string for the value of the entry that will be appended to the set entries command.
         * @param entry     the entry whose value is to be returned as a string for the setEntries-Cmd.
         * @return {null}   null only e.g. for a digital regular daytimer, as there is no value for it.
         */
        getEntryValueString(entry) {
            // Important to cast it to a string, otherwise 0 = eco mode would not be sent properly and one the comfort temp is chosen.
            return "" + entry.tempMode;
        }

        buildEntriesCommand(entries) {
            var command = this.getSetEntriesCommand(),
                entriesString = "",
                entriesCounter = 0,
                entry,
                valStr;

            for (var modeNr in entries) {
                if (entries.hasOwnProperty(modeNr)) {
                    for (var i = 0; i < entries[modeNr].length; i++) {
                        entry = entries[modeNr][i];

                        if (entry.to === entry.from) {
                            // Remove all entries with 0 seconds, which may be added by an app bug
                            continue;
                        } // Its not necessary to send the eco mode entries


                        if (entry.tempMode === IRoomControllerV2ControlEnums.Mode.ECO) {
                            continue;
                        }

                        entriesCounter++;
                        valStr = this.getEntryValueString(entry);
                        entriesString += "/" + modeNr + ";" + entry.from + ";" + entry.to + ";" + valStr; // Since the rework from 2021 (v12.1) the IRC supports entries that need to be activated

                        if (Feature.IRC_V2021) {
                            entriesString += ";" + (entry.needActivate ? 1 : 0);
                        }
                    }
                }
            }

            return Commands.format(command, entriesCounter, entriesString);
        }

        getParentControl() {
            return SandboxComponent.getStructureManager().getControlByUUID(this.uuidParent);
        }

        /**
         * Returns a description for the given entry modeNr and valueString
         * e.g: Montag: 00:00 - 00:00 -> Anwesend
         * @param entry
         * @param modeNr
         * @param valueStr
         * @return {string}
         * @private
         */
        _getDebugDescriptionForEntry(entry, modeNr, valueStr) {
            var now = moment().startOf("day"),
                opModes = SandboxComponent.getStructureManager().getOperatingModes(true);
            return opModes[modeNr] + ": " + now.clone().add(entry.from, "minutes").format("LT") + " - " + now.clone().add(entry.to, "minutes").format("LT") + " -> " + this.getParentControl().getNameOfIRCTempMode(parseInt(valueStr));
        }

    };
});
