'use strict';

define(['SequenceSelectorPage', 'ColorSelectorPage', 'ColorPickerV2ControlEnums'], function (SequenceSelectorPage, ColorSelectorPage, ColorPickerV2ControlEnums) {
    return class ColorPickerV2ControlContent extends Controls.ControlContent {
        constructor() {
            super(...arguments);
            this.selectedMode = false;
        }

        viewDidLoad() {
            let prmsArr = [];

            return Q(super.viewDidLoad(...arguments)).then(() => {
                this.colorView = new ColorSelectorPage(this.control);
                this.sequenceView = new SequenceSelectorPage(this.control);
                this.addToHandledSubviews(this.colorView);
                this.addToHandledSubviews(this.sequenceView); // these views should use the control contents sendCommmand.

                this.colorView.sendCommand = this.sendCommand.bind(this);
                this.sequenceView.sendCommand = this.sendCommand.bind(this);
                this.segmentedControl = new GUI.LxSegmentedControl([{
                    text: _("controls.colorpickerV2.color")
                }, {
                    text: _("controls.colorpickerV2.sequence")
                }]);
                this.segmentedControl.getElement().addClass("color-pickerv2-control-content__segment-control");
                this.pageView = new GUI.LxPageView([this.colorView.getElement(), this.sequenceView.getElement()], false, this._doneAnimating.bind(this), {
                    noScroll: true
                });
                this.pageView.disableUserInteraction(true);
                prmsArr.push(this.appendSubview(this.segmentedControl));
                prmsArr.push(this.appendSubview(this.pageView));

                return Q.all(prmsArr);
            });

        }

        titleBarText() {
            return this.control.name;
        }

        viewDidAppear() {
            var baseCall = super.viewDidAppear(...arguments);
            this.segmentChangedReg = this.segmentedControl.on("on-segment-selected", this._segmentChanged.bind(this));
            return baseCall;
        }

        viewWillDisappear() {
            this.segmentChangedReg(); // unregister from segment changes

            return super.viewWillDisappear(...arguments);
        }

        receivedStates(states) {
            // initially navigate to the desired screen.
            if (this.selectedMode === false) {
                var navPage = ColorPickerV2ControlEnums.PickerMode.SEQUENCE === states.selectedMode ? 1 : 0;
                this.pageView.setActivePage(navPage);
            }

            this.selectedMode = states.selectedMode;
            this.segmentedControl && this.segmentedControl.setDisabled(this.control.tuneAbleWhiteOnly());
        }

        _sendColorObject(colorObj, brightness) {
            var cmd;

            if (colorObj.type === ColorPickerV2ControlEnums.PickerMode.TEMPERATURE) {
                cmd = Commands.format(Commands.COLOR_PICKER_V2.TEMP, brightness, colorObj.kelvin);
            } else if (colorObj.type === ColorPickerV2ControlEnums.PickerMode.HSV) {
                cmd = Commands.format(Commands.COLOR_PICKER_V2.HSV, colorObj.hue, colorObj.sat, brightness);
            }

            this.sendCommand(cmd, Commands.Type.OVERRIDE);
        }

        _segmentChanged(ev, idx) {
            Debug.Control.ColorPickerV2 && console.log(this.name, "_segmentChanged: " + idx);
            this.pageView.setActivePage(idx);
        }

        _doneAnimating(pageIndex) {
            Debug.Control.ColorPickerV2 && console.log(this.name, "_doneAnimating: " + pageIndex);
            this.segmentedControl.setActiveSegment(pageIndex);
        }

    };
});
