

export default class NodeValueRequestHandler {
    constructor(control) {
        this._control = control;
        this.name = "NodeValueRequest@" + control.getName();
        this._launchList = false;
    }

    requestValue(nodeUuid) {
        Debug.Control.WallboxManager && console.log(this.name, "requestValue of " + nodeUuid);
        return this._addNodeUuidToLaunch(nodeUuid).then((jsonResponse) => {
            // returns a json object where each requested uuid is the key that maps a JSON object with the requested
            // values to it, eg.: { "nodeUUid1": { "total": 1, "totalNeg": 2}, "nodeUUid2": { "total": 3 } }
            if (jsonResponse.hasOwnProperty(nodeUuid)) {
                Debug.Control.WallboxManager && console.log(this.name, "requestCurrentNodeValue of " + nodeUuid + " > response: ", jsonResponse[nodeUuid]);
                return jsonResponse[nodeUuid];
            } else {
                console.error(this.name, "requestCurrentNodeValue of " + nodeUuid + " > MISSING VALUE in response: " + JSON.stringify(jsonResponse));
                return { err: "Missing in response" };
            }
        });
    }

    _addNodeUuidToLaunch(nodeUuid) {
        Debug.Control.WallboxManager && console.log(this.name + "-LaunchControl", "_addNodeUuidToLaunch " + nodeUuid);
        if (!this._launchList) {
            this._launchList = this._scheduleLaunch();
        }
        this._launchList.nodeMap[nodeUuid] = true;
        return this._launchList.def.promise;
    }

    _scheduleLaunch() {
        Debug.Control.WallboxManager && console.log(this.name + "-LaunchControl", "_scheduleLaunch");
        setTimeout(this._launchRequest.bind(this), 50);
        return { def: Q.defer(), nodeMap: {} };
    }

    _launchRequest() {
        let launchInfos = this._launchList,
            nodeUuids = Object.keys(launchInfos.nodeMap);

        // reset launchlist Entry
        this._launchList = false;

        Debug.Control.WallboxManager && console.log(this.name + "-LaunchControl", "_launchRequest, uuids=" + nodeUuids.join(","));

        // fire
        let cmd = Commands.format(Commands.EnergyFlowMonitor.GET_NODE_VALUE, nodeUuids.join(","))
        this._sendCommand(cmd).then((res) => {
            Debug.Control.WallboxManager && console.log(this.name + "-LaunchControl", "_launchRequest " + cmd + " > response: " , res);
            launchInfos.def.resolve(this._parseGetNodeValueResponse(res));
        }, (err) => {
            console.error(this.name + "-LaunchControl", "_launchRequest > fail: ", err);
            launchInfos.def.reject(err);
        });
    }

    _parseGetNodeValueResponse(plainRes) {
        let jsonRes,
            txtValue;
        try {
            txtValue = getLxResponseValue(plainRes, true);

            // miniserver may invalidly separate entries with two commas.
            txtValue = txtValue.replaceAll(",,", ",");

            jsonRes = JSON.parse(txtValue);
        } catch (ex) {
            console.error(this.name, "_parseGetNodeValueResponse failed! " + plainRes, ex);
            jsonRes = {};
        }
        return jsonRes;
    }

    _sendCommand(cmd) {
        return this._control.sendCommand(cmd, null, null, true, null, null, true);
    }


}