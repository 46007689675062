import {View, ScrollView} from "react-native";
import {useCallback, useMemo, useState} from "react";
import globalStyles from "GlobalStyles";
import {LxReactText, LxReactContextMenuHandler, LxReactPressable} from "LxComponents";
import Icons from "IconLib"
import {useNavigation} from "@react-navigation/native";

export default function AppSetupOptions({}) {
    const navigation = useNavigation();

    const addMsOptions = useMemo(() => {
        var options = [],
            canSearch = PlatformComponent.getNetworkStatus().status === NetworkStatus.LAN &&
                MiniserverFinder.hasMSFinder();

        canSearch && options.push({
            title: _("miniserverlist.add.search"),
            action: () => {
                return showMiniserverSearchScreen();
            }
        });
        options.push({
            title: _("miniserverlist.add.manual"),
            action: () => {
                return showEnterUrlScreen();
            }
        });
        return options;
    }, [PlatformComponent.getNetworkStatus()]);


    const showAddMiniserver = useCallback(() => {
        if (addMsOptions.length === 1) {
            return addMsOptions[0].action();
        }
        return LxReactContextMenuHandler.shared.showContextMenu(addMsOptions, "");
    }, [addMsOptions]);



    const connectToDemoMiniserver = useCallback(() => {
        return NavigationComp.connectToDemoMiniserver();
    }, []);

    const showEnterUrlScreen =  useCallback(() => {
        return NavigationComp.showEnterUrlScreen();
    }, []);

    const showMiniserverSearchScreen =  useCallback(() => {
        return NavigationComp.showMiniserverSearchScreen();
    }, []);

    const pairAppFn = useCallback(() => {
        navigation.navigate("AppPairingScreen");
    }, []);


    const options = useMemo(() => {
        const pairable = PairedAppComponent.isPairableDevice(),
            options = [];

        options.push({
            title: _("miniserverlist.add.to"),
            BtnIcon: Icons.MiniserverLined,
            iconStyle: {fill: globalStyles.colors.stateActive},
            action: showAddMiniserver
        });
        pairable && options.push({
            title: _("managed-tablet.set-up-managed-tab"),
            BtnIcon: Icons.Tablet,
            action: pairAppFn
        });
        options.push({
            title: _("setup-options.demo"),
            BtnIcon: Icons.HouseLined,
            action: connectToDemoMiniserver
        });
        return options;
    }, []);

    const [renderInfo, setRenderInfo] = useState({vertical: false, ready: false});
    const onLayoutCallback = useCallback(({nativeEvent: {layout: {width, height}}}) => {
        const optionWidth = (OptionSize + globalStyles.spacings.gaps.regular * 2.2); // 0.2 to add some offset
        const rqWidth = optionWidth * options.length;
        if (rqWidth >= width) {
            setRenderInfo({vertical: true, ready: true});
        } else {
            setRenderInfo({vertical: false, ready: true});
        }
    }, [options]);

    return <View style={styles.rootContainer}
                 onLayout={onLayoutCallback}>
        {
            renderInfo.ready ? <OptionCardList options={options} vertical={renderInfo.vertical}/> : null
        }
    </View>
}

export function OptionCardList({options, vertical = false}) {

    const Options = ({options}) => {
        return <> {
            options.map(option => {
                return <OptionCard {...option} />
            })
        } </>
    }
    if (vertical) {
        return <ScrollView
            horizontal={false}
            vertical={vertical}
            centerContent={true}
            contentContainerStyle={[styles.optionList, { flexDirection: "column" }]}
            key={"optcntr"}>
            <Options options={options} />
        </ScrollView>
    } else {
        return <View
            style={styles.optionList}
            key={"optcntr"}>
            <Options options={options} />
        </View>
    }


}

export function OptionCard({title, BtnIcon, action, iconStyle = {}}) {
    return <LxReactPressable pkey={title} key={title} onPress={action} pressableStyle={styles.option}>
        <View style={styles.optionIconCntr} key={"opticon"}>
            <BtnIcon style={[styles.optionIcon, iconStyle]} />
        </View>
        <LxReactText key={"opttitle" } style={styles.optionText}>{title}</LxReactText>
    </LxReactPressable>
}

const OptionSize = 200;

const styles = {
    rootContainer: {
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
    },
    optionList: {
        flexDirection: "row",
        alignSelf: "center",
        justifySelf: "center"
    },
    option: {
        width: OptionSize,
        flexDirection: "column",
        height: "auto",
        margin: globalStyles.spacings.gaps.regular
    },
    optionIconCntr: {
        width: OptionSize,
        height: OptionSize,
        justifyContent: "center",
        alignItems: "center",
        background: "#2525258C"
    },
    optionIcon: {
        width: 72,
        height: 72,
        fill: globalStyles.colors.text.secondary
    },
    optionText: {
        textAlign: "center",
        maxWidth: OptionSize,
        marginTop: globalStyles.spacings.gaps.regular,
        ...globalStyles.textStyles.body.regular,
        color: globalStyles.colors.text.secondary
    }
}