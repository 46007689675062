import {useCCEvent} from "../Components";
import {useCallback, useState} from "react";


export default function usePairedIdentify(initial = {}) {
    const [identifyInfos, setIdentifyInfos] = useState({active: false, ...initial});


    useCCEvent([CCEvent.PairedIdentify], useCallback((event, data) => {
        setIdentifyInfos(data);
    }, []))

    return identifyInfos;
}