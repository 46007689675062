'use strict';

define("FroniusControl", ["Control", "FroniusControlEnums"], function (Control, FroniusControlEnums) {
    return class FroniusControl extends Control {
        constructor() {
            super(...arguments);
        }

        getIcon() {
            return Icon.EnergyMonitor.ENERGY_MONITOR;
        }

        getFriendlyValueName(event, operator) {
            var value = parseFloat((event.value + "").replace(",", ".")); // value may be a string "1,1"

            switch (event.data.stateName) {
                case "prodCurr":
                    return this.name + " " + event.data.stateTitle + " " + operator + " " + lxUnitConverter.convertAndApply(FroniusControlEnums.VALUE_FORMAT, value);

                case "consCurr":
                    return this.name + " " + event.data.stateTitle + " " + operator + " " + lxUnitConverter.convertAndApply(FroniusControlEnums.VALUE_FORMAT, value);

                default:
                    return "";
            }
        }

    };
});
