'use strict';

define([], function () {
    class AutomaticDesignerSearchViewController extends GUI.ModalViewController {
        constructor(details) {
            super();
            Object.assign(this, ContextMenuHandler.Mixin);
            this.details = details;
        }

        viewWillAppear() {
            var showStatePrms,
                args = arguments;

            if (!this.didAlreadyShowInitialState) {
                showStatePrms = this.showState(ScreenState.AutomaticDesigner.SearchScreen, null, this.details, AnimationType.NONE);
            } else {
                showStatePrms = Q(true);
            }

            return showStatePrms.then(() => {
                this.didAlreadyShowInitialState = true;
                return super.viewWillAppear(...args);
            });
        }

        destroyOnBackNavigation() {
            return true;
        }

    }

    GUI.AutomaticDesignerSearchViewController = AutomaticDesignerSearchViewController;
    return GUI.AutomaticDesignerSearchViewController;
});
