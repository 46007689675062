'use strict';

define([], function () {
    return {
        GeneratorType: {
            Fronius: 0,
            Inputs: 1,
            Kostal: 2
        },
        VALUE_FORMAT: "%.2f kW",
        ENERGY_FORMAT: "%.2f kWh",
        BATTERY_FORMAT: "%.0f%%",
        ScreenState: {
            OVERVIEW: 'Controls.FroniusControl.FroniusControlContentOverview'
        },
        StatisticOutput: {
            PROD_POWER: 0,
            // power production
            CONS_POWER: 1,
            // power consumption
            GRID_POWER: 2,
            // power grid
            BATT_POWER: 3,
            // power of the battery
            BATT_CHARGE: 4,
            // charge of the battery
            PROD_ENERGY: 5,
            // energy production
            CONS_ENERGY: 6,
            // energy total consumption in house
            GRID_DELIVERY_ENERGY: 7,
            // energy grid delivery (export)
            GRID_CONS_ENERGY: 8,
            // energy grid consumption (import)
            // Indizes prior to the 9 output-statistic feature.
            PROD_ENERGY_OLD: 4,
            // energy production
            CONS_ENERGY_OLD: 5 // energy total consumption in house

        },
        NodeType: {
            Production: 'production',
            External: 'external',
            Consumption: 'consumption'
        },
        Status: {
            STANDBY: 8
        }
    };
});
