import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Minus(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path
                fillRule="evenodd"
                d="M2 12a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1z"
                clipRule="evenodd"
            />
        </Svg>
    )
}

export default Minus
