import { SwimmingMachineType } from "./poolControlEnums.js"
import {createStateCell} from "../../UtilityComp/utilities/helper";

export default class PoolControlStatesSrc {
    static getStateSections (control, states) {
        let sections = [],
            section = {},
            rows = [],
            row;

        if (states.error || states.serviceModeActive) {
            return rows;
        }

        row = this._getStateCellForSwimmingMachine(control, states);
        row && rows.push(row);

        if (control.details.hasCover) {
            rows.push(createStateCell(_("pool.control.cover"), states.coverPosText));
        }

        if (!states.error && control.details.hasActualTemp) {
            rows.push(createStateCell(_("pool.control.actual-water-temp"), lxFormat("%.1f°", states.actualTemp)));
        }

        if (!states.error && control.details.hasTargetTemp) {
            rows.push(createStateCell(_("pool.control.target-water-temp"), lxFormat("%.1f°", states.targetTemp)));
        }

        if (!states.error && control.details.hasWaterLevel) {
            rows.push(createStateCell(_("pool.control.water-level"), lxFormat(control.details.waterLevelUnit, states.waterLevel)));
        }

        if (!states.error && control.details.hasCustom1) {
            rows.push(createStateCell(control.details.customName1, lxFormat(control.details.customUnit1, states.custom1)));
        }

        if (!states.error && control.details.hasCustom2) {
            rows.push(createStateCell(control.details.customName2, lxFormat(control.details.customUnit2, states.custom2)));
        }

        section.rows = rows;
        sections.push(section)

        return sections;
    }

    static _getStateCellForSwimmingMachine(control, states) {
        let cell;

        switch (control.details.swimmingMachineType) {
            case SwimmingMachineType.DIGITAL:
                cell = createStateCell(_("pool.control.swimming-machine"), states.swimmingMachine ? _("on") : _("off"));
                break;

            case SwimmingMachineType.ANALOG:
                cell = createStateCell(_("pool.control.swimmingmachine.intensity"), states.swimMachineAnalogText);
                break;

            default:
                break;
        }

        return cell;
    }
}