'use strict';

define(['ControlActionCellsScreen', 'IRoomControllerV2ControlEnums'], function (ControlActionCellsScreen, IRoomControllerV2ControlEnums) {
    return class IRoomControllerV2ControlContent extends ControlActionCellsScreen {
        constructor() {
            super(...arguments);
        }

        shouldDisplayStateIcons() {
            return false;
        }

        getActionSection(states) {
            var section = super.getActionSection(...arguments) || {
                    rows: []
                },
                temp = this.control.getTempStringOfMode(states.activeMode, true),
                mainStatusCell = this._getMainStatusCell(states);

            if (states.operatingMode < IRoomControllerV2ControlEnums.OperatingMode.MANUAL.HEATING_AND_COOLING || states.operatingMode >= IRoomControllerV2ControlEnums.OperatingMode.MANUAL.HEATING_AND_COOLING && states.hasCurrentOverrideEntry) {
                this._addAutomaticContent(states, section);
            }

            mainStatusCell && section.rows.splice(0, 0, mainStatusCell);
            return section;
        }

        getAdditionalSection(states) {
            var section = super.getAdditionalSection(...arguments) || {
                    rows: []
                },
                settingsCell = this._getSettingsCell(states);

            settingsCell && section.rows.push(settingsCell);
            return section;
        }

        _getSettingsCell(states) {
            return {
                content: {
                    title: _("settings"),
                    clickable: true,
                    rightIconSrc: Icon.DISCLOSURE
                },
                action: this._showSettingsScreen.bind(this)
            };
        }

        _addAutomaticContent(states, section) {
            section.rows.splice(0, 0, {
                content: {
                    control: this.control,
                    parentControl: this.control.subControls[Object.keys(this.control.subControls)[0]]
                },
                type: GUI.TableViewV2.CellType.IRCV2TimeLineCell,
                action: this._showTimesScreen.bind(this)
            });
        }

        _getMainStatusCell(states) {
            var temp = this.control.getTempStringOfMode(states.activeMode, true);
            return {
                content: {
                    color: this.control.getColorOfIRCTempMode(states.activeMode),
                    title: this._getTemperatureCellTitle(states),
                    detail: {
                        text: temp
                    },
                    prominent: {
                        title: states.formattedTempActual,
                        actions: this._getTemperatureCellActions(states),
                        state: this._getModeStateObject(states)
                    },
                    info: {
                        text: this._getInfoText(states)
                    }
                },
                type: GUI.TableViewV2.CellType.IRCV2TemperatureCell,
                onTemperatureScreenOpen: this._showTemperaturesScreen.bind(this),
                action: function openStatistics() {
                    this.control.statistic && this.showStatisticForOutput(0);
                }.bind(this)
            };
        }

        _getTemperatureCellActions(states) {
            var buttons = [];
            buttons.pushObject(this.control.getButton0(states));
            buttons.pushObject(this.control.getButton1(states));
            return buttons.map(function (button) {
                return {
                    icon: button.iconSrc,
                    color: Color.STATE_INACTIVE,
                    action: function () {
                        if (typeof button.command === "object") {
                            button.command.tap && button.command.tap();
                        } else if (typeof button.command === "function") {
                            button.command();
                        } else if (typeof button.command === "string") {
                            this.control.sendCommand(button.command);
                        }
                    }.bind(this)
                };
            }.bind(this));
        }

        _getTemperatureCellTitle(states) {
            if (states.activeMode === IRoomControllerV2ControlEnums.Mode.ECO_PLUS) {
                return _("off");
            } else {
                return this.control.getTempModeDesc(null, true);
            }
        }

        _getInfoText(states) {
            var name = this.control.getNameOfActiveMode(true),
                infoText,
                overrideEntry = states.overrideEntries[0],
                currentColor = this.control.getColorOfIRCTempMode(states.activeMode); // Lets get the info text for the current mode

            switch (states.activeMode) {
                case IRoomControllerV2ControlEnums.Mode.COMFORT:
                    if (states.comfortTemperatureOffset !== 0) {
                        infoText = _("controls.ircv2.comfort-adoption.temp", {
                            temp: "<span style='color: " + currentColor + "'>" + states.comfortTemperatureOffset.addSign(1) + "°</span>"
                        });
                    } else {
                        infoText = _("controls.ircv2.comfort-active");
                    }

                    break;

                case IRoomControllerV2ControlEnums.Mode.ECO:
                    infoText = _("controls.ircv2.eco-active");
                    break;

                case IRoomControllerV2ControlEnums.Mode.ECO_PLUS:
                    infoText = _("controls.ircv2.off-active");
                    break;

                default:
                    // name will be "Unknown" if the mode is not known to the app, eg. due to an update
                    infoText = _('controls.ircv2.mode.active', {
                        mode: name
                    });
            } // Check if there is an active override entry that has not been triggered by the control block inputs (IC, IA or IO)
            // Alter the infoText according to the active override entry


            if (overrideEntry && !this._isControlBlockInputOverride(overrideEntry)) {
                if (overrideEntry.reason === IRoomControllerV2ControlEnums.Reason.PREPARE_STATE_HEAT_UP || overrideEntry.reason === IRoomControllerV2ControlEnums.Reason.PREPARE_STATE_COOL_DOWN) {
                    infoText = this._getPrepareStateInfoText(overrideEntry);
                } else if (overrideEntry.end) {
                    infoText = this._getTimerInfoText(overrideEntry, infoText);
                }
            }

            return infoText;
        }

        /**
         * Returns true if the overrideEntry is caused by a manually trigger of Ic, Ia or Io
         * @param overrideEntry
         * @return {boolean}
         * @private
         */
        _isControlBlockInputOverride(overrideEntry) {
            return this._isModeOverrideEntry(overrideEntry) && !overrideEntry.end;
        }

        _isModeOverrideEntry(overrideEntry) {
            return overrideEntry.reason === IRoomControllerV2ControlEnums.Reason.COMFORT_OVERRIDE && overrideEntry.reason === IRoomControllerV2ControlEnums.Reason.ECO_OVERRIDE && overrideEntry.reason === IRoomControllerV2ControlEnums.Reason.ECO_PLUS_OVERRIDE;
        }

        _showTimesScreen() {
            this.ViewController.showState(IRoomControllerV2ControlEnums.ScreenState.TIMES, null, {
                control: this.control.subControls[Object.keys(this.control.subControls)[0]]
            });
        }

        _showTemperaturesScreen() {
            this.ViewController.showState(IRoomControllerV2ControlEnums.ScreenState.TEMPERATURES, null, {
                control: this.control
            });
        }

        _showSettingsScreen() {
            this.showScreen(IRoomControllerV2ControlEnums.ScreenState.SETTINGS, {
                control: this.control
            });
        }

        _getPrepareStateInfoText(overrideEntry) {
            if (overrideEntry.reason === IRoomControllerV2ControlEnums.Reason.PREPARE_STATE_HEAT_UP) {
                return _("controls.ircv2.heat-up-phase-active");
            } else {
                return _("controls.ircv2.cool-down-phase-active");
            }
        }

        _getTimerInfoText(overrideEntry, name) {
            var format = moment().diff(overrideEntry.stopDate, "days") ? DateType.DateTextAndTimeNoYear : DateType.TimeFormat;
            return _('controls.ircv2.mode.active.until', {
                mode: name,
                time: overrideEntry.stopDate.format(format)
            });
        }

        // --------------------------------------------------------------------------------
        //                  Heating or cooling indication
        // --------------------------------------------------------------------------------

        /**
         * Returns the state object with icon, color and actionFn shown on the left side of
         * the temperature, which indicates whether the IRC is heating or cooling.
         * @param states
         * @returns {{color: string, iconSrc: string, actionFn: *}}
         * @private
         */
        _getModeStateObject(states) {
            var obj = {};

            if (states.hasOwnProperty("currentMode") && this._getIsHeating(states) || this._getIsCooling(states)) {
                obj.iconSrc = Icon.IRCV2.HEAT_COOL_STATE;
                obj.actionFn = this._showCurrentlyHeatingCoolingPopup.bind(this);
                obj.color = this._getHeatingCoolingColor(states);
            }

            return obj;
        }

        /**
         * Shows a popup informing the user if the IRC is currently heating or cooling. Opened when
         * the user taps the state icon shown on the left side of the IRC current temp.
         * @private
         */
        _showCurrentlyHeatingCoolingPopup() {
            var title,
                message,
                states = this.control.getStates();

            if (this._getIsAutomatic(states)) {
                title = _("controls.ircv2.op-mode.automatic-is-active");
            } else {
                title = _("controls.ircv2.op-mode.manual-is-active");
            }

            if (this._getIsHeating(states)) {
                message = _("controls.ircv2.op-mode.heating-is-active");
            } else if (this._getIsCooling(states)) {
                message = _("controls.ircv2.op-mode.cooling-is-active");
            }

            NavigationComp.showPopup({
                title: title,
                message: message,
                buttonOk: true,
                buttonCancel: false
            });
        }

        _getHeatingCoolingColor(states) {
            var color;

            if (this._getIsHeating(states)) {
                color = window.Styles.colors.red;
            } else if (this._getIsCooling(states)) {
                color = window.Styles.colors.blue;
            }

            return color;
        }

        _getIsHeating(states) {
            var isHeating = false; // contrary to the operatingMode, the currentMode will always be in onlyHeating or onlyCooling

            switch (states.currentMode) {
                case IRoomControllerV2ControlEnums.OperatingMode.AUTOMATIC.ONLY_HEATING:
                case IRoomControllerV2ControlEnums.OperatingMode.MANUAL.ONLY_HEATING:
                    isHeating = true;
                    break;

                default:
                    break;
            }

            return isHeating;
        }

        _getIsCooling(states) {
            var isCooling = false; // contrary to the operatingMode, the currentMode will always be in onlyHeating or onlyCooling

            switch (states.currentMode) {
                case IRoomControllerV2ControlEnums.OperatingMode.AUTOMATIC.ONLY_COOLING:
                case IRoomControllerV2ControlEnums.OperatingMode.MANUAL.ONLY_COOLING:
                    isCooling = true;
                    break;

                default:
                    break;
            }

            return isCooling;
        }

        _getIsAutomatic(states) {
            var isAutomatic = false;

            switch (states.operatingMode) {
                case IRoomControllerV2ControlEnums.OperatingMode.AUTOMATIC.ONLY_COOLING:
                case IRoomControllerV2ControlEnums.OperatingMode.AUTOMATIC.ONLY_HEATING:
                case IRoomControllerV2ControlEnums.OperatingMode.AUTOMATIC.HEATING_AND_COOLING:
                    isAutomatic = true;
                    break;

                default:
                    break;
            }

            return isAutomatic;
        }

    };
});
