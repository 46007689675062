'use strict';

define([], function () {
    return class ControlLockingScreen extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($("<div/>"));
            this.control = details.control;
            this._lockReason = "";
        }

        viewDidLoad() {
            var res = super.viewDidLoad(...arguments);
            this.setUpComfortUI(true);
            this.setIcon(Icon.LOCK);
            this.setIconColor();
            this.setTitle(_("control.lock.lock-title"));
            this.setSubtitle(_("control.lock.lock-description"));
            return res;
        }

        getURL() {
            return "control/" + this.control.uuidAction + "/locking";
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        styleForTableView() {
            return TableViewStyle.TRANSLUCENT;
        }

        setTableContent() {
            this.tableContent = [];
            var inputSection = {},
                inputContent = {},
                submitSection = {}; // input

            inputSection.sectionHeader = _("note") + " (" + _("optional") + ")";
            inputSection.rows = [];
            inputContent[GUI.LxInputEnum.MULTI_LINE] = true;
            inputContent[GUI.LxInputEnum.PLACEHOLDER] = _("control.lock.note-explanation");
            inputContent[GUI.LxInputEnum.VALUE] = this._lockReason;
            inputSection.rows.push({
                type: GUI.TableViewV2.CellType.INPUT,
                content: inputContent,
                textChanged: this._reasonChanged.bind(this)
            });
            this.tableContent.push(inputSection); // lock control button

            submitSection.rows = [];
            submitSection.rows.push({
                type: GUI.TableViewV2.CellType.DELETE,
                content: {
                    title: _("control.lock.lock-title")
                },
                action: this._handleLockControl.bind(this)
            });
            this.tableContent.push(submitSection);
            return super.setTableContent(...arguments);
        }

        _reasonChanged(section, row, tableView, value) {
            this._lockReason = value;
        }

        _handleLockControl() {
            var command = Commands.format(Commands.CONTROL.LOCK_CTRL, true, encodeURIComponent(this._lockReason));
            NavigationComp.showWaitingFor(this.control.sendCommand(command)).then(function (resp) {
                if (getLxResponseCode(resp) === ResponseCode.OK) {
                    this.closeAction();
                } else {
                    this._handleLockError(resp);
                }
            }.bind(this), this._handleLockError.bind(this));
        }

        _handleLockError(err) {
            var code = 0,
                value = "";

            try {
                code = getLxResponseCode(err);
                value = getLxResponseValue(err);
            } catch (ex) {
            }

            console.error(this.name, "Failed to locked control " + this.control.name + ": " + JSON.stringify(err));
            console.error(this.name, "    code: " + code);
            console.error(this.name, "   value: " + value);
            NavigationComp.showErrorPopup(false, _("control.lock.error"));
        }

    };
});
