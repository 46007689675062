import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Automatic(props) {
  return (
    <Svg isreact="true"
      width={22}
      height={22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.48 0a1 1 0 01.905.535l10.5 20a1 1 0 11-1.77.93L16.458 14.5H5.195l-3.292 6.93a1 1 0 01-1.806-.86l9.5-20A1 1 0 0110.48 0zM6.145 12.5h9.263l-4.863-9.263-4.4 9.263z"
      />
    </Svg>
  )
}

export default Automatic
