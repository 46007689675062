'use strict';

define("DimmerControl", ["Control"], function (Control) {
    return class DimmerControl extends Control {
        constructor() {
            super(...arguments);
        }

        getSwitch(states) {
            return {
                active: states.isOn,
                command0: Commands.DIMMER.OFF,
                command1: Commands.DIMMER.ON
            };
        }

        specificCreateCmdText(cmdParts) {
            var text = null,
                cfg = {
                    name: this.name
                };

            if (cmdParts[1] === Commands.DIMMER.ON) {
                text = _("cmdtext.on", cfg);
            } else if (cmdParts[1] === Commands.DIMMER.OFF) {
                text = _("cmdtext.off", cfg);
            } else {
                var value = parseFloat(cmdParts[1]);
                var states = this.getStates();

                if (states) {
                    // for usability reasons we only display 1%-100%! 0% = off! in the visu our sliders only goes from 1/min to 100/max
                    value = map(value, [states.states.min, states.states.max], [value === 0 || states.states.min === 0 ? 0 : 1, 100]);
                }

                cfg.value = lxFormat("%.0f%%", value);
                text = _("cmdtext.analog.value", cfg);
            }

            return text;
        }

    };
});
