const sanitizeValue = function(value, fallback = 0){
  if(
    typeof value !== 'number'
    || isNaN(value) 
    || !isFinite(value)
  ){
    return fallback;
  }
  return value;
}

export default sanitizeValue;
