/**
 * Created by loxone on 03.07.17.
 */
'use strict';
/**
 * This screen provides a list of all moods available. The user may either pick one of them and overwrite the mood with
 * the current outputs state or create a new scene with the current output states.
 */

define(["LightV2ControlEnums"], function (LightV2ControlEnums) {
    return class LightV2ControlReplaceMoodScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div />'));
            this.control = details.control; // detect which mood to select initially

            var states = this.control.getStates();

            if (states.activeMoods.length > 0) {
                this.activeId = states.activeMoods[0];
            } else if (states.lastActiveMoods.length > 0) {
                this.activeId = states.lastActiveMoods[0];
            } else if (states.favoriteMoods.length > 0) {
                this.activeId = states.favoriteMoods[0];
            } else if (states.additionalMoods.length > 0) {
                this.activeId = states.additionalMoods[0];
            }
        }

        getURL() {
            return "ReplaceMood";
        }

        titleBarText() {
            return _("controls.lightV2controller.mood.save");
        }

        titleBarConfig() {
            return {
                rightSide: TitleBarCfg.Button.TICK
            };
        }

        updateView(dismiss) {
            super.updateView(...arguments); // if dismiss is true, a new mood has been created!

            dismiss && this.ViewController.navigateBack();
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        reloadTable() {
            var
                /*favMoodsSection = this._getFavMoodsSection(),
                otherMoodsSection = this._getOtherMoodsSection(),*/
                currentMoodSection = this._getMoodsSection(),
                newMoodSection = this._getNewMoodSection();

            this.tableContent = [currentMoodSection, newMoodSection]; // Old two list UI

            /*if (otherMoodsSection.rows.length > 0) {
                this.tableContent.splice(1, 0, otherMoodsSection);
            }*/

            return super.reloadTable(...arguments);
        }

        titleBarActionRight() {
            if (this.activeId > 0) {
                this.control.sendCommand(Commands.format(Commands.LIGHTCONTROL_V2.MOOD.LEARN, this.activeId, this.control.getMoodName(this.activeId)));
                this.ViewController.navigateBack();
            }
        }

        // Private methods
        _getFavMoodsSection() {
            var section = {
                rows: [],
                headerTitle: _("controls.lightV2controller.mood.replace")
            };
            this.control.moodsOfList(true, function (mood) {
                this._createAndAddMoodCell(mood, section.rows);
            }.bind(this));
            return section;
        }

        _getOtherMoodsSection() {
            var section = {
                rows: []
            };
            this.control.moodsOfList(false, function (mood) {
                this._createAndAddMoodCell(mood, section.rows);
            }.bind(this));
            return section;
        }

        _getMoodsSection() {
            var section = {
                    rows: []
                },
                states = this.control.getStates();

            if (states) {
                states.moodList.forEach(function (mood) {
                    this._createAndAddMoodCell(mood, section.rows);
                }.bind(this));
            }

            return section;
        }

        _getNewMoodSection() {
            return {
                rows: [{
                    content: {
                        leftIconSrc: Icon.PLUS,
                        leftIconColor: window.Styles.colors.green,
                        title: _("controls.lightV2controller.mood.new"),
                        clickable: true
                    },
                    action: this._showNewMoodScreen.bind(this)
                }]
            };
        }

        _showNewMoodScreen() {
            this.ViewController.showState(LightV2ControlEnums.ScreenState.SAVE_MOOD, null, {
                control: this.control
            });
        }

        _createAndAddMoodCell(mood, rows) {
            var cell,
                selected = mood.id === this.activeId;
            cell = {
                content: {
                    title: mood.name,
                    selected: selected,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE
                },
                didCheckCell: function () {
                    this.activeId = mood.id;
                }.bind(this),
                type: GUI.TableViewV2.CellType.CHECKABLE
            };
            !mood.static && rows.push(cell);
        }

    };
});
