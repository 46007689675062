'use strict';

define("AudioZoneControlContent", ['ControlActionCellsScreen', 'AudioZoneControlEnums', 'AudioZoneTableViewHelper', 'AudioZoneShortcutHelper', 'SpotifyAccountHelper', 'CustomizationHelper', 'AddMediaViewControllerBase', 'AddMediaViewControllerZoneFavorite', 'AddMediaViewControllerPlaylist', 'AddMediaViewControllerPlaylistPicker', 'AddMediaViewControllerPlaylistSpotify', 'ZoneVoiceRecorder', 'MediaSearchBase', 'MediaLegacySearchScreen', 'MediaGlobalSearch', 'MediaBrowserBase', 'MediaBrowserService', 'MediaBrowserService7', 'MediaBrowserService11', 'MediaBrowserService12', 'MediaBrowserFavorites', 'MediaBrowserLineIn', 'MediaBrowserLibrary', 'MediaBrowserPlaylists', 'MediaContextMenuBase', "AboutScreenBase", "AudioZoneControlAboutMediaServer", "AudioZoneControlPlayer"], function (ControlActionCellsScreen, AudioZoneControlEnums, AudioZoneTableViewHelper, AudioZoneShortcutHelper, SpotifyAccountHelper, CustomizationHelper) {
    return class AudioZoneControlContent extends ControlActionCellsScreen {
        constructor() {
            super(...arguments);
            Object.assign(this, MediaStateHandler.Mixin);
            this.states = this.control.getStates();
            this.currentPlayerId = this.control.details.playerid;
            this.eventHandlers = [];
            this.currentScrollOffset = 0;
            this._viewShouldRemainVisible = true;
            this._showHiddenItemsMap = {};
        }

        viewDidLoad() {
            this.element.addClass("audio-zone-control");
            !HD_APP && this.ViewController.addMultipleView(ScreenState.AudioZoneDetailedVC);
            this.tableViewContentHelper = new AudioZoneTableViewHelper(this);
            this.customizationHelper = new CustomizationHelper();
            this.boundReload = this.reloadTable.bind(this);
            this.boundServiceReload = this.receivedServiceReloadEvent.bind(this);
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                if (Feature.SHARED_USER_SETTINGS) {
                    AudioZoneShortcutHelper.registerForShortcutChanges(this.boundReload, true);
                    this.customizationHelper.registerCustomizationChanges(this.boundReload, true);
                }

                this.actionTableView.setBackgroundView(new GUI.BackgroundView(Icon.INDICATOR, _("loading"), null, null, null, true));
            }.bind(this));
        }

        /**
         * Limit to much updates by just subscribing to those states
         * @returns {[string, string]}
         */
        getStateIDs() {
            return ["serverState", "universalIsLocked"];
        }

        setSubtitle() {
            return null;
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);

            this._addHorizontalScroller();
        }

        closeAction() {
            this._viewShouldRemainVisible = false;
            return super.closeAction();
        }

        viewDidDisappear(viewRemainsVisible) {
            return super.viewDidDisappear(this._viewShouldRemainVisible);
        }

        destroy() {
            this._stopMusicServerCommunication();

            if (Feature.SHARED_USER_SETTINGS) {
                AudioZoneShortcutHelper.unregisterForShortcutChanges(this.boundReload);
                this.customizationHelper.unregisterCustomizationChanges(this.boundReload);
            }

            return super.destroy(...arguments);
        }

        getActionTableView() {
            return new GUI.CardView(this.tableViewDataSource, this.tableViewDelegate);
        }

        // TableView DataSource Methods
        styleForTableView() {
            return HD_APP ? TableViewStyle.TRANSLUCENT : TableViewStyle.COMFORT_MODE_2020.replace("grouped", "translucent");
        }

        receivedStates(states) {
            super.receivedStates(...arguments);
            this.states = states;

            if (this._universalIsLocked !== states.universalIsLocked) {
                this._setVcReady(); // will show/dismiss the notReadyScreen


                this._universalIsLocked = states.universalIsLocked;
            }

            if (states.serverState === MediaEnum.ServerState.ONLINE) {
                if (this.previousServerState !== states.serverState) {
                    this._startMusicServerCommunication().then(function () {
                        this.reloadTable();
                    }.bind(this));
                }
            } else {
                this._stopMusicServerCommunication();

                this.reloadTable();
            }

            this.previousServerState = states.serverState;
        }

        receivedZoneFavoritesReloadEvent(reason) {
            return this.reloadTable();
        }

        receivedServiceReloadEvent(reason) {
            if (reason === MediaEnum.ReloadCause.USER_CHANGED) {
                // USER_CHANGED event is only possible for the Spotify Service
                return this.reloadTable();
            }
        }

        getTableContent() {
            return this.tableViewContentHelper.getTableContent();
        }

        shouldDisplayStateIcons() {
            return false;
        }

        /**
         * Reload the whole table
         * @note If an section already exists in the tableContent only it is reloaded instead of the whole table
         * @returns {*}
         */
        reloadTable() {
            if (MediaServerComp.Feature.THIS_APP) {
                // Check if We have all the features
                //If called by EventListener - unbind from the event, because we only need it once
                if (this.removeFeatureEventListenerFn) {
                    this.removeFeatureEventListenerFn();
                }

                return this.customizationHelper.getSavedSettings(this.control.uuidAction).then(function (settings) {
                    return this._updateTableContent().then(null, null, function notify(newSection) {
                        this.tableContent = this.tableContent || [];
                        var duplicateEntryIdx = -1;
                        this.tableContent.find(function (section, idx) {
                            if (section.identifier === newSection.identifier) {
                                duplicateEntryIdx = idx;
                            }
                        }); // Filter out the hidden cells

                        if (newSection && newSection.rows) {
                            var hasHiddenRows = false;
                            newSection.ogRows = cloneObjectDeep(newSection.rows); // Holds all rows, also the one that are hidden

                            newSection.filteredRows = newSection.rows.filter(function (row) {
                                // Only hold the hidden cells
                                var filter = true;

                                if (row && row.content.hasOwnProperty("identifier")) {
                                    hasHiddenRows |= !settings[row.content.identifier];
                                    filter = settings[row.content.identifier];
                                }

                                return filter;
                            }.bind(this));

                            if (this._showHiddenItemsMap[newSection.identifier]) {
                                newSection.rows = cloneObjectDeep(newSection.ogRows);
                            } else {
                                newSection.rows = cloneObjectDeep(newSection.filteredRows);
                            } // Add the show more or show less functionality


                            if (hasHiddenRows) {
                                newSection.sectionRightButtonTitle = this._showHiddenItemsMap[newSection.identifier] ? _("show-less") : _("more");

                                newSection.rightSectionButtonTapped = function rightSectionButtonTapped(sectionIdx) {
                                    this._showHiddenItemsMap[newSection.identifier] = !this._showHiddenItemsMap[newSection.identifier];

                                    if (!this._showHiddenItemsMap[newSection.identifier]) {
                                        delete this._showHiddenItemsMap[newSection.identifier];
                                        this.tableContent[sectionIdx].rows = cloneObjectDeep(this.tableContent[sectionIdx].filteredRows);
                                        this.tableContent[sectionIdx].sectionRightButtonTitle = _("more");
                                    } else {
                                        this.tableContent[sectionIdx].rows = cloneObjectDeep(this.tableContent[sectionIdx].ogRows);
                                        this.tableContent[sectionIdx].sectionRightButtonTitle = _("show-less");
                                    } // Manually update the tableContent, this.reloadSection and this.reloadTable will fetch the whole tableContent
                                    // From its different sources (Miniserver, Musicserver (Spotify, Tunein, ...) which results in slower loading times.


                                    var needsUpdate = false;
                                    needsUpdate |= this.tableViewDataSource.update(this.tableContent);
                                    needsUpdate |= this.tableViewDelegate.update(this.tableContent);
                                    this.actionTableView.setBackgroundView(null);
                                    needsUpdate && this.actionTableView.reloadSection(sectionIdx);
                                }.bind(this);
                            }
                        }

                        if (duplicateEntryIdx !== -1) {
                            this.tableContent[duplicateEntryIdx] = newSection;
                        } else {
                            this.tableContent.pushObject(newSection);
                        }

                        this.tableContent = this.tableContent.filter(function (section) {
                            var keep = section && section.rows && section.rows.length && section.rating !== MediaEnum.RATING.HIDDEN; // Filter out any hidden sections
                            // The duplicate section has been filtered out, also remove the duplicateEntryIdx!

                            if (!keep && section.identifier === newSection.identifier) {
                                duplicateEntryIdx = -1;
                            }

                            return keep;
                        }.bind(this));
                        this.tableContent.sort(function (a, b) {
                            return a.rating > b.rating ? 1 : -1;
                        });
                        var dsChanged = this.tableViewDataSource.update(this.tableContent);
                        var delChanged = this.tableViewDelegate.update(this.tableContent);
                        this.actionTableView.setBackgroundView(null);

                        if (dsChanged || delChanged) {
                            if (duplicateEntryIdx !== -1) {
                                this.actionTableView.reloadSection(duplicateEntryIdx).then(function () {
                                    this._addHorizontalScroller();
                                }.bind(this));
                            } else {
                                return this.actionTableView.reload().then(function () {
                                    this._addHorizontalScroller();
                                }.bind(this));
                            }
                        }
                    }.bind(this));
                }.bind(this));
            } else {
                //Feature check of media server -> not finished yet - caused loading spinner to be shown forever
                this.removeFeatureEventListenerFn = MediaServerComp.on(MediaServerComp.ECEvent.FeatureCheckingReady, this.reloadTable.bind(this));
            }
        }

        _updateTableContent() {
            return Q(this.getTableContent(this.control.getStates()));
        }

        _updateCommandsTable() {// Do Nothing here!
        }

        _addHorizontalScroller() {
            this.actionTableView && this.actionTableView.element.find(".card-section > .section__rows").mousewheel(function (event, delta) {
                this.scrollLeft -= delta;
                event.preventDefault();
            });
        }

        _startMusicServerCommunication() {
            var def = Q.defer();

            if (!MediaServerComp.getAudioViewsVisible()) {
                if (!MediaServerComp.isRestricted() && this.states && this.states.serverState === MediaEnum.ServerState.ONLINE) {
                    this._onFeatureCheckingReadyDstFnc = MediaServerComp.on("FeatureCheckingReady", function () {
                        def.resolve();
                    }.bind(this));
                } else {
                    def.resolve();
                }

                MediaServerComp.setAudioViewsVisible(true, this.control, this.ViewController);
            } else {
                def.resolve();
            }

            return def.promise.then(function () {
                if (!MediaServerComp.isRestricted()) {
                    Controls.AudioZoneControl.SpotifyAccountHelper.init();
                    Controls.AudioZoneControl.SpotifyAccountHelper.getCurrentAccountId(this.control.uuidAction).then(function (userId) {
                        if (Controls.AudioZoneControl.SpotifyAccountHelper.getSpotifyUserForId(userId)) {
                            this.spotifyId = userId;
                        }

                        this._registerForMediaServerReloadEvents(this.EVENT_TYPES.SERVICE);
                    }.bind(this));
                    this.serviceChangeReg = MediaServerComp.registerForServiceChanges(this.boundServiceReload);
                }

                this._registerForMediaServerReloadEvents(this.EVENT_TYPES.ZONE_FAVORITES);

                this._setVcReady();

                this._requestStates();
            }.bind(this));
        }

        _stopMusicServerCommunication() {
            this._unregisterForMediaServerEvents(this.EVENT_TYPES.SERVICE, this.EVENT_TYPES.ZONE_FAVORITES);

            this.serviceChangeReg && this.serviceChangeReg();
            Controls.AudioZoneControl.SpotifyAccountHelper.deinit();
            MediaServerComp.setAudioViewsVisible(false, this.control, this.ViewController);

            this._setVcReady();

            this._onFeatureCheckingReadyDstFnc && this._onFeatureCheckingReadyDstFnc();
        }

        _setVcReady() {
            if (this.ViewController && !(this.ViewController instanceof GUI.AudioZoneControlDetailedViewController)) {
                // Only do this in the ControlContent!
                this.ViewController.setReady(this.states && this.states.serverState === MediaEnum.ServerState.ONLINE && !this.states.universalIsLocked);
            }
        }

        getMediaInfo(contentType) {
            if (contentType === this.EVENT_TYPES.SERVICE && this.spotifyId) {
                return {
                    service: {
                        uid: MediaEnum.Service.SPOTIFY + "/" + this.spotifyId,
                        cmd: MediaEnum.Service.SPOTIFY
                    }
                };
            }
        }

    };
});
