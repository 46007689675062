import {LxBaseControlContent} from "LxComponents";
import {View} from "react-native";
import {LxReactTableView, ControlContext} from "LxComponents";
import {useContext, useMemo} from "react";
import globalStyles from "GlobalStyles";

function EneryManagerControlContent({controlUUID}) {
    return <LxBaseControlContent>
        <EnergyLoads/>
    </LxBaseControlContent>
}

function EnergyLoads(props) {
    const {control, states} = useContext(ControlContext)

    const loadsContent = useMemo(() => {
        if (!states || !states.loads) {
            return [];
        }
        let section = {
            rows: []
        };
        states.loads.forEach((load) => {
            let cellObj = {},
                subtitle = _("controls.energyManager.ready"),
                subtitleColor = globalStyles.colors.text.secondary;

            if (load.isActive) {
                subtitle = _("on");
                subtitleColor = globalStyles.colors.stateActive;
            }

            if (load.isWaitingForActivation) {
                subtitle = _("controls.energyManager.activation-needed");
                subtitleColor = globalStyles.colors.text.secondary;
            }

            if (load.isPreparing) {
                subtitle = _("controls.energyManager.prepare");
                subtitleColor = globalStyles.colors.text.secondary;
            }

            if (load.activeUntil !== 0) {
                subtitle = _("controls.energyManager.on-till", {
                    time: new LxDate(load.activeUntil, true).utc().format(DateType.TimeFormat)
                });
                subtitleColor = globalStyles.colors.stateActive;
            }

            if (load.isPermanentOn) {
                subtitle = _("on");
                subtitleColor = globalStyles.colors.stateActive;
            }

            cellObj = {
                type: GUI.TableViewV2.CellType.SWITCH,
                content: {
                    title: load.name,
                    subtitle: subtitle,
                    active: load.isActive,
                    subtitleColor: subtitleColor
                },
                onSwitchChanged: (value, section, row, tableView, cell) => {
                    let cmd = Commands.format(value ? Commands.EnergyManager.TURN_ON : Commands.EnergyManager.TURN_OFF, load.id);

                    control.sendCommand(cmd);
                }
            };

            if (!load.isPermanentOn && load.minOnTime > 0) {
                cellObj.content.button2Src = Icon.Pushbutton.PUSHBUTTON;

                cellObj.buttonTapped = (section, row, tableView) => {
                    let cmd = Commands.format(Commands.EnergyManager.TRIGGER, load.id);

                    control.sendCommand(cmd);
                };

                cellObj.buttonDoubleTapped = (section, row, tableView) => {
                    let cmd = Commands.format(Commands.EnergyManager.TRIGGER, load.id);

                    control.sendCommand([cmd, cmd]);
                };
            }

            section.rows.push(cellObj);
        });

        return [section];
    }, [states])

    return <View style={styles.loadsContainer}>
        {loadsContent && <LxReactTableView styles={styles.tableView} tableContent={loadsContent}/>}
    </View>
}

const styles = {
    loadsContainer: {
        width: "100%"
    },
    tableView: {
        List: {
            paddingHorizontal: 0
        }
    }
}

export default EneryManagerControlContent