'use strict';
/*
 title
 iconSrc

 color      // atm only used for the title
 */

window.GUI = function (GUI) {
    class LightCell extends GUI.TableViewV2.Cells.BaseCell {
        constructor() {
            super(...arguments);
            this.element.addClass("light-cell");
        }

        viewDidLoad() {
            var template, titleElement;
            super.viewDidLoad();
            template = GUI.TableViewV2.Cells.LightCell.Template.getCellContent(this.content.iconSrc, this.content.title);
            this.contentPlaceholder.append(template);

            if (this.content.color) {
                titleElement = this.element.find(".texts-placeholder__name");
                titleElement.css('color', this.content.color);
            }
        }

        // used in music server, here it's always clickable
        clickableAllowed() {
            return true;
        }

    }

    GUI.TableViewV2.Cells.LightCell = LightCell;

    GUI.TableViewV2.Cells.LightCell.Template = function () {
        var getCellContent = function getCellContent(iconSrc, title) {
            return (iconSrc ? '<div class="light-cell__icon-placeholder">' + ImageBox.getResourceImageWithClasses(iconSrc, "light-cell__icon") + '</div>' : '') + '<div class="light-cell__texts-placeholder">' + '<div class="texts-placeholder__name text-overflow-ellipsis">' + title + '</div>' + '</div>';
        };

        return {
            getCellContent: getCellContent
        };
    }();

    GUI.TableViewV2.CellType.LIGHT = "LightCell";
    return GUI;
}(window.GUI || {});
