import useLimitedWallboxes, {GetLimitedByTextForLimitBit, WallboxEmRestrictionCauses} from "./useLimitedWallboxes";
import {LxReactText, LxReactPressable} from "LxComponents";
import globalStyles from "../../../../styles/react/styles";
import {useNavigation} from "@react-navigation/native";
import {View} from "react-native"
import WallboxManagerLimitedByScreen from "./wallboxManagerLimitedByScreen";
import Icons from "IconLib";


export function WallboxManagerLimitedByCell({limitBit, key, onPress = null}) {
    return <LxReactPressable pressableStyle={styles.itemContainer}
                             disabled={!onPress}
                             key={limitBit}
                             onPress={onPress}>
        <View style={styles.iconCntr}>
            <Icons.Error fill={globalStyles.colors.orange} style={styles.icon}/>
        </View>
        <LxReactText style={styles.text}>{GetLimitedByTextForLimitBit(limitBit)}</LxReactText>
        {onPress ? <Icons.Chevron style={styles.disclosure} /> : null}
    </LxReactPressable>
}

export default function WallboxManagerLimitedByList(nodeUuid) {

    const restrictedMap = useLimitedWallboxes(nodeUuid);
    const navigation = useNavigation();

    const showMap = () => {
        let showMap = {};

        // strand and parent have the same text, show them as one cause!
        if (restrictedMap.hasOwnProperty(WallboxEmRestrictionCauses.PARENT) &&
            restrictedMap.hasOwnProperty(WallboxEmRestrictionCauses.STRAND)) {
            let bit = 0;
            bit = setBit(bit, WallboxEmRestrictionCauses.PARENT);
            bit = setBit(bit, WallboxEmRestrictionCauses.STRAND);
            showMap[bit] = [
                ...restrictedMap[WallboxEmRestrictionCauses.PARENT],
                ...restrictedMap[WallboxEmRestrictionCauses.STRAND]
            ]
        } else if (restrictedMap.hasOwnProperty(WallboxEmRestrictionCauses.PARENT)) {
            showMap[WallboxEmRestrictionCauses.PARENT] = restrictedMap[WallboxEmRestrictionCauses.PARENT];
        } else if (restrictedMap.hasOwnProperty(WallboxEmRestrictionCauses.STRAND)) {
            showMap[WallboxEmRestrictionCauses.STRAND] = restrictedMap[WallboxEmRestrictionCauses.STRAND];
        }

        if (restrictedMap.hasOwnProperty(WallboxEmRestrictionCauses.PMAX)) {
            showMap[WallboxEmRestrictionCauses.PMAX] = restrictedMap[WallboxEmRestrictionCauses.PMAX];
        }
        if (restrictedMap.hasOwnProperty(WallboxEmRestrictionCauses.ECO)) {
            showMap[WallboxEmRestrictionCauses.ECO] = restrictedMap[WallboxEmRestrictionCauses.ECO];
        }

        return showMap;
    }


    return  <View style={styles.listContainer}>
        {
            Object.keys(showMap())
                .map((limitBit, idx) => {
                return <WallboxManagerLimitedByCell
                    limitBit={limitBit}
                    key={limitBit + ""}
                    onPress={() => {
                        navigation.push(WallboxManagerLimitedByScreen.name, { limitedBit: limitBit, nodeUuid});
                    }} />
            })
        }
    </View>
}

const styles = {
    listContainer: {
        flexDirection: "column"
    },
    itemContainer: {
        flexDirection: "row",
        alignItems: "center",
        minHeight: 80
    },
    iconCntr: {
        borderRadius: "50%",
        backgroundColor: globalStyles.colors.stateInactiveB,
        width: 48,
        height: 48,
        alignItems: "center",
        justifyContent: "center",
        marginRight: globalStyles.spacings.gaps.regular
    },
    icon: {
        width: 24,
        height: 24,
    },
    text: {
        ...globalStyles.textStyles.subheadline.regular,
        color: globalStyles.colors.text.secondary,
        flex: 1
    },
    disclosure: {
        fill: globalStyles.colors.text.secondary,
        height: 24,
        width: 12,
        style: {marginLeft: 12, minHeight: 12, minWidth: 12}
    }
}