'use strict';

define(['AudioZoneControlEnums', 'HardwareVolumeButtonHandler', 'CurrentCoverView', 'AudioZoneControlQueueScreen'], function (AudioZoneControlEnums, HardwareVolumeButtonHandler, CurrentCoverView, AudioZoneControlQueueScreen) {
    class AudioZoneControlPlayer extends GUI.Screen {
        //region Static
        static Template = function () {
            var getControlTemplate = function getContainer(ctrl) {
                return '' + '<div class="container__wrapper">' + '<div class="container__queue-view"></div>' + '<div class="container__hide-able">' + '<div class="container__state-view">' + '<div class="media__cover__container"></div>' + '</div>' + '<div class="container__action-view">' + getMediaInfoBar() + getTimeBar() + createControlBar() + createVolumeControlBar() + '</div>' + '</div>' + createBottomBar() + '</div>';
            };

            var getMediaInfoBar = function getMediaInfoBar() {
                return '' + '<div class="action-view__media-info-bar action-view__bar">' + '<div class="media-info-bar__center-container">' + '<div class="center-container__top media-info-bar__main-label text-overflow-ellipsis">' + '...' + '</div>' + '<div class="center-container__bottom media-info-bar__sub-label text-overflow-ellipsis">' + '...' + '</div>' + '</div>' + '<div class="media-info-bar__right-container media-info-bar__outer-container more__button clickable">' + ImageBox.getResourceImageWithClasses(Icon.Buttons.MORE_HORIZONTAL, "more__icon outer-container__icon") + '</div>' + '</div>';
            };

            var getTimeBar = function getTimeBar() {
                return '' + '<div class="action-view__time-bar action-view__bar">' + '<div class="time-bar__bottom time__slider-container">' + '</div>' + '<div class="time-bar__top">' + '<div class="top__left time__current">' + '</div>' + '<div class="top__right time__total">' + '</div>' + '</div>' + '</div>';
            };

            var createControlBar = function createControlBar() {
                return '' + '<div class="action-view__control-bar action-view__bar">' + '<div class="control-bar__prev-button control-bar__icon-container">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PREV, "control-bar__prev control-bar__icon") + '</div>' + '<div class="control-bar__play-pause-button control-bar__icon-container control-bar__play-pause clickable">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PLAY, "control-bar__play control-bar__icon control-bar__play-pause-icon") + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PAUSE, "control-bar__pause control-bar__icon control-bar__play-pause-icon") + '</div>' + '<div class="control-bar__next-button control-bar__icon-container">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.NEXT, "control-bar__next control-bar__icon") + '</div>' + '</div>';
            };

            var createVolumeControlBar = function createVolumeControlBar() {
                return '' + '<div class="action-view__volume-control-bar action-view__bar">' + '</div>';
            };

            var createBottomBar = function createBottomBar() {
                return '' + '<div class="action-view__bottom-control-bar-container action-view__bar">' + '<div class="action-view__bottom-control-bar action-view__bar">' + '   <div class="action-view__bar zone-group-button clickable">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.GROUPED, "bar__icon zone-group__icon") + '   </div>' + '   <div class="action-view__bar shuffle-button clickable">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.SHUFFLE, "bar__icon zone-group__icon") + '   </div>' + '   <div class="action-view__bar repeat-button clickable">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.REPEAT_ALL, "bar__icon zone-group__icon") + '   </div>' + '   <div class="action-view__bar queue-button clickable">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.QUEUE, "bar__icon queue_icon") + '   </div>' + '   </div>' + '</div>';
            };

            return {
                getControlTemplate: getControlTemplate
            };
        }(); //endregion Static

        constructor(details) {
            Debug.Control.AudioZone.Player && debugLog("AudioZoneControlPlayer", " ctor");
            super($('<div />'));
            Object.assign(this, StateHandler.Mixin, MediaContextMenuHandler.Mixin);
            this.control = details.control;
            this.showQueue = details.showQueue;
            this.states = {};
            this.swipeHandlers = [];
        }

        viewDidLoad() {
            Debug.Control.AudioZone.Player && debugLog("AudioZoneControlPlayer", " viewDidLoad");
            return Q(super.viewDidLoad(...arguments) || true).then( () => {
                this.element.append(AudioZoneControlPlayer.Template.getControlTemplate(this.control));
                this.elements = {
                    stateView: $(this.element.find('.container__state-view')),
                    actionView: $(this.element.find('.container__action-view')),
                    hideAbleView: $(this.element.find('.container__hide-able')),
                    queueView: $(this.element.find('.container__queue-view')),
                    bottomBar: $(this.element.find('.action-view__bottom-control-bar'))
                };
                this.elements.coverContainer = $(this.elements.stateView.find('.media__cover__container'));
                this.elements.coverView = new CurrentCoverView(this.control);
                this.appendSubview(this.elements.coverView, this.elements.coverContainer); // Bars

                this.elements.mediaInfoBar = this.elements.actionView.find('.action-view__media-info-bar');
                this.elements.timeBar = this.elements.actionView.find('.action-view__time-bar');
                this.elements.controlBar = this.elements.actionView.find('.action-view__control-bar');
                this.elements.volumeControlBar = this.elements.actionView.find('.action-view__volume-control-bar'); // Media-Info

                this.elements.mainLabel = $(this.elements.mediaInfoBar.find('.media-info-bar__main-label'));
                this.elements.subLabel = $(this.elements.mediaInfoBar.find('.media-info-bar__sub-label'));
                this.elements.media = {}; // Time

                this.elements.time = {
                    sliderContainer: $(this.elements.timeBar.find('.time__slider-container')),
                    current: $(this.elements.timeBar.find('.time__current')),
                    total: $(this.elements.timeBar.find('.time__total'))
                }; // Control

                this.elements.prevButton = this.elements.controlBar.find('.control-bar__prev-button');
                this.elements.playPauseButton = this.elements.controlBar.find('.control-bar__play-pause-button');
                this.elements.nextButton = this.elements.controlBar.find('.control-bar__next-button');
                this.elements.playButtonIcon = this.elements.actionView.find('.control-bar__play');
                this.elements.pauseButtonIcon = this.elements.actionView.find('.control-bar__pause').hide(); // Volume Slider

                this.volumeControl = new Controls.AudioZoneControl.AudioZoneControlVolumeControl(this.control);
                this.volumeControl.getElement().addClass('action-view__bottom-bar action-view__bar');
                this.appendSubview(this.volumeControl, this.elements.volumeControlBar);
                this.hardwareVolHandler = new HardwareVolumeButtonHandler(this.control); // no need to respond to the onHardwareButtonUsed method, the volume control is already visible.
                // Bottom Buttons

                this.elements.queueButton = this.elements.bottomBar.find('.queue-button');
                this.elements.shuffleButton = this.elements.bottomBar.find('.shuffle-button');
                this.elements.repeatButton = this.elements.bottomBar.find('.repeat-button');
                this.elements.zoneGroupButton = this.elements.bottomBar.find('.zone-group-button');
                this.elements.moreButton = this.elements.mediaInfoBar.find('.more__button'); // ---------------------
                //    active controls// ---------------------


                this.buttons = {
                    prevButton: new GUI.LxButton(this, this.elements.prevButton[0], Color.BUTTON_GLOW),
                    playPauseButton: new GUI.LxButton(this, this.elements.playPauseButton[0], Color.BUTTON_GLOW),
                    nextButton: new GUI.LxButton(this, this.elements.nextButton[0], Color.BUTTON_GLOW),
                    queueButton: new GUI.LxButton(this, this.elements.queueButton[0], Color.BUTTON_GLOW),
                    shuffleButton: new GUI.LxButton(this, this.elements.shuffleButton[0], Color.BUTTON_GLOW),
                    repeatButton: new GUI.LxButton(this, this.elements.repeatButton[0], Color.BUTTON_GLOW),
                    zoneGroupButton: new GUI.LxButton(this, this.elements.zoneGroupButton[0], Color.BUTTON_GLOW),
                    moreButton: new GUI.LxButton(this, this.elements.moreButton[0], Color.BUTTON_GLOW)

};
                Object.values(this.buttons).forEach( (button) => {
                    this.addToHandledSubviews(button);
                    button.useChildsAsActiveParts('fill');
                });

                if (this.control.details.clientType === MediaEnum.ClientType.UPNP) {
                    this.hideSubview(this.buttons.zoneGroupButton);
                } // Timeline-Slider


                this.appendReactComp({
                    reactComp: GUI.LxSlider,
                    compProps: {
                        updateRate: 0.5,
                    },
                    target: this.elements.time.sliderContainer
                }).then(instance => {
                    this.timeLineSlider = instance;
                    this.timeLineSlider.getElement().addClass('time-line__slider');
                })
            });
        }

        viewWillAppear() {
            Debug.Control.AudioZone.Player && debugLog("AudioZoneControlPlayer", " viewWillAppear");
            var promise = Q(super.viewWillAppear(...arguments));

            if (this.showQueue) {
                this._handleQueueButtonTapped();

                this.showQueue = false;
            }

            return promise.then(function () {
                this._registerForStates(this.control.uuidAction);

                if (!Feature.MULTI_MUSIC_SERVER) {
                    // request the state now, the response is handled by the stateContainer.
                    this.dispatchCmd(MediaEnum.AudioCommands.STATUS);
                }
            }.bind(this));
        }

        viewDidAppear() {
            Debug.Control.AudioZone.Player && debugLog("AudioZoneControlPlayer", " viewDidAppear");
            return Q(super.viewDidAppear(...arguments)).then(function () {
                this._registerButtons(); //Swipe handlers caused queue elements not to be movable


                if (!this.qIsVisable) {
                    this._registerSwipeHandlers();
                }

                this.hardwareVolHandler.start();
                this.timeLineSlider.onPositionChanged = this._handleTimeLineSlider.bind(this);
            }.bind(this));
        }

        viewWillDisappear() {
            this._unregisterStates();

            super.viewWillDisappear(...arguments);
        }

        viewDidDisappear(viewRemainsVisible) {
            Debug.Control.AudioZone.Player && debugLog("AudioZoneControlPlayer", " viewDidDisappear"); // unregister buttons

            var buttonKeys = Object.keys(this.buttons),
                i,
                btnKey,
                button;

            for (i = 0; i < buttonKeys.length; i++) {
                btnKey = buttonKeys[i];
                button = this.buttons[btnKey];
                button.onButtonTapped = null;
            }

            this._unregisterSwipeHandlers();

            this.hardwareVolHandler.stop();
            this.timeLineSlider.onPositionChanged = null; // hide after it did disappear, otherwise it looks odd.

            this.elements.bgCoverView && this.hideSubview(this.elements.bgCoverView);
            super.viewDidDisappear(...arguments);
        }

        destroy() {
            Debug.Control.AudioZone.Player && debugLog("AudioZoneControlPlayer", " destroy");
            this.hardwareVolHandler.destroy(); // the MediaServerComp needs to know wether or not any (fullscreen) audio-views are visible

            if (this.opensConnection) {
                MediaServerComp.setAudioViewsVisible(false);
            }

            super.destroy();
        }

        updateView(details) {
            Debug.Control.AudioZone.Player && console.log(this.name, "updateView - " + JSON.stringify(details));
            super.updateView(...arguments); // when a queue wants to dismiss the player too, only if it's not root

            if (details.hasOwnProperty("target")) {
                if (details.target === AudioZoneControlEnums.ScreenState.DETAILS) {
                    // not root, so obviously an overlay over any child of menu
                    this.ViewController.navigateBack();
                }
            }
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.DOWN,
                style: 'transparent'
            };
        }

        titleBarText() {
            return NBR_SPACE;
        }

        getURL() {
            return "player";
        }

        getAnimation() {
            var platform = PlatformComponent.getPlatformInfoObj().platform;

            if (platform === PlatformType.IOS || platform === PlatformType.Android || platform === PlatformType.DeveloperInterface) {
                return HD_APP ? AnimationType.HD_MODAL_FULLSCREEN : AnimationType.MODAL;
            } else {
                return AnimationType.MODAL;
            }
        }

        // Received states preprocessed by the stateContainer
        receivedStates(states) {
            // base.receivedStates.call(this, states);
            // don't pass on, it'd show a disconnected notification, we don't want that here
            Debug.Control.AudioZone.Player && console.log(this.name, "receivedStates " + JSON.stringify(states));
            updateFields(this.states, states);
            this.volumeControl.receivedStates(states); // Updates the UI

            if (!this.qIsVisable) {
                this._togglePlayerState(this.states.isStream);
            } // PlayState


            this.elements.playPauseButton.toggleClass('active', this.states.isPlaying);

            if (this.states.isPlaying) {
                this.elements.playButtonIcon.hide();
                this.elements.pauseButtonIcon.show();
            } else {
                this.elements.playButtonIcon.show();
                this.elements.pauseButtonIcon.hide();
            } // Update Media Info


            var mainText, subText;

            if (this.states.texts.connectivityText) {
                mainText = this.control.getName();
                subText = this.states.texts.connectivityText;
            } else {
                mainText = this.states.texts.mainText;
                subText = this.states.texts.subText;
            }

            Debug.Control.AudioZone.Player && console.log('receivedStates: mainText --> ' + mainText);
            Debug.Control.AudioZone.Player && console.log('receivedStates: subText --> ' + subText);
            this.elements.mainLabel.text(mainText);
            this.elements.subLabel.text(subText);
            this.elements.shuffleButton.toggleClass('active', this.states.shuffleOn); //this.elements.repeatButton.toggleClass('active', this.states.isPlaying);

            this.elements.repeatButton.toggleClass('active', this.states.repeatMode !== 0);
            Debug.Control.AudioZone.Player && console.log("repeateMode from state: " + this.states.repeatMode);

            if (this.states.repeatMode === 3) {
                this.elements.repeatButton[0].innerHTML = ImageBox.getResourceImageWithClasses(Icon.AudioZone.REPEAT_CURRENT, 'bar__icon zone-group__icon');
            } else {
                this.elements.repeatButton[0].innerHTML = ImageBox.getResourceImageWithClasses(Icon.AudioZone.REPEAT_ALL, 'bar__icon zone-group__icon');
            }
        }

        // --------------------------------------------------------
        // --------------------------------------------------------
        // Connection Handling
        // --------------------------------------------------------
        // --------------------------------------------------------

        /**
         * Decides what channel to use to transmit the cmd (via media comp or miniserver)
         * it's sendCommand, but a different name was used to avoid confusion with base.sendCommand.
         * @param cmd
         */
        dispatchCmd(cmd) {
            if (MediaServerComp.isActive()) {
                var cmdObj = {
                    cmd: cmd
                };
                MediaServerComp.sendAudioZoneCommand(this.control.details.playerid, cmdObj);
            } else {
                SandboxComponent.sendCommand(this, this.control.uuidAction, cmd, null, this.control.isSecured, null, false);
            }
        }

        // Present the Queue
        _presentQueue() {
            var detail = {
                control: this.control
            };

            if (this.states.hasOwnProperty(MediaEnum.Event.QUEUE_INDEX)) {
                detail[MediaEnum.Event.QUEUE_INDEX] = this.states[MediaEnum.Event.QUEUE_INDEX];
            }

            this.ViewController.showState(AudioZoneControlEnums.ScreenState.QUEUE, null, detail);
        }

        // --------------- Prepareing the UI
        _registerSwipeHandlers() {
            var swipeOptions = {
                swipeVelocityX: 0.1
            };
            //this.swipeHandlers.push(Hammer(this.element[0], swipeOptions).on('swipedown', this._onSwipeDown.bind(this)));
        }

        _unregisterSwipeHandlers() {
            // Unregister swipe handlers
            for (var i = 0; i < this.swipeHandlers.length; i++) {
                this.swipeHandlers[i].dispose();
            }
        }

        _registerButtons() {
            var buttonKeys = Object.keys(this.buttons);

            for (var i = 0; i < buttonKeys.length; i++) {
                var btnKey = buttonKeys[i];
                var button = this.buttons[btnKey];
                button.onButtonTapped = this._handleButtonTapped.bind(this);
            }
        }

        // ------------------------------------------------------------------------------
        // ------------------------------------------------------------------------------
        //                UI Interaction
        // ------------------------------------------------------------------------------
        _handleTimeLineSlider(progress) {
            Debug.Control.AudioZone.Player && console.log("time line set to " + progress);
            var value = progress;

            if (Math.round(value) === Math.round(this.states[MediaEnum.Event.DURATION])) {
                value = value - 1;
            }

            this.control.sendCommand(Commands.format(Commands.AudioZone.PROGRESS, value));
        }

        _handleQueueButtonTapped() {
            if (!this.queueView) {
                this.queueView = new Controls.AudioZoneControl.AudioZoneControlQueueScreen({
                    control: this.control
                });
                this.appendSubview(this.queueView, this.elements.queueView);
            }

            this.toggleSubview(this.queueView, !this.qIsVisable);
            this.toggleSubview(this.elements.coverView, this.qIsVisable);
            this.elements.hideAbleView.toggle(this.qIsVisable);
            this.qIsVisable = !this.qIsVisable;
            this.qIsVisable ? this._unregisterSwipeHandlers() : this._registerSwipeHandlers();
            this.elements.queueButton.toggleClass('active', this.qIsVisable);
        }

        _handleGroupButtonTapped() {
            if (MediaServerComp.isRestricted()) {
                MediaServerComp.showRestrictedPopup();
            } else {
                var detail = {
                        control: this.control,
                        zone: {}
                    },
                    target = AudioZoneControlEnums.ScreenState.SYNC;
                detail.zone[MediaEnum.Event.PLAYER_ID] = this.control.details.playerid;
                detail.zone[MediaEnum.Event.NAME] = this.control.getName();

                if (MediaServerComp.Feature.REWORKED_GROUPING) {
                    detail.zone = this.control;
                    target = AudioZoneControlEnums.ScreenState.GROUP;
                }

                this.ViewController.showState(target, null, detail, AnimationType.MODAL);
            }
        }

        _nextTrack() {
            this.control.sendCommand(Commands.AudioZone.Queue.NEXT);
        }

        _prevTrack() {
            this.control.sendCommand(Commands.AudioZone.Queue.PREV);
        }

        _handleButtonTapped(sourceBtn) {
            switch (sourceBtn) {
                case this.buttons.prevButton:
                    this._prevTrack();

                    break;

                case this.buttons.playPauseButton:
                case this.buttons.coverButton:
                    this.control.sendCommand(this.states.isPlaying ? Commands.AudioZone.CLIENT.PAUSE : Commands.AudioZone.CLIENT.PLAY);
                    break;

                case this.buttons.nextButton:
                    this._nextTrack();

                    break;

                case this.buttons.moreButton:
                    this._handleContextMenuButtonTapped();

                    break;

                case this.buttons.queueButton:
                    this._handleQueueButtonTapped();

                    break;

                case this.buttons.shuffleButton:
                    this._handleShuffleButtonTapped();

                    break;

                case this.buttons.repeatButton:
                    this._handleRepeatButtonTapped();

                    break;

                case this.buttons.zoneGroupButton:
                    this._handleGroupButtonTapped();

                    break;

                default:
                    console.log(JSON.stringify(sourceBtn) + " not handled");
                    break;
            }
        }

        _onSwipeDown() {
            NavigationComp.navigateBack();
        }

        /**
         * Will acquire the currently playing identification and as soon as that is here, it'll
         * @private
         */
        _handleContextMenuButtonTapped() {
            if (MediaServerComp.isRestricted()) {
                MediaServerComp.showRestrictedPopup();
                return;
            }

            if (!MediaServerComp.Feature.SAVE_CURRENT_PLAYING) {
                return;
            }

            var pId = this.control.details.playerid,
                cmdObj = {
                    cmd: MediaEnum.AudioCommands.IDENTIFY_SOURCE
                };
            MediaServerComp.sendAudioZoneCommand(pId, cmdObj).then(function (result) {
                this.currentItemInfo = result.data[0];
                this.currentItemInfo[MediaEnum.Event.FILE_TYPE] = MediaEnum.FileType.LOCAL_FILE; // the app expects

                try {
                    this.currentItemInfo[MediaEnum.Event.AUDIO_PATH] = decodeURIComponent(this.currentItemInfo[MediaEnum.Event.AUDIO_PATH]);
                } catch (ex) {
                } // it contains the name of the player, something we don't need.


                delete this.currentItemInfo[MediaEnum.Event.NAME];
                Debug.Control.AudioZone.Player && console.log("Identify responded: " + JSON.stringify(this.currentItemInfo));

                this._showContextMenuForItem(this.currentItemInfo);
            }.bind(this), function (err) {
                console.error("Error while identifying the current source!");
            });
        }

        // -------------- ContextButtonMixin ----------------------

        /**
         * Returns the current mediaTypeDetails.
         * @returns {*}
         */
        getMediaTypeDetails(item) {
            var typeDetails = null,
                service = {};

            try {
                if (this.currentItemInfo) {
                    service[MediaEnum.Attr.SERVICE.CMD] = item[MediaEnum.Attr.SERVICE._];
                    service[MediaEnum.Attr.SERVICE.USER] = item[MediaEnum.Attr.SERVICE.USER];
                    service[MediaEnum.Attr.SERVICE.UID] = service[MediaEnum.Attr.SERVICE.CMD] + "/" + service[MediaEnum.Attr.SERVICE.USER];
                    service[MediaEnum.Event.NAME] = MediaServerComp.getServiceName(service[MediaEnum.Attr.SERVICE.CMD]);
                    typeDetails = {
                        service: service
                    };
                }
            } catch (ex) {
                console.error("Could not determine source of the current item!");
            }

            return typeDetails;
        }

        _togglePlayerState(isStreaming) {
            if (isStreaming && !this.states.duration) {
                this._setStreamingPlayer();
            } else {
                this._setLibraryPlayer();
            }
        }

        _setStreamingPlayer() {
            this.elements.timeBar.addClass("action-view__time-bar--hidden"); // Don't use ".hide()" it'll set the "display: none" property which will destroy flexbox!
        }

        _setLibraryPlayer() {
            this.elements.timeBar.removeClass("action-view__time-bar--hidden"); // Don't use ".show()" it'll set the "display: block" property which will destroy flexbox!
            // Update Time
            // - bar

            this.timeLineSlider.updateProperties({
                max: this.states[MediaEnum.Event.DURATION]
            });
            this.timeLineSlider.setPosition(this.states[MediaEnum.Event.TIME]); // - texts

            if (this.states.texts.time) {
                this.elements.time.current.text(this.states.texts.time.current);
                this.elements.time.total.text(this.states.texts.time.total);
            } else {
                this.elements.time.current.text("");
                this.elements.time.total.text("");
            }
        }

        _handleShuffleButtonTapped() {
            this.control.sendCommand(Commands.format(Commands.MEDIACLIENT.SHUFFLE, !this.states.shuffleOn));
        }

        _handleRepeatButtonTapped() {
            var repeatValue;
            Debug.Control.AudioZone.Player && console.log(this.name + " repeateMode: " + this.states.repeatMode);

            switch (this.states.repeatMode) {
                case 0:
                    repeatValue = 1;
                    break;

                case 1:
                    repeatValue = 3;
                    break;

                case 3:
                    repeatValue = 0;
                    break;

                default:
                    repeatValue = 0;
                    break;
            }

            this.control.sendCommand(Commands.format(Commands.MEDIACLIENT.REPEAT, repeatValue));
        }

    }

    Controls.AudioZoneControl.AudioZoneControlPlayer = AudioZoneControlPlayer;
});
