'use strict';

define("WindowMonitorControl", ["Control"], function (Control) {
    return class WindowMonitorControl extends Control {
        constructor() {
            super(...arguments);
            this.hasWindowsInDifferentRooms = this._hasWindowsInDifferentRooms();
        }

        getReactControlContentFlags() {
            return {
                showStateIcon: false
            }
        }

        getFriendlyValueName(event, operator) {
            return this.name + " " + event.data.stateTitle + " " + operator + " " + event.value.toString();
        }

        _hasWindowsInDifferentRooms() {
            // keep in mind that array may be empty!
            var i, room;

            for (i = 0; i < this.details.windows.length; i++) {
                if (room && this.details.windows[i].room !== room) {
                    return true;
                }

                room = this.details.windows[i].room;
            }

            return false;
        }

    };
});
