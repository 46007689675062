'use strict';

window.GUI = function (GUI) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var ASYNC_DELAY = 50; // ms the async operations are to be delayed.

        var ASYNC_CONTENT_LIMIT = 16; // ms the async operations are to be delayed.

        class AsyncTableView extends GUI.TableViewV2 {
            /**
             * ctor of AsyncTableView
             * @param {*} dataSource - context for datasource of the tableView
             * @param {*} delegate - context for callbacks from the tableView and cells
             * @param {jQuery} elem if set, this element turns into the TableViewV2, otherwise a new element will be created
             * @constructor
             */
            constructor(dataSource, delegate, elem) {
                super(...arguments);
                this.getElement().addClass('lx-async-table-view');
            }

            viewDidLoad() {
                super.viewDidLoad(...arguments);
                this.waitingView = new GUI.LxWaitingView(_("media.item.loading"));
                this.waitingView.getElement().addClass('lx-async-table-view__waiting-view');
                this.prependSubview(this.waitingView);
            }

            // -----------------------
            // Public methods
            // -----------------------

            /**
             * Reloads the tableView, either async or synchronously. Will display
             * a waiting screen while the data is being loaded.
             * @returns {*}
             */
            reload() {
                Debug.GUI.TableView && console.log(this.name, "reload");
                var result;

                this._stopNonBlockingProcess();

                if (this._needsAsync()) {
                    Debug.GUI.TableView && console.log("      use async");
                    this.showSubview(this.waitingView);
                    var def = Q.defer();

                    if (this.isVisible()) {
                        Debug.GUI.TableView && console.log("      perform after timeout");
                        setTimeout(this._asyncReload.bind(this, def), 0);
                    } else {
                        Debug.GUI.TableView && console.log("      perform when visible");
                        this.processWhenVisible(this._asyncReload.bind(this, def));
                    }

                    result = def.promise;
                } else {
                    Debug.GUI.TableView && console.log("      no need for async");
                    this.hideSubview(this.waitingView); // might be shown initially

                    result = super.reload(...arguments);
                }

                Debug.GUI.TableView && console.log(this.name, "reload < passed");
                return result;
            }

            reloadRowsSelective(sectionIdx, startIndex, nCells) {
                Debug.GUI.TableView && console.log(this.name, "reloadRowsSelective: " + sectionIdx + " / " + startIndex);
                var args = arguments,
                    def = Q.defer();

                if (this._needsAsync()) {
                    setTimeout(() => {
                        Debug.GUI.TableView && console.log(this.name, "reloadRowsSelective - async: " + sectionIdx + " / " + startIndex);
                        super.reloadRowsSelective(...args).then(function () {
                            def.resolve();
                        });
                        Debug.GUI.TableView && console.log(this.name, "reloadRowsSelective - async < done");
                    }, 0);
                    return def.promise;
                } else {
                    return super.reloadRowsSelective(...arguments);
                }
            }

            /**
             * Will destroy all the content and remove it from the DOM after a short delay.
             * Used to free up data while in the background.
             */
            destroyContent() {
                Debug.GUI.TableView && console.log(this.name, "destroyContent");

                this._stopNonBlockingProcess();

                setTimeout(() => {
                    Debug.GUI.TableView && console.log(this.name, "destroyContent - async");

                    super._destroyContent(...arguments);

                    Debug.GUI.TableView && console.log(this.name, "destroyContent < async passed");
                }, ASYNC_DELAY);
            }

            setUpSections(sections) {
                Debug.GUI.TableView && console.log(this.name, "setUpSections"); // create sections

                var all = [true]; // add a true to proceed if we have no sections!

                var loopFn = function loopFn(sectionIdx, sectionFragment, innerDef) {
                    var prms = this.setUpSection(sectionIdx, sectionFragment);
                    Q(prms).done(innerDef.resolve.bind(innerDef), innerDef.reject.bind(innerDef), innerDef.notify.bind(innerDef));
                }.bind(this);

                var fns = [],
                    cntr = 1;

                for (var sectionIdx = 0; sectionIdx < sections; sectionIdx++) {
                    this.table[sectionIdx] = []; // reset this section! we're reloading the whole thing anyway!

                    var sectionFragment = this.getSectionTemplate(sectionIdx);

                    if ((cntr += this._getNumberOfRowsInSection(sectionIdx)) < ASYNC_CONTENT_LIMIT) {
                        Debug.GUI.TableView && console.log("     section " + sectionIdx + " -- sync");
                        all.push(this.setUpSection(sectionIdx, sectionFragment));
                    } else {
                        Debug.GUI.TableView && console.log("     section " + sectionIdx + " -- ASYNC"); // perform async

                        var innerDef = Q.defer();
                        fns.push(loopFn(sectionIdx, sectionFragment, innerDef));
                        all.push(innerDef.promise);
                    }

                    cntr++; // add 1 per section.
                }

                this._processNonBlocking(fns);

                Debug.GUI.TableView && console.log(this.name, "setUpSections < passed");
                return Q.all(all);
            }

            // -----------------------
            // Private methods
            // -----------------------

            /**
             * Will start a non blocking operation loop that processes the functions provided by FNS one after another.
             * It'll also create a cancel-Object that will be used to stop this loop when needed.
             * @param fns   the array of functions to be processed one after another.
             * @private
             */
            _processNonBlocking(fns) {
                this._cancelNonBlockingProcessObj = {
                    cancel: false
                };
                nonBlockLoop(fns, this._cancelNonBlockingProcessObj);
            }

            /**
             * Stop the nonBlockingProcess using its cancel reference that was stored before.
             * @private
             */
            _stopNonBlockingProcess() {
                if (this._cancelNonBlockingProcessObj) {
                    this._cancelNonBlockingProcessObj.cancel = true;
                }
            }

            /**
             * Determines whether or not reloading the content is to be processed async.
             * @returns {boolean}
             * @private
             */
            _needsAsync() {
                var i = -1,
                    limitCntr;
                var sections = this.dataSource.numberOfSections ? this.dataSource.numberOfSections.call(this.dataSource, this) : 1;
                limitCntr = sections;

                while (limitCntr < ASYNC_CONTENT_LIMIT && ++i < sections) {
                    limitCntr += this._getNumberOfRowsInSection(i);
                }

                return limitCntr >= ASYNC_CONTENT_LIMIT;
            }

            _asyncReload(def) {
                this.hideSubview(this.waitingView);
                Debug.GUI.TableView && console.log(this.name, "reload - async");
                var promise = super.reload(...arguments);
                promise.done(function () {
                    Debug.GUI.TableView && console.log(this.name, "reload - async - done");
                    def.resolve();
                }.bind(this), function () {
                    Debug.GUI.TableView && console.log(this.name, "reload - async - reject");
                    def.reject();
                }.bind(this), function () {
                    Debug.GUI.TableView && console.log(this.name, "reload - async - notify");
                    def.notify();
                }.bind(this));
            }

        }

        GUI.AsyncTableView = AsyncTableView;
    }
    return GUI;
}(window.GUI || {});
