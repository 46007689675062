'use strict';

define(["QrCodeView"], function (QRCodeView) {
    return class QrCodeResultScreen extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div/>'));
            this.type = details.type;
            this.text = details.text;
            this.mainText = details.mainText;
            this.subText = details.subText;
            this._addLogo = true; // Clone the object to not change any property of the active miniserver

            this._miniserver = cloneObject(ActiveMSComponent.getActiveMiniserver());

            if (this.type === Interaction.QR.SHARE_MINISERVER) {
                this._addUser = false;
                this._addPassword = false;
            }
        }

        viewDidLoad() {
            this.qrCodeView = new QRCodeView();
            this.prependSubview(this.qrCodeView);
            return super.viewDidLoad(...arguments);
        }

        titleBarText() {
            var title = "";

            if (this.type === Interaction.QR.WRITE_COMMAND) {
                title = _('command');
            } else if (this.type === Interaction.QR.WRITE_SCENE) {
                title = _("scenes.name");
            } else if (this.type === Interaction.QR.WRITE_POSITION) {
                title = _('app-position');
            } else if (this.type === Interaction.QR.SHARE_MINISERVER) {
                title = _('miniserver');
            }

            return title;
        }

        closeAction() {
            super.closeAction();

            if (this.type !== Interaction.QR.SHARE_MINISERVER && this.type !== Interaction.QR.WRITE_SCENE) {
                NavigationComp.showState(ScreenState.QRCode);
            }
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        getURL() {
            if (this.type === Interaction.QR.WRITE_COMMAND) {
                return "QRCommand";
            } else if (this.type === Interaction.QR.WRITE_SCENE) {
                return "QRScene";
            } else if (this.type === Interaction.QR.WRITE_POSITION) {
                return "QRPosition";
            } else if (this.type === Interaction.QR.SHARE_MINISERVER) {
                return "ShareMiniserver";
            }
        }

        reloadTable() {
            this.tableContent = [];

            if (this.type === Interaction.QR.SHARE_MINISERVER) {
                this._buildString(this._addUser, this._addPassword);
            }

            this.qrCodeView.draw(this.text, this._addLogo ? Icon.Menu.APP_ICON : null).done(function (data) {
                this._qrData = data;
            }.bind(this));

            this.tableContent = this.getTableContentForType(this.type);

            return super.reloadTable(...arguments);
        }

        getTableContentForType(type) {
            let tableContent = [],
                params = getURIParameters(this.text);

            switch (type) {
                case Interaction.QR.WRITE_COMMAND:
                case Interaction.QR.WRITE_SCENE:
                    tableContent = this.getCommandTableContent(params);
                    break;
                case Interaction.QR.WRITE_POSITION:
                    tableContent = this.getPositionTableContent(params);
                    break;
                case Interaction.QR.SHARE_MINISERVER:
                    tableContent = this.getMiniserverTableContent(params);
                    break;
            }

            tableContent[0].rows.push(
                {
                    type: GUI.TableViewV2.CellType.SWITCH,
                    content: {
                        title: _('qr.show-app-logo'),
                        active: this._addLogo
                    },
                    onSwitchChanged: function onSwitchChanged(value, section, row, tableView) {
                        this._addLogo = value;
                        this.reloadTable();
                    }.bind(this)
                },
                {
                    content: {
                        title: _('qr.share'),
                        titleColor: window.Styles.colors.brand,
                        clickable: true
                    },
                    action: function (cell) {
                        let name = this._getFilename(),
                            base64 = this._qrData.slice(22);

                        exportBase64File(name + ".png", base64, "image/png");
                    }.bind(this)
                }
            )

            return tableContent;
        }

        getCommandTableContent(params) {
            let section = {
                    rows: []
                },
                controlUUID = UrlHelper.getControlUUIDFromCommandString(params.cmd),
                control = ActiveMSComponent.getControlByUUID(controlUUID),
                cmd = UrlHelper.getCommandFromCommandString(params.cmd),
                customGroupTitles = ActiveMSComponent.getStructureManager().getCustomGroupTitles();

            if (!control) {
                // might be an audioZone
                control = MediaServerComp.getZoneFromCommand(controlUUID, cmd);

                if (!control) {
                    control = {
                        name: _('unknown-object')
                    };
                } else {
                    // was audioZone
                    cmd = decodeURIComponent(cmd);
                }
            }

            if (control.isAutomaticScene) {
                section.rows = [
                    {
                        type: GUI.TableViewV2.CellType.GENERAL,
                        content: {
                            title: _("search.controltype.automaticscene"),
                            disclosureText: control.getName()
                        }
                    }
                ];
            } else {
                section.rows = [
                    {
                        type: GUI.TableViewV2.CellType.GENERAL,
                        content: {
                            title: _("command"),
                            disclosureText: cmd
                        }
                    },
                    {
                        type: GUI.TableViewV2.CellType.GENERAL,
                        content: {
                            title: _("search.function"),
                            disclosureText: control.name
                        }
                    }
                ]
                if (control.getRoom()) {
                    section.rows.push({
                        type: GUI.TableViewV2.CellType.GENERAL,
                        content: {
                            title: customGroupTitles[GroupTypes.ROOM],
                            disclosureText: control.getRoom().name
                        }
                    });
                }
                if (control.getCategory()) {
                    section.rows.push({
                        type: GUI.TableViewV2.CellType.GENERAL,
                        content: {
                            title: customGroupTitles[GroupTypes.CATEGORY],
                            disclosureText: control.getCategory().name
                        }
                    });
                }
            }

            return [
                section
            ]
        }

        getPositionTableContent(params) {
            let locInfo = UrlHelper.getLocationInfoFromLocationString(params.loc),
                customGroupTitles = ActiveMSComponent.getStructureManager().getCustomGroupTitles(),
                section = {
                    rows: []
                };

            if (locInfo.control) {
                let control = ActiveMSComponent.getControlByUUID(locInfo.control);
                section.rows.push({
                    type: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        title: _("search.function"),
                        disclosureText: control.name
                    }
                });
                if (control.getRoom()) {
                    section.rows.push({
                        type: GUI.TableViewV2.CellType.GENERAL,
                        content: {
                            title: customGroupTitles[GroupTypes.ROOM],
                            disclosureText: control.getRoom().name
                        }
                    });
                }
                if (control.getCategory()) {
                    section.rows.push({
                        type: GUI.TableViewV2.CellType.GENERAL,
                        content: {
                            title: customGroupTitles[GroupTypes.CATEGORY],
                            disclosureText: control.getCategory().name
                        }
                    });
                }
            } else if (locInfo.room) {
                let room = ActiveMSComponent.getStructureManager().getGroupByUUID(locInfo.room, GroupTypes.ROOM);
                section.rows.push({
                    type: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        title: _('app-position'),
                        disclosureText: room.name
                    }
                });
            } else if (locInfo.category) {
                let cat = ActiveMSComponent.getStructureManager().getGroupByUUID(locInfo.category, GroupTypes.CATEGORY);
                section.rows.push({
                    type: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        title: _('app-position'),
                        disclosureText: cat.name
                    }
                });
            } else {
                section.rows.push({
                    type: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        title: _('app-position'),
                        disclosureText: params.loc
                    }
                });
            }

            return [
                section
            ]
        }

        getMiniserverTableContent(params) {
            let section = {
                rows: [
                    {
                        content: {
                            title: this._miniserver.msName,
                            disclosureText: params.mac || params.host
                        }
                    }
                ]
            };

            if (params.usr) {
                section.rows.push({
                    type: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        title: _('user'),
                        disclosureText: params.usr
                    }
                });
            }

            section.rows.push(
                {
                    type: GUI.TableViewV2.CellType.SWITCH,
                    content: {
                        title: _('save-username'),
                        active: this._addUser
                    },
                    onSwitchChanged: function onSwitchChanged(value, section, row, tableView) {
                        this._addUser = value;

                        if (!value) {
                            this._addPassword = false;
                        }
                        this.reloadTable();
                    }.bind(this)
                },
                {
                    type: GUI.TableViewV2.CellType.SWITCH,
                    content: {
                        title: _('save-password'),
                        active: this._addPassword
                    },
                    onSwitchChanged: function onSwitchChanged(value, section, row, tableView, cell) {
                        // request the pw, if he is sure!
                        if (value) {
                            var content = {
                                title: _('qr.share-password-warning'),
                                buttonOk: _('save-password'),
                                buttonCancel: true,
                                icon: Icon.CAUTION,
                                color: window.Styles.colors.red
                            };
                            return NavigationComp.showPopup(content).then(() => {
                                return NavigationComp.requestPasswordFromCurrentUser().then(password => {
                                    this._addPassword = true;
                                    this._addUser = true;
                                    this._miniserver.password = password;
                                    this.reloadTable()
                                });
                            });
                        } else {
                            this._addPassword = false;
                            this.reloadTable()
                        }
                    }.bind(this)
                }
            )

            return [
                section
            ]
        }

        // Private methods
        _buildString(withUsr, withPwd) {
            let host = ActiveMSComponent.getCurrentUrl(true),
                ms = {
                    host: host
                };

            if (withUsr) {
                ms.usr = this._miniserver.activeUser;
                let pwd;

                try {
                    pwd = VendorHub.Crypto.decrypt(this._miniserver.password);

                    if (pwd.length === 0) {
                        pwd = this._miniserver.password;
                    }
                } catch (e) {
                    console.error(e);
                    pwd = this._miniserver.password;
                }

                if (withPwd || pwd === "") {
                    // if password is empty, add to qr code!
                    ms.pwd = pwd;
                }
            }

            this.text = UrlHelper.createURLStart(ms);
        }

        _getFilename() {
            return `Loxone QR Code (${this._miniserver.msName})`;
        }

    };
});
