import globalStyles from "GlobalStyles";
import {
    navigatorConfig,
    LxReactTableView,
    LxReactImageView,
    LxBackgroundComp,
    LxReactWaitingView,
    useBackNavigation
} from "LxComponents";
import {useMemo} from "react";
import {ScrollView} from "react-native";
import {useNavigation} from "@react-navigation/native";
import useControlHistory from "./useControlHistory";
import ControlHistoryCell from "./ControlHistoryCell";

function LxControlHistoryScreen({route}) {
    const navigation = useNavigation();
    const {entries, loading, reloadFn, maxEntries} = useControlHistory( (route && route.params) ? route.params.control : null); // either via context or via props

    useBackNavigation(() => {
        navigation.goBack();
    }, []);

    navigation.setOptions(
        navigatorConfig({
            title: _('history'),
            rightActions: [{
                action: ({dimensions, props, key}) => {
                    return <LxReactImageView source={Icon.Buttons.REFRESH} containerStyle={styles.refreshIcon}
                                             partFillColors={styles.refreshIconParts} forTitleBar={true}/>
                }
            }],
            onRightAction: reloadFn,
        })
    );

    const descriptionArgs = () => {
        // pass as number & count due to a mistake in the translations, where both placeholders where used
        return {count: maxEntries, number: maxEntries }
    }

    const historyContent = useMemo(() => {
        let result = entries.map((entry) =>{
            return {
                headerStrongTitle: entry.date,
                rows: [{
                    type: "CustomCell",
                    uuid: "" + entry.date.hashCode(), // just to ensure the keyExtractor is satisfied
                    comp: ControlHistoryCell,
                    props: {
                        entries: entry.entries
                    }
                }]
            };
        });

        if (result.length > 0) {
            result[result.length - 1].footerTitle = _("control.history.footer-description", descriptionArgs());
        }
        return result;
    }, [entries]);

    return <>
        {
            loading ?
                (<LxReactWaitingView />) :
                (
                historyContent && historyContent.length > 0 ?
                    (<ScrollView style={globalStyles.customStyles.screenContent}>
                        <LxReactTableView tableContent={historyContent} />
                    </ScrollView>)
                    :
                    <LxBackgroundComp
                        title={_("controls.tracker.no-entries")}
                        subTitle={_("control.history.empty-description", descriptionArgs())}
                        message={_("reload")} onMessageClickedFn={reloadFn}/>

            )
        }
    </>
}
const styles = {
    refreshIcon: {
        height: globalStyles.sizes.icons.big,
        width: globalStyles.sizes.icons.big,
        borderRadius: "50%"
    },
    refreshIconParts: {
        circle: globalStyles.colors.stateInactiveB,
        path: globalStyles.colors.text.secondary
    }
}

export default LxControlHistoryScreen;