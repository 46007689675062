import PropTypes from "prop-types";
import { useState, useEffect, useRef, useMemo } from 'react';
import {
    LxBackgroundComp,
    navigatorConfig,
    App,
    useHD
} from "LxComponents";
import Icons from "IconLib";
import {useNavigation} from "@react-navigation/native";

function LxPermissionHandler(props) {
    const isHD = useHD();

    let navigation;

    try {
        navigation = useNavigation()
    } catch (e) {
        navigation = App.navigationRef;
    }

    const [hasAllPermissionsGranted, setHasAllPermissionsGranted] = useState(false),
        requiredPermissions = props.requiredPermissions,
        iconStyle = {
            height: Styles.sizes.biggest,
            width: Styles.sizes.biggest
        };
    const _unregPermFnRef = useRef(null);
    const _connReadyDefRef = useRef(null);

    useEffect(() => {
        let deRegs = [
            CompChannel.on(CCEvent.ConnClosed, function (event) {
                _connReadyDefRef.current = Q.defer();
                setHasAllPermissionsGranted(false);
            }.bind(this)),
            CompChannel.on(CCEvent.ConnEstablished, function (event) {
                _connReadyDefRef.current.resolve();
                _grantPermissions();
            }.bind(this))
        ];
        _grantPermissions();
        _connReadyDefRef.current = Q.defer();
        _connReadyDefRef.current.resolve();

        return () => {
            _unregisterPermissions();
            deRegs.forEach(deReg => deReg());
        }
    }, []);

    const permissionList = useMemo(() => {
        if (Array.isArray(requiredPermissions)) {
            return requiredPermissions;
        } else {
            return [requiredPermissions];
        }
    }, [props.requiredPermissions])

    const title = useMemo(() => {
        return _('fingerprint.access-denied.unlock');
    }, [])

    const message = useMemo(() => {
        let result;
        if (nullEmptyString(props.requestingName)) {
            result = _("permission.message.universal", { feature: props.requestingName });
        } else {
            result = _('permission.message.default');
        }

        if (Debug.ShowRequestedPermissions) {
            result += "\n\n" + _("permission.list-permissions");
            result += permissionList.map(perm => SandboxComponent.getPermissionFriendlyName(perm)).join(", ");
        }

        return result;
    }, [props.requestingName])

    const _grantPermissions = () => {
        let requiredPermission = _getPermissionMask();
        Debug.Tokens && console.log(LxPermissionHandler.name, "_grantPermissions called");

        BiometricHelper.unlockApp().then(() => {
            Debug.Tokens && console.log(LxPermissionHandler.name, "  unlockApp resolved");

            // Wait for an active connection, as we can't get permissions without an active connection
            return _connReadyDefRef.current.promise.depActiveMsThen(() => {
                Debug.Tokens && console.log(LxPermissionHandler.name, "  _connReadyDefRef resolved");
                Debug.Tokens && console.log(LxPermissionHandler.name, "  getPermission called");

                return SandboxComponent.getPermission(requiredPermission, message).then((res) => {
                    Debug.Tokens && console.log(LxPermissionHandler.name, "  getPermission resolved");

                    _registerPermissions();
                    setHasAllPermissionsGranted(true);
                });
            });
        });
    }

    const _registerPermissions = () => {
        // convert to objects, registerForPermissions expects objects!
        let permissions = _getPermissions().map((permission) => {
            return {
                permission: permission,
                revoke: true
            }
        });

        if (permissions && permissions.length > 0) {
            _unregPermFnRef.current = SandboxComponent.registerForPermissions(permissions);
        }
    }

    const _unregisterPermissions = () => {
        if (_unregPermFnRef.current) {
            _unregPermFnRef.current();
            _unregPermFnRef.current = null;
        }
    }

    const _getPermissionMask = () => {
        return _getPermissions().reduce(function (sum, right) {
            return sum | right; // Add the permissions to one bitmap
        });
    }

    const _getPermissions = () => {
       return permissionList;
    }

    // also check in required tokens, to prevent screen flickering
    if (hasAllPermissionsGranted || CommunicationComponent.getToken(_getPermissionMask())) {
        if (props.component) {
            return <props.component {...(props.compProps || {})}/>;
        } else {
            return props.children;
        }
    } else {
        navigation.setOptions(
            navigatorConfig({
                navigation,
                animationType: isHD ? AnimationType.MODAL : AnimationType.HD_MODAL_FULLSCREEN,
                title: props.requestingName || _('control.lock.locked-title'),
            })
        );
        return <LxBackgroundComp
            onTitleClickedFn={_grantPermissions}
            message={message}
            title={title}
            icon={
                <Icons.Lock width={iconStyle.height} height={iconStyle.width} fill={Styles.colors.red}/>
            }
        />
    }
}

LxPermissionHandler.propTypes = {
    requiredPermissions: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.number)
    ]).isRequired,
    requestingName: PropTypes.string,
    component: PropTypes.func,
    compProps: PropTypes.object
}


export default LxPermissionHandler;
