import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Lock(props) {
    return (
        <Svg isreact="true"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 50 50"
            xmlSpace="preserve"
            enableBackground="new 0 0 50 50"
            {...props}
        >
            <Path d="M41.2 17.8h-3.4V9.4c0-3.4-2.8-6.2-6.2-6.2H18.5c-3.4 0-6.2 2.8-6.2 6.2v8.4H8.8c-1.1 0-2 .9-2 2v25c0 1.1.9 2 2 2h32.4c1.1 0 2-.9 2-2v-25c0-1.1-.9-2-2-2zM15.9 9.4c0-1.4 1.2-2.6 2.6-2.6h13.1c1.4 0 2.6 1.2 2.6 2.6v8.4H15.9V9.4zm23.7 33.8H10.4V21.5h29.1v21.7z" />
        </Svg>
    )
}

export default Lock
