'use strict';

define(["ArrangementSettingsScreen", //"ExpertModeSettingsScreen",
    "NotificationsSettingsScreen"], function (ArrangementSettingsScreen, //ExpertModeSettingsScreen,
                                                                      NotificationsSettingsScreen) {
    return class MiniserverSettingsScreen extends GUI.TableViewScreen {
        constructor() {
            super($('<div class="miniserver-settings-screen" />'));
            this.isWebinterface = PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface;
        }

        updateView() {
            // update the content, some content might have changed (eg. ExpertMode)
            this.reloadTable();
        }

        titleBarText() {
            return _('menu.miniserver.settings', {
                msName: ActiveMSComponent.getActiveMiniserver().msName
            });
        }

        reloadTable() {
            var generalSection = {
                    footerTitle: _('menu.settings.per-device-only'),
                    rows: SandboxComponent.getGeneralMiniserverSettings(this._showState.bind(this))
                },
                userSection = {
                    headerTitle: _('menu.current-user'),
                    rows: SandboxComponent.getMiniserverUserSettings(this._showState.bind(this))
                };
            this.tableContent = [generalSection, userSection];
            generalSection.rows.sort(function (a, b) {
                return a.content.title.localeCompare(b.content.title);
            });
            return super.reloadTable(...arguments);
        }

        _showState(state, details) {
            this.ViewController.showState(state, null, details);
        }

    };
});
