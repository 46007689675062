import * as React from "react"
import Svg, { Path } from "react-native-svg"

function AwningRetract(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path d="M1.886.342A.5.5 0 012.36 0H4.5a.5.5 0 01.5.5V3H1L1.886.342zM3 6a2 2 0 002-2H1a2 2 0 002 2zm8-2a2 2 0 11-4 0h4zm6 0a2 2 0 11-4 0h4zM7 .5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5V3H7V.5zm6 0a.5.5 0 01.5-.5h3a.5.5 0 01.5.5V3h-4V.5zM21 6a2 2 0 002-2h-4a2 2 0 002 2zm-1.5-6a.5.5 0 00-.5.5V3h4L22.114.342A.5.5 0 0021.64 0H19.5zM9.11 15.812a.5.5 0 00.702.078l1.688-1.35v5.96a.5.5 0 001 0v-5.96l1.688 1.35a.5.5 0 10.624-.78l-2.497-1.998a.498.498 0 00-.632.001L9.188 15.11a.5.5 0 00-.078.702zM2 23a.5.5 0 000 1h20a.5.5 0 000-1H2z" />
        </Svg>
    )
}

export default AwningRetract
