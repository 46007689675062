'use strict';

define(['IntercomControlEnums'], function (IntercomControlEnums) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var MIC_MIN = 0,
        MIC_MAX = 2,
        // 1 == regular, 0 == mute, 2 == 200%;
        SPKR_MIN = 0,
        SPKR_MAX = 2;
    return class IntercomControlContentSettings extends Controls.ControlContent {
        constructor(details) {
            super(details);
            this.sipUUID = this.control.uuidAction;
            this.currSettings = SipAdapter.loadSIPSettings(this.sipUUID);
            this.aboutSection = 0;
            this.sliderSection = 1;
            this.codecSection = 2;
            this.resetSection = 3;
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                this.tableView = new GUI.EditableTableView(this, this);
                this.appendSubview(this.tableView);
                return this._reloadTable();
            }.bind(this));
        }

        viewWillDisappear() {
            SipAdapter.storeSIPSettings(this.sipUUID, this.currSettings);
            return super.viewWillDisappear(...arguments);
        }

        getURL() {
            return "IntercomSettings";
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarText() {
            return _("settings");
        }

        // TableViewDataSource Methods
        styleForTableView(tableView) {
            return HD_APP ? TableViewStyle.GROUPED : null;
        }

        numberOfRowsInSection(section, tableView) {
            if (section === this.aboutSection) {
                return 1; // about intercom cell
            } else if (section === this.sliderSection) {
                return 2; // mic and speaker level
            } else if (section === this.codecSection) {
                return this.currSettings.audioCodecs.length; // codecs
            } else {
                return 1; // reset btn
            }
        }

        numberOfSections(tableView) {
            if (this._hasChanges()) {
                return 4;
            }

            return 3;
        }

        titleForHeaderInSection(section, tableView) {
            if (section === this.codecSection) {
                return _("controls.intercom.settings.audio-codecs") + " (Advanced)";
            }
        }

        cellTypeForCellAtIndex(section, row, tableView) {
            if (section === this.sliderSection) {
                return GUI.TableViewV2.CellType.SLIDER;
            }

            return GUI.TableViewV2.CellType.GENERAL;
        }

        // TableViewCellDataSource
        contentForCell(cell, section, row, tableView) {
            if (section === this.aboutSection) {
                return {
                    title: _("controls.intercom.about.title"),
                    disclosureIcon: true
                };
            } else if (section === this.sliderSection) {
                // levels
                if (row === 0) {
                    return {
                        title: _("controls.intercom.settings.mic"),
                        minValue: 0,
                        maxValue: 100,
                        minIconSrc: "resources/Images/Controls/AudioZone/icon-interpret.svg",
                        maxIconSrc: "resources/Images/Controls/AudioZone/icon-interpret.svg",
                        step: 5,
                        value: this._valueToPercent(this.currSettings.micLevel, MIC_MIN, MIC_MAX),
                        format: "%.0f%"
                    };
                } else if (row === 1) {
                    return {
                        title: _("controls.intercom.settings.speaker"),
                        minValue: 0,
                        maxValue: 100,
                        minIconSrc: "resources/Images/Controls/AudioZone/icon-vol-down.svg",
                        maxIconSrc: "resources/Images/Controls/AudioZone/icon-vol-up.svg",
                        step: 5,
                        value: this._valueToPercent(this.currSettings.speakerLevel, SPKR_MIN, SPKR_MAX),
                        format: "%.0f%"
                    };
                }
            } else if (section === this.codecSection) {
                var subtitle = _("controls.daytimer.op-mode.priority", {
                    priority: row + 1
                });

                if (row === 0) {
                    subtitle += " (" + _("adjective.highest") + ")";
                } else if (row === this.currSettings.audioCodecs.length - 1) {
                    subtitle += " (" + _("adjective.lowest") + ")";
                }

                return {
                    title: this.currSettings.audioCodecs[row].identifier,
                    subtitle: subtitle,
                    // the lowest should be 0
                    clickable: false
                };
            } else {
                return {
                    title: _("controls.intercom.settings.reset"),
                    leftIconSrc: "resources/Images/Controls/Hourcounter/reset.svg",
                    leftIconColor: window.Styles.colors.red,
                    clickable: true
                };
            }
        }

        // TableViewDelegate Methods
        didSelectCell(cell, section, row, tableView) {
            if (section === this.resetSection) {
                this._presentResetConfirmation();
            } else if (section === this.aboutSection) {
                this._presentAboutIntercomScreen();
            }
        }

        didMoveCell(section, row, tableView, cell, oldIdx, newIdx) {
            Debug.Control.Intercom.General && console.log(this.name, "didMoveCell from " + oldIdx + " to " + newIdx);
            this.currSettings.audioCodecs.move(oldIdx, newIdx); // TODO change to selective reloading when it's working with adding new sections!

            this._reloadTable();
        }

        sliderDragged(cell, section, row, tableView, value) {
            this._updateLevelForRow(row, value);
        }

        sliderClicked(cell, section, row, tableView, value) {
            this._updateLevelForRow(row, value);
        }

        /**
         * Will decide what level is affected, adopts its range (percent to a value) and store it.
         * @param row       what row did the value come from
         * @param value     the value as percentage (0-100).
         * @private
         */
        _updateLevelForRow(row, value) {
            if (row === 0) {
                // mic
                this.currSettings.micLevel = this._percentToValue(value, MIC_MIN, MIC_MAX);
            } else if (row === 1) {
                this.currSettings.speakerLevel = this._percentToValue(value, SPKR_MIN, SPKR_MAX);
            }

            SipAdapter.setLevels(this.currSettings.micLevel, this.currSettings.speakerLevel);
        }

        _resetSettings() {
            this.currSettings = SipAdapter.loadSIPSettings();
            SipAdapter.storeSIPSettings(this.sipUUID);

            this._reloadTable();
        }

        _hasChanges() {
            return JSON.stringify(this.currSettings) !== JSON.stringify(SipAdapter.loadSIPSettings());
        }

        _presentResetConfirmation() {
            var content = {
                title: _("controls.intercom.settings.reset.confirmation"),
                icon: Icon.INFO,
                color: window.Styles.colors.red,
                buttonOk: true,
                buttonCancel: true
            };
            NavigationComp.showPopup(content).then(function () {
                // reset all settings
                this._resetSettings();
            }.bind(this), function () {// cancel, nothing to do
            });
        }

        _presentAboutIntercomScreen() {
            this.ViewController.showState(IntercomControlEnums.ScreenState.ABOUT, null, {
                control: this.control
            });
        }

        _reloadTable() {
            return this.tableView.reload().then(function () {
                var states = this.control.getStates();

                if (states) {
                    this.tableView.setEditingModeForSection(this.codecSection, true, false, !states.isTalking);
                }
            }.bind(this));
        }

        /**
         * Computes the percentage of an value based on min and max values.
         * Keeps the range in mind, limits the percentage between 0 and 100
         * @param value     the value that needs to be converted.
         * @param min       the value for 0%
         * @param max       the value for 100%
         * @returns {number}    the percentage of the value (0-100).
         * @private
         */
        _valueToPercent(value, min, max) {
            var offset = value - min,
                delta = max - min,
                percent = offset / delta * 100;
            percent = Math.max(0, percent);
            percent = Math.min(100, percent);
            return percent;
        }

        /**
         * Takes a percentage and computes what value it represents based on
         * limits for 0% and 100%.
         * @param percent   expects a value between 0 and 100
         * @param min       the value for 0%
         * @param max       the value for 100%
         * @returns {number}    the value computed based on the input.
         * @private
         */
        _percentToValue(percent, min, max) {
            var delta = max - min,
                offset = delta * percent / 100,
                value = min + offset;
            return value;
        }

    };
});
