'use strict';

/**
 * AppLockedScreen
 */

class AppLockedScreen extends GUI.StaticScreenBase {
    //region Static
    static Template = function template() {
        var getTemplate = function getTemplate() {
            return $('' + '<div class="app-locked-screen__container">' + '   <div class="container__unlock-button container-button">' + '       <div class="container-button__title">' + _("fingerprint.access-denied.unlock") + '</div>' + '   </div>' + '</div>');
        };

        return {
            getTemplate: getTemplate
        };
    }(); //endregion Static

    constructor(details) {
        super();
    }

    viewDidLoad() {
        super.viewDidLoad(...arguments);
        this.element.addClass("app-locked-screen");
        this.element.append(AppLockedScreen.Template.getTemplate());
        this.authMethod = BiometricHelper.getBiometricTypePhrase();
        this.setUpComfortUI(true);
        this.setTitle(_("fingerprint.access-denied.title"));
        this.setSubtitle(_("fingerprint.access-denied.desc", {
            authMethod: this.authMethod
        }));
        this.elements.unlockButtonElm = this.element.find(".container__unlock-button");
        this.unlockButton = new GUI.LxButton(this, this.elements.unlockButtonElm[0]);
        this.addToHandledSubviews(this.unlockButton);

        this.unlockButton.onButtonTapped = function () {
            BiometricHelper.unlockApp();
        };
    }

    viewDidAppear() {
        super.viewDidAppear(...arguments);

        this._updateUI(this.isNotWaiting);
    }

    getAnimation() {
        return AnimationType.NONE;
    }

    hasHistory() {
        return false;
    }

    setWaiting() {
        this.isNotWaiting = false;

        this._updateUI();

        return this;
    }

    setLocked() {
        this.isNotWaiting = true;

        this._updateUI();

        return this;
    }

    // prevent showing the back button
    getLeftIcon() {
        return null;
    }

    leftAction() {// Nothing to do, we can't and won't navigate back
    }

    // Private
    _updateUI() {
        if (this._viewDidLoadPassed) {
            this.setIcon(Icon.LOCK);

            if (this.isNotWaiting) {
                this.setTitle(_("fingerprint.access-denied.title"));
                this.setSubtitle(_("fingerprint.access-denied.desc", {
                    authMethod: this.authMethod
                }));
            } else {
                this.setTitle(NBR_SPACE);
                this.setSubtitle(NBR_SPACE);
            }

            this.toggleSubview(this.unlockButton, this.isNotWaiting);
        }
    }

}

GUI.AppLockedScreen = AppLockedScreen;
