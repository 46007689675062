'use strict';

define(['AutopilotCommunicator', 'AutopilotUtility', 'AutopilotEnums'], function (AutopilotCommunicator, AutopilotUtility, AutopilotEnums) {
    return class AutopilotHistoryScreen extends GUI.Screen {
        constructor() {
            super($('<div class="autopilot-history-screen" />'));
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.elements = {};
            this.tableView = new GUI.TableViewV2(this, this);
            this.appendSubview(this.tableView);
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);
            SandboxComponent.registerForStateChangesForUUID('autopilotGenerator', this, this._receivedStates.bind(this));
        }

        viewWillDisappear() {
            SandboxComponent.unregisterForStateChangesForUUID('autopilotGenerator', this);
            super.viewWillDisappear(...arguments);
        }

        viewDidDisappear(viewRemainsVisible) {
            Debug.Autopilot.General && console.log("AutopilotHistoryScreen viewDidDisappear");
            super.viewDidDisappear(viewRemainsVisible);
        }

        getURL() {
            return "History";
        }

        titleForHeaderInSection(section) {
            return this.history[section].formatedDate;
        }

        // TABLE VIEW DELEGATES
        styleForTableView(tableView) {
            return TableViewStyle.GROUPED;
        }

        numberOfSections() {
            return this.history.length;
        }

        numberOfRowsInSection(section) {
            return this.history[section].entries.length;
        }

        cellTypeForCellAtIndex(section, row) {
            return GUI.TableViewV2.CellType.Special.AUTOPILOT_HISTORY_CELL;
        }

        contentForCell(cell, section, row) {
            var ruleEntry = this.history[section].entries[row];
            var content = {
                time: ruleEntry.timestamp.format(LxDate.getTimeFormat(true)),
                title: ruleEntry.title
            };

            var rule = this._findRule(ruleEntry.uuid);

            if (rule) {
                content.subtitle = AutopilotUtility.createDescriptionArrayForRule(rule) || "";
                content.clickable = true;
            } else {
                content.subtitle = _('autopilot.not-existing');
            }

            return content;
        }

        didSelectCell(cell, section, row) {
            var ruleEntry = this.history[section].entries[row],
                rule = this._findRule(ruleEntry.uuid);

            if (rule) {
                NavigationComp.showState(AutopilotEnums.ScreenStates.AutopilotRuleScreen, {
                    rule: rule,
                    editMode: true
                });
            }
        }

        _receivedStates(states) {
            Debug.Autopilot.StateContainer && console.log("AutopilotRulesScreen received states");
            var historyDidUpdate = states.historyEntries.length > 0 && (!this.history || this.history.length > 0 && states.lastEntry.time !== this.history[0].time);
            this.history = states.historyEntries;

            if (this.history.length === 0) {
                this.tableView.setBackgroundView(new GUI.BackgroundView(Icon.Alarm.CLOCK, _('autopilot.no-history'), _('autopilot.no-history.description')));
            } else {
                this.tableView.setBackgroundView(null);
            }

            this._reloadContent(historyDidUpdate);
        }

        _reloadContent(historyDidUpdate) {
            AutopilotCommunicator.getRules(!!this.rulesDict).done(function (rulesDict) {
                this.rulesDict = rulesDict;

                if (historyDidUpdate) {
                    this.processWhenVisible(this.tableView.reload.bind(this.tableView));
                }
            }.bind(this), function () {
                if (historyDidUpdate) {
                    this.processWhenVisible(this.tableView.reload.bind(this.tableView));
                }
            }.bind(this));
        }

        _findRule(ruleUUID) {
            for (var i = 0; i < this.rulesDict.length; i++) {
                if (this.rulesDict[i].id === ruleUUID) {
                    return this.rulesDict[i];
                }
            }

            return false;
        }

    };
});
