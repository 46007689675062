import {
    LxReactTableView,
    navigatorConfig
} from "LxComponents"
import { useState, useEffect, useRef } from "react";
import { useUpdate } from "ahooks";

function DeviceFavoriteSettingsScreen({navigation}) {
    let tempTableContent,
        tmpCurrentSortingMode;

    const ogCurrentSortingMode = useRef(ActiveMSComponent.getCurrentSortingMode())
    const updateUI = useUpdate();

    if (ogCurrentSortingMode.current === undefined) {
        if (ActiveMSComponent.getDeviceFavoritesActive()) {
            tmpCurrentSortingMode = Sorting.DEVICE_SORTING;
        } else {
            tmpCurrentSortingMode = Sorting.STANDARD_SORTING;
        }
    }

    navigation.setOptions(
        navigatorConfig({
            title: _("device-favorites.menu.title"),
            animationType: AnimationType.MODAL
        })
    )


    const [currentSortingMode, setCurrentSortingMode] = useState(ogCurrentSortingMode.current || tmpCurrentSortingMode);
    const [userStructure, setUserStructure] = useState({});
    const sortingRef = useRef(currentSortingMode);

    const _getContent = () => {
        let userSortingCell = _createSortingCell(_("new-sorting.user-specific-title"), _("new-sorting.user-specific-description"), Sorting.USER_SORTING),
            deviceSortingCell = _createSortingCell(_("new-sorting.device-specific-title"), _("new-sorting.device-specific-description"), Sorting.DEVICE_SORTING),
            standardSortingCell = _createSortingCell(_("new-sorting.standard-specific-title"), _("new-sorting.standard-specific-description", {
                "expertModeLite": _("adopt-ui-settings")
            }), Sorting.STANDARD_SORTING),
            rows = [ userSortingCell, deviceSortingCell, standardSortingCell ],
            resetButtonRows = [];

        resetButtonRows.pushObject(_createResetButton());

        return [
            {
                rows: rows.concat(resetButtonRows)
            }
        ];
    }

    const _createSortingCell = (title, description, value) => {
        return {
            type: GUI.TableViewV2.CellType.CHECKABLE_DESCRIPTION,
            content: {
                title: title,
                description: description,
                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE,
                selected: value === currentSortingMode
            },
            didCheckCell: () => {
                sortingRef.current = value;
                setCurrentSortingMode(value);
            }
        };
    }

    const _createResetButton = () => {
        let buttonText,
            devFavs = ActiveMSComponent.getDeviceFavoriteSettings().favorites;

        if (currentSortingMode === Sorting.USER_SORTING && !!userStructure) {
            buttonText = _("new-sorting.button-text-user");
        } else if (currentSortingMode === Sorting.DEVICE_SORTING && !!devFavs && !!devFavs.sorting) {
            buttonText = _("new-sorting.button-text-device");
        }

        if (buttonText) {
            return {
                type: GUI.TableViewV2.CellType.DELETE,
                content: {
                    title: buttonText
                },
                action: () => {
                    ActiveMSComponent.resetCompleteStructure(currentSortingMode);

                    if (currentSortingMode === Sorting.USER_SORTING) {
                        setUserStructure(undefined);
                    } else {
                        updateUI();
                    }
                }
            };
        } else {
            return null;
        }
    }


    tempTableContent = _getContent();

    useEffect(() => {
        PersistenceComponent.getShared(Sorting.USER_DEFAULT_STRUCTURE).then(structure => {
            setUserStructure(structure);
        });

        return () => {}
    }, []);

    useEffect(() => {
        navigation.addListener("beforeRemove", () => {
            if (sortingRef.current === Sorting.USER_SORTING) {
                ActiveMSComponent.setCurrentSortingMode(sortingRef.current);
                ActiveMSComponent.resetCompleteStructure();
                PersistenceComponent.activateDeviceFavorites(false, null, null, true);
            } else if (sortingRef.current === Sorting.DEVICE_SORTING) {
                ActiveMSComponent.setCurrentSortingMode(sortingRef.current);
                ActiveMSComponent.resetCompleteStructure();
                PersistenceComponent.activateDeviceFavorites(true);
            } else if (sortingRef.current === Sorting.STANDARD_SORTING) {
                ActiveMSComponent.resetCompleteStructure();
                ActiveMSComponent.setCurrentSortingMode(sortingRef.current);
                PersistenceComponent.activateDeviceFavorites(false, null, null, true);
            }

            NavigationComp.dispatchEventToUI(NavigationComp.UiEvents.FavoritesChanged);
        })
    }, [navigation])

    return <LxReactTableView
        tableContent={tempTableContent}
        showSectionSeparator={true}
    />
}


export default DeviceFavoriteSettingsScreen;
