import {useState, useEffect} from "react";

function useTrustStructure() {
    const [trustStructure, setTrustStructure] = useState({
            version: -1,
            user: null,
            items: []
        });

    const structureReceived = (newStruct) => {
        Debug.Trusts && console.log(useTrustStructure.name, "structureReceived: " + JSON.stringify(newStruct));
        setTrustStructure(newStruct);
    }

    useEffect(() => {
        return ActiveMSComponent.registerForTrustStructure(structureReceived);
    }, [])

    return trustStructure;
}


export default useTrustStructure;