'use strict';

define(["NfcCodeTouchControlEnums"], function (NfcCodeTouchControlEnums) {
    return class NfcSelectorScreen extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($("<div/>"));
            this.sortedNfcCodeTouches = details.sortedNfcCodeTouches;
            this.selectedNfcCodeTouchUuid = details.selectedNfcCodeTouchUuid;
            this.def = details.def;
        }

        closeAction() {
            this.def.reject();
            super.closeAction(...arguments);
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments);
            this.tableContent = [];

            if (this.sortedNfcCodeTouches && this.sortedNfcCodeTouches.readyToLearnTag && this.sortedNfcCodeTouches.readyToLearnTag.length) {
                this.tableContent.push({
                    rows: this.sortedNfcCodeTouches.readyToLearnTag.map(function (codeTouch) {
                        return {
                            type: GUI.TableViewV2.CellType.CHECKABLE,
                            content: {
                                title: codeTouch.name,
                                subtitle: codeTouch.details.place ? codeTouch.details.place + SEPARATOR_SYMBOL + codeTouch.getRoom().name : codeTouch.getRoom().name,
                                selected: codeTouch.uuidAction === this.selectedNfcCodeTouchUuid,
                                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE
                            },
                            didCheckCell: function () {
                                this.def.resolve(codeTouch);
                                this.ViewController.navigateBack();
                            }.bind(this)
                        };
                    }.bind(this))
                });
            }

            this.tableContent.push({
                footerTitle: _('nfc.learning.availability')
            });
            this.reloadTable();
        }

        getURL() {
            return "NfcCodeTouchSelectorScreen";
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarText() {
            return _("nfc.learning.availabledevices");
        }

        _getSectionTitleFromSortedNfcKey(key) {
            var title = null;

            switch (key) {
                case NfcCodeTouchControlEnums.CodeTouchSortKey.READY_TO_LEARN_TAG:
                    break;

                case NfcCodeTouchControlEnums.CodeTouchSortKey.OFFLINE:
                    title = _("offline");
                    break;

                case NfcCodeTouchControlEnums.CodeTouchSortKey.DUMMY:
                    break;

                case NfcCodeTouchControlEnums.CodeTouchSortKey.CAN_NOT_READ_TAG:
                    title = _("steak.settings.battery-powered");
                    break;
            }

            return title;
        }

        _isCellDisabledFromSortingNfcKey(key) {
            return key !== NfcCodeTouchControlEnums.CodeTouchSortKey.READY_TO_LEARN_TAG;
        }

    };
});
