import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Humidity(props) {
  return (
    <Svg isreact="true"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.405.207L12.4.198a.5.5 0 00-.798 0l-.006.009-.018.023-.068.091-.256.346a90.122 90.122 0 00-3.677 5.41c-1.005 1.608-2.017 3.365-2.78 5.004C4.044 12.703 3.5 14.279 3.5 15.5a8.5 8.5 0 0017 0c0-1.222-.543-2.798-1.297-4.418-.762-1.64-1.774-3.397-2.78-5.004A90.057 90.057 0 0012.492.321l-.068-.09-.018-.024zm2.28 11.538a.5.5 0 10-.87-.49l-4.5 8a.5.5 0 10.87.49l4.5-8zm-1.146 4.04C13.882 15.33 14.393 15 15 15c.607 0 1.118.33 1.46.786.343.456.54 1.064.54 1.714 0 .65-.197 1.258-.54 1.714-.342.457-.853.786-1.46.786s-1.118-.33-1.46-.786c-.343-.456-.54-1.064-.54-1.714 0-.65.197-1.258.54-1.714zm-6-4C7.882 11.33 8.393 11 9 11c.607 0 1.118.33 1.46.786.343.456.54 1.064.54 1.714 0 .65-.197 1.258-.54 1.714-.342.457-.853.786-1.46.786s-1.118-.33-1.46-.786C7.196 14.758 7 14.15 7 13.5c0-.65.197-1.258.54-1.714z"
      />
      <Path
        d="M8 13.5c0-.455.139-.847.34-1.114.2-.267.438-.386.66-.386.222 0 .46.119.66.386.201.267.34.66.34 1.114 0 .455-.139.847-.34 1.114-.2.267-.438.386-.66.386-.222 0-.46-.119-.66-.386-.201-.267-.34-.66-.34-1.114zM14 17.5c0-.455.139-.847.34-1.114.2-.267.438-.386.66-.386.222 0 .46.119.66.386.201.267.34.66.34 1.114 0 .455-.139.847-.34 1.114-.2.267-.438.386-.66.386-.222 0-.46-.119-.66-.386-.201-.267-.34-.66-.34-1.114z"
      />
    </Svg>
  )
}

export default Humidity