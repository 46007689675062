'use strict';

define("AudioZoneControl", [
    "Control",
    "AudioZoneControlEnums",
    "IconLib"
], function (
    Control,
    AudioZoneEnums,
    {
        default: Icons
    }
) {
    return class AudioZoneControl extends Control {
        constructor() {
            super(...arguments);
        }

        /**
         * If true, controls of this type MAY use the rooms name instead of its own. E.g. this is important for light
         * controllers, heating controllers and other room based controls.
         */
        controlTypeUsesRoomName() {
            return true;
        }

        getIcon() {
            var states = this.getStates();

            if (states.coverurl && states.isPlaying) {
                return null;
            } else {
                return Icon.AudioZone.AUDIO_ZONE;
            }
        }

        getButton0(states) {
            if (!this._shouldHideButtonsForStates(states)) {
                return {
                    iconSrc: Icon.PLUS,
                    reactIcon: Icons.VolPlus,
                    command: {
                        tap: this.changeVolume.bind(this, Direction.UP),
                        tick: this.changeVolume.bind(this, Direction.UP),
                        doubleTap: this._changeFavorite.bind(this)
                    }
                };
            }
        }

        getButton1(states) {
            if (!this._shouldHideButtonsForStates(states)) {
                return {
                    iconSrc: Icon.MINUS,
                    reactIcon: Icons.VolMinus,
                    command: {
                        tap: this.changeVolume.bind(this, Direction.DOWN),
                        tick: this.changeVolume.bind(this, Direction.DOWN),
                        doubleTap: Commands.AudioZone.CLIENT.PAUSE
                    }
                };
            }
        }

        getControlContentViewControllerIdentifier() {
            return GUI.AudioZoneControlContentViewControllerReactAbstractor.name;
        }

        getControlContentIdentifier() {
            if (CommunicationComponent.getCurrentReachMode() === ReachMode.LOCAL) {
                if (HD_APP) {
                    return "AudioZoneControlContentHD";
                } else {
                    return super.getControlContentIdentifier(...arguments);
                }
            } else {
                return "AudioZoneControlContentRemote";
            }
        }

        changeVolume(dir) {
            var states = this.getStates(),
                vol = states.volume,
                volStep = states.volumeStep;

            if (dir === Direction.UP) {
                // if the server/client isn't ready, activate it.
                if (!states.serverReady) {
                    if (Feature.AUDIO_ZONE__WAKE_AND_PLAY) {
                        // starts the server & sends play right after it is awake
                        return Commands.AudioZone.CLIENT.PLAY;
                    } else {
                        return Commands.AudioZone.SERVER.ON;
                    }
                } else if (!states.clientReady) {
                    return Commands.AudioZone.CLIENT.ON;
                }
            } // server/client up and running.


            if (Feature.VOLUME_STEP_SUPPORT) {
                if (dir === Direction.DOWN) {
                    volStep *= -1;
                }

                return Commands.AudioZone.VOLUME_STEP + "/" + volStep;
            } else {
                if (dir === Direction.DOWN) {
                    vol -= volStep;
                } else {
                    vol += volStep;
                }

                return Commands.AudioZone.VOLUME_SET + "/" + vol;
            }
        }

        _changeFavorite() {
            var states = this.getStates(),
                favorites = states.zoneFavorites,
                activeSource = states.source,
                currFavIndex = -1,
                nextFav = -1; // look which one is the currently selected

            for (var i = 0; i < favorites.length && currFavIndex < 0; i++) {
                if (favorites[i].slot === activeSource) {
                    // found it in the list!
                    currFavIndex = i;
                }
            } // decide which one will be next.


            if (currFavIndex >= favorites.length - 1) {
                // last favorite or not found, skip to first one
                nextFav = favorites[0].slot;
            } else {
                // pick the next one in line
                nextFav = favorites[currFavIndex + 1].slot;
            }

            return Commands.format(Commands.AudioZone.CLIENT.SOURCE_F, nextFav);
        }

        isNetworkClient() {
            return this.details.clientType === MediaEnum.ClientType.UPNP || // Connected via Network (LAN/ WIFI)
                this.details.clientType === MediaEnum.ClientType.RPI || // Connected via Network (LAN/ WIFI)
                this.details.clientType === MediaEnum.ClientType.EXTENSION; // Connected via Tree Turbo
        }

        // ------------------------------------------------------------
        // Previously on the AudioZoneControl as Static Methods
        // ------------------------------------------------------------
        specificCreateCmdText(cmdParts, cmdArguments) {
            var text = null,
                cfg = {
                    name: this.name
                },
                statesResult = SandboxComponent.getStatesForUUID(this.uuidAction);

            switch (cmdParts[1]) {
                case Commands.AudioZone.CLIENT.ON:
                    text = _("cmdtext.audiozone.on", cfg);
                    break;

                case Commands.AudioZone.Queue.PREV:
                    text = _("cmdtext.audiozone.queueminus", cfg);
                    break;

                case Commands.AudioZone.Queue.NEXT:
                    text = _("cmdtext.audiozone.queueplus", cfg);
                    break;

                case Commands.AudioZone.VOLUME_SET:
                    cfg.vol = cmdParts[2];
                    text = _("cmdtext.audiozone.volume", cfg);
                    break;

                case Commands.AudioZone.VOLUME_STEP:
                    var vol = parseInt(cmdParts[2]);
                    cfg.vol = vol > 0 ? "+" + vol : vol;
                    text = _("cmdtext.audiozone.volume", cfg);
                    break;

                case Commands.AudioZone.TTS.ID:
                    cfg.text = cmdParts[2];
                    text = _("cmdtext.audiozone.tts", cfg);
                    break;

                case Commands.AudioZone.CLIENT.SOURCEID:
                    try {
                        if (cmdArguments) {
                            cfg.item = cmdArguments[0];
                        } else {
                            var slot = parseInt(cmdParts[2]);
                            statesResult.states.zoneFavorites.some(function (fav) {
                                if ("" + fav.slot === slot + "") {
                                    cfg.item = fav.name;
                                    return true;
                                }

                                return false;
                            });
                        }
                    } catch (ex) {
                        console.error("Could not create name for source cmd!", ex);
                        cfg.item = cmdArguments ? cmdArguments[0] : _("media.item.zone-fav.slot-label", {
                            slotNr: cmdParts[2]
                        });
                    }

                    text = _("cmdtext.audiozone.playitem", cfg);
                    break;

                default:
                    break;
            }

            if (!text && cmdParts[1].startsWith("audio%2F")) {
                text = this._createMusicServerCmdText(cmdParts, cmdArguments, cfg);
            }

            return text;
        }

        _createMusicServerCmdText(cmdParts, cmdArguments, cfg) {
            // it's an audioZone-command!
            var audioParts = decodeURIComponent(cmdParts[1]).split("/");
            var audioCmdPart = audioParts.slice(2, 4).join("/");
            var processed = false,
                text = null;
            /**
             * Helper to handle play/add/insert item - texts
             * @param txtId
             */

            var handleItemText = function (txtId) {
                if (!cmdArguments) {
                    throw new Error("argumentTexts missing for " + audioCmdPart);
                }

                cfg.item = cmdArguments[0];
                text = _(txtId, cfg);
                processed = true;
            }; // check if it's a lineIn


            if (audioParts[2].startsWith("linein")) {
                handleItemText("cmdtext.audiozone.playitem");
            } // check audio commands made up of two parts


            if (!processed) {
                switch (audioCmdPart) {
                    case "library/play":
                    case "playlist/play":
                    case "roomfav/play":
                        handleItemText("cmdtext.audiozone.playitem");
                        break;

                    default:
                        break;
                }
            }

            if (!processed) {
                // check audio commands with one part
                switch (audioParts[2]) {
                    case MediaEnum.AudioCommands.VOLUME.REQUEST:
                        // contains "volume"
                        cfg.vol = lxFormat("%.0f%%", parseInt(audioParts[3])); // make sure the "+" is still there.

                        if (audioParts[3].startsWith("+")) {
                            cfg.vol = "+" + cfg.vol;
                        }

                        text = _("cmdtext.audiozone.volume", cfg);
                        break;

                    case "position":
                        // Force seconds
                        cfg.time = LxDate.formatSecondsIntoDigits(parseInt(audioParts[3]), false, true);
                        text = _("cmdtext.audiozone.position", cfg);
                        break;

                    case "favoriteplay":
                    case "serviceplay":
                    case "playurl":
                    case "linein":
                        handleItemText("cmdtext.audiozone.playitem");
                        break;

                    case "serviceplayadd":
                    case "addurl":
                    case "queueadd":
                        handleItemText("cmdtext.audiozone.additem");
                        break;

                    case "serviceplayinsert":
                    case "inserturl":
                    case "queueinsert":
                        handleItemText("cmdtext.audiozone.insertitem");
                        break;

                    case "serviceplayinsertandplay":
                    case "insertandplayurl":
                    case "queueandplay":
                        handleItemText("cmdtext.audiozone.insertplayitem");
                        break;

                    default:
                        var key = "cmdtext.audiozone." + audioParts[2].toLowerCase();

                        if (cmdArguments) {
                            for (var i = 0; i < cmdArguments.length; i++) {
                                cfg["argument" + i] = cmdArguments[i];
                            }
                        }

                        text = _(key, cfg);
                        break;
                }
            }

            return text;
        }

        _shouldHideButtonsForStates(states) {
            if (MediaServerComp.Feature.V2_FIRMWARE) {
                // This check will always fail, as we don't have a sufficient way to determine the version without connecting to the MusicServer yet
                return states.serverState === MediaEnum.ServerState.OFFLINE || states.serverState === MediaEnum.ServerState.NOT_REACHABLE || !states.clientReady && this.isNetworkClient() && !states.clientBooting;
            } else {
                return false; // v1 Musicservers can go into standby mode, v2 can't
            }
        }

    };
});
