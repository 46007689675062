'use strict';

define("TimedSwitchControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class TimedSwitchControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [];

            if (states.timerActive) {
                cmds.push(this.createCommand(_("permanently.switch.on"), Commands.TIMED_SWITCH.ON));
                cmds.push(this.createCommand(_("turn-off"), Commands.TIMED_SWITCH.OFF));

                if (control.details.isStairwayLs) {
                    cmds.push(this.createCommand(_("controls.timedswitch.start.again"), Commands.TIMED_SWITCH.PULSE));
                }
            } else if (states.isOn) {
                cmds.push(this.createCommand(_("turn-off"), Commands.TIMED_SWITCH.OFF));
            } else {
                cmds.push(this.createCommand(_("turn-on"), Commands.TIMED_SWITCH.ON));
                cmds.push(this.createCommand(_("controls.timedswitch.start"), Commands.TIMED_SWITCH.PULSE));
            }

            return cmds;
        }

    };
});
