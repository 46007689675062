'use strict';

define(["AtheneMediaHandler", "AtheneControlEnums", "AtheneActivityDetailsScreen"], function (MediaHandler, AtheneEnums) {
    return class AtheneActivitiesScreen extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            this.control = details.control;
            this.mediaHandler = MediaHandler.shared(this.control);
            this.mediaHandler.delegate = this;
            this.lastActivities = [];
        }

        supportedOrientation() {
            return DeviceOrientation.ANY;
        }

        viewDidLoad() {
            var args = arguments;
            return this.mediaHandler.getLastActivities().then(lastActivities => {
                this.lastActivities = lastActivities;
                return super.viewDidLoad(...args).then(function () {
                    if (!HD_APP) {
                        this.collectionView = this.getCollectionView();
                        this.appendSubview(this.collectionView, this.getTableViewPlaceholder());
                        this.collectionView.getElement().addClass("table-view-screen__content");
                        this.collectionView.getElement().addClass("table-view-screen__table-view");
                        return this.collectionView.reload();
                    }
                }.bind(this));
            });
        }

        titleBarText() {
            return _("athene.activities.title");
        }

        getTableView() {
            if (HD_APP) {
                return this.getCollectionView();
            }

            return super.getTableView(...arguments);
        }

        getCollectionView() {
            return new GUI.CardView(this.tableViewDataSource, this.tableViewDelegate);
        }

        styleForTableView() {
            return TableViewStyle.GROUPED;
        }

        reloadTable() {
            this.tableContent = [];

            var groupsObject = this._groupByDay(this.lastActivities);

            var sortedGroupsKeys = Object.keys(groupsObject).sort(function (a, b) {
                return groupsObject[b][0].getDate().unix() - groupsObject[a][0].getDate().unix();
            }.bind(this));
            sortedGroupsKeys.forEach(function (key) {
                var section = {
                    headerTitle: key,
                    rows: groupsObject[key].map(activity => {
                        return this._createActivityCell(activity);
                    })
                };
                this.tableContent.push(section);
            }.bind(this));
            return super.reloadTable(...arguments).then(function () {
                return this.collectionView ? this.collectionView.reload() : Q.resolve();
            }.bind(this));
        }

        _createActivityCell(activity) {
            return {
                content: {
                    activity: activity
                },
                type: GUI.TableViewV2.CellType.Special.ACTIVITY,
                action: this._navigateToActivityDetails.bind(this)
            };
        }

        _navigateToActivityDetails(cell) {
            var selectedIdx = this.lastActivities.findIndex(function (activity) {
                return cell.content.activity === activity;
            });
            NavigationComp.showState(AtheneEnums.Screens.ACTIVITY_DETAILS, {
                control: this.control,
                selectedIdx: selectedIdx,
                lastActivities: this.lastActivities
            });
        }

        _groupByDay(objectArray) {
            return objectArray.reduce(function (acc, obj) {
                var key = obj.getDate().format('LL');

                if (!acc[key]) {
                    acc[key] = [];
                } // Add object to list for given key's value


                acc[key].push(obj);
                return acc;
            }, {});
        }

    };
});
