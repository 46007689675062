'use strict';
/**
 * zone
 */

window.GUI = function (GUI) {
    class MediaControlCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getMediaControlCellContent = function getMediaControlCellContent(name) {
                return $('<div class="content__top">' + ImageBox.getResourceImageWithClasses(Icon.REMOVE, 'top__left-icon') + '<div class="top__text-cntr">' + '<div class="text-cntr__main">' + name + '</div>' + '<div class="text-cntr__sub"></div>' + '</div>' + '</div>' + '<div class="content__bottom"></div>');
            };

            return {
                getMediaControlCellContent: getMediaControlCellContent
            };
        }(); //endregion Static

        constructor() {
            super(...arguments);
            this.element.addClass("media-control-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.control = this.content.zone;
            this.playerId = this.content.playerId;
            this.contentPlaceholder.append(MediaControlCell.Template.getMediaControlCellContent(this.content.title));
            this.elements.top = this.contentPlaceholder.find(".content__top");
            this.elements.bottom = this.contentPlaceholder.find(".content__bottom");
            this.volumeControl = new Controls.AudioZoneControl.AudioZoneControlVolumeControl(this.control, true, null, null, this.playerId);
            this.volumeControl.element.addClass("bottom__volume");
            this.appendSubview(this.volumeControl, this.elements.bottom);
            this.playPauseButton = new Controls.AudioZoneControl.PlayPauseButton(this.control, this.getPlayPauseButtonTheme());
            this.playPauseButton.element.addClass("top__right-button");
            this.appendSubview(this.playPauseButton, this.elements.top);
            this.elements.mainText = this.contentPlaceholder.find(".text-cntr__main");
            this.elements.subText = this.contentPlaceholder.find(".text-cntr__sub");
        }

        getPlayPauseButtonTheme() {
            return null;
        }

        clickableAllowed() {
            return true;
        }

    }

    GUI.TableViewV2.Cells.MediaControlCell = MediaControlCell;
    GUI.TableViewV2.CellType.Special.MEDIA_CONTROL = "MediaControlCell";
    return GUI;
}(window.GUI || {});
