'use strict';

define(['AudioZoneControlEnums', 'AudioZoneDetailedContentBase'], function (AudioZoneControlEnums, AudioZoneDetailedContentBase) {
    class ZoneGroupCreateEditScreen extends AudioZoneDetailedContentBase {
        //region Static
        static Template = function () {
            var getUnsyncButton = function () {
                return '<div class="zone-group-create-edit-unsync-container">' + '   <button class="zone-group-create-edit-unsync__button">' + _("media.sync.unsync-group") + '   </button>' + '</div>';
            };

            return {
                getUnsyncButton: getUnsyncButton
            };
        }(); //endregion Static

        constructor(details) {
            super(...arguments);

            if (!details || !details.control) {
                throw new Error("Cannot create ZoneGroupCreateEditScreen if no control is passed in within the details!");
            }

            this.details = details;
            this.control = this.details.control; // Override the base control with the provided one!

            this.stateSet = {};
            this.groupMap = {};
            this.tableContent = [];
            this.allZonesSet = MediaServerComp.getAvailableZones();
            this.groupMap[this.control.details.playerid] = this.control.details.playerid;
            this.removedPlayerIds = {};
        }

        viewWillAppear() {
            super.viewWillAppear(arguments);

            this._getZoneStates();

            this._createFooter();

            this.reloadTable(); // show updated tablecontent
        }

        viewDidAppear() {
            super.viewDidAppear(arguments);
        }

        titleBarConfig() {
            return {
                rightSide: TitleBarCfg.Button.TICK
            };
        }

        titleBarText() {
            return _("media.group.set-up");
        }

        titleBarActionRight() {
            if (JSON.stringify(this.groupMapOld) !== JSON.stringify(this.groupMap)) {
                this._createGroup().then(function () {
                    NavigationComp.disableAnimationsTemp();
                    this.ViewController.navigateBack();
                    this.ViewController.navigateBack();
                }.bind(this));
            } else {
                this.ViewController.navigateBack();
            }
        }

        getURL() {
            return "zonegroupcreateedit";
        }

        _setTableContent() {
            this.tableContent = [{
                rows: []
            }];
            Object.keys(this.allZonesSet).forEach(function (playerId) {
                if (this.allZonesSet[playerId].details.clientType !== MediaEnum.ClientType.UPNP) {
                    this.tableContent[0].rows.push(this._createCell(playerId));
                }
            }.bind(this));
            this.groupMapOld = cloneObject(this.groupMap);
        }

        _createFooter() {
            var states;

            if (this.stateSet[this.control.details.playerid]) {
                states = this.stateSet[this.control.details.playerid];

                if (states.isSynced) {
                    this.unsyncButton = new GUI.LxButton(this, ZoneGroupCreateEditScreen.Template.getUnsyncButton());
                    this.appendSubview(this.unsyncButton);
                    this.unsyncButton.onButtonTapped = this._onUnsyncButtonTapped.bind(this);
                }
            }
        }

        _onUnsyncButtonTapped() {
            var groupableZonesIds = this.stateSet[this.control.details.playerid].syncedzones.map(function (player) {
                return player.playerid;
            });

            if (MediaServerComp.Feature.DISSOLVE_GROUP) {
                var cmd = MediaEnum.Commands.SYNC.MULTI_UNSYNC;
                cmd += groupableZonesIds.join("/");
                MediaServerComp.sendMediaServerCommand(cmd);
            } else {
                Debug.Control.AudioZone.Group && console.log("      using individual unsync commands");
                groupableZonesIds.forEach(function (zoneId) {
                    cmdObj.cmd = MediaEnum.AudioCommands.UNSYNC;
                    MediaServerComp.sendAudioZoneCommand(zoneId, cmdObj);
                });
            }

            NavigationComp.disableAnimationsTemp();
            NavigationComp.navigateBack();
            NavigationComp.navigateBack();
        }

        _getZoneStates() {
            var zone, uuid, states;
            Object.keys(this.allZonesSet).forEach(function (key) {
                zone = this.allZonesSet[key];
                uuid = zone.uuidAction;
                states = SandboxComponent.getStatesForUUID(uuid);

                this._zoneStatesReceived(states.states, key);
            }.bind(this));
        }

        /**
         * Will update the stateSet for this player and also trigger an UI update if critical data has changed.
         * @param states
         * @param playerId
         * @private
         */
        _zoneStatesReceived(states, playerId) {
            Debug.Control.AudioZone.Group && console.log(this.name, "_zoneStatesReceived: " + playerId);
            this.stateSet[playerId] = cloneObject(states);

            this._setTableContent(playerId);
        }

        _createCell(playerId) {
            var cell = {
                    content: {
                        title: this.allZonesSet[playerId].name,
                        playerId: parseFloat(playerId),
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE
                    },
                    type: GUI.TableViewV2.CellType.CHECKABLE,
                    didCheckCell: this._handleCheck.bind(this)
                },
                syncedZones;

            if (parseFloat(playerId) === this.control.details.playerid) {
                cell.content.selected = true;
            } else {
                if (this.stateSet[playerId]) {
                    if (this.stateSet[playerId].isSynced) {
                        syncedZones = this.stateSet[playerId].syncedzones;
                        syncedZones.forEach(function (playerIdObj) {
                            if (playerIdObj.playerid === this.control.details.playerid) {
                                cell.content.selected = true;
                                this.groupMap[playerId] = parseFloat(playerId);
                            }
                        }.bind(this));
                    }
                }
            }

            return cell;
        }

        _handleCheck(cell, section, row, tableView, selected) {
            if (cell.content.playerId !== this.control.details.playerid) {
                if (selected) {
                    this.groupMap[cell.content.playerId] = cell.content.playerId;

                    this._toggleRemove(cell.content.playerId, selected);
                } else {
                    if (this.groupMap[cell.content.playerId]) {
                        delete this.groupMap[cell.content.playerId];
                    }

                    this._toggleRemove(cell.content.playerId, selected);
                }
            } else {
                if (!selected) {
                    cell.setChecked(cell, true, true);
                }
            }
        }

        _toggleRemove(playerId, selected) {
            if (selected) {
                if (this.removedPlayerIds[playerId]) {
                    delete this.removedPlayerIds[playerId];
                }
            } else {
                if (this.groupMapOld[playerId]) {
                    this.removedPlayerIds[playerId] = playerId;
                }
            }
        }

        _createGroup() {
            var cmd = MediaEnum.AudioCommands.SYNC,
                playerIds = [];

            if (this.groupMapOld !== this.groupMap) {
                if (Object.keys(this.groupMap).length === 1) {
                    cmd = MediaEnum.AudioCommands.UNSYNC;
                } else {
                    Object.keys(this.removedPlayerIds).forEach(function (playerId) {
                        this._sendCommand(playerId, MediaEnum.AudioCommands.UNSYNC);
                    }.bind(this));
                    Object.keys(this.groupMap).forEach(function (playerId) {
                        if (parseFloat(playerId) !== this.control.details.playerid) {
                            playerIds.push(this.groupMap[playerId]);
                        }
                    }.bind(this));
                    cmd += playerIds.join("/");
                    cmd += "/";
                }

                return Q.resolve(this._sendCommand(this.control.details.playerid, cmd) || true);
            }
        }

        _sendCommand(playerId, cmd) {
            return MediaServerComp.sendAudioZoneCommand(playerId, {
                cmd: cmd
            });
        }

    }

    Controls.AudioZoneControl.ZoneGroupCreateEditScreen = ZoneGroupCreateEditScreen;
});
