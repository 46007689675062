'use strict';

import Irc2024TempAdoption from "../tempSettingsScreen/irc2024TempAdoption";

define(["IRoomControllerV2021ControlEnums"], function (CtrlEnums) {
    return class IrcTempSliders extends GUI.View {
        //region Static
        static Template = class {
            //region Static
            static getTemplate() {
                return '<div class="irc-temp-sliders"/>';
            } //endregion Static


        }; //endregion Static

        constructor(control, tempType) {
            super($(IrcTempSliders.Template.getTemplate()));
            this.control = control;
            this.tempType = tempType;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(() => {
                return this.appendReactComp({
                    reactComp: Irc2024TempAdoption,
                    compProps: {
                        tempType: this.tempType,
                        controlUuid: this.control.uuidAction
                    },
                    target: this.element
                }).then((instance) => {
                    // nothing to do.
                })
            });
        }


    };
});
