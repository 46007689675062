import {View} from "react-native";
import {useMemo} from "react";
import {useLiveState} from "LxComponents";
import globalStyles from "GlobalStyles"
import Icons from "IconLib"


export default function Wallbox2CardStateIcon({controlUuid, containerStyle}) {
    const liveState = useLiveState(controlUuid, [
        "isBelowLimit", 
        "isPaused", 
        "carConnected", 
        "loadSheddingActive",
    ]);

    const styles = {
        rootCntr: {  },
        mainIcon: {
            width: "100%", height: "100%", alignSelf: "center", justifySelf: "center"
        },
        smallIcon: {
            position: "absolute",
            top: -5,
            left: -5,
            borderRadius: "50%",
            background: globalStyles.colors.black
        }
    }

    const renderedStateIcon = useMemo(() => {
        let stateIconColor = globalStyles.colors.text.primary;
        if (liveState.states.carConnected
            && !liveState.states.loadSheddingActive
            && !liveState.states.isPaused
            && !liveState.states.isBelowLimit) {
            stateIconColor = globalStyles.colors.stateActive;
        }
        return <Icons.ECar style={{...styles.mainIcon, fill: stateIconColor }} />
    }, [
        liveState.states.carConnected,
        liveState.states.isBelowLimit,
        liveState.states.isPaused,
        liveState.states.loadSheddingActive
    ]);

    const renderedSmallIcon = useMemo(() => {
        let icon;
        if (liveState.states.loadSheddingActive) {
            icon = <Icons.ShieldEnergy
                style={{...styles.smallIcon, fill: globalStyles.colors.orange}} />;

        } else if (liveState.states?.phaseSwitching) {
            icon = <Icons.PauseCircleFilled
                style={{...styles.smallIcon, fill: globalStyles.colors.orange, padding: 1}} />; 
        } else if (liveState.states.isBelowLimit) {
            icon = <Icons.ExclamationMarkCircleFilled
                style={{...styles.smallIcon, fill: globalStyles.colors.orange}} />;

        } else if (liveState.states.isPaused) {
            // padding ensures a proper black circle around the icon
            icon = <Icons.PauseCircleFilled
                style={{...styles.smallIcon, fill: globalStyles.colors.stateActive, padding: 1}} />;

        }
        return icon;
    }, [
        liveState.states.isBelowLimit,
        liveState.states.isPaused,
        liveState.states.loadSheddingActive,
        liveState.states.phaseSwitching
    ]);

    return <View style={{...styles.rootCntr, ...containerStyle}}>
        {renderedStateIcon}
        {renderedSmallIcon}
    </View>;
}
