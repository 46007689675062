import { ScreenState } from "./irrigationControlEnums";

export default class IrrigationControlStatesSrc {
    static getStateSections(control, states, navigation) {
        let sections = [],
            section = {},
            rows = [],
            trackerControl = control.getTracker()

        states.hasZones && rows.push({
            content: {
                title: trackerControl.name,
                disclosureIcon: true,
                clickable: true
            },
            type: GUI.TableViewV2.CellType.GENERAL,
            action: this.showTracker.bind(this, trackerControl)
        }); // duration setting

        states.hasZones && rows.push({
            content: {
                title: _("controls.irrigation.duration"),
                disclosureIcon: true,
                clickable: true
            },
            type: GUI.TableViewV2.CellType.GENERAL,
            action: this.showDurationSettings.bind(this, control, navigation)
        });

        section.rows = rows;
        sections.push(section);

        return sections;
    }

    static showTracker(trackerControl) {
        NavigationComp.showControlContent(trackerControl);
    }

    static showDurationSettings(control, navigation) {
        navigation.navigate(ScreenState.DURATION, { control }, AnimationType.HD_OVERLAY);
    }
}