'use strict';
/**

 */

window.GUI = function (GUI) {
    class PickerViewDate extends GUI.LxPickerViewBase {
        constructor() {
            super(...arguments);
            Debug.GUI.PickerView && console.log(this.name, "constructor");
            this.currentValue = this.details.value;
        }

        /**
         * Returns a default picker configuration. Mode is used to differ what type of picker is to be used (scroller
         * is our default, but calendar is also available).
         * @returns {{theme: string, mode: string, display: string, lang: string, onChange}}
         */
        getPickerConfig() {
            var cfg = super.getPickerConfig(...arguments);
            cfg.controls = ['calendar'];
            cfg.layout = 'liquid'; // ensures the whole divs width & height is used.

            if (this.details) {
                cfg.defaultValue = this.details.value.toDate();
                cfg.selectedValues = [cfg.defaultValue];

                if (this.details.marked) {
                    cfg.marked = this.details.marked;
                }

                if (this.details.minDate) {
                    cfg.minDate = this.details.minDate.toDate();
                } else {
                    cfg.minDate = moment().startOf('year').toDate();
                }
            }

            cfg.hightlightNow = true;
            cfg.maxDate = moment().add(2, 'year').endOf('year').toDate();
            cfg.selectType = 'day';
            cfg.onDayChange = this.onChange.bind(this);
            cfg.onMonthLoaded = this._internalChange.bind(this); // workaround to fix disappearing months

            if (isUSDateOrder()) {
                // other countries (North American ones) have Sunday as the first day of the week.
                cfg.firstDay = 0;
            } else {
                // normal countries have monday as the first day of the week
                cfg.firstDay = 1;
            }

            return cfg;
        }

        /**
         * This function initializes the Picker
         */
        preparePicker() {
            this.picker.calendar(this.getPickerConfig());
            this.setPickerValue(this.details.value);
        }

        /**
         * This function will be called once the pickerValue has changed
         * For example if the user turns one of the pickerWheels
         * @param v The value returned by mobiscroll in the given format
         */
        onChange(v) {
            this.currentValue = moment(v.date);
            this.onPickerChangedFn && this.onPickerChangedFn(this.getPickerValue(v));
        }

        /**
         * This is a hotfix, as mobiscroll months otherwise are disappearing.
         * @param year
         * @param month
         * @param instance
         * @private
         */
        _internalChange(year, month, instance) {
            $(window.document).find(".loxone-picker").find(".dw-cal-slide").attr("style", "transform: translate3d(100%,0px,0px);")
            $(window.document).find(".loxone-picker").find(".dw-cal-slide-a").attr("style", "transform: translate3d(0%,0px,0px);")
        }

        getPickerValue(v) {
            /**
             * v is in format DD.MM.YYYY
             */
            return moment(v.date);
        }

        /**
         * This function will be called once the picker will disappear
         * @param value
         */
        onDisappear(value) {
            this.onPickerDisappearFn && this.onPickerDisappearFn(this.currentValue);
        }

        /**
         * This sets the given value to the mobiscroll picker
         * @param value This must be in the right format you specified in the picker config.
         */
        setPickerValue(value) {
            // adds the date to the LIST of selected days
            var date = value.toDate();
            this.picker.mobiscroll('setVal', date);
        }

    }

    GUI.LxPickerViewDate = PickerViewDate;
    return GUI;
}(window.GUI || {});
