'use strict';

window.GUI = function (GUI) {
    class QueueCell extends GUI.TableViewV2.Cells.MediaCell {
        constructor() {
            super(...arguments);
            this.element.addClass("queue-cell");
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.element.append($('<div class="queue-cell__border"></div>'));
        }

    }

    GUI.TableViewV2.Cells.QueueCell = QueueCell;
    GUI.TableViewV2.CellType.Special.QUEUE = "QueueCell";
    return GUI;
}(window.GUI || {});
