import globalStyles from "GlobalStyles";
import React from "react";
import PropTypes from "prop-types";
import { StyleSheet } from "react-native-web"
import {ButtonType, defaultStyles} from "./LxReactButtonDefaultSettings";
import { LxReactView, LxReactPressable, LxReactText } from "LxComponents";


/**
 * Returns a Touchable Component (https://reactnative.dev/docs/touchablewithoutfeedback), that contains a text and icons left and right
 * @param {Function} props.onPress Called when button was tapped
 * @param {Function} props.onLongPress Called if the time after onPressIn lasts longer than 370 milliseconds. (customizable)
 * @param {Function} props.onPressIn Called when button is pressed (before onPress)
 * @param {Function} props.onPressOut Called when button is released
 * @param {String} props.text Text that should be displayed in the button
 * @param {JSX.Element} props.leftIcon Icon that should be displayed left to the text
 * @param {JSX.Element} props.rightIcon Icon that should be displayed right to the text
 * @param {String} [props.buttonType = "primary"] Type of the button (coloring)
 * @param {number} [props.gap = 8] Space between text and icons (in px)
 * @param {Object} [props.containerStyle] For overriding the Touchable Component Style
 * @param {Object} [props.textStyle] For overriding the text style
 * @param {Boolean} [props.disabled = false] defines if the button is clickable or not
 */
export default function LxReactButton(props) {
    const Gap = () => {
        return {
            comp: LxReactView,
            props: {
                key: getKeyProp("gap"),
                style: {
                    width: props.gap || defaultStyles.GAP_SIZE
                }
            }
        };
    }

    const getKeyProp = (suffix) => {
        return props.pkey + "-" + suffix;
    }

    const ButtonIcon = (isLeft) => {
        let targetIcon = isLeft ? props.leftIcon : props.rightIcon;
        if (!targetIcon) return [];

        let iconObject = {
            comp: targetIcon,
            props: {
                key: getKeyProp(isLeft ? "left-icon" : "right-icon"),
                style: getStyles().icon
            }
        }

        if (isLeft) {
            return ([iconObject, Gap()]);
        } else {
            return ([Gap(),iconObject]);
        }
    }

    let isPrimary = () => {
        let buttonType =  (props && props.buttonType) || ButtonType.PRIMARY;
        return buttonType !== ButtonType.SECONDARY;
    }

    let getStyles = () => {
        var styles;
        if (props.disabled) {
            styles = disabledStyles;
        } else if (isPrimary()) {
            styles = primaryStyles;
        } else {
            styles = secondaryStyles;
        }
        return styles;
    }

    let getChildComps = () => {
        var childComps = [];
        childComps.splice(0, 0, ...ButtonIcon(true));
        childComps.push({
            comp: LxReactText,
            props: {
                numberOfLines: props.numberOfLines || 2,
                style: {...getStyles().text, ...props.textStyle},
                children: props.text,
                pkey: getKeyProp("text"),
                key: getKeyProp("text")
            }
        })
        childComps.splice(0, 0, ...ButtonIcon(false));
        return childComps;
    }

    return (
        <LxReactPressable
            style={[getStyles().button, StyleSheet.flatten(props.containerStyle)]}
            pressableStyle={[primaryStyles.pressable, props.pressableStyle]}
            onPress={props.onPress}
            onPressArgs={props.onPressArgs}
            onPressIn={props.onPressIn}
            onPressOut={props.onPressOut}
            onLongPress={props.onLongPress}
            onLongPressTick={props.onLongPressTick}
            longPressTickInterval={props.longPressTickInterval}
            disabled={props.disabled}
            childComps={getChildComps()}
            children={props.children}
            pkey={getKeyProp()}
            key={getKeyProp()}
        />
    );
}

LxReactButton.propTypes = {
    onPress: PropTypes.func,
    onPressArgs: PropTypes.array,
    onPressIn: PropTypes.func,
    onPressOut: PropTypes.func,
    onLongPress: PropTypes.func,
    onLongPressTick: PropTypes.func,
    longPressTickInterval: PropTypes.number,
    text: PropTypes.string.isRequired,
    leftIcon: PropTypes.oneOfType([ PropTypes.func, PropTypes.element ]),
    rightIcon: PropTypes.element,
    buttonType: PropTypes.string,
    gap: PropTypes.number,
    containerStyle: PropTypes.object, // styles the container wrapped around all of it.
    pressableStyle: PropTypes.object, // styles the thing that contains the children (Text/Icon).
    pressedContainerStyle: PropTypes.object,
    textStyle: PropTypes.object,
    pressedTextStyle: PropTypes.object,
    disabled: PropTypes.bool,
    pkey: PropTypes.string.isRequired
}

const primaryStyles = {
    button: {
        ...globalStyles.textStyles.body.bold,
        backgroundColor: globalStyles.colors.brand,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        borderRadius: 4,
    },
    pressable: {
        paddingVertical: 16,
        paddingHorizontal: 24,
    },
    text: {
        ...globalStyles.textStyles.body.bold,
        color: globalStyles.colors.grey["50"]
    },
    icon: {
        fill: globalStyles.colors.grey["50"],
        height: defaultStyles.ICON_SIZE,
        width: defaultStyles.ICON_SIZE
    }
};

const disabledStyles = {
    button: {
        ...primaryStyles.button,
        backgroundColor: globalStyles.colors.grey["300a36"]
    },
    text: {
        ...primaryStyles.text,
        opacity: "15%"
    },
    icon: {
        ...primaryStyles.icon,
        opacity: "15%"
    }
}

const secondaryStyles = {
    button: {
        ...primaryStyles.button,
        backgroundColor: globalStyles.colors.grey["900"]
    },
    text: {
        ...primaryStyles.text,
        color: globalStyles.colors.grey["200"]
    },
    icon: {
        ...primaryStyles.icon,
        fill: globalStyles.colors.grey["200"]
    }
}
