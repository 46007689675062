import {useState, useRef, useEffect, useCallback, useMemo} from "react";
import {useConnectionReady} from "LxComponents";

export default function useDeviceSearchTechTags(forceResetExtensions = false) {

    const [techTagList, setTechTagList] = useState(null);
    const connReady = useConnectionReady(true);
    const isMounted = useRef(false);

    const randomId = useRef(getRandomIntInclusive(100,999));
    const dbgName = useMemo(() => {
        return "useDeviceSearchTechTags#" + randomId.current;
    }, []);

    /**
     * Filters dummies and publishes the result.
     * @param extensions
     */
    const updateTechTags = useCallback((technologies) => {
        if (!isMounted.current) {
            Debug.DeviceSearch.TechTags && console.log(dbgName, "updateTechTags --> NOT mounted!");
            return;
        }
        Debug.DeviceSearch.TechTags && console.log(dbgName, "updateTechTags");
        setTechTagList(technologies);
    }, []);

    const reloadTechTags = () => {
        if (connReady) {
            Debug.DeviceSearch.TechTags && console.log(dbgName, "reloadTechTags");
            ActiveMSComponent.getAvailableTechTags(false, forceResetExtensions).then(updateTechTags).catch((e) => {
                Debug.DeviceSearch.TechTags && console.error(dbgName, "reloadTechTags -> error", e);
                updateTechTags([]);
            });
        } else {
            Debug.DeviceSearch.TechTags && console.log(dbgName, "reloadTechTags -> not connected, don't load");
        }
    }

    useEffect(() => {
        isMounted.current = true;
        reloadTechTags();
        let unregFn = ActiveMSComponent.registerForTechTagChanges(updateTechTags);
        return () => {
            isMounted.current = false;
            unregFn();
        };
    }, []);

    useEffect(() => {
        Debug.DeviceSearch.TechTags && console.log(dbgName, "useEffect@connReady/searchType -> connReady=" + connReady);
        reloadTechTags();
    }, [connReady]);

    return techTagList;
}