'use strict';

define(["ContentOverviewScreenBase", "ControlContentMenu", "HistoryManager", "CustomizationManager"], function (ContentOverviewScreenBase, ControlContentMenu, HistoryManager, CustomizationManager) {
    return class StartOverviewScreen extends ContentOverviewScreenBase {
        constructor(details) {
            super(...arguments);
            this.reloadContentTypes.push(this.EVENT_TYPES.SERVICE);
            this.reloadContentTypes.push(this.EVENT_TYPES.ZONE_FAVORITES);
        }

        viewDidLoad() {
            Debug.Control.AudioZone.StartOverview && console.log(this.viewId, "viewDidLoad");
            return super.viewDidLoad(...arguments).then(function () {
                this.boundHistoryChange = this._onHistoryChange.bind(this);
                this.boundCustomizationChange = this._onCustomizationChange.bind(this);
            }.bind(this));
        }

        viewDidAppear() {
            return super.viewDidAppear(...arguments).then(function baseViewDidAppearPassed() {
                this._registerForManagers();
            }.bind(this));
        }

        viewWillDisappear(viewRemainsVisible) {
            Debug.Control.AudioZone.StartOverview && console.log(this.viewId, "viewWillDisappear");
            !viewRemainsVisible && this._unregisterFromManagers();
            return super.viewWillDisappear(...arguments);
        }

        destroy() {
            Debug.Control.AudioZone.StartOverview && console.log(this.viewId, "destroy");

            this._unregisterFromManagers(true);

            return super.destroy(...arguments);
        }

        _registerForManagers() {
            Debug.Control.AudioZone.StartOverview && console.log(this.viewId, "_registerForManagers");
            var newHistoryVersion = HistoryManager.shared(this.control).registerForChange(this.boundHistoryChange);
            CustomizationManager.shared(this.control).registerForChange(this.boundCustomizationChange); // check if the history did change while being unregistered

            if (this._historyVersionOnUnregister && this._historyVersionOnUnregister !== newHistoryVersion) {
                Debug.Control.AudioZone.StartOverview && console.log(this.viewId, "   history version did change!");
                this.boundHistoryChange();
            }
        }

        _unregisterFromManagers(fromDestroy) {
            Debug.Control.AudioZone.StartOverview && console.log(this.viewId, "_unregisterFromManagers: fromDestroy=" + !!fromDestroy);
            this._historyVersionOnUnregister = HistoryManager.shared(this.control).unregisterFromChange(this.boundHistoryChange);
            CustomizationManager.shared(this.control).unregisterFromChange(this.boundCustomizationChange);
        }

        titleBarText() {
            return _("start");
        }

        getRightVCButtonIcon() {
            return null;
        }

        getRightVCButtonIconFn() {
            return null;
        }

        getTableContent() {
            // The singleton will be destroyed when the connection is lost, reapply the delegate on every fetch
            // the singleton already handles duplicate delegates!
            ControlContentMenu.shared(this.control).delegate = this;
            return Q.all(ControlContentMenu.shared(this.control).getStartTableContent(this.ViewController, this.reloadTable.bind(this)));
        }

        receivedZoneFavoritesReloadEvent() {
            Debug.Control.AudioZone.StartOverview && console.log(this.viewId, "receivedZoneFavoritesReloadEvent");
            return this._reloadSectionWithClass(ControlContentMenu.SECTION_ID.FAVS);
        }

        receivedServiceReloadEvent() {
            Debug.Control.AudioZone.StartOverview && console.log(this.viewId, "receivedServiceReloadEvent");
            return this._reloadSectionWithClass(ControlContentMenu.SECTION_ID.SPOTIFY_PLAYLISTS);
        }

        onSectionPatch(delegateId, section, sectionIdx) {
            if (this.tableContent) {
                var needsReload = false;
                this.tableContent.splice(sectionIdx, 1, section);
                needsReload |= this.tableViewDataSource.update(this.tableContent);
                needsReload |= this.tableViewDelegate.update(this.tableContent);

                if (needsReload) {
                    return this.tableView.reload();
                } else {
                    return Q.resolve();
                }
            }
        }

        _shouldShowFavorites() {
            // We have our own fav implementation
            return false;
        }

        _onHistoryChange() {
            return this._reloadSectionWithClass(ControlContentMenu.SECTION_ID.HISTORY);
        }

        _onCustomizationChange() {
            return this.reloadTable();
        }

        _reloadSectionWithClass(className) {
            if (!this.isInEditMode) {
                return this.setTableContent().then(function (needsReload) {
                    if (needsReload) {
                        // The section doesn't exist anymore, reload the whole table
                        return this.tableView.reload();
                    } else {
                        return Q.resolve();
                    }
                }.bind(this));
            }
        }

    };
});
