import {LxReactScreenAdapter,useCCEvent, TopNavigator, TransparentTheme} from "LxComponents"
import {NavigationContainer, createNavigationContainerRef, StackActions} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import React, {createContext, useCallback, useMemo} from "react";
import {getViewControllerDummy} from "./navigation/LxReactNavigationBarHelper";
import {Host} from 'react-native-portalize';
import ActiveMiniserverScreen from "../GUI/ActiveMiniserver/activeMiniserverScreen/activeMiniserverScreen.jsx";
import UserManagementNavigator from "../GUI/ActiveMiniserver/menu/miniserverSettings/userManagement/userManagementNavigator";
import UserDetailsScreen  from "../GUI/ActiveMiniserver/menu/miniserverSettings/userManagement/userDetails/userDetailsScreen";
import UserManagementScreen from "../GUI/ActiveMiniserver/menu/miniserverSettings/userManagement/userManagementScreen";
import TrustPeerUserSelectScreen from "../GUI/ActiveMiniserver/menu/miniserverSettings/userManagement/trustUsers/trustPeerUserSelectScreen";
import UserGroupsScreen from "../GUI/ActiveMiniserver/menu/miniserverSettings/userManagement/userDetails/userGroupsScreen/userGroupsScreen";
import CurrentUserScreen from "../GUI/ActiveMiniserver/menu/miniserverSettings/currentUser/currentUserScreen";
import ChangeSecretScreen from "../GUI/ActiveMiniserver/menu/miniserverSettings/changeSecret/changeSecretScreen";
import AmbientScreen from "./AmbientMode/screens/AmbientScreen";
import AmbientShortcutSelection from "./AmbientMode/screens/AmbientShortcutSelection"
import MessageOverviewScreen from "./LxReactMessageCenter/MessageOverviewScreen";
import MessageDetailScreen from "./LxReactMessageCenter/MessageDetailScreen";
// region Bottom Sheet quirks
//https://github.com/necolas/react-native-web/issues/1891
import "setimmediate"
import EcoScreenSettingsNavigator from "./EcoScreen/EcoScreenSettingsNavigator";
import EcoScreen from "./EcoScreen/EcoScreen";
import {getScreenStatesFromReactState} from "./navigation/LxReactNavigationStateAnalyzer";
import AmbientNewWallpaperPreview from "./AmbientMode/screens/AmbientNewWallpaperPreview";
import AmbientSettingsNavigator from "./AmbientMode/AmbientSettingsNavigator";
import AmbientOnboardingNavigator from "./AmbientMode/onboarding/AmbientOnboardingNavigator";
import LxReactSelectorScreen from "./LxReactSelectorScreen";
import QuickActionsNavigator from "./QuickActions/QuickActionsNavigator";
import PairedAppIdentify from "./PairedApp/PairedAppIdentify";

// endregion
if (!window.__reactNavigation) {
    window.__reactNavigation = createNavigationContainerRef();
}

const navigationRef = window.__reactNavigation;

const Navigation = {
    navigate: (...params) => {
        navigationRef.isReady() && navigationRef.navigate(...params);
    },
    reset: (...params) => {
        navigationRef.isReady() && navigationRef.reset(...params);
    },
    goBack: (...params) => {
        navigationRef.isReady() && navigationRef.goBack(...params);
    },
    canGoBack: (...params) => {
        navigationRef.isReady() && navigationRef.canGoBack(...params);
    },
    push: (...params) => {
        navigationRef.isReady() && navigationRef.current.dispatch(StackActions.push(...params));
    },
    popToTop: () => {
        debugger;
    },
    parent: () => {
        debugger;
    }
}

const getAllPossibleLegacyScreens = () => {
    let screenStateArray = [];

    const flattenScreenState = (screenState) => {
        if (typeof screenState === "string") {
            !!nullEmptyString(screenState) && screenStateArray.push(screenState)
        } else if (typeof screenState === "object") {
            Object.values(screenState).forEach(flattenScreenState);
        }
    }

    flattenScreenState(ScreenState);
    return [
        ...[
            GUI.AudioZoneControlContentViewControllerReactAbstractor.name,
            GUI.AudioZoneV2ControlContentViewControllerReactAbstractor.name,
            ...screenStateArray
        ].filter(screenState => {
            return ![
                ScreenState.RootViewController,
                ScreenState.ActiveMiniserverScreen,
                ScreenState.UserManagementNavigator,
                ScreenState.CurrentUserScreen,
                ScreenState.UserManagementScreen,
                ScreenState.TrustPeerUserSelectScreen,
                ScreenState.UserDetailsScreen,
                ScreenState.UserGroupsScreen,
                ScreenState.ChangeSecretScreen,
                ScreenState.EcoScreenSettingsScreen,
                ScreenState.MessageCenterMessagesScreen,
                ScreenState.MessageCenterMessageScreen,
                ScreenState.AmbientScreen,
                ScreenState.AmbientSettingsScreen,
                ScreenState.QuickActions,
                ScreenState.PairedAppIdentify
            ].includes(screenState);
        }),
        ActiveMiniserverScreen,
        UserManagementNavigator,
        CurrentUserScreen,
        UserManagementScreen,
        TrustPeerUserSelectScreen,
        UserDetailsScreen,
        UserGroupsScreen,
        ChangeSecretScreen,
        EcoScreen,
        AmbientScreen,
        PairedAppIdentify,
        AmbientShortcutSelection,
        AmbientNewWallpaperPreview,
        AmbientSettingsNavigator,
        EcoScreenSettingsNavigator,
        MessageOverviewScreen,
        MessageDetailScreen,
        QuickActionsNavigator,
        LxReactSelectorScreen
    ]
}

export const UrlContext = createContext({
    url: "",
    setUrl: (url) => {
    }
})

function App(props) {

    const Root = createStackNavigator();

    useCCEvent([CCEvent.EcoScreenDarkenerActive], useCallback((event, {dark, src}) => {
        Debug.EcoScreen && console.log("AppJSX - EcoScreenExt", "EcoScreenDarkenerActiveChanged: dark=" + dark + ", src=" + src)
        let currentScreenStates = [];
        getScreenStatesFromReactState(navigationRef.getState(), currentScreenStates)
        const ecoScreenIdx = currentScreenStates.indexOf(EcoScreen.name);
        const ecoScreenOnTop = ecoScreenIdx === currentScreenStates.length - 1;

        // only needs to show the eco screen, dismiss is handled by the eco screen itself.
        if (dark && !ecoScreenOnTop) {
            Debug.EcoScreen && console.log("AppJSX - EcoScreenExt", "      navigate to ecoScreen!", Root);
            navigationRef.navigate(EcoScreen.name);
        } else {
            Debug.EcoScreen && console.log("AppJSX - EcoScreenExt", "      nothing to do!");
        }
    }, []));

    const value = useMemo(() => ({
        setUrl: ({urlPart, replace, pop, notDuplicate, appendIndex, nPop= 1}) => {
            let url = window.CURRENT_URL;
            let newURL = "";

            if (pop) {
                if (urlPart) {
                    newURL = url.replace("/" + urlPart, "")
                } else if (appendIndex) {
                    let urlParts = url.split("/");
                    urlParts.splice(appendIndex, nPop);
                    newURL = urlParts.join("/");
                } else {
                    newURL = url.split("/").slice(0, nPop * -1).join("/");
                }
            } else if (urlPart) {
                if (notDuplicate && url.toLowerCase().includes(urlPart.toLowerCase())) {
                    return;
                }
                if (replace) {
                    newURL = urlPart;
                } else if (appendIndex) {
                    let currentUrlParts = url.split("/");
                    if (appendIndex >= 0 && appendIndex < currentUrlParts.length) {
                        currentUrlParts.splice(appendIndex, 0, urlPart);
                        newURL = currentUrlParts.join("/");
                    } else {
                        newURL = url + "/" + urlPart;
                    }
                } else {
                    newURL = url + "/" + urlPart
                }
            }
            NavigationComp && NavigationComp.updateURL(newURL);
        }
    }), []);
    const initialRoute = useMemo(() => {
        let initialScreenState = ScreenState.Archive;
        if (PlatformComponent.getPlatformInfoObj().platform !== PlatformType.Webinterface) {

            if (!Object.keys(PersistenceComponent.getAllMiniserver()).length) {
                if (PlatformComponent.getNetworkStatus().status === NetworkStatus.LAN && MiniserverFinder.hasMSFinder()) {
                    var platform = PlatformComponent.getPlatformInfoObj().platform;

                    if (platform === PlatformType.IOS) {
                        initialScreenState = ScreenState.Welcome;
                    } else {
                        initialScreenState = ScreenState.InitialSearch
                    }
                } else {
                    initialScreenState = ScreenState.Welcome;
                }
            } else {

                var lastURLMS = PersistenceComponent.getLastConnectedMiniserver();

                if (PersistenceComponent.backupAndSyncGetLastConnectedDeleted()) {
                    NavigationComp.showPopup({
                        title: _("backup-and-sync.popup.not-found.title"),
                        message: _("backup-and-sync.popup.not-found.message", {
                            name: lastURLMS.msName
                        }),
                        buttonOk: true
                    });
                } else if (lastURLMS) {
                    initialScreenState = ScreenState.ConnectingWaiting;
                }
            }
        }

        return initialScreenState;
    }, []);

    const getSafeAreaProviderStyle = () => {
        if (PlatformComponent.isMacOS()) {
            return {
                paddingTop: 25,
                WebkitAppRegion: "drag"
            }
        }
    }

    return (
        <SafeAreaProvider style={getSafeAreaProviderStyle()}>
            <Host>
                <UrlContext.Provider value={value}>
                    <NavigationContainer
                        ref={navigationRef}
                        theme={TransparentTheme}
                        documentTitle={defaultNavProps.title}
                        style={styles.navigationContainer}
                    >
                        <Root.Navigator
                            initialRouteName={initialRoute}
                            screenOptions={{
                                ...TopNavigator.forDefault()
                            }}>
                            {
                                App.DisconnectedState().routeNames.map(screenState => {
                                    return <Root.Screen
                                        key={screenState}
                                        name={screenState}
                                        component={LxReactScreenAdapter}
                                    />
                                })
                            }
                        </Root.Navigator>
                    </NavigationContainer>
                </UrlContext.Provider>
            </Host>
        </SafeAreaProvider>
    )
}

const defaultNavProps = {
    title: {
        enabled: false
    }
}

const styles = {
    navigationContainer: {
        WebkitAppRegion: "no-drag"
    }
}

App.RootViewController = getViewControllerDummy({
    navigation: Navigation
})

App.ActiveMiniserverDummyVC = () => {
    let element = $("root-view");

    return getViewControllerDummy({
        navigation: Navigation
    }, null, {
        appendSubview: (subview, target = element) => {
            return Q(subview.viewDidLoad()).done(() => {
                target.append(subview.getElement());
                return Q(subview.viewWillAppear()).then(() => {
                    return Q(subview.viewDidAppear());
                })
            })
        },
        removeSubview: (subview) => {
            return Q(subview.viewWillDisappear()).done(() => {
                return Q(subview.viewDidDisappear()).then(() => {
                    subview.getElement().remove();
                })
            })
        },
        get element() {
            return element;
        }
    }, GUI.ActiveMiniserverViewController.prototype)
}

/**
 * Generates the root state
 * @returns {{routes: [{name: (string|*)}], index: number}}
 * @constructor
 */
App.ActiveMiniserverState = () => {
    return {
        index: 1,
        routeNames: [
            ScreenState.ActiveMiniserver,
            ScreenState.InitialSearch,
            ScreenState.Welcome,
            ScreenState.Archive,
            ScreenState.MiniserverSearch,
            ScreenState.EnterUrl,
            ScreenState.Credentials,
            ScreenState.ConnectingWaiting,
            ScreenState.Error,
            ScreenState.DrawerViewController,
            ScreenState.AboutMiniserver,
            AmbientOnboardingNavigator.name
        ],
        routes: [
            {
                name: ScreenState.Archive
            },
            {
                name: ScreenState.ActiveMiniserver,
                state: {
                    index: 0,
                    routeNames: [
                        ...getAllPossibleLegacyScreens().filter(screenState => {
                            return ![
                                // RootVC
                                ScreenState.ActiveMiniserver,
                                ScreenState.InitialSearch,
                                ScreenState.Welcome,
                                ScreenState.Archive,
                                ScreenState.MiniserverSearch,
                                ScreenState.EnterUrl,
                                ScreenState.Credentials,
                                ScreenState.ConnectingWaiting,
                                ScreenState.Error,
                                // ActiveMSVC
                                ScreenState.FavoritesV2,
                                ScreenState.House,
                                ScreenState.Rooms,
                                ScreenState.Categories,
                                //ScreenState.GroupCardContent
                            ].includes(screenState)
                        })
                    ],
                    routes: [
                        {
                            name: ScreenState.ActiveMiniserverScreen,
                            routeNames: [
                                ScreenState.FavoritesV2,
                                ScreenState.House,
                                ScreenState.Rooms,
                                ScreenState.Categories
                            ]
                        }
                    ]
                }
            }
        ]
    }
}

/**
 * Generates the navigation state outside of the active miniserver screen and takes an initial route as an argument
 * @param initialState ScreenState that should be shown first
 * @param params Params for the shown ScreenState
 * @returns {{routeNames: (string|*)[], routes: [{name: (string|*)}], index: number}}
 * @constructor
 */
App.DisconnectedState = (initialState = null, params = null) => {
    let state = {
        index: 0,
        routeNames: [
            ScreenState.ActiveMiniserver,
            ScreenState.InitialSearch,
            ScreenState.Welcome,
            ScreenState.Archive,
            ScreenState.MiniserverSearch,
            ScreenState.EnterUrl,
            ScreenState.Credentials,
            ScreenState.ConnectingWaiting,
            ScreenState.Error,
            ScreenState.AboutMiniserver,
            ScreenState.Menu,
            ScreenState.LoxoneBranding,
            ScreenState.AboutApp,
            ScreenState.Licenses,
            ScreenState.LicenseDetail,
            ScreenState.PresentationSettings,
            ScreenState.LoxoneControlSettings,
            ScreenState.SecuritySettings,
            ScreenState.ModalViewController,
            "AppPairingScreen"
        ],
        routes: []
    }

    if (initialState) {
        if (initialState !== ScreenState.Archive && initialState !== ScreenState.Welcome) {
            state.routes.push({
                name: ScreenState.Archive,
                params: {}
            })
        }

        state.routes.push({
            name: initialState,
            params
        })
    }

    return state;
}

App.navigationRef = navigationRef;

export default App;


export {
    getAllPossibleLegacyScreens,
    Navigation
}


