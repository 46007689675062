import CardScreen from "../../ViewController/cardScreen/cardScreen.jsx";
import Icons from "IconLib";
import {useCallback, useRef} from "react";
import {useSetURLHook} from "LxComponents";

function HouseScreen({navigation, route}) {

    useSetURLHook({
        urlPart: UrlStartLocation.CENTRAL
    });

    let cardHeaderTitle = _('central');
    const childRef = useRef(null);

    const _getContent = useCallback(() => {
        if (!Feature.HOUSE_TAB) {
            // also show the update hint to all users, even if the would have something to show
            return [];
        }

        let section,
            content = [],
            sortingAttribute = ActiveMSComponent.getDeviceFavoritesActive() ? "manualRating" : "defaultRating",
            // already filters out controls that have no rating
            centralContent = ActiveMSComponent.getStructureManager().getCentralControls(),
            forceCellUI = !NavigationComp.tileRepresentationEnabled(); // the sections (central rooms) are sorted by the room rating

        centralContent.sort((a, b) => {
            if (a.room[sortingAttribute] === b.room[sortingAttribute]) {
                return a.room.name.toUpperCase().localeCompare(b.room.name.toUpperCase());
            }

            return a.room[sortingAttribute] < b.room[sortingAttribute] ? 1 : -1;
        }); // 1 section per central room, all controls with rating > 0, sorted by rating

        centralContent.forEach((roomContent, idx) => {
            section = {
                headerTitle: roomContent.room.name,
                didSelectHeader: () => {
                    NavigationComp.showGroupContent(roomContent.room.groupType, roomContent.room.uuid)
                },
                rows: []
            };
            roomContent.controls.forEach((control, idx) => {
                let hideActionElements = control.isGrouped() && control.isInCentralRoom();
                section.rows.push({
                    type: control.getCellType(),
                    content: {
                        control: control,
                        displayAsCell: forceCellUI,
                        displayRoomName: false,
                        showGroupDetails: true,
                        hideActionElements: hideActionElements
                    }
                });
            });

            if (section.rows.length > 0) {
                section.rows.sort( (a, b) =>  {
                    if (!ActiveMSComponent.getSortingStructure()) {
                        // old star sorting
                        if (a.content.control[sortingAttribute] === b.content.control[sortingAttribute]) {
                            return a.content.control.name.toUpperCase().localeCompare(b.content.control.name.toUpperCase());
                        }

                        return a.content.control[sortingAttribute] < b.content.control[sortingAttribute] ? 1 : -1;
                    } else {
                        // new sorting
                        let sortingA = ActiveMSComponent.getSortingStructureForObject(a.content.control.uuidAction, UrlStartLocation.ROOM);
                        let sortingB = ActiveMSComponent.getSortingStructureForObject(b.content.control.uuidAction, UrlStartLocation.ROOM);

                        if (sortingA && sortingB) {
                            return sortingA.position > sortingB.position ? 1 : -1;
                        } else {
                            return 0;
                        }
                    }
                });
                section.displayAsCard = true;
                section.headerTitle = roomContent.room.name;

                content.push(section);
            }
        });

        if (content && content.length) {
            cardHeaderTitle = content[0].headerTitle;
            delete content[0].headerTitle;
        }

        return content;
    }, [])

    const getEmptyScreenConfig = () => {
        if (Feature.HOUSE_TAB) {
            return {
                iconSrc: Icons.Central,
                title: _('house.empty.title'),
                subTitle: _('house.empty.subtitle'),
                message: _getEmptyScreenMessage()
            };
        } else {
            return {
                iconSrc: Icons.Central,
                title: _('house.update.title'),
                subTitle: _('house.update.subtitle'),
                message: _('house.update.message'),
                significantMsg: _('house.update.significant-message'),
                button: {
                    title: _('update.more-info'),
                    link: PLEASE_UPDATE_LINK
                }
            };
        }
    }

    /**
     * As it's unclear what rooms are of the type central, it may be a good idea to list them. This way the
     * customer can adopt the ratings there himself.
     * @private
     */
    const _getEmptyScreenMessage = () => {
        var message = _("house.empty.message"),
            rooms = ActiveMSComponent.getStructureManager().getCentralRooms(),
            examples = "",
            limit = 3;

        if (rooms.length > 0) {
            for (var i = 0; i < limit && i < rooms.length; i++) {
                if (i > 0) {
                    examples += ", ";
                }

                examples += rooms[i].name;
            }

            if (rooms.length >= limit) {
                examples += ", ...";
            }

            message += "<br>" + "(" + _("for-example", {
                example: examples
            }) + ")";
        } else {
            message += "<br><br>" + _("house.empty.contact-partner");
        }

        return message;
    }

    const getContextMenuOptions = useCallback(() => {
        let contextMenuOptions = [];

        if (childRef.current) {
            contextMenuOptions.pushObject(childRef.current.getSortingOption());
        }

        contextMenuOptions.push({
            title: _('help'),
            action: () => {
                NavigationComp.showPopup({
                    title: _("help"),
                    message: _getEmptyScreenMessage(),
                    buttonOk: true,
                    icon: Icon.INFO
                });
            }
        });

        return contextMenuOptions;
    } ,[])

    const _getFavForIndex = useCallback((section, row) => {
        return true;
    } ,[])

    const _getSortingLocation = useCallback((section, tableContent) => {
        return UrlStartLocation.ROOM;
    } ,[])

    const getSortingContextMenuOptions = useCallback((cell) => {
        let defaultContent = childRef.current.getSortingContextMenuOptions(cell),
            contextMenuContent = [];

        contextMenuContent.pushObject(_createRemoveControlFavoriteTab(cell.content.control));

        contextMenuContent = contextMenuContent.concat(defaultContent);

        return contextMenuContent;
    } ,[])

    const _createRemoveControlFavoriteTab = (control) => {
        if (ActiveMSComponent.isExpertModeLightEnabled() && !NavigationComp.getCurrentActivityType()) {
            return {
                title: _("context-menu.remove-favorite"),
                action: () => {
                    childRef.current.removeAsFavorite(control, UrlStartLocation.FAVORITES)
                }
            }
        } else {
            return null;
        }
    }

    const configRef = useRef( {
        emptyScreen: getEmptyScreenConfig(),
        initialContent : _getContent()
    });

    return <CardScreen
        ref={childRef}
        tableContentFromParent={configRef.current.initialContent}
        emptyScreenConfig={configRef.current.emptyScreen}
        getTableContent={_getContent}
        title={cardHeaderTitle}
        tabBarLabel={_('central')}
        contextMenuOptions={getContextMenuOptions}
        getFavForIndex={_getFavForIndex}
        getSortingLocation={_getSortingLocation}
        getSortingContextMenuOptions={getSortingContextMenuOptions}
    />
}

export default HouseScreen;
