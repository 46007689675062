'use strict';

define(["ControlActionCardsScreen"], function (ControlActionCardsScreen) {
    return class LightCentralControlContent extends ControlActionCardsScreen {
        constructor() {
            super(...arguments);
        }

        getActionSection(states) {
            var section = super.getActionSection(...arguments),
                selectedControls = this.control.getSelectedControls();

            if (selectedControls.length === 1) {
                var moodsCell = this._createCellObjectForCmd({
                        name: _("controls.lightCentralController.moods"),
                        cmd: this.handleDetailsBtn.bind(this)
                    }),
                    selectedControl = selectedControls[0],
                    selectedControlStates = selectedControl.getStates();

                if (selectedControlStates) {
                    if (selectedControlStates.isOn) {
                        section.rows[1] = moodsCell;
                    } else {
                        // Remove the first cell (Off cell)
                        section.rows.shift();
                        section.rows.push(moodsCell);
                    }
                }
            }

            return section;
        }

        _updateControlSelection() {
            super._updateControlSelection(...arguments);

            this._updateCommandsTable(this.states);
        }

    };
});
