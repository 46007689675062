'use strict';

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.MENU_SYSTEM_STATE_CELL = "MenuSystemStateCell";

    class MenuCell extends GUI.TableViewV2.Cells.GeneralCell {
        constructor() {
            super(...arguments);
            this.element.addClass("menu-system-state-cell");
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                if (this.content.sourceUuids && !this.deregMessageCenterUpdateReceiver) {
                    this.deregMessageCenterUpdateReceiver = ActiveMSComponent.registerSourceUuidForMessageCenterUpdate(this._messageCenterUpdateReceived.bind(this), this.content.sourceUuids);
                }
            }.bind(this));
        }

        destroy() {
            this.deregMessageCenterUpdateReceiver && this.deregMessageCenterUpdateReceiver();
            return super.destroy(...arguments);
        }

        setContent(content) {
            delete content.disclosureText;
            this.content = content;

            if (this.content.sourceUuids) {
                this._messageCenterUpdateReceived(null, MessageCenterHelper.findActiveEntriesWithSourceUuid(this.content.sourceUuids), this.content.sourceUuids);
            }
        }

        setDisclosureTextColor(color) {
            super.setDisclosureTextColor(...arguments);

            if (this.elements.disclosureLabel && color) {
                GUI.animationHandler.setCssAttr(this.elements.disclosureLabel, "backgroundColor", color ? applyAlphaChannelToColorString(color, 0.4) : "");
            }
        }

        /**
         * We got a messageCenterUpdate for the registered sourceUuid
         * @param ev
         * @param entries Array of entries for the registered sourceUuid
         * @param sourceUuids The registered sourceUuid
         * @private
         */
        _messageCenterUpdateReceived(ev, entries, sourceUuids) {
            if (entries && entries.length) {
                this.content.disclosureText = entries.length.toString();
                this.content.disclosureTextColor = MessageCenterHelper.getColorForSeverityEntry(entries[0]);
            } else {
                this.content.disclosureText = null;
                this.content.disclosureTextColor = null;
            }

            this.setDisclosureText(this.content.disclosureText, this.content.disclosureText1);
            this.setDisclosureTextColor(this.content.disclosureTextColor, this.content.disclosureColor1);
        }

    }

    GUI.TableViewV2.Cells.MenuSystemStateCell = MenuCell;
    return GUI;
}(window.GUI || {});
