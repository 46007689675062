import * as React from "react"
import Svg, { Path, Rect } from "react-native-svg"

function Weather13(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            <Path
                d="M3 16.2a4.79 4.79 0 012.44-4.158A7.26 7.26 0 0112.75 5a7.333 7.333 0 016.22 3.412A5.36 5.36 0 0120.47 8.2a5.28 5.28 0 014.944 3.37A4.793 4.793 0 0124.125 21H7.875A4.838 4.838 0 013 16.2z"
                fill="#d5e2e9"
                fillRule="evenodd"
            />
            <Path
                fill="#d5e2e9"
                d="M8.303 28.997L8.65 27.739 9.873 27.695 8.616 27.348 8.571 26.124 8.224 27.382 7.001 27.426 8.259 27.773 8.303 28.997z"
            />
            <Path
                fill="#d5e2e9"
                d="M6.272 25.935L6.332 24.85 7.32 24.598 6.235 24.537 5.983 23.55 5.923 24.634 4.935 24.887 6.02 24.947 6.272 25.935z"
            />
            <Path
                fill="#d5e2e9"
                d="M28.052 26.241L28.112 25.157 29.1 24.904 28.015 24.844 27.763 23.856 27.703 24.941 26.715 25.193 27.8 25.253 28.052 26.241z"
            />
            <Path
                fill="#d5e2e9"
                d="M23.172 26.049L24.064 27.278 25.417 26.826 24.188 27.72 24.64 29.072 23.747 27.842 22.394 28.295 23.624 27.401 23.172 26.049z"
            />
            <Rect
                x={12.74093}
                y={22.96617}
                width={2}
                height={4}
                rx={1}
                ry={1}
                transform="rotate(15 13.74 24.966)"
                fill="#51a9df"
            />
            <Rect
                x={16.74093}
                y={22.96617}
                width={2}
                height={4}
                rx={1}
                ry={1}
                transform="rotate(15 17.74 24.966)"
                fill="#51a9df"
            />
        </Svg>
    )
}

export default Weather13
