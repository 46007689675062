'use strict';

window.GUI = function (GUI) {
    var __cellCacheInstance = null;

    class TableViewCellCache {
        constructor() {
            this.name = "TableViewCellCache";
            this._cellCache = {};
        }

        storeReusableCell(reuseID, cell) {
            Debug.GUI.TableViewCellCache && console.log(this.name, "storeReusableCell: " + reuseID);
            this._cellCache[reuseID] = this._cellCache[reuseID] || [];

            this._cellCache[reuseID].push(cell);

            return true;
        }

        getReusableCell(reuseID) {
            var cell = null,
                candidates = this._cellCache[reuseID];

            if (candidates && candidates.length > 0) {
                for (var i = candidates.length - 1; i >= 0 && !cell; i--) {
                    if (candidates[i].isReadyForReuse()) {
                        cell = this._cellCache[reuseID].splice(i, 1)[0];
                    }
                }

                if (!cell) {
                    console.warn("Cannot reuse any of the " + candidates.length + " instances of " + reuseID + " - viewDidDisappear/viewWillAppear not passed yet");
                }
            }

            if (cell) {
                Debug.GUI.TableViewCellCache && console.log(this.name, "getReusableCell: " + reuseID + " --> success!");
            } else {
                Debug.GUI.TableViewCellCache && console.log(this.name, "getReusableCell: " + reuseID + " --> not found yet..");
            }

            return cell;
        }

        destroy() {
            Object.values(this._cellCache).forEach(function (cells) {
                cells.forEach(function (cell) {
                    cell.destroy();
                });
            }.bind(this));
            delete this._cellCache;
        }

    }

    GUI.TableViewCellCache = TableViewCellCache;

    GUI.getTableViewCellCache = function getTableViewCellCache() {
        if (__cellCacheInstance === null) {
            __cellCacheInstance = new GUI.TableViewCellCache();
        }

        return __cellCacheInstance;
    };

    return GUI;
}(window.GUI || {});
