'use strict';

define(['DaytimerControlEnums'], function (DaytimerControlEnums) {
    return class OperationModeBar extends GUI.View {
        //region Static
        static Template = function () {
            var createBar = function createBar() {
                return $('' + '<div class="operation-mode-bar">' + '<div class="operation-mode-bar__edit-btn clickable">' + ImageBox.getResourceImageWithClasses(Icon.SETTINGS, "edit-btn__image") + '</div>' + '</div>' + '');
            };

            return {
                createBar: createBar
            };
        }(); //endregion Static

        constructor(ctrl) {
            super(OperationModeBar.Template.createBar());
            Object.assign(this, StateHandler.Mixin);
            this.control = ctrl;
            this.selectedEntry = null;
            this.modes = [];
            this.selectable = false;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.modeList = new GUI.HorizontalSelector(this, this, true);
            this.modeList.getElement().addClass('operation-mode-bar__mode-list');
            this.appendSubview(this.modeList);

            if (!this.elements) {
                this.elements = {};
            }

            this.elements.modeButton = this.element.find(".operation-mode-bar__edit-btn");
            this.buttons = {
                modeButton: new GUI.LxButton(this, this.elements.modeButton[0], Color.BUTTON_GLOW)
            };
            this.addToHandledSubviews(this.buttons.modeButton);
            this.buttons.modeButton.useChildsAsActiveParts('fill');
            this._boundScrollHandler = this._dispatchOnScroll.bind(this);
            this.element.toggleClass("operation-mode-bar--hd", HD_APP);
        }

        viewWillAppear() {
            // Request state updates before the subviews receive viewWillAppear in order to avoid issues
            // in the lifecycle methods if a subview is to be hidden/removed based on the states.
            // Lengthy tasks in received states should be performed async after viewDidAppear was called.
            this._requestStates();

            super.viewWillAppear(...arguments);
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);

            this._registerForStates();

            this.buttons.modeButton.onButtonReleased = function onButtonReleased() {
                this.ViewController.showState(DaytimerControlEnums.ScreenState.OPERATING_MODES, null, {
                    control: this.control,
                    isEditMode: true
                });
            }.bind(this);

            this.modeList.onScroll = this._boundScrollHandler;
        }

        viewWillDisappear() {
            this._unregisterStates();

            this.buttons.modeButton.onButtonReleased = null;
            this.modeList.onScroll = null;
            super.viewWillDisappear(...arguments);
        }

        scrollTo(x, y) {
            this.modeList.scrollTo(x, y);
        }

        selectedMode(modeNr) {
            Debug.Control.Daytimer.OperationModeBar && console.log(this.name, "selectedMode: " + modeNr);
            this.selectedEntry = modeNr;

            this._updateSelector();
        }

        receivedStates(states) {
            Debug.Control.Daytimer.OperationModeBar && console.log(this.name, "receivedStates");
            this.states = cloneObjectDeep(states);
            this.modes = [];

            for (var i = 0; i < states.usedModes.length; i++) {
                this.modes[i] = {
                    nr: states.usedModes[i],
                    name: SandboxComponent.getStructureManager().getOperatingModes(states.usedModes[i])
                };
            }

            this._updateSelector();
        }

        setSelectable(selectable) {
            Debug.Control.Daytimer.OperationModeBar && console.log(this.name, "setSelectable: " + selectable);

            if (!selectable) {
                this.selectedEntry = null;
            }

            if (this.selectable != selectable) {
                this.selectable = selectable;

                this._updateSelector();
            }
        }

        // ----------------------------------------------------------------------
        //         HorizontalSelector
        // ----------------------------------------------------------------------
        getSelectorStrings() {
            var i,
                strings = [];

            for (i = 0; i < this.modes.length; i++) {
                strings.push(this.modes[i].name);
            }

            return strings;
        }

        didSelectEntry(number) {
            this.selectedEntry = this.modes[number].nr;
            Debug.Control.Daytimer.OperationModeBar && console.log(this.name, "didSelectEntry: " + this.selectedEntry);
            this.onModeSelected && this.onModeSelected(this.selectedEntry);
        }

        isSelectionAllowed() {
            return this.selectable;
        }

        // ----------------------------------------------------------------------
        _dispatchOnScroll(x, y) {
            this.onScroll && this.onScroll(x, y);
        }

        _updateSelector() {
            if (this.selectable) {
                var lookForMode = "";

                if (this.selectedEntry != null) {
                    lookForMode += this.selectedEntry;
                } else {
                    lookForMode += this.states.activeMode;
                }

                Debug.Control.Daytimer.OperationModeBar && console.log(this.name, "_updateSelector: " + lookForMode);

                for (var i = 0; i < this.modes.length; i++) {
                    if (this.modes[i].nr === lookForMode) {
                        this.modeList.setSelectedIndex(i);
                    }
                }
            }

            this.modeList.reload();
        }

    };
});
