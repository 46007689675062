'use strict';

define(['LightControlManualList', 'LightControlSceneList', 'LightControlEditSceneScreen', 'LightControlSaveSceneScreen', 'ColorPickerColorSelection'], function (LightControlManualList, LightControlSceneList, LightControlEditSceneScreen, LightControlSaveSceneScreen, ColorPickerColorSelection) {
    return class LightControlContentOldHD extends Controls.ControlContent {

        get reactHeaderShown() {
            return false
        }

        constructor(details) {
            super(details);
            this.isLightControl = this.control.type === ControlType.LIGHT;
            this.sceneList = new LightControlSceneList(this.control);
            this.manualList = new LightControlManualList(this.control);
        }

        viewDidLoad() {
            let prmsArr = [];
            return Q(super.viewDidLoad(...arguments)).then(function () {
                this.container = $('<div class="light-control-content__container"></div>');
                return GUI.animationHandler.append(this.container, this.element).then(function () {
                    this.sceneList.sendCommand = this.sendCommand;
                    prmsArr.push(this.appendSubview(this.sceneList, this.container));
                    this.manualList.sendCommand = this.sendCommand;
                    prmsArr.push(this.appendSubview(this.manualList, this.container));

                    return Q.all(prmsArr)
                }.bind(this));
            }.bind(this));
        }

        getTitlebar() {
            return this.sceneList.titleBar;
        }

        getAnimation() {
            return AnimationType.HD_OVERLAY_FULLSCREEN;
        }

        receivedStates(states) {
            this.sceneList.update(states);
            this.manualList.update(states); // presence simulation

            if (this.control.presence) {
                this.titleBar.togglePresenceSimulation(states.presenceSimulation.active);
            }
        }

    };
});
