'use strict';

define([], function () {
    return {
        SceneNumbers: {
            MANUAL: -1,
            ALL_OFF: 0,
            ALL_ON: 9
        },
        ScreenState: {
            //SAVE_SCENE: 'Controls.LightControl.LightControlSaveSceneScreen', // the old one
            SAVE_SCENE: 'LightControlSaveSceneScreen',
            // the new one, based on tableView
            EDIT_SCENE: 'LightControlEditSceneScreen',
            CONTROL_CONTENT_OLD: "LightControlContentOld"
        }
    };
});
