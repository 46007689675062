'use strict';

define("DimmerControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class DimmerControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [];

            if (states.isOn) {
                cmds.push(this.createCommand(_("turn-off"), Commands.DIMMER.OFF));
            } else {
                cmds.push(this.createCommand(_("turn-on"), Commands.DIMMER.ON));
            }

            return cmds;
        }

    };
});
