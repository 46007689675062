'use strict';

define(["AudioZoneV2ControlEnums", "MediaBrowserV2Base"], function (AudioZoneV2ControlEnums, MediaBrowserV2Base) {
    class MediaBrowserV2Playlists extends MediaBrowserV2Base {
        //region Static
        static getCellFromContentTypeItem(item, details, contentType, isPlaylist, isBrowsable, actionClb, buttonTappedClb, vc, isInEditMode) {
            // Pretend it's not a playlist to get the correct TableViewCell type
            return Controls.AudioZoneV2Control.MediaBrowserV2Base.getCellFromContentTypeItem.apply(this, arguments);
        }

        static getCellButtonIconSrc() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Base.getCellButtonIconSrc.apply(this, arguments);
        }

        static getCellButtonIconColor() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Base.getCellButtonIconColor.apply(this, arguments);
        }

        static getCellTypeForItemCellAtIndex(item, contentType, isInEditMode) {
            return Controls.AudioZoneV2Control.MediaBrowserV2Base.getCellTypeForItemCellAtIndex.apply(this, arguments);
        }

        static getIconObjForItem(item, identifier) {
            var baseIconObj = Controls.AudioZoneV2Control.MediaBrowserV2Base.getIconObjForItem.apply(this, arguments);

            if (AudioserverComp.isFileTypePlaylist(item.type) && !baseIconObj.hasProvidedCover) {
                baseIconObj.highRes = Icon.AudioZone.NEW.PLAYLIST;
                baseIconObj.lowRes = Icon.AudioZone.NEW.PLAYLIST;
                baseIconObj.color = Color.TEXT_SECONDARY_B;
            }

            baseIconObj.fallback = Icon.AudioZone.NEW.PLAYLIST;
            return baseIconObj;
        } //endregion Static


        //region Getter
        get RIGHT_SIDE_BUTTON() {
            let baseVal = super.RIGHT_SIDE_BUTTON;
            baseVal.ADD_PLAYLIST = "right-side__touch-area--add-playlist";
            return baseVal;
        }

        get desc() {
            return _("media.playlists");
        } //endregion Getter


        constructor(details) {
            super(...arguments);
            applyMixins(this, MediaBrowserEditablePlaylistMixinV2.Mixin);
        }

        /**
         * Needs to restart the edit mode - e.g. while editing a playlist, the add items context menu could be overlayed.
         * @returns {*}
         */
        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function () {
                if (this.editModeActive) {
                    return this.startEditing(this.lastSelectedItem, this.lastSelectedItem.contentType, this.getMediaInfo(this.lastSelectedItem.contentType), this.lastSelectedItem.id, true);
                }
            }.bind(this));
        }

        viewWillDisappear() {
            if (this.editModeActive) {
                this.stopEditing(this.lastSelectedItem.id);
            }

            return super.viewWillDisappear(...arguments);
        }

        titleBarActionRight(buttonId) {
            var args = arguments;

            if (buttonId === this.RIGHT_SIDE_BUTTON.SAVE && this.editModeActive) {
                return this.stopEditing(this.lastSelectedItem.id).then(() => {
                    return super.titleBarActionRight(...args);
                });
            } else if (buttonId === this.RIGHT_SIDE_BUTTON.ADD_PLAYLIST) {
                this.createPlaylist();
            } else {
                return super.titleBarActionRight(...args);
            }
        }

        getTableContent() {
            var baseTableContent = super.getTableContent(...arguments); // Only show the "add" cell if we don't display a Playlist

            if (this._shouldOfferNewPlaylist()) {
                if (!baseTableContent[0] || !baseTableContent[0].hasOwnProperty("rows")) {
                    baseTableContent[0] = {
                        rows: []
                    };
                }

                baseTableContent[0].rows.unshift({
                    content: {
                        clickable: true,
                        title: _("media.playlist.create"),
                        leftIconSrc: Icon.TitleBar.PLUS,
                        leftIconColor: window.Styles.colors.green,
                        leftIconBgEnabled: true,
                        disclosureIcon: false
                    },
                    action: this.createPlaylist.bind(this)
                });
            }

            return baseTableContent;
        }

        getEmptyViewIconSrc() {
            if (this.hasError) {
                return super.getEmptyViewIconSrc(...arguments);
            }

            return this.receivedContent ? null : super.getEmptyViewIconSrc(...arguments);
        }

        getEmptyViewTitle() {
            if (this.hasError) {
                return super.getEmptyViewTitle(...arguments);
            }

            return this.receivedContent ? _("media.playlist.empty.title") : super.getEmptyViewTitle(...arguments);
        }

        getEmptyViewButtonText() {
            if (this.hasError) {
                return super.getEmptyViewButtonText(...arguments);
            } else if (this.ViewController instanceof GUI.AddMediaViewControllerV2Base) {
                return this.receivedContent ? null : super.getEmptyViewButtonText(...arguments);
            } else {
                return this.receivedContent ? _("media.library.upload.title") : super.getEmptyViewButtonText(...arguments);
            }
        }

        emptyViewButtonAction() {
            this._addNewMusicToItem(this.lastSelectedItem, true);
        }

        createPlaylist() {
            var playlistCnt = this.contentTypeItems[MusicServerEnum.MediaContentType.PLAYLISTS].all.length,
                playlistPlaceholder = _("media.playlist.save.default-name", {
                    number: playlistCnt + 1
                }),
                id = this.getMediaInfo(this.lastSelectedItem.contentType);

            return this.control.audioserverComp.showNamePopup(_("media.playlist.create"), _("create"), playlistPlaceholder).then(function (plName) {
                return this.control.audioserverComp.createContainer(this.lastSelectedItem.contentType, id, plName).then(function (newPlaylistItem) {
                    this.control.audioserverComp.stopEditing();
                    this.control.audioserverComp.showConfirmationFeedback(_("media.notifications.playlist.new")); // Don't navigate into the newly created playlist if we are in the Playlist picker

                    if (this.ViewController instanceof GUI.AddMediaViewControllerV2PlaylistPicker) {
                        return Q(true);
                    } else {
                        return this.handleOnItemCellClicked(newPlaylistItem, newPlaylistItem.contentType);
                    }
                }.bind(this));
            }.bind(this));
        }

        getActionsForCtxAndItem(ctx, item) {
            var actions = [];

            switch (ctx) {
                case this.CONTEXT.LMS:
                    if (item === this) {
                        if (this._shouldOfferNewPlaylist()) {
                            actions.pushObject(this._getActionWithTmplForCtx({
                                content: {
                                    title: _("media.playlist.create"),
                                    leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_NEW
                                },
                                action: this.createPlaylist.bind(this)
                            }, ctx));
                        }
                    }

                    break;
            }

            actions = actions.concat(this._getEditableActionsForCtxAndItem.apply(this, arguments));
            return actions;
        }

        getRightVCButtonIcon() {
            if (!this.control.audioserverComp.isFileTypePlayable(this.lastSelectedItem.type, this.lastSelectedItem.contentType)) {
                return Icon.ADD;
            } else {
                return super.getRightVCButtonIcon(...arguments);
            }
        }

        getRightVCButtonIconFn() {
            if (!this.control.audioserverComp.isFileTypePlayable(this.lastSelectedItem.type, this.lastSelectedItem.contentType)) {
                return this.createPlaylist.bind(this);
            } else {
                return super.getRightVCButtonIconFn(...arguments);
            }
        }

        _getRightSideTitleBarButtons() {
            if (!this.control.audioserverComp.isFileTypePlayable(this.lastSelectedItem.type, this.lastSelectedItem.contentType)) {
                return [this._getRightSideTitleBarButtonElm(this.RIGHT_SIDE_BUTTON.ADD_PLAYLIST, Icon.TitleBar.PLUS)];
            } else {
                return super._getRightSideTitleBarButtons(...arguments);
            }
        }

        _shouldOfferNewPlaylist() {
            return !this.lastSelectedItem.fromSearch && // don't show create playlist from search result list.
                this.getMediaId() === 0 || this.ViewController instanceof GUI.AddMediaViewControllerV2Base && !(this.ViewController instanceof GUI.AddMediaViewControllerV2Playlist) && !(this.ViewController instanceof GUI.AddMediaViewControllerV2ZoneFavorite) && !this.control.audioserverComp.isFileTypePlayable(this.lastSelectedItem.type, this.lastSelectedItem.contentType);
        }

    }

    Controls.AudioZoneV2Control.MediaBrowserV2Playlists = MediaBrowserV2Playlists;
    return Controls.AudioZoneV2Control.MediaBrowserV2Playlists;
});
