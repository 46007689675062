'use strict';

define("AudioPlayerGroupControl", ["Control"], function (Control) {
    return class AudioPlayerGroupControl extends Control {
        constructor() {
            super(...arguments);
        }

    };
});
