import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"

function Manual(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Circle cx={12} cy={12} r={10} strokeWidth={1.5} fill="none"/>
            <Path
                d="M8 16V8l4 5 4-5v8"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
        </Svg>
    )
}

export default Manual
