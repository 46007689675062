'use strict';

define(["ControlCommandSrc", 'DaytimerControlEnums'], function (ControlCommandSrc, DaytimerControlEnums) {
    return class DaytimerControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [];

            if (states.daytimerState === DaytimerControlEnums.DaytimerState.NEEDS_ACTIVATION) {
                cmds.push(this.createCommand(_("controls.daytimer.activiate"), Commands.DAYTIMER.ACTIVATE, null));
            }

            if (states.daytimerState === DaytimerControlEnums.DaytimerState.TIMER) {
                cmds.push(this.createCommand(_("controls.daytimer.timer.end"), Commands.DAYTIMER.CANCEL_TIMER, null));
            }

            if (states.daytimerState !== DaytimerControlEnums.DaytimerState.TIMER && !states.resetActive) {
                cmds.push(this.createCommand(_("controls.daytimer.timer.start"), this.showTimer.bind(this, control), null))
            }

            return cmds;
        }

        showTimer(control) {
            NavigationComp.showState(DaytimerControlEnums.ScreenState.TIMER, { control });
        }
    };
});
