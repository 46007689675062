import Svg, { Path } from "react-native-svg"

function AutomaticDesigner(props) {
    return (
        <Svg isreact="true"
            height={24}
            viewBox="0 0 24 24"
            width={24}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M23.464.999a.477.477 0 01.377.134.49.49 0 01.151.268.477.477 0 01-.016.265l-3.987 18.44a.5.5 0 01-.73.332l-6.04-3.322-3.287 5.636c-.241.413-.85.279-.924-.16L9 22.5v-7.658L.319 11.466a.5.5 0 01-.093-.885l.083-.043 22.98-9.493A.477.477 0 0123.454 1zM10 15.346v5.304l2.343-4.016zM22.675 2.948l-9.173 8.518-3.155 2.929 8.806 4.843zm-1.55.073L1.844 10.985l7.545 2.934z"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default AutomaticDesigner
