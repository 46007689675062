'use strict';

define("IRCV2DaytimerControlStateContainer", ["DaytimerControlStateContainerBase", "IRoomControllerV2ControlEnums"], function (DaytimerControlStateContainerBase, IRoomControllerV2ControlEnums) {
    return class IRCV2DaytimerControlStateContainer extends DaytimerControlStateContainerBase {
        constructor(control) {
            super(control);
            this.iRoomControl = this.control.getParentControl();
        }

        prepareStates(newVals) {
            super.prepareStates(...arguments);
            this.states.activeMode = newVals[this.control.states.mode];

            this._prepareEntries(this.states.entries, this.states.activeTemperatures);
        }

        /**
         * Adopts the properties of every entry plus adds Eco-Modes everywhere no entry is made
         * @param entries
         * @private
         */
        _prepareEntries(entries) {
            var entry; // Modes without any entries are excluded from entries, add them because we need to add the Eco-Mode entry

            this.states.usedModes.forEach(function (modeNr) {
                modeNr = parseInt(modeNr);

                if (!entries.hasOwnProperty(modeNr)) {
                    entries[modeNr] = [];
                }
            });

            for (var modeNr in entries) {
                if (entries.hasOwnProperty(modeNr)) {
                    entries[modeNr] = this._addEcoModeEntries(entries[modeNr], modeNr); // add all the values need and remove the one we don't

                    for (var i = 0; i < entries[modeNr].length; i++) {
                        entry = entries[modeNr][i];
                        entry.tempMode = entry.value;
                        entry.targetTemp = this.iRoomControl.getTempStringOfMode(entry.tempMode);
                        entry.tempModeName = this.iRoomControl.getNameOfIRCTempMode(entry.tempMode);
                        delete entry.value;

                        if (!Feature.IRC_V2021) {
                            delete entry.needActivate;
                        } else if (entry.needActivate) {
                            entry.tempModeName += " " + _('controls.ircv2021.activate-when-presence-detected');
                        }
                    } // Its crucial to sort the entries. having an earlier entry in front of a later one corrupts the UI


                    entries[modeNr].sort(function (left, right) {
                        return left.from - right.from;
                    });
                }
            }
        }

        /**
         * Replaces any non existing mode with the Eco-Mode
         * @param entries
         * @param modeNr
         * @return {Array}
         * @private
         */
        _addEcoModeEntries(entries, modeNr) {
            var maxMinutesPerDay = 60 * 24,
                tmpEntries = [];

            if (entries.length) {
                entries.forEach(function (entry, idx) {
                    var nextEntry = entries[idx + 1],
                        prevEntry = entries[idx - 1];

                    if (!prevEntry && entry.from > 0) {
                        tmpEntries.push({
                            from: 0,
                            to: entry.from,
                            value: IRoomControllerV2ControlEnums.Mode.ECO,
                            equalModes: [modeNr]
                        });
                    } else if (prevEntry && prevEntry.to !== entry.from) {
                        tmpEntries.push({
                            from: prevEntry.to,
                            to: entry.from,
                            value: IRoomControllerV2ControlEnums.Mode.ECO,
                            equalModes: [modeNr]
                        });
                    }

                    tmpEntries.push(entry);

                    if (!nextEntry && entry.to < maxMinutesPerDay) {
                        tmpEntries.push({
                            from: entry.to,
                            to: maxMinutesPerDay,
                            value: IRoomControllerV2ControlEnums.Mode.ECO,
                            equalModes: [modeNr]
                        });
                    }
                });
            } else {
                tmpEntries.push({
                    from: 0,
                    to: maxMinutesPerDay,
                    value: IRoomControllerV2ControlEnums.Mode.ECO,
                    equalModes: [modeNr]
                });
            }

            return tmpEntries;
        }

    };
});
