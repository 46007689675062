/**
 * Created by loxone on 20.06.16.
 */
'use strict';

define(['AddMediaViewControllerV2Base'], function (AddMediaViewControllerV2Base) {
    class AddMediaViewControllerV2Playlist extends AddMediaViewControllerV2Base {
        //region Private
        // We can't add any of these items to a Local Playlist!
        BANNED_CUSTOMIZATION_KEYS = [
            MusicServerEnum.ControlContentMenuId.SPOTIFY,
            MusicServerEnum.ControlContentMenuId.SOUNDSUIT,
            MusicServerEnum.ControlContentMenuId.LINE_IN,
            MusicServerEnum.ControlContentMenuId.RADIO
        ]; //endregion Private

        constructor(details) {
            super(...arguments);
            this.delegate = details.delegate;
            this.playlistId = details.playlistId;
            this.control.audioserverComp.fillWithInputCustomizationKeys(this.BANNED_CUSTOMIZATION_KEYS);
            this.contentType = MusicServerEnum.MediaContentType.PLAYLISTS;
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function (res) {
                this._alreadyDisappearing = false;
                return res;
            }.bind(this));
        }

        viewWillDisappear() {
            this._alreadyDisappearing = true;

            this._endEditMode();

            return super.viewWillDisappear(...arguments);
        }

        startEditMode() {
            if (this.details.mustHandleEditMode) {
                return this.control.audioserverComp.startEditing(this.contentType, this.delegate.getMediaInfo(this.contentType), this.playlistId).then(this._editModeEnded.bind(this), this._editModeError.bind(this), this._editModeStarted.bind(this));
            } else {
                return Q();
            }
        }

        _endEditMode() {
            if (this.details.mustHandleEditMode) {
                this.control.audioserverComp.stopEditing(this.playlistId);
            }
        }

        _editModeEnded(cause) {
            Debug.Control.AudioZone.MediaEdit && console.log(this.name + ": _handleEditModeEnded: " + cause);

            if (cause === MusicServerEnum.EditEndCause.STOPPED) {
                this._updateEditMode(false);
            } else {
                this._handleEditModeError({
                    cause: cause
                });
            }
        }

        _editModeError(err) {
            console.error("Error with Edit Mode: error Object: " + JSON.stringify(err));

            var successFn = null,
                title = _("error"),
                message = !err ? "" : err[MusicServerEnum.Attr.Container.REASON],
                okayTitle = true,
                content;

            if (err && err.cause) {
                switch (err.cause) {
                    case MusicServerEnum.EditEndCause.MODIFIED:
                        title = _("media.edit.modified.title");
                        message = _("media.edit.modified.message", {
                            name: AudioserverComp.getNameForItem(this.delegate.lastSelectedItem)
                        });
                        successFn = this._updateEditMode.bind(this, true);
                        okayTitle = _("edit");
                        break;

                    case MusicServerEnum.EditEndCause.CONFLICT:
                        title = _("media.edit.conflict.title");
                        message = _("media.edit.conflict.message", {
                            name: AudioserverComp.getNameForItem(this.delegate.lastSelectedItem)
                        });
                        break;

                    default:
                        break;
                }

                content = {
                    title: title,
                    message: message,
                    buttonOk: okayTitle,
                    buttonCancel: okayTitle !== true,
                    icon: Icon.INFO
                };
                NavigationComp.showPopup(content).then(successFn, this.dismiss.bind(this));
            } else {
                console.error("Edit mode did fail but no error was provided! Don't respond");
            }
        }

        _editModeStarted() {
            AudioserverComp.prepareForAdding().finally(function editModeStartedPrepareForAddingEnded() {
                this.addItemCnt && this.control.audioserverComp.showConfirmationFeedback(this._getConfirmationText());

                this._handleDismiss();
            }.bind(this));
        }

        _updateEditMode(editActive) {
            if (!editActive) {
                this._handleDismiss();
            }
        }

        /**
         * On HD devices when the add items to playlist screen was dismissed by tapping on the background, the view
         * is already disappearing when _handleDismiss is called due to the editMode ending - this is caught here
         * as dismissing again would cause a dropout from the audio zone itself.
         * @private
         */
        _handleDismiss() {
            if (!this._alreadyDisappearing) {
                this.dismiss();
            } else {
                developerAttention(this.viewId + ": preventing dismiss call!");
                console.warn(this.viewId, "On HD when navigating back by pressing on the background, duplicate nav backs where called dropping out of the audiozone", getStackObj());
            }
        }

        _getConfirmationText() {
            return _("media.notifications.playlist.local.add");
        }

    }

    GUI.AddMediaViewControllerV2Playlist = AddMediaViewControllerV2Playlist;
    return GUI.AddMediaViewControllerV2Playlist;
});
