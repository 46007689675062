'use strict';
/**
 * This screen is used to present a screen allowing to select an specific end date & time.
 *
 * details
 *      title           title of the screen
 *      callbackFn      function to call when the duration is to be sent, seconds will be passed as only argument
 *      returnTimepoint default: false. when true the callback will be calles with seconds since 1.1.2009
 *      offerAlways     default: false. When true a switch is available and -1 will be returned when the switch is active.
 *      [pickerText]    text shown inside the picker cell, defaults to "Until" if not provided
 *      [footerTitle]   shown as footer below the date/time-picker
 *
 */

define([], function () {
    return class TimerUntilScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div />'));
            this.details = details;
            this.alwaysActive = false;
            this.disableUntil = this._getDisableEndDate();
        }

        titleBarText() {
            return this.details.title;
        }

        titleBarConfig() {
            return {
                rightSide: TitleBarCfg.Button.TICK
            };
        }

        titleBarActionRight() {
            var msTime = this._getCurrentMSDateTime(),
                callbackValue,
                promise = true;

            Debug.TimerUntilScreen && console.log(this.name, "titleBarActionRight: " + this.disableUntil.format(DateType.WeekdayAndDateTextAndTimeNoYear));

            if (this.details.callbackFn) {
                if (this.alwaysActive) {
                    callbackValue = -1;
                    Debug.TimerUntilScreen && console.log("    calling callback with always on value: " + callbackValue);
                } else if (!!this.details.returnTimepoint) {
                    callbackValue = this.disableUntil.getSecondsSince2009();
                    Debug.TimerUntilScreen && console.log("    calling callback with seconds since 2009: " + callbackValue);
                } else {
                    callbackValue = this.disableUntil.diff(msTime, 'seconds');
                    Debug.TimerUntilScreen && console.log("    calling callback with duration: " + callbackValue);
                }

                promise = this.triggerCallback(callbackValue);
            }

            Q(promise || true).then(function () {
                Debug.TimerUntilScreen && console.log("   done, navigate back!");
                this.ViewController.navigateBack(this.details.callbackFn ? null : timeDifference);
            }.bind(this));
        }

        triggerCallback(callbackValue) {
            return this.details.callbackFn(callbackValue);
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        reloadTable() {
            this.tableContent = this._getContent();
            return super.reloadTable(...arguments);
        }

        didChangeDate(section, row, tableView, value) {
            Debug.TimerUntilScreen && console.log(this.name, "didChangeDate: " + value);
            this.disableUntil = value;
        }

        alwaysSwitchChanged(active) {
            this.alwaysActive = active;
            this.reloadTable();
        }

        // may be overwritten by subclasses
        getSectionsBeforePicker() {
            return null;
        }

        // may be overwritten by subclasses
        getSectionsAfterPicker() {
            return null;
        }

        _getContent() {
            var content = [],
                previousSections = this.getSectionsBeforePicker(),
                alwaysCell = this._getAlwaysCell(),
                pickerFooter = this.details.footerTitle,
                pickerCell = this._getPickerCell(),
                trailingSections = this.getSectionsAfterPicker(); // ------ Potential content before picker ----------


            if (previousSections && previousSections.length > 0) {
                content = content.concat(previousSections);
            } // ------ Picker Sections ----------


            if (!!this.details.offerAlways) {
                if (this.alwaysActive) {
                    content.push({
                        rows: [alwaysCell]
                    });
                } else if (content.length > 0) {
                    // place both always & picker in same section
                    content.push({
                        rows: [alwaysCell, pickerCell],
                        footerTitle: pickerFooter
                    });
                } else {
                    // Place then in two different sections
                    content.push({
                        rows: [alwaysCell]
                    });
                    content.push({
                        rows: [pickerCell],
                        footerTitle: pickerFooter
                    });
                }
            } else {
                content.push({
                    rows: [pickerCell],
                    footerTitle: pickerFooter
                });
            } // ------ Potential Content after picker -------


            if (trailingSections && trailingSections.length > 0) {
                content = content.concat(trailingSections);
            }

            return content;
        }

        _getAlwaysCell() {
            return {
                type: GUI.TableViewV2.CellType.SWITCH,
                content: {
                    title: this.details.alwaysSwitchText ? this.details.alwaysSwitchText : _("always"),
                    active: this.alwaysActive
                },
                onSwitchChanged: this.alwaysSwitchChanged.bind(this)
            };
        }

        _getPickerCell() {
            return {
                type: GUI.TableViewV2.CellType.DATE_TIME_PICKER,
                content: {
                    title: this.details.pickerText ? this.details.pickerText : _("time.until"),
                    value: this.disableUntil,
                    expanded: true
                },
                onPickerChanged: this.didChangeDate.bind(this)
            };
        }

        /**
         * Returns a duration that ends at a well defined default end date.
         * @returns {number} duration in seconds
         * @private
         */
        _getDisableEndDate() {
            var defaultEnd = this._getCurrentMSDateTime();

            defaultEnd.add(8, 'days');
            defaultEnd.startOf('day');
            defaultEnd.add(12, 'hours');
            return defaultEnd;
        }

        _getCurrentMSDateTime() {
            return SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINISERVER_DATE_TIME);
        }

    };
});
