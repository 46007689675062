'use strict';

define("NfcCodeTouchControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class NfcCodeTouchControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [];
            control.getUsedOutputs().forEach((output) => {
                cmds.push(this.createCommand(output.name, control.sendOutputCommand.bind(control, output.nr)));
            });
            return cmds;
        }

    };
});
