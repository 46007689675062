// region HD Handling
window.hasHDSize = function hasHDSize() {
    var minHeightL = 550,
        minWidthL = 700,
        minHeightP = 550,
        minWidthP = 480;
    return window.innerHeight > minHeightL && window.innerWidth > minWidthL || window.innerHeight > minHeightP && window.innerWidth > minWidthP;
};
// for activating a block, that prevents overriding the AMBIENT_MODE variable (needed because of default location reset)
window.setAmbientStateBlock = (forceValue) => {
    window.Debug && window.Debug.AmbientMode && console.log(`AmbientStateLog: setAmbientStateBlock(): from ${window.__ambientToggleBlock} to ${forceValue}`)
    window.__ambientToggleBlock = !!forceValue
}

window.toggleAmbientMode = ambientMode => {
    let changed = false;
    window.Debug && window.Debug.AmbientMode && console.log(`AmbientStateLog: toggleAmbientMode(): try toggle from ${window.__isInAmbientMode} to ${ambientMode}...`)
    if (!window.__ambientToggleBlock && !!ambientMode !== window.__isInAmbientMode) {
        window.Debug && window.Debug.AmbientMode && console.log(`AmbientStateLog:  toggleAmbientMode(): toggled successfully`)
        window.__isInAmbientMode = !!ambientMode;
        changed = true;
    } else {
        window.Debug && window.Debug.AmbientMode && console.log(`AmbientStateLog:  toggleAmbientMode(): didn't toggle, blocked: ${window.__ambientToggleBlock}, is same: ${ambientMode === window.__isInAmbientMode}`)
    }
    return changed;
}
Object.defineProperty(window, "AMBIENT_MODE", {
    get() {
        return window.__isInAmbientMode;
    }
})
Object.defineProperty(window, "HD_APP", {
    get() {
        if (window.__isInAmbientMode) {
            return false;
        } else {
            return window.__unsafe_HD_APP;
        }
    },
    set(isHD) {
        window.Debug && window.Debug.AmbientMode && console.log(`AmbientStateLog: HD_APP set() from ${window.__unsafe_HD_APP} to ${isHD}`)
        window.__unsafe_HD_APP = !!isHD;
    }
})
window.HD_APP = hasHDSize();

// endregion

window.GUI = window.GUI || {};
// clone to unfreeze object
const Comps = {...require("LxComponents")};
Object.keys(Comps).forEach(compName => {
    Object.defineProperty(window.GUI, compName, {
        set(value) {
            developerAttention("🤔 Did you just override component of our Component Lib!?")
            Comps[compName] = value;
        },
        get() {
            console.error("DEPRECATED", `Don't use 'window.GUI.${compName}' or 'GUI.${compName}' but require '${compName}' from 'LxComponents'`);
            /*
            Please follow the stack trace and fix this!
             */
            //debugger;
            return Comps[compName];
        }
    })
});
