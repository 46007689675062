'use strict';

define("MailBoxControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class MailBoxControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states, navigation) {
            let cmds = [];

            if (states.mailReceived) {
                cmds.push(this.createCommand(_('controls.mailbox.confirmmail'), Commands.MailBox.CONF_MAIL));
            }

            if (states.notifDisabledTimerActive && !states.notifDisabledInput) {
                cmds.push(this.createCommand(_("controls.mailbox.reenable-notifications"), Commands.MailBox.NOTIF_ENABLE));
            } // Timer action added in control content!

            if (!states.notifDisabled) {
                cmds.push(this.createCommand(_("controls.mailbox.disable-notifications"), this.showDisableTimerScreen.bind(this, control, navigation)))
            }

            return cmds;
        }

        showDisableTimerScreen(control, navigation) {
            let details = {};
            details.title = _('controls.mailbox.disable-notifications');

            details.callbackFn = (deactivateUntil) => {
                return control.sendCommand(Commands.format(Commands.MailBox.NOTIF_DIS, deactivateUntil));
            };

            details.footerTitle = _("controls.mailbox.notifications-disabled.description");
            details.pickerText = _('controls.mailbox.disable-notif-until');
            navigation.navigate(ScreenState.TIMER_UNTIL_SCREEN, details);
        }

    };
});
