import {
    Graph,
    useControl,
    useLiveState,
} from "LxComponents"
import {
    useMemo
} from "react";
import { StyleSheet } from "react-native";
import useSpotPriceData from "../hooks/useSpotPriceData";
import PropTypes from "prop-types";
import {
    Categorization,
    getColorFor,
    getHumanReadableCategorization,
    removeDuplicates,
    determineResolution
} from "../utils";

const legend = [
    Categorization.V_LOW,
    Categorization.LOW,
    Categorization.HIGH,
    Categorization.V_HIGH
].map(category => {
    return {
        color: getColorFor({category}),
        label: getHumanReadableCategorization({category})
    }
});

function createLabels(datapoints, useAmPm, resolutionMultiplier) {
    const labelHoursIndex = [0, 6, 12, 18].map(hour => hour * resolutionMultiplier);
    const labels = labelHoursIndex.map((idx) => {
      const date = moment.unix(datapoints[idx]?.timestamp);
      const hour = date.hour();
      const minute = date.minute();
  
      if (useAmPm) {
        const period = hour < 12 ? 'AM' : 'PM';
        const displayHour = hour % 12 === 0 ? 12 : hour % 12;
        return `${displayHour} ${period}`;
      } else {
        const hoursStr = String(hour).padStart(2, '0');
        const minutesStr = String(minute).padStart(2, '0');
        return `${hoursStr}:${minutesStr}`;
      }
    });
  
    return labels;
  }

export default function DayGraph({uuidAction, viewInfo, fullStretch, onZoom}) {
    const control = useControl(uuidAction),
        { states = {} } = useLiveState(uuidAction, ["manualMax"]),
        {
            data = [],
            min,
            max,
            highMax,
            lowMax,
            vLowMax
        } = useSpotPriceData({
            uuidAction,
            start: viewInfo.start,
            end: viewInfo.end
        });

    const label = useMemo(() => {
        return lxSplitFormat(control?.details?.format ?? "").succTxt
    }, [control?.details?.format]);

    const {dataPoints, horizontalLabels} = useMemo(() => {
        let rawDps = data?.map(({ ts: timestamp, values }) => {
                let [value] = values;
                return {
                    color: getColorFor({
                        value,
                        stats: {
                            max,
                            highMax,
                            lowMax,
                            vLowMax,
                            min
                        }
                    }, states.manualMax),
                    timestamp,
                    value,
                    displayString: lxUnitConverter.convertAndApply(control?.details?.format ?? "", value)
                }
            }) ?? [];

        // region Fill in missing data points for each hour
        const filledDps = [];
        const startHour = moment.unix(data[0]?.ts).startOf('day');
        const { iteratorFn, resolutionMultiplier } = rawDps.length && determineResolution(rawDps.map(dp => dp.timestamp)) || {};
        const isSmallResolution = resolutionMultiplier !== 1;
        const endHour = startHour.clone().add(23, 'hours').add(isSmallResolution ? (60 / resolutionMultiplier) : 0, 'minutes');
        for (let referenceTime = startHour; referenceTime.isSameOrBefore(endHour); iteratorFn(referenceTime)) {
            const dp = rawDps.find(dp => referenceTime.isSame(moment.unix(dp.timestamp)));
            if (dp) {
                filledDps.push(dp);
            } else {
                filledDps.push({
                    timestamp: referenceTime.unix(),
                    value: Graph.NO_DATA_VALUE
                });
            }
        }
        // endregion

        // removing duplicates here as a last resort safety measure
        // as there where instances where the merging of forecast and statistics
        // lead to duplicate data points
        const dataPoints = removeDuplicates(filledDps)
        return {
            dataPoints,
            horizontalLabels: dataPoints.length ? createLabels(dataPoints, useAmPm(), resolutionMultiplier) : []
        }
    }, [JSON.stringify(data), states.manualMax]);

    return (
        <Graph
            style={styles.overview}
            titleLeft={_("controls.spotpriceoptimizer.graph.title")}
            type={'bar'}
            horizontalLabels={horizontalLabels}
            datapoints={dataPoints}
            legend={legend}
            fixedLowerLimit={Math.min(min, 0)}
            label={label}
            highlightFullGraph
            onZoom={onZoom}
            fullStretch={fullStretch}
        />
    )
}

const styles = StyleSheet.create({
    overview: {
        width: "100%",
        marginTop: 16,
    },
})

DayGraph.propTypes = {
    uuidAction: PropTypes.string,
    viewInfo: PropTypes.object,
    onZoom: PropTypes.func,
    fullStretch: PropTypes.boolean
}
