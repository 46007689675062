import React, {useState, useRef, useEffect} from "react";
import PropTypes from "prop-types";
import {View, Picker} from 'react-native';
import {LxReactText, LxReactPressable} from "LxComponents";
import Icons from "IconLib";
import globalStyles from "GlobalStyles";

export default function LxReactQuickSelect(props) {

    const [selectedValue, setSelectedValue] = useState(props.selectedValue),
        indexRef = useRef(0),
        tintColor = props.tintColor || globalStyles.colors.text.primary,
        iconComp = props.iconComp || Icons.ListIndicator,
        iconPosition = props.iconPosition || LxReactQuickSelect.IconPosition.Left,
        Icon = React.createElement(iconComp, {
                fill: tintColor,
                ...props.iconProps || {},
                style: {
                    minWidth: 20,
                    [`margin${iconPosition === LxReactQuickSelect.IconPosition.Left ? "Right" : "Left"}`]: globalStyles.spacings.gaps.small
                }
            }
        );

    // the selected value may also change via props, e.g. selected value changed from a state
    useEffect(() => {
        setSelectedValue(props.selectedValue);
    }, [props.selectedValue])

    const truncateText = (text) => {
        if (props.truncateOptionLabel && text.length > props.truncateOptionLabel) {
            return text.substring(0, props.truncateOptionLabel) + "...";
        } else {
            return text;
        }
    }

    const renderPickerItems = () => {
        return props.options.map((option, idx) => {
            let style = {
                    color: globalStyles.colors.text.secondary
                },
                label,
                value;
            if (typeof option === "string") {
                label = truncateText(option);
                value = option;

            } else {
                label = truncateText(option.title);
                value = option.value;
            }

            if (selectedValue !== undefined && selectedValue !== null && value.toString() === selectedValue?.toString()) {
                style = {
                    backgroundColor: globalStyles.colors.grey["300"],
                    color: globalStyles.colors.text.primary
                }
            }

            return <Picker.Item key={idx} label={label} value={value} style={style} color={style.color}/>
        });
    }

    const onChangeWrapper = (value, index) => {
        let oldValue = selectedValue, reject = () => setSelectedValue(oldValue);
        setSelectedValue(value);
        props.onOptionSelected(value, index, reject);
    }

    const getHumanReadableValue = () => {
        if (typeof props.options[0] === "string") {
            return selectedValue;
        } else if (selectedValue !== undefined && selectedValue !== null) {
            const selectedObj = props.options.find(option => {
                return option.value.toString() === selectedValue?.toString()
            });
            // ensures that this won't crash if e.g. the MS reports a selected value that isn't in part of the options!
            if (!selectedObj) {
                console.error(LxReactQuickSelect.name, "getHumanReadableValue failed: selectedValue " + selectedValue + " not found in options! " + JSON.stringify(props.options));
                return "--";
            } else {
                return selectedObj.title;
            }
        } else {
            return props.notSelectedTitle || "--"
        }
    }

    const getPicker = () => {
        if (props.options.length >= 3 || props.noToggle) {
            return (
                <Picker style={styles.picker} selectedValue={selectedValue} onValueChange={onChangeWrapper}>
                    {renderPickerItems()}
                </Picker>
            )
        }
    }

    const handleTextClicked = () => {
        let idx,
            value;

        if (props.options.length <= 2 && !props.noToggle) {
            idx = indexRef.current === 0 ? 1 : 0;

            if (typeof props.options[idx] === "string") {
                value = props.options[idx];
            } else {
                value = props.options[idx]?.value;
            }

            indexRef.current = idx;
            setSelectedValue(value);
            props.onOptionSelected(value, idx);
        } else {
            return null;
        }
    }

    return (
        <LxReactPressable key={"pressAbleContainer"}
                          onPressIn={handleTextClicked}
                          style={props.containerStyle}
        >
            <View style={styles.container}>
                {iconPosition === LxReactQuickSelect.IconPosition.Left ? Icon : null}
                <LxReactText numberOfLines={1} style={{
                    padding: globalStyles.spacings.gaps.small,
                    color: props.textColor || tintColor,
                    alignItems: "center",
                    ...styles.title,
                    ...props.titleStyle || {},
                }}>{props.title || getHumanReadableValue() || "..."}</LxReactText>
                {iconPosition === LxReactQuickSelect.IconPosition.Right ? Icon : null}
                {getPicker()}
            </View>
        </LxReactPressable>
    );
}

const styles = {
    title: {
        fontSize: globalStyles.fontSettings.sizes.smallReg,
        lineHeight: globalStyles.fontSettings.lineHeights.smallReg,
        fontFamily: globalStyles.fontSettings.families.semiBold
    },
    container: {
        flexDirection: "row",
        alignItems: "center"
    },
    picker: {
        position: "absolute",
        left: 0,
        opacity: 0,
        width: "100%",
        fontSize: globalStyles.fontSettings.sizes.regular,
        backgroundColor: globalStyles.colors.grey["600"],
        minWidth: 150
    }
}

LxReactQuickSelect.IconPosition = {
    Left: "left",
    Right: "right"
}

LxReactQuickSelect.propTypes = {
    selectedValue: PropTypes.string,
    title: PropTypes.string,
    tintColor: PropTypes.string,
    textColor: PropTypes.string,
    notSelectedTitle: PropTypes.string,
    titleStyle: PropTypes.object,
    containerStyle: PropTypes.object,
    iconComp: PropTypes.func,
    iconProps: PropTypes.object,
    iconPosition: PropTypes.oneOf(Object.values(LxReactQuickSelect.IconPosition)),
    noToggle: PropTypes.bool, // disables toggling between two options
    options: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ])
        }))
    ]).isRequired,
    onOptionSelected: PropTypes.func.isRequired,
    truncateOptionLabel: PropTypes.number // trunctates the item title to the given number of characters
}
