'use strict';

define("LightV2Control", [
    "Control",
    "LightV2ControlEnums",
    "IconLib"
], function (
    Control,
    LightV2ControlEnums,
    {
        default: Icons
    }
) {
    return class LightV2Control extends Control {

        get currentMoods() {
            let states = this.getStates(),
                fallback = [];

            if (states) {
                return states.moodList || fallback;
            } else {
                return fallback;
            }
        }

        get activeMoods() {
            let states = this.getStates(),
                fallback = [];

            if (states) {
                return states.activeMoods || fallback;
            } else {
                return fallback;
            }
        }

        constructor() {
            super(...arguments);
        }

        /**
         * If true, controls of this type MAY use the rooms name instead of its own. E.g. this is important for light
         * controllers, heating controllers and other room based controls.
         */
        controlTypeUsesRoomName() {
            return true;
        }

        getButton0() {
            if (!this.hasSingleSwitch()) {
                return {
                    iconSrc: Icon.ADD,
                    reactIcon: Icons.Plus,
                    command: {
                        tap: Commands.LIGHTCONTROL.SCENE_PLUS,
                        doubleTap: Commands.LIGHTCONTROL.ALL_OFF
                    }
                };
            }
        }

        getSwitch(states) {
            if (this.hasSingleSwitch()) {
                return {
                    active: states.isOn,
                    command0: Commands.format(Commands.LIGHTCONTROL_V2.MOOD.CHANGE_TOO, LightV2ControlEnums.KNOWN_MOODS.ALL_OFF),
                    command1: Commands.format(Commands.LIGHTCONTROL_V2.MOOD.CHANGE_TOO, LightV2ControlEnums.KNOWN_MOODS.ALL_ON)
                };
            }
        }

        /**
         * Returns the name of the currently selected mood, if no mood is selected manual will be returned
         * @param [moodId] Optional, will use the currentMoods of the control if not set
         */
        getMoodName(moodId) {
            var moodName = _("controls.lightcontroller.manual"),
                moods;

            if (moodId === undefined || moodId === null) {
                if (this.activeMoods.length === 1) {
                    return this.getMoodName(this.activeMoods[0]);
                }
            } else {
                moods = this.getStates().moodList;

                if (!moods) {
                    moodName = _("cmdtext.lightcontrollerV2.mood.unknown", {
                        moodid: moodId
                    });
                } else {
                    Object.values(moods).some(function (mood) {
                        if (mood.id === moodId) {
                            moodName = mood.name;
                        }

                        return mood.id === moodId;
                    });
                }
            }

            return moodName;
        }

        getMoodIcon(mood) {
            var icon = null;

            if (mood.isAlarmClockMood) {
                icon = Icon.Light.ALARM_CLOCK;
            } else if (mood.isMovementMood) {
                icon = Icon.IRCV2.REASON.PRESENCE;
            } else if (mood.isT5Mood) {// no icon for that right now
            }

            return icon;
        }

        /**
         * Iterates over the defined list of moods and calls the callback for every mood
         * @param wantFav
         * @param callback
         */
        moodsOfList(wantFav, callback) {
            var states = this.getStates();
            var listToIterate = wantFav ? states.favoriteMoods : states.additionalMoods;

            for (var i = 0; i < listToIterate.length; i++) {
                var moodId = listToIterate[i];

                for (var j = 0; j < states.moodList.length; j++) {
                    var mood = states.moodList[j];

                    if (mood.id === moodId) {
                        callback(mood);
                    }
                }
            }
        }

        specificCreateCmdText(cmdParts, argumentTexts) {
            var text = null,
                cfg = {};

            if (this.type === ControlType.LIGHT_V2) {
                if (argumentTexts) {
                    for (var i = 0; i < argumentTexts.length; i++) {
                        cfg["argument" + i] = argumentTexts[i];
                    }
                }

                if (cmdParts[1] === Commands.LIGHTCONTROL_V2.PREVIOUS_MOOD) {
                    text = _("cmdtext.lightcontrollerV2.mood.previous");
                } else if (cmdParts[1] === Commands.LIGHTCONTROL_V2.NEXT_MOODE) {
                    text = _("cmdtext.lightcontrollerV2.mood.next");
                } else if (cmdParts[1].startsWith("AI")) {
                    // controlling an output!
                    var scId = cmdParts[0] + "/" + cmdParts[1];
                    var subcontrol = this.subControls[scId];
                    text = createCmdText(subcontrol, cmdParts.slice(1), argumentTexts);
                } else {
                    cfg["argument0"] = this.getMoodName(parseInt(cmdParts[2]));

                    if (cmdParts[1] === "changeTo") {
                        // CHANGE_TO: "%i",  // moodId
                        text = _("cmdtext.lightcontrollerV2.mood.select", cfg);
                    } else if (cmdParts[1] === "addMood") {
                        // ADD: "learn/%i", // moodId
                        text = _("cmdtext.lightcontrollerV2.mood.combine.add", cfg);
                    } else if (cmdParts[1] === "removeMood") {
                        // REMOVE: "removeMood/%i", // moodId
                        text = _("cmdtext.lightcontrollerV2.mood.combine.remove", cfg);
                    } else if (cmdParts[1] === "learn") {
                        // LEARN: "learn/%i/%s", // moodId, moodName
                        text = _("cmdtext.lightcontrollerV2.mood.save", cfg);
                    } else if (cmdParts[2] === "delete") {
                        // delete: "delete/%i", // moodId
                        text = _("cmdtext.lightcontrollerV2.mood.delete", cfg);
                    }
                }
            }

            return text;
        }

        hasSingleSwitch() {
            var subControls = Object.values(this.subControls);
            return subControls.length === 1 && subControls[0].controlType === ControlType.SWITCH;
        }

        getFriendlyValueName(event, operator) {
            switch (event.data.stateName) {
                case "activeMoods":
                    var moodName = this.getMoodName(event.value);
                    return this.name + " " + _("controls.lightV2controller.mood") + " " + moodName + " " + _("active").toLowerCase();

                default:
                    return "";
            }
        }

    };
});
