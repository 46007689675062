'use strict';

define([
    "IRoomControllerV2ControlEnums",
    './../../IrcFixedSetpointUtil'
], function (OldCtrlEnums, { Util: FixedSetpointUtil }) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var AUTO_SELECTED = -42;
    return class TempAdoptionScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div/>'));
            Object.assign(this, ContextMenuHandler.Mixin);
            this.details = details;
            this.control = details.control;
            this.currentAdoptionTemp = details.currentManualTemp || -1000;
            this.currentAdoptionUntilValue = null; // determine which mode is currently active

            var states = this.control.getStates();
            this.currentAdoptionModeId = states.isManual ? OldCtrlEnums.Mode.MANUAL : AUTO_SELECTED;
            this.canHeat = states.canHeat && !states.isHeatingNotAllowed;
            this.canCool = states.canCool && !states.isCoolingNotAllowed;
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            return _("controls.ircv2021.temperature-adjustment");
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.CLOSE,
                rightSide: TitleBarCfg.Button.TICK
            };
        }

        titleBarActionRight() {
            this._startAdoptionOverride();

            this.closeAction();
        }

        getURL() {
            return "control/" + this.control.uuidAction + "/ircv2021TempAdoptionScreen";
        }

        setTableContent() {
            var states = this.control.getStates();
            this.tableContent = [];

            if (!states.isManual) {
                this.tableContent.push({
                    rows: this._getAdoptionModeCells(states)
                });
            } else {
                this.tableContent.push({
                    rows: [this._getAdoptionCell(states)]
                });
                this.currentAdoptionModeId = OldCtrlEnums.Mode.MANUAL;
            } // the picker itself

            var lastSection = {
                rows: [this._getAdoptionPickerCell()]
            };
            if (FixedSetpointUtil.isFixedSetpointValue(this.currentAdoptionModeId) && this.currentAdoptionModeId !== AUTO_SELECTED) {
                var cell = {
                    sliderDragged: (cell, section, row, tableView, value, isDragging) => {
                        this._handleAdoptionChanged(value, isDragging)
                    },
                    sliderClicked: (cell, section, row, tableView, value, isDragging) => {
                        this._handleAdoptionChanged(value, isDragging)
                    }
                };
                cell.type = GUI.TableViewV2.CellType.SLIDER;
                cell.content = {
                    title: this.control._getFixedSetpointName(this.currentAdoptionModeId),
                    value: this._getAdoptionManualTemp(states),
                    minValue: states.frostProtectTemperature,
                    minIconSrc: Icon.MINUS,
                    maxIconSrc: Icon.PLUS,
                    maxValue: states.heatProtectTemperature,
                    step: this.control.getTempStep(),
                    format: this.control.getFormatForTemp(),
                };

                lastSection.rows.splice(0, 0, cell);
            }


            this.tableContent.push(lastSection);
            return super.setTableContent(...arguments);
        }

        // --------------------------------------------------------------------------------
        // Content Creation
        // ---------------------------------------------------------------------------------
        _getAdoptionPickerCell(states) {
            var untilDate = this.currentAdoptionUntilValue || this._getDefaultAdoptionUntil();

            return {
                type: GUI.TableViewV2.CellType.GENERAL,
                content: {
                    title: _("controls.ircv2021.temperature-adjustment-set-until"),
                    disabled: false,
                    clickable: true,
                    disclosureIcon: false,
                    disclosureText: LxDate.formatFutureDateDynamic(untilDate, true),
                    disclosureColor: window.Styles.colors.stateActive
                },
                action: this._showEndTimePicker.bind(this)
            };
        }

        _getDefaultAdoptionUntil() {
            var msNow = SandboxComponent.getMiniserverTimeInfo();
            return msNow.clone().endOf("day").add(-1, "s"); // rm 1 second to ensure it's still "today".
        }

        _getAdoptionModeCells(states) {
            var cells = [];
            cells.push(this._getAutomaticModeCell(states));
            if (!Feature.IRC_SCHEDULE_SETPOINTS) {
                cells.push(this._getManualModeCell(_("controls.ircv2021.mode-fixed-setpoint"), OldCtrlEnums.Mode.MANUAL, states));
            }
            cells.push(this._getAdoptionModeCell(_("controls.ircv2021.mode-comfort"), OldCtrlEnums.Mode.COMFORT, states));
            cells.push(this._getAdoptionModeCell(_("controls.ircv2021.mode-eco"), OldCtrlEnums.Mode.ECO, states));
            cells.push(this._getAdoptionModeCell(_("controls.ircv2021.mode-off"), OldCtrlEnums.Mode.ECO_PLUS, states));

            if (Feature.IRC_SCHEDULE_SETPOINTS) {
                if (this.canHeat && this.canCool) {
                    cells.push(this._getAdoptionModeCell(_("controls.ircv2021.mode-fixed-setpoint"),
                        FixedSetpointUtil.convertToValue(this.currentAdoptionTemp, true, true), states));
                }
                if (this.canHeat) {
                    cells.push(this._getAdoptionModeCell(_("controls.ircv2021.mode-fixed-setpoint-only-heating"),
                        FixedSetpointUtil.convertToValue(this.currentAdoptionTemp, true, false), states));
                }
                if (this.canCool) {
                    cells.push(this._getAdoptionModeCell(_("controls.ircv2021.mode-fixed-setpoint-only-cooling"),
                        FixedSetpointUtil.convertToValue(this.currentAdoptionTemp, false, true), states));
                }
            }

            return cells;
        }

        _getAutomaticModeCell(states) {
            let subtitle = this.control.formatHcTemp(states.currMinTemp, states.currMaxTemp);
            if (this.control.singleComfortTemp && states.currMinTemp === states.currMaxTemp) {
                subtitle = this.control.formatTemp(states.currMinTemp);
            }

            return {
                type: GUI.TableViewV2.CellType.CHECKABLE,
                content: {
                    title: _("controls.ircv2021.setpoint-by-schedule"),
                    //title: _("controls.ircv2021.mode-auto-based-on-schedule"),
                    subtitle: subtitle,
                    selected: this.currentAdoptionModeId === AUTO_SELECTED,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED
                },
                didCheckCell: function () {
                    this._handleAdoptionModeChecked(AUTO_SELECTED);
                }.bind(this)
            };
        }

        _getManualModeCell(title, id, states) {
            var result = this._getAdoptionModeCell(title, id, states);

            result.type = GUI.TableViewV2.CellType.CHECKABLE_VALUE_SELECTION;
            result.content.subtitle = null; // No need for a subtitle

            result.content.valueSelection = {
                value: this._getAdoptionManualTemp(states),
                minValue: states.frostProtectTemperature,
                maxValue: states.heatProtectTemperature,
                stepWidth: this.control.getTempStep(),
                valueFormat: this.control.getFormatForTemp(),
                valueChangedFn: this._handleAdoptionChanged.bind(this)
            };
            return result;
        }

        _getAdoptionModeCell(title, id, states) {
            return {
                type: GUI.TableViewV2.CellType.CHECKABLE,
                content: {
                    title: title,
                    subtitle: this._getAdoptionModeDescription(id, states),
                    selected: this.currentAdoptionModeId === id,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED
                },
                didCheckCell: function () {
                    this._handleAdoptionModeChecked(id);
                }.bind(this)
            };
        }

        _getAdoptionModeDescription(id, states) {
            var description = null,
                tempH = 0,
                tempC = 0;

            switch (id) {
                case OldCtrlEnums.Mode.MANUAL:
                    description = this.control.formatTemp(this._getAdoptionManualTemp(states));
                    break;

                case OldCtrlEnums.Mode.COMFORT:
                    if (this.control.singleComfortTemp) {
                        description = this.control.formatTemp(states.comfortTempMin);
                    } else {
                        tempH = states.comfortTempMin;
                        tempC = states.comfortTempMax;
                        description = this.control.formatHcTemp(tempH, tempC);
                    }
                    break;

                case OldCtrlEnums.Mode.ECO:
                    tempH = states.ecoTempMin;
                    tempC = states.ecoTempMax;
                    description = this.control.formatHcTemp(tempH, tempC);
                    break;

                case OldCtrlEnums.Mode.ECO_PLUS:
                    tempH = states.frostProtectTemperature;
                    tempC = states.heatProtectTemperature;
                    description = this.control.formatHcTemp(tempH, tempC);
                    break;

                default:
                    break;
            }

            return description;
        }

        _getAdoptionManualTemp(states) {
            return this.currentAdoptionTemp === -1000 ? states.tempTarget : this.currentAdoptionTemp;
        }

        _getAdoptionCell(states) {
            return {
                type: GUI.TableViewV2.CellType.ValueSelectionCell,
                content: {
                    valueSelection: {
                        value: this._getAdoptionManualTemp(states),
                        minValue: states.frostProtectTemperature,
                        maxValue: states.heatProtectTemperature,
                        stepWidth: this.control.getTempStep(),
                        valueFormat: this.control.getFormatForTemp(),
                        valueChangedFn: this._handleAdoptionChanged.bind(this)
                    }
                }
            };
        }

        // --------------------------------------------------------------------------------
        // Content Responses
        // ---------------------------------------------------------------------------------
        _startAdoptionOverride() {
            var untilDate = this.currentAdoptionUntilValue || this._getDefaultAdoptionUntil();

            var unitlDateSeconds = untilDate.getSecondsSince2009(),
                cmd;

            if (this.currentAdoptionModeId === AUTO_SELECTED) {// Nothing to do.
            } else if (FixedSetpointUtil.isFixedSetpointValue(this.currentAdoptionModeId)) {
                var fsp = FixedSetpointUtil.extractFromValue(this.currentAdoptionModeId);
                fsp.target = this._getAdoptionManualTemp(this.control.getStates());
                cmd = Commands.format(Commands.I_ROOM_V2_CONTROLLER.OVERRIDE.START_MANUAL_TEMP,
                    FixedSetpointUtil.convertToValue(fsp.target, fsp.heat, fsp.cool),
                    unitlDateSeconds,
                    fsp.target);

            } else if (this.currentAdoptionModeId === OldCtrlEnums.Mode.MANUAL) {
                cmd = Commands.format(Commands.I_ROOM_V2_CONTROLLER.OVERRIDE.START_MANUAL_TEMP,
                    this.currentAdoptionModeId,
                    unitlDateSeconds,
                    this._getAdoptionManualTemp(this.control.getStates()));
            } else {
                cmd = Commands.format(Commands.I_ROOM_V2_CONTROLLER.OVERRIDE.START_UNTIL, this.currentAdoptionModeId, unitlDateSeconds);
            }

            cmd && this.control.sendCommand(cmd);
        }

        _handleAdoptionChanged(value, onGoing) {
            this.currentAdoptionTemp = value; // ensure the mode selection is set to Manual when the value changes.

            if (FixedSetpointUtil.isFixedSetpointValue(this.currentAdoptionModeId)) {
                let fsp = FixedSetpointUtil.extractFromValue(this.currentAdoptionModeId);
                this.currentAdoptionModeId = FixedSetpointUtil.convertToValue(value, fsp.heat, fsp.cool);
            } else if (this.currentAdoptionModeId !== OldCtrlEnums.Mode.MANUAL && !onGoing) {
                this.currentAdoptionModeId = OldCtrlEnums.Mode.MANUAL;
                this.reloadTable();
            }
        }

        _handleAdoptionModeChecked(id) {
            this.currentAdoptionModeId = id;
            this.reloadTable();
        }

        _handleAdoptionPickerChanged(value) {
            this.currentAdoptionUntilValue = value;
            this.reloadTable();
        }

        _showEndTimePicker(cell, row, section, tableView, srcEvent) {
            var untilDate = this.currentAdoptionUntilValue || this._getDefaultAdoptionUntil();

            NavigationComp.showContextMenu(this, {
                    type: GUI.LxTimePickerContextMenu.TYPE.DATE_TIME,
                    value: untilDate,
                    onPickerChanged: this._handleAdoptionPickerChanged.bind(this)
                }, _("controls.ircv2021.temperature-adjustment-set-until"), null, // center in screen on HD
                GUI.LxTimePickerContextMenu);
        }

    };
});
