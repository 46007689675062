'use strict';
/*
 Description of the content: (Every property is optional)
 content:
    [icon]          -> Visible on the upper left side (Can be colored with the color property)
    [title]         -> Visible on the upper left side
    [color]         -> Used to tint elements like icon and detail.icon
    [detail]
        [icon]      -> Visible on the upper right side (Can be colored with the color property)
        [text]      -> Visible on the upper right side
    [info]
        [text]      -> Visible on the bottom (centered) in a separate container

 Delegate:
 onTopLeftButtonPress(sensor, section, row, tableView)
 onTopRightButtonPress(sensor, section, row, tableView)
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.DetailedContentBaseCell = "DetailedContentBaseCell";

    class DetailedContentBaseCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return '<div class="content__top-bar">' + '          <div class="top-bar__description">' + '              <div class="description__icon-container"></div>' + '              <div class="description__title text-overflow-ellipsis"></div>' + '          </div>' + '      <div class="top-bar__detailed">' + '          <div class="detailed__text"></div>' + '          <div class="detailed__icon"></div>' + '      </div>' + '   </div>' + '   <div class="content__prominent-container"></div>' + '   <div class="content__info-bar">' + '      <div class="info-bar__info-container">' + '          <div class="info-container__text"></div>' + '      </div>' + '   </div>';
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        viewDidLoad() {
            var baseCall = super.viewDidLoad(...arguments);
            this.contentPlaceholder.append(DetailedContentBaseCell.Template.getTemplate());
            this.elements = {
                topBar: {
                    element: this.contentPlaceholder.find('.content__top-bar'),
                    description: {
                        element: this.contentPlaceholder.find('.top-bar__description'),
                        icon: this.contentPlaceholder.find('.description__icon-container'),
                        title: this.contentPlaceholder.find('.description__title')
                    },
                    detail: {
                        element: this.contentPlaceholder.find('.top-bar__detailed'),
                        icon: this.contentPlaceholder.find('.detailed__icon'),
                        text: this.contentPlaceholder.find('.detailed__text')
                    }
                },
                prominentContainer: {
                    element: this.contentPlaceholder.find('.content__prominent-container')
                },
                infoBar: {
                    element: this.contentPlaceholder.find('.content__info-bar'),
                    container: {
                        element: this.contentPlaceholder.find('.info-bar__info-container'),
                        text: this.contentPlaceholder.find('.info-container__text')
                    }
                },
                buttons: {}
            };
            this.elements.prominentContainer.element.append(this.getProminentContainer());
            return baseCall;
        }

        viewWillAppear() {
            var baseCall = super.viewWillAppear(...arguments),
                prms = true; // Only apply the content once!
            // There is no need to re-apply the already applied content
            // Why is this in the viewWillAppear and not in viewDidLoad?
            //    The applyContent function must be executed after all viewDidLoad functions of all children
            //    have been called including the fist one of the inheritance tree
            // Note: This function must only be executed once per lifecycle to prevent duplicate content and delegate calls

            if (!this.wasAlreadyShown()) {
                prms = this.applyContent();
            }

            return Q.all([baseCall, prms]);
        }

        clickableAllowed() {
            return true;
        }

        /**
         * Lets apply the content to the cell!
         * This method uses this.content to apply content to the cell, override this method to handle an custom elements
         */
        applyContent() {
            var prms = [true];
            this.clearContent();

            if (this.content.icon) {
                this.elements.topBar.description.icon.append(ImageBox.getResourceImageWithClasses(this.content.icon));

                if (this.content.color) {
                    this.elements.topBar.description.icon.css("fill", this.content.color);
                }
            }

            if (this.content.title) {
                this.elements.topBar.description.title.html(this.content.title);
            }

            if (this.content.icon || this.content.title) {
                this.elements.buttons.descriptionButton = new GUI.LxButton(this, this.elements.topBar.description.element[0], Color.BUTTON_GLOW, null, true);
                this.elements.buttons.descriptionButton.onButtonReleased = this.handleTopLeftButtonDelegate.bind(this);
                prms.push(this.addToHandledSubviews(this.elements.buttons.descriptionButton));
            }

            if (this.content.detail && this.content.detail.icon) {
                this.elements.topBar.detail.icon.append(ImageBox.getResourceImageWithClasses(this.content.detail.icon));
            }

            if (this.content.color) {
                this.elements.topBar.detail.element.css("background-color", this.content.color);
            }

            if (this.content.detail && this.content.detail.text) {
                this.elements.topBar.detail.text.html(this.content.detail.text);
            }

            if (this.content.detail && (this.content.detail.icon || this.content.detail.text)) {
                this.elements.buttons.detailsButton = new GUI.LxButton(this, this.elements.topBar.detail.element[0], Color.BUTTON_GLOW, null, true);
                this.elements.buttons.detailsButton.onButtonReleased = this.handleTopRightButtonDelegate.bind(this);
                prms.push(this.addToHandledSubviews(this.elements.buttons.detailsButton));
            }

            prms.push(this.applyProminentContainerContent());

            if (this.content.info && this.content.info.text) {
                this.elements.infoBar.element.show();
                this.elements.infoBar.container.text.html(this.content.info.text);
            } else {
                this.elements.infoBar.element.hide();
            }

            return Q.all(prms);
        }

        /**
         * Clears the cell and removes the previously applied content
         */
        clearContent() {
            this.clearProminentContent();
            this.elements.topBar.description.icon.empty();
            this.elements.topBar.description.title.empty();
            this.elements.topBar.detail.icon.empty();
            this.elements.topBar.detail.text.empty();
            this.elements.infoBar.container.text.empty();
        }

        handleTopLeftButtonDelegate() {
            this.delegate.onTopLeftButtonPress && this.delegate.onTopLeftButtonPress.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
        }

        handleTopRightButtonDelegate() {
            this.delegate.onTopRightButtonPress && this.delegate.onTopRightButtonPress.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
        }

        /**
         * The prominent container is handled separately, its optional
         * Eg. Add Text in the center of the cell
         */
        applyProminentContainerContent() {// Nothing to do here
        }

        /**
         * Clears the cell and removes the previously applied content
         * Note: This will be called from this.clearContent
         */
        clearProminentContent() {// Nothing to do here
        }

        /**
         * Returns the prominent container.
         * This container is located in the cells center
         */
        getProminentContainer() {
            throw new Error("Implement getProminentContainer function!");
        }

    }

    GUI.TableViewV2.Cells.DetailedContentBaseCell = DetailedContentBaseCell;
    return GUI;
}(window.GUI || {});
