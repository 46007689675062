import * as React from "react"
import Svg, { Path } from "react-native-svg"

function MiniserverLined(props) {
    return (
        <Svg
            isreact="true"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M14.975 4h1.05a1.25 1.25 0 012.45 0h4.275c.69 0 1.25.56 1.25 1.25v13.5c0 .69-.56 1.25-1.25 1.25h-2.775c-.116.57-.62 1-1.225 1h-8.5a1.25 1.25 0 01-1.225-1H1.25C.56 20 0 19.44 0 18.75V5.25C0 4.56.56 4 1.25 4h9.775c.116-.57.62-1 1.225-1h1.5c.605 0 1.11.43 1.225 1zM1.5 5.5v13h21v-5.75H10.75a.75.75 0 010-1.5H22.5V5.5h-21z"
            />
        </Svg>
    )
}

export default MiniserverLined
