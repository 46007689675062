"use strict";
/*
 rightIconSrc       // source of the right icon, if provided, buttonTapped will be called when icon is tapped

 isDummy            // true if this is a dummy device - affects the online/offline info
 isOnline           // whether or not the device is online

 buttonTapped(section, row, tableView)
 */

GUI.TableViewV2.CellType.Special.DEVICE_LEARNING_CHECKABLE_CELL = "DeviceLearningCheckableCell";

class DeviceLearningCheckableCell extends GUI.TableViewV2.Cells.CheckableCell {
    constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
        super(...arguments);
    }

    viewDidLoad() {
        return super.viewDidLoad(...arguments).then(function () {
            if (this.elements.rightIconCntr) {
                this.buttons = this.buttons || {};
                this.buttons.rightIconButton = new GUI.LxButton(this, this.elements.rightIconCntr);
                this.addToHandledSubviews(this.buttons.rightIconButton);
            }
        }.bind(this));
    }

    viewWillAppear() {
        var prms = super.viewWillAppear(...arguments);

        if (this.buttons && this.buttons.rightIconButton && this.delegate.buttonTapped) {
            this.buttons.rightIconButton.onButtonTapped = function () {
                this.delegate.buttonTapped.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
            }.bind(this);
        }

        return prms;
    }

    viewWillDisappear() {
        if (this.buttons && this.buttons.rightIconButton) {
            this.buttons.rightIconButton.onButtonTapped = null;
        }

        return super.viewWillDisappear(...arguments);
    }

    // ---------------------------------------------------------------------------------------------------------
    //   Methods for super fast tableContent updates
    // ---------------------------------------------------------------------------------------------------------
    cellTypeForReuse() {
        return GUI.TableViewV2.CellType.Special.DEVICE_LEARNING_CHECKABLE_CELL;
    }

    updateContent(newContent) {
        return super._updateContent(...arguments);
    }

    /**
     * This Method is used to apply a new content object onto the cell instance. e.g. update texts and so on.
     * @param newContent
     * @private
     */
    _applyNewContent(newContent) {
        Debug.GUI.CheckableCell && console.log(this.name, "_applyNewContent: " + JSON.stringify(newContent));
        var promises = [super._applyNewContent(...arguments)];
        var leftIconPlaceholderElem = this.element.find('.content__left-icon-placeholder'),
            leftIconOnline = false,
            leftIconOffline = false;

        if (newContent.hasOwnProperty('isDummy') && newContent.isDummy) {
            leftIconOnline = false;
            leftIconOffline = false;
        } else if (newContent.hasOwnProperty("isOnline")) {
            leftIconOnline = newContent.isOnline;
            leftIconOffline = !newContent.isOnline;
        }

        promises.push(GUI.animationHandler.schedule(function () {
            leftIconPlaceholderElem.toggleClass('content__left-icon-placeholder--online', leftIconOnline);
            leftIconPlaceholderElem.toggleClass('content__left-icon-placeholder--offline', leftIconOffline);
        }.bind(this)));
        return Q.all(promises);
    } // ---------------------------------------------------------------------------------------------------------
    // ---------------------------------------------------------------------------------------------------------


}

GUI.TableViewV2.Cells.DeviceLearningCheckableCell = DeviceLearningCheckableCell;
