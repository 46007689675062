'use strict';

define(["AudioZoneControlEnums"], function (AudioZoneControlEnums) {
    class AudioZoneSettingsAirplay extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            Object.assign(this, StateHandler.Mixin);
            this.details = details;
            this.control = details.control;
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments);

            this._registerForStates(this.control.uuidAction, ["enableAirPlay"]);
        }

        viewWillDisappear() {
            this._unregisterStates();

            super.viewWillDisappear(...arguments);
        }

        titleBarText() {
            return _("media.preferences.airplay");
        }

        receivedStates(states) {
            this.airplayEnabled = states.enableAirPlay;
            this.reloadTable();
        }

        setTableContent() {
            this.tableContent = [{
                rows: [{
                    content: {
                        title: _("media.preferences.allow-airplay"),
                        active: this.airplayEnabled
                    },
                    type: GUI.TableViewV2.CellType.SWITCH,
                    onSwitchChanged: this._onSwitchChanged.bind(this)
                }]
            }];
            return super.setTableContent(...arguments);
        }

        _onSwitchChanged(active, section, row, table) {
            this.control.sendCommand(Commands.format(Commands.AudioZone.SET_AIRPLAY, active));
        }

    }

    Controls.AudioZoneControl.AudioZoneSettingsAirplay = AudioZoneSettingsAirplay;
});
