import * as React from "react"
import Svg, { Path } from "react-native-svg"

function UserOutlined(props) {
    return (
        <Svg isreact="true"
            height={24}
            viewBox="0 0 24 24"
            width={24}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M12 1.5c3.06 0 5.5 2.928 5.5 6.5 0 2.275-.99 4.289-2.5 5.451v2.197l4.677 1.67a3.501 3.501 0 012.318 3.104l.005.192V22.5a.5.5 0 01-.41.492L21.5 23h-19a.5.5 0 01-.492-.41L2 22.5v-1.886c0-1.414.85-2.683 2.144-3.226l.179-.07L9 15.647v-2.195l-.164-.132C7.419 12.141 6.5 10.192 6.5 8c0-3.572 2.44-6.5 5.5-6.5zm2.001 12.558a4.761 4.761 0 01-4.001 0V16a.5.5 0 01-.25.433l-.082.038L4.66 18.26a2.501 2.501 0 00-1.652 2.169L3 20.614V22h18v-1.386c0-.994-.588-1.887-1.487-2.285l-.172-.07-5.01-1.788a.5.5 0 01-.323-.382L14 16zm-1.267-7.61l-.11.113c-.83.802-2.172 1.373-3.407 1.434L9 8a4.607 4.607 0 01-1.496-.235L7.5 8c0 3.056 2.037 5.5 4.5 5.5s4.5-2.444 4.5-5.5v.006l-.132.024c-1.491.226-2.61-.238-3.53-1.441zM12 2.5c-2.113 0-3.913 1.8-4.381 4.24.433.178.875.26 1.381.26 1.304 0 2.866-.786 3.323-1.661a.5.5 0 01.877-.017c.815 1.428 1.674 1.924 3.018 1.72l.186-.033.023-.004C16.04 4.431 14.189 2.5 12 2.5z"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default UserOutlined
