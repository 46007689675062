'use strict';

define([], function () {
    return class AudioserverDiagnosis extends GUI.Screen {
        //region Static
        static Template = class {
            //region Static
            static getDiagnosisContainer(diagnosisData) {
                var diagnosisText = JSON.stringify(diagnosisData, null, 2); // Its important to sanitize the HTML to prevent any XSS injections!

                return $("<pre class='audioserver-diagnosis__text-container'>" + diagnosisText + "</pre>");
            } //endregion Static


        }; //endregion Static

        constructor(details) {
            super($("<div/>"));
            this.control = details.control;
        }

        viewDidLoad() {
            var args = arguments;
            return this.control.audioserverComp.sendMediaServerCommand("audio/cfg/diagnosis").then(res => {
                this.diagnosisData = res.data;
                console.log(this.diagnosisData);
                return super.viewDidLoad(...args).then(function () {
                    this.element.append(AudioserverDiagnosis.Template.getDiagnosisContainer(this.diagnosisData));
                }.bind(this));
            });
        }

        titleBarText() {
            return _("diagnosis-information");
        }

        getWaitingForTitle() {
            return _("diagnosis-information.fetch");
        }

        getWaitingForMessage() {
            return NBR_SPACE;
        }

    };
});
