'use strict';

define("WindowControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class WindowControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            return [
                this.createCommand(_("close"), this.createCmdObj(Commands.WINDOW.CLOSE.JOG_ON, Commands.WINDOW.CLOSE.JOG_OFF), null, { rowIdx: 0 }),
                this.createCommand(_("open"), this.createCmdObj(Commands.WINDOW.OPEN.JOG_ON, Commands.WINDOW.OPEN.JOG_OFF), null, { rowIdx: 0 }),
                this.createDeactivatable(_("window.full.close"), Commands.WINDOW.CLOSE.FULL, null, states.isLocked, { rowIdx: 1 }),
                this.createDeactivatable(_("window.full.open"), Commands.WINDOW.OPEN.FULL, null, states.isLocked, { rowIdx: 1 })
            ];
        }

    };
});
