'use strict';

define(["AudioZoneV2ControlEnums", "MediaBrowserV2Base"], function (AudioZoneV2ControlEnums, MediaBrowserV2Base) {
    class MediaBrowserV2Library extends MediaBrowserV2Base {
        //region Static
        static getCellFromContentTypeItem(item, details, contentType, isPlaylist, isBrowsable, actionClb, buttonTappedClb, vc, isInEditMode) {
            var baseCell = Controls.AudioZoneV2Control.MediaBrowserV2Base.getCellFromContentTypeItem.apply(this, arguments);

            if (item.type === MusicServerEnum.FileType.LOCAL_DIR && item.external && item.configerror) {
                baseCell.content.class = null;
                baseCell.content.buttonSrc = Icon.INFO2;
                baseCell.content.color = window.Styles.colors.red;
            }

            return baseCell;
        }

        static getCellButtonIconSrc() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Base.getCellButtonIconSrc.apply(this, arguments);
        }

        static getCellButtonIconColor() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Base.getCellButtonIconColor.apply(this, arguments);
        }

        static getCellTypeForItemCellAtIndex(item, contentType, isInEditMode) {
            return Controls.AudioZoneV2Control.MediaBrowserV2Base.getCellTypeForItemCellAtIndex.apply(this, arguments);
        }

        static getIconObjForItem(item) {
            return Controls.AudioZoneV2Control.MediaBrowserV2Base.getIconObjForItem.apply(this, arguments);
        } //endregion Static


        //region Getter
        get desc() {
            return _("media.library");
        } //endregion Getter


        constructor(details) {
            super(...arguments);
            this._libScanRes = {};
        }

        viewWillAppear() {
            if (!this.libraryScanRegId) {
                this.libraryScanRegId = this.control.audioserverComp.registerForScanEvents(this.onLibraryScanEvent.bind(this));
            }

            return super.viewWillAppear(...arguments);
        }

        viewDidDisappear() {
            if (!this._viewShouldRemainVisible) {
                this.libraryScanRegId && this.control.audioserverComp.unregisterFromScanEvents(this.libraryScanRegId);
                this.libraryScanRegId = null;
            }

            return super.viewDidDisappear(...arguments);
        }

        onLibraryScanEvent(source, scanRes) {
            this._libScanRes = scanRes;

            if (scanRes.isScanning && this.tableContent.length !== 0) {
                this.setInfoText(_("media.library.re-index-in-progress"));
            } else {
                this.setInfoText(null);
            }

            this.updateEmptyViewConfig();
        }

        getEmptyViewIconSrc() {
            var baseIcon = super.getEmptyViewIconSrc(...arguments);

            if (this.receivedContent) {
                if (this._libScanRes.isScanning) {
                    baseIcon = Icon.INDICATOR;
                } else {
                    baseIcon = null;
                }
            }

            return baseIcon;
        }

        getEmptyViewTitle() {
            var baseTitle = super.getEmptyViewTitle(...arguments);

            if (this.receivedContent) {
                if (this._libScanRes.isScanning) {
                    baseTitle = _("media.library.re-index-in-progress");
                } else {
                    baseTitle = _('media.library.upload.title');
                }
            }

            return baseTitle;
        }

        getEmptyViewMessage() {
            var baseMsg = super.getEmptyViewMessage(...arguments);

            if (this.receivedContent) {
                if (this._libScanRes.isScanning) {
                    if (typeof this._libScanRes.files !== "undefined") {
                        if (this._libScanRes.files > 1 && this._libScanRes.folders > 1) {
                            baseMsg = _("media.library.re-index-detailed--multiple-files-folders", {
                                fileCnt: this._libScanRes.files,
                                folderCnt: this._libScanRes.folders
                            });
                        } else if (this._libScanRes.files > 1) {
                            baseMsg = _("media.library.re-index-detailed--multiple-files", {
                                fileCnt: this._libScanRes.files,
                                folderCnt: this._libScanRes.folders
                            });
                        } else if (this._libScanRes.folders > 1) {
                            baseMsg = _("media.library.re-index-detailed--multiple-folders", {
                                fileCnt: this._libScanRes.files,
                                folderCnt: this._libScanRes.folders
                            });
                        } else {
                            baseMsg = _("media.library.re-index-detailed", {
                                fileCnt: this._libScanRes.files,
                                folderCnt: this._libScanRes.folders
                            });
                        }
                    } else {
                        baseMsg = null;
                    }
                } else {
                    baseMsg = _("media.library.info", {
                        target: this.control.audioserverComp.getServerIp()
                    });
                }
            }

            return baseMsg;
        }

        handleOnItemCellClicked(item, contentType, section, row) {
            if (item.type === MusicServerEnum.FileType.LOCAL_DIR && item.external && item.configerror) {
                return this.handleOnCellButtonClicked.apply(this, arguments);
            } else {
                return super.handleOnItemCellClicked(...arguments);
            }
        }

        handleOnCellButtonClicked(item, contentType) {
            if (item.type === MusicServerEnum.FileType.LOCAL_DIR && item.external && item.configerror) {
                return NavigationComp.showPopup({
                    title: _("media.library.network-share.not-reachable.title"),
                    message: _("media.library.network-share.not-reachable.message", {
                        sharedName: item.name
                    }),
                    color: window.Styles.colors.orange,
                    buttonOk: true
                });
            } else {
                return super.handleOnCellButtonClicked(...arguments);
            }
        }

        getActionsForCtxAndItem(ctx, item) {
            var actions = super.getActionsForCtxAndItem(...arguments);

            switch (ctx) {
                case this.CONTEXT.LMS:
                    if (item === this) {
                        actions.pushObject(this._getActionWithTmplForCtx({
                            content: {
                                title: _("media.library.add"),
                                leftIconSrc: Icon.AudioZone.CONTEXT.LIBRARY_ADD
                            },
                            action: this.ViewController.ViewController.showState.bind(this.ViewController.ViewController, AudioZoneV2ControlEnums.ScreenState.SETTINGS_LIBRARY_ADD_OR_EDIT, null, {
                                control: this.control
                            })
                        }, ctx));

                        if (this.getMediaId(this.lastSelectedItem.contentType)) {
                            actions.pushObject(this._getActionWithTmplForCtx({
                                content: {
                                    title: _("media.library.re-index-specific", {
                                        shareName: this.lastSelectedItem.name
                                    }),
                                    leftIconSrc: Icon.AudioZone.CONTEXT.LIBRARY_SYNC
                                },
                                action: this._reloadLib.bind(this, this.lastSelectedItem)
                            }, ctx));
                        } else {
                            actions.pushObject(this._getActionWithTmplForCtx({
                                content: {
                                    title: _("media.library.re-index"),
                                    leftIconSrc: Icon.AudioZone.CONTEXT.LIBRARY_SYNC
                                },
                                action: this._reloadLib.bind(this, this.lastSelectedItem)
                            }, ctx));
                        }
                    }

                    break;
            }

            return actions;
        }

        _reloadLib(item) {
            var mediaId = this.getMediaId(item.contentType);

            if (mediaId) {
                return this.control.audioserverComp.sendMediaServerCommand(Commands.format(MusicServerEnum.Commands.LIBRARY.RESCAN_SPECIFIC, mediaId));
            } else {
                return this.control.audioserverComp.sendMediaServerCommand(MusicServerEnum.Commands.LIBRARY.RESCAN_ALL);
            }
        }

        _getRightSideTitleBarButtons() {
            return [this._getRightSideTitleBarButtonElm(this.RIGHT_SIDE_BUTTON.CONTEXT_MENU, Icon.Buttons.MORE_HORIZONTAL)];
        }

    }

    Controls.AudioZoneV2Control.MediaBrowserV2Library = MediaBrowserV2Library;
    return Controls.AudioZoneV2Control.MediaBrowserV2Library;
});
