"use strict";

window.MediaContextMenuHandlerV2 = {
    get Mixin() {
        return {
            CONTEXT: {
                LOCAL: "local",
                LMS: "lms",
                // -> Loxone Music Server
                SPOTIFY: "spotify",
                SOUNDSUIT: "soundsuit"
            },
            hasCtxMenu: function hasCtxMenu() {
                if (this.shouldShowCtxBtn) {
                    return this.shouldShowCtxBtn();
                } else {
                    return true;
                }
            },

            /**
             * Shows the Contextual Menu for the given Item
             * @param item
             * @private
             */
            _showContextMenuForItem: function _showContextMenuForItem(item, itemDetails) {
                this.__ctxItem = item;
                this.__itemDetails = itemDetails;
                var texts;

                if (this.__ctxItem instanceof GUI.Screen) {
                    texts = {
                        title: this.titleBarText()
                    };
                } else {
                    texts = this.control.audioserverComp.getTitleSubtitleForItem(this.__ctxItem);
                }

                this.control.audioserverComp.AudioViewController.showState(Controls.AudioZoneV2Control.Enums.ScreenState.CONTEXT_MENU_BASE, null, {
                    control: this.control,
                    delegate: this,
                    title: decodeURIComponent(texts.title)
                }, AnimationType.MODAL);
            },
            _getActionWithTmplForCtx: function _getActionWithTmplForCtx(actionTmpl, ctx) {
                if (!ctx) {
                    console.warn("No context is provided, ignore action!");
                    return null;
                }

                actionTmpl.ctx = ctx;

                if (!actionTmpl.content.hasOwnProperty("clickable")) {
                    actionTmpl.content.clickable = true;
                }

                if (actionTmpl.content.hasOwnProperty("leftIconSrc") && !actionTmpl.content.hasOwnProperty("leftIconColor")) {
                    actionTmpl.content.leftIconColor = Color.TEXT_SECONDARY_B;
                }

                return actionTmpl;
            },

            /**
             * Builds the TableContent for the Contextual Menu Screen
             * @note Its compatible with promises!
             * @returns {Promise}
             * @private
             */
            __getCtxMenuTableContent: function __getCtxMenuTableContent(hideFn) {
                var contexts = Object.values(this.CONTEXT),
                    tmpPrms = null,
                    tmpDefaultPrms = null,
                    ctxPrms = [],
                    tmpSection,
                    contextTableContent = []; // Add the custom provided context sections if available

                if (this.getCustomContextsForItem) {
                    try {
                        contexts = (this.getCustomContextsForItem(this.__ctxItem) || []).concat(contexts);
                    } catch (e) {
                        console.warn(this.name, "Can't get custom contexts for item! -> " + e.message);
                    }
                } // Get actions for every provided context


                contexts.forEach(function (ctx) {
                    if (this.getActionsForCtxAndItem) {
                        try {
                            tmpPrms = Q(this.getActionsForCtxAndItem(ctx, this.__ctxItem));
                            ctxPrms.push(tmpPrms);
                        } catch (e) {
                            console.warn(this.name, "Can't get actions for context and item! -> " + e.message);
                        }
                    }

                    tmpDefaultPrms = Q(this.__getDefaultActionsForCtx(ctx));
                    ctxPrms.push(tmpDefaultPrms);
                }.bind(this)); // Convert them into a TableContent

                return Q.all(ctxPrms).then(function (ctxsActions) {
                    contexts.forEach(function (ctx) {
                        tmpSection = {
                            headerTitle: this.__getCtxDesc(ctx),
                            rows: []
                        };
                        var wantedActions = [];
                        ctxsActions.forEach(function (ctxActions) {
                            if (ctxActions && ctxActions[0] && ctxActions[0].ctx === ctx) {
                                wantedActions = ctxActions.concat(wantedActions);
                            }
                        });
                        tmpSection.rows = wantedActions.filter(function (action) {
                            return action !== null;
                        });

                        if (tmpSection.rows.length) {
                            // Wrap around the provided action to hide the context menu once selecting a cell
                            tmpSection.rows.forEach(function (row) {
                                row.ogAction = row.action;

                                row.action = function () {
                                    Q(hideFn ? hideFn() : NavigationComp.navigateBack()).done(function () {
                                        row.ogAction && row.ogAction();
                                    }.bind(this));
                                }.bind(this);
                            }.bind(this));
                            contextTableContent.push(tmpSection);
                        }
                    }.bind(this));
                    return contextTableContent;
                }.bind(this));
            },

            /**
             * Gets the Default Actions for a given Context
             * @param ctx
             * @returns {Promise}
             * @private
             */
            __getDefaultActionsForCtx: function __getDefaultActionsForCtx(ctx) {
                var prms = [],
                    item,
                    itemOwner;

                switch (ctx) {
                    case this.CONTEXT.LOCAL:
                        if (this.__ctxItem instanceof Controls.AudioZoneV2Control.MediaBrowserV2Base) {
                            item = this.__ctxItem.lastSelectedItem; // Resolve the actual item if the user tapped on the screens context menu
                        } else {
                            item = this.__ctxItem;
                        }

                        if (this.control.audioserverComp.isFileTypePlayable(item.type, item.contentType)) {
                            if (!(this instanceof Controls.AudioZoneV2Control.PlayerV2) && !(this instanceof GUI.AudioZoneV2ControlMiniPlayerViewController)) {
                                prms.pushObject(this.__getPlayNowActionForCtxAndItem(ctx, item));
                                prms.pushObject(this.__getPlayNextActionForCtxAndItem(ctx, item));
                                prms.pushObject(this.__getAddToQueueActionForCtxAndItem(ctx, item));
                            }

                            if (item.contentType !== MusicServerEnum.MediaContentType.ZONE_FAVORITES &&
                                item.contentType !== MusicServerEnum.MediaContentType.SOUNDSUIT) {
                                prms.pushObject(this.__getAddToLMSPlaylistActionForCtxAndItem(ctx, item));
                            }
                        }

                        break;

                    case this.CONTEXT.LMS:
                        prms.pushObject(this.__getZoneFavActionForCtx(ctx));
                        break;

                    case this.CONTEXT.SPOTIFY:
                        itemOwner = Controls.AudioZoneV2Control.SingleTones.SpotifyAccountManager.shared(this.control).availableUsers.find(function (user) {
                            return user.id === this.username;
                        }.bind(this));

                        if (this.__ctxItem instanceof Controls.AudioZoneV2Control.MediaBrowserV2Base) {
                            item = this.__ctxItem.lastSelectedItem; // Resolve the actual item if the user tapped on the screens context menu
                        } else {
                            item = this.__ctxItem;
                        }

                        if (item.contentType === MusicServerEnum.MediaContentType.SERVICE && (item.hasOwnProperty("audiopath") || item.hasOwnProperty("id")) && (item.audiopath || item.id) && (item.audiopath || item.id).indexOf("spotify") !== -1) {
                            if (this.control.audioserverComp.isFileTypePlayable(item.type, item.contentType) && this.identifier === MusicServerEnum.Service.SPOTIFY) {
                                prms.pushObject(this._getActionWithTmplForCtx({
                                    content: {
                                        title: _("media.playlist.add-to-list.spotify"),
                                        leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_ADD
                                    },
                                    action: this.__addItemToPlaylistOfContentType.bind(this, item, MusicServerEnum.MediaContentType.SERVICE, _("media.playlist.add-to-list.spotify"))
                                }, ctx));
                            }

                            var spotifyParts = (item.audiopath || item.id).split(":");
                            spotifyParts.shift();

                            if (spotifyParts.length) {
                                var spotifyUrl = "https://open.spotify.com/" + spotifyParts.join("/"),
                                    spotifyUri = "spotify:" + spotifyParts.join(":");

                                if (this.control.audioserverComp.isSpotifyLikedSongsItem(item)) {
                                    spotifyUrl = "https://open.spotify.com/collection/tracks";
                                    spotifyUri = "spotify:collection:tracks";
                                }

                                prms.pushObject(this._getActionWithTmplForCtx({
                                    content: {
                                        title: _("open-in", {
                                            app: _("media.popup.spotify.title")
                                        }),
                                        leftIconSrc: Icon.AudioZone.CONTEXT.SHORTCUT_ADD
                                    },
                                    action: function () {
                                        if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface || PlatformComponent.getPlatformInfoObj().platform === PlatformType.DeveloperInterface) {
                                            NavigationComp.openWebsite(spotifyUrl);
                                        } else {
                                            NavigationComp.openWebsite(spotifyUri, null, function () {
                                                NavigationComp.openWebsite(spotifyUrl);
                                            });
                                        }
                                    }
                                }, ctx));

                                if (Debug.Experimental) {
                                    prms.pushObject(this._getActionWithTmplForCtx({
                                        content: {
                                            title: "Share (via Song.Link)",
                                            leftIconSrc: Icon.USER_MANAGEMENT.SHARE
                                        },
                                        action: function () {
                                            copyToClipboard("https://song.link/" + spotifyUri);
                                            this.control.audioserverComp.showConfirmationFeedback(_("Link has been copied to your clipboard"));
                                        }.bind(this)
                                    }, ctx));
                                }
                            }

                            if (this.control.audioserverComp.Feature.SERVICE_FOLLOW && itemOwner && !Controls.AudioZoneV2Control.SingleTones.SpotifyAccountManager.shared(this.control).isOwnerOfItem(item, itemOwner) && this.control.audioserverComp.canFollowServiceItem(item)) {
                                prms.pushObject(this.control.audioserverComp.sendMediaServerCommand(Commands.format(MusicServerEnum.Commands.SERVICE_MODIFY.IS_FOLLOWING, this.getMediaInfo(item.contentType).service.uid, item.id || item.audiopath)).then(function (resp) {
                                    if (resp.data.isfollowed) {
                                        return this._getActionWithTmplForCtx({
                                            content: {
                                                title: _("media.playlist.unfollow"),
                                                leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_UNFOLLOW
                                            },
                                            action: this._unfollowServiceItem.bind(this, item)
                                        }, ctx);
                                    } else {
                                        return this._getActionWithTmplForCtx({
                                            content: {
                                                title: _("media.playlist.follow"),
                                                leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_FOLLOW
                                            },
                                            action: this._followServiceItem.bind(this, item)
                                        }, ctx);
                                    }
                                }.bind(this)));
                            }
                        }

                        break;
                    case this.CONTEXT.SOUNDSUIT:
                        itemOwner = Controls.AudioZoneV2Control.SingleTones.SoundsuitAccountManager.shared(this.control).availableUsers.find(function (user) {
                            return user.id === this.username;
                        }.bind(this));

                        if (this.__ctxItem instanceof Controls.AudioZoneV2Control.MediaBrowserV2Base) {
                            item = this.__ctxItem.lastSelectedItem; // Resolve the actual item if the user tapped on the screens context menu
                        } else {
                            item = this.__ctxItem;
                        }

                        if (this.control.audioserverComp.isSoundsuitSchedule(item) && !!itemOwner) {
                            prms.pushObject(this._getActionWithTmplForCtx({
                                content: {
                                    title: _("edit"),
                                    leftIconSrc: Icon.AudioZone.NEW.GEAR
                                },
                                action: function () {
                                    this.control.audioserverComp.openEditSoundsuitSchedule(item);
                                }.bind(this)
                            }, ctx));
                        }

                        break;

                    default:
                        break;
                }

                return Q.all(prms);
            },
            __getZoneFavActionForCtx: function __getZoneFavActionForCtx(ctx) {
                var item = this.__ctxItem;

                if (item instanceof Controls.AudioZoneV2Control.MediaBrowserV2Base) {
                    item = this.__ctxItem.lastSelectedItem;
                }

                if ((this.control.audioserverComp.isFileTypePlayable(item.type, item.contentType) || item.type === MusicServerEnum.FileType.HW_INPUT) && !(item instanceof GUI.Screen)) {
                    if (!this.getMediaInfo) {
                        console.warn(this.name, "this.getMediaInfo is required but missing!");
                        return;
                    }

                    if (item.contentType !== MusicServerEnum.MediaContentType.LINEIN || item.contentType === MusicServerEnum.MediaContentType.LINEIN && this.control.audioserverComp.Feature.INPUT_ZONE_FAVS) {
                        return Controls.AudioZoneV2Control.SingleTones.FavoritesManager.shared(this.control).getExistingFavItem(item).then(function (existingFav) {
                            if (existingFav) {
                                return this._getActionWithTmplForCtx({
                                    content: {
                                        title: _("media.favorite.delete"),
                                        leftIconSrc: Icon.AudioZone.CONTEXT.FAVORITE_REMOVE
                                    },
                                    action: function () {
                                        Controls.AudioZoneV2Control.SingleTones.FavoritesManager.shared(this.control).deleteItemFromFav(existingFav);
                                    }.bind(this)
                                }, ctx);
                            } else if (AudioserverComp.isItemForFavorites(item)) {
                                return this._getActionWithTmplForCtx({
                                    content: {
                                        title: _("media.item.add-to-favorites-of-all-zones"),
                                        leftIconSrc: Icon.AudioZone.CONTEXT.FAVORITE_ADD
                                    },
                                    action: function () {
                                        Controls.AudioZoneV2Control.SingleTones.FavoritesManager.shared(this.control).saveItemAsFav(item, this.getMediaInfo(item.contentType));
                                    }.bind(this)
                                }, ctx);
                            }
                        }.bind(this));
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            },
            __getPlayNowActionForCtxAndItem: function __getPlayNowActionForCtxAndItem(ctx, item) {
                return this._getActionWithTmplForCtx({
                    content: {
                        title: _("media.item.play-now"),
                        leftIconSrc: Icon.AudioZone.CONTEXT.PLAY
                    },
                    action: function () {
                        if (this._playItem) {
                            this._playItem(item, true);
                        } else if (this.__itemDetails) {
                            this.control.audioserverComp.sendPlayerCommandFromType(item, null, this.__itemDetails);
                        } else {
                            this.handleItemPlayCommand(item, MusicServerEnum.PlayType.REPLACE);
                        }
                    }.bind(this)
                }, ctx);
            },
            __getPlayNextActionForCtxAndItem: function __getPlayNextActionForCtxAndItem(ctx, item) {
                // The Musicserver is not capable to execute this action for Zone Favorites as of now
                if (item.contentType !== MusicServerEnum.MediaContentType.ZONE_FAVORITES) {
                    if (this.__shouldDropQueueActionForItem(item)) {
                        return;
                    }

                    return this._getActionWithTmplForCtx({
                        content: {
                            title: _("media.play.next"),
                            leftIconSrc: Icon.AudioZone.CONTEXT.PLAY_NEXT
                        },
                        action: function () {
                            this.control.audioserverComp.showConfirmationFeedback(_("media.notifications.queue.next"));
                            this.handleItemPlayCommand(item, MusicServerEnum.PlayType.NEXT);
                        }.bind(this)
                    }, ctx);
                }
            },
            __getAddToQueueActionForCtxAndItem: function __getAddToQueueActionForCtxAndItem(ctx, item) {
                // The Musicserver is not capable to execute this action for Zone Favorites as of now
                if (this.control.audioserverComp.Feature.V2_FIRMWARE && item.contentType !== MusicServerEnum.MediaContentType.ZONE_FAVORITES) {
                    if (this.__shouldDropQueueActionForItem(item)) {
                        return;
                    }

                    return this._getActionWithTmplForCtx({
                        content: {
                            title: _("media.item.add-to-queue"),
                            leftIconSrc: Icon.AudioZone.CONTEXT.PLAY_LAST
                        },
                        action: function () {
                            this.control.audioserverComp.showConfirmationFeedback(_("media.notifications.queue.add"));
                            this.handleItemPlayCommand(item, MusicServerEnum.PlayType.ADD);
                        }.bind(this)
                    }, ctx);
                }
            },

            /**
             * Checks for the various reasons why an item shouldn't have a queue add or insert action.
             * @param item
             * @returns {boolean}
             * @private
             */
            __shouldDropQueueActionForItem: function __shouldDropQueueActionForItem(item) {
                // unable to mix content of different types (Spotify, MP3, TuneIn, ...)
                if (!this.__matchesCurrentPlaying(item)) {
                    return true;
                } // tunein streams are special


                if (this.__isTuneInItemOrPlaying(item)) {
                    return true;
                } // lineins are also special


                if (this.__isLineInItemOrPlaying(item)) {
                    return true;
                }

                return !this.control.audioserverComp.isQueueActionAllowed(MusicServerEnum.QueueAction.INSERT);
            },

            /**
             * It has been decided by the audio team with that mixed content cannot be supported as of now. Hence we're disabling
             * it based on the check implemented here. Compares the audiopath/id prefix with the one returned by the audio server.
             * @param item
             * @returns {boolean}
             * @private
             */
            __matchesCurrentPlaying: function __matchesCurrentPlaying(item) {
                // the audio server is not capable of mixing content together (spotify/library/tunein) in the queue.
                var currPrefix = this.__getPrefixFrom(this.control.getStates().audiopath),
                    // everything currently playing has an audiopath, but not an id.
                    itemPrefix = this.__getPrefixFrom(item.audiopath || item.id); // all items have ids, but not all have audiopaths


                if (itemPrefix !== currPrefix) {
                    console.warn("Context Menu Action dropped, prefix doesn't match currently playing one! Curr: '" + currPrefix + "', item: '" + itemPrefix + "'");
                    return false;
                } else {
                    return true;
                }
            },

            /**
             * Tune-In items can't be added to the queue or played next, neither can anything be added to a queue while tune-in
             * items are currently playing.
             * @param item
             * @returns {boolean}
             * @private
             */
            __isTuneInItemOrPlaying: function __isTuneInItemOrPlaying(item) {
                var isTuneInItem = this.__getPrefixFrom(item.id) === "tunein",
                    isTuneInPlaying = this.__getPrefixFrom(this.control.getStates().audiopath) === "tunein";
                isTuneInItem && console.warn("Context Menu Action dropped, tune in items don't support it");
                isTuneInPlaying && console.warn("Context Menu Action dropped, not supported while tune in items are playing!");
                return isTuneInItem || isTuneInPlaying;
            },

            /**
             * LineIns can't be added to the queue or played next, neither can anything be added to a queue while a lineIn is
             * currently playing.
             * @param item
             * @returns {boolean}
             * @private
             */
            __isLineInItemOrPlaying: function __isLineInItemOrPlaying(item) {
                var isLineInItem = this.__getPrefixFrom(item.id) === "linein",
                    isLineInPlaying = this.__getPrefixFrom(this.control.getStates().audiopath) === "linein";
                isLineInItem && console.warn("Context Menu Action dropped, lineins don't support it");
                isLineInPlaying && console.warn("Context Menu Action dropped, not supported while linein is playing!");
                return isLineInItem || isLineInPlaying;
            },

            /**
             * Extracts the prefix ('spotify', 'library', 'tunein', 'airplay') from an id or audio path
             * @param source
             * @returns {*}
             * @private
             */
            __getPrefixFrom: function __getPrefixFrom(source) {
                var prefix;

                if (!source || typeof source !== "string") { // id could be an int.
                    console.warn(this.viewId || this.name, "__getPrefixFrom failed - empty source! " + JSON.stringify(source));
                    return source;
                } else if (source.indexOf("spotify") === 0) {
                    // spotify doesn't have : as separator, but an @
                    prefix = "spotify";
                } else if (source.indexOf(":") >= 0) {
                    prefix = source.split(":")[0];
                } else {
                    prefix = source;
                }

                return prefix;
            },
            __getAddToLMSPlaylistActionForCtxAndItem: function __getAddToLMSPlaylistActionForCtxAndItem(ctx, item) {
                if (item.contentType !== MusicServerEnum.MediaContentType.SERVICE) {
                    var title = _("media.playlist.add-to-list.local");

                    return this._getActionWithTmplForCtx({
                        content: {
                            title: title,
                            leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_ADD
                        },
                        action: this.__addItemToPlaylistOfContentType.bind(this, item, MusicServerEnum.MediaContentType.PLAYLISTS, title)
                    }, ctx);
                }
            },
            __addItemToPlaylistOfContentType: function __addItemToPlaylistOfContentType(item, dstContentType, title) {
                var details = {
                    control: this.control.audioserverComp.getActiveZoneControl(),
                    mediaType: MusicServerEnum.MediaType.PLAYLIST
                };

                if (dstContentType === MusicServerEnum.MediaContentType.SERVICE) {
                    details.identifier = MusicServerEnum.Service.SPOTIFY;
                    details.lastSelectedItem = {
                        id: MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS,
                        contentType: dstContentType
                    };
                    details.username = this.username;
                } else if (dstContentType === MusicServerEnum.MediaContentType.PLAYLISTS) {
                    details.identifier = MusicServerEnum.Service.LMS;
                    details.lastSelectedItem = {
                        contentType: dstContentType
                    };
                    details.username = MusicServerEnum.NOUSER;
                } else {
                    console.warn("The contentType isn't supported yet!");
                    return;
                }

                details.contentTypes = [dstContentType];
                details.lastSelectedItem.name = title; // ViewController specific properties

                details.targetMediaScreenState = Controls.AudioZoneV2Control.MediaBrowserV2Base.getScreenStateForItem(details.lastSelectedItem);
                details.addTargetName = title;
                details.delegate = this;
                details.itemToAdd = item;
                details.blockedIds = [item.audiopath || item.id];

                if (this.lastSelectedItem && this.lastSelectedItem.id) {
                    details.blockedIds.push(this.lastSelectedItem.id);
                }

                details.mustHandleEditMode = true;
                this.control.audioserverComp.AudioViewController.showState(Controls.AudioZoneV2Control.Enums.ScreenState.ADD_MEDIA_VC_PLAYLIST_PICKER, null, details);
            },
            _followServiceItem: function _followServiceItem(item) {
                return this.control.audioserverComp.sendMediaServerCommand(Commands.format(MusicServerEnum.Commands.SERVICE_MODIFY.FOLLOW, this.getMediaInfo(item.contentType).service.uid, item.id || item.audiopath)).then(function () {
                    this.control.audioserverComp.serviceItemFollowedChanged(MusicServerEnum.MediaContentType.SERVICE, item, true);
                }.bind(this));
            },
            _unfollowServiceItem: function _unfollowServiceItem(item) {
                return this.control.audioserverComp.sendMediaServerCommand(Commands.format(MusicServerEnum.Commands.SERVICE_MODIFY.UNFOLLOW, this.getMediaInfo(item.contentType).service.uid, item.id || item.audiopath)).then(function () {
                    this.control.audioserverComp.serviceItemFollowedChanged(MusicServerEnum.MediaContentType.SERVICE, item, false);
                }.bind(this));
            },
            handleItemPlayCommand: function handleItemPlayCommand(item, playType) {
                var cmd = this.control.audioserverComp.getPlayCommandForItem(item, playType, item.contentType, this.getMediaInfo(item.contentType)),
                    result = false;

                if (cmd) {
                    result = this.control.audioserverComp.sendAudioZoneCommand(this.control.details[MusicServerEnum.Event.PLAYER_ID], {
                        cmd: cmd
                    });
                }

                return result;
            },
            __getCtxDesc: function __getCtxDesc(ctx) {
                var desc = null;

                switch (ctx) {
                    case this.CONTEXT.LMS:
                        desc = _("audio-server");
                        break;

                    case this.CONTEXT.SPOTIFY:
                        desc = _("media.popup.spotify.title");
                        break;

                    default:
                        if (this.getCtxDesc) {
                            desc = this.getCtxDesc(ctx);
                        }

                        break;
                }

                return desc;
            }
        };
    }

};
