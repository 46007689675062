import {useContext, useEffect, useState} from "react";
import {ControlContext, useConnectionReady} from "../../../react-comps/Components";
import PeriodicTaskRunner from "../../../shared/logic/tasks/PeriodicTaskRunner";

/**
 * hook which downloads the current values periodically from the wallbox manager.
 * Used for nodes that aren't on the root level.
 * @param nodeUuid
 * @returns {{power: number}}
 */
export default function useWallboxEmStatusRequest(nodeUuid) {
    const {control} = useContext(ControlContext);
    const connected = useConnectionReady(true);
    const [value, setValue] = useState({power: 0});

    useEffect(() => {
        let isRendered = true;

        const updateValue = () => {
            Debug.Control.WallboxManager && console.log(useWallboxEmStatusRequest.name, nodeUuid + " -  updateValue");
            if (connected) {
                return control?.requestNodeValue(nodeUuid).then(res => {
                    Debug.Control.WallboxManager && console.log(useWallboxEmStatusRequest.name, nodeUuid + " - updateValue, response: ", res);
                    isRendered && setValue({...res});
                });
            }
        }
        let stopRunner = null;

        if (connected) {
            updateValue().then(() => {
                stopRunner = PeriodicTaskRunner.run(updateValue, 5000);
            })
        }

        return () => {
            stopRunner && stopRunner();
            isRendered = false;
        }
    }, [control, connected])

    return value;
}