'use strict';
/*
 iconSrc
 title
 value -> An Array of values eg. [12, 43] for 12:43 Uhr
 minTime (Milliseconds)
 maxTime (Milliseconds)

 onPickerChanged(section, row, tableView, value)

 Examples:
 Label:                 12:48 Uhr
 -- --
 Wheel:     12 48
 -- --
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.TIME_PICKER = "TimePickerCell";

    class TimePickerCell extends GUI.TableViewV2.Cells.PickerBaseCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("time-picker-cell");
        }

        initDetail(detailElement) {
            super.initDetail(detailElement);
            detailElement.addClass("time-picker-cell__detail");
        }

        /**
         * This function initializes the Picker
         */
        preparePicker() {
            var timeFormat = LxDate.getMobiscrollTimeFormat(true).replace("mm", "ii");
            var timeWheels = LxDate.getMobiscrollTimeFormat(true, false, this.force24hours).replace("mm", "ii");
            this.picker.time(this.mergePickerConfig({
                minTime: this.content.minTime ? this.content.minTime : 0,
                maxTime: this.content.maxTime ? this.content.maxTime : 24 * 60 * 60 * 1000,
                wheelOrder: this.wheelOrder,
                timeWheels: timeWheels.replace(":", "").replace(" ", ""),
                timeFormat: timeFormat
            }));
            this.setPickerValue(this.content.value);
        }

        /**
         * This function will be called once the pickerValue has changed
         * For example if the user turns one of the pickerWheels
         * @param v The value returned by mobiscroll int he given format
         */
        onChange(v) {
            this.antiGhostTimer.fire();
            var val = this.picker.mobiscroll('getVal');
            var res = [val.getHours(), val.getMinutes(), val.getSeconds()];
            this.updateLabel(this.getValueAsString(v));
            this.content.value = res;
            this.delegate.onPickerChanged.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView, res);
        }

        /**
         * This function returns the string for the timeLabel
         * @param val
         */
        getValueAsString(val) {
            var stringValue = "";

            if (typeof val === "string") {
                stringValue = val;
            } else {
                var date = this._valuesToDate(val);

                var curr = moment(date);
                stringValue = curr.format(LxDate.getMobiscrollTimeFormat(true));
            }

            if (!useAmPm()) {
                stringValue += " " + _("timeSuffix");
            }

            return stringValue;
        }

        /**
         * This sets the given value to the mobiscroll picker
         * @param value This must be in the right format you specified in the picker config.
         */
        setPickerValue(value) {
            var date = this._valuesToDate(value);

            this.picker.mobiscroll('setVal', date);
        }

        _valuesToDate(values) {
            var date = new Date(2015, 1, 1, 0, 0, 0, 0);

            if (values[0]) {
                date.setHours(values[0]);
            }

            if (values[1]) {
                date.setMinutes(values[1]);
            }

            if (values[2]) {
                date.setSeconds(values[2]);
            }

            return date;
        }

    }

    GUI.TableViewV2.Cells.TimePickerCell = TimePickerCell;
    return GUI;
}(window.GUI || {});
