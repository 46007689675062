import * as React from "react"
import Svg, { Path } from "react-native-svg"

function DeviceSearch(props) {
  return (
    <Svg
      width={24}
      isreact="true"
      height={23}
      viewBox="0 0 24 23"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M12 .435a.5.5 0 01.5.5v1.417h4.27V.935a.5.5 0 011 0v1.417H20.148a1.678 1.678 0 01.7.173c.18.09.374.232.523.454.15.226.234.505.234.832v2.375h1.419a.5.5 0 010 1h-1.42v2.1a.5.5 0 01-1 0V3.81a.482.482 0 00-.065-.277.343.343 0 00-.137-.115.68.68 0 00-.261-.067H3.852a.482.482 0 00-.277.066.343.343 0 00-.114.137.68.68 0 00-.067.25V18.19c0 .152.036.233.065.277a.343.343 0 00.137.115.68.68 0 00.25.066h6.439a.5.5 0 110 1H7.23v1.418a.5.5 0 01-1 0v-1.417H3.85a1.676 1.676 0 01-.7-.173 1.341 1.341 0 01-.523-.454 1.472 1.472 0 01-.233-.832v-2.375H.977a.5.5 0 010-1h1.417V11.5H.977a.5.5 0 010-1h1.417V7.186H.977a.5.5 0 010-1h1.417v-2.38l.003-.072a1.674 1.674 0 01.17-.626c.089-.18.23-.374.454-.522.225-.15.504-.234.831-.234H6.23V.935a.5.5 0 011 0v1.417h4.27V.935a.5.5 0 01.5-.5z"
        fill="#000"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.438 12.401a4.841 4.841 0 017.182 6.475l2.797 2.798a.5.5 0 01-.707.707l-2.797-2.798a4.841 4.841 0 01-6.475-7.182zm3.423-.418a3.841 3.841 0 100 7.683 3.841 3.841 0 000-7.683z"
        fill="#000"
      />
    </Svg>
  )
}

export default DeviceSearch
