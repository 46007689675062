import {View} from "react-native";
import PropTypes from "prop-types";
import globalStyles from "GlobalStyles";
import {LxReactBadge, LxReactText} from "LxComponents";
import Icons from "IconLib";
import {LxReactRenderErrorView} from "LxComponents";

/**
 * @returns {JSX.Element} A test alarm cell with some Weekday Badges in the bottom component
 */
function LxReactAlarmClockBottomContainer(props) {

    const Badge = ({text, idx}) => {
        return (
            <LxReactBadge
                text={text}
                textStyle={globalStyles.textStyles.footNote.default}
                backgroundColor={props.isActive ? globalStyles.colors.white : globalStyles.colors.grey["300a36"]}
                textColor={props.isActive ? globalStyles.colors.black : globalStyles.colors.white}
                key={`badge.${idx}`}
                badgeType={"round"}
                containerStyle={{ aspectRatio: undefined }} // MS Shortcuts support changed LxReactBadge to maintain aspect ratio, to avoid overgrown badges it must be unset here
            />
        )
    }

    const BadgeGroup = ({dayType}) => {
        let badges = props[dayType];
        if (badges) {
            return (
                <View key={dayType} style={styles.badgeContainer}>
                    {
                        badges.map((text, idx) => <Badge text={text} idx={idx}/>)
                    }
                </View>
            );
        } else {
            return null;
        }
    }

    const InfoText = () => {
            return <View style={styles.infoContainer}>
                <Icons.InfoCircled style={styles.infoIcon}/>
                <LxReactText style={styles.infoText}>
                    {
                        _("controls.alarmClock.editable-on-device", {
                            productName: _("controls.alarmClock.touchNightLight.product-name")
                        })
                    }
                </LxReactText>
            </View>
    }

    try {
        return (
            <View
                style={styles.container}
                key={props.pkey + "-vw"}
            >
                <BadgeGroup dayType={"weekDays"} />
                <BadgeGroup dayType={"specialDays"} />
                {props.nightLight && <InfoText/>}
            </View>
        )
    } catch (e) {
        return <LxReactRenderErrorView error={e}/>
    }
}

const styles = {
    container: {
        paddingVertical: globalStyles.spacings.gaps.small,
        maxWidth: "50%",
        flexDirection: "column",
        flexWrap: "wrap"
    },
    badgeContainer: {
        flexDirection: "row",
        alignItems: "center"
    },
    infoContainer: {
        flexDirection: "row",
        alignItems: "center",
        marginVertical: globalStyles.spacings.gaps.smaller
    },
    infoText: {
        ...globalStyles.textStyles.footNote.default,
        color: globalStyles.colors.text.secondary,
        marginLeft: globalStyles.spacings.gaps.small
    },
    infoIcon: {
        height: globalStyles.sizes.icons.small,
        width: globalStyles.sizes.icons.small, fill: globalStyles.colors.text.secondary
    }
}

LxReactAlarmClockBottomContainer.propTypes = {
    weekDays: PropTypes.arrayOf(PropTypes.string),
    specialDays: PropTypes.arrayOf(PropTypes.string),
    nightLight: PropTypes.bool,
    isActive: PropTypes.bool
}

export default LxReactAlarmClockBottomContainer
