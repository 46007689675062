'use strict';

var AntiGhostTimer = function () {
    var ANTI_GHOST_DURATION = 2000;

    /**
     * Creates an anti ghost timer that can be configured
     * @param stopCallback
     * @param duration      ms how long the timer should be active. default 2000
     * @constructor
     */

    function AntiGhostTimer(stopCallback, duration) {
        this.antiGhostTimer = null;
        this.duration = duration ? duration : ANTI_GHOST_DURATION;
        this.stopCallback = stopCallback;
    }

    AntiGhostTimer.prototype.destroy = function destroy() {
        clearTimeout(this.antiGhostTimer);
    }; // Public functions


    AntiGhostTimer.prototype.fire = function fire() {
        this._startGhostBuster();
    };

    AntiGhostTimer.prototype.isAntiGhostTimerActive = function isAntiGhostTimerActive() {
        return this.isActive();
    };

    AntiGhostTimer.prototype.isActive = function isActive() {
        return this.antiGhostTimer ? true : false;
    };

    AntiGhostTimer.prototype.resetAntiGhostTimer = function resetAntiGhostTimer() {
        clearTimeout(this.antiGhostTimer);
        this.antiGhostTimer = null;
    };
    /**
     * The function passed in will be called once the the ghost timer is ready. E.g. updating the state
     * @param fn
     */


    AntiGhostTimer.prototype.performWhenReady = function performWhenReady(fn) {
        if (this.isActive()) {
            this._performFn = fn;
        } else {
            fn();
        }
    }; // Private functions


    AntiGhostTimer.prototype._startGhostBuster = function startGhostBuster() {
        clearTimeout(this.antiGhostTimer);
        this.antiGhostTimer = setTimeout(function () {
            this.antiGhostTimer = null;
            this.stopCallback && this.stopCallback();
            this._performFn && this._performFn();
            this._performFn = null;
        }.bind(this), this.duration);
    };

    return AntiGhostTimer;
}();
