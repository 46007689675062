'use strict';

define('StatusMonitorControl', ['Control'], function (Control) {
    return class StatusMonitorControl extends Control {
        constructor() {
            super(...arguments);
        }

        getReactControlContentFlags() {
            return {
                showStateIcon: false,
            };
        }

        getControlIcon() {
            return this.defaultIcon
                ? this.defaultIcon
                : Icon.StatusMonitor.DEFAULT_ICON;
        }
    };
});
