'use strict';
/**
 * The Main Difference between regular TableViewCells and reusingListCells, is that tableViewCells receive content and
 * prepare their vies for that very content inside viewDidLoad. ReusableListCells prepare for all possible scenarios
 * inside viewDidLoad and respond to content changes inside "updateContent".
 *
 * This allows an effective way of reusing list cells.
 *
 * @param rowIdx          reusable cells have to know about what row they are being reused in.
 * @param [clickable]     whether or not the cell itself can be clicked --> TRUE if missing
 *
 * @delegate onListCellTapped (cell, row, list)
 */

window.GUI = function (GUI) {
    GUI.ReusingListView.CellType = {};
    GUI.ReusingListView.Cells = {};
    GUI.ReusingListView.CellHeight = {
        DEFAULT: 60,
        COMFORT_MODE_2020: 72
    };
    /**
     * using this enum it should become very easy to provide and query content for reusingListViewContents.
     * @type {{ROW: string, CLICKABLE: string}}
     */

    GUI.LVContent = {
        ROW: "rowIdx",
        CLICKABLE: "clickable",
        CUSTOM_CLASS: "class"
    };

    class ReusableListCell extends GUI.View {
        //region Static
        static Template = function () {
            var getCellTemplate = function getCellTemplate() {
                return $('<div class="reusable-list-cell">' + '<div class="cell__content"></div>' + '</div>');
            };

            return {
                getCellTemplate: getCellTemplate
            };
        }(); //endregion Static

        constructor(delegate, cellType, listView) {
            super(ReusableListCell.Template.getCellTemplate());
            Object.assign(this, RippleContainer.Mixin);
            this.delegate = delegate;
            this.cellType = cellType;
            this.listView = listView;
            Debug.GUI.ListViewCells && console.log(this.viewId, "contructor");
            this.name = "ReusableListCell";
            this.rippleEnabled = true;
        }

        viewDidLoad() {
            Debug.GUI.ListViewCells && console.log(this.viewId, "viewDidLoad start");
            this.elements = {};
            return super.viewDidLoad(...arguments).then(function (res) {
                this.contentPlaceholder = this.element.find('.cell__content'); // create a cell button.

                this.cellButton = new GUI.LxButton(this, this.contentPlaceholder[0], Color.BUTTON_GLOW, null, true);
                this.addToHandledSubviews(this.cellButton);
                this.cellButton.setCancelOnDrag(true);
                Debug.GUI.ListViewCells && console.log(this.viewId, "viewDidLoad end");
                return res;
            }.bind(this));
        }

        viewWillAppear() {
            var promise = super.viewWillAppear(...arguments);
            this.stopRipple(); // use hit & release instead of cell tap, because then we know wether or not scrolling was stopped with

            this.cellButton.onButtonHit = this.onCellHit.bind(this);
            this.cellButton.onButtonReleased = this.onCellReleased.bind(this);
            return promise;
        }

        viewDidDisappear() {
            this.cellButton.onButtonHit = this.onCellHit.bind(this);
            this.cellButton.onButtonReleased = this.onCellReleased.bind(this);
            return super.viewDidDisappear(...arguments);
        }

        destroy() {
            Debug.GUI.ListViewCells && console.log(this.viewId, "destroy"); // When using promises, they might fire on an destroyed cell. Using the content one can ensure it's still
            // "alive". E.g. in mediaAddListCells "_safePerform" - it'll use hasCAttr, which in turn uses the content.

            delete this.content;
            return super.destroy(...arguments);
        }

        /**
         * Called when a cell is being initialized with content, or when it is reused.
         * @param content
         * @param scrolling  if the cell is being updated while scrolling is active.
         */
        updateContent(content, scrolling) {
            if (!content.hasOwnProperty(GUI.LVContent.ROW)) {
                throw new Error("Cannot update a cells content without specifying what row it is being used in!");
            }

            var oldCustomClass, newCustomClass;

            if (this.content && this.hasCAttr(GUI.LVContent.CUSTOM_CLASS)) {
                oldCustomClass = this.cAttr(GUI.LVContent.CUSTOM_CLASS);
            }

            this.scrolling = scrolling;
            this.content = content;
            this.updateCellClickable(); //it's clickable if its not scrolling.

            this.updateEnabled(!this.cAttr(GUI.LVContent.DISABLED, false));

            if (this.hasCAttr(GUI.LVContent.CUSTOM_CLASS)) {
                newCustomClass = this.cAttr(GUI.LVContent.CUSTOM_CLASS);
            }

            GUI.animationHandler.schedule(function () {
                oldCustomClass && this.element.toggleClass(oldCustomClass, false);
                newCustomClass && this.element.toggleClass(newCustomClass, true);
                this.element.attr("list-row", this.content[GUI.LVContent.ROW]);
            }.bind(this));
        }

        /**
         * There might be something that is needed to be done after scrolling has ended. E.g. cells should not be
         * clickable whiles scrolling is active -> if a cell is selected while scrolling is still active, it won't
         * forward it to its delegate.
         **/
        prepareAfterScroll() {
            Debug.GUI.ListViewCells && console.log(this.viewId, "prepareAfterScroll " + this.content[GUI.LVContent.ROW] + " - " + this.content.title);
            this.scrolling = false;
            this.updateCellClickable();
        }

        /**
         * This method is called whenever a cells has been scrolled to and can now be extended. A cell that is
         * just visible for a short time while being scrolled over will not be made visible.
         */
        extendCellContent() {// nothing to do here.
        }

        /**
         * Will check the current content whether or not it should be clickable and then it will adopt the
         * cellButton accordingly.
         */
        updateCellClickable() {
            var clickable = this.cAttr("clickable", true) && !this.cAttr(GUI.LVContent.DISABLED, false);

            if (clickable && this.scrolling) {
                clickable = false;
            }

            this.cellButton.setEnabled(clickable);
            GUI.animationHandler.toggleCssClass(this.contentPlaceholder, "clickable", clickable);
            this.rippleEnabled = clickable;
        }

        updateEnabled(enabled) {
            GUI.animationHandler.toggleCssClass(this.element, "disabled", !enabled);
        }

        /**
         * Will try to dispatch the listCellTapped event to the corresponding delegate.
         */
        handleCellTapped(src, ev) {
            if (this.delegate && this.delegate.onListCellTapped && !this.scrolling) {
                Debug.GUI.ListViewTiming && debugLog(this.viewId, "handleCellTapped: " + this.content[GUI.LVContent.ROW]);
                ev.gesture && this.startHammerRipple(ev); // It'll take about 150ms for the animation to start

                setTimeout(this._dispatchCellTapped.bind(this, ev), 150);
            } else {
                Debug.GUI.ListViewCells && console.info("Avoided onListCellTapped due to active scroll! " + this.content[GUI.LVContent.ROW] + " - " + this.content.title);
            }
        }

        /**
         * If the cell is hit while scrolling, the release-event is to be ignored. we don't want touch events of
         * cells while scrolling is active.
         */
        onCellHit() {
            this.ignoreReleaseEvent = this.scrolling;
        }

        /**
         * Ensures that the hit-event before this release event did not occur while scrolling was active. because
         * if so, then the scrolling was stopped by this "hit" and the release should not be propagated.
         * @param src
         * @param ev
         */
        onCellReleased(src, ev) {
            if (!this.scrolling && !this.ignoreReleaseEvent) {
                if (!this._tappedBlockTimeout) {
                    this.handleCellTapped(src, ev);
                    this._tappedBlockTimeout = setTimeout(function () {
                        clearTimeout(this._tappedBlockTimeout);
                        this._tappedBlockTimeout = null;
                    }.bind(this), 300);
                }
            }

            this.ignoreReleaseEvent = false;
        }

        /**
         * Will return the corresponding value from the content. If it wasn't provided, it'll use the default value
         * provided.
         * @param identifier
         * @param defaultValue
         * @returns {*}
         * @private
         */
        cAttr(identifier, defaultValue) {
            var result;

            if (this.hasCAttr(identifier)) {
                result = this.content[identifier];
            } else {
                result = defaultValue;
            }

            return result;
        }

        /**
         * Returns whether or not the content has a value for CAttr
         * @param identifier
         * @returns {boolean}
         */
        hasCAttr(identifier) {
            if (!this.content) {
                return false;
            }

            var result = this.content.hasOwnProperty(identifier);

            if (result) {
                // 'null' as a value will return true, but it should be treated as if there is no value in there.
                result = this.content[identifier] !== null;
            }

            return result;
        }

        _dispatchCellTapped(ev) {
            this.delegate.onListCellTapped.call(this.delegate, this, this.content[GUI.LVContent.ROW], this.listView);
        }

    }

    GUI.ReusingListView.Cells.BaseCell = ReusableListCell;
    return GUI;
}(window.GUI || {});
