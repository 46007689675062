import globalStyles from "GlobalStyles";

export function getStatsForDataPoints(dataPoints = []) {

    let min = NaN,
        max = NaN,
        diff = NaN,
        vLowMax = NaN,
        lowMax = NaN,
        highMax = NaN,
        average = NaN;

    if (!!dataPoints.length) {
        min =  Math.min(...dataPoints);
        max = Math.max(...dataPoints);
        diff = (max - min) / 4;
        vLowMax = min + diff;
        lowMax = vLowMax + diff;
        highMax = lowMax + diff;
        average = dataPoints.reduce((a, b) => {
            return a + b;
        }, 0) / dataPoints.length;
    }

    return {
        max,
        highMax,
        lowMax,
        vLowMax,
        min,
        average
    }
}

export const Categorization = {
    LOWEST: "lowest",
    V_LOW: "vLow",
    LOW: "low",
    HIGH: "high",
    V_HIGH: "vHigh",
    HIGHEST: "highest"
}

export function categorizeValue({value, stats = {}}, manualMax) {
    if (typeof value === 'number') {
        // There is a difference in the fraction digits between the Miniserver States and the forecasts,
        // Fixing it to our number of fraction digits (3) fixes this imbalance
        value = value.toFixed(3);
    }

    const {
        max,
        highMax,
        lowMax,
        vLowMax,
        min
    } = stats;
    if (typeof max !== "number" ||
        typeof highMax !== "number" ||
        typeof lowMax !== "number" ||
        typeof vLowMax !== "number" ||
        typeof min !== "number") {
        return;
    }
    if (value === stats.max || value > manualMax) {
        return Categorization.HIGHEST;
    } else if (value > stats.highMax) {
        return Categorization.V_HIGH;
    } else if (value > stats.lowMax) {
        return Categorization.HIGH;
    } else if (value > stats.vLowMax) {
        return Categorization.LOW;
    } else if (value > stats.min) {
        return Categorization.V_LOW;
    } else {
        return Categorization.LOWEST
    }
}

export const removeDuplicates = (arr) => {
    const uniqueStrSet = new Set(arr.map(JSON.stringify));
    return Array.from(uniqueStrSet).map(JSON.parse);
};

/**
 * Determines the predominant resolution based on the given datapoints.
 * @param {number[]} datapoints - The array of datapoints.
 * @param {number} [tolerance=2] - The tolerance value for determining the predominant resolution.
 * @param {boolean} [forecastMode=false] - Indicates whether the datapoints are in forecast mode.
 * @returns {object} - An object containing the iterator function, isSame function, and resolution multiplier - everything in the app is built to work with 1h resolution this multiplier will bring whatever decided resolution to 1h formats.
 */
export const determineResolution = (datapoints, tolerance = 2, forecastMode = false) => {
    let dates = [];
    
    if(!forecastMode) {
    // Convert timestamps to Date objects
    dates = removeDuplicates(datapoints).map(ts => moment.unix(ts).toDate());
    } else {
        dates = removeDuplicates(datapoints).map(ts => moment.unix(ts.s).toDate());
    }
    
    // Calculate differences in minutes
    const differences = [];
    for (let i = 1; i < dates.length; i++) {
        const diff = (dates[i] - dates[i - 1]) / (1000 * 60); // Difference in minutes
        differences.push(diff);
    }

    // Count occurrences of each resolution
    const resolutions = [15, 30, 60];
    const counts = resolutions.map(res => differences.filter(diff => diff === res).length);

    // Find the predominant resolution
    const predominantResolution = resolutions[counts.indexOf(Math.max(...counts))];

    // Calculate the total differences that match the predominant resolution
    const predominantCount = differences.filter(diff => diff === predominantResolution).length;

    // If the predominant count is greater than the threshold, return the appropriate functions
    if (predominantCount >= differences.length - tolerance) {
        return {
            iteratorFn: (time) => time.add(Number(predominantResolution), 'minutes'),
            resolutionMultiplier: 60 / Number(predominantResolution)
        }
    } 

    // Return mixed or other resolution functions if no predominant resolution is found
    return {
        iteratorFn: (time) => time.add(defaultResolution, 'minutes'),
        resolutionMultiplier: 1
    };
};

export function getColorFor({value, stats, category}, manualMax) {
    if (!category) {
        category = categorizeValue({ value, stats }, manualMax);
    }
    switch (category) {
        case Categorization.HIGHEST:
        case Categorization.V_HIGH:
            return globalStyles.colors.red_fixed;
        case Categorization.HIGH:
            return "#FFCC00";
        case Categorization.LOW:
            return globalStyles.colors.green_fixed;
        case Categorization.V_LOW:
        case Categorization.LOWEST:
            return "#498737";
        default:
            return "white";
    }
}

export function getHumanReadableCategorization({value, stats, category}) {
    if (!category) {
        category = categorizeValue({ value, stats });
    }
    switch (category) {
        case Categorization.HIGHEST:
            return _("controls.spotpriceoptimizer.highest")
        case Categorization.V_HIGH:
            return _("controls.spotpriceoptimizer.vhigh");
        case Categorization.HIGH:
            return _("controls.spotpriceoptimizer.high");
        case Categorization.LOW:
            return _("controls.spotpriceoptimizer.low");
        case Categorization.V_LOW:
            return _("controls.spotpriceoptimizer.vlow");
        case Categorization.LOWEST:
            return _("controls.spotpriceoptimizer.lowest");
        default:
            return "--";
    }
}
