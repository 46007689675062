'use strict';

define("CarChargerControl", ["Control", "CarChargerControlEnums", "AutopilotEnums"], function (Control, CarChargerControlEnums, AutopilotEnums) {
    return class CarChargerControl extends Control {
        constructor() {
            super(...arguments);
        }

        getReactControlContentFlags() {
            return {
                showMoreSection: false
            }
        }

        getLegacyScreens() {
            return [CarChargerControlEnums.ScreenState.SETTINGS]
        }

        getIcon() {
            return Icon.CarCharger.CAR_CHARGER;
        }

        getButton0(states) {
            var btn = false;

            if (states.carConnected && false) {
                btn = {
                    iconSrc: states.isCharging ? Icon.CarCharger.BTN_STOP : Icon.CarCharger.BTN_START,
                    reactIcon: false,
                    command: states.isCharging ? Commands.CAR_CHARGER.STOP_CHARGING : Commands.CAR_CHARGER.START_CHARGING
                };
            }

            return btn;
        }

        specificCreateCmdText(cmdParts) {
            var cfg = {
                    name: this.getName()
                },
                text;

            switch (cmdParts[1]) {
                case "charge":
                    if (cmdParts[2] === "on") {
                        text = _("cmdtext.carcharger.start", cfg);
                    } else if (cmdParts[2] === "off") {
                        text = _("cmdtext.carcharger.stop", cfg);
                    }

                    break;

                case "limitmode":
                    text = this._getLimitModeText(cmdParts, cfg);
                    break;

                case "profile":
                    cfg.profile = cmdParts[2];
                    text = _("cmdtext.carcharger.profile", cfg);
                    break;

                case "limit":
                    cfg.value = parseFloat(cmdParts[2]);
                    cfg.value = lxFormat("%.3f kW", cfg.value);
                    text = _("cmdtext.carcharger.limit.set", cfg);
                    break;

                default:
                    text = _("cmdtext.alarmclock.edit", cfg);
                    break;
            }

            return text;
        }

        getFriendlyValueName(event, operator) {
            var result = this.getName() + ": ";

            switch (event.data.stateName) {
                case "connected":
                    if (event.value) {
                        result += _("controls.car-charger.car-connected");
                    } else {
                        result += _("controls.car-charger.car-disconnected");
                    }

                    break;

                case "charging":
                    if (event.value) {
                        result += _("controls.car-charger.loading");
                    } else {
                        result += _("controls.car-charger.loading-paused");
                    }

                    break;

                case "power":
                    result += _("controls.car-charger.current-power") + " ";
                    result += AutopilotEnums.OperatorsTitle[event.operator] + " ";
                    result += lxUnitConverter.convertAndApply("%.2f kW", event.value);
                    break;

                case "chargeDuration":
                    result += _("controls.car-charger.charging-time") + " ";
                    result += AutopilotEnums.OperatorsTitle[event.operator] + " ";
                    result += lxFormat("<v.pdu>", event.value);
                    break;

                default:
                    return "";
            }

            return result;
        }

        _getLimitModeText(cmdParts, cfg) {
            var text = "";
            var mode = parseInt(cmdParts[2]);

            if (mode === CarChargerControlEnums.LimitMode.OFF) {
                text = _("cmdtext.carcharger.limit.no", cfg);
            } else if (mode === CarChargerControlEnums.LimitMode.MANUAL) {
                text = _("cmdtext.carcharger.limit.manual", cfg);
            } else if (mode === CarChargerControlEnums.LimitMode.AUTO) {
                text = _("cmdtext.carcharger.limit.auto", cfg);
            }

            return text;
        }

    };
});
