'use strict';
/**
 * a View which simply displays an Icon, Title and Message (eg. for empty TableViews)
 *
 * iconSrc
 * title
 * message
 * buttonText
 * buttonAction
 * iconFillColor    the color which is to be used to fill the icon
 */

window.GUI = function (GUI) {
    class BackgroundView extends GUI.View {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(iconSrc, title, message, buttonText) {
                return $('<div class="background-view">' + '   <div class="background-view__icon-placeholder">' + '       <div class="icon-placeholder__circle">' + '       </div>' + '   </div>' + '   <div class="background-view__texts">' + '       <div class="texts__title"></div>' + '       <div class="texts__message"></div>' + '       <div class="texts__button"></div>' + '   </div>' + '</div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(iconSrc, title, message, buttonText, buttonAction, noCircle, elementIsNotClickable, iconFillColor) {
            super(BackgroundView.Template.getTemplate(iconSrc, title, message, buttonText));
            this.config = {
                iconSrc: iconSrc,
                title: title,
                message: message,
                buttonText: buttonText,
                buttonAction: buttonAction,
                iconFillColor: iconFillColor,
                noCircle: noCircle,
                elementIsNotClickable: elementIsNotClickable
            };
            Debug.GUI.BackgroundView && console.log(this.viewId, "+CTOR", getStackObj());
        }

        viewDidLoad() {
            Debug.GUI.BackgroundView && console.log(this.viewId, "viewDidLoad", getStackObj());
            return super.viewDidLoad(...arguments).then(function (res) {
                this.elements = this.elements || {};
                this.elements.circleContainer = $(this.element.find(".icon-placeholder__circle"));
                this.elements.title = $(this.element.find(".texts__title"));
                this.elements.message = $(this.element.find(".texts__message"));
                this.elements.button = this.element.find(".texts__button");
                this.textButton = new GUI.LxButton(this, this.elements.button, Color.BUTTON_GLOW, null, true);
                this.textButton.useElementAsActivePart();
                this.addToHandledSubviews(this.textButton);
                this.wholeElementButton = new GUI.LxButton(this, this.element, Color.BUTTON_GLOW, null, true);
                this.wholeElementButton.useElementAsActivePart();
                this.addToHandledSubviews(this.wholeElementButton);
                this.update(this.config);
                Debug.GUI.BackgroundView && console.log(this.viewId, "viewDidLoad >> passed");
                return Q.resolve(res);
            }.bind(this));
        }

        setTranslucent() {
            Debug.GUI.BackgroundView && console.log(this.viewId, "setTranslucent");
            GUI.animationHandler.addCssClass(this.element, "background-view--translucent");
        }

        update(config) {
            if (!this.element) {
                Debug.GUI.BackgroundView && console.warn(this.viewId, "update - no element!", config);
                return;
            }

            Debug.GUI.BackgroundView && console.log(this.viewId, "update", config, getStackObj());
            this.config = config;
            var circleContainerHtml = this.config.iconSrc ? ImageBox.getResourceImageWithClasses(this.config.iconSrc, "circle__icon") : "";
            this.textButton.setEnabled(!!this.config.buttonText);
            this.wholeElementButton.setEnabled(!this.config.elementIsNotClickable);

            if (this.config.buttonAction) {
                this.wholeElementButton.onButtonReleased = this.config.buttonAction.bind(this);
                this.textButton.onButtonReleased = this.config.buttonAction.bind(this);
            }

            GUI.animationHandler.schedule(function () {
                this.elements.circleContainer.html(circleContainerHtml);
                this.element.toggleClass("background-view--no-icon", !!this.config.iconSrc);

                if (this.config.iconSrc) {
                    this.elements.circleContainer.find(".circle__icon").attr("style", this.config.iconFillColor ? "fill: " + this.config.iconFillColor : "");
                }if (this.config.buttonText) {
                    this.elements.button.text(this.config.buttonText);
                } else {
                    this.elements.button.text("");
                }

                this.elements.title.text(this.config.title);
                this.elements.message.html(this.config.message); // .html as svgs may be inline within the text (nfc access codes)

                this.elements.circleContainer.toggleClass("icon-placeholder__circle--no-circle", !!this.config.noCircle);
            }.bind(this));
        }

    }

    GUI.BackgroundView = BackgroundView;
    return GUI;
}(window.GUI || {});
