import {colors} from "./colors";

export const shadows = {
    thumbShadow: {
        shadowRadius: 4,
        shadowColor: colors.black,
        shadowOpacity: 0.5,
        shadowOffset: {
            width: 0,
            height: 2
        }
    }
}
