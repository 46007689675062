'use strict';

define("AutomaticDesignerScenesScreen", ["AutomaticDesignerFilterView"], function (AutomaticDesignerFilterView) {
    return class AutomaticDesignerScenesScreen extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            this.details = details || {};
            this.sceneUuid = this.details.sceneUuid; // optional used to directly open a scene via URL-Scheme

            this.filterControls = this.details.filterControls || [];
        }

        viewDidLoad() {
            var args = arguments;
            this.filterView = new AutomaticDesignerFilterView(this.filterControls);
            this.unregisterFilterResetFn = this.filterView.on(AutomaticDesignerFilterView.EVENTS.RESET, this._resetFilter.bind(this));
            this.appendSubview(this.filterView);
            return this._loadScenesFromComp().then(rules => {
                this.unregisteRuleListenerFn = AutomaticDesignerComponent.registerForRuleChangeNotify(function () {
                    this.updateView(this.details);
                }.bind(this));
                return Q(super.viewDidLoad(...args) || true).then(function () {
                    this.updateView(this.details);
                }.bind(this));
            });
        }

        viewWillAppear() {
            return Q(super.viewWillAppear(...arguments) || true).then(function () {
                return this._loadScenesFromComp();
            }.bind(this));
        }

        updateView(details) {
            this.details = details || {};

            this._loadScenesFromComp().then(function (scenes) {
                this.scenes = scenes;
                this.updateEmptyViewConfig();
                this.reloadTable();

                if (this.details.sceneUuid) {
                    var wantedScene = scenes.find(function (scene) {
                        return scene.id === this.details.sceneUuid;
                    }.bind(this));
                    wantedScene && this._openScene(wantedScene);
                    delete this.details.sceneUuid; // Delete it, we just want to do it once
                } else if (this.details.taskRecorderScene) {
                    this._openScene(cloneObject(this.details.taskRecorderScene));

                    delete this.details.taskRecorderScene;
                }
            }.bind(this));
        }

        // Just return null, we use the OnboardingScreen as an EmptyView
        emptyViewConfig() {
            return null;
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        destroy() {
            this.unregisteRuleListenerFn();
            super.destroy(...arguments);
        }

        getPermissions() {
            return [{
                permission: MsPermission.AUTOPILOT
            }];
        }

        getURL() {
            return UrlStartLocation.AUTOMATIC_DESIGNER_SCENES;
        }

        titleBarText() {
            return _("scenes.name_plural");
        }

        titleBarConfig() {
            return {
                rightSide: TitleBarCfg.CUSTOM_ICON,
                rightIconSrc: Icon.NEW
            };
        }

        titleBarActionRight() {
            NavigationComp.showState(ScreenState.AutomaticDesigner.RuleViewController, {
                extension: AutomaticDesignerEnums.RULE_EXTENSION.SCENE
            });
        }

        updateEmptyViewConfig() {
            if (this.emptyView) {
                this.removeSubview(this.emptyView);
            }

            this.emptyView = this._createEmptyView();
            this.appendSubview(this.emptyView);
        }

        reloadTable() {
            this.tableContent = [];

            if (this.scenes && this.scenes.length) {
                this.tableContent.push({
                    headerTitle: '',
                    headerDescription: _('automatic-designer.scenes.title'),
                    rows: this.scenes
                        .map(
                            function (scene, idx) {
                                return this._createCellForScene(scene);
                            }.bind(this),
                        )
                        .sort(function (a, b) {
                            return a.content.title.localeCompare(
                                b.content.title,
                            );
                        }),
                });
            }

            return super.reloadTable(...arguments);
        }

        _loadScenesFromComp() {
            return AutomaticDesignerComponent.getScenes(this.details.filterControls).then(function (scenes) {
                this.scenes = scenes;
                return scenes;
            }.bind(this));
        }

        _openScene(scene) {
            NavigationComp.showState(ScreenState.AutomaticDesigner.RuleViewController, {
                rule: scene,
                extension: AutomaticDesignerEnums.RULE_EXTENSION.SCENE
            });
        }

        _createCellForScene(scene) {
            var row = {
                type: GUI.TableViewV2.CellType.BUTTON,
                content: {
                    title: scene.name,
                    clickable: true,
                    iconSrc: Icon.AutomaticDesigner.SCENE,
                    leftIconSrc: Icon.AutomaticDesigner.SCENE,
                    iconColor: window.Styles.colors.green,
                    leftIconColor: window.Styles.colors.green,
                    buttonSrc: Icon.Pushbutton.PUSHBUTTON2
                },
                action: this._openScene.bind(this, scene),
                buttonTapped: function (value) {
                    AutomaticDesignerComponent.executeRule(scene);
                }.bind(this)
            };

            if (scene.invalid) {
                delete row.type; // Use a generalCell instead

                row.content.rightIconSrc = Icon.WARNING;
                row.content.rightIconColor = window.Styles.colors.orange;
                row.content.subtitle = _("error");
            } else if (scene.locked) {
                delete row.type; // Use a generalCell instead
            }

            return row;
        }

        _createEmptyView() {
            if (this.details.filterControls && this.details.filterControls.length === 0) {
                this.noFilterResultView = new GUI.BackgroundView(null, null, _("automatic-designer.filter.no-results"), null, null, true, true);
                this.noFilterResultView.getElement().addClass("table-view-screen__content");
                this.noFilterResultView.getElement().addClass("background-view--no-filter-result");
                this.noFilterResultView.setTranslucent(true);
                return this.noFilterResultView;
            } else {
                var onboardingContinueDef = Q.defer(),
                    onboardingScreen = new GUI.OnboardingScreen({
                        id: GUI.OnboardingScreen.IDS.AUTOMATIC_DESIGNER,
                        iconSrc: Icon.AutomaticDesigner.SCENE,
                        title: _("scenes.name_plural"),
                        message: _("scenes.onboarding.msg1"),
                        message2: _("scenes.onboarding.msg2"),
                        iconColor: window.Styles.colors.green,
                        continueBtnText: _("scenes.create"),
                        asView: true,
                        continueDef: onboardingContinueDef
                    });
                onboardingContinueDef.promise.progress(this.titleBarActionRight.bind(this));
                return onboardingScreen;
            }
        }

        _resetFilter() {
            delete this.details.filterControls;
            this.filterControls = [];
            this.updateView(this.details);
            this.filterView.updateView(this.filterControls);
        }

    };
});
