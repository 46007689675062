'use strict';

define([], function () {
    /**
     * Helper delegate to handle multiple types of the same eventType in one screen using the MediaStateHandler mixin
     * @type {FunctionConstructor}
     */
    return class OverviewContentLoader {
        constructor(control, item, max, mediaInfo) {
            applyMixins(this, MediaStateHandlerV2.Mixin);
            this.title = item.name;
            this.control = control;
            this.item = item;
            this.max = max;
            this.mediaInfo = mediaInfo;
            this.name = "OverviewContentLoader-" + this._getCacheKey();
            this._cachedData = null;
            this._downloadedData = null;
            this._downloadError = null;
        }

        gotFromCache() {
            return !!this._cachedData;
        }

        wasCacheUpdated() {
            return this.gotFromCache() && JSON.stringify(this._downloadedData) !== JSON.stringify(this._cachedData);
        }

        hasDownloadedData() {
            return !!this._downloadedData;
        }

        /**
         * Returns true if the content has either been downloaded or the download has failed.
         * @returns {*|boolean}
         */
        finished() {
            return this.hasDownloadedData() || !!this._downloadError;
        }

        fetch() {
            Debug.Control.AudioZone.OverviewContentLoader && console.log(this.name, "fetch: " + JSON.stringify(this.item) + " - " + JSON.stringify(this.mediaInfo), getStackObj());

            if (!this._fetchDeferred) {
                this._fetchDeferred = Q.defer();
                this._cachedData = this._retrieveFromCache();

                if (this._cachedData) {
                    Debug.Control.AudioZone.OverviewContentLoader && console.log(this.name, "fetch -> retrieved from cache", cloneObject(this._cachedData));
                    setTimeout(function () {
                        this._fetchDeferred.notify(this._sanitizeResult(this._cachedData));
                    }.bind(this), 5);
                }

                this._getFromLoader().then(function (res) {
                    Debug.Control.AudioZone.OverviewContentLoader && console.log(this.name, "fetch -> download finished", cloneObject(res));

                    this._fetchDeferred.resolve(res);
                }.bind(this), function (error) {
                    Debug.Control.AudioZone.OverviewContentLoader && console.error(this.name, "fetch -> failed to download!", error);
                    this._downloadError = error;

                    this._fetchDeferred.reject([]);
                }.bind(this));
            }

            return this._fetchDeferred.promise;
        }

        // --------------------------------------------------------------------------------
        //         Content loading
        // --------------------------------------------------------------------------------
        _getFromLoader() {
            Debug.Control.AudioZone.OverviewContentLoader && console.log(this.name, "_getFromLoader");
            return this.control.audioserverComp.connectionReadyDef.promise.then(function () {
                Debug.Control.AudioZone.OverviewContentLoader && console.log(this.name, "_getFromLoader >> connection ready");
                return this.control.audioserverComp.requestContentBatch(this.item.contentType, this.item.id, this.max, this.mediaInfo, true).promise.then(function (res) {
                    Debug.Control.AudioZone.OverviewContentLoader && console.log(this.name, "_getFromLoader >> result received!", res);
                    this._downloadedData = res;

                    this._persistCache(this._downloadedData); // store raw data, contains name info for title!


                    return this._sanitizeResult(this._downloadedData);
                }.bind(this));
            }.bind(this));
        }

        _sanitizeResult(res) {
            if (Array.isArray(res)) {
                return res;
            } else {
                this._retrieveTitle(res);

                res.items.forEach(function (item) {
                    item.contentType = this.item.contentType;
                }.bind(this));
                return res.items;
            }
        }

        _retrieveTitle(res) {
            if (res.id === MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS) {
                res.name = _("media.playlists");
            }

            this.title = res.name;
        }

        // --------------------------------------------------------------------------------
        //         Caching
        // --------------------------------------------------------------------------------
        _persistCache(res) {
            var cacheKey = this._getCacheKey();

            Debug.Control.AudioZone.OverviewContentLoader && console.log(this.name, "_persistCache: " + cacheKey, res);
            PersistenceComponent.saveForMiniserver(cacheKey, res);
        }

        _retrieveFromCache() {
            var cacheKey = this._getCacheKey();

            Debug.Control.AudioZone.OverviewContentLoader && console.log(this.name, "_retrieveFromCache " + cacheKey);
            return PersistenceComponent.getForMiniserver(cacheKey);
        }

        _getCacheKey() {
            var cacheKeySet = ["AudioContentCache"],
                cacheKey;
            cacheKeySet.push(this.item.contentType);

            if (this.mediaInfo.service) {
                cacheKeySet.push(this.mediaInfo.service.uid);
            }

            cacheKeySet.push(this.item.id);
            cacheKeySet.push(this.max);
            cacheKey = cacheKeySet.join("_");
            cacheKey = encodeURIComponent(cacheKey);
            return cacheKey;
        }

    };
});
