import {useContext, useMemo} from "react";
import {LxReactTableView, ControlContext} from "LxComponents";
import {View} from "react-native";
import {useNavigation} from "@react-navigation/native";
import globalStyles from "GlobalStyles";
import LxControlHistoryScreen from "./LxControlHistoryScreen";

function LxControlHistorySection (props) {
    const {control} = useContext(ControlContext);
    const navigation = useNavigation();

    const showHistory = () => {
        navigation.navigate(LxControlHistoryScreen.name);
    }

    const historyContent = useMemo(() => {
        let section = {
            rows: []
        };

        if (control?.hasHistory) {
            section.rows.push({
                content: {
                    title: _('history'),
                    disclosureIcon: true,
                },
                action: showHistory.bind(this)
            })
        }

        return section.rows.length ? [section] : null;
    }, [control, control?.hasHistory])

    return <>
        {
            historyContent && <View style={styles.container}>
                <LxReactTableView styles={styles.tableView} tableContent={historyContent} />
            </View>
        }
    </>

}

const styles = {
    container: {
        width: "100%",
        marginBottom: globalStyles.spacings.gaps.bigMedium
    },
    tableView: {
        List: {
            paddingHorizontal: 0
        }
    }
}

export default LxControlHistorySection
