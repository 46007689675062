'use strict';

window.Controls = function (Controls) {
    Controls.AudioZoneV2Control = Controls.AudioZoneV2Control || {};
    return Controls;
}(window.Controls || {});

define([], function () {
    const allStates = {
        DETAILS: 'Controls.AudioZoneV2Control.AudioZoneV2ControlDetails',
        DETAILS_HD: 'Controls.AudioZoneV2Control.AudioZoneV2ControlDetailsHD',
        QUEUE_SCREEN: 'Controls.AudioZoneV2Control.QueueScreenV2',
        PLAYER: 'Controls.AudioZoneV2Control.PlayerV2',
        ECO_PLAYER_VIEW: 'Controls.AudioZoneV2Control.EcoPlayerView',
        GROUP_OVERVIEW: 'Controls.AudioZoneV2Control.ZoneGroupOverviewV2',
        SETTINGS: {
            SCREEN: "MusicServerSettings",
            ABOUT: "AboutAudioServer"
        },
        VOICE_RECORDER: 'RecordingSelection',
        RECORDING_SCREEN: 'RecordingScreen',
        CUSTOM_STREAM_SCREEN: 'CustomStreamScreen',
        MEDIA_SEARCH_SCREEN: 'MediaGlobalSearchV2',
        MEDIA_SCREEN: 'MediaSearchScreen',
        LEGACY_SEARCH_SCREEN: 'Controls.AudioZoneV2Control.LegacySearchScreen',
        MEDIA_BROWSER_BASE_SCREEN: 'Controls.AudioZoneV2Control.MediaBrowserV2Base',
        FAVORITE_EDIT: 'FavoriteEditScreen',
        EDIT_SPECIFIC_FAV: 'EditSpecificFavScreen',
        SELECTOR_WRAPPER: 'SelectorScreenWrapper',
        AIRPLAY: 'AirPlaySettings',
        SETTINGS_SERVICES_SPOTIFY_MANAGEMENT: 'SpotifyManagement',
        SETTINGS_SERVICES_SOUNDSUIT_MANAGEMENT: 'SoundsuitManagement',
        SETTINGS_SERVICES_SOUNDSUIT_ACCOUNT: 'Controls.AudioZoneV2Control.SoundsuitAccountScreen',
        SETTINGS_SERVICES_RADIO: 'Controls.AudioZoneV2Control.ServiceRadioSettings',
        SETTINGS_SERVICE_LOGIN: 'Controls.AudioZoneV2Control.ServiceLoginScreen',
        SETTINGS_INPUT: 'SettingsLineIn',
        SETTINGS_INPUT_LIST: 'AudioInputList',
        SETTINGS_SHORTCUT_EDIT: 'ShortcutEditScreen',
        SETTINGS_ZONES_LIST: 'Controls.AudioZoneV2Control.MusicServerSettingsZoneList',
        SETTINGS_LIBRARY: 'SettingsLibrary',
        SETTINGS_LIBRARY_ADD_OR_EDIT: 'SettingsLibraryAddOrEdit',
        SETTINGS_LIBRARY_OVERVIEW: 'SettingsLibraryOverview',
        SETTINGS_EQ: 'EQSettings',
        SETTINGS_BLUETOOTH: 'BluetoothSettings',
        SETTINGS_VOLUME: 'VolumeSettings',
        CONTEXT_MENU_BASE: "Controls.AudioZoneV2Control.MediaContextMenuV2Base",
        NEW_SPOTIFY_ACCOUNT_SCREEN: "NewSpotifyAccountScreen",
        ADD_MEDIA_VC_BASE: "AddMediaViewControllerV2Base",
        ADD_MEDIA_VC_PLAYLIST: "AddMediaViewControllerV2Playlist",
        ADD_MEDIA_VC_PLAYLIST_PICKER: "AddMediaViewControllerV2PlaylistPicker",
        ADD_MEDIA_VC_PLAYLIST_SPOTIFY: "AddMediaViewControllerV2PlaylistSpotify",
        ADD_MEDIA_VC_FAVS: "AddMediaViewControllerV2ZoneFavorite",
        ADD_MEDIA_VC_SPOTIFY_ONBOARDING: "AddMediaViewControllerV2SpotifyOnboarding",
        ADD_MEDIA: "AddMediaV2Screen",
        SPOTIFY_ONBOARDING: "SpotifyOnboardingScreen",
        SPOTIFY_LIBRARY: "SpotifyLibraryScreenV2"
    }

    /**
     * Filter out all screen states that have to be required for the LxReactScreenAdapter.
     * @returns {{}}
     */
    const filterScreenAdapterStates = () => {
        let filtered = {};
        Object.keys(allStates).forEach((stateKey) => {
            switch (allStates[stateKey]) {
                case allStates.SELECTOR_WRAPPER:
                case allStates.SETTINGS_SHORTCUT_EDIT:
                case allStates.CONTEXT_MENU_BASE:
                case allStates.ADD_MEDIA_VC_BASE:
                case allStates.ADD_MEDIA_VC_PLAYLIST:
                case allStates.ADD_MEDIA_VC_PLAYLIST_PICKER:
                case allStates.ADD_MEDIA_VC_PLAYLIST_SPOTIFY: // <- neccessary for sure.
                case allStates.ADD_MEDIA_VC_SPOTIFY_ONBOARDING:
                case allStates.ADD_MEDIA_VC_FAVS:
                case allStates.NEW_SPOTIFY_ACCOUNT_SCREEN:
                case allStates.SPOTIFY_ONBOARDING:
                case allStates.EDIT_MEDIA:
                case allStates.SETTINGS.SCREEN:
                case allStates.SETTINGS_SERVICE_LOGIN:
                case allStates.SETTINGS_SERVICES_SOUNDSUIT_ACCOUNT:
                case allStates.SETTINGS_SERVICES_SOUNDSUIT_MANAGEMENT:
                case allStates.SETTINGS_LIBRARY_ADD_OR_EDIT:
                case allStates.GROUP_OVERVIEW:
                case allStates.VOICE_RECORDER:
                case allStates.EDIT_SPECIFIC_FAV:
                case allStates.SETTINGS.ABOUT:
                case allStates.CUSTOM_STREAM_SCREEN:
                    filtered[stateKey] = allStates[stateKey];
                    break;
            }
        });
        return filtered;
    }

    Controls.AudioZoneV2Control.Enums = {
        ScreenState: allStates,
        AllowedScreens: filterScreenAdapterStates(allStates)
    };
    return Controls.AudioZoneV2Control.Enums;
});
