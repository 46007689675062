import React from "react";
import {
    LxReactScreenAdapter,
    useDeviceOrientation
} from "LxComponents";

const getViewControllerDummy = (props, showStateHook, expendedFunctions = {}, prototypeTemplate = {}) => {
    return new Proxy({
        get alert() {
            return !!props.route.params.isAlert;
        },
        get fromNotification() {
            return !!props.route.params.fromNotification;
        },
        getShouldBlockScreenSaver: () => {
            debugger;
            return this.alert;
        },
        viewId: moment().unix(),
        canNavigateBack:  () => {
            return props.navigation.canGoBack();
        },
        reactNavigationProps: props,
        navigateBack: (skipSubView, detailsForPrevView) => {
            if(props.navigation.canGoBack()) {
                if (detailsForPrevView) {
                    let previousRoute = props.navigation.getState().routes.slice(-2, -1)[0];
                    if (previousRoute) {
                        props.navigation.navigate(previousRoute.name, detailsForPrevView);
                    } else {
                        props.navigation.goBack();
                    }
                } else {
                    props.navigation.goBack();
                }

            }
            return Q.resolve();
        },
        navigateBackFromViewCtrl: (viewCtrl, skipSubView, detailsForPrevView) => {
            if(props.navigation.canGoBack()) {
                if (detailsForPrevView) {
                    let previousRoute = props.navigation.getState().routes.slice(-2, -1)[0];
                    if (previousRoute) {
                        props.navigation.navigate(previousRoute, detailsForPrevView);
                    } else {
                        props.navigation.goBack();
                    }
                } else {
                    props.navigation.goBack();
                }

            }
            return Q.resolve();
        },
        navigateBackToRoot: (offset) => {
            if (props.navigation.canGoBack()) {
                props.navigation.popToTop();
            }
            return Q.resolve();
        },
        dismissScreen: (source) => {
            if (source.name === "SearchScreen") {
                props.navigation.popToTop();
            } else if (props.navigation.getId() === "AmbientNavigator") {
                let ambientNavState = props.navigation.getState();
                if (!ambientNavState || ambientNavState && ambientNavState.routes.length === 1) {
                    props.navigation.goBack()
                } else { // If Ambient Mode is open, remove all routes from the nested stack and the go back to close the ambient mode
                    props.navigation.popToTop();
                    props.navigation.goBack();
                }
            } else {
                props.navigation.goBack();
            }
        },
        dismiss: () => {
            props.navigation.goBack();
        },
        dismissAlert: () => {
            props.navigation.goBack();
        },
        showState: (identifier, view, details, animationType) => {
            details = details || {};
            details.animationType = animationType;
            let safeIdentifier = identifier;
            switch (safeIdentifier) {
                case ScreenState.AmbientControlContent:
                    safeIdentifier = ScreenState.ControlContent;
                    break;
                case ScreenState.AmbientAudioZoneV2ControlContent:
                    safeIdentifier = GUI.AudioZoneV2ControlContentViewControllerReactAbstractor.name;
                    break;
                case ScreenState.AmbientAudioZoneControlContent:
                    safeIdentifier = GUI.AudioZoneControlContentViewControllerReactAbstractor.name;
                    break;
                default:
                    break;
            }
            let action = "navigate",
                screenCtor = LxReactScreenAdapter.ScreenUtils.getScreenCtor(safeIdentifier),
                isAlert = screenCtor === GUI.ControlContentAlertViewController;

            if (screenCtor && screenCtor.allowMultiple && !isAlert) {
                action = "push";
            }

            if (details.showInAmbientMode && !details.push && !isAlert) {
                // navigating withing the ambient mode requires a special handling.
                action = "navigate";
                let navObj = {
                    screen: "GUI.ActiveMiniserverViewController",
                    params: {
                        screen: "AmbientScreen",
                        params: {
                            screen: "AmbientStackNavigator",
                            params: {
                                screen: identifier,
                                params: details
                            }
                        }

                    }
                }
                identifier = navObj.screen;
                details = navObj.params;
            } else if (details.push) {
                action = "push"
            }

            props.navigation[action](identifier, details); // DO NOT CHANGE THIS TO PUSH
            return Q.resolve();
        },
        exchangeInProgress: false,
        processQueue: () => {
            return Q.resolve();
        },
        getCurrentView: () => {
            return;
        },
        getQueueFinishedPromise: () => {
            return Q.resolve();
        },
        enableAnimations: () => {},
        addMultipleView: () => {

        },
        getURL: () => {
            //return "..."
        },
        setOrientation: (orientation) => {
            return useDeviceOrientation().set(orientation);
        },
        viewDidLoad: () => Q.resolve(),
        viewWillAppear: () => Q.resolve(),
        viewDidAppear: () => Q.resolve(),
        viewWillDisappear: () => Q.resolve(),
        viewDidDisappear: () => Q.resolve(),
        destroy: () => Q.resolve(),
        ...expendedFunctions
    }, {
        get: (target, prop, val) => {
            if (prop in target) {
                return target[prop];
            } else if (prop in prototypeTemplate) {
                return prototypeTemplate[prop];
            }
            developerAttention(`"${prop}" is not defined in ViewControllerDummy, define if necessary!`);
        }
    })
}
export {
    getViewControllerDummy,
};
