import { EfmUtilities, EfmViewType } from "../../efmUtilities";
import sanitizeValue from "./sanitizeValue";

const getSpecificValue = (header, values = [], takePositiveValue = true) => {
    if(values.length === 1) {
        if(takePositiveValue) {
            return Math.max(values[0], 0)
        } else {
            return Math.min(values[0], 0)
        }
    } else {
        if(takePositiveValue) {
            const posIdx = header.findIndex(h => !(/[N|n]eg/g.test(h.output)))
            return values[posIdx];
        } else {
            const negIdx = header.findIndex(h => /[N|n]eg/g.test(h.output))
            return -values[negIdx];
        }
    }
}

export const getLatestSpecificValue = (stats, viewType, withNeg = false) => {
    let total;
    if(viewType === EfmViewType.Actual) {
        if(!stats || !stats.data || stats.data.length === 0) {return total;}
        const dp = stats.data[stats.data.length - 1];
        total = {
            total: getSpecificValue(stats.header, dp.values, true),
        };
        if(withNeg) {
            total.totalNeg = getSpecificValue(stats.header, dp.values, false);
        }
    } else {
        try { total = EfmUtilities.reduceStatistics(stats, viewType) } catch (e) { }
    }
    return total;
}

export const getTotalWhileIgnoringTinyNumbers = (statsObj, neg) => {
    const key = neg ? 'totalNeg' : 'total'
    // If no numeric value is set at all, return undefined so the component knows to render nothing or "---"
    if(!statsObj || typeof statsObj[key] !== 'number') {
        return;
    }
    // If number has been pushed into infinity or would cause formatting issues because it
    // is too close to zero, just return 0
    const number_is_tiny = statsObj[key] < 1e-6 && statsObj[key] > -1e-6;
    if(!isFinite(statsObj[key]) || number_is_tiny) {
        return 0;
    }
    // At this point, statsObj[key] is sure to be a finite number far enough from zero to be formatted
    return statsObj[key];
}

export default getSpecificValue
