import { Graph, CircleGraph, useControl } from "LxComponents"
import PropTypes from "prop-types";
import { EfmUtilities, EfmViewType } from "../../efmUtilities";
import { useMemo } from "react";
import { View } from "react-native";

import useExtendedStats from "../hooks/useExtendedStats.jsx";

import {forceDataIntoMode} from "../util/averageValues.js";
import getSpecificValue from "../util/getSpecificValue.js";
import generateLabels from "../util/generateLabels.js";
import globalStyles from "GlobalStyles";

import { getTotalWhileIgnoringTinyNumbers } from "../util/getSpecificValue.js";
import useLowestMode from "../hooks/useLowestMode";

const usePreparedStatsForProductionGraph = ({gridStats, storageStats, productionStats, viewType, timeRange}) => {
    const lowestMode = useLowestMode([gridStats, storageStats, productionStats]);

    const rawExtendedStorageNodeStatsData = useExtendedStats({ usedMode: lowestMode, stats: storageStats, viewType, startTs: timeRange.start, endTs: timeRange.end, newExtend: true });
    const rawExtendedGridNodeStatsData = useExtendedStats({ usedMode: lowestMode, stats: gridStats, viewType, startTs: timeRange.start, endTs: timeRange.end, newExtend: true });
    const rawExtendedProductionNodeStatsData = useExtendedStats({ usedMode: lowestMode, stats: productionStats, viewType, startTs: timeRange.start, endTs: timeRange.end, newExtend: true });

    const {
        extendedStorageNodeStatsData,
        extendedGridNodeStatsData,
        extendedProductionNodeStatsData,
    } = useMemo(() => {
        let extendedStorageNodeStatsData = rawExtendedStorageNodeStatsData;
        let extendedGridNodeStatsData = rawExtendedGridNodeStatsData;
        let extendedProductionNodeStatsData = rawExtendedProductionNodeStatsData;

        if(rawExtendedStorageNodeStatsData && viewType === EfmViewType.Actual) {
            extendedStorageNodeStatsData = forceDataIntoMode(rawExtendedStorageNodeStatsData, timeRange.start, timeRange.end, lowestMode);
        }
        if(rawExtendedGridNodeStatsData && viewType === EfmViewType.Actual) {
            extendedGridNodeStatsData = forceDataIntoMode(rawExtendedGridNodeStatsData, timeRange.start, timeRange.end, lowestMode);
        }
        if(rawExtendedProductionNodeStatsData && viewType === EfmViewType.Actual) {
            extendedProductionNodeStatsData = forceDataIntoMode(rawExtendedProductionNodeStatsData, timeRange.start, timeRange.end, lowestMode);
        }

        return {
            extendedStorageNodeStatsData,
            extendedGridNodeStatsData,
            extendedProductionNodeStatsData,
        }
    }, [rawExtendedStorageNodeStatsData, rawExtendedGridNodeStatsData, rawExtendedProductionNodeStatsData]);

    return {
        extendedStorageNodeStatsData,
        extendedGridNodeStatsData,
        extendedProductionNodeStatsData,
    }
}

export default function ProductionGraph({ availableNodeTypes, gridStats, storStats: storageStats, prodStats: productionStats, viewType, timeRange, loading = false, controlUuid, currentValue, fullStretch, onZoom, isLivePast }) {
    const {
        extendedStorageNodeStatsData,
        extendedGridNodeStatsData,
        extendedProductionNodeStatsData,
    } = usePreparedStatsForProductionGraph({gridStats, storageStats, productionStats, viewType, timeRange});

    const control = useControl(controlUuid);

    const { graphData, horizontalLabels, highlightDateFormat, bottomLabelsCentered, legend } = useMemo(() => {
        if (!Array.isArray(extendedProductionNodeStatsData)) {
            return [];
        }

        const data = [];

        const useGridData = (Array.isArray(extendedGridNodeStatsData) && extendedGridNodeStatsData.length === extendedProductionNodeStatsData.length);
        const useStorageData = (Array.isArray(extendedStorageNodeStatsData) && extendedStorageNodeStatsData.length === extendedProductionNodeStatsData.length);

        let storageLabel = false; let gridLabel = false;

        extendedProductionNodeStatsData.forEach((prodDatapoint, idx) => {
            const arr = [];

            const produced = Math.max(0, prodDatapoint.values[0]);

            let storageExport;
            if(useStorageData) {
                storageExport = -getSpecificValue(storageStats.header, extendedStorageNodeStatsData[idx].values, false);
                storageExport = Math.min(produced, storageExport);
            }

            let gridExport;
            if (useGridData) {
                gridExport = -getSpecificValue(gridStats.header, extendedGridNodeStatsData[idx].values, false);
                if(typeof gridExport !== 'number') {
                    gridExport = 0;
                }
                if(useStorageData) {
                    gridExport = Math.min(produced - storageExport, gridExport);
                } else {
                    gridExport = Math.min(produced, gridExport);
                }
            }

            let ownConsumption = produced;
            if (gridExport) {
                ownConsumption -= gridExport;
            }
            if (storageExport) {
                ownConsumption -= storageExport;
            }


            if(useGridData) {
                let yellowPoint = ownConsumption; // yellowPoint shows all we produced
                if(gridExport) {
                    yellowPoint += gridExport;
                }
                if(storageExport) {
                    yellowPoint += storageExport;
                }
                arr.push({
                    color: globalStyles.colors.yellow,
                    timestamp: prodDatapoint.ts,
                    value: yellowPoint,
                    displayValue: gridExport,
                });
                gridLabel = true;
            }

            let greenPoint = ownConsumption;
            if(storageExport) {
                greenPoint += storageExport;
            }
            arr.push({
                color: globalStyles.colors.green,
                timestamp: prodDatapoint.ts,
                value: greenPoint,
                displayValue: ownConsumption,
            });

            if(useStorageData) {
                const bluePoint = storageExport;
                arr.push({
                    color: globalStyles.colors.blue,
                    timestamp: prodDatapoint.ts,
                    value: bluePoint,
                });
                storageLabel = true;
            }

            data.push(arr);
        });

        const graphData = data;

        const { horizontalLabels, highlightDateFormat, bottomLabelsCentered } = generateLabels({ viewType, data: graphData });

        const legend = [{
            color: globalStyles.colors.green,
            label: _(gridStats ? "energymanager2.self-consumption" : "efm.self-production"),
        }];
        if(storageLabel) {
            legend.push({
                color: globalStyles.colors.blue,
                label: _("efm.storage-title"),
            });
        }
        if(gridLabel) {
            legend.push({
                color: globalStyles.colors.yellow,
                label: _("efm.grid-export"),
            });
        }

        return {
            graphData,
            horizontalLabels,
            highlightDateFormat,
            bottomLabelsCentered: bottomLabelsCentered,
            legend,
        }
    }, [storageStats, gridStats, productionStats]);

    const customPillTitle = (dataPoints, dateFormat, valueFormat) => {
        const total = dataPoints.reduce((sum, {displayValue, value}) => {
            let valToUse = 0;

            if (typeof displayValue === 'number') {
                valToUse = displayValue;
            } else if (typeof value === 'number') {
                valToUse = value;
            }
            return valToUse + sum;
        }, 0);
        return `${ActiveMSComponent.getMomentFromUtcTimestamp(dataPoints[0].timestamp).format(dateFormat)} (${_('x.total', {
            x: lxUnitConverter.convertAndApply(valueFormat, total, true)
        })})`;
    };

    /* #region  Circle Graph Calculation */
    const {
        circleGraphValues,
        titleRight,
    } = useMemo(() => {
        if (!productionStats || !productionStats.data || productionStats.data.length === 0) {
            return {circleGraphValues: [], titleRight: ''};
        }

        let prodTotal, storageTotal, gridTotal;
        try { prodTotal = EfmUtilities.reduceStatistics(productionStats, viewType) } catch { }
        if (storageStats) {
            try { storageTotal = EfmUtilities.reduceStatistics(storageStats, viewType) } catch { }
        }
        if (gridStats) {
            try { gridTotal = EfmUtilities.reduceStatistics(gridStats, viewType) } catch { }
            if(gridTotal && typeof gridTotal.totalNeg !== 'number') {
                gridTotal.totalNeg = 0;
            }
        }


        /**
         * Andi: If you're in the past in live view, productionStats.header[0].format might hold
         * the actual and not the total format, so we take the dayStats Header format, which must be total
         */
        let titleRightFormat = productionStats.header[0].format;
        if(isLivePast && productionStats.dayStats) {
            titleRightFormat = productionStats.dayStats.header[0].format;
        }

        if (!gridTotal && !storageTotal) {
            if( currentValue && (typeof currentValue.total === 'number' || typeof currentValue.actual === 'number')) {
                const formattedTotal = lxUnitConverter.convertSplitAndApply(titleRightFormat, getTotalWhileIgnoringTinyNumbers({total: currentValue.total || currentValue.actual}));
                return {circleGraphValues: [], titleRight: `${formattedTotal.valueTxt} ${formattedTotal.succTxt}`};
            }
            return {circleGraphValues: [], titleRight: ''};
        }

        const arr = [];
        if (!prodTotal) {
            // if statistic data acquisition fails, this would lead to a blackscreen.
            return {
                circleGraphValues: arr,
                titleRight: null
            };
        }

        const greenPart = prodTotal.total - (gridTotal ? gridTotal.totalNeg : 0) - (storageTotal ? storageTotal.totalNeg : 0);
        arr.push({
            color: globalStyles.colors.green,
            value: Math.max(0, greenPart),
            label: _("energymanager2.self-consumption"),
            format: control.totalFormat
        });

        if (storageTotal) {
            const bluePart = Math.min(prodTotal.total, storageTotal.totalNeg);
            arr.push({
                color: globalStyles.colors.blue,
                value: Math.max(0, bluePart),
                label: _("efm.storage-title"),
                format: control.totalFormat
            });
        }

        if (gridTotal) {
            const yellowPart = Math.min(Math.max(0, prodTotal.total - (storageTotal ? storageTotal.totalNeg : 0)), gridTotal.totalNeg);
            arr.push({
                color: globalStyles.colors.yellow,
                value: Math.max(0, yellowPart),
                label: _("efm.grid-export"),
                format: control.totalFormat
            });
        }

        let titleRight;
        if(productionStats && currentValue && (typeof currentValue.total === 'number' || typeof currentValue.actual === 'number')){
            const formattedTotal = lxUnitConverter.convertSplitAndApply(titleRightFormat, getTotalWhileIgnoringTinyNumbers({total: currentValue.total || currentValue.actual}));
            titleRight = `${formattedTotal.valueTxt} ${formattedTotal.succTxt}`;
        }

        return {
            circleGraphValues: arr,
            titleRight,
        };
    }, [isLivePast, storageStats, gridStats, productionStats, currentValue, viewType]);
    /* #endregion */

    return (
        <>
            <Graph
                titleLeft={!fullStretch ? _("efm.production-title") : null}
                fullStretch={fullStretch}
                titleRight={!fullStretch ? titleRight : null}
                type={viewType === EfmViewType.Actual ? 'line' : 'bar'}
                horizontalLabels={horizontalLabels}
                datapoints={graphData}
                format={control.actualFormat}
                label={viewType === EfmViewType.Actual ? control.actualUnit : control.totalUnit}
                highlightDateFormat={highlightDateFormat}
                bottomLabelsCentered={bottomLabelsCentered}
                fixedLowerLimit={0}
                forcedTimeRange={timeRange}
                legend={Array.isArray(legend) && legend.length > 0 ? legend : [{color: globalStyles.colors.grey['600'], label: '-'}]}
                onZoom={onZoom}
                customPillTitle={customPillTitle}
            />
            {
                !fullStretch && (availableNodeTypes.Storage || availableNodeTypes.Grid) ? (
                    <>
                        <CircleGraph values={circleGraphValues} />
                        <View style={{height: 11}} />
                    </>
                ) : null
            }
        </>
    )
}

ProductionGraph.propTypes = {
    gridStats: PropTypes.object,
    storStats: PropTypes.object,
    prodStats: PropTypes.object,
    viewType: PropTypes.string,
    timeRange: PropTypes.object,
}
