import * as React from "react"
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg"

function Placeholder(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <G clipPath="url(#clip0_1000_57021)" fill="#EBEBF5">
                <Path d="M3 0h3v2H3a1 1 0 00-1 1v3H0V3a3 3 0 013-3zM0 9h2v7H0V9zM0 19v2a3 3 0 003 3h3v-2H3a1 1 0 01-1-1v-2H0zM22 19v2a1 1 0 01-1 1h-3v2h3a3 3 0 003-3v-2h-2zM24 16V9h-2v7h2zM24 3v3h-2V3a1 1 0 00-1-1h-3V0h3a3 3 0 013 3zM9 2V0h6v2H9zM15 22H9v2h6v-2z" />
            </G>
            <Defs>
                <ClipPath id="clip0_1000_57021">
                    <Path fill="#fff" d="M0 0H24V24H0z" />
                </ClipPath>
            </Defs>
        </Svg>
    )
}

export default Placeholder
