import {colors} from "./colors";
import {sizes} from "./sizes";
import {fontSettings, textStyles} from "./text";
import {spacings} from "./spacings";

export const customStyles = {
    separator: {
        height: 1,
        width: "100%",
        backgroundColor: colors.borderColor.default
    },
    ambientSeparator: {
        height: 1,
        width: "100%",
        backgroundColor: colors.borderColor.ambient
    },
    screenContent: {
        maxWidth: sizes.contentMaxWidth,
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
    },
    cellElement: {
        minHeight: 54
    },
    cellTitle: {
        color: colors.text.primary,
        fontFamily: fontSettings.families.regular,
        fontSize: fontSettings.sizes.medium
    },
    cellSubTitle: {
        ...textStyles.footNote.default,
        fontSize: fontSettings.sizes.medium,
        color: colors.text.secondary
    },
    sectionHeader: {
        fontFamily: fontSettings.families.regular,
        fontSize: fontSettings.sizes.large,
        color: colors.text.primary,
        minHeight: 15,
        marginTop: sizes.mediumBig,
        marginLeft: spacings.gaps.small
    },
    emptyHeader: {
        minHeight: 48,
    },
    sectionFooter: {
        ...textStyles.footNote.default,
        color: colors.text.tertiary,
        minHeight: 15,
        marginTop: sizes.mediumBig,
        marginLeft: spacings.gaps.small
    },
    rightCellText: {
        ...textStyles.footNote.default,
        textAlign: "right",
        fontSize: fontSettings.sizes.medium,
        color: colors.text.secondary
    },
    selectableText: {
        ...textStyles.body.default,
        textAlign: "right",
        color: colors.green
    },
    titleBarIcon: {
        height: sizes.icons.big,
        width: sizes.icons.big,
        fill: colors.buttonDisabledBg
    },
    UserManagementHeaderTitle: {
        marginTop: 32,
        marginBottom: 12,
        color: colors.text.primary,
        ...textStyles.titleLarge
    },
    blurredBackground: {
        backgroundColor: "rgba(28,28,30,0.64)",
        backdropFilter: "blur(28px)",
    },
    newTitleBarIcon: {
        height: sizes.icons.medium,
        width: sizes.icons.medium,
    },
    reactTitleBarIcon: {
        height: sizes.icons.big,
        width: sizes.icons.big,
        fill: colors.text.secondary,
        backgroundColor: colors.buttonDisabledBg,
        padding: spacings.gaps.smaller,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%"
    },
    fullWidth: {
        width: "100%"
    },
    textCommandContainer: {
        backgroundColor: colors.grey["300a36"],
        borderRadius: 4,
        padding: spacings.gaps.small
    },
    textCommandText: {
        width: "100%",
        textAlign: "center",
    },
    ambientHeaderIconContainer: {
        backgroundColor: "rgba(28,28,30,0.64)",
        backdropFilter: "blur(28px)",
        padding: spacings.gaps.small,
        borderRadius: 4,
        marginHorizontal: spacings.gaps.smallest
    },
    ambientTextShadow: {
        textShadowRadius: 20,
        textShadowColor: "rgba(0, 0, 0, 0.40)"
    }
}
