'use strict';

define([], function () {
    return {
        DIR: {
            CLOSING: -1,
            NOT_MOVING: 0,
            OPENING: 1
        }
    };
});
