import Svg, { Path } from "react-native-svg"

function QrCode(props) {
    return (
        <Svg isreact="true"
            height={24}
            viewBox="0 0 24 24"
            width={24}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M10 15v8H1v-8zm7 3v5h-5v-1h4v-4zm3 0v5h-1v-5zm3 3v2h-1v-2zM9 16H2v6h7zm4 1v3h-1v-3zm10-5v7h-1v-6h-4v-1zm-3 3v1h-4v-1zm-4-3v1h-3v2h-1v-3zm-6 0v1H1v-1zm0-11v9H1V1zm13 0v9h-8V1zM13 5v5h-1V5zM9 2H2v7h7zm13 0h-6v7h6zm-9-1v2h-1V1z"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default QrCode
