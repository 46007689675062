'use strict';

define("DaytimerControlBase", ["Control"], function (Control) {
    return class DaytimerControlBase extends Control {
        constructor() {
            super(...arguments);
        }

        getSetEntriesCommand() {
            return Commands.DAYTIMER.SET_ENTRIES;
        }

        /**
         * Returns the string for the value of the entry that will be appended to the set entries command.
         * @param entry     the entry whos value is to be returned as a string for the setEntries-Cmd.
         * @return {null}   null only e.g. for a digital regular daytimer, as there is no value for it.
         */
        getEntryValueString(entry) {
            return null;
        }

        buildEntriesCommand(entries) {
            var command = this.getSetEntriesCommand(),
                entriesString = "",
                entriesCounter = 0,
                entry,
                valStr;

            for (var modeNr in entries) {
                if (entries.hasOwnProperty(modeNr)) {
                    for (var i = 0; i < entries[modeNr].length; i++) {
                        entriesCounter++;
                        entry = entries[modeNr][i];
                        valStr = this.getEntryValueString(entry);
                        entriesString += "/" + entry.mode + ";" + entry.from + ";" + entry.to + ";" + (entry.needActivate ? 1 : 0);

                        if (valStr !== null) {
                            // null means there is no value to be added (e.g. for digitals)
                            entriesString += ";" + valStr;
                        }
                    }
                }
            }

            return Commands.format(command, entriesCounter, entriesString);
        }

        getCurrentEntry() {
            var currentEntry = null,
                states = this.getStates();
            states && states.entries && states.entries[states.activeMode] && states.entries[states.activeMode].forEach(function (entry) {
                if (this._isEntryCurrent(entry)) {
                    currentEntry = entry;
                }
            }.bind(this));
            return currentEntry;
        }

        _isEntryCurrent(entry) {
            var minutesAbsolute = 24 * 60,
                msNow = SandboxComponent.getMiniserverTimeAsFakeUTC(),
                momentMidnight = msNow.clone().startOf('day'),
                startDate = momentMidnight.clone().add(entry.from, "minutes"),
                endDate = momentMidnight.clone().add(entry.to, "minutes"); // The maximum time, an entry must end is midnight, overrideEntries may be active beyond midnight
            // Because our timeline is just one day these entries must end on the same day, or we do have drawing errors

            entry.from = entry.from < 0 ? 0 : entry.from;
            entry.to = entry.to > minutesAbsolute ? minutesAbsolute : entry.to || msNow.diff(momentMidnight, "minutes"); // If this entry is currently active

            return msNow.isBetween(startDate, endDate);
        }

    };
});
