'use strict';

define(["JalousieControlEnums"], function (CtrlEnums) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var OUTPUT_INTERVAL = 200;
    var ACTIVATE_INTERVAL = 5000;
    var GUI_TIMER_UPDATE_INTERVAL = 100;
    return class JalousieEndPositionAdjustmentScreen extends GUI.TableViewScreen {
        //region Static
        static Template = function () {
            var getContent = function getContent() {
                return $('<div class="jalousie-end-position-adjustment-screen__content">' + '<div class="content__top">' + '<div class="top__timer">' + lxFormat("%.1fs", 0) + '</div>' + '<div class="top__description">' + _("controls.jalousie.end-pos.duration-press") + '</div>' + '</div>' + '</div>');
            };

            return {
                getContent: getContent
            };
        }(); //endregion Static

        constructor(details) {
            super($('<div>'));
            Object.assign(this, StateHandler.Mixin);
            this.control = details.control;
            this._guiTimer = null;
            this.bitmap = CtrlEnums.EndPosAdjustBit.OFF;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.setUpComfortUI(false);
                this.setTitle(_("controls.jalousie.end-pos.adjust-title"));
                JalousieEndPositionAdjustmentScreen.Template.getContent().insertBefore(this.tableView.getElement());
                this.elements = this.elements || {};
                this.elements.timerLabel = this.element.find(".top__timer");
            }.bind(this));
        }

        viewDidAppear() {
            Debug.Control.JalousieConfig && console.log(this.name, "viewDidAppear");
            return super.viewDidAppear(...arguments).then(function () {
                this._sendActiveCmd(true);

                this.bitmap = CtrlEnums.EndPosAdjustBit.OFF;

                this._sendCurrentBitmap();

                this._startKeepActive();

                this._registerForStates(this.control.uuidAction, this.getStateIDs());
            }.bind(this));
        }

        viewDidDisappear() {
            Debug.Control.JalousieConfig && console.log(this.name, "viewWillDisappear");

            this._unregisterStates();

            this._stopGuiTimer();

            this._stopKeepActive();

            this.bitmap = CtrlEnums.EndPosAdjustBit.OFF;

            if (this._isConnected()) {
                this._sendCurrentBitmap();

                this._sendActiveCmd(false);
            } else {// no longer connected, cannot send. timeouts on MS will take care of resetting the outputs.
                // sending when there's no connection would cause the permission popups.
            }

            return super.viewDidDisappear(...arguments);
        }

        destroy() {
            Debug.Control.JalousieConfig && console.log(this.name, "destroy");
            return super.destroy(...arguments);
        }

        getURL() {
            return this.control.uuidAction + "/endPosAdjust";
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        receivedStates(states) {
            this.states = states;
        }

        getStateIDs() {
            return [CtrlEnums.StateId.ADJUSTING_END_POS];
        }

        getPermissions() {
            return [{
                permission: MsPermission.EXPERT_MODE,
                revoke: true
            }];
        }

        reloadTable() {
            this.tableContent = [];
            this.tableContent.push(this._getDisclaimerSection());
            this.tableContent.push(this._getBothSection());
            this.tableContent.push(this._getUpDownSection());
            return super.reloadTable(...arguments);
        }

        // ------------------------------------------------------------------------
        //  Content Acquisition
        // ------------------------------------------------------------------------
        _getBothSection() {
            var section = {
                    rows: []
                },
                upDownBit = CtrlEnums.EndPosAdjustBit.UP;
            upDownBit |= CtrlEnums.EndPosAdjustBit.DOWN;
            section.rows.push(this._getButtonRow(_("controls.jalousie.end-pos.up-and-down-simultaneos"), upDownBit, window.Styles.colors.red));
            return section;
        }

        _getUpDownSection() {
            var section = {
                    rows: [],
                    customClass: "table-view-section-columns"
                },
                upBit = CtrlEnums.EndPosAdjustBit.UP,
                downBit = CtrlEnums.EndPosAdjustBit.DOWN;
            section.rows.push(this._getButtonRow(_("controls.jalousie.up"), upBit));
            section.rows.push(this._getButtonRow(_("controls.jalousie.down"), downBit));
            return section;
        }

        _getButtonRow(title, bitmask, tintColor) {
            return {
                type: GUI.TableViewV2.CellType.Special.COMFORT_ACTION,
                content: {
                    title: title,
                    supportsTick: true,
                    tickInterval: OUTPUT_INTERVAL,
                    textColor: Color.CARD_TEXT_B,
                    tintColor: tintColor
                },
                action: this._setThenClearBitAndUpdate.bind(this, bitmask),
                onCellHit: this._setBitAndUpdate.bind(this, bitmask),
                onCellTick: this._setBitAndUpdate.bind(this, bitmask),
                onCellReleased: this._clearBitAndUpdate.bind(this, bitmask)
            };
        }

        _getDisclaimerSection() {
            var section = {
                rows: []
            };
            section.rows.push({
                type: GUI.TableViewV2.CellType.TEXT,
                content: {
                    text: _("controls.jalousie.end-pos.warning"),
                    color: window.Styles.colors.red
                }
            });
            return section;
        }

        // ------------------------------------------------------------------------
        //  Button Response Methods
        // ------------------------------------------------------------------------
        _setThenClearBitAndUpdate(bitMask) {
            this._setBitAndUpdate(bitMask);

            this._clearBitAndUpdate(bitMask);
        }

        _setBitAndUpdate(setBitmask) {
            this._guiTimer === null && this._startGuiTimer();
            this.bitmap = setBit(this.bitmap, setBitmask);

            this._sendCurrentBitmap();
        }

        _clearBitAndUpdate(clearBitmask) {
            this.bitmap = clearBit(this.bitmap, clearBitmask);

            this._sendCurrentBitmap();

            this._stopGuiTimer();
        }

        _sendCurrentBitmap() {
            Debug.Control.JalousieConfig && console.log(this.name, "_sendCurrentBitmap");
            var cmd = Commands.format(Commands.JALOUSIE.ADJUST_ENDPOSITION, this.bitmap);
            this.control.sendCommand(cmd);
        }

        // ------------------------------------------------------------------------
        // Keep Active Timer
        // ------------------------------------------------------------------------
        _startKeepActive() {
            Debug.Control.JalousieConfig && console.log(this.name, "_startKeepActive");
            this._keepActiveTimer && this._stopKeepActive();
            this._keepActiveTimer = setTimeout(this._keepActiveTimerFired.bind(this), ACTIVATE_INTERVAL);
        }

        _stopKeepActive() {
            Debug.Control.JalousieConfig && console.log(this.name, "_stopKeepActive");
            this._keepActiveTimer && clearTimeout(this._keepActiveTimer);
            this._keepActiveTimer = null;
        }

        _keepActiveTimerFired() {
            this._sendActiveCmd(true); // restart the timer.


            this._startKeepActive();
        }

        _sendActiveCmd(active) {
            Debug.Control.JalousieConfig && console.log(this.name, "_sendActiveCmd: " + active);
            var cmd = Commands.format(Commands.JALOUSIE.ENABLE_ENDPOS_ADJUSTMENT, active ? 1 : 0),
                fCmd = Commands.format(Commands.CONTROL.COMMAND, this.control.uuidAction, cmd);
            this.hasPermissionsGranted().then(function () {
                SandboxComponent.sendWithPermission(fCmd, MsPermission.EXPERT_MODE);
            });
        }

        _isConnected() {
            return CommunicationComponent.getCurrentConnectionType() !== CONNECTION_TYPE.NONE;
        }

        // ------------------------------------------------------------------------
        // Visible GUI Timer
        // ------------------------------------------------------------------------
        _startGuiTimer() {
            this.elements.timerLabel.toggleClass("top__timer--active", true);
            this._startTime = moment();
            this._guiTimer = setInterval(this._updateTime.bind(this, true), GUI_TIMER_UPDATE_INTERVAL);

            this._updateTime();
        }

        _stopGuiTimer() {
            if (this._guiTimer) {
                clearInterval(this._guiTimer);
                this.elements.timerLabel.toggleClass("top__timer--active", false);
                this._guiTimer = null;

                this._updateTime();
            }
        }

        _updateTime() {
            var now = moment(),
                delta = now.diff(this._startTime, "seconds", true); // only increment in 0.5 second steps - the user would be overwhelmed by 100ms steps!

            var flooredDelta = Math.floor(delta);
            var deltaDecimals = delta - flooredDelta;

            if (deltaDecimals < 0.5) {
                deltaDecimals = 0;
            } else {
                deltaDecimals = 0.5;
            }

            delta = flooredDelta + deltaDecimals;
            this.elements.timerLabel.text(lxFormat("%.1fs", delta));
        }

    };
});
