'use strict';

define([], function () {
    return {
        BUFFER_STATE: {
            WAITING: 0,
            HEATING_UP: 1,
            COOLING_DOWN: 2,
            // not in use right now
            OK_TEMP_REACHED: 3
        }
    };
});
