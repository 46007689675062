'use strict';
/*
 entries = [{time: "12:43:54", text: "Some Text"}]
 */

GUI.TableViewV2.CellType.Special.AUTOMATIC_DESIGNER_CELL = "AutomaticDesignerCell";

class AutomaticDesignerCell extends GUI.TableViewV2.Cells.EditableBaseCell {
    //region Static
    static CAPABILITY_ERROR = {
        NONE: 0,
        NOT_FOUND: -1,
        NOT_CONFIGURED: -2,
        SYSTEM_STATE_MESSAGE: -3
    };

    static getUserFriendlyValue(values, valueIndex, capability, targetEntry) {
        var capabilityDetails = capability.hasOwnProperty("details") ? capability.details : null,
            val,
            userFriendlyVal;

        if (typeof values !== "undefined") {
            val = values[valueIndex];
        }

        switch (capability.type) {
            case AutomaticDesignerEnums.ENTRY_TYPES.DIGITAL:
                var valText = [_('inactive'), _('active')];

                if (capabilityDetails && capabilityDetails[valueIndex]) {
                    if (capabilityDetails[valueIndex].hasOwnProperty("offText")) {
                        valText[0] = capabilityDetails[valueIndex].offText;
                    }

                    if (capabilityDetails[valueIndex].hasOwnProperty("onText")) {
                        valText[1] = capabilityDetails[valueIndex].onText;
                    }
                }

                userFriendlyVal = valText[+!!val];
                break;

            case AutomaticDesignerEnums.ENTRY_TYPES.ANALOG:
            case AutomaticDesignerEnums.ENTRY_TYPES.CALLER:
            case AutomaticDesignerEnums.ENTRY_TYPES.TTS:
            case AutomaticDesignerEnums.ENTRY_TYPES.SYSTEM_STATE:
                userFriendlyVal = this._getAnalogUserFriendlyValue(capability, capabilityDetails, val, valueIndex, targetEntry);
                break;
        }

        if (!userFriendlyVal) {
            if (typeof val !== "undefined") {
                userFriendlyVal = val.toString();
            } else if (capabilityDetails && capabilityDetails[valueIndex].hasOwnProperty("defaultText")) {
                userFriendlyVal = capabilityDetails[valueIndex].defaultText;
            } else {
                userFriendlyVal = "...";
            }
        }

        return userFriendlyVal;
    }

    static getDefaultValue(capability, valueIndex, targetEntry) {
        var capabilityDetails = capability.hasOwnProperty("details") ? capability.details : null,
            defaultValue;

        if (capability && capability.hasOwnProperty("sourceValue")) {
            defaultValue = capability.sourceValue;
        } else {
            switch (capability.type) {
                case AutomaticDesignerEnums.ENTRY_TYPES.DIGITAL:
                    defaultValue = 0;
                    break;

                case AutomaticDesignerEnums.ENTRY_TYPES.ANALOG:
                    defaultValue = this._getAnalogDefaultValue(capability, capabilityDetails, valueIndex, targetEntry);
                    break;

                case AutomaticDesignerEnums.ENTRY_TYPES.TEXT:
                    defaultValue = "";
                    break;

                case AutomaticDesignerEnums.ENTRY_TYPES.TTS:
                case AutomaticDesignerEnums.ENTRY_TYPES.MAIL:
                case AutomaticDesignerEnums.ENTRY_TYPES.CALLER:
                case AutomaticDesignerEnums.ENTRY_TYPES.SYSTEM_STATE:
                case AutomaticDesignerEnums.ENTRY_TYPES.NOTIFICATION:
                    defaultValue = this._setNotificationDefaultValues(capability, valueIndex);
                    break;
            }
        }

        return defaultValue;
    }

    static getOperatorName(operator) {
        var operatorName;

        switch (operator) {
            case AutomaticDesignerEnums.VALUE_OPERATORS.EQUAL:
            case AutomaticDesignerEnums.VALUE_OPERATORS.TEXT_EQUALS:
                operatorName = _('automatic-designer.operators.equal');
                break;

            case AutomaticDesignerEnums.VALUE_OPERATORS.NOT_EQUAL:
            case AutomaticDesignerEnums.VALUE_OPERATORS.TEXT_NOT_EQUAL:
                operatorName = _('automatic-designer.operators.not-equal');
                break;

            case AutomaticDesignerEnums.VALUE_OPERATORS.GREATER:
                operatorName = _('automatic-designer.operators.greater');
                break;

            case AutomaticDesignerEnums.VALUE_OPERATORS.LESS:
                operatorName = _('automatic-designer.operators.less');
                break;

            case AutomaticDesignerEnums.VALUE_OPERATORS.GREATER_OR_EQUAL:
                operatorName = _('automatic-designer.operators.greater-or-equal');
                break;

            case AutomaticDesignerEnums.VALUE_OPERATORS.LESS_OR_EQUAL:
                operatorName = _('automatic-designer.operators.less-or-equal');
                break;

            case AutomaticDesignerEnums.VALUE_OPERATORS.TEXT_CONTAINS:
                operatorName = _('automatic-designer.operators.contains');
                break;

            case AutomaticDesignerEnums.VALUE_OPERATORS.TEXT_NOT_CONTAINS:
                operatorName = _('automatic-designer.operators.contains-not');
                break;

            case AutomaticDesignerEnums.VALUE_OPERATORS.TEXT_STARTS_WITH:
                operatorName = _('automatic-designer.operators.starts-with');
                break;

            case AutomaticDesignerEnums.VALUE_OPERATORS.TEXT_NOT_STARTS_WITH:
                operatorName = _('automatic-designer.operators.starts-not-with');
                break;

            default:
                operatorName = "";
        }

        return operatorName;
    }

    static _getAnalogUserFriendlyValue(capability, capabilityDetails, val, valueIndex, targetEntry) {
        var analogUserFriendlyVal;

        if (capabilityDetails && capabilityDetails[valueIndex]) {
            if (capabilityDetails[valueIndex].hasOwnProperty("factor")) {
                val /= capabilityDetails[valueIndex].factor;
            }

            switch (capabilityDetails[valueIndex].type) {
                case AutomaticDesignerEnums.CAPABILITY_TYPES.INPUT:
                    analogUserFriendlyVal = val;
                    break;

                case AutomaticDesignerEnums.CAPABILITY_TYPES.TIME:
                    // adding 0 if the number is less than ten
                    if (typeof val === 'number') {
                        analogUserFriendlyVal = ('0' + Math.floor(val / 60)).slice(-2) + ':' + ('0' + val % 60).slice(-2);
                    }

                    analogUserFriendlyVal += " " + _('timeSuffix');
                    break;

                case AutomaticDesignerEnums.CAPABILITY_TYPES.DATE:
                    analogUserFriendlyVal = moment.unix(val).format(LxDate.getDateFormat());
                    break;

                case AutomaticDesignerEnums.CAPABILITY_TYPES.DURATION:
                    analogUserFriendlyVal = this._getDurationUserFriendlyValue(capability, capabilityDetails, val, valueIndex);
                    break;

                case AutomaticDesignerEnums.CAPABILITY_TYPES.ANALOG_SINGLE_SELECT:
                case AutomaticDesignerEnums.CAPABILITY_TYPES.ANALOG_MULTI_SELECT:
                    var targetOptions;

                    if (capabilityDetails[valueIndex].hasOwnProperty("values")) {
                        // Static defined options
                        targetOptions = capabilityDetails[valueIndex].values;
                    } else if (capabilityDetails[valueIndex].hasOwnProperty("sourceState")) {
                        // Dynamic, state dependent options
                        if (targetEntry && targetEntry.hasOwnProperty("uuidAction")) {
                            var stateContainer = SandboxComponent.getStateContainerForUUID(targetEntry.uuidAction);

                            if (stateContainer) {
                                targetOptions = stateContainer.getAutomaticDesignerStateObjectsFromState(capabilityDetails[valueIndex].sourceState);
                            }
                        }
                    } else if (capabilityDetails[valueIndex].hasOwnProperty("sourceDetail")) {
                        // Dynamic, detail dependent options
                        if (targetEntry && targetEntry.hasOwnProperty("uuidAction")) {
                            targetOptions = targetEntry.getAutomaticDesignerDetailObjectsFromDetailsKey(capabilityDetails[valueIndex].sourceDetail);
                        }
                    } else if (capabilityDetails[valueIndex].hasOwnProperty("sourceControlTypes")) {
                        // Dynamic, control list as option
                        targetOptions = [];
                        capabilityDetails[valueIndex].sourceControlTypes.forEach(function (controlType) {
                            targetOptions = targetOptions.concat(SandboxComponent.getStructureManager().getControlsByType(controlType).map(function (ctrol) {
                                return {
                                    id: ctrol.uuidAction,
                                    name: ctrol.getName()
                                };
                            }));
                        });
                    }

                    if (targetOptions && targetOptions.length) {
                        // In case of a singleSelect
                        if (typeof val === "undefined") {
                            if (capabilityDetails[valueIndex].hasOwnProperty("defaultText")) {
                                analogUserFriendlyVal = capabilityDetails[valueIndex].defaultText;
                            } else {
                                val = this.getDefaultValue(capability, valueIndex, targetEntry);
                            }
                        }

                        if (!analogUserFriendlyVal) {
                            if (!(val instanceof Array)) {
                                val = [val];
                            }

                            analogUserFriendlyVal = [].concat.apply([], val).map(function (rawVal) {
                                return targetOptions.find(function (definedVal) {
                                    return definedVal.id.toString() === rawVal.toString();
                                })?.name; // If condition in .find() isn't met it returns undefined, we cannot safely access .name on undefined (BG-I26838)
                            }).join(", ");
                        }

                        break;
                    }

                // Fallthough to default!
            }
        }

        return analogUserFriendlyVal;
    }

    static _getDurationUserFriendlyValue(capability, capabilityDetails, val, valueIndex) {
        if (capabilityDetails && capabilityDetails[valueIndex].hasOwnProperty("format")) {
            return sprintf(capabilityDetails[valueIndex].format, val);
        } else {
            return "" + val;
        }
    }

    static _getAnalogDefaultValue(capability, capabilityDetails, valueIndex, targetEntry) {
        var defaultValue;

        if (capabilityDetails && capabilityDetails[valueIndex]) {
            switch (capabilityDetails[valueIndex].type) {
                case AutomaticDesignerEnums.CAPABILITY_TYPES.TIME:
                    var now = new Date();
                    defaultValue = now.getHours() * 60 + now.getMinutes();
                    break;

                case AutomaticDesignerEnums.CAPABILITY_TYPES.DATE:
                    defaultValue = moment().unix();
                    break;

                case AutomaticDesignerEnums.CAPABILITY_TYPES.DURATION:
                    if (capabilityDetails[valueIndex].hasOwnProperty("min")) {
                        defaultValue = capabilityDetails[valueIndex].min;
                    } else {
                        defaultValue = 0;
                    }

                    break;

                case AutomaticDesignerEnums.CAPABILITY_TYPES.INPUT:
                    if (capabilityDetails[valueIndex].hasOwnProperty("min")) {
                        defaultValue = capabilityDetails[valueIndex].min;
                    } else if (targetEntry && targetEntry.hasOwnProperty("uuidAction")) {
                        var controlStates = targetEntry.getStates();

                        if (targetEntry.hasOwnProperty("details") && targetEntry.details.hasOwnProperty("min")) {
                            defaultValue = targetEntry.details.min;
                        } else if (controlStates) {
                            defaultValue = controlStates.min || 0;
                        } else {
                            defaultValue = 0;
                        }
                    } else {
                        defaultValue = 0;
                    }

                    break;

                case AutomaticDesignerEnums.CAPABILITY_TYPES.ANALOG_SINGLE_SELECT:
                case AutomaticDesignerEnums.CAPABILITY_TYPES.ANALOG_MULTI_SELECT:
                    if (capabilityDetails[valueIndex].hasOwnProperty("defaultText")) {
                        defaultValue = undefined;
                    } else {
                        var targetOptions;

                        if (capabilityDetails[valueIndex].hasOwnProperty("values")) {
                            // Static defined options
                            targetOptions = capabilityDetails[valueIndex].values;
                        } else if (capabilityDetails[valueIndex].hasOwnProperty("sourceState")) {
                            // Dynamic, state dependent options
                            if (targetEntry && targetEntry.hasOwnProperty("uuidAction")) {
                                var stateContainer = SandboxComponent.getStateContainerForUUID(targetEntry.uuidAction);

                                if (stateContainer) {
                                    targetOptions = stateContainer.getAutomaticDesignerStateObjectsFromState(capabilityDetails[valueIndex].sourceState);
                                }
                            }
                        } else if (capabilityDetails[valueIndex].hasOwnProperty("sourceDetail")) {
                            // Dynamic, detail dependent options
                            if (targetEntry && targetEntry.hasOwnProperty("uuidAction")) {
                                targetOptions = targetEntry.getAutomaticDesignerDetailObjectsFromDetailsKey(capabilityDetails[valueIndex].sourceDetail);
                            }
                        } else if (capabilityDetails[valueIndex].hasOwnProperty("sourceControlTypes")) {
                            // Dynamic, detail dependent options
                            targetOptions = [];
                            capabilityDetails[valueIndex].sourceControlTypes.forEach(function (controlType) {
                                targetOptions = targetOptions.concat(SandboxComponent.getStructureManager().getControlsByType(controlType).map(function (ctrol) {
                                    return {
                                        id: ctrol.uuidAction,
                                        name: ctrol.getName()
                                    };
                                }));
                            });
                        }

                        if (targetOptions && targetOptions.length) {
                            if (capabilityDetails[valueIndex].type === AutomaticDesignerEnums.CAPABILITY_TYPES.ANALOG_SINGLE_SELECT) {
                                defaultValue = targetOptions[0].id;
                            } else {
                                defaultValue = [targetOptions[0].id];
                            }
                        }
                    }

                    break;
            }
        } else {
            defaultValue = 0;
        }

        return defaultValue;
    }

    static _setNotificationDefaultValues(capability, valueIndex) {
        var defaultVal;

        switch (capability.details[valueIndex].type) {
            case AutomaticDesignerEnums.CAPABILITY_TYPES.INPUT:
            case AutomaticDesignerEnums.CAPABILITY_TYPES.MULTILINE:
                defaultVal = "";
        }

        return defaultVal;
    }

    static Template = function () {
        var getCellTemplate = function getCellTemplate() {
            return '' + '<div class="automatic-designer__container">' + '   <div class="container__text-container">' + '       <div class="text-container__top-container">' + '           <div class="top-container__icon-placeholder">' + '           </div>' + '           <div class="top-container__title"/>' + '       </div>' + '       <div class="text-container__info-text-container"/>' + '       <pre class="text-container__footer"/>' + // Why use a <pre>? PRE tags can show linebreaks as actual linebreaks
                '   </div>' + '   <div class="container__right-side-container">' + '       <div class="right-side-container__icon-placeholder">' + '       </div>' + '   </div>' + '</div>';
        };

        var getRightIcon = function getRightIcon(isInEditMode) {
            var iconSrc,
                classList = "icon-placeholder__icon";

            if (isInEditMode) {
                iconSrc = Icon.REMOVE;
                classList += " icon-placeholder__icon--remove";
            } else {
                iconSrc = Icon.TRANSLUCENT_ADD;
                classList += " icon-placeholder__icon--add";
            }

            return ImageBox.getResourceImageWithClasses(iconSrc, classList);
        };

        return {
            getCellTemplate: getCellTemplate,
            getRightIcon: getRightIcon
        };
    }(); //endregion Static

    //region Private
    //fast-class-es6-converter: extracted from defineStatic({}) content
    BUTTON_KEYS = {
        ADD: "addButton",
        OPERATOR: "operatorButton"
    }; //endregion Private

    constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
        super(...arguments);
        this._operatorButtons = [];
        this._variableViews = [];
        this._valueButtons = {};
        this._validityMap = {};
        this._activeSystemStateMessages = [];
        this._variableBoundries = {};
    }

    viewDidLoad() {
        return Q.all([super.viewDidLoad(...arguments), GUI.animationHandler.append(AutomaticDesignerCell.Template.getCellTemplate(), this.contentPlaceholder)]).then(function () {
            var knownType,
                footerText,
                domManipulationFns = []; // For easier debugging

            this.element.attr("descId", this.content.descID);
            this.content.groupIdx = typeof this.content.groupIdx === "undefined" ? this.sectionIdx - 1 : this.content.groupIdx;
            this.content.entryIdx = typeof this.content.entryIdx === "undefined" ? this.rowIdx - 1 : this.content.entryIdx;
            this.cellXPath = this.content.navigationPath + "." + this.content.entryIdx + "." + this.rowIdx;
            this.capability = AutomaticDesignerComponent.getCapabilityForDescID(this.content.descID, this.content.screenType);
            this.capabilityDetails = this.capability.hasOwnProperty("details") ? this.capability.details : null;
            this.knownTypes = AutomaticDesignerEnums.KNOWN_TYPES[this.content.screenType.toUpperCase()];
            knownType = Object.values(this.knownTypes).find(function (type) {
                return type.INT.includes(this.capability._adType || AutomaticDesignerEnums.KNOWN_TYPES.EVENTS.FUNCTIONS.INT[0]);
            }.bind(this));

            if (knownType) {
                this.entryType = knownType.STRING;
            }

            this._detectError(knownType);

            if (this.content.indent) {
                domManipulationFns.push(function () {
                    this.element.addClass("automatic-designer-cell__indent");
                }.bind(this));
            }

            domManipulationFns.push(function () {
                this.element.find(".right-side-container__icon-placeholder").html(AutomaticDesignerCell.Template.getRightIcon(this.content.editMode));
            }.bind(this));

            if ((this.content.editMode || this.content.fromSearch) && this.entryType) {
                if (this.content.lastSelectedEntry && this.content.lastSelectedEntry.hasOwnProperty('uuidAction')) {
                    this.content.lastSelectedEntry = SandboxComponent.getStructureManager().getControlByUUID(this.content.lastSelectedEntry.uuidAction);
                    this.element.find('.top-container__title').text((this.content.lastSelectedEntry.getName() || "").addLineBreakPossibilities(true));
                    footerText = this.content.lastSelectedEntry.getControlTypeName();

                    if (this.content.lastSelectedEntry.getRoom()) {
                        footerText += SEPARATOR_SYMBOL + this.content.lastSelectedEntry.getRoom().name;
                    }

                    this.element.find('.text-container__footer').text(footerText);
                } else {
                    this.element.find('.top-container__title').text(AutomaticDesignerComponent.getNameForEntryType(this.entryType, this.content.screenType));
                }

                var iconSrc;

                if (this.capabilityError === this.constructor.CAPABILITY_ERROR.NONE) {
                    iconSrc = AutomaticDesignerComponent.getIconForEntryType(this.entryType, this.content.screenType);
                } else {
                    iconSrc = Icon.CAUTION;
                }

                if (iconSrc) {
                    domManipulationFns.push(function () {
                        this.element.find('.top-container__icon-placeholder').html(ImageBox.getResourceImageWithClasses(iconSrc, "icon-placeholder__icon"));
                    }.bind(this));
                }
            } else {
                domManipulationFns.push(function () {
                    this.element.addClass("automatic-designer-cell__centered-button");
                    this.element.find('.text-container__footer').text(this.capability.description);
                }.bind(this));
            }

            var rightSideButtonElm = this.element.find('.container__right-side-container');
            this._rightSideButton = new GUI.LxButton(this, rightSideButtonElm);
            this.addToHandledSubviews(this._rightSideButton);

            this._rightSideButton.onButtonTapped = function () {
                if (this.content.editMode) {
                    this._removeFromRule();
                } else {
                    this._applyToRule();
                }
            }.bind(this);

            return this.prepareAndCreateVariableViews().then(function () {
                if (this._variableBoundries[0]) {
                    var ogFooter = this.element.find('.text-container__footer').text(),
                        newFooter = _("automatic-designer.value-range", {
                            range: this._variableBoundries[0].min + " - " + this._variableBoundries[0].max
                        }) + "\r\n" + ogFooter;
                    this.element.find('.text-container__footer').text(newFooter);
                }

                return GUI.animationHandler.scheduleAll(domManipulationFns);
            }.bind(this));
        }.bind(this));
    }

    // UI prep
    prepareAndCreateVariableViews() {
        var textPlaceholder = $('<div class ="text-container__title-container"/>'),
            capabilityString = this._getCapabilityName().replace(/\\n/g, "__<br>__");

        this.titleContainerElem = this.element.find('.text-container__info-text-container'); // Tear everything down

        var temporaryContent = this.titleContainerElem.clone();
        return GUI.animationHandler.replace(this.titleContainerElem, temporaryContent).then(function () {
            this.titleContainerElem.empty();
            return this._operatorButtons.concat(this._variableViews).reduce(function (prevPromise, view) {
                return prevPromise.then(function () {
                    return this.removeSubview(view);
                }.bind(this));
            }.bind(this), Q.resolve()).then(function () {
                var prms = [];

                if (this.content.editMode && !this.content.fromSearch && this.content.screenType === AutomaticDesignerEnums.SCREEN_TYPES.EVENTS && this.content.entryIdx > 0) {
                    capabilityString = _('and').toUpperCase() + " " + capabilityString;
                } // Rebuild the variableViews


                return capabilityString.split("__").reduce(function (prevPrms, word, wordIdx, words) {
                    return prevPrms.then(function () {
                        // Ignore empty words as we would get exceptions when when splitting it again!
                        if (nullEmptyString(word)) {
                            var valueMatch = Regex.AUTOMATIC_DESIGNER_VALUE.clone().exec(word),
                                unitMatch = Regex.AUTOMATIC_DESIGNER_UNIT.clone().exec(word),
                                valueIndex,
                                unitIndex;

                            if (valueMatch) {
                                valueIndex = valueMatch[1] || 0; // use 0 in case the word is __value__ (legacy)

                                if (this.capabilityError === this.constructor.CAPABILITY_ERROR.NONE) {
                                    this._setDefaultValueIfNeeded(valueIndex);

                                    this._variableViews = this._getValueViews(valueIndex);
                                    prms.push(this._variableViews.reduce(function (prevPrms, view) {
                                        return prevPrms.then(function () {
                                            return this.appendSubview(view, textPlaceholder);
                                        }.bind(this));
                                    }.bind(this), Q.resolve()).then(function () {
                                        var nextWord = words[wordIdx + 1];

                                        if (!nullEmptyString(nextWord.trim())) {
                                            nextWord = words[wordIdx + 2];
                                        } // We have a next word and its not a unit, but this is a value, so check if we can append one


                                        if (this.capabilityDetails && this.capabilityDetails[valueIndex].type === AutomaticDesignerEnums.CAPABILITY_TYPES.INPUT && (nextWord && !Regex.AUTOMATIC_DESIGNER_UNIT.exec(nextWord) || !nextWord)) {
                                            if (this.content.lastSelectedEntry && this.content.lastSelectedEntry.hasOwnProperty("uuidAction") && this.content.lastSelectedEntry.details && this.content.lastSelectedEntry.details.format) {
                                                textPlaceholder.append(this._getWordDiv(this.content.lastSelectedEntry.details.format.replace(/^(%(.\d)?)([a-z])?([%])?/, ""), // The regex removes the C-style format from the format and just leaves the unit
                                                    "text-container__title-container__word"));
                                            }
                                        }
                                    }.bind(this)));
                                } else {
                                    textPlaceholder.append(this._getWordDiv(NBR_SPACE, "text-container__title-container__word--missing"));
                                }
                            } else if (unitMatch) {
                                unitIndex = unitMatch[1] || 0; // use 0 in case the word is __unit__ (legacy)

                                if (this.capabilityDetails[unitIndex].hasOwnProperty("unit")) {
                                    textPlaceholder.append(this._getWordDiv(this.capabilityDetails[unitIndex].unit, "text-container__title-container__word"));
                                } else {
                                    if (this.content.lastSelectedEntry && this.content.lastSelectedEntry.hasOwnProperty("uuidAction") && this.content.lastSelectedEntry.details && this.content.lastSelectedEntry.details.format) {
                                        textPlaceholder.append(this._getWordDiv(this.content.lastSelectedEntry.details.format.replace(/^(%(.\d)?)([a-z])?([%])?/, ""), // The regex removes the C-style format from the format and just leaves the unit
                                            "text-container__title-container__word"));
                                    }
                                }
                            } else {
                                if (this.capabilityError === this.constructor.CAPABILITY_ERROR.NONE) {
                                    switch (word) {
                                        case AutomaticDesignerEnums.KEYWORDS.OPERATOR:
                                            // Show a single operator as a normal text
                                            if (!this.capability.hasOwnProperty("operators") || this.capability.operators.length > 1) {
                                                this._operatorButtons = this._getOperatorButtons();
                                                prms.push(this._operatorButtons.reduce(function (prevPromise, btn) {
                                                    return prevPromise.then(function () {
                                                        return this.appendSubview(btn, textPlaceholder);
                                                    }.bind(this));
                                                }.bind(this), Q.resolve()));
                                            } else {
                                                textPlaceholder.append(this._getWordDiv(this.constructor.getOperatorName(this.capability.operators[0]), "text-container__title-container__word"));
                                            }

                                            break;

                                        case AutomaticDesignerEnums.KEYWORDS.NAME_PLACEHOLDER:
                                            textPlaceholder.append(this._getWordDiv('"' + (this.content.lastSelectedEntry.name || "").addLineBreakPossibilities(true) + '"', "text-container__title-container__word"));
                                            break;

                                        case AutomaticDesignerEnums.KEYWORDS.LINE_BREAK:
                                            textPlaceholder.append($("<div class='text-container__title-container__line-filler'/>"));
                                            break;

                                        default:
                                            textPlaceholder.append(this._getWordDiv(word, "text-container__title-container__word"));
                                            break;
                                    }
                                } else {
                                    switch (word) {
                                        case AutomaticDesignerEnums.KEYWORDS.OPERATOR:
                                            textPlaceholder.append(this._getWordDiv(NBR_SPACE, "text-container__title-container__word--missing"));
                                            break;

                                        case AutomaticDesignerEnums.KEYWORDS.NAME_PLACEHOLDER:
                                            if (this.content.lastSelectedEntry.name) {
                                                textPlaceholder.append(this._getWordDiv(this.content.lastSelectedEntry.name, "text-container__title-container__word"));
                                            } else {
                                                textPlaceholder.append(this._getWordDiv(NBR_SPACE, "text-container__title-container__word--missing"));
                                            }

                                            break;

                                        case AutomaticDesignerEnums.KEYWORDS.LINE_BREAK:
                                            textPlaceholder.append($("<div class='text-container__title-container__line-filler'/>"));
                                            break;

                                        default:
                                            textPlaceholder.append(this._getWordDiv(word, "text-container__title-container__word"));
                                            break;
                                    }
                                }
                            }

                            return Q.all(prms).then(function () {
                                // Reset the regex to prevent sporadically null returns of the exec function
                                Regex.AUTOMATIC_DESIGNER_VALUE.lastIndex = 0;
                                Regex.AUTOMATIC_DESIGNER_UNIT.lastIndex = 0;
                            });
                        } else {
                            return Q.resolve();
                        }
                    }.bind(this));
                }.bind(this), Q.resolve()).then(function () {
                    return GUI.animationHandler.schedule(function () {
                        this.titleContainerElem.html(textPlaceholder);
                    }.bind(this));
                }.bind(this));
            }.bind(this));
        }.bind(this)).then(function () {
            return GUI.animationHandler.replace(temporaryContent, this.titleContainerElem);
        }.bind(this));
    }

    destroy() {
        if (this.content && !this.content.editMode) {
            AutomaticDesignerComponent.resetForXPath(this.cellXPath);
        }

        return super.destroy(...arguments);
    }

    onSelected() {
        if (this.capabilityError === this.constructor.CAPABILITY_ERROR.NONE) {
            super.onSelected(...arguments);
        } else {
            this._handleOnErrorTapped();
        }
    }

    setDragging(dragging) {
        this._isDragging = dragging;

        this._variableViews.concat(this._operatorButtons).forEach(function (view) {
            view.setEnabled && view.setEnabled(!dragging);
        });
    }

    // User Interaction
    _handleOnOperatorChange(operator) {
        if (this.content.editMode) {
            if (this.content.screenType === AutomaticDesignerEnums.SCREEN_TYPES.EVENTS) {
                AutomaticDesignerComponent.setEventOperator(this.content.groupIdx, this.content.entryIdx, operator);
            }
        } else {
            AutomaticDesignerComponent.setOperatorForXPath(this.cellXPath, operator);
        }

        this.prepareAndCreateVariableViews();
    }

    _handleOnValueChange(value, valueIndex, ignoreUIUpdate) {
        this._handleOnValidityChange(true, valueIndex);

        if (this.capabilityDetails && this.capabilityDetails[valueIndex].hasOwnProperty("factor")) {
            value *= this.capabilityDetails[valueIndex].factor;
        }

        if (this.content.editMode) {
            if (this.content.screenType === AutomaticDesignerEnums.SCREEN_TYPES.EVENTS) {
                AutomaticDesignerComponent.setEventValueAtIndex(this.content.groupIdx, this.content.entryIdx, value);
            } else {
                if (value instanceof Array) {
                    AutomaticDesignerComponent.setActionValues(this.content.entryIdx, value, this.capability);
                } else {
                    AutomaticDesignerComponent.setActionValueAtIndex(this.content.entryIdx, value, valueIndex, this.capability);
                }
            }
        } else {
            if (value instanceof Array) {
                AutomaticDesignerComponent.setValuesForXPath(this.cellXPath, value);
            } else {
                AutomaticDesignerComponent.setValueAtIndexForXPath(this.cellXPath, value, valueIndex);
            }
        }

        !ignoreUIUpdate && this.prepareAndCreateVariableViews();
    }

    _handleOnValidityChange(valid, valueIndex) {
        if (typeof valueIndex !== "undefined") {
            this._validityMap[valueIndex] = valid;
            valid = Object.values(this._validityMap).reduce(function (left, right) {
                return left && right;
            }, true);
        }

        if (!this._preventValidityChange) {
            this.delegate.validityDidChange && this.delegate.validityDidChange.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView, this, valid, this.cellXPath);

            if (!this.content.editMode) {
                this._rightSideButton.setEnabled(valid);

                this._rightSideButton.getElement().toggleClass("right-side-container__icon-placeholder--invalid-value", !valid);
            }
        }
    }

    _getCapabilityName() {
        var capabilityName = this.capability.name;

        if (this.content.editMode) {
            try {
                if (this.content.descID === AutomaticDesignerEnums.GENERIC_FUNCTION_CMD_FALLBACK_DESC_ID) {
                    var rootObj = AutomaticDesignerComponent.getCurrentEditRule().actions[this.content.entryIdx];

                    if (rootObj.hasOwnProperty("name")) {
                        capabilityName = rootObj.name;
                    } // Last fallback!


                    if (!nullEmptyString(capabilityName)) {
                        capabilityName = "__" + AutomaticDesignerEnums.KEYWORDS.NAME_PLACEHOLDER + "__";

                        if (rootObj.hasOwnProperty("command")) {
                            capabilityName += ": " + rootObj.command;
                        }
                    }
                }
            } catch (e) {// Nothing to do
            }
        }

        return capabilityName;
    }

    _getOperatorButtons() {
        return this._getWordDiv(this.constructor.getOperatorName(this._getCurrentOperator()), "text-container__title-container__interaction-element").map(function (elm) {
            var button = new GUI.LxButton(this, elm[0]);
            button.onButtonTapped = this._selectOperator.bind(this);
            return button;
        }.bind(this));
    }

    _getValueViews(valueIndex) {
        var details = this.capabilityDetails ? this.capabilityDetails[valueIndex] : null,
            views = [],
            inputConfig,
            validationRegex;
        delete this._variableBoundries[valueIndex];

        if (this.capability.type === AutomaticDesignerEnums.ENTRY_TYPES.ANALOG || this.capability.type === AutomaticDesignerEnums.ENTRY_TYPES.TEXT) {
            if (!details) {
                details = {};
            }

            if (!details.hasOwnProperty("type")) {
                details.type = AutomaticDesignerEnums.CAPABILITY_TYPES.INPUT;
            }
        }

        if (details && (details.type === AutomaticDesignerEnums.CAPABILITY_TYPES.INPUT || details.type === AutomaticDesignerEnums.CAPABILITY_TYPES.MULTILINE)) {
            if (details && details.hasOwnProperty("validationRegex")) {
                try {
                    validationRegex = new RegExp(details.validationRegex.replace(/\\\\/g, "\\"));
                } catch (e) {
                    // do nothing, an error occurred while processing the validationRegex
                    console.error(e);
                    console.warn("An error occurred while processing the validationRegex");
                }
            }

            inputConfig = {
                isDark: true
            };

            if (validationRegex) {
                inputConfig.validationRegex = validationRegex;
            }

            if (this.capabilityDetails && this.capabilityDetails[valueIndex].hasOwnProperty("defaultText")) {
                inputConfig.placeholder = this.capabilityDetails[valueIndex].defaultText;
            }

            try {
                inputConfig.value = this._getCurrentValues()[valueIndex];
            } catch (e) {
                inputConfig.value = 0;
            }

            if (this.capability.type === AutomaticDesignerEnums.ENTRY_TYPES.ANALOG) {
                if (this.capabilityDetails && this.capabilityDetails[valueIndex].hasOwnProperty("factor")) {
                    inputConfig.value /= this.capabilityDetails[valueIndex].factor;
                }

                inputConfig.type = GUI.LxInputEnum.Type.NUMBER;
                inputConfig.placeholder = inputConfig.value;
                inputConfig.numberStep = 0.001;

                if (this.capabilityDetails) {
                    if (this.capabilityDetails[valueIndex].hasOwnProperty("min")) {
                        inputConfig.numberMin = this.capabilityDetails[valueIndex].min;
                    }

                    if (this.capabilityDetails[valueIndex].hasOwnProperty("max")) {
                        inputConfig.numberMax = this.capabilityDetails[valueIndex].max;
                    }

                    if (this.capabilityDetails[valueIndex].hasOwnProperty("format")) {
                        var numComps = sprintf(this.capabilityDetails[valueIndex].format, 1).split(/([.,])/g);

                        if (numComps.length > 2) {
                            inputConfig.numberStep = Math.pow(10, -numComps[2].length);
                        } else {
                            inputConfig.numberStep = 1;
                        }
                    }
                }

                if (this.content.lastSelectedEntry && this.content.lastSelectedEntry.hasOwnProperty("uuidAction")) {
                    var controlStates = this.content.lastSelectedEntry.getStates();

                    if (this.content.lastSelectedEntry.hasOwnProperty("details")) {
                        if (this.content.lastSelectedEntry.details.hasOwnProperty("format")) {
                            var numComps = sprintf(this.content.lastSelectedEntry.details.format, 1).split(/([.,])/g);

                            if (numComps.length > 2) {
                                inputConfig.numberStep = Math.pow(10, -numComps[2].length);
                            } else {
                                inputConfig.numberStep = 1;
                            }
                        }

                        if (this.content.lastSelectedEntry.details.hasOwnProperty("min")) {
                            inputConfig.numberMin = this.content.lastSelectedEntry.details.min;
                        }

                        if (this.content.lastSelectedEntry.details.hasOwnProperty("max")) {
                            inputConfig.numberMax = this.content.lastSelectedEntry.details.max;
                        }

                        if (this.content.lastSelectedEntry.details.hasOwnProperty("step")) {
                            inputConfig.numberStep = this.content.lastSelectedEntry.details.step;
                        }
                    } else if (controlStates) {
                        if (controlStates.hasOwnProperty("min")) {
                            inputConfig.numberMin = controlStates.min;
                        }

                        if (controlStates.hasOwnProperty("max")) {
                            inputConfig.numberMax = controlStates.max;
                        }

                        if (controlStates.hasOwnProperty("step")) {
                            inputConfig.numberStep = controlStates.step;
                        }
                    }
                }

                if (typeof inputConfig.numberMin === "number" || typeof inputConfig.numberMax === "number") {
                    this._variableBoundries[valueIndex] = {};

                    if (typeof inputConfig.numberMin === "number") {
                        this._variableBoundries[valueIndex].min = inputConfig.numberMin;
                    } else {
                        this._variableBoundries[valueIndex].min = "∞";
                    }

                    if (typeof inputConfig.numberMax === "number") {
                        this._variableBoundries[valueIndex].max = inputConfig.numberMax;
                    } else {
                        this._variableBoundries[valueIndex].max = "∞";
                    }
                }
            } else if (details.type === AutomaticDesignerEnums.CAPABILITY_TYPES.MULTILINE) {
                inputConfig.multiline = true;
            }

            views.push(new GUI.LxInput(inputConfig, this));
            views[0].getElement().addClass("text-container__title-container__interaction-element text-container__title-container__interaction-element--first text-container__title-container__interaction-element--last");
            views[0].getElement().prepend($("<div class='interaction-element__background'></div>"));
            views[0].inputField.addClass("interaction-element__text");

            views[0].onTextChanged = function (value, valid) {
                this._handleOnValidityChange(valid, valueIndex);

                if (valid) {
                    if (this.capability.type === AutomaticDesignerEnums.ENTRY_TYPES.ANALOG) {
                        value = parseFloat(value); // Just in case someone enters 06 instead of 6
                    }

                    this._handleOnValueChange(value, valueIndex, true);
                }

                if (this._validityMap.hasOwnProperty(valueIndex)) {
                    views[0].getElement().toggleClass("text-container__title-container__interaction-element--invalid", !this._validityMap[valueIndex]);
                }
            }.bind(this);

            this._handleOnValidityChange(views[0].setValue(inputConfig.value), valueIndex);

            if (this._validityMap.hasOwnProperty(valueIndex)) {
                views[0].getElement().toggleClass("text-container__title-container__interaction-element--invalid", !this._validityMap[valueIndex]);
            }
        } else {
            // Handle as a button
            this._valueButtons[valueIndex] = this._getValueButtonElements(this.constructor.getUserFriendlyValue(this._getCurrentValues(), valueIndex, this.capability, this.content.lastSelectedEntry), valueIndex).map(function (elm) {
                var button = new GUI.LxButton(this, elm[0]);

                if (this._validityMap.hasOwnProperty(valueIndex)) {
                    button.getElement().toggleClass("text-container__title-container__interaction-element--invalid", !this._validityMap[valueIndex]);
                }

                button.onButtonTapped = this._selectValue.bind(this, valueIndex);
                return button;
            }.bind(this));
            views = this._valueButtons[valueIndex];
        }

        return views;
    }

    // Actions
    _selectOperator() {
        var capabilityOperators = this.capability.operators,
            def = Q.defer(),
            operatorObjects = Object.values(AutomaticDesignerEnums.VALUE_OPERATORS).map(function (opValue) {
                return {
                    name: this.constructor.getOperatorName(opValue),
                    value: opValue
                };
            }.bind(this)).filter(function (opObj) {
                if (nullEmptyString(opObj.name)) {
                    // Check if the capability has provided some operators
                    // and filter out any operators not provided by the capability
                    if (capabilityOperators) {
                        return capabilityOperators.includes(opObj.value) && opObj.name;
                    } else if (this.capability.type === AutomaticDesignerEnums.ENTRY_TYPES.TEXT) {
                        return opObj.value.isBetweenOrEqual(AutomaticDesignerEnums.VALUE_OPERATORS.TEXT_EQUALS, AutomaticDesignerEnums.VALUE_OPERATORS.TEXT_NOT_STARTS_WITH);
                    } else {
                        return opObj.value.isBetweenOrEqual(AutomaticDesignerEnums.VALUE_OPERATORS.BOOL, AutomaticDesignerEnums.VALUE_OPERATORS.LESS_OR_EQUAL);
                    }
                } else {
                    return false;
                }
            }.bind(this)),
            currentOperator = this._getCurrentOperator(),
            details = {
                options: operatorObjects.map(function (opObject) {
                    return {
                        title: opObject.name,
                        selected: opObject.value === currentOperator,
                        clickable: true,
                        opObj: opObject
                    };
                }),
                title: _("automatic-designer.operator"),
                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                mode: GUI.SelectorScreenMode.QUICK,
                deferred: def
            };

        NavigationComp.showSelector(details).then(function (results) {
            this._handleOnOperatorChange(results[0].option.opObj.value);
        }.bind(this));
    }

    _selectValue(valueIndex) {
        var currentValues = this._getCurrentValues(),
            currentValue = currentValues ? currentValues[valueIndex] : this.constructor.getDefaultValue(this.capability, valueIndex, this.content.lastSelectedEntry);

        switch (this.capability.type) {
            case AutomaticDesignerEnums.ENTRY_TYPES.DIGITAL:
                // Save a numeric value!
                this._handleOnValueChange(+!currentValue, valueIndex);

                break;

            default:
                switch (this.capabilityDetails[valueIndex].type) {
                    case AutomaticDesignerEnums.CAPABILITY_TYPES.TIME:
                    case AutomaticDesignerEnums.CAPABILITY_TYPES.DATE:
                    case AutomaticDesignerEnums.CAPABILITY_TYPES.DURATION:
                        this._showPicker(currentValue, valueIndex, this.cellXPath, this._valueButtons[valueIndex][0]);

                        break;

                    case AutomaticDesignerEnums.CAPABILITY_TYPES.ANALOG_SINGLE_SELECT:
                    case AutomaticDesignerEnums.CAPABILITY_TYPES.ANALOG_MULTI_SELECT:
                        this._showAnalogSelection(currentValue, valueIndex);

                        break;
                }

                break;
        }
    }

    // Specific value interaction
    _showAnalogSelection(currentValues, valueIndex) {
        var radioMode = GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
            mode = GUI.SelectorScreenMode.QUICK,
            values; // Must be an array of objects with keys "name" and "id"

        if (this.capabilityDetails[valueIndex].type === AutomaticDesignerEnums.CAPABILITY_TYPES.ANALOG_MULTI_SELECT) {
            radioMode = GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE;
            mode = GUI.SelectorScreenMode.CONFIRMED;
        }

        if (this.capabilityDetails && this.capabilityDetails[valueIndex].hasOwnProperty("values")) {
            // Static defined options
            values = this.capabilityDetails[valueIndex].values;
        } else if (this.capabilityDetails[valueIndex].hasOwnProperty("sourceState")) {
            // Dynamic, state dependent options
            if (this.content.lastSelectedEntry && this.content.lastSelectedEntry.hasOwnProperty("uuidAction")) {
                var stateContainer = SandboxComponent.getStateContainerForUUID(this.content.lastSelectedEntry.uuidAction);

                if (stateContainer) {
                    values = stateContainer.getAutomaticDesignerStateObjectsFromState(this.capabilityDetails[valueIndex].sourceState);
                }
            }
        } else if (this.capabilityDetails[valueIndex].hasOwnProperty("sourceDetail")) {
            // Dynamic, detail dependent options
            if (this.content.lastSelectedEntry && this.content.lastSelectedEntry.hasOwnProperty("uuidAction")) {
                values = this.content.lastSelectedEntry.getAutomaticDesignerDetailObjectsFromDetailsKey(this.capabilityDetails[valueIndex].sourceDetail);
            }
        } else if (this.capabilityDetails[valueIndex].hasOwnProperty("sourceControlTypes")) {
            // Dynamic, detail dependent options
            values = [];
            this.capabilityDetails[valueIndex].sourceControlTypes.forEach(function (controlType) {
                values = values.concat(SandboxComponent.getStructureManager().getControlsByType(controlType).map(function (ctrol) {
                    return {
                        id: ctrol.uuidAction,
                        name: ctrol.getName()
                    };
                }));
            });
        }

        if (values) {
            if (!(currentValues instanceof Array)) {
                currentValues = [currentValues];
            }

            currentValues = [].concat.apply([], currentValues);
            var def = Q.defer(),
                details = {
                    options: values.map(function (value) {
                        return {
                            title: value.name,
                            selected: currentValues ? currentValues.findIndex(function (currentValue) {
                                // eslint-disable-next-line eqeqeq
                                return currentValue == value.id; // Note: Don't use === as we can't ensure to have the same types
                            }) !== -1 : false,
                            clickable: true,
                            value: value
                        };
                    }),
                    title: _("misc.group-selection"),
                    emptyConfig: {
                        message: _("misc.no-group-selection"),
                        noCircle: true,
                        translucent: true,
                        elementIsNotClickable: true
                    },
                    radioMode: radioMode,
                    mode: mode,
                    deferred: def
                };
            NavigationComp.showSelector(details).then(function (results) {
                var value,
                    allVals = this._getCurrentValues() || [""];

                if (this.capabilityDetails[valueIndex].type === AutomaticDesignerEnums.CAPABILITY_TYPES.ANALOG_MULTI_SELECT) {
                    value = results.map(function (result) {
                        return result.option.value.id;
                    });

                    if (!this.content.editMode) {
                        value = [value];
                    }
                } else {
                    value = results[0].option.value.id;
                }

                if (this.capability.type === AutomaticDesignerEnums.ENTRY_TYPES.CALLER || this.capability.type === AutomaticDesignerEnums.ENTRY_TYPES.TTS) {
                    value = [allVals[0]].concat(value);
                }

                this._handleOnValueChange(value, valueIndex);
            }.bind(this));
        }
    }

    _showPicker(currentValue, valueIndex, xPath, button) {
        var originElm =
                /*button ? button.getElement()[0] :*/
                null,
            details = this.capabilityDetails[valueIndex];

        switch (details.type) {
            case AutomaticDesignerEnums.CAPABILITY_TYPES.TIME:
                currentValue = [Math.floor(currentValue / 60), currentValue % 60];

                this.ViewController._showContextMenu({
                    type: GUI.LxTimePickerContextMenu.TYPE.TIME,
                    value: currentValue,
                    onPickerChanged: function (value) {
                        value = value[0] * 60 + value[1];

                        if (details.hasOwnProperty("factor")) {
                            value /= this.capabilityDetails[valueIndex].factor;
                        }

                        this._handleOnValueChange(value, valueIndex);
                    }.bind(this)
                }, _('mobiscroll.clocktime'), originElm, GUI.LxTimePickerContextMenu);

                break;

            case AutomaticDesignerEnums.CAPABILITY_TYPES.DATE:
                currentValue = moment.unix(currentValue);

                this.ViewController._showContextMenu({
                    type: GUI.LxTimePickerContextMenu.TYPE.DATE,
                    value: currentValue,
                    onPickerChanged: function (value) {
                        value.set("hour", 0);
                        value.set("minute", 0);
                        value.set("second", 0);
                        value = value.unix();

                        if (details.hasOwnProperty("factor")) {
                            value /= this.capabilityDetails[valueIndex].factor;
                        }

                        this._handleOnValueChange(value, valueIndex);
                    }.bind(this)
                }, _('mobiscroll.date'), originElm, GUI.LxTimePickerContextMenu);

                break;

            case AutomaticDesignerEnums.CAPABILITY_TYPES.DURATION:
                var wheelOrder = AutomaticDesignerEnums.DURATION_WHEEL_ORDER; // Adaptive wheel order according to the min value

                if (details.hasOwnProperty("min") && details.min) {
                    if (details.min >= 60 * 60) {
                        wheelOrder = "hh";
                    }

                    if (details.min >= 60) {
                        wheelOrder = "hhii";
                    }
                } // Get an array of hour, minute, second as per the wheel order!


                currentValue = [Math.floor(currentValue / (60 * 60)), Math.floor(currentValue % (60 * 60) / 60), Math.floor(currentValue % 60)];

                this.ViewController._showContextMenu({
                    type: GUI.LxTimePickerContextMenu.TYPE.DURATION,
                    value: currentValue,
                    wheelOrder: wheelOrder,
                    minTime: details.hasOwnProperty("min") ? details.min * 1000 : null,
                    maxTime: details.hasOwnProperty("max") ? details.max * 1000 : null,
                    onPickerChanged: function (value) {
                        // Get seconds
                        value = value[0] * 60 * 60 + value[1] * 60 + value[2];

                        if (details.hasOwnProperty("factor")) {
                            value /= this.capabilityDetails[valueIndex].factor;
                        }

                        this._handleOnValueChange(value, valueIndex);
                    }.bind(this)
                }, _("mobiscroll.time"), originElm, GUI.LxTimePickerContextMenu);

                break;

            default:
        }
    }

    // Apply changes to the Rule
    _applyToRule() {
        var applyPrms;

        if (this.content.screenType === AutomaticDesignerEnums.SCREEN_TYPES.EVENTS) {
            applyPrms = this._applyEventToRule() || Q(true);
        } else {
            applyPrms = this._applyActionToRule() || Q(true);
        }

        applyPrms.then(function () {
            if (!this.content.editMode) {
                this._rightSideButton.getElement().find(".right-side-container__icon-placeholder").html(ImageBox.getResourceImageWithClasses(Icon.CHECKED, "icon-placeholder__icon icon-placeholder__icon--checked"));
                /*if (this._applyRuleAnimationTimeout) {
                    clearTimeout(this._applyRuleAnimationTimeout);
                }
                 this._applyRuleAnimationTimeout = setTimeout(function() {
                    this._rightSideButton.getElement().find(".right-side-container__icon-placeholder").html(Template.getRightIcon(this.content.editMode));
                }.bind(this), 500);*/


                HapticFeedback(HapticFeedback.STYLE.SELECT);

                if (this.ViewController instanceof GUI.AutomaticDesignerSearchViewController) {
                    // We need to dismiss both ViewControllers when finished
                    this.ViewController.dismiss();
                    this.ViewController.ViewController.dismiss();
                } else {
                    this.ViewController.dismiss();
                }
            }
        }.bind(this));
    }

    _applyEventToRule() {
        var operator = this._getCurrentOperator(),
            rawValues = this._getCurrentValues(),
            value = typeof rawValues !== "undefined" ? rawValues[0] : null,
            event = {
                descID: this.content.descID,
                type: this.capability._adType
            };

        if (this.content.editMode) {
            try {
                var ogEvent = AutomaticDesignerComponent.getCurrentEditRule().events[this.content.entryIdx];

                if (ogEvent.hasOwnProperty("name")) {
                    event.name = ogEvent.name;
                }
            } catch (e) {// Nothing to do
            }
        }

        if (this._operatorButtons.length) {
            event.operator = operator;
        } else if (typeof operator !== "undefined") {
            event.operator = operator;
        }

        if (value === null && this.capability.hasOwnProperty("sourceValue")) {
            value = this.capability.sourceValue;
        }

        if (value !== null) {
            if (value instanceof Array) {
                event[AutomaticDesignerEnums.VAL_KEYS.ARR_VALUES] = value;
            } else {
                event[AutomaticDesignerEnums.VAL_KEYS.VALUE] = value;
            }
        }

        if (this.knownTypes.FUNCTIONS.INT.includes(this.capability._adType) || this.knownTypes.ROOMS.INT.includes(this.capability._adType) || this.knownTypes.CATS.INT.includes(this.capability._adType)) {
            event.controlUUID = this.content.lastSelectedEntry.uuidAction;
            event.id = this.content.lastSelectedEntry.states[this.capability.sourceState] || ""; // if no state exists, we have to send an empty string
        } else if (this.knownTypes.WEATHER.INT.includes(this.capability._adType)) {
            event.id = this.content.lastSelectedEntry.id;
        } else if (this.knownTypes.OPERATING_MODES.INT.includes(this.capability._adType)) {
            event.id = this.content.lastSelectedEntry.id;
        } else if (this.knownTypes.TIMES.INT.includes(this.capability._adType)) {
            event.id = this.content.intType;
        }

        AutomaticDesignerComponent.addEventToRule(event);
    }

    _applyActionToRule() {
        var values = this._getCurrentValues(),
            action = {
                descID: this.content.descID,
                type: this.capability._adType
            },
            authPrms = Q(true);

        if (this.content.editMode) {
            try {
                var ogAction = AutomaticDesignerComponent.getCurrentEditRule().actions[this.content.entryIdx];

                if (ogAction.hasOwnProperty("name")) {
                    action.name = ogAction.name;
                }
            } catch (e) {// Nothing to do
            }
        }

        if (this.capability.type === AutomaticDesignerEnums.ENTRY_TYPES.CALLER || this.capability.type === AutomaticDesignerEnums.ENTRY_TYPES.TTS) {
            values = [].concat.apply([], values); // Flatten the map
        }

        if (typeof values !== "undefined") {
            action[AutomaticDesignerEnums.VAL_KEYS.ARR_VALUES] = values;
        }

        if (this.capability.hasOwnProperty("action")) {
            action.command = this.capability.action.replace(/<val(\d+)>/gm, function (fullMatch, index) {
                if (typeof values !== "undefined" && typeof values[index] !== "undefined") {
                    return values[index];
                } else {
                    return fullMatch;
                }
            });
        }

        if (this.knownTypes.FUNCTIONS.INT.includes(this.capability._adType) || this.knownTypes.SCENES.INT.includes(this.capability._adType) || this.knownTypes.ROOMS.INT.includes(this.capability._adType) || this.knownTypes.CATS.INT.includes(this.capability._adType)) {
            action.id = this.content.lastSelectedEntry.uuidAction;
            authPrms = AutomaticDesignerComponent.acquireVisuPasswordForObj({
                isSecured: this.content.lastSelectedEntry.isSecured,
                uuid: this.content.lastSelectedEntry.uuidAction
            });
        } else if (this.knownTypes.OPERATING_MODES.INT.includes(this.capability._adType)) {
            action.id = this.content.lastSelectedEntry.id;
        }

        return authPrms.then(function () {
            AutomaticDesignerComponent.addActionToRule(action);
        });
    }

    // Remove from rule
    _removeFromRule() {
        if (this.content.screenType === AutomaticDesignerEnums.SCREEN_TYPES.EVENTS) {
            AutomaticDesignerComponent.removeEventFromRule(this.content.groupIdx, this.content.entryIdx);
        } else {
            AutomaticDesignerComponent.removeActionFromRule(this.content.entryIdx);
        }

        HapticFeedback(HapticFeedback.STYLE.SELECT);
        this._preventValidityChange = true;
        this.delegate.didRemoveCapability && this.delegate.didRemoveCapability.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView, this, this.cellXPath);
    }

    // Helper functions
    _getWordDiv(phrase, cssClass) {
        if (phrase) {
            var words = phrase.split(/([ ])/),
                firstIdx = 0,
                lastIdx = words.length - 1;
            return words.filter(function (word) {
                // Filter out any empty strings just in case we split " " which will result in an array of  "", " ", ""
                return !!nullEmptyString(word);
            }).map(function (word, idx) {
                var div = $('<div class="' + cssClass + '">' + '   <div class="' + cssClass.split("__").pop() + '__background"></div>' + '   <div class="' + cssClass.split("__").pop() + '__text">' + word + '</div>' + '</div>');

                if (idx === firstIdx) {
                    div.addClass(cssClass + "--first");
                }

                if (idx === lastIdx) {
                    div.addClass(cssClass + "--last");
                }

                return div;
            });
        } else {
            return [$("<div class='" + cssClass + "'/>")];
        }
    }

    _getValueButtonElements(text, idx) {
        return this._getWordDiv(text, "text-container__title-container__interaction-element").map(function (wordDiv) {
            wordDiv.attr("id", "var-" + idx);
            return wordDiv;
        });
    }

    _getCurrentValues() {
        var values;

        if (this.content.editMode) {
            if (this.content.screenType === AutomaticDesignerEnums.SCREEN_TYPES.EVENTS) {
                values = AutomaticDesignerComponent.getEventValueAtIndex(this.content.groupIdx, this.content.entryIdx);
            } else {
                values = AutomaticDesignerComponent.getActionValue(this.content.entryIdx);

                if (this.capability.type === AutomaticDesignerEnums.ENTRY_TYPES.CALLER || this.capability.type === AutomaticDesignerEnums.ENTRY_TYPES.TTS) {
                    values = [values[0], values.slice(1)];
                }
            }
        } else {
            values = AutomaticDesignerComponent.getValuesForXPath(this.cellXPath);
        }

        return values;
    }

    _setDefaultValueIfNeeded(valueIndex) {
        var values = this._getCurrentValues(),
            isNotNeeded = typeof values !== "undefined" && values.length > valueIndex,
            defaultValue; // do specific validation here


        if (this.content.descID === AutomaticDesignerEnums.KNOWN_DESC_IDS.AUDIO_ZONE_V2_FAV) {
            try {
                // Simply check if the favorite is still available set it to empty if now
                this.constructor.getUserFriendlyValue(this._getCurrentValues(), valueIndex, this.capability, this.content.lastSelectedEntry);
            } catch (e) {
                this._handleOnValueChange(undefined, valueIndex, true);

                isNotNeeded = false;
            }
        }

        if (!isNotNeeded) {
            defaultValue = this.constructor.getDefaultValue(this.capability, valueIndex, this.content.lastSelectedEntry);

            if (typeof defaultValue !== "undefined") {
                this._handleOnValueChange(defaultValue, valueIndex, true);
            } else {
                this._handleOnValidityChange(false, valueIndex);
            }
        }
    }

    _getCurrentOperator() {
        var operator;

        if (this.content.editMode) {
            operator = AutomaticDesignerComponent.getEventOperator(this.content.groupIdx, this.content.entryIdx);
        } else {
            operator = AutomaticDesignerComponent.getOperatorForXPath(this.cellXPath);
        }

        if (typeof operator === "undefined") {
            if (this.capability.hasOwnProperty("operators") && this.capability.operators.length >= 1) {
                operator = this.capability.operators[0];
            } else {
                operator = AutomaticDesignerEnums.VALUE_OPERATORS.EQUAL;
            }

            this._handleOnOperatorChange(operator);
        }

        return operator;
    }

    _detectError(knownType) {
        this.capabilityError = this._getErrorForCapability(knownType);

        if (this.content.descID === AutomaticDesignerEnums.UNKNOWN_CAPABILITY_DESC_ID) {
            this.capabilityError = this.constructor.CAPABILITY_ERROR.NOT_FOUND;
        }

        if (this.capabilityError !== this.constructor.CAPABILITY_ERROR.NONE) {
            this.element.css("background-color", applyAlphaChannelToColorString(Color.ORANGE, 0.4));
            this.content.clickable = true;
            this.element.addClass("automatic-designer-cell__with-error");
        } else {
            this.element.css("background-color", " ");
        }
    }

    _handleOnErrorTapped() {
        switch (this.capabilityError) {
            case this.constructor.CAPABILITY_ERROR.NOT_FOUND:
                NavigationComp.showPopup({
                    title: _("automatic-designer.rule.entry.error.missing-object.title"),
                    message: _("automatic-designer.rule.entry.error.missing-object.message", {
                        objectType: _("search.function")
                    }),
                    icon: Icon.WARNING,
                    color: window.Styles.colors.orange,
                    buttonOk: true
                });
                break;

            case this.constructor.CAPABILITY_ERROR.NOT_CONFIGURED:
                NavigationComp.showErrorPopup(false, _("automatic-designer.rule.entry.error.incomplete-config.title"), _("automatic-designer.rule.entry.error.incomplete-config.message"));
                break;

            case this.constructor.CAPABILITY_ERROR.SYSTEM_STATE_MESSAGE:
                if (this._activeSystemStateMessages[0]) {
                    this.ViewController.showState(ScreenState.MessageCenterMessageScreen, null, {
                        entry: this._activeSystemStateMessages[0]
                    }, AnimationType.FADE);
                }

                break;
        }
    }

    _getErrorForCapability(knownType) {
        var error = this.constructor.CAPABILITY_ERROR.NONE;

        if (this.content.screenType === AutomaticDesignerEnums.SCREEN_TYPES.EVENTS) {
            switch (knownType.STRING) {
                case this.knownTypes.TIMES.STRING:
                    break;

                case this.knownTypes.ROOMS.STRING:
                case this.knownTypes.CATS.STRING:
                case this.knownTypes.FUNCTIONS.STRING:
                    error = this._getCapabilityErrorForControl();
                    break;

                case this.knownTypes.WEATHER.STRING:
                    error = this._getCapabilityErrorForWeatherField();
                    break;

                case this.knownTypes.OPERATING_MODES.STRING:
                    error = this._getCapabilityErrorForOPMode();
                    break;
            }
        } else {
            switch (knownType.STRING) {
                case this.knownTypes.OPERATING_MODES.STRING:
                    error = this._getCapabilityErrorForOPMode();
                    break;

                case this.knownTypes.ROOMS.STRING:
                case this.knownTypes.CATS.STRING:
                case this.knownTypes.FUNCTIONS.STRING:
                    error = this._getCapabilityErrorForControl();
                    break;

                case this.knownTypes.NOTIFICATIONS.STRING:
                    error = this._getCapabilityErrorForNotification();
                    break;

                case this.knownTypes.SCENES.STRING:
                    break;

                case this.knownTypes.SCRIPTS.STRING:
                    break;
            }
        }

        return error;
    }

    _getCapabilityErrorForControl() {
        if (this.content.lastSelectedEntry) {
            if (this.content.lastSelectedEntry.hasOwnProperty("uuidAction")) {
                if (!this.content.lastSelectedEntry.isConfigured()) {
                    return this.constructor.CAPABILITY_ERROR.NOT_CONFIGURED;
                } else {
                    return this.constructor.CAPABILITY_ERROR.NONE;
                }
            } else {
                return this.constructor.CAPABILITY_ERROR.NOT_FOUND;
            }
        } else {
            return this.constructor.CAPABILITY_ERROR.NONE;
        }
    }

    _getCapabilityErrorForOPMode() {
        if (this.content.lastSelectedEntry) {
            if (this.content.lastSelectedEntry.hasOwnProperty("id")) {
                if (!ActiveMSComponent.getStructureManager().getOperatingModes(this.content.lastSelectedEntry.id)) {
                    return this.constructor.CAPABILITY_ERROR.NOT_FOUND;
                } else {
                    return this.constructor.CAPABILITY_ERROR.NONE;
                }
            } else {
                return this.constructor.CAPABILITY_ERROR.NOT_FOUND;
            }
        } else {
            return this.constructor.CAPABILITY_ERROR.NOT_FOUND;
        }
    }

    _getCapabilityErrorForWeatherField() {
        if (this.content.lastSelectedEntry) {
            if (ActiveMSComponent.getStructureManager().getWeatherServer()) {
                if (typeof this.content.lastSelectedEntry.id !== "undefined" && ActiveMSComponent.getStructureManager().getWeatherFieldTypes(this.content.lastSelectedEntry.id)) {
                    this._activeSystemStateMessages = MessageCenterHelper.findActiveEntriesWithSourceUuid(ActiveMSComponent.getStructureManager().getWeatherServer().states.actual).filter(function (entry) {
                        return [MessageCenterHelper.KNOWN_ACTION_ID.WEATHER_EXPIRED, MessageCenterHelper.KNOWN_ACTION_ID.NO_ACTIVE_WEATHER].includes(entry.eventId);
                    }.bind(this));

                    if (this._activeSystemStateMessages && this._activeSystemStateMessages.length) {
                        return this.constructor.CAPABILITY_ERROR.SYSTEM_STATE_MESSAGE;
                    } else {
                        return this.constructor.CAPABILITY_ERROR.NONE;
                    }
                } else {
                    return this.constructor.CAPABILITY_ERROR.NOT_FOUND;
                }
            } else {
                return this.constructor.CAPABILITY_ERROR.NOT_FOUND;
            }
        } else {
            return this.constructor.CAPABILITY_ERROR.NOT_FOUND;
        }
    }

    _getCapabilityErrorForNotification() {
        if (this.content.lastSelectedEntry) {
            if (this.content.lastSelectedEntry._apType === AutomaticDesignerEnums.ACTION_TYPES.CALLER) {
                this._activeSystemStateMessages = [].concat.apply([], Object.values(ActiveMSComponent.getStructureManager().getCallerServices()).map(function (caller) {
                    return MessageCenterHelper.findActiveEntriesWithSourceUuid(caller.id).filter(function (entry) {
                        return [MessageCenterHelper.KNOWN_ACTION_ID.CALLER_EXPIRED, MessageCenterHelper.KNOWN_ACTION_ID.NO_ACTIVE_CALLER].includes(entry.eventId);
                    }.bind(this));
                }));

                if (this._activeSystemStateMessages && this._activeSystemStateMessages.length) {
                    return this.constructor.CAPABILITY_ERROR.SYSTEM_STATE_MESSAGE;
                } else {
                    return this.constructor.CAPABILITY_ERROR.NONE;
                }
            } else {
                return this.constructor.CAPABILITY_ERROR.NONE;
            }
        } else {
            return this.constructor.CAPABILITY_ERROR.NONE;
        }
    }

}

GUI.TableViewV2.Cells.AutomaticDesignerCell = AutomaticDesignerCell;
