'use strict';

define(['ControlSetting', 'ControlPresetSetting'], function (ControlSetting, ControlPresetSetting) {
    return class ControlSettingsUtil {
        constructor(details) {
            this.object = details.object;
            this.objectUUID = details.objectUUID;
            this.objectName = details.objectName;
            this.permission = details.permission;
            this.lightMode = this.permission === MsPermission.EXPERT_MODE_LIGHT;
        }

        /**
         * Loads settings from the current object. Even when expert-mode light is used, at some point data needs to be
         * loaded from the Miniserver, e.g. room/category symbols
         * @param useStructureData   false = load from the MS via Expert-Mode API (permission may still be light)
         * @returns {*}
         */
        getSettings(useStructureData) {
            return useStructureData ? this.getSettingsForLightMode(this.object) : this.getControlSettings(this.objectUUID, this.permission);
        }

        getControlSettings(objectUuid, permission) {
            return ActiveMSComponent.getSettingsConfigForObject(objectUuid, permission).then(this._prepareSettingObjects.bind(this));
        }

        getSettingsForLightMode(object) {
            return ActiveMSComponent.getLightSettingsConfigForObject(object).then(this._prepareSettingObjects.bind(this));
        }

        saveSettings() {
            return ActiveMSComponent.saveSettingsForObject(this.objectUUID, this.objectName);
        }

        showHelpFootNote() {
            return !this.lightMode;
        }

        get showSortingHintFooter() {
            return true;
        }

        _prepareSettingObjects(settingsConfig) {
            // initialize the ControlSetting objects..
            Object.values(settingsConfig).forEach(function (settings) {
                settings.forEach(function (setting, idx) {
                    if (setting.id === ControlSettingID.CONTROL_PRESET) {
                        settings[idx] = new ControlPresetSetting(setting);
                    } else {
                        settings[idx] = new ControlSetting(setting);
                    }
                });
            });
            return settingsConfig;
        }

    };
});
