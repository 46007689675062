import globalStyles from "GlobalStyles";
import {View} from "react-native";
import {LxReactButton, ButtonType, useNopeAreaForRef} from "LxComponents";
import { StyleSheet } from "react-native-web"
import {useCallback, useEffect, useState, useRef} from "react";
import LxReactWaitingIndicator from "../LxReactWaitingIndicator";
import {useConnectionReady} from "../Components";


export default function LxReactDeviceSearchRefresh({style, searchType, resultsShown = false}) {

    const ref = useRef(null);
    useNopeAreaForRef(ref);
    const [restartPending, setRestartPending] = useState(false);
    const connectionReady = useConnectionReady(true);

    const handleRefreshPressed = useCallback(() => {
        ActiveMSComponent.getAvailableExtensions(true);
        if (ActiveMSComponent.getDeviceSearchingState()) {
            setRestartPending(true);
            ActiveMSComponent.restartDeviceSearch().finally(() => {
                setRestartPending(false);
            });
        }
    }, []);

    const [canRefresh, setCanRefresh] = useState(false);
    useEffect(() => {
        if (searchType === DeviceManagement.TYPE.AIR) {
            setCanRefresh(resultsShown);
        } else {
            setCanRefresh(true);
        }
    }, [searchType, resultsShown])


    return <View key={"refresh"} style={[StyleSheet.flatten(Styles.rootCntr), StyleSheet.flatten(style)]}>
        {
            canRefresh
                ?
                <View style={Styles.buttonCntr} ref={ref}>
                    {
                        restartPending ?
                            <View style={Styles.refreshButton}>
                                <LxReactWaitingIndicator size="small" style={Styles.waitingIndicator} />
                            </View>
                            :
                            <LxReactButton
                                containerStyle={Styles.refreshButton}
                                type={ButtonType.SECONDARY}
                                disabled={!connectionReady}
                                onPress={handleRefreshPressed}
                                text={_("device-learning.search-again")} />
                    }
                </View>
                :
                null
        }

    </View>
}

const Styles = {
    rootCntr: {
        ...globalStyles.borders.top,
        backgroundColor: globalStyles.colors.grey["700"],
        width: "100%"
    },
    buttonCntr: {
    },
    refreshButton: {
        backgroundColor: globalStyles.colors.fill.primary,
        marginHorizontal: 24,
        marginTop: 16,
        marginBottom: 32,
        borderRadius: 4,
    },
    waitingIndicator: {
        paddingVertical: 16,
        paddingHorizontal: 24,
        justifySelf: "center",
        alignSelf: "middle"
    }
}