'use strict';
/*
 iconSrc         // left icon
 leftIconBgEnabled
 iconColor       // color of left icon
 title
 subtitle
 buttonSrc       // touchable icon (default a pushbutton)
 button2Src       // touchable icon (default a pushbutton)
 color           // color of the touchable icon
 color2           // color of the touchable icon
 disclosureText  // text on the right side in front of the button

 buttonTapped(section, row, tableView)
 buttonHit(section, row, tableView)
 buttonReleased(section, row, tableView)
 button2Tapped(section, row, tableView)
 button2Hit(section, row, tableView)
 button2Released(section, row, tableView)
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.BUTTON = "ButtonCell";

    class ButtonCell extends GUI.TableViewV2.Cells.EditableBaseCell {
        //region Static
        static Template = function () {
            var getButtonCellContent = function getButtonCellContent(content) {
                var icon = '',
                    subtitle = '',
                    disclosureExtension = '',
                    stateIcon = '',
                    button = '',
                    button2 = '';

                if (content.iconSrc) {
                    icon = '<div class="content__icon-placeholder ' + (content.leftIconBgEnabled ? "icon-placeholder__icon--with-background" : "") + '">' + getImage(content.iconSrc, "icon-placeholder__icon") + '</div>';
                }

                if (content.subtitle) {
                    subtitle = '<div class="texts-placeholder__subtitle">' + '   <div class="subtitle__text text-overflow-ellipsis">' + content.subtitle + '</div>' + '</div>';
                }

                if (content.disclosureText) {
                    disclosureExtension = '<div class="content__disclosure-text">' + content.disclosureText + '</div>';
                }

                if (content.buttonSrc) {
                    button = ImageBox.getResourceImageWithClasses(content.buttonSrc, "button__icon");
                } else if (content.buttonText) {
                    button = '<div class="button__text">' + content.buttonText + '</div>';
                }

                if (content.button2Src) {
                    button2 = '<div class="content__button">' + ImageBox.getResourceImageWithClasses(content.button2Src, "button__icon") + '</div>';
                }

                if (content.stateIconSrc) {
                    stateIcon = '<div class="content__state-icon">' + ImageBox.getResourceImageWithClasses(content.stateIconSrc, "state__icon") + '</div>';
                }

                return $(icon + '<div class="content__texts-placeholder">' + '<div class="texts-placeholder__title">' + '<div class="title__text text-overflow-ellipsis">' + content.title + '</div>' + '</div>' + subtitle + '</div>' + disclosureExtension + stateIcon + '<div class="content__button">' + button + '</div>' + button2);
            };

            var getImage = function getImage(src, classes, force) {
                var img;

                if (src.startsWith("resources/") && src.indexOf(".svg") !== -1) {
                    img = ImageBox.getResourceImageWithClasses(src, classes);
                } else {
                    img = '<img class="' + classes + '" src="' + src + '">';
                }

                if (force) {
                    ImageBox.getImageElement(src, classes).then(function (res) {
                        this.element && this.element.find('.' + classes).html(res[0]);
                    }.bind(this));
                }

                return img;
            };

            return {
                getButtonCellContent: getButtonCellContent
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("button-cell");
        }

        viewDidLoad() {
            var prms = super.viewDidLoad(...arguments);
            this.contentPlaceholder.append(ButtonCell.Template.getButtonCellContent(this.content));

            if (Debug._CYPRESS_TESTS_ACTIVE) {
                this.element.attr("data-title", this.content.title);
            }

            this.elements = Object.assign(this.elements, {
                iconPlaceholder: this.contentPlaceholder.find('.content__icon-placeholder'),
                icon: this.contentPlaceholder.find('.icon-placeholder__icon'),
                title: this.contentPlaceholder.find('.texts-placeholder__title .title__text'),
                subtitle: this.contentPlaceholder.find('texts-placeholder__subtitle .subtitle__text'),
                buttons: this.contentPlaceholder.find('.content__button'),
                stateIcon: this.contentPlaceholder.find('.content__state-icon')
            });

            this._addButton(this.elements.buttons[0], 0);

            if (this.content.button2Src) {
                this._addButton(this.elements.buttons[1], 1);
            }

            if (this.content.stateIconColor && this.elements.stateIcon.length > 0) {
                $(this.elements.stateIcon[0]).children('.state__icon').css('fill', this.content.stateIconColor);
            }

            if (this.content.color) {
                $(this.elements.buttons[0]).children('.button__icon').css('fill', this.content.color);
            }

            if (this.content.color2) {
                $(this.elements.buttons[1]).children('.button__icon').css('fill', this.content.color2);
            }

            if (this.content.iconColor) {
                this.elements.icon.css("fill", this.content.iconColor);
            }

            return prms;
        }

        clickableAllowed() {
            return true;
        }

        _addButton(element, buttonNr) {
            var button = new GUI.LxButton(this, element, Color.BUTTON_GLOW);
            button.useChildsAsActiveParts("fill");
            this.addToHandledSubviews(button);

            button.onButtonTapped = function onButtonTapped() {
                if (buttonNr === 0) {
                    this.delegate.buttonTapped && this.delegate.buttonTapped.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
                } else {
                    this.delegate.button2Tapped && this.delegate.button2Tapped.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
                }
            };

            button.onButtonHit = function onButtonHit() {
                if (buttonNr === 0) {
                    this.delegate.buttonHit && this.delegate.buttonHit.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
                } else {
                    this.delegate.button2Hit && this.delegate.button2Hit.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
                }
            };

            button.onButtonReleased = function onButtonReleased() {
                if (buttonNr === 0) {
                    this.delegate.buttonReleased && this.delegate.buttonReleased.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
                } else {
                    this.delegate.button2Released && this.delegate.button2Released.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
                }
            };

            button.onButtonTicked = function onButtonTicked() {
                if (buttonNr === 0) {
                    this.delegate.onButtonTicked && this.delegate.onButtonTicked.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
                } else {
                    this.delegate.button2Ticked && this.delegate.button2Ticked.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
                }
            };
        }

    }

    GUI.TableViewV2.Cells.ButtonCell = ButtonCell;
    return GUI;
}(window.GUI || {});
