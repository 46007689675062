'use strict';

define(['AudioZoneControlEnums', 'HardwareVolumeButtonHandler'], function (AudioZoneControlEnums, HardwareVolumeButtonHandler) {
    return class TinyPlayer extends GUI.View {
        //region Static
        static Template = function () {
            var getControlsBar = function getContainer() {
                return '' + '<div class="tiny-player__controls">' + '   <div class="controls__progress-bar">' + '       <div class="progress-bar__item"></div>' + '   </div>' + '   <div class="controls__action-section">' + '       <div class="action-section__right">' + '           <div class="right__cover-container right__cover-container--default">' + '           ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.NOTE, "cover__icon") + '           </div>' + '           <div class="right__track-info-container">' + '               <div class="track-info-container__track-title track-title"></div>' + '               <div class="track-info-container__artist-name artist-name"></div>' + '           </div>' + '       </div>' + '       <div class="action-section__buttons">' + '           <div class="buttons__play-state clickable">' + '           ' + ImageBox.getResourceImageWithClasses(Icon.INDICATOR, "item__icon") + '           </div>' + '           <div class="buttons__next clickable">' + '           ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.NEXT, "item__icon") + '           </div>' + '       </div>' + '   </div>' + '   <div class="controls__bottom-section">' + '      <div class="controls__slider-container volume-slider"></div>' + '      <div class="buttons__group clickable">' + '           ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.GROUPED, "item__icon") + '      </div>' + '   </div>' + '</div>';
            };

            return {
                getControlsBar: getControlsBar
            };
        }(); //endregion Static

        constructor(control) {
            super($('<div/>'));
            Object.assign(this, StateHandler.Mixin);
            this.control = control;
        }

        setViewController(vc) {
            super.setViewController(vc);
            this.element.removeClass("uses-react-safe-area");
        }

        viewDidLoad() {
            this.prepareUI();
            this.volumeControl = new Controls.AudioZoneControl.AudioZoneControlVolumeControl(this.control);
            this.volumeControl.getElement().addClass('action-view__bottom-bar action-view__bar');
            this.appendSubview(this.volumeControl, this.elements.volumeControllerSlider);
            this.hardwareVolHandler = new HardwareVolumeButtonHandler(this.control);
            Object.values(this.buttons).map(this.addToHandledSubviews.bind(this));
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                this.element.toggleClass("tiny-player--restricted", MediaServerComp.isRestricted());

                if (this.control.details.clientType === MediaEnum.ClientType.UPNP) {
                    this.hideSubview(this.buttons.groupButton);
                }
            }.bind(this));
        }

        getTemplate() {
            return TinyPlayer.Template.getControlsBar();
        }

        prepareUI() {
            this.element.append(this.getTemplate());
            this.elements = {};
            this.elements.actionSection = this.element.find('.controls__action-section');
            this.elements.coverIcon = this.element.find(".cover__icon");
            this.elements.playStateButton = this.element.find('.buttons__play-state');
            this.elements.nextButton = this.element.find('.buttons__next');
            this.elements.groupButton = this.element.find('.buttons__group');
            this.elements.trackTitle = this.element.find('.track-title');
            this.elements.artistName = this.element.find('.artist-name');
            this.elements.volumeControllerSlider = this.element.find('.volume-slider');
            this.elements.controlsProgressBar = this.element.find('.controls__progress-bar');
            this.elements.progressBarItem = this.element.find('.progress-bar__item');
            this.buttons = {
                nextButton: new GUI.LxButton(this, this.elements.nextButton[0], Color.BUTTON_GLOW),
                playStateButton: new GUI.LxButton(this, this.elements.playStateButton[0], Color.BUTTON_GLOW),
                groupButton: new GUI.LxButton(this, this.elements.groupButton[0], Color.BUTTON_GLOW)
            };
            if (this.elements.actionSection[0]) {
                this.buttons.playerButton = new GUI.LxButton(this, this.elements.actionSection[0], Color.BUTTON_GLOW);
            }
        }

        viewWillAppear() {
            $(document.body).addClass("audio-zone-appeared");
            super.viewWillAppear(...arguments);

            this._registerForStates();

            Debug.TinyPlayer && console.log('TinyPlayer registered for state changes for UUID: ' + this.control.uuidAction);
            var buttonKeys = Object.keys(this.buttons);

            for (var i = 0; i < buttonKeys.length; i++) {
                var btnKey = buttonKeys[i];
                var button = this.buttons[btnKey];
                button.onButtonTapped = this._handleButtonTapped.bind(this);
            }

            this.hardwareVolHandler.start();
        }

        viewWillDisappear() {
            this._unregisterStates();

            this.hardwareVolHandler.stop();
            Debug.TinyPlayer && console.log('TinyPlayer unregistered for state changes for UUID: ' + this.control.uuidAction);
            super.viewWillDisappear(...arguments);
        }

        destroy() {
            $(document.body).removeClass("audio-zone-appeared");
            this.hardwareVolHandler.destroy();
            super.destroy(...arguments);
        }

        sendMiniserverCommand(cmd) {
            SandboxComponent.sendCommand(this, this.control.uuidAction, cmd, null, this.control.isSecured, null, false);
        }

        //----------------------
        // Private Section
        //----------------------
        receivedStates(states) {
            this.states = states;
            Debug.Control.AudioZone.TinyPlayer && console.log("TinyPlayer TIME:  " + states.time + 's');
            Debug.Control.AudioZone.TinyPlayer && console.log("TinyPlayer Calc Width:  " + states.time * 100 / states.duration + '%');
            Debug.Control.AudioZone.TinyPlayer && console.log("TinyPlayer Track Title:   " + states.texts.mainText);
            Debug.Control.AudioZone.TinyPlayer && console.log("TinyPlayer Track Artist:   " + states.texts.subText);
            Debug.Control.AudioZone.TinyPlayer && console.log("TinyPlayer Current Volume:   " + states.volume);

            if (!MediaServerComp.isRestricted()) {
                this._setCover(states);
            }

            this._setTrackTitle(states);

            this._setArtist(states);

            this._setProgressBarDuration(states);

            if (states.isPlaying !== this.playingState) {
                this._updateTinyPlayer(states);

                this.playingState = states.isPlaying;
            }

            if (states.isSynced) {
                this._setGroupColor(states.syncColor);
            } else {
                this._setGroupColor(Color.WHITE);
            }
        }

        _setCover(states) {
            var iconObj;
            var platform = PlatformComponent.getPlatformInfoObj().platform;
            var version = parseFloat(PlatformComponent.getPlatformInfoObj().version);
            var canHandleSVGHref = !(platform === PlatformType.IOS && version <= 11);

            if (this.coverUrl !== states.coverurl) {
                // Use this function to also resolve the correct image for line in images
                iconObj = Controls.AudioZoneControl.MediaBrowserBase.getIconObjForItem(states);

                if (canHandleSVGHref) {
                    this._setSVGCover(iconObj);
                } else {
                    this._setOldIOSCover(iconObj);
                }

                this.coverUrl = states.coverurl;
            }
        }

        _setSVGCover(iconObj) {
            var svgStr;
            ImageBox.getImageElement(iconObj.lowRes, "cover__icon").then(function (res) {
                // Android 4.4.2 can't handle outerHTML, our Polyfill can only replace type 1 nodes, aka. nodes that can live in the DOM without any wrapper element (SVG "image" nodes)
                svgStr = "<svg viewBox='" + this.elements.coverIcon[0].getAttribute("viewBox") + "' class='cover__icon'>" + res[0].outerHTML + "</svg>";
                this.elements.coverIcon.replaceWith(svgStr);
                this.elements.coverIcon = this.element.find("svg.cover__icon"); // Check if it is a local SVG

                if (res[0].outerHTML.hasPrefix("<svg") && iconObj.lowRes.hasPrefix("resources/Images")) {
                    if (!iconObj.hasProvidedCover) {
                        res.css("fill", iconObj.color);
                        this.elements.coverIcon.parent().addClass("right__cover-container--default");
                    }
                } else {
                    this.elements.coverIcon.parent().removeClass("right__cover-container--default");
                }
            }.bind(this));
        }

        _setOldIOSCover(iconObj) {
            this.elements.coverIcon = this.element.find(".cover__icon");

            if (iconObj.lowRes.hasPrefix("resources/Images")) {
                this.elements.coverIcon.replaceWith(ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.NOTE, 'cover__icon'));
            } else {
                // Inline style is needed to prevent styling other objects
                this.elements.coverIcon.replaceWith("<img class='cover__icon' src='" + iconObj.lowRes + "' style='width: 100%; height: 100%;'/>");
            }
        }

        _setTrackTitle(states) {
            if (states.texts.mainText !== this.trackTitle) {
                this.elements.trackTitle.text(states.texts.mainText);
                this.trackTitle = states.texts.mainText;
            }
        }

        _setArtist(states) {
            if (states.texts.subText !== this.artistName) {
                this.elements.artistName.text(states.texts.subText);
                this.artistName = states.texts.subText;
            }
        }

        _setProgressBarDuration(states) {
            if (!!states.duration) {
                this.elements.progressBarItem.css('width', states.time * 100 / states.duration + '%');
            } else {
                this.elements.progressBarItem.css('width', '0%');
            }
        }

        _handleButtonTapped(sourceBtn) {
            if (!this.states) {
                return;
            }

            switch (sourceBtn) {
                case this.buttons.playerButton:
                    if (!MediaServerComp.isRestricted()) {
                        this.ViewController.showState(AudioZoneControlEnums.ScreenState.PLAYER, null, {
                            control: this.control
                        }, null, true);
                    }

                    break;

                case this.buttons.nextButton:
                    this._nextTrack();

                    break;

                case this.buttons.playStateButton:
                    this._togglePlayState();

                    break;

                case this.buttons.groupButton:
                    var details = {
                            control: this.control,
                            zone: {
                                playerid: this.control.details.playerid,
                                name: this.control.getName()
                            },
                            icon: Icon.AudioZone.GROUPED
                        },
                        showStateArgs = [AudioZoneControlEnums.ScreenState.GROUP_OVERVIEW, null, details];

                    if (HD_APP) {
                        this.ViewController.showState.apply(this.ViewController, showStateArgs);
                    } else {
                        return this.ViewController.showState(ScreenState.AudioZoneDetailedVC, null, {
                            showStateArgs: showStateArgs
                        }, AnimationType.FADE);
                    }

                    break;
            }
        }

        _prevTrack() {
            this.sendMiniserverCommand(Commands.AudioZone.Queue.PREV);
        }

        _nextTrack() {
            this.sendMiniserverCommand(Commands.AudioZone.Queue.NEXT);
        }

        _togglePlayState() {
            if (this.playingState) {
                this.sendMiniserverCommand(Commands.AudioZone.CLIENT.PAUSE);
            } else {
                this.sendMiniserverCommand(Commands.AudioZone.CLIENT.PLAY);
            }
        }

        _updateTinyPlayer(states) {
            if (states.isPlaying) {
                this.elements.playStateButton[0].innerHTML = ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PAUSE, 'play-state__icon item__icon active');
            } else {
                this.elements.playStateButton[0].innerHTML = ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PLAY, 'play-state__icon item__icon');
            }
        }

        _setGroupColor(color) {
            this.elements.groupButton.css("fill", color);
            this.buttons.groupButton.setInactiveColor(color);
        }

    };
});
