'use strict';

define("MediaClientControlCommandSrc", ["ControlCommandSrc", "JalousieControlEnums"], function (ControlCommandSrc, JalousieControlEnums) {
    return class MediaClientControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [];

            if (states.powerOn) {
                cmds.push(this.createCommand(null, Commands.MEDIACLIENT.PLAY, Icon.MediaClient.PLAY));
                cmds.push(this.createCommand(null, Commands.MEDIACLIENT.PAUSE, Icon.MediaClient.PAUSE));
                cmds.push(this.createCommand(_("off"), Commands.MEDIACLIENT.OFF, Icon.MediaClient.STOP));
                cmds.push(this.createCommand(null, Commands.MEDIACLIENT.SHUFFLE, Icon.MediaClient.SHUFFLE));
                cmds.push(this.createCommand(null, Commands.MEDIACLIENT.BACKWARD, Icon.MediaClient.BACKWARD));
                cmds.push(this.createCommand(null, Commands.MEDIACLIENT.FORWARD, Icon.MediaClient.FORWARD));

                this._prepareVolumeCommands(control, cmds);

                if (states.mute) {
                    cmds.push(this.createCommand(null, Commands.format(Commands.MEDIACLIENT.VOLUME, states.volume), Icon.MediaClient.SPEAKER_03));
                } else {
                    cmds.push(this.createCommand(null, Commands.MEDIACLIENT.MUTE, Icon.MediaClient.MUTE));
                }
            } else {
                cmds.push(this.createCommand(_("on"), Commands.MEDIACLIENT.ON, Icon.MediaClient.PLAY));
            }

            return cmds;
        }

        /**
         * Prepares and pushes the increase and decrease cmd objects onto the cmds array.
         * @param control
         * @param cmds
         * @private
         */
        _prepareVolumeCommands(control, cmds) {
            cmds.push(this.getVolumeDownCommand(control));
            cmds.push(this.getVolumeUpCommand(control));
        }

        /**
         * Returns a cmd object that allows to turn up the volume.
         * @param control
         * @return {*|{name: *, icon: *, cmd: *, details: *}}
         */
        getVolumeUpCommand(control) {
            var incCmdFn = this._createVolumeCommand.bind(this, control, false),
                doubleIncCmd = this._createSourcePlusCommand.bind(this, control),
                incCmdObj = this.createCmdObj(incCmdFn, incCmdFn, incCmdFn, doubleIncCmd, incCmdFn);

            return this.createCommand(null, incCmdObj, Icon.ValueSelector.PLUS);
        }

        /**
         * Returns a command object that allows to turn up the volume.
         * @param control
         * @return {*|{name: *, icon: *, cmd: *, details: *}}
         */
        getVolumeDownCommand(control) {
            var decrCmdFn = this._createVolumeCommand.bind(this, control, true),
                doubleDecCmd = Commands.MEDIACLIENT.OFF,
                decrCmdObj = this.createCmdObj(decrCmdFn, decrCmdFn, decrCmdFn, doubleDecCmd, decrCmdFn);

            return this.createCommand(null, decrCmdObj, Icon.ValueSelector.MINUS);
        }

        /**
         * Will return a cmd that increases or decreases the current volume by 1 percent.
         * @param control
         * @param down
         * @return {*|string}
         * @private
         */
        _createVolumeCommand(control, down) {
            var states = control.getStates(),
                stepUp = states.volumeStep,
                stepDown = stepUp * -1,
                newVol = states.volume + (down ? stepDown : stepUp);
            newVol = Math.max(newVol, 0);
            newVol = Math.min(newVol, states.maxVolume);
            return Commands.format(Commands.MEDIACLIENT.VOLUME, newVol);
        }

        /**
         * Will return the command that jumps to the next source.
         * @param control
         * @return {*|string}
         * @private
         */
        _createSourcePlusCommand(control) {
            var states = control.getStates(),
                sourceCnt = Object.values(states.sources).length,
                nxtSrc = states.activeSource % sourceCnt + 1;
            return Commands.format(Commands.MEDIACLIENT.SOURCE, nxtSrc);
        }

    };
});
