/**
 * Created by loxone on 20.06.16.
 */
'use strict';

define([], function () {
    class AddMediaViewControllerBase extends GUI.ModalViewController {
        constructor(details) {
            super(...arguments);
            this.details = details;
            this.targetMediaScreenState = details.targetMediaScreenState;
        }

        viewWillAppear() {
            var args = arguments,
                prms = [true];

            if (!this.didAlreadyShowState) {
                // add a dummy screen so that everything works correctly (HD_OVERLAY animation..)
                // Account for the dummy screen when navigatingBackToRoot...
                prms.push(this._handleAddInitialDummy());
                prms.push(super.showState(this.targetMediaScreenState, null, this.details, AnimationType.NONE));
                this.didAlreadyShowState = true;
            }

            return Q.all(prms).then(() => {
                return super.viewWillAppear(...args);
            });
        }

        getURL() {
            return this.details.control.name + "/addItem";
        }

        titleBarConfig() {
            return {
                rightSide: TitleBarCfg.TEXT,
                rightText: _("finish"),
                leftSide: TitleBarCfg.TEXT
            };
        }

        titleBarText() {
            return this.details.addTargetName || _("edit");
        }

        titleBarActionRight() {
            this.dismiss();
        }

        showState(identifier, view, details, animationType) {
            //It's not a controlContentViewController - so pass along the control here.
            details.control = this.details.control;
            return super.showState(...arguments);
        }

        navigateBackToRoot() {
            return super.navigateBackToRoot(1); // Account for the dummy view
        }

        navigateBack() {
            // check if we are HD and we did show a dummy view!
            if (this.history.length === 2) {
                return this.dismissModal();
            } else {
                return super.navigateBack(...arguments);
            }
        }

        destroyOnBackNavigation() {
            return true;
        }

        startEditMode() {// Can be overwritten in subclasses
        }

        addItem(item) {
            return MediaServerComp.addItem(item);
        }

        removeItem(idx) {
            return MediaServerComp.removeItem(idx);
        }

        moveItem(oldIdx, newIdx) {
            return MediaServerComp.moveItem(oldIdx, newIdx);
        }

        _checkTitlebar() {
            super._checkTitlebar(...arguments);

            if (this._titleBar) {
                this.element.addClass("view-controller--with-title-bar");
            }
        }

    }

    GUI.AddMediaViewControllerBase = AddMediaViewControllerBase;
    return GUI.AddMediaViewControllerBase;
});
