'use strict';

define(['AudioZoneV2ControlEnums'], function (AudioZoneV2ControlEnums) {
    class BluetoothSettings extends GUI.TableViewScreenV2 {

        constructor(details) {
            super($('<div />'));
            applyMixins(this, StateHandler.Mixin);
            this.details = details;
            this.control = details.control;
            this.states = cloneObjectDeep(this.control.getStates()); // used to locally keep track, will be modified.
        }

        get useNewTableView() {
            return true;
        }

        viewWillAppear() {
            this._registerForStates(this.control.uuidAction, ["bluetoothPairingEnabled"]);
            return super.viewWillAppear(...arguments);
        }

        viewWillDisappear() {
            this._unregisterStates();
            return super.viewWillDisappear(...arguments);
        }

        titleBarText() {
            return _("audio-server.settings.bluetooth.title");
        }

        receivedStates(states) {
            this.reloadTable();
        }

        reloadTable() {
            this.states = this.control.getStates();

            this.tableContent = [{
                rows: [this._getBluetoothEnabledCell()]
            }, {
                rows: [this._getPairingResetCell()],
            }];
            return super.reloadTable(...arguments);
        }

        _getBluetoothEnabledCell() {
            let setByLogic = this.control.bluetoothSetByLogic;

            if(setByLogic) {
                return {
                    content: {
                        disabled: true,
                        title: _("audio-server.settings.bluetooth.pairing-enabled"),
                        subtitle: _("controlled-via-logic"),
                        disclosureText: this.states.bluetoothPairingEnabled ? _("on") : _("off"),
                    }
                }
            }

            return {
                type: GUI.TableViewV2.CellType.SWITCH,
                content: {
                    title: _("audio-server.settings.bluetooth.pairing-enabled"),
                    active: this.states.bluetoothPairingEnabled,
                },
                onSwitchChanged: function (active, section, row, tableView, cell) {
                    var prms;
                    prms = this.control.sendCommand(Commands.format(Commands.AudioZone.BLUETOOTH_ON, active ? 1 : 0));
                    prms.finally(function () {
                        return this.reloadTable();
                    }.bind(this));
                }.bind(this)
            }
        }

        _getPairingResetCell() {
            return {
                type: GUI.TableViewV2.CellType.DELETE,
                content: {
                    title: _("audio-server.settings.bluetooth.reset-pairings")
                },
                action: this._handleResetPairing.bind(this)
            }
        }

        _handleResetPairing() {
            NavigationComp.showPopup({
                title: _("audio-server.settings.bluetooth.reset-pairings"),
                color: window.Styles.colors.red,
                message: _("audio-server.settings.bluetooth.reset-pairings-descr"),
                buttonOk: _("audio-server.settings.bluetooth.reset-pairings-conf-btn"),
                buttonCancel: true
            }).then(() => {
                let prms = this.control.sendCommand(Commands.AudioZone.BLUETOOTH_PAIRING_RESET).then(() => {
                }, (err) => {
                    NavigationComp.showErrorPopup("Failed to unpair", JSON.stringify(err));
                    this.reloadTable();
                });
                NavigationComp.showWaitingFor(prms);
            })

        }
    }

    Controls.AudioZoneV2Control.BluetoothSettings = BluetoothSettings;
    return Controls.AudioZoneV2Control.BluetoothSettings;
});
