export const spacings = {
    get contentHorizontal() {
        return  HD_APP ? 24 : 16
    },
    gaps: {
        smallest: 3,
        verySmall: 5,
        smaller: 8,
        small: 10,
        smallRegular: 12,
        regular: 16,
        big: 20,
        bigger: 24,
        bigMedium: 28,
        medium: 32,
        veryBig: 40,
        stateIcon: 48
    }
}

