'use strict';

window.GUI = function (GUI) {
    class FavoritesScreenHD extends GUI.FavoritesScreen {
        constructor() {
            super(...arguments);
            this.isPortrait = !isLandscape();
            this.registerForResize();
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.element.addClass('favorites-screen--hd');
            this.onResize();
        }

        destroy() {
            this.leftControls = null;
            this.rightControls = null;
            super.destroy();
        }

        onResize() {
            var isPortraitNow = !isLandscape();

            if (this.isPortrait === isPortraitNow) {
                return;
            }

            this.isPortrait = isPortraitNow;

            if (isPortraitNow) {
                // remove landscape lists
                this.removeSubview(this.leftControlList);
                this.removeSubview(this.rightControlList);
                this.leftControlList = null;
                this.rightControlList = null;
            } else {
                this.removeSubview(this.controlList);
                this.controlList = null;
            }

            this.elements.controlsContainer.toggleClass('controls-placeholder--hd-split', !this.isPortrait);

            this._setUpControlsList(); // setUpControlLists no longer reloads the content. Reload manually


            this.controlList && this.controlList.reload();
            this.leftControlList && this.leftControlList.reload();
            this.rightControlList && this.rightControlList.reload();

            if (this.onSplitListsChanged) {
                setTimeout(this.onSplitListsChanged(), 2000);
            }
        }

        /* no titleBar in HD */
        getTitlebar() {
            return MergeHdAndFavs ? null : super.getTitlebar(...arguments);
        }

        /**
         * On HD there are two columns showing controls, so these need to be updated too.
         */
        updateFavorites() {
            super.updateFavorites(...arguments); // split up the controls;

            this.leftControls = [];
            this.rightControls = [];
            var leftCount = 0;
            var rightCount = 0;

            for (var i = 0; i < this.controls.length; i++) {
                if (leftCount <= rightCount) {
                    this.leftControls.push(this.controls[i]);
                    leftCount += this._getCountForControl(this.controls[i]);
                } else {
                    this.rightControls.push(this.controls[i]);
                    rightCount += this._getCountForControl(this.controls[i]);
                }
            }

            this.leftControlList && this.leftControlList.reload();
            this.rightControlList && this.rightControlList.reload();
        }

        // --------------------------------------------------
        // Overwritten methods
        _setUpGroupList() {
            this.groupsView = new GUI.CardView(this, this);
            this.groupsView.getElement().addClass("favorites-screen__group-collection-view");
            this.appendSubview(this.groupsView, this.elements.scrollContainer);
            this.groupsView.reload();
        }

        _setUpControlsList() {
            this.elements.controlsContainer.toggleClass('controls-placeholder--hd-split', !this.isPortrait);

            if (this.isPortrait) {
                super._setUpControlsList();

                this.controlList.getElement().addClass('control-list--hd');
                this.controlList.getElement().addClass('control-list--favorites');
            } else {
                this.leftControlList = new GUI.ControlListTableView(this, this);
                this.leftControlList.getElement().addClass('control-list--favorites');
                this.leftControlList.getElement().addClass('control-list--hd');
                this.leftControlList.getElement().addClass('control-list--hd-split-left');
                this.leftControlList.getElement().addClass('control-list--hd-split');
                this.appendSubview(this.leftControlList, this.elements.controlsContainer);
                this.rightControlList = new GUI.ControlListTableView(this, this);
                this.rightControlList.getElement().addClass('control-list--favorites');
                this.rightControlList.getElement().addClass('control-list--hd');
                this.rightControlList.getElement().addClass('control-list--hd-split');
                this.rightControlList.getElement().addClass('control-list--hd-split-right');
                this.appendSubview(this.rightControlList, this.elements.controlsContainer);
            }
        }

        // --------------------------------------------------
        // groupsViewDataSource methods
        cellTypeForCellAtIndex() {
            return GUI.TableViewV2.CellType.Special.CARD;
        }

        numberOfSections(tableView) {
            if (tableView === this.groupsView || this.isPortrait) {
                return super.numberOfSections(...arguments);
            } else {
                return 1; // we only have one section in the controls
            }
        }

        numberOfRowsInSection(section, tableView) {
            if (tableView === this.groupsView || this.isPortrait) {
                return super.numberOfRowsInSection(...arguments);
            } else if (tableView === this.leftControlList) {
                return this.leftControls.length;
            } else if (tableView === this.rightControlList) {
                return this.rightControls.length;
            }
        }

        controlForCellAtIndex(section, row, tableView) {
            if (this.isPortrait) {
                return super.controlForCellAtIndex(...arguments);
            } else if (tableView === this.leftControlList) {
                return this.leftControls[row];
            } else if (tableView === this.rightControlList) {
                return this.rightControls[row];
            }
        }

        // --------------------------------------------------
        // Private methods

        /**
         * Helper method that is used to balance the split control list
         * @param control       the control to determine the numberOfRows from
         * @returns {number}    the number of rows this control takes up.
         * @private
         */
        _getCountForControl(control) {
            var count = 1;

            if (control.type === "AudioZone" || control.type === "MediaClient") {
                count = 2;
            }

            return count;
        }

    }

    GUI.FavoritesScreenHD = FavoritesScreenHD;
    return GUI;
}(window.GUI || {});
