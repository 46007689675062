'use strict';

define(["ControlActionCardsScreen"], function (ControlActionCardsScreen) {
    return class MediaClientControlContent extends ControlActionCardsScreen {
        constructor() {
            super(...arguments);
        }

    };
});
