'use strict';

define("AlarmCentralControl", ["AlarmControl"], function (AlarmControl) {
    return class AlarmCentralControl extends AlarmControl {
        constructor() {
            super(...arguments);
        }

        getReactControlContentFlags() {
            return {
                showStateIcon: false,
                showStateText: false
            }
        }

        getCentralIcon() {
            return Icon.Alarm.ALARM;
        }

        movementEnabled() {
            var enabled = false;
            this.forEachSubControl(function (ctrl) {
                enabled = enabled || ctrl.movementEnabled();
            });
            return enabled;
        }

    };
});
