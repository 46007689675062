'use strict';

window.GUI = function (GUI) {
    class WeatherAdWidget extends GUI.View {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return $('<div class="cell__top-border-effect clickable">' + '<div class="weather-ad-widget__content-container">' + ImageBox.getResourceImageWithClasses("resources/Images/WeatherAd/wetter-icons.svg", 'ad__icon') + '<div class="weather-ad-widget__spacer"></div>' + '<div class="weather-ad-widget__text-row text-overflow-ellipsis">' + _("weather.weatherAd.widget.title") + '</div>' + '</div>' + '</div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor() {
            super(WeatherAdWidget.Template.getTemplate());
            this.openWeatherAdHandler = Hammer(this.element[0]).on(tapEvent(), this.showWeatherAd.bind(this));
        }

        destroy() {
            this.openWeatherAdHandler.dispose();
            super.destroy();
        }

        showWeatherAd() {
            NavigationComp.showState(ScreenState.WeatherAd);
        }

    }

    GUI.WeatherAdWidget = WeatherAdWidget;
    return GUI;
}(window.GUI || {});
