import PropTypes from "prop-types";
import { View } from "react-native";
import { LxReactPressable, LxReactText } from "LxComponents";
import globalStyles from "GlobalStyles";

function LxBackgroundComp(props) {
    const Title = ({title = _('fingerprint.access-denied.unlock')}) => {
        if (props.onTitleClickedFn) {
            return title && <LxReactPressable onPress={props.onTitleClickedFn}>
                <LxReactText style={styles.primaryText}>{title}</LxReactText>
            </LxReactPressable>
        } else {
            return title && <LxReactText style={styles.primaryText}>{title}</LxReactText>
        }
    }

    const Message = ({message = _('permission.message.default')}) => {
        if (props.onMessageClickedFn) {
            return message && <LxReactPressable onPress={props.onMessageClickedFn}>
                <LxReactText style={styles.messageText}>{message}</LxReactText>
            </LxReactPressable>
        } else {
            return message && <LxReactText style={styles.messageText}>{message}</LxReactText>
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.contentContainer}>
                <View style={styles.iconContainer}>
                    {props.icon}
                </View>
                <View style={styles.buttonContainer}>
                    <Title title={props.title} />
                    {props.subTitle && <LxReactText style={styles.secondaryText}>{props.subTitle}</LxReactText>}
                    <Message message={props.message} />
                </View>
            </View>
        </View>
    )

}

const styles = {
    container: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    contentContainer: {
        maxHeight: 420,
        maxWidth: 450,
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center"
    },
    iconContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingHorizontal: globalStyles.spacings.gaps.regular
    },
    primaryText: {
        fontFamily: globalStyles.fontSettings.families.light,
        fontSize: globalStyles.fontSettings.sizes.title2,
        color: globalStyles.colors.text.primary,
        marginTop: globalStyles.sizes.smaller,
        marginBottom: globalStyles.sizes.smallest
    },
    secondaryText: {
        color: globalStyles.colors.text.primary,
        fontFamily: globalStyles.fontSettings.families.regular,
        fontSize: globalStyles.fontSettings.sizes.title2,
        marginBottom: globalStyles.sizes.mediumBig
    },
    messageText: {
        color: globalStyles.colors.text.secondary,
        fontFamily: globalStyles.fontSettings.families.regular,
        fontSize: globalStyles.fontSettings.sizes.medium,
    }
}
// endregion

LxBackgroundComp.propTypes = {
    icon: PropTypes.element,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    message: PropTypes.string,
    onTitleClickedFn: PropTypes.func,
    onMessageClickedFn: PropTypes.func
}


export default LxBackgroundComp;
