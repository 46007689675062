import {useContext, useState, useCallback, useMemo, useEffect} from "react";
import { View } from "react-native";
import { LxReactPressable, LxReactText, ControlContext, LxReactSeparator, AmbientContext } from "LxComponents";
import globalStyles from "GlobalStyles";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import PropTypes from "prop-types";
import LinearGradient from 'react-native-linear-gradient';

function LxControlNoteView({ showUpperSeparator = true, showLowerSeparator = true }) {
    const { control, dynamicControl } = useContext(ControlContext)
    const [controlNotes, setControlNotes] = useState(null);
    const navigation = useNavigation();
    const [showMore, setShowMore] = useState(false);
    const { isAmbientMode } = useContext(AmbientContext)

    const NUMBER_OF_LINES = 2;

    const showFullNotes = () => navigation.navigate(ScreenState.ControlHelp, { control });

    const loadControlNotes = () => {
        return CommunicationComponent.sendViaHTTP(Commands.format(Commands.CONTROL.HELP_TEXT, control.uuidAction), EncryptionType.REQUEST).then(notes => {
            if (notes && typeof notes === "string") {
                return notes;
            } else {
                return Q.reject("Notes appear not to be valid!");
            }
        });
    }

    const onTextLayout = useCallback(e => {
        const height = e.nativeEvent.layout.height;
        const count = Math.ceil(height / styles.noteText.lineHeight)
        setShowMore(count > NUMBER_OF_LINES);
    }, []);

    useEffect(() => {
        if (control && control.hasControlNotes) {
            loadControlNotes().then(loadedNotes => setControlNotes(loadedNotes));
        }
    }, [dynamicControl])

    const applyMargin = useMemo(() => {
        if (showUpperSeparator && showLowerSeparator) {
            return { marginVertical: 0 }
        } else if (!showUpperSeparator) {
            return { marginTop: 13 }
        } else if (!showLowerSeparator) {
            return { marginBottom: 13 }
        }
    }, [showUpperSeparator, showLowerSeparator])

    const gradientColors = useMemo(() => {
        if (isAmbientMode) {
            return [globalStyles.colors.transparent, globalStyles.colors.transparent]
        } else {
            return [globalStyles.colors.black, globalStyles.colors.transparent]
        }
    }, [isAmbientMode])

    const seperatorStyle = useMemo(() => {
        let style = { ...styles.seperator };
        if (isAmbientMode) {
            style.backgroundColor = globalStyles.colors.border;
        }
        return style;
    }, [isAmbientMode])

    const ControlNoteBox = () => {
        return <View style={styles.container}>
            {showUpperSeparator ? <LxReactSeparator style={seperatorStyle} /> : null}
            <View style={[styles.noteBoxContainer, applyMargin]}>
                {/* This is a trick to determine the full number of lines */}
                <LxReactText onLayout={onTextLayout} style={[styles.noteText, styles.noteTextHidden]}>
                    {controlNotes}
                </LxReactText>
                <LxReactText style={styles.noteText} numberOfLines={NUMBER_OF_LINES}>
                    {controlNotes}
                </LxReactText>
                {
                    showMore ?
                        <LinearGradient
                            colors={gradientColors}
                            start={{ x: 1, y: 0 }}
                            end={{ x: 0, y: 0 }}
                            style={styles.showMoreContainer}
                        >
                            <LxReactPressable onPress={showFullNotes}>
                                <LxReactText style={styles.showMore} >{_("more")}</LxReactText>
                            </LxReactPressable>
                        </LinearGradient> : null
                }
            </View>
            {showLowerSeparator ? <LxReactSeparator style={seperatorStyle} /> : null}
        </View>
    }

    return <>
        {controlNotes && <ControlNoteBox />}
    </>
}

const styles = {
    container: {
        width: "100%",
    },
    noteBoxContainer: {
        width: "100%",
        borderRadius: 6,
        paddingHorizontal: globalStyles.spacings.gaps.regular,
    },
    noteText: {
        ...globalStyles.textStyles.footNote.default,
        color: globalStyles.colors.text.secondary,
        maxWidth: "100%"
    },
    noteTextHidden: {
        position: "absolute",
        left: -10000,
        top: -10000,
        opacity: 0,
        paddingHorizontal: globalStyles.spacings.gaps.regular
    },
    showMoreContainer: {
        position: "absolute",
        bottom: 0,
        right: 0,
        width: "25%"
    },
    showMore: {
        ...globalStyles.textStyles.footNote.bold,
        color: globalStyles.colors.text.primary,
        alignSelf: "flex-end"
    },
    seperator: {
        marginVertical: 13
    }
}

export default LxControlNoteView;

LxControlNoteView.propTypes = {
    showUpperSeparator: PropTypes.bool,
    showLowerSeparator: PropTypes.bool
}
