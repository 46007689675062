'use strict';

define("LightControl", [
    "Control",
    "LightControlEnums",
    "ColorPickerControlEnums",
    "IconLib"
], function (
    Control,
    LightControlEnums,
    ColorPickerControlEnums,
    {
        default: Icons
    }
) {
    class LightControl extends Control {
        constructor() {
            super(...arguments);
            this.isLightControl = this.type === ControlType.LIGHT;
        }

        /**
         * If true, controls of this type MAY use the rooms name instead of its own. E.g. this is important for light
         * controllers, heating controllers and other room based controls.
         */
        controlTypeUsesRoomName() {
            return true;
        }

        getButton0(states) {
            if (!this.hasSingleSwitch()) {
                var tapCmd,
                    doubleTapCmd = Commands.LIGHTCONTROL.ALL_OFF;

                if (Feature.LIGHT_CONTROL_PLUS_MINUS_CMD) {
                    tapCmd = Commands.LIGHTCONTROL.SCENE_PLUS;
                } else {
                    // tap isn't possible before all states received
                    var scenesIndices = Object.keys(states.scenes || {}); // if no states are known yet, scenes is undefined

                    scenesIndices = scenesIndices.map(Number);
                    scenesIndices = scenesIndices.sort(function (a, b) {
                        return a - b;
                    });

                    if (states.activeSceneNr === LightControlEnums.SceneNumbers.ALL_OFF) {
                        tapCmd = Commands.format(Commands.LIGHTCONTROL.SELECT_SCENE, scenesIndices[0]);
                    } else if (states.activeSceneNr === LightControlEnums.SceneNumbers.ALL_ON) {
                        tapCmd = Commands.LIGHTCONTROL.ALL_OFF; // also possible with minus if there are no scenes
                    }

                    if (!tapCmd) {
                        var sceneIdx = scenesIndices.indexOf(states.activeSceneNr);
                        sceneIdx += 1;

                        if (sceneIdx < 0) {
                            tapCmd = Commands.LIGHTCONTROL.ALL_OFF;
                        } else if (sceneIdx === scenesIndices.length) {
                            tapCmd = Commands.LIGHTCONTROL.ALL_ON;
                        } else {
                            tapCmd = Commands.format(Commands.LIGHTCONTROL.SELECT_SCENE, scenesIndices[sceneIdx]);
                        }
                    }
                }

                return {
                    iconSrc: Icon.ADD,
                    reactIcon: Icons.Plus,
                    command: {
                        tap: tapCmd,
                        doubleTap: doubleTapCmd
                    }
                };
            }
        }

        getSwitch(states) {
            if (this.hasSingleSwitch()) {
                return {
                    active: states.isOn,
                    command0: Commands.LIGHTCONTROL.ALL_OFF,
                    command1: Commands.LIGHTCONTROL.ALL_ON
                };
            }
        }

        specificCreateCmdText(cmdParts, argumentTexts) {
            var text = null,
                cfg = {};

            if (this.type === ControlType.LIGHT) {
                if (argumentTexts) {
                    for (var i = 0; i < argumentTexts.length; i++) {
                        cfg["argument" + i] = argumentTexts[i];
                    }
                }

                if (cmdParts[1] === Commands.LIGHTCONTROL.ALL_ON) {
                    // ALL_ON: "on",
                    text = _("cmdtext.lightcontroller.on");
                } else if (cmdParts[1] === Commands.LIGHTCONTROL.ALL_OFF) {
                    // ALL_OFF: "reset",
                    text = _("cmdtext.lightcontroller.reset");
                } else if (cmdParts[1] === Commands.LIGHTCONTROL.SCENE_MINUS) {
                    text = _("cmdtext.lightcontroller.scene.minus");
                } else if (cmdParts[1] === Commands.LIGHTCONTROL.SCENE_PLUS) {
                    text = _("cmdtext.lightcontroller.scene.plus");
                } else if (cmdParts[1].startsWith("AI")) {
                    // controlling an output!
                    var scId = cmdParts[0] + "/" + cmdParts[1];
                    var subcontrol = this.subControls[scId];
                    text = createCmdText(subcontrol, cmdParts.slice(1), argumentTexts);
                } else {
                    if (cmdParts.length <= 2) {
                        // SELECT_SCENE: "%i",  // sceneNr
                        cfg["argument0"] = this.getSceneName(parseInt(cmdParts[1]));
                        text = _("cmdtext.lightcontroller.scene.select", cfg);
                    } else if (cmdParts[2] === "learn") {
                        // ADD_AND_RENAME_SCENE: "%i/learn/%s", // sceneNr, sceneName,
                        // SAVE_SCENE: "%i/learn" // sceneNr
                        text = _("cmdtext.lightcontroller.scene.save", cfg);
                    } else if (cmdParts[2] === "delete") {
                        // DELETE_SCENE: "%i/delete", // sceneNr,
                        text = _("cmdtext.lightcontroller.scene.delete", cfg);
                    }
                }
            } else if (this.type === ControlType.LIGHTSCENE_RGB) {
                if (cmdParts[1] === "AI") {
                    if (cmdParts[2] === "on") {
                        text = _("cmdtext.lightcontroller.on", cfg);
                    } else {
                        text = _("cmdtext.colorpicker.color", cfg);
                    }
                } else if (cmdParts[1] === Commands.LIGHTSCENE_RGB.OFF) {
                    text = _("cmdtext.lightcontroller.reset", cfg);
                } else {
                    // SELECT_SCENE: "%i",  // sceneNr
                    var sceneName = this.details && this.details.sceneList && this.details.sceneList[cmdParts[1]];

                    if (!sceneName) {
                        sceneName = _("autopilot.not-existing");
                    }

                    text = sceneName;
                }
            }

            return text;
        }

        getFriendlyValueName(event, operator) {
            switch (event.data.stateName) {
                case "activeScene":
                    var sceneName = this.getSceneName(event.value);
                    return this.name + " " + _("lightcontroller.activescene") + " " + sceneName + " " + _("active").toLowerCase();

                default:
                    return "";
            }
        }

        getSceneName(sceneNr) {
            sceneNr = parseInt(sceneNr);

            if (sceneNr === 0) {
                return _('controls.lightcontroller.all-off');
            } else if (sceneNr === 9) {
                return _('controls.lightcontroller.all-on');
            } else if (this.currentScenes && this.currentScenes[sceneNr]) {
                return this.currentScenes[sceneNr];
            } else {
                return _('lightcontroller.activescene') + " " + sceneNr;
            }
        }

        hasSingleSwitch() {
            if (this.isLightControl) {
                var subControls = Object.values(this.subControls);
                return subControls.length === 1 && subControls[0].controlType === ControlType.SWITCH;
            } else {
                return false;
            }
        }

    }

    Controls.LightControl = LightControl;
    Controls.LightControl.SATURATION_MIN_FOR_BG_A = 10;
    Controls.LightControl.BRIGHTNESS_MIN_FOR_BG_B = 10;
    return Controls.LightControl;
});
