'use strict';
/*
    Description of the content: (Base content is excluded here, please check the base implementation for further information)
    content:
        parentControl           -> The parentControl, used to send commands
        control                 -> the control, used to send commands
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.IRCV2TimeLineCell = "IRCV2TimeLineCell";
    const TIME_LINE_UPDATE_INTERVAL = 10000;
    class IRCV2TimeLineCell extends GUI.TableViewV2.Cells.DetailedContentBaseCell {
        //region Static
        static Template = function () {
            var getTimeLine = function getTimeLine() {
                return '' + '<div class="content__time-line">' + getDivScale() + '<div class="time-line__entries-placeholder"></div>' + '<div class="time-line__indicator">' + '   <div class="indicator__line"></div>' + '   <div class="indicator__circle">' + '       <div class="circle__placeholder-image"></div>' + '   </div>' + '</div>' + '<div class="time-line__label-placeholder">' + '<span class="placeholder__time-label">00</span>' + '<span class="placeholder__time-label">06</span>' + '<span class="placeholder__time-label">12</span>' + '<span class="placeholder__time-label">18</span>' + '<span class="placeholder__time-label">24</span>' + '</div>' + '</div>';
            };
            /**
             * A div for each hour with a left border. except for the last hour, there are two divs that are only
             * halve as wide as the others, and the last one has a right border instead of a left.
             * @returns {string}
             */


            var getDivScale = function getDivScale() {
                var divScale = '<div class="time-line__div-scale">',
                    lineClass;

                for (var i = 0; i < 25; i++) {
                    if (i % 3 === 0) {
                        lineClass = "div-scale__large-line";
                    } else {
                        lineClass = "";
                    }

                    divScale += '<div class="div-scale__line ' + lineClass + '" id="div-scale-line-' + i + '"></div>';
                }

                divScale += '</div>';
                return divScale;
            };

            var getEntryElement = function getEntryElement(override) {
                return '<div class="entries-placeholder__entry ' + (override ? "entry--override" : "") + '"></div>';
            };

            return {
                getTimeLine: getTimeLine,
                getEntryElement: getEntryElement
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.elements.prominentContainer.timeLine = {
                    timeLineIndicator: this.element.find('.time-line__indicator'),
                    timeLineIndicatorPlaceholderImage: this.element.find('.circle__placeholder-image'),
                    timeLineEntries: this.element.find(".time-line__entries-placeholder")
                }; // We are working with a canvas, lets register for the resizeEvent to be able to
                // correctly resize the canvas

                this.registerForResize();
                return this.applyContent();
            }.bind(this));
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function () {
                this._updateIndicatorPosition(SandboxComponent.getMiniserverTimeInfo(null, null, TimeValueFormat.MINUTES_SINCE_MIDNIGHT));

                this.timeIdentifier = SandboxComponent.getMiniserverTimeInfo(this, this._updateIndicatorPosition.bind(this), TimeValueFormat.MINUTES_SINCE_MIDNIGHT, TIME_LINE_UPDATE_INTERVAL);
                SandboxComponent.registerForStateChangesForUUID(this.content.parentControl.uuidAction, this, this.updateCellContent.bind(this));
                SandboxComponent.registerForStateChangesForUUID(this.content.control.uuidAction, this, this.updateCellContent.bind(this));
            }.bind(this));
        }

        viewDidAppear() {
            return super.viewDidAppear(...arguments).then(function (res) {
                this._updateIndicatorPosition(SandboxComponent.getMiniserverTimeInfo(null, null, TimeValueFormat.MINUTES_SINCE_MIDNIGHT));

                return res;
            }.bind(this));
        }

        viewWillDisappear() {
            SandboxComponent.removeFromTimeInfo(this.timeIdentifier);
            SandboxComponent.unregisterForStateChangesForUUID(this.content.parentControl.uuidAction, this);
            return super.viewWillDisappear(...arguments);
        }

        /**
         * This is the base function for drawing the timeline
         */
        updateCellContent() {
            if (!this.element) {
                return;
            }

            if (this._updateContentPromise && this._updateContentPromise.isPending()) {
                this._updateContentAgain = true;
                return this._updateContentPromise;
            } // 1 --> create a clone of the element


            var clone = this.elements.prominentContainer.element.clone();

            var states = this.content.parentControl.getStates(),
                desc = this.content.control._getCurrentDescription(),
                controlStates = this.content.control.getStates(),
                minutesAbsolute = 24 * 60,
                fragment = $(document.createDocumentFragment()),
                entries = this.getTimeLineEntries(states, controlStates),
                entry,
                startPercent,
                endPercent,
                entryElement,
                i; // 2 --> replace the element with the clone


            this._updateContentPromise = GUI.animationHandler.replace(this.elements.prominentContainer.element, clone).then(function () {
                // 3 --> update the element (not in DOM atm)
                this.elements.prominentContainer.timeLine.timeLineEntries.empty();
                this.content.title = SandboxComponent.getStructureManager().getOperatingModes(states.activeMode);

                if (nullEmptyString(desc)) {
                    this.content.info = {
                        text: desc
                    };
                }

                this.applyContent(); // Iterate over every entry and draw it appropriately

                for (i = 0; i < entries.length; i++) {
                    entry = entries[i];
                    startPercent = entry.from / minutesAbsolute;
                    endPercent = (minutesAbsolute - entry.to) / minutesAbsolute;
                    entryElement = $(IRCV2TimeLineCell.Template.getEntryElement(entry.hasOwnProperty("reason")));
                    entryElement.css('left', startPercent * 100 + '%');
                    entryElement.css('right', endPercent * 100 + '%'); // adopt color

                    entryElement.css('background-color', this.getColorForEntry(entry)); // Check if this entry is been overwritten and apply the correct class

                    if (entry.reason === undefined && controlStates.hasCurrentOverrideEntry) {
                        entryElement.addClass("entry--overwritten");
                    }

                    this.adoptElementForEntry(entryElement, entry);
                    fragment.append(entryElement);
                }

                var modeIcon = ImageBox.getResourceImageWithClasses(this.content.control.getIconOfIRCTempMode(), "placeholder-image__image");
                var modeColor = this.content.control.getColorOfIRCTempMode();
                this.elements.prominentContainer.timeLine.timeLineEntries.append(fragment);
                this.elements.prominentContainer.timeLine.timeLineIndicatorPlaceholderImage.html(modeIcon);
                this.elements.prominentContainer.timeLine.timeLineIndicatorPlaceholderImage.css("fill", modeColor);
            }.bind(this)).then(function () {
                // 4 --> replace back
                return GUI.animationHandler.replace(clone, this.elements.prominentContainer.element);
            }.bind(this)).then(function () {
                // 5 --> if another updateContent did arrive in the meantime, perform that next
                if (this._updateContentAgain) {
                    this._updateContentAgain = null;
                    setTimeout(this.updateCellContent.bind(this), 1);
                }
            }.bind(this));
            return this._updateContentPromise;
        }

        adoptElementForEntry(entryElement, entry) {// nothing to do, but maybe in sublcasses.
        }

        getColorForEntry(entry) {
            return this.content.control.getColorOfIRCTempMode(entry.tempMode, !(entry.isActive || entry.reason));
        }

        /**
         * Gets all entries we need to draw in the timeline.
         * These entries also get corrected, they can't begin before 0 (00:00) and after 24 * 60 (24:00)
         * @param daytimerStates
         * @param controlStates
         * @return {*|Array}
         */
        getTimeLineEntries(daytimerStates, controlStates) {
            var daytimerEntries = [],
                controlEntries = controlStates.overrideEntries || [];

            if (controlStates.operatingMode < 3) {
                daytimerEntries = this._getEntriesOfMode(daytimerStates.entries, daytimerStates.activeMode) || [];
            } else {
                daytimerEntries = [{
                    from: 0,
                    to: 60 * 24,
                    value: 0,
                    tempMode: 0
                }];
            }

            daytimerEntries = daytimerEntries.concat(controlEntries);
            daytimerEntries = daytimerEntries.map(this._sanitizeDaytimerEntries.bind(this));
            return daytimerEntries;
        }

        /**
         * We must resize the canvas on the resizeEvent, this prevents a missaligned UI
         */
        onResize() {
            // make sure the UI is updated asap, but not in a high frequency
            if (this.resizeTimeout) {
                return; // it'll update itself after the timer.
            }

            this.updateCellContent();

            this._repositionIndicator(this._minutesSinceMidnight); // no timer running, update immediately
            // start a timer to avoid too many refreshes.


            this.resizeTimeout = setTimeout(function () {
                // worst case 2 updates where one was needed.
                this.resizeTimeout = null;
            }.bind(this), 200);
        }

        /**
         * Adds some properties to the daytimer entries, these properties makes further calculations easier
         * @param entry
         * @return {*}
         * @private
         */
        _sanitizeDaytimerEntries(entry) {
            var minutesAbsolute = 24 * 60,
                msNow = SandboxComponent.getMiniserverTimeAsFakeUTC(),
                momentMidnight = msNow.clone().startOf('day'),
                startDate = momentMidnight.clone().add(entry.from, "minutes"),
                endDate = momentMidnight.clone().add(entry.to, "minutes"); // The maximum time, an entry must end is midnight, overrideEntries may be active beyond midnight
            // Because our timeline is just one day these entries must end on the same day, or we do have drawing errors

            entry.from = entry.from < 0 ? 0 : entry.from;
            entry.to = entry.to > minutesAbsolute ? minutesAbsolute : entry.to || msNow.diff(momentMidnight, "minutes"); // If this entry is currently active

            entry.isActive = msNow.isBetween(startDate, endDate);
            return entry;
        }

        /**
         * Updates the minutesSinceMidnight Attribute and calls repositionIndicator to update the UI too.
         * @param minutesSinceMidnight
         * @private
         */
        _updateIndicatorPosition(minutesSinceMidnight) {
            this._minutesSinceMidnight = minutesSinceMidnight;

            this._repositionIndicator(this._minutesSinceMidnight);
        }

        /**
         * Ensures the indicator is drawn at the proper position based on the current time and view size.
         * @param minutesSinceMidnight
         * @private
         */
        _repositionIndicator(minutesSinceMidnight) {
            var leftPos = minutesSinceMidnight / (24 * 60) * 100; // Line width indicator is 2 px so we subtract 1 px for perfect align

            GUI.animationHandler.setCssAttr(this.elements.prominentContainer.timeLine.timeLineIndicator, "left", 'min(max(10px, calc(' + leftPos + '% - 1px)), calc(100% - 10px))');
        }

        /**
         * Extracts a list entries for a specific mode index.
         * @param entries   the entries where the given modes entries are to be picked from
         * @param mode      the mode index number (-1,0,5,..)
         * @returns {*}     either the entries-array or FALSE if no entries exist.
         * @private
         */
        _getEntriesOfMode(entries, mode) {
            // mode 0 is "celebration day", so don't just compare with if(mode)!
            if (entries && (mode || mode === 0) && entries[mode]) {
                return entries[mode];
            }

            return false;
        }

        /**
         * Helper method for sending commands
         * @param cmd
         * @private
         */
        _sendCommand(cmd) {
            this.content.parentControl.sendCommand(cmd);
        }

        getProminentContainer() {
            return IRCV2TimeLineCell.Template.getTimeLine();
        }

    }

    GUI.TableViewV2.Cells.IRCV2TimeLineCell = IRCV2TimeLineCell;
    return GUI;
}(window.GUI || {});
