'use strict';

define("SolarPumpControl", ["Control", "SolarPumpControlEnums"], function (Control, SolarPumpControlEnums) {
    return class SolarPumpControl extends Control {
        constructor() {
            super(...arguments);
        }

        /**
         * Iterate over all available buffers
         * @param callback Is called for every available buffer
         */
        forEachBuffer(callback) {
            this.details.buffers.forEach(callback);
        }

        getFriendlyValueName(event, operator) {
            var index, eventName, eventText;

            if (event.data.stateName.indexOf("bufferTemp") !== -1) {
                index = event.data.stateName.replace("bufferTemp", "");
                eventName = this.name + " " + this.details.buffers[index].name + " " + operator + " " + event.value;
            } else if (event.data.stateName.indexOf("bufferState") !== -1) {
                eventText = this._getEventTextBufferState(event.value, event.data.stateName);
                index = event.data.stateName.replace("bufferState", "");
                eventName = this.name + " " + this.details.buffers[index].name + " " + eventText;
            } else if (event.data.stateName === "collectorTemp") {
                eventName = this.name + " " + _("solarpumpcontroller.collectorTemp") + " " + operator + " " + event.value;
            } else if (event.data.stateName === "heatOverload") {
                eventText = this._getEventTextHeatOverload(event.value);
                eventName = this.name + " " + eventText;
            }

            return eventName;
        }

        /**
         * Returns an array of object with at least a "name" and "id" property
         * @param detailsKey
         * @return {*[]}
         */
        getAutomaticDesignerDetailObjectsFromDetailsKey(detailsKey) {
            var wantedObj;

            switch (detailsKey) {
                case "buffers":
                    wantedObj = this.details[detailsKey].map(function (buffer, idx) {
                        return {
                            id: idx,
                            name: buffer.name
                        };
                    }.bind(this));
                    break;

                default:
                    wantedObj = super.getAutomaticDesignerDetailObjectsFromDetailsKey(...arguments);
            }

            return wantedObj;
        }

        _getEventTextBufferState(valueBufferState) {
            var eventText;

            switch (valueBufferState) {
                case SolarPumpControlEnums.BUFFER_STATE.WAITING:
                    eventText = _("solarpumpcontroller.bufferState.waiting");
                    break;

                case SolarPumpControlEnums.BUFFER_STATE.HEATING_UP:
                    eventText = _("solarpumpcontroller.bufferState.heatingUp");
                    break;
                //case solarPumpControlEnums.BUFFER_STATE.COOLING_DOWN:
                //not in use right now, see solarPumpControlEnums.js

                case SolarPumpControlEnums.BUFFER_STATE.OK_TEMP_REACHED:
                    eventText = _("solarpumpcontroller.bufferState.tempReached");
                    break;

                default:
                    eventText = _("unknown");
                    break;
            }

            return eventText;
        }

        _getEventTextHeatOverload(value) {
            var eventText;

            if (!value) {
                eventText = _("solarpumpcontroller.heatOverload.no");
            } else {
                eventText = _("solarpumpcontroller.heatOverload.yes");
            }

            return eventText;
        }

    };
});
