'use strict';

define(['LightV2ControlEditMoodScreen', 'LightV2ControlEnums'], function (LightV2ControlEditMoodScreen, LightV2ControlEnums) {
    return class LightV2ControlSaveMoodScreen extends LightV2ControlEditMoodScreen {
        constructor(details) {
            super(details);
            this.moodName = "";
        }

        getURL() {
            return "SaveMood";
        }

        titleBarText() {
            return _("controls.lightV2controller.mood.save");
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                this.sceneNameTableView.reload(); // We don't allow to delete a mood that is not created yet

                return this.hideSubview(this.deleteSceneTableView);
            }.bind(this));
        }

        receivedStates(states) {
            // Generate a new ID, can be anything > 8
            // < 8 indicates a connected T5, we don't have the option in the app
            // Remove all known moods
            var filteredMoodList = states.moodList.filter(function (mood) {
                return Object.values(LightV2ControlEnums.KNOWN_MOODS).indexOf(mood.id) === -1;
            }); // sort all moods according to its id

            filteredMoodList.sort(function sort(a, b) {
                return a.id - b.id;
            });

            if (filteredMoodList.length) {
                // increase the last known mood id by 1
                this.moodNr = filteredMoodList[filteredMoodList.length - 1].id + 1;
            } else {
                // if filteredMoodList has no entries (light control has no custom moods yet)
                // use 1 as default value
                this.moodNr = 1;
            } // Ensure that the new mood ID is < 8, so no T5 is linked to this new mood


            while (this.moodNr <= 8) {
                this.moodNr++;
            }
        }

        // Private methods
        _sendChanges() {
            if (this.moodName) {
                // this.moodNr is undefined if no light circuit is available hence no status is received
                // Set it to the first available mood number
                // Ensure that the new mood ID is < 8, so no T5 is linked to this new mood
                if (this.moodNr === undefined) {
                    this.moodNr = 9;
                }

                this.sendCommand(Commands.format(Commands.LIGHTCONTROL_V2.MOOD.LEARN, this.moodNr, this.moodName));
                this.ViewController.navigateBack(null, true); // informs the "parent" view that a new mood has been created.
            }
        }

    };
});
