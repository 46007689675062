'use strict';

define([], function () {
    return class ErrorScreen extends GUI.Screen {
        //region Static
        static Template = function () {
            var getContentTemplate = function getContentTemplate() {
                return $('<div class="error-screen__container">' + ImageBox.getResourceImageWithClasses('resources/Images/DeviceManagement/not-found.svg', 'container__error-icon') + '<div class="container__error-title"></div>' + '<div class="container__error-reason"></div>' + '<div class="container__more-info-button clickable">' + _("miniserver.no-structure.more-info") + '</div>' + '<div class="container__retry-button clickable">' + _("miniserver.conn-failed.retry") + '</div>' + '<div class="container__config-label">* ' + _("miniserver.no-structure.info") + '</div>' + '</div>');
            };

            return {
                getContentTemplate: getContentTemplate
            };
        }(); //endregion Static

        constructor(details) {
            super($("<div />"));
            this.miniserver = details.miniserver;
            this.errorCode = details.errorCode;
            this.reachMode = details.reachMode;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.setUpComfortUI(false);
            this.isWebinterface = PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface;
            this.element.append(ErrorScreen.Template.getContentTemplate());
            updateObject(this.elements, {
                titleLabel: this.element.find(".container__error-title"),
                reasonLabel: this.element.find(".container__error-reason"),
                retryButton: this.element.find('.container__retry-button'),
                moreInfoButton: this.element.find('.container__more-info-button'),
                configLabel: this.element.find('.container__config-label')
            });
            this.retryButton = new GUI.LxButton(this, this.elements.retryButton[0]);
            this.addToHandledSubviews(this.retryButton);
            this.moreInfoButton = new GUI.LxButton(this, this.elements.moreInfoButton[0]);
            this.addToHandledSubviews(this.moreInfoButton);
            this.updateView();

            this.retryButton.onButtonTapped = function onButtonTapped() {
                if (this.errorCode === ErrorCode.MsNotFound || this.errorCode === ErrorCode.StructureDownloadError) {
                    NavigationComp.connectTo(this.miniserver);
                } else if (this.errorCode === ErrorCode.MsSearchNotFound) {
                    MiniserverFinder.startMiniserverFinder();
                }
            }.bind(this);
        }

        viewWillAppear() {
            super.viewWillAppear();
            this.moreInfoButton.onButtonTapped = this._onMoreInfoTapped.bind(this);
        }

        viewWillDisappear() {
            this.moreInfoButton.onButtonTapped = null;
            super.viewWillDisappear();
        }

        getAnimation() {
            return AnimationType.FADE;
        }

        hasHistory() {
            return false;
        }

        updateView(details) {
            super.updateView();

            if (details) {
                if (typeof details.state === "number") {
                    this.errorCode = details.errorCode;
                }

                if (typeof details.reachMode === "number") {
                    this.reachMode = details.reachMode;
                }

                if (typeof details.miniserver === "object") {
                    this.miniserver = details.miniserver;
                }
            }

            var titleText,
                reasonText,
                hintText = _("miniserver.no-structure.info");

            this.moreInfoLink = null; // reset, the button will be toggled below.

            if (this.errorCode === ErrorCode.MsNotFound) {
                if (this.miniserver && this.miniserver.localUrl && this.miniserver.remoteUrl) {
                    titleText = this.miniserver.msName;
                    reasonText = _("miniserver.conn-failed.message");
                } else {
                    titleText = this._detectErrorTitle(this.miniserver);

                    if (this.miniserver && this.miniserver.msName && this.miniserver.msName !== titleText) {
                        reasonText = _("miniserver.conn-failed.message.known", {
                            miniserverName: this.miniserver.msName
                        });
                    } else {
                        reasonText = _("miniserver.conn-failed.message");
                    }
                }
            } else if (this.errorCode === ErrorCode.StructureIncomplete || this.errorCode === ErrorCode.StructureParserError || this.errorCode === ErrorCode.StructureProcessError || this.errorCode === ErrorCode.StructureDownloadError) {
                titleText = this.reachMode === ReachMode.LOCAL ? this.miniserver.localUrl : this.miniserver.remoteUrl;

                if (this.errorCode === ErrorCode.StructureIncomplete) {
                    reasonText = _("miniserver.no-structure.message");
                    hintText = _("miniserver.no-structure.no-controls.message");
                    this.moreInfoLink = _("miniserver.no-structure.no-controls.link");
                } else if (this.errorCode === ErrorCode.StructureParserError) {
                    reasonText = _("miniserver.no-structure.parse-error");
                } else if (this.errorCode === ErrorCode.StructureProcessError) {
                    reasonText = _("miniserver.no-structure.process-error");
                } else if (this.errorCode === ErrorCode.StructureDownloadError) {
                    reasonText = _("miniserver.no-structure.download-error");
                }
            } else if (this.errorCode === ErrorCode.MsUpdateRequired) {
                if (this.reachMode) {
                    titleText = this.reachMode === ReachMode.LOCAL ? this.miniserver.localUrl : this.miniserver.remoteUrl;
                } else {
                    if (this.miniserver.msName) {
                        titleText = this.miniserver.msName;
                    } else if (this.miniserver.localUrl) {
                        titleText = this.miniserver.localUrl;
                    } else if (this.miniserver.remoteUrl) {
                        titleText = this.miniserver.remoteUrl;
                    }
                }

                reasonText = _("miniserver.no-structure.config") + ' (' + minimumConfigVersion + ' +)';
            } else if (this.errorCode === ErrorCode.MsSearchNotFound) {
                titleText = _("miniserverlist.add.search");
                reasonText = _("miniserverlist.add.search.no-devices-found");
            } else if (this.errorCode === ErrorCode.CloudDNSError) {
                titleText = _("clouddns.register-miniserver");
                reasonText = _("clouddns.not-registered-error");
            } else if (this.errorCode === ErrorCode.NoPermission) {
                titleText = _("miniserver.no-permission.title");
                reasonText = _("miniserver.no-permission.message");
            }

            this.elements.retryButton.toggle(this.errorCode === ErrorCode.MsNotFound || this.errorCode === ErrorCode.MsSearchNotFound || this.errorCode === ErrorCode.StructureDownloadError);
            var showConfigMessage = this.errorCode === ErrorCode.StructureIncomplete || this.errorCode === ErrorCode.StructureParserError || this.errorCode === ErrorCode.StructureProcessError || this.errorCode === ErrorCode.MsUpdateRequired;
            this.elements.configLabel.toggle(showConfigMessage);

            if (showConfigMessage) {
                reasonText = reasonText + ' *';
                this.elements.configLabel.text('* ' + hintText);
            }

            this.elements.titleLabel.text(titleText);
            this.elements.reasonLabel.text(reasonText);
            this.elements.moreInfoButton.toggle(this.moreInfoLink !== null);
        }

        /**
         * Overwritten, see enterUrlScreen for details on why to overwrite it..
         **/
        closeAction() {
            this.ViewController.navigateBack();
        }

        // Private methods
        _detectErrorTitle(miniserver) {
            // try to find url
            if (!miniserver) {
                return _("error");
            } else if (miniserver.localUrl && !miniserver.remoteUrl) {
                return miniserver.localUrl;
            } else if (!miniserver.localUrl && miniserver.remoteUrl) {
                return miniserver.remoteUrl;
            } else {
                return miniserver.msName;
            }
        }

        _onMoreInfoTapped() {
            if (this.moreInfoLink) {
                NavigationComp.openWebsite(this.moreInfoLink);
            }
        }

    };
});
