import {LxBaseControlContent, LxReactButton, ControlContext} from "LxComponents";
import {useCallback, useContext} from "react";
import globalStyles from "GlobalStyles";
import {ScreenState} from "../jalousieControlEnums";
import {useNavigation} from "@react-navigation/native";

function JalousieControlContent ({ controlUUID }) {

    const navigation = useNavigation();
    const {control, states} = useContext(ControlContext)

    const showServiceMode = () => {
        if (states.deviceOffline) {
            showDeviceOfflinePopup();
        } else {
            let popupContent = {
                message: _("controls.jalousie.config.service-mode-popup.message"),
                buttonOk: _("controls.jalousie.config.service-mode-popup.activate-button"),
                buttonCancel: _('cancel').toUpperCase(),
                icon: Icon.WRENCH,
                color: globalStyles.colors.red
            };
            NavigationComp.showPopup(popupContent).done((buttonID) => {
                if (Feature.JAL_CONFIG_SERVICE_MODE_CMD) {
                    // since this version we should send a service mode command when entering the service mode to jump to the right state!
                    control.sendCommand(Commands.format(Commands.JALOUSIE.CONFIG.LEARN, Commands.JALOUSIE.CONFIG.SERVICE_MODE));
                }

                showConfigDialog();
            });
        }
    }

    const showConfigDialog = () => {
        navigation.navigate(ScreenState.CONFIG_DIALOG, {control});
    }

    const showDeviceOfflinePopup = () => {
        NavigationComp.showPopup({
            title: _("controls.jalousie.config.device-offline.title"),
            message: _("device-offline.message"),
            buttonOk: _("try-again"),
            buttons: [{
                title: _("error-help"),
                color: globalStyles.colors.red
            }],
            buttonCancel: true,
            icon: Icon.WRENCH,
            color: globalStyles.colors.red
        }).then((idx) => {
            if (idx === GUI.PopupBase.ButtonType.OK) {
                showServiceMode();
            } else {
                NavigationComp.openWebsite(DEVICE_OFFLINE_DIAGNOSTIC_LINK);
            }
        },  () => {})//nothing to do.
    }

    const showEndPosAdjustScreen = () => {
        navigation.navigate(ScreenState.ADJUST_END_POS, {control});
    }

    const getContextMenuOptions = useCallback((control, states) => {
        let options = [];
        if (states.serviceModeAvailable) {
            options.push({
                title: _("controls.jalousie.correct.servicemode.title"),
                action: showServiceMode
            });
        } else if (Feature.JALOUSIE_ENDPOSADJUST && ActiveMSComponent.isAdminUser()) {
            // don't offer the end position adjustment for geiger motors. That's what their service mode is for.
            options.push({
                title: _("controls.jalousie.end-pos.adjust-title"),
                action: showEndPosAdjustScreen
            });
        }
        return options;
    }, [control, states.serviceModeAvailable])

    //TODO: check if states.configurationNeeded is used correctly
    return <LxBaseControlContent customTitle={states.configurationNeeded && _("controls.jalousie.config.one-step.title")}
                                 customStateText={states.configurationNeeded && _('controls.jalousie.config.one-step.sub-title', {
                                     type: _('controls.jalousie.config.shading.salutation')
                                 })}
                                 showCommands={!states.configurationNeeded}
                                 showStates={!states.configurationNeeded}
                                 getAdditionalContextMenuOptions={getContextMenuOptions}>
        {states.configurationNeeded && <LxReactButton onPress={showConfigDialog} text={_("controls.jalousie.config.one-step.button-title")} pkey={"jalousie-config"} />}
    </LxBaseControlContent>
}

const DEVICE_OFFLINE_DIAGNOSTIC_LINK = "https://www.loxone.com/help/device-offline-diagnostics";

export default JalousieControlContent
