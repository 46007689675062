'use strict';
/*
 rating

 didChangeRating(rating, section, row, tableView)
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.CHECKABLE_RATING = "CheckableRatingCell";

    class CheckableRatingCell extends GUI.TableViewV2.Cells.CheckableCell {
        //region Static
        static Template = function () {
            var getRatingTemplate = function getRatingTemplate() {
                return '<div class="content__rating-placeholder">' + '<div class="rating-placeholder__label">0</div>' + ImageBox.getResourceImageWithClasses(Icon.STAR, 'rating-placeholder__icon') + '</div>';
            };

            return {
                getRatingTemplate: getRatingTemplate
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("checkable-rating-cell");
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.element.find('.cell__content').append(CheckableRatingCell.Template.getRatingTemplate());
                this.elements.ratingContainer = this.element.find('.content__rating-placeholder');
                this.elements.ratingLabel = this.element.find('.rating-placeholder__label');
                this.elements.ratingIcon = this.element.find('.rating-placeholder__icon');

                if (this.content.selected) {
                    this._updateRatingLabel();
                } else {
                    this.elements.ratingContainer.hide();
                }
            });
        }

        viewDidAppear() {
            var promise = super.viewDidAppear(...arguments);
            this.ratingHandler = Hammer(this.elements.ratingContainer[0]).on(tapEvent(), this._showRatingPopup.bind(this));
            return promise;
        }

        viewWillDisappear() {
            this.ratingHandler.dispose();
            this.ratingHandler = null;
            return super.viewWillDisappear(...arguments);
        }

        getDefaultDeselectedIcon() {
            return 'resources/Images/General/button-plus.svg';
        }

        onSelected(event, wasIconContainer) {
            super.onSelected(...arguments);

            if (wasIconContainer && this.content.disclosureIcon || !wasIconContainer && !this.content.disclosureIcon) {
                // promise of checkableCell will be resolved after this condition
                // so this.content.selected is still false at this time
                if (!this.content.selected) {
                    this.elements.ratingContainer.show();

                    this._showRatingPopup();
                } else {
                    // don't reset this.content.rating
                    this.elements.ratingContainer.hide();
                }
            }
        }

        _showRatingPopup(e) {
            e && e.stopPropagation();
            var content = {
                title: _("rating-popup.title"),
                availableStars: 10,
                rating: this.content.rating,
                buttonOk: _("finish"),
                buttonCancel: _("rating-popup.no-rating")
            };
            NavigationComp.showPopup(content, PopupType.RATING).done(function (newRating) {
                this.content.rating = newRating;

                this._updateRatingLabel();

                this.delegate.didChangeRating.call(this.delegate, newRating, this.sectionIdx, this.rowIdx, this.tableView);
            }.bind(this), function () {
                this.content.rating = 0;

                this._updateRatingLabel(); // to display correct value for next checking
                // initial rating will be set in the didCheck method

            }.bind(this));
        }

        _updateRatingLabel() {
            this.elements.ratingLabel.text(this.content.rating);
        }

    }

    GUI.TableViewV2.Cells.CheckableRatingCell = CheckableRatingCell;
    return GUI;
}(window.GUI || {});
