import { useCCEvent } from "LxComponents"
import { useCallback, useState } from "react";

/**
 * Updates when the app state changes (background/foreground)
 * @returns {boolean}   true as long as it's in the foreground.
 */
function useIsInForeground() {
    const [getIsForeground, setIsForeground] = useState(true);
    const handleCCEvent = useCallback((event, arg) => {
        switch (event) {
            case CCEvent.Resume:
            case CCEvent.Active:
                console.log(useIsInForeground.name, "Foreground! " + event);
                setIsForeground(true);
                break;
            case CCEvent.Resign:
            case CCEvent.Pause:
                console.log(useIsInForeground.name, "Background! " + event);
                setIsForeground(false);
                break;
        }
    }, []);
    useCCEvent([CCEvent.Resume, CCEvent.Active, CCEvent.Resign, CCEvent.Pause], handleCCEvent);

    return getIsForeground;
}

export default useIsInForeground;