import React, { useContext } from 'react';
import { ControlContext, WheelPicker } from 'LxComponents';

const TempPicker = ({
    showTempPicker,
    useFahrenheit,
    handleTempPickerSelect,
    canRetainLast = true,
}) => {
    const { states } = useContext(ControlContext);
    const generatePickerValues = () => {
        // TODO: what should the value ranges be for C and F
        let targetTempOptions;
        const upperTempBound = useFahrenheit ? 101 : 41;

        targetTempOptions = Object.fromEntries(
            [
                canRetainLast
                    ? [
                          0,
                          {
                              label: _(
                                  'controls.ac-control.settings.retain-last-value',
                              ),
                          },
                      ]
                    : null,
            ]

                .filter((x) => x)
                .concat(
                    Array.from({ length: upperTempBound }, (_, i) => {
                        if (i === 0 && canRetainLast) {
                            return null;
                        }
                        return [i + (canRetainLast ? 1 : 0), { label: i }];
                    }),
                )
                .filter((x) => x),
        );

        return Object.keys(targetTempOptions).map((key) => {
            return targetTempOptions[key];
        });
    };

    const wheelPickerData = [
        {
            dataset: generatePickerValues(),
            selectedIdx: Math.round(states.defaultTargetTemperature),
        },
    ];

    return showTempPicker ? (
        <WheelPicker onSelect={handleTempPickerSelect} data={wheelPickerData} />
    ) : null;
};

export default TempPicker;
