'use strict';
/*
 title
 titleColor
 subtitle
 subtitleColor
 showFullTitle              // normally the title is cropped to 1 line, with that you can make the cell's height dynamically to show the full title!
 showFullSubtitle           // normally the subtitle is cropped to 1 line, with that you can make the cell's height dynamically to show the full subtitle!
 changeTitleSubtitle
 disclosureIcon
 disclosureText
 disclosureColor
 disclosureText1
 disclosureColor1

 leftIconSrc
 leftIconBgEnabled
 leftFallbackIconSrc        // define a fallback icon, if the leftIcon is loaded from some remote server - only resource SVGs are allowed!
 leftIconColor
 leftIconClass
 leftIconLarger             // if true, the left icon will be as big as a cover in a mediaCell (used in audioZoneControl)

 rightIconSrc               // also supports Miniserver Images & svgs passed in as texts
 rightIconColor
 rightIconClass             // e.g. "icon__size-middle" represents it in 24x24 instead of 30x30
 rightIconSize              // SMALL (20x20), MIDDLE (24x24), LARGE (30x30) --> Default: LARGE
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.GENERAL = "GeneralCell";
    GUI.TableViewV2.Enums = GUI.TableViewV2.Enums || {};
    GUI.TableViewV2.Enums.GeneralCell = {
        IconSize: {
            SMALL: "icon__size-small",
            MEDIUM: "icon__size-medium",
            LARGE: "icon__size-large"
        }
    };

    class GeneralCell extends GUI.TableViewV2.Cells.EditableBaseCell {
        //region Static
        static Template = function () {
            var getGeneralCellContent = function getGeneralCellContent() {
                return $('<div class="content__left-icon-placeholder"></div>' + '<div class="content__texts-placeholder">' + '<div class="texts-placeholder__title">' + '<div class="title__text text-overflow-ellipsis"></div>' + '</div>' + '<div class="texts-placeholder__subtitle text-overflow-ellipsis"></div>' + '</div>' + '<div class="content__disc-text">' + '   <div class="disc-text0 text-overflow-ellipsis" />' + '   <div class="disc-text1 text-overflow-ellipsis" />' + '</div>' + '<div class="content__right-icon-placeholder" />');
            };
            /**
             * Returns an element containing the image that can be loaded from the source provided. If the source starts
             * with "resources/" and ends with ".svg" it is assumed that it is included in our ImageBox and is fetched
             * from there. All other images will be loaded from the path provided using an img tag.
             * @param src
             * @param classes
             * @returns {*}
             */


            var getImage = function getImage(src, classes) {
                var img;

                if (src.startsWith("resources/") && src.indexOf(".svg") !== -1) {
                    img = ImageBox.getResourceImageWithClasses(src, classes);
                } else {
                    img = '<img class="' + classes + '" src="' + src + '">';
                }

                return img;
            };

            return {
                getGeneralCellContent: getGeneralCellContent,
                getImage: getImage
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("general-cell");
            this._setIconNr = 0; // Important to avoid overwriting state-icons by default icons when default images have to be downloaded first
        }

        viewDidLoad() {
            Debug.GUI.GeneralCell && console.log(this._viewId, "viewDidLoad", getStackObj());
            var promises = [super.viewDidLoad(...arguments)];
            var contentTemplate = GeneralCell.Template.getGeneralCellContent(this.content);
            promises.push(GUI.animationHandler.append(contentTemplate, this.contentPlaceholder).then(function () {
                this.elements.title = this.element.find('.texts-placeholder__title .title__text');
                this.elements.subtitle = this.element.find('.texts-placeholder__subtitle');
                this.elements.disclosureLabel = this.element.find('.disc-text0');
                this.elements.disclosureLabel1 = this.element.find('.disc-text1');
                this.elements.leftIconCntr = this.element.find('.content__left-icon-placeholder');
                this.elements.rightIconCntr = this.element.find('.content__right-icon-placeholder');
                this.updateContent(this.content);
            }.bind(this)));
            return Q.all(promises);
        }

        // ---------------------------------------------------------------------------------------------------------
        //   Methods for super fast tableContent updates
        // ---------------------------------------------------------------------------------------------------------
        cellTypeForReuse() {
            return GUI.TableViewV2.CellType.GENERAL;
        }

        updateContent(newContent) {
            return super._updateContent(...arguments);
        }

        /**
         * This Method is used to apply a new content object onto the cell instance. e.g. update texts and so on.
         * @param newContent
         * @private
         */
        _applyNewContent(newContent) {
            Debug.GUI.GeneralCell && console.log(this.name, "_applyNewContent: " + JSON.stringify(newContent));
            var promises = [super._applyNewContent(...arguments)];
            var actions = [];
            actions.push(this._updLbl.bind(this, this.elements.title, newContent.title, newContent.titleColor, false));
            actions.push(this._updLbl.bind(this, this.elements.subtitle, newContent.subtitle, newContent.subtitleColor, true));
            actions.push(this._updLbl.bind(this, this.elements.disclosureLabel, newContent.disclosureText, newContent.disclosureColor, true));
            actions.push(this._updLbl.bind(this, this.elements.disclosureLabel1, newContent.disclosureText1, newContent.disclosureColor1, true));
            actions.push(this._tglCss.bind(this, this.elements.title, "text-overflow-ellipsis", !!newContent.showFullTitle));
            actions.push(this._tglCss.bind(this, this.elements.subtitle, "text-overflow-ellipsis", !!newContent.showFullSubtitle));
            actions.push(this._tglCss.bind(this, this.element, "general-cell--full-texts", !!newContent.showFullSubtitle || !!newContent.showFullTitle));
            actions.push(this._tglCss.bind(this, this.elements.leftIconCntr, "content__left-icon-placeholder--larger", !!newContent.leftIconLarger));
            promises.push(GUI.animationHandler.scheduleAll(actions));
            this.setLeftIcon(newContent.leftIconSrc);
            this.setRightIcon(newContent.rightIconSrc);
            this.setClickable(this.clickableAllowed());
            return Q.all(promises);
        }

        // ---------------------------------------------------------------------------------------------------------
        // ---------------------------------------------------------------------------------------------------------
        clickableAllowed() {
            if (!this.content.hasOwnProperty("clickable")) {
                this.content.clickable = !!this.content.disclosureIcon;
            }

            return this.content.clickable;
        }

        onSelected() {
            if (this.content.clickable) {
                super.onSelected(...arguments);
            }
        }

        // Public methods

        /**
         * Set a cell clickable
         * Adds or removes the click handling, clickable class and toggles the disclosureIcon
         * NOTE: The cell will only be clickable if the disclosureIcon is enabled!
         * @param clickable
         */
        setClickable(clickable) {
            var showDiscIndictor = clickable && !!this.content.disclosureIcon;
            this.content.clickable = clickable;
            this.element.attr("disclosure", showDiscIndictor); // Don't forget to register and unregister the click handling and set the clickable class

            if (showDiscIndictor || clickable) {
                // We already have a HammerObject, unregister the current handling to avoid duplicates
                if (this._hammerObject) {
                    this.unregisterClickHandling();
                }

                this.registerClickHandling();
            } else {
                this.unregisterClickHandling();
            } // Finally, apply the clickable class to show the correct mouse behaviour


            this.contentPlaceholder.toggleClass("clickable", showDiscIndictor || clickable);
        }

        setTitle(value) {
            var elem = this.elements.title;
            GUI.animationHandler.schedule(function () {
                elem.text(value);
                elem.prop("title", value);
            });
        }

        setTitleColor(color) {
            var elem = this.elements.title;
            GUI.animationHandler.schedule(function () {
                elem.css('color', color);
            });
        }

        setSubtitle(value) {
            var elem = this.elements.subtitle;
            GUI.animationHandler.schedule(function () {
                elem.toggle(!!value);
                elem.html(value);
                elem.prop("title", value);
            });
        }

        setSubtitleColor(color) {
            var elem = this.elements.subtitle;
            GUI.animationHandler.schedule(function () {
                elem.css('color', color);
            });
        }

        setDisclosureText(value, value1) {
            var actions = [];

            if (this.elements.disclosureLabel) {
                actions.push(this._updLblContent.bind(this, this.elements.disclosureLabel, value, true));
            }

            if (this.elements.disclosureLabel1) {
                actions.push(this._updLblContent.bind(this, this.elements.disclosureLabel1, value1, true));
            }

            GUI.animationHandler.scheduleAll(actions);
        }

        setDisclosureTextColor(color, color1) {
            var actions = [];

            if (this.elements.disclosureLabel) {
                actions.push(this._setCss.bind(this, this.elements.disclosureLabel, "color", color ? color : ""));
            }

            if (this.elements.disclosureLabel1) {
                actions.push(this._setCss.bind(this, this.elements.disclosureLabel1, "color", color1 ? color1 : ""));
            }

            GUI.animationHandler.scheduleAll(actions);
        }

        setRightIcon(iconSrc) {
            var rightIconClasses = "placeholder__icon right-icon-placeholder__icon",
                actions = [];
            rightIconClasses += this.content.rightIconClass ? " " + this.content.rightIconClass + " " : "";
            rightIconClasses += this.content.rightIconSize ? " " + this.content.rightIconSize + " " : " " + GUI.TableViewV2.Enums.GeneralCell.IconSize.LARGE;
            actions.push(function () {
                this.elements.rightIconCntr.empty();
                this.elements.rightIconCntr.toggle(!!iconSrc);
            }.bind(this)); // perform the remove & add & set color in the same animation frame!

            if (!iconSrc) {
                return GUI.animationHandler.scheduleAll(actions);
            }

            return this._getIcon(iconSrc, rightIconClasses).then(function (newIconElem) {
                this.elements.rightIcon = newIconElem;
                actions.push(function () {
                    this.elements.rightIconCntr.append(newIconElem);
                    this.elements.rightIcon.css('fill', this.content.rightIconColor || "");
                }.bind(this));
                return GUI.animationHandler.scheduleAll(actions);
            }.bind(this));
        }

        setRightIconColor(color) {
            if (this.elements.rightIcon) {
                GUI.animationHandler.schedule(function () {
                    this.elements.rightIcon.css('fill', color || "");
                }.bind(this));
            }

            this.content.rightIconColor = color;
        }

        /**
         * Used to replace the icon of the base delete button. Used eg in the editMoodLists moodCell
         */
        getDeleteButtonIcon() {
            return this.content.removeButtonIconSrc || super.getDeleteButtonIcon(...arguments);
        }

        setLeftIcon(iconSrc, isFallback) {
            var classAttr = this.content.leftIconClass || "left-icon-placeholder__icon",
                styleAttr = this.elements.leftIcon ? this.elements.leftIcon.attr("style") : "";
            GUI.animationHandler.schedule(function () {
                this.elements.leftIconCntr.empty();
                this.elements.leftIconCntr.toggle(!!iconSrc);
            }.bind(this));

            if (!iconSrc) {
                return Q.resolve();
            }

            return this._setIcon(iconSrc, this.elements.leftIconCntr, classAttr).then(function (newIconElem) {
                var frameOps = [];
                this.elements.leftIcon = newIconElem; // image fallback handling

                if (!isFallback && this.content.leftIconSrc && this.content.leftFallbackIconSrc && this.content.leftFallbackIconSrc.indexOf(".svg") !== -1) {
                    this.elements.leftIcon.on("error", this.setLeftIcon.bind(this, this.content.leftFallbackIconSrc, true));
                }

                frameOps.push(this._setAttr.bind(this, this.elements.leftIcon, "style", styleAttr));
                frameOps.push(this._tglCss.bind(this, this.elements.leftIconCntr, "content__left-icon-placeholder--with-background", !!this.content.leftIconBgEnabled));
                frameOps.push(this._setCss.bind(this, this.elements.leftIcon, "fill", this.content.leftIconColor || ""));
                GUI.animationHandler.scheduleAll(frameOps);
            }.bind(this));
        }

        _getIcon(iconSrc, iconClasses) {
            Debug.GUI.GeneralCell && console.log(this.name, "_setIcon");
            var newIconElem, promise;

            if (iconSrc.startsWith("https://") || iconSrc.startsWith("http://")|| iconSrc.startsWith("data:")) {
                newIconElem = $('<img src="' + iconSrc + '">');
                newIconElem.attr("class", iconClasses);
                promise = Q.resolve(newIconElem);
            } else if (iconSrc.startsWith("<svg")) {
                newIconElem = $(iconSrc);
                newIconElem.attr('class', iconClasses);
                promise = Q.resolve(newIconElem);
            } else if (iconSrc.startsWith("resources/")) {
                newIconElem = $(GeneralCell.Template.getImage(iconSrc, iconClasses));
                promise = Q.resolve(newIconElem);
            } else {
                // assume it's a Miniserver Icon
                var defer = Q.defer();
                promise = defer.promise;
                ImageBox.getImage(iconSrc).then(function (image) {
                    if (image.mimeType === "svg") {
                        newIconElem = $(image.image);
                    } else {
                        newIconElem = $('<img src="' + image.image + '"/>');
                    }

                    newIconElem.attr('class', iconClasses);
                    defer.resolve(newIconElem);
                }.bind(this));
            }

            return promise;
        }

        _setIcon(iconSrc, iconCntr, iconClasses) {
            Debug.GUI.GeneralCell && console.log(this.name, "_setIcon");
            return this._getIcon(iconSrc, iconClasses).then(function (newIconElem) {
                return GUI.animationHandler.append(newIconElem, iconCntr).then(function () {
                    return newIconElem;
                });
            });
        }

        _updLbl(elem, value, color, bUseHtml) {
            elem.toggle(!!value);
            bUseHtml ? elem.html(value) : elem.text(value);
            elem.prop("title", value);
            elem.css('color', color || ""); // important to use "" if emtpy, otherwise it remains unchanged!
        }

        _updLblContent(elem, value, bUseHtml) {
            elem.toggle(!!value);
            bUseHtml ? elem.html(value) : elem.text(value);
            elem.prop("title", value);
        }

        _tglCss(elem, cssClass, toggleVal) {
            elem.toggleClass(cssClass, toggleVal);
        }

        _setCss(elem, cssAttr, cssVal) {
            elem.css(cssAttr, cssVal || "");
        }

        _setAttr(elem, htmlAttr, htmlVal) {
            elem.attr(htmlAttr, htmlVal);
        }

    }

    GUI.TableViewV2.Cells.GeneralCell = GeneralCell;
    return GUI;
}(window.GUI || {});
