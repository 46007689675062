import globalStyles from "GlobalStyles";
import { LxReactPressable, ControlContext } from "LxComponents";
import { StyleSheet, View, Text, TouchableOpacity, Picker } from "react-native";
import { useContext, useState, useEffect, useCallback } from "react";
import { Modes } from "../acControlEnums";
import Icons from "IconLib"
import PropTypes from "prop-types";

const iconProps = {
    fill: globalStyles.colors.white,
    width: 23,
    height: 23
}

const IconsMap = {
    [Modes.AUTOMATIC]: <Icons.Automatic {...iconProps} />,
    [Modes.HEAT]: <Icons.AcSun {...iconProps} />,
    [Modes.COOL]: <Icons.Snowflake {...iconProps} />,
    [Modes.DRY]: <Icons.Humidity {...iconProps} />,
    [Modes.FAN]: <Icons.Fan {...iconProps} />
}

export default function UpperTempControl({
    displayTempPicker,
    sendValue,
    isSetByLogic }) {
    const { control, states } = useContext(ControlContext)
    const [selectedValue, setSelectedValue] = useState(states.mode);

    const incTargetTemp = useCallback(() => {
        sendValue(control, Commands.AC.SET_TARGET, states.targetTemperature + 1)
    }, [states.targetTemperature])

    const decTargetTemp = useCallback(() => {
        sendValue(control, Commands.AC.SET_TARGET, states.targetTemperature - 1)
    }, [states.targetTemperature])

    useEffect(() => {
        setSelectedValue(states.mode);
    }, [states.mode])

    const renderPickerItems = () => {
        return states.operatingModes?.map((option, idx) => {
            let style = {
                color: globalStyles.colors.text.secondary
            },
                label,
                value;
            if (typeof option === "string") {
                label = option;
                value = option;

            } else {
                label = option.title;
                value = option.value;
            }

            if (selectedValue !== undefined && value.toString() === selectedValue.toString()) {
                style = {
                    backgroundColor: globalStyles.colors.grey["300"],
                    color: globalStyles.colors.text.primary
                }
            }

            return <Picker.Item key={idx} label={label} value={value} style={style} color={style.color} />
        });
    }

    const modeSetByLogic = isSetByLogic("mode");
    return (
        <View style={styles.overview}>
            {!states.isOperatedExternally ? <View style={[styles.modeButton, { opacity: modeSetByLogic ? 0.3 : 1 }]}>
                <LxReactPressable disabled={modeSetByLogic}>
                    {IconsMap[states.mode]}
                </LxReactPressable>
                {!isSetByLogic("mode") ?
                    <Picker
                        style={styles.picker}
                        selectedValue={selectedValue}
                        onValueChange={(value) => sendValue(control, Commands.AC.SET_MODE, value)}>
                        {renderPickerItems()}
                    </Picker> : null}
            </View> : null}

            <TouchableOpacity
                disabled={isSetByLogic("targetTemperature")}
                onPress={displayTempPicker}
                style={[styles.tempOverview, isSetByLogic("targetTemperature") ? { marginRight: 26 } : null]}>
                <View style={styles.tempOverviewTemp}>
                    <Text style={styles.tempOverviewNum}>
                        {states.targetTemperatureText}
                    </Text>
                    <Text style={styles.superscriptText}>{`°${SandboxComponent.getTemperatureUnit()}`}</Text>
                </View>

                <Text style={styles.tempSubtitle}>
                    {_("controls.ac-control.target-temperature")}
                </Text>
            </TouchableOpacity>


            {!states.isOperatedExternally ? <View style={styles.incDecButtons}>
                {!isSetByLogic("targetTemperature") ?
                    <View>
                        <View style={styles.modeButton}>
                            <LxReactPressable
                                onPress={incTargetTemp}
                                onLongPressTick={incTargetTemp}>
                                <Icons.Plus fill={globalStyles.colors.white} width={20} height={20} />
                            </LxReactPressable>
                        </View>

                        <View style={[styles.modeButton, { marginTop: 25 }]}>
                            <LxReactPressable
                                onPress={decTargetTemp}
                                onLongPressTick={decTargetTemp}>
                                <Icons.Minus fill={globalStyles.colors.white} width={20} height={20} />
                            </LxReactPressable>
                        </View>
                    </View>
                    : null}
            </View> : null}
        </View>
    )
}

const styles = StyleSheet.create({
    overview: {
        width: "100%",
        flexDirection: "row",
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        textAlignVertical: 'center',
        paddingVertical: 35
    },
    incDecButtons: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
    },
    modeButton: {
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: globalStyles.colors.fill.tertiary,
        width: 52,
        height: 52,
        borderRadius: 3
    },
    tempOverview: {
        alignContent: "center",
        flexDirection: "column",
        flex: 1
    },
    tempOverviewTemp: {
        flexDirection: 'row',
        alignContent: "center",
        justifyContent: "center"
    },
    tempSubtitle: {
        color: globalStyles.colors.text.secondary,
        ...globalStyles.textStyles.footNote.bold,
        paddingTop: globalStyles.spacings.gaps.big
    },
    tempOverviewNum: {
        ...globalStyles.textStyles.body.default,
        fontSize: 69,
        color: globalStyles.colors.text.primary,
        lineHeight: 50
    },
    picker: {
        position: "absolute",
        left: 0,
        opacity: 0,
        width: "100%",
        fontSize: globalStyles.fontSettings.sizes.regular,
        backgroundColor: globalStyles.colors.grey["600"]
    },
    superscriptText: {
        ...globalStyles.textStyles.body.bold,
        color: globalStyles.colors.text.primary,
        fontSize: 22,
        lineHeight: 27
    }
})

UpperTempControl.propTypes = {
    displayTempPicker: PropTypes.bool,
    sendValue: PropTypes.func,
    isSetByLogic: PropTypes.func
}
