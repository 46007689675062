'use strict';

define("MeterV2Control", ["Control", "./content/meterV2ControlContent.jsx"], function (Control, CtrlContent) {
    return class MeterV2Control extends Control {
        constructor() {
            super(...arguments);
        }

        getFriendlyValueName(event, operator) {
            switch (event.data.stateName) {
                case "actual":
                    return this.name + " " + event.data.stateTitle + " " + operator + " " + lxFormat(this.details.actualFormat, event.value);

                case "total":
                    return this.name + " " + event.data.stateTitle + " " + operator + " " + lxFormat(this.details.totalFormat, event.value);

                default:
                    return "";
            }
        }

        getLegacyReactControlContent() {
            return CtrlContent.default;
        }

        isBidirectional() {
            let result;
            switch (this.details.type) {
                case "storage":
                case "bidirectional":
                    result = true;
                    break;
                default:
                    result = false;
                    break;
            }
            return result;
        }

        hasStorage() {
            let result;
            switch (this.details.type) {
                case "storage":
                    result = true;
                    break;
                default:
                    result = false;
                    break;
            }
            return result;
        }

        getStatisticGroupForOutput(outputName) {
            let superValue = super.getStatisticGroupForOutput(outputName),
                formatGetter = `${outputName}Format`;
            if (superValue && formatGetter in this) {
                superValue.dataPoints.forEach(dp => {
                    if (dp.output === outputName) {
                        dp.format = this[formatGetter];
                    }
                })
            }
            return superValue;
        }

        getStatisticDataPointForOutput(outputName) {
            let superValue = super.getStatisticDataPointForOutput(outputName),
                formatGetter = `${outputName}Format`;
            if (superValue && formatGetter in this) {
                superValue.format = this[formatGetter];
            }
            return superValue;
        }

        get actualFormat() {
            if (this.details.actualFormat && this.details.actualFormat.endsWith("kW")) {
                return "%.1f kW";
            }
            return this.details.actualFormat || "%.2f";
        }

        get totalFormat() {
            if (this.details.totalFormat && this.details.totalFormat.endsWith("kWh")) {
                return "%.1f kWh";
            }
            return this.details.totalFormat || "%.2f";
        }

        /**
         * @Note: This is used generically, Don't trust Intellisense
         */
        get totalNegFormat() {
            return this.totalFormat;
        }

        get storageFormat() {
            return this.details.storageFormat ||"%.1f";
        }

        get hasTotalDay() {
            return this.states.hasOwnProperty("totalDay");
        }
        get hasTotalWeek() {
            return this.states.hasOwnProperty("totalWeek");
        }
        get hasTotalMonth() {
            return this.states.hasOwnProperty("totalMonth");
        }
        get hasTotalYear() {
            return this.states.hasOwnProperty("totalYear");
        }

        getNameForOutput(outputName) {
            let name,
                identifierBase;

            if (outputName.startsWith("totalNeg")) {
                identifierBase = "totalNeg";
            } else if (outputName.startsWith("total")) {
                identifierBase = "total";
            } else {
                identifierBase = outputName;
            }

            // meter values: _("meter.total")
            switch (identifierBase) {
                case "totalNeg":
                    name = this.getTotalNegEnergyName();
                    break;
                case "total":
                    name = this.getTotalEnergyName();
                    break;
                case "actual":
                    name = this.getActualPowerName();
                    break;
                case "storage":
                    name = this.getStorageName();
                    break;
                default:
                    name = outputName;
                    break;
            }

            return name;
        }

        getActualPowerName() {
            if (this.details.powerName && this.details.powerName !== "") {
                return this.details.powerName;
            } else {
                return _("controls.wallbox2.power");
            }
        }

        getTotalEnergyName() {
            if (this.isBidirectional()) {
                return this.hasStorage() ? _("meter2.storage.discharge-energy") : _("meter2.consumed-energy");
            } else {
                return _("meter2.reading")
            }
        }

        getTotalNegEnergyName() {
            return this.hasStorage() ? _("meter2.storage.charge-energy") : _("meter2.delivered-energy");
        }

        getStorageName() {
            return _("meter2.storage.level");
        }

        // region statistic

        get actualPowerStatLegend() {
            let actualLegend;
            if (this.hasStorage()) {
                actualLegend = [
                    {
                        color: window.Styles.colors.green,
                        label: `${_("meter2.storage.charge-energy")} (-)`
                    },
                    {
                        color: window.Styles.colors.orange,
                        label: `${_("meter2.storage.discharge-energy")} (+)`
                    }
                ]
            } else if (this.isBidirectional()) {
                actualLegend = [
                    {
                        color: window.Styles.colors.green,
                        label: `${_("meter2.delivered-energy")} (-)`
                    },
                    {
                        color: window.Styles.colors.orange,
                        label: `${_("meter2.consumed-energy")} (+)`
                    }
                ]
            } else {
                actualLegend = [
                    {
                        color: window.Styles.colors.green,
                        label: this.getNameForOutput("actual")
                    }
                ]
            }
            return actualLegend;
        }

        get totalEnergyStatLegend() {
            let legend;

            if (this.hasStorage()) {
                legend = [
                    {
                        color: window.Styles.colors.green,
                        label: `${_("meter2.storage.charge-energy")}`
                    },
                    {
                        color: window.Styles.colors.orange,
                        label: `${_("meter2.storage.discharge-energy")}`
                    }
                ];
            } else if (this.isBidirectional()) {
                legend = [
                    {
                        color: window.Styles.colors.green,
                        label: `${_("meter2.delivered-energy")}`
                    },
                    {
                        color: window.Styles.colors.orange,
                        label: `${_("meter2.consumed-energy")}`
                    }
                ];
            } else {
                legend = [
                    {
                        color: window.Styles.colors.green,
                        label: _("meter2.reading")
                    }
                ];
            }
            return legend;
        }

        get storageLevelStatLegend() {
            return [
                {
                    color: window.Styles.colors.blue,
                    label: this.getNameForOutput("storage")
                }
            ];
        }

        get actualPowerStatColor() {
            let result = {};

            result.forcedColors = this.isBidirectional() ? {
                top: window.Styles.colors.orange,
                bottom: window.Styles.colors.green,
            } : undefined;
            result.colorInfo = {
                actual: {
                    negative: this.isBidirectional() ? window.Styles.colors.green : window.Styles.colors.orange,
                    positive: this.isBidirectional() ? window.Styles.colors.orange : window.Styles.colors.green
                }
            };
            return result;
        }

        get totalEnergyStatColor() {
            let result = {};

            if (this.isBidirectional()) {
                result.colorInfo = {
                    total: window.Styles.colors.orange, // consumption
                    totalNeg: window.Styles.colors.green // delivery
                };
            } else {
                result.colorInfo = window.Styles.colors.green;
            }

            return result;
        }

        get storageLevelStatColor() {
            return {
                colorInfo: window.Styles.colors.blue
            }
        }



        // endregion
    };
});
