'use strict';

window.GUI = function (GUI) {
    var getTypeById = function getTypeById(typeEnum, typeId) {
        if (typeof typeId === "object" && typeId.hasOwnProperty("id")) {
            // actual type was provided, no need to look it up
            return typeId;
        }

        var types = Object.values(typeEnum);

        for (var i = 0; i < types.length; i++) {
            var type = types[i];

            if (type.id === typeId) {
                return type;
            }
        }

        return types[0];
    };

    class HomeWidget extends GUI.View {
        //region Static
        static Template = function () {
            var getContent = function getContent() {
                return $('' + '<div class="home-widget__sky"><div>&nbsp;</div></div>' + '<div class="home-widget__center"></div>' + '<div class="home-widget__ground"><div>&nbsp;</div></div>' + '');
            };

            var getSkyline = function getSkyline(fgUrl, cssModifier) {
                return $(ImageBox.getResourceImageWithClasses(fgUrl, 'center__skyline skyline--' + cssModifier));
            };

            var getBuilding = function getBuilding(url, cssModifier) {
                if (url.indexOf("http://") !== -1) {
                    return $("<img src='" + url + "' class='center__building building--image building--" + cssModifier + "'>");
                } else {
                    return $(ImageBox.getResourceImageWithClasses(url, 'center__building building--' + cssModifier));
                }
            };

            var getBranding = function getBranding(image) {
                return $('' + '<div class="ground__partner-branding">' + '<img class="partner-branding__image" src="' + image.image + '">' + '</div>');
            };
            /**
             * @param side 'sunrise' or 'sunset'
             * @returns {*|jQuery|HTMLElement}
             */


            var getSunLabels = function getSunLabels(side) {
                return $('' + '<div class="center__sun-info--' + side + ' center__sun-info">' + '<span class="sun-info__hour"></span>' + '<span class="sun-info__minute"></span>' + '</div>');
            };

            return {
                getSunLabels: getSunLabels,
                getContent: getContent,
                getSkyline: getSkyline,
                getBuilding: getBuilding,
                getBranding: getBranding
            };
        }(); //endregion Static

        constructor(config, onHomeScreen) {
            super($('<div />'));
            this.config = config;
            this.onHomeScreen = onHomeScreen;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.element.append(HomeWidget.Template.getContent());
            this.elements = {
                skyPlaceholder: this.element.find('.home-widget__sky'),
                centerPlaceholder: this.element.find('.home-widget__center'),
                groundPlaceholder: this.element.find('.home-widget__ground')
            }; // create & append building & skyline

            var building = getTypeById(BuildingType, this.config.building);
            var skyline = getTypeById(SkylineType, this.config.skyline);
            this.elements.centerPlaceholder.append(HomeWidget.Template.getSkyline(skyline.frontUrl, skyline.tag));
            this.elements.centerPlaceholder.append(HomeWidget.Template.getBuilding(building.url, building.tag));
            this.elements.skyline = this.elements.centerPlaceholder.find('.center__skyline');
            this.elements.building = this.elements.centerPlaceholder.find('.center__building'); // create & append sunrise & sunset labels

            this.elements.centerPlaceholder.append(HomeWidget.Template.getSunLabels('sunrise'));
            this.elements.centerPlaceholder.append(HomeWidget.Template.getSunLabels('sunset'));
            this.elements.sunrisePlaceholder = this.elements.centerPlaceholder.find('.center__sun-info--sunrise');
            this.elements.sunsetPlaceholder = this.elements.centerPlaceholder.find('.center__sun-info--sunset');
            this.elements.sunrise = {
                hour: this.elements.sunrisePlaceholder.find('.sun-info__hour'),
                minute: this.elements.sunrisePlaceholder.find('.sun-info__minute')
            };
            this.elements.sunset = {
                hour: this.elements.sunsetPlaceholder.find('.sun-info__hour'),
                minute: this.elements.sunsetPlaceholder.find('.sun-info__minute')
            };
            this.hasPartnerBranding = ActiveMSComponent.getPartnerImageUUID() != null;
            this.element.toggleClass('home-widget--partner-branding', this.hasPartnerBranding);
            this.element.toggleClass('home-widget--hd', HD_APP);
            this.element.toggleClass('home-widget--merged', MergeHdAndFavs);

            if (this.onHomeScreen) {
                this.showControlsButton = new GUI.LxButton(this, this.elements.centerPlaceholder[0], null); // use centerPlaceholder, building can be replaced!

                this.addToHandledSubviews(this.showControlsButton);
            }

            if (this.hasPartnerBranding) {
                this.processAsync(ImageBox.getImage(ActiveMSComponent.getPartnerImageUUID()), function (image) {
                    var brandingElem = HomeWidget.Template.getBranding(image);
                    this.elements.groundPlaceholder.append(brandingElem);
                    this.partnerHandler = Hammer(brandingElem[0]).on("tap", function () {
                        NavigationComp.showState(ScreenState.PartnerBranding);
                    });
                }.bind(this));
            }
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments); // check if widget was reconfigured in the meantime!

            var homeScreenSettings = PersistenceComponent.getMiniserverSettings().homeScreen; // we check each property because the object is different due to cloning!

            if (homeScreenSettings.widget.building !== this.config.building || homeScreenSettings.widget.skyline !== this.config.skyline) {
                this.config = homeScreenSettings.widget;
                this.onConfigChange(this.config);
            }
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);
            SandboxComponent.registerForStateChangesForUUID('global', this, this.onStateChange);

            if (this.showControlsButton) {
                this.showControlsButton.onButtonTapped = function () {
                    NavigationComp.showState(ScreenState.Control);
                    this.showControlsButton.onButtonTapped = null;
                }.bind(this);
            }
        }

        viewWillDisappear() {
            SandboxComponent.unregisterForStateChangesForUUID('global', this);
            super.viewWillDisappear();
        }

        viewDidDisappear() {
            if (this.showControlsButton) {
                this.showControlsButton.onButtonTapped = null;
            }

            super.viewDidDisappear(...arguments);
        }

        destroy() {
            this.partnerHandler && this.partnerHandler.dispose();
            super.destroy();
        }

        onStateChange(states) {
            this._updateSunInfo(states.sunrise, this.elements.sunrise.hour, this.elements.sunrise.minute);

            this._updateSunInfo(states.sunset, this.elements.sunset.hour, this.elements.sunset.minute);
        }

        onConfigChange(newConfig) {
            this.config = newConfig; // create & replace building & skyline

            var building = getTypeById(BuildingType, this.config.building);
            var skyline = getTypeById(SkylineType, this.config.skyline);
            var newSkylineElem = HomeWidget.Template.getSkyline(skyline.frontUrl, skyline.tag);
            var newBuildingElem = HomeWidget.Template.getBuilding(building.url, building.tag);
            this.elements.skyline.replaceWith(newSkylineElem);
            this.elements.building.replaceWith(newBuildingElem);
            this.elements.skyline = this.elements.centerPlaceholder.find('.center__skyline');
            this.elements.building = this.elements.centerPlaceholder.find('.center__building');
        }

        setBuilding(building) {
            var newConfig = cloneObjectDeep(this.config);
            newConfig.building = building;
            this.onConfigChange(newConfig);
        }

        setSkyline(skyline) {
            var newConfig = cloneObjectDeep(this.config);
            newConfig.skyline = skyline;
            this.onConfigChange(newConfig);
        }

        _updateSunInfo(time, elHour, elMin) {
            var h = time.hours(),
                min = time.format('mm');
            elHour.html(h);
            elMin.html(min);
        }

    }

    GUI.HomeWidget = HomeWidget;
    GUI.HomeWidget.getTypeById = getTypeById;
    return GUI;
}(window.GUI || {});

var BuildingType = {
    Default: {
        id: 0,
        tag: 'default',
        url: "resources/Images/ActiveMiniserver/Home/Buildings/building-smart-home.svg",
        icon: 'resources/Images/ActiveMiniserver/Home/Buildings/icon-smart-home.svg'
    },
    Apartment1: {
        id: 1,
        tag: 'apartment1',
        url: "resources/Images/ActiveMiniserver/Home/Buildings/building-apartment-v1.svg",
        icon: 'resources/Images/ActiveMiniserver/Home/Buildings/icon-apartment-v1.svg'
    },
    Apartment2: {
        id: 2,
        tag: 'apartment2',
        url: "resources/Images/ActiveMiniserver/Home/Buildings/building-apartment-v2.svg",
        icon: 'resources/Images/ActiveMiniserver/Home/Buildings/icon-apartment-v2.svg'
    },
    Classic: {
        id: 3,
        tag: 'classic',
        url: "resources/Images/ActiveMiniserver/Home/Buildings/building-house-classic.svg",
        icon: 'resources/Images/ActiveMiniserver/Home/Buildings/icon-house-classic.svg'
    },
    Company: {
        id: 4,
        tag: 'company',
        url: "resources/Images/ActiveMiniserver/Home/Buildings/building-company.svg",
        icon: 'resources/Images/ActiveMiniserver/Home/Buildings/icon-company.svg'
    },
    Modern1: {
        id: 5,
        tag: 'modern1',
        url: "resources/Images/ActiveMiniserver/Home/Buildings/building-house-modern-v1.svg",
        icon: 'resources/Images/ActiveMiniserver/Home/Buildings/icon-house-modern-v1.svg'
    },
    Modern2: {
        id: 6,
        tag: 'modern2',
        url: "resources/Images/ActiveMiniserver/Home/Buildings/building-house-modern-v2.svg",
        icon: 'resources/Images/ActiveMiniserver/Home/Buildings/icon-house-modern-v2.svg'
    } // A 7th element will be added by the AppBranding

};
var SkylineType = {
    City: {
        id: 0,
        tag: 'city',
        frontUrl: "resources/Images/ActiveMiniserver/Home/Landscapes/bg-city.svg",
        icon: "resources/Images/ActiveMiniserver/Home/Landscapes/icon-city.svg"
    },
    Hills: {
        id: 1,
        tag: 'hills',
        frontUrl: "resources/Images/ActiveMiniserver/Home/Landscapes/bg-hills.svg",
        icon: "resources/Images/ActiveMiniserver/Home/Landscapes/icon-hills.svg"
    },
    LowLands: {
        id: 2,
        tag: 'lowlands',
        frontUrl: "resources/Images/ActiveMiniserver/Home/Landscapes/bg-lowlands.svg",
        icon: "resources/Images/ActiveMiniserver/Home/Landscapes/icon-lowlands.svg"
    },
    Mountains: {
        id: 3,
        tag: 'mountains',
        frontUrl: "resources/Images/ActiveMiniserver/Home/Landscapes/bg-mountains.svg",
        icon: "resources/Images/ActiveMiniserver/Home/Landscapes/icon-mountains.svg"
    },
    Woods: {
        id: 4,
        tag: 'woods',
        frontUrl: "resources/Images/ActiveMiniserver/Home/Landscapes/bg-woods.svg",
        icon: "resources/Images/ActiveMiniserver/Home/Landscapes/icon-woods.svg"
    }
};
