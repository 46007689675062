'use strict';
/**

 */

window.GUI = function (GUI) {
    class PickerViewBase extends GUI.View {
        constructor(details) {
            super($('<div />'));
            Debug.GUI.PickerView && console.log(this.name, "constructor");
            this.details = details;
            this.force24hours = this.details.force24hours || false;
            this.onPickerChangedFn = this.details.onPickerChanged;
            this.onPickerDisappearFn = this.details.onPickerDisappear;
            this.pickerSetup = this.getPickerConfig();
        }

        viewWillAppear() {
            Debug.GUI.ContextMenu && console.log(this.name, "viewWillAppear");
            return Q(super.viewWillAppear(...arguments)).then(function () {
                this.element.addClass("loxone-picker"); // Apply our theme

                this.picker = this.element.mobiscroll();
                this.preparePicker();
            }.bind(this));
        }

        viewWillDisappear() {
            this.onDisappear();
            return super.viewWillDisappear(...arguments);
        }

        /**
         * This merges a given config object and the default Picker Config
         * @param config
         * @returns {{theme: string, mode: string, display: string, lang: *, onChange: *}|*}
         */
        mergePickerConfig(config) {
            if (config) {
                return jQuery.extend({}, this.pickerSetup || {}, config);
            } else {
                return this.pickerSetup;
            }
        }

        getPickerConfig() {
            return {
                theme: 'loxone',
                mode: 'scroller',
                display: 'inline',
                // will place the picker inside the DIV element (does not work on other elements)
                lang: PlatformComponent.getLanguage().split("-")[0].toLowerCase(),
                // only use lang, not region (region may change/be omitted due to navigator.globalization.getPreferredLanguage)
                onChange: this.onChange.bind(this)
            };
        }

        // This functions needs to be implemented in every subclass

        /**
         * This function initializes the Picker
         */
        preparePicker() {
            throw "preparePicker needs to be implemented in your subclass";
        }

        /**
         * This function will be called once the pickerValue has changed
         * For example if the user turns one of the pickerWheels
         * @param v The value returned by mobiscroll in the given format
         */
        onChange(v) {
            throw "onChange needs to be implemented in your subclass";
        }

        /**
         * This sets the given value to the mobiscroll picker
         * @param value This must be in the right format you specified in the picker config.
         */
        setPickerValue(value) {
            throw "setPickerValue needs to be implemented in your subclass";
        }

        /**
         * This gets the given value to the mobiscroll picker
         * @param value This must be in the right format you specified in the picker config.
         */
        getPickerValue(value) {
            throw "getPickerValue needs to be implemented in your subclass";
        }

        /**
         * This function will be called once the picker will disappear
         * @param value
         */
        onDisappear(value) {
            this.onPickerDisappearFn && this.onPickerDisappearFn(this.getPickerValue());
        }

    }

    GUI.LxPickerViewBase = PickerViewBase;
    return GUI;
}(window.GUI || {});
