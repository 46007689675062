import {LxReactText} from "../../../react-comps/Components";
import globalStyles from "GlobalStyles";


export function ValueUnitText({predTxt, valueTxt, succTxt, color, id}) {
    return <LxReactText
        key={id}
        style={[valueUnitStyle.larger, { color: color || globalStyles.colors.text.primary }]}
    >
        <LxReactText style={valueUnitStyle.smaller} key={"pred"}>{predTxt}</LxReactText>
        {valueTxt}
        <LxReactText style={valueUnitStyle.smaller} key={"succ"}>{succTxt}</LxReactText>
    </LxReactText>
}

const valueUnitStyle = {
    larger: {
        ...globalStyles.textStyles.title3.bold,
    },
    smaller: {
        ...globalStyles.textStyles.footNote.bold,
    }
}

export default function WallboxManagerLoadText({power = false, limit = false, format, color}) {

    const getValueArr = () => {
        const splitFormat = lxSplitFormat(format);
        const currObj = power !== false ? {...splitFormat, valueTxt: lxFormat(splitFormat.valFormat, power, true)} : null,
            totalObj = limit !== false ? {...splitFormat, valueTxt: lxFormat(splitFormat.valFormat, limit, true)} : null;

        if (!!totalObj !== !!currObj) {
            let obj = currObj || totalObj;
            obj.id = "single";
            return [obj];

        } else if (currObj.succTxt === totalObj.succTxt) {
            return [
                {
                    valueTxt: currObj.valueTxt + " / " + totalObj.valueTxt,
                    succTxt: currObj.succTxt,
                    id: "combined"
                }
            ]
        } else {
            currObj.id = "curr";
            totalObj.id = "total";
            return [ currObj, {valueTxt: " / ", id: "sep"}, totalObj ];
        }
    }

    return <LxReactText>
        {
            getValueArr().map(valObj => {
                return <ValueUnitText {...valObj} color={color}/>
            })
        }
    </LxReactText>
}