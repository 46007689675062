import * as React from "react"
import Svg, { Path } from "react-native-svg"

function PinWithCrossFilled(props) {
    return (
        <Svg isreact="true"
            width={16}
            height={24}
            viewBox="0 0 16 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 8.006c0-4.412 3.589-8 8-8s8 3.589 8 8a7.999 7.999 0 01-6.216 7.795L8.479 20.15a.5.5 0 01-.958 0l-1.305-4.349A7.999 7.999 0 010 8.006zm3 0c0 2.756 2.242 5 5 5 2.757 0 5-2.243 5-5s-2.243-5-5-5c-2.758 0-5 2.243-5 5z"
                fill="#757575"
            />
            <Path
                d="M12.342 20.033a.5.5 0 01.317.948l-3.077 1.026 3.077 1.026a.498.498 0 11-.317.947L8 22.533l-4.341 1.446a.502.502 0 01-.633-.315.5.5 0 01.316-.632l3.076-1.026-3.076-1.026a.5.5 0 11.316-.948L8 21.479l4.342-1.446z"
                fill="#757575"
            />
        </Svg>
    )
}

export default PinWithCrossFilled
