'use strict';
/*
 title
 subtitle
 active
 progress

 // DelayedAlarmCell delegate
 onSwitchChanged(value, section, row, tableView)
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.DELAYED_ALARM_CELL = "DelayedAlarmCell";

    class DelayedAlarmCell extends GUI.TableViewV2.Cells.GeneralCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("delayed-alarm-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.element.find(".cell__content").append($('<div class="delay-button-placeholder">' + ImageBox.getResourceImageWithClasses("resources/Images/Controls/Alarm/button-timer.svg", "delay-button-icon") + ImageBox.getResourceImageWithClasses("resources/Images/Controls/Alarm/button-timer.svg", "delay-button-icon overlay--icon") + '</div>'));
            this.element.find(".cell__content").append($('<div class="switch-placeholder"></div>'));
            this.switch = new GUI.LxSwitch(this, this.element.find(".switch-placeholder")[0], window.Styles.colors.green);
            this.switch.setActive(this.content.active);
            this.delayButton = new GUI.LxButton(this, this.element.find(".delay-button-placeholder")[0], Color.BUTTON_GLOW, null, true);
            this.delayButton.useChildsAsActiveParts("fill");
            this.elements.delayButton = $(this.delayButton.getElement());
            this.addToHandledSubviews(this.delayButton);
        }

        viewWillAppear() {
            super.viewWillAppear();
            this.setPercentage(this.content.progress);
            this.switch.onStateChanged = this._onSwitchChanged.bind(this);
            this.delayButton.onButtonReleased = this._onDelayTriggered.bind(this);
        }

        viewWillDisappear() {
            this.switch.onStateChanged = null;
            this.delayButton.onButtonReleased = null;
            super.viewWillDisappear(...arguments);
        }

        destroy() {
            this.switch.destroy();
            this.switch = null;
            super.destroy();
        }

        setPercentage(progress) {
            // This "hides" the overlay if the switch is not active
            if (!this.content.active) {
                progress = 1;
            }

            if (progress > 0 && this.content.active) {
                this.switch.setActiveColor(Color.ORANGE);
            } else {
                this.switch.setActiveColor(Color.STATE_ACTIVE);
            }

            var overlayIcon = this.elements.delayButton.find(".overlay--icon");
            overlayIcon[0].style.display = "initial";
            var pixel = Math.floor(overlayIcon.height() * progress);
            overlayIcon[0].style.clip = "rect(" + pixel + "px," + overlayIcon.width() + "px," + overlayIcon.height() + "px, 0px)"; // If the progress is 0, aka the delay has finished, we hide the button

            this.elements.delayButton.toggle(progress !== 0);
            this.content.progress = progress;
        }

        // Private
        _onSwitchChanged(a) {
            this.delegate.onSwitchChanged && this.delegate.onSwitchChanged.call(this.delegate, a, this.sectionIdx, this.rowIdx, this.tableView);
        }

        _onDelayTriggered() {
            this.delegate.onDelayTriggered && this.delegate.onDelayTriggered.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
        }

    }

    GUI.TableViewV2.Cells.DelayedAlarmCell = DelayedAlarmCell;
    return GUI;
}(window.GUI || {});
