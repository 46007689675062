'use strict';

define("RadioControl", [
    "Control",
    "IconLib"
], function (
    Control,
    {
        default: Icons
    }
) {
    return class RadioControl extends Control {
        constructor() {
            super(...arguments);
        }

        getReactControlContentFlags() {
            return {
                showStateIcon: false
            }
        }

        specificCreateCmdText(cmdParts) {
            var cfg = {
                name: this.name
            };

            if (cmdParts[1] === "reset") {
                cfg.output = this.details.allOff;
            } else {
                var id = parseInt(cmdParts[1]);
                cfg.output = this.details.outputs[id];
            }

            return _("cmdtext.radio.select", cfg);
        }

        /**
         * The in- & decrease buttons are shown as otherwise the radio control cannot be told apart from a regular
         * info-only control.
         * @return {{iconSrc: string, command: {tap: (function(this:getButton0))}}}
         */
        getButton0() {
            return {
                iconSrc: Icon.PLUS,
                reactIcon: Icons.Plus,
                command: {
                    tap: this._getRadioCommand.bind(this, true)
                }
            };
        }

        /**
         * The in- & decrease buttons are shown as otherwise the radio control cannot be told apart from a regular
         * info-only control.
         * @return {{iconSrc: string, command: {tap: (function(this:getButton0))}}}
         */
        getButton1() {
            return {
                iconSrc: Icon.MINUS,
                reactIcon: Icons.Minus,
                command: {
                    tap: this._getRadioCommand.bind(this)
                }
            };
        }

        /**
         * Returns an array of object with at least a "name" and (int)"id" property
         * @param detailsKey
         * @return {*[]}
         */
        getAutomaticDesignerDetailObjectsFromDetailsKey(detailsKey) {
            var wantedObj;

            switch (detailsKey) {
                case "outputs":
                    wantedObj = Object.keys(this.details[detailsKey]).map(function (key) {
                        var outputName = this.details[detailsKey][key];
                        return {
                            id: parseInt(key),
                            name: outputName
                        };
                    }.bind(this));

                    if (this.details.allOff) {
                        wantedObj.push({
                            id: 0,
                            name: this.details.allOff
                        });
                    }

                    break;

                default:
                    wantedObj = super.getAutomaticDesignerDetailObjectsFromDetailsKey(...arguments);
            }

            return wantedObj;
        }

        /**
         * Returns the output command, keeping in mind the direction (increase or decrease).
         * @param increase      if true, the next output will be selected.
         * @return {*}
         * @private
         */
        _getRadioCommand(increase) {
            if (Feature.RADIO_NEXT_PREV) {
                return increase ? Commands.RADIO.NEXT : Commands.RADIO.PREV;
            } else {
                var activeOutput = this.getStates().activeChoiceNr,
                    outputKeys = Object.keys(this.details.outputs),
                    // might look like this ["1","3","8"]
                    cmd,
                    selectOutputIdx = -1; // lookup the next output key index

                if (activeOutput === 0) {
                    // turned off, switch to to first or last output right away
                    selectOutputIdx = increase ? 0 : outputKeys.length - 1;
                } else {
                    // the keys are strings, so convert the active output needs to be converted to a string too.
                    selectOutputIdx = outputKeys.indexOf(activeOutput + "");

                    if (selectOutputIdx >= 0) {
                        increase ? selectOutputIdx++ : selectOutputIdx--;
                    } else {
                        console.error("Radiobutton output not found!");
                        selectOutputIdx = -1;
                    }
                } // create the command.


                if (selectOutputIdx >= outputKeys.length || selectOutputIdx < 0) {
                    // out of bounds, reset
                    cmd = Commands.format(Commands.RADIO.RESET);
                } else {
                    cmd = Commands.format(Commands.RADIO.OUTPUT, outputKeys[selectOutputIdx]);
                }
                return cmd;
            }
        }
    };
});
