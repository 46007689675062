define(['AutopilotEnums'], function (AutopilotEnums) {
    var instance = null,
        AUTOPILOT_MAX_CONDITIONS = 4;

    /**
     * Collection of functions used by the autopilot generator
     * @constructor
     */

    function AutopilotUtility() {
        if (instance !== null) {
            throw new Error("Cannot instantiate more than one MySingleton, use MySingleton.getInstance()");
        }
    }

    AutopilotUtility.prototype = {
        /**
         * Returns a list of weather events or a single weather event if an id is given
         * @param weatherId (optional)
         * @returns {List|single event}
         */
        getWeatherEvents: function getWeatherEvents(weatherId) {
            var weatherEvents = [],
                weatherEventsDict = {},
                weatherEventTypes = ActiveMSComponent.getStructureManager().getWeatherFieldTypes();

            for (var prop in weatherEventTypes) {
                if (!weatherEventTypes.hasOwnProperty(prop)) {
                    continue;
                }

                if (AutopilotEnums.AllowedWeatherTypes.hasOwnProperty(prop)) {
                    var obj = weatherEventTypes[prop];
                    weatherEvents.push(obj);
                    weatherEventsDict[prop] = obj;
                }
            }

            if (typeof weatherId !== 'undefined') {
                return weatherEventsDict[weatherId];
            }

            weatherEvents = weatherEvents.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            });
            return weatherEvents;
        },

        /**
         * Returns a list of time events or a single time event if an id is given
         * @param timeId (optional)
         * @returns {List|single event}
         */
        getTimeEvents: function getTimeEvents(timeId) {
            var timeEvents = [],
                timeEventsDict = {},
                timeEventTypes = ActiveMSComponent.getStructureManager().getTimes(); // add custom time entry

            var customTimeEntry = {
                id: AutopilotEnums.SpecialEvents.CustomTime,
                name: "0_custom_time"
            };
            timeEvents.push(customTimeEntry);
            timeEventsDict[customTimeEntry.id] = customTimeEntry;

            for (var prop in timeEventTypes) {
                if (!timeEventTypes.hasOwnProperty(prop)) {
                    continue;
                }

                if (AutopilotEnums.AllowedTimeTypes.hasOwnProperty(prop)) {
                    var obj = timeEventTypes[prop];
                    timeEvents.push(obj);
                    timeEventsDict[prop] = obj;
                }
            }

            if (typeof timeId !== 'undefined') {
                return timeEventsDict[timeId];
            }

            timeEvents = timeEvents.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            });
            return timeEvents;
        },

        /**
         * Returns a list of caller services or a single caller if an id is given
         * @param callerId
         * @returns {List|single caller}
         */
        getCallerServices: function getCallerServices(callerId) {
            var callerServices = [],
                callerServicesDict = ActiveMSComponent.getStructureManager().getCallerServices();

            if (typeof callerId !== 'undefined') {
                return callerServicesDict[callerId];
            }

            for (var prop in callerServicesDict) {
                if (!callerServicesDict.hasOwnProperty(prop)) {
                    continue;
                }

                callerServices.push(callerServicesDict[prop]);
            }

            return callerServices;
        },
        getCategoriesFromControlTypes: function getCategoriesFromControlTypes(supportedTypes) {
            var categories = {},
                controls = ActiveMSComponent.getStructureManager().getAllControls();
            controls = Object.values(controls);
            controls.forEach(function (control) {
                if (supportedTypes.indexOf(control.type) !== -1) {
                    var cat = ActiveMSComponent.getStructureManager().getGroupByUUID(control.cat, GroupTypes.CATEGORY);

                    if (!cat) {
                        cat = {
                            uuid: UnassignedUUID,
                            name: _('category.without')
                        };
                    }

                    if (!categories[cat.uuid]) {
                        categories[cat.uuid] = {
                            uuid: cat.uuid,
                            title: cat.name,
                            rooms: {}
                        };
                    }

                    cat = categories[cat.uuid];
                    var room = ActiveMSComponent.getStructureManager().getGroupByUUID(control.room, GroupTypes.ROOM);

                    if (!room) {
                        room = {
                            id: UnassignedUUID,
                            name: _('room.without'),
                            value: []
                        };
                    }

                    if (!cat.rooms[room.uuid]) {
                        cat.rooms[room.uuid] = {
                            id: room.uuid,
                            title: room.name,
                            value: [control]
                        };
                    } else {
                        room = cat.rooms[room.uuid];
                        room.value.push(control);
                    }
                }
            });
            categories = Object.values(categories);
            categories.forEach(function (cat) {
                cat.rooms = Object.values(cat.rooms).sort(function (a, b) {
                    return a.title.localeCompare(b.title);
                });
            });
            categories = categories.sort(function (a, b) {
                return a.title.localeCompare(b.title);
            });
            return categories;
        },
        getControlForUUID: function getControlForUUID(uuid) {
            return ActiveMSComponent.getStructureManager().getControlByUUID(uuid);
        },

        /**
         * Returns a list of operating modes or a single operating mode if an id is given
         * @param all (if true return all operating modes, if false do not return week day operating modes)
         * @param modeId
         * @returns {Array|operating mode}
         */
        getOperatingModes: function getOperatingModes(all, modeId) {
            var operatingModes = [],
                operatingModeDays = [],
                operatingModesFiltered = [],
                operatingModesDict = cloneObject(ActiveMSComponent.getStructureManager().getOperatingModes(true)); // clone, will be edited below

            for (var prop in operatingModesDict) {
                // we just show operating modes <= 2, because we do not want to set the modes for the weekdays
                if (!operatingModesDict.hasOwnProperty(prop)) {
                    continue;
                }

                var obj = {
                    id: prop,
                    value: operatingModesDict[prop]
                }; // we hold a list of operating modes the user can select on actions
                // we do not allow the operating modes for the weekdays

                if (prop < 2) {
                    operatingModesFiltered.push(obj);
                    operatingModes.push(obj); // days will be added to end of array after sorting
                } else {
                    operatingModeDays.push(obj);
                }

                operatingModesDict[prop] = obj;
            }

            operatingModes = operatingModes.sort(function (a, b) {
                return a.value.localeCompare(b.value);
            }); // add the days to the end

            operatingModes = operatingModes.concat(operatingModeDays);
            operatingModesFiltered = operatingModesFiltered.sort(function (a, b) {
                return a.value.localeCompare(b.value);
            });

            if (typeof modeId !== 'undefined') {
                return operatingModesDict[modeId];
            }

            if (all) {
                return operatingModes;
            }

            return operatingModesFiltered;
        },

        /**
         * Creates the recommended title for a given rule
         * @param rule
         * @returns {string}
         */
        createTitleForRule: function createTitleForRule(rule) {
            if (!rule || rule.events.length === 0) {
                return "";
            } // populate rule


            this.populateRule(rule);
            var firstEvent = rule.events[0];
            return _("autopilot.rule.if").toUpperCase() + " " + this.createEventDescription(firstEvent, true);
        },

        /**
         * Creates the recommended description for a rule
         * IF ... THEN ...
         * @param rule
         * @returns {string}
         */
        createDescriptionForRule: function createDescriptionForRule(rule) {
            var result = ""; // populate rule

            this.populateRule(rule);

            if (rule.events.length === 0 && rule.actions.length === 0) {
                return "";
            }

            result += _("autopilot.rule.if").toUpperCase();

            for (var i = 0; i < rule.events.length; i++) {
                var event = rule.events[i];

                if (i > 0) {
                    result += " " + _("and");
                }

                result += " " + this.createEventDescription(event, true);
            }

            result += " " + _("autopilot.rule.then").toUpperCase();

            for (var i = 0; i < rule.actions.length; i++) {
                var action = rule.actions[i];

                if (rule.actions.length > 1 && i === rule.actions.length - 1) {
                    result += " " + _("and");
                } else if (i > 0) {
                    result += ",";
                }

                result += " " + this.createActionDescription(action, true);
            }

            return result;
        },

        /**
         * Creates the recommended description (Array, one line per "entry") for a rule
         * IF ...
         * and ..
         * THEN ...
         * @param rule
         * @returns {[]}
         */
        createDescriptionArrayForRule: function createDescriptionArrayForRule(rule) {
            var result = [],
                i,
                event,
                action,
                str = ""; // populate rule

            this.populateRule(rule);

            if (rule.events.length === 0 && rule.actions.length === 0) {
                return [];
            }

            for (i = 0; i < rule.events.length; i++) {
                event = rule.events[i];

                if (i === 0) {
                    str = _("autopilot.rule.if").toUpperCase();
                } else {
                    str = _("and");
                }

                str += " " + this.createEventDescription(event, true);
                result.push(str);
            }

            for (i = 0; i < rule.actions.length; i++) {
                action = rule.actions[i];

                if (i === 0) {
                    str = _("autopilot.rule.then").toUpperCase();
                } else {
                    str = _("and");
                }

                str += " " + this.createActionDescription(action, true);
                result.push(str);
            }

            var title = rule.name || this.createTitleForRule(rule); // if the title is generated, remove the first condition line in the description

            if (result[0] === title) {
                result.shift();
            } // a maximum of 4 lines.. 5th line is "..."


            if (result.length > AUTOPILOT_MAX_CONDITIONS) {
                while (result.length > AUTOPILOT_MAX_CONDITIONS) {
                    result.pop();
                }

                result.push("...");
            }

            return result;
        },

        /**
         * Creates the description for a given event
         * @param event
         * @param longVersion
         * @returns {string}
         */
        createEventDescription: function createEventDescription(event, longVersion) {
            if (!event) {
                return "";
            }

            var operator = event.operator || AutopilotEnums.DefaultOperator;
            var valueStr = "";

            switch (event.type) {
                case AutopilotEnums.EventTypes.Control:
                    return this._createControlEventDescription(event.data.control, event, longVersion);

                case AutopilotEnums.EventTypes.OperatingMode:
                    var opMode = event.data.entry;

                    if (opMode) {
                        valueStr = opMode.value + " " + (event.value ? _("active") : _("inactive")).toLowerCase();
                    } else {
                        // operating modes might be deleted. Then there won't be info on how it's called.
                        valueStr = "-" + _("autopilot.not-existing").toUpperCase() + "-";
                    }

                    return valueStr;

                case AutopilotEnums.EventTypes.Weather:
                    var weatherMode = event.data.entry;

                    if (weatherMode && weatherMode.analog) {
                        var op = event.operator || AutopilotEnums.DefaultOperator;
                        valueStr = AutopilotEnums.OperatorsTitle[op] + " " + event.value.toString().replace(",", ".") + (weatherMode.unit ? " " + weatherMode.unit : "");
                    } else {
                        valueStr = event.value ? _("yes") : _("no");
                    }

                    return _("weather." + event.id) + " " + valueStr.toLowerCase();

                case AutopilotEnums.EventTypes.Time:
                    var timeMode = event.data.entry;

                    if (!timeMode) {
                        return ""; // we removed weekdays, so we have to handle it
                    }

                    if (timeMode.analog) {
                        var op = event.operator || AutopilotEnums.DefaultOperator;
                        var val = event.value;

                        if (timeMode.id === AutopilotEnums.SpecialEvents.Weekdays) {
                            val = AutopilotEnums.Weekdays[val].title;
                        }

                        valueStr = AutopilotEnums.OperatorsTitle[op] + " " + val;
                    } else if (!this.isPulseEvent(event)) {
                        // if ! a pulse show yes or no, otherwise just show the name of the pulse event
                        valueStr = event.value ? _("yes") : _("no");
                        valueStr = valueStr.toLowerCase();
                    }

                    return _("times." + event.id) + (valueStr.length > 0 ? " " + valueStr : "");

                case AutopilotEnums.EventTypes.DateTime:
                    var dateTimeString = new moment(event.value).format(LxDate.getMobiscrollDateFormat().toUpperCase() + " " + LxDate.getMobiscrollTimeFormat(true));
                    return _("mobiscroll.date") + " " + AutopilotEnums.OperatorsTitle[operator] + " " + dateTimeString;

                case AutopilotEnums.EventTypes.CustomTime:
                    var seconds = event.value * 60;
                    return _("mobiscroll.clocktime") + " " + AutopilotEnums.OperatorsTitle[operator] + " " + LxDate.formatTimeFromSecondsToLocal(seconds, true);

                default:
                    return "";
            }
        },

        /**
         * Creates the description for a given action
         * @param action
         * @param longVersion
         * @returns {string}
         */
        createActionDescription: function createActionDescription(action, longVersion) {
            if (!action) {
                return "";
            }

            switch (action.type) {
                case AutopilotEnums.ActionTypes.Command:
                    return this._createControlActionDescription(action.data.control, action, longVersion);

                case AutopilotEnums.ActionTypes.OperatingMode:
                    var opMode = action.data.entry;

                    if (opMode) {
                        return opMode.value + " " + (action.value ? _("active") : _("inactive")).toLowerCase();
                    } else {
                        return _("autopilot.no-access");
                    }

                case AutopilotEnums.ActionTypes.CloudMailer:
                    return _("autopilot.action.email.title", {
                        recipient: action.recipient
                    });

                case AutopilotEnums.ActionTypes.Caller:
                    var caller = this.getCallerServices(action.id);

                    if (caller) {
                        return _("autopilot.action.caller.title", {
                            name: caller.name,
                            phonenumber: caller.phoneNumber
                        });
                    } else {
                        return _("autopilot.no-access");
                    }

                case AutopilotEnums.ActionTypes.Notification:
                    return _("notification") + ": " + action.subject;

                default:
                    return "";
            }
        },

        /**
         * Checks if a given rule contains an event already
         * @param rule
         * @param event
         * @returns {boolean}
         */
        containsEvent: function containsEvent(rule, event) {
            if (!rule || !rule.events || !event) {
                return false;
            } //TODO: adopt check depending on type


            for (var i = 0; i < rule.events.length; i++) {
                var currEvent = rule.events[i];

                if (currEvent.id === event.id && currEvent.controlUUID === event.controlUUID && currEvent.value === event.value && currEvent.type === event.type && currEvent.operator === event.operator) {
                    return true;
                }
            }

            return false;
        },

        /**
         * Checks if a given rule contains an action already
         * @param rule
         * @param action
         * @returns {boolean}
         */
        containsAction: function containsAction(rule, action) {
            if (!rule || !rule.actions || !action) {
                return false;
            } //TODO: adopt check depending on type, replace \n with /n on text


            for (var i = 0; i < rule.actions.length; i++) {
                var currAction = rule.actions[i];

                if (currAction.id === action.id && currAction.command === action.command && currAction.type === action.type && currAction.recipient === action.recipient && currAction.subject === action.subject
                    /*&& currAction.text === action.text*/
                ) {
                    // compare escaped version from miniserver
                    if (action.text) {
                        return action.text === currAction.text.replace(/\/n/g, "\n");
                    }

                    return true;
                }
            }

            return false;
        },

        /**
         * Checks if an event is a pulse event
         * @param event
         * @returns {boolean}
         */
        isPulseEvent: function isPulseEvent(event) {
            return AutopilotEnums.SpecialEvents.Pulse.indexOf(event.id) !== -1;
        },

        /**
         * Copy all properties from the src object to the destination object
         * @param src
         * @param dest
         */
        copyValues: function copyValues(src, dest) {
            //TODO: we don't need this functionality if we always save events/actions also on navigateBack
            // if we just want to store the action/event details with an explicit click we need to copy the values
            for (var prop in dest) {
                delete dest[prop];
            }

            for (var prop in src) {
                dest[prop] = src[prop];
            }
        },
        showValidationError: function showValidationError(message, title) {
            var content = {
                title: title ? title : _("input.missing"),
                message: message,
                buttonOk: _("OK"),
                buttonCancel: false,
                icon: Icon.X,
                color: window.Styles.colors.orange
            };
            return NavigationComp.showPopup(content);
        },

        /**
         * Populates a given event with additional data
         * e. g. adds control details to a control event
         * @param event
         */
        populateEvent: function populateEvent(event) {
            switch (event.type) {
                case AutopilotEnums.EventTypes.OperatingMode:
                    event.data = {
                        entry: this.getOperatingModes(true, event.id)
                    };
                    break;

                case AutopilotEnums.EventTypes.Time:
                    event.data = {
                        entry: this.getTimeEvents(event.id)
                    };
                    break;

                case AutopilotEnums.EventTypes.Weather:
                    event.data = {
                        entry: this.getWeatherEvents(event.id)
                    };
                    break;

                case AutopilotEnums.EventTypes.Control:
                    var control = this.getControlForUUID(event.controlUUID);
                    var transKey = "";
                    var stateName = "";

                    if (control) {
                        // get translation key for state
                        for (var prop in control.states) {
                            var val = control.states[prop];

                            if (val === event.id) {
                                stateName = prop;
                                break;
                            }
                        } // the translation key is genereated by the controlType.stateName


                        transKey = control.type + "." + stateName;
                        transKey = transKey.toLowerCase();
                    }

                    event.data = {
                        control: control,
                        stateTitle: transKey ? _(transKey) : "",
                        stateName: stateName
                    };
                    break;
            }
        },

        /**
         * Populates a given action with additional data
         * e. g. adds the operating mode details to an operating mode action
         * @param action
         */
        populateAction: function populateAction(action) {
            switch (action.type) {
                case AutopilotEnums.ActionTypes.OperatingMode:
                    action.data = {
                        entry: this.getOperatingModes(true, action.id)
                    };
                    break;

                case AutopilotEnums.ActionTypes.Command:
                    var control = this.getControlForUUID(action.id);
                    action.data = {
                        control: control
                    };
                    break;
            }
        },

        /**
         * Populates the whole rule with additional information
         * e. g. control details, operating mode details, ...
         * @param rule
         */
        populateRule: function populateRule(rule) {
            var i, event, action;

            for (i = 0; i < rule.events.length; i++) {
                event = rule.events[i];
                this.populateEvent(event);
            }

            for (i = 0; i < rule.actions.length; i++) {
                action = rule.actions[i];
                this.populateAction(action);
            }
        },

        /**
         * Creates a description for a given CONTROL event
         * @param control
         * @param event
         * @param longVersion (if true add control details)
         * @returns {string}
         * @private
         */
        _createControlEventDescription: function _createControlEventDescription(control, event, longVersion) {
            var opId = event.operator || AutopilotEnums.DefaultOperator;
            var operator = AutopilotEnums.OperatorsTitle[opId];
            var result = "";

            if (control) {
                try {
                    result = control.getFriendlyValueName(event, operator);

                    if (longVersion) {
                        result += " (" + control.groupDetail + ")";
                    }
                } catch (ex) {
                    console.error("Could not create an autopilot rule event description for the control " + control.name + "!");
                    console.error(ex);
                }
            }

            return result;
        },

        /**
         * Creates a description for a given CONTROL action
         * @param control
         * @param action
         * @param longVersion
         * @returns {string}
         * @private
         */
        _createControlActionDescription: function _createControlActionDescription(control, action, longVersion) {
            var friendly = "";

            if (!control) {
                return "";
            }

            try {
                var commands = [control.uuidAction]; // for simulating the uuidAction

                var commandsConcr = action.command.toString().split("/");

                if (commandsConcr.length > 0) {
                    commands = commands.concat(commandsConcr);
                }

                friendly = createCmdText(control, commands);

                if (control.type === AutopilotEnums.SupportedEventBlocks.LightController) {
                    friendly = control.name + ": " + friendly;
                }

                if ([ControlType.LIGHT_V2, ControlType.I_ROOM_V2].includes(control.type) && control.hasSiblingsInRoom) {
                    friendly = control.name + ": " + friendly;
                }
            } catch (err) {
                friendly = control.name + " " + action.command;
            }

            if (longVersion) {
                friendly += " (" + control.groupDetail + ")";
            }

            return friendly;
        },
        _mapObjectToArray: function _mapObjectToArray(obj, plain) {
            var result = [];

            for (var prop in obj) {
                if (!obj.hasOwnProperty(prop)) {
                    continue;
                }

                if (plain) {
                    var copy = JSON.parse(JSON.stringify(obj[prop]));
                    copy.id = prop;
                    result.push(copy);
                    continue;
                }

                result.push({
                    id: prop,
                    value: obj[prop]
                });
            }

            return result;
        }
    };

    AutopilotUtility.getInstance = function () {
        if (instance === null) {
            instance = new AutopilotUtility();
        }

        return instance;
    };

    return AutopilotUtility.getInstance();
});
