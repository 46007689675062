'use strict';
/**
 * DEPRECATED!
 * using StepCharView for Digital now!
 */

define([], function () {
    return function DigitalChartView(data, ui, ctx, Helper) {
        var middleDP, rectY, rectHeight, lastDP, x, maxRightIdx, lastX;

        var draw = function drawDigitalChart() {
            middleDP = data.source[data.source.middleIndex];
            rectY = Helper.getYForValue(1);
            rectHeight = Helper.getYForValue(0) - rectY;
            lastX = Helper.getXForTimestamp(middleDP.ts); // to the left

            Helper.forEachVisibleDataPoint(Direction.LEFT, data.source.middleIndex - 1, function (dp) {
                if (dp.value === 1) {
                    x = Helper.getXForTimestamp(dp.ts);
                    ctx.rect(x, rectY, lastX - x, rectHeight);
                } else {
                    lastX = Helper.getXForTimestamp(dp.ts);
                }
            });
            lastX = data.source[data.source.middleIndex] && Helper.getXForTimestamp(data.source[data.source.middleIndex].ts); // to the right

            if (data.source.middleIndex + 1 < data.source.length) {
                maxRightIdx = Helper.forEachVisibleDataPoint(Direction.RIGHT, data.source.middleIndex, function (dp) {
                    if (dp.value === 0) {
                        x = Helper.getXForTimestamp(dp.ts);
                        ctx.rect(lastX, rectY, x - lastX, rectHeight);
                    } else {
                        lastX = Helper.getXForTimestamp(dp.ts);
                    }

                    lastDP = dp;
                });
            } else {
                lastDP = data.source[data.source.middleIndex];
            }

            if (!data.source[maxRightIdx + 1] && data.upToDate && lastDP.value === 1) {
                // no more datapoints to right, is 1, data is upToDate
                x = Helper.getXForTimestamp(data.date);
                ctx.rect(lastX, rectY, x - lastX, rectHeight);
            }

            data.check();
        };

        return {
            draw: draw
        };
    };
});
