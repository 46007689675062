import {LxReactPressable, LxReactText} from "./Components";
import {View} from "react-native";
import globalStyles from "GlobalStyles";
import {useState} from "react";
import {useSafeAreaInsets} from "react-native-safe-area-context";

export default function BackToPrevMiniserver({children}) {

    const insets = useSafeAreaInsets();
    const [renderBar, setRenderBar] = useState(!!NavigationComp.getPreviousMsInfo());

    const backToPrev = () => {
        const {serialNo} = NavigationComp.getPreviousMsInfo();
        const prevMs = PersistenceComponent.getMiniserver(serialNo);
        if (prevMs) {
            NavigationComp.connectTo(prevMs);
        } else {
            console.error(BackToPrevMiniserver.name, "Previous Miniserver not found in Archive! snr=" + serialNo);
            setRenderBar(false);
            NavigationComp.resetPreviousMsInfo();
        }
    }
    const dismiss = () => {
        setRenderBar(false);
        NavigationComp.resetPreviousMsInfo();
    }

    const TopBar = ({render}) => {
        if (!render || !NavigationComp.getPreviousMsInfo()) {
            return null;
        }
        const {msName} = NavigationComp.getPreviousMsInfo() ;

        return <View style={styles.top}>
            <View style={styles.bar}>
                <LxReactPressable onPress={backToPrev} style={styles.left}>
                    <LxReactText style={styles.text}>
                        <LxReactText style={styles.text}>{_("ms-shortcut.header.back-to") + " "}</LxReactText>
                        <LxReactText style={styles.thickText}>{msName}</LxReactText>
                    </LxReactText>
                </LxReactPressable>

                <LxReactPressable onPress={dismiss} style={styles.right}>
                    <LxReactText style={styles.thickText}>{_("ms-shortcut.header.dismiss")}</LxReactText>
                </LxReactPressable>
            </View>
        </View>
    }

    // component is shown as custom header - add the offset for the safe area! (not done automatically for custom hdrs)
    return <View style={[styles.root, { marginTop: (insets.top || globalStyles.spacings.gaps.verySmall)}] }>
        <TopBar render={renderBar} />
        {children}
    </View>
}


const styles = {
    root: {
        flexDirection: "column",
        flex: 1,
        width: "100%",
        height: "100%"
    },
    top: {
        height: 40,
        width: "100%"
    },
    bar: {
        width: "100%",
        height: "100%",
        maxWidth: 480,
        marginHorizontal: "auto",
        borderRadius: 8,
        flexDirection: "row",
        backgroundColor: globalStyles.colors.fill.tertiary,
        alignItems: "center",
        paddingHorizontal: globalStyles.spacings.gaps.regular
    },
    text: {
        ...globalStyles.textStyles.subheadline.regular,
        color: globalStyles.colors.white
    },
    thickText: {
        ...globalStyles.textStyles.subheadline.strong,
        color: globalStyles.colors.white
    },
    left: {
        flex: 1,
    },
    right: {
        marginLeft: globalStyles.spacings.gaps.verySmall,
    }
}
