import {ControlContext, LxBaseControlContent, LxReactFlexibleCell} from "../../../react-comps/Components";
import {ScreenState} from "../alarmClockControlEnums";
import globalStyles from "../../../../styles/react/styles";
import {useContext} from "react";
import {useNavigation} from "@react-navigation/native";

function AlarmClockControlContent(props) {
    const {control} = useContext(ControlContext)
    const navigation = useNavigation();

    function SettingsCell(props) {
        return <LxReactFlexibleCell section={0} row={0} title={_("settings")} disclosureIcon={true} onPress={() => navigation.navigate(ScreenState.DEVICE_SETTINGS)}
                                    containerStyle={globalStyles.customStyles.fullWidth}/>
    }

    return <LxBaseControlContent>
        {showSettingsCell(control) && <SettingsCell />}
    </LxBaseControlContent>
}

const showSettingsCell = (control) => {
    return Feature.ALARM_CLOCK_NIGHT_LIGHT_DEVICE_SETTINGS && control.hasNightLightTouch()
}


export default AlarmClockControlContent