'use strict';

window.GUI = function (GUI) {
    class ActiveMiniserverViewController extends GUI.ViewController {
        constructor(details) {
            super($("<active-miniserver-view />"));
            this.currentMiniserver = ActiveMSComponent.getActiveMiniserver();
            this.addMultipleView(ScreenState.ControlContent);
            this.addMultipleView(ScreenState.AudioZoneControlContent);
            this.addMultipleView(ScreenState.AudioZoneV2ControlContent);
            this.addMultipleView(ScreenState.ControlContentAlert);
            /*if (details) {
             if (details.subScreen) {
             this.showState(details.subScreen, null, details.subScreenDetails);
             } else {
             this._showDefaultScreen();
             }
             } else {
             this._showDefaultScreen();
             }*/
        }

        viewWillAppear() {
            // to avoid unneccessary operations (e.g. audio player views trying to access the audioserverComp which
            // isn't there anymore) - detect an activeMS Change and if so, resolve without any constructive view-
            // livecycle calls.
            if (this._didActiveMiniserverChange("viewWillAppear")) {
                return Q.resolve();
            }

            return super.viewWillAppear(...arguments);
        }

        viewDidAppear() {
            // to avoid unneccessary operations (e.g. audio player views trying to access the audioserverComp which
            // isn't there anymore) - detect an activeMS Change and if so, resolve without any constructive view-
            // livecycle calls.
            if (this._didActiveMiniserverChange("viewDidAppear")) {
                return Q.resolve();
            }

            return super.viewDidAppear(...arguments);
        }

        showState(state, view, details, animationType) {
            switch (state) {
                case ScreenState.FavoritesV2:
                case ScreenState.House:
                case ScreenState.Rooms:
                case ScreenState.Categories:
                case ScreenState.Trust:
                    details = {
                        screenState: state
                    };
                    state = ScreenState.ActiveMiniserverScreen;
                    break;

                case ScreenState.CategoryDetail:
                case ScreenState.TrustListScreen:
                case ScreenState.RoomDetail:
                    details = {
                        screenState: state,
                        details: details
                    };
                    state = ScreenState.ActiveMiniserverScreen;
                    break;

                default:
                    break;
            }

            if (state === ScreenState.ActiveMiniserverScreen && this.viewStack.contains(ScreenState.ActiveMiniserverScreen)) {
                // we have the ActiveMiniserverScreen in the history, that means, we currently show another view (eg. settings, alert..)
                // what we have to do is: navigate back until the ActiveMiniserverScreen, and everything will be okay again :-)
                return this.navigateBackTo(ScreenState.ActiveMiniserverScreen).then(super.showState() // don't use apply arguments! (are adopted)
                );
            }

            return super.showState(state, view, details, animationType); // don't use apply arguments! (are adopted)
        }

        hasHistory() {
            return false;
        }

        getAnimation() {
            return AnimationType.NONE;
        }

        getURL() {
            let currentViewUrl = super.getURL();
            return this.currentMiniserver.serialNo + (currentViewUrl ? "/" + currentViewUrl : "");
        }

        destroyOnBackNavigation() {
            return true;
        }

        destroy() {
            this.currentMiniserver = null;

            if (this._currentActivity) {
                this.stopActivity(this._currentActivity);
            }

            return super.destroy();
        }

        updateBackground(bgSrc) {
            this.element.css("background", "url('" + bgSrc + "') no-repeat center center fixed");
        }

        // Public Methods

        /**
         * starts an activity (TaskRec, Position, Command)
         * @param activityType TaskRec, Position, Command
         * @param [type] only for NFC, QR
         * @param details only for Autopilot (contains rule)
         */
        startActivity(activityType, type, details) {
            if (this._currentActivity) {
                this.stopActivity(this._currentActivity);
            }

            this._currentActivity = activityType;

            if (HD_APP || activityType === GUI.ActiveMiniserverViewController.Activity.TaskRecorder || activityType === GUI.ActiveMiniserverViewController.Activity.Autopilot) {
                this.element.addClass("activity-running");
            }

            if (activityType === GUI.ActiveMiniserverViewController.Activity.TaskRecorder) {
                this._startTaskRecorder(details);
            } else if (activityType === GUI.ActiveMiniserverViewController.Activity.Autopilot) {
                this._startAutopilot(details);
            } else if (activityType === GUI.ActiveMiniserverViewController.Activity.Position) {
                this._startPositionActivity(type);
            } else if (activityType === GUI.ActiveMiniserverViewController.Activity.Command) {
                this._startCommandActivity(type);
            }
        }

        /**
         * stops the current activity
         * @param activityType
         */
        stopActivity(activityType) {
            if (this._currentActivity !== activityType) {
                console.error("can't stop an activity which isn't running! (running: " + this._currentActivity + ", to stop: " + activityType + ")");
                return;
            }

            delete this._currentActivity;
            SandboxComponent.stopTaskRecording();

            if (HD_APP || activityType === GUI.ActiveMiniserverViewController.Activity.TaskRecorder || activityType === GUI.ActiveMiniserverViewController.Activity.Autopilot) {
                this.element.removeClass("activity-running");
                this.removeSubview(this.activityBar);
                this.activityBar = null;
            } else {
                this.removeSubview(this.instructionNotification);
                this.instructionNotification = null;
            }
        }

        // Private Methods

        /*_showDefaultScreen: function _showDefaultScreen() {
                     if(HD_APP && MergeHdAndFavs) {
                     this.showState(ScreenState.HomeAndFavorites);
                     } else if (NavigationComp.getHomeScreenAvailability()) {
                     this.showState(ScreenState.Home);
                     } else {
                     this.showState(ScreenState.Favorites);
                     }
                     },*/

        /**
         * Starts the TaskRecording by appending the TaskRecordingBar
         * @param [details] Only required for TaskRecordingV2 (AutomaticDesigner)
         * @private
         */
        _startTaskRecorder(details) {
            if (Feature.AUTOMATIC_DESIGNER_TASK_RECORDER && details) {
                this.activityBar = new GUI.TaskRecordingV2Bar(details);
            } else {
                this.activityBar = new GUI.TaskRecordingBar();
            }

            this.appendSubview(this.activityBar);
        }

        _startAutopilot(rule) {
            this.activityBar = new GUI.AutopilotBar(rule);
            this.appendSubview(this.activityBar);
        }

        _startPositionActivity(type) {
            if (HD_APP) {
                this.activityBar = new GUI.PositionInstructionBar(type);
                this.appendSubview(this.activityBar);
            } else {
                this.instructionNotification = new GUI.PositionInstructionNotification(type);
                this.appendSubview(this.instructionNotification);
            }
        }

        _startCommandActivity(type) {
            if (HD_APP) {
                this.activityBar = new GUI.CommandInstructionBar(type);
                this.appendSubview(this.activityBar);
            } else {
                this.instructionNotification = new GUI.CommandInstructionNotification(type);
                this.appendSubview(this.instructionNotification);
            }
        }

        _didActiveMiniserverChange(method) {
            var activeMiniserver = ActiveMSComponent.getActiveMiniserver(),
                didChange = !activeMiniserver || activeMiniserver.serialNo !== this.currentMiniserver.serialNo;

            if (didChange) {
                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
                console.log(this.viewId, "The active miniserver did change! " + method + " won't be passed on, but resolve");
                console.log(this.viewId, "   viewController was initialized with: " + this.currentMiniserver.serialNo);
                console.log(this.viewId, "     but now a different one is active: " + (activeMiniserver ? activeMiniserver.serialNo : "-none-"));
                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
            }

            return didChange;
        }

    }

    GUI.ActiveMiniserverViewController = ActiveMiniserverViewController;
    GUI.ActiveMiniserverViewController.Activity = {
        TaskRecorder: "activity--task-recorder",
        Position: "activity--position",
        Command: "activity--command",
        Autopilot: "activity--autopilot"
    };
    return GUI;
}(window.GUI || {});
