import {useContext, useMemo} from "react";
import {View} from "react-native-web";
import {LxReactTableView, LxReactControl, ControlContext} from "LxComponents";
import globalStyles from "GlobalStyles";

function LxControlLinkedControls(props) {
    const {control} = useContext(ControlContext);

    const allInSameRoom = useMemo(() => {
        if (control) {
            if (control.linkedControls.length === 1) {
                return true;
            }
            return control.linkedControls.every(linkedCtrl => {
                return linkedCtrl.room === control.room;
            });
        } else {
            return false;
        }
    }, [control])

    const linkedControlsContent = useMemo(() => {
        var content = [],
            section = {};

        if (control) {
            section = {
                headerStrongTitle: _("control.linked-controls"),
                rows: control.linkedControls.map((linkedCtrl) => {
                    return {
                        mainContent: {
                            comp: LxReactControl,
                            props: {
                                key: linkedCtrl.uuidAction,
                                controlUuid: linkedCtrl.uuidAction,
                                displayAsCell: true,
                                showGroupDetails: !allInSameRoom
                            }
                        },
                        mainContentContainerStyle: {paddingVertical:0}, // rm flexible cells paddings
                        cellContainerStyle: {paddingHorizontal:0} // rm flexible cells paddings
                    }
                })
            }

            if (section.rows.length > 0) {
                /*section.rows.push({
                    action: function () {
                        // TODO: open something for LinkedControls Management
                    }.bind(this),
                    content: {
                        title: "Manage linked objects", // Add Translated Text
                        titleStyle: {
                            color: globalStyles.colors.brand
                        },
                        clickable: true
                    }
                }); */
                content.push(section);
            }
        }

        return content;
    }, [control, allInSameRoom]);

    return <View style={styles.container}>
        {control && control.hasLinkedControls && <LxReactTableView styles={styles.tableViewStyles} tableContent={linkedControlsContent}/>}
    </View>
}

const styles = {
    container: {
        width: "100%"
    },
    tableViewStyles: {
        List: {
            paddingHorizontal: 0
        }
    }
}

export default LxControlLinkedControls;
