'use strict';

define("AudioZoneControlContentRemote", ['ControlActionCellsScreen', 'MediaBrowserBase', 'MediaBrowserService', 'MediaBrowserService7', 'MediaBrowserService11', 'MediaBrowserService12', 'MediaBrowserFavorites', 'MediaBrowserLineIn', 'MediaBrowserLibrary', 'MediaBrowserPlaylists', 'AddMediaViewControllerBase', 'AddMediaViewControllerZoneFavorite', 'AddMediaViewControllerPlaylist', 'AddMediaViewControllerPlaylistPicker', 'AddMediaViewControllerPlaylistSpotify'], function (ControlActionCellsScreen) {
    return class AudioZoneControlContentRemote extends ControlActionCellsScreen {
        //region Static
        static Template = class {
            //region Static
            static getRemoteInfo() {
                return $("<div class='content__info-bar'>" + "   <div class='info-bar__text'>" + _("miniserver.connected.external") + "</div>" + "   " + ImageBox.getResourceImageWithClasses(Icon.INFO2, 'info-bar__icon') + "</div>");
            } //endregion Static


        }; //endregion Static

        constructor() {
            super(...arguments);
            Object.assign(this, MediaStateHandler.Mixin);
            this.zoneFavs = [];
            this.favs = [];
        }

        setSubtitle() {
            return null;
        }

        shouldDisplayStateIcons() {
            return false;
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                this.elements.remoteInfo = AudioZoneControlContentRemote.Template.getRemoteInfo();
                this.elements.remoteInfo.insertBefore(this.elements.content);
                this.remoteInfoButton = new GUI.LxButton(this, this.elements.remoteInfo, Color.BUTTON_GLOW, null, true);
                this.addToHandledSubviews(this.remoteInfoButton);
                this.remoteInfoButton.onButtonTapped = MediaServerComp.showRestrictedPopup.bind(MediaServerComp);
                MediaServerComp.setAudioViewsVisible(true, this.control, this);

                this._registerForMediaServerEvents(this.EVENT_TYPES.ZONE_FAVORITES, this.EVENT_TYPES.FAVORITES);
            }.bind(this));
        }

        destroy() {
            MediaServerComp.setAudioViewsVisible(false, this.control, this);

            this._unregisterForMediaServerEvents(this.EVENT_TYPES.ZONE_FAVORITES, this.EVENT_TYPES.FAVORITES);

            this.ViewController.setReady(false);
            return super.destroy(...arguments);
        }

        /**
         * Received all zoneFavorites, reload the table
         */
        receivedZoneFavoritesAllDataEvent(data) {
            this.zoneFavs = data.items.map(function (item) {
                Controls.AudioZoneControl.MediaBrowserBase.applyContentTypeToItem(item, this.EVENT_TYPES.ZONE_FAVORITES);
                return item;
            }.bind(this));

            this._updateCommandsTable(this.control.getStates());
        }

        /**
         * We have to reload the zoneFavorites, just empty the zoneFavorites and reload the table
         * @param reason
         */
        receivedZoneFavoritesReloadEvent(reason) {
            this.zoneFavs = [];
            return this._updateCommandsTable(this.control.getStates());
        }

        /**
         * Received all favorites, reload the table
         */
        receivedFavoritesAllDataEvent(data) {
            this.favs = data.items.map(function (item) {
                Controls.AudioZoneControl.MediaBrowserBase.applyContentTypeToItem(item, this.EVENT_TYPES.FAVORITES);
                return item;
            }.bind(this));
            return this._updateCommandsTable(this.control.getStates());
        }

        /**
         * We have to reload the favorites, just empty the zoneFavorites and reload the table
         * @param reason
         */
        receivedFavoritesReloadEvent(reason) {
            this.favs = [];
        }

        /**
         * Limit to much updates by just subscribing to those states
         * @returns {[string, string]}
         */
        getStateIDs() {
            return ["serverState", "universalIsLocked"];
        }

        receivedStates(states) {
            this.ViewController.setReady(states.serverState === MediaEnum.ServerState.ONLINE && !states.universalIsLocked);
            return super.receivedStates(...arguments);
        }

        getTableContent(states) {
            var tableContent = [];

            if (this.zoneFavs.length) {
                tableContent.push({
                    headerTitle: _("media.zone.x", {
                        name: this.control.getName()
                    }),
                    rows: this.getCellsForMediaItems(this.zoneFavs)
                });
            }

            if (this.favs.length) {
                tableContent.push({
                    headerTitle: _("media.favorites.all"),
                    rows: this.getCellsForMediaItems(this.favs)
                });
            }

            return tableContent;
        }

        /**
         * Returns a cell for the given mediaType
         * @param mediaItems
         * @returns {*}
         */
        getCellsForMediaItems(mediaItems) {
            return mediaItems.map(function (item) {
                var ctor = Controls.AudioZoneControl.MediaBrowserBase.getConstructorForItem(item),
                    cell = ctor.getCellFromContentTypeItem(item, {}, item.contentType, false, false, this.playItemInList.bind(this, item, mediaItems), null, this.ViewController, false); // Manipulate the cell to...
                // ...not contain the context menu button

                cell.type = GUI.TableViewV2.CellType.GENERAL; // ...always show the Star icon

                cell.content.leftIconSrc = Icon.AudioZone.NEW.STAR;
                cell.content.leftIconColor = Color.TEXT_SECONDARY_B;
                cell.content.iconSrc = Icon.AudioZone.NEW.STAR;
                cell.content.iconColor = Color.TEXT_SECONDARY_B;
                cell.content.leftIconBgEnabled = true;
                cell.content.leftIconLarger = false;
                return cell;
            }.bind(this));
        }

        /**
         * Playes the given item in the given list of items
         * @param item
         * @param list
         * @returns {boolean|*}
         */
        playItemInList(item, list) {
            if (item.contentType === this.EVENT_TYPES.ZONE_FAVORITES) {
                return MediaServerComp.sendCommandToServer(this.control, Commands.format(Commands.MEDIACLIENT.SOURCE, item.slot));
            } else {
                return MediaServerComp.sendPlayerCommandFromType(item, list.indexOf(item), {
                    username: MediaEnum.NOUSER,
                    mediaType: MediaEnum.MediaType.FAVORITES,
                    lastSelectedItem: {
                        name: this.name
                    }
                });
            }
        }

        // TableView DataSource Methods
        styleForTableView() {
            return HD_APP ? TableViewStyle.TRANSLUCENT : TableViewStyle.COMFORT_MODE_2020.replace("grouped", "translucent");
        }

    };
});
