"use strict";

define("PoolControlContentTemperatureModeList", ["PoolControlEnums"], function (PoolControlEnums) {
    return class PoolControlContentTemperatureModeList extends Controls.ControlContent {
        constructor(details) {
            super(details);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.tableView = new GUI.TableViewV2(this, this);
            this.tableView.getElement().addClass("temperature-mode-list__table-view");
            this.appendSubview(this.tableView);
        }

        getURL() {
            return "ModeList";
        }

        titleBarText() {
            return _("pool.control.mode");
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK,
                style: "transparent"
            };
        }

        receivedStates(states) {
            this._getTableViewContent(states);

            this.tableView.reload();
        }

        // TableViewDataSource Methods
        styleForTableView(tableView) {
            return HD_APP ? TableViewStyle.GROUPED : null;
        }

        titleForHeaderInSection(section) {
            return this.tableViewContent[section].sectionTitle;
        }

        numberOfSections() {
            return this.tableViewContent.length;
        }

        numberOfRowsInSection(section) {
            return this.tableViewContent[section].cells.length;
        }

        cellTypeForCellAtIndex(section, row) {
            return this.tableViewContent[section].cells[row].cellType;
        }

        contentForCell(cell, section, row) {
            return this.tableViewContent[section].cells[row].content;
        }

        // TableViewDelegate Methods
        didCheckCell(cell, section, row) {
            var tempModeID = this.temperatureIDs[section][row];
            this.sendCommand(Commands.format(Commands.POOL.SET_TEMP_MODE, tempModeID));
        }

        // Private methods
        _getTableViewContent(states) {
            this.tableViewContent = [];
            this.temperatureIDs = [];

            if (this.control.details.hasHeating && this.control.details.hasCooling) {
                this.tableViewContent.push({
                    sectionTitle: "",
                    cells: [{
                        cellType: GUI.TableViewV2.CellType.CHECKABLE,
                        content: {
                            title: _("autopilot"),
                            subtitle: _("pool.control.temp-mode.full-automatic.subtitle"),
                            selected: states.currentTempModeNr === PoolControlEnums.PoolTempMode.FULL_AUTO,
                            radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE
                        }
                    }]
                });
                this.temperatureIDs.push([PoolControlEnums.PoolTempMode.FULL_AUTO]);
            }

            var temperatureCells = [],
                tempIDs = [];

            if (this.control.details.hasHeating) {
                temperatureCells.push({
                    cellType: GUI.TableViewV2.CellType.CHECKABLE,
                    content: {
                        title: _("pool.control.temp-mode.automatic.heating"),
                        subtitle: _("pool.control.temp-mode.automatic.heating.subtitle"),
                        selected: states.currentTempModeNr === PoolControlEnums.PoolTempMode.AUTO_HEATING,
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE
                    }
                });
                tempIDs.push(PoolControlEnums.PoolTempMode.AUTO_HEATING);
            }

            if (this.control.details.hasCooling) {
                temperatureCells.push({
                    cellType: GUI.TableViewV2.CellType.CHECKABLE,
                    content: {
                        title: _("pool.control.temp-mode.automatic.cooling"),
                        subtitle: _("pool.control.temp-mode.automatic.cooling.subtitle"),
                        selected: states.currentTempModeNr === PoolControlEnums.PoolTempMode.AUTO_COOLING,
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE
                    }
                });
                tempIDs.push(PoolControlEnums.PoolTempMode.AUTO_COOLING);
            }

            if (this.control.details.hasHeating) {
                temperatureCells.push({
                    cellType: GUI.TableViewV2.CellType.CHECKABLE,
                    content: {
                        title: _("pool.control.temp-mode.manuel.heating"),
                        subtitle: _("pool.control.temp-mode.manuel.heating.subtitle"),
                        selected: states.currentTempModeNr === PoolControlEnums.PoolTempMode.MAN_HEATING,
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE
                    }
                });
                tempIDs.push(PoolControlEnums.PoolTempMode.MAN_HEATING);
            }

            if (this.control.details.hasCooling) {
                temperatureCells.push({
                    cellType: GUI.TableViewV2.CellType.CHECKABLE,
                    content: {
                        title: _("pool.control.temp-mode.manuel.cooling"),
                        subtitle: _("pool.control.temp-mode.manuel.cooling.subtitle"),
                        selected: states.currentTempModeNr == PoolControlEnums.PoolTempMode.MAN_COOLING,
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE
                    }
                });
                tempIDs.push(PoolControlEnums.PoolTempMode.MAN_COOLING);
            }

            this.tableViewContent.push({
                sectionTitle: "",
                cells: temperatureCells
            });
            this.temperatureIDs.push(tempIDs);
            this.tableViewContent.push({
                sectionTitle: "",
                cells: [{
                    cellType: GUI.TableViewV2.CellType.CHECKABLE,
                    content: {
                        title: _("pool.control.temp-mode.off"),
                        selected: states.currentTempModeNr === PoolControlEnums.PoolTempMode.OFF,
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE
                    }
                }]
            });
            this.temperatureIDs.push([PoolControlEnums.PoolTempMode.OFF]);
        }

    };
});
