import * as React from "react"
import Svg, { Path } from "react-native-svg"

function ArrowUp(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path
                fillRule="evenodd"
                d="M11.336 6.253a1 1 0 011.328 0l9 8a1 1 0 01-1.328 1.494L12 8.337l-8.336 7.41a1 1 0 01-1.328-1.494l9-8z"
                clipRule="evenodd"
            />
        </Svg>
    )
}

export default ArrowUp
