'use strict';

define('DaytimerControlStateContainerBase', ["ControlStateContainer"], function (ControlStateContainer, DaytimerControlEnums) {
    return class DaytimerControlStateContainerBase extends ControlStateContainer {
        constructor(control) {
            super(control);
        }

        prepareStates(newVals) {
            // map the entries
            this.states.entries = this.mapEntries(newVals[this.control.states.entriesAndDefaultValue].entries); // check equal modes of entries

            this.checkEqualModes(this.states.entries); // split up used modes

            this.states.usedModes = this.getUsedModesArray(newVals[this.control.states.modeList]);
        }

        mapEntries(entries) {
            if (!entries) {
                return {};
            }

            var entry,
                entryMap = {};

            for (var i = 0; i < entries.length; i++) {
                entry = entries[i];

                if (!this.control.details.analog) {
                    entry.value = 1;
                }

                delete entry.nr;

                if (entryMap[entry.mode]) {
                    entryMap[entry.mode].push(entry);
                } else {
                    entryMap[entry.mode] = [entry];
                }
            }

            return entryMap;
        }

        getUsedModesArray(textEvent) {
            var usedModes = [];

            if (textEvent.text) {
                // sometimes textevent = 0 (after entry changes)
                var modesStringArray = textEvent.text.split(',');

                for (var i = 0; i < modesStringArray.length; i++) {
                    var modeInfo = modesStringArray[i].split(';');
                    var modeNumber = modeInfo[0].split(':')[1].split('=')[1];
                    usedModes.push(modeNumber);
                }
            }

            return usedModes;
        }

        checkEqualModes(entries) {
            // iterate
            var entriesArray, entry, arrayToCheck;

            for (var modeNr in entries) {
                if (entries.hasOwnProperty(modeNr)) {
                    // entries to check
                    entriesArray = entries[modeNr];

                    for (var i = 0; i < entriesArray.length; i++) {
                        entry = entriesArray[i]; // checking

                        for (var modeNrToCheck in entries) {
                            if (entries.hasOwnProperty(modeNrToCheck)) {
                                // entries of checked mode
                                arrayToCheck = entries[modeNrToCheck];

                                for (var j = 0; j < arrayToCheck.length; j++) {
                                    var entryToCheck = arrayToCheck[j]; // compare

                                    if (entry.from === entryToCheck.from && entry.to === entryToCheck.to) {
                                        if (entry.value === entryToCheck.value && entry.needActivate === entryToCheck.needActivate) {
                                            if (entry.equalModes) {
                                                entry.equalModes.push(modeNrToCheck);
                                            } else {
                                                entry.equalModes = [modeNrToCheck];
                                            }

                                            break;
                                        }
                                    }
                                }
                            }
                        } // go on with next entry of iterating mode

                    }
                } // go on with the next mode

            }
        }

    };
});
