import {JalousieMode} from "./jalousieControlEnums";

export default class JalousieControlStatesSrc {
    static getStateSections(control, states) {
        let stateSections = [];

        if (control.details.isAutomatic) {
            stateSections.push({
                rows: [{
                    type: GUI.TableViewV2.CellType.SWITCH,
                    content: {
                        title: _("controls.jalousie.sun-pos-shading.general"),
                        active: states.jalousieMode === JalousieMode.AUTO_ACTIVE,
                        disabled: states.locked || states.safetyActive || !states.autoAllowed
                    },
                    onSwitchChanged: (value, section, row, tableView, cell) => {
                        control.sendCommand(value ? Commands.JALOUSIE.AUTO_ON : Commands.JALOUSIE.AUTO_OFF);
                    }
                }]
            });
        }

        return stateSections;
    }
}