'use strict';

define(["./iconLibShowcaseScreen.jsx"], function (IconLibShowCaseScreen) {
    return class IconLibShowcase extends GUI.Screen {

        static ReactComp = IconLibShowCaseScreen.default;

        constructor() {
            super($('<div/>'));
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

    };
});
