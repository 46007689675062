'use strict';

define([ "ControlActionCellsScreen", "LxComponents" ], function (ControlActionCellsScreen, {
    LxLegacyReactControlContent
}) {
    return class EnergyFlowMonitorControlContent extends ControlActionCellsScreen {

        static ReactComp = LxLegacyReactControlContent;

        constructor() {
            super(...arguments);
        }
    };
});
