'use strict';

window.GUI = function (GUI) {
    class EditableTableView extends GUI.TableViewV2 {
        /**
         * @param {*} dataSource - context for datasource of the tableView
         * @param {*} delegate - context for callbacks from the tableView and cells
         * @param {jQuery} elem if set, this element turns into the TableViewV2, otherwise a new element will be created
         * @param section
         * @param editing
         * @param removeEnabled
         * @param sortEnabled
         * @constructor
         */
        constructor(dataSource, delegate, elem, section, editing, removeEnabled, sortEnabled) {
            super(...arguments);
            section = section || 0;
            this.editSectionsMap = {};
            this.editSectionsMap[section] = {
                editActive: editing ? editing : false,
                removeEnabled: removeEnabled ? removeEnabled : false,
                sortEnabled: sortEnabled ? sortEnabled : false
            };
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function (res) {
                GUI.animationHandler.addCssClass(this.element, "editable-table-view");
                return res;
            }.bind(this));
        }

        reload() {
            if (this._isMovingCells) {
                Debug.GUI.EditableTableView && console.warn(this.viewId, "reload -- store for later, moving");
                var args = arguments;
                return this._movingCellsDeferred.promise.then(function () {
                    return this.reload.apply(this, args);
                }.bind(this));
            }

            Debug.GUI.EditableTableView && console.log(this.viewId, "reload"); // respondAfterReload is called by _internalHandleReloadPassed, as this is done before the promise resolves
            // and the dataSource and delegate are updated!

            this._whereAllRowsEditable = this._areAllRowsEditable();
            return super.reload(...arguments);
        }

        /**
         * Used by lxEditableTableView to perform operations before a potentially enqueued operation reload is
         * started, the unlock is performed, the processAfterReload is called or the calling views promise resolves.
         * MUST return a promise!
         * @returns {Q.Promise<unknown>}
         * @private
         */
        _internalHandleReloadPassed() {
            this._respondAfterReload(this._whereAllRowsEditable);

            return Q.resolve(); // respond after reload doesn't return a promise;
        }

        /**
         * Intercept in order to ensure that all rows that have been added are also editable. But only if all rows
         * after the start index have been editable before.
         * @param sectionIdx
         * @param startIndex
         * @param nCells
         */
        reloadRowsSelective(sectionIdx, startIndex, nCells) {
            if (this._isMovingCells) {
                Debug.GUI.EditableTableView && console.warn(this.viewId, "reloadRowsSelective -- store for later, moving");
                var args = arguments;
                return this._movingCellsDeferred.promise.then(function () {
                    return this.reload();
                }.bind(this));
            } // use reload() wherever possible to avoid issues with the rather unsafe (no lock/unlock and so on)
            // reloadRowsSelective.


            if (this.smartReloadCapableDataSourceAndDelegate()) {
                console.warn(this.viewId, "reloadRowsSelective - smart reload capable, use regular reload!", getStackObj());
                return this.reload();
            } else {
                Debug.GUI.EditableTableView && console.log(this.viewId, "reloadRowsSelective: sec=" + sectionIdx + " start=" + startIndex + " n=" + nCells);

                var allRowsEditable = this._areAllRowsEditable();

                return Q(super.reloadRowsSelective(...arguments) || true).then(function () {
                    this._respondAfterReload(allRowsEditable, sectionIdx);
                }.bind(this));
            }
        }

        /**
         * Will return an array with a value for each section if all containing rows are in edit mode
         * true - all rows of this section are in edit mode
         * false - at least one row is not in edit mode
         * @returns {*[]}
         * @private
         */
        _areAllRowsEditable() {
            var allRowsEditable = [];

            for (var i = 0; i < Object.keys(this.table).length; i++) {
                if (this.editSectionsMap.hasOwnProperty(i)) {
                    if (this.table[i] && this.editSectionsMap[i].numberOfEditableCells === this.table[i].length) {
                        allRowsEditable.push(true);
                    } else {
                        allRowsEditable.push(false);
                    }
                } else {
                    allRowsEditable.push(false);
                }
            }

            return allRowsEditable;
        }

        /**
         * Will reset the editing mode after a reload. Will also set the edit mode to new cells if previously all
         * cells have been editable.
         * @param allRowsEditable   whether or not all cells of the sectionToEdit where editable previously.
         * @param [sectionIdx]         the sectionIdx that was reloaded - if selective reloading was used.
         * @private
         */
        _respondAfterReload(allRowsEditable, sectionIdx) {
            Debug.GUI.TableView && console.log(this.viewId, "_respondAfterReload: allRows: [" + allRowsEditable + "], section: " + sectionIdx);
            var sectionToEdit = {},
                newEditableRowCount;

            if (sectionIdx === undefined) {
                Debug.GUI.TableView && console.log(this.viewId, "    iterate over all sections");

                for (sectionIdx = 0; sectionIdx < Object.keys(this.table).length; sectionIdx++) {
                    if (this.editSectionsMap.hasOwnProperty(sectionIdx) && this.editSectionsMap[sectionIdx].editActive) {
                        sectionToEdit = this.editSectionsMap[sectionIdx];
                        newEditableRowCount = sectionToEdit.numberOfEditableCells;

                        if (allRowsEditable[sectionIdx]) {
                            Debug.GUI.TableView && console.log(this.viewId, "        ** section " + sectionIdx + " all rows editable!");
                            newEditableRowCount = this.table[sectionIdx].length - sectionToEdit.editStartIndex;
                        }

                        Debug.GUI.TableView && console.log(this.viewId, "   -section " + sectionIdx + " - newEditableRowCount: " + newEditableRowCount);
                        this.setEditingModeForSection(sectionIdx, sectionToEdit.editActive, sectionToEdit.removeEnabled, sectionToEdit.sortEnabled, sectionToEdit.editStartIndex, newEditableRowCount);
                    }
                }
            } else if (this.editSectionsMap[sectionIdx]) {
                sectionToEdit = this.editSectionsMap[sectionIdx];
                newEditableRowCount = sectionToEdit.numberOfEditableCells;

                if (allRowsEditable[sectionIdx]) {
                    Debug.GUI.TableView && console.log(this.viewId, "        ** section " + sectionIdx + " all rows editable!");
                    newEditableRowCount = this.table[sectionIdx].length - sectionToEdit.editStartIndex;
                } // if a selective reloading was used, only respond if the section was reloaded which is being edited.


                if (sectionToEdit.editActive) {
                    Debug.GUI.TableView && console.log(this.viewId, "   -section " + sectionIdx + " - newEditableRowCount: " + newEditableRowCount);
                    this.setEditingModeForSection(sectionIdx, sectionToEdit.editActive, sectionToEdit.removeEnabled, sectionToEdit.sortEnabled, sectionToEdit.editStartIndex, newEditableRowCount);
                }
            }
        }

        destroy() {
            this.sortableContainer && this.sortableContainer.destroy();
            this.sortableContainer = null;
            return super.destroy();
        }

        setUpSection(sectionIdx) {
            return super.setUpSection(...arguments).then(function () {
                var hasTitle = !!this.dataSource.rightButtonTitleForHeaderInSection;

                if (hasTitle) {
                    var title = this.dataSource.rightButtonTitleForHeaderInSection.call(this.dataSource, sectionIdx, this);
                    hasTitle = !!title && title !== "";
                }

                if (hasTitle && !this.delegate.rightSectionButtonTapped) {
                    //Fixes issue BG-I6380: tap-event is fired twice on iPadOS >= 13.4 (related to Mouse Support OR Desktop Safari mode)
                    var useTapEvent = true;

                    if (PlatformComponent.isIpad && parseInt(PlatformComponent.getPlatformInfoObj().version.split(".")[0]) === 13 && parseInt(PlatformComponent.getPlatformInfoObj().version.split(".")[1]) >= 4) {
                        useTapEvent = false;
                    }

                    var event = useTapEvent ? tapEvent() : "click";
                    this.eventHandlers[this.eventHandlers.length - 1].on(event, function () {
                        var section = this.editSectionsMap[sectionIdx],
                            newEditableRowCount,
                            allRowsEditable = this._areAllRowsEditable();

                        if (allRowsEditable[sectionIdx]) {
                            newEditableRowCount = this.table[sectionIdx].length;
                        }

                        this.setEditingModeForSection(sectionIdx, !section.editActive, section.removeEnabled, section.sortEnabled, section.editStartIndex, newEditableRowCount);
                    }.bind(this));
                }

                return Q(true);
            }.bind(this));
        }

        // Public methods
        setEditingModeForSection(section, editing, removeEnabled, sortEnabled, startIdx, numberOfCells) {
            Debug.GUI.TableView && console.log(this.viewId, "setEditingModeForSection", section, getStack()[1], getStack()[2]);
            var sectionMapObj = this.editSectionsMap[section] || {};
            sectionMapObj.sectionToEdit = section;
            sectionMapObj.editActive = editing;
            sectionMapObj.removeEnabled = removeEnabled;
            sectionMapObj.sortEnabled = sortEnabled;
            sectionMapObj.editStartIndex = startIdx ? startIdx : 0;
            sectionMapObj.numberOfEditableCells = typeof numberOfCells === "number" ? numberOfCells : this.dataSource.numberOfRowsInSection.call(this.dataSource, section, this); // We need to reset the sortableContainer or we will get unwanted behaviour when sorting again (Switching between non editing - editing - non editing - editing)

            if (!editing) {
                sectionMapObj.sortableContainer && sectionMapObj.sortableContainer.destroy();
                sectionMapObj.sortableContainer = null;
            }

            this.editSectionsMap[section] = sectionMapObj;
            Debug.GUI.TableView && console.log(this.viewId, " > passed setEditingModeForSection - now updateCellsInSection " + section);
            return this._updateCellsInSection(section);
        }

        setEditingModeForAllSections(editing) {
            var amountOfSections = this.dataSource.numberOfSections(),
                iterator;

            for (iterator = 0; iterator < amountOfSections; iterator++) {
                this.setEditingModeForSection(iterator, editing, false, editing, null, null, true);
            }
        }

        // Private methods
        _updateCellsInSection(sectionIdx) {
            Debug.GUI.TableView && console.log(this.viewId, "_updateCellsInSection: " + sectionIdx);

            if (!this.table.hasOwnProperty(sectionIdx) || Object.keys(this.table[sectionIdx]).length === 0) {
                console.warn("Cannot set editing mode of an empty or non-existant section!");
                return;
            }

            var section = this.editSectionsMap[sectionIdx];

            if (section.editStartIndex + section.numberOfEditableCells > this.table[sectionIdx].length) {
                var dataSourceCount = this.dataSource.numberOfRowsInSection.call(this.dataSource, section, this);
                console.warn("There are currently fewer cells in the section than requested to be editable!");
                console.warn("      count from dataSource: " + dataSourceCount);
                console.warn("    trying to make editable: start: " + section.editStartIndex + ", count:" + section.numberOfEditableCells);
                console.warn("     cells currently in dom: " + this.table[sectionIdx].length); // Bail out, calling this.reloadSection would cause a reload loop!

                return Q.reject("Edit mode activated for more cells than there are in this section!");
            }

            if (section.editStartIndex < 0 || section.editStartIndex > section.numberOfEditableCells) {
                console.warn("There is something wrong with the start index or number of cells to edit!");
                return;
            }

            var sectionElem = this.element.children().eq(sectionIdx); // set classes to the section

            if (section.editActive) {
                sectionElem.addClass("section-editing");
            } else {
                sectionElem.removeClass("section-editing");
            } // check if class handling was properly


            var editMode = sectionElem.hasClass("section-editing");

            if (editMode !== section.editActive) {
                throw "Setting the css class didn't work!";
            } // handle different sections


            if (this.dataSource.rightButtonTitleForHeaderInSection) {
                var editButton = sectionElem.find(".header__edit-button");

                if (section.editActive) {
                    editButton.text(_('finish'));
                } else {
                    editButton.text(this.dataSource.rightButtonTitleForHeaderInSection(sectionIdx, this));
                }
            }

            if (section.editActive && section.sortEnabled) {
                this._prepareSortable(section, sectionElem, sectionIdx);
            } else {
                section.sortableContainer && section.sortableContainer.destroy();
                section.sortableContainer = null;
            } // tell every row that its in editing mode!


            for (var j = section.editStartIndex; j < section.editStartIndex + section.numberOfEditableCells; j++) {
                var cell = this.table[sectionIdx][j];

                if (section.editActive && j.isBetweenOrEqual(section.editStartIndex, section.numberOfEditableCells)) {
                    cell.startEditMode && cell.startEditMode.call(cell, section.removeEnabled, section.sortEnabled);
                } else {
                    cell.stopEditMode && cell.stopEditMode.call(cell);
                }
            }
        }

        _prepareSortable(section, sectionElem, sectionIdx) {
            var rowContainer = sectionElem.find('.section__rows');
            var container;

            if (section.numberOfEditableCells === this.table[sectionIdx].length || section.sortableContainer) {
                var oldSortContainer = sectionElem.find(".rows__sort-container");
                oldSortContainer.children().detach().prependTo(rowContainer); // Move any potential cells within the old sort container to its original position (start of the section)

                container = rowContainer; // has to be the direct parent of the draggable
            } else {
                container = $('<div class="rows__sort-container"></div>');
                var rows = rowContainer.find("[id^=row-]").filter(function () {
                    return parseInt(this.id.replace("row-", "")).isBetweenOrEqual(section.editStartIndex, section.numberOfEditableCells);
                }); // Find all rows in the range

                rows.detach().appendTo(container); // Perform one DOM operation instead of iterating over the rows is much more performant!

                if (section.editStartIndex === 0) {
                    rowContainer.prepend(container);
                } else {
                    container.insertAfter(sectionElem.find("#row-" + (section.editStartIndex - 1))); // insert after the last element of the first cells which aren't editable
                }
            }

            if (container.length === 0) {
                console.error("Sorting won't work, the container has to be the direct parent of the draggable!");
            }

            section.sortableContainer && section.sortableContainer.destroy();
            var options, ogBoundOnUpdate, ogBoundOnStart, ogBoundOnChange, ogBoundOnEnd;

            if (!!this.delegate.getSortableOptions) {
                options = this.delegate.getSortableOptions.call(this.delegate, sectionIdx, this);
            }

            if (!options) {
                options = {
                    draggable: '.section__cell',
                    handle: '.cell__sort-icon-placeholder'
                };
            } // Save the og functions as bound functions (basically clones them


            if (options.hasOwnProperty("onUpdate")) {
                ogBoundOnUpdate = options.onUpdate.bind(this.delegate);
            }

            if (options.hasOwnProperty("onStart")) {
                ogBoundOnStart = options.onStart.bind(this.delegate);
            }

            if (options.hasOwnProperty("onChange")) {
                ogBoundOnChange = options.onChange.bind(this.delegate);
            }

            if (options.hasOwnProperty("onEnd")) {
                ogBoundOnEnd = options.onEnd.bind(this.delegate);
            }

            options.onUpdate = function (e) {
                this._orderChanged.call(this, e, sectionIdx);

                try {
                    ogBoundOnUpdate && ogBoundOnUpdate.apply(this, arguments);
                } catch (e) {
                    console.warn("Couldn't call bound 'onUpdate'");
                    console.error(e);
                }
            }.bind(this); // Add haptic feedback to the sortable class buy sti


            options.onStart = function (e) {
                this._setIsMovingCells(true);

                HapticFeedback(HapticFeedback.STYLE.SELECTION_START);

                try {
                    ogBoundOnStart && ogBoundOnStart.apply(this, arguments);
                } catch (e) {
                    console.warn("Couldn't call bound 'onStart'");
                    console.error(e);
                }
            }.bind(this);

            options.onChange = function () {
                HapticFeedback(HapticFeedback.STYLE.SELECTION_CHANGE);

                try {
                    ogBoundOnChange && ogBoundOnChange.apply(this, arguments);
                } catch (e) {
                    console.warn("Couldn't call bound 'onChange'");
                    console.error(e);
                }
            };

            options.onEnd = function () {
                HapticFeedback(HapticFeedback.STYLE.SELECTION_END); // unlock tableViewDataSource & delegate before we call the boundOnEnd function
                // onEnd function is called after the cell has been moved so no need to keep them locked

                this._setIsMovingCells(false);

                try {
                    ogBoundOnEnd && ogBoundOnEnd.apply(this, arguments);
                } catch (e) {
                    console.warn("Couldn't call bound 'onEnd'");
                    console.error(e);
                }
            }.bind(this);

            section.sortableContainer = new Sortable(container[0], options);
        }

        /**
         * Called when starting and stopped moving around cells. This will effectively lock the table from
         * outside reloads - as this would mess with the sortable container.
         * @param isMoving
         * @private
         */
        _setIsMovingCells(isMoving) {
            Debug.GUI.EditableTableView && console.warn(this.viewId, "_setIsMovingCells: " + !!isMoving);
            this._isMovingCells = isMoving;

            if (isMoving) {
                this._setLocked(true);

                this._movingCellsDeferred = Q.defer();
            } else {
                this._setLocked(false);

                this._movingCellsDeferred.resolve();
            }
        }

        _removeCell(cell, sectionIdx, rowIdx) {
            Debug.GUI.TableView && console.log("_removeCell: " + sectionIdx + "/" + rowIdx); // update indices of following cells

            var cellToRemove, i;

            for (i = rowIdx + 1; i < this.table[sectionIdx].length; i++) {
                cellToRemove = this.table[sectionIdx][i];
                cellToRemove.rowIdx--; // learn the cell her new index

                cellToRemove.getElement()[0].id = "row-" + (i - 1);
            } // remove the cell we've just dismissed


            this.removeRow(sectionIdx, rowIdx); // keep the dataSource/delegate up to date, otherwise modifications aren't tracked right on later reloads.

            this.dataSource.tableContentRemoved && this.dataSource.tableContentRemoved(sectionIdx, rowIdx);
            this.delegate.tableContentRemoved && this.delegate.tableContentRemoved(sectionIdx, rowIdx); // reduce because the cell which was deleted must have been in the range of start and number of editable cells

            this.editSectionsMap[sectionIdx].numberOfEditableCells--;
        }

        _orderChanged(evt, sectionIdx) {
            Debug.GUI.TableView && console.log(this.name, "_orderChanged: " + sectionIdx);
            var oldIdx = parseInt(evt.item.id.replace("row-", "")),
                // We need to account for the sort container if it is still there (Due to the async nature of promises)
                // Simply subtract 1 if the sorting container is still in the DOM, otherwise the sorting always adds 1 to the newIdx
                offset = -(evt.item.parentElement.firstChild.className === "rows__sort-container"),
                newIdx = parseInt($(evt.item).index()) + offset;

            if (oldIdx !== newIdx) {
                // the delegate is informed by the baseclass. We take care of the cell-ids ant the arrays.
                var movingCell = this.table[sectionIdx].splice(oldIdx, 1)[0];
                this.table[sectionIdx].splice(newIdx, 0, movingCell);
                var i, cell;
                var from = Math.min(oldIdx, newIdx);
                var to = Math.max(oldIdx, newIdx);

                for (i = from; i <= to; i++) {
                    if (this.table[sectionIdx].length === i) {
                        console.error("Out of range!");
                        break;
                    }

                    cell = this.table[sectionIdx][i];
                    cell.rowIdx = i; // learn the cell her new index

                    cell.getElement()[0].id = "row-" + i;
                } // keep the dataSource/delegate up to date, otherwise modifications aren't tracked right on later reloads.


                this.dataSource.tableContentMoved && this.dataSource.tableContentMoved(sectionIdx, oldIdx, newIdx);
                this.delegate.tableContentMoved && this.delegate.tableContentMoved(sectionIdx, oldIdx, newIdx);
                this.delegate.didMoveCell.call(this.delegate, sectionIdx, oldIdx, this, cell, oldIdx, newIdx);
            }
        }

    }

    GUI.EditableTableView = EditableTableView;
    return GUI;
}(window.GUI || {});
