'use strict';
/**
 * This implementation extends the reusingListView by allowing to remove or move cells inside this list.
 *
 * delegate
 *      onListCellTapped
 *
 * dataSource
 *      heightForCellInRow (row, list)
 *      cellTypeForRowInList (row, list)
 *      contentForRowInList (row, list)
 *      numberOfRowsInList (list)
 */

window.GUI = function (GUI) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var SCROLL_STEP = 20; // how many px to scroll up/down when moving an item around

        var SCROLL_INTERVAL = 5; // every how many MS a scroll step is to be taken.

        var SCROLL_LIMIT = 50; // how many px on the top/bottom are used to scroll up or down.

        class EditableReusingListView extends GUI.ReusingListView {
            /**
             *
             * @param dataSource            where to get data from.
             * @param delegate              whom to call if cells where selected & so on.
             * @param [scrollContainer]     if the list itself is not the one who scrolls, but another element.
             */
            constructor(dataSource, delegate, scrollContainer) {
                super(...arguments);
            }

            // ------------------------------------------------------------
            // -------             Public Methods                ----------
            // ------------------------------------------------------------
            setEditingModeForSection(section, editing, removeEnabled, sortEnabled, startIdx, numberOfCells) {
                return this.setEditingMode(removeEnabled, sortEnabled);
            }

            /**
             * Activates the editing mode for all cells that support it.
             * @param removeEnabled
             * @param sortEnabled
             */
            setEditingMode(removeEnabled, sortEnabled) {
                Debug.GUI.EditableListView && console.log(this.viewId, "setEditingMode: remove=" + removeEnabled + " - sort=" + sortEnabled);
                Debug.GUI.EditableListViewTiming && debugLog(this.viewId, "setEditingMode: remove=" + removeEnabled + " - sort=" + sortEnabled);
                this.removeEnabled = removeEnabled;
                this.sortEnabled = sortEnabled;

                this._reloadPageContents();

                Debug.GUI.EditableListViewTiming && debugLog(this.viewId, "setEditingMode: remove=" + removeEnabled + " - sort=" + sortEnabled + " -- done");
                return Q(true);
            }

            // -------------------------------------------------------
            //         Overwritten Baseclass Methods
            // -------------------------------------------------------

            /**
             * used to intercept and adopt the content (for providing the editing attributes)
             * @param row
             * @returns {*}
             * @private
             */
            _requestContentForRow(row) {
                var content = super._requestContentForRow(...arguments);

                content[GUI.LVContent.MOVABLE] = this.sortEnabled;
                content[GUI.LVContent.DELETABLE] = this.removeEnabled; // the cell also needs to know it's position inside the list in order to move it properly.

                content[GUI.LVContent.Y_POS] = this.heightArray[row].offset - this.offsetTop;
                return content;
            }

            /**
             * Moved to a separate implementation to enable interference for subclasses. E.g. if a cell is being moved
             * it should not be reused. Can also be used to tell the table which cell types can be reused and which
             * cannot be reused.
             * @param cell          the cell in question
             * @returns {boolean}   whether or not it can be reused.
             * @private
             */
            _canReuseCell(cell) {
                return !this._isMovingCell(cell);
            }

            /**
             * Overwritten: If the oldCell is the cell we are currently moving around, it must not be removed until the
             * the movement has ended.
             * @param oldCell   the cell to remove and destroy.
             * @param newCell   the cell that the oldCell should be replaced with.
             * @param page      the page that currently contains this cell.
             * @param i         the index of the cell inside the pages cell array.
             * @private
             */
            _replaceCell(oldCell, newCell, page, i) {
                if (this._isMovingCell(oldCell)) {
                    // store a flag on the moving cell as it's not in a page anymore and will therefor no longer be
                    // removed or updated in a reload. It needs to be done manually after ending the movement.
                    oldCell.hasNoPage = true; // don't remove it from the UI - just add the new one and insert it in the array.

                    this.appendSubview(newCell, this.elements.content);
                    page.cells.splice(i, 1, newCell);
                } else {
                    super._replaceCell(...arguments);
                }
            }

            // -------------------------------------------------------
            //         Editing Methods
            // -------------------------------------------------------

            /**
             * Called when a cell hits the delete button. The cell must have been removed from the current "dataset".
             * @param cell
             * @param row
             * @private
             */
            _didRemoveCell(cell, row) {
                Debug.GUI.EditableListView && console.log(this.viewId, "_didRemoveCell: " + row);
                Debug.GUI.EditableListViewTiming && debugLog(this.viewId, "_didRemoveCell " + row); // the cell has been removed, the cells above are okay, reload the ones below!

                this.reloadAfter(row - 1);
                Debug.GUI.EditableListViewTiming && debugLog(this.viewId, "_didRemoveCell " + row + " done");
            }

            /**
             * Called as soon as a cell inside this list is about to start moving around.
             * @param cell          what cell is moving?
             * @param movingRow     what row was this cell in before it did start moving?
             * @param newPos        where is it now?
             * @private
             */
            _didStartMovingCell(cell, movingRow, newPos) {
                Debug.GUI.EditableListView && console.log(this.viewId, "_didStartMovingCell: " + movingRow); // ensure the cell is excluded from the regular reusing flow

                this._movingStartScrollTop = this._scrollTop;
                this._currentMoveTargetRow = movingRow; // store attribute that this cell is moving

                this.movingCell = cell; // disable scrolling using touch screens

                this._setTouchScrollingEnabled(false);

                this._respondToMovedCell(cell, movingRow, newPos);

                HapticFeedback(HapticFeedback.STYLE.SELECTION_START);
            }

            /**
             * This method will not only rearrange the cells so that the moving cell is shown at the proper position,
             * it will also scroll up or down if the cell is being dragged to the top or bottom of the view.
             * @param cell          what cell is moving?
             * @param movingRow     what row was this cell in before it did start moving?
             * @param newPos        where is it now?
             * @param eventPos      the position of the event on the screen. Used to determine the scroll direction.
             * @private
             */
            _didMoveCell(cell, movingRow, newPos, eventPos) {
                Debug.GUI.EditableListView && console.log(this.viewId, "_didMoveCell: " + movingRow); // if the cell moves up or down the view has to scroll accordingly

                this._scrollPageForMovement(eventPos);

                this._respondToMovedCell(cell, movingRow, newPos);
            }

            /**
             * Called when the user has "dropped" the movign cell on it's final destination. After this call, the delegate
             * will be informed and after that, the list will reload its content.
             * @param cell          what cell is moving?
             * @param movingRow     what row was this cell in before it did start moving?
             * @param newPos        where is it now?
             * @private
             */
            _didStopMovingCell(cell, movingRow, newPos) {
                Debug.GUI.EditableListView && console.log(this.viewId, "_didStopMovingCell: " + movingRow);

                var targetRow = this._respondToMovedCell(cell, movingRow, newPos);

                this._scrollInterval && clearInterval(this._scrollInterval);
                this._scrollInterval = false; // if the movingCell crossed from one page to another, it might have been replaced by another cell
                // and therefor is no longer inside a page. This means it has to be manually removed.

                if (this.movingCell && this.movingCell.hasNoPage) {
                    this.removeSubview(this.movingCell);
                }

                this.movingCell = null; // if the row has changed, inform the delegate so it can update it's dataset.

                if (targetRow !== movingRow && this.delegate.didMoveListCell) {
                    this.delegate.didMoveListCell.call(this.delegate, movingRow, targetRow, this);
                } // enable scrolling using touch screens again


                this._setTouchScrollingEnabled(true); // reload the modified data, even if the row didn't change, because the moving cell needs to be repositioned.


                this.reloadAfter(Math.min(movingRow, targetRow) - 1);
                HapticFeedback(HapticFeedback.STYLE.SELECTION_END);
            }

            /**
             * Will make sure the cell is moved to the proper position and that all other cells are properly positioned
             * around the new position. The "slot" where the moving cell is going to be needs to be empty and it needs
             * to move with the dragged cell.
             * @param cell
             * @param movingRow
             * @param newPos
             * @returns {*}     returns the current row this cell was moved to.
             * @private
             */
            _respondToMovedCell(cell, movingRow, newPos) {
                Debug.GUI.EditableListView && console.log(this.viewId, "_respondToMovedCell: initial row: " + movingRow + " currRow: " + this._currentMoveTargetRow);

                var adoptedPos = this._adoptPosition(newPos),
                    newTargetRow,
                    upwards,
                    heightObj = this.heightArray[this._currentMoveTargetRow]; // reposition the moving cell


                this.moveCellTo(cell, adoptedPos); // is it moving up or down? (keep in mind, the heightArray stores values in the scrollers coordinate system)

                upwards = adoptedPos < heightObj.offset - this.offsetTop; // what slot will did it move to?

                newTargetRow = this._detectTargetRow(adoptedPos, upwards, heightObj.height); // next step, update the moving target (attribute + move the empty slot)

                this._updateMoveTarget(movingRow, newTargetRow, upwards);

                return this._currentMoveTargetRow;
            }

            /**
             * This method is used to determine what row the moving row is being moved to. It uses the movingCells
             * height as it gives a more natural feeling if the center of the moving cell is used for deciding.
             * @param newPos        the position inside the listView where the moving row should move to (top-position)
             * @param upwards       whether or not the cell is moving upwards or downwards compared to the last movement.
             * @param movingCellHeight  the height of the cell that is being moved arount.
             * @returns {number}    the new target row. -1 if something went wrong
             * @private
             */
            _detectTargetRow(newPos, upwards, movingCellHeight) {
                // what slot will did it move to?
                var limit = upwards ? -1 : this._numRows(),
                    // -1 as 0 still needs to be minded
                    heightObj,
                    newTargetRow = -1,
                    i,
                    // the heightArray offsets include the topOffset, adopt the newPos
                    posInScroller = newPos + this.offsetTop + movingCellHeight / 2;

                for (i = this._currentMoveTargetRow; i != limit; upwards ? i-- : i++) {
                    heightObj = this.heightArray[i];

                    if (posInScroller > heightObj.offset && posInScroller <= heightObj.end) {
                        newTargetRow = i;
                        break;
                    }
                }

                return newTargetRow;
            }

            /**
             * This method will update both the empty slot and the currentMoveTargetRow.
             * it moves the other cells between _currentMoveTargetRow and the newTargetRow up or down - this way the
             * empty slot moves up or down depending on the arguments provided.
             * @param movingRow             what row is currently being moved around (where did it come from)
             * @param newTargetRow          whats the new desired position - here we will put the empty slot.
             * @param slotMovesUpwards      if the slot moves up or downwards since the last movement.
             * @private
             */
            _updateMoveTarget(movingRow, newTargetRow, slotMovesUpwards) {
                var i,
                    prevTargetRow = this._currentMoveTargetRow; // only move rows if a new target row could be found.

                if (newTargetRow > -1 && newTargetRow !== prevTargetRow) {
                    Debug.GUI.EditableListView && console.log(this.viewId, "_updateMoveTarget: moving " + movingRow + " from " + prevTargetRow + "->" + "" + newTargetRow + " - " + (slotMovesUpwards ? "up" : "down")); // reposition the cells in between

                    for (i = newTargetRow; slotMovesUpwards ? i <= prevTargetRow : i >= prevTargetRow; slotMovesUpwards ? i++ : i--) {
                        this._repositionCellForMovement(i, slotMovesUpwards, movingRow, newTargetRow);

                        HapticFeedback(HapticFeedback.STYLE.SELECTION_CHANGE);
                    } // update the target row


                    this._currentMoveTargetRow = newTargetRow;
                }
            }

            /**
             * This method is called on the cells that need to be repositioned as the movingCell is being dragged accross
             * the list. It makes sure the cell that orignally has been on "rowToMove" is now shon at the proper position
             * in the list. The proper position is shown if there is an empty slot right underneath the moving cell, at
             * the exact position the movingCell will be rearranged to if dropped.
             * @param rowToMove         the row of the cell to reposition as the movingCell changed it's position.
             * @param slotMovesUpwards  boolean value indicating if the empty slot is moving upwards in the list.
             * @param movingSource      the original position of the cell that is currently being moved and causing this reposition
             * @param movingTarget      the taret position of the cell that is currently being moved and causing this reposition
             * @private
             */
            _repositionCellForMovement(rowToMove, slotMovesUpwards, movingSource, movingTarget) {
                var cellToMove = this._getExistingCellForRow(rowToMove),
                    movement = this.heightArray[movingSource].height,
                    initialPos = this.heightArray[rowToMove].offset - this.offsetTop,
                    target = -1;

                if (cellToMove && !this._isMovingCell(cellToMove)) {
                    // depending on where the cells used to be and where the slot was, either move them up/down
                    // or put them back to their initial positions.
                    if (rowToMove < movingSource && slotMovesUpwards) {
                        // move the other cells downwards, the slot moves up.
                        target = initialPos + movement;
                        Debug.GUI.EditableListView && console.log("  move the cell at row = " + rowToMove + " downwards " + "to " + target + " (above origin & moving up)");
                    } else if (rowToMove > movingSource && !slotMovesUpwards) {
                        // move the other cells upwards, the slot moves down
                        target = initialPos - movement;
                        Debug.GUI.EditableListView && console.log("  move the cell at row = " + rowToMove + " upwards to " + "" + target + " (below origin & moving down)");
                    } else if (rowToMove === movingTarget) {
                        Debug.GUI.EditableListView && console.log("  don't move the cell at row " + rowToMove + " to it's " + "inital position! the moving cell is there");
                    } else {
                        target = initialPos;
                        Debug.GUI.EditableListView && console.log("  move the cell at row = " + rowToMove + " to its " + "initial position to " + target);
                    } // if the target has not been set, it does not have to be moved.


                    if (target >= 0) {
                        this.moveCellTo(cellToMove, target);
                    }
                } else {
                    Debug.GUI.EditableListView && console.log("  don't move the cell at row = " + "" + rowToMove + (cellToMove ? " it's the curr moving cell" : " NO CELL FOUND"));
                }
            }

            /**
             * Detects whether or not the cell provided is the cell that is currently being moved around.
             * @param cell
             * @returns {boolean}
             * @private
             */
            _isMovingCell(cell) {
                return cell === this.movingCell;
            }

            /**
             * Will make sure that the new position delivered by the cell is correct for this view. It will adopt the new
             * position so it isn't influenced by the delta that has been scrolled since the movement started.
             * @param newPos        position based provided by the cell itself without any knowledge on the scroll delta.
             * @returns {number}
             * @private
             */
            _adoptPosition(newPos) {
                var adoptedVal,
                    scrollWhileMoving = this._scrollTop - this._movingStartScrollTop;
                Debug.GUI.EditableListView && console.log(this.viewId, "_adoptPosition: " + newPos + " scrollWhileMoving: " + scrollWhileMoving); // now adopt the position by the scrolling delta since the start of this movement.

                adoptedVal = newPos + scrollWhileMoving; // ensure that no negative values are returned --> no item should move out of sight.

                adoptedVal = Math.max(adoptedVal, 0);
                return adoptedVal;
            }

            /**
             * Will look inside the pages for the cell object that matches the row provided.
             * @param row       what rows cell object are we looking for?
             * @returns {*}     the rows cell object - or undefined if not found!
             * @private
             */
            _getExistingCellForRow(row) {
                var cell, page;

                if (this._isRowInPage(row, this.currPage)) {
                    page = this.currPage;
                } else if (this._isRowInPage(row, this.upperPage)) {
                    page = this.upperPage;
                } else if (this._isRowInPage(row, this.lowerPage)) {
                    page = this.lowerPage;
                }

                if (page) {
                    cell = page.cells[row - page.startRow];
                }

                return cell;
            }

            /**
             * Will use the page's attributes to figure out whether or not the requested row is inside this page.
             * @param row   the row we're looking for
             * @param page  the page to check for the desired row.
             * @returns {*|boolean}
             * @private
             */
            _isRowInPage(row, page) {
                return page && row >= page.startRow && row <= page.endRow;
            }

            /**
             * Will en- or disable scrolling on touchscreen devices. This is important as otherwise the screen will
             * scroll while an a cell is being dragged - and therefore the item cannot be moved at all, except for the
             * beginning or the end of the list.
             * @param enabled   if true, the touch events will be used for scrolling, if false they will be "caught".
             * @private
             */
            _setTouchScrollingEnabled(enable) {
                Debug.GUI.EditableListView && console.log(this.viewId, "_setTouchScrollingEnabled: " + enable);

                if (enable && this.boundEvDisabler) {
                    this.scroller.off("touchmove", this.boundEvDisabler);
                } else if (!enable) {
                    if (!this.boundEvDisabler) {
                        this.boundEvDisabler = this._evDisabler.bind(this);
                    }

                    this.scroller.on("touchmove", this.boundEvDisabler);
                }
            }

            /**
             * Helper method that "catches" the event provided so it'll not be forwarded to any other listener.
             * @param ev    the event to catch.
             * @private
             */
            _evDisabler(ev) {
                if (ev.cancelable) {
                    ev.preventDefault();
                    ev.stopPropagation();
                }
            }

            /**
             * When a cell is moved into a certain region on top or on the bottom of the screen, the screen needs to
             * scroll up or down correspondingly. Otherwise an item can only be moved within the currently visible area.
             * @param posInWindow   The Position of the item in relation to the window.
             * @private
             */
            _scrollPageForMovement(posInWindow) {
                var windowHeight = this.scroller[0].offsetParent.clientHeight,
                    adoptedStep; // detect if, how far and in what direction scrolling is needed

                if (posInWindow < SCROLL_LIMIT) {
                    Debug.GUI.EditableListView && console.log(this.viewId, "_scrollPageForMovement (" + windowHeight + "): " + posInWindow + ") --> scroll up"); // the further the div is inside the scroll area, the faster the scrolling will be.

                    this._scrollDelta = Math.min(posInWindow, SCROLL_STEP) * -1;
                } else if (posInWindow > windowHeight - SCROLL_LIMIT) {
                    Debug.GUI.EditableListView && console.log(this.viewId, "_scrollPageForMovement (" + windowHeight + "): " + posInWindow + " (" + (windowHeight - posInWindow) + ") --> scrolling down");
                    adoptedStep = SCROLL_LIMIT - (windowHeight - posInWindow);
                    this._scrollDelta = Math.min(SCROLL_STEP, adoptedStep);
                } else {
                    Debug.GUI.EditableListView && console.log(this.viewId, "_scrollPageForMovement (" + windowHeight + "): " + posInWindow + " --> NOT MOVING");
                    this._scrollDelta = 0;
                } // if scrolling did not change. leave a potentially running scroll interval untouched.


                if (this._scrollDelta === 0) {
                    this._scrollInterval && clearInterval(this._scrollInterval);
                    this._scrollInterval = false;
                } // if scrolling is needed, but there is no active scroll interval, start a new one.


                if (this._scrollDelta !== 0 && !this._scrollInterval) {
                    this._scrollInterval = setInterval(function () {
                        // scroll by scroll delta! (either up or down)
                        this.scroller.scrollTop(this._scrollTop + this._scrollDelta);
                    }.bind(this), SCROLL_INTERVAL);
                }
            }

        }

        GUI.EditableReusingListView = EditableReusingListView;
    }
    return GUI;
}(window.GUI || {});
