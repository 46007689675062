import {useState, useEffect} from "react";
import {useConnectionReady} from "LxComponents";

export default function useDeviceSearchSwitchboards(device) {

    const [switchboards, setSwitchboards] = useState(null);
    const connReady = useConnectionReady(true);

    useEffect(() => {
        connReady && setSwitchboards(ActiveMSComponent.getAvailableSwitchboards())
        const unregFn = ActiveMSComponent.registerForSwitchboardChanges(setSwitchboards);
        return () => {
            unregFn && unregFn();
        }
    }, []);

    const filterSwitchboards = () => {
        if (device && device.hasOwnProperty("msSerial") && Array.isArray(switchboards)) {
            return switchboards.filter((sb) => {
                return sb.hasOwnProperty("msSerial") ? sb.msSerial === device.msSerial : true;
            });
        } else {
            return switchboards;
        }
    };

    return filterSwitchboards();
}
