'use strict';

define(["ContentOverviewScreenBase", "SpotifyAccountManager", "MediaBrowserV2Base", "OverviewContentLoader"], function (ContentOverviewScreenBase, SpotifyAccountManager, MediaBrowserV2Base, OverviewContentLoader) {
    return class PlaylistsContentOverviewScreen extends ContentOverviewScreenBase {
        //region Getter
        get RIGHT_SIDE_BUTTON() {
            let baseVal = super.RIGHT_SIDE_BUTTON;
            baseVal.ADD_PLAYLIST = "right-side__touch-area--add-playlist";
            return baseVal;
        }

        get spotifyId() {
            return SpotifyAccountManager.getUserUnique(SpotifyAccountManager.shared(this.control).activeUser);
        }

        get spotifyUsername() {
            return SpotifyAccountManager.shared(this.control).activeUserName;
        } //endregion Getter


        constructor(details) {
            super(...arguments);
            this.reloadContentTypes = this.reloadContentTypes.concat([this.EVENT_TYPES.SERVICE, this.EVENT_TYPES.PLAYLISTS]);
            this._spotifyPlaylists = [];
            this._localPlaylists = [];
        }

        loadData() {
            return super.loadData(...arguments).then(function () {
                var data = [];
                data.pushObject(this.getSpotifyPlaylistData());
                data.pushObject(this.getLocalPlaylistData());
                return Q.all(data);
            }.bind(this));
        }

        titleBarText() {
            return _("media.playlists");
        }

        titleBarActionRight(buttonId) {
            if (buttonId === this.RIGHT_SIDE_BUTTON.ADD_PLAYLIST) {
                // We only need the context menu if we have a spotify account, to let the user choose what playlist to create
                if (SpotifyAccountManager.shared(this.control).activeUser) {
                    this._showContextMenu([{
                        title: _("media.playlist.create"),
                        action: this._createLocalPlaylist.bind(this)
                    }, {
                        title: _("audio-server.spotify.add-playlist"),
                        action: this._createSpotifyPlaylist.bind(this)
                    }]);
                } else {
                    this._createLocalPlaylist();
                }
            } else {
                super.titleBarActionRight(...arguments);
            }
        }

        updateBackgroundView() {
            if (this.tableContent && this.tableContent.length) {
                this.tableView.setBackgroundView(null);
            } else if (this._loadedInitialContent) {
                this.tableView.setBackgroundView(new GUI.NoPlaylistView(this.control, this.titleBarActionRight.bind(this, this.RIGHT_SIDE_BUTTON.ADD_PLAYLIST)));
            } else {
                super.updateBackgroundView(...arguments);
            }
        }

        favFilterFunction(item) {
            return item.type === MusicServerEnum.FAVORITE_TYPES.LMS_PLAYLIST || item.type === MusicServerEnum.FAVORITE_TYPES.LIB_PLAYLIST || item.type === MusicServerEnum.FAVORITE_TYPES.SPOTIFY_PLAYLIST || item.type === MusicServerEnum.FAVORITE_TYPES.SPOTIFY_COLLECTION;
        }

        getDataForContentType(contentType, id) {
            return new OverviewContentLoader(this.control, {
                id: id,
                contentType: contentType
            }, this.MAX_OVERVIEW_ITEM_CNT, this.getMediaInfo(contentType)).fetch();
        }

        getSpotifyPlaylistData() {
            if (this.ViewController && this.ViewController.BANNED_CUSTOMIZATION_KEYS && this.ViewController.BANNED_CUSTOMIZATION_KEYS.indexOf(MusicServerEnum.ControlContentMenuId.SPOTIFY) !== -1) {
                return null;
            } else {
                if (this.spotifyId) {
                    return this.getDataForContentType(this.EVENT_TYPES.SERVICE, MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS).then(function (items) {
                        this._spotifyPlaylists = items || [];
                    }.bind(this));
                } else {
                    return null;
                }
            }
        }

        getLocalPlaylistData() {
            return this.getDataForContentType(this.EVENT_TYPES.PLAYLISTS, 0).then(function (items) {
                this._localPlaylists = items;
            }.bind(this));
        }

        getTableContent() {
            return super.getTableContent(...arguments).then(function (tableContent) {
                tableContent.pushObject(this._getSpotifySection());
                tableContent.pushObject(this._getLocalSection());
                return Q.all(tableContent);
            }.bind(this));
        }

        receivedServiceReloadEvent(reason) {
            if (reason !== MusicServerEnum.ReloadCause.STOPPED) {
                return this.getSpotifyPlaylistData().then(function () {
                    return this.reloadTable();
                }.bind(this));
            } else {
                return Q(true);
            }
        }

        receivedPlaylistsReloadEvent() {
            return this.getLocalPlaylistData().then(function () {
                return this.reloadTable();
            }.bind(this));
        }

        getMediaInfo(contentType) {
            if (contentType === this.EVENT_TYPES.SERVICE) {
                return {
                    service: {
                        uid: MusicServerEnum.Service.SPOTIFY + "/" + this.spotifyId,
                        cmd: MusicServerEnum.Service.SPOTIFY,
                        user: this.spotifyUsername
                    }
                };
            } else if (contentType === this.EVENT_TYPES.PLAYLISTS) {
                return {
                    service: {
                        uid: MusicServerEnum.Service.LMS + "/" + MusicServerEnum.NOUSER,
                        cmd: MusicServerEnum.Service.LMS
                    }
                };
            } else {
                return super.getMediaInfo(...arguments);
            }
        }

        handleOnItemCellClicked(item, contentType, section, row) {
            var details;

            if (contentType === this.EVENT_TYPES.SERVICE && this.control.audioserverComp.isFileTypeBrowsable(item.type)) {
                details = {
                    username: this.spotifyId,
                    lastSelectedItem: item,
                    contentTypes: [contentType],
                    identifier: MusicServerEnum.Service.SPOTIFY,
                    mediaType: MusicServerEnum.MediaType.PLAYLIST,
                    control: this.control
                };
                return this.ViewController.showState(Controls.AudioZoneV2Control.MediaBrowserV2Base.getScreenStateForItem(item), null, details);
            } else if (contentType === this.EVENT_TYPES.PLAYLISTS && this.control.audioserverComp.isFileTypeBrowsable(item.type)) {
                details = {
                    username: MusicServerEnum.NOUSER,
                    lastSelectedItem: item,
                    contentTypes: [contentType],
                    identifier: MusicServerEnum.Service.LMS,
                    mediaType: MusicServerEnum.MediaType.PLAYLIST,
                    control: this.control
                };
                return this.ViewController.showState(Controls.AudioZoneV2Control.MediaBrowserV2Base.getScreenStateForItem(item), null, details);
            } else {
                return super.handleOnItemCellClicked(...arguments);
            }
        }

        getRightVCButtonIcon() {
            return Icon.PLUS;
        }

        getRightVCButtonIconFn() {
            return this.titleBarActionRight.bind(this, this.RIGHT_SIDE_BUTTON.ADD_PLAYLIST);
        }

        _getFavSectionCellForItem(item) {
            // normalize favorite --> means it will be browsable as the favorite-types are converted into regular
            // filetypes. Also the service infos are being detected and applied onto the item.
            var normalizedItem = this.control.audioserverComp.normalizeFavoriteItem(item);
            return super._getFavSectionCellForItem(normalizedItem, normalizedItem.details);
        }

        _getSpotifySection() {
            return this._getSection(_("audio-server.spotify.playlists"), this._spotifyPlaylists.map(function (item) {
                item.contentType = this.EVENT_TYPES.SERVICE;
                var details = {
                    username: this.spotifyId,
                    identifier: MusicServerEnum.Service.SPOTIFY,
                    mediaType: MusicServerEnum.MediaType.SERVICE,
                    service: this.getMediaInfo(item.contentType).service,
                    control: this.control
                };
                return MediaBrowserV2Base.getConstructorForItem(item).getCellFromContentTypeItem(item, details, item.contentType, this.isInAddMode() ? false : this.control.audioserverComp.isFileTypePlaylist(item.type), this.control.audioserverComp.isFileTypeBrowsable(item.type), this.handleOnItemCellClicked.bind(this), this.handleOnCellButtonClicked.bind(this), this.ViewController);
            }.bind(this)), this._openSpotifyPlaylists.bind(this));
        }

        _openSpotifyPlaylists() {
            var item = {
                contentType: this.EVENT_TYPES.SERVICE,
                id: MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS,
                name: _("media.playlists")
            };
            this.ViewController.showState(MediaBrowserV2Base.getScreenStateForItem(item), null, {
                username: this.spotifyId,
                lastSelectedItem: item,
                contentTypes: [MusicServerEnum.MediaContentType.SERVICE],
                mediaType: MusicServerEnum.MediaType.SERVICE,
                identifier: MusicServerEnum.Service.SPOTIFY,
                control: this.control
            });
        }

        _getLocalSection() {
            return this._getSection(_("audio-server.library.playlists"), this._localPlaylists.map(function (item) {
                item.contentType = this.EVENT_TYPES.PLAYLISTS;
                var details = {
                        username: MusicServerEnum.NOUSER,
                        identifier: MusicServerEnum.Service.LMS,
                        service: this.getMediaInfo(item.contentType).service,
                        mediaType: MusicServerEnum.MediaType.PLAYLIST,
                        control: this.control
                    },
                    cell = MediaBrowserV2Base.getConstructorForItem(item).getCellFromContentTypeItem(item, details, item.contentType, this.control.audioserverComp.isFileTypePlaylist(item.type), this.control.audioserverComp.isFileTypeBrowsable(item.type), this.handleOnItemCellClicked.bind(this), this.handleOnCellButtonClicked.bind(this), this.ViewController);

                if (!this.isInAddMode()) {
                    cell.type = GUI.TableViewV2.CellType.Special.MEDIA_V2_COVER;
                    delete cell.content.class; // Removes the chevron class
                }

                return cell;
            }.bind(this)), this._openLocalPlaylists.bind(this));
        }

        _openLocalPlaylists() {
            var item = {
                contentType: this.EVENT_TYPES.PLAYLISTS,
                name: _("media.playlists")
            };
            this.ViewController.showState(MediaBrowserV2Base.getScreenStateForItem(item), null, {
                username: MusicServerEnum.NOUSER,
                lastSelectedItem: item,
                contentTypes: [item.contentType],
                mediaType: MusicServerEnum.MediaType.PLAYLIST,
                identifier: MusicServerEnum.Service.LMS,
                control: this.control
            });
        }

        _getRightSideTitleBarButtons() {
            var buttons = [];

            if (this._shouldOfferNewPlaylist()) {
                buttons.push(this._getRightSideTitleBarButtonElm(this.RIGHT_SIDE_BUTTON.ADD_PLAYLIST, Icon.TitleBar.PLUS));
            }

            return buttons;
        }

        _createLocalPlaylist() {
            return this._createGenericPlaylist(this.EVENT_TYPES.PLAYLISTS, {
                service: {
                    uid: MusicServerEnum.Service.LMS + "/" + MusicServerEnum.NOUSER
                }
            }, _("media.playlist.save.default-name", {
                number: this._localPlaylists.length + 1
            }));
        }

        _createSpotifyPlaylist() {
            return this._createGenericPlaylist(this.EVENT_TYPES.SERVICE, {
                service: {
                    uid: MusicServerEnum.Service.SPOTIFY + "/" + this.spotifyId,
                    cmd: MusicServerEnum.Service.SPOTIFY
                }
            }, _("media.playlist.save.default-name", {
                number: this._spotifyPlaylists.length + 1
            }));
        }

        _createGenericPlaylist(contentType, mediaInfo, placeholderName) {
            return this.control.audioserverComp.showNamePopup(_("media.playlist.create"), _("create"), placeholderName).then(function (plName) {
                return this.control.audioserverComp.createContainer(contentType, mediaInfo, plName).then(function (newPlaylistItem) {
                    this.control.audioserverComp.stopEditing(); // Don't navigate into the newly created playlist if we are in the Playlist picker

                    if (this.ViewController instanceof GUI.AddMediaViewControllerV2PlaylistPicker) {
                        return Q(true);
                    } else {
                        return this.handleOnItemCellClicked(newPlaylistItem, newPlaylistItem.contentType);
                    }
                }.bind(this));
            }.bind(this));
        }

        _shouldOfferNewPlaylist() {
            return !(this.ViewController instanceof GUI.AddMediaViewControllerV2PlaylistSpotify) && !(this.ViewController instanceof GUI.AddMediaViewControllerV2Playlist) && !(this.ViewController instanceof GUI.AddMediaViewControllerV2ZoneFavorite);
        }

    };
});
