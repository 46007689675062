import * as React from "react"
import Svg, { Path } from "react-native-svg"

function AcSun(props) {
  return (
    <Svg isreact="true"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.5c-4.136 0-7.5 3.364-7.5 7.5s3.364 7.5 7.5 7.5 7.5-3.364 7.5-7.5-3.364-7.5-7.5-7.5zm0 16a.5.5 0 00-.5.5v2a.5.5 0 001 0v-2a.5.5 0 00-.5-.5zm6.854-2.354l1.5 1.5a.5.5 0 01-.707.707l-1.5-1.5a.5.5 0 01.707-.707zM23 11.5a.5.5 0 010 1h-2a.5.5 0 010-1h2zm-4.146-5.647a.5.5 0 01-.707-.707l1.5-1.5a.5.5 0 01.707.707l-1.5 1.5zM12 3.5a.5.5 0 01-.5-.5V1a.5.5 0 011 0v2a.5.5 0 01-.5.5zM5.147 5.853l-1.5-1.5a.5.5 0 01.707-.707l1.5 1.5a.5.5 0 01-.707.707zm0 12.293a.5.5 0 01.707.707l-1.5 1.5a.5.5 0 01-.707-.707l1.5-1.5zM3 11.5a.5.5 0 010 1H1a.5.5 0 010-1h2z"
      />
    </Svg>
  )
}

export default AcSun
