'use strict';

define("AlarmControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class AlarmControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [];

            if (!states.armed) {
                if (Feature.ALARM_SINGLE_COMMAND) {
                    cmds.push(this.createCommand(_('controls.alarm.arm'), Commands.format(Commands.ALARM.ARM_SINGLE, !states.disableMove)));
                } else {
                    cmds.push(this.createCommand(_('controls.alarm.arm'), Commands.ALARM.ARM));
                }

                if (states.delayedArmTimeLeft === 0) {
                    if (Feature.ALARM_SINGLE_COMMAND) {
                        cmds.push(this.createCommand(_('controls.alarm.arm.delayed'), Commands.format(Commands.ALARM.DELAYED_ARM_SINGLE, !states.disableMove)));
                    } else {
                        cmds.push(this.createCommand(_('controls.alarm.arm.delayed'), Commands.ALARM.DELAYED_ARM));
                    }
                }
            }

            if (states.armed || states.delayedArmTimeLeft > 0) {
                cmds.push(this.createCommand(_('controls.alarm.disarm'), Commands.ALARM.DISARM));
            }

            if (states.alarmActive) {
                cmds.push(this.createCommand(_("controls.alarm.acknowledge"), Commands.ALARM.ACKNOWLEDGE));
            }

            return cmds;
        }

    };
});
