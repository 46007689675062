'use strict';

window.GUI = function (GUI) {
    class ControlListTableView extends GUI.TableViewV2 {
        //region Static
        static Template = function () {
            var getHeaderForSection = function getHeaderForSection(icon, title) {
                var iconElem = '',
                    titleElem = '';

                if (icon) {
                    iconElem = '<div class="header__icon-placeholder"></div>';
                }

                if (title !== UnassignedUUID) {
                    titleElem = '<div class="header__section-title text-overflow-ellipsis">' + title + '</div>';
                }

                return $('<div class="section__header">' + iconElem + titleElem + '</div>');
            };

            var getBoldTitleSectionHeaderWithDescriptionAndButton = function getBoldTitleSectionHeaderWithDescription(title, description, iconSrc) {
                var buttonElement = "";

                if (iconSrc) {
                    buttonElement = '       <div class="header__edit-button clickable right-container">' + '         ' + ImageBox.getResourceImageWithClasses(iconSrc, "left-btn__icon") + '       </div>';
                }

                return $('<div class="section__header header--descriptive">' + '       <div class="left-content">' + '           <div class="header__title title--strong">' + title + '</div>' + '           <div class="header__desc .circle">' + description + '</div>' + '       </div>' + buttonElement + '</div>');
            };

            var getEmptyHeaderForSection = function getEmptyHeaderForSection() {
                return $('<div class="section__header--empty"></div>');
            };

            var getRowsContainer = function getRowsContainer() {
                return $('<div class="section__rows"></div>');
            };

            return {
                getHeaderForSection: getHeaderForSection,
                getBoldTitleSectionHeaderWithDescriptionAndButton: getBoldTitleSectionHeaderWithDescriptionAndButton,
                getEmptyHeaderForSection: getEmptyHeaderForSection,
                getRowsContainer: getRowsContainer
            };
        }(); //endregion Static

        /**
         * @param {*} dataSource - context for datasource of the tableView
         * @param {*} delegate - context for callbacks from the tableView and cells
         * @param {jQuery} elem if set, this element turns into the TableViewV2, otherwise a new element will be created
         * @constructor
         */
        constructor(dataSource, delegate, elem) {
            super(...arguments);
        }

        /**
         * The header uses an icon when showing controlLists, overwrite it to show a "custom" section-Header if
         * one exists. Some ControlLists (subControl-Lists, Favorites) don't have a header. Controls that only have
         * a room or only a category but not the other groupType are shown with a "blank" header.
         */
        setUpSection(sectionIdx, sectionFragment) {
            var rowsContainer;
            return this.updateSectionHeader(sectionIdx, sectionFragment, true).then(() => {
                // check section header information
                return GUI.animationHandler.schedule(() => {
                    // create rows
                    rowsContainer = ControlListTableView.Template.getRowsContainer.call(this);
                    sectionFragment.append(rowsContainer);

                    this.element.append(sectionFragment); // to be sure the viewDidAppear works correctly!
                }).then(() => {
                    var rows = this._getNumberOfRowsInSection(sectionIdx, this);
                    return this._reloadRowsInSection(sectionIdx, 0, rows, rowsContainer);
                })
            });
        }

        /**
         * Updates the section header of a given section
         * @param sectionIdx
         * @param [sectionFragment]
         * @param [calledFromReload] if true, no need to check if a reload is in progress, as the reload did trigger it!
         */
        updateSectionHeader(sectionIdx, sectionFragment, calledFromReload = false) {
            // The TableView is async! Await its reload before processing this function!
            let promise;
            if (this.isWaitingForReload() && !calledFromReload) {
                var args = arguments;
                promise = this._reloadPrms.then(function () {
                    return this.updateSectionHeader.apply(this, args);
                }.bind(this));

            } else {
                if (!sectionFragment) {
                    GUI.animationHandler.schedule(() => {
                        sectionFragment = this.element.find("#section-" + sectionIdx);
                        sectionFragment.find(".section__header").remove();
                    });
                }

                if (this.dataSource.groupForHeaderInSection) {
                    var group = this.dataSource.groupForHeaderInSection.call(this.dataSource, sectionIdx, this); // Don't append any section header if no group is returned

                    if (group) {
                        if (group.uuid !== UnassignedUUID) {
                            var headerTemplate;

                            if (group.descriptionText) {
                                headerTemplate = ControlListTableView.Template.getBoldTitleSectionHeaderWithDescriptionAndButton(group.name, group.descriptionText, group.iconSrc);

                                if (group.iconSrc) {
                                    var rightSectionButton = headerTemplate.find(".header__edit-button");
                                    this.eventHandlers.push(Hammer(rightSectionButton[0])); // register button if rightSectionButtonTapped exists, otherwise it will be handled of the EditableTableView probably

                                    if (!!this.delegate.rightSectionButtonTapped) {
                                        this.eventHandlers[this.eventHandlers.length - 1].on(tapEvent(), this.delegate.rightSectionButtonTapped.bind(this.delegate, sectionIdx, this));
                                    }
                                }
                            } else {
                                headerTemplate = ControlListTableView.Template.getHeaderForSection(group.image, group.name);
                            }

                            if (group.image) {
                                var iconPlaceholder = headerTemplate.find('.header__icon-placeholder');
                                ImageBox.getImage(group.image).then(this._setGroupImage.bind(this, iconPlaceholder, group.color));
                            }

                            var header;
                            promise = GUI.animationHandler.schedule(() => {
                                sectionFragment.prepend(headerTemplate);
                                header = sectionFragment.children('.section__header').addClass("clickable");
                            }).then(() => {
                                this.eventHandlers.push(Hammer(header[0]));

                                if (this.delegate.didSelectHeader) {
                                    this.eventHandlers[this.eventHandlers.length - 1].on(tapEvent(), this.delegate.didSelectHeader.bind(this.delegate, sectionIdx, this));
                                }
                            });

                        } else {
                            promise = GUI.animationHandler.schedule(() => {
                                sectionFragment.prepend(ControlListTableView.Template.getEmptyHeaderForSection());
                            });
                        }
                    }
                } else {
                    console.warn("controlListTableView: missing groupForHeaderInSection delegate");
                }
            }
            return promise || Q.resolve();
        }

        /**
         * Returns the number of controls in this section that have the same controlType. Optionally it also checks
         * if they are in the same room and category too. This is needed in the favorite tab, where there might be
         * more than one AudioZone.
         * @param controlType e.g. AudioZone, Intercom, LightController,...
         * @param sectionIdx
         * @param room optional - it verifies whether room has to be checked for equality too.
         * @param cat optional - it verifies whether category has to be checked for equality too.
         * @returns {number}
         */
        numberOfEqualControlsInSection(controlType, sectionIdx, room, cat) {
            if (!room) {
                room = null;
            }

            if (!cat) {
                cat = null;
            }

            var rows = this._getNumberOfRowsInSection(sectionIdx);

            var equalControlsInSection = 0;
            var control, eqCtrl, eqCat, eqRoom;

            for (var i = 0; i < rows; i++) {
                control = this.dataSource.controlForCellAtIndex.call(this.dataSource, sectionIdx, i, this);
                eqCtrl = control.controlType === controlType;
                eqRoom = room !== null ? control.room === room : true;
                eqCat = cat !== null ? control.cat === cat : true;

                if (eqCat && eqCtrl && eqRoom) {
                    equalControlsInSection++;
                }
            }

            return equalControlsInSection;
        }

        // Private methods

        /**
         * Overriden since controlCells are to be handled differently:
         * - Their constructor is specified by their controlType
         * - Those cells are their own delegate and dataSource
         * - They don't have a content, since a control itself and state updates describe how the cellContent is to
         *     be constructed
         */
        _createRowAtIndex(sectionIdx, rowIdx) {
            //console.log("_createRowAtIndex: section: " + sectionIdx + " row: " + rowIdx);
            var def = Q.defer(),
                control = this.dataSource.controlForCellAtIndex.call(this.dataSource, sectionIdx, rowIdx, this); // reuse cells

            var reusedCell = this.dataSource.dequeueReusableCell && this.dataSource.dequeueReusableCell(control.uuidAction);

            if (reusedCell) {
                //console.info("reusing cell");
                reusedCell.sectionIdx = sectionIdx;
                reusedCell.rowIdx = rowIdx;
                return reusedCell;
            }

            var constructor = Controls[control.controlType + "Control"];

            if (typeof constructor === "function") {
                def.resolve(new constructor(this.delegate, this.dataSource, control, sectionIdx, rowIdx, this));
            } else {
                def.reject("'" + control.controlType + "Control' not handled correctly, constructor not found!");
            }

            return def.promise;
        }

        _setGroupImage(placeholder, groupColor, image) {
            if (image.mimeType === "svg") {
                var imageElem = $(image.image);
                imageElem.attr('class', 'icon-placeholder__icon');
                imageElem.css('fill', groupColor);
                placeholder.html(imageElem);
            } else {
                placeholder.html('<img class="icon-placeholder__icon" src="' + image.image + '"/>');
            }
        }

    }

    GUI.ControlListTableView = ControlListTableView;
    return GUI;
}(window.GUI || {});
