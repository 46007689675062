'use strict';

define("VentilationControlCommandSrc", ["ControlCommandSrc", "VentilationControlEnums"], function (ControlCommandSrc, VentilationControlEnums) {
    return class VentilationControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states, navigation) {
            let cmds = [];

            if (control.hasError() || control.hasWarning()) {
                // Action is optional
                if (states.controlInfo.action) {
                    cmds.push(this.createCommand(states.controlInfo.action.name, states.controlInfo.action.cmd));
                }
            } // Don't allow to set timers if the control is in error state and if the current timer is the settings timer
            // Settings timer will be stopped after 20 seconds or when the user closes out of the settings screen


            if (!control.hasError() && states.activeTimerProfile !== VentilationControlEnums.TIMER_PRESET.SETTINGS) {
                if (states.overwriteUntil) {
                    cmds.push(this.createCommand(_('controls.leaf.timer.stop'), Commands.VENTILATION.TIMER.STOP));
                } else {
                    // Deactivate the command if the control is stopped by logic,
                    // the control ignores all commands in this state
                    cmds.push(this.createDeactivatable(_('controls.leaf.timer.start'), () => {
                        navigation.navigate(VentilationControlEnums.SCREEN_STATES.VENT_TIMER_SCREEN, { control });
                    }, null, this._isTimerButtonDisabled(states.ventReason)));
                }
            }

            return cmds;
        }

        _isTimerButtonDisabled(ventReason) {
            return ventReason === VentilationControlEnums.VENT_REASONS.STOP || ventReason === VentilationControlEnums.VENT_REASONS.FROST_PROTECTION;
        }

    };
});
