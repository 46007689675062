'use strict';

define(["./sortingScreen.jsx"], function (SortingScreenComp) {
    return class SortingScreen extends GUI.Screen {
        static ReactComp = SortingScreenComp.default;

        constructor(details) {
            super($('<div/>'));
        }

        getAnimation() {
            return AnimationType.MODAL;
        }
    };
});

