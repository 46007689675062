import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SystemStateInfo(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path d="M12 0c6.624 0 12 5.376 12 12s-5.376 12-12 12S0 18.624 0 12 5.376 0 12 0zm0 9.5a.5.5 0 00-.5.5v7.5a.5.5 0 001 0V10a.5.5 0 00-.5-.5zm0-2A.75.75 0 1012 6a.75.75 0 000 1.5z" />
        </Svg>
    )
}

export default SystemStateInfo
