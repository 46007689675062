import AlarmControlBaseStatesSrc from "../alarmControlBase/AlarmControlBaseStatesSrc";

export default class AlarmControlStatesSrc extends AlarmControlBaseStatesSrc{
    static getMoreSection(control, states, navigation) {
        let section = super.getMoreSection(control, states, navigation)

        if (!states.alarmActive && control.movementEnabled()) {
            section.rows.unshift({
                content: {
                    title: _("controls.alarm.allow-presence-detection"),
                    active: !states.disableMove
                },
                type: GUI.TableViewV2.CellType.SWITCH,
                onSwitchChanged: this.toggleMovement.bind(this, control)
            });
        }

        return section;
    }

    static toggleMovement(control, active, section, row, tableView, cell) {
        control.sendCommand(Commands.format(Commands.ALARM.DISABLE_MOVEMENT, !active));
    }
}
