'use strict';

define(["AtheneMediaHandler", "AtheneControlEnums"], function (AtheneMediaHandler, AtheneEnums) {
    return class AtheneSettingsScreen extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            this.control = details.control;
            this.mediaHandler = AtheneMediaHandler.shared(this.control);
            this.mediaHandler.delegate = this;
            this.version = "-";
        }

        viewDidLoad() {
            let args = arguments,
                states = this.control.getStates();
            this.microphones = this.mediaHandler.localInputDevices;
            this.currentMicrophone = this.mediaHandler.localInputDevice;
            this.mediaHandler.deviceInfo && (this.verison = this.mediaHandler.deviceInfo.version); // Clone the object to not manipulate the StateContainer

            this.ttsAnswers = cloneObject(this.control.getStates().answers);
            this._ogTtsAnswers = cloneObject(this.ttsAnswers);
            return AtheneMediaHandler.deviceSupportsH264().then(supportsH264 => {
                this._deviceSupportsH264 = supportsH264;
                return PersistenceComponent.getShared(this._createKey(AtheneEnums.SETTINGS.PIP_SETTING)).then(position => {
                    this._pipPosition = position || AtheneEnums.SETTINGS.PIP_POSITION.RIGHT;
                    this.version = states.version;
                    return super.viewDidLoad(...args);
                });
            });
        }

        viewWillDisappear() {
            if (JSON.stringify(this.ttsAnswers) !== JSON.stringify(this._ogTtsAnswers)) {
                this.control.sendCommand(Commands.format(Commands.INTERCOM_GEN_2.SET_ANSWERS, this.ttsAnswers.join("/")));
            }

            return super.viewWillDisappear(...arguments);
        }

        titleBarText() {
            return _("athene.settings.title");
        }

        getAnimation() {
            if (HD_APP) {
                return AnimationType.MODAL;
            }

            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        reloadTable() {
            var microphoneSection, ttsSection, pipSection, internalOptionsSection, externalOptionsSection, aboutSection;
            this.tableContent = [];

            if (this.features && this.features.canSetMicrophone && this.currentMicrophone) {
                microphoneSection = {
                    headerTitle: _("athene.settings.microphone"),
                    rows: []
                };
                this.microphones.forEach(function (microphone) {
                    microphoneSection.rows.pushObject(this._createCheckableInputCell(microphone));
                }.bind(this));
                this.tableContent.push(microphoneSection);
            }

            ttsSection = {
                headerTitle: _("athene.settings.tts.title"),
                rows: [],
                footerTitle: _("athene.settings.tts.hint")
            };
            ttsSection.rows = ["A", "B", "C"].map(function (placeholder, idx) {
                return this._createTtsCell(this.ttsAnswers[idx], placeholder);
            }.bind(this));
            this.tableContent.push(ttsSection);

            if (this._deviceSupportsH264) {
                pipSection = {
                    headerTitle: _("athene.settings.pip.title"),
                    rows: [this._createPipCell(AtheneEnums.SETTINGS.PIP_POSITION.RIGHT), this._createPipCell(AtheneEnums.SETTINGS.PIP_POSITION.LEFT)]
                };
                this.tableContent.push(pipSection);
            }

            aboutSection = {
                headerTitle: _("athene.settings.about.title"),
                rows: [this._createAboutCell(_("athene.settings.about.name"), this.control.details.deviceName || this.control.name), this._createAboutCell(_("athene.settings.about.serial"), this.control.details.serialNr), this._createAboutCell(_("athene.settings.about.firmware"), this.version)]
            };

            if (!this.control.isTrustMember) {
                aboutSection.rows.push({
                    content: {
                        title: _("update.more-info"),
                        clickable: true,
                        disclosureIcon: true
                    },
                    action: function () {
                        NavigationComp.openWebsite(this.mediaHandler.host);
                    }.bind(this)
                }, {
                    content: {
                        title: _("audio-server.settings.reboot", {
                            productName: this.control.details.deviceName || this.control.name
                        }),
                        clickable: true
                    },
                    action: function () {
                        this.mediaHandler.sendCommandViaHTTP(this.mediaHandler.constructor.COMMANDS.REBOOT);
                    }.bind(this)
                });
            }

            this.tableContent.push(aboutSection);
            /*if (!!GUI.DebugScreen) {
                this.tableContent.push({
                    rows: [
                        {
                            content: {
                                title: _("diagnosis-information"),
                                clickable: true,
                                disclosureIcon: true
                            },
                            action: function () {
                                NavigationComp.showState(AtheneEnums.Screens.DIAGNOSIS, { control: this.control });
                            }.bind(this)
                        }
                    ]
                });
            }*/

            return super.reloadTable(...arguments);
        }

        _createKey(setting) {
            return setting + "_" + this.control.uuidAction;
        }

        _createPipCell(position) {
            return {
                content: {
                    title: position === AtheneEnums.SETTINGS.PIP_POSITION.RIGHT ? _("athene.settings.pip.right") : _("athene.settings.pip.left"),
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                    selected: this._pipPosition === position,
                    position: position
                },
                type: GUI.TableViewV2.CellType.CHECKABLE,
                didCheckCell: this._checkPipCell.bind(this)
            };
        }

        _createTtsCell(message, count) {
            return {
                type: GUI.TableViewV2.CellType.INPUT,
                content: {
                    value: message,
                    // validationRegex: Regex.CMD_TEXT,         // TODO-config: - tts messages are not correctly transfered from the miniserver
                    maxLength: AtheneEnums.MAX_TTS_LENGTH,
                    placeholder: _("athene.settings.tts.placeholder", {
                        count: count
                    })
                },
                textChanged: function textChanged(section, row, tableView, value) {
                    this.ttsAnswers[row] = nullEmptyString(value);
                }.bind(this)
            };
        }

        _createAboutCell(title, info) {
            return {
                content: {
                    title: title,
                    disclosureText: info,
                    copyable: true
                }
            };
        }

        _createCheckableInputCell(object) {
            return {
                content: {
                    title: object.label,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                    object: object,
                    selected: object.deviceId === this.currentMicrophone.deviceId
                },
                type: GUI.TableViewV2.CellType.CHECKABLE,
                didCheckCell: this._checkAudioCell.bind(this)
            };
        }

        _checkPipCell(cell) {
            PersistenceComponent.setShared(this._createKey(AtheneEnums.SETTINGS.PIP_SETTING), cell.content.position);
        }

        _checkAudioCell(cell) {
            var audioDevice = cell.content.object;

            if (audioDevice.kind === AtheneMediaHandler.DEVICE_KIND.AUDIO.INPUT) {
                this.mediaHandler.localInputDevice = audioDevice;
                PersistenceComponent.setLocalStorageItem(this._createKey(AtheneEnums.SETTINGS.INPUT_DEVICE), audioDevice.deviceId);
            } else if (audioDevice.kind === AtheneMediaHandler.DEVICE_KIND.AUDIO.OUTPUT) {
                this.mediaHandler.localOutputDevice = audioDevice;
                PersistenceComponent.setLocalStorageItem(this._createKey(AtheneEnums.SETTINGS.OUTPUT_DEVICE), audioDevice.deviceId);
            }
        }

        // ############################################################
        // ############  Media handler delegates ######################
        // ############################################################
        onLocalDevicesChanged(devices) {
            this.microphones = this.mediaHandler.localInputDevices;
            this.currentMicrophone = this.mediaHandler.localInputDevice;
            this.reloadTable();
        }

    };
});
