'use strict';

define(["AudioZoneV2ControlEnums"], function (AudioZoneV2ControlEnums) {
    class SettingsLibrary extends GUI.TableViewScreenV2 {
        //region Static
        static Template = class {
            //region Static
            static getLibSectionFooter() {
                return $("<div class='lib-scanning-view'>" + "   <div class='lib-scanning-view__content'>" + "       <div class='content__loading-indicator'/>" + "       <div class='content__loading-text'>" + _("media.library.re-index-in-progress") + "</div>" + "       <div class='content__loading-desc'></div>" + "   </div>" + "</div>");
            } //endregion Static


        }; //endregion Static

        //region Private
        //fast-class-es6-converter: extracted from defineStatic({}) content
        ERROR = MusicServerEnum.NAS_ERROR; //endregion Private

        constructor(details) {
            super($('<div />'));
            this.control = details.control;
        }

        viewWillAppear() {
            var args = arguments;
            return this._loadData().then(() => {
                return this.reloadTable().then(() => {
                    return super.viewWillAppear(...args);
                });
            }, () => {
                // Ensure we don't get cought in the viewcontroller
                return super.viewWillAppear(...args);
            }).then(() => {
                if (!this.libraryScanRegId) {
                    this.libraryScanRegId = this.control.audioserverComp.registerForScanEvents(this._onLibraryScanEvent.bind(this));
                }
            });
        }

        viewDidDisappear() {
            if (!this._viewShouldRemainVisible) {
                this.libraryScanRegId && this.control.audioserverComp.unregisterFromScanEvents(this.libraryScanRegId);
                this.libraryScanRegId = null;
            }

            return super.viewDidDisappear(...arguments);
        }

        titleBarText() {
            return _("media.library");
        }

        reloadTable() {
            this.tableContent = [{
                rows: [{
                    content: {
                        leftIconSrc: Icon.TRANSLUCENT_ADD,
                        leftIconColor: window.Styles.colors.green,
                        title: _("media.library.add"),
                        clickable: true
                    },
                    type: GUI.TableViewV2.CellType.GENERAL,
                    action: function () {
                        this.ViewController.showState(AudioZoneV2ControlEnums.ScreenState.SETTINGS_LIBRARY_ADD_OR_EDIT, null, {
                            control: this.control
                        });
                    }.bind(this)
                }]
            }];

            if (this.data && this.data.length) {
                this.tableContent.push({
                    rows: this.data.map(function (libObj) {
                        return {
                            content: {
                                leftIconSrc: Icon.AudioZone.NEW.PLAYLIST_NAS,
                                leftIconColor: Color.TEXT_SECONDARY_B,
                                title: libObj.name,
                                subtitle: libObj.server + "/" + libObj.folder,
                                clickable: true,
                                rightIconSrc: Icon.SETTINGS_SMALL,
                                rightIconColor: libObj.configerror ? window.Styles.colors.red : null
                            },
                            action: function action() {
                                this.ViewController.showState(AudioZoneV2ControlEnums.ScreenState.SETTINGS_LIBRARY_OVERVIEW, null, {
                                    libObj: libObj,
                                    control: this.control
                                });
                            }.bind(this)
                        };
                    }.bind(this))
                });
            }

            this.tableContent.push({
                rows: [{
                    content: {
                        title: _("media.library.re-index"),
                        titleColor: window.Styles.colors.brand,
                        clickable: true
                    },
                    action: function action() {
                        this.control.audioserverComp.sendMediaServerCommand(MusicServerEnum.Commands.LIBRARY.RESCAN_ALL);
                    }.bind(this)
                }],
                footerElement: SettingsLibrary.Template.getLibSectionFooter()
            });
            return super.reloadTable(...arguments);
        }

        _loadData() {
            return NavigationComp.showWaitingFor(this.control.audioserverComp.connectionReadyDef.promise.then(function () {
                return this.control.audioserverComp.sendMediaServerCommand(MusicServerEnum.Commands.SETTINGS.LIBRARY.LIST).then(function (data) {
                    this.data = data.data;
                    return this.data;
                }.bind(this));
            }.bind(this)));
        }

        _onLibraryScanEvent(source, scanRes) {
            var footerText; // Disable and enable the update button to prevent button spamming

            this.tableView.cellForSectionAndRow(this.tableContent.length - 1, 0).setEnabled(!scanRes.isScanning);
            var sectionFooter = this.element.find(".lib-scanning-view");

            if (typeof scanRes.files !== "undefined") {
                if (scanRes.files > 1 && scanRes.folders > 1) {
                    footerText = _("media.library.re-index-detailed--multiple-files-folders", {
                        fileCnt: scanRes.files,
                        folderCnt: scanRes.folders
                    });
                } else if (scanRes.files > 1) {
                    footerText = _("media.library.re-index-detailed--multiple-files", {
                        fileCnt: scanRes.files,
                        folderCnt: scanRes.folders
                    });
                } else if (scanRes.folders > 1) {
                    footerText = _("media.library.re-index-detailed--multiple-folders", {
                        fileCnt: scanRes.files,
                        folderCnt: scanRes.folders
                    });
                } else {
                    footerText = _("media.library.re-index-detailed", {
                        fileCnt: scanRes.files,
                        folderCnt: scanRes.folders
                    });
                }

                sectionFooter.find(".content__loading-desc").text(footerText);
            }

            this.element.toggleClass("settings-library--is-scanning", scanRes.isScanning);
        }

    }

    Controls.AudioZoneV2Control.SettingsLibrary = SettingsLibrary;
    return Controls.AudioZoneV2Control.SettingsLibrary;
});
