'use strict';

define([], function () {
    return {
        Status: {
            NORMAL: 0,
            TRIGGERED: 1,
            LOCKED: 2,
            DISABLED: 3
        }
    };
});
