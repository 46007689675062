import { Graph, LoadingGraph, useControl } from "LxComponents"
import PropTypes from "prop-types";
import { EfmUtilities, EfmViewType } from "../../efmUtilities";
import { useMemo } from "react";

import globalStyles from "GlobalStyles";
import useExtendedStats from "../hooks/useExtendedStats.jsx";

import {forceDataIntoMode} from "../util/averageValues.js";
import generateLabels from "../util/generateLabels.js";

import { getTotalWhileIgnoringTinyNumbers } from "../util/getSpecificValue.js";

export default function GridGraph({ gridStats: nodeStats, viewType, timeRange, loading = false, controlUuid, currentValue, fullStretch, onZoom, gridNodes, isLivePast }) {
    const extendedNodeStatsData = useExtendedStats({stats: nodeStats, viewType, startTs: timeRange.start, endTs: timeRange.end, newExtend: true})
    const control = useControl(controlUuid);
    const {graphData, horizontalLabels, highlightDateFormat, bottomLabelsCentered, titleRight} = useMemo(() => {
        if(!nodeStats || !Array.isArray(nodeStats.data)) {
            return [];
        }

        const graphData = [];
        let usedData = extendedNodeStatsData;
        if(viewType === EfmViewType.Actual) {
            usedData = forceDataIntoMode(extendedNodeStatsData, timeRange.start, timeRange.end, nodeStats.mode);
        }
        usedData.forEach(stat => {
                const arr = [];
                if(viewType === EfmViewType.Actual) {
                    arr.push({
                        color: stat.values[0] > 0 ? globalStyles.colors.red : globalStyles.colors.yellow,
                        timestamp: stat.ts,
                        value: stat.values[0],
                    });
                } else {
                    stat.values.forEach((value, idx) => {
                        arr.push({
                            color: /[N|n]eg/g.test(nodeStats.header[idx].output) || value < 0 ? globalStyles.colors.yellow : globalStyles.colors.red,
                            timestamp: stat.ts,
                            value: value,
                        });
                    });
                }
                graphData.push(arr);
            }
        );

        let titleRight;
        if(nodeStats && currentValue && (typeof currentValue.total === 'number' || typeof currentValue.actual === 'number')){
            let num = 0;
            if(typeof currentValue.actual === 'number') {
                num = currentValue.actual;
            } else {
                num = currentValue.total - (currentValue.totalNeg || 0);
            }
            let titleRightFormat = nodeStats.header[0].format;
            if(nodeStats.dayStats && isLivePast) {
                titleRightFormat = nodeStats.dayStats.header[0].format;
            }
            const formattedTotal = lxUnitConverter.convertSplitAndApply(titleRightFormat, getTotalWhileIgnoringTinyNumbers({total: num}));
            titleRight = `${formattedTotal.valueTxt} ${formattedTotal.succTxt}`;
        }

        const {horizontalLabels, highlightDateFormat, bottomLabelsCentered} = generateLabels({viewType, data: graphData});
        return {
            graphData,
            horizontalLabels,
            highlightDateFormat,
            bottomLabelsCentered: bottomLabelsCentered,
            titleRight,
        }
    }, [nodeStats, currentValue]);

    // hide export if gridNode isn't bididrectional
    const gridLegend = useMemo(() => {
        let legend = [
            {
                color: globalStyles.colors.red,
                label: _("efm.grid-import"),
            }
        ]
        if (gridNodes && Array.isArray(gridNodes) && gridNodes.some(gridNode => gridNode.isBidirectional)) {
            legend.push({
                color: globalStyles.colors.yellow,
                label: _("efm.grid-export"),
            });
        }
        return legend;
    }, [control])


    return (
        <Graph
            titleLeft={!fullStretch ? _("efm.grid-title") : undefined}
            titleRight={!fullStretch ? titleRight : undefined}
            fullStretch={fullStretch}
            type={viewType === EfmViewType.Actual ? 'line' : 'bar-side-by-side'}
            horizontalLabels={horizontalLabels}
            datapoints={graphData}
            format={control.actualFormat}
            label={viewType === EfmViewType.Actual ? control.actualUnit : control.totalUnit}
            highlightDateFormat={highlightDateFormat}
            bottomLabelsCentered={bottomLabelsCentered}
            onZoom={onZoom}
            forcedColors={{top: globalStyles.colors.red, bottom: globalStyles.colors.yellow}}
            forcedTimeRange={timeRange}
            legend={gridLegend}
        />
    )
}

GridGraph.propTypes = {
    gridStats: PropTypes.object,
    timeRange: PropTypes.object,
    viewType: PropTypes.string,
}
