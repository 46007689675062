"use strict";

define([], function () {
    return class LoadManagerProgressView extends GUI.ControlStateView {
        //region Static
        static Template = class {
            //region Static
            static get() {
                return $('<div>'
                    + '<div class="load-manager-progress-view__top">'
                    + '<div class="top__left">'
                    + '<div class="left__container-current">'
                    + '<div class="container-current__top-power-current">'
                    + '<span class="top-power-current__value">--</span>'
                    + '<span class="top-power-current__unit"> kW</span>'
                    + '</div>'
                    + '<div class="container-current__bottom-power-current">'
                    + '<div class="bottom-power-current__title">'
                    + _("controls.loadManager.current-power")
                    + '</div>'
                    + '</div>'
                    + '</div>'
                    + '</div>'
                    + '<div class="top__middle">'
                    + '<div class="middle__reverse-progress"></div>'
                    + '</div>'
                    + '<div class="top__right">'
                    + '<div class="right__separator"></div>'
                    + '<div class="right__container-max">'
                    + '<div class="container-max__top-power-max">'
                    + '<span class="top-power-max__value">--</span>'
                    + '<span class="top-power-max__unit"> kW</span>'
                    + '</div>' + '<div class="container-max__bottom-power-max">'
                    + '<div class="bottom-power-max__title">'
                    + _("controls.loadManager.max-power")
                    + '</div>'
                    + '</div>'
                    + '</div>'
                    + '</div>'
                    + '</div>'
                    + '<div class="load-manager-progress-view__bottom">'
                    + '<div class="bottom__bar-title">' +
                    _("controls.loadManager.available-power", {
                        power: "--"
                    })
                    + '</div>'
                    + '</div>'
                    + '</div>'
                );
            } //endregion Static


        }; //endregion Static

        constructor(control) {
            super(control, LoadManagerProgressView.Template.get());
            this.constrol = control;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(() => {
                this.elements = {}; // Reverse progress bar

                this.elements.reverseProgress = this.element.find(".middle__reverse-progress"); // Left Container

                this.elements.topLeft = this.element.find('.top__left');
                this.elements.leftSeparator = this.element.find(".left__separator");
                this.elements.leftContainerCurrent = this.element.find(".left__container-current");
                this.elements.topPowerCurrent = this.element.find('.container-current__top-power-current'); // Spans

                this.elements.topPowerCurrentValue = this.element.find('.top-power-current__value');
                this.elements.topPowerCurrentUnit = this.element.find('.top-power-current__unit');
                this.elements.BottomPowerCurrent = this.element.find('.container-current__bottom-power-current');
                this.elements.BottomPowerCurrentTitle = this.element.find('.bottom-power-current__title'); // Middle Container

                this.elements.bottomBarTitle = this.element.find('.bottom__bar-title');
                this.elements.gradientBar = this.element.find('.top__middle'); // Right container

                this.elements.topPowerMaxValue = this.element.find('.top-power-max__value'); // Bottom container

                this.elements.bottomContainer = this.element.find('.load-manager-progress-view__bottom');
            });
        }

        receivedStates(states) {
            if (!this.elements || this.elements.gradientBar.height() === 0) {
                this.processWhenVisible(this.receivedStates.bind(this, states));
                return;
            }
            var leftContainerMarginRight = 8,
                topMargin = 48;
            this.elements.bottomBarTitle.text(states.availablePowerText);
            this.elements.topPowerMaxValue.text(states.maxPowerText);
            this.elements.topPowerCurrentValue.text(states.currentPowerText); // Current power calculation

            var gradientBarMaxHeight = this.elements.gradientBar.height(),
                gradientBarHeight = gradientBarMaxHeight / states.maxPower * states.currentPower,
                reverseProgressBarHeight = gradientBarMaxHeight - gradientBarHeight,
                ownWidth = this.element.width();

            if (reverseProgressBarHeight < 0) {
                reverseProgressBarHeight = 0;
            } else if (reverseProgressBarHeight >= gradientBarMaxHeight) {
                reverseProgressBarHeight = gradientBarMaxHeight;
            }

            if (gradientBarHeight < 0) {
                gradientBarHeight = 0;
            }

            this.elements.reverseProgress.height(reverseProgressBarHeight);
            this.elements.leftContainerCurrent.width((ownWidth - this.elements.gradientBar.width()) / 2 - leftContainerMarginRight);


            if (reverseProgressBarHeight >= gradientBarMaxHeight / 2) {
                // Text above
                this.elements.leftContainerCurrent.addClass("container-current--above");
                this.elements.leftContainerCurrent.css("bottom", gradientBarHeight + this.elements.bottomContainer.height());
            } else {
                // Text below
                this.elements.leftContainerCurrent.removeClass("container-current--above");
                this.elements.leftContainerCurrent.css("top", reverseProgressBarHeight + topMargin);
            }
            this.elements.leftContainerCurrent.css("display", "initial");

            if (states.currentPower > states.maxPower) {
                this.elements.topPowerCurrentValue.addClass("value--exceeded");
                this.elements.topPowerCurrentUnit.addClass("unit--exceeded");
                this.elements.bottomBarTitle.addClass("bar-title--exceeded");
                this.elements.gradientBar.addClass("top-middle--exceeded");
                this.elements.bottomBarTitle.text(_("controls.loadManager.max-exceeded"));
            } else if (states.currentPower === states.maxPower) {
                this.elements.topPowerCurrentValue.removeClass("value--exceeded");
                this.elements.topPowerCurrentUnit.removeClass("unit--exceeded");
                this.elements.bottomBarTitle.removeClass("bar-title--exceeded");
                this.elements.gradientBar.removeClass("top-middle--exceeded");
                this.elements.bottomBarTitle.text(_("controls.loadManager.max-power-reached"));
            } else {
                this.elements.topPowerCurrentValue.removeClass("value--exceeded");
                this.elements.topPowerCurrentUnit.removeClass("unit--exceeded");
                this.elements.bottomBarTitle.removeClass("bar-title--exceeded");
                this.elements.gradientBar.removeClass("top-middle--exceeded");
                this.elements.bottomBarTitle.text(_("controls.loadManager.available-power", {
                    power: states.availablePowerText
                }));
            }
        }

    };
});
