import * as React from "react"
import Svg, { Path } from "react-native-svg"

function AlarmClockOff(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path d="M2.707 1.293a1 1 0 00-1.414 1.414l20 20a1 1 0 001.414-1.414l-20-20zm1.244-.17l3.11 3.11a10.434 10.434 0 011.938-.798 4.5 4.5 0 00-5.048-2.312zM.5 5.5c0-.64.133-1.247.374-1.798L3.94 6.769c-.61.73-1.123 1.543-1.518 2.42A4.495 4.495 0 01.5 5.5zm2 8a9.46 9.46 0 012.151-6.02l13.37 13.369A9.461 9.461 0 0112 23a9.5 9.5 0 01-9.5-9.5zm18.025 4.197L7.803 4.975A9.461 9.461 0 0112 4a9.5 9.5 0 019.5 9.5 9.46 9.46 0 01-.975 4.197zM6.053 22.154a10.5 10.5 0 01-.803-.611l-.604.603a.5.5 0 10.708.707l.699-.699z" />
            <Path d="M23.5 5.5c0 1.527-.76 2.876-1.923 3.69a10.528 10.528 0 00-6.576-5.755A4.5 4.5 0 0123.5 5.5z" />
        </Svg>
    )
}

export default AlarmClockOff
