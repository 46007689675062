'use strict';

define(["IRoomControlEnums"], function (IRoomControlEnums) {
    return class IRoomControlContentTemperatureSelection extends Controls.ControlContent {
        constructor(details) {
            super(details);
            this.selectedTempMode = details.entry.tempMode;
            this.parentControl = ActiveMSComponent.getControlByUUID(this.control.uuidParent);
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(() => {
                this.listSource = [//IRoomControlEnums.IRCTempMode.ECONOMY, no entry means "ECO-Temperature" - don't offer it explicitly.
                    IRoomControlEnums.IRCTempMode.PARTY, IRoomControlEnums.IRCTempMode.INCREASED_HEAT, IRoomControlEnums.IRCTempMode.EMPTY_HOUSE, IRoomControlEnums.IRCTempMode.HEAT_PROTECTION];

                if (this.control.details.isHeating) {
                    this.listSource.unshift(IRoomControlEnums.IRCTempMode.COMFORT_HEATING);
                } else if (this.control.details.isCooling) {
                    this.listSource.unshift(IRoomControlEnums.IRCTempMode.COMFORT_COOLING);
                }

                this.tableViewDataSource = tableViewDataSource(null, null, this);
                this.tableViewDelegate = tableViewDelegate(null, this);

                this.tableView = new GUI.TableViewV2(this.tableViewDataSource, this.tableViewDelegate);
                this.tableView.getElement().addClass("temperature-selection__table-view");
                return this.appendSubview(this.tableView).then(() => {
                    return this.receivedStates(this.parentControl.getStates());
                });
            });
        }

        getURL() {
            return "TemperatureList";
        }

        titleBarText() {
            if (this.control.details.isHeating) {
                return _("controls.irc.daytimer.heating.entries");
            } else if (this.control.details.isCooling) {
                return _("controls.irc.daytimer.cooling.entries");
            }
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK,
                style: 'transparent'
            };
        }

        receivedStates(states) {
            this.activeTemperatures = states.activeTemperatures;
            this._reloadTable();
        }

        _reloadTable() {
            let tableContent = this._getTableContent(),
                dataSourceUpdated = this.tableViewDataSource.update(tableContent),
                delegateUpdated = this.tableViewDelegate.update(tableContent);

            if (dataSourceUpdated || delegateUpdated) {
                this.tableView.reload();
            }
        }

        _getTableContent() {
            let tableContent = [],
                section = {
                    rows: []
                };

            section.rows = this.listSource.map((entry) => {
                return {
                    type: GUI.TableViewV2.CellType.CHECKABLE,
                    content: {
                        title: this.parentControl.getNameOfIRCTempMode(entry),
                        selected: this.selectedTempMode === entry,
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                        disclosureText: lxFormat(this.control.details.format, this.activeTemperatures[entry]) + SandboxComponent.getTemperatureUnit(),
                        disclosureColor: this.parentControl.getColorOfIRCTempMode(entry)
                    },
                    didCheckCell: (cell, section, row, tableView, selected) => {
                        if (selected) {
                            this.selectedTempMode = entry;

                            this._navigateBack();
                        }
                    }
                }
            })

            tableContent.push(section);

            return tableContent;
        }

        _navigateBack() {
            this.ViewController.navigateBack(false, {
                modeNr: this.selectedTempMode,
                targetTemp: this.activeTemperatures[this.selectedTempMode]
            });
        }

    };
});
