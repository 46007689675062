import globalStyles from "GlobalStyles";
import useAmbientWallpaper from "../hooks/useAmbientWallpaper";
import useAmbientWallpaperOptions, { OptionType } from "../hooks/useAmbientWallpaperOptions";
import { StyleSheet, ScrollView, Image, View } from 'react-native';
import PropTypes from "prop-types";
import { LxReactPressable } from "LxComponents";
import Icons from "IconLib"
import { useRef, useMemo, useEffect } from "react";
import { launchImageLibrary } from "react-native-image-picker";

export function ImagePreview() {
    const currBgImage = useAmbientWallpaper();

    return (
        <Image style={styles.previewImage} source={currBgImage ? currBgImage.source : null} />
    )
}

export function ImageSlider(props) {
    const bgImageOptions = useAmbientWallpaperOptions();
    const scrollViewRef = useRef();
    const refs = useRef({
        currentOffset: 0,
        scrollInterval: null
    });

    const getIdForBgImage = (imgObj) => {
        if (!imgObj) {
            return "-"
        }
        switch (imgObj.type) {
            case OptionType.BASE_IMAGE:
                return JSON.stringify(imgObj.source).hashCode();
            case OptionType.DEVICE_UPLOAD:
                return OptionType.DEVICE_UPLOAD
            default:
                return "-"
        }
    }

    const allImages = useMemo(() => {
        console.log("ALL_IMAGES_MEMO", bgImageOptions, props.additionalImages);
        let result = [...props.additionalImages, ...bgImageOptions].filter(img => {
            return !!img;
        }).map((image) => {
            return {
                id: getIdForBgImage(image),
                source: image.sourceThumb || image.source,
                sourceFull: image.source,
                author: image.author,
                authorLink: image.authorLink
            }
        })
        console.log("ALL_IMAGES_MEMO -> result = ", result);
        return result;
    }, [bgImageOptions, props.additionalImages])

    // ensure to scroll back, important for as it may be reused.
    useEffect(() => {
        refs.current.currentOffset = 0;
        if (scrollViewRef.current) {
            scrollViewRef.current.scrollTo({
                x: refs.current.currentOffset,
                animated: false
            });
        }
    }, [allImages]);

    const scrollOne = (back) => {
        const currentOffset = refs.current.currentOffset;
        const scrollOffset = back ? currentOffset - 180 : currentOffset + 180;
        if (scrollOffset < -180 || scrollOffset > (allImages.length + 1) * 180) {
            return
        }
        if (scrollViewRef.current) {
            scrollViewRef.current.scrollTo({
                x: scrollOffset,
                animated: true
            });
        }
    }

    const toggleLongScroll = (start, back) => {
        if (!start && refs.current.scrollInterval) {
            clearInterval(refs.current.scrollInterval);
            refs.current.scrollInterval = null;
        } else if (start && !refs.current.scrollInterval) {
            refs.current.scrollInterval = setInterval(() => scrollOne(back), 300)
        }
    }

    const onScroll = ({ nativeEvent }) => {
        refs.current.currentOffset = nativeEvent.contentOffset.x;
    }

    const ScrollIcon = ({ back }) => {
        const Icon = back ? Icons.ArrowLeft : Icons.ArrowRight;
        return <LxReactPressable onPress={scrollOne.bind(this, back)}
            onPressOut={() => toggleLongScroll(false, back)}
            onLongPress={() => toggleLongScroll(true, back)}>
            <Icon style={styles.arrowIcon} />
        </LxReactPressable>
    }

    const getUploadItem = () => {
        return <LxReactPressable key={"upload-item"} style={styles.uploadContainer} showHoverAnimation={true} onPress={() => {
            launchImageLibrary({ mediaType: "photo" }).then((res) => {
                let supportedFileTypes = [
                        "png",
                        "jpeg"
                    ];
                if (Debug.Experimental) {
                    supportedFileTypes.push("gif");
                }
                let supportedRegex = new RegExp(`^data:image\/(?:${supportedFileTypes.join("|")})`);
                if (!res.didCancel) {
                    if (supportedRegex.test(res.assets[0].uri)) {
                        const selectedImageUri = res.assets[0].uri;
                        let imgObj = {
                            sourceFull: { uri: selectedImageUri },
                            author: "",
                            authorLink: ""
                        };
                        props.onImageSelected(imgObj);
                    } else {
                        var content = {
                            title: _("ambient.image-picker.wrong-format.title"),
                            message: _("ambient.image-picker.wrong-format.description", {
                                formats: supportedFileTypes.join(", ")
                            }),
                            buttonOk: _('okay'),
                            icon: Icon.CAUTION
                        };
                        NavigationComp.showPopup(content);
                    }
                }

            }, (err) => {
                console.error("ImageSlider", "failed to load img from FS: ", err);
            })

        }}>
            <Icons.Plus style={[styles.sliderImage, styles.uploadIcon]} />
        </LxReactPressable>
    }

    const getImageList = () => {
        let result = allImages.map((image, index) => {
            return (
                <LxReactPressable key={index} showHoverAnimation={true} onPress={() => {
                    props.onImageSelected(image);
                }}>
                    <Image
                        style={styles.sliderImage}
                        source={image.source}
                    />
                </LxReactPressable>
            );
        })

        result.splice(0, 0, getUploadItem());
        return result;
    }
    return (
        <View style={styles.container}>
            <ScrollIcon back={true} />
            <ScrollView ref={scrollViewRef}
                pagingEnabled={true}
                horizontal={true}
                scrollEventThrottle={2} //should suffice, won't need high precision
                onScroll={onScroll}
                style={styles.scrollView}
            >
                {getImageList()}
            </ScrollView>
            <ScrollIcon back={false} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    title: {
        ...globalStyles.textStyles.title3.bold,
        color: globalStyles.colors.text.primary
    },
    loadFromLink: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.green_fixed
    },
    scrollView: {
        flex: 1,
        marginHorizontal: globalStyles.spacings.gaps.smallRegular
    },
    sliderImage: {
        flex: 1,
        width: 160,
        margin: globalStyles.spacings.gaps.verySmall,
        height: 100,
        resizeMode: 'cover'
    },
    arrowIcon: {
        height: globalStyles.sizes.icons.small,
        width: globalStyles.sizes.icons.small,
        fill: globalStyles.colors.text.primary
    },
    previewImage: {
        height: 233,
        width: 350,
        resizeMode: 'contain'
    },
    uploadContainer: {
        ...globalStyles.customStyles.blurredBackground,
        width: 160,
        margin: globalStyles.spacings.gaps.verySmall,
        height: 100,
    },
    uploadIcon: {
        fill: globalStyles.colors.text.primary,
        height: globalStyles.sizes.icons.regular,
        width: globalStyles.sizes.icons.regular,
        justifySelf: "center",
        alignSelf: "center"
    }
})

ImageSlider.propTypes = {
    onImageSelected: PropTypes.func,
    additionalImages: PropTypes.array
}
