'use strict';

define(['DaytimerListEntries', 'DaytimerCalendarEntries'], function (DaytimerListEntries, DaytimerCalendarEntries) {
    return class SplitOperationModeView extends GUI.View {
        //region Static
        static Template = function () {
            var createTemplate = function createTemplate() {
                return $('' + '<div class="split-operation-mode-view">' + '<div class="split-operation-mode-view__calendar-container"></div>' + '</div>' + '');
            };

            return {
                createTemplate: createTemplate
            };
        }(); //endregion Static

        constructor(ctrl) {
            super(SplitOperationModeView.Template.createTemplate());
            Object.assign(this, StateHandler.Mixin);
            this.currentMode = null;
            this.control = ctrl;
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.calendarContainer = this.element.find('.split-operation-mode-view__calendar-container');
            this.calendarView = new DaytimerCalendarEntries(this.control, function () {
            });
            this.calendarView.getElement().addClass('calendar-container__calendar-entries');
            this.calendarView.name = "SplitCalendarEntries";
            this.appendSubview(this.calendarView, this.calendarContainer);
            this.listView = new DaytimerListEntries(this.control, true);
            this.listView.sendCommand = this.sendCommand;
            this.listView.getElement().addClass('split-operation-mode-view__list');
            this.appendSubview(this.listView);
        }

        viewWillAppear() {
            // Request state updates before the subviews receive viewWillAppear in order to avoid issues
            // in the lifecycle methods if a subview is to be hidden/removed based on the states.
            // Lengthy tasks in received states should be performed async after viewDidAppear was called.
            this._requestStates();

            super.viewWillAppear(...arguments);
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);

            this._registerForStates();
        }

        viewWillDisappear() {
            this._unregisterStates();

            super.viewWillDisappear(...arguments);
        }

        receivedStates(states) {
            Debug.Control.Daytimer.SplitView && console.log(this.name, "receivedStates");
            this.listStates = cloneObjectDeep(states);
            this.calendarStates = cloneObjectDeep(states);

            if (this.currentMode == null) {
                this.currentMode = states.activeMode;
            }

            this.selectMode(this.currentMode);
        }

        selectMode(modeNr) {
            Debug.Control.Daytimer.SplitView && console.log(this.name, "selectMode " + modeNr);
            this.currentMode = modeNr;
            this.listView.selectMode(this.currentMode);
            this.calendarView.restrictToMode(modeNr);
        }

        setEditMode(editactive) {
            Debug.Control.Daytimer.SplitView && console.log(this.name, "setEditMode: " + editactive);
            this.listView.setEditMode(editactive);
        }

        removeDeletedEntries() {
            this.listView.removeDeletedEntries();
        }

    };
});
