'use strict';

define([], function () {
    return class AutomaticDesignerHistoryScreen extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div/>'));
            this.filterControls = details.filterControls;
        }

        viewWillAppear() {
            var baseCall = super.viewWillAppear(...arguments);
            SandboxComponent.registerForStateChangesForUUID(AutomaticDesignerEnums.AUTOMATIC_DESIGNER_STATE_UUID, this, this._receivedStates.bind(this));
            return baseCall;
        }

        updateView(details) {
            this.filterControls = details.filterControls;
            return this.reloadTable();
        }

        viewDidDisappear() {
            SandboxComponent.unregisterForStateChangesForUUID(AutomaticDesignerEnums.AUTOMATIC_DESIGNER_STATE_UUID, this);
            return super.viewDidDisappear(...arguments);
        }

        getURL() {
            return "History";
        }

        reloadTable() {
            return AutomaticDesignerComponent.getRules(this.filterControls).then(rules => {
                this.tableContent = [{
                    headerTitle: "",
                    headerDescription: _("automatic-designer.history.title")
                }];
                this.history && this.history.forEach(function (entry) {
                    this.tableContent.pushObject(this._createSectionForEntry(entry, rules));
                }.bind(this));
                return super.reloadTable(...arguments);
            });
        }

        _receivedStates(states) {
            Debug.Autopilot.StateContainer && console.log("AutopilotRulesScreen received states");
            var historyDidUpdate = states.historyEntries.length > 0 && (!this.history || this.history.length > 0 && states.lastEntry.time !== this.history[0].time);
            this.history = states.historyEntries;

            if (this.history.length === 0) {
                this.tableView.setBackgroundView(new GUI.BackgroundView(Icon.Alarm.CLOCK, _('autopilot.no-history'), _('autopilot.no-history.description')));
            } else {
                this.tableView.setBackgroundView(null);
            }

            if (historyDidUpdate) {
                this.reloadTable();
            }
        }

        _createSectionForEntry(entry, rules) {
            var rows = [];
            entry.entries.forEach(function (historicRule) {
                rows.pushObject(this._getCellForHistoricRule(historicRule, rules));
            }.bind(this));

            if (rows.length) {
                return {
                    headerTitle: entry.formatedDate,
                    rows: rows
                };
            } else {
                return null;
            }
        }

        _getCellForHistoricRule(historicRule, rules) {
            var associatedRule = rules.find(function (rule) {
                return rule.id === historicRule.uuid;
            });

            if (this.filterControls && this.filterControls.length) {
                if (!associatedRule) {
                    return null;
                }
            }

            return {
                content: {
                    title: historicRule.title,
                    subtitle: !associatedRule ? _("autopilot.not-existing") : null,
                    disclosureText: historicRule.time,
                    clickable: !!associatedRule
                },
                action: function () {
                    if (associatedRule) {
                        NavigationComp.showState(ScreenState.AutomaticDesigner.RuleViewController, {
                            rule: associatedRule
                        });
                    }
                }
            };
        }

    };
});
