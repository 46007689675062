import {useMemo} from 'react';

import useNodesValueUpdate from '../../Controls/energyFlowMonitorControl/content/useNodesValueUpdate';

import {getLeafNodesForStatistics} from '../utils';

export default (nodes, viewType, viewTs) => {
    const leafNodes = useMemo(() => {
        const arr = [];
        getLeafNodesForStatistics(arr, nodes); // filled by reference
        return arr;
    }, [nodes]);

    const nodeStates = useNodesValueUpdate(leafNodes, viewType, viewTs);

    const typedValues = useMemo(() => {
        const result = {};
        Object.entries(nodeStates).forEach(([nodeUuid, state]) => {
            const node = leafNodes.find(node => node._uuid === nodeUuid);
            if(node) {
                const usedState = cloneObject(state.stateValueMap);
                if(!result[node.nodeType]) {
                    result[node.nodeType] = usedState;
                } else {
                    const existingState = result[node.nodeType];
                    Object.entries(existingState).forEach(([key, value]) => {
                        usedState[key] += value;
                    });
                    result[node.nodeType] = usedState;
                }
            }
        });
        return result;
    }, [Object.entries(nodeStates).map(([nodeUuid, state]) => `${nodeUuid}-${JSON.stringify(state.stateValueMap)}`).join('|')]);
    return typedValues
}
