import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Weather03n(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            <Path
                d="M20.04 5a8.853 8.853 0 00-2.111.244A8.02 8.02 0 1028 14.015a5.177 5.177 0 01-1.95.325 5.997 5.997 0 01-6.01-6.01 5.488 5.488 0 01.975-3.249A3.954 3.954 0 0020.041 5z"
                fill="#9cb2bc"
            />
            <Path
                d="M2 21.6a5.431 5.431 0 012.627-4.677A7.984 7.984 0 0112.5 9a7.825 7.825 0 016.699 3.839 5.543 5.543 0 011.613-.239 5.698 5.698 0 015.326 3.79A5.42 5.42 0 0124.75 27H7.25A5.327 5.327 0 012 21.6z"
                fill="#d5e2e9"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default Weather03n
