'use strict';

define("SteakTargetTempSelectorScreen", ['TargetValueSelectorScreenBase', 'SteakControlEnums'], function (TargetValueSelectorScreenBase, SteakControlEnums) {
    return class SteakTargetTempSelectorScreen extends TargetValueSelectorScreenBase {
        constructor(details) {
            super(...arguments);
            this.sensor = details.sensor;
            this.originPos = this.sensor.targetTemperature;
            this.currentPos = this.originPos;
        }

        receivedStates(states) {
            this.currentMode = states.mode; // Save updated selected sensor to be able to override the temperature without
            // loosing the eventually edited sensor name
            this.sensor = states.sensors[this.sensor.idx];
            this.originPos = this.sensor.targetTemperature;
            this.currentPos = this.sensor.targetTemperature;
            this.updateSliderAndSubtitle();
        }

        updateSliderAndSubtitle() {
            this.sliderActionView.setPosition(this.originPos);
            this.setSubtitle(this.formatValue(this.originPos));
        }

        viewWillAppear() {
            return Q(super.viewWillAppear(...arguments).then(res => {
                this.updateSliderAndSubtitle()
            }));
        }

        viewWillDisappear() {
            // Only send the value if the value has changed!
            if (this.currentPos !== this.originPos) {
                this.sendCommand(this.formatCommand(this.currentPos));
            }

            super.viewWillDisappear(...arguments);
        }

        /**
         * These views always have a custom title.
         */
        getTitle() {
            return _("steak.sensor-type.temp.target-temp");
        }

        /**
         * Such views only need to have one state ID.
         */
        getStateID() {
            return "sensors";
        }

        /**
         * To send the value to the Miniserver.
         * @param value
         */
        formatCommand(value) {
            return Commands.format(Commands.Steak.SET_SENSOR, this.sensor.idx, value, this.sensor.name);
        }

        /**
         * To display the current value.
         */
        formatValue(value) {
            return _('steak.target-temp', {
                temp: lxFormat("%d°", value)
            });
        }

        /**
         * This function returns the config of the slider, it is crucial to implement it!
         * @returns {{min, max, step, antiGhostTimer: boolean}}
         */
        getSliderConfig() {
            var cfg = super.getSliderConfig(...arguments);
            cfg.min = SandboxComponent.getTemperatureForUI(SteakControlEnums.TARGET_TEMP.MIN);
            cfg.max = SandboxComponent.getTemperatureForUI(SteakControlEnums.TARGET_TEMP.MAX);
            cfg.step = 1;
            return cfg;
        }

        _updateSubtitle(state) {
            var states, stateId;

            if (!state && state !== 0) {
                states = this.control.getStates();
                stateId = this.getStateID();
                state = states[stateId][this.sensor.idx].targetTemperature;
            }

            super._updateSubtitle(state);
        }

        _sendValue(pos) {
            this.setSubtitle(this.formatValue(pos));
            this.currentPos = pos;
            this.sliderActionView.setPosition(this.currentPos); // override, don't send the value right away, wait until the user exits the screen
        }

    };
});
