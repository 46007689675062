import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Plus(props) {
    return (
        <Svg isreact="true"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M13 21a1 1 0 11-2 0v-8H3a1 1 0 110-2h8V3a1 1 0 112 0v8h8a1 1 0 110 2h-8v8z"
            />
        </Svg>
    )
}

export default Plus
