'use strict';

define([], function () {
    return class LxControlNoteView extends GUI.View {
        //region Static
        static Template = class {
            //region Static
            static getTemplate() {
                return $("" + "<div class='lx-control-note-view'>" + "   <div class='control-note-view__text'></div>" + "   <div class='control-note-view__more'>" + _("show-more-of-text") + "</div>" + "</div>");
            } //endregion Static


        }; //endregion Static

        constructor(control) {
            super(LxControlNoteView.Template.getTemplate());
            Debug.GUI.ControlNoteView && console.log(this.name, "+constructor");
            this._hasMoreButton = false;
            this.control = control;
        }

        viewDidLoad() {
            Debug.GUI.ControlNoteView && console.log(this.name, "viewDidLoad");
            var res = super.viewDidLoad(...arguments);
            this.textView = this.element.find('.control-note-view__text');
            this.moreView = this.element.find('.control-note-view__more');
            this.moreButton = new GUI.LxButton(this, this.element[0], Color.BUTTON_GLOW);
            this.addToHandledSubviews(this.moreButton);
            this.moreButton.useChildsAsActiveParts("fill"); // register for structural changes as they might also concern the control note.

            this._structureChangesReg = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.StructureChanged, this._checkStructureChanges.bind(this));
            return res.then(this._prepareControlNote.bind(this));
        }

        viewWillAppear() {
            Debug.GUI.ControlNoteView && console.log(this.name, "viewWillAppear");
            var res = super.viewWillAppear(...arguments);

            this._handleIsTruncated();
            this._safeTruncateTimeout = setTimeout(() => this._handleIsTruncated(), 50); // After going into mood settings and back truncation doesn't work as expected anymore, so we need to recheck it with safe timeout, shorter so it's not as noticeable

            this.moreButton.onButtonTapped = this._showFullText.bind(this);
            return res;
        }

        viewWillDisappear() {
            Debug.GUI.ControlNoteView && console.log(this.name, "viewWillDisappear");
            this.moreButton.onButtonTapped = null;
            !!this._safeTruncateTimeout && clearTimeout(this._safeTruncateTimeout)
            return super.viewWillDisappear(...arguments);
        }

        destroy() {
            Debug.GUI.ControlNoteView && console.log(this.name, "destroy");
            !!this._safeTruncateTimeout && clearTimeout(this._safeTruncateTimeout); // additional safety, as the view might be destroyed before it was even shown
            NavigationComp.removeFromUIEvent(this._structureChangesReg);
            return super.destroy(...arguments);
        }

        _showFullText() {
            Debug.GUI.ControlNoteView && console.log(this.name, "_showFullText");
            NavigationComp.showControlNotes(this.control);
        }

        _prepareControlNote() {
            Debug.GUI.ControlNoteView && console.log(this.name, "_prepareControlNote");
            var prms = false;

            if (!this.control.hasControlNotes) {
                console.error(this.name, "Using a ControlNoteView on a control that has no control notes!");

                this._handleControlNoteLoaded("");
            } else if (this.control.controlNote) {
                this._handleControlNoteLoaded(this.control.controlNote);
            } else {
                prms = this._loadControlNote();
            }

            return prms;
        }

        _loadControlNote() {
            Debug.GUI.ControlNoteView && console.log(this.name, "_loadControlNote");
            var prms = CommunicationComponent.sendViaHTTP(Commands.format(Commands.CONTROL.HELP_TEXT, this.control.uuidAction), EncryptionType.REQUEST);
            return prms.then(this._handleControlNoteLoaded.bind(this), function (error) {
                console.error(this.name, "Could not load control note for control: ", this.control, error);
            }.bind(this));
        }

        _handleControlNoteLoaded(controlNote) {
            controlNote = controlNote.toString(); // Ensure its a string, If the ControlNote is eg. 123 it is interpreted as a number instead!

            Debug.GUI.ControlNoteView && console.log(this.name, "_handleControlNoteLoaded");
            this.control.controlNote = controlNote;
this.textView.html(controlNote.sanitizeHTML().insertHrefs().replace(Regex.NEW_LINE, "<br>")); // called just in case the view life-cycle is messed up and the content is loaded after being visible.
            
             this._handleIsTruncated()
                         // just to be safe, if the element takes longer with updating the height
            this._safeTruncateTimeout = setTimeout(() => this._handleIsTruncated(), 100);
        }

        _handleIsTruncated() {
            this.element.toggleClass("lx-control-note-view__more-shown", false); // add truncate test class temporarily, so it knows how much space there is at most

            this.element.toggleClass("lx-control-note-view__truncate-test", true);
            this._hasMoreButton = isTruncated(this.textView);
            this.element.toggleClass("lx-control-note-view__truncate-test", false);
            Debug.GUI.ControlNoteView && console.log(this.name, "_handleIsTruncated: " + this._hasMoreButton); // The button is inserted only if "more" exists, otherwise links that may be in the text aren't clickable

            this.moreButton.setEnabled(this._hasMoreButton);
            this.element.toggleClass("lx-control-note-view__more-shown", this._hasMoreButton);
        }

        _checkStructureChanges(event, changes) {
            var isAffected = changes.controls && !!changes.controls[this.control.uuidAction];
            Debug.GUI.ControlNoteView && console.log(this.name, "_checkStructureChanges: affected - " + isAffected);

            if (isAffected) {
                delete this.control.controlNote;

                this._loadControlNote();
            }
        }

    };
});
