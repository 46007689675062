'use strict';

define([], function () {
    return class ControlPerDeviceFavoritesController extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div />'));
            this.object = details.object;
            this.isControl = !!this.object.uuidAction;
            this.uuid = this.isControl ? this.object.uuidAction : this.object.uuid;
            this.deviceSpecificSettings = ActiveMSComponent.getDeviceFavoriteSettingsFor(this.uuid);
            this.sortByRatingChanged = false;
            this.favoritesChanged = false;
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.setUpComfortUI(true);
            this.setTitle(_('adopt-ui-settings'));
            this.setIcon(this.isControl ? this.object.getIcon() : this.object.image);
        }

        viewWillDisappear() {
            // Check if it makes any sense to save the settings
            if (this.sortByRatingChanged || this.favoritesChanged) {
                ActiveMSComponent.setDeviceFavoriteSettingsFor(this.uuid, this.deviceSpecificSettings);
            }

            super.viewWillDisappear(...arguments);
        }

        getAnimation() {
            return AnimationType.FADE;
        }

        // TableViewDataSource Methods
        styleForTableView(tableView) {
            return TableViewStyle.TRANSLUCENT;
        }

        reloadTable() {
            this.tableContent = [];
            var section = {
                headerTitle: _('device-favorites.title'),
                rows: [{
                    type: GUI.TableViewV2.CellType.RATING,
                    content: {
                        rating: this.deviceSpecificSettings.rating
                    },
                    didChangeRating: function didChangeRating(cell, section, row, tableView, value, dragging) {
                        if (!dragging) {
                            this.deviceSpecificSettings.rating = value;
                            this.sortByRatingChanged = true;
                        }
                    }.bind(this)
                }, {
                    type: GUI.TableViewV2.CellType.SWITCH,
                    content: {
                        title: _('favorites.show-as'),
                        active: this.deviceSpecificSettings.isFavorite
                    },
                    onSwitchChanged: function onSwitchChanged(value, section, row, tableView, cell) {
                        this.deviceSpecificSettings.isFavorite = value;
                        this.favoritesChanged = true;
                    }.bind(this)
                }],
                footerTitle: _('device-favorites.description.active.a') + " " + _('device-favorites.description.active.a.ignored')
            };
            this.tableContent.push(section);
            return super.reloadTable(...arguments);
        }

    };
});
