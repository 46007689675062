'use strict';

define(["AudioZoneControlEnums"], function (AudioZoneControlEnums) {
    class AudioZoneControlSettingsLibrary extends GUI.TableViewScreenV2 {
        //region Private
        ERROR = MediaEnum.NAS_ERROR; //endregion Private

        constructor(details) {
            super($('<div />'));
        }

        viewWillAppear() {
            var args = arguments;
            return this._loadData().then(() => {
                return this.reloadTable().then(() => {
                    return super.viewWillAppear(...args);
                });
            });
        }

        titleBarText() {
            return _("media.library");
        }

        reloadTable() {
            this.tableContent = [{
                rows: [{
                    content: {
                        leftIconSrc: Icon.TRANSLUCENT_ADD,
                        leftIconColor: window.Styles.colors.green,
                        title: _("media.library.add"),
                        clickable: true
                    },
                    type: GUI.TableViewV2.CellType.GENERAL,
                    action: function () {
                        this.ViewController.showState(AudioZoneControlEnums.ScreenState.SETTINGS_LIBRARY_ADD_OR_EDIT);
                    }.bind(this)
                }]
            }];

            if (this.data && this.data.length) {
                this.tableContent.push({
                    rows: this.data.map(function (libObj) {
                        return {
                            content: {
                                leftIconSrc: Icon.AudioZone.NEW.PLAYLIST_NAS,
                                leftIconColor: Color.TEXT_SECONDARY_B,
                                title: libObj.name,
                                subtitle: libObj.server + "/" + libObj.folder,
                                clickable: true,
                                rightIconSrc: Icon.SETTINGS_SMALL,
                                rightIconColor: libObj.configerror ? window.Styles.colors.red : null
                            },
                            action: function action() {
                                this.ViewController.showState(AudioZoneControlEnums.ScreenState.SETTINGS_LIBRARY_OVERVIEW, null, {
                                    libObj: libObj
                                });
                            }.bind(this)
                        };
                    }.bind(this))
                });
            }

            return super.reloadTable(...arguments);
        }

        _loadData() {
            return MediaServerComp.sendMediaServerCommand(MediaEnum.Commands.SETTINGS.LIBRARY.LIST).then(function (data) {
                this.data = data.data;
                return this.data;
            }.bind(this));
        }

    }

    Controls.AudioZoneControl.AudioZoneControlSettingsLibrary = AudioZoneControlSettingsLibrary;
});
