'use strict';

define("PulseAtControl", [
    "Control",
    "PulseAtControlEnums",
    "IconLib"
], function (
    Control,
    CtrlEnums,
    {
        default: Icons
    }
) {
    return class PulseAtControl extends Control {
        constructor() {
            super(...arguments);
        }

        getButton0() {
            return {
                iconSrc: Icon.Pushbutton.PUSHBUTTON2,
                reactIcon: Icons.Circle,
                command: {
                    tap: Commands.PUSHBUTTON.PULSE
                }
            };
        }

        getControlIcon() {
            return Icon.PulseAt.PULSE_AT;
        }

        getNameForType(type) {
            var typeName;

            switch (type) {
                case CtrlEnums.TYPE.TIME:
                    typeName = _("controls.pulseAt.pulseType.time");
                    break;

                case CtrlEnums.TYPE.YEAR:
                    typeName = _("controls.pulseAt.pulseType.year");
                    break;

                case CtrlEnums.TYPE.MONTH:
                    typeName = _("controls.pulseAt.pulseType.month");
                    break;

                case CtrlEnums.TYPE.DAY:
                    typeName = _("controls.pulseAt.pulseType.day");
                    break;

                case CtrlEnums.TYPE.HOUR:
                    typeName = _("controls.pulseAt.pulseType.hour");
                    break;

                case CtrlEnums.TYPE.MINUTE:
                    typeName = _("controls.pulseAt.pulseType.minute");
                    break;

                case CtrlEnums.TYPE.SECOND:
                    typeName = _("controls.pulseAt.pulseType.second");
                    break;

                case CtrlEnums.TYPE.START:
                    typeName = _("controls.pulseAt.pulseType.start");
                    break;

                case CtrlEnums.TYPE.NIGHTFALL:
                    typeName = _("controls.pulseAt.pulseType.nightfall");
                    break;

                case CtrlEnums.TYPE.DAYBREAK:
                    typeName = _("controls.pulseAt.pulseType.daybreak");
                    break;

                case CtrlEnums.TYPE.SUNSET:
                    typeName = _("controls.pulseAt.pulseType.sunset");
                    break;

                case CtrlEnums.TYPE.SUNRISE:
                    typeName = _("controls.pulseAt.pulseType.sunrise");
                    break;

                default:
                    typeName = "";
            }

            return typeName;
        }

    };
});
