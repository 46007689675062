'use strict';
/**
 *
 */

window.GUI = function (GUI) {
    class VolumeControlCell extends GUI.TableViewV2.Cells.BaseCell {
        constructor() {
            super(...arguments);
            this.element.addClass("volume-control-cell");
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.contentPlaceholder.append(GUI.TableViewV2.Cells.VolumeControlCell.Template.getCellContent(this.content.title));
            this.volumeControl = new Controls.AudioZoneControl.AudioZoneControlVolumeControl(this.content.control, null, this._hideVolumeControl.bind(this));
            this.volumeControl.audioControlUsedDelegate = this._disappearTimerResetted.bind(this);
            this.volumeControl.getElement().addClass('content__volume-control');
            this.appendSubview(this.volumeControl, this.contentPlaceholder);

            if (!!this.content.singleControl) {
                this.element.addClass('volume-control-cell--single-control');
            }
        }

        clickableAllowed() {
            return false;
        }

        // ------------------------------------------------------------------------------------------------
        //       Private Method
        // ------------------------------------------------------------------------------------------------
        _hideVolumeControl() {
            this.delegate.hideVolumeControl && this.delegate.hideVolumeControl(this.sectionIdx, this.rowIdx, this.tableView);
        }

        _disappearTimerResetted(isDragging) {
            this.delegate.volumeControlUsed && this.delegate.volumeControlUsed(this.sectionIdx, this.rowIdx, this.tableView, isDragging);
        }

    }

    GUI.TableViewV2.Cells.VolumeControlCell = VolumeControlCell;

    GUI.TableViewV2.Cells.VolumeControlCell.Template = function () {
        var getCellContent = function getCellContent(title) {
            return '<div class="content__title">' + title + '</div>';
        };

        return {
            getCellContent: getCellContent
        };
    }();

    GUI.TableViewV2.CellType.Special.VOLUME_CONTROL = "VolumeControlCell";
    return GUI;
}(window.GUI || {});
