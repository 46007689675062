'use strict';

define("IRoomControllerV2021Control", [
    "IRoomControllerV2Control",
    'IRoomControllerV2021ControlEnums',
    'IRoomControllerV2ControlEnums',
    'IconLib',
    './IrcFixedSetpointUtil'
], function (
    IRoomControllerV2Control,
    CtrlEnums,
    OldCtrlEnums,
    {
        default: Icons
    },
    {
        Util: FixedSetpointUtil
    }
) {
    return class IRoomControllerV2021Control extends IRoomControllerV2Control {
        constructor() {
            super(...arguments);
        }

        /**
         * If true, the IRC does not target a range between comfort heating / cooling, but only one target temperature
         * Additionally it now has a comfortTolerance setting "around" that target temp.
         * @returns {boolean}
         */
        get singleComfortTemp() {
            if (this.details && this.details.hasOwnProperty("singleComfortTemperature")) {
                return !!this.details.singleComfortTemperature;
            }
            return false;
        }

        isInputConnected(input) {
            var inputFlags = this.details && this.details.connectedInputs ? this.details.connectedInputs : 0;
            return hasBit(inputFlags, input);
        }

        hasCapability(capability) {
            var capFlags = this.details && this.details.possibleCapabilities ? this.details.possibleCapabilities : 0;
            return hasBit(capFlags, capability);
        }

        get heatingCapability() {
            return this.hasHeatingCapability();
        }

        hasHeatingCapability() {
            return this.hasCapability(CtrlEnums.Capability.HEATING) || this.hasCapability(CtrlEnums.Capability.PASSIVE_HEAT);
        }

        get coolingCapability() {
            return this.hasCoolingCapability();
        }

        hasCoolingCapability() {
            return this.hasCapability(CtrlEnums.Capability.COOLING) || this.hasCapability(CtrlEnums.Capability.PASSIVE_COOL);
        }

        hasHeatCoolCapability() {
            return this.hasCoolingCapability() && this.hasHeatingCapability();
        }

        get linkedAcControls() {
            let linkedCtrls = [],
                ctrl;
            if (this.details.hasOwnProperty("linkedAcControls")) {
                this.details.linkedAcControls.forEach(uuid => {
                    ctrl = ActiveMSComponent.getStructureManager().getControlByUUID(uuid);
                    ctrl && linkedCtrls.pushObject(ctrl);
                })
            }
            return linkedCtrls;
        }

        _getCurrentDescription() {
            var description = super._getCurrentDescription(...arguments),
                states = this.getStates(),
                currentEntry,
                daytimer = null;

            if (!states.hasCurrentOverrideEntry) {
                daytimer = this.subControls[Object.keys(this.subControls)[0]];
                currentEntry = daytimer.getCurrentEntry();

                if (currentEntry && currentEntry.needActivate) {
                    description = _("controls.ircv2021.comfort-when-presence-detected");
                }
            }

            return description;
        }

        getNameOfIRCTempMode(modeId, allowOffset) {
            if (FixedSetpointUtil.isFixedSetpointValue(modeId)) {
                return this._getFixedSetpointName(modeId);
            } else {
                return super.getNameOfIRCTempMode(...arguments);
            }
        }

        _getFixedSetpointName(modeId) {
            if (FixedSetpointUtil.isFixedSetpointValue(modeId)) {
                var info = FixedSetpointUtil.extractFromValue(modeId),
                    result;

                if (info.heat && !info.cool) {
                    result = _("controls.ircv2021.mode-fixed-setpoint-only-heating");
                } else if (info.cool && !info.heat) {
                    result = _("controls.ircv2021.mode-fixed-setpoint-only-cooling")
                } else {
                    result = _('controls.ircv2021.mode-fixed-setpoint')
                }

                return result;
            }
            return null;
        }

        getTempModeDesc(modeId, allowPreparation) {
            var desc,
                states = this.getStates(),
                timerMode;
            modeId = typeof modeId === "number" ? modeId : states.activeMode;

            if (FixedSetpointUtil.isFixedSetpointValue(modeId)) {
                return this._getFixedSetpointName(modeId);
            }

            timerMode = this.details.timerModes.find(function (timerMode) {
                return timerMode.id === modeId;
            }); // check if an override is active & if it has to be minded at all

            if (allowPreparation && states.hasCurrentOverrideEntry && (states.isHeatingUp || states.isCoolingDown)) {
                if (states.isHeatingUp) {
                    desc = _("controls.ircv2.heat-up-phase");
                } else {
                    desc = _("controls.ircv2.cool-down-phase");
                }
            } else if (timerMode && timerMode.description) {
                desc = timerMode.description;
            } else {
                desc = _('controls.ircv2021.mode-fixed-setpoint');
            }

            return desc;
        }

        /**
         * Returns the color for the given mode
         * @param ignoreTempOffset
         * @param modeId
         * @param needActivate
         */
        getColorOfIRCTempMode(modeId, ignoreTempOffset, needActivate) {
            var color,
                states = this.getStates();

            if (modeId === undefined) {
                modeId = states.activeMode;
            }
            if (FixedSetpointUtil.isFixedSetpointValue(modeId)) {
                return "#FFCC00";
            }

            switch (modeId) {
                case OldCtrlEnums.Mode.ECO:
                case OldCtrlEnums.Mode.MANUAL:
                    color = '#90a4ae';
                    break;

                case OldCtrlEnums.Mode.ECO_PLUS:
                    color = Color.OAK;
                    break;

                case OldCtrlEnums.Mode.COMFORT:
                    if (needActivate) {
                        color = '#40602f';
                        /* Input markus: Remove as we don't show it in the new details screen either: BG-I8872
                        } else if (states.excessEnergyHeat && !ignoreTempOffset) {
                            color = window.Styles.colors.orange;
                        } else if (states.excessEnergyCool && !ignoreTempOffset) {
                            color = window.Styles.colors.blue;*/
                    } else {
                        color = window.Styles.colors.stateActive;
                    }

                    break;

                default:
                    break;
            }

            return color;
        }

        getTempRangeText(modeId) {
            var result,
                states = this.getStates();

            if (FixedSetpointUtil.isFixedSetpointValue(modeId)) {
                var setpointInfo = FixedSetpointUtil.extractFromValue(modeId);
                return this.formatTemp(setpointInfo.target)
            }

            switch (modeId) {
                case OldCtrlEnums.Mode.COMFORT:
                    if (this.singleComfortTemp) {
                        result = this.formatTemp(states.comfortTempMin);
                    } else {
                        result = this.formatHcTemp(states.comfortTempMin, states.comfortTempMax);
                    }
                    break;

                case OldCtrlEnums.Mode.ECO:
                    result = this.formatHcTemp(states.ecoTempMin, states.ecoTempMax);
                    break;

                case OldCtrlEnums.Mode.ECO_PLUS:
                    result = this.formatHcTemp(states.frostProtectTemperature, states.heatProtectTemperature);
                    break;

                default:
                    result = this.formatHcTemp(states.currMinTemp, states.currMaxTemp);
                    break;
            }

            return result;
        }

        formatTemp(temp) {
            return lxFormat(this.getFormatForTemp(), temp, true);
        }

        formatHcTemp(tempH, tempC) {
            var states = this.getStates(),
                format = this.getFormatForTemp(),
                canHeat = states.canHeat,
                canCool = states.canCool,
                texts = [];
            canHeat && texts.push("H: " + lxFormat(format, tempH));
            canCool && texts.push("C: " + lxFormat(format, tempC));
            return texts.join(", ");
        }

        getButton0(states) {
            if (states.isOff) {
                return null;
            } else if (this._isStopableTimer(states)) {
                return this._getStopTimerButton(states);
            } else if (this._isInactive(states)) {
                return this._getPresenceButton(states);
            } else {
                return this._getAdoptTempButton(states);
            }

            return null;
        }

        getButton1(states) {
            return null;
        }

        getTempStringOfMode(modeId, allowOffset, getLimitValues) {
            var states = this.getStates(),
                tempString,
                format = this.details.format;


            if (FixedSetpointUtil.isFixedSetpointValue(modeId)) {
                var setpointInfo = FixedSetpointUtil.extractFromValue(modeId);
                return lxFormat(format, setpointInfo.tempTarget);
            }

            switch (modeId) {
                case OldCtrlEnums.Mode.ECO:
                    tempString = lxFormat(format, states.ecoTempMin) + " … " + lxFormat(format, states.ecoTempMax);
                    break;

                case OldCtrlEnums.Mode.ECO_PLUS:
                    tempString = lxFormat(format, states.frostProtectTemperature) + " … " + lxFormat(format, states.heatProtectTemperature);
                    break;

                default:
                    if (getLimitValues) {
                        tempString = lxFormat(format, states.comfortTempMin) + " … " + lxFormat(format, states.comfortTempMax);
                    } else {
                        if (allowOffset) {
                            tempString = lxFormat(format, states.tempTarget);
                        } else {
                            tempString = lxFormat(format, states.comfortTemperature);
                        }
                    }

            }

            return tempString;
        }

        _isStopableTimer(states) {
            var isStopable = false;

            if (states.hasCurrentOverrideEntry && states.overrideEntries[0].isTimer) {
                isStopable = true;
            }

            return isStopable;
        }

        _isInactive(states) {
            var isInactive;

            switch (states.activeMode) {
                case OldCtrlEnums.Mode.COMFORT:
                case OldCtrlEnums.Mode.MANUAL:
                    isInactive = false;
                    break;

                default:
                    isInactive = true;
                    break;
            }

            return isInactive;
        }

        _getPresenceButton(states) {
            return {
                iconSrc: Icon.IRCV2021.Card.PRESENCE,
                reactIcon: Icons.Presence,
                command: Commands.format(Commands.I_ROOM_V2_CONTROLLER.OVERRIDE.START, OldCtrlEnums.Mode.COMFORT)
            };
        }

        _getStopTimerButton(states) {
            return {
                iconSrc: Icon.IRCV2021.Card.TIMER_STOP,
                reactIcon: Icons.TimerStop,
                command: Commands.format(Commands.I_ROOM_V2_CONTROLLER.OVERRIDE.STOP)
            };
        }

        _getAdoptTempButton(states) {
            return {
                iconSrc: Icon.IRCV2021.Card.TIMER_START,
                reactIcon: Icons.Timer,
                command: {
                    tap: this._showTempAdoptionScreen.bind(this, states)
                }
            };
        }

        _showTempAdoptionScreen(states) {
            NavigationComp.showState(CtrlEnums.ScreenState.TEMP_ADOPTION, {
                control: this,
                currentManualTemp: states.tempTarget
            });
        }

    };
});
