import globalStyles from "GlobalStyles";
import {
    useControl,
    DateViewTypeSelector,
    AmbientContext,
    useBorderColor,
    DateViewType,
    useLiveState,
    LxReactLinkedControlsList,
    ScrollingCards,
    useNodeValuesTyped,
    LxControlNoteView,
    LxControlContextProvider,
} from "LxComponents";
import { View, ScrollView } from "react-native";
import { useState, useEffect, useRef } from "react";
import { EfmUtilities, EfmViewType, EfmNodeFactory, EfmNodeType } from "../efmUtilities";

import ProductionGraph from "./graphs/ProductionGraph";
import ConsumptionGraph from "./graphs/ConsumptionGraph";
import GridGraph from "./graphs/GridGraph";
import StorageGraph from "./graphs/StorageGraph";
import { useMemo, useContext } from "react";

import NodeSpider from "../../../react-comps/NodeSpider";
import { getTotalWhileIgnoringTinyNumbers } from "./util/getSpecificValue";
import StorageLevelGraph from "./graphs/StorageLevelGraph";

import useNodesValueUpdate from "./useNodesValueUpdate";
import useAvailableNodeTypes from "../../../react-comps/customHooks/useAvailableNodeTypes";
import { useIsFocused } from "@react-navigation/native";

import sanitizeValue from "./util/sanitizeValue";
import CancelablePromise from 'cancelable-promise';

import useMoneySavedStats from "./hooks/useMoneySavedStats";

const createDummySpiderNodes = (nodes) => {
    return (nodes || []).map(realNode => {
        return createDummySpiderNode(realNode.title || realNode.name, realNode.isHeading, realNode.icon, realNode.hasChildren || realNode.hasChildNodes);
    });
}

const createDummySpiderNode = (name = '  ', isHeading, icon, hasChildren) => {
    return {
        isHeading: isHeading === true,
        name: name,
        icon: icon,
        hasChildren: hasChildren === true,
        showFlowAnimation: false,
        value: 0,
        flow: 0,
        positiveDirection: 0,
        format: '',
    }
}

const SeparationLine = () => {
    const borderColor = useBorderColor();

    return <View style={
        {
            marginLeft: '-50%',
            marginBottom: 9,
            marginTop: 6,
            width: '200%',
            height: 1,
            backgroundColor: borderColor
        }} />
}

// region node type checking

/**
 * Recursively checks the nodes provided and updates the foundTypeMap. Stops as soon as all types are found (i.e. true
 * @param foundTypeMap
 * @param nodes
 * @returns {boolean|*}
 * @private
 */
const _recGetNodeTypes = (foundTypeMap, nodes) => {
    if (!Array.isArray(nodes)) { return false; }

    // helper fn to check if all have been found already
    const allFoundCheck = () => { return Object.values(foundTypeMap).every(foundFlag => foundFlag); };

    return nodes.some(node => {
        foundTypeMap[node.nodeType] = true;

        if (allFoundCheck()) {
            return true;
        } else if (node.hasChildNodes) {
            return _recGetNodeTypes(foundTypeMap, node.getChildNodes());
        } else {
            return false;
        }
    });
}

/**
 * Will iterate over the nodes provided and returns a map where all possible node types are listed, but only found ones are true.
 * @param nodes array of nodes to check
 * @returns {{Grid: boolean, Storage: boolean, Load: boolean, Group: boolean, Production: boolean}}
 */
const getFoundNodeTypesMap = (nodes) => {
    // create map with all types set to false
    let foundTypeMap = {};
    Object.values(EfmNodeType).forEach(typeId => { foundTypeMap[typeId] = false; });

    // iterate over nodes (& recursively over their childnodes)
    _recGetNodeTypes(foundTypeMap, nodes);

    return foundTypeMap;
}
// endregion

const zommedGraphTitleMapping = {
    'production': _("efm.production-title"),
    'consumption': _("efm.consumption-title"),
    'grid': _("efm.grid-title"),
    'storage': _("efm.storage-title"),
    'storageLevel': _("efm.storage-level-title"),
}

export default function EnergyFlowMonitorControlContent({ navigation, route, updateNavigation, updateDetailsForExpand }) {
    const [zoomedGraph, setZoomedGraph] = useState(route.params.zoomedGraph || 'none')
    const [spiderNodes, setSpiderNodes] = useState([]);
    const [hasErrors, setHasErrors] = useState(false);
    const [viewInfo, setViewInfo] = useState(route.params.fixedViewinfo ? route.params.fixedViewinfo : {
        ts: ActiveMSComponent.getMiniserverUnixUtcTimestamp(),
        manuallySelectedTs: ActiveMSComponent.getMiniserverUnixUtcTimestamp(),
        vt: EfmViewType.Actual,
    });
    const { isAmbientMode } = useContext(AmbientContext)

    // BG-I22094 Andi: Used to prevent race condition in stats loading. See further down.
    const viewInfoRef = useRef(Object.assign({}, viewInfo));

    /**
     * BG-I22094 Andi:
     * For some reason, the stats retrieval fails when calling with viewType "lifetime"
     * and with a timestamp where no data is available yet.
     * So when "lifetime" is selected, always use the current ms server time.
     */
    const viewInfoForStatsCalls = useMemo(() => {
        let usedTs = viewInfo.ts;
        if (viewInfo.vt === EfmViewType.Lifetime) {
            usedTs = ActiveMSComponent.getMiniserverUnixUtcTimestamp();
        }
        const retViewInfo = {
            ts: usedTs,
            manuallySelectedTs: viewInfo.manuallySelectedTs,
            vt: viewInfo.vt,
        };
        viewInfoRef.current = retViewInfo;
        return retViewInfo;
    }, [viewInfo.ts, viewInfo.manuallySelectedTs, viewInfo.vt]);

    const isFocused = useIsFocused();

    const updateZoomedGraph = (newVal) => {
        if (newVal !== 'none' && isAmbientMode) {
            // when clicking zoom on a graph in ambient mode, it should go to full screen asap.
            updateDetailsForExpand(
                { ...route.params, fixedViewinfo: { ...viewInfo } },
                { zoomedGraph: newVal, expandedIntoGraph: true });
        } else {
            updateDetailsForExpand({ ...route.params, zoomedGraph: newVal, fixedViewinfo: { ...viewInfo } });
            setZoomedGraph(newVal);
        }
    };

    // keep track if component is mounted
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, []);

    useEffect(() => {
        return navigation.addListener('beforeRemove', (e) => {
            // if the EFM has been opened by expanding a graph from ambient mode, it should be closed when when clicking back.
            if (zoomedGraph !== 'none' && mounted && !route.params.expandedIntoGraph) {
                updateZoomedGraph('none');
                e.preventDefault();
            }
        });
    }, [navigation, zoomedGraph, mounted, route.params.expandedIntoGraph]);

    const [{
        Grid: gridStats,
        Production: prodStats,
        Storage: storStats,
        Load: loadStats,
    }, setNodeStats] = useState({});


    const [lastSpiderNodesRenderInfo, setLastSpiderNodesRenderInfo] = useState({ lastCount: 0, lastVt: viewInfo.vt });
    const [lastGraphRenderInfo, setLastGraphRenderInfo] = useState({ lastVt: viewInfo.vt, lastManuallySelectedTs: viewInfo.manuallySelectedTs });

    /**
     * Helper method to also inform the controlContent wrapper to pass the proper info to the zoomed EFM.
     * @param newVal
     */
    const updateViewInfo = (newVal) => {
        let newViewInfo = { ...newVal };
        setViewInfo(newViewInfo);
        updateDetailsForExpand({ ...route.params, zoomedGraph: zoomedGraph, fixedViewinfo: newViewInfo });
    }

    const control = useControl(route.params.controlUUID);
    const { nodes, topLevel, fixedTitle } = useMemo(() => {
        if (route.params.fixedNodes) {
            // search for a parent by searching the children of the control nodes
            let parent = null;

            // identify the parent node based on the first childs parent uuid property.
            if (route.params.fixedNodes.length > 0 && route.params.fixedNodes[0].parentNodeUuid) {
                parent = control.getNode(route.params.fixedNodes[0].parentNodeUuid);
                parent.isHeading = true; // okay to modify, returns a newly created instance.
            }

            return { nodes: parent ? [parent, ...route.params.fixedNodes] : route.params.fixedNodes, fixedTitle: route.params.fixedTitle, topLevel: false };
        } else {
            const nodes = control.getNodes();
            setSpiderNodes(createDummySpiderNodes(nodes))
            return { nodes, topLevel: true };
        }
    }, [control]);

    const availableNodeTypes = useAvailableNodeTypes(nodes);
    const gridNodes = useMemo(() => {
        return nodes.filter(node => node.nodeType === EfmNodeType.Grid);
    }, [nodes]);

    const moneySavedFromStatsUnchecked = useMoneySavedStats(control.uuidAction, viewInfo.start, viewInfo.end);

    useEffect(() => {
        let options = {}
        if (zoomedGraph !== "none" || route.params.fixedNodes) {
            options.showBack = true;
        }
        options.title = zommedGraphTitleMapping[zoomedGraph] || fixedTitle || control.getName();
        updateNavigation(options);
    }, [zoomedGraph, fixedTitle, route.params.fixedNodes]);

    // when showing groups that have a specific header node, ensure it is used to represent the data shown within it
    // (e.g. the statistics below including the value.
    const nodesForStatValue = useMemo(() => {
        const headerNodes = nodes.filter(node => !!node.isHeading);
        if (headerNodes.length > 0) {
            return headerNodes;
        }
        return nodes;
    }, [nodes])

    const { viewTypeForTypedNodeValues, isLivePast } = useMemo(() => {
        if (
            viewInfo.vt === EfmViewType.Actual &&
            moment(viewInfoForStatsCalls.ts * 1000).isBefore(ActiveMSComponent.getMiniserverUnixUtcTimestamp() * 1000, 'day')
        ) {
            return { viewTypeForTypedNodeValues: EfmViewType.Day, isLivePast: true };
        }
        return { viewTypeForTypedNodeValues: viewInfo.vt, isLivePast: false };
    }, [viewInfo.vt, viewInfoForStatsCalls.ts]);
    
    const typedNodeValuesMinViewtypeDay = useNodeValuesTyped(nodesForStatValue, viewInfo.vt === EfmViewType.Actual ? EfmViewType.Day : viewInfo.vt, viewInfoForStatsCalls.ts)
    const typedNodeValues = useNodeValuesTyped(nodesForStatValue, viewTypeForTypedNodeValues, viewInfoForStatsCalls.ts);

    const nodeStates = useNodesValueUpdate(nodes, viewInfo.vt, viewInfoForStatsCalls.ts);

    const liveState = useLiveState(route.params.controlUUID, [
        "productionPower", "gridPower", "storagePower", "consumptionPower",
        "priceImport", "priceExport", "co2Factor"
    ]);

    const timeRange = useMemo(() => {
        return EfmUtilities.getStatisticRange(control, viewInfo.vt, viewInfo.ts);
    }, [control, viewInfo.vt, viewInfo.ts]);

    /**
     * For the root level - if a grid meter is present, consumption statistics don't need to be downloaded.
     * This is allows to greatly reduce the number of statistic downloads, especially in large scale installations such
     * as the campus. On the root level, all statistics can be computed based on the grid - and if available - the storage
     * & production meters.
     * @type {string[]}
     */
    const ignoredStatOfNodeTypes = useMemo(() => {
        let ignoreCons = false;
        if (topLevel) {
            ignoreCons = !!getFoundNodeTypesMap(nodesForStatValue)[EfmNodeType.Grid];
        }
        return ignoreCons ? [EfmNodeType.Consumption] : [];
    }, [nodesForStatValue])

    /* #region  Get Node Stats */

    // array of promises to keep track on which one to cancel in the cleanup of the useEffect
    const nodeStatsPrmsArr = useRef([]); 

    useEffect(() => {
        if (!isFocused) {
            // Not focused, stats updated avoided!
            return;
        }
        if (zoomedGraph === "storageLevel" && !storageLevelStatSupported) {
            updateZoomedGraph('none');
        }

        if (!Array.isArray(nodes) || nodes.length === 0) {
            setNodeStats({});
            return;
        }
        if (viewInfo.vt !== lastGraphRenderInfo.lastVt || viewInfo.manuallySelectedTs !== lastGraphRenderInfo.lastManuallySelectedTs) {
            setNodeStats({});
        }
        const localVt = viewInfo.vt;
        const localTs = viewInfoForStatsCalls.ts;
        const promiseObject = EfmNodeFactory.getStatisticsForAllNodeTypes(nodesForStatValue, viewInfo.vt, viewInfoForStatsCalls.ts, ignoredStatOfNodeTypes);
        let promiseDayObject = {};
        if (viewInfo.vt === EfmViewType.Actual) {
            promiseDayObject = EfmNodeFactory.getStatisticsForAllNodeTypes(nodesForStatValue, EfmViewType.Day, viewInfo.ts, ignoredStatOfNodeTypes);
        }
        nodeStatsPrmsArr.current.push(CancelablePromise.allSettled([
            ...Object.values(promiseObject),
            ...Object.values(promiseDayObject)
        ]));
        nodeStatsPrmsArr.current[nodeStatsPrmsArr.current.length - 1].then(settled => {
            // filter out Nodes if we could not retrieve data
            const rawValues = settled
                .filter(({ status }) => status === "fulfilled")
                .map(({ value }) => value);

            const values = {...rawValues};
            const statsObj = {};
            const mainPromiseObjectKeys = Object.keys(promiseObject);
            mainPromiseObjectKeys.forEach((k, idx) => statsObj[k] = values[idx]);
            Object.keys(promiseDayObject).forEach((k, idx) => {
                if (statsObj[k]) {
                    statsObj[k].dayStats = values[idx + mainPromiseObjectKeys.length];
                }
            });
            Debug.Control.Efm.Stat && console.log("EfmStats", "all stats received, setting new stats Obj: ", statsObj);
            /**
             * BG-I22094 Andi:
             * Only actually update the data if we are still in the same viewInfo
             * as when the stats request was started.
             * Prevents wrong display caused be race condition.
             * E.g.:
             * Month view called, starts stats request.
             * Lifetime view called, starts stats request.
             * Lifetime stats request finishes, lifetime starts briefly shown
             * Month stats request finishes, now lifetime view shows month stats
             */
            if (viewInfoRef.current.vt === localVt && viewInfoRef.current.ts === localTs) {
                setNodeStats(statsObj);
                setLastGraphRenderInfo({ lastVt: viewInfo.vt, lastManuallySelectedTs: viewInfo.manuallySelectedTs })
            }
        }, (err) => {
            // failed
        });
        
        return () => {
                nodeStatsPrmsArr.current.slice(0, -1).forEach((prm, index) => {
                    prm.cancel();
                    nodeStatsPrmsArr.current.splice(index, 1);
                });
            }
        // }
    }, [nodes, viewInfo.vt, viewInfo.ts, viewInfoForStatsCalls.ts, isFocused]);
    /* #endregion */

    // reset the navigation to root level when node states is emtpy
    // which happens when resume at last position is set while viewing child nodes
    useEffect(() => {
        if (Object.keys(nodeStates).length === 0) {
            if (!route.params.didReset) { // avoids looping if e.g. a meter is shown atop of the EFM.
                navigation.reset({
                    index: 0,
                    routes: [{ name: 'EnergyFlowMonitorControlContent', params: { didReset: true } }],
                });
            }
        }
    }, [nodeStates, route.params.didReset])

    /* #region  Spider Nodes */
    const startSpiderNodeUpdate = () => {
        let passedVt = viewInfo.vt;
        let mHasErrors = false;
        if (
            viewInfo.vt === EfmViewType.Actual &&
            moment(viewInfo.ts * 1000).isBefore(ActiveMSComponent.getMiniserverUnixUtcTimestamp() * 1000, 'day')
        ) {
            passedVt = EfmViewType.Day;
        }
        let availableNodes = nodes.filter(node => node.isAvailable),
            prmsToSettle = availableNodes.map(node => {
                let valuePrm;
                const nodeState = nodeStates[node._uuid]

                if (nodeState?.error) {
                    valuePrm = CancelablePromise.resolve({});
                } else if (nodeState?.stateValueMap && Object.keys(nodeState.stateValueMap).length > 0 && nodeState.stateValueMap[passedVt]) {
                    valuePrm = CancelablePromise.resolve(nodeState.stateValueMap);
                } else {
                    valuePrm = node.getNodeValue(passedVt, viewInfo.ts);
                }

                return valuePrm.then(value => {
                    if (Object.keys(value).length > 0) {
                        let flow = 0;
                        if (node.isOfType(EfmNodeType.Grid) || node.isOfType(EfmNodeType.Production) || node.isOfType(EfmNodeType.Storage) || node.isOfType(EfmNodeType.Consumption)) {
                            flow = passedVt === EfmViewType.Actual ? value.actual : value.total - (value.totalNeg ? value.totalNeg : 0);
                        }
                        if (node.isOfType(EfmNodeType.Consumption)) {
                            flow *= -1; // For Consumption, positive values flow towards the node
                        }
                        if (typeof flow !== 'number' || Number.isNaN(flow) || !Number.isFinite(flow) || Math.abs(flow) < 0.0005) {
                            flow = 0;
                        }

                        let positiveDirection = -1; // e.g. charging storage, feeding grid
                        if (node.isOfType(EfmNodeType.Consumption) || node.isOfType(EfmNodeType.Production)) {
                            positiveDirection = 1;
                        }

                        const contextMenu = [];
                        if (node.control) {
                            contextMenu.push({
                                label: _("efm.go-to-control", { controlName: '"' + node.control.name + '"' }),
                                onClick: () => navigateToControl(node.control),
                            });
                            try {
                                const room = node.control.getRoom();
                                if (room) {
                                    contextMenu.push({
                                        label: _("efm.go-to-room", { roomName: '"' + room.name + '"' }),
                                        onClick: () => NavigationComp.showGroupContent(GroupTypes.ROOM, room.uuid, node.control.uuidAction)
                                    });
                                }
                            } catch { }
                            try {
                                const category = node.control.getCategory();
                                if (category) {
                                    contextMenu.push({
                                        label: _("efm.go-to-category", { categoryName: '"' + category.name + '"' }),
                                        onClick: () => NavigationComp.showGroupContent(GroupTypes.CATEGORY, category.uuid, node.control.uuidAction)
                                    });
                                }
                            } catch { }
                        }

                        const valueToPass = getTotalWhileIgnoringTinyNumbers({ total: passedVt === EfmViewType.Actual ? value.actual : value.total });
                        let secondValue = getTotalWhileIgnoringTinyNumbers({ total: passedVt === EfmViewType.Actual || !value.totalNeg ? undefined : -value.totalNeg });
                        let secondFormat;
                        if (node.isOfType(EfmNodeType.Storage) && passedVt === EfmViewType.Actual) {
                            if (node.nodeType === EfmNodeType.Group) {
                                secondValue = undefined;
                            } else {
                                secondValue = getTotalWhileIgnoringTinyNumbers({ total: value.storage });
                                secondFormat = control.storageFormat;
                            }
                        }

                        const baseArrowCondition = passedVt !== EfmViewType.Actual && (node.meetsTypeRequirements([EfmNodeType.Storage, EfmNodeType.Grid, EfmNodeType.Consumption]) || node.groupConsistingOf([EfmNodeType.Storage, EfmNodeType.Production])) && node.isBidirectional;
                        const isGridOrStorageNode = node.meetsTypeRequirements([EfmNodeType.Grid, EfmNodeType.Storage]);
                        const isConsumptionNode = node.isOfType(EfmNodeType.Consumption);
                        const topArrow = baseArrowCondition && valueToPass !== 0 ? {
                            up: (isGridOrStorageNode && valueToPass < 0 || isConsumptionNode && valueToPass > 0) || (!isGridOrStorageNode && !isConsumptionNode && valueToPass < 0),
                            col: (isGridOrStorageNode && valueToPass < 0 || isConsumptionNode && valueToPass < 0) || (!isGridOrStorageNode && !isConsumptionNode && valueToPass < 0) ? globalStyles.colors.green : globalStyles.colors.orange,
                        } : false;
                        const bottomArrow = baseArrowCondition && secondValue !== 0 ? {
                            up: (isGridOrStorageNode && secondValue < 0 || isConsumptionNode && secondValue > 0) || (!isGridOrStorageNode && !isConsumptionNode && secondValue < 0),
                            col: (isGridOrStorageNode && secondValue < 0 || isConsumptionNode && secondValue < 0) || (!isGridOrStorageNode && !isConsumptionNode && secondValue < 0) ? globalStyles.colors.green : globalStyles.colors.orange,
                        } : false;

                        let onClick = undefined;

                        if (node.hasChildNodes && !node.isHeading) {
                            // Make node clickable if a group view is available but not already displayed
                            onClick = () => {
                                navigation.push('EnergyFlowMonitorControlContent', {
                                    ...route.params,
                                    fixedViewinfo: viewInfo,
                                    fixedNodes: node._nodes,
                                    fixedTitle: node.title,
                                    parentSetViewInfo: updateViewInfo,
                                })
                            }
                        } else if (node.control) {
                            // Make node clickable if a detailed view is available
                            onClick = () => {
                                navigateToControl(node.control);
                            }
                        }

                        const getRoomNameForNode = (node) => {
                            if (node._control) {
                                return node._control.getRoom()?.name;
                            }
                        }

                        return {
                            name: node.title,
                            roomName: getRoomNameForNode(node),
                            isHeading: node.isHeading,
                            computeUntracked: node.computeUntracked,
                            untrackedName: node.untrackedName,
                            untrackedIcon: node.untrackedIcon,
                            showFlowAnimation: passedVt === EfmViewType.Actual,
                            value: Math.abs(valueToPass),
                            topArrow,
                            secondValue: typeof secondValue === 'number' ? Math.abs(secondValue) : undefined,
                            bottomArrow,
                            secondFormat,
                            flow,
                            positiveDirection,
                            format: passedVt === EfmViewType.Actual ? control.actualFormat : control.totalFormat,
                            hasChildren: node.hasChildNodes,
                            icon: node.icon,
                            onClick,
                            contextMenu,
                        };
                    } else {
                        return createDummySpiderNode(node._title, node.isHeading, node.icon, node.hasChildNodes);
                    }
                }, e => {
                    console.log('Error rendering Nodes: ', e);
                    mHasErrors = true;
                });
            });
        setHasErrors(mHasErrors)
        return CancelablePromise.allSettled(prmsToSettle).then(results => {
            const nodesToRender = results
                .filter(({ status }) => status === "fulfilled" )
                .map(({value}) => value).filter(n => n); // filter out null nodes - fix for BG-I29826
            return getUntrackedNode({ passedVt, subNodes: nodesToRender }).then(untrackedNode => {
                untrackedNode && nodesToRender.push(untrackedNode);
                setLastSpiderNodesRenderInfo({ lastCount: nodesToRender.length, lastVt: viewInfo.vt, nodes: nodesToRender });
                setSpiderNodes(nodesToRender);
            });
        });
    };

    const getUntrackedNode = ({ passedVt, subNodes = [] }) => {
        if (topLevel) {
            if (control && control.computeUntracked) {
                const nodesWithoutHeader = nodes.filter((n) => !n.isHeading);
                return EfmNodeFactory.getOtherValue(nodesWithoutHeader, passedVt, viewInfo.ts).then((otherNodeVal) => {
                    const value = otherNodeVal[passedVt === EfmViewType.Actual ? "actual" : "total"]
                    return {
                        name: control.untrackedName,
                        icon: control.untrackedIcon,
                        showFlowAnimation: passedVt === EfmViewType.Actual,
                        value: sanitizeValue(Math.abs(value)),
                        topArrow: passedVt !== EfmViewType.Actual && value !== 0 ? {
                            up: value > 0,
                            col: value < 0 ? globalStyles.colors.green : globalStyles.colors.orange,
                        } : false,
                        showValueArrow: true,
                        flow: value < 0 ? 1 : value > 0 ? -1 : 0,
                        positiveDirection: 1,
                        format: passedVt === EfmViewType.Actual ? control.actualFormat : control.totalFormat,
                    }
                })
            }
        } else {
            const parent = subNodes.find((n) => n.isHeading);
            if (parent && parent.computeUntracked) {
                const viewTypeKey = passedVt === EfmViewType.Actual ? 'actual' : 'total';

                const nodes_without_header = nodes.filter((n) => !n.isHeading);
                return EfmNodeFactory.getOtherValue(nodes_without_header, passedVt, viewInfo.ts).then(knownNodesValue => {
                    knownNodesValue = knownNodesValue[viewTypeKey];

                    const node_header = nodes.find((n) => n.isHeading);
                    return node_header.getNodeValue(passedVt, viewInfo.ts).then(headerResult => {

                        let headerValue = headerResult[viewTypeKey];
                        if (passedVt !== EfmViewType.Actual && typeof headerResult.totalNeg === 'number') {
                            headerValue -= headerResult.totalNeg;
                        }

                        const value = headerValue + knownNodesValue;

                        return {
                            name: parent.untrackedName,
                            icon: parent.untrackedIcon,
                            showFlowAnimation: passedVt === EfmViewType.Actual,
                            value: sanitizeValue(Math.abs(value)),
                            topArrow: false,
                            showValueArrow: true,
                            flow: value < 0 ? 1 : value > 0 ? -1 : 0,
                            positiveDirection: 1,
                            format: passedVt === EfmViewType.Actual ? control.actualFormat : control.totalFormat,
                        };
                    });
                });
            }
        }

        return CancelablePromise.resolve();
    }
    /* #endregion */

    const navigateToControl = (subControl) => {
        // pass on the viewType info + that the control has been opened by the EFM, this allows meters to show the same
        // viewType+TS as the EFM is currently in.
        NavigationComp.showControlContent(subControl, {
            updateParentViewInfo: onUpdateVt,
            viewType: viewInfo.vt,
            timestamp: viewInfo.ts,
            fromEfm: true // passing true here enables the meter to pass back modified vt+timestamp.
        })
    }
    
    // array of promises to keep track on which one to cancel in the cleanup of the useEffect
    const spiderNodePrmsArr = useRef([]); ;

    useEffect(() => {
        if (!Array.isArray(nodes) || nodes.length === 0) {
            setSpiderNodes([]);
            return;
        }

        let prms = startSpiderNodeUpdate();
        spiderNodePrmsArr.current.push(prms);

        // start a timeout that will show dummy spider nodes if loading data takes too long.
        const delayedDummyTimeout = setTimeout(() => {
            // no values arrived within timeout - show dummy while waiting further
            setSpiderNodes(createDummySpiderNodes(lastSpiderNodesRenderInfo.nodes, lastSpiderNodesRenderInfo));
        }, 500);
        prms.finally(() => {
            clearTimeout(delayedDummyTimeout)
        }, true);

        return () => {
            clearTimeout(delayedDummyTimeout);
            spiderNodePrmsArr.current.slice(0, -1).forEach((prm, index) => {
                prm.cancel();
                spiderNodePrmsArr.current.splice(index, 1);
            });
        }
    }, [nodes, nodeStates, viewInfo, topLevel, liveState.states]);
    /* #endregion */

    /* #region StorageLevelHelper */

    const storageLevelNode = useMemo(() => {
        let availableStorages = nodes.filter((no) => no.nodeType === EfmNodeType.Storage);
        return availableStorages.length > 0 ? availableStorages[0] : null;
    }, [nodes]);

    const storageLevelStatSupported = useMemo(() => {
        // in client/gateway installations it may occur that a node isn't available, this would cause problems.
        return viewInfo.vt === DateViewType.Live && !!storageLevelNode && !!storageLevelNode.control;
    }, [viewInfo.vt, storageLevelNode]);

    /* #endregion */

    /* #region  Cards */
    const cards = useMemo(() => {
        if (!Array.isArray(nodes) || !liveState || !liveState.states) {
            return [];
        }

        const arr = [];
        if (!typedNodeValuesMinViewtypeDay || !typedNodeValuesMinViewtypeDay[EfmNodeType.Production] || !liveState) {
            return [];
        }
        let prodTotal, storageTotal, gridTotal;
        try {
            prodTotal = typedNodeValuesMinViewtypeDay[EfmNodeType.Production];
            if (!prodTotal) { prodTotal = { total: 0 } }
            if (typeof prodTotal.total !== 'number') { prodTotal.total = 0 }
            if (typedNodeValuesMinViewtypeDay[EfmNodeType.Storage]) {
                storageTotal = typedNodeValuesMinViewtypeDay[EfmNodeType.Storage];
                if (!storageTotal) { storageTotal = { total: 0, totalNeg: 0 } }
                if (typeof storageTotal.total !== 'number') { storageTotal.total = 0 }
                if (typeof storageTotal.totalNeg !== 'number') { storageTotal.totalNeg = 0 }
            }
            if (typedNodeValuesMinViewtypeDay[EfmNodeType.Grid]) {
                gridTotal = typedNodeValuesMinViewtypeDay[EfmNodeType.Grid];
                if (!gridTotal) { gridTotal = { total: 0, totalNeg: 0 } }
                if (typeof gridTotal.total !== 'number') { gridTotal.total = 0 }
                if (typeof gridTotal.totalNeg !== 'number') { gridTotal.totalNeg = 0 }
            }
            Debug.Control.Efm.Stat && console.log("EfmStats", ` reduced total stats: production=${JSON.stringify(prodTotal)}, storage=${JSON.stringify(storageTotal)}, grid=${JSON.stringify(gridTotal)}`);
            if (prodTotal && typeof prodTotal.total === 'number') {
                const prodTotalNum = getTotalWhileIgnoringTinyNumbers(prodTotal);
                const gridTotalNegNum = getTotalWhileIgnoringTinyNumbers(gridTotal, true) || 0;
                const storTotalNegNum = getTotalWhileIgnoringTinyNumbers(storageTotal, true) || 0;
                const ownConsumption = Math.max(0, prodTotalNum - gridTotalNegNum - storTotalNegNum);
                const ownConsumptionPercent = ownConsumption <= 0 || prodTotalNum <= 0 ? 0 : ownConsumption / prodTotalNum * 100;
                const formattedTotal = lxUnitConverter.convertSplitAndApply(control.totalFormat, prodTotalNum);
                arr.push({
                    top: _("efm.card.energy-produced"),
                    middle: formattedTotal.valueTxt,
                    middleSmall: formattedTotal.succTxt,
                    bottom: !isNaN(ownConsumptionPercent) && isFinite(ownConsumptionPercent) ?
                        _("efm.card.self-consumed-precentage", { percentage: lxFormat("%.0f%%", ownConsumptionPercent) }) :
                        undefined,
                })
            }
        } catch (e) { }
        try {
            if (prodTotal && typeof prodTotal.total === 'number' && liveState.states.co2Factor) {
                const prodTotalNum = getTotalWhileIgnoringTinyNumbers(prodTotal);
                const co2Saved = getTotalWhileIgnoringTinyNumbers({ total: prodTotalNum * liveState.states.co2Factor });
                const numTrees = Math.round(((co2Saved / 1000) / 0.06) * 10) / 10;
                const formattedTotal = lxUnitConverter.convertSplitAndApply('%.2f', co2Saved);
                arr.push({
                    top: _("efm.card.co2-saved"),
                    middle: formattedTotal.valueTxt,
                    middleSmall: ' kg',
                    bottom: _("efm.card.equivalent-to-trees", { numTrees: lxFormat('%.1f', numTrees) }),
                })
            }
        } catch (e) { }
        try {
            if (prodTotal && typeof prodTotal.total === 'number' && gridTotal && typeof liveState.states.priceExport === 'number' && typeof liveState.states.priceImport === 'number') {
                const prodTotalNum = getTotalWhileIgnoringTinyNumbers(prodTotal);
                const gridTotalNum = getTotalWhileIgnoringTinyNumbers(gridTotal) || 0;
                const gridTotalNegNum = getTotalWhileIgnoringTinyNumbers(gridTotal, true) || 0;
                const storageTotalNum = getTotalWhileIgnoringTinyNumbers(storageTotal) || 0;
                const storageTotalNegNum = getTotalWhileIgnoringTinyNumbers(storageTotal, true) || 0;

                // moneySaved = Importpreis * (Production - Grid-Export) + Exportpreis * Grid-Export
                const consumedFromOwnProduction = prodTotalNum - gridTotalNegNum - storageTotalNegNum; // (Production - Grid-Export)
                const totalConsumed = consumedFromOwnProduction + storageTotalNum + gridTotalNum;

                const moneySavedFromStats = moneySavedFromStatsUnchecked?.loaded ? moneySavedFromStatsUnchecked?.value : undefined
                const moneySaved = moneySavedFromStats ? moneySavedFromStats : getTotalWhileIgnoringTinyNumbers(
                    { total: (consumedFromOwnProduction + storageTotalNum) * liveState.states.priceImport + gridTotalNegNum * liveState.states.priceExport }
                );
                // perc = moneySaved / Total Consumption * priceImport) * 100; Total Consumption = Total Production - Export + Import

                const formattedTotal = lxUnitConverter.convertSplitAndApply('%.2f', moneySaved, false); // Don't auto drop decimals on currencies

                // moneySavedPerc 2.0 = (SelfConsumption + Storage Discharge) * 100 / TotalConsumption
                let moneyWouldHaveSpentPerc = getTotalWhileIgnoringTinyNumbers({ total: Math.max(0, Math.min(100, ((consumedFromOwnProduction + storageTotalNum) * 100) / totalConsumed)) });
                arr.push({
                    top: _("efm.card.money-saved"),
                    middle: formattedTotal.valueTxt,
                    middleSmall: " " + ActiveMSComponent.getCurrencyString(),
                    bottom: _("efm.card.percentage-of-bill", { percentage: lxFormat("%.0f%%", moneyWouldHaveSpentPerc) })
                })
            }
        } catch (e) { }
        return arr.length > 0 ? arr : undefined;
    }, [
        typedNodeValuesMinViewtypeDay[EfmNodeType.Production],
        typedNodeValuesMinViewtypeDay[EfmNodeType.Storage],
        typedNodeValuesMinViewtypeDay[EfmNodeType.Grid],
        liveState.states,
        (liveState.states || {}).priceImport,
        (liveState.states || {}).priceExport,
        (liveState.states || {}).co2Factor,
        moneySavedFromStatsUnchecked
    ]);
    /* #endregion */

    const controlNotes = useMemo(() => {
        return control && control.hasControlNotes && topLevel ?
            <LxControlContextProvider controlUuid={control.uuidAction} isAlert={false}>
                <LxControlNoteView showUpperSeparator={false} />
            </LxControlContextProvider>
            : null;
    }, [control && control.hasControlNotes, control.uuidAction])

    const onUpdateVt = (selectedViewInfo) => {
        updateViewInfo(selectedViewInfo);
        if (!topLevel && route.params.parentSetViewInfo && typeof route.params.parentSetViewInfo === "function") {
            route.params.parentSetViewInfo(selectedViewInfo);
        }
    }

    const [backgroundClick, setBackgroundClick] = useState(false);
    const doBackgroundClick = function () {
        setBackgroundClick(!backgroundClick);
    }

    // TODO: may need a version-check unless statistic queue handling can be fixed within app.
    const liveTypeName = _("power");
    const allowPastLive = true;

    return (
        <View style={{ flex: 1, backgroundColor: 'transparent' }}>
            <DateViewTypeSelector
                liveTypeName={liveTypeName}
                allowPastLive={allowPastLive}
                onUpdateVt={onUpdateVt}
                value={viewInfo}
                autoUpdateTs={true}
                initialViewType={route.params.fixedViewinfo ? route.params.fixedViewinfo.vt : undefined}
                initialTs={route.params.fixedViewinfo ? route.params.fixedViewinfo.ts : undefined}
            />

            <View style={{ flex: 1, zIndex: 1 }}>

                <ScrollView
                    onClick={doBackgroundClick}
                    contentContainerStyle={{
                        paddingLeft: globalStyles.fontSettings.sizes.regular, paddingRight: globalStyles.fontSettings.sizes.regular,
                        paddingBottom: globalStyles.spacings.gaps.veryBig,
                        width: '100%', maxWidth: globalStyles.sizes.contentMaxWidth, marginLeft: 'auto', marginRight: 'auto',
                    }}
                >

                    {controlNotes}

                    <NodeSpider
                        viewType={viewInfo.vt}
                        nodes={spiderNodes}
                        topLevel={topLevel}
                        backgroundClick={backgroundClick}
                    />
                    {
                        topLevel && availableNodeTypes.Production ? (
                            <ScrollingCards cards={cards} />
                        ) : null
                    }
                    {
                        availableNodeTypes.Production ? (
                            <>
                                <SeparationLine />
                                <ProductionGraph isLivePast={isLivePast} availableNodeTypes={availableNodeTypes} onZoom={() => updateZoomedGraph('production')} currentValue={typedNodeValues[EfmNodeType.Production]} loading={!prodStats} gridStats={gridStats} storStats={storStats} prodStats={prodStats} viewType={viewInfo.vt} timeRange={timeRange} controlUuid={control.uuidAction} />
                            </>
                        ) : null
                    }
                    {
                        (availableNodeTypes.Grid && (availableNodeTypes.Production || availableNodeTypes.Storage) || availableNodeTypes.Production && availableNodeTypes.Storage || (availableNodeTypes.Consumption && !availableNodeTypes.Storage && !availableNodeTypes.Grid)) ? (
                            <>
                                <SeparationLine />
                                <ConsumptionGraph isLivePast={isLivePast} availableNodeTypes={availableNodeTypes} onZoom={() => updateZoomedGraph('consumption')} currentValues={typedNodeValues} gridStats={gridStats} storStats={storStats} prodStats={prodStats} loadStats={loadStats} viewType={viewInfo.vt} timeRange={timeRange} controlUuid={control.uuidAction} />
                            </>
                        ) : null
                    }
                    {
                        availableNodeTypes.Grid ? (
                            <>
                                <SeparationLine />
                                <GridGraph isLivePast={isLivePast} onZoom={() => updateZoomedGraph('grid')} currentValue={typedNodeValues[EfmNodeType.Grid]} loading={!gridStats} gridStats={gridStats} timeRange={timeRange} viewType={viewInfo.vt} controlUuid={control.uuidAction} gridNodes={gridNodes} />
                            </>
                        ) : null
                    }
                    {
                        availableNodeTypes.Storage ? (
                            <>
                                <SeparationLine />
                                <StorageGraph isLivePast={isLivePast} onZoom={() => updateZoomedGraph('storage')} currentValue={typedNodeValues[EfmNodeType.Storage]} loading={!storStats} storStats={storStats} timeRange={timeRange} viewType={viewInfo.vt} controlUuid={control.uuidAction} />
                            </>
                        ) : null
                    }
                    {
                        storageLevelStatSupported ?
                            <>
                                <SeparationLine />
                                <StorageLevelGraph storageNode={storageLevelNode} onZoom={() => updateZoomedGraph('storageLevel')} timeRange={timeRange} viewType={viewInfo.vt} showTitleRight={viewTypeForTypedNodeValues === EfmViewType.Actual} />
                            </>
                            : null
                    }
                    <LxReactLinkedControlsList controlUuid={route.params.controlUUID} />
                </ScrollView>
                {
                    zoomedGraph !== 'none' ? (
                        <ScrollView
                            contentContainerStyle={{
                                alignItems: 'stretch',
                                justifyContent: 'stretch',
                                flex: 1,
                            }}
                            style={{
                                backgroundColor: 'black', flex: 1,
                                paddingLeft: globalStyles.fontSettings.sizes.regular, paddingRight: globalStyles.fontSettings.sizes.regular,
                                width: '100%', height: '100%', position: 'absolute', left: 0, top: 0, paddingBottom: 40, display: 'flex', flexDirection: 'row',
                                overflow: "initial"
                            }}
                        >
                            {
                                zoomedGraph === 'production' ? (
                                    <ProductionGraph isLivePast={isLivePast} availableNodeTypes={availableNodeTypes} fullStretch={true} currentValue={typedNodeValues[EfmNodeType.Production]} loading={!prodStats} gridStats={gridStats} storStats={storStats} prodStats={prodStats} viewType={viewInfo.vt} timeRange={timeRange} controlUuid={control.uuidAction} />
                                ) : zoomedGraph === 'consumption' ? (
                                    <ConsumptionGraph isLivePast={isLivePast} availableNodeTypes={availableNodeTypes} fullStretch={true} currentValues={typedNodeValues} gridStats={gridStats} storStats={storStats} prodStats={prodStats} loadStats={loadStats} viewType={viewInfo.vt} timeRange={timeRange} controlUuid={control.uuidAction} />
                                ) : zoomedGraph === 'grid' ? (
                                    <GridGraph isLivePast={isLivePast} fullStretch={true} currentValue={typedNodeValues[EfmNodeType.Grid]} loading={!gridStats} gridStats={gridStats} timeRange={timeRange} viewType={viewInfo.vt} controlUuid={control.uuidAction} gridNodes={gridNodes} />
                                ) : zoomedGraph === 'storage' ? (
                                    <StorageGraph isLivePast={isLivePast} fullStretch={true} currentValue={typedNodeValues[EfmNodeType.Storage]} loading={!storStats} storStats={storStats} timeRange={timeRange} viewType={viewInfo.vt} controlUuid={control.uuidAction} />
                                ) : zoomedGraph === 'storageLevel' ? (
                                    storageLevelStatSupported ?
                                        <StorageLevelGraph fullStretch={true} storageNode={storageLevelNode} timeRange={timeRange} showTitleRight={viewTypeForTypedNodeValues === EfmViewType.Actual} />
                                        : null
                                ) : null
                            }
                        </ScrollView>
                    ) : null
                }
            </View>
        </View>
    )
}
