import * as React from "react"
import Svg, { Path } from "react-native-svg"

function EnergyFlowMonitorSymbol(props) {
    return (
        <Svg isreact="true"
            width={36}
            height={40}
            viewBox="0 0 36 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23 5a5.001 5.001 0 01-4.167 4.93v7.218l1.22.704 6.25-3.608A5.001 5.001 0 0135.321 10a5 5 0 01-8.184 5.687l-6.25 3.608v1.41l6.25 3.608A5.001 5.001 0 0135.32 30a5 5 0 01-9.017-4.244l-6.25-3.608-1.22.704v7.217A5.001 5.001 0 0118 40a5 5 0 01-.833-9.93v-7.218l-1.22-.704-6.25 3.608A5.001 5.001 0 01.679 30a5 5 0 018.184-5.687l6.25-3.608v-1.41l-6.25-3.608A5.001 5.001 0 01.68 10a5 5 0 019.018 4.244l6.25 3.608 1.22-.704V9.93A5.001 5.001 0 0118 0a5 5 0 015 5zm-5 3.333a3.333 3.333 0 100-6.666 3.333 3.333 0 000 6.666zm14.657 7.054a3.333 3.333 0 11-3.333-5.774 3.333 3.333 0 013.333 5.774zm-3.333 15a3.333 3.333 0 113.333-5.774 3.333 3.333 0 01-3.333 5.774zM14.667 35a3.333 3.333 0 116.666 0 3.333 3.333 0 01-6.666 0zM3.343 24.613a3.333 3.333 0 113.333 5.774 3.333 3.333 0 01-3.333-5.774zm3.333-15a3.333 3.333 0 11-3.333 5.774 3.333 3.333 0 013.333-5.774z"
            />
        </Svg>
    )
}

export default EnergyFlowMonitorSymbol
