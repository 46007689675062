'use strict';

define('AutomaticDesignerTableContentHelper', [], function () {
    return class AutomaticDesignerTableContentHelper {
        //region Static
        static getCellPrototypeForEntry(entry, type, screenType, fromSearch) {
            var wantedTypes = AutomaticDesignerEnums.KNOWN_TYPES[screenType.toUpperCase()],
                cell = {
                    content: {
                        clickable: true,
                        class: "base-cell--with-chevron",
                        entry: entry
                    }
                }; // Adopt the type

            if (entry.hasOwnProperty("groupType")) {
                type = entry.groupType;
            } else if (entry.hasOwnProperty("uuidAction")) {
                type = wantedTypes.FUNCTIONS.STRING;
            }

            try {
                switch (type) {
                    case wantedTypes.FUNCTIONS.STRING:
                        if (entry.hasOwnProperty("uuidAction")) {
                            cell.content.title = entry.getName();
                            cell.content.subtitle = entry.getControlTypeName();

                            if (fromSearch && entry.getRoom()) {
                                if (cell.content.subtitle) {
                                    cell.content.subtitle += SEPARATOR_SYMBOL + entry.getRoom().name;
                                } else {
                                    cell.content.subtitle = entry.getRoom().name;
                                }
                            }
                        } else {
                            cell.content.title = entry.name;
                        }

                        break;

                    case wantedTypes.ROOMS.STRING:
                    case wantedTypes.CATS.STRING:
                        cell.content.title = entry.name;
                        cell.content.subtitle = entry.subtitle;
                        break;

                    case GroupTypes.ROOM:
                    case GroupTypes.CATEGORY:
                        cell.content.leftIconSrc = entry.image;
                        cell.content.leftIconBgEnabled = true;
                        cell.content.leftIconColor = Color.WHITE;
                        cell.content.title = entry.name;
                        break;

                    case wantedTypes.OPERATING_MODES.STRING:
                    case wantedTypes.NOTIFICATIONS.STRING:
                    case wantedTypes.SCENES.STRING:
                    case wantedTypes.SCRIPTS.STRING:
                    case wantedTypes.WEATHER.STRING:
                    default:
                        cell.content.title = entry.name;
                }
            } catch (e) {
                cell.content.title = entry.name;
            }

            return cell;
        } //endregion Static


        constructor(delegate, navigationPath, screenType) {
            this.delegate = delegate;
            this.navigationPath = navigationPath;
            this.screenType = screenType;
        }

        getContentForIndex(lastSelectedEntry) {
            var tableContent = [{
                    rows: []
                }],
                rawData,
                capabilitiesEntries;

            if (lastSelectedEntry && lastSelectedEntry.hasOwnProperty('groupType')) {
                tableContent = this._getTableContentForGroup(lastSelectedEntry);
            } else if (lastSelectedEntry && !!lastSelectedEntry.uuidAction) {
                capabilitiesEntries = AutomaticDesignerComponent.getDataFromCapabilities(lastSelectedEntry.type);
                capabilitiesEntries.forEach(function (capabilitiesEntry) {
                    capabilitiesEntry && capabilitiesEntry[this.screenType] && capabilitiesEntry[this.screenType].forEach(function (actionOrEvent, entryIdx) {
                        if (lastSelectedEntry.meetsCapabilityConditions(actionOrEvent)) {
                            tableContent[0].rows.push(this.createDetailedCellForRow(actionOrEvent, lastSelectedEntry, false));
                        }
                    }.bind(this));
                }.bind(this));
            } else {
                rawData = AutomaticDesignerComponent.getRawDataForPath(this.navigationPath);
                rawData.forEach(function (entry) {
                    if (entry.isLegacy) {
                        return;
                    }

                    var pushCapability = function pushCapability(item, lse) {
                        item.intType.forEach(function (intType, intTypeIdx) {
                            capabilitiesEntries = AutomaticDesignerComponent.getDataFromCapabilities(intType);
                            capabilitiesEntries.forEach(function (capabilitiesEntry) {
                                capabilitiesEntry && capabilitiesEntry[this.screenType] && capabilitiesEntry[this.screenType].forEach(function (detailedEntry, entryIdx) {
                                    let control,
                                        meetsCondition = true;detailedEntry.entryIdx = (intTypeIdx + 1) * 10 + entryIdx;
                                   if ("uuidAction" in lse) {
                                        control = ActiveMSComponent.getStructureManager().getControlByUUID(lse.uuidAction);
                                        meetsCondition = control.meetsCapabilityConditions(detailedEntry);
                                    }

                                    meetsCondition && tableContent[0].rows.push(this.createDetailedCellForRow(detailedEntry, lse, false, intType));
                                }.bind(this));
                            }.bind(this));
                        }.bind(this));
                    }.bind(this);

                    if (entry.hasOwnProperty('name')) {
                        if (entry.flatList) {
                            entry.entries.forEach(function (entry1) {
                                pushCapability(entry1, entry);
                            }.bind(this));
                        } else {
                            tableContent[0].rows.push(this._createNavigationCell(entry));
                        }
                    } else if (entry.hasOwnProperty('intType')) {
                        pushCapability(entry, lastSelectedEntry);
                    }
                }.bind(this));

                if (lastSelectedEntry && lastSelectedEntry.type === AutomaticDesignerEnums.KNOWN_TYPES[this.screenType.toUpperCase()].OPERATING_MODES.STRING && lastSelectedEntry.hasOwnProperty("loxAppType")) {
                    tableContent[0].footerTitle = _("automatic-designer.operating-modes.not-used.desc");
                }
            }

            return tableContent.filter(function (section) {
                return section.rows && section.rows.length;
            });
        }

        getTableContentForGroupType(groupType) {
            return [{
                rows: AutomaticDesignerComponent.getUsedGroupsUuidsByType(groupType, this.screenType).map(function (groupUuid) {
                    return this._createNavigationCell(ActiveMSComponent.getStructureManager().getGroupByUUID(groupUuid, groupType));
                }.bind(this))
            }];
        }

        updateNavigationPathAtIndex(idx, val) {
            var tempXPath = this.navigationPath.split('.');
            tempXPath[idx] = val;
            this.navigationPath = tempXPath.join('.');
        }

        getTitleBarTitle() {
            var title;

            switch (this.screenType) {
                case AutomaticDesignerEnums.SCREEN_TYPES.EVENTS:
                    title = _('automatic-designer.rule.events.add');
                    break;

                case AutomaticDesignerEnums.SCREEN_TYPES.ACTIONS:
                    title = _('automatic-designer.rule.actions.add');
                    break;
            }

            return title;
        }

        _deduceLastSelectedItem(capability, intType, lastSelectedItem) {
            switch (capability.type) {
                case AutomaticDesignerEnums.EVENT_TYPES.WEATHER:
                    lastSelectedItem = ActiveMSComponent.getStructureManager().getWeatherFieldTypes(intType);
                    break;

                case AutomaticDesignerEnums.EVENT_TYPES.OPERATING_MODE:
                case AutomaticDesignerEnums.ACTION_TYPES.OPERATING_MODE:
                    lastSelectedItem = {
                        name: ActiveMSComponent.getStructureManager().getOperatingModes(intType),
                        id: intType
                    };
                    break;
            }

            return lastSelectedItem;
        }

        _getTableContentForGroup(group) {
            var filterGroupKey = group.groupType === GroupTypes.ROOM ? "room" : "cat",
                wantedGroupType = group.groupType === GroupTypes.ROOM ? GroupTypes.CATEGORY : GroupTypes.ROOM,
                sortGroupKey = wantedGroupType === GroupTypes.ROOM ? "room" : "cat",
                controlsInGroup,
                sectionMap = {},
                groups;

            if (!group.isLegacy) {
                controlsInGroup = Object.values(ActiveMSComponent.getStructureManager().getSupportedControls(true, true)).filter(function (control) {
                    return control.hasAutomaticDesignerCapabilities(this.screenType) && control[filterGroupKey] === group.uuid;
                }.bind(this));
            } else {
                controlsInGroup = AutomaticDesignerComponent.getRawDataForPath(this.navigationPath.replace(/(.*)(\..*\..*)/, "$1")).filter(function (control) {
                    // We can't use control[lastSelectedEntry.groupType] because our Enum GroupType.CATEGORY has the wrong value!
                    return control[filterGroupKey] === group.uuid;
                });
            }

            controlsInGroup.forEach(function (control) {
                if (!sectionMap.hasOwnProperty(control[sortGroupKey])) {
                    sectionMap[control[sortGroupKey]] = [];
                }

                sectionMap[control[sortGroupKey]].push(control);
            });
            groups = Object.keys(sectionMap).map(function (groupId) {
                return ActiveMSComponent.getStructureManager().getGroupByUUID(groupId, wantedGroupType);
            }.bind(this));
            return groups.map(function (group) {
                if (!group) {
                    if (wantedGroupType === GroupTypes.ROOM) {
                        group = {
                            id: UnassignedUUID,
                            name: _('room.without')
                        };
                    } else {
                        group = {
                            id: UnassignedUUID,
                            name: _('category.without')
                        };
                    }
                }

                return {
                    headerTitle: group.name,
                    rows: sectionMap[group.uuid].map(this._createNavigationCell.bind(this))
                };
            }.bind(this));
        }

        _createNavigationCell(entry) {
            var cell = this.constructor.getCellPrototypeForEntry(entry, entry.type, this.screenType);

            cell.action = function (cell, section, row, tableView) {
                this._navigateToNextEntry(cell.content.entry, row);
            }.bind(this);

            return cell;
        }

        _navigateToNextEntry(entry, row) {
            if (entry.hasOwnProperty("showAdFunc") && typeof entry.showAdFunc === "function" && entry.showAdFunc()) {// Do nothing if showAdFunc returns true we show the Ad
            } else {
                var details = {
                    navigationPath: this.navigationPath + "." + row,
                    screenType: this.screenType,
                    entry: entry
                };

                if (entry.type === AutomaticDesignerEnums.KNOWN_TYPES[this.screenType.toUpperCase()].FUNCTIONS.STRING) {
                    this.delegate.ViewController.showState(ScreenState.AutomaticDesigner.RuleMenuPageScreen, null, details);
                } else {
                    this.delegate.ViewController.showState(ScreenState.AutomaticDesigner.RuleMenuScreen, null, details);
                }
            }
        }

        _sortByRating(a, b, keys) {
            var aValue = cloneObjectDeep(a),
                bValue = cloneObjectDeep(b);
            keys.forEach(function (key) {
                aValue = aValue[key];
                bValue = bValue[key];
            }.bind(this));

            if (aValue.defaultRating === bValue.defaultRating) {
                return aValue.name.toUpperCase().localeCompare(bValue.name.toUpperCase());
            }

            return aValue.defaultRating < bValue.defaultRating ? 1 : -1;
        }

        createDetailedCellForRow(content, lastSelectedEntry, indent, intType) {
            return {
                type: GUI.TableViewV2.CellType.Special.AUTOMATIC_DESIGNER_CELL,
                content: {
                    title: content.name,
                    // This is just for sorting
                    descID: content.descID,
                    groupIdx: content.groupIdx,
                    entryIdx: content.entryIdx,
                    indent: indent,
                    navigationPath: this.navigationPath,
                    editMode: content.editMode,
                    lastSelectedEntry: lastSelectedEntry,
                    screenType: this.screenType,
                    intType: intType,
                    recorded: content.recorded
                },
                didRemoveCapability: function didRemoveCapability(section, row, tableView, cell, id) {
                    this.delegate.capabilityRemoved && this.delegate.capabilityRemoved(id);
                }.bind(this),
                validityDidChange: function validityDidChange(section, row, tableView, cell, valid, id) {
                    this.delegate.capabilityValidityChange && this.delegate.capabilityValidityChange(valid, id);
                }.bind(this)
            };
        }

    };
});
