import Svg, { Path } from "react-native-svg"

function EcoScreenFilled(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.867 4.006a.5.5 0 01.485.646C8.777 9.792 12.622 15 18 15l.114-.007a.5.5 0 01.49.646A9 9 0 011 13c0-4.92 3.952-8.922 8.867-8.994zm10.665 4.819a.5.5 0 01.936 0l.465 1.24 1.243.467a.5.5 0 01.086.895l-.087.041-1.242.465-.465 1.242a.5.5 0 01-.895.086l-.041-.086-.466-1.242-1.241-.465a.5.5 0 01-.087-.895l.086-.041 1.242-.467.466-1.24zm-5-8a.5.5 0 01.936 0l1.011 2.694 2.697 1.013a.5.5 0 01.086.895l-.087.041-2.696 1.01-1.01 2.698a.5.5 0 01-.896.086l-.041-.086-1.012-2.698-2.695-1.01a.5.5 0 01-.087-.895l.086-.041 2.696-1.013L15.532.824z"
            />
        </Svg>
    )
}

export default EcoScreenFilled
