'use strict';

define("AlarmClockControlCommandSrc", ["ControlCommandSrc", "AlarmClockControlEnums"], function (ControlCommandSrc, AlarmClockControlEnums) {
    return class AlarmClockControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [];

            if (states.isActive) {
                if (!states.isSnoozing && states.snoozeDuration > 0) {
                    cmds.push(this.createCommand(_("controls.alarmClock.snooze.for", {
                        time: LxDate.formatSeconds(states.snoozeDuration).replace(/&nbsp;/, "\u00A0")
                    }), Commands.ALARM_CLOCK.SNOOZE));
                }

                cmds.push(this.createCommand(_("controls.alarmClock.turn-off"), Commands.ALARM_CLOCK.DISMISS, null));
            } else {
                cmds.push(this.createCommand(_('controls.alarmClock.addNewEntry'), this.showEditEntry.bind(this, control), null));
            }

            return cmds;
        }

        showEditEntry(control) {
            NavigationComp.showState(AlarmClockControlEnums.ScreenState.EDIT_ENTRY, { control })
        }

    };
});
