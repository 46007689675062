import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Weather04(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            <Path
                d="M11 13.7a3.29 3.29 0 011.689-2.858A5.01 5.01 0 0117.75 6a5.085 5.085 0 014.306 2.346 3.735 3.735 0 011.038-.146 3.655 3.655 0 013.423 2.317A3.293 3.293 0 0125.625 17h-11.25A3.338 3.338 0 0111 13.7z"
                fill="#9cb2bc"
                fillRule="evenodd"
            />
            <Path
                d="M2 21.6a5.431 5.431 0 012.627-4.677A7.984 7.984 0 0112.5 9a7.825 7.825 0 016.699 3.839 5.543 5.543 0 011.613-.239 5.698 5.698 0 015.326 3.79A5.42 5.42 0 0124.75 27H7.25A5.327 5.327 0 012 21.6z"
                fill="#d5e2e9"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default Weather04
