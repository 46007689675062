'use strict';

define("AutomaticDesignerAddOrEditRule", [
    "AutomaticDesignerTableContentHelper",
    "LxComponents"
], function (
    AutomaticDesignerTableContentHelper, {
        App
    }
) {
    return class AutomaticDesignerAddOrEditRule extends GUI.TableViewScreenV2 {
        //region Static
        static Template = class {
            //region Static
            static lockedFooterElement(texts) {
                return $('<div class="locked-footer">' + '   ' + '<div class="locked-footer__msg1">' + texts[0] + '</div>' + '   ' + '<div class="locked-footer__msg2">' + texts[1] + '</div>' + '</div>');
            }

            static getEventMenuListTemplate() {
                return $("<div class='button-container'>" + "   <div class='button-container__button'>" + _('automatic-designer.rule.events.add') + "   </div>" + "</div>");
            }

            static getActionMenuListTemplate() {
                return $("<div class='add-item-container'>" + "   " + this._getCard("action-lib", Icon.AudioZone.NEW.LIBRARY, _("automatic-designer.rule.actions.cards.lib.text"), _('automatic-designer.rule.actions.add')) + "   " + this._getCard("action-task-recorder", Icon.TaskRecorderV2.RECORD, _("automatic-designer.rule.actions.cards.task-recorder.text"), _("automatic-designer.task-recorder.record-action.title")) + "</div>");
            }

            static _getCard(id, iconSrc, text, buttonTxt) {
                return "" + "<div class='add-item-container__card add-item-container__card--" + id + "'>" + "   " + ImageBox.getResourceImageWithClasses(iconSrc, "card__icon") + "   <div class='card__text'>" + text + "</div>" + "   <div class='card__button-container'>" + "       <div class='button-container__button'>" + buttonTxt + "</div>" + "   </div>" + "</div>" + "";
            }//endregion Static


        };

        static allowMultiple = true;
        //endregion Static

        constructor(details) {
            super($('<div/>'));
            this.details = details;

            if (details) {
                this.isInEditMode = details.isInEditMode;
                this.isSaveScreen = details.isSaveScreen;
            }

            this.currentEditRule = AutomaticDesignerComponent.getCurrentEditRule();
            this._ogRuleStr = JSON.stringify(this.currentEditRule);
            this._validityMap = {};
            this.isInEditMode = !!nullEmptyString(this.currentEditRule.id);
            this.isScene = this.currentEditRule.type === AutomaticDesignerEnums.RULE_TYPE.SCENE;

            if (details.hasOwnProperty("filterControls")) {
                this.filterControls = details.filterControls;
            }
        }

        setViewController() {
            super.setViewController(...arguments);
            this.ViewController.addMultipleView("GUI." + this.name);
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                return AutomaticDesignerComponent.acquireVisuPasswordForObj({
                    isSecured: this.currentEditRule.isSecured,
                    uuid: AutomaticDesignerComponent.getAutomaticDesignerUuid()
                }).then(function () {
                    return Q(true); // Authentication successful, just process as usual
                }, function () {
                    NavigationComp.disableAnimationsTemp();
                    this.ViewController.navigateBack();
                }.bind(this));
            }.bind(this));
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function () {
                return this.reloadTable();
            }.bind(this));
        }

        viewDidAppear() {
            return super.viewDidAppear(...arguments).then(function () {
                if (!this.isSaveScreen && this.filterControls && !this._didAutomaticallyShowFilteredLibrary) {
                    this._didAutomaticallyShowFilteredLibrary = true;

                    if (this.isScene) {
                        this._addActionFromLib();
                    } else {
                        this._addEventFromLib(0);
                    }
                }

                return Q.resolve();
            }.bind(this));
        }

        titleBarText() {
            return this.isInEditMode ? _('automatic-designer.rule.edit') : _('automatic-designer.rule.create');
        }

        titleBarConfig() {
            if (!this.isInEditMode) {
                return {
                    leftSide: this.isSaveScreen ? TitleBarCfg.Button.BACK : TitleBarCfg.Button.CLOSE,
                    rightSide: TitleBarCfg.CUSTOM_ICON,
                    rightIconSrc: this.isSaveScreen ? Icon.TitleBar.TICK : Icon.TitleBar.FORWARD,
                    ignoreTitleAsLeftSide: true
                };
            } else {
                return {
                    rightSide: TitleBarCfg.CUSTOM_ICON,
                    rightIconSrc: Icon.TitleBar.TICK,
                    ignoreTitleAsLeftSide: true
                };
            }
        }

        closeAction() {

            if (JSON.stringify(AutomaticDesignerComponent.getCurrentEditRule()) !== this._ogRuleStr) {
                if (this.isInEditMode) {
                    if (this._ruleIsValid) {
                        HapticFeedback(HapticFeedback.STYLE.WARNING);
                        return NavigationComp.showPopup({
                            title: this.getValidModifiedWarningTitleAndMsg().title,
                            message: this.getValidModifiedWarningTitleAndMsg().message,
                            buttons: [{
                                title: _("save"),
                                type: GUI.PopupBase.ButtonType.OK
                            }, {
                                title: _("dont-save"),
                                type: GUI.PopupBase.ButtonType.CUSTOM
                            }, {
                                title: _("cancel"),
                                type: GUI.PopupBase.ButtonType.CANCEL
                            }]
                        }).then(btnType => {
                            if (btnType === GUI.PopupBase.ButtonType.OK) {
                                this.titleBarActionRight();
                            } else {
                                return Q(App.navigationRef.goBack());
                            }
                        });
                    } else {
                        HapticFeedback(HapticFeedback.STYLE.WARNING);
                        return NavigationComp.showPopup({
                            title: this.getInvalidModifiedWarningTitleAndMsg().title,
                            message: this.getInvalidModifiedWarningTitleAndMsg().message,
                            buttons: [{
                                title: _("delete"),
                                type: GUI.PopupBase.ButtonType.OK
                            }, {
                                title: _("cancel"),
                                type: GUI.PopupBase.ButtonType.CANCEL
                            }]
                        }).then(btnType => {
                            if (btnType === GUI.PopupBase.ButtonType.OK) {
                                return this._deleteRule(true).then(() => {
                                    return Q(App.navigationRef.goBack());
                                });
                            }
                        });
                    }
                } else if (!this.isSaveScreen) {
                    HapticFeedback(HapticFeedback.STYLE.WARNING);
                    return NavigationComp.showPopup({
                        title: _("automatic-designer.add-or-edit.notice.new-unsaved.title"),
                        message: _("automatic-designer.add-or-edit.notice.new-unsaved.desc"),
                        buttons: [{
                            title: _("no"),
                            type: GUI.PopupBase.ButtonType.CANCEL
                        }, {
                            title: _("yes"),
                            type: GUI.PopupBase.ButtonType.CUSTOM
                        }]
                    }).then(btnType => {
                        return Q(App.navigationRef.goBack());
                    });
                }
            }
            return Q(this.ViewController.navigateBack());
        }

        getValidModifiedWarningTitleAndMsg() {
            return {
                title: _("automatic-designer.add-or-edit.notice.modify.title"),
                message: _("automatic-designer.add-or-edit.notice.modify.desc", {
                    type: this.isScene ? _("scenes.name") : _("automatic-designer.rule.name")
                })
            };
        }

        getInvalidModifiedWarningTitleAndMsg() {
            return {
                title: _("automatic-designer.add-or-edit.notice.modify.title"),
                message: _("automatic-designer.add-or-edit.notice.invalid-modify.desc", {
                    type: this.isScene ? _("scenes.name") : _("automatic-designer.rule.name")
                })
            };
        }

        titleBarActionRight() {
            if (this.isInEditMode || this.isSaveScreen) {
                return AutomaticDesignerComponent.addOrEditRule(this.isScene).then(function () {
                    // Use an empty object as first argument to trigger updateView in previous view
                    return this.ViewController.dismiss({});
                }.bind(this));
            } else {
                var details = cloneObject(this.details);
                details.isSaveScreen = true;

                if (this.isScene) {
                    return this.ViewController.showState(ScreenState.AutomaticDesigner.AddOrEditScene, null, details);
                } else {
                    return this.ViewController.showState(ScreenState.AutomaticDesigner.AddOrEditRule, null, details);
                }
            }
        }

        setTableContent() {
            this.currentEditRule = AutomaticDesignerComponent.getCurrentEditRule();
            this.tableContent = this._getTableContent();
            return super.setTableContent(...arguments);
        }

        validityDidChange(section, row, tableView, cell, valid, id) {
            this._setValidity(valid, id);
        }

        didRemoveCapability(section, row, tableView, cell, id) {
            delete this._validityMap[id];

            this._setValidity();
        }

        onGroupChange(section, row, tableView, cell, id) {
            this.tableView.setForceFullReload(true);
            return this.reloadTable().then(function () {
                this.tableView.setForceFullReload(false);
            }.bind(this));
        }

        reloadTable() {
            return super.reloadTable(...arguments).then(function (res) {
                this._processAfterTableReload();

                return res;
            }.bind(this));
        }

        _processAfterTableReload() {
            this.eventAddButtonElm = this.element.find(".button-container");
            this.actionLibButtonElm = this.element.find('.add-item-container__card--action-lib');
            this.actionTaskRecorderButtonElm = this.element.find('.add-item-container__card--action-task-recorder');

            if (this.eventAddButtonElm.length) {
                this.eventAddButton = new GUI.LxButton(this, this.eventAddButtonElm[0], null, null, false);
                this.addToHandledSubviews(this.eventAddButton);
                this.eventAddButton.onButtonTapped = this._addEventFromLib.bind(this, 0);
            }

            if (this.actionLibButtonElm.length) {
                this.actionLibButton = new GUI.LxButton(this, this.actionLibButtonElm[0], null, null, false);
                this.addToHandledSubviews(this.actionLibButton);
                this.actionLibButton.onButtonTapped = this._addActionFromLib.bind(this);
            }

            if (this.actionTaskRecorderButtonElm.length) {
                this.actionTaskRecorderButton = new GUI.LxButton(this, this.actionTaskRecorderButtonElm[0], null, null, false);
                this.addToHandledSubviews(this.actionTaskRecorderButton);

                this.actionTaskRecorderButton.onButtonTapped = function () {
                    AutomaticDesignerComponent.recordActions();
                };
            }
        }

        _setValidity(valid, key) {
            if (key) {
                this._validityMap[key] = valid;
            }

            valid = Object.values(this._validityMap).reduce(function (left, right) {
                return left && right;
            }, true);
            this.getTitleBar().buttons.rightSideButton.setEnabled(valid);
            this._ruleIsValid = valid;
        }

        _getTableContent() {
            var tableContent = [];

            if (this.currentEditRule.locked) {
                tableContent.pushObject(this._getHeaderSection());
                tableContent.pushObject(this._getRuleTitleSection());
                tableContent.pushObject(this._getRuleDescriptionSection());
            } else {
                tableContent.pushObject(this._getHeaderSection());
                tableContent.pushObject(this._getEditRuleEventsSection());
                tableContent.pushObject(this._getEditRuleActionsSection());
                tableContent.pushObject(this._getRuleSettingsSection());
                tableContent.pushObject(this._getRuleTitleSection());
                tableContent.pushObject(this._getRuleDescriptionSection());
                tableContent.pushObject(this._getRuleRoomCatSection());
                tableContent.pushObject(this._getRuleNotifyOnExecSection());
                tableContent.pushObject(this._getDeleteRuleSection());
            }

            return tableContent;
        }

        _getHeaderSection() {
            if (this.currentEditRule.locked) {
                return {
                    footerElement: AutomaticDesignerAddOrEditRule.Template.lockedFooterElement(this._getLockTexts())
                };
            }
        }

        _getLockTexts() {
            return [_("automatic-designer.rule.missing-permission.1"), _("automatic-designer.rule.missing-permission.2")];
        }

        _getRuleSettingsSection() {
            if (this.isInEditMode) {
                return {
                    headerStrongTitle: _('settings'),
                    rows: [{
                        type: GUI.TableViewV2.CellType.SWITCH,
                        content: {
                            title: _("automatic-designer.rule.actions.activate"),
                            active: this.currentEditRule.active
                        },
                        onSwitchChanged: function onSwitchChanged(value, section, row, tableView, cell) {
                            this.currentEditRule.active = value;
                        }.bind(this)
                    }]
                };
            }
        }

        _getRuleTitleSection() {
            if (this.isSaveScreen || this.isInEditMode) {
                this._setValidity(!!nullEmptyString(this.currentEditRule.name), "name");

                return {
                    headerTitle: _('autopilot.rule.title'),
                    rows: [{
                        type: GUI.TableViewV2.CellType.INPUT,
                        content: {
                            value: this.currentEditRule.name,
                            autoFocus: !this.currentEditRule.name,
                            required: true,
                            disabled: this.currentEditRule.locked
                        },
                        textChanged: function textChanged(section, row, tableView, value, valid) {
                            if (valid) {
                                this.currentEditRule.name = value;
                                AutomaticDesignerComponent.setRuleTitle(value);
                            }

                            this._setValidity(valid, "name");
                        }.bind(this)
                    }]
                };
            }
        }

        _getRuleDescriptionSection() {
            if (this.isSaveScreen || this.isInEditMode) {
                return {
                    headerTitle: _('automatic-designer.info-text'),
                    rows: [{
                        type: GUI.TableViewV2.CellType.INPUT,
                        content: {
                            multiline: true,
                            value: this.currentEditRule.description,
                            disabled: this.currentEditRule.locked
                        },
                        textChanged: function textChanged(section, row, tableView, value) {
                            AutomaticDesignerComponent.setRuleDescription(value);
                        }.bind(this)
                    }]
                };
            }
        }

        _getRuleRoomCatSection() {
            if (this.isSaveScreen || this.isInEditMode && Feature.AUTOMATIC_DESIGNER_RULES_ADDITIONAL_PROPS) {
                this.details.rule.visu = this.details.rule.visu || {};
                var currentRoom = ActiveMSComponent.getStructureManager().getGroupByUUID(this.details.rule.visu.room),
                    currentCat = ActiveMSComponent.getStructureManager().getGroupByUUID(this.details.rule.visu.cat);
                return {
                    rows: [{
                        content: {
                            title: ActiveMSComponent.getStructureManager().getCustomGroupTitles()[GroupTypes.ROOM],
                            disclosureText: currentRoom ? currentRoom.name : null,
                            clickable: true,
                            class: "base-cell--with-chevron"
                        },
                        action: function () {
                            this._selectFromDataSetWithTitle(Object.values(ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.ROOM, true)).map(function (group) {
                                return {
                                    id: group.uuid,
                                    name: group.name
                                };
                            }), ActiveMSComponent.getStructureManager().getCustomGroupTitles()[GroupTypes.ROOM], "room");
                        }.bind(this)
                    }, {
                        content: {
                            title: ActiveMSComponent.getStructureManager().getCustomGroupTitles()[GroupTypes.CATEGORY],
                            disclosureText: currentCat ? currentCat.name : null,
                            clickable: true,
                            class: "base-cell--with-chevron"
                        },
                        action: function () {
                            this._selectFromDataSetWithTitle(Object.values(ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.CATEGORY, true)).map(function (group) {
                                return {
                                    id: group.uuid,
                                    name: group.name
                                };
                            }), ActiveMSComponent.getStructureManager().getCustomGroupTitles()[GroupTypes.CATEGORY], "cat");
                        }.bind(this)
                    }]
                };
            } else {
                return null;
            }
        }

        _getRuleNotifyOnExecSection() {
            if (this.isSaveScreen || this.isInEditMode && Feature.AUTOMATIC_DESIGNER_RULES_ADDITIONAL_PROPS) {
                return {
                    rows: [{
                        type: GUI.TableViewV2.CellType.SWITCH,
                        content: {
                            title: _("automatic-designer.rule.notify-on-exec.title"),
                            active: hasBit(this.currentEditRule.ruleFlags, AutomaticDesignerEnums.RULE_FLAGS.NOTIFY_ON_EXEC)
                        },
                        onSwitchChanged: function onSwitchChanged(active, section, row, tableView, cell) {
                            if (active) {
                                this.currentEditRule.ruleFlags = (this.currentEditRule.ruleFlags || 0) | AutomaticDesignerEnums.RULE_FLAGS.NOTIFY_ON_EXEC;
                            } else {
                                this.currentEditRule.ruleFlags -= AutomaticDesignerEnums.RULE_FLAGS.NOTIFY_ON_EXEC;
                            }
                        }.bind(this)
                    }],
                    footerTitle: _("automatic-designer.rule.notify-on-exec.desc")
                };
            } else {
                return null;
            }
        }

        _getEditRuleEventsSection(editMode) {
            if (this.isSaveScreen) {
                return null;
            }

            var eventGroups = AutomaticDesignerComponent.getCurrentEditRuleEventGroups(),
                section = {
                    headerStrongTitle: _('automatic-designer.rule.events.name_plural'),
                    rows: []
                },
                spacerCell = {
                    type: GUI.TableViewV2.CellType.LIGHT_TEXT,
                    content: {
                        title: NBR_SPACE,
                        heightOverride: "16px"
                    }
                };

            if (!this.isInEditMode) {
                section.headerDescription = _("automatic-designer.rule.events.desc");
            }

            if (eventGroups && eventGroups.length) {
                eventGroups.forEach(function (eventGroup, groupIdx) {
                    if (groupIdx === 0) {
                        section.rows.push({
                            content: {
                                title: _('automatic-designer.when').toUpperCase(),
                                heightOverride: "48px"
                            }
                        });
                    } else if (eventGroup.events && eventGroup.events.length) {
                        section.rows.push({
                            content: {
                                title: _('automatic-designer.or-when').toUpperCase(),
                                heightOverride: "48px"
                            }
                        });
                    }

                    if (eventGroup.events && eventGroup.events.length) {
                        section.rows.push({
                            type: this._getAutomaticDesignerContainerCellType(),
                            content: {
                                type: AutomaticDesignerEnums.SCREEN_TYPES.EVENTS,
                                groupIdx: groupIdx,
                                capabilities: eventGroup.events,
                                draggable: this._canDragCapabilities()
                            }
                        });
                        section.rows.push({
                            content: {
                                title: _('and').toUpperCase(),
                                clickable: true,
                                leftIconSrc: Icon.TRANSLUCENT_ADD,
                                titleColor: Color.TEXT_SECONDARY_B,
                                leftIconColor: window.Styles.colors.green,
                                leftIconSize: GUI.TableViewV2.Enums.GeneralCell.IconSize.SMALL,
                                heightOverride: "48px",
                                class: "general-cell__inset"
                            },
                            action: this._addEventFromLib.bind(this, groupIdx)
                        });
                    }

                    section.rows.push(spacerCell);
                }.bind(this));
                section.rows.push({
                    content: {
                        title: _('automatic-designer.or-when').toUpperCase(),
                        heightOverride: "48px",
                        clickable: true,
                        leftIconSrc: Icon.TRANSLUCENT_ADD,
                        titleColor: Color.TEXT_SECONDARY_B,
                        leftIconColor: window.Styles.colors.green,
                        leftIconSize: GUI.TableViewV2.Enums.GeneralCell.IconSize.SMALL
                    },
                    action: this._addEventFromLib.bind(this, eventGroups.length)
                });

                this._setValidity(true, "events");
            } else {
                section.footerElement = AutomaticDesignerAddOrEditRule.Template.getEventMenuListTemplate();

                this._setValidity(false, "events");
            }

            return section;
        }

        _getEditRuleActionsSection(editMode) {
            if (this.isSaveScreen) {
                return null;
            }

            var actions = this._getActions(),
                headerDesc;

            if (!this.isInEditMode && !this.isScene) {
                headerDesc = _("automatic-designer.rule.actions.desc");
            }

            if (actions.length) {
                this._setValidity(true, "actions");

                return {
                    headerStrongTitle: _('automatic-designer.rule.actions.title_plural'),
                    headerDescription: headerDesc,
                    rows: [{
                        type: this._getAutomaticDesignerContainerCellType(),
                        content: {
                            type: AutomaticDesignerEnums.SCREEN_TYPES.ACTIONS,
                            groupIdx: 0,
                            capabilities: actions,
                            draggable: this._canDragCapabilities()
                        }
                    }].concat(this._getAddActions())
                };
            } else {
                this._setValidity(false, "actions");

                return {
                    headerStrongTitle: _('automatic-designer.rule.actions.title_plural'),
                    headerDescription: headerDesc,
                    rows: [],
                    footerElement: AutomaticDesignerAddOrEditRule.Template.getActionMenuListTemplate()
                };
            }
        }

        _getActions() {
            return AutomaticDesignerComponent.getCurrentRuleActions();
        }

        _canDragCapabilities() {
            return true;
        }

        _getDeleteRuleSection() {
            if (this.isInEditMode) {
                return {
                    rows: [{
                        type: GUI.TableViewV2.CellType.DELETE,
                        content: {
                            iconSrc: Icon.DELETE_2,
                            title: _("automatic-designer.rule.delete")
                        },
                        action: this._deleteRule.bind(this, false)
                    }]
                };
            }
        }

        _getAddActions() {
            var additionalActions = [{
                content: {
                    title: _('automatic-designer.rule.actions.add'),
                    clickable: true,
                    leftIconSrc: Icon.TRANSLUCENT_ADD,
                    titleColor: Color.TEXT_SECONDARY_B,
                    leftIconColor: window.Styles.colors.green,
                    leftIconSize: GUI.TableViewV2.Enums.GeneralCell.IconSize.SMALL,
                    heightOverride: "48px"
                },
                action: this._addActionFromLib.bind(this)
            }];

            if (Feature.AUTOMATIC_DESIGNER_TASK_RECORDER) {
                additionalActions.push({
                    content: {
                        title: _('automatic-designer.task-recorder.record-action.title'),
                        clickable: true,
                        leftIconSrc: Icon.TaskRecorderV2.RECORD,
                        titleColor: Color.TEXT_SECONDARY_B,
                        leftIconSize: GUI.TableViewV2.Enums.GeneralCell.IconSize.SMALL,
                        heightOverride: "48px"
                    },
                    action: function () {
                        AutomaticDesignerComponent.recordActions();
                    }
                });
            }

            return additionalActions;
        }

        _getLockedSection() {
            return {
                footerTitle: _("automatic-designer.rule.missing-permission")
            };
        }

        _addEventFromLib(groupIdx) {
            var details = {
                navigationPath: AutomaticDesignerEnums.SCREEN_TYPES.EVENTS,
                screenType: AutomaticDesignerEnums.SCREEN_TYPES.EVENTS
            };

            if (this.filterControls && this.filterControls[0] && this.filterControls[0].hasAutomaticDesignerCapabilities(details.screenType)) {
                details.filterControls = this.filterControls;
            }

            AutomaticDesignerComponent.setCurrentEditSectionIndex(groupIdx);
            NavigationComp.showState(ScreenState.AutomaticDesigner.RuleMenuViewController, details);
        }

        _addActionFromLib() {
            var details = {
                navigationPath: AutomaticDesignerEnums.SCREEN_TYPES.ACTIONS,
                screenType: AutomaticDesignerEnums.SCREEN_TYPES.ACTIONS
            };

            if (this.filterControls && this.filterControls[0] && this.filterControls[0].hasAutomaticDesignerCapabilities(details.screenType)) {
                details.filterControls = this.filterControls;
            }

            NavigationComp.showState(ScreenState.AutomaticDesigner.RuleMenuViewController, details);
        }

        _deleteRule(noNav) {
            return NavigationComp.showWaitingFor(AutomaticDesignerComponent.deleteRule(this.currentEditRule, this.isScene)).then(function () {
                !noNav && this.ViewController.navigateBack();
            }.bind(this));
        }

        _selectFromDataSetWithTitle(dataSet, title, visuKey) {
            this.currentEditRule.visu = this.currentEditRule.visu || {};
            var currentId = this.currentEditRule.visu[visuKey],
                def = Q.defer(),
                details = {
                    options: dataSet.map(function (data) {
                        return {
                            title: data.name,
                            selected: data.id === currentId,
                            rightIconSrc: data.iconSrc,
                            clickable: true,
                            ogObj: data
                        };
                    }),
                    title: title,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                    mode: GUI.SelectorScreenMode.QUICK,
                    deferred: def
                };
            return NavigationComp.showSelector(details).then(function (results) {
                this.currentEditRule.visu[visuKey] = results[0].option.ogObj.id;
                return this.reloadTable();
            }.bind(this));
        }

        _getAutomaticDesignerContainerCellType() {
            return GUI.TableViewV2.CellType.Special.AUTOMATIC_DESIGNER_CONTAINER_CELL;
        }

    };
});
