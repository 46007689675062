'use strict';

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.MS_MENU_CELL = "MsMenuCell";

    class MsMenuCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getCellContainer = function getCellContainer() {
                return $('<div class="ms-menu-cell"/>');
            };

            return {
                getCellContainer: getCellContainer
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);

            var generatedSvg = this._generateMsSvg(this.content.miniserver);

            this.contentPlaceholder.append(MsMenuCell.Template.getCellContainer());
            this.element.find('.ms-menu-cell').html(generatedSvg);
        }

        _generateMsSvg(ms) {
            return generateValueSvgFromString(ms.msName.substring(0, 3));
        }

    }

    GUI.TableViewV2.Cells.MsMenuCell = MsMenuCell;
    return GUI;
}(window.GUI || {});
