'use strict';

define(["AudioZoneControlEnums", "MediaBrowserService"], function (AudioZoneControlEnums, MediaBrowserService) {
    class MediaBrowserService12 extends MediaBrowserService {
        //region Static
        static isRadio() {
            return Controls.AudioZoneControl.MediaBrowserService.isRadio.apply(this, arguments);
        }

        static getCellFromContentTypeItem() {
            return Controls.AudioZoneControl.MediaBrowserService.getCellFromContentTypeItem.apply(this, arguments);
        }

        static getCellButtonIconSrc() {
            return Controls.AudioZoneControl.MediaBrowserService.getCellButtonIconSrc.apply(this, arguments);
        }

        static getCellTypeForItemCellAtIndex() {
            return Controls.AudioZoneControl.MediaBrowserService.getCellTypeForItemCellAtIndex.apply(this, arguments);
        }

        static getIconObjForItem() {
            return Controls.AudioZoneControl.MediaBrowserService.getIconObjForItem.apply(this, arguments);
        } //endregion Static


        constructor(details) {
            super(...arguments);
        }

        getActionsForCtxAndItem(ctx, item) {
            var actions = super.getActionsForCtxAndItem(...arguments);

            switch (ctx) {
                case this.CONTEXT.SPOTIFY:
                    if (item === this) {
                        actions.pushObject(this._getActionWithTmplForCtx({
                            content: {
                                title: _("media.playlist.unfollow"),
                                leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_UNFOLLOW
                            },
                            action: this._unfollowPlaylist.bind(this, item.lastSelectedItem)
                        }, ctx));
                    }

                    break;
            }

            return actions;
        }

        _unfollowPlaylist(playlist) {
            return MediaServerComp.deleteContainer(playlist.contentType, this.getMediaInfo(playlist.contentType), playlist[MediaEnum.Event.ID]).then(function () {
                return this.ViewController.navigateBack();
            }.bind(this));
        }

    }

    // FileType = 12 = FOLLOWED_PLAYLIST
    Controls.AudioZoneControl.MediaBrowserService12 = MediaBrowserService12;
    return Controls.AudioZoneControl.MediaBrowserService12;
});
