'use strict';

window.GUI = function (GUI) {
    class MediaCell extends GUI.TableViewV2.Cells.EditableBaseCell {
        constructor() {
            super(...arguments);
            this.element.addClass("media-cell-v2");
        }

        viewDidLoad() {
            super.viewDidLoad();
            var title;

            if (this.content.hasOwnProperty("title")) {
                title = this.content["title"];
            } else {
                if (this.content.hasOwnProperty(MediaEnum.Event.TITLE)) {
                    title = this.content[MediaEnum.Event.TITLE];
                } else if (this.content.hasOwnProperty(MediaEnum.Event.NAME)) {
                    title = this.content[MediaEnum.Event.NAME];
                }
            }

            this.hasDisclosureIcon = this.content.hasOwnProperty("disclosureIcon") && this.content.disclosureIcon;
            var subtitle;

            if (this.content.hasOwnProperty("subtitle")) {
                subtitle = this.content["subtitle"];
            } else {
                if (this.content.hasOwnProperty(MediaEnum.Event.ARTIST)) {
                    subtitle = this.content[MediaEnum.Event.ARTIST];
                }

                if (this.content.hasOwnProperty(MediaEnum.Event.ALBUM)) {
                    if (subtitle) {
                        subtitle += " (" + this.content[MediaEnum.Event.ALBUM] + ")";
                    } else {
                        subtitle = this.content[MediaEnum.Event.ALBUM];
                    }
                }
            }

            if (!this.elements) {
                this.elements = {};
            }

            this.hasDetailButton = false;

            if (this.content.hasOwnProperty("hasDetailButton")) {
                this.hasDetailButton = this.content["hasDetailButton"];
            }

            this.contentPlaceholder.append(GUI.TableViewV2.Cells.MediaCell.Template.getMediaCellContent(title, subtitle, this.hasDetailButton, this.hasDisclosureIcon, !!this.content.showLoadingIndicator));
            this.elements.detailButton = this.contentPlaceholder.find('.media-cell__right-icon-placeholder');
            this.elements.coverPlaceholder = this.contentPlaceholder.find('.media-cell__left-icon-placeholder');
            this.elements.coverView = new Controls.AudioZoneControl.CoverView(this.content);
            this.elements.coverView.element.addClass("left-icon-placeholder__icon");
            this.appendSubview(this.elements.coverView, this.elements.coverPlaceholder);

            if (this.hasDetailButton) {
                this.buttons.detailButton = new GUI.LxButton(this, this.elements.detailButton[0], Color.BUTTON_GLOW);
                this.addToHandledSubviews(this.buttons.detailButton);
            } else {
                this.buttons.detailButton = null;
            }

            if (this.content.hasOwnProperty("isPlaying") && this.content["isPlaying"]) {
                this.element.addClass("active");
            }

            this.elements.disclosureIcon = this.contentPlaceholder.find(".name__disclosure-icon");
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);

            if (this.hasDetailButton) {
                this.buttons.detailButton.onButtonTapped = this._handleDetailButton.bind(this);
            }
        }

        viewWillDisappear() {
            super.viewWillDisappear(...arguments);

            if (this.hasDetailButton) {
                this.buttons.detailButton.onButtonTapped = null;
            }
        }

        clickableAllowed() {
            return !this.editMode;
        }

        startEditMode() {
            super.startEditMode(...arguments);
            this.hasDetailButton && this.elements.detailButton.hide();
            this.hasDisclosureIcon && this.elements.disclosureIcon.hide();
        }

        stopEditMode() {
            super.stopEditMode();
            this.hasDetailButton && this.elements.detailButton.show();
            this.hasDisclosureIcon && this.elements.disclosureIcon.show();
        }

        _handleDetailButton() {
            this.delegate.onMediaDetailButtonTapped.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
        }

    }

    GUI.TableViewV2.Cells.MediaCell = MediaCell;

    GUI.TableViewV2.Cells.MediaCell.Template = function () {
        var getMediaCellContent = function getMediaCellContent(title, subtitle, hasDetailButton, hasDisclosureIcon, showLoadingIndicator) {
            var icon = '<div class="media-cell__left-icon-placeholder"></div>';
            var infoTag = '';

            if (subtitle && subtitle !== '') {
                infoTag = '<div class="texts-placeholder__info">' + subtitle + '</div>';
            }

            var detailButton = '';

            if (showLoadingIndicator) {
                detailButton = '<div class="media-cell__right-icon-placeholder">' + '<div class="right-icon-placeholder__spinner"></div>' + '</div>';
            } else if (hasDetailButton) {
                detailButton = '<div class="media-cell__right-icon-placeholder">' + ImageBox.getResourceImageWithClasses("resources/Images/Controls/AudioZone/button-more-title-bar.svg", "right-icon-placeholder__icon") + '</div>';
            }

            var disclosureIcon = '';

            if (hasDisclosureIcon) {
                disclosureIcon = ImageBox.getResourceImageWithClasses('resources/Images/General/disclosure-icon.svg', 'name__disclosure-icon');
            }

            return icon + '<div class="media-cell__texts-placeholder">' + '<div class="texts-placeholder__name">' + '<div class="name__text">' + title + '</div>' + disclosureIcon + '</div>' + infoTag + '</div>' + detailButton;
        };

        return {
            getMediaCellContent: getMediaCellContent
        };
    }();

    GUI.TableViewV2.CellType.Special.MEDIA = "MediaCell";
    return GUI;
}(window.GUI || {});
