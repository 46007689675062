'use strict';

/**
 * helper which adds a ripple effect to an element (-> button)
 * emits 2 events:
 * GUI.LxRippleButton.CLICK_EVENT       (= ripple starts)
 * GUI.LxRippleButton.CLICK_END_EVENT   (= when ripple effect is over)
 */

class LxRippleButton extends GUI.View {
    constructor(element) {
        super(element);
        Object.assign(this, RippleContainer.Mixin);
        this.enabled = true;
        this.setRippleEnabled(true);
    }

    viewDidAppear() {
        super.viewDidAppear(...arguments); // Unregister due to livecycle querks when multiple "addToHandledSubviews" are called!

        this._unregisterHandler();

        this.enabled && this._registerHandler();
    }

    viewWillDisappear() {
        super.viewWillDisappear(...arguments);
        this.stopRipple();
    }

    viewDidDisappear() {
        this._unregisterHandler();

        super.viewDidDisappear(...arguments);
    }

    // RippleContainer
    onRippleEnd() {
        this.emit(GUI.LxRippleButton.CLICK_END_EVENT);
    }

    _handleTap(e) {
        // prevent other actions (eg. a notification click, when this button is used on a notification)
        e.preventDefault();
        e.stopPropagation();
        this.startHammerRipple(e);
        this.emit(GUI.LxRippleButton.CLICK_EVENT, e);
    }

    setEnabled(enabled) {
        if (this.enabled === enabled) {
            return;
        }

        this.enabled = enabled;

        if (this.enabled && this.isVisible()) {
            this._registerHandler();
        } else if (!this.enabled) {
            this._unregisterHandler();
        }

        this.element.toggleClass("disabled", !this.enabled);
    }

    _registerHandler() {
        // Don't use hammer, it will prevent any vertical scrolling, which is useful in the favorite selector in the LCv2
        this.element && this.element.on("click", this._handleTap.bind(this));
    }

    _unregisterHandler() {
        this.element && this.element.off('click');
    }

}

GUI.LxRippleButton = LxRippleButton;
GUI.LxRippleButton.CLICK_EVENT = "click";
GUI.LxRippleButton.CLICK_END_EVENT = "click-end";
