define("GateControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class GateControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            var cmds = [
                this.createDeactivatable(_('close'), states.isMoving ? Commands.GATE.STOP : Commands.GATE.CLOSE, null, states.preventClose, { rowIdx: 0 }),
                this.createDeactivatable(_('open'), states.isMoving ? Commands.GATE.STOP : Commands.GATE.OPEN, null, states.preventOpen, { rowIdx: 0 })
            ];

            if (Feature.GATE_PARTIAL_OPEN) {
                cmds.push(this.createDeactivatable(
                    _("controls.gate.open-partially"),
                    Commands.GATE.PARTIAL_OPEN,
                    null,
                    states.preventOpen || states.preventClose,
                    { rowIdx: 1 }
                ));
            }
            return cmds;
        }

    };
});
