import {View} from 'react-native';
import PropTypes from "prop-types";
import {
    LxReactPressable,
    LxReactText,
    ReactWrapper
} from "LxComponents";
import globalStyles from "GlobalStyles";
import {Subject} from 'rxjs';
import {useCallback, useState} from "react";
import Tooltip from 'react-native-walkthrough-tooltip';

function LxReactMenuController(props) {

    const [menuVisible, setMenuVisible] = useState(false);

    const hide = useCallback(() => {
        setMenuVisible(false);
    }, []);

    const show = useCallback(() => {
        setMenuVisible(true);
    }, [])

    const showIfCopyable = useCallback(() => {
        if (props.copyableOnPress) {
            show();
        }
    }, [props.copyableOnPress])

    const onOptionPressed = useCallback(idx => {
        hide();
        props.onPress(idx);
    }, [props.onPress]);

    const ActionText = ({actionText}) => {
        return (
            <LxReactText
                numberOfLines={1}
                ellipsizeMode={"clip"}
                style={styles.action.text}
            >{actionText}</LxReactText>
        )
    }

    const Action = ({action}) => {
        if (typeof action === "string") {
            return <ActionText actionText={action} />
        } else if (typeof action === "object" && action.text) {
            return <View style={styles.action.container}>
            {
                action.iconComp ? ReactWrapper.React.createElement(action.iconComp, {
                    fill: globalStyles.colors.text.primary,
                    style: styles.action.icon
                }) : null
            }
            <ActionText actionText={action.text} />
        </View>
        }
    }

    let content = (
        <View>
            {props.actions.map((action, idx) => {
                return (
                    <LxReactPressable
                        style={styles.action.pressable}
                        onPress={onOptionPressed}
                        onPressArgs={[idx]}
                        key={idx.toString()}
                        pkey={idx.toString()}
                    > <Action action={action} /> </LxReactPressable>
                )
            })}
        </View>
    )

    return (
        <View style={props.style}>
            <Tooltip
                isVisible={menuVisible}
                content={content}
                placement="top"
                onClose={hide}
                contentStyle={styles.tooltip.container}
            >
                <LxReactPressable pkey={"content"}
                                  style={styles.tooltip.pressable}
                                  onPress={showIfCopyable}
                                  onLongPress={show}>
                    {props.children}
                </LxReactPressable>
            </Tooltip>
        </View>
    );
}

const styles = {
    tooltip: {
        container: {
            backgroundColor: globalStyles.colors.grey["500"],
            padding: 0
        },
        pressable: {
            maxWidth: "100%"
        }
    },
    action: {
        text: {
            ...globalStyles.textStyles.footNote.default,
            color: globalStyles.colors.text.primary,
            textOverflow: "clip",
        },
        icon: {
            marginRight: globalStyles.spacings.gaps.small
        },
        container: {
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
        },
        pressable: {
            justifyContent: "center",
            alignItems: "center",
            marginTop: globalStyles.spacings.gaps.smaller,
            marginBottom: globalStyles.spacings.gaps.smaller,
            marginLeft: globalStyles.spacings.gaps.smaller,
            marginRight: globalStyles.spacings.gaps.smallRegular,
        }
    }
}

export default LxReactMenuController;

LxReactMenuController.propTypes = {
    style: PropTypes.object,
    actions: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            iconComp: PropTypes.func,
            text: PropTypes.string.isRequired
        })
    ])).isRequired,
    onPress: PropTypes.func.isRequired,
    children: PropTypes.node,
};

LxReactMenuController.Channel = new Subject();
