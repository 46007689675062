import globalStyles from "GlobalStyles";
import { ControlContext } from "LxComponents";
import { StyleSheet, View } from "react-native";
import { useContext } from "react";
import {LxReactLinkText} from "../../../react-comps/Components";


export default function AcControlStateText({}) {
    const { control, states } = useContext(ControlContext)

    return (<View style={styles.overview}>
            <LxReactLinkText
                originalString={states.stateTextForContent}
                identifiers={[
                    {
                        name: control.parentClimateController?.getName(),
                        style: styles.goToText,
                        text: control.parentClimateController?.getName(),
                        onPress: () => {
                            NavigationComp.showControlContent(control.parentClimateController);
                        }
                    },{
                        name: control.parentIRC?.getName(),
                        style: styles.goToText,
                        text: control.parentIRC?.getName(),
                        onPress: () => {
                            NavigationComp.showControlContent(control.parentIRC);
                        }
                    },
                ]}
                textStyle={[styles.text , {
                    color: states.stateTextColor || states.stateTintColor || globalStyles.colors.text.secondary
                }] }/>
        </View>)
}

const styles = StyleSheet.create({
    overview: {
        width: "100%",
        flexDirection: "row",
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        textAlignVertical: 'center'
    },
    text: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.secondary
    },
    goToText: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.brand
    }
})
