'use strict';

/**
 * @param details   an object containing all the important infos for this screen.
 *      title:      the name for the selector screen, shown in the titlebar
 *      options:    an array of options to pick from. At most one is selected (boolean flag). title & subtitle are passed along.
 *      deferred:   when an option was picked, it will resolve the deferred with the index of the option.
 *      mode:       how the screen will respond to selection changes. Default: GUI.SelectorScreenMode.Normal
 *      isNewStyle: weather or not to use the new houseMode style
 *      emptyConfig: The emptyConfig if no options to select are available
 */

class SelectorScreen extends GUI.TableViewScreenV2 {
    constructor(details) {
        super($('<div class="selector-screen" />'));
        this.deferred = details.deferred;
        this.options = details.options;
        this.title = details.title;
        this.headerTitle = details.headerTitle;
        this.headerDescription = details.headerDescription;
        this.footerTitle = details.footerTitle;
        this.mode = details.mode ? details.mode : GUI.SelectorScreenMode.NORMAL;
        this.radioMode = details.radioMode ? details.radioMode : GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED;

        if (details.didRequestCheckingCell) {
            this.didRequestCheckingCell = details.didRequestCheckingCell;
        }

        if (details.hasOwnProperty("emptyConfig")) {
            // Add this function only if an emptyConfig is applied
            this.emptyViewConfig = function emptyViewConfig() {
                return details.emptyConfig;
            };
        }
    }

    viewDidLoad() {
        this.createContent(); // create the content now, because the base class will reload the table in viewDidLoad!

        return super.viewDidLoad(...arguments);
    }

    getAnimation() {
        return AnimationType.MODAL;
    }

    getURL() {
        return "SelectorScreen";
    }

    titleBarText() {
        return this.title;
    }

    titleBarConfig() {
        var cfg = {};

        if (this.mode === GUI.SelectorScreenMode.CONFIRMED) {
            cfg.leftSide = TitleBarCfg.Button.CLOSE;
            cfg.rightSide = TitleBarCfg.Button.TICK;
        } else {
            cfg.leftSide = TitleBarCfg.Button.CLOSE;
        }

        return cfg;
    }

    titleBarActionRight() {
        this._resolveWithSelectedRows();

        this.ViewController.navigateBack();
    }

    titleBarAction() {
        if (this.mode === GUI.SelectorScreenMode.CONFIRMED) {
            this.deferred.reject();
        } else {
            this._resolveWithSelectedRows();
        }

        this.ViewController.navigateBack();
    }

    createContent() {
        var optionRows = this.options.map(this._createOptionCell.bind(this));

        if (optionRows.length) {
            this.tableContent = [{
                rows: optionRows
            }];

            if (typeof this.headerTitle === "string") {
                this.tableContent[0].headerTitle = this.headerTitle;
            }

            if (typeof this.headerDescription === "string") {
                this.tableContent[0].headerDescription = this.headerDescription;
            }

            if (typeof this.footerTitle === "string") {
                this.tableContent[0].footerTitle = this.footerTitle;
            }
        }
    }

    _createOptionCell(option) {
        var cell = {};
        cell.type = GUI.TableViewV2.CellType.CHECKABLE;
        cell.content = cloneObject(option);
        cell.content.radioMode = this.radioMode;
        return cell;
    }

    didCheckCell(cell, section, row, tableView, selected) {
        if (this.mode === GUI.SelectorScreenMode.QUICK) {
            this._resolveWithSelectedRows();

            this.ViewController.navigateBack();
        } else {
            this._notifyWithRow(row);
        }
    }

    _resolveWithSelectedRows() {
        let result = this._getSelectedCells().map(function (cell) {
            return {
                row: cell.rowIdx,
                option: this.options[cell.rowIdx]
            };
        }.bind(this));

        if (result && result.length) {
            this.deferred.resolve(result);
        } else {
            this.deferred.reject();
        }
    }

    _getSelectedCells() {
        if (this.tableView.table[0]) {
            return this.tableView.table[0].filter(function (cell) {
                return cell.content.selected;
            });
        } else {
            return [];
        }
    }

    _notifyWithRow(row) {
        if (row || row === 0) {
            // 0 is a valid row!
            this.deferred.notify({
                row: row,
                option: this.options[row]
            });
        }
    }
}

GUI.SelectorScreen = SelectorScreen;
/**
 * Specifies how the selector will respond.
 * @type {{QUICK: string, NORMAL: string, CONFIRMED: string}}
 */

GUI.SelectorScreenMode = {
    QUICK: "Quick",
    // The deferred resolves after one item has been selected.
    NORMAL: "Normal",
    // The user can pick an option. The deferred is not resolved until the view is dismissed using the titlebar, also the promises notify function is called when selecting a cell
    CONFIRMED: "Confirmed" // The deferred will only resolve if the tickmark is used to confirm the selection, also the promises notify function is called when selecting a cell

};
