import {useContext, useMemo} from "react";
import {LxReactTableView, ControlContext} from "LxComponents";
import {View} from "react-native";
import {useNavigation} from "@react-navigation/native";
import globalStyles from "GlobalStyles";
import PropTypes from "prop-types";

function LxControlMoreSection (props) {
    const {control, states} = useContext(ControlContext);
    const navigation = useNavigation();

    const showStatistic = () => {
        let nr = 0;
        if (control.statistic && control.statistic.outputs && control.statistic.outputs[nr]) {
            nr = nr ? nr : 0;
            navigation.navigate(ScreenState.Statistic, {
                controlUUID: control.uuidAction,
                statisticOutputUUID: control.statistic.outputs[nr].uuid
            })
        }
    }

    const moreContent = useMemo(() => {
        let section =  control.getMoreSection && control.getMoreSection(states, navigation);

        if (!section || !section.rows) {
            section = {
                rows: []
            }
        }

        if (control.statistic && control.statistic.outputs && control.statistic.outputs.length) {
            section.rows.push({
                content: {
                    title: _("statistics"),
                    disclosureIcon: true,
                },
                action: showStatistic.bind(this)
            })
        }

        return section.rows.length ? [section] : null;
    }, [states])

    return <>
        {
            moreContent && <View style={styles.container}>
                <LxReactTableView styles={styles.tableView} tableContent={moreContent} />
            </View>
        }
    </>

}

const styles = {
    container: {
        width: "100%",
        marginBottom: globalStyles.spacings.gaps.bigMedium
    },
    tableView: {
        List: {
            paddingHorizontal: 0
        }
    }
}

export default LxControlMoreSection
