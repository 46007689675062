'use strict';

define(["NotificationsSettingsBaseScreen"], function (NotificationsSettingsBaseScreen) {
    return class InAppNotificationsSettingsScreen extends NotificationsSettingsBaseScreen {
        constructor() {
            super($('<div class="in-app-notifications-screen" />'));
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        getSettings() {
            return SandboxComponent.getInAppNotificationSettings();
        }

        getURL() {
            return UrlStartLocation.IN_APP_NOTIFICATIONS;
        }

        // Private methods
        reloadTable() {
            this.tableContent = []; // WARN: KEEP SECTIONS IN MIND WHEN MOVING STUFF AROUND! (cellForSectionAndRow)

            this.tableContent.push({
                rows: [{
                    type: GUI.TableViewV2.CellType.SWITCH,
                    content: {
                        title: _('notifications.activate-in-app-notifications'),
                        active: this._getSwitchState()
                    },
                    onSwitchChanged: function onSwitchChanged(value, section, row, tableView) {
                        this._enableNotifications(value);
                    }.bind(this)
                }, {
                    content: {
                        subtitle: _('notifications.in-app-notifications.help')
                    }
                }]
            });
            return super.reloadTable();
        }

        // Private Methods
        _enableNotifications(enabled) {
            if (enabled) {
                SandboxComponent.updateInAppNotificationSettings(); // enables with default settings
            } else {
                SandboxComponent.updateInAppNotificationSettings(false);
            }

            return SandboxComponent.getInAppNotificationSettings().then(function (settings) {
                this._settings = settings;
                this.reloadTable();
            }.bind(this));
        }

        _changeSetting(setting, enabled) {
            console.info("changing setting '" + setting + "' to " + enabled);
            this._settings[setting] = enabled;
            SandboxComponent.updateInAppNotificationSettings(this._settings);

            if (!this._getSwitchState()) {
                this._enableNotifications();

                this.reloadTable();
            }
        }

    };
});
