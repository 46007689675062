'use strict';

define("SwitchControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class SwitchControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [],
                cmdLabel,
                cmdAction,
                disabled = states.lockedOn;

            if (states.isActive) {
                cmdLabel = _("turn-off");
                cmdAction = Commands.SWITCH.OFF;
            } else {
                cmdLabel = _("turn-on");
                cmdAction = Commands.SWITCH.ON;
            }

            cmds.push(this.createDeactivatable(cmdLabel, cmdAction, null, disabled));

            return cmds;
        }

    };
});
