import {createStackNavigator} from "@react-navigation/stack";
import AmbientIntroductionScreen from "./AmbientIntroductionScreen";
import {NavigationContainer} from '@react-navigation/native';
import AmbientRoomSelector from "./AmbientRoomSelector";
import AmbientScreen from "../screens/AmbientScreen";
const AmbientOnboardingStack = createStackNavigator();


function AmbientOnboardingNavigator({hideNavigator}) {
    return <NavigationContainer
        documentTitle={defaultNavProps.title}
    >
        <AmbientOnboardingStack.Navigator initialRouteName={AmbientIntroductionScreen.name} >
            <AmbientOnboardingStack.Screen name={AmbientIntroductionScreen.name} component={AmbientIntroductionScreen} initialParams={{hideNavigator}} />
            <AmbientOnboardingStack.Screen name={AmbientRoomSelector.name} component={AmbientRoomSelector} initialParams={{hideNavigator}} />
            <AmbientOnboardingStack.Screen name={AmbientScreen.name} component={AmbientScreen} />
        </AmbientOnboardingStack.Navigator>
    </NavigationContainer>
}

const defaultNavProps = {
    title: {
        enabled: false
    }
}


export default AmbientOnboardingNavigator

export {
    AmbientOnboardingNavigator
}
