'use strict';

define(['CustomizationManager', 'AudioZoneV2ControlEnums', 'SpotifyAccountManager', "SoundsuitAccountManager", "MediaBrowserV2Favorites", "FavoritesManager", "HistoryManager", "OverviewContentLoader", 'LxComponents'], function (CustomizationManager, AudioZoneV2ControlEnums, SpotifyAccountManager, SoundsuitAccountManager, MediaBrowserV2Favorites, FavoritesManager, HistoryManager, OverviewContentLoader, {
    App, LxControlHistoryScreen
}) {
    Controls.AudioZoneV2Control.SingleTones = Controls.AudioZoneV2Control.SingleTones || {};

    if (Controls.AudioZoneV2Control.SingleTones.ControlContentMenu) {
        return Controls.AudioZoneV2Control.SingleTones.ControlContentMenu;
    } else {
        class ControlContentMenu {
            //region Static
            static SECTION_ID = {
                FAVS: "card-section--fav",
                HISTORY: "card-section--history",
                SPOTIFY_PLAYLISTS: "card-section--spotify-playlists"
            };
            static AUTO_RESOLVE_TIMEOUT = 150;

            static shared(control) {
                if (!control) {
                    console.warn("No control provided, try to deduce one!");

                    if (window.hasOwnProperty("AudioserverComp") && AudioserverComp.getActiveZoneControl()) {
                        control = AudioserverComp.getActiveZoneControl();
                    } else {
                        throw "No control available!";
                    }
                }

                this.__instances = this.__instances || {};

                if (!this.__instances.hasOwnProperty(control.uuidAction)) {
                    this.__instances[control.uuidAction] = new this(this, control);
                }

                return this.__instances[control.uuidAction];
            }

            static destroy(control) {
                if (control && this.__instances && this.__instances.hasOwnProperty(control.uuidAction)) {
                    this.__instances[control.uuidAction].destroy();

                    delete this.__instances;
                }
            } //endregion Static


            //region Setter
            set delegate(delegate) {
                delegate && this._delegates.pushIfNoDuplicate(delegate);

                // hack to ensure the spotify content is re-created when the view controller changes,
                // otherwise it'll just return the current promises data, which may be using the wrong
                // viewController in it's showState calls. This occurs when expanding the audio player
                // in the ambient mode. BG-I24170
                if (delegate && delegate.ViewController) {
                    if (this.__delVCtrlId && this.__delVCtrlId !== delegate.ViewController.viewId) {
                        Debug.Control.AudioZone.MenuHelper && console.warn(this.name, "delegate setter, viewController changed - reload spotify content! " + this.__delVCtrlId + " --> " + delegate.ViewController.viewId);
                        this.receivedServiceReloadEvent();
                    }
                    this.__delVCtrlId = delegate.ViewController.viewId;
                }
            } //endregion Setter


            constructor(initiator, control) {
                applyMixins(this, MediaStateHandlerV2.Mixin);

                if (!(initiator instanceof Function) && !Controls.AudioZoneV2Control.SingleTones.ControlContentMenu) {
                    throw "The ControlContentMenu is a Singletone, use it like that! -> ControlContentMenu.shared(this.control)";
                }

                this.name = "ControlContentMenu";
                Debug.Control.AudioZone.MenuHelper && console.log(this.name, "+CTOR");
                this.control = control;
                this._tableViews = [];
                this._delegates = [];
                this._activeKey = MusicServerEnum.ControlContentSectionId.START;

                this._registerForMediaServerReloadEvents(this.EVENT_TYPES.SERVICE);
            }

            destroy() {
                Debug.Control.AudioZone.MenuHelper && console.log(this.name, "-DESTROY");

                this._unregisterForMediaServerEvents(this.EVENT_TYPES.SERVICE); // Unregister any listeners here!

            }

            /**
             * Returns the this.ViewController.showState function arguments of the initial HD Detailed screen
             * according to the menu rating and enable state
             * @returns {*}
             */
            getInitialHDDetailedShowStateArgs() {
                Debug.Control.AudioZone.MenuHelper && console.log(this.name, "getInitialHDDetailedShowStateArgs");

                var def = Q.defer(),
                    firstItem,
                    showStateWrapper = function () {
                        var argsArr = Array.from(arguments);
                        argsArr[3] = AnimationType.NONE;
                        def.resolve(argsArr);
                    };

                return this.control.audioserverComp.connectionPromise().then(function () {
                    return CustomizationManager.shared(this.control).get().then(function (customizationMap) {
                        if (this._hasStartItems(customizationMap)) {
                            firstItem = {
                                key: MusicServerEnum.ControlContentSectionId.START
                            };
                        } else {
                            firstItem = sortArrByFields(Object.values(customizationMap[MusicServerEnum.ControlContentSectionId.MENU]).filter(function (item) {
                                return item.enabled && // Prevent specific actions from being triggered when opening the Player
                                    !CustomizationManager.isInputCustomizationKey(item.key) && item.key !== MusicServerEnum.ControlContentMenuId.ANNOUNCEMENT && item.key !== MusicServerEnum.ControlContentMenuId.GROUPS;
                            }.bind(this)), ["rating"])[0];
                        }

                        this._activeKey = firstItem.key;

                        this._getActionForCustomizationKey(firstItem.key, showStateWrapper)();

                        return def.promise;
                    }.bind(this));
                }.bind(this));
            }

            /**
             * Returns the tableView content of the AudioZone Menu
             * @param tableView
             * @param editMode
             * @param viewController
             * @param updateContentCallback a method to call when the data has changed after the promise resolved.
             * @returns {*}
             */
            getTableContent(tableView, editMode, viewController, updateContentCallback) {
                Debug.Control.AudioZone.MenuHelper && console.log(this.name, "getTableContent", getStackObj());

                var _isInAddMode = viewController instanceof GUI.AddMediaViewControllerV2Base,
                    showStateWrapper = this._getShowStateFunctionForVc(viewController),
                    clonedMap;

                this._tableViews.pushIfNoDuplicate(tableView);

                return CustomizationManager.shared(this.control).get().then(function getTableContentGotCustomizationManager(customizationMap) {
                    clonedMap = cloneObject(customizationMap);
                    this._tempTableContent = [];
                    this.tableContent = [];

                    if (editMode) {
                        this._tempTableContent.push({
                            sectionId: MusicServerEnum.ControlContentSectionId.START,
                            rows: sortArrByFields(Object.keys(clonedMap[MusicServerEnum.ControlContentSectionId.START]).map(function (customizationKey) {
                                return this._getRowForCustomizationKey(customizationKey, clonedMap[MusicServerEnum.ControlContentSectionId.START], showStateWrapper, _isInAddMode, viewController);
                            }.bind(this)), ["rows", "rating"])
                        });
                    } else {
                        if (!HD_APP) {
                            this._getStartTableContentForCustomizationMap(viewController, clonedMap, updateContentCallback).forEach(function _gotStartTableContentForCustomizationMap(section) {
                                this._tempTableContent.push(section);
                            }.bind(this));
                        } else {
                            if (this._hasStartItems(clonedMap)) {
                                clonedMap[MusicServerEnum.ControlContentSectionId.MENU][MusicServerEnum.ControlContentSectionId.START] = {
                                    rating: -1,
                                    enabled: true
                                };
                            }
                        }
                    }

                    this._tempTableContent.push(this._getContentSection(showStateWrapper, clonedMap[MusicServerEnum.ControlContentSectionId.MENU], _isInAddMode, viewController));

                    if (!_isInAddMode) {
                        this._tempTableContent.push(this._getSettingsSection(showStateWrapper));
                    }

                    return this._checkContentPromises(updateContentCallback).then(this._processAndFilterContent.bind(this));
                }.bind(this));
            }

            /**
             * Removes empty sections and updates the active class property
             * @param res
             * @returns {*}
             * @private
             */
            _processAndFilterContent(res) {
                Debug.Control.AudioZone.MenuHelper && console.log(this.name, "_processAndFilterContent: ", cloneObject(res));
                res = res.filter(function (section, secIdx) {
                    if (section.rows && section.rows.length || section.footer || section.footerTitle || section.footerElement) {
                        section.rows.forEach(function (row) {
                            if (row.customizationKey && row.customizationKey === this._activeKey) {
                                row.content.class = (row.content.class || "") + " active";
                            }
                        }.bind(this));
                        return true;
                    } else {
                        Debug.Control.AudioZone.MenuHelper && console.log(this.name, "   section " + secIdx + " filtered: ", cloneObject(section));
                        return false;
                    }
                }.bind(this));
                Debug.Control.AudioZone.MenuHelper && console.log(this.name, "   >> filtered: ", cloneObject(res));
                return res;
            }

            /**
             * Will ensure that all promises are either notified or resolved.
             * @param updateContentCallback
             * @returns {Q.Promise<unknown>}
             * @private
             */
            _checkContentPromises(updateContentCallback) {
                Debug.Control.AudioZone.MenuHelper && console.log(this.name, "_checkContentPromises");
                var contentReadyChecklist = [],
                    deferred = Q.defer(),
                    notifyResults = [];

                this._tempTableContent.forEach(function tableSectionPromiseCheck(section, secIdx) {
                    if (Q.isPromiseAlike(section)) {
                        contentReadyChecklist[secIdx] = false;
                        this.tableContent[secIdx] = {
                            title: _("loading"),
                            rows: []
                        };
                        Debug.Control.AudioZone.MenuHelper && console.log(this.name, "    - section " + secIdx + " is a promise");
                        section.then(function (resolved) {
                            // content promise resolved - check if modified from cached result
                            if (notifyResults[secIdx] !== JSON.stringify(resolved).hashCode()) {
                                Debug.Control.AudioZone.MenuHelper && console.log(this.name, "    - section " + secIdx + " promise RESOLVED", resolved);
                                this._tempTableContent[secIdx] = resolved;
                                this.tableContent[secIdx] = this._tempTableContent[secIdx];
                                contentReadyChecklist[secIdx] = true;

                                this._checkContentReady(contentReadyChecklist, deferred, this.tableContent, secIdx, updateContentCallback);
                            } else {
                                Debug.Control.AudioZone.MenuHelper && console.log(this.name, "    - section " + secIdx + " promise RESOLVED, but unchanged", resolved);
                            }
                        }.bind(this), function (error) {
                            // content promise returned an error
                            this._tempTableContent[secIdx] = {
                                title: _("error")
                            };
                            this.tableContent[secIdx] = this._tempTableContent[secIdx];
                            contentReadyChecklist[secIdx] = true;
                            Debug.Control.AudioZone.MenuHelper && console.log(this.name, "    - section " + secIdx + " promise FAILED", error);

                            this._checkContentReady(contentReadyChecklist, deferred, this.tableContent, secIdx, updateContentCallback);
                        }.bind(this), function (notify) {
                            notifyResults[secIdx] = JSON.stringify(notify).hashCode(); // cached content returned!

                            this._tempTableContent[secIdx] = notify;
                            this.tableContent[secIdx] = notify;
                            contentReadyChecklist[secIdx] = true;
                            Debug.Control.AudioZone.MenuHelper && console.log(this.name, "    - section " + secIdx + " promise NOTIFY (cached result)", notify);

                            this._checkContentReady(contentReadyChecklist, deferred, this.tableContent, secIdx, updateContentCallback);
                        }.bind(this));
                    } else {
                        Debug.Control.AudioZone.MenuHelper && console.log(this.name, "    - section " + secIdx + " is ready");
                        this.tableContent[secIdx] = section;
                        contentReadyChecklist[secIdx] = true;
                    }
                }.bind(this));

                this._checkContentReady(contentReadyChecklist, deferred, this.tableContent, updateContentCallback);

                return deferred.promise;
            }

            _checkContentReady(checklist, def, tableContent, secIdx, updateContentCallback) {
                if (checklist.every(function (tickSet) {
                    return tickSet;
                })) {
                    if (def.promise.isPending()) {
                        Debug.Control.AudioZone.MenuHelper && console.log(this.name, "_checkContentReady > resolve with: ", cloneObject(tableContent));
                        def.resolve(tableContent);
                    } else if (updateContentCallback) {
                        Debug.Control.AudioZone.MenuHelper && console.log(this.name, "_checkContentReady > calling updateContentCallback: ", cloneObject(tableContent));
                        updateContentCallback(this._processAndFilterContent(tableContent), secIdx);
                    } else {
                        console.warn(this.name, "ContentMenuHelper did receive new data after promise resolved, but not callback provided!");
                    }
                }
            }

            /**
             * Required as otherwise the reload events wouldn't be forwarded by the stateHandler due to id missmatches.
             * @param contentType
             * @returns {string|number}
             */
            getMediaId(contentType) {
                if (contentType === this.EVENT_TYPES.SERVICE) {
                    return MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS;
                } else if (contentType === this.EVENT_TYPES.ZONE_FAVORITES) {
                    return this.control.details.playerid;
                } else {
                    return 0;
                }
            }

            getMediaInfo(contentType) {
                if (contentType === this.EVENT_TYPES.SERVICE && SpotifyAccountManager.shared(this.control).isPrepared()) {
                    //ensures the activeUser is properly passed to loaders.
                    return {
                        service: {
                            cmd: MusicServerEnum.Service.SPOTIFY,
                            uid: MusicServerEnum.Service.SPOTIFY + "/" + SpotifyAccountManager.shared(this.control).activeUser.id
                        }
                    };
                } else {
                    return this.control.details;
                }
            }

            getStartTableContent(viewController, reloadContentFn) {
                Debug.Control.AudioZone.MenuHelper && console.log(this.name, "getStartTableContent", getStackObj());
                return CustomizationManager.shared(this.control).get().then(function getStartTableContentGotManager(customizationMap) {
                    Debug.Control.AudioZone.MenuHelper && console.log(this.name, "getStartTableContent > gotManager", getStackObj());
                    customizationMap = cloneObject(customizationMap);
                    return this._getStartTableContentForCustomizationMap(viewController, customizationMap, reloadContentFn);
                }.bind(this));
            }

            setCellSelected(key) {
                this._tableViews.forEach(function (tableView) {
                    if (tableView.getElement()) {
                        var matchingCell = tableView.getElement().find("." + key + "__cell"),
                            notMatchingCell = tableView.getElement().find(".base-cell:not(." + key + "__cell" + ")");
                        notMatchingCell.removeClass("active");
                        matchingCell.addClass("active");
                    }
                }.bind(this));

                this._activeKey = key;
            }

            receivedServiceReloadEvent(reason, identifier) {
                // Don't just simply delete any ongoing requests
                console.log(this.name, "receivedReloadEvent: reason=" + reason + ", id=" + identifier, getStackObj());

                if (this._spotifyPlaylistPrms && this._spotifyPlaylistPrms.inspect().state === "pending") {
                    this._spotifyPlaylistPrms.then(function () {
                        delete this._spotifyPlaylistPrms;
                    }.bind(this));
                } else {
                    delete this._spotifyPlaylistPrms;
                }
            }

            _getSDFavoriteSection(showStateFn, sectionIdx) {
                var sectionPrms = FavoritesManager.shared(this.control).favorites().then(function (favs) {
                    return {
                        customClass: "card-section " + this.constructor.SECTION_ID.FAVS,
                        headerTitle: _("media.favorites-of-zone", {
                            zone: this.control.getName()
                        }),
                        rows: favs.map(function (fav) {
                            var cellObj = MediaBrowserV2Favorites.getCellFromContentTypeItem(fav, {
                                identifier: MusicServerEnum.ControlContentIdentifiers.ZONE_FAVORITES,
                                control: this.control
                            }); // some favorites may be browsable

                            cellObj.action = MediaBrowserV2Favorites.getOpenFavoriteAction(this.control, fav, showStateFn);
                            return cellObj;
                        }.bind(this)),
                        sectionRightButtonTitle: _("more"),
                        rightSectionButtonTapped: this._getFavAction(MusicServerEnum.ControlContentMenuId.FAVORITES, showStateFn)
                    };
                }.bind(this)).then(function (section) {
                    if (sectionPrms.didAutoResolve) {
                        this._notifySectionPatch(this.constructor.SECTION_ID.FAVS, section, sectionIdx);
                    }

                    return section;
                }.bind(this));
                return sectionPrms;
                /*return autoResolveAfterWith(
                    sectionPrms,
                    this.constructor.AUTO_RESOLVE_TIMEOUT,
                    {
                        customClass: "card-section " + this.constructor.SECTION_ID.FAVS,
                        headerTitle: _("media.favorites-of-zone", { zone: this.control.getName() }),
                        rows: [],
                        sectionRightButtonTitle: _("more"),
                        rightSectionButtonTapped: this._getFavAction(MusicServerEnum.ControlContentMenuId.FAVORITES, showStateFn),
                        footer: {
                            iconSrc: Icon.INDICATOR,
                            message: _("loading"),
                            style: GUI.TableViewV2.FOOTER_STYLE.EMPTY_VIEW
                        }
                    }
                );*/
            }

            _getSDHistorySection(showStateFn, sectionIdx, updateContentCallback) {
                var historyManager = HistoryManager.shared(this.control),
                    history;

                if (!historyManager.isPrepared()) {
                    HistoryManager.shared(this.control).prepare().then(function _getSDHistorySectionManagerPrepared(arg) {
                        Debug.Control.AudioZone.MenuHelper && console.log(this.name, "_getSDHistorySectionManagerPrepared >> calling updateContentCallback");
                        updateContentCallback && updateContentCallback();
                    }.bind(this));
                    return {
                        customClass: "card-section " + this.constructor.SECTION_ID.HISTORY,
                        headerTitle: _("media.menu.history"),
                        rows: []
                    };
                } else {
                    history = historyManager.getHistory();
                    return {
                        customClass: "card-section " + this.constructor.SECTION_ID.HISTORY,
                        headerTitle: _("media.menu.history"),
                        rows: history.map(function (item) {
                            var details = item._historyDetails,
                                onHistoryItemClickedFn = null;
                            details.control = this.control;
                            details.contentTypes = [item.contentType];

                            if (this.control.audioserverComp.isFileTypeBrowsable(item.type)) {
                                onHistoryItemClickedFn = function handleOnHistoryItemClicked(item, contentType, section, row) {
                                    showStateFn(Controls.AudioZoneV2Control.MediaBrowserV2Base.getScreenStateForItem(item), null, details);
                                }.bind(this);
                            }

                            return Controls.AudioZoneV2Control.MediaBrowserV2Base.getConstructorForItem(item).getCellFromContentTypeItem(item, details, item.contentType, true, this.control.audioserverComp.isFileTypeBrowsable(item.type), onHistoryItemClickedFn);
                        }.bind(this)),
                        footer: history.length > 0 ? null : {
                            iconSrc: Icon.AudioZone.NEW.HISTORY,
                            message: _("audio-server.empty-history"),
                            style: GUI.TableViewV2.FOOTER_STYLE.EMPTY_VIEW
                        },
                        sectionRightButtonTitle: _("more"),
                        rightSectionButtonTapped: function () {
                            showStateFn("HistoryOverviewScreen", null, {
                                control: this.control,
                                customizationKey: MusicServerEnum.ControlContentSectionId.START
                            });
                        }.bind(this),
                        resetScrollLeftOnReload: true // as when music is played, this section is updated, scroll to the left.

                    };
                }
            }

            _getSpotifyPlaylists(showStateFn, sectionIdx, updateContentCallback) {
                Debug.Control.AudioZone.MenuHelper && console.log(this.name, "_getSpotifyPlaylists");

                if (!this._spotifyPlaylistPrms) {
                    var spotifyDeferred = Q.defer();
                    this._spotifyPlaylistPrms = spotifyDeferred.promise;
                    SpotifyAccountManager.shared(this.control).prepare().then(function () {
                        var spotifyUser = SpotifyAccountManager.shared(this.control).activeUser,
                            playlistItem = {
                                id: MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS,
                                contentType: MusicServerEnum.MediaContentType.SERVICE
                            },
                            service = {
                                uid: MusicServerEnum.Service.SPOTIFY + "/" + SpotifyAccountManager.getUserUnique(spotifyUser),
                                cmd: MusicServerEnum.Service.SPOTIFY
                            };

                        if (spotifyUser) {
                            service.user = spotifyUser.user;
                            return new OverviewContentLoader(this.control, playlistItem, HistoryManager.MAX_ENTRIES, {
                                service: service
                            }).fetch().then(function (playlists) {
                                if (spotifyDeferred.promise.isPending()) {
                                    Debug.Control.AudioZone.MenuHelper && console.log(this.name, "_getSpotifyPlaylists - response from AS, resolve");
                                    spotifyDeferred.resolve(this._addContentInfoToSpotifyPlaylistResult(playlists, service, spotifyUser, showStateFn, playlistItem));
                                } else {
                                    Debug.Control.AudioZone.MenuHelper && console.warn(this.name, "_getSpotifyPlaylists - response from AS, def resolved, use updateContentCallback"); // create a new deferred & resolve it asap. The UpdateContentCallback will
                                    // trigger a getSpotifyPlaylists call which will then resolve with the new
                                    // data.

                                    var newDeferred = Q.defer();
                                    this._spotifyPlaylistPrms = newDeferred.promise;
                                    newDeferred.resolve(this._addContentInfoToSpotifyPlaylistResult(playlists, service, spotifyUser, showStateFn, playlistItem));
                                    updateContentCallback && updateContentCallback();
                                }
                            }.bind(this), null, function (cachedPlaylists) {
                                Debug.Control.AudioZone.MenuHelper && console.log(this.name, "_getSpotifyPlaylists - cached response, resolve deferred");
                                spotifyDeferred.resolve(this._addContentInfoToSpotifyPlaylistResult(cachedPlaylists, service, spotifyUser, showStateFn, playlistItem));
                            }.bind(this));
                        } else {
                            spotifyDeferred.resolve({
                                customClass: "card-section " + this.constructor.SECTION_ID.SPOTIFY_PLAYLISTS,
                                rows: []
                            });
                        }
                    }.bind(this));

                    this._spotifyPlaylistPrms.then(function (section) {
                        if (this._spotifyPlaylistPrms.didAutoResolve) {
                            this._notifySectionPatch(this.constructor.SECTION_ID.SPOTIFY_PLAYLISTS, section, sectionIdx);
                        }

                        return section;
                    }.bind(this));
                } else {
                    Debug.Control.AudioZone.MenuHelper && console.log(this.name, "_getSpotifyPlaylists > promise exists, return. Promise pending? " + this._spotifyPlaylistPrms.isPending());
                }

                return this._spotifyPlaylistPrms;
            }

            /**
             * Used by either resolve or notify (cache) - enriches the result with additional content info for the table.
             * @param spotifyPlaylists
             * @param service
             * @param spotifyUser
             * @param showStateFn
             * @param playlistItem
             * @returns {{sectionRightButtonTitle: *, footer: (null|{iconSrc: string, style: string, message: *}), customClass: string, rows: *, rightSectionButtonTapped: (*|{new(...args: any[]): unknown}|((...args: any[]) => unknown)|OmitThisParameter<unknown>|{new(...args: unknown[]): unknown}|((...args: unknown[]) => unknown)), headerTitle: *}}
             * @private
             */
            _addContentInfoToSpotifyPlaylistResult(spotifyPlaylists, service, spotifyUser, showStateFn, playlistItem) {
                return {
                    customClass: "card-section " + this.constructor.SECTION_ID.SPOTIFY_PLAYLISTS,
                    headerTitle: _("audio-server.spotify.playlists"),
                    rows: spotifyPlaylists.map(function (item) {
                        var details = {
                            username: SpotifyAccountManager.getUserUnique(spotifyUser),
                            identifier: MusicServerEnum.Service.SPOTIFY,
                            service: service,
                            mediaType: MusicServerEnum.MediaType.SERVICE,
                            control: this.control,
                            contentTypes: [item.contentType],
                            lastSelectedItem: item
                        };
                        return Controls.AudioZoneV2Control.MediaBrowserV2Base.getConstructorForItem(item).getCellFromContentTypeItem(item, details, item.contentType, this.control.audioserverComp.isFileTypePlaylist(item.type), this.control.audioserverComp.isFileTypeBrowsable(item.type), function () {
                            showStateFn(Controls.AudioZoneV2Control.MediaBrowserV2Base.getScreenStateForItem(item), null, details);
                        });
                    }.bind(this)),
                    footer: spotifyPlaylists.length > 0 ? null : {
                        iconSrc: Icon.AudioZone.NEW.PLAYLIST,
                        message: _("audio-server.empty-spotify-playlists"),
                        style: GUI.TableViewV2.FOOTER_STYLE.EMPTY_VIEW
                    },
                    sectionRightButtonTitle: _("more"),
                    rightSectionButtonTapped: function () {
                        showStateFn(Controls.AudioZoneV2Control.MediaBrowserV2Base.getScreenStateForItem(playlistItem), null, {
                            username: SpotifyAccountManager.getUserUnique(spotifyUser),
                            lastSelectedItem: playlistItem,
                            contentTypes: [playlistItem.contentType],
                            mediaType: MusicServerEnum.MediaType.SERVICE,
                            identifier: MusicServerEnum.Service.SPOTIFY,
                            control: this.control
                        });
                    }.bind(this)
                };
            }

            _getContentSection(showStateFn, customizationMap, _isInAddMode, vc) {
                return {
                    sectionId: MusicServerEnum.ControlContentSectionId.MENU,
                    isContentSection: true,
                    rows: sortArrByFields(Object.keys(customizationMap).map((customizationKey) => {
                        return this._getRowForCustomizationKey(customizationKey, customizationMap, showStateFn, _isInAddMode, vc);
                    }).filter(entry => entry !== null), ["rows", "rating"])
                };
            }

            _getSettingsSection(showStateFn) {
                return {
                    isSettingsSection: true,
                    rows: [
                        ...(this.control.hasHistory
                            ? [this._getRow(_('history'), null, Icon.AudioZone.NEW.HISTORY, () => {
                                App.navigationRef.navigate(LxControlHistoryScreen, { control: this.control });
                                  },
                              )]
                            : []),
                        this._getRow(_("media.preferences"), null, Icon.AudioZone.NEW.GEAR, function () {
                            showStateFn(AudioZoneV2ControlEnums.ScreenState.SETTINGS.SCREEN, null, {
                                control: this.control
                            });
                        }.bind(this))
                    ]
                };
            }

            _getRowForCustomizationKey(customizationKey, customizationMap, showStateFn, _isInAddMode, vc) {
                var title, desc, iconSrc, action, row;

                switch (customizationKey) {
                    case MusicServerEnum.ControlContentIdentifiers.SPOTIFY:
                        title = this._getTitleForCustomizationKey(customizationKey);
                        iconSrc = this._getIconSrcForCustomizationKey(customizationKey);
                        action = this._getActionForCustomizationKey(customizationKey, showStateFn, _isInAddMode, vc);
                        row = this._getSpotifyRow(title, desc, iconSrc, action, customizationKey);
                        break;
                    case MusicServerEnum.ControlContentMenuId.SOUNDSUIT:
                        title = this._getTitleForCustomizationKey(customizationKey);
                        iconSrc = this._getIconSrcForCustomizationKey(customizationKey);
                        action = this._getActionForCustomizationKey(customizationKey, showStateFn, _isInAddMode, vc);
                        row = this._getSoundsuitRow(title, desc, iconSrc, action, customizationKey);
                        break;

                    default:
                        if (CustomizationManager.isInputCustomizationKey(customizationKey)) {
                            action = this._getActionForCustomizationKey(customizationKey, showStateFn, _isInAddMode, vc);
                            row = this._getLineInRow(action, customizationKey);
                        } else {
                            title = this._getTitleForCustomizationKey(customizationKey);
                            desc = this._getDescForCustomizationKey(customizationKey);
                            iconSrc = this._getIconSrcForCustomizationKey(customizationKey);
                            action = this._getActionForCustomizationKey(customizationKey, showStateFn, _isInAddMode, vc);
                            row = this._getRow(title, desc, iconSrc, action, customizationKey);
                        }

                }

                if (row) {
                    if (_isInAddMode && CustomizationManager.isInputCustomizationKey(customizationKey)) {
                        row.content.rightIconSrc = Icon.CIRCLED_ADD;
                        delete row.content.class;
                    }

                    row.key = customizationKey;
                    row.rating = customizationMap[customizationKey].rating;
                    row.enabled = customizationMap[customizationKey].enabled;
                }

                return row;
            }

            _getTitleForCustomizationKey(key) {
                var title = key;

                switch (key) {
                    case MusicServerEnum.ControlContentSectionId.START:
                        title = _("start");
                        break;

                    case MusicServerEnum.ControlContentMenuId.FAVORITES:
                        title = _("media.favorites-of-zone", {
                            zone: this.control.getName()
                        });
                        break;

                    case MusicServerEnum.ControlContentMenuId.HISTORY:
                        title = _("media.menu.history");
                        break;

                    case MusicServerEnum.ControlContentMenuId.SPOTIFY_PLAYLISTS:
                        title = _("audio-server.spotify.playlists");
                        break;

                    case MusicServerEnum.ControlContentMenuId.PLAYLISTS:
                        title = _("media.playlists");
                        break;

                    case MusicServerEnum.ControlContentMenuId.RADIO:
                        title = _("media.service.webradio");
                        break;

                    case MusicServerEnum.ControlContentMenuId.SPOTIFY:
                        title = _("media.popup.spotify.title");
                        break;

                    case MusicServerEnum.ControlContentMenuId.LIB:
                        title = _("media.library");
                        break;

                    case MusicServerEnum.ControlContentMenuId.SOUNDSUIT:
                        title = SOUNDSUIT_TITLE;
                        break;

                    case MusicServerEnum.ControlContentMenuId.ANNOUNCEMENT:
                        title = _("media.menu.announcement");
                        break;

                    case MusicServerEnum.ControlContentMenuId.GROUPS:
                        title = _("cmdtext.audiozone.sync");
                        break;
                }

                return title;
            }

            _getDescForCustomizationKey(key) {
                var desc = null;
                return desc;
            }

            _getIconSrcForCustomizationKey(key) {
                var iconSrc = null;

                switch (key) {
                    case MusicServerEnum.ControlContentSectionId.START:
                        iconSrc = Icon.AudioZone.NEW.NOTE;
                        break;

                    case MusicServerEnum.ControlContentMenuId.FAVORITES:
                        iconSrc = Icon.AudioZone.NEW.STAR;
                        break;

                    case MusicServerEnum.ControlContentMenuId.PLAYLISTS:
                        iconSrc = Icon.AudioZone.NEW.PLAYLIST;
                        break;

                    case MusicServerEnum.ControlContentMenuId.RADIO:
                        iconSrc = Icon.AudioZone.NEW.STREAM;
                        break;

                    case MusicServerEnum.ControlContentMenuId.SPOTIFY:
                        iconSrc = Icon.AudioZone.NEW.SPOTIFY;
                        break;

                    case MusicServerEnum.ControlContentMenuId.SOUNDSUIT:
                        iconSrc = Icon.AudioZone.SOUNDSUIT_LINE;
                        break;

                    case MusicServerEnum.ControlContentMenuId.LIB:
                        iconSrc = Icon.AudioZone.NEW.LIBRARY;
                        break;

                    case MusicServerEnum.ControlContentMenuId.ANNOUNCEMENT:
                        iconSrc = Icon.AudioZone.NEW.MIC;
                        break;

                    case MusicServerEnum.ControlContentMenuId.GROUPS:
                        iconSrc = Icon.AudioZone.NEW.GROUP;
                        break;
                }

                return iconSrc;
            }

            _getActionForCustomizationKey(key, showStateFn, _isInAddMode, vc) {
                var action = null;

                switch (key) {
                    case MusicServerEnum.ControlContentSectionId.START:
                        action = this._getStartAction.apply(this, arguments);
                        break;

                    case MusicServerEnum.ControlContentMenuId.FAVORITES:
                        action = this._getFavAction.apply(this, arguments);
                        break;

                    case MusicServerEnum.ControlContentMenuId.PLAYLISTS:
                        action = this._getPlaylistAction.apply(this, arguments);
                        break;

                    case MusicServerEnum.ControlContentMenuId.RADIO:
                        action = this._getRadioAction.apply(this, arguments);
                        break;

                    case MusicServerEnum.ControlContentMenuId.SPOTIFY:
                        action = this._getSpotifyAction.apply(this, arguments);
                        break;

                    case MusicServerEnum.ControlContentMenuId.SOUNDSUIT:
                        action = this._getSoundsuitAction.apply(this, arguments);
                        break;

                    case MusicServerEnum.ControlContentMenuId.LIB:
                        action = this._getLibAction.apply(this, arguments);
                        break;

                    case MusicServerEnum.ControlContentMenuId.ANNOUNCEMENT:
                        action = this._getAnnouncementAction.apply(this, arguments);
                        break;

                    case MusicServerEnum.ControlContentMenuId.GROUPS:
                        action = this._getGroupAction.apply(this, arguments);
                        break;

                    default:
                        if (CustomizationManager.isInputCustomizationKey(key)) {
                            action = this._getLineInAction.apply(this, arguments);
                        }

                        break;
                }

                return action;
            }

            _getFavAction(key, showStateFn) {
                var item = {
                    name: this._getTitleForCustomizationKey(key),
                    contentType: MusicServerEnum.MediaContentType.ZONE_FAVORITES
                };
                return function () {
                    showStateFn(Controls.AudioZoneV2Control.MediaBrowserV2Base.getScreenStateForItem(item), null, {
                        username: MusicServerEnum.NOUSER,
                        lastSelectedItem: item,
                        identifier: MusicServerEnum.ControlContentIdentifiers.ZONE_FAVORITES,
                        contentTypes: [item.contentType],
                        mediaType: MusicServerEnum.MediaType.FAVORITES,
                        control: this.control,
                        customizationKey: key
                    });
                }.bind(this);
            }

            _getPlaylistAction(key, showStateFn) {
                return function () {
                    showStateFn("PlaylistsContentOverviewScreen", null, {
                        control: this.control,
                        customizationKey: key
                    });
                }.bind(this);
            }

            _getStartAction(key, showStateFn) {
                return function () {
                    showStateFn("StartOverviewScreen", null, {
                        control: this.control,
                        customizationKey: key
                    });
                }.bind(this);
            }

            _getRadioAction(key, showStateFn) {
                return function () {
                    showStateFn("RadioContentOverviewScreen", null, {
                        control: this.control,
                        customizationKey: key
                    });
                }.bind(this);
            }

            _getSpotifyAction(key, showStateFn) {
                return function () {
                    showStateFn("SpotifyContentOverviewScreen", null, {
                        control: this.control,
                        customizationKey: key
                    });
                }.bind(this);
            }

            _getSoundsuitAction(key, showStateFn) {
                return function () {
                    showStateFn("SoundsuitContentOverviewScreen", null, {
                        control: this.control,
                        customizationKey: key
                    });
                }.bind(this);
            }

            _getLibAction(key, showStateFn) {
                var title = this._getTitleForCustomizationKey(key),
                    item = {
                        name: title,
                        contentType: MusicServerEnum.MediaContentType.LIBRARY,
                        id: 0
                    };

                return function () {
                    showStateFn(Controls.AudioZoneV2Control.MediaBrowserV2Base.getScreenStateForItem(item), null, {
                        username: MusicServerEnum.NOUSER,
                        lastSelectedItem: item,
                        identifier: MusicServerEnum.Service.LOCAL,
                        contentTypes: [item.contentType],
                        mediaType: MusicServerEnum.MediaType.LIBRARY,
                        control: this.control,
                        customizationKey: key
                    });
                }.bind(this);
            }

            _getLineInAction(key, showStateFn, _isInAddMode, vc) {
                return function () {
                    return this.control.audioserverComp.getInputs().promise.then(function (inputs) {
                        var currentInput = this.control.audioserverComp.getInputFromCustomizationKey(key);

                        if (!currentInput) {
                            console.error(this.name, "_getLineInAction failed, no input found for key " + key);
                            return Q.reject("no input found");
                        } else if (_isInAddMode) {
                            return vc.addItem(currentInput, {
                                service: {
                                    uid: MusicServerEnum.ControlContentIdentifiers.LOXONE_LINE_IN + "/" + MusicServerEnum.NOUSER
                                }
                            });
                        } else {
                            return this.control.audioserverComp.sendPlayerCommandFromType(currentInput);
                        }
                    }.bind(this));
                }.bind(this);
            }

            _getAnnouncementAction(key, showStateFn) {
                return function () {
                    var platformInfo = PlatformComponent.getPlatformInfoObj(),
                        majorPlatformVersion = parseInt(platformInfo.version);

                    if (platformInfo.platform === PlatformType.IOS && majorPlatformVersion === 13 && CommunicationComponent.getCurrentReachMode() === ReachMode.REMOTE) {
                        NavigationComp.showPopup({
                            message: _("media.announcement.not-supported.iOS13"),
                            buttonOk: true,
                            buttonCancel: false
                        }, PopupType.GENERAL);
                    } else {
                        showStateFn(AudioZoneV2ControlEnums.ScreenState.VOICE_RECORDER, null, {
                            control: this.control,
                            customizationKey: key
                        });
                    }
                }.bind(this);
            }

            _getGroupAction(key, showStateFn) {
                return function () {
                    showStateFn(AudioZoneV2ControlEnums.ScreenState.GROUP_OVERVIEW, null, {
                        control: this.control,
                        zone: {
                            playerid: this.control.details.playerid,
                            name: this.control.getName()
                        },
                        customizationKey: key
                    });
                }.bind(this);
            }

            _getRow(title, desc, iconSrc, action, customizationKey) {
                var row = {
                    content: {
                        clickable: true,
                        title: title,
                        leftIconSrc: iconSrc,
                        leftIconColor: window.Styles.colors.green,
                        disclosureText: desc,
                        disclosureColor: window.Styles.colors.green,
                        disclosureIcon: false,
                        class: !HD_APP ? "base-cell--with-chevron" : customizationKey + "__cell"
                    },
                    action: action,
                    customizationKey: customizationKey
                };
                return row;
            }

            _getSpotifyRow(title, desc, iconSrc, action, customizationKey) {
                var row = {
                    type: GUI.TableViewV2.CellType.Special.SPOTIFY_CELL,
                    content: {
                        clickable: true,
                        title: title,
                        leftIconSrc: iconSrc,
                        leftIconColor: window.Styles.colors.green,
                        disclosureColor: window.Styles.colors.green,
                        disclosureIcon: false,
                        class: !HD_APP ? "base-cell--with-chevron" : customizationKey + "__cell",
                        control: this.control
                    },
                    action: action,
                    customizationKey: customizationKey
                };
                return row;
            }

            _getSoundsuitRow(title, desc, iconSrc, action, customizationKey) {
                if (SoundsuitAccountManager.shared(this.control).activeUser) {
                    return this._getRow(title, desc, iconSrc, action, customizationKey);
                }
                return null;
            }

            _getLineInRow(action, customizationKey) {
                var input = this.control.audioserverComp.getInputFromCustomizationKey(customizationKey),
                    title = input ? input.name : _("media.section.line-in");
                return {
                    type: GUI.TableViewV2.CellType.Special.INPUT_CELL,
                    content: {
                        clickable: true,
                        title: title,
                        subtitle: input && input.uniqueSubtitle,
                        leftIconColor: window.Styles.colors.green,
                        // the icon itself is set by the cell.
                        input: input,
                        disclosureIcon: false,
                        class: HD_APP ? customizationKey + "__cell" : null,
                        control: this.control
                    },
                    action: action,
                    customizationKey: customizationKey
                };
            }

            _hasStartItems(customizationMap) {
                return Object.values(customizationMap[MusicServerEnum.ControlContentSectionId.START]).reduce(function (left, right) {
                    return {
                        enabled: !!(left.enabled | right.enabled)
                    };
                }.bind(this), {
                    enabled: false
                }).enabled;
            }

            _getStartTableContentForCustomizationMap(vc, customizationMap, updateContentCallback) {
                var tableContent = [],
                    showStateWrapper = this._getShowStateFunctionForVc(vc),
                    sectionIdx = 0;

                sortArrByFields(Object.values(customizationMap[MusicServerEnum.ControlContentSectionId.START]), ["rating"]).forEach(function handleCustomizationMapSection(sortObj) {
                    if (sortObj.enabled) {
                        switch (sortObj.key) {
                            case MusicServerEnum.ControlContentMenuId.FAVORITES:
                                tableContent.push(this._getSDFavoriteSection(showStateWrapper, sectionIdx++, updateContentCallback));
                                break;

                            case MusicServerEnum.ControlContentMenuId.HISTORY:
                                tableContent.push(this._getSDHistorySection(showStateWrapper, sectionIdx++, updateContentCallback));
                                break;

                            case MusicServerEnum.ControlContentMenuId.SPOTIFY_PLAYLISTS:
                                tableContent.push(this._getSpotifyPlaylists(showStateWrapper, sectionIdx++, updateContentCallback));
                                break;
                        }
                    }
                }.bind(this));
                return tableContent;
            }

            _getShowStateFunctionForVc(vc) {
                var _isInAddMode = vc instanceof GUI.AddMediaViewControllerV2Base;

                return function (identifier, view, details, animationType) {
                    if ((HD_APP) || GUI.AudioZoneV2ControlContentViewController.shouldHandleInControlContentVc(identifier) || _isInAddMode) {
                        vc.showState(identifier, view, details, animationType);
                    } else {
                        vc.showState(ScreenState.AudioZoneV2DetailedContentVC, null, {
                            showStateArgs: [identifier, view, details, animationType]
                        }, AnimationType.FADE);
                    }
                };
            }

            _notifySectionPatch(delegateId, section, sectionIdx) {
                // Sanitize the delegates by filtering old delegates and also call the delegate function
                this._delegates = this._delegates.filter(function (delegate) {
                    if (delegate) {
                        delegate.onSectionPatch && delegate.onSectionPatch.call(delegate, delegateId, section, sectionIdx);
                        return true;
                    } else {
                        return false;
                    }
                });
            }

        }

        Controls.AudioZoneV2Control.SingleTones.ControlContentMenu = ControlContentMenu;
    }

    return Controls.AudioZoneV2Control.SingleTones.ControlContentMenu;
});
