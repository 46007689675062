import {
    LxReactImageView,
    LxReactText,
    navigatorConfig,
    SearchableTable
} from "LxComponents"
import PropTypes from "prop-types";
import {useState, useEffect, useRef, useMemo, useCallback} from "react";
import {View} from "react-native";
import globalStyles from "GlobalStyles";
import {userStyles} from "../UserStyles";
import useTrustManager from "./useTrustManager";
import {PeerState} from "./TrustModels";
import TrustTableViewHelper from "./TrustTableViewHelper";
import {useBackNavigation} from "../../../../../../react-comps/Components";

function TrustPeerUserSelectScreen({navigation, route}) {
    const ogUsers = useRef({});
    const peerSubscriptionRef = useRef();
    const [peerData, setPeerData] = useState(null);
    const {trustManager} = useTrustManager();

    const hasUsers = useMemo(() => {
        return peerData && peerData.stateInfo.state === PeerState.Done && !!peerData.users.length;
    }, [peerData]);


    const onBackNavigation = () => {
        let prms = Q();
        if (JSON.stringify(ogUsers.current) !== JSON.stringify(peerData.users)) {
            prms = NavigationComp.showWaitingFor(ActiveMSComponent.editPeerUsers({
                [peerData.stateInfo.serial]: peerData.users
            }));
            prms.then(() => trustManager.trustUsersChanged());
        }

        prms.then((res) => {
            navigation.goBack();
        });
    }

    useBackNavigation(() => {
        onBackNavigation();
    }, [peerData]);

    useEffect(() => {
        navigation.setOptions({
            ...navigatorConfig({
                animationType: AnimationType.PUSH_OVERLAP_LEFT,
                title: peerData && peerData.name ? peerData.name : _("usermanagement.trust.managed-user"),
                rightActions: [{
                    action: ({dimensions, props, key}) => {
                        return <LxReactImageView source={Icon.Buttons.REFRESH} containerStyle={styles.refreshIcon}
                                                 partFillColors={styles.refreshIconParts} forTitleBar={true}/>
                    }
                }],
                onRightAction: () => trustManager.refreshPeer(peerData.stateInfo.serial),
                onLeftAction: onBackNavigation
            })
        })
    }, [peerData])

    useEffect(() => {
        const serial = route.params && route.params.serial;

        if (!peerSubscriptionRef.current) {
            peerSubscriptionRef.current = trustManager.getTrustUsersForPeer(serial).subscribe(newPeerData => {
                ogUsers.current = cloneObjectDeep(newPeerData.users);
                setPeerData({...newPeerData});
            });
        }

        return () =>  {
            peerSubscriptionRef.current && peerSubscriptionRef.current.unsubscribe();
            peerSubscriptionRef.current = null;
        }
    }, [])


    const _getUsersSection = (key, users) => {
        if (users.length) {
            return {
                headerTitle: key,
                headerTitleStyle: userStyles.letterHeading,
                rows: users.map((user) => {
                    return TrustTableViewHelper.getCellForUser(user, user.peerSerial, (selected) => {
                        //copy is needed to prevent setting a state with the same object reference
                        const updatedPeerData = {...peerData},
                              selectedUser = updatedPeerData.users.find(peerUser => peerUser.uuid === user.uuid);

                        if (selectedUser) {
                            selectedUser.used = selected;
                            setPeerData(updatedPeerData);
                        }
                    });
                })
            };
        }
    }

    const trustUserContent = useMemo(() => {
        let tableContent = [],
            _sortedUsers,
            peerState = peerData && peerData.stateInfo.state,
            isDoneLoading = peerState && peerState === PeerState.Done || peerState === PeerState.Failed;

        if (isDoneLoading) {
            if (peerState === PeerState.Done) {
                const allUsers = peerData.users;
                if (allUsers && Object.keys(allUsers).length) {
                    _sortedUsers = ActiveMSComponent.sortUsers(allUsers);
                    Object.keys(_sortedUsers).sort().forEach((key, index) => {
                        let users = _sortedUsers[key];
                        if (users && !!users.length) {
                            users.sort((userA, userB) => userA.name.localeCompare(userB.name));
                            tableContent.pushObject(_getUsersSection(key, users));
                        }
                    })
                } else {
                    tableContent.push({
                        rows: [{
                            content: {
                                title: _("usermanagement.trust.no-users-found")
                            },
                            excludeFromSearch: true
                        }]
                    });
                }
            } else if (peerState === PeerState.Failed) {
                tableContent.push({
                    rows: [{
                        content: {
                            title: _("usermanagement.trust.peer.not-reachable", {
                                trustPeer: peerData.name || peerData.stateInfo.serial
                            }),
                            titleStyle: {
                                color: globalStyles.colors.red
                            }
                        },
                        excludeFromSearch: true
                    }]
                });
            }
        } else {
            tableContent.push(TrustTableViewHelper.getDummyContent());
        }

        let labelText = _("loading");

        if (isDoneLoading) {
            const date = moment(peerData.stateInfo.timeStamp);
            const dateString = date.format(LxDate.getDateFormat(DateType.Date));
            const timeString = date.format(LxDate.getDateFormat(DateType.TimeFormat));
            labelText = _("last-updated", {
                date: dateString,
                time: timeString
            });
        }

        return <>
            <View style={styles.lastUpdatedLabelContainer}>
                <LxReactText style={styles.lastUpdatedLabel}>{labelText}</LxReactText>
            </View>
            <SearchableTable tableContent={tableContent} showSearchBar={hasUsers} filterTitle={_("search.title")}/>
        </>
    }, [peerData]);

    return trustUserContent
}

const styles = {
    container: {
        height: "100%",
        width: "100%"
    },
    lastUpdatedLabel: {
        ...globalStyles.textStyles.footNote.default,
        color: globalStyles.colors.text.secondary,
        paddingLeft: globalStyles.spacings.gaps.bigger
    },
    lastUpdatedLabelContainer: {
        flexDirection: "row",
        width: "100%",
        marginBottom: globalStyles.spacings.gaps.small
    },
    refreshText: {
        color: globalStyles.colors.green
    },
    refreshIcon: {
        height: globalStyles.sizes.icons.big,
        width: globalStyles.sizes.icons.big,
        borderRadius: "50%"
    },
    refreshIconParts: {
        circle: globalStyles.colors.stateInactiveB,
        path: globalStyles.colors.text.secondary
    }
}

TrustPeerUserSelectScreen.propTypes = {
    user: PropTypes.object,
    type: PropTypes.string,
    initSecretScore: PropTypes.number,
    setTitleBarButtonValid: PropTypes.func,
    setOptions: PropTypes.func
}

export default TrustPeerUserSelectScreen;
