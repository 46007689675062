import * as React from "react"
import Svg, { Path } from "react-native-svg"

function ArrowDown(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path
                fillRule="evenodd"
                d="M2.253 8.336a1 1 0 011.411-.083L12 15.663l8.336-7.41a1 1 0 011.328 1.494l-9 8a1 1 0 01-1.328 0l-9-8a1 1 0 01-.083-1.411z"
                clipRule="evenodd"
            />
        </Svg>
    )
}

export default ArrowDown
