'use strict';
/**

 */

window.GUI = function (GUI) {
    class PickerViewDateTime extends GUI.LxPickerViewBase {
        constructor() {
            super(...arguments);
            Debug.GUI.PickerView && console.log(this.name, "constructor");

            if (this.details.hideTimeAndYear) {
                this.dateFormat = LxDate.getMobiscrollDateFormat(true);
            } else {
                this.dateFormat = LxDate.getMobiscrollDateFormat();
                this.timeFormat = LxDate.getMobiscrollTimeFormat(true);
            }
        }

        /**
         * This function initializes the Picker
         */
        preparePicker() {
            var msTime = SandboxComponent.getMiniserverTimeInfo();
            var pickerConfig = this.mergePickerConfig({
                minDate: this.details.minDate || ActiveMSComponent.convertToJsDate(msTime.add(1, "minute")),
                // minutes plus 1, min of the timer value
                maxDate: this.details.maxDate || ActiveMSComponent.convertToJsDate(msTime.add(2, 'year').endOf('year'))
            });

            if (this.details.hideTimeAndYear) {
                pickerConfig.dateFormat = this.dateFormat;
                pickerConfig.dateOrder = isUSDateOrder() ? "MMdd" : "ddMM"; // year gets hidden

                this.picker.date(pickerConfig);
            } else {
                var pickerTimeFormat = this.timeFormat.replace("mm", "ii");
                pickerConfig.dateFormat = this.dateFormat;
                pickerConfig.dateOrder = isUSDateOrder() ? "Mddyy" : "ddMyy";
                pickerConfig.timeFormat = pickerTimeFormat;
                pickerConfig.timeWheels = pickerTimeFormat.replace(":", "").replace(" ", "");
                this.picker.datetime(pickerConfig);
            }

            this.setPickerValue(this.details.value);
        }

        /**
         * This function will be called once the pickerValue has changed
         * For example if the user turns one of the pickerWheels
         * @param v The value returned by mobiscroll in the given format
         */
        onChange(v) {
            this.onPickerChangedFn && this.onPickerChangedFn(this.getPickerValue(v));
        }

        getPickerValue(v) {
            var jsDateObj = this.picker.mobiscroll('getVal'); // v is a string, we use the date object.

            return ActiveMSComponent.convertToMsDate(jsDateObj); // avoids issues with different timezones.
        }

        /**
         * This sets the given value to the mobiscroll picker
         * @param value This must be in the right format you specified in the picker config.
         */
        setPickerValue(value) {
            var jsDate = ActiveMSComponent.convertToJsDate(value); // avoids issues with different timezones.

            this.picker.mobiscroll('setDate', jsDate);
        }

    }

    GUI.LxPickerViewDateTime = PickerViewDateTime;
    return GUI;
}(window.GUI || {});
