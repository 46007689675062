'use strict';
/*
 title
 rightIconSrc
 rightIconColor
 rightText
 rightTextColor
 rightTextAlign
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.LIGHT_TEXT = "LightTextCell";

    class LightTextCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getLightTextCellContent = function getLightTextCellContent(content) {
                var rightText = "";
                var rightIcon = "";

                if (content.rightIconSrc) {
                    rightIcon = ImageBox.getResourceImageWithClasses(content.rightIconSrc, 'right-cntr__icon');
                }

                if (content.rightText) {
                    rightText = '<div class="right-cntr__text">' + content.rightText + "</div>";
                }

                return $('' + '<div class="content__title">' + content.title + '</div>' + '<div class="content__right-cntr">' + rightText + rightIcon + '</div>');
            };

            return {
                getLightTextCellContent: getLightTextCellContent
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("light-text-cell");
        }

        viewDidLoad() {
            var prms = super.viewDidLoad(...arguments);
            this.contentPlaceholder.append(LightTextCell.Template.getLightTextCellContent(this.content));

            if (this.content.rightIconColor) {
                this.elements.rightIcon = this.contentPlaceholder.find('.right-cntr__icon');
                this.elements.rightIcon.css('fill', this.content.rightIconColor);
            }

            if (this.content.rightTextColor) {
                this.elements.rightText = this.contentPlaceholder.find('.right-cntr__text');
                this.elements.rightText.css('color', this.content.rightTextColor);
            }

            if (this.content.rightTextAlign) {
                this.elements.rightText = this.contentPlaceholder.find('.right-cntr__text');
                this.elements.rightText.css('text-align', this.content.rightTextAlign);
            }

            return prms;
        }

        clickableAllowed() {
            return false;
        }

        onSelected() {// nothing to do.
        }

    }

    GUI.TableViewV2.Cells.LightTextCell = LightTextCell;
    return GUI;
}(window.GUI || {});
