'use strict';
/*
 title
 selected
 showButton
 buttonSrc

 Delegate
 buttonTapped(section, row, tableView)
 buttonHit(section, row, tableView)
 buttonReleased(section, row, tableView)
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.SCENE_CELL = "SceneCell";

    class SceneCell extends GUI.TableViewV2.Cells.SceneBaseCell {
        //region Static
        static Template = function () {
            var getRightButton = function getRightButton(iconSrc) {
                return $('<div class="content__button">' + ImageBox.getResourceImageWithClasses(iconSrc, 'button__icon button__icon--edit') + '</div>');
            };

            return {
                getRightButton: getRightButton
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                return this._addRightButton();
            }.bind(this));
        }

        viewDidAppear() {
            var promise = super.viewDidAppear(...arguments);

            this.rightButton.onButtonTapped = function onButtonTapped() {
                this.delegate.buttonTapped && this.delegate.buttonTapped.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView, this);
            };

            this.rightButton.onButtonHit = function onButtonHit() {
                this.delegate.buttonHit && this.delegate.buttonHit.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView, this);
            };

            this.rightButton.onButtonReleased = function onButtonReleased() {
                this.delegate.buttonReleased && this.delegate.buttonReleased.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView, this);
            };

            return promise;
        }

        viewDidDisappear() {
            this.rightButton.onButtonTapped = null;
            this.rightButton.onButtonHit = null;
            this.rightButton.onButtonReleased = null;
            return super.viewDidDisappear(...arguments);
        }

        updateUI(selected) {
            super.updateUI(...arguments);

            if (this.content.showButton && selected) {
                this.showSubview(this.rightButton);
            } else {
                this.hideSubview(this.rightButton);
            }
        }

        // Private
        _addRightButton() {
            var rightButton = SceneCell.Template.getRightButton(this.content.buttonSrc);
            this.rightButton = new GUI.LxButton(this, rightButton[0], Color.BUTTON_GLOW, [{
                part: rightButton.children()[0],
                mode: 'fill'
            }], true);
            return this.appendSubview(this.rightButton, this.element.find('.cell__content'));
        }

    }

    GUI.TableViewV2.Cells.SceneCell = SceneCell;
    return GUI;
}(window.GUI || {});
