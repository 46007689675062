import {useMemo, useState, useRef, useEffect} from "react";
import {
    navigatorConfig,
    LxReactText,
    LxReactFlexibleCell,
    LxReactTextView,
    LxReactImageView,
    SearchableTable
} from "LxComponents";
import {useNavigation} from "@react-navigation/native";
import globalStyles from "GlobalStyles";
import EcoScreenPresenceControlSelection from "./EcoScreen/EcoScreenPresenceControlSelection";
import AmbientShortcutSelection from "./AmbientMode/screens/AmbientShortcutSelection";
import AmbientDefaultLocationSelector from "./AmbientMode/screens/AmbientDefaultLocationSelector";
import Icons from "IconLib"

/**
 * Can either show a list of options or a list of grouped options, both provided via options argument.
 * Required params or props:
 *  - options --> either a list of options or a list of sectioned options
 *      an option has the following args
 *          - id --> used to return the selected value
 *          - title, subTitle
 *          - searchTags --> may be provided to enhance search experience (title + subTitle are always used)
 *          - mainLeftContent/mainRightContent --> can be used to show additional infos.
 *      when sections are to be shown, provide the options like this:
 *          [ { title: "Section 1", options: [{id: "a", title: "A"}] }, { title: "Section 2", options: {...}]
 *  - onSelected --> a fn to call with the id of the selected item as arg, if the selection has changed.
 *  - autoClose --> when true, the screen closes after the selection callback has been performed.
 *  - title --> the title to show in the header.
 *  - createFn --> if provided an option to create a new entry is available
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function LxReactSelectorScreen(props) {
    const {
        title,
        onSelected,
        autoClose,
        dispatchSelectionChange = false,
        createNewPlaceholder = _("device-learning.create-new"),
        createNewRegex = Regex.NAME,
        createNewFilterRegex = Regex.NAME_FILTER,
        options,
        createFn,
        animationType,
        allowMultiple = false,
        selectedId = [],
        isSubSelection = false,
        getCustomFilteredContent,
        filterTitle,
        maxItemCount = -1,
        onMaxItemsReached,
        headerShown = true,
        withoutHorizontalSpaces = false
    } = {...props, ...((props.route && props.route.params) || {})};

    const [internalSelectedIds, setInternalSelectedIds] = useState(Array.isArray(selectedId) ? selectedId : [selectedId]);
    const navigation = useNavigation();

    const closeAllSelectorScreens = () => {
        const routes = navigation.getState().routes;
        let selectorRoutesCount = 0;
        for (let i = routes.length-1; i >= 0; i--) {
            if ([LxReactSelectorScreen.name, EcoScreenPresenceControlSelection.name, AmbientShortcutSelection.name, AmbientDefaultLocationSelector.name].includes(routes[i].name)) {
                selectorRoutesCount++;
            } else {
                break;
            }
        }
        if (selectorRoutesCount > 1) {
            navigation.pop(selectorRoutesCount);
        } else {
            navigation.goBack();
        }
    }

    const updateInternalSelectedId = (newId, checked = true, replace = false) => {
        if (newId) {
            if (replace) {
                const newSelectedIds = Array.isArray(newId) ? newId : [newId];
                if (!newSelectedIds.equals(internalSelectedIds)) {
                    setInternalSelectedIds(newSelectedIds)
                }
            } else {
                let newSelectedIds = internalSelectedIds;
                if (checked) {
                    newSelectedIds.push(newId)
                } else {
                    const controlIndex = newSelectedIds.indexOf(newId);
                    if (controlIndex !== -1) {
                        newSelectedIds.splice(controlIndex, 1)
                    }
                }
                setInternalSelectedIds(newSelectedIds)
            }
        }
    }

    useEffect(() => {
        updateInternalSelectedId(selectedId, true, true)
    }, [props.selectedId]) // important not to use the const selectedId, if the prop isn't defined, it'll create a new array each cycle and trigger this useEffect.

    navigation.setOptions(
        navigatorConfig({
            navigation,
            headerShown: headerShown,
            animationType: animationType || AnimationType.MODAL,
            headerStyle: {
              borderBottomWidth: 0
            },
            title: title,
            rightActions: [
                {
                    action: ({dimensions, props, key}) => {
                        return <Icons.Tick style={globalStyles.customStyles.reactTitleBarIcon}/>

                    }
                }
            ],
            onRightAction: () => {
                if (internalSelectedIds.length === 1 && internalSelectedIds[0] === CREATE_NEW_ID) {
                    if (nullEmptyString(newOptionName.current)) {
                        Q(createFn(newOptionName.current)).then((createdOption) => {
                            triggerOnSelected(createdOption.id);
                            navigation.goBack();
                        })
                    }

                } else {
                    triggerOnSelected()
                    if (isSubSelection) {
                        closeAllSelectorScreens()
                    } else {
                        navigation.goBack();
                    }
                }
            }
        })
    );

    const triggerOnSelected = (newOptionId, selected = true) => {
        let selectedIdsOfOriginType = newOptionId || internalSelectedIds;
        if (!Array.isArray(selectedId) && Array.isArray(selectedIdsOfOriginType)) {
            selectedIdsOfOriginType = selectedIdsOfOriginType[0];
        }
        selectedIdsOfOriginType && onSelected && onSelected(selectedIdsOfOriginType, selected);
    }

    const handleOpenSubselection = (option) => {
        navigation.push(LxReactSelectorScreen.name, {
            options: option.options,
            selectedId: internalSelectedIds,
            title,
            onSelected: (selectedId, selected) => {
                console.log(LxReactSelectorScreen.name, "handleOpenSubselection - selected!", selectedId);
                triggerOnSelected(selectedId, selected)
            },
            autoClose,
            dispatchSelectionChange,
            createNewPlaceholder,
            createNewRegex,
            createNewFilterRegex,
            createFn,
            animationType,
            allowMultiple,
            isSubSelection: true,
            getCustomFilteredContent,
            filterTitle,
            maxItemCount,
            onMaxItemsReached
        });
    };

    const getAdoptedCustomFiltered = (filterText) => {
        if (!getCustomFilteredContent) {
            return {};
        }
        return getCustomFilteredContent(filterText).map(section => {
            return {
                ...section,
                rows: section.rows.map(row => {
                    return createOptionRow(row);
                })
            }
        })
    }


    const createOptionRow = (option) => {
        const selectable = option.hasOwnProperty("id");
        const isSelectedInternally = selectable ? internalSelectedIds.includes(option.id) : false;
        // if the checked property is already set (custom search), take this with higher priority, otherwise it may be overridden with a wrong value
        const isSelectedExternally = option.hasOwnProperty("checked") && typeof option.checked === "boolean";
        const rowOptions = {
            title: option.title,
            subTitle: option.subTitle,
            searchTags: option.searchTags,
            checked: isSelectedExternally ? option.checked : isSelectedInternally,
            radioMode: selectable && !allowMultiple ? LxReactFlexibleCell.RadioMode.Table : LxReactFlexibleCell.RadioMode.Inactive,
            mainLeftContent: option.mainLeftContent,
            mainRightContent: option.mainRightContent,
            ...(option.options ? {
                onPress: () => {
                    handleOpenSubselection(option);
                }, disclosureIcon: true
            } : {}),
            onCheckedToggled: checked => {
                if (option.hasOwnProperty("onCheckedToggled") && typeof option.onCheckedToggled === "function") {
                    return option.onCheckedToggled(checked);
                } else {
                    if (checked && internalSelectedIds.length === maxItemCount) {
                        if (onMaxItemsReached) {
                            onMaxItemsReached();
                        }
                        return Q.reject();
                    }
                    return selectable ? Q.resolve(true) : Q.reject();
                }
            },
            onCheckedChange: (checked, dueToRadioMode = false) => {
                if (option.hasOwnProperty("onCheckedChange") && typeof option.onCheckedChange === "function") {
                    option.onCheckedChange(checked, dueToRadioMode);
                } else {
                    if (!dueToRadioMode) {
                        try {
                            const allowedCheckChange = checked || (allowMultiple && !checked)
                            if (allowedCheckChange && autoClose) {
                                triggerOnSelected(option.id);
                                closeAllSelectorScreens()
                            } else {
                                allowedCheckChange && dispatchSelectionChange && triggerOnSelected(option.id, checked);
                                allowedCheckChange && updateInternalSelectedId(option.id, checked, !allowMultiple);
                            }
                        } catch (ex) {
                            console.error(LxReactSelectorScreen.name, "onCheckedChange - delegate failed!", ex);
                        }
                    }
                }
            }
        }

        if (!option.hasOwnProperty("id") && option.hasOwnProperty("noSection") && option.noSection) {
            delete rowOptions.checked
        }

        return rowOptions;
    }

    const createOptionSection = (sectionData) => {
        let section = {
            rows: sectionData.options.map(createOptionRow)
        };
        if (section.rows.length === 0) {
            return null;
        }
        if (sectionData.title) {
            section.headerElement =
                <LxReactText style={globalStyles.customStyles.sectionHeader}>{sectionData.title}</LxReactText>;
        }
        return section;
    }

    const detectedSections = () => {
        // if the first option has options on its own, but not an id --> it means that the content shown has sections.
        return options && options.length > 0 && options[0].hasOwnProperty("options") && !options[0].hasOwnProperty("id") && !options[0].hasOwnProperty("noSection");
    }

    const selectionContent = useMemo(() => {
        if (detectedSections()) {
            return options.map(createOptionSection).filter(section => !!section && section.rows && section.rows.length);
        } else {
            return [{
                rows: options.map(createOptionRow)
            }];
        }
    }, [options, internalSelectedIds, maxItemCount]);

    const newOptionName = useRef(null);
    const createNewOptionRow = (value) => {
        const row = createOptionRow({id: CREATE_NEW_ID});
        delete row.title;
        row.mainCenterContent = {
            comp: LxReactTextView,
            props: {
                value: newOptionName.current,
                alignment: "left",
                textStyle: styles.inputStyle,
                placeholderTextColor: globalStyles.colors.text.secondary,
                placeholder: createNewPlaceholder,
                validationRegex: createNewRegex,
                autoFocus: internalSelectedIds[0] === CREATE_NEW_ID,
                textChanged: (newValue, isValid) => {
                    if (isValid) {
                        newOptionName.current = newValue;
                    } else {
                        newOptionName.current = null;
                    }
                    updateInternalSelectedId(CREATE_NEW_ID, true, true);
                },
                onFocus: () => {
                    if (internalSelectedIds[0] !== CREATE_NEW_ID) {
                        updateInternalSelectedId(CREATE_NEW_ID, true, true);
                    }
                },
                onBlur: (newValue, valid, modified) => {
                    if (newValue && valid && modified) {
                        newOptionName.current = newValue;
                    } else if (!valid) {
                        newOptionName.current = null;
                    }
                },
                filterRegex: createNewFilterRegex
            }
        };

        row.onCheckedChange = (checked, dueToRadioMode = false) => {
            if (!dueToRadioMode) {
                checked && updateInternalSelectedId(CREATE_NEW_ID, true, true);
            }
        }
        return row;
    }

    const createSection = useMemo(() => {
        if (!createFn) {
            return null;
        }
        return {
            rows: [
                createNewOptionRow()
            ]
        };
    }, [createFn, internalSelectedIds, createNewPlaceholder, createNewRegex])

    const tableContent = useMemo(() => {
        let content = [...selectionContent];
        if (createSection) {
            content.splice(0, 0, createSection);
        }
        return content;
    }, [selectionContent, internalSelectedIds])

    return <SearchableTable tableContent={tableContent} filterTitle={filterTitle} getCustomFilteredContent={getCustomFilteredContent && getAdoptedCustomFiltered} withoutHorizontalSpaces={withoutHorizontalSpaces}/>
}

const styles = {
    inputStyle: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.primary,
        height: "100%",
        marginTop: "auto",
        marginBottom: "auto",
    }
}

const CREATE_NEW_ID = "create-new-entry";
