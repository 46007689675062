'use strict';

window.GUI = function (GUI) {
    class TableViewV2 extends GUI.View {
        //region Static
        static FOOTER_STYLE = {
            DEFAULT: "",
            EMPTY_VIEW: "empty-view"
        };
        static Template = function () {
            var getSection = function getSection(identifier, customClass) {
                customClass = customClass || "";
                return $('<div class="lx-table-view__section ' + customClass + '" id="' + identifier + '"></div>');
            };

            var getHeaderForSection = function getHeaderForSection(title, rightButtonTitle, image) {
                return $('<div class="section__header">' + (image ? '<div class="header__section-image">' + ImageBox.getResourceImageWithClasses(image, 'section-image__icon') + '</div>' : "") + ( // always create if it isn't undefined (no title and no right Button)
                        title ? '<div class="header__section-title text-overflow-ellipsis">' + title + '</div>' : "") + ( // always create if it isn't undefined (no title and no right Button)
                        rightButtonTitle ? '<div class="header__edit-button clickable">' + rightButtonTitle + '</div>' : "") + // TODO rename edit-button
                    '</div>');
            };

            var getStrongTitleSectionHeaderWithDescription = function getStrongTitleSectionHeaderWithDescription(title, description) {
                return '<div class="section__header header--descriptive">' + '       <div class="header__title title--strong">' + title + '</div>' + '       <div class="header__desc">' + description + '</div>' + '</div>';
            };

            var getBoldTitleSectionHeaderWithDescription = function getBoldTitleSectionHeaderWithDescription(title, description) {
                return '<div class="section__header header--descriptive">' + '       <div class="header__title title--bold">' + title + '</div>' + '       <div class="header__desc">' + description + '</div>' + '</div>';
            };

            var getTitleSectionHeaderWithDescription = function getTitleSectionHeaderWithDescription(title, description) {
                return '<div class="section__header header--descriptive">' + '       <div class="header__title">' + title + '</div>' + '       <div class="header__desc">' + description + '</div>' + '</div>';
            };

            var getHeaderForSectionContent = function getHeaderForSectionContent(sectionContent) {
                var headerElement = $('<div class="section__header-content">' + sectionContent.message + '</div>');

                if (sectionContent.align) {
                    headerElement.css("text-align", sectionContent.align);
                }

                if (sectionContent.color) {
                    headerElement.css("color", sectionContent.color);
                }

                return headerElement;
            };

            var getFooterForSection = function getFooterForSection(title, sectionFooterColor) {
                return $('<div class="section__footer">' + '   <div class="footer__section-title"' + (typeof sectionFooterColor === "string" ? ' style="color: ' + sectionFooterColor + ';"' : '') + '>' + title + '</div>' + '</div>');
            };

            var getFooterViewForSection = function getFooterViewForSection(iconSrc, title, message, style) {
                return $('<div class="section__footer-view section__footer-view--' + (style || GUI.TableViewV2.FOOTER_STYLE.DEFAULT) + '">' + '   <div class="footer-view__icon-placeholder">' + '       ' + ImageBox.getResourceImageWithClasses(iconSrc, "icon-placeholder__icon") + '   </div>' + '   <div class="footer-view__texts">' + '       ' + (typeof title === "string" ? '<div class="texts__title">' + title + '</div>' : '') + '       ' + (typeof message === "string" ? '<div class="texts__detail">' + message + '</div>' : '') + '   </div>' + '</div>');
            };

            var getRowsContainer = function getRowsContainer() {
                return $('<div class="section__rows"></div>');
            };

            return {
                getSection: getSection,
                getHeaderForSection: getHeaderForSection,
                getHeaderForSectionContent: getHeaderForSectionContent,
                getStrongTitleSectionHeaderWithDescription: getStrongTitleSectionHeaderWithDescription,
                getBoldTitleSectionHeaderWithDescription: getBoldTitleSectionHeaderWithDescription,
                getTitleSectionHeaderWithDescription: getTitleSectionHeaderWithDescription,
                getRowsContainer: getRowsContainer,
                getFooterForSection: getFooterForSection,
                getFooterViewForSection: getFooterViewForSection
            };
        }(); //endregion Static

        /**
         * ctor of TableViewV2
         * @param {*} dataSource - context for datasource of the tableView
         * @param {*} delegate - context for callbacks from the tableView and cells
         * @param {jQuery} elem if set, this element turns into the TableViewV2, otherwise a new element will be created
         * @constructor
         */
        constructor(dataSource, delegate, elem) {
            super(elem || $('<div />'));
            this.element.addClass("lx-table-view-v2");
            this.dataSource = dataSource;
            this.delegate = delegate;
            this.dataSourceTs = 0;
            this.delegateTs = 0; // save this flag to ensure the initial reload isn't a selective/section or smartReload.

            this.collector = new GUI.TableViewContentCollector(this, this.dataSource, this.delegate, false);
            this.internalDs = tableViewDataSource(null, null, this.dataSource.dataSourceAltSrc || this.dataSource);
            this.internalDel = tableViewDelegate(null, this.delegate.delegateAltSrc || this.delegate);
            this.dataSource.tableView = this;
            this.delegate.tableView = this;

            this.initialReload = true;
            this.table = [];
            this.eventHandlers = [];
            Debug.GUI.TableViewTiming && this.__prepareReloadTracking();
        }

        viewWillAppear() {
            Debug.GUI.TableView && console.log(this.viewId, "viewWillAppear");
            return super.viewWillAppear(...arguments).then(function () {
                return GUI.animationHandler.schedule(function () {
                    this.element.scrollTop(this._scrollTop);

                    this._restoreScrollLeft(this.element);
                }.bind(this));
            }.bind(this));
        }

        viewWillDisappear() {
            this._updateScrollTop();

            this._updateScrollLeft();

            return super.viewWillDisappear(...arguments);
        }

        destroy() {
            Debug.GUI.TableViewTiming && this.__destroyTracking(); // don't call _destroyContent() because it's unnecessary at this point to call removeSubview for every cell!

            this.table.forEach(function destroySectionIteration(section) {
                section.forEach(function destroyCellIteration(cell) {
                    var cleanupFn = this.removeFromHandledSubviews(cell); // returns a cleanup-function, call it right away.

                    cleanupFn().then(this._handleRemovedCell.bind(this));
                }.bind(this));
            }.bind(this));

            while (this.eventHandlers.length) {
                this.eventHandlers.pop().dispose();
            }

            this.table = [];
            return super.destroy(...arguments);
        }

        // -----------------------
        // Public methods
        // -----------------------
        isWaitingForReload() {
            return this._reloadPrms && this._reloadPrms.inspect().state === "pending";
        }

        _handleReloadWhileWaitingForOne() {
            Debug.GUI.TableView && console.log(this.viewId, "_handleReloadWhileWaitingForOne");
            var promise = null;
            Debug.GUI.TableView && console.info("TableView is already reloading, Queue this reload!");

            if (this._reloadEnqueued) {
                // If a reload is already pending, don't add another one.
                Debug.GUI.TableView && console.info("TableView reload enqueued, returning the already enqueued promise");
                promise = this._enqueuedPromise;
            } else {
                this._reloadEnqueued = true;
                promise = this._reloadPrms.then(function reloadEnqueued() {
                    this.__logTrk("Performing enqueued reload");

                    this._reloadEnqueued = false;
                    Debug.GUI.TableView && console.info("TableView Reload Queue finished, reloading now!");
                    return Q(this.reload() || true);
                }.bind(this));
                this._enqueuedPromise = promise;
            }

            return promise;
        }

        setForceFullReload(shouldForce) {
            this.__forceFullReload = shouldForce;
        }

        __updateInternalContent() {
            this.collectedContent = this.collector.collectContent();
            // For testing purposes, don't forward the original DS/Del, but the internal one to cells
            this.internalDs.update(this.collectedContent.sections);
            this.internalDel.update(this.collectedContent.sections);
        }

        dsUpdated() {
            this.__updateInternalContent();
        }

        reload() {
            Debug.GUI.TableView && console.log(this.viewId, "reload", getStackObj());
            var promise = null,
                selectiveArg = {},
                temporaryClone = null;

            this.__updateInternalContent();

            if (this.isWaitingForReload()) {
                promise = this._handleReloadWhileWaitingForOne();
            } else if (!this._shouldTvReload()) {
                console.error(this.viewId, "Unnecessary TableView Reload avoided, check response of update on dataSource and delegate before calling reload!");
                this._reloadPrms = Q.resolve(true);
                promise = this._reloadPrms;
            } else {
                // lock the dataSource - ensures the info which the table view accesses during reload doesn't change
                this._setLocked(true); // Save the current scrollTop value to restore it after every section has been set up

                this._updateScrollTop(); // Save the current horizontal scroll positions


                this._updateScrollLeft(); // when not reloading initially, make use of cloning to avoid flickering/sequential appearing of content.


                if (!this.initialReload || Debug.Test.INITIAL_TABLE_CLONE) {
                    // to avoid sequential appearing of cells/sections, work "in the shadows" - keep a clone as mock around.
                    // 1.) clone the element itself
                    temporaryClone = this.element.clone(false); // 2.) replace the elemet with the clone, the clone remains visible while this.element is reworked.
                    //promise = GUI.animationHandler.replace(this.element, this._temporaryClone);

                    promise = GUI.animationHandler.schedule(function afShowClone() {
                        this.element.replaceWith(temporaryClone);
                        temporaryClone.scrollTop(this._scrollTop);

                        this._restoreScrollLeft(temporaryClone, true);
                    }.bind(this));
                } else {
                    promise = Q.resolve();
                } // 3.) once replaced, do the reloading on the no longer visible element.


                if (this._useSmartReload() && !this.__forceFullReload) {
                    promise = promise.then(this._performSmartReload.bind(this));
                } else if (this._isSelectiveReload(selectiveArg) && !this.__forceFullReload) {
                    promise = promise.then(this._performReloadRowsSelective.bind(this, selectiveArg.section, selectiveArg.startRow, selectiveArg.numRows));
                } else {
                    this._destroyContent();

                    var sections = this._getNumSections();

                    this._updateTableViewStyle(sections); // Restore the scrollTop value after all sections have been set up


                    promise = promise.then(this.setUpSections.bind(this, sections)).then(function reloadAfterSetupSections(res) {
                        return res;
                    }.bind(this), e => {
                        debugger;
                    });
                } // 4.) when the element is updated, replace the clone with the updated element!


                promise = promise.then(GUI.animationHandler.schedule.bind(GUI.animationHandler, function afRemoveClone() {
                    // update the initial reload flag - regardless of what reload has been performed, it's now no longer initial.
                    this.initialReload = false;
                    temporaryClone && temporaryClone.replaceWith(this.element);
                    this.element.scrollTop(this._scrollTop); // Save the current horizontal scroll positions

                    this._restoreScrollLeft(this.element);
                }.bind(this))); // 5.) Internal Post reloading actions (e.g. for editable table view)

                promise = promise.then(function () {
                    // ensure that the promise chain doesn't break if no promise is returned.
                    return Q.all([this._internalHandleReloadPassed(), true]);
                }.bind(this)); // 6.) Allow external handling

                promise = promise.then(function () {
                    // Will only be called once after each performed reload, ignores reloads that have been avoided
                    // due to enqueueing (that's the difference to doing sth after the promise returned by calling
                    // "reload" resolves, as those will resolve for each time reload has been called.)
                    if (this.collectedContent.processAtTheEndOfReload) {
                        // ensure that the promise chain remains in place.
                        return Q.all([() => { return this.collectedContent.processAtTheEndOfReload(this) }, true]);
                    } else {
                        return Q.resolve();
                    }
                }.bind(this)); // 7.) Unlock the dataSource & delegate

                promise = promise.then(function () {
                    // unlock the dataSource & delegate - it's safe to update their content again.
                    this._setLocked(false);
                }.bind(this));
                this._reloadPrms = promise;
            }

            return promise;
        }

        /**
         * Used by lxEditableTableView to perform operations before a potentially enqueued operation reload is
         * started, the unlock is performed, the processAfterReload is called or the calling views promise resolves.
         * MUST return a promise!
         * @returns {Q.Promise<unknown>}
         * @private
         */
        _internalHandleReloadPassed() {
            return Q.resolve(); // nothing to do here, this is for the editableTableView.
        }

        /**
         * Used to identify whether or not the dataSource and delegate implemented in lxTableView are used, which
         * are capable of performing a smart reload. This method is used to change from selective reloading to
         * a full smart reload. Which will be just as fast, but with all the safety precautions in place (locking,
         * calling the internalHandleReloadPassed and the processAfterReload at the right time)
         * @returns {*|boolean}
         */
        smartReloadCapableDataSourceAndDelegate() {
            var smartReloadCapable = this.dataSource.hasOwnProperty("modifications") && this.delegate.hasOwnProperty("modifications");
            Debug.GUI.TableView && console.log(this.viewId, "smartReloadCapableDataSourceAndDelegate: " + smartReloadCapable);
            return smartReloadCapable;
        }

        _setLocked(isLocked) {
            Debug.GUI.TableView && console.log(this.viewId, "_setLocked: ", !!isLocked);

            if (isLocked) {
                // lock the dataSource - ensures the info which the table view accesses during reload doesn't change
                this.dataSource && this.dataSource.lock && this.dataSource.lock();
                this.delegate && this.delegate.lock && this.delegate.lock();
            } else {
                // unlock the dataSource & delegate - it's safe to update their content again.
                this.dataSource && this.dataSource.unlock && this.dataSource.unlock();
                this.delegate && this.delegate.unlock && this.delegate.unlock();
            }
        }

        /**
         * Removes the old style from the tableView and applies the new one - if changed.
         * @param [numSections] the number of sections, if not passed, aquired using _getNumSections()
         * @private
         */
        _updateTableViewStyle(numSections) {
            // check tableViewStyle
            var sections = numSections || this._getNumSections();

            var newStyle = this._getStyleForTable(sections);

            if (newStyle !== this._currentTableViewStyle) {
                this.element.removeClass(this._currentTableViewStyle);
                this._currentTableViewStyle = newStyle;
                this.element.addClass(this._currentTableViewStyle);
            }
        }

        /**
         * Excluded to a separate method since the asyncTableView will handle this different.
         * @param sections
         * @returns {*}
         */
        setUpSections(sections) {
            Debug.GUI.TableView && console.log(this.viewId, "setUpSections"); // create sections

            var all = [true]; // add a true to proceed if we have no sections!;

            for (var sectionIdx = 0; sectionIdx < sections; sectionIdx++) {
                this.table[sectionIdx] = []; // reset this section! we're reloading the whole thing anyway!

                var sectionFragment = this.getSectionTemplate(sectionIdx);
                all.push(this.setUpSection(sectionIdx, sectionFragment));
            }

            return Q.all(all);
        }
        getSectionTemplate(sectionIdx) {
            if (this.dataSource.customClassForSection) {
                return TableViewV2.Template.getSection(this._getSectionIdentifier(sectionIdx), this.dataSource.customClassForSection.call(this.dataSource, sectionIdx, this));
            } else {
                return TableViewV2.Template.getSection(this._getSectionIdentifier(sectionIdx));
            }
        }

        _getSectionIdentifier(sectionIdx) {
            return "section-" + sectionIdx;
        }

        /**
         * Reloads certain parts of a tableView while leaving the rest untouched.
         * @param sectionIdx The section whos content is to be reloaded
         * @param startIndex The start index of the cells to reload
         * @param nCells from the startIndex, how many cells need to be reloaded
         */
        reloadRowsSelective(sectionIdx, startIndex, nCells) {
            Debug.GUI.TableView && console.log(this.viewId, "reloadRowsSelective: " + sectionIdx + ", start at " + startIndex + ", nCells: " + nCells);

            if (sectionIdx === undefined) {
                console.error(this.viewId, "reloadRowsSelective received without providing a section info!", getStackObj());
                return this.reload();
            }
            this.__updateInternalContent();
            var initArgs = arguments;

            if (this.isWaitingForReload()) {
                return this._handleReloadWhileWaitingForOne();
            } else if (this.smartReloadCapableDataSourceAndDelegate()) {
                // use reload whenever possible, as it's safer (lock/unlock, promise chain, calling processAfterReload, ..)
                console.warn(this.viewId, "reloadRowsSelective - smart reload capable, use regular reload!", getStackObj());
                return this.reload();
            } else {
                Debug.GUI.TableView && console.info("TableView is ready to be reloaded selectively!");
                this._reloadPrms = this._performReloadRowsSelective(sectionIdx, startIndex, nCells);
                return this._reloadPrms;
            }
        }

        _performReloadRowsSelective(sectionIdx, startIndex, nCells) {
            Debug.GUI.TableView && console.log(this.viewId, "_performReloadRowsSelective: " + sectionIdx + ", start at " + startIndex + ", nCells: " + nCells);
            var newSection = false,
                sectionFragment,
                rowsContainer;

            if (!sectionIdx && sectionIdx !== 0) {
                return Q(true);
            }

            if (!this.table[sectionIdx]) {
                this.table[sectionIdx] = [];
                newSection = true;
            } else {
                // if its not a new section, there must be a preexisting section fragment to work on.
                sectionFragment = this.element.find(">#" + this._getSectionIdentifier(sectionIdx));
                newSection = sectionFragment.length === 0;
            }

            if (this.initialReload) {
                console.info(this.viewId, " --> Initial Reload, don't use selective, reload all");

                if (this.isWaitingForReload()) {
                    console.error(this.viewId, "An ongoing reload is currently in progress, this will potentially never resolve!");
                }

                return this.reload();
            } // ensure we've got all the data we need


            if (!startIndex) {
                startIndex = 0;
            }

            if (!nCells || nCells === 0) {
                // if we have nothing to reload selectively, why reload at all?
                return Q(true);
            }

            if (newSection) {
                // The section doesn't exist yet, add it!
                Debug.GUI.TableView && console.log(this.viewId, "   setting up a new section!"); // Create a new section

                sectionFragment = this.getSectionTemplate(sectionIdx);
                return this.setUpSection(sectionIdx, sectionFragment);
            } else {
                // reload selectively
                Debug.GUI.TableView && console.log(this.viewId, "   reloading specific cells within section!"); // now update the cells

                rowsContainer = sectionFragment.find(".section__rows");
                sectionFragment.find(".section__footer-view").remove();
                return this._reloadRowsInSection(sectionIdx, startIndex, nCells, rowsContainer);
            }
        }

        /**
         * Reloads the whole section at the given index if available
         * @param sectionIdx
         * @returns {*}
         */
        reloadSection(sectionIdx) {
            Debug.GUI.TableView && console.log(this.viewId, "reloadSection: " + sectionIdx, getStackObj());
            var sectionFragment = this.element.find(">#" + this._getSectionIdentifier(sectionIdx));
            this.__updateInternalContent();
            if (this.isWaitingForReload()) {
                return this._handleReloadWhileWaitingForOne();
            } else if (this._useSmartReload() || this.initialReload) {
                Debug.GUI.TableView && console.log(this.viewId, "   if a smart reload is possible or it's initial, call reload() instead.");
                return this.reload();
            } else if (this.table[sectionIdx] && sectionFragment) {
                Debug.GUI.TableView && console.log(this.viewId, "   perform section reload");

                this._setLocked(true);

                this._updateScrollTop();

                this._removeCellsSelective(sectionIdx, 0, this.table[sectionIdx].length);

                this._reloadPrms = this.setUpSection(sectionIdx, this.getSectionTemplate(sectionIdx), sectionFragment).then(function (sectionElm) {
                    this.restoreScrollTop();

                    this._setLocked(false);
                }.bind(this));
                return this._reloadPrms;
            } else {
                return this.reload();
            }
        }

        /**
         * removes a row at the given indexes, optional animated (right out)
         * @param sectionIdx
         * @param rowIdx
         * @param animated
         * @returns {Promise|boolean} Promise when animated, boolean if not animated (false = the cell couldn't be found)
         */
        removeRow(sectionIdx, rowIdx, animated) {
            Debug.GUI.TableView && console.log(this.viewId, "removeRow " + sectionIdx + "/" + rowIdx);
            var cell = this.table[sectionIdx][rowIdx];

            if (cell) {
                if (animated) {
                    var def = Q.defer();
                    cell.getElement().velocity({
                        translateZ: 0,
                        // Force HA by animating a 3D property
                        translateX: ["-100%", "0%"],
                        height: ["0px"]
                    }, {
                        duration: 200,
                        complete: function () {
                            this.removeRow(sectionIdx, rowIdx, false); // calling the same method but not animated.

                            def.resolve();
                        }.bind(this)
                    });
                    return def.promise;
                } else {
                    this.table[sectionIdx].splice(rowIdx, 1);

                    this._removeCellAndStoreForReuse(cell); // update the indexes


                    var rows = this.table[sectionIdx],
                        row,
                        i;

                    for (i = rowIdx; i < rows.length; i++) {
                        row = rows[i];
                        row.rowIdx = i;
                    }

                    return Q.resolve();
                }
            }

            return Q.reject("Cell " + sectionIdx + "/" + rowIdx + " no longer in content!");
        }

        /**
         * Will update both the header and the footer of the section
         * @param sectionIdx
         * @private
         */
        _handleSectionMetaChanged(sectionIdx) {
            Debug.GUI.TableView && console.log(this.viewId, "_handleSectionMetaChanged: " + sectionIdx);
            console.warn(this.viewId, "_handleSectionMetaChanged NOT implemented!");
        }

        /**
         * Will update both the header and the footer of the section
         * @param sectionIdx
         * @private
         */
        _handleSectionHeaderChanged(sectionIdx) {
            Debug.GUI.TableView && console.log(this.viewId, "_handleSectionHeaderChanged: " + sectionIdx);
            var sectionFragment = this.element.find(">#" + this._getSectionIdentifier(sectionIdx));

            this._setUpSectionHeader(sectionIdx, sectionFragment);
        }

        /**
         * Will update both the header and the footer of the section
         * @param sectionIdx
         * @private
         */
        _handleSectionFooterChanged(sectionIdx) {
            Debug.GUI.TableView && console.log(this.viewId, "_handleSectionFooterChanged: " + sectionIdx);
            var sectionFragment = this.element.find(">#" + this._getSectionIdentifier(sectionIdx));

            this._setUpSectionFooter(sectionIdx, sectionFragment);
        }

        /**
         * Called by smartReload, updates the sections customClasses!
         * @param sectionIdx
         * @param [previousClasses]       will be removed, false or undefined if none
         * @param [newClasses]            will be added, false or undefined if none
         * @private
         */
        _handleSectionClassesChanged(sectionIdx, previousClasses, newClasses) {
            Debug.GUI.TableView && console.log(this.viewId, "_handleSectionFooterChanged: " + sectionIdx);

            if (!previousClasses && !newClasses) {
                console.error(this.viewId, "_handleSectionClassesChanged - without anything to change!");
                return;
            }

            var sectionFragment = this.element.find(">#" + this._getSectionIdentifier(sectionIdx));
            GUI.animationHandler.schedule(function () {
                previousClasses && sectionFragment.removeClass(previousClasses);
                newClasses && sectionFragment.addClass(newClasses);
            });
        }

        /**
         * Will add or replace the header of this section.
         * @param sectionIdx
         * @param sectionFragment
         * @private
         */
        _setUpSectionHeader(sectionIdx, sectionFragment) {
            var previousHeader = sectionFragment.find(".section__header, .section__header-view, .section__header-custom"),
                // could be either of the three
                newHeader = null; // check section header information

            var sectionTitle = this.collectedContent.sections[sectionIdx].headerTitle,
                sectionStrongTitle = this.collectedContent.sections[sectionIdx].headerStrongTitle,
                sectionBoldTitle = this.collectedContent.sections[sectionIdx].headerBoldTitle,
                sectionDescription = this.collectedContent.sections[sectionIdx].headerDescription,
                sectionImage = this.collectedContent.sections[sectionIdx].headerImage,
                sectionRightButtonTitle = this.collectedContent.sections[sectionIdx].sectionRightButtonTitle,
                sectionHeaderElement = this.collectedContent.sections[sectionIdx].headerElement,
                sectionHeaderContent = this.collectedContent.sections[sectionIdx].headerContent;

            if (sectionHeaderContent) {
                newHeader = TableViewV2.Template.getHeaderForSectionContent(sectionHeaderContent);
            } else if (sectionHeaderElement) {
                newHeader = $(sectionHeaderElement); // may not be a jquery element.

                newHeader.addClass('section__header-custom');
            } else if (sectionStrongTitle && !sectionDescription) {
                newHeader = TableViewV2.Template.getStrongTitleSectionHeaderWithDescription(sectionStrongTitle, "");
            } else if (sectionBoldTitle && !sectionDescription) {
                newHeader = TableViewV2.Template.getBoldTitleSectionHeaderWithDescription(sectionBoldTitle, "");
            } else if (sectionDescription) {
                if (sectionStrongTitle) {
                    newHeader = TableViewV2.Template.getStrongTitleSectionHeaderWithDescription(sectionStrongTitle, sectionDescription);
                } else if (sectionBoldTitle) {
                    newHeader = TableViewV2.Template.getBoldTitleSectionHeaderWithDescription(sectionBoldTitle, sectionDescription);
                } else if (sectionTitle) {
                    newHeader = TableViewV2.Template.getTitleSectionHeaderWithDescription(sectionTitle, sectionDescription);
                } else {
                    newHeader = TableViewV2.Template.getTitleSectionHeaderWithDescription("", sectionDescription);
                }
            } else {
                newHeader = TableViewV2.Template.getHeaderForSection(sectionTitle, sectionRightButtonTitle, sectionImage);
            }

            return GUI.animationHandler.schedule(function () {
                if (previousHeader.length > 0 && newHeader) {
                    previousHeader.replaceWith(newHeader);
                } else if (!newHeader) {
                    previousHeader.remove();
                } else {
                    sectionFragment.prepend(newHeader);
                }
            }).then(function _setUpSectionHeaderAnimationFramePassed() {
                var actions = [];

                if (!!this.collectedContent.sections[sectionIdx].didSelectHeader) {
                    var header = sectionFragment.children().first();

                    if (header[0]) {
                        actions.push(function () {
                            header.addClass("clickable");
                        });
                        this.eventHandlers.push(Hammer(header[0]));
                        //this.eventHandlers[this.eventHandlers.length - 1].on(tapEvent(), this.delegate.didSelectHeader.bind(this.delegate, sectionIdx, this));
                        this.eventHandlers[this.eventHandlers.length - 1].on(tapEvent(), () => { this.collectedContent.sections[sectionIdx].didSelectHeader(sectionIdx, this); });
                    }
                }

                if (sectionRightButtonTitle) {
                    var rightSectionButton = sectionFragment.find(".header__edit-button");

                    if (rightSectionButton[0]) {
                        this.eventHandlers.push(Hammer(rightSectionButton[0])); // register button if rightSectionButtonTapped exists, otherwise it will be handled of the EditableTableView probably

                        if (!!this.collectedContent.sections[sectionIdx].rightSectionButtonTapped) {
                            this.eventHandlers[this.eventHandlers.length - 1].on(tapEvent(),  () => { this.collectedContent.sections[sectionIdx].rightSectionButtonTapped(sectionIdx, this); });
                        } // color for editButton


                        if (!!this.collectedContent.sections[sectionIdx].sectionRightButtonColor) {
                            var color = this.collectedContent.sections[sectionIdx].sectionRightButtonColor;
                            actions.push(function () {
                                rightSectionButton.css('color', color);
                            });
                        }
                    }
                }

                actions.length > 0 && GUI.animationHandler.scheduleAll(actions);
            }.bind(this));
        }

        /**
         * Will add/update the footer of this section
         * @param sectionIdx
         * @param sectionFragment
         * @private
         */
        _setUpSectionFooter(sectionIdx, sectionFragment) {
            var oldFooter = sectionFragment.find(".section__footer, .section__footer-custom"),
                // could be either of the two
                newFooter;

            var sectionFooterTitle = this.collectedContent.sections[sectionIdx].footerTitle,
                sectionFooterColor = this.collectedContent.sections[sectionIdx].footerColor,
                sectionFooterContent = this.collectedContent.sections[sectionIdx].footer,
                sectionFooterElement = this.collectedContent.sections[sectionIdx].footerElement;

            if (sectionFooterElement) {
                newFooter = $(sectionFooterElement); // may not be a jquery element.

                newFooter.addClass('section__footer-custom');
            } else if (sectionFooterContent !== null && typeof sectionFooterContent === "object") {
                // null is of type "object"
                newFooter = TableViewV2.Template.getFooterViewForSection(sectionFooterContent.iconSrc, sectionFooterContent.title, sectionFooterContent.message, sectionFooterContent.style);
            } else if (typeof sectionFooterTitle === "string") {
                newFooter = TableViewV2.Template.getFooterForSection(sectionFooterTitle, sectionFooterColor);
            }

            return GUI.animationHandler.schedule(function () {
                if (oldFooter.length > 0 && newFooter) {
                    oldFooter.replaceWith(newFooter);
                } else if (!newFooter) {
                    oldFooter.remove();
                } else {
                    sectionFragment.append(newFooter);
                }
            }).then(function _setUpSectionFooterAnimationFramePassed() {
                if (!!this.collectedContent.sections[sectionIdx].didSelectFooter && newFooter) {
                    GUI.animationHandler.schedule(function () {
                        newFooter.addClass("clickable");
                    });
                    this.eventHandlers.push(Hammer(newFooter[0]));
                    this.eventHandlers[this.eventHandlers.length - 1].on(tapEvent(), () => { this.collectedContent.sections[sectionIdx].didSelectFooter(sectionIdx, this); });
                }
            }.bind(this));
        }

        /**
         * Will set up a new section header & footer, as well as a new rows-container with its rows in it.
         * Then it'll either replace the elem provided with the fragment or append it to this.element,
         * @param sectionIdx
         * @param sectionFragment
         * @param [elmToReplace]    optional element to replace with the new section
         * @returns {*}
         */
        setUpSection(sectionIdx, sectionFragment, elmToReplace) {
            Debug.GUI.TableView && console.log(this.viewId, "setUpSection: " + sectionIdx, getStackObj());

            this._setUpSectionHeader(sectionIdx, sectionFragment); // create rows container


            var rowsContainer = TableViewV2.Template.getRowsContainer();
            var rowCntrAppended = GUI.animationHandler.schedule(function () {
                sectionFragment.append(rowsContainer);
            });

            this._setUpSectionFooter(sectionIdx, sectionFragment);

            var rows = this._getNumberOfRowsInSection(sectionIdx);

            var targetElem = this.element;
            GUI.animationHandler.schedule(function () {
                if (elmToReplace) {
                    elmToReplace.replaceWith(sectionFragment);
                } else {
                    targetElem.append(sectionFragment); // to be sure the viewDidAppear works correctly!
                }
            }); // wait for the rowContainer to be in the section fragment, otherwise the rows can't be reloaded

            return rowCntrAppended.then(this._reloadRowsInSection.bind(this, sectionIdx, 0, rows, rowsContainer));
        }

        cellForSectionAndRow(section, row) {
            var cell = null;

            if (this.table.hasOwnProperty(section)) {
                cell = this.table[section][row];
            } else {
                console.error(this.viewId, "Cannot get cellForSectionAndRow, section " + section + " does not exist!", getStackObj());
            }

            return cell;
        }

        // background view
        setBackgroundView(bgView) {
            // if an update is in progress, wait for it to finish - then perform it.
            if (this._backgroundUpdatePromise && this._backgroundUpdatePromise.isPending()) {
                Debug.GUI.BackgroundView && console.log(this.viewId, "setBackgroundView: " + (bgView ? bgView.viewId : "-none-") + " - ENQUEUE", getStackObj());
                Debug.GUI.TableView && console.log(this.viewId, "setBackgroundView in progress -- enqueue!"); // keeping this update as bound operation & overwriting potential previous calls
                // ensures that "the last one wins".

                this._backgroundUpdateEnqueued = this.setBackgroundView.bind(this, bgView);
                return this._backgroundUpdatePromise.finally(function () {
                    this._backgroundUpdatePromise = null;
                    var promise;

                    if (this._backgroundUpdateEnqueued) {
                        Debug.GUI.BackgroundView && console.log(this.viewId, "setBackgroundView: operation finished, performe enqeued operation");
                        Debug.GUI.TableView && console.log(this.viewId, "setBackgroundView finished, now perform last queued operation!");
                        promise = this._backgroundUpdateEnqueued();
                        this._backgroundUpdateEnqueued = null;
                    } else {
                        promise = Q.resolve();
                    }

                    return promise;
                }.bind(this));
            }

            Debug.GUI.BackgroundView && console.log(this.viewId, "setBackgroundView: " + (bgView ? bgView.viewId : "-none-"), getStackObj());
            Debug.GUI.TableView && console.log(this.viewId, "setBackgroundView: " + (bgView ? "true" : "false"));

            if (this.backgroundView && bgView) {
                this._backgroundUpdatePromise = this.replaceSubview(this.backgroundView, bgView);
            } else if (this.backgroundView) {
                this._backgroundUpdatePromise = this.removeSubview(this.backgroundView);
            } else if (bgView) {
                this._backgroundUpdatePromise = this.safePrependSubview(bgView);
            }

            this.backgroundView = bgView;
            GUI.animationHandler.toggleCssClass(this.element, "lx-table-view-v2--empty-view-shown", !!bgView);
            return this._backgroundUpdatePromise || Q.resolve();
        }

        /**
         * if a scrollTop is cached, it will be reused
         */
        restoreScrollTop() {
            if (typeof this._scrollTop === "number") {
                Debug.GUI.TableView && console.log(this.viewId, "restoreScrollTop: " + this._scrollTop);
                GUI.animationHandler.setScrollTop(this.element, this._scrollTop);
            }
        }

        resetScrollTop() {
            Debug.GUI.TableView && console.log(this.viewId, "resetScrollTop");
            delete this._scrollTop;
            GUI.animationHandler.setScrollTop(this.element, 0);
        }

        /**
         * The tableView will scroll so that the cell provided is visible.
         * @param section           target cells section
         * @param row               target cells row
         * @param [animate]         animates the scroll (optional)
         * @param [offset]          scroll offset in px (optional)
         * @param [shouldBeFirst]   if the cell should be displayed on top of the screen (optional)
         */
        scrollTo(section, row, animate, offset, shouldBeFirst) {
            Debug.GUI.TableView && console.log(this.viewId, "scrollTo: s=" + section + ",r=" + row + " - offset=" + offset);
            var success = false;

            try {
                var cell = this.cellForSectionAndRow(section, row).getElement(),
                    sectionElem = this.element.find(">#" + this._getSectionIdentifier(section)),
                    topOffset = cell[0].offsetTop + sectionElem[0].offsetTop,
                    viewHeight = this.element[0].clientHeight,
                    // height available for content
                    targetHeight = cell[0].clientHeight; // height of the targeted cell
                // with the current offset, the targeted cell would be the first in the view. we want it to be simply
                // visible, it's okay if its the last visible cell.

                if (!shouldBeFirst) {
                    topOffset -= viewHeight - targetHeight;
                }

                if (typeof topOffset === "number") {
                    topOffset += offset;
                } // ensure that we don't scroll unnecessarily if the cell is already visible.


                if (this.element.scrollTop() < topOffset) {
                    this.element.animate({
                        scrollTop: topOffset
                    }, animate ? 300 : 0);
                    success = true;
                }
            } catch (ex) {
                console.error("Could not scroll to " + section + "/" + row + "! " + ex.message);
            }

            return success;
        }

        // -----------------------
        // Private methods
        // -----------------------
        _shouldTvReload() {
            var shouldReload = false; // -1 is the default for the delegate TS's, if they've never been updated, using 0 as default here - they will be reloaded;

            var currDelTs = 0,
                currDsTs = 0;

            if (this.delegate && typeof this.delegate.updateTimestamp !== "undefined") {
                currDelTs = this.delegate.updateTimestamp;
            } else {
                currDelTs = timingNow();
            }

            if (this.dataSource && typeof this.dataSource.updateTimestamp !== "undefined") {
                currDsTs = this.dataSource.updateTimestamp;
            } else {
                currDsTs = timingNow();
            }

            shouldReload = this.dataSourceTs !== currDsTs || this.delegateTs !== currDelTs;

            if (shouldReload) {
                Debug.GUI.TableView && console.log(this.viewId, "_shouldTvReload   ---> RELOAD!");
            } else {
                Debug.GUI.TableView && console.warn(this.viewId, "_shouldTvReload   !!! UNCHANGED !!!");
            }

            this.dataSourceTs = cloneObject(currDsTs);
            this.delegateTs = cloneObject(currDelTs);
            return shouldReload;
        }

        /**
         * Stores the current scroll position to eventually restore it later.
         * @private
         */
        _updateScrollTop() {
            if (this.isVisible()) {
                this._scrollTop = this.element.scrollTop();
                Debug.GUI.TableView && console.log(this.viewId, "_updateScrollTop: " + this._scrollTop);
            } // reloads before viewDidAppear would reset the scrollTop to 0, as it's not in the dom yet

        }

        /**
         * Will update the left scroll positions stored for the card sections currently shown.
         * The data is stored using the section-elements ID attribute.
         * @private
         */
        _updateScrollLeft() {
            // iterate over all sections, store scroll left positions.
            if (this.isVisible()) {
                var cardSections = this.element.find(".card-section > .section__rows");
                this._scrollLeftCache = this._scrollLeftCache || {};
                cardSections.each(function (idx) {
                    var elm = cardSections[idx],
                        jqElem = $(elm),
                        storageId = jqElem.parent().attr("id");

                    if (storageId !== "" && typeof storageId !== "undefined") {
                        this._scrollLeftCache[storageId] = elm.scrollLeft;
                    }
                }.bind(this));
            }
        }

        /**
         * Will restore the scrollLeft-Positions of the card sections in this table.
         * @param [element]     if missing, this.element is searched for card sections
         * @param [restoreAll]  if true, regardless of the delegates data, all sections scrollLeft are restored.
         * @private
         */
        _restoreScrollLeft(element, restoreAll) {
            // iterate over all sections, restore the saved scroll left positions.
            var elemSource = element || this.element,
                cardSections = elemSource.find(".card-section > .section__rows");

            try {
                cardSections.each(function (idx) {
                    var elm = cardSections[idx],
                        jqElem = $(elm),
                        storageId = jqElem.parent().attr("id"),
                        prevScrollPos;

                    if (storageId !== "" && typeof storageId !== "undefined" && this._scrollLeftCache.hasOwnProperty(storageId) && (restoreAll || this._shouldRestoreScrollLeftOf(storageId))) {
                        prevScrollPos = this._scrollLeftCache[storageId];
                        elm.scrollLeft = prevScrollPos;
                    }
                }.bind(this));
            } catch (ex) {// Don't let an issue regarding the horizontal scroller crash the app.
                // Some debuglog suggest exceptions in this region, I couldn't verify them tho.
            }
        }

        /**
         * Looks up the dataSources resetScroll left property, if provided, the sections leftScroll position isn't
         * restored. Required e.g. in the audioZones history.
         * @param sectionId
         * @returns {boolean}
         * @private
         */
        _shouldRestoreScrollLeftOf(sectionId) {
            var sectionIdx = parseInt(sectionId.split("-")[1]);
            return this.collectedContent.sections[sectionIdx].resetScrollLeftOnReload;
        }

        /**
         * Method retuning the number of cells in a certain section, this is in a separate method to enable
         * interfering in the process. Required e.g. in the collectionView.
         * @param sectionIdx
         * @returns {*}
         * @private
         */
        _getNumberOfRowsInSection(sectionIdx) {
            return this.collectedContent.sections[sectionIdx].rows.length;
        }

        _getNumSections() {
            return this.collectedContent.sections.length;
        }

        _getStyleForTable(numSections) {
            var newStyle = this.collectedContent.styleForTableView;

            if (!newStyle) {
                if (numSections > 1 || this.collectedContent.sections[0].headerTitle || this.collectedContent.sections[0].sectionRightButtonTitle) {
                    newStyle = TableViewStyle.GROUPED;
                } else {
                    newStyle = TableViewStyle.PLAIN; // PLAIN is the default
                }
            }

            return newStyle;
        }

        /**
         * Creates a cell for the specified section/row and returns it. This is put into a separate implementation
         * to enable interfering with it in subclasses such as the collectionView if needed.
         * @param sectionIdx
         * @param rowIdx
         * @returns {*}
         * @private
         */
        _createRowAtIndex(sectionIdx, rowIdx) {
            var cell;
            let cellType = this.dataSource.cellTypeForCellAtIndex.call(this.dataSource, sectionIdx, rowIdx, this),
                dbgSource = null, // there may be a cell that is ready to be used from our dataSource (e.g. controlCells)
                specificCellClass = this._getTableViewSpecificCellClass();

            if (this.dataSource.cellForIndex) {
                cell = this.dataSource.cellForIndex.call(this.dataSource, sectionIdx, rowIdx);
                dbgSource = cell ? "DataSource-CellForIndex" : null;
            }

            // for test purposes, pass on a new instance of the dataSource & delegate, fill them with the collectedData to see if it works.

            if (!cell) {
                cell = GUI.getTableViewCellCache().getReusableCell(cellType);
                dbgSource = cell ? "GlobalCellCache" : null;

                if (cell) {
                    cell.updateAttributesForReuse(this.internalDel, this.internalDs, sectionIdx, rowIdx, this); // Remove the specific cell class which may still be there from the previous usage

                    cell.getElement().attr("class", cell.getElement().attr("class").split(" ").filter(cssClass => {
                        return !cssClass.hasPrefix("specific-cell-class");
                    }).join(" "));
                    cell.updateContent(this.collectedContent.sections[sectionIdx].rows[rowIdx].content);
                }
            }

            if (!cell && this.dataSource.dequeueReusableCell) {
                cell = this.dataSource.dequeueReusableCell.call(this.dataSource, cellType);
                dbgSource = cell ? "DataSource-DequeueReusableCell" : null;
            }

            if (!cell) {
                dbgSource = "New instance";
                var constructor = GUI.TableViewV2.Cells[cellType];
                cell = new constructor(this.internalDel, this.internalDs, cellType, sectionIdx, rowIdx, this);
            }

            if (specificCellClass && !specificCellClass.hasPrefix("specific-cell-class")) {
                developerAttention(`specificCellClass must be prefixed with "specific-cell-class". ${specificCellClass} was given!`);
            }

            cell.getElement().addClass(specificCellClass);
            Debug.GUI.TableView && console.log(this.viewId, "_createRowAtIndex: section: " + sectionIdx + " row: " + rowIdx + " type: " + cellType + " -- " + dbgSource);
            return Q.resolve(cell);
        }

        _reloadRowsInSection(sectionIdx, rowStartIdx, nRows, rowsContainer) {
            Debug.GUI.TableView && console.log(this.viewId, "_reloadRowsInSection: " + sectionIdx + "/" + rowStartIdx + "-" + nRows, getStackObj());
            var reloadTimerStart = Debug.GUI.TableView ? timingNow() : 0;
            var endIdx = rowStartIdx + nRows,
                prevRowElm = rowStartIdx >= 1 ? rowsContainer.children()[rowStartIdx - 1] : null,
                jqGroup;
            var startTs = timingNow();
            var rowElementsToRemove = [];
            var removeCleanupFns = [];
            var cellInstancesToRemove = this.table[sectionIdx].splice(rowStartIdx, nRows);
            cellInstancesToRemove.forEach(function _reloadRowsInSectionRemovePrep(cellInstance) {
                rowElementsToRemove.push(cellInstance.getElement());
                removeCleanupFns.push(this.removeFromHandledSubviews(cellInstance));
            }.bind(this));
            Debug.GUI.TableView && console.log(this.viewId, "    --> Prepare to remove " + cellInstancesToRemove.length + " cells passed, took " + timingDelta(startTs) + " ms");
            startTs = timingNow();
            return this._addRowsToTableSection(rowStartIdx, endIdx, sectionIdx).then(function (rowsToAdd) {
                Debug.GUI.TableView && console.log(this.viewId, "    --> Constructing new cells took " + timingDelta(startTs) + " ms");
                startTs = timingNow(); // calls viewDidLoad on the cells

                var preparePromise = this.bulkPrepareSubviewsBeforeAdd(rowsToAdd);
                return preparePromise.then(function _reloadRowsInSectionPrepareSubviewsBeforeAddPassed() {
                    Debug.GUI.TableView && console.log(this.viewId, "    --> PreparePromise passed, took " + timingDelta(startTs) + " ms");
                    startTs = timingNow();
                    var temp;

                    if (rowsToAdd.length) {
                        temp = rowsToAdd.map(function (row) {
                            return row.getElement();
                        });
                        jqGroup = $([]);
                        temp.forEach(function (jqElem) {
                            Array.prototype.push.apply(jqGroup, $.makeArray(jqElem));
                        });

                        if (!jqGroup) {
                            console.error("JQGroup is false?");
                        } // .add doesn't preserve the order, as it uses the DOM order which isn't right when reusing cells.
                        //jqGroup = temp.reduce(function (l, r) { return l.add(r); });

                    }

                    startTs = timingNow();
                    return GUI.animationHandler.schedule(function _reloadRowsInSectionAfAction(e) {
                        if (jqGroup) {
                            Debug.GUI.TableView && console.log(this.viewId, "   adding " + jqGroup.length + " elements!"); // There is a previous row, insert it after the previous row

                            if (rowsContainer.children().length === 0) {
                                Debug.GUI.TableView && console.log(this.viewId, "   appending " + jqGroup.length + " elements");
                                rowsContainer.append(jqGroup);
                            } else if (prevRowElm) {
                                Debug.GUI.TableView && console.log(this.viewId, "   " + jqGroup.length + " elements are insertedAfter prevRowElem");
                                jqGroup.insertAfter(prevRowElm);
                            } else {
                                Debug.GUI.TableView && console.log(this.viewId, "   " + jqGroup.length + " elements are prepended");
                                rowsContainer.prepend(jqGroup);
                            }

                            Debug.GUI.TableView && console.log(this.viewId, "   added " + jqGroup.length + " elements");
                        } else {
                            Debug.GUI.TableView && console.log(this.viewId, "   nothing to add!");
                        }

                        Debug.GUI.TableView && console.log(this.viewId, "AF _reloadRowsInSection: " + sectionIdx + "/" + rowStartIdx + "-" + nRows);

                        if (rowElementsToRemove.length > 0) {
                            Debug.GUI.TableView && console.log(this.viewId, "   removing " + rowElementsToRemove.length + " elements");
                        } else {
                            Debug.GUI.TableView && console.log(this.viewId, "   nothing to remove!");
                        }

                        rowElementsToRemove.forEach(function _reloadRowsInSectionRemoveRow(remElem) {
                            remElem[0].parentNode.removeChild(remElem[0]);
                        });
                    }.bind(this)).then(function _reloadRowsInSectionAnimationFramePassed() {
                        startTs = timingNow();
                        setTimeout(function () {
                            removeCleanupFns.forEach(function _reloadRowsInSectionCleanup(cleanupFn) {
                                cleanupFn().then(this._handleRemovedCell.bind(this));
                            }.bind(this));
                        }.bind(this), 5);
                        startTs = timingNow();
                        return this.bulkPrepareSubviewsAfterAdd(rowsToAdd).then(function _reloadRowsInSectionBulkAddToHandledSubviewsPassed() {
                            Debug.GUI.TableView && console.log(this.viewId, "   --> finished _reloadRowsInSection: " + sectionIdx + "/" + rowStartIdx + "-" + nRows + " took " + timingDelta(reloadTimerStart) + "ms");
                        }.bind(this));
                    }.bind(this));
                }.bind(this));
            }.bind(this));
        }

        _addRowsToTableSection(rowStartIdx, endIdx, sectionIdx) {
            var def = Q.defer(),
                rowsToAdd = [],
                rowElm,
                rowPromises = [];

            for (var rowIdx = rowStartIdx; rowIdx < endIdx; rowIdx++) {
                try {
                    rowPromises.push(this._createRowAtIndex(sectionIdx, rowIdx).then(function (rowIdx, row) {
                        if (!row) {
                            console.error("The row object at " + sectionIdx + "-" + rowIdx + " does not exist!");
                            endIdx--;
                            rowIdx--;
                        }

                        rowElm = row.getElement();

                        if (!rowElm) {
                            console.error("The row object at " + sectionIdx + "-" + rowIdx + " does no longer have an element!");
                            endIdx--;
                            rowIdx--;
                        }

                        rowElm[0].setAttribute('id', 'row-' + rowIdx);
                        rowsToAdd.push(row); // add row to section

                        this.table[sectionIdx].splice(rowIdx, 0, row);
                    }.bind(this, rowIdx)));
                } catch (e) {
                    console.error(e.stack);
                }
            }

            Q.all(rowPromises).then(function () {
                def.resolve(rowsToAdd);
            }.bind(this));
            return def.promise;
        }

        _getTableViewSpecificCellClass() {
            return null;
        }

        _destroyContent() {
            Debug.GUI.TableView && console.log(this.viewId, "_destroyContent"); // remove sections to improve painting

            var sections = this.element && this.element.find(".lx-table-view__section");
            GUI.animationHandler.schedule(function () {
                sections.remove();
            });

            this._removeCells();

            while (this.eventHandlers.length) {
                this.eventHandlers.pop().dispose();
            }

            this.table = [];
        }

        _removeCells() {
            Debug.GUI.TableView && console.log(this.viewId, "_removeCells", getStackObj());
            var cleanupFns = [],
                elemsToRemove = [],
                cellInstance;

            for (var sIdx = 0; sIdx < this.table.length; sIdx++) {
                var section = this.table[sIdx];

                if (!section) {
                    console.error("Section " + sIdx + " does not exist!");
                    continue;
                }

                for (var rIdx = 0; rIdx < section.length; rIdx++) {
                    cellInstance = section[rIdx];
                    cleanupFns.push(this.removeFromHandledSubviews(cellInstance));
                    elemsToRemove.push(cellInstance.getElement());
                }
            }

            return GUI.animationHandler.schedule(function _removeCellsAnimationFrame() {
                elemsToRemove.forEach(function _removeCellsAnimationFrameRemoveChild(remElem) {
                    remElem[0].parentNode.removeChild(remElem[0]);
                });
            }).then(function _removeCellsAnimationFramePassed() {
                cleanupFns.forEach(function _removeCellsCleanup(cleanupFn) {
                    cleanupFn().then(this._handleRemovedCell.bind(this));
                }.bind(this));
            }.bind(this));
        }

        /**
         * Removes the given cell and stores it for reuse
         * @param row
         * @return {Q.Promise<any>}
         * @private
         */
        _removeCellAndStoreForReuse(row) {
            return this.removeSubview(row).then(function (reuseId) {
                this._storeCellForReuse(reuseId, row);
            }.bind(this));
        }

        _storeCellForReuse(reuseID, row) {
            if (reuseID) {
                if (typeof this.dataSource.storeReusableCell === "function") {
                    if (!this.dataSource.storeReusableCell(reuseID, row)) {
                        row.destroy();
                    }
                } else {
                    row.triggerResetCellContent();
                    GUI.getTableViewCellCache().storeReusableCell(reuseID, row);
                }
            }
        }

        _removeCellsSelective(section, rowStartIdx, nCells) {
            Debug.GUI.TableView && console.log(this.viewId, "_removeCellsSelective: " + section + " / " + rowStartIdx + " - cells: " + nCells, getStackObj());
            var rowElementsToRemove = [],
                removeCleanupFns = [],
                cellInstancesToRemove = this.table[section].splice(rowStartIdx, nCells);
            cellInstancesToRemove.forEach(function _removeCellsSelectivePrepare(cellInstance) {
                rowElementsToRemove.push(cellInstance.getElement());
                removeCleanupFns.push(this.removeFromHandledSubviews(cellInstance));
            }.bind(this));
            return GUI.animationHandler.schedule(function _removeCellsSelectiveAf(e) {
                rowElementsToRemove.forEach(function _removeCellsSelectiveAfRemoveChild(remElem) {
                    remElem[0].parentNode.removeChild(remElem[0]);
                });
            }).then(function _removeCellsSelectiveAnimationFramePassed() {
                removeCleanupFns.forEach(function _removeCellsSelectiveCleanup(cleanupFn) {
                    cleanupFn().then(this._handleRemovedCell.bind(this));
                }.bind(this));
            }.bind(this));
        }

        _handleRemovedCell(cell) {
            var reuseID = cell.getReuseID && cell.getReuseID() || false;

            if (reuseID) {
                this._storeCellForReuse(reuseID, cell);
            } else if (this._hasReuseCellTypeFn(cell) && this._hasUpdateFn(cell)) {
                this._storeCellForReuse(cell.cellTypeForReuse(), cell);
            } else {
                try {
                    cell.destroy();
                } catch (ex) {
                    console.error("Removed cell cannot be destroyed!", ex);
                }
            }
        }

        _useSmartReload() {
            if (this.initialReload) {
                return false; // No smart reload when initial, the whole content needs to be created at first.
            }

            var smartReloadable = false,
                modA = this.dataSource ? this.dataSource.modifications : {},
                modB = this.delegate ? this.delegate.modifications : {},
                modifications = mergeModifications(modA, modB),
                reloadStructure = {
                    sectionEdits: [],
                    sectionsToRemove: [],
                    sectionsToAdd: []
                };

            if (!modifications) {
                return false;
            }

            if (modifications.change === CHANGE.EDITED && modifications.changes) {
                modifications.changes.forEach(function _useSmartReloadChangeIteration(sectionChange) {
                    switch (sectionChange.change) {
                        case CHANGE.EDITED:
                            reloadStructure.sectionEdits.push(this._getSmartSectionEdit(parseInt(sectionChange.key), sectionChange.changes));
                            break;

                        case CHANGE.ADDED:
                            reloadStructure.sectionsToAdd.push(parseInt(sectionChange.key));
                            break;

                        case CHANGE.REMOVED:
                            reloadStructure.sectionsToRemove.push(parseInt(sectionChange.key));
                            break;

                        default:
                            break;
                    }
                }.bind(this));
                this._smartReloadStructure = reloadStructure;
                smartReloadable = true;
            } else {
                Debug.GUI.TableView && console.log(this.viewId, "_useSmartReload - false, modification: " + modifications.change + " changes: ", modifications.changes);
                this._smartReloadStructure = null;
            }

            return smartReloadable;
        }

        _getSmartSectionEdit(sectionIdx, sectionModifications) {
            var reloadStructure = {
                rowsToRemove: [],
                rowsToReload: [],
                rowsToUpdate: [],
                sectionHeaderUpdate: false,
                sectionFooterUpdate: false,
                sectionClassUpdate: false,
                sectionIdx: parseInt(sectionIdx)
            };
            sectionModifications.forEach(function (secChange) {
                if (secChange.key === "rows") {
                    if (secChange.change === CHANGE.EDITED) {
                        secChange.changes.forEach(function (rowChange) {
                            switch (rowChange.change) {
                                case CHANGE.EDITED:
                                    if (this._smartEditCanUpdateRowContent(sectionIdx, rowChange)) {
                                        reloadStructure.rowsToUpdate.push(parseInt(rowChange.key));
                                    } else {
                                        reloadStructure.rowsToReload.push(parseInt(rowChange.key));
                                    }

                                    break;

                                case CHANGE.ADDED:
                                    // adding is the same as reloading, nothing is remove, but cells are added.
                                    reloadStructure.rowsToReload.push(parseInt(rowChange.key));
                                    break;

                                case CHANGE.REMOVED:
                                    reloadStructure.rowsToRemove.push(parseInt(rowChange.key));
                                    break;

                                default:
                                    break;
                            }
                        }.bind(this));
                    } else if (secChange.change === CHANGE.ADDED) {
                        // did have no rows at all, but now there are some.
                        secChange.to.forEach(function (item, idx) {
                            reloadStructure.rowsToReload.push(idx);
                        });
                    } else if (secChange.change === CHANGE.REMOVED) {
                        // did have some rows, but now the rows are gone
                        secChange.from.forEach(function (item, idx) {
                            reloadStructure.rowsToRemove.push(idx);
                        });
                    } else {
                        console.error("Unhandled section modification: ", secChange);
                    }
                } else if (this._isHeaderProperty(secChange.key)) {
                    // change, add or remove header!
                    Debug.GUI.TableView && console.log(this.viewId, "   Header was modified ! " + sectionIdx);
                    reloadStructure.sectionHeaderUpdate = true;
                } else if (new RegExp("footer", "i").test(secChange.key)) {
                    // change, add or remove  footer!
                    Debug.GUI.TableView && console.log(this.viewId, "   Footer was modified! " + sectionIdx);
                    reloadStructure.sectionFooterUpdate = true;
                } else if (secChange.key === "customClass") {
                    Debug.GUI.TableView && console.log(this.viewId, "   Custom class was modified! " + sectionIdx); // modifications provide a "from" and "to" attribute. Both when edited, only one when added/removed

                    switch (secChange.change) {
                        case CHANGE.EDITED:
                            reloadStructure.sectionClassUpdate = {
                                remove: secChange.from,
                                add: secChange.to
                            };
                            break;

                        case CHANGE.ADDED:
                            reloadStructure.sectionClassUpdate = {
                                add: secChange.to
                            };
                            break;

                        case CHANGE.REMOVED:
                            reloadStructure.sectionClassUpdate = {
                                remove: secChange.from
                            };
                            break;

                        default:
                            break;
                    }

                    Debug.GUI.TableView && console.log(this.viewId, "      " + JSON.stringify(reloadStructure.sectionClassUpdate));
                } else {
                    developerAttention("Something unhandled was modified in section! " + sectionIdx + ": " + JSON.stringify(getPrintableModifications({
                        changes: [secChange]
                    })));
                }
            }.bind(this)); // optimize, create reload selective runs (start + length instead of single indexes)

            reloadStructure.rowsToReload = this._extractRunsFrom(reloadStructure.rowsToReload);
            reloadStructure.rowsToRemove = this._extractRunsFrom(reloadStructure.rowsToRemove, true);
            return reloadStructure;
        }

        _isHeaderProperty(key) {
            var isHeaderProperty = new RegExp("header", "i").test(key);

            if (!isHeaderProperty) {
                isHeaderProperty |= key.indexOf("sectionRightButtonTitle") >= 0;
                isHeaderProperty |= key.indexOf("sectionRightButtonColor") >= 0;
                isHeaderProperty |= key.indexOf("rightSectionButtonTapped") >= 0;
            }

            return isHeaderProperty;
        }

        /**
         * Returns true if only the content has changed (= not the type) and the cell has the updateContent
         * functionality implemented. Important - it isn't enought if a parent class has implemented it!
         * @param sectionIdx
         * @param rowChange
         * @returns {boolean}
         * @private
         */
        _smartEditCanUpdateRowContent(sectionIdx, rowChange) {
            var rowIdx = parseInt(rowChange.key);
            var onlyContentAffected = rowChange.changes.length === 1 && rowChange.changes[0].key === "content";
            var cellCanBeUpdated = false;

            if (this.table[sectionIdx] && this.table[sectionIdx][rowIdx]) {
                cellCanBeUpdated = this._hasUpdateFn(this.table[sectionIdx][rowIdx]);

                if (onlyContentAffected && !cellCanBeUpdated) {
                    var cellType = this.collectedContent.sections[sectionIdx].rows[rowIdx].type;
                    console.warn(cellType + " could be super fast, if updateContent was properly implemented!");
                }
            } else {
                console.error(this.viewId, "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
                console.error(this.viewId, "_smartEditCanUpdateRowContent - no cell exists on " + sectionIdx + "/" + rowIdx);
                console.error(this.viewId, "   sections: " + this.table.length);
                this.table.forEach(function (section, idx) {
                    if (this.table[section]) {
                        console.error(this.viewId, "      section " + idx + " has " + this.table[section].length + " rows");
                        this.table[section].forEach(function (row, rIdx) {
                            console.error(this.viewId, "          row " + rIdx + ": ", cloneObject(this.table[section][row].content));
                        }.bind(this));
                    } else {
                        console.error(this.viewId, "     section " + idx + " is null!");
                    }
                }.bind(this));
                console.error(this.viewId, "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
            }

            return onlyContentAffected && cellCanBeUpdated;
        }

        /**
         * Check if the prototype has an updateContent function, if so, super fast content updates are available.
         * Important, the updateContent fn implemented must call the _updateContent of the base!
         * @param toCheck   the cell instance to check.
         * @returns {boolean}
         * @private
         */
        _hasUpdateFn(toCheck) {
            return this.__hasFunction(toCheck, "updateContent");
        }

        _hasReuseCellTypeFn(toCheck) {
            return this.__hasFunction(toCheck, "cellTypeForReuse");
        }

        __hasFunction(toCheck, fnToCheck) {
            var allFns;
            var props = Object.getOwnPropertyNames(Object.getPrototypeOf(toCheck));
            return props.filter(function (e) {
                try {
                    if (typeof toCheck[e] === 'function' && e === fnToCheck) return true;
                } catch (ex) {
                    console.error("Failed to determine if the cell prototype implements '" + fnToCheck + "! " + e);
                    return false;
                }
            }).length > 0;
        }

        /**
         * Converts an array of indexes to an array containing run-objects, where each run object
         * specifies at what index the run starts and how many indexes are following.
         * This allows for performing bulk operations on a range of cells.
         * @param indexes
         * @param [reverseOrder]    optional, if true - the runs start from the last index to the first
         * @returns {[]}
         * @private
         */
        _extractRunsFrom(indexes, reverseOrder) {
            var changeList = [],
                modIndex; // establish change list

            for (var i = indexes.length - 1; i >= 0; i--) {
                modIndex = indexes[i];

                if (changeList.length < modIndex) {
                    changeList[modIndex + 1] = false; // ensure theres a "false" at the last pos
                }

                changeList[modIndex] = true;
            } // extract runs


            var currRun = null;
            var runs = [];

            for (i = changeList.length; i >= 0; i--) {
                if (changeList[i - 1] && changeList[i] && !changeList[i + 1]) {
                    currRun = {
                        end: i
                    };
                }

                if (!changeList[i - 1] && changeList[i] && !changeList[i + 1]) {
                    //single change
                    runs.push({
                        start: i,
                        end: i,
                        length: 1
                    });
                }

                if (!changeList[i - 1] && changeList[i] && changeList[i + 1]) {
                    if (!currRun) {
                        console.error("Untracked run end detected!");
                    } else {
                        currRun.start = i;
                        currRun.length = currRun.end - currRun.start + 1;
                        runs.push(currRun);
                        currRun = null;
                    }
                }
            }

            if (!reverseOrder) {
                runs = runs.sort(function (a, b) {
                    return a.start - b.start;
                });
            }

            return runs;
        }

        _performSmartReload() {
            var timeStart = Debug.GUI.TableView ? timingNow() : 0;
            Debug.GUI.TableView && console.log(this.viewId, "_performSmartReload", this._smartReloadStructure);
            var reloadActions = [],
                sectionActions;
            var allPromises = []; // iterate over sectionEdit-Objects and retrieve the corresponding actions for it.

            this._smartReloadStructure.sectionEdits.forEach(function _performSmartReloadSectionEditIteration(sectionStructure) {
                sectionActions = [];
                sectionStructure.rowsToUpdate.forEach(function (rowIdx) {
                    Debug.GUI.TableView && console.log(this.viewId, "   Performing row content update - " + sectionStructure.sectionIdx + "/" + rowIdx); // perform async, as the content update may take too long if the cells don't handle it properly
                    // via animationFrame

                    var updateDef = Q.defer();
                    allPromises.push(updateDef.promise);
                    setTimeout(function asyncUpdateRowContent() {
                        updateDef.resolve(this._updateContentOfRow(sectionStructure.sectionIdx, rowIdx));
                    }.bind(this), 0);
                }.bind(this));
                sectionStructure.rowsToReload.forEach(function (reloadRun) {
                    Debug.GUI.TableView && console.log(this.viewId, "   Enqueuing reload run - " + sectionStructure.sectionIdx + "/" + reloadRun.start + " - " + reloadRun.length);
                    sectionActions.push(this._performReloadRowsSelective.bind(this, sectionStructure.sectionIdx, reloadRun.start, reloadRun.length));
                }.bind(this));
                sectionStructure.rowsToRemove.forEach(function (removeRun) {
                    Debug.GUI.TableView && console.log(this.viewId, "   Enqueuing remove row action - " + sectionStructure.sectionIdx + "/" + removeRun.start + " - " + removeRun.length);
                    sectionActions.push(this._removeCellsSelective.bind(this, sectionStructure.sectionIdx, removeRun.start, removeRun.length));
                }.bind(this));

                if (sectionStructure.sectionFooterUpdate) {
                    Debug.GUI.TableView && console.log(this.viewId, "   Section footer updated - " + sectionStructure.sectionIdx);
                    sectionActions.push(this._handleSectionFooterChanged.bind(this, sectionStructure.sectionIdx));
                }

                if (sectionStructure.sectionHeaderUpdate) {
                    Debug.GUI.TableView && console.log(this.viewId, "   Section header updated - " + sectionStructure.sectionIdx);
                    sectionActions.push(this._handleSectionHeaderChanged.bind(this, sectionStructure.sectionIdx));
                }

                if (sectionStructure.sectionClassUpdate) {
                    Debug.GUI.TableView && console.log(this.viewId, "   Section classed updated - " + sectionStructure.sectionIdx + ": " + JSON.stringify(sectionStructure.sectionClassUpdate));
                    sectionActions.push(this._handleSectionClassesChanged.bind(this, sectionStructure.sectionIdx, sectionStructure.sectionClassUpdate.remove, sectionStructure.sectionClassUpdate.add));
                }

                reloadActions.push(sectionActions);
            }.bind(this));

            this._smartReloadStructure.sectionsToAdd.forEach(function _performSmartReloadSectionAddIteration(sectionIdx) {
                Debug.GUI.TableView && console.log(this.viewId, "   Enqueuing add section - " + sectionIdx + ", operation: " + reloadActions.length);
                reloadActions.push([this._addSection.bind(this, sectionIdx)]);
            }.bind(this));

            this._smartReloadStructure.sectionsToRemove.forEach(function _performSmartReloadSectionRemoveIteration(sectionIdx) {
                Debug.GUI.TableView && console.log(this.viewId, "   Enqueuing remove section - " + sectionIdx + ", operation: " + reloadActions.length);
                reloadActions.push([this._removeSection.bind(this, sectionIdx)]);
            }.bind(this)); // execute all sections in parallel (inside the sections it's sequential again).


            reloadActions.forEach(function iterateSectionActions(secActions, secActionsIdx) {
                allPromises.push(this._executeSmartReloadSectionActions(secActions, secActionsIdx));
            }.bind(this));
            return Q.all(allPromises).then(function () {
                Debug.GUI.TableView && console.log(this.viewId, "---> _performSmartReload took " + timingDelta(timeStart) + " ms");
            }.bind(this));
        }

        /**
         * Will iterate across the list of sectionActions provided & execute them sequentially. The promise returned
         * will resolve once the last action has been performed.
         * @param secActions        array of section action functions, each should return a promise;
         * @param secActionsIdx     the index of the section actions currently being processed, only for logging
         * @returns {Q.Promise<unknown>}    the promise that will resolve once all actions have been performed.
         * @private
         */
        _executeSmartReloadSectionActions(secActions, secActionsIdx) {
            if (secActions.length === 0) {
                return Q.resolve(); // no actions for this section.
            }

            var sectionDeferred = Q.defer();
            setTimeout(function asyncStartIterateSectionActions() {
                try {
                    var basePromise = null;
                    var lastPromise = null;
                    secActions.forEach(function executeSectionActions(action, actionIdx) {
                        if (!basePromise) {
                            basePromise = action() || Q.resolve();
                            lastPromise = basePromise;
                        } else {
                            lastPromise = lastPromise.then(function () {
                                return action() || Q.resolve();
                            }.bind(this), function (err) {
                                console.error("Failed at some reload action! ", err);
                                return action() || Q.resolve();
                            });
                        }
                    }.bind(this));
                    sectionDeferred.resolve(lastPromise);
                } catch (e) {
                    // Gotta catch 'em all
                    // We got an exception, the TableView may not be there anymore
                    sectionDeferred.reject(e);
                }
            }, 0);
            return sectionDeferred.promise;
        }

        _addSection(sectionIdx) {
            this.table[sectionIdx] = [];
            var sectionFragment = this.getSectionTemplate(sectionIdx);
            return this.setUpSection(sectionIdx, sectionFragment);
        }

        _removeSection(sectionIdx) {
            var promises = [];
            var sectionFragment;
            promises.push(this._removeCellsSelective(sectionIdx, 0, this.table[sectionIdx].length));
            sectionFragment = this.element.find(">#" + this._getSectionIdentifier(sectionIdx));
            sectionFragment.remove();
            return Q.all(promises);
        }

        _updateContentOfRow(section, row) {
            if (this.table && this.table[section] && this.table[section][row]) {
                var rowInstance = this.table[section][row],
                    newContent = this.collectedContent.sections[section].rows[row].content;
                return rowInstance.updateContent(newContent) || Q.resolve();
            } else {
                // this error did occasionally show up in logs. trying to determine where this comes from.
                console.error(this.viewId, "_updateContentOfRow failed - row doesn't exist! section: " + section + ", row: " + row);
                console.error(this.viewId, "Stack: " + JSON.stringify(getStack()));
                return Q.reject();
            }
        }

        _isSelectiveReload(selectiveArgs) {
            var newContent = this._gatherContent();

            var mod = this.__detectModifications(this._selectiveCheckPrevContent, newContent);

            var result = false;

            if (this._checkForSelectiveReload(mod) && !this.initialReload) {
                this.__logTrk("Selective reload is feasible!");

                this._getSelectiveReloadInfo(mod, selectiveArgs);

                result = true;
            } else {
                this.__logTrk("Selective reload not an option!");
            }

            this._selectiveCheckPrevContent = newContent;
            return result;
        }

        /**
         * Extracts which parts of the content have changed (e.g. have been removed/added/modified)
         * @param oldContent
         * @param newContent
         * @returns {{sections: {}}}
         * @private
         */
        __detectModifications(oldContent, newContent) {
            this.__logTrk("__detectModifications");

            var modifications = {
                sections: {}
            };
            var oldSection,
                newSection,
                oldRow,
                newRow,
                rowsModified = false,
                sectionsModified = false;
            var rIdx, sIdx;
            var oldCntnt = oldContent;
            var newCntnt = newContent;

            if (!oldCntnt || !oldCntnt.sections) {
                this.__logTrk("    no old content to compare, assuming empty");

                oldCntnt = oldCntnt || {
                    sections: []
                };
                oldCntnt.sections = oldCntnt.sections || [];
            }

            if (!newCntnt || !newCntnt.sections) {
                this.__errTrk("    no new content to compare, assuming empty");

                newCntnt = newCntnt || {
                    sections: []
                };
                newCntnt.sections = newCntnt.sections || [];
            }

            this.__logTrk("    oldSections: " + oldCntnt.sections.length);

            this.__logTrk("    newSections: " + newCntnt.sections.length);

            if (oldCntnt.sections.length !== newCntnt.sections.length) {
                modifications.sectionCountChanged = true;
            }

            for (sIdx = 0; sIdx < Math.max(oldCntnt.sections.length, newCntnt.sections.length); sIdx++) {
                oldSection = sIdx < oldCntnt.sections.length ? oldCntnt.sections[sIdx] : {
                    rows: []
                };
                newSection = sIdx < newCntnt.sections.length ? newCntnt.sections[sIdx] : {
                    rows: []
                };
                rowsModified = false;

                if (oldSection.rows.length !== newSection.rows.length) {
                    modifications.sections[sIdx] = modifications.sections[sIdx] || {
                        rows: []
                    };
                    modifications.sections[sIdx].rowCountChanged = true;
                }

                if (oldSection.hash !== newSection.hash) {
                    for (rIdx = 0; rIdx < Math.max(oldSection.rows.length, newSection.rows.length); rIdx++) {
                        oldRow = rIdx < oldSection.rows.length ? oldSection.rows[rIdx] : {};
                        newRow = rIdx < newSection.rows.length ? newSection.rows[rIdx] : {};

                        if (oldRow.hash !== newRow.hash) {
                            rowsModified = true;
                            modifications.sections[sIdx] = modifications.sections[sIdx] || {
                                rows: []
                            };
                            modifications.sections[sIdx].rows.push(rIdx);
                        }
                    }

                    if (!rowsModified) {
                        // either section header/footer modified or rows where added removed
                        modifications.sections[sIdx] = modifications.sections[sIdx] || {
                            rows: []
                        };
                    }
                }
            }

            if (newCntnt.hash !== oldCntnt.hash && modifications.length === 0) {
                // other information changed!
                modifications.table = true;
            }

            return modifications;
        }

        _checkForSelectiveReload(modifications) {
            var tableChanged = modifications.table,
                sectionCountChanged = modifications.sectionCountChanged,
                oneSectionAffected = Object.keys(modifications.sections).length === 1;

            if (Debug.GUI.TableView) {
                var reasons = [];
                tableChanged && reasons.push("Table itself Changed");
                sectionCountChanged && reasons.push("Section count changed");
                !oneSectionAffected && reasons.push("More than one or 0 sections affected");

                if (tableChanged || sectionCountChanged || !oneSectionAffected) {
                    console.log(this.viewId, "_checkForSelectiveReload - false, because: " + reasons.join(", "));
                } else {
                    console.log(this.viewId, "_checkForSelectiveReload - true!");
                }
            }

            return !tableChanged && // table info itself mustn't change
                !sectionCountChanged && // section count mustn't change
                oneSectionAffected; // only 1 section must be affected.
        }

        _getSelectiveReloadInfo(modifications, reloadInfo) {
            Debug.GUI.TableView && console.log(this.viewId, "_getSelectiveReloadInfo");
            var sectionIdx = parseInt(Object.keys(modifications.sections)[0]),
                sectionMod = modifications.sections[sectionIdx],
                rowIdx,
                countRows; // section mod may be missing.

            if (sectionMod && sectionMod.rows && !sectionMod.rowCountChanged) {
                rowIdx = sectionMod.rows[0];
                countRows = sectionMod.rows[sectionMod.rows.length - 1] - rowIdx + 1;
                Debug.GUI.TableView && console.log(this.viewId, "   rows provided: " + rowIdx + "/" + countRows);
            } else {
                rowIdx = 0;
                countRows = this._getNumberOfRowsInSection(sectionIdx);
                Debug.GUI.TableView && console.log(this.viewId, "   rows NOT provided, assuming all: " + rowIdx + "/" + countRows);
            }

            reloadInfo.section = sectionIdx;
            reloadInfo.startRow = rowIdx;
            reloadInfo.numRows = countRows;
            return reloadInfo;
        }

        _gatherContent() {
            var newContent = {
                    sections: []
                },
                sectionContent,
                rowsInSection,
                cellContent,
                numSections = this._getNumSections();

            newContent.style = this._getStyleForTable(numSections);

            for (var sIdx = 0; sIdx < numSections; sIdx++) {
                sectionContent = {
                    rows: []
                };

                this.__getSectionContent(sIdx, sectionContent);

                rowsInSection = this._getNumberOfRowsInSection(sIdx);

                for (var rIdx = 0; rIdx < rowsInSection; rIdx++) {
                    cellContent = {
                        row: rIdx
                    };

                    this.__getCellContent(sIdx, rIdx, cellContent);

                    cellContent.hash = JSON.stringify(cellContent).hashCode();
                    sectionContent.rows.push(cellContent);
                }

                sectionContent.hash = JSON.stringify(sectionContent).hashCode();
                newContent.sections.push(sectionContent);
            }

            return newContent;
        }

        __getSectionContent(sectionIdx, content) {
            content.sectionTitle = this.collectedContent.sections[sectionIdx].headerTitle;
            content.sectionStrongTitle = this.collectedContent.sections[sectionIdx].headerStrongTitle;
            content.sectionBoldTitle = this.collectedContent.sections[sectionIdx].headerBoldTitle;
            content.sectionDescription = this.collectedContent.sections[sectionIdx].headerDescription;
            content.sectionImage = this.collectedContent.sections[sectionIdx].headerImage;
            content.sectionRightButtonTitle = this.collectedContent.sections[sectionIdx].sectionRightButtonTitle;
            content.sectionHeaderElement = this.collectedContent.sections[sectionIdx].headerElement;
            content.sectionHeaderContent = this.collectedContent.sections[sectionIdx].headerContent;
            content.sectionFooterTitle = this.collectedContent.sections[sectionIdx].footerTitle;
            content.sectionFooterColor = this.collectedContent.sections[sectionIdx].footerColor;
            content.sectionFooterContent = this.collectedContent.sections[sectionIdx].footer;
            content.sectionFooterElement = this.collectedContent.sections[sectionIdx].footerElement;
            content.headerClickable = !!this.collectedContent.sections[sectionIdx].didSelectHeader;

            if (content.sectionRightButtonTitle) {
                content.sectionRightButtonTitleClickable = !!this.collectedContent.sections[sectionIdx].rightSectionButtonTapped;

                if (!!this.collectedContent.sections[sectionIdx].sectionRightButtonColor) {
                    content.sectionRightButtonColorForHeaderInSection = this.collectedContent.sections[sectionIdx].sectionRightButtonColor;
                }
            }

            if (!!this.collectedContent.sections[sectionIdx].didSelectFooter && (content.sectionFooterTitle || content.sectionFooterContent || content.sectionFooterElement)) {
                content.footerClickable = true;
            }
        }

        __getCellContent(sectionIdx, rowIdx, cell) {
            cell.cellType = this.dataSource.cellTypeForCellAtIndex.call(this.dataSource, sectionIdx, rowIdx, this);
            cell.constructor = GUI.TableViewV2.Cells[cell.cellType];
            cell.specificClass = this._getTableViewSpecificCellClass();
            cell.content = this.dataSource.contentForCell(null, sectionIdx, rowIdx);
        }

        // ------------------------------------------------------------------------------------------------
        //      Reload Tracking Methods
        // ------------------------------------------------------------------------------------------------
        __prepareReloadTracking() {
            this.__reloadCounter = 0;
            this.__reloadTracking = {};
            this.__previousContent = {}; // retrieve identifier for table

            this.__tableId = this.name + "-";
            var callerInfo,
                i = 2;

            do {
                callerInfo = getCallerInfo(i++);
            } while (callerInfo.includes(this.name));

            this.__tableId += callerInfo;
            this.__tableId += "-" + lxFormat("%04.0d", getRandomIntInclusive(0, 9999));

            this.__logTrk("Init");
        }

        __destroyTracking() {
            this.__logTrk("Destroy");
        }

        __logTrk(message) {
            Debug.GUI.TableViewTiming && console.log("TableViewTiming", this.__tableId + "  " + message);
        }

        __errTrk(message) {
            Debug.GUI.TableViewTiming && console.error("TableViewTiming", this.__tableId + "  " + message);
        }

        __trackTableViewReload(promise) {
            var idx = this.__reloadCounter++;

            this.__handleTableViewReloadStarted(idx);

            promise.then(function () {
                this.__handleTableViewReloadPassed(idx);
            }.bind(this), function (err) {
                this.__handleTableViewReloadPassed(idx);
            });
            return idx;
        }

        __handleTableViewReloadStarted(idx) {
            var trackingObj = {},
                newContent = this._gatherContent();

            var prevString = JSON.stringify(this.__previousContent);
            var newString = JSON.stringify(newContent);
            trackingObj.rightful = prevString !== newString;

            if (!trackingObj.rightful) {
                this.__errTrk("Not a rightful reload!");

                this.__errTrk(prevString);

                this.__errTrk(newString);
            }

            this.__previousContent = newContent;
            this.__reloadTracking[idx] = trackingObj;
            trackingObj.start = timingNow();
            trackingObj.stack = new Error().stack;
        }

        __handleTableViewReloadPassed(idx) {
            var trackingObj = this.__reloadTracking[idx];
            trackingObj.end = timingNow();
            trackingObj.duration = Math.round(trackingObj.end - trackingObj.start);

            if (trackingObj.rightful) {
                this.__logTrk("reload " + lxFormat("%03d", idx) + " passed, " + trackingObj.duration + "ms");
            } else {
                this.__errTrk("reload " + lxFormat("%03d", idx) + " passed, " + trackingObj.duration + "ms  - NOTHING changed!");

                this.__errTrk(trackingObj.stack);
            }
        }

    }

    GUI.TableViewV2 = TableViewV2;
    return GUI;
}(window.GUI || {});

var TableViewStyle = {
    PLAIN: "table-view-style-plain",
    SECTIONED: "table-view-style-sectioned",
    GROUPED: "table-view-style-grouped",
    FLAT: "table-view-style-flat",
    TRANSLUCENT: "table-view-style-translucent",
    COMFORT_MODE_2020: "table-view-style-grouped table-view-style-comfort-mode-2020",
    MENU: "table-view-style-grouped table-view-style-menu"
};

var _stringifyTableContent = function _stringifyTableContent(originalTableContent) {
    var modifiedTableContent = cloneObjectDeep(originalTableContent);
    modifiedTableContent = modifiedTableContent.map(function (section) {
        if (section && section.headerElement && "html" in section.headerElement) {
            section.headerElement = section.headerElement.html();
        }

        if (section && section.footerElement && "html" in section.footerElement) {
            section.footerElement = section.footerElement.html();
        }

        return section;
    });
    return JSON.stringify(modifiedTableContent);
};
/**
 * @param initialTableContent
 * @param defaultCellType
 * @param altSrc alternative dataSource, will be used (instead of tableContent) if the altSrc has a method implemented
 * @returns {{}}
 */


var tableViewDataSource = function tableViewDataSource(initialTableContent, defaultCellType, altSrc) {
    var isInitialUpdate = true;
    var __isLocked = false;
    var __cachedContent = null;
    var __cachedUpdateTimeStamp = null;

    var __updateTimeStamp = -1;

    var __compareContent = null;
    var __modifications = null;
    var __name = "tableViewDataSource";

    var __tableContent = cloneObjectDeep(initialTableContent);
    var __dsTableView = null;

    return {
        get delegateAltSrc() {
            return altSrc;
        },
        get tableContent() {
            return __tableContent;
        },

        set tableContent(newVal) {
            console.warn("Use the .update() to set the tableContent");
        },

        get updateTimestamp() {
            return __updateTimeStamp;
        },

        // no setter, cannot be modified from outside
        get modifications() {
            return __modifications;
        },

        set tableView(newVal) {
            __dsTableView = newVal;
        },
        get tableView() {
            return __dsTableView;
        },

        // no setter, cannot be modified from outside

        /**
         * Used by the table view to avoid content being modified while the table is still reloading.
         * Incoming update calls will not modify the current content, remember it until being unlocked.
         */
        lock: function lock() {
            Debug.GUI.TableDataSource && console.log(__name, "lock");
            __isLocked = true;
        },

        /**
         * Counterpart to lock()
         */
        unlock: function unlock() {
            Debug.GUI.TableDataSource && console.log(__name, "unlock");
            __isLocked = false;

            if (__cachedContent) {
                // call updateContent, this will recompute the modifications to the dataset of the time where it was
                // locked --> Important as the smartReload of the tableView uses the modifications set of the del/Datasource
                this.update(__cachedContent);
                __cachedContent = null;
                __cachedUpdateTimeStamp = null;
            }
        },
        update: function update(newTableContent, actionParam) {
            Debug.GUI.TableDataSource && console.log(__name, "update");
            var newStringifiedTableContent,
                modified = true,
                oldHash = -1,
                newHash = -1;

            if (!isInitialUpdate) {
                Debug.GUI.TableDataSource && console.log(__name, "   -> no longer initial, check");

                if (newTableContent) {
                    newStringifiedTableContent = _stringifyTableContent(newTableContent);
                    newHash = newStringifiedTableContent.hashCode();
                    oldHash = __compareContent ? JSON.stringify(__compareContent).hashCode() : -1;
                    modified = newHash !== oldHash;
                }
            } else {
                Debug.GUI.TableDataSource && console.log(__name, "   -> initial, mark as modified");
                isInitialUpdate = false;
            } // Why do we check for [object object] in the newStringifiedTableContent?
            // jQuery Objects get serialized to [object object], we simply check for that


            if (!modified && newStringifiedTableContent.indexOf("[object Object]") !== -1) {
                // stringify on jquery doesn't cover its content.
                Debug.GUI.TableDataSource && console.log(__name, "   -> jQuery contained, check modifications instead of hash");
                __modifications = getModifications(__compareContent, newTableContent);
                modified = __modifications !== CHANGE.NONE;

                if (modified) {
                    Debug.GUI.TableDataSource && console.log(__name, "   -> Modifications detected: ", getPrintableModifications(__modifications));
                } else {
                    Debug.GUI.TableDataSource && console.log(__name, "   -> NOT modified, neither via hash nor getModifications");
                }
            } else if (modified) {
                Debug.GUI.TableDataSource && console.log(__name, "   -> Modified " + oldHash + "->" + newHash);
                __modifications = getModifications(__compareContent, newTableContent);
                Debug.GUI.TableDataSource && console.log(__name, "   -> Modifications: ", getPrintableModifications(__modifications));
                Debug.GUI.TableDataSource && console.log(__name, "   Was: ", cloneObject(__compareContent));
                Debug.GUI.TableDataSource && console.log(__name, "   Now: ", cloneObject(newTableContent));
            } else {
                Debug.GUI.TableDataSource && console.log(__name, "   -> NOT modified " + oldHash + "->" + newHash);
                Debug.GUI.TableDataSource && console.log(__name, " prev: ", cloneObject(__compareContent));
                Debug.GUI.TableDataSource && console.log(__name, "  new: ", cloneObject(newTableContent));
            } // if a reload is currently in progress, don't update the content!


            if (__isLocked) {
                Debug.GUI.TableDataSource && console.log(__name, " --> locked, reload in progress!");

                if (modified) {
                    // only cache sth if the data has changed!
                    __cachedContent = cloneObjectDeep(newTableContent);
                    __cachedUpdateTimeStamp = timingNow();
                }
            } else {
                if (modified) {
                    __updateTimeStamp = timingNow();
                }

                __tableContent = cloneObjectDeep(newTableContent);
                __compareContent = cloneObjectDeep(newTableContent);
            }

            this.tableView && this.tableView.dsUpdated();
            return modified;
        },
        // called by the editableTableView in order to keep the compareContent up to date.
        tableContentMoved: function tableContentMoved(sectionIdx, oldIdx, newIdx) {
            Debug.GUI.TableDataSource && console.log(__name, "tableContentMoved: Section: " + sectionIdx + ", from: " + oldIdx + " to " + newIdx);

            var movingContent = __tableContent[sectionIdx].rows.splice(oldIdx, 1)[0];

            __tableContent[sectionIdx].rows.splice(newIdx, 0, movingContent);

            __compareContent = cloneObjectDeep(__tableContent);
        },
        // called by the editableTableView in order to keep the compareContent up to date.
        tableContentRemoved: function tableContentRemoved(sectionIdx, rowIdx) {
            Debug.GUI.TableDataSource && console.log(__name, "tableContentRemoved: Section: " + sectionIdx + ", Row: " + rowIdx);

            __tableContent[sectionIdx].rows.splice(rowIdx, 1);

            __compareContent = cloneObjectDeep(__tableContent);
        },
        styleForTableView: function styleForTableView() {
            if (altSrc && altSrc.styleForTableView) {
                return altSrc.styleForTableView.apply(altSrc, arguments);
            }

            return TableViewStyle.GROUPED;
        },
        styleForReusingListView: function styleForReusingListView() {
            if (altSrc && altSrc.styleForReusingListView) {
                return altSrc.styleForReusingListView.apply(altSrc, arguments);
            }

            return ReusingListViewStyle.GROUPED;
        },
        numberOfSections: function numberOfSections() {
            if (altSrc && altSrc.numberOfSections) {
                return altSrc.numberOfSections.apply(altSrc, arguments);
            }

            return this.tableContent ? this.tableContent.length : 1; // same behaviour as in lxTableView
        },
        numberOfRowsInSection: function numberOfRowsInSection(section) {
            if (altSrc && altSrc.numberOfRowsInSection) {
                return altSrc.numberOfRowsInSection.apply(altSrc, arguments);
            }

            return this._checkTableContent(section) && this.tableContent[section].rows && this.tableContent[section].rows.length;
        },
        hideItemSeparator: function hideItemSeparator(section) {
            if (altSrc && altSrc.hideItemSeparator) {
                return altSrc.hideItemSeparator.apply(altSrc, arguments);
            }
            return this._checkTableContent(section) && this.tableContent[section] && this.tableContent[section].hideItemSeparator;
        },
        cellTypeForCellAtIndex: function cellTypeForCellAtIndex(section, row, tableView) {
            if (altSrc && altSrc.cellTypeForCellAtIndex) {
                return altSrc.cellTypeForCellAtIndex.apply(altSrc, arguments);
            }

            return this._checkTableContent(section, row) && this.tableContent[section].rows[row].type || defaultCellType || GUI.TableViewV2.CellType.GENERAL;
        },
        contentForFooterInSection: function contentForFooterInSection(section) {
            if (altSrc && altSrc.contentForFooterInSection) {
                return altSrc.contentForFooterInSection.apply(altSrc, arguments);
            }

            return this._checkTableContent(section) && this.tableContent[section].footer;
        },
        colorForFooterInSection: function colorForFooterInSection(section) {
            if (altSrc && altSrc.colorForFooterInSection) {
                return altSrc.colorForFooterInSection.apply(altSrc, arguments);
            }

            return this._checkTableContent(section) && this.tableContent[section].footerColor;
        },
        titleForHeaderInSection: function titleForHeaderInSection(section, tableView) {
            if (altSrc && altSrc.titleForHeaderInSection) {
                return altSrc.titleForHeaderInSection.apply(altSrc, arguments);
            }

            return this._checkTableContent(section) && this.tableContent[section].headerTitle;
        },
        imageForHeaderInSection: function imageForHeaderInSection(section, tableView) {
            if (altSrc && altSrc.imageForHeaderInSection) {
                return altSrc.imageForHeaderInSection.apply(altSrc, arguments);
            }

            return this._checkTableContent(section) && this.tableContent[section].headerImage;
        },
        strongTitleForHeaderInSection: function strongTitleForHeaderInSection(section, tableView) {
            if (altSrc && altSrc.strongTitleForHeaderInSection) {
                return altSrc.strongTitleForHeaderInSection.apply(altSrc, arguments);
            }

            return this._checkTableContent(section) && this.tableContent[section].headerStrongTitle;
        },
        boldTitleForHeaderInSection: function boldTitleForHeaderInSection(section, tableView) {
            if (altSrc && altSrc.boldTitleForHeaderInSection) {
                return altSrc.boldTitleForHeaderInSection.apply(altSrc, arguments);
            }

            return this._checkTableContent(section) && this.tableContent[section].headerBoldTitle;
        },
        descriptionForHeaderInSection: function descriptionForHeaderInSection(section, tableView) {
            if (altSrc && altSrc.descriptionForHeaderInSection) {
                return altSrc.descriptionForHeaderInSection.apply(altSrc, arguments);
            }

            return this._checkTableContent(section) && this.tableContent[section].headerDescription;
        },
        rightButtonTitleForHeaderInSection: function rightButtonTitleForHeaderInSection(section, tableView) {
            if (altSrc && altSrc.rightButtonTitleForHeaderInSection) {
                return altSrc.rightButtonTitleForHeaderInSection.apply(altSrc, arguments);
            }

            return this._checkTableContent(section) && this.tableContent[section].sectionRightButtonTitle;
        },
        rightButtonColorForHeaderInSection: function rightButtonColorForHeaderInSection(section, tableView) {
            if (altSrc && altSrc.rightButtonColorForHeaderInSection) {
                return altSrc.rightButtonColorForHeaderInSection.apply(altSrc, arguments);
            }

            return this._checkTableContent(section) && this.tableContent[section].sectionRightButtonColor;
        },
        sectionHeaderElement: function sectionHeaderElement(section, tableView) {
            if (altSrc && altSrc.sectionHeaderElement) {
                return altSrc.sectionHeaderElement.apply(altSrc, arguments);
            }

            return this._checkTableContent(section) && this.tableContent[section].headerElement;
        },
        customClassForSection: function customClassForSection(section, tableView) {
            if (altSrc && altSrc.customClassForSection) {
                return altSrc.customClassForSection.apply(altSrc, arguments);
            }

            return this._checkTableContent(section) && this.tableContent[section].customClass;
        },
        resetScrollLeftOfSectionOnReload: function resetScrollLeftOfSectionOnReload(section, table) {
            if (altSrc && altSrc.resetScrollLeftOfSectionOnReload) {
                return altSrc.resetScrollLeftOfSectionOnReload(section, table);
            } else {
                return this._checkTableContent(section) && !!this.tableContent[section].resetScrollLeftOnReload;
            }
        },
        sectionHeaderContent: function sectionHeaderContent(section, tableView) {
            if (altSrc && altSrc.sectionHeaderContent) {
                return altSrc.sectionHeaderContent.apply(altSrc, arguments);
            }

            return this._checkTableContent(section) && this.tableContent[section].headerContent;
        },
        titleForFooterInSection: function titleForFooterInSection(section, tableView) {
            if (altSrc && altSrc.titleForFooterInSection) {
                return altSrc.titleForFooterInSection.apply(altSrc, arguments);
            }

            return this._checkTableContent(section) && this.tableContent[section].footerTitle;
        },
        sectionFooterElement: function sectionFooterElement(section, tableView) {
            if (altSrc && altSrc.sectionFooterElement) {
                return altSrc.sectionFooterElement.apply(altSrc, arguments);
            }

            return this._checkTableContent(section) && this.tableContent[section].footerElement;
        },
        contentForCell: function contentForCell(cell, section, row) {
            if (altSrc && altSrc.contentForCell) {
                return altSrc.contentForCell.apply(altSrc, arguments);
            }

            return this._checkTableContent(section, row) && this.tableContent[section].rows[row].content;
        },
        cellForIndex: function cellForIndex(section, row) {
            if (altSrc && altSrc.cellForIndex) {
                return altSrc.cellForIndex.apply(altSrc, arguments);
            }

            return this._checkTableContent(section, row) && this.tableContent[section].rows[row].cell;
        },
        dequeueReusableCell: function dequeueReusableCell(reuseId) {
            if (altSrc && altSrc.dequeueReusableCell) {
                return altSrc.dequeueReusableCell.apply(altSrc, arguments);
            }

            return null;
        },
        storeReusableCell: function storeReusableCell(reuseId, cell) {
            if (altSrc && altSrc.storeReusableCell) {
                return altSrc.storeReusableCell.apply(altSrc, arguments);
            } else {
                cell.triggerResetCellContent();
                return GUI.getTableViewCellCache().storeReusableCell(reuseId, cell);
            }
        },
        heightForCellInRow: function heightForCellInRow(row, reusingList) {
            if (altSrc && altSrc.heightForCellInRow) {
                return altSrc.heightForCellInRow.apply(altSrc, arguments);
            }

            return this._checkTableContent(0, row) && this.tableContent[0].rows[row].height;
        },
        cellTypeForRowInList: function cellTypeForRowInList(row, list) {
            if (altSrc && altSrc.cellTypeForRowInList) {
                return altSrc.cellTypeForRowInList(row, list);
            } else {
                return GUI.ReusingListView.CellType.GENERAL;
            }
        },
        _checkTableContent: function _checkTableContent(section, row) {
            return this.tableContent && this.tableContent[section] && (typeof row !== "number" || this.tableContent[section].rows && this.tableContent[section].rows[row]);
        }
    };
};
/**
 * @param initialTableContent
 * @param altSrc alternative dataSource, will be used (instead of tableContent) if the altSrc has a method implemented
 @returns {{}}
 */


var tableViewDelegate = function tableViewDelegate(initialTableContent, altSrc) {
    var __delUpdateTimeStamp = -1;

    var __delIsLocked = false;
    var __delCachedContent = null;
    var __delCompareContent = null;
    var __delCachedUpdateTimeStamp = null;
    var __delModifications = null;
    var __name = "tableViewDelegate";
    var __isInitial = true;

    var __delTableContent = cloneObjectDeep(initialTableContent);
    var __delTableView = null;

    return {
        get delegateAltSrc() {
            return altSrc;
        },
        get tableContent() {
            return __delTableContent;
        },

        set tableContent(newVal) {
            console.warn("Use the .update() to set the tableContent");
        },

        get updateTimestamp() {
            return __delUpdateTimeStamp;
        },

        // no setter, cannot be modified from outside
        get modifications() {
            return __delModifications;
        },

        set tableView(newVal) {
            __delTableView = newVal;
        },
        get tableView() {
            return __delTableView;
        },

        // no setter, cannot be modified from outside

        /**
         * Used by the table view to avoid content being modified while the table is still reloading.
         * Incoming update calls will not modify the current content, remember it until being unlocked.
         */
        lock: function lock() {
            Debug.GUI.TableDelegate && console.log(__name, "lock");
            __delIsLocked = true;
        },

        /**
         * Counterpart to lock()
         */
        unlock: function unlock() {
            Debug.GUI.TableDelegate && console.log(__name, "unlock");
            __delIsLocked = false;

            if (__delCachedContent) {
                Debug.GUI.TableDelegate && console.log(__name, "   applying cached content: ", __delCachedContent); // call updateContent, this will recompute the modifications to the dataset of the time where it was
                // locked --> Important as the smartReload of the tableView uses the modifications set of the del/Datasource

                this.update(__delCachedContent);
                __delCachedUpdateTimeStamp = null;
                __delCachedContent = null;
            }
        },
        /// DELEGATE
        update: function update(newTableContent, actionParam) {
            Debug.GUI.TableDelegate && console.log(__name, "update", getStackObj());
            var modified = true,
                newHash = -1,
                oldHash = -1;

            if (!__isInitial) {
                Debug.GUI.TableDataSource && console.log(__name, "   -> no longer initial, check");

                if (newTableContent) {
                    newHash = _stringifyTableContent(newTableContent).hashCode();
                    oldHash = __delCompareContent ? JSON.stringify(__delCompareContent).hashCode() : -1;
                    modified = newHash !== oldHash;
                }
            } else {
                Debug.GUI.TableDataSource && console.log(__name, "   -> initial, mark as modified");
                __isInitial = false;
            }

            if (modified) {
                Debug.GUI.TableDelegate && console.log(__name, "  --> MODIFIED " + oldHash + "->" + newHash);
                __delModifications = getModifications(__delCompareContent, newTableContent);
                Debug.GUI.TableDelegate && console.log(__name, "    Modifications: ", getPrintableModifications(__delModifications));
                Debug.GUI.TableDelegate && console.log(__name, "              was: ", cloneObject(__delCompareContent));
                Debug.GUI.TableDelegate && console.log(__name, "              now: ", cloneObject(newTableContent));
            } else {
                __delModifications = getModifications(__delCompareContent, newTableContent);
                Debug.GUI.TableDelegate && console.log(__name, "  --> NOT modified " + oldHash + "->" + newHash);
                Debug.GUI.TableDelegate && console.log(__name, " prev: ", cloneObject(__delCompareContent));
                Debug.GUI.TableDelegate && console.log(__name, "  new: ", cloneObject(newTableContent));
            } // if a reload is currently in progress, don't update the content!


            if (__delIsLocked) {
                Debug.GUI.TableDataSource && console.log(__name, " --> locked, reload in progress!");

                if (modified) {
                    // only cache sth if the data has changed!
                    __delCachedContent = cloneObjectDeep(newTableContent);
                    __delCachedUpdateTimeStamp = timingNow();
                }
            } else {
                if (modified) {
                    __delUpdateTimeStamp = timingNow();
                }

                __delTableContent = cloneObjectDeep(newTableContent);
                __delCompareContent = cloneObjectDeep(newTableContent);
            }

            this.tableView && this.tableView.dsUpdated();

            return modified;
        },
        // called by the editableTableView in order to keep the compareContent up to date.
        tableContentMoved: function tableContentMoved(sectionIdx, oldIdx, newIdx) {
            var movingContent = __delTableContent[sectionIdx].rows.splice(oldIdx, 1)[0];

            __delTableContent[sectionIdx].rows.splice(newIdx, 0, movingContent);

            __delCompareContent = cloneObjectDeep(__delTableContent);
        },
        // called by the editableTableView in order to keep the compareContent up to date.
        tableContentRemoved: function tableContentRemoved(sectionIdx, rowIdx) {
            __delTableContent[sectionIdx].rows.splice(rowIdx, 1);

            __delCompareContent = cloneObjectDeep(__delTableContent);
        },
        didSelectHeader: function didSelectHeader(section, tableView) {
            return this._checkAndCall("didSelectHeader", section, null, arguments);
        },
        didSelectFooter: function didSelectFooter(section, tableView) {
            return this._checkAndCall("didSelectFooter", section, null, arguments);
        },
        rightSectionButtonTapped: function rightSectionButtonTapped(section, tableView) {
            return this._checkAndCall("rightSectionButtonTapped", section, null, arguments);
        },
        getSortableOptions: function getSortableOptions(section, row) {
            return this._checkAndCall("getSortableOptions", section, null, arguments);
        },
        didRequestRemovingCell: function didRequestRemovingCell(section, row, tableView, cell) {
            return this._checkAndCall("didRequestRemovingCell", section, null, arguments);
        },
        onViewWillAppear: function onViewWillAppear(cell, section, row) {
            if (altSrc && altSrc.onViewWillAppear) {
                return altSrc.onViewWillAppear.apply(altSrc, arguments);
            } else {
                return this._checkAndCall("onViewWillAppear", section, row, arguments);
            }
        },
        didSelectCell: function didSelectCell(cell, section, row) {
            Debug.GUI.TableDelegate && console.log(__name, "didSelectCell: ", cell, section, row);

            if (altSrc && altSrc.didSelectCell) {
                return altSrc.didSelectCell.apply(altSrc, arguments);
            } else {
                return this._checkAndCall("action", section, row, arguments);
            }
        },
        showSortingContextMenu: function showSortingContextMenu(cell, section, row) {
            cell.tableView.element.addClass("stop-scrolling");

            if (altSrc && altSrc.showSortingContextMenu) {
                return altSrc.showSortingContextMenu.apply(altSrc, arguments);
            } else {
                return this._checkAndCall("showSortingContextMenu", section, row, arguments);
            }
        },
        hideSortingContextMenu: function hideSortingContextMenu(cell, section, row) {
            cell.tableView.element.removeClass("stop-scrolling");

            if (altSrc && altSrc.hideSortingContextMenu) {
                return altSrc.hideSortingContextMenu.apply(altSrc, arguments);
            } else {
                return this._checkAndCall("hideSortingContextMenu", section, row, arguments);
            }
        },
        startSorting: function startSorting(cell, section, row) {
            if (altSrc && altSrc.startSorting) {
                return altSrc.startSorting.apply(altSrc, arguments);
            } else {
                return this._checkAndCall("startSorting", section, row, arguments);
            }
        },
        processAtTheEndOfReload: function processAtTheEndOfReload(tableView) {
            if (altSrc && altSrc.processAtTheEndOfReload) {
                return altSrc.processAtTheEndOfReload.apply(altSrc, arguments);
            } else {
                return this._checkAndCall("processAtTheEndOfReload", null, null, arguments);
            }
        },
        markNotificationAsRead: function markNotificationAsRead(tableView) {
            if (altSrc && altSrc.markNotificationAsRead) {
                return altSrc.markNotificationAsRead.apply(altSrc, arguments);
            } else {
                return this._checkAndCall("markNotificationAsRead", null, null, arguments);
            }
        },
        favoriteSelected: function favoriteSelected(tableView) {
            if (altSrc && altSrc.favoriteSelected) {
                return altSrc.favoriteSelected.apply(altSrc, arguments);
            } else {
                return this._checkAndCall("favoriteSelected", null, null, arguments);
            }
        },
        onListCellTapped: function onListCellTapped(cell, row, reusableList) {
            return this.didSelectCell(cell, 0, row, reusableList);
        },
        onListCellRightButtonTapped: function onListCellRightButtonTapped(cell, row, reusableList) {
            return this.buttonTapped(0, row, reusableList);
        },
        didMoveListCell: function didMoveListCell(oldIdx, newIdx) {
            return this._checkAndCall("didMoveListCell", 0, oldIdx, arguments);
        },
        removeListCell: function removeListCell(rowIdx, listView) {
            return this._checkAndCall("removeListCell", 0, rowIdx, arguments);
        },
        // GUI.TableViewV2.CellType.Special.COMFORT_ACTION
        onCellHit: function onCellHit(cell, section, row) {
            return this._checkAndCall("onCellHit", section, row, arguments);
        },
        onCellTick: function onCellTick(cell, section, row) {
            return this._checkAndCall("onCellTick", section, row, arguments);
        },
        onCellReleased: function onCellReleased(cell, section, row) {
            return this._checkAndCall("onCellReleased", section, row, arguments);
        },
        onCellDoubleTapped: function onCellDoubleTapped(cell, section, row) {
            return this._checkAndCall("onCellDoubleTapped", section, row, arguments);
        },
        // GUI.TableViewV2.CellType.SWITCH
        onSwitchChanged: function onSwitchChanged(value, section, row, tableView, cell) {
            return this._checkAndCall("onSwitchChanged", section, row, arguments);
        },
        // GUI.TableViewV2.CellType.SLIDER
        sliderDragged: function sliderDragged(cell, section, row, tableView, value) {
            return this._checkAndCall("sliderDragged", section, row, arguments);
        },
        sliderDragEnded: function sliderDragEnded(cell, section, row, tableView, value) {
            return this._checkAndCall("sliderDragEnded", section, row, arguments);
        },
        sliderClicked: function sliderClicked(cell, section, row, tableView, value) {
            return this._checkAndCall("sliderClicked", section, row, arguments);
        },
        userfriendlyValueForSlider: function userfriendlyValueForSlider(cell, section, row, tableView, value) {
            return this._checkAndCall("userfriendlyValueForSlider", section, row, arguments);
        },
        // AutomaticDesignerCell
        didRemoveCapability: function didRemoveCapability(section, row, tableView) {
            return this._checkAndCall("didRemoveCapability", section, row, arguments);
        },
        validityDidChange: function validityDidChange(section, row, tableView) {
            return this._checkAndCall("validityDidChange", section, row, arguments);
        },
        onGroupChange: function onGroupChange(section, row, tableView) {
            return this._checkAndCall("onGroupChange", section, row, arguments);
        },
        // GUI.TableViewV2.CellType.CHECKABLE
        didRequestCheckingCell: function didRequestCheckingCell(cell, section, row, tableView) {
            return this._checkAndCall("didRequestCheckingCell", section, row, arguments);
        },
        didCheckCell: function didCheckCell(cell, section, row, tableView, selected) {
            return this._checkAndCall("didCheckCell", section, row, arguments);
        },
        // GUI.TableViewV2.CellType.INPUT
        textChanged: function textChanged(section, row, tableView, value, valid, valueDidChange) {
            return this._checkAndCall("textChanged", section, row, arguments);
        },
        submitText: function submitText(section, row, tableView, value, valid) {
            return this._checkAndCall("submitText", section, row, arguments);
        },
        onFocus: function onFocus(section, row, tableView, value, valid) {
            return this._checkAndCall("onFocus", section, row, arguments);
        },
        onBlur: function onBlur(section, row, tableView, value, valid) {
            return this._checkAndCall("onBlur", section, row, arguments);
        },
        // GUI.TableViewV2.CellType.BUTTON & .Special.DEVICE_LEARNING_CELL && .Special.DEVICE_LEANING_CHECKABLE_CELL
        buttonTapped: function buttonTapped(section, row, tableView) {
            return this._checkAndCall("buttonTapped", section, row, arguments);
        },
        buttonDoubleTapped: function buttonDoubleTapped(section, row, tableView) {
            return this._checkAndCall("buttonDoubleTapped", section, row, arguments);
        },
        // GUI.TableViewV2.CellType.BUTTON
        buttonHit: function buttonHit(section, row, tableView) {
            return this._checkAndCall("buttonHit", section, row, arguments);
        },
        buttonReleased: function buttonReleased(section, row, tableView) {
            return this._checkAndCall("buttonReleased", section, row, arguments);
        },
        button2Tapped: function button2Tapped(section, row, tableView) {
            return this._checkAndCall("button2Tapped", section, row, arguments);
        },
        button2Hit: function button2Hit(section, row, tableView) {
            return this._checkAndCall("button2Hit", section, row, arguments);
        },
        button2Released: function button2Released(section, row, tableView) {
            return this._checkAndCall("button2Released", section, row, arguments);
        },
        onButtonTicked: function onButtonTicked(section, row, tableView) {
            return this._checkAndCall("onButtonTicked", section, row, arguments);
        },
        button2Ticked: function button2Ticked(section, row, tableView) {
            return this._checkAndCall("button2Ticked", section, row, arguments);
        },
        didRemoveCell: function didRemoveCell(section, row, tableView) {
            return this._checkAndCall("didRemoveCell", section, row, arguments);
        },
        didMoveCell: function didMoveCell(section, row, tableView) {
            return this._checkAndCall("didMoveCell", section, row, arguments);
        },
        // GUI.TableViewV2.CellType.RATING
        didChangeRating: function didChangeRating(cell, section, row, tableView, value, dragging) {
            return this._checkAndCall("didChangeRating", section, row, arguments);
        },
        // GUI.TableViewV2.CellType.DATE_PICKER
        onPickerChanged: function onPickerChanged(section, row, tableView, value) {
            return this._checkAndCall("onPickerChanged", section, row, arguments);
        },
        //GUI.TableViewV2.CellType.Special.MEDIA_EULA_INPUT_CELL
        eulaCheckboxChanged: function eulaCheckboxChanged(section, row, checked) {
          return this._checkAndCall("eulaCheckboxChanged", section, row, arguments)
        },
        // GUI.TableViewV2.CellType.Special.MOOD_CELL
        favoriteStateChanged: function favoriteStateChanged(isFav, moodId, section, row, tableView) {
            return this._checkAndCall("favoriteStateChanged", section, row, arguments);
        },
        // GUI.TableViewV2.CellType.DetailedContentBaseCell
        onTopLeftButtonPress: function onTopLeftButtonPress(section, row, tableView) {
            return this._checkAndCall("onTopLeftButtonPress", section, row, arguments);
        },
        onTopRightButtonPress: function onTopRightButtonPress(section, row, tableView) {
            return this._checkAndCall("onTopRightButtonPress", section, row, arguments);
        },
        // GUI.TableViewV2.CellType.Special.STEAK_SENSOR_CELL
        onChangeDescription: function onChangeDescription(sensor, section, row, tableView) {
            return this._checkAndCall("onChangeDescription", section, row, arguments);
        },
        onTargetTempChange: function onTargetTempChange(sensor, section, row, tableView) {
            return this._checkAndCall("onTargetTempChange", section, row, arguments);
        },
        // GUI.TableViewV2.CellType.Special.STEAK_TIMER_CELL
        onTimerSetSelected: function onTimerSetSelected(sensor, section, row, tableView) {
            return this._checkAndCall("onTimerSetSelected", section, row, arguments);
        },
        onTimerStop: function onTimerStop(sensor, section, row, tableView) {
            return this._checkAndCall("onTimerStop", section, row, arguments);
        },
        onTimerStart: function onTimerStart(sensor, section, row, tableView) {
            return this._checkAndCall("onTimerStart", section, row, arguments);
        },
        // GUI.TableViewV2.CellType.Special.IRCV2TemperatureCell
        onTemperatureScreenOpen: function onTemperatureScreenOpen(section, row, tableView) {
            return this._checkAndCall("onTemperatureScreenOpen", section, row, arguments);
        },
        _checkTableContent: function _checkTableContent(section, row) {
            if (typeof row === "number") {
                return this.tableContent && this.tableContent[section] && this.tableContent[section].rows && this.tableContent[section].rows[row];
            } else {
                return this.tableContent && this.tableContent[section];
            }
        },

        /**
         * Generic method to check what delegate handles a callback from a cell & then calls the delegates callback method.
         * @param fn        the name of the method to call.
         * @param section
         * @param row
         * @param args      the arguments array to use when calling the delegates method
         * @return {*}      the result of the method call.
         * @private
         */
        _checkAndCall: function _checkAndCall(fn, section, row, args) {
            // don't check for "hasOwnProperty", because it can also be inherited..
            if (altSrc && typeof altSrc[fn] === "function") {
                return altSrc[fn].apply(altSrc, args);
            } else if (this._checkTableContent(section, row)) {
                if (typeof row === "number" && typeof this.tableContent[section].rows[row][fn] === "function") {
                    return this.tableContent[section].rows[row][fn].apply(this, args);
                } else if (typeof this.tableContent[section][fn] === "function") {
                    return this.tableContent[section][fn].apply(this, args);
                }
            }
        },

        getCallable: function getCallable(fn, section, row) {
            var callable = false;
            if (altSrc && typeof altSrc[fn] === "function") {
                callable = altSrc[fn].bind(altSrc);
            } else if (this._checkTableContent(section, row)) {
                if (typeof row === "number" && typeof this.tableContent[section].rows[row][fn] === "function") {
                    callable = this.tableContent[section].rows[row][fn];
                } else if (typeof this.tableContent[section][fn] === "function") {
                    callable = this.tableContent[section][fn];
                }
            }
            return callable;
        }
    };
};
