'use strict';

define("TaskV2DetailsScreen", ["AutomaticDesignerAddOrEditRule"], function (AutomaticDesignerAddOrEditRule) {
    return class TaskV2DetailsScreen extends AutomaticDesignerAddOrEditRule {
        constructor(details) {
            super(...arguments);
            this.isExistingRuleOrScene = false;
        }

        titleBarText() {
            return _("automatic-designer.task-recorder.actions.title");
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                if (hasBit(this.details.extension, AutomaticDesignerEnums.RULE_EXTENSION.SCENE)) {
                    return AutomaticDesignerComponent.getScenes();
                } else {
                    return AutomaticDesignerComponent.getRules();
                }
            }.bind(this)).then(function (rulesOrScenes) {
                this.isExistingRuleOrScene = !!rulesOrScenes.find(function (ruleOrScene) {
                    return ruleOrScene.id === this.currentEditRule.id;
                }.bind(this));
            }.bind(this));
        }

        titleBarActionRight() {
            this.currentEditRule.actions = this.currentEditRule.actions.map(function (action) {
                delete action.recorded;
                return action;
            });
            this.ViewController.dismiss();

            if (hasBit(this.details.extension, AutomaticDesignerEnums.RULE_EXTENSION.SCENE)) {
                // Navigate back into the SceneOverview
                NavigationComp.showState(ScreenState.AutomaticDesigner.ScenesOverviewScreen, {
                    taskRecorderScene: this.currentEditRule,
                    isInEditMode: this.isExistingRuleOrScene
                });
            } else {
                // Navigate back into the AutomaticDesigner
                NavigationComp.showState(ScreenState.AutomaticDesigner.OverviewScreen, {
                    taskRecorderRule: this.currentEditRule,
                    isInEditMode: this.isExistingRuleOrScene
                });
            }
        }

        closeAction() {
            this.ViewController.dismiss();

            if (hasBit(this.details.extension, AutomaticDesignerEnums.RULE_EXTENSION.SCENE)) {
                // Navigate back into the SceneOverview
                NavigationComp.showState(ScreenState.AutomaticDesigner.ScenesOverviewScreen, {
                    taskRecorderScene: this.details.ogRule,
                    isInEditMode: this.isExistingRuleOrScene
                });
            } else {
                // Navigate back into the AutomaticDesigner
                NavigationComp.showState(ScreenState.AutomaticDesigner.OverviewScreen, {
                    taskRecorderRule: this.details.ogRule,
                    isInEditMode: this.isExistingRuleOrScene
                });
            }
        }

        emptyViewConfig() {
            return {
                iconSrc: Icon.TaskRecorderV2.RECORD,
                message: _("automatic-designer.task-recorder.actions.no-actions"),
                translucent: true
            };
        }

        _getTableContent() {
            var tableContent = [];
            tableContent.pushObject(this._getEditRuleActionsSection(true));

            if (this._hasWaitingActions()) {
                tableContent.push({
                    rows: [{
                        content: {
                            title: _("automatic-designer.task-recorder.actions.remote-waiting.action-title"),
                            titleColor: window.Styles.colors.brand,
                            clickable: true
                        },
                        action: this._removeWaitingActions.bind(this)
                    }],
                    footerTitle: _("automatic-designer.task-recorder.actions.remote-waiting.desc")
                });
            }

            return tableContent;
        }

        _getEditRuleActionsSection() {
            var actions = this._getActions(),
                actionsSection = super._getEditRuleActionsSection(...arguments);

            if (actions.length && actionsSection) {
                delete actionsSection.headerTitle; // Remove the last two rows

                actionsSection.rows = actionsSection.rows.filter(function (row) {
                    return row.type === GUI.TableViewV2.CellType.Special.AUTOMATIC_DESIGNER_CONTAINER_TASK_DETAILS_CELL;
                });
            } else {
                actionsSection = null;
            }

            return actionsSection;
        }

        _getActions() {
            return this.currentEditRule.actions || [];
        }

        _canDragCapabilities() {
            return false;
        }

        _hasWaitingActions() {
            return !!this._getActions().find(function (action) {
                return action.descID === AutomaticDesignerEnums.KNOWN_DESC_IDS.WAITING;
            });
        }

        _removeWaitingActions() {
            this.currentEditRule.actions = this.currentEditRule.actions.filter(function (action) {
                return !(action.descID === AutomaticDesignerEnums.KNOWN_DESC_IDS.WAITING && action.recorded);
            });
            this.reloadTable();
        }

        _getAutomaticDesignerContainerCellType() {
            return GUI.TableViewV2.CellType.Special.AUTOMATIC_DESIGNER_CONTAINER_TASK_DETAILS_CELL;
        }

    };
});
