import PropTypes from "prop-types";
import React from "react";
import LxReactSwitch from "./LxReactSwitch";

/**
 * Returns a Switch Component based on SwitchToggle (https://github.com/yujong-lee/react-native-switch-toggle)
 * @prop {Color} [activeTrackColor = brand] Color of the Switch Background when active (optional)
 * @prop {Boolean} [value = false] Initial value
 * @prop {Function} onValueChanged Callback function that is triggered when switch was toggled, returns new value as param
 * @prop {Boolean} [disabled = false] If false, taps on switch are not processed
 */
class LxReactSwitchAdapter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value || false,
            disabled: props.disabled
        }
        this._value = this.state.value;
        this._inactiveBackgroundColor = props.inactiveBackgroundColor;

        this._boundOnValueChanged = newValue => {
            this.toggleValue(false);
            if (this.props.onValueChanged) {
                this.props.onValueChanged(newValue);
            }
        }
    }

    setDisabled(disabled) {
        this.setState({
            disabled: disabled
        });
    }

    isDisabled() {
        return this.state.disabled;
    }

    toggleValue(shouldUpdateSwitch) {
        this._value = !this._value;
        if (shouldUpdateSwitch) {
            this.setState({
                value: this._value
            })
        }
    }

    toggle(emitEvent) {
        if (this.isDisabled()) return;

        this.toggleValue(true);

        if (emitEvent && this.props.onValueChanged) {
            this.props.onValueChanged(newValue);
        }
    }

    setActive(active) {
        if (active !== this._value) {
            this.toggleValue(true);
        }
    }

    isActive() {
        return this._value;
    }

    render() {
        return (
            <LxReactSwitch
                activeTrackColor={this.props.activeTrackColor}
                value={this.state.value}
                inactiveBackgroundColor={this._inactiveBackgroundColor}
                onValueChanged={this._boundOnValueChanged}
                disabled={this.state.disabled}
                isLegacy={true}
            />
        );
    }
}

LxReactSwitchAdapter.propTypes = {
    activeTrackColor: PropTypes.string,
    value: PropTypes.bool,
    onValueChanged: PropTypes.func,
    disabled: PropTypes.bool
}

export default LxReactSwitchAdapter;
