'use strict';

define(["ControlSetting", "ControlSettingsEditScreen", "ControlSettingsEditIconScreen", "ControlSettingsUtil", "JalousieControlEnums"], function (ControlSetting, ControlSettingsEditScreen, ControlSettingsEditIconScreen, ControlSettingsUtil, JalousieControlEnums) {
    return class ControlSettingsScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div />'));
            this.settingsUtil = details.settingsUtil;
            this.settingsConfig = details.settingsConfig;
            this.object = details.object;
            this.lightMode = details.lightMode;
            this._isControl = !!this.object.uuidAction;
            this._specialType = details.specialType;
            this._changedValues = {};
            this._changedSubSettings = {};
        }

        updateView(details) {
            var needsUpdate = false; // is called when we just edited a value, and navigated back..

            if (details.value !== undefined) {
                this._changedValues[details.setting.id] = details.value;
                needsUpdate = true;
            } // We also need to check, if a subSetting has been modified, so we don't lose the value when navigating back in


            if (details.changedSubSettings !== undefined && Object.keys(details.changedSubSettings).length > 0) {
                this._changedSubSettings[details.setting.id] = details.changedSubSettings;
                needsUpdate = true;
            }

            // may be called by LxReactScreenAdapter with route props when appLock-Screen was shown, in this situation
            // there is no setting provided.
            if (details.setting) {
                if (details.setting.compareValue(details.value)) {
                    delete this._changedValues[details.setting.id];
                }

                if (this.lightMode && !this.settingsUtil.icon && details.setting.id === ControlSettingID.DEFAULT_ICON) {
                    this.setIcon(details.value || details.setting.value);
                }
            }

            if (needsUpdate) {
                // We need to patch the information here, otherwise we don't know what to show
                if ([ControlSettingID.CATEGORY, ControlSettingID.ROOM].includes(details.setting.id)) {
                    Object.keys(this.settingsConfig).forEach(settingsKey => {
                        this.settingsConfig[settingsKey].forEach((setting, settingsIdx) => {
                            if (setting.id === details.setting.id) {
                                this.settingsConfig[settingsKey][settingsIdx] = details.setting;
                            }
                        });
                    });
                }

                this._reloadSetting(details.setting);
            }
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);

            if (this.lightMode) {
                this.setUpComfortUI(true);
                this.setTitle(this.settingsUtil.title || _('adopt-ui-settings'));
                if (this.settingsUtil.icon) {
                    this.setIcon(this.settingsUtil.icon);
                } else {
                    this.setIcon(this._isControl ? this.object.getIcon() : this.object.image);
                }
            }
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function () {
                var currentSortingMode = ActiveMSComponent.getCurrentSortingMode(),
                    devFavsActivated = ActiveMSComponent.getDeviceFavoritesActive();
                this._sortingStructureFound = !!ActiveMSComponent.getSortingStructure();

                if (!currentSortingMode && !devFavsActivated || currentSortingMode === Sorting.STANDARD_SORTING) {
                    this._starSortingMode = true;
                } else {
                    this._starSortingMode = false;
                }

                return this.reloadTable();
            }.bind(this));
        }

        getAnimation() {
            return this.lightMode ? AnimationType.FADE : AnimationType.Modal;
        }

        getURL() {
            return "Settings";
        }

        titleBarText() {
            if (this._specialType === EXPERT_MODE.SPECIAL_TYPE.ADD_ROOM) {
                return _('device-learning.add-room.expertmode-title');
            }

            return _('expert-settings');
        }

        titleBarActionRight() {
            if (this._specialType === EXPERT_MODE.SPECIAL_TYPE.ADD_ROOM) {
                this._titleBar.onLeftButtonTapped();
            }
        }

        titleBarConfig() {
            if (this._specialType === EXPERT_MODE.SPECIAL_TYPE.ADD_ROOM) {
                return {
                    rightSide: TitleBarCfg.Button.TICK,
                    leftSide: TitleBarCfg.TEXT // To avoid left side button

                };
            }
        }

        // TableViewDataSource Methods
        styleForTableView(tableView) {
            if (this.lightMode) {
                return TableViewStyle.TRANSLUCENT;
            }

            return HD_APP ? TableViewStyle.GROUPED : null;
        }

        // Private methods
        reloadTable() {
            this.tableContent = [];
            this.presetSetting = null;
            Object.values(this.settingsConfig).forEach(function (settings, idx) {
                var section = {
                    rows: []
                };

                if (!this.lightMode) {
                    var settingSectionKey = Object.keys(this.settingsConfig)[idx];

                    if (settingSectionKey !== "-") {
                        section.sectionHeader = settingSectionKey;
                    }
                }

                settings.forEach(function (setting) {
                    // e.g. the presetSetting provides the section content on its own.
                    if (setting.id === ControlSettingID.CONTROL_PRESET) {
                        this.presetSetting = setting; // as there is only one presetSection entry - this handling is okay.

                        this._preparePresetSection(section);
                    } else {
                        var row = this._getRow(setting);

                        if ((this._sortingStructureFound || !this._starSortingMode) && this.settingsUtil.lightMode && (setting.id === ControlSettingID.RATING_CONTROLS || setting.id === ControlSettingID.FAVORITE_CONTROLS || setting.id === ControlSettingID.ADD_FAVORITE_GROUP || setting.id === ControlSettingID.FAVORITE_GROUPS)) {
                            row.content.disabled = true;
                        }

                        section.rows.push(row);
                        var currentSorting = ActiveMSComponent.getCurrentSortingMode(),
                            devFavsActive = ActiveMSComponent.getDeviceFavoritesActive();

                        if (settings.headerTitle && (!currentSorting && !devFavsActive || currentSorting === Sorting.STANDARD_SORTING) && this.settingsUtil.lightMode) {
                            section.headerTitle = settings.headerTitle;
                        }

                        if (settings.footerTitle && (!currentSorting && !devFavsActive || currentSorting === Sorting.STANDARD_SORTING) && this.settingsUtil.lightMode) {
                            section.footerTitle = settings.footerTitle;
                        }
                    }
                }.bind(this));
                this.tableContent.push(section);
            }.bind(this));
            this.tableContent.pushObject(this._prepareHintSetting());
            return super.reloadTable(...arguments);
        }

        _getRow(setting) {
            if (this.lightMode) {
                if (setting.isRatingSetting()) {
                    var cellContent = {
                        type: GUI.TableViewV2.CellType.RATING,
                        content: {
                            rating: setting.value
                        },
                        didChangeRating: function didChangeRating(cell, section, row, tableView, value, dragging) {
                            if (!dragging) {
                                this._changeRatingValue(setting, value);
                            }
                        }.bind(this)
                    };

                    if (this._sortingStructureFound || !this._starSortingMode) {
                        cellContent.content.disabled = true;
                    }

                    return cellContent;
                } else if (setting.id === ControlSettingID.FAVORITE_CONTROLS || setting.id === ControlSettingID.FAVORITE_GROUPS) {
                    if (setting.id === ControlSettingID.FAVORITE_GROUPS) {
                        this._favSetting = setting;
                    }

                    return {
                        type: GUI.TableViewV2.CellType.SWITCH,
                        content: {
                            title: _('favorites.show-as'),
                            active: setting.value
                        },
                        onSwitchChanged: function onSwitchChanged(value, section, row, tableView, cell) {
                            this._changeFavValue(setting, value, false);
                        }.bind(this)
                    };
                }
            }

            return {
                content: this._getCellContent(setting),
                action: this._handleSettingsTap.bind(this, setting)
            };
        }

        _prepareHintSetting() {
            if (!this.settingsUtil.showSortingHintFooter || this._starSortingMode) {
                return null;
            } else {
                var currentSortingMode = ActiveMSComponent.getCurrentSortingMode(),
                    devFavsActivated = ActiveMSComponent.getDeviceFavoritesActive(),
                    footerTitle = "";

                let translationObject =  {
                    "hint-inlineButton": "<br><div class='detail__link'>" + _("new-sorting.hint-inlineButton") + "</div>"
                };

                if (currentSortingMode === Sorting.USER_SORTING) {
                    footerTitle = _("new-sorting.hint-text-user", translationObject);
                } else if (currentSortingMode === Sorting.DEVICE_SORTING) {
                    footerTitle = _("new-sorting.hint-text-device", translationObject);
                } else if (currentSortingMode === undefined && devFavsActivated) {
                    footerTitle = _("new-sorting.hint-text-device", translationObject);
                }

                return {
                    footerTitle: footerTitle,
                    didSelectFooter: function () {
                        NavigationComp.showState(ScreenState.DeviceFavoritesSettings);
                    }
                };
            }
        }

        _getCellContent(setting) {
            var value = this._changedValues[setting.id] !== undefined ? this._changedValues[setting.id] : setting.value,
                // maybe we have a changedValue for it..
                locked = setting.isLocked();
            var content = {
                title: setting.name,
                titleColor: locked ? Color.TEXT_INACTIVE : null,
                disclosureText: setting.userfriendlyValue(value),
                disclosureIcon: true,
                clickable: true
            };

            if (setting.isRatingSetting()) {
                content.rightIconSrc = Icon.RATING;
                content.rightIconColor = window.Styles.colors.green;
            } else if (setting.id === ControlSettingID.CATEGORY_COLOR) {
                content.rightIconSrc = Icon.CIRCLE; // Adopt config colors to new CI or branding colors

                content.rightIconColor = adoptConfigColor(value, true);
            } else if (setting.id === ControlSettingID.IMAGE) {
                content.rightIconSrc = value;
                content.rightIconColor = Color.TEXT_B;
            } else if (setting.id === ControlSettingID.CONTROL_SPECIFIC.ENERGY_MANAGER.LOAD_ICON) {
                content.rightIconColor = Color.TEXT_B;

                if (value !== "") {
                    content.rightIconSrc = value;
                }
            } else if (setting.id === ControlSettingID.DEFAULT_ICON) {
                content.rightIconColor = Color.TEXT_B;

                if (value !== "") {
                    content.rightIconSrc = value;
                }
            } else if (setting.id === ControlSettingID.HELP_TEXT) {
                // This type of text contains raw HTML, sanitize it to prevent XSS
                content.disclosureText = value.htmlEncode();
            }

            if (!this.lightMode) {
                content.disclosureColor = locked ? Color.TEXT_INACTIVE : window.Styles.colors.green;
            }

            return content;
        }

        _handleSettingsTap(setting) {
            this.ViewController.showState(ScreenState.ControlSettingEdit, null, {
                lightMode: this.lightMode,
                settingsUtil: this.settingsUtil,
                setting: setting,
                value: this._changedValues[setting.id],
                object: this.object,
                onSelectedValue: this.updateView.bind(this), // required for the icon-selection-comp
                changedSubSettings: this._changedSubSettings[setting.id]
            });
        }

        _reloadSetting(setting) {
            // use this parameter setting object, because in expert mode light it's the "full" object after a change in EditScreen
            // we search for the correct section/row of the setting to reload...
            var settingSectionKeys = Object.keys(this.settingsConfig),
                section = 0,
                row = 0;
            settingSectionKeys.every(function (settingSectionKey, sectionIdx) {
                var settingsSection = this.settingsConfig[settingSectionKey],
                    shouldContinue = true;
                section = sectionIdx;
                settingsSection.every(function (set, rowIdx) {
                    if (set.id === setting.id) {
                        shouldContinue = false;
                        row = rowIdx;
                        return false;
                    }

                    return true;
                });
                return shouldContinue;
            }.bind(this)); //console.log("reloading setting in section: " + section + " row: " + row);

            this.tableContent[section].rows[row] = this._getRow(setting);
            this.tableView.reloadRowsSelective(section, row, 1); // keep in mind, that every change might affect the preset settings too.

            this._checkReloadPresetSection(setting);
        }

        _changeRatingValue(setting, value) {
            this.ViewController.verifyValue(setting, value).done(function (newVal) {
                // check if we should automatically enable "isFavorite" on groups, if a rating is set.
                if (newVal > 0 && this._favSetting && !this._favSetting.value) {
                    this._changeFavValue(this._favSetting, true, true);
                }
            }.bind(this));
        }

        _changeFavValue(setting, value, updateCell) {
            this.ViewController.verifyValue(setting, value).done(function (newVal) {
                setting.value = newVal;
                updateCell && this._reloadSetting(setting);
            }.bind(this));
        }

        //----------------------------------------------------------------------------------------
        //----------------------------------------------------------------------------------------
        //                          Control Presets Handling
        //----------------------------------------------------------------------------------------
        //----------------------------------------------------------------------------------------

        /**
         * If a presetSetting exists and the modified setting is affected by the preset, this method will reload
         * the section showing the preset settings.
         * @param changedSetting    the setting that has been changed.
         * @private
         */
        _checkReloadPresetSection(changedSetting) {
            if (this.presetSetting && this.presetSetting.isAffectedSetting(changedSetting.id)) {
                var presetSectionIdx = this.tableContent.length - 1;
                this.tableContent[presetSectionIdx] = {};

                this._preparePresetSection(this.tableContent[presetSectionIdx]);

                this.tableView.reloadRowsSelective(presetSectionIdx, 0, 2);
            }
        }

        /**
         * Populates the section provided with content to display this.presetSetting properly
         * @param section
         * @private
         */
        _preparePresetSection(section) {
            var modified = this._isPresetModified();

            Debug.ControlSettings && console.log(this.name, "_preparePresetSection: modified: " + (modified ? "true" : "false"));
            section.rows = [this._getPresetSelectionRow(modified)];

            if (modified) {
                section.rows.push(this._getUpdatePresetRow());
                section.footerTitle = {
                    message: _("preset.update.footer")
                };
            }
        }

        _isPresetModified() {
            var modified = this.presetSetting.isModified; // if the flag is not yet set, iterate over the settings affected by this preset and check if sth changed

            if (!modified) {
                this.presetSetting.affectedSettings.forEach(function (affectedId) {
                    modified |= this._changedValues.hasOwnProperty(affectedId);
                    modified |= this._changedSubSettings.hasOwnProperty(affectedId);
                }.bind(this));
            }

            Debug.ControlSettings && console.log(this.name, "_isPresetModified: " + (modified ? "true" : "false"));
            return modified;
        }

        _getPresetSelectionRow(modified) {
            return {
                content: {
                    title: this.presetSetting.name,
                    disclosureText: this.presetSetting.userfriendlyValue() + (modified ? "*" : ""),
                    disclosureColor: window.Styles.colors.green,
                    clickable: false
                }
            };
        }

        _getUpdatePresetRow() {
            return {
                content: {
                    title: _("preset.update.title"),
                    titleColor: window.Styles.colors.green,
                    clickable: true
                },
                action: this._storeAndUpdatePreset.bind(this)
            };
        }

        _storeAndUpdatePreset() {
            var storePromise = this._storeSettings();

            var updatePromise = storePromise.then(function () {
                return SandboxComponent.updatePreset(this.presetSetting, this.object);
            }.bind(this));
            updatePromise.fail(function (err) {
                console.error(this.name, "Failed to store the settings, update the presets or apply it to other controls!", err);
            }.bind(this));
            updatePromise.finally(this._reloadSettings.bind(this));
            return updatePromise;
        }

        _storeSettings() {
            Debug.ControlSettings && console.log(this.name, "_storeSettings");
            var needToSaveFirst = false,
                prms; // if values affected have been changed by the expert mode, those need to be saved first.

            Object.keys(this._changedValues).every(function (changedSettingId) {
                needToSaveFirst |= this.presetSetting.isAffectedSetting(parseInt(changedSettingId));
            }.bind(this));

            if (needToSaveFirst && this.object.uuidAction) {
                Debug.ControlSettings && console.log(this.name, "    changed, store the settings");
                prms = this.settingsUtil.saveSettings();
            } else {
                prms = Q.all([true]);
            }

            return prms;
        }

        _reloadSettings() {
            Debug.ControlSettings && console.log(this.name, "_reloadSettings");
            return this.settingsUtil.getSettings(this.lightMode).then(function (settingsConfig) {
                Debug.ControlSettings && console.log(this.name, "    got new settings");
                this.settingsConfig = settingsConfig;
                this._changedValues = {};
                this._changedSubSettings = {};
                return this.reloadTable();
            }.bind(this));
        }

    };
});
