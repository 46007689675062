'use strict';

define([], function () {
    return class LightControlSaveSceneScreen extends Controls.ControlContent {
        constructor(details) {
            super(details); // detect if it is a a light-control or lightscene-rgb

            this.isLightControl = this.control.type === 'LightController';
            this.selectedSceneNumber = null;
            this.currentSceneName = null;
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                this.tableView = new GUI.SelectableTableView(this, this);
                return this.appendSubview(this.tableView);
            }.bind(this));
        }

        titleBarText() {
            return _("controls.lightcontroller.scene.save");
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.CLOSE,
                rightSide: TitleBarCfg.Button.TICK
            };
        }

        getURL() {
            return "SaveScene";
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarActionRight() {
            this._saveScene();
        }

        receivedStates(states) {
            if (!this.scenes || states.haveScenesChanged) {
                this.scenes = null;

                if (this.isLightControl) {
                    this.scenes = states.scenes;
                } else {
                    this.scenes = this.control.details.sceneList;
                }

                this.tableView.reload();
            }
        }

        // TableViewCell dataSource
        numberOfSections(tableView) {
            return 1;
        }

        numberOfRowsInSection(section, tableView) {
            if (this.isLightControl) {
                return 8;
            } else {
                return Object.keys(this.scenes).length;
            }
        }

        cellTypeForCellAtIndex(section, row, tableView) {
            return GUI.TableViewV2.CellType.Special.SAVE_SCENE_CELL;
        }

        contentForCell(cell, section, row, tableView) {
            var content = {},
                sceneId = row + 1,
                // scenes start with id 1 (0 = all off)
                isSelected = this.selectedSceneNumber === sceneId;

            if (this.scenes.hasOwnProperty(sceneId)) {
                content.value = this.scenes[sceneId];
                content.override = true;
            } else {
                content.placeholder = _("controls.lightcontroller.scene.not-assigned");
            }

            content.selected = isSelected;
            content.showButton = false;
            content.selectable = true;
            content.autoFocus = isSelected;
            content[GUI.LxInputEnum.REGEX] = Regex.NAME;
            content.disableInput = !this.isLightControl; // only lightControls can rename scenes.

            return content;
        }

        // TableViewDelegate methods
        didCheckCell(cell, section, row, tableview, selected) {
            //console.log(this.name, "didSelectCell: " + row + " -> " + selected);
            this.tableView.selectCell(0, row, selected);
            this.selectedSceneNumber = row + 1;
            this.currentSceneName = this.scenes[this.selectedSceneNumber] ? this.scenes[this.selectedSceneNumber] : null;
        }

        textChanged(section, row, tableView, value, valid, valueDidChange) {
            //console.log(this.name, "textChanged: " + row + ": " + value + (valid ? "" : " --> INVALID"));
            if (valid && this.selectedSceneNumber === row + 1) {
                this.currentSceneName = value;
            } else {
                this.currentSceneName = null;
            }
        }

        submitText(section, row, tableView, value, valid, valueDidChange) {
            //console.log(this.name, "submitText: " + row + ": " + value + (valid ? "" : " --> INVALID"));
            if (valid && this.selectedSceneNumber === row + 1) {
                this.currentSceneName = value;
            } else {
                this.currentSceneName = null;
            }

            this._saveScene();
        }

        // Event listeners
        _saveScene() {
            //console.log("_saveScene: " + this.selectedSceneNumber + " - '" + this.currentSceneName + "'");
            var cmd;

            if (this.currentSceneName) {
                if (this.isLightControl) {
                    cmd = Commands.format(Commands.LIGHTCONTROL.ADD_AND_RENAME_SCENE, this.selectedSceneNumber, this.currentSceneName);
                } else {
                    cmd = Commands.format(Commands.LIGHTSCENE_RGB.LEARN, this.selectedSceneNumber);
                }

                this.sendCommand(cmd);
                this.ViewController.navigateBack();
            } else if (this.selectedSceneNumber) {
                var content = {
                    title: _("controls.lightcontroller.scene.no-name-title"),
                    message: _("controls.lightcontroller.scene.no-name-message"),
                    buttonOk: true,
                    icon: Icon.CAUTION
                };
                NavigationComp.showPopup(content);
            }
        }

    };
});
