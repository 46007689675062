'use strict';

define(['AudioZoneV2ControlEnums'], function (AudioZoneV2ControlEnums) {
    class EQSettings extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            applyMixins(this, StateHandler.Mixin);
            this.details = details;
            this.control = details.control;
            this._blockingTimeoutMap = {};
        }

        viewWillDisappear() {
            if (JSON.stringify(this._ogEqualizerSettings) !== JSON.stringify(this.equalizerSettings)) {
                this._saveEQForControl(this.control);
            }

            super.viewWillDisappear(...arguments);
        }

        titleBarText() {
            return _("media.equalizer");
        }

        reloadTable() {
            var args = arguments;
            return this._getEqSettings().then(eqSettings => {
                this._ogEqualizerSettings = cloneObject(eqSettings);
                this.equalizerSettings = eqSettings;
                this.tableContent = eqSettings.map(function (band, idx) {
                    return {
                        rows: [{
                            type: GUI.TableViewV2.CellType.SLIDER,
                            content: {
                                title: band.name,
                                id: band.id,
                                value: band.value,
                                minIconSrc: Icon.ValueSelector.MINUS_PLAIN,
                                minValue: band.low,
                                maxIconSrc: Icon.ValueSelector.PLUS_PLAIN,
                                maxValue: band.high,
                                step: band.step,
                                format: "%.1f"
                            },
                            sliderDragged: this._onEQSettingChange.bind(this),
                            sliderClicked: this._onEQSettingChange.bind(this)
                        }]
                    };
                }.bind(this));
                this.tableContent.push({
                    rows: [{
                        content: {
                            title: _("media.equalizer.send-to"),
                            titleColor: window.Styles.colors.brand,
                            clickable: true
                        },
                        action: this._sendEQSettingsToZone.bind(this)
                    }]
                });
                return super.reloadTable(...args);
            });
        }

        _onEQSettingChange(cell, section, row, tableView, value) {
            var eqBand = this.equalizerSettings.find(function (band) {
                return band.id === cell.content.id;
            });

            if (eqBand) {
                eqBand.value = parseFloat(value).toFixed(1); // https://stackoverflow.com/a/48856974/1766321;

                this._saveSpecificEQBand(eqBand.id);
            }
        }

        _sendEQSettingsToZone() {
            var def = Q.defer(),
                availableZones = this.control.audioserverComp.getAvailableZones(true).filter(function (zone) {
                    return zone.uuidAction !== this.control.uuidAction;
                }.bind(this)),
                details = {
                    options: availableZones.map(function (control) {
                        return {
                            title: control.getName(),
                            subtitle: control.getSubtitle(),
                            clickable: true,
                            zone: control
                        };
                    }.bind(this)),
                    title: NBR_SPACE,
                    // We don't want a title per design!
                    headerTitle: "",
                    headerDescription: _("media.equalizer.send-to.desc", {
                        zoneName: this.control.getName()
                    }),
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE,
                    mode: GUI.SelectorScreenMode.CONFIRMED,
                    deferred: def
                };
            NavigationComp.showSelector(details).done(function (result) {
                result.forEach(function (select) {
                    this._saveEQForControl(select.option.zone);
                }.bind(this));
            }.bind(this));
        }

        /**
         * Sending the EQ Settings to a given control, also ensures to send them at max every 50 ms to prevent spamming of the Musicserver
         * @param control
         * @private
         */
        _saveEQForControl(control) {
            control.audioserverComp.sendMediaServerCommand(MusicServerEnum.Commands.SETTINGS.EQ.SET + "/" + control.details.playerid + "/" + sortArrByFields(this.equalizerSettings, ["id"]).map(function (band) {
                return band.value;
            }).join(","));
        }

        _saveSpecificEQBand(bandId) {
            var value = this.equalizerSettings.find(function (band) {
                return band.id === bandId;
            }).value;

            if (!this._blockingTimeout) {
                this.control.audioserverComp.sendMediaServerCommand(MusicServerEnum.Commands.SETTINGS.EQ.SET + "/" + this.control.details.playerid + "/" + bandId + "/" + value);
                this._blockingTimeout = setTimeout(function () {
                    delete this._blockingTimeoutMap;
                }.bind(this), 50);
            } else {
                clearTimeout(this._blockingTimeout);
                this._blockingTimeout = setTimeout(function () {
                    delete this._blockingTimeout;

                    this._saveSpecificEQBand(bandId);
                }.bind(this), 50);
            }
        }

        _getEqSettings() {
            var def = Q.defer(),
                eqSettings = [];
            this.control.audioserverComp.sendMediaServerCommand(MusicServerEnum.Commands.SETTINGS.EQ.GET + "/" + this.control.details.playerid).then(function (resp) {
                if (resp && resp.data) {
                    eqSettings = resp.data;
                    def.resolve(eqSettings);
                } else {
                    def.reject("Malformed response");
                }
            }, def.reject);
            return def.promise;
        }

    }

    Controls.AudioZoneV2Control.EQSettings = EQSettings;
    return Controls.AudioZoneV2Control.EQSettings;
});
