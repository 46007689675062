import {View} from "react-native";
import PropTypes from "prop-types";
import {LxReactTextView, LxReactButton, LxReactText, LxReactSeparator} from "LxComponents";
import globalStyles from "GlobalStyles";
import {useState, useRef, useEffect, useCallback} from 'react';
import Icons from "IconLib";

export default function LxReactLoginForm(props) {
    const [user, setUser] = useState({text: props.user, valid: props.isUserValid !== false});
    const [password, setPassword] = useState({text: props.password, valid: !!props.isPasswordValid});
    const userInputRef = useRef(null);
    const passwordInputRef = useRef(null);

    // required to update when props change. e.g. after invalid login attempt, to keep the user around.
    useEffect(() => {
        setUser({text: props.user, valid: props.isUserValid !== false});
    }, [props.user, props.isUserValid]);

    const onUserChanged = useCallback((text, isValid) => {
        setUser({text: text, valid: (isValid !== false)});
    }, [])

    const onPasswordChanged = useCallback((text, isValid) => {
        setPassword({text: text, valid: (isValid !== false)});
    }, [])

    const onSubmitted = useCallback(() => {
        if (user.valid && password.valid) {
            props.onSubmit(user.text, password.text);
        }
    }, [user, password]);

    const getSubmitEnabled = useCallback(() => {
        return user.valid && password.valid && user.text && password.text;
    }, [user, password]);


    useEffect(() => {
        if (props.onInputChanged) {
            props.onInputChanged(user.text, password.text, user.valid && password.valid);
        }
    }, [user, password])

    const ErrorInfo = () => {
        if (props.errorInfo) {
            let errors = Array.isArray(props.errorInfo) ? props.errorInfo : [props.errorInfo];
            return <View style={styles.errorInfoCntr}>
                {
                    errors.map(err => {
                        return <View><LxReactText style={styles.errorInfoText}>{err}</LxReactText></View>;
                    })
                }
            </View>
        } else if (props.hideSubmit) {
            return null; // when there's no submit, also no need for a gap.
        } else {
            return <View style={styles.gap}/>;
        }
    }

    // Sadly doesn't work when the input is focused
    /*useKeyPress("Tab", () => {
        if (userInputRef.current && passwordInputRef.current) {
            if (userInputRef.current.isFocused()) {
                passwordInputRef.current.focus();
            } else {
                userInputRef.current.focus()
            }
        }
    })*/

    return <View style={styles.main}>
        <form>
            <LxReactTextView
                ref={userInputRef}
                leftIcon={Icons.User}
                textStyle={styles.inputTextStyle}
                placeholderStyle={styles.inputPlaceholderStyle}
                alignment={"left"}
                autoFocus={(user.text === "")}
                placeholder={_("user")}
                {...props.userInputProps}
                value={user.text}
                textChanged={onUserChanged}
                autoCapitalize={"none"}/>
            <LxReactSeparator/>
            <LxReactTextView
                ref={passwordInputRef}
                type={GUI.LxInputEnum.Type.PASSWORD}
                leftIcon={Icons.Lock}
                textStyle={styles.inputTextStyle}
                placeholderStyle={styles.inputPlaceholderStyle}
                alignment={"left"}
                autoFocus={(user.text !== "")}
                placeholder={_("password")}
                secureTextEntry={true}
                {...props.passwordInputProps}
                value={password.text}
                onSubmitEditing={onSubmitted}
                textChanged={onPasswordChanged}
                autoCapitalize={"none"}/>
            <LxReactSeparator/>
            <ErrorInfo/>
            <LxReactButton
                    containerStyle={[ styles.submitButtonContainer, (props.hideSubmit ? {display: "none"} : {}) ]}
                    pkey={"login-form-submit"}
                    text={props.submitText || _('connect')}
                    disabled={!getSubmitEnabled()}
                    onPress={onSubmitted}/>
        </form>
    </View>;
};

const styles = {
    main: {
        flex: 1,
        flexDirection: "column"
    },
    inputPlaceholderStyle: {
        fontSize: globalStyles.fontSettings.sizes.medium,
        fontFamily: globalStyles.fontSettings.families.regular
    },
    inputTextStyle: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.primary
    },
    passwordInputStyle: {},
    errorInfoCntr: {
        marginTop: 16.5,
        marginBottom: 17
    },
    errorInfoText: {
        color: globalStyles.colors.red,
        lineHeight: 17
    },
    gap: {
        height: 31.5
    },
    submitButtonContainer: {
        textAlign: "center"
    }
}

LxReactLoginForm.propTypes = {
    user: PropTypes.string,
    isUserValid: PropTypes.bool,
    password: PropTypes.string,
    isPasswordValid: PropTypes.bool,
    userInputProps: PropTypes.object,
    passwordInputProps: PropTypes.object,

    errorInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),

    submitText: PropTypes.string,
    submitButtonProps: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,

    hideSubmit: PropTypes.bool,
    onInputChanged: PropTypes.func
};
