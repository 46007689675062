'use strict'

define("PoolControlCommandSrc", ["ControlCommandSrc", "PoolControlEnums"], function (ControlCommandSrc, PoolControlEnums) {
    return class PoolControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states, navigation) {
            let cmds = [];

            if (states.hasError && !states.errorStillPresent) {
                cmds.push(this.createCommand(_("pool.control.warning.restart-button"), Commands.POOL.ACK_ERROR));
            } else if (!states.error && states.serviceModeActive) {
                cmds.push(this.createCommand(_("pool.control.quit.service-mode"), Commands.format(Commands.POOL.SET_OPERATING_MODE, 1)));
            } else if (!states.error) {
                switch (control.details.swimmingMachineType) {
                    case PoolControlEnums.SwimmingMachineType.DIGITAL:
                        if (states.swimmingMachine) {
                            cmds.push(this.createCommand(_("cmdtext.pool.swimmingmachine.off"), Commands.POOL.SWIMMING_MACHINE_OFF))
                        } else {
                            cmds.push(this.createCommand(_("cmdtext.pool.swimmingmachine.on"), Commands.POOL.SWIMMING_MACHINE_ON))
                        }
                        break;

                    case PoolControlEnums.SwimmingMachineType.ANALOG:
                        cmds.push(this.createCommand(_("pool.control.swimming-machine"), PoolControlCommandSrc.showAnalogSwimmingMachine.bind(this, navigation, control)));
                        break;

                    default:
                        break;
                }

                if (true || control.details.hasCover) {
                    cmds.push(this.createCommand(_("pool.control.cover"), PoolControlCommandSrc.showCover.bind(this, navigation, control)))
                }
            }

            return cmds;
        }

        static showAnalogSwimmingMachine(navigation, control) {
            navigation.navigate(PoolControlEnums.ScreenState.SWIM_MACHINE, { control })
        }

        static showCover(navigation, control) {
            navigation.navigate(PoolControlEnums.ScreenState.COVER, { control })
        }

    };
});
