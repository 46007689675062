'use strict';

define(["NfcCodeTouchControlContentCodeValidity", "NfcCodeTouchControlContentCodePermissions", "NfcCodeTouchControlEnums"], function (NfcCodeTouchControlContentCodeValidity, NfcCodeTouchControlContentCodePermissions, NfcCodeTouchControlEnums) {
    return class NfcCodeTouchControlContentCode extends GUI.Screen {
        constructor(details) {
            super($('<div />'));
            this.control = details.control;
            this._isNewCode = !!details.createNewCode;

            if (this._isNewCode) {
                this.code = this._generateNewCode();
            } else {
                this._originalCode = cloneObject(details.code);
                this.code = cloneObject(details.code);
            }
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.tableView = new GUI.TableViewV2(this, this);
            this.appendSubview(this.tableView);
        }

        titleBarText() {
            return this._isNewCode ? _('user.access-code.new-code') : _('user.access-code.details');
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.CLOSE,
                rightSide: TitleBarCfg.CUSTOM_ICON,
                rightIconSrc: 'resources/Images/General/TitleBar/titlebar_tickmark.svg'
            };
        }

        titleBarActionRight() {
            if (this.code.name.length === 0) {
                this.tableView.cellForSectionAndRow(0, 0).focus(); // focus name
            } else if (this.code.hasOwnProperty("code") && // we don't have a code when only updating
                this.code.code !== -1 && this.code.code.length === 0) {
                this.tableView.cellForSectionAndRow(1, 0).focus(); // focus code
            } else if (!this._hasChanges()) {
                this.ViewController.dismissModal();
            } else {
                if (this._isNewCode) {
                    // create the new code...
                    this._sendCreateCmd();
                } else {
                    // update code
                    this._sendUpdateCmd();
                }
            }
        }

        viewWillAppear() {
            this.tableView.reload();
            super.viewWillAppear(...arguments);
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);

            if (this._isNewCode) {
                // focus the input cell
                this.tableView.cellForSectionAndRow(0, 0).focus();
            }
        }

        getURL() {
            return this._isNewCode ? "NewCode" : this.code.uuid;
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        handleModalBackgroundTapped() {
            return !this._hasChanges();
        }

        // TableViewDataSource Methods
        styleForTableView(tableView) {
            return TableViewStyle.GROUPED;
        }

        numberOfSections(tableView) {
            if (this._isNewCode) {
                return 3;
            }

            return 5;
        }

        numberOfRowsInSection(section, tableView) {
            if (section === 0) {
                return 1;
            } else if (section === 1) {
                return 1;
            } else if (section === 2) {
                return 2;
            } else if (section === 3) {
                return 1;
            } else if (section === 4) {
                return 1;
            }
        }

        cellTypeForCellAtIndex(section, row, tableView) {
            if (section === 0 || section === 1) {
                return GUI.TableViewV2.CellType.INPUT;
            } else if (section === 2 || section === 3) {
                return GUI.TableViewV2.CellType.GENERAL;
            } else if (section === 4) {
                return GUI.TableViewV2.CellType.DELETE;
            }
        }

        titleForHeaderInSection(section) {
            if (section === 0) {
                return _('description');
            } else if (section === 1) {
                return _('user.code');
            } else if (section === 3) {
                return _('actions');
            }
        }

        contentForCell(cell, section, row, tableView) {
            if (section === 0) {
                return {
                    value: this.code.name,
                    placeholder: _('description'),
                    validationRegex: Regex.TEXT,
                    type: GUI.LxInputEnum.Type.TEXT
                };
            } else if (section === 1) {
                return {
                    value: this.code.code !== -1 ? this.code.code : "",
                    // -1 means that there is no code to update
                    placeholder: this._isNewCode || this.code.isEmpty ? _('user.access-code.enter') : "******",
                    validationRegex: Regex.ACCESS_CODE,
                    type: GUI.LxInputEnum.Type.TELEPHONE
                };
            } else if (section === 2) {
                if (row === 0) {
                    return {
                        title: _('validity'),
                        disclosureText: this._getValidity(),
                        disclosureColor: window.Styles.colors.green,
                        disclosureText1: this._getValidityTime(),
                        clickable: true,
                        disclosureIcon: true
                    };
                } else if (row === 1) {
                    return {
                        title: _('permissions'),
                        disclosureText: this._getPermissions(),
                        disclosureColor: window.Styles.colors.green,
                        clickable: true,
                        disclosureIcon: true
                    };
                }
            } else if (section === 3) {
                return {
                    title: this.code.isActive ? _('user.code.deactivate') : _('user.code.reactivate'),
                    leftIconSrc: Icon.LOCK,
                    clickable: true
                };
            } else if (section === 4) {
                return {
                    title: _('user.code.delete')
                };
            }
        }

        // TableViewDelegate methods
        textChanged(section, row, tableView, value, valid, valueDidChange) {
            if (section === 0) {
                // name
                if (valid) {
                    this.code.name = value;
                } else {
                    this.code.name = "";
                }
            } else if (section === 1) {
                // code
                if (valid) {
                    this.code.code = value;
                } else if (value.length === 0 && !this._isNewCode) {
                    this.code.code = -1; // means that we don't wan't to update the code
                } else {
                    this.code.code = "";
                }
            }
        }

        didSelectCell(cell, section, row) {
            if (section === 2) {
                if (row === 0) {
                    // validity
                    this.ViewController.showState(NfcCodeTouchControlEnums.ScreenState.CODE_VALIDITY, null, {
                        control: this.control,
                        code: this.code
                    });
                } else if (row === 1) {
                    // permissions
                    this.ViewController.showState(NfcCodeTouchControlEnums.ScreenState.CODE_PERMISSIONS, null, {
                        control: this.control,
                        code: this.code
                    });
                }
            } else if (section === 3) {
                // activate/deactivate code
                if (this.code.isActive) {
                    this._sendDeactivateCmd();
                } else {
                    this._handleCodeActivation();
                }
            } else if (section === 4) {
                // delete code
                NavigationComp.showPopup({
                    title: _("user.code.delete"),
                    message: _("user.code.delete.confirmation"),
                    buttonOk: _('delete'),
                    buttonCancel: true,
                    icon: Icon.DELETE,
                    color: window.Styles.colors.red
                }).done(function () {
                    this._sendDeleteCmd();
                }.bind(this));
            }
        }

        // Private methods

        /**
         * string for validity of code
         * @returns {string}
         * @private
         */
        _getValidity() {
            switch (this.code.type) {
                case NfcCodeTouchControlEnums.CodeType.PERMANENT:
                    return _('code-touch.unlimited');

                case NfcCodeTouchControlEnums.CodeType.ONE_TIME:
                    return _('code-touch.single-use');

                case NfcCodeTouchControlEnums.CodeType.TIME_DEPENDENT:
                    return _('code-touch.time-restricted');

                default:
                    return NOT_AVAILABLE;
            }
        }

        /**
         * user-friendly time string for time dependant codes
         * @returns {string}
         * @private
         */
        _getValidityTime() {
            if (this.code.type === NfcCodeTouchControlEnums.CodeType.TIME_DEPENDENT) {
                var format = "L, " + LxDate.getTimeFormat(true);
                return moment.unix(this.code.timeFrom).format(format) + " - " + moment.unix(this.code.timeTo).format(format);
            }
        }

        /**
         * returns a permissions string ("A, B, ...")
         * max. 2 outputs, if more, dots are displayed
         * @returns {string}
         * @private
         */
        _getPermissions() {
            var codeOutputs = this.code.outputs,
                permissionsArray = [];

            if (codeOutputs & NfcCodeTouchControlEnums.OutputBitmask.ONE) {
                permissionsArray.push(this.control.getOutputForNr(1));
            }

            if (codeOutputs & NfcCodeTouchControlEnums.OutputBitmask.TWO) {
                permissionsArray.push(this.control.getOutputForNr(2));
            }

            if (codeOutputs & NfcCodeTouchControlEnums.OutputBitmask.THREE) {
                permissionsArray.push(this.control.getOutputForNr(3));
            }

            if (codeOutputs & NfcCodeTouchControlEnums.OutputBitmask.FOUR) {
                permissionsArray.push(this.control.getOutputForNr(4));
            }

            if (codeOutputs & NfcCodeTouchControlEnums.OutputBitmask.FIVE) {
                permissionsArray.push(this.control.getOutputForNr(5));
            }

            if (codeOutputs & NfcCodeTouchControlEnums.OutputBitmask.SIX) {
                permissionsArray.push(this.control.getOutputForNr(6));
            } // one code might have a permission for an number that has no output attached.


            permissionsArray = permissionsArray.filter(function (item) {
                return item !== NOT_AVAILABLE;
            }); // only display first two

            if (permissionsArray.length > 2) {
                permissionsArray.length = 2;
                return permissionsArray.join(", ") + ", ...";
            }

            return permissionsArray.join(", ");
        }

        /**
         * creates a new code object with default values
         * @returns {object}
         * @private
         */
        _generateNewCode() {
            var outputs = this.control.getUsedOutputs(),
                firstOutput = outputs[0];
            return {
                name: _('user.access-code'),
                code: "",
                type: NfcCodeTouchControlEnums.CodeType.PERMANENT,
                outputs: firstOutput ? this.control.getBitmaskForOutput(firstOutput) : 0,
                standardOutput: firstOutput ? firstOutput.nr : 0
            };
        }

        /**
         * returns true if some property of the code has changed
         * @returns {boolean}
         * @private
         */
        _hasChanges() {
            return JSON.stringify(this._originalCode) !== JSON.stringify(this.code);
        }

        _checkIfTimeIsValid() {
            var now = moment().unix();
            return this.code.timeTo > now && this.code.timeTo > this.code.timeFrom;
        }

        _handleCodeActivation() {
            if (this.code.type === NfcCodeTouchControlEnums.CodeType.TIME_DEPENDENT) {
                if (this._checkIfTimeIsValid()) {
                    this.code.isActive = true;

                    this._sendUpdateCmd();
                } else {
                    this.ViewController.showState(NfcCodeTouchControlEnums.ScreenState.CODE_VALIDITY, null, {
                        control: this.control,
                        code: this.code
                    });
                }
            } else {
                this._sendActivateCmd();
            }
        }

        _sendDeactivateCmd() {
            this.control.sendCommandWithErrorHandling(Commands.format(Commands.NFC_CODE_TOUCH.DEACTIVATE_CODE, this.code.uuid), this.ViewController);
        }

        _sendCreateCmd() {
            var cmd;

            if (this.code.type === NfcCodeTouchControlEnums.CodeType.TIME_DEPENDENT) {
                cmd = Commands.format(Commands.NFC_CODE_TOUCH.CREATE_CODE_WITH_TIME, encodeURIComponent(this.code.name), this.code.code, this.code.type, this.code.outputs, this.code.standardOutput, this.code.timeFrom, this.code.timeTo);
            } else {
                cmd = Commands.format(Commands.NFC_CODE_TOUCH.CREATE_CODE, encodeURIComponent(this.code.name), this.code.code, this.code.type, this.code.outputs, this.code.standardOutput);
            }

            this.control.sendCommandWithErrorHandling(cmd, this.ViewController);
        }

        _sendUpdateCmd() {
            var cmd;

            if (!this.code.hasOwnProperty("code")) {
                this.code.code = -1; // -1 = don't change the code
            }

            if (this.code.type === NfcCodeTouchControlEnums.CodeType.TIME_DEPENDENT) {
                cmd = Commands.format(Commands.NFC_CODE_TOUCH.UPDATE_CODE_WITH_TIME, this.code.uuid, this.code.isActive, encodeURIComponent(this.code.name), this.code.code, this.code.type, this.code.outputs, this.code.standardOutput, this.code.timeFrom, this.code.timeTo);
            } else {
                cmd = Commands.format(Commands.NFC_CODE_TOUCH.UPDATE_CODE, this.code.uuid, this.code.isActive, encodeURIComponent(this.code.name), this.code.code, this.code.type, this.code.outputs, this.code.standardOutput);
            }

            this.control.sendCommandWithErrorHandling(cmd, this.ViewController);
        }

        _sendDeleteCmd() {
            this.control.sendCommandWithErrorHandling(Commands.format(Commands.NFC_CODE_TOUCH.DELETE_CODE, this.code.uuid), this.ViewController);
        }

        _sendActivateCmd() {
            this.control.sendCommandWithErrorHandling(Commands.format(Commands.NFC_CODE_TOUCH.ACTIVATE_CODE, this.code.uuid), this.ViewController);
        }

    };
});
