import {useContext, useMemo, useRef} from "react";
import {
    LxReactFlexibleCell,
    LxReactText, LxReactTextView,
    navigatorConfig,
    SearchableTable
} from "LxComponents";
import {SelectionContext} from "./NewSelectorScreen";
import globalStyles from "GlobalStyles";
import Icons from "IconLib"
import {v4 as uuidV4} from "uuid";

function InternalSelectorScreen({navigation, route}) {
    let {
        title,
        onSelected,
        autoClose,
        dispatchSelectionChange ,
        createNewPlaceholder,
        createNewRegex ,
        createNewFilterRegex,
        baseOptions,
        createFn,
        animationType,
        allowMultiple,
        internalSelectedIds,
        getCustomFilteredContent,
        filterTitle,
        maxItemCount,
        onMaxItemsReached,
        headerShown,
        withoutHorizontalSpaces,
        updateSelectedIds
    } = useContext(SelectionContext);

    const {
        isSubSelection = false,
        options = baseOptions
    } = {...(route.params || {})}

    const closeSelectorScreens = () => {
        if (isSubSelection) {
            navigation.popToTop();
        }
        navigation.goBack();
    }

    navigation.setOptions(
        navigatorConfig({
            navigation,
            headerShown: headerShown,
            animationType: animationType || AnimationType.MODAL,
            headerStyle: {
                borderBottomWidth: 0
            },
            title: title,
            rightActions: [
                {
                    action: ({dimensions, props, key}) => {
                        //return <Icons.MoreCircled key={key} fill={props.tintColor} height={dimensions.height} width={dimensions.width}/>
                        return <Icons.Tick style={globalStyles.customStyles.reactTitleBarIcon}/>
                    }
                }
            ],
            onRightAction: () => {
                if (internalSelectedIds.length === 1 && internalSelectedIds[0] === CREATE_NEW_ID) {
                    if (nullEmptyString(newOptionName.current)) {
                        Q(createFn(newOptionName.current)).then((createdOption) => {
                            onSelected(createdOption.id);
                            navigation.goBack();
                        })
                    }

                } else {
                    closeSelectorScreens()
                }
            }
        })
    );


    const handleOpenSubselection = (option) => {
        navigation.push(InternalSelectorScreen.name, {
            isSubSelection: true,
            options: option.options
        });
    };

    const getAdoptedCustomFiltered = (filterText) => {
        if (!getCustomFilteredContent) {
            return {};
        }
        return getCustomFilteredContent(filterText).map(section => {
            return {
                ...section,
                rows: section.rows.map(row => {
                    return createOptionRow(row);
                })
            }
        })
    }

    const createOptionRow = (option) => {
        const selectable = option.hasOwnProperty("id");
        const isSelectedInternally = selectable ? internalSelectedIds.includes(option.id) : false;
        // if the checked property is already set (custom search), take this with higher priority, otherwise it may be overridden with a wrong value
        const isSelectedExternally = option.hasOwnProperty("checked") && typeof option.checked === "boolean";
        const rowOptions = {
            uniqueId: selectable ? option.id : uuidV4(),
            disabled: option.disabled,
            title: option.title,
            subTitle: option.subTitle,
            searchTags: option.searchTags,
            checked: isSelectedExternally ? option.checked : isSelectedInternally,
            radioMode: selectable && !allowMultiple ? LxReactFlexibleCell.RadioMode.Table : LxReactFlexibleCell.RadioMode.Inactive,
            mainLeftContent: option.mainLeftContent,
            mainRightContent: option.mainRightContent,
            ...(option.options ? {
                onPress: () => {
                    handleOpenSubselection(option);
                }, disclosureIcon: true
            } : {}),
            onCheckedToggled: checked => {
                if (option.hasOwnProperty("onCheckedToggled") && typeof option.onCheckedToggled === "function") {
                    return option.onCheckedToggled(checked);
                } else {
                    if (checked && internalSelectedIds.length === maxItemCount) {
                        if (onMaxItemsReached) {
                            onMaxItemsReached();
                        }
                        return Q.reject();
                    }
                    return selectable ? Q.resolve(true) : Q.reject();
                }
            },
            onCheckedChange: (checked, dueToRadioMode = false) => {
                if (option.hasOwnProperty("onCheckedChange") && typeof option.onCheckedChange === "function") {
                    option.onCheckedChange(checked, dueToRadioMode);
                } else {
                    if (!dueToRadioMode) {
                        try {
                            const allowedCheckChange = checked || (allowMultiple && !checked)
                            if (allowedCheckChange && autoClose) {
                                onSelected(option.id);
                                closeSelectorScreens()
                            } else {
                                allowedCheckChange && dispatchSelectionChange && onSelected(option.id, checked);
                                allowedCheckChange && updateSelectedIds(option.id, checked, !allowMultiple);
                            }
                        } catch (ex) {
                            console.error(InternalSelectorScreen.name, "onCheckedChange - delegate failed!", ex);
                        }
                    }
                }
            }
        }

        if (!option.hasOwnProperty("id") && option.hasOwnProperty("noSection") && option.noSection) {
            delete rowOptions.checked
        }

        return rowOptions;
    }

    const createOptionSection = (sectionData) => {
        let section = {
            rows: sectionData.options.map(createOptionRow)
        };
        if (section.rows.length === 0) {
            return null;
        }
        if (sectionData.title) {
            section.headerElement =
                <LxReactText style={globalStyles.customStyles.sectionHeader}>{sectionData.title}</LxReactText>;
        }
        return section;
    }

    const detectedSections = () => {
        // if the first option has options on its own, but not an id --> it means that the content shown has sections.
        return options && options.length > 0 && options[0].hasOwnProperty("options") && !options[0].hasOwnProperty("id") && !options[0].hasOwnProperty("noSection");
    }

    const selectionContent = useMemo(() => {
        if (detectedSections()) {
            return options.map(createOptionSection).filter(section => !!section && section.rows && section.rows.length);
        } else {
            return [{
                rows: options.map(createOptionRow)
            }];
        }
    }, [options, internalSelectedIds, maxItemCount]);

    const newOptionName = useRef(null);
    const createNewOptionRow = (value) => {
        const row = createOptionRow({id: CREATE_NEW_ID});
        delete row.title;
        row.mainCenterContent = {
            comp: LxReactTextView,
            props: {
                value: newOptionName.current,
                alignment: "left",
                textStyle: styles.inputStyle,
                placeholderTextColor: globalStyles.colors.text.secondary,
                placeholder: createNewPlaceholder,
                validationRegex: createNewRegex,
                autoFocus: internalSelectedIds[0] === CREATE_NEW_ID,
                textChanged: (newValue, isValid) => {
                    if (isValid) {
                        newOptionName.current = newValue;
                    } else {
                        newOptionName.current = null;
                    }
                    updateSelectedIds(CREATE_NEW_ID, true, true);
                },
                onFocus: () => {
                    if (internalSelectedIds[0] !== CREATE_NEW_ID) {
                        updateSelectedIds(CREATE_NEW_ID, true, true);
                    }
                },
                onBlur: (newValue, valid, modified) => {
                    if (newValue && valid && modified) {
                        newOptionName.current = newValue;
                    } else if (!valid) {
                        newOptionName.current = null;
                    }
                },
                filterRegex: createNewFilterRegex
            }
        };

        row.onCheckedChange = (checked, dueToRadioMode = false) => {
            if (!dueToRadioMode) {
                checked && updateSelectedIds(CREATE_NEW_ID, true, true);
            }
        }
        return row;
    }

    const createSection = useMemo(() => {
        if (!createFn) {
            return null;
        }
        return {
            rows: [
                createNewOptionRow()
            ]
        };
    }, [createFn, internalSelectedIds, createNewPlaceholder, createNewRegex])

    const tableContent = useMemo(() => {
        let content = [...selectionContent];
        if (createSection) {
            content.splice(0, 0, createSection);
        }
        return content;
    }, [selectionContent, internalSelectedIds])

    return <SearchableTable tableContent={tableContent}
                            filterTitle={filterTitle}
                            getCustomFilteredContent={getCustomFilteredContent && getAdoptedCustomFiltered}
                            withoutHorizontalSpaces={withoutHorizontalSpaces}/>
}

const styles = {
    inputStyle: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.primary,
        height: "100%",
        marginTop: "auto",
        marginBottom: "auto",
    }
}

const CREATE_NEW_ID = "create-new-entry";

export default InternalSelectorScreen;
