const addNodeWhilePreventingDuplicates = (arr, node) => {
    if(arr.every(n => n._uuid !== node._uuid)) {
        arr.push(node);
    }
}

/**
 * Returns all nodes that do not have any child nodes (i.e. leave nodes) or that are have a control.
 * @param arr
 * @param nodes
 * @param ignoredTypes
 */
const getLeafNodesForStatistics = (arr, nodes, ignoredTypes = []) => {
    if(!Array.isArray(nodes)) {return;}

    nodes.forEach(node => {
        if (typeof node.getNodeTypes !== "function") {
            console.warn("getLeafNodesForStatistics", "bailing out due to missing getNodeTypes fn! ", node);
            // probably resuming on some persisted data, nodes may not be actual instances, but parsed JSON.
            return;
        }
        const ignoredNodeType = !!ignoredTypes.find(ignType => node.nodeType === ignType);

        
        //check if all children are Load type nodes
        const only_load_children = Object.entries(node.getNodeTypes())
        .every(([key,value])=> key === 'Load' || value !== true);

        const is_leaf = !node.hasChildNodes
        // Distribution boards should always have their children checked
        // They would otherwise be usually ignored, because they show as Consumption nodes
        // However, they can have prod/grid/storage behind them that are important for the stats
        // If however the control has only Load type children the leafs do not need to be loaded
        const is_leaflike_control = !!node.control && (
            !node.isDistributionBoard
            || only_load_children
        )

        if(is_leaf || is_leaflike_control) {
            !ignoredNodeType && addNodeWhilePreventingDuplicates(arr, node);
        } else {
            getLeafNodesForStatistics(arr, node.childNodes, ignoredTypes);
        }
    })
}

export default getLeafNodesForStatistics
