'use strict';

define("IRoomControlCommandSrc", ["ControlCommandSrc", "IRoomControlEnums"], function (ControlCommandSrc, IRoomControlEnums) {
    return class IRoomControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            let cmds = [];

            if (states.remainingTime > 0) {
                cmds.push(this.createCommand(_("controls.irc.timer.stop"), Commands.I_ROOM_CONTROLLER.CANCEL_TIMER, null));
            }

            if (states.serviceMode) {
                cmds.push(this.createCommand(_("controls.irc.service.end"), Commands.format(Commands.I_ROOM_CONTROLLER.SERVICE, IRoomControlEnums.ServiceMode.OFF), null));
                cmds.push(this._serviceCmd(_("controls.irc.service.no-heat-no-cool"), IRoomControlEnums.ServiceMode.HEATING_OFF_COOLING_OFF, states));
                cmds.push(this._serviceCmd(_("controls.irc.service.heat-no-cool"), IRoomControlEnums.ServiceMode.HEATING_ON_COOLING_OFF, states));
                cmds.push(this._serviceCmd(_("controls.irc.service.no-heat-cool"), IRoomControlEnums.ServiceMode.HEATING_OFF_COOLING_ON, states));
                cmds.push(this._serviceCmd(_("controls.irc.service.heat-cool"), IRoomControlEnums.ServiceMode.HEATING_ON_COOLING_ON, states));
            }

            if (states.isInManualMode) {
                cmds.push(this.createCommand(_("controls.irc.manual.temp"), this.showManualTempEditor.bind(this, control), null));
            }

            if (states.remainingTime <= 0 && !states.logicOverride && // ensures no timer is currently running
                states.userInfo !== IRoomControlEnums.UserInfo.NO_PERIOD_ACTIVE) {
                cmds.push(this.createCommand(_("controls.irc.timer.start"), this.showTimer.bind(this, control), null))
            }

            return cmds;
        }

        _serviceCmd(name, modeNr, states) {
            let cmd = Commands.format(Commands.I_ROOM_CONTROLLER.SERVICE, modeNr);
            return this.createSelectable(name, cmd, null, states.serviceMode === modeNr, null);
        }

        showManualTempEditor(control) {
            NavigationComp.showState(IRoomControlEnums.ScreenState.MAN_TEMP_SCREEN, { control })
        }

        showTimer(control) {
            NavigationComp.showState(IRoomControlEnums.ScreenState.TIMER, { control })
        }

    };
});
