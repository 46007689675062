'use strict';

define("ControlStateContainer", ["GlobalStyles"], function ({ default: globalStyles }) {
    return class ControlStateContainer {
        /**
         * ctor for ControlStateContainer
         * @param control
         * @constructor
         */
        constructor(control) {
            this.registrations = [];
            this.control = control;
            this.version = 0; // counts the updates received from the miniserver to be able to check if something changed for a listener

            this.name = this.control.name + "-StateContainer";

            this._addGetters();

            this.hasAllStates = false;

            if (this.control.securedDetails) {
                this.securedDetailsRegId = ActiveMSComponent.registerForSecuredDetails(this.control.uuidAction, this.updateWithSecuredDetails.bind(this));
            }

            this.deregMessageCenterUpdateReceiver = ActiveMSComponent.registerSourceUuidForMessageCenterUpdate(this._messageCenterUpdateReceived.bind(this), this.control.uuidAction); // Register for the structureChange event to react to changes and eg. update the live icon

            this._structureChangesReg = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.StructureChanged, this._checkForStructureChanges.bind(this));

            if (this.shouldRegisterForUTCUpdate()) {
                this.utcUpdateReg = SandboxComponent.getMiniserverTimeInfo(this, this._onUtcUpdate.bind(this), TimeValueFormat.MINISERVER_UTC_OFFSET);
            }
        }

        /**
         * Returns an array of object with at least a "name" and "id" property
         * @param sourceState
         * @return {*[]}
         */
        getAutomaticDesignerStateObjectsFromState(sourceState) {
            var stateObj = [],
                tmpStateObj;

            try {
                tmpStateObj = this.states[sourceState];

                if (Array.isArray(tmpStateObj) && tmpStateObj[0].hasOwnProperty("name") && tmpStateObj[0].hasOwnProperty("id")) {
                    stateObj = tmpStateObj;
                } else {
                    throw new Error("Object malformed!");
                }
            } catch (e) {
                console.warn("Couldn't get AutomaticDesignerStateObject for sourceState: '" + sourceState + "' - " + e.message);
            }

            return stateObj;
        }

        /**
         * This method is called once the secured details have been loaded. This might some time later.
         * @param secDetails
         */
        updateWithSecuredDetails(secDetails) {
            this.control = ActiveMSComponent.mergeSecuredDetailsWithControl(this.control, secDetails);
            delete this.securedDetailsRegId;
        }

        /**
         * This method is called every time the controls structure changes (ExpertMode)
         */
        structureDidChange() {// implement custom handling here!
        }

        /**
         * returns either the current states and the states version or false if not all states has been received yet
         * @returns {{states:{object}, version: {number}}|boolean}
         */
        getStatesForUUID() {
            if (this.version && // don't check for this.hasAllStates, because we use getStatesForUUID before allStatesReceived gets called!
                this._checkIfStatesAreStillValid()) {
                // The states can also be returned within 10 minutes after a connection loss. Don't just deny the states after a connection loss, this will result in corrupt UI
                return {
                    states: this.states,
                    version: this.version
                };
            }

            return false;
        }

        /**
         * registers a function for status updates
         * @param {object} listener
         * @param {function} fn
         * @param {function} [allStatesReceivedFn] function if all states are up to date
         * @param {boolean} [statesVersion] the version of the states from the listener (if he requested getStatesForUUID before..)
         */
        registerForStatusUpdates(listener, fn, allStatesReceivedFn, statesVersion) {
            this.registrations.push({
                listener: listener,
                fn: fn,
                allStatesReceivedFn: allStatesReceivedFn
            });

            if (this.hasAllStates) {
                try {
                    allStatesReceivedFn && allStatesReceivedFn.call(listener, true);

                    if (typeof statesVersion !== "number" || statesVersion < this.version) {
                        fn.call(listener, this.states);
                    }
                } catch (e) {
                    console.error(e.stack || e);
                }
            }
        }

        registerFunctionForStateChanges(fn, allStatesReceivedFn) {
            var uniqueId = getRandomIntInclusive(0, 9999999999); //TODO-woessto find proper register/unregister identifier
            this.registrations.push({
                listener: uniqueId,
                isFnListener: true,
                fn: fn,
                allStatesReceivedFn: allStatesReceivedFn
            });

            if (this.hasAllStates) {
                try {
                    allStatesReceivedFn && allStatesReceivedFn(true);
                } catch (e) {
                    console.error(e.stack || e);
                }
            }
            return () => {
                this.unregisterFromStatusUpdates(uniqueId);
            };
        }

        /**
         * The StateContainer will register for Miniserver UTC updates if returned true
         * This is especially useful when some states are UTC based and need to be formatted into a readable time
         * @note: Such a timeupdate will notify all listeners!
         * @return {boolean}
         */
        shouldRegisterForUTCUpdate() {
            return false;
        }

        /**
         * removes a function from status update receivers
         * @param listener
         */
        unregisterFromStatusUpdates(listener) {
            var registered;

            for (var i = 0; i < this.registrations.length; i++) {
                registered = this.registrations[i];

                if (registered.listener === listener) {
                    this.registrations.splice(i, 1);
                    break;
                }
            }
        }

        getStateNames(control) {
            return Object.keys(control.states);
        }

        getUUIDsToRegister(control, stateNames) {
            control = control || this.control;
            stateNames = stateNames || this.getStateNames(control);
            var uuids = [],
                i,
                stateName;

            for (i = 0; i < stateNames.length; i++) {
                stateName = stateNames[i];

                if (control.states.hasOwnProperty(stateName)) {
                    if (control.states[stateName] instanceof Array) {
                        uuids = uuids.concat(control.states[stateName]);
                    } else if (typeof control.states[stateName] === "object") {
                        uuids = uuids.concat(Object.values(control.states[stateName]));
                    } else {
                        uuids.push(control.states[stateName]);
                    }
                }
            }

            return uuids;
        }

        newStatesReceived(newVals) {
            if (!this.prepareStates) {
                console.log("'prepareStates' has to be overwritten from subclass!");
                return;
            }

            this.version++; // count up version with each update

            this.newVals = newVals;
            this.dateAtConnectionLoss = null;

            try {
                this._prepareUniversalStates(newVals);

                Q.resolve(this.prepareStates(newVals)).finally(function () {
                    this._checkPresenceSimulation(newVals);
                    this.notifyListener();
                }.bind(this));
            } catch (e) {
                console.error(e.stack || e);
            }
        }

        notifyListener() {
            var regs = this.registrations.slice(),
                // take registrations -> could change during notifying!
                registered;

            for (var i = 0; i < regs.length; i++) {
                registered = regs[i];

                try {
                    if (registered.isFnListener) {
                        registered.fn(this.states);
                    } else {
                        registered.fn.call(registered.listener, this.states);
                    }
                } catch (e) {
                    console.error(e.stack || e);
                }
            }

            regs = null;
            registered = null;
        }

        allStatesReceived(allStatesArrived) {
            this.hasAllStates = allStatesArrived;
            var regs = this.registrations.slice(),
                // take registrations -> could change during notifying!
                registered;

            for (var i = 0; i < regs.length; i++) {
                registered = regs[i];

                try {
                    registered.allStatesReceivedFn && registered.allStatesReceivedFn.call(registered.listener, this.hasAllStates);
                } catch (e) {
                    console.error(e.stack || e);
                }
            }

            regs = null;
            registered = null;
        }

        /**
         * sends the command for this control
         * @param cmd
         */
        sendCommand(cmd) {
            return SandboxComponent.sendCommand(this, this.control.uuidAction, cmd, Commands.Type.QUEUE, this.control.isSecured);
        }

        /**
         * gets called when the connection got lost
         */
        connectionLost() {
            // store versionAtConnectionLoss -> we need to distinguish whether we received the first status update in the current connection session (needed for alerts)
            // the version needs to be stored and compared, because otherwise (eg. if we reset the version to 0) we would probably miss the first StatusUpdate after a reconnect!
            this.versionAtConnectionLoss = this.version;
            this.dateAtConnectionLoss = moment();
        }

        /**
         * if only 1 (the first update) has been received so far or if this is the first update in the current connection session
         * @returns {boolean}
         */
        isFirstUpdate() {
            return this.version === 1 || this.versionAtConnectionLoss === this.version - 1;
        }

        destroy() {
            this.securedDetailsRegId && ActiveMSComponent.unregisterFromSecuredDetails(this.control.uuidAction, this.securedDetailsRegId);

            if (this.deregMessageCenterUpdateReceiver) {
                this.deregMessageCenterUpdateReceiver();
            }

            if (this._structureChangesReg) {
                this._structureChangesReg();
            }

            if (this.utcUpdateReg) {
                SandboxComponent.removeFromTimeInfo(this.utcUpdateReg);
            } // override if needed

        }

        /**
         * return a state text to be displayed in eg. comfort mode
         * @param [short]   if short text should be used
         * @returns {string|null|undefined}
         */
        getStateText(short) {
            if (!this.control.isConfigured()) {
                return _("unconfigured.title");
            } // override as needed!

        }

        /**
         * return a state text to be displayed in e.g. the system scheme
         * @returns {string|null|undefined}
         */
        getStateTextForInfo() {
            var stateTextShort = this.getStateTextShort(),
                res;

            if (isEmptyOrNullString(stateTextShort)) {
                res = stateTextShort;
            } else {
                res = this.control.getName();
            }

            return res;
        }

        /**
         * just calls this.getStateText with short parameter true
         * @returns {*|string|null|undefined}
         */
        getStateTextShort() {
            return this.getStateText(true);
        }

        /**
         * will simply return the result of this.getStateText() by default. can be overwritten to provide a different
         * state text in the content (actionScreen) than in the cell/card.
         * @return {*|string|null|undefined}
         */
        getStateTextForContent() {
            return this.getStateText();
        }

        /**
         * return a state icon to be displayed in eg. comfort mode
         * @returns {string|null|undefined}
         */
        getStateIcon() {// override as needed!
        }

        /**
         * return a state icon color
         * @returns {string|null|undefined}
         */
        getStateIconColor() {// override as needed!
            return this.getStateColor();
        }

        /**
         * Icon itself remains unchanged, just the transformations are modified.
         * E.g. for garage door.
         * Falls back to getStateIcon if getLiveStateIcon isn't implemented in subclass
         * @returns {string|null|undefined}
         */
        getLiveStateIcon() {
            return this.getStateIcon();
        }

        /**
         * return a state icon to be displayed in eg. comfort mode
         * @returns {string|null|undefined}
         */
        getStateIconForContent() {
            // override as needed!
            return this.getStateIcon();
        }

        /**
         * return the state info
         * { title: "", message: "", color: "" }
         */
        getStateInfo() {// override as needed!
        }

        /**
         * return the small state icon
         * @returns {{iconSrc: "", color: ""}}
         */
        getStateIconSmall() {// override as needed!
        }

        /**
         * return the small state icon
         * @returns {{iconSrc: "", color: ""}}
         */
        getStateIconSmallForContent() {
            // override as needed!
            return this.getStateIconSmall();
        }

        /**
         * return a state icon color to be displayed in eg. comfort mode
         * @returns {string|null|undefined}
         */
        getStateColor() {
            // override as needed!
            return "";
        }

        /**
         * return a state icon color to be displayed in eg. comfort mode
         * @returns {string|null|undefined}
         */
        getStateColorForContent() {
            // override as needed!
            return this.getStateColor();
        }

        getStateTintColor() {// override as needed!
        }

        /**
         * return a state text color to be displayed in eg. comfort mode
         * @returns {string|null|undefined}
         */
        getStateTextColor() {
            // override as needed!
            if (!this.control.isConfigured()) {
                return window.Styles.colors.orange;
            } else {
                return this.getStateColor();
            }
        }

        /**
         * e.g. the energy monitor will activate/deactivate certain statistic outputs depending on the state.
         * @returns {*}
         */
        getStatisticOutputs() {
            return this.control.statistic.outputs;
        }

        getPresenceSimulationText() {
            if (this.states.presenceSimulation && this.states.presenceSimulation.active) {
                return this.states.presenceSimulation.from + NBR_SPACE + "-" + NBR_SPACE + this.states.presenceSimulation.to + NBR_SPACE + _('timeSuffix');
            }
        }

        _getIsPresenceSimulationActive() {
            return this.states.presenceSimulation && this.states.presenceSimulation.active;
        }

        /**
         * Helper method to create a state info object.
         * @param message
         * @param title
         * @param color
         * @return {{message: *, title: *, color: *}}
         * @private
         */
        _createStateInfo(message, title, color) {
            return {
                message: message,
                title: title,
                color: color
            };
        }

        /**
         * checks if presence simulation is available and handles the states..
         * @param newVals
         * @private
         */
        _checkPresenceSimulation(newVals) {
            if (this.control.presence && typeof newVals[this.control.states.presenceFrom] === "number" && typeof newVals[this.control.states.presenceTo] === "number") {
                Debug.PresenceSimulation && console.log("------------------------------");
                Debug.PresenceSimulation && console.log(this.control.name);
                Debug.PresenceSimulation && console.log("presenceFrom", newVals[this.control.states.presenceFrom]);
                Debug.PresenceSimulation && console.log("presenceTo", newVals[this.control.states.presenceTo]);
                var fromSecs = newVals[this.control.states.presenceFrom],
                    toSecs = newVals[this.control.states.presenceTo];
                var obj = {
                    //active: fromSecs >= 0 && toSecs > 0
                    active: fromSecs >= 0 && toSecs > 0 || fromSecs > 0 && toSecs >= 0
                };

                if (obj.active) {
                    if (toSecs > 86400) {
                        // eg. from "Abenddämmerung" to "Morgendämmerung" -> seconds can be greater than 86400!
                        toSecs -= 86400;
                    }

                    obj.from = LxDate.formatSecondsIntoDigits(fromSecs, true, false, true);
                    obj.to = LxDate.formatSecondsIntoDigits(toSecs, true, false, true);
                }

                this.states.presenceSimulation = obj;
                Debug.PresenceSimulation && console.log(JSON.stringify(this.states.presenceSimulation));
                Debug.PresenceSimulation && console.log("------------------------------");
            }
        }

        /**
         * adds the states.xxx getters
         * @private
         */
        _addGetters() {
            var getStateText = this.getStateText.bind(this),
                getStateTextShort = this.getStateTextShort.bind(this),
                getStateTextForInfo = this.getStateTextForInfo.bind(this),
                getStateTextForContent = this.getStateTextForContent.bind(this),
                getStateIcon = this.getStateIcon.bind(this),
                getStateIconColor = this.getStateIconColor.bind(this),
                getLiveStateIcon = this.getLiveStateIcon.bind(this),
                getStateIconForContent = this.getStateIconForContent.bind(this),
                getStateIconSmall = this.getStateIconSmall.bind(this),
                getStateIconSmallForContent = this.getStateIconSmallForContent.bind(this),
                getStateColor = this.getStateColor.bind(this),
                getStateColorForContent = this.getStateColorForContent.bind(this),
                getStateTintColor = this.getStateTintColor.bind(this),
                getStateTextColor = this.getStateTextColor.bind(this),
                getStateInfo = this.getStateInfo.bind(this),
                getPresenceSimulationText = this.getPresenceSimulationText.bind(this),
                _getIsPresenceSimulationActive = this._getIsPresenceSimulationActive.bind(this),
                _getUniversalIsLocked = this._getUniversalIsLocked.bind(this),
                _getUniversalIsUnlockable = this._getUniversalIsUnlockable.bind(this),
                _getUniversalLockReason = this._getUniversalLockReason.bind(this),
                _getUniversalLockedColor = this._getUniversalLockedColor.bind(this),
                _getUniversalLockedIcon = this._getUniversalLockedIcon.bind(this),
                _getUniversalLockedIconSmall = this._getUniversalLockedIconSmall.bind(this),
                _getUniversalLockedText = this._getUniversalLockedText.bind(this),
                _getUniversalLockedDescription = this._getUniversalLockedDescription.bind(this);

            this.states = {
                get stateText() {
                    if (_getUniversalIsLocked()) {
                        return _getUniversalLockedText();
                    }

                    return getStateText();
                },

                get stateTextShort() {
                    if (_getUniversalIsLocked()) {
                        return _getUniversalLockedText();
                    } else if (_getIsPresenceSimulationActive()) {
                        return _('presence-simulation');
                    }

                    return getStateTextShort();
                },

                get stateTextForInfo() {
                    if (_getUniversalIsLocked()) {
                        return _getUniversalLockedText(); // important for system scheme
                    }

                    return getStateTextForInfo();
                },

                get stateTextForContent() {
                    if (_getUniversalIsLocked()) {
                        return _getUniversalLockedDescription();
                    }

                    return getStateTextForContent();
                },

                get stateIcon() {
                    return getStateIcon();
                },


                get stateIconColor() {
                    return getStateIconColor();
                },

                get liveStateIcon() {
                    return getLiveStateIcon();
                },

                get stateIconForContent() {
                    if (_getUniversalIsLocked()) {
                        return _getUniversalLockedIcon();
                    }

                    return getStateIconForContent();
                },

                get stateIconSmall() {
                    if (_getUniversalIsLocked()) {
                        return _getUniversalLockedIconSmall();
                    } else if (_getIsPresenceSimulationActive()) {
                        return {
                            iconSrc: Icon.PRESENCE_SIMULATION,
                            color: globalStyles.colors.blue_fixed
                        }
                    }

                    return getStateIconSmall();
                },

                get stateIconSmallForContent() {
                    if (_getUniversalIsLocked()) {
                        return null;
                    }

                    return getStateIconSmallForContent();
                },

                get stateColor() {
                    if (_getUniversalIsLocked()) {
                        return null;
                    }

                    return getStateColor();
                },

                get stateColorForContent() {
                    if (_getUniversalIsLocked()) {
                        return _getUniversalLockedColor;
                    }

                    return getStateColor();
                },

                get stateTintColor() {
                    if (_getUniversalIsLocked()) {
                        return null;
                    }

                    return getStateTintColor();
                },

                get stateTextColor() {
                    if (_getUniversalIsLocked()) {
                        return _getUniversalLockedColor();
                    }

                    return getStateTextColor();
                },

                get stateInfo() {
                    if (_getUniversalIsLocked()) {
                        return null;
                    }

                    return getStateInfo();
                },

                get presenceSimulationText() {
                    if (_getUniversalIsLocked()) {
                        return null;
                    }

                    return getPresenceSimulationText();
                },

                // universal states, those are (potentially) available across all controls
                get universalIsLocked() {
                    return _getUniversalIsLocked();
                },

                get universalIsUnlockable() {
                    return _getUniversalIsUnlockable();
                },

                get universalLockReason() {
                    return _getUniversalLockReason();
                }

            };
        }

        /**
         * We got a messageCenterUpdate for the registered sourceUuid
         * @param ev
         * @param entries Array of entries for the registered sourceUuid
         * @param sourceUuid The registered sourceUuid
         * @private
         */
        _messageCenterUpdateReceived(ev, entries, sourceUuid) {
            this.states.messageCenterEntries = entries;

            if (this.hasAllStates) {
                this.version++; // count up version with each update
            }

            this.notifyListener();
        }

        /**
         * Structure did change, check if this control is part of the changes and react
         * @param ev
         * @param changes
         * @private
         */
        _checkForStructureChanges(ev, changes) {
            if (changes.controls && !!changes.controls[this.control.uuidAction]) {
                this.structureDidChange();
            }
        }

        /**
         * Returns true if there hasn't been any connection loss yet, or the connection loss has been within 10 minutes
         * @return {boolean}
         * @private
         */
        _checkIfStatesAreStillValid() {
            if (this.dateAtConnectionLoss) {
                return moment().diff(this.dateAtConnectionLoss, "seconds") <= WAITING_FOR_STATES_TIMEOUT;
            }

            return true;
        }

        /**
         * Notifies all listeners
         * Gets called when the stateContainer has registered for the UTC update
         * and the UTC offset changes
         * @private
         */
        _onUtcUpdate() {
            this.notifyListener();
        }

        // ---------------------------------------------------------------------------------------------------------
        // -------------------------- Universal State Handling -----------------------------------------------------
        // ---------------------------------------------------------------------------------------------------------

        /**
         * In order to avoid issues where the subclasses prepare states isn't called, states that are available across
         * all controls are handled in this separate function.
         * @param newVals
         */
        _prepareUniversalStates(newVals) {
            this._uStates = this._uStates || {};
            this._uStates.lockInfo = this._getLockInfoFromState(newVals);
        }

        _getLockInfoFromState(newVals) {
            var lockInfo;
            var lockObj = {
                locked: ControlLockState.NOT_LOCKED,
                reason: null
            };

            if (Feature.CONTROL_LOCK_HANDLING && !!this.control.states && this.control.states.jLocked) {
                try {
                    lockInfo = newVals[this.control.states.jLocked];

                    if (lockInfo && lockInfo.text) {
                        lockObj = JSON.parse(lockInfo.text);

                        if (lockObj.reason && lockObj.locked === 0) {
                            lockObj.locked = ControlLockState.LOCKED_BY_LOGIC;
                        }
                    }
                } catch (ex) {
                    if (lockInfo) {
                        console.error(this.name, "Failed to parse lockInfo: '" + lockInfo.text + "'");
                    } else {
                        console.error(this.name, "Failed to get lockInfo!");
                    }
                }
            }

            return lockObj;
        }

        _getUniversalIsLocked() {
            var lockState = ControlLockState.NOT_LOCKED;
            var isLocked;

            if (this._uStates && this._uStates.lockInfo) {
                lockState = this._uStates.lockInfo.locked;
            }

            isLocked = lockState !== ControlLockState.NOT_LOCKED;
            return isLocked;
        }

        _getUniversalIsUnlockable() {
            var lockState = ControlLockState.NOT_LOCKED;

            if (this._uStates && this._uStates.lockInfo) {
                lockState = this._uStates.lockInfo.locked;
            }

            return lockState === ControlLockState.LOCKED_BY_VISU;
        }

        _getUniversalLockReason() {
            var reason = null;

            if (this._getUniversalIsLocked()) {
                // only available if it's locked
                reason = this._uStates.lockInfo.reason;
            }

            return reason;
        }

        _getUniversalLockedColor() {
            return window.Styles.colors.red;
        }

        _getUniversalLockedIcon() {
            return Icon.LOCK;
        }

        _getUniversalLockedIconSmall() {
            return {
                iconSrc: this._getUniversalLockedIcon(),
                color: this._getUniversalLockedColor()
            };
        }

        _getUniversalLockedText() {
            return _("control.lock.locked-title");
        }

        _getUniversalLockedDescription() {
            var name, description;

            if (this.control && this.control.name && this._hasUniversalLockInfo()) {
                name = this.control.name;

                if (this._uStates.lockInfo.locked === ControlLockState.LOCKED_BY_LOGIC) {
                    description = _("control.lock.locked-by-logic-description", {
                        name: name
                    });
                } else {
                    description = _("control.lock.locked-description", {
                        name: name
                    });
                }
            } else {
                description = null;
            }

            return description;
        }

        _hasUniversalLockInfo() {
            return this.states && this._uStates && this._uStates.lockInfo;
        }

    };
});
