'use strict';
/**
 * This screen is used to present a screen allowing to select an specific end date & time.
 * Based on the TimerUntilScreen - but with more functionality:
 * Additionally it allows for choosing an option for the timer and an option after the timer
 * Like: start at timer with option A until 2030-01-21 and then option B should be in place afterwards.
 *
 * details
 *      title           title of the screen
 *      callbackFn      function to call when the duration is to be sent, seconds will be passed as first argument,
 *                      eventual selected timerOptions as seconds and afterTimerOptions as third argument.
 *      returnTimepoint default: false. when true the callback will be calles with seconds since 1.1.2009
 *      offerAlways     default: false. When true a switch is available and -1 will be returned when the switch is active.
 *      [pickerText]    text shown inside the picker cell, defaults to "Until" if not provided
 *      [footerTitle]   shown as footer below the date/time-picker
 *
 *      [timerOptions]          an array of options too choose from
 *          [timerOption]       the option selected will be returned using the callbackFn as second argument.
 *                              Must contain at least a title!
 *                              May contain a selected attribute.
 *      [timerOptionsTitle]     the title to show above the options
 *
 *      [afterTimerOptions]     an array of options that will be in place after the timer
 *          [afterTimerOption]  the option selected will be returned using the callbackFn as THIRD argument.
 *                              Must contain at least a title!
 *                              May contain a selected attribute.
 *      [afterTimerOptionsTitle]  the title to show above the afterTimerOptions
 */

define(["TimerUntilScreen"], function (TimerUntilScreen) {
    return class TimerUntilOptionsScreen extends TimerUntilScreen {
        constructor(details) {
            super(...arguments);

            if (this.details.timerOptions) {
                this.timerOptions = cloneObject(this.details.timerOptions);
                this.timerOptions.forEach(function (option) {
                    if (option.selected) {
                        this.selectedTimerOption = option;
                    }
                }.bind(this));
            }

            if (this.details.afterTimerOptions) {
                this.afterTimerOptions = cloneObject(this.details.afterTimerOptions);
                this.afterTimerOptions.forEach(function (option) {
                    if (option.selected) {
                        this.selectedAfterTimerOption = option;
                    }
                }.bind(this));
            }
        }

        triggerCallback(callbackValue) {
            // add additional arguments to callback!
            return this.details.callbackFn(callbackValue, this.selectedTimerOption, this.selectedAfterTimerOption);
        }

        getSectionsBeforePicker() {
            var sections = [];

            if (this.timerOptions) {
                sections.push(this._getTimerOptionsSection());
            }

            return sections;
        }

        getSectionsAfterPicker() {
            var sections = [];

            if (this.afterTimerOptions) {
                sections.push(this._getAfterTimerOptionsSection());
            }

            return sections;
        }

        // ----------------------------------------------------------------------------------------
        //  Private Methods
        // ----------------------------------------------------------------------------------------
        _getTimerOptionsSection() {
            var section = {
                headerTitle: this.details.timerOptionsTitle,
                rows: []
            };
            this.timerOptions.forEach(function (option) {
                section.rows.push(this._createOptionCell(option));
            }.bind(this));
            return section;
        }

        _getAfterTimerOptionsSection() {
            var section = {
                headerTitle: this.details.afterTimerOptionsTitle,
                rows: []
            };
            this.afterTimerOptions.forEach(function (option) {
                section.rows.push(this._createOptionCell(option, true));
            }.bind(this));
            return section;
        }

        _createOptionCell(option, isAfter) {
            return {
                type: GUI.TableViewV2.CellType.CHECKABLE,
                content: {
                    title: option.title,
                    selected: option.selected,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED
                },
                didCheckCell: function (cell, section, row, tableView, selected) {
                    if (isAfter) {
                        this._afterTimerOptionSelected(row);
                    } else {
                        this._timerOptionSelected(row);
                    }
                }.bind(this)
            };
        }

        _timerOptionSelected(index) {
            var optionNr = 0;
            this.timerOptions.forEach(function (option) {
                option.selected = index === optionNr++;
            }.bind(this));
            this.selectedTimerOption = this.timerOptions[index];
        }

        _afterTimerOptionSelected(index) {
            var optionNr = 0;
            this.afterTimerOptions.forEach(function (option) {
                option.selected = index === optionNr++;
            }.bind(this));
            this.selectedAfterTimerOption = this.afterTimerOptions[index];
        }

    };
});
