'use strict';

define([], function () {
    return class AlexaSetupViewController extends GUI.ModalViewController {
        constructor(details) {
            super();
            this.platform = PlatformComponent.getPlatformInfoObj().platform;
            this._isAdminUser = ActiveMSComponent.isAdminUser();
        }

        getAnimation() {
            return AnimationType.NONE;
        }

        getShouldBlockScreenSaver() {
            return true;
        }

        handleNextScreen(details) {
            return this.showState(details.state, null, details.details);
        }

        getPermissions() {
            if (this._isAdminUser) {
                return [{
                    permission: MsPermission.ADMIN,
                    revoke: true
                }, {
                    permission: MsPermission.CONFIG,
                    revoke: true
                }, {
                    permission: MsPermission.EXPERT_MODE,
                    revoke: true
                }, {
                    permission: MsPermission.MANAGE_MS_PLUGINS,
                    revoke: true
                }, {
                    permission: MsPermission.USER_MANAGEMENT,
                    revoke: true
                }, {
                    permission: MsPermission.CHANGE_PWD,
                    revoke: true
                }];
            } else {
                return super.getPermissions(...arguments);
            }
        }

        closeAction(error) {
            if (error && getLxResponseValue(error) && getLxResponseCode(error) === 501) {
                return NavigationComp.showPopup({
                    title: '',
                    message: getLxResponseValue(error).error,
                    buttonOk: _('done')
                });
            }

            if (this.currentViewIdentifier !== ScreenState.AlexaOnboardingScreen && this.currentViewIdentifier !== ScreenState.AlexaConfiguredScreen) {
                return NavigationComp.showPopup({
                    title: '',
                    message: _('alexa.plugin.cancel'),
                    buttonOk: _('done')
                }).then(function () {
                    var deletingPrms = ActiveMSComponent.deleteAlexaPlugin();
                    NavigationComp.showWaitingFor(deletingPrms, _('alexa.plugin.delete'));
                    deletingPrms.then(function () {
                        return this.ViewController.dismiss();
                    }.bind(this));
                }.bind(this));
            } else {
                return this.dismiss();
            }
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                if (this._isAdminUser) {
                    return ActiveMSComponent.isAlexaAddonCreated().then(function (response) {
                        if (isOlderVersionThan(response.version, '0.3.6')) {
                            NavigationComp.showPopup({
                                message: "Please update your alexa plugin.".debugify(),
                                buttonOk: _('okay')
                            });
                            return Q.reject();
                        } else if (response.created) {
                            if (response.ownerUserUuid !== ActiveMSComponent.getCurrentUser().uuid) {
                                response.isDifferentUser = true;
                                return this._navigateToConfiguredScreen(response);
                            } else {
                                return ActiveMSComponent.checkPluginConfiguredState().then(function (isConfigured) {
                                    if (isConfigured) {
                                        return this._navigateToConfiguredScreen(response);
                                    } else {
                                        return this._navigateToAlexaOnboarding();
                                    }
                                }.bind(this));
                            }
                        } else {
                            return this._navigateToAlexaOnboarding();
                        }
                    }.bind(this), function () {
                        return this._navigateToAlexaOnboarding();
                    }.bind(this));
                } else {
                    return this._navigateToAlexaOnboarding();
                }
            }.bind(this));
        }

        viewWillDisappear() {
            ActiveMSComponent.alexaClearRequestTimeouts();
            return super.viewWillDisappear(...arguments);
        }

        _navigateToConfiguredScreen(pluginInfo) {
            return this.showState(ScreenState.AlexaConfiguredScreen, null, {
                isDifferentUser: pluginInfo.isDifferentUser,
                ownerUserName: pluginInfo.ownerUserName
            });
        }

        _navigateToAlexaOnboarding() {
            var def = Q.defer(),
                isCurrentUserAdmin = ActiveMSComponent.getCurrentUser().isAdmin,
                details = {
                    iconSrc: Icon.ALEXA.APP,
                    title: _('alexa.onboarding.title'),
                    message: _('alexa.onboarding.message'),
                    iconColor: Color.ALEXA_BLUE,
                    continueDef: def,
                    bottomText: _('alexa.onboarding.info.title') + '<br>' + _('alexa.onboarding.info.message1') + '<br>' + _('alexa.onboarding.info.message2')
                };

            if (isCurrentUserAdmin) {
                details.continueBtnText = _('infoscreen.start');
            } else {
                details.hasErrorText = true;
                details.bottomText = _('alexa.onboarding.no-permission');
            }

            def.promise.done(function (viaButton) {
                this.dismiss();
            }.bind(this));
            return this.showState(ScreenState.AlexaOnboardingScreen, null, details, AnimationType.NONE);
        }

    };
});
