'use strict';

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.BUTTON_CELL_CUSTOM = "CustomButtonCell";

    class CustomButtonCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(title, iconSrc) {
                return $((iconSrc ? ImageBox.getResourceImageWithClasses(iconSrc, "custom-button-cell__icon") : "") + '<div class="custom-button-cell__title text-overflow-ellipsis">' + title + '</div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("custom-button-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.contentPlaceholder.append(CustomButtonCell.Template.getTemplate(this.content && this.content.title, this.content && this.content.iconSrc));
        }

        clickableAllowed() {
            return true;
        }

    }

    GUI.TableViewV2.Cells.CustomButtonCell = CustomButtonCell;
    return GUI;
}(window.GUI || {});
