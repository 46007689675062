'use strict';
/**
 * Expects a device or extension object, then shows it's infos. Used in the deviceLearning screens.
 */

define(['AboutScreenBase'], function (AboutScreenBase) {
    return class AboutDeviceScreen extends AboutScreenBase {
        constructor(device) {
            super($('<div />'));
            this.device = device;
        }

        viewWillAppear() {
            return Q(super.viewWillAppear(...arguments) || true).then(function () {
                this.setTitle(this._getDeviceTitle());

                if (this.device.serialNr && !this.device.internal) {
                    this.setSubtitle(this.device.serialNr.formatStringToSerialNumber(this.device.type));
                }

                if (!this.device.imageUrl) {
                    this.device.imageUrl = ActiveMSComponent.getImageUrlWithDeviceType(this.device.type);
                }

                this.setIcon(this.device.imageUrl);
                this.setIconColor(Color.GREEN);
            }.bind(this));
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        reloadTable() {
            this.tableContent = [];
            !this.device.internal && this.tableContent.push(this._getGeneralInfoSection());
            this.tableContent.push(this._getDeviceStateSection());
            this.tableContent.push(this._getInstallationPlaceSection());
            return super.reloadTable(...arguments);
        }

        _createCellContent(title, detail, color) {
            var rowObject = {
                type: GUI.TableViewV2.CellType.Special.COMFORT_STATE,
                content: {
                    title: title,
                    stateText: detail,
                    stateTextColor: color
                }
            };

            this._addCopyActionForDisclosureText(rowObject, title, detail);

            return rowObject;
        }

        _getGeneralInfoSection() {
            var section = {
                rows: []
            };
            section.rows.push(this._createCellContent(_('device-learning.designation'), this.device.name || ""));
            section.rows.push(this._createCellContent(_('description'), this.device.description || ""));
            section.rows.push(this._createCellContent(_("miniserver.serial-number"), this.device.serialNr ? this.device.serialNr.formatStringToSerialNumber(this.device.type) : ""));
            return section;
        }

        _getDeviceStateSection() {
            var section = {
                    rows: []
                },
                stateText = _("unknown"),
                color = null;

            if (this.device.serialNr && this.device.deviceState) {
                stateText = this.device.deviceState ? _("online") : _("offline");
                color = this.device.deviceState ? window.Styles.colors.green : window.Styles.colors.red;
            } else if (!this.device.serialNr) {
                stateText = _("device-learning.unpaired");
                color = window.Styles.colors.red;
            }

            section.rows.push(this._createCellContent(_('status'), stateText, color));
            return section;
        }

        _getInstallationPlaceSection() {
            var section = {
                rows: []
            };

            if (this.device.switchboard) {
                section.rows.push(this._createCellContent(_("device-learning.switchboard"), this.device.switchboard));

                if (this.device.row && this.device.column) {
                    section.headerTitle = _("battery-monitor.device.assembly-place");
                    section.rows.push(this._createCellContent(_("device-learning.row"), this.device.row));
                    section.rows.push(this._createCellContent(_("device-learning.position"), this.device.column));
                }
            } else if (this.device.place) {
                section.rows.push(this._createCellContent(_('battery-monitor.device.assembly-place'), this.device.place));
            }

            return section;
        }

        _getDeviceTitle() {
            var title;

            if (this.device.deviceType) {
                title = this.device.deviceType;
            } else if (this.device.name) {
                title = this.device.name;

                if (this.device.internal) {
                    title += SEPARATOR_SYMBOL + this.device.msName;
                }
            }

            return title;
        }

    };
});
