'use strict';
/*
 time
 title
 subtitle
 */

GUI.TableViewV2.CellType.History = "HistoryCell";

class HistoryCell extends GUI.TableViewV2.Cells.BaseCell {
    //region Static
    static Template = function () {
        var getTemplate = function getTemplate(time, title, subtitle, steps) {
            return $('<div class="content__time">' + time + '</div>' + '<div class="content_texts">' + '   <div class="texts__steps">' + (steps || "") + '</div>' + '   <div class="texts__title">' + (title || "") + '</div>' + '   <div class="texts__subtitle">' + (subtitle || "") + '</div>' + '</div>');
        };

        return {
            getTemplate: getTemplate
        };
    }(); //endregion Static

    constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
        super(...arguments);
        this.element.addClass("history-cell");
    }

    viewDidLoad() {
        super.viewDidLoad();
        this.contentPlaceholder.append(HistoryCell.Template.getTemplate(this.content.time, this.content.title, this.content.subtitle, this.content.steps));
    }

}

GUI.TableViewV2.Cells.HistoryCell = HistoryCell;
