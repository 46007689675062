'use strict';

define(['TargetValueSelectorScreenBase', 'SaunaControlEnums'], function (TargetValueSelectorScreenBase, SaunaControlEnums) {
    return class TargetTemperatureSelectorScreen extends TargetValueSelectorScreenBase {
        constructor(control) {
            super(...arguments);
        }

        /**
         * These views always have a custom title.
         */
        getTitle() {
            return this.hasBenchTemp ? _("controls.sauna.desired.bench-temperature") : _("controls.sauna.desired.temperature");
        }

        /**
         * Such views only need to have one state ID.
         */
        getStateID() {
            return "tempTargetVal";
        }

        /**
         * As we display either the bench or the room temperature, we need to know if a bench sensor is connected.
         * @return {*}
         */
        getStateIDs() {
            var ids = super.getStateIDs(...arguments);
            ids.push("hasTempBench");
            return ids;
        }

        receivedStates(states) {
            super.receivedStates(...arguments);
            this.hasBenchTemp = states.hasBenchTemp;
            this.tempTargetVal = states.tempTargetVal;
            this.setTitle(this.getTitle());
        }

        viewWillAppear() {
            return Q(super.viewWillAppear(...arguments).then(res => {
                this.tempTargetVal && this.sliderActionView.setPosition(this.tempTargetVal);
            }));
        }

        /**
         * To send the value to the Miniserver.
         * @param value
         */
        formatCommand(value) {
            return Commands.format(Commands.SAUNA.TEMP, value);
        }

        /**
         * To display the current value.
         */
        formatValue(value) {
            return lxFormat("%d°", value);
        }

        /**
         * This function returns the config of the slider, it is crucial to implement it!
         * @returns {{min, max, step, antiGhostTimer: boolean}}
         */
        getSliderConfig() {
            var cfg = super.getSliderConfig(...arguments);
            cfg.min = this.currentMode && this.currentMode.minTemp ? SandboxComponent.getTemperatureForUI(this.currentMode.minTemp) : SandboxComponent.getTemperatureForUI(SaunaControlEnums.SaunaModes.SAUNA_MANUAL.minTemp);
            cfg.max = this.currentMode && this.currentMode.maxTemp ? SandboxComponent.getTemperatureForUI(this.currentMode.maxTemp) : SandboxComponent.getTemperatureForUI(SaunaControlEnums.SaunaModes.SAUNA_MANUAL.maxTemp);
            cfg.step = 1;
            return cfg;
        }

        /**
         * Returns an array of mode ids that are okay for this view. If the current mode is not contained at the
         * time when a value is sent, the mode will be changed to manual.
         */
        getAcceptibleModes() {
            var modes = super.getAcceptibleModes(...arguments);
            modes.push(SaunaControlEnums.SaunaModes.FINNISH_MANUAL.nr); // finish manual is also okay!

            return modes;
        }

        /**
         * Used to determine which mode to switch to. Usually the manual mode is the goal. But it finnish manual
         * might also be an option.
         * @private
         */
        getManualTargetMode() {
            var target;

            if (this.control.details.hasVaporizer && this._isFinnishMode()) {
                target = SaunaControlEnums.SaunaModes.FINNISH_MANUAL.nr;
            } else {
                target = super.getManualTargetMode(...arguments);
            }

            return target;
        }

        /**
         * Will return true if the mode is known and either finnish automatic or finnish manual.
         * @return {*|boolean}
         * @private
         */
        _isFinnishMode() {
            return this.currentMode && (this.currentMode.nr === SaunaControlEnums.SaunaModes.FINNISH_MANUAL.nr || this.currentMode.nr === SaunaControlEnums.SaunaModes.FINNISH.nr);
        }

    };
});
