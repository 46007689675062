import {BUFFER_STATE} from "./solarPumpControlEnums";
import globalStyles from "GlobalStyles";

export default class SolarPumpControlStatesSrc {
    static getStateSections(control, states) {
        let sections = [];

        let section = {
            rows: control.details.buffers.map((buffer, idx) => {
                return this._getBufferCell(buffer, states["bufferTemp" + idx], states["bufferState" + idx]);
            })
        }

        sections.push(section)

        return sections
    }

    /**
     * Creates a cell for the given buffer with a title (buffer name), disclosureText (temperature)
     * and rightIcon (bufferState)
     * @param buffer
     * @param bufferTemp
     * @param bufferState
     * @return {{type: string, content: {title: *, disclosureText: string, rightIconSrc: *, rightIconColor: *}}}
     * @private
     */
    static _getBufferCell(buffer, bufferTemp, bufferState) {
        return {
            type: GUI.TableViewV2.CellType.GENERAL,
            content: {
                title: buffer.name,
                disclosureText: lxFormat("%d°", SandboxComponent.getTemperatureForUI(bufferTemp)),
                rightIconSrc: this._getCellRightIcon(bufferState),
                rightIconColor: this._getCellRightIconColor(bufferState)
            }
        };
    }

    static _getCellRightIcon(bufferState) {
        var iconSrc;

        switch (bufferState) {
            case BUFFER_STATE.HEATING_UP:
                iconSrc = Icon.SolarPump.ARROW_RIGHT_UP_CIRCLE;
                break;

            case BUFFER_STATE.OK_TEMP_REACHED:
                iconSrc = Icon.SolarPump.CHECKMARK_CIRCLE;
                break;

            default:
                iconSrc = Icon.SolarPump.CIRCLE;
                break;
        }

        return iconSrc;
    }

    static _getCellRightIconColor(bufferState) {
        var iconColor;

        switch (bufferState) {
            case BUFFER_STATE.HEATING_UP:
                iconColor = globalStyles.colors.orange;
                break;

            case BUFFER_STATE.OK_TEMP_REACHED:
                iconColor = globalStyles.colors.stateActive;
                break;

            default:
                iconColor = Color.COMFORT_UI_CELL_BACKGROUND;
                break;
        }

        return iconColor;
    }
}