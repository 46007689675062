'use strict';

define(["ControlInfoView", "DaytimerControlInfoView", "IRoomControlEnums"], function (ControlInfoView, DaytimerControlInfoView, IRoomControlEnums) {
    var MIN_ENTRY_HEIGHT = 7;
    return class IRoomControlInfoView extends DaytimerControlInfoView {
        //region Static
        static Template = function () {
            var getCurrentColorKnob = function getCurrentColorKnob() {
                return $('<div class="indicator__knob"></div>');
            };

            return {
                getCurrentColorKnob: getCurrentColorKnob
            };
        }(); //endregion Static

        constructor() {
            super(...arguments);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.elements.timeLineIndicator.append(IRoomControlInfoView.Template.getCurrentColorKnob());
            this.elements.indicatorKnob = this.elements.timeLineIndicator.find(".indicator__knob");
        }

        receivedStates(states) {
            super.receivedStates(...arguments);

            this._updateKnob(states);
        }

        getTopLabelText(states) {
            var text = null;

            switch (states.preparationPhase) {
                case IRoomControlEnums.PreparationPhase.HEATING_UP:
                    text = _("controls.irc.prep.heating");
                    break;

                case IRoomControlEnums.PreparationPhase.COOLING_DOWN:
                    text = _("controls.irc.prep.cooling");
                    break;
            }

            return text;
        }

        getBottomLabelText(states) {
            return states.activeOperatingModeName;
        }

        /**
         * Returns an array containing the state ids this view is interested in. statesReceived will only be called
         * if one of the states specified in this array changes.
         * By default this method returns false, which means this view is interested in all states of the state cntr.
         * @return {Array|boolean}
         */
        getStateIDs() {
            return null;
        }

        getTimeLineEntries(states) {
            return states.timeLineEntries;
        }

        adoptEntryElement(elem, entry, states) {
            // adopt color
            var hexColor = this.control.getColorOfIRCTempMode(entry.tempMode);
            elem.css('background-color', hexColor); // adopt height

            var entryHeight = this._getHeightForTemp(states.activeTemperatures[entry.tempMode], states);

            elem.css('height', entryHeight + 'px');
        }

        showCalendarEntries() {
            var isHeating = this.control.isHeating(this.states.controllerMode),
                uuid = this.control.getDaytimerUuidFromIRoomControl(isHeating),
                daytimerControl = ActiveMSComponent.getStructureManager().getControlByUUID(uuid);
            this.ViewController.showState(IRoomControlEnums.ScreenState.CALENDAR, null, {
                control: daytimerControl
            });
        }

        _updateKnob(states) {
            var height = this._getHeightForTemp(states.tempActual, states),
                knobSize = this.elements.indicatorKnob[0].clientHeight,
                pos = height - knobSize / 2;

            this.elements.indicatorKnob.css('bottom', pos + "px");
            var hexColor = this.control.getColorOfIRCTempMode(states.activeTempMode);
            this.elements.indicatorKnob.css('background-color', hexColor);
        }

        _getHeightForTemp(temp, states) {
            // "extend" the min/max range. This will help drawing more nicely as the area isn't too narrow.
            var max = states.maxTemp,
                min = states.minTemp,
                addRange = (max - min) * 0.2;
            min -= addRange;
            max += addRange; // compute new height

            var tempDelta = max - min,
                minHeight = MIN_ENTRY_HEIGHT,
                timeLineHeight = this._getClientHeight() - minHeight,
                heightPercent = (temp - min) / tempDelta,
                height = timeLineHeight * heightPercent + MIN_ENTRY_HEIGHT; // ensure it's within the ranges.

            height = Math.max(0, height);
            height = Math.min(this._getClientHeight(), height);
            return height;
        }

        _getClientHeight() {
            return this.elements.timeLineEntries[0].clientHeight;
        }

    };
});
