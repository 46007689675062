'use strict';

define("Wallbox2Control",
    [
        "MeterV2Control",
        "./wallbox2CardStateIcon.jsx",
        "./content/wbModeSettings.jsx",
        "./content/wbHistory.jsx",
        "./content/wallbox2ControlContent.jsx",
        "./wallbox2ControlEnums.js",
        "LxComponents"
    ],
    function (
        MeterV2Control,
        WbCardStateIcon,
        WbModeSettings,
        WbHistory,
        CtrlContent,
        Wb2Enums, {
            LxReactControlStatsScreen
        }
    ) {

        const MANUAL_MODE_ID = 99;

        return class Wallbox2Control extends MeterV2Control {
            constructor() {
                super(...arguments);

                //
                this._keepCurrentModeObj = {
                    id: 0,
                    name: _("controls.wallbox2.keep-current-mode"),
                    value: 0,
                    setByLogic: false
                }
            }

            // region getters

            get modes() {
                let states = this.getStates(),
                    result;
                if (states && states.modes) {
                    result = this.details.modes.map((mode) => {
                        let stateMode = this._getModeFromStates(mode.id, states);
                        let combined = Object.assign({}, mode);
                        combined = Object.assign(combined, stateMode);
                        return combined;
                    });
                } else {
                    result = this.details.modes;
                }
                return result;
            }

            get unplugModes() {
                return [
                    this._keepCurrentModeObj,
                    ...this.modes.filter((mode) => {
                        return !!mode.name;
                    })
                ]
            }

            get manualModeId() {
                return MANUAL_MODE_ID;
            }

            get minChargingPower() {
                return this.details.min;
            }

            get maxChargingPower() {
                return this.details.max;
            }

            get connectedInputsKnown() {
                return this.details && this.details.hasOwnProperty("connectedInputs");
            }

            get chargingAllowedSetByLogic() {
                if (this.connectedInputsKnown) {
                    return hasBit(this.details.connectedInputs, Wb2Enums.InputFlags.ChargingAllowed);
                } else {
                    return true;
                }
            }

            get carConnectedInfoAvailable() {
                if (this.connectedInputsKnown) {
                    return hasBit(this.details.connectedInputs, Wb2Enums.InputFlags.CarConnected);
                } else {
                    return true;
                }
            }

            get getMeterIsConnected() {
                if (this.connectedInputsKnown) {
                    return hasBit(this.details.connectedInputs, Wb2Enums.InputFlags.MeterConnected);
                } else {
                    return true;
                }
            }

            get getChargeActiveConnected() {
                if (this.connectedInputsKnown) {
                    return hasBit(this.details.connectedInputs, Wb2Enums.InputFlags.ChargeActive);
                } else {
                    return true;
                }
            }

            get getCpIsConnected() {
                if (this.connectedInputsKnown) {
                    return hasBit(this.details.connectedInputs, Wb2Enums.InputFlags.CurrentPowerConnected);
                } else {
                    return true;
                }
            }

            // wallbox energy manager support

            get isManaged() {
                return this.details.hasOwnProperty("manager") && !!this.details.manager;
            }

            get managerUuid() {
                return this.isManaged ? this.details.manager : null;
            }

            get manager() {
                if (!this.isManaged) {
                    return null;
                }
                let mngrUid = (typeof this.details.manager === "string") ? this.details.manager : null;
                return mngrUid ? ActiveMSComponent.getStructureManager().getControlByUUID(mngrUid) : null;
            }

            get managerName() {
                let mngr = this.manager;
                if (mngr) {
                    return mngr.name;
                } else {
                    return _("search.controltype.wallboxmanager");
                }
            }

            // wallbox OCPP support

            get managedByOCPP() {
                return this.details.hasOwnProperty("ocpp") && !!this.details.ocpp;
            }

            get ocppPluginName() {
                if (!this.managedByOCPP) {
                    return null;
                }
                if (this.details.ocpp.hasOwnProperty("name")) {
                    return this.details.ocpp.name;
                } else {
                    return _("controls.wallbox2.ext-approval-service-unknown")
                }
            }

            shouldHidePrice(price) {
                return this.managedByOCPP && Math.round(price) === -1337;
            }


            get currentPower() {
                let states = this.getStates();
                if (states && states.session) {
                    return states.session.power || 0;
                } else {
                    return 0;
                }
            }

            get limitedBy() {
                let states = this.getStates();
                if (states && states.limitedBy) {
                    return states.limitedBy;
                } else {
                    return 0;
                }
            }

            get isPrioCharging() {
                let states = this.getStates();
                return true;
                if (states) {
                    return states.priority;
                } else {
                    return false;
                }
            }

            get currentLimit() {
                let states = this.getStates();
                if (states) {
                    return states.limit;
                } else {
                    return 0;
                }
            }

            // endregion

            // region general control methods

            getLegacyReactControlContent() {
                return CtrlContent.default;
            }

            getReactScreens() {
                let screens = [
                    ...super.getReactScreens(...arguments),
                    WbModeSettings.default,
                    WbHistory.default
                ];
                if (this.supportsStatisticV2) {
                    screens.push(LxReactControlStatsScreen)
                }
                return screens;
            }

            // endregion

            // region commands

            toggleCharging() {
                let states = this.getStates();
                if (states) {
                    this.setChargingEnabled(!states.chargingEnabled);
                }
            }

            setChargingEnabled(isEnabled) {
                return isEnabled ? this.enableCharging() : this.disableCharging();
            }

            enableCharging() {
                return this.sendCommand(Commands.WALLBOX2.ENABLE);
            }

            disableCharging() {
                return this.sendCommand(Commands.WALLBOX2.DISABLE);
            }

            getLimitForManualMode(manualValue=undefined) {
                const limit = manualValue ?? this.getStates()?.limit;
                if (limit > this.maxChargingPower) {
                    return this.details.max;
                } else if (limit < this.minChargingPower) {
                    return this.details.min;
                } else {
                    return limit;
                }
            }

            changeToMode(idx) {
                if (parseInt(idx) === MANUAL_MODE_ID) {
                    this.sendCommand(Commands.format(Commands.WALLBOX2.MANUAL_LIMIT, this.getLimitForManualMode()));
                }

                return this.sendCommand(Commands.format(Commands.WALLBOX2.SET_MODE, idx));
            }

            setUnplugMode(idx) {
                return this.sendCommand(Commands.format(Commands.WALLBOX2.SET_UNPLUG_MODE, idx));
            }

            updateMode(id, name, value) {
                // featurecheck here
                if (Feature.WALLBOX2_UPDATED_BLOCK && id === MANUAL_MODE_ID) {
                    return this.sendCommand(Commands.format(Commands.WALLBOX2.MANUAL_LIMIT, value));
                } else {
                    return this.sendCommand(Commands.format(Commands.WALLBOX2.UPDATE_MODE, id, value, name));
                }
            }

            // endregion

            getStepSize(format) {
                const formatNum = format.match(/^%\.(\d*)/)[1]

                let stepSize = 0.1
                if (!(formatNum === 1)) {
                    stepSize = `0.${"0".repeat(formatNum - 1)}1`
                }

                return Number(stepSize)
            }

            // region  helper

            getTrackerControl() {
                var trackerCtrl = Object.values(this.subControls)[0];
                trackerCtrl.name = _("history");
                return trackerCtrl;
            }

            getLimitModeObj(id, filtered = true) {
                let mode;
                (filtered ? this.unplugModes : this.modes).some((modeObj) => {
                    if (id === modeObj.id) {
                        mode = modeObj;
                        return true;
                    }
                });
                return mode;
            }

            // endregion

            _getModeFromStates(id, states) {
                return states.modes ? states.modes[id] : {};
            }
        };
    });
