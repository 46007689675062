import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Circle(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path
                fillRule="evenodd"
                d="M12 21a9 9 0 100-18 9 9 0 000 18zm0 2c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11z"
                clipRule="evenodd"
            />
        </Svg>
    )
}

export default Circle
