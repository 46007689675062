'use strict';

window.Controls = function (Controls) {
    Controls.AudioZoneControl = Controls.AudioZoneControl || {};
    return Controls;
}(window.Controls || {});

define([], function () {
    Controls.AudioZoneControl.Enums = {
        ScreenState: {
            DETAILS: 'Controls.AudioZoneControl.AudioZoneControlDetails',
            DETAILS_HD: 'Controls.AudioZoneControl.AudioZoneControlDetailsHD',
            POPUP: 'Controls.AudioZoneControl.AudioZoneContextMenu',
            HOME_SCREEN: 'AudioZoneControlContentHomeScreen',
            QUEUE: 'Controls.AudioZoneControl.AudioZoneControlQueue',
            QUEUE_OLD: 'Controls.AudioZoneControl.AudioZoneControlQueueOld',
            QUEUE_EDIT: 'Controls.AudioZoneControl.AudioZoneControlQueueEdit',
            QUEUE_DETAIL: 'Controls.AudioZoneControl.AudioZoneControlQueueDetailOverlay',
            QUEUE_SCREEN: 'Controls.AudioZoneControl.AudioZoneControlQueueScreen',
            MEDIA: 'Controls.AudioZoneControl.AudioZoneControlMedia',
            ITEM_ADD_SRC: 'Controls.AudioZoneControl.AddMediaItemSourceScreen',
            RADIOS: 'Controls.AudioZoneControl.AudioZoneControlRootRadioList',
            LINE_IN: 'Controls.AudioZoneControl.AudioZoneLineInList',
            MEDIA_EDIT: 'Controls.AudioZoneControl.AudioZoneControlMediaEdit',
            MEDIA_DETAIL: 'Controls.AudioZoneControl.AudioZoneControlMediaDetailOverlay',
            PLAYER: 'Controls.AudioZoneControl.AudioZoneControlPlayer',
            SYNC: 'Controls.AudioZoneControl.AudioZoneControlSync',
            GROUP_OVERVIEW: 'Controls.AudioZoneControl.ZoneGroupOverviewScreen',
            GROUP: 'Controls.AudioZoneControl.ZoneGroupScreen',
            GROUP_CREATE_EDIT: 'Controls.AudioZoneControl.ZoneGroupCreateEditScreen',
            SETTINGS: 'Controls.AudioZoneControl.AudioZoneControlSettings',
            ABOUT_SERVER: 'Controls.AudioZoneControl.AudioZoneControlAboutMediaServer',
            VOICE_RECORDER: 'Controls.AudioZoneControl.VoiceRecorder',
            GLOBAL_SEARCH_SCREEN: 'Controls.AudioZoneControl.GlobalSearchScreen',
            LEGACY_SEARCH_SCREEN: 'Controls.AudioZoneControl.LegacySearchScreen',
            MASTER_VOLUME_SLIDER_SCREEN: 'Controls.AudioZoneControl.MasterVolumeSliderScreen',
            MEDIA_BROWSER_BASE_SCREEN: 'Controls.AudioZoneControl.MediaBrowserBase',
            SEARCH: 'Controls.AudioZoneControl.AudioZoneControlSearch',
            SEARCH_DETAIL: 'Controls.AudioZoneControl.AudioZoneControlSearchDetailOverlay',
            PLAYLIST_SAVE: 'Controls.AudioZoneControl.AudioZoneControlPlaylistSave',
            PLAYLIST_ROOT: 'Controls.AudioZoneControl.RootPlaylistView',
            PLAYLIST: 'Controls.AudioZoneControl.PlaylistView',
            PLAYLIST_CONTENT: 'Controls.AudioZoneControl.PlaylistContentView',
            PLAYLIST_DETAIL: 'Controls.AudioZoneControl.PlaylistDetailOverlay',
            PLAYLIST_SELECTOR: 'Controls.AudioZoneControl.PlaylistSelectorScreen',
            ZONE_FAVORITE_SAVE: 'Controls.AudioZoneControl.ZoneFavoriteSaveScreen',
            ZONE_FAVORITE_SAVE_IN_ZONES: 'Controls.AudioZoneControl.ZoneSelectorScreen',
            ZONE_FAVORITE_LIST: 'Controls.AudioZoneControl.ZoneFavoriteMediaList',
            ZONE_FAVORITE_EDIT: 'Controls.AudioZoneControl.ZoneFavoriteEditScreen',
            FAVORITE_LIST: 'Controls.AudioZoneControl.FavoriteMediaList',
            FAVORITE_EDIT: 'Controls.AudioZoneControl.AudioZoneControlFavoriteEdit',
            GLOBAL_FAVORITE_EDIT: 'Controls.AudioZoneControl.AudioZoneControlGlobalFavoriteEdit',
            AIRPLAY: 'Controls.AudioZoneControl.AudioZoneSettingsAirplay',
            SETTINGS_SERVICES_SPOTIFY_MANAGEMENT: 'AudioZoneSettingsSpotifyManagementScreen',
            SETTINGS_SERVICES_RADIO: 'Controls.AudioZoneControl.AudioZoneSettingsServiceRadioSettings',
            SETTINGS_SERVICES_ADD_SPOTIFY: 'Controls.AudioZoneControl.AudioZoneSettingsServiceLoginScreen',
            SETTINGS_SERVICE_LOGIN: 'Controls.AudioZoneControl.AudioZoneSettingsServiceLoginScreen',
            SETTINGS_SERVICES_WEB_LOGIN: 'Controls.AudioZoneControl.AudioZoneSettingsServiceWebLoginScreen',
            SETTINGS_SERVICES_AVAILABLE_LIST: 'Controls.AudioZoneControl.AudioZoneSettingsServicesAvailableList',
            SETTINGS_INPUT_LIST: 'Controls.AudioZoneControl.AudioZoneControlSettingsLineInList',
            SETTINGS_INPUT: 'Controls.AudioZoneControl.AudioZoneControlSettingsLineIn',
            SETTINGS_SHORTCUT_EDIT: 'AudioZoneControlSettingsShortcutEditScreen',
            SETTINGS_DISPLAY_EDIT: 'Controls.AudioZoneControl.AudioZoneControlAppearanceEditScreen',
            SETTINGS_ZONES_LIST: 'Controls.AudioZoneControl.AudioZoneControlSettingsZoneList',
            SETTINGS_LIBRARY: 'Controls.AudioZoneControl.AudioZoneControlSettingsLibrary',
            SETTINGS_LIBRARY_ADD_OR_EDIT: 'Controls.AudioZoneControl.AudioZoneControlSettingsLibraryAddOrEdit',
            SETTINGS_LIBRARY_OVERVIEW: 'Controls.AudioZoneControl.AudioZoneControlSettingsLibraryOverview',
            SETTINGS_EQ: 'Controls.AudioZoneControl.AudioZoneControlZoneEQSettings',
            SETTINGS_VOLUME: 'Controls.AudioZoneControl.AudioZoneControlZoneVolumeSettings',
            SETTINGS_AUDIO_DELAY: 'Controls.AudioZoneControl.AudioZoneControlZoneAudioDelaySettings',
            CONTEXT_MENU_BASE: "Controls.AudioZoneControl.MediaContextMenuBase",
            ADD_MEDIA_VC_BASE: "Controls.AudioZoneControl.AddMediaViewControllerBase",
            ADD_MEDIA_VC_PLAYLIST: "Controls.AudioZoneControl.AddMediaViewControllerPlaylist",
            ADD_MEDIA_VC_PLAYLIST_PICKER: "Controls.AudioZoneControl.AddMediaViewControllerPlaylistPicker",
            ADD_MEDIA_VC_PLAYLIST_SPOTIFY: "Controls.AudioZoneControl.AddMediaViewControllerPlaylistSpotify",
            ADD_MEDIA_VC_FAVS: "Controls.AudioZoneControl.AddMediaViewControllerZoneFavorite",
            ADD_MEDIA: "Controls.AudioZoneControl.AddMediaScreen",
            ADD_ZONE_FAV: "Controls.AudioZoneControl.AddZoneFavoritesScreen",
            EDIT_MEDIA: "Controls.AudioZoneControl.EditMediaScreen",
            SPOTIFY_LIBRARY: "Controls.AudioZoneControl.SpotifyLibraryScreen"
        }
    };
    return Controls.AudioZoneControl.Enums;
});
