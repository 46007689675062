import {createContext, useMemo} from "react";
import useTrustStructure from "./useTrustStructure";

export const TrustContext = createContext({
    version: -1337,
    user: null,
    items: []
})

function TrustContextProvider (props) {
    const structure = useTrustStructure();

    const contextValue = useMemo(() => {
        return {
            ...structure
        }
    }, [structure.version])

    return <TrustContext.Provider value={contextValue}>
        {props.children}
    </TrustContext.Provider>
}

export default TrustContextProvider;
