'use strict';

define([], function () {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var HomeKitEnum = ActiveMSComponent.HomeKitEnum;
    return class HomeKitSetupViewController extends GUI.ModalViewController {
        constructor(details) {
            super();
            ActiveMSComponent.resetHomeKitSetupStage();
            this.platform = PlatformComponent.getPlatformInfoObj().platform;
        }

        getAnimation() {
            return AnimationType.NONE;
        }

        getShouldBlockScreenSaver() {
            return true;
        }

        getPermissions() {
            var isAdmin = ActiveMSComponent.isAdminUser();

            if (isAdmin) {
                return [{
                    permission: MsPermission.ADMIN,
                    revoke: true
                }, {
                    permission: MsPermission.CONFIG,
                    revoke: true
                }, {
                    permission: MsPermission.EXPERT_MODE,
                    revoke: true
                }, {
                    permission: MsPermission.MANAGE_MS_PLUGINS,
                    revoke: true
                }, {
                    permission: MsPermission.USER_MANAGEMENT,
                    revoke: true
                }, {
                    permission: MsPermission.CHANGE_PWD,
                    revoke: true
                }];
            } else {
                return super.getPermissions(...arguments);
            }
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                if (!Feature.HOME_KIT) {
                    this._navigateToHomeKitOnboarding(true);

                    return Q.resolve();
                }

                if (!ActiveMSComponent.isAdminUser()) {
                    // no administrative rights --> no homekit, will be shown in the onBoardingScreen
                    return this._navigateToHomeKitOnboarding();
                }

                return ActiveMSComponent.ensureHomeKitToken().then(function (res) {
                    return ActiveMSComponent.isHomeKitAddonCreated().then(function (response) {
                        this._version = response.version;
                        this._apiUserUuid = response.apiUserUuid;

                        if (response.created) {
                            if (ActiveMSComponent.getCurrentHomeKitUserType() !== HomeKit.VIEWER.OWNER) {
                                return this._navigateToHomeKitSettings();
                            } else {
                                return this._ensureHomeKitStatusActive().then(function (status) {
                                    this._version = status.pluginVersion;

                                    if (status && status.isPaired) {
                                        // Navigate to already paired settings screen where it is possible
                                        // to delete the bridge and go to the accessory selection screen
                                        return this._navigateToHomeKitSettings();
                                    } else {
                                        // Do not activate the HomeKit plugin here! Just navigate to the onboarding screen
                                        return this._navigateToHomeKitOnboarding();
                                    }
                                }.bind(this));
                            }
                        } else {
                            return this._navigateToHomeKitOnboarding(false, ActiveMSComponent.homeKitAddonUpdateRequired(this._version));
                        }
                    }.bind(this), function (error) {
                        if (error && error.cause === HomeKitEnum.Error.GET_ADDON_LIST_FAILED) {
                            this.handleError(error, _("home-kit.error.failed-to-get-addons-info"));
                            return Q.reject();
                        } else if (error && error.LL && error.LL.Code !== "401" && error.LL.Code !== "403") {
                            this.handleError(error, _("home-kit.error.failed-to-get-addons-info"));
                            return Q.reject();
                        } else {
                            console.error(this.name, "viewDidLoad - isHomeKitAddonCreated, unhandled error occurred: " + JSON.stringify(error));

                            this._navigateToHomeKitOnboarding();
                        }
                    }.bind(this));
                }.bind(this), function (err) {
                    if (err === ActiveMSComponent.HomeKitEnum.TokenState.REBOOT_REQUIRED) {
                        NavigationComp.showPopup({
                            title: _("home-kit.error.token-reboot-required.title"),
                            message: _("home-kit.error.token-reboot-required.message"),
                            buttonOk: _("miniserver.trigger-reboot"),
                            buttonCancel: _("cancel")
                        }).then(function () {
                            SandboxComponent.sendWithPermission(Commands.REBOOT_MINISERVER, MsPermission.SYS_WS);
                        }.bind(this));
                        return Q.reject(); // avoids showing any homeKit screen if no token is ready.
                    }

                    this._navigateToHomeKitOnboarding(false, false, true);

                    return Q.resolve();
                }.bind(this));
            }.bind(this));
        }

        /**
         * Called when the viewController becomes active to ensure the addon is active.
         * @returns {*}
         * @private
         */
        _ensureHomeKitStatusActive() {
            return ActiveMSComponent.getHomeKitAddonStatus().then(function (status) {
                return status;
            }.bind(this), function (error) {
                this.handleError(error, _("home-kit.error.start-homekit"));
                return Q.reject();
            }.bind(this));
        }

        _navigateBackDueToCanceledPermissionElevation() {
            this.dismiss();
        }

        viewWillDisappear() {
            ActiveMSComponent.stopHomeKitPolling();
            return super.viewWillDisappear(...arguments);
        }

        viewDidDisappear(viewRemainsVisible) {
            Debug.HomeKit && console.log(this.viewId, "viewDidDisappear: viewRemainsVisible=" + !!viewRemainsVisible);
            return super.viewDidDisappear(...arguments).then(function () {
                if (!viewRemainsVisible && !this._preventReset) {
                    !this._preventPopup && NavigationComp.showPopup({
                        title: _("home-kit.abort.message"),
                        buttonOk: true,
                        buttonCancel: false
                    }); // no need to wait for popup, it can't be cancelled anyway!

                    console.warn(this.viewId, "viewDidDisappear - about to reset HomeKit!");
                    return ActiveMSComponent.resetHomeKit().then(function () {
                        Debug.HomeKit && console.log(this.viewId, "viewDidDisappear - done, HomeKit was reset!");
                    }.bind(this), function (err) {
                        console.error(this.viewId, "viewDidDisappear - failed to reset HomeKit! " + JSON.stringify(err));
                    }.bind(this));
                }
            }.bind(this));
        }

        setPreventReset(active) {
            this._preventReset = active;
        }

        destroyOnBackNavigation() {
            return true;
        }

        destroy() {
            super.destroy(...arguments);
        }

        handleError(error, title) {
            console.error(this.viewId, "handleError: " + JSON.stringify(error), getStackObj());
            ActiveMSComponent.stopHomeKitPolling();

            var errorTitle = title || _("error"),
                errorMessage,
                shouldBailOut = false,
                code,
                value;

            code = getLxResponseCode(error);

            try {
                value = getLxResponseValue(error, true);
            } catch (ex) {
            }

            switch (code) {
                case HomeKit.ResponseCode.UNKNOWN:
                    errorMessage = JSON.stringify(error);
                    break;

                case HomeKit.ResponseCode.UNAUTHORIZED:
                    // unauthorized, should not happen.
                    errorMessage = code + " Unauthorized. " + (value ? ", " + value : "");
                    break;

                case HomeKit.ResponseCode.NOT_FOUND:
                    // not found, should also not happen.
                    errorMessage = code + " HomeKit AddOn not found. " + (value ? ", " + value : "");
                    break;

                case HomeKit.ResponseCode.NOT_INSTALLED_OR_STARTED:
                    // Plugin not installed or not starting
                    errorMessage = _("home-kit.error.no-response-homekit");
                    break;

                case HomeKit.ResponseCode.RUNNING_NOT_ACTIVE:
                    // Plugin running, but did not become active
                    errorMessage = _("home-kit.error.installed-but-inactive-homekit");
                    break;

                case HomeKit.ResponseCode.FAILED_TO_SWITCH_USER:
                    errorTitle = _("home-kit.error.user-change-failed.title");
                    errorMessage = _("home-kit.error.user-change-failed.message"); // in this situation the addon needs to be removed and re-created, otherwise it won't recover.

                    shouldBailOut = true;
                    console.error(this.name, "about to bail out due to failed-to-switch-user!" + JSON.stringify(getStackObj()));
                    this._preventReset = false;
                    this._preventPopup = true;
                    break;

                case HomeKit.ResponseCode.TEMP_FORBIDDEN:
                    errorTitle = _("home-kit.error.temp-blocked.title");
                    errorMessage = _("home-kit.error.temp-blocked.message");
                    break;

                default:
                    // required e.g. for custom errors from the Ext, like isActive: false on status request
                    errorMessage = code + (value ? ", " + value : "");
                    break;
            }

            this.showErrorPopup(errorTitle, errorMessage);

            if (shouldBailOut) {
                this.dismiss();
            }
        }

        showErrorPopup(title, message) {
            NavigationComp.showPopup({
                title: title,
                message: message,
                buttonOk: _('okay'),
                buttonCancel: false,
                color: window.Styles.colors.red
            }, PopupType.GENERAL);
        }

        getPermissionPopupDetails() {
            return {
                title: _("home-kit.permission.title"),
                message: _("home-kit.permission.message"),
                buttonOk: _("home-kit.permission.open-settings"),
                buttonCancel: true
            };
        }

        showPluginVersionPopup() {
            NavigationComp.showCopyPopup(_("home-kit.version-title"), this._version);
        }

        getHomeKitUsers(force) {
            return ActiveMSComponent.getUsers(force).then(function (users) {
                return users.filter(function (user) {
                    return !user.trustMember || user.trustMember && user.trustMember === ActiveMSComponent.getMiniserverSerialNo();
                }.bind(this));
            }.bind(this));
        }

        _navigateToHomeKitOnboarding(shouldUpdate, addonUpdateRq, tokenMissing) {
            var def = Q.defer(),
                isCurrentUserAdmin = ActiveMSComponent.getCurrentUser().isAdmin,
                details = {
                    iconSrc: Icon.HOME_KIT.HOME,
                    title: _("home-kit.welcome.title"),
                    message: _("home-kit.welcome.subtitle"),
                    iconColor: Color.WHITE,
                    continueDef: def,
                    bottomIcon: Icon.HOME_KIT.WORKS,
                    rightButtonSrc: this._version && Icon.Menu.INFO,
                    rightButtonAction: this._version && this.showPluginVersionPopup.bind(this)
                },
                reachMode = CommunicationComponent.getCurrentReachMode();

            if (shouldUpdate && isCurrentUserAdmin) {
                details.bottomText = _("home-kit.welcome.update");
            } else if (addonUpdateRq && isCurrentUserAdmin) {
                details.bottomText = _("home-kit.welcome.update-addons");
            } else if (tokenMissing && isCurrentUserAdmin) {
                details.bottomText = _("home-kit.error.not-provisioned");

                if (Debug.HomeKit) {
                    details.bottomText += "<br><br>" + "Miniserver FW 12.4.3.23+ required until final release".debugify();
                }
            } else if (ActiveMSComponent.getCurrentHomeKitUserType() === HomeKit.VIEWER.USER) {
                details.bottomText = _("home-kit.welcome.user.hint");
            } else if (reachMode === ReachMode.REMOTE) {
                details.bottomText = _("home-kit.welcome.local");
            } else {
                details.continueBtnText = _("home-kit.welcome.button");
            }

            def.promise.done(function (viaButton) {
                this.dismiss();
            }.bind(this));
            return this.showState(ScreenState.HomeKitWelcome, null, details, AnimationType.NONE);
        }

        _navigateToHomeKitSettings() {
            this._comeFromSettings = true;
            return ActiveMSComponent.checkHomeKitPermission().then(function () {
                return ActiveMSComponent.getMiniserverHomeName().then(function (homeName) {
                    return this._handleNavigateToHomeKitSettings(homeName);
                }.bind(this), function (error) {
                    NavigationComp.showPopup({
                        title: _("home-kit.home-missing.title"),
                        message: _("home-kit.home-missing.message"),
                        buttonOk: _("home-kit.home-missing.unpair"),
                        buttonCancel: true,
                        color: window.Styles.colors.red
                    }).then(function (result) {
                        ActiveMSComponent.unpairHomeKit().then(function () {
                            return this._navigateToHomeKitOnboarding();
                        }.bind(this));
                    }.bind(this), function () {
                        this._preventReset = true;
                        this.dismiss();
                    }.bind(this));
                }.bind(this));
            }.bind(this), function () {
                NavigationComp.showPopup(this.getPermissionPopupDetails()).then(function () {
                    this._preventReset = true;
                    return ActiveMSComponent.openIosAppSettings().then(function () {
                        this.dismiss();
                    }.bind(this));
                }.bind(this), function (error) {
                    this._preventReset = true;
                    this.dismiss();
                }.bind(this));
            }.bind(this));
        }

        _handleNavigateToHomeKitSettings(homeName) {
            if (ActiveMSComponent.getCurrentHomeKitUserType() === HomeKit.VIEWER.OWNER) {
                return ActiveMSComponent.getHomeKitAccessoriesList(homeName).then(function (accessories) {
                    return ActiveMSComponent.getMiniserverHomeName().then(function (homeName) {
                        return this._showHkSettings({
                            accessories: accessories,
                            homeName: homeName,
                            apiUserUuid: this._apiUserUuid
                        });
                    }.bind(this));
                }.bind(this));
            } else {
                return this._showHkSettings({
                    accessories: [],
                    homeName: "",
                    apiUserUuid: this._apiUserUuid
                });
            }
        }

        _showHkSettings(settingArguments) {
            return this.showState(ScreenState.HomeKitSettings, null, settingArguments);
        }

    };
});
