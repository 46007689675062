import globalStyles from "GlobalStyles";

import React, {useState} from 'react'

import { View } from 'react-native'
import { ScrollView } from "react-native-gesture-handler";

import { LxReactText, DraggableScrollView } from "LxComponents"
import EFMCardBg1 from '../../../resources/Images/React/EFM/Cards/BG1'
import EFMCardBg2 from '../../../resources/Images/React/EFM/Cards/BG2'
import EFMCardBg3 from '../../../resources/Images/React/EFM/Cards/BG3'

const styles = {
    topText: {
        fontFamily: globalStyles.fontSettings.families.bold,
        color: 'white',
        fontSize: globalStyles.fontSettings.sizes.small,
        marginBottom: 2,
        textShadow: '1px 1px 4px rgba(0, 0, 0, 0.16)',
    },
    midTextSmall: {
        fontFamily: globalStyles.fontSettings.families.bold,
        fontSize: globalStyles.fontSettings.sizes.medium,
    },
    midText: {
        fontFamily: globalStyles.fontSettings.families.extraBold,
        color: 'white',
        fontSize: globalStyles.fontSettings.sizes.largeTitle,
        textShadow: '1px 1px 4px rgba(0, 0, 0, 0.16)',
    },
    botText: {
        fontFamily: globalStyles.fontSettings.families.regular,
        color: 'white',
        fontSize: globalStyles.fontSettings.sizes.medium,
        marginTopv: 6,
        paddingLeft: 4,
        textShadow: '1px 1px 4px rgba(0, 0, 0, 0.16)',
    },
}

function ScrollingCards({cards}) {
    const [width, setWidth] = useState(0)
    if(!Array.isArray(cards)) {return null;}
    let usedCards = [].concat(cards);
    if(cards.length === 0) {
        usedCards.push({})
    }
    return (
        <View style={{width: '100%', marginBottom: 10}} onLayout={({nativeEvent: {layout: {width}}}) => setWidth(width)}>
            <View style={{marginLeft: -globalStyles.fontSettings.sizes.regular, marginRight: -globalStyles.fontSettings.sizes.regular}}>
                <DraggableScrollView
                    horizontal={true}
                    contentContainerStyle={{width: '100%', flexDirection: 'row', paddingLeft: globalStyles.fontSettings.sizes.regular, paddingRight: globalStyles.fontSettings.sizes.regular}}
                >
                    {
                        usedCards.map((c, idx) => (
                            <View style={{
                                marginRight: 16,
                                width: width - 16,
                                borderRadius: 10, overflow: 'hidden',
                            }} key={idx}>
                                {
                                    (idx % 3 === 0) ? (
                                        <EFMCardBg1 style={{width: '100%', height: 'auto'}} />
                                    ) : (idx % 3 === 1) ? (
                                        <EFMCardBg2 style={{width: '100%', height: 'auto'}} />
                                    ) : (
                                        <EFMCardBg3 style={{width: '100%', height: 'auto'}} />
                                    )
                                }
                                <View
                                    style={{
                                        position: 'absolute',
                                        left: 0, top: 0, width: '100%', height: '100%',
                                        padding: 16,
                                        justifyContent: 'center',
                                    }}
                                >
                                    {c.top ? <LxReactText style={styles.topText}>{c.top}</LxReactText> : null}
                                    {c.middle ? <LxReactText style={styles.midText}>{c.middle}{c.middleSmall ? <LxReactText style={styles.midTextSmall}>{c.middleSmall}</LxReactText> : null}</LxReactText> : null}
                                    {c.bottom ? <LxReactText style={styles.botText}>{c.bottom}</LxReactText> : null}
                                </View>
                            </View>
                        ))
                    }
                </DraggableScrollView>
            </View>
        </View>
    )
}

export default ScrollingCards
