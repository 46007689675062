import * as React from "react"
import Svg, { Path } from "react-native-svg"

function MoreCircled(props) {
    return (
        <Svg isreact="true"
            width={22}
            height={22}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M6 12.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM12.5 11a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM16 12.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 11c0 6.075-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0s11 4.925 11 11zm-2 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
        </Svg>
    )
}

export default MoreCircled
