'use strict';

window.MediaBrowserEditablePlaylistMixin = {
    get Mixin() {
        return {

            startEditing(playlist, contentType, mediaInfo, playlistId, downloadItems) {
                // Don't start a second defer, we might already have one from the initial call, the second one comes from the error handling!
                if (!this.startEditDef) {
                    this.startEditDef = Q.defer();
                }

                this._currentPlaylist = playlist;
                this._startArgs = arguments;
                this._editModePrms = MediaServerComp.startEditing(contentType, mediaInfo, playlistId, downloadItems).then(this._editModeEnded.bind(this), this._handleEditModeError.bind(this), function () {
                    this.startEditDef.resolve();

                    this._editModeStarted.apply(this, arguments);
                }.bind(this));
                return this.startEditDef.promise;
            },

            stopEditing(playlistId) {
                MediaServerComp.stopEditing.apply(MediaServerComp, arguments);
                this._editModePrms = null;
                this.startEditDef = null;
                return Q(true);
            },

            isInEditMode() {
                return !!this._editModePrms;
            },

            _editModeEnded(cause) {
                Debug.Control.AudioZone.MediaEdit && console.log(this.name + ": _handleEditModeEnded: " + cause);

                if (cause === MediaEnum.EditEndCause.STOPPED) {
                    this._updateEditMode(false);
                } else {
                    this._handleEditModeError({
                        cause: cause
                    });
                }
            },

            _updateEditMode(editActive) {
                var result = Q(true);
                this.editModeEnded && this.editModeEnded.apply(this, arguments);
                Debug.Control.AudioZone.MediaEdit && console.log(this.name + ": updateEditMode: " + editActive);

                if (editActive && this.isInEditMode()) {
                    return result;
                }

                if (editActive) {
                    result = this.startEditing.apply(this, this._startArgs);
                } else {
                    result = this.stopEditing(this._currentPlaylist.id);
                }

                return result;
            },

            /**
             * used when the edit mode was ended or could not be started due to some sort of error.
             * @param err   error object, might contain a userfriendly "reason" or a "cause" indicating what went wrong.
             * @private
             */
            _handleEditModeError(err) {
                console.error("Error with Edit Mode: error Object: " + JSON.stringify(err));
                this.onEditModeError && this.onEditModeError.apply(this, arguments);

                var successFn = null,
                    title = _("error"),
                    message = !err ? "" : err[MediaEnum.Attr.Container.REASON],
                    okayTitle = true,
                    content;

                if (err && err.cause) {
                    switch (err.cause) {
                        case MediaEnum.EditEndCause.MODIFIED:
                            title = _("media.edit.modified.title");
                            message = _("media.edit.modified.message", {
                                name: MediaServerComp.getNameForItem(this._currentPlaylist)
                            });
                            successFn = this._updateEditMode.bind(this, true);
                            okayTitle = _("edit");
                            break;

                        case MediaEnum.EditEndCause.CONFLICT:
                            title = _("media.edit.conflict.title");
                            message = _("media.edit.conflict.message", {
                                name: MediaServerComp.getNameForItem(this._currentPlaylist)
                            });
                            break;

                        default:
                            break;
                    }

                    content = {
                        title: title,
                        message: message,
                        buttonOk: okayTitle,
                        buttonCancel: okayTitle !== true,
                        icon: Icon.INFO
                    };
                    NavigationComp.showPopup(content).then(successFn);

                    this._updateEditMode(false);
                } else {
                    console.error("Edit mode did fail but no error was provided! Don't respond");
                }
            },

            _editModeStarted() {
                this.onEditModeStarted && this.onEditModeStarted.apply(this, arguments);
                MediaServerComp.prepareForAdding().finally(this._updateEditMode.bind(this, false));
            },

            _getEditableActionsForCtxAndItem(ctx, item) {
                var actions = [],
                    actualItem,
                    wantedCtx;

                if (item === this) {
                    actualItem = item.lastSelectedItem;
                } else {
                    actualItem = item;
                }

                if (actualItem.contentType === MediaEnum.MediaContentType.SERVICE) {
                    if (this.identifier === MediaEnum.ControlContentIdentifiers.SPOTIFY) {
                        wantedCtx = this.CONTEXT.SPOTIFY;
                    }
                } else if (actualItem.contentType === MediaEnum.MediaContentType.PLAYLISTS) {
                    wantedCtx = this.CONTEXT.LMS;
                }

                if (ctx === wantedCtx) {
                    if (item === this && MediaServerComp.isFileTypePlaylist(item.lastSelectedItem.type)) {
                        actions.pushObject(this._getActionWithTmplForCtx({
                            content: {
                                title: _("media.playlist.edit-list"),
                                leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_EDIT
                            },
                            action: this._editPlaylist.bind(this, item.lastSelectedItem)
                        }, ctx));

                        if (MediaServerComp.Feature.RENAME_PLAYLIST) {
                            actions.pushObject(this._getActionWithTmplForCtx({
                                content: {
                                    title: _("media.playlist.rename"),
                                    leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_EDIT
                                },
                                action: this._renamePlaylist.bind(this, this.lastSelectedItem)
                            }, ctx));
                        }

                        actions.pushObject(this._getActionWithTmplForCtx({
                            content: {
                                title: _("media.playlist.delete"),
                                leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_DELETE
                            },
                            action: this._deletePlaylist.bind(this, this.lastSelectedItem)
                        }, ctx));
                        actions.pushObject(this._getActionWithTmplForCtx({
                            content: {
                                title: _("media.library.upload.title"),
                                leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_ADD
                            },
                            action: this._addNewMusicToItem.bind(this, item.lastSelectedItem)
                        }, ctx));
                    } else if (MediaServerComp.isFileTypePlaylist(item.type)) {
                        if (MediaServerComp.Feature.RENAME_PLAYLIST) {
                            actions.pushObject(this._getActionWithTmplForCtx({
                                content: {
                                    title: _("media.playlist.rename"),
                                    leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_EDIT
                                },
                                action: this._renamePlaylist.bind(this, item)
                            }, ctx));
                        }

                        actions.pushObject(this._getActionWithTmplForCtx({
                            content: {
                                title: _("media.playlist.delete"),
                                leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_DELETE
                            },
                            action: this._deletePlaylist.bind(this, item)
                        }, ctx));
                        actions.pushObject(this._getActionWithTmplForCtx({
                            content: {
                                title: _("media.library.upload.title"),
                                leftIconSrc: Icon.AudioZone.CONTEXT.PLAYLIST_ADD
                            },
                            action: this._addNewMusicToItem.bind(this, item)
                        }, ctx));
                    } else if (MediaServerComp.isFileTypePlayable(item.type)) {
                        actions.pushObject(this._getActionWithTmplForCtx({
                            content: {
                                title: _("remove"),
                                leftIconSrc: Icon.AudioZone.CONTEXT.DELETE
                            },
                            action: this._removeItemFromPlaylist.bind(this, item)
                        }, ctx));
                    }
                }

                return actions;
            },

            _deletePlaylist(item) {
                return MediaServerComp.deleteContainer(MediaEnum.MediaContentType.PLAYLISTS, this.getMediaInfo(item.contentType), item[MediaEnum.Event.ID]).then(function () {
                    if (item === this.lastSelectedItem) {
                        this.ViewController.navigateBack();
                    }
                }.bind(this));
            },

            _renamePlaylist(item) {
                var texts = MediaServerComp.getTitleSubtitleForItem(item),
                    playlistId = item[MediaEnum.Event.ID];
                return this._performEditActionForItemWithinPlaylistId(item, playlistId, function () {
                    return MediaServerComp.showNamePopup(_("media.playlist.rename"), _("apply"), decodeURIComponent(texts.title), decodeURIComponent(texts.title)).then(function (newName) {
                        return MediaServerComp.renameContainer(item.contentType, this.getMediaInfo(item.contentType), playlistId, newName).then(function () {
                            if (this.lastSelectedItem.id === item.id) {
                                this.lastSelectedItem.name = newName;

                                this._titleBar.setTitleBarSideTexts(this.titleBarText());

                                var sectionHeaderTitle = this.element.find(".playlist-information__title");

                                if (sectionHeaderTitle[0]) {
                                    sectionHeaderTitle.text(newName);
                                }
                            }
                        }.bind(this));
                    }.bind(this));
                }.bind(this));
            },

            _addNewMusicToItem(item) {
                var addMediaVc;

                if (item.contentType === MediaEnum.MediaContentType.SERVICE) {
                    addMediaVc = Controls.AudioZoneControl.ScreenState.ADD_MEDIA_TO_PLAYLIST_SPOTIFY;
                } else {
                    addMediaVc = Controls.AudioZoneControl.ScreenState.ADD_MEDIA_TO_PLAYLIST;
                }

                this.ViewController.showState(addMediaVc, null, {
                    delegate: this,
                    playlistId: item[MediaEnum.Event.ID],
                    control: this.control,
                    addTargetName: _("media.library.upload.title"),
                    targetMediaScreenState: Controls.AudioZoneControl.ScreenState.ADD_MEDIA,
                    blockedIds: [item.id]
                });
            },

            _editPlaylist(playlist) {
                this.ViewController.showState(Controls.AudioZoneControl.Enums.ScreenState.ADD_MEDIA_VC_PLAYLIST, null, {
                    targetMediaScreenState: Controls.AudioZoneControl.Enums.ScreenState.EDIT_MEDIA,
                    delegate: this,
                    playlistId: playlist[MediaEnum.Event.ID],
                    contentType: playlist.contentType
                });
            },

            _removeItemFromPlaylist(item) {
                this._performEditActionForItemWithinPlaylistId(item, this.lastSelectedItem[MediaEnum.Event.ID], function () {
                    return MediaServerComp.removeItem(this.contentTypeItems[item.contentType].all.indexOf(item));
                }.bind(this));
            },

            _performEditActionForItemWithinPlaylistId(item, playlistId, boundEditAction) {
                return NavigationComp.showWaitingFor(this.startEditing(this.lastSelectedItem, item.contentType, this.getMediaInfo(item.contentType), playlistId)).finally(function () {
                    return Q(boundEditAction().finally(function () {
                        return this.stopEditing(playlistId);
                    }.bind(this)));
                }.bind(this));
            }
        };
    }
};
