import {useControl, navigatorConfig, LxReactHeader, LxReactTextView, useLiveState, LxReactTableView, LxReactText} from "LxComponents"
import {View} from "react-native"
import {useState, useRef, useMemo, useEffect} from "react"
import globalStyles from "GlobalStyles";
import Icons from "IconLib";
import {useBackNavigation} from "../../../../react-comps/Components";


export default function EmStorageSettingsScreen({ navigation, route }) {
    const details = route.params;
    const control = useControl(details.controlUUID);
    const liveState = useLiveState(details.controlUUID, ["minStateOfCharge", "maxStorageChargePower"]);

    const topNavCfg = navigatorConfig({
        title: _("energymanager2.storage-settings.title"),
        animationType: AnimationType.MODAL
    });

    navigation.setOptions({
        ...topNavCfg
    })

    useBackNavigation(() => {
        navigation.goBack();
    })

    // region acquire exact texts from miniserver
    const [storageTexts, setStorageTexts] = useState(control.storageSettingsTexts);
    const loadId = useRef(0);
    useEffect(() => {
        if (storageTexts.length === 0 && loadId.current === 0) {
            loadId.current = 1;
            control.loadStorageSettingsTexts().then(() => {
                if (loadId.current === 1) {
                    setStorageTexts(control.storageSettingsTexts)
                    loadId.current = 3;
                }
            });
        }
        return () => {
            loadId.current = 0;
        }
    });

    const {minStorageTitle, minStorageDescr, maxStorageChargeTitle, maxStorageChargeDescr} = useMemo(() => {
        let minStorageTitle = "Minimum energy storage state of charge [%]";
        let minStorageDescr = "If value is greater than 0, charging the energy storage has the highest priority until the minimum state of charge (SoC) is reached. After that, the energy storage is only charged if there is excess energy.";
        let maxStorageChargeTitle = "Maximum energy storage power [kW]";
        let maxStorageChargeDescr = "Defines the maximum charging power of the energy storage.";

        storageTexts.forEach((textObj) => {
            if (textObj.io === "MinSoc") {
                minStorageTitle = textObj.title;
                minStorageDescr = textObj.description + " [" + textObj.unit + "]";

            } else if (textObj.io === "MaxSpwr") {
                maxStorageChargeTitle = textObj.title;
                maxStorageChargeDescr = textObj.description + " [" + textObj.unit + "]";
            }
        })

        return {
            minStorageTitle, minStorageDescr, maxStorageChargeTitle, maxStorageChargeDescr
        }
    }, [loadId.current]);
    // endregion

    const styles = {
        rootCntr: {
            //...globalStyles.customStyles.screenContent,
            flexDirection: "column",
            flex: 1
        },
        headerCntr: {
        },
        header: {
        },
        value: {
            ...globalStyles.textStyles.body.default,
            color: globalStyles.colors.text.secondary,
            textAlign: "right"

        },
        description: {
            ...globalStyles.textStyles.footNote.default,
            color: globalStyles.colors.text.secondary,
            textAlign: "left",
            marginTop: globalStyles.sizes.smaller
        },

    }

    const minStateOfChargeRow = () => {
        let controllable = control.canConfigureMinStateOfCharge();
        return {
            title: minStorageTitle,
            disabled: !controllable,
            subTitle: !controllable ?  _("controlled-via-logic") : null,
            mainRightContent: {
                comp: LxReactTextView,
                props: {
                    value: lxFormat("%.0f", liveState.states.minStateOfCharge),
                    textStyle: {...styles.value, marginRight: 0 },
                    containerStyle: {width: 80},
                    displayInCell: true,
                    placeholderTextColor: globalStyles.colors.text.secondary,
                    hideRightIcon: true,
                    placeholder: "-",
                    type: GUI.LxInputEnum.Type.NUMBER,
                    validation: [
                        { regExp: Regex.INT_0_100, message: _("invalid-input")}
                    ],
                    onBlur: (newValue, isValid, modified) => {
                        if (newValue && isValid && modified) {
                            control.setStorageMinStateOfCharge(parseInt(newValue));
                        }
                    }
                },
            },
            bottomContent: {
                comp: LxReactText,
                props: {
                    children: minStorageDescr,
                    style: styles.description
                }
            }
        };
    }

    const maxStorageChargePowerRow = () => {
        let controllable = control.canConfigureMaxStorageChargePower();
        return {
            title: maxStorageChargeTitle,
            disabled: !controllable,
            subTitle: !controllable ?  _("controlled-via-logic") : null,
            mainRightContent: {
                comp: LxReactTextView,
                props: {
                    value: lxFormat("%.2f", liveState.states.maxStorageChargePower, true),
                    textStyle: {...styles.value, marginRight: 0 },
                    containerStyle: {width: 80},
                    displayInCell: true,
                    placeholderTextColor: globalStyles.colors.text.secondary,
                    hideRightIcon: true,
                    placeholder: "-",
                    type: GUI.LxInputEnum.Type.NUMBER,
                    validation: [
                        { regExp: Regex.POSITIVE_DECIMAL_VALUE, message: _("invalid-input")}
                    ],
                    onBlur: (newValue, isValid, modified) => {
                        if (newValue && isValid && modified) {
                            control.setStorageMaxChargePower(newValue);
                        }
                    }
                },
            },
            bottomContent: {
                comp: LxReactText,
                props: {
                    children: maxStorageChargeDescr,
                    style: styles.description
                }
            }
        };
    }

    const getTableContent = () => {
        let rows = [];
        if (control.hasStorageStateOfChargeInfo) {
            rows.pushObject(minStateOfChargeRow());
        }
        if (control.hasStorageChargePowerInfo) {
            rows.pushObject(maxStorageChargePowerRow());
        }
        return [{
            rows: rows
        }];
    }

    return <View style={styles.rootCntr} >
        <View style={styles.headerCntr}><LxReactHeader icon={Icons.Flash} style={styles.header} /></View>
        <LxReactTableView tableContent={getTableContent()} containerStyle={styles.table}/>
    </View>

}
