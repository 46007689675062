'use strict';
/*

 buttonTapped(section, row, tableView)
 buttonHit(section, row, tableView)
 buttonReleased(section, row, tableView)

 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.COLLECTION_BUTTON = "CollectionButtonCell";

    class CollectionButtonCell extends GUI.TableViewV2.Cells.CollectionCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("collection-button-cell");
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(() => {
                var activeParts = [{
                    part: this.contentPlaceholder.find('.content__icon-placeholder')[0],
                    mode: "fill"
                }, {
                    part: this.contentPlaceholder.find('.icon-placeholder__icon')[0],
                    mode: "fill"
                }];

                if (this.content.activeTextColor) {
                    activeParts.push({
                        part: this.contentPlaceholder.find('.content__title')[0],
                        mode: "color"
                    });
                }

                this.button = new GUI.LxButton(this, this.contentPlaceholder[0]); //this.button.useChildsAsActiveParts('fill');

                return this.addToHandledSubviews(this.button);
            });
        }

        viewDidAppear() {
            var baseCall = super.viewDidAppear(...arguments);

            this.button.onButtonTapped = function pushbuttonTapped() {
                this.delegate.buttonTapped && this.delegate.buttonTapped(this.sectionIdx, this.rowIdx, this.tableView);
            };

            this.button.onButtonHit = function pushbuttonHit() {
                this.delegate.buttonHit && this.delegate.buttonHit(this.sectionIdx, this.rowIdx, this.tableView);
            };

            this.button.onButtonReleased = function pushbuttonReleased() {
                this.delegate.buttonReleased && this.delegate.buttonReleased(this.sectionIdx, this.rowIdx, this.tableView);
            };

            return baseCall;
        }

        viewWillDisappear() {
            this.button.onButtonTapped = null;
            this.button.onButtonHit = null;
            this.button.onButtonReleased = null;
            return super.viewWillDisappear();
        }

        setEnabled(enabled) {
            super.setEnabled(enabled);
            this.button.setEnabled(enabled);
        }

    }

    GUI.TableViewV2.Cells.CollectionButtonCell = CollectionButtonCell;
    return GUI;
}(window.GUI || {});
