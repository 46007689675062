'use strict';

define(["AutomaticDesignerTableContentHelper"], function (AutomaticDesignerTableContentHelper) {
    return class AutomaticDesignerRuleMenuPageScreen extends GUI.Screen {
        constructor(details) {
            super($('<div/>'));
            this.screenType = details.screenType;
            this.lastSelectedEntry = details.entry;
            this.tableContentHelper = new AutomaticDesignerTableContentHelper(this, details.navigationPath, details.screenType);
        }

        viewDidLoad() {
            var promise = super.viewDidLoad(...arguments);
            this.elements = {};
            this.roomsTableViewDataSource = tableViewDataSource(null, null, this);
            this.roomsTableViewDelegate = tableViewDelegate(null, this);
            this.roomsTableView = new GUI.TableViewV2(this.roomsTableViewDataSource, this.roomsTableViewDelegate);
            this.addToHandledSubviews(this.roomsTableView);
            this.categoriesTableViewDataSource = tableViewDataSource(null, null, this);
            this.categoriesTableViewDelegate = tableViewDelegate(null, this);
            this.categoriesTableView = new GUI.TableViewV2(this.categoriesTableViewDataSource, this.categoriesTableViewDelegate);
            this.addToHandledSubviews(this.categoriesTableView);
            var roomTableContent = this.tableContentHelper.getTableContentForGroupType(GroupTypes.ROOM);
            this.roomsTableViewDataSource.update(roomTableContent);
            this.roomsTableViewDelegate.update(roomTableContent);
            this.roomsTableView.reload();
            var catTableContent = this.tableContentHelper.getTableContentForGroupType(GroupTypes.CATEGORY);
            this.categoriesTableViewDataSource.update(catTableContent);
            this.categoriesTableViewDelegate.update(catTableContent);
            this.categoriesTableView.reload();
            var groupTitles = ActiveMSComponent.getStructureManager().getCustomGroupTitles();
            this.segmentedControl = new GUI.LxSegmentedControl([{
                text: groupTitles[GroupTypes.ROOM]
            }, {
                text: groupTitles[GroupTypes.CATEGORY]
            }]);
            this.segmentedControl.on("on-segment-selected", function (ev, idx) {
                this.pageView.setActivePage(idx);
            }.bind(this));
            this.pageView = new GUI.LxPageView([this.roomsTableView.getElement(), this.categoriesTableView.getElement()], false, this._doneAnimating.bind(this), {
                overscroll: true
            });
            this.pageView.disableUserInteraction(true);
            this.appendSubview(this.segmentedControl);
            this.appendSubview(this.pageView);
            return promise;
        }

        titleBarText() {
            return this.lastSelectedEntry.name || " ";
        }

        titleBarConfig() {
            return {
                style: TitleBarCfg.STYLE.MINOR
            };
        }

        titleBarActionRight() {
            this.ViewController.showGlobalSearch();
        }

        // TableView DataSource Methods
        styleForTableView() {
            return TableViewStyle.COMFORT_MODE_2020;
        }

        _doneAnimating(pageIndex) {
            this.tableContentHelper.updateNavigationPathAtIndex(AutomaticDesignerEnums.PAGE_VIEW_INDEX, pageIndex);
            this.segmentedControl.setActiveSegment(pageIndex);
        }

    };
});
