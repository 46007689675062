import {useState, useEffect} from "react";
import {useConnectionReady} from "LxComponents";

export default function useDeviceSearchLightGroups(device) {

    /**
     * Ensures the lightGroup "None" exists and publishes the result.
     * @param lightGroups
     */
    const addNoneLgEntry = (lightGroups) => {
        if (!lightGroups) {
            return null;
        }
        let preparedList = [...lightGroups];
        // ensure a "no lighting scene selected" entry is available
        if (!preparedList.find((entry) => entry.id === 0)) {
            preparedList.splice(0, 0, {id: 0, uuid: "", name: _("not-used")});
        }
        return preparedList;
    }

    const connReady = useConnectionReady(true);
    const [lightGroupsList, setLightGroupsList] = useState(null);

    useEffect(() => {
        connReady && setLightGroupsList(addNoneLgEntry(ActiveMSComponent.getAvailableLightGroups()))
        const unregFn = ActiveMSComponent.registerForLightGroupChanges((newLightGroups) => {
            setLightGroupsList(addNoneLgEntry(newLightGroups));
        });
        return () => {
            unregFn && unregFn();
        }
    }, []);

    const filterLightGroups = () => {
        if (device && device.hasOwnProperty("msSerial") && Array.isArray(lightGroupsList)) {
            return lightGroupsList.filter((lg) => {
                return lg.hasOwnProperty("msSerial") ? lg.msSerial === device.msSerial : true;
            });
        } else {
            return lightGroupsList;
        }
    };


    return filterLightGroups();
}
