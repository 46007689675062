import {isValidSliderValue} from "./LxReactSliderUtils";
import LxReactVerticalSlider from "./LxReactVerticalSlider";
import {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {LxSliderDefaultSettings} from "LxComponents";

function LxReactVerticalSliderHandler(props) {
    const configRef = useRef({
        min: LxSliderDefaultSettings.defaultSettings.MIN,
        max: LxSliderDefaultSettings.defaultSettings.MAX,
        step: LxSliderDefaultSettings.defaultSettings.STEP
    });
    const [value, setValue] = useState(props.value);

    const optionsRef = useRef({
        isDragging: false,
        valueChangeTimeout: null,
        cachedValue: 0
    })

    const getOptions = () => optionsRef.current;

    useEffect(() => {
        let noGhostActive = !getOptions().antiGhostTimer ||
            (getOptions().antiGhostTimer && !getOptions().antiGhostTimer.isAntiGhostTimerActive());
        Debug.React.Slider && console.log(LxReactVerticalSliderHandler.name + "---> props.value changed, internal value = " + value + ", props.value=" + props.value)
        Debug.React.Slider && console.log(LxReactVerticalSliderHandler.name + "---> AntiGhostTimer active: " + !noGhostActive);
        if (noGhostActive && !isDragging() && props.value !== value) {
            Debug.React.Slider && console.log(LxReactVerticalSliderHandler.name + "---> Setting Value to " + props.value)
            updateValueSafely(props.value)
        }
        getOptions().cachedValue = props.value;
    }, [props.value])

    useEffect(() => {
        let cleanConfig = checkConfigForSafety(props.config);

        Object.keys(cleanConfig).forEach(key => {
            if (cleanConfig[key] !== configRef.current[key]) {
                configRef.current[key] = cleanConfig[key];
            }
        })
    }, [props.config])

    useEffect(() => {
        if (props.antiGhostTimer) {
            getOptions().cachedValue = props.value;
            getOptions().antiGhostTimer = new AntiGhostTimer(() => {
                updateValueSafely(getOptions().cachedValue);
            }, props.antiGhostDuration);
        }
    }, [])

    //region Public Methods
    const checkConfigForSafety = (rawConfig) => {
        if (!isValidSliderValue(rawConfig.max)) {
            rawConfig.max = configRef.current.max || LxSliderDefaultSettings.defaultSettings.MAX;
        }
        if (!isValidSliderValue(rawConfig.min)) {
            rawConfig.min = configRef.current.min || LxSliderDefaultSettings.defaultSettings.MIN;
        }
        if (!isValidSliderValue(rawConfig.step)) {
            rawConfig.step = configRef.current.step || LxSliderDefaultSettings.defaultSettings.STEP;
        }
        return rawConfig
    }

    const updateValueSafely = (newValue) => {
        Debug.React.Slider && console.log(LxReactVerticalSlider.name + "---> updateValueSafely() " + newValue)
        let newSafeValue = Math.min(configRef.current.max, Math.max(newValue, configRef.current.min))
        setValue(newSafeValue);
        getOptions().cachedValue = newSafeValue;
    }

    //endregion Public methods

    //region Private methods
    const isDragging = () => getOptions().isDragging;

    const onValueChanged = (newValue) => {
        if (!isDragging()) {
            onDraggingChanged(true);
        }
        getOptions().cachedValue = newValue;
        getOptions().antiGhostTimer && getOptions().antiGhostTimer.fire();
        updateValueSafely(newValue)
        if (props.config.hasCustomRate) {
            if (!getOptions().valueChangeTimeout) {
                getOptions().valueChangeTimeout = setTimeout(() => {
                    Debug.React.Slider && console.log(LxReactVerticalSliderHandler.name + " --> Timeout ended")
                    triggerOnPositionChanged(getOptions().cachedValue, true)
                }, configRef.current.updateRate * 1000);
            }
        } else {
            Debug.React.Slider && console.log(LxReactVerticalSliderHandler.name + " --> call instant triggerOnPositionChanged()")
            triggerOnPositionChanged(newValue, false);
        }
    }

    const triggerOnPositionChanged = (value, afterTimeout) => {
        if (props.onValueChanged) {
            Debug.React.Slider && console.log(LxReactVerticalSliderHandler.name + " --> call external onValueChanged() ", value)
            props.onValueChanged(value, value === configRef.current.min, getOptions().isDragging);
        }
        if (afterTimeout) {
            getOptions().valueChangeTimeout = null;
        }
    }

    const onDraggingChanged = (hasStarted) => {
        getOptions().isDragging = hasStarted;
        if (props.onDraggingChanged) {
            props.onDraggingChanged(value);
        }
    }

    useEffect(() => {
        configRef.current = checkConfigForSafety(configRef.current);
    }, [])

    //endregion Private methods
    return (
        <LxReactVerticalSlider
            min={configRef.current.min}
            max={configRef.current.max}
            step={configRef.current.step}
            value={value}
            onValueChanged={(newVal) => onValueChanged(newVal)}
            onValueChangeComplete={(newVal) => onDraggingChanged(false)}
            activeColor={configRef.current.activeColor}
            disabled={props.disabled}
            containerStyle={props.containerStyle}
        />
    )
}

LxReactVerticalSliderHandler.propTypes = {
    value: PropTypes.number.isRequired,
    config: PropTypes.object.isRequired,
    hasAntiGhostTimer: PropTypes.bool,
    antiGhostDuration: PropTypes.number,
    onValueChanged: PropTypes.func,
    onDraggingChanged: PropTypes.func,
    onDraggingEnded: PropTypes.func,
    containerStyle: PropTypes.object
}

export default LxReactVerticalSliderHandler
