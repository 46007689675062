'use strict';

define("AtheneControlCommandSrc", ["IntercomControlCommandSrc", "AtheneControlEnums"], function (IntercomControlCommandSrc, AtheneControlEnums) {
    return class AtheneCommandSrc extends IntercomControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            if (states.deviceState === AtheneControlEnums.DEVICE_STATE.OK) {
                return super.getCommands(...arguments);
            } else {
                return [];
            }
        }

        _createCommandObjFor(subControlId) {
            return this.createCmdObj(Commands.format(Commands.INTERCOM.OUTPUT_PULSE, subControlId), function () {
                return null;
            }, Commands.format(Commands.INTERCOM.OUTPUT_PULSE, subControlId));
        }

    };
});
