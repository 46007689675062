'use strict';

window.GUI = function (GUI) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        GUI.TableViewV2.CellType.Special.MINISERVER_CELL = "MiniserverCell";

        class MiniserverCell extends GUI.TableViewV2.Cells.EditableBaseCell {
            //region Static
            static Template = function () {
                var getMiniserverCellContent = function getMiniserverCellContent(content) {
                    var subtitle = content.subtitle ? getSubtitle(content) : '';
                    return $('<div class="miniserver-cell__texts-placeholder">' + '<div class="texts-placeholder__title text-overflow-ellipsis">' + content.title + '</div>' + subtitle + '</div>' + getInfoIcon());
                };

                var getSubtitle = function getSubtitle(content) {
                    var extraClass = content.isConnected ? " connected" : "";
                    var result = '<div class="texts-placeholder__subtitle' + extraClass + '">';

                    if (content.isConnected) {
                        result += ImageBox.getResourceImageWithClasses("resources/Images/DeviceManagement/connect.svg", "subtitle__icon");
                    }

                    result += '<div class="subtitle__text text-overflow-ellipsis">' + content.subtitle + '</div>';
                    return result + '</div>';
                };

                var getInfoIcon = function getInfoIcon() {
                    return '<div class="miniserver-cell__info-button">' + ImageBox.getResourceImageWithClasses('resources/Images/General/info.svg', 'info-button__icon') + '</div>';
                };

                return {
                    getMiniserverCellContent: getMiniserverCellContent
                };
            }(); //endregion Static

            constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
                super(...arguments);
                this.element.addClass("miniserver-cell");
            }

            viewDidLoad() {
                super.viewDidLoad();
                var content = MiniserverCell.Template.getMiniserverCellContent(this.content);
                this.contentPlaceholder.append(content);
                this.infoButton = new GUI.LxButton(this, content.closest('.miniserver-cell__info-button')[0], Color.BUTTON_GLOW, null, true);
                this.infoButton.useChildsAsActiveParts('fill');
                this.addToHandledSubviews(this.infoButton);
            }

            viewDidAppear() {
                super.viewDidAppear(...arguments);
                this.infoButton.onButtonTapped = this._showMiniserverInfoPopup;
            }

            viewWillDisappear() {
                this.infoButton.onButtonTapped = null;
                super.viewWillDisappear(...arguments);
            }

            clickableAllowed() {
                return true;
            }

            // Event listeners
            _showMiniserverInfoPopup() {
                var miniserver = this.dataSource.infoForMiniserverCell(this.element, this.sectionIdx, this.rowIdx);
                var message = _("miniserver.serial-number") + ": " + miniserver.serialNo + "<br />";

                if (ActiveMSComponent.getMiniserverSerialNo() === miniserver.serialNo) {
                    message = message.concat(_("miniserver.firmware") + ": " + ActiveMSComponent.getConfigVersion() + "<br /><br />");
                } else {
                    message = message.concat("<br />");
                }

                if (miniserver.activeUser) {
                    message = message.concat(_("miniserver.user") + ": " + miniserver.activeUser + "<br /><br />");
                }

                if (miniserver.localUrl) {
                    message = message.concat(_("miniserver.url.local") + ": " + miniserver.localUrl + "<br />");
                }

                if (miniserver.remoteUrl) {
                    message = message.concat(_("miniserver.url.remote") + ": " + miniserver.remoteUrl + "<br />");
                }

                var content = {
                    title: 'Miniserver "' + miniserver.msName + '"',
                    message: message,
                    buttonOk: true,
                    icon: Icon.INFO,
                    color: window.Styles.colors.green
                };
                NavigationComp.showPopup(content);
            }

        }

        GUI.TableViewV2.Cells.MiniserverCell = MiniserverCell;
    }
    return GUI;
}(window.GUI || {});
