import CardScreen from "../../ViewController/cardScreen/cardScreen.jsx";
import PropTypes from "prop-types";
import {useCallback, useContext, useRef} from "react";
import {useFocusEffect} from "@react-navigation/native";
import {AmbientContext} from "LxComponents";

function GroupsScreen({navigation, route}) {
    const {isAmbientMode} = useContext(AmbientContext)
    const details = route.params,
        groupType = details.groupType;

    useFocusEffect(useCallback(() => {
        if (isAmbientMode) {
            navigation.setOptions({
                headerTitle: details.headerTitle
            })
        }
    }, [details.headerTitle]))

    const childRef = useRef(null);

    const _getContent = useCallback(() => {
        let groups = ActiveMSComponent.getStructureManager().getGroupsByType(groupType),
            useSimpleDesign = PersistenceComponent.getSimpleDesignSetting(),
            favGroups = ActiveMSComponent.getStructureManager().getFavoriteGroupsByGroupType(groupType);

        let content = [],
            section0 = {
                rows: []
            },
            section1 = {
                headerTitle: _("all"),
                rows: []
            };

        if (favGroups.length > 0) {
            favGroups.forEach((group) => {
                section0.rows.push({
                    type: GUI.TableViewV2.CellType.Special.GROUP_CARD,
                    content: {
                        group: group,
                        displayAsCell: !NavigationComp.tileRepresentationEnabled(),
                        useSimpleDesign: useSimpleDesign
                    }
                });
            }); // no need to sort anything, favorites are already sorted by getFavoriteGroupsByType

            content.push(section0);
        }

        groups.forEach((group) => {
            section1.rows.push({
                type: GUI.TableViewV2.CellType.Special.GROUP_CARD,
                content: {
                    group: group,
                    displayAsCell: true,
                    useSimpleDesign: useSimpleDesign
                }
            });
        }); // sort regular groups by name

        section1.rows.sort((a, b) => {
            if (!ActiveMSComponent.getSortingStructure()) {
                // old star sorting
                return a.content.group.name.toUpperCase().localeCompare(b.content.group.name.toUpperCase());
            } else {
                // new sorting
                var location = GroupTypes.ROOM;

                if (a.content.group.groupType === GroupTypes.CATEGORY) {
                    location = GroupTypes.CATEGORY;
                }

                var objA = ActiveMSComponent.getSortingStructureForObject(a.content.group.uuid, location);
                var objB = ActiveMSComponent.getSortingStructureForObject(b.content.group.uuid, location);

                if (objA && objB && (objA.position === null || objB.position === null)) {
                    return 0;
                }

                if (objA && objB && objA.position > objB.position) {
                    return 1;
                }

                if (objA && objB && objA.position < objB.position) {
                    return -1;
                }

                return a.content.group.name.toUpperCase().localeCompare(b.content.group.name.toUpperCase());
            }
        });
        content.push(section1);
        return content.filter((section) => {
            return section.rows.length > 0;
        });
    }, [])

    // needed in cardScreen
    // don't delete
    const getContextMenuOptions = useCallback(() => {
        let options = [];

        if (childRef.current) {
            options.pushObject(childRef.current.getSortingOption());
        }


        return options;
    }, [])

    const _getFavForIndex = useCallback((section, numberOfSections) => {
        if (numberOfSections === 1) {
            return false;
        } else {
            return section === 0;
        }
    }, [])

    const _getSortingLocation = useCallback((section, tableContent) => {
        let numberOfSections = tableContent.length;

        if (groupType === GroupTypes.CATEGORY) {
            if (numberOfSections === 1) {
                return UrlStartLocation.CATEGORY;
            } else {
                return section === 0 ? UrlStartLocation.FAVORITES : UrlStartLocation.CATEGORY;
            }
        } else {
            if (numberOfSections === 1) {
                return UrlStartLocation.ROOM;
            } else {
                return section === 0 ? UrlStartLocation.FAVORITES : UrlStartLocation.ROOM;
            }
        }
    }, [])

    const getSortingContextMenuOptions = useCallback((cell) => {
        let defaultContent = childRef.current.getSortingContextMenuOptions(cell),
            contextMenuContent = [];
        contextMenuContent.pushObject(_createOnFavGroupEntry(cell.content.group));
        contextMenuContent = contextMenuContent.concat(defaultContent);
        return contextMenuContent;
    }, [])

    const _createOnFavGroupEntry = (group) => {
        if (ActiveMSComponent.isExpertModeLightEnabled() && !NavigationComp.getCurrentActivityType()) {
            let location, sortingObject;

            if (groupType === GroupTypes.ROOM) {
                location = UrlStartLocation.ROOM;
            } else {
                location = UrlStartLocation.CATEGORY;
            }

            if (!ActiveMSComponent.getSortingStructure()) {
                if (group.isFavorite) {
                    return _createRemoveFavoriteGroupEntry(group, location);
                } else {
                    return _createAddFavoriteGroupEntry(group, location);
                }
            } else {
                sortingObject = ActiveMSComponent.getSortingStructureForObject(group.uuid, UrlStartLocation.FAVORITES);

                if (sortingObject && sortingObject.isFav) {
                    return _createRemoveFavoriteGroupEntry(group, location);
                } else {
                    return _createAddFavoriteGroupEntry(group, location);
                }
            }
        } else {
            return null;
        }
    }

    const _createAddFavoriteGroupEntry = (group, location) => {
        var title;

        if (groupType === GroupTypes.ROOM) {
            title = _("context-menu.add-room-favorite");
        } else {
            title = _("context-menu.add-category-favorite");
        }

        return {
            title: title,
            action: () => {
                childRef.current.addAsFavorite(null, location, null, group);
            }
        };
    }

    const _createRemoveFavoriteGroupEntry = (group, location) => {
        var title;

        if (groupType === GroupTypes.ROOM) {
            title = _("context-menu.remove-room-favorite");
        } else {
            title = _("context-menu.remove-category-favorite");
        }

        return {
            title: title,
            action: () => {
                childRef.current.removeAsFavorite(null, location, null, group);
            }
        };
    }

    const initialContent = useRef(_getContent())
    const emptyScreenConfig = useRef(details.emptyScreenConfig)

    return <CardScreen
        ref={childRef}
        tableContentFromParent={initialContent.current}
        getTableContent={_getContent}
        emptyScreenConfig={emptyScreenConfig.current}
        title={details.headerTitle}
        contextMenuOptions={getContextMenuOptions}
        getFavForIndex={_getFavForIndex}
        getSortingLocation={_getSortingLocation}
        getSortingContextMenuOptions={getSortingContextMenuOptions}
    />
}

GroupsScreen.propTypes = {
    groupType: PropTypes.string,
    emptyScreenConfig: PropTypes.object,
    headerTitle: PropTypes.string
}

export default GroupsScreen;
