/**
 * Created by loxone on 14.12.17.
 */
'use strict';

define(['VentilationControlEnums'], function (VentilationControlEnums) {
    return class VentilationControlSettingsScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div />'));
            this.control = details.control;
            this.timerHasBeenSent = false;
        }

        viewWillDisappear() {
            // Only stop the timer if the settings have changed and if the current active timer is the settings timer!
            this._clearSendTimeout();

            if (this.timerHasBeenSent) {
                var states = this.control.getStates();

                if (states.activeTimerProfile === VentilationControlEnums.TIMER_PRESET.SETTINGS) {
                    // Send this command as an "automatic" (last parameter) to prevent the "Bad Connection popup
                    // when immediately closing and reopening the app after changing a value
                    this.control.sendCommand(Commands.VENTILATION.TIMER.STOP, null, null, true, null, null, true);
                }
            }

            super.viewWillDisappear(...arguments);
        }

        titleBarText() {
            return _('settings');
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        reloadTable() {
            var states = this.control.getStates(),
                hasAbsenceMin = this.control.details.hasAbsenceMin,
                hasAbsenceMax = this.control.details.hasAbsenceMax,
                hasPresenceMin = this.control.details.hasPresenceMin,
                hasPresenceMax = this.control.details.hasPresenceMax,
                basicRows = [],
                increasedRows = []; // It doesn't make any sense, if we show the cells for presence if the user is absent

            if (this.control.details.hasPresence) {
                basicRows.push(this._createCell(hasPresenceMin, states.presenceMin, _('controls.leaf.settings.intensity-presence')));
                increasedRows.push(this._createCell(hasPresenceMax, states.presenceMax, _('controls.leaf.settings.intensity-presence')));
            }

            basicRows.push(this._createCell(hasAbsenceMin, states.absenceMin, this._getAbsenceTitle(true)));
            increasedRows.push(this._createCell(hasAbsenceMax, states.absenceMax, this._getAbsenceTitle(false)));
            this.tableContent = [{
                headerBoldTitle: _("controls.leaf.settings.basic-ventilation"),
                rows: basicRows
            }, {
                headerBoldTitle: _('controls.leaf.settings.extended-ventilation'),
                rows: increasedRows
            }];
            return super.reloadTable(...arguments);
        }

        /**
         * Returns the combined content of a generalCell and a sliderCell
         * @param hasType
         * @param value
         * @param title
         * @returns {{type: string, content: {title: *, disclosureText: string, disclosureColor: string, value: *, format: string, valueColor, minIconSrc: string, maxIconSrc: string}, sliderClicked: (function(this:_createCell))}}
         * @private
         */
        _createCell(hasType, value, title) {
            var type = hasType ? GUI.TableViewV2.CellType.GENERAL : GUI.TableViewV2.CellType.SLIDER;
            return {
                type: type,
                content: {
                    title: title,
                    subtitle: _('controls.leaf.settings.locked-desc'),
                    subtitleColor: Color.TEXT_SECONDARY_A,
                    // GeneralCell content
                    disclosureText: value + "%",
                    disclosureColor: window.Styles.colors.stateActive,
                    // SliderCell content
                    value: value,
                    format: "%i%%",
                    valueColor: window.Styles.colors.stateActive,
                    minIconSrc: Icon.ValueSelector.MINUS_PLAIN,
                    maxIconSrc: Icon.ValueSelector.PLUS_PLAIN,
                    hasCustomRate: true,
                    updateRate: 0.8
                },
                sliderClicked: this._directlySendNewValue.bind(this),
                sliderDragged: this._directlySendNewValue.bind(this)
            };
        }

        /**
         * Returns the title for the absence Cell
         * This function will return different strings if no presence is connected to the control
         * @param min Max or Min absence
         * @private
         */
        _getAbsenceTitle(min) {
            if (min) {
                if (this.control.details.hasPresence) {
                    return _('controls.leaf.settings.intensity-absence');
                }
            } else {
                if (this.control.details.hasPresence) {
                    return _('controls.leaf.settings.intensity-absence');
                }
            }

            return _('controls.leaf.settings.intensity');
        }

        /**
         * Directly send the new value
         * @param cell
         * @param section
         * @param row
         * @param tableView
         * @param value
         * @private
         */
        _directlySendNewValue(cell, section, row, tableView, value) {
            var cmd, timeoutCmd;

            this._clearSendTimeout();

            if (section === 0) {
                if (row === 0 && this.control.details.hasPresence) {
                    cmd = Commands.format(Commands.VENTILATION.SET_PRESENCE.MIN, value);
                } else {
                    cmd = Commands.format(Commands.VENTILATION.SET_ABSENCE.MIN, value);
                }
            } else if (section === 1) {
                if (row === 0 && this.control.details.hasPresence) {
                    cmd = Commands.format(Commands.VENTILATION.SET_PRESENCE.MAX, value);
                } else {
                    cmd = Commands.format(Commands.VENTILATION.SET_ABSENCE.MAX, value);
                }
            }

            if (cmd) {
                this.control.sendCommand(cmd, Commands.Type.OVERRIDE); // The Control ignores any timer when stopped.
                // Only start a timer when the control is not stopped to prevent unnecessary communication

                if (this.control.getStates().ventReason !== VentilationControlEnums.VENT_REASONS.STOP) {
                    timeoutCmd = Commands.format(Commands.VENTILATION.TIMER.SET, VentilationControlEnums.SETTING_TIMER_DURATION, value, VentilationControlEnums.MODES.HEAT_EXCHANGER_ON, VentilationControlEnums.TIMER_PRESET.SETTINGS); // Send this command as an "automatic" (last parameter) to prevent the "Bad Connection popup
                    // when immediately closing and reopening the app after changing a value

                    this.control.sendCommand(timeoutCmd, Commands.Type.OVERRIDE, null, true, null, null, true);
                    this.timerHasBeenSent = true;
                }
            }
        }

        _clearSendTimeout() {
            if (this.sendTimeout) {
                clearTimeout(this.sendTimeout);
                this.sendTimeout = null;
            }
        }

    };
});
