'use strict';
/*
 title || icon
 textColor          // specify a font color for this button
 */

window.GUI = function (GUI) {
    class CenterCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(content) {
                if (content.icon) {
                    return ImageBox.getResourceImageWithClasses(content.icon, "content__icon");
                } else {
                    return '<div class="content__title">' + content.title + '</div>';
                }
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor() {
            super(...arguments);
            this.element.addClass("center-cell");
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(this.updateContent.bind(this, this.content));
        }

        clickableAllowed() {
            return true;
        }

        // ---------------------------------------------------------------------------------------------------------
        //   Methods for super fast tableContent updates
        // ---------------------------------------------------------------------------------------------------------
        cellTypeForReuse() {
            return GUI.TableViewV2.CellType.CENTER;
        }

        updateContent(newContent) {
            return super._updateContent(...arguments);
        }

        /**
         * This Method is used to apply a new content object onto the cell instance. e.g. update texts and so on.
         * @param newContent
         * @private
         */
        _applyNewContent(newContent) {
            var promises = [super._applyNewContent(...arguments)];
            promises.push(GUI.animationHandler.schedule(function afApplyNewContentCentered() {
                this.contentPlaceholder.empty();
                this.contentPlaceholder.append(CenterCell.Template.getTemplate(newContent));
                this.contentPlaceholder.find(".content__title").css('color', newContent.textColor);
            }.bind(this)));
            return Q.all(promises);
        } // ---------------------------------------------------------------------------------------------------------
        // ---------------------------------------------------------------------------------------------------------


    }

    GUI.TableViewV2.Cells.CenterCell = CenterCell;
    GUI.TableViewV2.CellType.CENTER = "CenterCell";
    return GUI;
}(window.GUI || {});
