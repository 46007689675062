'use strict';
/**
 * entry
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.MESSAGE_CENTER_ENTRY_CELL = "MessageCenterEntryCell";

    class MessageCenterEntryCell extends GUI.TableViewV2.Cells.CardsCell {
        //region Static
        static Template = function () {
            var getHeader = function getHeader() {
                return $('<div class="cell__header">' + '   <div class="header__time"></div>' + '   <div class="header__location"></div>' + '   <div class="header__location-icon">' + '' + ImageBox.getResourceImageWithClasses(Icon.PIN, "location-icon__icon") + '   </div>' + '</div>');
            };

            return {
                getHeader: getHeader
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        setContent(content) {
            this.entry = content.entry;
            content.iconSrc = MessageCenterHelper.getIconForSeverityEntry(this.entry, false, true);
            content.iconColor = MessageCenterHelper.getColorForSeverityEntry(this.entry, content.checkConfirmed);
            content.title2 = this.entry.title;
            content.subtitle = this.entry.affectedName;
            content.displayAsCell = true;
            this.setTintColor(MessageCenterHelper.getTintColorForSeverity(this.entry, content.checkConfirmed));
            super.setContent(...arguments);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            var location = MessageCenterHelper.getLocationForEntry(this.entry);
            this.contentPlaceholder.before(MessageCenterEntryCell.Template.getHeader());
            this.elements.header = {
                time: this.element.find(".header__time"),
                location: this.element.find(".header__location"),
                locationIcon: this.element.find('.header__location-icon')
            };
            this.elements.header.time.text(MessageCenterHelper.getFormatedDate(this.entry));
            this.elements.header.location.text(location);
            this.elements.header.locationIcon.toggle(!!location); // Historic and already read entries shouldn't display the little red dot

            this.elements.iconCntr.toggleClass("content__icon-placeholder--with-small", !this.entry.readAt);
            this.elements.iconCntr.parent().toggleClass("cell__content--historic", !!this.entry.setHistoricAt);
        }

        /**
         * The MessageCenterEntryCell uses a custom icon
         * @note The smaller circle doesn't hold an SVG, its tinted red and is smaller
         * @return {string}
         * @private
         */
        _getBgIcon() {
            return Icon.STATE_ICON.MESSAGE_CENTER;
        }

    }

    GUI.TableViewV2.Cells.MessageCenterEntryCell = MessageCenterEntryCell;
    return GUI;
}(window.GUI || {});
