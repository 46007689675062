/**
 * Created by loxone on 27/07/15.
 */

/*
    title
    titleColor
 */
window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.INTERCOM_STATE = "IntercomStateCell";

    class IntercomStateCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getCellContent = function getCellContent(content) {
                var txtStyle = '';

                if (content.hasOwnProperty('titleColor')) {
                    txtStyle = ' style="color:' + content.titleColor + '" ';
                }

                var txt = '' + '<div class="content__title" ' + txtStyle + '>' + content.title + '</div>';
                return $(txt);
            };

            return {
                getCellContent: getCellContent
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("intercom-state-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.contentPlaceholder.append(IntercomStateCell.Template.getCellContent(this.content));
        }

        clickableAllowed() {
            return this.content.clickable;
        }

    }

    GUI.TableViewV2.Cells.IntercomStateCell = IntercomStateCell;
    return GUI;
}(window.GUI || {});
