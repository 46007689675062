'use strict';

define([], function () {
    return function LineChartView(data, ui, ctx, Helper, noEntryDefine) {
        var middleDP,
            middleX = 0,
            middleY = 0,
            lastX = 0,
            lastY = 0,
            middleOffset,
            drawToY;

        var draw = function drawLineChart() {
            drawToY = data.min < 0 ? Helper.getYForValue(0) : ui.bottomOffset;
            middleOffset = 1;
            middleDP = data.source[data.source.middleIndex];
            middleX = Math.round(Helper.getXForTimestamp(middleDP.ts)); // need to round here!

            middleY = Helper.getYForValue(middleDP.value);

            if (middleDP.isNoDataEntry) {
                // Don't show the true value of the NoDataValue, use the drawToY value instead to not display any chart
                middleY = drawToY;
            } // start in the middle


            ctx.moveTo(middleX, middleY);
            lastX = middleX; // set to middleX -> if no data points to left are drawn we draw down and up!

            lastY = middleY; // Don't show the true value if the middle data point is a "noEntryDefine" value
            // Instead:
            //  • Draw a straight line from the middle point (middleX, middleY) to the X value of the next data point
            //  • This step has to be repeated for every following contiguous data point that is a "noEntryDefine" value

            if (middleDP.isNoDataEntry) {
                Helper.forEachVisibleDataPoint(Direction.LEFT, data.source.middleIndex - middleOffset, function (dp, idx) {
                    return _drawNoEntryValueFromMiddleToDir(Direction.LEFT, dp, idx);
                });
            } // Draw the chart to the left


            Helper.forEachVisibleDataPoint(Direction.LEFT, data.source.middleIndex - middleOffset, function (dp, idx) {
                return _drawChartFromMiddleToDir(Direction.LEFT, dp, idx);
            }); // go back to the middle

            ctx.lineTo(lastX, drawToY);
            ctx.lineTo(middleX, drawToY);
            ctx.moveTo(middleX, middleY);
            lastX = middleX;
            middleOffset = 1; // Just to debug, shows a 15x15 green dot where the middle data point is
            //ctx.arc(middleX, middleY, 15, 0, 2 * Math.PI);
            // Don't show the true value if the middle data point is a "noEntryDefine" value
            // Instead:
            //  • Draw a straight line from the middle point (middleX, middleY) to the X value of the next data point
            //  • This step has to be repeated for every following contiguous data point that is a "noEntryDefine" value

            if (middleDP.isNoDataEntry) {
                Helper.forEachVisibleDataPoint(Direction.RIGHT, data.source.middleIndex + middleOffset, function (dp, idx) {
                    return _drawNoEntryValueFromMiddleToDir(Direction.RIGHT, dp, idx);
                });
            } // Draw the chart to the right


            Helper.forEachVisibleDataPoint(Direction.RIGHT, data.source.middleIndex + middleOffset, function (dp, idx) {
                return _drawChartFromMiddleToDir(Direction.RIGHT, dp, idx);
            });
            ctx.lineTo(lastX, drawToY);
            ctx.lineTo(middleX, drawToY);
            ctx.closePath();

            if (Debug.Statistic.Draw) {
                // Marks all data points on the left side
                Helper.forEachVisibleDataPoint(Direction.LEFT, data.source.middleIndex - middleOffset, function (dp, idx) {
                    if (!dp.isNoDataEntry) {
                        Helper.markPointWithColorAndLabel(Helper.getXForTimestamp(dp.ts), Helper.getYForValue(dp.value), window.Styles.colors.red, dp.value.toFixed(2));
                    }
                }); // Marks all data points on the right side

                Helper.forEachVisibleDataPoint(Direction.RIGHT, data.source.middleIndex + middleOffset, function (dp, idx) {
                    if (!dp.isNoDataEntry) {
                        Helper.markPointWithColorAndLabel(Helper.getXForTimestamp(dp.ts), Helper.getYForValue(dp.value), window.Styles.colors.red, dp.value.toFixed(2));
                    }
                }); // Marks the current middle

                Helper.markPointWithColorAndLabel(middleX, middleY, window.Styles.colors.blue, "middleDp");
            }

            data.check();
        };
        /**
         * Draws a straight line from the middle in the given direction for each contiguous NoDataEntry
         * @example
         +     Middle   +
         +-------------------+-------------------+
         |          |        |     |             |
         |     +-+  |        |     |             |
         |  +--+ |  |        |     | +---+   +---+
         |  |    +--+        |     | |   +---+   |
         +--+       |        |     +-+           |
         |          +--------------+ <-- This straight line is drawn here
         +---------------------------------------+
         +              +
         * @param dir The direction to draw the NoEntryValue (starting from the middle)
         * @param dp The data point to draw
         * @param idx The index of the data point
         * @returns {boolean}
         * @private
         */


        var _drawNoEntryValueFromMiddleToDir = function _drawNoEntryValueFromMiddleToDir(dir, dp, idx) {
            var nextOrPrevDp;

            if (dir === Direction.LEFT) {
                nextOrPrevDp = data.source[idx + 1];
            } else {
                nextOrPrevDp = data.source[idx - 1];
            } // The previous data point (aka middle data point) is a "noEntryDefine" value,
            // just draw a straight line to the left or right


            if (nextOrPrevDp && nextOrPrevDp.isNoDataEntry) {
                lastX = Helper.getXForTimestamp(dp.ts);
                lastY = drawToY;
                ctx.lineTo(lastX, lastY);
            } // The data point is a "noEntryDefine" value, draw a straight line to the left or right,
            // also decrease or increase the middleOffset, because we can ignore the data point when we draw the chart in the next step


            if (dp.isNoDataEntry) {
                lastX = Helper.getXForTimestamp(dp.ts);
                lastY = drawToY;
                ctx.lineTo(lastX, lastY);
                middleOffset++;
            } else {
                // Stop the iteration, draw nothing. This breaks the iterations
                return false;
            }
        };
        /**
         * Draws the graph from the middle to the given direction
         * @note NoDataEntries won't be drawn, the user will see blank spots instead
         * @example
         Middle
         +-------------------+-------------------+
         |                   |                   |
         |                   |       +--+        |
         +--+           +--------+   |  +-+    +-+
         |  +-+         |    |   |   |    |    | |
         |    |         |    |   |   |    |    | |
         |    |         |    |   |   |    |    | |
         +----+---------+----+---+---+----+----+-+
         * @param dir The direction to draw the graph (starting from the middle)
         * @param dp The data point to draw
         * @param idx The index of the data point
         * @returns {boolean}
         * @private
         */


        var _drawChartFromMiddleToDir = function _drawChartFromMiddleToDir(dir, dp, idx) {
            var nextOrPrevDp;

            if (dir === Direction.LEFT) {
                nextOrPrevDp = data.source[idx - 1];
            } else {
                nextOrPrevDp = data.source[idx + 1];
            }

            lastY = Helper.getYForValue(dp.value);

            if (!dp.isNoDataEntry) {
                lastX = Helper.getXForTimestamp(dp.ts);
            }

            ctx.lineTo(lastX, lastY);

            if (dp.isNoDataEntry) {
                lastX = Helper.getXForTimestamp(dp.ts);
                ctx.lineTo(lastX, lastY);

                if (nextOrPrevDp) {
                    lastX = Helper.getXForTimestamp(nextOrPrevDp.ts);
                    ctx.lineTo(lastX, lastY);
                }
            }
        };

        return {
            draw: draw
        };
    };
});
