"use strict";

define([], function () {
    return {
        ALARM_LEVEL: {
            NO_ALARM: 0,
            PRE_ALARM: 1,
            MAIN_ALARM: 2
        },
        STATE: {
            INACTIVE: 0,
            ACTIVE: 1
        }
    };
});
