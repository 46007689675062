'use strict';
/*
 rating
 didChangeRating(cell, section, row, tableView, value, dragging)
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.RATING = "RatingCell";

    class RatingCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                var htmlStr = '';

                for (var i = 0; i < 10; i++) {
                    htmlStr += ImageBox.getResourceImageWithClasses(Icon.Light.STAR, 'content__star');
                }

                return htmlStr;
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.elements.ratingStars = $(RatingCell.Template.getTemplate());
            this.contentPlaceholder.append(this.elements.ratingStars);
            this.updateRating(this.content.rating);
        }

        viewDidAppear() {
            super.viewDidAppear();
            this.ratingHandler = Hammer(this.contentPlaceholder[0]).on("tap click drag dragend", this._handleDrag.bind(this));
        }

        viewWillDisappear() {
            this.ratingHandler.dispose();
            this.ratingHandler = null;
            super.viewWillDisappear(...arguments);
        }

        updateRating(rating) {
            this.elements.ratingStars.each(function (index, value) {
                value.classList.toggle("star--active", index < rating);
            });
        }

        _handleDrag(e) {
            if (this.content.disabled) {
                return;
            }

            var isDragging = e.type === "drag",
                width = this.contentPlaceholder.width(),
                x = Math.max(0, Math.min(getEventPosition(e).x - this.contentPlaceholder[0].getBoundingClientRect().left, width)),
                percent = x / width * 10,
                rating = Math.round(percent);

            if ((e.type === "tap" || e.type === "click") && rating > 1) {
                // > 1 to get 0 if tapping on the first half of star 1!
                rating = Math.ceil(percent);
            }

            this.updateRating(rating); // call the delegate

            this.delegate.didChangeRating.call(this.delegate, this, this.sectionIdx, this.rowIdx, this.tableView, rating, isDragging);
        }

    }

    GUI.TableViewV2.Cells.RatingCell = RatingCell;
    return GUI;
}(window.GUI || {});
