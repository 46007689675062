'use strict';

define(['AudioZoneShortcutHelper'], function (AudioZoneShortcutHelper) {
    class AudioZoneControlQueueScreen extends GUI.TableViewScreenV2 {
        //region Private
        CLASSES = {
            IS_PLAYING: "is_playing"
        }; //endregion Private

        //region Getter
        get queue() {
            return this._queue;
        } //endregion Getter


        //region Setter
        set queue(newQueue) {
            this._queue = newQueue; //Also check if user currently changed an entry in the queue, then prevent table update from state - lead to unresponsive queue view

            if (this.isFinished && this.antiGhostTimer && !this.antiGhostTimer.isActive()) {
                clearTimeout(this.loadingViewTimeout);
                this.reloadTable().then(function () {
                    // The queue has changed, reset the editing mode for the tableView to ensure all cells are in the correct edit mode
                    // When queue is empty, we do not have tableContent
                    if (this.tableContent.length !== 0) {
                        this.tableView.setEditingModeForSection(0, true, true, this.tableContent[0].rows.length > 1);
                    }

                    this.hideSubview(this.loadingView);
                }.bind(this));
            }
        } //endregion Setter


        constructor(details) {
            super($('<div class="audio-zone-control-queue-screen" />'));
            Object.assign(this, MediaStateHandler.Mixin);
            this.control = details.control || MediaServerComp.getActiveZoneControl();
            this._queue = [];
        }

        viewDidLoad() {
            var promise = Q(super.viewDidLoad(...arguments));
            this.processWhenVisible(this.viewBecameVisible.bind(this));
            this.loadingView = new GUI.BackgroundView(Icon.INDICATOR, _("loading"), null, null, null, true);
            this.loadingView.getElement().addClass("queue-view__loading-screen");
            this.emptyQueueView = new GUI.BackgroundView(Icon.ERROR, _('controls.tracker.no-entries'), null, null, null, true);
            this.emptyQueueView.setTranslucent();
            this.appendSubview(this.loadingView);
            this.hideSubview(this.loadingView);
            this.appendSubview(this.emptyQueueView);
            this.hideSubview(this.emptyQueueView);
            return promise;
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments); //Needed to prevent State updates to the editableTableView during removing of items in current playlist

            this.antiGhostTimer = new AntiGhostTimer(this.antighostTimerFinished.bind(this), 4000);
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments); //Only show loading view when queue could not be displayed quickly

            this.loadingViewTimeout = setTimeout(this.showLoadingView.bind(this), 250);
        }

        viewWillDisappear() {
            this.antiGhostTimer.destroy();
            clearTimeout(this.loadingViewTimeout);
            this.hideSubview(this.loadingView);
            super.viewWillDisappear(...arguments);
        }

        destroy() {
            SandboxComponent.unregisterForStateChangesForUUID(this.control.uuidAction, this);

            this._unregisterForMediaServerEvents(this.EVENT_TYPES.QUEUE);

            super.destroy(...arguments);
        }

        viewBecameVisible() {
            this._registerForMediaServerEvents(this.EVENT_TYPES.QUEUE);

            SandboxComponent.registerForStateChangesForUUID(this.control.uuidAction, this, this._receivedMiniserverStates.bind(this));
        }

        showLoadingView() {
            if (this.loadingView && !this.isFinished) {
                this.showSubview(this.loadingView);
            }
        }

        receivedQueueChunkEvent(data) {
            this.isFinished = data.isFinished;

            if (data.isFinished && data.items.length === 0) {
                this.showSubview(this.emptyQueueView);
            } //replace current queue, if whe have all items


            if (data.isFinished && data.items.length === data.totalitems) {
                this.queue = data.items;
            } else {
                this.queue = this.queue.concat(data.items);
            }
        }

        antighostTimerFinished() {
            //set the current queue
            this.queue = this._queue;
        }

        receivedQueueReloadEvent(data) {
            this.isFinished = false;
            this.queue = []; // Reset the queue on the reload event, the queue will be refilled in the "receivedQueueChunkEvent" function
        }

        receivedQueueErrorEvent() {
            this.isFinished = true;
            this.queue = [];
        }

        getTableView() {
            this.tableViewDelegate.didRequestRemovingCell = this.didRequestRemovingCell.bind(this);
            return new GUI.EditableTableView(this.tableViewDataSource, this.tableViewDelegate);
        }

        getAnimation() {
            return AnimationType.FADE;
        }

        didMoveCell(section, row, tableView, cell, oldIdx, newIdx) {
            var cmdObj = {},
                rowObj;

            if (MediaServerComp.Feature.QUEUE_V2) {
                cmdObj.cmd = Commands.format(MediaEnum.AudioCommands.QUEUE_V2.MOVE_BEFORE, this.queue[oldIdx].unique_id, this.queue[newIdx].unique_id);
                cmdObj.cmd += '#' + newIdx;
            } else {
                cmdObj.cmd = MediaEnum.AudioCommands.QUEUE.MOVE + oldIdx + "/" + newIdx;
            }

            MediaServerComp.sendAudioZoneCommand(this.control.details.playerid, cmdObj);
            rowObj = this.queue.splice(oldIdx, 1);
            this.queue.splice(newIdx, 0, rowObj[0]);
            this.antiGhostTimer.fire();
        }

        didRequestRemovingCell(section, row) {
            return this.currentQueueIndex !== row;
        }

        didRemoveCell(section, row) {
            var cmdObj;

            if (MediaServerComp.Feature.QUEUE_V2) {
                cmdObj = {
                    cmd: Commands.format(MediaEnum.AudioCommands.QUEUE_V2.REMOVE, this.queue[row].unique_id)
                };
            } else {
                cmdObj = {
                    cmd: MediaEnum.AudioCommands.QUEUE.REMOVE + row
                };
            }

            this.antiGhostTimer.fire();
            MediaServerComp.sendAudioZoneCommand(this.control.details.playerid, cmdObj);
        }

        getURL() {
            return "queue";
        }

        setTableContent() {
            var queueSection = this._getQueueSection();

            this.tableContent = [];

            if (queueSection.rows.length && this.isFinished) {
                this.tableContent.push(queueSection);
            }

            return super.setTableContent(...arguments);
        }

        // ---------------------
        // Private Section
        // ---------------------
        _receivedMiniserverStates(states) {
            var prevQIdx = this.currentQueueIndex;
            this.isPlaying = states.isPlaying;

            if (this.currentQueueIndex !== states.queueIndex) {
                this.currentQueueIndex = states.queueIndex;
                this.setTableContent().then(function () {
                    this.tableView.processWhenVisible(function () {
                        // Manually re-style the cell to prevent calling the reload or selective reload function.
                        // These functions are way to unperformant and will trigger many re-paints which will result in flickering!
                        var previousSelectedCell = this.tableView.cellForSectionAndRow(0, prevQIdx),
                            newSelectedCell = this.tableView.cellForSectionAndRow(0, this.currentQueueIndex);

                        if (previousSelectedCell) {
                            previousSelectedCell.content.titleColor = Color.WHITE;
                            previousSelectedCell.setTitleColor(previousSelectedCell.content.titleColor);
                            previousSelectedCell.content.removeButtonIconSrc = Icon.REMOVE;
                            previousSelectedCell.setDeleteButtonIcon(previousSelectedCell.content.removeButtonIconSrc);
                            previousSelectedCell.getElement().removeClass(this.CLASSES.IS_PLAYING);
                        }

                        if (newSelectedCell) {
                            newSelectedCell.content.titleColor = window.Styles.colors.green;
                            newSelectedCell.setTitleColor(newSelectedCell.content.titleColor);
                            newSelectedCell.content.removeButtonIconSrc = Icon.AudioZone.NEW.IS_PLAYING;
                            newSelectedCell.setDeleteButtonIcon(newSelectedCell.content.removeButtonIconSrc);
                            newSelectedCell.getElement().addClass(this.CLASSES.IS_PLAYING);
                        }
                    }.bind(this));
                }.bind(this));
            }
        }

        _getQueueSection() {
            var section = {
                    rows: []
                },
                texts;
            section.rows = this.queue.map(function (content, idx) {
                texts = MediaServerComp.getTitleSubtitleForItem(content);
                var row = {
                    content: {
                        title: decodeURIComponent(texts.title),
                        subtitle: decodeURIComponent(texts.subtitle),
                        active: true,
                        clickable: true,
                        forceSelection: true
                    },
                    action: function (cell, section, row, tableView) {
                        if (MediaServerComp.Feature.QUEUE_V2) {
                            MediaServerComp.sendAudioZoneCommand(this.control.details.playerid, {
                                cmd: Commands.format(MediaEnum.AudioCommands.QUEUE_V2.PLAY_ID, this.queue[row].unique_id)
                            });
                        } else {
                            MediaServerComp.sendAudioZoneCommand(this.control.details.playerid, {
                                cmd: MediaEnum.AudioCommands.QUEUE.JUMP_TO + row
                            });
                        }
                    }.bind(this)
                };

                if (this.isPlaying && this.currentQueueIndex === idx) {
                    row.content.titleColor = window.Styles.colors.green;
                    row.content.removeButtonIconSrc = Icon.AudioZone.NEW.IS_PLAYING;
                    row.content.class = this.CLASSES.IS_PLAYING;
                }

                return row;
            }.bind(this));
            return section;
        }

        _hasQueueChanged(left, right) {
            return JSON.stringify(left) === JSON.stringify(right);
        }

    }

    Controls.AudioZoneControl.AudioZoneControlQueueScreen = AudioZoneControlQueueScreen;
    return Controls.AudioZoneControl.AudioZoneControlQueueScreen;
});
