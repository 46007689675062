import * as React from "react"
import Svg, { Path } from "react-native-svg"

function ArrowUpCircled(props) {
    return (
        <Svg
            isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M16.207 9.793l-3.5-3.5a1 1 0 00-1.414 0l-3.5 3.5a1 1 0 001.414 1.414L11 9.414V17a1 1 0 102 0V9.414l1.793 1.793a1 1 0 001.414-1.414z"
            />
            <Path
                d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12zm-2 0c0-5.523-4.477-10-10-10S2 6.477 2 12s4.477 10 10 10 10-4.477 10-10z"
            />
        </Svg>
    )
}

export default ArrowUpCircled
