define([], function () {
    /**
     * convenience wrapper for a setting
     * @param data
     * @constructor
     */
    function ControlSetting(data) {
        this._data = data;
        this.id = data.id;
        this.name = data.name;
        this.format = data.format;
        this.value = data.value;
        this.help = data.help;

        switch (this.format) {
            case ControlSettingType.NUMBER:
                this.stepValue = data.stepValue;

                if (typeof data.minValue === "number" && typeof data.maxValue === "number") {
                    this.minValue = data.minValue;
                    this.maxValue = data.maxValue;
                }

                break;

            case ControlSettingType.RADIO:
                this.validValues = data.validValues;
                break;

            case ControlSettingType.BOOLEAN:
                if (data.subSettings instanceof Array) {
                    this.subSettings = data.subSettings;
                }

                break;

            default:
                break;
        }
    }

    ControlSetting.prototype.isLocked = function isLocked() {
        return !!this._data.locked;
    };
    /**
     * returns true if the setting is a custom parameter or an input
     * if also locked is true, it means, that this value is not overwritten by logic
     * it simply can't be changed (setting) or it's an input, no parameter
     * @returns {boolean}
     */


    ControlSetting.prototype.isCustomParameter = function isCustomParameter() {
        return this.id >= 300 && this.id < 400;
    };

    ControlSetting.prototype.userfriendlyValue = function userfriendlyValue(value) {
        value = value !== undefined ? value : this.value;

        switch (this.format) {
            case ControlSettingType.BOOLEAN:
                return value ? _('yes') : _('no');

            case ControlSettingType.NUMBER:
                if (this.id === ControlSettingID.ROOM_SIZE) {
                    return parseFloat(value) + " " + ActiveMSComponent.getSquareMeasure();
                }

                return parseFloat(value) + "";

            case ControlSettingType.STRING:
                return value;

            case ControlSettingType.RADIO:
                if (this.id === ControlSettingID.CATEGORY_COLOR || this.id === ControlSettingID.IMAGE) {
                    return "";
                } else if (this.id === ControlSettingID.DEFAULT_ICON && value !== "") {
                    // "" --> "nicht verwenden", show name
                    return "";
                }

                for (var i = 0; i < this.validValues.length; i++) {
                    if (this.validValues[i].id == value && // don't use === (value may be a number as a string due to verify command)
                        typeof this.validValues[i].name === "string") {
                        return this.validValues[i].name;
                    }
                }

                return "";

            default:
                return NOT_AVAILABLE;
        }
    };
    /**
     * compares the value with the value of the settin itself
     * @param value
     * @returns {boolean} if values are equal
     */


    ControlSetting.prototype.compareValue = function compareValue(value) {
        switch (this.format) {
            case ControlSettingType.BOOLEAN:
            case ControlSettingType.STRING:
            case ControlSettingType.RADIO:
                return value === this.value;

            case ControlSettingType.NUMBER:
                return parseFloat(value) === this.value;

            default:
                return false;
        }
    };
    /**
     * returns true if this setting is a rating setting
     * @returns {boolean}
     */


    ControlSetting.prototype.isRatingSetting = function isRatingSetting() {
        return this.id === ControlSettingID.RATING_CONTROLS || this.id === ControlSettingID.RATING_GROUPS;
    };

    return ControlSetting;
});
