'use strict';

define(['DaytimerCalendarEntries', 'DaytimerControlEnums', 'DaytimerControlContent', 'DaytimerOperationModeBar', 'DaytimerControlContentOperatingModes', 'DaytimerSplitOperationModeView'], function (DaytimerCalendarEntries, DaytimerControlEnums, DaytimerControlContent, DaytimerOperationModeBar, DaytimerControlContentOperatingModes, DaytimerSplitOperationModeView) {
    return class DaytimerControlContentCalendarHD extends Controls.ControlContent {
        get reactHeaderShown() {
            return false
        }

        constructor(details) {
            super(details);
            this.element.addClass("daytimer-control-content-calendar-hd");
            this.calendarVisible = true;
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                if (!this.elements) {
                    this.elements = {};
                }

                this.registerForResize();
                this.opModeBar = new DaytimerOperationModeBar(this.control);
                this.opModeBar.getElement().addClass("daytimer-control-content-calendar-hd__op-mode-bar");
                return this.appendSubview(this.opModeBar).then(function () {
                    this.opModeBar.onScroll = this._modeBarDidScroll.bind(this);
                    this.opModeBar.onModeSelected = this._modeBarSelectionChanged.bind(this);
                    var subViewsContainer = $('<div class="daytimer-control-content-calendar-hd__subviews-container"></div>');
                    this.element.append(subViewsContainer);
                    this.elements.subViewsContainer = this.element.find(".daytimer-control-content-calendar-hd__subviews-container"); // add & show the calendarEntries

                    this.calendarEntries = new DaytimerCalendarEntries(this.control, this._calendarDidScroll.bind(this));
                    this.calendarEntries.name = "FullScreenCalendarEntries";
                    return this.appendSubview(this.calendarEntries, subViewsContainer).then(function () {
                        this.loadingScreen = new GUI.LxWaitingView();
                        this.loadingScreen.getElement().addClass("daytimer-control-content-calendar-hd__loading");
                        return this.appendSubview(this.loadingScreen);
                    }.bind(this));
                }.bind(this));
            }.bind(this));
        }

        viewDidAppear() {
            Debug.Control.Daytimer.Calendar && console.log(this.name + ": viewDidAppear");
            super.viewDidAppear(...arguments);

            this._titleBar.onLeftButtonTapped = function () {
                this.ViewController.navigateBack();
                this._titleBar.onLeftButtonTapped = null; // workaround for twice calls
            }.bind(this); // add new entry button


            this._titleBar.onRightButtonTapped = function () {
                if (!this.calendarVisible && this.editModeActive) {
                    this.splitModeView && this.splitModeView.removeDeletedEntries();
                } else {
                    this.ViewController.showState(DaytimerControlEnums.ScreenState.ENTRY_DETAIL, null, {
                        control: this.control,
                        isEditMode: false
                    });
                }
            }.bind(this); // list button


            this._titleBar.listButtonTapped = function (showCalendar) {
                this._updateCalendarVisible(showCalendar);
            }.bind(this); // delete button


            this._titleBar.deleteButtonTapped = function (isEditModeActive) {
                this.editModeActive = isEditModeActive;
                this.splitModeView && this.splitModeView.setEditMode(isEditModeActive);
            }.bind(this);

            this.hideSubview(this.loadingScreen);
        }

        viewDidDisappear(viewRemainsVisible) {
            Debug.Control.Daytimer.Calendar && console.log(this.name + ": viewDidDisappear");
            super.viewDidDisappear(viewRemainsVisible);
            this._titleBar.onLeftButtonTapped = null;
            this._titleBar.onRightButtonTapped = null;
            this._titleBar.listButtonTapped = null;
            this._titleBar.deleteButtonTapped = null;
        }

        onResize() {
            Debug.Control.Daytimer.Calendar && console.log(this.name + ": onResize");
        }

        getURL() {
            return "Calendar";
        }

        getAnimation() {
            return AnimationType.HD_OVERLAY_FULLSCREEN;
        }

        titleBarText() {
            if (this.control.details.isHeating) {
                return _("controls.irc.daytimer.heating.entries");
            } else if (this.control.details.isCooling) {
                return _("controls.irc.daytimer.cooling.entries");
            } else {
                return _("controls.daytimer.entries");
            }
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK,
                rightSide: 'daytimerCalendar',
                style: 'transparent'
            };
        }

        receivedStates(states) {
            Debug.Control.Daytimer.Calendar && console.log(this.name + ": receivedStates");
        }

        _calendarDidScroll(x, y) {
            Debug.Control.Daytimer.Calendar && console.log(this.name + ": _calendarDidScroll: " + x + "/" + y);
            this.opModeBar.scrollTo(x, y);
        }

        _modeBarDidScroll(x, y) {
            Debug.Control.Daytimer.Calendar && console.log(this.name + ": _modeBarDidScroll: " + x + "/" + y);
            this.calendarEntries.scrollTo(x, y);
        }

        _modeBarSelectionChanged(modeNr) {
            Debug.Control.Daytimer.Calendar && console.log(this.name + ": _modeBarSelectionChanged: " + modeNr);
            this.selectedMode = modeNr;
            this.splitModeView && this.splitModeView.selectMode(modeNr);
        }

        _sendCommand() {
            Debug.Control.Daytimer.Calendar && console.log("_sendCommand " + JSON.stringify(arguments));
            this.sendCommand.apply(this, arguments);
        }

        _updateCalendarVisible(showCalendar) {
            if (showCalendar === this.calendarVisible) {
                return;
            }

            Debug.Control.Daytimer.Calendar && console.log(this.name + ": _updateCalendarVisible: " + showCalendar);
            this.calendarVisible = showCalendar;
            this.opModeBar.setSelectable(!showCalendar);

            if (showCalendar) {
                if (this.splitModeView) {
                    this.hideSubview(this.splitModeView);
                }

                this.showSubview(this.calendarEntries);
            } else {
                this.hideSubview(this.calendarEntries);

                if (!this.splitModeView) {
                    this._createSplitModeView();
                } else {
                    this.showSubview(this.splitModeView);
                }

                this.selectedMode && this.splitModeView.selectMode(this.selectedMode);
                this.selectedMode && this.opModeBar.selectedMode(this.selectedMode);
            }
        }

        _createSplitModeView() {
            this.splitModeView = new DaytimerSplitOperationModeView(this.control);
            this.splitModeView.sendCommand = this._sendCommand.bind(this);
            this.appendSubview(this.splitModeView, this.elements.subViewsContainer);
        }

    };
});
