'use strict';

define(['AutopilotEnums', 'AutopilotUtility'], function (AutopilotEnums, AutopilotUtility) {
    return class AutopilotEventDetailsSimpleScreen extends GUI.Screen {
        constructor(details) {
            super($('<div class="autopilot-event-details-simple-screen" />'));
            this.originalEvent = details.event;
            this.event = details.event ? JSON.parse(JSON.stringify(details.event)) : {};
            this.editMode = typeof details.event !== 'undefined';
            this.options = details.options;
            this.type = details.type;
            this.eventType = details.eventType;
            this.title = details.title;
            this.validInput = true;

            if (!this.editMode) {
                this.event.type = details.eventType;
                this.event.id = details.id;
            }
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.elements = {};
            this.tableView = new GUI.TableViewV2(this, this);
            this.appendSubview(this.tableView);
        }

        viewWillAppear() {
            this.tableView.reload();
            super.viewWillAppear(...arguments);
        }

        getURL() {
            return "Autopilot/EventDetailsSimple";
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarText() {
            return this.title;
        }

        titleBarAction() {
            if (this.editMode) {
                this._saveChanges();
            } else {
                this.ViewController.navigateBack();
            }
        }

        styleForTableView(tableView) {
            return TableViewStyle.GROUPED;
        }

        numberOfSections() {
            return 1;
        }

        contentForFooterInSection(section, tableView) {
            if (this.event.type === AutopilotEnums.EventTypes.Time) {
                var evDetails = AutopilotEnums.AllowedTimeTypes[this.event.id];

                if (evDetails.description) {
                    return {
                        iconSrc: "resources/Images/General/icon-description.svg",
                        title: _("description"),
                        message: evDetails.description
                    };
                }
            }

            if (this.event.type === AutopilotEnums.EventTypes.Weather) {
                var evDetails = AutopilotUtility.getWeatherEvents(this.event.id);
                var message = "";

                if (evDetails && evDetails.unit) {
                    message = _("unit") + ": " + evDetails.unit;
                } else {
                    var allowedWeatherType = AutopilotEnums.AllowedWeatherTypes[this.event.id];

                    if (allowedWeatherType && allowedWeatherType.description) {
                        message = allowedWeatherType.description;
                    }
                }

                if (message.length > 0) {
                    return {
                        iconSrc: "resources/Images/General/icon-description.svg",
                        title: _("description"),
                        message: message
                    };
                }
            }
        }

        numberOfRowsInSection(sectionIdx) {
            if (this.type === AutopilotEnums.EventDetailTypes.Radio) {
                return this.options.length;
            }

            var cntRows = 0;

            if (this.type === AutopilotEnums.EventDetailTypes.Time) {
                cntRows = 1;
            } else {
                cntRows = AutopilotEnums.Operators.length + 1; // + 1 for input field
            } // this adds or removes the "Add event" button


            if (!this.editMode) {
                cntRows++;
            }

            return cntRows;
        }

        cellTypeForCellAtIndex(section, row) {
            if (this.type === AutopilotEnums.EventDetailTypes.Radio) {
                if (row < this.options.length) {
                    return GUI.TableViewV2.CellType.GENERAL;
                }
            } else if (this.type === AutopilotEnums.EventDetailTypes.Time) {
                if (row === 0) {
                    return GUI.TableViewV2.CellType.TIME_PICKER;
                }
            } else {
                if (row < AutopilotEnums.Operators.length) {
                    // operator cells
                    return GUI.TableViewV2.CellType.CHECKABLE;
                } else if (row === AutopilotEnums.Operators.length) {
                    // input cell, depending on type
                    switch (this.type) {
                        case AutopilotEnums.EventDetailTypes.Number:
                        case AutopilotEnums.EventDetailTypes.DecimalNumber:
                            return GUI.TableViewV2.CellType.INPUT;

                        case AutopilotEnums.EventDetailTypes.Time:
                            return GUI.TableViewV2.CellType.TIME_PICKER;

                        case AutopilotEnums.EventDetailTypes.DateTime:
                            return GUI.TableViewV2.CellType.DATE_TIME_PICKER;
                    }
                }
            } // the last row has to be the button cell (if not in editMode)


            return GUI.TableViewV2.CellType.BUTTON_CELL_CUSTOM;
        }

        contentForCell(cell, section, row) {
            if (this.type === AutopilotEnums.EventDetailTypes.Radio) {
                // if set of finite values
                if (row < this.options.length) {
                    var option = this.options[row];
                    return {
                        title: option.title,
                        rightIconSrc: Icon.TRANSLUCENT_ADD,
                        rightIconColor: window.Styles.colors.green,
                        clickable: true
                    };
                }
            } else {
                // any kind of input expect a set of finite values
                if (this.type === AutopilotEnums.EventDetailTypes.Time) {
                    // handle special case: custom time
                    if (row === 0) {
                        var value;

                        if (this.event.value) {
                            var hour = Math.floor(this.event.value / 60);
                            var min = this.event.value % 60;
                            value = [hour, min];
                        } else {
                            var now = new Date();
                            value = [now.getHours(), now.getMinutes()];
                        }

                        this.event.value = value;
                        return {
                            title: _("mobiscroll.time"),
                            value: value,
                            expanded: true
                        };
                    } else {
                        return {
                            title: _("autopilot.rule.event.add"),
                            color: window.Styles.colors.green,
                            clickable: true
                        };
                    }
                } // after this point we handle values compared by an operator


                if (row < AutopilotEnums.Operators.length) {
                    // operator cells!
                    var operator = AutopilotEnums.Operators[row]; // as default operator use =

                    if (this.editMode && !this.event.operator) {
                        this.event.operator = AutopilotEnums.DefaultOperator;
                    }

                    return {
                        title: operator.title,
                        selected: this.event.operator === operator.id,
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED
                    };
                } else if (row === AutopilotEnums.Operators.length) {
                    // input cell, depending on type
                    switch (this.type) {
                        case AutopilotEnums.EventDetailTypes.Number:
                        case AutopilotEnums.EventDetailTypes.DecimalNumber:
                            var regex = this.type === AutopilotEnums.EventDetailTypes.Number ? Regex.INT_VALUE : Regex.DECIMAL_VALUE;
                            return {
                                type: "text",
                                value: this.event.value || "",
                                validationRegex: regex,
                                placeholder: _("autopilot.rule.action.placeholder-value")
                            };
                        // the following part (time) is commented out, because we removed the comparison by operator
                        // maybe we need this in future (e. g. xx:xx > 12:00)

                        /*case AutopilotEnums.EventDetailTypes.Time:
                         var value;
                         if (this.event.value) {
                         var hour = Math.floor(this.event.value / 60);
                         var min = this.event.value % 60;
                         value = [hour, min];
                         }
                         else {
                         var now = new Date();
                         value = [now.getHours(), now.getMinutes()];
                         }
                          this.event.value = value;
                          return {
                         iconSrc: Icon.AlarmClock.ALARM_CLOCK,
                         title: _("mobiscroll.time"),
                         value: value
                         };*/
                        // the following case DateTime is not used at the moment!

                        case AutopilotEnums.EventDetailTypes.DateTime:
                            var now = new moment();
                            var momentValue = new moment(this.event.value * 1000);
                            var value = momentValue || now;
                            this.event.value = value;
                            return {
                                iconSrc: Icon.AlarmClock.ALARM_CLOCK,
                                title: _("mobiscroll.date"),
                                value: value
                            };
                    }
                }
            } // return button content


            return {
                title: _("autopilot.rule.event.add"),
                color: window.Styles.colors.green,
                clickable: true
            };
        }

        didCheckCell(cell, sectionIdx, row) {
            this.event.operator = AutopilotEnums.Operators[row].id;
        }

        didSelectCell(cell, sectionIdx, row) {
            if (this.type === AutopilotEnums.EventDetailTypes.Radio) {
                this.event.value = this.options[row].value;
            }

            this._saveChanges();
        }

        textChanged(section, row, tableView, value, valid, valueDidChange) {
            this.event.value = value;
            this.validInput = valid;
        }

        onPickerChanged(section, row, tableView, value) {
            this.event.value = value;
        }

        _validateInputs(sectionObj) {
            if (this.type === AutopilotEnums.EventDetailTypes.Radio) {
                if (typeof this.event.value === 'undefined') {
                    return _("input.no-value-selected");
                }
            } else {
                if (!this.validInput) {
                    if (this.type === AutopilotEnums.EventDetailTypes.Number) {
                        return _("input.number-required");
                    } else {
                        return _("input.decimal-number-required");
                    }
                } // on plain input -> also an operator has to be selected


                if (typeof this.event.value === 'undefined') {
                    return _("input.no-input-value");
                }

                if (!this.event.operator && this.type !== AutopilotEnums.EventDetailTypes.Time) {
                    return _("input.no-comparison-operator");
                } // check if value is outside the valid range


                if (sectionObj && sectionObj.rows.length > 0 && (sectionObj.rows[0].hasOwnProperty("minValue") || sectionObj.rows[0].hasOwnProperty("maxValue"))) {
                    var min = sectionObj.rows[0].minValue;
                    var max = sectionObj.rows[0].maxValue;

                    if (this.event.value < min || this.event.value > max) {
                        return _("input.out-of-range") + " (" + min + " - " + max + ")";
                    }
                }
            }

            return true;
        }

        _saveChanges() {
            var validationResult = this._validateInputs();

            if (validationResult === true) {
                if (this.type === AutopilotEnums.EventDetailTypes.Time) {
                    delete this.event.id;
                    this.event.value = this.event.value[0] * 60 + this.event.value[1];
                }

                if (this.editMode) {
                    AutopilotUtility.copyValues(this.event, this.originalEvent);
                    NavigationComp.dispatchEventToUI(AutopilotEnums.CommunicationEvents.UPDATE_EVENT, this.originalEvent);
                } else {
                    NavigationComp.dispatchEventToUI(AutopilotEnums.CommunicationEvents.ADD_EVENT, this.event);
                    NavigationComp.disableAnimationsTemp();
                }

                this.ViewController.navigateBackTo(AutopilotEnums.ScreenStates.AutopilotRuleScreen);
            } else {
                return AutopilotUtility.showValidationError(validationResult);
            }
        }

    };
});
