"use strict";

define("PoolControlContentSettings", ["PoolControlEnums", "IRoomControlContentTemperatureList"], function (PoolControlEnums) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    // Martin via E-Mail
    var MIN_TARGET_TEMP = 15;
    var MAX_TARGET_TEMP = 40;
    var STEP_TARGET_TEMP = 0.5; // Section Indexes

    var SectionIndex = {
        OPERATING_MODES: 0,
        DAYTIMER: 1,
        BACKWASH_AND_RINSING_TIMES: 2,
        TEMPERATURE_CONTROL: -1,
        MONITORING: 3,
        STATISTIC: -1
    };
    return class PoolControlContentSettings extends Controls.ControlContent {
        constructor(details) {
            super(details);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.tableView = new GUI.TableViewV2(this, this);
            this.tableView.getElement().addClass("pool-control-content-settings__table-view");
            this.appendSubview(this.tableView);
        }

        titleBarText() {
            return _("settings");
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK,
                style: "transparent"
            };
        }

        getURL() {
            return "Settings";
        }

        receivedStates(states) {
            // Navigate back if there is an error, or if the opMode has changed to ServiceMode
            // All important information will be shown in the controlContent
            if ((this.control.hasError() || states.currentOpModeNr === PoolControlEnums.PoolMode.SERVICE_MODE) && !this.blockBackNavigation) {
                this.blockBackNavigation = true; // Navigate back to the ControlContent (Its the root of the current VC)
                // Note: This also hides all screens currently present over this screen!

                this.ViewController.navigateBackToRoot();
                return;
            }

            this.cycleMode = states.currentCycleNr; // We need to reload the tableView if
            // the operating or temperature mode changed

            if (this.activeOperatingMode !== states.currentOpModeNr || this.activeTempMode !== states.currentTempModeNr) {
                this.activeOperatingMode = states.currentOpModeNr;
                this.activeTempMode = states.currentTempModeNr;

                this._prepareTableViewContent(states);

                this.tableView.reload();
            } else {
                // always update times (in every pool-mode)
                var timeArrays = this._getTimeArraysOfSeconds(states.backwashTime, states.rinseTime);

                var backWashCell = this.tableView.cellForSectionAndRow(SectionIndex.BACKWASH_AND_RINSING_TIMES, 0);
                var rinsingCell = this.tableView.cellForSectionAndRow(SectionIndex.BACKWASH_AND_RINSING_TIMES, 1);
                backWashCell.setValue(timeArrays.backwash);
                rinsingCell.setValue(timeArrays.rinse);

                this._prepareTableViewContent(states); // timer
                // The following cell only exists if the service mode is automatic


                if (states.currentOpModeNr === PoolControlEnums.PoolMode.AUTOMATIC) {
                    // We only need to reload the last row if the subtitle (the time) has changed
                    // Its either undefined or "Remaining Time 04&nbsp;h&nbsp;55&nbsp;min" with decreasing time.
                    if (this.tableViewContent[SectionIndex.DAYTIMER].cells[1].content.subtitle !== this.prevSubtitle) {
                        this.tableView.reloadRowsSelective(SectionIndex.DAYTIMER, 1, 1);
                        this.prevSubtitle = this.tableViewContent[SectionIndex.DAYTIMER].cells[1].content.subtitle;
                    }
                } // temperature


                if (this.control.hasTemperatureControl(this.control)) {
                    var tempModeCell = this.tableView.cellForSectionAndRow(SectionIndex.TEMPERATURE_CONTROL, 0);

                    var discText = this._getTemperatureModeCellDiscText(states);

                    var disc1Text = this._getTemperatureModeCellDisc1Text(states);

                    tempModeCell.setDisclosureText(discText, disc1Text);
                    var idx = 1;

                    if (this.control.details.hasEco && this.control.isTempModeAutomatic(states.currentTempModeNr)) {
                        if (this.control.statistic) {
                            idx++;
                        }

                        var ecoCell = this.tableView.cellForSectionAndRow(SectionIndex.TEMPERATURE_CONTROL, idx);
                        ecoCell.setDisclosureText(states.ecoActive ? _("active") : _("inactive"));
                        ecoCell.setDisclosureTextColor(states.ecoActive ? window.Styles.colors.stateActive : Color.STATE_INACTIVE);
                    }

                    if (this.control.isTempModeAutomatic(states.currentTempModeNr)) {
                        idx++;
                        var tempSliderCell = this.tableView.cellForSectionAndRow(SectionIndex.TEMPERATURE_CONTROL, idx);
                        tempSliderCell.setPosition(states.targetTemp);
                    }
                } // Here we update the monitoring section


                var monitoringCells = this.tableViewContent[SectionIndex.MONITORING].cells.length;
                this.tableView.reloadRowsSelective(SectionIndex.MONITORING, 0, monitoringCells);
            }
        }

        // TableViewDataSource methods
        styleForTableView(tableView) {
            return HD_APP ? TableViewStyle.GROUPED : null;
        }

        titleForHeaderInSection(section) {
            return this.tableViewContent[section].sectionTitle;
        }

        numberOfSections() {
            return this.tableViewContent.length;
        }

        numberOfRowsInSection(section) {
            return this.tableViewContent[section].cells.length;
        }

        cellTypeForCellAtIndex(section, row) {
            return this.tableViewContent[section].cells[row].cellType;
        }

        contentForCell(cell, section, row) {
            return this.tableViewContent[section].cells[row].content;
        }

        // TableViewDelegate methods
        didSelectCell(cell, section, row) {
            if (section === SectionIndex.OPERATING_MODES) {
                this._showState(PoolControlEnums.ScreenState.OPERATING_MODES);
            } else if (section === SectionIndex.DAYTIMER) {
                if (row === 0) {
                    var details = {
                        control: this.control.subControls[Object.keys(this.control.subControls)[0]]
                    };

                    this._showState(PoolControlEnums.ScreenState.CALENDAR, details);
                } else if (row === 1) {
                    this._showState(PoolControlEnums.ScreenState.TIMER);
                }
            } else if (section === SectionIndex.TEMPERATURE_CONTROL) {
                if (row === 0) {
                    this._showState(PoolControlEnums.ScreenState.TEMPERATURE_MODE_LIST);
                } else {
                    this.showStatistic();
                }
            } else if (section === SectionIndex.STATISTIC) {
                this.showStatistic();
            }
        }

        onPickerChanged(section, row, tableView, value) {
            var seconds = value[1] * 60 + value[2];

            if (row === 0) {
                this.sendCommand(Commands.format(Commands.POOL.SET_BACKWASH_TIME, seconds));
            } else if (row === 1) {
                this.sendCommand(Commands.format(Commands.POOL.SET_RINSE_TIME, seconds));
            }
        }

        sliderDragged(cell, section, row, tableView, value) {
            this.sendCommand(Commands.format(Commands.POOL.SET_TARGET_TEMP, value), Commands.Type.OVERRIDE);
        }

        buttonReleased() {
            this._cancelCycleMode();
        }

        buttonTapped() {
            this._cancelCycleMode();
        }

        // Private methods
        _cancelCycleMode() {
            if (this.cycleMode === PoolControlEnums.PoolCycleMode.FLUSHING) {
                // Why 2 Parameters? Because we need to cancel both timers.
                // The backwash and rinse timer
                this.sendCommand(Commands.format(Commands.POOL.START_TIMER, this.cycleMode, 1 + '/' + 1));
            } else {
                this.sendCommand(Commands.format(Commands.POOL.START_TIMER, this.cycleMode, 1));
            }
        }

        _prepareTableViewContent(states) {
            var timeArrays = this._getTimeArraysOfSeconds(states.backwashTime, states.rinseTime);

            var isAutomaticMode = states.currentOpModeNr === PoolControlEnums.PoolMode.AUTOMATIC;
            this.tableViewContent = [{
                sectionTitle: _("pool.control.maintenance-and-cleaning"),
                cells: [this._getModeSelectionCell(states.currentOpModeNr)]
            }, {
                sectionTitle: "",
                cells: [{
                    cellType: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        title: _("pool.control.cycle-switching-time"),
                        disclosureIcon: true,
                        leftIconSrc: "resources/Images/Controls/Pool/icon-switching_times.svg"
                    }
                }]
            }, {
                sectionTitle: _("pool.control.times-back-and-clearwash"),
                cells: [{
                    cellType: GUI.TableViewV2.CellType.DURATION_PICKER,
                    content: {
                        title: _("pool.control.back-wash"),
                        iconSrc: "resources/Images/Controls/Pool/icon-duration.svg",
                        value: timeArrays.backwash,
                        minTime: this.control.details.backwashBounds.min * 1000,
                        maxTime: this.control.details.backwashBounds.max * 1000,
                        wheelOrder: "iiss"
                    }
                }, {
                    cellType: GUI.TableViewV2.CellType.DURATION_PICKER,
                    content: {
                        title: _("pool.control.clear-wash"),
                        iconSrc: "resources/Images/Controls/Pool/icon-duration.svg",
                        value: timeArrays.rinse,
                        minTime: this.control.details.rinseBounds.min * 1000,
                        maxTime: this.control.details.rinseBounds.max * 1000,
                        wheelOrder: "iiss"
                    }
                }]
            }, {
                sectionTitle: _("controls.pool.settings.monitoring"),
                cells: [{
                    cellType: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        title: _("pool.control.valve-position"),
                        disclosureText: this.control.getNameOfPoolValvePositions(states.valvePosition)
                    }
                }, {
                    cellType: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        title: _("pool.control.drain-valve"),
                        disclosureText: states.drainValve ? _("opened") : _("closed")
                    }
                }]
            }];

            if (this.control.hasTemperatureControl(this.control)) {
                var temperatureSection = {
                    sectionTitle: _("pool.control.temp-control"),
                    cells: [{
                        cellType: GUI.TableViewV2.CellType.GENERAL,
                        content: {
                            title: _("pool.control.mode"),
                            disclosureIcon: isAutomaticMode,
                            disclosureText: this._getTemperatureModeCellDiscText(states),
                            disclosureColor: isAutomaticMode ? '' : window.Styles.colors.red,
                            disclosureText1: this._getTemperatureModeCellDisc1Text(states),
                            disclosureColor1: window.Styles.colors.stateActive
                        }
                    }]
                }; // We always have to insert this section at index 3!

                this.tableViewContent.splice(3, 0, temperatureSection);
                SectionIndex.TEMPERATURE_CONTROL = 3;
                SectionIndex.MONITORING = 4;
            } else {
                SectionIndex.TEMPERATURE_CONTROL = -1;
                SectionIndex.MONITORING = 3;
            }

            if (this.control.statistic) {
                var statCell = {
                    cellType: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        title: _("statistics.button.title"),
                        disclosureIcon: true,
                        leftIconSrc: "resources/Images/Controls/Statistic/chart.svg"
                    }
                };

                if (this.control.hasTemperatureControl(this.control)) {
                    this.tableViewContent[SectionIndex.TEMPERATURE_CONTROL].cells.push(statCell);
                } else {
                    var statisticSection = {
                        cells: [statCell]
                    }; // We always have to insert this section at index 3!

                    this.tableViewContent.push(statisticSection);
                    SectionIndex.STATISTIC = 4;
                }
            } else {
                SectionIndex.STATISTIC = -1;
            }

            if (this.activeOperatingMode === PoolControlEnums.PoolMode.AUTOMATIC) {
                var cell;

                if (states.isCycleAbortable) {
                    var title = this.control.getNameOfPoolCycleMode(states.currentCycleNr) + " " + _("active");

                    var subtitle = _("controls.pool.time-remaining") + " " + LxDate.formatSecondsShort(states.remainingTime);
                    var iconName = this.control.getIconNameOfPoolCycleMode(states.currentCycleNr);
                    cell = {
                        cellType: GUI.TableViewV2.CellType.BUTTON,
                        content: {
                            iconSrc: "resources/Images/Controls/Pool/" + iconName + ".svg",
                            iconColor: this.control.getColorOfPoolCycleMode(states.currentCycleNr),
                            title: title,
                            subtitle: subtitle,
                            buttonSrc: "resources/Images/General/close.svg"
                        }
                    };
                } else {
                    cell = {
                        cellType: GUI.TableViewV2.CellType.GENERAL,
                        content: {
                            title: _("controls.pool.timer"),
                            subtitle: states.valvePosition === -1 ? _('pool.control.valve-pos.valve-moves') : null,
                            disclosureIcon: true,
                            rightIconSrc: "resources/Images/Controls/Pool/button-timer.svg"
                        }
                    };
                }

                this.tableViewContent[SectionIndex.DAYTIMER].cells.push(cell);

                if (this.control.hasTemperatureControl(this.control) && this.control.isTempModeAutomatic(this.activeTempMode)) {
                    if (this.control.details.hasEco) {
                        this.tableViewContent[SectionIndex.TEMPERATURE_CONTROL].cells.push({
                            cellType: GUI.TableViewV2.CellType.GENERAL,
                            content: {
                                leftIconSrc: "resources/Images/Controls/Pool/icon-eco_mode.svg",
                                leftIconColor: window.Styles.colors.green,
                                title: _("pool.control.eco"),
                                disclosureText: states.ecoActive ? _("active") : _("inactive"),
                                disclosureColor: states.ecoActive ? window.Styles.colors.stateActive : Color.STATE_INACTIVE
                            }
                        });
                    } // always show target temp in automatic mode


                    this.tableViewContent[SectionIndex.TEMPERATURE_CONTROL].cells.push({
                        cellType: GUI.TableViewV2.CellType.SLIDER,
                        content: {
                            title: _("pool.control.target-water-temp"),
                            leftIconSrc: "resources/Images/Controls/Pool/icon-target_temp.svg",
                            value: states.targetTemp,
                            minValue: SandboxComponent.getTemperatureForUI(MIN_TARGET_TEMP),
                            maxValue: SandboxComponent.getTemperatureForUI(MAX_TARGET_TEMP),
                            step: STEP_TARGET_TEMP,
                            format: this.control.details.format
                        }
                    });
                }
            }
        }

        _getTimeArraysOfSeconds(backwash, rinse) {
            var backwashMin = parseInt(backwash / 60);
            var backwashSec = backwash % 60;
            var rinseMin = parseInt(rinse / 60);
            var rinseSec = rinse % 60;
            return {
                backwash: [undefined, backwashMin, backwashSec],
                rinse: [undefined, rinseMin, rinseSec]
            };
        }

        _getTemperatureModeCellDiscText(states) {
            var isAutomaticMode = states.currentOpModeNr === PoolControlEnums.PoolMode.AUTOMATIC;
            var disclosureText = "";

            if (isAutomaticMode) {
                disclosureText = this.control.getNameOfPoolTempMode(states.currentTempModeNr);
            } else {
                disclosureText = _("pool.control.deactivated");
            }

            return disclosureText;
        }

        _getTemperatureModeCellDisc1Text(states) {
            var disclosureText1 = "";

            if (states.heatingApproved) {
                disclosureText1 = _("pool.control.heating-active");
            } else if (states.coolingApproved) {
                disclosureText1 = _("pool.control.cooling-active");
            }

            return disclosureText1;
        }

        _showState(viewType, details) {
            details = details || {};
            details.contentDetails = details;
            details.control = details.control || this.control;
            this.ViewController.showState(viewType, null, details);
        }

        _getModeSelectionCell(currMode) {
            var isOutOfOrder = currMode === PoolControlEnums.PoolMode.OUT_OF_ORDER,
                changable = !isOutOfOrder || this.control.supportsOutOfServiceViaApp(),
                subtitle = null;

            if (this.control.details.hasResetConnected === true) {
                subtitle = _("controlled-via-logic");
            }

            return {
                cellType: GUI.TableViewV2.CellType.GENERAL,
                content: {
                    title: _("pool.control.settings.operating-mode"),
                    subtitle: subtitle,
                    disclosureIcon: changable,
                    disclosureText: this.control.getNameOfPoolMode(currMode)
                }
            };
        }

    };
});
