'use strict';
/**
 * This screen offers to select an option from a list.
 * Optionally it provides access to timerUntilOptionsScreen via a timer-Button.
 *
 *
 * A selection made in this screen is reported
 *      -   via the onSelectionChanged(index, option) method right away
 *      -   via the onSelected(index, option) method when the screen is closed.
 *
 * When a timer is provided and the timer was started:
 *      -   via the OnTimerStarted(time, timerOption, timerAfterOption) method (see callbackFn in timerUntilOptionsScreen)
 *
 *
 * details
 *      title                   title of the screen
 *      [onSelectionChanged]    function to call when a selection has been made
 *      [onSelected]            function to call when a selection has been made and the screen closes
 *      options                 an array of options too choose from
 *          [option]            the option selected will be returned using the callbackFn as second argument.
 *                              Must contain at least a title!
 *                              May contain a selected attribute.
 *      [timerButtonTitle]  title of the button of the timer
 *      [timerDetails]      see TimerUntilOptionsScreen for details.
 *      [onTimerStarted]    returns the items selected in the timerUntilScreen (see callbackFn in timerUntilOptionsScreen)
 *
 *
 */

define([], function () {
    return class SelectorTimerScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($('<div />'));
            this.details = details;
        }

        titleBarText() {
            return this.details.title;
        }

        reloadTable() {
            this.tableContent = this._getContent();
            return super.reloadTable(...arguments);
        }

        closeAction() {
            if (this.selectedOption && this.details.onSelected) {
                this.details.onSelected(this.selectedOptionIndex, this.selectedOption);
            }

            return super.closeAction(...arguments);
        }

        _getContent() {
            var content = [],
                timerSection,
                optionsSection = {
                    rows: this._getOptionRows()
                };
            content.push(optionsSection);

            if (this.details.timerDetails) {
                timerSection = {
                    rows: []
                };
                timerSection.rows.push({
                    type: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        title: this.details.timerButtonTitle || _("steak.timer.start"),
                        clickable: true,
                        rightIconSrc: Icon.TimedSwitch.ICON,
                        rightIconColor: Color.TEXT_B
                    },
                    action: this._showTimerUntilOptionsScreen.bind(this)
                });
                content.push(timerSection);
            }

            return content;
        }

        _getOptionRows() {
            var rows = [];
            this.details.options && this.details.options.forEach(function (option) {
                rows.push(this._createOptionCell(option));
            }.bind(this));
            return rows;
        }

        _createOptionCell(option) {
            return {
                type: GUI.TableViewV2.CellType.CHECKABLE,
                content: {
                    title: option.title,
                    selected: option.selected,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED
                },
                didCheckCell: function (cell, section, row, tableView, selected) {
                    this._optionSelected(row, option);
                }.bind(this)
            };
        }

        _optionSelected(index, option) {
            this.details.options.forEach(function (option, oIdx) {
                option.selected = index === oIdx;
            }.bind(this));
            this.details.onSelectionChanged && this.details.onSelectionChanged(index, option);
            this.selectedOption = this.details.options[index];
            this.selectedOptionIndex = index;
        }

        _showTimerUntilOptionsScreen() {
            var timerDetails = this.details.timerDetails;
            timerDetails.callbackFn = this._handleTimerStarted.bind(this);
            timerDetails.title = timerDetails.title || _("steak.timer.start");
            this.ViewController.showState(ScreenState.TIMER_UNTIL_OPTIONS_SCREEN, null, timerDetails);
        }

        _handleTimerStarted(arg1, arg2, arg3) {
            // reset selected options, no selection is reported when a timer has been started.
            this.selectedOption = null;
            this.selectedOptionIndex = -1;
            this.details.onTimerStarted && this.details.onTimerStarted(arg1, arg2, arg3);
            this.closeAction();
        }

    };
});
