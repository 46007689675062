import Svg, { Path } from "react-native-svg"

function Gear(props) {
    return (
        <Svg isreact="true"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            xmlSpace="preserve"
            {...props}
        >
            <Path d="M62.4 25.8h-2.8c-2 0-4.2-1.4-4.9-3.2-.7-1.8-.2-4.3 1.2-5.8l2-2c.6-.6.6-1.6 0-2.3L51.4 6c-.3-.3-.7-.5-1.2-.5-.4 0-.8.2-1.1.5l-2 2c-1 1-2.5 1.6-4 1.6-.6 0-1.2-.1-1.8-.3-1.8-.7-3.2-2.9-3.2-4.9V1.6c0-.9-.7-1.6-1.6-1.6h-9.2c-.9 0-1.6.7-1.6 1.6v2.8c0 2-1.4 4.2-3.2 4.9-.5.2-1.1.3-1.8.3-1.5 0-3-.6-4-1.6l-2-2c-.3-.3-.7-.5-1.1-.5-.4 0-.8.2-1.1.5L6 12.5c-.6.6-.6 1.6 0 2.3l2 2c1.4 1.4 2 4 1.3 5.8s-2.9 3.2-4.9 3.2H1.6c-.9 0-1.6.7-1.6 1.6v9.2c0 .9.7 1.6 1.6 1.6h2.8c2 0 4.2 1.4 4.9 3.2s.2 4.3-1.3 5.7l-2 2c-.6.6-.6 1.7 0 2.3l6.5 6.5c.3.3.7.5 1.1.5.4 0 .8-.2 1.1-.5l2-2c1-1 2.5-1.6 4-1.6.6 0 1.2.1 1.8.3 1.8.7 3.2 2.9 3.2 4.9v2.8c0 .9.7 1.6 1.6 1.6h9.2c.9 0 1.6-.7 1.6-1.6v-2.8c0-2 1.4-4.2 3.2-4.9.5-.2 1.1-.3 1.8-.3 1.5 0 3 .6 4 1.6l2 2c.3.3.7.5 1.1.5.4 0 .8-.2 1.1-.5l6.5-6.5c.6-.6.6-1.7 0-2.3l-2-2c-1.4-1.4-2-4-1.2-5.7.7-1.8 2.9-3.2 4.9-3.2h2.8c.9 0 1.6-.7 1.6-1.6v-9.2c.1-.9-.6-1.6-1.5-1.6zM32 50.2C21.9 50.2 13.8 42 13.8 32c0-10.1 8.2-18.2 18.2-18.2 10.1 0 18.2 8.1 18.2 18.2S42.1 50.2 32 50.2z" />
            <Path d="M32 45.1c-7.2 0-13.1-5.9-13.1-13.1S24.8 18.9 32 18.9 45.1 24.7 45.1 32c0 7.2-5.9 13.1-13.1 13.1z" />
        </Svg>
    )
}

export default Gear
