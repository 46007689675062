'use strict';
/*
 type (text)            --> Fixed to password
 leftIconSrc
 title
 value
 placeholder
 autoFocus (boolean)
 validationRegex

 // InputCell methods
 focus()    // focuses the input

 // InputCell delegate
 textChanged(section, row, tableView, value, valid, valueDidChange) // TODO adopt to our global syntax! (+cell, +tableView!), name "inputCellValueChanged"
 submitText(section, row, tableView, value, valid) // TODO adopt to our global syntax! (+cell, +tableView!), name "inputCellValueChanged"
 // TODO add onSubmit function (onkeyup -> enter?)
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.PASS_INPUT = "PasswordInputCell";

    class InputCell extends GUI.TableViewV2.Cells.InputCell {
        constructor() {
            super(...arguments);
            this.element.addClass("password-input-cell");
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.strengthIndicator = this.element.find(".lx-input__right-icon");

                this._setStrengthIndicatorToDefault();
            }.bind(this));
        }

        setContent(content) {
            content.type = "password";
            return super.setContent(...arguments);
        }

        textChanged(value, valid, valueDidChange) {
            return Q(super.textChanged(...arguments)).then(result => {
                this._checkAndHandlePasswordStrength(value, result); // the delegate may set a strenght differently
                return result;
            });
        }

        submitText(value, valid, valueDidChange) {
            this._updatePasswordStrength(value);

            return super.submitText(...arguments);
        }

        _checkAndHandlePasswordStrength(value, extStrength) {
            if (Object.values(UserManagement.PWD_STRENGTH).some(strengthValue => {
                return strengthValue === extStrength;
            })) {
                this._updatePasswordStrength(value, extStrength, true);
            } else {
                this._updatePasswordStrength(value);
            }
        }

        /**
         * Will detect the password strength and adopt the indicator level according.
         * @param value     the current value in the password field
         * @param [extScore] potentially provided
         * @param [useExtScore] indicator that the extenal score should be used.
         * @private
         */
        _updatePasswordStrength(value, extScore, useExtScore) {
            var promises = [];

            if (value === "") {
                promises.push(this._setStrengthIndicatorToDefault());
            } else {
                var strength = useExtScore ? extScore :PasswordEval.evaluatePassword(value, 3);
                promises.push(this._setStrengthIndicatorToDefault());

                switch (strength) {
                    case 0:
                        promises.push(this._updIndicatorAction(1, window.Styles.colors.red));
                        this.strengthIndicator.find("path:lt(1)").css("fill", window.Styles.colors.red);
                        break;

                    case 1:
                        promises.push(this._updIndicatorAction(2, window.Styles.colors.orange));
                        break;

                    case 2:
                        promises.push(this._updIndicatorAction(3, window.Styles.colors.green));
                        break;

                    case 3:
                        promises.push(this._updIndicatorAction(4, window.Styles.colors.green));
                        break;

                    default:
                        break;
                }
            }

            return Q.all(promises);
        }

        _updIndicatorAction(idx, color) {
            var indicator = this.strengthIndicator.find("path:lt(" + idx + ")");
            return GUI.animationHandler.setCssAttr(indicator, "fill", color);
        }

        _setStrengthIndicatorToDefault() {
            var actions = [];
            this.strengthIndicator[0].childNodes.forEach(function (indicator) {
                actions.push(function () {
                    indicator.style.fill = Color.ColorUiElementBgB;
                });
            });
            return GUI.animationHandler.scheduleAll(actions);
        }

    }

    GUI.TableViewV2.Cells.PasswordInputCell = InputCell;
    return GUI;
}(window.GUI || {});
