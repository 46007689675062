'use strict';

define("AalSmartAlarmControl", ["Control", "AalSmartAlarmControlEnums"], function (Control, AalSmartAlarmControlEnums) {
    return class AalSmartAlarmControl extends Control {
        constructor() {
            super(...arguments);
        }

        getDescForLevel(level) {
            var descLevel = "";

            switch (level) {
                case AalSmartAlarmControlEnums.ALARM_LEVEL.NO_ALARM:
                    descLevel = _("controls.alarm.level.not-active");
                    break;

                case AalSmartAlarmControlEnums.ALARM_LEVEL.PRE_ALARM:
                    descLevel = _("controls.alarm.fireAlarm.pre-alarm.active");
                    break;

                case AalSmartAlarmControlEnums.ALARM_LEVEL.MAIN_ALARM:
                    descLevel = _("controls.alarm.fireAlarm.main-alarm.active");
                    break;
            }

            return descLevel;
        }

        getDescForState(state) {
            var descState;

            if (state === AalSmartAlarmControlEnums.STATE.ACTIVE) {
                descState = _("active");
            } else if (state === AalSmartAlarmControlEnums.STATE.INACTIVE) {
                descState = _("inactive");
            }

            return descState;
        }

        specificCreateCmdText(cmdParts) {
            var text = null,
                cfg = {
                    name: this.name
                };

            if (cmdParts[1] === Commands.ALARM.AAL_SMART_ALARM.QUIT) {
                text = _("cmdtext.alarm.quit", cfg);
            } else if (cmdParts[1] === Commands.ALARM.START_DRILL) {
                text = _("controls.alarm-base.drill.start");
            }

            return text;
        }

        getFriendlyValueName(event, operator) {
            var fValue = "";

            switch (event.data.stateName) {
                case "alarmLevel":
                    fValue = this.name + " " + _("alarm.level") + " " + operator + " " + this.getDescForLevel(event.value);
                    break;

                case "isLeaveActive":
                    fValue = this.name + " " + _("controls.aal-smart-alarm.desc.leave") + " " + operator + " " + this.getDescForState(event.value);
                    break;

                case "isLocked":
                    fValue = this.name + " " + _("controls.aal-smart-alarm.desc.locked") + " " + operator + " " + this.getDescForState(event.value);
                    break;

                default:
                    fValue = "";
            }

            return fValue;
        }

    };
});
