import {LxBaseControlContent} from "LxComponents";
import {MODE, OVERWRITE_REASON, SERVICE_MOOD_SETTING} from "../climateControlEnums";
import {useContext, useMemo} from "react";
import {View} from "react-native";
import {LxReactTableView, ControlContext} from "LxComponents";
import globalStyles from "GlobalStyles";

function ClimateControlContent({controlUUID}) {

    /**
     * Appends the serviceMode option to the context menu (top right "more" button)
     * @return {*}
     */
    const getContextMenuOptions = (control, states) => {
        let options = []// Only add the service mode button if the service mode isn't already active

        if (states.currentMode !== MODE.SERVICE_MODE) {
            options.push({
                title: _("controls.irc.mode.service"),
                action: handleServiceModeTapped.bind(this, control, states)
            });
        }

        return options;
    }

    const handleServiceModeTapped = (control, states) => {
        let enableServiceModeCmd = Commands.format(Commands.CLIMATE_CONTROLLER.SET_SERVICE_MODE, SERVICE_MOOD_SETTING.STANDBY);

        if (states.overwriteReason === OVERWRITE_REASON.STOP) {
            NavigationComp.showPopup({
                title: _("controls.irc.mode.service"),
                message: _("controls.climate.service-mode.unavailable"),
                buttonOk: _('okay'),
                icon: Icon.CAUTION,
                color: globalStyles.colors.red,
                type: PopupType.GENERAL
            });
        } else {
            control.sendCommand(enableServiceModeCmd);
        }
    }

    return <LxBaseControlContent getAdditionalContextMenuOptions={getContextMenuOptions}>
            <ServiceModeItems />
        </LxBaseControlContent>
}

function ServiceModeItems (props) {

    const {control, states} = useContext(ControlContext);

    const settingsModeContent = useMemo(() => {
        let section = {
            rows: []
        }

        if (states.currentMode !== MODE.SERVICE_MODE) {
            return [];
        }

        let settingsModeSettings = Object.values(SERVICE_MOOD_SETTING).filter((id) => {
            return id !== SERVICE_MOOD_SETTING.OFF; // The off command will be displayed separately, filter it out
        });

        settingsModeSettings.forEach(settingId => {
            let cmd = Commands.format(Commands.CLIMATE_CONTROLLER.SET_SERVICE_MODE, settingId);
            let isActive = states.serviceMode === settingId;

            section.rows.push({
                type: GUI.TableViewV2.CellType.CHECKABLE,
                content: {
                    title: _getServiceName(settingId),
                    selected: isActive,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                },
                didCheckCell: () => {
                    control.sendCommand(cmd)
                }
            })
        })

        return [section];
    }, [states.currentMode])

    return <View style={styles.modeContainer}>
        {settingsModeContent && <LxReactTableView tableContent={settingsModeContent} />}
    </View>
}

/**
 * Returns the correct name of the given settingsId
 * @param settingsId
 * @return {*}
 * @private
 */
function _getServiceName(settingsId) {
    let name;

    switch (settingsId) {
        case SERVICE_MOOD_SETTING.STANDBY:
            name = _("controls.climate.servicemode.inactive");
            break;

        case SERVICE_MOOD_SETTING.HEATING_ON:
            name = _("controls.climate.servicemode.heating");
            break;

        case SERVICE_MOOD_SETTING.COOLING_ON:
            name = _("controls.climate.servicemode.cooling");
            break;

        case SERVICE_MOOD_SETTING.VENT_ON:
            name = _("controls.climate.servicemode.ventilation");
            break;

        case SERVICE_MOOD_SETTING.OFF:
            name = _("off");
            break;
    }

    return name;
}

const styles = {
    modeContainer: {
        width: "100%"
    }
}

export default ClimateControlContent