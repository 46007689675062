'use strict';

define("GateCentralControl", ["GateControl", "GateControlEnums"], function (GateControl, GateControlEnums) {
    return class GateCentralControl extends GateControl {
        constructor() {
            super(...arguments);
        }

        getReactControlContentFlags() {
            return {
                showStateIcon: false
            }
        }

        isUpDownDoor() {
            return this._getAnimationType() === GateControlEnums.GateAnimationType.GARAGE_DOOR;
        }

        isTurnDoorLeft() {
            return this._getAnimationType() === GateControlEnums.GateAnimationType.TURN_DOOR_LEFT;
        }

        isTurnDoorRight() {
            return this._getAnimationType() === GateControlEnums.GateAnimationType.TURN_DOOR_RIGHT;
        }

        isTurnDoorDouble() {
            return this._getAnimationType() === GateControlEnums.GateAnimationType.TURN_DOOR_DOUBLE;
        }

        isSectionalDoorLeft() {
            return this._getAnimationType() === GateControlEnums.GateAnimationType.SECTIONAL_DOOR_LEFT;
        }

        isSectionalDoorRight() {
            return this._getAnimationType() === GateControlEnums.GateAnimationType.SECTIONAL_DOOR_RIGHT;
        }

        _getAnimationType() {
            var savedAnimationType = null,
                differentAnimationTypes = false,
                cntr;
            this.details.controls.forEach(function (control) {
                cntr = ActiveMSComponent.getStructureManager().getControlByUUID(control.uuid);

                if (!savedAnimationType && cntr) {
                    savedAnimationType = cntr.details.animation;
                }

                if (cntr && cntr.details.animation !== savedAnimationType) {
                    differentAnimationTypes = true;
                }
            }.bind(this));
            return differentAnimationTypes ? null : savedAnimationType;
        }

    };
});
