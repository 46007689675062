import Svg, { Path } from "react-native-svg"

function Miniserver(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path d="M24 11H13.458a.503.503 0 00-.283.088l-1.211.83-.026.026a.1.1 0 00.026.139l1.211.83a.501.501 0 00.283.088H24v4.5a.5.5 0 01-.5.5H.5a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h23a.5.5 0 01.5.5v4.5zm-22.5.5a.5.5 0 000 1h1a.5.5 0 000-1h-1zm3 0a.5.5 0 000 1h1a.5.5 0 000-1h-1zM2.5 3h19a.5.5 0 01.5.5V5H2V3.5a.5.5 0 01.5-.5zM2 19h20v1.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5V19z" />
        </Svg>
    )
}

export default Miniserver
