'use strict';

define(["SpotifyAccountManager"], function (SpotifyAccountManager) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var BUTTON_KEYS = {
        ADD_MUSIC: "addMusic",
        OPEN_SPOTIFY: "openSpotify"
    };
    return class NewSpotifyAccountScreen extends GUI.Screen {
        //region Static
        static Template = class {
            //region Static
            static get(iconSrc, title, desc) {
                return $("<div class='new-spotify-account-screen__container'>" + "   <div class='container__icon-placeholder'>" + '       ' + ImageBox.getResourceImageWithClasses(iconSrc, "icon-placeholder__icon") + "   </div>" + "   <div class='container__title'>" + title + "</div>" + "   <div class='container__description'>" + desc + "</div>" + "   <div class='container__button-container'>" + "       <div class='button-container__button button-container__button--add-music'>" + _("media.library.upload.title") + "..." + "</div>" + "       <div class='button-container__button button-container__button--open-spotify'>" + _("audio-server.spotify.show") + "</div>" + "   </div>" + "</div>");
            } //endregion Static


        }; //endregion Static

        constructor(details) {
            super($("<div />"));
            this.control = details.control;
            this.user = details.user || SpotifyAccountManager.shared(this.control).activeUser;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.element.append(NewSpotifyAccountScreen.Template.get(Icon.AudioZone.SPOTIFY_ICON, _("audio-server.spotify.add.success.title"), _("audio-server.spotify.add.success.desc")));
                this.elements.buttons = {};
                this.elements.buttons[BUTTON_KEYS.ADD_MUSIC] = new GUI.LxRippleButton(this.element.find(".button-container__button--add-music"));
                this.elements.buttons[BUTTON_KEYS.OPEN_SPOTIFY] = new GUI.LxRippleButton(this.element.find(".button-container__button--open-spotify"));
                Object.keys(this.elements.buttons).forEach(function (btnKey) {
                    this.addToHandledSubviews(this.elements.buttons[btnKey]);
                    this.elements.buttons[btnKey].on(GUI.LxRippleButton.CLICK_EVENT, this._handleButtonWithKey.bind(this, btnKey));
                }.bind(this));
            }.bind(this));
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            return _("media.popup.spotify.title");
        }

        _handleButtonWithKey(btnKey) {
            switch (btnKey) {
                case BUTTON_KEYS.ADD_MUSIC:
                    SpotifyAccountManager.shared(this.control).setUserWithIdActive(SpotifyAccountManager.getUserUnique(this.user));
                    this.control.audioserverComp.AudioViewController.navigateBackToRoot().then(function () {
                        var details = {
                            control: this.control,
                            targetMediaScreenState: Controls.AudioZoneV2Control.Enums.ScreenState.SPOTIFY_ONBOARDING,
                            addTargetName: _("media.library.upload.title")
                        };
                        this.control.audioserverComp.AudioViewController.showState(Controls.AudioZoneV2Control.Enums.ScreenState.ADD_MEDIA_VC_SPOTIFY_ONBOARDING, null, details);
                    }.bind(this));
                    break;

                case BUTTON_KEYS.OPEN_SPOTIFY:
                    SpotifyAccountManager.shared(this.control).setUserWithIdActive(SpotifyAccountManager.getUserUnique(this.user));
                    this.control.audioserverComp.AudioViewController.navigateBackToRoot().then(function () {
                        if (HD_APP) {
                            this.control.audioserverComp.AudioViewController.showState("SpotifyContentOverviewScreen", null, {
                                control: this.control,
                                customizationKey: MusicServerEnum.ControlContentMenuId.SPOTIFY // This key is used to automatically select Spotify Menu Cell

                            });
                        } else {
                            this.control.audioserverComp.AudioViewController.showState(ScreenState.AudioZoneV2DetailedContentVC, null, {
                                showStateArgs: ["SpotifyContentOverviewScreen", null, {
                                    control: this.control,
                                    customizationKey: MusicServerEnum.ControlContentMenuId.SPOTIFY
                                }]
                            }, AnimationType.FADE);
                        }
                    }.bind(this));
                    break;
            }
        }

    };
});
