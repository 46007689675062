/**
 * Created by loxone on 14.06.17.
 */
'use strict';

define(["ServiceModeStateView", 'PoolControlEnums'], function (ServiceModeStateView, PoolControlEnums) {
    return class ServiceModeScreen extends Controls.ControlContent {
        constructor(details) {
            super(details);
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(() => {
                this.serviceModeList = new ServiceModeStateView(this.control);
                this.serviceModeList.sendCommand = this.control.sendCommand.bind(this.control);
                return this.appendSubview(this.serviceModeList); //TODO-woessto: adopt screen - it doesn't scroll nor animate properly. https://www.wrike.com/open.htm?id=158282466
            });
        }

        viewDidDisappear() {
            // true = keepViewVisible
            // This is needed to receive status updates, even tho the screen is overlayed by another screen.
            // This screen handles the back navigation to the controlContent if the service mode gets activated while the
            // settings screen and any other subsequencial screens are shown
            super.viewDidDisappear(true);
        }

        receivedStates(states) {
            if (!this.blockBackNavigation && states.currentOpModeNr !== PoolControlEnums.PoolMode.SERVICE_MODE) {
                this.blockBackNavigation = true; // Navigate back to the ControlContent (Its the root of the current VC)
                // Note: This also hides all screens currently present over this screen!

                this.ViewController.navigateBackToRoot();
            }
        }

        getAnimation() {
            return AnimationType.HD_OVERLAY;
        }

        getURL() {
            return "ServiceMode";
        }

        titleBarText() {
            return _('settings') + " (" + _("pool.control.OP-modes.servicemode") + ")";
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK
            };
        }

    };
});
