import {createStateCell} from "../../UtilityComp/utilities/helper";
import {ScreenState as CarChargerScreenStates }  from "./carChargerControlEnums";

export default class CarChargerControlStatesSrc {
    static getStateSections(control, states, navigation) {
        var section = {
                rows: []
            };
            // profiles start at 0, their energy indices at 1 (0 is the power)
        // current/last energy

        section.rows.push(this.createEnergyCell(control, states)); // current power

        if (states.isCharging) {
            section.rows.push(this.createPowerCell(control, states));
        } // current/last duration

        section.rows.push(this.createDurationCell(control, states));

      // push first section on it


        if (states.showLoadManagement) {
            section.rows.push(this._getLoadManagementCell(control, states, navigation));
        } // eventually the second section is to be pushed on it too.

        if (control.statistic) {
            section.rows.push({
                content: {
                    title: _("statistics"),
                    disclosureIcon: true,
                },
                action: () => {
                    let nr = 0;
                    if (control.statistic && control.statistic.outputs && control.statistic.outputs[nr]) {
                        nr = nr ? nr : 0;
                        navigation.navigate(ScreenState.Statistic, {
                            controlUUID: control.uuidAction,
                            statisticOutputUUID: control.statistic.outputs[nr].uuid
                        })
                    }
                }
            })
        }

        return [section];
    }

    static createEnergyCell(control, states) {
        let statIndex = Math.max(states.currentProfile, 0) + 1; // math max, as the curr profile is -1 if no other profiles exist
        let text = states.isCharging ? _("controls.car-charger.curr.energy") : _("controls.car-charger.last.energy");
        let value = states.sessionEnergyText;
        let action = this.showStatisticForOutput.bind(this, statIndex, control);
        return createStateCell(text, value, null, null, action); // current power
    }

    static createPowerCell(control, states) {
        let text = _("controls.car-charger.curr.power");
        let value = states.powerText;
        let action = this.showStatisticForOutput.bind(this, 0, control);
        return createStateCell(text, value, null, null, action);
    }

    static createDurationCell(control, states) {
        let text = states.isCharging ? _("controls.car-charger.curr.duration") : _("controls.car-charger.last.duration");
        let value = LxDate.formatSeconds(states.chargeDuration).replaceAll("&nbsp;", " ");
        return createStateCell(text, value);
    }

    /**
     * Will return a cell obj that handles everything in connection with the load management.
     * @param states
     * @param control
     * @param navigation
     * @return {{content: {}}}
     * @private
     */
    static _getLoadManagementCell(control, states, navigation) {
        return {
            content: {
                title: _("controls.car-charger.charging-limit"),
                disclosureText: states.limitModeText,
                disclosureIcon: true,
                clickable: true
            },
            action: () => {
                navigation.navigate(CarChargerScreenStates.SETTINGS, {control});
            }
        };
    }

    /**
     * Will present the statistic for the output provided.
     * @param nr    the output number, 0 if not provided.
     * @param control
     */
    static showStatisticForOutput(nr, control) {
        if (control.statistic && control.statistic.outputs && control.statistic.outputs[nr]) {
            nr = nr ? nr : 0;
            NavigationComp.showStatistic(control.uuidAction, control.statistic.outputs[nr].uuid);
        }
    }
}
