'use strict';

define([], function () {
    return class TaskDetailsScreen extends GUI.Screen {
        constructor(details) {
            super($('<div class="task-details-screen"></div>'));
            this.task = cloneObjectDeep(details.task);
            this.isEditing = !!details.editing;

            if (this.isEditing) {
                this.originalTask = cloneObjectDeep(this.task);
            }
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.titleBar = this._getTitlebar();
            this.prependSubview(this.titleBar);
            this.tableView = new GUI.TableViewV2(this, this);
            this.tableView.getElement().addClass("task-details-screen__table-view");
            this.appendSubview(this.tableView);
            this.tableView.reload();
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);

            this.titleBar.onLeftButtonTapped = function () {
                var showTaskRecAgain = !this.isEditing; // save here temporary because of destroy fn!

                this.ViewController.navigateBack();
                this.titleBar.onLeftButtonTapped = null; // workaround twice calls
                // if not editing, we go back to the TaskRec Screen!

                if (showTaskRecAgain) {
                    NavigationComp.showState(ScreenState.TaskRecorder);
                }
            }.bind(this);

            this.titleBar.onRightButtonTapped = function () {
                if (this.isEditing) {
                    SandboxComponent.updateTask(this.originalTask, this.task);
                } else {
                    SandboxComponent.addTask(this.task);
                }

                this.ViewController.navigateBack();
            }.bind(this);
        }

        viewDidDisappear() {
            this.titleBar.onRightButtonTapped = null;
            this.titleBar.onLeftButtonTapped = null;
            super.viewDidDisappear(...arguments);
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        // Private methods
        _getTitlebar() {
            var titleBarConfig = {
                leftSide: TitleBarCfg.Button.CLOSE,
                rightSide: TitleBarCfg.Button.TICK,
                style: HD_APP ? 'transparent' : null
            };
            var titleBar = new GUI.LxTitleBar(titleBarConfig);

            if (this.isEditing) {
                titleBar.setTitleBarSideTexts(_('taskrecorder.edit-task'));
            } else {
                titleBar.setTitleBarSideTexts(_('taskrecorder.new-task'));
            }

            return titleBar;
        }

        // TableViewDataSource Methods
        styleForTableView() {
            return HD_APP ? TableViewStyle.GROUPED : null;
        }

        numberOfSections() {
            if (this.isEditing) {
                return 4;
            }

            return 3;
        }

        numberOfRowsInSection(section) {
            if (section === 0) {
                return 1;
            } else if (section === 1) {
                return 1;
            } else if (section === 2) {
                return this.task.commands.length;
            } else if (section === 3) {
                return 1;
            }
        }

        cellTypeForCellAtIndex(section) {
            if (section === 0) {
                return GUI.TableViewV2.CellType.INPUT;
            } else if (section === 1) {
                return GUI.TableViewV2.CellType.DATE_TIME_PICKER;
            } else if (section === 2) {
                return GUI.TableViewV2.CellType.Special.TASK_COMMAND;
            } else if (section === 3) {
                return GUI.TableViewV2.CellType.DELETE;
            }
        }

        contentForCell(cell, section, row) {
            if (section === 0 && row === 0) {
                // InputCell
                return {
                    type: "text",
                    value: this.task.name,
                    placeholder: _('taskrecorder.task-name'),
                    autoFocus: true
                };
            } else if (section === 1) {
                // PickerCell
                return {
                    iconSrc: "resources/Images/ActiveMiniserver/TaskRecorder/icon-clock.svg",
                    title: _('taskrecorder.start-time'),
                    value: this.task.startDate
                };
            } else if (section === 2) {
                // TaskCommandCell
                return {
                    title: this.task.commands[row].mainText,
                    groupDetail: this.task.commands[row].subText,
                    delta: this.task.commands[row].delta
                };
            } else if (section === 3) {
                // DeleteCell
                return {
                    title: _('taskrecorder.delete-task')
                };
            }
        }

        // TableViewDelegate Methods
        didSelectCell(cell, section) {
            if (section === 3) {
                var content = {
                    title: _("taskrecorder.delete-task"),
                    message: _("taskrecorder.delete-task-message", {
                        taskName: this.task.name
                    }),
                    buttonOk: _("delete"),
                    buttonCancel: true,
                    icon: Icon.ERROR,
                    color: window.Styles.colors.red
                };
                var popupProm = NavigationComp.showPopup(content);
                popupProm.then(function () {
                    SandboxComponent.deleteTask(this.originalTask); // delete original!

                    this.ViewController.navigateBack();
                }.bind(this));
            }
        }

        textChanged(section, row, tableView, value, valid) {
            if (valid) {
                this.task.name = value;
            }
        }

        onPickerChanged(section, row, tableView, value) {
            // update task times!
            SandboxComponent.updateTaskStartTime(this.task, value);
        }

    };
});
