'use strict';

define([], function () {
    return {
        ScreenState: {
            TEMP_SETTINGS: "IRCTempSettingsScreen",
            TEMP_ADOPTION: ScreenState.iRoomV2021.TempAdoption
        },
        ConnectedInputs: {
            COMFORT_MIN_TEMP: 1,
            COMFORT_MAX_TEMP: 2,
            // comfort-temp h+c 4 <-- unused
            // allowed comfort-tolerance 8 <-- unused.
            ECO_MIN_TEMP: 16,
            ECO_MAX_TEMP: 32,
            // allowed absent-deviation 64 <-- unused.
            SHADING_HEATING: 128,
            SHADING_COOLING: 256,
            FROST_PROTECT_TEMP: 512,
            HEAT_PROTECT_TEMP: 1024,
            MODE: 2048,
            CO2_LEVEL: 4096,
            INDOOR_HUMIDITY: 8192,
        },
        Capability: {
            HEATING: 1,
            COOLING: 2,
            SHADING: 4,
            VENTILATION: 8,
            PASSIVE_HEAT: 16,
            PASSIVE_COOL: 32
        },
        TempZone: {
            HEATING: 1,
            //Too cold, should be heating up
            FLOATING: 0,
            // OK, within heating and cooling limit
            COOLING: -1 //Too hot, should be cooling down.

        },
        TmpSliderType: {
            ECO_SINGLE_COMFORT: 1, // single target eco offset
            SINGLE_COMFORT: 2, // single target temperature
            SHADING_HEAT: 3,
            SHADING_COOL: 4,
            ECO_MIN: 5, // heating/cooling comfort
            ECO_MAX: 6, // heating/cooling comfort
            COMFORT_HEAT: 7, // heating comfort, two separate temps
            COMFORT_COOL: 8, // Cooling comfort, two separate temps
        }
    };
});
