import {useState, useRef, useEffect, useCallback} from "react";
import {useConnectionReady} from "LxComponents";
import useDeviceSearchLightGroups from "./useDeviceSearchLightGroups";
import DeviceSearchUtils from "./deviceSearchUtils";

export default function useDeviceSearchExistingDevices(deviceType, miniserver = -1, extension = null, searchType) {

    const [existingDevicesList, setExistingDevicesList] = useState(null);
    const [countState, setCountState] = useState(0);
    const connReady = useConnectionReady(true);
    const allRooms = ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.ROOM, true);
    const lightGroups = useDeviceSearchLightGroups();

    /**
     * Ensures the lightGroup "None" exists and publishes the result.
     * @param deviceList
     */
    const updateExistingDevices = (deviceList) => {
        Debug.DeviceSearch.Assistant && console.log("useDeviceSearchExistingDevices", "updateExistingDevices", deviceList);
        let populatedList = DeviceSearchUtils.populateDeviceListWith(deviceList, lightGroups, allRooms);
        setCountState(countState + 1);
        setExistingDevicesList(populatedList);
    }

    const isMounted = useRef(true);
    const reloadExistingDevices = () => {
        Debug.DeviceSearch.Assistant && console.log("useDeviceSearchExistingDevices", "reloadExistingDevices");
        ActiveMSComponent.getDevicesOfExtensions(deviceType, miniserver, extension).then(function (res) {
            if (isMounted.current) {
                updateExistingDevices(res);
            }
        }.bind(this));
    }

    const onExtensionListChanged = useCallback(() => {
        Debug.DeviceSearch.Assistant && console.log("useDeviceSearchExistingDevices", "onExtensionListChanged - connected? " + connReady);
        connReady && reloadExistingDevices();
    }, []);

    useEffect(() => {
        Debug.DeviceSearch.Assistant && console.log("useDeviceSearchExistingDevices", "mounted -> connected? " + connReady);
        connReady && reloadExistingDevices();
        let unregFn = ActiveMSComponent.registerForExtensionChanges(onExtensionListChanged, searchType);

        return () => {
            Debug.DeviceSearch.Assistant && console.log("useDeviceSearchExistingDevices", "unmounted");
            isMounted.current = false;
            unregFn();
        }
    }, []);

    useEffect(() => { // when the light groups change, the devices need to be reloaded, as they may contain lightgroup membership info.
        Debug.DeviceSearch.Assistant && console.log("useEffect@lightGroups", "connected? " + connReady);
        connReady && reloadExistingDevices();
    }, [lightGroups]);


    useEffect(() => {
        Debug.DeviceSearch.Assistant && console.log("useDeviceSearchExistingDevices", "useEffect@connReady=" + connReady);
        connReady && reloadExistingDevices();
    }, [deviceType])

    return existingDevicesList;
}
