import { View, TextInput } from 'react-native';
import globalStyles from "GlobalStyles";
import PropTypes from "prop-types";
import React, { useState, useMemo, useRef, forwardRef, useEffect, useImperativeHandle } from 'react';
import LxReactIconButton from './LxReactButton/LxReactIconButton'
import { LxReactText } from "LxComponents";
import Icons from "IconLib";


const LxReactTextView = forwardRef((props, forwardRef) => {
    const [inputValue, setValue] = useState(props.value);
    const [validationErrorMessage, setValidationErrorMessage] = useState(props.validationErrorText);
    const {
        enterAllowed = true,
        customErrorMessage = null
    } = props;
    // region style


    useEffect(() => {
        if (props.keepValueUpdated) {
            setValue(props.value);
        }
    }, [props.value]);

    //ensure auto focus is working
    useEffect(() => {
        if (props.autoFocus && !inputRef.current.isFocused()) {
            inputRef.current.focus()
            onFocus();
        }
    }, [])

    const styles = useMemo(() => {
        return {
            mainContainer: {
                flexDirection: "column",
                maxWidth: "100%",
                width: "100%"
            },
            inputContainer: {
                base: {
                    width: "100%",
                    maxWidth: "100%",
                    flexDirection: "row",
                    alignItems: "center"
                },
                multiline: {
                    height: globalStyles.sizes.textInput.multiLineHeight
                },
                singeline: {
                    height: props.displayInCell ? "auto" : globalStyles.sizes.textInput.height
                },
                innerContainer: {
                    flexDirection: "row",
                    height: "100%"
                },
                rightContainer: {
                    flexDirection: "column",
                    flex: 1,
                    height: "100%"
                }
            },
            leftIconContainer: {
                width: globalStyles.sizes.textInput.leftIconWidth,
                height: "100%",
                justifyContent: "center",
                marginRight: globalStyles.spacings.gaps.small
            },
            leftIcon: {
                fill: globalStyles.colors.text.tertiary
            },
            titleText: {
                color: globalStyles.colors.text.primary,
                marginRight: globalStyles.spacings.gaps.small,
                fontSize: globalStyles.fontSettings.sizes.medium,
                lineHeight: props.displayInCell ? "initial" : globalStyles.sizes.textInput.height,
                alignSelf: "center"
            },
            input: {
                marginRight: props.displayInCell ? 0 : globalStyles.spacings.gaps.small,
                color: globalStyles.colors.text.secondary,
                flex: 1,
                minWidth: 0, // fixes iOS render issue
                ...globalStyles.textStyles.body.default
            },
            closeIconContainer: {
                backgroundColor: globalStyles.colors.transparent,
                padding: 0,
                justifyContent: "center"
            },
            closeIcon: {
                height: globalStyles.sizes.icons.tiny,
                width: globalStyles.sizes.icons.tiny,
                fill: globalStyles.colors.text.secondary
            },
            titleSection: {
                flexDirection: "column",
            },
            subtitleText: {
                ...globalStyles.textStyles.footNote.default,
                color: globalStyles.colors.text.secondary,
            },
        }
    }, [props.displayInCell])

    const lastInputRefs = useRef({
        value: inputValue || "",
        valid: true,
        changed: false
    });

    const inputRef = useRef(null);

    // endregion style
    let biometricAuthFailed = false;

    let keyboardType;

    switch (props.type) {
        case GUI.LxInputEnum.Type.TELEPHONE:
            keyboardType = "phone-pad";
            break;
        case GUI.LxInputEnum.Type.NUMBER:
            keyboardType = "number-pad";
            break;
    }

    const onChangeText = value => {
        let filtered = filterInput(value);
        let isValid = validateInput(filtered);
        lastInputRefs.current.value = filtered;
        lastInputRefs.current.valid = isValid;
        lastInputRefs.current.changed = true;
        props.textChanged && props.textChanged(filtered, isValid);
        setValue(filtered);
    }

    const filterInput = text => {
        if ("filterRegex" in props) {
            return text.regexFilter(props.filterRegex, props.filterReplaceChar);
        }
        return text;
    }

    const validateInput = text => {
        let isValid = null,
            invalidIdx = -1;

        if ("validation" in props && props.validation) {
            if (Array.isArray(props.validation)) {
                isValid = props.validation.every((obj, idx) => {
                    if (typeof obj === "object" && "regExp" in obj && "message" in obj) {
                        let valid = obj.regExp.test(text);
                        if (!valid) {
                            invalidIdx = idx;
                        }
                        return valid;
                    } else if (typeof obj === "object" && typeof obj.valFunc === "function" && "message" in obj) {
                        let valid = obj.valFunc(text);
                        if (!valid) {
                            invalidIdx = idx;
                        }
                        return valid;
                    } else {
                        return true;
                    }
                });
            } else if (typeof props.validation === "function") {
                isValid = props.validation();
            }
            if (invalidIdx !== -1) {
                setValidationErrorMessage(props.validation[invalidIdx].message);
            } else {
                setValidationErrorMessage(null);
            }
        } else if ("validationRegex" in props && props.validationRegex) {
            isValid = props.validationRegex.test(text);
        }


        return isValid;
    }

    /**
     * asynValidationOnEnd is a function that either resolves with true/false or an error text to be shown if the input
     * isn't correct.
     * @param text
     * @returns {Q.Promise<boolean>}
     */
    const asyncValidateOnEnd = text => {
        let isValid = lastInputRefs.current.valid;

        if ("asynValidationOnEnd" in props &&
            props.asynValidationOnEnd &&
            isValid !== false &&
            typeof props.asynValidationOnEnd === "function") {

            return props.asynValidationOnEnd(text).then((isValid) => {
                if (typeof isValid === "string") {
                    lastInputRefs.current.valid = false;
                    setValidationErrorMessage(isValid);
                } else {
                    lastInputRefs.current.valid = isValid;
                    if (!isValid) {
                        setValidationErrorMessage(_("invalid-input"));
                    }
                }
                return lastInputRefs.current.valid;
            });
        } else {
            return Q.resolve();
        }
    }

    /**
     * Note that on iOS this method isn't called when using keyboardType="phone-pad".
     */
    const onSubmitEditing = () => {
        let isValid = validateInput(inputValue),
            endValidation = isValid ? asyncValidateOnEnd(inputValue) : Q.resolve(true);
        endValidation.then(() => {
            props.onSubmitEditing && props.onSubmitEditing(inputValue, lastInputRefs.current.valid, lastInputRefs.current.changed);
        })
    }

    const onFocus = () => {
        if (inputRef.current && !inputRef.current.isFocused() && !props.selectTextOnFocus) {
            //TODO: This is specific html code, use the code below when going native
            /*inputRef.current.setNativeProps({
                selection: {
                    start: textLength,
                    end: textLength
                }
            } */
            // The timeout is needed (we dont know why), probably because of an interference with the focus event
            setTimeout(() => inputRef.current.setSelectionRange(textLength, textLength), 0);
        }
        props.onFocus && props.onFocus();
    }

    const onBlur = () => {
        asyncValidateOnEnd(inputValue).then(() => {
            props.onBlur && props.onBlur(lastInputRefs.current.value, lastInputRefs.current.valid, lastInputRefs.current.changed)
        })
    }

    const textLength = useMemo(() => {
        return (inputValue || "").toString().length;
    }, [inputValue])

    const renderTextInput = () => {
        return (
            <TextInput
                ref={inputRef}
                style={[{
                    textAlign: props.alignment || "right",
                },
                styles.input,
                props.textStyle
                ]}
                placeholder={props.placeholder || ""}
                placeholderTextColor={props.placeholderTextColor || globalStyles.colors.text.tertiary}
                selectTextOnFocus={!!props.selectTextOnFocus}
                onChangeText={onChangeText}
                onSubmitEditing={onSubmitEditing}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyPress={e => {
                    if (e.nativeEvent.key === "Enter") {
                        !enterAllowed && e.preventDefault()
                    }
                }}
                multiline={props.multiline || false}
                autoFocus={props.autoFocus || false}
                value={(inputValue || "").toString()}
                maxLength={props.maxLength}
                textContentType={props.textContentType}
                disabled={props.disabled}
                secureTextEntry={props.secureTextEntry}
                autoComplete={props.autoComplete}
                type={props.type}
                autoCapitalize={props.autoCapitalize}
                keyboardType={props.keyboardType || "default"}
            />
        );
    }

    const _isBiometricSecretAvailable = (props) => {
        var userPwToken = null,
            isBiometricSecretEnabled = false,
            targetMs = props.biometricSecretTargetMs || ActiveMSComponent.getActiveMiniserver();

        if (props.type === GUI.LxInputEnum.Type.PASSWORD && props.hasOwnProperty(GUI.LxInputEnum.BIOMETRIC_SECRET_TYPE) && BiometricHelper.hasEnrolledBiometrics) {
            userPwToken = PersistenceComponent.getBiometricTokenForSecretType(props[GUI.LxInputEnum.BIOMETRIC_SECRET_TYPE], targetMs.serialNo, targetMs.activeUser);
            isBiometricSecretEnabled = PersistenceComponent.isBiometricSecretWithTypeEnabled(props[GUI.LxInputEnum.BIOMETRIC_SECRET_TYPE], targetMs.serialNo, targetMs.activeUser);
        } // Force a boolean type to ensure toggleClass works correctly!

        return !!(userPwToken && isBiometricSecretEnabled);
    }

    const _requestBiometricSecret = (props) => {
        let targetMs = props.biometricSecretTargetMs;

        BiometricHelper.getSecretOfType(props[GUI.LxInputEnum.BIOMETRIC_SECRET_TYPE], targetMs, true).then(function (secret) {
            setValue(secret);
            props.textChanged && props.textChanged(secret);
        }.bind(this), function (errorCode) {
            var platform = PlatformComponent.getPlatformInfoObj().platform; // As designed only implemented for iOS

            if (platform === PlatformType.IOS && errorCode !== BiometricHelper.ErrorCodes.UserCanceled) {
                // don't use the removeFocus function here the element might be detached from dom
                document.activeElement.blur();
                NavigationComp.showPopup({
                    message: _('biometrics.login-error-text', {
                        biometricType: BiometricHelper.getBiometricTypePhrase()
                    }),
                    buttonOk: true
                }).then(function () {
                    setValue("");
                    biometricAuthFailed = true;
                    PersistenceComponent.setBiometricTokenForSecretType(null, props[GUI.LxInputEnum.BIOMETRIC_SECRET_TYPE], targetMs.serialNo, targetMs.activeUser);
                    PersistenceComponent.setBiometricSecretWithSecretTypeEnabled(props[GUI.LxInputEnum.BIOMETRIC_SECRET_TYPE], true, targetMs.serialNo, targetMs.activeUser);
                }.bind(this));
            }
        }.bind(this));
    }

    useImperativeHandle(forwardRef, () => ({
        focus() {
            inputRef.current && inputRef.current.focus();
        },
        blur() {
            inputRef.current && inputRef.current.blur();
        },
        clear() {
            inputRef.current && inputRef.current.clear();
        },
        isFocused() {
            if (inputRef.current) {
                return inputRef.current.isFocused();
            } else {
                return false;
            }
        }
    }))

    const LeftIcon = () => {
        if (props.leftIcon) {
            let icon = React.createElement(props.leftIcon, { ...styles.leftIcon, ...props.leftIcon });

            return (
                <View style={styles.leftIconContainer}>
                    {icon}
                </View>
            )
        } else {
            return null;
        }
    }

    const TitleText = () => {
        if (props.title) {
            return (
                <LxReactText numberOfLines={1} style={styles.titleText}>
                    {props.title}
                </LxReactText>
            )
        } else {
            return null;
        }
    }

    const SubtitleText = () => {
        if (props.subtitle) {
            return (
                <LxReactText numberOfLines={1} style={styles.subtitleText}>
                    {props.subtitle}
                </LxReactText>
            )
        } else {
            return null;
        }
    }

    const CloseIcon = () => {
        let closeIconKey = (props.key || props.pkey) + "-close-button";
        if (!props.hideRightIcon) {
            if (props.showClearIcon && (inputValue && inputValue.length)) {
                return (
                    <LxReactIconButton
                        icon={Icons.DeleteCircled}
                        pkey={closeIconKey}
                        key={closeIconKey}
                        iconStyle={styles.closeIcon}
                        containerStyle={styles.closeIconContainer}
                        onPress={() => {
                            props.textChanged && props.textChanged("");
                            setValue("");
                        }}
                    />
                )
            } else if (props.rightDefaultIcon) {
                return (
                    <LxReactIconButton
                        icon={props.rightDefaultIcon}
                        pkey={closeIconKey}
                        key={closeIconKey}
                        iconStyle={props.rightDefaultIconStyle}
                        containerStyle={styles.closeIconContainer}
                        onPress={() => {
                            props.rightDefaultIconAction();
                        }}
                    />
                )
            } else if (!biometricAuthFailed && _isBiometricSecretAvailable(props)) {
                return (
                    <LxReactIconButton
                        icon={Icons.FaceID}
                        pkey={closeIconKey}
                        key={closeIconKey}
                        containerStyle={styles.closeIconContainer}
                        onPress={() => {
                            _requestBiometricSecret(props);
                        }}
                    />
                )
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    const InvalidText = () => {
        return (
            <LxReactText
                style={{
                    color: globalStyles.colors.red,
                    textAlign: props.alignment || "right"
                }}
            >
                {(customErrorMessage ? customErrorMessage : validationErrorMessage)}
            </LxReactText>
        )
    }

    return ( // renderTextInput cannot be used as a functional component because of the ref!
        <View style={[styles.mainContainer, props.style]}>
            <View
                style={[styles.inputContainer.base, props.multiline ? styles.inputContainer.multiline : styles.inputContainer.singeline, props.containerStyle]}>
                <LeftIcon />
                <View style={[styles.titleSection, props.titleContainerStyle]}>
                    <TitleText />
                    <SubtitleText />
                </View>
                <View style={styles.inputContainer.rightContainer}>
                    <View style={styles.inputContainer.innerContainer}>
                        {renderTextInput()}
                        <CloseIcon />
                    </View>
                    <InvalidText />
                </View>
            </View>
        </View>
    )
});


export default LxReactTextView;

LxReactTextView.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    titleContainerStyle: PropTypes.object,
    textStyle: PropTypes.object,
    placeholder: PropTypes.string,
    placeholderTextColor: PropTypes.string,
    autoFocus: PropTypes.bool,
    textChanged: PropTypes.func,
    onSubmitEditing: PropTypes.func,
    showClearIcon: PropTypes.bool,
    multiline: PropTypes.bool,
    alignment: PropTypes.string,
    containerStyle: PropTypes.object,
    rightDefaultIcon: PropTypes.func,
    rightDefaultIconStyle: PropTypes.object,
    rightDefaultIconAction: PropTypes.func,
    leftIcon: PropTypes.node,
    leftIconStyle: PropTypes.object,
    biometricSecretTargetMs: PropTypes.object,
    type: PropTypes.string,
    biometricSecretType: PropTypes.string,
    maxLength: PropTypes.number,
    hideRightIcon: PropTypes.bool,
    disabled: PropTypes.bool,
    secureTextEntry: PropTypes.bool,
    validationErrorText: PropTypes.string,
    validation: PropTypes.oneOf([
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.shape({
            regExp: PropTypes.instanceOf(RegExp),
            message: PropTypes.string
        }))
    ]),
    asynValidationOnEnd:  PropTypes.func, // used to asynchronically verify a value before leaving the input.
    value: PropTypes.string,
    filterRegex: PropTypes.instanceOf(RegExp),
    validationRegex: PropTypes.instanceOf(RegExp),
    onBlur: PropTypes.func,
    displayInCell: PropTypes.bool,
    autoCapitalize: PropTypes.oneOf([
        'none', 'sentences', 'words', 'characters'
    ]),
    selectTextOnFocus: PropTypes.bool,
    keyboardType: PropTypes.oneOf([
        "number-pad",
        "decimal-pad",
        "numeric",
        "email-address",
        "phone-pad",
        "url"
    ]),
    enterAllowed: PropTypes.bool
};
