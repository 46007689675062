'use strict';

define(["HomeKitOnboardingScreen"], function (HomeKitOnboardingScreen) {
    class HomeKitWelcomeScreen extends GUI.HomeKitOnboardingScreen {
        //region Static
        static getShownIds() {
            return [];
        }

        static shouldShow(id) {
            return true;
        } //endregion Static


        constructor(details) {
            super(details);
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                var reachMode = CommunicationComponent.getCurrentReachMode();
                this.ViewController._preventReset = true;

                if (!this.continueBtnText) {
                    this.bottomText.addClass("user-viewer");
                }
            }.bind(this));
        }

        continueBtnAction() {
            if (this.ViewController.platform === PlatformType.IOS) {
                ActiveMSComponent.checkHomeKitPermission().then(function () {
                    this._handleContinueAction();
                }.bind(this), function () {
                    return NavigationComp.showPopup(this.ViewController.getPermissionPopupDetails()).then(function () {
                        return ActiveMSComponent.openIosAppSettings();
                    }.bind(this), function (error) {
                        this.ViewController._preventReset = false;
                        this.ViewController.dismiss();
                    }.bind(this));
                }.bind(this));
            } else {
                this._handleContinueAction();
            }
        }

        _handleContinueAction() {
            var callbackDefer = Q.defer(),
                waitingPromise;
            waitingPromise = ActiveMSComponent.isHomeKitAddonCreated().then(function (response) {
                if (response.created) {
                    return this._handleHomes();
                } else {
                    return ActiveMSComponent.createHomeKitPlugin().then(function (isCreated) {
                        return this._handleHomes();
                    }.bind(this));
                }
            }.bind(this));
            this.ViewController.showState(ScreenState.WaitingScreen, null, {
                cancelButtonText: _("home-kit.loading.cancel"),
                message: _("home-kit.loading.message.setup"),
                callbackDefer: callbackDefer,
                waitingPromise: waitingPromise
            });
            callbackDefer.promise.then(function (screenState) {
                var defer = Q.defer();

                if (screenState === ScreenState.HomeKitSetupCode) {
                    this.ViewController.showState(ScreenState.HomeKitSetupCode, null, {
                        iconSrc: Icon.HOME_KIT.HOME,
                        title: _("home-kit.setup-code.title"),
                        message: _("home-kit.setup-code.message"),
                        continueBtnText: _('home-kit.continue'),
                        shareButtonText: _("home-kit.setup-code.share-button"),
                        continueDef: defer,
                        pairingInfo: this.pairingInfo,
                        home: ActiveMSComponent.getMiniserverName(),
                        createHome: false
                    });
                    defer.promise.done(function () {
                        this.ViewController.dismiss();
                    }.bind(this));
                } else {
                    this.ViewController.showState(screenState);
                }
            }.bind(this), function (error) {
                this.ViewController.handleError(error, _("home-kit.error.start-homekit"));
                this.closeAction();
            }.bind(this));
        }

        _handleHomes() {
            return ActiveMSComponent.getAllHomeKitHomes().then(function (homes) {
                if (homes && homes.length > 0) {
                    // Homes are already at the home app - navigate to the home selection screen
                    return ScreenState.HomeKitSelectHome;
                } else {
                    // No Homes are at the home app - create a new home with miniserver name and pair it
                    var miniserverName = ActiveMSComponent.getMiniserverName();
                    return ActiveMSComponent.addHomeKitHome(miniserverName).then(function () {
                        return ActiveMSComponent.getHomeKitPairingInfo().then(function (pairingInfo) {
                            this.pairingInfo = pairingInfo;
                            return ScreenState.HomeKitSetupCode;
                        }.bind(this));
                    }.bind(this));
                }
            }.bind(this));
        }

    }

    GUI.HomeKitWelcomeScreen = HomeKitWelcomeScreen;
});
