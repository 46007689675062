import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Location(props) {
    return (
        <Svg isreact="true"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 51.2 64"
            xmlSpace="preserve"
            enableBackground="new 0 0 51.2 64"
            {...props}
        >
            <Path
                d="M43.7 7.8c-10-10.4-26.2-10.4-36.2 0-10 10.4-10 27.1 0 37.5L25.6 64l18.1-18.7c10-10.4 10-27.2 0-37.5zm-18 27.5c-5.3 0-9.6-4.3-9.6-9.6s4.3-9.6 9.6-9.6 9.6 4.3 9.6 9.6-4.3 9.6-9.6 9.6z"
                fillRule="evenodd"
                clipRule="evenodd"
            />
        </Svg>
    )
}

export default Location
