import { useCallback, useEffect, useState } from "react";
import { useCCEvent } from "LxComponents";
import defaultBG from "../../../../resources/Images/EcoScreen/wallpaper.png";

export default function useAmbientSettings() {
    const [settings, setSettings] = useState(PersistenceComponent.getAmbientModeSettings());

    const updateSettings = () => {
        // clone to avoid modifying the dataset to be stored!
        let newSettings = cloneObjectDeep(PersistenceComponent.getAmbientModeSettings());
        let activeMSSerial = ActiveMSComponent.getActiveMiniserverSerial();
        PersistenceComponent.loadFile("AmbientBG_"+activeMSSerial).then((imgData) => {
            saveImgDataFromFile(imgData, newSettings)
        }, (err) => {
            Debug.AmbientMode && console.error("useAmbientSettings", "Failed to load miniserver specific stored background image!")
            Debug.AmbientMode && console.error("useAmbientSettings", JSON.stringify(err));
        }).finally(() => {
            if (newSettings && newSettings.imageObject && !newSettings.imageObject.source.uri) {
                saveImgDataFromFile(defaultBG, newSettings)
            }
            setSettings(newSettings);
        })
    }

    useCCEvent([CCEvent.AmbientModeSettingChanged], useCallback((event, args) => {
        updateSettings();
    }, []));

    useEffect(() => {
        updateSettings();
    }, [ActiveMSComponent.getActiveMiniserverSerial()]);

    return settings;
}

const saveImgDataFromFile = (imgData, settings) => {
    if (imgData && settings && settings.imageObject && settings.imageObject.source) {
        settings.imageObject.source.uri = imgData;
    }
}

