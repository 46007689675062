'use strict';

define(["LxComponents"], function ({ App }) {
    return class AlexaSelectUserScreen extends GUI.TableViewScreenV2 {
        //region Static
        static Template = function () {
            var nextButtonTemplate = function nextButtonTemplate() {
                return $('' + '<div class="bottom-button__container">' + '' + '<div class="container__button">' + _('home-kit.continue') + '</div>' + '</div>');
            };

            return {
                nextButtonTemplate: nextButtonTemplate
            };
        }(); //endregion Static

        constructor(details) {
            super($("<div />"));
        }

        viewDidLoad() {
            var args = arguments;
            return ActiveMSComponent.getUsers().then(users => {
                this._users = users;
                return super.viewDidLoad(...args).then(function () {
                    this._nextButton = new GUI.LxButton(this, AlexaSelectUserScreen.Template.nextButtonTemplate(), Color.BUTTON_GLOW, null, true, 250);
                    return this.appendSubview(this._nextButton);
                }.bind(this));
            });
        }

        viewWillAppear() {
            var baseCall = super.viewWillAppear(...arguments);
            this._nextButton.onButtonTapped = this._handleNextButtonTapped.bind(this);

            this._nextButton.setEnabled(false);

            return baseCall;
        }

        viewWillDisappear() {
            this._nextButton.onButtonTapped = null;
            return super.viewWillDisappear(...arguments);
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.CLOSE
            };
        }

        titleBarText() {
            return _('alexa.title');
        }

        closeAction() {
            this.ViewController.closeAction();
        }

        reloadTable() {
            this.tableContent = [];
            this.tableContent.push(this._getInfoSection());
            this.tableContent.push(this._getUsersSection());
            this.tableContent.push(this._getCreateUserSection());
            return super.reloadTable(...arguments);
        }

        _getInfoSection() {
            return {
                headerTitle: "",
                footerTitle: _('alexa.user.hint-text1') + '<br><br>' + _('alexa.user.hint-text2')
            };
        }

        _getUsersSection() {
            return {
                headerTitle: _('user'),
                rows: this._users.map(this._createCellForUser.bind(this))
            };
        }

        _createCellForUser(user) {var userEditDef = Q.defer(),
            getUserPrms;
            return {
                type: GUI.TableViewV2.CellType.CHECKABLE_BUTTON,
                content: {
                    title: user.name + (user.trustMember ? "@" + user.trustMember : ""),disabled: user.userState !== UserManagement.USER_STATES.ACTIVE,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                    buttonSrc: Icon.SETTINGS_SMALL,
                    subtitle: ActiveMSComponent.getUserStateDescription(user).stateDescription
                },
                didCheckCell: function didCheckCell() {
                    this._selectedUser = user.uuid;

                    this._nextButton.setEnabled(true);}.bind(this),
                buttonTapped: function (section, row, tableView) {
                    userEditDef.promise.finally(function (user) {
                        getUserPrms = ActiveMSComponent.getUsers(true);
                        NavigationComp.showWaitingFor(getUserPrms).then(function (users) {
                            this._users = users;
                            return this.reloadTable();
                        }.bind(this));
                    }.bind(this));

                    App.navigationRef.navigate(ScreenState.UserDetailsScreen, {
                        user: user,
                        isNewUser: false,
                        homeKitDefer: userEditDef
                    });
                }.bind(this)
            };
        }

        _handleNextButtonTapped() {
            ActiveMSComponent.setAlexaAPIUser(this._selectedUser).then(function () {
                var details = {
                    details: {
                        endpoints: null,
                        isInEditMode: false,
                        userUuid: this._selectedUser
                    },
                    state: ScreenState.AlexaControlSelectorScreen
                };
                this.ViewController.handleNextScreen(details);
            }.bind(this));
        }

        _getCreateUserSection() {
            var section = {
                rows: []
            };
            section.rows.push({
                action: this._showInformationPopup.bind(this, false),
                content: {
                    title: _('home-kit.users.add'),
                    leftIconSrc: Icon.TRANSLUCENT_ADD,
                    leftIconColor: window.Styles.colors.stateActive,
                    clickable: true
                }
            });
            return section;
        }

        _showInformationPopup(retry, name) {
            var message,
                messageColor,
                newUserDef = Q.defer();

            if (retry) {
                message = _('user.exists', {
                    username: name
                });
                messageColor = window.Styles.colors.red;
            } else {
                message = _('user.new.message');
            }

            newUserDef.promise.then(function (user) {
                ActiveMSComponent.setAlexaAPIUser(user.uuid).then(function () {
                    var details = {
                        details: {
                            endpoints: null,
                            isInEditMode: false,
                            createdUser: user
                        },
                        state: ScreenState.AlexaControlSelectorScreen
                    };
                    this.ViewController.handleNextScreen(details);
                }.bind(this));
            }.bind(this));
            return NavigationComp.showPopup({
                title: _('user.new.title'),
                message: message,
                messageColor: messageColor,
                buttonOk: _('user.add.short'),
                buttonCancel: true,
                icon: Icon.INFO,
                color: window.Styles.colors.stateActive,
                input: {
                    id: "username",
                    required: true,
                    validationRegex: Regex.USERNAME,
                    filterRegex: Regex.USERNAME_FILTER
                }
            }, PopupType.INPUT).then(function (res) {
                var user = {
                        name: res.result
                    },
                    addUser; // checks if the username already exists

                addUser = this._users.find(function (user) {
                    return user.name === res.result;
                });

                if (!addUser) {
                    ActiveMSComponent.addOrEditUser(user).then(function (user) {
                        App.navigationRef.navigate(ScreenState.UserDetailsScreen, {
                            user: JSON.parse(cloneObjectDeep(user.LL.value)),
                            allUsers: this._users,
                            isNewUser: true,
                            homeKitDefer: newUserDef
                        });
                    }.bind(this))
                } else {
                    this._showInformationPopup(true, res.result);
                }
            }.bind(this));
        }

    }
})

