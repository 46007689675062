'use strict';

define([
    "LightControlContentOld",
    "LightV2ControlMoodList",
    "LightV2ControlLightCircuitList",
    "LightV2ControlNewMoodScreen",
    "LightV2ControlEnums",
    "ControlNoteView"
], function (LightControlContentOld, LightV2ControlMoodList, LightV2ControlLightCircuitList, LightV2ControlNewMoodScreen, LightV2ControlEnums, ControlNoteView) {
    return class LightV2ControlContentOld extends LightControlContentOld {
        constructor(details) {
            super(details);
            this.isLightControl = true;
        }

        viewDidLoad() {
            var prms;
            return Q(super.viewDidLoad(...arguments)).then(function () {
                this.element.addClass("light-control-content");

                if (this.control.hasControlNotes) {
                    prms = this._createAndInsertNoteView();
                } else {
                    prms = Q.resolve();
                } // register for structural changes as they might also concern the control note.


                this._structureChangesReg = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.StructureChanged, this._checkStructureChanges.bind(this));
                return prms;
            }.bind(this));
        }

        destroy() {
            NavigationComp.removeFromUIEvent(this._structureChangesReg);
            return super.destroy(...arguments);
        }

        titleBarText() {
            return this.control.getName();
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK,
                rightSide: TitleBarCfg.CUSTOM_ICON,
                rightIconSrc: Icon.TitleBar.PLUS,
                subTitleBar: 'segmented',
                segments: [{
                    type: 'label',
                    text: _("controls.lightV2controller.lightCircuits")
                }, {
                    type: 'label',
                    text: _("controls.lightV2controller.mood_plural")
                }]
            };
        }

        titleBarActionRight() {
            this.ViewController.showState(LightV2ControlEnums.ScreenState.NEW_MOOD, null, {
                control: this.control
            });
        }

        getManualList() {
            return new LightV2ControlLightCircuitList(this.control);
        }

        getSceneList() {
            return new LightV2ControlMoodList(this.control);
        }

        _createAndInsertNoteView() {
            this.noteView = new ControlNoteView(this.control);
            this.noteView.element.addClass("light-v2-control-content-old__note-view");
            return this.insertSubviewBefore(this.noteView, this.pageView.element);
        }

        _checkStructureChanges(event, changes) {
            var isAffected = changes.controls && !!changes.controls[this.control.uuidAction];

            if (isAffected) {
                if (!this.noteView && this.control.hasControlNotes) {
                    // no view shown, but should show one
                    this._createAndInsertNoteView();
                } else if (this.noteView && !this.control.hasControlNotes) {
                    // note view shown, but shouldn't
                    this.removeSubview(this.noteView);
                } // otherwise, there's nothing to do

            }
        }

    };
});
