'use strict';

define(["AudioZoneV2DetailedContentBase", "MediaBrowserV2Favorites", "FavoritesManager"], function (AudioZoneV2DetailedContentBase, MediaBrowserV2Favorites, FavoritesManager) {
    return class ContentOverviewScreenBase extends AudioZoneV2DetailedContentBase {
        //region Private
        MAX_OVERVIEW_ITEM_CNT = 10; //endregion Private

        constructor(details) {
            super(...arguments);
            applyMixins(this, MediaStateHandlerV2.Mixin);
            this.control = details.control;
            this.reloadContentTypes = [];

            if (this._shouldShowFavorites()) {
                this.reloadContentTypes.push(this.EVENT_TYPES.ZONE_FAVORITES);
            }

            this._favorites = [];
            this._loadedInitialContent = false;
            this._isInitial = true;
        }

        isInAddMode() {
            return this.ViewController instanceof GUI.AddMediaViewControllerV2Base;
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function () {
                if (this._isInitial) {
                    this._isInitial = false;
                    this.setup();
                }
            }.bind(this));
        }

        destroy() {
            this._unregisterFromMediaServerReloadEvents.apply(this, this.reloadContentTypes);

            return super.destroy(...arguments);
        }

        getAnimation() {
            return AnimationType.FADE; // This is the "initial" view, we always want to fade the overview views
        }

        setup() {
            return this.loadData().done(function () {
                this._loadedInitialContent = true;

                this._registerForMediaServerReloadEvents.apply(this, this.reloadContentTypes);

                return this.reloadTable();
            }.bind(this), function (e) {
                this._hasError = true;
                return this.reloadTable();
            }.bind(this));
        }

        loadData() {
            return this.control.audioserverComp.connectionPromise().then(function () {
                if (this._shouldShowFavorites()) {
                    return this.getFavoriteData();
                } else {
                    return Q();
                }
            }.bind(this));
        }

        reloadTable() {
            return super.reloadTable(...arguments).then(function () {
                this.updateBackgroundView();
            }.bind(this));
        }

        updateBackgroundView() {
            if ((!this.tableContent || !this.tableContent.length) && !this._loadedInitialContent) {
                this.tableView.setBackgroundView(new GUI.BackgroundView(Icon.INDICATOR, _("loading"), null, null, null, true, true));
            } else if (this._hasError) {
                this.tableView.setBackgroundView(new GUI.BackgroundView(Icon.ERROR, _("error"), _("error.occured"), _("try-again"), this.setup.bind(this), true, true));
            } else {
                this.tableView.setBackgroundView(null);
            }
        }

        getTableView() {
            return new GUI.CardView(this.tableViewDataSource, this.tableViewDelegate);
        }

        titleBarText() {
            return this.control.getName();
        }

        getFavoriteData() {
            return FavoritesManager.shared(this.control).favorites().then(function (favs) {
                this._favorites = favs;
            }.bind(this));
        }

        receivedZoneFavoritesReloadEvent() {
            return this.processWhenVisible(function () {
                return this.getFavoriteData().then(function () {
                    return this.reloadTable();
                }.bind(this));
            }.bind(this));
        }

        /**
         * Filters the favorite items (Top section)
         * @param item
         * @returns {boolean}
         */
        favFilterFunction(item) {
            return true;
        }

        getMediaInfo(contentType) {
            return this.control.details;
        }

        getMediaId(contentType) {
            if (contentType === this.EVENT_TYPES.SERVICE) {
                return MusicServerEnum.Spotify.TYPES.MY_PLAYLISTS;
            } else if (contentType === this.EVENT_TYPES.ZONE_FAVORITES) {
                return this.control.details.playerid;
            } else {
                return 0;
            }
        }

        _getFavSectionHeaderTitle() {
            return _("media.favorites");
        }

        getTableContent() {
            var tableContent = [];

            if (!this.isInAddMode()) {
                tableContent.pushObject(this._getFavSection());
            }

            return Q.all(tableContent);
        }

        setTableContent() {
            var argv = arguments;
            return Q(this.getTableContent()).then(tableContent => {
                this.tableContent = tableContent;

                if (this.tableContent.length <= 2) {
                    this.tableContent.forEach(function (section) {
                        if (!section.isFavSection && section.hasOwnProperty("previousCustomClass")) {
                            section.previousCustomClass = section.customClass;
                            section.customClass = null;
                        }
                    });
                } else {
                    this.tableContent.forEach(function (section) {
                        if (!section.isFavSection && section.hasOwnProperty("previousCustomClass")) {
                            section.customClass = section.previousCustomClass;
                        }
                    });
                }

                return super.setTableContent(...argv);
            });
        }

        _getFavSection() {
            return this._getSection(this._getFavSectionHeaderTitle(), this._favorites.filter(this.favFilterFunction.bind(this)).map(function (item) {
                return this._getFavSectionCellForItem(item);
            }.bind(this)), null, true);
        }

        /**
         * Extracted to separate method, this allows subclasses to overwrite this method. Which is used e.g. in the
         * playlistsOverview where the favorite playlists are made browsable
         * @param item
         * @param [details] optional to pass on custom details. e.g. used in playlistsOverview, as the identifier favorits isn't working here.
         * @returns {*}
         * @private
         */
        _getFavSectionCellForItem(item, details) {
            var intDetails = details || {
                username: MusicServerEnum.NOUSER,
                identifier: MusicServerEnum.ControlContentIdentifiers.ZONE_FAVORITES
            };
            intDetails.control = this.control;
            return MediaBrowserV2Favorites.getCellFromContentTypeItem(item, intDetails, item.contentType, this.control.audioserverComp.isFileTypePlaylist(item.type), this.control.audioserverComp.isFileTypeBrowsable(item.type), this.handleOnItemCellClicked.bind(this), this.handleOnCellButtonClicked.bind(this), this.ViewController);
        }

        handleOnItemCellClicked(item, contentType, section, row) {
            if (this.control.audioserverComp.isFileTypeBrowsable(item.type)) {
                var details = {
                    username: MusicServerEnum.NOUSER,
                    lastSelectedItem: item,
                    contentTypes: [contentType]
                };
                return this.ViewController.showState(Controls.AudioZoneV2Control.MediaBrowserV2Base.getScreenStateForItem(item), null, details);
            } else if (this.ViewController instanceof GUI.AddMediaViewControllerV2Base) {
                this.handleOnCellButtonClicked.apply(this, arguments);
            } else {
                return this._playItem(item, !this.control.audioserverComp.useShufflePlay(item));
            }
        }

        handleOnCellButtonClicked(item, contentType, section, row) {
            if (this.ViewController instanceof GUI.AudioZoneV2ControlDetailedContentViewController || this.ViewController instanceof GUI.AudioZoneV2ControlContentViewController) {
                // GlobalSearch
                return this._showContextMenuForItem(item);
            } else if (this.ViewController instanceof GUI.AddMediaViewControllerV2Base) {
                var cell,
                    reloadQueue = [true];

                if (typeof section === "number" && typeof row === "number") {
                    cell = this.tableContent[section].rows[row];
                    cell.content.buttonSrc = Icon.INDICATOR_BOLD;
                    cell.content.rightIconSrc = Icon.INDICATOR_BOLD;
                    this.tableContent[section].rows[row] = cell;
                    reloadQueue.push(super.reloadRowsSelective(section, row, 1));
                }

                return Q.all(reloadQueue).then(Q(this.ViewController.addItem(item, this.getMediaInfo(contentType))).finally(() => {
                    var def = Q.defer();
                    setTimeout(() => {
                        if (cell) {
                            cell.content.buttonSrc = Icon.CHECKED;
                            cell.content.rightIconSrc = Icon.CHECKED;
                            this.tableContent[section].rows[row] = cell;
                            def.resolve(super.reloadRowsSelective(section, row, 1));
                        } else {
                            def.resolve(Q(true));
                        }
                    }, 250);
                    return def.promise;
                }));
            }
        }

        _playItem(item) {
            return this.control.audioserverComp.sendPlayerCommandFromType(item);
        }

        _getSection(title, rows, showMoreAction, isFav) {
            if (rows && rows.length) {
                return {
                    headerTitle: title,
                    rows: rows,
                    isFavSection: isFav,
                    previousCustomClass: !this.isInAddMode() ? "card-section" : null,
                    customClass: !this.isInAddMode() ? "card-section" : null,
                    sectionRightButtonTitle: showMoreAction ? _("media.global-search-button.show-more") : null,
                    sectionRightButtonColor: window.Styles.colors.brand,
                    didSelectHeader: showMoreAction
                };
            }
        }

        _getRightSideTitleBarButtons() {
            return [];
        }

        _shouldShowFavorites() {
            return true;
        }

    };
});
