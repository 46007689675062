'use strict';

class DebugScreen extends GUI.StaticScreenBase {
    //region Static
    static Template = function () {
        var getTemplate = function getTemplate() {
            return $('<div class="debug-screen__container">' + '       <div class="container__log" />' + '       <div class="container__settings" />' + '       <div class="container__buttons">' + '           ' + ImageBox.getResourceImageWithClasses(Icon.Menu.THEME, "buttons__btn theme-btn") + '           ' + ImageBox.getResourceImageWithClasses(Icon.SETTINGS_SMALL, "buttons__btn settings-btn") + '           ' + ImageBox.getResourceImageWithClasses(Icon.DELETE_2, "buttons__btn clear-btn") + '           ' + ImageBox.getResourceImageWithClasses(Icon.USER_MANAGEMENT.SHARE, "buttons__btn copy-btn") + '           ' + ImageBox.getResourceImageWithClasses(Icon.Buttons.MORE_HORIZONTAL, "buttons__btn more-btn") + '       </div>' + '     </div>');
        };

        var createSettings = function createSettings(settings, title) {
            var list = $('<form></form>');
            list.append(createFieldset(settings, title, "Debug"));
            return list;
        };

        var createFieldset = function createFieldset(settings, title, key) {
            var fieldset = $('<fieldset id="' + key + '">' + (title != null ? '<legend><b>' + title + '</b></legend>' : '') + '</fieldset>');
            var entryKey = "";

            for (var subKey in settings) {
                if (settings.hasOwnProperty(subKey)) {
                    entryKey = key + "." + subKey;

                    if (typeof settings[subKey] === 'boolean') {
                        fieldset.append(createEntry(subKey, settings[subKey], entryKey));
                    } else if (typeof settings[subKey] === 'object') {
                        fieldset.append(createFieldset(settings[subKey], subKey, entryKey));
                    }
                }
            }

            return fieldset;
        };

        var createEntry = function createEntry(key, value, id) {
            var entry = $('<div><input type="checkbox" id="' + id + '"' + (value ? ' checked' : '') + '><label for="' + id + '">' + key + '</label></div>');
            var input = entry.find("input");

            input[0].onchange = function () {
                Debug.set(this.id, this.checked);
                Debug.saveCustomSettings();
            };

            return entry;
        };

        return {
            getTemplate: getTemplate,
            createSettings: createSettings
        };
    }(); //endregion Static

    /// Static for the constructor before creating an instance
    static get enabled() {
        return window.UPDATE_LEVEL !== UpdateComp.UpdateLevel.RELEASE || PlatformComponent.isDeveloperInterface();
    }

    // For after creating the instance
    get enabled() {
        return this.constructor.enabled;
    }

    constructor() {
        super();
        Object.assign(this, ContextMenuHandler.Mixin);
        this.string = "";
        console.log("-- DebugScreen start --");
        console.info(`Language: ${this.translateToFlags(PlatformComponent.getLanguage())}`);
        console.info(`useAmPm: ${useAmPm() ? "👍" : "👎"}`);
        console.info(`isUSDateOrder: ${isUSDateOrder() ? "👍" : "👎"}`);
    }

    viewDidLoad() {
        super.viewDidLoad();
        this.element.addClass("debug-screen");
        this.element.append(DebugScreen.Template.getTemplate());
        this.elements = {
            logPlaceholder: this.element.find(".container__log"),
            settings: this.element.find(".container__settings"),
            themeBtn: this.element.find(".theme-btn"),
            settingsBtn: this.element.find('.settings-btn'),
            deleteBtn: this.element.find('.clear-btn'),
            copyBtn: this.element.find('.copy-btn'),
            moreBtn: this.element.find('.more-btn')
        };
        this.elements.settings.hide();
        this.elements.settings.append(DebugScreen.Template.createSettings(Debug));
    }

    viewWillAppear() {
        super.viewWillAppear();
        this.elements.moreBtn.toggle(this._getShowMoreOptions().length > 0);

        this._updateLog();
    }

    viewDidAppear() {
        super.viewDidAppear();
        this.elements.themeBtn.bind("click", this._toggleTheme.bind(this));
        this.elements.settingsBtn.bind("click", this._showSettings.bind(this));
        this.elements.deleteBtn.bind("click", this._clearBtnTapped.bind(this));
        this.elements.copyBtn.bind("click", this._copyLog.bind(this));
        this.elements.moreBtn.bind("click", this._showMoreOptions.bind(this));

        this._scrollToBottom();
    }

    viewDidDisappear(viewRemainsVisible) {
        this.elements.themeBtn.unbind("click", this._toggleTheme.bind(this));
        this.elements.settingsBtn.unbind("click", this._showSettings.bind(this));
        this.elements.deleteBtn.unbind("click", this._clearBtnTapped.bind(this));
        this.elements.copyBtn.unbind("click", this._copyLog.bind(this));
        super.viewDidDisappear(viewRemainsVisible);
    }

    getAnimation() {
        return AnimationType.HD_OVERLAY_FULLSCREEN;
    }

    titleBarText() {
        return "Debug";
    }

    titleBarAction() {
        debugConsole.unregisterFromLogs();
        this.remove();
    }

    destroy() {
        console.log("-- DebugScreen end --");
        super.destroy();
    }

    registerShake() {
        if (window.hasOwnProperty("shake")) {
            shake.startWatch(this.show.bind(this));
        }

        this._registerQueueErrors();
    }

    unregisterShake() {
        if (window.hasOwnProperty("shake")) {
            shake.stopWatch();
        }

        this._unregisterQueueErrors();
    }

    registerKeypress() {
        window.addEventListener('keypress', this._onkeypress.bind(this), false);

        this._registerQueueErrors();
    }

    unregisterKeypress() {
        window.removeEventListener('keypress', this._onkeypress.bind(this), false);

        this._unregisterQueueErrors();
    }

    // Private methods
    translateToFlags(localeParts) {
        return localeParts.split("-").map(localePart => {
            const codePoints = localePart
                .toUpperCase()
                .split('')
                .map(char =>  127397 + char.charCodeAt());
            return String.fromCodePoint(...codePoints);
        }).join("-")
    }

    _updateLog() {
        this.elements.logPlaceholder.empty(); // logs

        var logs = debugConsole.getLogs();

        for (var i = 0; i < logs.length; i++) {
            this._log(logs[i].time, logs[i].string, logs[i].level, true);
        }

        debugConsole.registerForLogs(this._log.bind(this));
    }

    _showSettings() {
        this.elements.settings.toggle();
    }

    _clearBtnTapped() {
        this.elements.settings.hide();
        var content = {
            buttons: [{
                title: "clear log"
            }, {
                title: "set default settings"
            }],
            buttonCancel: true,
            icon: Icon.DELETE,
            color: window.Styles.colors.orange
        };
        NavigationComp.showPopup(content).then(function (pressedBtn) {
            if (pressedBtn === 0) {
                this.elements.logPlaceholder.empty();
                debugConsole.clearLogs();
            } else if (pressedBtn === 1) {
                Debug.def();
                Debug.deleteCustomSettings();

                this._reloadSettings();
            }
        }.bind(this));
    }

    _reloadSettings() {
        this.elements.settings.empty();
        this.elements.settings.append(DebugScreen.Template.createSettings(Debug));
    }

    _toggleTheme() {
        var op = parseFloat(parseFloat(this.element.css("opacity")).toFixed(1));

        if (op === 1) {
            op = 0.1;
        } else if (op === 0.8) {
            op = 1;
        } else if (op === 0.5) {
            op = 0.8;
        } else {
            op = 0.5;
        }

        this.element.css("opacity", op);
    }

    _copyLog() {
        var elementStr = this.elements.logPlaceholder[0].outerHTML,
            comprehensiveInfo = "<p class='comprehensive-info'>" + "App: " + PlatformComponent.getAppInfoObj().appVersion + "<br>",
            netStat = PlatformComponent.getNetworkStatus(),
            styles;
        elementStr = elementStr.replace('\"', '"');
        comprehensiveInfo = comprehensiveInfo + "Platform: " + JSON.stringify(PlatformComponent.getPlatformInfoObj()) + "<br>";
        comprehensiveInfo = comprehensiveInfo + "Network: " + (netStat === NetworkStatus.NOT_REACHABLE ? "Not Reachable" : netStat === NetworkStatus.CELL ? "Cellular" : "LAN/WLAN") + "<br>";
        comprehensiveInfo = comprehensiveInfo + "Language: " + JSON.stringify(PlatformComponent.getLanguage()) + ", ";
        comprehensiveInfo = comprehensiveInfo + "Battery: " + JSON.stringify(PlatformComponent.getBatteryStateObj()) + "<br>";
        comprehensiveInfo = comprehensiveInfo + "</p>";
        elementStr = comprehensiveInfo + elementStr;
        styles = "" + "body { background: white; font-family: monospace; }" + ".comprehensive-info { color: " + Color.CARD_TEXT_A + "; }" + ".log { color: black; }" + ".info { color: " + window.Styles.colors.blue + "; }" + ".warn { color: " + window.Styles.colors.orange + "; }" + ".error { color: " + window.Styles.colors.red + "; }" + // This is the darkmode part of the Debuglog file
            "@media (prefers-color-scheme: dark) {" + "   html {" + "       scrollbar-face-color: #646464;" + "       scrollbar-base-color: #646464;" + "       scrollbar-3dlight-color: #646464;" + "       scrollbar-highlight-color: #646464;" + "       scrollbar-track-color: #000;" + "       scrollbar-arrow-color: #000;" + "       scrollbar-shadow-color: #646464;" + "       scrollbar-dark-shadow-color: #646464;" + "   }" + "   ::-webkit-scrollbar { width: 8px; height: 3px; }" + "   ::-webkit-scrollbar-button {  background-color: #666; }" + "   ::-webkit-scrollbar-track {  background-color: #646464; }" + "   ::-webkit-scrollbar-track-piece { background-color: #000; }" + "   ::-webkit-scrollbar-thumb { height: 50px; background-color: #666; border-radius: 3px; }" + "   ::-webkit-scrollbar-corner { background-color: #646464; }" + "   ::-webkit-resizer { background-color: #666; }" + "   body { background: " + Color.APP_BACKGROUND_B + "; }" + "   .comprehensive-info { color: " + Color.CARD_TEXT_B + "; }" + "   .log { color: " + Color.TEXT_SECONDARY_B + "; }" + "}";
        var htmlString = '' + '<!DOCTYPE html>' + '<html lang="en">' + '    <head>' + '        <title>Debug</title>' + '        <style>' + styles + '</style>' + '    </head>' + '    <body>' + elementStr + '</body>' + '</html>';
        var base64 = new Blob([htmlString], {
                type: 'text/html'
            }),
            platform = PlatformComponent.getPlatformInfoObj().platform;

        if (platform === PlatformType.Android) {
            var fr = new FileReader();

            fr.onload = function (e) {
                var str = e.target.result;
                str = str.slice(22);
                exportBase64File("DebugLog.html", str, "text/html", this.elements.copyBtn[0]);
            }.bind(this);

            fr.readAsDataURL(base64);
        } else if (platform === PlatformType.IOS) {
            var origin;

            if (this.elements.copyBtn[0]) {
                origin = this.elements.copyBtn[0].getBoundingClientRect();
                origin = {
                    x: origin.left + origin.width / 2,
                    y: origin.top + origin.height / 2
                };
            }

            debugShare.share(null, function (e) {
                console.error("LOG!!! --> " + JSON.stringify(e));
            }, origin);
        } else if (PlatformComponent.isElectron) {
            loxoneControl.saveFile(null, null, "debug.html", htmlString);
        } else {
            var url = URL.createObjectURL(base64);
            var a = document.createElement("a");
            a.href = url;
            a.download = "debug.html";
            a.click();
        }
    }

    _log(time, logString, level, dontScroll) {
        if (dontScroll === undefined) {
            // check if user looks somewhere (not scrolled down to bottom)
            dontScroll = this.elements.logPlaceholder.scrollTop() !== this.elements.logPlaceholder[0].scrollHeight - this.elements.logPlaceholder[0].clientHeight;
        }

        this.elements.logPlaceholder.append($('<pre class="' + level + '"><b>' + time + ' </b></pre>').append($('<span />').text(logString)));
        !dontScroll && this._scrollToBottom();
    }

    _scrollToBottom() {
        this.elements.logPlaceholder.scrollTop(Math.max(0, this.elements.logPlaceholder[0].scrollHeight - this.elements.logPlaceholder[0].clientHeight));
    }

    _printUnhandledQueueErrors() {
        var reasons = Q.getUnhandledReasons();

        while (reasons.length) {
            console.error("ERROR in Q promise!");
            console.error(reasons.shift());
        }

        Q.resetUnhandledRejections();
    }

    _registerQueueErrors() {
        Q.longStackSupport = true;
        this.queueInterval = setInterval(this._printUnhandledQueueErrors, 2000);
    }

    _unregisterQueueErrors() {
        clearInterval(this.queueInterval);
    }

    /**
     * Will toggle itself if the string "debug" is entered anywhere + will show the chrome dev tools if the
     * DebugScreen is visible and the shortcut "shift + i" is used on Electron applications
     * @param e
     * @private
     */
    _onkeypress(e) {
        var char = String.fromCharCode(e.charCode); // Will show the chrome dev tools if the DebugScreen is visible and the shortcut "shift + i" is used

        if (PlatformComponent.isElectron) {
            if (e.shiftKey && char.toLowerCase() === "i" && this._viewDidAppearPassed) {
                window.electron.remote.debug.toggleDevTools();
                return;
            }
        }

        if (!char && e['key']) {
            char = e['key'];
        }

        if (char === "Enter" || char === "\r" || char === "\n" || char === "Meta") {
            return;
        }

        char = char.replace("U+", "");
        char = '"' + char.replace(/([0-9a-z]{4})/g, '\\u$1') + '"';
        char = JSON.parse(char);
        char = char.toUpperCase();
        this.string += char;

        if ("DEBUG".indexOf(this.string) === 0) {
            if (this.string === "DEBUG") {
                // done!
                this.string = "";
                GUI.DebugScreen.toggle();
            } else if (char === "D") {
                this.string = "D";
                clearTimeout(this.timeout);
                this.timeout = setTimeout(function () {
                    this.string = "";
                }.bind(this), 3000);
            }
        } else {
            clearTimeout(this.timeout);
            this.string = "";
        }
    }

    /**
     * Returns an array of objects with key title and action
     * @private
     */
    _getShowMoreOptions() {
        var options = [];

        if (window.electron) {
            options.push({
                title: "WebRTC Internals",
                action: window.electron.remote.debug.showChromePage.bind(this, "webrtc-internals")
            });
            options.push({
                title: "GPU Info",
                action: window.electron.remote.debug.showChromePage.bind(this, "gpu")
            });
            options.push({
                title: "Arbitrary Chrome Page",
                action: function () {
                    NavigationComp.showPopup({
                        title: "Arbitrary Chrome Page",
                        message: "Enter the name of the Chrome page you want to access (chrome:// will automatically be added)",
                        buttonOk: _("open"),
                        buttonCancel: true,
                        color: window.Styles.colors.stateActive,
                        allowEmptyString: false,
                        input: {
                            placeholder: "webrtc-internals"
                        }
                    }, PopupType.INPUT).then(function (res) {
                        return window.electron.remote.debug.showChromePage(res.result);
                    }.bind(this));
                }.bind(this)
            });
        }

        if (Controls.AtheneControl && Controls.AtheneControl.SingleTons.MediaHandler.__instances) {
            Object.values(Controls.AtheneControl.SingleTons.MediaHandler.__instances).forEach(function (mediaHandler) {
                if (!mediaHandler.control.isTrustMember) {
                    options.push({
                        title: mediaHandler.control.getName(),
                        action: this._showOptionsForMediaHandler.bind(this, mediaHandler)
                    });
                }
            }.bind(this));
        }

        return options;
    }

    /**
     * Shows the more options
     * @private
     */
    _showMoreOptions() {
        this._showContextMenu(this._getShowMoreOptions(), _("more"));
    }

    _showOptionsForMediaHandler(mediaHandler) {
        var options = [{
            title: "Send HTTP Command",
            action: function () {
                NavigationComp.showPopup({
                    title: mediaHandler.control.getName(),
                    message: "Execute any arbitrary Webservice (without leading slash)".debugify(),
                    buttonOk: "Execute".debugify(),
                    buttonCancel: true,
                    allowEmptyString: true,
                    input: {
                        value: ""
                    }
                }, PopupType.INPUT).then(function (res) {
                    mediaHandler.sendCommandViaHTTP(res.result);
                }.bind(this));
            }.bind(this)
        }];

        this._showContextMenu(options, mediaHandler.control.getName());
    }

}

GUI.DebugScreen = DebugScreen;
