'use strict';

define("WebsocketSignaling", ["SignalingBase"], function (SignalingBase) {
    return class WebsocketSignaling extends SignalingBase {
        //region Static
        static RPC_ID = {
            START: 0
        };
        static RPC_ID_INC = 1;
        static PRC_METHOD = {
            AUTH: "authenticate",
            REACH_MODE: "reachMode",
            CALL: "call",
            HANGUP: "hangup",
            ADD_ICE_CANDIDATE: "addIceCandidate"
        };
        static RPC_NOTIFICATIONS = {
            READY: "ready",
            CALL_STATE: "callState",
            KICK: "kick",
            NO_ICE_MATCH: "noIceMatchFound",
            ICE_GATHERING_FINISHED: "iceGatheringFinished",
            EXCESSIVE_TEMP: "excessiveTemp",
            ONLINE_CHANGED: "onlineChanged",
            LEGACY_INTERNET_CHANGED: "internetChanged",
            TTS_INFO_CHANGED: "ttsInfoChanged",
            CLOSE_DUE_TO_NO_DEMAND: "closeDueToNoDemand"
        };
        static RPC_ERROR = {
            PARSE_ERROR: {
                code: -32700,
                message: "Parse error"
            },
            INVALID_REQUEST: {
                code: -32600,
                message: "Invalid Request"
            },
            METHOD_NOT_FOUND: {
                code: -32061,
                message: "Method not found"
            },
            INVALID_PARAMS: {
                code: -32062,
                message: "Invalid params"
            },
            INTERNAL_ERROR: {
                code: -32603,
                message: "Internal error"
            }
        };
        static METHOD_TYPE = {
            METHOD: 0,
            NOTIFICATION: 1
        };
        static WS_PROTOCOL = "webrtc-signaling";
        static OFFER_ACTION = {
            NEW: "new",
            ADD_AUDIO: "add_audio",
            REMOVE_AUDIO: "remove_audio"
        };
        static OFFER_EXTENSION = {
            NONE: 0,
            STATIC_VIDEO: 1
        };
        static OFFER_ERROR = {
            METHOD_NOT_ALLOWED: 405,
            NO_SUPPORTED_CODEC: 415,
            CALL_ACTIVE_IN_OTHER_SESSION: 486,
            NUMBER_OF_VIDEO_STREAMS_EXCEEDED: 509
        };
        static CALL_STATE = {
            UNKNOWN: -1,
            AVAILABLE: 0,
            OCCUPIED: 1
        };
        static KICK_TYPE = {
            CALL_RESPONSE: 0,
            // Another user responded to an eminent call, he has priority over the call
            RE_RING: 1,
            // A user hit the bell again while a call was active
            STREAM_STATE_CHANGED: 2,
            // WIP
            STREAM_ERROR: 3 // Is thrown when the stream runs in some kine of error

        };
        static _DELEGATE_FN = {
            COM_ERROR: "onComError",
            ADD_ICE_CANDIDATE: "onAddIceCandidate",
            CALL_STATE_CHANGE: "onCallStateChange",
            KICKED: "onWebRtcKick",
            NO_ICE_MATCH_FOUND: "onNoIceMatchFound",
            EXCESSIVE_TEMP: "onExcessiveTemp",
            ONLINE_CHANGED: "onOnlineChanged",
            TTS_INFO_CHANGED: "onTTSInfoChanged"
        }; //endregion Static

        //region Private
        //fast-class-es6-converter: extracted from defineStatic({}) content
        MAX_CMD_TIMEOUT = 10000;
        ON_DEMAND_CLOSE_TIMEOUT = 15000; //endregion Private

        //region Getter
        get sessionToken() {
            return this._sessionToken;
        } //endregion Getter


        constructor({
                        url,
                        delegate,
                        onDemand
                    }) {
            super(...arguments);
            this._url = url;
            this._delegate = delegate;
            this._isOnDemand = !!onDemand;
            this._jsonRpcClientId = null;
            this._cmdDefMap = {};
            this._cmdTimeoutMap = {};
            this._socket = null;
            this._authReadyDef = null;
            this._onDemandCloseTimeout = null;
            this._sessionToken = null;
            this._modulus = null;
            this._exp = null;
        }

        destroy() {
            this._closeSocket();

            super.destroy(...arguments);
        }

        /**
         * Function to set up the communication layer, setup Websocket and authenticate
         * @returns {Promise}
         */
        setupComLayer() {
            if (!this._isAuthorized()) {
                try {
                    this._authReadyDef = Q.defer(); // Reset the rpc-id

                    this._jsonRpcClientId = this.constructor.RPC_ID.START;

                    let sockUrl = this._url.replace("http", "ws");

                    Debug.Control.Athene.Signaling && console.log(this.name, "Try to open socket -> " + sockUrl);
                    this._socket = new WebSocket(sockUrl, this.constructor.WS_PROTOCOL);
                    this._socket.onopen = this._onOpen.bind(this);
                    this._socket.onclose = this._onClose.bind(this);
                    this._socket.onerror = this._onError.bind(this);
                    this._socket.onmessage = this._onMessage.bind(this);
                    let originalSend = this._socket.send;

                    this._socket.send = (...args) => {
                        this._isAuthorized() && this._startOnDemandTimeoutIfNecessary();
                        return originalSend.apply(this._socket, args);
                    };

                    return this._authReadyDef.promise.then(() => {
                        this._startOnDemandTimeoutIfNecessary();

                        return Q.resolve();
                    });
                } catch (e) {
                    return Q.reject(e);
                }
            } else {
                return Q.resolve();
            }
        }

        /**
         * Sends an offer
         * @param localDescription The peer connections local description
         * @param offerAction The Offer Actions (See OFFER_ACTION enum in the static constructor)
         * @param [force] Will close the oldest WebRTC session in favour of this one, defaults to false
         * @param [exception] Will close the oldest WebRTC session in favour of this one, defaults to false
         * @returns {Q.Promise<unknown>} resolves with the remoteDescription or rejects with an error
         */
        sendOffer({localDescription, offerAction, force = false, exception = WebsocketSignaling.OFFER_EXTENSION.NONE}) {
            return this._isSocketAuthenticated().then(function () {
                // Ensure we always send just one offer to prevent any issues regarding WebRTC
                if (!this._sendingOfferPrms) {
                    this._sendingOfferPrms = this._sendJsonRpcCmd(this.constructor.PRC_METHOD.CALL, [localDescription, offerAction, !!force, exception]).finally(function () {
                        delete this._sendingOfferPrms;
                    }.bind(this));
                }

                return this._sendingOfferPrms;
            }.bind(this));
        }

        /**
         * Sends a hangup command
         */
        hangUp() {
            return this._isSocketAuthenticated().then(function () {
                return this._sendJsonRpcCmd(this.constructor.PRC_METHOD.HANGUP);
            }.bind(this), function (e) {
                return Q.resolve();
            });
        }

        /**
         * Adds a given iceCandidate to the the remote peer
         * @param iceCandidate
         * @return {Q.Promise<unknown>}
         */
        addIceCandidate(iceCandidate) {
            if (Debug.Control.Athene.DevFeatures.FULL_ICE_CANDIDATE) {
                return this.execArbitraryMethod(this.constructor.PRC_METHOD.ADD_ICE_CANDIDATE, [iceCandidate]);
            } else {
                var candidate, sdpMLineIndex;

                if ("candidate" in iceCandidate) {
                    candidate = iceCandidate.candidate;
                }

                if ("sdpMLineIndex" in iceCandidate) {
                    sdpMLineIndex = iceCandidate.sdpMLineIndex;
                }

                return this.execArbitraryMethod(this.constructor.PRC_METHOD.ADD_ICE_CANDIDATE, [candidate, sdpMLineIndex]);
            }
        }

        /**
         * Notifies the Intercom about the ICE Gathering Finished State
         * @return {Q.Promise<*>}
         */
        onIceGatheringFinished() {
            return this.execArbitraryMethod(this.constructor.RPC_NOTIFICATIONS.ICE_GATHERING_FINISHED, null, this.constructor.METHOD_TYPE.NOTIFICATION);
        }

        /**
         * Executes any given method with the given params
         * @param method
         * @param [params]
         * @param [type]
         * @return {Q.Promise<*>}
         */
        execArbitraryMethod(method, params, type = this.constructor.METHOD_TYPE.METHOD) {
            return this._isSocketAuthenticated().then(function () {
                if (type === this.constructor.METHOD_TYPE.METHOD) {
                    return this._sendJsonRpcCmd(method, params);
                } else {
                    return Q(this._sendJsonRpcNotification(method, params));
                }
            }.bind(this));
        }

        _startOnDemandTimeoutIfNecessary() {
            if (this._isOnDemand) {
                this._stopOnDemandTimeout();

                this._onDemandCloseTimeout = setTimeout(() => {
                    if (!this._delegate.peerConnection) {
                        this._closeSocket();
                    } else {
                        console.info(this.name, "WebRTC is active, demand is given - don't close");

                        this._startOnDemandTimeoutIfNecessary();
                    }
                }, this.ON_DEMAND_CLOSE_TIMEOUT);
            }
        }

        _stopOnDemandTimeout() {
            if (this._onDemandCloseTimeout) {
                clearTimeout(this._onDemandCloseTimeout);
            }
        }

        _onOpen() {
            Debug.Control.Athene.Signaling && console.log(this.name, "✅ socket successfully opened");
            this._authChallengeTimeout && clearTimeout(this._authChallengeTimeout);
            this._authChallengeTimeout = setTimeout(function () {
                // No "authenticate" notification has been received with in the this.MAX_CMD_TIMEOUT,
                // the device may not be an intercom!
                this._authReadyDef.reject(new Error("No auth challenge within " + this.MAX_CMD_TIMEOUT / 1000 + " seconds"));

                this._closeSocket();

                this._authChallengeTimeout = null;
            }.bind(this), this.MAX_CMD_TIMEOUT);
        }

        _onClose(e) {
            Debug.Control.Athene.Signaling && console.log(this.name, "💀 socket closed: " + e.reason);

            if (e.wasClean) {
                this._onConnectionClosed("regular");
            } else {
                this._onConnectionClosed(e.reason);
            } // clear all waiting defers and timeouts


            Object.keys(this._cmdDefMap).forEach(function (rpcId) {
                this._cmdDefMap[rpcId].reject(new Error("Websocket closed"));

                delete this._cmdDefMap[rpcId];

                if (this._cmdTimeoutMap.hasOwnProperty(rpcId)) {
                    clearTimeout(this._cmdTimeoutMap[rpcId]);
                    delete this._cmdTimeoutMap[rpcId];
                }
            }.bind(this)); // Reset all variables which are bound to a session

            this._cmdDefMap = {};
            this._cmdTimeoutMap = {};
            this._sessionToken = null;
            this._modulus = null;
            this._exp = null;

            this._closeSocket();

            this._stopOnDemandTimeout();

            this._socket && (this._socket.onclose = null);
        }

        _onError(e) {
            Debug.Control.Athene.Signaling && console.log(this.name, "❌ socket error");
            Debug.Control.Athene.Signaling && console.error(e);

            this._closeSocket({
                error: e
            });

            this._stopOnDemandTimeout();

            this._notifyDelegates(this.constructor._DELEGATE_FN.COM_ERROR, e);
        }

        /**
         * Handle messages from the Websocket, resolves or rejects the commands if available
         * @param e The message received by the Websocket
         * @private
         */
        _onMessage(e) {
            try {
                var rpc = JSON.parse(e.data);

                if (!rpc.hasOwnProperty("method")) {
                    if (rpc.hasOwnProperty("id")) {
                        // We got a response to a request we made, process it
                        this._processJsonRpcResponse(rpc);
                    } else {
                        console.warn("Received invalid JSON-RPC message!");
                    }
                } else {
                    if (rpc.hasOwnProperty("id")) {
                        // Athene executes a method on our side
                        this._processAtheneJsonRpcCall(rpc);
                    } else {
                        // We got a notification from the Athene, process it
                        this._processJsonRpcNotification(rpc);
                    }
                }
            } catch (e) {
                console.error(e);
                var jsonRpcError = this.constructor.RPC_ERROR.PARSE_ERROR;
                jsonRpcError.data = e.message; // Send the Error message back to the Athene, so Johannes knows what's wrong with the JSON

                this._socket.send(JSON.stringify(this._getJsonRpcErrorResponse(jsonRpcError, -1)));
            }

            this._startOnDemandTimeoutIfNecessary();
        }

        _closeSocket({
                         error
                     } = {}) {
            if (!this._socket) {
                return;
            }

            this._socket.close();

            this._socket.onopen = null;
            this._socket.onerror = null;
            this._socket.onmessage = null;
            this._socket = null;
        }

        /**
         * Will resolve if the socket is ready to communicate authorized, rejects with an error if not
         * @return {Q.Promise<unknown>}
         * @private
         */
        _isSocketAuthenticated() {
            if (this._isAuthorized()) {
                return Q.resolve();
            } else {
                return Q.reject(new Error("Unauthorized!"));
            }
        }

        /**
         * Checks if the Socket is currently authenticated
         * @return {null|*|boolean}
         * @private
         */
        _isAuthorized() {
            return this._socket && this._authReadyDef && this._authReadyDef.promise.inspect().state === "fulfilled";
        }

        /**
         * Simply sends the JSON-RPC command to the Athene, saves the defer and timeout for later handling
         * @param method The JSON-RPC Method
         * @param [params] Array of params
         * @return {Q.Promise<unknown>}
         * @private
         */
        _sendJsonRpcCmd(method, params) {
            if (this._socket) {
                var rpc = this._getJsonRpcRequest(method, params),
                    def = Q.defer();

                def.rpc = rpc;
                this._cmdDefMap[rpc.id] = def;
                this._cmdTimeoutMap[rpc.id] = setTimeout(this._handleTimeoutForRpcWithId.bind(this, rpc.id), this.MAX_CMD_TIMEOUT);

                if (Debug.Communication) {
                    //data.substring(0, 40) + " ... " + data.substring(data.length - 60, data.length - 1)
                    var paramsString = (params || []).map(JSON.stringify).join(", ");

                    if (paramsString.length >= 50) {
                        paramsString = paramsString.substr(0, 25) + "[...]" + paramsString.substring(paramsString - 25, paramsString.length - 1);
                    }

                    CommTracker.track(def.promise, CommTracker.Transport.INTERCOM_GEN_2_SIGNALING, method + "(" + paramsString + ") [rpcId = " + rpc.id + "]");
                }

                this._socket.send(JSON.stringify(rpc));

                return def.promise;
            } else {
                return Q.reject(new Error("Socket not ready for method: '" + method + "'"));
            }
        }

        /**
         * Simply sends the JSON-RPC notification to the Athene
         * @param method The JSON-RPC notification method
         * @param [params] Array of params
         * @return {Q.Promise<unknown>}
         * @private
         */
        _sendJsonRpcNotification(method, params) {
            if (this._socket) {
                var rpc = this._getJsonRpcRequest(method, params, true);

                this._socket.send(JSON.stringify(rpc));

                return Q.resolve();
            } else {
                return Q.reject(new Error("Socket not ready for notification with method: '" + method + "'"));
            }
        }

        /**
         * Will handle the timeout for a given JSON-RPC command
         * @note Will reject the defer with a timeout error
         * @param rpcId
         * @private
         */
        _handleTimeoutForRpcWithId(rpcId) {
            if (this._cmdDefMap.hasOwnProperty(rpcId)) {
                this._cmdDefMap[rpcId].reject(new Error("Timeout: " + this._cmdDefMap[rpcId].rpc.method));

                delete this._cmdDefMap[rpcId];
            }

            delete this._cmdTimeoutMap[rpcId];
        }

        /**
         * Creates an JSON-RPC request object to send from the App to the Athene
         * @note https://www.jsonrpc.org/specification
         * @param method The method to execute
         * @param [params] Array of params
         * @param [isNotification] Won't add an id property
         * @return {Object}
         * @private
         */
        _getJsonRpcRequest(method, params, isNotification = false) {
            var rpc = {
                jsonrpc: "2.0",
                method: method,
                id: this._jsonRpcClientId
            };

            if (params) {
                rpc.params = params;
            }

            if (!isNotification) {
                this._jsonRpcClientId += this.constructor.RPC_ID_INC;
            } else {
                delete rpc.id;
            }

            return rpc;
        }

        /**
         * Returns a JSON-RPC object for a successful execution with result data
         * @param id
         * @param [data] arbitrary object
         * @return {{result, id, jsonrpc: string}}
         */
        _getJsonRpcSuccessResponse(id, data) {
            var jsonRpc = {
                jsonrpc: "2.0",
                result: {
                    code: 200,
                    message: "Ok"
                },
                id: id
            };

            if (data) {
                jsonRpc.result.data = data;
            }

            return jsonRpc;
        }

        /**
         * Returns a JSON-RPC object for an unsuccessful execution with result data
         * @param error The JSON-RPC compatible error object {code: XXX, message: "XXX"}
         * @param id
         * @param [data] Object will be added to the error object if provided
         * @return {{id, jsonrpc: string, error}}
         */
        _getJsonRpcErrorResponse(error, id, data) {
            if (data) {
                error.data = data;
            }

            return {
                jsonrpc: "2.0",
                error: error,
                id: id
            };
        }

        _processJsonRpcResponse(rpc) {
            var associatedDef = this._cmdDefMap[rpc.id];

            if (associatedDef) {
                if (rpc.hasOwnProperty("error")) {
                    associatedDef.reject({
                        code: rpc.error.code,
                        message: rpc.error.message
                    });
                } else {
                    associatedDef.resolve(rpc.result.data);
                }

                delete this._cmdDefMap[rpc.id];

                if (this._cmdTimeoutMap.hasOwnProperty(rpc.id)) {
                    clearTimeout(this._cmdTimeoutMap[rpc.id]);
                    delete this._cmdTimeoutMap[rpc.id];
                }
            } else {
                console.warn("Couldn't find defer for JSON-RPC: " + rpc.id);
            }
        }

        /**
         * A Notification has been sent from the Athene to the Client, process it if known
         * @param rpcResp
         * @private
         */
        _processJsonRpcNotification(rpcResp) {
            switch (rpcResp.method) {
                case this.constructor.RPC_NOTIFICATIONS.READY:
                    this._authReadyTimeout && clearTimeout(this._authReadyTimeout);
                    this._authReadyTimeout = null;

                    this._authReadyDef.resolve();

                    break;

                case this.constructor.RPC_NOTIFICATIONS.CALL_STATE:
                    this._notifyDelegates(this.constructor._DELEGATE_FN.CALL_STATE_CHANGE, rpcResp.params);

                    break;

                case this.constructor.RPC_NOTIFICATIONS.KICK:
                    // The kick notification has been extended during development
                    var isLegacyKick = rpcResp.params.length === 1;

                    if (isLegacyKick) {
                        this._notifyDelegates(this.constructor._DELEGATE_FN.KICKED, [this.constructor.KICK_TYPE.CALL_RESPONSE, rpcResp.params[0]]);
                    } else {
                        this._notifyDelegates(this.constructor._DELEGATE_FN.KICKED, rpcResp.params);
                    }

                    break;

                case this.constructor.RPC_NOTIFICATIONS.NO_ICE_MATCH:
                    this._notifyDelegates(this.constructor._DELEGATE_FN.NO_ICE_MATCH_FOUND, rpcResp.params);

                    break;

                case this.constructor.RPC_NOTIFICATIONS.EXCESSIVE_TEMP:
                    this._notifyDelegates(this.constructor._DELEGATE_FN.EXCESSIVE_TEMP, rpcResp.params);

                    break;

                case this.constructor.RPC_NOTIFICATIONS.ONLINE_CHANGED:
                case this.constructor.RPC_NOTIFICATIONS.LEGACY_INTERNET_CHANGED:
                    this._notifyDelegates(this.constructor._DELEGATE_FN.ONLINE_CHANGED, rpcResp.params);

                    break;

                case this.constructor.RPC_NOTIFICATIONS.TTS_INFO_CHANGED:
                    this._notifyDelegates(this.constructor._DELEGATE_FN.TTS_INFO_CHANGED, rpcResp.params);

                    break;

                default:
                    console.warn("Unknown JSON-RPC notify method: " + rpcResp.method);
            }
        }

        /**
         * Process anny RPCs from the Athene
         * @param rpc The JSON-RPC object
         * @return {Q.Promise<void>}
         * @private
         */
        _processAtheneJsonRpcCall(rpc) {
            var responsePrms;

            switch (rpc.method) {
                case this.constructor.PRC_METHOD.AUTH:
                    this._authChallengeTimeout && clearTimeout(this._authChallengeTimeout);
                    this._authChallengeTimeout = null;
                    responsePrms = this._processAuth.apply(this, rpc.params);
                    break;

                case this.constructor.PRC_METHOD.REACH_MODE:
                    responsePrms = Q.resolve([CommunicationComponent.getCurrentReachMode()]);
                    break;

                case this.constructor.PRC_METHOD.ADD_ICE_CANDIDATE:
                    var candidateInitDict = {};

                    if (Debug.Control.Athene.DevFeatures.FULL_ICE_CANDIDATE) {
                        candidateInitDict = rpc.params[0];
                    } else {
                        candidateInitDict.candidate = rpc.params[0];
                        candidateInitDict.sdpMLineIndex = rpc.params[1];

                        if (rpc.params[2] !== undefined && rpc.params[2] !== null) {
                            candidateInitDict.sdpMid = rpc.params[2];
                        }

                        if (rpc.params[3] !== undefined && rpc.params[3] !== null) {
                            candidateInitDict.usrnameFragment = rpc.params[3];
                        }
                    }

                    this._notifyDelegates(this.constructor._DELEGATE_FN.ADD_ICE_CANDIDATE, new RTCIceCandidate(candidateInitDict));

                    responsePrms = Q.resolve();
                    break;

                default:
                    responsePrms = Q.reject(this.constructor.RPC_ERROR.METHOD_NOT_FOUND);
            }

            return responsePrms.then(function (responseParams) {
                return this._getJsonRpcSuccessResponse(rpc.id, responseParams);
            }.bind(this), function (error) {
                var jsonRpc;

                if (error && error.hasOwnProperty("code") && error.hasOwnProperty("message")) {
                    jsonRpc = this._getJsonRpcErrorResponse(error, rpc.id, error.data);
                } else {
                    developerAttention("Please throw a correct PRC compatible error object with code and message property, data property is optional");
                    jsonRpc = this._getJsonRpcErrorResponse(this.constructor.RPC_ERROR.INTERNAL_ERROR, rpc.id);
                }

                return jsonRpc;
            }.bind(this)).then(function (jsonRpc) {
                this._socket.send(JSON.stringify(jsonRpc));
            }.bind(this));
        }

        /**
         * Cryptographic authentication for the current signaling session
         * @param sessionToken
         * @param modulus
         * @param exp
         * @return {Q.Promise<(*|string)[]>}
         * @private
         */
        _processAuth(sessionToken, modulus, exp) {
            this._sessionToken = sessionToken;
            this._modulus = modulus;
            this._exp = exp;

            if (!this._sessionToken || !this._modulus || !this._exp) {
                this._authReadyDef.reject(new Error(this.constructor.Error.INVALID_PARAMS.message));

                return Q.reject(this.constructor.Error.INVALID_PARAMS);
            } else {
                this._authReadyTimeout && clearTimeout(this._authReadyTimeout);
                this._authReadyTimeout = setTimeout(function () {
                    this._authReadyDef.reject(new Error("Timeout: Authentication"));
                }.bind(this), this.MAX_CMD_TIMEOUT);

                var token = ActiveMSComponent.getCurrentCredentials().token,
                    user = ActiveMSComponent.getActiveMiniserver().activeUser,
                    key = CryptoJS.lib.WordArray.random(32 / 2).toString(CryptoJS.enc.Hex),
                    encrypted = CryptoJS.AES.encrypt(token, key, {
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7
                    }),
                    rsaEnc = this._encryptWithPublicKey([encrypted.key.toString(CryptoJS.enc.Hex), encrypted.iv.toString(CryptoJS.enc.Hex), sessionToken].join(":"));

                return Q.resolve([user, rsaEnc, encrypted.ciphertext.toString(CryptoJS.enc.Base64)]);
            }
        }

        /**
         * Encrypts a given payload with the Athene public key and exponent, will return null if either is missing
         * @param payload The payload to encrypt
         * @return {null|*}
         * @private
         */
        _encryptWithPublicKey(payload) {
            if (this._modulus && this._exp) {
                var encrypt = new JSEncrypt();
                encrypt.getKey().setPublic(this._modulus, this._exp);
                return encrypt.encrypt(payload);
            } else {
                return null;
            }
        }

    };
});
