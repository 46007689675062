'use strict';

define('NotificationsSettingsBaseScreen', ['GlobalStyles'], function (globalStyles) {
    return class NotificationsSettingsBaseScreen extends GUI.TableViewScreenV2 {

        get useNewTableView() {
            return true;
        }

        constructor(element) {
            super(element); // Initialize the settings

            this._settings = {};
            Object.values(PushNotificationSettingType).forEach(function (notificationType) {
                this._settings[notificationType] = false;
            }.bind(this));
        }

        viewDidLoad() {
            var args = arguments;
            return this.getSettings().then(settings => {
                this._settings = settings;
                return super.viewDidLoad(...args);
            });
        }

        getAnimation() {
            return HD_APP ? AnimationType.PUSH_OVERLAP_LEFT : AnimationType.MODAL;
        }

        titleBarText() {
            return _('notifications');
        }

        /**
         * creates tableContent and reloads table
         * @param enabled parameter from subclass
         */
        reloadTable() {
            if (!this._getSwitchState()) {
                return super.reloadTable(...arguments);
            } // WARN: KEEP SECTIONS IN MIND WHEN MOVING STUFF AROUND! (cellForSectionAndRow)


            var settingsSection = {
                headerTitle: _('notifications.activate-for') + "...",
                rows: []
            };
            this.tableContent.push(settingsSection); // CONTROLS

            settingsSection.rows = this._getSupportedControlNotificationTypes().map(function (notificationType) {
                return this._createRow(notificationType);
            }.bind(this)); // Custom

            settingsSection.rows.push(this._createRow(PushNotificationSettingType.CUSTOM)); // SYSTEM
            // if the user is a "limitedUser", we won't show him the option to revice system state notifications

            if (!MessageCenterHelper.isLimitedUser()) {
                settingsSection.rows.push(this._createRow(PushNotificationSettingType.ERRORS));
            }

            return super.reloadTable(...arguments);
        }

        getSettings() {
            console.log("override getSettings!");
        }

        // Private methods
        _changeSetting() {
            console.log("override _changeSetting!");
        }

        _createRow(settingType) {
            return {
                type: GUI.TableViewV2.CellType.CHECKABLE,
                content: {
                    title: this._getRowTitle(settingType),
                    selected: this._settings[settingType],
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE,
                },
                didCheckCell: function didCheckCell(cell, section, row, tableView, selected) {
                    this._changeSetting(settingType, selected);
                }.bind(this)
            };
        }

        _getRowTitle(settingType) {
            var title;

            switch (settingType) {
                case PushNotificationSettingType.ALARM:
                    title = _('controls.alarm');
                    break;

                case PushNotificationSettingType.SMOKE_ALARM:
                    title = _('controls.smoke-alarm');
                    break;

                case PushNotificationSettingType.HOURCOUNTER:
                    title = _('controls.hourcounter');
                    break;

                case PushNotificationSettingType.SAUNA:
                    title = _('controls.sauna');
                    break;

                case PushNotificationSettingType.INTERCOM:
                    title = _('controls.intercom');
                    break;

                case PushNotificationSettingType.INTERCOM_GEN_2:
                    title = _('search.controltype.intercomv2');
                    break;

                case PushNotificationSettingType.I_ROOM:
                    title = _('controls.irc-name');
                    break;

                case PushNotificationSettingType.POOL:
                    title = _('controls.pool.name');
                    break;

                case PushNotificationSettingType.STEAK:
                    title = _('steak.product-name');
                    break;

                case PushNotificationSettingType.CUSTOM:
                    title = _('notifications.custom');
                    break;

                case PushNotificationSettingType.ERRORS:
                    title = _("message-center.name");
                    break;

                case PushNotificationSettingType.AAL_SMART_ALARM:
                    title = _("controls.aal-smart-alarm");
                    break;

                case PushNotificationSettingType.AAL_EMERGENCY:
                    title = _("controls.aalemergency");
                    break;

                case PushNotificationSettingType.MAILBOX:
                    title = _("controls.mailbox");
                    break;

                default:
                    title = settingType;
            }

            return title;
        }

        _getSwitchState() {
            return this._getUsedSupportedNotificationTypes().reduce(function (l, r) {
                return l |= this._settings[r];
            }.bind(this), false);
        }

        _getSupportedControlNotificationTypes() {
            var notificationControlTypes = [],
                availableNotificationControls = Object.values(PushNotificationSettingType),
                availableControls = ActiveMSComponent.getStructureManager().getAllControls(),
                control; // Pool push notifications have been moved to the system state

            if (Feature.POOL_PUSH_REMOVED) {
                // Just remove the pool notifications from the list
                availableNotificationControls.splice(availableNotificationControls.indexOf(PushNotificationSettingType.POOL), 1);
            } // Gat all available controlTypes


            Object.keys(availableControls).forEach(function (uuidAction) {
                control = availableControls[uuidAction]; // Check both the controlType and the type property, this makes it easier add new controls because we don't
                // have to take care on what property we will use

                if (availableNotificationControls.indexOf(control.controlType) !== -1) {
                    notificationControlTypes.push(control.controlType);
                }

                if (availableNotificationControls.indexOf(control.type) !== -1) {
                    notificationControlTypes.push(control.type);
                }
            }); // Remove all duplicate controlTypes

            notificationControlTypes = notificationControlTypes.filter(function (controlType, idx) {
                return notificationControlTypes.indexOf(controlType) === idx;
            });
            return notificationControlTypes;
        }

        _getUsedSupportedNotificationTypes() {
            return this._getSupportedControlNotificationTypes().concat([PushNotificationSettingType.CUSTOM, PushNotificationSettingType.ERRORS]);
        }

    };
});
