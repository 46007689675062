'use strict';

define(['IntercomControlEnums', 'IntercomControlContentMissedView'], function (IntercomControlEnums, IntercomControlContentMissedView) {
    return class IntercomControlContentMissedList extends Controls.ControlContent {
        constructor(details) {
            Debug.Control.Intercom.General && console.log("IntercomControlContentMissedList ctor");
            super(details);

            if (Feature.BELL_EVENT_IMAGE_DETECTION) {
                this.hasImages = !!this.control.details.lastBellEventImages;
            } else {
                this.hasImages = !!this.control.details.videoInfo.alertImage;
            }
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.tableView = new GUI.TableViewV2(this, this);
            this.appendSubview(this.tableView);
        }

        viewDidDisappear(viewRemainsVisible) {
            Debug.Control.Intercom.General && console.log("IntercomControlContentMissedList viewDidDisappear");
            super.viewDidDisappear(viewRemainsVisible);
        }

        titleBarText() {
            return this.control.name;
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK
            };
        }

        receivedStates(states) {
            Debug.Control.Intercom.General && console.log("IntercomControlContentMissedList receivedStates");
            this.lastActivitiesDateMap = states.lastActivitiesDateMap;
            this.lastActivitiesDates = Object.keys(this.lastActivitiesDateMap);
            this.tableView.reload();
        }

        getURL() {
            return "MissedList";
        }

        getAnimation() {
            return AnimationType.HD_OVERLAY;
        }

        // TableViewDataSource Methods
        styleForTableView() {
            return TableViewStyle.GROUPED;
        }

        numberOfRowsInSection(section, tableView) {
            var date = this.lastActivitiesDates[section];
            var rows = this.lastActivitiesDateMap[date];
            return rows.length;
        }

        numberOfSections(tableView) {
            return this.lastActivitiesDates.length;
        }

        titleForHeaderInSection(sectionIdx, tableView) {
            return this.lastActivitiesDates[sectionIdx];
        }

        cellTypeForCellAtIndex(section, row, tableView) {
            if (this.hasImages) {
                return GUI.TableViewV2.CellType.Special.MINISERVER_IMAGE;
            } else {
                return GUI.TableViewV2.CellType.GENERAL;
            }
        }

        // TableViewCellDataSource
        contentForCell(cell, section, row, tableView) {
            var result = this.lastActivitiesDateMap[this.lastActivitiesDates[section]][row];

            if (this.hasImages) {
                return {
                    title: result.timestampString,
                    uuid: this.control.uuidAction,
                    ts: result.timestamp
                };
            } else {
                return {
                    title: result.timestampString
                };
            }
        }

        // TableViewDelegate Methods
        didSelectCell(cell, section, row, tableView) {
            var date = this.lastActivitiesDates[section];
            var tsObj = this.lastActivitiesDateMap[date][row];
            this.ViewController.showState(IntercomControlEnums.ScreenState.MISSED_VIEW, null, {
                control: this.control,
                tsObject: tsObj,
                tsObjects: this._prepareTsObjectArray.call(this)
            });
        }

        _prepareTsObjectArray() {
            var tsObjectArray = [];

            for (var i = 0; i <= this.lastActivitiesDates.length - 1; i++) {
                var date = this.lastActivitiesDates[i];

                for (var j = 0; j <= this.lastActivitiesDateMap[date].length - 1; j++) {
                    tsObjectArray.unshift(this.lastActivitiesDateMap[date][j]);
                }
            }

            return tsObjectArray;
        }

    };
});
