'use strict';

/**
 * Screen with:
 *
 *  details:
 * iconSrc  (must be an url, default)
 * title
 * message
 * btnTitle (if empty, no button will be shown)
 * link     (opens when tapping the button)
 */
let ComplexReactComp = require("/app/scripts/react-comps/test/ComplexReactComp.jsx").default;
define([], function() {
    return class ReactTestScreen extends GUI.Screen {

        static ReactComp = ComplexReactComp;

        static title = "ReactNative Components Showcase"

        constructor(details) {
            super(...arguments);
        }

        titleBarText() {
            return ReactTestScreen.title;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(() => {
                /*this.setUpComfortUI(true);
                this.setIcon("https://d33wubrfki0l68.cloudfront.net/554c3b0e09cf167f0281fda839a5433f2040b349/ecfc9/img/header_logo.svg");*/
                this.appendReactComp({
                    reactComp: ComplexReactComp,
                    compProps: {
                        delegate: this
                    }
                })
            });
        }
    }
});
