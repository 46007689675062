import * as React from "react"
import Svg, { Path } from "react-native-svg"

function WeatherCloudy(props) {
    return (
        <Svg isreact="true"
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            viewBox="0, 0, 32, 32"
            {...props}
        >
            <Path
                d="M20 8.583A5.417 5.417 0 1025.417 14a5.403 5.403 0 00-1.584-3.833A5.403 5.403 0 0020 8.583zm.833-2.083V4.833a.833.833 0 00-1.666 0V6.5a.833.833 0 001.666 0zM15.25 8.083l-1.167-1.166a.824.824 0 10-1.166 1.166l1.166 1.167a.825.825 0 001.167-1.167zm-2.75 5.084h-1.667a.833.833 0 000 1.666H12.5a.833.833 0 000-1.666zm1.583 5.583l-1.166 1.167a.824.824 0 101.166 1.166l1.167-1.166a.824.824 0 10-1.167-1.167zm5.084 2.75v1.667a.833.833 0 001.666 0V21.5a.833.833 0 00-1.666 0zm5.583-1.583l1.167 1.166a.825.825 0 001.166-1.166l-1.166-1.167a.825.825 0 00-1.167 1.167zm2.75-5.084h1.667a.833.833 0 000-1.666H27.5a.833.833 0 000 1.666zM25.917 9.25l1.166-1.167a.825.825 0 00-1.166-1.166L24.75 8.083a.824.824 0 101.167 1.167z"
                fill="#F4CD27"
            />
            <Path
                d="M3 22.5c0-1.608.859-3.094 2.252-3.898a6.75 6.75 0 0112.49-3.403 4.882 4.882 0 015.947 2.96A4.5 4.5 0 0122.5 27h-15A4.5 4.5 0 013 22.5z"
                fill="#D5E2E9"
            />
        </Svg>
    )
}

export default WeatherCloudy
