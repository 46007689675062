'use strict';

define("WindowControl", [
    "Control",
    "IconLib"
], function (
    Control,
    {
        default: Icons
    }
) {
    return class WindowControl extends Control {
        constructor(weatherServer) {
            super(...arguments);
        }

        getCellType(forCentral) {
            return forCentral ? window.GUI.TableViewV2.CellType.GENERAL : ControlCard.BASE;
        }

        getControlContentIdentifier() {
            return "ControlActionCardsScreen";
        }

        getButton0(states) {
            return {
                iconSrc: Icon.Window.OPEN_CIRCLED,
                reactIcon: Icons.Open,
                command: Commands.WINDOW.OPEN.FULL,
                disabled: states.isLocked
            };
        }

        getButton1(states) {
            return {
                iconSrc: Icon.Window.CLOSE_CIRCLED,
                reactIcon: Icons.Close,
                command: Commands.WINDOW.CLOSE.FULL,
                disabled: states.isLocked
            };
        }

        specificCreateCmdText(cmdParts) {
            var cfg = {
                    name: this.name
                },
                text;

            switch (cmdParts[1]) {
                case "open":
                    if (cmdParts[2] === "on") {
                        text = _("cmdtext.window.open", cfg);
                    } else {
                        text = _("cmdtext.window.open.stop", cfg);
                    }

                    break;

                case "close":
                    if (cmdParts[2] === "on") {
                        text = _("cmdtext.window.close", cfg);
                    } else {
                        text = _("cmdtext.window.close.stop", cfg);
                    }

                    break;

                case Commands.WINDOW.OPEN.FULL:
                    text = _("cmdtext.window.open.full", cfg);
                    break;

                case Commands.WINDOW.CLOSE.FULL:
                    text = _("cmdtext.window.close.full", cfg);
                    break;

                case "moveToPosition":
                    cfg.percentage = cmdParts[2];
                    text = _("cmdtext.window.moveToPosition", cfg);
                    break;

                default:
            }

            return text;
        }

    };
});
