import {LxReactWaitingView, useBackNavigation} from "LxComponents";
import useIsPairedApp from "../../../react-comps/PairedApp/useIsPairedApp";

export default function ConnectionWaitingScreen({route: { params }}) {

    const detectWaitingTitle = () => {
        let title = NBR_SPACE;
        if (!params || !params.miniserver || params.state === WaitingState.MiniserverSearch) {
            // no title

        } else if (params.state === WaitingState.Connecting || params.state === WaitingState.Loading) {
            title = params.reachMode === ReachMode.LOCAL ? params.miniserver.localUrl : params.miniserver.remoteUrl;

        } else if (params.state === WaitingState.RequestingCloudDNS) {
            title = params.miniserver.remoteUrl;

        } else if (params.miniserver.localUrl && !params.miniserver.remoteUrl) {
            title = params.miniserver.localUrl;

        } else if (!params.miniserver.localUrl && params.miniserver.remoteUrl) {
            title = params.miniserver.remoteUrl;

        } else {
            title = params.miniserver.msName;
        }
        return title;
    }

    const detectMessages = () => {
        if (!params) {
            return
        }
        var reasonText,
            badConnectionText,
            useDefaultBadConnText = false,
            messages;

        switch (params.state) {
            case WaitingState.ReachabilityCheck:
                if (params.miniserver.msName) {
                    reasonText = _("miniserver.waiting.reachability.known", {
                        miniserverName: params.miniserver.msName
                    });
                } else {
                    reasonText = _("miniserver.waiting.reachability") + ".";
                }
                useDefaultBadConnText = params.connectionRetryCount >= 3;
                break;

            case WaitingState.RequestingCloudDNS:
                reasonText = _("miniserver.waiting.requesting-clouddns") + ".";
                useDefaultBadConnText = params.connectionRetryCount >= 3;
                break;

            case WaitingState.Connecting:
                if (params.miniserver.msName) {
                    reasonText = _("miniserver.waiting.establishing.known", {
                        miniserverName: params.miniserver.msName
                    });
                }
                useDefaultBadConnText = params.connectionRetryCount >= 3;
                break;

            case WaitingState.Loading:
                // loading structure
                reasonText = _("miniserver.waiting.loading-structure");
                if (typeof params.downloadProgress === "number") {
                    reasonText = reasonText + " (" + Math.round(params.downloadProgress * 100) + "%)";
                }
                break;

            case WaitingState.MsOutOfService:
                if (params.miniserver.msName) {
                    reasonText = '..' + _("miniserver.waiting.rebooting.known", {
                        miniserverName: params.miniserver.msName
                    }) + '..';
                } else {
                    reasonText = '..' + _("miniserver.waiting.rebooting") + '..';
                }
                break;

            case WaitingState.MsUpdating:
                reasonText = _("update.installing");
                break;

            case WaitingState.MiniserverSearch:
                reasonText = _("miniserver.waiting.searching");
                break;

            case WaitingState.NoMoreEventSlots:
                reasonText = _('miniserver.waiting.no-event-slots-left');
                badConnectionText = _('miniserver.waiting.no-event-slots-left.subtitle');
                break;
            default:
                reasonText = _("miniserver.waiting.reachability");
                useDefaultBadConnText = params.connectionRetryCount > 4;
                break;
        }

        if (useDefaultBadConnText) {
            badConnectionText = _('connection.bad.title');
        }

        var count = params.connectionRetryCount % 4;
        for (var i = 0; i < count; i++) {

            if (badConnectionText) {
                badConnectionText += ".";
            } else {
                reasonText += ".";
            }
        }

        if (reasonText || badConnectionText) {
            messages = [];
            messages.pushObject(reasonText);
            messages.pushObject(badConnectionText);
        }

        return messages;
    }

    const isPairedApp = useIsPairedApp();
    const onCancel = () => {
        if (isPairedApp) {
            console.error(ConnectionWaitingScreen.name, "onCancel intercepted --> paired apps don't go to the archive!");
            return;
        }

        // will also be overwritten!
        if (params && params.state === WaitingState.MiniserverSearch) {
            MiniserverFinder.stopMiniserverFinder();
        }

        NavigationComp.showArchive();
    }

    useBackNavigation(onCancel);

    let isWebinterface = PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface;
    return <LxReactWaitingView
        title={detectWaitingTitle()}
        message={detectMessages()}
        buttonText={ ((isWebinterface || isPairedApp) ? null : _("cancel")) }
        action={onCancel.bind(this)}
    />
}
