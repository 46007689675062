'use strict';

define(["ClimateUsControlEnums"], function (CtrlEnums) {
    return class ClimateUsInfoView extends GUI.View {
        //region Static
        static Template = class {
            //region Static
            static getTemplate(hasThermostat) {
                var noTempClass = hasThermostat ? "" : "climate-us-info-view--no-outside-temp";
                return '' + '<div class="climate-us-info-view ' + noTempClass + '">' + '<div class="climate-us-info-view__main">' + (!!hasThermostat ? this._getThermostatContent() : "") + this._getDemandContent() + '</div>' + '<div class="climate-us-info-view__bottom">' + '<div class="bottom__mode-text"></div>' + '</div>' + '</div>';
            }

            static _getThermostatContent() {
                return '' + '<div class="main__thermostat">' + '<div class="thermostat__top">' + '<div class="top__text">' + _("controls.climate.us.outdoor") + '</div>' + '</div>' + '<div class="thermostat__main">' + this._getUniqueThermostatSvg() + '</div>' + '</div>';
            }

            static _getUniqueThermostatSvg() {
                let svg = ImageBox.getResourceImageWithClasses(Icon.ClimateUs.THERMOSTAT_UNIVERSAL, "main__thermostat-x");
                let uniqueId = getRandomIntInclusive(0, 99999);
                svg = svg.replaceAll("linearGradient-universal-1", "linearGradient-universal-1-" + uniqueId);
                svg = svg.replaceAll("linearGradient-universal-2", "linearGradient-universal-2-" + uniqueId);
                svg = svg.replaceAll("linearGradient-universal-3", "linearGradient-universal-3-" + uniqueId);
                return svg;
            }

            static _getDemandContent() {
                return '' + '<div class="main__demand">' + '<div class="demand__limit-cntr">' + '<div class="limit-cntr__top"></div>' + '<div class="limit-cntr__main">' + '<div class="main__limit-bar">' + '<div class="limit-bar__line"></div>' + '<div class="limit-bar__text">' + '<span class="text__value"></span>' + '<span class="text__percent">%</span>' + '</div>' + '</div>' + '</div>' + '</div>' + '<div class="demand__bars-cntr">' + '<div class="bars-cntr__top">' + '<div class="top__text">' + _("controls.climate.us.rooms") + '</div>' + '</div>' + '<div class="bars-cntr__main"></div>' + '</div>' + '</div>';
            } //endregion Static


        }; //endregion Static

        constructor(control, modeAction, limitsAction, ircAction) {
            super($(ClimateUsInfoView.Template.getTemplate(true)));
            Object.assign(this, StateHandler.Mixin);
            this.showOutsideTemp = true;
            this.control = control;
            this.modeAction = modeAction;
            this.limitsAction = limitsAction;
            this.ircAction = ircAction;
        }

        viewDidLoad() {
            var promise = super.viewDidLoad(...arguments);
            this.elements = {
                demandCntr: this.element.find(".main__demand"),
                barCntr: this.element.find(".bars-cntr__main"),
                limitText: this.element.find(".text__value"),
                limitBar: this.element.find(".main__limit-bar"),
                modeText: this.element.find(".bottom__mode-text"),
                modeCntr: this.element.find(".climate-us-info-view__bottom"),
                thermostatCntrWrapper: this.element.find(".main__thermostat"),
                thermostatCntr: this.element.find(".thermostat__main"),
                thermostat: this.element.find(".main__thermostat-x")
            };

            this.svgParts = {
                coolingLimit: this.elements.thermostat.find(".thermostat-cooling-limit"),
                heatingLimit: this.elements.thermostat.find(".thermostat-heating-limit"),
                outsideTemp: this.elements.thermostat.find(".thermostat-outside-temp")
            };

            this.coolingDemandBar = new GUI.LxPercentageBar({
                iconSrc: Icon.ClimateUs.COOLING,
                action: this.ircAction
            });
            this.appendSubview(this.coolingDemandBar, this.elements.barCntr);
            this.coolingDemandBar.getElement().addClass("main__bar");
            this.coolingDemandBar.getElement().addClass("main__bar--cooling");
            this.heatingDemandBar = new GUI.LxPercentageBar({
                iconSrc: Icon.ClimateUs.HEATING,
                action: this.ircAction
            });
            this.appendSubview(this.heatingDemandBar, this.elements.barCntr);
            this.heatingDemandBar.getElement().addClass("main__bar");
            this.heatingDemandBar.getElement().addClass("main__bar--heating");
            this.buttons = {};

            this.buttons.limitsButton = new GUI.LxButton(this, this.elements.thermostatCntr);
            this.addToHandledSubviews(this.buttons.limitsButton);
            this.buttons.limitsButton.onButtonTapped = this.limitsAction;
            this.buttons.modeButton = new GUI.LxButton(this, this.elements.modeCntr);
            this.addToHandledSubviews(this.buttons.modeButton);
            this.buttons.modeButton.onButtonTapped = this.modeAction;

            return promise;
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function () {
                this._registerForStates();
            }.bind(this));
        }

        viewWillDisappear() {
            this._unregisterStates(this.control.uuidAction);

            return super.viewWillDisappear(...arguments);
        }

        receivedStates(states) {
            this.states = states;
            var threshold = lxFormat("%.0f", states.demandThreshold);

            this._updateHeatingBar(states);

            this._updateCoolingBar(states);

            this._updateModeText(states);

            this.showOutsideTemp = this.control.hasOutsideTemperature();

            if (this.showOutsideTemp) {
                this.elements.thermostatCntr.show();
                this._updateThermostat(states);
            } else {
                if (Feature.HVAC_CLIMATE_CONTROLLER_INTEGRATION) {
                    this.elements.thermostatCntrWrapper.hide();
                }
                this.elements.thermostatCntr.hide();
            }

            if (
                Feature.HVAC_CLIMATE_CONTROLLER_INTEGRATION &&
                (!this.control.hasSwitchOnThresholdCapability ||
                    (this.control.hasSwitchOnThresholdCapability &&
                        states.demandThreshold < 0))
            ) {
                this.elements.limitText.css('visibility', 'hidden');
                this.elements.limitBar.css('visibility', 'hidden');
            } else {
                if (this.elements.limitBar.css('visibility') === 'hidden' && this.elements.limitText.css('visibility') === 'hidden') {
                    this.elements.limitText.css('visibility', 'visible');
                    this.elements.limitBar.css('visibility', 'visible');
                }
                this.elements.limitText.text(threshold);
                this.elements.limitBar.css(
                    'height',
                    states.demandThreshold + '%',
                );
            }
        }

        _updateHeatingBar(states) {
            var color = states.isHeating ? window.Styles.colors.orange : null,
                iconSrc = Icon.ClimateUs.HEATING;

            if (states.notHeatingDueTemp || states.modeOnlyCooling || states.modeOff) {
                iconSrc = Icon.ClimateUs.NO_HEATING;
            }

            if (
                Feature.HVAC_CLIMATE_CONTROLLER_INTEGRATION &&
                !this.control.hasSwitchOnThresholdCapability
            ) {
                this.heatingDemandBar.setLimit(-1);
            } else {
                this.heatingDemandBar.setLimit(states.demandThreshold);
            }

            this.heatingDemandBar.setShowPattern(states.isHeating);
            this.heatingDemandBar.setValue(states.demandHeat);
            this.heatingDemandBar.setColor(color);
            this.heatingDemandBar.setIcon(iconSrc);
            this.heatingDemandBar.setIconColor(Color.WHITE);
            this.heatingDemandBar.setIcon2(this._isHeatingFanActive(states) ? Icon.ClimateUs.FAN : null);
            this.heatingDemandBar.setIcon2Color(Color.WHITE);
            this.heatingDemandBar.setAdditionalIcon(states.isHeatingStage2 ? Icon.ClimateUs.STAGE2 : null);
            this.heatingDemandBar.setAdditionalIconColor(Color.WHITE);
        }

        _updateCoolingBar(states) {
            var color = states.isCooling ? window.Styles.colors.blue : null,
                iconSrc = Icon.ClimateUs.COOLING;

            if (states.notCoolingDueTemp || states.modeOnlyHeating || states.modeOff) {
                iconSrc = Icon.ClimateUs.NO_COOLING;
            }

            if (
                Feature.HVAC_CLIMATE_CONTROLLER_INTEGRATION &&
                !this.control.hasSwitchOnThresholdCapability
            ) {
                this.coolingDemandBar.setLimit(-1);
            } else {
                this.coolingDemandBar.setLimit(states.demandThreshold);
            }

            this.coolingDemandBar.setShowPattern(states.isCooling);
            this.coolingDemandBar.setValue(states.demandCool);
            this.coolingDemandBar.setColor(color);
            this.coolingDemandBar.setIcon(iconSrc);
            this.coolingDemandBar.setIconColor(Color.WHITE);
            this.coolingDemandBar.setIcon2(this._isCoolingFanActive(states) ? Icon.ClimateUs.FAN : null);
            this.coolingDemandBar.setIcon2Color(Color.WHITE);
            this.coolingDemandBar.setAdditionalIcon(states.isCoolingStage2 ? Icon.ClimateUs.STAGE2 : null);
            this.coolingDemandBar.setAdditionalIconColor(Color.WHITE);
        }

        _isCoolingFanActive(states) {
            return (states.isCooling || !states.isHeating) && states.fanActive;
        }

        _isHeatingFanActive(states) {
            return (states.isHeating || !states.isCooling) && states.fanActive;
        }

        _updateModeText(states) {
            var text = "";

            if (states.modeAuto) {
                text = _("controls.climate.us.mode.auto-full");
            } else if (states.modeOnlyHeating) {
                text = _("controls.climate.us.mode.heating-full");
            } else if (states.modeOnlyCooling) {
                text = _("controls.climate.us.mode.cooling-full");
            } else if (states.modeOff) {
                text = _("controls.climate.us.mode.off-full");
            }

            this.elements.modeText.text(text);
        }

        _updateThermostat(states) {
            var bShowHeatCool = false,
                bShowHeat = false,
                bShowCool = false;

            if (states.outdoorTempHeatCoolAllowed) {
                bShowHeatCool = true;
            } else if (states.outdoorTempHeatAllowed) {
                bShowHeat = true;
            } else if (states.outdoorTempCoolAllowed) {
                bShowCool = true;
            }

            this.elements.thermostatCntr.toggleClass("thermostat__main--heating-only", bShowHeat);
            this.elements.thermostatCntr.toggleClass("thermostat__main--cooling-only", bShowCool);
            this.elements.thermostatCntr.toggleClass("thermostat__main--heating-and-cooling", bShowHeatCool);
            this.svgParts.heatingLimit.text(this._formatTemp(states.maximumTempHeating));
            this.svgParts.coolingLimit.text(this._formatTemp(states.minimumTempCooling));
            this.svgParts.outsideTemp.text(this._formatTemp(states.actualOutdoorTemp));
        }

        _formatTemp(temp) {
            var format = "%.1f°";

            if (SandboxComponent.getTemperatureUnit() === TempAppendix(TempUnit.FAHRENHEIT)) {
                format = "%.0f°";
            }

            return lxFormat(format, temp);
        }

    };
});
