import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Presence(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path d="M14.475 5.975a3.5 3.5 0 10-4.95-4.95 3.5 3.5 0 004.95 4.95zM10 18.5v-3.967c-1.952-.934-3-3.735-3-6.033a.5.5 0 01.5-.5h9a.5.5 0 01.5.5c0 2.299-1.049 5.099-3 6.033V18.5c0 .275-.225.5-.5.5h-3a.5.5 0 01-.5-.5z" />
            <Path d="M8.103 14.342c.27.288.568.548.897.768v.099c-1.351.194-2.553.515-3.523.923-.824.347-1.457.747-1.878 1.165C3.181 17.714 3 18.12 3 18.5c0 .38.18.786.6 1.203.42.418 1.053.818 1.877 1.165C7.12 21.56 9.427 22 12 22c2.573 0 4.88-.44 6.523-1.132.824-.347 1.457-.747 1.878-1.165.418-.417.599-.823.599-1.203 0-.38-.18-.786-.6-1.203-.42-.418-1.053-.818-1.877-1.165-.97-.408-2.172-.73-3.523-.924v-.098c.329-.22.627-.48.897-.768 1.125.208 2.146.503 3.014.869.897.377 1.654.84 2.195 1.378.543.54.894 1.186.894 1.911 0 .725-.35 1.37-.894 1.911-.54.539-1.298 1-2.195 1.378C17.117 22.545 14.674 23 12 23c-2.673 0-5.117-.455-6.912-1.21-.896-.378-1.653-.84-2.194-1.379C2.35 19.871 2 19.225 2 18.5c0-.725.35-1.37.894-1.911.54-.539 1.298-1.001 2.194-1.378.869-.366 1.89-.661 3.015-.869z" />
            <Path d="M6.913 16.605A14.705 14.705 0 019 16.188v1.01c-.68.094-1.291.22-1.812.369-.61.174-1.065.371-1.358.569-.31.21-.33.342-.33.364 0 .022.02.155.33.364.293.198.749.395 1.358.57C8.398 19.778 10.1 20 12 20c1.9 0 3.602-.22 4.812-.567.61-.174 1.065-.371 1.358-.569.31-.21.33-.342.33-.364 0-.022-.02-.155-.33-.364-.293-.198-.749-.395-1.358-.57A13.456 13.456 0 0015 17.198v-1.009c.776.103 1.481.244 2.087.417.658.188 1.227.421 1.643.702.398.27.77.663.77 1.193s-.372.924-.77 1.193c-.416.28-.985.514-1.643.702-1.323.378-3.121.605-5.087.605-1.966 0-3.764-.227-5.087-.605-.658-.188-1.227-.421-1.643-.702-.398-.27-.77-.663-.77-1.193s.372-.924.77-1.193c.416-.28.985-.514 1.643-.702z" />
        </Svg>
    )
}

export default Presence
