'use strict';

define(["NfcTagInteractionScreen", "ShareMiniserverScreen"], function (NfcTagInteractionScreen, ShareMiniserverScreen) {
    return class NfcScreen extends GUI.Screen {
        constructor() {
            super($('<div class="nfc-screen" />'));
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(() => {
                this.tableView = new GUI.TableViewV2(this, this);
                this.appendSubview(this.tableView);
                this.tableView.reload();
                return Q.resolve();
            })
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        destroy() {
            this.titleBar = null;
            return super.destroy();
        }

        titleBarText() {
            return _('nfc.smart-tag');
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK
            };
        }

        // Private methods
        // TableViewDataSource Methods
        styleForTableView(tableView) {
            return HD_APP ? TableViewStyle.GROUPED : null;
        }

        numberOfSections(tableView) {
            return 2;
        }

        numberOfRowsInSection(section, tableView) {
            if (section === 0) {
                return SandboxComponent.isAutopilotAvailable() && Feature.AUTOMATIC_DESIGNER_SCENES ? 4 : 3;
            } else if (section === 1) {
                return 3;
            }
        }

        cellTypeForCellAtIndex(section, row, tableView) {
            return GUI.TableViewV2.CellType.GENERAL;
        }

        titleForHeaderInSection(section, tableView) {
            if (section === 0) {
                return _('nfc.tag.write');
            } else if (section === 1) {
                return _('actions');
            }
        }

        contentForCell(cell, section, row, tableView) {
            if (section === 0) {
                if (row === 0) {
                    return {
                        title: _('app-position.save'),
                        subtitle: _('nfc.app-position'),
                        leftIconSrc: "resources/Images/ActiveMiniserver/NFC/icon-save-position.svg",
                        clickable: true
                    };
                } else if (row === 1) {
                    var content = {
                        title: _('command.save'),
                        subtitle: _('nfc.command'),
                        leftIconSrc: "resources/Images/ActiveMiniserver/NFC/icon-save-task.svg",
                        clickable: true
                    };

                    if (!Feature.NFC_QR_QA_COMMANDS) {
                        content.rightIconSrc = Icon.Notification.CAUTION;
                        content.rightIconColor = Color.DISCLOSURE_ICON_A;
                    }

                    return content;
                } else if (row === 2) {
                    return {
                        title: _('nfc.tag.write-miniserver'),
                        subtitle: _('nfc.miniserver'),
                        showFullSubtitle: true,
                        leftIconSrc: "resources/Images/ActiveMiniserver/NFC/icon-share-miniserver.svg",
                        clickable: true
                    };
                } else if (row === 3) {
                    return {
                        title: _("scenes.name"),
                        showFullSubtitle: true,
                        leftIconSrc: Icon.AutomaticDesigner.SCENE,
                        clickable: true
                    };
                }
            } else if (section === 1) {
                if (row === 0) {
                    return {
                        title: _('nfc.tag.read'),
                        leftIconSrc: "resources/Images/ActiveMiniserver/NFC/icon-nfc-read.svg",
                        clickable: true
                    };
                } else if (row === 1) {
                    return {
                        title: _('nfc.tag.erase'),
                        leftIconSrc: "resources/Images/General/trash-can-v2.svg",
                        clickable: true
                    };
                } else if (row === 2) {
                    return {
                        title: _('nfc.tag.write-protect'),
                        leftIconSrc: "resources/Images/ActiveMiniserver/NFC/icon-lock.svg",
                        clickable: true
                    };
                }
            }
        }

        // TableViewDelegate Methods
        didSelectCell(cell, section, row, tableView) {
            if (section === 0) {
                if (row === 1 && !Feature.NFC_QR_QA_COMMANDS) {
                    NavigationComp.showCurrentlyNotSupportedPopup(_('command.save.not-supported'));
                    return;
                } else if (row === 2) {
                    // Share Miniserver
                    this.ViewController.showState(ScreenState.NfcShareMiniserver);
                    return;
                } else if (row === 3) {
                    var def = Q.defer(),
                        details = {
                            options: SandboxComponent.getStructureManager().getAllSceneControls().map(function (sceneControl) {
                                return {
                                    title: sceneControl.getName(),
                                    clickable: true,
                                    sceneControl: sceneControl
                                };
                            }),
                            title: _("nfc.smart-tag.create"),
                            radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE,
                            mode: GUI.SelectorScreenMode.QUICK,
                            deferred: def
                        };
                    NavigationComp.showSelector(details).then(function (results) {
                        var control = results[0].option.sceneControl;
                        NavigationComp.showState(ScreenState.NfcTagInteractionScreen, {
                            type: Interaction.NFC.WRITE_SCENE,
                            commandUrl: control.getSceneExecUrlScheme()
                        });
                    }.bind(this));
                    return;
                }

                NavigationComp.disableAnimationsTemp();
                this.ViewController.navigateBack().done(function () {
                    if (row === 0) {
                        // App-Position
                        var onFinish = function () {
                            NavigationComp.startActivity(GUI.ActiveMiniserverViewController.Activity.Position, Interaction.NFC.WRITE_POSITION);
                        }.bind(this);

                        if (PersistenceComponent.getAppInstructionFlag(UserInstructionFlag.NFC_POSITION)) {
                            onFinish();
                        } else {
                            PersistenceComponent.setAppInstructionFlag(UserInstructionFlag.NFC_POSITION);

                            var msg = _('app-position.help');

                            msg += ImageBox.getResourceImageWithClasses("resources/Images/ActiveMiniserver/NFC/icon-nfc-logo.svg");
                            NavigationComp.showState(ScreenState.QuickGuide, {
                                iconSrc: "resources/Images/ActiveMiniserver/NFC/nfc-logo.svg",
                                title: _('app-position.save'),
                                message: msg,
                                onFinishFn: onFinish
                            });
                        }
                    } else if (row === 1) {
                        // Command / Task
                        var onFinish = function () {
                            NavigationComp.startActivity(GUI.ActiveMiniserverViewController.Activity.Command, Interaction.NFC.WRITE_COMMAND);
                        }.bind(this);

                        if (PersistenceComponent.getAppInstructionFlag(UserInstructionFlag.QR_COMMAND)) {
                            onFinish();
                        } else {
                            PersistenceComponent.setAppInstructionFlag(UserInstructionFlag.NFC_COMMAND);
                            NavigationComp.showState(ScreenState.QuickGuide, {
                                iconSrc: "resources/Images/ActiveMiniserver/NFC/nfc-logo.svg",
                                title: _('command.save'),
                                message: _('command.help'),
                                onFinishFn: onFinish
                            });
                        }
                    }
                }.bind(this));
            } else if (section === 1) {
                if (row === 0) {
                    // Read
                    NavigationComp.showState(ScreenState.NfcTagInteractionScreen, {
                        type: Interaction.NFC.READ
                    });
                } else if (row === 1) {
                    // Delete
                    NavigationComp.showState(ScreenState.NfcTagInteractionScreen, {
                        type: Interaction.NFC.ERASE
                    });
                } else if (row === 2) {
                    // Writelock
                    NavigationComp.showState(ScreenState.NfcTagInteractionScreen, {
                        type: Interaction.NFC.WRITE_LOCK
                    });
                }
            }
        }

    };
});
