'use strict';

define(["ControlActionCellsScreen", "LxComponents"], function (ControlActionCellsScreen, {
    LxLegacyReactControlContent
}) {
    return class MeterV2ControlContent extends ControlActionCellsScreen {

        static ReactComp = LxLegacyReactControlContent;

        constructor() {
            super(...arguments);
        }

    };
});
