'use strict';

define(["AboutScreenBase", "AudioserverDiagnosis"], function (AboutScreenBase) {
    return class AboutAudioServer extends AboutScreenBase {
        constructor(details) {
            super($('<div />'));
            this.control = details.control || AudioserverComp.getActiveZoneControl();
            this.mediaServer = this.control.audioserverComp.getActiveMediaServer();
            this.serverIp = this.control.audioserverComp.getServerIp();
            this.name = this.control.audioserverComp.getServerName();
            this.serialNo = this.control.audioserverComp.getServerSerialNumber();
            this.firmware = this.control.audioserverComp.getServerFirmwareVersion();
            this.isV2 = this.serialNo.toUpperCase().hasPrefix(MusicServerEnum.V2_MAC_PRFX);
            this.isCompact = this.serialNo.toUpperCase().hasPrefix(MusicServerEnum.COMPACT_MAC_PRFX);

            if (this.isCompact) {
                this.firmware = ActiveMSComponent.getConfigVersion();
            }
        }

        viewWillAppear() {
            return Q(super.viewWillAppear(...arguments) || true).then(function () {
                this.setTitle(this.name);
                var iconSrc = Icon.AudioZone.DEVICES.V1;

                if (this.isV2) {
                    iconSrc = Icon.AudioZone.DEVICES.V2;
                } else if (this.isCompact) {
                    iconSrc = Icon.MINISERVER_COMPACT;
                }

                this.setIcon(iconSrc);
                this.setIconColor(Color.GREEN);
            }.bind(this));
        }

        resizeIconClass() {
            var baseClass = super.resizeIconClass(...arguments);
            baseClass += " audio-zone-v2-control-about-audio-server--big-img";
            return baseClass;
        }

        _getOpenWebinterfaceAction() {
            var url = false,
                action;

            if (this.isCompact) {
                if(ActiveMSComponent.getGatewayType() !== GatewayType.GATEWAY) {
                    url = `${ActiveMSComponent.getMiniserverConnectionUrl().replace(/\/$/, "")}/admin`;
                } else if (ActiveMSComponent.getGatewayType() === GatewayType.GATEWAY && CommunicationComponent.getCurrentReachMode() === ReachMode.LOCAL) {
                    const structureManager = ActiveMSComponent.getStructureManager();
                    const audioServerList = structureManager.getMediaServerSet();
                    const { host } = Object.values(audioServerList).find(server => server.mac === this.serialNo);
                    url = new URL(`http://${host}`);
                    url = `${url.protocol}//${url.hostname}/admin`;
                }
            } else {
                url = this.control.audioserverComp.getAudioserverHostHttp();
            }

            if (!url && CommunicationComponent.getCurrentReachMode() === ReachMode.LOCAL && !this.isCompact) {
                url = this.serverIp.startsWith("http") ? this.serverIp : "http://" + this.serverIp;
            }

            if (!url && CommunicationComponent.getCurrentReachMode() === ReachMode.REMOTE) {
                action = () => {
                    NavigationComp.showPopup({
                        title: _("miniserver.connected.external"),
                        message: _("item-unavailable-remote", {
                            name: _("media.preferences.services.editor.more-info")
                        }),
                        buttonOk: true
                    });
                };
            } else {
                action = () => {
                    NavigationComp.openWebsite(url);
                };
            }

            return action;
        }

        reloadTable() {
            this.tableContent = [{
                rows: [this._getCellWithTitleAndDetail(_("miniserver.url.local"), this.serverIp), this._getCellWithTitleAndDetail(_("miniserver.serial-number"), this.serialNo), this._getCellWithTitleAndDetail(_("miniserver.firmware"), this.firmware)]
            }, {
                rows: [{
                    content: {
                        title: _("media.preferences.services.editor.more-info"),
                        clickable: true,
                        class: "base-cell--with-chevron",
                        disclosureIcon: false
                    },
                    action: this._getOpenWebinterfaceAction()
                }, {
                    content: {
                        title: _("diagnosis-information"),
                        clickable: true,
                        class: "base-cell--with-chevron",
                        disclosureIcon: false
                    },
                    action: function openDiagnosis() {
                        if (this.control.audioserverComp.isConnectionPromisePending()) {
                            NavigationComp.showPopup({
                                title: _("miniserver.not-connected"),
                                buttonCancel: _("okay")
                            });
                        } else {
                            this.ViewController.showState("AudioserverDiagnosis", null, {
                                control: this.control
                            });
                        }
                    }.bind(this)
                }, function () {
                    let title;

                    if (this.isCompact) {
                        title = _("audio-server.settings.restart-services");
                    } else {
                        title = _("audio-server.settings.reboot", {
                            productName: this.name
                        });
                    }

                    return {
                        content: {
                            title: title,
                            clickable: true
                        },
                        action: function () {
                                this.control.audioserverComp.rebootAudioServer(this.control);
                        }.bind(this)
                    };
                }.bind(this)()]
            }];
            return super.reloadTable(...arguments);
        }

        _getCellWithTitleAndDetail(title, detail) {
            var cell = {
                content: {
                    title: title,
                    disclosureText: detail
                }
            };

            this._addCopyActionForDisclosureText(cell, title, detail);

            return cell;
        }

    };
});
