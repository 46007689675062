

class FileRequest {

    /**
     * Returns a file request suitable for acquiring data from aws for example.
     * @param url
     * @param dataType
     * @param [name] optional, will be read from the url provided.
     * @returns {FileRequest}
     */
    static remote(url, dataType, name) {
        let fileName = name;
        if (!fileName) {
            let urlObj = new URL(url);
            const pathParts = urlObj.pathname.split("/");
            fileName = pathParts[pathParts.length - 1];
        }
        return new FileRequest(fileName, dataType, {url});
    }

    static cache(name, dataType) {
        return new FileRequest(name, dataType);
    }

    static resource(resourcePath, dataType = DataType.FILE, name) {
        let fileName = name;
        if (!fileName) {
            const pathParts = resourcePath.split("/");
            fileName = pathParts[pathParts.length - 1];
        }
        return new FileRequest(fileName, dataType, {resourcePath});
    }

    constructor(name, dataType = DataType.OBJECT, {url, resourcePath, data, version, updateInterval, fileDownloadFn, versionDownloadFn } = {}) {
        this._name = name;
        this._dataType = dataType;
        this._url = url;
        this._resourcePath = resourcePath;

        this._data = data;
        this._version = version;

        this._fileDownloadFn = fileDownloadFn;
        this._versionDownloadFn = versionDownloadFn;
    }

    get name() {
        return this._name;
    }
    get dataType() {
        return this._dataType;
    }

    // region getters & setters for the acquisition
    get url() {
        return this._url;
    }
    set url(newVal) {
        this._url = newVal;
    }
    get resourcePath() {
        return this._resourcePath;
    }
    set resourcePath(newVal) {
        this._resourcePath = newVal;
    }
    // endregion

    // region getters & setters for the results
    get data() {
        return this._data;
    }
    set data(newVal) {
        this._data = newVal;
    }
    get version() {
        return this._version;
    }
    set version(newVal) {
        this._version = newVal;
    }
    // endregion

    // region server download function override, e.g. to download not via ajax but from the MS for example.
    get fileDownloadFn() {
        return this._fileDownloadFn;
    }
    set fileDownloadFn(newVal) {
        this._fileDownloadFn = newVal;
    }
    get versionDownloadFn() {
        return this._versionDownloadFn;
    }
    set versionDownloadFn(newVal) {
        this._versionDownloadFn = newVal;
    }
    // endregion
}

export default FileRequest;