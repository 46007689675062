'use strict';

{//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var CURRENT_DETAILS_TYPES = {
        TEMPERATURE: "prec-temp",
        PRESSURE: "bar-press",
        PRECIPITATION: "precipitation",
        REL_HUMIDITY: "rel-hum",
        SOL_RADIATION: "sol-radiation",
        DEW_POINT: "deq-point",
        WIND_SPEED: "wind-speed"
    };

    class WeatherScreenV2 extends GUI.Screen {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                var contentContainer = $('<div class="weather-screen-v2__content-container"></div>');
                contentContainer.append(_getCurrentInfoSection());
                contentContainer.append(_getForecastSection());
                contentContainer.append(_getDayOverviewSection());
                return contentContainer;
            };

            var getForecastElement = function getForecastElement(upperText, entry) {
                var iconSrc = entry.weatherIcon,
                    lowerText = Math.round(entry.temperature) + "°";
                return $('<div class="scroll-wrapper__entry clickable">' + '   <canvas class="entry__curve"></canvas>' + '   <div class="entry__upper-label">' + upperText + '</div>' + '   ' + ImageBox.getResourceImageWithClasses(iconSrc, 'entry__icon') + '   <div class="entry__lower-label">' + lowerText + '</div>' + '</div>');
            }; // PRIVATE METHODS


            var _getCurrentInfoSection = function _getCurrentInfoSection() {
                return $('<div class="content-container__current-info-section">' + // This container can change its position depending on the devices orientation, so it has a variable position
                    '   <div class="current-info-section__variable-container">' + '       <div class="variable-container__current-condition">' + '           <div class="current-condition__icon-container">' + '               <div class="icon-container__icon-placeholder"></div>' + '           </div>' + '           <div class="current-condition__texts">' + '               <div class="texts__temp"></div>' + '               <div class="texts__desc"></div>' + '           </div>' + '       </div>' + '       <div class="variable-container__sun-state">' + '           <div class="sun-state-entry">' + '               <div class="sun-state-entry__icon">' + '                   ' + ImageBox.getResourceImageWithClasses(Icon.Weather.SUN_RISE, "icon-sunrise") + '               </div>' + '               <div class="sun-state-entry__time sun-state-entry__time-sun-rise"></div>' + '           </div>' + '           <div class="sun-state-entry">' + '               <div class="sun-state-entry__icon">' + '                   ' + ImageBox.getResourceImageWithClasses(Icon.Weather.SUN_SET, "icon-sunrise") + '               </div>' + '               <div class="sun-state-entry__time sun-state-entry__time-sun-set"></div>' + '           </div>' + '       </div>' + '   </div>' + '   <div class="current-info-section__current-details">' + '       ' + _getCurrentDetails() + '   </div>' + '</div>');
            };

            var _getForecastSection = function _getForecastSection() {
                return $('<div class="content-container__forecast-section">' + '   <div class="forecast-section__scroll-wrapper"></div>' + '</div>');
            };

            var _getDayOverviewSection = function _getDayOverviewSection() {
                return $('<div class="content-container__day-overview-section"></div>');
            };

            var _getCurrentDetails = function _getCurrentDetails() {
                var table = '<table class="current-info-section__current-details">',
                    detailKeys = Object.keys(CURRENT_DETAILS_TYPES);
                detailKeys.forEach(function (key, idx) {
                    var desc = "",
                        type = CURRENT_DETAILS_TYPES[key];

                    switch (type) {
                        case CURRENT_DETAILS_TYPES.TEMPERATURE:
                            desc = _('weather.perceivedTemp');
                            break;

                        case CURRENT_DETAILS_TYPES.PRESSURE:
                            desc = _('weather.barometricPressure');
                            break;

                        case CURRENT_DETAILS_TYPES.PRECIPITATION:
                            desc = _('weather.precipitation');
                            break;

                        case CURRENT_DETAILS_TYPES.REL_HUMIDITY:
                            desc = _('weather.relHumidity');
                            break;

                        case CURRENT_DETAILS_TYPES.SOL_RADIATION:
                            desc = _('weather.solarRadiation');
                            break;

                        case CURRENT_DETAILS_TYPES.DEW_POINT:
                            desc = _('weather.dewPoint');
                            break;

                        case CURRENT_DETAILS_TYPES.WIND_SPEED:
                            desc = _('weather.windSpeed');
                            break;

                        default:
                            desc = "Unknown Type: " + type;
                            break;
                    }

                    table += '<tr>' + '   ' + '<td class="current-details_desc desc--' + type + '">' + desc + '</td>' + '   ' + '<td class="current-details_value value--' + type + '"></td>' + '</tr>'; // Don't apply a spacer row after the last row, it won't look as good

                    if (idx < detailKeys.length - 1) {
                        // We can't apply any margin to a <tr> tag, so we use a spacer row with the same height as the margin we would give.
                        table += '<tr class="current-details_spacer-row"></tr>';
                    }
                });
                table += '</table>';
                return table;
            };

            return {
                getTemplate: getTemplate,
                getForecastElement: getForecastElement
            };
        }(); //endregion Static

        constructor(details) {
            super($('<div />'));
            this.weatherServer = ActiveMSComponent.getStructureManager().getWeatherServer();
            this.formats = this.weatherServer.details.format;
            this.forecastEntries = [];
            this.selectedTime = null;
            this.lastWindZRotation = 0;
            this.waitingForStatesDef = Q.defer();
        }

        viewDidLoad() {
            var prms = [super.viewDidLoad(...arguments) || true, this.waitingForStatesDef.promise // wait until all needed statea are available, this is used when the URL-Start location is the weather screen
            ];
            this.element.append(WeatherScreenV2.Template.getTemplate()); // Preparing our this.elements object
            // Iterate over all the current detail types to prepare every entry

            var currentDetails = {};
            Object.keys(CURRENT_DETAILS_TYPES).forEach(function (key) {
                var type = CURRENT_DETAILS_TYPES[key];
                currentDetails[key] = this.element.find('.value--' + type);
            }.bind(this)); // this.elements is already defined, so we can't initialize it,
            // We merge the already existing this.elements, with our objects to save some lines of code

            updateObject(this.elements, {
                currentInfoSection: {
                    currentCondition: {
                        Icon: this.element.find('.icon-container__icon-placeholder'),
                        Temp: this.element.find('.texts__temp'),
                        Desc: this.element.find('.texts__desc')
                    },
                    currentSunStats: {
                        container: this.element.find('.variable-container__sun-state'),
                        sunRiseTime: this.element.find(".sun-state-entry__time-sun-rise"),
                        sunSetTime: this.element.find(".sun-state-entry__time-sun-set")
                    },
                    currentDetails: currentDetails
                },
                forecastSection: {
                    container: this.element.find(".content-container__forecast-section"),
                    scrollWrapper: this.element.find(".forecast-section__scroll-wrapper")
                },
                dayOverviewSection: {
                    container: this.element.find(".content-container__day-overview-section"),
                    buttons: {
                        // Note, this must be the same keys as in this.states.forecasts
                        today: new GUI.DayButton(this),
                        tomorrow: new GUI.DayButton(this),
                        dayAfterTomorrow: new GUI.DayButton(this)
                    }
                }
            });
            Object.keys(this.elements.dayOverviewSection.buttons).forEach(function (key) {
                var button = this.elements.dayOverviewSection.buttons[key];
                this.appendSubview(button, this.elements.dayOverviewSection.container);
            }.bind(this));
            this.elements.forecastSection.container.mousewheel(function (event, delta) {
                this.scrollLeft -= delta;
                event.preventDefault();
            });
            this.setUpComfortUI();
            this.setTitle(ActiveMSComponent.getActiveMiniserver().location);
            SandboxComponent.registerForStateChangesForUUID(WEATHER_UUID, this, this.receivedStates); // this is to get the sunset and sunrise time

            SandboxComponent.registerForStateChangesForUUID(GLOBAL_UUID, this, this.onSunStateChange);
            return Q.all(prms);
        }

        viewWillDisappear() {
            super.viewWillDisappear();
            SandboxComponent.unregisterForStateChangesForUUID(WEATHER_UUID, this);
            SandboxComponent.unregisterForStateChangesForUUID(GLOBAL_UUID, this);
        }

        getAnimation() {
            return AMBIENT_MODE ? AnimationType.FADE : AnimationType.PUSH_OVERLAP_LEFT;
        }

        getURL() {
            return "Weather";
        }

        receivedStates(states) {
            Debug.Weather && console.log("WeatherScreenV2 receivedStates"); // Here we merge the states (sunset and weather)
            //this.states = Object.assign(this.states || {}, states); // This will now work on > iOS 9 and Android 4.4.3

            this.states = jQuery.extend({}, this.states || {}, states);

            if (this.states.hasValidData && this.states.sunrise && this.states.sunset) {
                this._update();
            }
        }

        onSunStateChange(states) {
            Debug.Weather && console.log("WeatherScreenV2 receivedSunStates"); // Here we merge the states (sunset and weather)
            //this.states = Object.assign(this.states || {}, {sunrise: states.sunrise, sunset: states.sunset}); // This will now work on > iOS 9 and Android 4.4.3

            this.states = jQuery.extend({}, this.states || {}, {
                sunrise: states.sunrise,
                sunset: states.sunset
            });

            if (this.states.hasValidData) {
                this._update();
            }
        }

        daySelected(dayButton) {
            Object.keys(this.elements.dayOverviewSection.buttons).forEach(function (buttonKey, buttonIdx) {
                var button = this.elements.dayOverviewSection.buttons[buttonKey],
                    selectEntryIdx = 9; // Show the 8th hour, it's much more interesting that midnight

                if (dayButton === button) {
                    if (buttonKey === "today") {
                        selectEntryIdx = 0;
                    }

                    this._updateAndSelectEntry(this.states.forecasts[buttonKey][selectEntryIdx]);
                }
            }.bind(this));
        }

        // PRIVATE METHODS
        _update() {
            // Check if the we are still waiting for the waitingForStatesDef promise to be resolved and resolve it here
            // this will remove the "waiting for" popup which will be displayed on initial start of the app (URL-Scheme to Weather, or EntryLocation to last location)
            if (this.waitingForStatesDef.promise.inspect().state === "pending") {
                this.waitingForStatesDef.resolve();
            } // Default is the first entry of today
            // keep the currently selected entry selected


            var entry = this.states.forecasts.today[0],
                keepEntrySelected = this.selectedTime !== null;

            if (keepEntrySelected) {
                entry = this._getEntryWithTime(this.selectedTime);
            } // Initiate the forecast if we don't have any items yet


            if (this.forecastEntries.length === 0) {
                this._initiateForecastSection();
            }

            this._updateAndSelectEntry(entry, true);
        }

        /**
         * Updates every section and selects the given entry
         * @param entry
         * @param preventScroll
         * @private
         */
        _updateAndSelectEntry(entry, preventScroll) {
            this._updateCurrentInfoSection(entry);

            this._updateForecastSection();

            !preventScroll && this._scrollToForecastEntry(entry);

            this._selectEntryInForecastSection(entry);

            this._updateDayButtons(entry);

            this.selectedTime = entry.timestamp;
        }

        // CurrentInfoSection

        /**
         * Updates the CurrentInfoSection
         * It handles the icon, Temperature, sunset and rise and the details
         * @param entry
         * @private
         */
        _updateCurrentInfoSection(entry) {
            var date = moment().date(),
                entryDate = entry.timestamp.date(),
                timeString = entry.timestamp.format(LxDate.getDateFormat(DateType.TimeFormat)),
                weekday = entry.timestamp.format(DateType.Weekday),
                conditionIcon = ImageBox.getResourceImageWithClasses(entry.weatherIcon, "icon-placeholder__icon"); // Remove sun information (sun rise and sun set) for any future day

            this._toggleSunInfo(this.states.forecasts.today.indexOf(entry) !== -1); // Set the time of the entry


            if (entryDate === date) {
                this.setSubtitle(_('dateTime.today') + ", " + timeString);
            } else if (entry.isDayOverview) {
                this.setSubtitle(weekday);
            } else {
                this.setSubtitle(weekday + ", " + timeString);
            } // CurrentConditions


            this.elements.currentInfoSection.currentCondition.Icon.html(conditionIcon);
            this.elements.currentInfoSection.currentCondition.Temp.text(lxFormat(this.formats.temperature, entry.temperature));
            this.elements.currentInfoSection.currentCondition.Desc.text(entry.weatherText.capitalize()); // CurrentSunStats

            this.elements.currentInfoSection.currentSunStats.sunRiseTime.text(this.states.sunrise.format(DateType.TimeFormat));
            this.elements.currentInfoSection.currentSunStats.sunSetTime.text(this.states.sunset.format(DateType.TimeFormat)); // CurrentDetails

            this._updateCurentInfoSectionDetails(entry);
        }

        _updateCurentInfoSectionDetails(entry) {
            Object.keys(this.elements.currentInfoSection.currentDetails).forEach(function (key) {
                var value = "",
                    type = CURRENT_DETAILS_TYPES[key];

                switch (type) {
                    case CURRENT_DETAILS_TYPES.TEMPERATURE:
                        value = lxFormat(this.formats.temperature, entry.perceivedTemperature);
                        break;

                    case CURRENT_DETAILS_TYPES.PRESSURE:
                        value = lxFormat(this.formats.barometricPressure, entry.barometricPressure);
                        break;

                    case CURRENT_DETAILS_TYPES.PRECIPITATION:
                        value = lxFormat(this.formats.precipitation, entry.precipitation);
                        break;

                    case CURRENT_DETAILS_TYPES.REL_HUMIDITY:
                        value = lxFormat(this.formats.relativeHumidity, entry.relativeHumidity);
                        break;

                    case CURRENT_DETAILS_TYPES.SOL_RADIATION:
                        value = entry.solarRadiation + " %";
                        break;

                    case CURRENT_DETAILS_TYPES.DEW_POINT:
                        value = lxFormat(this.formats.temperature, entry.dewPoint);
                        break;

                    case CURRENT_DETAILS_TYPES.WIND_SPEED:
                        var direction = entry.windDirection,
                            windDirectionIcon = // Directly set the last z-rotation to have a fluid animation
                                '<div class="value__wind-dir-icon" style="transform: rotateZ(' + this.lastWindZRotation + 'deg)">' + // use a container for android bug?
                                '   ' + ImageBox.getResourceImageWithClasses(Icon.Weather.COMPASS, 'wind-dir-icon__icon') + '</div>';
                        value = this._getShortWindDirection(direction) + windDirectionIcon + lxFormat(this.formats.windSpeed, entry.windSpeed);
                        break;

                    default:
                        value = "Unknown Type: " + type;
                        break;
                }

                this.elements.currentInfoSection.currentDetails[key].html(value); // Animate the Wind-dir-icon if we have the type wind_speed

                if (type === CURRENT_DETAILS_TYPES.WIND_SPEED) {
                    // update wind direction icon (+180deg / windDirection is the "source" of the wind!)
                    var windZRotation = (direction + 180) % 360; // also handle eg. 460deg -> 100deg

                    if (this.lastWindZRotation !== windZRotation) {
                        var windIcon = this.elements.currentInfoSection.currentDetails[key].find(".value__wind-dir-icon");
                        windIcon.velocity("stop").velocity({
                            translateZ: 0,
                            // Force HA by animating a 3D property
                            rotateZ: [this.lastWindZRotation, windZRotation] // Rotate from the current position to the new position

                        }, {
                            duration: 800,
                            easing: "spring"
                        });
                    }

                    this.lastWindZRotation = windZRotation;
                }
            }.bind(this));
        }

        // Forecast Section

        /**
         * Adds all the forecastEntries to the forecast
         * @private
         */
        _initiateForecastSection() {
            // find the min/max temp!
            var minTemp = this.states.forecasts.today[0].temperature,
                maxTemp = this.states.forecasts.today[0].temperature;

            var temps = this._getMinMaxTemp(minTemp, maxTemp);

            minTemp = temps.min;
            maxTemp = temps.max;
            var paddingTemp = Math.ceil((maxTemp - minTemp) / 5); // temp to add/subtract from min/max
            // adopt min and max to avoid reaching top and bottom!

            minTemp = Math.floor(minTemp);
            maxTemp = Math.ceil(maxTemp);
            this._minTemp = minTemp - paddingTemp;
            this._maxTemp = maxTemp + paddingTemp;
            this._dayTempRange = this._maxTemp - this._minTemp;

            this._appendForecastEntries();
        }

        /**
         * Appends a ForecastEntry for each forecastentry
         * @private
         */
        _appendForecastEntries() {
            var forecastKeys = Object.keys(this.states.forecasts);
            forecastKeys.forEach(function (key, forecastKeyIdx) {
                var forecast = this.states.forecasts[key];
                forecast.forEach(function (forecastEntry, forecastEntryIdx) {
                    var weatherForecastEntry = new GUI.WeatherForecastEntry(this, forecastEntry);
                    this.forecastEntries.push(weatherForecastEntry);
                    this.appendSubview(weatherForecastEntry, this.elements.forecastSection.scrollWrapper);
                }.bind(this));
            }.bind(this));
        }

        /**
         * Delegate method of the ForecastEntry if it was selected
         * @param forecastEntry
         * @param selectedEntry
         */
        onForecastEntrySelected(forecastEntry, selectedEntry) {
            this._updateAndSelectEntry(selectedEntry, true);
        }

        /**
         * Update every ForecastEntry with a new entry
         * @private
         */
        _updateForecastSection() {
            var forecastKeys = Object.keys(this.states.forecasts),
                forecastEntryIdx = 0;
            forecastKeys.forEach(function (key, forecastKeyIdx) {
                var forecast = this.states.forecasts[key];
                forecast.forEach(function (forecastEntry) {
                    var weatherForecastEntry = this.forecastEntries[forecastEntryIdx++];
                    weatherForecastEntry.update(forecastEntry);
                }.bind(this));
            }.bind(this));
        }

        /**
         * Selects the given entry and deselects the currently selected entry
         * @param entry
         * @private
         */
        _selectEntryInForecastSection(entry) {
            var entryToSelect = this.forecastEntries[this._getForecastIdxOfEntry(entry)],
                selectedEntry = this._getEntryWithTime(this.selectedTime),
                selectedForecastEntry = this.forecastEntries[this._getForecastIdxOfEntry(selectedEntry)];

            selectedForecastEntry.setSelected(false);
            entryToSelect.setSelected(true);
        }

        /**
         * Scrolls to a given entry in the forecast
         * @param entry
         * @private
         */
        _scrollToForecastEntry(entry) {
            var position = this._getForecastIdxOfEntry(entry) * GUI.WeatherForecastEntry.DIMENSIONS.WIDTH;
            this.elements.forecastSection.scrollWrapper.velocity("stop").velocity("scroll", {
                offset: position,
                axis: "x",
                container: this.elements.forecastSection.scrollWrapper.parent(),
                translateX: "0px"
            });
            this.currentScrollOffset = position;
        }

        // Daybuttons

        /**
         * Updates all dayButtons and sets the button that matches the given entry as selected
         * @param entry
         * @private
         */
        _updateDayButtons(entry) {
            var forecasts = this.states.forecasts;
            Object.keys(forecasts).forEach(function (forecastKey, forecastIdx) {
                var dayEntries = forecasts[forecastKey];
                var forecast = dayEntries[0];
                var dayButton = this.elements.dayOverviewSection.buttons[forecastKey];

                if (forecast) {
                    // We need to compute the min and may temperatures our self is it isn't a dayOverview entry
                    if (forecast.isDayOverview) {
                        dayButton.updateDay({
                            name: forecast.title,
                            iconSrc: forecast.weatherIcon,
                            minTemp: lxFormat(this.formats.temperature, forecast.minTemperature),
                            maxTemp: lxFormat(this.formats.temperature, forecast.temperature)
                        });
                    } else {
                        var minMax = this._getDayMinMaxOfDay(dayEntries);

                        dayButton.updateDay({
                            name: _("dateTime.today"),
                            iconSrc: forecast.weatherIcon,
                            minTemp: lxFormat(this.formats.temperature, minMax.min),
                            maxTemp: lxFormat(this.formats.temperature, minMax.max)
                        });
                    }
                }
            }.bind(this));

            this._selectDayButtonForEntry(entry);
        }

        _selectDayButtonForEntry(entry) {
            Object.keys(this.elements.dayOverviewSection.buttons).forEach(function (buttonKey, buttonIdx) {
                var button = this.elements.dayOverviewSection.buttons[buttonKey];
                button.setActive(this.states.forecasts[buttonKey].indexOf(entry) !== -1);
            }.bind(this));
        }

        // Helper functions

        /**
         * Checks if two entries are equal
         * @param entry1
         * @param entry2
         * @returns {*}
         * @private
         */
        _entryEqualsEntry(entry1, entry2) {
            return entry1.timestamp.isSame(entry2.timestamp);
        }

        _toggleSunInfo(show) {
            var visibilityState = show ? "visible" : "hidden";
            this.elements.currentInfoSection.currentSunStats.container.css("visibility", visibilityState);
        }

        _getMinMaxTemp(minTemp, maxTemp) {
            var days = mapToArray(this.states.forecasts);

            for (var j = 0; j < days.length; j++) {
                var day = days[j];
                var i = 1;

                while (i < day.length) {
                    if (day[i].temperature < minTemp) {
                        minTemp = day[i].temperature;
                    } else if (day[i].temperature > maxTemp) {
                        maxTemp = day[i].temperature;
                    }

                    i++;
                }
            }

            return {
                min: minTemp,
                max: maxTemp
            };
        }

        _getDayMinMaxOfDay(dayEntry) {
            var min = 9999;
            var max = -273;

            for (var dayProp in dayEntry) {
                if (dayEntry.hasOwnProperty(dayProp)) {
                    var temp = dayEntry[dayProp].temperature;

                    if (temp < min) {
                        min = temp;
                    }

                    if (temp > max) {
                        max = temp;
                    }
                }
            }

            return {
                min: min,
                max: max
            };
        }

        _getForecastIdxOfEntry(entry) {
            var idx = 0,
                arrIdx = this.states.forecasts.today.indexOf(entry);

            if (arrIdx !== -1) {
                return idx + arrIdx;
            }

            idx += this.states.forecasts.today.length;
            arrIdx = this.states.forecasts.tomorrow.indexOf(entry);

            if (arrIdx !== -1) {
                return idx + arrIdx;
            }

            idx += this.states.forecasts.tomorrow.length;
            arrIdx = this.states.forecasts.dayAfterTomorrow.indexOf(entry);

            if (arrIdx !== -1) {
                return idx + arrIdx;
            }
        }

        /**
         * Returns the matching entry for the given time, will return the first entry of today if no matching entry has been found
         * @param time
         * @returns {*}
         * @private
         */
        _getEntryWithTime(time) {
            var dayKeys = Object.keys(this.states.forecasts),
                foundEntry = this.states.forecasts.today[0];
            dayKeys.forEach(function (dayKey) {
                var day = this.states.forecasts[dayKey];
                day.forEach(function (entry) {
                    if (entry.timestamp.isSame(time)) {
                        foundEntry = entry;
                    }
                });
            }.bind(this));
            return foundEntry;
        }

        /**
         * Returns the direction in which the wind is blowing as a short string eg NNE (North-north-east)
         * @param directionInDegree
         * @returns {*}
         * @private
         */
        _getShortWindDirection(directionInDegree) {
            // the direction always identifies the source of the wind, not the direction it blows towards
            // http://de.windfinder.com/wind/windspeed.htm

            /*
             N	    Nord	        0°          348.75 - 11.25
             NNE	Nordnordost	    22.5°       11.25  - 33.75
             NE	    Nordost	        45°         33.75  - 56.25
             ENE	Ostnordost	    67.5°       56.25  - 78.75
             E	    Ost	            90°         78.75  - 101.25
             ESE	Ostsüdost	    112.5°      101.25 - 123.75
             SE	    Südost	        135°        123.75 - 146.25
             SSE	Südsüdost	    157.5°      146.25 - 168.75
             S	    Süd	            180°        168.75 - 191.25
             SSW	Südsüdwest	    202.5°      191.25 - 213.75
             SW	    Südwest	        225°        213.75 - 236.25
             WSW	Westsüdwest	    247.5°      236.25 - 258.75
             W	    West	        270°        258.75 - 281.25
             WNW	Westnordwest	292.5°      281.25 - 303.75
             NW	    Nordwest	    315°        303.75 - 326.25
             NNW	Nordnordwest	337.5°      326.25 - 348.75
             */
            if (348.75 < directionInDegree && directionInDegree <= 360 || 0 <= directionInDegree && directionInDegree <= 11.25) {
                // N
                return _('northAbbr');
            } else if (11.25 < directionInDegree && directionInDegree <= 33.75) {
                // NNE
                return _('northAbbr') + _('northAbbr') + _('eastAbbr');
            } else if (33.75 < directionInDegree && directionInDegree <= 56.25) {
                // NE
                return _('northAbbr') + _('eastAbbr');
            } else if (56.25 < directionInDegree && directionInDegree <= 78.75) {
                // ENE
                return _('eastAbbr') + _('northAbbr') + _('eastAbbr');
            } else if (78.75 < directionInDegree && directionInDegree <= 101.25) {
                // E
                return _('eastAbbr');
            } else if (101.25 < directionInDegree && directionInDegree <= 123.75) {
                // ESE
                return _('eastAbbr') + _('southAbbr') + _('eastAbbr');
            } else if (123.75 < directionInDegree && directionInDegree <= 146.25) {
                // SE
                return _('southAbbr') + _('eastAbbr');
            } else if (146.25 < directionInDegree && directionInDegree <= 168.75) {
                // SSE
                return _('southAbbr') + _('southAbbr') + _('eastAbbr');
            } else if (168.75 < directionInDegree && directionInDegree <= 191.25) {
                // S
                return _('southAbbr');
            } else if (191.25 < directionInDegree && directionInDegree <= 213.75) {
                // SSW
                return _('southAbbr') + _('southAbbr') + _('westAbbr');
            } else if (213.75 < directionInDegree && directionInDegree <= 236.25) {
                // SW
                return _('southAbbr') + _('westAbbr');
            } else if (236.25 < directionInDegree && directionInDegree <= 258.75) {
                // WSW
                return _('westAbbr') + _('southAbbr') + _('westAbbr');
            } else if (258.75 < directionInDegree && directionInDegree <= 281.25) {
                // W
                return _('westAbbr');
            } else if (281.25 < directionInDegree && directionInDegree <= 303.75) {
                // WNW
                return _('westAbbr') + _('northAbbr') + _('westAbbr');
            } else if (303.75 < directionInDegree && directionInDegree <= 326.25) {
                // NW
                return _('northAbbr') + _('westAbbr');
            } else if (326.25 < directionInDegree && directionInDegree <= 348.75) {
                // NNW
                return _('northAbbr') + _('northAbbr') + _('westAbbr');
            }
        }

    }

    GUI.WeatherScreenV2 = WeatherScreenV2;
}
