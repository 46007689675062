'use strict';

define(['TinyPlayer', 'AudioZoneControlEnums'], function (TinyPlayer, AudioZoneControlEnums) {
    return class TinyPlayerHD extends TinyPlayer {
        //region Static
        static Template = function () {
            var getControlsBar = function getContainer() {
                return '' + '<div class="tiny-player-hd__controls">' + '   <div class="controls__progress-bar">' + '       <div class="progress-bar__item"></div>' + '   </div>' + '   <div class="controls__player-container">' + '       <div class="player-container--left">' + '           <div class="right__cover-container right__cover-container--default">' + '           ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.NOTE, "cover__icon") + '           </div>' + '           <div class="right__track-info-container">' + '               <div class="track-info-container__track-title track-title"></div>' + '               <div class="track-info-container__artist-name artist-name"></div>' + '           </div>' + '       </div>' + '       <div class="player-container--center">' + '           <div class="action-section__buttons">' + '              <div class="buttons__prev clickable">' + '              ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PREV, "item__icon") + '              </div>' + '              <div class="buttons__play-state clickable">' + '              ' + ImageBox.getResourceImageWithClasses(Icon.INDICATOR, "item__icon") + '              </div>' + '              <div class="buttons__next clickable">' + '              ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.NEXT, "item__icon") + '              </div>' + '           </div>' + '           <div class="controls__slider-container volume-slider"></div>' + '       </div>' + '       <div class="player-container--right">' + '           <div class="buttons__group clickable">' + '           ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.GROUPED, "item__icon") + '           </div>' + '           <div class="player_details__queue clickable">' + '           ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.QUEUE, "item__icon") + '           </div>' + '       </div>' + '   </div>' + '</div>';
                return '' + '<div class="tiny-player-hd__controls">' + '   <div class="controls__progress-bar">' + '       <div class="progress-bar__item"></div>' + '   </div>' + '   <div class="controls__text-section">' + '       <div class="text-section__scroll-wrapper">' + '           <div class="scroll-wrapper__track-title track-title"></div>' + '          <div class="scroll-wrapper__artist-name artist-name"></div>' + '       </div>' + '   </div>' + '   <div class="controls__action-section">' + '       <div class="action-section__slider-container volume-slider"></div>' + '       <div class="action-section__buttons">' + '           <div class="buttons__prev clickable">' + '           ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.PREV, "item__icon") + '           </div>' + '           <div class="buttons__play-state clickable">' + '           ' + ImageBox.getResourceImageWithClasses(Icon.INDICATOR, "item__icon") + '           </div>' + '           <div class="buttons__next clickable">' + '           ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEXT, "item__icon") + '           </div>' + '       </div>' + '       <div class="action-section__player_details">' + '           <div class="buttons__group clickable">' + '           ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.GROUPED, "item__icon") + '           </div>' + '           <div class="player_details__queue clickable">' + '           ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.QUEUE, "item__icon") + '           </div>' + '       </div>' + '   </div>' + '</div>';
            };

            return {
                getControlsBar: getControlsBar
            };
        }(); //endregion Static

        constructor(control) {
            super(...arguments);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.registerForResize();
        }

        viewWillAppear() {
            return Q(super.viewWillAppear(...arguments) || true).then(function () {
                if (MediaServerComp.isRestricted()) {
                    this.hideSubview(this.buttons.groupButton);
                    this.hideSubview(this.buttons.queueButton);
                }
            }.bind(this));
        }

        getTemplate() {
            return TinyPlayerHD.Template.getControlsBar();
        }

        prepareUI() {
            super.prepareUI(...arguments);
            this.elements.topControlArea = this.element.find(".action-section__buttons")
            this.elements.leftArea = this.element.find('.player-container--left')
            this.elements.rightArea = this.element.find('.player-container--right')
            this.elements.prevButton = this.element.find('.buttons__prev');
            this.elements.queueButton = this.element.find('.player_details__queue');
            this.buttons.prevButton = new GUI.LxButton(this, this.elements.prevButton[0], Color.BUTTON_GLOW);
            this.buttons.queueButton = new GUI.LxButton(this, this.elements.queueButton[0], Color.BUTTON_GLOW);

            //player is now clickable only in areas around the slider, because slider and hammer.js are colliding otherwise
            this.buttons.playerButtonTop = new GUI.LxButton(this, this.elements.topControlArea[0], Color.BUTTON_GLOW);
            this.buttons.playerButtonLeft = new GUI.LxButton(this, this.elements.leftArea[0], Color.BUTTON_GLOW);
            this.buttons.playerButtonRight = new GUI.LxButton(this, this.elements.rightArea[0], Color.BUTTON_GLOW);
        }

        onResize() {
            this._checkForMarquee();
        }

        _onStateChange(states) {
            super._onStateChange(...arguments);

            this._checkForMarquee();
        }

        //----------------------
        // Private Section
        //----------------------
        _handleButtonTapped(sourceBtn) {
            super._handleButtonTapped(...arguments);

            if (!this.states) {
                return;
            }

            switch (sourceBtn) {
                case this.buttons.playerButtonLeft:
                case this.buttons.playerButtonRight:
                case this.buttons.playerButtonTop:
                    if (!MediaServerComp.isRestricted()) {
                        this.ViewController.showState(AudioZoneControlEnums.ScreenState.PLAYER, null, {
                            control: this.control
                        }, null, true);
                    }

                    break;

                case this.buttons.prevButton:
                    this._prevTrack();

                    break;

                case this.buttons.queueButton:
                    if (!MediaServerComp.isRestricted()) {
                        this.ViewController.showState(AudioZoneControlEnums.ScreenState.PLAYER, null, {
                            control: this.control,
                            showQueue: true
                        }, null, true);
                    } else {
                        MediaServerComp.showRestrictedPopup();
                    }

                    break;

                default:
                    break;
            }
        }

        _checkForMarquee() {// TODO-goelzda implement auto scrolling
        }

    };
});
