'use strict';

define([
    "IRoomControllerV2ControlEnums",
    "IRoomControllerV2021ControlEnums",
    "IrcFixedSetpointUtil"
], function (IRoomControllerV2ControlEnums, IRoomControllerV2021ControlEnums, { Util: FixedSetpointUtil }) {
    return class IRoomV2ControlContentTemperatureSelection extends Controls.ControlContent {
        constructor(details) {
            super(details);
            this.selectedTempMode = details.entry.tempMode;
            this.parentControl = ActiveMSComponent.getControlByUUID(this.control.uuidParent);
        }

        viewDidLoad() {
            var promise = super.viewDidLoad(...arguments);
            this.listSource = [IRoomControllerV2ControlEnums.Mode.ECO, IRoomControllerV2ControlEnums.Mode.COMFORT, IRoomControllerV2ControlEnums.Mode.ECO_PLUS];
            if (Feature.IRC_SCHEDULE_SETPOINTS) {
                var states = this.parentControl.getStates(),
                    target = states ? states.tempTarget : 22;

                if (FixedSetpointUtil.isFixedSetpointValue(this.selectedTempMode)) {
                    target = FixedSetpointUtil.extractFromValue(this.selectedTempMode).target;
                }

                if (states.canHeat && states.canCool) {
                    this.listSource.push(FixedSetpointUtil.convertToValue(target, true, true));
                }
                if (states.canCool) {
                    this.listSource.push(FixedSetpointUtil.convertToValue(target, false, true));
                }
                if (states.canHeat) {
                    this.listSource.push(FixedSetpointUtil.convertToValue(target, true, false));
                }
            }
            this.tableView = new GUI.TableViewV2(this, this);
            this.tableView.getElement().addClass("temperature-selection__table-view");
            this.appendSubview(this.tableView);
            return promise;
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function () {
                // reload after each viewWillAppear, this.control is the daytimer, so one cannot rely on it's states
                // --> the temperature values required are contained by the parents statecontainer (the irc)
                return this.tableView.reload();
            }.bind(this));
        }

        getURL() {
            return "TemperatureList";
        }

        titleBarText() {
            return _("iroomcontroller.tempactual");
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK,
                style: 'transparent'
            };
        }

        // TableViewDataSource methods
        styleForTableView() {
            return TableViewStyle.GROUPED;
        }

        numberOfSections() {
            return Feature.IRC_V2021 ? 2 : 1;
        }

        numberOfRowsInSection(section) {
            if (section === 1) {
                return 1;
            } else {
                return this.listSource.length;
            }
        }

        cellTypeForCellAtIndex(section, row) {
            if (section === 0) {
                return GUI.TableViewV2.CellType.CHECKABLE;
            } else {
                return GUI.TableViewV2.CellType.GENERAL;
            }
        }

        contentForCell(cell, section, row) {
            if (section === 1) {
                return this._getTempEditCell();
            }

            var description;

            if (Feature.IRC_V2021) {
                if (FixedSetpointUtil.isFixedSetpointValue(this.listSource[row])) {
                    description = ""; // no temp shown while picking the "mode" (heat&cool, heat only, cool only)
                } else {
                    description = this.parentControl.getTempRangeText(this.listSource[row]);
                }
            } else {
                description = this.parentControl.getTempModeDesc(this.listSource[row]);
            }

            return {
                title: this.parentControl.getNameOfIRCTempMode(this.listSource[row]),
                selected: this._isSelected(this.listSource[row]),
                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                disclosureText: description,
                disclosureColor: this.parentControl.getColorOfIRCTempMode(this.listSource[row], true)
            };
        }

        _isSelected(listSourceEntry) {
            if (FixedSetpointUtil.extractFromValue(this.selectedTempMode) && FixedSetpointUtil.isFixedSetpointValue(listSourceEntry)) {
                let currSetpoint = FixedSetpointUtil.extractFromValue(this.selectedTempMode);
                let listSetpoint = FixedSetpointUtil.extractFromValue(listSourceEntry);
                return (listSetpoint.cool === currSetpoint.cool && listSetpoint.heat === currSetpoint.heat);
            } else {
                return this.selectedTempMode === listSourceEntry;
            }
        }

        // TableViewDelegate methods
        didCheckCell(cell, section, row, tableView, selected) {
            if (selected) {
                this.selectedTempMode = this.listSource[row];

                this._navigateBack();
            }
        }

        didSelectCell(cell, section, row) {
            this.ViewController.showState(IRoomControllerV2021ControlEnums.ScreenState.TEMP_SETTINGS, null, {
                control: this.parentControl
            });
        }

        _navigateBack() {
            this.ViewController.navigateBack(false, {
                modeNr: this.selectedTempMode,
                targetTemp: this.parentControl.getTempStringOfMode(this.selectedTempMode),
                tempModeName: this.parentControl.getNameOfIRCTempMode(this.selectedTempMode)
            });
        }

        _getTempEditCell() {
            return {
                title: _("edit"),
                rightIconSrc: Icon.IRCV2021.SETTINGS,
                rightIconColor: Color.STATE_INACTIVE_B,
                clickable: true
            };
        }

    };
});
