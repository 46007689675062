import {LxReactText, useControl,LxReactImageView, useLiveState, LxReactTableView} from "LxComponents"
import {View,ScrollView} from "react-native"
import {useMemo} from "react"
import globalStyles from "GlobalStyles";
import MeterHeaderValue from "./meterHeaderValue";

export default function MeterV2NoStatsControlContent({navigation, route}) {
    const ctrlUuid = route.params.controlUUID;
    const control = useControl(ctrlUuid);

    const styles = {
        rootCntr: {
            ...globalStyles.customStyles.screenContent
        },
        iconCntr: {
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: 138
        },
        icon: {
            justifyContent: "center",
            alignItems: "center",
            width: 70,
            height: 70,
            fill: globalStyles.colors.text.primary
        },
        actualContainer: {
            flexDirection: "row"
        },
        actualValue: {
            flex: 1
        },
        headerTitleStyle: {
            ...globalStyles.customStyles.sectionHeader
        },
        cellTitleStyle: {
            ...globalStyles.customStyles.cellTitle
        },
        valueTextStyle: {
            ...globalStyles.customStyles.rightCellText
        }
    }

    const renderTitle = (title) => {
        return <LxReactText style={styles.headerTitleStyle}>{title}</LxReactText>;
    }

    const {requiredStateNames, sectionTemplates} = useMemo(() => {
        let sectionTemplates = [];
        let requiredStateNames = [
            "actualValue",
            "totalDayValue",
            "totalWeekValue",
            "totalMonthValue",
            "totalYearValue",
            "totalValue",
            "stateColor"
            ];

        sectionTemplates.push({
            headerElement: renderTitle(control.getTotalEnergyName()),
            rowTemplates: [
                {state: "totalDayValue", title: _("dateTime.today")},
                {state: "totalWeekValue", title: _("dateTime.this-week")},
                {state: "totalMonthValue", title: _("dateTime.this-month")},
                {state: "totalYearValue", title: _("dateTime.this-year")},
                {state: "totalValue", title: _("meter.total")},
            ]
        });


        if (control.isBidirectional()) {
            requiredStateNames.splice(0,0, ...[
                "totalNegDayValue",
                "totalNegWeekValue",
                "totalNegMonthValue",
                "totalNegYearValue",
                "totalNegValue",
            ]);

            sectionTemplates.push({
                headerElement: renderTitle(control.getTotalNegEnergyName()),
                rowTemplates: [
                    {state: "totalNegDayValue", title: _("dateTime.today")},
                    {state: "totalNegWeekValue", title: _("dateTime.this-week")},
                    {state: "totalNegMonthValue", title: _("dateTime.this-month")},
                    {state: "totalNegYearValue", title: _("dateTime.this-year")},
                    {state: "totalNegValue", title: _("meter.total")},
                ]
            });
        }
        if (control.hasStorage()) {
            requiredStateNames.splice(0,0, ...[
                "storageValue",
            ]);
        }
        return {requiredStateNames, sectionTemplates};
    }, [ctrlUuid]);
    const liveState = useLiveState(ctrlUuid, requiredStateNames);

    const {tableContent} = useMemo(() => {
        let tableContent = sectionTemplates.map((templ) => {
            let section = { headerElement: templ.headerElement };
            section.rows = templ.rowTemplates.map((rowTempl) => {
                return {
                    title: rowTempl.title,
                    titleStyle: styles.cellTitleStyle,
                    mainRightContent: {
                        comp: LxReactText,
                        props: {
                            children: lxUnitConverter.convertAndApply(control.totalFormat, liveState.states[rowTempl.state]),
                            style: styles.valueTextStyle
                        }
                    }
                }
            });
            return section;
        });

        return {tableContent};
    }, [liveState.states, requiredStateNames]);

    const headerIcon = useMemo(() => {
        const color = liveState.states.stateColor || globalStyles.colors.text.primary;
        return <View style={styles.iconCntr}>
            <LxReactImageView containerStyle={{...styles.icon, fill: color}}  source={control.getIcon()} />
        </View>
    }, [control.defaultIcon, liveState.states.stateColor])


    const actualContent = useMemo(() => {
        let content = [],
            actualTxt = lxUnitConverter.convertSplitAndApply(control.actualFormat, liveState.states.actualValue),
            actualTitle = control.getNameForOutput("actual"),
            storageTxt, storageTitle,
            color = liveState.states.stateColor || globalStyles.colors.text.primary;

        content.push(<MeterHeaderValue unitTxt={actualTxt.succTxt}
                                       valueTxt={actualTxt.valueTxt}
                                       text={actualTitle}
                                       style={styles.actualValue}
                                       color={color}/>
        );

        if (control.hasStorage()) {
            storageTitle = control.getNameForOutput("storage");
            storageTxt = lxUnitConverter.convertSplitAndApply(control.storageFormat, liveState.states.storageValue);

            content.push(<MeterHeaderValue unitTxt={storageTxt.succTxt}
                                           valueTxt={storageTxt.valueTxt}
                                           text={storageTitle}
                                           style={styles.actualValue}
                                           color={color}/>
            );
        }

        return content;
    }, [control.details.type, liveState.states.actualValue, liveState.states.storageValue, liveState.states.stateColor]);


    return <ScrollView style={styles.rootCntr}>
        {headerIcon}
        <View style={styles.actualContainer}>{actualContent}</View>
        <LxReactTableView tableContent={tableContent} />
    </ScrollView>
}
