'use strict';

define(["AudioZoneV2ControlEnums", "MediaBrowserV2Service"], function (AudioZoneV2ControlEnums, MediaBrowserV2Service) {
    class MediaBrowserV2Service11 extends MediaBrowserV2Service {
        //region Static
        static isRadio() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Service.isRadio.apply(this, arguments);
        }

        static getCellFromContentTypeItem() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Service.getCellFromContentTypeItem.apply(this, arguments);
        }

        static getCellButtonIconSrc() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Service.getCellButtonIconSrc.apply(this, arguments);
        }

        static getCellButtonIconColor() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Service.getCellButtonIconColor.apply(this, arguments);
        }

        static getCellTypeForItemCellAtIndex() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Service.getCellTypeForItemCellAtIndex.apply(this, arguments);
        }

        static getIconObjForItem() {
            return Controls.AudioZoneV2Control.MediaBrowserV2Service.getIconObjForItem.apply(this, arguments);
        } //endregion Static


        constructor(details) {
            super(...arguments);
            applyMixins(this, MediaBrowserEditablePlaylistMixinV2.Mixin);
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function () {
                if (this.editModeActive && !this.control.audioserverComp.isEditingActive()) {
                    return this.startEditing(this.lastSelectedItem, this.lastSelectedItem.contentType, this.getMediaInfo(this.lastSelectedItem.contentType), this.lastSelectedItem.id, true);
                }
            }.bind(this));
        }

        viewWillDisappear() {
            if (this.editModeActive) {
                this.stopEditing(this.lastSelectedItem.id);
            }

            return super.viewWillDisappear(...arguments);
        }

        onContentTypeReload(reason, contentType) {
            return super.onContentTypeReload(...arguments).then(function () {
                if (reason === MusicServerEnum.ReloadCause.USER_CHANGED && this.lastSelectedItem.owner !== this.username) {
                    this.ViewController.navigateBack();
                }
            }.bind(this));
        }

        getEmptyViewIconSrc() {
            if (this.hasError) {
                return super.getEmptyViewIconSrc(...arguments);
            }

            return this.receivedContent ? null : super.getEmptyViewIconSrc(...arguments);
        }

        getEmptyViewTitle() {
            if (this.hasError) {
                return super.getEmptyViewTitle(...arguments);
            }

            return this.receivedContent ? _("media.playlist.empty.title") : super.getEmptyViewTitle(...arguments);
        }

        getEmptyViewButtonText() {
            if (this.hasError) {
                return super.getEmptyViewButtonText(...arguments);
            } else if (this.ViewController instanceof GUI.AddMediaViewControllerV2Base) {
                return this.receivedContent ? null : super.getEmptyViewButtonText(...arguments);
            } else {
                return this.receivedContent ? _("media.library.upload.title") : super.getEmptyViewButtonText(...arguments);
            }
        }

        emptyViewButtonAction() {
            if (!this.hasError && this.receivedContent) {
                this._addNewMusicToItem(this.lastSelectedItem, true);
            } else {
                return super.emptyViewButtonAction(...arguments);
            }
        }

        getActionsForCtxAndItem(ctx, item) {
            var actions = super.getActionsForCtxAndItem(...arguments),
                editable = true;

            if (ctx === this.CONTEXT.SPOTIFY) {
                // ownerId added to AS on 2021.11.09
                // Spotify playlists from favorites (mediaBrowser_Favorites getFileTypeForItem) are always marked
                // editable, but the owner might not fit, hence it must be checked here if the ownership matches the
                // current spotify user
                editable = Controls.AudioZoneV2Control.SingleTones.SpotifyAccountManager.shared(this.control).isOwnerOfItem(this.lastSelectedItem);
            }

            if (editable) {
                actions = actions.concat(this._getEditableActionsForCtxAndItem.apply(this, arguments));
            }

            return actions;
        }

    }

    // FileType = 11 = PLAYLIST_EDITABLE
    Controls.AudioZoneV2Control.MediaBrowserV2Service11 = MediaBrowserV2Service11;
    return Controls.AudioZoneV2Control.MediaBrowserV2Service11;
});
