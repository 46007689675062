import * as React from "react"
import Svg, { Path } from "react-native-svg"

function ChevronsUpDown(props) {
    return (
        <Svg isreact="true"
            width={12}
            height={17}
            viewBox="0 0 12 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.844 1.125a.25.25 0 01.312 0l5.273 4.219a.25.25 0 01.04.351l-.938 1.171a.25.25 0 01-.351.04l-4.024-3.22a.25.25 0 00-.312 0L1.82 6.906a.25.25 0 01-.352-.04L.531 5.695a.25.25 0 01.04-.351l5.273-4.22zM5.844 15.875a.25.25 0 00.312 0l5.273-4.219a.25.25 0 00.04-.351l-.938-1.171a.25.25 0 00-.351-.04l-4.024 3.22a.25.25 0 01-.312 0l-4.024-3.22a.25.25 0 00-.352.04l-.937 1.171a.25.25 0 00.04.351l5.273 4.22z"
            />
        </Svg>
    )
}

export default ChevronsUpDown
