'use strict';

define(["ActivityImage", "AtheneMediaHandler"], function (ActivityImage, MediaHandler) {
    return class AtheneActivityDetailsScreen extends Controls.ControlContent {
        //region Static
        static Template = function () {
            var getActivityPage = function getActivityPage(idx) {
                return $('<div class="athene-activity-details-screen__activity-page" id="' + idx + '" data-loading="true" data-error="false">' + '   <img class="activity-page__image" src="' + TRANSPARENT_16X9 + '">' + '' + ImageBox.getResourceImageWithClasses(Icon.Athene.NO_IMAGE, "activity-page__error-image") + '</div>');
            };

            return {
                getActivityPage: getActivityPage
            };
        }(); //endregion Static

        constructor(details) {
            super(details);
            this.details = details;
            this.control = details.control;
            this._visibleActivityIdx = details.selectedIdx;
            this.lastActivities = this.details.lastActivities || [];
            this.scaleValue = 1.0;
            this._pages = [];
            this.baseMap = {};
        }

        supportedOrientation() {
            return DeviceOrientation.ANY;
        }

        viewDidLoad() {
            let lastActivitiesPrms;

            if (!("lastActivities" in this.details)) {
                lastActivitiesPrms = MediaHandler.shared(this.control).getLastActivities();
            } else {
                lastActivitiesPrms = Q(this.details.lastActivities);
            }

            return lastActivitiesPrms.then(lastActivities => {
                this.lastActivities = lastActivities;
                return super.viewDidLoad(...arguments).then(() => {
                    this._pages = this._createActivityPages();
                    this.pageView = new GUI.LxPageView(this._pages, null, this._doneAnimating.bind(this), {
                        overscroll: true
                    });
                    this.pageView.disableUserInteraction(false);
                    this.elements = {};
                    return this.appendSubview(this.pageView).then(() => {
                        this.pageView.setActivePage(this.details.selectedIdx);
                    });
                });
            });
        }
        
        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarText() {
            if (this.lastActivities && this.lastActivities.length) {
                return this.lastActivities[this._visibleActivityIdx].getDate().format(LxDate.getDateFormat(DateType.DateTextAndTime));
            } else {
                return "";
            }
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK,
                style: 'transparent',
                rightSide: TitleBarCfg.CUSTOM_ICON,
                rightIconSrc: Icon.USER_MANAGEMENT.SHARE,
                rightIconColor: Color.BRAND_COLOR,
                rightAction: this.titleBarActionRight.bind(this)
            };
        }

        titleBarActionRight() {
            var activity = this.lastActivities[this._visibleActivityIdx],
                fileName = this.control.getName() + " " + this.lastActivities[this._visibleActivityIdx].getDate().format(DateType.DateTextAndTime) + ".jpg",
                originElement = this._titleBar.buttons.rightSideButton.getElement()[0];
            NavigationComp.showWaitingFor(activity.getHighRes()).then(function (dataURI) {
                exportBase64File(fileName, dataURI.split("base64,")[1], "image/jpg", originElement);
            }.bind(this), function () {// Just ignore the error (The request may just be aborted!)
            });
        }

        _createActivityPages() {
            return this.lastActivities.map(function (activity, idx) {
                return AtheneActivityDetailsScreen.Template.getActivityPage(idx);
            });
        }

        _doneAnimating(pageIndex) {
            this._loadActivityForIdx(pageIndex);

            [pageIndex - 2, pageIndex - 1, pageIndex + 1, pageIndex + 2].forEach(this._loadActivityForIdx.bind(this));
            this._visibleActivityIdx = pageIndex;
            var activity = this.lastActivities[this._visibleActivityIdx];
            this.processWhenVisible(() => {
                this._titleBar && this._titleBar.setTitleBarSideTexts(activity.getDate().format(LxDate.getDateFormat(DateType.DateTextAndTime)), this.titleBarConfig());
            });
        }

        _loadActivityForIdx(idx) {
            var activity = this.lastActivities[idx];

            if (activity) {
                activity.getHighRes().then(function (dataURI) {
                    var pageElm = this._pages[idx],
                        imgElm = pageElm.find(".activity-page__image");

                    if (imgElm.attr("src") !== dataURI) {
                        pageElm.attr("data-loading", "false");
                        imgElm.attr("src", dataURI);
                    }
                }.bind(this), function (e) {
                    var pageElm = this._pages[idx];
                    pageElm.attr("data-loading", "false");
                    pageElm.attr("data-error", "true");
                }.bind(this));
            }
        }

    };
});
