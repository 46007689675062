import {
    useSystemStatusInfo,
    LxReactImageView,
    useConnectionReady,
    LxReactPressable
} from "LxComponents"
import React, {useCallback, useEffect, useState} from "react";
import globalStyles from "GlobalStyles";
import PropTypes from "prop-types";

function DynamicRightButtonComp({navigation}) {
    const connectionReady = useConnectionReady(),
        defaultNewsInfo = {
            unreadCount: 0,
            color: globalStyles.colors.stateInActive,
            allDataLoaded: false
        },
        [newsInfo, setNewsInfo] = useState(defaultNewsInfo),
        sysStatusInfo = useSystemStatusInfo();

    useEffect(() => {
        let deregNewsUpdateReceiver = LxServerComponent.registerForNewsHubChanges(_newsChanged);

        return () => {
            deregNewsUpdateReceiver && deregNewsUpdateReceiver();
            setNewsInfo(defaultNewsInfo);
        }
    }, [])


    const RightButton = (props) => {
        let newsButtonColor = globalStyles.colors.buttonDisabledBg;

        if (newsInfo.allDataLoaded && sysStatusInfo.allDataLoaded) {
            newsButtonColor = newsInfo.color;
        }

        if (connectionReady && sysStatusInfo.allDataLoaded && sysStatusInfo.hasActiveEntry) {
            //return <Icons.HeartWithBeat key={"MessageCenterNewsButton"} fill={refs.current.severityButtonColor} height={dimensions.height} width={dimensions.width}/>

            return (
                <LxReactPressable
                    style={styles.icon}
                    onPress={_handleMessageCenterButtonTapped}>
                    <LxReactImageView source={Icon.MESSAGE_CENTER.HEALTH_CIRCLED} containerStyle={styles.iconContainer} imageStyle={{fill: sysStatusInfo.color}}/>
                </LxReactPressable>
            )
        } else {
            //return <Icons.News key={"MessageCenterNewsButton"} fill={refs.current.newsButtonColor} height={dimensions.height} width={dimensions.width}/>

            return (
                <LxReactPressable
                    style={styles.icon}
                    onPress={_handleNewsButtonTapped}>
                    <LxReactImageView source={Icon.Buttons.NEWS} containerStyle={styles.iconContainer} imageStyle={{fill: newsButtonColor}} />
                </LxReactPressable>
            )
        }
    }

    const _newsChanged = (ev, newsEntries) => {
        let unreadNews = newsEntries.newIds,
            unreadNewsCnt = unreadNews.length;

        setNewsInfo({
            unreadCount: unreadNewsCnt,
            color: unreadNewsCnt > 0 ? globalStyles.colors.stateActive : globalStyles.colors.stateInActive,
            allDataLoaded: true
        })
    }

    const _handleMessageCenterButtonTapped = useCallback(() => {
        navigation.navigate(ScreenState.MessageCenterMessagesScreen);
    }, []);

    const _handleNewsButtonTapped = useCallback(() => {
        navigation.navigate(ScreenState.NewsHubScreen);
    }, []);

    return <RightButton />
}

const styles = {
    iconContainer: {
        flex: 1
    },
    icon: {
        height: globalStyles.sizes.icons.big,
        width: globalStyles.sizes.icons.big
    }
}

DynamicRightButtonComp.propTypes = {
    navigation: PropTypes.object
}

export default DynamicRightButtonComp;
