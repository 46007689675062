'use strict';

define("AudioZoneControlContentHomeScreen", ["AudioZoneControlContent"], function (AudioZoneControlContent) {
    return class AudioZoneControlContentHomeScreen extends AudioZoneControlContent {
        constructor() {
            super(...arguments);
        }

        getAnimation() {
            return HD_APP ? AnimationType.HD_OVERLAY_FULLSCREEN : AnimationType.MODAL;
        }

        getTableView() {
            return new GUI.CardView(this.tableViewDataSource, this.tableViewDelegate);
        }

        getTableContent() {
            var def = Q.defer();
            var sectionPromises = [];

            var resolveFunction = function resolveFunction(promise) {
                return Q(promise).then(function (res) {
                    var clonedRes = cloneObjectDeep(res);
                    clonedRes && def.notify(clonedRes); // Don't notify with an empty result

                    return clonedRes;
                });
            };

            sectionPromises.pushObject(resolveFunction(this.tableViewContentHelper.getFavoriteSection(this.control, cloneObject(this.states))));

            if (Feature.SHARED_USER_SETTINGS) {
                sectionPromises.pushObject(resolveFunction(this.tableViewContentHelper.getShortcutSection(this.control)));
            }

            Q.all(sectionPromises).then(function (tableContent) {
                def.resolve(tableContent);
            });
            return def.promise;
        }

        setTitle() {// Overwrite as we don't want a title!
        }

        getLeftIcon() {
            return null;
        }

        getRightIcon() {
            return null;
        }

        _onTableViewReloadFinished() {// Just overwrite, we don't want to do anything in this case...
        }

        _shouldShowLinkedControls() {
            return false;
        }

    };
});
