import {getSubmenuCell} from "../../UtilityComp/utilities/helper";

export default class AalEmergencyControlStatesSrc{
    static getMoreSection(control, states) {
        let section = {
            rows: []
        };

        section.rows.push(getSubmenuCell(_("controls.aalemergency.history"), this.showHistory.bind(this, control)));

        return section;
    }

    static showHistory(control) {
        let trackerCtrl = Object.values(control.subControls)[0];
        trackerCtrl.name = _("controls.aalemergency.history");
        NavigationComp.showControlContent(trackerCtrl);
    }
}
