'use strict';

import {getRequirePathForModule} from "../UtilityComp/utilities/helper";

define("Control", ["Control_Central", "LxComponents"], function (Control_Central, {LxBaseControlContent, LxControlHistoryScreen}) {
    return class Control {

        static RequireContext = {
            CommandSrc: require.context("./", true, /.*CommandSrc\.js/),
            StatesSrc: require.context("./", true, /.*StatesSrc\.js/),
            ControlContent: require.context("./", true, /.*\/content\/controlContent\.jsx?$/),
        }

        get hasControlNotes() {
            return this._hasControlNotes && !this._isSubControl;
        }

        get hasHistory() {
            return !!(this.details && this.details.hasHistory);
        }

        get historyMaxLength() {
            if (!this.hasHistory) {
                return 0;
            } else if (this.details.hasHistory === true) {
                return 20; // initial limit, before it did become adoptable
            } else {
                return parseInt(this.details.hasHistory + ""); // ensure it's a number, even if it may be string.
            }
        }

        constructor(jsonObject, isSubControl) {
            Object.assign(this, Control_Central.Mixin);
            this._isSubControl = !!isSubControl;
            this._hasControlNotes = jsonObject.hasControlNotes;

            this._applyJsonObject(jsonObject);

            this._initCommandSrc();
            this._initStatesSrc();

            // To gain attention from the developer which is currently developing a control we get
            // the controlTypeName on controlInit to ensure he sees the potential warning about
            // the missing translation needed for the search and AutomaticDesigner
            if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.DeveloperInterface) {
                this.getControlTypeName();
            }
        }

        destroy() {
            // Check if the control has SingleTones to destroy
            if (Controls && Controls[this.constructor.name] && Controls[this.constructor.name].SingleTons) {
                Object.values(Controls[this.constructor.name].SingleTons).forEach(function (singleton) {
                    singleton.destroy(this);
                }.bind(this));
            }
        }

        /**
         * Checks if the control has AutomaticDesigner capabilities
         * @param [capabilityType] the capability type to check ["event"|"action"]
         * @return {boolean}
         */
        hasAutomaticDesignerCapabilities(capabilityType) {
            var capabilitiesEntries = AutomaticDesignerComponent.getDataFromCapabilities(this.type),
                capabilityTypes = capabilityType ? [capabilityType] : Object.values(AutomaticDesignerEnums.SCREEN_TYPES);
            return capabilitiesEntries.map(function (capabilitiesEntry) {
                // Filter out any conditional capabilities
                if (capabilitiesEntry) {
                    capabilityTypes.forEach(function (capType) {
                        if (capType && capabilitiesEntry.hasOwnProperty(capType)) {
                            capabilitiesEntry[capType] = capabilitiesEntry[capType].filter(function (actionOrEvent) {
                                return this.meetsCapabilityConditions(actionOrEvent);
                            }.bind(this));
                        }
                    }.bind(this));
                }

                if (capabilitiesEntry) {
                    if (capabilityType) {
                        return capabilitiesEntry && capabilitiesEntry.hasOwnProperty(capabilityType);
                    }

                    return !!capabilitiesEntry;
                } else {
                    return false;
                }
            }.bind(this)).reduce(function (left, right) {
                return Boolean(left | right);
            }, false);
        }

        meetsCapabilityConditions(actionOrEvent) {
            var conditionFulfilled = true;

            if (actionOrEvent.hasOwnProperty("condition")) {
                var flattenedConditions = flattenObject(actionOrEvent.condition);
                Object.keys(flattenedConditions).forEach(function (xPath) {
                    var toTest = __helperAIF(this, xPath);

                    if (toTest === undefined || toTest === null) {
                        // Always meet the condition if nothing to test is available: BG-I11799
                        conditionFulfilled &= true;
                    } else {
                        conditionFulfilled &= new RegExp(flattenedConditions[xPath].replace(/\\\\/, "\\")).test(toTest.toString());
                    }
                }.bind(this));
            }

            return Boolean(conditionFulfilled);
        }

        getControlTypeName() {
            return this._getControlTypeNameForType(this.type);
        }

        // called whenever an expert mode structure updated is received
        onStructureUpdated() {// nothing to do in the base.
        }

        isConfigured() {
            if (this.hasOwnProperty("configState") && this.configState.hasOwnProperty("isConfigured")) {
                return this.configState.isConfigured;
            } else {
                return true;
            }
        }

        /**
         * Returns the active system status entry that locks the UI
         * @returns {null}
         */
        getLockingSystemStatusMessage(statesObj) {
            let states = statesObj || this.getStates(),
                entry = null;
            if (states && states.messageCenterEntries && states.messageCenterEntries.length > 0) {
                let filtered = states.messageCenterEntries.filter((entry) => {
                    if (entry.setHistoricAt !== null) {
                        return false;
                    } else {
                        return entry.isVisuLocked;
                    }
                });
                if (filtered.length > 0) {
                    entry = filtered[0];
                }
            }
            return entry;
        }

        /**
         * Returns the most severe, active system status entry.
         * @returns {null}
         */
        getActiveSystemStatusMessage(statesObj) {
            let states = statesObj || this.getStates(),
                entry = null,
                minLevel = MessageCenterHelper.SEVERITY_LEVEL.WARNING;
            if (states && states.messageCenterEntries && states.messageCenterEntries.length > 0) {
                let filtered = states.messageCenterEntries.filter((entry) => {
                    if (entry.setHistoricAt !== null || entry.confirmedAt !== null) {
                        return false;
                    } else {
                        return entry.severity >= minLevel;
                    }
                });
                if (filtered.length > 0) {
                    filtered.sort((a, b) => {
                        // return value > 0 ==> a after b
                        // return value < 0 ==> b after a
                        // a with severity 3 and b with severity 2 --> result = -1 --> b after a
                        return b.severity - a.severity;
                    });
                    entry = filtered[0];
                }
            }
            return entry;
        }

        isInDevelopment() {
            return false;
        }

        getName() {
            var name;

            if (this.shouldUseRoomName()) {
                try {
                    name = this.getRoom().name;
                } catch (e) {
                    name = this.name;
                    console.warn(e.message);
                }
            } else {
                name = this.name;
            }

            if (this.isInDevelopment()) {
                name = name.debugify();
            }

            return name;
        }

        // region linked controls

        get hasLinkedControls() {
            return !!this.links ? (Array.isArray(this.links) && this.links.length > 0) : false;
        }

        get linkedControlUuids() {
            return this.hasLinkedControls ? this.links : [];
        }

        get linkedControls() {
            return this.linkedControlUuids.map((ctrlUuid) => {
                return ActiveMSComponent.getControlByUUID(ctrlUuid);
            }).filter(ctrl => !!ctrl);
        }

        /**
         * Since 13.1 controls may be read only, i.e. they mustn't be controlled, but can only be viewed.
         * @returns {boolean}
         */
        get isReadOnly() {
            if (!this.hasOwnProperty("restrictions")) {
                return false; // if prop is missing, it's not restricted at all.
            }
            let checkFlag;
            if (CommunicationComponent.getCurrentReachMode() === ReachMode.LOCAL) {
                checkFlag = Restrictions.LOCAL_READ_ONLY;
            } else {
                checkFlag = Restrictions.REMOTE_READ_ONLY;
            }
            return hasBit(this.restrictions, checkFlag);
        }

        // endregion

        /**
         * Returns true if this specific control instance should display the rooms name instead of the controls name too.
         * It also keeps in mind that there may be more than one instance inside a room and therefore showing the rooms
         * name might not be a good idea.
         * @return {*|boolean}
         */
        shouldUseRoomName() {
            return this.controlTypeUsesRoomName() && this.room && // there might not be a room assigned!
                !this.hasSiblingsInRoom;
        }

        /**
         * If true, controls of this type MAY use the rooms name instead of its own. E.g. this is important for light
         * controllers, heating controllers and other room based controls.
         */
        controlTypeUsesRoomName() {
            return false;
        }

        // ----------- Groups -----------

        /**
         * returns the actual room object if a room is assigned
         * @returns {room|null}
         */
        getRoom() {
            return ActiveMSComponent.getStructureManager().getGroupByUUID(this.room, GroupTypes.ROOM);
        }

        /**
         * returns the actual category object if a category is assigned
         * @returns {category|null}
         */
        getCategory() {
            return ActiveMSComponent.getStructureManager().getGroupByUUID(this.cat, GroupTypes.CATEGORY);
        }

        /**
         * Returns the cell type for this control. If this control doesn't have a custom cell
         * ControlCard.BASE will be returned
         * @return {string}
         */
        getCellType() {
            var cellType;

            if (this.isGrouped()) {
                cellType = this.controlType + ControlCard.GROUPED_SUFFIX;
            } else {
                cellType = this.controlType + ControlCard.REGULAR_SUFFIX;
            }

            if (typeof GUI.TableViewV2.Cells[cellType] === "function") {
                return cellType;
            }

            return ControlCard.BASE;
        }

        supportsStates() {
            var hasStates = !!this.states;

            if (!hasStates && this.isGrouped()) {
                this.forEachSubControl(function (ctrl) {
                    hasStates |= ctrl.supportsStates();
                });
            }

            return !!hasStates;
        }

        supportsLocking() {
            return this.details && this.details.jLockable === true;
        }

        unlockControl() {
            return this.sendCommand(Commands.CONTROL.UNLOCK_CTRL);
        }

        getStates() {
            var states = SandboxComponent.getStatesForUUID(this.uuidAction);

            if (states === false) {
                return {};
            }

            return states.states;
        }

         get statesReady() {
            return SandboxComponent.getStatesForUUID(this.uuidAction) !== false;
         }

        getStateText() {
            return this.getStates().stateText;
        }

        getStateTextShort() {
            return this.getStates().stateTextShort;
        }

        //TODO ganselu: adopt comment

        /**
         * returns eg. AlarmControlContent or AlarmCentralControlContent for this control
         * It will use ControlActionCellsScreen as a fallback if the controls content does not exist
         * @returns {string}
         */
        getControlContentViewControllerIdentifier() {
            return ScreenState.ControlContent;
        }

        /**
         * Retuns an array of react-screens that are used by this control
         * @returns {*[]}
         */
        getReactScreens() {
            return [LxControlHistoryScreen];
        }

        /**
         * Retuns an array of legacy-screens that are used by this control
         * @returns {*[]}
         */
        getLegacyScreens() {
            return [];
        }

        /**
         * Used by LxReactControlContent.jsx to identify which specific content to render.
         * @returns {null}
         */
        getReactControlContent() {
            let controlContent
            try {
                controlContent = Control.RequireContext.ControlContent(getRequirePathForModule(`${this.controlType}ControlContent`, ".jsx"));
            } catch (e) {
                try {
                    controlContent = Control.RequireContext.ControlContent(getRequirePathForModule(`${this.controlType}ControlContent`, ".js"))
                    return false;
                } catch (e) {
                    controlContent = LxBaseControlContent;
                }
            }
            if (controlContent && controlContent.__esModule && "default" in controlContent) {
                controlContent = controlContent.default;
            }
            return controlContent;
        }

        /**
         * Used by LxReactControlContent.jsx to pass simple props to a base content
         * @returns {{}}
         */
        getReactControlContentFlags() {
            return {};
        }

        /**
         * Used by navigation to determine if the control is rendered by the generic LxBaseControlContent
         * @returns {null}
         */
        hasReactBaseControlContent() {
            return false
        }

        /**
         * returns eg. AlarmControlContent or AlarmCentralControlContent for this control
         * It will use ControlActionCellsScreen as a fallback if the controls content does not exist
         * @returns {string}
         */
        getControlContentIdentifier() {
            var id = this.controlType + "ControlContent";

            if (!requirePathDefined(id)) {
                id = "ControlActionCellsScreen";
            }

            return id;
        }

        /**
         * override and return a friendly string for the given state (used eg. for autopilot..)
         * @param event
         * @param operator
         * @returns {string}
         */
        getFriendlyValueName(event, operator) {
            return "";
        }

        // ----------- Commands -----------

        /**
         * @see this._sendCommand
         */
        sendCommand() {
            if (this.isReadOnly) {
                return Q.reject(new Error("read only"));
            }
            if (this.isGrouped()) {
                return this.sendCommandGrouped.apply(this, arguments);
            }

            return this._sendCommand.apply(this, arguments);
        }

        /**
         * @param cmd
         * @returns {function} a bound function to send the command from the parameter
         */
        getCommand(cmd) {
            // TODO rename this method! more specific name
            return this.sendCommand.bind(this, cmd);
        }

        /**
         * @returns {string} icon src (either for control group or from the category)
         */
        getIcon() {
            var icon;

            if (this.isGrouped()) {
                icon = this.getCentralIcon();
            }

            if (!icon) {
                if (this.defaultIcon) {
                    icon = this.defaultIcon;
                } else {
                    icon = this.getControlIcon() || this.getCatIcon() || Icon.TabBar.CATEGORY;
                }
            }

            return icon || Icon.DEFAULT;
        }

        get reactIcon() {
            return null;
        }

        get useReactIconInContent() {
            return false;
        }

        /**
         * Returns an icon for this control type, that Icon will be used if no defaultIcon is set by loxone config.
         * @returns {null}
         */
        getControlIcon() {
            return null;
        }

        getCatIcon() {
            var cat = this.getCategory();
            return cat && cat.image;
        }

        /**
         * Excluded so subclasses have the option to modify what parts of a command are being recorded. In general only
         * the first part of the command is recorded, as the rest is not important.
         * @param cmd       the command who's usage is to be recorded.
         * @param source    what part of the UI the cmd was triggered from.
         */
        recordUsage(cmd, source) {
            var recCmd = cmd.split("/")[0]; // remove the rest, only the first part is of interest.

            recCmd = this._cleanCmd(recCmd);
            return VendorHub.Usage.controlCmd(this.controlType, recCmd, source);
        }

        // CONTROLS IN CARDS
        /**
         * Occasionally the basic states required for cards aren't sufficient (e.g. a remote control has needsWaiting,
         * which is providing info on wether or not to show the butttons)
         * @returns {*[]}
         */
        getAdditionalCardStateNames() {
            return [];
        }

        getSwitch() {
            return false;
        }

        getButton0() {
            return false;
        }

        getButton1() {
            return false;
        }

        get button0() {
            return this.getButton0(this.getStates())
        }

        get button1() {
            return this.getButton1(this.getStates())
        }

        get switch() {
            return this.getSwitch(this.getStates())
        }

        // CommandSrc

        /**
         * returns the commands for this object
         * @returns {*|null}
         */
        getCommands(navigation) {
            var states = this.getStates();

            if (!this.supportsStates() || states && Object.keys(states).length > 0) {
                // check if states has been received.. (eg. for URL Start)
                return this.commandSrc.getCommands(this, states, navigation);
            } else {
                return null;
            }
        }

        getStateSections(states, navigation) {
            return this.statesSrc.getStateSections && this.statesSrc.getStateSections(this, states, navigation);
        }

        getMoreSection(states, navigation) {
            return this.statesSrc.getMoreSection && this.statesSrc.getMoreSection(this, states, navigation);
        }

        /**
         * Defines the value that should not be displayed in the Statistic screen
         * @note The text returned in getStatisticNoDataEntryLabelText() will be displayed in the statisticScreen
         *       header label instead of the value itself.
         * @example The Touch & Grill saves -255 as statistic value if no sensor is connected,
         *          the user shouldn't see these values, but instead should see the string "No sensor connected"
         * @returns {null}
         */
        getStatisticNoDataValue() {
            return null;
        }

        /**
         * Text displayed in the statisticScreen header label instead of the value if the selected value is getStatisticNoDataEntryValue
         * @returns {*}
         */
        getStatisticNoDataEntryLabelText() {
            return _('statistics.no-data');
        }

        /**
         * Used to download additional required data for presenting the control content. Will be loaded by the
         * controlContentViewController in viewDidLoad
         * @returns {promise}
         */
        loadDataForContentScreen() {
            if (this._hasSecuredDetails()) {
                return this._loadSecuredDetails();
            }
            return Q.resolve(true); // to be implemented by the baseclass if required
        }

        /**
         * Used to ensure opening a controlContent doesn't show a WaitingPopup. Will call loadDataForContentScreen and
         * returns a promise that resolves immediately once the data has been prepared initially.
         * @returns {null}
         */
        prepareDataForContentScreen() {
            if (!this._contentDataPromise) {
                this._contentDataPromise = this.loadDataForContentScreen();
                this._contentDataPromise.fail(() => {
                    delete this._contentDataPromise;
                });
            }

            return this._contentDataPromise;
        }

        getCommandSrcRequirePath() {
            return `${this.controlType}ControlCommandSrc`;
        }

        getStateSrcRequirePath() {
            return `${this.controlType}ControlStatesSrc`;
        }

        /**
         * Returns an array of object with at least a "name" and "id" property
         * @param detailsKey
         * @return {*[]}
         */
        getAutomaticDesignerDetailObjectsFromDetailsKey(detailsKey) {
            var wantedObj = [];

            if (this.details.hasOwnProperty(detailsKey)) {
                if (this.details[detailsKey] instanceof Array) {
                    wantedObj = this.details[detailsKey];
                } else if (this.details[detailsKey] instanceof Object) {
                    // Last chance of getting our wantedObj
                    try {
                        wantedObj = Object.keys(this.details[detailsKey]).map(function (key, idx) {
                            return {
                                name: this.details[detailsKey][key],
                                id: idx
                            };
                        }.bind(this));
                    } catch (e) {// Nothing to do, this was our last try
                        // As we couldn't get the Object we will show an empty selector screen in the AutomaticDesigner
                    }
                }
            }

            return wantedObj;
        }

        //region Private Methods

        /**
         * applies the JSON Object to the "this" object
         * @param jsonObject
         * @private
         */
        _applyJsonObject(jsonObject) {
            for (var prop in jsonObject) {
                if (jsonObject.hasOwnProperty(prop)) {
                    if (typeof this[prop] === "undefined") {
                        this[prop] = jsonObject[prop];
                    } else {
                        console.warn("Overriding '" + this[prop] + "' (" + prop + ") with '" + jsonObject[prop] + "'");
                    }
                }
            }
        }

        /**
         * Sends the command given using the sandboxComponent
         * @param cmd                   the command to send
         * @param type                  the command type (Commands.Type.QUEUE is default)
         * @param [customActionId]      needs to be specified if something other than the controls uuidAction is to be used
         * @param [dontRecord]          tells the sandbox to avoid adding this cmd to the taskrecorder, it's sent.
         * @param [argumentTexts]       when tasks are recorded, arguments might be needed in order to construct a useful name
         * @param [source]              what screen did send this command? Cell, control content or the room mode?
         * @param [automatic]           Is this comment sent without user interaction?
         * @returns {Promise}
         */
        _sendCommand(cmd, type, customActionId, dontRecord, argumentTexts, source, automatic) {
            var actionId = customActionId || this.uuidAction; // record the usage of this command. Convert to string, as the following methods expect one.

            !dontRecord && this.recordUsage(cmd + "", source);
            return SandboxComponent.sendCommand(this, actionId, cmd, type, this.isSecured, !!automatic, dontRecord, argumentTexts);
        }

        /**
         * Will ensure the command recorded does not compromise the usage analysis.
         * @param command
         * @returns {*}
         * @private
         */
        _cleanCmd(command) {
            //TODO-woessto: move to individual controls 149437584
            var cleaned = command,
                val;

            switch (this.controlType) {
                case ControlType.DIMMER:
                case ControlType.SLIDER:
                    val = parseFloat(command);

                    if (!isNaN(val)) {
                        cleaned = "-value-";
                    }

                    break;

                case ControlType.COLOR_PICKER_V2:
                case ControlType.COLOR_PICKER:
                    cleaned = command.split("(")[0]; // e.g. hsv(100,50,20) --> hsv;

                    break;

                default:
                    break;
            }

            return cleaned;
        }

        _initCommandSrc() {
            let commandSrcPath = getRequirePathForModule(this.getCommandSrcRequirePath()),
                cTor;

            if (commandSrcPath) {
                cTor = Control.RequireContext.CommandSrc(commandSrcPath);
            } else {
                cTor = require("Controls/controlCommandSrc.js");
            }
            this.commandSrc = new cTor(this);
        }

        _initStatesSrc() {
            let statesSrcPath = getRequirePathForModule(this.getStateSrcRequirePath()),
                cTor;

            if (statesSrcPath) {
                cTor = Control.RequireContext.StatesSrc(statesSrcPath).default;
            } else {
                cTor = {
                    getStateSections: () =>  [],
                    getMoreSection: () => {}
                }
            }
            this.statesSrc = cTor;
        }

        _getControlTypeNameForType(type) {
            var lowerType = type.toLowerCase(),
                stringId = "search.controltype." + lowerType,
                controlTypeName = _(stringId);

            if (controlTypeName === stringId || lowerType === controlTypeName) {
                // We didn't find the string try to append the type again, controls with keywords
                // in the Kerberos.xml will have this structure
                stringId += "." + lowerType;
                controlTypeName = _(stringId);
            }

            if (controlTypeName === stringId || lowerType === controlTypeName) {
                if (type !== ControlType.UNIVERSAL && // Ignore the Universal control, its just for internal use!
                    !this._isSubControl) {
                    developerAttention(type + " has no localized controlTypeName -> " + stringId, window.Styles.colors.red);
                }

                return null;
            } else {
                return controlTypeName;
            }
        }

        //endregion

        //region statisticV2
        get supportsStatisticV2() {
            return this.hasOwnProperty("statisticV2") && this.statisticV2 && this.statisticV2.groups.length > 0;
        }

        /**
         * Returns the statistic group object that contains the values for this output
         * @param outputName
         * @returns {*}
         */
        getStatisticGroupForOutput(outputName) {
            var groupObj;
            if (this.supportsStatisticV2) {
                this.statisticV2.groups.some((statGroup => {
                    if (statGroup.dataPoints.some((dataPoint) => {
                        return dataPoint.output === outputName
                    })) {
                        groupObj = statGroup;
                        return true;
                    }
                }));
            }
            return groupObj;
        }

        /**
         * Returns the dataPoint object representing this output
         * @param outputName
         * @returns {*}
         */
        getStatisticDataPointForOutput(outputName) {
            var dataPoint;
            if (this.supportsStatisticV2) {
                this.statisticV2.groups.some((statGroup => {
                    return statGroup.dataPoints.some((currDp) => {
                        if (currDp.output === outputName) {
                            dataPoint = currDp;
                            return true;
                        }
                    });
                }))
            }
            return dataPoint;
        }
        // endregion

        // ----------------------------------------------------------------------------------------------
        // ----------------------------------- SECURED DETAILS ------------------------------------------
        // ----------------------------------------------------------------------------------------------
        _hasSecuredDetails() {
            return this.securedDetails === true;
        }

        _loadSecuredDetails() {
            return ActiveMSComponent.loadSecuredDetailsFor(this.uuidAction, this._isIntercomControl());
        }

        /**
         * checks if the current control is an Intercom
         * @returns {boolean}
         * @private
         */
        _isIntercomControl() {
            return this.controlType === ControlType.INTERCOM || this.controlType === ControlType.INTERCOM_GEN_2;
        }

    };
});
