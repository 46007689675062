import globalStyles from "GlobalStyles";
import {LxReactText, LxReactButton} from "LxComponents";
import {useCallback, useMemo} from "react";
import {View} from "react-native";
import {useConnectionReady} from "../Components";
import LxReactWaitingView from "../LxReactWaitingView";

export default function LxReactDeviceSearchStoppedView({searchType, isRestarting = false, wasKicked = true}) {

    const connectionReady = useConnectionReady(true);
    const handleRestart = useCallback(() => {
        ActiveMSComponent.restartDeviceSearch();
    }, []);

    const message = useMemo(() => {
        if (wasKicked) {
            let message;
            switch (searchType) {
                case DeviceManagement.TYPE.AIR:
                    message = _("air.device-learning.stopped.message");
                    break;
                case DeviceManagement.TYPE.TREE:
                    message = _("tree.device-learning.stopped.message");
                    break;
                default:
                    message = "Search was started on another device. Device search can only be run on one device.".debugify();
                    break;
            }
            return <LxReactText key="message" style={Styles.messageStyle}>{message}</LxReactText>
        }
        return null;
    }, [wasKicked, searchType]);

    const title = useMemo(() => {
        let titleText;
        if (searchType === DeviceManagement.TYPE.AIR) {
            titleText = _("air.device-learning.stopped.title");
        } else if (searchType === DeviceManagement.TYPE.TREE) {
            titleText = _("tree.device-learning.stopped.title");
        } else {
            titleText = "Search stopped".debugify();
        }
        return <LxReactText key="title" style={Styles.titleStyle}>{titleText}</LxReactText>
    }, [searchType, isRestarting])

    if (isRestarting) {
        return <LxReactWaitingView title={_("device-learning.restarting-search")} />
    } else {
        return <View style={Styles.containerStyle}>
            {title}
            {message}
            {<LxReactButton
                        style={Styles.restartButton}
                        onPress={handleRestart}
                        disabled={!connectionReady}
                        text={_("device-learning.search-again")}
                        textStyle={Styles.restartButtonText}
                    />}
        </View>
    }
}

const Styles = {
    containerStyle: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: 1
    },
    titleStyle: {
        ...globalStyles.textStyles.title1.bold,
        color: globalStyles.colors.text.primary,
        textAlign: "center",
        marginBottom: globalStyles.spacings.gaps.small
    },
    messageStyle: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.primary,
        textAlign: "center",
        marginBottom: globalStyles.spacings.gaps.small
    },
    restartButton: {
    },
    restartButtonText: {

    }
}