'use strict';

define(["LightControlSceneList", "LightV2ControlEnums"], function (LightControlSceneList, LightV2ControlEnums) {
    return class MoodList extends LightControlSceneList {
        constructor(control) {
            super(...arguments);
            this.tableViewContent = [];
            this._initialReload = true;
        }

        viewDidLoad() {
            var promise = super.viewDidLoad(...arguments);
            this.isLightControl = true;
            return promise;
        }

        update(states) {
            var tableViewDataSourceModified = false,
                tableViewDelegateModified = false;

            this._prepareTableViewContent(states);

            this.activeMoods = states.activeMoods;

            if (this._initialReload || this._shouldReloadTable(states)) {
                this._prevMoodList = cloneObject(states.moodList);
                this._prevFavMoodList = cloneObject(states.favoriteMoods);
                this._initialReload = false;
                tableViewDataSourceModified = this.tableViewDataSource.update(this.tableViewContent);
                tableViewDelegateModified = this.tableViewDelegate.update(this.tableViewContent);
                (tableViewDelegateModified || tableViewDataSourceModified) && this.tableView.reload().then(function () {
                    this.handleTableReloaded();
                }.bind(this));
            }
        }

        titleForHeaderInSection(section) {
            return this.tableViewContent[section].title;
        }

        sectionHeaderElement(section) {
            return this.tableViewContent[section].headerElement;
        }

        styleForTableView() {
            return TableViewStyle.SECTIONED;
        }

        numberOfSections() {
            return this.tableViewContent.length;
        }

        numberOfRowsInSection(section) {
            return this.tableViewContent[section].rows.length;
        }

        cellTypeForCellAtIndex(section, row) {
            return this.tableViewContent[section].rows[row].type;
        }

        contentForCell(cell, section, row) {
            return this.tableViewContent[section].rows[row].content;
        }

        // TableViewDelegate methods
        didSelectCell(cell, section, row, tableView) {
            cell.content.action && cell.content.action.call(this);
        }

        didCheckCell(cell, section, row, tableView, selected) {
            var id = cell.content.id; // Minimize traffic by only activating a mood that is not active right now

            if (this.activeMoods.indexOf(id) === -1 || this.activeMoods.length >= 2) {
                this.sendCommand(Commands.format(Commands.LIGHTCONTROL_V2.MOOD.CHANGE_TOO, cell.content.id));
            }
        }

        buttonTapped(section, row, tableView, cell) {
            this._addRemoveMoodCell(cell);
        }

        buttonReleased(section, row, tableView, cell) {
            this._addRemoveMoodCell(cell);
        }

        /**
         * Called whenever the table view has been reloaded. important for subclasses where the table view might
         * be editable.
         */
        handleTableReloaded() {// nothing to do here.
        }

        // Private
        _addRemoveMoodCell(cell) {
            if (cell.content.isMulti) {
                this.sendCommand(Commands.format(Commands.LIGHTCONTROL_V2.MOOD.REMOVE, cell.content.id));
            } else {
                this.sendCommand(Commands.format(Commands.LIGHTCONTROL_V2.MOOD.ADD, cell.content.id));
            }
        }

        _prepareTableViewContent(states) {
            this.tableViewContent = [];

            if (states.moodList.length > 0) {
                var moodsSection = {
                    rows: []
                };
                states.moodList.forEach(function (mood) {
                    moodsSection.rows.push(this._createMoodCellObj(mood, states));
                }.bind(this));
                this.tableViewContent.push(moodsSection);
            }

            this.tableViewContent.push({
                title: null,
                rows: [{
                    type: GUI.TableViewV2.CellType.GENERAL,
                    content: {
                        title: _("controls.lightV2controller.moods.edit"),
                        leftIconSrc: Icon.Light.SETTINGS,
                        clickable: true,
                        leftIconColor: Color.TEXT_INACTIVE_B,
                        action: function () {
                            this.ViewController.showState(LightV2ControlEnums.ScreenState.EDIT_MOODS, null, {
                                control: this.control
                            });
                        }
                    }
                }]
            });
        }

        _createMoodCellObj(mood, states) {
            return {
                type: GUI.TableViewV2.CellType.Special.MOOD_CELLV2,
                content: {
                    title: mood.name,
                    id: mood.id,
                    showButton: mood.isAccumulative,
                    buttonSrc: Icon.TRANSLUCENT_ADD,
                    selectable: true,
                    iconSrc: this.control.getMoodIcon(mood),
                    controlUuid: this.control.uuidAction
                }
            };
        }

        _shouldReloadTable(states) {
            var newMoodList, prevMoodList;
            newMoodList = states.moodList.map(function (mood) {
                return {
                    mood: mood.name,
                    id: mood.id
                };
            });
            prevMoodList = this._prevMoodList.map(function (mood) {
                return {
                    mood: mood.name,
                    id: mood.id
                };
            });
            return JSON.stringify(newMoodList) !== JSON.stringify(prevMoodList) || JSON.stringify(states.favoriteMoods) !== JSON.stringify(this._prevFavMoodList);
        }

    };
});
