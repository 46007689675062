//let { Component } = window.React;
import React from "react";
import {ReactWrapper} from "./Components";
import * as ReactDOM from "react-dom";

class LxReactComp extends React.Component {

    static ViewInstanceCount = 0
    static ViewCreationCount = 0

    // region Private
    viewController;
    // endregion

    isReactComp = true

    get name() {
        return this.constructor.name;
    }

    set name(newName) {
        console.warn(this.name, "Setting the name from outside of the class is bad behaviour and deprecated");
    }

    get ViewController() {
        return this.viewController;
    }

    set ViewController(vc) {
        this.viewController = vc;
    }

    // A React comp is always shown, hiding/showing should be handled via the render function
    get isHidden() {
        return false;
    }

    get viewId() {
        return `${this.name} - ${this.constructor.ViewInstanceCount}`;
    }

    constructor(props) {
        super(props);
        this.constructor.ViewCreationCount++;
        this.constructor.ViewInstanceCount++;
        Debug.GUI.Views && console.log(this.viewId, "ctor");
        Debug.Instances && console.info("ViewInstanceCount ++ " + this.constructor.ViewInstanceCount);
    }

    componentDidMount() {
        this.isCompMounted = true;
    }

    componentWillUnmount() {
        this.isCompMounted = false
    }

    // Custom functions
    getElement() {
        return $(ReactDOM.findDOMNode(this));
    }


    // region Legacy Code
    // Current LifeCycle methods

    /**
     * gets called ONCE when the view will be shown soon
     * view is NOT in DOM at this point
     */
    viewDidLoad() {
        return Q.resolve();
    }

    /**
     * gets called when the view is about to appear (before transition)
     * view is in DOM at this point
     */
    viewWillAppear() {
        return Q.resolve();
    }

    /**
     * gets called after the transition
     * view is in DOM at this point
     */
    viewDidAppear() {
        return Q.resolve();
    }
    viewWillDisappear() {
        //this.emit("viewWillDisappear");
        return Q.resolve();
    }
    viewDidDisappear(viewRemainsVisible) {
        //this.emit("viewDidDisappear");
        return Q.resolve();
    }

    setViewController(vc) {
        this.viewController = vc
    }

    destroy() {
    }

    //endregion
}

export default LxReactComp;
