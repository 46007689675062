import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Weather02n(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            <Path
                d="M20.04 5a8.853 8.853 0 00-2.111.244A8.02 8.02 0 1028 14.015a5.177 5.177 0 01-1.95.325 5.997 5.997 0 01-6.01-6.01 5.488 5.488 0 01.975-3.249A3.954 3.954 0 0020.041 5z"
                fill="#9cb2bc"
            />
            <Path
                d="M2 22.5a4.502 4.502 0 012.252-3.898 6.75 6.75 0 0112.49-3.403 4.882 4.882 0 015.947 2.96A4.5 4.5 0 0121.5 27H6.5A4.5 4.5 0 012 22.5z"
                fill="#d5e2e9"
                fillRule="evenodd"
            />
            <Path
                fill="#f4cd27"
                fillRule="evenodd"
                d="M7.249 9.261L8.01 9.527 7.249 9.794 6.845 10.926 6.44 9.794 5.679 9.527 6.44 9.261 6.845 8.129 7.249 9.261z"
            />
            <Path
                fill="#f4cd27"
                fillRule="evenodd"
                d="M3.67 10.46L4.236 11.97 5.302 12.325 4.236 12.68 3.67 14.19 3.104 12.68 2.038 12.325 3.104 11.97 3.67 10.46z"
            />
            <Path fill="none" d="M0 0H32V32H0z" />
        </Svg>
    )
}

export default Weather02n
