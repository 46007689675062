import {useConnectionReady} from "LxComponents";
import {useEffect, useState} from "react"


/**
 * Returns the names for customField1-5
 * @returns {{customField1: "Height", customField2: "Benutzerdefiniertes Feld 2", customField3: "3", customField4: "Vier", customField5: "Number"}}
 */
export default function useCustomFieldCaptions() {
    const [captionMap, setCaptionMap] = useState({});
    const connReady = useConnectionReady();

    useEffect(() => {
        if (connReady) {
            ActiveMSComponent.getUserCustomFieldCaptions().then((res) => {
                setCaptionMap({...res});
            });
        }
    }, [connReady]);

    return captionMap;
}