import { useLiveState, LxReactImageView } from "LxComponents";
import {useMemo} from "react";
import {View} from "react-native";


export default function TempDisplay({tempState, controlUuid, containerStyle, forCell = false}) {
    const liveState = useLiveState(controlUuid, [tempState]);

    const styles = {
        rootCntr: {},
        tempImgCntrStyle: {
            width: "100%",
            maxWidth: 66
        },
        tempImgStyle: {
            width: "initial"
        }
    }
    const cardStyles = {
        rootCntr: {
            ...styles.rootCntr,
            marginTop: 31,
            marginLeft: 24,
            height: 53,
            flex: 1,
        },
        tempImgCntrStyle: {
            ...styles.tempImgCntrStyle
        },
        tempImgStyle: {
            ...styles.tempImgStyle,
            width: 65
        }
    }
    const cellStyles = {
        rootCntr: {
            ...styles.rootCntr,
            paddingLeft: 1,
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            flexDirection:"column",
            height: "auto",
            maxHeight: 48
        },
        tempImgCntrStyle: {
            ...styles.tempImgCntrStyle
        },
        tempImgStyle: {
            ...styles.tempImgStyle
        }
    }

    const {cntrStyle, tempImgCntrStyle, tempImgStyle, textSizes} = useMemo(() => {
        let style = {
            cntrStyle: forCell ? cellStyles.rootCntr : cardStyles.rootCntr,
            textSizes: forCell ? { decimal: 30, fraction: 20} : {decimal: 40, fraction: 24},
            tempImgCntrStyle: forCell ? cellStyles.tempImgCntrStyle : cardStyles.tempImgCntrStyle,
            tempImgStyle: forCell ? cellStyles.tempImgStyle : cardStyles.tempImgStyle
        }

        // merge input style for container
        style.cntrStyle = {...containerStyle, ...style.cntrStyle};

        return style;
    }, [forCell]);

    const image = useMemo(() => {
        return <LxReactImageView source={generateValueSvg(liveState.states[tempState], textSizes.decimal, textSizes.fraction)}
                                 containerStyle={tempImgCntrStyle}
                                 imageStyle={tempImgStyle} />
    }, [liveState.states[tempState], liveState.allStatesReceived]);

    return <View style={cntrStyle}>
        {image}
    </View>
}
