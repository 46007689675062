import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Weather06(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            <Path
                d="M23.5 15a1.002 1.002 0 01-1.01 1h-4.98a1 1 0 110-2h4.98a1 1 0 011.01 1zM14.5 15a1.002 1.002 0 01-1.01 1H8.51a1 1 0 110-2h4.98a1 1 0 011.01 1zM23.5 7a1.002 1.002 0 01-1.01 1h-4.98a1 1 0 110-2h4.98a1 1 0 011.01 1zM14.5 7a1.002 1.002 0 01-1.01 1H8.51a1 1 0 110-2h4.98a1 1 0 011.01 1zM28.5 11a1.002 1.002 0 01-1.01 1h-4.98a1 1 0 110-2h4.98a1 1 0 011.01 1zM19.5 11a1.002 1.002 0 01-1.01 1h-4.98a1 1 0 110-2h4.98a1 1 0 011.01 1zM10.5 11a1.002 1.002 0 01-1.01 1H4.51a1 1 0 110-2h4.98a1 1 0 011.01 1zM3.5 26a1.002 1.002 0 011.01-1h22.98a1 1 0 110 2H4.51a1 1 0 01-1.01-1z"
                fill="#d5e2e9"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default Weather06
