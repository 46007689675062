import {useCallback, useState} from "react";
import {useCCEvent} from "../Components";


export default function useIsPairedApp() {

    const [isPaired, setIsPaired] = useState(PairedAppComponent.isPaired());

    const handleCCEvent = useCallback((event, arg) => {
        if (event === CCEvent.UnpairedApp) {
            setIsPaired(false);
        } else if (event === CCEvent.PairedAppInfoReady) {
            setIsPaired(PairedAppComponent.isPaired());
        }
    }, []);

    useCCEvent([CCEvent.UnpairedApp, CCEvent.PairedAppInfoReady], handleCCEvent, [])

    return isPaired;
}