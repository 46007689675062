import {createStateCell} from "../../UtilityComp/utilities/helper";

export default class SaunaControlStatesSrc {
    static getStateSections(control, states) {
        let sections = [];

        let section = {},
            rows = [],
            tempName = states.hasBenchTemp ? _("controls.sauna.bench-temp") : _("controls.sauna.temperature.current");

        if (control.details.hasDoorSensor) {
            rows.push(createStateCell(_("controls.sauna.door.title"), states.doorClosed ? _("closed") : _("opened")));
        } // if drying or when the fan is active - the temp & humidity aren't shown in the info view, show them here.


        if (states.fanActive || states.drying || !states.isActive) {
            rows.push(createStateCell(tempName, states.tempActual + "°"));

            if (control.details.hasVaporizer) {
                rows.push(createStateCell(_("controls.sauna.humidity.current"), states.humidityActual + "%"));
            }
        }

        if (states.hasSecondaryTemp) {
            rows.push(createStateCell(_("controls.sauna.room-temp.name"), states.secondaryTempText + "°"));
        }

        section.rows = rows;

        sections.push(section)

        return sections;
    }
}