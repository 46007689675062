'use strict';

define(["LxComponents"], function (
    {
        LxReactText,
        LxReactImage
    }
) {
    /**
     * BatteryDeviceScreen displays a single device, image, status, battery level, help etc.
     */
    return class BatteryDeviceScreen extends GUI.TableViewScreenV2 {

        get useNewTableView() {
            return true;
        }

        constructor(details) {
            super($("<div/>"));
            this.device = details.device;
        }

        /**
         * gets called when this screen is already opened and the user taps another notification
         * @param details
         */
        updateView(details) {
            // hand over the details, the BatteryMonitorScreen will then show the correct device (updateView gets called)
            this.ViewController.navigateBack(false, details);
        }

        getURL() {
            return "BatteryMonitor/" + this.device.uuid;
        }

        getAnimation() {
            return AnimationType.PUSH_OVERLAP_LEFT;
        }

        titleBarText() {
            return this.device.name.decodeHtmlEntities(); // this.device.name was sanitized thus html exist in the string
        }

        // Private methods
        reloadTable() {
            var sectionDevice = {
                    rows: []
                },
                tableContent = [sectionDevice];

            sectionDevice.rows.push({
                content: {
                    topContent: {
                        comp: LxReactImage,
                        props: {
                            resizeMode: "contain",
                            source: {
                                uri: this.device.image,
                            },
                            style: {
                                width: "60%",
                                height: 200,
                                marginRight: "auto",
                                marginLeft: "auto"
                            }
                        }
                    },
                    topStyle: {
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: Styles.spacings.gaps.veryBig
                    }
                }
            })

            sectionDevice.rows.push({
                content: {
                    title: _('battery-monitor.device.type'),
                    disclosureText: this.device.friendlyType
                }
            });
            sectionDevice.rows.push({
                content: {
                    title: _('battery-monitor.device.assembly-place'),
                    disclosureText: this.device.roomName ? this.device.roomName : _('unknown-room'),
                    disclosureText1: this.device.roomName && this.device.roomName === this.device.place ? null : this.device.place // if the room name is equal to the place, only show it once!

                }
            });

            if (this.device.serialNr) {
                sectionDevice.rows.push({
                    content: {
                        title: _('miniserver.serial-number'),
                        disclosureText: this.device.serialNr
                    }
                });
            }

            var isOnline = this._isDeviceOnline(),
                bottomContent = null;

            if (!isOnline) {
                bottomContent = {
                    comp: LxReactText,
                    props: {
                        children: _('battery-monitor.reasons') + ": " + _('battery-monitor.battery.empty') + "; " +_('battery-monitor.device.connection-problem'),
                        style: {
                            ...Styles.textStyles.body.default,
                            marginTop: Styles.spacings.gaps.small,
                            color: Styles.colors.text.secondary
                        }
                    }
                }
            }

            sectionDevice.rows.push({
                content: {
                    title: _('status'),
                    disclosureText: isOnline ? _('online') : _('offline'),
                    disclosureColor: isOnline ? window.Styles.colors.green : window.Styles.colors.red,
                    clickable: !isOnline,
                    bottomContent: bottomContent
                }
            });

            if (!isOnline) {
                sectionDevice.rows.push({
                    content: {
                        title: _('online.last'),
                        disclosureText: this._getTimeSinceOffline()
                    }
                });
            }

            if (this.device.type === ControlType.STEAK) {
                sectionDevice.rows.push({
                    content: {
                        title: !isOnline ? _('battery-monitor.battery.state.last') : _('battery-monitor.battery.state'),
                        disclosureText: this.device.batteryLevel + "%",
                        disclosureColor: this._getDisclosureColor(isOnline)
                    }
                });
            } else {
                sectionDevice.rows.push({
                    content: {
                        title: !isOnline ? _('battery-monitor.battery.state.last') : _('battery-monitor.battery.state'),
                        subtitle: this.device.batteryLow ? !isOnline ? _('battery-monitor.battery.renew') : _('battery-monitor.battery.renew-soon') : null,
                        disclosureText: this.device.batteryLevel + "%",
                        disclosureColor: this._getDisclosureColor(isOnline)
                    }
                });
            } // Not all devices have a battery link!


            if (this.device.batteryLink) {
                sectionDevice.rows.push({
                    action: function () {
                        NavigationComp.openWebsite(this.device.batteryLink);
                    }.bind(this),
                    content: {
                        title: _('battery-monitor.battery.help'),
                        titleStyle: {
                            color: Styles.colors.brand
                        },
                        clickable: true
                    }
                });
            }

            this.tableContent = tableContent;
            return super.reloadTable(...arguments);
        }

        // Helper
        _isDeviceOnline() {
            return this.device.deviceState & 1;
        }

        //formats string for last online time
        _getTimeSinceOffline() {
            return LxDate.formatPastDateDynamic(this.device.lastOnline);
        }

        //sets the color of the batterylevel in the batterDeviceScreen
        _getDisclosureColor(isOnline) {
            var disclosureColor;

            if (this.device.batteryLevel === 0) {
                disclosureColor = window.Styles.colors.red;
            } else if (this.device.batteryLow) {
                disclosureColor = window.Styles.colors.orange;
            } else if (isOnline) {
                disclosureColor = window.Styles.colors.green;
            }

            return disclosureColor;
        }

    };
});
