'use strict';
/*
 type (text)
 leftIconSrc
 title
 value
 placeholder
 autoFocus (boolean)
 selectText (boolean)
 validationRegex        // used to ensure no unsupported characters are entered
 filterRegex            // used to replace unsupported characters from the value provided
 [numberStep] optional if type is number
 [numberMin] optional if type is number
 [numberMax] optional if type is number
 [showToggleButton] optional boolean if type is password

 // InputCell methods
 focus()    // focuses the input
 selectText()    // focuses the text

 // InputCell delegate
 textChanged(section, row, tableView, value, valid, valueDidChange) // TODO adopt to our global syntax! (+cell, +tableView!), name "inputCellValueChanged"
 submitText(section, row, tableView, value, valid) // TODO adopt to our global syntax! (+cell, +tableView!), name "inputCellValueChanged"
 didFocus(section, row, tableView)
 didBlur(section, row, tableView)
 // TODO add onSubmit function (onkeyup -> enter?)
 */

window.GUI = function (GUI) {
    class InputCell extends GUI.TableViewV2.Cells.BaseCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.element.addClass("input-cell");
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                this.input = new GUI.LxInput(this.content, this);
                return this.appendSubview(this.input, this.contentPlaceholder).then(function () {
                    GUI.animationHandler.addCssClass(this.input.getElement(), "content__input");
                    GUI.animationHandler.toggleCssClass(this.element, "multiline", !!this.content[GUI.LxInputEnum.MULTI_LINE]);

                    if (this.content.type === GUI.LxInputEnum.Type.PASSWORD && this.content.showToggleButton) {
                        this._addToggleButton();
                    }

                    this.input.onFocus = this.onFocus.bind(this);
                    this.input.onBlur = this.onBlur.bind(this);
                }.bind(this));
            }.bind(this));
        }

        focus() {
            this.input.focus.apply(this.input, arguments);
        }

        /**
         * focuses/selects the text of the input
         */
        selectText() {
            this.input.selectText.apply(this.input, arguments);
        }

        textChanged(value, valid, valueDidChange) {
            return this.delegate.textChanged && this.delegate.textChanged(this.sectionIdx, this.rowIdx, this.tableView, value, valid, valueDidChange);
        }

        submitText(value, valid, valueDidChange) {
            this.delegate.submitText && this.delegate.submitText(this.sectionIdx, this.rowIdx, this.tableView, value, valid, valueDidChange);
        }

        onFocus() {
            this.delegate.onFocus && this.delegate.onFocus(this.sectionIdx, this.rowIdx, this.tableView);
        }

        onBlur() {
            this.delegate.onBlur && this.delegate.onBlur(this.sectionIdx, this.rowIdx, this.tableView);
        }

        /**
         * displays a "toggle" button on the right side
         * @private
         */
        _addToggleButton() {
            this.toggleButton = new GUI.LxRippleButton($('<div class="content__toggle-btn"><div class="toggle-btn__text">' + _('show') + '</div></div>'));
            this.appendSubview(this.toggleButton, this.contentPlaceholder);
            this.toggleButton.on(GUI.LxRippleButton.CLICK_EVENT, this._handleToggleTap.bind(this));
            this._isVisible = false;
        }

        /**
         * toggles the type of the input field (text/password)
         * @private
         */
        _handleToggleTap() {
            this._isVisible = !this._isVisible;
            var toggleTxtElem = this.toggleButton.getElement().find(".toggle-btn__text");
            GUI.animationHandler.schedule(function () {
                toggleTxtElem.text(this._isVisible ? _('hide') : _('show'));
                this.input.setType(this._isVisible ? GUI.LxInputEnum.Type.TEXT : GUI.LxInputEnum.Type.PASSWORD);
            }.bind(this));
        }

    }

    GUI.TableViewV2.Cells.InputCell = InputCell;
    GUI.TableViewV2.CellType.INPUT = "InputCell";
    return GUI;
}(window.GUI || {});
