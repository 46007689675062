import {useEffect, useRef} from "react";

function useCCEvent(events, callback, deps = []) {

    // #region connection handling
    const eventRegs = useRef(null);
    useEffect(() => {
        if (!eventRegs.current) {
            eventRegs.current = events.map((ev) => {
                return CompChannel.on(ev, (eventObj, arg) => {
                    callback(ev, arg);
                });
            })
        }
        return () => {
            eventRegs.current && Array.isArray(eventRegs.current)
                && eventRegs.current.forEach((reg) => CompChannel.off(reg));
            eventRegs.current = null;
        }
    }, [JSON.stringify(events), callback, ...deps])
    // endregion

    return true;
}

export default useCCEvent;
