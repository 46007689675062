import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Wind(props) {
    return (
        <Svg isreact="true"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 32 26.6"
            xmlSpace="preserve"
            enableBackground="new 0 0 32 26.6"
            {...props}
        >
            <Path
                d="M25.9 13.6H0v-2h26c2.3 0 4.1-1.8 4.1-4.1S28.3 3.4 26 3.4s-4.1 1.8-4.1 4.1v2h-2v-2c0-3.4 2.7-6.1 6.1-6.1 3.4 0 6.1 2.7 6.1 6.1s-2.8 6.1-6.2 6.1zm-13-4.1H0v-2h12.9c1.5 0 2.7-1.2 2.7-2.7 0-1.5-1.2-2.7-2.7-2.7-1.5 0-2.7 1.2-2.7 2.7v.7h-2v-.7C8.2 2.2 10.3 0 13 0c2.6 0 4.8 2.1 4.8 4.8-.1 2.6-2.2 4.7-4.9 4.7zm15.7 11.6c0 3-2.4 5.4-5.4 5.4-3 0-5.4-2.4-5.4-5.4v-1.4h2v1.4c0 1.9 1.5 3.4 3.4 3.4s3.4-1.5 3.4-3.4-1.5-3.4-3.4-3.4H0v-2h23.1c3.1 0 5.5 2.4 5.5 5.4z"
                fillRule="evenodd"
                clipRule="evenodd"
            />
        </Svg>
    )
}

export default Wind
