"use strict";

define(["CarChargerControlEnums"], function (CarChargerControlEnums) {
    return class CarChargerControlContentSettings extends Controls.ControlContent {
        constructor(details) {
            super(details);
            this.isInitalReload = true;
            this.limitAllowed = true;

            if (Feature.CAR_CHARGER_LIMIT_ALLOWED) {
                this.limitAllowed = this.control.details.limitAllowed;
            }

            this.chargerType = this.control.details.chargerType;
        }

        viewDidLoad() {
            var baseCall = super.viewDidLoad(...arguments);
            this.tableViewDataSource = tableViewDataSource(null, null, this);
            this.tableViewDelegate = tableViewDelegate(null, this);
            this.tableView = new GUI.TableViewV2(this.tableViewDataSource, this.tableViewDelegate);
            this.appendSubview(this.tableView);
            return baseCall;
        }

        titleBarText() {
            return _('settings');
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.BACK,
                style: "transparent"
            };
        }

        getURL() {
            return "Settings";
        }

        getAnimation() {
            return AnimationType.HD_OVERLAY;
        }

        destroy() {
            this.states = null;
            super.destroy();
        }

        receivedStates(states) {
            this.states = states; // initial table view reload
            // also handles need reloads

            if (this.isInitalReload || this.states.limitMode !== this.prevLimitMode) {
                this.isInitalReload = false;
                this.prevLimitMode = this.states.limitMode;

                this._reloadTable();
            }

            if (this.states.currentLimit !== this.lastSetSliderValue) {
                if (this.tableView.cellForSectionAndRow(1, 0)) {
                    this.tableView.cellForSectionAndRow(1, 0).setPosition(this.states.currentLimit);
                }
            }
        }

        sendCommand() {
            if (this.states.status === CarChargerControlEnums.Status.ONLINE) {
                return super.sendCommand(...arguments);
            } else {
                // show a notification
                GUI.Notification.createGeneralNotification({
                    title: _('unable-to-send-command'),
                    subtitle: _('controls.car-charger.offline'),
                    removeAfter: 5,
                    clickable: true
                }, NotificationType.WARNING);
            }
        }

        //private functions
        _reloadTable() {
            this.tableContent = [];
            this.tableContent.push(this._getModeSelectorSection());

            if (this.states && this.states.limitMode === CarChargerControlEnums.LimitMode.MANUAL) {
                this.tableContent.push(this._getChargeLimiterSection());
            }

            this.tableViewDataSource.update(this.tableContent);
            this.tableViewDelegate.update(this.tableContent);
            this.tableView.reload();
        }

        _getModeSelectorSection() {
            var section = {
                rows: []
            };

            if (this.limitAllowed) {
                section.rows.push(this._getSelectorCell(_('controls.car-charger.charging-limit.auto'), CarChargerControlEnums.LimitMode.AUTO));
            }

            section.rows.push(this._getSelectorCell(_('controls.car-charger.charging-limit.manual'), CarChargerControlEnums.LimitMode.MANUAL));
            section.rows.push(this._getSelectorCell(_('controls.car-charger.charging-limit.off'), CarChargerControlEnums.LimitMode.OFF));
            return section;
        }

        _getChargeLimiterSection() {
            var section = {
                    rows: []
                },
                cell;
            cell = {
                type: GUI.TableViewV2.CellType.SLIDER,
                content: {
                    title: _('controls.car-charger.charging-limit'),
                    color: window.Styles.colors.blue,
                    minValue: this.states.minLimit,
                    minIconSrc: "resources/Images/Controls/CarCharger/icon-bolt.svg",
                    maxValue: this.states.maxLimit,
                    maxIconSrc: "resources/Images/Controls/CarCharger/icon-bolt.svg",
                    step: 0.1,
                    value: this.states.currentLimit,
                    format: "%.3f kW",
                    antiGhostTimer: true
                },
                sliderDragEnded: function (cell, section, row, tableView, value) {
                    this._sendValue(value);
                }.bind(this),
                sliderClicked: function (cell, section, row, tableView, value) {
                    this._sendValue(value);
                }.bind(this)
            };
            section.rows.push(cell);

            if (this.chargerType === CarChargerControlEnums.Type.BMW) {
                section.footerTitle = _('controls.car-charger.charging-limit.warning', {
                    minutes: 15
                });
            }

            return section;
        }

        _getSelectorCell(title, mode) {
            return {
                type: GUI.TableViewV2.CellType.CHECKABLE,
                content: {
                    activeIconBGColor: window.Styles.colors.blue,
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED,
                    title: title,
                    selected: this.states.limitMode === mode
                },
                didCheckCell: function () {
                    if (mode != null) {
                        this._sendCommand(Commands.format(Commands.CAR_CHARGER.SET_LIMIT_MODE, mode));
                    }
                }.bind(this)
            };
        }

        _sendValue(val) {
            this.lastSetSliderValue = val;

            this._sendCommand(Commands.format(Commands.CAR_CHARGER.SET_MAX_LIMIT, val));
        }

        _sendCommand(cmd) {
            // use a short timeout, that the statusupdates don't reload the table (rippleEffect)
            this._waitForCommand = true;
            clearTimeout(this._waitForCmdTimeout);
            this._waitForCmdTimeout = setTimeout(function () {
                // use 2 timeouts, fast commands wouldn't work
                this._waitForCommand = false;
            }.bind(this), 300);
            setTimeout(function () {
                this.sendCommand(cmd);
            }.bind(this), 300);
        }

    };
});
