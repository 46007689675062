export default class PowerUnitControlStatesSrc {
    static getStateSections(control, states) {
        let stateSections = [];

        stateSections.push(this.getMainSection(control, states))

        return stateSections;
    }

    static getMainSection(control, states) {
        let rows = [],
            totalStateObj = states.totalPowerState;
        const meterRefs = control.details.meterRefs

        rows.pushObject(this._createCellFromObject({
            ...totalStateObj,
            actionFn: this._showMeter.bind(this, ActiveMSComponent.getControlByUUID(meterRefs[0]))
        }));
        states.outputStates && states.outputStates.forEach(outputState => {
            rows.pushObject(this._createCellFromObject({
                ...outputState,
                actionFn: this._showMeter.bind(this, ActiveMSComponent.getControlByUUID(meterRefs[outputState.id]))
            }));
        });
        rows.pushObject(this._createCellFromObject(states.supplyState));
        rows.pushObject(this._createCellFromObject(states.remainingState));
        rows.pushObject(this._createCellFromObject(states.batteryState));
        rows.pushObject(this._createCellFromObject({
            title: _("controls.power-unit.history"),
            actionFn: this._showTracker.bind(this, control)
        }));
        return {
            rows: rows
        };
    }

    static _createCellFromObject(stateObj) {
        if (!stateObj) {
            return null;
        }

        return {
            content: {
                title: stateObj.title,
                disclosureIcon: !!stateObj.actionFn,
                clickable: !!stateObj.actionFn,
                disclosureText: stateObj.stateText,
                disclosureColor: stateObj.stateColor
            },
            action: stateObj.actionFn
        };
    }

    static _showTracker(control) {
        NavigationComp.showControlContent(control.getTracker(), null, HD_APP ? AnimationType.HD_OVERLAY : AnimationType.PUSH_OVERLAP_LEFT);
    }

    static _showMeter(control) {
        NavigationComp.showControlContent(control, null, HD_APP ? AnimationType.HD_OVERLAY : AnimationType.PUSH_OVERLAP_LEFT);
    }
}