'use strict';

define([], function () {
    return class JalousieControlContentConfigHelpScreen extends GUI.StaticScreenBase {
        //region Static
        static Template = function () {
            var getTemplate = function getView() {
                return $('<div class="jalousie-control-content-config-help-screen__container-view">' + '   <h2>' + _('controls.jalousie.config.help.preparation') + '</h2>' + '   <img src="resources/Images/Controls/Jalousie/jalousie/config/preparations.jpg"> ' + '   <p>' + _('controls.jalousie.config.help.before-start') + '</p>' + '   <h2>' + _('controls.jalousie.config.help.step1') + '</h2>' + '   <img src="resources/Images/Controls/Jalousie/jalousie/config/step1.jpg"> ' + '   <p>' + _('controls.jalousie.config.help.step1.text') + '</p>' + '   <h2>' + _('controls.jalousie.config.help.step2') + '</h2>' + '   <img src="resources/Images/Controls/Jalousie/jalousie/config/step2.jpg"> ' + '   <p>' + _('controls.jalousie.config.help.step2.text') + '</p>' + '   </div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor() {
            super($('<div class="jalousie-control-content-config-help-screen"/>'));
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.element.append(JalousieControlContentConfigHelpScreen.Template.getTemplate());
            this.element.addClass("jalousie-control-content-config-help-screen");
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        getURL() {
            return "Help";
        }

        titleBarText() {
            return _('practical-tip');
        }

        titleBarAction() {
            this.remove();
        }

        titleBarConfig() {
            return {
                style: "transparent"
            };
        }

    };
});
