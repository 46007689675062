'use strict';

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.CHECKABLE_BUTTON_MEDIA = "CheckableButtonMediaCell";

    class CheckableButtonMediaCell extends GUI.TableViewV2.Cells.CheckableButtonCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                var iconObj = Controls.AudioZoneV2Control.MediaBrowserV2Base.getConstructorForItem(this.content.item).getIconObjForItem(this.content.item, this.content.details.identifier),
                    coverViewPlaceholder = $("<div class='cover-view__placeholder'/>");
                coverViewPlaceholder.insertAfter(this.elements.checkIconCntr);
                this.coverView = new Controls.AudioZoneV2Control.CoverViewV2(coverViewPlaceholder, iconObj, true);
                this.addToHandledSubviews(this.coverView);
            }.bind(this));
        }

        // ---------------------------------------------------------------------------------------------------------
        //   Methods for super fast tableContent updates
        // ---------------------------------------------------------------------------------------------------------
        cellTypeForReuse() {
            return GUI.TableViewV2.CellType.CHECKABLE_BUTTON_MEDIA;
        }

        updateContent(newContent) {
            return super._updateContent(...arguments);
        }

        resetCellContent() {
            var promises = [super.resetCellContent(...arguments)];
            promises.push(this.coverView.empty());
            return Q.all(promises);
        }

        /**
         * This Method is used to apply a new content object onto the cell instance. e.g. update texts and so on.
         * @param newContent
         * @private
         */
        _applyNewContent(newContent) {
            // call verify first, as it'll ensure the proper icons are set.
            var promises = [super._applyNewContent(...arguments)],
                itemConstructor = Controls.AudioZoneV2Control.MediaBrowserV2Base.getConstructorForItem(newContent.item),
                iconObj = itemConstructor.getIconObjForItem(newContent.item, newContent.details.identifier);
            this.coverView && promises.push(this.coverView.setIconObject(iconObj));
            return Q.all(promises);
        }

    }

    GUI.TableViewV2.Cells.CheckableButtonMediaCell = CheckableButtonMediaCell;
    return GUI;
}(window.GUI || {});
