'use strict';
/**
 * When a view is opened where a media item can be picked to be added to a playlist or stored as zone favorite, this
 * cell is being used instead of it's baseclass. It will inform the delegate when an item is to be added, plus it will
 * show the proper symbol/button on the right side as a response to the promise returned by the delegate.
 *
 * @param [addButton]   boolean whether or not this cell should offer an "add" button
 * @param [addState]    MediaAddState --> add, spinning, done, error. Can be used to control the add state form outside.
 *
 * @delegate onMediaListAddButtonTapped(cell, row, list)
 */

window.GUI = function (GUI) {
    GUI.ReusingListView.CellType.MEDIA_ADD = "MediaAddListCell";
    GUI.LVContent.Media.ADD_STATE = "addState"; // used to keep track on the state (add, spinning, done, error)

    GUI.LVContent.Media.ADD_BUTTON = "addButton";

    var Template = function () {
        var getAddItemIcons = function getAddItemIcons() {
            var icons = "",
                iconClass = 'right__icon cell__icon right__icon-';
            icons += ImageBox.getResourceImageWithClasses(Icon.PLUS, iconClass + "add");
            icons += '<div class="cell__icon right__icon right__icon-spinning">';
            icons += '<div class="icon-spinning__spinner"></div>'; // boxed, otherwise the whole cell'd be shaking.

            icons += '</div>';
            icons += ImageBox.getResourceImageWithClasses(Icon.CHECKED, iconClass + 'done');
            icons += ImageBox.getResourceImageWithClasses(Icon.General.CAUTION, iconClass + 'error');
            return icons;
        };

        return {
            getAddItemIcons: getAddItemIcons
        };
    }();
    /**
     * Enum identifying the states the mediaCell can be in.
     * @type {{ADD: string, SPINNING: string, DONE: string, ERROR: string}}
     */


    var MediaAddState = {
        ADD: 'add',
        SPINNING: 'spinning',
        DONE: 'done',
        ERROR: 'error'
    };

    class MediaAddListCell extends GUI.ReusingListView.Cells.MediaListCell {
        constructor(delegate, cellType, listView) {
            super(...arguments);
            this.element.addClass("media-add-list-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.elements.moreIcons = this.elements.rightCntr.find(".right__icon").hide(); // insert a right side icon by default.

            this.elements.rightCntr.prepend(MediaAddListCell.Template.getAddItemIcons());
            this.elements.addIcon = this.elements.rightCntr.find(".right__icon-add").hide();
            this.elements.errorIcon = this.elements.rightCntr.find(".right__icon-error").hide();
            this.elements.doneIcon = this.elements.rightCntr.find(".right__icon-done").hide();
            this.elements.spinningIcon = this.elements.rightCntr.find(".right__icon-spinning").hide();
        }

        /**
         * When adding items is active, there is no other icon on the right side than the add button.
         * It might be
         */
        updateRightIcon() {
            var isAddable = this.cAttr(GUI.LVContent.Media.ADD_BUTTON, false),
                addState = this.cAttr(GUI.LVContent.Media.ADD_STATE, MediaAddState.ADD);
            this.elements.rightCntr.toggle(isAddable);
            this.rightButton.setEnabled(isAddable); // only update the symbol if this cell is addable and the state has changed

            if (isAddable && this.mediaAddState !== addState) {
                // the attribute this.mediaAddState is adopted inside the methods called in here.
                switch (addState) {
                    case MediaAddState.ADD:
                        this._showAdd();

                        break;

                    case MediaAddState.SPINNING:
                        this._showAdding();

                        break;

                    case MediaAddState.DONE:
                        this._showDone();

                        break;

                    case MediaAddState.ERROR:
                        this._showError();

                        break;

                    default:
                        break;
                }
            }
        }

        /**
         * As an add cell does not provide a detail-button, it only needs to handle adding items.
         */
        handleRightButtonTapped() {
            if (!this.delegate.onMediaListAddButtonTapped) {
                console.error(this.delegate + " does not handle onMediaListAddButtonTapped!");
                return;
            }

            if (this.mediaAddState === MediaAddState.SPINNING) {
                console.error(this.name + " is already adding");
                return;
            }

            this._showAdding();

            var currRow = this.cAttr(GUI.LVContent.ROW),
                promise = this.delegate.onMediaListAddButtonTapped.call(this.delegate, this, currRow, this.listView);

            if (promise) {
                // as the promise might resolve at a time where the content might have been updated, tie it to the
                // row using safePerform.
                promise.done(function (succ) {
                    this._safePerform(currRow, this._showDone.bind(this, succ));
                }.bind(this), function (err) {
                    this._safePerform(currRow, this._showError.bind(this, err));
                }.bind(this));
            } else {
                this._showAdd();
            }
        }

        // ------------------------------------------------------------------------------------------------
        //             Private Methods
        // ------------------------------------------------------------------------------------------------

        /**
         * Replaces the tickmark with a plus icon.
         * @private
         */
        _showAdd() {
            this._presentIcon(this.elements.addIcon, 1000);

            this.mediaAddState = MediaAddState.ADD;
        }

        /**
         * Replaces the add button with a spinning indicator.
         * @private
         */
        _showAdding() {
            this._presentIcon(this.elements.spinningIcon);

            this.mediaAddState = MediaAddState.SPINNING;
        }

        /**
         * Replaces the spinning indicator button with a tickmark.
         * @private
         */
        _showDone() {
            this._presentIcon(this.elements.doneIcon);

            this.mediaAddState = MediaAddState.DONE; // after two seconds, show the plus again - if the row is still up to date.

            setTimeout(this._safePerform.bind(this, this.cAttr(GUI.LVContent.ROW, -1), this._showAdd.bind(this)), 2000);
        }

        /**
         * Replaces the spinning indicator with an error symbol
         * @private
         */
        _showError(error) {
            if (error === "cancel") {
                // don't show an error if the user has cancelled it!
                this._presentIcon(this.elements.addIcon);

                return;
            }

            this._presentIcon(this.elements.errorIcon);

            this.mediaAddState = MediaAddState.ERROR;
            var content = {
                title: _('media.playlist.add.error.title', {
                    item: MediaServerComp.getNameForItem(error.item)
                }),
                message: error.cause,
                buttonOk: _('okay'),
                icon: Icon.CAUTION,
                type: PopupType.GENERAL
            };
            NavigationComp.showPopup(content);
        }

        /**
         * Will show the icon provided and hide all other icons. It'll use a transition from the current icon to
         * the new icon.
         * @param icon
         * @param duration
         * @private
         */
        _presentIcon(icon, duration) {
            if (icon === this.visibleIcon) {
                return; // icon already shown
            }

            this._swapElements(this.visibleIcon, icon);

            this._hideIcon(this.elements.errorIcon, icon, this.visibleIcon);

            this._hideIcon(this.elements.doneIcon, icon, this.visibleIcon);

            this._hideIcon(this.elements.spinningIcon, icon, this.visibleIcon);

            this._hideIcon(this.elements.addIcon, icon, this.visibleIcon);

            this.visibleIcon = icon;
        }

        /**
         * Will hide the icon if it isn't equal to used1 or used2
         * @param icon
         * @param used1
         * @param used2
         * @private
         */
        _hideIcon(icon, used1, used2) {
            if (icon !== used1 && icon !== used2) {
                icon.hide();
            }
        }

        /**
         * Will fade from the hideElem to the showElem.
         * @param hideElem
         * @param showElem
         * @param [duration]    how fast the transition should be.
         * @private
         */
        _swapElements(hideElem, showElem, duration) {
            if (!duration) {
                duration = 350;
            }

            var outDuration = duration * 0.8,
                inDuration = duration * 1.2; // hide the old element - if provided

            hideElem && hideElem.velocity("fadeOut", {
                duration: outDuration,
                complete: lxRequestAnimationFrame.bind(window, function () {
                    hideElem.hide();
                }.bind(this), hideElem)
            }); // show the new element.

            showElem.velocity("fadeIn", {
                begin: lxRequestAnimationFrame.bind(window, function () {
                    showElem.show();
                }.bind(this), showElem),
                duration: inDuration
            });
        }

        /**
         * Will only call fn if the row of the cell did not change in the meantime.
         * @param row
         * @param fn
         * @private
         */
        _safePerform(row, fn) {
            if (row === this.cAttr(GUI.LVContent.ROW, -1)) {
                fn();
            }
        }

    }

    GUI.ReusingListView.Cells.MediaAddListCell = MediaAddListCell;
    return GUI;
}(window.GUI || {});
