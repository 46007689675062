'use strict';

define("SpotPriceOptimizerControl", [
    "Control",
    "IconLib",
    "./content/AutomaticScreen",
], function (
    Control,
    { default: Icons },
    AutomaticScreen
    ) {

    const CACHE_VERSION = 1;

    return class SpotPriceOptimizerControl extends Control {

        constructor() {
            super(...arguments);
            this._cachedForecasts = null;
        }

        getButton0() {
            if (this.details.hasAutomation) {
                return {
                    reactIcon: Icons.Automatic,
                    command: () => NavigationComp.showControlContent(this, null, null, {initRouteName: AutomaticScreen.default.name}),
                };
            }
        }

        getForecast({
            forecastTimestamp,
            startUnix,
            endUnix
                    }) {
            if (forecastTimestamp) {
                return this._loadCachedForecasts().then(({ lastChanged, forecast }) => {
                    if (lastChanged >= forecastTimestamp) {
                        return forecast;
                    } else {
                        return this._fetchForecasts();
                    }
                }, e => {
                    return this._fetchForecasts();
                }).then(forecast => {
                    if (!startUnix && !endUnix) {
                        return forecast;
                    } else if (startUnix && endUnix) {
                        return forecast.filter(({s}) => {
                            return s.isBetween(startUnix, endUnix);
                        });
                    } else if (startUnix) {
                        return forecast.filter(({s}) => {
                            return s >= startUnix;
                        });
                    } else if (endUnix) {
                        return forecast.filter(({e}) => {
                            return e <= endUnix;
                        });
                    } else {
                        return forecast;
                    }
                });
            }

            return Q.resolve([]);
        }

        get reactIcon() {
            return Icons.Gauge
        }


        _getForecastFileName() {
            return `${ActiveMSComponent.getActiveMiniserverSerial()}-${this.uuidAction}-forecast.json`;
        }

        _loadCachedForecasts() {
            if (!this._cachedForecasts) {
                return PersistenceComponent.loadFile(
                    this._getForecastFileName(),
                    DataType.OBJECT
                ).then((forecast) => {
                    if (forecast.version === CACHE_VERSION) {
                        this._cachedForecasts = forecast;
                        return forecast;
                    } else {
                        return Q.reject("Cache mismatch");
                    }
                });
            } else {
                return Q.resolve(this._cachedForecasts);
            }
        }

        _cacheForecasts(forecast, lastChanged) {
            return PersistenceComponent.saveFile(this._getForecastFileName(), {
                version: CACHE_VERSION,
                lastChanged,
                forecast
            }, DataType.OBJECT).then(() => {
                this._cachedForecasts = {
                    version: CACHE_VERSION,
                    lastChanged,
                    forecast
                };
                return forecast;
            }, e => {
                console.warn(this.getName(), "Couldn't cache forecast");
                console.error(e);
                return forecast;
            });
        }

        _fetchForecasts() {
            return CommunicationComponent.sendViaHTTP(
                `jdev/sps/io/${this.uuidAction}/${Commands.SPOT_PRICE_OPTIMIZER.GET_FORECAST}`,
                EncryptionType.NONE,
                true
            ).then(forecast => {
                return this._cacheForecasts(forecast, this.getStates().forecastTimestamp);
            });
        }

        getReactScreens() {
            return [
                ...super.getReactScreens(...arguments),
                AutomaticScreen.default
            ];
        }
    };
});
