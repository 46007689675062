import {TYPE} from "./pulseAtControlEnums";
import {LxReactContextMenuHandler} from "LxComponents";
import globalStyles from "GlobalStyles";

export default class PulseAtControlStatesSrc {

    static currentSelection = null;

    static getStateSections(control, states) {
        let sections = [];

        let section = {},
            rows = [],
            currentTime = [states.startTime.hours(), states.startTime.minutes(), states.startTime.seconds()];

        if (Feature.PULSE_AT_TIME) {
            let disclosureText = control.getNameForType(states.type);
            rows.pushObject(this._generatePulseRow(control, states, disclosureText));
        }

        if (states.type === TYPE.TIME) {
            rows.pushObject(this._generatePickerRow(control, _("mobiscroll.clocktime"), states.startTimeString, this._showPicker, currentTime, _('mobiscroll.clocktime')));
        }

        if (states.type === TYPE.TIME || states.type === TYPE.NIGHTFALL || states.type === TYPE.DAYBREAK || states.type === TYPE.SUNSET || states.type === TYPE.SUNRISE) {
            rows.pushObject(this._generateOneTimePulseRow(control, states.oneTimePulseDate));

            if (!!states.oneTimePulseDate) {
                let date = states.oneTimePulseDate.clone();
                date.hours(0);
                date.minutes(0);
                date.seconds(0);
                rows.pushObject(this._generatePickerRow(control, _("mobiscroll.date"), states.oneTimePulseDate.format(DateType.Date), this._showPicker, moment.unix(date.unix()), _('mobiscroll.date')));
            }
        }

        section.rows = rows;
        sections.push(section)

        return sections;
    }

    static _generatePulseRow(control,states, disclosureText) {
        return {
            content: {
                title: _("controls.pulseAt.time-picker"),
                disclosureText: disclosureText,
                clickable: true,
                disclosureColor: Color.TEXT_INACTIVE_B
            },
            type: GUI.TableViewV2.CellType.GENERAL,
            action: (cell, section, row, tableView) => {
                let defer = Q.defer(),
                    details = {
                        options: this._generateTypeOptions(control, states),
                        title: _("controls.pulseAt.time-picker"),
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.TABLE,
                        mode: GUI.SelectorScreenMode.QUICK,
                        deferred: defer
                    };
                NavigationComp.showSelector(details).then((results) => {
                    let cmd = Commands.format(Commands.PulseAt.SET_TYPE, results[0].option.id);
                    return control._sendCommand(cmd);
                });
            }
        };
    }

    static _generateTypeOptions(control, states) {
        return Object.values(TYPE).map((type) => {
            return {
                id: type,
                title: control.getNameForType(type),
                selected: type === states.type
            };
        }).sort((a, b) => {
            if (a.title < b.title) {
                return -1;
            }

            if (a.title > b.title) {
                return 1;
            }

            return 0;
        });
    }

    static _generatePickerRow(control, title, value, action, currentValue, pickerTitle) {
        return {
            content: {
                title: title,
                disclosureText: value,
                clickable: true,
                disclosureColor: globalStyles.colors.green
            },
            type: GUI.TableViewV2.CellType.GENERAL,
            action: (cell, section, row, tableView) => {
                if (row === (Feature.PULSE_AT_TIME ? 1 : 0)) {
                    this._showPicker(control, currentValue, GUI.LxTimePickerContextMenu.TYPE.TIME, pickerTitle);
                } else {
                    this._showPicker(control, currentValue, GUI.LxTimePickerContextMenu.TYPE.DATE, pickerTitle);
                }
            }
        };
    }

    static _generateOneTimePulseRow(control, date) {
        return {
            content: {
                title: _("controls.pulseAt.oneTime"),
                active: !!date,
                clickable: true
            },
            type: GUI.TableViewV2.CellType.SWITCH,
            onSwitchChanged: (value) => {
                let cmd, timeValue;

                if (!value) {
                    timeValue = 0;
                } else {
                    let currentTime = ActiveMSComponent.getMiniserverTimeInfo().clone().utc(0);
                    currentTime.hours(0);
                    currentTime.minutes(0);
                    currentTime.seconds(0);
                    timeValue = currentTime.getSecondsSince2009();
                }

                cmd = Commands.format(Commands.PulseAt.SET_ONE_TIME_PULSE, timeValue);

                control.sendCommand(cmd);
            }
        };
    }

    static _showPicker(control, currentValue, type, title) {
        let options = {
            type: type,
            value: currentValue,
            onPickerChanged: (value) => this.currentSelection = value,
            showSeconds: true
        };

        if (type === GUI.LxTimePickerContextMenu.TYPE.TIME) {
            options.onPickerDisappear = this._handleTime.bind(this, control);
        } else {
            options.onPickerDisappear = this._handleDate.bind(this, control);
            options.minDate = moment();
        }

        LxReactContextMenuHandler.shared.showContextMenu(options, title, null, GUI.LxTimePickerContextMenu);
    }

    static _handleTime(control) {
        if (this.currentSelection) {
            let time = this.currentSelection[0] * 60 * 60 + this.currentSelection[1] * 60 + this.currentSelection[2],
                // seconds since midnight
                cmd = Commands.format(Commands.PulseAt.SET_TIME, time);

            control._sendCommand(cmd);

            this.currentSelection = null;
        }
    }

    static _handleDate(control, value) {
        if (this.currentSelection) {
            let cmd = Commands.format(Commands.PulseAt.SET_ONE_TIME_PULSE, this.currentSelection.getSecondsSince2009());

            control._sendCommand(cmd);

            this.currentSelection = null;
        }
    }
}
