'use strict';

window.GUI = function (GUI) {
    class HomeKitAccessoriesScreen extends GUI.SearchTableViewScreen {
        //region Static
        static Template = class {
            //region Static
            static getAccessoriesCountTemplate() {
                return $("<div class='accessories-count'></div>");
            }

            static getContinueButtonTemplate() {
                return $("<div class='continue-button'>" + _('home-kit.continue') + "</div>");
            } //endregion Static


        }; //endregion Static

        constructor(details) {
            super(...arguments);
            Object.assign(this, ContextMenuHandler.Mixin);

            this._handleDetails(details);
        }

        shouldBeUpdated() {
            return false;
        }

        updateView(details) {
            this._handleDetails(details);

            this.ViewController._preventReset = !!this.ViewController._comeFromSettings;
            return this._handleAccessories().then(function () {
                this._adjustCount();

                return this.reloadTable();
            }.bind(this));
        }

        _handleDetails(details) {
            this._selectedAccessories = [];
            this._apiUserUuid = details.apiUserUuid;

            if (details && details.accessories) {
                this._accessories = details.accessories;
            } else {
                this._accessories = [];
            }

            if (details && details.homeName) {
                this._homeName = details.homeName;
            } else {
                this._homeName = "";
            }

            if (details && details.comeFromSettings) {
                this._comeFromSettings = details.comeFromSettings;
            }
        }

        titleBarConfig() {
            if (!this._comeFromSettings) {
                return {
                    leftSide: TitleBarCfg.Button.CLOSE,
                    rightSide: TitleBarCfg.CUSTOM_ICON,
                    rightIconSrc: Icon.SEARCH
                };
            }

            return {
                leftSide: TitleBarCfg.Button.BACK
            };
        }

        viewDidLoad() {
            var args = arguments;
            this.ViewController._preventReset = !!this.ViewController._comeFromSettings;
            return this._handleAccessories().then(() => {
                return super.viewDidLoad(...args).then(function () {
                    var buttonReadyPrms;
                    this.accessoriesCountElem = HomeKitAccessoriesScreen.Template.getAccessoriesCountTemplate();
                    this.element.append(this.accessoriesCountElem);

                    if (!this._comeFromSettings) {
                        this.continueButtonElem = HomeKitAccessoriesScreen.Template.getContinueButtonTemplate();
                        this.continueButton = new GUI.LxButton(this, this.continueButtonElem);
                        buttonReadyPrms = this.insertSubviewAfter(this.continueButton, this.accessoriesCountElem);
                        this.continueButton.onButtonTapped = this._continueButtonAction.bind(this);
                    } else {
                        buttonReadyPrms = Q.resolve();
                    }

                    return buttonReadyPrms.then(this._adjustCount.bind(this));
                }.bind(this));
            });
        }

        _handleAccessories() {
            var promise;

            this._accessories.forEach(function (accessory) {
                if (accessory.visible) {
                    this._addSelectedAccessory(accessory.aid);
                }
            }.bind(this));

            promise = ActiveMSComponent.getHomeKitAccessoriesWithRooms(this._accessories);
            return promise.then(function (accessoriesWithRooms) {
                this._accessoriesWithRooms = accessoriesWithRooms;
            }.bind(this));
        }

        titleBarText() {
            return _("home-kit.functions.title");
        }

        closeAction() {
            Debug.HomeKit && console.log(this.viewId, "closeAction - comeFromSettings? " + !!this._comeFromSettings + ", selectedLength=" + this._selectedAccessories.length);

            if (!this._comeFromSettings) {
                this.ViewController.dismiss();
            } else if (this._selectedAccessories.length > ActiveMSComponent.getMaxHomeKitAccessories()) {
                this.ViewController.dismiss();
            } else {
                var mappedAccessories = this._accessories.map(this._mappedAccessoriesMap.bind(this)),
                    addedAccessories = this._getAddedAccessories();

                var promise = this._updateAccessoryVisibility(mappedAccessories, addedAccessories);

                NavigationComp.showWaitingFor(promise, _("home-kit.loading.message.finish")).then(function () {
                    this.ViewController.navigateBack(true, {
                        accessories: mappedAccessories
                    });
                }.bind(this), function (error) {
                    console.error(this.viewId, "updatingHomeKitAccessories failed: " + JSON.stringify(error));
                    this.ViewController.handleError(error, _("home-kit.error.update-accessories"));
                    this.ViewController.navigateBack();
                }.bind(this));
            }
        }

        /**
         * Used when updating the visibility later on, after the initial setup!
         * This is different, as only new accessories need to be "prepared" in the HomeKit-Home
         * @returns {*}
         * @private
         */
        _updateAccessoryVisibility(mappedAccessories, addedAccessories) {
            Debug.HomeKit && console.log(this.viewId, "_updateAccessoryVisibility: all=" + mappedAccessories.length + ", new accessories: " + addedAccessories.length);
            var promises = []; // at first, ensure the plugin is starting to listen for new accessories

            promises.push(ActiveMSComponent.assignNewHomeKitAccessories(addedAccessories).then(function (assigningErrors) {
                Debug.HomeKit && console.log(this.viewId, "_updateAccessoryVisibility: > accessories added to HK " + JSON.stringify(assigningErrors));
            }.bind(this), function (err) {
                console.error(this.viewId, "_updateAccessoryVisibility: > assignNewHomeKitAccessories failed! " + JSON.stringify(err));
                return Q.reject(err);
            }.bind(this))); // secondly, pass on the updated list of accessories to the Miniserver, it'll then restart the accessory
            // server and the newly created accessories should arrive - meaning that the listenerst registered above
            // will be called.

            promises.push(ActiveMSComponent.updateHomeKitAccessoriesList(mappedAccessories).then(function (res) {
                Debug.HomeKit && console.log(this.viewId, "_updateAccessoryVisibility: > MS did receive new accessories list. Res=" + JSON.stringify(res));
                return ActiveMSComponent.getHomeKitAddonStatus().then(function (statusRes) {
                    Debug.HomeKit && console.log(this.viewId, "_updateAccessoryVisibility: > MS confirms addon-status" + JSON.stringify(statusRes));
                }.bind(this));
            }.bind(this))); // If one of the given promises is rejected, the returned promise is immediately rejected, not waiting for the rest of the batch

            return Q.all(promises).then(function (succ) {
                Debug.HomeKit && console.log(this.viewId, "_updateAccessoryVisibility: FINISHED, Success=" + JSON.stringify(succ));
                return succ;
            }.bind(this), function (err) {
                Debug.HomeKit && console.log(this.viewId, "_updateAccessoryVisibility: FAILED " + JSON.stringify(err));
                return Q.reject(err);
            }.bind(this));
        }

        getAnimation() {
            return AnimationType.NONE;
        }

        reloadTable() {
            this._createTableContent();

            return super.reloadTable(...arguments);
        }

        _getAddedAccessories() {
            var addedAccessories = [];

            this._selectedAccessories.forEach(function (selected) {
                this._accessories.forEach(function (accessory) {
                    if (selected === accessory.aid && !accessory.visible && accessory.homeKitRoom) {
                        addedAccessories.pushObject(accessory);
                    }
                }.bind(this));
            }.bind(this));

            return addedAccessories;
        }

        _getSearchIndexItem(sectionIdx, rowIdx) {
            sectionIdx -= 1;

            if (this._accessoryGroups) {
                return {
                    name: this._accessoryGroups[sectionIdx].values[rowIdx].name,
                    uuid: this._accessoryGroups[sectionIdx].values[rowIdx].aid,
                    object: this._accessoryGroups[sectionIdx].values[rowIdx]
                };
            } else {
                return null;
            }
        }

        _removeSelectedAccessory(aid) {
            var index = this._selectedAccessories.indexOf(aid);

            if (index > -1) {
                this._selectedAccessories.splice(index, 1);
            }

            this._adjustCount();
        }

        _addSelectedAccessory(aid) {
            this._selectedAccessories.pushIfNoDuplicate(aid);

            this._adjustCount();
        }

        _adjustCount() {
            var selectedCount = this._selectedAccessories.length,
                validClass = "selected-count-valid";

            if (selectedCount > ActiveMSComponent.getMaxHomeKitAccessories()) {
                validClass = "selected-count-invalid";
                this.continueButtonElem && this.continueButton.setEnabled(false);
            } else {
                this.continueButtonElem && this.continueButton.setEnabled(true);
            }

            if (this._accessories.length === 0) {
                this.accessoriesCountElem && this.accessoriesCountElem.html(_("empty.group-screen.title"));
            } else {
                this.accessoriesCountElem && this.accessoriesCountElem.html(_("home-kit.functions.selected", {
                    selected: "<span class=" + validClass + ">" + selectedCount + "</span>",
                    maximum: ActiveMSComponent.getMaxHomeKitAccessories()
                }));
            }

            if (this.getTitleBar() && this.getTitleBar().buttons.rightSideButton) {
                this.getTitleBar().buttons.rightSideButton.setEnabled(this._accessories.length);
            }
        }

        _createCellFromSearchItem(accessory) {
            return this._createAccessoryRow(accessory);
        }

        _getKeywordsForUuid(uuid) {
            var accessory = this._accessories.find(function (accessory) {
                    return accessory.aid === uuid;
                }.bind(this)),
                keywords = super._getKeywordsForUuid(...arguments);

            if (accessory) {
                keywords.push(accessory.typeName);
                keywords.push(accessory.room);
            }

            return keywords;
        }

        _createTableContent() {
            if (!this._showWaiting) {
                if (this._accessories.length === 0) {
                    var descriptionSection = {
                        footerTitle: _("home-kit.functions.empty.description-1") + "<br><br>" + _("home-kit.functions.empty.description-3"),
                        rows: []
                    };
                    var buttonSection = {
                        rows: [this._createButtonCell(_("home-kit.functions.button.settings"), this._openUserDetailsScreen.bind(this))]
                    };

                    if (!this._comeFromSettings) {
                        descriptionSection.footerTitle = _("home-kit.functions.empty.description-1") + "<br><br>" + _("home-kit.functions.empty.description-2");
                        buttonSection.rows.pushObject(this._createButtonCell(_("home-kit.functions.button.change-user"), this._navigateToUsers.bind(this)));
                    }

                    this.tableContent = [descriptionSection, buttonSection];
                    this.originalTableContent = cloneObjectDeep(this.tableContent);
                } else {
                    this.tableContent = [{
                        footerTitle: _("home-kit.functions.message"),
                        rows: []
                    }];
                    this._accessoryGroups = groupByArray(this._accessories, "room");
                    sortArrByFields(this._accessoryGroups, ["key"]);

                    this._accessoryGroups.forEach(function (item) {
                        sortArrByFields(item.values, ["name"]);
                    }.bind(this));

                    this._accessoryGroups.forEach(function (accessoryGroup) {
                        var rows = [];
                        accessoryGroup.values.forEach(function (accessory) {
                            rows.push(this._createAccessoryRow(accessory));
                        }.bind(this));
                        this.tableContent.push({
                            headerTitle: accessoryGroup.values[0].room,
                            sectionRightButtonTitle: _("home-kit.functions.select-all"),
                            rightSectionButtonTapped: this._sectionButtonAction.bind(this),
                            rows: rows
                        });
                    }.bind(this));

                    this.originalTableContent = cloneObjectDeep(this.tableContent);
                }
            } else {
                this.tableContent = [];
            }
        }

        _createButtonCell(title, action) {
            return {
                content: {
                    title: _(title),
                    clickable: true
                },
                type: GUI.TableViewV2.CellType.GENERAL,
                action: action
            };
        }

        _openUserDetailsScreen() {
            var userDetailDefer = Q.defer();
            ActiveMSComponent.getUser(this._apiUserUuid).then(function (user) {
                NavigationComp.showState(ScreenState.UserDetailsScreen, {
                    user: getLxResponseValue(user),
                    isNewUser: true,
                    homeKitDefer: userDetailDefer
                }, AnimationType.MODAL);
            }.bind(this));
            return userDetailDefer.promise.then(function () {
                var promise = ActiveMSComponent.getHomeKitAddonStatus().then(function () {
                    return ActiveMSComponent.getHomeKitAccessoriesList().then(function (accessories) {
                        this._accessories = accessories;

                        this._handleAccessories().then(function () {
                            this._adjustCount();

                            return this.reloadTable();
                        }.bind(this));
                    }.bind(this));
                }.bind(this), function (error) {
                    console.error(this.viewId, "_openUserDetailsScreen: failed to get HomeKit-Status", error);
                }.bind(this));
                NavigationComp.showWaitingFor(promise);
                return promise;
            }.bind(this));
        }

        _navigateToUsers() {
            this.ViewController.getHomeKitUsers().then(function (users) {
                this.ViewController.showState(ScreenState.HomeKitUsers, null, {
                    users: users
                });
            }.bind(this));
        }

        _createAccessoryRow(accessory) {
            var cellObject = {
                type: GUI.TableViewV2.CellType.CHECKABLE_BUTTON,
                content: {
                    title: accessory.name,
                    active: true,
                    selected: this._selectedAccessories.includes(accessory.aid),
                    radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE,
                    accessory: accessory,
                    buttonSrc: Icon.Buttons.MORE2,
                    subtitle: accessory.typeName
                },
                didCheckCell: this._checkAccessoryCellAction.bind(this),
                buttonTapped: function (section, row, tableView) {
                    var accessory = tableView.cellForSectionAndRow(section, row).content.accessory,
                        contextMenuOptions = [];
                    contextMenuOptions.pushObject(this._getIdentifyRow(accessory));

                    if (accessory.model === "Switch") {
                        contextMenuOptions.pushObject(this._getAccessoryTypeRow(HomeKit.SERVICE_TYPE.LIGHT, accessory.serial));
                        contextMenuOptions.pushObject(this._getAccessoryTypeRow(HomeKit.SERVICE_TYPE.SWITCH, accessory.serial));
                        contextMenuOptions.pushObject(this._getAccessoryTypeRow(HomeKit.SERVICE_TYPE.FAN, accessory.serial));
                    }

                    this._showContextMenu(contextMenuOptions);
                }.bind(this)
            };

            if (this._comeFromSettings && this._accessoriesWithRooms[accessory.serial] && accessory.homeKitRoom && this._accessoriesWithRooms[accessory.serial].room !== accessory.homeKitRoom && accessory.visible) {
                cellObject.content.button2Src = Icon.INFO2;
                cellObject.content.button2Fill = window.Styles.colors.orange;
                cellObject.content.homeAccessory = this._accessoriesWithRooms[accessory.serial];

                cellObject.button2Tapped = function (section, row, tableView) {
                    NavigationComp.showPopup(this._createAssignRoomPopupContent(cellObject), PopupType.GENERAL).then(function () {
                        return ActiveMSComponent.assignHomeKitAccessory(accessory.serial, accessory.homeKitRoom, accessory.homeKitName || "").then(function (assignedRoomName) {
                            this._accessoriesWithRooms[accessory.serial].room = assignedRoomName;
                            accessory.homeKitRoom = assignedRoomName;
                            return this.reloadTable();
                        }.bind(this), function (error) {
                            this.ViewController.handleError(error, _("home-kit.error.assign-room"));
                        }.bind(this));
                    }.bind(this));
                }.bind(this);
            }

            return cellObject;
        }

        _createAssignRoomPopupContent(cellObject) {
            return {
                title: _("home-kit.functions.assign.popup.title"),
                message: _("home-kit.functions.assign.popup.message", {
                    room: cellObject.content.homeAccessory.room
                }),
                buttonOk: _("home-kit.functions.assign.popup.assign"),
                buttonCancel: _("home-kit.functions.assign.popup.cancel")
            };
        }

        _getIdentifyRow(accessory) {
            return {
                title: _("home-kit.context-menu.identify"),
                action: function () {
                    ActiveMSComponent.identifyHomeKitAccessory(accessory.serial).then(function () {
                        console.log("Identify successful for accessory: " + accessory.name);
                    }.bind(this), function (error) {
                        console.error("Identify error: " + error);
                    }.bind(this));
                }.bind(this)
            };
        }

        _getAccessoryTypeRow(type, accessorySerial) {
            var serviceId = "",
                serviceName = _("home-kit.context-menu.type.switch");

            if (type === HomeKit.SERVICE_TYPE.LIGHT) {
                serviceId = "00000043-0000-1000-8000-0026BB765291";
                serviceName = _("home-kit.context-menu.type.light");
            } else if (type === HomeKit.SERVICE_TYPE.FAN) {
                serviceId = "00000040-0000-1000-8000-0026BB765291";
                serviceName = _("home-kit.context-menu.type.fan");
            }

            return {
                title: serviceName,
                action: function () {
                    ActiveMSComponent.changeHomeKitAccessoryServiceType(accessorySerial, serviceId).then(function () {
                        console.log("Changed service type of accessory: " + accessorySerial);
                    }.bind(this), function (error) {
                        console.error("Error changing service type of accessory: " + accessorySerial);
                    }.bind(this));
                }.bind(this)
            };
        }

        _checkAccessoryCellAction(cell, section, row, tableView, selected) {
            var possibleAccessoryId = cell.content.accessory.aid;

            if (selected) {
                this._addSelectedAccessory(possibleAccessoryId);
            } else {
                this._removeSelectedAccessory(possibleAccessoryId);
            }

            if (this.isSearchModeActive) {
                this._adjustTableContent(this.filteredTableContent, possibleAccessoryId, selected);
            }

            this._adjustTableContent(this.tableContent, possibleAccessoryId, selected);

            this._adjustTableContent(this.originalTableContent, possibleAccessoryId, selected);
        }

        _adjustTableContent(tableContent, possibleAccessoryId, selected) {
            var rowIndex,
                sectionIndex = tableContent.findIndex(function (sectionObject) {
                    return sectionObject.rows.findIndex(function (rowObject, rowIdx) {
                        var objectFound = rowObject.content.accessory.aid === possibleAccessoryId;
                        rowIndex = rowIdx;
                        return objectFound;
                    }.bind(this));
                }.bind(this));

            if (rowIndex && rowIndex >= 0) {
                tableContent[sectionIndex].rows[rowIndex].content.selecetd = selected;
            }
        }

        _sortByKey(a, b) {
            if (a.key.toUpperCase() > b.key.toUpperCase()) {
                return 1;
            }

            if (a.key.toUpperCase() < b.key.toUpperCase()) {
                return -1;
            }

            return 0;
        }

        _mappedAccessoriesMap(accessory) {
            var selectedIndex = this._selectedAccessories.findIndex(function (aid) {
                return aid === accessory.aid;
            }.bind(this));

            return {
                aid: accessory.aid,
                visible: selectedIndex !== -1,
                name: accessory.name,
                room: accessory.room,
                roomUuid: accessory.roomUuid,
                serial: accessory.serial,
                control: accessory.control,
                homeKitRoom: accessory.homeKitRoom,
                homeKitName: accessory.homeKitName,
                model: accessory.model,
                typeName: accessory.typeName
            };
        }

        /**
         * During initial setup, this method is used to update the list of accessories on the Miniserver and also to
         * initially prepare all the visible accessories in the HomeKit-Home.
         * @param allAccessories
         * @param accessoriesToAddToHomeKit
         * @returns {*}
         * @private
         */
        _initialSetAccessories(allAccessories, accessoriesToAddToHomeKit) {
            Debug.HomeKit && console.log(this.viewId, "_initialSetAccessories: all=" + allAccessories.length + ", new accessories: " + accessoriesToAddToHomeKit.length);
            return ActiveMSComponent.updateHomeKitAccessoriesList(allAccessories).then(function (res) {
                Debug.HomeKit && console.log(this.viewId, "_initialSetAccessories: > MS confirmed new accessories list! Res=" + JSON.stringify(res));
                return ActiveMSComponent.getHomeKitAddonStatus().then(function () {
                    Debug.HomeKit && console.log(this.viewId, "_initialSetAccessories: > MS confirmed addon status!");
                    return ActiveMSComponent.assignHomeKitAccessories(accessoriesToAddToHomeKit).then(function () {
                        Debug.HomeKit && console.log(this.viewId, "_initialSetAccessories: > device confirmed accessories where added!");
                    }.bind(this));
                }.bind(this));
            }.bind(this)).then(function (succ) {
                Debug.HomeKit && console.log(this.viewId, "_initialSetAccessories: SUCCESS! all=" + allAccessories.length + ", new accessories: " + accessoriesToAddToHomeKit.length);
                return succ;
            }.bind(this), function (error) {
                console.error(this.viewId, "_initialSetAccessories: FAILED - " + JSON.stringify(error));
                return Q.reject(error);
            }.bind(this));
        }

        _continueButtonAction() {
            Debug.HomeKit && console.log(this.viewId, "_continueButtonAction");

            var waitingPromise,
                promises = [],
                callbackDefer = Q.defer(),
                mappedAccessories = this._accessories.map(this._mappedAccessoriesMap.bind(this)),
                addedAccessories = this._getAddedAccessories(); // if accessories are selected that have previously not been visible in homeKit (e.g. due to surpassing
            // the 150 accessory limit), start a listener for these to be configured.


            if (addedAccessories.length > 0) {
                promises.push(ActiveMSComponent.assignNewHomeKitAccessories(addedAccessories));
            } // send the initial (and potentially adopted) list to the Miniserver


            promises.push(this._initialSetAccessories(mappedAccessories, this._getSelectedMappedAccessories(mappedAccessories))); // all of the above must succeed to proceed

            waitingPromise = Q.all(promises); // if one promise fails, this will reject immediately.

            waitingPromise.then(function () {
                Debug.HomeKit && console.log(this.viewId, "_continueButtonAction > accessories prepared, continue!");
            }.bind(this));
            this.ViewController.showState(ScreenState.WaitingScreen, null, {
                message: _("home-kit.loading.message.finish"),
                cancelButtonText: _("home-kit.loading.cancel"),
                waitingPromise: waitingPromise,
                callbackDefer: callbackDefer
            });
            callbackDefer.promise.then(function () {
                this._navigateToSetupDone(this._getRoomCount());
            }.bind(this), function (error) {
                console.error(this.viewId, "HomeKit accessory update failed on continue button action!" + JSON.stringify(error));
                this.ViewController.handleError(error, _("home-kit.error.update-accessories"));
            }.bind(this));
        }

        _getSelectedMappedAccessories(mappedAccessories) {
            return mappedAccessories.filter(function (accessory) {
                return accessory.visible;
            }.bind(this));
        }

        _getRoomCount() {
            var roomNames = this._accessories.filter(function (accessory) {
                return this._selectedAccessories.indexOf(accessory.aid) !== -1;
            }.bind(this)).map(function (accessory) {
                return accessory.room;
            }.bind(this));

            var reducedRoomNames = roomNames.filter(function (accessory, pos) {
                return roomNames.indexOf(accessory) === pos;
            }.bind(this));
            return reducedRoomNames.length;
        }

        _navigateToSetupDone(roomCount) {
            var def = Q.defer();
            this.ViewController.showState(ScreenState.HomeKitSetupDone, null, {
                iconSrc: Icon.HOME_KIT.HOME,
                title: _("home-kit.setup-done.title"),
                message: _("home-kit.setup-done.subtitle", {
                    selected: this._selectedAccessories.length,
                    rooms: roomCount
                }),
                message2: _("home-kit.setup-done.subtitle2"),
                iconColor: Color.WHITE,
                continueBtnText: _("home-kit.setup-done.button"),
                continueDef: def,
                bottomIcon: Icon.HOME_KIT.SIRI,
                bottomText: _("home-kit.setup-done.hint")
            }, AnimationType.NONE);
            def.promise.done(function (viaButton) {
                this.ViewController.dismiss();
            }.bind(this));
        }

        _sectionButtonAction(section, tableView) {
            var allAids;

            if (this.isSearchModeActive) {
                allAids = this.filteredTableContent[section].rows.map(function (row) {
                    return row.content.accessory;
                }.bind(this));
            } else {
                allAids = this._accessoryGroups[section - 1].values;
            }

            var allSelected = allAids.map(function (accessory) {
                return this._selectedAccessories.includes(accessory.aid);
            }.bind(this)).reduce(function (a, b) {
                return a && b;
            }, true);
            var visible = !allSelected;
            allAids.forEach(function (acc, index) {
                if (visible) {
                    this._addSelectedAccessory(acc.aid);
                } else {
                    this._removeSelectedAccessory(acc.aid);
                }
            }.bind(this));
            allAids.forEach(function (aid, index) {
                var cell = this.tableView.cellForSectionAndRow(section, index);
                cell.setChecked(visible, true, true);
            }.bind(this));
        }

    }

    GUI.HomeKitAccessoriesScreen = HomeKitAccessoriesScreen;
    return GUI;
}(window.GUI || {});
