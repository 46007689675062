'use strict';

define("SmokeAlarmControlCommandSrc", ["ControlCommandSrc", "SmokeAlarmControlEnums"], function (ControlCommandSrc, SmokeAlarmControlEnums) {
    return class SmokeAlarmControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states, navigation) {
            let cmds = [];

            if (states.alarmActive) {
                cmds.push(this.createCommand(_("controls.alarm.acknowledge"), Commands.ALARM.SMOKE_ALARM.QUIT, null));
                let showMuteButton = states.acousticAlarm; // We need to be able to mute the smokeAlarm if this feature is set.
                // The miniserver activates the visual alarm at an earlier alarm state (pre alarm)

                if (Feature.SMOKE_SIGNAL_AT_PREALARM) {
                    showMuteButton = states.currentAlarmLevel !== SmokeAlarmControlEnums.ALARM_LEVEL.NO_ALARM;
                }

                if (showMuteButton) {
                    let title = states.areAlarmSignalsOff ? _("controls.alarm.fireAlarm.siren.muted") : _("controls.alarm.fireAlarm.siren.mute");
                    cmds.push(this.createDeactivatable(title, Commands.ALARM.SMOKE_ALARM.MUTE, null, states.areAlarmSignalsOff, null));
                }
            } else if (states.serviceModeActive || states.testAlarmActive) {
                // the service mode may no longer be active, but there may still be the testalarm active when the timer runs out.
                if (states.testAlarmActive) {
                    // And the testAlarm is active
                    cmds.push(this.createCommand(_("controls.alarm.fireAlarm.quit.testalarm"), Commands.ALARM.SMOKE_ALARM.QUIT, null));
                } else {
                    cmds.push(this.createCommand(_("controls.alarm.fireAlarm.quit.service-mode"), Commands.format(Commands.ALARM.SMOKE_ALARM.SERVICE_MODE, 0), null));
                }
            }

            if (!states.alarmActive && !states.testAlarmActive && !states.serviceModeActive) {
                cmds.push(this.createCommand(_("controls.alarm.fireAlarm.start.service-mode"), this.showServiceMode.bind(this, control, navigation), null));
            }

            return cmds;
        }

        showServiceMode(control, navigation) {
            navigation.navigate(SmokeAlarmControlEnums.ScreenState.SERVICE_MODE, { control })
        }

    };
});
