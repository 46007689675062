'use strict';

{//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var USER_CANCEL = "The user cancelled sign in.";

    class AlexaSetupScreen extends GUI.AlexaOnboardingScreen {
        //region Static
        static Template = class {
            static getButtonTemplate(btnTitle) {
                return $("<div class='button-container'>" + "   <img class='button-container__button' src='" + Icon.ALEXA.OPEN_SDK + "'/>" + "</div>");
            }
        }; //endregion Static

        constructor(details) {
            super($("<div />"));
            this.id = details.id;
            this.iconSrc = details.iconSrc;
            this.iconColor = details.iconColor;
            this.title = details.title;
            this.message = details.message;
            this.bottomText = details.bottomText;
            this._awsCodeChallenge = details.awsCodeChallenge;
            this.shareButtonText = details.shareButtonText;
            this.continueBtnText = details.continueBtnText;
            this.continueDef = details.continueDef;
        }

        closeAction() {
            this.ViewController.closeAction();
        }

        continueBtnAction() {
            var callbackDefer = Q.defer(),
                waitingPromise = Q.defer();
            this.ViewController.showState(ScreenState.WaitingScreen, null, {
                cancelButtonText: _('cancel'),
                message: _('alexa.configuration.completing'),
                callbackDefer: callbackDefer,
                waitingPromise: waitingPromise.promise
            });
            var options = {
                productID: "LoxoneMiniserver",
                deviceSerialNumber: ActiveMSComponent.getActiveMiniserver().serialNo,
                codeChallenge: this._awsCodeChallenge
            };

            window.AmazonLoginPlugin.authorizeAVS(options, function (data) {
                var sendData = {
                    code: data.authorizationCode,
                    redirect_uri: data.redirectUri,
                    client_id: data.clientId
                };
                ActiveMSComponent.loginAmazonUser(sendData).then(function () {
                    console.log(this.name, "setauth successfull");
                    ActiveMSComponent.getAuthState().then(function () {
                        waitingPromise.resolve();
                    }.bind(this), function () {
                        waitingPromise.reject();
                        return this._showErrorPopup();
                    }.bind(this));
                }.bind(this), function () {
                    waitingPromise.reject();
                    return this._showErrorPopup();
                }.bind(this));
            }.bind(this), function (err) {
                if (err === USER_CANCEL) {
                    waitingPromise.reject(err);
                } else {
                    this._showErrorPopup().then(function () {
                        waitingPromise.reject();
                    });
                }
            }.bind(this));
            callbackDefer.promise.then(function (screenState) {
                this.ViewController.showState(ScreenState.AlexaSelectUserViewController, null, {
                    screenToShow: ScreenState.AlexaSelectUserScreen
                });
            }.bind(this), function (err) {
                if (err !== USER_CANCEL) {
                    this.closeAction();
                }
            }.bind(this));
        }

        _getButtonTemplate() {
            return AlexaSetupScreen.Template.getButtonTemplate(this.continueBtnText);
        }

        _showErrorPopup() {
            return NavigationComp.showPopup({
                title: _('alexa.setup.error'),
                buttonOk: _('done')
            });
        }

    }

    GUI.AlexaSetupScreen = AlexaSetupScreen;
}
