'use strict';

define(["ControlInfoView", "DaytimerTimeLine", 'DaytimerControlEnums'], function (ControlInfoView, DaytimerTimeLine, DaytimerControlEnums) {
    var TIME_LINE_UPDATE_INTERVAL = 10000;
    return class DaytimerControlInfoView extends ControlInfoView {
        //region Static
        static Template = function () {
            var getContent = function getContent() {
                return '<div class="daytimer-control-info-view__label daytimer-control-info-view__top-label" />' + getTimeLine() + '<div class="daytimer-control-info-view__label daytimer-control-info-view__bottom-label" />';
            };

            var getTimeLine = function getTimeLine() {
                return '' + '<div class="daytimer-control-info-view__time-line">' + '<div class="time-line__label-placeholder">' + '<span class="placeholder__time-label">00</span>' + '<span class="placeholder__time-label">06</span>' + '<span class="placeholder__time-label">12</span>' + '<span class="placeholder__time-label">18</span>' + '<span class="placeholder__time-label">24</span>' + '</div>' + '<canvas class="time-line__canvas"></canvas>' + '<div class="time-line__entries-placeholder"></div>' + '<div class="time-line__indicator"></div>' + '</div>' + '</div>';
            };

            var getEntryElement = function getEntryElement(needsActivation, activated) {
                var icon = '';

                if (needsActivation) {
                    icon = getActivationCircle(activated);
                }

                return '<div class="entries-placeholder__entry">' + icon + '</div>';
            };

            var getActivationCircle = function getActivationCircle(unlocked) {
                var iconSrc = unlocked ? Icon.Daytimer.LOCK.OPENED : Icon.Daytimer.LOCK.CLOSED,
                    circleClass = "entry__activation-circle";

                if (unlocked) {
                    circleClass += " entry__activation-circle--unlocked";
                }

                return '' + '<div class="' + circleClass + '">' + ImageBox.getResourceImageWithClasses(iconSrc, 'activation-circle__icon') + '</div>';
            };

            var drawScale = function drawScale(timeLineElement) {
                var timeCanvas = timeLineElement[0];
                checkAndPrepareCanvasContextForRetina(timeCanvas);
                var ctx = timeCanvas.getContext("2d");
                var width = timeCanvas.clientWidth;
                var height = timeCanvas.clientHeight;
                ctx.beginPath();
                ctx.strokeStyle = Color.DAYTIMER_SCALE;
                ctx.lineWidth = 2;
                ctx.moveTo(1, 0);
                ctx.lineTo(1, height); // big steps

                ctx.lineWidth = 1;
                var i;

                for (i = 0; i <= 24; i++) {
                    if (i % 3 === 0) {
                        ctx.moveTo((width - 1) / 24 * i, 0);
                        ctx.lineTo((width - 1) / 24 * i, height);
                    }
                }

                ctx.stroke(); // small steps

                ctx.lineWidth = 0.5;

                for (i = 0; i < 24; i++) {
                    if (i % 3 !== 0) {
                        ctx.moveTo(width / 24 * i, 2);
                        ctx.lineTo(width / 24 * i, height);
                    }
                }

                ctx.stroke();
            };

            return {
                getContent: getContent,
                drawScale: drawScale,
                getEntryElement: getEntryElement
            };
        }(); //endregion Static

        constructor() {
            super(...arguments);
            this.boundTapHandler = this.showCalendarEntries.bind(this);
        }

        viewDidLoad() {
            var prms = super.viewDidLoad(...arguments);
            this.element.append($(DaytimerControlInfoView.Template.getContent()));
            this.elements = {
                bottomLabel: this.element.find(".daytimer-control-info-view__bottom-label"),
                timeLineEntries: this.element.find('.time-line__entries-placeholder'),
                timeLineIndicator: this.element.find('.time-line__indicator'),
                topLabel: this.element.find(".daytimer-control-info-view__top-label"),
                scale: this.element.find('.time-line__canvas')
            };
            this.openCalendarHandler = Hammer(this.element[0]);
            this.registerForResize();
            return prms;
        }

        viewWillAppear() {
            var promise = super.viewWillAppear(...arguments);
            DaytimerControlInfoView.Template.drawScale(this.elements.scale);
            this.openCalendarHandler.on(tapEvent(), this.boundTapHandler);
            this.timeIdentifier = SandboxComponent.getMiniserverTimeInfo(this, this._updateIndicatorPosition.bind(this), TimeValueFormat.MINUTES_SINCE_MIDNIGHT, TIME_LINE_UPDATE_INTERVAL);
            return promise;
        }

        viewDidAppear() {
            return super.viewDidAppear(...arguments).then(function () {
                DaytimerControlInfoView.Template.drawScale(this.elements.scale);

                this._updateIndicatorPosition(SandboxComponent.getMiniserverTimeInfo(null, null, TimeValueFormat.MINUTES_SINCE_MIDNIGHT));
            }.bind(this));
        }

        viewWillDisappear() {
            this.openCalendarHandler.off(tapEvent(), this.boundTapHandler);
            SandboxComponent.removeFromTimeInfo(this.timeIdentifier);
            return super.viewWillDisappear(...arguments);
        }

        destroy() {
            this.openCalendarHandler && this.openCalendarHandler.dispose();
            return super.destroy(...arguments);
        }

        /**
         * Returns an array containing the state ids this view is interested in. statesReceived will only be called
         * if one of the states specified in this array changes.
         * By default this method returns false, which means this view is interested in all states of the state cntr.
         * @return {Array|boolean}
         */
        getStateIDs() {
            return ["entries", "activeMode", "value", "daytimerState"];
        }

        receivedStates(states) {
            super.receivedStates(...arguments);
            this.processWhenVisible(this.updateTimeLine.bind(this, states));
            var txt = this.getTopLabelText(states);
            txt && this.elements.topLabel.text(txt);
            txt = this.getBottomLabelText(states);
            txt && this.elements.bottomLabel.text(txt);
            this.elements.bottomLabel.text();
            this.states = states;
        }

        getTopLabelText(states) {
            return null;
        }

        getBottomLabelText(states) {
            return states.activeModeName;
        }

        updateTimeLine(states) {
            this.elements.timeLineEntries.empty();
            var isOn = states.daytimerState === DaytimerControlEnums.DaytimerState.ON,
                needsActivation = states.daytimerState === DaytimerControlEnums.DaytimerState.NEEDS_ACTIVATION,
                timeLineWidth = this.elements.timeLineEntries[0].clientWidth,
                minutesAbsolute = 24 * 60,
                fragment = $(document.createDocumentFragment()),
                entries = this.getTimeLineEntries(states),
                entry,
                startPercent,
                endPercent,
                entryElement,
                activatedEntry,
                i;

            for (i = 0; i < entries.length; i++) {
                entry = entries[i];
                startPercent = entry.from / minutesAbsolute;
                endPercent = (minutesAbsolute - entry.to) / minutesAbsolute;
                activatedEntry = entry.needActivate && entry.from === states.currentTimeSpan.from && isOn;
                entryElement = $(DaytimerControlInfoView.Template.getEntryElement(entry.needActivate, activatedEntry));
                entryElement.css('left', startPercent * 100 + '%');
                entryElement.css('right', endPercent * 100 + '%');
                this.adoptEntryElement(entryElement, entry, states);
                fragment.append(entryElement);
            }

            this.elements.timeLineEntries.append(fragment);
            this.element.toggleClass('daytimer-control-info-view--active', isOn);
            this.element.toggleClass('daytimer-control-info-view--needs-activation', needsActivation);
        }

        getTimeLineEntries(states) {
            return this._getEntriesOfMode(states.entries, states.activeMode);
        }

        adoptEntryElement(elem, entry, states) {// nothing to do.
        }

        onResize() {
            // make sure the UI is updated asap, but not in a high frequency
            if (this.resizeTimeout) {
                return; // it'll update itself after the timer.
            }

            this.states && this.updateTimeLine(this.states);

            this._repositionIndicator(this._minutesSinceMidnight); // no timer running, update immediately
            // start a timer to avoid too many refreshes.


            this.resizeTimeout = setTimeout(function () {
                // worst case 2 updates where one was needed.
                this.resizeTimeout = null;
            }.bind(this), 200);
        }

        showCalendarEntries() {
            var target = DaytimerControlEnums.ScreenState.CALENDAR;
            this.ViewController.showState(target, null, {
                control: this.control
            });
        }

        /**
         * Updates the minutesSinceMidnight Attribute and calls repositionIndicator to update the UI too.
         * @param minutesSinceMidnight
         * @private
         */
        _updateIndicatorPosition(minutesSinceMidnight) {
            this._minutesSinceMidnight = minutesSinceMidnight;

            this._repositionIndicator(this._minutesSinceMidnight);
        }

        /**
         * Ensures the indicator is drawn at the proper position based on the current time and view size.
         * @param minutesSinceMidnight
         * @private
         */
        _repositionIndicator(minutesSinceMidnight) {
            var leftPos = minutesSinceMidnight / (24 * 60) * 100; // Line width indicator is 2 px so we subtract 1 px for perfect align

            GUI.animationHandler.setCssAttr(this.elements.timeLineIndicator, "left", 'min(max(10px, calc(' + leftPos + '% - 1px)), calc(100% - 10px))');
        }

        /**
         * Extracts a list entries for a specific mode index.
         * @param entries   the entries where the given modes entries are to be picked from
         * @param mode      the mode index number (-1,0,5,..)
         * @returns {*}     either the entries-array or FALSE if no entries exist.
         * @private
         */
        _getEntriesOfMode(entries, mode) {
            // mode 0 is "celebration day", so don't just compare with if(mode)!
            if (entries && (mode || mode === 0) && entries[mode]) {
                return entries[mode];
            }

            return false;
        }

        _sendCommand(cmd) {
            this.control.sendCommand(cmd);
        }

    };
});
