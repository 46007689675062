'use strict';

window.GUI = function (GUI) {
    class ZoneFavoriteSaveCell extends GUI.TableViewV2.Cells.BaseCell {
        constructor() {
            super(...arguments);
            this.element.addClass("zone-favorite-save-cell");
        }

        viewDidLoad() {
            super.viewDidLoad();
            this.isSelected = this.content.hasOwnProperty("isSelected") && this.content.isSelected === true;
            this.isUnused = this.content.hasOwnProperty(MediaEnum.Event.EMPTY_SLOT_ID);

            if (!this.elements) {
                this.elements = {};
            }

            this.contentPlaceholder.append(GUI.TableViewV2.Cells.ZoneFavoriteSaveCell.Template.getCellContent(this.content.title, this.content.doesOverwrite, this.isSelected));
            this.elements.iconPlaceholder = this.contentPlaceholder.find(".zone-favorite-save-cell__icon-placeholder");
            this.contentPlaceholder.toggleClass('empty', this.isUnused);
            this.contentPlaceholder.toggleClass('isSelected', this.isSelected);
            this.elements.iconPlaceholder.toggleClass('not-used', this.isUnused);
            this.elements.iconPlaceholder.toggleClass('selected', this.isSelected);
            this.element.toggleClass('selected', this.isSelected);

            if (this.isSelected) {
                this.elements.input = this.contentPlaceholder.find(".title-form__input");
            }
        }

        viewWillDisappear() {
            if (this.isSelected) {
                this.elements.input.blur(); // fix for iOS 10, otherwise the keyboard will appear after an animation
            }

            super.viewWillDisappear(...arguments);
        }

        onSelected() {
            if (!this.isSelected) {
                super.onSelected(...arguments);
            }
        }

        clickableAllowed() {
            return true;
        }

        getTitle() {
            if (this.isSelected) {
                return this.elements.input[0].value;
            } else {
                return this.content.title;
            }
        }

    }

    GUI.TableViewV2.Cells.ZoneFavoriteSaveCell = ZoneFavoriteSaveCell;

    GUI.TableViewV2.Cells.ZoneFavoriteSaveCell.Template = function () {
        var getCellContent = function getCellContent(title, overwrite, isSelected) {
            var overwriteDiv = '';

            if (overwrite) {
                overwriteDiv = '<div class="zone-favorite-save-cell__overwrite">' + '<div class="overwrite__title">' + _("media.item.zone-fav.overwrite").toUpperCase() + '</div>' + '</div>';
            }

            var titleDiv = '';

            if (isSelected) {
                titleDiv = '<form class="texts__title-form">' + '<input type="text" class="title-form__input text-overflow-ellipsis" ' + 'value="' + title + '" placeholder=""/>' + '</form>';
            } else {
                titleDiv = '<div class="texts__title">' + title + '</div>';
            }

            return '' + '<div class="zone-favorite-save-cell__icon-placeholder">' + '<div class="icon-placeholder__icon icon-placeholder__circle"></div>' + '</div>' + '<div class="zone-favorite-save-cell__texts">' + titleDiv + '</div>' + overwriteDiv + '';
        };

        return {
            getCellContent: getCellContent
        };
    }();

    GUI.TableViewV2.CellType.Special.ZONE_FAVORITE_SAVE = "ZoneFavoriteSaveCell";
    return GUI;
}(window.GUI || {});
