import ConnectionWaitingScreen from "../connectingWaiting/connectionWaitingScreen.jsx";
import {useEffect} from "react";

export default function InitialSearchScreen(props) {

    if ((props.route.params || {}).state !== WaitingState.MiniserverSearch) {
        props.navigation.setParams({
            state: WaitingState.MiniserverSearch
        })
    }

    useEffect(() => {
        let dereg = MiniserverFinder.on(MiniserverFinder.Event.SEARCH_FINISHED, searchFinished);
        MiniserverFinder.startMiniserverFinder();
        return dereg;
    }, []);

    const searchFinished = (ev, miniservers) => {
        if (miniservers.length === 1) {
            NavigationComp.connectTo(miniservers[0]);
        } else if (miniservers.length > 1) {
            NavigationComp.showMiniserverSearchScreen({
                miniservers: miniservers
            });
        } else {
            NavigationComp.showWelcomeScreen();
        }
    }

    return <ConnectionWaitingScreen {...props}/>
}
