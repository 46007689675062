import {createStackNavigator} from "@react-navigation/stack";
import {CommonActions, useFocusEffect} from '@react-navigation/native';
import {useState, useCallback, useEffect, useRef, useMemo} from "react";
import {
    LxPermissionHandler,
    navigatorConfig,
    LxReactDeviceSearchMiniserverSelection,
    LxReactDeviceSearchTechTagSelection,
    LxReactDeviceSearchExtensionSelection,
    LxReactDeviceSearchResults,
    LxReactDeviceSearchAssistant,
    LxReactDeviceSearchContext,
    useSetURLHook,
    LxReactSelectorScreen
} from "LxComponents";

const DEV_SEARCH_STORED_ROUTE = "-last-device-stored-route";

function LxReactDeviceSearchNavigator(props) {
    const getVerifiedSearchTypeParam = () => {
        let searchType = props.route.params.searchType;
        if (!ActiveMSComponent.isValidDeviceSearchType(searchType)) {
            console.error(LxReactDeviceSearchNavigator.name, "getVerifiedSearchTypeParam: " + searchType + " is not valid! " + "Assuming tree");
            searchType = DeviceManagement.TYPE.TREE;
        } else {
            Debug.DeviceSearch.Navigator && console.log(LxReactDeviceSearchNavigator.name, "getVerifiedSearchTypeParam: " + searchType + " is valid!");
        }

        return searchType;
    }
    const searchType = getVerifiedSearchTypeParam();
    const prepareForSearch = () => {
        return ActiveMSComponent.prepareForDeviceSearch(searchType);
    };
    const [searchId, setSearchId] = useState(prepareForSearch);
    const restoredRouteRef = useRef(null);


    props.navigation.setOptions(navigatorConfig({
        navigation: props.navigation,
        animationType: AnimationType.MODAL,
        headerShown: false
    }));

    // region storing routes via context callbacks
    const getStoredRoute = () => {
        let storedRouteString = PersistenceComponent.getLocalStorageItemWithTtl(searchType + DEV_SEARCH_STORED_ROUTE);
        let storedRoute = storedRouteString ? JSON.parse(storedRouteString) : [];
        if (storedRoute.length > 1) {
            setTimeout(() => {
                Debug.DeviceSearch.Navigator && console.log(LxReactDeviceSearchNavigator.name, "dispatch reset route to: ", storedRoute);
                const derivedSearchType = storedRoute[storedRoute.length - 1].params.searchType;
                ActiveMSComponent.setSearchType(derivedSearchType);
                restoredRouteRef.current = storedRoute;
                if(storedRoute.length > 1) {
                    // the prepareForDeviceSearch is called when a Tag is selected, when the route is restored we need to call it manually
                    ActiveMSComponent.prepareForDeviceSearch(derivedSearchType);
                }
                props.navigation.dispatch(CommonActions.reset({
                    index: storedRoute.length - 1,
                    routes: storedRoute
                }));
            },0);
        }
        return storedRoute;
    }
    const [] = useState(getStoredRoute);
    const routeStore = useRef([]);

    const addToRouteFn = useCallback((name, params, key) => {
        Debug.DeviceSearch.Navigator && console.log(LxReactDeviceSearchNavigator.name, "addToRouteFn " + name, JSON.stringify(params), key);
        if(!routeStore.current.find((routeObj) => routeObj.key === key)) {
            routeStore.current.push({name, params, key});
            triggerPersistRoute();
            Debug.DeviceSearch.Navigator && routeStore.current.forEach((routeObj, idx) => console.log(LxReactDeviceSearchNavigator.name, "       " + routeObj.name, JSON.stringify(routeObj.params)));
        }
    }, []);

    const updateRouteParamFn = useCallback((name, params) => {
        let updated = false;
        for (let i = routeStore.current.length - 1; i >= 0 && !updated; i--) {
            if (routeStore.current[i].name === name) {
                routeStore.current[i].params = { ...routeStore.current[i].params, ...params };
                updated = true;
            }
        }
        updated && triggerPersistRoute();
        Debug.DeviceSearch.Navigator && console.log(LxReactDeviceSearchNavigator.name, "updateRouteParamFn " + name, JSON.stringify(params));
    }, []);

    const rmFromRouteFn = useCallback((name, params, key) => {
        let filtered = false;
        for (let i = routeStore.current.length - 1; i >= 0 && !filtered; i--) {
            if (routeStore.current[i].key === key) {
                routeStore.current.splice(i, 1);
                filtered = true;
            }
        }
        filtered && triggerPersistRoute();
        if(filtered) {
            const activeSearchType = ActiveMSComponent.getSearchType();
            if(activeSearchType !== searchType) {
                ActiveMSComponent.setSearchType(routeStore.current[routeStore.current.length - 1].params.searchType);
            }
        }

        Debug.DeviceSearch.Navigator && console.log(LxReactDeviceSearchNavigator.name, "rmFromRouteFn " + name);
        Debug.DeviceSearch.Navigator && routeStore.current.forEach((routeObj, idx) => console.log(LxReactDeviceSearchNavigator.name, "       " + routeObj.name, JSON.stringify(routeObj.params)));

    }, []);
    const storeRouteTimeoutRef = useRef(null);
    const triggerPersistRoute = () => {
        if (!storeRouteTimeoutRef.current) {
            storeRouteTimeoutRef.current = setTimeout(persistRoute, 1000);
        }
    }
    const persistRoute = () => {
        Debug.DeviceSearch.Navigator && console.log(LxReactDeviceSearchNavigator.name, "persistRoute", JSON.stringify(routeStore.current));
        PersistenceComponent.setLocalStorageItemWithTtl(searchType + DEV_SEARCH_STORED_ROUTE, JSON.stringify(routeStore.current), DeviceManagement.DATA_TTL);
        storeRouteTimeoutRef.current = null;
    }
    // endregion


    useEffect(() => {
        ActiveMSComponent.setSearchType(searchType);
        return () => {
            if (storeRouteTimeoutRef.current) {
                clearTimeout(storeRouteTimeoutRef.current);
                (async => {
                    persistRoute();
                })();
            }
            ActiveMSComponent.stopDeviceSearch();
        }
    }, []);

    useFocusEffect(
        useCallback(() => {
            let unblockFn = SandboxComponent.blockAmbientAndScreensaver();
            return () => {
                unblockFn && unblockFn();
                routeStore.current.some((routeObj) => {
                    // initially only the extension serialNr was used, but in newer versions the requestTarget is the
                    // weapon of choice to uniquely identify the extensions (especially multiple internal tree branches
                    // on client gateway installations.
                    let target = routeObj.params.extension?.requestTarget ?? false;
                    if (!target) {
                        target = routeObj.params.extension?.serialNr ?? false
                    }
                    if (target) {
                        ActiveMSComponent.setDeviceIdentify(target);
                        return true;
                    }
                })
            }
        }, [])
    )

    const DeviceSearchStack = createStackNavigator();

    const [isGateway, setIsGateway] = useState(ActiveMSComponent.getGatewayType() === GatewayType.GATEWAY);
    useEffect(() => {
        setIsGateway(ActiveMSComponent.getGatewayType() === GatewayType.GATEWAY);
    }, [ActiveMSComponent.getGatewayType()]);

    const handleStateEvent = ({ data: { state } }) => {
        if(!restoredRouteRef.current) {
            return;
        }

        const numberOfRoutes = restoredRouteRef.current.length;
        if(state.routes.length === numberOfRoutes) {
            return;
        } else if (state.routes.length < numberOfRoutes) {
            const lastRoute = state.routes[state.routes.length - 1];
            if(lastRoute.params.searchType !== ActiveMSComponent.getSearchType()) {
                ActiveMSComponent.switchToSearchableParent(lastRoute.params.searchType).then((parkedSearchTag) => {
                    if(parkedSearchTag) {
                        setSearchId(ActiveMSComponent.prepareForDeviceSearch(lastRoute.params.searchType));
                        ActiveMSComponent.setSearchType(lastRoute.params.searchType);
                    }
                    
                }).catch((err) => {
                    Debug.DeviceSearch.Navigator && console.error(LxReactDeviceSearchNavigator.name, "handleStateEvent: ", err);
                    setSearchId(ActiveMSComponent.prepareForDeviceSearch(lastRoute.params.searchType));
                    ActiveMSComponent.setSearchType(lastRoute.params.searchType);
                }).finally(() => {
                    restoredRouteRef.current = null;
                });
            }
        }
    }

    return (
        <LxReactDeviceSearchContext.Provider
            value={{
                addToRoute: addToRouteFn,
                updateRouteParams: updateRouteParamFn,
                removeFromRoute: rmFromRouteFn,
            }}
        >
            <DeviceSearchStack.Navigator screenListeners={{
                state: handleStateEvent
            }}>
                {Feature.GENERIC_DEVICE_SEARCH ? (
                    <DeviceSearchStack.Screen
                        name={LxReactDeviceSearchTechTagSelection.name}
                        component={LxReactDeviceSearchTechTagSelection}
                        initialParams={{
                            ...props.route.params,
                            searchId: searchId,
                            isGateway
                        }}
                    />
                ) : null}
                {isGateway ? (
                    <DeviceSearchStack.Screen
                        name={LxReactDeviceSearchMiniserverSelection.name}
                        component={LxReactDeviceSearchMiniserverSelection}
                        initialParams={{
                            ...props.route.params,
                            searchId: searchId,
                        }}
                    />
                ) : null}
                <DeviceSearchStack.Screen
                    name={LxReactDeviceSearchExtensionSelection.name}
                    component={LxReactDeviceSearchExtensionSelection}
                    initialParams={{
                        ...props.route.params,
                        searchId: searchId,
                    }}
                />
                <DeviceSearchStack.Screen
                    name={LxReactDeviceSearchResults.name}
                    component={LxReactDeviceSearchResults}
                    initialParams={{
                        ...props.route.params,
                        searchId: searchId,
                    }}
                />
                <DeviceSearchStack.Screen
                    name={LxReactDeviceSearchAssistant.name}
                    component={LxReactDeviceSearchAssistant}
                    initialParams={{
                        ...props.route.params,
                        searchId: searchId,
                    }}
                />
                <DeviceSearchStack.Screen
                    name={LxReactSelectorScreen.name}
                    component={LxReactSelectorScreen}
                />
            </DeviceSearchStack.Navigator>
        </LxReactDeviceSearchContext.Provider>
    );
}

export default (props) => {

    props.navigation.setOptions(navigatorConfig({
        navigation: props.navigation,
        animationType: AnimationType.MODAL
    }));


    useSetURLHook({
        urlPart: "deviceSearch/" + props.route.params.searchType,
        nPop: 2
    });

    useEffect(() => {
        ActiveMSComponent.prepareForDeviceSearch();
    }, []);

    const permTitle = useMemo(() => {
       return ActiveMSComponent.getTitleForDeviceSearchType(props.route.params.searchType);
    }, [props.searchType])

    return (<LxPermissionHandler
        requestingName={permTitle}
        requiredPermissions={ActiveMSComponent.getRequiredDeviceSearchPermissions()}>
        <LxReactDeviceSearchNavigator {...props} />
    </LxPermissionHandler>);

}