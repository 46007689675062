import { ScreenState } from "./alarmClockControlEnums";

export default class AlarmClockControlStatesSrc {
    static getStateSections(control, states, navigation) {
        let sections = [],
            section = {},
            rows = [],
            sortedEntries = states.sortedEntries;

        if (states.isActive || !sortedEntries) {
            return sections
        } else {
            sortedEntries.forEach((entry) => {
                rows.push({
                    content: entry,
                    type: GUI.TableViewV2.CellType.Special.ALARM_CLOCK_ENTRY,
                    action: this.showEntry.bind(this, entry, control, navigation),
                    onSwitchChanged: this.toggleEntry.bind(this, control)
                });
            });
        }

        section.rows = rows;
        sections.push(section);

        return sections;
    }

    static showEntry(entry, control, navigation) {
        navigation.navigate(ScreenState.EDIT_ENTRY, { entry })
    }

    static toggleEntry(control, value, section, row, tableView) {
        let entries = control.getStates().sortedEntries,
            entry = entries[row],
            // We need to send the daily flag instead of the operating modes if this is a NightLight Touch
            // Modes must be either be a numerical value as a String or an array of numerical values separated by a ","
            // entry.daily is a Bool +entry.daily gives us a number
            modes = entry.nightLight ? (+entry.daily).toString() : entry.modes.toString();
        control.sendCommand(Commands.format(Commands.ALARM_CLOCK.ALARM_ENTRY, entry.id, entry.name, entry.alarmTime, value, modes));
    }
}