'use strict';

define("PowerUnitControl", ["Control", "PowerUnitControlEnums"], function (Control, PowerUnitControlEnums) {
    return class PowerUnitControl extends Control {
        constructor() {
            super(...arguments);
            var tracker = this.getTracker();
            tracker.name = _("controls.power-unit.history"); // parse outputs

            var outputMap = {},
                idPos,
                outputObj,
                namedCount = 0;
            this.details.outputs.forEach((outputStr, idx) => {
                outputObj = {
                    _raw: outputStr
                };
                idPos = outputStr.lastIndexOf("(");
                outputObj.configured = idPos > 1;
                outputObj.id = outputStr.substring(idPos + 1, outputStr.length - 1);

                if (idPos < 0 || !outputObj.id) {
                    // ensure the ID is provided!
                    outputObj.id = idx + 1 + "";
                }

                outputObj.locationId = _("controls.power-unit.output-fuse-id", {
                    number: outputObj.id
                });

                if (outputObj.configured) {
                    namedCount++;
                    outputObj.name = outputStr.substring(0, idPos - 1);
                } else {
                    outputObj.name = null;
                }

                outputMap[outputObj.id] = cloneObject(outputObj);
            }); // if no outputs have been named, show them all with a default name (= locationId).

            if (namedCount === 0) {
                Object.keys(outputMap).forEach(outputId => {
                    outputMap[outputId].name = outputMap[outputId].locationId;
                });
            } // should always be 7, safety first.


            this.details._numOutputs = this.details.outputs.length;
            this.details.outputs = outputMap;
        }

        getNumberOfOutputs() {
            return this.details._numOutputs; // should always be 7, safety first.
        }

        getOutputObj(idx) {
            return this.details.outputs[idx + ""];
        }

        getOutputName(idx) {
            return this.details.outputs[idx + ""].name;
        }

        getSupplyMode() {
            if (!this.details.hasOwnProperty("supplyMode")) {
                // assume both
                return PowerUnitControlEnums.SupplyMode.SUPPLY_AND_BATTERY;
            } else {
                return this.details.supplyMode;
            }
        }

        getTracker() {
            var subControls = this.subControls,
                trackerUUID = Object.keys(subControls)[0];
            return this.subControls[trackerUUID];
        }

    };
});
