'use strict';

define("LightControlCommandSrc", ["ControlCommandSrc", "LightControlEnums"], function (ControlCommandSrc, LightControlEnums) {
    return class LightControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            var hasSingleSwitch = control.hasSingleSwitch(),
                offText = hasSingleSwitch ? _('turn-off') : _('controls.lightcontroller.all-off'),
                onText = hasSingleSwitch ? _('turn-on') : _('controls.lightcontroller.all-on'),
                cmds = [],
                scenes = control.isLightControl ? states.scenes : control.details.sceneList,
                isAllOn = control.isLightControl ? states.activeSceneNr === LightControlEnums.SceneNumbers.ALL_ON : states.activeSceneNr === Object.keys(scenes).length + 1;

            if (control.hasSingleSwitch()) {
                if (states.isOn) {
                    cmds.push(this.createCommand(offText, Commands.LIGHTCONTROL.ALL_OFF, null, states.activeSceneNr === LightControlEnums.SceneNumbers.ALL_OFF));
                } else {
                    cmds.push(this.createCommand(onText, Commands.LIGHTCONTROL.ALL_ON, null));
                }
            } else {
                cmds.push(this.createSelectable(offText, Commands.LIGHTCONTROL.ALL_OFF, null, states.activeSceneNr === LightControlEnums.SceneNumbers.ALL_OFF));

                if (scenes) {
                    Object.keys(scenes).forEach(function (sceneNr) {
                        cmds.push(this.createSelectable(scenes[sceneNr], Commands.format(Commands.LIGHTCONTROL.SELECT_SCENE, sceneNr), null, states.activeSceneNr === parseInt(sceneNr)));
                    }.bind(this));
                }

                cmds.push(this.createSelectable(onText, Commands.LIGHTCONTROL.ALL_ON, null, isAllOn));
            }

            return cmds;
        }

    };
});
