import * as React from "react"
import Svg, { Path } from "react-native-svg"

function TimerStop(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <Path d="M2.707 1.293a1 1 0 00-1.414 1.414l20 20a1 1 0 001.414-1.414l-20-20zm18.357 16.942l-1.444-1.444A9.001 9.001 0 0013 3.054V4.5a1 1 0 11-2 0V2a1 1 0 011-1c6.075 0 11 4.925 11 11 0 2.315-.715 4.463-1.936 6.235zM1 12c0-2.314.715-4.463 1.937-6.235L4.38 7.208A8.954 8.954 0 003 12a9 9 0 0013.791 7.62l1.444 1.444A10.949 10.949 0 0112 23C5.925 23 1 18.075 1 12z" />
        </Svg>
    )
}

export default TimerStop
