'use strict';
/*
    Description of the content: (Base content is excluded here, please check the base implementation for further information)
    content:
        prominent
            title           -> Visible in the center of the cell (Displays the temperature)
            actions         -> Visible on the center right side of the cell (Array of actions, action example below)
            state           -> Visible on the center left side of the cell
                iconSrc     -> the icon to show on the left side
                color       -> the color of the icon
                actionFn    -> the fn to call when the state icon is clicked


    action:                 -> Example of the action structure
        icon                -> Icon to display as the button
        action              -> Action to execute on buttonReleased
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.IRCV2TemperatureCell = "IRCV2TemperatureCell";
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var IrcTemplate = function () {
            var getProminentContainer = function getProminentContainer() {
                return '' + '<div class="prominent-container__left-container"></div>' + '<div class="prominent-container__center-container">' + '<div class="center-container__title"></div>' + '</div>' + '<div class="prominent-container__right-container"></div>';
            };

            var getStateElement = function getStateElement(iconSrc) {
                return '' + '<div class="left-container__state">' + ImageBox.getResourceImageWithClasses(iconSrc, "state__icon") + '</div>';
            };

            return {
                getProminentContainer: getProminentContainer,
                getStateElement: getStateElement
            };
        }();

        class IRCV2TemperatureCell extends GUI.TableViewV2.Cells.DetailedContentBaseCell {
            constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
                super(...arguments);
            }

            viewDidLoad() {
                super.viewDidLoad(...arguments);
                this.elements.prominentContainer.leftContainer = this.contentPlaceholder.find('.prominent-container__left-container');
                this.elements.prominentContainer.center = {
                    element: this.contentPlaceholder.find('.prominent-container__center-container'),
                    title: this.contentPlaceholder.find('.center-container__title')
                };
                this.elements.prominentContainer.rightContainer = this.contentPlaceholder.find('.prominent-container__right-container');
            }

            applyProminentContainerContent() {
                var prms = [true];
                this.elements.prominentContainer.center.title.text(this.content.prominent.title);

                if (this.content.prominent.actions) {
                    this.content.prominent.actions.forEach(function (action, idx) {
                        if (action.icon && action.action) {
                            var button = new GUI.LxButton(this, this._getPrimaryButton(action, idx), Color.BUTTON_GLOW, null, true);
                            button.onButtonReleased = action.action.bind(this);
                            prms.push(this.appendSubview(button, this.elements.prominentContainer.rightContainer));
                        }
                    }.bind(this));
                }

                if (this.content.prominent.state && this.content.prominent.state.iconSrc) {
                    this.elements.prominentContainer.leftContainer.append(IrcTemplate.getStateElement(this.content.prominent.state.iconSrc));
                    this.elements.prominentContainer.leftContainer.state = {
                        container: this.elements.prominentContainer.leftContainer.find('.left-container__state'),
                        icon: this.elements.prominentContainer.leftContainer.find('.state__icon')
                    };

                    if (this.content.prominent.state.actionFn) {
                        this.buttons = this.buttons || {};

                        if (!this.buttons.stateBtn) {
                            this.buttons.stateBtn = new GUI.LxButton(this, this.elements.prominentContainer.leftContainer.state.container);
                            prms.push(this.addToHandledSubviews(this.buttons.stateBtn));
                        }

                        this.buttons.stateBtn.onButtonReleased = this.content.prominent.state.actionFn;
                    }

                    if (this.content.prominent.state.color) {
                        this.elements.prominentContainer.leftContainer.state.icon.css("fill", this.content.prominent.state.color);
                    } else {
                        this.elements.prominentContainer.leftContainer.state.icon.css("fill", "");
                    }
                }

                return Q.all(prms);
            }

            clearProminentContent() {
                super.clearProminentContent(...arguments);
                this.elements.prominentContainer.center.title.empty();
                this.elements.prominentContainer.rightContainer.empty();
                this.buttons && this.buttons.stateBtn && this.removeSubview(this.buttons.stateBtn);
                this.elements.prominentContainer.leftContainer.empty();
            }

            getProminentContainer() {
                return IrcTemplate.getProminentContainer();
            }

            /**
             * Redefines the clickable element, only the center element should be clickable in this case,
             * it prevents false positives when clicking on the "+" and "-" buttons which would show the statistic screen
             * @returns {jQuery}
             * @private
             */
            _getClickableElement() {
                return this.elements.prominentContainer.center.element;
            }

            handleTopRightButtonDelegate() {
                this.delegate.onTemperatureScreenOpen && this.delegate.onTemperatureScreenOpen.call(this.delegate, this.sectionIdx, this.rowIdx, this.tableView);
            }

            _getPrimaryButton(action, idx) {
                return '<div class="' + "action-button button-" + idx + '">' + '       ' + ImageBox.getResourceImageWithClasses(action.icon) + '   </div>';
            }

        }

        GUI.TableViewV2.Cells.IRCV2TemperatureCell = IRCV2TemperatureCell;
    }
    return GUI;
}(window.GUI || {});
