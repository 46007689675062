import {useNavigation} from "@react-navigation/native";
import {useEffect} from "react";


export default function useBeforeNavBack(beforeNavBack) {
    const navigation = useNavigation();

    useEffect(() => {
        return navigation.addListener('beforeRemove', (e) => {
            beforeNavBack(e);
        });
    }, [navigation, beforeNavBack]);

}