'use strict';

define(['IRoomControllerV2ControlEnums', 'IRoomControlEnums'], function (IRoomControllerV2ControlEnums, IRoomControlEnums) {
    return class IRoomControllerV2SettingsScreen extends GUI.TableViewScreen {
        constructor(details) {
            super($("<div/>"));
            this.control = details.control;
        }

        // Private methods
        titleBarText() {
            return _('settings');
        }

        reloadTable() {
            this.tableContent = [{
                rows: [{
                    content: {
                        title: _('opmode.title'),
                        disclosureIcon: true,
                        clickable: true
                    },
                    action: this._showState.bind(this, IRoomControllerV2ControlEnums.ScreenState.OPERATING_MODES)
                }, {
                    content: {
                        title: _('autopilot.rule.event.times'),
                        disclosureIcon: true,
                        clickable: true
                    },
                    action: this._showTimesScreen.bind(this)
                }, {
                    content: {
                        title: _('controls.irc.temperatures'),
                        disclosureIcon: true,
                        clickable: true
                    },
                    action: this._showState.bind(this, IRoomControllerV2ControlEnums.ScreenState.TEMPERATURES)
                }]
            }];
            return super.reloadTable(...arguments);
        }

        _showState(state) {
            this.ViewController.showState(state, null, {
                control: this.control
            });
        }

        _showTimesScreen() {
            this.ViewController.showState(IRoomControllerV2ControlEnums.ScreenState.TIMES, null, {
                control: this.control.subControls[Object.keys(this.control.subControls)[0]]
            });
        }

    };
});
