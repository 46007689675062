'use strict';

define(["AudioZoneV2ControlEnums"], function (AudioZoneV2ControlEnums) {
    class SettingsLibraryAddOrEdit extends GUI.TableViewScreenV2 {
        //region Private
        ERROR = MusicServerEnum.NAS_ERROR;
        LIB_OBJ_KEYS = ["server", "guest", "username", "password", "folder", "name", "type"]; //endregion Private

        constructor(details) {
            super($('<div />'));
            this.guest = false;
            this.control = details.control;
            this.libObj = details.libObj;
            this.originalLibObj = {};
            this.name = ""; // A default name will be applied when adding a new library

            this.hasExplicitName = false;
            this.type = "cifs"; // This is the default filesystem for SMB based shares

            if (this.libObj) {
                if (!this.libObj.hasOwnProperty("type")) {
                    this.libObj.type = this.type;
                }

                this.LIB_OBJ_KEYS.forEach(function (key) {
                    if (this.libObj.hasOwnProperty(key)) {
                        this[key] = this.libObj[key];
                        this.originalLibObj[key] = this.libObj[key];
                    }
                }.bind(this));
            }
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            return _("media.library.network-share");
        }

        reloadTable() {
            this.tableContent = [{
                rows: [{
                    content: {
                        title: _("media.library.server"),
                        placeholder: _("media.library.server.placeholder"),
                        value: this.server
                    },
                    type: GUI.TableViewV2.CellType.INPUT,
                    textChanged: function textChanged(section, row, tableView, value) {
                        this.server = value;

                        this._onInputChanged();

                        this._autoGenerateNameIfNeeded();
                    }.bind(this)
                }, {
                    content: {
                        title: _("media.library.path"),
                        placeholder: _("media.library.path.placeholder.mandatory"),
                        value: this.folder
                    },
                    type: GUI.TableViewV2.CellType.INPUT,
                    textChanged: function textChanged(section, row, tableView, value) {
                        this.folder = value;

                        this._onInputChanged();

                        this._autoGenerateNameIfNeeded();
                    }.bind(this)
                }, {
                    content: {
                        title: _("description"),
                        placeholder: _("media.library.network-share"),
                        value: this.name
                    },
                    type: GUI.TableViewV2.CellType.INPUT,
                    textChanged: function textChanged(section, row, tableView, value) {
                        this.hasExplicitName = !!value;
                        this.name = value;

                        this._onInputChanged();
                    }.bind(this)
                }]
            }, {
                rows: [{
                    content: {
                        title: _("username"),
                        placeholder: _("media.library.creds.required"),
                        value: this.username
                    },
                    type: GUI.TableViewV2.CellType.INPUT,
                    textChanged: function textChanged(section, row, tableView, value) {
                        this.username = value;

                        this._onInputChanged();
                    }.bind(this)
                }, {
                    content: {
                        title: _("password"),
                        placeholder: _("media.library.creds.required"),
                        value: this.password,
                        type: GUI.LxInputEnum.Type.PASSWORD
                    },
                    type: GUI.TableViewV2.CellType.INPUT,
                    textChanged: function textChanged(section, row, tableView, value) {
                        this.password = value;

                        this._onInputChanged();
                    }.bind(this)
                }]
            }, {
                rows: [{
                    content: {
                        title: _("media.library.creds.guest"),
                        active: this.guest
                    },
                    type: GUI.TableViewV2.CellType.SWITCH,
                    onSwitchChanged: this._onGuestChange.bind(this)
                }]
            }, {
                rows: [{
                    content: {
                        title: this.libObj ? _("apply") : _("media.preferences.services.editor.add"),
                        disabled: true
                    },
                    type: GUI.TableViewV2.CellType.CENTER,
                    action: this._addLibrary.bind(this)
                }]
            }];
            return super.reloadTable(...arguments).then(function () {
                this._onGuestChange(this.guest);
            }.bind(this));
        }

        _onGuestChange(value) {
            this.guest = value;
            [0, 1].forEach(function (row) {
                if (this.guest) {
                    this.tableView.cellForSectionAndRow(1, row).input.setValue("");
                    delete this.username;
                    delete this.password;
                }

                this.tableView.cellForSectionAndRow(1, row).setEnabled(!this.guest);
            }.bind(this));

            this._onInputChanged();
        }

        _onInputChanged() {
            var enableAddButton = true; // Also check for the following properties when adding a new NAS share

            if (!this.libObj) {
                if (this.server) {
                    if (!this.guest) {
                        enableAddButton = this.username && this.password;
                    } else {
                        enableAddButton = true;
                    }

                    if (!this.folder) {
                        enableAddButton = false;
                    }
                } else {
                    enableAddButton = false;
                }
            }

            this.tableView.cellForSectionAndRow(3, 0).setEnabled(enableAddButton);
        }

        _getLibObject() {
            var libObj = {};
            this.LIB_OBJ_KEYS.forEach(function (key) {
                if (this.hasOwnProperty(key)) {
                    if (key === "password") {
                        libObj[key] = this.guest ? "" : this.control.audioserverComp.encryptWithPublicKey(this[key]);
                    } else {
                        libObj[key] = this[key];
                    }
                }
            }.bind(this));
            return diff(this.originalLibObj, libObj);
        }

        _addLibrary() {
            if (this.server) {
                var newLib = this._getLibObject(),
                    payload = btoa(unescape(encodeURIComponent(JSON.stringify(newLib)))),
                    cmd;

                if (!!this.libObj) {
                    cmd = Commands.format(MusicServerEnum.Commands.SETTINGS.LIBRARY.EDIT, this.libObj.id, payload);
                } else {
                    cmd = Commands.format(MusicServerEnum.Commands.SETTINGS.LIBRARY.ADD, payload);
                }

                NavigationComp.showWaitingFor(this.control.audioserverComp.sendMediaServerCommand(cmd), _("media.popup.library.rescanning.title")).done(function (res) {
                    if (res.data.configerror === this.ERROR.NONE) {
                        this.ViewController.navigateBack(false, res.data);
                    } else {
                        this._handleError(res.data);
                    }
                }.bind(this));
            }
        }

        _autoGenerateNameIfNeeded() {
            if (!this.hasExplicitName) {
                if (this.folder && this.server) {
                    this.name = this.folder + " (" + this.server + ")";
                } else if (this.folder) {
                    this.name = this.folder;
                } else {
                    this.name = this.server;
                }
            }

            this.tableView.cellForSectionAndRow(0, 2).input.inputField.val(this.name);
        }

        _handleError(data) {
            var errorTitl = _("audio-server.library.nas.error.generic.title"),
                errorMsg = null;

            switch (data.configerror) {
                case this.ERROR.ADD_SELF:
                    errorMsg = _("audio-server.library.nas.error.add-self.message");
                    break;

                case this.ERROR.DUPLICATE:
                    errorMsg = _("audio-server.library.nas.error.duplicate.message");
                    break;

                case this.ERROR.NON_EXISTENT:
                    errorMsg = _("audio-server.library.nas.error.non-existent.message");
                    break;

                case this.ERROR.UNREACHABLE:
                    errorMsg = _("audio-server.library.nas.error.not-reachable.message");
                    break;

                case this.ERROR.INVALIDE_CREDS:
                    errorMsg = _("audio-server.library.nas.error.invalide-creds.message");
                    break;
            }

            NavigationComp.showErrorPopup(false, errorTitl, errorMsg);
        }

    }

    Controls.AudioZoneV2Control.SettingsLibraryAddOrEdit = SettingsLibraryAddOrEdit;
    return Controls.AudioZoneV2Control.SettingsLibraryAddOrEdit;
});
