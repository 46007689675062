'use strict';

define("PulseAtControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class PulseAtControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            return [this.createCommand(_("activate"), Commands.PUSHBUTTON.PULSE)];
        }

    };
});
