'use strict';

define("SequentialControlCommandSrc", ["ControlCommandSrc"], function (ControlCommandSrc) {
    return class SequentialControlCommandSrc extends ControlCommandSrc {
        constructor() {
            super(...arguments);
        }

        getCommands(control, states) {
            return control.details.sequences.map(sequence => this.createCommand(sequence.name, lxFormat(Commands.SEQUENTIAL.TRIGGER, sequence.id), null));
        }

    };
});
