'use strict';

define("MsShortcutControl", [
    "Control",
    "IconLib",
    "LxComponents"
], function (
    Control,
    {
        default: Icons
    },
    { MsShortcutBadge }
) {
    return class MsShortcutControl extends Control {
        constructor() {
            super(...arguments);
            this.dbgName = "MsShortcutControl@" + this.name;

            if (this.isInTrust) {
                this._unregFn = ActiveMSComponent.registerForTrustStructure(this._trustStructureUpdated.bind(this));
            }
            this.details.localUrl = (this.details.localUrl && this.details.localUrl.sanitizeHTML()) || null;
            this.details.remoteUrl = (this.details.remoteUrl && this.details.remoteUrl.sanitizeHTML()) || null;
            this.details.serialNr = (this.details.serialNr && this.details.serialNr.toUpperCase().sanitizeHTML()) || null;
        }

        get isInTrust() {
            return !!this.details.trust;
        }

        get serialNo() {
            if (nullEmptyString(this.details.serialNr)) {
                return this.details.serialNr.toUpperCase();
            }
            return null;
        }

        get localUrl() {
            return this.details.localUrl;
        }

        get remoteUrl() {
            return this.details.remoteUrl;
        }

        get msObject() {
            const obj = {
                msName: this.getName()
            };

            if (this.serialNo) {
                obj.serialNo = this.serialNo;
            }
            if (this.remoteUrl) {
                obj.remoteUrl = this.remoteUrl;
            }
            if (this.localUrl) {
                obj.localUrl = this.localUrl;
            }

            return obj;
        }

        get reactIcon() {
            return MsShortcutBadge;
        }

        get useReactIconInContent() {
            return true;
        }

        destroy() {
            this._unregFn && this._unregFn();
            return super.destroy();
        }

        getIcon() {
            if (this.defaultIcon) {
                return this.defaultIcon;
            } else {
                return Icon.MINISERVER_SCHEME;
            }
        }

        getStateTextShort() {
            return _("ms-shortcut.title")
        }

        getButton0() {
            return {
                iconSrc: Icon.Application.APP_LINK,
                reactIcon: Icons.Link,
                command: this.open.bind(this)
            };
        }

        open() {
            Debug.Control.MsShortcut && console.log(this.dbgName, "open");
            if (this.isInTrust && this._fromTrust) {
                Debug.Control.MsShortcut && console.log(this.dbgName, " >> trust-ms from TrustExt!");
                return ActiveMSComponent.switchToTrustedMiniserver(this._fromTrust);
            } else {
                return this._regularSwitchTo();
            }
        }

        _regularSwitchTo() {
            Debug.Control.MsShortcut && console.log(this.dbgName, "_regularSwitchTo");
            const stored = PersistenceComponent.getMiniserver(this.serialNo),
                target = stored || this.msObject;

            if (!target.localUrl && !target.remoteUrl) {
                if (target.serialNo) {
                    Debug.Control.MsShortcut && console.log(this.dbgName, "   > neither local nor remote url, assume it's using cloudDns/remoteConnect");
                    target.remoteUrl = "dns.loxonecloud.com/" + target.serialNo;
                } else {
                    console.error(this.name, "_regularSwitchTo failed - neither a local nor a remote url! " + JSON.stringify(target));
                    return Q.reject("Missing local and remote url, cannot connect!");
                }
            }

            Debug.Control.MsShortcut && console.log(this.dbgName, "   > changing to target ms: " + target.msName);
            Debug.Control.MsShortcut && console.log(this.dbgName, "                      user: " + target.activeUser);
            Debug.Control.MsShortcut && console.log(this.dbgName, "                trustToken: " + !!target.trustToken);
            Debug.Control.MsShortcut && console.log(this.dbgName, "                     token: " + !!target.token);
            Debug.Control.MsShortcut && console.log(this.dbgName, "                  password: " + !!target.password);
            Debug.Control.MsShortcut && console.log(this.dbgName, "                  localUrl: " + target.localUrl);
            Debug.Control.MsShortcut && console.log(this.dbgName, "                 remoteUrl: " + target.remoteUrl);
            if(PlatformComponent.getPlatformInfoObj().platform === PlatformType.Webinterface) {
                const urlBase = target.remoteUrl || target.localUrl;
                const username = target.activeUser || ActiveMSComponent.getActiveMiniserver().activeUser;
                try {
                    let url = urlBase;
                    if(isCloudDnsUrl(url) && !url.startsWith("https://") && !url.startsWith("http://")) {
                        url = new URL(`https://${urlBase}`);
                        url.searchParams.set("username", username);
                    }

                    const targetUrl = new URL(url);
                    NavigationComp.openWebsite(targetUrl, undefined, () => {});
                } catch (e) {
                    NavigationComp.showPopup({
                        title: _('error'),
                        message: _('media.app-outdated.info', { server: urlBase }),
                        icon: Icon.CAUTION,
                        color: Color.RED,
                        buttonOk: true,
                        buttons: [{
                            title: _('user.shared-data.connection.external'),
                            color: Color.BRAND_COLOR,
                        }]
                    }).then(buttonIdx => {
                        if (buttonIdx === 0) {
                            if (target.serialNo) {
                                NavigationComp.openWebsite(`https://dns.loxonecloud.com/${target.serialNo}?username=${encodeURIComponent(username)}`, undefined, () => {});
                            }
                        }
                    });
                    console.error(this.dbgName, "Error while switching to miniserver", e);
                }
            } else {
                return NavigationComp.switchToMiniserver(target);
            }
        }

        _trustStructureUpdated(newStructure) {
            this._fromTrust = ActiveMSComponent.getTrustedMiniserverList().find(ms => {
                return ms.serialNo.toUpperCase() === this.serialNo;
            });
            Debug.Control.MsShortcut && console.log(this.dbgName, "_trustStructureUpdated: available=" + !!this._fromTrust);
        }
    };
});
