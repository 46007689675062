import Svg, { Path } from "react-native-svg"

function News(props) {
    return (
        <Svg isreact="true"
            height={24}
            viewBox="0 0 24 24"
            width={24}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M23.5 2a.5.5 0 01.5.5v19a.5.5 0 01-.5.5H.5a.5.5 0 01-.5-.5v-19A.5.5 0 01.5 2zM23 3H1v18h22zm-3.5 13a.5.5 0 01.09.992L19.5 17h-6a.5.5 0 01-.09-.992L13.5 16zm-9 0a.5.5 0 01.09.992L10.5 17h-6a.5.5 0 01-.09-.992L4.5 16zm0-2a.5.5 0 01.09.992L10.5 15h-7a.5.5 0 01-.09-.992L3.5 14zm10 0a.5.5 0 01.09.992L20.5 15h-7a.5.5 0 01-.09-.992L13.5 14zm-10-2a.5.5 0 01.09.992L10.5 13h-7a.5.5 0 01-.09-.992L3.5 12zm10 0a.5.5 0 01.09.992L20.5 13h-7a.5.5 0 01-.09-.992L13.5 12zm0-7a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-17a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5zM20 6H4v2h16z"
                fillRule="evenodd"
            />
        </Svg>
    )
}

export default News
