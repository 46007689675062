import React, {useContext} from "react";
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import PropTypes from "prop-types";
import Icons from "IconLib";
import FavoritesScreenV2 from "../favorites/favoritesScreenV2.jsx";
import HouseScreen from "../house/houseScreen.jsx";
import RoomsScreen from "../rooms/roomsScreen.jsx";
import CategoriesScreen from "../categories/categoriesScreen.jsx";
import {TransitionPresets} from "@react-navigation/stack";
import {
    LxTabBar,
    TopNavigator,
    LxReactCardScreenTopNavigator,
    navigatorConfig,
    AmbientContext
} from "LxComponents"
import globalStyles from "GlobalStyles";
import TrustNavigator from "../trust/trustNavigator";
import useTrustStructure from "../trust/useTrustStructure";
import BackToPrevMiniserver from "../../../react-comps/BackToPrevMiniserver";

const screenOptions = (...params) => {
    return {
        ...TopNavigator.forDefault(),
        ...TransitionPresets.ModalFadeTransition,
        ...navigatorConfig(LxReactCardScreenTopNavigator(...params)),
        freezeOnBlur: true
    }
}

function ActiveMiniserverScreen({navigation, route}) {
    const {isAmbientMode} = useContext(AmbientContext)
    const trustStructure = useTrustStructure();

    // By showing the backToPrevMiniserver in the navigator - it's ensured that the react navigation's
    // safe-area logic doesn't mess up. If the backToPrev would be simply rendered "wrapped" around this
    // screen, the header of the screens inside this navigation would additionally add the safe-area offsets.
    navigation.setOptions({
        ...navigation.options,
        headerShown: true,
        header: (a) => {
            return <BackToPrevMiniserver />
        }
    })

    const Tab = createBottomTabNavigator(),
        groupTitles = ActiveMSComponent.getStructureManager().getCustomGroupTitles(),
        tabs = [
            {
                component: FavoritesScreenV2,
                options: {
                    title: _('favorites.title'),
                    icon: Icons.Star,
                }
            },
            {
                component: HouseScreen,
                options: {
                    title: _('central'),
                    icon: Icons.Central,

                }
            },
            {
                component: RoomsScreen,
                options: {
                    title: groupTitles[GroupTypes.ROOM],
                    icon: Icons.Rooms,
                    headerShown: false
                }
            },
            {
                component: CategoriesScreen,
                options: {
                    title: groupTitles[GroupTypes.CATEGORY],
                    icon: Icons.Category,
                    headerShown: false
                }
            }
        ];

    if (!PlatformComponent.isWebInterface() && trustStructure && !trustStructure.noTrusts) {
        tabs.push({
                component: TrustNavigator,
                options: {
                    title:  trustStructure.multipleTrusts ? _("trust.tab-name_plural") : _("trust.tab-name"),
                    icon: Icons.Trust,
                    headerShown: false
                }
        });
    }

    return <Tab.Navigator sceneContainerStyle={{
            backgroundColor: isAmbientMode ? "transparent" : globalStyles.colors.black
        }} screenOptions={(params) => screenOptions(params, isAmbientMode)} initialRouteName={ScreenState.FavoritesV2}
                       tabBar={props => <LxTabBar {...props} isAmbientMode={isAmbientMode}/>}>
            {
                tabs.map(tab => {
                    return <Tab.Screen name={tab.component.name} key={tab.component.name} {...tab}/>
                })
            }
        </Tab.Navigator>
}

ActiveMiniserverScreen.propTypes = {
    screenState: PropTypes.string
}

export default ActiveMiniserverScreen;
