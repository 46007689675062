'use strict';

class BackupAndSyncRestoredScreen extends GUI.OnboardingScreen {
    //region Static
    static getShownIds() {
        return [];
    }

    static shouldShow(id) {
        return true;
    } //endregion Static


    constructor(details) {
        super($("<div />"));
        this.continueDef = details.continueDef;
        this.iconSrc = details.iconSrc;
        this.iconColor = details.iconColor;
        this.title = details.title;
        this.message = details.message;
        this.continueBtnText = details.continueBtnText;
    }

    getAnimation() {
        return AnimationType.NONE;
    }

    continueBtnAction() {
        this.ViewController.dismiss();
    }

    onTopLeftButtonTapped() {
        this.ViewController.dismiss();
    }

}

GUI.BackupAndSyncRestoredScreen = BackupAndSyncRestoredScreen;
