'use strict';

define(["../../../react-comps/VirginAppScreen.jsx"], function (VirginAppScreen) {
    return class WelcomeScreen extends GUI.Screen {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return $('<div>' + '   <div class="welcome-screen__choices-container">' + '       ' + getContainer(Icon.MINISERVER_SCHEME, _('welcome.miniserver.own'), _('welcome.miniserver.own.message'), _('luring.no.upgradeHome.button')) + '       ' + getContainer(Icon.PHONE, _('welcome.miniserver.none'), _('welcome.miniserver.none.message'), _('luring.no.testTheApp.button')) + '   </div>' + '</div>');
            };

            var getContainer = function getContainer(iconSrc, title, message, btnTitle) {
                return '<div class="choices-container__choice">' + '   <div class="choice__icon-placeholder">' + '       ' + ImageBox.getResourceImageWithClasses(iconSrc, "icon-placeholder__icon") + '   </div>' + '   <div class="choice__title">' + title + '</div>' + '   <div class="choice__description">' + message + '</div>' + '   <div class="choice__btn clickable">' + btnTitle + '</div>' + '</div>';
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        static ReactComp = VirginAppScreen.default;
        //reactHeaderShown = false;

        constructor() {
            super(WelcomeScreen.Template.getTemplate());
        }

        viewDidLoad() {
            var baseCall = super.viewDidLoad(...arguments);
            var choices = this.element.find(".choices-container__choice");
            this.elements.choice0 = $(choices[0]);
            this.elements.choice1 = $(choices[1]);
            var buttons = this.element.find('.choice__btn');
            this.buttons = {
                homepageButton: new GUI.LxButton(this, buttons[0]),
                demoButton: new GUI.LxButton(this, buttons[1])
            };
            this.addToHandledSubviews(this.buttons.homepageButton);
            this.addToHandledSubviews(this.buttons.demoButton);

            this.buttons.homepageButton.onButtonTapped = function openHomepage() {
                NavigationComp.showArchive();
            };

            this.buttons.demoButton.onButtonTapped = function () {
                NavigationComp.connectToDemoMiniserver();
            };

            return baseCall;
        }

        getLeftIcon() {
            return Icon.Buttons.SETTINGS;
        }

        titleBarAction() {
            this.ViewController.showState(ScreenState.Menu)
        }

        viewWillAppear() {
            var baseCall = super.viewWillAppear(...arguments);

            if (HD_APP) {
                var height = Math.max(this.elements.choice0.height(), this.elements.choice1.height());
                this.elements.choice0.height(height);
                this.elements.choice1.height(height);
            }

            return baseCall;
        }

        getAnimation() {
            return AnimationType.FADE;
        }

        hasHistory() {
            return false;
        }

    };
});
