import {useContext, useEffect, useMemo} from "react";
import {
    ControlContext,
    LxReactTableView,
    navigatorConfig,
    useSetURLHook,
    LxControlContentContainer
} from "LxComponents";
import globalStyles from "GlobalStyles";
import {useNavigation} from "@react-navigation/native";

function LxSettingsScreen(props) {
    const {control, states} = useContext(ControlContext)
    const navigation = useNavigation();

    useSetURLHook({
        urlPart: "SettingsScreen"
    })

    useEffect(() => {
        navigation.setOptions({
            ...navigatorConfig({
                title: _("settings"),
                animationType: AnimationType.MODAL
            })
        })
    }, [])

    const getSnoozeTimerSection = () => {
        if (!Feature.ALARM_CLOCK_SNOOZE_TIMER_SETTING) {
            return null;
        }

        if (control.details.snoozeDurationConnected) {
            return {
                rows: [getCellForSnoozeDurationValue()]
            };
        } else {
            return {
                rows: [getCellForSnoozeDurationSlider()]
            };
        }
    }

    const getCellForSnoozeDurationValue = () => {
        return {
            type: GUI.TableViewV2.CellType.GENERAL,
            content: {
                title: _("controls.alarmClock.snooze.duration"),
                subtitle: _("controls.leaf.settings.locked-desc"),
                disclosureText: getValueForSnoozeCell(states.snoozeDuration),
                disclosureColor: Color.STATE_INACTIVE
            }
        };
    }

    const getCellForSnoozeDurationSlider = () => {
        return {
            type: GUI.TableViewV2.CellType.SLIDER,
            content: {
                title: _("controls.alarmClock.snooze.duration"),
                value: states.snoozeDuration,
                step: 60,
                minValue: 60,
                maxValue: 30 * 60,
                formatWithHTML: "%s",
                valueColor: globalStyles.colors.stateActive,
                minIconSrc: Icon.MINUS,
                maxIconSrc: Icon.PLUS,
                cmd: Commands.ALARM_CLOCK.SET_SNOOZE_DURATION,
                preventMinIconScale: true
            },
            sliderDragged: onSliderValueChanged.bind(this),
            sliderClicked: onSliderValueChanged.bind(this),
            userfriendlyValueForSlider: (cell, section, row, tableView, value) => {
                return getValueForSnoozeCell(value);
            }
        };
    }

    const getCellForBrightnessSlider = (isActive) => {
        return {
            type: GUI.TableViewV2.CellType.SLIDER,
            content: {
                title: isActive ? _("controls.alarmClock.display.title.active") : _("controls.alarmClock.display.title.inactive"),
                //step: 100/16, // Only 16 steps are possible due to hardware limitations
                value: states.deviceSettings[isActive ? "brightActive" : "brightInactive"],
                valueColor: globalStyles.colors.stateActive,
                format: "%i%%",
                minIconSrc: Icon.MINUS,
                maxIconSrc: Icon.PLUS,
                cmd: isActive ? Commands.ALARM_CLOCK.SET_BRIGHTNESS_ACTIVE : Commands.ALARM_CLOCK.SET_BRIGHTNESS_INACTIVE,
                preventMinIconScale: true
            },
            sliderDragged: onSliderValueChanged.bind(this),
            sliderClicked: onSliderValueChanged.bind(this)
        };
    }

    const getCellForBrightnessValue = (isActive) => {
        let value = states.deviceSettings[isActive ? "brightActive" : "brightInactive"];
        return {
            type: GUI.TableViewV2.CellType.GENERAL,
            content: {
                title: isActive ? _("active") : _("inactive"),
                subtitle: _("controls.leaf.settings.locked-desc"),
                disclosureText: value + "%",
                disclosureColor: globalStyles.colors.stateInActive
            }
        };
    }

    const getSoundSelectionCell = (isOverwritten) => {
        if (isOverwritten) {
            return {
                content: {
                    title: _("sound"),
                    subtitle: _("controls.leaf.settings.locked-desc"),
                    disclosureText: Object.values(control.details.wakeAlarmSounds).find((sound) => {
                        return sound.id === states.wakeAlarmSoundSettings.sound;
                    }).name,
                    disclosureColor: globalStyles.colors.green
                }
            };
        } else {
            return {
                content: {
                    title: _("sound"),
                    disclosureText: Object.values(control.details.wakeAlarmSounds).find((sound) => {
                        return sound.id === states.wakeAlarmSoundSettings.sound;
                    }).name,
                    disclosureColor: globalStyles.colors.green,
                    clickable: true,
                    disclosureIcon: Icon.DISCLOSURE
                },
                action: onChooseSound.bind(this)
            };
        }
    }

    const getCellForVolumeSlider = () => {
        return {
            type: GUI.TableViewV2.CellType.SLIDER,
            content: {
                title: _("media.volume"),
                minValue: 5,
                value: states.wakeAlarmSoundSettings.volume,
                valueColor: globalStyles.colors.stateActive,
                format: "%i%%",
                minIconSrc: Icon.MINUS,
                maxIconSrc: Icon.PLUS,
                preventMinIconScale: true
            },
            sliderDragEnded: (cell, section, row, tableView, value) => {
                control.sendCommand(Commands.format(Commands.ALARM.SET_ALARM_VOLUME, value));
            },
            sliderClicked: (cell, section, row, tableView, value) => {
                control.sendCommand(Commands.format(Commands.ALARM.SET_ALARM_VOLUME, value));
            }
        };
    }

    const getCellForVolumeValue = () => {
        return {
            type: GUI.TableViewV2.CellType.GENERAL,
            content: {
                title: _("media.volume"),
                subtitle: _("controls.leaf.settings.locked-desc"),
                disclosureText: states.wakeAlarmSoundSettings.volume + "%",
                disclosureColor: globalStyles.colors.stateInActive
            }
        };
    }

    const getSlopingCell = (isOverwritten) => {
        if (isOverwritten) {
            return {
                type: GUI.TableViewV2.CellType.GENERAL,
                content: {
                    title: _("fade-in"),
                    subtitle: _("controls.leaf.settings.locked-desc"),
                    disclosureText: states.wakeAlarmSoundSettings.isSloping ? _("on") : _("off"),
                    disclosureColor: globalStyles.colors.stateInActive
                }
            };
        } else {
            return {
                type: GUI.TableViewV2.CellType.SWITCH,
                content: {
                    title: _("fade-in"),
                    active: states.wakeAlarmSoundSettings.isSloping
                },
                onSwitchChanged: (value) => {
                    control.sendCommand(Commands.format(Commands.ALARM.SET_ALARM_SLOPING, value));
                }
            };
        }
    }

    const onSliderValueChanged = (cell, section, row, tableView, value) => {
        control.sendCommand(Commands.format(cell.content.cmd, value), Commands.Type.OVERRIDE);
    }

    /**
     * Displays the sound selector and sends the selected sound
     * @private
     */
    const onChooseSound = (cell) => {
        var def = Q.defer(),
            details = {
                options: control.details.wakeAlarmSounds.map((sound) => {
                    return {
                        title: sound.name,
                        clickable: true,
                        id: sound.id,
                        selected: states.wakeAlarmSoundSettings.sound === sound.id
                    };
                }),
                title: _("sound"),
                mode: GUI.SelectorScreenMode.NORMAL,
                deferred: def
            };
        navigation.navigate(ScreenState.SelectorScreen, details);
        def.promise.then((result) => {
            // Show the change to the user
            cell.setDisclosureText(result[0].option.title);
        }, null, (result) => {
            // Directly send the selected sound when the user selects the cell
            control.sendCommand(Commands.format(Commands.ALARM.SET_ALARM_SOUND, result.option.id));
        });
    }

    const getAlarmSoundSettingsSection = () => {
        let section = {
            rows: []
        };
        section.rows.push(getSoundSelectionCell(control.details.wakeAlarmSoundConnected));

        if (!control.details.wakeAlarmVolumeConnected) {
            section.rows.push(getCellForVolumeSlider());
        } else {
            section.rows.push(getCellForVolumeValue());
        }

        section.rows.push(getSlopingCell(control.details.wakeAlarmSlopingConnected));
        return section;
    }

    const settingsContent = useMemo(() => {
        let tableContent = [],
            snoozeTimerSection = getSnoozeTimerSection(),
            strongTitleSection = {
                headerStrongTitle: _("controls.alarmClock.touchNightLight.product-name"),
                rows: []
            },
            displayBrightnessSection = {
                headerTitle: _("controls.alarmClock.display"),
                rows: [],
                footerTitle: _("controls.alarmClock.display.desc", {
                    productName: _("controls.alarmClock.touchNightLight.product-name")
                })
            },
            alarmSoundSection = {
                headerTitle: _("controls.alarmClock.touchNightLight.settings.beeper"),
                rows: [{
                    type: GUI.TableViewV2.CellType.SWITCH,
                    content: {
                        title: _("controls.alarmClock.touchNightLight.settings.beeper"),
                        active: states.deviceSettings.beepUsed
                    },
                    onSwitchChanged: (value) => control.sendCommand(Commands.format(Commands.ALARM_CLOCK.SET_BEEP_ON, value))
                }],
                footerTitle: _("controls.alarmClock.touchNightLight.settings.beeper-footer", {
                    beepSetting: _("controls.alarmClock.touchNightLight.settings.beeper"),
                    productName: _("controls.alarmClock.touchNightLight.product-name")
                })
            };

        if (!control.details.brightInactiveConnected) {
            displayBrightnessSection.rows.push(getCellForBrightnessSlider(false));
        } else {
            displayBrightnessSection.rows.push(getCellForBrightnessValue(false));
        }

        if (!control.details.brightActiveConnected) {
            displayBrightnessSection.rows.push(getCellForBrightnessSlider(true));
        } else {
            displayBrightnessSection.rows.push(getCellForBrightnessValue(true));
        }

        snoozeTimerSection && tableContent.push(snoozeTimerSection);
        strongTitleSection && tableContent.push(strongTitleSection);
        displayBrightnessSection && tableContent.push(displayBrightnessSection);
        alarmSoundSection && tableContent.push(alarmSoundSection);

        if (states.deviceSettings.beepUsed && Feature.ALARM_CLOCK_ADVANCED_SOUND_OPTIONS) {
            tableContent.push(getAlarmSoundSettingsSection());
        }


        return tableContent;
    }, [states])

    return <LxControlContentContainer>
        {settingsContent && <LxReactTableView tableContent={settingsContent} styles={styles.tableView} showSectionSeparator={false}/>}
    </LxControlContentContainer>
}

const getValueForSnoozeCell = (value) => {
    if (value > 0) {
        let fragments = LxDate.getTimeFragementsFromSeconds(value),
            durIds = LxDate.durationIdentifiers();

        let result;

        if (fragments.seconds) {
            result = fragments.minutes + "&nbsp;" + durIds.minutes + "&nbsp;" + fragments.seconds + "&nbsp;" + durIds.seconds;
        } else {
            result = fragments.minutes + "&nbsp;" + durIds.minutes;
        }
        return result.replaceAll("&nbsp;", " ")
    } else {
        return _("off");
    }
}

const styles = {
    tableView: {
        List: {
            width: "100%"
        },
        SectionHeader: {
            StrongTitle: {
                ...globalStyles.textStyles.title2.bold,
                color: globalStyles.colors.text.primary
            }
        }
    }
}

export default LxSettingsScreen