'use strict';

define('AnalogSwimmingMachineScreen', ['ControlActionSliderScreen', 'PoolControlEnums'], function (ControlActionSliderScreen, PoolControlEnums) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var SLIDER_RATE = 1.0; // seconds

    var SWIM_MIN = 0,
        SWIM_MAX = 1,
        STEP_WIDTH = 0.05;
    return class AnalogSwimmingMachineScreen extends ControlActionSliderScreen {
        constructor() {
            super(...arguments);
            this.antiGhostTimer = new AntiGhostTimer(this._updateSubtitle.bind(this));
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.setTitle(_("pool.control.swimming-machine"));
        }

        destroy() {
            this.antiGhostTimer && this.antiGhostTimer.resetAntiGhostTimer();
            super.destroy(...arguments);
        }

        shouldDisplayStateIcons() {
            return false;
        }

        /**
         * This function returns the config of the slider, it is crucial to implement it!
         * @param states The control states
         * @returns {{min, max, step, antiGhostTimer: boolean}}
         */
        getSliderConfig(states) {
            return {
                min: SWIM_MIN,
                max: SWIM_MAX,
                step: STEP_WIDTH,
                antiGhostTimer: true,
                updateRate: SLIDER_RATE
            };
        }

        getStateIDs() {
            return ["swimmingMachine"];
        }

        receivedStates(states) {
            // do NOT pass the states on to the subclass, otherwise it would adopt the state texts, icons and
            // so on based on the stateContainer of the pool, which is not what we want.
            this.sliderActionView.setPosition(states.swimmingMachine);
            this._canUpdateUI() && this._updateSubtitle(states.swimmingMachine);
        }

        getAdditionalSection() {
            return null;
        }

        // Private
        _sendValue(pos) {
            this._updateSubtitle(pos);

            this.antiGhostTimer.fire();
            this.sendCommand(Commands.format(Commands.POOL.SET_SWIMMING_MACHINE, pos), Commands.Type.OVERRIDE);
        }

        _canUpdateUI() {
            return this.antiGhostTimer && !this.antiGhostTimer.isAntiGhostTimerActive();
        }

        _updateSubtitle(state) {
            if (!state && state !== 0) {
                state = this.control.getStates().swimmingMachine;
            }

            this.sliderActionView.setPosition(state);
            this.setSubtitle(lxFormat("%d %%", state * 100));
        }

    };
});
