'use strict';

define("SystemSchemeControl", ["Control"], function (Control) {
    return class SystemSchemeControl extends Control {

        get imagePrms() {
            if (this.details.imagePath) {
                return this._checkStoredImageVersion();
            } else {
                return Q.reject("No imagePath specified!");
            }
        }

        constructor() {
            super(...arguments);
            // the uuids of the controls may not be unique accross different miniservers (e.g. when copying the program file)
            this.versionFileName = ActiveMSComponent.getActiveMiniserverSerial() + "@" + this.uuidAction + '_Image';
        }

        /**
         * Returns an icon for this control type, that Icon will be used if no defaultIcon is set by loxone config.
         * @returns {Icon}
         */
        getControlIcon() {
            return Icon.SystemScheme.CONTROL;
        }

        /**
         * If the main control doesn't support states, report that here
         * @returns {*}
         */
        supportsStates() {
            var supportsStates = false;
            var mainControl;

            if (this.details.mainControl) {
                mainControl = ActiveMSComponent.getStructureManager().getControlByUUID(this.details.mainControl);
                supportsStates = mainControl ? mainControl.supportsStates() : false; // e.g. EIB Jalousie doesn't have states
            }

            return supportsStates;
        }

        /**
         * Checks the stored image version, if the image isn't up to date, it'll delete the stored image.
         * @returns {*}
         * @private
         */
        _checkStoredImageVersion() {
            Debug.Control.SystemScheme && console.log(this.name, "_checkStoredImageVersion");
            return PersistenceComponent.loadFile(this.versionFileName, DataType.STRING).then(
                this._handleLoadedImageVersion.bind(this),
                this._handleLoadedImageVersion.bind(this)
            );
        }

        _handleLoadedImageVersion(imgVersion) {
            Debug.Control.SystemScheme && console.log(this.name, "_handleLoadedImageVersion: " + JSON.stringify(imgVersion));
            let purgePrms = Q.resolve(), // We have to parse the imageVersion from the loxApp, because we store the version as string
                wasPurged = false,
                imageLoader = ImageLoader.shared(ImageLoader.DOWNLOADER.DL_SOCK);


            if (imgVersion !== this.details.imageVersion.toString()) {
                wasPurged = true;
                Debug.Control.SystemScheme && console.log(this.name, "   image outdated, delete image");
                purgePrms = imageLoader.purge(this.details.imagePath);
            }

            return purgePrms.then(() => {
                if (wasPurged) {
                    Debug.Control.SystemScheme && console.log(this.name, "_updateStoredImageVersion: " + JSON.stringify(this.details.imageVersion));
                    PersistenceComponent.saveFile(this.versionFileName, this.details.imageVersion, DataType.STRING);
                }
                return imageLoader.get(this.details.imagePath, moment().add(1, "month").unix, ImageLoader.RESPONSE_TYPE.DATA_URI);
            });
        }

    };
});
