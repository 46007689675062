'use strict';

define(["AudioZoneControlEnums", "MediaBrowserBase", "AudioZoneSpotifyHelper"], function (AudioZoneControlEnums, MediaBrowserBase, AudioZoneSpotifyHelper) {
    class MediaBrowserService extends MediaBrowserBase {
        //region Static
        static isRadio(identifier) {
            return Object.values(MediaEnum.Radios).indexOf(identifier) > -1;
        }

        static getCellFromContentTypeItem(item, details, contentType, isPlaylist, isBrowsable, actionClb, buttonTappedClb, vc, disabled) {
            var baseCell;

            if (item.tag === MediaEnum.Tag.ARTIST) {
                baseCell = Controls.AudioZoneControl.MediaBrowserBase.getCellFromContentTypeItem.call(this, item, details, contentType, false, isBrowsable, actionClb, buttonTappedClb, vc);
                baseCell.content.class = baseCell.content.class || "";
                baseCell.content.class += " base-cell--rounded-icon";
            } else {
                baseCell = Controls.AudioZoneControl.MediaBrowserBase.getCellFromContentTypeItem.apply(this, arguments);
            }

            return baseCell;
        }

        static getCellButtonIconSrc(item, contentType, vc) {
            return Controls.AudioZoneControl.MediaBrowserBase.getCellButtonIconSrc.apply(this, arguments);
        }

        static getCellTypeForItemCellAtIndex(item, contentType) {
            if (item.tag === MediaEnum.Tag.ARTIST) {
                return GUI.TableViewV2.CellType.GENERAL;
            } else {
                return Controls.AudioZoneControl.MediaBrowserBase.getCellTypeForItemCellAtIndex.apply(this, arguments);
            }
        }

        static getIconObjForItem(item, identifier) {
            var baseIconObj = Controls.AudioZoneControl.MediaBrowserBase.getIconObjForItem.apply(this, arguments);

            if (this.isRadio(identifier) && MediaServerComp.isFileTypeBrowsable(item.type)) {
                if (item.cmd === MediaEnum.Radios.WORLD) {
                    baseIconObj.highRes = Icon.AudioZone.NEW.GLOBE;
                    baseIconObj.lowRes = Icon.AudioZone.NEW.GLOBE;
                } else if (item.cmd === MediaEnum.Radios.MUSIC) {
                    baseIconObj.highRes = Icon.AudioZone.NEW.GENRE;
                    baseIconObj.lowRes = Icon.AudioZone.NEW.GENRE;
                } else {
                    baseIconObj.highRes = Icon.AudioZone.NEW.STREAM;
                    baseIconObj.lowRes = Icon.AudioZone.NEW.STREAM;
                }

                baseIconObj.color = window.Styles.colors.green;
            } else if (identifier === MediaEnum.Service.SPOTIFY) {
                if (item.hasOwnProperty("id")) {
                    switch (item.id.replace(/[^\d]/g, "")) {
                        // MusicServers running v1 software will have some additional information in the id tag. Replace it to get the sole id
                        case MediaEnum.Spotify.TYPES.FEATURES:
                            baseIconObj.highRes = Icon.AudioZone.NEW.CLOCK_FIRE;
                            baseIconObj.lowRes = Icon.AudioZone.NEW.CLOCK_FIRE;
                            break;

                        case MediaEnum.Spotify.TYPES.NEW_RELEASE:
                            baseIconObj.highRes = Icon.AudioZone.NEW.CLOCK;
                            baseIconObj.lowRes = Icon.AudioZone.NEW.CLOCK;
                            break;

                        case MediaEnum.Spotify.TYPES.CATEGORIES:
                            baseIconObj.highRes = Icon.AudioZone.NEW.GENRE;
                            baseIconObj.lowRes = Icon.AudioZone.NEW.GENRE;
                            break;

                        case MediaEnum.Spotify.TYPES.MY_PLAYLISTS:
                            baseIconObj.highRes = Icon.AudioZone.NEW.PLAYLIST;
                            baseIconObj.lowRes = Icon.AudioZone.NEW.PLAYLIST;
                            break;

                        case MediaEnum.Spotify.TYPES.TRACKS:
                        case MediaEnum.Spotify.TYPES.TRACKS_LEGACY:
                            baseIconObj.highRes = Icon.AudioZone.NEW.PODCAST;
                            baseIconObj.lowRes = Icon.AudioZone.NEW.PODCAST;
                            break;

                        case MediaEnum.Spotify.TYPES.ALBUMS:
                            baseIconObj.highRes = Icon.AudioZone.NEW.CD;
                            baseIconObj.lowRes = Icon.AudioZone.NEW.CD;
                            break;

                        case MediaEnum.Spotify.TYPES.ARTISTS:
                            baseIconObj.highRes = Icon.AudioZone.NEW.MIC_2;
                            baseIconObj.lowRes = Icon.AudioZone.NEW.MIC_2;
                            break;

                        case MediaEnum.Spotify.TYPES.LIBRARY:
                            baseIconObj.highRes = Icon.AudioZone.NEW.LIBRARY;
                            baseIconObj.lowRes = Icon.AudioZone.NEW.LIBRARY;
                            break;

                        default:
                            baseIconObj.rounded = item.tag === MediaEnum.Tag.ARTIST;
                    }

                    baseIconObj.color = window.Styles.colors.green;
                }
            }

            return baseIconObj;
        } //endregion Static


        //region Getter
        get desc() {
            if (MediaBrowserService.isRadio(this.identifier)) {
                return _("media.service.webradio");
            } else {
                return _("media.popup.spotify.title");
            }
        } //endregion Getter


        constructor(details) {
            super(...arguments);

            if (MediaBrowserService.isRadio(this.identifier)) {
                this.legacySearchIdentifier = MediaEnum.SearchLoc.RADIO;
            } else {
                this.legacySearchIdentifier = MediaEnum.SearchLoc.SPOTIFY;
            }
        }

        setViewController() {
            super.setViewController(...arguments);
            this.spotifyHelper = new AudioZoneSpotifyHelper(this.ViewController, this);

            if (!this.ViewController.handleOnSubtitleTapped) {
                this.ViewController.handleOnSubtitleTapped = this.spotifyHelper.showSpotifySelectorContextMenu.bind(this.spotifyHelper, this.ViewController);
            }
        }

        viewControllerSubtitleText() {
            if (!MediaBrowserService.isRadio(this.identifier)) {
                return this.username;
            }

            return null;
        }

        onContentTypeReload(reason, contentType) {
            if (reason === MediaEnum.ReloadCause.USER_CHANGED) {
                // Spotify specific event
                return Controls.AudioZoneControl.SpotifyAccountHelper.getCurrentAccountId(this.control.uuidAction).then(function (id) {
                    var user = Controls.AudioZoneControl.SpotifyAccountHelper.getSpotifyUserForId(id);

                    if (user) {
                        this.username = user.user;
                        this.details.username = this.username;

                        this.ViewController._checkTitlebar();

                        return MediaServerComp.invalidateContentCachesOf(contentType, this.identifier, MediaEnum.ReloadCause.CONTENT_UPDATED);
                    }
                }.bind(this));
            } else {
                return super.onContentTypeReload(...arguments);
            }
        }

        getTableContent() {
            var baseTableContent = super.getTableContent(...arguments),
                customSectionClass = this.contentTypes.join(" ") + " ";

            if (!this.receivedContent) {
                customSectionClass += this.LOADING_SECTION_CLASS;
            } // Only show the "add" cell if we don't display a Playlist


            if (this._shouldOfferNewPlaylist()) {
                baseTableContent = [{
                    customClass: customSectionClass,
                    rows: [{
                        content: {
                            clickable: true,
                            title: _("media.playlist.create"),
                            leftIconSrc: Icon.TitleBar.PLUS,
                            leftIconColor: window.Styles.colors.green,
                            leftIconBgEnabled: true,
                            disclosureIcon: false
                        },
                        action: this.createPlaylist.bind(this)
                    }]
                }].concat(baseTableContent);
            }

            return baseTableContent;
        }

        getSectionIdxOffset() {
            return +this._shouldOfferNewPlaylist();
        }

        getMediaInfo(controlType) {
            if (MediaBrowserService.isRadio(this.identifier)) {
                this.lastSelectedItem.uid = this.lastSelectedItem.uid || this.identifier + "/" + this.username;
                this.lastSelectedItem.cmd = MediaEnum.Service.MUSIC;
                return {
                    service: this.lastSelectedItem
                };
            } else {
                var baseInfo = super.getMediaInfo(...arguments);
                baseInfo.service.cmd = MediaEnum.Service.SPOTIFY;
                return baseInfo;
            }
        }

        getMediaId(controlType) {
            if (MediaBrowserService.isRadio(this.identifier)) {
                return this.lastSelectedItem.root || this.lastSelectedItem.id;
            } else {
                return super.getMediaId(...arguments);
            }
        }

        createPlaylist() {
            var playlistCnt = this.contentTypeItems[MediaEnum.MediaContentType.SERVICE].all.length,
                playlistPlaceholder = _("media.playlist.save.default-name", {
                    number: playlistCnt + 1
                }),
                id = this.getMediaInfo(this.lastSelectedItem.contentType);

            return MediaServerComp.showNamePopup(_("media.playlist.create"), _("create"), playlistPlaceholder).then(function (plName) {
                return MediaServerComp.createContainer(this.lastSelectedItem.contentType, id, plName).then(function (newPlaylistItem) {
                    // Don't navigate into the newly created playlist if we are in the Playlist picker
                    if (this.ViewController instanceof GUI.AddMediaViewControllerPlaylistPicker) {
                        return Q(true);
                    } else {
                        return this.handleOnItemCellClicked(newPlaylistItem, newPlaylistItem.contentType);
                    }
                }.bind(this));
            }.bind(this));
        }

        _shouldOfferNewPlaylist() {
            if (this.USE_REUSING) {
                return false;
            } else {
                if (!MediaBrowserService.isRadio(this.identifier) && this.lastSelectedItem.id === MediaEnum.Spotify.TYPES.MY_PLAYLISTS) {
                    return !MediaServerComp.isFileTypePlayable(this.lastSelectedItem.type, this.lastSelectedItem.contentType);
                } else {
                    return false;
                }
            }
        }

    }

    Controls.AudioZoneControl.MediaBrowserService = MediaBrowserService;
    return Controls.AudioZoneControl.MediaBrowserService;
});
