import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Sum(props) {
    return (
        <Svg isreact="true"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.109.546A1 1 0 015 0h13a1 1 0 110 2H6.964l6.845 9.412a1 1 0 010 1.176L6.964 22H18a1 1 0 110 2H5a1 1 0 01-.809-1.588L11.764 12 4.19 1.588A1 1 0 014.11.546z"
            />
        </Svg>
    )
}

export default Sum
