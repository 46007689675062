'use strict';


define(["LxComponents"], function ({LxReactDeviceSearchNavigator}) {
    return class DeviceSearchViewController extends GUI.ModalViewController {

        static ReactComp = LxReactDeviceSearchNavigator;

        constructor(details) {
            super();
            this.liveSearchState = null;
            this.details = details || {};
            this.searchType = details.searchType;
            ActiveMSComponent.setSearchType(this.searchType);
            ActiveMSComponent.prepareForDeviceSearch();
            this._currentDeviceId = ActiveMSComponent.getDeviceIdentifier();
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                return this.showState(ScreenState.SelectExtensionScreen, null, this.details);
            }.bind(this));
        }

        getPermissions() {
            var permissions = [{
                permission: MsPermission.DEVICE_MANAGEMENT,
                revoke: true
            }];

            if (SandboxComponent.checkPermission(MsPermission.EXPERT_MODE)) {
                permissions.push({
                    permission: MsPermission.EXPERT_MODE,
                    revoke: true
                });
            }

            return permissions;
        }

        /**
         * If this returns true, the screen will ensure that the screensaver won't appear as long as it's visible.
         * @returns {boolean}
         */
        getShouldBlockScreenSaver() {
            return true;
        }

        destroyOnBackNavigation() {
            return true;
        }

        destroy() {
            Debug.DeviceSearch.General && console.log("destroy: unregisterForStateChangesForUUID");
            SandboxComponent.unregisterForStateChangesForUUID(GLOBAL_UUID, this);

            if (this.popupPromise) {
                NavigationComp.removePopup(this.popupPromise);
            }

            super.destroy(...arguments);
        }

        startSearchOnExtension(searchParam) {
            Debug.DeviceSearch.General && console.log("startSearchOnExtension: " + this.isSearching);
            return ActiveMSComponent.startDeviceSearch(searchParam).then(function () {
                Debug.DeviceSearch.General && console.log("startSearchOnExtension: started search on extension: " + searchParam);
                SandboxComponent.registerForStateChangesForUUID(GLOBAL_UUID, this, this._globalStatesReceived.bind(this));
            }.bind(this));
        }

        _globalStatesReceived(states) {
            if (!this.name) {
                console.warn("ATTENTION!!: this.name is null in deviceSearchScreen!"); // TODO-goelzda: Remove for Release

                return;
            }

            Debug.DeviceSearch.General && console.log(this.name, "_globalStatesReceived: deviceId: '" + this._currentDeviceId + "' liveSearchState: '" + JSON.stringify(states.liveSearch) + "'");
            var isFirstUpdate = this.liveSearchState === null,
                didChange = JSON.stringify(this.liveSearchState) !== JSON.stringify(states.liveSearch);
            this.liveSearchState = states.liveSearch;

            if (!isFirstUpdate && didChange) {
                this._checkCurrentSearchUser(this.liveSearchState);
            }
        }

        _checkCurrentSearchUser(liveSearchState) {
            if (this._currentDeviceId !== liveSearchState.deviceId) {
                if (!this.popupPromise) {
                    SandboxComponent.unregisterForStateChangesForUUID(GLOBAL_UUID, this); // true => The user has been explicitly kicked

                    ActiveMSComponent.stopDeviceSearch(true);
                    this.popupPromise = NavigationComp.showPopup({
                        // tree.device-learning.stopped.title
                        // air.device-learning.stopped.title
                        title: _(this.searchType + '.device-learning.stopped.title'),
                        // tree.device-learning.stopped.message
                        // air.device-learning.stopped.message
                        message: _(this.searchType + '.device-learning.stopped.message'),
                        buttonOk: true,
                        buttonCancel: false,
                        icon: Icon.INFO,
                        color: window.Styles.colors.green
                    }, PopupType.GENERAL).then(function () {
                        this.dismiss();
                    }.bind(this));
                }
            }
        }

        _navigateBackDueToCanceledPermissionElevation() {
            this.dismiss();
        }

    };
});
