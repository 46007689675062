'use strict';

define(['AutopilotCommunicator', 'AutopilotEnums', 'AutopilotUtility', 'AutopilotEventScreen', 'AutopilotActionsScreen', 'AutopilotNotificationScreen', 'AutopilotOperatingModeScreen', 'AutopilotEventDetailsControlScreen', 'AutopilotEventDetailsSimpleScreen'], function (AutopilotCommunicator, AutopilotEnums, AutopilotUtility) {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var Sections = {
        Events: 0,
        Actions: 1,
        Title: 2,
        //Description: 3,
        Buttons: 3
    };
    return class AutopilotRuleScreen extends GUI.Screen {
        constructor(details) {
            super($('<div class="autopilot-rule-screen" />'));

            if (!details) {
                details = {};
            }

            this.redirected = details.hasChanges || false;
            this.rule = details.rule ? cloneObject(details.rule) : this._createNewRule();
            this.editMode = typeof details.rule !== 'undefined' && typeof details.rule.id !== 'undefined';
            this.hasChanges = details.hasChanges || false;
            this.customChanges = {
                title: false,
                description: false
            }; // add event handlers for catching event/action changes in other views

            this._addEventReg = NavigationComp.registerForUIEvent(AutopilotEnums.CommunicationEvents.ADD_EVENT, this._addEvent.bind(this));
            this._addActionReg = NavigationComp.registerForUIEvent(AutopilotEnums.CommunicationEvents.ADD_ACTION, this._addAction.bind(this));
            this._updateEventReg = NavigationComp.registerForUIEvent(AutopilotEnums.CommunicationEvents.UPDATE_EVENT, this._updateEvent.bind(this));
            this._updateActionReg = NavigationComp.registerForUIEvent(AutopilotEnums.CommunicationEvents.UPDATE_ACTION, this._updateAction.bind(this));
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.elements = {};
            this.tableView = new GUI.EditableTableView(this, this);
            this.appendSubview(this.tableView);
        }

        viewWillAppear() {
            this._reloadData(true);

            super.viewWillAppear(...arguments);
        }

        getURL() {
            return "Autopilot/" + (this.isNewRule ? "NewRule" : "Rule");
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            return this.isNewRule ? _("autopilot.new") : _("autopilot.edit");
        }

        titleBarConfig() {
            return {
                rightSide: TitleBarCfg.Button.TICK
            };
        }

        titleBarAction() {
            this.ViewController.navigateBack();
        }

        titleBarActionRight() {
            this._saveRule();
        }

        destroy() {
            NavigationComp.removeFromUIEvent(this._addEventReg);
            NavigationComp.removeFromUIEvent(this._addActionReg);
            NavigationComp.removeFromUIEvent(this._updateEventReg);
            NavigationComp.removeFromUIEvent(this._updateActionReg);
            this._addEventReg = null;
            this._addActionReg = null;
            this._updateEventReg = null;
            this._updateActionReg = null;
            super.destroy(...arguments);
        }

        styleForTableView(tableView) {
            return TableViewStyle.GROUPED;
        }

        numberOfSections() {
            if (!this.editMode) {
                return Object.keys(Sections).length - 1; // do not show button section (delete button)
            }

            return Object.keys(Sections).length;
        }

        titleForHeaderInSection(section) {
            if (section === Sections.Events) {
                return _("autopilot.rule.events");
            } else if (section === Sections.Actions) {
                return _("autopilot.rule.actions");
            } else if (section === Sections.Title) {
                return _("autopilot.rule.title") + " (" + _("optional") + ")";
            } else if (section === Sections.Description) {
                return _("autopilot.rule.summary");
            } else if (section === Sections.Buttons) {
                return "";
            }
        }

        numberOfRowsInSection(section) {
            if (section === Sections.Events) {
                return this.rule.events.length + 1;
            } else if (section === Sections.Actions) {
                return this.rule.actions.length + 1;
            } else if (section === Sections.Title) {
                return 1;
            } else if (section === Sections.Description) {
                return 1;
            } else if (section === Sections.Buttons) {
                return 1;
            }
        }

        cellTypeForCellAtIndex(section, row) {
            if (section === Sections.Events) {
                if (row === this.rule.events.length) {
                    return GUI.TableViewV2.CellType.GENERAL;
                }

                return GUI.TableViewV2.CellType.Special.AUTOPILOT_EVENT_CELL;
            } else if (section === Sections.Actions) {
                return GUI.TableViewV2.CellType.GENERAL;
            } else if (section === Sections.Title) {
                return GUI.TableViewV2.CellType.INPUT;
            } else if (section === Sections.Description) {
                return GUI.TableViewV2.CellType.INPUT;
            } else if (section === Sections.Buttons) {
                return GUI.TableViewV2.CellType.DELETE;
            }
        }

        contentForCell(cell, section, row) {
            if (section === Sections.Events) {
                if (row === this.rule.events.length) {
                    return {
                        leftIconSrc: Icon.TRANSLUCENT_ADD,
                        leftIconColor: window.Styles.colors.green,
                        title: _("autopilot.rule.event.add"),
                        clickable: true
                    };
                }

                return this._createEventCellContent(this.rule.events[row], row);
            } else if (section === Sections.Actions) {
                if (row === this.rule.actions.length) {
                    return {
                        leftIconSrc: Icon.TRANSLUCENT_ADD,
                        leftIconColor: window.Styles.colors.green,
                        title: _("autopilot.rule.action.add"),
                        clickable: true
                    };
                }

                return this._createActionCellContent(this.rule.actions[row]);
            } else if (section === Sections.Title) {
                return {
                    type: "text",
                    value: this.rule.name || "",
                    placeholder: _("autopilot.rule.title"),
                    validationRegex: Regex.TEXT_HTTP,
                    filterRegex: Regex.TEXT_HTTP_FILTER,
                    showGreen: false
                };
            } else if (section === Sections.Description) {
                return {
                    type: "text",
                    value: AutopilotUtility.createDescriptionForRule(this.rule),
                    //this.rule.description || "",
                    placeholder: _("autopilot.rule.summary"),
                    multiline: true,
                    readonly: true
                };
            } else if (section === Sections.Buttons) {
                return {
                    title: _("autopilot.rule.delete")
                };
            }
        }

        didSelectCell(cell, section, row) {
            if (section === Sections.Events) {
                if (row === this.rule.events.length) {
                    this.ViewController.showState(AutopilotEnums.ScreenStates.AutopilotEventScreen);
                } else {
                    this._handleEventClick(this.rule.events[row]);
                }
            } else if (section === Sections.Actions) {
                if (row === this.rule.actions.length) {
                    this.ViewController.showState(AutopilotEnums.ScreenStates.AutopilotActionsScreen, null, {
                        rule: this.rule
                    });
                } else {
                    this._handleActionClick(this.rule.actions[row]);
                }
            } else if (section === Sections.Buttons) {
                this._removeAutopilot();
            }
        }

        didRemoveCell(section, row) {
            if (section === Sections.Events) {
                this.rule.events.splice(row, 1);
            } else if (section === Sections.Actions) {
                this.rule.actions.splice(row, 1);
            }

            this.hasChanges = true;

            this._reloadData(true);
        }

        textChanged(section, row, tableView, text, isValid) {
            if (section === Sections.Title) {
                this.rule.name = isValid || text === "" ? text : null;
                this.customChanges.title = true;
            } else if (section === Sections.Description) {
                this.rule.description = text; // for the moment, we don't allow to change the description (readonly)
                //this.customChanges.description = true;
            }

            this.hasChanges = true;
        }

        _reloadData(repopulate) {
            if (repopulate) {
                this._populateRule(this.rule);
            }

            this.tableView.reload();
            this.tableView.setEditingModeForSection(Sections.Events, true, true, false, 0, this.rule.events.length);
            this.tableView.setEditingModeForSection(Sections.Actions, true, true, false, 0, this.rule.actions.length);
        }

        _createNewRule() {
            this.isNewRule = true;
            return {
                name: "",
                description: "",
                active: true,
                events: [],
                actions: []
            };
        }

        _validateRule() {
            if (!this.rule.events || this.rule.events.length === 0) {
                return _("autopilot.rule.incomplete.no-events");
            } else if (!this.rule.actions || this.rule.actions.length === 0) {
                return _("autopilot.rule.incomplete.no-actions");
            } else if (!this.rule.name) {
                return _("autopilot.rule.incomplete.invalid-name");
            }

            return true;
        }

        _saveRule() {
            // do not allow another save request
            if (this.saveRequest) {
                return;
            }

            this.saveRequest = true;

            var validationResult = this._validateRule();

            if (validationResult === true) {
                if (!this.hasChanges) {
                    // skip validation and update process on miniserver if there are no changes
                    this.saveRequest = false;
                    this.ViewController.navigateBack();
                    return;
                } // if there is no name or description, they will be created automatically


                if (!this.rule.name || !this.customChanges.title && this.rule.nameIsGenerated) {
                    this.rule.name = AutopilotUtility.createTitleForRule(this.rule);
                }

                if (!this.rule.description) {
                    this.rule.description = ""; //this.rule.description = AutopilotUtility.createDescriptionForRule(this.rule);
                }

                var cleanRule = this._cleanRule(this.rule);

                AutopilotCommunicator.saveOrUpdateRule(cleanRule, this.isNewRule).then(function (succ) {
                    this.saveRequest = false;
                    this.ViewController.navigateBack();
                }.bind(this), function () {
                    var content = {
                        title: _("error"),
                        message: _("autopilot.save.error"),
                        buttonCancel: true,
                        icon: Icon.X,
                        color: window.Styles.colors.red
                    };
                    this.saveRequest = false;
                    NavigationComp.showPopup(content);
                }.bind(this));
            } else {
                this.saveRequest = false;
                return AutopilotUtility.showValidationError(validationResult, _("autopilot.rule.incomplete.title"));
            }
        }

        _removeAutopilot() {
            var content = {
                title: _("autopilot.rule.delete"),
                message: _("autopilot.rule.delete-description"),
                buttonOk: _("delete"),
                buttonCancel: true,
                icon: Icon.X,
                color: window.Styles.colors.red
            };
            NavigationComp.showPopup(content).then(function () {
                Debug.Autopilot.General && console.log("remove autopilot");
                AutopilotCommunicator.removeRule(this.rule).then(function (succ) {
                    this.ViewController.navigateBack();
                }.bind(this), function (err) {
                    var content = {
                        title: _("error"),
                        message: _("autopilot.delete.error"),
                        buttonCancel: true,
                        icon: Icon.X,
                        color: window.Styles.colors.red
                    };
                    NavigationComp.showPopup(content);
                });
            }.bind(this));
        }

        _handleEventClick(event) {
            if (event.type === AutopilotEnums.EventTypes.Control) {
                this.ViewController.showState(AutopilotEnums.ScreenStates.AutopilotEventDetailsControlScreen, null, {
                    event: event,
                    editMode: true
                });
            } else if (event.type === AutopilotEnums.EventTypes.OperatingMode) {
                this.ViewController.showState(AutopilotEnums.ScreenStates.AutopilotOperatingModeScreen, null, {
                    event: event,
                    operatingMode: AutopilotUtility.getOperatingModes(true, event.id)
                });
            } else if (event.type === AutopilotEnums.EventTypes.Weather) {
                var weatherDeatils = {
                    title: _("weather." + event.id),
                    event: event,
                    eventType: AutopilotEnums.EventTypes.Weather
                };
                var weatherType = event.data.entry;

                if (weatherType.analog) {
                    weatherDeatils.type = AutopilotEnums.EventDetailTypes.DecimalNumber;
                } else {
                    weatherDeatils.options = AutopilotEnums.YesNoOptions;
                    weatherDeatils.type = AutopilotEnums.EventDetailTypes.Radio;
                }

                this.ViewController.showState(AutopilotEnums.ScreenStates.AutopilotEventDetailsSimpleScreen, null, weatherDeatils);
            } else if (event.type === AutopilotEnums.EventTypes.Time) {
                var timeDetails = {
                    title: _("times." + event.id),
                    event: event,
                    eventType: AutopilotEnums.EventTypes.Time
                };
                var timeType = event.data.entry;

                if (!timeType) {
                    return; // we removed weekdays, so we have to handle it
                }

                if (timeType.id === AutopilotEnums.SpecialEvents.Weekdays) {
                    // it's an analog value, but we have to show user friendly options
                    timeDetails.options = AutopilotEnums.Weekdays;
                    timeDetails.type = AutopilotEnums.EventDetailTypes.Radio;
                } else if (timeType.analog) {
                    timeDetails.type = AutopilotEnums.EventDetailTypes.Number;
                } else {
                    timeDetails.options = AutopilotEnums.YesNoOptions;
                    timeDetails.type = AutopilotEnums.EventDetailTypes.Radio;
                }

                this.ViewController.showState(AutopilotEnums.ScreenStates.AutopilotEventDetailsSimpleScreen, null, timeDetails);
            } else if (event.type === AutopilotEnums.EventTypes.CustomTime) {
                this.ViewController.showState(AutopilotEnums.ScreenStates.AutopilotEventDetailsSimpleScreen, null, {
                    title: _("mobiscroll.time"),
                    event: event,
                    eventType: AutopilotEnums.EventTypes.CustomTime,
                    type: AutopilotEnums.EventDetailTypes.Time,
                    operator: event.operator,
                    editMode: true
                });
            } else if (event.type === AutopilotEnums.EventTypes.DateTime) {
                // datetime is not used at the moment!
                this.ViewController.showState(AutopilotEnums.ScreenStates.AutopilotEventDetailsSimpleScreen, null, {
                    title: _("mobiscroll.date"),
                    event: event,
                    eventType: AutopilotEnums.EventTypes.DateTime,
                    type: AutopilotEnums.EventDetailTypes.DateTime,
                    operator: event.operator
                });
            }
        }

        _handleActionClick(action) {
            if (action.type === AutopilotEnums.ActionTypes.Command) {// do nothing
                // TODO enable editing?

                /*this.ViewController.showState(AutopilotEnums.ScreenStates.AutopilotControlCommandsScreen, null, {
                 action: {
                 controlUUID: selectedControl.uuidAction,
                 type: AutopilotEnums.EventTypes.Control,
                 editMode: false
                 }
                 });*/
            } else if (action.type === AutopilotEnums.ActionTypes.OperatingMode) {
                this.ViewController.showState(AutopilotEnums.ScreenStates.AutopilotOperatingModeScreen, null, {
                    action: action,
                    operatingMode: AutopilotUtility.getOperatingModes(true, action.id)
                });
            } else if (action.type === AutopilotEnums.ActionTypes.Notification || action.type === AutopilotEnums.ActionTypes.CloudMailer || action.type === AutopilotEnums.ActionTypes.Caller) {
                this.ViewController.showState(AutopilotEnums.ScreenStates.AutopilotNotificationScreen, null, {
                    action: action,
                    notificationType: action.type
                });
            }
        }

        _createEventCellContent(event, row) {
            var title = AutopilotUtility.createEventDescription(event),
                subtitle = "",
                disclosureIcon = true;

            switch (event.type) {
                case AutopilotEnums.EventTypes.Control:
                    var control = event.data.control;

                    if (control) {
                        subtitle =
                            /*control.name + SEPARATOR_SYMBOL +*/
                            control.groupDetail;
                    }

                    break;

                case AutopilotEnums.EventTypes.OperatingMode:
                    subtitle = _("pool.control.settings.operating-mode");
                    break;

                case AutopilotEnums.EventTypes.Weather:
                    subtitle = _("weather");
                    break;

                case AutopilotEnums.EventTypes.Time:
                    subtitle = _("autopilot.rule.event.times");
                    disclosureIcon = !AutopilotUtility.isPulseEvent(event);
                    break;

                case AutopilotEnums.EventTypes.DateTime:
                    subtitle = _("mobiscroll.date");
                    break;

                case AutopilotEnums.EventTypes.CustomTime:
                    subtitle = _("autopilot.rule.event.times");
                    break;
            }

            var rowPrefix = row === 0 ? _("autopilot.rule.if") : _("and");
            var content = {
                title: rowPrefix.toUpperCase() + " " + title,
                subtitle: subtitle,
                forceSelection: true,
                disclosureIcon: disclosureIcon
            };

            if (event.invalid) {
                content.title = _("autopilot.not-existing");
                content.rightIconSrc = Icon.CAUTION;
                content.rightIconColor = window.Styles.colors.red;
                content.disclosureIcon = false;
            }

            return content;
        }

        _createActionCellContent(action) {
            var useDisclosureIcon = true;
            Debug.Autopilot.General && console.log(action);

            if (typeof action.type !== 'number') {
                console.error("no action type set");
                return;
            }

            var title = AutopilotUtility.createActionDescription(action),
                subtitle = "";

            switch (action.type) {
                case AutopilotEnums.ActionTypes.Command:
                    var control = action.data.control;
                    useDisclosureIcon = false;

                    if (control) {
                        subtitle = control.groupDetail;
                    } else {
                        title = _("autopilot.no-access");
                    }

                    break;

                case AutopilotEnums.ActionTypes.OperatingMode:
                    subtitle = _("pool.control.settings.operating-mode");
                    break;

                case AutopilotEnums.ActionTypes.CloudMailer:
                case AutopilotEnums.ActionTypes.Caller:
                case AutopilotEnums.ActionTypes.Notification:
                    subtitle = _("message");
                    break;

                default:
                    break;
            }

            var content = {
                title: title,
                subtitle: subtitle,
                disclosureIcon: useDisclosureIcon,
                forceSelection: true
            };

            if (action.invalid) {
                content.title = _("autopilot.not-existing");
                content.rightIconSrc = Icon.CAUTION;
                content.rightIconColor = window.Styles.colors.red;
                content.disclosureIcon = false;
            }

            return content;
        }

        /**
         * This populates the rule with object data for the given events and actions
         * @private
         */
        _populateRule(rule) {
            AutopilotUtility.populateRule(rule);

            this._updateTexts();
        }

        _updateTexts() {
            // we only generate a title and a description the first time the user creates a new rule
            // or if the fields are empty
            if (!this.editMode || this.redirected || this.rule.nameIsGenerated) {
                // update title and description, if user hasn't touched the fields yet
                if (!this.customChanges.title || this.rule.nameIsGenerated) {
                    this.rule.name = AutopilotUtility.createTitleForRule(this.rule);
                }
            } //this.rule.description = AutopilotUtility.createDescriptionForRule(this.rule);

        }

        /**
         * Remove unnecessary data from rule (like data from population)
         * @param rule
         * @private
         */
        _cleanRule(rule) {
            var ruleCopy = JSON.parse(JSON.stringify(rule));

            if (!ruleCopy.name || ruleCopy.name.length === 0) {
                ruleCopy.name = AutopilotUtility.createTitleForRule(ruleCopy);
            }

            delete ruleCopy.nameIsGenerated;
            var i;

            for (i = 0; i < ruleCopy.events.length; i++) {
                var event = ruleCopy.events[i];
                delete event['data'];
            }

            for (i = 0; i < ruleCopy.actions.length; i++) {
                var action = ruleCopy.actions[i];
                delete action['data'];
            }

            return ruleCopy;
        }

        _addEvent(jsEvent, autopilotEvent) {
            Debug.Autopilot.General && console.log("add event");
            Debug.Autopilot.General && console.log(autopilotEvent);

            if (!autopilotEvent.operator) {
                autopilotEvent.operator = AutopilotEnums.DefaultOperator;
            } // check if event already exists


            if (!AutopilotUtility.containsEvent(this.rule, autopilotEvent)) {
                AutopilotUtility.populateEvent(autopilotEvent);
                this.rule.events.push(autopilotEvent);
                this.hasChanges = true;

                this._reloadData();
            }
        }

        _updateEvent(jsEvent, autopilotEvent) {
            Debug.Autopilot.General && console.log("update event");
            Debug.Autopilot.General && console.log(autopilotEvent);
            this.hasChanges = true; // the data of the existing event has changed, repopulate the rule.

            this._reloadData(true);
        }

        _addAction(jsEvent, action) {
            Debug.Autopilot.General && console.log("add action");
            Debug.Autopilot.General && console.log(action);

            if (!action.id) {
                action.id = AutopilotEnums.DefaultActionId;
            } // check if action already exists


            if (!AutopilotUtility.containsAction(this.rule, action)) {
                AutopilotUtility.populateAction(action);
                this.rule.actions.push(action);
                this.hasChanges = true;

                this._reloadData();
            }
        }

        _updateAction(jsEvent, action) {
            Debug.Autopilot.General && console.log("update action");
            Debug.Autopilot.General && console.log(action);
            this.hasChanges = true;

            this._reloadData();
        }

    };
});
