'use strict';
/**
 * entry point for the autopilot generator
 */

define(['AutomaticDesignerRulesScreen', 'AutomaticDesignerHistoryScreen', 'AutomaticDesignerFilterView'], function (AutomaticDesignerRulesScreen, AutomaticDesignerHistoryScreen, AutomaticDesignerFilterView) {
    return class AutomaticDesignerOverviewScreen extends GUI.Screen {
        constructor(details) {
            super($('<div />'));
            this.details = details || {};
            this.filterControls = this.details.filterControls || [];
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                var onboardingContinueDef = Q.defer(),
                    onboardingScreen = new GUI.OnboardingScreen({
                        id: GUI.OnboardingScreen.IDS.AUTOMATIC_DESIGNER,
                        iconSrc: Icon.AutomaticDesigner.COMMON,
                        title: _('autopilot.name'),
                        message: _("automatic-designer.onboarding.message1"),
                        message2: _("automatic-designer.onboarding.message2"),
                        iconColor: window.Styles.colors.green,
                        continueBtnText: _("automatic-designer.onboarding.start"),
                        asView: true,
                        continueDef: onboardingContinueDef
                    });
                onboardingContinueDef.promise.progress(this.titleBarActionRight.bind(this));
                this.elements = {};
                this.filterView = new AutomaticDesignerFilterView(this.filterControls);
                this.unregisterFilterResetFn = this.filterView.on(AutomaticDesignerFilterView.EVENTS.RESET, this._resetFilter.bind(this));
                this.rulesScreen = new AutomaticDesignerRulesScreen(this.details);
                this.historyScreen = new AutomaticDesignerHistoryScreen(this.details);
                this.addToHandledSubviews(this.rulesScreen);
                this.addToHandledSubviews(this.historyScreen);
                this.segmentedControl = new GUI.LxSegmentedControl([{
                    text: _("autopilot.rule.name.plural")
                }, {
                    text: _("autopilot.history")
                }]);
                this.segmentedControl.on("on-segment-selected", function (ev, idx) {
                    this.pageView.setActivePage(idx);
                }.bind(this));
                this.pageView = new GUI.LxPageView([this.rulesScreen.getElement(), this.historyScreen.getElement()], false, this._doneAnimating.bind(this), {
                    overscroll: true
                });
                this.pageView.disableUserInteraction(true);
                this.noFilterResultView = new GUI.BackgroundView(null, null, _("automatic-designer.filter.no-results"), null, null, true, true);
                this.noFilterResultView.getElement().addClass("background-view--no-filter-result");
                this.noFilterResultView.setTranslucent(true);
                this.appendSubview(this.filterView);
                this.appendSubview(this.segmentedControl);
                this.appendSubview(onboardingScreen);
                this.appendSubview(this.pageView);
                this.appendSubview(this.noFilterResultView);
                this.hideSubview(this.noFilterResultView);
                this.unregisteRuleListenerFn = AutomaticDesignerComponent.registerForRuleChangeNotify(function () {
                    this._handleEmptyView();
                }.bind(this));
                return this._handleEmptyView();
            }.bind(this));
        }

        getURL() {
            return UrlStartLocation.AUTOMATIC_DESIGNER;
        }

        destroy() {
            this.unregisteRuleListenerFn();
            this.unregisterFilterResetFn();
            return super.destroy(...arguments);
        }

        getPermissions() {
            return [{
                permission: MsPermission.AUTOPILOT
            }];
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            return _("autopilot.name");
        }

        titleBarConfig() {
            return {
                rightSide: TitleBarCfg.CUSTOM_ICON,
                rightIconSrc: Icon.NEW
            };
        }

        titleBarActionRight() {
            NavigationComp.showState(ScreenState.AutomaticDesigner.RuleViewController);
        }

        updateView(details) {
            this.details = details;
            this.rulesScreen.updateView(this.details);
            this.historyScreen.updateView(this.details);
        }

        _doneAnimating(pageIndex) {
            this.segmentedControl.setActiveSegment(pageIndex);
        }

        _resetFilter() {
            delete this.details.filterControls;
            this.filterControls = [];
            this.rulesScreen.updateView(this.details);
            this.historyScreen.updateView(this.details);
            this.filterView.updateView(this.filterControls);
        }

        _handleEmptyView() {
            return AutomaticDesignerComponent.getRules(this.details.filterControls).then(function (rules) {
                if (rules && rules.length) {
                    this.element.removeClass("automatic-designer-overview-screen--empty");
                    this.element.removeClass("automatic-designer-overview-screen--empty-no-filter-result");
                    this.hideSubview(this.noFilterResultView);
                } else {
                    this.element.addClass("automatic-designer-overview-screen--empty");

                    if (this.filterControls.length) {
                        this.element.addClass("automatic-designer-overview-screen--empty-no-filter-result");
                        this.showSubview(this.noFilterResultView);
                    }
                }
            }.bind(this));
        }

    };
});
