import {View} from "react-native-web";
import Icons from "IconLib";
import {LxReactPressable, LxSliderDefaultSettings} from "LxComponents";
import PropTypes from "prop-types";
import globalStyles from "GlobalStyles";
import LxReactVerticalSliderHandler from "./LxReactVerticalSliderHandler";

function LxReactVerticalSliderButtonView(props) {

    const onValueChanged = (newValue) => {
        props.onValueChanged && props.onValueChanged(newValue, true)
    }

    const onButtonPressed = (shouldIncrease) => {
        let config = props.config;
        let newValue;
        if (shouldIncrease) {
            newValue = props.value + (config.step || LxSliderDefaultSettings.defaultSettings.STEP);
            if (newValue > config.max) {
                newValue = config.max;
            }
        } else {
            newValue = props.value - (config.step || LxSliderDefaultSettings.defaultSettings.STEP);
            if (newValue < config.min) {
                newValue = config.min;
            }
        }

        onValueChanged(newValue)
    }

    const ActionButton = ({isPlus}) => {
        return <LxReactPressable style={styles.actionButtonContainer} pressableStyle={styles.actionButton}
                                 pkey={isPlus ? "plus-button" : "minus-button"} onPress={() => onButtonPressed(isPlus)}>
            {isPlus ? <Icons.Plus {...styles.actionIcon}/> : <Icons.Minus {...styles.actionIcon}/>}
        </LxReactPressable>
    }

    return <View style={styles.container}>
        <View style={styles.contentContainer}>
            <LxReactVerticalSliderHandler {...props} containerStyle={styles.slider}/>
            <View style={styles.actionButtonsContainer}>
                <ActionButton isPlus={true}/>
                <ActionButton isPlus={false}/>
            </View>
        </View>
    </View>
}

LxReactVerticalSliderButtonView.propTypes = {
    config: PropTypes.object.isRequired,
    value: PropTypes.number.isRequired,
    onValueChanged: PropTypes.func
}

const styles = {
    container: {
        width: "100%",
        flex: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    contentContainer: {
        width: "100%",
        height: "100%",
        maxHeight: 405,
        flexDirection: "row",
        alignItems: "flex-end",
        justifyContent: "center"
    },
    slider: {
        width: "50%",
        height: "100%",
        marginLeft: 50
    },
    actionButtonsContainer: {
        marginLeft: globalStyles.spacings.gaps.small
    },
    actionButtonContainer: {
        height: globalStyles.sizes.icons.veryLarge,
        width: globalStyles.sizes.icons.veryLarge,
        justifyContent: "flex-end"
    },
    actionButton: {
        justifyContent: "center",
        alignItems: "center",
        height: globalStyles.sizes.icons.bigger,
        width: globalStyles.sizes.icons.bigger,
        padding: globalStyles.spacings.gaps.verySmall,
        backgroundColor: globalStyles.colors.grey["300a36"],
        borderRadius: "50%"
    },
    actionIcon: {
        height: globalStyles.sizes.icons.tiny,
        width: globalStyles.sizes.icons.tiny,
        fill: globalStyles.colors.white
    }
}

export default LxReactVerticalSliderButtonView
