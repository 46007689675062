'use strict';
/*
    Description of the content: (Base content is excluded here, please check the base implementation for further information)
    content:
        sensor
        sensorAlarmText
        sensorColor
        currentTemp

    Delegate:
        onChangeDescription(sensor, section, row, tableView)
        onTargetTempChange(sensor, section, row, tableView)
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.STEAK_SENSOR_CELL = "SteakSensorCell";

    class SteakSensorCell extends GUI.TableViewV2.Cells.DetailedContentBaseCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        viewDidLoad() {
            super.viewDidLoad(...arguments);
            this.elements.prominentContainer.leftContainer = this.contentPlaceholder.find('.prominent-container__left-container');
            this.elements.prominentContainer.center = {
                element: this.contentPlaceholder.find('.prominent-container__center-container'),
                title: this.contentPlaceholder.find('.center-container__title')
            };
            this.elements.prominentContainer.rightContainer = this.contentPlaceholder.find('.prominent-container__right-container');
            this.elements.infoBar.container.time = $("<div class='info-container__time'></div>");
            this.elements.infoBar.container.element.prepend(this.elements.infoBar.container.time);
        }

        applyContent() {
            var timeOffset = SandboxComponent.getMiniserverTimeInfo(this, null, TimeValueFormat.MINISERVER_UTC_OFFSET),
                time,
                today,
                isToday,
                timeFormat;
            this.content.title = this.content.sensor.name;
            this.content.icon = Icon.CIRCLE;
            this.content.info = {};
            this.content.detail = {
                text: lxFormat("%d°", this.content.sensor.targetTemperature),
                icon: Icon.Steak.TARGET
            };
            this.content.prominent = {
                title: lxFormat("%d°", this.content.currentTemp)
            };

            if (this.content.sensorAlarmText.text && this.content.sensorAlarmText.text !== "") {
                this.content.info.text = this.content.sensorAlarmText.text;
                time = new LxDate(this.content.sensorAlarmText.time, true).utcOffset(timeOffset);
                today = moment().startOf("day");
                isToday = time.isSame(today, "d");
                timeFormat = isToday ? DateType.TimeFormat : LxDate.getDateTimeFormat(DateType.WeekdayAndDateTextNumeralAndTime, true);
                this.content.info = {
                    time: time.format(timeFormat),
                    text: this.content.sensorAlarmText.text
                };
            }

            if (this.content.sensorAlarmText.ringing) {
                this.content.color = window.Styles.colors.orange;
                this.content.prominent.actions = [{
                    icon: Icon.Steak.ALARM_SILENT,
                    action: this.onSelected.bind(this) // Just simulate a click on the cell, we want the same behaviour

                }];
            } else {
                this.content.color = Color.WHITE;
            }

            super.applyContent(...arguments);

            if (this.content.info && this.content.info.time) {
                this.elements.infoBar.container.time.text(this.content.info.time);
            }

            this.elements.topBar.description.icon.css("fill", this.content.sensorColor);

            if (this.content.sensorAlarmText.ringing) {
                this.elements.infoBar.container.element.css("background-color", applyAlphaChannelToColorString(Color.ORANGE, 0.4));
            }
        }

        applyProminentContainerContent() {
            var prms = true;

            if (this.content.prominent && this.content.prominent.title) {
                this.elements.prominentContainer.center.title.text(this.content.prominent.title);
                this.elements.prominentContainer.center.title.css("color", this.content.color);
            }

            if (this.content.prominent.actions) {
                this.content.prominent.actions.forEach(function (action, idx) {
                    if (action.icon && action.action) {
                        var button = new GUI.LxButton(this, this._getPrimaryButton(action, idx), Color.BUTTON_GLOW, null, true);
                        button.onButtonReleased = action.action.bind(this);
                        prms = this.appendSubview(button, this.elements.prominentContainer.rightContainer);
                        button.getElement().find(".action-button__icon").css("fill", this.content.color);
                    }
                }.bind(this));
            }

            return prms;
        }

        getProminentContainer() {
            return '   <div class="prominent-container__left-container"></div>' + '      <div class="prominent-container__center-container">' + '          <div class="center-container__title"></div>' + '      </div>' + '      <div class="prominent-container__right-container"></div>';
        }

        _getPrimaryButton(action, idx) {
            return '<div class="' + "action-button button-" + idx + '">' + '       ' + ImageBox.getResourceImageWithClasses(action.icon, "action-button__icon") + '   </div>';
        }

        handleTopRightButtonDelegate() {
            this.delegate.onTargetTempChange && this.delegate.onTargetTempChange.call(this.delegate, this.content.sensor, this.sectionIdx, this.rowIdx, this.tableView);
        }

        handleTopLeftButtonDelegate() {
            this.delegate.onChangeDescription && this.delegate.onChangeDescription.call(this.delegate, this.content.sensor, this.sectionIdx, this.rowIdx, this.tableView);
        }

    }

    GUI.TableViewV2.Cells.SteakSensorCell = SteakSensorCell;
    return GUI;
}(window.GUI || {});
