import PropTypes from "prop-types";
import LxReactText from "./LxReactText";
import {
    ReactWrapper
} from "LxComponents";

/**
 * Collection of PropType definitions and render function to render those props.
 * Used to pass on components and props into e.g. flexible cells or the lxReactHeader.
 */

/**
 * PropType definition of a single object with two attributes (comp & props)
 * comp = functional component
 * props = the properties object passed on when rendering
 * @type {Requireable<Required<InferProps<{comp: Validator<NonNullable<(...args: any[]) => any>>, props: Validator<NonNullable<object>>}>>>}
 */
const SingleComponentProp = PropTypes.exact({
    comp: PropTypes.func.isRequired, // must be a function component, otherwise it'll be repainted on each render.
    props: PropTypes.object.isRequired
});

/**
 * PropType definition - either a SingleComponentProp or an array of SingleComponentProps
 * @type {Requireable<NonNullable<InferType<Requireable<Required<InferProps<{comp: React.Validator<NonNullable<function(...[*]): *>>, props: React.Validator<NonNullable<Object>>}>>>|Requireable<unknown[]>>>>}
 */
const ComponentsProp = PropTypes.oneOfType([SingleComponentProp, PropTypes.arrayOf(SingleComponentProp)]);

/**
 * PropType definition of either one SingleComponentProp or a string
 * @type {Requireable<NonNullable<InferType<Requireable<string>|Requireable<Required<InferProps<{comp: React.Validator<NonNullable<function(...[*]): *>>, props: React.Validator<NonNullable<Object>>}>>>>>>}
 */
const SingleTextComponentProp = PropTypes.oneOfType([
    PropTypes.string,
    SingleComponentProp
]);

/**
 * PropType definition - either a single item (string/SingleComponentProp) or an array of such items.
 * @type {Requireable<NonNullable<InferType<Requireable<InferType<React.Requireable<string>|React.Requireable<Required<InferProps<{comp: React.Validator<NonNullable<function(...[*]): *>>, props: React.Validator<NonNullable<Object>>}>>>>>|Requireable<unknown[]>>>>}
 */
const TextComponentsProp = PropTypes.oneOfType(
    [SingleTextComponentProp,
        PropTypes.arrayOf(
            SingleTextComponentProp
        )]
)

/**
 * Renders components passed via the propTypes defined in this module
 * @param component single text or compProp or array of texts or compProps
 * @returns {null} rendered components
 */
export const renderComponentProperty = (component) => {
    let processedContent = null;

    if (component) {
        if (Array.isArray(component)) {
            processedContent = component.map(renderSingleItem);
            if (processedContent.length === 0) {
                processedContent = null;
            }
        } else {
            processedContent = renderSingleItem(component);
        }
    }

    return processedContent;
}

/**
 * Helper that takes care of actually rendering a single item
 * @param component
 * @returns {JSX.Element|null|*}
 */
const renderSingleItem = (component) => {
    if (typeof component === "string") {
        return <LxReactText>{component}</LxReactText>;
    } else if (component) {
        checkComponentProp(component);
        return ReactWrapper.React.createElement(component.comp, component.props);
    } else {
        return null;
    }
}

/**
 * Helper that ensures that the compProp provided is a functional component (required here)
 * @param prop
 */
const checkComponentProp = (prop) => {
    if (!prop) {
        console.error("LxReactCompPropTypes", "Passing NULL as content object must be handled!");
    } else if (!prop.comp) {
        console.error("LxReactCompPropTypes", "Content objects need to have a comp property to be rendered!");
    }
}

const LxReactCompPropTypes = {
    SingleComponentProp: SingleComponentProp,
    ComponentsProp: ComponentsProp,
    SingleTextComponentProp: SingleTextComponentProp,
    TextComponentsProp: TextComponentsProp,
    renderComponentProperty: renderComponentProperty
}

export default LxReactCompPropTypes;
