'use strict';

define([], function () {
    return {
        Screens: {
            SETTINGS: "AtheneSettingsScreen",
            DIAGNOSIS: "AtheneDiagnosisScreen",
            ACTIVITIES: "AtheneActivitiesScreen",
            ACTIVITY_DETAILS: "AtheneActivityDetailsScreen",
            NOT_READY: "AtheneNotReadyScreen"
        },
        SETTINGS: {
            LAST_SEEN_ACTIVITIES: "lastSeenActivities",
            PIP_SETTING: "pipSetting",
            INPUT_DEVICE: "inputDevice",
            OUTPUT_DEVICE: "outputDevice",
            PIP_POSITION: {
                RIGHT: "right",
                LEFT: "left"
            }
        },
        DEVICE_STATE: {
            UNKNOWN: 0,
            OK: 1,
            REBOOTING: 2,
            INITIALIZING: 3
        },
        MAX_TTS_LENGTH: 128
    };
});
