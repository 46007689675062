'use strict';

define(["SpotifyAccountManager"], function (SpotifyAccountManager) {
    class AudioZoneV2ControlQueueScreen extends GUI.TableViewScreenV2 {
        //region Static
        static Template = function () {
            return {
                getUpNextSectionHeader: function getUpNextSectionHeader() {
                    return $('<div class="up-next-section-header">' + '   ' + '<div class="up-next-section-header__left-content">' + '       ' + '<div class="left-content__title">' + _("audio-server.queue.next-up") + '</div>' + '       ' + '<div class="left-content__subtitle">' + '           ' + '<div class="subtitle__from">' + _("time.from") + '</div>' + '           ' + '<div class="subtitle__actual-from"></div>' + '       ' + '</div>' + '   ' + '</div>' + '' + '<div class="up-next-section-header__right-content">' + '       ' + '<div class="right-content__repeat-placeholder right-content__repeat-placeholder--0">' + '           ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.REPEAT_ALL, "repeat-placeholder repeat-placeholder--all") + '           ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.REPEAT_CURRENT, "repeat-placeholder repeat-placeholder--current") + '       ' + '</div>' + '       ' + '<div class="right-content__shuffle-placeholder">' + '           ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.SHUFFLE, "shuffle-placeholder") + '       ' + '</div>' + '' + '</div>' + '</div>');
                }
            };
        }(); //endregion Static

        //region Private
        //fast-class-es6-converter: extracted from defineStatic({}) content
        CLASSES = {
            IS_PLAYING: "is_playing"
        }; //endregion Private

        //region Getter
        get queue() {
            return this._queue;
        }

        get queueIdx() {
            if (this.currentQueueId && this.queue.length) {
                return this.queue.findIndex(function (qItem) {
                    return qItem.unique_id === this.currentQueueId;
                }.bind(this));
            } else {
                return 0;
            }
        } //endregion Getter


        //region Setter
        set queue(newQueue) {
            this._queue = newQueue;

            if (this.isFinished && this.currentQueueId) {
                Debug.Control.AudioZone.Queue && console.log(this.viewId, "queue setter, isFinished is true - reload");
                this.reloadTable().then(function () {
                    this._reloadAfterShuffleDefer && this._reloadAfterShuffleDefer.resolve();
                }.bind(this));
            }
        } //endregion Setter



        constructor(details) {
            super($('<div class="audio-zone-control-queue-screen" />'));
            applyMixins(this, MediaStateHandlerV2.Mixin);
            this.control = details.control;
            this._viewId += "-ID:" + this.control.details.playerid;
            this._queue = [];
            this._currentQueue = [];
            this._history = [];
            this._current = null;
            this._upNext = [];
            this.shouldFocusCurrent = true;
            this._resetMediaAppEv = this.control.audioserverComp.on(this.control.audioserverComp.ECEvent.ResetMediaApp, function (ev, reason) {
                Debug.Control.AudioZone.Queue && console.log(this.viewId, "resetMediaApp: " + reason);

                this._unregisterFromEventsAndEmptyQueue();
            }.bind(this));
                this._connReadyEv = this.control.audioserverComp.on(this.control.audioserverComp.ECEvent.ConnEstablished, function (ev) {

                Debug.Control.AudioZone.Queue && console.log(this.viewId, "connectionEstablished");

                this._resisterForQueueEvents();
            }.bind(this));
            Debug.Control.AudioZone.Queue && console.log(this.viewId, "+CTOR");
        }

        viewDidLoad() {
            Debug.Control.AudioZone.Queue && console.log(this.viewId, "viewDidLoad");
            return Q(super.viewDidLoad(...arguments)).then(function () {
                this._receivedMiniserverStates(this.control.getStates());

                SandboxComponent.registerForStateChangesForUUID(this.control.uuidAction, this, this._receivedMiniserverStates.bind(this));
                Debug.Control.AudioZone.Queue && console.log(this.viewId, "viewDidLoad >> passed");
            }.bind(this));
        }

        viewWillAppear() {
            Debug.Control.AudioZone.Queue && console.log(this.viewId, "viewWillAppear");
            return super.viewWillAppear(...arguments).then(function () {
                Debug.Control.AudioZone.Queue && console.log(this.viewId, "viewWillAppear >> passed");

                this._resisterForQueueEvents();
            }.bind(this));
        }

        viewWillDisappear() {
            // ensure to rm a potentially pending shuffle promise is rejected at least.
            this._reloadAfterShuffleDefer && this._reloadAfterShuffleDefer.isPending() && this._reloadAfterShuffleDefer.reject();
            return super.viewWillDisappear(...arguments);
        }

        viewDidDisappear() {
            Debug.Control.AudioZone.Queue && console.log(this.viewId, "viewDidDisappear");
            this.shouldFocusCurrent = true;



            this._unregisterFromEventsAndEmptyQueue();

            return super.viewDidDisappear(...arguments);
        }

        destroy() {
            SandboxComponent.unregisterForStateChangesForUUID(this.control.uuidAction, this);
            this.control.audioserverComp.off(this._resetMediaAppEv);
            this.control.audioserverComp.off(this._connReadyEv);

            this._unregisterFromEventsAndEmptyQueue();

            return super.destroy(...arguments);
        }

        _unregisterFromEventsAndEmptyQueue(args) {
            Debug.Control.AudioZone.Queue && console.log(this.viewId, "_unregisterFromEventsAndEmptyQueue");

            this._unregisterForMediaServerEvents(this.EVENT_TYPES.QUEUE);

            this._emptyQueue();
        }

        _resisterForQueueEvents() {
            Debug.Control.AudioZone.Queue && console.log(this.viewId, "_registerForQueueEvents");

            this._registerForMediaServerEvents(this.EVENT_TYPES.QUEUE);
        }

        receivedQueueChunkEvent(data) {
            Debug.Control.AudioZone.Queue && console.log(this.viewId, "receivedQueueChunkEvent: " + data.start + ", " + data.items.length + " items");

            if (!this.isFinished) {
                this.isFinished = data.isFinished; // Remove the index, it will prevent our reload behaviour

                data.items.forEach(function (item) {
                    delete item.qindex;
                });
                this.queue = this.queue.concat(data.items);
            } else {
                console.info("receivedQueueChunkEvent: Ignoring update, data has not been reset!");
            }

            return Q(true);
        }

        receivedQueueReloadEvent(data) {
            Debug.Control.AudioZone.Queue && console.log(this.viewId, "receivedQueueReloadEvent");

            this._emptyQueue();
        }

        receivedQueueErrorEvent(err) {
            Debug.Control.AudioZone.Queue && console.error(this.viewId, "receivedQueueErrorEvent", err);
            this.isFinished = err !== MusicServerEnum.ReloadCause.CHANGED; // if changed, reload!

            this.queue = [];
        }

        getTableView() {
            return new GUI.EditableTableView(this.tableViewDataSource, this.tableViewDelegate);
        }

        getAnimation() {
            return AnimationType.FADE;
        }

        emptyViewConfig() {
            if (this.isFinished) {
                return {
                    iconSrc: Icon.ERROR,
                    title: _('controls.tracker.no-entries'),
                    message: " ",
                    // Needed otherwise the empty will not be shown
                    translucent: true,
                    noCircle: true
                };
            } else {
                return {
                    iconSrc: Icon.INDICATOR,
                    title: _('loading'),
                    message: " ",
                    // Needed otherwise the empty will not be shown
                    translucent: true,
                    noCircle: true
                };
            }
        }

        /**
         * Used to intercept the onStart/onEnd calls of the editableTableView.
         * @param sectionIdx
         * @param tableView
         * @returns {*}
         */
        getSortableOptions(sectionIdx, tableView) {
            return {
                draggable: '.section__cell',
                handle: '.cell__sort-icon-placeholder',
                onStart: this._cellStartedMoving.bind(this),
                onEnd: this._cellStoppedMoving.bind(this)
            };
        }

        /**
         * Called when the order has changed - don't access this.tableContent as it may no longer be valid (reload
         * during modifying the order).
         * @param section
         * @param row
         * @param tableView
         * @param cell
         * @param oldIdx
         * @param newIdx
         */
        didMoveCell(section, row, tableView, cell, oldIdx, newIdx) {
            Debug.Control.AudioZone.Queue && console.warn(this.viewId, "didMoveCell - " + oldIdx + "->" + newIdx);

            var cmdObj = {},
                moveBeforeIdx = newIdx < oldIdx ? newIdx : newIdx + 1,
                // Move it before item x, meaning that we have to look at the item after newIdx.
                movedQid = this._getQueueIdForItem(section, oldIdx),
                nextItemQid,
                sectionOffset = this._history.length + Number(!!this._current);

            if (!this._checkQueueId(movedQid)) {
                return; // failed to get current move id.
            } else if (!this.control.audioserverComp.Feature.UPDATED_QUEUE_MOVE) {
                console.warn(this.viewId, "Updated queue move commands not supported by this audioserver, using old!"); // in the old AS versions, the moveBefore command actually did move the item after the nextItemQid;

                nextItemQid = this._getQueueIdForItem(section, newIdx);

                if (!this._checkQueueId(nextItemQid)) {
                    return; // failed to get queue id of target
                }

                cmdObj.cmd = Commands.format(MusicServerEnum.AudioCommands.QUEUE_V2.MOVE_BEFORE, movedQid, nextItemQid);
            } else if (this._movingContent[section].rows.length <= moveBeforeIdx) {
                // we're at the end.
                cmdObj.cmd = Commands.format(MusicServerEnum.AudioCommands.QUEUE_V2.MOVE_END, movedQid);
            } else {
                nextItemQid = this._getQueueIdForItem(section, moveBeforeIdx);

                if (!this._checkQueueId(nextItemQid)) {
                    return; // failed to get queue id of target
                }

                cmdObj.cmd = Commands.format(MusicServerEnum.AudioCommands.QUEUE_V2.MOVE_BEFORE, movedQid, nextItemQid);
            }

            if (!this._dataModifiedWhileMoving) {
                // Keep our own dataset up to date to prevent a full tableView reload - only sensible if nothing changed in the meantime.
                this._currentQueue.move(oldIdx + sectionOffset, newIdx + sectionOffset);

                this._queue.move(oldIdx + sectionOffset, newIdx + sectionOffset);
            }

            this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, cmdObj);
        }

        didRemoveCell(section, row, tableView, cell) {
            var cmdObj = {
                    cmd: Commands.format(MusicServerEnum.AudioCommands.QUEUE_V2.REMOVE, cell.content.qId)
                },
                sectionOffset = this._history.length + Number(!!this._current); // Keep our own dataset up to date to prevent a full tableView reload

            this._currentQueue.splice(row + sectionOffset, 1);

            this._queue.splice(row + sectionOffset, 1);

            this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, cmdObj);
        }

        getURL() {
            return "queue";
        }

        setTableContent() {
            Debug.Control.AudioZone.Queue && console.log(this.viewId, "setTableContent");
            this.tableContent = [];

            if (this.queueIdx !== -1 && this.queue.length) {
                this._history = this._queue.slice(0, this.queueIdx);
                this._current = this._queue[this.queueIdx];
                this._upNext = cloneObject(this._queue).splice(this.queueIdx + 1);
                this.tableContent.push(this._getHistorySection());
                this.tableContent.push(this._getCurrentSection());
                this.tableContent.push(this._getNextSection());
                this.tableContent = this.tableContent.filter(function (section) {
                    if (section && section.isNextSection) {
                        return true;
                    } else {
                        return section && section.rows && section.rows.length;
                    }
                }.bind(this));
            }

            Debug.Control.AudioZone.Queue && console.log(this.viewId, "   new content: ", cloneObject(this.tableContent));
            return super.setTableContent(...arguments).then(function (shouldReload) {
                Debug.Control.AudioZone.Queue && console.log(this.viewId, "   will reload: " + !!shouldReload);
                return !!shouldReload;
            }.bind(this));
        }

        reloadTable(force) {
            Debug.Control.AudioZone.Queue && console.log(this.viewId, "reloadTable: force=" + !!force, getStackObj());
            var args = [];
            return super.reloadTable(...args).then(function () {
                Debug.Control.AudioZone.Queue && console.log(this.viewId, "reloadTable >> passed");
            }.bind(this));
        }

        /**
         * Using this method ensures that even after severeal enqueued reloas, this mehtod is performed after each
         * reload has been passed and before the next reload is started.
         * The dataSource & delegate are still locked at the time this method is called!
         * The promise returned by "reload()" is also still pending in this state.
         * @returns {*}
         */
        processAtTheEndOfReload() {
            Debug.Control.AudioZone.Queue && console.log(this.viewId, "processAtTheEndOfReload"); // use the dataSources content for these actions, it's still locked and so it's exactly the content in the table.

            var dsTableContent = this.tableView.dataSource.tableContent;
            this._currentQueue = cloneObject(this.queue);

            if (typeof this.currentQueueId === "string" && this.queue.length) {
                var nextSection = dsTableContent.find(function (section) {
                    return section.isNextSection;
                });

                if (nextSection) {
                    var nextSectionIdx = dsTableContent.indexOf(nextSection);
                    Debug.Control.AudioZone.Queue && console.log(this.viewId, "   setting edit mode on section: " + (nextSectionIdx - 1));
                    this.tableView.setEditingModeForSection(nextSectionIdx - 1, false, false);
                    Debug.Control.AudioZone.Queue && console.log(this.viewId, "   setting edit mode on section: " + nextSectionIdx);
                    this.tableView.setEditingModeForSection(nextSectionIdx, true, this.removeSupported(), dsTableContent[nextSectionIdx].rows.length > 1 && this.moveSupported());

                    if (this.shouldFocusCurrent) {
                        Debug.Control.AudioZone.Queue && console.log(this.viewId, "   scroll to section: " + (nextSectionIdx - 1));

                        if (this.tableView.scrollTo(nextSectionIdx - 1, 0, false, -75, true)) {
                            Debug.Control.AudioZone.Queue && console.log(this.viewId, "   Successfully scrolled to section: " + (nextSectionIdx - 1));
                            this.shouldFocusCurrent = false;
                        } else {
                            console.error(this.viewId, "Failed to scroll to section: " + (nextSectionIdx - 1));
                        }
                    }
                }
            }

            this._updateUpNextSectionHeader();

            return Q(true);
        }

        removeSupported() {
            return this.control.audioserverComp.isQueueActionAllowed(MusicServerEnum.QueueAction.REMOVE);
        }

        moveSupported() {
            return this.control.audioserverComp.isQueueActionAllowed(MusicServerEnum.QueueAction.MOVE);
        }

        // ----------------------

        /**
         * Grab a current version of the content, this way the from & to queue-id are right.
         * Also store a reference, this allows to track if there was a reload while moving around.
         * called by the lxEditableTableView before the didMoveCell is called, when starting to drag it around.
         * @private
         */
        _cellStartedMoving() {
            Debug.Control.AudioZone.Queue && console.warn(this.viewId, "_cellStartedMoving");
            this._movingContent = cloneObject(this.tableView.dataSource.tableContent);
            this._isMovingCells = true;
        }

        /**
         * Invalidate the cached content list, also reset the flag.
         * Called by lxEditableTable view after the didMoveCell method.
         * @private
         */
        _cellStoppedMoving() {
            Debug.Control.AudioZone.Queue && console.warn(this.viewId, "_cellStoppedMoving");
            this._movingContent = null;
            this._isMovingCells = false;
        }

        // ---------------------
        // Private Section
        // ---------------------
        _updateUpNextSectionHeader() {
            console.log(this.viewId, "_updateUpNextSectionHeader");

            if (this.nextSectionHeader) {
                console.log(this.viewId, "    toggle: isStream=" + !!this.states._isStream);
                this.toggleSubview(this.shuffleButton, !this.states.isStream);
                this.toggleSubview(this.repeatButton, !this.states.isStream);
                var actualFrom = this.nextSectionHeader.find(".subtitle__actual-from");
                var subtitle = this.nextSectionHeader.find(".left-content__subtitle");
                var parentName = this.states.parent && this.states.parent.name ? decodeURIComponent(this.states.parent.name) : null;
                console.log(this.viewId, "    parent: " + parentName);
                GUI.animationHandler.schedule(function () {
                    if (!this.states.isStream) {
                        this.repeatButton.getElement().toggleClass("active", this.states.plrepeat !== 0);
                        this.repeatButton.getElement().toggleClass("right-content__repeat-placeholder--3", this.states.plrepeat === 3);
                    }

                    if (parentName) {
                        console.log(this.viewId, "    update parentname & show: " + parentName);
                        actualFrom.text(parentName);
                        subtitle.show();
                    } else {
                        console.log(this.viewId, "    hide parentname");
                        subtitle.hide();
                    }
                }.bind(this));
            } else {
                console.warn(this.viewId, "    no nextSectionHeader present!");
            }
        }

        _receivedMiniserverStates(states) {
            this.states = states;
            this.isPlaying = states.isPlaying;
            var upNextHeaderChanged = false;

            if (this._parent !== JSON.stringify(states.parent) || this._isStream !== states.isStream || this._plrepeat !== states.plrepeat) {
                upNextHeaderChanged = true;
                this._parent = JSON.stringify(states.parent);
                this._isStream = states.isStream;
                this._plrepeat = states.plrepeat;
            }

            if (this.currentQueueId !== states.qid) {
                this._handleQueueIdChangeTo(states.qid);
            }  if (upNextHeaderChanged) {
                //Debug.Control.AudioZone.Queue &&
                console.log(this.viewId, "_receivedMiniserverStates: upNextHeader info changed: " + JSON.stringify(this._parent));

                this._updateUpNextSectionHeader();
            }
        }

        _handleQueueIdChangeTo(newQueueId) {
            Debug.Control.AudioZone.Queue && console.log(this.viewId, "_handleQueueIdChangeTo: from " + this.currentQueueId + " to " + newQueueId);
            this.currentQueueId = newQueueId;
            this._dataModifiedWhileMoving = this._isMovingCells;

            if (this._dataModifiedWhileMoving) {
                console.error("The current queue id was modified while moving the cells!");
            }

            if (this.isFinished) {
                Debug.Control.AudioZone.Queue && console.log(this.viewId, "  >> data load finished, qid changed, reload the table!");
                this.reloadTable(true);
            } else {
                Debug.Control.AudioZone.Queue && console.log(this.viewId, "  >> NOT reloading the table! not finished");
            }
        }

        _getHistorySection() {
            return {
                headerTitle: _("history"),
                rows: this._history.map(function (item) {
                    return this._getCellForQueueItem(item, false, true);
                }.bind(this))
            };
        }

        _getCurrentSection() {
            return {
                headerTitle: _("audio-server.title.current"),
                rows: [this._getCellForQueueItem(this._current, true)]
            };
        }

        _getNextSection() {
            if (!this.nextSectionHeader) {
                console.log(this.viewId, "_getNextSection > create header");
                this.nextSectionHeader = AudioZoneV2ControlQueueScreen.Template.getUpNextSectionHeader();
                var shuffleBtnElm = this.nextSectionHeader.find(".right-content__shuffle-placeholder"),
                    repeatBtnElm = this.nextSectionHeader.find(".right-content__repeat-placeholder");
                this.shuffleButton = new GUI.LxButton(this, shuffleBtnElm, Color.BUTTON_GLOW, null, true, 250);
                this.repeatButton = new GUI.LxButton(this, repeatBtnElm, Color.BUTTON_GLOW, null, true, 250);
                this.shuffleButton.useChildsAsActiveParts("fill");
                this.repeatButton.useChildsAsActiveParts("fill");
                this.addToHandledSubviews(this.shuffleButton);
                this.addToHandledSubviews(this.repeatButton);
                this.shuffleButton.onButtonTapped = this._handleShuffleButton.bind(this);
                this.repeatButton.onButtonTapped = this._handleRepeatButton.bind(this);

                // ensure the header contains the current infos already:
                this.states && this._updateUpNextSectionHeader();
            } else {
                console.log(this.viewId, "_getNextSection > header already exists");
            }

            return {
                headerElement: this.nextSectionHeader,
                didSelectHeader: this._handleOnParent.bind(this),
                rows: this._upNext.map(function (item) {
                    return this._getCellForQueueItem(item, false);
                }.bind(this)),
                isNextSection: true
            };
        }

        _getCellForQueueItem(item, allowIcon, showHistoryIcon) {
            var texts = this.control.audioserverComp.getTitleSubtitleForItem(item);
            var isStream = item.audiotype === MediaEnum.AudioType.STREAM || item.audiotype === MediaEnum.AudioType.LINEIN || item.audiotype === MediaEnum.AudioType.BLUETOOTH || item.audiotype === MediaEnum.AudioType.AIRPLAY;
            var cellObj = {
                type: allowIcon ? GUI.TableViewV2.CellType.Special.MediaBrowserBrowsableCell : null,
                content: {
                    title: decodeURIComponent(texts.title),
                    subtitle: decodeURIComponent(texts.subtitle),
                    item: item,
                    details: {},
                    leftIconSrc: showHistoryIcon ? Icon.AudioZone.PLAY : null,
                    active: true,
                    clickable: !isStream,
                    forceSelection: true,
                    qId: item.unique_id
                },
                action: function (cell, section, row, tableView) {
                    this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                        cmd: Commands.format(MusicServerEnum.AudioCommands.QUEUE_V2.PLAY_ID, cell.content.qId)
                    });
                }.bind(this)
            };

            if (Debug.Control.AudioZone.Queue) {
                cellObj.content.title = item.unique_id + " - " + cellObj.content.title;
            }

            return cellObj;
        }

        _getIconObject(item) {
            var ctor = Controls.AudioZoneV2Control.MediaBrowserV2Base.getConstructorForItem(item);
            return ctor.getIconObjForItem(item);
        }

        _handleOnParent() {
            // content type is required for this to work.
            if (!this.states.parent.hasOwnProperty("contentType")) {
                Controls.AudioZoneV2Control.MediaBrowserV2Base.applyContentTypeToItem(this.states.parent);
            } // check if parent item is browsable or editable (spotify/local playlist)


            if (this.states.parent.contentType === MusicServerEnum.MediaContentType.SERVICE) {
                if (this.states.parent.type === MusicServerEnum.FileType.PLAYLIST_BROWSABLE) {
                    if (SpotifyAccountManager.shared(this.control).isOwnerOfItem(this.states.parent)) {
                        this.states.parent.type = MusicServerEnum.FileType.PLAYLIST_EDITABLE;
                    }
                }
            }

            if (this.states.parent && this.states.parent.type) {
                this.control.audioserverComp.AudioViewController.navigateToItem(this.states.parent, this.ViewController);
            }
        }

        _handleRepeatButton() {
            var repeatValue;

            switch (this.states.plrepeat) {
                case 0:
                    repeatValue = 1;
                    break;

                case 1:
                    repeatValue = 3;
                    break;

                default:
                    repeatValue = 0;
                    break;
            }

            this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                cmd: MusicServerEnum.AudioCommands.REPEAT.PLAIN + repeatValue
            });
        }

        /**
         * Ensures that no multiple shuffles are performed at once, which may put the audioserver/app under high
         * pressure, especially when working with large lists.
         * @private
         */
        _handleShuffleButton() {
            if (this._reloadAfterShuffleDefer && this._reloadAfterShuffleDefer.promise.isPending()) {
                // shuffle already pending
                return;
            }

            this._reloadAfterShuffleDefer = Q.defer();
            this._shuffleBtnActivateTimeout && clearTimeout(this._shuffleBtnActivateTimeout);
            this._shuffleBtnActivateTimeout = null; // temporarily activate & disable the button - this indicates that shuffling is in progress on large lists

            this.shuffleButton.getElement().toggleClass("active", true);
            this.shuffleButton.setEnabled(false);

            this._reloadAfterShuffleDefer.promise.then(function () {
                this.shuffleButton.getElement().toggleClass("active", false);
                this.shuffleButton.setEnabled(true);
            }.bind(this)); // ensure it's becoming active again, even if nothing happened (e.g. shuffle on lib-pl ignored by AS)

this._shuffleBtnActivateTimeout = setTimeout(() => {
                this._reloadAfterShuffleDefer && this._reloadAfterShuffleDefer.resolve();
            }, 4000);            this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                cmd: MusicServerEnum.AudioCommands.SHUFFLE
            }).fail(function (err) {
                this._reloadAfterShuffleDefer.resolve();
            }.bind(this));
        }

        _getDetailsForItem(item) {
            var mediaType = null,
                identifier = null,
                user = MusicServerEnum.NOUSER;
            return {
                mediaType: mediaType,
                contentTypes: [item.contentType],
                identifier: identifier,
                username: user
            };
        }

        _emptyQueue() {
            this.isFinished = false;
            this.queue = []; // Reset the queue on the reload event, the queue will be refilled in the "receivedQueueChunkEvent" function
        }

        /**
         * Acquires the queue id of the item at section and row - returns -1 if out of range or sth goes wrong.
         * @param sectionIdx
         * @param rowIdx
         * @returns {number}    -1 if sth went wrong
         * @private
         */
        _getQueueIdForItem(sectionIdx, rowIdx) {
            var queueId = -1;

            try {
                queueId = this._movingContent[sectionIdx].rows[rowIdx].content.qId;
            } catch (ex) {
                console.error(this.viewId, "Failed to acquire queue-id of object at section " + sectionIdx + ", row: " + rowIdx + "!", ex);
            }

            return queueId;
        }

        /**
         * Will check if the queue id isn't -1, if so returns false and will trigger a table reload.
         * @param qId
         * @returns {boolean}
         * @private
         */
        _checkQueueId(qId) {
            var isValid = true;

            if (qId === -1) {
                isValid = false;
                setTimeout(this.reloadTable.bind(this), 5); // perform async to allow other calls to finish.
            }

            return isValid;
        }

    }

    Controls.AudioZoneV2Control.QueueScreenV2 = AudioZoneV2ControlQueueScreen;
    return Controls.AudioZoneV2Control.QueueScreenV2;
});
