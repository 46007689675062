'use strict';

define("EcoPlayerView", ['AudioZoneV2ControlEnums', 'MediaBrowserV2Base', 'MediaBrowserV2Service', 'MediaBrowserV2Service7', 'MediaBrowserV2Service11', 'MediaBrowserV2Service12', 'MediaBrowserV2Favorites', 'MediaBrowserV2LineIn', 'MediaBrowserV2Library', 'MediaBrowserV2Playlists', 'HardwareVolumeButtonHandlerV2', 'CurrentCoverViewV2'], function (AudioZoneV2ControlEnums, MediaBrowserV2Base, MediaBrowserV2Service, MediaBrowserV2Service7, MediaBrowserV2Service11, MediaBrowserV2Service12, MediaBrowserV2Favorites, MediaBrowserV2LineIn, MediaBrowserV2Library, MediaBrowserV2Playlists, HardwareVolumeButtonHandlerV2, CurrentCoverViewV2) {
    class EcoPlayerView extends GUI.View {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate() {
                return '' + '<div class="eco-player-view__left"></div>' + '<div class="eco-player-view__right">' + '   <div class="right__playable-container">' + '       <div class="playable-container__name playable-container__label active" />' + '       <div class="playable-container__title playable-container__label" />' + '       <div class="playable-container__subtitle playable-container__label" />' + '       <div class="playable-container__detail playable-container__label active" />' + '       <div class="playable-container__main-controls">' + '          <div class="main-controls__spacing"></div>' + '             ' + createControlBar() + '          <div class="main-controls__spacing"></div>' + '       </div>' + '       <div class="playable-container__volume-controls">' + '          <div class="volume-controls__spacing"></div>' + '          <div class="volume-controls__center"></div>' + '          <div class="volume-controls__spacing"></div>' + '       </div>' + '   </div>' + '   <div class="right__locked-container">' + '       <div class="locked-container__title">' + _("operation-blocked") + '</div>' + '       <div class="locked-container__desc">' + _("audio-server.operation-blocked") + '</div>' + '   </div>' + '</div>' + '</div>' + '';
            };

            var createControlBar = function createControlBar() {
                return '' + '<div class="main-controls__control-bar">' + '   <div class="control-bar__prev-button control-bar__icon-container">' + '       ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PREV, "control-bar__prev control-bar__icon") + '   </div>' + '   <div class="control-bar__play-pause-button control-bar__icon-container control-bar__play-pause clickable">' + '       ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PLAY, "control-bar__play control-bar__icon control-bar__play-pause-icon") + '       ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PAUSE, "control-bar__pause control-bar__icon control-bar__play-pause-icon") + '   </div>' + '   <div class="control-bar__next-button control-bar__icon-container">' + '      ' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.NEXT, "control-bar__next control-bar__icon") + '   </div>' + '</div>' + '';
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        /**
         *
         * @param control
         * @param dismissCallback called when the playerView wants to be dismissed.
         */
        constructor(control) {
            Debug.Control.AudioZone.EcoPlayerView && console.log("EcoPlayerView ctor");
            super();
            applyMixins(this, StateHandler.Mixin);
            this.control = control;
        }

        viewDidLoad() {
            Debug.Control.AudioZone.EcoPlayerView && console.log(this.name, "viewDidLoad");
            var promise = super.viewDidLoad(...arguments);
            this.element.append(EcoPlayerView.Template.getTemplate(this.control));
            this.elements = {};
            this.elements.leftContainer = this.element.find(".eco-player-view__left");
            this.elements.playerName = this.element.find(".playable-container__name");
            this.elements.titleLabel = this.element.find(".playable-container__title");
            this.elements.subtitleLabel = this.element.find(".playable-container__subtitle");
            this.elements.parentLabel = this.element.find(".playable-container__detail");
            this.elements.volumeControls = this.element.find(".volume-controls__center");
            this.elements.mainControls = this.element.find(".main-controls__control-bar");
            this.elements.playerName.text(this.getPlayerTitle()); // volume control strip

            this.controlStrip = new GUI.AudioZoneV2ControlStrip(this.control, {
                hudTarget: this,
                forEco: true
            });
            this.controlStrip.element.addClass("center__strip");
            this.controlStrip.setViewController(this);
            this.appendSubview(this.controlStrip, this.elements.volumeControls); // control buttons

            this.elements.prevButton = this.elements.mainControls.find('.control-bar__prev-button');
            this.elements.playPauseButton = this.elements.mainControls.find('.control-bar__play-pause-button');
            this.elements.nextButton = this.elements.mainControls.find('.control-bar__next-button');
            this.elements.playButtonIcon = this.elements.mainControls.find('.control-bar__play');
            this.elements.pauseButtonIcon = this.elements.mainControls.find('.control-bar__pause').hide(); // ---------------------
            //    active controls
            // ---------------------

            this.buttons = {
                playerSelectorButton: new GUI.LxButton(this, this.elements.playerName[0], Color.BUTTON_GLOW, null, null, 250),
                parentButton: new GUI.LxButton(this, this.elements.parentLabel[0], Color.BUTTON_GLOW, null, null, 250),
                prevButton: new GUI.LxButton(this, this.elements.prevButton[0], Color.BUTTON_GLOW, null, true, 250),
                playPauseButton: new GUI.LxButton(this, this.elements.playPauseButton[0], Color.BUTTON_GLOW, null, true, 250),
                nextButton: new GUI.LxButton(this, this.elements.nextButton[0], Color.BUTTON_GLOW, null, true, 250),
                bgButton: new GUI.LxButton(this, this.element[0])
            };
            Object.values(this.buttons).forEach(function (button) {
                this.addToHandledSubviews(button);
                button.useChildsAsActiveParts("fill");
                button.useElementAsActivePart("color");
            }.bind(this)); // Set up cover view using require.

            this.coverView = new CurrentCoverViewV2(this.control);
            this.coverView.name = "EcoCoverView";
            this.coverView.element.addClass("left__cover-view");
            this.appendSubview(this.coverView, this.elements.leftContainer);
            return promise;
        }

        viewWillAppear() {
            Debug.Control.AudioZone.EcoPlayerView && console.log(this.name, "viewWillAppear");
            var promise = super.viewWillAppear(...arguments);
            SandboxComponent.registerForStateChangesForUUID(this.control.uuidAction, this, this.receivedStates);
            return promise;
        }

        viewDidAppear() {
            Debug.Control.AudioZone.EcoPlayerView && console.log(this.name, "viewDidAppear");
            var promise = super.viewDidAppear(...arguments);

            this._registerButtons();

            HardwareVolumeButtonHandlerV2.shared().start(this.control);
            return promise;
        }

        viewWillDisappear() {
            Debug.Control.AudioZone.EcoPlayerView && console.log(this.name, "viewWillDisappear");
            SandboxComponent.unregisterForStateChangesForUUID(this.control.uuidAction, this);
            return super.viewWillDisappear(...arguments);
        }

        viewDidDisappear(viewRemainsVisible) {
            Debug.Control.AudioZone.EcoPlayerView && console.log(this.name, "viewDidDisappear");

            this._unregisterButtons();

            try {
                HardwareVolumeButtonHandlerV2.shared().stop();
            } catch (ex) {
                console.error(this.viewId, "viewDidDisappear - caught exception while trying to stop HW volume button handler! " + ex.message);
            }

            return super.viewDidDisappear(...arguments);
        }

        getPlayerTitle() {
            var title = this.control.getName(),
                room = this.control.getRoom();

            if (room && room.name !== title) {
                title += SEPARATOR_SYMBOL + room.name;
            }

            return title;
        }

        /**
         * Used by the eco screen to tell the ecoPlayer whether or not the "screen" is active.
         * @param isActive
         */
        resignActive() {
            this.controlStrip.resignActive();
        }

        receivedStates(states) {
            this.states = states;
            this.element.toggleClass("eco-player-view--is-locked", !!states.isLocked || !!states.universalIsLocked);
            this.elements.titleLabel.text(states.texts.mainText);
            this.elements.subtitleLabel.text(states.texts.subText);

            if (this.states.parent && this.states.parent.name) {
                this.elements.parentLabel.text(_("media.item.from", {
                    parentName: decodeURIComponent(this.states.parent.name)
                }));
            } else {
                this.elements.parentLabel.text(NBR_SPACE);
            }

            this.elements.prevButton.toggleClass('disabled', states.isStream);
            this.elements.nextButton.toggleClass('disabled', states.isStream);
            this.elements.playPauseButton.toggleClass('active', states.isPlaying);

            if (this.states.isPlaying) {
                this.elements.playButtonIcon.hide();
                this.elements.pauseButtonIcon.show();
            } else {
                this.elements.playButtonIcon.show();
                this.elements.pauseButtonIcon.hide();
            } // Inform screensaver, as it may need to adopt the screens brightness
        }

        // ------- Private Methods --------------
        _registerButtons() {
            var buttonKeys = Object.keys(this.buttons);

            for (var i = 0; i < buttonKeys.length; i++) {
                var btnKey = buttonKeys[i];
                var button = this.buttons[btnKey];
                button.onButtonTapped = this._handleButtonTapped.bind(this);
            }
        }

        _unregisterButtons() {
            var buttonKeys = Object.keys(this.buttons),
                i,
                btnKey,
                button;

            for (i = 0; i < buttonKeys.length; i++) {
                btnKey = buttonKeys[i];
                button = this.buttons[btnKey];
                button.onButtonTapped = null;
            }
        }

        _handleButtonTapped(sourceBtn) {
            switch (sourceBtn) {
                case this.buttons.prevButton:
                    if (!this.controlButtonsDisabled) {
                        this._prevTrack();
                    }

                    break;

                case this.buttons.playPauseButton:
                    if (this.states.isPlaying) {
                        this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                            cmd: MusicServerEnum.AudioCommands.PAUSE
                        });
                    } else {
                        this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                            cmd: MusicServerEnum.AudioCommands.PLAY
                        });
                    }

                    break;

                case this.buttons.nextButton:
                    if (!this.controlButtonsDisabled) {
                        this._nextTrack();
                    }

                    break;

                case this.buttons.bgButton:
                    NavigationComp.showControlContent(this.control);
                    break;

                case this.buttons.parentButton:
                    if (this.states.parent && this.states.parent.type) {
                        if (this.control.audioserverComp.AudioViewController) {
                            this.control.audioserverComp.AudioViewController.navigateToItem(this.states.parent);
                        } else {
                            NavigationComp.showControlContent(this.control, {
                                item: this.states.parent
                            });
                        }
                    } else {
                        NavigationComp.showControlContent(this.control);
                    }

                    break;

                case this.buttons.playerSelectorButton:
                    NavigationComp.showState(ScreenState.AmbientSettingsScreen, null, AnimationType.MODAL);
                    break;

                default:
                    console.log("Button not handled");
                    break;
            }
        }

        _nextTrack() {
            this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                cmd: MusicServerEnum.AudioCommands.QUEUE.NEXT
            });
        }

        _prevTrack() {
            this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                cmd: MusicServerEnum.AudioCommands.QUEUE.PREV
            });
        }

    }

    Controls.AudioZoneV2Control.EcoPlayerView = EcoPlayerView;
    return Controls.AudioZoneV2Control.EcoPlayerView;
});
