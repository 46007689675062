import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Weather01n(props) {
    return (
        <Svg isreact="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            <Path
                d="M20.04 5a8.853 8.853 0 00-2.111.244A8.02 8.02 0 1028 14.015a5.177 5.177 0 01-1.95.325 5.997 5.997 0 01-6.01-6.01 5.488 5.488 0 01.975-3.249A3.954 3.954 0 0020.041 5z"
                fill="#9cb2bc"
            />
            <Path
                d="M18.19 27H7.81A3.852 3.852 0 014 23.116a3.924 3.924 0 011.58-3.148A5.441 5.441 0 0110.924 15a5.3 5.3 0 014.281 2.168 4.122 4.122 0 014.324 2.31A3.893 3.893 0 0122 23.116 3.852 3.852 0 0118.19 27z"
                fill="#d5e2e9"
            />
            <Path
                fill="#f4cd27"
                fillRule="evenodd"
                d="M8.249 11.261L9.01 11.527 8.249 11.794 7.845 12.926 7.44 11.794 6.679 11.527 7.44 11.261 7.845 10.129 8.249 11.261z"
            />
            <Path
                fill="#f4cd27"
                fillRule="evenodd"
                d="M4.67 12.46L5.236 13.97 6.302 14.325 5.236 14.68 4.67 16.19 4.104 14.68 3.038 14.325 4.104 13.97 4.67 12.46z"
            />
            <Path fill="none" d="M0 0H32V32H0z" />
        </Svg>
    )
}

export default Weather01n
